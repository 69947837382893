import React, { useState } from 'react';
// import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
import { Drawer, Spin, Tag } from 'udesk-ui';
import { StandardScript } from '../TableItem/StandardScript';
import styled from 'styled-components';
import { getIntelligentPartnerMistakeCollectionsByIdWordDetail } from 'src/api/intelligentPartnerMistakeCollections/{id}/wordDetail';
import { IntelligentPartnerMistakeRuleWord } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const CheckScriptWrap = styled.div`
    height: 100%;
    .udesk-qa-ui-spin-nested-loading {
        height: 100%;
        .udesk-qa-ui-spin-container {
            height: 100%;
        }
    }
    .rule-word-detail-list-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px 0;
        margin: 0 16px;
        .rule-word-detail-list-item-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 8px;
        }
        .rule-word-detail-list-item-content {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
        }
        .rule-word-detail-list-item-tags {
            margin-top: 8px;
        }
    }
`;
export const CheckScript = React.memo((props: any) => {
    // const locales = Locales['current'];

    const {
        detail: { standardWord = '-', ruleWordDetailList = [] },
        loading,
        ...drawerProps
    } = props;

    return (
        <Drawer title={/* 查看话术 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.checkScript.index.checkScript} placement="right" width={560} {...drawerProps}>
            <CheckScriptWrap>
                <Spin spinning={loading}>
                    <StandardScript words={standardWord} />
                    {ruleWordDetailList.map((ruleWordDetail, index) => {
                        return (
                            <div className="rule-word-detail-list-item">
                                <div className="rule-word-detail-list-item-title">{`${/* 第 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.checkScript.index.section}${
                                    index + 1
                                }${/* 次话术 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.checkScript.index.secondaryDiscourseTechnique}`}</div>
                                <div className="rule-word-detail-list-item-content">
                                    {ruleWordDetail.words}
                                </div>
                                {Array.isArray(ruleWordDetail.ruleList) &&
                                    ruleWordDetail.ruleList.length > 0 && (
                                    <div className="rule-word-detail-list-item-tags">
                                        {ruleWordDetail.ruleList.map((rule) => {
                                            return <Tag color={rule.isHit ? 'green' : 'red'}>{rule.ruleName}</Tag>;
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </Spin>
            </CheckScriptWrap>
        </Drawer>
    );
});

export const useCheckScript = () => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState<IntelligentPartnerMistakeRuleWord | {}>({});

    const onClose = () => {
        setVisible(false);
    };
    const open = (id) => {
        setVisible(true);
        setLoading(true);
        getIntelligentPartnerMistakeCollectionsByIdWordDetail({
            segments: {
                id,
            },
        })
            .then((resp) => {
                setDetail(resp.data || {});
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        visible,
        open,
        onClose,
        detail,
        loading,
    };
};
