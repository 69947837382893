import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Button, message } from 'udesk-ui';
import { TemplatePropsValue as TypeSelectValue } from '../TypeSelect';
import { TypeEnum } from '../../const';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';

const Editor = styled.div`
    min-height: 100px;
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
`;

const Operator = styled.div`
    margin-bottom: 16px;
`;

type PromptAreaProps = {
    value?: string;
    onChange?: (v: string) => void;
    getParams?: () => { modelDesc: string; typeSelect: TypeSelectValue };
    setParams?: (d: any) => void;
};

let tempValue = '';

const PromptArea: FC<PromptAreaProps> = (props) => {
    const { value, onChange, getParams, setParams } = props;

    const onGenPrompt = () => {
        const { modelDesc, typeSelect } = getParams?.() || {};
        const { type, divideRuleList } = typeSelect || {};

        if (
            !modelDesc ||
            (type === TypeEnum.GradedEvaluation &&
                !divideRuleList?.every((e) => e.divide && e.divideDesc))
        ) {
            message.warning(
                /* 请完善配置 */ UdeskLocales['current'].pages.semanticIntelligence.intelligentModel
                    .components.promptArea.index.pleaseImproveTheConfiguration
            );
            return;
        }

        if (type === TypeEnum.GradedEvaluation) {
            onChange?.(genGradedEvaluationPrompt(modelDesc, divideRuleList));
        } else {
            onChange?.(genConformPrompt(modelDesc));
        }
    };

    const genConformPrompt = (desc: string) => {
        const isZh = Udesk.data.init.company.systemLang === 'zh';

        if (isZh) {
            // no-locale-translate
            return `
                以上内容中 <span id="prompt-des" style="color: blue">${desc}</span>
                <br /> 
                <br />
                如果存在，请回答“是”并给出证明；如果不存在，只需回答“否”即可。
            `;
        } else {
            return `
                In the above <span id="prompt-des" style="color: blue">${desc}</span>
                <br /> 
                <br />
                If so, answer "yes" and provide proof; If not, simply answer "no".
            `;
        }
    };

    const genGradedEvaluationPrompt = (
        desc: string,
        divideRuleList?: TypeSelectValue['divideRuleList']
    ) => {
        const evaluatesLen = divideRuleList?.length;

        const isZh = Udesk.data.init.company.systemLang === 'zh';

        if (isZh) {
            // no-locale-translate
            return `
                以上内容中 <span id="prompt-des" style="color: blue">${desc}</span>
                分为${evaluatesLen || 0}档评级
                <br />
                ${divideRuleList?.reduce(
                    (pre, cur, idx) =>
                        `${pre}
                        <span style="color: blue">
                            (${idx + 1})
                            "<span class="prompt-level">${
                                cur.divide || ''
                            }</span>"：<span class="prompt-eva">${cur.divideDesc || ''}</span>
                        </span>
                        <br />`,
                    ''
                )} 
                <br />
                请根据对话内容，从中选定一个评级（<span style="font-weight: 500">${divideRuleList
                    ?.map((d) => `<span>${d.divide || ''}</span>`)
                    .join('/')}</span>），并给出证明。
            `;
        } else {
            return `
                In the above <span id="prompt-des" style="color: blue">${desc}</span>
                It is rated in ${evaluatesLen || 0}
                <br />
                ${divideRuleList?.reduce(
                    (pre, cur, idx) =>
                        `${pre}
                        <span style="color: blue">
                            (${idx + 1})
                            "<span class="prompt-level">${
                                cur.divide || ''
                            }</span>": <span class="prompt-eva">${cur.divideDesc || ''}</span>
                        </span>
                        <br />`,
                    ''
                )} 
                <br />
                Select a rating based on the conversation (<span style="font-weight: 500">${divideRuleList
                    ?.map((d) => `<span>${d.divide || ''}</span>`)
                    .join('/')}</span>), and prove it.
            `;
        }
    };

    const onInput = (e) => {
        const modelDesc = e.target.querySelector('#prompt-des')?.innerText;
        setParams?.({ modelDesc });

        const levelSpans = Array.from(e.target.querySelectorAll('.prompt-level')) as any[];
        const evaSpans = Array.from(e.target.querySelectorAll('.prompt-eva')) as any[];
        if (levelSpans?.length && evaSpans?.length) {
            const divideRuleList = levelSpans.map((l, idx) => {
                return {
                    divide: l?.innerText,
                    divideDesc: evaSpans[idx]?.innerText,
                };
            });
            setParams?.({ typeSelect: { divideRuleList } });
        }

        const str = e.target.innerHTML;
        onChange?.(str);
        tempValue = str;
    };

    return (
        <>
            <Operator>
                <Button type="primary" onClick={onGenPrompt}>
                    {/* 生成提示语 */}
                    {
                        UdeskLocales['current'].pages.semanticIntelligence.intelligentModel
                            .components.promptArea.index.generatePromptLanguage
                    }
                </Button>
            </Operator>

            <Editor
                contentEditable
                onInput={onInput}
                dangerouslySetInnerHTML={{ __html: value }}
                key={Math.random().toString()} // 在相同内容时强制刷新
            ></Editor>
        </>
    );
};

export default memo(PromptArea, (prev, next) => {
    return tempValue === next.value;
});
