import React from 'react';
import './index.less';

type PropsType = {
  date: string;
  children: any;
};

export default React.memo<PropsType>((props) => {
    const {date, children} = props;
    return (
        <div className={"time-event"}>
            <div>{date}</div>
            <div>{children}</div>
        </div>
    );
});
