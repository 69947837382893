import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Button } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
import { postCallServiceSummaryFieldsGeneratePrompt } from 'src/api/callServiceSummaryFields/generatePrompt';

const Editor = styled.div`
    min-height: 100px;
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
`;

const Operator = styled.div`
    margin-bottom: 16px;
`;

interface PromptAreaProps {
    value?: string;
    onChange?: (v?: string) => void;
    getPromptParams?: () => Promise<any>;
}

let tempValue = '';

const PromptArea: FC<PromptAreaProps> = (props) => {
    const { value, onChange, getPromptParams } = props;

    const onGenPrompt = () => {
        getPromptParams?.().then((values) => {
            postCallServiceSummaryFieldsGeneratePrompt(values).then((res) => {
                const p = res.data;
                onChange?.(p);
            });
        });
    };

    const onInput = (e) => {
        const str = e.target.innerHTML;
        onChange?.(str);
        tempValue = str;
    };

    return (
        <>
            <Operator>
                <Button type="primary" onClick={onGenPrompt}>
                    {/* 生成提示语 */}
                    {
                        UdeskLocales['current'].pages.semanticIntelligence.intelligentModel
                            .components.promptArea.index.generatePromptLanguage
                    }
                </Button>
            </Operator>

            <Editor
                contentEditable
                onInput={onInput}
                dangerouslySetInnerHTML={{ __html: value }}
                key={Math.random().toString()} // 在相同内容时强制刷新
            ></Editor>
        </>
    );
};

export default memo(PromptArea, (prev, next) => {
    console.log(tempValue, next.value);
    return tempValue === next.value;
});
