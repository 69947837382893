export default buildUser;

function buildUser(user, init) {
    if (user) {
        var features = init.menuList;
        var featureCodeMappings = {};
        if(features){
            for (var i = 0; i < features.length; i++) {
                var feature = features[i];
                featureCodeMappings[feature.permission] = true;
            }
        }


        Object.defineProperty(user, "hasFeature", {
            value: function(featureCode) {
                return Object.prototype.hasOwnProperty.call(featureCodeMappings, featureCode);
            },
            enumerable: true,
            configurable: false,
            writable: false
        });
        return user;
    }
}
