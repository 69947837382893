import Udesk from 'Udesk';

function computeFlowPointPredeterminedScoreRange(canvasNodeMatchResultList) {
    let max = 0, min = 0;
    if (Array.isArray(canvasNodeMatchResultList)) {
        walk([...canvasNodeMatchResultList]);
    }

    return [min, max];

    function walk(list) {
        // list.sort((a, b) => a.nodeIndex - b.nodeIndex);

        const nodeMap = {
            1: {
                value: 0,
                children: [],
                accumulativeTotal: [0],
            }
        }; // 使用nodeIndex做Key
        list.forEach(node => {
            const preIndexList = node.preIndexList;
            const { nodeIndex } = node;
            const treeNode = {
                value: 0,
                accumulativeTotal: [],
            };
            if (node.score) {
                if (node.scoreType === 2) {
                    treeNode.value -= node.score;
                } else {
                    treeNode.value += node.score;
                }
            }
            preIndexList.forEach((preIndex) => {
                const pNode = nodeMap[preIndex];
                if (pNode) {
                    if (pNode.children) {
                        pNode.children.push(treeNode);
                    } else {
                        pNode.children = [treeNode];
                    }
                    pNode.accumulativeTotal.forEach(pNodeAccumulativeTotal => {
                        const accumulativeTotal = pNodeAccumulativeTotal + treeNode.value;
                        treeNode.accumulativeTotal.push(accumulativeTotal);
                        max = Math.max(max, accumulativeTotal);
                        min = Math.min(min, accumulativeTotal);
                    });
                }
            });
            nodeMap[nodeIndex] = treeNode;
        });

    }
}

function computeSecondaryCategoryLimitScore(secondaryCategory) {
    secondaryCategory._minScore = 0;
    secondaryCategory._maxScore = 0;
    // 1. 根据各个质检点的加减分设置，计算最大值与最小值
    if (secondaryCategory.pointDataList && secondaryCategory.pointDataList.length > 0) {
        for (let i = 0; i < secondaryCategory.pointDataList.length; i++) {
            let pointData = secondaryCategory.pointDataList[i];
            const { canvasNodeMatchResultList, scoreType, predeterminedScore } = pointData;

            // 如果是流程质检
            if(canvasNodeMatchResultList) {
                // 一个流程可能有很多个分支，所以：
                // 1 分别找出最大值分支和最小值分支，这个值不一定要到叶子节点，要对比途经的所有值
                // 2 最大值分支得分加到 secondaryCategory._maxScore 上
                // 3 最小值分支得分减到 secondaryCategory._minScore 上
                const [min, max] = computeFlowPointPredeterminedScoreRange(canvasNodeMatchResultList);
                secondaryCategory._minScore += min;
                secondaryCategory._maxScore += max;
            }else{
                // 原有逻辑
                if (scoreType === 2) {
                    // 扣分累计到最小值上
                    secondaryCategory._minScore -= predeterminedScore;
                } else {
                    // 加分累计到最大值上
                    secondaryCategory._maxScore += predeterminedScore;
                }
            }
        }
    }

    // 2. 根据上下限与起评分决定最终分值
    computeFinalLimitScore(secondaryCategory);
}

function computeFinalLimitScore(category) {
    // 1. 最小值与最大值均需增加起评分
    if (category.initialScore != null) {
        category._minScore += category.initialScore;
        category._maxScore += category.initialScore;
    }

    // 2.1 最大值比上限大，则最大值为上限
    if (category.scoreUpperLimit != null && category._maxScore > category.scoreUpperLimit) {
        category._maxScore = category.scoreUpperLimit;
    }
    // 2.2 最大值比下限小，则最大值为下限
    if (category.scoreLowerLimit != null && category._maxScore < category.scoreLowerLimit) {
        category._maxScore = category.scoreLowerLimit;
    }

    // 3.1 最小值比下限小，则最小值为下限
    if (category.scoreLowerLimit != null && category._minScore < category.scoreLowerLimit) {
        category._minScore = category.scoreLowerLimit;
    }
    // 3.2 最小值比上限大，则最小值为上限
    if (category.scoreUpperLimit != null && category._minScore > category.scoreUpperLimit) {
        category._minScore = category.scoreUpperLimit;
    }
}

function computeScore(task, pointCategoryList) {
    task.inspectionGrade = '--';
    task.score = 0;
    if (pointCategoryList && pointCategoryList.length > 0) {
        for (let i = 0; i < pointCategoryList.length; i++) {
            let firstCategory = pointCategoryList[i];
            firstCategory.score = 0;
            firstCategory._totalScore = 0;

            if (firstCategory.children && firstCategory.children.length > 0) {
                for (let j = 0; j < firstCategory.children.length; j++) {
                    let secondaryCategory = firstCategory.children[j];
                    // 计算各个二级分类分值
                    computeSecondaryCategoryScore(secondaryCategory);

                    // 一级分类，第一步得分=该一级分类下所有二级分类的最终得分和 + 该分类起始评分
                    // 一级分类，原始总分：该一级分类下所有二级分类的【二级分类预设分值】和
                    // 二级分类预设分值：如果二级分类设置了得分上限，则为得分上限，否则为二级分类的【最终得分】
                    firstCategory.score += secondaryCategory.score;
                    firstCategory._totalScore +=
                        secondaryCategory.scoreUpperLimit != null
                            ? secondaryCategory.scoreUpperLimit
                            : secondaryCategory.score;
                }
            }

            if (firstCategory.initialScore != null) {
                firstCategory.score += firstCategory.initialScore;
                firstCategory._totalScore += firstCategory.initialScore;
            }
            // 根据上下限计算一级分类最终分值
            computeFinalScore(firstCategory);
            // 累计质检任务的分值
            task.score += firstCategory.score;
        }
    }
}

function computeSecondaryCategoryScore(secondaryCategory) {
    secondaryCategory.score = 0;

    // 1. 根据各个质检点的选择情况，计算实际得分和、实际总分、原始总分
    // 实际得分和：该分类下除掉【不涉及项】所有质检点的得分之和
    // 实际总分：该分类下除掉【不涉及项】所有质检点的预设分值之和
    // 原始总分：该分类下所有质检点的预设分值之和（包括【不涉及项】）
    // 第一步得分= 实际得分和 / 实际总分 * 原始总分 + 该分类起始评分
    // 备注：如果【实际总分】=0分，则第一步得分=该分类起始评分
    let score = 0;
    let totalScoreWithoutNA = 0;
    let totalScore = 0;
    let totalScoreShouldShared = 0;
    let naScoreAdd = 0;
    if (secondaryCategory.pointDataList && secondaryCategory.pointDataList.length > 0) {
        for (let i = 0; i < secondaryCategory.pointDataList.length; i++) {
            let pointData = secondaryCategory.pointDataList[i];
            let predeterminedScore =
                pointData.scoreType === 2 ? pointData.predeterminedScore * -1 : pointData.predeterminedScore;
            totalScore += predeterminedScore;
            if (pointData.type !== Udesk.enums.pointTypes.machineLearning.id) {
                if (!pointData.naChecked) {
                    totalScoreWithoutNA += predeterminedScore;
                    switch (pointData.gradeType) {
                        case Udesk.enums.pointGradeTypes.radio.id:
                            if (pointData.gradeChooseHit === 1) {
                                score += predeterminedScore;
                            }
                            break;
                        case Udesk.enums.pointGradeTypes.input.id:
                            if (pointData.gradeInput != null) {
                                if (pointData.scoreType === 2) {
                                    score -= pointData.gradeInput;
                                } else {
                                    score += pointData.gradeInput;
                                }
                            }
                            break;
                        case Udesk.enums.pointGradeTypes.select.id:
                            if (pointData.gradeChooseOption != null && !isNaN(pointData.gradeChooseOption)) {
                                score +=
                                pointData.scoreType === 2
                                    ? Number(pointData.gradeChooseOption) * -1
                                    : Number(pointData.gradeChooseOption);
                            }
                            break;
                        case Udesk.enums.pointGradeTypes.foctors.id:
                            if (pointData.gradeChooseHit === 1) {
                                score += predeterminedScore;
                            }
                            break;
                        default:
                            break;
                    }
                }
                //区分NA命中时 平均 加减分 不加不减的情况
                //组内平均：NA命中 -> 分数平均至其他非NA的项
                //直接加减分：NA命中 -> 分数不平均分配，分数直接加减到得分
                //不加不减：NA命中 -> 分数不平均分配，分数不进入得分
                if (pointData.naChecked) {
                    switch (pointData.naScoreType) {
                        case Udesk.enums.naScoreType.average.id:
                            totalScoreShouldShared += predeterminedScore;
                            break;
                        case Udesk.enums.naScoreType.addOrSubtract.id:
                            naScoreAdd += predeterminedScore;
                            // totalScoreWithoutNA += predeterminedScore;
                            break;
                        case Udesk.enums.naScoreType.noChange.id:
                        // totalScoreWithoutNA += predeterminedScore;
                            break;
                        default:
                            break;
                    }
                }
            } else {
                totalScoreWithoutNA += predeterminedScore;
                if (pointData.gradeChooseHit === 1) {
                    score += predeterminedScore;
                }
            }
        }
    }
    secondaryCategory.score =
        totalScoreWithoutNA === 0
            ? score + naScoreAdd
            : (totalScoreShouldShared / totalScoreWithoutNA) * score + score + naScoreAdd;
    secondaryCategory._totalScore = totalScore;
    if (secondaryCategory.initialScore != null) {
        secondaryCategory.score += secondaryCategory.initialScore;
        secondaryCategory._totalScore += secondaryCategory.initialScore;
    }

    // 2. 根据上下限计算二级分类最终分值
    computeFinalScore(secondaryCategory);
}

function computeFinalScore(category) {
    // 1. 比上限大
    // 忽略超额溢出，第二步得分=该分类分数上限
    // 自动权重核算，第二步得分=该分类分数上限 / （原始总分 + 该分类起始评分） * 第一步得分
    if (category.scoreUpperLimit != null && category.score > category.scoreUpperLimit) {
        if (category.wayOverUpperLimit === 1) {
            category.score = category.scoreUpperLimit;
        } else {
            category.score = (category.scoreUpperLimit / category._totalScore) * category.score;
        }
    }

    // 2. 比下限小，则分值为下限
    if (category.scoreLowerLimit != null && category.score < category.scoreLowerLimit) {
        category.score = category.scoreLowerLimit;
    }
    category.score = Math.round(category.score * 100) / 100;
}

function getScore(pointCategoryList, data) {
    data.task._minScore = 0;
    data.task._maxScore = 0;
    if (pointCategoryList && pointCategoryList.length > 0) {
        if (pointCategoryList[0].children && pointCategoryList[0].children.length > 0) {
            data.selectedCategory = pointCategoryList[0].children[0];
        }

        // 计算各级分值
        for (let i = 0; i < pointCategoryList.length; i++) {
            let firstCategory = pointCategoryList[i];
            firstCategory._minScore = 0;
            firstCategory._maxScore = 0;

            if (firstCategory.children && firstCategory.children.length > 0) {
                for (let j = 0; j < firstCategory.children.length; j++) {
                    let secondaryCategory = firstCategory.children[j];
                    // 计算各个二级分值
                    computeSecondaryCategoryLimitScore(secondaryCategory);

                    // 累计最大值与最小值
                    firstCategory._minScore += secondaryCategory._minScore;
                    firstCategory._maxScore += secondaryCategory._maxScore;
                }
            }

            // 计算一级最终分值
            computeFinalLimitScore(firstCategory);

            // 累计质检任务的最大值与最小值
            data.task._minScore += firstCategory._minScore;
            data.task._maxScore += firstCategory._maxScore;
        }
    }
}

function formatTotalScore(data) {
    let scoreSting = '';
    if (data._minScore && data._minScore !== 0) scoreSting += data._minScore + '~';
    if (data._maxScore && data._maxScore !== 0) scoreSting += data._maxScore;
    if (!data._minScore && !data._maxScore) scoreSting = '-';
    if (scoreSting === '') scoreSting = '0';
    return scoreSting.replace(/~$/, '');
}

export default {
    computeSecondaryCategoryLimitScore,
    computeFinalLimitScore,
    computeScore,
    computeSecondaryCategoryScore,
    computeFinalScore,
    getScore,
    formatTotalScore,
};
