// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetTaskHelpsFindBySeekHelpIdByIdParam,
    BaseResponseListTaskHelpFoundResponse,
} from '../../../types';

/**
 * findBySeekHelpId
 *
 * @export
 * @tags task-help-controller
 * @link [GET] /taskHelps/findBySeekHelpId/{id}
 * @param id
 */
export function getTaskHelpsFindBySeekHelpIdById(
    options: ApiOptions<GetTaskHelpsFindBySeekHelpIdByIdParam, never> & {
        segments: GetTaskHelpsFindBySeekHelpIdByIdParam;
    }
): Promise<BaseResponseListTaskHelpFoundResponse> {
    return request<never, GetTaskHelpsFindBySeekHelpIdByIdParam, never>(
        '/taskHelps/findBySeekHelpId/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getTaskHelpsFindBySeekHelpIdById' }
    );
}

export const meta = [
    { tags: ['task-help-controller'], path: '/taskHelps/findBySeekHelpId/{id}', method: 'get' },
];
