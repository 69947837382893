import Udesk from 'Udesk';
import GeneralInspectionReportComponent from "./component";
import Locales from "UdeskLocales";

class GeneralInspectionReportRoute extends GeneralInspectionReportComponent {
    asyncModel(changedParams) {
        let { sdkOptions } = this.props;
        let queryTaskListUrl = Udesk.business.apiPath.concatApiPath(
            `inspectionUniversalReports/queryTaskList`,
            sdkOptions
        );
        let queryTaskListPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(queryTaskListUrl).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let proposalUrl = Udesk.business.apiPath.concatApiPath(
            `inspectionUniversalReports`,
            sdkOptions
        );
        let proposaPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(proposalUrl).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            queryTaskList: queryTaskListPromise,
            proposalList: proposaPromise
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === "queryTaskList") {
            this.privates.taskDataList = [
                {
                    qa_inspection__task_id: -1,
                    qa_inspection__task_name: Locales['current'].fix.allTasks,
                },
                ...asyncResult.data
            ];
            this.actions.update();
        } else if (asyncKey === "proposalList") {
            this.privates.proposalData = asyncResult.data.map(item => {
                if (item.inspectionRemark) {
                    if (item.inspectionRemark.split('\n')) {
                        item.inspectionRemark = item.inspectionRemark.split('\n');
                    }

                }
                return item;
            });
        }
    }
}
export default GeneralInspectionReportRoute;
