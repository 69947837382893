// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetInspectionPointsInlinePointBySourceParam,
    BaseResponseListInspectionInlinePointConfig,
} from '../../../types';

/**
 * getIntelligentInlinePoint
 *
 * @export
 * @tags 质检点
 * @link [GET] /inspectionPoints/inlinePoint/{source}
 * @param source
 */
export function getInspectionPointsInlinePointBySource(
    options: ApiOptions<GetInspectionPointsInlinePointBySourceParam, never> & {
        segments: GetInspectionPointsInlinePointBySourceParam;
    }
): Promise<BaseResponseListInspectionInlinePointConfig> {
    return request<never, GetInspectionPointsInlinePointBySourceParam, never>(
        '/inspectionPoints/inlinePoint/{source}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getInspectionPointsInlinePointBySource',
        }
    );
}

export const meta = [
    { tags: ['质检点'], path: '/inspectionPoints/inlinePoint/{source}', method: 'get' },
];
