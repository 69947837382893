import Udesk from 'Udesk';
import BusinessAnalysisComponent from './component';
import _isEmpty from 'lodash-es/isEmpty';
import { getBusinessAnalysisConditionConfigsById } from 'src/api/businessAnalysisConditionConfigs/{id}';

class BusinessAnalysisRoute extends BusinessAnalysisComponent {
    asyncModel(changedParams) {
        let {
            sdkOptions,
            match
        } = this.props;
        let { pageSize, pageNum } = this.state;
        let { keyword } = this.privates;

        let params = {
            pageNum,
            pageSize,
            keyword,
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }
        let searchTemplate = '';
        if ([1, 2, 5].includes(this.privates.storages.dataSourceType)) {
            let searchTemplateUrl = Udesk.business.apiPath.concatApiPath(`search-template/advanced`, sdkOptions);
            let serachTemplateData = {
                dataFieldSourceList: ['a', 'pr'],
                fieldIdList: ['f_3', 'f_10'],
                excludeFieldSourceList: [],
                excludeFieldIdList: [],
                type: this.privates.storages.dataSourceType,
            };
            searchTemplate = new Promise((resolve, reject) => {
                Udesk.ajax.post(searchTemplateUrl, serachTemplateData).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        }
        if (
            !_isEmpty(this.privates.storages.data) &&
            this.privates.storages.startTime &&
            this.privates.storages.endTime &&
            (this.privates.storages.dataSourceType ||
                (!this.privates.storages.dataSourceType && this.privates.storages.dataSourceType === 0))
        ) {
            this.actions.fieldSave();
        }
        match.params.id && getBusinessAnalysisConditionConfigsById({
            segments: {
                id: match.params.id
            }
        }).then(resp => {
            if(resp.data){
                const { condition, name } = resp.data;
                if(condition) {
                    const { startTime, endTime, dataSourceType, conditions } = condition;
                    this.privates.storages.startTime = startTime;
                    this.privates.storages.endTime = endTime;
                    this.privates.storages.dataSourceType = dataSourceType;
                    this.privates.storages.data = conditions;
                    this.privates.name = name;
                    // this.privates.storages.colorChangeList = conditions;
                    let colorChangeList = [];
                    let countList = genColorChangeList(conditions, colorChangeList);
                    this.privates.storages.countList = countList;
                    this.privates.storages.colorChangeList = colorChangeList;
                    this.privates.storages.num = getMaxNum(conditions);
                    this.actions.update();
                    this.privates.formInstance?.current?.resetFields?.();
                    this.actions.fieldSave();
                }
            }
        });
        return {
            // fieldList,
            // correlationList,
            // conversionList,
            // searchData,
            searchTemplate,
        };
    }
    parseAsyncModel(asyncModel) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'searchTemplate') {
            this.privates.searchTemplate = asyncResult.filter((item) => item.status);
            
            this.actions.update();
        }
    }
}

export default BusinessAnalysisRoute;

function genColorChangeList(conditions, colorChangeList = [], countList = 0) {
    let ret = conditions.length;
    conditions.forEach(condition => {
        colorChangeList.push({id: parseInt(condition.num, 10), color: condition.color});
        if(Array.isArray(condition.children)){
            ret += genColorChangeList(condition.children, colorChangeList, condition.children.length);
        }
    });
    return ret;
}
function getMaxNum(conditions) {
    let ret = 0;
    let stack = [...conditions];
    while(stack.length){
        let condition = stack.shift();
        if(Array.isArray(condition.children)){
            Array.prototype.push.apply(stack, condition.children);
        }
        ret = Math.max(parseInt(condition.num, 10), ret);
    }
    return ret;
}