import React from "react";

export const Text = React.memo(({children}) => {
    if (Array.isArray(children)) {
        return children.map(item => <Text>{item}</Text>);
    }

    if ($.isPlainObject(children)) {
        const {children: childNode, className, component: Component, props} = children as any;

        if (Component) {
            return <Component {...props} className={className}>{childNode}</Component>;
        } else {
            if (className) {
                return <span {...props} className={className}>{childNode}</span>;
            }
        }

        return childNode;
    }

    return children;
});
