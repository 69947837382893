// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostAiModelsTrainingBody, BaseResponseAiModelTrainingResponse } from '../../types';

/**
 * training
 *
 * @export
 * @tags ai-model-controller
 * @link [POST] /aiModels/training
 */
export function postAiModelsTraining(
    data: PostAiModelsTrainingBody
): Promise<BaseResponseAiModelTrainingResponse>;
export function postAiModelsTraining(
    data: PostAiModelsTrainingBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseAiModelTrainingResponse>;
export function postAiModelsTraining(
    data: PostAiModelsTrainingBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseAiModelTrainingResponse> {
    return request<PostAiModelsTrainingBody, never, never>('/aiModels/training', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postAiModelsTraining',
    });
}

export const meta = [{ tags: ['ai-model-controller'], path: '/aiModels/training', method: 'post' }];
