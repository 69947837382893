import React, { useState, useEffect, useImperativeHandle } from 'react';
// import Locales from 'UdeskLocales';
import styled from 'styled-components';
import { FormBuilder, Icon, Input, Select, Space } from 'udesk-ui';
import UdeskList from 'Component/common/udesk-list';
import SemanticTagsSelect from 'Component/pages/components/semantic-tags-select';
import UdeskLocales from 'UdeskLocales';
import AiTextArea from 'src/pages/coach/course-management/flow/components/AiTextArea';

const ScoringRuleItemWrap = styled.div`
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    .udesk-qa-ui-form-item-control-input-content {
        position: relative;
        .logical-operator-select {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-110%, -112%);
            width: 60px;
            display: none;
            & .show {
                display: block;
            }
        }
    }
    .udesk-qa-ui-space-item {
        > .udesk-qa-ui-form-item {
            margin-bottom: 0;
        }
    }
    > div {
        background-color: rgba(0, 0, 0, 0.03);
        padding: 8px;
    }
`;

export const ScoringRuleItem = React.memo(
    React.forwardRef((props: any, ref) => {
        // const locales = Locales['current'];
        const [showLogicalOperator, setShowLogicalOperator] = useState(
            (props.rule?.conditionList?.length ?? 0) > 1
        );
        const [form] = FormBuilder.useForm();
        // useEffect(() => {
        //     props.registerScoringRuleItemForm?.(form);
        // }, []);

        useEffect(() => {
            setShowLogicalOperator((props.rule?.conditionList?.length ?? 0) > 1);
        }, [props.rule.conditionList?.length]);

        useImperativeHandle(ref, () => {
            return {
                form,
                id: props.id,
            };
        });

        return (
            <ScoringRuleItemWrap>
                {/* <div style={{ marginBottom: 8 }}>
                <Input style={{ width: 200 }} />
            </div> */}
                <div>
                    <FormBuilder
                        form={form}
                        customComponents={{ AiTextArea, ScoringRuleItemIf }}
                        initialValues={props}
                        footerRender={false}
                        fields={[
                            {
                                type: 'Input',
                                name: 'name',
                                rules: [
                                    {
                                        required: true,
                                        message:
                                            UdeskLocales['current'].components.coach.scoringRules
                                                .components.scoringRuleItem.pleaseEnterARuleName,
                                    },
                                ],
                                props: {
                                    placeholder:
                                        /* 请输入规则名称 */ UdeskLocales['current'].components
                                            .coach.scoringRules.components.scoringRuleItem
                                            .pleaseEnterARuleName,
                                    style: {
                                        width: 200,
                                    },
                                },
                            },
                            props.showWords && {
                                label: /* 标准话术 */ UdeskLocales['current'].components.coach
                                    .scoringRules.components.scoringRuleItem.standardScript,
                                type: 'AiTextArea',
                                name: 'words',
                            },
                            {
                                type: 'ScoringRuleItemIf',
                                name: ['rule', 'conditionList'],
                                label: /* 如果 */ UdeskLocales['current'].components.coach
                                    .scoringRules.components.scoringRuleItem.if,
                                props: {
                                    onChange: (conditionList) => {
                                        setShowLogicalOperator(conditionList.length > 1);
                                    },
                                    readOnly: props.readOnly,
                                },
                                addonBefore: (
                                    <FormBuilder.Item name={['rule', 'logicalOperator']}>
                                        <Select
                                            className={`${
                                                showLogicalOperator ? 'show' : ''
                                            } logical-operator-select`}
                                            options={[
                                                {
                                                    value: '&&',
                                                    label: /* 且 */ UdeskLocales['current']
                                                        .components.coach.scoringRules.components
                                                        .scoringRuleItem.and,
                                                },
                                                {
                                                    value: '||',
                                                    label: /* 或 */ UdeskLocales['current']
                                                        .components.coach.scoringRules.components
                                                        .scoringRuleItem.or,
                                                },
                                            ]}
                                        ></Select>
                                    </FormBuilder.Item>
                                ),
                            },
                            {
                                type: 'InputNumber',
                                name: 'score',
                                label: /* 则得分 */ UdeskLocales['current'].components.coach
                                    .scoringRules.components.scoringRuleItem.thenScore,
                                props: {
                                    placeholder:
                                        /* 请输入分值 */ UdeskLocales['current'].components.coach
                                            .scoringRules.components.scoringRuleItem
                                            .pleaseEnterAScore,
                                    min: 0,
                                },
                                addonBefore: (
                                    <FormBuilder.Item name="operator">
                                        <Select
                                            style={{ width: 150 }}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: /* 加分 */ UdeskLocales['current']
                                                        .components.coach.scoringRules.components
                                                        .scoringRuleItem.bonusPoints,
                                                },
                                                {
                                                    value: 2,
                                                    label: /* 减分 */ UdeskLocales['current']
                                                        .components.coach.scoringRules.components
                                                        .scoringRuleItem.minusPoints,
                                                },
                                            ]}
                                        ></Select>
                                    </FormBuilder.Item>
                                ),
                            },
                        ].filter(Boolean)}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 19 }}
                    />
                </div>
            </ScoringRuleItemWrap>
        );
    })
);
const newScoringRuleItemIf = {
    operator: 'hit',
    conditionType: 1,
    matchOperator: 'all',
};
function ScoringRuleItemIf(props) {
    const { value = [{}], onChange, readOnly } = props;
    const onItemChange = (index, changedValue) => {
        let newValue = [...value];
        newValue[index] = {
            ...newValue[index],
            ...changedValue,
        };
        onChange?.(newValue);
    };
    return (
        <React.Fragment>
            <UdeskList
                readOnly={readOnly}
                deleteItem={(index) => {
                    let newValue = [...value];
                    newValue[index] = null;
                    onChange?.(newValue.filter(Boolean));
                }}
                addItem={() => {
                    onChange?.([
                        ...value,
                        {
                            ...newScoringRuleItemIf,
                            idCode: value.length
                                ? `R${
                                      parseInt(
                                          value[value.length - 1].idCode.replace('R', ''),
                                          10
                                      ) + 1
                                  }`
                                : 'R1',
                        },
                    ]);
                }}
                deleteBtnText={
                    <Icon
                        type="MinusCircleFilled"
                        antdIcon={true}
                        style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                    />
                }
            >
                {value.map((item, index) => {
                    return (
                        <ScoringRuleItemIfItem
                            {...item}
                            onChange={onItemChange.bind(null, index)}
                        ></ScoringRuleItemIfItem>
                    );
                })}
            </UdeskList>
            {/* {value.length > 1 && (
                <div className="logical-operator-select">
                    <Select
                        value={logicalOperator}
                        onChange={}
                        options={[
                            {
                                value: '&&',
                                label: '且',
                            },
                            {
                                value: '||',
                                label: '或',
                            },
                        ]}
                    ></Select>
                </div>
            )} */}
        </React.Fragment>
    );
}
(ScoringRuleItem as any).newScoringRuleItemIf = newScoringRuleItemIf;
function ScoringRuleItemIfItem(props) {
    return (
        <Space>
            <Select
                value={props.operator}
                options={[
                    {
                        label: /* 命中 */ UdeskLocales['current'].components.coach.scoringRules
                            .components.scoringRuleItem.hit,
                        value: 'hit',
                    },
                    {
                        label: /* 未命中 */ UdeskLocales['current'].components.coach.scoringRules
                            .components.scoringRuleItem.misses,
                        value: 'not_hit',
                    },
                ]}
                onChange={(v) => {
                    props.onChange({ operator: v });
                }}
            ></Select>
            <Select
                value={props.conditionType}
                options={[
                    {
                        label: /* 关键词 */ UdeskLocales['current'].components.coach.scoringRules
                            .components.scoringRuleItem.keyWord,
                        value: 1,
                    },
                    {
                        label: /* 语义 */ UdeskLocales['current'].components.coach.scoringRules
                            .components.scoringRuleItem.semantics,
                        value: 2,
                    },
                ]}
                onChange={(v) => {
                    props.onChange({ conditionType: v });
                }}
            ></Select>
            {props.conditionType === 1 && (
                <>
                    <Select
                        value={props.matchOperator}
                        options={[
                            {
                                label: /* 全部 */ UdeskLocales['current'].components.coach
                                    .scoringRules.components.scoringRuleItem.whole,
                                value: 'all',
                            },
                            {
                                label: /* 任意 */ UdeskLocales['current'].components.coach
                                    .scoringRules.components.scoringRuleItem.arbitrarily,
                                value: 'any',
                            },
                        ]}
                        onChange={(v) => {
                            props.onChange({ matchOperator: v });
                        }}
                        style={{ width: 80 }}
                    ></Select>
                    <Input
                        value={props.keyword}
                        onChange={(v) => {
                            props.onChange({ keyword: v.target.value });
                        }}
                    />
                </>
            )}
            {props.conditionType === 2 && (
                <SemanticTagsSelect
                    showStandardSentence={false}
                    onChange={(v) => {
                        props.onChange({
                            keyword: v.groupId,
                            semanticName: v.semanticName,
                            sentence: v.sentence,
                        });
                    }}
                    value={{
                        semanticName: props.semanticName,
                        sentence: props.sentence,
                        groupId: props.keyword,
                        key: props.keyword,
                    }}
                />
                // <Select
                //     value={props.keyword}
                //     options={[
                //         { label: '关键词', value: 1 },
                //         { label: '语义', value: 2 },
                //     ]}
                // onChange={(v) => {
                //     props.onChange({ keyword: v });
                // }}
                // ></Select>
            )}
        </Space>
    );
}
