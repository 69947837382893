// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { GetUsersBySystemModuleActiveRecordParam, BaseResponseVoid } from '../../../../types';

/**
 * activeModule
 *
 * @export
 * @tags 用户
 * @link [GET] /users/{systemModule}/active/record
 * @param systemModule
 */
export function getUsersBySystemModuleActiveRecord(
    options: ApiOptions<GetUsersBySystemModuleActiveRecordParam, never> & {
        segments: GetUsersBySystemModuleActiveRecordParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, GetUsersBySystemModuleActiveRecordParam, never>(
        '/users/{systemModule}/active/record',
        { ...options, method: 'get', __$requestCalleeName: 'getUsersBySystemModuleActiveRecord' }
    );
}

export const meta = [
    { tags: ['用户'], path: '/users/{systemModule}/active/record', method: 'get' },
];
