import React, { useEffect, useState, useMemo, useContext } from 'react';
import Udesk from 'Udesk';

import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';

import Locales from 'UdeskLocales';
import { Button, Popconfirm, Space, Switch } from 'udesk-ui';
import { Context } from '../real-time-analysis';


interface PageContext {
    pageNum: number;
    pageSize: number;
    savePageInfo: ({ pageNum, pageSize }: { pageNum: number; pageSize: number }) => void;
}
function ClientManage(props) {
    const locales = Locales['current'];

    const { pageNum: defaultPageNum, pageSize: defaultPageSize, savePageInfo } = useContext<PageContext>(Context as any);
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [pageNum, setPageNum] = useState(defaultPageNum);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [refresh, needRefresh] = useState(1);
    const [statusSwitchLoadingMap, setStatusSwitchLoadingMap] = useState({});
    const statusChange = (item, index) => {
        let url = Udesk.business.apiPath.concatApiPath(`inspectionScenes/${item.id}/on-off`, props.sdkOptions);
        setStatusSwitchLoadingMap(prev => ({...prev, [item.id]: true}));
        Udesk.ajax.put(url, {status: dataSource[index].status ? 0 : 1}).then(
            (resp) => {
                setStatusSwitchLoadingMap(prev => ({...prev, [item.id]: false}));
                let newData = [...dataSource];
                newData[index].status = !newData[index].status;        
                setDataSource(newData);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const goToEdit = (item) => {
        savePageInfo?.({
            pageNum: pageNum,
            pageSize: pageSize,
        });
        let routeOptions = {
            history: props.history,
            routeName: 'wechatQaRealTimeAnalysisDetail',
            pathParams: {
                id: item.id,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const deleteItem = (item) => {
        setLoadingDelete(true);
        let url = Udesk.business.apiPath.concatApiPath(`inspectionScenes/${item.id}`, props.sdkOptions);
        Udesk.ajax.del(url).then(
            (resp) => {
                setLoadingDelete(false);
                needRefresh(prev => prev + 1);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoadingDelete(false);
            }
        );
    };
    useEffect(() => {
        
        
    }, []);
    const columns = useMemo(() => {
        return [
            {
                key: 'name',
                dataIndex: 'name',
                title: locales.pages.wechatQa.realTimeAnalysis.table.name,
                width: '10%',
            },
            {
                key: 'pointNum',
                dataIndex: 'pointNum',
                title: locales.pages.wechatQa.realTimeAnalysis.table.count,
            },
            {
                key: 'initialScore',
                dataIndex: 'initialScore',
                title: locales.pages.wechatQa.realTimeAnalysis.table.baseScore,
                width: '10%',
            },
            {
                key: 'qualifiedScore',
                dataIndex: 'qualifiedScore',
                title: locales.pages.wechatQa.realTimeAnalysis.table.passScore,
                width: '10%',
            },
            {
                key: 'scoreUpperLimit',
                dataIndex: 'scoreUpperLimit',
                title: locales.pages.wechatQa.realTimeAnalysis.table.maxScore,
                width: '10%',
            },
            {
                key: 'scoreLowerLimit',
                dataIndex: 'scoreLowerLimit',
                title: locales.pages.wechatQa.realTimeAnalysis.table.minScore,
                width: '10%',
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: locales.pages.wechatQa.realTimeAnalysis.table.status,
                width: '10%',
                render: (name, item, index) => {
                    return (
                        <div>
                            <Switch
                                loading={statusSwitchLoadingMap[item.id]}
                                checked={Boolean(item.status)}
                                onChange={statusChange.bind(null, item, index)}
                                size='small'
                            />
                        </div>
                    );
                },
            },
            {
                key: 'actions',
                dataIndex: 'actions',
                title: locales.columns.tasks.manage.template.checkPoint.actions,
                width: '18%',
                align: 'center' as any,
                render: (name, item, index) => {
                    return (
                        <div>
                            <Space>
                                {/* {hasFeature && hasFeature('task:template:point:edit') && ( */}
                                <Button type='link' size='small' onClick={goToEdit.bind(null, item)}>
                                    {locales.labels.edit}
                                </Button>
                                {/* )} */}
                                {/* {hasFeature && hasFeature('task:template:point:delete') && ( */}
                                <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={deleteItem.bind(null, item)}
                                    onCancel={(e) => e!.stopPropagation()}
                                    okText={locales.labels.confirm}
                                    okButtonProps={{
                                        loading: loadingDelete,
                                    }}
                                    placement='topRight'
                                    cancelText={locales.labels.cancel}
                                >
                                    <Button danger type='link' size='small'>
                                        {locales.labels.delete}
                                    </Button>
                                </Popconfirm>
                                {/* )} */}
                            </Space>
                        </div>
                    );
                },
            },
        ];
    }, [dataSource, statusSwitchLoadingMap]);

    const tableProps = useMemo(() => {
        return {
            columns,
            dataSource,
            subtractedHeight: 136,
        };
    }, [columns, dataSource]);
    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPageNum(page);
                setPageSize(pageSize);
            }
        };
    }, [pageNum, pageSize, total]);

    const createBtn = useMemo(() => {
        const a = () => {
            let routeOptions = {
                history: props.history,
                routeName: 'wechatQaRealTimeAnalysisNew',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
            savePageInfo?.({
                pageNum,
                pageSize,
            });
        };
        return <Button onClick={a}>{locales.labels.create}</Button>;
    }, []);

    useEffect(() => {
        let url = Udesk.business.apiPath.concatApiPath(`inspectionScenes`, props.sdkOptions);
        setLoading(true);
        Udesk.ajax.get(url, {pageNum, pageSize}).then(
            (resp) => {
                setLoading(false);
                setDataSource(resp.data);
                if(resp.paging){
                    const {pageNum, pageSize, total} = resp.paging;
                    setPageNum(pageNum);
                    setPageSize(pageSize);
                    setTotal(total);
                }
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [pageNum, pageSize, refresh]);
    return (
        <Page
            pageBodyClassName='real-time-analysis-page-index'
            title={locales.pages.wechatQa.realTimeAnalysis.title}
            padding={true}
        >
            <CommonTablePage loading={loading} mainButton={createBtn}>
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
        </Page>
    );
}

export default React.memo(ClientManage);
