export default {
  lang: {
    num: 'UU No.',
    total: 'Strip/Total',
    strip: 'Strip',
    more: 'Lebih banyak',
    chosen: 'Telah dipilih',
    cancel: 'Batalkan pilihan',
    bulkOperations: 'Operasi massal',
    import: 'Impor',
  },
};
