const STATIC_REDUX_KEY_SYMBOL = Symbol("[StaticReduxKey]");
const INSTANCE_REDUX_KEY_SYMBOL = Symbol("[InstanceReduxKey]");

export default {
    STATIC_REDUX_KEY_SYMBOL,
    INSTANCE_REDUX_KEY_SYMBOL,
};
export {
    STATIC_REDUX_KEY_SYMBOL,
    INSTANCE_REDUX_KEY_SYMBOL,
};