/*
 * @Author: 唐毓斌
 * @Description: 任务详情
 */
import React, { useEffect, useState } from 'react';
import Page from 'Component/common/udesk-qa-page-layout'; 
import Udesk from 'Udesk';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import SimplePieChart from './components/SimplePieChart';
import GaueChart from './components/GaueChart';
import { Button } from 'udesk-ui';

import OrganizationMemberSelect from '../../../components/OrganizationMemberSelect';
import { useCoachUserTreeDataWithPermit } from 'src/util/hook/user';
import './index.less';
// import { useRequest } from 'src/util/hook';
import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import { postIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskId } from 'src/api/intelligentPartnerTaskInfos/emotions/analysis/record/{taskId}';


const TaskCenterDetail: React.FC<any> = (props) => {
    const locales = UdeskLocales['current'];
    const { history, match } = props;
    // console.log('props',props);
    // const request = useRequest();
    const [taskInfo, setTaskInfo] = useState<any>();
    const [taskInfoExtra, setTaskInfoExtra] = useState<any>({});
    const [loading, setLoading] = useState(false);

    // --筛选start
    const [userSelectObj, setUserSelectObj] = useState<any>({
        userIds: [],
        organizationIds: []
    });
    const { treeData } = useCoachUserTreeDataWithPermit();
    const treeDataChange = (data) => {
        // console.log(data);
        setUserSelectObj(data);
    };
    const [ confirmFilterParams, setConfirmFilterParams] = useState<any>({
        userSelectObj: {
            userIds: [],
            organizationIds: []
        }
    });
    const filterConfirm = () => {
        // console.log('confirm',userSelectObj);
        setConfirmFilterParams({
            userSelectObj
        });
    };
    const filterReset = () => {
        setConfirmFilterParams({
            userSelectObj: {
                userIds: [],
                organizationIds: []
            }
        });
        setUserSelectObj({
            userIds: [],
            organizationIds: []
        });
    };
    // --筛选end

    const onBack = () => {
        const routeName = 'coachTaskCenterList';
        Udesk.ui.routing.transitionTo({
            history,
            routeName,
        });
    };

    const goToDetail = () => {
        const routeName = 'coachLearningCenterDetailTaskDetail';
        // console.log(props);
        const pathParams = {
            id: props.match.params.id,
        };
        const queryParams =  {
            tab: 'numberData'
        };
        Udesk.ui.routing.transitionTo({
            history,
            pathParams,
            queryParams,
            routeName
        });
    };

    const init = () => {
        const id = match?.params?.id || '0';
        if (id !== '0') {
            setLoading(true);
            getIntelligentPartnerTaskInfosById({
                segments: { id },
            })
                .then((resp) => {
                    let values: any = {
                        ...resp?.data,
                    };
                    //画像
                    values.imageIdInfo = {
                        status: 2,
                    };
                    if (values.imageId != null) {
                        values.imageIdInfo.status = 1;
                        values.imageIdInfo.selectedItemId = values.imageId;
                    }
                    // 时长限制
                    values.limitDurationInfo = {
                        status: 1,
                    };
                    if (values.limitDuration != null) {
                        values.limitDurationInfo.status = 2;
                        values.limitDurationInfo.inputValue = values.limitDuration;
                    }
                    // 时间
                    values.taskTimeInfo = {
                        status: 1,
                        viewText:
                            /* 不限时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .index.unlimitedTime,
                    };
                    if (values.taskStartTime && values.taskStartTime) {
                        values.taskTimeInfo.status = 2;
                        values.taskTimeInfo.viewText =
                            moment(values.taskStartTime).format('YYYY-MM-DD HH:mm') +
                            /* 至 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .to +
                            moment(values.taskEndTime).format('YYYY-MM-DD HH:mm');
                    }
                    // 评分标准
                    values.gradingStandartInfo = {
                        commitScore: values.commitScore,
                        excellentScore: values.excellentScore,
                    };
                    // 完成要求
                    values.completeStandartInfo = {};
                    if (values.completeByTimes != null) {
                        values.completeStandartInfo.timesStatus = true;
                        values.completeStandartInfo.completeByTimes = values.completeByTimes;
                    }
                    if (values.completeByScore != null) {
                        values.completeStandartInfo.scoreStatus = true;
                        values.completeStandartInfo.completeByScore = values.completeByScore;
                    }
                    setTaskInfo(values);
                    // getTaskScore(values);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });

            // const reqData = {
            //     taskId: id,
            // };
            // postIntelligentPartnerTaskInfosStatisticsFindAll(reqData)
            let params = {
                ...confirmFilterParams.userSelectObj
            };
            postIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskId(params, {segments: {taskId: id}})
            // request('/intelligentPartnerTaskInfos/emotions/analysis/record/'+ id, params, 'post')
            .then((resp) => {
                // const currentItem = (resp?.data || []).find((item) => item.id + '' === id);
                if (resp?.data) {
                    setTaskInfoExtra(resp?.data || {});
                }
            });
        }
    };

    useEffect(() => {
        init();
    }, [confirmFilterParams.userSelectObj]);

    return (
        <Page
            pageBodyClassName="task-center-detail-container"
            backGroundGhost={true}
            title={`${taskInfo?.taskName || ''}-${
                taskInfo?.taskType === 1
                    ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                          .practice
                    : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                          .examination
            }`}
            onBack={onBack}
        >
                    <div className="tcd-header-wrapper">
                        <div className="tcd-header-filter-box" style={{background: '#fff'}}>
                            <div className="tcd-header-filter-box-left">
                                {UdeskLocales['current'].pages.coach.taskCenter.detail.index.staff}：
                                <OrganizationMemberSelect 
                                    placeholder={locales.labels.pleaseSelect}
                                    width={200}
                                    value={userSelectObj}
                                    onChange={treeDataChange}
                                    treeData={treeData}
                                />
                            </div>
                            <div className="tcd-header-filter-box-right">
                                <Button onClick={filterReset}>{locales.labels.reset}</Button>
                                <Button type="primary" onClick={filterConfirm}>{locales.labels.confirm}</Button>
                            </div>
                        </div>
                        </div>
                            <div className="tcd-header-wrapper">
                                <div className="tcd-header-title">
                                    <p> 
                                        {
                                            /* 累计 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .accumulative
                                        }
                                        {
                                            taskInfo?.taskType === 1
                                                ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .practice
                                                : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .examination
                                        }
                                        {
                                            /* 次数 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .frequency
                                        }
                                        <span style={{color: '#5B8FF9'}}>{taskInfoExtra.practiceCount || '-'}</span>
                                        {
                                            /* 次 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .times
                                        }
                                    </p>
                                    <p>
                                        {
                                            /* 累计 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .accumulative
                                        }
                                        {
                                            taskInfo?.taskType === 1
                                                ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .practice
                                                : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .examination
                                        }
                                        {
                                            /* 话术 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .script
                                        }
                                        <span style={{color: '#5B8FF9'}}>{taskInfoExtra.sentenceCount || '-'}</span>
                                        {
                                            /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .sentence
                                        }
                                    </p>
                                </div>
                                <div className="tcd-header-echarts no-border">
                                    <p className="echarts-title">
                                        {
                                            /* 情绪得分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                .emotionalScore
                                        }
                                    </p>
                                    <p className="echarts-title">
                                        {
                                            /* 情绪分布 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                .emotionalDistribution
                                        }
                                    </p>
                                </div>
                                <div className="tcd-header-echarts">
                                        <GaueChart
                                            id="executeRateChart11122"
                                            // width='calc(25% - 8px)'
                                            loading={loading}
                                            height={360}
                                            subTitle={
                                                /* 平均表达分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .implementationRate
                                            }
                                            maxValue={taskInfoExtra.maxEmotionScore || 100}
                                            title={(taskInfoExtra.executeRate?taskInfoExtra.executeRate: 0) + '%'}
                                            data={{value: taskInfoExtra.avgEmotionScore}}
                                        />
                                        <SimplePieChart
                                            id="executeRateChart1677"
                                            // width='calc(25% - 8px)'
                                            loading={loading}
                                            height={300}
                                            subTitle={' '}
                                            extraData={taskInfoExtra.negativeEmotionsList || []}
                                            title={
                                                taskInfoExtra.sentenceCount ? 
                                                ((taskInfoExtra.negativeEmotionsCount/ 
                                                taskInfoExtra.sentenceCount)* 100).toFixed(2)+ '%' : ' '}
                                            textFontSize={32}
                                            colorArr={['#5B8FF9', '#5AD8A6']}
                                            data={[
                                                {
                                                    name: /* 负向情绪 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .negativeEmotion + taskInfoExtra.negativeEmotionsCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.negativeEmotionsCount,
                                                },
                                                {
                                                    name: /* 正常情绪 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .normalEmotion + taskInfoExtra.normalNegativeEmotionsCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.normalNegativeEmotionsCount,
                                                },
                                            ]}
                                        />
                                </div>
                                <div className="go-to-etail" onClick={goToDetail}>
                                    {
                                        /* 查看详情 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                        .getDetail
                                    }
                                </div>
                            </div>
                        {/* </Tabs.TabPane>
                    </Tabs> */}
        </Page>
    );
};

export default hookProps(TaskCenterDetail);
