import React from 'react';
import Udesk from 'Udesk';
import _find from 'lodash-es/find';
import _isEmpty from 'lodash-es/isEmpty';
class AudioTextViewContentComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        inspectDataSource: null,
        item: null,
        type: null,
        content: null,
        markSemanticVisible: true,
        caseBaseFlag: false,
    };
    //#endregion

    state = {};
    privates = {
        fields: true,
        inputStatus: true,
        caseBaseFlag: false,
        addFlag: false,
        callFields: '',
        inputValue: '',
        statustCase: true,
        averageFlag: false,
        vedioDom: React.createRef(),
    };
    static computes = {};
    actions = {
        sendCurrentTimeRange(item) {
            this.trigger('onTimeRangeChanged', item);
        },
        markSemantic(content) {
            this.trigger('onMarkSemanticClick', content);
        },
        caseBaseAdd(id) {
            let { caseBaselist } = this.props;
            this.privates.fields
                ? caseBaselist({ id: id, flag: true, inputValue: this.privates.callFields })
                : caseBaselist({ id: id, flag: false });
            this.privates.fields = !this.privates.fields;
            this.privates.statustCase = false;
            this.privates.addFlag = !this.privates.addFlag;
            this.actions.update();
        },
        fieldsChange() {
            let { inputFlag, item } = this.props;
            inputFlag && inputFlag({ id: item.data.id, flag: false });
            this.privates.inputStatus = false;
            this.actions.update();
        },
        closeFields() {
            let { inputFlag, item } = this.props;
            this.privates.inputValue = this.privates.callFields;
            this.privates.inputStatus = true;

            inputFlag && inputFlag({ id: item.data.id, flag: true });
            this.actions.update();
        },
        onOkFields(id) {
            let { inputFlag, item, caseBaselist } = this.props;
            this.privates.callFields = this.privates.inputValue || this.privates.callFields;
            this.privates.inputStatus = true;
            if (!this.privates.fields) {
                caseBaselist({ id: id, flag: true, inputValue: this.privates.callFields });
            }
            inputFlag && inputFlag({ id: item.data.id, flag: true });
            this.actions.update();
        },
        inputEdit(e) {
            this.privates.inputValue = e.target.value;
        },
        onEditorChange(value) {
            this.privates.inputValue = value;
        },
        averageChange(flag) {
            this.privates.averageFlag = flag;
            this.actions.update();
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        if (props.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id) {
            if (
                props.fieldsList &&
                _find(props.fieldsList.dialogText, (current) => {
                    return current.id === props.item.data.id;
                })
            ) {
                let obj = _find(props.fieldsList.dialogText, (current) => {
                    return current.id === props.item.data.id;
                });
                return {
                    callFields: obj.text,
                    addFlag: true,
                    fields: false,
                };
            } else if (
                props.fieldsList &&
                _isEmpty(props.fieldsList.dialogText) &&
                props.fieldsList.id &&
                props.fieldsList.libraryId
            ) {
                return {
                    callFields: props.item.states.text,
                    addFlag: true,
                    fields: false,
                };
            } else {
                return {
                    callFields: props.item.states.text,
                    addFlag: false,
                    fields: true,
                };
            }
        }
        if (props.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id) {
            if (
                props.fieldsList &&
                _find(props.fieldsList.dialogText, (current) => {
                    return current.id === props.item.data.id;
                })
            ) {
                let obj = _find(props.fieldsList.dialogText, (current) => {
                    return current.id === props.item.data.id;
                });
                let text = JSON.parse(obj.text);
                return {
                    callFields: text.data.content,
                    addFlag: true,
                    fields: false,
                };
            } else if (
                props.fieldsList &&
                _isEmpty(props.fieldsList.dialogText) &&
                props.fieldsList.id &&
                props.fieldsList.libraryId
            ) {
                return {
                    callFields: props.content,
                    addFlag: true,
                    fields: false,
                };
            } else {
                return {
                    callFields: props.content,
                    addFlag: false,
                    fields: true,
                };
            }
        }
    }
    componentDidMount() {}
    componentDidUpdate(prevProps, prevState, snapshot) {}
    componentWillUnmount() {}
    asyncModel(changedParams) {}
    //#endregion
}

export default AudioTextViewContentComponent;
