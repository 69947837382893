// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewCallOpportunityByCallIdParam,
    BaseResponseDataCallOpportunity,
    PutReviewCallOpportunityByCallIdParam,
    PutReviewCallOpportunityByCallIdBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 查询机会点
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/opportunity/{callId}
 * @param callId
 */
export function getReviewCallOpportunityByCallId(
    options: ApiOptions<GetReviewCallOpportunityByCallIdParam, never> & {
        segments: GetReviewCallOpportunityByCallIdParam;
    }
): Promise<BaseResponseDataCallOpportunity> {
    return request<never, GetReviewCallOpportunityByCallIdParam, never>(
        '/review/call/opportunity/{callId}',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewCallOpportunityByCallId' }
    );
}

/**
 * 修改机会点
 *
 * @export
 * @tags 通话记录
 * @link [PUT] /review/call/opportunity/{callId}
 * @param callId
 */
export function putReviewCallOpportunityByCallId(
    data: PutReviewCallOpportunityByCallIdBody,
    options: ApiOptions<PutReviewCallOpportunityByCallIdParam, never> & {
        segments: PutReviewCallOpportunityByCallIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutReviewCallOpportunityByCallIdBody,
        PutReviewCallOpportunityByCallIdParam,
        never
    >('/review/call/opportunity/{callId}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewCallOpportunityByCallId',
    });
}

export const meta = [
    { tags: ['通话记录'], path: '/review/call/opportunity/{callId}', method: 'get' },
    { tags: ['通话记录'], path: '/review/call/opportunity/{callId}', method: 'put' },
];
