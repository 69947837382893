import Udesk from 'Udesk';
import React from 'react';
import DemoIndexRoute from './route';
import InspectionDetail from 'Component/pages/components/inspection-detail';

// import ReactModal from 'udesk-react/src/components/react-modal';
import { Button, Radio, Select, Upload, Modal, Steps, Icon } from 'udesk-ui';
import './style.scss';

const { Step } = Steps;
export default class DemoIndexTemplate extends DemoIndexRoute {
    render() {
        let { actions, locales, props, state, privates } = this;
        return (
            <React.Fragment>
                <div className='quality-inspection-demo'>
                    <div className='demo-header'>
                        <img src={'/static/images/qa-logo.png'} alt='' className='demo-logo' />
                        <span className='demo-title'>{locales.pages.demo.title}</span>
                    </div>
                    <div className='demo-body'>
                        <div className='demo-body-left-react'>
                            <div className='demo-body-left-content'>
                                <div className='demo-body-left-content-title'>{locales.pages.demo.template}</div>
                                <div className='demo-body-left-content-select'>
                                    <Select
                                        value={state.template}
                                        style={{ width: '100%' }}
                                        onChange={actions.changeSelectTemplate}
                                    >
                                        <For each='template' index='index' of={privates.model.templates}>
                                            <Select.Option key={template.id} value={template.id}>
                                                {template.name}
                                            </Select.Option>
                                        </For>
                                    </Select>
                                </div>
                                <div className='demo-body-left-content-title'>{locales.pages.demo.radio}</div>
                                <div className='demo-body-left-content-types'>
                                    <Radio.Group
                                        value={state.voiceType}
                                        onChange={actions.onValueChanged.params('voiceType')}
                                    >
                                        <For each='item' index='index' of={Udesk.enums.voiceDemoTypes}>
                                            <Radio
                                                key={item.id}
                                                value={item.id}
                                                style={{ marginRight: index % 2 ? 0 : 24 }}
                                            >
                                                {item.name}
                                            </Radio>
                                        </For>
                                    </Radio.Group>
                                </div>
                                <div className='demo-body-left-content-upload'>
                                    <Upload
                                        accept='.wav,.mp3'
                                        showUploadList={false}
                                        beforeUpload={(flie) => {
                                            actions.uploadAudio(flie);
                                            return false;
                                        }}
                                    >
                                        <Button style={{ marginBottom: '5px' }}>
                                            {locales.pages.demo.uploadAudio}
                                        </Button>
                                        <p>{locales.pages.demo.uploadAudioTip}</p>
                                    </Upload>
                                </div>
                                <div className='demo-body-left-content-upload-result-tip'>
                                    <Choose>
                                        <When condition={state.uploadStatus === 1}>
                                            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong'></i>
                                            {locales.pages.demo.uploadSuccess}
                                            {state.voiceName}
                                        </When>
                                        <When condition={state.uploadStatus === -1}>
                                            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai'></i>
                                            {locales.pages.demo.uploadFailed}
                                        </When>
                                    </Choose>
                                </div>
                                <div className='demo-body-left-content-upload' style={{ display: 'none' }}>
                                    <Upload
                                        accept='.srt,.txt'
                                        showUploadList={false}
                                        beforeUpload={(flie) => {
                                            actions.uploadText(flie);
                                            return false;
                                        }}
                                    >
                                        <Button style={{ marginBottom: '5px' }}>{locales.pages.demo.uploadText}</Button>
                                        <p>{locales.pages.demo.uploadTextTip}</p>
                                    </Upload>
                                </div>
                                <div className='demo-body-left-content-upload-result-tip' style={{ display: 'none' }}>
                                    <Choose>
                                        <When condition={state.uploadTextStatus === 1}>
                                            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong'></i>
                                            {locales.pages.demo.uploadSuccess}
                                            {state.textName}
                                        </When>
                                        <When condition={state.uploadTextStatus === -1}>
                                            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai'></i>
                                            {locales.pages.demo.uploadFailed}
                                        </When>
                                    </Choose>
                                </div>
                                <Button type='primary' onClick={actions.startQualityInspection} disabled={privates.qualityInspectionStarted} style={{width: '100%', marginTop: 40}}>
                                    {privates.qualityInspectionStarted?locales.pages.demo.qualityInspectionStarted:locales.pages.demo.startQualityInspection}
                                </Button>
                                <Button onClick={actions.downloadText} style={{width: '100%', marginTop: 8}}>
                                    {locales.pages.demo.downloadText}
                                </Button>
                            </div>
                        </div>
                        <div className='demo-body-right-react'>
                            <div className='demo-body-right-content'>
                                <Choose>
                                    <When condition={state.demoResultVisible}>
                                        <InspectionDetail
                                            isDemo={true}
                                            ref={privates.inspectionDetailRef}
                                            relatedDatas={[]}
                                            resetStatus={false}
                                            onQualityCheckScoreSave={actions.qualityCheckScoreSaved}
                                            handMarkMap={actions.handMarkMap}
                                            pointChildrenComputer={{}}
                                            pointChildrenPerson={[]}
                                            caseBaseStatus={true}
                                            onUpdate={actions.onUpdate}
                                            id={state.inspectionData.id}
                                            // caseBase={caseBase}
                                            sdkOptions={props.sdkOptions}
                                            caseLibraryId={props.match.params.caseLibraryId}
                                            taskId={state.template}
                                            hasFeature={null}
                                            detail={state.inspectionData}
                                            smartTags={privates.smartTags}
                                            changeList={[]}
                                            qualityCheckScoreSaving={false}
                                            qualityCheckScoreType={Udesk.enums.qualityCheckDetailTypes.spotCheck.id}
                                            onQualityCheckScoreCancel={actions.spotCheckScoreCanceled}
                                            relatedDatasPaging={{
                                                pageNum: 1,
                                                pageSize: 10
                                            }}
                                            parentLoadMore={actions.parentLoadMore}
                                            relatedLoading={false}
                                            testSetListFlag={true}
                                            disabledSmartTagsChange={true}
                                            showQualityCheckScoreTalk={state.inspectionData.pointCategoryList}
                                        />
                                    </When>
                                    <Otherwise>
                                        <p className='demo-body-right-content-checking-tip'>
                                            {locales.pages.demo.noCheckContent}
                                        </p>
                                    </Otherwise>
                                </Choose>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    wrapClassName='quality-inspection-demo-modal'
                    title={locales.pages.demo.checking}
                    visible={state.modelVisible}
                    closable={false}
                    maskClosable={false}
                    footer={[
                        <Button type='primary' onClick={actions.queryCheckResult} disabled={!state.isComplete && !state.isFailure} loading={privates.queryCheckResultClicked}>
                            {locales.labels.complete}
                        </Button>
                    ]}
                >
                    <div>
                        {
                            privates.inspectionStatusMap && privates.inspectionStatusMap.length ?
                                <Steps size='small' labelPlacement='vertical'>
                                    {
                                        privates.inspectionStatusMap.map(item => {
                                            let uniIcon = <Icon type='ic-warn-jinggao' style={{color: '#e90', fontSize: '24px'}}/>;
                                            let isVolumeFailed = parseInt(item.key,10) === 3 && parseInt(item.value,10) === 4;
                                            return (
                                                <Step
                                                    status={isVolumeFailed ? 'finish' : item.status}
                                                    title={item.title}
                                                    icon={item.status === 'process' ? (<Icon antdIcon={true} type='LoadingOutlined'/>) : isVolumeFailed ? uniIcon : null}
                                                />
                                            );
                                        })
                                    }
                                </Steps>
                                : <div>Loading...</div>
                        }
                    </div>
                    {/* <div style={{textAlign: 'right'}}>
                        
                    </div> */}
                </Modal>
                <If condition={state.iframeUrl}>
                    <iframe src={state.iframeUrl} className='demo-inspection-iframe' title='url'></iframe>
                </If>
            </React.Fragment>
        );
    }
}
