export default {
  labels: {
    clear: "Entleeren",
    refresh: "Aktualisieren",
    nextStep: "Weiter",
    prevStep: "Der letzte Schritt",
    closeDriver: "Verlassen Sie die Anleitung",
    complete: "Fertig",
    viewStatistics: "Statistiken ansehen",
    colon: "：",
    splitter: "/",
    remark: "Bemerkungen",
    correction: "Fehler korrektur",
    save: "Speichern",
    reset: "Zurücksetzen",
    increase: "Hinzufügen",
    preview: "Vorschau",
    unit: "Artikel",
    cancel: "Stornierung",
    all: "Alle",
    searchPlaceHolder: "Schlüssel wort abfrage eingeben",
    greaterThan: "Größer als",
    lessThan: "Weniger als",
    second: "Sekunden",
    description: "Beschreibung",
    column: "Spalte",
    pleaseChoice: "Bitte wählen Sie",
    filter: "Screening",
    saveAndSubmit: "Speichern und einreichen",
    submit: "Einreichung",
    appeal: "Beschwerde",
    review: "Überprüfung",
    choose: "Wählen Sie",
    manage: "Verwaltung",
    delete: "Löschen",
    add: "Neu hinzugefügt",
    create: "Neu",
    edit: "Bearbeiten",
    name: "Name",
    describe: "Beschreibung",
    next: "Weiter",
    percent: "%",
    export: "Exportieren",
    exportAll: "Alle exportieren",
    confirm: "Bestimmt",
    back: "Zurück",
    versionUpdate:
      "Ich freue mich, Ihnen mitteilen zu können, dass unsere Website gerade eine neue Version ver öffentlicht hat! Um Ihren normalen Gebrauch zu gewährleisten, müssen Sie Ihre aktuelle Arbeit sofort speichern und dann die Seite aktualisieren (klicken Sie auf die Schaltfläche Aktualisieren des Browsers oder drücken Sie die Taste F5), damit unsere neue Version wirksam wird. Vielen Dank für die Zusammenarbeit!",
    more: "Mehr",
    selected: "Ausgewählt",
    deduct: "Schnalle",
    maxScore: "Am meisten",
    scoreUnit: "Punkte",
    pointUnit: "Eine",
    comma: "，",
    view: "Anzeigen",
    batchingImport: "Importieren",
    deleteContent:
      "Bestätigen Sie, dass Sie löschen möchten? Nach dem Löschen kann es nicht wieder hergestellt werden",
    import: "Importieren",
    uploadFile: "Dateien hochladen",
    downloadFile: "Datei exportieren",
    upload: "Hochladen",
    click: "Klicken Sie auf",
    downloadTemplate: "Vorlage herunter laden",
    fillInTheForm: "Füllen Sie nach Format aus",
    UploadExcelfile: "Excel hochladen, um den Stapel import abzuschließen",
    uploadIdentical:
      "Wenn der API-Name vorhanden ist, werden die bereits im System vorhandenen Daten beim Import aktualisiert",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Anwendbar",
    leftBracket: "(",
    rightBracket: ")",
    question: "Das Problem",
    answer: "Die Antwort",
    content: "Inhalt",
    search: "Suche",
    query: "Abfrage",
    cancelEdit: "Bearbeiten abbrechen",
    noValue: "Keine Daten",
    selectAll: "Alle ausgewählt",
    changeAGroup: "Eine andere Gruppe",
    arrow: " ---> ",
    saveReview: "Überprüfung und Einreichung",
    saveSpotCheck: "Probenahme einreichen",
    rapidSampling: "Schnelle Probenahme",
    file: "Archivierung",
    download: "Herunter laden",
    lastPage: "Vorherige Seite",
    nextPage: "Nächste Seite",
    batchActions: "Stapel betrieb",
    cancelDistribution: "Zuweisung aufheben",
    againDistribution: "Umverteilung",
    deleteRecord: "Datensätze löschen",
    oneOptionOpertor: "Optionale Qualitäts inspektoren",
    allOptionOpertor: "Alle Qualitäts inspektoren",
    distributionInfoFirstOne: "Bestätigen Sie richtig",
    distributionInfoFirstTwo:
      "Wird der Datensatz neu zugewiesen? Bitte wählen Sie das Ziel zuordnen",
    distributionInfoSecondOne: "Bestimmen Sie zu stornieren",
    distributionInfoSecondTwo:
      "Wird die auf gezeichnete Zuordnung person information nach der Stornierung zu einem nicht zugewiesenen Überprüfung datensatz?",
    distributionInfoSecondThree:
      "Gibt es die auf gezeichneten Zuordnung informationen? Nach der Stornierung wird es zu einem nicht zugewiesenen Stichproben datensatz.",
    distributionInfoThirdOne: "Der ausgewählte Datensatz hat",
    distributionInfoThirdTwo:
      "Für überprüfte Datensätze werden überprüfte Datensätze nicht verarbeitet.",
    distributionInfoThirdThree:
      "Für stichproben artige Aufzeichnungen werden stichproben artige Aufzeichnungen nicht verarbeitet.",
    exportError: "Export fehl geschlagen",
    exportSuccess: "Erfolgreich exportieren",
    deleteRecordInfo:
      "Bestätigen Sie, dass Sie {0} Datensätze löschen möchten? Daten werden nach dem Löschen nicht wieder hergestellt",
    pleaseSetFilter: "Bitte wählen Sie die Filter kriterien",
    noCountAfterFilter:
      "Keine Export daten, bitte setzen Sie die Filter bedingungen zurück",
    redo: "Wiederausführen",
    batchMove: "Massen bewegung",
    websiteName: "Intelligente Qualitäts prüfung",
    salesEnable: "Verkaufs ermächtigung",
    systemName: "Intelligente Qualitäts prüfung",
    initSystemError:
      "Wenn das Netzwerk abnormal ist oder die Seiten initial isierung fehl geschlagen ist, wenden Sie sich bitte an den Kundendienst",
    day: "Tag",
    hour: "Stunden",
    minute: "Punkte",
    questionMark: "？",
    personalSettings: "Persönliche Einstellungen",
    logout: "Abmelden",
    ok: "Bestimmt",
    saveAs: "Speichern unter",
    pleaseEnter: "Bitte geben Sie ein",
    executeMessage: {
      executeSuccess: "Einträge {0} erfolgreich ausführen",
      executeError: "{0} Balken ausführung fehl geschlagen",
    },
    field: "Feld",
    operator: "Operatoren",
    value: "Wert",
    placeholder: "Bitte wählen Sie",
    pleaseSelect: "Bitte wählen Sie",
    totalScore: "Gesamt punktzahl",
    hyphen: "-",
    semicolon: "；",
    wfm: "Intelligente Schicht",
    user: "Benutzer",
    none: "Keine",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Speichern Sie erfolgreich! Relevante Mitarbeiter werden wirksam, nachdem sie die Seite aktualisiert haben",
    saveAsName: "{0}(Kopie)",
    systemInformation: "System daten anzeigen",
    billingCenter: "Abrechnung zentrum",
    warningMessage:
      "Das verbleibende Kontingent ist nicht ausreichend, bitte so schnell wie möglich aufladen",
    fileTypeHolder:
      "Das Dateiformat ist falsch. Bitte laden Sie die Datei im Format {0} hoch",
    dataNo: "Keine Daten",
    move: "Mobile",
    study:
      "Sie haben einen nicht gekennzeichneten selbst lernenden Qualitäts punkt",
    studySuccess: "Erfolgreich markiert",
    studyError: "Kennzeichnung fehl geschlagen",
    editType: "Klassifikation labels bearbeiten",
    copyed: "In die Zwischen ablage kopiert",
    clickToCopy: "Klicken Sie auf Kopieren",
    addFilterConditions: "Filter kriterien hinzufügen",
    filterConditions: "Screening-Bedingungen",
    messageTime: "Nachrichten zeit",
    messageType: "Nachrichten typ",
    pushType: "Nachrichten kanäle",
    messageContent: "Inhalt der Nachricht",
    messageName: "Name der Nachricht",
    messageStatus: "Nachrichten status",
    configuration: "Konfiguration informationen",
    sendTarget: "Ziel senden",
    guideUrl: "Geführte URL",
    noticeDetail: "Nachrichten details",
    action: "Betrieb",
    seeDetail: "Details anzeigen",
    theDay: "{0} Tag",
    theLastDayOfMonth: "Letzter Tag des Monats",
    ignore: "Ignorieren",
    seeNow: "Jetzt ansehen",
    exportConfirmText: {
      export: "Insgesamt {0} Daten, bestätigen Sie den Export?",
      exportAll: "Bestätigen Sie den Export aller Inhalte?",
    },
    samrTagAdd: "Tags hinzufügen",
    deletePopconfirmContent: "Bestätigen Sie, dass Sie löschen möchten?",
    TheLastThirtyDays: "Die letzten 30 Tage",
    addNumber: "Plus",
    subNumber: "Minus",
    addStrategy: "Strategie hinzufügen",
    minutes: "Minuten",
    status: "Status aktivieren",
    pushConfig: "Push-Konfiguration",
    category: "Klassifizierung",
    and: "Mit",
    interval: "Intervalle",
    switchOn: "Öffnen",
    switchOff: "Schließen",
    batchDel: "Massen löschen",
    exportTxt: "Exportieren von TXT-Text",
    playSpeed: "Doppelende Geschwindigkeit",
    messageLogBtn:
      "Anzeigen von Durchsagen/Konversationen, die die Benachricht igung auslösen",
    dataLink: "Daten verknüpfung",
    yes: "Ist",
    no: "Nein",
    sentenceUnit: "Satz",
    appealDetail: "Einzelheiten zum Beschwerde protokoll",
    detail: "Details",
    default: "Standard",
    customer: "Kunden",
    agent: "Kunden service",
    sort: "Sortieren",
    applyNow: "Anwendung",
    index: "Seriennummer",
    allColumns: "Spalten, die hinzugefügt werden können",
    selectedColumns: "Spalten hinzugefügt",
    syn: "Synchron isieren",
    saveAndNext: "Speichern und erstellen Sie den nächsten",
    viewReference: "Zitieren anzeigen",
    referenceDetail: "Details zitieren",
    goToQaList: "Gehen Sie zur Qualitäts prüfung",
    goToCheck: "Gehen Sie zur Probenahme",
    businessCard: "Visitenkarten",
    weapp: "Kleines Programm",
    redpacket: "Rote Umschläge",
    externalRedpacket: "Austausch roter Umschlag",
    sphfeed: "Video nummer",
    exportTaskCreated: "Die Export aufgabe wurde erfolgreich erstellt",
    plzGoTo: "Bitte springen Sie zu",
    importTaskCreated: "Die Import aufgabe wurde erfolgreich erstellt",
    taskCreated: "Aufgabe erfolgreich erstellt",
    reply: "Antwort",
    replied: "Antwortete",
    release: "Veröffentlichen",
    expandMore: "Erweitern Sie andere {0} Antworten",
    comment: "Kommentar",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Bitte haben Sie mindestens eine {0}",
      canNotBeEmptyFormat: "{0} kann nicht leer sein",
      repeatErrorTipFormat: "{0} Es gibt Duplikate",
      getInformationFailedFormat:
        "Wenn Sie die {0}-Informationen nicht abrufen, überprüfen Sie die Netzwerk verbindung oder versuchen Sie es später erneut",
      deleteSuccessFormat: "{0} erfolgreich gelöscht.",
      deleteFailedFormat:
        "{0} Das Löschen ist fehl geschlagen. Bitte versuchen Sie es später erneut.",
      saveSuccessFormat: "{0} erfolgreich gespeichert.",
      saveFailedFormat:
        "{0} Speichern fehl geschlagen, bitte versuchen Sie es später erneut.",
      fileSuccessFormat: "{0} erfolgreich archiviert.",
      fileFailedFormat:
        "{0} Die Archivierung ist fehl geschlagen. Bitte versuchen Sie es später erneut.",
      exportSuccessFormat: "{0} Erfolgreich exportiert",
      exportFailedFormat:
        "{0} Der Export ist fehl geschlagen. Bitte versuchen Sie es später erneut.",
      submitSuccessFormat: "{0} erfolgreich eingereicht.",
      submitFailedFormat:
        "{0} Die Übermittlung ist fehl geschlagen. Bitte versuchen Sie es später erneut.",
      confirmSuccessFormat: "{0} bestätigt den Erfolg.",
      confirmFailedFormat:
        "{0} Die Bestätigung ist fehl geschlagen. Bitte versuchen Sie es später erneut.",
      pleaseUploadFile: "Bitte laden Sie zuerst die Datei hoch!",
      importFileSuccess: "Die Datei wurde erfolgreich importiert!",
      importFildFailed: "Datei import fehl geschlagen!",
      deleteSuccess: "Löschen erfolgreich!",
      deleteFailed:
        "Löschen fehl geschlagen, bitte versuchen Sie es später erneut!",
      illegalTitle: "Unzureichende Berechtigungen",
      illegalMessage:
        "Sie haben nicht einen Teil oder alle Berechtigungen für den nächsten Schritt. Bitte wenden Sie sich an den Admini strator, um zu konfigurieren. Klicken Sie auf die Schaltfläche OK, um den Start zu beenden",
      illegalMessageCurrent:
        "Sie haben nicht einige oder alle Berechtigungen für den aktuellen Schritt. Wenden Sie sich an die Admini strator konfiguration. Klicken Sie auf die Schaltfläche OK, um den Start zu beenden",
      skipMessage:
        "Wenn Sie diesen Schritt vorübergehend überspringen möchten, klicken Sie auf Überspringen",
      errorMsg500:
        "Die Server anforderung ist fehl geschlagen. Überprüfen Sie Ihren Vorgang oder aktualisieren Sie ihn erneut",
      errorMsg404: "Ooops... die gesuchte Seite ist weg",
      errorMsg400:
        "Die Server anforderung ist fehl geschlagen. Bitte versuchen Sie es erneut, nachdem Sie Ihre Daten überprüft haben",
      pageOverFlow:
        "Derzeit werden nur 10.000 Anzeigen unterstützt. Bitte legen Sie die Filter bedingungen fest, um den übers chüssigen Teil anzuzeigen",
      isHandAddSmartTag:
        "Ist ein intelligentes Etikett, das manuell hinzugefügt wird",
      defaultGradeMustAtLast: "Die Standard bewertung muss ganz unten sein",
      AutomaticAllocationInspectorsIsNull:
        "Bitte rufen Sie die Basis informations seite auf, um einen Qualitäts inspektor hinzuzufügen",
      timePickerTips:
        "Um Ihren normalen Gebrauch nicht zu beeinträchtigen, 23:00 Uhr 2:00 ist die System wartungs zeit und es werden keine zeit gesteuerten Aufgaben ausgeführt.",
      batchDeleteInfo:
        "Die {0}-Einträge wurden erfolgreich gelöscht. Die {1}-Einträge sind fehl geschlagen. Der Grund für den Fehler ist, dass die Daten bereits verwendet wurden.",
      batchDeleteTips:
        "Überprüfen Sie {0} Daten, um das Löschen zu bestätigen.",
      correctionSuccess: "Erfolgreiche Fehler korrektur",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError:
            "Fügen Sie bis zu 10 Qualitäts prüfungs formulare hinzu",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "Die Summe der Indikator elemente {0} muss 100 betragen",
          banProjectMaxErrorTip:
            "Fügen Sie bis zu 2000 verbotene Elemente hinzu",
          deadlyProjectMaxErrorTip:
            "Tödliche Elemente werden bis zu 500 hinzugefügt",
        },
      },
    },
    info: {
      creatSuccess: "Erfolgreich erstellen",
      creatError: "Erstellung fehl geschlagen",
      editSuccess: "Erfolgreich modifiziert",
      editError: "Änderung fehl geschlagen",
      deleteSuccess: "Löschen erfolgreich",
      addSuccess: "Neuer Erfolg",
      deleteError: "Löschen fehl geschlagen",
      conditionError: "Operator logischer Ausdruck Fehler",
      customJudgeLogic:
        "Benutzer definierte bedingte Kombination Logik ausdruck fehler",
      exportError: "Export fehl geschlagen",
      searchError: "Screening fehl geschlagen",
      loadError: "{0} Laden fehl geschlagen",
      saveSuccess: "Speichern Sie erfolgreich",
      saveError: "Speichern fehl geschlagen",
      fileSuccess: "Archivierung erfolgreich",
      fileError: "Archivierung fehl geschlagen",
      cannotBeNull: "{0} kann nicht leer sein!",
      pleaseSearchAndSelect:
        "Bitte geben Sie das Schlüssel wort für die Suche ein",
      clickAndToggle: "Klicken Sie auf Wählen Umschalten",
      deleteWarning:
        "{0} Das Löschen kann nicht wieder hergestellt werden. Sind Sie sicher, dass Sie fortfahren möchten?",
      getOssAuthFailed:
        "Wenn die OSS-Autor isierung fehl geschlagen ist, überprüfen Sie die Netzwerk verbindung oder versuchen Sie es später erneut.",
      getInsightAuthFailed:
        "Wenn die Insight-Autor isierung fehl geschlagen ist, überprüfen Sie die Netzwerk verbindung oder versuchen Sie es später erneut.",
      pictureUploadFailed: "Bild-Upload fehl geschlagen",
      pictureUploadFailedSize:
        "Die Bildgröße übers ch reitet das Limit und das Hochladen von Bildern schlägt fehl",
      wecomTagsSyncInfo:
        "Letzte Aktualisierung:{0}, Synchron isieren von {1} Nachrichten",
      collectSuccess: "Erfolg bei der Sammlung",
    },
  },
  components: {
    common: {
      qcReportEcharts: {
        errorMessage: "Fehler beim Abrufen von Berichts daten",
      },
      waveAudio: {
        noWaveMessage:
          "Um Ihre Erfahrung zu erhalten, wird die Wellenform bei Aufnahmen von mehr als {0} Minuten nicht angezeigt. Sie können sie direkt abspielen",
      },
      customFilter: {
        del: "Löschen",
        edit: "Bearbeiten",
        new: "Benutzer definiertes Filtern",
        apply: "Anwendung",
        cancel: "Stornierung",
        onlyApply: "Nur anwendbar",
        ohterSave: "Speichern unter",
        save: "Speichern",
        customFilter: "Häufig verwendetes Screening",
        reset: "Zurücksetzen",
        holderKeyWord: "Bitte geben Sie Schlüssel wörter ein",
        customFilterRule: "Regeln der Filterung logik:",
        customFilterName: "Name des Filters:",
        holderCustomFilterName: "Bitte geben Sie den Filter namen ein",
        manualConfiguration: "Manuelle Konfiguration",
        holderCustomRule:
          "Bitte geben Sie eine benutzer definierte Logik bedingung ein",
        example: "Beispiel",
        dragConfiguration: "Drag & Drop-Konfiguration",
        condition: "Bedingungen",
        operation: "Operation",
        infoDel: "Entfernen Sie Inhalte außerhalb des Bereichs",
        toConfigure: "Konfiguration",
        holderCustomFilterNameLength:
          "Die maximale Länge des Filter namens beträgt 10",
        customFilterNameNone: "Der Filter name darf nicht leer sein",
        and: "Und",
        or: "Oder",
        not: "Nicht",
        delSuccess: "Löschen erfolgreich",
        saveSuccess: "Speichern Sie erfolgreich",
        updateSuccess: "Erfolgreich modifiziert",
        customFilterCondition: "Screening-Bedingungen",
        conditionRule: "Bedingung regeln",
        none: "Keine",
        filterPlaceholder: "Bitte geben Sie Schlüssel wörter ein",
      },
      labelTabs: {
        addThis: "Hierzu hinzufügen",
        goThis: "Bewegen Sie sich hier",
        saveGo: "Speichern und trainieren",
      },
      datePicker: {
        today: "Heute",
        yesterday: "Gestern",
        theDayBeforeYesterday: "Vor gestern",
        thisWeek: "Diese Woche",
        lastWeek: "Letzte Woche",
        thisMonth: "Diesen Monat",
        lastMonth: "Im letzten Monat",
        lastSevenDay: "Die letzten 7 Tage",
        lastThirtyDay: "Die letzten 30 Tage",
        thisQuarter: "Das Quartal",
        lastQuarter: "Das letzte Quartal",
        thisYear: "Dieses Jahr",
        lastYear: "Letztes Jahr",
        lastYearFromToday: "Das letzte Jahr",
      },
      pagination: { page: "Insgesamt {0} Einträge,{1} Seite" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Laden Sie frühere Nachrichten",
          bottom: "Weitere Nachrichten laden",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Qualitäts prüfungs aufgaben",
        informationCollectTasksManageTitle: "Aufgaben sammeln",
        customerServiceCenterTitle: "Kundendienst zentrum",
        informationTitle: "Rechen zentren",
        intelligentAnalysisTitle: "Intelligente Analyse",
        semanticIntelligence: "Semantische Intelligenz",
        trainingCenterTitle: "Trainings zentrum",
        adminTitle: "System verwaltung",
      },
      qualityCheckingTask: {
        title: "Anruf qualitäts prüfung",
        index: {
          tipOne:
            "Die Anruf qualitäts prüfung wird verwendet, um die Qualität der Call-Center-Arbeit der Mitarbeiter zu bewerten. Sie können ein geeignetes Qualitäts prüfungs formular in Kombination mit Ihrem eigenen Geschäft einrichten",
          tipTwo:
            "Geeignete Regeln können für Qualitäts prüfungs aufgaben festgelegt werden, einschl ießlich Screening, Probenahme usw. Nachdem der Qualitäts inspektor eine Bewertung vorgenommen hat, kann der Kundendienst die Ergebnisse der Qualitäts prüfung überprüfen und weitere Beschwerden einreichen.",
          addTaskTitle: "Qualitäts prüfungs aufgaben hinzufügen",
          deleteTipTitle: "Qualitäts prüfungs aufgaben löschen",
          deleteTipContent:
            "Die Qualitäts prüfungs aufgabe kann nach dem Löschen nicht wieder hergestellt werden. Wird das Löschen bestätigt?",
          checkingTask: "Qualitäts prüfungs aufgaben",
          checkingTaskList: "Liste der Qualitäts prüfungs aufgaben",
          formList: "Liste der Formulare",
          viewHelpDocuments: "Hilfe-Dokumentation anzeigen",
        },
        manage: {
          taskSetting: {
            title: "Aufgaben einstellungen",
            form: "Formular",
            basicInfo: "Grundlegende Informationen",
            visibleRange: "Sichtbarer Bereich",
            visibleRangeTip:
              "Das ausgewählte Kundendienst-und Kundendienst team kann die Qualitäts prüfungs aufgabe anzeigen und die Berechtigung seins tel lungen für diese Aufgabe kombinieren. Der Kundendienst leiter, der Kundendienst, der Qualitäts inspektor usw.",
            automaticSampling: "Automatische Stichprobe",
            automaticSamplingTip:
              "Sie können Regeln für die automatische Stichprobe festlegen, damit das System regelmäßig Qualitäts prüfungs aufgaben gemäß den von Ihnen festgelegten Regeln hinzufügt, z. B. jeden Montag 5% der Anrufe der letzten Woche",
            samplingRate: "Stichproben frequenz",
            samplingTime: "Probenzeit",
            samplingRange: "Stichproben bereich",
            samplingRule: "Stichproben regeln",
            fillterCondition: "Screening-Bedingungen",
            workflow: "Arbeits ablauf für die Qualitäts prüfung",
            appealsTime: "Zeit für Beschwerden erlaubt",
            appealsTimeTipOne: "Nachdem die Bewertung eingereicht wurde",
            appealsTimeTipTwo: "Innerhalb von Tagen",
            appealsTimeTipThree:
              'Wenn die zulässige Berufungs zeit übers ch ritten wird, wird der Status der Qualitäts prüfung automatisch auf "bestätigt" geändert.',
            auditTime: "Prüfungs verarbeitung szeit",
            auditTimeTipOne: "Nach Anzeige",
            auditTimeTipTwo: "Innerhalb von Tagen",
            auditTimeTipThree:
              'Nach Ablauf der Überprüfung verarbeitung szeit behält der Datensatz die ursprüngliche Punktzahl bei und der Qualitäts prüfungs status wird automatisch in "bestätigt" geändert.',
            noAppealsMode: "Kein Beschwerde modus",
            appealsMode: "Appell-Modus zulassen",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Kunden service",
            customerServicesGroup: "Kundendienst gruppe",
            surveyList: "Bewertungs liste",
            customFields: "Benutzer definierte Felder",
            fieldConfig: "Feld konfiguration",
            from: "Von",
            owner: "Ver antwort licher",
            inspectorAndReviewerAuthority:
              "Überprüfung behörde für Qualitäts prüfungen",
            inspector: "Qualitäts inspektor",
            reviewer: "Prüfer",
            inspectorTip: "Qualitäts inspektoren können die Aufgabe bewerten",
            reviewerTip: "Der Prüfer kann den Einsatz bewerten",
            dataStatisticsAuthority: "Berechtigungen für Daten statistik",
          },
          samplingRecords: {
            title: "Stichproben aufzeichnungen",
            index: {},
            manualSampling: {
              title: "Probenahme",
              fillterCondition: "Screening-Bedingungen",
              samplingRule: "Stichproben regeln",
              agent: "Kunden service",
              type: "Typ",
              result: "Ergebnisse",
              evaluate: "Bewertung",
              relayNumber: "Relais nummer",
              source: "Quelle",
              task: "Aufgaben",
              queueState: "Warteschlangen status",
              callHanger: "Anruf auflegen",
              queueTime: "Das Anstehen ist zeit aufwändig",
              talkTime: "Dauer des Anrufs",
              bellTime: "Glocken länge",
              recordCountTipOne: "Insgesamt",
              recordCountTipTwo: "Datensätze",
              customerServices: "Kunden service",
              customerServicesGroup: "Kundendienst gruppe",
              surveyList: "Bewertungs liste",
              customFields: "Benutzer definierte Felder",
              fieldConfig: "Feld konfiguration",
              defaultFields: "Standard felder",
            },
          },
          taskList: {
            title: "Aufgaben liste",
            index: {
              batchDelete: "Massen löschen",
              batchSubmit: "Massen übermittlung",
              batchConfirm: "Chargen bestätigung",
              itemFields: "Projekt felder bewerten",
              categoryFields: "Klassifizierung felder der zweiten Ebene",
              forbidsAndDeadlies: "Verbotene und tödliche Gegenstände",
              scoreFieldList: "Liste der Benotung felder",
              customerName: "Name des Kunden",
              cellphone: "Telefon",
              email: "Mailbox",
              organization: "Unternehmen",
              description: "Beschreibung",
              tags: "Tags",
              taskUserRole: "Benutzer rolle für aktuelle Aufgaben",
            },
            workBench: {
              title: "Datensatz details",
              checkForm: "Qualitäts prüfungs formular",
              waitEvaluate: "Zu bewerten",
              alreadyEvaluate: "Ausge wertet",
              waitSubmit: "Absenden",
              alreadySubmit: "Eingereicht",
              waitConfirm: "Zu bestätigen",
              alreadyConfirm: "Bestätigt",
              waitReview: "Ausstehende Überprüfung",
              alreadyReview: "Geüberprüfte",
              alreadyDone: "Abgeschlossen",
              comment: "Anmerkungen zur Bewertung",
              totalScore: "Gesamt punktzahl",
              score: "Punkte",
              remark: "Bemerkungen",
              changeRecords: "Änderungs protokoll",
              subject: "Thema",
              secondLevelScore: "Level 2 Bewertung",
              deadlyList: "Tödliches Element",
              forbidList: "Verbotene Punkte",
              addToCheckTask: "Zu Qualitäts prüfungs aufgaben hinzufügen",
              submitCheckRemark:
                "Einreichung einer Qualitäts prüfungs bewertung",
              changeCheckRemark:
                "Überarbeitete Bewertung der Qualitäts prüfung",
              doneCheckRemark: "Abschluss der Qualitäts prüfung",
              submitCheckReview:
                "Überprüfung der Qualitäts prüfung eingereicht",
              submitCheckAppeal: "Beschwerde zur Qualitäts prüfung eingereicht",
              saveFlowSuccess: "Bewerten Sie den Erfolg.",
              saveFlowFailed:
                "Die Bewertung ist fehl geschlagen. Bitte versuchen Sie es später erneut.",
              FormDetail: "Formular details",
              callDetail: "Details zur Anruf aufzeichnung",
              sampleCalllogDetail: "Datensatz details",
              appealReasonTip:
                "Bitte geben Sie den Grund für die Beschwerde ein (erforderlich)",
              appeal: "Beschwerde",
              appealReason: "Grund für die Beschwerde",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Qualitäts prüfungs formular",
        basicInformationTitle: "Grundlegende Informationen",
        indicatorsProjectTitle: "Indikator projekt",
        specialProjectTitle: "Sonder gegenstände",
        totalScore: "Gesamt punktzahl",
        indicatorsName: "Name des Indikators",
        score: "Punktzahl",
        singleScore: "Einzel punkte",
        weight: "Gewichte",
        maxDeductScore: "Die meisten Punkte abgezogen",
        add: "Erhöhung",
        detail: "Formular details",
        list: {
          title: "Qualitäts prüfungs formular",
          tipOne:
            "Das Konfigurieren des Qualitäts prüfungs formulars ist der erste Schritt in der Qualitäts prüfungs arbeit. Sie können das am besten geeignete Qualitäts prüfungs formular in Kombination mit dem Geschäft des Unternehmens konfigurieren.",
          tipTwo:
            "Wir unterstützen verschiedene Formulare wie direkte Bewertung, Gewichts bewertung und Abzugs system. Es wird empfohlen, die Regeln festzulegen und sie dem System hinzuzufügen.",
        },
        basicInformation: {
          formTemplate: "Formular vorlagen",
          directScore: "Direkte Bewertung",
          weightedScore: "Gewichte Bewertung",
          deductScore: "Punkt abzug",
          directScoreSecondLevel: "Direkte Bewertung-Stufe 2",
          weightedScoreSecondLevel: "Gewichtung bewertung-Stufe 2",
          deductScoreSecondLevel: "Punkt abzug-Stufe 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Verbotene Punkte",
          banProjectTip:
            "Es kann verwendet werden, um die Bewertung der Qualitäts prüfung zu überprüfen. Es kann als Verstoß oder als Ergänzung zu den Bemerkungen angesehen werden und hat keinen Einfluss auf die tatsächliche Punktzahl.",
          deadlyProject: "Tödliches Element",
          deadlyProjectTip:
            "Der tödliche Punkt kann verwendet werden, um ihn zu überprüfen, wenn die Qualitäts prüfung bewertet wird. Wenn der tödliche Punkt überprüft wird, wird die Qualitäts prüfungs aufgabe direkt mit 0 Punkten bewertet",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Anruf qualitäts prüfung",
          entiretyReportTitle: "Gesamt bericht",
          serviceGroupReportTitle: "Bericht der Kundendienst gruppe",
          serviceReportTitle: "Kundendienst bericht",
          entiretyReport: {
            pandect: "Übersicht",
            contrast: "Kontrast",
            trend: "Trend",
            analyze: "Analyse",
            totalScore: "Gesamt punktzahl",
            banProject: "Verbotene Punkte",
            deadlyProject: "Tödliches Element",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Liste der Qualitäts prüfungs ergebnisse",
          taskSelectPlaceholder: "Wählen Sie eine Qualitäts prüfungs aufgabe",
          agentSelectPlaceholder: "Kunden service auswählen",
          refresh: "Aktualisieren",
          all: "Alle",
          unread: "Ungelesen",
          inComplaint: "Berufung",
          reviewed: "Geüberprüfte",
          totalScore: "Allgemeiner Kommentar",
          detail: "Details zur Qualitäts prüfung",
          audioText: "Aufnahme text",
          callInfo: "Anruf informationen",
          ticketInfo: "Informationen zum Arbeits auftrag",
          wechatInfo: "Sitzungs informationen",
          dialogText: "Dialog text",
          dialogInfo: "Informationen zum Dialog",
          customer: "Kunden",
          agent: "Kunden service",
          none: "Keine Qualitäts prüfungs daten",
          appealSuccess: "Die Berufung war erfolgreich!",
          appealFailed:
            "Die Beschwerde ist fehl geschlagen, bitte versuchen Sie es später erneut!",
          pointListIsNull: "Der Beschwerde prüf punkt kann nicht leer sein!",
          pleaseSelectPoint: "Bitte wählen Sie einen Qualitäts prüf punkt!",
          unsupportedFormat:
            "Dieses Format wird vorübergehend nicht unterstützt",
          shardingInformation: "Das Folgende ist Splitter {0}",
          smartTags: "Intelligente Tags",
          businessRecords: "Geschäfts aufzeichnung",
          selectedPointsWarning:
            "Bitte kreuzen Sie die Qualitäts prüf punkte an, um Berufung einzulegen, geben Sie relevante Sätze für die Beschwerde punkte an und klicken Sie dann auf Senden.",
          applyReason: "Grund für die Berufung (bis zu 50 Wörter)",
          chatMessageTypes: {
            all: "Alle",
            text: "Text",
            image: "Bild",
            agree: "Stimmen Sie dem Inhalt der Sitzung zu",
            video: "Video",
            voice: "Stimme",
            file: "Datei",
            emotion: "Ausdruck",
            link: "Link",
            chatrecord: "Chat-Verlauf",
            mixed: "Gemischte Nachrichten",
            default: "Nachrichten werden nicht unterstützt",
            deleteFriend: "Freunde löschen",
            redpacket: "Rote Umschläge",
            card: "Visitenkarten",
            disAgree: "Mit dem Inhalt der Sitzung nicht einverst anden",
            weapp: "Kleines Programm",
            externalRedpacket: "Austausch roter Umschlag",
            meetingVoicevCall: "Anruf nachrichten",
            uRL: "Webseite",
            replyTimeout: "Timeout Antwort",
            sphfeed: "Video nummer",
            chatRecordTitle: "Chat-Verlauf",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Gesamt punktzahl",
            actualScore: "Bewertung",
            actualGrade: "Bewertung",
            appeal: "Beschwerde",
            remark: "Künstliche Bemerkungen",
            none: "Keine Details zur Qualitäts prüfung",
            pointEdit: "Klassifizierung der Qualitäts prüf punkte bearbeiten",
          },
          changeList: {
            title: "Änderungs protokoll",
            user: "Benutzer",
            point: "Qualitäts prüf punkt",
          },
          relatedRecord: {
            title: "Verwandte Aufzeichnungen",
            qualityList: "Qualitäts prüfungs aufgaben",
            none: "Keine",
            check: "Anzeigen",
            loadMore: "Laden Sie mehr",
          },
        },
      },
      grade: {
        title: "Qualitäts prüfungs bewertung",
        subTitle:
          "Die Qualitäts prüfungs bewertung wird verwendet, um die Qualitäts prüfungs objekte nach Abschluss der Qualitäts prüfungs bewertung zu bewerten. Beispiels weise kann sie mit weniger als 60 Punkten für nicht qualifiziert, 60-85 Punkten für gut und 80 Punkten für aus gezeichnet konfiguriert werden",
        list: {
          up: "Nach oben bewegen",
          down: "Nach unten bewegen",
          del: "Löschen",
          case: "Automatisch der Fall bibliothek beitreten",
          orderCase: "Standard-Fall bibliothek",
          info: "Die Standard bewertung hat keine Übereinstimmung bedingung und kann nicht gelöscht werden. Wenn eine der oben genannten Bewertungen nicht erfüllt ist, wird die Standard bewertung erhalten",
          add: "Bewertung hinzufügen",
          save: "Speichern",
          close: "Stornierung",
          max: "Bis zu 20 Qualitäts prüfungs bewertungen können konfiguriert werden",
          name: "Nicht benannt",
          success: "Speichern Sie erfolgreich",
        },
      },
      qualityInspectionList: {
        title: "Qualitäts prüfungs liste",
        fastFilter: "Verstöße gegen die Qualitäts prüfung",
        fastFilterTip:
          "Treffer im Subtraktion system sind Verstöße, und Fehlschüsse im Bonuspunkte system sind Verstöße",
        titleWechat: "Analyse liste",
        titleCollect: "Erfassungs liste",
        status: "Analyse des Zustands",
        reQualityInspectionConfig:
          "Aufgaben optionen für die erneute Qualitäts prüfung",
        pleaseSelectRecheckPoints:
          "Bitte wählen Sie den Qualitäts prüf punkt aus, der neu berechnet werden muss",
        beginReQualityInspection: "Starten Sie die Ausführung",
        cancelReQualityInspection: "Aufgabe abbrechen",
        reQualityInspection: "Nachprüfung",
        reQualityInspectionWechat: "Neu analysieren",
        reQualityInspectionUnderWay: "Überprüfen Sie die Aufgabe erneut...",
        reQualityInspectionUnderWayTip:
          "Das Verlassen der Seite nach dem Schließen des Fensters hat keinen Einfluss auf die Aufgabe",
        reQualityInspectionDone:
          "Die erneute Qualitäts prüfung ist abgeschlossen",
        reQualityInspectionDoneTip:
          "Die erneute Qualitäts prüfung wurde erfolgreich abgeschlossen. Klicken Sie auf die Schaltfläche Bestätigen",
        endReQualityInspection: "Aufgabe beenden",
        alreadyDoneTip: "Abgeschlossen {0}",
        totalTip: "Gesamt {0} Balken",
        endReInspectionSuccess:
          "Die Aufgabe der erneuten Qualitäts prüfung wurde beendet",
        closeWindow: "Fenster schließen",
        endReInspectionFailed:
          "Die erneute Qualitäts prüfungs aufgabe ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
        detail: {
          title: "Details zur Qualitäts prüfung",
          wechatTitle: "Analyse Details",
          titleCollect: "Details sammeln",
          callIndex: "Assoziierte Anrufe",
          textIndex: "Assoziierter Dialog",
          fileConfirm: "Bestätigen Sie die Archivierung?",
          fileConfirmDesc:
            "Die Qualitäts prüfungs bewertung kann nach der Archivierung nicht bearbeitet werden. Bitte bestätigen Sie, ob sie archiviert ist",
        },
        sysCloumnsContal: "System Spalten feld verwaltung",
        personCloumnsContal: "Verwaltung persönlicher Spalten felder",
      },
      spotCheckList: {
        title: "Stichproben liste",
        onlyMyTip: "Schauen Sie sich nur meine Probenahme an",
        plInspector: "Bitte wählen Sie einen Probenahme prüfer",
        allInspector: "Alle anzeigen",
        seInspector: "Designierter Probenahme prüfer anzeigen",
        myInspector: "Schau dir nur meine an",
        noInspector: "Nicht zugewiesene Probenahme prüfer anzeigen",
        detail: { title: "Details zur Probenahme" },
      },
      reviewList: {
        title: "Überprüfung liste",
        onlyMyTip: "Schauen Sie sich nur meine Überprüfung an",
        plInspector: "Bitte wählen Sie einen Prüfer",
        allInspector: "Alle anzeigen",
        seInspector: "Designierter Prüfer anzeigen",
        myInspector: "Schau dir nur meine an",
        noInspector: "Nicht zugewiesene Prüfer anzeigen",
        detail: { title: "Details überprüfen" },
      },
      caseBaseList: {
        title: "Fall bibliothek",
        del: "Löschen",
        delTitle: "Nach dem Löschen wird nicht alles gespeichert",
        cancel: "Stornierung",
        actions: "Betrieb",
        seeCaseBase: "Sehen Sie sich den Fall an",
        seeDetail: "Details anzeigen",
        edit: "Bearbeiten",
        save: "Speichern",
        addCaseBase: "Neue Fall bibliothek",
        export: "Exportieren",
        ok: "Bestimmt",
        tip: "Die typischen Anruf-und Konversation daten in der Qualitäts prüfungs liste können zum einfachen Lernen und Teilen in der Fall bibliothek gespeichert werden",
        detail: {
          choiceCaseBase: "Bitte wählen Sie den Fall",
          delCaseBase: "Fall löschen",
          addCaseBase: "Hinzufügen von Fällen",
          successed: "Speichern Sie erfolgreich",
          info: "Bitte speichern oder abbrechen Sie zuerst den Inhalt, den Sie bearbeiten",
          caseType:
            "Bitte wählen Sie die Fall bibliothek aus, der Sie beitreten möchten",
        },
      },
      testSetList: {
        title: "Qualitäts prüfungs set",
        tip: "Die von den Qualitäts inspektoren nach der Probenahme geprüften Daten werden im Qualitäts prüf zentral gespeichert, um die Konfiguration der Qualitäts prüf bedingungen und Qualitäts prüf regeln für einige nach der Probenahme ausgeführte Maschinen prüf fehler zu optimieren",
        refresh: "Aktualisieren",
        reQualityInspectionUnderWay: "Neubewertung der Aufgabe...",
        reQualityInspectionUnderWayTip:
          "Das Verlassen der Seite nach dem Schließen des Fensters hat keinen Einfluss auf die Aufgabe",
        reQualityInspectionDone: "Neubewertung ist abgeschlossen",
        reQualityInspectionDoneTip:
          "Die Neubewertung wurde erfolgreich abgeschlossen. Klicken Sie auf die Schaltfläche Bestätigen",
        endReQualityInspection: "Aufgabe beenden",
        alreadyDoneTip: "Abgeschlossen {0}",
        totalTip: "Gesamt {0} Balken",
        endReInspectionSuccess: "Aufgabe der Neubewertung beendet",
        closeWindow: "Fenster schließen",
        endReInspectionFailed:
          "Die Neubewertung der Aufgabe ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
        recheckPoint:
          "Berechnen Sie die Punktzahl und Genauigkeit der Qualitäts prüf punkte neu",
        recheckPointTitle: "Neubewertung der Optionen",
        delete: {
          reQualityInspectionUnderWay: "Batch-Lösch aufgaben...",
          reQualityInspectionUnderWayTip:
            "Das Verlassen der Seite nach dem Schließen des Fensters hat keinen Einfluss auf die Aufgabe",
          reQualityInspectionDone: "Massen löschung aufgabe abgeschlossen",
          reQualityInspectionDoneTip:
            "Das Batch-Löschen wurde erfolgreich abgeschlossen. Klicken Sie auf die Schaltfläche Bestätigen",
          endReQualityInspection: "Aufgabe beenden",
          alreadyDoneTip: "Abgeschlossen {0}",
          totalTip: "Gesamt {0} Balken",
          endReInspectionSuccess: "Massen löschung aufgabe beendet",
          closeWindow: "Fenster schließen",
          endReInspectionFailed:
            "Die Batch-Lösch aufgabe ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
        },
        reultList: {
          assessmentTime: "Zeitpunkt der letzten Auswertung",
          assessmentAll: "Gesamt bewertung",
          strip: "Artikel",
          spot: "Punkt",
          deviation: "Abweichung",
          accuracyRate: "Genauigkeit",
          info: "Keine Bewertungs ergebnisse, bitte bewerten",
          onlyDeviationInfo: "Nur Abweichung datensätze ansehen",
          againAssessment: "Neubewertung",
          screen: "Screening",
          batchDelete: "Massen entfernung",
          matching: "Übereinstimmungen",
          ok: "Ist",
          noCancel: "Nein",
        },
      },
      smartWordsLibrary: {
        title: "Intelligenter Thesaurus",
        tip1: "Synonyme: zur Reduzierung des Trainings volumens für semantische Tags, Synonyme für ähnliche Sätze ohne wiederholtes Training",
        tip2: "Fachbegriffe: Optimierung der Trans literat isierung und Optimierung des Verständnisses der natürlichen Sprache für nicht ident ifi zierte Firmennamen, Produktnamen, Branchen vokabular und andere Eigennamen, um die Erkennungs genauigkeit und Analyse genauigkeit zu verbessern",
        tip3: "Folgen Sie den Wörtern: Achten Sie auf die Wörter, die Sie in der Wort wolken analyze beachten möchten, und helfen Sie der KI-Analyse, Hotspots an das aktuelle Geschäft anzupassen",
        tip4: "Blockierte Wörter: Blockieren Sie Wörter, auf die Sie in der Wort wolken analyze nicht achten möchten, und helfen Sie der KI-Analyse, Hotspots an das aktuelle Geschäft anzupassen",
        tip5: "Wort gewicht: Wird zur Fehler korrektur und Optimierung der Erkennung semantischer Tags verwendet. Durch Ändern des Einfluss gewichts von Wörtern im Satz kann der semantische Erkennungs effekt in bestimmten Szenarien nach Bedarf optimiert werden",
        tip6: "Korpus daten: Geben Sie den großen Absatz text für die jeweiligen Geschäfts szenarien ein, der für die Fehler korrektur und das ASR-Training verwendet wird, um die Genauigkeit der ASR-Trans literat ion zu verbessern",
        upload: {
          uploadSynonymWords: "Synonyme importieren",
          uploadProfessionalWords: "Fachbegriffe importieren",
          uploadAttentionWords: "Folgen Sie Wörter importieren",
          uploadIgnoreWords: "Importieren Sie ignoriert Wörter",
          uploadWordsWeight: "Importieren Sie gewichtete Wörter",
          uploadTypo: "Tippfehler-Sätze importieren",
          uploadCorpusData: "Korpus daten importieren",
          uploadCorpusContent: "Korpus daten inhalt importieren",
          firstUploadStep:
            "1. Laden Sie die Vorlage herunter und füllen Sie sie im Vorlagen format aus",
          downloadTemplateMessage: "Vorlage herunter laden:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Klicken Sie auf die Schaltfläche Importieren, um eine EXCEL-Datei hochzuladen, die dem Vorlagen format entspricht",
          secondUploadCorpusDataStep:
            "2. Klicken Sie auf die Schaltfläche Importieren, um eine TXT-Datei hochzuladen, die dem Vorlagen format entspricht",
          postUploadDataFail: "Übermittlung von Upload-Daten fehl geschlagen",
          pleaseUploadTemplate: "Bitte laden Sie die EXCEL/JSON/TXT-Datei hoch",
          templateNeedJSONOrXLSX:
            "Datei vorlagen können nur im JSON-oder xlsx-Format vorliegen",
          templateNeedTxt:
            "Korpus-Daten datei vorlagen können nur TXT-Dateien sein",
          uploadSuccess: "Erfolgreich importiert",
          uploadFailed: "Import fehl geschlagen",
          downloadError: "Download fehl geschlagen",
          downLoadTxtTitle: "Korpus inhalt. txt",
        },
        navTabNames: {
          synonymWords: "Synonyme",
          professionalWords: "Fachbegriffe",
          attentionWords: "Worte der Aufmerksamkeit",
          ignoreWords: "Wörter ignorieren",
          wordsWeight: "Wort gewicht",
          corpusData: "Korpus daten",
          typoLibrary: "Satz von Tippfehlern",
        },
        synonymWords: {
          createSynonymWords: "Neue Synonyme",
          editSynonymWords: "Synonyme bearbeiten",
          addSynonymWords: "Synonyme hinzufügen",
          pleaseEnterStandardWords: "Bitte geben Sie Standard wörter ein",
          synonymWordsExists:
            "Das Synonym ist bereits vorhanden und kann nicht hinzugefügt werden",
          synonymWordsCanNotNull:
            "Das hinzugefügte Synonym kann nicht leer sein",
          pleaseAddSynonymWords: "Bitte fügen Sie Synonyme hinzu",
          addSuccess: "Neuer Erfolg",
          editSuccess: "Erfolgreich modifiziert",
          deleteSuccess: "Löschen erfolgreich",
          deleteError: "Löschen fehl geschlagen",
          required: "*",
        },
        professionalWords: {
          create: "Neue Fachbegriffe",
          edit: "Fach nomen bearbeiten",
          addTargetWords: "Ersatz wörter hinzufügen",
          targetWordsExists:
            "Das Ersatz wort ist bereits vorhanden und kann nicht hinzugefügt werden",
          targetWordsCanNotNull:
            "Das hinzugefügte Ersatz wort kann nicht leer sein",
          pleaseAddTargetWords: "Bitte Ersatz wörter hinzufügen",
          pleaseEnter: "Bitte geben Sie einen Fachbegriff ein",
          wordsExists: "Dieser Fachbegriff existiert bereits",
          createSuccess: "Erfolg hinzufügen",
          editSuccess: "Erfolgreich modifiziert",
          deleteSuccess: "Löschen erfolgreich",
          deleteError: "Löschen fehl geschlagen",
          saveError: "Speichern fehl geschlagen",
          addTargetWordsMaxNumberError:
            "Ersatz wörter können bis zu 100 hinzufügen",
          addHomophonyWordsMaxNumberError:
            "Blockierte Wörter können bis zu 100 hinzufügen",
        },
        attentionWords: {
          create: "Neue Aufmerksamkeit wörter",
          edit: "Folgen Sie der Wort bearbeitung",
          pleaseEnter: "Bitte geben Sie das Anliegen ein",
          wordsExists: "Das Wort Aufmerksamkeit ist bereits vorhanden",
          createSuccess: "Erfolg hinzufügen",
          editSuccess: "Erfolgreich modifiziert",
          deleteSuccess: "Löschen erfolgreich",
          deleteError: "Löschen fehl geschlagen",
        },
        ignoreWords: {
          create: "Neues Ignorieren von Wörtern",
          edit: "Wörter bearbeiten ignorieren",
          pleaseEnter: "Bitte geben Sie das Ignorieren ein",
          wordsExists: "Das Ignorieren des Wortes existiert bereits",
          createSuccess: "Erfolg hinzufügen",
          editSuccess: "Erfolgreich modifiziert",
          deleteSuccess: "Löschen erfolgreich",
          deleteError: "Löschen fehl geschlagen",
        },
        wordsWeight: {
          createWordsWeight: "Neue Gewichtung wörter",
          editWordsWeight: "Gewicht Wörter bearbeiten",
          addWordsWeight: "Gewichtung wörter hinzufügen",
          pleaseEnterWeightWords: "Bitte geben Sie das Gewichts wort ein",
          pleaseEnterWeight: "Bitte geben Sie das Gewicht ein",
          weightNeedInZeroToHundred: "Das Gewicht muss 0 ~ sein Zwischen 100",
          synonymWordsExists: "Das gewichtete Wort existiert bereits",
          weightNeedNumber: "Gewichte erfordern ein ganzzahliges Format",
          standardWeightInformation:
            "Das Benchmark-Gewicht beträgt 50. Je größer das Gewicht, desto stärker der Einfluss auf die Analyse",
          createSuccess: "Erfolg hinzufügen",
          editSuccess: "Erfolgreich modifiziert",
          deleteSuccess: "Löschen erfolgreich",
          deleteError: "Löschen fehl geschlagen",
        },
        corpusData: {
          createCorpusData: "Neue Korpus daten",
          editCorpusData: "Korpus daten bearbeiten",
          addCorpusData: "Korpus daten hinzufügen",
          pleaseEnterCorpusDescription:
            "Bitte geben Sie die Korpus beschreibung ein",
          pleaseEnterCorpusContent: "Bitte geben Sie den Korpus inhalt ein",
          synonymWordsExists: "Die Korpus daten sind bereits vorhanden",
          CorpusContentWordsOverSetting:
            "Die Anzahl der Inhalts wörter beträgt mehr als 20.000 Wörter.",
          download: "Herunter laden",
          upload: "Hochladen",
          see: "Anzeigen",
          canNotSave:
            "Bitte verwenden Sie die Import funktion, sonst kann sie nicht gespeichert werden",
          createSuccess: "Erfolg hinzufügen",
          editSuccess: "Erfolgreich modifiziert",
          deleteSuccess: "Löschen erfolgreich",
          deleteError: "Löschen fehl geschlagen",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Das richtige Wort",
            wrongWord: "Falsches Wort",
            wrongWordCount: "Anzahl der falschen Wörter",
            wrongWordTip: "Drücken Sie die Eingabetaste, um hinzuzufügen",
            status: "Wirksamkeit",
          },
          create: "Erstellen Sie eine neue Tippfehler-Sammlung",
          edit: "Tippfehler-Wortschatz bearbeiten",
          tip: 'Der Tippfehler-Satz wird verwendet, um den Effekt der "Tippfehler erkennung" zu optimieren. Wenn beispiels weise "Rücker statt ung" fäl schlicher weise als "Erweiterung" gesendet wird, können Sie über diese Funktion allgemeine Tippfehler und korrekte Wörter festlegen. Achten Sie darauf, dass sie am nächsten Tag nach dem Speichern wirksam werden.',
          onlyChinese: "Unterstützt nur die Eingabe chinesischer Wörter",
          wrongWordLengthErr:
            "Die Länge jedes falschen Wortes muss 2 ~ betragen 10 Wörter",
        },
      },
      semanticTags: {
        title: "Semantische Tags",
        current: "Aktuelle semantische Beschriftung",
        reference1: "Qualitäts prüfungs aufgabe: {0} - {1}",
        reference2: "Intelligente Tags: {0}",
        reference3: "Daten vor verarbeitung: {0}",
        reference4: "Wissens datenbank: {0}",
        index: { import: "Importieren von semantischen Tags" },
        detail: {
          new: "Neue semantische Beschriftung",
          edit: "Bearbeiten von semantischen Tags",
          name: "Name",
          standardSentences: "Standard-Satz",
          effectiveness: "Wirksamkeit",
          similarSentences: "Ähnliche Sätze",
          tip: "Unterstützt das Aufrufen der Entität-API mit # #, Beispiel: Ist Ihre Handynummer # sys_phone #?",
          excludeSimilarSentences: "Ähnliche Sätze ausschließen",
          intelligentRecommend: "Intelligente Empfehlung ähnlicher Inhalte",
          fastTraining: "Schnelle Ausbildung:",
          qualify: "Reguläre Regeln",
          recallKeyWord: "Rückruf-Schlüssel wörter",
          excludeKeywords: "Schlüssel wörter ausschließen",
          excludeKeywordsInfo1:
            "Ausschluss von Schlüssel wörtern und regulären Regeln hat Vorrang vor allen Logik wirksam. Wenn der Ausschluss aus geschlossen wird, wird das semantische Label nicht getroffen",
          keyWordInfo1:
            "Rückruf-Schlüssel wörter unterstützen das Ausfüllen mehrerer in jeder Zeile, getrennt durch englische Kommas, und beim Ausfüllen mehrerer Schlüssel wörter ist die Logik alle Treffer.",
          keyWordInfo2:
            "Der Rückruf von Schlüssel wörtern unterstützt das Ausfüllen mehrerer Zeilen, und die Logik des Ausfüllens mehrerer Zeilen kann als Treffer festgelegt werden.",
          recallRegular: "Rückruf von regulären Regeln",
          excludeRegular: "Ausschluss der regulären Regeln",
          similaSentences: "Ähnliche Sätze ausschließen",
          simim: "Ähnlichkeit",
          keyWordInfo3:
            "Das Ausschließen ähnlicher Sätze hat Vorrang vor allen Logiken. Wenn Sie einen ähnlichen Satz aus schließen, wird das semantische Etikett nicht getroffen. Die Standard ähnlichkeit beträgt 80%. Je größer die Ähnlichkeit, desto ähnlicher.",
          keyWordInfo4:
            "Die Regel ähnelt der regulären Regel, wobei der Start und das Ende standard mäßig vorhanden sind. Es muss nicht mit Klammern verwendet werden, z. B. [Nicht XXXX]",
          keyWordInfo5:
            "Die Funktionen in den erweiterten Modulen sind in der Regel experimentelle Funktionen für Experten, die mit der Bedienung vertraut sind. Wenn Sie nicht sicher sind, wie Sie dies tun sollen, wird empfohlen, diesen Abschnitt nicht zu ändern.",
          keyWordInfo6:
            "Die Satz übergreifende Erkennungs funktion gibt semantischen Labels die Möglichkeit, Sätze über Sätze hinweg zu erkennen.",
          keyWordInfo7: "Bis zu einer kontinuier lichen",
          keyWordInfo8:
            "Ignorieren Sie, dass die Länge verschiedener Rollen nicht größer ist als",
          keyWordInfo9: "Satz eines Wortes für die Inter-Satz-Erkennung",
          keyWordInfo10: "Die längste Kreuz satz grenze ist nicht größer als",
          keyWordInfo11:
            "Entfernen Sie den ähnlichen Prozentsatz nicht weniger als",
          keyWordInfo12: "Die Ähnlichkeit ist nicht niedriger als",
          keyWordInfo13:
            "Der ähnliche Satz von % wird ähnlich abgeglichen (die Standard ähnlichkeit beträgt 80%).",
          keyWordInfo14:
            "Die Ähnlichkeit wird entsprechend der Ähnlichkeit der Sätze abgeglichen. Es wird empfohlen, zu ähnliche Sätze zu entfernen, um die Erkennungs effizienz zu verbessern.",
          keyWord: "Schlüssel wörter",
          entity: "Entität",
          ruleField: "Begriff Wort",
          customRule: "Sprach regel design",
          fanhua: "Ähnliche Frage",
          test: "Test",
          testOk: "Hit-Test",
          senior: "Erweiterte Einstellungen",
          seniorList: "Satz übergreifende Erkennung",
          up: "Aktivieren",
          down: "Schließen",
          ju: "Satz",
          oneWord: "Worte",
          simimNo: "Ähnlich",
          seniorField: "Ähnlichkeit schwelle",
          save: "Speichern",
          cancel: "Stornierung",
          specialElements: "Besondere Elemente",
          logicRule: "Logische Regeln",
          every: "Mehrere Übereinstimmungen",
          recallOk: "Rückruf wörter treffen:",
          recallNo: "Rückruf wort verfehlt",
          ruleOk: "Rückruf regel treffen",
          ruleNo: "Fehlschlag",
          rularOk: "Regeln für Treffer",
          rularNo: "Versäumte Regeln",
          infoOk: "Standards ätze oder ähnliche Sätze treffen:",
          infoNo: "Fehlende Standards ätze oder ähnliche Sätze",
          fieldOk: "Treffer, um ähnliche Sätze aus zuschließen:",
          fieldNo: "Fehler schließen ähnliche Sätze aus",
          excludeRuleNo: "Fehler ausschluss",
          excludeRuleOk: "Rekl.",
          excludeKeywordNo: "Fehlende Ausschluss-Schlüssel wörter",
          excludeKeywordOk: "Schlüssel wörter aus geschlossen",
          update: "Erfolgreiches Update",
          fanhuaList:
            "Liste der ähnlichen Sätze, die zur Verallgemeinerung seite eingereicht wurden",
          wordList:
            "Die Trainings liste ist leer und kann nicht an die allgemeine Liste gesendet werden",
          start: "Der Anfang",
          end: "Ende",
          keyWordInfo15:
            "Ähnliche Felder befinden sich in der Generali sierungs liste (erweiterte Konfiguration ähnlich, um einges chränkt zu werden)",
          saveSuccess: "Speichern Sie erfolgreich",
          errInfo: "Es gibt Felder, die nicht oder falsch ausgefüllt sind",
        },
        table: {
          deepTraining: "Tiefen training {0} Runde",
          fastTraining: "Schnelles Training",
        },
      },
      intelligentCollection: {
        title: "Intelligente Sammlung",
        classification: "Liste der Gruppierungen",
        new: "Neue Sammlung",
        table: {
          name: "Feld name",
          props: "Kunden attribute",
          type: "Feld typ",
          desc: "Feld beschreibung",
        },
        detail: {
          isCustomerProps: "In Kunden attributen speichern",
          customerPropsHelper:
            "Nach dem Speichern werden die gesammelten Informationen auf die Kunden informationen aktualisiert. Wenn es nicht gespeichert wird, werden die Kunden informationen nicht aktualisiert",
          name: "Feld name",
          type: "Feld typ",
          text: "Text",
          desc: "Feld beschreibung",
          descPlaceHolder:
            "Beschreiben Sie die Verwendung des Felds, um Informationen zu sammeln",
          condition: "Bedingungen",
          logic: "Regeln",
          method: "Sammel methode",
          message: "Informations inhalt",
          range: "Extraktion bereich",
          entity: "Entität extrahieren",
          base: "Grundlegende Informationen",
          collect: "Informationen sammeln",
        },
      },
      intelligentTags: {
        title: "Intelligente Tags",
        sessionLabelTitle: "Gesprächs szene",
        keyEventsTitle: "Schlüssel ereignisse",
        customerLabelTitle: "Kunden-Tags",
        addClassification: "Erstellen Sie eine neue Etiketten klassifizierung",
        defaultCategoryName: "Standard klassifizierung",
        index: {
          createTag: "Neues Etikett",
          createEvent: "Neues Ereignis",
          editTagClassification: "Beschriftung klassifizierung bearbeiten",
          selectTagClassification: "Auswahl der Etiketten klassifizierung",
          editTagGrounp: "Gruppierung bearbeiten",
          callTimeError:
            "Die gewählte Gesprächs zeit/Gesprächs start darf einen Monat (31 Tage) nicht überschreiten.",
          callRecordCountError:
            "Die Anzahl der Datensätze ist 0. Bitte wählen Sie die Anruf-/Konversation szeit erneut aus",
          selectItmeCountError:
            "Bitte wählen Sie das Smart Label aus, für das Sie den Stapel vorgang ausführen müssen",
          redoSuccess:
            "Wiederholen Sie es erfolgreich, gehen Sie zum Task Center, um es anzuzeigen",
          redoError:
            "Die erneute Ausführung ist fehl geschlagen. Bitte versuchen Sie es später erneut",
          timeError:
            "Die Abfrage zeit darf einen Monat (31 Tage) nicht überschreiten.",
          categoryName: "Klassifizierung namen",
          categoryColor: "Klassifizierung von Farben",
          newTagClassification: "Neue Etiketten klassifizierung",
          newEventClassification: "Neue Kategorie",
          tagClassification: "Etiketten klassifizierung",
          eventClassification: "Ereignis klassifizierung",
        },
        detail: {
          new: "Neue intelligente Tags",
          edit: "Intelligente Tags bearbeiten",
          tagName: "Name",
          tagDesc: "Beschreibung",
          category: "Klassifizierung",
          tagStatus: "Wirksamkeit",
          operator: "Operator",
          rule: "Regeln",
          labelClassAtion: "Art der Beschriftung",
          labelClassAtionTip1:
            "Beschriftung: Beschriftung basierend auf Regeln",
          labelClassAtionTip2:
            "Klassifizierung etikett: Beschriftung basierend auf Modell training",
          group: "Gruppierung",
          checkLabel: "Auswahl der Kategorie",
          warn: "Frühwarnung",
          warnTips:
            "Wenn die Sitzung nach dem Einschalten der Warnung diese Schlüssel ereignis regel erfüllt, wird die angegebene Person aufgefordert, eine Erinnerung an die Aufgaben stelle zu erhalten",
          warnError: "Bitte wählen Sie die benannte Person",
        },
        subTitle: {
          common: "Intelligente Sitzungs beschriftungen",
          wecom: "Enterprise Micro Label",
        },
      },
      customerPortrait: {
        title: "Kunden porträt",
        index: { import: "Kunden porträts importieren" },
        searchPlaceHolder: "Kunden-ID, Spitzname oder Telefon nummer",
        callCount: "Anzahl der Gespräche:",
        callDuration: "Gesamt anruf dauer:",
        dialogCount: "Gesamtzahl der Dialoge:",
        keyWordsStatistics:
          "Keyword-Statistik (Top 20 & Aufmerksamkeit wörter)",
        keyWordsCount: "({0})",
        businessTag: "Geschäfts label",
        keyWordsCloudImg: "Schlüssel wörter Cloud Map",
        customerEmotionTrend: "Trends zur Veränderung der Kunden stimmung",
        notFoundCustomer: "Verwandte Kunden nicht abgerufen",
        noKeyWords: "Keine relevanten Schlüssel wörter gefunden",
        noBusinessTags: "Das zugehörige Geschäfts label wurde nicht abgefragt",
        getKeyWordsStatisticsDataError:
          "Abfrage der Kunden-Keyword-Statistik fehl geschlagen",
        getBusinessTagsStatisticsDataError:
          "Abfrage des Kunden geschäfts label fehl geschlagen",
        personCustome: "Kunden informationen wurden nicht abgefragt",
        notimeInfo: "Emotionale Informationen wurden nicht abgefragt",
        timeList: "Datums bereich",
        operator: "Intelligente Tags",
        placeHolder: "Bitte wählen Sie",
        placeOperator: "Bitte wählen Sie Smart Label",
        search: "Abfrage",
        reset: "Zurücksetzen",
        searchHolder: "Suche",
        call: "Der Anruf",
        text: "Dialog",
      },
      sentimentAnalysis: { title: "Entdeckung der öffentlichen Meinung" },
      saleSentimentAnalysis: { title: "Analyse der öffentlichen Meinung" },
      dataPreprocess: {
        title: "Daten vor verarbeitung",
        index: {
          createData: "Neue Daten",
          selectDataSource: "Wählen Sie eine Datenquelle",
          selectStatus: "Wählen Sie die Wirksamkeit",
          callTimeError:
            "Die gewählte Gesprächs zeit/Gesprächs start darf einen Monat (31 Tage) nicht überschreiten.",
          callRecordCountError:
            "Die Anzahl der Datensätze ist 0. Bitte wählen Sie die Anruf-/Konversation szeit erneut aus",
          selectItmeCountError:
            "Bitte wählen Sie die Daten aus, für die eine Stapel operation erforderlich ist",
          redoSuccess:
            "Wiederholen Sie es erfolgreich, gehen Sie zum Task Center, um es anzuzeigen",
          redoError:
            "Die erneute Ausführung ist fehl geschlagen. Bitte versuchen Sie es später erneut",
        },
        detail: {
          new: "Neue Daten",
          edit: "Daten bearbeiten",
          name: "Name",
          desc: "Beschreibung",
          dataSource: "Datenquellen",
          status: "Wirksamkeit",
          entity: "Entität extrahieren",
          operator: "Operator",
          rule: "Regeln",
        },
      },
      connectedByConversation: {
        title: "Dialog verband",
        list: {
          title: "Dialog zuordnung funktion",
          info: "Die Konversation zuordnung funktion kann verschiedene Anrufe und Dialoge gemäß den vor eingestellten Regeln verknüpfen",
          rule: "Dialog assoziation regeln",
          source: "Bitte wählen Sie die Datenquelle",
          classification: "Bitte wählen Sie die Kategorie",
          choose: "Bitte wählen Sie",
          input: "Bitte geben Sie ein",
          save: "Speichern",
          cancel: "Stornierung",
          fieldwei: "Bit",
          fieldNot: "Es gibt nicht ausgefüllte Felder",
          fiedldlengthNo: "Feld länge übers ch reitet die Grenze",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Mehrere Schlüssel wörter können durch Kommas getrennt werden",
          applyRole: "Erkennung von Rollen",
          preOperator: "Vor operator",
          applyOperatorScope: "Erfassungs bereich",
          addOperator: "Hinzufügen eines Operators",
          noPreOperator: "Keine",
          from: "Die",
          to: "Satz zum ersten",
          end: "Satz",
          testRegular: "Testen Sie reguläre Ausdrücke",
          test: "Test",
          matchSuccess: "Übereinstimmend zum Erfolg",
          matchError: "Match fehl geschlagen",
          querySementicTags: "Wählen Sie semantische Tags",
          reQuerySementicTags: "Semantische Tags erneut auswählen",
          sementicTag: "Semantische Tags",
          standardSentence: "Standard-Satz",
          similarity: "Ähnlichkeit",
          actions: "Betrieb",
          noFitTag: "Ich habe kein geeignetes Etikett gefunden,",
          addSemanticTag: "Fügen Sie ein neues semantisches Label hinzu",
          pleaseEnterRegular: "Bitte geben Sie einen regulären Ausdruck ein",
          pleaseEnterRegularText:
            "Bitte geben Sie den Inhalt ein, der überprüft werden muss",
          newAssociateComponentTip:
            "Wenn der Wert ein numerischer Typ ist, verwenden Sie die ältere Funktion",
        },
        filterManage: {
          noValueTip: "Keine Daten",
          allCall: "Alle Anrufe",
          allDialogue: "Alle Gespräche",
          allWechat: "Alle Sitzungen",
          getFiltersListFailed:
            "Das Abrufen der Filter liste ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
          getFilterDetailFailed:
            "Die Filter details sind fehl geschlagen. Bitte versuchen Sie es später erneut!",
          getAllCountFailed:
            "Die Gesamtzahl der Anrufe/Konversationen ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
        },
        listManage: {
          searchPlaceHolder:
            "Bitte geben Sie das Schlüssel wort für die Suche ein",
          filterConditions: "Screening-Bedingungen",
          records: "Aufzeichnung",
          getRecordsFailed:
            "Der Datensatz kann nicht abgerufen werden. Bitte versuchen Sie es später erneut!",
        },
        miniListManage: {
          getRecordsFailed:
            "Der Datensatz kann nicht abgerufen werden. Bitte versuchen Sie es später erneut!",
        },
        applyTemplates: {
          title: "Anwendbare Qualitäts prüfungs vorlage",
          collectionTitle: "Anwendbare Sammlung vorlagen",
        },
        recordDetail: {
          showWaveAudio: "Aufnahme anzeigen",
          getDetailFailed:
            "Wenn Sie die Details nicht erhalten haben, versuchen Sie es später erneut!",
          getSmartTagsFailed:
            "Wenn Sie das Smart-Label nicht erhalten haben, versuchen Sie es später erneut!",
          getTemplatesFailed:
            "Wenn Sie die anwendbare Vorlage nicht erhalten haben, versuchen Sie es später erneut!",
        },
        changeList: {
          sampleStatus: "Status der Probenahme",
          sampleAssignInspector: "Zuweisen von Probenahme personal",
          reviewAssignInspector: "Zugewiesene Prüfer",
          read: "Lesen",
          distributivePerson: "Personen zuordnen",
          totalScore: "Gesamt punktzahl",
        },
        qualityCheckScore: {
          totalScore: "Gesamt punktzahl",
          actualScore: "Punktzahl",
          actualGrade: "Bewertung",
          appeal: "Beschwerde",
          remark: "Bemerkungen",
          manualRemark: "Künstliche Bemerkungen",
          none: "Keine Details zur Qualitäts prüfung",
          interactiveTip:
            "Verwenden Sie die interaktive Erkennung, um die Verwendung von Kundendienst-FAQ zu erkennen",
          interactiveIsEmptyTip:
            "Interaktive Erkennung von Inhalten nicht erkannt",
        },
        semanticMark: {
          semanticContent: "Markierung satz",
          markedSimilarTitel: "Ähnliche semantische Tags markiert",
          markedExcludeTitle:
            "Ähnliche semantische Tags wurden aus geschlossen",
          taskTagTitle: "Aufgaben bezogene semantische Tags",
          similarTagTitle:
            "Erkennung ähnlicher semantischer Tags (Ähnlichkeit> 40%,Top 20)",
          delete: "Löschen",
          markSimilar: "Ähnliche Markierungen",
          markExclude: "Ähnlichkeit ausschließen",
          none: "Keine",
        },
        selectCallTime: {
          callTime: "Gesprächs zeit/Sitzungs beginn",
          callTimeRemind:
            "Sie können bis zu einem einzigen Anruf pro Monat (31 Tage) im letzten Jahr auswählen",
          callRecordCount:
            "Sie haben insgesamt {0} Anruf-/Konversation datensätze ausgewählt",
          getCallRecordError:
            "Der Datensatz kann nicht abgerufen werden. Bitte versuchen Sie es später erneut",
        },
      },
      informationEntities: {
        title: "Informations entitäten",
        index: { import: "Importieren von Informations entitäten" },
        detail: {
          new: "Neue Informations entität",
          edit: "Bearbeiten von Informations entitäten",
          name: "Name",
          apiName: "API-Name",
          activeFlag: "Wirksamkeit",
          remark: "Beschreibung",
          logicExp: "Entität reguläre Regeln",
          sentenceList: "Entität inhalt",
          tip: "Entität inhalt unterstützt die Eingabe mehrerer, durch Leerzeichen getrennter Synonyme derselben Entität",
          remarkLengthError: "Beschreibung Länge darf 255 nicht überschreiten",
          nameLengthError: "Die Länge des Namens darf 40 nicht überschreiten",
          entityDuplicateError: "Duplizieren von Entität inhalten",
        },
      },
      interactiveRecognition: {
        title: "Interaktion erkennung",
        classify: "Klassifizierung",
        addClassify: "Kategorie hinzufügen",
        editClassify: "Klassifizierung bearbeiten",
        index: { import: "Interaktion erkennung importieren" },
        detail: {
          new: "Neue intelligente Interaktion",
          edit: "Intelligente Interaktion bearbeiten",
          type: "Typ",
          standardQuestion: "Standard probleme",
          similarQList: "Ähnliche Probleme",
          exceptSimilarQList: "Ähnliche Probleme ausschließen",
          category: "Klassifizierung",
          targetType: "Problemerkennungs-Ziel",
          status: "Wirksamkeit",
          standardAnswer: "Standard-Antworten",
          similarAList: "Ähnliche Antworten",
          wrongAList: "Falsche Antwort",
        },
      },
      businessAnalysis: {
        title: "Geschäfts analyze",
        tip1: "Geschäfts trend analyze: Die Ordinate ist die Anzahl der Konversationen, dh die Anzahl der Konversationen, die die Bedingungen erfüllen, und die Abszisse ist die Zeit",
        tip2: "Korrelation analyze: Analysieren Sie die Korrelation mehrerer Indikatoren, zeigen Sie die Korrelation daten, das Datenvolumen, die Korrelation und die Wiederholung srate an",
        tip3: "Conversion-Analyse: Analysieren Sie mehrere Indikatoren, um eine gegenseitige Conversion-Beziehung zu bilden. Das Bestimmung kriterium für die Conversion ist derselbe Anruf/Dialog. Wenn zwei verschiedene Indikatoren getroffen werden können, kann davon ausgegangen werden, dass diese beiden Indikatoren eine Conversion-Beziehung haben.",
      },
      knowledgeBase: {
        title: "Wissens datenbank",
        list: {
          add: "Unter kategorien hinzufügen",
          delete: "Kategorien löschen",
          categoryName: "Klassifizierung namen",
          createSuccess: "Unter kategorie erfolgreich erstellt",
          keyWord: "Schlüssel wörter",
        },
        detail: {
          type: "Typ",
          status: "Zustand",
          classification: "Klassifizierung",
          standardQuestion: "Standard probleme",
          answerDetectionRange: "Antwort erkennungs bereich",
          standardAnswer: "Standard-Antworten",
        },
      },
      callManage: {
        title: "Anruf aufzeichnung filter",
        listTitle: "Anruf verwaltung",
        detailTitle: "Details zum Anruf",
      },
      dialogueManage: {
        title: "Dialog text filter",
        listTitle: "Konversation management",
        detailTitle: "Gesprächs details",
      },
      weChatManage: {
        title: "Enterprise WeChat Sitzungs filter",
        listTitle: "Verwaltung von Unternehmens-WeChat-Sitzungen",
        detailTitle: "Details zur Enterprise WeChat-Sitzung",
      },
      speechRecognition: {
        title: "Spracher kennung",
        asr: "Spracher kennung",
        asrRemind:
          "Das Ausschalten dieses Schalters führt zu einem Ausfall der Sprach-zu-Text-Funktion und der Qualitäts prüfungs funktion der Maschine",
        asrConfig: "ASR-Filterung",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Nach der Aktivierung wird die ASR-Trans literierung von IM durchgeführt",
        asrConfigRemind:
          "Nur berechtigte Anrufe führen ASR-Inhalte aus, wenn sie aktiviert sind",
        correction: "Intelligente Fehler korrektur",
        correctionRemind:
          "Nach der Aktivierung wird der Inhalt von \"<a href = '/site/semantisch-intelligent/smart-words-libry/profession ell-words 'target =' _ blank '> Smart Thesaurus-profession elles Substantiv </a>\" verwendet.",
        correctionConfig: "Fehler korrektur filterung",
        advancedSettings: "Erweiterte Einstellungen",
        advancedSettingsTip:
          "Wählen Sie das angegebene ASR-Modell für die angegebenen Daten aus.",
        mandarinASRModel: "Mandarin ASR-Modell",
        cantoneseASRModel: "Kantonesisches ASR-Modell",
        englishASRModel: "Englisch ASR-Modell",
        correctionConfigRemind:
          "Nach dem Aktivieren werden nur Anrufe korrigiert, die die folgenden Bedingungen erfüllen",
        customJudgeLogicIsNull:
          "Benutzer definierte Logik kann nicht leer sein",
        redoCorrection: "Führen Sie die Fehler korrektur erneut aus",
        callTime: "Wählen Sie die Anruf zeit",
        callTimeRemind:
          "Sie können die Aufzeichnung eines einzelnen Anrufs im letzten Jahr bis zu einem Monat (31 Tage) auswählen",
        callRecordCount: "Sie haben insgesamt {0} Anruf listen ausgewählt",
        redoCorrectionSubmit: "Einreichung zur Ausführung",
        callTimeError:
          "Der ausgewählte Anruf zeitrahmen darf einen Monat (31 Tage) nicht überschreiten.",
        callRecordCountError:
          "Die Anzahl der Anruf protokolle ist 0. Bitte wählen Sie die Anruf zeit erneut aus",
        redoCorrectionSuccess:
          "Die erneute Fehler korrektur ist erfolgreich. Gehen Sie zum Task Center, um sie anzuzeigen",
        redoCorrectionError:
          "Die erneute Fehler korrektur ist fehl geschlagen. Bitte versuchen Sie es später erneut",
      },
      role: {
        title: "Rollen management",
        create: "Neue Rolle",
        detail: {
          title: "Rolle",
          new: "Neue Rolle",
          view: "Rollen ansehen",
          edit: "Rollen bearbeiten",
          navTabNames: {
            basicInfo: "Menü berechtigungen",
            taskPermissions: "Aufgaben berechtigung für die Qualitäts prüfung",
            dataPermissions: "Daten berechtigungen",
          },
          basicInfo: { roleName: "Name", permissions: "Berechtigungen" },
          taskPermissions: {
            allTask: "Alle Qualitäts prüfungs aufgaben",
            myCreatedTask:
              "Die Qualitäts prüfungs aufgabe, die ich erstellt habe",
          },
          dataPermissions: {
            dataPermissionsConfig: "Konfiguration von Daten berechtigungen",
            tips: "Die Rolle kann die Daten in der ausgewählten Kategorie sehen",
            select: "Auswahl der Kategorie",
            selectPlaceholder:
              "Bitte wählen Sie die Kategorie aus, für die Daten sichtbare Berechtigungen erforderlich sind",
          },
        },
      },
      dataMigration: {
        title: "Daten migration",
        dataUpload: "Daten werden eingezogen",
        dataDowload: "Auszug von Daten",
        error:
          "Bitte wählen Sie die Qualitäts prüfungs aufgabe aus, die Sie exportieren möchten",
        list: {
          info: "Bitte wählen Sie die Qualitäts prüfungs aufgabe aus, die Sie ausziehen möchten",
          listInfo:
            "Bitte wählen Sie aus, ob Sie die relevanten Daten der Qualitäts prüfungs aufgabe verlassen möchten (z. B. intelligentes Thesaurus, semantische Tags, Informations entität, intelligente Interaktion usw.)",
          noInfo:
            "Bitte wählen Sie aus, ob Sie andere nicht-Qualitäts prüfungs daten ausziehen möchten",
          ownInfo: "Nur Auszug aus der Mission",
          everyInfo: "Aufgaben bezogen",
          allInfo: "Ausziehen",
          callFilter: "Anruf filter",
          talkFilter: "Dialog filter",
          smartTag: "Intelligente Tags",
        },
        upload: {
          info: "1. Klicken Sie auf Upload-Taste, um eine JSON-Datei hochzuladen, die dem Format entspricht",
          uploadSuccess: "Erfolgreich hochgeladen",
          uploadError: "Upload fehl geschlagen",
        },
      },
      dataAnalysis: {
        businessStatistics: "Geschäfts statistik",
        qualityEffect: "Qualitäts prüfung Gesundheit",
        qualityAnalysis: "Analyse der Qualitäts prüfung",
        generalInspecitionReport: "Allgemeiner Bericht",
        fullAmountWork: "Arbeits belastung der Qualitäts inspektoren",
        intelligentTagsAnalysis: "Etiketten analyze",
        saleIntelligentTagsAnalysis: "Intelligente Etiketten analyze",
        generalInspecitionReportTip:
          "Gesamtdaten: Statistik der Gesamtmenge der Anruf-und Dialog daten innerhalb des derzeit begrenzten Zeit-und Aufgaben bereichs, einschl ießlich der Anzahl und des Prozentsatzes der Daten zur intelligenten Qualitäts prüfung, der Anzahl und des Prozentsatzes der Daten zur manuellen Probenahme, der Anzahl der Beschwerden, der Überprüfung srate und des Zeittrend diagramms",
        groupTest: "Gruppen tests",
      },
      uploadDownloadList: {
        title: "Aufgaben zentrum",
        tabs: {
          upload: "Import hochladen",
          download: "Herunter laden Exportieren",
          taskCenter: "Aufgaben ausführung",
        },
      },
      uploadDownloadModel: {
        title: "Hochladen und Herunter laden",
        list: {
          clear: "Entleeren",
          success: "Erfolg",
          fail: "Scheitern",
          hand: "In Arbeit",
          noInfo: "Keine Daten",
        },
      },
      billingCenter: {
        title: "Abrechnung zentrum",
        export: "Exportieren",
        limit: "Erinnerungs limit",
        tips: "Das Abrechnung serge bnis wird aufgrund von Rundungen nur als Referenz abweichen",
        dashboard: {
          remainingQuota: "Aktuelle Rest quote",
          usedQuota: "Kumulierte verwendete Quoten",
          voiceQuality: "Sprach prüfung:",
          voice: "Stimme:",
          textQuality: "Text Qualitäts prüfung:",
          text: "Text:",
          hour: "Stunden",
          tenThousandHour: "Zehntausend Stunden",
          day: "Tag",
          cutoff: "Frist",
          warning:
            "(Das verbleibende Kontingent ist nicht ausreichend, bitte so schnell wie möglich aufladen.)",
          isClosed: "(Zustand geschlossen)",
        },
        table: {
          quotaRecord: "Quoten rekord",
          timeFilter: "Datum auswählen:",
          search: "Abfrage",
          reset: "Zurücksetzen",
          id: "Seriennummer",
          type: "Typ",
          qualityType: "Art der Qualitäts prüfung",
          quotaChange: "Veränderung",
          surplus: "Balance",
          changeTime: "Zeit",
          voiceQuality: "Sprach prüfung",
          textQuality: "Text Qualitäts prüfung",
          sizeChangeText: "Anzeige pro Seite",
          used: "Verwenden Sie",
          give: "Aus gezeichnet",
          deduction: "Abzug",
          callTime: "Gesprächs zeit",
          count: "Verarbeitete Datenmenge",
          page: "Insgesamt {0} Einträge,{1} Seite",
        },
        limitModal: {
          call: "Die Quote für die Sprach prüfung ist niedriger als",
          callFix: "Stunden für Erinnerungen",
          dialogue:
            "Die verbleibende Zeit für die Text prüfung ist kleiner als",
          dialogueFix: "Tage, um daran zu erinnern",
        },
      },
      messageCenter: {
        replyTitle: "Antworte mir",
        answerTitle: "@ Meins",
        shareTitle: "Teile meine",
      },
      columnsManage: { title: "Qualitäts prüfung Feld verwaltung" },
      recordUpload: {
        list: {
          task: "Qualitäts prüfungs aufgaben",
          user: "Betreiber",
          uploadTime: "Upload zeit",
          endTime: "Endzeit",
          total: "Gesamt Upload",
          success: "Erfolgreiche Qualitäts prüfung",
          failed: "Qualitäts prüfung fehl geschlagen",
          progress: "Fortschritt der Qualitäts prüfung",
          inProgress: "In Arbeit",
          finished: "Abgeschlossen",
        },
        steps: {
          uploading: "Aufnahme dateien hochladen",
          baseInfo: "Füllen Sie die grundlegenden Informationen aus",
          start: "Beginnen Sie mit der Qualitäts prüfung",
        },
        uploadComponent: {
          dragger: "Dateien hinzufügen per Drag & Drop",
          or: "Oder",
          selectFile: "Datei auswählen",
          des: "Unterstützt WAV, MP3-Format, Abtastrate von 8000Hz,16-Bit-Dateien",
          limit1: "Höchste Uploads auf einmal",
          limit2: "Dateien, jede Dateigröße übers ch reitet nicht",
          uploading: "Hochladen",
          finished: "Upload abgeschlossen",
          success: "Erfolg",
          failure: "Scheitern",
        },
        baseInfo: {
          task: "Wählen Sie eine Qualitäts prüfungs aufgabe",
          type: "Wählen Sie das Aufnahme format",
          agentSide: "Kanal des Kundendienstes",
          excel: "Daten auf dem Weg hochladen",
          checkFileInfo: "Überprüfen Sie die Datei informationen",
          excelFileError: "Bitte laden Sie Daten auf der Straße hoch",
          checking: "Überprüfung...",
          reCheck: "Überprüfen Sie erneut",
          success: "Erfolgreiche Überprüfung",
          excelUoloadFail: "Upload von Daten auf der Straße fehl geschlagen",
          tips: {
            tip1: "1. Der Name der Aufnahme datei in ecxel muss mit dem Namen der hochgeladenen Aufnahme übereinstimmen und kann nicht wiederholt werden",
            tip2: "2. Das System überprüft die Wiederholung mit dem Namen der Aufzeichnung datei und der Anruf-ID. Die Wiederholung führt dazu, dass die Qualitäts prüfung fehlschlägt",
            tip3: "3. Für die herunter geladene Excel-Aufnahme vorlage ist die Schriftfarbe rot ein erforderliches Feld",
            tip4: "4. Das Feld format des Zeittyps lautet: yyyy-mm-dd hh:mm:ss, zum Beispiel 2021-04-20 17:36:30",
          },
        },
        title: "Hochladen lokaler Aufnahmen",
        checkTitle: "Aufnahme prüfung",
        start: "Beginnen Sie mit der Qualitäts prüfung",
        disabledTips:
          "Bitte überprüfen Sie die Aufzeichnung datei, bevor Sie die Qualitäts prüfung durchführen",
        tips: "Die gleich namige Aufzeichnung datei deckt die Ergebnisse der Qualitäts prüfung und grundlegende Informationen ab",
        tips1: "Ziehen Sie die Datei hier oder klicken Sie auf Hochladen",
        tips2: "Unterstützung",
        tips3: "Format",
        type: "wav、mp3",
        tips4: "Höchste Uploads auf einmal",
        tips5: "Dateien, jede Dateigröße übers ch reitet nicht",
        tipsOverSize:
          "Die aktuelle Warteschlange ist voll. Bitte entfernen Sie die Dateien in der Warteschlange und laden Sie sie hoch",
        size: "500M",
        count: "20",
        uploaded: "Die Aufnahme datei wurde hochgeladen",
        uploading: "({0}%)",
        uploadFinished: "(Upload abgeschlossen)",
        bottomTitle: "Sammlung von Aufzeichnung informationen:",
        recordType: "Wählen Sie das Aufnahme format aus:",
        double: "Zwei kanal ige Anruf aufzeichnung",
        single: "Einkanal-Anruf aufzeichnung",
        recordBaseInfo:
          "Laden Sie die grundlegenden Informationen der Aufnahme hoch:",
        addBtn: "Dateien hinzufügen",
        downloadTemplate: "Vorlage herunter laden. excel",
        checkRecord: "Überprüfen Sie die Aufnahme informationen",
        checking: "Überprüfung...",
        reCheck: "Überprüfen Sie erneut",
        checkAll: "Alle ausgewählt",
        cancelCheckAll: "Alle abbrechen",
        delete: "Löschen",
        deleteLotSize: "Massen löschen",
        agentChannel: "Kundendienst kanal:",
        left: "Links kanal",
        right: "Der rechte Kanal",
        donotKonw: "Weiß nicht",
        mission: "Wählen Sie die Qualitäts prüfungs aufgabe aus:",
        uploadFail: "Upload fehl geschlagen",
        repeat: "Dateien wiederholen",
        overCount: "Anzahl der Dateien übers ch reitet das Limit",
        overSize: "Dateigröße übers ch reitet Grenzen",
        uoloadFail: "Upload in die Cloud fehl geschlagen",
        formatError: "Falsches Format",
        seccess: "Erfolgreiche Überprüfung",
        fail: "Überprüfung fehl geschlagen",
        recordChecking: "Bitte warten Sie später...",
        recordCheckSuccess: "Die Aufnahme prüfung ist abgeschlossen",
        recordCheckFail:
          "Die Aufnahme prüfung ist fehl geschlagen. Bitte überprüfen Sie, ob die Aufnahme wiederholt oder ungültig ist",
        backToIndex: "Zurück zur Upload-Liste",
        recordCheckIsStarted:
          "Derzeit wird eine Qualitäts prüfungs aufgabe ausgeführt. Bitte starten Sie eine neue Aufgabe, nachdem die aktuelle Aufgabe beendet ist",
      },
      customerServicePortrait: {
        title: "Kunden service Porträt",
        tip1: "Gesetz zur Verteilung der Satz länge: Die Verteilung der Satz länge basiert auf der Anzahl der Wörter in der Satz länge und wird segmentiert, wobei nur der Kundendienst berechnet wird",
        tip2: "Gesetz der Sprech geschwindigkeit verteilung: Die Verteilung der Sprech geschwindigkeit basiert auf der Sprech geschwindigkeit (Wort/pro Minute) und wird segmentiert, wobei nur der Kundendienst berechnet wird",
        tip3: "Gesetz zur Verteilung der Reaktions zeit: Die Verteilung der Reaktions zeit berechnet nur die Reaktion des Kundendienstes auf Kunden",
        tip4: "Einhaltung der Qualitäts prüfung: Compliance-Rate an Qualitäts prüf punkten",
        tip5: "Durchschnitt liche Punktzahl für Qualitäts prüfungs aufgaben: Durchschnitt liche Punktzahl für Qualitäts prüfungs aufgaben",
        contol: {
          date: "Datum auswählen:",
          today: "Heute",
          thisMonth: "Diesen Monat",
          lastMonth: "Im letzten Monat",
          thisQuarter: "Das Quartal",
          lastQuarter: "Das letzte Quartal",
          thisYear: "Dieses Jahr",
          lastYear: "Letztes Jahr",
          customer: "Kunden service:",
          search: "Abfrage",
          reset: "Zurücksetzen",
          export: "Exportieren",
          indicator: "Verwaltung von Indikatoren",
          selectPlaceholder:
            "Bitte wählen Sie den Kunden service aus, der verglichen werden muss",
          warning:
            "Wählen Sie bis zu 5 Kundendienst mitarbeiter zum Vergleich aus",
          warning2:
            "Bitte wählen Sie mindestens 1 Kundendienst für Anfragen aus",
          dataWarning:
            "Unterstützt nur die Abfrage von Daten von bis zu 366 Tagen nach dem Startdatum",
          seccuss: "Erfolgreich exportierte Datensätze",
        },
        echarts: {
          distributionLaw: "Verteilungs gesetz",
          count: "Anzahl",
          bout: "Zeiten",
        },
        portrait: {
          ability: "Dimension der Kundendienst fähigkeit",
          option: "Konfiguration",
          abilityOption: "Konfigurieren der Kompetenz dimension",
          placeholder: "Eingabe fähigkeit Dimension Stichworts uche",
          save: "Speichern",
          warning:
            "Bitte wählen Sie mindestens 3 Indikatoren für die Konfiguration aus. Sie können bis zu 10 Indikatoren auswählen",
        },
        label: { title: "Kundendienst-Geschäfts etikett" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Kundendienst-Ranking",
        select: {
          date: "Datum auswählen:",
          today: "Heute",
          thisMonth: "Diesen Monat",
          lastMonth: "Im letzten Monat",
          thisQuarter: "Das Quartal",
          lastQuarter: "Das letzte Quartal",
          thisYear: "Dieses Jahr",
          lastYear: "Letztes Jahr",
          group: "Kundendienst gruppe:",
          groupPlaceholder: "Bitte wählen Sie die Kundendienst gruppe",
          customer: "Kunden service:",
          customerPlaceholder: "Bitte wählen Sie den Kunden service",
          search: "Abfrage",
          reset: "Zurücksetzen",
          compared: "Kontrast",
          export: "Exportieren",
          column: "Spalte",
          warning:
            "Wählen Sie bis zu 5 Kundendienst mitarbeiter zum Vergleich aus",
          warning2: "Bitte wählen Sie mindestens einen Kundendienst aus",
          dataWarning:
            "Unterstützt nur die Abfrage von Daten von bis zu 366 Tagen nach dem Startdatum",
          selected: "Kunden service ausgewählt:",
          seccuss: "Erfolgreich exportierte Datensätze",
        },
        compared: {
          title: "Kunden service auswählen",
          return: "Stornierung",
          compared: "Kontrast",
          tips: "Bitte wählen Sie zum Vergleich 2-5 Kundendienst mitarbeiter",
          warning:
            "Unterstützen Sie nur 2-5 Kundendienst mitarbeiter zum Vergleich",
        },
        transfer: {
          leftTitle: "Listen einträge",
          rightTitle: "Anzeige elemente",
        },
        table: {
          page: "Insgesamt {0} Einträge,{1} Seite",
          actionTitle: "Betrieb",
          action: "Anzeigen",
        },
      },
      conceptWord: {
        title: "Begriff Wort",
        new: "Neues Konzept wort",
        control: {
          search: "Suche:",
          searchPlaceholder: "Name oder Stichworts uche",
          commit: "Abfrage",
          reset: "Zurücksetzen",
          import: "Importieren",
          export: "Exportieren",
          seccuss: "Erfolgreich exportierte Datensätze",
        },
        modal: { title: "Importieren Sie Konzept wörter" },
        table: {
          sizeChangeText: "Anzeige pro Seite",
          page: "Insgesamt {0} Einträge,{1} Seite",
          edit: "Bearbeiten",
          delete: "Löschen",
          request:
            "Nach dem Löschen kann es nicht wieder hergestellt werden. Wird das Löschen bestätigt?",
          cancel: "Stornierung",
          title: {
            conceptWord: "Begriff Wort",
            describeWordsNum: "Beschreiben Sie die Anzahl der Wörter",
            updateTime: "Aktualisierungszeit",
            status: "Wirksamkeit",
            effective: "Wirksam",
            invalid: "Ungültig",
            action: "Betrieb",
          },
        },
        detail: {
          title: {
            new: "Neues Konzept wort",
            edit: "Konzept wörter bearbeiten",
          },
          name: "Begriff Wort Name",
          namePlaceholder: "Bitte geben Sie den Namen des Konzept worts ein",
          effectiveness: "Wirksamkeit",
          effective: "Wirksam",
          invalid: "Ungültig",
          description: "Beschreiben Sie das Wort:",
          descriptionPlaceholder: "Bitte geben Sie ein Beschreibung wort ein",
          add: "Hinzufügen",
          repeat: "Inhalt wiederholen",
          empty: "Beschreibung wörter können nicht leer sein",
          recommendEmpty:
            "Keine weiteren empfohlenen Beschreibung wörter, bitte manuell eingeben und hinzufügen",
          recommend: "Intelligente Empfehlung beschreibung wörter",
          cancel: "Stornierung",
          change: "Eine andere Gruppe",
          descriptionSelected: "Beschreibung wörter hinzugefügt:",
          save: "Speichern",
          success: "Konzept Wort speichern Erfolg",
          warning: "Konzept wortname kann nicht leer sein",
          warning2: "Bitte fügen Sie mindestens 1 Beschreibung wort hinzu",
          submitBtnHandle: {
            title:
              "Das Häkchen Beschreibung wort wird nicht hinzugefügt. Das Speichern gibt das nicht hinzugefügte Beschreibung wort auf",
            content:
              "Klicken Sie auf OK, um zur Startseite des Konzept worts zurück zukehren. Bestätigen Sie, dass es fortgesetzt wird?",
          },
        },
      },
      smartTools: {
        btn: "Intelligente Werkzeuge",
        title: {
          similarWords: "Verwandte Worter kennung",
          search: "Satz nach Wort",
          semanticTags: "Semantische Tags",
        },
        sentenceSearch: {
          placeholder:
            "Bitte geben Sie die Schlüssel worts uche ein und unterstützen Sie mehrere Schlüssel wörter",
          more: "Volltext anzeigen",
          pageUp: "Vorherige Seite",
          pageDown: "Nächste Seite",
          total: "Insgesamt {0} Datensätze",
        },
        similarDiscover: {
          placeholder: "Bitte geben Sie Schlüssel wörter ein",
          copyed: "Repliziert",
          none: "Keine verwandten Wörter, bitte versuchen Sie andere Schlüssel wörter",
        },
        smartTags: {
          searchPlaceHolder: "Bitte geben Sie Schlüssel wörter oder Sätze ein",
          createSmartTag: "Neu",
          createClusteringSmartTag: "Erstellen Sie mit {0} Daten",
          addFromKeyword: "Hinzufügen von Such inhalten zu ähnlichen Sätzen",
          hit: "Treffer",
          similar: "Ähnlich",
          name: "Semantische Tag-Namen",
          namePlaceHolder:
            "Bitte geben Sie den Namen des semantischen Labels ein",
          recallWords: "Rückruf wort",
          recallWordsPlaceHolder:
            "Bitte geben Sie das Rückruf-Schlüssel wort ein",
          recallRegular: "Rückruf von regulären Regeln",
          recallRegularPlaceHolder: "Bitte geben Sie den Rückruf rückruf ein",
          rules: "Regeln",
          standardSentences: "Standard-Satz",
          standardSentencesPlaceHolder: "Bitte geben Sie den Standards atz ein",
          similaSentences: "Ähnliche Sätze",
          similaSentencesPlaceHolder:
            "Bitte geben Sie einen ähnlichen Satz ein",
          notSaveError:
            "Bitte speichern Sie zuerst das semantische Etikett, bevor Sie suchen",
          add: "Hinzufügen",
          newPage: "Auf einer neuen Seite öffnen",
          querryError:
            "Ähnliche Felder befinden sich in der Generali sierungs liste (erweiterte Konfiguration ähnlich, um einges chränkt zu werden)",
          detailTitle: "Semantische Etiketten details",
        },
      },
      trainingCenter: {
        title: "Trainings zentrum",
        edit: "Bearbeiten",
        delete: "Löschen",
        seleteAll: "Alle ausgewählt",
        cancelCheckAll: "Alle abbrechen",
        add: "Hinzufügen",
        remove: "Entfernen",
        save: "Speichern",
        cancel: "Stornierung",
        fastTraining: {
          title: "Schnelles Training",
          titleDes:
            "Das schnelle Training soll helfen, semantische Tags schnell zu verallgemeinern, um 20 ähnliche Sätze zu erreichen",
          semanticTags: "Semantische Tags",
          semanticTagsPlaceholder:
            "Bitte geben Sie die Prozess schlüssel wörter ein, um zu suchen",
          standardSentence: "Standards atz:{0}",
          similarSentencesPlaceholder:
            "Geben Sie ähnliche Sätze ein, um sie hinzuzufügen oder zu suchen",
          similarSentencesAdd: "Hinzufügen",
          similarSentencesSearch: "Suche",
          similarSentences: "Ähnliche Sätze hinzugefügt",
          trainingCount: "Anzahl der Schulungen:{0}",
          similarSentencesRecommend: "Ähnliche Sätze empfehlen",
          noResp:
            "Keine verwandten semantischen Tags, bitte ändern Sie die Schlüssel wörter",
          similarSentencesModalTitle: "Suche nach ähnlichen Sätzen",
          moreInfo: "Kontext anzeigen",
          moreInfoBtn: "Kontext",
          modalSearchType: {
            all: "Alle",
            customer: "Kunden service",
            client: "Kunden",
          },
          repetWarning:
            "Es gibt bereits denselben ähnlichen Satz, bitte nicht wiederholt hinzufügen",
          repetRemoveTip:
            "Speichern Sie den doppelten Inhalt in ähnlichen Sätzen, der automatisch deaktiviert wurde",
        },
        trainingHistory: {
          title: "Trainings geschichte",
          select: {
            date: "Zeit auswählen:",
            today: "Heute",
            thisMonth: "Diesen Monat",
            lastMonth: "Im letzten Monat",
            thisQuarter: "Das Quartal",
            lastQuarter: "Das letzte Quartal",
            thisYear: "Dieses Jahr",
            lastYear: "Letztes Jahr",
            search: "Abfrage",
            reset: "Zurücksetzen",
          },
          table: {
            time: "Zeit",
            user: "Betreiber",
            tag: "Semantische Tags",
            similarCount: "Anzahl der ähnlichen Satz identifikation",
            deleteCount: "Anzahl von Sätzen entfernen",
            page: "Insgesamt {0} Einträge,{1} Seite",
          },
        },
        classifiedTraining: {
          title: "Klass ifi ziertes Training",
          tagsGrounp: "Etiketten gruppierung",
          placeHolder: "Bitte wählen Sie",
          cellTime: "Zeit auswählen",
          search: "Abfrage",
          reset: "Zurücksetzen",
          classifiedFlag: "Klassifizierung strain ings ituation",
          num: "Numerisch",
          gas: "Prozent",
          noData: "Nicht katego risierte Ausbildung",
          noDataHeight:
            "Nicht klass ifi zierte Daten machen einen relativ hohen Anteil aus, und Schulungen sind dringend erforderlich",
          noDataSmall:
            "Nicht klass ifi zierte Daten machen einen relativ hohen Anteil aus, es wird empfohlen, zu trainieren",
          noDataDays: "Letzte Ausführung {0} vor Tagen",
          training: "Training",
          start: "Der Anfang",
          addClass: "Erhöhen Sie die Klassifizierung",
          custom: "Erweiterte Filterung",
          marked: "Markierte Daten Errata",
          markedHeight:
            "Die Errata wurde seit {0} Tagen nicht mehr durchgeführt, es wird empfohlen, die Errata durch zuführen",
          markedSmall:
            "Die Errata wurde seit {0} Tagen nicht mehr durchgeführt, und die Errata ist dringend erforderlich",
          mark: "Errata",
          dataCheck: "Verifikation der klass ifi zierten Daten",
          dataCheckSmall:
            "Die K-L divergität ist höher als 0,5, es wird empfohlen, zu trainieren",
          dataCheckHeight:
            "Die K-L divergität ist höher als 0,8 und das Training ist dringend erforderlich",
          check: "Überprüfung",
          history: "Geschichte markieren",
          historyTime:
            "Die tägliche Kennzeichnung und Änderung führt das Modell training automatisch um 2:00 Uhr morgens durch",
          today: "Heute markiert",
          article: "Artikel",
          none: "Keine",
          historyCheck: "Historische Gesamt kennzeichnung",
          look: "Aufzeichnung anzeigen",
          historyluate: "Historische Daten neu berechnen",
          startluate: "Starten Sie die Neu berechnung",
          classData: "Klassifizierung daten",
          classStatus: "Trainings fortschritt",
          next: "Weiter",
          prev: "Zurück",
          finish: "Abschluss des Trainings",
          stop: "Ende des Trainings",
          classDetail: "Trainings details",
          trainingNone: "Vorübergehend nicht trainierte intelligente Tags",
        },
        classifiedTrainingHistory: {
          title: "Trainings geschichte",
          time: "Zeit auswählen:",
          tag: "Label-Typ:",
          user: "Betreiber:",
          data: "Wählen Sie die Datenquelle aus:",
          search: "Abfrage",
          searchBar: "Suche:",
          searchBarPlaceholder:
            "Bitte geben Sie die Anruf-ID/Konversation-ID für die Suche ein",
          reset: "Zurücksetzen",
          export: "Exportieren",
          all: "Alle",
          select: {
            today: "Heute",
            thisMonth: "Diesen Monat",
            lastMonth: "Im letzten Monat",
            thisQuarter: "Das Quartal",
            lastQuarter: "Das letzte Quartal",
            thisYear: "Dieses Jahr",
            lastYear: "Letztes Jahr",
            search: "Abfrage",
            reset: "Zurücksetzen",
          },
          table: {
            user: "Betreiber",
            date: "Betriebszeit",
            data: "Datenquellen",
            id: "Anruf-ID/Konversation-ID",
            tagType: "Intelligente Etiketten gruppierung",
            tagName: "Name des Etiketts",
            state: "Zustand",
            sizeChangeText: "Anzeige pro Seite",
            page: "Insgesamt {0} Einträge,{1} Seite",
            call: "Der Anruf",
            dialogue: "Dialog",
          },
        },
        clusteringAnnotation: {
          title: "Clustering",
          left: { title: "Cluster-Ergebnisse" },
          right: {
            title: "Cluster-Sätze",
            checkAll: "Alle Seiten auswählen",
            tagging: "Kennzeichnung",
            detail: "Anzeigen",
            meaningless: "Ignorieren",
            meaninglessTips:
              "Die verna ch lässig ten Clustering-Ergebnisse werden in Zukunft nicht mehr angezeigt. Bestätigen Sie, dass Sie sie ignorieren möchten?",
            cancel: "Stornierung",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Kunden management",
          tabTitle: "Kundendienst zentrum Kunden",
        },
        wechatClientManage: {
          title: "Unternehmens-und Mikro kunden",
          table: {
            id: "Kunden-ID",
            name: "Kunden-Spitzname",
            tag: "Kunden-Tags",
          },
        },
      },
      collectionRule: {
        title: "Regeln für die Sammlung",
        form: {
          info: "Intelligente Sammlung von Informationen",
          infoPlaceholder: "Bitte wählen Sie die Kategorie",
        },
        table: {
          name: "Feld name",
          props: "Kunden attribute",
          type: "Feld typ",
          desc: "Feld beschreibung",
        },
      },
      dataAdd: {
        title: "Daten hinzufügen",
        types: {
          auto: "Automatisches Hinzufügen",
          manual: "Manuelles Hinzufügen",
        },
      },
      wechatStaff: {
        table: {
          name: "Name",
          part: "Abteilung",
          phone: "Handynummer",
          email: "Mailbox",
          wechartId: "Enterprise WeChat ID",
          status: "Status im Dienst",
        },
        info: "Bitte wählen Sie zuerst die Abteilung aus, in der die Daten synchron isiert werden müssen",
        synSuccess: "Erfolgreich synchron isiert",
        init: "Initial isierung",
        syn: "Synchron isieren",
      },
      appealHistory: {
        appealTime: "Zeit für Beschwerden",
        cancelTime: "Widerruf zeit",
        reviewTime: "Überprüfung szeit",
        inspector: "Qualitäts inspektor",
        result: "Ergebnis der Beschwerde",
        complainResult:
          "Insgesamt wurden {0} Qualitäts prüf punkte angefochten, {1} erfolgreich angefochten und {2} abgelehnt",
        reason: "Grund für die Beschwerde",
        rejection: "Gründe für die Ablehnung",
        cancel: "Widerruf",
        tip: "Bestätigen Sie, dass die Beschwerde zurück gezogen werden soll?",
      },
      appealSdk: {
        time: "Gesprächs zeit",
        externalIdCall: "ID des Anruf protokolls",
        externalIdText: "ID des Dialog protokolls",
        customerNickName: "Kunden-Spitzname",
        readStatus: "Status überprüfen",
        status: "Status der Beschwerde",
        isManual: "Ob manuelle Qualitäts prüfung",
        complainButtonInfo:
          "Berufung {0} mal erlaubt, Berufung {1} mal eingelegt, kann die verbleibende Beschwerde {2} mal angefochten werden. Kann vor {3} Berufung einlegen",
        submit: "Beschwerde einreichen",
        complain: "Beschwerde",
        allComplain: "Alle Beschwerden",
        pendingSubmit: "Absenden",
        taskIds: "Qualitäts prüfungs aufgaben",
        graded: "Bewertung",
        complainSuccess: "Die Beschwerde wurde erfolgreich eingereicht",
        appealSdkIndex: {
          title: "Ergebnisse der intelligenten Qualitäts prüfung",
        },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Wenn Sie die Filter listen informationen nicht abrufen, überprüfen Sie die Netzwerk verbindung oder versuchen Sie es später erneut!",
      columns: "Spalte",
      filter: "Screening",
      noSearchResult: "Keine Aufzeichnung gefunden",
      pleaseSearch: "Bitte wählen Sie einen Filter und versuchen, zu suchen",
      sift: "Screening",
      create: "Neu",
      search: "Suche",
      createTaskTitle: "Neue Aufgabe",
      title: "Titel",
      deadline: "Frist",
      principal: "Ver antwort licher",
      enqueueTime: "Eintritts zeit",
      computeError: "Berechnung fehl geschlagen, bitte später!",
      sum: "Summe",
      max: "Maximalwert",
      min: "Mindestwert",
      avg: "Durchschnitt",
      count: "Zählen",
      maxDate: "Maximum (aktuell)",
      minDate: "Minimaler Wert (der älteste)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Das Abrufen der Insight-Linka dresse ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
      },
      template: {
        learningReminder: "Lern erinnerungen",
        subscriptionDynamics: "Abonnieren Sie die Dynamik",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Aufgaben verwaltung", chaneg: "Umschalten" },
        qualityCheckTemplate: {
          text: "Vorlage für Qualitäts prüfung",
          collectText: "Sammlung vorlagen",
          condition: { text: "Qualitäts prüfungs bedingungen" },
          checkPoint: { text: "Konfiguration von Regeln" },
          automaticAdd: { text: "Prozess automatisierung" },
          manualAdd: { text: "Manuelles Hinzufügen" },
          roleConfig: { text: "Rollen konfiguration" },
          basicInfo: { text: "Grundlegende Informationen" },
          smartWordsLibrary: { text: "Intelligenter Thesaurus" },
          grade: { text: "Qualitäts prüfungs bewertung" },
          collectionRule: { text: "Regeln für die Sammlung" },
          addData: { text: "Hinzufügen von Daten" },
          collectionList: { text: "Erfassungs liste" },
        },
        qualityCheckWorkbench: {
          text: "Werkbank für Qualitäts prüfung",
          wechatText: "Analyse-Werkbank",
          informationCollectText: "Sammel bänke",
          qualityInspectionList: {
            text: "Qualitäts prüfungs liste",
            wechatText: "Analyse liste",
            informationCollectText: "Erfassungs liste",
          },
          condition: { text: "Qualitäts prüfungs liste (verworfen)" },
          reviewList: { text: "Überprüfung liste" },
          spotCheckList: { text: "Stichproben liste" },
          caseBaseList: { text: "Fall bibliothek" },
          testSetList: { text: "Qualitäts prüfungs set" },
        },
        statisticsAndReports: {
          text: "Statistiken und Berichte",
          wholeReport: { text: "Gesamt bericht" },
          groupReport: { text: "Bericht der Kundendienst gruppe" },
          agentReport: { text: "Kundendienst bericht" },
          customerServiceExecution: { text: "Analyse des Kundendienstes" },
          analysisOverview: { text: "Analyse im Überblick" },
          inspectionItemsAnalysis: {
            text: "Analyse von Qualitäts prüf produkten",
          },
        },
        wechatQa: {
          text: "Enterprise WeChat Qualitäts prüfung",
          task: { text: "Analytische Aufgaben" },
          baseInfo: { text: "Grundlegende Informationen" },
          checkPoint: { text: "Analyse der Modell konfiguration" },
          setting: { text: "Konfiguration der Unternehmens-WeChat-Analyse" },
          automaticAdd: { text: "Prozess automatisierung" },
          manualAdd: { text: "Manuelles Hinzufügen" },
        },
        clientCenter: { text: "Kundendienst zentrum" },
      },
      conditionDictionaries: {
        title: "Alle Bedingungen",
        searchDefaultText: "Bitte geben Sie die ID oder den Namen ein",
        id: "ID",
        name: "Name",
        conditionDetailedShow: "Erweitern",
        conditionDetailedHide: "Weglegen",
        remark: "Beschreibung:",
        checkRole: "Rollen:",
        operator: "Operator:",
        operatorLogic: "Logisch:",
      },
      audioTextView: {
        customer: "Kunden",
        agent: "Kunden service",
        unsupportedFormat: "Dieses Format wird vorübergehend nicht unterstützt",
      },
      checkPointClassifications: {
        add: "Neue Kategorie",
        name: "Name",
        enterToSave: "Drücken Sie die Eingabetaste, um zu speichern",
        highestScore: "Maximale Punktzahl Obergrenze",
        minimumScore: "Mindest punktzahl Untergrenze",
        defaultMinScore: "Standard ist 0 Punkte",
        minimumScoreTitle: "Untergrenze der Punktzahl",
        beginScore: "Gesamt bewertung",
        beginScoreTitle: "Start bewertung",
        scoreSetTip:
          "Die maximale Punktzahl ist kleiner als die Summe der Punkte. Sie können wählen:",
        ignoreExcessSpillover: "Überlauf ignorieren",
        automaticWeightCalculation: "Automatische Gewichts abrechnung",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Feld" },
    checkRange: {
      numberTipOne: "Die",
      numberTipTwo: "Satz",
      numberTipThree: "Satz zum ersten",
      conditionLimit: "Bedingungen Einschränkungen",
      roleLimit: "Rollen beschränkungen",
      contextLimit: "Satz begrenzung",
      notZero: "Das Satz limit unterstützt die Eingabe von 0 nicht",
      startMustLessThanEnd:
        "Die Position des Anfangs satzes muss kleiner sein als die Position des End satzes",
    },
    waveAudio: {
      play: "Spielen",
      pause: "Aussetzung",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Bitte geben Sie ein",
      pleaseEnterRegular: "Bitte geben Sie einen regulären Ausdruck ein",
      pleaseEnterGrammar: "Bitte geben Sie die Grammatik regeln ein",
      pleaseEnterTestText:
        "Bitte geben Sie den Inhalt ein, der überprüft werden muss",
      pleaseEnterLoudness: "Bitte geben Sie ein Lautstärke-Dezibel ein",
      pleaseEnterRangeAbility: "Bitte geben Sie den Änderungs bereich ein",
      afterHit: "Nach dem Treffer prüfen",
      pleaseEnterContext: "Bitte geben Sie eine Referenz anweisung ein",
      wordNumber: "Wort lücke",
      intervalSecond: "Das Zei tinte rvall ist größer als",
      minute: "Jede Minute",
      wordUnit: "Worte",
      test: "Test",
      testRegular: "Testen Sie reguläre Ausdrücke",
      testGrammar: "Test Grammatik",
      testGrammarError: "Grammatik test fehl geschlagen",
      regularError: "Regulärer Ausdruck falsch, bitte erneut eingeben",
      pleaseEnterSemanticSentence:
        "Bitte geben Sie einen Satz ein, der eine semantische Erkennung erfordert",
      pleaseEnterAssociate:
        "Bitte beachten Sie die Anweisungen auf der rechten Seite, um Routen-Daten ausdrücke zu erstellen",
      querySemanticTags: "Wählen Sie semantische Tags",
      reQuerySemanticTags: "Semantische Tags erneut auswählen",
      semanticTags: "Semantische Tags",
      standardSentence: "Standard-Satz",
      semanticName: "Semantische Tags",
      sentence: "Standard-Satz",
      similarity: "Ähnlichkeit",
      actions: "Betrieb",
      tipOne: "Ich habe kein geeignetes Etikett gefunden,",
      tipTwo: "Fügen Sie ein neues semantisches Label hinzu",
      notLessThan: "Nicht weniger als",
      notGreaterThan: "Nicht größer als",
      ignoreLessThan: "Ignorieren Sie weniger als",
      wordUnitSet: "Satz des Wortes",
      speedWordNotNull:
        "Die Erkennung der Sprach geschwindigkeit mit der geringsten Anzahl von Wörtern und der maximalen Anzahl von Wörtern kann nicht leer sein",
      speedWordMinValueLessThanMaxValue:
        "Die Mindest anzahl von Wörtern kann nicht größer als die maximale Anzahl von Wörtern sein",
      speechGrabbingIntervalTip: "Schnapp intervall> =",
      speechLengthTip: "Schnappen Sie sich den Satz lang> =",
      msUnit: "ms",
      sUnit: "Sekunden",
      grabIntervalNotNull: "Das Telefon intervall kann nicht leer sein",
      grabLengthNotNull: "Der lange Satz kann nicht leer sein",
      pleaseSelectEntity: "Bitte wählen Sie die Informations entität",
      pleaseSelectOperator: "Bitte wählen Sie die Operation",
      pleaseSelectBasicInfo: "Bitte wählen Sie die Basis informationen",
      pleaseEnterFixedValue: "Bitte geben Sie einen festen Wert ein",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Übereinstimmend zum Erfolg",
      matchError: "Match fehl geschlagen",
      mindetectionStatement: "Minimale Erkennungs anweisung",
      conceptWordholder: "Bitte wählen Sie Konzept wörter",
      elementHolder: "Bitte geben Sie ein Element ein",
      associateMedol: { tabs: { data: "Daten", operator: "Operator" } },
      repet: "Wiederholen",
      bout: "Zeiten",
      ignore: "Ignorieren Sie weniger als",
      ignoreAffix: "Satz des Wortes",
      associateData: "Daten auf dem Weg",
      pretreatmentData: "Daten vor verarbeitung",
      reviewTaskData: "Überprüfung der Aufgaben daten",
      taskData: "Daten für Qualitäts prüfungs aufgaben",
      businessData: "Geschäfts aufzeichnung",
      functionOperator: "Funktions operatoren",
      operation: "Operator",
      notAnyMatch: "Wenn ein Problem nicht getroffen wird:",
      times: "Anzahl der Vorkommen",
      newAssociateDataTip:
        "Wenn der Wert ein numerischer Typ ist, verwenden Sie die ältere Funktion",
    },
    conditionGather: {
      cycle: "Zyklus",
      rlue: "Regeln",
      delete: "Entfernen",
      sampling: "Extraktion",
      item: "Artikel",
      percent: "%",
      samplingSame: "Die gleiche Menge pro Kundendienst",
      samplingRecheck:
        "Ermöglicht die wiederholte Extraktion stichproben artiger Einträge",
      dateUnit: "Nummer",
      samplingTip:
        "Unterschied liche Regeln, die denselben Anruf abdecken, werden nicht wiederholt extrahiert, und Datensätze, die extrahiert und nicht überprüft wurden, werden nicht erneut extrahiert.",
      add: "Neue Regeln",
    },
    ruleGather: {
      title: "Regeln",
      screeningCondition: "Screening-Bedingungen",
      addRule: "Neue Regeln",
      inspector: "Qualitäts inspektor",
      ruleTip:
        "Der Verteilungs modus der Regel priorität wird in der Reihenfolge von oben nach unten zugewiesen, und die Zuweisung wird nicht wiederholt. Innerhalb der Regel oder nicht übereinstimmen der Regel elemente werden zufällig zugewiesen",
      moveUp: "Nach oben bewegen",
      moveDown: "Nach unten bewegen",
      top: "Oben",
    },
    pushRulesGather: {
      title: "Regeln",
      delete: "Entfernen",
      newPush: "Neuer Push",
      newRule: "Neue Regeln",
      sampling: "Extraktion",
      item: "Artikel",
      percent: "%",
      samplingSame: "Die gleiche Menge pro Kundendienst",
      dateUnit: "Nummer",
      screeningCondition: "Screening-Bedingungen",
      email: "Mailbox",
      emailPlaceholder:
        "Geben Sie das Postfach ein und teilen Sie es mit einem Komma",
      updateType: "Aktualisierungs typ",
      pushDetail: "Push-Details",
      pushUrl: "Push-URL",
      pushWay: "Push-Typ",
      pushMethod: "Push-Methode",
      pushContentType: "Datenformat",
      pushData: "Push-Daten",
      additionalData: "Zusätzliche Daten",
      inspectionType: "Art der Qualitäts prüfung",
      all: "Alle",
    },
    taskAdditionalData: {
      newData: "Neue Daten",
      delete: "Entfernen",
      placeholder: "Bitte geben Sie den Namen ein",
      dataPlaceholder: "Bitte geben Sie den Inhalt ein",
    },
    UdeskQaReact: {
      loadSdkError:
        "Das Laden des SDK ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
    },
    gearOptionList: { evaluation: "Bewertung" },
    tableComponentTransfer: {
      leftTitle: "Listen einträge",
      rightTitle: "Anzeige elemente",
    },
    kmTinymce: {
      link: "Link einfügen/bearbeiten",
      modalTitle: "Link einfügen",
      href: "Adresse",
      text: "Text anzeigen",
      title: "Titel",
      target: "Weg öffnen",
      none: "Keine",
      newWindow: "Öffnet in neuem Fenster",
      buttonStyle: "Button-Stil",
      close: "Schließen",
      open: "Öffnen",
      answerMessage: "Sprung/Nachricht senden",
      answerMessageTitle: "Schaltfläche Nachricht senden",
      answerMessageText: "Text anzeigen",
      answerMessageContent: "Inhalt der Nachricht",
      answerMessageButtonStyle: "Button-Stil",
      answerMessageTips:
        "Hinweis: Die Schaltfläche zum Senden von Nachrichten unterstützt nur Web kanäle. Bitte seien Sie vorsichtig bei der Konfiguration",
      answerSwitchStaff: "Sprung/Transfer manuell",
      answerSwitchStaffTitle: "Den manuellen Knopf drehen",
      answerSwitchStaffText: "Text anzeigen",
      answerSwitchStaffRule: "Regeln für Tasten",
      answerSwitchStaffButtonStyle: "Button-Stil",
      answerSwitchStaffTips:
        "Hinweis: Die manuelle Schaltfläche unterstützt nur Web kanäle. Bitte konfigurieren Sie sie sorgfältig",
      textButton: "Text-Taste",
      borderButton: "Mit Rahmen knopf",
      message: "Nachricht senden",
      switchStaff: "Handarbeit drehen",
      messageError:
        "Der Inhalt der Schaltfläche darf 10 Wörter nicht überschreiten",
      messageIsEmptyError: "Der Inhalt der Schaltfläche kann nicht leer sein",
      okText: "Bestimmt",
      cancelText: "Stornierung",
      video: "Video",
      uploadVideo: "Video hochladen",
      uploadText: "Unterstützt nur MP4 Format, Größe nicht mehr als 20M",
      videoEmptyOrUploading: "Das Video ist leer oder wird hochgeladen",
      customEmoji: "Ausdruck",
    },
    massageConfiguration: {
      pushTarget: "Benachricht igungs ziele",
      customizePushTarget: "Benutzer definierte Push-Ziele",
      sysUser: "Aktuelle System benutzer",
      sysRole: "Aktuelle System rolle",
      emailPushTarget: "Ziel senden",
      targetEmailsPlaceholder:
        "Bitte geben Sie Ihre E-Mail-Adresse ein und trennen Sie sie durch ein Komma",
      subject: "E-Mail-Name",
      SDKID: "SDK-Identifikation-ID",
      content: "Inhalt der Benachricht igung",
      sdkPushType: "Push-Schema",
      sdkPushURL: "Push-URL",
      pushData: "Push-Daten",
      sdkPushWay: "Push-Methode",
      dataType: "Datenformat",
      additionalData: "Zusätzliche Daten",
      name: "Name der Nachricht",
      filter: "Screening-Bedingungen",
      triggerType: "Trigger mechanismus",
      pushCycle: "Regelmäßige feste Frequenz",
      pushContent: "Inhalt des Berichts",
      qualityList: "Liste der Qualitäts prüfungs ergebnisse",
      designated: "Personal benennen",
      messageType: "Nachrichten typ",
    },
    taskCard: {
      template: {
        cancelCollection: "Sammlung abbrechen",
        collection: "Sammlung",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Standard-Satz:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Sammlung",
          calls: "Ein Anruf",
          createdBy: "Ersteller:",
          createdOn: "Zeit zum Erstellen:",
        },
        fileItem: {
          uIProvidesIcon: "Ui bietet icon",
          minute: "Minuten",
          callClip: "Anruf clip",
          start: "Der Anfang",
          End: "Ende",
          employees: "Mitarbeiter:",
          Customer: "Kunden:",
          sessionTime: "Sitzungs zeit:",
          reasonForRecommendation: "Empfohlener Grund:",
          addedBy: "Hinzufügen von:",
          addTime: "Zeit hinzufügen:",
        },
        modalAddFile: {
          caseTitle: "Titel des Falls",
          addSomeClipsToTheCaseLibrary:
            "Hinzufügen eines Teils eines Fragments zur Fall bibliothek",
          addToCaseBase: "Hinzufügen zur Fall bibliothek",
          storedIn: "Gespeichert in",
          pleaseSelect: "Bitte wählen Sie",
          reasonsForRecommendation: "Gründe für die Empfehlung",
          pleaseEnter: "Bitte geben Sie ein",
        },
      },
      shareModal: {
        index: {
          customer: "Kunden:",
          employees: "Mitarbeiter:",
          timeliness: "Aktualität:",
          recordingLinkValidWithinDays:
            "Der Aufnahme link ist innerhalb weniger Tage gültig",
          sharingInstructions: "Anweisungen teilen:",
          shareSuccess: "Teilen Sie den Erfolg!",
          cancel: "Stornierung",
          share: "Teilen",
          copySucceeded: "Kopieren Sie erfolgreich!",
          copyShareLink: "Link zum Teilen kopieren",
          shareWithColleagues: "Mit Kollegen teilen",
          shareWithCustomers: "Mit Kunden teilen",
          staff: "Mitarbeiter",
          selectColleagues: "Kollegen auswählen",
          pleaseSelectColleaguesToShare:
            "Bitte wählen Sie die Kollegen aus, die Sie teilen möchten",
          pleaseEnterTheSharingDescription:
            "Bitte geben Sie die Freigabe anweisungen ein",
          taskName: "Aufgaben:",
          recordingShareValidWithinDays:
            "Der Share-Link ist innerhalb weniger Tage gültig",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Die Datei konnte nicht gefunden werden!",
          sale: "Verkauf:",
          customer: "Kunden:",
          topicDetection: "Themen erkennung:",
          keyEvents: "Schlüssel ereignisse:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Kollegen auswählen",
          helpContent: "Hilfe inhalt",
          visibleRange: "Sichtbarer Bereich",
          seekCounseling: "Suchen Sie eine Beratung",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Rat einholen", concatText: "Nach" },
      },
      customerInsight: {
        index: {
          customerInformation: "Kunden informationen",
          customerInsight: "Kunden-Einblicke",
          customerLabel: "Kunden-Tags",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Sprach empfehlung",
          objectionHandlingScripts: "Dissens behandlung",
          productSellingPointScripts: "Produkt verkaufs argument",
          knowledgePointRecommendation: "Wissens punkte mpfehlung",
          relatedKnowledgePoints: "Verwandte Wissens punkte",
          recommendedCases: "Empfohlener Fall",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Sprach empfehlung",
          caseRecommendation: "Fall empfehlung",
          hit: "Treffer",
          miss: "Fehlschlag",
          supervisionItem: "Überwachungs gegenstände",
          implementation: "Ausführung",
          reInspection: "Nachprüfung",
          supervisionItemSettings: "Einstellungen für Überwachungs elemente",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Telefonanrufe",
          enterpriseWeChatCall: "Unternehmens ansprache",
          enterpriseMicroConversation: "Enterprise Micro Session",
          open: "Öffnen",
          close: "Schließen",
          conversationScenario: "Gesprächs szene",
          monitorConversationChannels: "Überwachen Sie die Sitzungs kanäle",
          permanentlyValid: "Dauerhaft wirksam",
          permanentlyInvalid: "Dauerhaft ungültig",
          customValidity: "Benutzer definiertes Ablaufdatum",
          termOfValidity: "Gültigkeit dauer",
          newRating: "Neue Bewertung",
          whenConversation: "Wenn die Sitzung",
          hit: "Treffer",
          miss: "Fehlschlag",
          time: "Zeit",
          rated: "Bewertung als",
          qualified: "Qualifiziert",
          unqualified: "Nicht qualifiziert",
          modifiedSuccessfully: "Erfolgreich modifiziert",
          classificationName: "Klassifizierung namen",
          supervisionObject: "Gegenstand der Überwachung",
          entryintoforceTime: "Wirkungs zeit",
          supervisionItemRating: "Bewertung von Aufsichts posten",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Bitte überprüfen Sie, ob die Bewertung des Überwachungs posten korrekt ist",
          ratingRules: "Bewertungs regeln",
          settingOfRatingRulesForSupervisionItems:
            "Einstellung der Bewertungs regeln für Aufsichts posten",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Schlüssel ereignisse",
          supervisionItem: "Überwachungs gegenstände",
          conversationScenario: "Gesprächs szene",
          customerLabel: "Kunden-Tags",
          reDetectionRules: "Regeln erneut erkennen",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Aufnahme herunter laden",
          downloadText: "Text herunter laden",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Bilder hochladen",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Bitte wählen Sie Bilder mit einer Größe von nicht mehr als 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Oder",
            and: "Und",
            wrong: "Nicht",
            matchTo: "Passend zu",
            rule: "Regeln",
            satisfactionOperator: "Erfüllen Sie den Operator",
            customerLabel: "Kunden-Tags",
            keyEvents: "Schlüssel ereignisse",
            knowledgePoints: "Wissens punkte",
            sessionLabel: "Sitzungs beschriftungen",
            supervisionItem: "Überwachungs gegenstände",
          },
          linkExplorer: { linkInTheText: "Link im Text:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound:
          "Die benutzer definierte Komponente wurde nicht gefunden:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "Anpassen" },
      dropdownMonit: {
        updateSucceeded: "Erfolgreiches Update",
        areYouSureToDelete: "Sicher zu löschen?",
        custom: "Anpassen",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Speichern Sie die aktuelle Berichts konfiguration === Formular",
        saveCurrentReportConfiguration:
          "Aktuelle Berichts konfiguration speichern",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Bitte geben Sie den Namen der allgemeinen Einstellungen ein, die maximale Länge beträgt 20!",
        preservation: "Speichern",
      },
      template: {
        saveCurrentReportConfiguration:
          "Aktuelle Berichts konfiguration speichern",
        updateCurrentReportConfiguration:
          "Aktualisieren der aktuellen Berichts konfiguration",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Informationen zum Arbeits auftrag",
        workOrder: "Arbeits auftrag",
        triggerScenario: "Auslösendes Szenario",
        associatedCallConversation: "Assoziierte Anrufe/Konversationen",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Testen Sie mit dem vorherigen Text/Dialog",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "Die", day: "Tag" },
      businessSummary: {
        normal: {
          equalTo: "Gleich",
          notEqualTo: "Nicht gleich",
          contain: "Enthält",
          excluding: "Nicht enthalten",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Ich bin ich, es ist ein anderes Feuerwerk @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hallo, ich bin ein Warnhinweis für die Roboter-Qualitäts prüfung, da er der Gruppe am 17. Oktober hinzugefügt wurde",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Ich bin ich, es ist ein anderes Feuerwerk @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hallo, ich bin ein Warnhinweis für die Roboter-Qualitäts prüfung, da er der Gruppe am 17. Oktober hinzugefügt wurde",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Thema Kommunikation",
          conversationSummary: "Zusammenfassung der Sitzung",
          serviceSuggestions: "Service-Beratung",
        },
        template: { smartTags: "Intelligente Tags" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Klicken Sie hier, um mehr zu erfahren" },
      schema: {
        textRobot: "Text Roboter",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Trainer-Begleitung, Dokument-Q & A, Aufgaben dialog, Formular-Q & A, Grafik-Q & A",
        capitalRetentionRobot: "Kapital erhaltung roboter",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Umfän gliches Follow-up-und Kapital einbehaltung strategien, hoch person ifi zierte Kommunikation, hohe Kapital einbehalte quote und effiziente und automatische Konvertierung von Hinweisen",
        outboundRobot: "Ausge rief Roboter",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Gegenbesuche nach Abschluss von Arbeits aufträgen, Umfragen zur Service zufriedenheit, Warnungen vor Betriebs-und Wartungs fehlern, Erinnerungen an die Auftrags lieferung, Benachricht igungen über E-Commerce-Aktivitäten, Termine für Kandidaten zur Unterstützung der Geschäfts automatisierung und zur Verbesserung der Geschäfts effizienz",
        incomingRobot: "Einatmen des Roboters",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Flexible Integration von Geschäfts systemen wie Arbeits aufträgen und Bestellungen zur Realisierung der Prozess automatisierung, maßge schneiderte Erkennung von Sprach semantischen Modellen, sofortige Geschäfts effekte; Entität (Mobiltelefon nummer, Bestell betrag, Bestell zeit, Adresse,...) Identifizieren und unterstützen Sie komplexe Unternehmen schnell",
        intelligentQualityInspection: "Intelligente Qualitäts prüfung",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Volle Qualitäts prüfung, zeitnah und effizient, Genauigkeit srate von 85%",
        intelligentSessionAnalysis: "Intelligente Konversation analyze",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Einblicke in Geschäfts mängel, Hören auf Kunden stimmen und Verbesserung der Marketing transformation",
        enterpriseWechatConversationAnalysis:
          "Analyse von Unternehmens-WeChat-Sitzungen",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Identifizieren Sie potenzielle Geschäfts möglichkeiten und Risiken effizient und erhöhen Sie die Conversion-Rate des Marketings um 20%",
        assistant: "Sitz assistent",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Der Agenten assistent kann sicherstellen, dass der Kundendienst des Anfängers den Service abschließt und die Qualität der Anrufe für den Kunden service verbessert. Lassen Sie jeden Sitz Ihr Trumpf sein",
        digitalPeople: "Digitale Menschen",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          "Anthropo morphe Kommunikation von Angesicht zu Angesicht, Unterstützung der multimodalen Interaktion von Ton, Text, Video und Touch, Realisierung eines immer siven Kommunikation erlebnisses, Verbesserung des Markenimages und der Benutzer erfahrung",
        aIKnowledgeZhongtaiEnterpriseSearch:
          "AI Knowledge China Taiwan · Unternehmens suche",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Wissens suche, Wissens austausch, Dokumenten suche, Datenbank-Docking-Suche",
        aIKnowledgeCenterKCsKnowledgeBase:
          "AI Knowledge Zhong tai · KCS Knowledge Base",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Bereitstellung eines vollständigen Lebenszyklus managements von Wissen, einer einheitlichen Wartung von Robotern und IM-Wissens datenbanken, Unterstützung des Andockens von Wissens datenbanken von Dritt anbietern, Wissens vorlagen, atomarem Wissen und anderen leistungs starken Funktionen, um eine bequemere Erfahrung im Wissens management zu ermöglichen",
        aIKnowledgeCenterKnowledgeMap:
          "KI-Wissens zentrale Station · Wissens karte",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "One-Stop-Atlas-Erstellung integriert Unternehmens wissen, große Bilder zeigen Wissens details und Docking-Funktionen, um ein maßge schneider tes Erlebnis zu schaffen",
        modularDevelopmentPlatformOriginalEngineASR:
          "Modulare Entwicklungs plattform · Original motor · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Durch die Verwendung von End-to-End-Algorithmen zur Erzielung eines maßge schneider ten Geschäfts effekts liegt die Erkennungs rate natürlicher Szenen über 90% und die personal isierte Vokabel erkennung über 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Modulare Entwicklungs plattform · Intelligentes Marketing",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Verwenden Sie Deep-Learning-Algorithmen und kombinieren Sie Benutzer porträts, um genaue Produkt empfehlungen zu erzielen und die Umsatz umwandlung zu verbessern",
        modularDevelopmentPlatformUserPortrait:
          "Modulare Entwicklungs plattform · Benutzer porträt",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Erstellen Sie Benutzer porträts genau basierend auf Benutzer daten und Dialog daten und beschreiben Sie die Kunden merkmale vollständig",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Modulare Entwicklungs plattform · Intelligente Empfehlung",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Empfehlen Sie gemäß Benutzer porträts und Produkt porträts automatisch Produkte, die für Benutzer von Interesse sein können, und realisieren Sie personal isierte Empfehlungen für Tausende von Menschen",
        modularDevelopmentPlatformNLPPAAS:
          "Modulare Entwicklungs plattform · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Bietet eine hohe Genauigkeit der NLP-Grund funktionen, die die zugrunde liegenden Funktionen unterschied licher Granularität wie Wörter, Sätze, Artikel usw. abdecken",
      },
    },
    qualityScore: {
      index: {
        show: "Anzeige:",
        displayBySecondaryClassification:
          "Anzeige nach Sekundär klassifizierung",
        showByRule: "Anzeige nach Regeln",
        pleaseEnterClassificationruleName:
          "Bitte geben Sie den Namen der Kategorie/Regel ein",
        clickCopy: "Klicken Sie auf Kopieren",
        hitDetailsOfOperatorsInRules:
          "Details zum Beacon-Treffer in den Regeln:",
        hit: "Treffer",
        miss: "Fehlschlag",
        filter: "Screening:",
        clickToViewDetails: "Klicken Sie hier, um Details anzuzeigen",
      },
      components: {
        flowNodeList: {
          branch: "Punkte",
          pleaseSelect: "Bitte wählen Sie",
          viewTheCompleteProcess: "Sehen Sie sich den vollständigen Prozess an",
          thereIsCurrentlyNoProcessAvailable: "Kein Prozess",
        },
        pointItem: { xiangHit: "Artikel trifft" },
        flowNodeListModal: {
          index: { completeProcess: "Vollständiger Prozess" },
        },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Wenn sich die Aufgabe der Prozess qualitäts prüfung geändert hat, wenden Sie sich bitte erneut an.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Diese Daten wurden regelmäßig gelöscht",
        subject: "Themen:",
        description: "Beschreibung:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask:
              "Zu Qualitäts prüfungs aufgaben hinzufügen",
          },
        },
      },
    },
    relatedDetail: {
      index: {
        associatedCallConversation: "Assoziierte Anrufe/Konversationen",
      },
    },
    samplingTarget: {
      index: {
        time: "Zeit",
        samplingQuantityIsLessThan:
          "Die Anzahl der Stichproben ist niedriger als",
        alertWhen: "Vorwarnung",
        everyday: "Täglich",
        weekly: "Wöchentlich",
        monthly: "Jeden Monat",
        samplingInspectionTargetSavedSuccessfully:
          "Das Probenahme ziel wurde erfolgreich gespeichert",
        personnel: "Personal",
        eachCustomerService: "Jeder Kunden service",
        cycle: "Zyklus",
        samplingInspectionTarget: "Probenahme ziele",
        strip: "Artikel",
        alertSettings: "Frühwarn einstellungen",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Kunden service",
        complete: "Fertig",
        Objective: "/Ziel",
        everyday: "Täglich",
        weekly: "Wöchentlich",
        monthly: "Jeden Monat",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Gruppen-Chat-Interaktion srate",
        maximumResponseIntervalTime: "Das längste Antwort intervall",
        numberOfPreemptiveCalls: "Anzahl der Gespräche",
        numberOfMessages: "Anzahl der Nachrichten",
        strip: "Artikel",
        proportionOfMessages: "Anteil der Nachrichten",
        speechPercentage: "Sprach anteil",
        longestCustomerTalk: "Längste Kunden rede",
        numberOfIndepthDiscussions: "Die Anzahl der eingehenden Diskussionen",
        second: "Zeiten",
        maximumDurationOfContinuousSpeech: "Die längste Dauer des Sprechens",
        questionFrequency: "Häufigkeit der Fragen",
        customer: "Kunden",
        keyEvents: "Schlüssel ereignisse:",
        opportunity: {
          index: {
            generationFailed: "Generierung fehl geschlagen",
            generateOpportunityPoints: "Generieren Sie Opportunity-Punkte",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Generierung fehl geschlagen",
            generateSummary: "Zusammenfassung der Generierung",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Sicher zu löschen?",
        add: "Hinzufügen",
        opportunityPoints: "Punkt der Gelegenheit",
        conversationSummary: "Zusammenfassung der Sitzung",
        cancel: "Stornierung",
        determine: "Bestimmt",
        edit: "Bearbeiten",
        establish: "Erstellen",
        conversationalInsights: "Einsicht in die Sitzung",
        keyEvents: "Schlüssel ereignisse",
        numKeyEvents: "Ein Schlüssel ereignis",
        questionAnalysis: "Analyse von Fragen",
        find: "Entdeckung",
        questions: "Fragen",
        conversationAction: "Sitzungs aktion",
        customer: "Kunden",
        sale: "Verkauf",
        sessionLabel: "Gesprächs szene",
        employeeQuestions: "Mitarbeiter fragen",
        customerQuestions: "Kunden fragen",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Kunden",
        customerService: "Kunden service",
        replyTo: "Antworte auf",
        addressee: "Empfänger:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Wieder identifizieren",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Die erneute Identifizierung verbraucht die Trans literations zeit. Bitte seien Sie vorsichtig",
        determine: "Bestimmt",
        executionSuccessful: "Erfolgreiche Ausführung",
        addToQualityInspectionTask: "Zu Qualitäts prüfungs aufgaben hinzufügen",
        batchOperation: "Stapel betrieb",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Erfolg hinzufügen",
        addToQualityInspectionTask: "Zu Qualitäts prüfungs aufgaben hinzufügen",
        qualityInspectionTask: "Qualitäts prüfungs aufgaben",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Erkennung von Rollen",
        detectionRange: "Erfassungs bereich",
        sentence: "Satz",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Diese Daten wurden regelmäßig gelöscht",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Zurück zum Geschäfts system" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Problem extraktion",
        businessSummary: "Geschäfts zusammenfassung",
        keyWord: "Schlüssel wörter",
      },
      components: {
        problemExtraction: {
          no: "Nein",
          customerServiceDidNotAnswer: "Kundendienst unbeantwortet",
          customerServiceHasAnswered: "Kundendienst beantwortet",
          problemUnresolved: "Problem ungelöst",
          theProblemHasBeenResolved: "Problem behoben",
          customerIssue: "Kunden fragen:",
          customerServiceAnswer: "Antwort des Kundendienstes:",
          reExtractQuestions: "Problem erneut extrahieren",
          clickToExtractQuestions:
            "Klicken Sie hier, um die Frage zu extrahieren",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Porträt Name",
            backgroundDescription: "Hintergrund beschreibung",
            customerLabel: "Kunden-Tags",
            labelManagement: "Etiketten verwaltung",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Neu" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Fragen Sie nach Einstellungen" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Erhöhen Sie die Befragung",
              remove: "Entfernen",
              addQuestioning: "Frage hinzufügen",
              section: "Die",
              secondInquiry: "Zweite Befragung",
              questioningScore: "Nach der Punktzahl fragen",
            },
          },
        },
        index: {
          accordingToRules: "Nach den Regeln",
          byScore: "Nach Punktzahl",
          whenMissRule: "Wenn die Regel nicht getroffen wird",
          whenConductingQuestioning: "Zeit zu fragen",
          pleaseSelectARule: "Bitte wählen Sie die Regel",
          ruleCannotBeEmpty: "Regeln können nicht leer sein",
          currentScriptScore: "Wenn die Punktzahl ≤",
          pleaseEnterTheScore: "Bitte geben Sie die Punktzahl ein",
          scoreCannotBeEmpty: "Punkte dürfen nicht leer sein",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Erzähler:" },
          pPT: { slide: "Diashow" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Sprach kommentare",
              script: "Wörter",
              semantics: "Semantisch",
              violatingWords: "Verstoß gegen das Wort",
              speechSpeed: "Sprech geschwindigkeit",
              emotion: "Emotionen",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Sprach kommentare",
              regenerate: "Regenerieren",
              generate: "Generieren",
              hitSituation: "Schlager-Situation",
              noSemanticTagsMatched:
                "Nicht mit semantischen Labels übereinstimmen",
              matchToSemanticTags: "Übereinstimmend zu semantischen Tags",
              appealToBeSubmitted: "Beschwerde eingereicht werden",
              ratingHasBeenReviewed: "Die Bewertung wurde überprüft",
              ratingAppealInProgress: "Bewertungs beschwerde",
              expressionScore: "Ausdrücken der Punktzahl",
              moodParticlesTimes: "Tische Wörter, {0} mal",
              changeTheModalParticlesTo: "Ändern Sie das Tonwort in",
              second: "Zeiten",
              interrogation: "Fragen",
              questioningWithoutScoring: "Fragen ohne Wertung",
              branch: "Punkte",
              matchingRate: "Übereinstimmung srate",
              ratingAppeal: "Beschwerde bewerten",
              changeTo: "Wechseln Sie zu",
              wordPerMinute: "Wort pro Minute",
              completeScript: "Vollständige Worte",
              incompleteScript: "Unvollständige Worte",
              semanticHit: "Semantische Treffer",
              semanticMisses: "Semantische Fehlschläge",
              scriptViolation: "Verstoß gegen das Wort",
              modified: "Geändert",
              notChanged: "Nicht geändert",
              keyWord: "Schlüssel wörter",
              semanticLabels: "Semantische Tags",
              semanticMatchingRate: "Semantische Übereinstimmung srate",
              violatingWords: 'Verstoß gegen das Wort"',
              delete: "Löschen",
              unmatched: "Nicht übereinstimmend",
              matching: "Übereinstimmungen",
              matchTo: "Passend zu",
              notMatchedTo: "Nicht übereinstimmend zu",
              scriptScore: "Score",
              standardScript: "Standard-Wörter",
              emotionalScore: "Emotionale Punktzahl",
              speechSpeedScore: "Sprech geschwindigkeit Score",
              viewResolution: "Analyse anzeigen",
              nodeConfiguration: "Konfiguration von Knoten",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Die Antwortzeit für diese Frage ist Überstunden und der Antwort inhalt wird automatisch gesendet",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Die Antwortzeit für diese Frage ist Überstunden und der Antwort inhalt wird automatisch gesendet",
          },
          correctBtn: {
            saveSuccessful: "Speichern Sie erfolgreich",
            errorCorrection: "Fehler korrektur",
            cancel: "Stornierung",
            preserve: "Speichern",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Regeln für die Wertung",
            cancel: "Stornierung",
            determine: "Bestimmt",
          },
          scoringRuleItem: {
            whole: "Alle",
            arbitrarily: "Beliebig",
            pleaseEnterARuleName: "Bitte geben Sie den Namen der Regel ein",
            standardScript: "Standard-Wörter",
            if: "Wenn",
            and: "Und",
            or: "Oder",
            thenScore: "Punktzahl",
            pleaseEnterAScore: "Bitte geben Sie die Punktzahl ein",
            bonusPoints: "Extrapunkte",
            minusPoints: "Punkt absenkung",
            hit: "Treffer",
            misses: "Fehlschlag",
            keyWord: "Schlüssel wörter",
            semantics: "Semantisch",
          },
          title: {
            ruleValidation: "Überprüfung der Regeln",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Bewertungs regeln (die Gesamt punktzahl der aktuellen Wörter ist",
            points: "Punkte)",
            editRules: "Regeln bearbeiten",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Kein Etikett" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Kommunikation ziele",
              scoringCriteria: "Bewertungs kriterien",
            },
          },
        },
        index: {
          generating: "Generieren",
          generateDialogueFramework: "Konversation rahmen generieren",
          theme: "Thema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Eingabe aufforderung",
            generatePromptLanguage: "Eingabe aufforderung generieren",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Phase der Kommunikation",
          communicationObjectives: "Kommunikation ziele",
          scoringCriteria: "Bewertungs kriterien",
          suggestionsForImprovement: "Vorschläge zur Förderung",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Abläufe punkten",
            contentIntegrity: "Vollständigkeit des Inhalts:",
            NumberOfCompletedProcesses:
              "%, Die Anzahl der abgeschlossenen Prozesse",
            keyHitPointsTotal: "Wichtige Punkte: insgesamt",
            notReached: "Nicht erreicht",
            accurateFollowupAccumulationOfStandardScripts:
              "Genaue Nach lesung: Anhäufung von Standard-Sprach fähigkeiten",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Ein Wort, die Schüler folgen der Gesamtzahl der Wörter",
            aWord: "Worte",
            languageNormsYes: "Sprach spezifikation: ja",
            emotionsAppearing: "Emotionen: erscheinen",
            speechSpeedSpeakingTooFast: "Sprech geschwindigkeit: Zu schnell",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Wortlaut: Zu viele Wortlaut",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Zum Ignorieren von Tags hinzufügen",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Sind Sie sicher, dass Sie Smart Labels erneut ausführen möchten?",
        reExecute: "Wiederausführen",
      },
      component: { successfullyAdded: "Erfolg hinzufügen" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Schließen Sie alle Benachricht igungen",
        reQualityInspectionTask: "Die Aufgabe der erneuten Qualitäts prüfung",
        requalityInspection: "Nachprüfung",
        waiting: "Warten",
        viewDetails: "Details anzeigen",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Erkennungs zeit:" } },
        },
        template: { responseOpeningSentence: "Antwort auf den Anfangs satz" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Generierung fehl geschlagen",
            generate: "Generieren",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Keine Daten" } },
      },
      index: { regenerate: "Regenerieren" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Sicher zu löschen?",
        operationSuccessful: "Erfolgreiche Operation",
      },
      template: { delete: "Löschen" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Bitte wählen Sie die Frage!",
        similarQuestionRecommendation: "Ähnliche Empfehlung",
        selectAllOnPage: "Wenn alle Seiten ausgewählt sind",
        cancel: "Stornierung",
        preserve: "Speichern",
        changeBatch: "Ändern Sie eine Charge",
      },
    },
    listControl: { index: { columnControl: "Spalten steuerung" } },
    loginUserAvatar: { index: { switch: "Umschalten" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Beim Hinzufügen von Daten in Echtzeit bleibt die Ausführung von Qualitäts prüfungs aufgaben zeit aufwändig. Die spezifische Zeit hängt von der Größe der Qualitäts prüfungs daten ab und reicht von wenigen Minuten bis zu zwei Stunden.",
      },
    },
    bEditor: {
      index: {
        delete: "Löschen",
        cancel: "Stornierung",
        confirm: "Bestimmt",
        insert: "Einfügen",
        width: "Breite",
        height: "Höhe",
        clearContent: "Inhalt löschen",
        revoked: "Widerruf",
        redo: "Wiederherstellen",
        fontSize: "Schriftgröße",
        rowHeight: "Zeilen höhe",
        wordSpacing: "Wort abstand",
        paragraphIndent: "Einrückung des Absatzes",
        increaseIndent: "Erhöhen Sie die Einrückung",
        decreaseIndent: "Reduzierung der Einrückung",
        border: "Rand",
        color: "Farbe",
        textColor: "Text farbe",
        backgroundColor: "Hintergrund farbe",
        temporaryColor: "Temporäre Farben",
        bold: "Fett",
        italic: "Kursiv",
        underline: "Unterstreichen",
        strikethrough: "Durch gestrichen",
        font: "Schriftart",
        textAlignment: "Text ausrichtung",
        left: "Auf der linken Seite",
        centered: "Mitte",
        right: "Auf der rechten Seite",
        bothEnds: "Die beiden Enden",
        leftFloat: "Linke schwebend",
        rightFloat: "Schweben auf der rechten Seite",
        subrupt: "Hochmarkiert",
        subscript: "Unter dem Index",
        clearStyle: "Stil löschen",
        title: "Titel",
        regular: "Routine",
        orderedList: "Ordentliche Liste",
        unorderedList: "Liste der Unordnung",
        reference: "Zitat",
        code: "Code",
        link: "Link",
        clearLink: "Link löschen",
        horizontalLine: "Horizontale Linie",
        media: "Medien",
        mediaLibrary: "Mediathek",
        smallExpression: "Kleiner Ausdruck",
        fullScreen: "Vollbild",
        exitFullScreen: "Vollbild beenden",
        enterLinkText: "Link text eingeben",
        enterLinkAddress: "Geben Sie die Linka dresse ein",
        enterTheLinkAddressAndPressEnter:
          "Geben Sie die Linka dresse ein und geben Sie die Eingabetaste ein",
        openInNewWindow: "Öffnet in neuem Fenster",
        removeLink: "Link entfernen",
        playingAudioFiles: "Audiodatei abspielen",
        playVideoFiles: "Videodateien abspielen",
        embeddedMedia: "Eingebettete Medien",
        image: "Bild",
        video: "Video",
        audio: "Audio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Name",
        description: "Beschreibung",
        type: "Typ",
        status: "Zustand",
        actions: "Betrieb",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Name der Aufgabe",
        description: "Beschreibung",
        form: "Qualitäts prüfungs formular",
        actions: "Betrieb",
        createUser: "Schöpfer",
      },
      samplingRecords: {
        createTime: "Zeit",
        type: "Typ",
        operateUser: "Betreiber",
        number: "Anzahl hinzufügen",
        filterConditionList: "Screening-Bedingungen",
        sampleRuleStr: "Stichproben bedingungen",
        timeGreaterThan: "Zeit ist größer als",
        timeLessThan: "Die Zeit ist kleiner als",
      },
    },
    customFilters: {
      createdAt: "Gesprächs zeit",
      customerId: "Kunden",
      agentId: "Kunden service",
      userGroupId: "Kundendienst gruppe",
      state: "Zustand",
      lastEvaluateUserId: "Letzter bewertete Person",
      submitTime: "Letzte Einreichung szeit",
      totalScore: "Gesamt punktzahl",
      formItemForbidIdList: "Verbotene Punkte",
      formItemDeadlyIdList: "Tödliches Element",
    },
    samplingInspection: {
      callTime: "Gesprächs zeit",
      dialogTime: "Zeit für Gespräche",
      originator: "Personen zuordnen",
      createTime: "Zeit erstellen",
      updateTime: "Aktualisierungszeit",
      status: "Prozess zustand",
      score: "Punktzahl",
      actions: "Betrieb",
      reviewer: "Prüfer",
      samplingOfficer: "Probenahme",
      id: "ID des Anruf protokolls",
      time: "Gesprächs zeit",
      phone: "Kunden telefon",
      staff: "Kunden service",
      inspectionStatus: "Qualitäts prüfungs status",
      samplingStatus: "Status der Probenahme",
      inspectionGrade: "Bewertung",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Standard wörter",
        synonymWords: "Synonyme",
        synonymWordsNumber: "Anzahl der Synonyme",
        useFrequency: "Häufigkeit der Nutzung",
        effectiveness: "Wirksamkeit",
        operation: "Betrieb",
      },
      common: {
        useFrequency: "Häufigkeit der Nutzung",
        effectiveness: "Wirksamkeit",
        operation: "Betrieb",
      },
      professionalWords: {
        professionalWords: "Fachbegriffe",
        useFrequency: "Verwenden von Statistiken",
        homophonic: "Heiße Wörter (Homophone Substitution)",
        target: "Substitution von Substantiven",
        targetWords: "Wörter ersetzen",
        homophonyWords: "Homophone abgeschirmte Wörter",
        operation: "Betrieb",
      },
      attentionWords: "Worte der Aufmerksamkeit",
      ignoreWords: "Wörter ignorieren",
      wordsWeight: {
        selfDefineWeightWords: "Benutzer definierte Gewichtung wörter",
        weightWords: "Gewicht Wörter",
        weight: "Gewichte",
        useFrequency: "Häufigkeit der Nutzung",
        effectiveness: "Wirksamkeit",
        operation: "Betrieb",
      },
      corpusData: {
        corpusDescription: "Korpus beschreibung",
        updateTime: "Aktualisierungszeit",
        wordsCount: "Anzahl der Wörter",
        effectiveness: "Wirksamkeit",
        operation: "Betrieb",
        corpusContent: "Inhalt",
      },
    },
    semanticTags: {
      title: "Semantische Tags",
      tags: "Standard-Satz",
      orgName: "Gehört zur Organisation",
      tagCount: "Anzahl der Trainings einheiten",
      usingCount: "Anzahl der Referenzen",
      activeFlag: "Wirksamkeit",
      suggestion: "Trainings empfehlungen",
      actions: "Betrieb",
    },
    intelligentTags: {
      tagName: "Name",
      tagDesc: "Beschreibung",
      tagRefCnt: "Anzahl der Markierungen",
      categoryName: "Etiketten klassifizierung",
      eventName: "Ereignis klassifizierung",
      tagStatus: "Wirksamkeit",
      actions: "Betrieb",
      tagType: "Art der Beschriftung",
    },
    dataPreprocess: {
      name: "Name",
      desc: "Beschreibung",
      dataSource: "Einschränkungen bei Datenquellen",
      type: "Typ",
      status: "Wirksamkeit",
      actions: "Betrieb",
    },
    informationEntities: {
      name: "Name",
      apiName: "API-Name",
      type: "Typ",
      remark: "Beschreibung",
      activeFlag: "Wirksamkeit",
      actions: "Betrieb",
    },
    interactiveRecognition: {
      questionAndAnswer: "Standard Fragen & Antworten",
      type: "Typ",
      status: "Zustand",
      actions: "Betrieb",
      question: "Das Problem",
    },
    role: { id: "ID", name: "Name", actions: "Betrieb" },
    uploadDownload: {
      taskName: "Dateiname",
      createTime: "Datum",
      size: "Größe",
      createUserName: "Betreiber",
      status: "Zustand",
      result: "Ergebnisse",
      actions: "Betrieb",
    },
    taskCenterList: {
      name: "Inhalt der Aufgabe",
      createTime: "Zeit für die Einreichung",
      startTime: "Zeit für den Start",
      endTime: "Endzeit",
      createUserName: "Betreiber",
      status: "Zustand",
      result: "Ergebnisse",
      actions: "Betrieb",
    },
    customerInformation: {
      title: "Informationen zum Kunden service",
      data: "Nur abnormale Daten anzeigen",
      list: {
        agentNo: "Arbeits nummer/Kundendienst-ID",
        agentName: "Kunden service",
        agentTel: "Telefon",
        agentEmail: "Mailbox",
        updateTime: "Aktualisierungszeit",
        actions: "Betrieb",
      },
      upload: {
        second:
          "2. Klicken Sie auf die Schaltfläche Importieren, um eine EXCEL-Datei hochzuladen, die dem Vorlagen format entspricht",
      },
    },
    IndexManagement: {
      title: "Verwaltung von Indikatoren",
      list: {
        indicatorName: "Name",
        indicatorDesc: "Beschreibung",
        activity: "Wirksamkeit",
        inspectDataSource: "Datenquellen",
        sysType: "Typ",
        agentGroups: "Kundendienst gruppe",
        actions: "Betrieb",
      },
      detail: {
        nameEmpty: "Der Name kann nicht leer sein",
        descript: "Beschreibung",
        descriptEmpty: "Beschreibung kann nicht leer sein",
        active: "Wirksamkeit",
        activeEmpty: "Wirksamkeit kann nicht leer sein",
        dataSource: "Datenquellen",
        dataSourceEmpty: "Die Datenquelle kann nicht leer sein",
        grounp: "Kundendienst gruppe",
        grounpEmpty: "Das Kundendienst team kann nicht leer sein",
        grounpHolder: "Bitte wählen Sie die Kundendienst gruppe",
        type: "Bewertungs methode",
        typeNone: "Die Bewertungs methode kann nicht leer sein",
        base: "Grund punkte",
        baseNone: "Die Grund punktzahl kann nicht leer sein",
        positive: "Positive Faktoren",
        positiveNone: "Positive Faktoren können nicht leer sein",
        customAdd: "Filter hinzufügen",
        negative: "Negative Faktoren",
        negativeNone: "Negative Faktoren können nicht leer sein",
        customOK: "Filter bedingungen:",
        judgeStrategy: "Bedingung regeln:",
        other: "Andere Faktoren",
        otherAdd: "Faktoren hinzufügen",
        agent: "Rohdaten",
        section: "Anzahl der Intervalle",
        sectionNone: "Die Anzahl der Intervalle kann nicht leer sein",
        step: "Intervall schritt",
        stepNone: "Intervall schritt",
        save: "Speichern",
        cancel: "Stornierung",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Alle",
      staff: "Nur ich",
      department: "Diese Abteilung und darunter",
    },
    gongStatisticsDate: { week: "Woche", month: "Monat", season: "Saison" },
    gongCoachTaskStatus: {
      wait: "Beratung",
      already: "Coaching",
      ignore: "Ignoriert",
    },
    callRangeStatus: {
      all: "Alle Anrufe",
      sale: "Mitarbeiter initiiert",
      customer: "Kunden initiierung",
    },
    todoListType: {
      later: "Später zuhören",
      help: "Beratung um Hilfe",
      contact: "Später kontaktieren",
      send: "Informationen senden",
      warn: "Risiko warnung",
    },
    frequencyTypes: {
      everyDay: "Täglich",
      everyWeek: "Wöchentlich",
      everyMonth: "Jeden Monat",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Nicht klass ifi zierte Daten training",
        markedDataCorrigenda: "Markierte Daten Errata",
        classifiedDataCheck: "Verifikation der klass ifi zierten Daten",
        markedHistoryRecord: "Geschichte markieren",
        historyDataRecalculate: "Historische Daten neu berechnen",
      },
      dataExtractMode: {
        random: "Völlig zufällig",
        intelligentRecommend: "Intelligente Empfehlung",
        custom: "Anpassen",
        specifiedClass: "Geben Sie die Klassifizierung an",
      },
      smartRatesList: {
        markRate: "Kennzeichnung srate",
        corrigendaRate: "Errata",
        kl: "K-L divergierung",
        signalNoiseRate: "Signal-Rausch-Verhältnis",
      },
    },
    visibleRangeTypes: {
      all: "Alle",
      customerGroup: "Kundendienst gruppe",
      userDefined: "Anpassen",
    },
    samplingRateTypes: {
      everyDay: "Jeden Tag ziehen",
      everyWeek: "Wöchentliche Extraktion",
    },
    taskRemindTypes: {
      confirm:
        "Senden Sie eine Erinnerung, wenn das Aufgaben protokoll der Qualitäts prüfung bestätigt werden muss",
      appeal:
        "Senden Sie eine Erinnerung, wenn ein Qualitäts prüfungs aufgaben protokoll eine Beschwerde ein leitet",
    },
    weekDays: {
      monday: "Montag",
      tuesday: "Dienstag",
      wednesday: "Mittwoch",
      thursday: "Donnerstag",
      friday: "Freitag",
      saturday: "Samstag",
      sunday: "Sonntag",
    },
    reportDateTypes: {
      dailyReport: "Tägliche Berichte",
      weeklyReport: "Wöchentlicher Bericht",
    },
    samplingRangeDayTypes: { today: "Heute", yesterday: "Gestern" },
    samplingRangeWeekTypes: {
      thisWeek: "Diese Woche",
      lastWeek: "Letzte Woche",
    },
    samplingRuleTypes: {
      proportion: "Proportionale Stichprobe",
      random: "Zufalls stichproben",
    },
    taskStatusTypes: {
      waitEvaluate: "Zu bewerten",
      waitSubmit: "Absenden",
      waitConfirm: "Zu bestätigen",
      waitReview: "Ausstehende Überprüfung",
      completed: "Abgeschlossen",
      all: "Alle",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Direkte Bewertung",
        weightedScore: "Gewichte Bewertung",
        deductScore: "Punkt abzug",
        directScoreSecondLevel: "Direkte Bewertung-Stufe 2",
        weightedScoreSecondLevel: "Gewichtung bewertung-Stufe 2",
        deductScoreSecondLevel: "Punkt abzug-Stufe 2",
      },
    },
    fieldOperators: {
      is: "Gleich",
      not: "Nicht gleich",
      contains: "Enthält",
      notContains: "Nicht enthalten",
      not_contains: "Nicht enthalten",
      containsAny: "Enthält beliebig",
      contains_any: "Enthält beliebig",
      is_any: "Enthält beliebig",
      not_any: "Enthält keine beliebige",
      notContainsAny: "Enthält keine beliebige",
      not_contains_any: "Enthält keine beliebige",
      containsAll: "Alle enthalten",
      contains_all: "Alle enthalten",
      notContainsAll: "Enthält nicht alle",
      nont_contains_all: "Enthält nicht alle",
      isNull: "Ist leer",
      is_null: "Ist leer",
      isNotNull: "Nicht leer",
      is_not_null: "Nicht leer",
      greaterThan: "Größer als",
      greater_than: "Größer als",
      greaterThanEq: "Größer als gleich",
      greater_than_eq: "Größer als gleich",
      lessThan: "Weniger als",
      less_than: "Weniger als",
      lessThanEq: "Weniger als gleich",
      less_than_eq: "Weniger als gleich",
      between: "Zwischen",
      notBetween: "Nicht zwischen",
      not_between: "Nicht zwischen",
      relative: "Gleich",
      set: "Auf gesetzt",
      belongs: "Gehört zu",
      notBelongs: "Gehört nicht dazu",
      not_belongs: "Nicht gleich",
      today: "Heute",
      yesterday: "Gestern",
      the_day_before_yesterday: "Vor gestern",
      tomorrow: "Morgen",
      next_seven_day: "Die nächsten sieben Tage",
      last_seven_day: "Die letzten sieben Tage",
      this_week: "Diese Woche",
      next_week: "Nächste Woche",
      last_week: "Letzte Woche",
      this_month: "Diesen Monat",
      next_month: "Nächsten Monat",
      last_month: "Letzten Monat",
      this_year: "Dieses Jahr",
      next_year: "Nächstes Jahr",
      last_year: "Letztes Jahr",
      exact_match: "Präzise Übereinstimmung",
      add_value: "Neuer Mehrwert",
      remove_value: "Wert löschen",
      set_null: "Leer",
      isAny: "Beliebig gleich",
      notAny: "Beliebig ist nicht gleich",
      belongTo: "Gehört zu",
      notBelongTo: "Gehört nicht dazu",
      hasAny: "Enthält beliebig",
      notHasAny: "Enthält keine beliebige",
      prefixContains: "Der Anfang ist gleich",
      prefixNotContains: "Der Anfang ist nicht gleich",
      suffixContains: "Das Ende ist gleich",
      suffixNotContains: "Das Ende ist nicht gleich",
      nextSevenDay: "In den nächsten 7 Tagen",
      lastSevenDay: "Die letzten 7 Tage",
      thisWeek: "Diese Woche",
      nextWeek: "Nächste Woche",
      lastWeek: "Letzte Woche",
      thisMonth: "Diesen Monat",
      nextMonth: "Nächsten Monat",
      lastMonth: "Letzten Monat",
      thisYear: "Dieses Jahr",
      nextYear: "Nächstes Jahr",
      lastYear: "Letztes Jahr",
      after: "Später (einschl ießlich des Tages)",
      before: "Früher als (einschl ießlich des Tages)",
      allMatching: "Alle Übereinstimmungen",
      allNotMatching: "Alle stimmen nicht überein",
      hit: "Treffer",
      not_hit: "Fehlschlag",
    },
    fieldsOperators: {
      is: "Gleich",
      is_null: "Zur gleichen Zeit leer",
      head_equal: "Der Anfang ist gleich",
      tail_equal: "Das Ende ist gleich",
      is_not_null: "Gleichzeitig nicht leer",
      not: "Nicht gleich",
      diff_equal: "Der Unterschied ist gleich",
      diff_greater_than: "Der Unterschied ist größer als",
      diff_greater_than_eq: "Die Differenz ist größer oder gleich",
      diff_less_than: "Die Differenz ist geringer als",
      diff_less_than_eq: "Die Differenz ist kleiner als gleich",
      hour_equal: "Die gleiche Stunde",
      day_equal: "Am selben Tag",
      week_equal: "Die gleiche Woche",
      month_equal: "Im selben Monat",
      year_equal: "Im selben Jahr",
      day: "Tag",
      minute: "Minuten",
      hour: "Stunden",
      part_equal: "Teil gleich",
    },
    samplingColumnCaptions: {
      created_at: "Gesprächs zeit",
      agent_id: "Kunden service",
      get_direction: "Art des Anrufs",
      call_result: "Ergebnisse des Anrufs",
      survey: "Bewertung",
      derived_from_id: "Quelle",
      quit_queue_why: "Warteschlangen status",
      queuing_duration: "Das Anstehen ist zeit aufwändig",
      talking_seconds: "Dauer des Anrufs",
      ringing_seconds: "Glocken zeit",
      hangup_by: "Anruf auflegen",
    },
    callTypes: {
      callIn: "Rufen Sie an",
      callOut: "Atme aus",
      threeParty: "(Drei Parteien)",
      consultation: "(Beratung)",
      insert: "(Starker Einsatz)",
      monitor: "(Überwachung)",
      transfer: "(Übertragung)",
      intercept: "(Abfangen)",
      transferOutside: "(Übernehmen Sie eine externe Leitung)",
      threePartyOutside: "(Drei glied rige Außenlinie)",
      consultingOutside: "(Beratungs leitung)",
    },
    callResults: {
      staffAnswer: "Kundendienst beantwortet",
      staffNotAnswer: "Kundendienst nicht beantwortet",
      customerAnswer: "Kunden annahme",
      customerNotAnswer: "Kunde nicht empfangen",
      phoneBusy: "Telefon besetzt",
      phoneOffline: "Telefon offline",
      customerSpeedHangUp: "Kunden schnell hängen",
      customerHangUp: "Kunde legt auf",
      queueTimeout: "Auszeit in der Schlange",
      queueGiveUp: "Aufgeben von Warteschlangen",
      outlineAnswer: "Leitung empfangen",
      outlineNotAnswer: "Die Außenlinie ist nicht anges ch lossen",
      noChooseQueue: "Warteschlange nicht ausgewählt",
    },
    defaultSurveys: {
      noEvaluation: "Nicht bewertet",
      noNeedToEvaluate: "Keine Bewertung erforderlich",
    },
    queueStates: {
      queueSuccess: "Erfolgreich in der Schlange",
      queueTimeout: "Auszeit in der Schlange",
      queueGiveUp: "Aufgeben von Warteschlangen",
      noStaffOnline: "Kein Kunden service online",
    },
    callHangers: {
      customer: "Kunden",
      staff: "Kunden service",
      outline: "Außerhalb",
    },
    workFlowTypes: {
      noAppealsMode: "Kein Beschwerde modus",
      appealsMode: "Appell-Modus zulassen",
    },
    fieldCategories: {
      standardCondition: "Kriterien für das Standard-Screening",
      customerCondition: "Benutzer definierte Filter bedingungen",
    },
    sampleTypes: {
      automatic: "Automatische Stichprobe",
      manual: "Manuelle Probenahme",
    },
    defaultFields: {
      createdAt: "Gesprächs zeit",
      agentId: "Kunden service",
      getDirection: "Art des Anrufs",
      callResult: "Ergebnisse des Anrufs",
      survey: "Bewertung",
      derivedFrom: "Quelle",
      quitQueueWhy: "Warteschlangen status",
      queuingDuration: "Das Anstehen ist zeit aufwändig",
      talkingSeconds: "Dauer des Anrufs",
      ringingSeconds: "Glocken zeit",
      hangupBy: "Anruf auflegen",
    },
    conditionFieldCategories: {
      fixedField: "Feste Felder",
      customField: "Benutzer definierte Felder",
      secondLevelScore: "Klassifizierung der zweiten Ebene",
      formItemScore: "Bewertungs element",
      forbids: "Verbotene Punkte",
      deadlies: "Tödliches Element",
    },
    columnFieldCategories: {
      agent: "Kunden service",
      callLog: "Anruf protokoll",
      businessFields: "Geschäfts informationen",
      customer: "Kunden",
      customerCustomFields: "Kunden anpassung",
      source: "Quelle",
      queue: "Warteschlange",
      fixed: "Fixiert",
      secondLevelScore: "Klassifizierung der zweiten Ebene",
      formItemScore: "Bewertungs element",
      forbids: "Verbotene Punkte",
      deadlies: "Tödliches Element",
    },
    taskListDefaultFields: {
      startTime: "Gesprächs zeit",
      name: "Kunden service",
      state: "Zustand",
      totalScore: "Gesamt punktzahl",
      formItemForbidList: "Verbotene Punkte",
      formItemDeadlyList: "Tödliches Element",
      comment: "Anmerkungen zur Bewertung",
      inspector: "Bewertungs person",
      review: "Prüfer",
    },
    evaluationStates: {
      initial: "Initial isierung",
      waitEvaluate: "Zu bewerten",
      evaluated: "Absenden",
      waitConfirm: "Zu bestätigen",
      waitCheck: "Ausstehende Überprüfung",
      complete: "Abgeschlossen",
    },
    workBenchInfoTypes: {
      callInfo: "Anruf informationen",
      businessInfo: "Geschäfts informationen",
      customerInfo: "Kunden informationen",
    },
    evaluationEventStates: {
      create: "Erstellen",
      valuate: "Bewertung",
      edit: "Bearbeiten",
      submit: "Einreichung",
      confirm: "Bestätigung",
      appeal: "Beschwerde",
      checkConfirm: "Überprüfung bestätigt",
      check: "Überprüfung",
    },
    formItemTypes: {
      direct: "Direkte Bewertung",
      weight: "Gewichte",
      deduct: "Punkt abzug",
      forbid: "Verbotene Punkte",
      deadly: "Tödliches Element",
    },
    appealCheckCallTypes: {
      callIn: "Rufen Sie an",
      callBack: "Zwei-Wege-Rückruf",
      callOut: "Ausge rief Direktwahl",
      autoCall: "Automatische externe Anrufe",
    },
    appealCheckCallStatus: {
      waitReview: "Ausstehende Überprüfung",
      reviewed: "Geüberprüfte",
      filed: "Abgelegt",
    },
    samplingStatus: {
      all: "Alle",
      unchecked: "Nicht geprüfene Extraktion",
      checked: "Probenahme",
    },
    inspectionStatus: {
      all: "Alle",
      unread: "Ungelesen",
      readed: "Lesen",
      appeal: "Beschwerde",
      review: "Überprüfung",
      filed: "Abgelegt",
    },
    gradeChooseHits: { yes: "Ist", no: "Nein" },
    appealCheckStatus: {
      all: "Alle Zustände",
      unread: "Ungelesen",
      inComplaint: "Berufung",
      reviewed: "Geüberprüfte",
      readed: "Lesen",
      filed: "Abgelegt",
      spotchecked: "Probenahme",
    },
    flowStatus: {
      unread: "Ungelesen",
      alreadyRead: "Lesen",
      complaining: "Berufung",
      reviewed: "Geüberprüfte",
      sampling: "Nicht geprüfene Extraktion",
      samplingCompleted: "Probenahme",
      complainCancel: "Widerruf",
    },
    complainStatus: {
      success: "Erfolg",
      fail: "Scheitern",
      default: "Berufung",
      canceled: "Widerruf",
    },
    reviewInspectionStatus: {
      inComplaint: "Berufung",
      reviewed: "Geüberprüfte",
    },
    gradeChangeTypes: {
      edit: "Bearbeiten",
      check: "Überprüfung",
      file: "Archivierung",
      remark: "Bemerkungen",
      complain: "Beschwerde",
      review: "Überprüfung",
      reviewEvaluation: "Überprüfung und Bewertung",
      sample: "Probenahme",
      sampleEvaluation: "Bewertung der Probenahme",
      sampleSubmit: "Probenahme einreichen",
      sampleAssign: "Probenahme zuweisung",
      reviewAssign: "Überprüfung der Zuordnung",
      read: "Lesen",
      reviewConfirm: "Überprüfung bestätigt",
      sampleConfirm: "Bestätigung der Probenahme",
      caseBase: "Zu Fall gruppen hinzufügen",
      sampleCancel: "Probenahme storniert Zuordnung",
      reviewCancel: "Überprüfung und Zuweisung abbrechen",
      sampleAgain: "Stichproben umverteilung",
      reviewAgain: "Überprüfung der Umverteilung",
      btachDelete: "Löschen Sie stichproben artige Inspektions aufzeichnungen",
      rapidSampling: "Schnelle Probenahme",
      reInspection: "Nachprüfung",
      smartTagsChange: "Smart Label-Änderungen",
      cancelAppeal: "Widerruf der Beschwerde",
    },
    appealTypes: {
      transliterationError: "Trans literal fehler",
      discriminationError: "Diskriminierung fehler",
      otherError: "Andere Fehler",
    },
    inspectDataSources: {
      voiceCall: "Sprach anrufe",
      textDialogue: "Text gespräche",
      realTimeVoiceCall: "Echtzeit-Sprach anrufe",
      realTimeTextDialogue: "Text gespräche in Echtzeit",
      wechatDialogue: "Enterprise Micro Session",
      taobao: "E-Commerce-Text dialog",
      ticket: "Arbeits auftrag",
      wechatRadio: "Enterprise Micro Voice",
    },
    inspectTaskType: {
      all: "Alle",
      common: "Konventionelle Qualitäts prüfung",
      relate: "Verwandte Qualitäts prüfung",
    },
    inspectApproveType: {
      all: "Alle",
      resolve: "Audit bestanden",
      resolving: "Audit",
      reject: "Audit nicht bestanden",
    },
    dataSourceFlags: {
      all: "Keine Einschränkungen",
      voiceCall: "Der Anruf",
      textDialogue: "Dialog",
    },
    smartWordsLibrary: { effective: "Wirksam", invalid: "Ungültig" },
    activeFlags: { effiective: "Wirksam", invalid: "Ungültig" },
    labelTypes: {
      dimensionLabel: "Kennzeichnung",
      classificationLabel: "Klassifizierung kennzeichnung",
    },
    pointTypes: {
      automatic: "Automatische Auswertung",
      manual: "Manuelle Bewertung",
      interactiveDetection: "Interaktion erkennung",
      smartRules: "Regeln der Intelligenz",
      machineLearning: "Selbst lernende Muster",
      intelligentModel: "Intelligentes Modell",
    },
    pointGradeTypes: {
      radio: "Single-Choice",
      input: "Eingabe",
      select: "Wählen Sie",
      all: "Treffen Sie alle",
      any: "Befriedigt jeden",
      customize: "Anpassen",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Keyword-Übereinstimmung",
      question: "Frage satz",
      regular: "Regulärer Ausdruck",
      context: "Kontext wiederholen",
      semantics: "Semantische Übereinstimmung",
      word: "Wort anzahl ähnlicher Satz",
      dialogue: "Zei tinte rvall des Dialogs",
      speed: "Sprach geschwindigkeit erkennung",
      grabDetection: "Schnapp erkennung",
      semanticTags: "Semantische Tags",
    },
    applyRoles: {
      all: "Alle",
      customer: "Kunden",
      agent: "Kunden service",
      robot: "Roboter",
    },
    saleApplyRoles: { all: "Alle", customer: "Kunden", agent: "Mitarbeiter" },
    applyOperatorScopes: {
      all: "Volltext",
      preCondition: "Voraussetzungen",
      scope: "Bereich angeben",
    },
    keywordScopes: {
      all: "Alle Schlüssel wörter erkennen",
      any: "Erkennung von Schlüssel wörtern",
    },
    preOperatorHitTypes: {
      first: "Erste Übereinstimmung",
      every: "Jedes Mal, wenn Sie übereinstimmen",
      last: "Letzte Übereinstimmung",
      any: "Beliebige Übereinstimmung",
      none: "Nicht übereinstimmend",
    },
    operatorRuleTypes: {
      all: "Treffen Sie alle",
      any: "Befriedigt jeden",
      custom: "Benutzer definierte Logik",
    },
    informationEntityTypes: {
      system: "System",
      custom: "Anpassen",
      moduleCompany: "Modul",
    },
    organizationTypes: { null: "Keine", moduleCompany: "Modul" },
    customType: {
      smartTag: "Entität extrahieren",
      scriptTimes: "Wörter zählen",
      dataCategory: "Klassifizierung von Daten",
    },
    interactiveQuestionTypes: {
      standard: "Standard-Fragen und Antworten",
      multiElement: "Fragen und Antworten zu mehreren Elementen",
      guideMultiRound: "Geführte Mehrrad interaktion",
    },
    targetTypes: {
      all: "Alle",
      customerService: "Kunden service",
      customer: "Kunden",
    },
    interactiveTypes: {
      similarQList: "Ähnliche Probleme",
      exceptSimilarQList: "Ähnliche Probleme ausschließen",
      similarAList: "Ähnliche Antworten",
      wrongAList: "Falsche Antwort",
    },
    filterTypes: {
      call: "Anruf aufzeichnung",
      dialogue: "Dialog text",
      wechat: "Enterprise WeChat",
      wechatAll: "Alle Gespräche über Enterprise WeChat",
      ticket: "Arbeits auftrag Konversation",
      taobao: "E-Commerce-Sitzung",
      wechatRadio: "Enterprise Micro Voice",
    },
    fieldTypes: {
      keyword: "Schlüssel wörter",
      agent: "Kunden service",
      agentGroup: "Kundendienst gruppe",
      sigleListbox: "Single-Choice",
      multiListbox: "Mehrfach auswahl",
      text: "Einz eitler Text",
      textArea: "Mehrzeilen-Text",
      dateTime: "Zeit",
      number: "Zahlen",
      voice: "",
      customer: "Kunden",
      fieldWithLink: "Mit verknüpften Feldern",
      percentage: "Prozent",
      intelligentClassification: "Intelligente Klassifizierung",
      testSetList: "Dynamische Felder für den Tests atz",
      inspector: "Qualitäts inspektor",
    },
    saveTypes: { save: "Speichern" },
    knowledgeType: { oneToOne: "Eine Frage und eine Antwort" },
    updateTypes: { update: "Aktualisieren", saveAs: "Speichern unter" },
    recommendItemActionTypes: {
      similar: "Ähnlich",
      exclude: "Ausschluss",
      ignore: "Ignorieren",
    },
    gradeStatus: {
      unread: "Ungelesen",
      alreadyReview: "Geüberprüfte",
      alreadyFiled: "Abgelegt",
      waitReview: "Ausstehende Überprüfung",
      alreadyRead: "Lesen",
    },
    qualityCheckDetailTypes: { review: "Überprüfung", spotCheck: "Probenahme" },
    sampleAndReviewStatus: {
      init: "Nicht extrahiert",
      unread: "Ungelesen",
      alreadyRead: "Lesen",
      complaining: "Berufung",
      reviewed: "Geüberprüfte",
      sampling: "Nicht geprüfene Extraktion",
      samplingCompleted: "Probenahme",
    },
    interactiveAnswerTypes: {
      noAnswer: "Antworten nicht erkannt",
      answer: "Antworten erkannt",
      wrongAnswer: "Falsche Antworten erkannt",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Problem nicht erkannt",
      question: "Probleme erkannt",
      eliminateQuestion: "Fehler beim Ausschluss erkannt",
    },
    isManual: {
      yes: "Manuelle Qualitäts prüfung",
      no: "Keine manuelle Qualitäts prüfung",
    },
    readStatus: { yes: "Lesen", no: "Ungelesen" },
    reInspectionType: {
      recalcScore: "Nur Scores (einschl ießlich Bewertungen) neu berechnen",
      recheckPoint: "Neu berechnung von Qualitäts prüf punkten und-werten",
    },
    common: {
      eCommerceReview: "E-Commerce-Bewertungen",
      socialMediaComments: "Social Media Review",
      customer: "Kunden",
      customerService: "Kunden service",
      robot: "Roboter",
      customerInformation: "Kunden informationen",
      workOrderFields: "Arbeits auftrags feld",
      intelligentConversationAnalysis: "Intelligente Konversation analyze",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Einblicke in Geschäfts mängel, Hören auf Kunden stimmen und Verbesserung der Marketing transformation",
      intelligentAccompanyingPractice: "Intelligentes Sparring",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Erstellen Sie ein immer sives KI-Sparring, das reale Geschäfts szenarien simuliert, die Beherrschung von Goldmedaille gesprächen durch alle Mitarbeiter realisiert und die Dialog fähigkeiten schnell verbessert",
      intelligentQualityInspection: "Intelligente Qualitäts prüfung",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Volle Qualitäts prüfung, zeitnah und effizient, Genauigkeit srate über 85%",
      salesEmpowerment: "Verkaufs ermächtigung",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI formt das Vertriebs management neu, realisiert die Digitalis ierung und Visual isierung des Kommunikation prozesses, verwaltet jeden Vertriebs link transparent, verfügt über intelligente Einblicke in jeden Kommunikation schritt, diagnosti ziert Geschäfts probleme verfeinert und wiederholt die Verkaufs erfahrung perfekt",
      algorithmPlatform: "Plattform für Algorithmen",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Basierend auf der phonetischen Semantik der selbst entwickelten Original-Engine werden ASR-und NLP-Modelle angepasst, um Geschäfts szenarien daten zu öffnen und das Selbst lernen des Systems zu realisieren, um das Ziel zu erreichen, intelligenter und genauer zu verwenden",
      generalSettings: "Universelle Einstellungen",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Einheitliche Einstellungen und Verwaltung von Daten berechtigungen und Mitarbeiter rollen",
      callLog: "Anruf protokoll",
      intelligentRules: "Regeln der Intelligenz",
      businessRecords: "Geschäfts aufzeichnung",
      customFilter: {
        call: "Sprach anrufe",
        text: "Text gespräche",
        smartTags: "Intelligente Tags",
        pretreatment: "Vor verarbeitung",
        smartTagGroup: "Intelligente Tag-Gruppe",
        currency: "Allgemeine Daten",
        wechat: "Enterprise WeChat",
        spotCheck: "Probenahme",
        personaField: "Kundenliste",
        ticket: "Arbeits auftrag Konversation",
        taobao: "E-Commerce-Sitzung",
        wechatRadio: "Enterprise Micro Voice",
        reviewList: "Überprüfung",
      },
      talkerRecognitionType: {
        API: "API zur Identifizierung von Schienen rollen",
        text: "Texter kennung",
      },
      operatorTypes: {
        keyword: "Keyword-Übereinstimmung",
        question: "Frage satz",
        regular: "Regulärer Ausdruck",
        context: "Kontext wiederholen",
        semantics: "Semantische Übereinstimmung",
        word: "Wort anzahl ähnlicher Satz",
        dialogue: "Zei tinte rvall des Dialogs",
        speed: "Sprach geschwindigkeit erkennung",
        grabDetection: "Schnapp erkennung",
        semanticTags: "Semantische Tags",
        keywordStrict: "Keyword-Matching (streng)",
        link: "Verknüpfte Felder",
        entityInfoDetection: "Erkennung von Informations entitäten",
        basicInfoDetection: "Grundlegende Informations erkennung",
        volume: "Lautstärke erkennung",
        emotion: "Analyse von Emotionen",
        grammar: "Grammatik-Regeln",
        sentenceLength: "Satz langen erkennung",
        conceptWord: "Begriff Wort",
        questionsAndAnswers: "Wissens datenbank lösung (alt)",
        element: "Fragen und Antworten zu Elementen",
        silent: "Antwort-Timeout",
        associate: "Daten auf dem Weg",
        repeat: "Wiederholen senden",
        timeInterval: "Länge des Intervalls",
        typoDetection: "Erkennung von Tippfehlern",
        silentOld: "Stille",
        knowledge: "Wissens antwort",
        wecomRemark: "Kunden unternehmen Micro-Anmerkungen",
        customerAddTime: "Kunden fügen Zeit hinzu",
      },
      keywordOperators: {
        all: "Alle Übereinstimmungen",
        allNot: "Alle stimmen nicht überein",
        any: "Beliebige Übereinstimmung",
      },
      applyRoles: { all: "Alle", customer: "Kunden", agent: "Kunden service" },
      sentenceLength: {
        less: "Weniger als",
        greater: "Größer als",
        equal: "Gleich",
        greater_or_equal: "Größer als gleich",
        less_or_equal: "Weniger als gleich",
      },
      dialogueApplyScopes: {
        differentRoles: "Zwischen verschiedenen Charakteren",
        agent: "Kunden service",
        customer: "Kunden",
        agentResponse: "Antwort des Kundendienstes",
        customerResponse: "Antwort des Kunden",
        customerNoResponse: "Kunden reagieren nicht",
      },
      applyConditionHitTypes: {
        first: "Der erste Treffer",
        every: "Jeder Treffer",
        last: "Der letzte Treffer",
      },
      applyConditionScopes: {
        current: "Aktuell",
        before: "Vor",
        after: "Nach",
        beforeAll: "Alle vorher",
        afterAll: "Danach alle",
        around: "In der Nähe",
      },
      voiceDemoTypes: {
        singleChannel: "Einkanal-Anruf aufzeichnung",
        dualChannel: "Zwei kanal ige Anruf aufzeichnung",
      },
      sendStatus: {
        sending: "Senden",
        arrive: "Zugestellt",
        fail: "Senden fehl geschlagen",
        off_sending: "Offline-Versand ungelesen",
        off_arrive: "Lesen",
        rollback: "Zurück gezogen",
      },
      collectionMethod: {
        assignment: "Direkte Zuordnung",
        entity: "Entität extraktion",
      },
      systemTypes: {
        qa: "Intelligente Qualitäts prüfung",
        wfm: "Intelligente Schicht",
      },
      entityOperators: {
        equals: "Gleich Informationen",
        notEquals: "Nicht gleich Informationen",
        contains: "Informationen enthalten",
        notContains: "Enthält keine Informationen",
        equalsFix: "Gleich einem festen Wert",
        notEqualsFix: "Nicht gleich einem festen Wert",
        containsFix: "Feste Werte enthalten",
        notContainsFix: "Enthält keine festen Werte",
      },
      basicInfoOperators: {
        equals: "Gleich",
        notEquals: "Nicht gleich",
        contains: "Enthält",
        notContains: "Nicht enthalten",
        isNull: "Ist leer",
        isNotNull: "Nicht leer",
      },
      volumeDetectionWays: {
        loudness: "Lautstärke Dezibel",
        rangeAbilitySelf:
          "Änderungs bereich (im Vergleich zum vorherigen Satz)",
        rangeAbilityOth: "Änderungs bereich (der obere Satz des Vergleichs)",
        rangeAbility3: "Änderungs bereich (im Vergleich zum eigenen Satz)",
      },
      volumeDetectionOperators: {
        is: "Gleich",
        not: "Nicht gleich",
        greaterThan: "Größer als",
        greaterThanEq: "Größer als gleich",
        lessThan: "Weniger als",
        lessThanEq: "Weniger als gleich",
      },
      sentimentTypes: {
        positive: "Positive Emotionen",
        negative: "Negative Emotionen",
        neutral: "Neutrale Stimmung",
        thankful: "Danke",
        happy: "Glücklich",
        complaining: "Beschwerde",
        angry: "Wütend",
        post: "Vorderseite",
        negat: "Negativ",
        neut: "Neutral",
      },
      emotionOperators: { is: "Konform", not: "Nicht erfüllt" },
      propTypes: { system: "System", user: "Anpassen" },
      valueTypes: { text: "Text", number: "Zahlen" },
    },
    tasks: {
      allowCustomGrievance: "Zulassen, den Grund für die Beschwerde anzupassen",
      businessSummary: "Geschäfts zusammenfassung",
      theDayBeforeYesterday: "Vor gestern",
      assessActionTypes: { reassess: "Neubewertung" },
      scoreType: {
        aiScore: "Intelligente Bewertung",
        manualScore: "Manuelle Bewertung",
        interactiveDetection: "Interaktion erkennung",
        smartRules: "Regeln der Intelligenz",
        machineLearning: "Selbst lernende Muster",
      },
      inspectType: {
        inspectTrue: "Überprüfen Sie nach",
        inspectFalse: "Nicht überprüft",
      },
      operatorType: {
        keyword: "Keyword-Übereinstimmung",
        notKeyword: "Schlüssel wörter stimmen nicht überein",
      },
      applyRole: { all: "Alle", customer: "Kunden", agent: "Kunden service" },
      applyScope: {
        all: "Alle",
        specific: "Begewiesener Satz",
        scope: "Bereich angeben",
      },
      judgeStrategy: {
        all: "Erfüllen Sie alle Bedingungen",
        arbitrarily: "Erfüllen Sie beliebige Bedingungen",
        custom: "Benutzer definierte Logik",
      },
      checkPointStatus: {
        waitFor: "Warten auf Überprüfung",
        notDeductScore: "Keine Punkte abgezogen",
        deductScore: "Punkt abzug",
      },
      applyOptions: {
        hit: "Bedingungen treffen",
        notHit: "Keine angegebenen Bedingungen",
      },
      gradeTypes: {
        yesOrNo: "Ob es sich erfüllt",
        input: "Manuelle Eingabe",
        level: "Datei bewertung",
        multipleFactors: "Mehrere Elemente",
      },
      gradeChooseHits: { yes: "Ist", no: "Nein" },
      gradeChangeTypes: {
        edit: "Bearbeiten",
        check: "Überprüfung",
        file: "Archivierung",
        remark: "Bemerkungen",
        complain: "Beschwerde",
        review: "Überprüfung",
        reviewEvaluation: "Überprüfung und Bewertung",
        sample: "Probenahme",
        sampleEvaluation: "Bewertung der Probenahme",
        sampleSubmit: "Probenahme einreichen",
        sampleAssign: "Probenahme zuweisung",
        reviewAssign: "Überprüfung der Zuordnung",
        read: "Lesen",
        reviewConfirm: "Überprüfung bestätigt",
        sampleConfirm: "Bestätigung der Probenahme",
      },
      gradeStatus: {
        unread: "Ungelesen",
        alreadyReview: "Geüberprüfte",
        alreadyFild: "Abgelegt",
        waitReview: "Ausstehende Überprüfung",
        alreadyRead: "Lesen",
      },
      samplingFrequencyTypes: {
        everyDay: "Täglich",
        everyWeek: "Wöchentlich",
        everyMonth: "Jeden Monat",
      },
      samplingEveryDayTimeTypes: { yesterday: "Gestern", today: "Heute" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Diese Woche",
        lastWeek: "Letzte Woche",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Diesen Monat",
        lastMonth: "Letzten Monat",
      },
      samplingTypes: {
        random: "Zufalls stichproben",
        proportional: "Proportionale Stichprobe",
      },
      weekdays: {
        Monday: "Montag",
        Tuesday: "Dienstag",
        Wednesday: "Mittwoch",
        Thursday: "Donnerstag",
        Friday: "Freitag",
        Saturday: "Samstag",
        Sunday: "Sonntag",
      },
      distributeTypes: {
        rule: "Regel priorität zuweisung",
        average: "Durchschnitt liche Zuordnung nach dem Zufalls prinzip",
      },
      notMatchAnyRuleTypes: {
        random: "Zufällige gleichmäßige Verteilung",
        assign: "Zuordnung zu einer bestimmten Person",
        ignore: "Nicht zugeteilt",
      },
      inspectorTypes: {
        all: "Alle Qualitäts inspektoren",
        some: "Designierter Qualitäts inspektor",
      },
      pushFrequencyTypes: {
        everyDay: "Täglich",
        everyWeek: "Wöchentlich",
        everyMonth: "Jeden Monat",
        inspectionUpdate: "Aktualisierung der Qualitäts prüfung",
        timelyInspection: "Qualitäts prüfung in Echtzeit",
      },
      pushObjectTypes: { email: "E-Mail angeben", staff: "Kundendienst" },
      reviewOptions: {
        reviewResult:
          "Die Ergebnisse der Qualitäts prüfung werden während der Überprüfung angezeigt",
        reviewRecord: "Verwandte Datensätze während der Überprüfung anzeigen",
        reviewAgentHidden:
          "Kundendienst informationen während der Überprüfung ausblenden",
        samplingResult:
          "Die Ergebnisse der Qualitäts prüfung werden während der Probenahme angezeigt",
        samplingRecord: "Zeigt relevante Datensätze während der Probenahme an",
        sampleAgentHidden:
          "Kundendienst informationen während der Probenahme ausblenden",
        checkExportAddCallInfoIphone:
          "Anhängen von Anruf protokollieren beim Exportieren der Liste",
        sampleExportAddCallInfoIphone:
          "Anhängen von Anruf protokollieren beim Exportieren der Liste",
        checkExportAddCallInfoText:
          "Zusätzliche Konversation datensätze beim Exportieren von Listen",
        sampleExportAddCallInfoText:
          "Zusätzliche Konversation datensätze beim Exportieren von Listen",
        inspectionExportAddCallInfoIphone:
          "Fügen Sie beim Exportieren der Liste einen Anruf verlauf hinzu (Sie können jedes Mal bis zu 10.000 Stück exportieren).",
        inspectionExportAddCallInfoText:
          "Fügen Sie beim Exportieren der Liste Konversation datensätze hinzu (Sie können jedes Mal bis zu 10000 Artikel exportieren)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Ermöglicht die schnelle Durchführung von Stichproben inspektionen in den Details der Qualitäts prüfung",
        fastSampleInSampleDetail:
          "Ermöglichen Sie die schnelle Durchführung von Stichproben inspektionen in den Details der Stichproben prüfung",
        fastSampleInReviewDetail:
          "Ermöglichen Sie die schnelle Durchführung von Stichproben inspektionen in den Überprüfung details",
      },
      permitCustomReasonList: {
        permitCustomReason: "Zulassen von benutzer definierten Antrags gründen",
      },
      autoMateTasks: {
        autoLoad: "Automatisches Hinzufügen",
        sample: "Automatische Probenahme",
        assign: "Automatische Zuordnung",
        pushes: "Automatischer Push",
        assess: "Automatische Auswertung",
        check: "Beschwerde erneut",
        case: "Synchron isierung der Fall bibliothek",
      },
      qualityCheckDetailTypes: {
        review: "Überprüfung",
        spotCheck: "Probenahme",
      },
      appealTypes: {
        transliterationError: "Trans literal fehler",
        discriminationError: "Diskriminierung fehler",
        otherError: "Andere Fehler",
      },
      inspectDataSources: {
        voiceCall: "Sprach anrufe",
        textDialogue: "Text gespräche",
        realTimeVoiceCall: "Echtzeit-Sprach anrufe",
        realTimeTextDialogue: "Text gespräche in Echtzeit",
      },
      pointTypes: {
        automatic: "Automatische Auswertung",
        manual: "Manuelle Bewertung",
        interactiveDetection: "Interaktion erkennung",
      },
      pointGradeTypes: {
        radio: "Single-Choice",
        input: "Eingabe",
        select: "Wählen Sie",
      },
      sampleAndReviewStatus: {
        init: "Nicht extrahiert",
        unread: "Ungelesen",
        alreadyRead: "Lesen",
        complaining: "Berufung",
        reviewed: "Geüberprüfte",
        sampling: "Nicht geprüfene Extraktion",
        samplingCompleted: "Probenahme",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Überlauf ignorieren",
        automaticWeightCalculation: "Automatische Gewichts abrechnung",
      },
      calculateScoreTypes: { add: "Extrapunkte", sub: "Punkt absenkung" },
      naChecked: {
        check: "Dies ist nicht an der zulässigen Erkennung beteiligt",
      },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Proportionale Punkte",
        allRight: "Alle richtigen Punkte",
        anyRight: "Beliebige und korrekte Punkte",
      },
      noHitQuestionResults: {
        true: 'Das Ergebnis ist "Ja"',
        false: 'Ergebnis "Nein"',
      },
      repetRules: {
        same: "Der Inhalt ist genau der gleiche",
        similar: "Semantische Ähnlichkeit",
      },
      relateConditions: {
        highlightQuestion: "Hervorhebung problem",
        highlightAnswer: "Hervorhebung der Antwort",
        hightlightFoctors: "Hervorhebung elemente",
      },
      interactiveQuestionTypes: {
        question: "Probleme erkannt",
        eliminateQuestion: "Fehler beim Ausschluss erkannt",
      },
      interactiveAnswerTypes: {
        noAnswer: "Antworten nicht erkannt",
        answer: "Antworten erkannt",
        wrongAnswer: "Falsche Antworten erkannt",
      },
      inspectionUpdateTypes: {
        autoInspection: "Automatische Qualitäts prüfung",
        autoInspectionWechat: "Automatische Analyse",
        artificialSampling: "Künstliche Probenahme",
        artificialReview: "Manuelle Überprüfung",
        manualModification: "Schnelle Probenahme",
        timelyInspection: "Qualitäts prüfung in Echtzeit",
        samplingAssign: "Probenahme zuweisung",
        artificialComplain: "Beschwerde eingereicht",
        fastSampling: "Schnelle Einreichung von Stichproben",
        recheckInspection: "Nachprüfung",
        recheckInspectionWechat: "Neu analysieren",
      },
      timelyInspectionTypes: {
        timelyInspection: "Qualitäts prüfung in Echtzeit",
      },
      pushActionTypes: { email: "Mail", interfacePush: "Schnitts telle Push" },
      pushDetailWays: {
        riskAlert: "Risiko-Erinnerung",
        subscribe: "Nachrichten abonnieren",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Anruf/Gesprächs nachricht",
        inspectionResults: "Ergebnisse der Qualitäts prüfung",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Anruf/Gesprächs nachricht",
        textContent: "Text inhalt des Anrufs/Gesprächs",
        inspectionResults: "Ergebnisse der Qualitäts prüfung",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Durchschnitt liche Punktzahl innerhalb der Gruppe",
        addOrSubtract: "Direkte Addition und Subtraktion",
        noChange: "Keine Addition oder Subtraktion",
      },
      defaultScore: {
        empty: "Ist leer",
        add: "Extrapunkte",
        notAdd: "Keine Extrapunkte",
        sub: "Punkt absenkung",
        notSub: "Keine Punkte absenkung",
        na: "N/A",
        input: "Manuelle Eingabe",
        choose: "Wählen Sie",
      },
      strategyTypes: {
        hitAdd: "Treffer plus Punkte",
        hitNotAdd: "Treffer ohne Punkte",
        hitSub: "Treffer und Minuspunkte",
        hitNotSub: "Keine Punkte",
      },
    },
    download: {
      exportStatus: {
        prepare: "In Vorbereitung",
        process: "In Arbeit",
        faild: "Export fehl geschlagen",
        success: "Erfolg",
      },
    },
    messageType: {
      all: "Alle",
      monitor: "Sofortige Überwachung",
      trigger: "Benachricht igungen auslösen",
      timing: "Zeit gesteuerte Berichte",
    },
    messagePushType: {
      all: "Alle",
      system: "System meldungen",
      sdk: "Nachrichten SDK",
      push: "Schnitts telle Push",
      email: "Mail",
      udesk: "Udesk Benachricht igungen",
      udeskWhite: "Benachricht igung über das Kundendienst system",
      weChat: "Unternehmens-Mikro nachrichten",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Zuweisen von Probenahme personal",
      sampleInspector: "Probenahme",
      assignReviewer: "Zugewiesene Prüfer",
      reviewer: "Prüfer",
      taskInspector: "Qualitäts inspektor für Aufgaben",
    },
    sdkPushType: {
      webHook: "Standard webhook",
      dingDing: "Nagel Roboter",
      weChat: "Enterprise WeChat Roboter",
      customer: "Benutzer definierter Json-Inhalt",
    },
    inspectionRemarksType: {
      inspection: "Qualitäts prüfung",
      check: "Probenahme",
      review: "Überprüfung",
    },
    segmentationStrategyType: {
      count: "Anzahl der Nachrichten",
      spacing: "Nachrichten intervall",
    },
    segmentationStrategy: {
      byTime: "Timing-Schnitt",
      customize: "Kombiniertes Schneiden",
    },
    inspectType: {
      commonInspect: "Konventionelle Qualitäts prüfung",
      associateInspect: "Verwandte Qualitäts prüfung",
    },
    typoDetectionOperators: { less: "Weniger als", greater: "Größer als" },
    autoAddTypes: {
      realTimeAdd: "Hinzufügen in Echtzeit",
      timingAdd: "Timing hinzufügen",
    },
    messageTypes: {
      text: "Text nachrichten",
      markdown: "Markdown Nachrichten",
    },
    inServiceStatus: {
      activated: "Aktiviert",
      disabled: "Deaktiviert",
      inactivated: "Nicht aktiviert",
      resigned: "Unternehmen verlassen",
    },
    roles: { agent: "Mitarbeiter", customer: "Kunden" },
    commentVisibleAuthority: {
      all: "Sichtbar für alle",
      self: "Nur für sich sichtbar",
      designation: "Die angegebene Person ist sichtbar",
    },
    appealCheckingTask: {
      eCommerceReview: "E-Commerce-Bewertungen",
      socialMediaComments: "Social Media Review",
      largeModel: "Großes Modell",
      customModel: "Benutzer definiertes Modell",
      universalModel: "Universelles Modell",
      enterpriseWeChatText: "Enterprise-Mikro text",
      generalQualityInspection: "Allgemeine Qualitäts prüfung",
      processQualityInspection: "Prozess Qualitäts prüfung",
      voiceCopy: "Sprach wiederfassung",
      beEqualTo: "Gleich",
      notEqualTo: "Nicht gleich",
      canAppeal: "Kann Berufung einlegen",
      noAppeal: "Nicht anspruch bar",
    },
    wechat: {
      redEnvelopes: "Rote Umschläge",
      text: "Text",
      picture: "Bild",
      voice: "Stimme",
      voiceCall: "Sprach anrufe",
      video: "Video",
      businessCard: "Visitenkarten",
      position: "Lage",
      expression: "Ausdruck",
      link: "Link",
      applet: "Kleines Programm",
      chatRecord: "Chat-Verlauf",
      mixedMessage: "Gemischte Nachrichten",
      file: "Datei",
    },
    gong: {
      sessionActivity: "Sitzungs aktivitäten",
      conversationAction: "Sitzungs aktion",
      riskSemantics: "Semantik des Risikos",
      daysNotFollowedUp: "Anzahl der Tage ohne Nach verfolgung",
      waysideData: "Daten auf dem Weg",
      notStarted: "Nicht gestartet",
      completed: "Abgeschlossen",
      haveInHand: "In Arbeit",
      unpublished: "Nicht ver öffentlicht",
      published: "Gepostet",
      knowledgePoints: "Wissens punkte",
      technologicalProcess: "Prozess",
      notMarked: "Nicht markiert",
      inAnnotation: "Markieren",
      annotated: "Beschriftet",
    },
    qualityChecingTask: {
      appealCanceled: "Beschwerde abgebrochen",
      complaintExpired: "Ablauf der Beschwerde",
      incomingCall: "Rufen Sie an",
      exhale: "Atme aus",
      incomingCallthirdParty: "Einrufen (drei Parteien)",
      incomingCallconsultation: "Anruf (Beratung)",
      incomingCallforcedInsertion: "Einrufen (starker Einfügen)",
      incomingCallmonitoring: "Anrufen (Überwachung)",
      incomingCalltransfer: "Anrufen (Übertragung)",
      incomingCallinterception: "Anrufen (Abfangen)",
      incomingCalltransferToExternalLine:
        "Anrufen (externe Leitung übertragen)",
      incomingCallthirdPartyExternalLine:
        "Anrufen (drei glied rige Außenlinie)",
      incomingCallexternalLine: "Anrufen (Beratungs leitung)",
      outgoingCallthirdParty: "Ausrufen (drei Parteien)",
      outgoingCalltransfer: "Ausrufen (Übertragung)",
      outgoingCallforcedInsertion: "Ausrufen (starker Einsetzen)",
      outgoingCallmonitoring: "Ausrufen (Überwachung)",
      outgoingCallinterception: "Ausatmen (abfangen)",
      outgoingCalltransferToExternalLine:
        "Ausrufen (auf die externe Leitung übertragen)",
      outgoingCallthreePartyExternalLine: "Ausrufen (drei Seiten außen)",
      customerServiceAnswer: "Kundendienst beantwortet",
      customerServiceMissed: "Kundendienst nicht beantwortet",
      customerAnswer: "Kunden annahme",
      customerMissed: "Kunde nicht empfangen",
      theTelephoneIsBusy: "Telefon besetzt",
      phoneOffline: "Telefon offline",
      customerQuickHanging: "Kunden schnell hängen",
      clientHangsUp: "Kunde legt auf",
      queueTimeout: "Auszeit in der Schlange",
      giveUpQueuing: "Aufgeben von Warteschlangen",
      outsideLineAnswering: "Leitung empfangen",
      externalLineIsNotConnected: "Die Außenlinie ist nicht anges ch lossen",
      noQueueSelected: "Warteschlange nicht ausgewählt",
      notEvaluated: "Nicht bewertet",
      noEvaluationRequired: "Keine Bewertung erforderlich",
      queuingSucceeded: "Erfolgreich in der Schlange",
      noCustomerServiceOnline: "Kein Kunden service online",
      customer: "Kunden",
      customerService: "Kunden service",
      outsideLines: "Außerhalb",
    },
    sessionActivity: {
      single: "Durchschnitt liche Gesprächs zeit",
      all: "Gesamt anrufe",
      allTime: "Gesamtdauer des Anrufs",
    },
    sessionAction: {
      salesProportion: "Umsatz anteil",
      salesLongest: "Durchschnitt lich längste Verkaufs rede",
      customerLongest: "Durchschnitt lich längste Kunden rede",
      discussNumber: "Die Anzahl der eingehenden Diskussionen",
      frequency: "Häufigkeit der Fragen",
    },
    monitorCriteria: {
      all: "Vorher und nachher",
      before: "Vor",
      after: "Nach",
    },
    monitorTimeCycle: { day: "Tag", week: "Woche", month: "Monat" },
    monitorGroupTestType: {
      ab: "Vergleich der beiden Gruppen",
      a: "Gruppe A",
      b: "Gruppe B",
    },
    fields: {
      department: "Abteilung",
      customerTags: "Kunden-Tags",
      member: "Mitglieder",
      time: "Zeit",
      cascade: "Kaskade",
      anyMatch: "Beliebige Übereinstimmung",
      keyWord: "Schlüssel wörter",
      keyEvents: "Schlüssel ereignisse",
    },
    semanticIntelligence: {
      staff: "Mitarbeiter",
      customer: "Kunden",
      employeesAndCustomers: "Mitarbeiter und Kunden",
      notStarted: "Nicht gestartet",
      haveInHand: "In Arbeit",
      completed: "Abgeschlossen",
      callRecording: "Anruf aufzeichnung",
      enterpriseWeChatCall: "Unternehmens ansprache",
      enterpriseWeChatText: "Enterprise-Mikro text",
      discourseMiningForObjectionHandling: "Dissens Umgang mit Worten",
      productSellingPointScriptsMining: "Produkt verkaufs argument",
      conversationScenario: "Gesprächs szene",
      topicOfConversation: "Thema",
      keyEvents: "Schlüssel ereignisse",
      customerLabel: "Kunden-Tags",
      includeAny: "Enthält beliebig",
      includeAll: "Alle enthalten",
      and: "Und",
      or: "Oder",
      question: "Frage satz",
      nonInterrogativeSentence: "Nicht-Frage-Satz",
    },
    coach: {
      notPassed: "Nicht bestanden",
      analogTelephone: "Analoge Telefone",
      enterScenarioInformation: "Szenario informationen eingeben",
      generateDialogueFramework: "Konversation rahmen generieren",
      generatePromptLanguage: "Eingabe aufforderung generieren",
      aIGeneratedDialogue: "AI generiert Gespräche",
      system: "System",
      custom: "Anpassen",
      flow: "Prozess",
      script: "Wörter",
      express: "Ausdruck",
      emotion: "Emotionen",
      rateAccordingToCourseConfiguration: "Bewertung nach Kurs konfiguration",
      intelligentModelRating: "Bewertung für intelligente Modelle",
      allData: "Alle Daten",
      dataFromThisDepartment: "Daten dieser Abteilung",
      multidepartmentalData: "Multi-Sektor-Daten",
      underReview: "Audit",
      no: "Nein",
      yes: "Ist",
      automaticRating: "Automatische Wertung",
      auditRating: "Bewertung überprüfen",
      reRating: "Neu bewertet",
      inAppeal: "Berufung",
      reviewed: "Geüberprüfte",
      notViewed: "Nicht angesehen",
      viewed: "Gesehen",
      uncorrected: "Nicht korrigiert",
      corrected: "Behoben",
      practice: "Übungen",
      examination: "Prüfung",
      other: "Andere",
      notMeetingStandards: "Nicht dem Standard entsprechen",
      meetingStandards: "Erreichen Sie den Standard",
      excellent: "Aus gezeichnet",
      invalid: "Ungültig",
      notStarted: "Nicht gestartet",
      haveInHand: "In Arbeit",
      ended: "Es ist vorbei",
      completed: "Abgeschlossen",
      hangInTheAir: "Unvollendet",
      passed: "Beabschiedet",
      voiceConversation: "Sprach gespräche",
      textDialogue: "Text gespräche",
      slidePresentation: "Folien rede",
      unpublished: "Nicht ver öffentlicht",
      published: "Gepostet",
      narration: "Erzähler",
      studentSpeaking: "Schüler sprechen",
      robotTalk: "Roboter sprechen",
      knowledgeBaseQA: "Fragen und Antworten zur Wissens datenbank",
      slide: "Diashow",
      negativeEmotions: "Negative Emotionen",
      emotionallyNormal: "Die Stimmung ist normal",
      incompleteScript: "Unvollständige Worte",
      completeScript: "Vollständige Worte",
      normalSpeechSpeed: "Normale Sprech geschwindigkeit",
      speakTooFast: "Zu schnell",
      speakTooSlowly: "Zu langsam",
      whole: "Alle",
      singlePage: "Einzels eite",
      qA: "Eine Frage und eine Antwort",
      situationalDialogue: "Situative Gespräche",
      misses: "Fehlschlag",
      hit: "Treffer",
      sequentialQA: "Sequentielle Fragen und Antworten",
      randomQA: "Zufällige Fragen und Antworten",
      mastered: "Gemeistert",
      notMastered: "Nicht gemeistert",
    },
    algorithm: {
      salesEmpowerment: "Verkaufs ermächtigung",
      enterpriseWeChatVoice: "Enterprise WeChat Voice",
      enterpriseWeChatText: "Enterprise WeChat Text",
      waiting: "Warten",
      extracting: "Extraktion",
      success: "Erfolg",
      fail: "Scheitern",
      intelligentQualityInspection: "Intelligente Qualitäts prüfung",
      textDialogue: "Text gespräche",
      voiceCall: "Sprach anrufe",
      smartTags: "Intelligente Tags",
      satisfactionEvaluation: "Zufriedenheit bewertung",
      score: "Bewertung",
      grade: "Bewertung",
      customerServiceTeam: "Kundendienst gruppe",
      conversationAnalysis: "Sitzungs analyze",
    },
    operatorListGather: {
      businessSummary: "Geschäfts zusammenfassung",
      firstSentence: "Erster Satz",
      lastSentence: "Letzter Satz",
      allTime: "Die ganze Zeit",
      nonBusinessWorkingHours: "Nicht geschäftliche Arbeitszeit",
      businessWorkingHours: "Geschäfts arbeitzeit",
    },
    callDialogueManage: {
      eCommerceReview: "E-Commerce-Bewertungen",
      socialMediaComments: "Social Media Review",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Einloggen",
        email: "Mailbox",
        password: "Passwort",
        forgetPwd: "Passwort vergessen",
        emailCannotBeEmpty: "Das Postfach darf nicht leer sein.",
        passwordCannotByEmpty: "Das Passwort darf nicht leer sein.",
        mustChangeLogin:
          "Ihr Passwort muss zurück gesetzt werden. Bitte wenden Sie sich an den Admini strator, um es zurück zusetzen.",
        dismissionLogin: "Es tut mir leid, dass Sie gegangen sind.",
        forbiddenLogin: "Entschuldigung, Ihr Konto ist deaktiviert.",
        loginError:
          "Die Anmeldung ist fehl geschlagen, die Mailbox oder das Passwort ist falsch.",
        loginPrompt: "Melden Sie sich beim System an",
        rememberMe: "Erinnern Sie sich an mich",
      },
      logout: {
        logoutError:
          "Die Abmeldung ist fehl geschlagen. Überprüfen Sie die Netzwerk verbindung oder versuchen Sie es später erneut.",
      },
      resetPassword: {
        forgetPwd: "Passwort vergessen",
        inputEmailPrompt:
          "Geben Sie unten Ihre Konto-E-Mail-Adresse ein, und wir senden Ihnen eine E-Mail und Anweisungen zum Zurücksetzen Ihres Passworts.",
        sendEmailSuccess:
          "Ändern Sie das Passwort, um zu bestätigen, dass das Schreiben erfolgreich an die von Ihnen ausgefüllte Mailbox gesendet wurde. Bitte überprüfen Sie es rechtzeitig.",
        sendEmailFailed:
          "Das Postfach kann nicht gesendet werden. Überprüfen Sie die Netzwerk verbindung oder versuchen Sie es später erneut.",
        setNewPassword: "Legen Sie Ihr neues Passwort fest",
        passwordCannotEmpty: "Passwort darf nicht leer sein",
        passwordFormWrong: "Passwort falsch formatiert",
        confirmPasswordWrong:
          "Die zweimal eingegebenen Passwörter sind nicht gleich.",
        passwordResetSuccess: "Das Passwort wurde erfolgreich zurück gesetzt.",
        passwordResetFailed:
          "Wenn das Kennwort nicht zurück gesetzt werden kann, überprüfen Sie die Netzwerk verbindung oder versuchen Sie es später erneut.",
        linkTimeover:
          "Der Link zum Zurücksetzen des Passworts ist abgelaufen. Bitte versuchen Sie es erneut",
      },
      form: { title: "CASE Formular anzeige" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Mailbox",
          emailFormatError:
            "Das Postfach format ist falsch, bitte erneut eingeben!",
          forgotPassword: "Passwort vergessen?",
          describe:
            "Geben Sie unten Ihre Konto-E-Mail-Adresse ein, und wir senden Ihnen eine E-Mail und Anweisungen zum Zurücksetzen Ihres Passworts.",
          send: "Senden",
          emailCannotBeEmpty: "Das Postfach darf nicht leer sein",
          resetPasswordExpire:
            "Der Link zum Zurücksetzen des Passworts ist abgelaufen. Bitte versuchen Sie es erneut",
        },
        resetPassword: {
          setPassword: "Legen Sie Ihr neues Passwort fest",
          newPassword: "Neues Passwort",
          repeatPassword: "Passwort wiederholen",
          resetPassword: "Passwort zurücksetzen",
          passwordCannotBeEmpty: "Das neue Passwort kann nicht leer sein.",
          confirmPasswordCannotBeEmpty:
            "Wiederholte Passwörter können nicht leer sein.",
          passwordsNotMatch: "Zweimal inkonsistente Passwörter!",
          passwordFormWrong:
            "Das Passwort darf nicht auf Chinesisch sein und die Anzahl beträgt 6-14!",
          linkExpiration:
            "Der Link zum Zurücksetzen des Passworts ist abgelaufen. Bitte versuchen Sie es erneut",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Sie haben keine Anzeige berechtigungen für diese Seite",
      },
    },
    downloadCenter: {
      name: "Download-Center",
      saveTime: "(30 Tage standard mäßig gespeichert)",
      headerTitle: "Datei herunter laden",
      download: "Herunter laden",
      downloadPromp: "Die Download-Aufgabe wurde gesendet. Bitte gehen Sie zu",
      viewProgress: "Fortschritt anzeigen",
    },
    uploadCenter: { name: "Upload-Center" },
    tasks: {
      index: {
        name: "Name",
        remark: "Beschreibung",
        qualityCheckTasksTitle: "Werkbank",
        informationCollection: "Aufgaben sammeln",
        qualityCheckTasks: "Qualitäts prüfungs aufgaben",
        wechatQaTasks: "Analytische Aufgaben",
        moduleName: "Neue Vorlage verwenden",
        creatNewQualityCheckTask: "Neue Aufgabe",
        generalInspectionList: "Allgemeiner Qualitäts prüfbericht",
        editQualityCheckTask: "Aufgaben zur Qualitäts prüfung bearbeiten",
        creatNewQualityCheckTaskError:
          "Neue Qualitäts prüfungs aufgabe fehl geschlagen",
        editQualityCheckTaskError:
          "Die Bearbeitung der Qualitäts prüfungs aufgabe ist fehl geschlagen",
        type: "Formular typ",
        singleScore: "Einzel punkte",
        singleScoreNullError: "Einzelne Punktzahlen können nicht leer sein",
        inspector: "Qualitäts inspektor",
        inspectDataSource: "Datenquellen",
        taskName: "Name der Aufgabe",
        inspectionType: "Art der Qualitäts prüfung",
      },
      manage: {
        screeningCondition: "Screening-Bedingungen",
        template: {
          condition: {
            index: {
              name: "Bedingungen",
              existingConditions: "Bestehende Bedingungen",
            },
            detail: {
              id: "ID",
              name: "Bedingung Name",
              remark: "Beschreibung",
              checkRange: "Erfassungs bereich",
              operator: "Operator",
              operatorLogic: "Operator-Logik",
            },
          },
          checkPointTest: {
            title: "Regel test",
            running: "Regel test, bitte später...",
            clearSuccess: "Das simulierte Gespräch wurde erfolgreich geleert",
            mock: "Simulierte Gespräche",
            save: "Ausgewählte Gespräche speichern",
            saveAll: "Alle Gespräche speichern",
            saveSuccess: "Konversation spart Erfolg",
            close: "Rückzug",
            delete: "Wählen Sie löschen",
            clear: "Entleeren",
            customer: "Kunden",
            agent: "Kunden service",
            test: "Test",
            request:
              "Der simulierte Dialog kann nach dem Entleeren nicht wieder hergestellt werden. Wird das Entleeren bestätigt?",
            deletePopconfirmContent:
              "Bestätigen Sie das Löschen der ausgewählten Konversation?",
          },
          checkPoint: {
            index: {
              name: "Konfiguration von Regeln",
              wechatTitle: "Analyse der Modell konfiguration",
              existingCheckPoints: "Regeln für die Qualitäts prüfung",
              currentCheckPointScore:
                "Die aktuellen Qualitäts prüfungs regeln sind",
              currentCheckPointScoreWechat:
                "Die aktuelle Analyse modell bewertung ist",
              currentCheckPointWeight:
                "Die Summe der Gewichte der aktuellen Qualitäts prüfungs regeln beträgt",
              weightTotalScoreAndItemScore:
                "Gesamt punktzahl: 100 Punkte Einzel punkte:",
              template: "Vorlage für Qualitäts prüfungs regeln",
              import: "Vorlage für Qualitäts prüfungs regeln importieren",
              getTemplateError:
                "Die Vorlage für Qualitäts prüfungs regeln konnte nicht erhalten werden",
              tabs: { template: "Vorlage", demo: "Beispiel" },
              customize: "Leere Vorlage",
              move: "Ziehen Sie, um die Klassifizierung und Sortierung anzupassen",
              classification: "Klassifizierung der Qualitäts prüfung",
              classificationWeChat: "Modell klassifizierung",
              first: "Klassifizierung der ersten Ebene",
              second: "Klassifizierung der zweiten Ebene",
              new: "Neue Qualitäts prüfungs regeln",
              weChatNew: "Hinzufügen eines Analyse modells",
              createWeChat: "Neues Analyse modell",
              empty: "Keine Qualitäts prüfungs regeln,",
              wechatEmpty: "Kein Analyse modell,",
              newSecond: "Neue sekundäre Klassifizierung",
              newFirst:
                "Erstellen Sie eine neue Klassifizierung der ersten Ebene",
              checkPoint: "Qualitäts prüfungs regeln",
              checkPointWeChat: "Analytische Modelle",
            },
            detail: {
              name: "Name",
              remark: "Beschreibung",
              type: "Typ",
              deduction: "Punkt abzug",
              rule: "Regeln",
              highlighted: "Hervorhebung",
              gradeType: "Bewertungs methode",
              interactiveCategory: "Interaktive Klassifizierung",
              predeterminedScore: "Punktzahl",
              weight: "Gewichte",
              na: "N/A",
              default: "Standard punktzahl",
              preRule: "Vorbedingung regeln",
              analysisHighlighted: "Regeln auflösen",
              categorySelectPrompt: "Bitte wählen Sie die Kategorie",
              checkHighlightArrayError:
                "Die Regeln stimmen nicht mit der Hervorhebung überein. Klicken Sie erneut auf die Schaltfläche zum Analysieren der Regeln",
              foctorsHolder: "Bitte wählen Sie die Elemente",
              foctorsErrorNone:
                "Die Multi-Element-Regel ist leer, bitte fügen Sie einen Multi-Element-Typ hinzu",
              naError:
                "Das Element kann nicht geschlossen werden, wenn die Standard punktzahl N/A ist",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A ist keine Anwendung. Legen Sie eine Voraussetzung für die Qualitäts prüfungs regel fest und erkennen Sie, wenn die Bedingungen erfüllt sind. Wenn die Bedingungen nicht erfüllt sind, gilt die Regel nicht, und die Regel wird nicht erkannt.\nAnweisungen zum Ausfüllen der Voraussetzungen: "&&" bedeutet "und" || "bedeutet" oder ","!" Bedeutet "nicht", füllen Sie das Beispiel "(R1 || R2) && R3" aus',
                applicationMessageType: "Anwendungs nachrichten typ",
                selectAll: "Alle ausgewählt",
                highlightTags: "Hervorhebung etikett:",
                ruleType: "Arten von Regeln",
                dataSource: "Datenquellen",
                whole: "Alle",
                enterpriseMicroConversation: "Enterprise Micro Session",
                enterpriseAndMicroCommunication: "Unternehmens ansprache",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Beurteilung des Knotens",
                    dialogueNode: "Konversation knoten",
                    editNodes: "Knoten bearbeiten",
                  },
                  nodeConfig: {
                    nodeName: "Name des Knotens",
                    nodeRules: "Knoten regeln",
                    scoringLogic: "Bewertungs logik",
                    score: "Punktzahl:",
                    rule: "Regeln",
                    multiBranchLogic: "Multi verzweigte Logik",
                    highlight: "Hervorhebung",
                    submit: "Einreichung",
                  },
                  ruleList: {
                    branch: "Zweig",
                    branchName: "Zweig name:",
                    addRules: "Regeln erhöhen",
                  },
                  sidebar: { node: "Knoten" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Automatische Organisation",
                      canvasAdaptation: "Adaptive Leinwand",
                      copy: "Kopieren",
                      delete: "Löschen",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Beurteilen Sie, dass der unter geordnete Knoten des Knotens nicht als Verbindungs ziel verwendet werden kann",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Start knoten können nicht als Verbindungs ziel verwendet werden",
                      startingNodeCannotConnectMultipleNodes:
                        "Start knoten können nicht mit mehreren Knoten verbunden werden",
                      nodeCannotBeConnectedToItself:
                        "Knoten können sich nicht mit sich selbst verbinden",
                      startNode: "Knoten starten",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Alle abbrechen",
                    selectAll: "Alle ausgewählt",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Ob es sich erfüllt",
                    gradedEvaluation: "Datei bewertung",
                  },
                },
              },
            },
            configuration: {
              title: "Qualitäts prüfungs regeln",
              wechatTitle: "Analytische Modelle",
              neme: "Name",
              errorMessage:
                "Löschen fehl geschlagen, mindestens eine Bedingung",
              desc: "Beschreibung",
              status: "Zustand",
              yes: "Wirksam",
              no: "Ungültig",
              namePlaceHolder: "Bitte geben Sie den Namen des Etiketts ein",
              desPlaceHolder: "Bitte geben Sie eine Beschreibung ein",
              nameEmpty: "Der Name kann nicht leer sein",
              desEmpty: "Beschreibung kann nicht leer sein",
              type: "Typ",
              ruler: "Regeln der Intelligenz",
              machineLearning: "Selbst lernende Muster",
              autoScore: "Intelligente Bewertung (alt)",
              manualScore: "Manuelle Bewertung",
              interaction: "Interaktion erkennung (alt)",
              typeTips: {
                model:
                  'Intelligentes Modell: Es handelt sich um ein vor eingestelltes Qualitäts inspektions system ohne Konfiguration und Wartung, mit dem Probleme im Service schnell überprüft werden können. Dieses Modell ist allgemeiner. Wenn Sie die Geschäfts anforderungen nicht erfüllen, verwenden Sie bitte "intelligente Regeln" oder "manuelle Bewertungen".',
                rule: "Intelligente Regeln: Das heißt, die automatische Bewertung basierend auf der Regel-KI",
                manual:
                  "Manuelle Bewertung: manuelle Qualitäts prüfung, manuelle Bewertung",
              },
              model: "Modell:",
              ruleType: {
                words: "Wörter",
                interaction: "Interaktion",
                feature: "Merkmale",
                information: "Informationen",
              },
              testingMode: {
                all: "Alle Schlüssel wörter erkennen",
                any: "Erkennung von Schlüssel wörtern",
              },
              operatorTypesOfInteraction: {
                question: "Eine Frage und eine Antwort",
                element: "Element Antwort",
                repet: "Kontext wiederholen",
                snatch: "Schnapp es sich zu reden",
                silent: "Stille",
              },
              role: "Erkennungs rolle:",
              preposition: "Vor operator:",
              range: "Erfassungs bereich:",
              from: "Die",
              to: "Satz zum ersten",
              end: "Satz",
              hasOldSilentTip:
                'Hallo, um die Genauigkeit zu verbessern, wurde "Smart Rules-Interaction-Silence" auf "Response Timeout" aktualisiert. Es wird empfohlen, die Nutzungs regeln zu aktualisieren!',
              changeToOld: "Wechseln Sie zur alten Version",
              changeToNew: "Wechseln Sie zur neuen Version",
              logic: {
                logic: "Bewertungs logik",
                naTip:
                  "N/A ist keine Anwendung. Legen Sie eine Voraussetzung für die Qualitäts prüfungs regel fest und erkennen Sie, wenn die Bedingungen erfüllt sind. Wenn die Bedingungen nicht erfüllt sind, gilt die Regel nicht. Wenn die Bedingungen nicht erfüllt sind, wird die Regel nicht erkannt.",
                score: "Punktzahl:",
                na: "N/A：",
                naDes: "Dies ist nicht an der zulässigen Erkennung beteiligt",
                preRule: "Vorbedingung regeln:",
                notCatchPreRule: "Wenn die Voraussetzungen nicht erfüllt sind:",
                naChecked: "Beim Treffer von N/A:",
                preRuleEmpty: "Die Vorbedingung regel kann nicht leer sein",
                operatorEmpty:
                  "Die notwendigen Bedingungen im Operator können nicht leer sein",
                multipleEmpty:
                  "Die erforderlichen Bedingungen in der Mehrfach logik bewertung können nicht leer sein",
                average: "Durchschnitt liche Punktzahl innerhalb der Gruppe",
                addOrSubtract: "Direkte Addition und Subtraktion",
                noChange: "Keine Addition oder Subtraktion",
                rule: "Regeln:",
                all: "Erfüllen Sie alle Operatoren",
                any: "Erfüllen Sie jeden Operator",
                customize: "Benutzer definierte Operator logik",
                multiple: "Mehrere logische Bewertungen",
                heightLight: "Hervorhebung:",
                machineLearningTip:
                  "Der Selbst lernmodus ist derzeit nur für einzelne Satz techniken wirksam. Bitte verwenden Sie intelligente Regeln für komplexe Wörter",
                strategy: "Strategie:",
              },
            },
            component: {
              approvalSubmittedSuccessfully:
                "Genehmigung erfolgreich eingereicht",
            },
            components: {
              pageHeaderExtra: {
                test: "Test",
                formal: "Offiziell",
                submit: "Einreichung",
              },
              createModal: { index: { myRules: "Meine Regeln" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Prozess automatisierung",
              screeningCondition: "Screening-Bedingungen",
              aiScreeningCondition: "Intelligente Filter bedingungen",
              rule: "Regeln",
              automaticAddition: "Automatisches Hinzufügen",
              automaticAdditionTip:
                "Automatisches Hinzufügen von Daten zu Aufgaben in Echtzeit oder in regelmäßigen Abständen zur KI-Qualitäts prüfung",
              autoAdd: {
                autoAddType: "Automatisches Hinzufügen",
                addFrequency: "Frequenz hinzufügen",
                add: "Hinzufügen",
              },
              automaticSamplingInspection: "Automatische Probenahme",
              automaticSamplingInspectionTip:
                "Extrahieren Sie automatisch Daten, für die eine manuelle Qualitäts prüfung erforderlich ist. Nehmen Sie beispiels weise täglich 10% der gestrigen zufälligen Daten für manuelle Stichproben prüfungen",
              everyAgentCheckLimit: "Obergrenze für jeden Kunden service",
              automaticAllocation: "Automatische Zuordnung",
              automaticAllocationTip:
                "Zuordnung von Stichproben inspektionen: Die extrahierten Daten werden automatisch dem Qualitäts inspektor zugewiesen. Beispiels weise werden täglich 10% der gestrigen zufälligen Daten für manuelle Stichproben ausgewählt, und die beiden Qualitäts inspektoren werden dieser Aufgabe durchschnitt lich zufällig zugewiesen",
              automaticAllocationTip2:
                "Überprüfung zuweisung: Die angefochten Daten werden automatisch dem Qualitäts inspektor zur Überprüfung zugewiesen. Beispiels weise werden die Daten der täglichen Beschwerde gleichmäßig und zufällig den 2 Qualitäts inspektoren dieser Aufgabe zur Überprüfung zugewiesen",
              automaticPush: "Automatischer Push",
              automaticPushTip:
                "Trigger be nachricht igung: Benachricht igung/Warnung während der Qualitäts prüfung. Wenn beispiels weise ein schwer wiegender Fehler im Kundendienst auftritt, senden Sie eine Benachricht igungs nachricht an die Enterprise Micro-Gruppe",
              automaticPushTip2:
                "Zeit bericht: Senden Sie die Ergebnisse der Qualitäts prüfung an den Zeit bericht in Form einer E-Mail. Senden Sie beispiels weise täglich um 9:00 Uhr die Ergebnisse der Qualitäts prüfung der gestrigen manuellen Probenahme an die Mailbox des Kundendienst leiters",
              automaticAssess: "Automatische Auswertung",
              automaticAssessTip:
                "Die Ergebnisse der manuellen Probenahme werden regelmäßig mit den Ergebnissen der KI-Qualitäts prüfung verglichen, um die Genauigkeit zu bewerten. Beispiels weise wird die Genauigkeit der gestern stichproben artigen Daten täglich um 8:00 Uhr bewertet",
              reexaminationComplaint: "Beschwerde erneut",
              spoktReviewDetail: "Geschäfts konfiguration",
              spoktReviewDetailTip:
                "Konfigurieren Sie Funktionen wie Qualitäts prüf listen, Stichproben listen, Beschwerde dienste, Überprüfung listen, Bemerkungen usw.",
              qaDetailAction: "Konfiguration der Qualitäts prüfung",
              spoktDetailAction: "Probenahme konfiguration",
              reviewDetailAction: "Überprüfen Sie die Konfiguration",
              appealAction: "Konfiguration der Beschwerde",
              remarkTemplate: "Bemerkungen Vorlage",
              automaticArchiving: "Automatische Archivierung",
              conditionalRules: "Bedingung regeln",
              qualityInspectionConditions: "Qualitäts prüfungs bedingungen",
              unopened: "Noch nicht geöffnet",
              sampleInspectionDistributionModel: "Probenahme zuweisung",
              reviewAllocationModel: "Überprüfung der Zuordnung",
              distributionModelTitle: "Verteilungs strategie:",
              pushRules: "Push-Regeln",
              push: "Push",
              assessRules: "Bewertungs regeln",
              on: "on",
              off: "off",
              updateStatusSuccess: "Status änderung erfolgreich!",
              updateStatusFailed:
                "Die Status änderung ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
              sampleNumberOrPercent: "Anzahl der Stichproben oder Proportionen",
              inspectorList: "Designierter Qualitäts inspektor",
              numberOfSubmission: "Erlaubt die Anzahl der Einreichungen",
              rewivewDays: "Tag",
              TimeOfSubmission: "Gültigkeit dauer der Einreichung des Antrags",
              rewivewSecond: "Zeiten",
              switchOn: "Öffnen",
              switchOff: "Schließen",
              notMatchAnyRule: "Wenn keine willkürliche Regel überein stimmt:",
              appealOptionsList: {
                hiddenInspector:
                  "Berufung verbirgt Informationen zum Qualitäts inspektor",
                hiddenHit:
                  "Die Beschwerde seite unterstützt die Hervorhebung und Position ierung von Treffern",
              },
              caseLibrary: "Synchron isierung der Fall bibliothek",
              caseLibrarySyncTip:
                "Synchron isieren Sie die vom QA-System hinzugefügten Fälle mit dem Kundendienst system",
              addCase: "Erhöhen Sie die Synchron isierung",
              caseLibraryId: "Fall bibliothek",
              caseLibraryIdTip:
                "Gruppierung von Fall bibliotheken für das QA-System",
              udeskCaseLibraryIdTip:
                "Fall gruppierung des Kundendienst systems",
              syncHis: "Synchrone Geschichte Fall",
              otherSettings: "Andere Einstellungen",
              udeskCaseLibraryId: "Push-Fall gruppe",
              url: "Push-URL",
              urlTip:
                "Bitte geben Sie die Schnitts telle adresse ein, die mit http:// oder https:// beginnt",
            },
            templateNew: {
              secondaryReview: "Sekundäre Überprüfung",
              numberOfAppealsAllowedToBeSubmitted:
                "Anzahl der Einreichungen von Beschwerden erlaubt",
              validityOfSubmissionOfAppeal:
                "Gültigkeit dauer der Einreichung einer Beschwerde",
              validityOfAppealReview: "Gültigkeit dauer der Berufungs prüfung",
              hours: "Stunden",
              businessSummary: "Geschäfts zusammenfassung",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Untergrenze für jeden Kunden service",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Die Geschäfts zusammenfassung wird automatisch vom großen Modell generiert, und die Generierung dimension kann je nach Geschäfts situation angepasst werden",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Verteilungs strategie nur für Prüfer der ersten Ebene",
              secondaryCompounding: "Sekundär verbund:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Ein zweiter Prüfer unterstützt nur eine gleichmäßige Verteilung",
              secondLevelReviewer: "Prüfer der zweiten Ebene:",
              samplingInspectionTarget: "Probenahme ziele",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Legen Sie die Ziele fest, die bei der manuellen Probenahme erreicht werden sollten, und geben Sie eine Erinnerung an, wenn das Ziel nicht erreicht oder übers ch ritten wird. Beispiels weise sollte jeder Kundendienst 100 Probenahme inspektionen pro Monat durchführen. Wenn es weniger als 80 gibt, werden die Seiten für Qualitäts prüfungen und Probenahme aufgefordert.",
            },
            component: {
              verificationFailed: "Überprüfung fehl geschlagen",
              pleaseAddASecondLevelQualityInspector:
                "Bitte fügen Sie einen Qualitäts inspektor der zweiten Ebene hinzu",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Eingabe aufforderung",
                    fieldName: "Feld name",
                    fieldDescription: "Feld beschreibung",
                    generateContent: "Inhalte generieren",
                    enabled: "Ob aktiviert werden soll",
                    generationMethod: "Generieren",
                    automaticGeneration: "Automatische Generierung",
                    manualTrigger: "Manueller Auslöser",
                    filterCondition: "Screening-Bedingungen",
                    return: "Zurück",
                    preserve: "Speichern",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Ergebnisse generieren" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Unbegrenzt",
                    limitedScope: "Begrenzter Bereich",
                    add: "Hinzufügen",
                    thereIsCurrentlyNoDataAvailable: "Keine Daten",
                  },
                },
              },
              index: {
                fieldName: "Feld name",
                fieldDescription: "Feld beschreibung",
                nothing: "Keine",
                generationMethod: "Generieren",
                generateManually: "Manuelle Generierung",
                automaticGeneration: "Automatische Generierung",
                generateContent: "Inhalte generieren",
                notQualified: "Unbegrenzt",
                filterCondition: "Screening-Bedingungen",
                enabled: "Ob aktiviert werden soll",
                type: "Typ",
                system: "System",
                custom: "Anpassen",
                operation: "Betrieb",
                edit: "Bearbeiten",
                areYouSureToDeleteIt: "Sicher zu löschen?",
                delete: "Löschen",
                addField: "Felder erhöhen",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Rollen konfiguration",
              roleConfigTip:
                "Wenn die importierten Anruf daten Mono sind, werden die Konfiguration identifikation regeln für den Empfänger getrennt verarbeitet. Die Kundendienst rolle entspricht den Regeln",
              operator: "Operator",
              operatorLogic: "Operator-Logik",
            },
          },
          manualAdd: {
            index: {
              name: "Manuelles Hinzufügen",
              tip: "Wird verwendet, um Vorschau daten durch manuelle Konfiguration bedingungen zu filtern und sie schnell zu den Qualitäts prüfungs aufgaben hinzuzufügen",
              screeningCondition:
                "Fügen Sie gemäß den Filter bedingungen hinzu",
              aiScreeningCondition: "Intelligente Filter bedingungen",
              rule: "Regeln",
              createTime: "Zeit hinzufügen",
              filterTitle: "Inhalte filtern",
              filterRule: "Screening-Bedingungen",
              startTime: "Zeit für den Start",
              endTime: "Endzeit",
              records: "Datensätze importieren",
              createUserName: "Bediener",
              successCount: "Erfolg hinzufügen",
              conditionRule: "Bedingung regeln:",
              preview: "Vorschau",
              previewData:
                "Derzeit entspricht die Filter bedingung {0}, die Aufgabe {1} wurde hinzugefügt, die Aufgabe {2} kann hinzugefügt werden, die Aufgabe {2} kann nicht hinzugefügt werden, die Aufgabe {3} kann nicht hinzugefügt werden",
              failCount: "Hinzufügen fehl geschlagen",
            },
          },
          basicInfo: {
            index: {
              title: "Grundlegende Informationen",
              name: "Name",
              taskName: "Name der Aufgabe",
              taskNamePlaceholder: "Bitte geben Sie den Namen der Aufgabe ein",
              requiredName: "Der Aufgaben name kann nicht leer sein",
              taskRemarkPlaceholder:
                "Bitte geben Sie eine Aufgaben beschreibung ein",
              requiredRemark: "Aufgaben beschreibung kann nicht leer sein",
              remark: "Beschreibung",
              taskRemark: "Aufgaben beschreibung",
              source: "Datenquellen",
              benchmarkScore: "Benchmark-Punkte",
              benchmarkScorePlaceholder:
                "Bitte geben Sie den Benchmark-Punkt ein",
              scoreLowerLimit: "Mindest punktzahl Untergrenze",
              scoreUpperLimit: "Maximale Punktzahl Obergrenze",
              inspectType: "Art der Qualitäts prüfung",
              nlpModel: "NLP-Modell",
              commonInspect: "Konventionelle Qualitäts prüfung",
              associateInspect: "Verwandte Qualitäts prüfung",
              associateCycle: "Assoziation zyklus",
              associateRegulation: "Assoziation regeln",
              judgeStrategy: "Logik ausführen",
              rule: "Regeln",
              commonTip:
                "Konventionelle Qualitäts prüfung: Geeignet für einzelne Kommunikation, Dialog und Qualitäts prüfung des Inhalts einer einzelnen Kommunikation",
              associateTip:
                "Zugehörige Qualitäts prüfung: Gilt für mehrere Durchsagen und Dialoge und verknüpft den Inhalt mehrerer Kommunikationen mit der Qualitäts prüfung",
              cycleTip:
                "Wenn mehrere verbundene Kommunikation und Dialoge die Daten im zugehörigen Zeitraum erfüllen, geben Sie die Qualitäts prüfung der Aufgabe ein",
              scoreLowerLimitPlaceholder:
                "Bitte geben Sie die Mindest punktzahl ein",
              scoreUpperLimitPlaceholder:
                "Bitte geben Sie die maximale Punktzahl ein",
              call: "Sprach anrufe",
              dialogue: "Text gespräche",
              user: "Qualitäts inspektor",
              wechatUser: "Betreiber",
              noDesignated: "Nicht angeben",
              type: "Formular typ",
              singleScore: "Einzel punkte",
              conduct:
                "Bitte verbessern Sie zuerst die folgenden Informationen. Klicken Sie nach Abschluss auf Weiter",
              conduct2:
                "Bitte schließen Sie die Konfiguration der Qualitäts prüfungs regeln und des Qualitäts prüfungs punkts ab. Wenn Sie die Konfiguration abgeschlossen haben, können Sie auf Weiter klicken oder den Leitfaden beenden",
              conduct3:
                "Die Qualitäts prüfungs bewertung basiert auf der Bewertungs konfiguration des Qualitäts prüfungs punkts und seiner Bewertung. Sie können verschiedene Qualitäts prüfungs ergebnisse definieren. Wenn Sie keine Bewertung benötigen, können Sie diesen Schritt direkt überspringen. Wenn Sie die Konfiguration abgeschlossen haben, können Sie auf klicken Weiter oder beenden Sie den Start",
              conduct4:
                "Sie können die Daten eigenschaften konfigurieren, die automatisch in die Aufgabe eingeführt werden, gemäß den Daten merkmalen, die bei dieser Qualitäts prüfungs aufgabe überprüft werden sollen, an der Position zum automatischen Hinzufügen von Daten. Wenn Sie vorerst keine Daten automatisch hinzufügen müssen, können Sie auf Überspringen klicken. Wenn Sie die Konfiguration abgeschlossen haben, Sie können auf Weiter klicken",
              conduct5:
                "Wenn Sie eine Mono-Aufnahme verwenden, konfigurieren Sie diese Option und klicken Sie auf Weiter. Verwenden Sie nur den Kundendienst, um dem System zu helfen, verschiedene Spuren zwischen dem Kundendienst und dem Kunden zu identifizieren. Wenn Sie eine Zweikanal-Aufnahme verwenden oder die Konfiguration abgeschlossen haben, können Sie auf Überspringen klicken",
              conduct6:
                "Sie können die Funktion manuell hinzufügen, um die vorhandenen Dialog daten im System für die Qualitäts prüfung einzuführen, oder Sie haben eine manuelle Hinzufügen aufgabe gesendet. Klicken Sie einfach auf Fertig stellen",
              helper: "Hilfe-Dokumentation anzeigen",
              quit: "Verlassen Sie die Anleitung",
              next: "Weiter",
              complete: "Fertig",
              skip: "Überspringen",
              cancel: "Stornierung",
              delete: "Löschen",
              save: "Speichern",
            },
            template: {
              samplingInspector: "Probenahme",
              sessionType: "Art der Sitzung",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Werkbank für Qualitäts prüfung",
              search: "Screening",
              export: "Exportieren",
              condition: "Bedingungen",
              logic: "Logik",
              reQualityInspection: "Nachprüfung",
              reQualityInspectionUnderWay:
                "Überprüfen Sie die Aufgabe erneut...",
              reQualityInspectionUnderWayTip:
                "Das Verlassen der Seite nach dem Schließen des Fensters hat keinen Einfluss auf die Aufgabe",
              reQualityInspectionDone:
                "Die erneute Qualitäts prüfung ist abgeschlossen",
              reQualityInspectionDoneTip:
                "Die erneute Qualitäts prüfung wurde erfolgreich abgeschlossen. Klicken Sie auf die Schaltfläche Bestätigen",
              endReQualityInspection: "Aufgabe beenden",
              alreadyDoneTip: "Abgeschlossen",
              totalTip: "Insgesamt",
              unit: "Artikel",
              endReInspectionSuccess:
                "Die Aufgabe der erneuten Qualitäts prüfung wurde beendet",
              closeWindow: "Fenster schließen",
              endReInspectionFailed:
                "Die erneute Qualitäts prüfungs aufgabe ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
            },
            detail: {
              name: "Details zur Qualitäts prüfung",
              reviewName: "Details überprüfen",
              spotCheckName: "Details zur Probenahme",
              audioText: "Aufnahme text",
              callInfo: "Anruf informationen",
              satisfactionEvaluation: "Zufriedenheit bewertung:",
              queueTime: "Das Anstehen ist zeit aufwändig:",
              ringingTime: "Klingeln zeit:",
              callTime: "Anruf ereignis:",
              hangUpIdentity: "Anruf auflegen:",
              location: "Zuschreibung:",
              source: "Quelle:",
              agent: "Kunden service:",
              deviceType: "Gerätetyp:",
              callType: "Art des Anrufs:",
              phoneNumber: "Relais nummer:",
              task: "Aufgaben:",
              commentsTip:
                "Bitte geben Sie den Kommentar zur Qualitäts prüfung ein",
              lastPage: "Vorherige Seite",
              nextPage: "Nächste Seite",
              score: "Qualitäts prüfungs bewertung",
              review: "Überprüfung",
              file: "Archivierung",
              changeList: "Änderungs protokoll",
              fullMarks: "Volle Punktzahl",
              most: "Am meisten",
              saveReview: "Überprüfung und Einreichung",
              saveSpotCheck: "Probenahme einreichen",
              point: "Qualitäts prüf punkt",
              sampleAssignInspector: "Zuweisen von Probenahme personal",
              reviewAssignInspector: "Zugewiesene Prüfer",
              sampleStatus: "Status der Probenahme",
              read: "Lesen",
              user: "Benutzer",
              confirmer: "Person bestätigen",
              voiceDownloadError:
                "Der Sprach download ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
              smartTags: "Intelligente Tags",
              interactiveTip:
                "Verwenden Sie die interaktive Erkennung, um die Verwendung von Kundendienst-FAQ zu erkennen",
              interactiveIsEmptyTip:
                "Interaktive Erkennung von Inhalten nicht erkannt",
              businessRecords: "Geschäfts aufzeichnung",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Analyse des Unternehmens-WeChat-Gruppen chats",
            },
          },
        },
      },
      cards: {
        health: "Qualitäts prüfung Gesundheit",
        accuracy: "Zustimmung srate gefunden",
        sessionsTotal: "Gesamtzahl der Sitzungen",
        enable: "Aktivieren",
        disable: "Deaktivieren",
        none: "Keine",
        call: "Der Anruf",
        dialogue: "Dialog",
        taobao: "E-Commerce",
        wecom: "Sitzung",
        ticket: "Arbeits auftrag",
      },
      component: {
        backup: "(Backup)",
        taskSaveAsSucceeded: "Aufgabe als Erfolg speichern",
        copyContent: "Inhalt kopieren",
        essentialInformation: "Grundlegende Informationen",
        ruleConfiguration: "Konfiguration von Regeln",
        basicInformationQualityInspector:
          "Grundlegende Informationen-Qualitäts inspektor",
        qualityInspectionRating: "Qualitäts prüfungs bewertung",
        roleConfiguration: "Rollen konfiguration",
        autoAddConfiguration: "Konfigurationen automatisch hinzufügen",
        autoPushConfiguration: "Automatische Push-Konfiguration",
        automaticSamplingConfiguration:
          "Automatische Stichproben konfiguration",
        automaticAllocationConfiguration:
          "Konfigurationen automatisch zuweisen",
        businessConfiguration: "Geschäfts konfiguration",
        caseBaseConfiguration: "Fall bibliothek",
      },
      template: {
        cancel: "Stornierung",
        complete: "Fertig",
        sort: "Sortieren",
        ruleApproval: "Regel genehmigung",
        enterpriseMicroConversationAnalysisTask:
          "Aufgaben zur Analyse von Unternehmens mikro konversationen",
        enterpriseAndMicroVoiceAnalysisTask:
          "Enterprise Micro-Sprach analyze aufgabe",
        saveTaskAs: "Aufgabe speichern unter",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Details zu Regeln" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Erfolgreich modifiziert",
            automaticApproval: "Automatische Genehmigung",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Erfolgreich modifiziert",
            test: "Test",
            formal: "Offiziell",
            adopt: "Durch",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Ist es sicher, abgelehnt zu werden?",
              pleaseEnterTheRejectionReason:
                "Bitte geben Sie den Grund für die Ablehnung ein",
              reject: "Abgewiesen",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Erfolgreich modifiziert",
              taskName: "Name der Aufgabe",
              dataSource: "Datenquellen",
              qualityInspectionType: "Art der Qualitäts prüfung",
              state: "Zustand",
              updateTime: "Aktualisierungszeit",
              updatedBy: "Person aktualisieren",
              approver: "Genehmiger",
              reasonForRejection: "Gründe für die Ablehnung",
              operation: "Betrieb",
              details: "Details",
              adopt: "Durch",
              whole: "Alle",
              approvalStatus: "Genehmigungs status",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Übersicht",
      hi: "Hallo,",
      standardEdition: "Standard Edition",
      call: "Guthaben für die Sprach prüfung",
      text: "Gültigkeit dauer der Text qualitäts prüfung",
      collectionTasks: "Sammlung von Qualitäts prüfungs aufgaben",
      noCollection: "Keine Sammlung von Qualitäts prüfungs aufgaben,",
      goToCollecte: "Zu sammeln",
      yesterdayOverView: "Geschäfts statistik von gestern",
      yesterdayAgent: "Das gestrige Kundendienst-Ranking",
      yesterdayInspWork: "Die Arbeit des Qualitäts inspektors gestern",
      operationalStatistics: {
        title: "Geschäfts statistik",
        qualityTesting: "Qualitäts prüfung",
        spotCheck: "Probenahme",
        appeal: "Beschwerde",
        chartTitles: {
          qualityTestingTrend: "Qualitäts kontroll volumen Trend",
          qualityTestingAnalysis: "Qualitäts prüfungs aufgaben",
          spotCheckTrend: "Trend zur Probenahme",
          spotCheckAnalysis: "Probenahme aufgabe",
          appealTrend: "Beschwerden im Trend",
          appealAnalysis: "Beschwerde aufgabe",
        },
        titles: {
          qualityDataTend: "Qualitäts prüfungs daten verteilung",
          qualityCover: "Qualitäts prüfungs abdeckung",
          qualityAverageTime:
            "Durchschnitt liche Wartezeit für Qualitäts prüfungen",
          qualityCoverTend: "Qualitäts prüfungs abdeckung Trend",
          handCover: "Manuelle Verarbeitung situation",
          checkCover: "Stichproben abdeckung",
          resAverageTime: "Durchschnitt liche Verarbeitung dauer",
          handTend: "Künstliche Verarbeitung von Trends",
          dataTend: "Verteilung von Daten aufgaben",
        },
        qualityAverage: "Durchschnitt liche Qualitäts prüfung",
        scoreTrend: "Bewertungs trend für Qualitäts prüfungen",
        qualityAverageTime:
          "Durchschnitt liche Wartezeit für Qualitäts prüfungen",
        callCoverage: "Abdeckung der Sprach qualitäts prüfung",
        textCoverage: "Text Qualitäts prüfungs abdeckung",
        checkCount: "Stichproben daten",
        siphonCount: "Extrahierte Datenmenge",
        reviewCount: "Datenvolumen überprüfen",
        appealCount: "Volumen der Beschwerde daten",
        checkRate: "Stichproben durchführung verhältnis",
        reviewRate: "Anteil der Beschwerde prüfung",
        PieTitle: {
          totalCount: "Gesamtzahl der Gespräche",
          totalCall: "Anzahl der Sprach anrufe",
          totalIm: "Anzahl der Text gespräche",
          inspectionCall: "Sprach qualitäts prüfung",
          inspectionIm: "Text qualitäts prüfung",
          checkCall: "Sprach stichproben",
          unCheckCall: "Nicht geprühtes Sprach volumen",
          checkIm: "Text stichproben",
          unCheckIm: "Text nicht stichproben artig",
          reviewCall: "Sprach überprüfung",
          reviewIm: "Text überprüfung",
          unReviewCall: "Nicht überprüfte Sprach menge",
          unReviewIm: "Nicht überprüfte Text menge",
          unInspectionCall: "Sprach prüfung nicht geprüft",
          unInspectionIm: "Text nicht geprüft",
        },
        check_rate: "Stichproben abdeckung",
        voice_check_rate: "Abdeckung der Spracher kennung",
        text_check_rate: "Text-Stichproben abdeckung",
        sample_execute_time: "Durchschnitt liche Probenahme verarbeitung dauer",
        review_execute_time:
          "Durchschnitt liche Bearbeitungs zeit für Beschwerden",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Kanban für Qualitäts prüfungs regeln",
          agent: "Kundendienst-Ranking",
        },
        tableTips: {
          conditions:
            "<Div> Sortieren Sie die Gesamtzahl der Gespräche, die gegen die Qualitäts prüfungs regeln verstoßen und diese einhalten. <Br/>\nQualitäts prüf punkt: Name des Qualitäts prüf punkts <br />\nGesamtzahl der illegalen Kommunikation dialoge: Gesamtzahl der Kommunikation dialoge, die gegen den Qualitäts kontroll punkt verstoßen <br />\nAnteil der Verstöße: Anteil der Verstöße = Gesamtzahl der Gespräche über Verstöße/Gesamtzahl der Gespräche über Verstöße </div>",
          agent:
            "<Div> Sortieren Sie die durchschnitt liche Bewertung der Sitze <br />\nName des Kundendienstes: Name des Kundendienstes <br />\nKundendienst gruppe: Kundendienst gruppe (Organisation informationen in Kundendienst informationen)<br />\nGesamtzahl der bewerteten Kommunikation dialoge: Die Anzahl der Qualitäts prüfungs dialoge des Kundendienstes <br />\nDurchschnitt liche Bewertung der Qualitäts prüfung: Die durchschnitt liche Bewertung der Qualitäts prüfung des Kundendienstes (Gesamt bewertung/Gesamt konversationen)</div>",
        },
        tips: {
          total:
            "Die Gesamtzahl der Anrufe/Dialoge zur Eingabe der Qualitäts prüfungs aufgabe",
          agentTotal:
            "Die Gesamtzahl des Kundendienstes für Qualitäts prüfungen, die die Aufgabe der Qualitäts prüfung eingegeben haben",
          avg: "Durchschnitt liche Bewertung der Qualitäts prüfung",
          violation:
            "Die Anzahl der Verstöße gegen die Qualitäts prüfung in allen Dialogen",
          compliance:
            "Summen der Häufigkeit, mit der die Qualitäts prüfungs elemente während des Qualitäts prüfungs prozesses erfüllt werden",
          check: "Gesamtzahl der manuellen Stichproben",
          appeal:
            "Die Gesamtzahl der eingereichten Beschwerden in der Gesamtzahl der Gespräche",
          review:
            "Die Gesamtzahl der Überprüfungen in der Gesamtzahl der Gespräche",
        },
        subTips: {
          violation:
            "Verstoß rate: Gesamtzahl der Verstöße/Gesamtzahl der Qualitäts prüf vorgänge * 100%",
          compliance:
            "Compliance-Rate: Gesamtzahl der Compliance-Bestimmungen/Gesamtzahl der Qualitäts prüfungs gegenstände * 100%",
          check:
            "Stichproben bedeckung srate: Gesamtzahl der manuellen Stichproben/Gesamt qualitäts prüfung * 100%",
          appeal:
            "Beschwerde quote: Gesamtzahl der Beschwerden/Gesamt qualitäts prüfung * 100%",
          review:
            "Überprüfung srate: Gesamtzahl der Überprüfung/Gesamt qualitäts prüfung * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Systeme in stellungen",
          systemName: "Name des Systems",
          speakerRecognize: "Wort erkennung",
          massage: "Benachricht igung über Nachrichten",
          evaluate: "Künstliche kognitive Bewertung",
          evaluateTip:
            "Wählen Sie nach dem Öffnen eine schnelle Probenahme in den Details der Qualitäts prüfung aus, um zur manuellen Bewertung zu veranlassen",
          speakerRecognizeTip:
            "Um über die API auf die Anruf aufzeichnung eines Dritt anbieters zuzugreifen, muss angegeben werden, ob der linke Kanal der Kundendienst oder der Kunde ist",
          speakerRecognizeTip2:
            "Single-Channel-Aufnahme wird durch Texter kennung und Rollen konfiguration definiert die Rolle des Kundendienstes",
          enabledImSendStatusFilter:
            "Text Qualitäts prüfung Nachrichten filterung",
          enabledImSendStatusFilterTips:
            "Der Erkennungs inhalt der Operatoren der Qualitäts prüfung enthält keine Nachrichten mit dem Status [zurück gezogen], [fehl geschlagen senden], [Offline-Nachrichten ungelesen]",
          lang: "Einstellungen für die Systemsp rache",
          zh: "Vereinfachtes Chinesisch",
          en: "English",
          tw: "Traditionelles Chinesisch",
        },
        template: {
          largeModelSettings: "Große Modell-Einstellungen",
          indonesian: "Indonesisch",
          vietnamese: "Vietnam esisch",
          portuguese: "Portugiesisch",
          malay: "Malaiisch",
          french: "Französisch",
          spanish: "Spanisch",
          japanese: "Japanisch",
          thai: "Thailändisch",
          blockSystemSendingMessages:
            "Abschirmung system zum Senden von Nachrichten",
          textDataDesensitization: "Desensi bilisierung von Text daten",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Wenn der Schalter eingesc haltet ist, wird eine Dropdown-Liste der Desensi bilisierungs felder angezeigt (Unterstützung für Mehrfach auswahl), Desensi bilisierungs felder: Name, Handynummer, Adresse",
          desensitizingField: "Desensi bilisierungs felder",
          name: "Name der Person",
          cellphoneNumber: "Handynummer",
          address: "Adresse",
          dataCleaning: "Daten bereinigung",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Nach dem Einrichten werden die Daten des Qualitäts inspektions gesprächs und des Unternehmens-WeChat-Gesprächs regelmäßig bereinigt und können nicht wieder hergestellt werden. Bitte seien Sie vorsichtig",
          newCleaningRules: "Neue Reinigungs regeln",
          qualityInspectionRuleApproval:
            "Genehmigung der Qualitäts prüfungs regeln",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Nach dem Öffnen muss die Änderung der Qualitäts prüfungs regeln genehmigt werden, bevor sie wirksam wird",
          maskOfflineMessageUnread: "Ungelesene Offline-Nachrichten blockieren",
          privacyNumberProtection: "Schutz der Datenschutz nummer",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Desensi bilisiert nach dem Öffnen das Feld "Kunden telefon" im Anruf, das als 1881 ******* angezeigt wird',
          shieldingWithdrawn: "Abschirmung zurück gezogen",
          maskSendingFailed: "Abschirmung fehl geschlagen beim Senden",
        },
        component: {
          conversation: "Der Anruf",
          dialogue: "Dialog",
          weCom: "Enterprise WeChat",
          day: "Tag",
          week: "Woche",
          month: "Monat",
          year: "Jahr",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Bereinigen Sie den Umfang",
            dataCleaningTimeSetting:
              "Einstellungen für die Daten bereinigung szeit",
            afterCleanUpTheData: "Nach der Reinigung der Daten",
            dataAfterComplaintCompletion: "Daten nach Abschluss der Beschwerde",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Erfolgreiche Ausführung",
          saveSuccessful: "Speichern Sie erfolgreich",
          speechRecognition: "Spracher kennung",
          reExecuteErrorCorrection:
            "Führen Sie die Fehler korrektur erneut aus",
          confirmExecution: "Bestätigung der Ausführung",
          voiceDetection: "Sprach erkennung",
          noiseDetectionPowerLevel: "Rausch erkennung Powerlevel ≤",
          pleaseEnterTheThreshold: "Bitte geben Sie den Schwellen wert ein",
          volumeDetectionPowerLevel: "Lautstärke erkennung Powerlevel ≥",
          reset: "Zurücksetzen",
          preserve: "Speichern",
        },
        coachTemplate: {
          french: "Französisch",
          spanish: "Spanisch",
          japanese: "Japanisch",
          thai: "Thailändisch",
          loginPopupWindow: "Popup-Fenster anmelden",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Nach dem Öffnen wird beim Aufrufen des intelligenten Sparring systems eine Popup-Eingabe aufforderung angezeigt",
          title: "Titel",
          text: "Text",
          checkBoxContent: "Inhalt der Kästchen",
          informedConsentForm: "Einvers tändnis erklärung",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Um Ihnen zu helfen, Ihre Kommunikations-und Sprach fähigkeiten sowie die Anwendung von Fachwissen zu verbessern, damit Sie jederzeit und überall üben und Feedback in Echtzeit erhalten können, sammeln wir über diese Plattform die folgenden Informationen über Sie:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Ich habe diese Einvers tändnis erklärung vollständig gelesen und vollständig verstanden und meine Zustimmung zur Verarbeitung meiner sensiblen persönlichen Daten gemäß den in dieser Einvers tändnis erklärung beschriebenen Zwecken und Methoden feierlich erklärt",
        },
      },
      systemIntegration: {
        title: "Systeme in stellungen",
        ccIntegrationTips:
          "Intelligente Qualitäts prüfung Docking Udesk Call Center Beschreibung Dokument",
        unauthorized: "Nicht autorisiert",
        authorized: "Autorisiert",
        udeskDomain: "Domain name des Kundendienst systems",
        udeskToken: "Kundendienst system Schlüssel",
        udeskAdminEmail: "Admini strator konto des Kundendienst systems",
        password: "Passwort für das Admini strator konto",
        applyAuth: "Autor isierung",
        cancelAuth: "Autor isierung aufheben",
        reApplyAuth: "Wieder autorisieren",
        keyTips:
          "Backstage-Verwaltung des Kundendienst systems-API-Authentifizierung Token bei der Single Sign-On",
      },
      customerField: {
        title: "Kunden feld",
        table: {
          propName: "Feld name",
          propDesc: "Feld beschreibung",
          valueType: "Feld typ",
          propType: "So erstellen",
          createTime: "Zeit erstellen",
          canNotEdit: "System felder unterstützen keine Bearbeitung",
        },
        edit: "Felder bearbeiten",
        new: "Neues Feld",
      },
      wechatDocking: {
        title: "Enterprise-Micro-Docking",
        formLabels: {
          companyId: "Unternehmens-ID",
          communicationKey: "Kommunikation schlüssel",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL für Ereignis rückrufe",
          applicationName: "Name der Anwendung",
          applicationId: "ID der Web anwendung",
          applicationSecret: "Apps secret",
        },
        formTitle: {
          initialForm: "Erste Einstellungen",
          synchronizeForm: "Synchron isierung des Adressbuchs",
          settingForm: "App-Einstellungen",
        },
        qrCodeUrlTip:
          "Bitte scannen Sie den folgenden QR-Code, um die Anwendungs bindung zu entwickeln",
      },
      taobao: {
        formLabels: {
          name: "Name des Geschäfts",
          account: "Konto",
          password: "Passwort",
          confirm: "Passwort bestätigen",
          authorization: "Autor isierung",
          switchOn: "Öffnen",
          switchOff: "Schließen",
        },
        tableLabels: {
          name: "Name des Geschäfts",
          account: "Konto",
          authorization: "Autor isierung",
          updateUser: "Kürzlich aktualisiert",
          updateTime: "Letzte Aktualisierung",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Domain name der zweiten Ebene von Weifeng",
          secretKeyTip:
            "Mieter Schlüssel, erhalten Sie das Andocken des Hintergrunds ystems des Weifeng-Systems",
        },
      },
      user: {
        index: {
          name: "Mitarbeiter verwaltung",
          existingUsers: "Bereits Mitarbeiter",
          deleteSuccess: "Löschen erfolgreich",
          deleteFailed: "Löschen fehl geschlagen",
        },
        detail: {
          accountPrompt: "Bitte geben Sie die E-Mail-Adresse ein",
          resetPassword: "Passwort zurücksetzen",
          passwordPrompt: "Passwort (6-14 Buchstaben und Zahlen)",
          originPasswordPrompt: "Bitte geben Sie das anfängliche Passwort ein",
          resumePasswordPrompt: "Bitte geben Sie das Passwort erneut ein",
          passwordFormWrong:
            "Bitte geben Sie das Passwort für die Kombination von 6-14 Buchstaben und Zahlen ein!",
          resumePasswordWrong: "Zweimal inkonsistente Passwörter!",
          suppertImageType: "Unterstützung jpg, gif, png, maximal 500Kb",
          namePrompt: "Bitte geben Sie den Namen ein",
          nickNamePrompt: "Bitte geben Sie einen Spitznamen ein",
          telephonePrompt: "Bitte geben Sie die Telefon nummer ein",
          roleSelectedPrompt: "Bitte wählen Sie die Rolle",
          agentIdPrompt: "Bitte geben Sie die Arbeits nummer ein",
          disabled: "Deaktivieren",
          enabled: "Aktivieren",
          reloadBowerInfo:
            "Muss ich den Browser erneut aktualisieren, wenn sich die Benutzerin formationen ändern?",
          saveUserError:
            "Benutzerin formationen können nicht gespeichert werden. Bitte versuchen Sie es später erneut",
          resetPasswordSuccess: "Passwort erfolgreich zurück gesetzt",
          resetPasswordFailed:
            "Das Zurücksetzen des Passworts ist fehl geschlagen. Bitte versuchen Sie es später erneut",
        },
      },
      userGroups: {
        index: {
          name: "Management von Mitarbeiter gruppen",
          existingUserGroups: "Bestehende Mitarbeiter gruppe",
        },
        detail: {},
      },
      role: {
        index: {
          name: "Rollen management",
          existingRoles: "Bestehende Rollen",
        },
        edit: {
          editRole: "Rollen bearbeiten",
          createRole: "Erstellen von Rollen",
          basicInfo: "Grundlegende Informationen",
          taskPermissions: "Aufgaben berechtigungen",
          existingTasks: "Bestehende Aufgaben",
        },
      },
      anomalousData: {
        title: "Ungewöhnliche Daten",
        list: {
          selectHolder: "Bitte wählen Sie die Datenquelle",
          businessTime: "Geschäfts zeit",
          businessId: "Geschäft Id",
          export: "Exportieren",
        },
      },
      templateComponent: {
        list: {
          title: "Markt für Vorlagen",
          name: "Name der Vorlage",
          searchHolder: "Bitte geben Sie den abzufragen den Inhalt ein",
          update: "Update-Situation",
          sort: "Sortieren wie",
          moduleType: "Vorlagen typ",
          moduleSourceType: "Anwendbarer Typ",
          fetchNum: "Anzahl der Zugriffe",
          newest: "Kürzlich aktualisiert",
          usedMost: "Holen Sie sich die meisten",
          call: "Der Anruf",
          dialogue: "Dialog",
          waiting: "Zu überprüfen",
          cancel: "Veröffentlichung abbrechen",
          success: "Audit bestanden, ver öffentlicht",
          failed: "Überprüfung abgelehnt",
          no: "Nicht aufrüstbar",
          ok: "Kann aufgerüstet werden",
          all: "Alle",
          delete: "Löschen erfolgreich",
          downOk: "Erfolgreich aus den Regalen",
          withdraw: "Der Rückzug war erfolgreich",
          updateOk: "Erfolgreiches Upgrade",
          get: "Holen Sie sich",
          width: "Rückzug",
          getOk: "Erfolg erzielen",
          orgName: "Gehört zur Organisation",
          nowVersion: "Aktuelle Version",
          lastedVersion: "Neueste Version",
          appStatus: "Status der Anwendung",
          actions: "Betrieb",
          down: "Aus den Regalen",
          updateTop: "Upgrade",
          check: "Anzeigen",
          deleteWhen: "Löschen bestätigen?",
          yes: "Ist",
          noo: "Nein",
          deleteOk: "Löschen",
          permissible: "Erlaubt",
          noPermissible: "Nicht erlaubt",
          openUp: "Offen",
          openDown: "Nicht offen",
          task: "Qualitäts prüfungs aufgaben",
          point: "Qualitäts prüf punkt",
          tag: "Semantische Tags",
          muti: "Mehrere Elemente",
          inter: "Eine Frage und eine Antwort",
          info: "Informations entitäten",
          concept: "Begriff Wort",
          detail: "Modul details",
          infomation: "Grundlegende Informationen",
          detailConfig: "Details Konfiguration",
          twoEdit: "Sekundäre Bearbeitung",
          mustUpdate: "Jetzt upgraden",
          descition: "Beschreibung der Vorlage",
          descitionSize:
            "Die Beschreibung der Vorlage darf 250 Wörter nicht überschreiten",
          stencilsDetail: "Details der Vorlage",
          updatedRecords: "Rekord aktualisieren",
          version: "Versions nummer",
          updateDescription: "Beschreibung aktualisieren",
          myModule: "Meine Vorlage",
          addModule: "Neues Modul",
          publishVersion: "Release-Version",
          earliestVersion: "Die früheste Version",
          edit: "Bearbeiten",
          add: "Neu hinzugefügt",
          configNo: "Die Modul konfiguration details dürfen nicht leer sein",
          updateNo: "Update-Anweisungen können nicht leer sein",
          editSuccess: "Erfolgreich modifiziert",
          saveSuccess: "Speichern Sie erfolgreich",
          newer: "Aktualisieren",
          sumbit: "Einreichung",
          cancelName: "Stornierung",
          nameRequired: "Der Modul name ist ein Pflicht feld",
          nameSize: "Der Modul name darf 16 Wörter nicht überschreiten",
          publishStatus: "Status veröffentlichen",
          submitUpdate: "Aktualisie rungen einreichen",
          updateVersion: "Aktualisierte Version",
          updateExplain: "Update-Anweisungen",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon E-Commerce",
        youtubeVideo: "Youtube-Videos",
        amazon: {
          component: {
            queuing: "In der Schlange",
            notStarted: "Nicht gestartet",
            inProgress: "In Arbeit",
            success: "Erfolg",
            failed: "Scheitern",
            successfullyCreated: "Erfolgreich erstellen",
            editedSuccessfully: "Erfolg bei der Bearbeitung",
            operationSucceeded: "Erfolgreiche Operation",
            deletedSuccessfully: "Löschen erfolgreich",
            link: "Link",
            grabState: "Greif zustand",
            updateTime: "Aktualisierungszeit",
            grab: "Greifen",
            edit: "Bearbeiten",
          },
          template: {
            amazonEcommerce: "Amazon E-Commerce",
            thisProduct: "Dieses Produkt",
            competition: "Konkurrenz produkte",
            new: "Neu",
            edit: "Bearbeiten",
            link: "Link",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube-Videos",
            thisProduct: "Dieses Produkt",
            competition: "Konkurrenz produkte",
            new: "Neu",
            edit: "Bearbeiten",
            link: "Link",
          },
          component: {
            queuing: "In der Schlange",
            notStarted: "Nicht gestartet",
            inProgress: "In Arbeit",
            success: "Erfolg",
            failed: "Scheitern",
            successfullyCreated: "Erfolgreich erstellen",
            editedSuccessfully: "Erfolg bei der Bearbeitung",
            operationSucceeded: "Erfolgreiche Operation",
            deletedSuccessfully: "Löschen erfolgreich",
            youTubeVideoLink: "YouTube-Video-Link",
            grabState: "Greif zustand",
            updateTime: "Aktualisierungszeit",
            grab: "Greifen",
            edit: "Bearbeiten",
          },
        },
      },
    },
    demo: {
      title: "Intelligente Qualitäts prüfung",
      template: "Wählen Sie eine Vorlage für die Qualitäts prüfung",
      radio: "Wählen Sie das Aufnahme format",
      uploadAudio: "Aufnahme hochladen",
      uploadAudioTip:
        "Upload-Aufnahmen werden nur im WAV-und MP3-Format unterstützt und die Größe beträgt nicht mehr als 1G",
      uploadText: "Text hochladen",
      uploadTextTip:
        "Das Hochladen von Text unterstützt nur die Formate srt und txt und die Größe beträgt nicht mehr als 10M. Nach dem Hochladen von Text wird ASR nicht mehr zum Analysieren von Aufnahme dateien verwendet",
      uploadSuccess: "Die Aufnahme datei wurde erfolgreich hochgeladen",
      uploadFailed: "Upload der Aufnahme datei fehl geschlagen",
      startQualityInspection: "Beginnen Sie mit der Qualitäts prüfung",
      qualityInspectionStarted: "In der Qualitäts prüfung",
      downloadText: "Text herunter laden",
      checking: "Fortschritt der Qualitäts prüfung",
      checkingTip: "Erwarten Sie einige Minuten, bitte haben Sie Geduld",
      noCheckContent: "Noch keine Qualitäts prüfungs ergebnisse",
      noTextDownload: "Kein Qualitäts prüfungs text zum Herunter laden!",
      pleaseCheckParams:
        "Bitte überprüfen Sie, ob Sie die Vorlage, den Sprach typ und die Aufnahme datei hochladen",
      inspectionFailed:
        "Die Qualitäts prüfung ist fehl geschlagen. Bitte versuchen Sie es später erneut!",
    },
    wechatQa: {
      baseInfo: { title: "Grundlegende Informationen" },
      conversationSetting: {
        title: "Sitzungs einstellungen",
        segmentationStrategy: "Aufteilung strategie",
        rule: "Strategie",
        time: "Schnitt zeit",
        daily: "Jeden Tag",
        passScore: "Sitzungs qualifikation spunkte",
        ruleLogic: "Logik ausführen",
      },
      message: {
        title: "Push-Ereignisse",
        table: {
          index: "Seriennummer",
          name: "Name",
          type: "Push-Methode",
          desc: "Beschreibung",
          status: "Zustand",
        },
        subTitle: "Liste der Ereignisse",
      },
      checkPoint: {
        title: "Analytische Modelle",
        groups: "Modell gruppierung",
        newClassification: "Neue Gruppierung",
        editClassification: "Gruppierung bearbeiten",
        benchmarkScore: "Benchmark-Punkte",
        pointCount:
          "Das gemeinsame Analyse modell unter der aktuellen Klassifizierung",
        openPointCount: "Aktivieren Sie die Anzahl von",
        closedPointCount: "Die Anzahl der Deaktivierung ist",
        scoreLowerLimit: "Mindest punktzahl Untergrenze",
        scoreUpperLimit: "Maximale Punktzahl Obergrenze",
        classificationName: "Gruppieren von Namen",
        classificationNamePlaceHolder:
          "Bitte geben Sie den Namen der Gruppe ein",
        classificationRemark: "Gruppen beschreibung",
        classificationRemarkPlaceHolder:
          "Bitte geben Sie eine Gruppen beschreibung ein",
      },
      chartRecord: {
        title: "Enterprise WeChat-Sitzung",
        table: {
          id: "Sitzungs-ID",
          type: "Art der Sitzung",
          startTime: "Session-Start-Zeit",
          endTime: "Die letzte Endzeit der Sitzung",
          groupId: "Gruppen-ID",
          client: "Kunden",
          customer: "Kunden service",
          averageScore: "Durchschnitt liche Qualitäts prüfungs bewertung",
        },
        detail: {
          title: "Details zur Enterprise WeChat-Sitzung",
          inspectionInfo: "Analyse von Informationen",
          chartInfo: "Sitzungs informationen",
          tasks: "Analytische Aufgaben",
          avgScore: "Analysieren Sie die durchschnitt liche Punktzahl",
          qualified: "Qualifiziert",
          unqualified: "Nicht qualifiziert",
          taskTitle: "Anwendbare Analyse vorlagen",
          info: "Analyse von Informationen in Echtzeit",
          today: "Die heutigen Nachrichten",
          history: "Historische Nachrichten",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname:
                  "Bitte geben Sie eine Notiz/einen Spitznamen ein",
                pleaseEnterTheGroupChatName:
                  "Bitte geben Sie den Namen des Gruppen chats ein",
              },
            },
          },
          template: { whole: "Alle" },
        },
      },
      realTimeAnalysis: {
        title: "Echtzeit-Analyse",
        table: {
          name: "Name der Szene",
          count: "Anzahl der Analyse modelle",
          baseScore: "Benchmark-Punkte",
          passScore: "Qualifizierte Punkte",
          maxScore: "Maximale Punktzahl Obergrenze",
          minScore: "Mindest punktzahl Untergrenze",
          status: "Zustand",
          actions: "Betrieb",
        },
        detail: {
          title: "Analyse von Szenarien in Echtzeit",
          form: {
            name: "Name der Szene",
            prevCondition: "Szenario auslösende Bedingungen",
            prevConditionToolTip:
              "Wenn die Bedingung leer ist, wird diese Szene standard mäßig analysiert",
            prevConditionError:
              "Die Szenen auslöser bedingungen können nicht mit dem Analyse modell zusammenpassen",
            scoreInputPlaceholder: "Geben Sie die Punktzahl ein",
            baseScore: "Benchmark-Punkte",
            status: "Zustand",
            passScore: "Qualifizierte Punkte",
            maxScore: "Obergrenze der höchsten Punktzahl",
            minScore: "Mindest untergrenze",
            analysisModel: "Analytische Modelle",
            message: "Benachricht igungen auslösen",
            pushType: "Push-Methode",
            createAnalysisModel: "Modell hinzufügen",
            scoreUpperLimitError:
              "Die Untergrenze der Mindest punktzahl darf nicht größer als die Obergrenze der Höchst punktzahl sein",
            table: { name: "Modellname", rule: "Regeln", score: "Punktzahl" },
            pushConfigSwitchTip1: "Wenn die Szene erscheint",
            pushConfigSwitchTip2:
              "Push-Benachricht igungen für Zeiten und höher",
          },
        },
      },
      smartCustomerTag: {
        title: "Intelligente Kunden-Tags",
        table: {
          name: "Name der Regel",
          tags: "Enterprise Micro-Kunden etikett",
          status: "Status aktivieren",
          createUser: "Schöpfer",
          createTime: "Zeit erstellen",
        },
        detail: {
          name: "Name der Regel",
          condition: "Regeln festlegen",
          logic: "Logik",
          status: "Status aktivieren",
          tags: "Etiketten auswählen",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Anzahl der Kunden markierungen" },
        },
        messageFiltering: {
          template: {
            edit: "Bearbeiten",
            messageContent: "Inhalt der Nachricht",
            messageTypeID: "Nachrichten typ ID",
            messageTypeDescription: "Beschreibung des Nachrichten typs",
            messageFiltering: "Nachrichten filterung",
            suitableForFilteringDataWithoutQualityInspection:
              "Geeignet zum Filtern von Daten ohne Qualitäts prüfung",
            create: "Erstellen",
            messageDescription: "Beschreibung der Nachricht",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Gesprächs zeit",
          sessionType: "Art der Sitzung",
          whole: "Alle",
          staff: "Mitarbeiter",
          customer: "Kunden",
          callKeywords: "Anruf Schlüssel wörter",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Details zum Enterprise Micro Voice Call",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Sitzungs archiv",
        voiceCall: "Sprach anrufe",
        noMoreVoiceCalls: "Keine Sprach anrufe mehr",
        enterpriseWechatVoiceCall: "Enterprise WeChat Sprach anruf",
        enterpriseWechatConversationDetails:
          "Details zur Enterprise WeChat-Sitzung",
        previous: "Zurück",
        next: "Weiter",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Aufgaben zur Analyse von Unternehmens mikro konversationen",
          enterpriseAndMicroVoiceAnalysisTask:
            "Enterprise Micro-Sprach analyze aufgabe",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Analyse des Unternehmens-WeChat-Gruppen chats",
      },
      wechatQaSettingEntry: { messageFiltering: "Nachrichten filterung" },
    },
    businessAnalysis: {
      keyword: "Name des Berichts",
      createUser: "Schöpfer",
      createTime: "Zeit erstellen",
      updateTime: "Aktualisierungszeit",
    },
    semanticIntelligence: {
      applicationMessageType: "Anwendungs nachrichten typ",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Bitte verbessern Sie die Konfiguration",
              generatePromptLanguage: "Eingabe aufforderung generieren",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Ob es sich erfüllt",
              gradedEvaluation: "Datei bewertung",
            },
          },
          training: {
            index: {
              pleaseEnter: "Bitte geben Sie ein",
              pleaseGeneratePrompt:
                "Bitte generieren Sie eine Eingabe aufforderung",
              pleaseSelectADialogue: "Bitte wählen Sie Dialog",
              training: "Training",
              dataType: "Datentyp:",
              voiceCall: "Sprach anrufe",
              textDialogue: "Text gespräche",
              generate: "Generieren",
              qualityInspectionResults: "Ergebnisse der Qualitäts prüfung:",
              hit: "Schlag fähigkeiten:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Modellname",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Bitte geben Sie den Namen des Modells ein, z. B.: Gibt es eine Untätigkeit im Kundendienst?",
            modelDescription: "Modell beschreibung",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Der Inhalt, den Sie testen müssen, kann detailliert beschrieben werden, um das Verständnis des Modells zu erleichtern, z. B.: Gibt es eine Situation, in der der Kundendienst nichts getan hat, dh der Kundendienst hat eine Antwort auf Kunden fragen, aber die Kunden fragen werden nicht behandelt oder der Antwort inhalt hat nichts mit der Antwort des Kunden zu tun",
            validity: "Wirksamkeit",
            effective: "Wirksam",
            invalid: "Ungültig",
            type: "Typ",
            hintLanguage: "Eingabe aufforderung",
            editIntelligentModel: "Bearbeiten von intelligenten Modellen",
            createANewIntelligentModel: "Neues intelligentes Modell",
          },
        },
        index: {
          modelName: "Modellname",
          modelDescription: "Modell beschreibung",
          type: "Typ",
          whetherItMeetsTheRequirements: "Ob es sich erfüllt",
          gradedEvaluation: "Datei bewertung",
          validity: "Wirksamkeit",
          intelligentModel: "Intelligentes Modell",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Geeignet für subjektive Qualitäts prüfungen (z. B. ob die Einstellung zum Qualitäts prüfungs service enthusiast isch ist, ob der Service geduldig ist usw.)",
        },
      },
      clusterAnalysis: "Clustering-Analyse",
      speechMining: "Wörter ausgraben",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Unterstützt den Stapel import von Informations entitäten über die API-Schnitts telle, und die Bearbeitung wird nach dem Speichern des API-Namens nicht unterstützt",
        },
        component: { nothing: "Keine" },
      },
      knowledgeBase: {
        index: {
          classification: "Klassifizierung",
          import: "Importieren",
          importKnowledgeBase: "Wissens datenbank importieren",
          moveKnowledgePointsInBatch: "Bulk Mobile Wissens punkte",
          moveTo: "Verschieben Sie zu:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Bitte wählen Sie die Wissens punkte aus, die in großen Mengen verschoben werden müssen",
        },
        detail: {
          index: {
            intrasentence: "Innerhalb des Satzes",
            afterProblem: "Nach dem Problem",
          },
        },
      },
      semanticTags: { component: { nothing: "Keine" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Sensible Wörter",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Sensible Wörter: Sensible Wörter können mit Geschäfts aufmerksamkeit wörtern konfiguriert werden, die zur Entdeckung sensibler Wörter in der öffentlichen Meinung verwendet werden",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Neue sensible Wörter",
            editSensitiveWords: "Bearbeiten Sie sensible Wörter",
            sensitiveWords: "Sensible Wörter",
          },
          template: {
            sensitiveWords: "Sensible Wörter",
            sensitiveWordsCannotBeEmpty:
              "Sensible Wörter können nicht leer sein",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Bitte geben Sie sensible Wörter ein, die durch mehrere sensible Wörter durch ',' getrennt sind",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Bitte wählen Sie das Anwendungs szenario",
            keyWord: "Schlüssel wörter",
            pleaseEnterKeywords: "Bitte geben Sie Schlüssel wörter ein",
            keywordLengthWithinCharacters:
              "Keyword-Länge innerhalb von 40 Zeichen",
            dataSources: "Datenquellen",
            pleaseSelectADataSource: "Bitte wählen Sie die Datenquelle",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Das aktuelle Clustering ist abgeschlossen. Wenn Sie es erneut bearbeiten, werden neue Clustering-Daten generiert. Die abgeschlossenen Clustering-Daten werden nicht übers chrieben. Bitte bestätigen Sie",
            savingSucceeded: "Speichern Sie erfolgreich",
            clusteringStart: "Clustering beginnt",
            clustering: "Clustering",
            edit: "Bearbeiten",
            newlyBuild: "Neu",
            clusterName: "Clustering-Name",
            pleaseEnterAName: "Bitte geben Sie den Namen ein",
            withinCharactersOfTheName: "Name Länge innerhalb 40 Zeichen",
            speaker: "Sprecher",
            pleaseSelectASpeaker: "Bitte wählen Sie den Sprecher",
            sessionDate: "Sitzungs datum",
            pleaseEnterTheSessionDate: "Bitte geben Sie das Sitzungs datum ein",
            selectUpToMonthOfHistoricalSessionData:
              "Wählen Sie bis zu 1 Monat historische Sitzungs daten",
            automaticallyClusterDailyNewData:
              "Täglich neue Daten zum automatischen Clustering",
            conversationChannel: "Konversation kanäle",
            pleaseSelectAConversationChannel:
              "Bitte wählen Sie den Konversation kanal",
            applicationScenario: "Anwendungs szenarien",
            clusteringRules: "Regeln für das Clustering",
            incompleteClusteringRuleSettings:
              "Unvollständige Einstellung der Clustering-Regel",
            startClustering: "Starten Sie das Clustering",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Bitte wählen Sie eine Qualitäts prüfungs aufgabe",
              beEqualTo: "Gleich",
            },
          },
        },
        detail: {
          index: {
            theme: "Thema",
            numberOfCorpora: "Korpus zahl",
            corpusCoverage: "Korpus abdeckung",
            clusterDetails: "Details zum Clustering",
          },
          ranking: {
            hotWords: "Heiße Worte",
            numberOfCorpora: "Korpus zahl",
            speaker: "Sprecher",
            sit: "Sitzen",
            passenger: "Gast",
            hotSentence: "Heißer Satz",
            corpusDetails: "Korpus details",
          },
        },
        index: {
          delete: "Löschen",
          deletionSucceeded: "Löschen erfolgreich",
          clusterName: "Clustering-Name",
          speaker: "Sprecher",
          creationTime: "Zeit erstellen",
          state: "Zustand",
          operation: "Betrieb",
          see: "Anzeigen",
          edit: "Bearbeiten",
          clusterAnalysis: "Clustering-Analyse",
          newCluster: "Neues Clustering",
          clusteringStatus: "Clustering-Status",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Eingabe von Schlüssel wörtern Suche Einspruch",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Sprach bibliothek",
                recommendedScripts: "Empfohlene Wörter",
                common: "Insgesamt",
                strip: "Artikel",
                scriptFavorites: "Sprach favoriten",
              },
              conditionPostfix: {
                detectRoles: "Erkennung von Rollen",
                detectionRange: "Erfassungs bereich",
                sentence: "Satz",
              },
              customerFilter: {
                scriptFiltering: "Sprach filter",
                cancel: "Stornierung",
              },
              index: {
                viewDetails: "Details anzeigen",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Bestätigen Sie, dass Sie aufgeben möchten? Kann nach dem Verlassen nicht mehr wieder hergestellt werden",
                abandonment: "Gib auf",
                deletionSucceeded: "Löschen erfolgreich",
                passenger: "Gast",
                sit: "Sitzen",
                adopted: "Ver abschiedet",
                determine: "Bestimmt",
                cancel: "Stornierung",
                adopt: "Adoptiert",
              },
              pageRightFrom: {
                savingSucceeded: "Speichern Sie erfolgreich",
                pleaseEnterTheScriptContent:
                  "Bitte geben Sie den Inhalt der Wörter ein",
                pleaseSelectAFavoriteGroup:
                  "Bitte wählen Sie die Favoriten gruppe",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Dissidenten verarbeitung sbank",
              },
              pageRightTable: {
                index: { pleaseChooseToAdoptScript: "Bitte wählen Sie Wörter" },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Einspruchs behandlung Favoriten",
            },
            pageRightTable: {
              likeCancel: "Wie abbrechen",
              likeSuccessfully: "Wie Erfolg",
              adoptScripts: "Adoption",
              operation: "Betrieb",
              viewConversationDetails: "Details zum Gespräch anzeigen",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Produkt verkaufs argument Sprach bibliothek",
              },
              pageRightTable: { keyPointsOfScript: "Wichtige Punkte" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Einspruchs behandlung Favoriten",
              newGroup: "Neue Gruppierung",
              editGroup: "Gruppierung bearbeiten",
              addGroup: "Hinzufügen von Gruppierungen",
              savingSucceeded: "Speichern Sie erfolgreich",
              groupName: "Gruppieren von Namen",
              pleaseEnterTheGroupName:
                "Bitte geben Sie den Namen der Gruppe ein",
              groupNameWithinCharacters:
                "Gruppen name Länge innerhalb von 20 Zeichen",
              deletionSucceeded: "Löschen erfolgreich",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings:
                    "Sprach empfehlung seins tel lungen",
                },
                rightForm: {
                  savingSucceeded: "Speichern Sie erfolgreich",
                  intelligentRecommendation: "Intelligente Empfehlung",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Die aus gezeichneten Wörter, die angenommen wurden, werden automatisch in der Seiten leiste des Gesprächs mit ähnlichen Einwänden angezeigt. Mitarbeiter können die empfohlenen Wörter direkt auf der Seite mit den Details zur Überprüfung des Gesprächs anzeigen, um zu lernen",
                  objectionsIncludedByDefault:
                    "Einspruch standard mäßig enthalten",
                  addRecommendationTriggerSemantics:
                    "Erhöhen Sie die empfohlene Trigger-Semantik",
                  remindEmployeesToView:
                    "Erinnern Sie die Mitarbeiter an die Anzeige",
                  shareWithMoreEmployees: "Mit mehr Mitarbeitern teilen",
                  allHands: "Alle Mitarbeiter",
                },
                smartToolbar: {
                  selectSemanticTag: "Wählen Sie semantische Tags",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Exportieren Sie alle Datensätze unter der aktuellen Gruppierung?",
                newScript: "Neue Wörter",
                editingScript: "Wörter bearbeiten",
                savingSucceeded: "Speichern Sie erfolgreich",
                objection: "Einspruch",
                pleaseEnterAScriptObjection: "Bitte geben Sie die Wörter ein",
                speechTechnique: "Wörter",
                pleaseEnterTheScriptContent:
                  "Bitte geben Sie den Inhalt der Wörter ein",
                grouping: "Gruppierung",
                pleaseSelectAFavoriteGroup:
                  "Bitte wählen Sie die Favoriten gruppe",
                adoptScripts: "Adoption",
                passenger: "Gast",
                sit: "Sitzen",
                numberOfViews: "Anzahl der Aufrufe",
                useful: "Nützlich",
                operation: "Betrieb",
                edit: "Bearbeiten",
                delete: "Löschen",
                deletionSucceeded: "Löschen erfolgreich",
                scriptRecommendationSettings:
                  "Sprach empfehlung seins tel lungen",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Sprach bibliothek",
          scriptMining: "Wörter ausgraben",
          objectionHandlingScripts: "Dissens behandlung",
          productSellingPointScripts: "Produkt verkaufs argument",
        },
        sellingPoint: {
          index: { newCluster: "Neues Clustering" },
          components: {
            callDetailModal: {
              sessionDetails: "Gesprächs details",
              cancel: "Stornierung",
              determine: "Bestimmt",
              choice: "Wählen Sie",
            },
            pageCenterTable: { scriptOverview: "Sprach übersicht" },
            pageLeftTable: {
              enterSearchKeywords: "Such schlüssel wörter eingeben",
              keyWord: "Schlüssel wörter",
            },
            pageRightTable: {
              index: {
                viewDetails: "Details anzeigen",
                scriptLibrary: "Sprach bibliothek",
                scriptRefinement: "Sprach verfeinerung",
                adoptScript: "Adoption",
              },
              tagsEdit: { keyPointsOfScript: "Wichtige Punkte" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Produkt verkaufs argument Sprach bibliothek",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Standard mäßig enthaltene Verkaufs argumente",
                  },
                },
                index: {
                  keyWord: "Schlüssel wörter",
                  pleaseSelectAKeyword: "Bitte wählen Sie Schlüssel wörter",
                  keyPointsOfScript: "Wichtige Punkte",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Bitte geben Sie die wichtigsten Punkte ein",
                  viewDetails: "Details anzeigen",
                  pleaseChooseToAdoptScript: "Bitte wählen Sie Wörter",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Übereinstimmende Logik",
            nLPMatching: "NLP-Übereinstimmungen",
            strictlyMatchByWord: "Strikte Übereinstimmung nach Wort",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Name des Nachrichten typs",
          messageTypeID: "Nachrichten typ ID",
          messageTypeDescription: "Beschreibung des Nachrichten typs",
          applicationMessageType: "Anwendungs nachrichten typ",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Es eignet sich für den Zugriff auf verschiedene Kanal daten zur Qualitäts prüfung und muss gemäß der Kanal identifikation klass ifi ziert werden",
        },
        detail: {
          index: {
            messageTypeName: "Name des Nachrichten typs",
            pleaseEnterAMessageTypeName:
              "Bitte geben Sie den Namen des Nachrichten typs ein",
            messageTypeDescription: "Beschreibung des Nachrichten typs",
            describeYourMessageTypeInDetail:
              "Beschreiben Sie Ihren Nachrichten typ im Detail",
            editMessageType: "Nachrichten typen bearbeiten",
            newMessageType: "Neuer Nachrichten typ",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Bitte wählen Sie die Teammitglieder aus",
        existingFilter: "Bereits Filter",
        pleaseSelect: "Bitte wählen Sie",
        filterCondition: "Filter bedingungen",
        areYouSureToDeleteTheCurrentFilter:
          "Wird das Löschen des aktuellen Filters bestätigt?",
        sessionDate: "Sitzungs datum",
        staff: "Mitarbeiter",
        customer: "Kunden",
        pleaseEnterCustomerName: "Bitte geben Sie den Namen des Kunden ein",
        keyWord: "Schlüssel wörter",
        whole: "Alle",
        pleaseEnterSessionKeywords:
          "Bitte geben Sie Konversation schlüssel wörter ein",
        sessionDuration: "Dauer der Sitzung",
        sessionLabel: "Gesprächs szene",
        pleaseSelectASessionLabel: "Bitte wählen Sie die Sitzungs szene",
        commentTopic: "Kommentar zum Thema",
        pleaseSelectACommentTopic: "Bitte wählen Sie ein Kommentar thema",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Wird bestätigt, dass der aktuelle Filter übers chrieben wird?",
        overlayFilter: "Filter abdecken",
        NewFilter: "Neuer Filter",
        newFilter: "Neuer Filter",
        filterName: "Name des Filters",
        pleaseEnter: "Bitte geben Sie ein",
        allCall: "Alle Anrufe",
        percentage: "Prozent",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'Unterstützt die Suche nach mehreren Schlüssel wörtern. Die "oder"-Beziehung wird durch Kommas (Chinesisch und Englisch) getrennt, und die "und"-Beziehung wird durch ein Signal getrennt, z. B.:',
          callRecordID: "ID des Anruf protokolls",
          pleaseFillInTheCallRecordID:
            "Bitte füllen Sie die Anruf protokoll-ID aus",
          minute: "Minuten",
          second: "Sekunden",
          whole: "Alle",
          callRecording: "Anruf aufzeichnung",
          enterpriseWechat: "Enterprise WeChat",
          conversationChannel: "Konversation kanäle",
          pleaseSelectASessionChannel:
            "Bitte wählen Sie den Konversation kanal",
          learningStatus: "Lern status",
          pleaseSelectLearningStatus: "Bitte wählen Sie den Lern status",
          notLearned: "Nicht gelernt",
          learned: "Hat gelernt",
          Minutes: "Minuten",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Der Filter ist leer und neue Filter werden nicht unterstützt!",
          filterAddedSuccessfully: "Der neue Filter ist erfolgreich!",
          failedToAddFilter: "Filter neu fehl geschlagen!",
          filterDeletedSuccessfully: "Filter gelöscht erfolgreich!",
          filterDeletionFailed: "Filter löschen fehl geschlagen!",
          filterOverwriteSucceeded: "Filter abdeckung erfolgreich!",
          filterOverwriteFailed: "Filter abdeckung fehl geschlagen!",
          theme: "Thema",
          pleaseSelectATheme: "Bitte wählen Sie ein Thema",
          keyEvents: "Schlüssel ereignisse",
          pleaseSelectAKeyEvent: "Bitte wählen Sie das Schlüssel ereignis",
        },
        filterItem: {
          startRetesting: "Starten Sie die erneute Erkennung",
          time: "Zeit",
          talkTime: "Dauer des Anrufs",
          keyEvents: "Schlüssel ereignisse",
          totalImplementationRateOfSupervisionItems:
            "Überwachung der Gesamt durchführung srate",
          sort: "Sortieren:",
          seeMore: "Sehen Sie mehr",
        },
        filterStatistics: {
          conversation: "Der Anruf",
          weChat: "WeChat",
          quantity: "Menge",
        },
      },
      caseBase: {
        index: {
          copyTo: "Kopieren nach",
          pleaseSelectTheLocationToMove:
            "Bitte wählen Sie den Ort, um sich zu bewegen!",
          folderCopiedSuccessfully: "Ordner erfolgreich kopiert!",
          cancelCollectionSuccessfully: "Abbrechen der Sammlung erfolgreich!",
          collectionSucceeded: "Sammlung erfolgreich!",
          starCaseBase: "Star-Standard-Fall bibliothek",
          move: "Mobile",
          rename: "Umbenennen",
          delete: "Löschen",
          folderMovedSuccessfully: "Ordner verschoben erfolgreich!",
          fileMovedSuccessfully: "Die Datei wurde erfolgreich verschoben!",
          fileMoveFailed: "Datei bewegung fehl geschlagen!",
          folderAddedSuccessfully: "Ordner erfolgreich hinzufügen!",
          folderRenameSucceeded: "Ordner umbenennen erfolgreich!",
          fileRenameSucceeded: "Die Datei wurde erfolgreich umbenannt!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Der Ordner kann nach dem Löschen nicht mehr abgerufen werden. Bestätigen Sie das Löschen?",
          deleteSucceeded: "Löschen erfolgreich!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Die Dateien können nach dem Löschen nicht mehr abgerufen werden. Bestätigen Sie das Löschen?",
          share: "Insgesamt",
          folders: "Ordner,",
          calls: "Ein Anruf",
          newFolder: "Ordner hinzufügen",
          modifyFolder: "Ordner ändern",
          title: "Titel",
          pleaseEnter: "Bitte geben Sie ein",
          selectMoveLocation: "Wählen Sie die Bewegungs position",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Lern erinnerungen",
          subscriptionDynamics: "Abonnieren Sie die Dynamik",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID:
              "Die Sitzung, die Sie abonniert haben (ID",
            thereAreDynamicUpdates: "Es gibt dynamische Updates",
            newCommentsReplies: "Neue Kommentare/Antworten",
            strip: "Artikel",
            newHelp: "Neue Hilfe",
            goAndWatch: "Geh und schau",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Geantwortet auf meinen Kommentar",
            replySucceeded: "Antwort erfolgreich!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "In den Kommentaren @ mich",
            replySucceeded: "Antwort erfolgreich!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Teilen Sie den Anruf für Sie",
            shareCasesForYou: "Fälle für Sie teilen",
          },
        },
        study: {
          index: {
            learned: "Hat gelernt",
            ignored: "Ignoriert",
            study: "Lernen",
            ignore: "Ignorieren",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Erfolgreich importiert",
          account: "Konto",
          password: "Passwort",
          cover: "Abdeckung",
          skip: "Überspringen",
          import: "Importieren",
          importEmployees: "Mitarbeiter importieren",
          importPrompt: "Tipps importieren",
          confirm: "Bestimmt",
          theDataContainsDuplicateAccountsOrNames:
            "Die Daten haben ein Konto oder einen doppelten Namen",
          allCoverage: "Alle abdecken",
          skipAll: "Alle überspringen",
          headPortrait: "Avatar",
          fullName: "Name",
          nickname: "Spitzname",
          excellentStaff: "Aus gezeichnetes Personal",
          yes: "Ist",
          no: "Nein",
          department: "Abteilung",
          accountNumberemail: "Konto (E-Mail)",
          telephone: "Telefon",
          jobNumber: "Arbeits nummer",
          role: "Rolle",
          pleaseEnterANameOrPhoneSearch:
            "Bitte geben Sie die E-Mail-Adresse, den Namen oder die Telefons uche ein",
          person: "Menschen)",
          AddEmployee: "Mitarbeiter hinzufügen",
        },
        edit: {
          jobNo: "Arbeits nummer",
          udeskCustomerServiceSystemIdentity:
            "Identität des Udesk-Kundendienst systems",
          enterpriseWechatIdentity: "Unternehmens-WeChat-Identität",
          inputID: "Geben Sie die Identität ein",
          newIdentityBinding: "Identitäts bindung hinzufügen",
          identityBinding: "Identität gebunden",
          avatarGreaterThan500kb: "Avatar größer als 500Kb",
          uploadSuccessful: "Erfolgreich hochgeladen",
          upload: "Hochladen",
          supportJPGGIFAndPNGUpTo500kb:
            "Unterstützung jpg, gif, png, maximal 500Kb",
          yes: "Ist",
          no: "Nein",
          accountNumber: "Konto",
          pleaseEnterTheAccountNumber: "Bitte geben Sie das Konto ein",
          initialPassword: "Anfangs passwort",
          pleaseInputAPassword: "Bitte geben Sie das Passwort ein",
          confirmPassword: "Passwort bestätigen",
          thePasswordsEnteredTwiceAreDifferent:
            "Die Passwörter, die zweimal eingegeben wurden, sind unterschied lich!",
          headPortrait: "Avatar",
          fullName: "Name",
          pleaseEnterYourName: "Bitte geben Sie den Namen ein",
          nickname: "Spitzname",
          department: "Abteilung",
          excellentStaff: "Aus gezeichnetes Personal",
          sessionIdentity: "Sitzungs identität",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Markieren Sie den Sitzungs datensatz, der dem Mitarbeiter gehört",
          telephone: "Telefon",
          jobNumber: "Arbeits nummer",
          role: "Rolle",
          pleaseSelectARole: "Bitte wählen Sie die Rolle",
          preservation: "Speichern",
          cancel: "Stornierung",
          addedSuccessfully: "Erfolg hinzufügen!",
          modificationSucceeded: "Die Änderung war erfolgreich!",
          newEmployees: "Neue Mitarbeiter",
          editEmployee: "Mitarbeiter bearbeiten",
        },
        detail: {
          viewDetails: "Details anzeigen",
          accountNumber: "Konto",
          headPortrait: "Avatar",
          fullName: "Name",
          nickname: "Spitzname",
          department: "Abteilung",
          excellentStaff: "Aus gezeichnetes Personal",
          yes: "Ist",
          no: "Nein",
          sessionIdentity: "Sitzungs identität",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Markieren Sie den Sitzungs datensatz, der dem Mitarbeiter gehört",
          telephone: "Telefon",
          jobNumber: "Arbeits nummer",
          role: "Rolle",
        },
      },
      roleManage: {
        list: {
          menu: "Menü",
          functionalCategory: "Funktions kategorien",
          functionalSubclass: "Funktions kategorie",
          deletedSuccessfully: "Löschen erfolgreich",
          NewRole: "Neue Rolle",
          role: "Rolle",
          dataPermissions: "Daten berechtigungen:",
          edit: "Bearbeiten",
          areYouSureYouWantToDeleteTheRole: "Löschen von Rollen bestimmen",
          determine: "Bestimmt",
          cancel: "Stornierung",
          delete: "Löschen",
          applicationPermission: "App-Berechtigungen",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Sehen Sie nur Ihre eigenen Sitzungs daten",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Sie können die Sitzungs daten dieser Abteilung und ihrer unter geordneten Abteilungen anzeigen",
          menu: "Menü",
          functionalCategory: "Funktions kategorien",
          functionalSubclass: "Funktions kategorie",
          successfullyAdded: "Neuer Erfolg",
          modifiedSuccessfully: "Erfolgreich modifiziert",
          edit: "Bearbeiten",
          cancel: "Stornierung",
          confirm: "Bestätigung",
          roleName: "Name der Rolle",
          pleaseEnterTheRoleName: "Bitte geben Sie den Namen der Rolle ein",
          dataAuthority: "Daten berechtigungen",
          youCanViewCompanyWideSessionData:
            "Kann unternehmens weite Sitzungs daten anzeigen",
          applicationPermission: "App-Berechtigungen",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Sitzungs prozess",
          generateProcess: "Prozess generieren",
          addProcess: "Prozess hinzufügen",
          cancel: "Stornierung",
          complete: "Fertig",
          edit: "Bearbeiten",
          theSettingWillBeSuccessfulLater:
            "Die Einstellung ist später erfolgreich!",
          previous: "Zurück",
          next: "Weiter",
          debuggingMode: "Debugging-Modus",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Wenn die Nachricht nach dem Öffnen mit Schlüssel ereignissen, Überwachungs einträgen, Kunden-Tag-Regeln oder Wissens punkt regeln überein stimmt, werden der Name der Regel, mit der die Nachricht überein stimmt, und der Name des Wissens punkts in der Wissens datenbank angezeigt.",
          seeLater: "Später sehen",
          determine: "Bestimmt",
          conversationalInsights: "Einsicht in die Sitzung",
          customerInsight: "Kunden-Einblicke",
          knowledgePointRecommendation: "Wissens punkte mpfehlung",
          comment: "Kommentar",
          sessionQuality: "Sitzungs qualität",
          setToListenLaterSuccessfully:
            "Die Einstellung ist später erfolgreich!",
          listenLater: "Später zuhören",
          seekCounselingSuccess: "Suchen Sie eine Beratung für den Erfolg!",
          altogether: "Insgesamt",
          appearsAt: "Erscheint irgendwo",
          commentsPublishedSuccessfully: "Kommentar erfolgreich",
          callRecording: "Anruf aufzeichnung",
          addedSuccessfully: "Erfolg hinzufügen",
          shareWithColleagues: "Mit Kollegen teilen",
          shareWithCustomers: "Mit Kunden teilen",
          resumptionDetails: "Details zur Überprüfung",
          addToCaseBase: "Zu Fall bibliothek hinzufügen",
          shareCases: "Fälle teilen",
          seekCounseling: "Suchen Sie eine Beratung",
          sessionRecord: "Sitzungs protokoll",
          businessRecord: "Geschäfts aufzeichnung",
          keywordSearch: "Stichworts uche",
          whole: "Alle",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Bitte geben Sie einen Kommentar ein, geben Sie @ ein, um andere zu benachrichtigen, und geben Sie # ein, um ein Thema hinzuzufügen",
          subscriptionSession: "Abonnieren Sie die Sitzung",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Nach dem Abonnieren erhalten Sie Benachricht igungen und Erinnerungen, wenn neue Anfragen, Kommentare oder Antworten vorliegen",
        },
      },
      coachTask: {
        index: {
          employeeName: "Name des Mitarbeiters",
          customerName: "Name des Kunden",
          source: "Quelle",
          viewSessionRecords: "Sitzungs protokoll anzeigen",
          coachingStatus: "Nachhilfe status",
          helpTime: "Zeit für Hilfe",
          counselingTime: "Nachhilfe zeit",
          helpContent: "Hilfe inhalt",
          coachingOperation: "Coaching-Operationen",
          areYouSureYouWantToIgnoreIt: "Sicher ignorieren?",
          determine: "Bestimmt",
          cancel: "Stornierung",
          ignoreHelp: "Hilferufe ignorieren",
          coaching: "Führen Sie eine Beratung durch",
          viewCoaching: "Beratung anzeigen",
          areYouSureToReturnToCounseling:
            "Sind Sie sicher, bis zur Beratung zurück zukehren?",
          returnToPendingCoaching: "Bis zur Beratung wieder hergestellt",
          tutor: "Berater",
          helpSeekingStatus: "Hilfe Status",
          recentCoachingTime: "Aktuelle Nachhilfe zeit",
          operation: "Betrieb",
          ignoreSuccess: "Erfolg ignorieren!",
          recoverySucceeded: "Wiederherstellen erfolgreich!",
          successfulCoaching: "Coaching erfolgreich!",
          myMission: "Meine Aufgabe",
          selectCoachingStatus: "Wählen Sie den Coaching-Status",
          whole: "Alle",
          iAskedForHelp: "Ich bat um Hilfe",
          signOut: "Rückzug",
          submit: "Einreichung",
          helpPerson: "Hilfe suchen",
          customer: "Kunden",
          toBeCoached: "Beratung",
          counselingSuggestions: "Beratung",
          pleaseEnterCoachingSuggestions:
            "Bitte geben Sie Beratungs vorschläge ein",
        },
      },
      coachTaskCenter: {
        title: "Aufgaben zentrum",
        list: {
          taskName: "Name der Aufgabe",
          lessonGroup: "Kurs klassifizierung",
          lessonName: "Kurs",
          taskType: "Arten von Aufgaben",
          taskStatus: "Aufgaben status",
          taskExecutionInfo: "Ausführung",
          totalCount: "Anzahl der zu implemen tieren den Personen",
          executeCount: "Anzahl der Personen, die ausgeführt wurden",
          executeRate: "Ausführungs rate",
          completeCount: "Anzahl der abgeschlossenen Personen",
          completeRate: "Abschluss rate",
          commitCount: "Anzahl der Personen, die den Standard erfüllen",
          commitRate: "Compliance-Rate",
          excellentRate: "Aus gezeichnete Rate",
          taskTime: "Aufgaben zeit",
          option: "Betrieb",
          edit: "Bearbeiten",
          taskDetail: "Aufgaben daten",
          delete: "Löschen",
          new: "Neu freigegeben",
          taskTypeAll: "Alle",
          taskTypePractice: "Übungen",
          taskTypeTest: "Prüfung",
          taskStatusNotStarted: "Nicht gestartet",
          taskStatusRunning: "In Arbeit",
          taskStatusEnd: "Es ist vorbei",
          taskStatusInvalid: "Nicht in Kraft getreten",
          unlimitedTime: "Keine zeitliche Begrenzung",
          to: "Zu",
          deleteConfirm:
            "Nach dem Löschen werden alle Lern datensätze unter dieser Aufgabe gelöscht. Wird das Löschen bestätigt?",
          deleteSuccess: "Erfolgreiches Löschen von Aufgaben",
        },
        edit: {
          addTask: "Neue Release-Aufgabe",
          editTask: "Bearbeiten von Veröffentlichung aufgaben",
          publishSuccess: "Erfolg bei der Veröffentlichung",
          publishFailed: "Veröffentlichung fehl geschlagen",
          updateSuccess: "Erfolgreiches Update",
          updateFailed: "Update fehl geschlagen",
          cannotBeNull: "Kann nicht leer sein",
          explain: "Beschreibung",
          taskDescExtra: "(Der Inhalt hier wird am Eingang von {0} angezeigt)",
          taskTargetObj: "Objekte veröffentlichen",
          isEnable: "Status wirksam",
          dialogueMode: "Dialog modus",
          voice: "Stimme",
          text: "Text",
          userPortrait: "Benutzer porträt",
          difficultyLevel: "Schwierigkeit sgrad",
          limitDuration: "Begrenzung der Dauer",
          tipLabel: "Tipps",
          tipTooltip:
            "Erlauben Sie den Schülern, die Referenz fähigkeiten zu überprüfen?",
          limitTimes: "Anzahl der Prüfungen",
          time: "Zeit",
          gradingStandart: "Bewertungs kriterien",
          gradingStandartDescAffix:
            "(Die Gesamt punktzahl des aktuellen Dialog prozesses ist",
          gradingStandartDescSuffix:
            "Punkte, Wörter {0} Punkte, Emotionen {1} Punkte, Sprech geschwindigkeit {2} Punkte)",
          completeStandart: "Anforderungen an die Aufgaben erfüllung",
          byTimes: "Nach Anzahl der",
          byScore: "Nach Punktzahl",
          completeByTimes: "Anzahl der Übungen",
          times: "Zeiten",
          score: "Punkte",
          anyTimes: "Jedes Mal",
          getScore: "Punktzahl",
          offLimitDuration: "Unbegrenzte Zeit lang",
          onLimitDuration: "Langes Limit",
          commitScore: "Standards erfüllen, punkten",
          excellentScore: "Aus gezeichnete Kriterien, Punktzahl",
          offLimitTime: "Keine zeitliche Begrenzung",
          onLimitTime: "Begrenzte Zeit",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Hallo," },
          callDynamic: {
            today: "Heute",
            yesterday: "Gestern",
            sessions: "Sitzungen",
          },
          todoList: {
            strip: "Artikel",
            theSessionIsAddedTolistenLater:
              'Die Sitzung wird zu "Später hören" hinzugefügt',
            askYouForHelp: "Bitten Sie um Hilfe",
            iHopeToContactYouLater:
              "Ich freue mich darauf, mich später zu kontaktieren",
            lookingForwardToSendingTheInformationLater:
              "Erwarten Sie, die Informationen später zu senden",
            dialogueRisk: "Es gibt Risiko punkte in der Sitzung",
            tutored: "Coaching",
            goToCounseling: "Geh zum Coaching",
            contacted: "Kontakt aufgenommen",
            hasBeenSent: "Gesendet",
            areYouSureYouWantToIgnoreIt: "Sicher ignorieren?",
            determine: "Bestimmt",
            cancel: "Stornierung",
            ignore: "Ignorieren",
            checked: "Gesehen",
          },
          callListItem: {
            strip: "Artikel",
            learned: "Hat gelernt",
            keyEvents: "Schlüssel ereignisse",
            totalImplementationRateOfSupervisionItems:
              "Überwachung der Gesamt durchführung srate",
          },
        },
        index: {
          operationSucceeded: "Die Operation ist erfolgreich!",
          sessionDynamics: "Gesprächs dynamik",
          my: "Mein",
          department: "Abteilung",
          company: "Unternehmen",
          toDoList: "Die Aufgaben sind erledigt",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Effektives Anruf volumen (a)",
          bestPractices: "Best Practices",
          averageDurationOfASingleCallminutes:
            "Durchschnitt liche Dauer eines einzelnen Anrufs (Minuten)",
          totalCallVolumePCs: "Gesamt anrufe (a)",
          totalTalkTimehours: "Gesamt anruf dauer (Stunden)",
          speakingPercentagepercentage: "Sprach anteil (Prozent)",
          maximumContinuousSpeakingTimeminutes:
            "Die längste Dauer des Sprechens (Minuten)",
          numberOfIndepthDiscussionsPCs: "Eingehende Diskussion zeiten (a)",
          maximumCustomerTalkminutes: "Die längste Kunden rede (Minuten)",
          questionFrequencyPCs: "Häufigkeit der Fragen (a)",
          averageValue: "Durchschnitt",
          selectDate: "Wählen Sie ein Datum",
          personnel: "Personal",
          pleaseSelect: "Bitte wählen Sie",
          callRange: "Reichweite des Anrufs",
          conversationActivity: "Sitzungs aktivitäten",
          conversationAction: "Sitzungs aktion",
          keyEvents: "Schlüssel ereignisse",
          callRecord: "Anruf protokoll",
          filter: "Screening:",
          conversation: "Der Anruf",
          minute: "Minuten",
          sale: "Mitarbeiter",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Januar",
            numberOfCalls: "Anzahl der Anrufe",
            accountInformation: "Konto informationen",
            month: "Monat",
            today: "Heute",
            customerDetails: "Kunden details",
            searchKeywordsInCommunicationContent:
              "Suche nach Schlüssel wörtern in Kommunikation inhalten",
            allMembers: "Alle Mitglieder",
            reset: "Zurücksetzen",
            query: "Abfrage",
            allTrip: "Volle Reise anzeige",
            contactOverview: "Kontakt übersicht",
            lastContact: "Letzter Kontakt",
            nextContact: "Nächster Kontakt",
            previous: "Zurück",
            next: "Weiter",
            viewDetails: "Details anzeigen",
            contactDynamics: "Kontakt nachrichten",
            lastYear: "Das letzte Jahr",
            lastCall: "Der letzte Anruf",
            hotTopicsForDiscussion: "Diskussion themen",
            totalInTheLastYear: "Insgesamt im letzten Jahr",
            callTimes: "Anruf",
          },
        },
        list: {
          index: {
            risk: "Risiken",
            have: "Ja",
            nothing: "Keine",
            channel: "Kanäle",
            callRecording: "Anruf aufzeichnung",
            enterpriseWeChatConversation: "Enterprise Micro Session",
            enterpriseWeChatCall: "Unternehmens ansprache",
            followUpSuggestions: "Follow-up-Empfehlungen",
            customer: "Kunden center",
            followUp: "Follow-up-Situation",
            personInCharge: "Ver antwort licher",
            recentFollowup: "Kürzlich nachverfolgt",
            lastContactTime: "Letzte Kontakt zeit",
            operation: "Betrieb",
            see: "Anzeigen",
            exceed: "Mehr als",
            noActivityForMoreThanDays: "Tage ohne Aktivität",
            lately: "Vor kurzem",
            risksOccurredInTheLastDays: "Risikos emantik innerhalb von Tagen",
            savedSuccessfully: "Speichern Sie erfolgreich",
            today: "Heute",
            allDepartments: "Alle Abteilungen",
            allMembers: "Alle Mitglieder",
            customerName: "Name des Kunden",
            reset: "Zurücksetzen",
            query: "Abfrage",
            customers: "Kunden",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Zeigen Sie den Kontakt zwischen Mitarbeitern und Kunden an, wobei die rosa Blase die Kunden nachricht und die blaue die Mitarbeiter nachricht ist. Je größer der Anteil der Rede, desto größer die Blase",
            riskAllocation: "Risiko-Allokation",
            preservation: "Speichern",
            pleaseEnter: "Bitte geben Sie ein",
            noActivityForDays: "Tage ohne Aktivität",
            riskSemanticsWithinDays: "Risikos emantik innerhalb von Tagen",
            addRiskSemantics: "Risiko-Semantik hinzufügen",
            semanticTags: "Semantische Tags:",
            standardSentence: "Standard-Satz:",
          },
          components: {
            advancedFilter: {
              components: {
                list: {
                  index: { addNewConditions: "Neue Bedingungen hinzufügen" },
                },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Erfüllen Sie alle folgenden Bedingungen",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Screening",
                pleaseSelectFilteringCriteria:
                  "Bitte wählen Sie die Filter kriterien",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage:
                "Ändern Sie die Phase der Geschäfts möglichkeiten",
              theCurrentStageIs: "Die aktuelle Phase ist [",
              ConfirmToChangeItTo:
                "】, Bestätigen Sie, dass es in [geändert wird",
              IsIt: "】 Ist es?",
              StageCoStay: "】 Stage bleiben",
              day: "Tag",
              start: "Der Anfang",
              end: "Ende",
              orderInput: "Verlust rechnung",
              restart: "Neustart",
              pleaseConfigureTheSalesStageInformation:
                "Bitte konfigurieren Sie die Verkaufs phasen informationen",
              reasonForDocumentInput: "Gründe für den Verlust:",
              completeOrderInput: "Vervollständigen Sie den Verlust",
              pleaseSelectTheReason: "Bitte wählen Sie den Grund",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "Der Manager empfiehlt:",
              },
              index: {
                sessionDetails: "Gesprächs details",
                customerDynamics: "Kunden dynamik",
                riskPoint: "Risiko punkte",
                followUpSuggestions: "Follow-up-Empfehlungen",
              },
              sessionDetails: {
                common: "Insgesamt",
                currentlyViewingThe: "Aktuelle Ansicht",
                sessions: "Sitzungen",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Die aktuelle Phase ist geblieben",
              day: "Tag",
            },
          },
          business: {
            index: {
              reEnableOpportunity: "Geschäfts möglichkeiten wieder aktivieren",
            },
          },
          customerTag: { index: { label: "Tags" } },
          moreCustomer: {
            index: {
              essentialInformation: "Grundlegende Informationen",
              cancel: "Stornierung",
              confirm: "Bestätigung",
              customer: "Kunden",
              currentFollowupStage: "Aktuelle Folge phase",
              telephone: "Telefon",
              followUpDays: "Follow-up-Tage",
              day: "Tag",
              remarks: "Bemerkungen",
              channelPreference: "Kanal präferenzen",
              hello: "Hallo",
              customFields: "Benutzer definierte Felder",
              opportunityAmount: "Anzahl der Geschäfts möglichkeiten",
              focus: "Sorge",
              customerInformation: "Kunden informationen",
              viewMoreCustomerInformation:
                "Weitere Kunden informationen anzeigen",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Schlüssel ereignisse:" },
                row1: {
                  customerDynamics: "Kunden dynamik",
                  common: "Insgesamt",
                  barDynamic: "Streifen dynamisch",
                },
                row2: {
                  riskPoint: "Risiko punkte",
                  common: "Insgesamt",
                  risks: "Streifen risiko",
                },
                row3: {
                  followUpSuggestions: "Follow-up-Empfehlungen",
                  intelligentRecommendation: "Intelligente Empfehlung",
                  strip: "Artikel",
                  managersSuggestATotalOf: "Manager schlagen insgesamt vor",
                  recommendedByManagers: "Empfehlung des Managers",
                  creationTime: "Zeit erstellen",
                  administratorSuggestions: "Admini strator beratung",
                  suggestionsForNewManagers: "Vorschläge für neue Manager",
                },
              },
            },
            index: { typeTime: "Typ/Zeit" },
          },
          userInfo: {
            index: {
              personInCharge: "Ver antwort licher",
              recentFollower: "Kürzlich nachverfolgt",
              startTime: "Erste Anflug zeit",
              followUpDays: "Follow-up-Tage",
              day: "Tag",
              remarks: "Bemerkungen",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Teammitglieder",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Mitglieder lernen die Gesamtzahl der Sitzungen anderer Personen (um sich zu betonen), überprüfen Sie die Sitzungs details und klicken Sie auf die Aufnahme, um sie als einmal zu lernen",
          totalNumberOfStudies: "Gesamtzahl der Studien",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Die Gesamtzahl der Kommentare und Antworten, die von Mitgliedern zu Sitzungen anderer Personen initiiert wurden (Do-Do)",
          totalNumberOfInteractions: "Gesamtzahl der Interaktionen",
          learnTheSumOfConversationCallDuration:
            "Summe der Konversation anruf zeit",
          lengthOfStudy: "Dauer des Lernens",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Die Gesamtzahl der Mitglieder, die Hilfe für die Sitzungen anderer Personen initiieren (decodieren)",
          totalNumberOfRequests: "Gesamtzahl der Hilfe anfragen",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Die Gesamtzahl der Mitglieder, die die Hilfe anderer Personen beraten (entfernt)",
          totalCounseling: "Gesamtzahl der Nachhilfe",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Die Gesamtzahl der Sitzungen von Mitgliedern zu anderen Personen, die der Fall bibliothek hinzugefügt wurden (dever)",
          addCase: "Hinzufügen von Fällen",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Die Gesamtzahl der Mitglieder, die die Sitzungen anderer Personen abonnieren (entfernt)",
          subscribe: "Abonnieren",
          totalNumberOfSessionsCommunicatedByMembers:
            "Gesamtzahl der Sitzungen, mit denen Mitglieder kommunizieren",
          totalSessions: "Gesamtzahl der Sitzungen",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Mitglieder wiederholen die Gesamtzahl ihrer eigenen Sitzungen (Deter pline), überprüfen Sie die Sitzungs details und klicken Sie auf die Aufnahme, um sie als einmal zu wiederholen",
          totalNumberOfDoubleDisk: "Gesamtzahl der Wiedereinsenen",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Gesamtzahl der Überprüfungen/Gesamtzahl der Sitzungen * 100%",
          proportionOfDoubleDisk: "Anteil der Wiederaufnahme",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Summe der Sitzungs anrufs tunden",
          resumptionDuration: "Wiederholung dauer",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Die Gesamtzahl der Mitglieder, die Hilfe für ihre eigenen Sitzungen initiieren (entfernt)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Die Gesamtzahl der Kommentare (einschl ießlich Antworten), die von den Sitzungen der Mitglieder erhalten wurden",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Die Gesamtzahl der Sitzungen eines Mitglieds, die von anderen zur Fall bibliothek hinzugefügt wurden (dever)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Die Gesamtzahl der Sitzungen eines Mitglieds, die von anderen abonniert wurden (Do-Do)",
          askForHelpInTheLastMonth: "Hilfe im letzten Monat",
          lastTimeForHelp: "Letzte Hilferufe",
          operation: "Betrieb",
          viewAllSessionsInTheLastMonth:
            "Alle Sitzungen des letzten Monats anzeigen",
          allDepartments: "Alle Abteilungen",
          reset: "Zurücksetzen",
          query: "Abfrage",
          altogether: "Insgesamt",
          secondSession: "Zweite Sitzung",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Aktuelle Test gegenstände:",
            supervisionItem: "Überwachungs gegenstände",
            keyEvents: "Schlüssel ereignisse",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Bitte kreuzen Sie die zu testenden Überwachungs elemente oder Schlüssel ereignisse an und stellen Sie sicher, dass der Anruf in der simulierten Konversation enthalten ist",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Analyse der Ausführung" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Prozess 1",
              technologicalProcess: "Prozess",
              newProcess: "Neuer Prozess",
            },
          },
          index: {
            upload: "Hochladen",
            preservation: "Speichern",
            newlyBuild: "Neu",
            cancel: "Stornierung",
            name: "Name",
            describe: "Beschreibung",
            type: "Typ",
            classification: "Klassifizierung",
            content: "Inhalt",
            enclosure: "Anhang",
            knowledgePointAssociation: "Wissens punkt zuordnung",
            open: "Öffnen",
            close: "Schließen",
            operator: "Operator",
            logic: "Logik",
            see: "Anzeigen",
            edit: "Bearbeiten",
          },
        },
        index: {
          name: "Name",
          describe: "Beschreibung",
          createdBy: "Schöpfer",
          type: "Typ",
          lastUpdated: "Letzte Aktualisierung",
          numberOfReferences: "Anzahl der Zitate",
          operation: "Betrieb",
          see: "Anzeigen",
          edit: "Bearbeiten",
          delete: "Löschen",
          categoryDeletedSuccessfully: "Klassifiziertes Löschen erfolgreich",
          classificationOfKnowledgePoints:
            "Klassifizierung von Wissens punkten",
          newlyBuild: "Neu",
          knowledgeBase: "Wissens datenbank",
          knowledgePoints: "Wissens punkte",
          technologicalProcess: "Prozess",
          keyword: "Schlüssel wörter",
          batchExport: "Massen export",
          batchDelete: "Massen löschen",
          importProcess: "Prozess importieren",
          importKnowledgePoints: "Wissens punkte importieren",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Wenn der Wissens punkt in der Wissens datenbank nicht vorhanden ist, fügen Sie den Wissens punkt direkt hinzu. Wenn er vorhanden ist, überspringen Sie ihn und aktualisieren Sie ihn nicht.",
          editKnowledgePointClassification:
            "Wissens punkt klassifizierung bearbeiten",
          newKnowledgePointClassification: "Neue Wissens punkt klassifizierung",
          categoryEditingSucceeded: "Klassifiziertes Bearbeiten erfolgreich",
          newClassificationSucceeded: "Neue Kategorie erfolgreich",
          classificationName: "Klassifizierung namen",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Überwachung der Ausführung" },
      },
      topicCallout: {
        index: {
          whole: "Alle",
          date: "Datum",
          dimensionStatus: "Beschriftung status",
          callRecordID: "ID des Anruf protokolls",
          callRecordIDMustBeNumeric:
            "Die Anruf protokoll-ID muss numerisch sein",
          strip: "Artikel",
          topicAnnotation: "Themen kennzeichnung",
          annotated: "Beschriftet",
          stripNotMarked: "Artikel, nicht markiert",
          barInDimension: "Artikel, markieren",
          customer: "Kunden",
          sale: "Verkauf",
          common: "Insgesamt",
          sessions: "Streifen sitzung",
          currentTopic: "Aktuelles Thema:",
          staff: "Mitarbeiter",
          topicOfConversation: "Thema",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Der Vorgang war erfolgreich, das Etikett wurde zurück gesetzt",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Nach dem Zurücksetzen werden alle Beschriftung datensätze dieser Sitzung gelöscht. Bitte bestätigen Sie",
          resetAll: "Alle zurücksetzen",
          pleaseSelectTheTopicToBeMarked:
            "Bitte wählen Sie das Thema aus, das Sie markieren möchten",
          pleaseTickTheSentenceToBeMarked:
            "Bitte kreuzen Sie den Satz an, der markiert werden soll",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Der Vorgang ist erfolgreich, die Kennzeichnung ist wirksam",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Der Umfang dieser Kennzeichnung enthält Nachrichten zu anderen Themen. Klicken Sie auf OK, um sie abzudecken",
          confirmDimensions: "Bestätigen Sie die Kennzeichnung",
          talkTime: "Gesprächs zeit",
          callID: "Anruf id",
        },
      },
      topicList: {
        index: {
          callID: "Anruf-ID",
          latestMarkedTime: "Letzte Markierung szeit",
          topicOfConversation: "Thema",
          numberOfSentences: "Anzahl der Sätze",
          sentence: "Satz",
          operation: "Betrieb",
          see: "Anzeigen",
          delete: "Löschen",
          edit: "Bearbeiten",
          newSubTopic: "Neues Unter thema",
          move: "Mobile",
          whole: "Alle",
          topicDeletedSuccessfully: "Löschen des Themas Erfolg",
          newlyBuild: "Neu",
          topicList: "Liste der Themen",
          callIDMustBeNumeric: "Die Anruf-ID muss numerisch sein",
          leadInTopic: "Themen importieren",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Wenn der Kategorie name wiederholt wird, wird die ursprüngliche Themen kategorie nicht übers chrieben, das Element wird direkt übersprungen und nicht importiert",
          mobileClassification: "Mobile Klassifizierung",
          editTopic: "Themen bearbeiten",
          newTopic: "Neues Thema",
          topicName: "Thema Name",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Erfolgreich modifiziert",
            createdSuccessfully: "Erfolgreich erstellen",
            modificationFailed: "Änderung fehl geschlagen",
            creationFailed: "Erstellung fehl geschlagen",
            monitoringName: "Name der Überwachung",
            upToCharacters: "Bis zu 40 Zeichen",
            pleaseEnterAName: "Bitte geben Sie den Namen ein",
            monitoringIndicators: "Überwachungs indikatoren",
            sessionActivity: "Sitzungs aktivitäten",
            conversationAction: "Sitzungs aktion",
            keyEvents: "Schlüssel ereignisse",
            supervisionItems: "Überwachungs gegenstände",
            monitoringMembers: "Mitglieder überwachen",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Gruppen, die Anleitung, Schulung oder Management intervention erhalten",
            groupA: "Gruppe A",
            pleaseSelectGroupAData: "Bitte wählen Sie Gruppe A Daten",
            thereAreDuplicateUsersInGroupB:
              "Benutzer mit dem Vorhanden sein von Duplikate in Gruppe B",
            groupARemarks: "Anmerkungen der Gruppe A",
            controlGroupGroupComparedWithGroupA:
              "Kontrollgruppe, die Gruppe, die mit Gruppe A verglichen wurde",
            groupB: "Gruppe B",
            pleaseSelectGroupBData: "Bitte wählen Sie Daten gruppe B.",
            thereAreDuplicateUsersInGroupA:
              "Benutzer mit Duplikat ion in Gruppe A",
            groupBRemarks: "Anmerkungen der Gruppe B",
            monitoringPeriod: "Überwachungs zyklus",
            startNode: "Knoten starten",
            monitoringScope: "Umfang der Überwachung",
            pleaseSelectMonitoringConditions:
              "Bitte wählen Sie Überwachungs bedingungen",
            pleaseEnterANumber: "Bitte geben Sie die Zahlen ein",
            pleaseSelectATimePeriod: "Bitte wählen Sie den Zeitraum",
            preservation: "Speichern",
            cancel: "Stornierung",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Löschen erfolgreich",
            notStarted: "Nicht gestartet",
            haveInHand: "In Arbeit",
            closed: "Es ist vorbei",
            newGroupTest: "Neuer Paket test",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Phase des Verkaufs",
        communicationStrategy1: "Kommunikation strategie",
        riskAllocation: "Risiko-Allokation",
        businessConfiguration: "Geschäfts konfiguration",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Dissens behandlung",
                  productSellingPointScript: "Produkt verkaufs argument",
                  caseGrouping: "Fall gruppierung",
                  pleaseSelectExcellentCases:
                    "Bitte wählen Sie einen aus gezeichneten Fall",
                  pleaseSelectKnowledgePoints:
                    "Bitte wählen Sie Wissens punkte",
                  associativeLinks: "Assoziierte Links",
                  jumpTo: "Springe zu",
                  jumpType: "Sprung typ",
                  knowledgePoints: "Wissens punkte",
                  excellentCases: "Aus gezeichneter Fall",
                  excellentScript: "Aus gezeichnete Wörter",
                  pleaseSelectASingleKnowledgePoint:
                    "Bitte wählen Sie einen einzelnen Wissens punkt",
                },
              },
            },
            index: {
              name: "Name",
              pushText: "Push-Text",
              enableStatus: "Status aktivieren",
              triggerRules: "Trigger regeln",
              communicationStrategy: "Kommunikation strategie",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping:
                "Gruppierung von Kommunikation strategien",
              name: "Name",
              pushText: "Push-Text",
              effectiveness: "Wirksamkeit",
              openSuccessfully: "Öffnen Sie den Erfolg",
              closeSuccessfully: "Schließen Sie erfolgreich",
              operationFailed: "Operation fehl geschlagen",
              operation: "Betrieb",
              edit: "Bearbeiten",
              delete: "Löschen",
              newlyBuild: "Neu",
              communicationStrategy: "Kommunikation strategie",
              editGroup: "Gruppierung bearbeiten",
              groupName: "Gruppieren von Namen",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Alle Sitzungen",
                  recentDays: "Die letzten Tage",
                  recentSessions: "Letzte Sitzung",
                  scopeOfApplication: "Anwendungs bereich:",
                  day: "Tag",
                  through: "Pass",
                },
              },
            },
            index: {
              describe: "Beschreibung",
              applicableCustomers: "Anwendbar für Kunden",
              enableStatus: "Status aktivieren",
              triggerRules: "Trigger regeln",
              riskRules: "Risiko regeln",
            },
          },
          list: {
            index: {
              riskAllocation: "Risiko-Allokation",
              keyWord: "Schlüssel wörter",
              newRiskRule: "Neue Risiko regeln",
              riskRuleName: "Name der Risiko regel",
              describe: "Beschreibung",
              state: "Zustand",
              openSuccessfully: "Öffnen Sie den Erfolg",
              closeSuccessfully: "Schließen Sie erfolgreich",
              operationFailed: "Operation fehl geschlagen",
              operation: "Betrieb",
              edit: "Bearbeiten",
              delete: "Löschen",
              deletionSucceeded: "Löschen erfolgreich",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Verlust rechnung" },
              endReasonList: {
                addEndReason: "Gründe für das Ende hinzufügen",
                enterEndReason: "Grund für das Ende der Eingabe",
              },
              stageList: {
                keyEvents: "Schlüssel ereignisse",
                waysideData: "Daten auf dem Weg",
                includeAny: "Enthält beliebig",
                includeAll: "Alle enthalten",
                pleaseEnterTheStageName: "Bitte geben Sie den Bühnen namen ein",
                setStageRules: "Bühnen regeln festlegen",
                stageName: "Bühnen name",
                operator: "Operator",
                logic: "Logik",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Das Eingabe format des Zeittyp felds lautet: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Die Anfrage ist fehl geschlagen, bitte versuchen Sie es später erneut",
              salesStage: "Phase des Verkaufs",
              essentialInformation: "Grundlegende Informationen",
              name: "Name",
              applicableDepartments: "Anwendbare Abteilung",
              applicableToOthers: "Anwendbar auf andere",
              manualChangePhase: "Manuelle Änderungs phase",
              enableStatus: "Status aktivieren",
              describe: "Beschreibung",
              opportunityStageSettings: "Einstellung der Geschäfts möglichkeit",
              stageSettings: "Bühnen einstellungen",
              aliasSettings: "Alias-Einstellungen",
              closeOpportunity: "Geschäfts möglichkeiten beenden",
              newSuccessfully: "Neuer Erfolg",
              newFailed: "Neuer Fehler",
              savingSucceeded: "Speichern Sie erfolgreich",
              saveFailed: "Speichern fehl geschlagen",
            },
          },
          list: {
            index: {
              salesStage: "Phase des Verkaufs",
              keyWord: "Schlüssel wörter",
              newSalesStage: "Neue Verkaufs phase",
              salesStageName: "Name der Verkaufs phase",
              describe: "Beschreibung",
              state: "Zustand",
              openSuccessfully: "Öffnen Sie den Erfolg",
              closeSuccessfully: "Schließen Sie erfolgreich",
              operationFailed: "Operation fehl geschlagen",
              operation: "Betrieb",
              edit: "Bearbeiten",
              delete: "Löschen",
              deletionSucceeded: "Löschen erfolgreich",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Große Modell generierung",
            conversationInsights: "Einsicht in die Sitzung",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Automatische Generierung" },
              },
              hooks: {
                conversationSummary: "Zusammenfassung der Sitzung",
                conversationProcess: "Sitzungs prozess",
                opportunityPoints: "Punkt der Gelegenheit",
              },
              prompt: {
                index: {
                  edit: "Bearbeiten",
                  restoreDefault: "Standard wiederherstellen",
                  cancel: "Stornierung",
                  confirm: "Bestätigung",
                },
              },
            },
            index: {
              saveSuccessful: "Speichern Sie erfolgreich",
              hintLanguage: "Eingabe aufforderung",
              preserve: "Speichern",
              cancel: "Stornierung",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Große Modell generierung",
        salesStage: "Phase des Verkaufs",
        communicationStrategy: "Kommunikation strategie",
        riskAllocation: "Risiko-Allokation",
        businessConfiguration: "Geschäfts konfiguration",
      },
      salesLearningCenter: {
        knowledgeBase: "Wissens datenbank",
        speechCorpus: "Sprach bibliothek",
        learningCenter: "Lern zentren",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Kunden informationen" },
          },
        },
        index: { otherInformation: "Weitere Informationen" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Importieren" } } },
        hooks: {
          index: {
            all: "Alle",
            intelligentModel: "Intelligentes Modell",
            smartRules: "Regeln der Intelligenz",
            manualScoring: "Manuelle Bewertung",
            enable: "Aktivieren",
            notEnabled: "Nicht aktiviert",
            voiceCall: "Sprach anrufe",
            textDialogue: "Text gespräche",
            ecommerceTextSession: "E-Commerce-Texts itzung",
            workOrder: "Arbeits auftrag",
          },
        },
        index: {
          ruleName: "Name der Regel",
          ruleType: "Arten von Regeln",
          pleaseSelect: "Bitte wählen Sie",
          ruleStatus: "Zustand der Regeln",
          dataSourceType: "Typ der Datenquelle",
          voiceCall: "Sprach anrufe",
          textDialogue: "Text gespräche",
          ecommerceTextSession: "E-Commerce-Texts itzung",
          workOrder: "Arbeits auftrag",
          status: "Zustand",
          operation: "Betrieb",
          edit: "Bearbeiten",
          ruleTemplate: "Regel vorlagen",
        },
      },
      analysisReportTitle: "Analyse berichte",
      ruleTest: "Regel test",
      intelligentTags: {
        labelDisplayQuantity: "Anzahl der Etiketten anzeigen",
        unlimited: "Keine Begrenzung",
        ignoringTagLibraries: "Tag-Bibliotheken ignorieren",
        automaticallyGenerateLabels:
          "Automatische Erstellung von Beschriftungen",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'Generieren Sie nach dem Öffnen automatisch Beschriftungen für die Sitzung basierend auf dem großen Modell, und die generierten Beschriftungen werden standard mäßig unter "Nicht katego risiert" klass ifi ziert.',
        defaultValidity: "Standard gültigkeit",
        supervisionItem: "Überwachungs gegenstände",
        customerLabel: "Kunden-Tags",
        newSupervisionItemRule: "Neue Aufsichts regeln",
        editSupervisionItemRule: "Aufsichts regeln bearbeiten",
        newCustomerLabel: "Neues Kunden etikett",
        editCustomerLabel: "Kunden-Tags bearbeiten",
        newSessionLabel: "Neue Sitzungs beschriftungen",
        editSessionLabel: "Sitzungs beschriftungen bearbeiten",
        newKeyEvent: "Neue Schlüssel ereignisse",
        editKeyEvents: "Bearbeiten von Schlüssel ereignissen",
        detail: {
          template: { nameIsARequiredField: "Name ist ein Pflicht feld" },
          formItemComponent: {
            largeModelExtensionLabel: "Großes Modell erweiterungs etikett",
            sessionRecordID: "Sitzungs protokoll id",
            sessionTime: "Sitzungs zeit",
            smartTags: "Intelligente Tags",
            viewDetails: "Details anzeigen",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Nach dem Löschen werden die in dieser Sitzung auf gezeichneten Daten gelöscht. Wird das Löschen bestätigt?",
            tagData: "Daten markieren",
            batchDeletion: "Massen löschen",
          },
        },
        template: {
          advancedFiltering: "Erweiterte Filterung",
          dataType: "Datentypen",
          filterCriteria: "Screening-Bedingungen",
          edit: "Bearbeiten",
          createANewSubcategory: "Neue Unter kategorie",
          delete: "Löschen",
          nameLengthCannotExceed:
            "Die Länge des Namens darf 40 nicht überschreiten",
        },
        component: {
          modelExpansion: "Modell erweiterung",
          enable: "Aktivieren",
          notEnabled: "Nicht aktiviert",
          standardScreening: "Standard-Screening",
          advancedFiltering: "Erweiterte Filterung",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Schlüssel wörter",
              sessionLabel: "Sitzungs beschriftungen",
              keyEvents: "Schlüssel ereignisse",
              matchAny: "Passen Sie beliebig an",
              matchAll: "Alles zusammenpassen",
              beEqualTo: "Gleich",
              includeAll: "Alle enthalten",
              includeAny: "Enthält beliebig",
              advancedConditions: "Fort geschrittene Bedingungen",
              smartTag: "Intelligente Tags",
              smartTagI: "Smart Tag eins",
              smartTagII: "Intelligente Tags zwei",
            },
            template: {
              pleaseSelectSmartTag: "Bitte wählen Sie Smart Label",
              pleaseSelectASessionLabel:
                "Bitte wählen Sie die Sitzungs beschriftung",
              pleaseSelectAKeyEvent: "Bitte wählen Sie das Schlüssel ereignis",
              pleaseSelectAdvancedCondition:
                "Bitte wählen Sie erweiterte Bedingungen",
              newAdvancedFilter: "Neuer erweiterter Filter",
              moveUp: "Nach oben bewegen",
              moveDown: "Nach unten bewegen",
              moveLeft: "Links verschoben",
              shiftRight: "Rechts verschoben",
            },
          },
          component: {
            businessAnalysispng: "Geschäfts analyze. png",
            savedSuccessfully: "Speichern Sie erfolgreich",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Dauerhaft wirksam",
            permanentlyInvalid: "Dauerhaft ungültig",
            customValidity: "Benutzer definiertes Ablaufdatum",
            termOfValidity: "Gültigkeit dauer",
            name: "Name",
            explain: "Beschreibung",
            state: "Zustand",
            classification: "Klassifizierung",
            smartRecommendation: "Intelligente Empfehlung",
            preconditions: "Voraussetzungen",
            SetPreconditionsForThisSupervisionItem:
              "1. Legen Sie die Voraussetzungen für dieses Überwachungs element fest:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Wenn die Voraussetzungen erfüllt sind, handelt es sich um ein "zu implemen tieren des Überwachungs element", und das Überwachungs element wird getestet.',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Wenn die Voraussetzungen nicht erfüllt sind, wird dieser Überwachungs posten nicht erkannt.",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Es werden keine Voraussetzungen festgelegt, dh alle Sitzungen werden standard mäßig erkannt",
            have: "Ja",
            nothing: "Keine",
            preconditionRule: "Vorbedingung regeln",
            preconditionLogic: "Vorbedingung logik",
          },
          recommendation: {
            caseGrouping: "Fall gruppierung",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Wenn Mitarbeiter nicht ausgeführt werden, können sie auf intelligente Weise hervorragende Sprach fähigkeiten und hervorragende Fälle empfehlen",
            smartRecommendation: "Intelligente Empfehlung",
            open: "Öffnen",
            close: "Schließen",
            whenThisSupervisionItem: "Wenn dieses Überwachungs element",
            implement: "Ausführung",
            unexecuted: "Nicht ausgeführt",
            when: "Zeit:",
            recommendedKnowledgePoints: "Empfohlene Wissens punkte",
            caseRecommendation: "Fall empfehlung",
          },
        },
        index: {
          determine: "Bestimmt",
          sort: "Sortieren",
          sortSucceeded: "Erfolg beim Sortieren",
          supervisionItemClassification:
            "Klassifizierung von Überwachungs posten",
          pressEnterToCreateANew:
            "Drücken Sie die Wagen rücklauf taste, um neu zu erstellen",
          nameCannotBeEmpty: "Der Name kann nicht leer sein",
          newlyBuild: "Neu",
          name: "Name",
          explain: "Beschreibung",
          validStatus: "Gültiger Status",
          permanentlyValid: "Dauerhaft wirksam",
          permanentlyInvalid: "Dauerhaft ungültig",
          operation: "Betrieb",
          edit: "Bearbeiten",
          batchDelete: "Massen löschen",
          newSupervisionItem: "Neues Überwachungs element",
          keyWord: "Schlüssel wörter",
          delete: "Löschen",
          deleteSucceeded: "Löschen erfolgreich",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Die Daten können nach dem Löschen nicht wieder hergestellt werden. Wird das Löschen bestätigt?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Intelligente Tags",
              beEqualTo: "Gleich",
              includeAll: "Alle enthalten",
              includeAny: "Enthält beliebig",
              matchAny: "Passen Sie beliebig an",
              matchAll: "Alles zusammenpassen",
              smartTagI: "Smart Tag eins",
              smartTagII: "Intelligente Tags zwei",
            },
            template: {
              pleaseSelectASmartTag: "Bitte wählen Sie Smart Label",
              pleaseSelectAdvancedConditions:
                "Bitte wählen Sie erweiterte Bedingungen",
              newAdvancedFilter: "Neuer erweiterter Filter",
              rename: "Umbenennen",
              moveUp: "Nach oben bewegen",
              moveDown: "Nach unten bewegen",
              shiftLeft: "Links verschoben",
              shiftRight: "Rechts verschoben",
            },
          },
        },
        template: {
          delete: "Löschen",
          labelFiltering: "Tag-Screening:",
          satisfyAny: "Befriedigt jeden",
          meetAll: "Treffen Sie alle",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Klassifizierung kennzeichnung der zweiten Ebene Die Anzahl der Dialoge ≥ Klassifizierung der ersten Ebene, eine Sitzung kann mehrere Tags enthalten",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Berechnungs logik für Ketten änderungen: (Dialog volumen des ausgewählten Zeitraums-Dialog volumen des letzten Zeitraums neben dem letzten Zeitraum)/Dialog volumen des letzten Zeitraums",
          proportionOfPrimaryClassification:
            "Der Anteil der Klassifizierung der ersten Ebene",
          saveAs: "Speichern unter",
          cover: "Abdeckung",
          preservation: "Speichern",
          qualityInspectionTask: "Analytische Aufgaben:",
          query: "Abfrage",
          changeFromMonthToMonth: "Ketten veränderungen",
          tagRanking: "Label-Ranking",
          proportion: "Anteil",
        },
        component: {
          numberOfConversations: "Anzahl der Gespräche",
          operation: "Betrieb",
          businessAnalysispng: "Geschäfts analyze. png",
          savingSucceeded: "Speichern Sie erfolgreich",
          deletionSucceeded: "Löschen erfolgreich",
          sequence: "Sequenz",
          firstLevelClassification: "Klassifizierung der ersten Ebene",
          ringRatioChange: "Veränderung gegenüber dem Vor monat",
          classClassification: "Klassen klassifizierung",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Bestätigen Sie das Löschen von Dokumenten und deren Extraktion?",
            addKnowledgeBase: "Hinzufügen einer Wissens datenbank",
            uploadDocument: "Dokumente hochladen",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Unterstützt Dateien im Word-, PDF-, Ppt-, Excel-und TXT-Format",
            taskName: "Name der Aufgabe",
            extractConversationDate: "Dialog datum extrahieren",
            pleaseSelectAConversationDate:
              "Bitte wählen Sie das Datum der Konversation",
            dataSource: "Datenquellen",
            dataType: "Datentypen",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Erfolgreich exportieren",
              problem: "Das Problem",
              answer: "Die Antwort",
              addToKnowledgeBase: "Hinzufügen zur Wissens datenbank",
              pleaseConfirmWhetherToDeleteIt:
                "Bitte stellen Sie sicher, ob Sie löschen möchten",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Hinzufügen zur Wissens datenbank",
              selectClassification: "Auswahl der Kategorie",
              pleaseSelectAKnowledgeBaseClassification:
                "Bitte wählen Sie die Wissens datenbank klassifizierung",
            },
          },
        },
        index: {
          intelligentExtraction: "Intelligente Extraktion",
          documentExtraction: "Dokumenten extraktion",
          dialogueExtraction: "Dialog extraktion",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Erfolgreiche Operation",
          labelIgnored: "Tags wurden ignoriert",
          add: "Hinzufügen",
          areYouSureToDeleteIt: "Sicher zu löschen?",
          batchFilterLabels: "Massen filter etiketten",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Intelligente Qualitäts prüfung",
        savedSuccessfully: "Speichern Sie erfolgreich",
        logoSettings: "Logo-Einstellungen",
        default: "Standard",
        custom: "Anpassen",
        title: "Titel",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Der Name des vom System gezeigten Titels wird innerhalb von 6 Wörtern empfohlen.",
        logoImageUpload: "Hochladen von Logo-Bildern",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Bitte wählen Sie Bilder mit einer Größe von nicht mehr als 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Um den Effekt des Logo-Browsings sicher zustellen, schlagen Sie vor, Bilder hochzuladen:",
        Size5050Pixels: "1. Größe: 50*50 Pixel;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Format: Bild mit transparentem Hintergrund, PNG oder JPG, Logo-Grafik mit Weiß",
        browserLabelIcon: "Browser-Etiketten-Symbol",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Um den Effekt des Durchsuchen von Tags sicher zustellen, schlagen Sie vor, Bilder hochzuladen:",
        Size3232Pixels: "1. Größe: 32*32 Pixel;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Format: transparenter hintergrund bild, png oder jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Die Qualitäts prüfung ist im Gange, bitte warten Sie später...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "Zu diesem Zeitpunkt im Jahr 2015 haben Chinas Immobilien einen kritischen Moment des Überlebens erreicht. Eine große Anzahl von Häusern ist unbe aufsicht igt, und die Menschen sind nicht bereit, sie zu kaufen. Hangzhou fiel einmal unter 8000, und einige Entwickler zersch metter ten den Markt für Lieferungen. Shenzhen konsolidierte sich zu diesem Zeitpunkt immer noch um 2 bis 3W, und Häuser in erstklassigen Städten waren ebenfalls in Gefahr",
        xiaoAn: "Xiaoan",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Stores tore storeload Diese Speicher barrieren werden als jsr-Speicher barrieren bezeichnet, die auf jvm-Ebene liegen. Es gibt zwei Möglichkeiten, sie auf der untersten Ebene des Betriebs systems zu implemen tieren: 1 ist der Sperr bus und die andere ist die Meta sprache der Speicher barriere, wie z. B. sfance (Schreib barriere) mfence (volle Barriere). Die unterste Ebene der virtuellen Maschine von hots pots verwendet eine Assembly-Anweisung: lock addl,lock a leerer Vorgang, der der Sperr bus ist. Dies liegt daran, dass einige Betriebs systeme keine Speicher barriere meta unterstützen.",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Versuchen Sie, das Fleisch mehrmals zu marinieren, und das nach dem Hinzufügen von Zutaten gebratene Gemüse, das Fleisch ist wie faul und es riecht sauer! Von da an wird kein Kochwein mehr in das Wurstfleisch gegeben",
        xiaodong: "Xiaodong",
        xiaoxi: "Xiaoxi",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Versuchen Sie, das Fleisch mehrmals zu marinieren, und das nach dem Hinzufügen von Zutaten gebratene Gemüse, das Fleisch ist wie faul, mit einem sauren Geruch! Von da an wird kein Kochwein mehr in das Wurstfleisch gegeben",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "E-Commerce-Kommentar verwaltung",
        socialMediaReviewManagement: "Verwaltung sozialer Medien kommentare",
        customDataSource: "Benutzer definierte Daten original",
        qualityInspectionResults: "Ergebnisse der Qualitäts prüfung",
        accountManagement: "Konto verwaltung",
        employeeProfile: "Mitarbeiter porträt",
        intelligentAnalysis: "Intelligente Analyse",
        customKanban: "Benutzer definiertes Kanban",
        dataScreening: "Übersicht über die Daten",
        learningRecord: "Lern aufzeichnungen",
        dataAnalysis: "Datenanalyse",
        scoringManagement: "Management von Bewertungen",
        businessConfiguration: "Geschäfts konfiguration",
        manualReview: "Manuelle Überprüfung",
        taskList: "Aufgaben liste",
        videoAnalysis: "Video analyze",
        overview: "Übersicht",
        personalSignage: "Persönliche Kanban",
        learningCenter: "Lern zentren",
        wrongQuestionSet: "Falsche Fragens ammlung",
        abilityImprovement: "Verbesserung der Fähigkeit",
        close: "Schließen",
        switchingSystemApplications: "System anwendungen umschalten",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Bewegen Sie die Maus hier, um das Anwendungs umschalt menü aufzurufen. Klicken Sie auf den Menüpunkt, um zu verschiedenen Anwendungen zu springen",
        teamAnalysis: "Team analyze",
        semanticIntelligence: "Semantische Intelligenz",
        permissionManagement: "Verwaltung von Berechtigungen",
        dataConnection: "Docking von Daten",
        courseManagement: "Kurs management",
        labelManagement: "Etiketten verwaltung",
        curriculum: "Kurs zentrum",
        courseConfiguration: "Kurs konfiguration",
        task: "Meine Aufgabe",
        historicalLearningData: "Historische Aufzeichnungen",
        taskCenter: "Aufgaben verwaltung",
        knowledgeBase: "Wissens datenbank",
        ecommerceSessionManagement: "Verwaltung von E-Commerce-Sitzungen",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "App-Schalter",
          descriptionOfIntelligentQualityInspectionSystem:
            "Beschreibung des intelligenten Qualitäts prüf systems",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "E-Commerce-Sitzungs filter",
        allEcommerceSessions: "Alle E-Commerce-Sitzungen",
        ecommerceSessionManagement: "Verwaltung von E-Commerce-Sitzungen",
      },
    },
    analysisEnable: {
      bestPractices: "Best Practices",
      behaviorAnalysis: "Verhaltens analyze",
      teamAnalysis: "Team analyze",
      executiveSupervision: "Überwachung der Ausführung",
      executiveForceAnalysis: "Analyse der Ausführung",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Bewertung:",
          startTime: "Zeit für den Start",
          workOrderNo: "Arbeits auftrags nummer",
          customerServiceHasReadNotRead: "Kundendienst gelesen/ungelesen",
          canYouAppeal: "Ob Berufung eingelegt werden kann",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Enterprise Micro Bemerkungen",
          addFriendTime: "Freunde Zeit hinzufügen",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Starten Sie die erneute Qualitäts prüfung",
          callID: "Anruf-ID",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Neue Funktionen! Klicken Sie auf die Schaltfläche "Schnelle Probenahme". Nach erfolgreicher Einreichung wird automatisch zur nächsten Seite gesprungen ~~~',
        },
      },
      template: {
        deleteRecord: "Datensätze löschen",
        areYouSureToDeleteIt: "Sicher zu löschen?",
        confirm: "Bestimmt",
        reIdentification: "Wieder identifizieren",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Die erneute Identifizierung verbraucht die Trans literations zeit. Bitte seien Sie vorsichtig",
        determine: "Bestimmt",
        executionSuccessful: "Erfolgreiche Ausführung",
      },
      component: {
        pleaseSelectData: "Bitte wählen Sie Daten",
        operationSuccessful: "Erfolgreiche Operation",
        startPerformingReQualityInspection:
          "Starten Sie die erneute Qualitäts prüfung",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Name der Datei hochladen",
        analysisTask: "Analytische Aufgaben",
        analyzeProgress: "Fortschritt analysieren",
        analysisSucceeded: "Analysieren Sie den Erfolg",
        analysisFailed: "Analyse fehl geschlagen",
        localDataUpload: "Hochladen lokaler Daten",
        recordingUpload: "Aufnahme-Upload",
        textUpload: "Text-Upload",
        uploadTime: "Upload zeit",
        taskName: "Name der Aufgabe",
        operator: "Betreiber",
        qualityInspectionProgress: "Fortschritt der Qualitäts prüfung",
        whole: "Alle",
        haveInHand: "In Arbeit",
        completed: "Abgeschlossen",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Exportieren Sie alle abgeschlossenen Datensätze unter den aktuellen Filter bedingungen?",
        upload: "Hochladen",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Füllen Sie den Namen aus",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Geben Sie den Namen ein, z. B. den Typ/die Sammlung/den Zweck der importierten Daten",
            default: "Standard",
            jDCOM: "Jingdong",
            dataType: "Datentypen",
            selectAnalysisTask: "Analytische Aufgaben auswählen",
            uploadFiles: "Dateien hochladen",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Laden Sie die Excel-Vorlage herunter und füllen Sie sie im Format aus:",
            template: "Vorlage",
            SupportUpTo10000PiecesOfData:
              "2. Maximale Unterstützung von 10000 Daten",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Unterstützt das Hochladen von von JD exportierten Konversation daten, TXT-Dateien und die maximale Dateigröße unterstützt 100M",
            alongTheWayDataTemplate: "Daten vorlagen auf dem Weg",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Bitte haben Sie etwas Geduld in der Text qualitäts prüfung",
            textQualityInspectionCompleted:
              "Text-Qualitäts prüfung abgeschlossen",
          },
        },
        template: {
          startAnalysis: "Beginnen Sie mit der Analyse",
          pleaseUploadTheFile: "Bitte laden Sie die Datei hoch",
          localDataUpload: "Hochladen lokaler Daten",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            'Neue Funktionen! Klicken Sie auf die Schaltfläche "Überprüfungs-und Übermittlung". Nach erfolgreicher Übermittlung springt sie automatisch zur nächsten Seite ~~~',
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Aufgaben berechtigung für die Qualitäts prüfung",
            edit: "Bearbeiten",
            iParticipated: "Ich habe teilgenommen",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Diese Rolle kann die Daten unter der ausgewählten Abteilung sehen",
            dataPermissions: "Daten berechtigungen",
            selectDepartment: "Abteilung auswählen",
            edit: "Bearbeiten",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Starten Sie die erneute Qualitäts prüfung",
          callID: "Anruf-ID",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Neue Funktionen! Klicken Sie auf die Schaltflächen "Probenahme einreichen" und "Schnelle Probenahme". Nach erfolgreicher Übermittlung springt es automatisch zur nächsten Seite ~~~',
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Regeln" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Arbeits auftrags verwaltung",
          workOrderDetails: "Details zum Arbeits auftrag",
        },
      },
      template: {
        workOrderFilter: "Arbeits auftrags filter",
        allWorkOrders: "Alle Arbeits aufträge",
        workOrderManagement: "Arbeits auftrags verwaltung",
      },
    },
    trainingCenter: {
      topicAnnotation: "Themen kennzeichnung",
      topicList: "Liste der Themen",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Rollen typ",
              keyWord: "Schlüssel wörter",
              pleaseEnterKeywordSearch:
                "Bitte geben Sie die Stichworts uche ein",
              query: "Abfrage",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Diese Funktion ist noch nicht geöffnet. Bitte wenden Sie sich bei Bedarf an den Systemad minis trator",
          pleaseSelectAQuestion: "Bitte wählen Sie die Frage!",
          recommendedSimilarSentencesLargeModel:
            "Empfohlener ähnlicher Satz (großes Modell)",
          selectAll: "Alle ausgewählt",
          add: "Hinzufügen",
          changeBatch: "Ändern Sie eine Charge",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Geschäfts arbeitzeit",
      localDataUpload: "Hochladen lokaler Daten",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Montieren Sie die Menü liste basierend auf den aktuellen Benutzer berechtigungen",
      sessionSettings: "Sitzungs einstellungen",
      logoSettings: "Logo-Einstellungen",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Willkommen zurück! Bitte geben Sie Ihre Daten ein",
        mailbox: "Mailbox",
        password: "Passwort",
        signIn: "Einloggen",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin:
            "Die aktuelle IP erlaubt keine Anmeldung",
          welcomeToLogin: "Willkommen bei der Anmeldung",
          pursuingExcellenceAndLeadingChange:
            "Streben Sie nach Exzellenz und führen Sie den Wandel",
          intelligentPlatform: "Intelligente Plattform",
        },
        virtualLogin: { index: { pleaseSelect: "Bitte wählen Sie" } },
      },
      oauth: {
        illegalCall: "Illegaler Anruf",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Willkommen zurück! Klicken Sie auf die Schaltfläche unten, um zur Identitäts authentifizierungs plattform zu springen",
        loggingIn: "Einloggen...",
        unifiedIdentityAuthenticationLogin:
          "Einheitliche Identitäts authentifizierungs anmeldung",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Regel test: Einige Regeln können nicht mit Regel tests verwendet werden, z. B. Sprach geschwindigkeit, Lautstärke, Daten usw.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Erfahren Sie mehr",
          enteringTheSystem: "Zugang zum System",
        },
      },
      index: {
        cancel: "Stornierung",
        enteringTheSystem: "Zugang zum System",
        close: "Schließen",
        enterSystemApplication: "Geben Sie die System anwendung ein",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Klicken Sie auf "System eingeben", um zu einer aktivierten System-App zu springen',
      },
    },
    permissionManagement: {
      employeeManagement: "Mitarbeiter verwaltung",
      roleManagement: "Rollen management",
      permissionManagement: "Verwaltung von Berechtigungen",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Gleich",
            contain: "Enthält",
            doesNotContain: "Nicht enthalten",
            applicationSystem: "Anwendungs systeme",
            intelligentQualityInspection:
              "Intelligente Qualitäts prüfung/intelligente Sitzungs analyze",
            salesEmpowerment: "Verkaufs ermächtigung",
            dataSourceType: "Typ der Datenquelle",
            filterCriteria: "Screening-Bedingungen",
          },
        },
        index: {
          dataConnection: "Docking von Daten",
          savingSucceeded: "Speichern Sie erfolgreich",
          dataIsolation: "Daten isolierung",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Nach dem Öffnen können Sie den Daten bereich jedes Anwendungs systems für den Zugriff auf Anrufe und Unternehmens-WeChat-Sitzungen festlegen. Wenn der Schalter nicht eingesc haltet ist, ist jedes System mit der vollen Datenmenge verbunden",
          accessRules: "Zugangs regeln",
          preservation: "Speichern",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Name der Anwendung",
            accessRights: "Zugriffs rechte",
            inAppPermissionSettings: "Einstellungen für In-App-Berechtigungen",
            setUp: "Einstellungen",
            applicationAccess: "Zugriff auf Apps",
            newRole: "Neue Rollen",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Gesprächs details" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Gesprächs details",
          caseDetails: "Fall details",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Sichtbare Mitarbeiter",
            newCourse: "Neuer Kurs",
            editCourse: "Kurse bearbeiten",
            courseName: "Name des Kurses",
            courseIntroduction: "Einführung in den Kurs",
            courseType: "Arten von Kursen",
            courseClassification: "Kurs klassifizierung",
            courseCover: "Kurs abdeckung",
            learningContent: "Lerninhalte",
            uploadLearningMaterials: "Lernmaterial ien hochladen",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Test",
              preservation: "Speichern",
              cancel: "Stornierung",
              editNodeName: "Knoten namen bearbeiten",
              determine: "Bestimmt",
              individual: "Eine",
              of: "Insgesamt",
              branch: "Punkte",
            },
            configDrawerComponents: {
              pPTParsing: "PPT-Analyse...",
              uploadSuccessful: "Erfolgreich hochgeladen",
              uploadAttachments: "Anhänge hochladen",
              pPTParsingPleaseWait: "PPT-Analyse, bitte warten",
            },
            customerPortraitConfigDrawer: {
              cancel: "Stornierung",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Kann nach dem Löschen nicht wieder hergestellt werden. Möchten Sie dieses Porträt löschen?",
              delete: "Löschen",
              determine: "Bestimmt",
              customerPortrait: "Kunden porträt",
              bindSelectedCharacterPortrait:
                "Binden Sie das ausgewählte Charakter porträt",
              customerProfileDetails: "Details zum Kunden porträt",
              createANewCustomerProfile:
                "Erstellen Sie ein neues Kunden porträt",
            },
            flow: {
              sidebar: { node: "Knoten" },
              useControlButtons: {
                cancellingPleaseWait: "Widerruf, bitte später...",
                redoInProgressPleaseWait: "Wiederherstellen, bitte warten...",
                automaticOrganization: "Automatische Organisation",
                canvasAdaptation: "Adaptive Leinwand",
                delete: "Löschen",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Knoten können sich nicht mit sich selbst verbinden",
              },
            },
            globalConfigDrawer: {
              cancel: "Stornierung",
              determine: "Bestimmt",
              globalConfiguration: "Globale Konfiguration",
              sessionDetection: "Sitzungs erkennung",
              reciprocalRule: "Regeln für die Interaktion",
              robotVoice: "Roboter-Timbre",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Der Schüler knoten hat die Einstellung für Überstunden nicht beantwortet",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Die maximal zulässige Stille des Schülers ohne Antwort (s)",
              promptScriptForFailureToAnswerOverTime:
                "Überstunden ohne Antwort",
              addScript: "Wörter hinzufügen",
            },
            robotTimbreForm: {
              aixianatural: "Ai Xia (Natur)",
              zhishaChat: "Zhisha (Chat)",
              zhiqiangentle: "Zhiqian (sanft)",
              zhimiEmotion: "Zhimi (Emotion)",
              aiShuoNatural: "Ai Shuo (Natur)",
              zhishuoChat: "Zhishuo (Chat)",
              aiHaoAffinity: "Ai Hao (Affinität)",
              zhixiangEmotion: "Zhi xiang (Emotion)",
              robotVoice: "Roboter-Timbre",
              schoolboy: "Junge",
              girlStudent: "Mädchen",
              volume: "Lautstärke",
              tone: "Ton",
              speechSpeed: "Sprech geschwindigkeit",
              test: "Test",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Hallo, ich bin ein intelligenter Trainer und freue mich auf die Kommunikation mit Ihnen",
            },
            sessionDetectionForm: {
              expressionDetection: "Expressions erkennung",
              excessiveDetectionOfModalParticles:
                "Erkennung von zu vielen Tischen",
              theNumberOfRepetitionsIs: "Die Anzahl der Wiederholungen beträgt",
              minus: "Zeit, subtrahieren",
              appear: "Erscheint",
              subtract: "Zeiten, subtrahieren",
              branch: "Punkte",
              addRules: "Regeln erhöhen",
              includingNegativeAngryComplaining:
                "(Einschl ießlich Negativ, Wut, Beschwerde)",
              scorePointsDisplay: "Punkte anzeigen",
              reminderOfSpeakingTooFasttooSlow:
                "Zu schnell/zu langsam Erinnerung",
              scriptViolationReminder: "Erinnerung an Verstöße",
              emotionalReminder: "Emotionale Erinnerung",
              speechSpeedDetection: "Sprach geschwindigkeit erkennung",
              normalSpeechSpeedScore:
                "Normale Punktzahl für Sprech geschwindigkeit",
              speakTooFast: "Zu schnell",
              everyMinuteGreaterThan: "Mehr als jede Minute",
              aWordAppears: "Ein Wort, erscheint",
              speakTooSlowly: "Zu langsam",
              lessThanPerMinute: "Weniger als jede Minute",
              ignoreLessThan: "Ignorieren Sie weniger als",
              aSentenceOfWords: "Satz des Wortes",
              emotionalDetection: "Emotions erkennung",
              emotionalNormalScore: "Emotionale normale Punktzahl",
              negativeEmotions: "Negative Emotionen",
              illegalWordDetection: "Erkennung von Verstößen",
              scriptViolationConfiguration:
                "Verstoß gegen die Sprach konfiguration",
              realTimePromptOnTheFrontEnd: "Front-End-Tipps in Echtzeit",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Fragen und Antworten zur Wissens datenbank",
                  qAMethod: "Fragen und Antworten",
                  numberOfQuestionsAndAnswers:
                    "Anzahl der Fragen und Antworten",
                  scoringRules: "Regeln für die Wertung",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Jede Frage und Antwort ist richtig",
                  branch: "Punkte",
                  successfullySaved: "Speichern Sie erfolgreich",
                },
                pptNode: {
                  verificationPassed: "Verifizierungs bestanden",
                  upload: "Hochladen",
                  supportPptPdfFormatFiles:
                    "Unterstützung für Dateien im PPT-und PDF-Format",
                  uploadSlide: "Folien hochladen",
                  assessmentMethod: "Bewertungs methode",
                  associateSlide: "Assoziierte Folien",
                  standardScript: "Standard-Wörter",
                  successfullySaved: "Speichern Sie erfolgreich",
                },
                robotNode: {
                  robotScript: "Roboter-Wörter",
                  randomScript: "Zufällige Worte",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Wählen Sie eine ähnliche semantische Sprache, und der Roboter sagt zufällig einen von ihnen",
                  customerPortrait: "Kunden porträt",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Wenn das Porträt des Kunden konsistent ist, sagt der Roboter diese Technik",
                  setAsDefaultScript: "Als Standard sprache festlegen",
                  yes: "Ist",
                  no: "Nein",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Diese Technik wird verwendet, wenn der Roboter ein Benutzer-Tag verfehlt",
                  successfullySaved: "Speichern Sie erfolgreich",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Dieser Verzweigung knoten verfügt bereits über eine Standard-Sprach technik. Ersetzen Sie die Standard-Sprache durch die aktuelle Sprach technik?",
                },
                startNode: {
                  paragon: "Erzähl technik",
                  successfullySaved: "Speichern Sie erfolgreich",
                },
                userNode: {
                  verificationPassed: "Verifizierungs bestanden",
                  standardScript: "Standard-Wörter",
                  successfullySaved: "Speichern Sie erfolgreich",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Bitte verbessern Sie die Konfiguration",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Bitte geben Sie ein Dialog beispiel ein",
                  dialogueExample: "Beispiele für Gespräche",
                  generateConversationExamples:
                    "Beispiele für Konversationen generieren",
                  mattersNeedingAttention: "Vorsicht maßnahmen",
                  pleaseEnterThePromptLanguage:
                    "Bitte geben Sie eine Eingabe aufforderung ein",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Dialogs zene",
                  simulateCharacter: "Rolle simulieren",
                  accompanyingTrainees: "Sparring Objekt",
                  selectFromTemplateLibrary:
                    "Wählen Sie aus der Vorlagen bibliothek",
                  templateLibrary: "Vorlagen bibliothek",
                  role: "Rolle",
                  scene: "Szene",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Diashow-Test fenster 【",
              clearContent: "Inhalt löschen",
              close: "Schließen",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI-Generierung prozess",
                scriptStyle: "Sprach stil",
                theMostConversationRounds: "Die meisten Gesprächs runden",
                copyConversation: "Konversation kopieren",
                application: "Anwendung",
                regenerate: "Regenerieren",
                startGenerating: "Starten Sie die Generierung",
                pleaseGenerateAProcess: "Bitte generieren Sie den Prozess",
                copySuccessful: "Erfolgreiche Replikation",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Handels vertreter",
                    customerServicePersonnel: "Kundendienst personal",
                    storeCounter: "Laden zähler",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Generieren Sie eine Volltext rede",
                speaker: "Sprecher",
                scriptStyle: "Sprach stil",
                copyConversation: "Konversation kopieren",
                application: "Anwendung",
                regenerate: "Regenerieren",
                startGenerating: "Starten Sie die Generierung",
                pleaseGenerateAProcess: "Bitte generieren Sie den Prozess",
                operationSuccessful: "Erfolgreiche Operation",
                copySuccessful: "Erfolgreiche Replikation",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Wörter polieren" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Worte können nicht leer sein!",
              copySuccessful: "Erfolgreiche Replikation",
              cancel: "Stornierung",
              copy: "Kopieren",
              replaceTheOriginalText: "Original ersetzen",
              aiGeneration: "Ai-Generierung",
              aiPolishing: "Ai polieren",
              currentStyle: "Aktueller Stil",
              rigorous: "Strenge",
              mild: "Mild",
              lively: "Lebhaft",
              simplicity: "Einfachheit",
              pleaseEnterTheSpeakerRole:
                "Bitte geben Sie die Sprecher rolle ein",
              startGenerating: "Starten Sie die Generierung",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Worte können nicht leer sein!",
              copySuccessful: "Erfolgreiche Replikation",
              aiRewrite: "Ai umschreiben",
              cancel: "Stornierung",
              copy: "Kopieren",
              replaceTheOriginalText: "Original ersetzen",
              currentStyle: "Aktueller Stil",
              rigorous: "Strenge",
              mild: "Mild",
              lively: "Lebhaft",
              simplicity: "Einfachheit",
              pleaseEnterTheSpeakerRole:
                "Bitte geben Sie die Sprecher rolle ein",
              startGenerating: "Starten Sie die Generierung",
            },
          },
          index: {
            verificationPassed: "Verifizierungs bestanden",
            ruleValidation: "Überprüfung der Regeln",
            aIGeneration: "KI-Generierung",
            generateFullTextSpeechScript: "Generieren Sie eine Volltext rede",
            editCourse: "Kurse bearbeiten",
            importConversation: "Konversationen importieren",
            uploadExcelToCompleteDialogueImport:
              "Hochladen von Excel, um die Konversation zu vervollständigen",
            editBasicInformation: "Grundlegende Informationen bearbeiten",
            test: "Test",
            release: "Veröffentlichen",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Neues Etikett" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Kann nach dem Löschen nicht wieder hergestellt werden. Möchten Sie dieses Etikett löschen?",
              delete: "Löschen",
              cancel: "Stornierung",
            },
          },
        },
        index: {
          labelManagement: "Etiketten verwaltung",
          newGroup: "Neue Gruppierung",
          edit: "Bearbeiten",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Kann nach dem Löschen nicht wieder hergestellt werden. Möchten Sie diese Tag-Gruppe löschen?",
          delete: "Löschen",
          cancel: "Stornierung",
          editGroup: "Gruppierung bearbeiten",
          labelGroupName: "Name der Etiketten gruppierung",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Deaktivierte Kamera",
              noCameraDevicesFound: "Kamera-Gerät nicht gefunden",
            },
          },
          cue: {
            index: {
              regenerate: "Regenerieren",
              generate: "Generieren",
              referenceScript: "Referenz",
              mattersNeedingAttention: "Vorsicht maßnahmen",
              examFrequency: "Anzahl der Prüfungen",
              section: "Die",
              timestotal: "Zeiten/insgesamt",
              second: "Zeiten",
              requirementsForPassingTheExam: "Prüfungs anforderungen",
              examScore: "Prüfungs punktzahl ≥",
              branch: "Punkte",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Kein Porträt",
              customerBackground: "Kunden hintergrund",
              customerLabel: "Kunden-Tags",
            },
          },
          flow: {
            index: {
              processReminder: "Prozess aufforderung (",
              score: "Punktzahl",
              TotalScore: "/Gesamt punktzahl",
            },
          },
          index: {
            taskName: "Name der Aufgabe",
            taskStatus: "Aufgaben status",
            whole: "Alle",
            completionStatus: "Abschluss zustand",
            courseClassification: "Kurs klassifizierung",
            practiceMode: "Übungs modus",
            examMode: "Prüfungs modus",
            practiceTime: "Zeit zum Üben",
          },
          ppt: {
            index: {
              nextPage: "Nächste Seite",
              tips: "Tipps",
              continueWithCurrentPage:
                "Fahren Sie mit der aktuellen Seite fort",
              goToTheNextPage: "Zur nächsten Seite",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Nach dem Aufrufen der nächsten Seite können Sie nicht mehr zur vorherigen Seite zurückkehren. Bitte bestätigen Sie, dass die aktuelle Seite vollständig erklärt ist, bevor Sie springen",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Wenn Sie auf die nächste Seite klicken, wird dieser Tipp nicht angezeigt",
            },
            list: { index: { slide: "Diashow" } },
          },
          record: {
            index: {
              unableToRecord: "Kann nicht aufnehmen:",
              creatingARecorderService: "Erstellen eines Recorder-Dienstes",
              turnOffTheRecorderService: "Schließen Sie den Recorder-Dienst",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Der aktuelle Client ist bereits mit dem ASR Websocket-Server verbunden",
              afterProcessingTheLastFrameRestoreTheState:
                "Stellen Sie den Status wieder her, nachdem Sie den letzten Frame verarbeitet haben",
              aSRConnectionClosed: "ASR-Verbindung geschlossen...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Die Aufnahme wird angehalten und verarbeitet, aber Daten befinden sich noch im Cache",
              foundASRChannelClosedRecreateWebsocketLink:
                "Stellen Sie fest, dass der ASR-Kanal geschlossen ist, erstellen Sie den Websocket-Link erneut",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Stellen Sie fest, dass der ASR-Kanal nicht geöffnet ist, und erstellen Sie den Websocket-Link erneut",
            },
            ppt: { hook: { index: { serverError: "Server fehler" } } },
            text: {
              index: {
                pleaseEnter: "Bitte geben Sie ein",
                sendOut: "Senden",
                startPracticing: "Beginnen Sie mit dem Üben",
                startTheExam: "Beginnen Sie mit der Prüfung",
              },
            },
            util: {
              recorded: "Auf gezeichnet",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16-Bit-PCM-SendFrameSize muss ein ganzzahliges Vielfaches von 2 sein",
              pCMSamplingRateShouldNotOccur:
                "Die PCM-Abtastrate sollte nicht auftreten",
              andTheRequiredSamplingRate: "Und die erforderliche Abtastrate",
              atypism: "Inkonsistenzen",
              errorsThatShouldNotOccur: "Fehler, die nicht auftreten sollten:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> Unordentliche Daten, wenn die Anforderungen nicht hoch sind, können Sie </span> direkt verwerfen',
              flower: "Blumen",
              TransferStopped: ": Die Übertragung wurde gestoppt",
            },
            video: { index: { camera: "Kamera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Der aktuelle Client ist bereits mit dem ASR Websocket-Server verbunden",
                    aSRConnectionClosed: "ASR-Verbindung geschlossen...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Stellen Sie fest, dass der ASR-Kanal geschlossen ist, erstellen Sie den Websocket-Link erneut",
                    unableToRecord: "Kann nicht aufnehmen:",
                    aSRChannelClosedReopened:
                      "ASR-Kanal geschlossen, wieder geöffnet",
                    theASRChannelWasNotCreatedStartingNow:
                      "Der ASR-Kanal wurde nicht erstellt. Erstellen Sie jetzt",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect Rückruf, um den ASR-Kanal zu löschen",
                    creatingARecorderService:
                      "Erstellen eines Recorder-Dienstes",
                    turnOffTheRecorderService:
                      "Schließen Sie den Recorder-Dienst",
                  },
                  util: {
                    recorded: "Auf gezeichnet",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16-Bit-PCM-SendFrameSize muss ein ganzzahliges Vielfaches von 2 sein",
                    pCMSamplingRateShouldNotOccur:
                      "Die PCM-Abtastrate sollte nicht auftreten",
                    andTheRequiredSamplingRate:
                      "Und die erforderliche Abtastrate",
                    atypism: "Inkonsistenzen",
                    errorsThatShouldNotOccur:
                      "Fehler, die nicht auftreten sollten:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> Unordentliche Daten, wenn die Anforderungen nicht hoch sind, können Sie </span> direkt verwerfen',
                    flower: "Blumen",
                    TransferStopped: ": Die Übertragung wurde gestoppt",
                  },
                },
                index: { failInSend: "Senden fehl geschlagen" },
              },
              index: {
                startRecording: "Aufnahme starten",
                endRecording: "Aufnahme beenden",
                startPracticing: "Beginnen Sie mit dem Üben",
                startTheExam: "Beginnen Sie mit der Prüfung",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Die verbleibende Anzahl der aktuellen Prüfungen ist",
              totalScriptScore: "Gesamt punktzahl der Wörter:",
              totalEmotionalScore: "Emotionale Gesamt punktzahl:",
              totalScoreOfExpression: "Drücken Sie die Gesamt punktzahl aus:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Die aktuellen Ergebnisse der Aufgaben bewertung müssen manuell überprüft werden. Nach Abschluss der Überprüfung werden Sie über die endgültigen Ergebnisse informiert. Bitte haben Sie etwas Geduld",
              areYouSureToSubmit: "Wird die Einreichung bestätigt?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Die Übung ist beendet, wird die Einreichung bestätigt?",
              theExamHasEndedAreYouSureToSubmit:
                "Die Prüfung ist beendet, wird die Einreichung bestätigt?",
              completed: "Abgeschlossen",
              page: "Seite",
              pageCount: "Gesamtzahl der Seiten",
              topic: "Frage",
              totalNumberOfQuestions: "Gesamtzahl der Fragen",
              totalScore: "Gesamt punktzahl",
              branch: "Punkte",
              accumulatedCompletionProgress:
                "Kumulativer Abschluss fortschritt",
              accumulatedPracticeDuration: "Kumulative Übungs dauer",
              lastPauseExerciseResults: "Letzte Pause-Ergebnisse der Übungen",
              exerciseInstructions: "Anweisungen zum Üben",
              examInstructions: "Beschreibung der Prüfung",
              halfwayPausePracticeResults:
                "Halbzeit pause-Ergebnisse der Übungen",
              tips: "Tipps",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Nach dem Verlassen wird diese Übung nicht in die Punktzahl einbezogen. Wird der Rückzug bestätigt?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Es ist nicht gestattet, sich während der Prüfung zurück zuziehen. Bestätigen Sie die Einreichung?",
              pleaseOperateWithCaution: "Mal, bitte vorsichtig arbeiten)",
              restart: "Von vorne anfangen",
              returnToLearning: "Zurück zum Lernen",
              continuePracticing: "Üben Sie weiter",
              continuingTheExam: "Fortsetzung der Prüfung",
              return: "Zurück",
              notSubmittedTemporarily: "Nicht einreichen",
              iGotIt: "Ich weiß.",
              confirmExit: "Bestätigen Sie den Ausstieg",
              confirmSubmission: "Bestätigen Sie die Einreichung",
              signOut: "Rückzug",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Aussetzung",
              submit: "Einreichung",
              remainingTimeOfThisQuestion: "Verbleibende Zeit für diese Frage",
              SecondsLeftUntilTheEndTime: "Noch 10 Sekunden bis zum Ende",
              remainingTime: "Verbleibende Zeit",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Der Sprach inhalt des Benutzers wird nicht erkannt. Versuchen Sie es erneut",
          },
          wave: {
            index: {
              continueRecording: "Fortsetzung der Aufnahme",
              submit: "Einreichung",
              endRecording: "Aufnahme beenden",
              suspendRecording: "Aufnahme pausieren",
              startPracticing: "Beginnen Sie mit dem Üben",
              startTheExam: "Beginnen Sie mit der Prüfung",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Dialog modus",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Zu viel Lärm, bitte schalten Sie die ruhige Umgebung um",
              normalNoiseDetection: "Normale Geräusch erkennung",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Die Lautstärke ist zu gering, bitte erhöhen Sie die Lautstärke",
              volumeDetectionIsNormal: "Lautstärke erkennung normal",
              noiseDetection: "Geräusch erkennung",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Bitte bleiben Sie ruhig und nehmen Sie 3s Umgebungs geräusche auf",
              endRecording: "Aufnahme beenden",
              startRecording: "Aufnahme starten",
              volumeDetection: "Lautstärke erkennung",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Bitte lesen Sie "Lautstärke test jetzt" mit normaler Lautstärke',
              playLastDetectedAudio:
                "Spielen Sie das zuletzt erkannte Audio ab",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Bitte lesen Sie die Anforderungen sorgfältig durch, bevor Sie beginnen",
              return: "Zurück",
              iGotIt: "Ich weiß.",
              timeLimitForSingleQuestionAnswering:
                "Begrenzung der Zeit für die Beantwortung einer einzelnen Frage",
              totalTimeLimit: "Gesamt zeitlimit",
              unlimitedTime: "Unbegrenzte Zeit",
              stillNeedToCompleteTheTaskInTotal:
                "Es ist immer noch erforderlich, um die Aufgabe zu erledigen",
              practice: "Übungen",
              second: "Zeiten",
              taskScore: "Eine Aufgabe Punktzahl ≥",
              branch: "Punkte",
              or: "Oder",
              examScore: "Prüfungs punktzahl ≥",
              remainingExamTimes: "Anzahl der verbleibenden Prüfungen",
              totalScore: "Gesamt punktzahl",
              Script: "(Wörter",
              scoreEmotion: "Geteilte Emotionen",
              fractionExpression: "Teil ausdruck",
              points: "Punkte)",
              standardScore: "Compliance-Punkte",
              excellentScore: "Aus gezeichnete Punkte",
              exerciseInstructions: "Anweisungen zum Üben",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Um die Fragen reibungslos zu beantworten, schließen Sie bitte zuerst den Geräte test ab:",
              camera: "Kamera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Sich im Video sehen zu können bedeutet, dass die Kamera normal ist",
              microphone: "Mikrofon",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Bitte sprechen Sie mit dem Mikrofon. Wenn Sie das Wellenmuster sehen, ist das Mikrofon normal",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Leer",
                  copyTo: "Kopieren nach",
                  classification: "Klassifizierung",
                  rename: "Umbenennen",
                  newlyBuild: "Neu",
                  categoryParent: "Überge ordnete Klassifizierung",
                  moveTo: "Verschieben Sie zu",
                  name: "Name",
                },
              },
              util: {
                rename: "Umbenennen",
                moveTo: "Verschieben Sie zu",
                copyTo: "Kopieren nach",
                delete: "Löschen",
                courseClassification: "Kurs klassifizierung",
                newlyBuild: "Neu",
              },
            },
          },
          edit: { index: { curriculum: "Kurs" } },
          index: {
            learn: "Lernen",
            practice: "Übe",
            examination: "Prüfung",
            curriculum: "Kurs",
            courseIntroduction: "Einführung in den Kurs:",
            createdOn: "Zeit zum Erstellen:",
            published: "Gepostet",
            tasks: "Aufgaben",
            unpublishedTasks: "Nicht ver öffentlichte Aufgaben",
            task: "Aufgaben",
            operation: "Betrieb",
            learningMaterials: "Lernmaterial ien",
            goPractice: "Übe",
            publishTask: "Aufgaben veröffentlichen",
            taskData: "Aufgaben daten",
            edit: "Bearbeiten",
            copy: "Kopieren",
            successfullyCopied: "Erfolgreiche Replikation",
            delete: "Löschen",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Nach dem Löschen werden alle Aufgaben daten in diesem Kurs gelöscht. Wird das Löschen des Kurses bestätigt?",
            courseName: "Name des Kurses",
            creationTime: "Zeit erstellen",
            releaseStatus: "Status veröffentlichen",
            whole: "Alle",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Kein Ton erkannt",
              voice: "Stimme",
              text: "Text",
              referenceScript: "Referenz:",
              clickOnTheRightSideToStartRecording:
                "Klicken Sie rechts, um die Aufnahme zu starten",
              sendOut: "Senden",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Der aktuelle Client ist bereits mit dem ASR Websocket-Server verbunden",
                afterProcessingTheLastFrameRestoreTheState:
                  "Stellen Sie den Status wieder her, nachdem Sie den letzten Frame verarbeitet haben",
                aSRConnectionClosed: "ASR-Verbindung geschlossen...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Die Aufnahme wird angehalten und verarbeitet, aber Daten befinden sich noch im Cache",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Stellen Sie fest, dass der ASR-Kanal geschlossen ist, erstellen Sie den Websocket-Link erneut",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Stellen Sie fest, dass der ASR-Kanal nicht geöffnet ist, und erstellen Sie den Websocket-Link erneut",
                unableToRecord: "Kann nicht aufnehmen:",
                creatingARecorderService: "Erstellen eines Recorder-Dienstes",
                turnOffTheRecorderService: "Schließen Sie den Recorder-Dienst",
              },
              util: {
                recorded: "Auf gezeichnet",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16-Bit-PCM-SendFrameSize muss ein ganzzahliges Vielfaches von 2 sein",
                pCMSamplingRateShouldNotOccur:
                  "Die PCM-Abtastrate sollte nicht auftreten",
                andTheRequiredSamplingRate: "Und die erforderliche Abtastrate",
                atypism: "Inkonsistenzen",
                errorsThatShouldNotOccur:
                  "Fehler, die nicht auftreten sollten:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> Unordentliche Daten, wenn die Anforderungen nicht hoch sind, können Sie </span> direkt verwerfen',
                flower: "Blumen",
                TransferStopped: ": Die Übertragung wurde gestoppt",
              },
            },
            finishTest: {
              index: {
                endTest: "Beenden Sie den Test",
                testResult: "Testergebnisse",
                returnToCanvas: "Zurück zur Leinwand",
                retest: "Wieder testen",
              },
            },
          },
          index: {
            testFromCurrentNode: "Testen Sie vom aktuellen Knoten aus",
            listening: "Im Radio...",
            canvasTesting: "Leinwand-Test",
            endOfProcess: "Ende des Prozesses",
            restart: "Von vorne anfangen",
            start: "Der Anfang",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Analyse weglassen",
                  expandParsing: "Analysieren",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Zu viel Moral",
                  second: "Zeiten",
                  ratingOverview: "Bewertungs übersicht",
                  totalScore: "Gesamt punktzahl",
                  section: "Die",
                  name: "Name",
                  of: "(Insgesamt",
                  people: "Menschen)",
                  scriptScore: "Score",
                  common: "Insgesamt",
                  keyPoints: "Ein wichtiger Punkt,",
                  notReached: "Nicht erreicht,",
                  have: "Ja",
                  violationOfRegulations: "Verstöße",
                  emotionalScore: "Stimmungs punkte",
                  appear: "Erscheint",
                  secondaryNegativeEmotions: "Negative Emotionen",
                  expressionScore: "Ausdrücken der Punktzahl",
                  speakTooFast: "Zu schnell",
                  times: "Mal,",
                  speakTooSlowly: "Zu langsam",
                  average: "Durchschnitt",
                  wordminute: "Wort/Minute",
                },
              },
              score: {
                index: {
                  studentNodeName: "Name des Schüler knotens",
                  keyPointName: "Name der Punkte",
                  slideNodeName: "Name des Diashow-Knotens",
                  dialogue: "Dialog",
                  mainPoints: "Punkt",
                  slide: "Diashow",
                  detectionResult: "Testergebnisse",
                  satisfy: "Treffen",
                  dissatisfaction: "Nicht zufrieden",
                  score: "Punktzahl",
                  script: "Wörter",
                  semantics: "Semantisch",
                  scriptViolation: "Verstoß gegen das Wort",
                  emotion: "Emotionen",
                  expression: "Ausdruck",
                },
              },
              taskTitle: { index: { branch: "Punkte" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Diesmal",
                  lastTime: "Letztes Mal",
                  comprehensiveEvaluation: "Umfassende Bewertung",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Standard-Sprach akkumulation',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Ein Wort, die Schüler folgen der Gesamtzahl der Wörter",
                  aWordspan: "Wörter </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Zeiten </span><br /><span style = "margin-left: 8px">· Die Sprach geschwindigkeit ist zu langsam',
                  timesspan: "Zeiten </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Zu viele Modal wörter',
                  complete: "Vollständige Inhalte",
                  keyHit: "Punkt treffer",
                  languageNorm: "Sprach spezifikationen",
                  fluentExpression: "Fließender Ausdruck",
                  emotionallyPositive: "Positive Emotionen",
                  radarChart: "Radar karte",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Anzahl der vollständigen Prozesse',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· Treffer punkte',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· Haben',
                  dealingWithIllegalWordsspan: "Verstöße gegen Wörter </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· Zu schnell',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Zeiten </span><br /><span style = "margin-left: 8px">· Zu viele Modal wörter',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Negative Emotionen',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Bewertungs rekord",
                  firstRating: "Erste Wertung",
                  totalScore: "Gesamt punktzahl",
                  changeTo: "Ändern Sie in",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Phase der Kommunikation",
                  communicationObjectives: "Kommunikation ziele",
                  scoringCriteria: "Bewertungs kriterien",
                  suggestionsForImprovement: "Vorschläge zur Förderung",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Verbleibende Zeiten:",
                  second: "Zeiten",
                  doItAgain: "Wieder",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Der Browser unterstützt die Audio wiedergabe nicht.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Kommentare und Vorschläge",
                  regenerate: "Regenerieren",
                  generate: "Generieren",
                  generationFailed: "Generierung fehl geschlagen",
                },
              },
              resultModal: {
                index: { return: "Zurück", viewDetails: "Details anzeigen" },
              },
            },
            index: {
              reRatingSuccessful: "Erfolgreich neu bewertet",
              reRatingFailed: "Neubewertung fehl geschlagen",
              downloadRecording: "Aufnahme herunter laden",
              downloadText: "Text herunter laden",
              download: "Herunter laden",
              reRating: "Neu bewertet",
              submitARatingAppeal: "Einreichung einer Bewertungs beschwerde",
              reviewSubmission: "Überprüfung und Einreichung",
              saveChanges: "Änderungen speichern",
              previousSentence: "Vorheriger Satz",
              nextSentence: "Der nächste Satz",
              score: "Bewertung",
              scoringSituation: "Score-Situation",
              dialogueAnalysis: "Dialog analyze",
            },
          },
          index: {
            ratingAppealInProgress: "Bewertungs beschwerde",
            operation: "Betrieb",
            wrongQuestionSet: "Falsche Fragens ammlung",
            ratingUpdate: "Bewertung aktualisiert",
            viewDetails: "Details anzeigen",
            curriculum: "Kurs",
            taskName: "Name der Aufgabe",
            taskType: "Arten von Aufgaben",
            score: "Punktzahl",
            scoringResults: "Ergebnis",
            time: "Zeit",
            courseClassification: "Kurs klassifizierung",
            whole: "Alle",
            learningTime: "Zeit zum Lernen",
            historicalLearningData: "Daten zum historischen Lernen",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Übungs pause",
              lastProgress: "Letzter Fortschritt",
              continuePracticing: "Üben Sie weiter",
              practice: "Übungen",
              startPracticing: "Beginnen Sie mit dem Üben",
              historicalData: "Historische Daten",
              courseIntroduction: "Einführung in den Kurs:",
              exerciseDifficulty: "Übung Schwierigkeit sgrad:",
              practiceCompletionRequirements:
                "Anforderungen an den Übungs abschluss:",
              practiceFrequency: "Anzahl der Übungen ≥",
              second: "Zeiten",
              anyScore: "Beliebige Punktzahl ≥",
              branch: "Punkte",
              or: "Oder",
              taskList: "Aufgaben liste",
              practiceTime: "Übungs zeit:",
              to: "Zu",
              unlimitedTime: "Keine zeitliche Begrenzung",
              completionStatus: "Abschluss zustand",
              numberOfExercises: "Anzahl der Übungen",
              maximumScore: "Die höchste Punktzahl",
              operation: "Betrieb",
              enteringLearning: "Eintritt in das Lernen",
              historicalLearningData: "Daten zum historischen Lernen",
              wrongQuestionSet: "Falsche Fragens ammlung",
            },
          },
          index: {
            practiceTasks: "Aufgaben üben",
            examTasks: "Prüfungs aufgaben",
            task: "Aufgaben",
          },
          learn: {
            index: {
              courseName: "Name des Kurses",
              courseIntroduction: "Einführung in den Kurs",
              learningContent: "Lerninhalte",
              downloadLearningMaterials: "Lernmaterial ien herunter laden",
              startPracticing: "Beginnen Sie mit dem Üben",
            },
          },
          test: {
            index: {
              taskList: "Aufgaben liste",
              courseIntroduction: "Einführung in den Kurs:",
              examDifficulty: "Prüfungs schwierigkeiten:",
              requirementsForPassingTheExam: "Prüfungs anforderungen:",
              examScore: "Prüfungs punktzahl ≥",
              branch: "Punkte",
              examTime: "Prüfungszeit:",
              to: "Zu",
              unlimitedTime: "Keine zeitliche Begrenzung",
              completionStatus: "Abschluss zustand",
              completionTimes: "Anzahl der Abgänge",
              maximumScore: "Die höchste Punktzahl",
              operation: "Betrieb",
              immediateExam: "Jetzt die Prüfung",
              history: "Historische Aufzeichnungen",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Referenz" } },
              flow: {
                index: {
                  wrongQuestionItem: "Falsche Fragen (",
                  score: "Punktzahl",
                  TotalScore: "/Gesamt punktzahl",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Server fehler" } },
                  index: {
                    startRecording: "Aufnahme starten",
                    endRecording: "Aufnahme beenden",
                    startPracticing: "Beginnen Sie mit dem Üben",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Bitte geben Sie ein",
                    sendOut: "Senden",
                    startPracticing: "Beginnen Sie mit dem Üben",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Aufnahme starten",
                    endRecording: "Aufnahme beenden",
                    startPracticing: "Beginnen Sie mit dem Üben",
                  },
                  hook: { index: { failInSend: "Senden fehl geschlagen" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Diese falsche Frage Übungs punktzahl",
                  tips: "Tipps",
                  areYouSureToEnd: "Wird das Ende bestätigt?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Übe erneut, ohne die falsche Frage zu beherrschen",
                  continue: "Weiter",
                  returnToTheSetOfIncorrectQuestions:
                    "Zurück zum falschen Fragens atz",
                  end: "Ende",
                  wrongQuestionItem: "Falsche Fragen",
                  numberOfExercisesThisTime: "Anzahl der Übungen",
                  masteringTheSituation: "Meistern Sie die Situation",
                  mastered: "Gemeistert",
                  notMastered: "Nicht gemeistert",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Üben Sie diese Frage erneut",
                  nextQuestion: "Die nächste Frage",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Falsche Frage übung",
                endExercise: "Übungen beenden",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Falsche Frage übung",
                endExercise: "Übungen beenden",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Falsche Frage übung",
                endExercise: "Übungen beenden",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Wörter anzeigen",
                  section: "Die",
                  secondaryDiscourseTechnique: "Zweite Wörter",
                },
              },
              tableItem: {
                count: { second: "Zeiten" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Keine relevanten Informationen gefunden",
                  recallWords: "Rückruf wort",
                  branch: "Punkte",
                  answerIncorrectly: "Falsche Antwort",
                  second: "Zeiten",
                  wrongAnswerRate: "Falsche Antwort rate",
                },
                standardScript: {
                  standardScript: "Standard-Wörter",
                  myScript: "Meine Worte",
                },
                status: {
                  mastered: "Gemeistert",
                  notMastered: "Nicht gemeistert",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "Gesamtzahl der Antworten",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Die falsche Frage wurde geübt",
                },
              },
            },
            index: {
              wrongQuestionList: "Liste der falschen Fragen",
              wrongQuestionSet: "Falsche Fragens ammlung",
              mispracticeExercises: "Falsche Frage übung",
              taskName: "Name der Aufgabe",
              staff: "Mitarbeiter",
              masteringTheSituation: "Meistern Sie die Situation",
              whole: "Alle",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Anzahl der Übungen ≥",
              second: "Zeiten",
              or: "Oder",
              excellentScoreAtAnyOneTime:
                "Jede Punktzahl ist aus gezeichnet (≥",
              points: "Punkte)",
              examScore: "Prüfungs punktzahl ≥",
              branch: "Punkte",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Zufällige gleichmäßige Verteilung",
              assignToDesignatedPersonnel:
                "Zuordnung zu einer bestimmten Person",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Erhöhen Sie die Anzahl der Prüfungen für den Erfolg",
              failedToIncreaseTheNumberOfExams:
                "Erhöhen Sie die Anzahl der fehl geschlagenen Prüfungen",
              increaseTheNumberOfExams: "Erhöhen Sie die Anzahl der Prüfungen",
              selectObjects: "Objekt auswählen",
            },
          },
          executionFilter: { index: { participationRate: "Teilnahme quote" } },
          intelligentReview: {
            index: {
              overallReview: "Gesamt bewertung",
              pleaseEnterTheReviewCriterianotMandatory:
                "Bitte geben Sie den Bewertungs standard ein (nicht erforderlich)",
              commentaryOnSingleSentenceRhetoric: "Ein-Satz-Kommentar",
              realTimeReview: "Echtzeit-Bewertungen",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Durchschnitt liche Prüfungs punktzahl",
            averageExamDurationmin: "Durchschnitt liche Prüfungs dauer (min)",
            accumulatedPracticeDurationmin: "Kumulative Übungs dauer (min)",
            accumulatedPracticeTimes: "Kumulative Übungs zeiten",
            totalScore: "Gesamt punktzahl",
            average: "Durchschnitt liche Punktzahl",
            total: "Gesamtzahl",
            keyHitCount: "Anzahl der Treffer zum Punkt",
            practiceAverageScore: "Übung durchschnitt liche Punktzahl",
            averagePracticeDurationmin: "Durchschnitt liche Übungs dauer (min)",
            topOfTheHighestScore: "Die höchste Punktzahl Top3",
            branch: "Punkte",
            errorProneItemTop: "Fehler anfälmaßnahmen Top 5",
            hitRate: "Trefferquote",
            hitRateOfKeyPointsInScript:
              "Die wichtigsten Punkte der Sprach trefferquote",
            averageScriptScore: "Durchschnitt liche Punktzahl",
            sortByProcess: "Sortieren nach Prozess",
            sortByScore: "Sortieren nach Punktzahl",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Durchschnitt liche Punktzahl auf dieser Seite/Gesamt punktzahl auf dieser Seite",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Durchschnitt liche Punktzahl/Gesamt punktzahl",
            details: "Details",
            viewRules: "Regeln ansehen",
            standardScript: "Standard-Wörter",
          },
          scoreDetailList: {
            section: "Die",
            second: "Zeiten",
            practice: "Übungen",
            examination: "Prüfung",
            notMeetingStandards: "Nicht dem Standard entsprechen",
            meetingStandards: "Erreichen Sie den Standard",
            excellent: "Aus gezeichnet",
            frequency: "Anzahl",
            fraction: "Punktzahl",
            result: "Ergebnisse",
            operation: "Betrieb",
            viewDetails: "Details anzeigen",
          },
          index: {
            common: "Insgesamt",
            people: "Menschen",
            staff: "Mitarbeiter",
            notMeetingStandards: "Nicht dem Standard entsprechen",
            meetingStandards: "Erreichen Sie den Standard",
            excellent: "Aus gezeichnet",
            notStarted: "Nicht gestartet",
            haveInHand: "In Arbeit",
            completed: "Abgeschlossen",
            passed: "Beabschiedet",
            hangInTheAir: "Unvollendet",
            failed: "Nicht bestanden",
            unlimitedTime: "Keine zeitliche Begrenzung",
            to: "Zu",
            maximumScoringCriteria: "Höchste Bewertungs kriterien",
            completionStatus: "Abschluss zustand",
            employeeName: "Name des Mitarbeiters",
            frequency: "Anzahl",
            practice: "Übungen",
            examination: "Prüfung",
            average: "Durchschnitt liche Punktzahl",
            speechCraftAvgScore: "Durchschnitt liche Punktzahl",
            emotionAvgScore: "Durchschnitt liche Punktzahl für Emotionen",
            speedAvgScore: "Drücken Sie die durchschnitt liche Punktzahl aus",
            flowAvgScore: "Prozess durchschnitt liche Punktzahl",
            practiceCostTime: "Kumulative Zeit",
            participateCommitRate: "Compliance-Rate",
            participateExcellentRate: "Aus gezeichnete Rate",
            duration: "Dauer",
            score: "Punktzahl",
            scoringResults: "Ergebnis",
            flowScore: "Abläufe punkten",
            craftScore: "Score",
            emotionScore: "Stimmungs punkte",
            speedScore: "Ausdrücken der Punktzahl",
            contentIntegrity: "Vollständigkeit des Inhalts",
            pointHitRate: "Punkt-Trefferquote",
            emotionNegativeRate: "Negativer Anteil der Emotionen",
            paraPhaSiaRate: "Anteil der Sprach geschwindigkeit",
            toneOvermuchRate: "Übermäßiger Anteil an Tischen",
            maximumScore: "Die höchste Punktzahl",
            highestScoreResult: "Höchste Ergebnisse",
            state: "Zustand",
            averageDurationmin: "Durchschnitt liche Dauer (min)",
            lately: "Vor kurzem",
            time: "Zeit",
            operation: "Betrieb",
            viewDetails: "Details anzeigen",
            wrongQuestionSet: "Falsche Fragens ammlung",
            taskName: "Name der Aufgabe",
            completeRequirements: "Anforderungen erfüllen",
            publishTo: "Objekte veröffentlichen",
            taskTime: "Aufgaben zeit",
            fractionalComposition: "Bruch zusammensetzung",
            totalScore: "Gesamt punktzahl",
            branch: "Punkte",
            Script: "(Wörter",
            scoreEmotion: "Geteilte Emotionen",
            fractionExpression: "Teil ausdruck",
            points: "Punkte)",
            implementationRate: "Teilnahme quote",
            numberOfExecutors: "Anzahl der Teilnehmer",
            numberOfPeopleNotExecuted:
              "Anzahl der nicht teilnehmen den Personen",
            completionRate: "Abschluss rate",
            numberOfCompletedPersonnel: "Anzahl der abgeschlossenen Personen",
            numberOfUnfinishedPersonnel: "Anzahl der unvollendeten Personen",
            complianceRate: "Compliance-Rate",
            numberOfQualifiedPersonnel:
              "Anzahl der Personen, die den Standard erfüllen",
            numberOfNonCompliantIndividuals:
              "Anzahl der Personen, die die Standards nicht erfüllen",
            excellentRate: "Aus gezeichnete Rate",
            participateCompleteRate: "Teilnahme quote",
            numberOfOutstandingIndividuals:
              "Aus gezeichnete Anzahl von Personen",
            numberOfNonOutstandingIndividuals: "Nicht aus gezeichnete Anzahl",
            export: "Exportieren",
            taskData: "Aufgaben daten",
            resultAnalysis: "Analyse der Ergebnisse",
            scoreDetails: "Bewertungs details-",
            dataOverview: "Daten übersicht",
            taskDetail: "Aufgaben details",
            scriptAnalysis: "Sprach analyze",
            flowAnalysis: "Prozess analyze",
            expressionAnalysis: "Expressions analyze",
            emotionAnalysis: "Analyse von Emotionen",
            byTime: "Ansicht der Zeit dimension",
            byNumber: "Ansicht der Anzahl der Dimensionen",
            personData: "Statistik nach Personal",
            numberData: "Datensatz details",
            count: "Nach Anzahl der",
            person: "Nach Anzahl der Personen",
            reachTimes: "Reichweite",
            outOfReachTimes: "Unerreichte Zeiten",
            seeOutOfReach: "Klicken Sie hier, um unberührt zu sehen",
            accumulative: "Kumuliert",
            script: "Wörter",
            times: "Zeiten",
            sentence: "Satz",
            expressionAverage:
              "Drücken Sie die durchschnitt liche Punktzahl aus",
            modalAnalysis: "Analyse von Tischen",
            total: "Insgesamt",
            muchModalAnalysis: "Es gibt zu viele Töne",
            normal: "Normal",
            speedAnalysis: "Sprech geschwindigkeit analyze",
            speedDistribution: "Sprach geschwindigkeit verteilung",
            speakingSpeed: "Sprech geschwindigkeit",
            normalSpeak: "Normale Sprach geschwindigkeit",
            tooFastSpeak: "Zu schnell",
            tooSlowlySpeak: "Zu langsam",
            violationWord: "Verstoß gegen das Wort",
            violationWordAnalysis: "Analyse von Verstößen",
            noData: "Keine",
            emotionalScore: "Stimmungs punkte",
            emotionalDistribution: "Verteilung der Emotionen",
            negativeEmotion: "Negative Emotionen",
            normalEmotion: "Normale Emotionen",
            getDetail: "Details anzeigen",
            barPattern: "Balken diagramm ansicht",
            timeDimension: "Breitengrad ansicht der Zeit",
            timesDimension: "Ansicht der Anzahl der Dimensionen",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Durchschnitt liche Punktzahl",
                  averageScoreOfScript: "Durchschnitt liche Punktzahl",
                  averageEmotionalScore: "Emotionaler Durchschnitt",
                  expressionAverageScore:
                    "Drücken Sie die durchschnitt liche Punktzahl aus",
                  processAverageScore: "Prozess durchschnitt liche Punktzahl",
                  accumulatedDuration: "Akkumulative Dauer",
                  accumulatedTimes: "Kumulative Anzahl",
                  participationComplianceRate: "Teilnahme quote",
                  participationRateForAchievingExcellence:
                    "Teilnahme an der hervorragenden Rate",
                  branch: "Punkte",
                  numberOfPeople: "Anzahl der Personen",
                  averageScoreOfExpression:
                    "Drücken Sie die durchschnitt liche Punktzahl aus",
                  people: "Menschen",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Negative Emotionen" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Durchschnitt liche Punktzahl",
                  branch: "Punkte",
                },
                useSummaryDetail: { keyHitRate: "Punkt-Trefferquote" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Intelligente Bewertungen",
            pleaseImproveTheConfiguration:
              "Bitte verbessern Sie die Konfiguration",
            examResults: "Prüfungs ergebnisse",
            showTheResultsAfterEachExam:
              "Zeigen Sie die Ergebnisse nach jeder Prüfung an",
            showTheResultsAfterTheLastExam:
              "Die Ergebnisse werden nach der letzten Prüfung angezeigt",
            doNotDisplayResults: "Ergebnisse werden nicht angezeigt",
            resultDisplay: "Ergebnis anzeige",
            score: "Punktzahl",
            scoringResults: "Ergebnis",
            allowViewingScoreDetails:
              "Erlaubt, die Details der Punktzahl anzuzeigen",
            viewCanvasConfiguration: "Ansicht der Canvas-Konfiguration",
            synchronizationFailedProcessNodeHasChanged:
              "Synchron isierung fehl geschlagen, Prozess knoten haben Änderungen vorgenommen",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Die Synchron isierung ist erfolgreich. Klicken Sie hier, um den aktualisierten Kurs zu veröffentlichen",
            retrievedTheLatestCanvasConfiguration:
              "Die neueste Leinwand konfiguration wurde gezogen",
            synchronizeCanvasConfiguration: "Synchrone Canvas-Konfiguration",
            scoringMethod: "Bewertungs methode",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Punkte, Prozess {0} Punkte, Wörter {1} Punkte, Emotionen {2} Punkte, Sprech geschwindigkeit {3} Punkte)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Hervorragende Standards müssen höher sein als die Standards",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Der Bewertungs standard muss mehr als 0%, weniger als oder gleich 100% erfüllen",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Der Bewertungs standard muss mehr als 0 Punkte, weniger als oder gleich {0} Punkte erfüllen",
            doRatingsRequireManualReview:
              "Muss die Bewertung manuell überprüft werden?",
            allocationStrategy: "Verteilungs strategie",
            pleaseSelectTheAssignedPersonnel:
              "Bitte wählen Sie die zugewiesene Person",
            pleaseSelectADesignatedPerson:
              "Bitte wählen Sie die angegebene Person aus",
            timeLimitForSingleQuestionAnswering:
              "Begrenzung der Zeit für die Beantwortung einer einzelnen Frage",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Standard mäßig für neu hinzugefügte Mitarbeiter freigegeben",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Die Neubewertung bedeutet, dass sie gemäß den Bewertungs regeln der neuesten Leinwand abgeglichen wird. Die aktuelle Aufgabe hat manuell überprüfte Bewertungs daten, ob die Punktzahl übers chrieben werden soll",
            notCovered: "Nicht abdecken",
            cover: "Abdeckung",
            participationSituation: "Beteiligung situation",
            numberOfParticipants: "Anzahl der Teilnehmer",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Summen der Anzahl der von dieser Aufgabe ausgewählten Übungs objekte",
            sumOfTheNumberOfParticipantsInTheTask:
              "Summe der Anzahl der Personen, die an der Mission teilgenommen haben",
            numberOfCompletedPersonnel: "Anzahl der abgeschlossenen Personen",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Summen der Anzahl der Personen, die die Anforderungen für die Erfüllung der Aufgabe erfüllen",
            numberOfQualifiedPersonnel:
              "Anzahl der Personen, die den Standard erfüllen",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Summen Sie die Anzahl der Personen, die die Aufgaben bewertung erfüllen",
            excellentPersonnel: "Aus gezeichnete Anzahl von Personen",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Erfüllen Sie die Anzahl der Personen mit hervorragenden Aufgaben werten",
            participationRate: "Teilnahme quote",
            numberOfParticipantsNumberOfParticipants:
              "Anzahl der Teilnehmer/Anzahl der Teilnehmer",
            numberOfCompletedNumberOfParticipants:
              "Anzahl der abgeschlossenen Personen/Anzahl der Teilnehmer",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Anzahl der Standards/Anzahl der Teilnehmer",
            excellentNumbernumberOfParticipants:
              "Aus gezeichnete Anzahl/Teilnehmer",
            participationCompletionRate: "Teilnahme quote",
            numberOfCompletedParticipated:
              "Anzahl der abgeschlossenen Personen/Teilnehmer",
            participationComplianceRate: "Teilnahme quote",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Anzahl der Compliance-Teilnehmer/Anzahl der Teilnehmer",
            participationRateForAchievingExcellence:
              "Teilnahme an der hervorragenden Rate",
            accumulatedDuration: "Akkumulative Dauer",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Die Teilnehmer nehmen an der Aufgabe teil",
            averageDuration: "Durchschnitt liche Dauer",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Die Dauer der Teilnehmer an der Aufgabe und die Gesamtzahl der Teilnehmer",
            accumulatedTimes: "Anzahl der Akkumulative",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Summen der Anzahl der Teilnehmer an Aufgaben",
            averageScore: "Durchschnitt liche Punktzahl",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Durchschnitt liche Punktzahl der an der Mission beteiligten Personen",
            maximumScore: "Die höchste Punktzahl",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Die höchste Punktzahl für die an der Mission beteiligten Personen",
            averageScoreOfScript: "Durchschnitt liche Punktzahl",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Die Punktzahl der Schüler/Gesamtzahl der Teilnehmer",
            totalScoreOfScript: "Gesamt punktzahl der Wörter",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Legen Sie die Gesamt punktzahl der Wörter unter dieser Aufgabe fest",
            processAverageScore: "Prozess durchschnitt liche Punktzahl",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Teilnehmer prozess Score/Gesamtzahl der Teilnehmer",
            totalProcessScore: "Gesamt punktzahl des Prozesses",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Die Gesamt punktzahl des Prozesses, der unter dieser Aufgabe festgelegt wurde",
            averageEmotionalScore: "Emotionaler Durchschnitt",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Die emotionale Punktzahl der Schüler/Gesamtzahl der Teilnehmer",
            totalEmotionalScore: "Emotionale Gesamt punktzahl",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Die Gesamt punktzahl der Emotionen unter der Aufgabe gesetzt",
            averageScoreOfExpression:
              "Drücken Sie die durchschnitt liche Punktzahl aus",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Die Schüler drücken die Punktzahl/die Gesamtzahl der Teilnehmer aus",
            totalScoreOfExpression: "Drücken Sie die Gesamt punktzahl aus",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Die Gesamt ausdrucks punktzahl, die unter dieser Aufgabe festgelegt wurde",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Starten Sie die erneute Bewertung. Sie können zum Aufgaben center springen, um den Fortschritt zu überprüfen",
            reRating: "Neu bewertet",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Die Neubewertung bedeutet, dass sie gemäß den Bewertungs regeln der neuesten Leinwand abgeglichen wird und das aktuelle Bewertungs ergebnis abdeckt",
            releaseTime: "Veröffentlichung szeit",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Geprüft",
              pendingReview: "Zu überprüfen",
              enterKeywordSearch: "Stichworts uche eingeben",
            },
            pageRightTable: {
              index: {
                employeeName: "Name des Mitarbeiters",
                systemRating: "System bewertung",
                scoringResults: "Ergebnis",
                duration: "Dauer",
                time: "Zeit",
                state: "Zustand",
                whetherToModifyTheRating:
                  "Ob die Punktzahl korrigiert werden soll",
                finalScore: "Endstand",
                reviewedBy: "Prüfer",
                operation: "Betrieb",
                viewScoreDetails: "Score-Details anzeigen",
                viewStatus: "Status anzeigen",
                whole: "Alle",
                correctionStatus: "Status korrigieren",
                completeReview: "Abschluss prüfung",
                tips: "Tipps",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Die endgültigen Ergebnisse der Auszubildenden unterliegen dem aktuellen endgültigen Überprüfung serge bnis. Bitte bestätigen Sie",
              },
            },
          },
          index: { manualReview: "Manuelle Überprüfung" },
        },
        review: {
          index: {
            employeeName: "Name des Mitarbeiters",
            score: "Punktzahl",
            scoringResults: "Ergebnis",
            duration: "Dauer",
            taskName: "Name der Aufgabe",
            numberOfAppealScripts: "Anzahl der Beschwerden",
            appealTime: "Zeit für Beschwerden",
            processStatus: "Prozess zustand",
            scoreAfterReview: "Punktzahl nach Überprüfung",
            reviewedBy: "Überprüfung",
            operation: "Betrieb",
            viewScoreStatus: "Überprüfen Sie die Punktzahl",
            whole: "Alle",
            manualReview: "Manuelle Überprüfung",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Aufgaben daten üben",
            implementationRate: "Ausführungs rate",
            numberOfExecutors: "Anzahl der Personen, die ausgeführt werden",
            numberOfPeopleNotExecuted: "Anzahl der nicht ausgeführten Personen",
            completionRate: "Abschluss rate",
            numberOfCompletedPersonnel: "Anzahl der abgeschlossenen Personen",
            numberOfUnfinishedPersonnel: "Anzahl der unvollendeten Personen",
            complianceRate: "Compliance-Rate",
            numberOfQualifiedPersonnel:
              "Anzahl der Personen, die den Standard erfüllen",
            numberOfNonCompliantIndividuals:
              "Anzahl der Personen, die die Standards nicht erfüllen",
            excellentRate: "Aus gezeichnete Rate",
            numberOfOutstandingIndividuals:
              "Aus gezeichnete Anzahl von Personen",
            numberOfNonOutstandingIndividuals: "Nicht aus gezeichnete Anzahl",
            examTaskData: "Prüfungs aufgaben Daten",
            published: "Gepostet",
            courses: "Kurse",
            courseData: "Kurs daten",
            practiceTasks: "Aufgaben üben",
            examTasks: "Prüfungs aufgaben",
          },
          practiceSummary: {
            practiceSummary: "Übungs zusammenfassung",
            numberOfPracticeTasks: "Anzahl der Übungs aufgaben",
            numberOfExercises: "Anzahl der Übungen",
            accumulatedPracticeDuration: "Kumulative Übungs dauer",
            complianceRate: "Compliance-Rate",
            excellentRate: "Aus gezeichnete Rate",
            ranking: "Ranking",
          },
          rankingList: {
            numberOfExercises: "Anzahl der Übungen",
            exerciseDuration: "Übungs dauer",
            complianceRate: "Compliance-Rate",
            excellentRate: "Aus gezeichnete Rate",
            diligenceChart: "Fleißige Liste",
            excellentList: "Aus gezeichnete Liste",
            ranking: "Ranking",
            fullName: "Name",
            i: "Ich",
          },
          taskCard: {
            end: "Frist:",
            noDeadline: "Keine Frist",
            second: "Zeiten",
            branch: "Punkte",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Keine Aufgabe",
            completed: "Abgeschlossen",
          },
          filter: { employeeDepartment: "Mitarbeiter abteilung" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Der Export ist erfolgreich. Gehen Sie zum Task Center, um anzuzeigen",
              export: "Exportieren",
            },
          },
          filterConfig: {
            dropdown: { custom: "Anpassen" },
            modal: {
              updateSuccessful: "Erfolgreiches Update",
              successfullySaved: "Speichern Sie erfolgreich",
              setAsACommonReport: "Als allgemeiner Bericht festlegen",
              updateCurrentReport: "Aktualisieren des aktuellen Berichts",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Durchschnitt liche Punktzahl",
                  averageScoreOfScript: "Durchschnitt liche Punktzahl",
                  averageEmotionalScore:
                    "Durchschnitt liche Punktzahl für Emotionen",
                  expressionAverageScore:
                    "Drücken Sie die durchschnitt liche Punktzahl aus",
                  processAverageScore: "Prozess durchschnitt liche Punktzahl",
                  branch: "Punkte",
                },
              },
              comprehensiveEvaluation: { const: { data: "Daten" } },
              doneStatus: {
                index: {
                  practice: "Übungen",
                  examination: "Prüfung",
                  executed: "Wurde ausgeführt",
                  unexecuted: "Nicht ausgeführt",
                  accumulatedExamDuration: "Kumulative Prüfungszeit",
                  examFrequency: "Anzahl der Prüfungen",
                },
              },
              filter: {
                index: {
                  practice: "Übungen",
                  examination: "Prüfung",
                  pleaseCompleteTheRequiredFields:
                    "Bitte perfektionieren Sie die erforderlichen Elemente",
                  staff: "Mitarbeiter",
                  taskType: "Arten von Aufgaben",
                  pleaseSelect: "Bitte wählen Sie",
                  taskName: "Name der Aufgabe",
                },
              },
            },
            index: {
              completionStatus: "Vollendung situation",
              comprehensiveEvaluation: "Umfassende Bewertung",
              abilityGrowthTrend: "Trend zum Kapazitäts wachstum",
              errorProneAnalysis: "Fehler anfällende Punkt analyze",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Anzahl der Aktiven",
            accumulatedPracticeDuration: "Kumulative Übungs dauer",
            averagePracticeDuration: "Durchschnitt liche Übungs dauer",
            people: "Menschen",
            numberOfPracticeTasks: "Anzahl der Übungs aufgaben",
            numberOfExercises: "Anzahl der Übungen",
            exerciseDuration: "Übungs dauer",
            second: "Zeiten",
            avgScore: "Durchschnitt liche Punktzahl",
            speechCraftAvgScore: "Durchschnitt liche Punktzahl",
            emotionAvgScore: "Durchschnitt liche Punktzahl für Emotionen",
            speedAvgScore: "Drücken Sie die durchschnitt liche Punktzahl aus",
            flowAvgScore: "Prozess durchschnitt liche Punktzahl",
            practiceCostTime: "Kumulative Zeit",
            totalPracticeTimes: "Kumulative Anzahl",
            participateCommitRate: "Teilnahme quote",
            participateExcellentRate: "Teilnahme an der hervorragenden Rate",
          },
        },
        index: {
          employeeProfile: "Mitarbeiter porträt",
          overview: "Übersicht",
          personalSignage: "Persönliche Kanban",
          ongoingExercises: "Übungen im Gange",
          ongoingExams: "Prüfungen im Gange",
          practiceTaskProgress: "Aufgaben fortschritt üben",
          examTaskProgress: "Fortschritt der Prüfungs aufgaben",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Bitte wählen Sie Konzept wörter",
              sessionDetectionSettings:
                "Einstellungen für die Sitzungs erkennung",
              successfullySaved: "Speichern Sie erfolgreich",
              abnormalSpeechSpeed: "Abnormale Sprech geschwindigkeit",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Unterstützt benutzer definierte Standards zur Erkennung der Sprech geschwindigkeit, und die Ergebnisse werden als normale Sprech geschwindigkeit, zu schnelle Sprech geschwindigkeit und zu langsame Sprech geschwindigkeit beurteilt. Wenn eine abnormale Sprech geschwindigkeit (zu schnell/zu langsam) auftritt, wird die Punktzahl gemäß der Bewertungs regel abgezogen",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Zu schnell: mehr als jede Minute",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Wörter, die Sprech geschwindigkeit ist zu langsam: weniger als jede Minute",
              charactersIgnoringLessThan: "Wörter, ignorieren Sie weniger als",
              aSentenceOfWords: "Satz des Wortes",
              excessiveModalParticles: "Zu viel Tonalität",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Unterstützt benutzer definierte Tonwörter und legt Regeln für zu viele Tonwörter fest. Wenn zu viele Wörter auftreten, wird die Punktzahl gemäß den Bewertungs regeln abgezogen",
              theNumberOfRepetitionsIs: "Die Anzahl der Wiederholungen beträgt",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "In der zweiten Zeit wird festgestellt, dass es zu viele Wörter gibt",
              definitionOfSensitiveViolations:
                "Definition von sensiblen Verstößen",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Unterstützt das benutzer definierte Vokabular für illegale Wörter, um fest zustellen, ob in einem Satz ein illegales Wort angezeigt wird. Wenn ein Verstoß auftritt, wird die Punktzahl gemäß der Bewertungs regel abgezogen",
              violationWords: "Verstoß gegen das Wort:",
              emotionalDetection: "Emotions erkennung",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Die Emotions erkennung wird gemäß der systematischen Stimmungs modell dialog technik durchgeführt, und die Ergebnisse werden als normale Emotionen und negative Emotionen (einschl ießlich Negativität, Wut und Beschwerden) beurteilt. Wenn die Stimmung negativ ist, werden die Punkte gemäß den Bewertungs regeln abgezogen",
              preserve: "Speichern",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Bewertungs dimension",
              ratingPoints: "Bewertungs punkte",
              explain: "Beschreibung",
              type: "Typ",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Zufällige Daten" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Standard-Sprach akkumulation',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Ein Wort, die Schüler folgen der Gesamtzahl der Wörter",
                  aWordspan: "Wörter </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Zeiten </span><br /><span style = "margin-left: 8px">· Die Sprach geschwindigkeit ist zu langsam',
                  timesspan: "Zeiten </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Zu viele Modal wörter',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Die Punktzahl ist" },
                  item: {
                    violationOnceReduced: "1 Verstoß",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Abnormale Sprach geschwindigkeit (einschl ießlich zu schnelle und zu langsame Sprach geschwindigkeit) 1 Mal reduziert",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "1 Mal zu viel Ton",
                    oneNegativeDecreaseInEmotions:
                      "Negative Emotionen werden 1 Mal reduziert",
                    branch: "Punkte",
                  },
                  wrap: { name: "Name", preserve: "Speichern" },
                },
              },
              index: {
                complete: "Vollständige Inhalte",
                keyHit: "Punkt treffer",
                accurateFollowupReading: "Folgen Sie genau",
                languageNorm: "Sprach spezifikationen",
                speechSpeed: "Sprech geschwindigkeit",
                modalParticles: "Wortlaut",
                emotionallyPositive: "Positive Emotionen",
                scoringModel: "Bewertungs modell",
                successfullyModified: "Erfolgreich modifiziert",
                newSuccessfullyCreated: "Neuer Erfolg",
              },
            },
            index: {
              scoringModel: "Bewertungs modell",
              newModel: "Neues Modell",
              model: "Modell",
              type: "Typ",
              operation: "Betrieb",
              preview: "Vorschau",
              edit: "Bearbeiten",
            },
          },
          index: {
            scoringModel: "Bewertungs modell",
            ratingDimension: "Bewertungs dimension",
            sessionDetectionSettings:
              "Einstellungen für die Sitzungs erkennung",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Bitte wählen Sie",
                hit: "Treffer",
                misses: "Fehlschlag",
              },
            },
          },
          index: {
            duration: "Dauer",
            processScore: "Abläufe punkten",
            branch: "Punkte",
            contentCompleteness: "Vollständigkeit des Inhalts",
            scriptScore: "Score",
            keyHitRate: "Punkt-Trefferquote",
            emotionalScore: "Stimmungs punkte",
            negativeProportionOfEmotions: "Negativer Anteil der Emotionen",
            expressionScore: "Ausdrücken der Punktzahl",
            theProportionOfAbnormalSpeechSpeed:
              "Anteil der Sprach geschwindigkeit",
            excessiveProportionOfModalParticles:
              "Übermäßiger Anteil an Tischen",
            learningRecordData: "Daten zum Lernen von Aufzeichnungen",
            staff: "Mitarbeiter",
            curriculum: "Kurs",
            mainPoints: "Punkt",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Tabelle",
                        lineChart: "Linien diagramm",
                        barChart: "Histogramm",
                        pieChart: "Kuchen diagramm",
                      },
                      index: { switchingTypes: "Typ wechseln" },
                    },
                  },
                  index: {
                    saveSuccessful: "Speichern Sie erfolgreich",
                    editChart: "Bearbeiten von Diagrammen",
                    chartName: "Name des Diagramms",
                    deleteSuccessful: "Löschen erfolgreich",
                    refresh: "Aktualisieren",
                    export: "Exportieren",
                    areYouSureToDeleteIt: "Sicher zu löschen?",
                    delete: "Löschen",
                    viewFilteringCriteria:
                      "Überprüfen Sie die Filter bedingungen",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Der Export ist erfolgreich. Gehen Sie zum Task Center, um anzuzeigen",
              },
            },
            header: {
              index: {
                saveSuccessful: "Speichern Sie erfolgreich",
                deleteSuccessful: "Löschen erfolgreich",
                editReport: "Berichte bearbeiten",
                reportName: "Name des Berichts",
                refresh: "Aktualisieren",
                areYouSureToDeleteIt: "Sicher zu löschen?",
                delete: "Löschen",
                exportToExcel: "Exportieren von Excel",
                exportSnapshot: "Snapshots exportieren",
                export: "Exportieren",
                authority: "Berechtigungen",
                permissionSettings: "Einstellungen für Berechtigungen",
                reportVisiblePermissions:
                  "Sichtbare Berechtigungen für Berichte",
                visibleToEveryone: "Sichtbar für alle",
                onlyVisibleToOneself: "Nur für sich sichtbar",
                designatedPersonnelVisible:
                  "Die angegebene Person ist sichtbar",
                pleaseSelect: "Bitte wählen Sie",
                reportDataPermissions: "Berechtigung für Berichts daten",
                restrictedByUserPermissions:
                  "Einges chränkt durch Benutzer berechtigungen",
                visiblePeopleAreTheSame:
                  "Es ist zu sehen, dass die Leute gleich sind",
              },
            },
            titleFooter: {
              index: {
                addReport: "Neuer Bericht",
                reportName: "Name des Berichts",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Der Export ist erfolgreich. Gehen Sie zum Task Center, um anzuzeigen",
            dataQueryInProgress: "Daten abfrage...",
            customKanban: "Benutzer definiertes Kanban",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Tabelle",
                lineChart: "Linien diagramm",
                barChart: "Histogramm",
                pieChart: "Kuchen diagramm",
              },
              index: {
                successfullyAdded: "Erfolg hinzufügen",
                viewSQL: "Sql ansehen",
                export: "Exportieren",
                addToReport: "Zum Bericht hinzufügen",
              },
            },
            refer: {
              hooks: {
                data: "Daten",
                interrogativeSentence: "Frage",
                historicalQuery: "Historische Abfrage",
              },
              index: {
                copySuccessful: "Erfolgreiche Replikation",
                copy: "Kopieren",
              },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Nicht dem Standard entsprechen",
                        meetingStandards: "Erreichen Sie den Standard",
                        excellent: "Aus gezeichnet",
                        notStartedYet: "Nicht gestartet",
                        inProgress: "In Arbeit",
                        completed: "Abgeschlossen",
                        incomplete: "Unvollendet",
                      },
                    },
                  },
                  const: {
                    taskName: "Name der Aufgabe",
                    department: "Abteilung",
                    studentName: "Studenten name",
                    scoringResults: "Ergebnis",
                    taskCompletionStatus: "Status der Aufgaben erfüllung",
                  },
                  index: {
                    pleaseSelectADimension: "Bitte wählen Sie die Dimension",
                  },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Geben Sie @ ein, um den Bomben rahmen für die Dimensions auswahl zu öffnen",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Bitte geben Sie die Anfrage anweisung ein, um direkt abzufragen",
                query: "Abfrage",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Der Export ist erfolgreich. Gehen Sie zum Task Center, um anzuzeigen",
            dataQueryInProgress: "Daten abfrage...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Stichworts uche",
                previous: "Zurück",
                next: "Nächste",
                theObjectThatTriggersTheScrollingEventIs:
                  "Das Objekt, das das Scroll-Ereignis auslöst, ist",
                userScrolling: "Benutzer-Scrollen",
              },
            },
            summary: {
              index: {
                reminder: "Eingabe aufforderung:",
                edit: "Bearbeiten",
                name: "Name",
                hintLanguage: "Eingabe aufforderung",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Meinungs extraktion" },
            },
            outline: {
              index: {
                numberOfKeywords: "Anzahl der Schlüssel wörter",
                speaker: "Sprecher",
                speechDuration: "Lange Sprechzeit",
                proportionOfSpeaking: "Sprach anteil",
                maximumSpeechDuration: "Die längste Redezeit",
                numberOfQuestions: "Anzahl der Fragen",
                averageSpeechSpeed: "Durchschnitt liche Sprach geschwindigkeit",
                conversationalActions: "Sitzungs aktion",
                contentSummary: "Inhalts zusammenfassung",
                generateSummary: "Zusammenfassung generieren",
                keywordCloud: "Schlüssel wörter Wort wolke",
              },
            },
          },
          index: {
            outline: "Zusammenfassung",
            viewpoint: "Meinung",
            contentSummary: "Inhalts zusammenfassung",
            viewpointExtraction: "Meinungs extraktion",
            summaryOfKeyPoints: "Zusammenfassung der Punkte",
            videoAnalysis: "Video analyze",
          },
        },
        list: {
          index: {
            delete: "Löschen",
            uploadSuccessful: "Erfolgreich hochgeladen",
            videoList: "Liste der Videos",
            uploadVideo: "Video hochladen",
            videoName: "Video Name",
            uploadTime: "Upload zeit",
            endTime: "Endzeit",
            analyzeProgress: "Fortschritt analysieren",
            haveInHand: "In Arbeit",
            completed: "Abgeschlossen",
            fail: "Scheitern",
            toBegin: "Warten auf den Anfang",
            operation: "Betrieb",
            see: "Anzeigen",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Arbeitszeit hinzufügen" },
        },
      },
      index: {
        businessWorkingHours: "Geschäfts arbeitzeit",
        newlyBuild: "Neu",
        name: "Name",
        describe: "Beschreibung",
        operation: "Betrieb",
        edit: "Bearbeiten",
        delete: "Löschen",
        editBusinessWorkingHours:
          "Bearbeiten Sie die Arbeitszeit des Geschäfts",
        newBusinessWorkingHours: "Arbeitszeit für neue Unternehmen",
        workingHours: "Arbeitszeit",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Intelligente Extraktion",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Haben Sie Ihre Stornierung bestätigt? Nach der Stornierung kann es nicht wieder hergestellt werden",
        },
      },
    },
    accountManagement: {
      securitySetting: "Sicherheits einstellungen",
      accountManagement: "Konto verwaltung",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Zu",
            switchToIPAddressMode: "Wechseln Sie in den IP-Adress modus",
            switchToIPSegmentMode: "Wechseln Sie zum IP-Segment-Modus",
          },
        },
      },
      index: {
        saveSuccessful: "Speichern Sie erfolgreich",
        pleaseImproveTheConfiguration: "Bitte verbessern Sie die Konfiguration",
        securitySetting: "Sicherheits einstellungen",
        employeeLoginIPRestrictions:
          "IP-Beschränkungen für die Anmeldung von Mitarbeitern",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Beschränken Sie den IP-Bereich, in dem sich Mitarbeiter anmelden können, und die Anmeldung ist über den Bereich hinaus nicht zulässig. Es ist nur für Websites wirksam, mobile Apps sind nicht einges chränkt, Admini strato ren sind nicht einges chränkt.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Geben Sie beim Hinzufügen oder Ändern das IP-Segment des externen Netzwerks (dasselbe C-Segment) oder eine feste IP-Adresse des externen Netzwerks ein.",
        allowLoginIPRange: "IP-Bereich, in dem die Anmeldung zulässig ist",
        preserve: "Speichern",
      },
    },
    coachAdminEntry: { speechRecognition: "Spracher kennung" },
    billingCenter: {
      template: {
        details: "Details",
        languageType: "Arten von Sprachen",
        aSREngine: "ASR Motoren",
        voiceQualityQuota: "Sprach qualitäts prüfungs kontingent",
        realtimeData: "Daten in Echtzeit",
        calculatedByDay: "Berechnet nach Tag",
      },
      component: {
        aSRName: "ASR-Name",
        language: "Sprache",
        remainingQuota: "Rest quote",
        hours: "Stunden",
        usedQuota: "Ausgelaufte Quote",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully:
          "Die Operation wurde erfolgreich gespeichert!",
        deletedSuccessfully: "Löschen erfolgreich!",
        pleaseSelectLanguage: "Bitte wählen Sie die Sprache",
        pleaseConfigureRules: "Bitte konfigurieren Sie die Regeln",
        updateSuccessful: "Das Update war erfolgreich!",
        operationSuccessful: "Die Operation ist erfolgreich!",
      },
      template: {
        aSRLanguage: "ASR-Sprache",
        pleaseSelect: "Bitte wählen Sie",
        model: "Modell",
        pleaseSelectLanguage: "Bitte wählen Sie die Sprache",
        delete: "Löschen",
        newASRModel: "Neues ASR-Modell",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Wettbewerbs fähige Produkt analyze" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "E-Commerce-Kommentar-Filter",
        allComments: "Alle Kommentare",
        ecommerceReviewManagement: "E-Commerce-Kommentar verwaltung",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Social Media Review Filter",
        allComments: "Alle Kommentare",
        socialMediaReviewManagement: "Verwaltung sozialer Medien kommentare",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Wettbewerbs fähige Produkt analyze",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Name",
        nickname: "Spitzname",
        name: "Name",
        email: "Konto (E-Mail)",
        mobile: "Telefon",
        agentId: "Arbeits nummer",
        position: "Post",
        role: "Rolle",
        actions: "Betrieb",
        createUser: "Neue Mitarbeiter",
        editUser: "Mitarbeiter bearbeiten",
        account: "Konto",
        originPassword: "Anfangs passwort",
        newPassword: "Neues Passwort",
        resumePassword: "Passwort bestätigen",
        userGroup: "Gruppe der Mitarbeiter",
        authorizationGroup: "Berechtigung gruppen",
        resetPassword: "Passwort zurücksetzen",
      },
      userGroups: {
        id: "ID",
        name: "Name",
        userCount: "Anzahl der Mitarbeiter",
        actions: "Betrieb",
        createUserGroup: "Neue Mitarbeiter gruppe",
        editUserGroup: "Mitarbeiter gruppe bearbeiten",
        member: "Mitglieder",
      },
      role: {
        id: "ID",
        name: "Name",
        actions: "Betrieb",
        all: "Alle",
        taskName: "Aufgaben",
        readTask: "Aufgaben ansicht",
        manageTemplate: "Verwaltung von Vorlagen",
        manualInspection: "Manuelle Überprüfung",
        readResultAll: "Ergebnisse anzeigen",
        readResultPersonal: "Ergebnis ansicht (persönlich)",
        readProcess: "Überprüfen Sie den Qualitäts prüfungs prozess",
        readReport: "Statistiken und Berichte",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Datum",
        taskName: "Name",
        size: "Größe",
        status: "Zustand",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Name",
            remark: "Beschreibung",
            actions: "Betrieb",
          },
          checkPoint: {
            name: "Name",
            remark: "Beschreibung",
            type: "Typ",
            gradeType: "Bewertungs methode",
            conditionLogic: "Regeln",
            predeterminedScore: "Punktzahl",
            weight: "Gewichte",
            status: "Zustand",
            actions: "Betrieb",
            switchOn: "Aktivieren",
            switchOff: "Deaktivieren",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Zeit",
            customer: "Kunden",
            customerPhone: "Kunden telefon",
            callType: "Art des Anrufs",
            source: "Quelle",
            voiceRecording: "Anruf aufzeichnung",
            graded: "Bewertung",
            openingGreeting: "Eröffnungs grüße",
          },
          checkPointList: {
            name: "Qualitäts prüf punkt",
            type: "Typ",
            deductScore: "Punktzahl",
            isHit: "Schlager-Situation",
            status: "Überprüfung",
            finalDeductScore: "Punkte abzug bestätigen",
            hit: "Treffer",
            notHit: "Fehlschlag",
          },
        },
      },
    },
    custom: { keywords: "Schlüssel wörter des Dialogs" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Systeme in stellungen" },
      systemIntegration: {
        text: "System integration",
        subTitle: {
          udesk: "Udesk-Kundendienst system",
          udeskWhite: "Kundendienst system",
          wechat: "Enterprise WeChat",
          taobao: "Taobao tausend Kühe",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Qualitäts prüfung Feld verwaltung" },
      customerField: { text: "Kunden feld" },
      speechRecognition: { text: "Spracher kennung" },
      user: { text: "Mitarbeiter verwaltung" },
      userGroups: { text: "Management von Mitarbeiter gruppen" },
      role: { text: "Rollen management" },
      dataMigration: { text: "Daten migration" },
      taskCenter: { text: "Aufgaben zentrum" },
      recordUpload: { text: "Hochladen lokaler Aufnahmen" },
      anomalousData: { text: "Ungewöhnliche Daten" },
      systemLog: { text: "Nachrichten protokoll" },
      msgCenter: { text: "Nachrichten zentrum" },
      wechatDocking: { text: "Enterprise-Micro-Docking" },
    },
    tasks: {
      homePage: { text: "Startseite" },
      workbench: { text: "Werkbank" },
      semanticIntelligence: { text: "Semantische Intelligenz" },
      intelligentAnalysis: { text: "Intelligente Analyse" },
      intelligentLabel: { text: "Intelligente Tags" },
      admin: { text: "Verwaltung" },
      callManage: { text: "Anruf verwaltung" },
      dialogueManage: { text: "Konversation management" },
      ticketManage: { text: "Arbeits auftrags verwaltung" },
      dataAnalysis: { text: "Datenanalyse" },
      customerService: { text: "Kundendienst zentrum" },
      clientCenter: { text: "Kunden center" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Trainings zentrum" },
      templates: { text: "Verwaltung von Vorlagen" },
      wechatQaTasks: { text: "Enterprise WeChat Workbench" },
      wechatQaSetting: {
        text: "Konfiguration der Unternehmens-WeChat-Analyse",
      },
      wechatQaChartRecord: { text: "Enterprise WeChat-Sitzung" },
      wechatStaffCenter: { text: "Enterprise Micro-Mitarbeiter zentrum" },
      callAnalysis: { text: "Sitzungs analyze" },
      caseBaseFolder: { text: "Fall bibliothek" },
      coachTask: { text: "Coaching-Aufgaben" },
      teamAnalysis: { text: "Team analyze" },
      customer: { text: "Kunden center" },
      analysisEnable: { text: "Analytische Ermächtigung" },
      analysisHelp: { text: "Hilfe analyze" },
      analysisStudy: { text: "Analyse lernen" },
      analysisReview: { text: "Überprüfung analyze" },
      analysisTags: { text: "Intelligente Etiketten analyze" },
      analysisSentiment: { text: "Analyse der öffentlichen Meinung" },
      analysisBusiness: { text: "Geschäfts analyze" },
      groupTest: { text: "Gruppen tests" },
    },
    navBar: {
      billingCenter: "Hilfe zentrum",
      uploadDownload: "Upload-Download-Center",
      setting: "Systeme in stellungen",
    },
  },
  fix: {
    selectType: "Typ auswählen:",
    all: "Alle",
    call: "Der Anruf",
    text: "Dialog",
    wechat: "Enterprise WeChat-Sitzung",
    businessTrendAnalysis: "Analyse der Geschäfts trends der ersten Ebene",
    noEchart: "Keine Daten für das Diagramm",
    checkPointisRequired: "Die Regel ist als Pflicht element konfiguriert",
    voiceDownLoad: "Aufnahme herunter laden",
    exportTxt: "Exportieren von TXT-Text",
    exportWord: "Word-Text exportieren",
    sureReRole: "Bestätigen Sie die Rolle des umgekehrten Sprechers?",
    sureRecover: "Bestätigen Sie die erneute Fehler korrektur?",
    sureReAsr: "Bestätigung der Wieder identifikation?",
    reRole: "Reverse Sprecher",
    recover: "Neu korrektur",
    reAsr: "Wieder identifizieren",
    recordingOperation: "Aufnahme betrieb",
    onlySave30Day: "(Nur für 30 Tage reserviert)",
    appealError:
      "Bitte wenden Sie sich an die Qualitäts kontroll stelle, bevor Sie sie einreichen",
    exportToTaskCenter: "Erfolgreich in das Upload-Download-Center exportiert",
    caseBaseLimit: "Die Fall bibliothek darf 50 nicht überschreiten",
    unnamed: "Nicht benannt",
    wordSpeed: "Wort/Minute",
    hit: "Treffer",
    checkPoint: "Qualitäts prüf punkt",
    realTimeAdditionTip1:
      "Echtzeit-Hinzufügen: Die Qualitäts prüfung beginnt sofort, wenn Daten in das Qualitäts kontroll system gelangen",
    realTimeAdditionTip2:
      "Timing hinzufügen: Fügen Sie regelmäßig automatisch Daten für die Qualitäts prüfung hinzu",
    null: "Keine",
    collcetedProps: "Erfassungs feld",
    editingNameNow: "Es wird ein Name bearbeitet",
    upToFourLevels: "Klassifizieren Sie bis zu vier Ebenen",
    default: "Standard",
    nameCantBeNull: "Der Name kann nicht leer sein",
    editingNow: "Es wird eine Operation bearbeitet",
    nameError: "Der Name ist falsch",
    nameMaxLength8: "Die maximale Länge des Namens beträgt 8",
    callTime: "Gesprächs zeit",
    sentimentValue: "Kunden-Stimmungs wert",
    agentSentimentValue: "Kunden service Emotionaler Wert",
    sentimentAnalysis: "Analyse von Emotionen",
    associatedData: "Daten auf dem Weg",
    notifyTheOwner: "Benachrichtigen Sie den Gruppen besitzer",
    notifyTheEmployee: "Informieren Sie die Mitarbeiter selbst",
    notifyTheOwnerTip:
      "Der Gruppen inhaber wird benachricht igt, wenn der Sitzungs typ Gruppen chat ist",
    customNotifyEmployee:
      "Benutzer definierte Benachricht igung der Mitarbeiter",
    customNotifyEmployeeTip:
      "Wenn der Auslöser ein Mitarbeiter ist, wird der Mitarbeiter benachricht igt, zu dem die Nachricht gehört",
    clickToLearnMore: "Klicken Sie hier, um mehr zu erfahren",
    repeatMarkingTip:
      "Dieser Datensatz wurde vom Qualitäts prüf punkt markiert und muss nicht wiederholt werden",
    successfulEvaluation: "Erfolg bewerten",
    evaluationFailed: "Bewertung fehl geschlagen",
    qualityInspectionScore: "Qualitäts prüfungs bewertung",
    semanticTags: "Semantische Tags",
    smartTags: "Intelligente Tags",
    collect: "Informationen sammeln",
    appealRecord: "Aufzeichnungen über Beschwerden",
    satisfy: "Zufrieden",
    notSatisfy: "Nicht zufrieden",
    appealPlaceHolder: "Bitte geben Sie den Grund für die Beschwerde ein",
    giveUp: "Aufgeben",
    rejectReasonPlaceHolder: "Bitte geben Sie den Grund für die Ablehnung ein",
    reject: "Abgewiesen",
    changeRejectReason: "Gründe für die Ablehnung ändern",
    pass: "Durch",
    revise: "Änderung",
    machineEvaluation: "Maschinen bewertung",
    poor: "Schlechte",
    excellent: "Gut",
    moderate: "Allgemein",
    mustMarked: "Der Qualitäts prüf punkt muss gekennzeichnet sein",
    pleaseChoose: "Bitte wählen Sie",
    manualEvaluation: "Manuelle Bewertung",
    qualityInspectionTemplate: "Anwendbare Qualitäts prüfungs vorlage",
    collectionTemplate: "Anwendbare Sammlung vorlagen",
    keywordsWordCloud: "Schlüssel wörter Wort wolke",
    editCategoryLabel: "Klassifikation labels bearbeiten",
    justCall: "Nur den Anruf ansehen",
    justConversation: "Schauen Sie sich nur das Gespräch an",
    batchOperation: "Stapel betrieb",
    customerServicesGroup: "Kundendienst gruppe",
    joinGroup: "Gruppe beitreten",
    removeGroup: "Gruppe verschieben",
    enterInformationEntity:
      "Bitte geben Sie den Inhalt der Informations entität ein",
    enterNotBeNull: "Die Eingabe kann nicht leer sein",
    operationGroup: "Betriebs gruppe",
    customerServiceSelected: "Kunden service ausgewählt",
    callAndConversation: "Anruf/Gespräch",
    conversation: "Dialog",
    system: "System",
    customize: "Anpassen",
    effective: "Wirksam",
    invalid: "Ungültig",
    successfulAndToTaskCenter:
      "Erfolgreiche Ausführung, gehen Sie zum Missions center, um sie anzuzeigen",
    recalculationFailed: "Neu berechnung fehl geschlagen",
    selectRecalculatedIndicator:
      "Bitte wählen Sie den Indikator aus, der neu berechnet werden soll",
    source: "Bitte wählen Sie die Datenquelle",
    placeholder: "Bitte geben Sie Schlüssel wörter ein",
    cumulative: "Kumulativer Typ",
    average: "Durchschnitt licher Typ",
    distribution: "Verteilungs gesetz",
    originalDataCannotEmpty: "Rohdaten können nicht leer sein",
    inspection: "Qualitäts prüfung",
    check: "Probenahme",
    review: "Überprüfung",
    draftSavedSuccessfully: "Entwurf erfolgreich gespeichert",
    optimization: "Optimierung",
    agentName: "Name des Kundendienstes",
    allTasks: "Alle Qualitäts prüfungs aufgaben",
    datePickerSelect: "Wählen Sie ein Datum",
    callSelect: "Dialog auswählen",
    taskSelect: "Aufgaben auswählen",
    genReport: "Berichte generieren",
    dataSource: "Datenquellen",
    callsTotal: "Die Gesamtzahl der Anrufe",
    intelligentQualityInspection: "Intelligente Qualitäts prüfung",
    percentage: "Anteil",
    artificialSampling: "Künstliche Probenahme",
    appealNumber: "Anzahl der Beschwerden",
    totalConversation: "Die Gesamtzahl der Gespräche",
    samplingReviewRate: "Überprüfung srate",
    data: "Daten",
    inspectionResults: "Ergebnisse der Qualitäts prüfung",
    inspected: "Qualitäts prüfung",
    totalScore: "Gesamt punktzahl",
    learnMore: "Sehen Sie mehr",
    inspectionEffect: "Qualitäts prüfungs effekt",
    allTotal: "Gesamt volumen",
    manualInspection: "Manuelle Überprüfung",
    artificialReview: "Manuelle Überprüfung",
    approvalAmountAndRate: "Genehmigungs betrag und Genehmigungs rate",
    customerScore: "Kunden service Punktzahl",
    inspectionRecommendations: "Empfehlungen zur Qualitäts prüfung",
    saveDraft: "Entwürfe speichern",
    historicalAdvice: "Historische Vorschläge",
    advicePlaceHolder: "Bitte geben Sie Vorschläge ein...",
    upload: "Datei-Upload",
    customAdd: "Filter hinzufügen",
    advancedConditions: "Fort geschrittene Bedingungen",
    keyWord: "Schlüssel wörter",
    containsAll: "Alle enthalten",
    containsAny: "Enthält beliebig",
    matchAny: "Passen Sie beliebig an",
    matchAll: "Alles zusammenpassen",
    smartTagsOne: "Smart Tag eins",
    smartTagsTwo: "Intelligente Tags zwei",
    season: "Saison",
    month: "Monat",
    week: "Woche",
    day: "Tag",
    hour: "Zeit",
    notMoreThan366: "Die Zeit darf 366 Tage nicht überschreiten",
    propsCannotBeNull: "Es gibt Felder, die nicht ausgefüllt sind",
    other: "Andere",
    correlationCoefficient: "Korrelation koeffizient",
    repetitionRate: "Wiederholung srate",
    configurationItemsMoreThan20:
      "Fügen Sie bis zu 20 Konfiguration elemente hinzu",
    conversionAnalysis: "Konvertierungs analyze",
    correlationAnalysis: "Korrelation analyze",
    successfulExportAndToTaskCenter:
      "Der Export ist erfolgreich. Gehen Sie zum Task Center, um anzuzeigen",
    typeSelect: "Typ auswählen",
    exportExcel: "Excel exportieren",
    exportPng: "Exportieren von png",
    noDataChart: "Keine Daten für das Diagramm",
    placeOperator: "Bitte wählen Sie Smart Label",
    placeAdvancedConditions: "Bitte wählen Sie erweiterte Bedingungen",
    createAdvancedConditions: "Neuer erweiterter Filter",
    moveUp: "Nach oben bewegen",
    moveDown: "Nach unten bewegen",
    moveLeft: "Links verschoben",
    moveRight: "Rechts verschoben",
    statusChangeSuccess: "Status änderung erfolgreich",
    editSuccess: "Erfolgreich modifiziert",
    saveSuccess: "Speichern Sie erfolgreich",
    firstPage: "Startseite",
    lastPage: "Letzte Seite",
    customerInfo: "Kunden informationen",
    type: "Typ",
    NumberTypeErrorMessage:
      'Der numerische Feld informations inhalt kann nur Zahlen oder "."',
    taskStartPrompt: "Eingabe aufforderung zum Öffnen von Aufgaben",
    startConfirm:
      "Bitte klicken Sie auf die Bestätigungs-Schaltfläche, um die Qualitäts prüfungs aufgabe zu öffnen",
    continueToSubmitWithoutEvaluation:
      "Keine Bewertung und weitere Einreichung",
    rejectTip:
      "Es wurden keine Änderungs vorgänge festgestellt. Bestätigen Sie, dass alle Beschwerden direkt abgelehnt werden?",
    unmarkedSelfLearningTip:
      "Sie haben einen nicht gekennzeichneten selbst lernenden Qualitäts punkt",
    markedSuccessfully: "Erfolgreich markiert",
    markingFailed: "Kennzeichnung fehl geschlagen",
    exceedingLength: "Über die Länge hinaus",
    uncategorized: "Nicht katego risiert",
    addSuccess: "Neuer Erfolg",
    deleteSuccess: "Löschen erfolgreich",
    featureName: "Element namen",
    informationEntity: "Informations entitäten",
    standardAnswer: "Standard Antwort",
    keyElementAnswer: "Schlüssel elemente Antworten",
    generalStatement: "Allgemeine Aussage (keine Anfrage erforderlich)",
    generalQuestionsAndConfirmation: "Allgemeine Zweifel und Bestätigung",
    specialQuestionsAndAnswers: "Spezielle Fragen und Antworten",
    agent: "Kunden service",
    customer: "Kunden",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Die semantischen Tags und Informations entitäten der Schlüssel elemente können nicht gleichzeitig leer sein",
    standardSentence: "Standard-Satz",
    acquaintance: "Bekanntschaft",
    smartUePlaceholder:
      "Bitte geben Sie den Namen der intelligenten Interaktion ein",
    typeSelectPlaceholder: "Bitte wählen Sie den Typ",
    keyElement: "Schlüssel elemente",
    reQuerySementicTags: "Semantische Tags erneut auswählen",
    querySementicTags: "Wählen Sie semantische Tags",
    QATestForm: "Frage-und-Antwort-Testform",
    selectNotBeNull: "Die Auswahl kann nicht leer sein",
    featureDetectionTarget: "Element erkennungs ziele",
    categorized: "Klassifizierung",
    effectiveness: "Wirksamkeit",
    hideAdvancedSettings: "Erweiterte Einstellungen ausblenden",
    showAdvancedSettings: "Erweiterte Einstellungen anzeigen",
    generalStatementOnly: "Allgemeine Aussage",
    generalQuestions: "Allgemeine Zweifel",
    affirmativeExpression: "Positiv zum Ausdruck",
    negativeIntention: "Negative Absicht",
    searchSementicTags: "Semantische Tags abfragen",
    choiceQuestion: "Auswahl frage",
    selectiveAnswer: "Selektive Antwort",
    sementicTagsPlaceholder:
      "Bitte geben Sie den Inhalt des semantischen Tags ein",
    openSpecialQuestion: "Offene spezielle Frage",
    openAnswer: "Offene Antwort",
    customQA: "Benutzer definierte Fragen und Antworten",
    questions: "Frage methode",
    dataPlaceholder: "Bitte geben Sie den Inhalt ein",
    reply: "Antwort",
    notFindSuitableLabel: "Das richtige Etikett wurde nicht gefunden",
    addSemanticTag: "Fügen Sie ein neues semantisches Label hinzu",
    intelligentInteraction: "Intelligente Interaktion",
    matching: "Übereinstimmungen",
    bout: "Zeiten",
    keywordMatch: "Keyword-Übereinstimmung",
    addFilterConditions: "Filter kriterien hinzufügen",
    buttonStartCollectionTask:
      "Bitte klicken Sie auf die Schaltfläche OK, um die Erfassungs aufgabe zu öffnen",
    buttonStartTask:
      "Bitte klicken Sie auf die Schaltfläche OK, um die Qualitäts prüfungs aufgabe zu öffnen",
    ruleConfigurationIsRequired:
      "Die Regel ist als Pflicht element konfiguriert",
    asrRunning: "ASR-Ausführung",
    errorCorrectionExecution: "Fehler korrektur ausführung",
    emotionalVolumeRecognition: "Emotionale Lautstärke erkennung",
    dataPreprocessing: "Daten vor verarbeitung",
    inspectionExecution: "Durchführung der Qualitäts prüfung",
    businessId: "Geschäft Id",
    businessTime: "Geschäfts zeit",
    createTime: "Zeit erstellen",
    abnormalReason: "Ursachen von Anomalien",
    singleChat: "Single Chat",
    groupChat: "Gruppen chat",
    taskContent: "Inhalt der Aufgabe",
    submissionTime: "Zeit für die Einreichung",
    startTime: "Zeit für den Start",
    endTime: "Endzeit",
    runningTime: "Zeitaufwand für den Betrieb",
    totalAmountOfData: "Gesamt datenvolumen",
    resultsOfThe: "Ergebnisse der Ausführung",
    operator: "Betreiber",
    taskDetails: "Missions details",
    executiveFunction: "Ausführungs funktion",
    qualityCheckTasks: "Qualitäts prüfungs aufgaben",
    implementationDetails: "Details zur Ausführung",
    conditionalRules: "Bedingung regeln",
    smartCondition: "Intelligente Bedingungen",
    goThis: "Bewegen Sie sich hier",
    pleaseMarkClassification: "Bitte kennzeichnen Sie die Klassifizierung",
    advancedScreening: "Erweiterte Filterung",
    noDataToManipulate: "Keine Daten zum Betrieb",
    concept: "Begriff Wort",
    checkPoint1: "Konfiguration von Regeln",
    requiredFieldsCannotEmpty: "Erforderliche Elemente dürfen nicht leer sein",
    rule: "Regeln",
    afterFixUnit: "Worte",
    unmarkedSelfLearningPointsTip:
      "Sie haben einen nicht gekennzeichneten selbst lernenden Qualitäts punkt",
    onlyReservedFor7Days: "(Nur für 7 Tage reserviert)",
    downloadError: "Download fehl geschlagen",
    pause: "Aussetzung",
    continue: "Weiter",
    recalcScore: "Nur Scores (einschl ießlich Bewertungen) neu berechnen",
    recalculatePointsAndScores:
      "Neu berechnung von Qualitäts prüf punkten und Bewertungen",
    recalculatePointsAndScoresWithEmtry:
      "Neu berechnung von Qualitäts prüf punkten und-werten (Qualitäts prüf punkte sind leer)",
    inProgress: "In Arbeit",
    toBeExecuted: "Ausstehende Ausführung",
    executeImmediately: "Sofort ausführen",
    specialElements: "Besondere Elemente",
    logicRule: "Logische Regeln",
    every: "Mehrere Übereinstimmungen",
    hitRecall: "Rückruf wort getroffen",
    notHitRecall: "Rückruf wort verfehlt",
    hitRecallRegular: "Rückruf regel treffen",
    notHitRecallRegular: "Fehlschlag",
    hitRule: "Regeln für Treffer",
    notHitRule: "Versäumte Regeln",
    hitSimilarSentence: "Ähnliche Sätze treffen",
    notHitSimilarSentence: "Versäumte ähnliche Sätze",
    hitExcludeSimilarSentences: "Treffer, um ähnliche Sätze aus zuschließen",
    notHitExcludeSimilarSentences: "Fehler schließen ähnliche Sätze aus",
    updateSuccess: "Erfolgreiches Update",
    qualify: "Reguläre Regeln",
    recallKeyWord: "Rückruf-Schlüssel wörter",
    grammar: "Grammatik-Regeln",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Die Gültigkeit änderung ist fehl geschlagen. Bitte versuchen Sie es erneut",
    postAutoMateTasksCaseByTaskId:
      "Die Synchron isierungs konfiguration der Fall bibliothek kann nicht gespeichert werden",
    getAutoMateTasksCaseByTaskId:
      "Die synchrone Konfiguration der Fall bibliothek ist fehl geschlagen",
    postInspectionRemarkModelConfigs:
      "Hinweis Vorlagen können nicht gespeichert werden",
    putInspectionRemarkModelConfigsById:
      "Hinweis Vorlagen können nicht gespeichert werden",
    getInspectionRemarkModelConfigsById:
      "Fehler beim Abrufen der Notiz vorlagen daten",
    postKnowledgeDatas:
      "Die neue Wissens datenbank konnte nicht erstellt werden. Bitte versuchen Sie es erneut",
    putKnowledgeDatasById:
      "Die Wissens datenbank wurde nicht geändert. Bitte versuchen Sie es erneut",
    getLibraryFiles:
      "Das Abrufen der Text preis ordner liste ist fehl geschlagen",
    getLibraryCalls: "Datei erfassung fehl geschlagen",
    getLibraryFilesSearch: "Suche fehl geschlagen",
    putLibraryFilesMoveById: "Der Preis ordner ist fehl geschlagen",
    putLibraryCallsById: "Datei umbenennen fehl geschlagen!",
    postLibraryFiles: "Ordner hinzufügen fehl geschlagen",
    putLibraryFilesById: "Ordner umbenennen fehl geschlagen",
    deleteLibraryFilesById: "Löschen fehl geschlagen",
    deleteLibraryCallsById: "Löschen fehl geschlagen",
    postLibraryCalls: "Hinzufügen fehl geschlagen",
    getVoiceUploadResultById:
      "Der Export des Upload-Ergebnisses ist fehl geschlagen",
  },
};
