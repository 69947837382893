export default {
    // dateFormat: "yyyy-mm-dd",
    momentDateFormat: "YYYY-MM-DD",
    momentDateSlashFormat: "YYYY/MM/DD",
    momentDateSlashHourMinuteTimeFormat: "YYYY/MM/DD HH:mm",
    momentDateSlashHourMinuteSecondTimeFormat: "YYYY/MM/DD HH:mm:ss",
    momentDateSlashYearHourMinuteSecondTimeFormat: "YY/MM/DD HH:mm:ss",

    datePickerFormat: "yyyy-mm-dd",
    // timeFormat: "hh:mm:ss",
    momentTimeFormat: "HH:mm:ss",
    momentTimeNoSecondFormat: "HH:mm",
    momentDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    momentDateTimeNoSecondFormat: "YYYY-MM-DD HH:mm",
    momentDateWeekDayFormat: "dddd",
};