import React, { useRef, useState, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import { Button, Icon, Menu, Popconfirm, Space, Tree, Modal, FormBuilder, Spin, Switch } from 'udesk-ui';
import type { TableProps } from 'udesk-ui/lib/table';
import type { TreeProps } from 'udesk-ui/lib/tree';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import type { PaginationProps } from 'udesk-ui/lib/pagination';
import type { MenuProps } from 'udesk-ui/lib/menu';
import type { ModalProps as AntModalProps } from 'udesk-ui/lib/modal';
import UdeskTable from '../udesk-table';
import UdeskPagination from '../udesk-pagination';
import ExportButton from '../export-button';
import FilterBtn from '../../pages/components/custom-filter-button';
import FilterModal from '../../pages/components/custom-filter-modal';
import Locales from 'UdeskLocales';
import { TreeNode } from './components/tree-node';
import Udesk from 'Udesk';
import './style.scss';
const noop = () => {};
const menuStyles: React.CSSProperties = {
    width: 240,
    // height: '100%',
    // overflowX: 'hidden',
    // overflowY: 'auto',
};
interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}
interface Filter {
    judgeStrategy: number;
    conditionList: any[];
    customJudgeLogic: string;
    type: number;
    onApply: (v) => void;
    taskId?: number | string;
    taskName?: string;
    customName?: string;
    customId?: number | string;
    filterList?: string[];
    querryType?: string;
    filterTaskData?: boolean;
    onConditionFilterSaved?: (flag, data) => void;
}
interface ExportBtn {
    onExport?: (showFieldList) => void;
    hasFilterCondition?: boolean;
}
interface ClassificationItem {
    label: string;
    description?: string;
    id: number;
    canEdit?: boolean;
    canDelete?: boolean;
}
interface Classification extends Omit<MenuProps, 'title'> {
    classifications: ClassificationItem[];
    onEdit?: (item: ClassificationItem, index: number) => void;
    onDelete?: (item: ClassificationItem, index: number) => void;
    title?: React.ReactNode;
}
interface ModalProps extends AntModalProps {
    formConfig?: FormProps;
    loading?: boolean;
}
interface TablePageProps {
    showExport?: boolean;
    showColumsControl?: boolean;
    showFilter?: boolean;
    // showClassification?: boolean;
    loading?: boolean;
    leftRender?: () => React.ReactElement;
    exportBtn?: ExportBtn;
    filter?: Filter;
    mainButton?: React.ReactElement;
    tableHeaderLeftRender?: React.ReactElement;
    tableHeaderRightRender?: () => React.ReactElement;
    customComponents?: FormProps['customComponents'];
    onSearch?: (params: any) => void;
    onReset?: (params: any) => void;
    onResetClick?: (formInstance: any, values: any) => void;
    searchFormInitialValues?: { [key: string]: any };
}
interface TablePageRef {
    table: {
        clearSelectedRowKeys: () => void;
    };
}
export type TableComponentProps = {
    subtractedHeight?: number; //有顶部按钮区域和底部分页的情况下是136
    cacheKey?: string | false;
    checkable?: boolean;
} & Omit<TableProps<any>, 'pagination' | 'loading'>;
type TreeNodeExtraItem = {
    label?: string;
    onClick?: (node: any) => void;
    danger?: boolean;
    disabled?: boolean | ((item: any) => boolean);
};
type TreeComponentProps = { treeKey: string; treeNodeExtra?: TreeNodeExtraItem[] } & TreeProps;
type PaginationComponentProps = { overSizeHidden?: boolean } & PaginationProps;
const mainButtonProps = { type: 'primary' };
/**
 * MenuItem 是左侧分类选择组件的每一项
 * @param props
 */
const MenuItem = (props) => {
    const locales = Locales['current'];

    const { item, index, onClassificationItemEdit, onClassificationItemDelete } = props;
    return (
        <div className='classification-item ellipsis'>
            <div className='classification-item-content ellipsis'>
                <div className='classification-title ellipsis'>{item.label}</div>
                {item.description && (
                    <div className='classification-description ellipsis' title={item.description}>
                        {item.description}
                    </div>
                )}
            </div>
            <div className='classification-actions' onClick={(e) => e.stopPropagation()}>
                <Space>
                    {item.canEdit !== false && typeof onClassificationItemEdit === 'function' && (
                        <Button
                            onClick={onClassificationItemEdit.bind(null, item, index)}
                            type='text'
                            size='small'
                            tooltip={locales.labels.edit}
                            icon={<Icon type='EditTwoTone' antdIcon={true}></Icon>}
                        ></Button>
                    )}
                    {item.canDelete !== false && typeof onClassificationItemDelete === 'function' && (
                        <Popconfirm
                            onConfirm={onClassificationItemDelete.bind(null, item, index)}
                            title={locales.labels.deletePopconfirmContent}
                            okText={locales.labels.delete}
                            cancelText={locales.labels.cancel}
                        >
                            <Button
                                type='text'
                                tooltip={locales.labels.delete}
                                size='small'
                                icon={<Icon type='DeleteTwoTone' antdIcon={true}></Icon>}
                            ></Button>
                        </Popconfirm>
                    )}
                </Space>
            </div>
        </div>
    );
};
const MenuItemContent = React.memo(MenuItem);
const ClassificationComponent: React.FC<Classification> = (props) => null;
const TreeComponent: React.FC<Classification> = (props) => null;
const ModalComponent: React.FC<ModalProps> = (props) => null;
const PaginationComponent: React.FC<PaginationComponentProps> = (props) => null;
const TableComponent: React.FC<TableComponentProps> = (props) => null;
interface ShowMap {
    classification?: boolean;
    tree?: boolean;
}

const DEFAULT_MODAL_FORM_PROPS: Partial<FormProps> = {
    footerRender: false,
};
const DEFAULT_MODAL_FORM_INITIALVALUES = {};
/**
 * 表单页的典型页面, 可覆盖左侧有分组列表, 使用高级筛选器, 导出, 列控制, 右侧有主按钮的应用场景
 * @param props
 */
const CommonTablePage = React.forwardRef<TablePageRef, TablePageProps>((props, ref) => {
    const locales = Locales['current'];

    const [showMap, setShowMap] = useState<ShowMap>({});
    const [classification, setClassification] = useState<any>({});
    const [treeProps, setTreeProps] = useState<any>({});
    const [tableProps, setTableProps] = useState<any>({});
    /**
     * pagination 分页的Props
     */
    const [pagination, setPagination] = useState<any>({});
    /**
     * modalProps 新建、编辑弹窗Props
     */
    const [modalProps, setModalProps] = useState<any>({});
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    /**
     * modalFormConfig 弹窗里的表单配置
     */
    const [modalFormConfig, setModalFormConfig] = useState<any>({});
    const [initialValues, setInitialValues] = useState<any>({});
    const [modalForm] = FormBuilder.useForm();
    const [searchForm] = FormBuilder.useForm();

    const prevTreeData = useRef<any[]>([]);
    const prevFormattedTreeData = useRef<any[]>([]);
    const [tableSelectedRowsMap, setTableSelectedRowsMap] = useState<any>({});
    const [tableKey, setTableKey] = useState<string>(Udesk.utils.string.uuid());
    useImperativeHandle(
        ref,
        () => ({
            table: {
                clearSelectedRowKeys: () => {
                    setTableSelectedRowsMap({});
                    setTableKey(Udesk.utils.string.uuid());
                },
            },
            searchForm,
        }),
        []
    );
    useEffect(() => {
        let newMap: any = {};
        React.Children.forEach(props.children, (child: any) => {
            if (React.isValidElement(child)) {
                switch (child?.type) {
                    case ClassificationComponent: {
                        newMap.classification = true;
                        setClassification(child.props);
                        break;
                    }
                    case TreeComponent: {
                        newMap.tree = true;
                        let childProps: any = child.props;
                        if ((child.props as any)?.treeNodeExtra) {
                            const { treeNodeExtra, treeData, ...others } = child.props as any;
                            childProps = others;
                            if (prevTreeData.current !== treeData) {
                                prevTreeData.current = treeData;

                                const getNode = (data) => {
                                    return data.map((i) => {
                                        let children = Array.isArray(i.children)
                                            ? getNode(i.children || [])
                                            : undefined;
                                        return {
                                            ...i,
                                            title: <TreeNode {...i} menu={treeNodeExtra} />, //这个不是antd的TreeNode，是自己写的
                                            children,
                                        };
                                    });
                                };
                                prevFormattedTreeData.current = getNode(treeData);
                            }
                            childProps.treeData = prevFormattedTreeData.current;
                        }
                        setTreeProps(childProps);
                        break;
                    }
                    case TableComponent: {
                        const { columns, checkable = false, ...others } = child.props as any;
                        let tableProps = { ...others };
                        // tableSelectedRowsMap.current = {
                        //     ...(tableProps.dataSource.reduce((prev, cur) => ({...prev, [cur.key || cur.id]: false}), {})),
                        //     ...tableSelectedRowsMap.current
                        // };
                        if (checkable) {
                            const rowSelection = {
                                onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
                                    setTableSelectedRowsMap((prev) => ({
                                        ...prev,
                                        ...tableProps.dataSource.reduce(
                                            (prev, cur) => ({ ...prev, [cur.key || cur.id]: false }),
                                            {}
                                        ),
                                        ...selectedRowKeys.reduce((prev, cur) => ({ ...prev, [cur]: true }), {}),
                                    }));
                                },
                            };
                            tableProps.rowSelection = rowSelection;
                        }
                        const formattedColumns = formatColumns(columns);
                        tableProps.columns = formattedColumns;
                        setTableProps(tableProps);
                        break;
                    }
                    case PaginationComponent: {
                        setPagination(child.props);
                        break;
                    }
                    case ModalComponent: {
                        const { formConfig = {}, ...modal } = child.props as any;
                        setModalFormConfig({ ...DEFAULT_MODAL_FORM_PROPS, ...formConfig });
                        const { onOk, loading = false, ...otherModalProps } = modal;
                        let finalModalProps = { ...otherModalProps, confirmLoading: loading };
                        if (formConfig?.initialValues?.id) {
                            finalModalProps.title = locales.labels.edit;
                            finalModalProps.okText = locales.labels.save;
                        } else {
                            finalModalProps.title = locales.labels.create;
                            finalModalProps.okText = locales.labels.create;
                        }
                        setInitialValues(formConfig?.initialValues || DEFAULT_MODAL_FORM_INITIALVALUES);
                        finalModalProps.onOk = () => {
                            modalForm.validateFields().then((values) => {
                                onOk(Object.assign({}, formConfig?.initialValues, values));
                            });
                        };
                        setModalLoading(loading);
                        setModalProps(finalModalProps);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        });
        setShowMap(newMap);
    }, [props.children, modalForm]);
    useEffect(() => {
        const newKeys = Object.keys(tableSelectedRowsMap).filter((k) => tableSelectedRowsMap[k]);
        tableProps?.onSelectedRowsChange?.(newKeys);
    }, [tableSelectedRowsMap, tableProps?.onSelectedRowsChange]);

    useEffect(() => {
        modalForm?.resetFields?.();
    }, [initialValues]);
    const {
        // table: tableProps = {},
        showExport = false,
        showColumsControl = false,
        showFilter = false,
        // showClassification = false,
        loading = false,
        // pagination,
        // classification,
        exportBtn = {},
        filter = {} as Filter,
        tableHeaderLeftRender,
        tableHeaderRightRender,
        leftRender,
        customComponents = {},
        onSearch,
        onReset,
        searchFormInitialValues = {},
        onResetClick,
    } = props;
    const { onExport: onExportProps, ...exportBtnProps } = exportBtn as ExportBtn;
    const {
        judgeStrategy: propsJudgeStrategy,
        conditionList: propsConditionList,
        customJudgeLogic: propsCustomJudgeLogic,
        onApply = noop,
        type,
        onConditionFilterSaved: propsOnConditionFilterSaved,
        // ...otherFilterProps
    } = filter;
    const {
        title: classificationTitle,
        classifications,
        onEdit: onClassificationItemEdit,
        onDelete: onClassificationItemDelete,
        ...menuProps
    } = classification ?? {};
    const [filterVisible, setFilterVisible] = useState(false);
    const [showFieldList, setShowFieldList] = useState([]);
    const tableRef = useRef<any>();

    const filterFormConfig: FormProps = useMemo(() => {
        const fields = (tableProps.columns ?? [])
            .filter((i) => i.type)
            .map((i) => ({ ...i, label: i.label || i.title, name: i.name || i.key || i.dataIndex }));

        return {
            fields,
            customComponents,
            layout: 'inline',
            footerRender: false,
            initialValues: searchFormInitialValues,
        };
    }, [tableProps, customComponents, searchFormInitialValues]);

    const onPopoverVisible = (e) => {
        tableRef?.current?.triggerColumnsControl?.(e);
    };
    /**
     * 高级筛选器显示/隐藏的回调
     */
    const changeFilterVisible = useCallback(() => {
        setFilterVisible(true);
    }, []);
    /**
     * 高级筛选器取消的回调
     */
    const cancel = useCallback(() => {
        setFilterVisible(false);
    }, []);
    /**
     * 高级筛选器点击仅适用时的回调
     * @param conditionList 配置的规则项
     * @param customJudgeLogic 自定义逻辑
     * @param judgeStrategy 满足所有/任意/自定义
     * @param id
     * @param name
     */
    const handleConditionApply = (conditionList, customJudgeLogic, judgeStrategy, id, name) => {
        setFilterVisible(false);
        onApply({ conditionList, customJudgeLogic, judgeStrategy, id, name });
    };
    /**
     * 记录当前Table展示出来的列的id
     * @param keys Table中当前展示出来的列的id
     */
    const onColumnsChange = (keys) => {
        setShowFieldList(keys);
    };

    // const onSearchBtnClick = () => {
    //     searchForm.submit()
    // }
    /**
     * 当此页只有一项, 删除后没有项目且不在首页的情况下触发, 默认回到列表首页
     */
    useEffect(() => {
        if (
            tableProps?.dataSource?.length === 0 &&
            typeof pagination?.current === 'number' &&
            pagination.current !== 1 &&
            pagination.total !== 0
        ) {
            pagination.onChange?.(1, pagination.pageSize);
        }
    }, [tableProps.dataSource]);
    const onExport = useCallback(() => {
        if (typeof onExportProps !== 'function') {
            return;
        }
        onExportProps(showFieldList ?? []);
    }, [showFieldList, onExportProps]);
    /**
     * 表示列表上方是否有按钮的标志位
     */
    const hasHeader = useMemo(() => {
        return (
            showFilter ||
            showExport ||
            showColumsControl ||
            props.mainButton ||
            tableHeaderLeftRender ||
            typeof onSearch === 'function'
        );
    }, [showFilter, showExport, showColumsControl, props.mainButton, tableHeaderLeftRender, onSearch]);

    const hasLeft = showMap.classification || showMap.tree;
    return (
        <div className={`udesk-qa-web-table-common-page${hasLeft ? '-with-classification' : ''}`}>
            {hasLeft && (
                <div className='udesk-qa-web-table-common-page-classification'>
                    <div className='udesk-qa-web-table-common-page-classification-title'>{classificationTitle}</div>
                    <div className='udesk-qa-web-table-common-page-classification-body'>
                        {leftRender?.()}
                        {!leftRender && classification && (
                            <Menu {...menuProps} mode='inline' style={menuStyles}>
                                {Array.isArray(classifications) &&
                                    classifications.map((item, index) => {
                                        return (
                                            <Menu.Item key={item.id} title={item.label}>
                                                <MenuItemContent
                                                    item={item}
                                                    index={index}
                                                    onClassificationItemEdit={onClassificationItemEdit}
                                                    onClassificationItemDelete={onClassificationItemDelete}
                                                />
                                            </Menu.Item>
                                        );
                                    })}
                            </Menu>
                        )}
                        {!leftRender && treeProps && <Tree {...treeProps} key={treeProps.treeKey}></Tree>}
                    </div>
                </div>
            )}
            <div className='udesk-qa-web-table-common-page-main'>
                {hasHeader && (
                    <div className='udesk-qa-web-table-common-page-header'>
                        {tableHeaderLeftRender ? (
                            <div
                                style={typeof onSearch === 'function' ? { flex: 'unset' } : undefined}
                                className='udesk-qa-web-table-common-page-header-left'
                            >
                                {tableHeaderLeftRender}
                            </div>
                        ) : null}
                        {typeof onSearch === 'function' && (
                            <Space className='udesk-qa-web-table-common-page-header-search-form'>
                                <FormBuilder {...filterFormConfig} form={searchForm} onFinish={onSearch} />
                                <Button onClick={searchForm.submit}>{locales.labels.query}</Button>
                                <Button
                                    onClick={
                                        typeof onResetClick === 'function'
                                            ? onResetClick?.bind(null, searchForm, searchFormInitialValues)
                                            : () => {
                                                searchForm.resetFields();
                                                onReset?.(searchFormInitialValues);
                                            }
                                    }
                                >
                                    {locales.labels.reset}
                                </Button>
                            </Space>
                        )}
                        <Space>
                            {tableHeaderRightRender ? <div>{tableHeaderRightRender}</div> : null}
                            {showFilter && (
                                <FilterBtn
                                    onClick={changeFilterVisible}
                                    judgeStrategy={propsJudgeStrategy}
                                    conditionList={propsConditionList}
                                    customJudgeLogic={propsCustomJudgeLogic}
                                />
                            )}
                            {showExport && (
                                <ExportButton
                                    exportDataCount={pagination?.total ?? 0}
                                    {...exportBtnProps}
                                    disabled={loading}
                                    hasFilterCondition={true}
                                    onClick={onExport}
                                />
                            )}
                            {showColumsControl && (
                                <Button onClick={onPopoverVisible}>
                                    {locales.components.pages.customerServiceRankList.select.column}
                                </Button>
                            )}
                            {props.mainButton && React.cloneElement(props.mainButton, mainButtonProps)}
                        </Space>
                    </div>
                )}
                <UdeskTable
                    key={tableKey}
                    rowKey={(item) => item.id}
                    subtractedHeight={136}
                    {...tableProps}
                    loading={loading}
                    ref={tableRef}
                    pagination={false}
                    onColumnsChange={onColumnsChange}
                ></UdeskTable>
                {Boolean(pagination?.total) && <UdeskPagination {...(pagination ?? {})} />}
                <Modal {...modalProps}>
                    <Spin spinning={modalLoading}>
                        <FormBuilder {...modalFormConfig} form={modalForm} />
                    </Spin>
                </Modal>
                {showFilter && (
                    <FilterModal
                        {...filter}
                        visible={filterVisible}
                        cancel={cancel}
                        applyAction={handleConditionApply}
                        onConditionFilterSaved={propsOnConditionFilterSaved}
                        judgeStrategy={propsJudgeStrategy}
                        customJudgeLogic={propsCustomJudgeLogic}
                        conditionList={propsConditionList}
                        type={type}
                    />
                )}
            </div>
        </div>
    );
});
let ExportComponent: any = React.memo(CommonTablePage);
ExportComponent.Classification = ClassificationComponent;
ExportComponent.Pagination = PaginationComponent;
ExportComponent.Table = TableComponent;
ExportComponent.Tree = TreeComponent;
ExportComponent.Modal = ModalComponent;

export default ExportComponent as typeof ExportComponent & {
    Classification: React.FC<Classification>;
    Pagination: React.FC<PaginationComponentProps>;
    Table: React.FC<TableComponentProps>;
    Tree: React.FC<TreeComponentProps>;
    Modal: React.FC<ModalProps>;
};

const formatColumns = (columns) => {
    const locales = Locales['current'];

    return columns.map((column) => {
        if (column.renderType === 'action' || column.renderType === 'actions') {
            let ret = { ...column };
            if (!column.render) {
                ret.render = function (text, item, index) {
                    const disableMap = column.disableRule?.(item) ?? {};
                    return (
                        <div style={{ marginLeft: '-8px' }}>
                            <Space>
                                {typeof column.onView === 'function' && (
                                    <Button
                                        type='link'
                                        size='small'
                                        disabled={disableMap?.view}
                                        onClick={column.onView?.bind?.(null, item)}
                                    >
                                        {locales.labels.view}
                                    </Button>
                                )}
                                {typeof column.onEdit === 'function' && (
                                    <Button
                                        type='link'
                                        size='small'
                                        disabled={disableMap?.edit}
                                        onClick={column.onEdit?.bind?.(null, item)}
                                    >
                                        {locales.labels.edit}
                                    </Button>
                                )}
                                {typeof column.onDelete === 'function' && (
                                    <Popconfirm
                                        title={locales.labels.deleteContent}
                                        onConfirm={column.onDelete?.bind?.(null, item)}
                                        onCancel={(e) => e!.stopPropagation()}
                                        okText={locales.labels.confirm}
                                        placement='topRight'
                                        cancelText={locales.labels.cancel}
                                    >
                                        <Button danger type='link' size='small' disabled={disableMap?.delete}>
                                            {locales.labels.delete}
                                        </Button>
                                    </Popconfirm>
                                )}
                            </Space>
                        </div>
                    );
                };
            }
            return ret;
        }
        if (column.renderType === 'switch') {
            let ret = { ...column };
            if (!column.render) {
                ret.render = function (text, item, index) {
                    return (
                        <div>
                            <Switch
                                checked={Boolean(item[column.dataIndex || column.key])}
                                onChange={column.onSwitchChange.bind(null, item, index)}
                                size='small'
                            />
                        </div>
                    );
                };
            }
            return ret;
        }
        return column;
    });
};
