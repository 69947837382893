import React from 'react';
import { useCustomerPortraitConfig } from './useCustomerPortraitConfig';

export const Context = React.createContext<
    ReturnType<typeof useCustomerPortraitConfig> | undefined
>(undefined);

export const CustomerPortraitConfigProvider = ({ children, value }) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
};
export const useCustomerPortraitConfigContext = () => {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error(
            'useCustomerPortraitConfigContext must be used within a CustomerPortraitConfigProvider'
        );
    }
    return context;
};
