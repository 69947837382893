import React from 'react';
import Component from './base';
export default class Template extends Component {
    render() {
        let {
            props,
            privates,
            actions
        } = this;
        let {
            computes,
            isCheckable
        } = privates;
        let {
            nodeCheckBoxComponent: NodeCheckBoxComponent,
            nodeTextComponent: NodeTextComponent,
            node,
            expandedIconClass,
            showNodeDescriptorIcon,
            getNodeDescriptorIcon,
            nameField,
            isShowNodeNum,
            numField,
            enableActions,
            dragIconClass,
            addIconClass,
            editIconClass,
            deleteIconClass,
            collapsedIconClass,
            draggable,
            nodeActionsComponent:NodeActionsComponent
        } = props;
        let expandedIconClassName = `${expandedIconClass} toggle-icon`;
        let collapsedIconClassName = `${collapsedIconClass} toggle-icon`;
        let collapsedIconInvisibleClassName = `${collapsedIconClass} toggle-icon invisible`;
        let dragIconClassName = `${dragIconClass} sort-button`;
        return (
            <div id={node.data.id} className={computes._className} ref={(reactTreeNodeElement) => this.privates.reactTreeNodeElement = reactTreeNodeElement}>
                <div className="node-toggle">
                    <If condition={node.nodes && node.nodes.length > 0}>
                        <If condition={node.states.expanded}>
                            <a className="toggle-link" href="javascript:void(0)" onClick={actions.collapse.params(node).stopPropagation()}>
                                <i className={expandedIconClassName}></i>
                            </a>
                        </If>
                        <If condition={!node.states.expanded}>
                            <a className="toggle-link" href="javascript:void(0)" onClick={actions.expand.params(node).stopPropagation()}>
                                <i className={collapsedIconClassName}></i>
                            </a>
                        </If>

                    </If>
                    <If condition={!(node.nodes && node.nodes.length > 0)}>
                        <a className="toggle-link invisible" href="javascript:void(0)">
                            <i className={collapsedIconInvisibleClassName}></i>
                        </a>
                    </If>
                </div>
                <If condition={isCheckable}>
                    <div className="node-check">
                        <If condition={NodeCheckBoxComponent}>
                            <NodeCheckBoxComponent value={node.states.checked} disabled={node.states.disabled} onChanged={actions.checkStatusChanged.params(node)} />
                        </If>
                        <If condition={!NodeCheckBoxComponent}>
                            <input type="checkbox" onClick={actions.nativeCheckStatusChanged.params(node)} checked={node.states.checked} disabled={node.states.disabled} />
                        </If>
                    </div>
                </If>

                <If condition={showNodeDescriptorIcon && getNodeDescriptorIcon}>
                    <i className={getNodeDescriptorIcon(node.data)}></i>
                </If>

                <div className="node-body">
                    <NodeTextComponent node={node} nameField={nameField} isShowNodeNum={isShowNodeNum} numField={numField} click={actions.click.params(node)} />
                    <If condition={enableActions && node.states.actions.visible}>
                        <If condition={!draggable}>
                            <div className="node-actions">
                                <NodeActionsComponent addIconClass={addIconClass} editIconClass={editIconClass} deleteIconClass={deleteIconClass} node={node} onNodeAction={actions.onNodeAction} />
                            </div>
                        </If>
                        <If condition={draggable}>
                            <i className={dragIconClassName}></i>
                        </If>
                    </If>
                </div>
            </div>
        );
    }
}