import ImportModal from 'Component/common/import-modal';
import React, { useCallback } from 'react';
import {
    getReviewIntelligentPartnerNodePptConfig,
    postReviewIntelligentPartnerNodePptConfig,
} from 'src/api/review/intelligentPartner/node/pptConfig';
import { postReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeId } from 'src/api/review/intelligentPartner/node/pptConfig/upload/{flowId}/{nodeId}';
import { putReviewIntelligentPartnerNodePptConfigById } from 'src/api/review/intelligentPartner/node/pptConfig/{id}';
import { postReviewIntelligentPartnerNodeScoreRuleCheck } from 'src/api/review/intelligentPartner/node/scoreRule/check';
import { isDefined } from 'src/util/core';
import Udesk from 'Udesk';
import { Button, message } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const fields = (props) => {
    const { flowId, form, configId } = props;
    if (isPptConfigNode(props.node.data.configType)) {
        return [
            {
                label: /* 上传幻灯片 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                    .components.const.nodeConfigs.pptNode.uploadSlide,
                type: 'PptUpload',
                name: 'pptFile',
                props: {
                    flowId,
                    resetPptImageList: () => {
                        form.setFieldsValue({
                            pptImageList: undefined,
                        });
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: /* 考核方式 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                    .components.const.nodeConfigs.pptNode.assessmentMethod,
                type: 'RadioGroup',
                props: {
                    options: Udesk.enums.pptEvaluationMode.map((i) => ({
                        label: i.name,
                        value: i.id,
                    })),
                },
                name: 'evaluationMode',
            },
        ];
    }
    if (props.evaluationMode === Udesk.enums.pptEvaluationMode.whole.id) {
        return [
            {
                label: '',
                type: 'ScoringRules',
                name: 'scoreRuleList',
                props: {
                    onChange: (scoreRuleList) => {
                        props.saveFieldValues((prev) => {
                            prev.scoreRuleList = scoreRuleList;
                        });
                    },
                    configId: props.configId,
                    type: 2, // 1:学员节点  2:ppt
                    showWords: true,
                    onCheckRule: () => {
                        const values = props.form?.getFieldsValue?.();
                        postReviewIntelligentPartnerNodeScoreRuleCheck(values).then(() => {
                            message.success(
                                /* 校验通过 */ UdeskLocales['current'].pages.coach.courseManagement
                                    .flow.components.const.nodeConfigs.pptNode.verificationPassed
                            );
                        });
                    },
                },
            },
        ];
    } else {
        return [
            {
                className: 'udesk-coach-ppt-image-list',
                label: (
                    <>
                        <span>
                            {
                                /* 关联幻灯片 */ UdeskLocales['current'].pages.coach
                                    .courseManagement.flow.components.const.nodeConfigs.pptNode
                                    .associateSlide
                            }
                        </span>
                        <UploadModal
                            flowId={flowId}
                            nodeId={props.node.id}
                            successCallback={(resp) => {
                                form.setFieldsValue({
                                    pptImageList: resp.data,
                                });
                            }}
                        >
                            {/* 上传 */}
                            {
                                UdeskLocales['current'].pages.coach.courseManagement.flow.components
                                    .const.nodeConfigs.pptNode.upload
                            }
                        </UploadModal>
                    </>
                ),
                type: 'PptPreview',
                name: 'pptImageList',
            },
            {
                label: /* 标准话术 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                    .components.const.nodeConfigs.pptNode.standardScript,
                type: 'AiWordsTextArea',
                name: 'words',
                props: {
                    autoSize: {
                        minRows: 4,
                        maxRows: 8,
                    },
                    flowId,
                    configId,
                },
            },
            {
                label: '',
                type: 'ScoringRules',
                name: 'scoreRuleList',
                props: {
                    onChange: (scoreRuleList) => {
                        props.saveFieldValues((prev) => {
                            prev.scoreRuleList = scoreRuleList;
                        });
                    },
                    configId: props.configId,
                    type: 2, // 1:学员节点  2:ppt
                    onCheckRule: () => {
                        const values = props.form?.getFieldsValue?.();
                        postReviewIntelligentPartnerNodeScoreRuleCheck(values).then(() => {
                            message.success(
                                /* 校验通过 */ UdeskLocales['current'].pages.coach.courseManagement
                                    .flow.components.const.nodeConfigs.pptNode.verificationPassed
                            );
                        });
                    },
                },
            },
            {
                type: 'Input',
                props: {
                    type: 'hidden',
                    placeholder: UdeskLocales['current'].labels.pleaseEnter,
                },
                name: 'evaluationMode',
            },
        ];
    }
};

const request = ({ nodeId, saveFieldValues, node }) => {
    return getReviewIntelligentPartnerNodePptConfig({
        params: {
            nodeId,
        },
    }).then((resp) => {
        const res: any = resp.data || {};
        if (Array.isArray(res.scoreRuleList)) {
            res.scoreRuleList = res.scoreRuleList.map((i) => {
                return {
                    ...i,
                    rule: JSON.parse(i.rule || ''),
                };
            });
        }
        if (!res.evaluationMode) {
            res.evaluationMode = isDefined(
                node.parentNodeId,
                Udesk.enums.pptEvaluationMode.singlePage.id,
                Udesk.enums.pptEvaluationMode.whole.id
            );
        }
        if (!res.configType) {
            res.configType = isDefined(
                node.parentNodeId,
                Udesk.enums.pptConfigType.singlePage.id,
                Udesk.enums.pptConfigType.pptConfig.id
            );
        }
        return res;
    });
};

const finish = ({ formInstanceCache, configId, nodeId, node, flowId }) => {
    const { configType } = node.data ?? {};
    return (values) => {
        const params = {
            ...values,
            nodeId,
            configType: node.data.configType,
            flowId,
            nodeLocation: JSON.stringify(node.position),
        };
        let submit: any = null;
        if (isPptConfigNode(configType)) {
            params.id = configId;
            params.pptImageList = (values.pptImageList || []).map((i) => i.attachResource);
            params.pptImageWordsList = values.pptImageList || [];
            submit = postReviewIntelligentPartnerNodePptConfig(params, {
                successMsg:
                    /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                        .components.const.nodeConfigs.pptNode.successfullySaved,
            });
        } else {
            if (!configId) {
                submit = postReviewIntelligentPartnerNodePptConfig(params, {
                    successMsg:
                        /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                            .components.const.nodeConfigs.pptNode.successfullySaved,
                });
            } else {
                submit = putReviewIntelligentPartnerNodePptConfigById(params, {
                    segments: {
                        id: configId,
                    },
                    successMsg:
                        /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                            .components.const.nodeConfigs.pptNode.successfullySaved,
                });
            }
        }
        return submit.then((resp) => {
            return resp;
        });
    };
};

export default {
    fields,
    request,
    finish,
};

function isPptConfigNode(configType) {
    return configType === Udesk.enums.pptConfigType.pptConfig.id;
}

export type RefType = {
    open: () => void;
};

const UploadModal = React.forwardRef<RefType, any>((props: any, ref) => {
    const { successCallback, flowId, nodeId } = props;

    const request = useCallback(
        (params) => {
            return postReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeId(params, {
                segments: {
                    flowId,
                    nodeId,
                },
            });
        },
        [flowId, nodeId]
    );

    const {
        fileRef,
        handleOnCancel,
        handleOnOk,
        isUploadVisible,
        uploadFile,
        file,
        clearFile,
        showImportModal,
        uploadLoading,
    } = ImportModal.useImportModal({
        isShowImportSuccessNotification: false,
        accept: ['ppt', 'pptx', 'pdf'],
        request,
        formatParams: useCallback((file, uploadData) => {
            return {
                downloadPath: uploadData.key,
                host: uploadData.token.host,
                keyName: uploadData.key,
                name: uploadData.name,
            };
        }, []),
    });

    return (
        <>
            <Button onClick={showImportModal} size="small" type="link">
                {/* 上传 */}
                {
                    UdeskLocales['current'].pages.coach.courseManagement.flow.components.const
                        .nodeConfigs.pptNode.upload
                }
            </Button>
            <ImportModal
                fileRef={fileRef}
                onCancel={() => {
                    clearFile();
                    handleOnCancel();
                }}
                onOk={() => handleOnOk().then(successCallback).then(clearFile)}
                visible={isUploadVisible}
                uploadFile={uploadFile}
                title={
                    /* 上传文档 */ UdeskLocales['current'].pages.intelligentAnalysis
                        .intelligentExtractors.components.pageLeftTable.uploadDocument
                }
                file={file}
                okButtonProps={{
                    loading: uploadLoading,
                    disabled: uploadLoading,
                }}
            >
                <div className="form-group">
                    {[
                        '（',
                        /* 支持ppt、pdf格式文件 */ UdeskLocales['current'].pages.coach
                            .courseManagement.flow.components.const.nodeConfigs.pptNode
                            .supportPptPdfFormatFiles,
                        '）',
                    ].join('')}
                </div>
            </ImportModal>
        </>
    );
});
