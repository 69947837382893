import RectangularCoordinateSystemBuilderClass from './rectangular-coordinate-system';

class StackColumnOptionsBuilderClass extends RectangularCoordinateSystemBuilderClass {
    _decorateSeries(series, optionsOwner) {
        series = super._decorateSeries(...arguments);

        let {
            showSeriesLabels,
        } = optionsOwner.props;

        for (let seriesItem of series) {
            seriesItem.stack = "one";
        }

        if (showSeriesLabels) {
            let lastSeriesItem = series[series.length - 1];
            lastSeriesItem.label.normal.show = true;
            for (let i = series.length - 2; i >= 0; i--) {
                series[i].label = {};
            }

            if (lastSeriesItem.label.normal) {
                lastSeriesItem.label.normal.formatter = (params) => {
                    let paramsValue = Number(params.value);
                    let totalDataValue = 0;

                    for (let i = series.length - 2; i >= 0; i--) {
                        let seriesItem = series[i];
                        let dataValue = Number(seriesItem.data[params.dataIndex].value);
                        if (isNaN(dataValue)) {
                            dataValue = 0;
                        }
                        totalDataValue += dataValue;
                    }

                    if (isNaN(paramsValue)) {
                        paramsValue = 0;
                    }
                    return (paramsValue + totalDataValue).toFixed(2);
                };
            }
        }
        return series;
    }
}

export default StackColumnOptionsBuilderClass;
