import tranToTaskCenterNotification from 'Component/common/task-notification';
import React from 'react';
import Udesk from 'Udesk';
import config from '../../../common/config/index';
import findKey from 'lodash-es/findKey';
// import { postAsrFilterConfigSave } from 'src/api/asr-filter-config/save/index';
import { postAsrFilterConfigSaveList } from 'src/api/asr-filter-config/save/list';
import { isSalesSubApp } from 'Udesk/system/subApp';
import { getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

class SpeechRecognitionComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
        currentRule: {
            conditionList: [],
            customJudgeLogic: '',
            judgeStrategy: 1,
            _type: '',
        },
    };
    privates = {
        asrFiltersInstance: null,
        correctionFiltersInstance: null,
        asrSwitch: false,
        asrConfigSwitch: false,
        imAsrConfigSwitch: true,
        asrConfigId: null,
        asrConfig: {
            conditionList: [],
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            customJudgeLogic: '',
        },
        asrBackup: {},
        correctionSwitch: false,
        correctionConfigSwitch: false,
        correctionConfigId: null,
        correctionConfig: {
            conditionList: [],
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            customJudgeLogic: '',
        },
        correctionBackup: {},
        fieldList: [],
        callTimeRange: [],
        redoCorrectionIds: [],
        callDateRef: React.createRef(),
        asrLanguageList: [],
        canSelectAsrLanguage: [],
        defaultAsrLanguage: '',
        defaultAsrLanguageObj: null,
        conditionJson: {
            asrConfig: [],
            correctionConfig: [],
        },
        conditionJsonInfo: {
            asrConfig: [],
            correctionConfig: [],
        },
        asrModelNew: [],
        asrModel: {
            mandarin: {
                conditionList: [],
                customJudgeLogic: '',
                judgeStrategy: 1,
            },
            cantonese: {
                conditionList: [],
                customJudgeLogic: '',
                judgeStrategy: 1,
            },
            english: {
                conditionList: [],
                customJudgeLogic: '',
                judgeStrategy: 1,
            },
        },
        asrModelOrder: [],
        asrModelMap: {
            mandarin: Udesk.enums.speechRecognitionSecondaryType.asrModel.id,
            english: Udesk.enums.speechRecognitionSecondaryType.enAsrModel.id,
            cantonese: Udesk.enums.speechRecognitionSecondaryType.canAsrModel.id,
        },
        asrModelSwitch: false,
    };

    static computes = {
        _fields: [
            'privates.fieldList',
            function ({ props, privates, locales }) {
                let { fieldList } = privates;
                fieldList.map((field) => {
                    return field;
                });
                return Udesk.business.fields.buildConditionFields({
                    fields: fieldList,
                });
            },
        ],
        _fieldValueSettings: [
            'privates.fieldList',
            function ({ props, privates, locales }) {
                return config.getFilterComponentSettings();
            },
        ],
    };

    actions = {
        onDateChanged(value) {
            this.privates.callTimeRange = value;
            if (moment(value[0]).add(31, 'days') <= value[1]) {
                return Udesk.ui.notify.error(
                    this.locales.components.pages.speechRecognition.callTimeError
                );
            }
            let url = Udesk.business.apiPath.concatApiPath(
                isSalesSubApp() ? 'review/call/call-num' : 'data/call-num',
                this.props.sdkOptions
            );
            let data = {
                startTime: moment(value[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(value[1])
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .millisecond(999)
                    .format('YYYY-MM-DD HH:mm:ss'),
                inspectDataSource: 1,
            };
            Udesk.ajax.post(url, data).then(
                (resp) => {
                    this.privates.redoCorrectionIds = resp.data.ids;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            this.locales.components.pages.components.selectCallTime
                                .getCallRecordError
                    );
                }
            );
        },
        redoCorrection() {
            let { locales } = this;
            let { callTimeRange, redoCorrectionIds, callDateRef } = this.privates;
            if (moment(callTimeRange[0]).add(31, 'days') <= callTimeRange[1]) {
                return Udesk.ui.notify.error(
                    locales.components.pages.speechRecognition.callTimeError
                );
            }
            if (redoCorrectionIds.length === 0) {
                return Udesk.ui.notify.error(
                    locales.components.pages.speechRecognition.callRecordCountError
                );
            }
            let url = Udesk.business.apiPath.concatApiPath(
                isSalesSubApp() ? 'asr-filter-config/review/redo' : 'asr-filter-config/redo',
                this.props.sdkOptions
            );
            let data = {
                startTime: moment(callTimeRange[0])
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(callTimeRange[1])
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .millisecond(999)
                    .format('YYYY-MM-DD HH:mm:ss'),
                processCount: redoCorrectionIds.length,
                callIds: redoCorrectionIds,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, data).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        locales.components.pages.speechRecognition.redoCorrectionSuccess
                    );
                    tranToTaskCenterNotification();
                    this.privates.callTimeRange = [];
                    this.privates.redoCorrectionIds = [];
                    callDateRef.current &&
                        callDateRef.current.actions.clearDateRange &&
                        callDateRef.current.actions.clearDateRange();
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            locales.components.pages.speechRecognition.redoCorrectionError
                    );
                }
            );
        },
        onSwitchChanged(type, active) {
            // console.log('onSwitchChanged', type, active);
            let { locales, privates, actions } = this;
            let { sdkOptions } = this.props;
            let activeFlag = active ? 1 : 0;
            let url = Udesk.business.apiPath.concatApiPath(
                `/asr-filter-config/switch/${type}?activeFlag=${activeFlag}`,
                sdkOptions
            );
            let who = getName(locales, type, Udesk.enums.speechRecognitionSecondaryType.onOff.id);
            Udesk.ajax.get(url).then(
                (resp) => {
                    switch (type) {
                        case Udesk.enums.speechRecognitionSwitchType.asr.id:
                            privates.asrSwitch = active;
                            actions.update();
                            break;
                        case Udesk.enums.speechRecognitionSwitchType.correction.id:
                            privates.correctionSwitch = active;
                            privates.callTimeRange = [];
                            privates.redoCorrectionIds = [];
                            actions.update();
                            break;
                        default:
                            break;
                    }
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            who
                        )
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                who
                            )
                    );
                }
            );
        },
        onConfigSwitchChanged(type, active) {
            let { locales, privates, actions } = this;
            if (active) {
                switch (type) {
                    case Udesk.enums.speechRecognitionSwitchType.asr.id:
                        privates.asrConfigSwitch = active;
                        actions.update();
                        break;
                    case Udesk.enums.speechRecognitionSwitchType.correction.id:
                        privates.correctionConfigSwitch = active;
                        actions.update();
                        break;
                    default:
                        break;
                }
            } else {
                let who = getName(
                    locales,
                    type,
                    Udesk.enums.speechRecognitionSecondaryType.config.id
                );
                saveConfigAndBackup(this, type, false).then(
                    (resp) => {
                        Udesk.ui.notify.success(
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveSuccessFormat,
                                who
                            )
                        );
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    locales.business.notifyMessage.saveFailedFormat,
                                    who
                                )
                        );
                    }
                );
            }
        },
        filterContidionChanged(type, conditions) {
            let target = getTarget(this, type);
            target.conditionList = conditions;
        },
        changeJudgeStrategy(type, value) {
            let target = getTarget(this, type);
            target.judgeStrategy = value;
            if (
                value === Udesk.enums.operatorRuleTypes.all.id ||
                value === Udesk.enums.operatorRuleTypes.any.id
            ) {
                target.customJudgeLogic = '';
            }
            this.actions.update();
        },
        saveConfig(type) {
            let { locales } = this;
            let who = getName(locales, type, Udesk.enums.speechRecognitionSecondaryType.config.id);
            saveConfigAndBackup(this, type, true).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            who
                        )
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                who
                            )
                    );
                }
            );
        },
        resetConfig(type) {
            // switch (type) {
            // case Udesk.enums.speechRecognitionSwitchType.asr.id:
            //     this.privates.asrFiltersInstance.privates.dirtyFullConditions = null;
            //     break;
            // case Udesk.enums.speechRecognitionSwitchType.correction.id:
            //     this.privates.correctionFiltersInstance.privates.dirtyFullConditions = null;
            //     break;
            // default:
            //     break;
            // }
            let backup = getBackup(this, type);
            for (let [key, value] of Object.entries(backup)) {
                if (typeof value === 'object') {
                    this.privates[key] = Udesk.utils.object.deepCopy(value);
                } else {
                    this.privates[key] = value;
                }
            }
            if (type === Udesk.enums.speechRecognitionSwitchType.correction.id) {
                let correctionConfigJson = this.actions.getConfigJson(
                    this.privates.correctionBackup.correctionConfig.conditionList
                );
                this.privates.conditionJsonInfo.correctionConfig =
                    correctionConfigJson.conditionJsonInfo;
                this.privates.conditionJson.correctionConfig = correctionConfigJson.conditionJson;
            } else {
                let asrConfigJson = this.actions.getConfigJson(
                    this.privates.asrBackup.asrConfig.conditionList
                );
                this.privates.conditionJsonInfo.asrConfig = asrConfigJson.conditionJsonInfo;
                this.privates.conditionJson.asrConfig = asrConfigJson.conditionJson;
            }
            this.actions.update();
        },

        //高级筛选器
        handleConditionCancel() {
            this.setState({
                baseFilterVisible: false,
            });
        },
        handleConditionApply(type, conditionList, customJudgeLogic, judgeStrategy) {
            // console.log(
            //     'handleConditionApply',
            //     type,
            //     '-',
            //     conditionList,
            //     '-',
            //     customJudgeLogic,
            //     '-',
            //     judgeStrategy,
            //     '-'
            // );
            this.privates.asrModelNew.forEach((item, index) => {
                if (item.languageType === type || index === this.state.activeItemIndex) {
                    item.conditionList = conditionList;
                    item.judgeStrategy = judgeStrategy;
                    if (judgeStrategy !== 3) {
                        item.customJudgeLogic = '';
                    } else {
                        item.customJudgeLogic = customJudgeLogic;
                    }

                    this.actions.changeBaseFilterVisible(item);
                }
            });

            // let { editKey } = this.privates;
            let condition = this.state.currentRule;
            // let type = condition._type;

            condition.conditionList = conditionList;
            condition.judgeStrategy = judgeStrategy;
            if (judgeStrategy !== 3) {
                condition.customJudgeLogic = '';
            } else {
                condition.customJudgeLogic = customJudgeLogic;
            }
            this.privates.customId = null;
            this.privates.customName = null;
            // this.setState({currentRule: condition});
            this.actions.changeBaseFilterVisible(condition);
            if (Object.values(this.privates.asrModelMap).includes(type)) {
                this.privates.asrModel[findKey(this.privates.asrModelMap, (o) => o === type)] =
                    condition;
            } else {
                let { conditionJsonInfo, conditionJson } =
                    this.actions.getConfigJson(conditionList);
                this.privates.conditionJsonInfo[type] = conditionJsonInfo;
                this.privates.conditionJson[type] = conditionJson;
                this.privates[type] = condition;
            }
            this.actions.update();
        },
        editFilterOhter(condition, type) {
            condition._type = type;
            this.setState({
                baseFilterVisible: true,
                currentRule: condition,
            });
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.forEach((item) => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach((items) => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label +
                                ' ' +
                                obj[item.operator] +
                                ' ' +
                                str.substring(0, str.length - 1),
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName ||
                                item.value.agentGroupName ||
                                item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <div
                        style={{
                            color: 'rgba(0, 0, 0, 0.65)',
                            marginBottom: index + 1 === list.length ? '0px' : '8px',
                        }}
                    >
                        {index + 1}. {item}
                    </div>
                );
            });
        },
        changeBaseFilterVisible(condition) {
            let baseFilterVisible = this.state.baseFilterVisible;
            if (!baseFilterVisible) {
                this.setState({
                    currentRule: condition,
                });
            }
            this.setState({
                baseFilterVisible: !baseFilterVisible,
            });
        },
        getConfigJson(conditions) {
            let conditionJsonInfo = [];
            let conditionJson = [];
            conditionJsonInfo =
                conditions.map((item) => {
                    if (item.field && item.field.statusKey) {
                        return {
                            ...item,
                            field: {
                                ...item.field,
                                id: `${item.field.id}-${item.field.inspectionTaskId}`,
                            },
                        };
                    } else {
                        return item;
                    }
                }) || [];
            conditionJson = conditions.map((item) => {
                if (item.field && item.field.statusKey) {
                    return {
                        field: `${item.field.id}-${item.field.inspectionTaskId}`,
                        operator: item.operator,
                        value: item.value,
                        statusKey: `${item.field.id}-${item.field.inspectionTaskId}`,
                    };
                } else {
                    return {
                        field: item.field.id,
                        operator: item.operator,
                        value: item.value,
                    };
                }
            });
            return {
                conditionJsonInfo,
                conditionJson,
            };
        },
        onNewFilterModalEditClick(type, currentRule, index) {
            // console.log('onNewFilterModalEditClick', type, currentRule);
            this.setState({
                currentRule: { ...currentRule, _type: type },
                baseFilterVisible: true,
                activeItemIndex: index,
            });
        },
        onAsrSwitchChanged(type, checked) {
            // console.log('onAsrSwitchChanged', type, checked);

            this.privates.asrModelSwitch = checked;
            this.actions.update();
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `/asr-filter-config/switch/${Udesk.enums.speechRecognitionSwitchType.asr.id}`,
                sdkOptions
            );
            let data = {
                activeFlag: checked ? 1 : 0,
                type: Udesk.enums.speechRecognitionSwitchType.asr.id, // asr是1
                secondaryType: Udesk.enums.asrDataFilterSecondaryType.switch.id,
            };
            Udesk.ajax.get(url, data).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        /* 操作保存成功！ */ UdeskLocales['current'].pages.speechRecognition
                            .component.operationSavedSuccessfully
                    );
                    resolve();
                },
                (reason) => {
                    reject(reason);
                    this.privates.asrModelSwitch = !checked;
                    this.actions.update();
                }
            );

            // saveAsrConfig(this, Udesk.enums.asrDataFilterSecondaryType.switch.id, checked ? 1 : 0);
            return;
            this.privates.asrModelSwitch = checked;
            this.actions.update();
            if (checked) {
                this.privates.asrModelSwitch = true;
                this.actions.update();
            } else {
                return;
                postAsrFilterConfigSaveList(
                    Object.entries(this.privates.asrModel).map(([key, value]) => {
                        return {
                            activeFlag: 0,
                            filter: value,
                            secondaryType: this.privates.asrModelMap[key],
                            type,
                        };
                    })
                ).then(() => {
                    this.privates.asrModelSwitch = false;
                    this.actions.update();
                });
            }
        },
        defaultAsrLanguageChange(value, b) {
            // console.log('defaultAsrLanguageChange', value, b);
            saveAsrConfig(this, Udesk.enums.asrDataFilterSecondaryType.default.id, value);
        },
        asrLanguageChange(index, value) {
            this.privates.asrModelNew[index]['languageType'] = value;
            // this.privates.canSelectAsrLanguage.forEach((item, index) => {
            //     if (item.value === value) {
            //         this.privates.canSelectAsrLanguage[index]['disabled'] = true;
            //     }
            // });
            this.actions.update();
            // console.log('asrLanguageChange', index, value);
        },
        addModel() {
            this.privates.asrModelOrder.push('english');
            this.privates.asrModelNew.push({
                ...this.state.currentRule,
                languageType: '',
            });
            // console.log('addModel', this.privates.asrModelNew);
            this.actions.update();
        },
        deleteModel(index) {
            // console.log(index, this.privates.asrModelNew);
            let deleteItem = this.privates.asrModelNew.splice(index, 1)[0];
            // console.log(deleteItem, this.privates.asrModelNew, deleteItem);
            // return;
            if (deleteItem.id) {
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(
                    `/asr-filter-config/${deleteItem.id}`,
                    sdkOptions
                );
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(
                            /* 删除成功！ */ UdeskLocales['current'].pages.speechRecognition
                                .component.deletedSuccessfully
                        );
                        this.privates.canSelectAsrLanguage.forEach((item, index) => {
                            if (item.value === deleteItem.languageType) {
                                this.privates.canSelectAsrLanguage[index]['disabled'] = false;
                            }
                        });
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            } else {
                this.privates.canSelectAsrLanguage.forEach((item, index) => {
                    if (deleteItem.languageType && item.value === deleteItem.languageType) {
                        this.privates.canSelectAsrLanguage[index]['disabled'] = false;
                    }
                });
                this.actions.update();
            }
        },
        saveAsrModelConfig(languageValue, rules, index) {
            // console.log(
            //     'saveAsrModelConfig',
            //     '-',
            //     languageValue,
            //     '-',
            //     rules,
            //     '-',
            //     index,
            //     '-',
            //     this.privates.asrModelNew
            // );
            if (!languageValue) {
                Udesk.ui.notify.error(
                    /* 请选择语言 */ UdeskLocales['current'].pages.speechRecognition.component
                        .pleaseSelectLanguage
                );
                return;
            } else if (rules.conditionList.length <= 0) {
                Udesk.ui.notify.error(
                    /* 请配置规则 */ UdeskLocales['current'].pages.speechRecognition.component
                        .pleaseConfigureRules
                );
                return;
            }
            // return;
            let activeItem = this.privates.asrModelNew[index];
            if (activeItem.id) {
                // 编辑
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(
                    `/asr-filter-config/${activeItem.id}`,
                    sdkOptions
                );
                let data = {
                    activeFlag: 1,
                    type: Udesk.enums.speechRecognitionSwitchType.asr.id, // asr是1
                    secondaryType: Udesk.enums.asrDataFilterSecondaryType.filter.id,
                    filter: {
                        ...activeItem,
                    },
                };
                // console.log(data);
                // return;
                Udesk.ajax.put(url, data).then(
                    (resp) => {
                        Udesk.ui.notify.success(
                            /* 更新成功！ */ UdeskLocales['current'].pages.speechRecognition
                                .component.updateSuccessful
                        );
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            } else {
                // 创建
                saveAsrConfig(
                    this,
                    Udesk.enums.asrDataFilterSecondaryType.filter.id,
                    languageValue,
                    this.privates.asrModelNew.filter(
                        (item) => item.languageType === languageValue
                    )[0]
                );
            }
        },
    };

    //#region Life Cycle
    componentDidMount() {
        let asrConfigJson = this.actions.getConfigJson(this.privates.asrConfig.conditionList);
        let correctionConfigJson = this.actions.getConfigJson(
            this.privates.correctionConfig.conditionList
        );
        this.privates.conditionJsonInfo.asrConfig = asrConfigJson.conditionJsonInfo;
        this.privates.conditionJsonInfo.correctionConfig = correctionConfigJson.conditionJsonInfo;
        this.privates.conditionJson.asrConfig = asrConfigJson.conditionJson;
        this.privates.conditionJson.correctionConfig = correctionConfigJson.conditionJson;
        this.actions.update();
    }
    parseProps({ props, prevProps }) {
        // let {conditionJsonInfo, conditionJson} = this.actions.getConfigJson(conditionList);
        // this.privates[type] = condition;
        // return {
        //     conditionJsonInfo,
        //     conditionJson,
        //     condition
        // }
    }
    componentWillUnmount() {
        this.privates.asrFiltersInstance = null;
        this.privates.correctionFiltersInstance = null;
    }
    //#endregion
}

function getQueryConditionList(conditionList, fieldList) {
    let queryConditionList = [];
    if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
        queryConditionList = Udesk.utils.object.deepCopy(conditionList);
        if (queryConditionList && queryConditionList.length > 0) {
            queryConditionList.map((condition) => {
                fieldList.forEach((field) => {
                    if (condition.field === field.id) {
                        condition.field = field;
                    }
                });
                return condition;
            });
        }
    }
    return queryConditionList;
}

function getTarget(that, type) {
    let { asrConfig, correctionConfig } = that.privates;
    switch (type) {
        case Udesk.enums.speechRecognitionSwitchType.asr.id:
            return asrConfig;
        case Udesk.enums.speechRecognitionSwitchType.correction.id:
            return correctionConfig;
        default:
            return {};
    }
}

function getBackup(that, type) {
    let { asrBackup, correctionBackup } = that.privates;
    switch (type) {
        case Udesk.enums.speechRecognitionSwitchType.asr.id:
            return asrBackup;
        case Udesk.enums.speechRecognitionSwitchType.correction.id:
            return correctionBackup;
        default:
            return {};
    }
}

function getName(locales, type, secondaryType) {
    switch (type) {
        case Udesk.enums.speechRecognitionSwitchType.asr.id:
            if (secondaryType === Udesk.enums.speechRecognitionSecondaryType.onOff.id) {
                return locales.components.pages.speechRecognition.asr;
            } else {
                return locales.components.pages.speechRecognition.asrConfig;
            }
        case Udesk.enums.speechRecognitionSwitchType.correction.id:
            if (secondaryType === Udesk.enums.speechRecognitionSecondaryType.onOff.id) {
                return locales.components.pages.speechRecognition.correction;
            } else {
                return locales.components.pages.speechRecognition.correctionConfig;
            }
        default:
            return '';
    }
}
function saveAsrConfig(that, secondaryType, activeValue, configRule) {
    let { locales, privates, actions } = that;
    let { sdkOptions } = that.props;
    let target = getTarget(that, 1);
    if (
        target.judgeStrategy === Udesk.enums.operatorRuleTypes.custom.id &&
        target.customJudgeLogic.trim() === ''
    ) {
        return reject({
            errorMsg: locales.components.pages.speechRecognition.customJudgeLogicIsNull,
        });
    }

    let data = {
        activeFlag: 1,
        type: Udesk.enums.speechRecognitionSwitchType.asr.id, // asr是1
        secondaryType: secondaryType,
        filter: {
            conditionList: [],
            judgeStrategy: target.judgeStrategy,
            customJudgeLogic: target.customJudgeLogic,
        },
    };
    if (secondaryType === Udesk.enums.asrDataFilterSecondaryType.default.id) {
        data.filter.languageType = activeValue;
    } else if (secondaryType === Udesk.enums.asrDataFilterSecondaryType.switch.id) {
        data.filter = {};
        data.activeFlag = activeValue;
    } else if (secondaryType === Udesk.enums.asrDataFilterSecondaryType.filter.id) {
        data.filter = configRule;
    }
    // console.log('saveAsrConfig', data);
    // return;
    let url = Udesk.business.apiPath.concatApiPath('/asr-filter-config/save', sdkOptions);
    Udesk.ajax.post(url, data).then(
        (resp) => {
            switch (secondaryType) {
                case Udesk.enums.asrDataFilterSecondaryType.default.id:
                    privates.defaultAsrLanguage = activeValue;
                    actions.update();
                    break;
                case Udesk.enums.asrDataFilterSecondaryType.filter.id:
                    privates.canSelectAsrLanguage.forEach((item, index) => {
                        if (item.value === activeValue) {
                            privates.canSelectAsrLanguage[index]['disabled'] = true;
                        }
                    });
                    privates.asrModelNew = [];
                    actions.reloadModel();
                    break;
                default:
                    break;
            }
            Udesk.ui.notify.success(
                /* 操作成功！ */ UdeskLocales['current'].pages.speechRecognition.component
                    .operationSuccessful
            );
            // let backup = getBackup(that, type);
            // for (let [key] of Object.entries(backup)) {
            //     if (typeof privates[key] === 'object') {
            //         backup[key] = Udesk.utils.object.deepCopy(privates[key]);
            //     } else {
            //         backup[key] = privates[key];
            //     }
            // }
        },
        (reason) => {
            reject(reason);
        }
    );
}

function saveConfigAndBackup(that, type, active) {
    return new Promise((resolve, reject) => {
        let { locales, privates, actions } = that;
        let { sdkOptions } = that.props;
        let target = getTarget(that, type);
        if (
            target.judgeStrategy === Udesk.enums.operatorRuleTypes.custom.id &&
            target.customJudgeLogic.trim() === ''
        ) {
            return reject({
                errorMsg: locales.components.pages.speechRecognition.customJudgeLogicIsNull,
            });
        }
        let data = {
            type,
            secondaryType: Udesk.enums.speechRecognitionSecondaryType.config.id,
            activeFlag: active ? 1 : 0,
            filter: {
                conditionList: getQueryConditionList(target.conditionList, privates.fieldList),
                judgeStrategy: target.judgeStrategy,
                customJudgeLogic: target.customJudgeLogic,
            },
        };
        let url = Udesk.business.apiPath.concatApiPath('/asr-filter-config/save', sdkOptions);
        Udesk.ajax.post(url, data).then(
            (resp) => {
                switch (type) {
                    case Udesk.enums.speechRecognitionSwitchType.asr.id:
                        privates.asrConfigSwitch = active;
                        actions.update();
                        break;
                    case Udesk.enums.speechRecognitionSwitchType.correction.id:
                        privates.correctionConfigSwitch = active;
                        actions.update();
                        break;
                    default:
                        break;
                }
                let backup = getBackup(that, type);
                for (let [key] of Object.entries(backup)) {
                    if (typeof privates[key] === 'object') {
                        backup[key] = Udesk.utils.object.deepCopy(privates[key]);
                    } else {
                        backup[key] = privates[key];
                    }
                }
                resolve();
            },
            (reason) => {
                reject(reason);
            }
        );
    });
}
function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter((item) => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map((item) => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}

export default SpeechRecognitionComponent;
