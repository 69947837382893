import localForage from 'localforage';
import Udesk from 'Udesk';

export const QA_SUB_APP = 'qa';
export const SALES_SUB_APP = 'sales';

export const setCurrentSubAppType = (subAppType) => {
    return localForage.setItem('currentSubAppType', subAppType);
};

export const getCurrentSubAppType = () => {
    return localForage.getItem('currentSubAppType');
};

export const isSubApp = (nameSpace) => {
    return (cb?) => {
        const startWithNameSpace = window.location.pathname.startsWith('/' + nameSpace);
        if (!startWithNameSpace) {
            return startWithNameSpace;
        }
        cb?.();
        return startWithNameSpace;
    };
};

export const isQaSubApp = isSubApp(Udesk.enums.sysModuleList.qa.nameSpace);
export const isSalesSubApp = isSubApp(Udesk.enums.sysModuleList.sales.nameSpace);
export const isAlgorithm = isSubApp(Udesk.enums.sysModuleList.algorithmPlatform.nameSpace);
export const isGeneralSetting = isSubApp(Udesk.enums.sysModuleList.commonSetting.nameSpace);
export const isCoach = isSubApp(Udesk.enums.sysModuleList.coach.nameSpace);
export const isConversationAnalysis = isSubApp(
    Udesk.enums.sysModuleList.conversationAnalysis.nameSpace
);

export const getCurrentRouteName = (baseRouteName) => {
    // if (isSalesSubApp() || isAlgorithm() || isCoach() || isGeneralSetting() || isConversationAnalysis()) {
    if (
        Udesk.enums.sysModuleList
            .filter((s) => s.nameSpace !== Udesk.enums.sysModuleList.qa.nameSpace)
            .some((c) => isSubApp(c.nameSpace)())
    ) {
        return camelCase(
            Udesk.enums.sysModuleList.find((sysModule) => isSubApp(sysModule.nameSpace)())
                .nameSpace +
                '-' +
                baseRouteName
        );
    }

    return baseRouteName;
};

export const getRouteNameWithNameSpace = (nameSpace) => (baseRouteName) => {
    return camelCase(`${nameSpace}-${baseRouteName}`);
};

/** 将字符串转化为驼峰写法 */
function camelCase(str, firstLetterUpperCase = false) {
    return str
        .split('-')
        .map((_, index) =>
            _.replace(/^\w/, (item) => {
                if (firstLetterUpperCase) {
                    return item.toUpperCase();
                } else {
                    return index === 0 ? item.toLowerCase() : item.toUpperCase();
                }
            })
        )
        .join('');
}

export const getSystemModule = () => {
    const system = Udesk.enums.sysModuleList.find((item) => isSubApp(item.nameSpace)());
    if (system) {
        return system.id;
    }
    return Udesk.enums.sysModuleList.qa.id;
};