import React, { useEffect, useState } from 'react';
import { FormBuilder, Button as UdeskButton } from 'udesk-ui';
import LoginRoute from './route';
import { Layout } from './components/Layout';
import VirtualLogin from 'src/components/pages/components/VirtualLogin';
import { setOauthSubdomain } from 'src/util/loginRedirect';
import { getOauthByDomainCallbackUrl } from 'src/api/oauth/{domain}/callbackUrl';
import { getOauthByDomainLogin } from 'src/api/oauth/{domain}/login';
import { getOauthByDomainMultiCompanyLogin } from 'src/api/oauth/{domain}/multiCompany/login';
import { RelationCompany } from 'src/api/types';
import { isJuSiHong, LoginStatusCode } from './const';
import UdeskLocales from 'UdeskLocales';

const Button = (props) => {
    return <UdeskButton {...props} htmlType="submit"></UdeskButton>;
};

const Template = ({ isCallback, subdomain, code, params }) => {
    const [loginLoading, setLoginLoading] = useState(isCallback);
    const [callbackLoading, setCallbackLoading] = useState(false);
    const [authorization, setAuthorization] = useState<any>({});
    const [virtualLogin, setVirtualLogin] = useState(false);
    const [relationCompanyList, setRelationCompanyList] = useState<RelationCompany[]>();

    useEffect(() => {
        if (isCallback) {
            if (!code) {
                console.log(/* 违法调用 */ UdeskLocales['current'].pages.login.oauth.illegalCall);
            }

            if (isJuSiHong(subdomain)) {
                getOauthByDomainMultiCompanyLogin({
                    params: {
                        code,
                    },
                    segments: {
                        domain: subdomain,
                    },
                })
                    .then((res) => {
                        if (res.data?.loginStatusCode === LoginStatusCode.Multi) {
                            setVirtualLogin(true);
                            setRelationCompanyList(res.data?.relationCompanyList);
                        } else {
                            if (params.refer) {
                                window.location.href = params.refer;
                            } else {
                                window.location.href = '/entry-page';
                            }
                        }
                    })
                    .finally(() => {
                        setLoginLoading(false);
                    });
            } else {
                getOauthByDomainLogin({
                    params: {
                        code,
                    },
                    segments: {
                        domain: subdomain,
                    },
                })
                    .then(() => {
                        setOauthSubdomain(subdomain);
                        if (params.refer) {
                            window.location.href = params.refer;
                        } else {
                            window.location.href = '/entry-page';
                        }
                    })
                    .catch(() => {
                        setLoginLoading(false);
                    });
            }

            // return;
        }

        setCallbackLoading(true);
        getOauthByDomainCallbackUrl({
            segments: {
                domain: subdomain,
            },
        })
            .then((resp) => {
                console.log(resp.data);
                setAuthorization(resp.data);
            })
            .finally(() => {
                setCallbackLoading(false);
            });
    }, []);

    const onJump = () => {
        if (!(isCallback && (loginLoading || callbackLoading))) {
            let url = `${authorization.authorizationEndpoint}?grant_type=authorization_code&client_id=${authorization.clientId}&response_type=code&scope=openid&redirect_uri=${authorization.callbackUrl}`;

            // 聚思鸿单点登录地址
            if (isJuSiHong(subdomain)) {
                url = `${authorization.authorizationEndpoint}`;
            }

            window.location.href = url;
        }
    };

    return (
        <Layout
            desc={
                /* 欢迎回来！点击下方按钮跳转身份认证平台 */ UdeskLocales['current'].pages.login
                    .oauth.welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform
            }
            subdomain={subdomain}
        >
            <FormBuilder
                customComponents={{ Button }}
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                onFinish={() => {
                    onJump();
                }}
                fields={[
                    {
                        type: 'Button',
                        props: {
                            children:
                                isCallback && (loginLoading || callbackLoading)
                                    ? /* 登录中... */ UdeskLocales['current'].pages.login.oauth
                                          .loggingIn
                                    : isJuSiHong(subdomain)
                                    ? 'SSO Login'
                                    : /* 统一身份认证登录 */ UdeskLocales['current'].pages.login
                                          .oauth.unifiedIdentityAuthenticationLogin,
                            block: true,
                            size: 'large',
                            type: 'primary',
                            loading: loginLoading || callbackLoading,
                            style: {
                                marginTop: 24,
                                ...(isJuSiHong(subdomain)
                                    ? {
                                          background: '#0083c4',
                                          borderColor: '#0083c4',
                                          fontSize: 18,
                                          fontWeight: 500,
                                          height: 56,
                                      }
                                    : {}),
                            },
                        },
                    },
                ]}
                footerRender={false}
                style={{
                    marginTop: 24,
                }}
            />

            <VirtualLogin
                visible={virtualLogin}
                relationCompanyList={relationCompanyList}
                onCancel={() => setVirtualLogin(false)}
            />
        </Layout>
    );
};

export default class LoginTemplate extends LoginRoute {
    render() {
        const routeName = this.props.route.name;
        const isCallback = 'oauthCallback' === routeName;
        const params = this.props.match.params;
        const { subdomain } = params;
        const queryParams = new URLSearchParams(this.props.location.search);
        let code = queryParams.get('code');

        return (
            <Template
                isCallback={isCallback}
                subdomain={subdomain}
                code={code}
                params={this.props.match.params}
            />
        );
    }
}
