import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { putInspectionRuleApprovesConfig } from 'src/api/inspectionRule/approves/config';
import { Switch, Form, message } from 'udesk-ui';


const AutoApprove = (props) => {
    const {value, locales} = props;
    const [form] = Form.useForm();

    const changeHandle = useCallback((values) => {
        putInspectionRuleApprovesConfig({
            enabledInspectRuleApproveAuto: values.enabledInspectRuleApproveAuto ?  1 : 0,
        }).then(() => {
            message.success(/* 修改成功 */locales.pages.tasks.approve.components.autoApproveSwitch.modificationSucceeded);
        });
    }, []);

    useEffect(() => {
        if (typeof value === 'boolean') {
            form.setFieldsValue({
                enabledInspectRuleApproveAuto: value
            });
        }
    }, [form, value]);

    return (
        <Form 
            form={form} 
            layout="inline"
            onValuesChange={changeHandle}
        >
            <Form.Item
                name="enabledInspectRuleApproveAuto"
                label={/* 自动审批 */locales.pages.tasks.approve.components.autoApproveSwitch.automaticApproval} valuePropName="checked"
            >
                <Switch />
            </Form.Item>
        </Form>
    );

};

export default React.memo(AutoApprove);