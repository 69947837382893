import React, { useCallback, useState } from 'react';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import OrganizationMemberSelect from '../OrganizationMemberSelect';
import { inspect } from 'src/util/core';
import { Button, Form, InputNumber, Modal, Space } from 'udesk-ui';
import { useRequest } from 'src/util/hook';

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};

const ExamTimesInput = (props) => {
    const { id, treeData, getUserByIds, getOrgByIds, value, onChange } = props;
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const { loading: confirmLoading, run } = useRequest({
        onSuccess() {
            setVisible(false);
            Udesk.ui.notify.success(
                /* 增加考试次数成功 */ UdeskLocales['current'].pages.coach.taskCenter.components
                    .examTimesInput.index.successfullyIncreasedTheNumberOfExams
            );
        },
        onError() {
            Udesk.ui.notify.error(
                /* 增加考试次数失败 */ UdeskLocales['current'].pages.coach.taskCenter.components
                    .examTimesInput.index.failedToIncreaseTheNumberOfExams
            );
        },
    });

    const isEdit = id !== '0';

    const clickHandle = useCallback(() => {
        form.resetFields();
        setVisible(true);
    }, [form]);

    const onFinish = useCallback(
        (values) => {
            const { exerciseTimes, userIds } = values;
            run(
                `/intelligentPartnerTaskInfos/${id}/add/exerciseTimes`,
                { exerciseTimes, ...userIds },
                'put'
            );
        },
        [id]
    );

    return (
        <Space>
            <InputNumber style={{ width: 80 }} min={1} value={value} onChange={onChange} />
            <span>{UdeskLocales['current'].pages.gong.coachTaskCenter.edit.times}</span>
            {inspect(isEdit, () => (
                <>
                    <Button type="link" onClick={clickHandle}>
                        {/* 增加考试次数 */}
                        {
                            UdeskLocales['current'].pages.coach.taskCenter.components.examTimesInput
                                .index.increaseTheNumberOfExams
                        }
                    </Button>
                    <Modal
                        title={
                            /* 增加考试次数 */ UdeskLocales['current'].pages.coach.taskCenter
                                .components.examTimesInput.index.increaseTheNumberOfExams
                        }
                        confirmLoading={confirmLoading}
                        visible={visible}
                        onOk={() => form.submit()}
                        onCancel={() => setVisible(false)}
                    >
                        <Form
                            form={form}
                            {...layout}
                            initialValues={{
                                userIds: null,
                                exerciseTimes: 1,
                            }}
                            autoComplete="off"
                            validateMessages={{
                                // eslint-disable-next-line no-template-curly-in-string
                                required:
                                    '${label}' +
                                    UdeskLocales['current'].pages.gong.coachTaskCenter.edit
                                        .cannotBeNull,
                            }}
                            scrollToFirstError={true}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label={
                                    /* 增加考试次数 */ UdeskLocales['current'].pages.coach
                                        .taskCenter.components.examTimesInput.index
                                        .increaseTheNumberOfExams
                                }
                                name="exerciseTimes"
                                rules={[{ required: true }]}
                            >
                                <ExamTimesInput id={'0'} />
                            </Form.Item>
                            <Form.Item
                                label={
                                    /* 选择对象 */ UdeskLocales['current'].pages.coach.taskCenter
                                        .components.examTimesInput.index.selectObjects
                                }
                                name="userIds"
                                rules={[{ required: true }]}
                            >
                                <OrganizationMemberSelect
                                    treeData={treeData}
                                    getUserByIds={getUserByIds}
                                    getOrgByIds={getOrgByIds}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
            ))}
        </Space>
    );
};

export default ExamTimesInput;
