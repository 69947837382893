export const StatusEnum = {
    efficient: 1,
    invalid: 0,
};

export const TypeEnum = {
    /** 是否符合 */
    Conform: 1,
    /** 分档评价 */
    GradedEvaluation: 2,
};
