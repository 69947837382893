import React from 'react';
import AudioStickers from 'Component/pages/components/audio-stickers';
import WaveAudio from 'Component/common/wave-audio';
import SmartTags from 'Component/pages/components/smart-tags';
import AudioCallInfo from 'Component/pages/components/audio-call-info';
import EmotionsAnalysis from 'Component/pages/components/emotion-analysis';
import RecordDetailComponent from './component';
import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import _map from 'lodash-es/map';
import { Button, Progress, Modal, Icon, PageHeader } from 'udesk-ui';
import ClassNames from 'classnames';
import _size from 'lodash-es/size';

export default class RecordDetailTemplate extends RecordDetailComponent {
    render() {
        let {
            keywords,
        } = this.props;
        let {
            detail,
            smartTags,
            showEmotionAnalysis,
            treeDataList,
            labelActive,
            progressState,
            treeNonCategory,
            visible,
            treeDataListObj,
            labelActiveModal,
            treeValue,
            addLevel,
            idState,
            labelParent,
            labelParentModal,
            hitData,
            progressCount,
            treeDataRef,
            labelRefObj,
            labelRef,
            treeDataRefObj,
            hitDataObj
        } = this.privates;
        let { categoryIdList } = this.privates.storages;
        let {
            actions,
            locales
        } = this;
        return (
            // <div className="qa-react-record-detail-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    onBack={actions.backToIndexPage}
                    title={locales.components.pages.trainingCenter.classifiedTraining.classDetail}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root qa-react-record-detail-page'>
                        <Choose>
                            <When condition={detail}>
                                <If condition={detail.voiceUrl && !showEmotionAnalysis}>
                                    <AudioStickers detail={detail} />
                                    {/* <div className={ClassNames({ ["wave-audio-display-none"]: !showEmotionAnalysis })}> */}
                                    <WaveAudio src={detail.voiceUrl} isShowEmotionButton={true} onClickEmotionAnalysis={actions.changeShowEmotionAnalysis} ref={instance => { this.privates.waveAudioInstance = instance; }} />
                                    {/* </div> */}
                                </If>
                                <If condition={showEmotionAnalysis}>
                                    <div className="qa-react-record-detail-emotion-analysis-container">
                                        <button type="button" className="btn btn-xs btn-default qa-react-record-detail-emotion-button" onClick={actions.changeShowEmotionAnalysis}>
                                            {locales.components.pages.components.recordDetail.showWaveAudio}
                                        </button>
                                        <EmotionsAnalysis detail={detail} classNames="qa-react-record-detail-emotion-analysis" />
                                    </div>
                                </If>
                                <SmartTags onSmartTagsChange={actions.onSmartTagsChange} callId={detail.id} smartTags={smartTags} smartTagsTitle={locales.components.pages.appealChekingTask.list.smartTags} treeListVisible={actions.treeListVisible} />
                                <div className="qa-react-record-detail-content row">
                                    <div className="qa-react-record-detail-content-left-part col-xs-12 col-md-5 col-lg-5 classified-training-detail">
                                        <AudioCallInfo detail={detail} keywords={keywords} inspectDataSource={detail.inspectDataSource} onTimeRangeChanged={actions.sendCurrentTimeRange} onMarkSemanticClick={actions.markSemantic} />
                                    </div>
                                    <div className="qa-react-record-detail-content-right-part col-xs-12 col-md-7 col-lg-7">
                                        <div className="qa-react-record-detail-content-word-cloud">
                                            <div className='label-tabs-list-page' ref={labelRef}>
                                                {
                                                    !_isEmpty(treeDataList) && _map(treeDataList, (item, index) => {
                                                        if (item) {
                                                            return (
                                                                <div className='label-tabs-list-page-field' key={index} style={{ width: '190px' }} ref={treeDataRef[`tree-${index}`]} onMouseUp={actions.fieldUp.params(labelRef)}>
                                                                    <div className='label-field-target-drag' onMouseDown={actions.treeDrag.params(treeDataRef[`tree-${index}`], labelRef)} >
                                                                        <Icon type="ic-pause-zanting" />
                                                                    </div>
                                                                    <div className='label-tabs-list-page-field-drag'>
                                                                        {
                                                                            !_isEmpty(item) && item.map(items => {
                                                                                return (
                                                                                    <div className={ClassNames('label-field-info', { 'label-field-info-active-parent': (labelParent[items.tagLevel] === items.id) || (hitData[items.tagLevel] === items.id) }, { 'label-field-info-active': items.id === labelActive })} key={items.id} onClick={actions.activesLabel.params(items.id, items.tagLevel, items.leafFlag)}>
                                                                                        <span className='label-field-info-field' title={items.tagName}>
                                                                                            {items.tagName}
                                                                                        </span>
                                                                                        {
                                                                                            items.leafFlag !== 1 && (
                                                                                                <Icon type="ic-play-line" />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return;
                                                        }

                                                    })
                                                }
                                            </div>

                                            <div className='label-tabs-list-noeLabel'>
                                                {
                                                    !_isEmpty(treeNonCategory) && (
                                                        <span className={ClassNames('label-field-info', { 'label-field-info-active': treeNonCategory.id === labelActive })} onClick={actions.activesLabelNone.params(treeNonCategory.id)}>{treeNonCategory.tagName}</span>
                                                    )
                                                }

                                            </div>
                                            <div className='label-tabs-list-progress'>
                                                <div className='label-tabs-list-progress-line'>
                                                    <span className='label-tabs-list-progress-line-title'>{locales.components.pages.trainingCenter.classifiedTraining.classStatus} </span>
                                                    <Progress type='line' format={actions.progressChange} successPercent={parseInt((progressState / _size(categoryIdList)) * 100, 10)} strokeColor='blue' />
                                                </div>
                                                <div className="label-tabs-list-progress-action">
                                                    {
                                                        progressCount > 0 && (
                                                            <Button style={{ marginRight: '5px' }} onClick={actions.prevProgress.params(progressCount - 1)}>{locales.components.pages.trainingCenter.classifiedTraining.prev}</Button>
                                                        )
                                                    }
                                                    {
                                                        progressCount !== _size(categoryIdList) - 1 && (
                                                            <Button style={{ marginRight: '5px' }} onClick={actions.nextProgress.params(progressCount + 1)}>{locales.components.pages.trainingCenter.classifiedTraining.next}</Button>
                                                        )
                                                    }
                                                    {
                                                        progressCount === _size(categoryIdList) - 1 ? (
                                                            <Button onClick={actions.classifiedIndex}>{locales.components.pages.trainingCenter.classifiedTraining.finish}</Button>
                                                        ) : (<Button onClick={actions.classifiedIndex}>{locales.components.pages.trainingCenter.classifiedTraining.stop}</Button>)
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </When>
                            <Otherwise>
                                <div className="qa-react-record-detail-page-no-value">
                                    {locales.labels.noValue}
                                </div>
                            </Otherwise>
                        </Choose>
                        <Modal
                            title={locales.fix.editCategoryLabel}
                            visible={visible}
                            onCancel={actions.modalCancel}
                            onOk={actions.modalOk}
                        >
                            <div className='label-tabs-list-page' ref={labelRefObj}>
                                {
                                    !_isEmpty(treeDataListObj) && _map(treeDataListObj, (item, index) => {
                                        if (item) {
                                            return (
                                                <div className='label-tabs-list-page-field' key={index} style={{ width: '190px' }} ref={treeDataRefObj[`treeObj-${index}`]} onMouseUp={actions.fieldUp.params(labelRefObj)}>
                                                    <div className='label-field-target-drag' onMouseDown={actions.treeDrag.params(treeDataRefObj[`treeObj-${index}`], labelRefObj)}>
                                                        <Icon type="ic-pause-zanting" />
                                                    </div>
                                                    <div className='label-tabs-list-page-field-drag'>
                                                        {
                                                            !_isEmpty(item) && item.map(items => {
                                                                return (
                                                                    <div style={{ display: items.id === idState ? 'none' : 'flex' }} className={ClassNames('label-field-info', { 'label-field-info-active-parent': (labelParentModal[items.tagLevel] === items.id) || (hitDataObj[items.tagLevel] === items.id) }, { 'label-field-info-active': items.id === labelActiveModal })} key={items.id} onClick={actions.activesLabelModal.params(items.id, items.tagLevel, items.leafFlag)}>
                                                                        <span>
                                                                            {items.tagName}
                                                                        </span>
                                                                        {
                                                                            items.leafFlag !== 1 && (
                                                                                <Icon type="ic-play-line" />
                                                                            )
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        {
                                                            index === addLevel && (
                                                                <div className='label-field-info' key={'add-tagId'} >
                                                                    <span>
                                                                        {treeValue.categoryName}
                                                                    </span>
                                                                    <Icon type="ic-play-line" />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            item.length < 20 && item.length > 0 && ((!item.find(current => current.id === treeValue.id) || _isEmpty(item) || (item.find(current => current.id === treeValue.id) && index !== addLevel)) && index !== addLevel) && (
                                                                <div className='label-field-info-add' onClick={actions.labelFieldAdd.params(index, treeValue.id)}>
                                                                    <span> + {locales.fix.goThis}</span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return;
                                        }
                                    })
                                }
                            </div>

                        </Modal>
                    </div >
                </div >
            </div >
        );
    }
}

