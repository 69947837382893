export default {
  lang: {
    confirm: {
      title:
        'Вы подтвердили, что хотите удалить? После удаления больше не может быть восстановлено',
      okText: 'Удалить',
      cancelText: 'Отмена',
    },
  },
};
