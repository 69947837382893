import React from 'react';
import PropTypes from 'prop-types';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

const INITIAL_VIEW_INDEX = 0;
const DEFAULT_URL_ATTR_NAME_SRC = "src";
const DEFAULT_URL_ATTR_NAME_DATA_URL = "data-url";
const imageFilter = (element) => {
    return true;
};

class ImageViewerComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        componentContainer: null,
        container: "body",
        backdrop: true,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        className: "",
        filter: imageFilter,
        fullscreen: true,
        initialViewIndex: INITIAL_VIEW_INDEX,
        inline: false,
        interval: 5000,
        keyboard: true,
        loading: true,
        loop: true,
        minWidth: 200,
        minHeight: 100,
        movable: true,
        rotatable: true,
        scalable: true,
        zoomable: true,
        zoomOnTouch: true,
        zoomOnWheel: true,
        slideOnTouch: true,
        toggleOnDblclick: true,
        tooltip: true,
        transition: true,
        zIndex: 2015,
        zIndexInline: 0,
        zoomRatio: 0.1,
        minZoomRatio: 0.01,
        maxZoomRatio: 100,
        ready: null,
        show: null,
        shown: null,
        hide: null,
        hidden: null,
        view: null,
        viewed: null,
        zoom: null,
        zoomed: null,

        //below are obsoleted.
        urlAttrName: null,
        resetViewer: false,

    }
    static propTypes = {
        componentContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        container: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        button: PropTypes.bool,
        navbar: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        title: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.func, PropTypes.array]),
        toolbar: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.object]),
        className: PropTypes.string,
        filter: PropTypes.func,
        fullscreen: PropTypes.bool,
        initialViewIndex: PropTypes.number,
        inline: PropTypes.bool,
        interval: PropTypes.number,
        keyboard: PropTypes.bool,
        loading: PropTypes.bool,
        loop: PropTypes.bool,
        minWidth: PropTypes.number,
        minHeight: PropTypes.number,
        movable: PropTypes.bool,
        rotatable: PropTypes.bool,
        scalable: PropTypes.bool,
        zoomable: PropTypes.bool,
        zoomOnTouch: PropTypes.bool,
        zoomOnWheel: PropTypes.bool,
        slideOnTouch: PropTypes.bool,
        toggleOnDblclick: PropTypes.bool,
        tooltip: PropTypes.bool,
        transition: PropTypes.bool,
        zIndex: PropTypes.number,
        zIndexInline: PropTypes.number,
        zoomRatio: PropTypes.number,
        minZoomRatio: PropTypes.number,
        maxZoomRatio: PropTypes.number,
        ready: PropTypes.func,
        show: PropTypes.func,
        shown: PropTypes.func,
        hide: PropTypes.func,
        hidden: PropTypes.func,
        view: PropTypes.func,
        viewed: PropTypes.func,
        zoom: PropTypes.func,
        zoomed: PropTypes.func,

        //below are obsoleted.
        urlAttrName: PropTypes.string,
        resetViewer: PropTypes.bool,
    }
    //#endregion

    //#region privates
    privates = {
        imageViewerInstance: null,
        isFirstRanderImageView: true,
        ownContainer: React.createRef()
    };
    //#endregion

    //#region public methods
    show(imageIndex) {
        if (this.privates.isFirstRanderImageView) {
            this.actions.init();
        }
        let viewerApi = this.privates.imageViewerInstance;
        if (viewerApi != null) {
            viewerApi.update();
            viewerApi.show();
            this.goto(imageIndex);
        }
    }
    hide() {
        if (this.privates.isFirstRanderImageView) {
            return;
        }
        let viewerApi = this.privates.imageViewerInstance;
        if (viewerApi != null) {
            viewerApi.hide();
        }
    }
    goto(imageIndex) {
        if (this.privates.isFirstRanderImageView) {
            this.actions.init();
        }
        let viewerApi = this.privates.imageViewerInstance;
        if (viewerApi != null) {
            viewerApi.view(imageIndex ? imageIndex : INITIAL_VIEW_INDEX);
        }
    }
    //#endregion

    //#region defaultProps
    static computes = {
        getUrlAttrName: ["urlAttrName", function ({ props, state, privates, locales }) {
            let urlAttrName = props.urlAttrName;
            return function (element) {
                if (urlAttrName != null) {
                    if (element[urlAttrName] != null) {
                        return element[urlAttrName];
                    }
                }
                if (urlAttrName != null) {
                    if (element[urlAttrName] != null) {
                        return element[urlAttrName];
                    }
                }
                if (element[DEFAULT_URL_ATTR_NAME_DATA_URL] != null) {
                    return element[DEFAULT_URL_ATTR_NAME_DATA_URL];
                }
                if (element[DEFAULT_URL_ATTR_NAME_SRC] != null) {
                    return element[DEFAULT_URL_ATTR_NAME_SRC];
                }
            };
        }],

        // containerElement: ["componentContainer", function ({ props, state, privates, locales }) {
        //     let componentContainer = props.componentContainer;
        //     if (componentContainer != null) {
        //         if (componentContainer instanceof HTMLElement) {
        //             return componentContainer;
        //         }
        //         else if (typeof componentContainer === "string") {
        //             return document.querySelector(componentContainer);
        //         }
        //     }
        //     return privates.ownContainer.current;
        // }],
    }
    //#endregion

    componentDidMount() {
        this.actions.init();
    }
    componentWillUnmount() {
        let imageViewerInstance = this.privates.imageViewerInstance;
        if (imageViewerInstance) {
            imageViewerInstance.destroy();
        }
    }

    actions = {
        init() {
            let options = {};
            for (let key of Object.keys(ImageViewerComponent.defaultProps)) {
                options[key] = this.props[key];
            }
            options.url = this.privates.computes.getUrlAttrName;

            let targetElement = this.privates.ownContainer.current;
            let componentContainer = this.props.componentContainer;
            if (componentContainer != null) {
                if (componentContainer instanceof HTMLElement) {
                    targetElement = componentContainer;
                }
                else if (typeof componentContainer === "string") {
                    targetElement = document.querySelector(componentContainer);
                }
            }

            if (targetElement) {
                let viewerApi = new Viewer(targetElement, options);
                this.privates.imageViewerInstance = viewerApi;
                this.privates.isFirstRanderImageView = false;
            }
        }
    }
}



export default ImageViewerComponent;