import React from "react";
import PropTypes from "prop-types";

export default class TaskConditionDictionariesComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        items: [],
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        items: PropTypes.array,
    };
    //#endregion

    //#region state
    state = {
        searchText: "",
        conditionDictionariesDisplay: false,
    };
    //#endregion

    privates = {
        searchText: "",
        conditionList: [],
    };

    //#region actions
    actions = {
        switchDetailsDisplay(item) {
            item.states.detailsDisplay = !item.states.detailsDisplay;
            this.actions.update();
        },

        onConditionDicChange() {
            this.setState({
                conditionDictionariesDisplay: !this.state.conditionDictionariesDisplay
            });
        },

        delSearchTex() {
            this.setState({
                searchText: ""
            });
        },

        searchCondition() {
            let { props, state, privates } = this;
            privates.searchText = state.searchText;
            let arr = [];
            props.items.forEach(item => {
                if (privates.searchText === "") {
                    arr.push({
                        data: item,
                        states: {
                            detailsDisplay: false
                        }
                    });
                } else if (
                    item.idCode.indexOf(privates.searchText) > -1 ||
                    item.name.indexOf(privates.searchText) > -1
                ) {
                    arr.push({
                        data: item,
                        states: {
                            detailsDisplay: false
                        }
                    });
                }
            });
            privates.conditionList = arr;
            this.actions.update();
        },

        onKeyDown(e) {
            if(e.keyCode === 13) {
                this.actions.searchCondition();
            }
        }
    };
    //#endregion
    //#region Life Cycle
    init() {
        let { privates, props } = this;
        if(Array.isArray(props.items)){
            privates.conditionList = props.items.map(item => {
                return {data: item, states: {detailsDisplay: false}};
            });
        }
    }
    componentDidMount() {
    }
    //#endregion
}
