// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostIntelligentPartnerHomePageTaskRankBody,
    BaseResponseListIntelligentPartnerHomePageRank,
} from '../../../types';

/**
 * 榜单接口
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [POST] /intelligentPartner/homePage/taskRank
 */
export function postIntelligentPartnerHomePageTaskRank(
    data: PostIntelligentPartnerHomePageTaskRankBody
): Promise<BaseResponseListIntelligentPartnerHomePageRank>;
export function postIntelligentPartnerHomePageTaskRank(
    data: PostIntelligentPartnerHomePageTaskRankBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerHomePageRank>;
export function postIntelligentPartnerHomePageTaskRank(
    data: PostIntelligentPartnerHomePageTaskRankBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerHomePageRank> {
    return request<PostIntelligentPartnerHomePageTaskRankBody, never, never>(
        '/intelligentPartner/homePage/taskRank',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerHomePageTaskRank',
        }
    );
}

export const meta = [
    { tags: ['智能陪练首页接口'], path: '/intelligentPartner/homePage/taskRank', method: 'post' },
];
