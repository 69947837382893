import React from 'react';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import LayoutMenu from 'Component/common/layout-menu';
import { Redirect } from 'react-router-dom';

export class TasksManageEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: this.props.match.params,
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let { route, location, sdkOptions, match } = this.props;

        let { locales, privates } = this;

        let navLists = [];
        let group = {};
        // 根据当前用户权限，组装菜单列表
        const task = sdkOptions.props.task;
        const hasFeature = task ? task._hasFeature : null;
        let taskId = privates.model.taskId;

        if (hasFeature && hasFeature('task:template:root')) {
            group = {
                text: locales.components.tasks.subMenu.qualityCheckTemplate.text,
                children: [],
            };
            if (hasFeature && hasFeature('task:template:info:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.basicInfo.text,
                    pathName: 'tasksBasicInfoIndex',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/basic-info');
                    },
                });
            }
            if (hasFeature && hasFeature('task:template:point:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.checkPoint.text,
                    pathName: 'tasksCheckPointEntry',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/check-point');
                    },
                });
            }
            if (Udesk.business.task.isVoiceCall(task.inspectDataSource) || Udesk.business.task.isTextDialogue(task.inspectDataSource)) {
                group.children.push({
                    text: locales.pages.tasks.manage.template.checkPointTest.title,
                    pathName: 'tasksCheckPointTestIndex',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/test-check-point');
                    },
                });
            }
            if (
                hasFeature &&
                hasFeature('task:template:condition:root') &&
                !this.privates.model.taskVersion.pointVersion
            ) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.condition.text,
                    pathName: 'tasksConditionEntry',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/condition');
                    },
                });
            }
            if (
                hasFeature &&
                hasFeature('task:template:grade:root') &&
                task.inspectDataSource !== Udesk.enums.inspectDataSources.ticket.id
            ) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.grade.text,
                    pathName: 'gradeIndex',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/grade');
                    },
                });
            }
            if (hasFeature && hasFeature('task:template:auto:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.automaticAdd.text,
                    pathName: 'tasksAutomaticAddIndex',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/automatic-add');
                    },
                });
            }
            if (
                hasFeature &&
                hasFeature('task:template:roleConfig:root') &&
                task &&
                task.inspectDataSource !== Udesk.enums.inspectDataSources.textDialogue.id &&
                task.inspectDataSource !== Udesk.enums.inspectDataSources.realTimeVoiceCall.id &&
                task.inspectDataSource !== Udesk.enums.inspectDataSources.realTimeTextDialogue.id &&
                task.inspectDataSource !== Udesk.enums.inspectDataSources.ticket.id &&
                task.inspectDataSource !== Udesk.enums.inspectDataSources.taobao.id
            ) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.roleConfig.text,
                    pathName: 'tasksRoleConfigIndex',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/role-config');
                    },
                });
            }
            if (
                hasFeature &&
                hasFeature('task:template:manual:root') &&
                task &&
                !task.related &&
                task.inspectDataSource !== Udesk.enums.inspectDataSources.realTimeVoiceCall.id &&
                task.inspectDataSource !== Udesk.enums.inspectDataSources.realTimeTextDialogue.id
            ) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.manualAdd.text,
                    pathName: 'tasksManualAddIndex',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/manual-add');
                    },
                });
            }
            navLists.push(group);
        }
        if (hasFeature && hasFeature('task:work:root')) {
            group = {
                text: locales.components.tasks.subMenu.qualityCheckWorkbench.text,
                children: [],
            };
            if (hasFeature && hasFeature('task:work:inpectionList:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckWorkbench.qualityInspectionList.text,
                    pathName: 'qualityInspectionList',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/quality-inspection-list');
                    },
                });
            }
            if (hasFeature && hasFeature('task:work:review:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckWorkbench.reviewList.text,
                    pathName: 'reviewList',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/review-list');
                    },
                });
            }
            if (hasFeature && hasFeature('task:work:sample:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckWorkbench.spotCheckList.text,
                    pathName: 'spotCheckList',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/spot-check-list');
                    },
                });
            }
            if (hasFeature && hasFeature('task:work:caseLibrary:root') && task && !task.related) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckWorkbench.caseBaseList.text,
                    pathName: 'caseBase',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/case-base');
                    },
                });
            }
            if (task.inspectDataSource !== Udesk.enums.inspectDataSources.ticket.id) {
                if (hasFeature && hasFeature('task:work:testset:root')) {
                    group.children.push({
                        text: locales.components.tasks.subMenu.qualityCheckWorkbench.testSetList.text,
                        pathName: 'testSetList',
                        pathParams: { taskId },
                        isActive: (match, location) => {
                            return location.pathname.includes('/test-set-list');
                        },
                    });
                }
            }
            navLists.push(group);
        }
        if (hasFeature && hasFeature('task:report:root')) {
            group = {
                text: locales.components.tasks.subMenu.statisticsAndReports.text,
                children: [],
            };
            if (hasFeature && hasFeature('task:report:business-analysis:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.statisticsAndReports.analysisOverview.text,
                    pathName: 'analysisOverview',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/analysis-overview');
                    },
                });
            }
            if (hasFeature && hasFeature('task:report:inspection:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.statisticsAndReports.inspectionItemsAnalysis.text,
                    pathName: 'inspectionItemsAnalysis',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/inspection-items-analysis');
                    },
                });
            }
            if (hasFeature && hasFeature('task:report:agent-analysis:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.statisticsAndReports.customerServiceExecution.text,
                    pathName: 'customerServiceExecution',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/customer-service-execution');
                    },
                });
            }
            if (hasFeature && hasFeature('task:report:all:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.statisticsAndReports.wholeReport.text,
                    pathName: 'tasksWholeReport',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/whole-report');
                    },
                });
            }
            if (hasFeature && hasFeature('task:report:agent:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.statisticsAndReports.agentReport.text,
                    pathName: 'tasksAgentReport',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/agent-report');
                    },
                });
            }
            navLists.push(group);
        }
        if (this.isRedirect(route, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].children[0].pathName,
                pathParams: match.params,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className='tasks-manage-entry'>
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={locales.components.pages.menuTitleList.tasksManageTitle}
                    />
                </div>
            );
        }
    }
    model() {
        const { props } = this;
        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );

        let associateFields = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/field/task/' + params.taskId, props.sdkOptions);
            Udesk.ajax.get(url, { type: 'associate' }).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.screeningCondition
                            )
                    );
                    reject(reason);
                }
            );
        });

        //  任务的数据源
        let taskPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + params.taskId, props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                            )
                    );
                    reject(reason);
                }
            );
        });

        // 任务权限数据源
        let taskMenuPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/menu/' + params.taskId, props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                            )
                    );
                    reject(reason);
                }
            );
        });

        // 检查质检点版本
        let taskVersionPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/${params.taskId}/version`,
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                            )
                    );
                    reject(reason);
                }
            );
        });

        return {
            taskId: params.taskId,
            associateFields: associateFields,
            task: taskPromise,
            taskMenu: taskMenuPromise,
            taskVersion: taskVersionPromise,
        };
    }
    onModelResolved(model) {
        let task = model.task;
        let features = model.taskMenu;
        let featureCodeMappings = {};
        if (features) {
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                featureCodeMappings[feature.permission] = true;
            }
        }
        if (!Object.prototype.hasOwnProperty.call(task, '_hasFeature')) {
            Object.defineProperty(task, '_hasFeature', {
                value: function (featureCode) {
                    return Object.prototype.hasOwnProperty.call(featureCodeMappings, featureCode);
                },
                enumerable: false,
                configurable: false,
                writable: false,
            });
        }
        this.props.sdkOptions.props.biTaskId = parseInt(model.taskId, 10);
        this.props.sdkOptions.props.task = task;
        this.props.sdkOptions.props.basicInfoFields = model.associateFields;
    }
    componentWillUnmount() {
        // 清理掉相关数据，否则会污染其他页面
        this.props.sdkOptions.props.biTaskId = null;
        this.props.sdkOptions.props.task = null;
        this.props.sdkOptions.props.basicInfoFields = null;
    }
}

export default Udesk.react.udeskify(TasksManageEntry);
