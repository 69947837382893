import React from 'react';
import Udesk from 'Udesk';
import EmotionAnalysisComponent from './component';
import ReactEcharts from 'udesk-react/src/components/react-echarts';
import ClassNames from 'classnames';
import './style.scss';

export default class EmotionAnalysisTemplate extends EmotionAnalysisComponent {
    render() {
        let {
            classNames
        } = this.props;
        let {
            _emotions
        } = this.privates.computes;

        let modelData = Udesk.business.report.getChartDataFix(this, _emotions);

        return (
            <div className={ClassNames("component-emotion-analysis", { [`${classNames}`]: classNames })}>
                <ReactEcharts type="line" xLabelField="xLabel" records={modelData.items} seriesColumns={modelData.columns} />
            </div>
        );
    }
}