import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import PropTypes from 'prop-types';
// import _debounce from 'lodash-es/debounce';

export default class CheckPointOperatorListGatherComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        conditionItem: {},
        operatorTypes: [],
        onChange: null,
        entityUrl: '',
        conceptWordUrl: '',
        entities: [],
        entityFields: [],
        disabled: false,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        conditionItem: PropTypes.object,
        operatorTypes: PropTypes.array,
        onChange: PropTypes.func,
        entityUrl: PropTypes.string,
        conceptWordUrl: PropTypes.string,
        entities: PropTypes.array,
        entityFields: PropTypes.array,
        disabled: PropTypes.bool,
    };
    //#endregion
    state = {
        isSemanticTagsModalVisible: false,
        semanticSentence: null,
        semanticTags: [],
        columns: [],
    };
    privates = {
        operatorItem: {},
        selectValue: '',
        selectInputValue: '',
        selectOptionList: {
            conceptWord: [],
            element: [],
            entityInfoDetection: [],
        },
        treeSelectOptionList: [],
        interactiveRule: 2,
        showAssociate: false,
        associateInputRef: React.createRef(),
        associateDivRef: React.createRef(),
        showAssociateTimer: null,
        associateActiveTab: 'data',
        operatorList: [],
        associateList: [],
        associateOperatorList: [],
        associateOperatorListSource: [],
        associateListSource: [],
        associateListSearchValue: '',
        associateOperatorIconList: [
            {
                subType: 0,
                icon: 'hanshu',
            },
            {
                subType: 1,
                icon: 'jia',
            },
            {
                subType: 2,
                icon: 'jian',
            },
            {
                subType: 3,
                icon: 'cheng',
            },
            {
                subType: 4,
                icon: 'chu',
            },
            {
                subType: 21,
                icon: 'dengyu',
            },
            {
                subType: 22,
                icon: 'dayu',
            },
            {
                subType: 23,
                icon: 'dayudengyu',
            },
            {
                subType: 24,
                icon: 'xiaoyu',
            },
            {
                subType: 25,
                icon: 'xiaoyudengyu',
            },
            {
                subType: 51,
                icon: 'yu',
            },
            {
                subType: 52,
                icon: 'huo',
            },
            {
                subType: 53,
                icon: 'fei',
            },
        ],
    };

    actions = {
        changeOperatorItemType(operatorItem, value) {
            Object.keys(operatorItem.operatorList[0]).forEach((key) => {
                if (
                    key !== '_id' &&
                    key !== 'operatorType' &&
                    key !== 'taskId' &&
                    key !== 'type' &&
                    key !== 'id' &&
                    key !== 'idCode' &&
                    key !== 'operator' &&
                    key !== 'conditionType' &&
                    key !== 'applyRole' &&
                    key !== 'applyConditionHitType' &&
                    key !== 'applyOperatorScope'
                ) {
                    delete operatorItem.operatorList[0][key];
                }
            });
            operatorItem.operatorList[0].type = value;
            operatorItem.operatorType = value;
            if (operatorItem.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id) {
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.entityInfoDetection.id,
                };
                // this.actions.getInformationEntity();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.volume.id) {
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.volume.id,
                    operator: Udesk.enums.volumeDetectionOperators.is.id,
                    detectionWay: Udesk.enums.volumeDetectionWays.loudness.id,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.emotion.id) {
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.emotion.id,
                    operator: Udesk.enums.emotionOperators.is.id,
                    sentiment: Udesk.enums.sentimentTypes.positive.id,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.sentenceLength.id) {
                //句长检测
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.sentenceLength.id,
                    compareType: Udesk.enums.sentenceLength.less.id,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.semanticTags.id) {
                operatorItem.semanticTag = {};
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.conceptWord.id) {
                // 概念词
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.conceptWord.id,
                    compareType: Udesk.enums.conceptWordOperators.any.id,
                };
                // this.actions.getConceptWords();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id) {
                //一问一答
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.questionsAndAnswers.id,
                    interactiveRule: Udesk.enums.interactiveRules.allRight.id,
                };
                // this.actions.getInteractionInitTree();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.element.id) {
                //要素问答
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.element.id,
                };
                // this.actions.getMultiElements();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.associate.id) {
                //随路字段
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.associate.id,
                };
                // this.actions.getAssociate();
                // this.actions.getAssociateOperator();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.repeat.id) {
                //重复话术
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.repeat.id,
                    repeatType: Udesk.enums.repeatRules.same.id,
                };
            }
            this.actions.handleOperatorItemChange();
        },
        changeOperatorItem(operatorItem, key, value, label, extra) {
            //区分是否是概念词
            if (operatorItem.operatorType === Udesk.enums.operatorTypes.conceptWord.id) {
                if (key === 'compareType') {
                    operatorItem.operatorList[0].param[key] = value;
                }
                // if(key === 'conceptWord'){
                //     let conceptWordList= this.privates.selectOptionList.conceptWord;
                //     operatorItem.operatorList[0].param[key] = conceptWordList.find(item => item.id === value);
                // }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id) {
                //区分一问一答
                if (key === 'interactiveCategory') {
                    operatorItem.operatorList[0].param.interactiveCategoryId = value;
                    operatorItem.operatorList[0].param.interactiveCategoryName = label[0];
                    operatorItem.operatorList[0].keyword = value;
                }
                if (key === 'interactiveRule') {
                    operatorItem.operatorList[0].param.interactiveRule = value.target.value;
                }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.element.id) {
                //区分要素问答
                if (key === 'compareType') {
                    operatorItem.operatorList[0].param.hit = value;
                }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.sentenceLength.id) {
                //区分句长检测
                if (key === 'compareType') {
                    operatorItem.operatorList[0].param[key] = value;
                }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.volume.id) {
                //区分音量检测
                operatorItem.operatorList[0].param[key] = value;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.emotion.id) {
                //区分情绪分析
                operatorItem.operatorList[0].param[key] = value;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id) {
                //区分信息实体
                operatorItem.operatorList[0].param[key] = value;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.repeat.id) {
                //区分重复话术
                operatorItem.operatorList[0].param[key] = value.target.value;
            } else {
                operatorItem.operatorList[0][key] = value;
            }
            this.actions.handleOperatorItemChange();
        },
        handleOperatorItemChange() {
            this.actions.update();
            this.trigger('onChange', this.privates.operatorItem);
        },
        changeInputValue(operatorItem, key, value) {
            if (value instanceof Object) {
                if (key === 'expectedValue' || key === 'inputContent') {
                    operatorItem.operatorList[0].param[key] = value.target.value;
                } else {
                    operatorItem.operatorList[0][key] = value.target.value;
                }
            } else {
                if (key === 'similarity' || key === 'repeatTimes' || key === 'wordsNum') {
                    operatorItem.operatorList[0].param[key] = value;
                } else {
                    operatorItem.operatorList[0][key] = value;
                }
            }
            if (key === 'semanticSentence') {
                this.setState({
                    semanticSentence: operatorItem.operatorList[0][key],
                });
            }
            this.actions.handleOperatorItemChange();
        },
        changeInputValueInParam(operatorItem, key, value) {
            if (value instanceof Object) {
                operatorItem.operatorList[0].param[key] = value.target.value;
            } else {
                operatorItem.operatorList[0].param[key] = value;
            }
            this.actions.handleOperatorItemChange();
        },
        changeSemanticTagsModalVisible(operatorItem) {
            let isSemanticTagsModalVisible = this.state.isSemanticTagsModalVisible;
            this.setState({
                isSemanticTagsModalVisible: !isSemanticTagsModalVisible,
            });
            if (!isSemanticTagsModalVisible) {
                this.setState({
                    operatorItem: operatorItem,
                });
                this.setState({
                    semanticSentence: operatorItem.semanticSentence,
                });
                getColumns(this, operatorItem);
                this.actions.querySemanticTags(operatorItem.operatorList[0].semanticSentence);
            }
        },
        changeSemanticSentence(e) {
            let operatorItem = this.state.operatorItem;
            this.setState({
                semanticSentence: e.target.value,
            });
            operatorItem.semanticSentence = e.target.value;
        },
        querySemanticTags(semanticSentence) {
            if (semanticSentence == null || semanticSentence.trim() === '') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.components.operatorListGather.querySemanticTags
                    )
                );
            }

            let url = Udesk.business.apiPath.concatApiPath('/smartTags/findSemanticList', this.props.sdkOptions);
            let params = {
                keyword: semanticSentence,
            };
            Udesk.ajax.get(url, params).then(
                (resp) => {
                    if (resp && resp.data) {
                        let data = [];
                        resp.data.forEach((item) => {
                            data.push({
                                key: item.semanticId,
                                semanticName: item.semanticName,
                                sentence: item.sentence,
                                similarity: item.similarity,
                                groupId: item.groupId,
                            });
                        });
                        this.setState({
                            semanticTags: data,
                        });
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        handleSelectChange(operatorItem, key, value) {
            operatorItem.operatorList[0].param[key] = value;
            this.actions.handleOperatorItemChange();
        },
        handleSelectSearch(type, value) {
            this.privates.selectInputValue = value;
            // if (type === 'entity') {
            //     _debounce(this.actions.getInformationEntity, 500)();
            // } else if (type === 'expectedValueObject') {
            //     return;
            // }
        },
        conceptWordChanged(operatorItem, value) {
            operatorItem.operatorList[0].param.conceptWord = value;
            operatorItem.operatorList[0].keyword = value != null ? value.id.toString() : null;
            this.actions.handleOperatorItemChange();
        },
        getInformationEntity() {
            let { props } = this;
            let url = Udesk.business.apiPath.concatApiPath(`/information-entity/list`, props.sdkOptions);
            Udesk.ajax
                .post(
                    url,
                    this.privates.selectInputValue ? { keyword: this.privates.selectInputValue, activeFlag: 1 } : {}
                )
                .then(
                    (resp) => {
                        this.privates.selectOptionList.entityInfoDetection = resp.data;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        },
        // getConceptWords(){
        //     let {props} = this;
        //     let url = Udesk.business.apiPath.concatApiPath(`/conceptWords/findAll`, props.sdkOptions);
        //     Udesk.ajax.post(url, this.privates.selectInputValue?{keyword: this.privates.selectInputValue}:{}).then(
        //         resp => {
        //             this.privates.selectOptionList.conceptWord = resp.data;
        //             this.actions.update();
        //         },
        //         reason => {
        //             Udesk.ui.notify.error(reason.errorMsg);
        //         }
        //     );
        // },
        getInteractionInitTree() {
            let { props } = this;
            let url = Udesk.business.apiPath.concatApiPath('/interactiveCategories/initTree', props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.privates.treeSelectOptionList = getTreeData(resp.data);
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getMultiElements() {
            let { props } = this;
            let url = Udesk.business.apiPath.concatApiPath('/interactiveDatas/multiElements', props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.privates.selectOptionList.element = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getAssociate() {
            let { sdkOptions } = this.props;
            let fieldUrl = Udesk.business.apiPath.concatApiPath(`field/data/search-template`, sdkOptions);
            // let data = { sourceList: [], typeList: [1] };
            // if (type === 0) {
            //     data.sourceList =  [1, 2];
            // } else {
            //     data.sourceList =  type;
            // }
            let type = Udesk.enums.inspectDataSources.voiceCall.id;
            this.privates.type = type;
            if (
                sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id
            ) {
                type = Udesk.enums.inspectDataSources.voiceCall.id;
                this.privates.type = type;
            } else if (
                sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
                sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id
            ) {
                type = Udesk.enums.inspectDataSources.textDialogue.id;
                this.privates.type = type;
            }
            let data = {
                sourceList: [type],
                typeList: [1, 3, 6],
            };
            Udesk.ajax.post(fieldUrl, data).then(
                (resp) => {
                    this.privates.associateList = resp.data[0].typeList;
                    this.privates.associateListSource = resp.data[0].typeList;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getAssociateOperator() {
            let { sdkOptions } = this.props;
            let fieldUrl = Udesk.business.apiPath.concatApiPath(`operator/meta`, sdkOptions);
            let data = {
                typeList: [1, 2],
            };
            Udesk.ajax.post(fieldUrl, data).then(
                (resp) => {
                    this.privates.associateOperatorList = resp.data;
                    this.privates.associateOperatorListSource = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    // let demo = [
                    //     {
                    //         type: 1,
                    //         list: [
                    //             {
                    //                 type: 1,
                    //                 subType: 1,
                    //                 view: '+',
                    //                 note: '加号',
                    //             },
                    //             {
                    //                 type: 1,
                    //                 subType: 2,
                    //                 view: '-',
                    //                 note: '-号',
                    //             }
                    //         ]
                    //     },
                    //     {
                    //         type: 2,
                    //         list: [
                    //             {
                    //                 type: 2,
                    //                 view: 'aaaa(xxxxx,xxxx,xxx)',
                    //                 note: 'xvxxxxxxxxxx',
                    //             },
                    //             {
                    //                 type: 2,
                    //                 view: 'bb(xax,xxx,xx)',
                    //                 note: 'xxxxxxxxxxxx',
                    //             }
                    //         ]
                    //     },
                    // ];
                    // this.privates.associateOperatorList = demo;
                    // this.privates.associateOperatorListSource = demo;
                }
            );
        },
        validateGrabLength(operatorItem, attribute, value) {
            if (value < 1) {
                operatorItem[attribute] = 1;
                this.actions.handleOperatorItemChange();
            }

            if (operatorItem.operatorList[0][attribute] == null || operatorItem.operatorList[0][attribute] === '') {
                return Udesk.ui.notify.error(UdeskLocales.current.components.operatorListGather.grabLengthNotNull);
            }
        },
        validateGrabInterval(operatorItem, attribute, value) {
            if (operatorItem.operatorList[0][attribute] == null || operatorItem.operatorList[0][attribute] === '') {
                return Udesk.ui.notify.error(UdeskLocales.current.components.operatorListGather.grabIntervalNotNull);
            }
        },
        validateMinValue(operatorItem, attribute, value) {
            if (value < 0) {
                operatorItem[attribute] = 0;
                this.actions.handleOperatorItemChange();
            }
        },
        validateSpeedValue(operatorItem, attribute, value) {
            let changed = false;
            if (value < 0) {
                operatorItem.operatorList[0][attribute] = 0;
                changed = true;
            }

            if (
                (operatorItem.operatorList[0].wordPerMinite == null ||
                    operatorItem.operatorList[0].wordPerMinite === '') &&
                (operatorItem.operatorList[0].maxWordPerMinite == null ||
                    operatorItem.operatorList[0].maxWordPerMinite === '')
            ) {
                return Udesk.ui.notify.error(UdeskLocales.current.components.operatorListGather.speedWordNotNull);
            }

            if (operatorItem.operatorList[0].wordPerMinite && operatorItem.operatorList[0].maxWordPerMinite) {
                if (
                    parseInt(operatorItem.operatorList[0].wordPerMinite, 10) >
                    parseInt(operatorItem.operatorList[0].maxWordPerMinite, 10)
                ) {
                    operatorItem.operatorList[0][attribute] = 0;
                    changed = true;
                    return Udesk.ui.notify.error(
                        UdeskLocales.current.components.operatorListGather.speedWordMinValueLessThanMaxValue
                    );
                }
            }

            if (changed) {
                this.actions.handleOperatorItemChange();
            }
        },
        validateVolumDetectionValue(operatorItem, value) {
            let changed = false;
            if (operatorItem.operatorList[0].param.detectionWay === Udesk.enums.volumeDetectionWays.loudness.id) {
                if (value < 0) {
                    operatorItem.operatorList[0].param.detectionValue = 0;
                    changed = true;
                }
                if (value > 200) {
                    operatorItem.operatorList[0].param.detectionValue = 200;
                    changed = true;
                }
            } else {
                if (value < -1000) {
                    operatorItem.operatorList[0].param.detectionValue = -1000;
                    changed = true;
                }
                if (value > 1000) {
                    operatorItem.operatorList[0].param.detectionValue = 1000;
                    changed = true;
                }
            }
            if (changed) {
                this.actions.handleOperatorItemChange();
            }
        },
        applyToCondition(item) {
            let operatorItem = this.privates.operatorItem;
            operatorItem.keyword = item.groupId;
            operatorItem.semanticId = item.key;

            if (!operatorItem.semanticTag) {
                operatorItem.semanticTag = {};
            }

            operatorItem.semanticTag.semanticName = item.semanticName;
            operatorItem.semanticTag.sentence = item.sentence;
            this.actions.handleOperatorItemChange();
        },
        openEditTagWindow(item) {
            let url = `/site/semantic-intelligence/semantic-tags/edit/${item.groupId}`;
            if (this.props.sdkOptions.props.domain) {
                url = this.props.sdkOptions.props.domain + url;
            }
            Udesk.utils.web.openWindow(url, '_blank');
        },
        seleteSemanticName(selectedRowKeys, selectedRows) {
            let operatorItem = this.privates.operatorItem;
            operatorItem.operatorList[0].keyword = selectedRows[0].groupId;
            operatorItem.operatorList[0].semanticId = selectedRows[0].key;

            // if (!operatorItem.semanticTag) {
            //     operatorItem.semanticTag = {};
            // }

            operatorItem.operatorList[0].semanticTag = selectedRows[0];
            // operatorItem.semanticTag.sentence = selectedRows[0].sentence;
            this.actions.handleOperatorItemChange();
        },
        openCreateTagWindow() {
            let url = '/site/semantic-intelligence/semantic-tags/new';
            if (this.props.sdkOptions.props.domain) {
                url = this.props.sdkOptions.props.domain + url;
            }
            Udesk.utils.web.openWindow(url, '_blank');
        },
        showAssociate(type, visible) {
            if (type === 'focus') {
                let that = this;
                clearTimeout(this.privates.showAssociateTimer);
                this.privates.showAssociateTimer = setTimeout(() => {
                    this.privates.showAssociate = !this.privates.showAssociate;
                    that.actions.update();
                }, 100);
                if (this.privates.showAssociate) {
                    clearTimeout(this.privates.showAssociateTimer);
                }
            }
            if (type === 'click') {
                this.privates.showAssociateTimer = setTimeout(() => {
                    this.privates.showAssociate = !this.privates.showAssociate;
                    this.actions.update();
                }, 100);
            }
        },
        associateInputFocus() {
            if (
                this.privates.associateDivRef.current.firstChild.selectionStart !==
                this.privates.associateDivRef.current.firstChild.selectionEnd
            ) {
                // let start = this.privates.associateDivRef.current.firstChild.selectionStart;
                this.privates.associateInputRef.current.focus();
                // let that = this;
                // setTimeout(() => {
                //     that.privates.associateDivRef.current.firstChild.selectionStart = start;
                //     that.privates.associateDivRef.current.firstChild.selectionEnd = start;
                // },100);
            } else {
                this.privates.associateInputRef.current.focus();
            }
        },
        associateSearchChange(e) {
            e.stopPropagation();
            if (this.privates.associateListSearchValue) {
                this.actions.setAssociateList();
            } else {
                this.privates.associateList = this.privates.associateListSource;
                this.privates.associateOperatorList = this.privates.associateOperatorListSource;
                this.actions.update();
            }
        },
        setAssociateList(value) {
            if (this.privates.associateListSearchValue === '[' || this.privates.associateListSearchValue === ']') {
                this.privates.associateList = this.privates.associateListSource;
            } else {
                this.privates.associateList = this.privates.associateListSource.map((associate) => {
                    if (associate.fieldList) {
                        return {
                            type: associate.type,
                            fieldList: associate.fieldList.filter(
                                (item) => item.label.indexOf(this.privates.associateListSearchValue) !== -1
                            ),
                        };
                    }
                    return {
                        type: associate.type,
                        fieldList: [],
                    };
                });
            }
            this.privates.associateOperatorList = this.privates.associateOperatorListSource.map((operatorList) => {
                if (operatorList.list) {
                    return {
                        type: operatorList.type,
                        list: operatorList.list.filter(
                            (item) =>
                                item.view.indexOf(this.privates.associateListSearchValue) !== -1 ||
                                item.note.indexOf(this.privates.associateListSearchValue) !== -1
                        ),
                    };
                }
                return {
                    type: operatorList.type,
                    list: [],
                };
            });
            this.actions.update();
        },
        associateListSearchChange(e) {
            this.privates.associateListSearchValue = e.target.value;
            if (e.target.value === '') {
                this.privates.associateList = this.privates.associateListSource;
                this.privates.associateOperatorList = this.privates.associateOperatorListSource;
                this.actions.update();
            }
        },
        associateTabsChange(activeKey) {
            this.privates.associateActiveTab = activeKey;
            this.actions.update();
        },
        associateDataClick(operatorItem, type, item, e) {
            if (!operatorItem.operatorList[0].param.inputContent) {
                operatorItem.operatorList[0].param.inputContent = '';
            }
            let textAreaDom = this.privates.associateDivRef.current.firstChild;
            //为运算操作符前后添加空格，以区分是否为新增字符
            let arrNew = operatorItem.operatorList[0].param.inputContent.split('');
            if (type === 1 && item.length === 1) {
                item = ' ' + item + ' ';
            }
            //删除示例中的形参
            if (type === 2) {
                let itemCopy = item.split('');
                itemCopy.splice(item.indexOf('(') + 1, item.lastIndexOf(')') - item.indexOf('(') - 1);
                item = itemCopy.join('');
            }
            arrNew.splice(textAreaDom.selectionStart, 0, item);
            operatorItem.operatorList[0].param.inputContent = arrNew.join('');
            let that = this;
            textAreaDom.focus();
            setTimeout(() => {
                let res = operatorItem.operatorList[0].param.inputContent.length;
                //根据元素长度判断是否新增，新增元素长度恒大于1
                arrNew.forEach((ele, index) => {
                    //新添加的元素是函数操作符(包含()且不是数据)
                    if (ele.indexOf('(') >= 1 && ele.indexOf('[') === -1 && ele.indexOf(']') === -1) {
                        res = index + ele.indexOf('(') + 1;
                    }
                    //新添加的元素在函数的()中
                    if (ele.indexOf('(') < 1 && arrNew[index + 1] && arrNew[index + 1].length !== 1) {
                        res = index + arrNew[index + 1].length + 1;
                    }
                    //新添加的元素是数据或运算操作符
                    if (ele.indexOf('(') < 1 && arrNew[index + 1] && arrNew[index].length !== 1) {
                        res = index + arrNew[index].length;
                    }
                });
                //定位光标位置，selectionStart与selectionEnd不同可实现自动选中某区间效果
                that.privates.associateDivRef.current.firstChild.selectionStart = res;
                that.privates.associateDivRef.current.firstChild.selectionEnd = res;
            }, 100);
            //阻止冒泡
            e.stopPropagation();

            this.actions.handleOperatorItemChange();
        },
    };
    parseProps({ props, prevProps, state, privates, isInitial }) {
        if (privates.operatorItem.operatorType === 13) {
            // this.actions.getInformationEntity();
        }
        let operatorItem = JSON.parse(JSON.stringify(props.conditionItem));
        if (!operatorItem.operatorType) {
            operatorItem.operatorType = 0;
        }
        if (privates.operatorItem.operatorType === 12) {
            // this.actions.getInteractionInitTree();
        }
        // this.actions.changeOperatorItemType(privates.operatorItem);
        return {
            operatorItem: operatorItem,
        };
    }
    init() {
        // if(this.props.conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.questionsAndAnswers.id){
        //     this.actions.getInteractionInitTree();
        // }
        // if(this.props.conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.element.id) {
        //     this.actions.getMultiElements();
        // }
        // if(this.props.conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.associate.id){
        //     this.actions.getAssociate();
        //     this.actions.getAssociateOperator();
        // }
    }
}

function getTreeData(tree) {
    return tree.map((item) => {
        return { key: item.id, value: item.id, title: item.name, children: getTreeData(item.children) };
    });
}
function getColumns(that, operatorItem) {
    let { actions, locales } = that;
    let columns = [
        {
            title: UdeskLocales.current.components.operatorListGather.semanticName,
            dataIndex: 'semanticName',
            width: 120,
        },
        {
            title: UdeskLocales.current.components.operatorListGather.sentence,
            dataIndex: 'sentence',
        },
        {
            title: UdeskLocales.current.components.operatorListGather.similarity,
            dataIndex: 'similarity',
            width: 80,
        },
        {
            title: UdeskLocales.current.components.operatorListGather.actions,
            dataIndex: 'actions',
            key: 'x',
            width: 60,
            render: (text, record) => {
                return (
                    <div>
                        <button className='action-btn delete-btn' onClick={actions.openEditTagWindow.params(record)}>
                            {locales.labels.edit}
                        </button>
                        {/* <button
                            className="action-btn delete-btn"
                            style={{marginLeft: "5px"}}
                            onClick={actions.applyToCondition.params(record)}
                        >
                            {locales.labels.apply}
                        </button> */}
                    </div>
                );
            },
        },
    ];
    that.setState({
        columns,
    });
}
