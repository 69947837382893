import React from 'react';
import Udesk from 'Udesk';
import { getCurrentRouteName, isGeneralSetting } from 'Udesk/system/subApp';

class RoleDetailTaskPermissionsComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        id: this.props.match.params.id,
        defaultMenuList: [],
        inspectorMenuList: [],
        taskList: [],
    };

    static computes = {
        _myCreatedTag: [
            'privates.defaultMenuList',
            function ({ props, privates }) {
                return isAllChecked(privates.defaultMenuList);
            },
        ],
        _myParticipateTag: [
            'privates.inspectorMenuList',
            function ({ props, privates }) {
                return isAllChecked(privates.inspectorMenuList);
            },
        ],
        _allTag: [
            'privates.inspectorMenuList',
            'privates.defaultMenuList',
            'privates.taskList',
            function ({ props, privates }) {
                if (!isAllChecked(privates.defaultMenuList)) return false;
                if (!isAllChecked(privates.inspectorMenuList)) return false;
                for (let i = 0; i < privates.taskList.length; i++) {
                    if (!privates.taskList[i]._tag) return false;
                }
                return true;
            },
        ],
    };

    actions = {
        goToEdit() {
            let { match } = this.props;
            const id = match.params.id;
            const sysModule = parseInt(match.params.sysModule, 10);
    
            let routeOptions = {
                history: this.props.history,
                routeName: 'generalSettingRoleEditTaskPermissions',
                pathParams: { id, sysModule },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        backToIndex() {
            if(isGeneralSetting()){
                window.history.back();
            }else{
                let routeOptions = {
                    history: this.props.history,
                    routeName: getCurrentRouteName('roleIndex'),
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        checkedAll(checked) {
            if (checked) {
                this.privates.defaultMenuList = getAllMenuList(this);
                this.privates.inspectorMenuList = getAllMenuList(this);
                this.privates.taskList.forEach((task) => {
                    task._tag = true;
                    task.taskMenuList = getAllMenuList(this);
                });
            } else {
                this.privates.defaultMenuList = [];
                this.privates.inspectorMenuList = [];
                this.privates.taskList.forEach((task) => {
                    task._tag = false;
                    task.taskMenuList = [];
                });
            }
            this.actions.update();
        },
        checkedMyCreatedAll(checked) {
            if (checked) {
                this.privates.defaultMenuList = getAllMenuList(this);
            } else {
                this.privates.defaultMenuList = [];
            }
            this.actions.update();
        },
        checkedMyParticipateAll(checked) {
            if (checked) {
                this.privates.inspectorMenuList = getAllMenuList(this);
            } else {
                this.privates.inspectorMenuList = [];
            }
            this.actions.update();
        },
        checkedTaskAll(task, checked) {
            if (checked) {
                task._tag = true;
                task.taskMenuList = getAllMenuList(this);
            } else {
                task._tag = false;
                task.taskMenuList = [];
            }
            this.actions.update();
        },
        checkedMyCreatedMenu() {
            this.actions.update();
        },
        checkedTaskMenu(task) {
            task._tag = isAllChecked(task.taskMenuList);
            this.actions.update();
        },
        saveMenuList() {
            let { locales } = this;
            let { sdkOptions } = this.props;
            let { id, defaultMenuList, inspectorMenuList, taskList } = this.privates;
            if (defaultMenuList) {
                defaultMenuList = defaultMenuList.map((item) => {
                    return item.id;
                });
            }
            if (inspectorMenuList) {
                inspectorMenuList = inspectorMenuList.map((item) => {
                    return item.id;
                });
            }
            if (taskList) {
                taskList = taskList.filter((task) => {
                    return task.taskMenuList.length > 0;
                });
                taskList = taskList.map((task) => {
                    return {
                        taskId: task.taskId,
                        taskMenuList: task.taskMenuList.map((item) => {
                            return item.id;
                        }),
                    };
                });
            }

            let params = {};
            if (defaultMenuList.length > 0) params.defaultMenuList = defaultMenuList;
            if (inspectorMenuList.length > 0) params.inspectorMenuList = inspectorMenuList;
            if (taskList.length > 0) params.taskMenuList = taskList;

            let url = Udesk.business.apiPath.concatApiPath(`roleInspectionPermissions`, sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            if (id) {
                url = Udesk.business.apiPath.concatApiPath(`roleInspectionPermissions/${id}`, sdkOptions);
                method = Udesk.enums.requestMethods.update.name;
            }

            Udesk.ajax(url, params, method).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locales.components.pages.role.detail.title
                        )
                    );
                    this.actions.backToIndex();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locales.components.pages.role.detail.title
                            )
                    );
                }
            );
        },
        getNodeBehaviors() {
            let { route } = this.props;
            return {
                expanded: false,
                disabled: route.name === 'roleViewTaskPermissions',
            };
        },
        isAllChecked(checkedList) {
            isAllChecked(checkedList);
        },
    };

    //#region Life Cycle
    componentDidMount() {}
    componentWillUnmount() {}
    //#endregion
}

function getAllMenuList(self) {
    return Udesk.data.init.allTaskMenuList.map((item) => {
        return { id: item.id };
    });
}

function isAllChecked(checkedList) {
    const allList = Udesk.data.init.allTaskMenuList;
    for (let i = 0; i < allList.length; i++) {
        let checkedItem = checkedList.find((item) => {
            return item.id === allList[i].id;
        });
        if (!checkedItem) {
            return false;
        }
    }
    return true;
}

export default RoleDetailTaskPermissionsComponent;
