import React, { useEffect, useRef, useState, } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import 'udesk-ui/lib/form/style/index.less';
import './style.scss';
import UdeskLocales from 'UdeskLocales';
import { getIntelligentPartnerTaskFinishRecordsShareLogByShareIdDetail } from 'src/api/intelligentPartnerTaskFinishRecords/shareLog/{shareId}/detail/index';
import { Dialogue, RefType } from 'Component/pages/components/coach/Dialogue';
import { IntelligentPartnerSentence } from 'src/api/types';
import Audio from 'src/pages/coach/learning-center/record/detail/components/Audio/index';

const CaseDetail: React.FC<any> = (props) => {
    const dialogueRef = useRef<RefType>(null);
    const recordId = parseInt(props.match.params.id, 10);
    const [scoreAnalysis, setScoreAnalysis] = useState<IntelligentPartnerSentence[]>([]);
    const [voice, setVoice] = useState<string>();
    
    useEffect(() => {
        if (recordId) {
            getIntelligentPartnerTaskFinishRecordsShareLogByShareIdDetail({
                segments: { shareId: recordId }
            }).then((res)=>{
                //语音播报
                if (res.data) {
                    setVoice(res.data?.voice);
                    setScoreAnalysis(res.data?.dialogSentenceList || []);
                }
            });
        }
    }, [recordId]);


    return (
        <Page
            pageBodyClassName="wechat-message-notification-page"
            title={
                /* 案例详情 */ UdeskLocales['current'].pages.wechatNotificationAnalysis
                    .messageNotification.index.caseDetails
            }
            // padding={true}
        >
            <Audio src={voice} dialogueRef={dialogueRef} scoreAnalysis={scoreAnalysis} />
            <Dialogue
                ref={dialogueRef}
                dialogueSentenceList={scoreAnalysis}
                itemRender={(props, index) => {
                    return (
                        <React.Fragment>
                            <Dialogue.Item
                                // isChangeMatchResult={
                                //     !isEqual(
                                //         scoreAnalysis?.[index],
                                //         scoreAnalysisDataSource?.[index]
                                //     )
                                // }
                                // isAudit={isAudit}
                                // isReview={isReview}
                                // isEdit={inspect(isEdit, () => {
                                //     if (isReview) {
                                //         return !detail?.isFinishChecked && !!props.appealContent;
                                //     }
                                //     if (isAudit) {
                                //         return !detail?.isFinishReview;
                                //     }
                                //     return true;
                                // })}
                                // setPropValue={(path, value) =>
                                //     setPropValue(`${index}.traineeWordsResult.${path}`, value)
                                // }
                                // getPropValue={(path) =>
                                //     getPropValue(`${index}.traineeWordsResult.${path}`)
                                // }
                                // getPropValueWithDataSource={(path) =>
                                //     getPropValueWithDataSource(
                                //         `${index}.traineeWordsResult.${path}`
                                //     )
                                // }
                                isShare={true}
                                isAllOpen={false}
                                index={index}
                                showCorrect={true}
                                // recordId={recordId}
                                {...props}
                            />
                        </React.Fragment>
                    );
                }}
            />
        </Page>
    );
};

class Component extends React.Component {
    render() {
        return <CaseDetail {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
