// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostPreprocessRulesFindAllDataCategoryTypeQuery,
    PostPreprocessRulesFindAllDataCategoryTypeBody,
    BaseResponseListPreprocessRuleFoundResponse,
} from '../../types';

/**
 * findAllDataCategoryType
 *
 * @export
 * @tags preprocess-rule-controller
 * @link [POST] /preprocessRules/findAllDataCategoryType
 */
export function postPreprocessRulesFindAllDataCategoryType(
    data: PostPreprocessRulesFindAllDataCategoryTypeBody
): Promise<BaseResponseListPreprocessRuleFoundResponse>;
export function postPreprocessRulesFindAllDataCategoryType(
    data: PostPreprocessRulesFindAllDataCategoryTypeBody,
    options: ApiOptions<never, PostPreprocessRulesFindAllDataCategoryTypeQuery>
): Promise<BaseResponseListPreprocessRuleFoundResponse>;
export function postPreprocessRulesFindAllDataCategoryType(
    data: PostPreprocessRulesFindAllDataCategoryTypeBody,
    options?: ApiOptions<never, PostPreprocessRulesFindAllDataCategoryTypeQuery>
): Promise<BaseResponseListPreprocessRuleFoundResponse> {
    return request<
        PostPreprocessRulesFindAllDataCategoryTypeBody,
        never,
        PostPreprocessRulesFindAllDataCategoryTypeQuery
    >('/preprocessRules/findAllDataCategoryType', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postPreprocessRulesFindAllDataCategoryType',
    });
}

export const meta = [
    {
        tags: ['preprocess-rule-controller'],
        path: '/preprocessRules/findAllDataCategoryType',
        method: 'post',
    },
];
