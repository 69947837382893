// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { BaseResponseint } from '../../../../types';

/**
 * 查询未查看的复核记录
 *
 * @export
 * @tags 智能陪练任务评审/申诉接口
 * @link [GET] /intelligentPartner/check/record/unViewed
 */
export function getIntelligentPartnerCheckRecordUnViewed(): Promise<BaseResponseint>;
export function getIntelligentPartnerCheckRecordUnViewed(
    options: ApiOptions<never, never>
): Promise<BaseResponseint>;
export function getIntelligentPartnerCheckRecordUnViewed(
    options?: ApiOptions<never, never>
): Promise<BaseResponseint> {
    return request<never, never, never>('/intelligentPartner/check/record/unViewed', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerCheckRecordUnViewed',
    });
}

export const meta = [
    {
        tags: ['智能陪练任务评审/申诉接口'],
        path: '/intelligentPartner/check/record/unViewed',
        method: 'get',
    },
];
