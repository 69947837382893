import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CustomerInfo from '../../components/customer-info';
import ChatRecordPanel from '../../components/record/voice/index';
import VideoRecord from '../../components/record/video';
import FlowPanel from '../../components/flow';
import TaskCue from '../../components/cue';
import TaskMessageModal, { RefType } from '../../components/task-message-modal';
import TaskSubmitButton from '../../components/task-submit-button';
import { Col, Row, Space } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { linkTo } from 'src/util/link';
import { isExercise } from '../../components/util';
import { useChatRecord } from '../../components/record/voice/hook';
import { getDateTimeString } from 'src/util/moment';
import { useAiGen } from 'src/pages/coach/learning-center/node-config-test/components/hooks/useAiGen';
import { useDocumentVisibility } from 'ahooks';
import { PageControlContext } from 'src/pages/home';
import ResultModal, { ResultModalRefType } from '../../record/detail/components/ResultModal';
import { IntelligentPartnerPageDetailFoundResponse } from 'src/api/types';
import './index.less';

const Template = React.memo((props: any) => {
    const { history, match } = props;
    const locales = Locales['current'];
    const taskId = match.params.id;
    const [taskDetail, setTaskDetail] = useState<IntelligentPartnerPageDetailFoundResponse>();
    const queryParams = new URLSearchParams(props.location.search);
    const lessonId = Number(queryParams.get('lessonId'));
    const { courseType, isAiGenCourse, aiImage, aiGenSceneNodeId } = useAiGen({
        courseId: lessonId,
    });

    const request = useRequest();
    const [record, setRecord] = useState<any>(null); // 记录
    const {
        nextMessage,
        flowIndex,
        flowData,
        globalConfig,
        referenceWords,
        onGenRefer,
        onGenReferLoading,
        loadingForSendMessage, // 发送消息接口的loading
        loadingForTaskStart, // 任务开始接口的loading
        passThroughParams, // 消息透传参数
        isStartTask, // 是否开始任务
        setIsStartTask,
        isLastNode, // 任务是否是最后一个节点
        isOver, // 录音是否结束
        pause, // 录音暂停
        list, // 消息列表
        audioRef, // 播放器
        startTaskHandle, // 开始任务
        startAudioRecord, // 开始录音

        suspendDetail,
        isSuspend,
        isSuspending,
        isLoadSuspendInfo,
        loadingForSuspend,
        pauseTask,
        continueTask,
        restartTask,

        pointOfFirstEntry,
    } = useChatRecord({
        request,
        task: taskDetail,
        record, // 记录
        setRecord,
        aiGenSceneNodeId,
    });

    const taskMessageModalRef = useRef<RefType>(null);

    // 获取任务详情信息
    useLiveEffect(
        (success) => {
            request(`/intelligentPartner/${taskId}/pageDetail`).then((resp) => {
                success(() => {
                    const data = resp.data;
                    if (data) {
                        data.id = parseInt(taskId);
                        setTaskDetail(data);
                    }
                });
            });
        },
        [request, taskId]
    );

    useEffect(() => {
        isLastNode && taskMessageModalRef.current?.open(4);
    }, [isLastNode]);

    const goBack = useCallback(() => {
        isExercise(
            taskDetail?.taskType,
            () => linkTo(history, 'coachLearningCenterExerciseTask'),
            () => linkTo(history, 'coachLearningCenterTestTask')
        );
    }, [history, taskDetail?.taskType]);

    const goNext = useCallback(() => {
        const isOpen =
            taskDetail?.enableManualReview === Udesk.enums.enableManualReviewType.open.id;
        linkTo(
            history,
            isOpen ? 'coachLearningCenterRecord' : 'coachLearningCenterRecordDetail',
            { id: record?.id },
            {
                backRouteParams: [
                    isExercise(
                        taskDetail?.taskType,
                        'coachLearningCenterExerciseTask',
                        'coachLearningCenterTestTask'
                    ),
                ],
            },
            isOpen ? { taskId } : {}
        );
    }, [history, record?.id, taskDetail?.enableManualReview, taskDetail?.taskType, taskId]);

    const [submitLoading, setSubmitLoading] = useState(false);
    const resultModalRef = useRef<ResultModalRefType>();

    const submitTaskHandle = useCallback(() => {
        setSubmitLoading(true);
        request(
            `/intelligentPartner/${record.id}/submit`,
            {
                flowId: passThroughParams?.flowId,
                video: '',
                submitTime: getDateTimeString(),
                isReviewed: taskDetail?.enableManualReview,
            },
            'put'
        )
            .then(
                (resp) => {
                    Udesk.ui.notify.success(locales.business.info.saveSuccess);
                    setIsStartTask(false);
                    taskMessageModalRef.current?.close?.();

                    if (isExercise(taskDetail?.taskType)) {
                        resultModalRef.current?.onOpen?.();
                    } else {
                        if (taskDetail?.resultDisplayConfigObj?.examResultDisplayMethod === 1) {
                            resultModalRef.current?.onOpen?.();
                        } else if (
                            taskDetail?.resultDisplayConfigObj?.examResultDisplayMethod === 2
                        ) {
                            if ((taskDetail?.remainingTimes || 0) === 1) {
                                resultModalRef.current?.onOpen?.();
                            } else {
                                goBack();
                            }
                        } else {
                            goBack();
                        }
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            )
            .finally(() => {
                setSubmitLoading(false);
            });
    }, [
        request,
        record?.id,
        passThroughParams?.flowId,
        taskDetail?.enableManualReview,
        taskDetail?.taskType,
        taskDetail?.resultDisplayConfigObj?.examResultDisplayMethod,
        taskDetail?.remainingTimes,
        locales.business.info.saveSuccess,
        setIsStartTask,
        goBack,
    ]);

    const cancelTaskAjax = useCallback(() => {
        // 取消时，练习时模式调用 cancel，考试时调用提交
        isExercise(
            taskDetail?.taskType,
            () => {
                request(`/intelligentPartner/${record.id}/cancel`).then(
                    (resp) => {
                        goBack();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            },
            submitTaskHandle
        );
    }, [goBack, record?.id, request, submitTaskHandle, taskDetail?.taskType]);

    const cancelTaskHandle = useCallback(() => {
        if (isStartTask) {
            taskMessageModalRef.current?.open(taskDetail?.taskType as any);
        } else {
            goBack();
        }
    }, [goBack, isStartTask, taskDetail?.taskType]);

    const pauseTaskHandle = useCallback(
        (callback) => {
            pauseTask(
                () => {
                    taskMessageModalRef.current?.open(5);
                },
                {
                    flowId: passThroughParams.flowId,
                    suspendTime: getDateTimeString(),
                    video: '',
                }
            );
        },
        [passThroughParams.flowId, pauseTask]
    );

    const documentVisibility = useDocumentVisibility();
    // 切换tab/最小化时触发
    useEffect(() => {
        if (isStartTask && documentVisibility === 'hidden') {
            taskMessageModalRef.current?.open(taskDetail?.taskType as any);
        }
    }, [documentVisibility, isStartTask, taskDetail?.taskType]);

    const handleTaskAbnormal = useCallback(() => {
        if (isStartTask) {
            // 取消时，练习时模式调用 cancel，考试时调用提交
            isExercise(
                taskDetail?.taskType,
                () => {
                    record?.id &&
                        // fetch(`/backend/intelligentPartner/${record.id}/cancel`, {
                        //     method: 'GET',
                        //     keepalive: true,
                        // });
                        fetch(`/backend/intelligentPartner/${record.id}/suspend`, {
                            method: 'put',
                            body: JSON.stringify({
                                flowId: passThroughParams.flowId,
                                suspendTime: getDateTimeString(),
                                video: '',
                            }),
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            keepalive: true,
                        });
                },
                () => {
                    record?.id &&
                        fetch(`/backend/intelligentPartner/${record.id}/submit`, {
                            method: 'put',
                            body: JSON.stringify({
                                flowId: passThroughParams?.flowId,
                                video: '',
                                submitTime: getDateTimeString(),
                                isReviewed: taskDetail?.enableManualReview,
                            }),
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            keepalive: true,
                        });
                }
            );
        }
    }, [
        isStartTask,
        passThroughParams?.flowId,
        record?.id,
        taskDetail?.enableManualReview,
        taskDetail?.taskType,
    ]);

    useEffect(() => {
        // 页面关闭和刷新时触发
        window.addEventListener('beforeunload', handleTaskAbnormal);
        // 前进后退时
        window.addEventListener('popstate', handleTaskAbnormal);

        return () => {
            setTimeout(() => {
                window.removeEventListener('beforeunload', handleTaskAbnormal);
                window.removeEventListener('popstate', handleTaskAbnormal);
            });
        };
    }, [handleTaskAbnormal]);

    const { toggleShowSider, toggleShowHeader } = useContext(PageControlContext);

    useEffect(() => {
        if (isStartTask) {
            toggleShowSider(false);
            toggleShowHeader(false);
        }

        return () => {
            toggleShowSider(true);
            toggleShowHeader(true);
        };
    }, [isStartTask, toggleShowHeader, toggleShowSider]);

    return (
        <Page
            pageClassName="learning-center-exercise"
            pageBodyClassName="learning-center-exercise-body call"
            backGroundGhost={true}
            onBack={cancelTaskHandle}
            title={taskDetail?.taskName}
            extra={
                <TaskSubmitButton
                    {...{
                        nextMessage,
                        submitMessage: startAudioRecord,
                        pauseTask: pauseTaskHandle,
                        isSuspending,
                        loadingForSuspend,
                        taskMessageModalRef,
                        record,
                        taskDetail,
                        isLastNode,
                        isStartTask,
                        submitTaskHandle,
                    }}
                />
            }
        >
            <Row gutter={16}>
                {isAiGenCourse ? (
                    <Col span={5}>
                        <CustomerInfo data={aiImage} />
                    </Col>
                ) : taskDetail?.customerPersona ? (
                    <Col span={5}>
                        <CustomerInfo data={taskDetail?.customerPersona} />
                    </Col>
                ) : null}
                <Col span={taskDetail?.customerPersona || isAiGenCourse ? 13 : 18}>
                    <ChatRecordPanel
                        {...{
                            task: taskDetail,
                            globalConfig,
                            isStartTask,
                            isLastNode,
                            isOver,
                            pause,
                            list,
                            audioRef,
                            startTaskHandle,
                            startAudioRecord,
                            loadingForTaskStart,
                            loadingForSendMessage,
                            pointOfFirstEntry,
                        }}
                    />
                </Col>
                <Col span={6}>
                    <Space direction={'vertical'} className={'other-panel'}>
                        <VideoRecord />
                        {courseType !== Udesk.enums.lessonType.aiGen.id
                            ? isExercise(taskDetail?.taskType, () => (
                                  <FlowPanel
                                      index={flowIndex}
                                      list={flowData}
                                      isLastNode={isLastNode}
                                  />
                              ))
                            : null}
                        <TaskCue
                            referenceWords={referenceWords}
                            taskDetail={taskDetail}
                            isAiGenCourse={isAiGenCourse}
                            onGenRefer={onGenRefer}
                            onGenReferLoading={onGenReferLoading}
                            isStartTask={isStartTask}
                        />
                    </Space>
                </Col>
                <Col flex="0" style={{ padding: 0 }}>
                    <TaskMessageModal
                        {...{
                            ref: taskMessageModalRef,
                            goBack,
                            cancelTaskAjax,
                            submitTaskAjax: submitTaskHandle,
                            taskDetail,
                            suspendDetail,
                            isSuspend,
                            isLoadSuspendInfo,
                            loadingForSuspend,
                            continueTask,
                            restartTask,
                            loading: submitLoading,
                        }}
                    />
                </Col>

                <Col flex="0" style={{ padding: 0 }}>
                    <ResultModal
                        templateRef={resultModalRef}
                        recordId={record?.id}
                        history={history}
                        onBack={goBack}
                        onDetail={goNext}
                        config={taskDetail?.resultDisplayConfigObj?.resultDisplayContents}
                    />
                </Col>
            </Row>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
