import React from 'react';
import CustomerSericeInforomationRoute from './route';
import Udesk from 'Udesk';
import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import { Input, Modal, Select, TreeSelect, Popconfirm, PageHeader, Button, Space } from 'udesk-ui';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import CustomerInformationUpload from '../customer-information-upload-modal';
import CustomerServiceGrounp from '../../components/customer-service-grounp';
import _concat from 'lodash-es/concat';
import './style.scss';
import _size from 'lodash-es/size';
import ExportButton from 'Component/common/export-button';

export default class CoustomerServiceInformationTemplate extends CustomerSericeInforomationRoute {
    render() {
        let { /* sdkOptions, */ form } = this.props;
        let { visible, modalData, visibleStatus = false } = this.state;
        let { getFieldDecorator } = form;
        let { columns, data, loading, confirmLoading, paging, isShowUploadModal, grounpFields, grounpSelect, selectedItems, grounpDataList, grounpVisible } = this.privates;
        let { pageNum, pageSize, total } = paging;
        let { actions, privates, locales } = this;
        let { Option } = Select;
        // let { TreeNode } = TreeSelect;
        return (
            // <div className='customer-information-tags-index-page'>
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.fields.customerInformation.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root customer-information-tags-index-page'>
                        <div className='customer-information-tags-index-page-body qa-react-page-body'>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("agent:info:view")}>
                                <div className='customer-information-tags-index-page-body-right' style={{overflowX: 'hidden', position: 'relative'}}>
                                    <div className='customer-information-tags-index-page-body-search-container qa-react-page-body-search-container'>
                                        <div className='qa-react-page-body-search-container-left-part pull-left'>
                                            <input
                                                type='text'
                                                className='customer-information-tags-index-page-body-search-input form-control'
                                                defaultValue={privates.keywords}
                                                placeholder={locales.labels.searchPlaceHolder}
                                                onKeyUp={actions.searchItems}
                                                onChange={actions.onchangeItems}
                                            />
                                            <input
                                                type='checkbox'
                                                onChange={actions.listStatus}
                                                className='customer-information-chekcbox'
                                            />
                                            <span>{locales.fields.customerInformation.data}</span>
                                        </div>
                                        <div className='qa-react-page-body-search-container-right-part pull-right'>
                                            <Space>
                                                <Popconfirm placement="bottom" visible={this.state.deletePopVisible} title={Udesk.utils.string.format(locales.labels.deleteRecordInfo, _size(selectedItems))} okText={locales.labels.confirm} cancelText={locales.labels.cancel} onCancel={actions.deleteRecordCancel} onConfirm={actions.deleteRecordOk}>
                                                    <Select value={grounpSelect} style={{ width: '120px', marginRight: '5px', verticalAlign: 'middle' }} onChange={actions.grounpChange}>
                                                        <If
                                                            condition={
                                                                Udesk.data.init.user && Udesk.data.init.user.hasFeature("agent:info:edit")
                                                            }
                                                        >
                                                            <Option value='add'>{locales.fix.joinGroup}</Option>
                                                            <Option value='emd'>{locales.fix.removeGroup}</Option>
                                                        </If>
                                                        <If
                                                            condition={
                                                                Udesk.data.init.user &&
                                                                Udesk.data.init.user.hasFeature("agent:info:delete")
                                                            }
                                                        >
                                                            <Option value='del'>
                                                                <a href="" onClick={actions.deletReordClick} style={{ display: "inline-block", width: "100%" }}>{locales.labels.deleteRecord}</a>
                                                            </Option>
                                                        </If>
                                                    </Select>
                                                </Popconfirm>
                                                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("agent:info:add")}>
                                                    <Button onClick={actions.createCallList}>
                                                        {locales.labels.create}
                                                    </Button>
                                                </If>
                                                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("agent:info:import")}>
                                                    <Button onClick={actions.showUploadModal}>
                                                        {locales.labels.import}
                                                    </Button>
                                                </If>
                                                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("agent:info:export")}>
                                                    <ExportButton disabled={loading} exportDataCount={total} hasFilterCondition={true} onClick={actions.exportCallList} />
                                                </If>
                                            </Space>
                                        </div>
                                    </div>
                                    <div className='customer-information-index-page-body-content-container qa-react-page-body-content-container'>
                                        <div className='customer-information-index-page-body-content-container-left'>
                                            {
                                                privates.grounpDataList && <CustomerServiceGrounp data={grounpDataList} grounpSaveChange={actions.grounpSaveChange} groupNodeDelChange={actions.groupNodeDelChange} onSelectSearch={actions.onSelectSearch} jurisdiction={true} />
                                            }
                                        </div>
                                        <div className='customer-information-index-page-body-content-container-right'>
                                            <ReactTable columns={columns} items={data} sortable={true} onSorted={actions.sort} checkable={true} onSelectionChanged={actions.onCheckChange} selectedItems={_concat([], selectedItems)} />
                                            {/* <ReactPager
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                                languageCode={sdkOptions.props.language}
                                                pageChanged={actions.pageChanged}
                                            /> */}
                                            <UdeskPagination
                                                current={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                                onChange={actions.pageChanged}
                                            />
                                        </div>

                                    </div>
                                    <div>
                                        <Modal
                                            className='customer-information-modal'
                                            title={visibleStatus ? locales.labels.create : locales.labels.edit}
                                            visible={visible}
                                            width={600}
                                            destroyOnClose='true'
                                            okText={locales.labels.save}
                                            cancelText={locales.labels.cancel}
                                            onOk={this.actions.handleSubmit}
                                            confirmLoading={confirmLoading}
                                            onCancel={this.actions.close}>
                                            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
                                                <Form.Item label={locales.fields.customerInformation.list.agentNo}>
                                                    {getFieldDecorator('agentNo', {
                                                        initialValue: modalData.agentNo,
                                                        rules: [{ required: true, message: '' }]
                                                    })(<Input disabled={visibleStatus ? false : visible} />)}
                                                </Form.Item>
                                                <Form.Item label={locales.fields.customerInformation.list.agentName}>
                                                    {getFieldDecorator('agentName', {
                                                        initialValue: modalData.agentName ? modalData.agentName : '',
                                                        rules: []
                                                    })(<Input />)}
                                                </Form.Item>
                                                <Form.Item label={locales.fields.customerInformation.list.agentTel}>
                                                    {getFieldDecorator('agentTel', {
                                                        initialValue: modalData.agentTel ? modalData.agentTel : '',
                                                        rules: []
                                                    })(<Input />)}
                                                </Form.Item>
                                                <Form.Item label={locales.fields.customerInformation.list.agentEmail}>
                                                    {getFieldDecorator('agentEmail', {
                                                        initialValue: modalData.agentEmail ? modalData.agentEmail : '',
                                                        rules: []
                                                    })(<Input />)}
                                                </Form.Item>
                                                <Form.Item label={locales.fix.customerServicesGroup}>
                                                    {getFieldDecorator('agentGroups', {
                                                        initialValue: modalData.agentGroups ? modalData.agentGroups.map(item => item.id) : undefined,
                                                    })(
                                                        <TreeSelect
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder={locales.labels.pleaseChoice}
                                                            treeData={grounpDataList}
                                                            allowClear
                                                            multiple
                                                            treeDefaultExpandAll
                                                            treeNodeFilterProp="title"
                                                        >
                                                        </TreeSelect>
                                                    )}

                                                </Form.Item>
                                            </Form>
                                        </Modal>
                                        <Modal
                                            className='customer-information-modal'
                                            title={locales.fix.operationGroup}
                                            visible={grounpVisible}
                                            width={600}
                                            destroyOnClose='true'
                                            okText={locales.labels.save}
                                            cancelText={locales.labels.cancel}
                                            onOk={actions.grounpSave}
                                            confirmLoading={confirmLoading}
                                            onCancel={actions.grounpCancel}>
                                            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
                                                <Form.Item label={locales.fix.customerServiceSelected}>
                                                    <div className='customer-information-modal-grounp-field'>
                                                        {
                                                            selectedItems && selectedItems.length > 0 && selectedItems.map(item => {
                                                                return (<div style={{ width: '50%' }}>{item.agentName}<span onClick={actions.grounpFieldDel.params(item)}><i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i></span></div>);
                                                            })
                                                        }
                                                    </div>
                                                </Form.Item>
                                                <Form.Item label={locales.fix.customerServicesGroup}>
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        value={grounpFields}
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={locales.labels.pleaseChoice}
                                                        treeData={grounpDataList}
                                                        allowClear
                                                        multiple
                                                        treeDefaultExpandAll
                                                        onChange={actions.onChangeGrounp}
                                                        treeNodeFilterProp="title"
                                                    >
                                                    </TreeSelect>
                                                </Form.Item>
                                            </Form>
                                        </Modal>
                                    </div>
                                    <CustomerInformationUpload
                                        title={locales.labels.import}
                                        visible={isShowUploadModal}
                                        headerClassName='udesk-qa-customer-information-modal-header'
                                        onCancel={actions.closeUploadModal}
                                        makeListRefresh={actions.refresh}
                                    />
                                </div>

                            </If>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
