// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostReviewIntelligentPartnerDialogFlowImportByFlowIdParam,
    PostReviewIntelligentPartnerDialogFlowImportByFlowIdBody,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 导入文件生成对话流程
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/dialogFlow/import/{flowId}
 * @param flowId
 */
export function postReviewIntelligentPartnerDialogFlowImportByFlowId(
    data: PostReviewIntelligentPartnerDialogFlowImportByFlowIdBody,
    options: ApiOptions<PostReviewIntelligentPartnerDialogFlowImportByFlowIdParam, never> & {
        segments: PostReviewIntelligentPartnerDialogFlowImportByFlowIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PostReviewIntelligentPartnerDialogFlowImportByFlowIdBody,
        PostReviewIntelligentPartnerDialogFlowImportByFlowIdParam,
        never
    >('/review/intelligentPartner/dialogFlow/import/{flowId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewIntelligentPartnerDialogFlowImportByFlowId',
    });
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/dialogFlow/import/{flowId}',
        method: 'post',
    },
];
