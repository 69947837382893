import React, { useCallback, useMemo } from 'react';
import Udesk from 'Udesk';
import { fire, isArray } from 'src/util/core';
import { Radio, Select, Checkbox } from 'udesk-ui';

const ALL_DEFAULT_VALUE = -999;

const buildEnumOptions = (enumKey, render, filter) => {
    let list = (isArray(enumKey) ? enumKey : Udesk.enums[enumKey]) ?? [];
    if (typeof filter === 'function') {
        list = list.filter(filter);
    }
    return list.map((item) => {
        return {
            label: fire(render, item) || item.name,
            value: item.id,
        };
    });
};

export const EnumSelect = (props) => {
    const { value, onChange, onChangeAfter, enumKey, width, empty, render, filter, ...elProps } =
        props;

    const options = useMemo(() => {
        const options = buildEnumOptions(enumKey, render, filter);
        if (empty) {
            options.unshift({
                label: empty.label,
                value: ALL_DEFAULT_VALUE,
            });
        }
        return options;
    }, [empty, enumKey, render, filter]);

    const selectValue = useMemo(() => {
        if (empty) {
            return value === empty.value ? ALL_DEFAULT_VALUE : value;
        }
        return value;
    }, [empty, value]);

    const changeHandle = useCallback(
        (value) => {
            if (value === ALL_DEFAULT_VALUE) {
                value = empty.value;
            }
            fire(onChange, value);
            fire(onChangeAfter, value);
        },
        [empty?.value, onChange, onChangeAfter]
    );

    return (
        <Select
            value={selectValue}
            onChange={changeHandle}
            style={{ width }}
            options={options}
            {...elProps}
        />
    );
};

export const EnumCheckbox = (props) => {
    const { enumKey, ...elProps } = props;
    return <Checkbox.Group options={buildEnumOptions(enumKey)} {...elProps} />;
};

export const EnumRadio = (props) => {
    const { enumKey, render, filter, onChange, onChangeAfter, empty, ...elProps } = props;

    const changeHandle = useCallback(
        (value) => {
            if (value === ALL_DEFAULT_VALUE) {
                value = empty.value;
            }
            fire(onChange, value);
            fire(onChangeAfter, value);
        },
        [empty?.value, onChange, onChangeAfter]
    );

    return (
        <Radio.Group
            onChange={changeHandle}
            options={buildEnumOptions(enumKey, render, filter)}
            {...elProps}
        />
    );
};
