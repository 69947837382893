import React from 'react';
import styled from 'styled-components';
import { Button, Form, Icon, Input, InputNumber, Select, Space, Switch } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const Wrap = styled.div`
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.03);
    margin-bottom: 8px;
    position: relative;
    border-radius: 3px;

    > .udesk-qa-ui-btn {
        position: absolute;
        top: 12px;
        right: 0;
        display: flex;
        align-items: center;
    }
    .udesk-qa-ui-space-item {
        &:first-child {
            flex: 1;
        }
    }
`;
const Fields = styled.div`
    > .udesk-qa-ui-space {
        width: 100%;
        .udesk-qa-ui-space-item {
            &:first-child {
                flex: 1;
            }
        }
    }
`;

export const DetailedInquiryList = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { questionCloselyMode, scoreRuleList } = props;
    return (
        <Form.List name="traineeConfigAskedList">
            {(fields, { remove, add, move }) => {
                return (
                    <Fields>
                        {fields.map((field, index) => (
                            <Wrap>
                                {questionCloselyMode === 1 && (
                                    <Space>
                                        {
                                            /* 当未命中规则 */ UdeskLocales['current'].components
                                                .coach.detailedInquiryConfig.index.whenMissRule
                                        }
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'notHitRule']}
                                            fieldKey={[field.fieldKey, 'notHitRule']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                    /* 规则不能为空 */ UdeskLocales['current']
                                                        .components.coach.detailedInquiryConfig
                                                        .index.ruleCannotBeEmpty,
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 150 }}
                                                options={scoreRuleList}
                                            />
                                        </Form.Item>
                                        {
                                            /* 时，进行追问 */ UdeskLocales['current'].components
                                                .coach.detailedInquiryConfig.index
                                                .whenConductingQuestioning
                                        }
                                    </Space>
                                )}
                                {questionCloselyMode === 2 && (
                                    <Space>
                                        {
                                            /* 当话术得分≤ */ UdeskLocales['current'].components
                                                .coach.detailedInquiryConfig.index
                                                .currentScriptScore
                                        }
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'score']}
                                            fieldKey={[field.fieldKey, 'score']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                    /* 得分不能为空 */ UdeskLocales['current']
                                                        .components.coach.detailedInquiryConfig
                                                        .index.scoreCannotBeEmpty,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder={
                                                    /* 请输入得分 */ UdeskLocales['current']
                                                        .components.coach.detailedInquiryConfig
                                                        .index.pleaseEnterTheScore
                                                }
                                            />
                                        </Form.Item>
                                        {
                                            /* 时，进行追问 */ UdeskLocales['current'].components
                                                .coach.detailedInquiryConfig.index
                                                .whenConductingQuestioning
                                        }
                                    </Space>
                                )}
                                <Form.List name={[field.name, 'questionCloselyContent']}>
                                    {(fields, { remove, add, move }) => {
                                        return (
                                            <>
                                                {fields.map((wordsField, index) => {

                                                    return (
                                                        <Space
                                                            key={index}
                                                            style={{
                                                                marginBottom: 8,
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <div style={{ width: '100%' }}>
                                                                <Title>
                                                                    <div>{`${
                                                                        /* 第 */ UdeskLocales[
                                                                            'current'
                                                                        ].components.coach
                                                                            .detailedInquiryConfig
                                                                            .components
                                                                            .detailedInquiryList
                                                                            .index.section
                                                                    }${index + 1}${
                                                                        /* 次追问 */ UdeskLocales[
                                                                            'current'
                                                                        ].components.coach
                                                                            .detailedInquiryConfig
                                                                            .components
                                                                            .detailedInquiryList
                                                                            .index.secondInquiry
                                                                    }`}</div>
                                                                    <Space>
                                                                        <div>
                                                                            {/* 追问得分 */}
                                                                            {
                                                                                UdeskLocales[
                                                                                    'current'
                                                                                ].components.coach
                                                                                    .detailedInquiryConfig
                                                                                    .components
                                                                                    .detailedInquiryList
                                                                                    .index
                                                                                    .questioningScore
                                                                            }
                                                                        </div>
                                                                        <Form.Item
                                                                            {...wordsField}
                                                                            name={[
                                                                                // field.name,
                                                                                wordsField.name,
                                                                                'enableScore',
                                                                            ]}
                                                                            fieldKey={
                                                                                wordsField.key
                                                                            }
                                                                            valuePropName="checked"
                                                                        >
                                                                            <Switch size="small" />
                                                                        </Form.Item>
                                                                    </Space>
                                                                </Title>
                                                                <Form.Item
                                                                    {...wordsField}
                                                                    // name={[field.name, wordsField.name, 'words']}
                                                                    name={[
                                                                        wordsField.name,
                                                                        'words',
                                                                    ]}
                                                                    fieldKey={wordsField.key}
                                                                >
                                                                    <Input.TextArea
                                                                        maxCount={100}
                                                                        showCount={true}
                                                                        maxLength={100}
                                                                    />
                                                                </Form.Item>
                                                            </div>

                                                            <Button
                                                                type="text"
                                                                size="small"
                                                                danger
                                                                onClick={() => remove(index)}
                                                            >
                                                                <Icon
                                                                    type="DeleteOutlined"
                                                                    antdIcon={true}
                                                                />
                                                            </Button>
                                                        </Space>
                                                    );
                                                })}
                                                <div>
                                                    <Button
                                                        onClick={() =>
                                                            add({ enableScore: 0, words: '' })
                                                        }
                                                        type="link"
                                                        size="small"
                                                    >{/* 增加追问 */}{UdeskLocales['current'].components.coach.detailedInquiryConfig.components.detailedInquiryList.index.increaseQuestioning}</Button>
                                                </div>
                                            </>
                                        );
                                    }}
                                </Form.List>

                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => remove(index)}
                                    icon={<Icon type="delete_minus" />}
                                >{/* 移除 */}{UdeskLocales['current'].components.coach.detailedInquiryConfig.components.detailedInquiryList.index.remove}</Button>
                            </Wrap>
                        ))}
                        <div>
                            <Button onClick={() => add({})} type="link" size="small">
                                {/* 增加规则 */}
                                {
                                    UdeskLocales['current'].pages.coach.courseManagement.flow
                                        .components.sessionDetectionForm.addRules
                                }
                            </Button>
                        </div>
                    </Fields>
                );
            }}
        </Form.List>
    );
});

const Title = styled.div`
    display: flex;
    justify-content: space-between;
`;
