import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import config from '../../../../../common/config/index';
import { getSystemModule } from 'Udesk/system/subApp';

export default class TasksManualAddComponent extends React.Component {
    //#region state
    state = {
        conditionList: [],
        intelligentCondition: '',
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: Udesk.config.paging.defaultPageSize,
        total: 0,
        showModel: false,
    };
    privates = {
        editFilterVisible: false,
        newFilterVisible: false,
        customFilterList: [],
        customeFilterFieldKey: [],
        customeFilterField: null,
        customDataSourceList: [],
        displayConditionList: [],
        storages: {
            searchField: '',
            paging: {},
            selectedFilter: null,
            fieldList: [],
        },
        customJudgeLogic: '',
        conditionJson: [],
        conditionJsonInfo: [],
        isFilterVisible: false,
        judgeStrategy: 1,
        conditionList: [],
        previewData: {},
    };
    //#endregion

    static computes = {
        _fields: [
            'privates.model.manualCustomFields',
            function ({ props, privates, locales }) {
                if (privates.model && privates.model.manualCustomFields) {
                    return Udesk.business.fields.buildConditionFields({
                        fields: privates.model.manualCustomFields,
                    });
                } else {
                    return [];
                }
            },
        ],
        _fieldValueSettings: [
            'privates.model.manualCustomFields',
            function ({ props, privates, locales }) {
                return config.getFilterComponentSettings();
            },
        ],
        _isFilterVisible: [
            'privates.editFilterVisible',
            'privates.newFilterVisible',
            function ({ props, privates, locales }) {
                let {
                    // filter,
                    editFilterVisible,
                    newFilterVisible,
                } = privates;
                if (editFilterVisible || newFilterVisible) {
                    return true;
                }
                return false;
            },
        ],
    };

    //#region actions
    actions = {
        pageChanged(pageNum, pageSize) {
            this.setState({
                pageNum,
            });
            this.actions.reloadModel({ pageNum, pageSize });
        },
        showTotal(total, range) {
            let { locales } = this;
            return Udesk.utils.string.format(
                locales.components.pages.billingCenter.table.page,
                total,
                Math.ceil(total / this.state.pageSize)
            );
        },
        onShowSizeChange(current, size) {
            this.setState({
                pageSize: size,
                pageNum: 1,
            });
            this.actions.reloadModel({ pageNum: 1, pageSize: size });
        },
        // filterContidionChanged(value) {
        //     this.setState({
        //         conditionList: value
        //     });
        // },
        // 组织发请求用的筛选数据
        getQueryConditionList(conditionList) {
            let allFieldList = this.privates.model.manualCustomFields;
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.forEach((condition) => {
                        allFieldList.forEach((field) => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            }
                        });
                    });
                }
            }
            return queryConditionList;
        },
        importManualAddRecords() {
            if (!this.privates.model.processCreateStatus.status) {
                this.setState({
                    showModel: true,
                });
            } else {
                this.actions.queryImportAdd();
            }
        },
        queryImportAdd() {
            let taskId = this.privates.model.taskId;
            let intelligentCondition = this.state.intelligentCondition;
            let conditions = this.privates.conditionJsonInfo;
            let params = {
                taskId: taskId,
                intelligentCondition: intelligentCondition,
                baseFilter: {
                    conditionList: this.actions.getQueryConditionList(conditions),
                    customJudgeLogic: this.privates.customJudgeLogic,
                    judgeStrategy: this.privates.judgeStrategy,
                },
                processCount: this.privates.previewData.cnJoinTaskCount,
                systemModule: getSystemModule(),
            };
            let url = Udesk.business.apiPath.concatApiPath('/inspectionManualLoads', this.props.sdkOptions);
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        `${UdeskLocales.current.labels.import}${UdeskLocales.current.business.info.creatSuccess}`
                    );
                    this.privates.previewData = {};
                    this.privates.conditionJsonInfo = [];
                    this.privates.judgeStrategy = 1;
                    this.privates.customJudgeLogic = '';
                    this.privates.conditionJson = [];
                    this.actions.reloadModel();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            `${UdeskLocales.current.labels.import}${UdeskLocales.current.business.info.creatError}`
                    );
                }
            );
        },
        close() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'tasksIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sendAjaxToNext() {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, { processStatus: -1 }).then(
                (resp) => {
                    const routeOptions = {
                        history: this.props.history,
                        routeName: 'tasksManualAddIndex',
                        pathParams: { taskId: this.privates.model.taskId },
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        exitBoot() {
            this.actions.sendAjaxToNext();
        },
        handleOk() {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionTasks/' + this.privates.model.taskId + '/status/1',
                this.props.sdkOptions
            );
            Udesk.ajax.put(url).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                    this.actions.queryImportAdd();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
            this.setState({
                showModel: false,
            });
        },
        handleCancel() {
            this.setState({
                showModel: false,
            });
        },
        changeFilterVisible() {
            this.privates.isFilterVisible = true;
            this.actions.update();
        },
        handleConditionOtherSave(data, flagType) {
            let { sdkOptions } = this.props;
            if (flagType) {
                let url = Udesk.business.apiPath.concatApiPath(`search-template/update`, sdkOptions);
                new Promise((resolve, reject) => {
                    Udesk.ajax.put(url, data).then(
                        (resp) => {
                            Udesk.ui.notify.success(this.locales.components.common.customFilter.updateSuccess);
                            // this.actions.getCustomFilterList();
                            this.privates.isFilterVisible = false;
                            resolve(resp);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg || this.locales.components.pages.components.listManage.getRecordsFailed
                            );
                            reject(reason);
                        }
                    );
                });
            } else {
                let url = Udesk.business.apiPath.concatApiPath(`search-template/save`, sdkOptions);
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(url, data).then(
                        (resp) => {
                            Udesk.ui.notify.success(this.locales.components.common.customFilter.saveSuccess);
                            // this.actions.getCustomFilterList();
                            this.privates.isFilterVisible = false;
                            resolve(resp);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg || this.locales.components.pages.components.listManage.getRecordsFailed
                            );
                            reject(reason);
                        }
                    );
                });
            }
        },
        handleConditionDel(id) {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`search-template/${id}`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(this.locales.components.common.customFilter.delSuccess);
                        // this.actions.getCustomFilterList();
                        resolve(resp);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg || this.locales.components.pages.components.listManage.getRecordsFailed
                        );
                        reject(reason);
                    }
                );
            });
        },
        handleConditionCancel() {
            this.privates.isFilterVisible = false;
            this.actions.update();
        },
        handleConditionCancelWithoutClearData() {
            this.privates.editFilterVisible = false;
            this.privates.newFilterVisible = false;
            this.actions.update();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy, id, name) {
            this.privates.conditionJsonInfo =
                conditionList.map((item) => {
                    if (item.field.statusKey) {
                        return {
                            ...item,
                            field: {
                                ...item.field,
                                id: `${item.field.id}-${item.field.inspectionTaskId}`,
                            },
                        };
                    } else {
                        return item;
                    }
                }) || [];
            this.privates.conditionJson =
                conditionList.map((item) => {
                    if (item.field.statusKey) {
                        return {
                            field: `${item.field.id}-${item.field.inspectionTaskId}`,
                            operator: item.operator,
                            value: item.value,
                            statusKey: `${item.field.id}-${item.field.inspectionTaskId}`,
                        };
                    } else {
                        return {
                            field: item.field.id,
                            operator: item.operator,
                            value: item.value,
                        };
                    }
                }) || [];
            this.privates.judgeStrategy = judgeStrategy;
            if (judgeStrategy !== 3) {
                this.privates.customJudgeLogic = '';
            } else {
                this.privates.customJudgeLogic = customJudgeLogic;
            }
            this.privates.isFilterVisible = false;
            this.privates.customName = null;
            this.privates.customId = null;
            if (id) {
                this.privates.customName = name;
                this.privates.customId = id;
            }
            this.actions.getPreviewData();
            this.actions.update();
        },
        onConditionFilterSaved(flag, data) {
            if (data) {
                this.privates.customId = data.id;
                this.privates.customName = data.name;
            }
        },
        filterContidionChanged(keyIds, value) {
            this.privates.conditionJson = value;
            this.actions.update();
        },
        // getQueryConditionList(conditionList, showFieldList) {
        //     let queryConditionList = [];
        //     if (
        //         conditionList &&
        //         conditionList instanceof Array &&
        //         conditionList.length > 0
        //     ) {
        //         queryConditionList = Udesk.utils.object.deepCopy(conditionList);
        //         if (queryConditionList && queryConditionList.length > 0) {
        //             queryConditionList.map(condition => {
        //                 showFieldList.forEach(field => {
        //                     if (condition.field === field.id) {
        //                         condition.field = field;
        //                     }
        //                 });
        //                 return condition;
        //             });
        //         }
        //     }
        //     return queryConditionList;
        // },
        editFilterOhter(list, radioValue, customInfo, condition) {
            this.privates.isFilterVisible = true;
            this.privates.displayConditionList = list || [];
            this.privates.judgeStrategyList = radioValue || 1;
            this.privates.customJudgeLogicList = customInfo || '';
            this.privates.conditionList = condition || [];
            this.actions.update();
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.forEach((item) => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach((items) => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label + ' ' + obj[item.operator] + ' ' + str.substring(0, str.length - 1),
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <div
                        style={{
                            color: 'rgba(0, 0, 0, 0.65)',
                            marginBottom: index + 1 === list.length ? '0px' : '8px',
                        }}
                    >
                        {index + 1}. {item}
                    </div>
                );
            });
        },
        getPreviewData() {
            let { sdkOptions } = this.props;

            let taskId = this.privates.model.taskId;
            let intelligentCondition = this.state.intelligentCondition;
            let conditions = this.privates.conditionJsonInfo;
            let params = {
                taskId: taskId,
                intelligentCondition: intelligentCondition,
                baseFilter: {
                    conditionList: this.actions.getQueryConditionList(conditions),
                    customJudgeLogic: this.privates.customJudgeLogic,
                    judgeStrategy: this.privates.judgeStrategy,
                },
            };
            let url = Udesk.business.apiPath.concatApiPath(`inspectionManualLoads/preview`, sdkOptions);
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    this.privates.previewData = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.components.pages.components.listManage.getRecordsFailed
                    );
                }
            );
        },
        transToManage() {
            Udesk.business.goToListManager.trans(
                {
                    conditionList: this.privates.conditionJsonInfo,
                    judgeStrategy: this.privates.judgeStrategy,
                    customJudgeLogic: this.privates.customJudgeLogic,
                },
                this.privates.type
            );
        },
    };
    //#endregion
}
function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter((item) => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map((item) => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}
