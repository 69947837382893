import React from 'react';
import PropTypes from 'prop-types';
import Udesk from 'Udesk';
import importSuccessNotification from 'Component/common/import-notification';
import { getSystemModule } from 'Udesk/system/subApp';

// const FILE_TYPES = [
//     'application/json',
//     'application/wps-office.xlsx',
//     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//     'text/plain'
// ];
// const FILE_TYPES_TXT_INDEX = 3;
export default class CustomerInformationUploadModalComponent extends React.Component {
    static defaultProps = {
        visible: false, //是否显示对话框
        title: '', //标题
        onCancel: null, //关闭modal回调
        headerClassName: ''
        // boardName: '',//上传时,所需下载模板的名字,
        // navItemType: null
    };
    static propTypes = {
        visible: PropTypes.bool,
        title: PropTypes.string,
        onCancel: PropTypes.func,
        headerClassName: PropTypes.string
        // boardName: PropTypes.string,
        // navItemType: PropTypes.number
    };
    privates = {};
    actions = {
        downloadTemplate(fileType) {
            let { sdkOptions } = this.props;

            let templateLocalUrl = `/import/customer-service/customer-information.${fileType}`;
            if (fileType === 'txt' || fileType === 'json') {
                templateLocalUrl = `/import/customer-service/customer-information.${fileType}.zip`;
            }

            let templateUrl =
                Udesk.business.apiPath.getStaticBasePath(sdkOptions.props.domain, sdkOptions.props.pathPrefix) +
                templateLocalUrl;
            window.location = templateUrl;
        },
        upload(e) {
            let { locales } = this;
            if (e.target.files && e.target.files.length === 0) {
                return;
            }
            // let isUploadCorpus =
            //     this.props.title === locales.components.pages.smartWordsLibrary.upload.uploadCorpusData;

            // let isNoSuitableFileType = FILE_TYPES.indexOf(e.target.files[0].type) === -1;

            // if (isUploadCorpus) {
            //     if (isNoSuitableFileType) {
            //         Udesk.ui.notify.error(locales.components.pages.smartWordsLibrary.upload.pleaseUploadTemplate);
            //         return;
            //     }
            //     if (!isNoSuitableFileType && e.target.files[0].type !== FILE_TYPES[FILE_TYPES_TXT_INDEX]) {
            //         Udesk.ui.notify.error(locales.components.pages.smartWordsLibrary.upload.templateNeedTxt);
            //         return;
            //     }
            // } else {
            //     if (isNoSuitableFileType) {
            //         Udesk.ui.notify.error(locales.components.pages.smartWordsLibrary.upload.pleaseUploadTemplate);
            //         return;
            //     }
            //     if (!isNoSuitableFileType && e.target.files[0].type === FILE_TYPES[FILE_TYPES_TXT_INDEX]) {
            //         Udesk.ui.notify.error(locales.components.pages.smartWordsLibrary.upload.templateNeedJSONOrXLSX);
            //         return;
            //     }
            // }
            // 不需要使用this.privates.uploadingFile
            let targetFile = e.target.files[0];
            let fileName = fileType(targetFile.name);
            if (!fileName) {
                return;
            } else if (fileName !== 'xlsx') {
                return Udesk.ui.notify.error(Udesk.utils.string.format(locales.labels.fileTypeHolder, 'xlsx'));
            }
            let id = new Date().getTime();

            // 获取远程服务器权限
            Udesk.app.fileStorage.upload(targetFile, {
                token: new Promise((resolve, reject) => {
                    Udesk.business
                        .getServerAuthorization({
                            type: 4
                        })
                        .then(
                            function (resp) {
                                resolve(resp);
                            },
                            function (reason) {
                                reject(reason);
                            }
                        );
                }),
                successCallback: this.actions.uploadFileSuccess.bind(this, id),
                errorCallback: this.actions.uploadFileError.bind(this, id)
            });
        },
        uploadFileSuccess(id, file, fileData, options) {
            let { sdkOptions } = this.props;

            let url = Udesk.business.apiPath.concatApiPath(`agent/import`, sdkOptions);

            let params = {
                keyName: fileData.key,
                originalFileName: file.name,
                fileSize: file.size,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(this.locales.components.pages.smartWordsLibrary.upload.uploadSuccess);
                    this.trigger('onCancel');
                    this.trigger('makeListRefresh');
                    importSuccessNotification();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.components.pages.smartWordsLibrary.upload.postUploadDataFail
                    );
                }
            );
        },
        uploadFileError(id, file, fileData, options) { }
    };
}
function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}