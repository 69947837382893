// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PutReviewIntelligentPartnerNodeByIdParam,
    PutReviewIntelligentPartnerNodeByIdBody,
    BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse,
    DeleteReviewIntelligentPartnerNodeByIdParam,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 修改对话流程节点
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [PUT] /review/intelligentPartner/node/{id}
 * @param id
 */
export function putReviewIntelligentPartnerNodeById(
    data: PutReviewIntelligentPartnerNodeByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerNodeByIdParam, never> & {
        segments: PutReviewIntelligentPartnerNodeByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse> {
    return request<
        PutReviewIntelligentPartnerNodeByIdBody,
        PutReviewIntelligentPartnerNodeByIdParam,
        never
    >('/review/intelligentPartner/node/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerNodeById',
    });
}

/**
 * 删除对话流程节点
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [DELETE] /review/intelligentPartner/node/{id}
 * @param id
 */
export function deleteReviewIntelligentPartnerNodeById(
    options: ApiOptions<DeleteReviewIntelligentPartnerNodeByIdParam, never> & {
        segments: DeleteReviewIntelligentPartnerNodeByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReviewIntelligentPartnerNodeByIdParam, never>(
        '/review/intelligentPartner/node/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerNodeById',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/{id}',
        method: 'put',
    },
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/{id}',
        method: 'delete',
    },
];
