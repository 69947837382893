// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PutReviewIntelligentPartnerDialogFlowConfigByIdParam,
    PutReviewIntelligentPartnerDialogFlowConfigByIdBody,
    BaseResponseIntelligentPartnerDialogFlowGlobalConfigFoundResponse,
} from '../../../../../types';

/**
 * 修改对话流程全局配置
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [PUT] /review/intelligentPartner/dialogFlow/config/{id}
 * @param id
 */
export function putReviewIntelligentPartnerDialogFlowConfigById(
    data: PutReviewIntelligentPartnerDialogFlowConfigByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerDialogFlowConfigByIdParam, never> & {
        segments: PutReviewIntelligentPartnerDialogFlowConfigByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerDialogFlowGlobalConfigFoundResponse> {
    return request<
        PutReviewIntelligentPartnerDialogFlowConfigByIdBody,
        PutReviewIntelligentPartnerDialogFlowConfigByIdParam,
        never
    >('/review/intelligentPartner/dialogFlow/config/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerDialogFlowConfigById',
    });
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/dialogFlow/config/{id}',
        method: 'put',
    },
];
