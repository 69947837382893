import React, { useCallback } from 'react';
import { Table } from 'udesk-ui';
import './index.scss';

type PropsType = {
    data: any[];
    setSelectedRowKey: Function;
    selectedRowKey: number;
}

export default React.memo((props: PropsType) => {
    const {
        data,
        selectedRowKey, 
        setSelectedRowKey,
    }= props;

    const renderCell = useCallback((text, record) => {
        return (
            <div className='table-row-item' onClick={() => setSelectedRowKey(record.key)}>
                <span>{record.name}</span>
            </div>
        );
    }, [setSelectedRowKey]);

    return (
        <div className='favorite-config-table-left'>
            <Table
                scroll={{
                    y: '100%'
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    pageSize: 99999,
                    total: data.length,
                    hideOnSinglePage: true,
                }} 
                columns={[]}
                dataSource={data}
            />
        </div>
    );
});
