import React, { Component } from 'react';
import { Form, Space } from 'udesk-ui';
import Locales from 'UdeskLocales';


const { Item } = Form;

const MixtureComponent: React.FC<any> = ({ onChange, value, field, children }) => {
    return (
        <Space>
            {field.addonBefore}
            {React.Children.map(children, (child) => React.cloneElement(child, { onChange, value }))}
            {field.addonAfter}
        </Space>
    );
};
export default class Field extends Component<any> {
    private destroy = false;
    render() {
        const locales = Locales['current'];

        const { field, mixedConfigMap, formStore } = this.props;
        const { field: fieldInField, forceDisabled, forceHidden, ...args } = field;
        const { props = {} } = fieldInField;
        const { disabled, ...fieldPropsWithoutDisabled } = props;
        let Component = mixedConfigMap[fieldInField.type];
        //未找到对应组件
        if (!Component) {
            if (process.env.NODE_ENV === 'development') {
                return <div>{`${/* 未找到自定义组件： */locales.components.createForm.field.customComponentNotFound}${fieldInField.type}`}</div>;
            }
            return null;
        }
        if (formStore.getFieldVisible(field.name) === false || forceHidden) {
            return null;
        }
        const getDisabledProps = () => {
            if (forceDisabled) {
                return Boolean(forceDisabled);
            }
            const fieldDisable = formStore.getFieldDisable(field.name);
            if(typeof fieldDisable === 'boolean'){
                return fieldDisable;
            }
            return Boolean(disabled);
        };
        return (
            <Item {...args}>
                <MixtureComponent field={field}>
                    <Component
                        disabled={getDisabledProps()}
                        placeholder={`${locales.labels.pleaseEnter}${field.label}`}
                        {...(fieldPropsWithoutDisabled || {})}
                    />
                </MixtureComponent>
            </Item>
        );
    }
    public reRender() {
        if (this.destroy) return;
        // const { formStore, field } = this.props;
        this.forceUpdate();
    }
    componentDidMount() {
        const { field, formStore } = this.props;
        formStore.registerField(field.name, this);
    }
    componentWillUnmount() {
        this.destroy = true;
    }
}
