// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetSupervisionRuleDetailsByIdParam,
    BaseResponseInspectionPointFoundResponse,
} from '../../types';

/**
 * 获取规则配置详情
 *
 * @export
 * @tags 监督项规则设置API
 * @link [GET] /supervisionRuleDetails/{id}
 * @param id
 */
export function getSupervisionRuleDetailsById(
    options: ApiOptions<GetSupervisionRuleDetailsByIdParam, never> & {
        segments: GetSupervisionRuleDetailsByIdParam;
    }
): Promise<BaseResponseInspectionPointFoundResponse> {
    return request<never, GetSupervisionRuleDetailsByIdParam, never>(
        '/supervisionRuleDetails/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getSupervisionRuleDetailsById' }
    );
}

export const meta = [
    { tags: ['监督项规则设置API'], path: '/supervisionRuleDetails/{id}', method: 'get' },
];
