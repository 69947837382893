import React, { FC, memo, useMemo } from 'react';

export type TemplateProps = {
    value?: any;
    empty?: string;
    options?: any[];
    suffix?: string;
};

const Template: FC<TemplateProps> = memo((props) => {
    let { value, empty = '--', options, suffix = '' } = props;

    const text = useMemo(() => {
        let text = value ? value + suffix : empty;

        if (options) {
            const item = options.find((item) => item.value === value);
            if (item) {
                text = item.label + suffix;
            }
        }

        return text;
    }, [empty, options, suffix, value]);

    return <span>{text}</span>;
});

export default Template;
