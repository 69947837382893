function _typeof(o) {
  '@babel/helpers - typeof';
  return (
    (_typeof =
      'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
        ? function (o) {
            return typeof o;
          }
        : function (o) {
            return o &&
              'function' == typeof Symbol &&
              o.constructor === Symbol &&
              o !== Symbol.prototype
              ? 'symbol'
              : typeof o;
          }),
    _typeof(o)
  );
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r &&
      (o = o.filter(function (r) {
        return Object.getOwnPropertyDescriptor(e, r).enumerable;
      })),
      t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2
      ? ownKeys(Object(t), !0).forEach(function (r) {
          _defineProperty(e, r, t[r]);
        })
      : Object.getOwnPropertyDescriptors
      ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t))
      : ownKeys(Object(t)).forEach(function (r) {
          Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
        });
  }
  return e;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, 'string');
  return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== 'object' || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || 'default');
    if (_typeof(res) !== 'object') return res;
    throw new TypeError('@@toPrimitive must return a primitive value.');
  }
  return (hint === 'string' ? String : Number)(input);
}
function _toConsumableArray(arr) {
  return (
    _arrayWithoutHoles(arr) ||
    _iterableToArray(arr) ||
    _unsupportedIterableToArray(arr) ||
    _nonIterableSpread()
  );
}
function _nonIterableSpread() {
  throw new TypeError(
    'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
  );
}
function _iterableToArray(iter) {
  if (
    (typeof Symbol !== 'undefined' && iter[Symbol.iterator] != null) ||
    iter['@@iterator'] != null
  )
    return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _slicedToArray(arr, i) {
  return (
    _arrayWithHoles(arr) ||
    _iterableToArrayLimit(arr, i) ||
    _unsupportedIterableToArray(arr, i) ||
    _nonIterableRest()
  );
}
function _nonIterableRest() {
  throw new TypeError(
    'Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
  );
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === 'string') return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === 'Object' && o.constructor) n = o.constructor.name;
  if (n === 'Map' || n === 'Set') return Array.from(o);
  if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }
  return arr2;
}
function _iterableToArrayLimit(r, l) {
  var t =
    null == r ? null : ('undefined' != typeof Symbol && r[Symbol.iterator]) || r['@@iterator'];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (((i = (t = t.call(r)).next), 0 === l)) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) {}
    } catch (r) {
      (o = !0), (n = r);
    } finally {
      try {
        if (!f && null != t.return && ((u = t.return()), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
import { useEffect, useState } from 'react';
var useRowSelection = function useRowSelection(rowSelectionType, options) {
  var _ref = options !== null && options !== void 0 ? options : {},
    _ref$rowKeysFieldName = _ref.rowKeysFieldName,
    rowKeysFieldName = _ref$rowKeysFieldName === void 0 ? 'id' : _ref$rowKeysFieldName,
    selectedRowKeys = _ref.selectedRowKeys,
    onSelectedRowKeysChange = _ref.onSelectedRowKeysChange;
  var _useState = useState(
      (options === null || options === void 0 ? void 0 : options.defaultSelectedRowKeys) || [],
    ),
    _useState2 = _slicedToArray(_useState, 2),
    selectedItems = _useState2[0],
    setSelectedItems = _useState2[1];
  var resetToEmpty = function resetToEmpty() {
    setSelectedItems([]);
    onSelectedRowKeysChange === null || onSelectedRowKeysChange === void 0
      ? void 0
      : onSelectedRowKeysChange([], []);
  };
  if (!rowSelectionType)
    return {
      rowSelection: undefined,
      instance: {
        resetToEmpty: resetToEmpty,
      },
    };
  var triggerOnChange = function triggerOnChange(newSelectedItems) {
    onSelectedRowKeysChange === null || onSelectedRowKeysChange === void 0
      ? void 0
      : onSelectedRowKeysChange(
          newSelectedItems.map(function (i) {
            return i[rowKeysFieldName];
          }),
          newSelectedItems,
        );
  };
  var onCheckChange = function onCheckChange(record, selected, selectedRows, nativeEvent) {
    var newSelectedItems = _toConsumableArray(selectedItems);
    if (selected) {
      newSelectedItems.push(record);
    } else {
      newSelectedItems = newSelectedItems.filter(function (item) {
        return item[rowKeysFieldName] !== record[rowKeysFieldName];
      });
    }
    setSelectedItems(newSelectedItems);
    triggerOnChange(newSelectedItems);
  };
  var onSelectAll = function onSelectAll(selected, selectedRows, changeRows) {
    var newSelectedItems = _toConsumableArray(selectedItems);
    if (selected) {
      newSelectedItems = [].concat(
        _toConsumableArray(newSelectedItems),
        _toConsumableArray(changeRows),
      );
    } else {
      newSelectedItems = newSelectedItems.filter(function (item) {
        return !changeRows.find(function (r) {
          return r[rowKeysFieldName] === item[rowKeysFieldName];
        });
      });
    }
    setSelectedItems(newSelectedItems);
    triggerOnChange(newSelectedItems);
  };
  useEffect(
    function () {
      if (!selectedRowKeys) return;
      setSelectedItems(
        selectedRowKeys.map(function (i) {
          return _defineProperty({}, rowKeysFieldName, i);
        }),
      );
    },
    [selectedRowKeys],
  );
  return {
    rowSelection: _objectSpread(
      _objectSpread({}, options),
      {},
      {
        type: rowSelectionType,
        onSelect: onCheckChange,
        onSelectAll: onSelectAll,
        selectedRowKeys: selectedItems.map(function (i) {
          return i[rowKeysFieldName];
        }),
      },
    ),
    instance: {
      resetToEmpty: resetToEmpty,
    },
  };
};
export default useRowSelection;
