import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {Button,Icon } from 'udesk-ui';
import { fire } from 'src/util/core';

const useForceUpdate = () => {
    const [, setCount] = useState(0);
    return useCallback((flag) => {
        if (!flag) {
            setTimeout(() => {
                setCount(count => count + 1);
            }, 1000);
        }
    }, []);
};

export default React.memo((props: any) => {
    const {
        locales,
        value: data, 
        onChange, 
        SmartToolBarRef, 
        openSmartToolBarCallback, 
        closeSmartToolBarCallback 
    } =props;
    const [isInitSmartToolBar, setIsInitSmartToolBar] = useState(false);
    const forceUpdate = useForceUpdate();
    const [value, setValue] = useState(data);

    const $smartToolBar = useMemo(() => {
        return SmartToolBarRef.current;
    }, [
        SmartToolBarRef.current
    ]);

    const $smartTags = useMemo(() => {
        if ($smartToolBar) {
            return $smartToolBar.privates.smartTagsRef.current;
        }
    }, [
        $smartToolBar?.privates.smartTagsRef.current
    ]);

    const clickHandle = useCallback((item) => {
        setValue(value => {
            const list = [...value];
            const index = list.findIndex(smart => smart.semanticId === item.id);
            if (index > -1) {
                list.splice(index, 1);
            } else {
                list.push({
                    semanticId: item.id,
                    semanticName: item?.title,
                    sentence: item?.tags[0]?.content
                });
            }
            onChange(list);
            return list;
        });
        return false;
    }, [
        onChange
    ]);

    const setSmartActiveTags = useCallback((value, error?) => {
        if ($smartTags) {
            $smartTags.actions.setActiveTagId(value);
        } else {
            fire(error, $smartTags);
        }
    }, [
        $smartTags
    ]);

    const delSmartHandle = useCallback((event, item, data) => {
        event.stopPropagation();
        let list = [...data];
        if (item) {
            onChange(list.filter(smart => smart !== item));
        }
    }, [
        onChange
    ]);

    const openSmartToolBar = useCallback(() => {
        if ($smartToolBar) {
            if (!$smartToolBar.state.visible) {
                $smartToolBar.actions.open(
                    {
                        open: clickHandle,
                        close: closeSmartToolBarCallback,
                    }, 
                    value.map(item => item.semanticId)
                );
            }
            setIsInitSmartToolBar(true);
            $smartToolBar.actions.seleteTag('3');
        }
    }, [
        $smartToolBar, 
        closeSmartToolBarCallback, 
        clickHandle, 
        value
    ]);

    const scrollTo = useCallback((id) => {
        $smartTags?.actions.scrollTo(id);
    }, [
        $smartTags
    ]);

    const openHandle = useCallback(() => {
        openSmartToolBarCallback();
        openSmartToolBar();
    }, [
        openSmartToolBar,
        openSmartToolBarCallback
    ]);

    // const getDataForValue = useCallback((ids) => {
    //     const smartTagsList = $smartTags?.privates.smartTagsList;
    //     if (smartTagsList) {
    //         return ids.map(
    //             id => smartTagsList.find(item => item.id === id)
    //         ).filter(
    //             item => !!item
    //         );
    //     }
    //     return [];
    // }, [
    //     $smartTags?.privates.smartTagsList
    // ]);

    useEffect(() => {
        if ($smartTags) {
            $smartTags.privates.isNotAutoSort = true;
            $smartTags.privates.isNotOnlyOneActiveKey = true;
        }
        return () => {
            if ($smartTags) {
                $smartTags.privates.isNotAutoSort = false;
                $smartTags.privates.isNotOnlyOneActiveKey = false;
            }

        };
    }, [
        $smartTags
    ]);

    useEffect(() => {
        let ids = [];
        if (value) {
            ids = value.map(item => item.semanticId);
        }
        setSmartActiveTags(ids);
    }, [
        setSmartActiveTags,
        value
    ]);

    // 防止$smartTags因没有加载导致为未定义
    useEffect(() => {
        if (isInitSmartToolBar) {
            setSmartActiveTags(value.map(item => item.semanticId), () => {
                forceUpdate($smartTags);
            });
        }
    }, [
        $smartTags,
        forceUpdate, 
        isInitSmartToolBar, 
        setSmartActiveTags, 
        value
    ]);

    useEffect(() => setValue(data), [data]);

    return (
        <>
            <Button 
                className="smart-toolbar"
                onClick={openHandle} 
                block>
                <span>{/* 选择语义标签 */}{locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.smartToolbar.selectSemanticTag}</span>
            </Button>
            {
                value && value.length > 0 ? (
                    <div className="smart-list">
                        {
                            value.map(item => {
                                return (
                                    <div onClick={() => scrollTo(item.semanticId)}>
                                        <span className='close'>
                                            <Button 
                                                size='small' 
                                                type="link" 
                                                onClick={(e) => delSmartHandle(e, item, value)}
                                                icon={<Icon type='CloseOutlined' antdIcon={true} />} />
                                        </span>
                                        <div>
                                            <span>{item.semanticName}</span>
                                        </div>
                                        <div>
                                            <span>{item.sentence}</span>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                ) : null
            }

        </>
    );
});