// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostIntelligentPartnerSmartRemarkSubmitBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 提交生成智能点评接口
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [POST] /intelligentPartner/smart/remark/submit
 */
export function postIntelligentPartnerSmartRemarkSubmit(
    data: PostIntelligentPartnerSmartRemarkSubmitBody
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerSmartRemarkSubmit(
    data: PostIntelligentPartnerSmartRemarkSubmitBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerSmartRemarkSubmit(
    data: PostIntelligentPartnerSmartRemarkSubmitBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentPartnerSmartRemarkSubmitBody, never, never>(
        '/intelligentPartner/smart/remark/submit',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerSmartRemarkSubmit',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/smart/remark/submit',
        method: 'post',
    },
];
