// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListInspectionTaskFoundResponse } from '../../types';

/**
 * 获取规则模板几种数据源的质检任务列表
 *
 * @export
 * @tags 智能分析-规则模板
 * @link [GET] /pointTemplate/task
 */
export function getPointTemplateTask(): Promise<BaseResponseListInspectionTaskFoundResponse>;
export function getPointTemplateTask(
    options: ApiOptions<never, never>
): Promise<BaseResponseListInspectionTaskFoundResponse>;
export function getPointTemplateTask(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListInspectionTaskFoundResponse> {
    return request<never, never, never>('/pointTemplate/task', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getPointTemplateTask',
    });
}

export const meta = [{ tags: ['智能分析-���则模板'], path: '/pointTemplate/task', method: 'get' }];
