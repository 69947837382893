export default {
  lang: {
    confirm: {
      title:
        'Bestätigen Sie, dass Sie löschen möchten? Nach dem Löschen kann es nicht wieder hergestellt werden',
      okText: 'Löschen',
      cancelText: 'Stornierung',
    },
  },
};
