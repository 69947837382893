// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewCallByIdTopicTagQuery,
    GetReviewCallByIdTopicTagParam,
    BaseResponseListTopicTagFoundResultResponse,
} from '../../../../types';

/**
 * findTopicTagById
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/{id}/topicTag
 * @param id
 */
export function getReviewCallByIdTopicTag(
    options: ApiOptions<GetReviewCallByIdTopicTagParam, GetReviewCallByIdTopicTagQuery> & {
        segments: GetReviewCallByIdTopicTagParam;
    }
): Promise<BaseResponseListTopicTagFoundResultResponse> {
    return request<never, GetReviewCallByIdTopicTagParam, GetReviewCallByIdTopicTagQuery>(
        '/review/call/{id}/topicTag',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewCallByIdTopicTag' }
    );
}

export const meta = [{ tags: ['通话记录'], path: '/review/call/{id}/topicTag', method: 'get' }];
