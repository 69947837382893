// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostSmartTagsFindAllBody, BaseResponseListSmartTagFoundResponse } from '../../types';

/**
 * findAll
 *
 * @export
 * @tags smart-tag-controller
 * @link [POST] /smartTags/findAll
 */
export function postSmartTagsFindAll(
    data: PostSmartTagsFindAllBody
): Promise<BaseResponseListSmartTagFoundResponse>;
export function postSmartTagsFindAll(
    data: PostSmartTagsFindAllBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListSmartTagFoundResponse>;
export function postSmartTagsFindAll(
    data: PostSmartTagsFindAllBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListSmartTagFoundResponse> {
    return request<PostSmartTagsFindAllBody, never, never>('/smartTags/findAll', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSmartTagsFindAll',
    });
}

export const meta = [
    { tags: ['smart-tag-controller'], path: '/smartTags/findAll', method: 'post' },
];
