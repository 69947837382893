// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerHomePageStudentTaskTrendQuery,
    BaseResponseListIntelligentPartnerHomePageStudentTaskTrend,
} from '../../../../types';

/**
 * 学员端任务趋势接口
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [GET] /intelligentPartner/homePage/student/taskTrend
 */
export function getIntelligentPartnerHomePageStudentTaskTrend(
    options: ApiOptions<never, GetIntelligentPartnerHomePageStudentTaskTrendQuery> & {
        params: GetIntelligentPartnerHomePageStudentTaskTrendQuery;
    }
): Promise<BaseResponseListIntelligentPartnerHomePageStudentTaskTrend> {
    return request<never, never, GetIntelligentPartnerHomePageStudentTaskTrendQuery>(
        '/intelligentPartner/homePage/student/taskTrend',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerHomePageStudentTaskTrend',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/student/taskTrend',
        method: 'get',
    },
];
