export default {
  lang: {
    export: 'Xuất',
    successfully: 'Xuất nhiệm vụ được tạo thành công',
    pleaseJumpTo: 'Vui lòng chuyển đến',
    uploadAndDownloadCenter: 'Trung tâm tải lên và tải xuống',
    check: 'Xem',
    exportAll: 'Xác nhận xuất toàn bộ nội dung?',
    total: 'Tổng cộng',
    confirm: 'Dữ liệu, xác nhận xuất?',
    error: 'Số lượng dữ liệu có thể xuất là 0, vui lòng thay đổi điều kiện lọc và thử lại',
  },
};
