import React, { useMemo } from 'react';
import { Avatar, Tabs } from 'udesk-ui';
import styled from 'styled-components';
import { Summary } from '../Summary';
import UdeskLocales from 'UdeskLocales';

const ViewpointWrap = styled.div`
    overflow: hidden;
    flex: 1;
    .udesk-qa-ui-tabs-left {
        height: 100%;
    }
    .udesk-qa-ui-tabs-tab-active {
        background-color: #e6f4ff;
    }
    .udesk-qa-ui-tabs-tab {
        padding-top: 8px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
`;

export const Viewpoint = (props) => {
    const { data, speakerList, onAdd, ...summaryProps } = props;
    const dataGroupBySpeaker = useMemo(() => {
        if (!Array.isArray(data)) return {};
        console.log('data', data);
        console.log('speakerList', speakerList);

        return data.reduce((p, c) => {
            if (!p[c.speaker]) {
                p[c.speaker] = [];
            }
            p[c.speaker].push(c);
            return p;
        }, {});
    }, [data, speakerList]);
    console.log('dataGroupBySpeaker', dataGroupBySpeaker);

    return (
        <ViewpointWrap>
            <Tabs tabPosition="left">
                {speakerList.map((speaker) => (
                    <Tabs.TabPane tab={<Avatar>{speaker}</Avatar>} key={speaker}>
                        <Summary
                            {...summaryProps}
                            data={dataGroupBySpeaker[speaker]}
                            onAdd={
                                onAdd &&
                                ((value) => {
                                    return onAdd({
                                        ...value,
                                        speaker,
                                    });
                                })
                            }
                            modalTitle={
                                /* 观点提取 */ UdeskLocales['current'].pages.conversationAnalysis
                                    .videoAnalysis.detail.components.viewpoint.index
                                    .viewpointExtraction
                            }
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </ViewpointWrap>
    );
    // return (
    //     <>
    //         <Title>{title}</Title>
    //         <div style={{overflowY: 'auto', flex: 1}}>
    //             {speakerList.map((speaker) => {
    //                 console.log('dataGroupBySpeaker[speaker]', dataGroupBySpeaker[speaker]);
    //                 return (
    //                     <SummaryItem>
    //                         <Avatar>{speaker}</Avatar>
    //                         <div className="summary-item-right">
    //                             <Summary
    //                                 {...summaryProps}
    //                                 data={dataGroupBySpeaker[speaker]}
    //                                 onAdd={(value) => {
    //                                     return onAdd({
    //                                         ...value,
    //                                         speaker,
    //                                     });
    //                                 }}
    //                                 modalTitle={/* 观点提取 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.viewpoint.index.viewpointExtraction}
    //                             />
    //                         </div>
    //                     </SummaryItem>
    //                 );
    //             })}
    //         </div>
    //     </>
    // );
};
