import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { Button, Modal, Space, message } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import { TypeEnum } from './const';
import { getAiModels } from 'src/api/aiModels';
import { deleteAiModelsById, putAiModelsById } from 'src/api/aiModels/{id}';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import './style.scss';

const Template = React.memo((props: any) => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });
    const ref = useRef<any>({});

    const queryList = useCallback(
        ({ pageSize, pageNum }: { pageSize: number; pageNum: number }) => {
            setLoading(true);
            getAiModels({
                params: {
                    pageNum,
                    pageSize,
                },
            }).then((resp) => {
                setDataSource(resp.data || []);
                setLoading(false);
                setPaging(resp.paging as any);
            });
        },
        []
    );

    useEffect(() => {
        queryList({ pageSize: 20, pageNum: 1 });
    }, [queryList]);

    const paginationProps = useMemo(() => {
        const { pageSize, total } = paging;
        return {
            current: paging.pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                const newPages = {
                    ...paging,
                    pageNum: page,
                    pageSize,
                };
                queryList({ ...newPages });
                setPaging(newPages);
            },
        };
    }, [paging, queryList]);

    const columns = useMemo(
        () => [
            {
                title: /* 模型名称 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.index.modelName,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: /* 模型描述 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.index.modelDescription,
                dataIndex: 'modelDesc',
                key: 'modelDesc',
            },
            {
                title: /* 类型 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.index.type,
                dataIndex: 'type',
                key: 'type',
                render: (_, record) => {
                    if (record.type === TypeEnum.Conform) {
                        return /* 是否符合 */ UdeskLocales['current'].pages.semanticIntelligence
                            .intelligentModel.index.whetherItMeetsTheRequirements;
                    } else if (record.type === TypeEnum.GradedEvaluation) {
                        return /* 分档评价 */ UdeskLocales['current'].pages.semanticIntelligence
                            .intelligentModel.index.gradedEvaluation;
                    } else {
                        return '-';
                    }
                },
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: /* 有效性 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.index.validity,
                renderType: 'switch',
                onSwitchChange: (item, index) => {
                    const { id, status } = item;
                    putAiModelsById(
                        { status: status ? 0 : 1 },
                        {
                            segments: {
                                id,
                            },
                            successMsg: UdeskLocales['current'].business.info.editSuccess,
                        }
                    ).then(() => {
                        let newData = [...dataSource];
                        newData[index].status = !newData[index].status;
                        setDataSource(newData);
                    });
                },
            },
            {
                dataIndex: 'actions',
                title: UdeskLocales['current'].labels.action,
                renderType: 'actions',
                onEdit: (item) => {
                    let routeOptions = {
                        history: props.history,
                        routeName: 'intelligentModelEdit',
                        pathParams: { id: item.id },
                        state: {},
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                },
                onDelete: (item) => {
                    deleteAiModelsById({
                        segments: {
                            id: item.id,
                        },
                    }).then((res) => {
                        const done = res.data?.code === 1;
                        const msg = res.data?.message;

                        if (done) {
                            message.success(
                                UdeskLocales['current'].business.notifyMessage.deleteSuccess
                            );
                            queryList({
                                pageNum: 1,
                                pageSize: paging.pageSize,
                            });
                        } else {
                            Modal.confirm({
                                title: msg,
                                onOk: () => {
                                    deleteAiModelsById({
                                        params: {
                                            confirm: 1,
                                        },
                                        segments: {
                                            id: item.id,
                                        },
                                    }).then(() => {
                                        message.success(
                                            UdeskLocales['current'].business.notifyMessage
                                                .deleteSuccess
                                        );
                                        queryList({
                                            pageNum: 1,
                                            pageSize: paging.pageSize,
                                        });
                                    });
                                },
                            });
                        }
                    });
                },
            },
        ],
        [dataSource, paging.pageSize, props.history, queryList]
    );

    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
        };
    }, [columns, dataSource]);

    const createBtn = (
        <Button
            onClick={() => {
                let routeOptions = {
                    history: props.history,
                    routeName: 'intelligentModelNew',
                    state: {},
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }}
        >
            {UdeskLocales['current'].labels.create}
        </Button>
    );

    return (
        <Page
            pageBodyClassName="knowledge-base-page-index"
            title={
                <Space>
                    <span>
                        {/* 智能模型 */}
                        {
                            UdeskLocales['current'].pages.semanticIntelligence.intelligentModel
                                .index.intelligentModel
                        }
                    </span>
                    <span style={{ fontSize: 12, color: 'rgba(0,0,0,0.45)' }}>
                        {/* 适用于主观项质检（如质检服务态度是否热情、服务是否耐心等场景） */}
                        {
                            UdeskLocales['current'].pages.semanticIntelligence.intelligentModel
                                .index
                                .applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc
                        }
                    </span>
                </Space>
            }
            padding={true}
        >
            <CommonTablePage ref={ref} loading={loading} mainButton={createBtn}>
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
