import LocalesManagerClass from 'udesk-web-toolkits/src/udesk/locales/locales-manager';
import reactUdeskLocales from 'udesk-react/src/udesk/locales';
import toolkitUdeskLocales from 'udesk-web-toolkits/src/udesk/locales';

import Chinese from './zh-cn';
import English from './en-us';
import ChineseTw from './zh-tw';
import FR from './fr';
import ES from './es';
import JA from './ja';
import TH from './th';
import AR from './ar';
import RU from './ru';
import TR from './tr';
import VI from './vi';
import PT from './pt';
import ChineseHK from './zh-hk';
import ID from './id';
import DE from './de';
import IT from './it';
import KO from './ko';
import PL from './pl';
import FI from './fi';
import NL from './nl';
import CS from './cs';
import MS from './ms';

import zhCN from 'udesk-ui/es/locale/zh_CN';
import enUS from 'udesk-ui/es/locale/en_US';
import zhTW from 'udesk-ui/es/locale/zh_TW';
import frFR from 'udesk-ui/es/locale/fr_FR';
import esES from 'udesk-ui/es/locale/es_ES';
import jaJP from 'udesk-ui/es/locale/ja_JP';
import thTH from 'udesk-ui/es/locale/th_TH';
import arEG from 'udesk-ui/es/locale/ar_EG';
import ruRU from 'udesk-ui/es/locale/ru_RU';
import trTR from 'udesk-ui/es/locale/tr_TR';
import viVN from 'udesk-ui/es/locale/vi_VN';
import ptPT from 'udesk-ui/es/locale/pt_PT';
import zhHK from 'udesk-ui/es/locale/zh_HK';
import idID from 'udesk-ui/es/locale/id_ID';
import deDE from 'udesk-ui/es/locale/de_DE';
import itIT from 'udesk-ui/es/locale/it_IT';
import koKR from 'udesk-ui/es/locale/ko_KR';
import plPL from 'udesk-ui/es/locale/pl_PL';
import fiFI from 'udesk-ui/es/locale/fi_FI';
import nlNL from 'udesk-ui/es/locale/nl_NL';
import csCZ from 'udesk-ui/es/locale/cs_CZ';
import msMY from 'udesk-ui/es/locale/ms_MY';

import gmUIZhCN from 'udesk_gm_ui/es/locale/zh_CN';
import gmUIEnUS from 'udesk_gm_ui/es/locale/en_US';
import gmUIZhTW from 'udesk_gm_ui/es/locale/zh_TW';
import gmUIJA from 'udesk_gm_ui/es/locale/ja';
import gmUIFR from 'udesk_gm_ui/es/locale/fr';
import gmUIES from 'udesk_gm_ui/es/locale/es';
import gmUITH from 'udesk_gm_ui/es/locale/th';
import gmUIAR from 'udesk_gm_ui/es/locale/ar';
import gmUIRU from 'udesk_gm_ui/es/locale/ru';
import gmUITR from 'udesk_gm_ui/es/locale/tr';
import gmUIVI from 'udesk_gm_ui/es/locale/vi';
import gmUIPT from 'udesk_gm_ui/es/locale/pt';
import gmUIZhHK from 'udesk_gm_ui/es/locale/zh_HK';
import gmUIZhID from 'udesk_gm_ui/es/locale/id';
import gmUIZhDE from 'udesk_gm_ui/es/locale/de';
import gmUIZhIT from 'udesk_gm_ui/es/locale/it';
import gmUIZhKO from 'udesk_gm_ui/es/locale/ko';
import gmUIZhPL from 'udesk_gm_ui/es/locale/pl';
import gmUIZhFI from 'udesk_gm_ui/es/locale/fi';
import gmUIZhNL from 'udesk_gm_ui/es/locale/nl';
import gmUIZhCS from 'udesk_gm_ui/es/locale/cs';
import gmUIMS from 'udesk_gm_ui/es/locale/ms';

export const localesStore = {
    'ZH-CN': Chinese,
    'EN-US': English,
    'ZH-TW': ChineseTw,
    FR,
    ES,
    JA,
    TH,
    AR,
    RU,
    TR,
    VI,
    PT,
    'ZH-HK': ChineseHK,
    ID,
    DE,
    IT,
    KO,
    PL,
    FI,
    NL,
    CS,
    MS,
};

// 智能质检项目的 code 对应
export const LOCALES_MAP = {
    zh: {
        // 本项目/udesk-react/udesk-web-toolkits 使用
        language: 'ZH-CN',
        // udesk-ui/antd 使用
        antd: zhCN,
        // udesk_gm_ui 使用
        gmUi: gmUIZhCN,
        // moment 使用
        moment: 'zh-cn',
    },
    en: {
        language: 'EN-US',
        antd: enUS,
        gmUi: gmUIEnUS,
    },
    tw: {
        language: 'ZH-TW',
        antd: zhTW,
        gmUi: gmUIZhTW,
        moment: 'zh-tw',
    },
    fr: {
        language: 'FR',
        antd: frFR,
        gmUi: gmUIFR,
        moment: 'fr',
    },
    es: {
        language: 'ES',
        antd: esES,
        gmUi: gmUIES,
        moment: 'es',
    },
    ja: {
        language: 'JA',
        antd: jaJP,
        gmUi: gmUIJA,
        moment: 'ja',
    },
    th: {
        language: 'TH',
        antd: thTH,
        gmUi: gmUITH,
        moment: 'th',
    },
    ar: {
        language: 'AR',
        antd: arEG,
        gmUi: gmUIAR,
        moment: 'ar',
    },
    ru: {
        language: 'RU',
        antd: ruRU,
        gmUi: gmUIRU,
        moment: 'ru',
    },
    tr: {
        language: 'TR',
        antd: trTR,
        gmUi: gmUITR,
        moment: 'tr',
    },
    vi: {
        language: 'VI',
        antd: viVN,
        gmUi: gmUIVI,
        moment: 'vi',
    },
    pt: {
        language: 'PT',
        antd: ptPT,
        gmUi: gmUIPT,
        moment: 'pt',
    },
    hk: {
        language: 'ZH-HK',
        antd: zhHK,
        gmUi: gmUIZhHK,
        moment: 'zh-hk',
    },
    id: {
        language: 'ID',
        antd: idID,
        gmUi: gmUIZhID,
        moment: 'id',
    },
    de: {
        language: 'DE',
        antd: deDE,
        gmUi: gmUIZhDE,
        moment: 'de',
    },
    it: {
        language: 'IT',
        antd: itIT,
        gmUi: gmUIZhIT,
        moment: 'it',
    },
    ko: {
        language: 'KO',
        antd: koKR,
        gmUi: gmUIZhKO,
        moment: 'ko',
    },
    pl: {
        language: 'PL',
        antd: plPL,
        gmUi: gmUIZhPL,
        moment: 'pl',
    },
    fi: {
        language: 'FI',
        antd: fiFI,
        gmUi: gmUIZhFI,
        moment: 'fi',
    },
    nl: {
        language: 'NL',
        antd: nlNL,
        gmUi: gmUIZhNL,
        moment: 'nl',
    },
    cs: {
        language: 'CS',
        antd: csCZ,
        gmUi: gmUIZhCS,
        moment: 'cs',
    },
    ms: {
        language: 'MS',
        antd: msMY,
        gmUi: gmUIMS,
        moment: 'ms',
    },
};

class BiLocalesManagerClass extends LocalesManagerClass {
    setLanguage(languageCode) {
        // 设置本项目语言
        super.setLanguage(languageCode);
        // 设置 udesk-react 语言 （其内部会设置 udesk-web-toolkits 语言）
        reactUdeskLocales.setLanguage(languageCode);
    }
}

let localesManager = new BiLocalesManagerClass(localesStore, 'ZH-CN');
localesManager.merge(reactUdeskLocales, toolkitUdeskLocales);
export default localesManager;

// 质检结果申诉SDK code 对应
export const APPEAL_LOCALES_MAP = {
    'ZH-CN': {
        // 本项目/udesk-react/udesk-web-toolkits 使用
        language: 'ZH-CN',
        // udesk-ui/antd 使用
        antd: zhCN,
        // moment 使用
        moment: 'zh-cn',
    },
    'EN-US': {
        language: 'EN-US',
        antd: enUS,
    },
    'ZH-TW': {
        language: 'ZH-TW',
        antd: zhTW,
        moment: 'zh-tw',
    },
    FR: {
        language: 'FR',
        antd: frFR,
        moment: 'fr',
    },
    ES: {
        language: 'ES',
        antd: esES,
        moment: 'es',
    },
    JA: {
        language: 'JA',
        antd: jaJP,
        moment: 'ja',
    },
    TH: {
        language: 'TH',
        antd: thTH,
        moment: 'th',
    },
    AR: {
        language: 'AR',
        antd: arEG,
        moment: 'ar',
    },
    RU: {
        language: 'RU',
        antd: ruRU,
        moment: 'ru',
    },
    TR: {
        language: 'TR',
        antd: trTR,
        moment: 'tr',
    },
    VI: {
        language: 'VI',
        antd: viVN,
        moment: 'vi',
    },
    PT: {
        language: 'PT',
        antd: ptPT,
        moment: 'pt',
    },
    'ZH-HK': {
        language: 'ZH-HK',
        antd: zhHK,
        moment: 'zh-hk',
    },
    ID: {
        language: 'ID',
        antd: idID,
        moment: 'id',
    },
    DE: {
        language: 'DE',
        antd: deDE,
        moment: 'de',
    },
    IT: {
        language: 'IT',
        antd: itIT,
        moment: 'it',
    },
    KO: {
        language: 'KO',
        antd: koKR,
        moment: 'ko',
    },
    PL: {
        language: 'PL',
        antd: plPL,
        moment: 'pl',
    },
    FI: {
        language: 'FI',
        antd: fiFI,
        moment: 'fi',
    },
    NL: {
        language: 'NL',
        antd: nlNL,
        moment: 'nl',
    },
    CS: {
        language: 'CS',
        antd: csCZ,
        moment: 'cs',
    },
    MS: {
        language: 'MS',
        antd: msMY,
        moment: 'ms',
    },
};
