import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import { FormBuilder, Button, Space, Row, Col, Radio } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import cusConfigMap from 'src/common/config/form-config/cus-component-map';
import Page from 'Component/common/udesk-qa-page-layout';
import Locales from 'UdeskLocales';
import './style.scss';
import { InspectionPointFoundResponse } from 'src/api/types';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { postSupervisionRuleDetails } from 'src/api/supervisionRuleDetails';
import { getSupervisionRuleConfigs } from 'src/api/supervisionRuleConfigs';
import { getSupervisionRuleDetailsById } from 'src/api/supervisionRuleDetails/{id}';
import Recommendation from './recommendation';
import { isSalesSubApp } from 'Udesk/system/subApp';

const locales = Locales['current'];
const smartCustomerTag = locales.pages.wechatQa.smartCustomerTag.detail;

const LAYOUT = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
};
const MAX_WIDTH = {
    maxWidth: 350,
};

const EffectiveTime = (props) => {
    // const [validityPeriod, setValidityPeriod] = useState<1 | 2 | 3>(1);
    return (
        <div>
            <Radio.Group
                onChange={(e) => {
                    props.onChange?.(e.target.value);
                }}
                style={{
                    height: 32,
                    verticalAlign: 'sub',
                }}
                value={props.value}
                options={[
                    {
                        label: /* 永久有效 */ locales.pages.intelligentAnalysis.supervision.detail
                            .index.permanentlyValid,
                        value: 1,
                    },
                    {
                        label: /* 永久无效 */ locales.pages.intelligentAnalysis.supervision.detail
                            .index.permanentlyInvalid,
                        value: 0,
                    },
                    {
                        label: /* 自定义有效期 */ locales.pages.intelligentAnalysis.supervision
                            .detail.index.customValidity,
                        value: 2,
                    },
                ]}
            />
            {props.value === 2 && (
                <div style={{ marginTop: 8 }}>
                    <FormBuilder.Item
                        label={
                            /* 有效期 */ locales.pages.intelligentAnalysis.supervision.detail.index
                                .termOfValidity
                        }
                        name="cusEffectTime"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <UdeskDatePicker></UdeskDatePicker>
                    </FormBuilder.Item>
                </div>
            )}
        </div>
    );
};
interface InitialValues extends Omit<InspectionPointFoundResponse, 'preRuleList' | 'ruleList'> {
    preFlag: 0 | 1;
    name?: string;
    tagDesc?: string;
    preRuleList?: any;
    ruleList?: any;
    cusEffectTime?: [string, string];
    libraryFiles: any;
    knowledgeBases: any;
}

const DEFAULT_CONDITION: any = getCondition('R1');
const DEFAULT_PREV_CONDITION: any = getCondition('PR1');

function SupervisionDetail(props) {
    const [initialValues, setInitialValues] = useState<InitialValues>(() => {
        const queryParams = new URLSearchParams(props.location.search);
        let classification = queryParams.get('classificationId');
        let res: any = {
            conditionList: [DEFAULT_CONDITION],
            preConditionList: [DEFAULT_PREV_CONDITION],
            preRuleList: {
                judgeStrategy: Udesk.enums.operatorRuleTypes.custom.id,
            },
            ruleList: {
                judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            },
            preFlag: 0,
            effectConfig: 1,
            smartRecommendStatus: 0,
            inspectionExecuteStatus: 1,
        };
        if (classification) {
            res.categoryId = parseInt(classification, 10);
        }
        return res;
    });
    const [loading, setLoading] = useState(false);
    const [conditions, setConditions] = useState<any[]>([DEFAULT_CONDITION]);
    // const [prevCondition, setPrevConditions] = useState<any[]>([DEFAULT_PREV_CONDITION]);
    const [classification, setClassification] = useState<any[]>([]);
    const [wrapForm] = FormBuilder.useForm();
    // const [triggerPointId, setTriggerPointId] = useState<number | undefined>(undefined);
    useEffect(() => {
        getSupervisionRuleConfigs().then((resp) => {
            setClassification(
                (resp.data ?? []).map((i) => ({ value: i.id, label: i.categoryName }))
            );
        });
        let { match } = props;
        let id = match.params.id;
        if (id) {
            setLoading(true);
            getSupervisionRuleDetailsById({
                segments: {
                    id,
                },
            })
                .then((resp) => {
                    if (resp.data) {
                        setInitialValues({
                            ...(resp.data as InitialValues),
                            cusEffectTime:
                                resp.data.effectStartTime && resp.data.effectEndTime
                                    ? [resp.data.effectStartTime, resp.data.effectEndTime]
                                    : undefined,
                            tagDesc: resp.data.remark,
                            preConditionList: resp.data.preConditionList ?? [
                                DEFAULT_PREV_CONDITION,
                            ],
                            ruleList: resp.data.ruleList?.[0]
                                ? {
                                      customJudgeLogic: resp.data.ruleList?.[0].formula,
                                      judgeStrategy: resp.data.ruleList?.[0].judgeStrategy,
                                  }
                                : {
                                      judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
                                  },
                            preRuleList: resp.data.preRuleList?.[0]
                                ? {
                                      customJudgeLogic: resp.data.preRuleList?.[0].formula,
                                      judgeStrategy: resp.data.preRuleList?.[0].judgeStrategy,
                                  }
                                : {
                                      judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
                                  },
                            smartRecommendStatus: resp.data.smartRecommendStatus || 0,
                            libraryFiles: resp.data.libraryFiles
                                ? resp.data.libraryFiles.split(',').map((id) => +id)
                                : [],
                            knowledgeBases: resp.data.knowledgeBases
                                ? resp.data.knowledgeBases.split(',').map((id) => +id)
                                : [],
                        });
                        // setPrevConditions(resp.data.preConditionList ?? []);
                        setConditions(resp.data.conditionList ?? []);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    const transitionToIndex = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'supervisionList',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const onFinish = (values) => {
        const {
            ruleList,
            cusEffectTime,
            name,
            preRuleList,
            libraryFiles,
            knowledgeBases,
            ...other
        } = values;
        const getArray = (data) => (Array.isArray(data) ? data : [data]).filter(Boolean);
        const getRule = (rule) => {
            if ([1, 2].includes(rule.judgeStrategy)) {
                return {
                    judgeStrategy: rule.judgeStrategy,
                    formula: '',
                };
            }
            return {
                judgeStrategy: rule.judgeStrategy,
                formula: rule.customJudgeLogic,
            };
        };
        const preRuleListParams = getArray(preRuleList).map(getRule);
        const ruleListParams = getArray(ruleList).map(getRule);

        other.preConditionList = other.conditionList; // 将算子信息放到前置算子中

        let params = {
            ...other,
            tagName: name,
            ruleList: ruleListParams,
            relateConditionList: other.conditionList.map((i) => ({ idCode: i.idCode })),
            preRelateConditionList:
                other.preConditionList?.map((i) => ({ idCode: i.idCode })) ?? undefined,
            preRuleList: preRuleListParams,
        };
        if (params.smartRecommendStatus) {
            params.libraryFileIds = libraryFiles;
            params.knowledgeBaseIds = knowledgeBases;
        }
        if (Array.isArray(cusEffectTime)) {
            params.effectStartTime = cusEffectTime[0];
            params.effectEndTime = cusEffectTime[1];
        }
        let { match } = props;
        let id = match.params.id;
        if (id) {
            params.id = parseInt(id, 10);
        }

        postSupervisionRuleDetails(params).then(() => {
            transitionToIndex();
        });
    };

    const config: FormProps<any> = {
        // onValuesChange: onCollectionValuesChange,
        onFinish: onFinish,
        initialValues: initialValues,
        customComponents: { ...cusConfigMap, EffectiveTime, Recommendation },
        ...LAYOUT,
        fields: [
            {
                type: 'Input',
                name: 'name',
                label: /* 名称 */ locales.pages.intelligentAnalysis.supervision.detail.index.name,
                props: {
                    showCount: true,
                    maxCount: 40,
                    style: MAX_WIDTH,
                    placeholder: locales.labels.pleaseEnter,
                },
                rules: [
                    {
                        required: true,
                    },
                    {
                        max: 40,
                        type: 'string',
                    },
                ],
            },
            {
                type: 'TextArea',
                name: 'tagDesc',
                label: /* 说明 */ locales.pages.intelligentAnalysis.supervision.detail.index
                    .explain,
                props: {
                    autoSize: { minRows: 2, maxRows: 2 },
                    showCount: true,
                    maxCount: 255,
                    placeholder: locales.labels.pleaseEnter,
                },
            },
            {
                type: 'EffectiveTime',
                name: 'effectConfig',
                label: /* 状态 */ locales.pages.intelligentAnalysis.supervision.detail.index.state,
                props: {
                    options: [
                        {
                            label: locales.labels.switchOn,
                            value: 1,
                        },
                        {
                            label: locales.labels.switchOff,
                            value: 0,
                        },
                    ],
                    style: MAX_WIDTH,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'Select',
                name: 'categoryId',
                label: /* 分类 */ locales.pages.intelligentAnalysis.supervision.detail.index
                    .classification,
                props: {
                    options: classification,
                    style: MAX_WIDTH,
                    placeholder: locales.labels.pleaseSelect,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'Recommendation',
                label: /* 智能推荐 */ locales.pages.intelligentAnalysis.supervision.detail.index
                    .smartRecommendation,
                name: 'smartRecommendStatus',
                noStyle: true,
                props: {
                    type: 0,
                },
            },

            // {
            //     type: 'ConditionListGather' as any,
            //     name: 'preConditionList',
            //     label: /* 前置条件规则 */locales.pages.intelligentAnalysis.supervision.detail.index.preconditionRule,
            //     props: {
            //         type: [0],
            //         ruglar: true,
            //         isCollection: true,
            //         onChange: (value) => {
            //             setPrevConditions(value);
            //         },
            //     },
            //     rules: [
            //         {
            //             required: true,
            //         },
            //     ],
            // },
            // {
            //     type: 'ConditionListGather' as any,
            //     name: 'conditionList',
            //     label: smartCustomerTag.condition,
            //     props: {
            //         type: [0],
            //         ruglar: true,
            //         isCollection: true,
            //         onChange: (value) => {
            //             setConditions(value);
            //         },
            //     },
            //     rules: [
            //         {
            //             required: true,
            //         },
            //     ],
            // },
            {
                type: 'ConditionListGather' as any,
                name: 'conditionList',
                label: smartCustomerTag.condition,
                props: {
                    type: [1],
                    ruglar: true,
                    isCollection: true,
                    isNewAssociate: 1,
                    isAutoAssociateDateSource: isSalesSubApp() ? 0 : 1,
                    onChange: (value) => {
                        setConditions(value);
                    },
                    replenishOperatorTypes(showList) {
                        const index = showList.findIndex((item) => item.id === 13);
                        if (index > -1) {
                            showList.splice(index, 1);
                        }
                    },
                    replenishConditionTypes(showList, list) {
                        isSalesSubApp(() => {
                            showList.push(list[3]); //添加信息模块
                        });
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'RadioGroup',
                name: 'preFlag',
                label: /* 前置条件 */ locales.pages.intelligentAnalysis.supervision.detail.index
                    .preconditions,
                tooltip: (
                    <div>
                        <div>
                            {/* 1.为该条监督项设置前置条件： */}
                            {
                                locales.pages.intelligentAnalysis.supervision.detail.index
                                    .SetPreconditionsForThisSupervisionItem
                            }
                        </div>
                        <div>
                            {/* 符合前置条件时，即为“应执行监督项”，则对该条监督项进行检测； */}
                            {
                                locales.pages.intelligentAnalysis.supervision.detail.index
                                    .whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested
                            }
                        </div>
                        <div>
                            {/* 不符合前置条件时，则不检测该条监督项； */}
                            {
                                locales.pages.intelligentAnalysis.supervision.detail.index
                                    .ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected
                            }
                        </div>
                        <div>
                            {/* 2.不设置前置条件，即默认对所有会话进行检测 */}
                            {
                                locales.pages.intelligentAnalysis.supervision.detail.index
                                    .NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault
                            }
                        </div>
                    </div>
                ),
                props: {
                    options: [
                        {
                            label: /* 有 */ locales.pages.intelligentAnalysis.supervision.detail
                                .index.have,
                            value: 1,
                        },
                        {
                            label: /* 无 */ locales.pages.intelligentAnalysis.supervision.detail
                                .index.nothing,
                            value: 0,
                        },
                    ],
                },
                effect: {
                    visible: {
                        1: ['preConditionList', 'preRuleList'],
                    },
                },
            },
            {
                type: 'Logic',
                name: 'preRuleList',
                label: /* 前置条件逻辑 */ locales.pages.intelligentAnalysis.supervision.detail.index
                    .preconditionLogic,
                props: {
                    conditions: conditions,
                    isCondition: true,
                    allowOperatorRuleTypes: [Udesk.enums.operatorRuleTypes[2]],
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'Logic',
                name: 'ruleList',
                label: smartCustomerTag.logic,
                props: {
                    conditions: conditions,
                    isCondition: true,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        ],
        footerRender: false,
    };

    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues]);
    return (
        <Page pageBodyClassName="udesk-qa-supervision-detail" padding={true} loading={loading}>
            <FormBuilder {...(config as FormProps)} form={wrapForm}></FormBuilder>
            <Row>
                <Col offset={4}>
                    <Space>
                        <Button type="primary" onClick={wrapForm.submit}>
                            {locales.labels.save}
                        </Button>
                        <Button onClick={transitionToIndex}>{locales.labels.cancel}</Button>
                    </Space>
                </Col>
            </Row>
        </Page>
    );
}

export default React.memo(SupervisionDetail);

function getCondition(idCode) {
    return {
        conditionType: 1, //1话术2交互3特征4信息
        taskConditionId: null,
        orderId: 1,
        operatorType: 11,
        idCode,
        operatorList: [
            {
                applyConditionHitType: 1,
                applyOperatorScope: {
                    key: 'all',
                    id: 1,
                },
                type: 11,
                operator: 'all',
                applyRole: 'all',
            },
        ],
        applyConditionId: undefined,
        applyConditionIdCode: null,
        operatorLogic: '1',
        operator: 'all',
        applyRole: 'all',
        applyScope: null,
        similarityScore: null,
        differWordNumber: null,
        wordPerMinite: null,
        maxWordPerMinite: null,
        grabIntervalTime: null,
        grabMaxSpeakTime: null,
        intervalSecond: null,
        keyword: '',
        semanticSentence: null,
        applyConditionScope: null,
        applyConditionHitType: 1,
        // applyOperatorScope: {
        //     key: 'all',
        //     id: 1
        // },
        param: {},
    };
}
