import React, { FC, useEffect, useMemo, useState } from 'react';
import { Select } from 'udesk-ui';
import { postIntelligentPartnerTaskInfosFindAll } from 'src/api/intelligentPartnerTaskInfos/findAll';
import { IntelligentPartnerTaskStatisticsFoundResponse } from 'src/api/types';
import { SelectProps } from 'udesk-ui/lib/select';
import { IntelligentPartnerTaskPageRequest } from 'src/api/types';

type TaskSelectProps = SelectProps<string> & { extraPrams?: IntelligentPartnerTaskPageRequest };

export const TaskSelect: FC<TaskSelectProps> = (props) => {
    const [options, setOptions] = useState<IntelligentPartnerTaskStatisticsFoundResponse[]>([]);

    const getOptions = useMemo(
        () => (taskName?: string) => {
            postIntelligentPartnerTaskInfosFindAll({
                taskName,
                ...(props.extraPrams || {}),
            }).then((res) => {
                setOptions(res.data || []);
            });
        },
        [props.extraPrams]
    );

    useEffect(() => {
        getOptions();
    }, [getOptions]);

    const cumputed_options = useMemo(
        () => options.map((o) => ({ ...o, label: o.taskName!, value: o.id! })),
        [options]
    );

    return <Select options={cumputed_options} {...props} />;
};
