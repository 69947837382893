import React, { useImperativeHandle } from 'react';
import styled from 'styled-components';
import Avatar from './Avatar';
import { IntelligentPartnerSentence } from 'src/api/types';
import ChatBubble from './ChatBubble';
import { FormatDate } from './UserNode';
import UdeskLocales from 'UdeskLocales';

const KnowledgeNodeWrap = styled.div`
    display: flex;
    justify-content: ${(props) => (props.align === 'right' ? 'flex-end' : 'flex-start')};

    & ::selection {
        background-color: rgba(199, 237, 206);
        color: rgba(0, 0, 0, 0.85);
    }
    .udesk-coach-user-node-left {
        /* flex: 1; */
        max-width: 70%;
        margin: ${(props) => (props.align === 'right' ? '0 12px 0 0' : '0 0 0 12px')};

        .udesk-coach-user-node-title {
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 8px;
            text-align: ${(props) => (props.align === 'right' ? 'end' : 'start')};
        }
        .udesk-coach-user-node-content {
            background: ${(props) => (props.align === 'right' ? '#1a6eff' : '#fff')};
            border-radius: 0px 4px 4px 4px;
            padding: 10px 12px;
            color: ${(props) => (props.align === 'right' ? '#fff' : 'rgba(0, 0, 0, 0.85)')};
            font-size: 14px;
            line-height: 22px;
            display: inline-flex;
            float: right;
        }
        .udesk-coach-user-node-footer {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 2px 8px;
            color: rgba(0, 0, 0, 0.45);
            clear: both;
        }
    }

    .udesk-qa-ui-avatar {
        width: 36px;
        height: 36px;
    }
`;

type KnowledgeNodeProps = IntelligentPartnerSentence & {
    index: number;
    align: 'right' | 'left';
    isTimeout: boolean;
    showCorrect?: boolean;
    recordId?: number;
};

export const KnowledgeNode = React.memo(
    React.forwardRef((props: KnowledgeNodeProps, ref) => {
        const { align, showCorrect, recordId, id } = props;

        useImperativeHandle(ref, () => {
            return {};
        });

        return (
            <KnowledgeNodeWrap align={align}>
                {align === 'left' ? <Avatar align={align} /> : null}
                <div className="udesk-coach-user-node-left">
                    <div className="udesk-coach-user-node-title">
                        <FormatDate startSecond={props.startSecond} />
                    </div>
                    {props.isTimeout ? (
                        <>
                            {props.content ? (
                                <ChatBubble
                                    content={props.content}
                                    ossKey={props.ossKey}
                                    align={align}
                                    style={{
                                        marginBottom: props.content ? 8 : 0,
                                    }}
                                />
                            ) : null}
                            <div className="udesk-coach-user-node-footer">
                                {/* 本题作答时间超时，自动提交作答内容 */}
                                {
                                    UdeskLocales['current'].components.coach.dialogue.components
                                        .knowledgeNode
                                        .theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted
                                }
                            </div>
                        </>
                    ) : (
                        <ChatBubble
                            content={props.content}
                            ossKey={props.ossKey}
                            align={align}
                            showCorrect={showCorrect && props.channel === 2}
                            recordId={recordId}
                            id={id}
                        />
                    )}
                </div>
                {align === 'right' ? <Avatar align={align} /> : null}
            </KnowledgeNodeWrap>
        );
    })
);
