import React, { useCallback, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Form, InputNumber } from 'udesk-ui';
import { EnumSelect } from 'Component/common/enum-component';

export default React.memo((props: any) => {
    const {
        value,
        onChanged, /* 传入的主回调函数，在组件内当值变化时调用 */
        //fields, /* 字段列表 */
        //fieldKey, /* 当前选择字段的key */
        //conditionIndex, /* 当前行的索引值，从0开始 */
        //className, /* 自定义className */
        //disabled, /* 是否禁用 */
        //runtimeAttributes, /* 从外部直接透传给自定义组件的props */
    } = props;
    const [form] = Form.useForm();
    const locales = Locales['current'];

    useEffect(() => {
        if (value) {
            form.setFieldsValue(value);
        } else {
            // 设置默认值
            onChanged({
                applyRole: 'all',
                applyConditionScope: 'current',
                applyConditionLimit: 0
            });
        }
    }, [form, onChanged, value]);

    const changeHandle = useCallback((value, values) => {
        onChanged(values);
    }, [onChanged]);

    return (
        <div>
            <Form form={form} layout="inline" onValuesChange={changeHandle}>
                <Form.Item
                    name="applyRole"
                    label={/* 检测角色 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.conditionPostfix.detectRoles}
                >
                    <EnumSelect bordered={false} enumKey="applyRoles" />
                </Form.Item>
                <Form.Item
                    name="applyConditionScope"
                    label={/* 检测范围 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.conditionPostfix.detectionRange}
                >
                    <EnumSelect bordered={false} enumKey="applyConditionScopes" />
                </Form.Item>
                {
                    [
                        Udesk.enums.applyConditionScopes.before.id,
                        Udesk.enums.applyConditionScopes.after.id,
                        Udesk.enums.applyConditionScopes.around.id
                    ].includes(value?.applyConditionScope) ? (
                            <>
                                <Form.Item
                                    name="applyConditionLimit"
                                    required
                                >
                                    <InputNumber size='small' min={0} max={9999} style={{ width: 70 }} />
                                </Form.Item>
                                <Form.Item colon={false} label={/* 句 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.conditionPostfix.sentence} />
                            </>
                        ) : null
                }

            </Form>
        </div>
    );
});
