import React, { FC } from 'react';
import useResizeObserver from 'use-resize-observer';
import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: #fff;
    padding: 0 16px 16px;

    .udesk-qa-web-item-wrapper-title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #d9d9d9;
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        font-weight: bold;
    }
`;

type ItemWraaperProps = {
    title: string;
};

const ItemWraaper: FC<ItemWraaperProps> = (props) => {
    const { ref } = useResizeObserver<HTMLDivElement>();

    return (
        <Wrapper ref={ref}>
            <div className="udesk-qa-web-item-wrapper-title">
                <div>{props.title}</div>
            </div>
            {props.children}
        </Wrapper>
    );
};

export default ItemWraaper;
