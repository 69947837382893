import React, { FC, memo, useState } from 'react';
import { useRequest } from 'ahooks';
import styled from 'styled-components';
import { Button, Input, Select, Space, message } from 'udesk-ui';
import { isEmpty } from 'src/util/core';
import { DataType } from './const';
import { getAiModelsGetUniqueRecordFieldByDataSourceType } from 'src/api/aiModels/getUniqueRecordField/{dataSourceType}';
import { postAiModelsTraining } from 'src/api/aiModels/training';
import { PostAiModelsTrainingBody } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    margin-bottom: 16px;
`;

const Item = styled.div`
    margin-top: 12px;
`;

type TemplateProps = {
    getValues?: () => any;
};

const Template: FC<TemplateProps> = (props) => {
    const { getValues } = props;

    const [isShow, setIsShow] = useState(false);

    const [dataType, setDataType] = useState(DataType.VOICE);

    const onDataTypeChange = (t: number) => {
        setDataType(t);
    };

    const [idLabel, setIdLabel] = useState('');

    useRequest(
        () =>
            getAiModelsGetUniqueRecordFieldByDataSourceType({
                segments: { dataSourceType: dataType },
            }),
        {
            refreshDeps: [dataType],
            onSuccess: (res) => {
                setIdLabel(/* 请输入 */UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.pleaseEnter + res.data?.label);
            },
        }
    );

    const [trainingDataId, setTrainingDataId] = useState();

    const onDialogChange = (id) => {
        setTrainingDataId(id);
    };

    const onTrainingShow = () => {
        setIsShow((s) => !s);
    };

    const [trainingResult, setTrainingResult] = useState('');
    const [hitResult, setHitResult] = useState('');

    const { run: train, loading } = useRequest(
        (params: PostAiModelsTrainingBody) => postAiModelsTraining(params),
        {
            manual: true,
            onSuccess: (res) => {
                setTrainingResult(res.data?.result || '');
                setHitResult(res.data?.talkHitting || '');
            },
        }
    );

    const onTrain = () => {
        const values = getValues?.();

        if (isEmpty(values?.prompt)) {
            message.warning(/* 请生成提示语 */UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.pleaseGeneratePrompt);
            return;
        }

        if (isEmpty(trainingDataId)) {
            message.warning(/* 请选择对话 */UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.pleaseSelectADialogue);
            return;
        }

        train({
            name: values.name,
            modelDesc: values.modelDesc || values.name,
            status: values.status,
            type: values.typeSelect?.type,
            divideRuleList: values.typeSelect?.divideRuleList,
            prompt: values.prompt,
            trainingDataSourceType: dataType,
            trainingDataId,
        });
    };

    return (
        <Wrapper>
            <Button onClick={() => onTrainingShow()}>{/* 训练 */}{UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.training}</Button>

            {isShow ? (
                <>
                    <Item>
                        <Space>
                            <div>{/* 数据类型： */}{UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.dataType}</div>
                            <Select
                                style={{ width: 200 }}
                                options={[
                                    {
                                        label: /* 语音通话 */UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.voiceCall,
                                        value: DataType.VOICE,
                                    },
                                    {
                                        label: /* 文本对话 */UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.textDialogue,
                                        value: DataType.TEXT,
                                    },
                                ]}
                                value={dataType}
                                onChange={(v) => onDataTypeChange(v)}
                            />
                            <Input
                                style={{ width: 200 }}
                                value={trainingDataId}
                                onChange={(e) => onDialogChange(e.target.value)}
                                placeholder={idLabel}
                            />
                            <Button
                                type="primary"
                                onClick={() => onTrain()}
                                loading={loading}
                                disabled={loading}
                            >{/* 生成 */}{UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.generate}</Button>
                        </Space>
                    </Item>

                    <Item>
                        <Space>
                            <div>{/* 质检结果： */}{UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.qualityInspectionResults}</div>
                            <Input.TextArea
                                style={{ width: 400 }}
                                readOnly
                                autoSize
                                value={trainingResult}
                            />
                        </Space>
                    </Item>

                    <Item>
                        <Space>
                            <div>{/* 命中话术： */}{UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.training.index.hit}</div>
                            <Input.TextArea
                                style={{ width: 400 }}
                                readOnly
                                autoSize
                                value={hitResult}
                            />
                        </Space>
                    </Item>
                </>
            ) : null}
        </Wrapper>
    );
};

export default memo(Template);
