import React from 'react';
import moment from 'moment';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
import _concat from 'lodash-es/concat';
import { getSystemModule } from 'Udesk/system/subApp';

class SpotCheckListComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {

    };
    //#endregion

    state = {
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: Udesk.config.paging.defaultPageSize,
        total: null,
        showMyOwn: false,
    };
    privates = {
        storages: {
            startTime: moment().add(-7, "day").format("YYYY-MM-DD 00:00:00"),
            endTime: moment().format("YYYY-MM-DD 23:59:59"),
            sampleStatus: null,
            selectSpotCheckValue: null,
            showType: null,
            userTwoValue: [],
            inspectorListValue: [],
            conditionList: [],
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            customJudgeLogic: '',

        },
        format: Udesk.config.date.momentDateFormat,
        serverFormat: Udesk.config.date.momentDateTimeFormat,
        spotCheckList: [],
        selectedItems: [],
        checkVisible: false,
        selectStatus: false,
        userOptions: [],
        selectValue: null,
        userStatusAgain: true,
        selectResAssignendValue: 2,
        autoComponentBlurFlag: false,
        data: [],
        inspectorList: [],
        changeColumns: null,
        tableRef: React.createRef(),
        loading: false,
        inspectionData: [],
        selectedRowKeys: [],
        filterVisible: false,
        type: null,
        customId: null,
        customName: '',

    };
    static computes = {
        _columns: ["privates.data", function ({ props, privates, locales }) {
            let { data } = privates;
            let showFieldList = data && data.showFieldList ? data.showFieldList : [];
            let columns = [];
            showFieldList.forEach(field => {
                let column = {};
                if (field.fieldName !== 'remark') {
                    column = {
                        dataIndex: field.id,
                        title: field.label,
                        key: field.id,
                        hasTemplate: true,
                        render: (name, items, index) => {
                            if (name) {
                                if (name.length > 20) {
                                    let text = name.substring(0, 20) + '...';
                                    return <div title={name}>{text}</div>;
                                } else {
                                    return <div title={name}>{name}</div>;
                                }
                            } else {
                                return name;
                            }
                        }
                    };
                    columns.push(column);
                }
            });
            if (showFieldList && !_isEmpty(showFieldList)) {
                let remarkObj = _find(showFieldList, current => current.fieldName === 'remark');
                if (remarkObj) {
                    let column = {
                        dataIndex: remarkObj.fieldName,
                        title: remarkObj.label,
                        key: remarkObj.id,
                        render: (name, item, index) => {
                            const content = item?.[remarkObj.id]??'';
                            if (content) {
                                if (content.length > 20) {
                                    let text = content.substring(0, 20) + '...';
                                    return <div title={content}>{text}</div>;
                                } else {
                                    return <div title={content}>{content}</div>;
                                }
                            } else {
                                return content;
                            }
                        }
                    };
                    columns = _concat(columns, column);
                }
            }
            return columns;
        }],
        _dataList: ["privates.data", function ({ props, privates, locales }) {
            let {
                data
            } = privates;
            let fieldValueMaps = [];

            if (data && data.fieldDataList && data.fieldDataList.length > 0) {
                data.fieldDataList.forEach(item => {
                    let fieldValueMap = item.fieldValueMap;
                    fieldValueMap.id = item.dataId;
                    fieldValueMaps.push(item.fieldValueMap);
                });
            }

            return fieldValueMaps;
        }],

    };
    enableStorage = true;
    storageStrategies = {
        storageWay: "memory",
        extraStorages: ["state.pageNum", "state.pageSize", "state.showMyOwn"],
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/tasks\/manage\/\d+\/workbench\/spot-check-list\/detail\/\d+/i,
                /^\/site\/wechat-qa\/task\/manager\/\d+\/spot-check-list\/detail\/\d+/i,
            ],
        },
    };

    actions = {
        dateChanged([startTime, endTime]) {
            this.privates.storages.startTime = startTime;
            this.privates.storages.endTime = endTime;
            this.privates.selectedItems = [];
            this.privates.selectedRowKeys = [];
            this.actions.update();
            this.actions.reloadAsyncModel();
        },
        getSamplingStatus(value) {
            this.privates.storages.sampleStatus = parseInt(value, 10);
            this.privates.selectedRowKeys = [];
            this.privates.selectedItems = [];
            this.actions.update();
            this.actions.reloadAsyncModel();
        },
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        pageSizeChange(current, pageSize) {
            this.actions.reloadAsyncModel({ pageSize, pageNum: Udesk.config.paging.defaultPageNumber, });
        },
        changeOnlyStatus() {
            let {
                showMyOwn
            } = this.state;
            this.setState({
                showMyOwn: !showMyOwn
            });
            this.actions.reloadAsyncModel({
                showMyOwn: !showMyOwn
            });
        },
        viewItemDetail(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: this.privates.isWechatQa ? 'wechatSpotCheckListDetail' : "spotCheckListDetail",
                pathParams: {
                    taskId: this.props.match.params.taskId,
                    id: item.id,
                },
                queryParams: {
                    inspectionId: item.af_0
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        onCheckChange(selectedRowKeys, selectedRows) {
            this.privates.selectedRowKeys = selectedRowKeys;
            this.privates.selectedItems = selectedRows;
            this.actions.update();
        },
        deletReordClick(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        deleteRecordCancel() { },
        deleteRecordOk() {
            this.actions.selectChangeDelete();
        },
        selecthandleChange(value) {
            if (value === "cancel") {
                this.privates.checkVisible = true;
                this.privates.selectValue = 'cancel';
                this.privates.selectStatus = false;
            } else if (value === "again") {
                this.privates.checkVisible = true;
                this.privates.userValue = [];
                this.privates.selectValue = 'again';
                this.privates.selectStatus = true;
            } else if (value === "delete") {
                // this.actions.selectChangeDelete();
            }
            this.actions.update();
        },
        onUserChanged(value) {
            this.privates.userValue = value;
            this.actions.update();
        },
        loadUserOptions(value) {
            let { sdkOptions } = this.props;
            let params = {
                pageSize: 1000,
            };
            if (value) {
                Object.assign(params, { keyword: value });
            }
            let url = Udesk.business.apiPath.concatApiPath(`users`, sdkOptions);
            return new Promise((resolve, reject) => {
                Udesk.ajax.get(url, params).then(
                    resp => {
                        resolve(resp.data.map(item => { return { id: item.id, name: item.realname }; }));
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        selectResAssignendChange(value) {
            this.privates.selectResAssignendValue = value;
            if (value === 2) {
                this.privates.userStatusAgain = true;
            } else {
                this.privates.userStatusAgain = false;
            }
            this.actions.update();
        },
        selectChangeCancel() {
            let { sdkOptions, match } = this.props;
            let { selectedItems } = this.privates;
            let ids = selectedItems ? selectedItems.map(item => { return item.id; }) : [];
            let taskId = match.params.taskId;
            let params = {
                ids
            };
            let url = Udesk.business.apiPath.concatApiPath(`sample/cancelAssigned?taskId=${taskId}`, sdkOptions);
            this.privates.loading = true;
            this.actions.update();
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    resp => {
                        Udesk.ui.notify.success(resp.message);
                        resolve(resp);
                        this.privates.checkVisible = false;
                        this.privates.selectedItems = [];
                        this.privates.selectedRowKeys = [];
                        this.privates.userStatusAgain = true;
                        this.privates.selectResAssignendValue = 2;
                        this.actions.reloadAsyncModel();
                        this.actions.update();
                    },
                    reason => {
                        this.privates.loading = false;
                        this.actions.update();            
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        selectChangeAgain() {
            let { sdkOptions, match } = this.props;
            let { selectedItems, selectResAssignendValue, userValue } = this.privates;
            let ids = selectedItems ? selectedItems.map(item => { return item.id; }) : [];
            let inspectorList = userValue && selectResAssignendValue === 2 ? userValue.map(item => { return item.id; }) : [];
            let taskId = match.params.taskId;
            let params = {
                ids,
                assignType: selectResAssignendValue,
                inspectorList
            };
            this.privates.loading = true;
            this.actions.update();
            let url = Udesk.business.apiPath.concatApiPath(`sample/reAssigned?taskId=${taskId}`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    resp => {
                        Udesk.ui.notify.success(resp.message);
                        resolve(resp);
                        this.privates.checkVisible = false;
                        this.privates.selectedItems = [];
                        this.privates.selectedRowKeys = [];
                        this.privates.userStatusAgain = true;
                        this.privates.selectResAssignendValue = 2;
                        this.actions.reloadAsyncModel();
                        this.actions.update();
                    },
                    reason => {
                        this.privates.loading = false;
                        this.actions.update();            
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        selectChangeDelete() {
            let { sdkOptions, match } = this.props;
            let { selectedItems } = this.privates;
            let ids = selectedItems ? selectedItems.map(item => { return item.id; }) : [];
            let taskId = match.params.taskId;
            let params = {
                ids,
            };
            this.privates.loading = true;
            this.actions.update();
            let url = Udesk.business.apiPath.concatApiPath(`sample/batchDelete?taskId=${taskId}`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    resp => {
                        Udesk.ui.notify.success(resp.message);
                        resolve(resp);
                        this.privates.selectedItems = [];
                        this.privates.selectedRowKeys = [];
                        this.actions.reloadAsyncModel();
                        this.actions.update();
                    },
                    reason => {
                        this.privates.loading = false;
                        this.actions.update();
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        changeFilterVisible() {
            this.privates.selectValue = this.locales.labels.batchActions;
            this.privates.checkVisible = false;
            this.privates.userStatusAgain = true;
            this.privates.selectResAssignendValue = 2;
            this.actions.update();
        },
        handleConditionFilter() {
            if (this.privates.selectValue === "cancel") {
                this.actions.selectChangeCancel();
            } else if (this.privates.selectValue === "again") {
                this.actions.selectChangeAgain();
            }
            this.privates.selectValue = this.locales.labels.batchActions;
            this.privates.checkVisible = false;
            this.actions.update();
        },
        selectTwoChange(value) {
            this.privates.storages.showType = value;
            this.privates.storages.selectSpotCheckValue = value;
            if (value === 5) {
                this.privates.storages.seeUserList = true;
                this.privates.storages.userTwoValue = [];
            } else {
                this.privates.storages.seeUserList = false;
                this.privates.storages.userTwoValue = [];
                this.privates.storages.inspectorListValue = [];
                this.actions.reloadAsyncModel();
            }
            this.privates.selectedItems = [];
            this.privates.selectedRowKeys = [];
            this.actions.update();
        },
        onUserListTwoChanged(value) {
            this.privates.storages.userTwoValue = value;
            this.actions.update();
        },
        autoComponentFocus() {
            this.privates.autoComponentBlurFlag = false;
            this.actions.update();
        },
        autoComponentBlur() {
            this.privates.autoComponentBlurFlag = true;
            this.privates.storages.inspectorListValue = this.privates.storages.userTwoValue.map(item => { return item.id; });
            this.actions.reloadAsyncModel();
            this.actions.update();
        },
        export() {

            let {
                sdkOptions,
                match
            } = this.props;
            let {
                tableRef
            } = this.privates;
            let { _columns } = this.privates.computes;
            let { showType, inspectorListValue } = this.privates.storages;
            let {
                // startTime,
                // endTime,
                conditionList,
                judgeStrategy,
                customJudgeLogic,    
                sampleStatus
            } = this.privates.storages;

            let showFieldList = [], exportTaskFieldCreateRequestList = [];
            let changeColumns = [];
            if (tableRef && tableRef.current) {
                if (_isEmpty(tableRef.current.privates.targetKeys)) {
                    changeColumns = [];
                } else {
                    let list = [];
                    // list = _columns.filter(current => tableRef.current.privates.targetKeys.includes(current.key));
                    tableRef.current.privates.targetKeys.forEach((key) => {
                        let currentColum = _columns.find(current => current.key === key);
                        if(currentColum){
                            list.push(currentColum);
                        }
                    });

                    changeColumns = list;
                }
                showFieldList = tableRef.current.privates.targetKeys;
            }
            // showFieldList = changeColumns.map(item => {
            //     return item.key;
            // });
            exportTaskFieldCreateRequestList = changeColumns.map(item => {
                return {
                    field: item.key,
                    label: item.title
                };
            });
            let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
            if (!showType) {
                if (hasFeature && hasFeature("task:work:sample:view")) {
                    showType = 1;
                } else if (hasFeature && hasFeature("task:work:sample:view:self")) {
                    showType = 2;
                } else if (hasFeature && hasFeature("task:work:sample:view:none")) {
                    showType = 3;
                }
            }
            let params = {
                // startTime,
                // endTime,
                conditionList,
                judgeStrategy,
                customJudgeLogic,    
                sampleStatus,
                showType,
                inspectorList: inspectorListValue,
                taskId: match.params.taskId,
                showFieldList,
                exportTaskFieldCreateRequestList,
                systemModule: getSystemModule(),
            };
            let postDataUrl = Udesk.business.apiPath.concatApiPath('exps/sampleData', sdkOptions);
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(postDataUrl, params)
                    .then(
                        (resp) => {
                            Udesk.ui.notify.success(this.locales.labels.exportSuccess);
                            resolve(resp);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || this.locales.labels.exportError);
                            reject(reason);
                        }
                    );
            });

        },
        onColumnsChange(value) {
            this.privates.changeColumns = value;
        },
        onPopoverVisible(e) {
            this.privates.tableRef.current.triggerColumnsControl(e);
        },
        //高级筛选器
        // 筛选弹框
        changeCusFilterVisible() {
            let { filterVisible } = this.privates;
            this.privates.filterVisible = !filterVisible;
            this.actions.update();
        },
        cancel() {
            this.privates.filterVisible = false;
            this.actions.update();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy, id, name) {
            this.privates.storages.conditionList = conditionList;
            this.privates.storages.customJudgeLogic = customJudgeLogic;
            this.privates.storages.judgeStrategy = judgeStrategy;
            this.privates.customName = name;
            this.privates.customId = id;

            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
                conditionList: conditionList,
                judgeStrategy,
                customJudgeLogic,
            };
            this.privates.filterVisible = false;
            this.actions.reloadAsyncModel(params);
        },
        onConditionFilterSaved(flag, data){
            if(data){
                this.privates.customId = data.id;
            }
        },

    };

    //#region Life Cycle
    componentDidMount() {
        if (!this.privates.storages.selectSpotCheckValue) {
            this.privates.storages.selectSpotCheckValue = this.locales.components.pages.spotCheckList.plInspector;
        }
    }
    init() {
        let { sdkOptions } = this.props;
        let type = Udesk.business.task.formatInspectDataSource(sdkOptions.props.task.inspectDataSource);
        if(type === Udesk.enums.inspectDataSources.wechatDialogue.id){
            this.privates.isWechatQa = true;
        }
        this.privates.type = type;

        //初始化筛选条件
        let searchTemplate =  this.props.location.state || sdkOptions.props.defaultSearchTemplate.find(template => (template.templateType === sdkOptions.props.task.inspectDataSource));
        this.privates.storages.conditionList = searchTemplate.conditionList;
        this.privates.storages.customJudgeLogic = searchTemplate.customJudgeLogic;
        this.privates.storages.judgeStrategy = searchTemplate.judgeStrategy;
        this.actions.update();
    }
    asyncModel(changedParams) {
        let that = this;
        let {
            sdkOptions,
            match
        } = this.props;
        let {
            pageSize,
            pageNum,
        } = this.state;
        let { showType, inspectorListValue } = this.privates.storages;
        let {
            // startTime,
            // endTime,
            conditionList,
            judgeStrategy,
            customJudgeLogic,
            sampleStatus
        } = this.privates.storages;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        if (!showType) {
            if (hasFeature && hasFeature("task:work:sample:view")) {
                showType = 1;
            } else if (hasFeature && hasFeature("task:work:sample:view:self")) {
                showType = 2;
            } else if (hasFeature && hasFeature("task:work:sample:view:none")) {
                showType = 3;
            }
        }
        let params = {
            pageNum,
            pageSize,
            // startTime,
            // endTime,
            conditionList,
            judgeStrategy,
            customJudgeLogic,
            sampleStatus,
            showType,
            inspectorList: inspectorListValue
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }

        params.taskId = match.params.taskId;

        let postDataUrl = Udesk.business.apiPath.concatApiPath('sample/list-v1', sdkOptions);
        that.privates.loading = true;
        that.actions.update();
        let inspectionData = new Promise((resolve, reject) => {
            Udesk.ajax.post(postDataUrl, params)
                .then(
                    function (resp) {
                        resolve(resp);
                        that.privates.data = resp.data;
                        that.privates.loading = false;
                        that.actions.update();
                        if (resp.paging) {
                            that.setState({
                                pageSize: resp.paging.pageSize,
                                pageNum: resp.paging.pageNum,
                                total: resp.paging.total
                            });
                        }
                    },
                    function (reason) {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(that.locales.business.notifyMessage.getInformationFailedFormat, that.locales.components.pages.qualityCheckingTask.index.checkingTaskList));
                        that.privates.loading = false;
                        that.actions.update();
                        reject(reason);
                    }
                );
        });
        return { inspectionData };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'inspectionData') {
            if (asyncResult && asyncResult.data) {
                this.privates.data = asyncResult.data;
                this.actions.update();
            }
        }
    }
}

export default SpotCheckListComponent;