import React, { FC, useMemo } from 'react';
import { useChart } from './hooks';
import { Dropdown, Menu, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

type TemplateProps = {
    chartType?: string;
    onTypeChange?: (t: string) => void;
    chartDisable?: boolean;
    pieChartDisabled?: boolean;
};

const Template: FC<TemplateProps> = (props) => {
    const { chartType, onTypeChange, chartDisable = false, pieChartDisabled = false } = props;

    const { chartTypeList } = useChart({ chartDisable, pieChartDisabled });

    const onInnerTypeChange = (type: string) => {
        onTypeChange?.(type);
    };

    const curIcon = useMemo(() => {
        return (
            <span title={/* 切换类型 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.components.chartToggle.index.switchingTypes} style={{ cursor: 'pointer' }}>
                {chartTypeList.find((c) => c.type === chartType)?.icon}
            </span>
        );
    }, [chartType, chartTypeList]);

    return (
        <Dropdown
            overlay={
                <Menu>
                    {chartTypeList.map((ch) => {
                        return (
                            <Menu.Item
                                onClick={() => onInnerTypeChange(ch.type)}
                                disabled={ch.disabled}
                            >
                                <Space size="small">
                                    {ch.icon}
                                    {ch.name}
                                </Space>
                            </Menu.Item>
                        );
                    })}
                </Menu>
            }
        >
            {curIcon}
        </Dropdown>
    );
};

export default Template;
