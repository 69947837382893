// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostIntelligentPartnerHomePageStudentPictureFinishStatusBody,
    BaseResponseListIntelligentPartnerHomePageStudentPictureFinishStatusResponse,
} from '../../../../../types';

/**
 * 员工画像-完成情况
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [POST] /intelligentPartner/homePage/student/picture/finishStatus
 */
export function postIntelligentPartnerHomePageStudentPictureFinishStatus(
    data: PostIntelligentPartnerHomePageStudentPictureFinishStatusBody
): Promise<BaseResponseListIntelligentPartnerHomePageStudentPictureFinishStatusResponse>;
export function postIntelligentPartnerHomePageStudentPictureFinishStatus(
    data: PostIntelligentPartnerHomePageStudentPictureFinishStatusBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerHomePageStudentPictureFinishStatusResponse>;
export function postIntelligentPartnerHomePageStudentPictureFinishStatus(
    data: PostIntelligentPartnerHomePageStudentPictureFinishStatusBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerHomePageStudentPictureFinishStatusResponse> {
    return request<PostIntelligentPartnerHomePageStudentPictureFinishStatusBody, never, never>(
        '/intelligentPartner/homePage/student/picture/finishStatus',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerHomePageStudentPictureFinishStatus',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/student/picture/finishStatus',
        method: 'post',
    },
];
