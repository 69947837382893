// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type { BaseResponseListIntelligentPartnerPromptWordClassify } from '../../../../../../types';

/**
 * 查询所有提示字段
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [GET] /intelligentPartner/ai/analysis/prompt/word/findAll
 */
export function getIntelligentPartnerAiAnalysisPromptWordFindAll(): Promise<BaseResponseListIntelligentPartnerPromptWordClassify>;
export function getIntelligentPartnerAiAnalysisPromptWordFindAll(
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerPromptWordClassify>;
export function getIntelligentPartnerAiAnalysisPromptWordFindAll(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerPromptWordClassify> {
    return request<never, never, never>('/intelligentPartner/ai/analysis/prompt/word/findAll', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerAiAnalysisPromptWordFindAll',
    });
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/prompt/word/findAll',
        method: 'get',
    },
];
