import React, { useState, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { Button, PageHeader, Space } from 'udesk-ui';
import Ranking from './ranking';
import { useLiveEffect, useRequest } from 'src/util/hook';

import './index.scss'; 

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};
const goBack = () => window.history.back();

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const {
        match ,        
        selectedRowKey, 
        setSelectedRowKey, 
    } = props;
    const [cluster, setCluster] = useState<any>();
    const [tableData, setTableData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<StandardizeTableProps['table']['pagination']>(defaultPagination);
    const request = useRequest();

    const viewHandle = useCallback(id => {
        setSelectedRowKey(id);
    }, [
        setSelectedRowKey
    ]);

    const ajaxTableData=  useCallback((id, map) => {
        setLoading(true);
        return request(`review/cluster/clusterResult/${id}`, map).then((resp) => {
            const { pageNum, pageSize } = map;
            const pagination = {
                total: resp.paging?.total,
                current: resp.paging?.pageNum || pageNum,
                pageSize: resp.paging?.pageSize || pageSize,
            };
            const list = resp.data ?? [];
            list.forEach(item => item.key = item.id);
            if (list.length > 0) {
                if (selectedRowKey && list.some(item => item.id === selectedRowKey)) {
                    viewHandle(selectedRowKey);
                } else {
                    viewHandle(list[0].id);
                }
            }
            setTableData(list);
            setPagination(pagination);
        }).finally(() => {
            setLoading(false);
        });
    }, [
        selectedRowKey,
        viewHandle,
        request
    ]);

    const onRequest = useCallback((params) => {
        const {pagination}  = params;
        if (match.params.id) {
            ajaxTableData(
                match.params.id, 
                {
                    pageNum: pagination.current,
                    pageSize: pagination.pageSize,
                }
            );
        }
    }, [
        ajaxTableData, 
        match.params.id
    ]);

    useLiveEffect((success) => {
        if (match.params.id) {
            request(`/review/cluster/${match.params.id}`).then((resp) => {
                success(() => setCluster(resp.data));
            });
        }
    }, [
        match.params.id
    ]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 主题 */locales.pages.semanticIntelligence.clusteringAnalysis.detail.index.theme,
                key: 'question',
                dataIndex: 'question',
                render: (question, record) => {
                    return (
                        <div style={{cursor: 'pointer'}} onClick={() => viewHandle(record.id)}>
                            <div>{question}</div>
                            {
                                record.hotWordsList ? (
                                    <Space>
                                        {
                                            record.hotWordsList.map((word) => {
                                                return <Button type="link">{word}</Button>;
                                            })
                                        }
                                    </Space>
                                ) : null
                            }

                        </div>
                    );
                },
            },
            {
                title: /* 语料数 */locales.pages.semanticIntelligence.clusteringAnalysis.detail.index.numberOfCorpora,
                key: 'questionCount',
                dataIndex: 'questionCount',
                defaultSortOrder: 'descend',
                width:100,
                sorter: (a, b) => {
                    return a.questionCount - b.questionCount;
                },
            },
            {
                title: /* 语料覆盖 */locales.pages.semanticIntelligence.clusteringAnalysis.detail.index.corpusCoverage,
                key: 'questionRate',
                dataIndex: 'questionRate',
                defaultSortOrder: 'descend',
                width:100,
                sorter: (a, b) => a.questionRate - b.questionRate,
                render: (questionRate) => `${questionRate}%`,
            },
        ];
        return {
            loading,
            columns,
            dataSource: tableData,
            pagination,
            rowSelection: {
                selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                type: 'radio',
            },
        };
    }, [
        locales,
        loading, 
        tableData, 
        pagination, 
        viewHandle,
        selectedRowKey
    ]);

    return (
        <div className='udesk-qa-web-page'>
            <PageHeader
                className='udesk-qa-web-page-header'
                title={cluster?.name}
                onBack={goBack}
            />
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root clustering-analysis-detail'>
                    <div>
                        <div className='detail-title'>{/* 聚类详情 */}{locales.pages.semanticIntelligence.clusteringAnalysis.detail.index.clusterDetails}</div>
                        <StandardizeTable.RequestTable
                            onRequest={onRequest}
                            table={tableProps}
                            filter={{
                                fields: []
                            }}
                        />
                    </div>
                    <Ranking request={request} subjectId={selectedRowKey}/>
                </div>
            </div>
        </div>
    );
});

class Component extends React.Component {
    privates = {
        storages: {
            selectedRowKey: null as number | null,
        },
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'sessionStorage',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/semantic-intelligence\/clustering-analysis\/detail\/*/i,
            ],
        },
    };
    actions = {
        saveStorages(changedParams: any) {
            (this as any).privates.storages = { ...(this as any).privates.storages, ...changedParams };
        },
        setSelectedRowKey (selectedRowKey) {
            (this as any).actions.saveStorages({ selectedRowKey });
            (this as any).actions.update();
        }
    };
    render() {
        return <Template 
            {...this.props} 
            selectedRowKey={this.privates.storages.selectedRowKey}
            setSelectedRowKey={this.actions.setSelectedRowKey}  
        />;
    }
}
export default Udesk.react.udeskify(Component);
