import React from 'react';
import Udesk from 'Udesk';
class ReInspectionModelComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {
        type: '',
    };
    //#endregion

    state = {

    };

    actions = {
        allPointsChecked(event) {
            this.privates.allPoints = event.target.checked;
            selectAllPonints(this, event.target.checked);
            this.trigger("onPointsCheckedChange", this.privates.reInspectionPoints, this.privates.allPoints);
        },
        pointChecked(point, event) {
            point._checked = event.target.checked;
            recalcAllPointsChecked(this);
            this.trigger("onPointsCheckedChange", this.privates.reInspectionPoints, this.privates.allPoints);
        },
        reInspectionTypeChanged(event) {
            this.privates.reInspectionType = event.target.value;
            this.trigger("reInspectionTypeChanged", this.privates.reInspectionType);
            this.actions.update();
        },
    };
    parseProps({ props, prevProps, state, privates, isInitial }) {
        let newPrivates = {
            allPoints: props.allPoints,
            reInspectionPoints: props.reInspectionPoints,
            reInspectionTypes: Udesk.enums.reInspectionTypes,
        };
        if(props.type === 'test-set-list'){
            newPrivates.reInspectionTypes = Udesk.enums.reInspectionTypes.filter(type => type.id === Udesk.enums.reInspectionTypes.recheckPoint.id);
        }
        return newPrivates;
    }
}

export default ReInspectionModelComponent;

function recalcAllPointsChecked(that) {
    let { reInspectionPoints } = that.privates;
    that.privates.allPoints = true;
    for (let i = 0; i < reInspectionPoints.length; i++) {
        let firstCategory = reInspectionPoints[i];
        for (let j = 0; j < firstCategory.children.length; j++) {
            let secondCategory = firstCategory.children[j];
            for (let index = 0; index < secondCategory.points.length; index++) {
                if (!secondCategory.points[index]._checked) {
                    that.privates.allPoints = false;
                    return;
                }
            }
        }
    }
}
function selectAllPonints(that, checked) {
    let { reInspectionPoints } = that.privates;
    for (let i = 0; i < reInspectionPoints.length; i++) {
        let firstCategory = reInspectionPoints[i];
        for (let j = 0; j < firstCategory.children.length; j++) {
            let secondCategory = firstCategory.children[j];
            for (let index = 0; index < secondCategory.points.length; index++) {
                secondCategory.points[index]._checked = checked;
            }
        }
    }
}