// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostBusinessAnalysisRankBody,
    BaseResponseBusinessAnalysisRankFoundResponseDto,
} from '../../types';

/**
 * rank
 *
 * @export
 * @tags business-analysis-controller
 * @link [POST] /businessAnalysis/rank
 */
export function postBusinessAnalysisRank(
    data: PostBusinessAnalysisRankBody
): Promise<BaseResponseBusinessAnalysisRankFoundResponseDto>;
export function postBusinessAnalysisRank(
    data: PostBusinessAnalysisRankBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisRankFoundResponseDto>;
export function postBusinessAnalysisRank(
    data: PostBusinessAnalysisRankBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisRankFoundResponseDto> {
    return request<PostBusinessAnalysisRankBody, never, never>('/businessAnalysis/rank', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postBusinessAnalysisRank',
    });
}

export const meta = [
    { tags: ['business-analysis-controller'], path: '/businessAnalysis/rank', method: 'post' },
];
