// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type { PostWecomSmartTagsBody, BaseResponseWecomSmartTagFoundResponse } from '../types';

/**
 * save
 *
 * @export
 * @tags wecom-smart-tag-controller
 * @link [POST] /wecomSmartTags
 */
export function postWecomSmartTags(
    data: PostWecomSmartTagsBody
): Promise<BaseResponseWecomSmartTagFoundResponse>;
export function postWecomSmartTags(
    data: PostWecomSmartTagsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseWecomSmartTagFoundResponse>;
export function postWecomSmartTags(
    data: PostWecomSmartTagsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseWecomSmartTagFoundResponse> {
    return request<PostWecomSmartTagsBody, never, never>('/wecomSmartTags', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postWecomSmartTags',
    });
}

export const meta = [
    { tags: ['wecom-smart-tag-controller'], path: '/wecomSmartTags', method: 'post' },
];
