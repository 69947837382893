// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetOperatorLogsFindReviewAnalysisQuery,
    BaseResponseListOperatorLog,
} from '../../types';

/**
 * findReviewAnalysis
 *
 * @export
 * @tags operator-log-controller
 * @link [GET] /operatorLogs/findReviewAnalysis
 */
export function getOperatorLogsFindReviewAnalysis(): Promise<BaseResponseListOperatorLog>;
export function getOperatorLogsFindReviewAnalysis(
    options: ApiOptions<never, GetOperatorLogsFindReviewAnalysisQuery>
): Promise<BaseResponseListOperatorLog>;
export function getOperatorLogsFindReviewAnalysis(
    options?: ApiOptions<never, GetOperatorLogsFindReviewAnalysisQuery>
): Promise<BaseResponseListOperatorLog> {
    return request<never, never, GetOperatorLogsFindReviewAnalysisQuery>(
        '/operatorLogs/findReviewAnalysis',
        { ...options, method: 'get', __$requestCalleeName: 'getOperatorLogsFindReviewAnalysis' }
    );
}

export const meta = [
    { tags: ['operator-log-controller'], path: '/operatorLogs/findReviewAnalysis', method: 'get' },
];
