import SetIntervalClass from "./set-interval-class";
import storage from "./storage";
import EventedClass from '../../../evented-class';

const SESSION_VERSION_MONITOR_TIMER_SYMBOL = Symbol(`session-version-moniton-timer-symbol`);
const OLD_VERSION_SYMBOL = Symbol(`old-version-symbel`);
const SESSION_VERSION_MONITOR_OPTIONS_SYMBOL = Symbol(`session-version-monitor-options-symbol`);
const SESSION_VERSION_MONITOR_START_SYMBOL = Symbol(`session-version-monitor-start-symbol`);
let DEFAULT_OPTIONS = {
    intervalSeconds: 10
};


class SessionVersionMonitorClass extends EventedClass {
    constructor(options) {
        super();
        if (options == null) { 
            options = {};
        }
        this[SESSION_VERSION_MONITOR_OPTIONS_SYMBOL] = Object.assign({}, DEFAULT_OPTIONS, options);
        this[SESSION_VERSION_MONITOR_TIMER_SYMBOL] = new SetIntervalClass();
        this[OLD_VERSION_SYMBOL] = null;
        this[SESSION_VERSION_MONITOR_START_SYMBOL]();
    }

    [SESSION_VERSION_MONITOR_START_SYMBOL]() {
        if (this[SESSION_VERSION_MONITOR_TIMER_SYMBOL]) {
            this[SESSION_VERSION_MONITOR_TIMER_SYMBOL].clear();
            let that = this;
            this[SESSION_VERSION_MONITOR_TIMER_SYMBOL].setInterval(() => {
                let version = storage.get("version");
                if (version != null && typeof version === "string") {
                    let oldVersion = that[OLD_VERSION_SYMBOL];
                    if (oldVersion != null && version !== oldVersion) {
                        that.trigger("onVersionChanged", [version, oldVersion, storage.get("isVersionRollback")]);
                    }
                    that[OLD_VERSION_SYMBOL] = version;
                }
            }, this[SESSION_VERSION_MONITOR_OPTIONS_SYMBOL].intervalSeconds);
        }
    }
    destroy() { 
        this[SESSION_VERSION_MONITOR_OPTIONS_SYMBOL] = null;
        if (this[SESSION_VERSION_MONITOR_TIMER_SYMBOL]) { 
            this[SESSION_VERSION_MONITOR_TIMER_SYMBOL].clear();
            this[SESSION_VERSION_MONITOR_TIMER_SYMBOL] = null;
        }
        this[OLD_VERSION_SYMBOL] = null;
    }
}

export default SessionVersionMonitorClass;