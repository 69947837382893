// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    BaseResponseList知识点分类相应结果,
    PostKnowledgeBaseBody,
    BaseResponse知识点分类相应结果,
} from '../types';

/**
 * 知识库分类列表
 *
 * @export
 * @tags 知识库分类接口
 * @link [GET] /knowledgeBase
 */
export function getKnowledgeBase(): Promise<BaseResponseList知识点分类相应结果>;
export function getKnowledgeBase(
    options: ApiOptions<never, never>
): Promise<BaseResponseList知识点分类相应结果>;
export function getKnowledgeBase(
    options?: ApiOptions<never, never>
): Promise<BaseResponseList知识点分类相应结果> {
    return request<never, never, never>('/knowledgeBase', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getKnowledgeBase',
    });
}

/**
 * 更新/保存知识库分类
 *
 * @export
 * @tags 知识库分类接口
 * @link [POST] /knowledgeBase
 */
export function postKnowledgeBase(
    data: PostKnowledgeBaseBody
): Promise<BaseResponse知识点分类相应结果>;
export function postKnowledgeBase(
    data: PostKnowledgeBaseBody,
    options: ApiOptions<never, never>
): Promise<BaseResponse知识点分类相应结果>;
export function postKnowledgeBase(
    data: PostKnowledgeBaseBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponse知识点分类相应结果> {
    return request<PostKnowledgeBaseBody, never, never>('/knowledgeBase', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postKnowledgeBase',
    });
}

export const meta = [
    { tags: ['知识库分类接口'], path: '/knowledgeBase', method: 'get' },
    { tags: ['知识库分类接口'], path: '/knowledgeBase', method: 'post' },
];
