import { useEffect } from 'react';
import { useStorage } from '../../../utils';
var MAX_LIMIT = 50;
var useMemoScrollPosition = function useMemoScrollPosition(_ref) {
  var cacheId = _ref.cacheId,
    tableBodyPrefixCls = _ref.tableBodyPrefixCls,
    wrapRef = _ref.wrapRef,
    dataSource = _ref.dataSource,
    columns = _ref.columns;
  var storage = useStorage(cacheId, 'session');
  var limitCount = 0;
  var tryToSavePrevScrollPosition = function tryToSavePrevScrollPosition() {
    var _wrapRef$current;
    var dom = (_wrapRef$current = wrapRef.current) === null || _wrapRef$current === void 0 ? void 0 : _wrapRef$current.querySelector(".".concat(tableBodyPrefixCls));
    var scrollTop = dom === null || dom === void 0 ? void 0 : dom.scrollTop;
    var scrollLeft = dom === null || dom === void 0 ? void 0 : dom.scrollLeft;
    storage.save({
      scrollTop: scrollTop,
      scrollLeft: scrollLeft
    });
  };
  var tryToResetPrevScrollPosition = function tryToResetPrevScrollPosition(x, y) {
    var _wrapRef$current2;
    var dom = (_wrapRef$current2 = wrapRef.current) === null || _wrapRef$current2 === void 0 ? void 0 : _wrapRef$current2.querySelector(".".concat(tableBodyPrefixCls));
    if (!dom) {
      limitCount++;
      if (limitCount < MAX_LIMIT) {
        requestAnimationFrame(tryToResetPrevScrollPosition.bind(null, x, y));
      } else {
        limitCount = 0;
      }
    } else {
      limitCount = 0;
      dom.scrollTo(x, y);
    }
  };
  useEffect(function () {
    // ant-table-body
    var storagesValue = storage.read();
    if (storagesValue && (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length)) {
      requestAnimationFrame(tryToResetPrevScrollPosition.bind(null, storagesValue.scrollLeft, storagesValue.scrollTop));
    }
  }, [columns, dataSource]);
  return {
    tryToSavePrevScrollPosition: tryToSavePrevScrollPosition,
    tryToResetPrevScrollPosition: tryToResetPrevScrollPosition,
    storage: storage
  };
};
export default useMemoScrollPosition;