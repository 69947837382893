// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetLargeModelConfigsQuery,
    BaseResponseListLargeModelConfigFoundResponse,
    PostLargeModelConfigsBody,
    BaseResponseVoid,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags large-model-config-controller
 * @link [GET] /largeModelConfigs
 */
export function getLargeModelConfigs(): Promise<BaseResponseListLargeModelConfigFoundResponse>;
export function getLargeModelConfigs(
    options: ApiOptions<never, GetLargeModelConfigsQuery>
): Promise<BaseResponseListLargeModelConfigFoundResponse>;
export function getLargeModelConfigs(
    options?: ApiOptions<never, GetLargeModelConfigsQuery>
): Promise<BaseResponseListLargeModelConfigFoundResponse> {
    return request<never, never, GetLargeModelConfigsQuery>('/largeModelConfigs', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getLargeModelConfigs',
    });
}

/**
 * save
 *
 * @export
 * @tags large-model-config-controller
 * @link [POST] /largeModelConfigs
 */
export function postLargeModelConfigs(data: PostLargeModelConfigsBody): Promise<BaseResponseVoid>;
export function postLargeModelConfigs(
    data: PostLargeModelConfigsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postLargeModelConfigs(
    data: PostLargeModelConfigsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostLargeModelConfigsBody, never, never>('/largeModelConfigs', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postLargeModelConfigs',
    });
}

export const meta = [
    { tags: ['large-model-config-controller'], path: '/largeModelConfigs', method: 'get' },
    { tags: ['large-model-config-controller'], path: '/largeModelConfigs', method: 'post' },
];
