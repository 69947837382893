import Udesk from 'Udesk';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { getReviewIntelligentPartnerNodeInputSceneConfig } from 'src/api/review/intelligentPartner/node/inputSceneConfig';
import { getReviewIntelligentPartnerDialogFlow } from 'src/api/review/intelligentPartner/dialogFlow';
import { getIntelligentPartnerCustomerPersonas } from 'src/api/intelligentPartnerCustomerPersonas';
import { IntelligentPartnerCustomerPersonaFoundResponse } from 'src/api/types';

type UseAiGenProps = {
    courseId?: string | number | null;
};

// 根据ai生成的课程id，获取课程配置
export const useAiGen = (props: UseAiGenProps) => {
    const { courseId } = props;

    const [courseType, setCourseType] = useState<number>();
    const [aiImage, setAiImage] = useState<IntelligentPartnerCustomerPersonaFoundResponse>({});
    const [portraitList, setPortraitList] = useState<
        IntelligentPartnerCustomerPersonaFoundResponse[]
    >([]);
    const [aiGenSceneNodeId, setAiGenSceneNodeId] = useState<number>();

    const getImage = useCallback(async (nodeId, list) => {
        const res2 = await getReviewIntelligentPartnerNodeInputSceneConfig({
            params: {
                nodeId,
            },
        });

        setAiImage(list?.find?.((p) => p.id === res2.data?.simulatedRole) || {});
    }, []);

    const init = useCallback(async () => {
        if (!courseId) return;

        const [info, res] = await Promise.all([
            getReviewIntelligentPartnerDialogFlow({
                params: {
                    courseId: Number(courseId),
                },
            }),
            getIntelligentPartnerCustomerPersonas({
                params: {
                    courseId,
                },
            } as any),
        ]);

        const _courseType = info.data?.courseType;
        setCourseType(_courseType);
        setPortraitList(res.data || []);

        if (_courseType === Udesk.enums.lessonType.aiGen.id) {
            const aiGenSceneNodeId = info.data?.nodeList?.find(
                (node) => node.nodeType === Udesk.enums.nodeTypes.aiGenSceneNode.id
            )?.id;
            if (aiGenSceneNodeId) {
                getImage(aiGenSceneNodeId, res.data || []);
                setAiGenSceneNodeId(aiGenSceneNodeId);
            }
        }
    }, [courseId, getImage]);

    useEffect(() => {
        init();
    }, [init]);

    const isAiGenCourse = useMemo(() => {
        return courseType === Udesk.enums.lessonType.aiGen.id;
    }, [courseType]);

    return {
        courseType,
        isAiGenCourse,
        aiImage,
        portraitList,
        aiGenSceneNodeId,
    };
};
