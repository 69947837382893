import React, { useState, useCallback, useMemo } from 'react';
import Udesk from 'Udesk';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { Button } from 'udesk-ui';
import CustomFilterModal from './customerFilterModal';
import CustomFilterBtn from 'Component/pages/components/custom-filter-button';
import ConditionFieldComponent from './conditionPostfix';
import { getSystemModule } from 'Udesk/system/subApp';
import '../index.scss';

const type = 4;
export default React.memo((props: any) => {
    const {
        locales,
        selectedRowKey,
        judgeStrategy,
        conditionList,
        customJudgeLogic,
        setJudgeStrategy,
        setConditionList,
        setCustomJudgeLogic,
        onFilterComplete,
    }= props;
    const [filterVisible, setFilterVisible] = useState(false);
    const [customId, setCustomId] = useState<number>();
    const [customName, setCustomName] = useState();
    const [isFiltering, setIsFilterIng] = useState(false);
    const [count, setCount] = useState(0);

    const request = useRequest();
    const formatAssociate = useMemo(
        () => {
            const map = [
                { 
                    format: 'Is({0}, {1})', 
                    operatorValue: 'is', 
                },
                {
                    format: 'not Is({0}, {1})',
                    operatorValue: 'not',
                },
                {
                    format: '{0} > {1}',
                    operatorValue: 'greater_than',
                },
                { 
                    format: '{0} < {1}', 
                    operatorValue: 'less_than', 
                },
                {
                    format: 'Keyword({0}, {1})',
                    operatorValue: 'contains',
                },
                {
                    format: 'IsEmpty({0})',
                    operatorValue: 'is_null',
                },
                {
                    format: 'not IsEmpty({0})',
                    operatorValue: 'is_not_null',
                },
            ];

            return (condition) => {
                if (!['f_107', 'f_108', 'f_109', 'f_110'].includes(condition.field.id)) {
                    map.find(operator => {
                        const flag = operator.operatorValue === condition.operator;
                        let inputContent = operator.format;
                        if (flag) {
                            [
                                `[${condition.field.label}]`,
                                `${condition.value}`
                            ].forEach((value, index) => {
                                inputContent = inputContent.replace(`{${index}}`, value);
                            });
                        }
                        condition.postfix = {
                            param: {
                                type: Udesk.enums.operatorTypes.associate.id,
                                inputContent,
                                version: 1
                            },
                        };
                        return flag;
                    });
                }
            };
        },
        []
    );

    const changeCusFilterVisible = useCallback(() => {
        setFilterVisible(true);
    }, []);

    const cancelHandle = useCallback(() => {
        setFilterVisible(false);
    }, []);

    const handleConditionApply = (conditionList, customJudgeLogic, judgeStrategy, id, name) => {
        setJudgeStrategy(judgeStrategy);
        setConditionList(conditionList);
        setCustomJudgeLogic(customJudgeLogic, true);
        setCustomId(id);
        setCustomName(name);

        let params = {
            subjectId: selectedRowKey,
            conditionList,
            judgeStrategy,
            customJudgeLogic,
            systemModule: getSystemModule(),
            // 这个参数后端要求的， 暂时先这样
            // template: {
            //     id,
            //     name,
            //     subjectId: selectedRowKey,
            //     templateType: type,
            //     conditionList,
            //     judgeStrategy,
            //     customJudgeLogic,
            // }
        };
        conditionList.forEach(formatAssociate);
        request('review/words/filter/search', params, 'post').then(() => {
            cancelHandle();
            setCount(count => count + 1);
        });
    };

    const onConditionFilterSaved = (flag, data) => {
        if (data) {
            setCustomId(data.id);
            setCount(count => count + 1);
        }
    };

    const operatorsFilter = (operators, fieldId) =>{
        const newOperators = [
            Udesk.enums.fieldOperators.less_than,
            Udesk.enums.fieldOperators.greater_than
        ];
        if (['f_107'].includes(fieldId)) {
            return operators.filter(item => item.id === Udesk.enums.fieldOperators.is.id);
        }
        if (!['f_108', 'f_109', 'f_110'].includes(fieldId)) {
            newOperators.forEach(operator => {
                if (!operators.some(item => item.id === operator.id)) {
                    operators.push(operator);
                }
            });
        }
        return operators;
    };

    const fieldAjax = useCallback(() => {
        return new Promise((resolve, reject) => {
            request('field/reviewData/search-template', {
                sourceList: [1]
            }, 'post').then(
                resp => resolve(resp),
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || locales.components.pages.components.listManage.getRecordsFailed
                    );
                    reject(reason);
                }
            );
        });
    }, [
        locales, 
        request
    ]);

    const customAjax = useCallback(() => {
        return new Promise((resolve, reject) => {
            request('search-template/advanced', {
                type,
                subjectId : selectedRowKey
            }, 'post').then(
                resp => resolve(resp),
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || locales.components.pages.components.listManage.getRecordsFailed
                    );
                    reject(reason);
                }
            );
        });
    }, [
        selectedRowKey,
        locales, 
        request
    ]);

    const customSaveAjax = useCallback((data, method) => {
        data.subjectId = selectedRowKey;
        return request('search-template/wordsFilter/save', data, method);
    }, [
        selectedRowKey,
        request
    ]);

    /**
     * 扩展组件是否显示在新的一行
     * @param position 扩展字段显示的位置信息 0：同一行末尾， 1：下一行， 此处只做函数调用的位置标记，并不决定显示不显示
     * @param data 当前行的数据
     * @returns true：显示， false：不显示，当不存在此函数且存在扩展字段组件时将默认显示在同一行末尾
     */
    const postfixComponentRenderInLine = (position, data) => {
        let isRender = false;
        if (position) {
            isRender = ['f_107', 'f_108', 'f_109', 'f_110'].includes(data?.field);
            if (!isRender) {
                delete data.postfix;
            }
        }
        return isRender;
    };

    const requestFilterStatus = useCallback((subject, callback) => {
        return request(`review/words/filter/status/${subject}`).then(
            callback
        );
    }, [request]);

    const onCannel = useCallback(() => {
        request(`/review/words/filter/cancel/${selectedRowKey}`, {}, 'put').then(
            () => {
                setCount(count => count + 1);
            }, 
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        request, 
        selectedRowKey
    ]);

    const filterButton = useMemo(() => {
        if (isFiltering) {
            return <Button type='primary' disabled>{/* 话术过滤中 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.customerFilter.scriptFiltering}</Button>;
        }
        return <Button type='primary' onClick={changeCusFilterVisible}>{/* 话术过滤 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.customerFilter.scriptFiltering}</Button>;
    }, [
        locales,
        isFiltering,
        changeCusFilterVisible
    ]);

    useLiveEffect((success) => {
        request('/search-template', {
            subjectId: selectedRowKey
        }).then(
            resp => {
                success(() => {
                    const {
                        conditionList, judgeStrategy, customJudgeLogic, id, name
                    } = resp.data ?? {
                        conditionList: [],
                        judgeStrategy: 1, // 筛选逻辑规则
                        customJudgeLogic: '', // 自定义逻辑
                    };
                    if (id) {
                        setCustomId(id);
                        setCustomName(name);
                    }
                    
                    setJudgeStrategy(judgeStrategy);
                    setConditionList(conditionList);
                    setCustomJudgeLogic(customJudgeLogic, true);
                });
            }
        );
    }, [
        selectedRowKey
    ]);

    useLiveEffect((success) => {
        let timer;
        const callback = (resp) =>  {
            success(() => {
                // 1 进行中， 3 完成
                if (resp.data === 1) {
                    setIsFilterIng(true);
                    timer = setTimeout(() => {
                        requestFilterStatus(selectedRowKey, callback);
                    }, 1000);
                } else {
                    setIsFilterIng((isFiltering) => {
                        if (isFiltering) {
                            onFilterComplete(success);
                        }   
                        return false;
                    });
                }
            });
        };

        if (selectedRowKey) {
            setIsFilterIng(false);
            requestFilterStatus(selectedRowKey, callback);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [
        count,
        selectedRowKey, 
        onFilterComplete
    ]);

    return ( 
        <>
            <CustomFilterBtn
                onClick={changeCusFilterVisible}
                judgeStrategy={judgeStrategy}
                conditionList={conditionList}
                customJudgeLogic={customJudgeLogic}
                buttonComponent={filterButton}
            />
            {
                isFiltering ? <Button type='link' onClick={onCannel}>{/* 取消 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.customerFilter.cancel}</Button> : null
            }
            {
                selectedRowKey ? (
                    <CustomFilterModal
                        visible={filterVisible}
                        cancel={cancelHandle}
                        applyAction={handleConditionApply}
                        onConditionFilterSaved={onConditionFilterSaved}
                        judgeStrategy={judgeStrategy}
                        customJudgeLogic={customJudgeLogic}
                        customName={customName}
                        customId={customId}
                        conditionList={conditionList}
                        type={type}
                        // 因为是新的接口，这里无需传参，保证有值即可
                        querryType={'empty'} 
                        filterList={['empty']}
                        // 筛选项接口
                        fieldAjax={fieldAjax}
                        // 常用筛选接口
                        customAjax={customAjax}
                        // 常用筛选保存接口
                        customSaveAjax={customSaveAjax}
                        filterTaskData={true}
                        postfixComponentRenderInLine={postfixComponentRenderInLine}
                        conditionPostfixComponent={ConditionFieldComponent}
                        operatorsFilter={operatorsFilter}
                        isAutoHideOperator={true}
                    />
                ) : null
            }
            
        </>
    );
});
