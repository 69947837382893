import React from "react";
import Udesk from 'Udesk';
import _isEmpty from "lodash-es/isEmpty";
import _size from "lodash-es/size";
import _extend from "lodash-es/extend";
import _cloneDeep from "lodash-es/cloneDeep";
import _concat from "lodash-es/concat";
import _compact from "lodash-es/compact";
import { getSystemModule } from 'Udesk/system/subApp';

class CaseBaseComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        visible: false,
        visibleTab: true,
        visibleEdit: false,
        inputNames: {},
        fields: {},
        data: [],
        drawData: [],
        inspectionId: "",
        selectKeys: "",
        activeKey: "",
        // rloadFlag: false,
        sortNumber: 1,
        paging: {
            pageNum: 1,
            pageSize: 10
        },
        audioList: [],
        audio: null,
        caseId: null,
        filteredItems: [],
        filteredList: [],
        showFieldList: [],
        audioFlag: false,
        timer: null,
        tableRef: React.createRef(),
        loading: false,
        customId: null,
        customName: '',
        storages: {
            taskId: '',
            pageNum: Udesk.config.paging.defaultPageNumber,
            pageSize: 10,
            conditionList: [],
            fieldList: [],
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            customJudgeLogic: '',
            keywords: '',
        },
        filterVisible: false,
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/tasks\/manage\/\d+\/workbench\/case-base\/detail\/\d+/i,
            ],
        },
        restoreStrategies: function (value) {
            return value && value.storages && this.props.match.params.taskId === value.storages.taskId;
        },
    };
    static computes = {
        _columns: [
            "privates.data",
            function ({ props, privates, locales }) {
                let { data } = privates;
                let showFieldList = data.showFieldList || [];
                let columns = [];
                showFieldList.forEach(field => {
                    let column = {
                        // name: field.fieldName,
                        title: field.label,
                        key: field.id,
                        render: (name, item, index) => {
                            return item.fieldValueMap[field.id];
                        }
                    };
                    columns.push(column);
                });

                return columns;
            }
        ],
        _cacheKey: [
            'privates.data',
            function ({ props, privates, locales }) {
                let {
                    sdkOptions: {
                        props: { task },
                    },
                } = props;
                let cacheKey = '';
                if (
                    task &&
                    (task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                        task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id)
                ) {
                    cacheKey = `tasks.manage.workbench.case-base.${Udesk.enums.inspectDataSources.voiceCall.key}.company-${Udesk.data.init.company.id}.taskId-${task.id}.index`;
                } else if (
                    task &&
                    (task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
                        task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
                ) {
                    cacheKey = `tasks.manage.workbench.case-base.${Udesk.enums.inspectDataSources.textDialogue.key}.company-${Udesk.data.init.company.id}.taskId-${task.id}.index`;
                }
                return cacheKey;
            },
        ],
    };
    actions = {
        onClose() {
            this.privates.visible = false;
            if (this.privates.audio) {
                this.privates.audio.pause();
                this.privates.audioList = [];
                this.privates.audioFlag = true;
            }
            clearTimeout(this.privates.timer);
            this.privates.timer = null;
            this.actions.update();
        },
        handleClick(item, index) {
            let { sdkOptions } = this.props;
            let url1 = Udesk.business.apiPath.concatApiPath(
                `caseLibraryDatas/${this.privates.data.caseLibraryDataMap[item.dataId].id}`,
                sdkOptions
            );
            this.privates.dataId = this.privates.data.caseLibraryDataMap[
                item.dataId
            ].inspectionDataId;
            return new Promise((resolve, reject) => {
                Udesk.ajax.get(url1).then(
                    resp => {
                        resolve(resp);
                        this.privates.visible = true;
                        this.privates.drawData = resp.data;
                        this.privates.remarkList = resp.data.remarkList || [];
                        let list = [];
                        !_isEmpty(resp.data) &&
                            JSON.parse(resp.data.dialogText).forEach(item => {
                                list = [
                                    ...list,
                                    {
                                        start: item.begin_time,
                                        end: item.end_time
                                    }
                                ];
                            });

                        this.forceUpdate(() => {
                            if (this.privates.drawData.voiceUrl) {
                                // 初始从第一条的通话开始播放
                                this.privates.audioList = list;
                                this.actions.controlAudio(0);
                            }
                        });
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        handleClose(value) {
            let { sdkOptions } = this.props;
            let url1 = Udesk.business.apiPath.concatApiPath(
                `caseLibraryDatas/${this.privates.data.caseLibraryDataMap[value.dataId].id}`,
                sdkOptions
            );
            return new Promise((resolve, reject) => {
                Udesk.ajax.del(url1).then(
                    resp => {
                        resolve(resp);
                        this.actions.reloadList(this.privates.activeKey);
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        controlAudio(starIndex) {
            // if(this.privates.audioFlag){
            //     this.privates.audio.removeEventListener('seeked', () => {});
            //     this.privates.audio.removeEventListener('seeking', () => {});
            //     this.privates.audio.removeEventListener('timeupdate', () => {});
            // }
            // 准备，第一次需要获取audio DOM
            // let { audio } = this.privates;

            let audio = document.getElementById("media");

            let index = starIndex; // 播放下标
            let audioList = this.privates.audioList;
            audio.currentTime = audioList[index].start / 1000;
            let seeking = false;

            // 拖拽进度条检测函数，进度条拖拽以后需要检测拖拽位置对应的通话记录index是否为当前播放的记录，如果不是需要找到应该播放哪条记录。
            // 记录检索规则：
            // 1、如果进度条拖动后，仍然落在当前播放的记录起止时间内，则从该时间点开始继续播放
            // 2、如果进度条拖动后的时间落在新的记录起止时间内，则需要切换播放下标index，并从拖拽的时间的开始继续播放
            // 3、如果进度条拖动后的时间落在两段通话记录之间，则切换播放下标index至后面的一段，如果没有之后的记录，则下标index切换至第一段，并从切换后记录的开始时间播放
            function seekTime() {
                seeking = false;
                let currentTime = audio.currentTime;
                if (
                    currentTime >= audioList[index].start / 1000 &&
                    currentTime <= audioList[index].end / 1000
                ) {
                    // 拖拽后仍然是当前播放的通话，不做任何处理，直接继续播放
                    return;
                }

                if (
                    currentTime < audioList[0].start / 1000 ||
                    currentTime > audioList[audioList.length - 1].end / 1000
                ) {
                    // 拖拽后时间落在两头，切换播放下标至第一条通话，重新开始播放
                    index = 0;
                    audio.currentTime = audioList[index].start / 1000;
                    return;
                }

                for (let i = 0; i < audioList.length; i++) {
                    let next = i + 1;
                    if (
                        currentTime >= audioList[i].start / 1000 &&
                        currentTime <= audioList[i].end / 1000
                    ) {
                        // 拖拽后时间落到其他通话中，需要切换播放下标后再继续播放
                        index = i;
                        return;
                    } else if (
                        next < audioList.length &&
                        currentTime > audioList[i].end / 1000 &&
                        currentTime < audioList[next].start / 1000
                    ) {
                        // 拖拽后时间落在两个通话中间，切换播放下标到之后一条通话并从记录开始时间播放
                        index = next;
                        audio.currentTime = audioList[index].start / 1000;
                        return;
                    }
                }
            }

            function debouce(func, delay) {
                var timer = null;
                return function () {
                    if (timer) clearTimeout(timer);
                    //每一次都重新设置timer，就是要保证每一次执行的至少delay秒后才可以执行
                    timer = setTimeout(func, delay);
                };
            }

            audio.addEventListener("seeking", () => {
                seeking = true;
            });

            audio.addEventListener("seeked", debouce(seekTime, 300));

            audio.addEventListener("timeupdate", () => {
                let currentTime = audio.currentTime;
                if (
                    seeking ||
                    (currentTime >= audioList[index].start / 1000 &&
                        currentTime <= audioList[index].end / 1000)
                ) {
                    // 正在seek或者时间在当前播放对话的起止时间内，不做任何处理
                    return;
                }
                if (index !== _size(audioList) - 1) {
                    // 当前通话播放完毕，切换当前播放下标index到下一条
                    // 如果当前通话与下一条间隔超过5秒，需要暂停2秒后，跳过中间部分，否则连续播放
                    if (currentTime > audioList[index].end / 1000) {
                        index += 1;
                        if (
                            (audioList[index].start - audioList[index - 1].end) / 1000 >
                            5
                        ) {
                            audio.pause();

                            this.privates.timer = setTimeout(() => {
                                audio.currentTime = audioList[index].start / 1000;
                                audio.play();
                            }, 2000);
                        }
                    }
                } else {
                    // 最后一条通话播放完毕，切换当前播放下标index到第一条
                    if (currentTime > audioList[_size(audioList) - 1].end / 1000) {
                        audio.pause();
                        index = 0;
                        audio.currentTime = audioList[index].start / 1000;
                    }
                }
            });
        },
        onTabClick(e) {
            this.privates.activeKey = e;
            // this.privates.rloadFlag = false;
            this.actions.reloadList(e);
            this.actions.update();
        },
        tabBarEdit(e) {
            e.preventDefault();
            e.stopPropagation();
            let { filteredItems } = this.privates;
            filteredItems.forEach(item => {
                _extend(this.privates.fields, {
                    [item.key]: false
                });
            });
            this.privates.filteredList = _cloneDeep(filteredItems);
            this.privates.visibleEdit = !this.privates.visibleEdit;
            this.actions.update();
        },
        handleOk() {
            let { sdkOptions, match } = this.props;
            let { filteredList } = this.privates;
            let url = Udesk.business.apiPath.concatApiPath(
                `caseLibraryCategorys/${match.params.taskId}`,
                sdkOptions
            );
            let list = _cloneDeep(filteredList);
            let lists = list.map((item, index) => {
                delete item.key;
                return {
                    ...item,
                    orderNum: _size(list) - index
                };
            });
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, lists).then(
                    resp => {
                        resolve(resp);
                        this.privates.visibleEdit = false;
                        // this.privates.rloadFlag = true;
                        !_isEmpty(list) && (this.privates.activeKey = list[0].key);
                        this.actions.reloadAsyncModel();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        handleCancel() {
            this.privates.visibleEdit = false;
            this.actions.update();
        },
        onSortEnd({ oldIndex, newIndex }) {
            let targetKeys = _concat([], this.privates.filteredList),
                target = targetKeys.splice(oldIndex, 1);
            targetKeys.splice(newIndex, 0, target[0]);
            this.privates.filteredList = _concat([], targetKeys);
            this.actions.update();
        },
        sortEdit(value, names) {
            this.privates.fields[value] = true;
            _extend(this.privates.inputNames, { [value]: names });
            this.actions.update();
        },
        inputChange(values, e) {
            this.privates.filteredList.map(item => {
                if (item.key === values) {
                    item.name = e.target.value;
                }
                return item;
            });
        },
        inputClose(value) {
            this.privates.fields[value] = false;
            this.privates.filteredList.map(item => {
                if (item.key === value) {
                    item.name = this.privates.inputNames[value];
                }
                return item;
            });
            this.actions.update();
        },
        sortSave(value) {
            this.privates.fields[value] = false;
            this.actions.update();
        },
        sortAdd() {
            this.privates.sortNumber++;
            if (_size(this.privates.filteredList) === 51) {
                Udesk.ui.notify.error(this.locales.fix.caseBaseLimit);
            } else {
                let data = {
                    countCase: 0,
                    defaultFlag: 0,
                    key: `sort_${this.privates.sortNumber}`,
                    level: 0,
                    name: this.locales.fix.unnamed,
                    orderNum: 0,
                    pid: 0,
                    taskId: parseInt(this.props.match.params.taskId, 10)
                };
                this.privates.filteredList = [data, ...this.privates.filteredList];
                _extend(this.privates.fields, {
                    [`sort_${this.privates.sortNumber}`]: true
                });
                _extend(this.privates.inputNames, {
                    [`sort_${this.privates.sortNumber}`]: this.locales.fix.unnamed
                });
            }
            this.actions.update();
        },
        caseBaseDelete(key, id) {
            let { sdkOptions } = this.props;
            if (id) {
                let url = Udesk.business.apiPath.concatApiPath(
                    `caseLibraryCategorys/${id}`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.del(url).then(
                        resp => {
                            resolve(resp);
                            this.actions.reloadAsyncModel();
                            let list = [];
                            this.privates.filteredList.forEach(item => {
                                if (!(item.key === key)) {
                                    list = [...list, item];
                                }
                            });
                            this.privates.filteredList = list;
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            } else {
                let list = [];
                this.privates.filteredList.forEach(item => {
                    if (!(item.key === key)) {
                        list = [...list, item];
                    }
                });
                this.privates.filteredList = list;
                this.actions.update();
            }
        },
        pageChanged(pageNum, pageSize) {
            this.privates.storages.pageNum = pageNum;
            this.privates.storages.pageSize = pageSize;
            this.actions.reloadList(this.privates.caseId);
        },
        reloadList(id, changedParams) {
            let { sdkOptions, match } = this.props;
            let url1 = Udesk.business.apiPath.concatApiPath(
                `caseLibraryDatas/search/${id}`,
                sdkOptions
            );
            let params = {
                pageNum: this.privates.storages.pageNum,
                pageSize: this.privates.storages.pageSize,
                libraryId: id,
                taskId: match.params.taskId,
                conditionList: this.privates.storages.conditionList,
                judgeStrategy: this.privates.storages.judgeStrategy,
                customJudgeLogic: this.privates.storages.customJudgeLogic,
                ...changedParams,
            };
            this.privates.loading = true;
            this.actions.update();
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url1, params).then(
                    resp => {
                        resolve(resp);
                        this.privates.data = resp.data;
                        this.privates.storages = {...this.privates.storages, ...resp.paging};
                        this.privates.showFieldList = resp.data.showFieldList || [];
                        this.privates.caseId = id;
                        this.privates.loading = false;
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onPopoverVisible(e) {
            this.privates.tableRef.current.triggerColumnsControl(e);
        },
        export(key) {
            let { sdkOptions } = this.props;
            let { locales } = this;
            let url1 = Udesk.business.apiPath.concatApiPath(
                `exps/caseLibraryData/${key}`,
                sdkOptions
            );
            let showFieldList = this.privates.showFieldList || [];

            let exportTaskFieldCreateRequestList = showFieldList.map(item => {
                return { field: item.id, label: item.label };
            });
            let params = {
                libraryId: key,
                docType: 1,
                taskId: this.props.match.params.taskId,
                conditionList: this.privates.storages.conditionList,
                judgeStrategy: this.privates.storages.judgeStrategy,
                customJudgeLogic: this.privates.storages.customJudgeLogic,
                exportTaskFieldCreateRequestList: exportTaskFieldCreateRequestList,
                systemModule: getSystemModule(),
            };
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url1, params).then(
                    resp => {
                        Udesk.ui.notify.success(locales.labels.exportSuccess);
                        resolve(resp);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        qualityDetail(id) {
            let routeOptions = {
                history: this.props.history,
                routeName: "caseBaseDetail",
                pathParams: {
                    taskId: this.props.match.params.taskId,
                    id: id
                },
                queryParams: {
                    libraryId: this.privates.activeKey
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        // 改变显示列
        columnsChange(items) {
            let showFieldList = [];
            if (items && items instanceof Array && items.length > 0) {
                showFieldList = items.map(item => {
                    let existField = this.privates.data.showFieldList.find(field => {
                        return item.name === field.fieldName;
                    });
                    return existField;
                });
            }
            this.privates.showFieldList = _compact(showFieldList);
        },
        // 高级筛选器
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.conditionList.forEach((item) => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach((items) => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label + ' ' + obj[item.operator] + ' ' + str.substring(0, str.length - 1),
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <p style={{ marginBottom: '3px' }}>
                        {index + 1}. {item}
                    </p>
                );
            });
        },
        getQueryConditionList(conditionList) {
            let allFieldList = getAllFieldListFilter(this.props, this.privates);
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map((condition) => {
                        allFieldList.forEach((field) => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            } else {
                                if (field.statusKey && condition.field === field.statusKey) {
                                    condition.field = field;
                                }
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        queckListMang() {
            let { locales } = this;
            let { conditionList, judgeStrategy, customJudgeLogic } = this.privates.storages;
            return (
                <div className='custom-filter-info-type'>
                    <div className='index-management-custom-filter-Info'>
                        <div>
                            {locales.components.common.customFilter.customFilterCondition}：
                            {_isEmpty(conditionList) && locales.components.common.customFilter.none}
                        </div>
                        <div>
                            {!_isEmpty(conditionList) &&
                                this.actions.fieldsFilterChange({
                                    conditionList: this.actions.getQueryConditionList(conditionList),
                                })}
                        </div>
                    </div>
                    <div>
                        {locales.components.common.customFilter.conditionRule}：
                        <span>
                            {judgeStrategy !== 3 &&
                                Udesk.enums.operatorRuleTypes.find((current) => current.id === (judgeStrategy || Udesk.enums.operatorRuleTypes.all.id)).name}
                            {judgeStrategy === 3 && customJudgeLogic}
                        </span>
                    </div>
                </div>
            );
        },
        onConditionFilterSaved(flag, data){
            if(data){
                this.privates.customId = data.id;
            }
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy, id, name) {
            this.privates.storages.conditionList = conditionList;
            this.privates.storages.customJudgeLogic = customJudgeLogic;
            this.privates.storages.judgeStrategy = judgeStrategy;
            this.privates.customName = name;
            this.privates.customId = id;

            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
                conditionList: conditionList,
                judgeStrategy,
                customJudgeLogic,
            };
            this.privates.filterVisible = false;
            this.actions.reloadList(this.privates.caseId, params);
        },
        changeFilterVisible() {
            let { filterVisible } = this.privates;
            this.privates.filterVisible = !filterVisible;
            this.actions.update();
        },
        cancel() {
            this.privates.filterVisible = false;
            this.actions.update();
        },

    };

    //#region Life Cycle
    componentDidMount() {
        this.privates.storages.taskId = this.props.match.params.taskId;
    }
    componentWillUnmount() { }
    init(){
        this.privates.activeKey = this.props.location?.state?.libraryId;
    }
    // componentDidUpdate(prevProps) {
    //     if (!_isEmpty(this.privates.filteredItems) && this.privates.rloadFlag) {
    //         this.actions.reloadList(this.privates.activeKey);
    //         this.privates.rloadFlag = false;
    //     }
    // }

    //#endregion
}

export default CaseBaseComponent;

function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter((item) => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map((item) => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}

function getAllFieldListFilter(props, privates) {
    let { fieldList } = privates.storages;
    let { sdkOptions } = props;

    let allFieldList = [];

    if (fieldList && fieldList.length > 0) {
        allFieldList = fieldList;
    } else if (sdkOptions.props.fields && sdkOptions.props.fields.length > 0) {
        allFieldList = sdkOptions.props.fields;
    }

    return allFieldList;
}