import Udesk from 'Udesk';
import React from 'react';
import UdeskPagination from 'Component/common/udesk-pagination';
import RoleIndexRoute from './route';
import './style.scss';
import { PageHeader, Table, Button } from 'udesk-ui';

export default class RoleIndexTemplate extends RoleIndexRoute {
    render() {
        // let {
        //     sdkOptions
        // } = this.props;
        let {
            columns,
            pageNum,
            pageSize,
            total
        } = this.state;
        let {
            roles,
            loading
        } = this.privates;
        let {
            actions,
            locales
        } = this;

        return (
            // <div className="role-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.role.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root role-index-page' style={{ padding: 16 }}>
                        <div className="role-index-page-body qa-react-page-body">
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:role:list")}>
                                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:role:add")}>
                                    <div className="role-index-page-body-search-container qa-react-page-body-search-container">
                                        <div className="qa-react-page-body-search-container-right-part pull-right">
                                            <Button type='primary' onClick={actions.createItem}>
                                                {locales.components.pages.role.create}
                                            </Button>
                                        </div>
                                    </div>
                                </If>
                                <div className="role-index-page-body-content-container qa-react-page-body-content-container">
                                    <Table columns={columns} dataSource={roles} size='small' pagination={false} loading={loading}/>
                                    <div style={{marginTop: 8}}>
                                        {/* <ReactPager
                                            pageNum={pageNum}
                                            pageSize={pageSize}
                                            total={total}
                                            languageCode={sdkOptions.props.language}
                                            pageChanged={actions.pageChanged}
                                        /> */}
                                        <UdeskPagination
                                            current={pageNum}
                                            pageSize={pageSize}
                                            total={total}
                                            onChange={actions.pageChanged}
                                        />
                                    </div>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

