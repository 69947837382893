import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table } from 'udesk-ui';
import { Summary } from '../Summary';
import { Card } from './components/Card';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import UdeskLocales from 'UdeskLocales';

const Wrap = styled.div`
    overflow: auto;
    scroll-behavior: smooth;
    /* .key-words-cloud {
        height: 500px;
    } */
`;

export const Outline = React.memo((props: any) => {
    const { summaryData, loading, bindId, request, ...propsSummary } = props;

    const [conversationalActions, setConversationalActions] = useState([]);
    const [keywordColumns] = useState([
        {
            field: 'count',
            name: /* 关键词次数 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.numberOfKeywords,
        },
    ]);
    const [keywordItems, setKeywordItems] = useState([]);
    const requestConversationalActions = () => {
        request(`conversational-actions/${bindId}`).then((resp) => {
            console.log('resp', resp);
            setConversationalActions(resp);
        });
    };

    const requestKeywords = () => {
        request(`conversational-actions/keywords/${bindId}`).then((resp) => {
            console.log('keywords', resp);
            setKeywordItems(
                resp.map((i) => ({
                    count: {
                        originalValue: i.count,
                        value: i.count,
                    },
                    xLabel: i.word,
                }))
            );
        });
    };
    useEffect(() => {
        requestConversationalActions();
        requestKeywords();
    }, []);

    const columns = [
        { key: 'speaker', dataIndex: 'speaker', title: /* 说话人 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.speaker },
        { key: 'duration', dataIndex: 'duration', title: /* 说话时长 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.speechDuration },
        { key: 'proportion', dataIndex: 'proportion', title: /* 说话占比 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.proportionOfSpeaking },
        { key: 'maximumSpeechDuration', dataIndex: 'maximumSpeechDuration', title: /* 最长发言时长 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.maximumSpeechDuration },
        { key: 'questionsNumber', dataIndex: 'questionsNumber', title: /* 提问数量 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.numberOfQuestions },
        { key: 'averageSpeechSpeed', dataIndex: 'averageSpeechSpeed', title: /* 平均语速 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.averageSpeechSpeed },
    ];

    const finalizeChartOptions = useCallback((option) => {
        option.tooltip.trigger = 'item';
        option.tooltip.backgroundColor = '#fff';
        option.tooltip.borderColor = '#333';
        option.tooltip.textStyle.color = '#333';
        option.tooltip.padding = 15;
        option.tooltip.textStyle.fontSize = 14;
        return option;
    }, []);
    return (
        <Wrap>
            <Card title={/* 会话动作 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.conversationalActions}>
                <Table columns={columns} dataSource={conversationalActions} pagination={false} />
            </Card>
            <Card title={/* 内容摘要 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.contentSummary}>
                <Summary
                    data={summaryData}
                    {...propsSummary}
                    loading={loading}
                    modalTitle={/* 生成摘要 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.generateSummary}
                />
            </Card>
            <Card title={/* 关键词词云 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.outline.index.keywordCloud}>
                <div style={{ height: 500, overflow: 'hidden' }}>
                    <ReactEcharts
                        // ref={privates.wordCloudRef}
                        classNames="key-words-cloud"
                        type="wordCloud"
                        xLabelField="xLabel"
                        showTooltips={true}
                        rerenderOptimization={true}
                        records={keywordItems}
                        seriesColumns={keywordColumns}
                        finalizeChartOptions={finalizeChartOptions}
                        height="100%"
                    />
                </div>
            </Card>
        </Wrap>
    );
});
