import {
    NotImplementedError,
    ArgumentNullError
} from '../../error';
import EventedClass from '../../evented-class';
import VersionMonitorClass from "./version-monitor/version-monitor-class";
import SesstionVersionMonitorClass from "./version-monitor/sesstion-version-monitor-class";
import storage from "./version-monitor/storage";

const DEFAULT_OPTIONS = {
    versionMonitorIntervalSeconds: 60,
    backgroundVersionMonitorIntervalSeconds: 300,
    enableNewVerionTip: true,
    enableForceUpdate: true,
    reloadCallback: null
};
let GlobalInstances = new Set();
let GetUserForSentry = null;
let getTagsContextForSentry = null;
let enableSentry = true;
let EnableVersionmOnitoring = true;
let versionMonitorInstance = null;

const RELOAD_SYMBOL = Symbol(`RELOAD_SYMBOL`);
const SESSTION_VERSION_MONITOR_INSTANCE = Symbol(`SESSTION_VERSION_MONITOR_INSTANCE`);
const SDK_BASE_CLASS_INIT = Symbol(`SDK_BASE_CLASS_INIT`);
const SDK_EVENTS_SYMBOL = Symbol(`SDK_EVENTS_SYMBOL`);

export {
    SDK_EVENTS_SYMBOL
};

export default class SDKBaseClass extends EventedClass {
    constructor(options) {
        super();
        if (typeof this.constructor.destroy !== "function") {
            throw new NotImplementedError(`${this.constructor.name}.destroy`);
        }
        this.options = Object.assign({}, DEFAULT_OPTIONS, options);
        GlobalInstances.add(this);
        this[SDK_EVENTS_SYMBOL] = new EventedClass();
        this[SDK_BASE_CLASS_INIT]();
    }

    [SDK_BASE_CLASS_INIT]() {
        if (this.constructor.enableVersionmOnitoring) {
            let versionPath = this.getVerssionPath();
            if (versionMonitorInstance == null) {
                versionMonitorInstance = new VersionMonitorClass({
                    versionPath: versionPath,
                    intervalSeconds: this.options.versionMonitorIntervalSeconds || DEFAULT_OPTIONS.versionMonitorIntervalSeconds
                });
                versionMonitorInstance.start();
                storage.init({
                    sdkName: this.constructor.sdkName
                });
            }
            changeVersionMonitorSeconds.call(this);
            this[SESSTION_VERSION_MONITOR_INSTANCE] = new SesstionVersionMonitorClass();
            this[SESSTION_VERSION_MONITOR_INSTANCE].on("onVersionChanged", (version, oldversion, isVersionRollback) => {
                if (this.options.enableForceUpdate && isVersionRollback) {
                    this.constructor.reload();
                    return;
                }
                if (this.options.enableNewVerionTip) {
                    this[SDK_EVENTS_SYMBOL].trigger("onShowNewVersionTip", [version, oldversion]);
                }
            });
        }
    }

    [RELOAD_SYMBOL](successCallback, failCallbask) {
        if (this.options.reloadCallback != null && typeof this.options.reloadCallback === "function") {
            let reloadResult = this.options.reloadCallback();
            if (reloadResult != null && typeof reloadResult.then === "function") {
                reloadResult.then(function() {
                    if (typeof successCallback === "function") {
                        successCallback(...arguments);
                    }
                }, function() {
                    if (typeof failCallbask === "function") {
                        failCallbask(...arguments);
                    }
                });
            }
        }
    }

    static reload(successCallback, failCallbask) {
        for (const instance of GlobalInstances) {
            instance[RELOAD_SYMBOL](successCallback, failCallbask);
        }
    }

    getVerssionPath() {
        throw new NotImplementedError(`getVerssionPath`);
    }

    destroy() {
        if (GlobalInstances.has(this.constructor)) {
            GlobalInstances.delete(this);
        }
        this.off();
        changeVersionMonitorSeconds.call(this);
        if (this[SESSTION_VERSION_MONITOR_INSTANCE]) {
            this[SESSTION_VERSION_MONITOR_INSTANCE].off("onVersionChanged");
            this[SESSTION_VERSION_MONITOR_INSTANCE].destroy();
            this[SESSTION_VERSION_MONITOR_INSTANCE] = null;
        }
    }

    static get destroy() {
        return function() {
            for (const instance of GlobalInstances) {
                instance.destroy();
            }
            GlobalInstances.clear();
            if (versionMonitorInstance != null) {
                versionMonitorInstance.destroy();
                versionMonitorInstance = null;
            }
            storage.destroy();
        };
    }

    static get initGlobalNameSpace() {
        return function(sdkClass, options) {
            if (sdkClass == null) {
                throw new ArgumentNullError(`initGlobalNameSpace.sdkClass`);
            }
            if (typeof options !== "object") {
                options = {
                    defaultNamespace: null
                };
            }
            let globalNamespace = window.__SDK_GLOBAL_NAMESPACE__;

            if (typeof globalNamespace === "function") {
                globalNamespace = globalNamespace();
            }
            if (globalNamespace == null) {
                if (options.defaultNamespace) {
                    globalNamespace = options.defaultNamespace;
                } else {
                    globalNamespace = "";
                }
            }

            if (globalNamespace !== "") {
                window[globalNamespace] = sdkClass;
            }

            if (typeof window.__SDK_LOADED_CALLBACK__ === "function") {
                window.__SDK_LOADED_CALLBACK__(sdkClass);
            }
        };
    }

    static get sdkName() {
        throw new NotImplementedError(`static sdkName`);
    }

    static set enableSentry(value) {
        enableSentry = value
    }

    static get enableSentry() {
        return enableSentry;
    }

    static set getUserForSentry(value) {
        GetUserForSentry = value;
    }

    static set getTagsContextForSentry(value) {
        getTagsContextForSentry = value;
    }

    static get getUserForSentry() {
        return GetUserForSentry;
    }

    static get getTagsContextForSentry() {
        return getTagsContextForSentry;
    }

    static set enableVersionmOnitoring(value) {
        EnableVersionmOnitoring = value;
    }

    static get enableVersionmOnitoring() {
        return EnableVersionmOnitoring;
    }
    static get currentVersion() {
        return storage.get("version");
    }
}

function changeVersionMonitorSeconds() {
    if (versionMonitorInstance == null) {
        return;
    }
    let currentIntervalSeconds = versionMonitorInstance.currentIntervalSeconds;
    if (GlobalInstances.size > 0) {
        if (currentIntervalSeconds !== this.options.versionMonitorIntervalSeconds) {
            versionMonitorInstance.changeOptions({
                intervalSeconds: this.options.versionMonitorIntervalSeconds
            });
        }
    } else {
        if (currentIntervalSeconds !== this.options.backgroundVersionMonitorIntervalSeconds) {
            versionMonitorInstance.changeOptions({
                intervalSeconds: this.options.backgroundVersionMonitorIntervalSeconds
            });
        }
    }
}