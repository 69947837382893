import React, { useCallback, useMemo } from 'react';
import OrganizationMemberSelect from './OrganizationWithFinalValueSelect';
import { inspect, isDefined, isNumber, posterity } from 'src/util/core';
import { Checkbox, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        value, onChange, treeData, finalValue,
        getUserByIds, getOrgByIds,
        ...otherProps
    } = props;

    const checked = useMemo(() => isDefined(value), [value]);

    const onChangeForCheckbox = useCallback(() => {
        onChange(checked ? null : []);
    }, [onChange, checked]);

    const treeDataWithDisabledProps= useMemo(() => {
        const dataSource = treeData.filter(
            (node) => isNumber(node?.id)
        ).map(node => ({
            ...node, 
            disableCheckbox: false
        }));
        if (finalValue && dataSource.length > 0) {
            const {organizationIds} = finalValue;
            const list: any[] = [].concat(
                organizationIds.map(id => dataSource.find(item => item.id === id))
            );

            posterity(
                list,
                (node) => {
                    if (node) {
                        node.disableCheckbox = true;
                    }
                },
                (node) => {
                    if (isNumber(node?.id)) {
                        return dataSource.filter(item => item.pId === node.id);
                    }
                    return undefined;
                }
            );
        }
        return dataSource;
    }, [
        finalValue, treeData
    ]);

    return (
        <Space>
            <Checkbox checked={checked} onChange={onChangeForCheckbox}>{/* 默认发布给新添加员工 */}{UdeskLocales['current'].pages.coach.taskCenter.edit.autoAddOrganizationList.publishToNewlyAddedEmployeesByDefault}</Checkbox>
            {
                inspect(checked, () => (
                    <OrganizationMemberSelect
                        {...otherProps}
                        value={{
                            organizationIds: value || [],
                            userIds: [],
                        }}
                        treeData={treeDataWithDisabledProps} 
                        finalValue={finalValue}
                        getUserByIds={getUserByIds} 
                        getOrgByIds={getOrgByIds}
                        onChange={({organizationIds}) => onChange(organizationIds)}
                    />
                ))
            }
        </Space>
    );
});