import React, { useContext } from 'react';
import { useScorePageDetail } from '../hooks/useScorePageDetail';

const ScoreDetailContext = React.createContext<ReturnType<typeof useScorePageDetail> | undefined>(
    undefined
);

export const ScoreDetailProvider = ({ value, children }) => {
    return <ScoreDetailContext.Provider value={value}>{children}</ScoreDetailContext.Provider>;
};

export const useScoreDetailContext = () => {
    const context = useContext(ScoreDetailContext);
    if (context === undefined) {
        throw new Error('useScoreDetailContext must be used within a ScoreDetailProvider');
    }

    return context;
};
