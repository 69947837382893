export function formatDuration(durationSeconds) {
    let hours = Math.floor(durationSeconds / (60 * 60));
    hours = hours >= 10 ? hours : `0${hours}`;
    let second = durationSeconds % (60 * 60);
    let minutes = Math.floor(second / 60);
    minutes = minutes >= 10 ? minutes : `0${minutes}`;
    second = Math.floor(second) % 60;
    second = second >= 10 ? second : `0${second}`;
    return `${hours}:${minutes}:${second}`;
}
