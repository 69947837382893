import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import { Button, FormBuilder } from 'udesk-ui';
import AutoSizeTable from 'Component/common/auto-size-table';

import Page from 'Component/common/udesk-qa-page-layout';

import Locales from 'UdeskLocales';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';


function CollectionPoint(props) {
    const locales = Locales['current'];

    const [form] = FormBuilder.useForm();
    const [options, setOptions] = useState<{ label: string; value: number }[]>([]);
    const [dataSource, setDataSource] = useState<{ [key: string]: string | number }[]>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<any>({});
    const requestTableDataSource = (value) => {
        setTableLoading(true);
        new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `informationAcquisitionRules/category/${value}`,
                props.sdkOptions
            );
            Udesk.ajax.get(url, { pageSize: 1000, pageNum: 1 }).then(
                (resp) => {
                    setDataSource(resp.data);
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        }).finally(() => {
            setTableLoading(false);
        });
    };
    const getInformationAcquisitionTaskGroupRelations = () => {
        let url = Udesk.business.apiPath.concatApiPath(
            `informationAcquisitionTaskGroupRelations/task/${props.match.params.taskId}`,
            props.sdkOptions
        );
        setFormLoading(true);
        Udesk.ajax.get(url).then(
            (resp) => {
                setInitialValues(resp.data);
                setFormLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setFormLoading(false);
            }
        );
    };

    const submit = (values) => {
        let url = Udesk.business.apiPath.concatApiPath(
            `informationAcquisitionTaskGroupRelations`,
            props.sdkOptions
        );
        Udesk.ajax.post(url, { categoryId: values.categoryId, taskId: props.match.params.taskId }).then(
            (resp) => {
                Udesk.ui.notify.success(locales.business.info.saveSuccess);
                getInformationAcquisitionTaskGroupRelations();
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const config: FormProps = {
        initialValues: initialValues,
        fields: [
            {
                name: 'categoryId',
                label: locales.components.pages.collectionRule.form.info,
                type: 'Select',
                props: {
                    placeholder: locales.components.pages.collectionRule.form.infoPlaceholder,
                    options: options,
                    onChange: requestTableDataSource,
                },
                forceDisabled: initialValues.categoryId,
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        ],
        onFinish: submit,
        footerRender: initialValues.categoryId || formLoading ? false : undefined,
    };
    const goToPointEdit = (item) => {
        window.open(`/site/intelligent-analysis/intelligent-collection/${item.categoryId}/edit/${item.id}`);
    };
    const columns = [
        {
            title: locales.components.pages.collectionRule.table.name,
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ellipsis: true,
        },
        {
            title: locales.components.pages.collectionRule.table.props,
            dataIndex: 'customField',
            key: 'customField',
            width: '20%',
            render: (name, item, index) => {
                return <div>{name ? locales.labels.yes : locales.labels.no}</div>;
            },
        },
        {
            title: locales.components.pages.collectionRule.table.type,
            dataIndex: 'fieldType',
            key: 'fieldType',
            width: '20%',
            render: (name) => {
                return Udesk.enums.valueTypes.getName(name);
            },
        },
        {
            title: locales.components.pages.collectionRule.table.desc,
            dataIndex: 'describe',
            key: 'describe',
            width: '20%',
            ellipsis: true,
        },
        {
            key: 'actions',
            dataIndex: 'actions',
            title: locales.labels.action,
            width: '18%',
            align: 'center' as any,
            render: (name, item, index) => {
                return (
                    <Button type='link' size='small' onClick={goToPointEdit.bind(null, item)}>
                        {locales.labels.view}
                    </Button>
                );
            },
        },
    ];
    useEffect(() => {
        form.resetFields();
        initialValues.categoryId && requestTableDataSource(initialValues.categoryId);
    }, [initialValues]);
    useEffect(() => {
        let url = Udesk.business.apiPath.concatApiPath(`informationAcquisitionCategories`, props.sdkOptions);
        Udesk.ajax.get(url, { pageSize: 1000 }).then(
            (resp) => {
                setOptions(
                    resp.data.map((i) => ({
                        ...i,
                        label: i.name,
                        value: i.id,
                    }))
                );
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
        getInformationAcquisitionTaskGroupRelations();
    }, []);

    return (
        <Page
            pageBodyClassName='data-collection-point-page-index'
            title={locales.components.pages.collectionRule.title}
            padding={true}
            loading={formLoading}
        >
            <FormBuilder {...config} form={form}>
                <div style={{ marginBottom: 16 }}>
                    {form.getFieldValue('categoryId') ? (
                        <AutoSizeTable
                            columns={columns}
                            dataSource={dataSource}
                            subtractedHeight={165}
                            pagination={false}
                            loading={tableLoading}
                        />
                    ) : null}
                </div>
            </FormBuilder>
        </Page>
    );
}

export default React.memo(CollectionPoint);
