// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewCallDataCallDetailByIdQuery,
    GetReviewCallDataCallDetailByIdParam,
    BaseResponseDataCallDto,
} from '../../../../../types';

/**
 * 通话详情
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/dataCall/detail/{id}
 * @param id
 */
export function getReviewCallDataCallDetailById(
    options: ApiOptions<
        GetReviewCallDataCallDetailByIdParam,
        GetReviewCallDataCallDetailByIdQuery
    > & { segments: GetReviewCallDataCallDetailByIdParam }
): Promise<BaseResponseDataCallDto> {
    return request<
        never,
        GetReviewCallDataCallDetailByIdParam,
        GetReviewCallDataCallDetailByIdQuery
    >('/review/call/dataCall/detail/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallDataCallDetailById',
    });
}

export const meta = [
    { tags: ['通话记录'], path: '/review/call/dataCall/detail/{id}', method: 'get' },
];
