import React, { FC, memo, useCallback } from 'react';
import { Select } from 'udesk-ui';

type Select2Props = {
    onChange?: (...arg: any) => void;
    onChangeAfter?: (...arg: any) => void;
    [k: string]: unknown;
};

const Select2: FC<Select2Props> = React.memo((props) => {
    const { onChangeAfter, onChange, ...other } = props;

    const changeHandle = useCallback(
        (...args) => {
            onChange?.(...args);
            onChangeAfter?.(...args);
        },
        [onChange, onChangeAfter]
    );

    return <Select {...other} onChange={changeHandle} />;
});

export default memo(Select2);
