// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PutSubjectMarkFilesMoveByIdParam,
    PutSubjectMarkFilesMoveByIdBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 移动话题
 *
 * @export
 * @tags 话题标注
 * @link [PUT] /subjectMarkFiles/move/{id}
 * @param id
 */
export function putSubjectMarkFilesMoveById(
    data: PutSubjectMarkFilesMoveByIdBody,
    options: ApiOptions<PutSubjectMarkFilesMoveByIdParam, never> & {
        segments: PutSubjectMarkFilesMoveByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutSubjectMarkFilesMoveByIdBody, PutSubjectMarkFilesMoveByIdParam, never>(
        '/subjectMarkFiles/move/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putSubjectMarkFilesMoveById' }
    );
}

export const meta = [{ tags: ['话题标注'], path: '/subjectMarkFiles/move/{id}', method: 'put' }];
