import React from 'react';
import Udesk from 'Udesk';
import { isAlgorithm, isSalesSubApp } from 'Udesk/system/subApp';

class InformationEntitiesDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        sentenceList: [],
        loading: false,
        type: null,
        config: {}
    };

    actions = {
        onFinish(values) {
            let {
                locales
            } = this;
            let {
                sdkOptions
            } = this.props;
            let {
                // sentenceList,
                model
            } = this.privates;
            let sentenceList = values.sentenceList;
            if (sentenceList && sentenceList.length > 0) {
                sentenceList = sentenceList.filter(item => {
                    return item.content.trim() !== "";
                });
            }
            // this.privates.loading = true;
            // this.actions.update();
            // model.sentenceList = sentenceList;
            // let params = model;
            let params = {
                ...values,
                sentenceList,
                // systemType: Udesk.data.init.company.systemType,
            };
            let remark = values.remark && values.remark.trim();
            if (remark) {
                params.remark = remark;
            }

            let url = Udesk.business.apiPath.concatApiPath(`information-entity/save`, sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            if (model.id) {
                url = Udesk.business.apiPath.concatApiPath(`information-entity/update`, sdkOptions);
                method = Udesk.enums.requestMethods.update.name;
                params = {
                    ...model,
                    ...params
                };
            }

            Udesk.ajax(url, params, method).then(
                (resp) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.success(Udesk.utils.string.format(locales.business.notifyMessage.saveSuccessFormat, locales.components.pages.informationEntities.title));
                    this.actions.backToIndex();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(locales.business.notifyMessage.saveFailedFormat, locales.components.pages.informationEntities.title));
                    this.privates.loading = false;
                    this.actions.update();
                }
            );
        },
        saveEntity() {
            let {
                locales
            } = this;
            let {
                sdkOptions
            } = this.props;
            let {
                // sentenceList,
                model
            } = this.privates;
            if (model.name == null || model.name.trim() === "") {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.informationEntities.detail.name)
                );
            }
            if (model.apiName == null || model.apiName.trim() === "") {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.informationEntities.detail.apiName)
                );
            }

            let sentenceList = this.privates.model.sentenceList;
            if (sentenceList && sentenceList.length > 0) {
                sentenceList = sentenceList.filter(item => {
                    return item.content.trim() !== "";
                });
            }
            // this.privates.loading = true;
            // this.actions.update();
            // model.sentenceList = sentenceList;
            // let params = model;
            let params = {
                ...model,
                sentenceList,
                // systemType: Udesk.data.init.company.systemType,
            };

            let url = Udesk.business.apiPath.concatApiPath(`information-entity/save`, sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            if (model.id) {
                url = Udesk.business.apiPath.concatApiPath(`information-entity/update`, sdkOptions);
                method = Udesk.enums.requestMethods.update.name;
            }

            Udesk.ajax(url, params, method).then(
                (resp) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.success(Udesk.utils.string.format(locales.business.notifyMessage.saveSuccessFormat, locales.components.pages.informationEntities.title));
                    this.actions.backToIndex();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(locales.business.notifyMessage.saveFailedFormat, locales.components.pages.informationEntities.title));
                    this.privates.loading = false;
                    this.actions.update();
                }
            );
        },
        backToIndex() {
            let routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm() ? 'algorithmInformationEntitiesIndex' : isSalesSubApp() ? 'salesInformationEntitiesIndex' : "informationEntitiesIndex",
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sentenceValueChange(index, value) {
            let {
                sentenceList
            } = this.privates.model;
            sentenceList[index].content = value;
            this.actions.update();
        },
        addSentence() {
            let {
                sentenceList
            } = this.privates.model;
            if (Array.isArray(sentenceList) && sentenceList.length >= 2000) {
                return;
            }
            sentenceList.unshift({
                content: ''
            });
            this.actions.update();
        },
        sentenceEmptyErrorTip() {
            Udesk.ui.notify.error(
                Udesk.utils.string.format(this.locales.business.notifyMessage.canNotBeEmptyFormat, this.locales.components.pages.informationEntities.detail.sentenceList)
            );
        },
        deleteSentence(index) {
            let {
                sentenceList
            } = this.privates.model;
            sentenceList.splice(index, 1);
            this.actions.update();
        },
        changeActiveFlag(value) {
            this.privates.model.activeFlag = value;
            this.actions.update();
        }
    };

    //#region Life Cycle
    init() {
        let activeFlags = Udesk.enums.activeFlags;
        activeFlags = activeFlags.map(item => {
            return {
                id: item.id,
                name: item.name
            };
        });
        this.privates.activeFlags = activeFlags;
        // this.actions.update();
        // const { locales, privates, actions } = this;
        // this.privates.
    }
    componentWillUnmount() { }
    //#endregion
}

export default InformationEntitiesDetailComponent;