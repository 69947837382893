import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';

class AudioTextViewComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        sentenceList: [],
        highlightSeqList: [],
        point: null,
        appealVisible: false,
        inspectDataSource: null,
        pointList: [],
        markSemanticVisible: true,
        detailId: '',
        inputFlagList: [],
        keywords: '',
        applyReason: false,
    };
    //#endregion

    state = {};
    privates = {
        highlightSeqList: [],
        highlightSeqIndex: 1,
        appealSentenceList: [],
        audioTextListRef: React.createRef(),
        caseBaseLists: [],
        searchContentList: [],
        keywords: '',
    };
    static computes = {
        audioTextItems: ({ props, state, privates, locales }) => {
            let audioTextItems = createAudioTextItems(
                props.sentenceList,
                privates.highlightSeqList,
                privates.highlightSeqIndex,
                props.inspectDataSource,
                props.pointList,
                props.point
            );
            return audioTextItems;
        }
    };
    actions = {
        keywordsChanged(e) {
            this.privates.keywords = e.target.value;
            this.actions.update();
        },
        searchContet() {
            let searchContet = this.privates.keywords.trim();
            if (searchContet === "") return;
            // 先清除质检点命中高亮信息
            this.actions.closeHit();
            const keywords = searchContet.split(/ +/);
            let audioTextListDOM = this.privates.audioTextListRef.current;
            let audioTextListElems = audioTextListDOM.getElementsByClassName('audio-text-list-item-text-container');
            for (let i = 0; i < audioTextListElems.length; i++) {
                Udesk.utils.dom.clearHighlight(audioTextListElems[i]);
                keywords.forEach(item => {
                    Udesk.utils.dom.highlightText(audioTextListElems[i], item);
                });
            }
            this.privates.searchContentList = audioTextListDOM.getElementsByTagName('em');
            let searchedFirst = audioTextListDOM.getElementsByTagName('em')[0];
            if (searchedFirst) {
                searchedFirst.setAttribute("class", "audio-text-list-item-text-hit");
                searchedFirst.scrollIntoViewIfNeeded && searchedFirst.scrollIntoViewIfNeeded();
            }
            this.actions.update();
        },
        changeAppealType(item, event) {
            item.data.complainType = event.target.value;
            this.actions.getAppealSentenceList();
        },
        changeAppealReason(item, event) {
            item.data.complainReason = event.target.value;
            this.actions.getAppealSentenceList();
        },
        changeReasonEditStatus(item, status) {
            item.data.reasonEditStatus = status;
            this.actions.getAppealSentenceList();
        },
        getAppealSentenceList() {
            let { audioTextItems } = this.privates.computes;
            let { appealSentenceList } = this.privates;
            appealSentenceList = audioTextItems
                .filter(item => {
                    return item.data.complainType;
                })
                .map(item => {
                    return item.data;
                });
            this.trigger('onSelectedSentenceListChanged', appealSentenceList);
        },
        sendCurrentTimeRange(item) {
            this.trigger('onTimeRangeChanged', item);
        },
        markSemantic(content) {
            this.trigger('onMarkSemanticClick', content);
        },
        nextHit(type) {
            let { highlightSeqList } = this.privates;
            if (type === 'up' && this.privates.highlightSeqIndex > 1) {
                this.privates.highlightSeqIndex -= 1;
            }
            if (type === 'down' && this.privates.highlightSeqIndex < highlightSeqList.length) {
                this.privates.highlightSeqIndex += 1;
            }
            this.actions.update();
            this.forceUpdate(() => {
                let audioCallInfoDOM = this.privates.audioTextListRef.current;
                let searchedFirst = audioCallInfoDOM.getElementsByClassName('audio-text-list-item-text-hit')[0];
                searchedFirst && searchedFirst.scrollIntoViewIfNeeded && searchedFirst.scrollIntoViewIfNeeded();
            });
        },
        closeHit() {
            this.privates.highlightSeqList = [];
            this.privates.highlightSeqIndex = 1;
            this.actions.update();
            this.trigger('onCloseHitClick');
        },
        nextSearched(type) {
            let { searchContentList, highlightSeqIndex } = this.privates;
            searchContentList[highlightSeqIndex - 1].removeAttribute("class");
            if (type === 'up' && this.privates.highlightSeqIndex > 1) {
                this.privates.highlightSeqIndex -= 1;
            }
            if (type === 'down' && this.privates.highlightSeqIndex < searchContentList.length) {
                this.privates.highlightSeqIndex += 1;
            }
            this.actions.update();
            let next = searchContentList[this.privates.highlightSeqIndex - 1];
            next.setAttribute("class", "audio-text-list-item-text-hit");
            next && next.scrollIntoViewIfNeeded && next.scrollIntoViewIfNeeded();
        },
        closeSearched() {
            clearSearchedHighlight(this);
            this.privates.highlightSeqIndex = 1;
            this.privates.searchContentList = [];
            this.privates.keywords = "";
            this.actions.update();
        },
        caseBaselist(value) {
            let { saveCaseBase } = this.props;
            saveCaseBase && saveCaseBase(value);
        },
        inputFlag(value) {
            let { inputFlags } = this.props;
            if (!_isEmpty(this.privates.inputFlagList)) {
                let list = [];
                if (_find(this.privates.inputFlagList, current => current.id === value.id)) {
                    list = this.privates.inputFlagList.map(item => {
                        if (item.id === value.id) {
                            item.flag = value.flag;
                        }
                        return item;
                    });
                } else {
                    list = [...this.privates.inputFlagList, value];
                }
                this.privates.inputFlagList = list;
                if (_find(list, current => current.flag === false)) {
                    inputFlags && inputFlags(false);
                } else {
                    inputFlags && inputFlags(true);
                }
            } else {
                let list = [value];
                this.privates.inputFlagList = list;
                if (_find(list, current => current.flag === false)) {
                    inputFlags && inputFlags(false);
                } else {
                    inputFlags && inputFlags(true);
                }
            }
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        let privates = {
            highlightSeqList: props.highlightSeqList,
            highlightSeqIndex: 1,
            point: props.point,
            caseBaseFlag: props.caseBaseFlag,
            keywords: props.keywords,
        };
        if (props.keywords === "") {
            clearSearchedHighlight(this);
            privates.searchContentList = [];
        }
        return privates;
    }
    componentDidMount() {
        if (this.privates.keywords !== "") {
            this.actions.searchContet();
        }
    }
    componentWillUnmount() { }
    asyncModel(changedParams) { }
    //#endregion
}

function createAudioTextItems(sentenceList, highlightSeqList, highlightSeqIndex, inspectDataSource, pointList, point) {
    let markedSentenceList = [];
    if (point && pointList && pointList.length > 0) {
        let targetPoint = pointList.find(item => {
            return item.pointId === point.pointId;
        });
        if (targetPoint) {
            markedSentenceList = targetPoint.sentenceList;
        }
    }

    let audioTextItems = [];
    if (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id) {
        if (sentenceList && sentenceList.length > 0) {
            sentenceList.forEach((element, index) => {
                let isHit = highlightSeqList[highlightSeqIndex - 1] === index + 1;
                let isComplain = false;
                let targetSentence = null;
                if (markedSentenceList.length > 0) {
                    targetSentence = markedSentenceList.find(markedItem => {
                        return markedItem.id === index + 1;
                    });
                    if (targetSentence) {
                        isComplain = true;
                    }
                }
                let item = {
                    data: {
                        id: index + 1,
                        text: element.text,
                        fromSec: element.fromSec,
                        endSec: element.endSec,
                        dialogSentenceId: element.id,
                        role: element.role,
                        complainReason: "",
                        averageSpeakSpeed: element.averageSpeakSpeed
                    },
                    states: {
                        isHit: isHit,
                        isComplain: isComplain,
                        text: element.text,
                        content: {
                            data: {}
                        }
                    }
                };
                if (isComplain) {
                    item.data.complainType = targetSentence.complainType;
                    item.data.complainReason = targetSentence.complainReason;
                    item.data.reasonEditStatus = targetSentence.reasonEditStatus;
                }
                audioTextItems.push(item);
            });
        }
    } else if (inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id) {
        if (sentenceList && sentenceList.length > 0) {
            sentenceList.forEach((element, index) => {
                if (element && element.content) {
                    let isHit = highlightSeqList[highlightSeqIndex - 1] === index + 1;
                    let isComplain = false;
                    let targetSentence = null;
                    if (markedSentenceList.length > 0) {
                        targetSentence = markedSentenceList.find(markedItem => {
                            return markedItem.id === element.id;
                        });
                        if (targetSentence) {
                            isComplain = true;
                        }
                    }
                    let item = {
                        data: {
                            id: element.id,
                            content: element.content,
                            dialogSentenceId: element.id,
                            role: element.sender,
                            createdTime: element.created_at,
                            complainReason: "",
                        },
                        states: {
                            isHit: isHit,
                            content: JSON.parse(element.content)
                        }
                    };
                    if (isComplain) {
                        item.data.complainType = targetSentence.complainType;
                        item.data.complainReason = targetSentence.complainReason;
                        item.data.reasonEditStatus = targetSentence.reasonEditStatus;
                    }
                    audioTextItems.push(item);
                }
            });
        }
    }
    return audioTextItems;
}

function clearSearchedHighlight(that) {
    let audioTextListDOM = that.privates.audioTextListRef.current;
    if (audioTextListDOM) {
        let audioTextListElems = audioTextListDOM.getElementsByClassName('audio-text-list-item-text-container');
        for (let i = 0; i < audioTextListElems.length; i++) {
            Udesk.utils.dom.clearHighlight(audioTextListElems[i]);
        }
    }
}

export default AudioTextViewComponent;
