// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostIntelligentPartnerAiAnalysisTextToSqlResultBody,
    BaseResponseIntelligentPartnerAiTextToSqlFoundResponse,
} from '../../../../../types';

/**
 * 用户输入转SQL结果
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [POST] /intelligentPartner/ai/analysis/textToSql/result
 */
export function postIntelligentPartnerAiAnalysisTextToSqlResult(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultBody
): Promise<BaseResponseIntelligentPartnerAiTextToSqlFoundResponse>;
export function postIntelligentPartnerAiAnalysisTextToSqlResult(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerAiTextToSqlFoundResponse>;
export function postIntelligentPartnerAiAnalysisTextToSqlResult(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerAiTextToSqlFoundResponse> {
    return request<PostIntelligentPartnerAiAnalysisTextToSqlResultBody, never, never>(
        '/intelligentPartner/ai/analysis/textToSql/result',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerAiAnalysisTextToSqlResult',
        }
    );
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/textToSql/result',
        method: 'post',
    },
];
