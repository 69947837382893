// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetIntelligentExtractTasksResultQuery,
    BaseResponseListIntelligentExtractResultFoundResponse,
} from '../../types';

/**
 * 抽取结果列表
 *
 * @export
 * @tags 智能抽取接口
 * @link [GET] /intelligentExtractTasks/result
 */
export function getIntelligentExtractTasksResult(): Promise<BaseResponseListIntelligentExtractResultFoundResponse>;
export function getIntelligentExtractTasksResult(
    options: ApiOptions<never, GetIntelligentExtractTasksResultQuery>
): Promise<BaseResponseListIntelligentExtractResultFoundResponse>;
export function getIntelligentExtractTasksResult(
    options?: ApiOptions<never, GetIntelligentExtractTasksResultQuery>
): Promise<BaseResponseListIntelligentExtractResultFoundResponse> {
    return request<never, never, GetIntelligentExtractTasksResultQuery>(
        '/intelligentExtractTasks/result',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentExtractTasksResult' }
    );
}

export const meta = [
    { tags: ['智能抽取接口'], path: '/intelligentExtractTasks/result', method: 'get' },
];
