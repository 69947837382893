import Udesk from 'Udesk';
import React from "react";
import TaskCenterListRoute from './route';
import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import { Drawer, Divider, Button } from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import './style.scss';
import { inspect } from 'src/util/core';
export default class TaskCenterListTemplate extends TaskCenterListRoute {
    render() {
        let { data, columns, paging, visible, taskRef, detailData } = this.privates;
        // let { sdkOptions } = this.props;
        let { locales, actions } = this;
        // let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        return (
            <div ref={taskRef} className='qa-react-list-page-body-task-center-list'>
                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("manual:task:view")}>
                    <div className='qa-react-list-page-body-uploadDownload'>
                        <div className='qa-react-list-page-functions'></div>
                        <div className='qa-react-list-page-content' style={{ position: 'static' }}>
                            <Choose>
                                <When condition={data && data.length > 0}>
                                    <ReactTable
                                        horizontalScrollbar={true}
                                        showHorizontalScrollbar={true}
                                        columns={columns}
                                        items={data}
                                    />
                                </When>
                                <Otherwise>
                                    <div className='qa-react-list-page-content-no-value'>{locales.labels.noValue}</div>
                                </Otherwise>
                            </Choose>
                        </div>
                    </div>
                    <div className='qa-react-list-page-footer'>
                        <If condition={data && data.length > 0}>
                            {/* <ReactPager
                                showTotalPages={true}
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={0}
                                pageSize={paging.pageSize}
                                total={paging.total}
                                pageNum={paging.pageNum}
                                languageCode={sdkOptions.props.language}
                                pageChanged={actions.pageChanged}
                            /> */}
                            <UdeskPagination
                                current={paging.pageNum}
                                pageSize={paging.pageSize}
                                total={paging.total}
                                onChange={actions.pageChanged}
                            />
                        </If>
                    </div>
                    <Drawer
                        title={locales.labels.seeDetail}
                        placement='right'
                        closable={false}
                        onClose={actions.drawerClose}
                        visible={visible}
                        getContainer={false}
                    >
                        {
                            !_isEmpty(detailData) &&
                            (
                                <div>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.taskContent}</div>
                                        <div>{!_isEmpty(detailData) && detailData.name}</div>
                                    </div>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.submissionTime}</div>
                                        <div>{!_isEmpty(detailData) && detailData.createTime}</div>
                                    </div>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.startTime}</div>
                                        <div>{!_isEmpty(detailData) && detailData.startTime}</div>
                                    </div>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.endTime}</div>
                                        <div>{!_isEmpty(detailData) && detailData.endTime}</div>
                                    </div>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.runningTime}</div>
                                        <div>{!_isEmpty(detailData) && detailData.duration} {locales.labels.second}</div>
                                    </div>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.totalAmountOfData}</div>
                                        <div>{!_isEmpty(detailData) && detailData.processCount} {locales.labels.unit}</div>
                                    </div>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.resultsOfThe}</div>
                                        <div>{!_isEmpty(detailData) && detailData.result}</div>
                                    </div>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.operator}</div>
                                        <div>{!_isEmpty(detailData) && detailData.createUserName}</div>
                                    </div>
                                    <Divider>{locales.fix.taskDetails}</Divider>
                                    <div className='task-center-list-field-detail'>
                                        <div className='task-center-list-field-left'>{locales.fix.executiveFunction}</div>
                                        <div>{!_isEmpty(detailData) && detailData.action}</div>
                                    </div>
                                    {
                                        !_isEmpty(detailData) && (detailData.taskType === 2 || detailData.taskType === 4) && (
                                            <div className='task-center-list-field-detail'>
                                                <div className='task-center-list-field-left'>{locales.fix.qualityCheckTasks}</div>
                                                {
                                                    inspect(detailData.taskType === 4, () => (
                                                        <div>
                                                            <Button 
                                                                size='small' 
                                                                type='link' 
                                                                onClick={this.actions.goLink}>
                                                                {detailData.taskName}
                                                            </Button>
                                                        </div>
                                                    ), () => (
                                                        <div>{!_isEmpty(detailData) && detailData.taskName}</div>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        !_isEmpty(detailData) && detailData.taskType === 4 && (
                                            <div className='task-center-list-field-detail'>
                                                <div className='task-center-list-field-left'>{locales.fix.implementationDetails}</div>
                                                <div>{actions.fieldsFilterChangeCheck(detailData.reCheckCondition)}</div>
                                            </div>
                                        )
                                    }
                                    {
                                        !_isEmpty(detailData) && (detailData.taskType === 7 || detailData.taskType === 8 || detailData.taskType === 10) && (
                                            <div className='task-center-list-field-detail'>
                                                <div className='task-center-list-field-left'>{locales.fix.implementationDetails}</div>
                                                <div>{actions.fieldsDataChange(detailData.filterCondition)}</div>
                                            </div>
                                        )
                                    }
                                    {
                                        !_isEmpty(detailData) && (detailData.taskType === 2 || detailData.taskType === 4) && (
                                            <div className='task-center-list-field-detail'>
                                                <div className='task-center-list-field-left'>{locales.labels.filterConditions}</div>
                                                <div>{actions.fieldsFilterChange(detailData.baseFilter)}</div>
                                            </div>
                                        )
                                    }
                                    {
                                        !_isEmpty(detailData) && (detailData.taskType === 7 || detailData.taskType === 8 || detailData.taskType === 9 || detailData.taskType === 10) && (
                                            <div className='task-center-list-field-detail'>
                                                <div className='task-center-list-field-left'>{locales.labels.filterConditions}</div>
                                                <div>{detailData.filterCondition && (detailData.filterCondition.startTime + " - " + detailData.filterCondition.endTime)}</div>
                                            </div>
                                        )
                                    }
                                    {
                                        !_isEmpty(detailData) && detailData.taskType === 2 && detailData.intelligentCondition && (
                                            <div className='task-center-list-field-detail'>
                                                <div className='task-center-list-field-left'>{locales.fix.smartCondition}</div>
                                                <div>{detailData.intelligentCondition}</div>
                                            </div>
                                        )
                                    }

                                </div>
                            )
                        }
                    </Drawer>
                </If>
            </div >
        );
    }
}