export default {
  labels: {
    clear: "Esvaziar",
    refresh: "Atualizar",
    nextStep: "Próximo passo",
    prevStep: "Passo anterior",
    closeDriver: "Orientação de saída",
    complete: "Concluído",
    viewStatistics: "Veja as estatísticas",
    colon: "：",
    splitter: "/",
    remark: "Observações",
    correction: "Correção de erros",
    save: "Salvar",
    reset: "Redefinir",
    increase: "Adicionar",
    preview: "Pré-visualização",
    unit: "Artigo",
    cancel: "Cancelamento",
    all: "Todos",
    searchPlaceHolder: "Inserir uma consulta de palavra-chave",
    greaterThan: "Maior que",
    lessThan: "Menor que",
    second: "Segundos",
    description: "Descrição",
    column: "Coluna",
    pleaseChoice: "Por favor, escolha",
    filter: "Filtragem",
    saveAndSubmit: "Salvar e enviar",
    submit: "Enmissão",
    appeal: "Reclamação",
    review: "Revisão",
    choose: "Escolha",
    manage: "Gestão",
    delete: "Excluir",
    add: "Adicionado",
    create: "Novo",
    edit: "Editar",
    name: "Nome",
    describe: "Descrição",
    next: "Próximo passo",
    percent: "%",
    export: "Exportar",
    exportAll: "Exportar tudo",
    confirm: "OK",
    back: "Voltar",
    versionUpdate:
      "Fico feliz em informar que nosso site acaba de lançar uma nova versão! Para garantir seu uso normal, precisamos que você salve imediatamente o trabalho atual e, em seguida, atualize a página (clique no botão de atualização do navegador ou pressione a tecla F5) para que nossa nova versão funcione. Muito obrigado por sua cooperação!",
    more: "Mais",
    selected: "Selecionado",
    deduct: "Fivela",
    maxScore: "No máximo",
    scoreUnit: "Pontos",
    pointUnit: "Um",
    comma: "，",
    view: "Veja",
    batchingImport: "Importação",
    deleteContent:
      "Você confirma que deseja remover? Não será restaurado após a exclusão",
    import: "Importação",
    uploadFile: "Fazer upload de arquivos",
    downloadFile: "Exportar arquivos",
    upload: "Upload",
    click: "Clique",
    downloadTemplate: "Baixar o modelo",
    fillInTheForm: "Preencha por formato",
    UploadExcelfile:
      "Fazer o upload do Excel para concluir a importação em massa",
    uploadIdentical:
      "Se o nome da API existir, os dados existentes no sistema serão atualizados ao importar",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Aplicável",
    leftBracket: "(",
    rightBracket: ")",
    question: "Problema",
    answer: "Resposta",
    content: "Conteúdo",
    search: "Pesquisa",
    query: "Inquérito",
    cancelEdit: "Cancelar a edição",
    noValue: "Sem dados",
    selectAll: "Seleção completa",
    changeAGroup: "Mudar um grupo",
    arrow: " ---> ",
    saveReview: "Submissão de revisão",
    saveSpotCheck: "Apresentação de inspeção aleatória",
    rapidSampling: "Inspeção de amostragem rápida",
    file: "Arquivamento",
    download: "Download",
    lastPage: "Página anterior",
    nextPage: "Próxima página",
    batchActions: "Operação em lote",
    cancelDistribution: "Cancelar a atribuição",
    againDistribution: "Redistribuição",
    deleteRecord: "Excluir registros",
    oneOptionOpertor: "Inspetor de qualidade opcional",
    allOptionOpertor: "Todos os inspetores de qualidade",
    distributionInfoFirstOne: "Para confirmar",
    distributionInfoFirstTwo:
      "A reatribuição de registros? Por favor, escolha uma meta de alocação",
    distributionInfoSecondOne: "OK para cancelar",
    distributionInfoSecondTwo:
      "A informação do alocador para o registro da barra se tornará um registro de revisão não atribuído após o cancelamento.",
    distributionInfoSecondThree:
      "A informação do alocador para o registro do artigo se tornará um registro de amostragem não atribuído após o cancelamento.",
    distributionInfoThirdOne: "Existem registros selecionados",
    distributionInfoThirdTwo:
      "Os registros revisados, os registros revisados não serão processados.",
    distributionInfoThirdThree:
      "Os registros que foram amostrados não serão processados.",
    exportError: "Falha na exportação",
    exportSuccess: "Exportação bem sucedida",
    deleteRecordInfo:
      "Confirme que deseja excluir {0} registros? Os dados não serão recuperados após a exclusão",
    pleaseSetFilter: "Por favor, escolha uma condição de filtro",
    noCountAfterFilter: "Sem dados exportados, por favor, redefina os filtros",
    redo: "Reexecução",
    batchMove: "Mover em lote",
    websiteName: "Inspeção de qualidade inteligente",
    salesEnable: "Capacitação de vendas",
    systemName: "Inspeção de qualidade inteligente",
    initSystemError:
      "Uma anomalia de rede ou falha na inicialização da página, entre em contato com o atendimento ao cliente",
    day: "Dia",
    hour: "Horas",
    minute: "Pontos",
    questionMark: "？",
    personalSettings: "Configurações pessoais",
    logout: "Cancelamento",
    ok: "OK",
    saveAs: "Salvar como",
    pleaseEnter: "Por favor, insira",
    executeMessage: {
      executeSuccess: "Execução bem sucedida do artigo {0}",
      executeError: "{0} falha de execução",
    },
    field: "Campo",
    operator: "Operador",
    value: "Valor",
    placeholder: "Por favor, escolha",
    pleaseSelect: "Por favor, escolha",
    totalScore: "Pontuação total",
    hyphen: "-",
    semicolon: "；",
    wfm: "Agendamento inteligente",
    user: "Usuário",
    none: "Nenhum",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Salve o sucesso! Os funcionários relevantes entram em vigor após a atualização da página",
    saveAsName: "{0}(cópia)",
    systemInformation: "Ver os dados do sistema",
    billingCenter: "Centro de faturamento",
    warningMessage:
      "A cota restante é insuficiente, recarregue o mais rápido possível",
    fileTypeHolder:
      "O formato do arquivo está incorreto, faça o upload do arquivo de formato {0}",
    dataNo: "Sem dados",
    move: "Movimento",
    study: "Você tem um ponto de autoaprendizagem não marcado",
    studySuccess: "Rotulagem bem-sucedida",
    studyError: "Falha na marcação",
    editType: "Editar etiquetas de classificação",
    copyed: "Copiado para a área de transferência",
    clickToCopy: "Clique em Copiar",
    addFilterConditions: "Adicionar uma condição de filtro",
    filterConditions: "Condições de triagem",
    messageTime: "Hora da mensagem",
    messageType: "Tipo de mensagem",
    pushType: "Canais de mensagens",
    messageContent: "Conteúdo da mensagem",
    messageName: "Nome da mensagem",
    messageStatus: "Estado da mensagem",
    configuration: "Informações de configuração",
    sendTarget: "Enviar um alvo",
    guideUrl: "URL de inicialização",
    noticeDetail: "Detalhes da mensagem",
    action: "Operação",
    seeDetail: "Ver detalhes",
    theDay: "{0} dia",
    theLastDayOfMonth: "O último dia do mês",
    ignore: "Ignorar",
    seeNow: "Veja agora",
    exportConfirmText: {
      export: "Total de {0} dados, confirmando a exportação?",
      exportAll: "Confirmar a exportação de todo o conteúdo?",
    },
    samrTagAdd: "Adicionar um rótulo",
    deletePopconfirmContent: "Você confirma que deseja remover?",
    TheLastThirtyDays: "Últimos 30 dias",
    addNumber: "Mais",
    subNumber: "Menos",
    addStrategy: "Adicionar uma estratégia",
    minutes: "Minutos",
    status: "Ativar o estado",
    pushConfig: "Configuração de push",
    category: "Classificação",
    and: "E",
    interval: "Intervalo",
    switchOn: "Ligado",
    switchOff: "Fechar",
    batchDel: "Excluir em massa",
    exportTxt: "Exportar texto TXT",
    playSpeed: "Velocidade dupla",
    messageLogBtn: "Ver o tráfego/diálogo que acionou a notificação",
    dataLink: "Link de dados",
    yes: "Sim",
    no: "Não.",
    sentenceUnit: "Frase",
    appealDetail: "Detalhes do registro de reclamação",
    detail: "Detalhes",
    default: "Padrão",
    customer: "Clientes",
    agent: "Atendimento ao cliente",
    sort: "Ordenação",
    applyNow: "Aplicações",
    index: "Número de série",
    allColumns: "Colunas que podem ser adicionadas",
    selectedColumns: "Colunas adicionadas",
    syn: "Sincronização",
    saveAndNext: "Salvar e criar o próximo",
    viewReference: "Veja as referências",
    referenceDetail: "Detalhes da citação",
    goToQaList: "Vá para a inspeção de qualidade",
    goToCheck: "Vá para a inspeção aleatória",
    businessCard: "Cartão de visita",
    weapp: "Pequenos programas",
    redpacket: "Envelope vermelho",
    externalRedpacket: "Intercomunicação de envelopes vermelhos",
    sphfeed: "Número de vídeo",
    exportTaskCreated: "A tarefa de exportação foi criada com sucesso",
    plzGoTo: "Por favor, pule para",
    importTaskCreated: "A tarefa de importação foi criada com sucesso",
    taskCreated: "Criação de tarefas bem-sucedida",
    reply: "Resposta",
    replied: "Respondeu",
    release: "Publicação",
    expandMore: "Expanda outras respostas {0}",
    comment: "Comentários",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Por favor, pelo menos um {0}",
      canNotBeEmptyFormat: "{0} não pode ser vazio",
      repeatErrorTipFormat: "{0} Há uma repetição",
      getInformationFailedFormat:
        "A obtenção de informações {0} falhou, verifique a conexão de rede ou tente novamente mais tarde",
      deleteSuccessFormat: "{0} A remoção foi bem sucedida.",
      deleteFailedFormat: "{0} A exclusão falhou, tente novamente mais tarde.",
      saveSuccessFormat: "{0} Salva com sucesso.",
      saveFailedFormat: "{0} Salvar falhou, tente novamente mais tarde.",
      fileSuccessFormat: "{0} Arquivamento bem sucedido.",
      fileFailedFormat:
        "{0} O arquivamento falhou, tente novamente mais tarde.",
      exportSuccessFormat: "{0} Exportação bem sucedida",
      exportFailedFormat:
        "{0} A exportação falhou, tente novamente mais tarde.",
      submitSuccessFormat: "{0} O envio foi bem sucedido.",
      submitFailedFormat: "{0} falha no envio, tente novamente mais tarde.",
      confirmSuccessFormat: "{0} Confirmado com sucesso.",
      confirmFailedFormat: "{0} Confirme a falha, tente novamente mais tarde.",
      pleaseUploadFile: "Por favor, faça o upload do arquivo primeiro!",
      importFileSuccess: "A importação de arquivos foi bem sucedida!",
      importFildFailed: "Falha na importação de arquivos!",
      deleteSuccess: "A remoção foi bem sucedida!",
      deleteFailed: "A exclusão falhou, tente novamente mais tarde!",
      illegalTitle: "Permissões insuficientes",
      illegalMessage:
        "Você não tem algumas ou todas as permissões para a próxima etapa, entre em contato com o administrador para configurar, clique no botão OK para sair da inicialização",
      illegalMessageCurrent:
        "Você não tem algumas ou todas as permissões para a etapa atual, entre em contato com o administrador para configurar, clique no botão OK para sair da inicialização",
      skipMessage:
        "Se você quiser pular esta etapa por um tempo, clique em Pular",
      errorMsg500:
        "A solicitação do servidor falhou, verifique sua operação ou atualize novamente",
      errorMsg404: "Ooops... a página que você está procurando está faltando",
      errorMsg400:
        "A solicitação do servidor falhou, verifique seus dados e tente novamente",
      pageOverFlow:
        "Atualmente, suporta apenas a exibição de 10.000 artigos, defina condições de filtro para ver o excesso",
      isHandAddSmartTag: "É um rótulo inteligente adicionado manualmente",
      defaultGradeMustAtLast:
        "A classificação padrão deve ser colocada no final",
      AutomaticAllocationInspectorsIsNull:
        "Por favor, vá para a página de informações básicas para adicionar um inspetor de qualidade",
      timePickerTips:
        "Para não afetar seu uso normal, 23:00 ~ 2:00 é o tempo de manutenção do sistema e não executa tarefas cronometradas.",
      batchDeleteInfo:
        "Exclusão bem-sucedida de {0} barras e falha {1} barras. O motivo da falha é que os dados foram usados.",
      batchDeleteTips:
        "Marque um total de {0} dados para confirmar a exclusão?",
      correctionSuccess: "Correção de erros bem-sucedida",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError:
            "Adicionar até 10 formulários de inspeção de qualidade",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "A soma dos itens do indicador {0} deve ser 100",
          banProjectMaxErrorTip: "Adicionar até 2000 itens proibidos",
          deadlyProjectMaxErrorTip: "Adicione até 500 itens fatais",
        },
      },
    },
    info: {
      creatSuccess: "Criação de sucesso",
      creatError: "Falha na criação",
      editSuccess: "Modificações bem-sucedidas",
      editError: "Falha na modificação",
      deleteSuccess: "Exclusão bem sucedida",
      addSuccess: "Novo sucesso",
      deleteError: "A exclusão falhou",
      conditionError: "Erro de expressão lógica do operador",
      customJudgeLogic:
        "Erro de expressão lógica de combinação de condição personalizada",
      exportError: "Falha na exportação",
      searchError: "Falha na triagem",
      loadError: "{0} falha de carregamento",
      saveSuccess: "Salve o sucesso",
      saveError: "Salvar falha",
      fileSuccess: "Arquivamento de sucesso",
      fileError: "Falha no arquivamento",
      cannotBeNull: "{0} não pode ser vazio!",
      pleaseSearchAndSelect: "Por favor, insira uma pesquisa de palavra-chave",
      clickAndToggle: "Clique em Escolher para mudar",
      deleteWarning:
        "{0} A exclusão não será restaurada, você tem certeza de que deseja continuar?",
      getOssAuthFailed:
        "A obtenção da autorização OSS falhou, verifique a conexão de rede ou tente novamente mais tarde.",
      getInsightAuthFailed:
        "A obtenção da autorização do Insight falhou. Verifique a conexão de rede ou tente novamente mais tarde.",
      pictureUploadFailed: "Falha no upload da imagem",
      pictureUploadFailedSize:
        "O tamanho da imagem excede o limite, o upload da imagem falha",
      wecomTagsSyncInfo: "Última atualização:{0}, Sincronizar {1} informação",
      collectSuccess: "Sucesso na coleção",
    },
  },
  components: {
    common: {
      qcReportEcharts: {
        errorMessage: "Falha na obtenção de dados do relatório",
      },
      waveAudio: {
        noWaveMessage:
          "Para manter a sua experiência, a forma de onda não é exibida por mais de {0} minutos de gravação, você pode reproduzir diretamente",
      },
      customFilter: {
        del: "Excluir",
        edit: "Editar",
        new: "Filtragem personalizada",
        apply: "Aplicações",
        cancel: "Cancelamento",
        onlyApply: "Apenas aplicável",
        ohterSave: "Salvar como",
        save: "Salvar",
        customFilter: "Triagem comum",
        reset: "Redefinir",
        holderKeyWord: "Digite a palavra-chave",
        customFilterRule: "Filtrar regras lógicas:",
        customFilterName: "Nome do filtro:",
        holderCustomFilterName: "Insira o nome do filtro",
        manualConfiguration: "Configuração manual",
        holderCustomRule: "Insira uma condição lógica personalizada",
        example: "Exemplo",
        dragConfiguration: "Configuração de arrastar",
        condition: "Condições",
        operation: "Operações",
        infoDel: "Excluir o conteúdo arrastado para fora da área",
        toConfigure: "Configuração",
        holderCustomFilterNameLength:
          "O comprimento máximo do nome do filtro é 10",
        customFilterNameNone: "O nome do filtro não pode estar vazio",
        and: "E",
        or: "Ou",
        not: "Não",
        delSuccess: "Exclusão bem sucedida",
        saveSuccess: "Salve o sucesso",
        updateSuccess: "Modificações bem-sucedidas",
        customFilterCondition: "Condições de triagem",
        conditionRule: "Regras condicionais",
        none: "Nenhum",
        filterPlaceholder: "Digite a palavra-chave",
      },
      labelTabs: {
        addThis: "Adicionar a este",
        goThis: "Mover para este",
        saveGo: "Salvar e treinar",
      },
      datePicker: {
        today: "Hoje",
        yesterday: "Ontem",
        theDayBeforeYesterday: "Anteontem",
        thisWeek: "Esta semana",
        lastWeek: "Semana passada",
        thisMonth: "Este mês",
        lastMonth: "Mês passado",
        lastSevenDay: "Últimos 7 dias",
        lastThirtyDay: "Últimos 30 dias",
        thisQuarter: "Este trimestre",
        lastQuarter: "O último trimestre",
        thisYear: "Este ano",
        lastYear: "Ano passado",
        lastYearFromToday: "Ano recente",
      },
      pagination: { page: "Artigo {0} no total, página {1}" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Carregar mensagens anteriores",
          bottom: "Carregar mais mensagens",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Tarefa de inspeção de qualidade",
        informationCollectTasksManageTitle: "Missão de Coleta",
        customerServiceCenterTitle: "Centro de atendimento ao cliente",
        informationTitle: "Centro de dados",
        intelligentAnalysisTitle: "Análise inteligente",
        semanticIntelligence: "Inteligência Semântica",
        trainingCenterTitle: "Centro de treinamento",
        adminTitle: "Gerenciamento de sistemas",
      },
      qualityCheckingTask: {
        title: "Inspeção de qualidade de chamada",
        index: {
          tipOne:
            "A inspeção de qualidade da chamada é usada para avaliar a qualidade do trabalho do call center dos funcionários. Você pode combinar seu próprio negócio para configurar o formulário de inspeção de qualidade adequado",
          tipTwo:
            "Regras adequadas podem ser definidas para tarefas de inspeção de qualidade, incluindo triagem, amostragem, etc. Depois que o inspetor de qualidade pontua, o atendimento ao cliente pode verificar os resultados da inspeção de qualidade e apelar mais adiante,",
          addTaskTitle: "Adicionar tarefas de inspeção de qualidade",
          deleteTipTitle: "Excluir tarefas de inspeção de qualidade",
          deleteTipContent:
            "A tarefa de inspeção de qualidade não pode ser restaurada após a exclusão. Confirme a exclusão?",
          checkingTask: "Tarefa de inspeção de qualidade",
          checkingTaskList: "Lista de tarefas de inspeção de qualidade",
          formList: "Lista de formulários",
          viewHelpDocuments: "Ver a documentação de ajuda",
        },
        manage: {
          taskSetting: {
            title: "Configurações da tarefa",
            form: "Formulário",
            basicInfo: "Informações básicas",
            visibleRange: "Faixa visível",
            visibleRangeTip:
              "O atendimento ao cliente selecionado e a equipe de atendimento ao cliente podem verificar a tarefa de inspeção de qualidade e, além disso, podem combinar as configurações de autoridade para fornecer o líder da equipe de atendimento ao cliente, atendimento ao cliente, inspetor de qualidade, etc.",
            automaticSampling: "Amostragem automática",
            automaticSamplingTip:
              "Você pode definir regras para amostragem automática, para que o sistema adicione tarefas de inspeção de qualidade regularmente de acordo com as regras que você definiu, como tirar 5% das chamadas da semana passada todas as segundas-feiras",
            samplingRate: "Frequência de amostragem",
            samplingTime: "Tempo de amostragem",
            samplingRange: "Faixa de amostragem",
            samplingRule: "Regras de amostragem",
            fillterCondition: "Condições de triagem",
            workflow: "Fluxo de trabalho de inspeção de qualidade",
            appealsTime: "Tempo de recurso permitido",
            appealsTimeTipOne: "Após o envio da pontuação",
            appealsTimeTipTwo: "Dentro dos dias",
            appealsTimeTipThree:
              'Após o tempo de recurso permitido, o status de inspeção de qualidade do registro é automaticamente modificado para "confirmado"',
            auditTime: "Tempo de processamento de auditoria",
            auditTimeTipOne: "Depois de registrar uma reclamação",
            auditTimeTipTwo: "Dentro dos dias",
            auditTimeTipThree:
              'Após o tempo de processamento de auditoria, o registro mantém a pontuação original e o status de inspeção de qualidade é automaticamente modificado para "confirmado"',
            noAppealsMode: "Sem modo de recurso",
            appealsMode: "Modo de recurso permitido",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Atendimento ao cliente",
            customerServicesGroup: "Grupo de atendimento ao cliente",
            surveyList: "Lista de avaliação",
            customFields: "Campos personalizados",
            fieldConfig: "Configuração de campo",
            from: "De",
            owner: "Responsável",
            inspectorAndReviewerAuthority:
              "Autoridade de inspeção de qualidade e revisão",
            inspector: "Inspetor de qualidade",
            reviewer: "Reinspetor",
            inspectorTip: "O inspetor de qualidade pode avaliar a tarefa",
            reviewerTip: "Os revisores podem avaliar a tarefa",
            dataStatisticsAuthority: "Permissões de estatísticas de dados",
          },
          samplingRecords: {
            title: "Registro de amostragem",
            index: {},
            manualSampling: {
              title: "Amostragem",
              fillterCondition: "Condições de triagem",
              samplingRule: "Regras de amostragem",
              agent: "Atendimento ao cliente",
              type: "Tipo",
              result: "Resultados",
              evaluate: "Avaliação",
              relayNumber: "Número de retransmissão",
              source: "Fonte",
              task: "Missão",
              queueState: "Estado de fila",
              callHanger: "O lado da chamada",
              queueTime: "Filas demoradas",
              talkTime: "Dduração da chamada",
              bellTime: "A duração do sino",
              recordCountTipOne: "Total",
              recordCountTipTwo: "Registro de barras",
              customerServices: "Atendimento ao cliente",
              customerServicesGroup: "Grupo de atendimento ao cliente",
              surveyList: "Lista de avaliação",
              customFields: "Campos personalizados",
              fieldConfig: "Configuração de campo",
              defaultFields: "Campos padrão",
            },
          },
          taskList: {
            title: "Lista de tarefas",
            index: {
              batchDelete: "Excluir em massa",
              batchSubmit: "Enmissão em massa",
              batchConfirm: "Confirmação em lote",
              itemFields: "Campos de projeto de pontuação",
              categoryFields: "Campo de classificação de segundo nível",
              forbidsAndDeadlies: "Item proibido e letal",
              scoreFieldList: "Lista de campos de item de classificação",
              customerName: "Nome do cliente",
              cellphone: "Telefone",
              email: "Caixa de correio",
              organization: "Empresa",
              description: "Descrição",
              tags: "Rótulos",
              taskUserRole: "Papel do usuário da tarefa atual",
            },
            workBench: {
              title: "Detalhes do registro",
              checkForm: "Formulário de inspeção de qualidade",
              waitEvaluate: "A ser avaliado",
              alreadyEvaluate: "Avaliado",
              waitSubmit: "A ser submetido",
              alreadySubmit: "Enviado",
              waitConfirm: "A confirmar",
              alreadyConfirm: "Confirmado",
              waitReview: "A ser revificado",
              alreadyReview: "Reverificado",
              alreadyDone: "Concluído",
              comment: "Comentários de avaliação",
              totalScore: "Pontuação total",
              score: "Pontos",
              remark: "Observações",
              changeRecords: "Registro de mudança",
              subject: "Tema",
              secondLevelScore: "Pontuação secundária",
              deadlyList: "Fatal",
              forbidList: "Item proibido",
              addToCheckTask: "Adicionar à tarefa de inspeção de qualidade",
              submitCheckRemark:
                "Apresentado a avaliação de inspeção de qualidade",
              changeCheckRemark: "Avaliação de inspeção de qualidade revisada",
              doneCheckRemark: "Avaliação de inspeção de qualidade concluída",
              submitCheckReview:
                "Enviado para revisão de inspeção de qualidade",
              submitCheckAppeal:
                "Apresentou uma reclamação de inspeção de qualidade",
              saveFlowSuccess: "Avaliação de sucesso.",
              saveFlowFailed: "A avaliação falhou, tente novamente mais tarde.",
              FormDetail: "Detalhes do formulário",
              callDetail: "Detalhes do registro de chamadas",
              sampleCalllogDetail: "Detalhes do registro",
              appealReasonTip:
                "Por favor, insira o motivo da reclamação (obrigatório)",
              appeal: "Reclamação",
              appealReason: "Motivo da reclamação",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} formulário de inspeção de qualidade",
        basicInformationTitle: "Informações básicas",
        indicatorsProjectTitle: "Itens de indicador",
        specialProjectTitle: "Item especial",
        totalScore: "Pontuação total",
        indicatorsName: "Nome do indicador",
        score: "Pontuação",
        singleScore: "Pontos individuais",
        weight: "Peso",
        maxDeductScore: "Mais pontos deduzidos",
        add: "Aumento",
        detail: "Detalhes do formulário",
        list: {
          title: "Formulário de inspeção de qualidade",
          tipOne:
            "Configurar o formulário de inspeção de qualidade é o primeiro passo no trabalho de inspeção de qualidade. Você pode combinar os negócios da empresa para configurar o formulário de inspeção de qualidade mais adequado.",
          tipTwo:
            "Apoiamos uma variedade de formulários, como classificação direta, pontuação de peso e sistema de dedução. É recomendável que você adicione ao sistema depois de configurar as regras.",
        },
        basicInformation: {
          formTemplate: "Modelo de formulário",
          directScore: "Classificação direta",
          weightedScore: "Pontuação de peso",
          deductScore: "Dedução de pontos",
          directScoreSecondLevel: "Classificação direta-Nível 2",
          weightedScoreSecondLevel: "Pontuação de peso-Nível 2",
          deductScoreSecondLevel: "Dedução-Nível 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Item proibido",
          banProjectTip:
            "Pode ser usado para verificar a pontuação da inspeção de qualidade, pode ser considerado uma violação ou pode ser considerado um suplemento às observações e não afeta a pontuação real.",
          deadlyProject: "Fatal",
          deadlyProjectTip:
            "O item fatal pode ser usado para marcar a pontuação de inspeção de qualidade. Se o item fatal for marcado, a tarefa de inspeção de qualidade será avaliada diretamente em 0 pontos",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Inspeção de qualidade de chamada",
          entiretyReportTitle: "Relatório geral",
          serviceGroupReportTitle:
            "Relatório do grupo de atendimento ao cliente",
          serviceReportTitle: "Relatório de atendimento ao cliente",
          entiretyReport: {
            pandect: "Visão geral",
            contrast: "Contraste",
            trend: "Tendências",
            analyze: "Análise",
            totalScore: "Pontuação total",
            banProject: "Item proibido",
            deadlyProject: "Fatal",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Lista de resultados de inspeção de qualidade",
          taskSelectPlaceholder: "Escolha a tarefa de inspeção de qualidade",
          agentSelectPlaceholder: "Escolha o atendimento ao cliente",
          refresh: "Atualizar",
          all: "Todos",
          unread: "Não lido",
          inComplaint: "No recurso",
          reviewed: "Reverificado",
          totalScore: "Revisão geral",
          detail: "Detalhes da inspeção de qualidade",
          audioText: "Texto de gravação",
          callInfo: "Informações sobre chamadas",
          ticketInfo: "Informações sobre a folha de trabalho",
          wechatInfo: "Informações da sessão",
          dialogText: "Texto da conversa",
          dialogInfo: "Informação de diálogo",
          customer: "Clientes",
          agent: "Atendimento ao cliente",
          none: "Sem dados de inspeção de qualidade",
          appealSuccess: "Reclamação de sucesso!",
          appealFailed: "O recurso falhou, tente novamente mais tarde!",
          pointListIsNull:
            "O ponto de inspeção de qualidade de recurso não pode estar vazio!",
          pleaseSelectPoint:
            "Por favor, escolha o ponto de inspeção de qualidade!",
          unsupportedFormat: "O formato não é suportado por enquanto",
          shardingInformation: "A seguir estão os fragmentos {0}",
          smartTags: "Rótulos inteligentes",
          businessRecords: "Registros de negócios",
          selectedPointsWarning:
            "Marque os itens de inspeção de qualidade que deseja apelar, especifique as declarações relevantes para os itens de recurso e clique em Enviar.",
          applyReason: "Motivo da reclamação (até 50 palavras)",
          chatMessageTypes: {
            all: "Todos",
            text: "Texto",
            image: "Imagens",
            agree: "Consentimento para o conteúdo da sessão",
            video: "Vídeo",
            voice: "Voz",
            file: "Arquivo",
            emotion: "Expressão",
            link: "Link",
            chatrecord: "Histórico de bate-papo",
            mixed: "Mensagens híbridas",
            default: "Mensagens não suportadas",
            deleteFriend: "Remover amigos",
            redpacket: "Envelope vermelho",
            card: "Cartão de visita",
            disAgree: "Não concorde com o conteúdo da sessão",
            weapp: "Pequenos programas",
            externalRedpacket: "Intercomunicação de envelopes vermelhos",
            meetingVoicevCall: "Mensagem de chamada",
            uRL: "Página da Web",
            replyTimeout: "Resposta de tempo limite",
            sphfeed: "Número de vídeo",
            chatRecordTitle: "Histórico de bate-papo",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Pontuação total",
            actualScore: "Classificação",
            actualGrade: "Classificação",
            appeal: "Reclamação",
            remark: "Observações manuais",
            none: "Não há dados de detalhes de inspeção de qualidade",
            pointEdit:
              "Classificação de edição de pontos de inspeção de qualidade",
          },
          changeList: {
            title: "Registro de mudança",
            user: "Usuário",
            point: "Ponto de inspeção de qualidade",
          },
          relatedRecord: {
            title: "Registros relacionados",
            qualityList: "Tarefa de inspeção de qualidade",
            none: "Nenhum",
            check: "Veja",
            loadMore: "Carregar mais",
          },
        },
      },
      grade: {
        title: "Classificação de inspeção de qualidade",
        subTitle:
          "A classificação de inspeção de qualidade é usada para classificar os objetos de inspeção de qualidade após a conclusão da pontuação de inspeção de qualidade. Por exemplo, pode ser configurada com menos de 60 pontos não qualificados, 60-85 pontos são bons e acima de 80 pontos são excelentes",
        list: {
          up: "Mover para cima",
          down: "Mover para baixo",
          del: "Excluir",
          case: "Junte-se automaticamente à biblioteca de casos",
          orderCase: "Biblioteca de casos padrão",
          info: "A classificação padrão não tem condições de correspondência e não pode ser excluída. Se você não atender a nenhuma das classificações acima, receberá a classificação padrão",
          add: "Adicionar uma classificação",
          save: "Salvar",
          close: "Cancelamento",
          max: "Até 20 classificações de inspeção de qualidade podem ser configuradas",
          name: "Sem nome",
          success: "Salve o sucesso",
        },
      },
      qualityInspectionList: {
        title: "Lista de inspeção de qualidade",
        fastFilter: "Violações de inspeção de qualidade",
        fastFilterTip:
          "O sistema de redução de pontos é uma violação, e o sistema de bônus é uma violação",
        titleWechat: "Lista de análise",
        titleCollect: "Lista de aquisição",
        status: "Estado de análise",
        reQualityInspectionConfig: "Opção de tarefa de re-inspeção",
        pleaseSelectRecheckPoints:
          "Por favor, escolha o ponto de inspeção de qualidade que precisa de uma nova contabilidade",
        beginReQualityInspection: "Início da execução",
        cancelReQualityInspection: "Cancelar uma tarefa",
        reQualityInspection: "Re-inspeção de qualidade",
        reQualityInspectionWechat: "Reanálise",
        reQualityInspectionUnderWay: "Na missão de re-inspeção...",
        reQualityInspectionUnderWayTip:
          "Deixar a página depois de fechar a janela não afeta o andamento da tarefa",
        reQualityInspectionDone: "A reinspeção de qualidade foi concluída",
        reQualityInspectionDoneTip:
          "Agora que a reinspeção foi concluída com sucesso, clique no botão de confirmação",
        endReQualityInspection: "Encerre a tarefa",
        alreadyDoneTip: "Artigo concluído {0}",
        totalTip: "Total de {0} barras",
        endReInspectionSuccess:
          "A tarefa de reinspeção de qualidade foi encerrada",
        closeWindow: "Fechar a janela",
        endReInspectionFailed:
          "A tarefa de reinspeção de qualidade falhou, por favor, tente novamente mais tarde!",
        detail: {
          title: "Detalhes da inspeção de qualidade",
          wechatTitle: "Detalhes da análise",
          titleCollect: "Detalhes da coleção",
          callIndex: "Chamada de associação",
          textIndex: "Diálogo conectado",
          fileConfirm: "Confirmar o arquivamento?",
          fileConfirmDesc:
            "Não será possível editar a pontuação de inspeção de qualidade após o arquivamento, por favor confirme se o arquivamento",
        },
        sysCloumnsContal: "Gerenciamento de campos de coluna do sistema",
        personCloumnsContal: "Gerenciamento de campos de coluna pessoal",
      },
      spotCheckList: {
        title: "Lista de inspeção aleatória",
        onlyMyTip: "Veja apenas minhas inspeções aleatórias",
        plInspector: "Por favor, escolha um inspetor de amostragem",
        allInspector: "Ver tudo",
        seInspector: "Verifique o inspetor de amostragem designado",
        myInspector: "Apenas veja o meu",
        noInspector: "Verifique os inspetores de amostragem não designados",
        detail: { title: "Detalhes da inspeção aleatória" },
      },
      reviewList: {
        title: "Lista de revisão",
        onlyMyTip: "Apenas veja minha revisão",
        plInspector: "Por favor, escolha um revisor",
        allInspector: "Ver tudo",
        seInspector: "Ver o revisor designado",
        myInspector: "Apenas veja o meu",
        noInspector: "Veja os revisadores não atribuídos",
        detail: { title: "Detalhes da revisão" },
      },
      caseBaseList: {
        title: "Biblioteca de casos",
        del: "Excluir",
        delTitle: "Todo o conteúdo não é salvo após a exclusão",
        cancel: "Cancelamento",
        actions: "Operação",
        seeCaseBase: "Veja o caso",
        seeDetail: "Ver detalhes",
        edit: "Editar",
        save: "Salvar",
        addCaseBase: "Nova biblioteca de casos",
        export: "Exportar",
        ok: "OK",
        tip: "Os dados típicos de chamadas e conversas na lista de inspeção de qualidade podem ser salvos na biblioteca de casos para facilitar o aprendizado e o compartilhamento",
        detail: {
          choiceCaseBase: "Por favor, escolha o caso",
          delCaseBase: "Caso de exclusão",
          addCaseBase: "Adicionar um caso",
          successed: "Salve o sucesso",
          info: "Por favor, salve ou cancele o conteúdo que está editando primeiro",
          caseType: "Selecione a biblioteca de casos a ser adicionada",
        },
      },
      testSetList: {
        title: "Conjunto de testes de inspeção de qualidade",
        tip: "Os dados da inspeção aleatória do inspetor de qualidade serão armazenados na coleção de teste de inspeção de qualidade, o que é conveniente para otimizar a configuração das condições de inspeção de qualidade e regras de inspeção de qualidade para alguns erros de inspeção de máquina comparados após a inspeção aleatória",
        refresh: "Atualizar",
        reQualityInspectionUnderWay: "Reavaliar a missão...",
        reQualityInspectionUnderWayTip:
          "Deixar a página depois de fechar a janela não afeta o andamento da tarefa",
        reQualityInspectionDone: "Reavaliação concluída",
        reQualityInspectionDoneTip:
          "A reavaliação foi concluída com sucesso, clique no botão de confirmação",
        endReQualityInspection: "Encerre a tarefa",
        alreadyDoneTip: "Artigo concluído {0}",
        totalTip: "Total de {0} barras",
        endReInspectionSuccess: "Reavaliar a missão encerrada",
        closeWindow: "Fechar a janela",
        endReInspectionFailed:
          "Reavaliar o fracasso da missão, tente novamente mais tarde!",
        recheckPoint:
          "Recalcule a pontuação e a taxa de precisão do ponto de inspeção de qualidade",
        recheckPointTitle: "Reavaliar as opções",
        delete: {
          reQualityInspectionUnderWay: "Excluir tarefas em massa...",
          reQualityInspectionUnderWayTip:
            "Deixar a página depois de fechar a janela não afeta o andamento da tarefa",
          reQualityInspectionDone: "Tarefa de exclusão em massa concluída",
          reQualityInspectionDoneTip:
            "A exclusão em massa foi concluída com sucesso, clique no botão de confirmação",
          endReQualityInspection: "Encerre a tarefa",
          alreadyDoneTip: "Artigo concluído {0}",
          totalTip: "Total de {0} barras",
          endReInspectionSuccess: "A tarefa de exclusão em massa foi encerrada",
          closeWindow: "Fechar a janela",
          endReInspectionFailed:
            "A tarefa de exclusão em massa falhou, tente novamente mais tarde!",
        },
        reultList: {
          assessmentTime: "Tempo da última avaliação",
          assessmentAll: "Avaliação total",
          strip: "Artigo",
          spot: "Ponto",
          deviation: "Desvio",
          accuracyRate: "Taxa de precisão",
          info: "Sem resultados de avaliação, avalie",
          onlyDeviationInfo: "Veja apenas os registros de desvios",
          againAssessment: "Reavaliação",
          screen: "Filtragem",
          batchDelete: "Remoção de lote",
          matching: "Correspondência",
          ok: "Sim",
          noCancel: "Não.",
        },
      },
      smartWordsLibrary: {
        title: "Thesaurus inteligente",
        tip1: "Sinônimos: usado para reduzir a quantidade de treinamento de rótulos semânticos, frases semelhantes de sinônimos sem repetição de treinamento",
        tip2: "Termos profissionais: Otimização de transliteração e otimização de compreensão de linguagem natural para nomes de empresas imprecisos, nomes de produtos, vocabulário da indústria, etc., para melhorar a precisão do reconhecimento e a precisão da análise",
        tip3: "Palavras de preocupação: preste atenção às palavras que você deseja prestar atenção na análise de nuvem de palavras para ajudar a análise de pontos de acesso da IA a se adequar aos negócios atuais",
        tip4: "Palavras de bloqueio: bloqueie as palavras que você não deseja prestar atenção na análise de nuvem de palavras para ajudar a análise de pontos de acesso da IA a se adequar aos negócios atuais",
        tip5: "Peso da palavra: usado para otimizar a correção de erros no reconhecimento de rótulos semânticos, modificando o peso de influência de uma palavra em uma frase, otimizando o efeito de reconhecimento semântico em uma cena específica de acordo com as necessidades",
        tip6: "Dados de corpus: insira o texto de parágrafo grande para suas respectivas cenas de negócios para correção de erros e treinamento de ASR e melhore a precisão da transcrição de ASR",
        upload: {
          uploadSynonymWords: "Importação de sinônimos",
          uploadProfessionalWords: "Importar substantivos profissionais",
          uploadAttentionWords: "Importar palavras de interesse",
          uploadIgnoreWords: "Importar palavras ignoradas",
          uploadWordsWeight: "Importar palavras de peso",
          uploadTypo: "Importar um conjunto de palavras com erros de digitação",
          uploadCorpusData: "Importar dados de corpus",
          uploadCorpusContent: "Importar conteúdo de dados corpus",
          firstUploadStep:
            "1. Faça o download do modelo, preencha de acordo com o formato do modelo",
          downloadTemplateMessage: "Faça o download do modelo:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Clique no botão Importar para fazer upload do arquivo EXCEl que esteja em conformidade com o formato do modelo",
          secondUploadCorpusDataStep:
            "2. Clique no botão Importar para fazer upload do arquivo TXT em conformidade com o formato do modelo",
          postUploadDataFail: "Falha ao enviar dados de upload",
          pleaseUploadTemplate: "Faça o upload do arquivo EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "Os modelos de arquivo só podem estar no formato JSON ou xlsx",
          templateNeedTxt:
            "O modelo de arquivo de dados corpus só pode ser um arquivo TXT",
          uploadSuccess: "Importação bem-sucedida",
          uploadFailed: "Falha na importação",
          downloadError: "Falha no download",
          downLoadTxtTitle: "Conteúdo corpus. txt",
        },
        navTabNames: {
          synonymWords: "Sinônimos",
          professionalWords: "Substantivo profissional",
          attentionWords: "Palavras de preocupação",
          ignoreWords: "Ignorar a palavra",
          wordsWeight: "Peso da palavra",
          corpusData: "Dados de corpus",
          typoLibrary: "Conjunto de palavras com erros de digitação",
        },
        synonymWords: {
          createSynonymWords: "Novos sinônimos",
          editSynonymWords: "Editar sinônimos",
          addSynonymWords: "Adicionar sinônimos",
          pleaseEnterStandardWords: "Por favor, insira a palavra padrão",
          synonymWordsExists: "O sinônimo já existe e não pode ser adicionado",
          synonymWordsCanNotNull:
            "Os sinônimos adicionados não podem estar vazios",
          pleaseAddSynonymWords: "Por favor, adicione sinônimos",
          addSuccess: "Novo sucesso",
          editSuccess: "Modificações bem-sucedidas",
          deleteSuccess: "Exclusão bem sucedida",
          deleteError: "A exclusão falhou",
          required: "*",
        },
        professionalWords: {
          create: "Novo substantivo profissional",
          edit: "Editor de substantivos profissionais",
          addTargetWords: "Adicionar uma palavra de substituição",
          targetWordsExists:
            "A palavra de substituição já existe e não pode ser adicionada",
          targetWordsCanNotNull:
            "A palavra de substituição adicionada não pode estar vazia",
          pleaseAddTargetWords:
            "Por favor, adicione uma palavra de substituição",
          pleaseEnter: "Por favor, insira um substantivo profissional",
          wordsExists: "O substantivo profissional já existe",
          createSuccess: "Adição de sucesso",
          editSuccess: "Modificações bem-sucedidas",
          deleteSuccess: "Exclusão bem sucedida",
          deleteError: "A exclusão falhou",
          saveError: "Salvar falha",
          addTargetWordsMaxNumberError:
            "Palavras de substituição podem ser adicionadas até 100",
          addHomophonyWordsMaxNumberError:
            "Palavras de bloqueio podem ser adicionadas até 100",
        },
        attentionWords: {
          create: "Novas palavras de preocupação",
          edit: "Preocupação com a edição de palavras",
          pleaseEnter: "Por favor, insira uma palavra de interesse",
          wordsExists: "A palavra de preocupação já existe",
          createSuccess: "Adição de sucesso",
          editSuccess: "Modificações bem-sucedidas",
          deleteSuccess: "Exclusão bem sucedida",
          deleteError: "A exclusão falhou",
        },
        ignoreWords: {
          create: "Nova palavra de negligência",
          edit: "Ignorar a edição de palavras",
          pleaseEnter: "Por favor, insira a palavra de negligência",
          wordsExists: "A palavra ignorada já existe",
          createSuccess: "Adição de sucesso",
          editSuccess: "Modificações bem-sucedidas",
          deleteSuccess: "Exclusão bem sucedida",
          deleteError: "A exclusão falhou",
        },
        wordsWeight: {
          createWordsWeight: "Nova palavra de peso",
          editWordsWeight: "Editar palavras de peso",
          addWordsWeight: "Adicionar uma palavra de peso",
          pleaseEnterWeightWords: "Por favor, insira a palavra de peso",
          pleaseEnterWeight: "Por favor, insira o peso",
          weightNeedInZeroToHundred: "O peso precisa ser 0 ~ Entre 100",
          synonymWordsExists: "A palavra de peso já existe",
          weightNeedNumber: "O peso requer formato inteiro",
          standardWeightInformation:
            "O peso de referência é 50, quanto maior o peso, maior a influência na análise",
          createSuccess: "Adição de sucesso",
          editSuccess: "Modificações bem-sucedidas",
          deleteSuccess: "Exclusão bem sucedida",
          deleteError: "A exclusão falhou",
        },
        corpusData: {
          createCorpusData: "Novos dados corpus",
          editCorpusData: "Editar dados corpus",
          addCorpusData: "Adicionar dados corpus",
          pleaseEnterCorpusDescription:
            "Por favor, insira a descrição do corpus",
          pleaseEnterCorpusContent: "Por favor, insira o conteúdo do corpus",
          synonymWordsExists: "Os dados do corpus já existem",
          CorpusContentWordsOverSetting:
            "Estatísticas de palavras de conteúdo mais de 20000 palavras,",
          download: "Download",
          upload: "Upload",
          see: "Veja",
          canNotSave:
            "Use a função de importação, caso contrário, não será possível salvar",
          createSuccess: "Adição de sucesso",
          editSuccess: "Modificações bem-sucedidas",
          deleteSuccess: "Exclusão bem sucedida",
          deleteError: "A exclusão falhou",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Palavras corretas",
            wrongWord: "Palavras erradas",
            wrongWordCount: "Número de palavras erradas",
            wrongWordTip: "Pressione a tecla Enter para adicionar",
            status: "Eficácia",
          },
          create: "Novo conjunto de palavras com erros de digitação",
          edit: "Editar um conjunto de palavras com erros de digitação",
          tip: 'O conjunto de palavras com erros de digitação é usado para otimizar o efeito de "detecção de erros de digitação". Por exemplo, se "reembolso" for enviado incorretamente como "largura de retorno", você pode definir erros comuns e palavras corretas por meio dessa função, e preste atenção para que ele entrará em vigor no dia seguinte após o salvamento.',
          onlyChinese: "Suporta apenas a entrada de palavras chinesas",
          wrongWordLengthErr:
            "O comprimento de cada palavra errada deve ser 2 ~ 10 palavras",
        },
      },
      semanticTags: {
        title: "Rótulos semânticos",
        current: "Rótulos semânticos atuais",
        reference1: "Tarefa de inspeção de qualidade: {0} - {1}",
        reference2: "Etiqueta inteligente: {0}",
        reference3: "Pré-processamento de dados: {0}",
        reference4: "Base de conhecimento: {0}",
        index: { import: "Importar rótulos semânticos" },
        detail: {
          new: "Novo rótulo semântico",
          edit: "Editar rótulos semânticos",
          name: "Nome",
          standardSentences: "Sentença padrão",
          effectiveness: "Eficácia",
          similarSentences: "Sentença semelhante",
          tip: "Suporte para chamar a API da entidade com # #, Exemplo: Seu número de telefone é # sys_phone #?",
          excludeSimilarSentences: "Excluir frases semelhantes",
          intelligentRecommend: "Conteúdo semelhante recomendado inteligente",
          fastTraining: "Treinamento rápido:",
          qualify: "A regular",
          recallKeyWord: "Palavras-chave de recall",
          excludeKeywords: "Excluir palavras-chave",
          excludeKeywordsInfo1:
            "A exclusão de palavras-chave e a correção têm precedência sobre todas as lógicas. Se a exclusão for atingida, o rótulo semântico não será atingido",
          keyWordInfo1:
            "Cada linha de palavras-chave de recall suporta o preenchimento de vários, separados por vírgulas em inglês, e a lógica é todos os acertos ao preencher vários.",
          keyWordInfo2:
            "As palavras-chave de recall suportam o preenchimento de várias linhas, e a lógica de preenchimento de várias linhas pode ser estabelecida como um acerto arbitrário.",
          recallRegular: "Recall regular",
          excludeRegular: "Excluir a regular",
          similaSentences: "Excluir frases semelhantes",
          simim: "Similaridade",
          keyWordInfo3:
            "A exclusão de sentenças semelhantes tem precedência sobre todas as lógicas. Se a exclusão de sentenças semelhantes for atingida, o rótulo semântico não será atingido. A similaridade padrão é de 80%. Quanto maior a similaridade, mais semelhante será.",
          keyWordInfo4:
            "As regras são semelhantes às regulares, com início e fim existindo por padrão. Não deve ser usado com colchetes, por exemplo [não XXXX]",
          keyWordInfo5:
            "Os recursos nos módulos avançados geralmente são recursos experimentais preparados para especialistas familiarizados com a operação e, se você não tiver certeza de como deve fazê-lo, é recomendável que você não altere esta seção.",
          keyWordInfo6:
            "A função de detecção entre frases dá aos rótulos semânticos a capacidade de detectar através de frases.",
          keyWordInfo7: "No máximo o mesmo papel contínuo",
          keyWordInfo8:
            "Ignore que os diferentes personagens não sejam maiores que o comprimento da frase",
          keyWordInfo9:
            "Uma frase de uma palavra para detecção de frase cruzada",
          keyWordInfo10: "A frase limite mais longa não é maior que",
          keyWordInfo11:
            "Remoção de uma percentagem de similaridade não inferior a",
          keyWordInfo12: "A similaridade não é inferior a",
          keyWordInfo13:
            "% De frases semelhantes serão correspondidas de forma semelhante (a similaridade padrão é de 80%)",
          keyWordInfo14:
            "A remoção de semelhança combinará com base na similaridade da frase. Recomenda-se remover frases muito semelhantes para melhorar a eficiência da detecção.",
          keyWord: "Palavras-chave",
          entity: "Entidades",
          ruleField: "Palavra de conceito",
          customRule: "Design de regras de fala",
          fanhua: "Similaridade pergunta",
          test: "Teste",
          testOk: "Teste de acerto",
          senior: "Configurações avançadas",
          seniorList: "Detecção de frase cruzada",
          up: "Habilitar",
          down: "Fechar",
          ju: "Frase",
          oneWord: "Uma palavra",
          simimNo: "Similaridade para ir mais pesado",
          seniorField: "Limite de similaridade",
          save: "Salvar",
          cancel: "Cancelamento",
          specialElements: "Elementos especiais",
          logicRule: "Regras lógicas",
          every: "Correspondência múltipla",
          recallOk: "Palavras de recall de sucesso:",
          recallNo: "Recall de falha",
          ruleOk: "Acertar a correção do recall",
          ruleNo: "Não acertar a regular",
          rularOk: "Regras de acerto",
          rularNo: "Regras de falha",
          infoOk: "Acerte uma frase padrão ou semelhante:",
          infoNo: "Frase padrão ou semelhante não atingido",
          fieldOk: "Acertar frases semelhantes:",
          fieldNo: "Excluir frases semelhantes",
          excludeRuleNo: "Eliminação de falha",
          excludeRuleOk: "Eliminação de acertos",
          excludeKeywordNo: "Palavras-chave excluídas",
          excludeKeywordOk: "Palavras-chave de exclusão de acerto",
          update: "A atualização foi bem-sucedida",
          fanhuaList:
            "Enviado para a página de generalização para assinar uma lista de frases semelhantes",
          wordList:
            "A lista de treinamento está vazia e não pode ser enviada para a lista de generalização",
          start: "Início",
          end: "Fim",
          keyWordInfo15:
            "Existem campos semelhantes na lista de generalização (configuração avançada de desrestrições de semelhança)",
          saveSuccess: "Salve o sucesso",
          errInfo:
            "Existem campos não preenchidos ou preenchidos incorretamente",
        },
        table: {
          deepTraining: "Treinamento em profundidade {0} roda",
          fastTraining: "Treino rápido",
        },
      },
      intelligentCollection: {
        title: "Aquisição inteligente",
        classification: "Lista de agrupamentos",
        new: "Nova coleção",
        table: {
          name: "Nome do campo",
          props: "Atributos do cliente",
          type: "Tipo de campo",
          desc: "Descrição do campo",
        },
        detail: {
          isCustomerProps: "Salvar nas propriedades do cliente",
          customerPropsHelper:
            "Depois de salvar, as informações coletadas são atualizadas para as informações do cliente. Sem salvar, as informações do cliente não serão atualizadas",
          name: "Nome do campo",
          type: "Tipo de campo",
          text: "Texto",
          desc: "Descrição do campo",
          descPlaceHolder:
            "Descrever a utilidade das informações de coleta de campo",
          condition: "Condições",
          logic: "Regras",
          method: "Método de coleta",
          message: "Conteúdo da informação",
          range: "Faixa de extração",
          entity: "Extração de entidades",
          base: "Informações básicas",
          collect: "Coleta de informações",
        },
      },
      intelligentTags: {
        title: "Rótulos inteligentes",
        sessionLabelTitle: "Cena de sessão",
        keyEventsTitle: "Eventos-chave",
        customerLabelTitle: "Rótulos de clientes",
        addClassification: "Nova classificação de etiquetas",
        defaultCategoryName: "Classificação padrão",
        index: {
          createTag: "Nova etiqueta",
          createEvent: "Novo evento",
          editTagClassification: "Editar a classificação do rótulo",
          selectTagClassification: "Selecionar a classificação do rótulo",
          editTagGrounp: "Editar um agrupamento",
          callTimeError:
            "O intervalo de tempo de conversação/início da conversa selecionado não pode exceder um mês (31 dias)",
          callRecordCountError:
            "O número de registros é 0, selecione novamente o tempo de chamada/conversa",
          selectItmeCountError:
            "Selecione as etiquetas inteligentes que precisam de operações em massa",
          redoSuccess:
            "Reexecutar novamente com sucesso, consulte o Centro de Tarefas",
          redoError: "Reexecutar novamente falhou, tente novamente mais tarde",
          timeError: "O tempo de consulta não pode exceder um mês (31 dias)",
          categoryName: "Nome da classificação",
          categoryColor: "Classificação de cores",
          newTagClassification: "Nova classificação de etiquetas",
          newEventClassification: "Nova classificação",
          tagClassification: "Classificação de rótulos",
          eventClassification: "Classificação de eventos",
        },
        detail: {
          new: "Nova etiqueta inteligente",
          edit: "Editar etiquetas inteligentes",
          tagName: "Nome",
          tagDesc: "Descrição",
          category: "Classificação",
          tagStatus: "Eficácia",
          operator: "Operador",
          rule: "Regras",
          labelClassAtion: "Tipo de etiqueta",
          labelClassAtionTip1:
            "Rótulos de anotação: marcação de rótulos com base em regras",
          labelClassAtionTip2:
            "Etiqueta de classificação: rotulagem com base no treinamento do modelo",
          group: "Agrupamento",
          checkLabel: "Classificação de seleção",
          warn: "Alerta antecipado",
          warnTips:
            "Depois de ativar o aviso, quando a sessão atender a esta regra de evento chave, a pessoa designada será solicitada e o lembrete será recebido na área de tarefas",
          warnError: "Por favor, selecione o pessoal designado de aviso",
        },
        subTitle: {
          common: "Rótulos de sessão inteligente",
          wecom: "Micro etiqueta da empresa",
        },
      },
      customerPortrait: {
        title: "Retratos de clientes",
        index: { import: "Importar retratos de clientes" },
        searchPlaceHolder: "ID do cliente, apelido ou telefone",
        callCount: "Número de conversas:",
        callDuration: "Dduração total da chamada:",
        dialogCount: "Número total de conversas:",
        keyWordsStatistics:
          "Estatísticas de palavras-chave (Top 20 & palavras de preocupação)",
        keyWordsCount: "({0})",
        businessTag: "Rótulos de negócios",
        keyWordsCloudImg: "Imagem de nuvem de palavras-chave",
        customerEmotionTrend: "Tendências de mudança de humor do cliente",
        notFoundCustomer: "Os clientes relevantes não foram recuperados",
        noKeyWords: "Palavras-chave relevantes não consultadas",
        noBusinessTags: "Não foi consultado o rótulo comercial relevante",
        getKeyWordsStatisticsDataError:
          "Falha na consulta de estatísticas de palavras-chave do cliente",
        getBusinessTagsStatisticsDataError:
          "Falha na consulta de rótulos de negócios do cliente",
        personCustome: "Informações do cliente não consultadas",
        notimeInfo: "Informações emocionais não consultadas",
        timeList: "Intervalo de datas",
        operator: "Rótulos inteligentes",
        placeHolder: "Por favor, escolha",
        placeOperator: "Por favor, escolha o rótulo inteligente",
        search: "Inquérito",
        reset: "Redefinir",
        searchHolder: "Pesquisa",
        call: "Chamada",
        text: "Diálogo",
      },
      sentimentAnalysis: { title: "Descoberta da opinião pública" },
      saleSentimentAnalysis: { title: "Análise da opinião pública" },
      dataPreprocess: {
        title: "Pré-processamento de dados",
        index: {
          createData: "Novos dados",
          selectDataSource: "Selecionar uma fonte de dados",
          selectStatus: "Validade de seleção",
          callTimeError:
            "O intervalo de tempo de conversação/início da conversa selecionado não pode exceder um mês (31 dias)",
          callRecordCountError:
            "O número de registros é 0, selecione novamente o tempo de chamada/conversa",
          selectItmeCountError:
            "Selecione os dados que precisam executar operações em massa",
          redoSuccess:
            "Reexecutar novamente com sucesso, consulte o Centro de Tarefas",
          redoError: "Reexecutar novamente falhou, tente novamente mais tarde",
        },
        detail: {
          new: "Novos dados",
          edit: "Editar dados",
          name: "Nome",
          desc: "Descrição",
          dataSource: "Fonte de dados",
          status: "Eficácia",
          entity: "Extração de entidades",
          operator: "Operador",
          rule: "Regras",
        },
      },
      connectedByConversation: {
        title: "Associações de diálogo",
        list: {
          title: "Função de associação de diálogo",
          info: "A função de associação de diálogo pode associar diferentes chamadas e conversas de acordo com regras predefinidas",
          rule: "Regras de associação de diálogo",
          source: "Escolha a fonte de dados",
          classification: "Por favor, escolha a classificação",
          choose: "Por favor, escolha",
          input: "Por favor, insira",
          save: "Salvar",
          cancel: "Cancelamento",
          fieldwei: "Bit",
          fieldNot: "Existem campos não preenchidos",
          fiedldlengthNo: "O comprimento do campo excede o limite",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "As palavras-chave podem ser definidas várias, separadas por vírgulas",
          applyRole: "Detecção de papéis",
          preOperator: "Pré-operador",
          applyOperatorScope: "Faixa de detecção",
          addOperator: "Adicionar um operador",
          noPreOperator: "Nenhum",
          from: "No.",
          to: "Sentença para o primeiro",
          end: "Frase",
          testRegular: "Teste de expressões regulares",
          test: "Teste",
          matchSuccess: "Sucesso de correspondência",
          matchError: "Falha na correspondência",
          querySementicTags: "Escolha um rótulo semântico",
          reQuerySementicTags: "Selecione novamente os rótulos semânticos",
          sementicTag: "Rótulos semânticos",
          standardSentence: "Sentença padrão",
          similarity: "Similaridade",
          actions: "Operação",
          noFitTag: "Não encontrou o rótulo certo,",
          addSemanticTag: "Adicionar um novo rótulo semântico",
          pleaseEnterRegular: "Insira uma expressão regular",
          pleaseEnterRegularText: "Insira o que precisa de verificação",
          newAssociateComponentTip:
            "Se o valor for do tipo numérico, use a função antiga",
        },
        filterManage: {
          noValueTip: "Sem dados",
          allCall: "Todas as chamadas",
          allDialogue: "Todos os diálogos",
          allWechat: "Todas as sessões",
          getFiltersListFailed:
            "Obter a lista de filtros falhou, tente novamente mais tarde!",
          getFilterDetailFailed:
            "Obter os detalhes do filtro falhou, tente novamente mais tarde!",
          getAllCountFailed:
            "Obter o número total de chamadas/conversas falhou, tente novamente mais tarde!",
        },
        listManage: {
          searchPlaceHolder: "Por favor, insira uma pesquisa de palavra-chave",
          filterConditions: "Condições de triagem",
          records: "Registros",
          getRecordsFailed:
            "Obter o registro falhou, tente novamente mais tarde!",
        },
        miniListManage: {
          getRecordsFailed:
            "Obter o registro falhou, tente novamente mais tarde!",
        },
        applyTemplates: {
          title: "Modelo de inspeção de qualidade aplicável",
          collectionTitle: "Modelos de aquisição aplicáveis",
        },
        recordDetail: {
          showWaveAudio: "Mostrar gravações",
          getDetailFailed:
            "Obter detalhes falhou, por favor tente novamente mais tarde!",
          getSmartTagsFailed:
            "Obter o rótulo inteligente falhou, tente novamente mais tarde!",
          getTemplatesFailed:
            "Obter o modelo aplicável falhou, tente novamente mais tarde!",
        },
        changeList: {
          sampleStatus: "Status de inspeção aleatória",
          sampleAssignInspector: "Atribuir inspetores de amostragem",
          reviewAssignInspector: "Distribuição de revisor",
          read: "Já leu",
          distributivePerson: "Atribuir pessoas",
          totalScore: "Pontuação total",
        },
        qualityCheckScore: {
          totalScore: "Pontuação total",
          actualScore: "Pontuação",
          actualGrade: "Classificação",
          appeal: "Reclamação",
          remark: "Observações",
          manualRemark: "Observações manuais",
          none: "Não há dados de detalhes de inspeção de qualidade",
          interactiveTip:
            "Detectar o uso de FAQ de atendimento ao cliente usando o reconhecimento interativo",
          interactiveIsEmptyTip:
            "Conteúdo de reconhecimento de interação não detectado",
        },
        semanticMark: {
          semanticContent: "Frase de marcação",
          markedSimilarTitel: "Rótulos semânticos semelhantes marcados",
          markedExcludeTitle:
            "Rótulos semânticos semelhantes estão marcados para exclusão",
          taskTagTitle: "Rótulos semânticos relacionados à tarefa",
          similarTagTitle:
            "Identificar rótulos semânticos de similaridade (> 40%,Top 20)",
          delete: "Excluir",
          markSimilar: "Marcadores semelhantes",
          markExclude: "Excluir semelhança",
          none: "Nenhum",
        },
        selectCallTime: {
          callTime: "Tempo de conversação/horário de início da sessão",
          callTimeRemind:
            "Você pode escolher um registro de uma única conversa no último ano que não tenha mais de um mês (31 dias)",
          callRecordCount:
            "Você selecionou um total de {0} registros de chamadas/conversas",
          getCallRecordError:
            "Obter o registro falhou, tente novamente mais tarde",
        },
      },
      informationEntities: {
        title: "Entidades de informação",
        index: { import: "Importar entidades de informação" },
        detail: {
          new: "Criar uma nova entidade de informação",
          edit: "Editar entidades de informação",
          name: "Nome",
          apiName: "Nome da API",
          activeFlag: "Eficácia",
          remark: "Descrição",
          logicExp: "A entidade regular",
          sentenceList: "Conteúdo da entidade",
          tip: "O conteúdo da entidade suporta a entrada de vários, separados por espaços, como sinônimos da mesma entidade",
          remarkLengthError: "O comprimento da descrição não pode exceder 255",
          nameLengthError: "O comprimento do nome não pode exceder 40",
          entityDuplicateError: "Repetição de conteúdo da entidade",
        },
      },
      interactiveRecognition: {
        title: "Reconhecimento de interação",
        classify: "Classificação",
        addClassify: "Adicionar uma classificação",
        editClassify: "Editar a classificação",
        index: { import: "Importar o reconhecimento de interação" },
        detail: {
          new: "Nova interação inteligente",
          edit: "Editar interações inteligentes",
          type: "Tipo",
          standardQuestion: "Perguntas padrão",
          similarQList: "Problemas semelhantes",
          exceptSimilarQList: "Excluir problemas semelhantes",
          category: "Classificação",
          targetType: "Objetivo de detecção de problemas",
          status: "Eficácia",
          standardAnswer: "Resposta padrão",
          similarAList: "Resposta semelhante",
          wrongAList: "Resposta errada",
        },
      },
      businessAnalysis: {
        title: "Análise de negócios",
        tip1: "Análise de tendência de negócios: a ordenada é o número de diálogos comunicantes, ou seja, quantos diálogos comunicantes atendem às condições, e a abscissa é o tempo",
        tip2: "Análise de Correlação: Analisar a associação de vários indicadores, levitar o mouse para mostrar a situação dos dados de correlação, volume de dados, correlação, taxa de repetição",
        tip3: "Análise de conversão: a análise de múltiplos indicadores pode formar uma relação de conversão mútua. O critério de determinação da conversão é a mesma chamada/diálogo. Se dois indicadores diferentes puderem ser atingidos, esses dois indicadores podem ser considerados como tendo uma relação de transformação",
      },
      knowledgeBase: {
        title: "Base de conhecimento",
        list: {
          add: "Adicionar subcategorias",
          delete: "Excluir uma classificação",
          categoryName: "Nome da classificação",
          createSuccess: "Criação bem sucedida de subcategorias",
          keyWord: "Palavras-chave",
        },
        detail: {
          type: "Tipo",
          status: "Estado",
          classification: "Classificação",
          standardQuestion: "Perguntas padrão",
          answerDetectionRange: "Faixa de detecção de resposta",
          standardAnswer: "Resposta padrão",
        },
      },
      callManage: {
        title: "Filtro de gravação de chamadas",
        listTitle: "Gerenciamento de chamadas",
        detailTitle: "Detalhes da chamada",
      },
      dialogueManage: {
        title: "Filtros de texto de diálogo",
        listTitle: "Gestão de Diálogo",
        detailTitle: "Detalhes da conversa",
      },
      weChatManage: {
        title: "Filtro de sessão do WeChat corporativo",
        listTitle: "WeChat Corporativo Gestão de Sessões",
        detailTitle: "Detalhes da sessão WeChat da empresa",
      },
      speechRecognition: {
        title: "Reconhecimento de voz",
        asr: "Reconhecimento de voz",
        asrRemind:
          "Desligar este interruptor fará com que a função de voz para texto e a função de inspeção de qualidade da máquina falhe",
        asrConfig: "Filtragem ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Quando ativado, a transliteração ASR do IM é executada",
        asrConfigRemind:
          "Quando ativado, somente as chamadas qualificadas executam o conteúdo ASR",
        correction: "Correção inteligente de erros",
        correctionRemind:
          "Depois de ativado, ele usará o conteúdo de \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words' target =' _ blank'> Thesaurus inteligente-substantivo profissional </a>\" para conversão de correção de erro forçada",
        correctionConfig: "Filtragem de correção de erros",
        advancedSettings: "Configurações avançadas",
        advancedSettingsTip:
          "Selecione o modelo ASR especificado para os dados especificados.",
        mandarinASRModel: "Modelo ASR Mandarim",
        cantoneseASRModel: "Modelos ASR em cantonês",
        englishASRModel: "Modelos ASR em inglês",
        correctionConfigRemind:
          "Quando ativado, apenas as chamadas que atendem às seguintes condições serão corrigidas",
        customJudgeLogicIsNull: "A lógica personalizada não pode estar vazia",
        redoCorrection: "Reexecutar a correção de erros",
        callTime: "Escolha o tempo de conversação",
        callTimeRemind:
          "Você pode escolher um registro de chamadas de até um mês (31 dias) no último ano",
        callRecordCount:
          "Você selecionou um total de {0} registros de chamadas",
        redoCorrectionSubmit: "Execução de submissão",
        callTimeError:
          "O intervalo de tempo de conversação selecionado não pode exceder um mês (31 dias)",
        callRecordCountError:
          "O número de registros de chamadas é 0, selecione novamente o tempo de chamada",
        redoCorrectionSuccess:
          "A correção de erros foi bem-sucedida, consulte o Centro de Tarefas",
        redoCorrectionError:
          "Recorreção de erros falhou, por favor, tente novamente mais tarde",
      },
      role: {
        title: "Gerenciamento de papéis",
        create: "Novo papel",
        detail: {
          title: "Personagens",
          new: "Novo papel",
          view: "Veja os personagens",
          edit: "Editar uma função",
          navTabNames: {
            basicInfo: "Permissões de menu",
            taskPermissions: "Autoridade da tarefa de inspeção de qualidade",
            dataPermissions: "Permissões de dados",
          },
          basicInfo: { roleName: "Nome", permissions: "Permissões" },
          taskPermissions: {
            allTask: "Todas as tarefas de inspeção de qualidade",
            myCreatedTask: "As tarefas de inspeção de qualidade que criei",
          },
          dataPermissions: {
            dataPermissionsConfig: "Configuração de permissões de dados",
            tips: "A função pode ver os dados sob a classificação selecionada",
            select: "Classificação de seleção",
            selectPlaceholder:
              "Selecione a classificação que requer permissões visíveis de dados",
          },
        },
      },
      dataMigration: {
        title: "Migração de dados",
        dataUpload: "Mocação de dados",
        dataDowload: "Emigração de dados",
        error:
          "Por favor, selecione a tarefa de inspeção de qualidade para exportação",
        list: {
          info: "Escolha a tarefa de inspeção de qualidade para sair",
          listInfo:
            "Escolha se deseja mover os dados relevantes para a tarefa de inspeção de qualidade (por exemplo, thesaurus inteligente, rótulos semânticos, entidades de informação, interações inteligentes, etc.)",
          noInfo:
            "Escolha se deseja mover outros dados que não sejam de inspeção de qualidade",
          ownInfo: "Apenas sair da missão",
          everyInfo: "Relacionado à tarefa de sair",
          allInfo: "Emigre de todos",
          callFilter: "Filtro de chamadas",
          talkFilter: "Filtro de diálogo",
          smartTag: "Rótulos inteligentes",
        },
        upload: {
          info: "1. Clique no botão de upload para fazer upload de um arquivo JSON compatível com o formato",
          uploadSuccess: "Carregar com sucesso",
          uploadError: "Falha no upload",
        },
      },
      dataAnalysis: {
        businessStatistics: "Estatísticas de negócios",
        qualityEffect: "Saúde da inspeção de qualidade",
        qualityAnalysis: "Análise de inspeção de qualidade",
        generalInspecitionReport: "Relatório genérico",
        fullAmountWork: "Carga de trabalho do inspetor de qualidade",
        intelligentTagsAnalysis: "Análise de rótulos",
        saleIntelligentTagsAnalysis: "Análise inteligente de etiquetas",
        generalInspecitionReportTip:
          "Total de dados: estatísticas sobre o tempo e o escopo da tarefa atualmente limitado, o número total de chamadas e dados de conversação, incluindo o número de dados de inspeção de qualidade inteligente & porcentagem, o número de dados de amostragem manual & porcentagem, o número de reclamações, a taxa de reinspeção e o gráfico de tendência de tempo",
        groupTest: "Teste de agrupamento",
      },
      uploadDownloadList: {
        title: "Centro de tarefas",
        tabs: {
          upload: "Fazer upload da importação",
          download: "Fazer o download da exportação",
          taskCenter: "Execução de tarefas",
        },
      },
      uploadDownloadModel: {
        title: "Fazer o upload do download",
        list: {
          clear: "Esvaziar",
          success: "Sucesso",
          fail: "Falha",
          hand: "Em andamento",
          noInfo: "Sem dados",
        },
      },
      billingCenter: {
        title: "Centro de faturamento",
        export: "Exportar",
        limit: "Limite de lembrete",
        tips: "O resultado do faturamento será desviado devido ao arredondamento, apenas para referência",
        dashboard: {
          remainingQuota: "Cota residual atual",
          usedQuota: "Cota acumulada usada",
          voiceQuality: "Qualidade de voz:",
          voice: "Voz:",
          textQuality: "Inspeção de qualidade de texto:",
          text: "Texto:",
          hour: "Horas",
          tenThousandHour: "Dez mil horas",
          day: "Dia",
          cutoff: "Prazo",
          warning:
            "(A cota restante é insuficiente, recarregue o mais rápido possível)",
          isClosed: "(Estado fechado)",
        },
        table: {
          quotaRecord: "Registro de cotas",
          timeFilter: "Escolha uma data:",
          search: "Inquérito",
          reset: "Redefinir",
          id: "Número de série",
          type: "Tipo",
          qualityType: "Tipo de inspeção de qualidade",
          quotaChange: "Mudanças",
          surplus: "Balanço",
          changeTime: "Tempo",
          voiceQuality: "Qualidade de voz",
          textQuality: "Inspeção de qualidade de texto",
          sizeChangeText: "Exibição por página",
          used: "Uso",
          give: "Concedido",
          deduction: "Dedução",
          callTime: "Tempo de conversação",
          count: "Processamento de dados",
          page: "Artigo {0} no total, página {1}",
        },
        limitModal: {
          call: "A cota de inspeção de qualidade de voz é menor do que",
          callFix: "Horas para fazer lembretes",
          dialogue:
            "O tempo restante da inspeção de qualidade de texto é menor que",
          dialogueFix: "Dias para fazer lembretes",
        },
      },
      messageCenter: {
        replyTitle: "Responda ao meu",
        answerTitle: "@ O meu",
        shareTitle: "Compartilhar o meu",
      },
      columnsManage: {
        title: "Gerenciamento de campo de inspeção de qualidade",
      },
      recordUpload: {
        list: {
          task: "Tarefa de inspeção de qualidade",
          user: "Homem de operação",
          uploadTime: "Tempo de upload",
          endTime: "Hora do fim",
          total: "Total de upload",
          success: "Inspeção de qualidade bem-sucedida",
          failed: "Falha na inspeção de qualidade",
          progress: "Progresso da inspeção de qualidade",
          inProgress: "Em andamento",
          finished: "Concluído",
        },
        steps: {
          uploading: "Fazer upload de um arquivo de gravação",
          baseInfo: "Preencha as informações básicas",
          start: "Início da inspeção de qualidade",
        },
        uploadComponent: {
          dragger: "Arrastar para adicionar arquivos",
          or: "Ou",
          selectFile: "Escolher o arquivo",
          des: "Suporte wav, formato mp3, taxa de amostragem de 8000Hz, arquivos de 16 bits",
          limit1: "Upload de uma só vez",
          limit2: "Um arquivo, cada um com um tamanho de arquivo não maior que",
          uploading: "Em upload",
          finished: "O upload está concluído",
          success: "Sucesso",
          failure: "Falha",
        },
        baseInfo: {
          task: "Escolha a tarefa de inspeção de qualidade",
          type: "Escolha a forma de gravação",
          agentSide: "Canal de atendimento ao cliente",
          excel: "Carregar dados de acompanhamento",
          checkFileInfo: "Verificação das informações do arquivo",
          excelFileError: "Por favor, faça upload de dados de acompanhamento",
          checking: "Na verificação...",
          reCheck: "Reverificação",
          success: "Verificação bem-sucedida",
          excelUoloadFail: "Falha no upload de dados de acompanhamento",
          tips: {
            tip1: "1. O nome do arquivo de gravação no ecxel precisa ser consistente com o nome da gravação carregada e não pode ser novamente",
            tip2: "2. O sistema usa o nome do arquivo de gravação e o ID da chamada para verificar o peso, e a repetição fará com que a inspeção de qualidade falhe",
            tip3: "3. Baixar o modelo de gravação do Excel, a cor da fonte é vermelho é um campo obrigatório",
            tip4: "4. O formato do campo do tipo tempo é: yyyy-mm-dd hh:mm:ss, por exemplo, 2021-04-20 17:36:30",
          },
        },
        title: "Upload de gravação local",
        checkTitle: "Inspeção de qualidade de gravação",
        start: "Início da inspeção de qualidade",
        disabledTips:
          "Verifique os arquivos de gravação antes da inspeção de qualidade",
        tips: "O arquivo de gravação de mesmo nome cobre os resultados da inspeção de qualidade e informações básicas",
        tips1: "Arraste o ficheiro para cá ou clique em Upload",
        tips2: "Suporte",
        tips3: "Formato",
        type: "wav、mp3",
        tips4: "Upload de uma só vez",
        tips5: "Um arquivo, cada um com um tamanho de arquivo não maior que",
        tipsOverSize:
          "A fila atual está cheia, remova os arquivos da fila antes de fazer o upload",
        size: "500M",
        count: "20",
        uploaded: "Arquivos de gravação carregados",
        uploading: "({0}%)",
        uploadFinished: "(O upload está completo)",
        bottomTitle: "Coleção de informações de gravação:",
        recordType: "Escolha a forma de gravação:",
        double: "Gravação de chamadas de canal duplo",
        single: "Gravação de chamadas de canal único",
        recordBaseInfo: "Fazer upload de informações básicas de gravação:",
        addBtn: "Adicionar um arquivo",
        downloadTemplate: "Download do modelo. excel",
        checkRecord: "Verificação das informações de gravação",
        checking: "Na verificação...",
        reCheck: "Reverificação",
        checkAll: "Seleção completa",
        cancelCheckAll: "Cancelar a seleção completa",
        delete: "Excluir",
        deleteLotSize: "Excluir em massa",
        agentChannel: "Canal de atendimento ao cliente:",
        left: "Canal da esquerda",
        right: "Canal direito",
        donotKonw: "Não sei.",
        mission: "Escolha a tarefa de inspeção de qualidade:",
        uploadFail: "Falha no upload",
        repeat: "Arquivos duplicados",
        overCount: "Número de arquivos acima do limite",
        overSize: "Tamanho do arquivo excede o limite",
        uoloadFail: "Falha no upload para a nuvem",
        formatError: "Erro de formatação",
        seccess: "Verificação bem-sucedida",
        fail: "Falha na verificação",
        recordChecking:
          "Durante a inspeção de qualidade de gravação, por favor, mais tarde...",
        recordCheckSuccess: "Inspeção de qualidade de gravação concluída",
        recordCheckFail:
          "A inspeção de qualidade da gravação falhou, verifique se a gravação é repetida ou inválida",
        backToIndex: "Voltar para a lista de upload",
        recordCheckIsStarted:
          "A tarefa de inspeção de qualidade está sendo executada no momento, por favor, inicie uma nova tarefa após a tarefa atual",
      },
      customerServicePortrait: {
        title: "Retrato de atendimento ao cliente",
        tip1: "Lei de distribuição de frases longas: a distribuição de frases longas, de acordo com o número de palavras longas de frases, estatísticas segmentadas, apenas o atendimento ao cliente é calculado",
        tip2: "Lei de distribuição da velocidade da fala: a distribuição da velocidade da fala, de acordo com a velocidade da fala (palavra/minuto), as estatísticas são realizadas em seções e apenas o atendimento ao cliente é calculado",
        tip3: "Lei de distribuição do tempo de resposta: a distribuição do tempo de resposta, apenas a resposta do atendimento ao cliente à situação do cliente",
        tip4: "Conformidade de inspeção de qualidade: taxa de conformidade do ponto de inspeção de qualidade",
        tip5: "Pont médio das tarefas de inspeção de qualidade: Pont médio das tarefas de inspeção de qualidade",
        contol: {
          date: "Escolha uma data:",
          today: "Hoje",
          thisMonth: "Este mês",
          lastMonth: "Mês passado",
          thisQuarter: "Este trimestre",
          lastQuarter: "O último trimestre",
          thisYear: "Este ano",
          lastYear: "Ano passado",
          customer: "Atendimento ao cliente:",
          search: "Inquérito",
          reset: "Redefinir",
          export: "Exportar",
          indicator: "Gestão de indicadores",
          selectPlaceholder:
            "Por favor, escolha o atendimento ao cliente que precisa de comparação",
          warning: "Escolha até 5 clientes para comparação",
          warning2: "Escolha pelo menos 1 atendimento ao cliente para consulta",
          dataWarning:
            "Suporta consultas somente até 366 dias após a data de início de dados",
          seccuss: "Exportação de registros bem-sucedida",
        },
        echarts: {
          distributionLaw: "Lei de distribuição",
          count: "Número de vezes",
          bout: "Vezes",
        },
        portrait: {
          ability: "Dimensão da capacidade de atendimento ao cliente",
          option: "Configuração",
          abilityOption: "Dimensão de capacidade de configuração",
          placeholder:
            "Pesquisa de palavra-chave de dimensão de capacidade de entrada",
          save: "Salvar",
          warning:
            "Escolha pelo menos 3 indicadores para configuração, até 10 indicadores",
        },
        label: { title: "Etiqueta de negócios de atendimento ao cliente" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Classificação de atendimento ao cliente",
        select: {
          date: "Escolha uma data:",
          today: "Hoje",
          thisMonth: "Este mês",
          lastMonth: "Mês passado",
          thisQuarter: "Este trimestre",
          lastQuarter: "O último trimestre",
          thisYear: "Este ano",
          lastYear: "Ano passado",
          group: "Grupo de atendimento ao cliente:",
          groupPlaceholder:
            "Por favor, selecione o grupo de atendimento ao cliente",
          customer: "Atendimento ao cliente:",
          customerPlaceholder: "Por favor, escolha o atendimento ao cliente",
          search: "Inquérito",
          reset: "Redefinir",
          compared: "Contraste",
          export: "Exportar",
          column: "Coluna",
          warning: "Escolha até 5 clientes para comparação",
          warning2: "Escolha pelo menos um atendimento ao cliente",
          dataWarning:
            "Suporta consultas somente até 366 dias após a data de início de dados",
          selected: "Atendimento ao cliente selecionado:",
          seccuss: "Exportação de registros bem-sucedida",
        },
        compared: {
          title: "Escolha o atendimento ao cliente",
          return: "Cancelamento",
          compared: "Contraste",
          tips: "Escolha de 2 a 5 clientes para comparação",
          warning: "Apenas 2 a 5 clientes são suportados para comparação",
        },
        transfer: {
          leftTitle: "Item de lista",
          rightTitle: "Item de exibição",
        },
        table: {
          page: "Artigo {0} no total, página {1}",
          actionTitle: "Operação",
          action: "Veja",
        },
      },
      conceptWord: {
        title: "Palavra de conceito",
        new: "Nova palavra de conceito",
        control: {
          search: "Pesquisa:",
          searchPlaceholder: "Pesquisa de nome ou palavra-chave",
          commit: "Inquérito",
          reset: "Redefinir",
          import: "Importação",
          export: "Exportar",
          seccuss: "Exportação de registros bem-sucedida",
        },
        modal: { title: "Importar palavras conceituais" },
        table: {
          sizeChangeText: "Exibição por página",
          page: "Artigo {0} no total, página {1}",
          edit: "Editar",
          delete: "Excluir",
          request:
            "Após a exclusão será irrecuperável, a exclusão é confirmada?",
          cancel: "Cancelamento",
          title: {
            conceptWord: "Palavra de conceito",
            describeWordsNum: "Descrever o número de palavras",
            updateTime: "Tempo de atualização",
            status: "Eficácia",
            effective: "Eficaz",
            invalid: "Inválido",
            action: "Operação",
          },
        },
        detail: {
          title: {
            new: "Nova palavra de conceito",
            edit: "Editar palavras conceituais",
          },
          name: "Nome da palavra conceito",
          namePlaceholder: "Por favor, insira o nome da palavra de conceito",
          effectiveness: "Eficácia",
          effective: "Eficaz",
          invalid: "Inválido",
          description: "Palavra de descrição:",
          descriptionPlaceholder: "Por favor, insira a palavra descritiva",
          add: "Adicionar",
          repeat: "Conteúdo duplicado",
          empty: "A palavra descritiva não pode ser vazia",
          recommendEmpty:
            "Não há mais palavras de descrição recomendadas, insira-as manualmente",
          recommend: "Palavra de descrição de recomendação inteligente",
          cancel: "Cancelamento",
          change: "Mudar um grupo",
          descriptionSelected: "Uma palavra de descrição foi adicionada:",
          save: "Salvar",
          success: "Palavras conceituais para salvar o sucesso",
          warning: "Nome de palavra de conceito não pode estar vazio",
          warning2: "Adicione pelo menos 1 palavra de descrição",
          submitBtnHandle: {
            title:
              "A palavra de descrição marcada não é adicionada, salvar a palavra de descrição não adicionada será abandonada",
            content:
              "Clique em OK para retornar à página inicial da palavra do conceito, confirme para continuar?",
          },
        },
      },
      smartTools: {
        btn: "Ferramentas inteligentes",
        title: {
          similarWords: "Descoberta de palavras relacionadas",
          search: "Por palavras",
          semanticTags: "Rótulos semânticos",
        },
        sentenceSearch: {
          placeholder:
            "Insira a pesquisa de palavra-chave, suporte para várias palavras-chave",
          more: "Veja a íntegra",
          pageUp: "Página anterior",
          pageDown: "Próxima página",
          total: "Um total de {0} registros",
        },
        similarDiscover: {
          placeholder: "Digite a palavra-chave",
          copyed: "Replicado",
          none: "Sem palavras relacionadas, tente outras palavras-chave",
        },
        smartTags: {
          searchPlaceHolder: "Insira uma palavra-chave ou frase",
          createSmartTag: "Novo",
          createClusteringSmartTag: "Novo com {0} dados",
          addFromKeyword: "Adicionar conteúdo de pesquisa a frases semelhantes",
          hit: "Hit",
          similar: "Similaridade",
          name: "Nome do rótulo semântico",
          namePlaceHolder: "Insira o nome do rótulo semântico",
          recallWords: "Palavra de recall",
          recallWordsPlaceHolder: "Insira a palavra-chave do recall",
          recallRegular: "Recall regular",
          recallRegularPlaceHolder: "Por favor, insira a correção do recall",
          rules: "Regras",
          standardSentences: "Sentença padrão",
          standardSentencesPlaceHolder: "Por favor, insira a frase padrão",
          similaSentences: "Sentença semelhante",
          similaSentencesPlaceHolder: "Por favor, insira uma frase semelhante",
          notSaveError:
            "Por favor, salve o rótulo semântico antes de encontrá-lo",
          add: "Adicionar",
          newPage: "Abrir em uma nova página",
          querryError:
            "Existem campos semelhantes na lista de generalização (configuração avançada de desrestrições de semelhança)",
          detailTitle: "Detalhes do rótulo semântico",
        },
      },
      trainingCenter: {
        title: "Centro de treinamento",
        edit: "Editar",
        delete: "Excluir",
        seleteAll: "Seleção completa",
        cancelCheckAll: "Cancelar a seleção completa",
        add: "Adicionar",
        remove: "Remover",
        save: "Salvar",
        cancel: "Cancelamento",
        fastTraining: {
          title: "Treino rápido",
          titleDes:
            "O treinamento rápido foi projetado para ajudar os rótulos semânticos a se generalizarem rapidamente para 20 frases semelhantes",
          semanticTags: "Rótulos semânticos",
          semanticTagsPlaceholder:
            "Por favor, insira o processo de pesquisa de palavra-chave",
          standardSentence: "Sentença padrão:{0}",
          similarSentencesPlaceholder:
            "Adicionar ou pesquisar inserindo uma frase semelhante",
          similarSentencesAdd: "Adicionar",
          similarSentencesSearch: "Pesquisa",
          similarSentences: "Uma frase semelhante foi adicionada",
          trainingCount: "Número de treinamento:{0}",
          similarSentencesRecommend: "Sentença semelhante recomendada",
          noResp:
            "Sem rótulos semânticos relevantes, substitua as palavras-chave",
          similarSentencesModalTitle: "Pesquisa de frases semelhantes",
          moreInfo: "Veja o contexto",
          moreInfoBtn: "Contexto",
          modalSearchType: {
            all: "Todos",
            customer: "Atendimento ao cliente",
            client: "Clientes",
          },
          repetWarning:
            "Já existe a mesma frase semelhante, não adicione repetidamente",
          repetRemoveTip:
            "Salve o conteúdo duplicado em frases semelhantes, que foi automaticamente pesado",
        },
        trainingHistory: {
          title: "História do treinamento",
          select: {
            date: "Escolha o tempo:",
            today: "Hoje",
            thisMonth: "Este mês",
            lastMonth: "Mês passado",
            thisQuarter: "Este trimestre",
            lastQuarter: "O último trimestre",
            thisYear: "Este ano",
            lastYear: "Ano passado",
            search: "Inquérito",
            reset: "Redefinir",
          },
          table: {
            time: "Tempo",
            user: "Homem de operação",
            tag: "Rótulos semânticos",
            similarCount: "Número de logotipos de frases semelhantes",
            deleteCount: "Remover o número de frases",
            page: "Artigo {0} no total, página {1}",
          },
        },
        classifiedTraining: {
          title: "Treinamento de classificação",
          tagsGrounp: "Agrupamento de rótulos",
          placeHolder: "Por favor, escolha",
          cellTime: "Escolha o tempo",
          search: "Inquérito",
          reset: "Redefinir",
          classifiedFlag: "Situação de treinamento de classificação",
          num: "Valor numérico",
          gas: "Porcentagem",
          noData: "Treinamento não classificado",
          noDataHeight:
            "A proporção de dados não classificados é relativamente alta e o treinamento é urgentemente necessário",
          noDataSmall:
            "Dados não classificados representam uma proporção relativamente alta, recomenda-se treinamento",
          noDataDays: "Última execução {0} dias atrás",
          training: "Treinamento",
          start: "Início",
          addClass: "Aumentar a classificação",
          custom: "Filtragem avançada",
          marked: "Errata de dados marcada",
          markedHeight: "Não há errata há {0} dias, recomenda-se errata",
          markedSmall:
            "Não há erro por {0} dia, e há necessidade urgente de erro",
          mark: "Errata",
          dataCheck: "Verificação de dados classificados",
          dataCheckSmall:
            "K-L a divergência acima de 0,5, recomenda-se treinamento",
          dataCheckHeight:
            "A divergência do K-L é superior a 0,8, e o treinamento é necessário com urgência",
          check: "Verificação",
          history: "Histórico de anotações",
          historyTime:
            "As anotações e modificações diárias executarão automaticamente o treinamento do modelo às 2h00",
          today: "Nota de hoje",
          article: "Artigo",
          none: "Nenhum",
          historyCheck: "Total histórico",
          look: "Veja os registros",
          historyluate: "Recontagem de dados históricos",
          startluate: "Recálculo de arranque",
          classData: "Dados de classificação",
          classStatus: "Progresso do treinamento",
          next: "O próximo",
          prev: "O último",
          finish: "Treino completo",
          stop: "Fim do treino",
          classDetail: "Detalhes do treinamento",
          trainingNone: "Sem etiquetas inteligentes para treinamento",
        },
        classifiedTrainingHistory: {
          title: "História do treinamento",
          time: "Escolha o tempo:",
          tag: "Tipo de etiqueta:",
          user: "Operador:",
          data: "Escolha uma fonte de dados:",
          search: "Inquérito",
          searchBar: "Pesquisa:",
          searchBarPlaceholder:
            "Insira o ID de chamada/ID de conversa para pesquisa",
          reset: "Redefinir",
          export: "Exportar",
          all: "Todos",
          select: {
            today: "Hoje",
            thisMonth: "Este mês",
            lastMonth: "Mês passado",
            thisQuarter: "Este trimestre",
            lastQuarter: "O último trimestre",
            thisYear: "Este ano",
            lastYear: "Ano passado",
            search: "Inquérito",
            reset: "Redefinir",
          },
          table: {
            user: "Operador",
            date: "Tempo de operação",
            data: "Fonte de dados",
            id: "ID da chamada/ID da conversa",
            tagType: "Agrupamento inteligente de etiquetas",
            tagName: "Nome do rótulo",
            state: "Estado",
            sizeChangeText: "Exibição por página",
            page: "Artigo {0} no total, página {1}",
            call: "Chamada",
            dialogue: "Diálogo",
          },
        },
        clusteringAnnotation: {
          title: "Anotação de agrupamento",
          left: { title: "Resultados de agrupamento" },
          right: {
            title: "Sentença de agrupamento",
            checkAll: "Selecionando todas as páginas",
            tagging: "Anotações",
            detail: "Veja",
            meaningless: "Ignorar",
            meaninglessTips:
              "Os resultados do cluster que foram ignorados não serão mais exibidos posteriormente. Você confirma que deseja ignorar?",
            cancel: "Cancelamento",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Gestão de clientes",
          tabTitle: "Clientes do centro de atendimento ao cliente",
        },
        wechatClientManage: {
          title: "Micro clientes corporativos",
          table: {
            id: "ID do cliente",
            name: "Apelido do cliente",
            tag: "Rótulos de clientes",
          },
        },
      },
      collectionRule: {
        title: "Regras de aquisição",
        form: {
          info: "Informações de aquisição inteligente",
          infoPlaceholder: "Por favor, escolha a classificação",
        },
        table: {
          name: "Nome do campo",
          props: "Atributos do cliente",
          type: "Tipo de campo",
          desc: "Descrição do campo",
        },
      },
      dataAdd: {
        title: "Adição de dados",
        types: { auto: "Adição automática", manual: "Adicionar manualmente" },
      },
      wechatStaff: {
        table: {
          name: "Nome",
          part: "Departamentos afiliados",
          phone: "Número de celular",
          email: "Caixa de correio",
          wechartId: "Wechat ID da empresa",
          status: "Estado de serviço",
        },
        info: "Escolha primeiro o departamento em que os dados precisam ser sincronizados",
        synSuccess: "Sincronização bem sucedida",
        init: "Inicialização",
        syn: "Sincronização",
      },
      appealHistory: {
        appealTime: "Tempo de reclamação",
        cancelTime: "Tempo de revogação",
        reviewTime: "Tempo de revisão",
        inspector: "Inspetor de qualidade",
        result: "Resultado da reclamação",
        complainResult:
          "Um total de {0} itens de inspeção de qualidade foram reclamados, {1} foram reclamados com sucesso e {2} foram rejeitados",
        reason: "Motivo da reclamação",
        rejection: "Razões para rejeição",
        cancel: "Revogação",
        tip: "Confirmação para revogar a denúncia?",
      },
      appealSdk: {
        time: "Tempo de conversa",
        externalIdCall: "ID do registro de chamadas",
        externalIdText: "ID do registro de conversa",
        customerNickName: "Apelido do cliente",
        readStatus: "Estado de acesso",
        status: "Estado de recurso",
        isManual: "Se a inspeção de qualidade manual",
        complainButtonInfo:
          "Permite recursos {0} vezes, recursos {1} vezes e recursos restantes {2} vezes. {3} Recurso antes",
        submit: "Envie uma reclamação",
        complain: "Reclamação",
        allComplain: "Todos os recursos",
        pendingSubmit: "A ser submetido",
        taskIds: "Tarefa de inspeção de qualidade",
        graded: "Classificação",
        complainSuccess: "Recurso apresentado com sucesso",
        appealSdkIndex: {
          title: "Resultados de inspeção de qualidade inteligentes",
        },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Obter informações da lista de filtros falhou, verifique a conexão de rede ou tente novamente mais tarde!",
      columns: "Coluna",
      filter: "Filtragem",
      noSearchResult: "Nenhum registro foi encontrado",
      pleaseSearch: "Por favor, escolha um filtro e tentar pesquisar",
      sift: "Filtragem",
      create: "Novo",
      search: "Pesquisa",
      createTaskTitle: "Novas tarefas",
      title: "Título",
      deadline: "Prazo",
      principal: "Responsável",
      enqueueTime: "Tempo de entrada",
      computeError: "Falha de cálculo, por favor mais tarde!",
      sum: "Soma",
      max: "Máximo",
      min: "Mínimo",
      avg: "Média",
      count: "Contagem",
      maxDate: "Máximo (mais recente)",
      minDate: "Mínimo (mais antigo)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Obter o endereço do link Insight falhou, tente novamente mais tarde!",
      },
      template: {
        learningReminder: "Lembrete de aprendizagem",
        subscriptionDynamics: "Dinâmica de assinatura",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Gerenciamento de tarefas", chaneg: "Alternar" },
        qualityCheckTemplate: {
          text: "Modelo de inspeção de qualidade",
          collectText: "Modelos de aquisição",
          condition: { text: "Condições de inspeção de qualidade" },
          checkPoint: { text: "Configuração da regra" },
          automaticAdd: { text: "Automação de processos" },
          manualAdd: { text: "Adicionar manualmente" },
          roleConfig: { text: "Configuração de função" },
          basicInfo: { text: "Informações básicas" },
          smartWordsLibrary: { text: "Thesaurus inteligente" },
          grade: { text: "Classificação de inspeção de qualidade" },
          collectionRule: { text: "Regras de aquisição" },
          addData: { text: "Adicionar dados" },
          collectionList: { text: "Lista de aquisição" },
        },
        qualityCheckWorkbench: {
          text: "Bancada de inspeção de qualidade",
          wechatText: "Bancada de análise",
          informationCollectText: "Coleta de trabalho",
          qualityInspectionList: {
            text: "Lista de inspeção de qualidade",
            wechatText: "Lista de análise",
            informationCollectText: "Lista de aquisição",
          },
          condition: { text: "Lista de inspeção de qualidade (abandonada)" },
          reviewList: { text: "Lista de revisão" },
          spotCheckList: { text: "Lista de inspeção aleatória" },
          caseBaseList: { text: "Biblioteca de casos" },
          testSetList: { text: "Conjunto de testes de inspeção de qualidade" },
        },
        statisticsAndReports: {
          text: "Estatísticas e relatórios",
          wholeReport: { text: "Relatório geral" },
          groupReport: { text: "Relatório do grupo de atendimento ao cliente" },
          agentReport: { text: "Relatório de atendimento ao cliente" },
          customerServiceExecution: {
            text: "Análise de atendimento ao cliente",
          },
          analysisOverview: { text: "Visão geral da análise" },
          inspectionItemsAnalysis: { text: "Análise de inspeção de qualidade" },
        },
        wechatQa: {
          text: "Inspeção de qualidade do WeChat corporativo",
          task: { text: "Tarefas de análise" },
          baseInfo: { text: "Informações básicas" },
          checkPoint: { text: "Análise da configuração do modelo" },
          setting: { text: "Configuração de análise de WeChat empresarial" },
          automaticAdd: { text: "Automação de processos" },
          manualAdd: { text: "Adicionar manualmente" },
        },
        clientCenter: { text: "Centro de atendimento ao cliente" },
      },
      conditionDictionaries: {
        title: "Todas as condições",
        searchDefaultText: "Por favor, insira o ID ou nome",
        id: "ID",
        name: "Nome",
        conditionDetailedShow: "Expansão",
        conditionDetailedHide: "Recolher",
        remark: "Descrição:",
        checkRole: "Papel:",
        operator: "Operador:",
        operatorLogic: "Lógica:",
      },
      audioTextView: {
        customer: "Clientes",
        agent: "Atendimento ao cliente",
        unsupportedFormat: "O formato não é suportado por enquanto",
      },
      checkPointClassifications: {
        add: "Nova classificação",
        name: "Nome",
        enterToSave: "Pressione a tecla Enter para salvar",
        highestScore: "Maior número de pontos",
        minimumScore: "Pontuação mínima limite inferior",
        defaultMinScore: "O padrão é 0 pontos",
        minimumScoreTitle: "Limite inferior de pontuação",
        beginScore: "Valor de pontuação geral",
        beginScoreTitle: "Valor de pontuação",
        scoreSetTip:
          "O limite máximo de pontuação é menor que a soma das pontuações, você pode escolher:",
        ignoreExcessSpillover: "Ignorar o excesso de transbordamento",
        automaticWeightCalculation: "Contabilidade automática de peso",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Campo" },
    checkRange: {
      numberTipOne: "No.",
      numberTipTwo: "Frase",
      numberTipThree: "A frase chega ao primeiro",
      conditionLimit: "Restrições condicionais",
      roleLimit: "Restrições de função",
      contextLimit: "Limite de sentença",
      notZero: "O limite de sentença não suporta entrada 0",
      startMustLessThanEnd:
        "A posição da frase inicial deve ser menor que a posição da frase final",
    },
    waveAudio: {
      play: "Reprodução",
      pause: "Suspensão",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Por favor, insira",
      pleaseEnterRegular: "Insira uma expressão regular",
      pleaseEnterGrammar: "Por favor, insira regras de gramática",
      pleaseEnterTestText: "Insira o que precisa de verificação",
      pleaseEnterLoudness: "Por favor, insira decibéis de volume",
      pleaseEnterRangeAbility: "Por favor, insira a amplitude de mudança",
      afterHit: "Verificação após acerto",
      pleaseEnterContext: "Por favor, insira uma instrução de referência",
      wordNumber: "Diferença de contagem de palavras",
      intervalSecond: "O intervalo de tempo é maior que",
      minute: "Cada minuto",
      wordUnit: "Uma palavra",
      test: "Teste",
      testRegular: "Teste de expressões regulares",
      testGrammar: "Gramática de teste",
      testGrammarError: "Falha no teste de gramática",
      regularError: "Erro de expressão regular, reinsira",
      pleaseEnterSemanticSentence:
        "Insira uma frase que requer detecção semântica",
      pleaseEnterAssociate:
        "Consulte as instruções à direita para criar expressões de dados de acompanhamento",
      querySemanticTags: "Escolha um rótulo semântico",
      reQuerySemanticTags: "Selecione novamente os rótulos semânticos",
      semanticTags: "Rótulos semânticos",
      standardSentence: "Sentença padrão",
      semanticName: "Rótulos semânticos",
      sentence: "Sentença padrão",
      similarity: "Similaridade",
      actions: "Operação",
      tipOne: "Não encontrou o rótulo certo,",
      tipTwo: "Adicionar um novo rótulo semântico",
      notLessThan: "Não menos que",
      notGreaterThan: "Não é maior que",
      ignoreLessThan: "Ignorar menos do que",
      wordUnitSet: "Palavra da frase",
      speedWordNotNull:
        "Detecção de velocidade de fala O número mínimo de palavras e o número máximo de palavras não podem ser vazios",
      speedWordMinValueLessThanMaxValue:
        "Detecção de velocidade de fala O número mínimo de palavras não pode ser maior que o número máximo de palavras",
      speechGrabbingIntervalTip: "Intervalo de conversa> =",
      speechLengthTip: "Palavras longas> =",
      msUnit: "ms",
      sUnit: "Segundos",
      grabIntervalNotNull: "O intervalo de chamadas não pode estar vazio",
      grabLengthNotNull: "Palavras longas não podem ser vazias",
      pleaseSelectEntity: "Selecione a entidade de informação",
      pleaseSelectOperator: "Por favor, escolha a operação",
      pleaseSelectBasicInfo: "Por favor, escolha a informação básica",
      pleaseEnterFixedValue: "Por favor, insira um valor fixo",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Sucesso de correspondência",
      matchError: "Falha na correspondência",
      mindetectionStatement: "Instrução de detecção mínima",
      conceptWordholder: "Por favor, escolha a palavra de conceito",
      elementHolder: "Por favor, insira um elemento",
      associateMedol: { tabs: { data: "Dados", operator: "Operador" } },
      repet: "Repetição",
      bout: "Vezes",
      ignore: "Ignorar menos do que",
      ignoreAffix: "Palavra da frase",
      associateData: "Dados de estrada",
      pretreatmentData: "Pré-processamento de dados",
      reviewTaskData: "Reexame dos dados da missão",
      taskData: "Dados da tarefa de inspeção de qualidade",
      businessData: "Registros de negócios",
      functionOperator: "Operador de função",
      operation: "Operador de operações",
      notAnyMatch: "Ao falhar qualquer problema:",
      times: "Número de ocorrências",
      newAssociateDataTip:
        "Se o valor for do tipo numérico, use a função antiga",
    },
    conditionGather: {
      cycle: "Ciclo",
      rlue: "Regras",
      delete: "Remover",
      sampling: "Extração",
      item: "Artigo",
      percent: "%",
      samplingSame: "Cada atendimento ao cliente sorteará a mesma quantidade",
      samplingRecheck: "Permitir a extração repetida de entradas aleatórias",
      dateUnit: "No.",
      samplingTip:
        "Regras diferentes que cobrem a mesma chamada não serão sorteadas repetidamente, e os registros que foram extraídos e não verificados não serão extraídos novamente.",
      add: "Novas regras",
    },
    ruleGather: {
      title: "Regras",
      screeningCondition: "Condições de triagem",
      addRule: "Novas regras",
      inspector: "Inspetor de qualidade",
      ruleTip:
        "O modo de distribuição onde as regras são priorizadas, alocadas de cima para baixo em sequência, alocações não duplicadas, dentro de uma regra ou não correspondendo a qualquer item de regra será atribuído aleatoriamente",
      moveUp: "Mover para cima",
      moveDown: "Mover para baixo",
      top: "Coloque o topo",
    },
    pushRulesGather: {
      title: "Regras",
      delete: "Remover",
      newPush: "Novo push",
      newRule: "Novas regras",
      sampling: "Extração",
      item: "Artigo",
      percent: "%",
      samplingSame: "Cada atendimento ao cliente sorteará a mesma quantidade",
      dateUnit: "No.",
      screeningCondition: "Condições de triagem",
      email: "Caixa de correio",
      emailPlaceholder: "Digite a caixa de correio, dividindo-a com vírgulas",
      updateType: "Tipo de atualização",
      pushDetail: "Detalhes do push",
      pushUrl: "URL de push",
      pushWay: "Tipo de push",
      pushMethod: "Maneira de push",
      pushContentType: "Formato de dados",
      pushData: "Push de dados",
      additionalData: "Dados adicionais",
      inspectionType: "Tipo de inspeção de qualidade",
      all: "Todos",
    },
    taskAdditionalData: {
      newData: "Novos dados",
      delete: "Remover",
      placeholder: "Por favor, insira um nome",
      dataPlaceholder: "Por favor, insira o conteúdo",
    },
    UdeskQaReact: {
      loadSdkError: "O carregamento do SDK falhou, tente novamente mais tarde!",
    },
    gearOptionList: { evaluation: "Avaliação" },
    tableComponentTransfer: {
      leftTitle: "Item de lista",
      rightTitle: "Item de exibição",
    },
    kmTinymce: {
      link: "Inserir/editar um link",
      modalTitle: "Inserir um link",
      href: "Endereço",
      text: "Mostrar texto",
      title: "Título",
      target: "Abra o caminho",
      none: "Nenhum",
      newWindow: "Abre em uma nova janela",
      buttonStyle: "Estilos de botão",
      close: "Fechar",
      open: "Ligado",
      answerMessage: "Ir/enviar uma mensagem",
      answerMessageTitle: "Botão Enviar Mensagem",
      answerMessageText: "Mostrar texto",
      answerMessageContent: "Conteúdo da mensagem",
      answerMessageButtonStyle: "Estilos de botão",
      answerMessageTips:
        "Nota: O botão Enviar mensagem suporta apenas canais web, por favor configure com cuidado",
      answerSwitchStaff: "Saltar/virar manual",
      answerSwitchStaffTitle: "Botão de rotação artificial",
      answerSwitchStaffText: "Mostrar texto",
      answerSwitchStaffRule: "Regras do botão",
      answerSwitchStaffButtonStyle: "Estilos de botão",
      answerSwitchStaffTips:
        "Nota: Botão de conversão manual suporta apenas canais web, por favor configure com cuidado",
      textButton: "Botão de texto",
      borderButton: "Botão com moldura",
      message: "Enviar uma mensagem",
      switchStaff: "Transferência de mão de obra",
      messageError: "O conteúdo do botão não pode exceder 10 palavras",
      messageIsEmptyError: "O conteúdo do botão não pode estar vazio",
      okText: "OK",
      cancelText: "Cancelamento",
      video: "Vídeo",
      uploadVideo: "Fazer upload de um vídeo",
      uploadText:
        "Suporta apenas o formato MP4, não mais do que 20M de tamanho",
      videoEmptyOrUploading: "O vídeo está vazio ou em upload",
      customEmoji: "Expressão",
    },
    massageConfiguration: {
      pushTarget: "Objetivos de notificação",
      customizePushTarget: "Objetivos push personalizados",
      sysUser: "Usuários atuais do sistema",
      sysRole: "Funções atuais do sistema",
      emailPushTarget: "Enviar um alvo",
      targetEmailsPlaceholder:
        "Digite a caixa de correio separada por vírgula em inglês",
      subject: "Nome da mensagem",
      SDKID: "ID de identificação do SDK",
      content: "Conteúdo da notificação",
      sdkPushType: "Esquema push",
      sdkPushURL: "URL de push",
      pushData: "Push de dados",
      sdkPushWay: "Maneira de push",
      dataType: "Formato de dados",
      additionalData: "Dados adicionais",
      name: "Nome da mensagem",
      filter: "Condições de triagem",
      triggerType: "Mecanismo de gatilho",
      pushCycle: "Frequência fixa cronometrada",
      pushContent: "Conteúdo do relatório",
      qualityList: "Lista de resultados de inspeção de qualidade",
      designated: "Pessoal designado",
      messageType: "Tipo de mensagem",
    },
    taskCard: {
      template: {
        cancelCollection: "Cancelar uma coleção",
        collection: "Coleção",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Sentença padrão:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Coleção",
          calls: "Uma chamada",
          createdBy: "Fundador:",
          createdOn: "Tempo de criação:",
        },
        fileItem: {
          uIProvidesIcon: "Ui oferta icon",
          minute: "Minutos",
          callClip: "Fragmentos de chamada",
          start: "Início",
          End: "Fim",
          employees: "Funcionários:",
          Customer: "Clientes:",
          sessionTime: "Tempo de sessão:",
          reasonForRecommendation: "Razões recomendadas:",
          addedBy: "Adicionado por:",
          addTime: "Tempo de adição:",
        },
        modalAddFile: {
          caseTitle: "Título do caso",
          addSomeClipsToTheCaseLibrary:
            "Adicionar fragmentos parciais à biblioteca de casos",
          addToCaseBase: "Adicionar à biblioteca de casos",
          storedIn: "Armazenado em",
          pleaseSelect: "Por favor, escolha",
          reasonsForRecommendation: "Razões recomendadas",
          pleaseEnter: "Por favor, insira",
        },
      },
      shareModal: {
        index: {
          customer: "Clientes:",
          employees: "Funcionários:",
          timeliness: "Atualidade:",
          recordingLinkValidWithinDays: "O link de gravação é válido em dias",
          sharingInstructions: "Compartilhar instruções:",
          shareSuccess: "Compartilhe o sucesso!",
          cancel: "Cancelamento",
          share: "Compartilhar",
          copySucceeded: "Copiar sucesso!",
          copyShareLink: "Copiar o link de compartilhamento",
          shareWithColleagues: "Compartilhar com colegas",
          shareWithCustomers: "Compartilhar com os clientes",
          staff: "Funcionários",
          selectColleagues: "Escolhendo colegas de trabalho",
          pleaseSelectColleaguesToShare:
            "Por favor, escolha um colega para compartilhar",
          pleaseEnterTheSharingDescription:
            "Por favor, insira a nota de compartilhamento",
          taskName: "Missão:",
          recordingShareValidWithinDays:
            "O link de compartilhamento em dias é válido",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Não foi possível encontrar o arquivo!",
          sale: "Vendas:",
          customer: "Clientes:",
          topicDetection: "Detecção de tópicos:",
          keyEvents: "Eventos-chave:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Escolhendo colegas de trabalho",
          helpContent: "Conteúdo de ajuda",
          visibleRange: "Faixa visível",
          seekCounseling: "Procure aconselhamento",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Procure conselhos", concatText: "Para" },
      },
      customerInsight: {
        index: {
          customerInformation: "Informações do cliente",
          customerInsight: "Insight do cliente",
          customerLabel: "Rótulos de clientes",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Recomendação da fala",
          objectionHandlingScripts: "Tratamento de objeções",
          productSellingPointScripts: "Ponto de venda do produto",
          knowledgePointRecommendation:
            "Recomendação de pontos de conhecimento",
          relatedKnowledgePoints: "Pontos de conhecimento relacionados",
          recommendedCases: "Caso recomendado",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Recomendação da fala",
          caseRecommendation: "Recomendação de caso",
          hit: "Hit",
          miss: "Não atingido",
          supervisionItem: "Item de supervisão",
          implementation: "Situação de execução",
          reInspection: "Re-inspeção de qualidade",
          supervisionItemSettings: "Supervisionar as configurações do item",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Chamada de telefone",
          enterpriseWeChatCall: "WeChat da empresa",
          enterpriseMicroConversation: "Micro-sessão da empresa",
          open: "Ligado",
          close: "Fechar",
          conversationScenario: "Cena de sessão",
          monitorConversationChannels: "Canais de sessão supervisionados",
          permanentlyValid: "Eficaz para sempre",
          permanentlyInvalid: "Permanentemente inválido",
          customValidity: "Período de validade personalizado",
          termOfValidity: "Período de validade",
          newRating: "Nova classificação",
          whenConversation: "Quando a sessão",
          hit: "Hit",
          miss: "Não atingido",
          time: "Quando",
          rated: "A classificação é",
          qualified: "Qualificado",
          unqualified: "Não qualificado",
          modifiedSuccessfully: "Modificações bem-sucedidas",
          classificationName: "Nome da classificação",
          supervisionObject: "Objeto de supervisão",
          entryintoforceTime: "Tempo de vigência",
          supervisionItemRating: "Classificação de item de supervisão",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Verifique se a classificação do item de supervisão está correta",
          ratingRules: "Regras de classificação",
          settingOfRatingRulesForSupervisionItems:
            "Configuração da regra de classificação de item de supervisão",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Eventos-chave",
          supervisionItem: "Item de supervisão",
          conversationScenario: "Cena de sessão",
          customerLabel: "Rótulos de clientes",
          reDetectionRules: "Regras de redetecção",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Baixar gravações",
          downloadText: "Baixar o texto",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Fazer upload de uma imagem",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Por favor, escolha uma imagem com um tamanho não superior a 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Ou",
            and: "E",
            wrong: "Não",
            matchTo: "Correspondência para",
            rule: "Regras",
            satisfactionOperator: "Satisfaça o operador",
            customerLabel: "Rótulos de clientes",
            keyEvents: "Eventos-chave",
            knowledgePoints: "Pontos de conhecimento",
            sessionLabel: "Rótulos de sessão",
            supervisionItem: "Item de supervisão",
          },
          linkExplorer: { linkInTheText: "Link no texto:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound:
          "O componente personalizado não foi encontrado:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "Personalização" },
      dropdownMonit: {
        updateSucceeded: "A atualização foi bem-sucedida",
        areYouSureToDelete: "Tá certo de excluir?",
        custom: "Personalização",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Salvar a configuração atual do relatório === formulário",
        saveCurrentReportConfiguration:
          "Salvar a configuração atual do relatório",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Por favor, insira o nome da configuração comum, o comprimento máximo é 20!",
        preservation: "Salvar",
      },
      template: {
        saveCurrentReportConfiguration:
          "Salvar a configuração atual do relatório",
        updateCurrentReportConfiguration:
          "Atualizar a configuração atual do relatório",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Informações sobre a folha de trabalho",
        workOrder: "Lista de trabalho",
        triggerScenario: "Cena de gatilho",
        associatedCallConversation: "Chamadas/conversas associadas",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Teste com texto de comunicação/diálogo anterior",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "No.", day: "Dia" },
      businessSummary: {
        normal: {
          equalTo: "É igual a",
          notEqualTo: "Não é igual a",
          contain: "Contém",
          excluding: "Não contém",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Eu sou eu, não é o mesmo pirotecnia @ 156xxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Oi, eu sou um aviso de mensagem de alerta de inspeção de qualidade do robô, devido à adição ao grupo em 17 de outubro",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Eu sou eu, não é o mesmo pirotecnia @ 156xxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Oi, eu sou um aviso de mensagem de alerta de inspeção de qualidade do robô, devido à adição ao grupo em 17 de outubro",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Tópicos de comunicação",
          conversationSummary: "Resumo da sessão",
          serviceSuggestions: "Sugestões de serviço",
        },
        template: { smartTags: "Rótulos inteligentes" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Clique para saber mais" },
      schema: {
        textRobot: "Robô de texto",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Escolta de treinador, perguntas e respostas de documentos, diálogo de tarefas, perguntas e respostas de formulário, perguntas e respostas de mapa",
        capitalRetentionRobot: "Robô de retenção",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Ricas estratégias de questionamento e retenção de capital, comunicação de alto grau de personificação, garantia de alta taxa de retenção de capital, conversão automática eficiente de pistas",
        outboundRobot: "Robô de chamada externa",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Visitas de retorno após a conclusão da ordem de serviço, pesquisa de satisfação do serviço, avisos de falha de operação e manutenção, lembretes de entrega de pedidos, avisos de atividades de comércio eletrônico, compromissos de candidatos, para ajudar na automação de negócios e melhorar a eficiência dos negócios",
        incomingRobot: "Robô de entrada",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Integração flexível de pedidos de trabalho, pedidos e outros sistemas de negócios para realizar a automação do processo; reconhecimento personalizado do modelo semântico de voz e efeitos de negócios imediatos; entidade (número do telefone celular, valor do pedido, hora do pedido, endereço,...) Identificação, suporte rápido para negócios complexos",
        intelligentQualityInspection: "Inspeção de qualidade inteligente",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Inspeção de qualidade total, oportuna e eficiente, taxa de precisão de 85%",
        intelligentSessionAnalysis: "Análise de sessão inteligente",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Insights sobre as deficiências de negócios, ouvir as vozes dos clientes e melhorar a transformação do marketing",
        enterpriseWechatConversationAnalysis:
          "Análise de sessão de WeChat corporativo",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Identificação eficiente de oportunidades de negócios e riscos potenciais e aumento de 20% nas taxas de conversão de marketing",
        assistant: "Assistente de assento",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "O assistente de assento pode garantir que o atendimento ao cliente iniciante conclua o serviço e melhore a qualidade das chamadas de atendimento ao cliente. Deixe cada assento ser seu trunfo",
        digitalPeople: "Pessoas digitais",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          'Comunicação antropomórfica "face a face", suporte a interação multimodal de voz, texto, vídeo e toque, para obter uma experiência de comunicação imersiva e melhorar a imagem da marca e a experiência de serviço do usuário',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Conhecimento de AI em Zhongtai · Pesquisa corporativa",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Pesquisa de conhecimento, compartilhamento de conhecimento, pesquisa de documentos, pesquisa de encaixe de banco de dados",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Conhecimento de AI na plataforma · Base de conhecimento KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Fornece gerenciamento de ciclo de vida completo do conhecimento, manutenção unificada de robôs e bases de conhecimento de IM, suporte para encaixe de bases de conhecimento de terceiros, modelos de conhecimento, conhecimento atômico e outras funções poderosas para trazer uma experiência de gerenciamento de conhecimento mais conveniente",
        aIKnowledgeCenterKnowledgeMap:
          "Conhecimento de AI na plataforma · Atlas de conhecimento",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "O mapa de uma parada constrói e integra o conhecimento corporativo, exibe os detalhes do conhecimento em grande escala e os recursos de encaixe criam uma experiência personalizado",
        modularDevelopmentPlatformOriginalEngineASR:
          "Plataforma de desenvolvimento modular · Motor de coração original · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Usando algoritmos de ponta a ponta para alcançar a experiência de efeito personalizado de negócios, a taxa de reconhecimento de cena natural é superior a 90% e o reconhecimento de vocabulário personalizado é superior a 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Plataforma de desenvolvimento modular · Marketing inteligente",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Use algoritmos de aprendizagem profunda, combinados com retratos de usuários, para obter recomendações precisas de produtos e melhorar a conversão de vendas",
        modularDevelopmentPlatformUserPortrait:
          "Plataforma de desenvolvimento modular · Retrato do usuário",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Crie um retrato do usuário com precisão com base nos dados do usuário e nos dados de diálogo, descrevendo totalmente as características do cliente",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Plataforma de desenvolvimento modular · Recomendação inteligente",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "De acordo com o retrato do usuário e o retrato do produto, recomenda automaticamente aos usuários produtos que possam estar interessados e realiza uma recomendação personalizada de milhares de pessoas",
        modularDevelopmentPlatformNLPPAAS:
          "Plataforma de desenvolvimento modular · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Fornece uma capacidade básica de PNL de alta precisão, cobrindo a capacidade subjacente de diferentes granularidade, como palavras, frases e artigos",
      },
    },
    qualityScore: {
      index: {
        show: "Exibido:",
        displayBySecondaryClassification:
          "Exibido por classificação secundária",
        showByRule: "Mostrar por regras",
        pleaseEnterClassificationruleName:
          "Por favor, insira o nome da classificação/regra",
        clickCopy: "Clique em Copiar",
        hitDetailsOfOperatorsInRules:
          "Detalhes do acerto do operador dentro da regra:",
        hit: "Hit",
        miss: "Não atingido",
        filter: "Triagem:",
        clickToViewDetails: "Clique para ver detalhes",
      },
      components: {
        flowNodeList: {
          branch: "Pontos",
          pleaseSelect: "Por favor, escolha",
          viewTheCompleteProcess: "Veja o processo completo",
          thereIsCurrentlyNoProcessAvailable: "Sem processo",
        },
        pointItem: { xiangHit: "Item acertado" },
        flowNodeListModal: { index: { completeProcess: "Processo completo" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Se a tarefa de inspeção de qualidade do processo for alterada, por favor, apele novamente.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "Os dados foram limpos regularmente",
        subject: "Assunto:",
        description: "Descrição:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask:
              "Adicionar a uma tarefa de inspeção de qualidade",
          },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Chamadas/conversas associadas" },
    },
    samplingTarget: {
      index: {
        time: "Quando",
        samplingQuantityIsLessThan:
          "O número de inspeções aleatórias é inferior",
        alertWhen: "Alerta de tempo",
        everyday: "Diário",
        weekly: "Semanal",
        monthly: "Mensal",
        samplingInspectionTargetSavedSuccessfully:
          "O alvo da inspeção aleatória foi salvo com sucesso",
        personnel: "Pessoal",
        eachCustomerService: "Cada atendimento ao cliente",
        cycle: "Ciclo",
        samplingInspectionTarget: "Objetivo de inspeção aleatória",
        strip: "Artigo",
        alertSettings: "Configurações de alerta",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Atendimento ao cliente",
        complete: "Concluído",
        Objective: "/Objetivos",
        everyday: "Diário",
        weekly: "Semanal",
        monthly: "Mensal",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Taxa de interação de bate-papo em grupo",
        maximumResponseIntervalTime: "Tempo de intervalo máximo de resposta",
        numberOfPreemptiveCalls: "Número de chamadas roubadas",
        numberOfMessages: "Número de mensagens",
        strip: "Artigo",
        proportionOfMessages: "A proporção de mensagens",
        speechPercentage: "Proporção da fala",
        longestCustomerTalk: "Maior discurso do cliente",
        numberOfIndepthDiscussions: "Número de discussões em profundidade",
        second: "Vezes",
        maximumDurationOfContinuousSpeech:
          "Duração de fala contínua mais longa",
        questionFrequency: "Perguntas frequentes",
        customer: "Clientes",
        keyEvents: "Eventos-chave:",
        opportunity: {
          index: {
            generationFailed: "Falha na geração",
            generateOpportunityPoints: "Gerar pontos de oportunidade",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Falha na geração",
            generateSummary: "Gerar um pequeno nó",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Tá certo de excluir?",
        add: "Adicionar",
        opportunityPoints: "Ponto de oportunidade",
        conversationSummary: "Resumo da sessão",
        cancel: "Cancelamento",
        determine: "OK",
        edit: "Editar",
        establish: "Criar",
        conversationalInsights: "Insights de conversação",
        keyEvents: "Eventos-chave",
        numKeyEvents: "Um evento chave",
        questionAnalysis: "Análise de perguntas",
        find: "Descoberta",
        questions: "Perguntas",
        conversationAction: "Ação de sessão",
        customer: "Clientes",
        sale: "Vendas",
        sessionLabel: "Cena de sessão",
        employeeQuestions: "Perguntas dos funcionários",
        customerQuestions: "Perguntas do cliente",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Clientes",
        customerService: "Atendimento ao cliente",
        replyTo: "Responder a",
        addressee: "Destinatário:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Reidentificação",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "A reidentificação consumirá o tempo de transliteração, por favor, tenha cuidado",
        determine: "OK",
        executionSuccessful: "Execução bem sucedida",
        addToQualityInspectionTask:
          "Adicionar a uma tarefa de inspeção de qualidade",
        batchOperation: "Operação em lote",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Adição de sucesso",
        addToQualityInspectionTask:
          "Adicionar a uma tarefa de inspeção de qualidade",
        qualityInspectionTask: "Tarefa de inspeção de qualidade",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Detecção de papéis",
        detectionRange: "Faixa de detecção",
        sentence: "Frase",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "Os dados foram limpos regularmente",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Voltar ao sistema de negócios" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Extração de perguntas",
        businessSummary: "Resumo de negócios",
        keyWord: "Palavras-chave",
      },
      components: {
        problemExtraction: {
          no: "Não.",
          customerServiceDidNotAnswer: "Atendimento ao cliente não respondeu",
          customerServiceHasAnswered: "O atendimento ao cliente respondeu",
          problemUnresolved: "Problema não resolvido",
          theProblemHasBeenResolved: "Problema resolvido",
          customerIssue: "Pergunta do cliente:",
          customerServiceAnswer: "Respostas de atendimento ao cliente:",
          reExtractQuestions: "Problema de reextração",
          clickToExtractQuestions: "Clique na pergunta de extração",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Nome do retrato",
            backgroundDescription: "Descrição do plano de fundo",
            customerLabel: "Rótulos de clientes",
            labelManagement: "Gestão de etiquetas",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Novo" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Configuração de questionamento" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Aumentar o questionamento",
              remove: "Remover",
              addQuestioning: "Adicionar perguntas",
              section: "No.",
              secondInquiry: "Questionamento",
              questioningScore: "Pontuação de questionamento",
            },
          },
        },
        index: {
          accordingToRules: "Por regra",
          byScore: "Pressione o placar",
          whenMissRule: "Quando as regras de falha",
          whenConductingQuestioning: "Quando, faça perguntas",
          pleaseSelectARule: "Por favor, escolha a regra",
          ruleCannotBeEmpty: "As regras não podem ser vazias",
          currentScriptScore: "Quando a pontuação da fala ≤",
          pleaseEnterTheScore: "Por favor, insira a pontuação",
          scoreCannotBeEmpty: "A pontuação não pode ser nula",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Narração:" },
          pPT: { slide: "Slides" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Comentário sobre a técnica",
              script: "A técnica da fala",
              semantics: "Semântica",
              violatingWords: "Palavras de violação",
              speechSpeed: "Velocidade da fala",
              emotion: "Emoções",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Comentário sobre a técnica",
              regenerate: "Re-geração",
              generate: "Geração",
              hitSituation: "Situação de acerto",
              noSemanticTagsMatched: "Não corresponde a um rótulo semântico",
              matchToSemanticTags: "Correspondência a um rótulo semântico",
              appealToBeSubmitted: "Recurso a ser apresentado",
              ratingHasBeenReviewed: "As classificações foram revisadas",
              ratingAppealInProgress: "Em recurso de pontuação",
              expressionScore: "Pontuação de expressão",
              moodParticlesTimes: "Palavras de linguagem, {0} vezes",
              changeTheModalParticlesTo: "A palavra de tom é alterada para",
              second: "Vezes",
              interrogation: "Questionamento",
              questioningWithoutScoring: "Perguntado sem pontuação",
              branch: "Pontos",
              matchingRate: "Taxa de correspondência",
              ratingAppeal: "Apelação de pontuação",
              changeTo: "Alterado para",
              wordPerMinute: "Palavra por minuto",
              completeScript: "A técnica está completa",
              incompleteScript: "A fala está incompleta",
              semanticHit: "Hit semântico",
              semanticMisses: "Inacerto semântico",
              scriptViolation: "Violação da fala",
              modified: "Modificado",
              notChanged: "Não modificado",
              keyWord: "Palavras-chave",
              semanticLabels: "Rótulos semânticos",
              semanticMatchingRate: "Taxa de correspondência semântica",
              violatingWords: 'Palavras ilegais"',
              delete: "Excluir",
              unmatched: "Não correspondido",
              matching: "Correspondência",
              matchTo: "Correspondência para",
              notMatchedTo: "Não correspondido a",
              scriptScore: "Pontuação de palavras",
              standardScript: "A técnica padrão",
              emotionalScore: "Pontuação emocional",
              speechSpeedScore: "Pontuação de velocidade de fala",
              viewResolution: "Ver análise",
              nodeConfiguration: "Configuração do nó",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "O tempo de resposta para esta pergunta está excedendo e o conteúdo da resposta é enviado automaticamente",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "O tempo de resposta para esta pergunta está excedendo e o conteúdo da resposta é enviado automaticamente",
          },
          correctBtn: {
            saveSuccessful: "Salve o sucesso",
            errorCorrection: "Correção de erros",
            cancel: "Cancelamento",
            preserve: "Salvar",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Regras de pontuação",
            cancel: "Cancelamento",
            determine: "OK",
          },
          scoringRuleItem: {
            whole: "Todos",
            arbitrarily: "Arbitrário",
            pleaseEnterARuleName: "Insira o nome da regra",
            standardScript: "A técnica padrão",
            if: "Se",
            and: "E",
            or: "Ou",
            thenScore: "Pontuação",
            pleaseEnterAScore: "Por favor, insira a pontuação",
            bonusPoints: "Pontos extras",
            minusPoints: "Pontos de redução",
            hit: "Hit",
            misses: "Não atingido",
            keyWord: "Palavras-chave",
            semantics: "Semântica",
          },
          title: {
            ruleValidation: "Verificação de regras",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Regras de pontuação (a pontuação total da conversa atual é",
            points: "Pontos)",
            editRules: "Editar regras",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Sem rótulo" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Objetivos de comunicação",
              scoringCriteria: "Critérios de classificação",
            },
          },
        },
        index: {
          generating: "Em geração",
          generateDialogueFramework: "Gerar estrutura de diálogo",
          theme: "Tema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Prompt",
            generatePromptLanguage: "Gerar um prompt",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Fase de comunicação",
          communicationObjectives: "Objetivos de comunicação",
          scoringCriteria: "Critérios de classificação",
          suggestionsForImprovement: "Sugestões de elevação",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Pontuação do processo",
            contentIntegrity: "Integridade do conteúdo:",
            NumberOfCompletedProcesses: "%, Número de processos concluídos",
            keyHitPointsTotal: "Pontos principais acertos: total",
            notReached: "Um não alcançado",
            accurateFollowupAccumulationOfStandardScripts:
              "Siga a leitura com precisão: acúmulo de habilidades de fala padrão",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Uma palavra, o número de palavras que os alunos seguem",
            aWord: "Uma palavra",
            languageNormsYes: "Especificação de idioma: Sim",
            emotionsAppearing: "Emoções: Aparece",
            speechSpeedSpeakingTooFast:
              "Velocidade da fala: velocidade da fala muito rápida",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Palavras de tom: muitas palavras de tom aparecem",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Adicionar a ignorar rótulos",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Tenho certeza de que deseja executar novamente a etiqueta inteligente?",
        reExecute: "Reexecução",
      },
      component: { successfullyAdded: "Adição de sucesso" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Desativar todas as notificações",
        reQualityInspectionTask: "Tarefa de reinspeção de qualidade",
        requalityInspection: "Re-inspeção de qualidade",
        waiting: "À espera",
        viewDetails: "Ver detalhes",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Tempo de detecção:" } },
        },
        template: { responseOpeningSentence: "Sentença inicial de resposta" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "Falha na geração", generate: "Geração" },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Sem dados" } },
      },
      index: { regenerate: "Re-geração" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Tá certo de excluir?",
        operationSuccessful: "Operação bem sucedida",
      },
      template: { delete: "Excluir" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Por favor, escolha a pergunta!",
        similarQuestionRecommendation: "Recomendação de perguntas semelhantes",
        selectAllOnPage: "Quando todas as páginas forem selecionadas",
        cancel: "Cancelamento",
        preserve: "Salvar",
        changeBatch: "Mudar um lote",
      },
    },
    listControl: { index: { columnControl: "Controle de coluna" } },
    loginUserAvatar: { index: { switch: "Alternar" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Quando os dados são adicionados em tempo real, a tarefa de inspeção de qualidade ainda consome tempo. O tempo específico depende da quantidade de dados de inspeção de qualidade, variando de alguns minutos a 2 horas.",
      },
    },
    bEditor: {
      index: {
        delete: "Excluir",
        cancel: "Cancelamento",
        confirm: "OK",
        insert: "Inserção",
        width: "Largura",
        height: "Altura",
        clearContent: "Limpar o conteúdo",
        revoked: "Revogação",
        redo: "Refeito",
        fontSize: "Tamanho da fonte",
        rowHeight: "Altura da linha",
        wordSpacing: "Espaçamento de palavras",
        paragraphIndent: "Cindentação de parágrafo",
        increaseIndent: "Aumento da retração",
        decreaseIndent: "Reduzindo o recuo",
        border: "Borda",
        color: "Cor",
        textColor: "Cor do texto",
        backgroundColor: "Cor de fundo",
        temporaryColor: "Cores temporárias",
        bold: "Em negrito",
        italic: "Em itálico",
        underline: "Sublinhado",
        strikethrough: "Linha apagada",
        font: "Fonte",
        textAlignment: "Alinhamento de texto",
        left: "À esquerda",
        centered: "No meio",
        right: "À direita",
        bothEnds: "Ambas as extremidades",
        leftFloat: "Esquerda Flutuante",
        rightFloat: "Flutuante à direita",
        subrupt: "Sobrescrito",
        subscript: "Subscrito",
        clearStyle: "Limpar estilos",
        title: "Título",
        regular: "Rotina",
        orderedList: "Lista ordenada",
        unorderedList: "Lista de desordem",
        reference: "Citações",
        code: "Código",
        link: "Link",
        clearLink: "Limpar o link",
        horizontalLine: "Linha horizontal",
        media: "Mídia",
        mediaLibrary: "Biblioteca de mídia",
        smallExpression: "Pequenas expressões",
        fullScreen: "Tela cheia",
        exitFullScreen: "Sair de tela cheia",
        enterLinkText: "Digite o texto do link",
        enterLinkAddress: "Insira o endereço do link",
        enterTheLinkAddressAndPressEnter:
          "Digite o endereço do link e devolver o carro",
        openInNewWindow: "Abre em uma nova janela",
        removeLink: "Remover um link",
        playingAudioFiles: "Reproduzir arquivos de áudio",
        playVideoFiles: "Reproduzir arquivos de vídeo",
        embeddedMedia: "Mídia incorporada",
        image: "Imagem",
        video: "Vídeo",
        audio: "Áudio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Nome",
        description: "Descrição",
        type: "Tipo",
        status: "Estado",
        actions: "Operação",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Nome da tarefa",
        description: "Descrição",
        form: "Formulário de inspeção de qualidade",
        actions: "Operação",
        createUser: "Criador",
      },
      samplingRecords: {
        createTime: "Tempo",
        type: "Tipo",
        operateUser: "Homem de operação",
        number: "Adicionar quantidade",
        filterConditionList: "Condições de triagem",
        sampleRuleStr: "Condições de amostragem",
        timeGreaterThan: "Tempo maior que",
        timeLessThan: "Tempo menor que",
      },
    },
    customFilters: {
      createdAt: "Tempo de conversação",
      customerId: "Clientes",
      agentId: "Atendimento ao cliente",
      userGroupId: "Grupo de atendimento ao cliente",
      state: "Estado",
      lastEvaluateUserId: "Última pessoa de classificação",
      submitTime: "Última hora de envio",
      totalScore: "Pontuação total",
      formItemForbidIdList: "Item proibido",
      formItemDeadlyIdList: "Fatal",
    },
    samplingInspection: {
      callTime: "Tempo de conversação",
      dialogTime: "Tempo de conversação",
      originator: "Atribuir pessoas",
      createTime: "Tempo de criação",
      updateTime: "Tempo de atualização",
      status: "Estado do processo",
      score: "Pontuação",
      actions: "Operação",
      reviewer: "Reinspetor",
      samplingOfficer: "Inspetor de amostragem",
      id: "ID do registro de chamadas",
      time: "Tempo de conversação",
      phone: "Telefone do cliente",
      staff: "Atendimento ao cliente",
      inspectionStatus: "Status de inspeção de qualidade",
      samplingStatus: "Status de inspeção aleatória",
      inspectionGrade: "Classificação",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Palavra padrão",
        synonymWords: "Sinônimos",
        synonymWordsNumber: "Número de sinônimos",
        useFrequency: "Frequência de uso",
        effectiveness: "Eficácia",
        operation: "Operação",
      },
      common: {
        useFrequency: "Frequência de uso",
        effectiveness: "Eficácia",
        operation: "Operação",
      },
      professionalWords: {
        professionalWords: "Substantivo profissional",
        useFrequency: "Estatísticas de uso",
        homophonic: "Palavras quentes (substituição homofônica)",
        target: "Substituição de substantivos",
        targetWords: "Palavra de substituição",
        homophonyWords: "Palavras de escudo homofônico",
        operation: "Operação",
      },
      attentionWords: "Palavras de preocupação",
      ignoreWords: "Ignorar a palavra",
      wordsWeight: {
        selfDefineWeightWords: "Palavras de peso personalizadas",
        weightWords: "Palavras de peso",
        weight: "Peso",
        useFrequency: "Frequência de uso",
        effectiveness: "Eficácia",
        operation: "Operação",
      },
      corpusData: {
        corpusDescription: "Descrição do corpus",
        updateTime: "Tempo de atualização",
        wordsCount: "Número de palavras",
        effectiveness: "Eficácia",
        operation: "Operação",
        corpusContent: "Conteúdo",
      },
    },
    semanticTags: {
      title: "Rótulos semânticos",
      tags: "Sentença padrão",
      orgName: "Organização",
      tagCount: "Número de treinamento",
      usingCount: "Número de referências",
      activeFlag: "Eficácia",
      suggestion: "Recomendações de treinamento",
      actions: "Operação",
    },
    intelligentTags: {
      tagName: "Nome",
      tagDesc: "Descrição",
      tagRefCnt: "Número de marcadores",
      categoryName: "Classificação de rótulos",
      eventName: "Classificação de eventos",
      tagStatus: "Eficácia",
      actions: "Operação",
      tagType: "Tipo de etiqueta",
    },
    dataPreprocess: {
      name: "Nome",
      desc: "Descrição",
      dataSource: "Restrições de fonte de dados",
      type: "Tipo",
      status: "Eficácia",
      actions: "Operação",
    },
    informationEntities: {
      name: "Nome",
      apiName: "Nome da API",
      type: "Tipo",
      remark: "Descrição",
      activeFlag: "Eficácia",
      actions: "Operação",
    },
    interactiveRecognition: {
      questionAndAnswer: "Perguntas padrão & respostas",
      type: "Tipo",
      status: "Estado",
      actions: "Operação",
      question: "Problema",
    },
    role: { id: "ID", name: "Nome", actions: "Operação" },
    uploadDownload: {
      taskName: "Nome do arquivo",
      createTime: "Data",
      size: "Tamanho",
      createUserName: "Operador",
      status: "Estado",
      result: "Resultados",
      actions: "Operação",
    },
    taskCenterList: {
      name: "Conteúdo da tarefa",
      createTime: "Tempo de envio",
      startTime: "Hora de início",
      endTime: "Hora do fim",
      createUserName: "Operador",
      status: "Estado",
      result: "Resultados",
      actions: "Operação",
    },
    customerInformation: {
      title: "Informações de atendimento ao cliente",
      data: "Visualizar apenas dados de exceção",
      list: {
        agentNo: "Número de trabalho/ID de atendimento ao cliente",
        agentName: "Atendimento ao cliente",
        agentTel: "Telefone",
        agentEmail: "Caixa de correio",
        updateTime: "Tempo de atualização",
        actions: "Operação",
      },
      upload: {
        second:
          "2. Clique no botão Importar para fazer upload do arquivo EXCEl que esteja em conformidade com o formato do modelo",
      },
    },
    IndexManagement: {
      title: "Gestão de indicadores",
      list: {
        indicatorName: "Nome",
        indicatorDesc: "Descrição",
        activity: "Eficácia",
        inspectDataSource: "Fonte de dados",
        sysType: "Tipo",
        agentGroups: "Grupo de atendimento ao cliente",
        actions: "Operação",
      },
      detail: {
        nameEmpty: "O nome não pode estar vazio",
        descript: "Descrição",
        descriptEmpty: "A descrição não pode ser vazia",
        active: "Eficácia",
        activeEmpty: "A eficácia não pode ser vazia",
        dataSource: "Fonte de dados",
        dataSourceEmpty: "A fonte de dados não pode estar vazia",
        grounp: "Grupo de atendimento ao cliente",
        grounpEmpty: "O grupo de atendimento ao cliente não pode estar vazio",
        grounpHolder: "Por favor, selecione o grupo de atendimento ao cliente",
        type: "Método de classificação",
        typeNone: "O método de classificação não pode ser vazio",
        base: "Pontos básicos",
        baseNone: "A base não pode ser vazia",
        positive: "Fatores positivos",
        positiveNone: "Fatores positivos não podem ser vazios",
        customAdd: "Adicionar filtros",
        negative: "Fatores negativos",
        negativeNone: "Fatores negativos não podem ser vazios",
        customOK: "Condições de triagem:",
        judgeStrategy: "Regras condicionais:",
        other: "Outros fatores",
        otherAdd: "Adicionar fatores",
        agent: "Dados brutos",
        section: "Número de intervalos",
        sectionNone: "O número de intervalos não pode ser vazio",
        step: "Passo do intervalo",
        stepNone: "Passo do intervalo",
        save: "Salvar",
        cancel: "Cancelamento",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Todos",
      staff: "Só eu",
      department: "Este departamento e abaixo",
    },
    gongStatisticsDate: { week: "Semana", month: "Mês", season: "Temporada" },
    gongCoachTaskStatus: {
      wait: "Aconselhamento",
      already: "Aconselhado",
      ignore: "Ignorado",
    },
    callRangeStatus: {
      all: "Todas as chamadas",
      sale: "Iniciação de funcionários",
      customer: "Iniciação do cliente",
    },
    todoListType: {
      later: "Ouça mais tarde",
      help: "Aconselhamento para obter ajuda",
      contact: "Contato mais tarde",
      send: "Enviar dados",
      warn: "Alerta de risco",
    },
    frequencyTypes: {
      everyDay: "Diário",
      everyWeek: "Semanal",
      everyMonth: "Mensal",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Treinamento de dados não classificados",
        markedDataCorrigenda: "Errata de dados marcada",
        classifiedDataCheck: "Verificação de dados classificados",
        markedHistoryRecord: "Histórico de anotações",
        historyDataRecalculate: "Recontagem de dados históricos",
      },
      dataExtractMode: {
        random: "Totalmente aleatório",
        intelligentRecommend: "Recomendação inteligente",
        custom: "Personalização",
        specifiedClass: "Especificar a classificação",
      },
      smartRatesList: {
        markRate: "Taxa de rotulagem",
        corrigendaRate: "Taxa de errata",
        kl: "K-L divergência",
        signalNoiseRate: "Relação sinal-ruído",
      },
    },
    visibleRangeTypes: {
      all: "Todos",
      customerGroup: "Grupo de atendimento ao cliente",
      userDefined: "Personalização",
    },
    samplingRateTypes: {
      everyDay: "Extração diária",
      everyWeek: "Extração semanal",
    },
    taskRemindTypes: {
      confirm:
        "Envie um lembrete quando o registro da tarefa de inspeção de qualidade precisar ser confirmado",
      appeal:
        "Envie um lembrete quando o registro da tarefa de inspeção de qualidade iniciar uma reclamação",
    },
    weekDays: {
      monday: "Segunda-feira",
      tuesday: "Terça-feira",
      wednesday: "Quarta-feira",
      thursday: "Quinta-feira",
      friday: "Sexta-feira",
      saturday: "Sábado",
      sunday: "Domingo",
    },
    reportDateTypes: {
      dailyReport: "Relatório diário",
      weeklyReport: "Relatório semanal",
    },
    samplingRangeDayTypes: { today: "Hoje", yesterday: "Ontem" },
    samplingRangeWeekTypes: {
      thisWeek: "Esta semana",
      lastWeek: "Semana passada",
    },
    samplingRuleTypes: {
      proportion: "Amostragem proporcional",
      random: "Amostragem aleatória",
    },
    taskStatusTypes: {
      waitEvaluate: "A ser avaliado",
      waitSubmit: "A ser submetido",
      waitConfirm: "A confirmar",
      waitReview: "A ser revificado",
      completed: "Concluído",
      all: "Todos",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Classificação direta",
        weightedScore: "Pontuação de peso",
        deductScore: "Dedução de pontos",
        directScoreSecondLevel: "Classificação direta-Nível 2",
        weightedScoreSecondLevel: "Pontuação de peso-Nível 2",
        deductScoreSecondLevel: "Dedução-Nível 2",
      },
    },
    fieldOperators: {
      is: "É igual a",
      not: "Não é igual a",
      contains: "Contém",
      notContains: "Não contém",
      not_contains: "Não contém",
      containsAny: "Contém qualquer",
      contains_any: "Contém qualquer",
      is_any: "Contém qualquer",
      not_any: "Não contém arbitrário",
      notContainsAny: "Não contém arbitrário",
      not_contains_any: "Não contém arbitrário",
      containsAll: "Contém todos",
      contains_all: "Contém todos",
      notContainsAll: "Não contém todos",
      nont_contains_all: "Não contém todos",
      isNull: "Para o vazio",
      is_null: "Para o vazio",
      isNotNull: "Não para o vazio",
      is_not_null: "Não para o vazio",
      greaterThan: "Maior que",
      greater_than: "Maior que",
      greaterThanEq: "Maior que igual a",
      greater_than_eq: "Maior que igual a",
      lessThan: "Menor que",
      less_than: "Menor que",
      lessThanEq: "Menos do que igual a",
      less_than_eq: "Menos do que igual a",
      between: "Em algum lugar",
      notBetween: "Não entre",
      not_between: "Não entre",
      relative: "É igual a",
      set: "A configuração é",
      belongs: "Pertence",
      notBelongs: "Não pertence",
      not_belongs: "Não é igual a",
      today: "Hoje",
      yesterday: "Ontem",
      the_day_before_yesterday: "Anteontem",
      tomorrow: "Amanhã",
      next_seven_day: "Nos próximos sete dias",
      last_seven_day: "Os últimos sete dias",
      this_week: "Esta semana",
      next_week: "Na próxima semana",
      last_week: "Semana passada",
      this_month: "Este mês",
      next_month: "No próximo mês",
      last_month: "Mês passado",
      this_year: "Este ano",
      next_year: "Ano que vem",
      last_year: "Ano passado",
      exact_match: "Correspondência exata",
      add_value: "Novo valor agregado",
      remove_value: "Excluir valores",
      set_null: "Vazio",
      isAny: "Qualquer é igual a",
      notAny: "Arbitrário não é igual a",
      belongTo: "Pertence",
      notBelongTo: "Não pertence",
      hasAny: "Contém qualquer",
      notHasAny: "Não contém arbitrário",
      prefixContains: "O início é igual a",
      prefixNotContains: "O início não é igual a",
      suffixContains: "O final é igual a",
      suffixNotContains: "O final não é igual a",
      nextSevenDay: "Os próximos 7 dias",
      lastSevenDay: "Últimos 7 dias",
      thisWeek: "Esta semana",
      nextWeek: "Na próxima semana",
      lastWeek: "Semana passada",
      thisMonth: "Este mês",
      nextMonth: "No próximo mês",
      lastMonth: "Mês passado",
      thisYear: "Este ano",
      nextYear: "Ano que vem",
      lastYear: "Ano passado",
      after: "Mais tarde (contém o dia)",
      before: "Mais cedo (incluindo o dia)",
      allMatching: "Todas as correspondências",
      allNotMatching: "Todos não correspondem",
      hit: "Hit",
      not_hit: "Não atingido",
    },
    fieldsOperators: {
      is: "É igual a",
      is_null: "Ao mesmo tempo vazio",
      head_equal: "O início é igual a",
      tail_equal: "O final é igual a",
      is_not_null: "Não vazio ao mesmo tempo",
      not: "Não é igual a",
      diff_equal: "A diferença é igual a",
      diff_greater_than: "A diferença é maior que",
      diff_greater_than_eq: "A diferença é maior ou igual a",
      diff_less_than: "A diferença é menor que",
      diff_less_than_eq: "A diferença é menor ou igual a",
      hour_equal: "A mesma hora",
      day_equal: "No mesmo dia",
      week_equal: "Na mesma semana",
      month_equal: "No mesmo mês",
      year_equal: "No mesmo ano",
      day: "Dia",
      minute: "Minutos",
      hour: "Horas",
      part_equal: "Parte é igual a",
    },
    samplingColumnCaptions: {
      created_at: "Tempo de conversação",
      agent_id: "Atendimento ao cliente",
      get_direction: "Tipo de chamada",
      call_result: "Resultados da chamada",
      survey: "Avaliação",
      derived_from_id: "Fonte",
      quit_queue_why: "Estado de fila",
      queuing_duration: "Filas demoradas",
      talking_seconds: "Dduração da chamada",
      ringing_seconds: "Tempo de toque",
      hangup_by: "O lado da chamada",
    },
    callTypes: {
      callIn: "Chamada",
      callOut: "Expire",
      threeParty: "(Três partes)",
      consultation: "(Consulta)",
      insert: "(Inserção forte)",
      monitor: "(Escuta)",
      transfer: "(Transferência)",
      intercept: "(Interceptação)",
      transferOutside: "(Transferência para fora)",
      threePartyOutside: "(Linha externa tripartida)",
      consultingOutside: "(Consulta externa)",
    },
    callResults: {
      staffAnswer: "Atendimento ao cliente atende",
      staffNotAnswer: "Atendimento ao cliente não recebido",
      customerAnswer: "Resposta do cliente",
      customerNotAnswer: "Cliente não atendeu",
      phoneBusy: "O telefone está ocupado",
      phoneOffline: "O telefone está offline",
      customerSpeedHangUp: "Clientes pendurados rapidamente",
      customerHangUp: "Clientes desligados",
      queueTimeout: "Tempo de espera",
      queueGiveUp: "Desista da fila",
      outlineAnswer: "Resposta externa",
      outlineNotAnswer: "A linha externa não está conectada",
      noChooseQueue: "Fila não selecionada",
    },
    defaultSurveys: {
      noEvaluation: "Não avaliado",
      noNeedToEvaluate: "Sem avaliação",
    },
    queueStates: {
      queueSuccess: "A fila para o sucesso",
      queueTimeout: "Tempo de espera",
      queueGiveUp: "Desista da fila",
      noStaffOnline: "Sem atendimento ao cliente online",
    },
    callHangers: {
      customer: "Clientes",
      staff: "Atendimento ao cliente",
      outline: "Linha externa",
    },
    workFlowTypes: {
      noAppealsMode: "Sem modo de recurso",
      appealsMode: "Modo de recurso permitido",
    },
    fieldCategories: {
      standardCondition: "Condições de triagem padrão",
      customerCondition: "Filtragem definida pelo usuário",
    },
    sampleTypes: {
      automatic: "Amostragem automática",
      manual: "Amostragem manual",
    },
    defaultFields: {
      createdAt: "Tempo de conversação",
      agentId: "Atendimento ao cliente",
      getDirection: "Tipo de chamada",
      callResult: "Resultados da chamada",
      survey: "Avaliação",
      derivedFrom: "Fonte",
      quitQueueWhy: "Estado de fila",
      queuingDuration: "Filas demoradas",
      talkingSeconds: "Dduração da chamada",
      ringingSeconds: "Tempo de toque",
      hangupBy: "O lado da chamada",
    },
    conditionFieldCategories: {
      fixedField: "Campos fixos",
      customField: "Campos personalizados",
      secondLevelScore: "Classificação secundária",
      formItemScore: "Item de pontuação",
      forbids: "Item proibido",
      deadlies: "Fatal",
    },
    columnFieldCategories: {
      agent: "Atendimento ao cliente",
      callLog: "Registro de chamadas",
      businessFields: "Informações de negócios",
      customer: "Clientes",
      customerCustomFields: "Personalização do cliente",
      source: "Fonte",
      queue: "Fila",
      fixed: "Fixação",
      secondLevelScore: "Classificação secundária",
      formItemScore: "Item de pontuação",
      forbids: "Item proibido",
      deadlies: "Fatal",
    },
    taskListDefaultFields: {
      startTime: "Tempo de conversação",
      name: "Atendimento ao cliente",
      state: "Estado",
      totalScore: "Pontuação total",
      formItemForbidList: "Item proibido",
      formItemDeadlyList: "Fatal",
      comment: "Comentários de avaliação",
      inspector: "Pontuação de pessoas",
      review: "Reinspetor",
    },
    evaluationStates: {
      initial: "Inicialização",
      waitEvaluate: "A ser avaliado",
      evaluated: "A ser submetido",
      waitConfirm: "A confirmar",
      waitCheck: "A ser revificado",
      complete: "Concluído",
    },
    workBenchInfoTypes: {
      callInfo: "Informações sobre chamadas",
      businessInfo: "Informações de negócios",
      customerInfo: "Informações do cliente",
    },
    evaluationEventStates: {
      create: "Criar",
      valuate: "Avaliação",
      edit: "Editar",
      submit: "Enmissão",
      confirm: "Confirmação",
      appeal: "Reclamação",
      checkConfirm: "Confirmação de revisão",
      check: "Revisão",
    },
    formItemTypes: {
      direct: "Classificação direta",
      weight: "Peso",
      deduct: "Dedução de pontos",
      forbid: "Item proibido",
      deadly: "Fatal",
    },
    appealCheckCallTypes: {
      callIn: "Chamada",
      callBack: "Rechamada bidirecional",
      callOut: "Discagem direta",
      autoCall: "Chamada automática",
    },
    appealCheckCallStatus: {
      waitReview: "A ser revificado",
      reviewed: "Reverificado",
      filed: "Arquivamento",
    },
    samplingStatus: {
      all: "Todos",
      unchecked: "Extração não verificado",
      checked: "Foi verificado aleatoriamente",
    },
    inspectionStatus: {
      all: "Todos",
      unread: "Não lido",
      readed: "Já leu",
      appeal: "Reclamação",
      review: "Revisão",
      filed: "Arquivamento",
    },
    gradeChooseHits: { yes: "Sim", no: "Não." },
    appealCheckStatus: {
      all: "Estado total",
      unread: "Não lido",
      inComplaint: "No recurso",
      reviewed: "Reverificado",
      readed: "Já leu",
      filed: "Arquivamento",
      spotchecked: "Foi verificado aleatoriamente",
    },
    flowStatus: {
      unread: "Não lido",
      alreadyRead: "Já leu",
      complaining: "No recurso",
      reviewed: "Reverificado",
      sampling: "Extração não verificado",
      samplingCompleted: "Foi verificado aleatoriamente",
      complainCancel: "Revogado",
    },
    complainStatus: {
      success: "Sucesso",
      fail: "Falha",
      default: "No recurso",
      canceled: "Revogado",
    },
    reviewInspectionStatus: {
      inComplaint: "No recurso",
      reviewed: "Reverificado",
    },
    gradeChangeTypes: {
      edit: "Editar",
      check: "Revisão",
      file: "Arquivamento",
      remark: "Observações",
      complain: "Reclamação",
      review: "Revisão",
      reviewEvaluation: "Avaliação de revisão",
      sample: "Inspeção aleatória",
      sampleEvaluation: "Avaliação de amostragem",
      sampleSubmit: "Apresentação de inspeção aleatória",
      sampleAssign: "Atribuição de amostragem",
      reviewAssign: "Distribuição de revisão",
      read: "Já leu",
      reviewConfirm: "Confirmação de revisão",
      sampleConfirm: "Confirmação de inspeção aleatória",
      caseBase: "Adicionar a um grupo de casos",
      sampleCancel: "A inspeção aleatória cancela a distribuição",
      reviewCancel: "Revisão para cancelar a distribuição",
      sampleAgain: "Realocação de inspeção aleatória",
      reviewAgain: "Realocação de revisão",
      btachDelete: "Excluir registros de amostragem",
      rapidSampling: "Inspeção de amostragem rápida",
      reInspection: "Re-inspeção de qualidade",
      smartTagsChange: "Mudança de etiqueta inteligente",
      cancelAppeal: "Recurso de revogação",
    },
    appealTypes: {
      transliterationError: "Erro de transliteração",
      discriminationError: "Julgamento de erros",
      otherError: "Outros erros",
    },
    inspectDataSources: {
      voiceCall: "Chamada de voz",
      textDialogue: "Diálogo de texto",
      realTimeVoiceCall: "Chamada de voz em tempo real",
      realTimeTextDialogue: "Conversas de texto em tempo real",
      wechatDialogue: "Micro-sessão da empresa",
      taobao: "Diálogo de texto de comércio eletrônico",
      ticket: "Lista de trabalho",
      wechatRadio: "Microvoz da empresa",
    },
    inspectTaskType: {
      all: "Todos",
      common: "Inspeção de qualidade convencional",
      relate: "Inspeção de qualidade associada",
    },
    inspectApproveType: {
      all: "Todos",
      resolve: "Revisão aprovada",
      resolving: "Em revisão",
      reject: "A auditoria não foi aprovada",
    },
    dataSourceFlags: {
      all: "Sem restrições",
      voiceCall: "Chamada",
      textDialogue: "Diálogo",
    },
    smartWordsLibrary: { effective: "Eficaz", invalid: "Inválido" },
    activeFlags: { effiective: "Eficaz", invalid: "Inválido" },
    labelTypes: {
      dimensionLabel: "Rótulos de marcação",
      classificationLabel: "Rótulos de classificação",
    },
    pointTypes: {
      automatic: "Avaliação automática",
      manual: "Avaliação manual",
      interactiveDetection: "Detecção de interação",
      smartRules: "Regras inteligentes",
      machineLearning: "Modo de auto-aprendizagem",
      intelligentModel: "Modelos inteligentes",
    },
    pointGradeTypes: {
      radio: "Escolha única",
      input: "Entrada",
      select: "Escolha",
      all: "Conheça todos",
      any: "Satisfaça qualquer",
      customize: "Personalização",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Correspondência de palavras-chave",
      question: "Sentença interrogativa",
      regular: "Expressões regulares",
      context: "Repetição de contexto",
      semantics: "Correspondência semântica",
      word: "Palavras semelhantes",
      dialogue: "Intervalo de conversação",
      speed: "Detecção de velocidade de fala",
      grabDetection: "Detecção de chamadas",
      semanticTags: "Rótulos semânticos",
    },
    applyRoles: {
      all: "Todos",
      customer: "Clientes",
      agent: "Atendimento ao cliente",
      robot: "Robô",
    },
    saleApplyRoles: {
      all: "Todos",
      customer: "Clientes",
      agent: "Funcionários",
    },
    applyOperatorScopes: {
      all: "Texto completo",
      preCondition: "Pré-condições",
      scope: "Especificar o intervalo",
    },
    keywordScopes: {
      all: "Detectar todas as palavras-chave",
      any: "Detectar palavras-chave arbitrárias",
    },
    preOperatorHitTypes: {
      first: "Primeira correspondência",
      every: "Cada correspondência",
      last: "Última correspondência",
      any: "Qualquer correspondência",
      none: "Não correspondido",
    },
    operatorRuleTypes: {
      all: "Conheça todos",
      any: "Satisfaça qualquer",
      custom: "Lógica personalizada",
    },
    informationEntityTypes: {
      system: "Sistema",
      custom: "Personalização",
      moduleCompany: "Módulos",
    },
    organizationTypes: { null: "Nenhum", moduleCompany: "Módulos" },
    customType: {
      smartTag: "Extração de entidades",
      scriptTimes: "Contagem de palavras",
      dataCategory: "Classificação de dados",
    },
    interactiveQuestionTypes: {
      standard: "Perguntas e respostas padrão",
      multiElement: "Perguntas e respostas com vários elementos",
      guideMultiRound: "Interação guiada de várias rodas",
    },
    targetTypes: {
      all: "Todos",
      customerService: "Atendimento ao cliente",
      customer: "Clientes",
    },
    interactiveTypes: {
      similarQList: "Problemas semelhantes",
      exceptSimilarQList: "Excluir problemas semelhantes",
      similarAList: "Resposta semelhante",
      wrongAList: "Resposta errada",
    },
    filterTypes: {
      call: "Gravação de chamadas",
      dialogue: "Texto da conversa",
      wechat: "WeChat corporativo",
      wechatAll: "WeChat corporativo em todos os diálogos",
      ticket: "Sessão de ordem de trabalho",
      taobao: "Sessões de comércio eletrônico",
      wechatRadio: "Microvoz da empresa",
    },
    fieldTypes: {
      keyword: "Palavras-chave",
      agent: "Atendimento ao cliente",
      agentGroup: "Grupo de atendimento ao cliente",
      sigleListbox: "Escolha única",
      multiListbox: "Multi-escolha",
      text: "Texto de linha única",
      textArea: "Texto de várias linhas",
      dateTime: "Tempo",
      number: "Números",
      voice: "",
      customer: "Clientes",
      fieldWithLink: "Campo com link",
      percentage: "Porcentagem",
      intelligentClassification: "Classificação inteligente",
      testSetList: "Campo dinâmico Conjunto de teste",
      inspector: "Inspetor de qualidade",
    },
    saveTypes: { save: "Salvar" },
    knowledgeType: { oneToOne: "Uma pergunta e uma resposta" },
    updateTypes: { update: "Atualização", saveAs: "Salvar como" },
    recommendItemActionTypes: {
      similar: "Similaridade",
      exclude: "Excluir",
      ignore: "Ignorar",
    },
    gradeStatus: {
      unread: "Não lido",
      alreadyReview: "Reverificado",
      alreadyFiled: "Arquivamento",
      waitReview: "A ser revificado",
      alreadyRead: "Já leu",
    },
    qualityCheckDetailTypes: {
      review: "Revisão",
      spotCheck: "Inspeção aleatória",
    },
    sampleAndReviewStatus: {
      init: "Não extraído",
      unread: "Não lido",
      alreadyRead: "Já leu",
      complaining: "No recurso",
      reviewed: "Reverificado",
      sampling: "Extração não verificado",
      samplingCompleted: "Foi verificado aleatoriamente",
    },
    interactiveAnswerTypes: {
      noAnswer: "Nenhuma resposta detectada",
      answer: "Detecção de respostas",
      wrongAnswer: "Detecção de respostas erradas",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Problemas não detectados",
      question: "Problema detectado",
      eliminateQuestion: "Problemas de exclusão detectados",
    },
    isManual: {
      yes: "Inspeção de qualidade manual",
      no: "Inspeção de qualidade não manual",
    },
    readStatus: { yes: "Já leu", no: "Não lido" },
    reInspectionType: {
      recalcScore: "Apenas pontuação (incluindo classificação)",
      recheckPoint:
        "Recalculação de pontos e pontuações de inspeção de qualidade",
    },
    common: {
      eCommerceReview: "Revisão de comércio eletrônico",
      socialMediaComments: "Comentários da mídia social",
      customer: "Clientes",
      customerService: "Atendimento ao cliente",
      robot: "Robô",
      customerInformation: "Informações do cliente",
      workOrderFields: "Campo da ordem de trabalho",
      intelligentConversationAnalysis: "Análise de sessão inteligente",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Insights sobre as deficiências de negócios, ouvir as vozes dos clientes e melhorar a transformação do marketing",
      intelligentAccompanyingPractice: "Treinamento de sparring inteligente",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Crie um treinamento de sparring de IA imersivo que simula cenários reais de negócios, perceba o domínio de todos os membros da arte da medalha de ouro e aprimore rapidamente as habilidades de diálogo",
      intelligentQualityInspection: "Inspeção de qualidade inteligente",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Inspeção de qualidade total, oportuna e eficiente, com uma taxa de precisão de mais de 85%",
      salesEmpowerment: "Capacitação de vendas",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI remodelar o gerenciamento de vendas, realizar a digitalização do processo de comunicação, visualização, gerenciamento transparente de cada parte das vendas, insights inteligentes e cada etapa da comunicação, diagnóstico refinado de problemas de negócios, reedição perfeita da experiência da coroa de vendas",
      algorithmPlatform: "Plataforma de algoritmos",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Com base na semântica de fala do mecanismo de mente original de auto-estudo, customização do modelo ASR e NLP, para abrir dados de cena de negócios e realizar o autoaprendizado do sistema.",
      generalSettings: "Configurações genéricas",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Gerenciamento unificado de configurações para permissões de dados e funções de funcionários",
      callLog: "Registro de chamadas",
      intelligentRules: "Regras inteligentes",
      businessRecords: "Registros de negócios",
      customFilter: {
        call: "Chamada de voz",
        text: "Diálogo de texto",
        smartTags: "Rótulos inteligentes",
        pretreatment: "Pré-processamento",
        smartTagGroup: "Grupo de etiquetas inteligentes",
        currency: "Dados genéricos",
        wechat: "WeChat corporativo",
        spotCheck: "Inspeção aleatória",
        personaField: "Lista de clientes",
        ticket: "Sessão de ordem de trabalho",
        taobao: "Sessões de comércio eletrônico",
        wechatRadio: "Microvoz da empresa",
        reviewList: "Revisão",
      },
      talkerRecognitionType: {
        API: "Função de faixa de identificação da API",
        text: "Reconhecimento de texto",
      },
      operatorTypes: {
        keyword: "Correspondência de palavras-chave",
        question: "Sentença interrogativa",
        regular: "Expressões regulares",
        context: "Repetição de contexto",
        semantics: "Correspondência semântica",
        word: "Palavras semelhantes",
        dialogue: "Intervalo de conversação",
        speed: "Detecção de velocidade de fala",
        grabDetection: "Detecção de chamadas",
        semanticTags: "Rótulos semânticos",
        keywordStrict: "Correspondência de palavras-chave (estritamente)",
        link: "Campos de ligação",
        entityInfoDetection: "Detecção de entidade de informação",
        basicInfoDetection: "Detecção de informações básicas",
        volume: "Detecção de volume",
        emotion: "Análise de sentimentos",
        grammar: "Regras de gramática",
        sentenceLength: "Detecção de frase longa",
        conceptWord: "Palavra de conceito",
        questionsAndAnswers: "Respostas da base de conhecimento (antigas)",
        element: "Perguntas e respostas sobre os elementos",
        silent: "Tempo limite de resposta",
        associate: "Dados de estrada",
        repeat: "Envio repetido",
        timeInterval: "Tempo de intervalo",
        typoDetection: "Detecção de erros de digitação",
        silentOld: "Silêncio",
        knowledge: "Respostas de conhecimento",
        wecomRemark: "Micro observações da empresa do cliente",
        customerAddTime: "Tempo de adição do cliente",
      },
      keywordOperators: {
        all: "Todas as correspondências",
        allNot: "Todos não correspondem",
        any: "Correspondência arbitrária",
      },
      applyRoles: {
        all: "Todos",
        customer: "Clientes",
        agent: "Atendimento ao cliente",
      },
      sentenceLength: {
        less: "Menor que",
        greater: "Maior que",
        equal: "É igual a",
        greater_or_equal: "Maior que igual a",
        less_or_equal: "Menos do que igual a",
      },
      dialogueApplyScopes: {
        differentRoles: "Entre diferentes papéis",
        agent: "Atendimento ao cliente",
        customer: "Clientes",
        agentResponse: "Resposta de atendimento ao cliente",
        customerResponse: "Resposta do cliente",
        customerNoResponse: "Clientes não respondem",
      },
      applyConditionHitTypes: {
        first: "Primeiro acerto",
        every: "Cada golpe",
        last: "Último acerto",
      },
      applyConditionScopes: {
        current: "Atual",
        before: "Anterior",
        after: "Depois",
        beforeAll: "Antes de tudo",
        afterAll: "Depois de tudo",
        around: "Perto",
      },
      voiceDemoTypes: {
        singleChannel: "Gravação de chamadas de canal único",
        dualChannel: "Gravação de chamadas de canal duplo",
      },
      sendStatus: {
        sending: "Envio em",
        arrive: "Entrega",
        fail: "Falha ao enviar",
        off_sending: "Envio off-line não lido",
        off_arrive: "Já leu",
        rollback: "Retirada",
      },
      collectionMethod: {
        assignment: "Atribuição direta",
        entity: "Extração de entidade",
      },
      systemTypes: {
        qa: "Inspeção de qualidade inteligente",
        wfm: "Agendamento inteligente",
      },
      entityOperators: {
        equals: "É igual a informação",
        notEquals: "Não é igual a informação",
        contains: "Contém informações",
        notContains: "Não contém informações",
        equalsFix: "É igual a um valor fixo",
        notEqualsFix: "Não é igual a um valor fixo",
        containsFix: "Contém valores fixos",
        notContainsFix: "Não contém valores fixos",
      },
      basicInfoOperators: {
        equals: "É igual a",
        notEquals: "Não é igual a",
        contains: "Contém",
        notContains: "Não contém",
        isNull: "Para o vazio",
        isNotNull: "Não para o vazio",
      },
      volumeDetectionWays: {
        loudness: "Dibéis de volume",
        rangeAbilitySelf:
          "Amplitude de mudança (em comparação com a frase anterior)",
        rangeAbilityOth: "Amplitude de mudança (comparação da frase acima)",
        rangeAbility3: "Amplitude de mudança (melhor do que a frase principal)",
      },
      volumeDetectionOperators: {
        is: "É igual a",
        not: "Não é igual a",
        greaterThan: "Maior que",
        greaterThanEq: "Maior que igual a",
        lessThan: "Menor que",
        lessThanEq: "Menos do que igual a",
      },
      sentimentTypes: {
        positive: "Emoções positivas",
        negative: "Emoções negativas",
        neutral: "Emoções neutras",
        thankful: "Obrigado",
        happy: "Feliz",
        complaining: "Reclamações",
        angry: "Com raiva",
        post: "Positivo",
        negat: "Negativo",
        neut: "Neutro",
      },
      emotionOperators: {
        is: "Em conformidade",
        not: "Não está em conformidade",
      },
      propTypes: { system: "Sistema", user: "Personalização" },
      valueTypes: { text: "Texto", number: "Números" },
    },
    tasks: {
      allowCustomGrievance: "Permitir motivos de recurso personalizados",
      businessSummary: "Resumo de negócios",
      theDayBeforeYesterday: "Anteontem",
      assessActionTypes: { reassess: "Reavaliação" },
      scoreType: {
        aiScore: "Avaliação inteligente",
        manualScore: "Avaliação manual",
        interactiveDetection: "Detecção de interação",
        smartRules: "Regras inteligentes",
        machineLearning: "Modo de auto-aprendizagem",
      },
      inspectType: {
        inspectTrue: "Verifique para",
        inspectFalse: "Não verificado",
      },
      operatorType: {
        keyword: "Correspondência de palavras-chave",
        notKeyword: "Palavras-chave não correspondem",
      },
      applyRole: {
        all: "Todos",
        customer: "Clientes",
        agent: "Atendimento ao cliente",
      },
      applyScope: {
        all: "Todos",
        specific: "Sentença especificada",
        scope: "Especificar o intervalo",
      },
      judgeStrategy: {
        all: "Todas as condições são atendidas",
        arbitrarily: "Atender a qualquer condição",
        custom: "Lógica personalizada",
      },
      checkPointStatus: {
        waitFor: "Aguardando revisão",
        notDeductScore: "Sem dedução de pontos",
        deductScore: "Dedução de pontos",
      },
      applyOptions: {
        hit: "Condição acerta",
        notHit: "Nenhuma condição especificada",
      },
      gradeTypes: {
        yesOrNo: "Se está em conformidade",
        input: "Entrada manual",
        level: "Avaliação de classificação",
        multipleFactors: "Multi-elementos",
      },
      gradeChooseHits: { yes: "Sim", no: "Não." },
      gradeChangeTypes: {
        edit: "Editar",
        check: "Revisão",
        file: "Arquivamento",
        remark: "Observações",
        complain: "Reclamação",
        review: "Revisão",
        reviewEvaluation: "Avaliação de revisão",
        sample: "Inspeção aleatória",
        sampleEvaluation: "Avaliação de amostragem",
        sampleSubmit: "Apresentação de inspeção aleatória",
        sampleAssign: "Atribuição de amostragem",
        reviewAssign: "Distribuição de revisão",
        read: "Já leu",
        reviewConfirm: "Confirmação de revisão",
        sampleConfirm: "Confirmação de inspeção aleatória",
      },
      gradeStatus: {
        unread: "Não lido",
        alreadyReview: "Reverificado",
        alreadyFild: "Arquivamento",
        waitReview: "A ser revificado",
        alreadyRead: "Já leu",
      },
      samplingFrequencyTypes: {
        everyDay: "Diário",
        everyWeek: "Semanal",
        everyMonth: "Mensal",
      },
      samplingEveryDayTimeTypes: { yesterday: "Ontem", today: "Hoje" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Esta semana",
        lastWeek: "Semana passada",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Este mês",
        lastMonth: "Mês passado",
      },
      samplingTypes: {
        random: "Amostragem aleatória",
        proportional: "Amostragem proporcional",
      },
      weekdays: {
        Monday: "Segunda-feira",
        Tuesday: "Terça-feira",
        Wednesday: "Quarta-feira",
        Thursday: "Quinta-feira",
        Friday: "Sexta-feira",
        Saturday: "Sábado",
        Sunday: "Domingo",
      },
      distributeTypes: {
        rule: "Distribuição de prioridade de regras",
        average: "Atribuição aleatória média",
      },
      notMatchAnyRuleTypes: {
        random: "Alocação de média aleatória",
        assign: "Atribuído a pessoas designadas",
        ignore: "Sem distribuição",
      },
      inspectorTypes: {
        all: "Todos os inspetores de qualidade",
        some: "Inspetor de qualidade designado",
      },
      pushFrequencyTypes: {
        everyDay: "Diário",
        everyWeek: "Semanal",
        everyMonth: "Mensal",
        inspectionUpdate: "Atualização de inspeção de qualidade",
        timelyInspection: "Inspeção de qualidade em tempo real",
      },
      pushObjectTypes: {
        email: "Especificar uma caixa de correio",
        staff: "Atendimento ao cliente",
      },
      reviewOptions: {
        reviewResult:
          "Exibir os resultados da inspeção de qualidade durante a revisão",
        reviewRecord: "Exibir registros relevantes durante a revisão",
        reviewAgentHidden:
          "Ocultar informações de atendimento ao cliente durante a revisão",
        samplingResult:
          "Exibir os resultados da inspeção de qualidade durante a inspeção aleatória",
        samplingRecord:
          "Exibir registros relevantes durante a inspeção aleatória",
        sampleAgentHidden:
          "Ocultar informações de atendimento ao cliente durante a inspeção aleatória",
        checkExportAddCallInfoIphone:
          "Anexar registros de chamadas ao exportar uma lista",
        sampleExportAddCallInfoIphone:
          "Anexar registros de chamadas ao exportar uma lista",
        checkExportAddCallInfoText:
          "Anexar registros de conversas ao exportar uma lista",
        sampleExportAddCallInfoText:
          "Anexar registros de conversas ao exportar uma lista",
        inspectionExportAddCallInfoIphone:
          "Anexar registros de chamadas ao exportar a lista (até 10000 de cada vez)",
        inspectionExportAddCallInfoText:
          "Anexar registros de conversação ao exportar a lista (até 10000 de cada vez)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Permitir inspeções aleatórias rápidas em detalhes de inspeção de qualidade",
        fastSampleInSampleDetail:
          "Permite a execução rápida de inspeções aleatórias em detalhes de amostragem",
        fastSampleInReviewDetail:
          "Permite a rápida execução de verificações aleatórias nos detalhes da revisão",
      },
      permitCustomReasonList: {
        permitCustomReason:
          "Razões para permitir representações personalizadas",
      },
      autoMateTasks: {
        autoLoad: "Adição automática",
        sample: "Inspeção automática de amostragem",
        assign: "Alocação automática",
        pushes: "Push automático",
        assess: "Avaliação automática",
        check: "Recurso de reexame",
        case: "Sincronização da biblioteca de casos",
      },
      qualityCheckDetailTypes: {
        review: "Revisão",
        spotCheck: "Inspeção aleatória",
      },
      appealTypes: {
        transliterationError: "Erro de transliteração",
        discriminationError: "Julgamento de erros",
        otherError: "Outros erros",
      },
      inspectDataSources: {
        voiceCall: "Chamada de voz",
        textDialogue: "Diálogo de texto",
        realTimeVoiceCall: "Chamada de voz em tempo real",
        realTimeTextDialogue: "Conversas de texto em tempo real",
      },
      pointTypes: {
        automatic: "Avaliação automática",
        manual: "Avaliação manual",
        interactiveDetection: "Detecção de interação",
      },
      pointGradeTypes: {
        radio: "Escolha única",
        input: "Entrada",
        select: "Escolha",
      },
      sampleAndReviewStatus: {
        init: "Não extraído",
        unread: "Não lido",
        alreadyRead: "Já leu",
        complaining: "No recurso",
        reviewed: "Reverificado",
        sampling: "Extração não verificado",
        samplingCompleted: "Foi verificado aleatoriamente",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Ignorar o excesso de transbordamento",
        automaticWeightCalculation: "Contabilidade automática de peso",
      },
      calculateScoreTypes: { add: "Pontos extras", sub: "Pontos de redução" },
      naChecked: {
        check: "Permitir que este item não seja envolvido na detecção",
      },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Dê pontos em proporção",
        allRight: "Todos os pontos são dados corretamente",
        anyRight: "Dê pontos de forma arbitrária e correta",
      },
      noHitQuestionResults: {
        true: 'O resultado é "sim"',
        false: 'Resultados para "Não"',
      },
      repetRules: {
        same: "O conteúdo é exatamente o mesmo",
        similar: "Similaridade semântica",
      },
      relateConditions: {
        highlightQuestion: "Problema de destaque",
        highlightAnswer: "Respostas brilhantes",
        hightlightFoctors: "Elementos de destaque",
      },
      interactiveQuestionTypes: {
        question: "Problema detectado",
        eliminateQuestion: "Problemas de exclusão detectados",
      },
      interactiveAnswerTypes: {
        noAnswer: "Nenhuma resposta detectada",
        answer: "Detecção de respostas",
        wrongAnswer: "Detecção de respostas erradas",
      },
      inspectionUpdateTypes: {
        autoInspection: "Inspeção automática de qualidade",
        autoInspectionWechat: "Análise automática",
        artificialSampling: "Inspeção de amostragem manual",
        artificialReview: "Revisão manual",
        manualModification: "Inspeção de amostragem rápida",
        timelyInspection: "Inspeção de qualidade em tempo real",
        samplingAssign: "Atribuição de amostragem",
        artificialComplain: "Apresentação de recurso",
        fastSampling: "Submissão rápida de inspeção aleatória",
        recheckInspection: "Re-inspeção de qualidade",
        recheckInspectionWechat: "Reanálise",
      },
      timelyInspectionTypes: {
        timelyInspection: "Inspeção de qualidade em tempo real",
      },
      pushActionTypes: { email: "Correio", interfacePush: "Push da interface" },
      pushDetailWays: {
        riskAlert: "Alerta de risco",
        subscribe: "Assinar mensagens",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Mensagem de chamada/conversa",
        inspectionResults: "Resultados de inspeção de qualidade",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Mensagem de chamada/conversa",
        textContent: "Conteúdo de texto de chamada/conversa",
        inspectionResults: "Resultados de inspeção de qualidade",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Média de pontuação dentro do grupo",
        addOrSubtract: "Pontos de adição e subtração direta",
        noChange: "Sem adicionar ou subtrair pontos",
      },
      defaultScore: {
        empty: "Para o vazio",
        add: "Pontos extras",
        notAdd: "Sem pontos",
        sub: "Pontos de redução",
        notSub: "Sem redução de pontos",
        na: "N/A",
        input: "Entrada manual",
        choose: "Escolha",
      },
      strategyTypes: {
        hitAdd: "Pontos de bônus de acerto",
        hitNotAdd: "Não há pontos para acertar",
        hitSub: "Perda de pontos",
        hitNotSub: "Não há redução de pontos",
      },
    },
    download: {
      exportStatus: {
        prepare: "Em preparação",
        process: "Em andamento",
        faild: "Falha na exportação",
        success: "Sucesso",
      },
    },
    messageType: {
      all: "Todos",
      monitor: "Monitoramento imediato",
      trigger: "Acionando notificações",
      timing: "Relatórios cronometrados",
    },
    messagePushType: {
      all: "Todos",
      system: "Mensagens do sistema",
      sdk: "SDK de mensagens",
      push: "Push da interface",
      email: "Correio",
      udesk: "Notificações de udesk",
      udeskWhite: "Notificação do sistema de atendimento ao cliente",
      weChat: "Micro notícias da empresa",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Atribuir inspetores de amostragem",
      sampleInspector: "Inspetor de amostragem",
      assignReviewer: "Distribuição de revisor",
      reviewer: "Reinspetor",
      taskInspector: "Inspetor de qualidade da tarefa",
    },
    sdkPushType: {
      webHook: "Webhook padrão",
      dingDing: "Robô de pregos",
      weChat: "Robô WeChat corporativo",
      customer: "Personalizar o conteúdo do Json",
    },
    inspectionRemarksType: {
      inspection: "Inspeção de qualidade",
      check: "Inspeção aleatória",
      review: "Revisão",
    },
    segmentationStrategyType: {
      count: "Número de mensagens",
      spacing: "Intervalo de mensagens",
    },
    segmentationStrategy: {
      byTime: "Corte cronometrado",
      customize: "Corte de combinação",
    },
    inspectType: {
      commonInspect: "Inspeção de qualidade convencional",
      associateInspect: "Inspeção de qualidade associada",
    },
    typoDetectionOperators: { less: "Menor que", greater: "Maior que" },
    autoAddTypes: {
      realTimeAdd: "Adição em tempo real",
      timingAdd: "Adição de temporização",
    },
    messageTypes: {
      text: "Mensagens de texto",
      markdown: "Mensagens do Markdown",
    },
    inServiceStatus: {
      activated: "Ativado",
      disabled: "Desativado",
      inactivated: "Não ativado",
      resigned: "Sair da empresa",
    },
    roles: { agent: "Funcionários", customer: "Clientes" },
    commentVisibleAuthority: {
      all: "Visível para todos",
      self: "Só visível para si mesmo",
      designation: "A pessoa especificada é visível",
    },
    appealCheckingTask: {
      eCommerceReview: "Revisão de comércio eletrônico",
      socialMediaComments: "Comentários da mídia social",
      largeModel: "Modelo grande",
      customModel: "Modelos personalizados",
      universalModel: "Modelos genéricos",
      enterpriseWeChatText: "Micro texto da empresa",
      generalQualityInspection: "Inspeção de qualidade geral",
      processQualityInspection: "Qualidade de processo",
      voiceCopy: "Disco de repetição de voz",
      beEqualTo: "É igual a",
      notEqualTo: "Não é igual a",
      canAppeal: "Pode apelar",
      noAppeal: "Sem recurso",
    },
    wechat: {
      redEnvelopes: "Envelope vermelho",
      text: "Texto",
      picture: "Imagens",
      voice: "Voz",
      voiceCall: "Chamada de voz",
      video: "Vídeo",
      businessCard: "Cartão de visita",
      position: "Localização",
      expression: "Expressão",
      link: "Link",
      applet: "Pequenos programas",
      chatRecord: "Histórico de bate-papo",
      mixedMessage: "Mensagens híbridas",
      file: "Arquivo",
    },
    gong: {
      sessionActivity: "Atividade da sessão",
      conversationAction: "Ação de sessão",
      riskSemantics: "Semântica de risco",
      daysNotFollowedUp: "Dias sem acompanhamento",
      waysideData: "Dados de estrada",
      notStarted: "Não começou",
      completed: "Concluído",
      haveInHand: "Em andamento",
      unpublished: "Não publicado",
      published: "Publicado",
      knowledgePoints: "Pontos de conhecimento",
      technologicalProcess: "Processo",
      notMarked: "Não marcado",
      inAnnotation: "Na anotação",
      annotated: "Marcado",
    },
    qualityChecingTask: {
      appealCanceled: "Recurso de cancelamento",
      complaintExpired: "Recurso expirado",
      incomingCall: "Chamada",
      exhale: "Expire",
      incomingCallthirdParty: "Entradas (três partes)",
      incomingCallconsultation: "Consulta (consulta)",
      incomingCallforcedInsertion: "Inserto (inserção forte)",
      incomingCallmonitoring: "Aclamação (escuta)",
      incomingCalltransfer: "Entradas (transferência)",
      incomingCallinterception: "Interceptação (interceptação)",
      incomingCalltransferToExternalLine: "Aclamação (transferência para fora)",
      incomingCallthirdPartyExternalLine: "Entradas (três linhas externas)",
      incomingCallexternalLine: "Entrar (consultar a linha externa)",
      outgoingCallthirdParty: "Exalando (três partes)",
      outgoingCalltransfer: "Expire (transferência)",
      outgoingCallforcedInsertion: "Expire (inserção forte)",
      outgoingCallmonitoring: "Exaltação (escuta)",
      outgoingCallinterception: "Expire (interceptação)",
      outgoingCalltransferToExternalLine: "Expire (transferência para fora)",
      outgoingCallthreePartyExternalLine: "Expire (linha externa tripartida)",
      customerServiceAnswer: "Atendimento ao cliente atende",
      customerServiceMissed: "Atendimento ao cliente não recebido",
      customerAnswer: "Resposta do cliente",
      customerMissed: "Cliente não atendeu",
      theTelephoneIsBusy: "O telefone está ocupado",
      phoneOffline: "O telefone está offline",
      customerQuickHanging: "Clientes pendurados rapidamente",
      clientHangsUp: "Clientes desligados",
      queueTimeout: "Tempo de espera",
      giveUpQueuing: "Desista da fila",
      outsideLineAnswering: "Resposta externa",
      externalLineIsNotConnected: "A linha externa não está conectada",
      noQueueSelected: "Fila não selecionada",
      notEvaluated: "Não avaliado",
      noEvaluationRequired: "Sem avaliação",
      queuingSucceeded: "A fila para o sucesso",
      noCustomerServiceOnline: "Sem atendimento ao cliente online",
      customer: "Clientes",
      customerService: "Atendimento ao cliente",
      outsideLines: "Linha externa",
    },
    sessionActivity: {
      single: "Tempo médio de conversação única",
      all: "Volume total de chamadas",
      allTime: "Duração total da chamada",
    },
    sessionAction: {
      salesProportion: "A proporção de vendas",
      salesLongest: "A maior média de vendas fala",
      customerLongest: "O cliente médio mais longo fala",
      discussNumber: "Número de discussões em profundidade",
      frequency: "Perguntas frequentes",
    },
    monitorCriteria: {
      all: "Antes e depois",
      before: "Anterior",
      after: "Depois",
    },
    monitorTimeCycle: { day: "Dia", week: "Semana", month: "Mês" },
    monitorGroupTestType: {
      ab: "Comparação entre dois grupos",
      a: "Grupo A",
      b: "Grupo B",
    },
    fields: {
      department: "Departamento",
      customerTags: "Rótulos de clientes",
      member: "Membros",
      time: "Tempo",
      cascade: "Concata",
      anyMatch: "Correspondência arbitrária",
      keyWord: "Palavras-chave",
      keyEvents: "Eventos-chave",
    },
    semanticIntelligence: {
      staff: "Funcionários",
      customer: "Clientes",
      employeesAndCustomers: "Funcionários e clientes",
      notStarted: "Não começou",
      haveInHand: "Em andamento",
      completed: "Concluído",
      callRecording: "Gravação de chamadas",
      enterpriseWeChatCall: "WeChat da empresa",
      enterpriseWeChatText: "Micro texto da empresa",
      discourseMiningForObjectionHandling: "Escavação de objeções",
      productSellingPointScriptsMining:
        "Mineração do ponto de venda do produto",
      conversationScenario: "Cena de sessão",
      topicOfConversation: "Tópicos",
      keyEvents: "Eventos-chave",
      customerLabel: "Rótulos de clientes",
      includeAny: "Contém qualquer",
      includeAll: "Contém todos",
      and: "E",
      or: "Ou",
      question: "Sentença interrogativa",
      nonInterrogativeSentence: "Sentença não interrogativa",
    },
    coach: {
      notPassed: "Não passou",
      analogTelephone: "Telefone analógico",
      enterScenarioInformation: "Inserir informações do cenário",
      generateDialogueFramework: "Gerar estrutura de diálogo",
      generatePromptLanguage: "Gerar um prompt",
      aIGeneratedDialogue: "AI gera diálogo",
      system: "Sistema",
      custom: "Personalização",
      flow: "Processo",
      script: "A técnica da fala",
      express: "Expressão",
      emotion: "Emoções",
      rateAccordingToCourseConfiguration:
        "Configurar a pontuação de acordo com o curso",
      intelligentModelRating: "Classificação de modelos inteligentes",
      allData: "Todos os dados",
      dataFromThisDepartment: "Dados do departamento",
      multidepartmentalData: "Dados multidepartamentais",
      underReview: "Em revisão",
      no: "Não.",
      yes: "Sim",
      automaticRating: "Classificação automática",
      auditRating: "Pontuação de auditoria",
      reRating: "Reclassificação",
      inAppeal: "No recurso",
      reviewed: "Reverificado",
      notViewed: "Não visto",
      viewed: "Visualizado",
      uncorrected: "Não corrigido",
      corrected: "Corrigido",
      practice: "Exercícios",
      examination: "Exame",
      other: "Outros",
      notMeetingStandards: "Não atingiu o padrão",
      meetingStandards: "Padrões",
      excellent: "Excelente",
      invalid: "Falha",
      notStarted: "Não começou",
      haveInHand: "Em andamento",
      ended: "Acabou",
      completed: "Concluído",
      hangInTheAir: "Não concluído",
      passed: "Foi aprovado",
      voiceConversation: "Diálogo de voz",
      textDialogue: "Diálogo de texto",
      slidePresentation: "Apresentação de slides",
      unpublished: "Não publicado",
      published: "Publicado",
      narration: "Narração",
      studentSpeaking: "O aluno fala",
      robotTalk: "O robô fala",
      knowledgeBaseQA: "Perguntas e Respostas da Base de Conhecimento",
      slide: "Slides",
      negativeEmotions: "Emoções negativas",
      emotionallyNormal: "Emoções normais",
      incompleteScript: "A fala está incompleta",
      completeScript: "A técnica está completa",
      normalSpeechSpeed: "Velocidade de fala normal",
      speakTooFast: "Muito rápido",
      speakTooSlowly: "Velocidade de fala muito lenta",
      whole: "Todos",
      singlePage: "Página única",
      qA: "Uma pergunta e uma resposta",
      situationalDialogue: "Diálogo situacional",
      misses: "Não atingido",
      hit: "Hit",
      sequentialQA: "Sequência de perguntas e respostas",
      randomQA: "Perguntas e respostas aleatórias",
      mastered: "Dominar",
      notMastered: "Não dominado",
    },
    algorithm: {
      salesEmpowerment: "Capacitação de vendas",
      enterpriseWeChatVoice: "WeChat corporativo de voz",
      enterpriseWeChatText: "WeChat corporativo texto",
      waiting: "À espera",
      extracting: "Extração em",
      success: "Sucesso",
      fail: "Falha",
      intelligentQualityInspection: "Inspeção de qualidade inteligente",
      textDialogue: "Diálogo de texto",
      voiceCall: "Chamada de voz",
      smartTags: "Rótulos inteligentes",
      satisfactionEvaluation: "Avaliação de satisfação",
      score: "Classificação",
      grade: "Classificação",
      customerServiceTeam: "Grupo de atendimento ao cliente",
      conversationAnalysis: "Análise de sessão",
    },
    operatorListGather: {
      businessSummary: "Resumo de negócios",
      firstSentence: "Primeira frase",
      lastSentence: "A última frase",
      allTime: "Tempo total",
      nonBusinessWorkingHours: "Horário de trabalho não comercial",
      businessWorkingHours: "Horas de trabalho de negócios",
    },
    callDialogueManage: {
      eCommerceReview: "Revisão de comércio eletrônico",
      socialMediaComments: "Comentários da mídia social",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Login",
        email: "Caixa de correio",
        password: "Senha",
        forgetPwd: "Esqueceu a senha",
        emailCannotBeEmpty: "A caixa de correio não deve estar vazia.",
        passwordCannotByEmpty: "A senha não deve estar vazia.",
        mustChangeLogin:
          "Desculpe, sua senha deve ser redefinida, entre em contato com o administrador para redefinir a senha.",
        dismissionLogin: "Desculpe, você deixou o emprego.",
        forbiddenLogin: "Desculpe, sua conta está desativada.",
        loginError: "Falha de login, caixa de correio ou senha incorreta.",
        loginPrompt: "Faça login no sistema",
        rememberMe: "Lembre-se de mim",
      },
      logout: {
        logoutError:
          "Falha no logout, verifique a conexão de rede ou tente novamente mais tarde.",
      },
      resetPassword: {
        forgetPwd: "Esqueceu a senha",
        inputEmailPrompt:
          "Digite o e-mail da sua conta abaixo e enviaremos um e-mail e instruções para redefinir sua senha.",
        sendEmailSuccess:
          "A carta de confirmação de alteração de senha foi enviada com sucesso para a caixa de correio que você preencheu, verifique-a a tempo.",
        sendEmailFailed:
          "O envio da caixa de correio falhou, verifique a conexão de rede ou tente novamente mais tarde.",
        setNewPassword: "Definir sua nova senha",
        passwordCannotEmpty: "A senha não deve estar vazia",
        passwordFormWrong: "A senha não está formatada corretamente",
        confirmPasswordWrong: "As senhas inseridas duas vezes não são iguais.",
        passwordResetSuccess: "A redefinição de senha foi bem-sucedida.",
        passwordResetFailed:
          "A redefinição de senha falhou, verifique a conexão de rede ou tente novamente mais tarde.",
        linkTimeover: "O link de redefinição de senha expira, tente novamente",
      },
      form: { title: "Apresentação do formulário CASE" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Caixa de correio",
          emailFormatError:
            "O formato da caixa de correio está errado, por favor, digite-o novamente!",
          forgotPassword: "Esqueceu a senha?",
          describe:
            "Digite o e-mail da sua conta abaixo e enviaremos um e-mail e instruções para redefinir sua senha.",
          send: "Enviar",
          emailCannotBeEmpty: "A caixa de correio não pode estar vazia",
          resetPasswordExpire:
            "O link de redefinição de senha expira, tente novamente",
        },
        resetPassword: {
          setPassword: "Definir sua nova senha",
          newPassword: "Nova senha",
          repeatPassword: "Senha duplicada",
          resetPassword: "Redefinir a senha",
          passwordCannotBeEmpty: "A nova senha não pode estar vazia.",
          confirmPasswordCannotBeEmpty:
            "A senha de repetição não pode estar vazia.",
          passwordsNotMatch: "Duas vezes a senha não é consistente!",
          passwordFormWrong:
            "A senha não pode ser em chinês e o número está entre 6 e 14 dígitos!",
          linkExpiration:
            "O link de redefinição de senha expira, tente novamente",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Você não tem permissão de visualização para esta página",
      },
    },
    downloadCenter: {
      name: "Centro de download",
      saveTime: "(30 dias de salvamento padrão)",
      headerTitle: "Baixar arquivos",
      download: "Download",
      downloadPromp: "A tarefa de download foi enviada, vá para",
      viewProgress: "Veja o progresso",
    },
    uploadCenter: { name: "Centro de upload" },
    tasks: {
      index: {
        name: "Nome",
        remark: "Descrição",
        qualityCheckTasksTitle: "Bancada de trabalho",
        informationCollection: "Missão de Coleta",
        qualityCheckTasks: "Tarefa de inspeção de qualidade",
        wechatQaTasks: "Tarefas de análise",
        moduleName: "Usar um modelo para criar um novo",
        creatNewQualityCheckTask: "Novas tarefas",
        generalInspectionList: "Relatório geral de inspeção de qualidade",
        editQualityCheckTask: "Editar tarefas de inspeção de qualidade",
        creatNewQualityCheckTaskError:
          "Nova tarefa de inspeção de qualidade falhou",
        editQualityCheckTaskError:
          "A tarefa de edição de inspeção de qualidade falhou",
        type: "Tipo de formulário",
        singleScore: "Pontuação única",
        singleScoreNullError: "A pontuação de um único item não pode ser vazia",
        inspector: "Inspetor de qualidade",
        inspectDataSource: "Fonte de dados",
        taskName: "Nome da tarefa",
        inspectionType: "Tipo de inspeção de qualidade",
      },
      manage: {
        screeningCondition: "Condições de triagem",
        template: {
          condition: {
            index: {
              name: "Condições",
              existingConditions: "Condições existentes",
            },
            detail: {
              id: "ID",
              name: "Nome da condição",
              remark: "Descrição",
              checkRange: "Faixa de detecção",
              operator: "Operador",
              operatorLogic: "Lógica do operador",
            },
          },
          checkPointTest: {
            title: "Teste de regras",
            running: "Teste de regras, por favor mais tarde...",
            clearSuccess: "O diálogo simulado foi esvaziado com sucesso",
            mock: "Simulação de diálogo",
            save: "Salvar a conversa selecionada",
            saveAll: "Salve todos os diálogos",
            saveSuccess: "O diálogo é salvo com sucesso",
            close: "Saída",
            delete: "Apagar a seleção",
            clear: "Esvaziar",
            customer: "Clientes",
            agent: "Atendimento ao cliente",
            test: "Teste",
            request:
              "O diálogo simulado será irrecuperável após o esvaziamento, o esvaziamento é confirmado?",
            deletePopconfirmContent:
              "Confirmou a exclusão da conversa selecionada?",
          },
          checkPoint: {
            index: {
              name: "Configuração da regra",
              wechatTitle: "Análise da configuração do modelo",
              existingCheckPoints: "Existem regras de inspeção de qualidade",
              currentCheckPointScore:
                "A pontuação atual da regra de inspeção de qualidade é",
              currentCheckPointScoreWechat:
                "A pontuação atual do modelo de análise é",
              currentCheckPointWeight:
                "A soma dos pesos das regras de inspeção de qualidade atuais é",
              weightTotalScoreAndItemScore:
                "Pontuação total: 100 pontos individuais:",
              template: "Modelo de regra de inspeção de qualidade",
              import: "Importar modelos de regras de inspeção de qualidade",
              getTemplateError:
                "Falha na aquisição do modelo de regra de inspeção de qualidade",
              tabs: { template: "Modelos", demo: "Exemplo" },
              customize: "Modelos em branco",
              move: "Arraste para ajustar a classificação e classificação",
              classification: "Classificação de inspeção de qualidade",
              classificationWeChat: "Classificação de modelos",
              first: "Classificação de primeiro nível",
              second: "Classificação secundária",
              new: "Novas regras de inspeção de qualidade",
              weChatNew: "Adicionar um modelo de análise",
              createWeChat: "Novo modelo de análise",
              empty: "Não há regras de inspeção de qualidade,",
              wechatEmpty: "Sem modelo de análise,",
              newSecond: "Nova classificação secundária",
              newFirst: "Nova classificação de primeiro nível",
              checkPoint: "Regras de inspeção de qualidade",
              checkPointWeChat: "Modelos de análise",
            },
            detail: {
              name: "Nome",
              remark: "Descrição",
              type: "Tipo",
              deduction: "Dedução de pontos",
              rule: "Regras",
              highlighted: "Destaque",
              gradeType: "Método de classificação",
              interactiveCategory: "Classificação de interação",
              predeterminedScore: "Pontos",
              weight: "Peso",
              na: "N/A",
              default: "Pontuação do padrão",
              preRule: "Regras pré-condicionais",
              analysisHighlighted: "Regras de análise",
              categorySelectPrompt: "Por favor, escolha a classificação",
              checkHighlightArrayError:
                "As regras não correspondem ao destaque, clique novamente no botão analisar a regra",
              foctorsHolder: "Por favor, escolha os elementos",
              foctorsErrorNone:
                "A regra de vários elementos está vazia, adicione o tipo de vários elementos",
              naError:
                "Não é possível fechar o item quando a pontuação padrão é N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A é não aplicativo. Defina as pré-condições para esta regra de inspeção de qualidade e teste quando a condição for atendia. Se a regra não for aplicável se a condição não for atendia, a regra não será detectada;\nPré-condições Preencha as instruções: "&&" significa "e", "||" significa "ou", "!" Informa "não", preencha o exemplo "(R1 || R2) && R3"',
                applicationMessageType: "Tipo de mensagem do aplicativo",
                selectAll: "Seleção completa",
                highlightTags: "Etiquetas de destaque:",
                ruleType: "Tipo de regra",
                dataSource: "Fonte de dados",
                whole: "Todos",
                enterpriseMicroConversation: "Micro-sessão da empresa",
                enterpriseAndMicroCommunication: "WeChat da empresa",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Nó de julgamento",
                    dialogueNode: "Nó de diálogo",
                    editNodes: "Editar um nó",
                  },
                  nodeConfig: {
                    nodeName: "Nome do nó",
                    nodeRules: "Regras de nó",
                    scoringLogic: "Lógica de classificação",
                    score: "Pontos:",
                    rule: "Regras",
                    multiBranchLogic: "Lógica de vários ramos",
                    highlight: "Destaque",
                    submit: "Enmissão",
                  },
                  ruleList: {
                    branch: "Ramo",
                    branchName: "Nome da filial:",
                    addRules: "Regras de aumento",
                  },
                  sidebar: { node: "Nó" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Acabamento automático",
                      canvasAdaptation: "Adaptativo de tela",
                      copy: "Reprodução",
                      delete: "Excluir",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Julgar que o nó filho de um nó não pode ser um destino de conexão",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "O nó de início não pode ser um destino de conexão",
                      startingNodeCannotConnectMultipleNodes:
                        "O nó inicial não pode conectar mais de um nó",
                      nodeCannotBeConnectedToItself:
                        "Os nós não podem ser conectados a si mesmos",
                      startNode: "Início do nó",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Cancelar a seleção completa",
                    selectAll: "Seleção completa",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Se está em conformidade",
                    gradedEvaluation: "Avaliação de classificação",
                  },
                },
              },
            },
            configuration: {
              title: "Regras de inspeção de qualidade",
              wechatTitle: "Modelos de análise",
              neme: "Nome",
              errorMessage: "A exclusão falhou, pelo menos uma condição",
              desc: "Descrição",
              status: "Estado",
              yes: "Eficaz",
              no: "Inválido",
              namePlaceHolder: "Por favor, insira o nome do rótulo",
              desPlaceHolder: "Por favor, insira uma descrição",
              nameEmpty: "O nome não pode estar vazio",
              desEmpty: "A descrição não pode ser vazia",
              type: "Tipo",
              ruler: "Regras inteligentes",
              machineLearning: "Modo de auto-aprendizagem",
              autoScore: "Pontuação inteligente (antiga)",
              manualScore: "Classificação manual",
              interaction: "Detecção de interação (antigo)",
              typeTips: {
                model:
                  'Modelo inteligente: é um conjunto predefinido de sistema de inspeção de qualidade, sem configuração e manutenção, que pode detectar rapidamente os problemas no serviço. Este modelo é mais comum. Se você não atender às demandas de negócios, use "regras inteligentes" ou "pontuação manual',
                rule: "Regras inteligentes: ou seja, pontuação automática baseada na regra AI",
                manual:
                  "Pontuação manual: ou seja, inspeção manual de qualidade, pontuação manual",
              },
              model: "Modelo:",
              ruleType: {
                words: "A técnica da fala",
                interaction: "Interação",
                feature: "Características",
                information: "Informações",
              },
              testingMode: {
                all: "Detectar todas as palavras-chave",
                any: "Detectar palavras-chave arbitrárias",
              },
              operatorTypesOfInteraction: {
                question: "Uma pergunta e uma resposta",
                element: "Os elementos respondem",
                repet: "Repetição de contexto",
                snatch: "Apreme a conversa",
                silent: "Silêncio",
              },
              role: "Detecção de papéis:",
              preposition: "Pré-operador:",
              range: "Faixa de detecção:",
              from: "No.",
              to: "Sentença para o primeiro",
              end: "Frase",
              hasOldSilentTip:
                'Olá, para aumentar a precisão, "Regras inteligentes-interação-silêncio" foi atualizado para "Tempo limite de resposta", é recomendável atualizar as regras de uso!',
              changeToOld: "Mudar para a versão antiga",
              changeToNew: "Mudar para a nova versão",
              logic: {
                logic: "Lógica de classificação",
                naTip:
                  "N/A é não o aplicativo. Defina as pré-condições para esta regra de inspeção de qualidade e teste quando a condição for atendia. Se a condição não for atendia, a regra não se aplica, a regra não será detectada.",
                score: "Pontos:",
                na: "N/A：",
                naDes: "Permitir que este item não seja envolvido na detecção",
                preRule: "Regras pré-condicional:",
                notCatchPreRule: "Quando as pré-condições não são atendidas:",
                naChecked: "Ao acertar N/A:",
                preRuleEmpty: "A regra pré-condicional não pode ser vazia",
                operatorEmpty:
                  "A condição necessária no operador não pode ser vazia",
                multipleEmpty:
                  "A condição necessária na pontuação lógica múltipla não pode ser vazia",
                average: "Média de pontuação dentro do grupo",
                addOrSubtract: "Pontos de adição e subtração direta",
                noChange: "Sem adicionar ou subtrair pontos",
                rule: "Regras:",
                all: "Satisfaz todos os operadores",
                any: "Satisfaça operadores arbitrários",
                customize: "Lógica de operador personalizado",
                multiple: "Pontuação lógica múltipla",
                heightLight: "Destaque:",
                machineLearningTip:
                  "Atualmente, o modo de autoaprendizagem só entra em vigor para o discurso de frase única. Use regras inteligentes para o discurso complexo",
                strategy: "Estratégia:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Aprovação e envio bem-sucedido",
            },
            components: {
              pageHeaderExtra: {
                test: "Teste",
                formal: "Oficialmente",
                submit: "Enmissão",
              },
              createModal: { index: { myRules: "Minhas regras" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Automação de processos",
              screeningCondition: "Condições de triagem",
              aiScreeningCondition: "Condições de triagem inteligentes",
              rule: "Regras",
              automaticAddition: "Adição automática",
              automaticAdditionTip:
                "Adição automática de dados à tarefa em tempo real ou cronometrada para inspeção de IA",
              autoAdd: {
                autoAddType: "Método de adição automática",
                addFrequency: "Frequência de adição",
                add: "Adicionar",
              },
              automaticSamplingInspection: "Inspeção automática de amostragem",
              automaticSamplingInspectionTip:
                "Extraia automaticamente dados que requerem inspeção de qualidade manual. Por exemplo, 10% dos dados aleatórios de ontem são coletados diariamente para amostragem manual",
              everyAgentCheckLimit:
                "O limite superior de cada atendimento ao cliente",
              automaticAllocation: "Alocação automática",
              automaticAllocationTip:
                "Atribuição de amostragem: Os dados selecionados são automaticamente alocados ao inspetor de qualidade. Por exemplo, 10% dos dados aleatórios de ontem são selecionados diariamente para amostragem manual e, em média, aleatoriamente atribuídos a 2 inspetores de qualidade para esta tarefa",
              automaticAllocationTip2:
                "Alocação de revisão: os dados do recurso são automaticamente atribuídos ao inspetor de qualidade para revisão. Por exemplo, os dados do recurso diário são alocados aleatoriamente para a revisão de 2 inspetores de qualidade para esta tarefa.",
              automaticPush: "Push automático",
              automaticPushTip:
                "Aviso de disparo: notificação/aviso durante a inspeção de qualidade, por exemplo, quando o atendimento ao cliente cometer um erro fatal, envie uma mensagem de notificação para o microgrupo de empresas",
              automaticPushTip2:
                "Relatório regular: Envie os resultados da inspeção de qualidade na forma de um relatório regular por e-mail, por exemplo, envie os resultados da inspeção de qualidade da inspeção aleatória manual de ontem para a caixa de correio do supervisor de atendimento ao cliente às 9 h todos os dias",
              automaticAssess: "Avaliação automática",
              automaticAssessTip:
                "Os resultados da amostragem manual são comparados regularmente com os resultados da inspeção de qualidade da IA para avaliação de precisão. Por exemplo, todos os dias às 8:00, os dados amostrados ontem serão avaliados quanto à precisão",
              reexaminationComplaint: "Recurso de reexame",
              spoktReviewDetail: "Configuração de negócios",
              spoktReviewDetailTip:
                "Configuração de funções como lista de inspeção de qualidade, lista de inspeção aleatória, empresa de reclamação, lista de revisão, observações, etc.",
              qaDetailAction: "Configuração de inspeção de qualidade",
              spoktDetailAction: "Configuração de inspeção aleatória",
              reviewDetailAction: "Configuração de revisão",
              appealAction: "Configuração de recurso",
              remarkTemplate: "Modelos de notas",
              automaticArchiving: "Arquivamento automático",
              conditionalRules: "Regras condicionais",
              qualityInspectionConditions: "Condições de inspeção de qualidade",
              unopened: "Não aberto ainda",
              sampleInspectionDistributionModel: "Atribuição de amostragem",
              reviewAllocationModel: "Distribuição de revisão",
              distributionModelTitle: "Estratégia de distribuição:",
              pushRules: "Regras de push",
              push: "Push",
              assessRules: "Regras de avaliação",
              on: "on",
              off: "off",
              updateStatusSuccess: "A mudança de status foi bem-sucedida!",
              updateStatusFailed:
                "A mudança de status falhou, tente novamente mais tarde!",
              sampleNumberOrPercent: "Número ou proporção de amostras",
              inspectorList: "Inspetor de qualidade designado",
              numberOfSubmission: "Número de vezes de apresentação permitida",
              rewivewDays: "Dia",
              TimeOfSubmission: "O período de validade da apresentação",
              rewivewSecond: "Vezes",
              switchOn: "Ligado",
              switchOff: "Fechar",
              notMatchAnyRule: "Quando você não corresponde a qualquer regra:",
              appealOptionsList: {
                hiddenInspector:
                  "Recurso para ocultar informações do inspetor de qualidade",
                hiddenHit:
                  "A página de apelação suporta acertos de posicionamento de destaque",
              },
              caseLibrary: "Sincronização da biblioteca de casos",
              caseLibrarySyncTip:
                "Sincronizar casos adicionados ao sistema QA com o sistema de atendimento ao cliente",
              addCase: "Aumentar a sincronização",
              caseLibraryId: "Biblioteca de casos",
              caseLibraryIdTip:
                "Agrupamento de bibliotecas de casos para sistemas QA",
              udeskCaseLibraryIdTip:
                "Agrupamento de casos do sistema de atendimento ao cliente",
              syncHis: "Casos históricos de sincronização",
              otherSettings: "Outras configurações",
              udeskCaseLibraryId: "Grupo de casos de push",
              url: "URL de push",
              urlTip:
                "Digite o endereço da interface que começa com http:// ou https://",
            },
            templateNew: {
              secondaryReview: "Revisão de segundo nível",
              numberOfAppealsAllowedToBeSubmitted:
                "Número de reclamações permitidas",
              validityOfSubmissionOfAppeal:
                "Período de validade do envio do recurso",
              validityOfAppealReview:
                "Período de validade da revisão do recurso",
              hours: "Horas",
              businessSummary: "Resumo de negócios",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Limite inferior para cada atendimento ao cliente",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "O resumo de negócios é gerado automaticamente pelo modelo grande, a dimensão de geração pode ser personalizada de acordo com a situação do negócio",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Estratégia de alocação de apenas um nível de revisor",
              secondaryCompounding: "Composto de segundo nível:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Os revisadores de segundo nível suportam apenas distribuição uniforme",
              secondLevelReviewer: "Auditor de segundo nível:",
              samplingInspectionTarget: "Objetivo de inspeção aleatória",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Defina a meta de inspeção aleatória manual que deve ser concluída. Se a meta não for alcançada ou ultrapassada, um lembrete será dado. Por exemplo, cada atendimento ao cliente deve completar 100 inspeções aleatórias por mês. Quando for inferior a 80, a inspeção de qualidade e as páginas de inspeção aleatória serão lembretes.",
            },
            component: {
              verificationFailed: "Falha na verificação",
              pleaseAddASecondLevelQualityInspector:
                "Por favor, adicione um inspetor de qualidade de segundo nível",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Prompt",
                    fieldName: "Nome do campo",
                    fieldDescription: "Descrição do campo",
                    generateContent: "Gerar conteúdo",
                    enabled: "Ativar se",
                    generationMethod: "Como gerar",
                    automaticGeneration: "Geração automática",
                    manualTrigger: "Acionamento manual",
                    filterCondition: "Condições de triagem",
                    return: "Voltar",
                    preserve: "Salvar",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Gerar resultados" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Não limitado",
                    limitedScope: "Faixa limitada",
                    add: "Adicionar",
                    thereIsCurrentlyNoDataAvailable: "Sem dados",
                  },
                },
              },
              index: {
                fieldName: "Nome do campo",
                fieldDescription: "Descrição do campo",
                nothing: "Nenhum",
                generationMethod: "Como gerar",
                generateManually: "Gerar manualmente",
                automaticGeneration: "Geração automática",
                generateContent: "Gerar conteúdo",
                notQualified: "Não limitado",
                filterCondition: "Condições de triagem",
                enabled: "Ativar se",
                type: "Tipo",
                system: "Sistema",
                custom: "Personalização",
                operation: "Operação",
                edit: "Editar",
                areYouSureToDeleteIt: "Tá certo de excluir?",
                delete: "Excluir",
                addField: "Aumentar o campo",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Configuração de função",
              roleConfigTip:
                "Quando os dados de chamada importados são mono, as regras de identificação são configuradas para o processamento de separação de conversadores. Função de atendimento ao cliente em conformidade com as regras",
              operator: "Operador",
              operatorLogic: "Lógica do operador",
            },
          },
          manualAdd: {
            index: {
              name: "Adicionar manualmente",
              tip: "Usado para filtrar dados de visualização por meio de condições de configuração manual e adicioná-los rapidamente à tarefa de inspeção de qualidade",
              screeningCondition: "Adicionar por filtros",
              aiScreeningCondition: "Condições de triagem inteligentes",
              rule: "Regras",
              createTime: "Adicionar tempo",
              filterTitle: "Filtrar conteúdo",
              filterRule: "Condições de triagem",
              startTime: "Hora de início",
              endTime: "Hora do fim",
              records: "Importar registros",
              createUserName: "Operador",
              successCount: "Adição de sucesso",
              conditionRule: "Regras condicionais:",
              preview: "Pré-visualização",
              previewData:
                "Atualmente, as condições de triagem {0} são atendidas, as tarefas {1} foram adicionadas, as tarefas {2} podem ser adicionadas e as tarefas {3} não podem ser adicionadas",
              failCount: "Falha na adição",
            },
          },
          basicInfo: {
            index: {
              title: "Informações básicas",
              name: "Nome",
              taskName: "Nome da tarefa",
              taskNamePlaceholder: "Por favor, insira o nome da tarefa",
              requiredName: "O nome da tarefa não pode estar vazio",
              taskRemarkPlaceholder: "Por favor, insira a descrição da tarefa",
              requiredRemark: "A descrição da tarefa não pode ser vazia",
              remark: "Descrição",
              taskRemark: "Descrição da tarefa",
              source: "Fonte de dados",
              benchmarkScore: "Pontos de referência",
              benchmarkScorePlaceholder:
                "Por favor, insira a pontuação de referência",
              scoreLowerLimit: "Pontuação mínima limite inferior",
              scoreUpperLimit: "Maior número de pontos",
              inspectType: "Tipo de inspeção de qualidade",
              nlpModel: "Modelos NLP",
              commonInspect: "Inspeção de qualidade convencional",
              associateInspect: "Inspeção de qualidade associada",
              associateCycle: "Ciclo de associação",
              associateRegulation: "Regras de associação",
              judgeStrategy: "Lógica de execução",
              rule: "Regras",
              commonTip:
                "Inspeção de qualidade convencional: adequado para uma única comunicação e diálogo, e o conteúdo de uma única comunicação é inspeção de qualidade",
              associateTip:
                "Inspeção de qualidade relacionada: adequada para comunicação múltipla e diálogo, para vincular o conteúdo de comunicação múltipla para inspeção de qualidade",
              cycleTip:
                "Se você associar várias comunicações, diálogo, os dados dentro do ciclo de associação entrar na inspeção de qualidade da tarefa",
              scoreLowerLimitPlaceholder:
                "Por favor, insira o limite inferior da pontuação mínima",
              scoreUpperLimitPlaceholder:
                "Por favor, insira o limite máximo de pontuação",
              call: "Chamada de voz",
              dialogue: "Diálogo de texto",
              user: "Inspetor de qualidade",
              wechatUser: "Operador",
              noDesignated: "Não especificar",
              type: "Tipo de formulário",
              singleScore: "Pontuação única",
              conduct:
                "Por favor, aperfeiçoe as seguintes informações primeiro, clique em Próximo após a conclusão",
              conduct2:
                "Por favor, complete a configuração das regras de inspeção de qualidade e do ponto de inspeção de qualidade, se você tiver concluído a configuração, você pode clicar Próximo ou sair da orientação",
              conduct3:
                "A classificação de inspeção de qualidade é a configuração da classificação com base no ponto de inspeção de qualidade e sua situação de classificação. Você pode definir diferentes níveis de resultados de inspeção de qualidade. Se você não precisar da classificação, você pode pular esta etapa diretamente. Se você concluiu a configuração, você pode clicar em Próximo ou sair da orientação",
              conduct4:
                "Você pode adicionar automaticamente o local de acordo com as características de dados que devem ser verificadas nesta tarefa de inspeção de qualidade para configurar as características de dados que serão introduzidas automaticamente na tarefa. Se você não precisar adicionar dados automaticamente por enquanto, você pode clicar em pular, se você tiver concluído a configuração, você pode clicar em Avançar",
              conduct5:
                "Se você estiver usando uma gravação mono, configure este item e clique na próxima etapa. Use o sistema de ajuda para identificar as diferentes faixas do atendimento ao cliente e do cliente. Se você usar a gravação de dois canais ou tiver concluído a configuração, você pode clicar para pular",
              conduct6:
                "Você pode adicionar recursos manualmente para introduzir dados de conversação existentes no sistema para inspeção de qualidade ou você enviou para adicionar tarefas manualmente, clique diretamente em Concluir",
              helper: "Ver a documentação de ajuda",
              quit: "Orientação de saída",
              next: "Próximo passo",
              complete: "Concluído",
              skip: "Pular",
              cancel: "Cancelamento",
              delete: "Excluir",
              save: "Salvar",
            },
            template: {
              samplingInspector: "Inspetor de amostragem",
              sessionType: "Tipo de sessão",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Bancada de inspeção de qualidade",
              search: "Filtragem",
              export: "Exportar",
              condition: "Condições",
              logic: "Lógica",
              reQualityInspection: "Re-inspeção de qualidade",
              reQualityInspectionUnderWay: "Na missão de re-inspeção...",
              reQualityInspectionUnderWayTip:
                "Deixar a página depois de fechar a janela não afeta o andamento da tarefa",
              reQualityInspectionDone:
                "A reinspeção de qualidade foi concluída",
              reQualityInspectionDoneTip:
                "Agora que a reinspeção foi concluída com sucesso, clique no botão de confirmação",
              endReQualityInspection: "Encerre a tarefa",
              alreadyDoneTip: "Concluído",
              totalTip: "Total",
              unit: "Artigo",
              endReInspectionSuccess:
                "A tarefa de reinspeção de qualidade foi encerrada",
              closeWindow: "Fechar a janela",
              endReInspectionFailed:
                "A tarefa de reinspeção de qualidade falhou, por favor, tente novamente mais tarde!",
            },
            detail: {
              name: "Detalhes da inspeção de qualidade",
              reviewName: "Detalhes da revisão",
              spotCheckName: "Detalhes da inspeção aleatória",
              audioText: "Texto de gravação",
              callInfo: "Informações sobre chamadas",
              satisfactionEvaluation: "Avaliação de satisfação:",
              queueTime: "A fila consome tempo:",
              ringingTime: "Tempo de toque:",
              callTime: "Eventos de chamada:",
              hangUpIdentity: "O lado da chamada:",
              location: "Atribuições:",
              source: "Fonte:",
              agent: "Atendimento ao cliente:",
              deviceType: "Tipo de dispositivo:",
              callType: "Tipo de chamada:",
              phoneNumber: "Número de retransmissão:",
              task: "Missão:",
              commentsTip:
                "Por favor, insira um comentário de inspeção de qualidade",
              lastPage: "Página anterior",
              nextPage: "Próxima página",
              score: "Pontuação de inspeção de qualidade",
              review: "Revisão",
              file: "Arquivamento",
              changeList: "Registro de mudança",
              fullMarks: "Pontuação total",
              most: "No máximo",
              saveReview: "Submissão de revisão",
              saveSpotCheck: "Apresentação de inspeção aleatória",
              point: "Ponto de inspeção de qualidade",
              sampleAssignInspector: "Atribuir inspetores de amostragem",
              reviewAssignInspector: "Distribuição de revisor",
              sampleStatus: "Status de inspeção aleatória",
              read: "Já leu",
              user: "Usuário",
              confirmer: "Confirmação de pessoas",
              voiceDownloadError:
                "O download de voz falhou, tente novamente mais tarde!",
              smartTags: "Rótulos inteligentes",
              interactiveTip:
                "Detectar o uso de FAQ de atendimento ao cliente usando o reconhecimento interativo",
              interactiveIsEmptyTip:
                "Conteúdo de reconhecimento de interação não detectado",
              businessRecords: "Registros de negócios",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Análise de bate-papo em grupo WeChat corporativo",
            },
          },
        },
      },
      cards: {
        health: "Saúde da inspeção de qualidade",
        accuracy: "Taxa de aprovação de descoberta",
        sessionsTotal: "Número total de sessões",
        enable: "Habilitar",
        disable: "Desativar",
        none: "Não.",
        call: "Chamada",
        dialogue: "Diálogo",
        taobao: "Comércio eletrônico",
        wecom: "Sessão",
        ticket: "Lista de trabalho",
      },
      component: {
        backup: "(Backup)",
        taskSaveAsSucceeded: "Salvar a missão como um sucesso",
        copyContent: "Copiar conteúdo",
        essentialInformation: "Informações básicas",
        ruleConfiguration: "Configuração da regra",
        basicInformationQualityInspector:
          "Informações básicas-Inspetor de Qualidade",
        qualityInspectionRating: "Classificação de inspeção de qualidade",
        roleConfiguration: "Configuração de função",
        autoAddConfiguration: "Adicionar automaticamente a configuração",
        autoPushConfiguration: "Configuração automática de push",
        automaticSamplingConfiguration: "Configuração de amostragem automática",
        automaticAllocationConfiguration: "Configuração de alocação automática",
        businessConfiguration: "Configuração de negócios",
        caseBaseConfiguration: "Biblioteca de casos",
      },
      template: {
        cancel: "Cancelamento",
        complete: "Concluído",
        sort: "Ordenação",
        ruleApproval: "Aprovação de regras",
        enterpriseMicroConversationAnalysisTask:
          "Tarefa de análise de micrssession",
        enterpriseAndMicroVoiceAnalysisTask:
          "Tarefa de análise de fala micro corporativa",
        saveTaskAs: "A tarefa é salva como",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Detalhes da regra" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Modificações bem-sucedidas",
            automaticApproval: "Aprovação automática",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Modificações bem-sucedidas",
            test: "Teste",
            formal: "Oficialmente",
            adopt: "Através",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Determinada a rejeição?",
              pleaseEnterTheRejectionReason:
                "Por favor, insira o motivo da rejeição",
              reject: "Rejida",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Modificações bem-sucedidas",
              taskName: "Nome da tarefa",
              dataSource: "Fonte de dados",
              qualityInspectionType: "Tipo de inspeção de qualidade",
              state: "Estado",
              updateTime: "Tempo de atualização",
              updatedBy: "Homem de atualização",
              approver: "Aprovador",
              reasonForRejection: "Razões para rejeição",
              operation: "Operação",
              details: "Detalhes",
              adopt: "Através",
              whole: "Todos",
              approvalStatus: "Status de aprovação",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Visão geral",
      hi: "Oi,",
      standardEdition: "Versão padrão",
      call: "Saldo de inspeção de qualidade de voz",
      text: "Período de validade da inspeção de qualidade do texto",
      collectionTasks: "Tarefas de inspeção de qualidade da coleção",
      noCollection: "Não há tarefas de inspeção de qualidade para coleta,",
      goToCollecte: "Vá para a coleção",
      yesterdayOverView: "Estatísticas de negócios de ontem",
      yesterdayAgent: "Classificação de atendimento ao cliente ontem",
      yesterdayInspWork: "O trabalho do inspetor de qualidade ontem",
      operationalStatistics: {
        title: "Estatísticas de negócios",
        qualityTesting: "Inspeção de qualidade",
        spotCheck: "Inspeção aleatória",
        appeal: "Reclamação",
        chartTitles: {
          qualityTestingTrend: "Tendência de qualidade",
          qualityTestingAnalysis: "Tarefa de inspeção de qualidade",
          spotCheckTrend: "Tendência de inspeção aleatória",
          spotCheckAnalysis: "Tarefa de inspeção aleatória",
          appealTrend: "Tendências de apelação",
          appealAnalysis: "Tarefa de recurso",
        },
        titles: {
          qualityDataTend: "Distribuição de dados de inspeção de qualidade",
          qualityCover: "Cobertura de inspeção de qualidade",
          qualityAverageTime:
            "Média de tempo de espera para inspeção de qualidade",
          qualityCoverTend: "Tendências de cobertura de inspeção de qualidade",
          handCover: "Situação de processamento manual",
          checkCover: "Cobertura de inspeção aleatória",
          resAverageTime: "Dduração média de processamento",
          handTend: "Tendências de processamento humano",
          dataTend: "Distribuição de tarefas de dados",
        },
        qualityAverage: "Pontos médios de inspeção de qualidade",
        scoreTrend: "Tendência de pontuação de inspeção de qualidade",
        qualityAverageTime:
          "Média de tempo de espera para inspeção de qualidade",
        callCoverage: "Cobertura de qualidade de voz",
        textCoverage: "Cobertura de inspeção de qualidade de texto",
        checkCount: "Volume de dados de amostragem",
        siphonCount: "Extraia a quantidade de dados",
        reviewCount: "Reexame da quantidade de dados",
        appealCount: "Quantidade de dados de reclamações",
        checkRate: "Proporção de inspeção aleatória",
        reviewRate: "Proporção de revisão de recursos",
        PieTitle: {
          totalCount: "Número total de conversas",
          totalCall: "Número de chamadas de voz",
          totalIm: "Número de conversas de texto",
          inspectionCall: "Quantidade de qualidade de voz",
          inspectionIm: "Quantidade de inspeção de qualidade de texto",
          checkCall: "Volume de amostragem de voz",
          unCheckCall: "Volume de voz não verificado",
          checkIm: "Volume de amostragem de texto",
          unCheckIm: "Texto não verificado",
          reviewCall: "Quantidade de revisão de voz",
          reviewIm: "Quantidade de revisão de texto",
          unReviewCall: "Quantidade de voz não revisada",
          unReviewIm: "A quantidade de texto não revido",
          unInspectionCall: "Volume de voz sem inspeção",
          unInspectionIm: "A quantidade de texto não é de qualidade",
        },
        check_rate: "Cobertura de inspeção aleatória",
        voice_check_rate: "Cobertura de amostragem de voz",
        text_check_rate: "Cobertura de amostragem de texto",
        sample_execute_time: "Dduração média do processamento de amostragem",
        review_execute_time: "Dduração média de processamento de reclamações",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Kanban de regras de inspeção de qualidade",
          agent: "Classificação de atendimento ao cliente",
        },
        tableTips: {
          conditions:
            "<Div> Classifique o número total de conversas em conformidade e violações das regras de inspeção de qualidade. <Br/>\nItem de inspeção de qualidade: nome do ponto de inspeção de qualidade <br />\nNúmero total de conversas ilegais: número total de conversas ilegais no item de inspeção de qualidade <br />\nA proporção de violações: a proporção de violações = número total de conversas ilegais/número total de conversas comunicadas </div>",
          agent:
            "<Div> Classifique a pontuação média dos assentos <br />\nNome do atendimento ao cliente: nome do atendimento ao cliente <br />\nGrupo de atendimento ao cliente: Grupo de atendimento ao cliente (informações da organização nas informações de atendimento ao cliente)<br />\nNúmero total de conversas avaliadas: o número total de conversas de inspeção de qualidade do atendimento ao cliente <br />\nPontuação média de inspeção de qualidade: Pontuação média de inspeção de qualidade do atendimento ao cliente (soma de pontuação/número total de conversas)</div>",
        },
        tips: {
          total:
            "Número total de comunicação/diálogo para entrar na tarefa de inspeção de qualidade",
          agentTotal:
            "O número total de clientes de inspeção de qualidade que entram na tarefa de inspeção de qualidade",
          avg: "Pontuação média da pontuação de inspeção de qualidade",
          violation:
            "Todas as conversas de comunicação sobre o número de violações de inspeção de qualidade",
          compliance:
            "Diálogo geral durante o processo de inspeção de qualidade",
          check: "Número total de inspeções aleatórias manuais",
          appeal: "Total de recursos apresentados no número total de conversas",
          review:
            "O número total de pessoas submetidas à revisão no número total de conversas",
        },
        subTips: {
          violation:
            "Taxa de violação: número total de violações/número total de itens de inspeção de qualidade * 100%",
          compliance:
            "Taxa de conformidade: número total de conformidade/número total de itens de inspeção de qualidade * 100%",
          check:
            "Cobertura de inspeção aleatória: número total de inspeção aleatória manual/inspeção de qualidade total * 100%",
          appeal:
            "Taxa de reclamação: número total de reclamações/inspeção de qualidade total * 100%",
          review:
            "Taxa de revisão: total de revisão/total de inspeção de qualidade * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Configurações do sistema",
          systemName: "Nome do sistema",
          speakerRecognize: "Reconhecimento de falantes",
          massage: "Notificação de mensagem",
          evaluate: "Avaliação cognitiva artificial",
          evaluateTip:
            "Após a abertura, selecione a inspeção aleatória rápida nos detalhes da inspeção de qualidade. A avaliação manual será solicitada",
          speakerRecognizeTip:
            "O acesso à gravação de chamadas de terceiros por meio da API precisa identificar se o canal esquerdo é o atendimento ao cliente ou o cliente",
          speakerRecognizeTip2:
            "A gravação de canal único define a função de atendimento ao cliente por meio do reconhecimento de texto, configuração de função",
          enabledImSendStatusFilter:
            "Filtragem de mensagens de qualidade de texto",
          enabledImSendStatusFilterTips:
            "O conteúdo de detecção do operador de inspeção de qualidade não inclui mensagens cujo status é [Retirado], [Falha ao enviar], [Mensagem offline não lida]",
          lang: "Configurações de idioma do sistema",
          zh: "Chinês simplificado",
          en: "English",
          tw: "Chinês tradicional",
        },
        template: {
          largeModelSettings: "Configurações do modelo grande",
          indonesian: "Língua indonésia",
          vietnamese: "Vietnamita",
          portuguese: "Português",
          malay: "Malaio",
          french: "Francês",
          spanish: "Espanhol",
          japanese: "Japonês",
          thai: "Tailandês",
          blockSystemSendingMessages:
            "Sistema de bloqueio para enviar mensagens",
          textDataDesensitization: "Dessensibilização de dados de texto",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "O interruptor é aberto para mostrar a lista suspensa do campo de dessensibilização (suporte para seleção múltipla), campo de dessensibilização: nome da pessoa, número do celular, endereço",
          desensitizingField: "Campos de dessensibilização",
          name: "Nomes de pessoas",
          cellphoneNumber: "Número de celular",
          address: "Endereço",
          dataCleaning: "Limpeza de dados",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Após a configuração, o diálogo de inspeção de qualidade e os dados da sessão do WeChat corporativo serão limpos regularmente e não podem ser restaurados. Por favor, opere com cuidado",
          newCleaningRules: "Novas regras de limpeza",
          qualityInspectionRuleApproval:
            "Aprovação de regras de inspeção de qualidade",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Após a abertura, a revisão das regras de inspeção de qualidade precisa ser aprovada antes de entrar em vigor",
          maskOfflineMessageUnread: "Bloquear mensagens off-line não lidas",
          privacyNumberProtection: "Proteção do número de privacidade",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Após a abertura, o campo "Telefone do Cliente" é dessensibilizado e exibido como 1881 *******',
          shieldingWithdrawn: "O escudo foi retirado",
          maskSendingFailed: "Bloquear falha de envio",
        },
        component: {
          conversation: "Chamada",
          dialogue: "Diálogo",
          weCom: "WeChat corporativo",
          day: "Dia",
          week: "Semana",
          month: "Mês",
          year: "Ano",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Limpe o escopo",
            dataCleaningTimeSetting:
              "Configurações de tempo de limpeza de dados",
            afterCleanUpTheData: "Depois de limpar os dados",
            dataAfterComplaintCompletion: "Dados após a conclusão do recurso",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Execução bem sucedida",
          saveSuccessful: "Salve o sucesso",
          speechRecognition: "Reconhecimento de voz",
          reExecuteErrorCorrection: "Reexecutar a correção de erros",
          confirmExecution: "Confirmar a execução",
          voiceDetection: "Detecção de voz",
          noiseDetectionPowerLevel: "Detecção de ruído powerLevel ≤",
          pleaseEnterTheThreshold: "Por favor, insira o limite",
          volumeDetectionPowerLevel: "Detecção de volume powerLevel ≥",
          reset: "Redefinir",
          preserve: "Salvar",
        },
        coachTemplate: {
          french: "Francês",
          spanish: "Espanhol",
          japanese: "Japonês",
          thai: "Tailandês",
          loginPopupWindow: "Janela pop-up de login",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Após a abertura, ele será solicitado ao entrar no sistema de treinamento de sparring inteligente",
          title: "Título",
          text: "Texto principal",
          checkBoxContent: "Conteúdo da caixa de seleção",
          informedConsentForm: "Consentimento informado",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Para ajudá-lo a melhorar sua comunicação e habilidades de fala, bem como a aplicação de conhecimentos especializados, permitindo que você pratique a qualquer hora e em qualquer lugar e aceite feedback em tempo real, coletaremos as seguintes informações através desta plataforma:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Eu li e compreendo totalmente este formulário de consentimento informado e declarei solenemente que concordei em processar minhas informações pessoais sensíveis de acordo com os propósitos e métodos descritos neste formulário de consentimento informado",
        },
      },
      systemIntegration: {
        title: "Configurações do sistema",
        ccIntegrationTips:
          "Documento de descrição do call center Udesk de encaixe de inspeção de qualidade inteligente",
        unauthorized: "Não autorizado",
        authorized: "Autorizado",
        udeskDomain: "Nome de domínio do sistema de atendimento ao cliente",
        udeskToken: "Chave do sistema de atendimento ao cliente",
        udeskAdminEmail:
          "Conta de administrador do sistema de atendimento ao cliente",
        password: "Senha da conta de administrador",
        applyAuth: "Autorização",
        cancelAuth: "Cancelar autorização",
        reApplyAuth: "Reautorização",
        keyTips:
          "Gerenciamento em segundo plano do sistema de atendimento ao cliente-API de autenticação Token em logon único",
      },
      customerField: {
        title: "Campos do cliente",
        table: {
          propName: "Nome do campo",
          propDesc: "Descrição do campo",
          valueType: "Tipo de campo",
          propType: "Como criar",
          createTime: "Tempo de criação",
          canNotEdit: "Os campos do sistema não suportam a edição",
        },
        edit: "Editar um campo",
        new: "Novo campo",
      },
      wechatDocking: {
        title: "Micro docagem empresarial",
        formLabels: {
          companyId: "ID da empresa",
          communicationKey: "Chave de comunicação",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL de retorno de chamada de evento",
          applicationName: "Nome do aplicativo",
          applicationId: "ID do aplicativo Web",
          applicationSecret: "Aplicativos secret",
        },
        formTitle: {
          initialForm: "Configuração inicial",
          synchronizeForm: "Sincronização de contatos",
          settingForm: "Configurações do aplicativo",
        },
        qrCodeUrlTip:
          "Peça ao administrador para verificar o código QR abaixo para desenvolver a ligação do aplicativo",
      },
      taobao: {
        formLabels: {
          name: "Nome da loja",
          account: "Conta",
          password: "Senha",
          confirm: "Confirmar a senha",
          authorization: "Autorização",
          switchOn: "Ligado",
          switchOff: "Fechar",
        },
        tableLabels: {
          name: "Nome da loja",
          account: "Conta",
          authorization: "Autorização",
          updateUser: "Atualizações recentes de pessoas",
          updateTime: "Tempo de atualização recente",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Nomes de domínio de segundo nível da Weifeng",
          secretKeyTip:
            "A chave secreta do inquilino, obtida no encaixe do sistema de back-end do sistema Weifeng",
        },
      },
      user: {
        index: {
          name: "Gestão de funcionários",
          existingUsers: "Funcionários já",
          deleteSuccess: "Exclusão bem sucedida",
          deleteFailed: "A exclusão falhou",
        },
        detail: {
          accountPrompt: "Por favor, insira uma caixa de correio",
          resetPassword: "Redefinir a senha",
          passwordPrompt:
            "Senha (6-14 letras de dígitos em combinação com números)",
          originPasswordPrompt: "Por favor, insira a senha inicial",
          resumePasswordPrompt: "Por favor, digite novamente a senha",
          passwordFormWrong:
            "Por favor, digite 6-14 letras de dígitos com uma senha de combinação numérica!",
          resumePasswordWrong: "Duas vezes a senha não é consistente!",
          suppertImageType: "Suporte jpg, gif, png, até 500Kb",
          namePrompt: "Por favor, insira um nome",
          nickNamePrompt: "Por favor, digite o apelido",
          telephonePrompt: "Por favor, insira um número de telefone",
          roleSelectedPrompt: "Por favor, escolha o personagem",
          agentIdPrompt: "Por favor, insira o número de trabalho",
          disabled: "Desativar",
          enabled: "Habilitar",
          reloadBowerInfo:
            "As informações do usuário mudam, você precisa atualizar o navegador novamente?",
          saveUserError:
            "Salvar as informações do usuário falhou, tente novamente mais tarde",
          resetPasswordSuccess: "Redefinição de senha bem-sucedida",
          resetPasswordFailed:
            "A redefinição de senha falhou, tente novamente mais tarde",
        },
      },
      userGroups: {
        index: {
          name: "Gestão de grupos de funcionários",
          existingUserGroups: "Grupos de funcionários existentes",
        },
        detail: {},
      },
      role: {
        index: {
          name: "Gerenciamento de papéis",
          existingRoles: "Papel existente",
        },
        edit: {
          editRole: "Editar uma função",
          createRole: "Criar um personagem",
          basicInfo: "Informações básicas",
          taskPermissions: "Permissões de tarefas",
          existingTasks: "Tarefas já existentes",
        },
      },
      anomalousData: {
        title: "Dados de exceção",
        list: {
          selectHolder: "Escolha a fonte de dados",
          businessTime: "Tempo de negócios",
          businessId: "Id de negócios",
          export: "Exportar",
        },
      },
      templateComponent: {
        list: {
          title: "Mercado de modelos",
          name: "Nome do modelo",
          searchHolder: "Por favor, insira o que você quer consultar",
          update: "Situação de atualização",
          sort: "Método de classificação",
          moduleType: "Tipo de modelo",
          moduleSourceType: "Tipo aplicável",
          fetchNum: "Número de aquisição",
          newest: "Atualizações recentes",
          usedMost: "Obter o máximo",
          call: "Chamada",
          dialogue: "Diálogo",
          waiting: "A ser revisado",
          cancel: "Cancelar a publicação",
          success: "Revisão aprovada, publicada",
          failed: "Rejida pela revisão",
          no: "Não atualizável",
          ok: "Pode ser atualizado",
          all: "Todos",
          delete: "Exclusão bem sucedida",
          downOk: "Salta bem-sucedida",
          withdraw: "Retirada bem sucedida",
          updateOk: "O upgrade foi bem sucedido",
          get: "Obter",
          width: "Retirada",
          getOk: "Obter o sucesso",
          orgName: "Organização",
          nowVersion: "Versão atual",
          lastedVersion: "Versão mais recente",
          appStatus: "Estado da aplicação",
          actions: "Operação",
          down: "Salta",
          updateTop: "Atualização",
          check: "Veja",
          deleteWhen: "Confirmar a exclusão?",
          yes: "Sim",
          noo: "Não.",
          deleteOk: "Excluir",
          permissible: "Permitido",
          noPermissible: "Não permitido",
          openUp: "Aberto",
          openDown: "Não aberto",
          task: "Tarefa de inspeção de qualidade",
          point: "Ponto de inspeção de qualidade",
          tag: "Rótulos semânticos",
          muti: "Multi-elementos",
          inter: "Uma pergunta e uma resposta",
          info: "Entidades de informação",
          concept: "Palavra de conceito",
          detail: "Detalhes do módulo",
          infomation: "Informações básicas",
          detailConfig: "Configuração de detalhes",
          twoEdit: "Edição secundária",
          mustUpdate: "Atualizar agora",
          descition: "Descrição do modelo",
          descitionSize: "A descrição do modelo não pode exceder 250 palavras",
          stencilsDetail: "Detalhes do modelo",
          updatedRecords: "Atualizar registros",
          version: "Número da versão",
          updateDescription: "Descrição da atualização",
          myModule: "Meu modelo",
          addModule: "Novo módulo",
          publishVersion: "Versão de lançamento",
          earliestVersion: "Versão mais antiga",
          edit: "Editar",
          add: "Adicionado",
          configNo:
            "Os detalhes da configuração do módulo não podem estar vazios",
          updateNo: "A descrição da atualização não pode estar vazia",
          editSuccess: "Modificações bem-sucedidas",
          saveSuccess: "Salve o sucesso",
          newer: "Atualização",
          sumbit: "Enmissão",
          cancelName: "Cancelamento",
          nameRequired: "O nome do módulo é um campo obrigatório",
          nameSize: "O nome do módulo não pode exceder 16 palavras",
          publishStatus: "Status de publicação",
          submitUpdate: "Enviar uma atualização",
          updateVersion: "Versão atualizada",
          updateExplain: "Instruções de atualização",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon comércio eletrônico",
        youtubeVideo: "Vídeos do Youtube",
        amazon: {
          component: {
            queuing: "Na fila",
            notStarted: "Não começou",
            inProgress: "Em andamento",
            success: "Sucesso",
            failed: "Falha",
            successfullyCreated: "Criação de sucesso",
            editedSuccessfully: "Sucesso editorial",
            operationSucceeded: "Operação bem sucedida",
            deletedSuccessfully: "Exclusão bem sucedida",
            link: "Link",
            grabState: "Estado de agarrar",
            updateTime: "Tempo de atualização",
            grab: "Agarra",
            edit: "Editar",
          },
          template: {
            amazonEcommerce: "Amazon comércio eletrônico",
            thisProduct: "Este produto",
            competition: "Produtos concorrentes",
            new: "Novo",
            edit: "Editar",
            link: "Link",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Vídeos do Youtube",
            thisProduct: "Este produto",
            competition: "Produtos concorrentes",
            new: "Novo",
            edit: "Editar",
            link: "Link",
          },
          component: {
            queuing: "Na fila",
            notStarted: "Não começou",
            inProgress: "Em andamento",
            success: "Sucesso",
            failed: "Falha",
            successfullyCreated: "Criação de sucesso",
            editedSuccessfully: "Sucesso editorial",
            operationSucceeded: "Operação bem sucedida",
            deletedSuccessfully: "Exclusão bem sucedida",
            youTubeVideoLink: "Link de vídeo do YouTube",
            grabState: "Estado de agarrar",
            updateTime: "Tempo de atualização",
            grab: "Agarra",
            edit: "Editar",
          },
        },
      },
    },
    demo: {
      title: "Inspeção de qualidade inteligente",
      template: "Escolha um modelo de inspeção de qualidade",
      radio: "Escolha a forma de gravação",
      uploadAudio: "Fazer upload de uma gravação",
      uploadAudioTip:
        "A gravação de upload suporta apenas os formatos wav e mp3, o tamanho não excede 1G",
      uploadText: "Fazer upload de texto",
      uploadTextTip:
        "O upload de texto suporta apenas os formatos srt e txt, o tamanho não excede 10M, o arquivo de gravação não será mais analisado usando ASR após o upload do texto",
      uploadSuccess: "Carregamento de arquivos de gravação bem-sucedido",
      uploadFailed: "Falha no upload do arquivo de gravação",
      startQualityInspection: "Início da inspeção de qualidade",
      qualityInspectionStarted: "Em inspeção de qualidade",
      downloadText: "Download de texto",
      checking: "Progresso da inspeção de qualidade",
      checkingTip: "Espere alguns minutos, seja paciente",
      noCheckContent: "Nenhum resultado de inspeção de qualidade",
      noTextDownload: "Não há texto de inspeção de qualidade para download!",
      pleaseCheckParams:
        "Verifique se você escolhe o modelo, o tipo de voz e o upload do arquivo de gravação",
      inspectionFailed:
        "A inspeção de qualidade falhou, por favor, tente novamente mais tarde!",
    },
    wechatQa: {
      baseInfo: { title: "Informações básicas" },
      conversationSetting: {
        title: "Configurações da sessão",
        segmentationStrategy: "Estratégia de divisão",
        rule: "Estratégia",
        time: "Tempo de divisão",
        daily: "Todos os dias",
        passScore: "Pontos de qualificação de sessão",
        ruleLogic: "Lógica de execução",
      },
      message: {
        title: "Eventos de push",
        table: {
          index: "Número de série",
          name: "Nome",
          type: "Maneira de push",
          desc: "Descrição",
          status: "Estado",
        },
        subTitle: "Lista de eventos",
      },
      checkPoint: {
        title: "Modelos de análise",
        groups: "Agrupamento de modelos",
        newClassification: "Novo agrupamento",
        editClassification: "Editar um agrupamento",
        benchmarkScore: "Pontos de referência",
        pointCount: "Modelo de análise comum na classificação atual",
        openPointCount: "A quantidade de ativação é",
        closedPointCount: "O número de desativação é",
        scoreLowerLimit: "Pontuação mínima limite inferior",
        scoreUpperLimit: "Maior número de pontos",
        classificationName: "Nome do agrupamento",
        classificationNamePlaceHolder: "Por favor, insira o nome do grupo",
        classificationRemark: "Descrição do agrupamento",
        classificationRemarkPlaceHolder:
          "Por favor, insira a descrição do agrupamento",
      },
      chartRecord: {
        title: "Sessões de WeChat Corporativo",
        table: {
          id: "ID da sessão",
          type: "Tipo de sessão",
          startTime: "Hora de início da sessão",
          endTime: "Tempo de encerramento recente da sessão",
          groupId: "ID do grupo",
          client: "Clientes",
          customer: "Atendimento ao cliente",
          averageScore: "Pontuação média de inspeção de qualidade",
        },
        detail: {
          title: "Detalhes da sessão WeChat da empresa",
          inspectionInfo: "Analisar informações",
          chartInfo: "Informações da sessão",
          tasks: "Tarefas de análise",
          avgScore: "Média de análise",
          qualified: "Qualificado",
          unqualified: "Não qualificado",
          taskTitle: "Modelos de análise aplicáveis",
          info: "Informações de análise em tempo real",
          today: "Notícias de hoje",
          history: "Mensagens históricas",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Por favor, insira notas/apelidos",
                pleaseEnterTheGroupChatName:
                  "Por favor, insira o nome do bate-papo em grupo",
              },
            },
          },
          template: { whole: "Todos" },
        },
      },
      realTimeAnalysis: {
        title: "Análise em tempo real",
        table: {
          name: "Nome da cena",
          count: "Número de modelos de análise",
          baseScore: "Pontos de referência",
          passScore: "Pontos qualificados",
          maxScore: "Maior número de pontos",
          minScore: "Pontuação mínima limite inferior",
          status: "Estado",
          actions: "Operação",
        },
        detail: {
          title: "Análise de cenários em tempo real",
          form: {
            name: "Nome da cena",
            prevCondition: "Condições de disparo de cena",
            prevConditionToolTip:
              "Se a condição estiver vazia, essa cena será analisada por padrão",
            prevConditionError:
              "As condições de disparo de cena não coincidem com o modelo de análise",
            scoreInputPlaceholder: "Insira a pontuação",
            baseScore: "Pontos de referência",
            status: "Estado",
            passScore: "Pontos qualificados",
            maxScore: "Limite de pontuação máxima",
            minScore: "Limite inferior mínimo",
            analysisModel: "Modelos de análise",
            message: "Acionando notificações",
            pushType: "Maneira de push",
            createAnalysisModel: "Adicionar um modelo",
            scoreUpperLimitError:
              "O limite inferior da pontuação mais baixa não pode ser maior que o limite superior da pontuação mais alta",
            table: { name: "Nome do modelo", rule: "Regras", score: "Pontos" },
            pushConfigSwitchTip1: "Quando a cena aparece",
            pushConfigSwitchTip2: "Quando e acima, notificações push",
          },
        },
      },
      smartCustomerTag: {
        title: "Etiqueta inteligente do cliente",
        table: {
          name: "Nome da regra",
          tags: "Etiqueta de cliente de micro empresa",
          status: "Ativar o estado",
          createUser: "Criador",
          createTime: "Tempo de criação",
        },
        detail: {
          name: "Nome da regra",
          condition: "Definir regras",
          logic: "Lógica",
          status: "Ativar o estado",
          tags: "Seleção de rótulos",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Número de marcas de clientes" },
        },
        messageFiltering: {
          template: {
            edit: "Editar",
            messageContent: "Conteúdo da mensagem",
            messageTypeID: "ID do tipo de mensagem",
            messageTypeDescription: "Descrição do tipo de mensagem",
            messageFiltering: "Filtragem de mensagens",
            suitableForFilteringDataWithoutQualityInspection:
              "Adequado para filtrar dados sem inspeção de qualidade",
            create: "Criar",
            messageDescription: "Descrição da mensagem",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Tempo de conversação",
          sessionType: "Tipo de sessão",
          whole: "Todos",
          staff: "Funcionários",
          customer: "Clientes",
          callKeywords: "Palavras-chave de chamada",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Detalhes da chamada de voz da Enterprise Micro",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Arquivo de sessão",
        voiceCall: "Chamada de voz",
        noMoreVoiceCalls: "Sem mais chamadas de voz",
        enterpriseWechatVoiceCall: "Chamada de voz do WeChat corporativo",
        enterpriseWechatConversationDetails:
          "Detalhes da sessão WeChat da empresa",
        previous: "O último",
        next: "O próximo",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Tarefa de análise de micrssession",
          enterpriseAndMicroVoiceAnalysisTask:
            "Tarefa de análise de fala micro corporativa",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Análise de bate-papo em grupo WeChat corporativo",
      },
      wechatQaSettingEntry: { messageFiltering: "Filtragem de mensagens" },
    },
    businessAnalysis: {
      keyword: "Nome do relatório",
      createUser: "Criador",
      createTime: "Tempo de criação",
      updateTime: "Tempo de atualização",
    },
    semanticIntelligence: {
      applicationMessageType: "Tipo de mensagem do aplicativo",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Por favor, melhore a configuração",
              generatePromptLanguage: "Gerar um prompt",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Se está em conformidade",
              gradedEvaluation: "Avaliação de classificação",
            },
          },
          training: {
            index: {
              pleaseEnter: "Por favor, insira",
              pleaseGeneratePrompt: "Por favor, gere um prompt",
              pleaseSelectADialogue: "Por favor, escolha o diálogo",
              training: "Treinamento",
              dataType: "Tipo de dados:",
              voiceCall: "Chamada de voz",
              textDialogue: "Diálogo de texto",
              generate: "Geração",
              qualityInspectionResults: "Resultados da inspeção de qualidade:",
              hit: "Hit e falar:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Nome do modelo",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Digite o nome do modelo, por exemplo: se há falta de ação no atendimento ao cliente",
            modelDescription: "Descrição do modelo",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Você pode descrever o conteúdo que você precisa testar em detalhes para facilitar a compreensão do modelo, como: se o atendimento ao cliente não fez nada, ou seja, o atendimento ao cliente tem uma resposta às perguntas do cliente, mas as perguntas do cliente não foram processadas ou o conteúdo da resposta não tem nada a ver com a resposta do cliente",
            validity: "Eficácia",
            effective: "Eficaz",
            invalid: "Inválido",
            type: "Tipo",
            hintLanguage: "Prompt",
            editIntelligentModel: "Editar um modelo inteligente",
            createANewIntelligentModel: "Novo modelo inteligente",
          },
        },
        index: {
          modelName: "Nome do modelo",
          modelDescription: "Descrição do modelo",
          type: "Tipo",
          whetherItMeetsTheRequirements: "Se está em conformidade",
          gradedEvaluation: "Avaliação de classificação",
          validity: "Eficácia",
          intelligentModel: "Modelos inteligentes",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Adequado para itens subjetivos de inspeção de qualidade (como se a atitude do serviço de inspeção de qualidade é entusiástica, se o serviço é paciente, etc.)",
        },
      },
      clusterAnalysis: "Análise de clustering",
      speechMining: "Escavação de palavras",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Suporte para importação em massa de entidades de informação através da interface da API, o nome da API não suporta edição depois de salvo",
        },
        component: { nothing: "Nenhum" },
      },
      knowledgeBase: {
        index: {
          classification: "Classificação",
          import: "Importação",
          importKnowledgeBase: "Importar uma base de conhecimento",
          moveKnowledgePointsInBatch:
            "Pontos de conhecimento em movimento em massa",
          moveTo: "Mover para:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Por favor, selecione os pontos de conhecimento que precisam ser movidos em massa",
        },
        detail: {
          index: {
            intrasentence: "Dentro da frase",
            afterProblem: "Depois do problema",
          },
        },
      },
      semanticTags: { component: { nothing: "Nenhum" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Palavras sensíveis",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Palavras sensíveis: palavras sensíveis podem ser configuradas com palavras de preocupação de negócios, usadas para encontrar palavras sensíveis na opinião pública",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Novas palavras sensíveis",
            editSensitiveWords: "Editar palavras sensíveis",
            sensitiveWords: "Palavras sensíveis",
          },
          template: {
            sensitiveWords: "Palavras sensíveis",
            sensitiveWordsCannotBeEmpty:
              "Palavras sensíveis não podem ser vazias",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Por favor, insira palavras sensíveis, várias palavras sensíveis são separadas por ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Por favor, selecione o cenário de aplicação",
            keyWord: "Palavras-chave",
            pleaseEnterKeywords: "Digite a palavra-chave",
            keywordLengthWithinCharacters:
              "Comprimento de palavras-chave dentro de 40 caracteres",
            dataSources: "Fontes de dados",
            pleaseSelectADataSource: "Escolha uma fonte de dados",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Agora que o cluster está concluído, a reedição produzirá uma nova linha de dados de cluster que não sobrescreverá os dados de cluster concluídos, confirme",
            savingSucceeded: "Salve o sucesso",
            clusteringStart: "Início do agrupamento",
            clustering: "Agrupamento",
            edit: "Editar",
            newlyBuild: "Novo",
            clusterName: "Nome do agrupamento",
            pleaseEnterAName: "Por favor, insira um nome",
            withinCharactersOfTheName:
              "Comprimento do nome dentro de 40 caracteres",
            speaker: "Falador",
            pleaseSelectASpeaker: "Por favor, escolha o falante",
            sessionDate: "Data da sessão",
            pleaseEnterTheSessionDate: "Por favor, insira a data da sessão",
            selectUpToMonthOfHistoricalSessionData:
              "Selecione até 1 mês de dados históricos da sessão",
            automaticallyClusterDailyNewData:
              "Novos dados diários de agrupamento automático",
            conversationChannel: "Canais de conversação",
            pleaseSelectAConversationChannel:
              "Por favor, escolha o canal de sessão",
            applicationScenario: "Cenários de aplicação",
            clusteringRules: "Regras de agrupamento",
            incompleteClusteringRuleSettings:
              "Configuração incompleta da regra de agrupamento",
            startClustering: "Início do agrupamento",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Por favor, escolha a tarefa de inspeção de qualidade",
              beEqualTo: "É igual a",
            },
          },
        },
        detail: {
          index: {
            theme: "Tema",
            numberOfCorpora: "Contexto",
            corpusCoverage: "Cobertura de corpus",
            clusterDetails: "Detalhes do cluster",
          },
          ranking: {
            hotWords: "Palavras quentes",
            numberOfCorpora: "Número de corpus",
            speaker: "Falador",
            sit: "Sente-se",
            passenger: "Convidado",
            hotSentence: "Sentença quente",
            corpusDetails: "Detalhes do corpus",
          },
        },
        index: {
          delete: "Excluir",
          deletionSucceeded: "Exclusão bem sucedida",
          clusterName: "Nome do agrupamento",
          speaker: "Falador",
          creationTime: "Tempo de criação",
          state: "Estado",
          operation: "Operação",
          see: "Veja",
          edit: "Editar",
          clusterAnalysis: "Análise de clustering",
          newCluster: "Novo agrupamento",
          clusteringStatus: "Estado de agrupamento",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Digite a palavra-chave para pesquisar objeções",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Biblioteca de habilidades",
                recommendedScripts: "Recomende falar",
                common: "O total",
                strip: "Artigo",
                scriptFavorites: "Favoritos da conversa",
              },
              conditionPostfix: {
                detectRoles: "Detecção de papéis",
                detectionRange: "Faixa de detecção",
                sentence: "Frase",
              },
              customerFilter: {
                scriptFiltering: "Filtragem de palavras",
                cancel: "Cancelamento",
              },
              index: {
                viewDetails: "Ver detalhes",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Confirmou que quer desistir? Não será recuperado após o abandono",
                abandonment: "Abandonar",
                deletionSucceeded: "Exclusão bem sucedida",
                passenger: "Convidado",
                sit: "Sente-se",
                adopted: "Adoptado",
                determine: "OK",
                cancel: "Cancelamento",
                adopt: "Adoção",
              },
              pageRightFrom: {
                savingSucceeded: "Salve o sucesso",
                pleaseEnterTheScriptContent:
                  "Por favor, insira o conteúdo da conversa",
                pleaseSelectAFavoriteGroup:
                  "Por favor, escolha o agrupamento de favoritos",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Banco de dados de processamento de objeções",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript:
                    "Por favor, escolha adotar a técnica",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Favoritos de processamento de objeções",
            },
            pageRightTable: {
              likeCancel: "Curta o cancelamento",
              likeSuccessfully: "Curta o sucesso",
              adoptScripts: "Adote a técnica da fala",
              operation: "Operação",
              viewConversationDetails: "Ver detalhes da conversa",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Biblioteca de pontos de venda de produtos",
              },
              pageRightTable: { keyPointsOfScript: "Pontos-chave do discurso" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Favoritos de processamento de objeções",
              newGroup: "Novo agrupamento",
              editGroup: "Editar um agrupamento",
              addGroup: "Adicionar um agrupamento",
              savingSucceeded: "Salve o sucesso",
              groupName: "Nome do agrupamento",
              pleaseEnterTheGroupName: "Por favor, insira o nome do grupo",
              groupNameWithinCharacters:
                "Nome de agrupamento dentro de 20 caracteres de comprimento",
              deletionSucceeded: "Exclusão bem sucedida",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings:
                    "Configurações recomendadas para falar",
                },
                rightForm: {
                  savingSucceeded: "Salve o sucesso",
                  intelligentRecommendation: "Recomendação inteligente",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Exibe automaticamente as excelentes técnicas de fala adotadas na barra lateral da conversa com objeções semelhantes. Os funcionários podem visualizar diretamente as técnicas de fala recomendadas na página de detalhes da repetição da sessão para aprender",
                  objectionsIncludedByDefault: "Objeções incluídas por padrão",
                  addRecommendationTriggerSemantics:
                    "Aumentar a semântica do gatilho de recomendação",
                  remindEmployeesToView: "Lembre os funcionários para ver",
                  shareWithMoreEmployees: "Compartilhar com mais funcionários",
                  allHands: "Todos os funcionários",
                },
                smartToolbar: {
                  selectSemanticTag: "Escolha um rótulo semântico",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Exporta todos os registros de dados no pacote atual?",
                newScript: "Nova técnica",
                editingScript: "Edição de palavras",
                savingSucceeded: "Salve o sucesso",
                objection: "Objeção",
                pleaseEnterAScriptObjection: "Por favor, insira objeções",
                speechTechnique: "A técnica da fala",
                pleaseEnterTheScriptContent:
                  "Por favor, insira o conteúdo da conversa",
                grouping: "Agrupamento",
                pleaseSelectAFavoriteGroup:
                  "Por favor, escolha o agrupamento de favoritos",
                adoptScripts: "Adote a técnica da fala",
                passenger: "Convidado",
                sit: "Sente-se",
                numberOfViews: "Visualizações",
                useful: "Útil",
                operation: "Operação",
                edit: "Editar",
                delete: "Excluir",
                deletionSucceeded: "Exclusão bem sucedida",
                scriptRecommendationSettings:
                  "Configurações recomendadas para falar",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Biblioteca de habilidades",
          scriptMining: "Escavação de palavras",
          objectionHandlingScripts: "Tratamento de objeções",
          productSellingPointScripts: "Ponto de venda do produto",
        },
        sellingPoint: {
          index: { newCluster: "Novo agrupamento" },
          components: {
            callDetailModal: {
              sessionDetails: "Detalhes da sessão",
              cancel: "Cancelamento",
              determine: "OK",
              choice: "Escolha",
            },
            pageCenterTable: { scriptOverview: "Visão geral da conversa" },
            pageLeftTable: {
              enterSearchKeywords: "Insira uma palavra-chave de pesquisa",
              keyWord: "Palavras-chave",
            },
            pageRightTable: {
              index: {
                viewDetails: "Ver detalhes",
                scriptLibrary: "Biblioteca de habilidades",
                scriptRefinement: "Extração da fala",
                adoptScript: "Adote a técnica da fala",
              },
              tagsEdit: { keyPointsOfScript: "Pontos-chave do discurso" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Biblioteca de pontos de venda de produtos",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Pontos de venda incluídos por padrão",
                  },
                },
                index: {
                  keyWord: "Palavras-chave",
                  pleaseSelectAKeyword: "Por favor, escolha palavras-chave",
                  keyPointsOfScript: "Pontos-chave do discurso",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Por favor, insira os principais pontos da conversa",
                  viewDetails: "Ver detalhes",
                  pleaseChooseToAdoptScript:
                    "Por favor, escolha adotar a técnica",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Lógica de correspondência",
            nLPMatching: "Correspondência de PNL",
            strictlyMatchByWord: "Correspondência estrita por palavra",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Nome do tipo de mensagem",
          messageTypeID: "ID do tipo de mensagem",
          messageTypeDescription: "Descrição do tipo de mensagem",
          applicationMessageType: "Tipo de mensagem do aplicativo",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Adequado para acesso a dados de diferentes canais para inspeção de qualidade, deve ser classificado de acordo com a identificação do canal",
        },
        detail: {
          index: {
            messageTypeName: "Nome do tipo de mensagem",
            pleaseEnterAMessageTypeName:
              "Por favor, insira o nome do tipo de mensagem",
            messageTypeDescription: "Descrição do tipo de mensagem",
            describeYourMessageTypeInDetail:
              "Descrever seu tipo de mensagem em detalhes",
            editMessageType: "Editar um tipo de mensagem",
            newMessageType: "Novo tipo de mensagem",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Escolha um membro da equipe",
        existingFilter: "Filtro já existente",
        pleaseSelect: "Por favor, escolha",
        filterCondition: "Condições de filtro",
        areYouSureToDeleteTheCurrentFilter:
          "A exclusão do filtro atual está confirmada?",
        sessionDate: "Data da sessão",
        staff: "Funcionários",
        customer: "Clientes",
        pleaseEnterCustomerName: "Por favor, insira o nome do cliente",
        keyWord: "Palavras-chave",
        whole: "Todos",
        pleaseEnterSessionKeywords: "Insira a palavra-chave da sessão",
        sessionDuration: "Dduração da sessão",
        sessionLabel: "Cena de sessão",
        pleaseSelectASessionLabel: "Por favor, selecione a cena da sessão",
        commentTopic: "Tópicos de comentários",
        pleaseSelectACommentTopic: "Por favor, escolha um tópico de comentário",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "A cobertura do filtro atual é confirmada?",
        overlayFilter: "Filtro de cobertura",
        NewFilter: "Novo filtro",
        newFilter: "Novo filtro",
        filterName: "Nome do filtro",
        pleaseEnter: "Por favor, insira",
        allCall: "Todas as chamadas",
        percentage: "Porcentagem",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'Suporte para pesquisar várias palavras-chave. O relacionamento "ou" é separado por vírgula (chinês e inglês), e o relacionamento "e" "é separado por um epide, como:',
          callRecordID: "ID do registro de chamadas",
          pleaseFillInTheCallRecordID:
            "Por favor, preencha o ID do registro de chamadas",
          minute: "Minutos",
          second: "Segundos",
          whole: "Todos",
          callRecording: "Gravação de chamadas",
          enterpriseWechat: "WeChat corporativo",
          conversationChannel: "Canais de conversação",
          pleaseSelectASessionChannel: "Por favor, escolha o canal de sessão",
          learningStatus: "Estado de aprendizagem",
          pleaseSelectLearningStatus:
            "Por favor, escolha o status de aprendizagem",
          notLearned: "Não aprendendo",
          learned: "Aprendendo",
          Minutes: "Minutos",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "O filtro está vazio e não há suporte para novos filtros!",
          filterAddedSuccessfully: "Novos filtros de sucesso!",
          failedToAddFilter: "Nova falha de filtro!",
          filterDeletedSuccessfully: "A remoção do filtro foi bem sucedida!",
          filterDeletionFailed: "A remoção do filtro falhou!",
          filterOverwriteSucceeded: "O filtro cobre o sucesso!",
          filterOverwriteFailed: "A cobertura do filtro falhou!",
          theme: "Tema",
          pleaseSelectATheme: "Por favor, escolha um tema",
          keyEvents: "Eventos-chave",
          pleaseSelectAKeyEvent: "Por favor, escolha o evento-chave",
        },
        filterItem: {
          startRetesting: "Iniciar a redetecção",
          time: "Tempo",
          talkTime: "Dduração da chamada",
          keyEvents: "Eventos-chave",
          totalImplementationRateOfSupervisionItems:
            "Taxa de implementação total do item de supervisão",
          sort: "Ordenação:",
          seeMore: "Veja mais",
        },
        filterStatistics: {
          conversation: "Chamada",
          weChat: "WeChat",
          quantity: "Quantidade",
        },
      },
      caseBase: {
        index: {
          copyTo: "Copiar para",
          pleaseSelectTheLocationToMove:
            "Por favor, escolha a localização do movimento!",
          folderCopiedSuccessfully: "Cópia de pasta com sucesso!",
          cancelCollectionSuccessfully: "Cancelar a coleção com sucesso!",
          collectionSucceeded: "Coleção de sucesso!",
          starCaseBase: "Biblioteca de casos de estrela",
          move: "Movimento",
          rename: "Renomear",
          delete: "Excluir",
          folderMovedSuccessfully: "O movimento da pasta foi bem sucedido!",
          fileMovedSuccessfully: "Mover arquivos com sucesso!",
          fileMoveFailed: "Falha no movimento de arquivos!",
          folderAddedSuccessfully: "Adicionar pastas com sucesso!",
          folderRenameSucceeded: "Renomear a pasta com sucesso!",
          fileRenameSucceeded: "Renomear o arquivo com sucesso!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Não será possível recuperá-lo após a exclusão da pasta, você confirma a exclusão?",
          deleteSucceeded: "A remoção foi bem sucedida!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Não será possível recuperá-lo após a exclusão do arquivo, você confirma a exclusão?",
          share: "Commons",
          folders: "Uma pasta,",
          calls: "Uma chamada",
          newFolder: "Nova pasta",
          modifyFolder: "Modificar uma pasta",
          title: "Título",
          pleaseEnter: "Por favor, insira",
          selectMoveLocation: "Escolha a posição móvel",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Lembrete de aprendizagem",
          subscriptionDynamics: "Dinâmica de assinatura",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID:
              "A sessão em que você se inscreveu (ID",
            thereAreDynamicUpdates: "Há uma atualização dinâmica",
            newCommentsReplies: "Novos comentários/respostas",
            strip: "Artigo",
            newHelp: "Nova ajuda",
            goAndWatch: "Vá assistir",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Respondeu ao meu comentário",
            replySucceeded: "A resposta foi bem sucedida!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Me nos comentários",
            replySucceeded: "A resposta foi bem sucedida!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Compartilhar sua chamada",
            shareCasesForYou: "Compartilhe o caso para você",
          },
        },
        study: {
          index: {
            learned: "Aprendendo",
            ignored: "Ignorado",
            study: "Aprendizagem",
            ignore: "Ignorar",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Importação bem-sucedida",
          account: "Conta",
          password: "Senha",
          cover: "Cobertura",
          skip: "Pular",
          import: "Importação",
          importEmployees: "Importar funcionários",
          importPrompt: "Dicas de importação",
          confirm: "OK",
          theDataContainsDuplicateAccountsOrNames:
            "Há uma duplicação de conta ou nome da entrada de dados",
          allCoverage: "Cobertura total",
          skipAll: "Ignorar tudo",
          headPortrait: "Avatar",
          fullName: "Nome",
          nickname: "Apelido",
          excellentStaff: "Excelente equipe",
          yes: "Sim",
          no: "Não.",
          department: "Departamento",
          accountNumberemail: "Conta (caixa de correio)",
          telephone: "Telefone",
          jobNumber: "Número de trabalho",
          role: "Personagens",
          pleaseEnterANameOrPhoneSearch:
            "Por favor, digite e-mail, nome ou pesquisa por telefone",
          person: "Pessoas)",
          AddEmployee: "Adicionar funcionários",
        },
        edit: {
          jobNo: "Número de trabalho",
          udeskCustomerServiceSystemIdentity:
            "Identidade do sistema de atendimento ao cliente Udesk",
          enterpriseWechatIdentity: "Status de WeChat corporativo",
          inputID: "Identificação de entrada",
          newIdentityBinding: "Nova ligação de identidade",
          identityBinding: "Ligação de identidade",
          avatarGreaterThan500kb: "Avatar maior que 500Kb",
          uploadSuccessful: "Carregar com sucesso",
          upload: "Upload",
          supportJPGGIFAndPNGUpTo500kb: "Suporte jpg, gif, png, até 500Kb",
          yes: "Sim",
          no: "Não.",
          accountNumber: "Conta",
          pleaseEnterTheAccountNumber: "Por favor, insira a conta",
          initialPassword: "Senha inicial",
          pleaseInputAPassword: "Por favor, digite a senha",
          confirmPassword: "Confirmar a senha",
          thePasswordsEnteredTwiceAreDifferent:
            "A senha digitada duas vezes não é a mesma!",
          headPortrait: "Avatar",
          fullName: "Nome",
          pleaseEnterYourName: "Por favor, insira um nome",
          nickname: "Apelido",
          department: "Departamento",
          excellentStaff: "Excelente equipe",
          sessionIdentity: "Identidade de sessão",
          markTheSessionRecordAsBelongingToTheEmployee:
            "O registro da sessão de marcação é atribuído a esse funcionário",
          telephone: "Telefone",
          jobNumber: "Número de trabalho",
          role: "Personagens",
          pleaseSelectARole: "Por favor, escolha o personagem",
          preservation: "Salvar",
          cancel: "Cancelamento",
          addedSuccessfully: "Adicione o sucesso!",
          modificationSucceeded: "A modificação foi bem sucedida!",
          newEmployees: "Novos funcionários",
          editEmployee: "Editar funcionários",
        },
        detail: {
          viewDetails: "Ver detalhes",
          accountNumber: "Conta",
          headPortrait: "Avatar",
          fullName: "Nome",
          nickname: "Apelido",
          department: "Departamento",
          excellentStaff: "Excelente equipe",
          yes: "Sim",
          no: "Não.",
          sessionIdentity: "Identidade de sessão",
          markTheSessionRecordAsBelongingToTheEmployee:
            "O registro da sessão de marcação é atribuído a esse funcionário",
          telephone: "Telefone",
          jobNumber: "Número de trabalho",
          role: "Personagens",
        },
      },
      roleManage: {
        list: {
          menu: "Menu",
          functionalCategory: "Categoria de funções",
          functionalSubclass: "Pequena classe de função",
          deletedSuccessfully: "Exclusão bem sucedida",
          NewRole: "Novo papel",
          role: "Personagens",
          dataPermissions: "Permissões de dados:",
          edit: "Editar",
          areYouSureYouWantToDeleteTheRole: "Identificar a função de exclusão",
          determine: "OK",
          cancel: "Cancelamento",
          delete: "Excluir",
          applicationPermission: "Permissões de aplicação",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Você só pode ver seus próprios dados de sessão",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Pode ver os dados da sessão deste departamento e dos departamentos subordinados",
          menu: "Menu",
          functionalCategory: "Categoria de funções",
          functionalSubclass: "Pequena classe de função",
          successfullyAdded: "Novo sucesso",
          modifiedSuccessfully: "Modificações bem-sucedidas",
          edit: "Editar",
          cancel: "Cancelamento",
          confirm: "Confirmação",
          roleName: "Nome do personagem",
          pleaseEnterTheRoleName: "Por favor, insira o nome da função",
          dataAuthority: "Permissões de dados",
          youCanViewCompanyWideSessionData:
            "Pode visualizar os dados da sessão em toda a empresa",
          applicationPermission: "Permissões de aplicação",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Processo de sessão",
          generateProcess: "Processo de geração",
          addProcess: "Adicionar um processo",
          cancel: "Cancelamento",
          complete: "Concluído",
          edit: "Editar",
          theSettingWillBeSuccessfulLater:
            "Configurações para ver o sucesso mais tarde!",
          previous: "O último",
          next: "O próximo",
          debuggingMode: "Modo de depuração",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Após a abertura, se a mensagem corresponder a eventos importantes, itens de supervisão, regras de rotulagem do cliente ou regras de ponto de conhecimento, o nome da regra ao qual a mensagem corresponde e o nome do ponto de conhecimento da base de conhecimento será exibido.",
          seeLater: "Veja depois",
          determine: "OK",
          conversationalInsights: "Insights de conversação",
          customerInsight: "Insight do cliente",
          knowledgePointRecommendation:
            "Recomendação de pontos de conhecimento",
          comment: "Comentários",
          sessionQuality: "Qualidade da sessão",
          setToListenLaterSuccessfully:
            "Configurações para ouvir o sucesso mais tarde!",
          listenLater: "Ouça mais tarde",
          seekCounselingSuccess: "Procure aconselhamento para ter sucesso!",
          altogether: "Um total",
          appearsAt: "Aparece em algum lugar'",
          commentsPublishedSuccessfully: "Comentários publicados com sucesso",
          callRecording: "Gravação de chamadas",
          addedSuccessfully: "Adição de sucesso",
          shareWithColleagues: "Compartilhar com colegas",
          shareWithCustomers: "Compartilhar com os clientes",
          resumptionDetails: "Detalhes do disco",
          addToCaseBase: "Adicionar à biblioteca de casos",
          shareCases: "Casos de partilha",
          seekCounseling: "Procure aconselhamento",
          sessionRecord: "Registro de sessão",
          businessRecord: "Registros de negócios",
          keywordSearch: "Pesquisa de palavras-chave",
          whole: "Todos",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Por favor, insira um comentário, digite @ para notificar outras pessoas, digite # para adicionar um tópico",
          subscriptionSession: "Sessão de assinatura",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Após a assinatura, você receberá um lembrete de notificação quando houver novos pedidos de ajuda, comentários e respostas",
        },
      },
      coachTask: {
        index: {
          employeeName: "Nome do funcionário",
          customerName: "Nome do cliente",
          source: "Fonte",
          viewSessionRecords: "Visualizar o registro da sessão",
          coachingStatus: "Status de tutoria",
          helpTime: "Tempo de ajuda",
          counselingTime: "Tempo de tutoria",
          helpContent: "Conteúdo de ajuda",
          coachingOperation: "Operações de tutoria",
          areYouSureYouWantToIgnoreIt: "É certo ignorar?",
          determine: "OK",
          cancel: "Cancelamento",
          ignoreHelp: "Ignorar ajuda",
          coaching: "Realizar aconselhamento",
          viewCoaching: "Veja a tutoria",
          areYouSureToReturnToCounseling:
            "Tem certeza de que vai voltar ao aconselhamento?",
          returnToPendingCoaching: "Restaure para aconselhamento",
          tutor: "Coachador",
          helpSeekingStatus: "Estado de ajuda",
          recentCoachingTime: "Tempo de tutoria recente",
          operation: "Operação",
          ignoreSuccess: "Ignore o sucesso!",
          recoverySucceeded: "A recuperação foi bem sucedida!",
          successfulCoaching: "Coaching para o sucesso!",
          myMission: "Minha missão",
          selectCoachingStatus: "Escolha o status do coaching",
          whole: "Todos",
          iAskedForHelp: "Eu pedi ajuda",
          signOut: "Saída",
          submit: "Enmissão",
          helpPerson: "Peça ajuda",
          customer: "Clientes",
          toBeCoached: "Aconselhamento",
          counselingSuggestions: "Recomendações de aconselhamento",
          pleaseEnterCoachingSuggestions:
            "Por favor, insira conselhos de aconselhamento",
        },
      },
      coachTaskCenter: {
        title: "Centro de tarefas",
        list: {
          taskName: "Nome da tarefa",
          lessonGroup: "Classificação do curso",
          lessonName: "Cursos afiliados",
          taskType: "Tipo de tarefa",
          taskStatus: "Status da tarefa",
          taskExecutionInfo: "Situação de execução",
          totalCount: "Número de pessoas que devem ser executadas",
          executeCount: "Número de pessoas executadas",
          executeRate: "Taxa de execução",
          completeCount: "Número de pessoas concluídas",
          completeRate: "Taxa de conclusão",
          commitCount: "Número de pessoas que atendem ao padrão",
          commitRate: "Taxa de conformidade",
          excellentRate: "Taxa de excelência",
          taskTime: "Tempo de missão",
          option: "Operação",
          edit: "Editar",
          taskDetail: "Dados da tarefa",
          delete: "Excluir",
          new: "Novo lançamento",
          taskTypeAll: "Todos",
          taskTypePractice: "Exercícios",
          taskTypeTest: "Exame",
          taskStatusNotStarted: "Não começou",
          taskStatusRunning: "Em andamento",
          taskStatusEnd: "Acabou",
          taskStatusInvalid: "Não está em vigor",
          unlimitedTime: "Tempo ilimitado",
          to: "Para",
          deleteConfirm:
            "Após a exclusão, todos os registros de aprendizagem sob esta tarefa serão esvaziados, a exclusão é confirmada?",
          deleteSuccess: "A remoção da tarefa foi bem-sucedida",
        },
        edit: {
          addTask: "Novas tarefas de publicação",
          editTask: "Editar tarefas de publicação",
          publishSuccess: "Publicação bem sucedida",
          publishFailed: "Falha na publicação",
          updateSuccess: "A atualização foi bem-sucedida",
          updateFailed: "Falha na atualização",
          cannotBeNull: "Não pode ser vazio",
          explain: "Descrição",
          taskDescExtra: "(O conteúdo será mostrado aqui na entrada {0})",
          taskTargetObj: "Publicar objetos",
          isEnable: "Estado efetivo",
          dialogueMode: "Modo de diálogo",
          voice: "Voz",
          text: "Texto",
          userPortrait: "Retrato do usuário",
          difficultyLevel: "Dificuldade",
          limitDuration: "Limite de duração",
          tipLabel: "Dicas de fala",
          tipTooltip: "Se os alunos podem ver as habilidades de referência",
          limitTimes: "Número de exames",
          time: "Tempo",
          gradingStandart: "Critérios de classificação",
          gradingStandartDescAffix:
            "(A pontuação total do processo de diálogo atual é",
          gradingStandartDescSuffix:
            "Pontos, palavras {0} pontos, emoções {1} pontos, velocidade de fala {2} pontos)",
          completeStandart: "Requisitos de conclusão de missão",
          byTimes: "Número de vezes",
          byScore: "Pressione o placar",
          completeByTimes: "Número de exercícios",
          times: "Vezes",
          score: "Pontos",
          anyTimes: "Qualquer uma vez",
          getScore: "Pontuação",
          offLimitDuration: "Sem limite de tempo",
          onLimitDuration: "Limite de tempo",
          commitScore: "Padrões de conformidade, pontuação",
          excellentScore: "Padrões excelentes, pontuação",
          offLimitTime: "Tempo ilimitado",
          onLimitTime: "Tempo limitado",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Oi," },
          callDynamic: {
            today: "Hoje",
            yesterday: "Ontem",
            sessions: "Uma sessão",
          },
          todoList: {
            strip: "Artigo",
            theSessionIsAddedTolistenLater:
              'A sessão foi adicionada "Ouça mais tarde"',
            askYouForHelp: "Peça ajuda a você",
            iHopeToContactYouLater: "Espere entrar em contato mais tarde",
            lookingForwardToSendingTheInformationLater:
              "Espere enviar os dados mais tarde",
            dialogueRisk: "Existem pontos de risco na sessão",
            tutored: "Aconselhado",
            goToCounseling: "Vá para o aconselhamento",
            contacted: "Contactado",
            hasBeenSent: "Enviado",
            areYouSureYouWantToIgnoreIt: "É certo ignorar?",
            determine: "OK",
            cancel: "Cancelamento",
            ignore: "Ignorar",
            checked: "Visualizado",
          },
          callListItem: {
            strip: "Artigo",
            learned: "Aprendendo",
            keyEvents: "Eventos-chave",
            totalImplementationRateOfSupervisionItems:
              "Taxa de implementação total do item de supervisão",
          },
        },
        index: {
          operationSucceeded: "Operação bem sucedida!",
          sessionDynamics: "Dinâmica da sessão",
          my: "O meu",
          department: "Departamento",
          company: "Empresa",
          toDoList: "O que fazer",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Volume efetivo de chamadas (um)",
          bestPractices: "Melhores práticas",
          averageDurationOfASingleCallminutes:
            "Dduração média de uma única chamada (minutos)",
          totalCallVolumePCs: "Total de chamadas (um)",
          totalTalkTimehours: "Dduração total da chamada (horas)",
          speakingPercentagepercentage: "Falar e proporção (%)",
          maximumContinuousSpeakingTimeminutes:
            "Duração de fala contínua mais longa (minutos)",
          numberOfIndepthDiscussionsPCs:
            "Número de discussões em profundidade (um)",
          maximumCustomerTalkminutes: "Maior discurso do cliente (minutos)",
          questionFrequencyPCs: "Perguntas frequentes (um)",
          averageValue: "Média",
          selectDate: "Escolha uma data",
          personnel: "Pessoal",
          pleaseSelect: "Por favor, escolha",
          callRange: "Intervalo de chamadas",
          conversationActivity: "Atividade da sessão",
          conversationAction: "Ação de sessão",
          keyEvents: "Eventos-chave",
          callRecord: "Registro de chamadas",
          filter: "Triagem:",
          conversation: "Chamada",
          minute: "Minutos",
          sale: "Funcionários",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Janeiro",
            numberOfCalls: "Número de chamadas",
            accountInformation: "Informações da conta",
            month: "Mês",
            today: "Hoje",
            customerDetails: "Detalhes do cliente",
            searchKeywordsInCommunicationContent:
              "Recuperar palavras-chave no conteúdo da comunicação",
            allMembers: "Todos os membros",
            reset: "Redefinir",
            query: "Inquérito",
            allTrip: "Exibir em toda a jornada",
            contactOverview: "Visão geral de contato",
            lastContact: "Último contato",
            nextContact: "Contato da próxima vez",
            previous: "O último",
            next: "O próximo",
            viewDetails: "Ver detalhes",
            contactDynamics: "Dinâmica de contato",
            lastYear: "Ano recente",
            lastCall: "Chamada mais recente",
            hotTopicsForDiscussion: "Tópicos de discussão",
            totalInTheLastYear: "No último ano",
            callTimes: "Segunda chamada",
          },
        },
        list: {
          index: {
            risk: "Risco",
            have: "Há",
            nothing: "Nenhum",
            channel: "Canais",
            callRecording: "Gravação de chamadas",
            enterpriseWeChatConversation: "Micro-sessão da empresa",
            enterpriseWeChatCall: "WeChat da empresa",
            followUpSuggestions: "Sugestões de acompanhamento",
            customer: "Centro de clientes",
            followUp: "Situação de acompanhamento",
            personInCharge: "Responsável",
            recentFollowup: "Acompanhamento recente de pessoas",
            lastContactTime: "Tempo de contato mais recente",
            operation: "Operação",
            see: "Veja",
            exceed: "Mais do que",
            noActivityForMoreThanDays: "Dia sem atividade",
            lately: "Recentemente",
            risksOccurredInTheLastDays: "Semântica de risco em dias",
            savedSuccessfully: "Salve o sucesso",
            today: "Hoje",
            allDepartments: "Todos os departamentos",
            allMembers: "Todos os membros",
            customerName: "Nome do cliente",
            reset: "Redefinir",
            query: "Inquérito",
            customers: "Um cliente",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Mostre o contato entre funcionários e clientes. Entre eles, a bolha rosa é a mensagem do cliente e o azul é a mensagem do funcionário. Quanto maior a proporção de discursos, maior a bolha",
            riskAllocation: "Configuração de risco",
            preservation: "Salvar",
            pleaseEnter: "Por favor, insira",
            noActivityForDays: "Dia sem atividade",
            riskSemanticsWithinDays: "Semântica de risco em dias",
            addRiskSemantics: "Adicionar semântica de risco",
            semanticTags: "Rótulos semânticos:",
            standardSentence: "Sentença padrão:",
          },
          components: {
            advancedFilter: {
              components: {
                list: {
                  index: { addNewConditions: "Adicionar novas condições" },
                },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Atenda a todas as seguintes condições",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Filtragem",
                pleaseSelectFilteringCriteria:
                  "Por favor, escolha uma condição de filtro",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Fase de mudança de oportunidade",
              theCurrentStageIs: "O estágio atual é [",
              ConfirmToChangeItTo: "】, Com certeza alterá-lo para [",
              IsIt: "】 É?",
              StageCoStay: "】 Estágio total",
              day: "Dia",
              start: "Início",
              end: "Fim",
              orderInput: "Perder a ordem",
              restart: "Reiniciar",
              pleaseConfigureTheSalesStageInformation:
                "Por favor, configure as informações da fase de vendas",
              reasonForDocumentInput: "Motivo da perda:",
              completeOrderInput: "Complete a ordem de perda",
              pleaseSelectTheReason: "Por favor, escolha o motivo",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "Sugestões de gerentes:",
              },
              index: {
                sessionDetails: "Detalhes da sessão",
                customerDynamics: "Dinâmica do cliente",
                riskPoint: "Ponto de risco",
                followUpSuggestions: "Sugestões de acompanhamento",
              },
              sessionDetails: {
                common: "O total",
                currentlyViewingThe: "Visualização atual No.",
                sessions: "Uma sessão",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "A fase atual está parada",
              day: "Dia",
            },
          },
          business: {
            index: {
              reEnableOpportunity: "Reativar oportunidades de negócios",
            },
          },
          customerTag: { index: { label: "Rótulos" } },
          moreCustomer: {
            index: {
              essentialInformation: "Informações básicas",
              cancel: "Cancelamento",
              confirm: "Confirmação",
              customer: "Clientes",
              currentFollowupStage: "Fase de acompanhamento atual",
              telephone: "Telefone",
              followUpDays: "Dias de acompanhamento",
              day: "Dia",
              remarks: "Observações",
              channelPreference: "Preferências de canal",
              hello: "Olá.",
              customFields: "Campos personalizados",
              opportunityAmount: "Quantidade de oportunidades de negócios",
              focus: "Foco",
              customerInformation: "Informações do cliente",
              viewMoreCustomerInformation: "Veja mais informações do cliente",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Eventos-chave:" },
                row1: {
                  customerDynamics: "Dinâmica do cliente",
                  common: "O total",
                  barDynamic: "Dinâmica da barra",
                },
                row2: {
                  riskPoint: "Ponto de risco",
                  common: "O total",
                  risks: "Risco",
                },
                row3: {
                  followUpSuggestions: "Sugestões de acompanhamento",
                  intelligentRecommendation: "Recomendação inteligente",
                  strip: "Artigo",
                  managersSuggestATotalOf: "Os gerentes sugerem um total",
                  recommendedByManagers: "Recomendação do gerente",
                  creationTime: "Tempo de criação",
                  administratorSuggestions: "Recomendações do administrador",
                  suggestionsForNewManagers: "Sugestões de novos gerentes",
                },
              },
            },
            index: { typeTime: "Tipo/Tempo" },
          },
          userInfo: {
            index: {
              personInCharge: "Responsável",
              recentFollower: "Acompanhamento recente de pessoas",
              startTime: "Tempo de primeiro toque",
              followUpDays: "Dias de acompanhamento",
              day: "Dia",
              remarks: "Observações",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Membros da equipe",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Membros aprendem o número total de sessões de outras pessoas (para ser pesado), verificar os detalhes da sessão e clicar na gravação para reproduzir é considerado como aprender uma vez",
          totalNumberOfStudies: "Total de estudos",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Número total de comentários e respostas de membros em sessões de outras pessoas (para mais pesado)",
          totalNumberOfInteractions: "Total de interações",
          learnTheSumOfConversationCallDuration:
            "Sons de tempo de chamada de conversação de aprendizagem",
          lengthOfStudy: "Duração do estudo",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Número total de pedidos de ajuda iniciados por um membro em uma sessão de outra pessoa (para peso)",
          totalNumberOfRequests: "Número total de ajuda",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "O número total de membros que aconselham a ajuda de outras pessoas (para peso)",
          totalCounseling: "Número total de tutoria",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Número total de sessões de membros para outras pessoas adicionadas ao banco de casos (para ser pesado)",
          addCase: "Adicionar um caso",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Número total de assinaturas de membros em sessões de outras pessoas (para pesado)",
          subscribe: "Assinaturas",
          totalNumberOfSessionsCommunicatedByMembers:
            "Número total de sessões comunicadas pelos membros",
          totalSessions: "Número total de sessões",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Membros revisam o número total de suas próprias sessões (para mais pesado), verificam os detalhes da sessão e clicam na gravação para reproduzir o disco uma vez",
          totalNumberOfDoubleDisk: "Número total de relistações",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Número total de repetição/número total de sessões * 100%",
          proportionOfDoubleDisk: "A proporção de discos compostos",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Sagem de tempo de chamada de conversação de repetição",
          resumptionDuration: "Duração da retomada",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Número total de pedidos de ajuda iniciados por membros em suas sessões",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Número total de comentários de outras pessoas (incluindo respostas) recebidos por um membro da sessão",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "O número total de sessões de membros adicionados por outros ao banco de casos (para ser pesado)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Número total de assinaturas (para pesado) de uma sessão de um membro por outra pessoa",
          askForHelpInTheLastMonth: "Ajuda no último mês",
          lastTimeForHelp: "Tempo de ajuda mais recente",
          operation: "Operação",
          viewAllSessionsInTheLastMonth: "Ver todas as sessões do último mês",
          allDepartments: "Todos os departamentos",
          reset: "Redefinir",
          query: "Inquérito",
          altogether: "Um total",
          secondSession: "Segunda sessão",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Projetos de teste atuais:",
            supervisionItem: "Item de supervisão",
            keyEvents: "Eventos-chave",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Marque os itens de supervisão ou os principais eventos que você deseja testar e certifique-se de que há uma chamada na conversa simulada",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Análise de Execução" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Processo 1",
              technologicalProcess: "Processo",
              newProcess: "Novo processo",
            },
          },
          index: {
            upload: "Upload",
            preservation: "Salvar",
            newlyBuild: "Novo",
            cancel: "Cancelamento",
            name: "Nome",
            describe: "Descrição",
            type: "Tipo",
            classification: "Classificação",
            content: "Conteúdo",
            enclosure: "Acessórios",
            knowledgePointAssociation: "Associações de pontos de conhecimento",
            open: "Ligado",
            close: "Fechar",
            operator: "Operador",
            logic: "Lógica",
            see: "Veja",
            edit: "Editar",
          },
        },
        index: {
          name: "Nome",
          describe: "Descrição",
          createdBy: "Criador",
          type: "Tipo",
          lastUpdated: "Tempo de atualização recente",
          numberOfReferences: "Número de citações",
          operation: "Operação",
          see: "Veja",
          edit: "Editar",
          delete: "Excluir",
          categoryDeletedSuccessfully: "Exclusão de classificação bem-sucedida",
          classificationOfKnowledgePoints:
            "Classificação de pontos de conhecimento",
          newlyBuild: "Novo",
          knowledgeBase: "Base de conhecimento",
          knowledgePoints: "Pontos de conhecimento",
          technologicalProcess: "Processo",
          keyword: "Palavras-chave",
          batchExport: "Exportação em massa",
          batchDelete: "Excluir em massa",
          importProcess: "Processo de importação",
          importKnowledgePoints: "Importar pontos de conhecimento",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Se os pontos de conhecimento não existirem na base de conhecimento, os pontos de conhecimento serão adicionados diretamente. Se existirem, eles serão ignorados e não serão atualizados",
          editKnowledgePointClassification:
            "Editar a classificação dos pontos de conhecimento",
          newKnowledgePointClassification:
            "Nova classificação de pontos de conhecimento",
          categoryEditingSucceeded: "Edição de classificação bem sucedida",
          newClassificationSucceeded: "Nova classificação bem-sucedida",
          classificationName: "Nome da classificação",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Supervisão de execução" },
      },
      topicCallout: {
        index: {
          whole: "Todos",
          date: "Data",
          dimensionStatus: "Status de anotação",
          callRecordID: "ID do registro de chamadas",
          callRecordIDMustBeNumeric:
            "O ID do registro de chamadas deve ser digital",
          strip: "Artigo",
          topicAnnotation: "Anotação de tópico",
          annotated: "Marcado",
          stripNotMarked: "Artigo, não marcado",
          barInDimension: "Artigo, marcado em",
          customer: "Clientes",
          sale: "Vendas",
          common: "O total",
          sessions: "Sessão",
          currentTopic: "Tópicos atuais:",
          staff: "Funcionários",
          topicOfConversation: "Tópicos",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Operação bem sucedida, rotulagem redefinida",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Todos os registros de anotação desta sessão serão esvaziados após a redefinição, por favor confirme",
          resetAll: "Redefinir tudo",
          pleaseSelectTheTopicToBeMarked:
            "Por favor, escolha o tópico a ser marcado",
          pleaseTickTheSentenceToBeMarked:
            "Por favor, marque a frase que você quer marcar",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Operação bem-sucedida, a marcação está em vigor",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "O escopo desta anotação contém mensagens de outros tópicos, clique em OK para cobrir",
          confirmDimensions: "Confirmar a anotação",
          talkTime: "Tempo de conversação",
          callID: "ID de chamada",
        },
      },
      topicList: {
        index: {
          callID: "ID da chamada",
          latestMarkedTime: "Tempo de marcação mais recente",
          topicOfConversation: "Tópicos",
          numberOfSentences: "Número de sentenças",
          sentence: "Sentença",
          operation: "Operação",
          see: "Veja",
          delete: "Excluir",
          edit: "Editar",
          newSubTopic: "Novos subtópicos",
          move: "Movimento",
          whole: "Todos",
          topicDeletedSuccessfully: "Remover o tópico com sucesso",
          newlyBuild: "Novo",
          topicList: "Lista de tópicos",
          callIDMustBeNumeric: "O ID da chamada deve ser digital",
          leadInTopic: "Importar um tópico",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Quando o nome da classificação é repetido, a classificação do tópico original não será coberta, o item será pulado diretamente e não será importado",
          mobileClassification: "Classificação móvel",
          editTopic: "Editar um tópico",
          newTopic: "Novo tópico",
          topicName: "Nome do tópico",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Modificações bem-sucedidas",
            createdSuccessfully: "Criação de sucesso",
            modificationFailed: "Falha na modificação",
            creationFailed: "Falha na criação",
            monitoringName: "Nome de monitoramento",
            upToCharacters: "Até 40 caracteres",
            pleaseEnterAName: "Por favor, insira um nome",
            monitoringIndicators: "Indicadores de monitoramento",
            sessionActivity: "Atividade da sessão",
            conversationAction: "Ação de sessão",
            keyEvents: "Eventos-chave",
            supervisionItems: "Item de supervisão",
            monitoringMembers: "Membros de monitoramento",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Grupos que recebem orientação, treinamento ou intervenção gerencial",
            groupA: "Grupo A",
            pleaseSelectGroupAData: "Selecione os dados do grupo A",
            thereAreDuplicateUsersInGroupB:
              "Com usuários duplicados no Grupo B",
            groupARemarks: "Observações do grupo A",
            controlGroupGroupComparedWithGroupA:
              "Grupo de controle, grupo em comparação com o grupo A",
            groupB: "Grupo B",
            pleaseSelectGroupBData: "Selecione os dados do Grupo B",
            thereAreDuplicateUsersInGroupA: "Usuários duplicados com o grupo A",
            groupBRemarks: "Observações do Grupo B",
            monitoringPeriod: "Ciclo de monitoramento",
            startNode: "Início do nó",
            monitoringScope: "Faixa de monitoramento",
            pleaseSelectMonitoringConditions:
              "Por favor, escolha as condições de monitoramento",
            pleaseEnterANumber: "Por favor, insira números",
            pleaseSelectATimePeriod: "Por favor, escolha o período de tempo",
            preservation: "Salvar",
            cancel: "Cancelamento",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Exclusão bem sucedida",
            notStarted: "Não começou",
            haveInHand: "Em andamento",
            closed: "Acabou",
            newGroupTest: "Novo teste de grupo",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Fase de vendas",
        communicationStrategy1: "Estratégias de comunicação",
        riskAllocation: "Configuração de risco",
        businessConfiguration: "Configuração de negócios",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Tratamento de objeções",
                  productSellingPointScript: "Ponto de venda do produto",
                  caseGrouping: "Agrupamento de casos",
                  pleaseSelectExcellentCases:
                    "Por favor, escolha um excelente caso",
                  pleaseSelectKnowledgePoints:
                    "Por favor, escolha o ponto de conhecimento",
                  associativeLinks: "Link de associação",
                  jumpTo: "Ir para",
                  jumpType: "Tipo de salto",
                  knowledgePoints: "Pontos de conhecimento",
                  excellentCases: "Excelente caso",
                  excellentScript: "Excelente fala",
                  pleaseSelectASingleKnowledgePoint:
                    "Escolha um único ponto de conhecimento",
                },
              },
            },
            index: {
              name: "Nome",
              pushText: "Texto push",
              enableStatus: "Ativar o estado",
              triggerRules: "Regras de gatilho",
              communicationStrategy: "Estratégias de comunicação",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping:
                "Agrupamento de estratégias de comunicação",
              name: "Nome",
              pushText: "Texto push",
              effectiveness: "Eficácia",
              openSuccessfully: "Abra o sucesso",
              closeSuccessfully: "Fechar com sucesso",
              operationFailed: "Falha na operação",
              operation: "Operação",
              edit: "Editar",
              delete: "Excluir",
              newlyBuild: "Novo",
              communicationStrategy: "Estratégias de comunicação",
              editGroup: "Editar um agrupamento",
              groupName: "Nome do agrupamento",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Todas as sessões",
                  recentDays: "Dias recentes",
                  recentSessions: "Sessão recente",
                  scopeOfApplication: "Âmbito de aplicação:",
                  day: "Dia",
                  through: "Tong",
                },
              },
            },
            index: {
              describe: "Descrição",
              applicableCustomers: "Aplicável aos clientes",
              enableStatus: "Ativar o estado",
              triggerRules: "Regras de gatilho",
              riskRules: "Regras de risco",
            },
          },
          list: {
            index: {
              riskAllocation: "Configuração de risco",
              keyWord: "Palavras-chave",
              newRiskRule: "Novas regras de risco",
              riskRuleName: "Nome da regra de risco",
              describe: "Descrição",
              state: "Estado",
              openSuccessfully: "Abra o sucesso",
              closeSuccessfully: "Fechar com sucesso",
              operationFailed: "Falha na operação",
              operation: "Operação",
              edit: "Editar",
              delete: "Excluir",
              deletionSucceeded: "Exclusão bem sucedida",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Perder a ordem" },
              endReasonList: {
                addEndReason: "Motivo final da adição",
                enterEndReason: "O motivo do fim da entrada",
              },
              stageList: {
                keyEvents: "Eventos-chave",
                waysideData: "Dados de estrada",
                includeAny: "Contém qualquer",
                includeAll: "Contém todos",
                pleaseEnterTheStageName: "Por favor, insira o nome da fase",
                setStageRules: "Definir regras de fase",
                stageName: "Nome da fase",
                operator: "Operador",
                logic: "Lógica",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Formato de entrada de campo de tipo de hora: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "O pedido falhou, tente novamente mais tarde",
              salesStage: "Fase de vendas",
              essentialInformation: "Informações básicas",
              name: "Nome",
              applicableDepartments: "Departamentos aplicáveis",
              applicableToOthers: "Aplicável a outros",
              manualChangePhase: "Fase de mudança manual",
              enableStatus: "Ativar o estado",
              describe: "Descrição",
              opportunityStageSettings:
                "Configuração do estágio de oportunidade",
              stageSettings: "Configuração de fase",
              aliasSettings: "Configurações de alias",
              closeOpportunity: "Fim das oportunidades de negócios",
              newSuccessfully: "Novo sucesso",
              newFailed: "Nova falha",
              savingSucceeded: "Salve o sucesso",
              saveFailed: "Salvar falha",
            },
          },
          list: {
            index: {
              salesStage: "Fase de vendas",
              keyWord: "Palavras-chave",
              newSalesStage: "Nova fase de vendas",
              salesStageName: "Nome da fase de vendas",
              describe: "Descrição",
              state: "Estado",
              openSuccessfully: "Abra o sucesso",
              closeSuccessfully: "Fechar com sucesso",
              operationFailed: "Falha na operação",
              operation: "Operação",
              edit: "Editar",
              delete: "Excluir",
              deletionSucceeded: "Exclusão bem sucedida",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Geração de modelos grandes",
            conversationInsights: "Insights de conversação",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Geração automática" },
              },
              hooks: {
                conversationSummary: "Resumo da sessão",
                conversationProcess: "Processo de sessão",
                opportunityPoints: "Ponto de oportunidade",
              },
              prompt: {
                index: {
                  edit: "Editar",
                  restoreDefault: "Restaurar o padrão",
                  cancel: "Cancelamento",
                  confirm: "Confirmação",
                },
              },
            },
            index: {
              saveSuccessful: "Salve o sucesso",
              hintLanguage: "Prompt",
              preserve: "Salvar",
              cancel: "Cancelamento",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Geração de modelos grandes",
        salesStage: "Fase de vendas",
        communicationStrategy: "Estratégias de comunicação",
        riskAllocation: "Configuração de risco",
        businessConfiguration: "Configuração de negócios",
      },
      salesLearningCenter: {
        knowledgeBase: "Base de conhecimento",
        speechCorpus: "Biblioteca de habilidades",
        learningCenter: "Centro de aprendizagem",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Informações do cliente" },
          },
        },
        index: { otherInformation: "Outras informações" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Importação" } } },
        hooks: {
          index: {
            all: "Todos",
            intelligentModel: "Modelos inteligentes",
            smartRules: "Regras inteligentes",
            manualScoring: "Classificação manual",
            enable: "Habilitar",
            notEnabled: "Não ativado",
            voiceCall: "Chamada de voz",
            textDialogue: "Diálogo de texto",
            ecommerceTextSession: "Sessão de texto do comércio eletrônico",
            workOrder: "Lista de trabalho",
          },
        },
        index: {
          ruleName: "Nome da regra",
          ruleType: "Tipo de regra",
          pleaseSelect: "Por favor, escolha",
          ruleStatus: "Estado da regra",
          dataSourceType: "Tipo de fonte de dados",
          voiceCall: "Chamada de voz",
          textDialogue: "Diálogo de texto",
          ecommerceTextSession: "Sessão de texto do comércio eletrônico",
          workOrder: "Lista de trabalho",
          status: "Estado",
          operation: "Operação",
          edit: "Editar",
          ruleTemplate: "Modelo de regra",
        },
      },
      analysisReportTitle: "Relatórios de análise",
      ruleTest: "Teste de regras",
      intelligentTags: {
        labelDisplayQuantity: "Número de exibição de etiquetas",
        unlimited: "Não limitado",
        ignoringTagLibraries: "Ignorar a biblioteca de rótulos",
        automaticallyGenerateLabels: "Geração automática de etiquetas",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'Após a abertura, as etiquetas são geradas automaticamente para a sessão com base no modelo grande, e as tags geradas são classificadas por padrão em "Não classificado"',
        defaultValidity: "Validade padrão",
        supervisionItem: "Item de supervisão",
        customerLabel: "Rótulos de clientes",
        newSupervisionItemRule: "Nova regra de item de supervisão",
        editSupervisionItemRule: "Editar uma regra de item de supervisão",
        newCustomerLabel: "Criar novos rótulos de clientes",
        editCustomerLabel: "Editar a etiqueta do cliente",
        newSessionLabel: "Novo rótulo de sessão",
        editSessionLabel: "Editar rótulos de sessão",
        newKeyEvent: "Novos eventos-chave",
        editKeyEvents: "Editar eventos-chave",
        detail: {
          template: { nameIsARequiredField: "O nome é um campo obrigatório" },
          formItemComponent: {
            largeModelExtensionLabel: "Rótulos de expansão de modelo grande",
            sessionRecordID: "ID de registro de sessão",
            sessionTime: "Tempo de sessão",
            smartTags: "Rótulos inteligentes",
            viewDetails: "Ver detalhes",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Os dados registrados nesta sessão serão esvaziados após a exclusão. A exclusão é confirmada?",
            tagData: "Marcação de dados",
            batchDeletion: "Excluir em massa",
          },
        },
        template: {
          advancedFiltering: "Filtragem avançada",
          dataType: "Tipo de dados",
          filterCriteria: "Condições de triagem",
          edit: "Editar",
          createANewSubcategory: "Nova subclassificação",
          delete: "Excluir",
          nameLengthCannotExceed: "O comprimento do nome não pode exceder 40",
        },
        component: {
          modelExpansion: "Expansão do modelo",
          enable: "Habilitar",
          notEnabled: "Não ativado",
          standardScreening: "Triagem padrão",
          advancedFiltering: "Filtragem avançada",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Palavras-chave",
              sessionLabel: "Rótulos de sessão",
              keyEvents: "Eventos-chave",
              matchAny: "Combinar qualquer",
              matchAll: "Combine tudo",
              beEqualTo: "É igual a",
              includeAll: "Contém todos",
              includeAny: "Contém qualquer",
              advancedConditions: "Condições avançadas",
              smartTag: "Rótulos inteligentes",
              smartTagI: "Etiqueta inteligente um",
              smartTagII: "Etiqueta inteligente II",
            },
            template: {
              pleaseSelectSmartTag: "Por favor, escolha o rótulo inteligente",
              pleaseSelectASessionLabel:
                "Por favor, selecione o rótulo de sessão",
              pleaseSelectAKeyEvent: "Por favor, escolha o evento-chave",
              pleaseSelectAdvancedCondition:
                "Por favor, escolha condições avançadas",
              newAdvancedFilter: "Nova triagem avançada",
              moveUp: "Mover para cima",
              moveDown: "Mover para baixo",
              moveLeft: "Mover para a esquerda",
              shiftRight: "Mudança para a direita",
            },
          },
          component: {
            businessAnalysispng: "Análise de negócios. png",
            savedSuccessfully: "Salve o sucesso",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Eficaz para sempre",
            permanentlyInvalid: "Permanentemente inválido",
            customValidity: "Período de validade personalizado",
            termOfValidity: "Período de validade",
            name: "Nome",
            explain: "Descrição",
            state: "Estado",
            classification: "Classificação",
            smartRecommendation: "Recomendação inteligente",
            preconditions: "Pré-condições",
            SetPreconditionsForThisSupervisionItem:
              "1. Defina as pré-condições para o item de supervisão:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Quando as pré-condições são atendidas, é o "item de supervisão a ser executado", e o item de supervisão é testado;',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Quando as pré-condições não forem atendidas, o item de supervisão não será detectado;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Sem pré-condições, ou seja, todas as sessões são detectadas por padrão",
            have: "Há",
            nothing: "Nenhum",
            preconditionRule: "Regras pré-condicionais",
            preconditionLogic: "Lógica pré-condicional",
          },
          recommendation: {
            caseGrouping: "Agrupamento de casos",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Quando a execução do funcionário não está em vigor, você pode recomendar de forma inteligente excelentes palavras e casos excelentes",
            smartRecommendation: "Recomendação inteligente",
            open: "Ligado",
            close: "Fechar",
            whenThisSupervisionItem: "Quando este item de supervisão",
            implement: "Execução",
            unexecuted: "Não executado",
            when: "Quando:",
            recommendedKnowledgePoints: "Pontos de conhecimento recomendados",
            caseRecommendation: "Recomendação de caso",
          },
        },
        index: {
          determine: "OK",
          sort: "Ordenação",
          sortSucceeded: "Classificação bem sucedida",
          supervisionItemClassification:
            "Classificação de itens supervisionados",
          pressEnterToCreateANew:
            "Pressione o retorno do carro para construir uma nova",
          nameCannotBeEmpty: "O nome não pode estar vazio",
          newlyBuild: "Novo",
          name: "Nome",
          explain: "Descrição",
          validStatus: "Estado válido",
          permanentlyValid: "Eficaz para sempre",
          permanentlyInvalid: "Permanentemente inválido",
          operation: "Operação",
          edit: "Editar",
          batchDelete: "Excluir em massa",
          newSupervisionItem: "Novos itens de supervisão",
          keyWord: "Palavras-chave",
          delete: "Excluir",
          deleteSucceeded: "Exclusão bem sucedida",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Os dados não serão recuperados após a exclusão, a exclusão é confirmada?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Rótulos inteligentes",
              beEqualTo: "É igual a",
              includeAll: "Contém todos",
              includeAny: "Contém qualquer",
              matchAny: "Combinar qualquer",
              matchAll: "Combine tudo",
              smartTagI: "Etiqueta inteligente um",
              smartTagII: "Etiqueta inteligente II",
            },
            template: {
              pleaseSelectASmartTag: "Por favor, escolha o rótulo inteligente",
              pleaseSelectAdvancedConditions:
                "Por favor, escolha condições avançadas",
              newAdvancedFilter: "Nova triagem avançada",
              rename: "Renomear",
              moveUp: "Mover para cima",
              moveDown: "Mover para baixo",
              shiftLeft: "Mover para a esquerda",
              shiftRight: "Mudança para a direita",
            },
          },
        },
        template: {
          delete: "Excluir",
          labelFiltering: "Filtragem de etiquetas:",
          satisfyAny: "Satisfaça qualquer",
          meetAll: "Conheça todos",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Etiquetas de classificação de segundo nível Número de diálogo ≥ Classificação de primeiro nível, uma sessão pode conter várias tags",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Lógica de cálculo da variação da cadeia: (Volume de diálogo do período selecionado-Volume de diálogo do período de tempo anterior ao lado)/Volume de diálogo do período de tempo anterior",
          proportionOfPrimaryClassification:
            "A proporção da classificação de primeiro nível",
          saveAs: "Salvar como",
          cover: "Cobertura",
          preservation: "Salvar",
          qualityInspectionTask: "Tarefas de análise:",
          query: "Inquérito",
          changeFromMonthToMonth: "Mudanças na cadeia",
          tagRanking: "Ranking de etiquetas",
          proportion: "Situação de proporção",
        },
        component: {
          numberOfConversations: "Número de conversas",
          operation: "Operação",
          businessAnalysispng: "Análise de negócios. png",
          savingSucceeded: "Salve o sucesso",
          deletionSucceeded: "Exclusão bem sucedida",
          sequence: "Sequência",
          firstLevelClassification: "Classificação de primeiro nível",
          ringRatioChange: "Variação da cadeia",
          classClassification: "Classificação de nível",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Confirmar a exclusão do documento e seu conteúdo extraído?",
            addKnowledgeBase: "Adicionar uma base de conhecimento",
            uploadDocument: "Fazer upload de documentos",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Suporte Word, pdf, ppt, excel, arquivos de formato txt",
            taskName: "Nome da tarefa",
            extractConversationDate: "Extraia a data da conversa",
            pleaseSelectAConversationDate:
              "Por favor, selecione a data da conversa",
            dataSource: "Fonte de dados",
            dataType: "Tipo de dados",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Exportação bem sucedida",
              problem: "Problema",
              answer: "Resposta",
              addToKnowledgeBase: "Adicionar à base de conhecimento",
              pleaseConfirmWhetherToDeleteIt:
                "Por favor, certifique-se de remover",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Adicionar à base de conhecimento",
              selectClassification: "Classificação de seleção",
              pleaseSelectAKnowledgeBaseClassification:
                "Selecione a classificação da base de conhecimento",
            },
          },
        },
        index: {
          intelligentExtraction: "Extração inteligente",
          documentExtraction: "Extração de documentos",
          dialogueExtraction: "Extração de diálogo",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operação bem sucedida",
          labelIgnored: "Rótulos foram ignorados",
          add: "Adicionar",
          areYouSureToDeleteIt: "Tá certo de excluir?",
          batchFilterLabels: "Rótulos de filtragem em massa",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Inspeção de qualidade inteligente",
        savedSuccessfully: "Salve o sucesso",
        logoSettings: "Configuração do logo",
        default: "Padrão",
        custom: "Personalização",
        title: "Título",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "O nome do título exibido pelo sistema é recomendado dentro de 6 palavras.",
        logoImageUpload: "Upload de imagem logo",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Por favor, escolha uma imagem com um tamanho não superior a 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Para garantir o efeito de navegação do logotipo, o upload de imagens é recomendado:",
        Size5050Pixels: "1. Tamanho: 50*50 pixels;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Formato: imagem de fundo transparente, png ou jpg, gráfico de logotipo usando branco",
        browserLabelIcon: "Ícone de guia do navegador",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Para garantir o efeito de navegação de etiquetas, o upload de imagens é recomendado:",
        Size3232Pixels: "1. Tamanho: 32*32 pixels;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Formato: imagem de fundo transparente, png ou jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "A inspeção de qualidade está em andamento, por favor, mais tarde...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "Na reunião de 2015, o mercado imobiliário do meu país atingiu um momento crítico de sobrevivência, um grande número de casas foi deixado sem interesse e as pessoas não estavam dispostas a comprá-las. Hangzhou caiu abaixo de 8.000 uma vez, e alguns incorporadores quebraram o mercado para enviar. Shenzhen ainda estava se consolidando em torno de 2w-3w naquela época, e as casas nas cidades de primeiro nível também estavam em perigo.",
        xiaoAn: "Xiao An",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore steload Essas barreiras de memória são chamadas de barreiras de memória jsr, que são no nível jvm. Existem duas maneiras de implementá-las na parte inferior do sistema operacional: 1 é o barramento de bloqueio e o segundo é a memoriana de barreira de memória, como sfance (barreira de escrita) mfence (barreira de leitura) (barreira de leitura). A parte inferior da máquina virtual hotspots usa uma instrução de montagem: bloco addl, uma operação vazia, ou seja, um barramento de bloqueio, o que é feito porque alguns sistemas operacionais não suportam meta de barreira de memória.",
        xiaobei: "Pequeno Norte",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Tente marinar a carne várias vezes, adicione os vegetais fritos depois de beber, a carne é como podre, um cheiro azedo! A partir de então, a carne marinada não vai colocar vinho de cozinha",
        xiaodong: "Xiaodong",
        xiaoxi: "Pequeno Oeste",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Tente marinar a carne várias vezes, adicione os vegetais fritos depois de beber, a carne é como podre, um cheiro azedo! A partir de então, não coloque mais vinho de cozinha na carne marinada",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Gestão de revisão de comércio eletrônico",
        socialMediaReviewManagement: "Gestão de comentários de mídia social",
        customDataSource: "Original de dados personalizado",
        qualityInspectionResults: "Resultados de inspeção de qualidade",
        accountManagement: "Gestão de contas",
        employeeProfile: "Retratos de funcionários",
        intelligentAnalysis: "Análise inteligente",
        customKanban: "Kanban personalizado",
        dataScreening: "Visão geral dos dados",
        learningRecord: "Registro de aprendizagem",
        dataAnalysis: "Análise de dados",
        scoringManagement: "Gestão de Pontuação",
        businessConfiguration: "Configuração de negócios",
        manualReview: "Revisão manual",
        taskList: "Lista de tarefas",
        videoAnalysis: "Análise de vídeo",
        overview: "Visão geral",
        personalSignage: "Kanban pessoal",
        learningCenter: "Centro de aprendizagem",
        wrongQuestionSet: "Conjunto de perguntas erradas",
        abilityImprovement: "Aprimore a capacidade",
        close: "Fechar",
        switchingSystemApplications: "Alternar aplicações do sistema",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Mova o mouse aqui para chamar o menu de alternância de aplicativos e clique no item de menu para pular aplicativos diferentes",
        teamAnalysis: "Análise da equipe",
        semanticIntelligence: "Inteligência Semântica",
        permissionManagement: "Gerenciamento de permissões",
        dataConnection: "Docking de dados",
        courseManagement: "Gestão Curricular",
        labelManagement: "Gestão de etiquetas",
        curriculum: "Centro de cursos",
        courseConfiguration: "Configuração do curso",
        task: "Minha missão",
        historicalLearningData: "História",
        taskCenter: "Gerenciamento de tarefas",
        knowledgeBase: "Base de conhecimento",
        ecommerceSessionManagement:
          "Gerenciamento de sessões de comércio eletrônico",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Alternar o aplicativo",
          descriptionOfIntelligentQualityInspectionSystem:
            "Descrição do sistema de inspeção de qualidade inteligente",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Filtros de sessão de comércio eletrônico",
        allEcommerceSessions: "Todas as sessões de comércio eletrônico",
        ecommerceSessionManagement:
          "Gerenciamento de sessões de comércio eletrônico",
      },
    },
    analysisEnable: {
      bestPractices: "Melhores práticas",
      behaviorAnalysis: "Análise Comportamental",
      teamAnalysis: "Análise da equipe",
      executiveSupervision: "Supervisão de execução",
      executiveForceAnalysis: "Análise de Execução",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Classificação:",
          startTime: "Hora de início",
          workOrderNo: "Número da ordem de trabalho",
          customerServiceHasReadNotRead:
            "Atendimento ao cliente foi lido/não lido",
          canYouAppeal: "Se você pode apelar",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Notas de micro-empresa",
          addFriendTime: "Adicionar tempo de amigos",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Comece a executar a reinspeção de qualidade",
          callID: "ID da chamada",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Novos recursos! Clique no botão "Verificar rapidamente" e, após o envio bem-sucedido, ele saltará automaticamente para a próxima página ~~',
        },
      },
      template: {
        deleteRecord: "Excluir registros",
        areYouSureToDeleteIt: "Tá certo de excluir?",
        confirm: "OK",
        reIdentification: "Reidentificação",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "A reidentificação consumirá o tempo de transliteração, por favor, tenha cuidado",
        determine: "OK",
        executionSuccessful: "Execução bem sucedida",
      },
      component: {
        pleaseSelectData: "Por favor, escolha os dados",
        operationSuccessful: "Operação bem sucedida",
        startPerformingReQualityInspection:
          "Comece a executar a reinspeção de qualidade",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Carregar o nome do arquivo",
        analysisTask: "Tarefas de análise",
        analyzeProgress: "Analise o progresso",
        analysisSucceeded: "Análise para o sucesso",
        analysisFailed: "Falha na análise",
        localDataUpload: "Upload de dados local",
        recordingUpload: "Upload de gravação",
        textUpload: "Upload de texto",
        uploadTime: "Tempo de upload",
        taskName: "Nome da tarefa",
        operator: "Homem de operação",
        qualityInspectionProgress: "Progresso da inspeção de qualidade",
        whole: "Todos",
        haveInHand: "Em andamento",
        completed: "Concluído",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Exporta todos os registros de dados concluídos sob a condição de filtro atual?",
        upload: "Upload",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Preencha o nome",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Preencha o nome, como o tipo/coleção/finalidade dos dados importados",
            default: "Padrão",
            jDCOM: "JD",
            dataType: "Tipo de dados",
            selectAnalysisTask: "Escolha uma tarefa de análise",
            uploadFiles: "Fazer upload de arquivos",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Faça o download do modelo do Excel, preencha por formato:",
            template: "Modelos",
            SupportUpTo10000PiecesOfData: "2. Suporte máximo de 10000 dados",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Suporta upload de dados de conversação exportados pela JD.com, arquivo. txt, tamanho máximo de arquivo de suporte 100M",
            alongTheWayDataTemplate: "Modelo de dados de estrada",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Durante a inspeção de qualidade do texto, por favor, espere pacientemente",
            textQualityInspectionCompleted:
              "Conclusão da inspeção de qualidade de texto",
          },
        },
        template: {
          startAnalysis: "Início da análise",
          pleaseUploadTheFile: "Por favor, faça o upload do arquivo",
          localDataUpload: "Upload de dados local",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "Novos recursos! Clique no botão 'Enviar revisar' e, após o envio bem-sucedido, ele saltará automaticamente para a próxima página ~~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Autoridade da tarefa de inspeção de qualidade",
            edit: "Editar",
            iParticipated: "Eu participei",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "A função pode ver os dados sob o departamento selecionado",
            dataPermissions: "Permissões de dados",
            selectDepartment: "Escolha um departamento",
            edit: "Editar",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Comece a executar a reinspeção de qualidade",
          callID: "ID da chamada",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Novos recursos! Clique nos botões de 'submissão de amostra' e 'inspeção de amostra', após o envio bem-sucedido, ele saltará automaticamente para a próxima página ~~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Regras" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Gestão da ordem de trabalho",
          workOrderDetails: "Detalhes da ordem de trabalho",
        },
      },
      template: {
        workOrderFilter: "Filtro de lista de trabalho",
        allWorkOrders: "Todos os pedidos de trabalho",
        workOrderManagement: "Gestão da ordem de trabalho",
      },
    },
    trainingCenter: {
      topicAnnotation: "Anotação de tópico",
      topicList: "Lista de tópicos",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Tipo de função",
              keyWord: "Palavras-chave",
              pleaseEnterKeywordSearch:
                "Por favor, insira a palavra-chave para pesquisa",
              query: "Inquérito",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Este recurso ainda não está aberto, se necessário, entre em contato com o administrador do sistema",
          pleaseSelectAQuestion: "Por favor, escolha a pergunta!",
          recommendedSimilarSentencesLargeModel:
            "Sentença semelhante recomendada (modelo grande)",
          selectAll: "Seleção completa",
          add: "Adicionar",
          changeBatch: "Mudar um lote",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Horas de trabalho de negócios",
      localDataUpload: "Upload de dados local",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Montar uma lista de menus com base nas permissões do usuário atual",
      sessionSettings: "Configurações da sessão",
      logoSettings: "Configuração do logo",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Bem-vindo de volta! Por favor, insira seus detalhes",
        mailbox: "Caixa de correio",
        password: "Senha",
        signIn: "Login",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "O IP atual não permite o pouso",
          welcomeToLogin: "Bem-vindo ao login",
          pursuingExcellenceAndLeadingChange:
            "Buscar a excelência e liderar a mudança",
          intelligentPlatform: "Plataforma inteligente",
        },
        virtualLogin: { index: { pleaseSelect: "Por favor, escolha" } },
      },
      oauth: {
        illegalCall: "Chamada ilegal",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Bem-vindo de volta! Clique no botão abaixo para pular para a plataforma de autenticação de identidade",
        loggingIn: "Login em...",
        unifiedIdentityAuthenticationLogin: "Login de autenticação unificada",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Teste de regras: algumas regras não podem usar testes de regras, como volume de fala, dados de acompanhamento, etc.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Saiba mais",
          enteringTheSystem: "Acesso ao sistema",
        },
      },
      index: {
        cancel: "Cancelamento",
        enteringTheSystem: "Acesso ao sistema",
        close: "Fechar",
        enterSystemApplication: "Entre no aplicativo do sistema",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Clique em "Entrar no sistema" para pular para aplicativos do sistema habilitados',
      },
    },
    permissionManagement: {
      employeeManagement: "Gestão de funcionários",
      roleManagement: "Gerenciamento de papéis",
      permissionManagement: "Gerenciamento de permissões",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "É igual a",
            contain: "Contém",
            doesNotContain: "Não contém",
            applicationSystem: "Sistema de aplicação",
            intelligentQualityInspection:
              "Inspeção de qualidade inteligente/análise de sessão inteligente",
            salesEmpowerment: "Capacitação de vendas",
            dataSourceType: "Tipo de fonte de dados",
            filterCriteria: "Condições de triagem",
          },
        },
        index: {
          dataConnection: "Docking de dados",
          savingSucceeded: "Salve o sucesso",
          dataIsolation: "Isolamento de dados",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Depois de ligado, você pode definir o intervalo de dados para cada sistema de aplicativo para acessar chamadas e sessões WeChat corporativas. Se o interruptor não estiver ligado, cada sistema terá acesso à quantidade total de dados",
          accessRules: "Regras de acesso",
          preservation: "Salvar",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Nome do aplicativo",
            accessRights: "Permissões de acesso",
            inAppPermissionSettings:
              "Configurações de permissões no aplicativo",
            setUp: "Configurações",
            applicationAccess: "Acesso ao aplicativo",
            newRole: "Novos personagens",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Detalhes da sessão" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Detalhes da sessão",
          caseDetails: "Detalhes do caso",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Funcionários visíveis",
            newCourse: "Novo curso",
            editCourse: "Editar um curso",
            courseName: "Nome do curso",
            courseIntroduction: "Introdução ao curso",
            courseType: "Tipo de curso",
            courseClassification: "Classificação do curso",
            courseCover: "Capa do curso",
            learningContent: "Conteúdo de aprendizagem",
            uploadLearningMaterials:
              "Fazer upload de materiais de aprendizagem",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Teste",
              preservation: "Salvar",
              cancel: "Cancelamento",
              editNodeName: "Editar o nome do nó",
              determine: "OK",
              individual: "Um",
              of: ", Um total",
              branch: "Pontos",
            },
            configDrawerComponents: {
              pPTParsing: "Análise PPT em...",
              uploadSuccessful: "Carregar com sucesso",
              uploadAttachments: "Fazer upload de anexos",
              pPTParsingPleaseWait: "Análise PPT, por favor mais tarde",
            },
            customerPortraitConfigDrawer: {
              cancel: "Cancelamento",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Não é possível recuperar após a exclusão. Tenho certeza de que deseja remover este retrato?",
              delete: "Excluir",
              determine: "OK",
              customerPortrait: "Retratos de clientes",
              bindSelectedCharacterPortrait:
                "Ligação para selecionar o retrato do personagem",
              customerProfileDetails: "Detalhes do retrato do cliente",
              createANewCustomerProfile: "Novo retrato do cliente",
            },
            flow: {
              sidebar: { node: "Nó" },
              useControlButtons: {
                cancellingPleaseWait: "Revogação, por favor mais tarde...",
                redoInProgressPleaseWait: "Em refazer, por favor mais tarde...",
                automaticOrganization: "Acabamento automático",
                canvasAdaptation: "Adaptativo de tela",
                delete: "Excluir",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Os nós não podem ser conectados a si mesmos",
              },
            },
            globalConfigDrawer: {
              cancel: "Cancelamento",
              determine: "OK",
              globalConfiguration: "Configuração global",
              sessionDetection: "Detecção de sessão",
              reciprocalRule: "Regras de interação",
              robotVoice: "Timbre de robô",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "O nó do aluno não responde às configurações de tempo limite",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "O tempo máximo de silêncio permitido pelo aluno não respondeu (s)",
              promptScriptForFailureToAnswerOverTime:
                "Não há resposta para horas extras",
              addScript: "Adicionar uma técnica",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (Natureza)",
              zhishaChat: "Zhisha (bate-papo)",
              zhiqiangentle: "Zhiqian (moderado)",
              zhimiEmotion: "Chimi (emoção)",
              aiShuoNatural: "Ai Shuo (Natureza)",
              zhishuoChat: "Zhishuo (bate-papo)",
              aiHaoAffinity: "Ai Hao (afinidade)",
              zhixiangEmotion: "Zhixiang (emoção)",
              robotVoice: "Timbre de robô",
              schoolboy: "Garoto",
              girlStudent: "Menina",
              volume: "Volume",
              tone: "Tom",
              speechSpeed: "Velocidade da fala",
              test: "Teste",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Olá, eu sou um treinador de treinamento inteligente e estou ansioso para se comunicar com você",
            },
            sessionDetectionForm: {
              expressionDetection: "Detecção de expressão",
              excessiveDetectionOfModalParticles:
                "Detecção excessiva de palavras de tom",
              theNumberOfRepetitionsIs: "O número de repetições é",
              minus: "A segunda vez, menos",
              appear: "Aparece",
              subtract: "Tempo, menos",
              branch: "Pontos",
              addRules: "Regras de aumento",
              includingNegativeAngryComplaining:
                "(Incluindo negativo, raiva, reclamação)",
              scorePointsDisplay: "Exibição de pontos de pontuação",
              reminderOfSpeakingTooFasttooSlow: "Lembrete rápido/muito lento",
              scriptViolationReminder: "Lembrete de violação de fala",
              emotionalReminder: "Lembretes de humor",
              speechSpeedDetection: "Detecção de velocidade de fala",
              normalSpeechSpeedScore: "Pontuação normal de velocidade de fala",
              speakTooFast: "Muito rápido",
              everyMinuteGreaterThan: "Maior que por minuto",
              aWordAppears: "Uma palavra, aparece",
              speakTooSlowly: "Velocidade de fala muito lenta",
              lessThanPerMinute: "Menos de cada minuto",
              ignoreLessThan: "Ignorar menos do que",
              aSentenceOfWords: "Palavra da frase",
              emotionalDetection: "Detecção de emoções",
              emotionalNormalScore: "Emoções marcam normalmente",
              negativeEmotions: "Emoções negativas",
              illegalWordDetection: "Detecção de palavras ilegais",
              scriptViolationConfiguration: "Configuração ilegal de conversa",
              realTimePromptOnTheFrontEnd: "Dicas de front-end em tempo real",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA:
                    "Perguntas e Respostas da Base de Conhecimento",
                  qAMethod: "Método de perguntas e respostas",
                  numberOfQuestionsAndAnswers:
                    "Quantidade de perguntas e respostas",
                  scoringRules: "Regras de pontuação",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Qualquer pergunta e resposta está correta,",
                  branch: "Pontos",
                  successfullySaved: "Salve o sucesso",
                },
                pptNode: {
                  verificationPassed: "A verificação é aprovada",
                  upload: "Upload",
                  supportPptPdfFormatFiles:
                    "Suporte ppt, arquivos de formato pdf",
                  uploadSlide: "Fazer upload de uma apresentação de slides",
                  assessmentMethod: "Método de avaliação",
                  associateSlide: "Slides de correlação",
                  standardScript: "A técnica padrão",
                  successfullySaved: "Salve o sucesso",
                },
                robotNode: {
                  robotScript: "Falando robótico",
                  randomScript: "Tese aleatória",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Escolha palavras semânticas semelhantes e o robô falará aleatoriamente uma das frases",
                  customerPortrait: "Retratos de clientes",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Quando o retrato do cliente é o mesmo, o robô diz essa técnica",
                  setAsDefaultScript: "Definido como a técnica padrão",
                  yes: "Sim",
                  no: "Não.",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Esta técnica é dita quando o robô falha em qualquer tag do usuário",
                  successfullySaved: "Salve o sucesso",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Este nó de ramificação já possui uma técnica de fala padrão. O discurso padrão é substituído pela técnica de fala atual?",
                },
                startNode: {
                  paragon: "Narração",
                  successfullySaved: "Salve o sucesso",
                },
                userNode: {
                  verificationPassed: "A verificação é aprovada",
                  standardScript: "A técnica padrão",
                  successfullySaved: "Salve o sucesso",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Por favor, melhore a configuração",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Por favor, insira um exemplo de conversa",
                  dialogueExample: "Exemplo de diálogo",
                  generateConversationExamples: "Exemplo de diálogo gerado",
                  mattersNeedingAttention: "Precauções",
                  pleaseEnterThePromptLanguage: "Por favor, insira um prompt",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Cena de diálogo",
                  simulateCharacter: "Simulação de personagens",
                  accompanyingTrainees: "Objetos de sparring",
                  selectFromTemplateLibrary:
                    "Escolha entre uma biblioteca de modelos",
                  templateLibrary: "Biblioteca de modelos",
                  role: "Personagens",
                  scene: "Cena",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Janela de teste de apresentação de slides [",
              clearContent: "Limpar o conteúdo",
              close: "Fechar",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "O processo de geração de IA fala",
                scriptStyle: "Estilo de fala",
                theMostConversationRounds: "Mais rodadas de diálogo",
                copyConversation: "Copiar uma conversa",
                application: "Aplicações",
                regenerate: "Re-geração",
                startGenerating: "Comece a gerar",
                pleaseGenerateAProcess: "Por favor, crie o processo",
                copySuccessful: "Replicação bem sucedida",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Representante de vendas",
                    customerServicePersonnel:
                      "Pessoal de atendimento ao cliente",
                    storeCounter: "Balcões da loja",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Gerar discurso de texto completo",
                speaker: "Palestrante",
                scriptStyle: "Estilo de fala",
                copyConversation: "Copiar uma conversa",
                application: "Aplicações",
                regenerate: "Re-geração",
                startGenerating: "Comece a gerar",
                pleaseGenerateAProcess: "Por favor, crie o processo",
                operationSuccessful: "Operação bem sucedida",
                copySuccessful: "Replicação bem sucedida",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Redução da fala" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "A fala não pode ser vazia!",
              copySuccessful: "Replicação bem sucedida",
              cancel: "Cancelamento",
              copy: "Reprodução",
              replaceTheOriginalText: "Substituir o original",
              aiGeneration: "Geração Ai",
              aiPolishing: "Ai retoques",
              currentStyle: "Estilo atual",
              rigorous: "Rigor",
              mild: "Moderado",
              lively: "Animado",
              simplicity: "Simplicidade",
              pleaseEnterTheSpeakerRole: "Por favor, insira o papel do falante",
              startGenerating: "Comece a gerar",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "A fala não pode ser vazia!",
              copySuccessful: "Replicação bem sucedida",
              aiRewrite: "Ai reescrita",
              cancel: "Cancelamento",
              copy: "Reprodução",
              replaceTheOriginalText: "Substituir o original",
              currentStyle: "Estilo atual",
              rigorous: "Rigor",
              mild: "Moderado",
              lively: "Animado",
              simplicity: "Simplicidade",
              pleaseEnterTheSpeakerRole: "Por favor, insira o papel do falante",
              startGenerating: "Comece a gerar",
            },
          },
          index: {
            verificationPassed: "A verificação é aprovada",
            ruleValidation: "Verificação de regras",
            aIGeneration: "Geração de IA",
            generateFullTextSpeechScript: "Gerar discurso de texto completo",
            editCourse: "Editar um curso",
            importConversation: "Importar uma conversa",
            uploadExcelToCompleteDialogueImport:
              "Fazer upload do Excel para completar a importação de conversas",
            editBasicInformation: "Editar informações básicas",
            test: "Teste",
            release: "Publicação",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Nova etiqueta" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Não é possível recuperar após a exclusão. Tenho certeza de que deseja remover este rótulo?",
              delete: "Excluir",
              cancel: "Cancelamento",
            },
          },
        },
        index: {
          labelManagement: "Gestão de etiquetas",
          newGroup: "Novo agrupamento",
          edit: "Editar",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Não é possível recuperar após a exclusão. Tenho certeza de que deseja excluir esse grupo de tags?",
          delete: "Excluir",
          cancel: "Cancelamento",
          editGroup: "Editar um agrupamento",
          labelGroupName: "Nome do agrupamento de rótulos",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Câmera desativada",
              noCameraDevicesFound: "Nenhum dispositivo de câmera encontrado",
            },
          },
          cue: {
            index: {
              regenerate: "Re-geração",
              generate: "Geração",
              referenceScript: "Referências",
              mattersNeedingAttention: "Precauções",
              examFrequency: "Número de exames",
              section: "No.",
              timestotal: "Vezes/total",
              second: "Vezes",
              requirementsForPassingTheExam:
                "Requisitos para aprovação no exame",
              examScore: "Pontuação do exame ≥",
              branch: "Pontos",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Sem retrato",
              customerBackground: "Fundo do cliente",
              customerLabel: "Rótulos de clientes",
            },
          },
          flow: {
            index: {
              processReminder: "Dicas de processo (",
              score: "Pontuação",
              TotalScore: "/Total de pontos",
            },
          },
          index: {
            taskName: "Nome da tarefa",
            taskStatus: "Status da tarefa",
            whole: "Todos",
            completionStatus: "Estado de conclusão",
            courseClassification: "Classificação do curso",
            practiceMode: "Modo de prática",
            examMode: "Modo de exame",
            practiceTime: "Tempo de prática",
          },
          ppt: {
            index: {
              nextPage: "Próxima página",
              tips: "Dicas",
              continueWithCurrentPage: "Continuar com a página atual",
              goToTheNextPage: "Vá para a próxima página",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Depois de entrar na próxima página, não será mais possível retornar à página anterior, por favor confirme a explicação da página atual está completa antes de pular",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Clique na próxima página não aparecerá esta dica",
            },
            list: { index: { slide: "Slides" } },
          },
          record: {
            index: {
              unableToRecord: "Não é possível gravar:",
              creatingARecorderService: "Criar um serviço de Recorder",
              turnOffTheRecorderService: "Fechar o serviço Recorder",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "O cliente atual está conectado ao servidor ASR Websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "Depois de processar o último quadro, restaure o estado",
              aSRConnectionClosed: "A conexão ASR está fechada...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "A gravação foi suspensa e processada, mas os dados ainda estão presentes no cache",
              foundASRChannelClosedRecreateWebsocketLink:
                "Descubra o fechamento do canal ASR, recrie o link Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Recriar o link Websocket, descobrindo que o canal ASR não está aberto",
            },
            ppt: { hook: { index: { serverError: "Erro no servidor" } } },
            text: {
              index: {
                pleaseEnter: "Por favor, insira",
                sendOut: "Enviar",
                startPracticing: "Comece a praticar",
                startTheExam: "Início do exame",
              },
            },
            util: {
              recorded: "Gravado",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 bits pcm SendFrameSize deve ser um múltiplo inteiro de 2",
              pCMSamplingRateShouldNotOccur:
                "Não deve haver taxa de amostragem de pcm",
              andTheRequiredSamplingRate: "E taxa de amostragem necessária",
              atypism: "Inconsistência",
              errorsThatShouldNotOccur: "Erros que não devem ocorrer:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> Dados desordenados, se os requisitos não forem altos, eles podem ser descartados diretamente </span>',
              flower: "Flor",
              TransferStopped: ": A transmissão foi interrompida",
            },
            video: { index: { camera: "Câmera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "O cliente atual está conectado ao servidor ASR Websocket",
                    aSRConnectionClosed: "A conexão ASR está fechada...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Descubra o fechamento do canal ASR, recrie o link Websocket",
                    unableToRecord: "Não é possível gravar:",
                    aSRChannelClosedReopened: "Canal ASR fechado, reaberto",
                    theASRChannelWasNotCreatedStartingNow:
                      "O canal ASR não foi criado, agora começa a criar",
                    useEffectCallbackToClearTheASRChannel:
                      "O retorno de chamada useEffect para limpar o canal ASR",
                    creatingARecorderService: "Criar um serviço de Recorder",
                    turnOffTheRecorderService: "Fechar o serviço Recorder",
                  },
                  util: {
                    recorded: "Gravado",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 bits pcm SendFrameSize deve ser um múltiplo inteiro de 2",
                    pCMSamplingRateShouldNotOccur:
                      "Não deve haver taxa de amostragem de pcm",
                    andTheRequiredSamplingRate:
                      "E taxa de amostragem necessária",
                    atypism: "Inconsistência",
                    errorsThatShouldNotOccur: "Erros que não devem ocorrer:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> Dados desordenados, se os requisitos não forem altos, eles podem ser descartados diretamente </span>',
                    flower: "Flor",
                    TransferStopped: ": A transmissão foi interrompida",
                  },
                },
                index: { failInSend: "Falha ao enviar" },
              },
              index: {
                startRecording: "Começar a gravar",
                endRecording: "Fim da gravação",
                startPracticing: "Comece a praticar",
                startTheExam: "Início do exame",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(O número de vezes restantes do exame atual é",
              totalScriptScore: "Pontuação total:",
              totalEmotionalScore: "Pontuação total emocional:",
              totalScoreOfExpression: "Expresse a pontuação total:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Os resultados da pontuação da tarefa atual precisam ser revisados manualmente, a conclusão da revisão notificará você da pontuação final, por favor, seja paciente",
              areYouSureToSubmit: "Confirmar a submissão?",
              theExerciseHasEndedAreYouSureToSubmit:
                "O exercício acabou, a submissão está confirmada?",
              theExamHasEndedAreYouSureToSubmit:
                "O exame acabou, a submissão está confirmada?",
              completed: "Concluído",
              page: "Página",
              pageCount: "Total de páginas",
              topic: "Pergunta",
              totalNumberOfQuestions: "Número total de perguntas",
              totalScore: "Pontuação total",
              branch: "Pontos",
              accumulatedCompletionProgress:
                "Progresso de conclusão cumulativa",
              accumulatedPracticeDuration: "Dduração da prática cumulativa",
              lastPauseExerciseResults: "Última pausa-Resultados da Prática",
              exerciseInstructions: "Instruções de exercício",
              examInstructions: "Descrição do exame",
              halfwayPausePracticeResults:
                "Pausa no meio do caminho-resultados da prática",
              tips: "Dicas",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Este exercício não conta para a pontuação após a retirada. A retirada é confirmada?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "A retirada não é permitida no meio do exame, o envio é confirmado?",
              pleaseOperateWithCaution: "Vezes, por favor, tenha cuidado)",
              restart: "Começar de novo",
              returnToLearning: "Voltar para aprender",
              continuePracticing: "Continue praticando",
              continuingTheExam: "Continuar com o exame",
              return: "Voltar",
              notSubmittedTemporarily: "Não envie por enquanto",
              iGotIt: "Eu sei...",
              confirmExit: "Confirmar a saída",
              confirmSubmission: "Confirmar o envio",
              signOut: "Saída",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Suspensão",
              submit: "Enmissão",
              remainingTimeOfThisQuestion: "Tempo restante para esta pergunta",
              SecondsLeftUntilTheEndTime: "Faltam 10 segundos para o fim",
              remainingTime: "Tempo restante",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "O conteúdo de voz do usuário não é detectado, tente novamente",
          },
          wave: {
            index: {
              continueRecording: "Continuar a gravação",
              submit: "Enmissão",
              endRecording: "Fim da gravação",
              suspendRecording: "Suspender a gravação",
              startPracticing: "Comece a praticar",
              startTheExam: "Início do exame",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Modo de diálogo",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Muito barulho, por favor, mude o ambiente silencioso",
              normalNoiseDetection: "Detecção de ruído normal",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "O volume é muito pequeno, aumente o volume",
              volumeDetectionIsNormal: "Detecção de volume normal",
              noiseDetection: "Detecção de ruído",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Por favor, fique quieto e gravar 3s de som ambiente",
              endRecording: "Fim da gravação",
              startRecording: "Começar a gravar",
              volumeDetection: "Detecção de volume",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Por favor, leia "teste de volume agora" em volume normal',
              playLastDetectedAudio: "Reproduzir o último áudio detectado",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Leia atentamente os requisitos antes de começar",
              return: "Voltar",
              iGotIt: "Eu sei...",
              timeLimitForSingleQuestionAnswering:
                "Limite de duração para uma única pergunta",
              totalTimeLimit: "Limite total de tempo",
              unlimitedTime: "Sem limite de tempo",
              stillNeedToCompleteTheTaskInTotal: "Tarefas sempre concluídas",
              practice: "Exercícios",
              second: "Vezes",
              taskScore: "A tarefa marcou ≥ uma vez",
              branch: "Pontos",
              or: "Ou",
              examScore: "Pontuação do exame ≥",
              remainingExamTimes: "Número de exames restantes",
              totalScore: "Pontuação total",
              Script: "(Tese",
              scoreEmotion: "Emoções divididas",
              fractionExpression: "Expressão dividida",
              points: "Pontos)",
              standardScore: "Pontos padrão",
              excellentScore: "Pontos excelentes",
              exerciseInstructions: "Instruções de exercício",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Para responder às perguntas sem problemas, conclua o teste do equipamento primeiro:",
              camera: "Câmera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Ser capaz de se ver dentro do vídeo significa que a câmera está normal",
              microphone: "Microfone",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Por favor, fale com o microfone, ver o padrão de onda significa que o microfone está funcionando",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Vazio",
                  copyTo: "Copiar para",
                  classification: "Classificação",
                  rename: "Renomear",
                  newlyBuild: "Novo",
                  categoryParent: "Classificação superior",
                  moveTo: "Mover",
                  name: "Nome",
                },
              },
              util: {
                rename: "Renomear",
                moveTo: "Mover",
                copyTo: "Copiar para",
                delete: "Excluir",
                courseClassification: "Classificação do curso",
                newlyBuild: "Novo",
              },
            },
          },
          edit: { index: { curriculum: "Currículo" } },
          index: {
            learn: "Aprenda",
            practice: "Prática",
            examination: "Exame",
            curriculum: "Currículo",
            courseIntroduction: "Introdução ao curso:",
            createdOn: "Tempo de criação:",
            published: "Publicado",
            tasks: "Uma missão",
            unpublishedTasks: "Tarefas não publicadas",
            task: "Missão",
            operation: "Operação",
            learningMaterials: "Materiais de aprendizagem",
            goPractice: "Vá para a prática",
            publishTask: "Publicar tarefas",
            taskData: "Dados da tarefa",
            edit: "Editar",
            copy: "Reprodução",
            successfullyCopied: "Replicação bem sucedida",
            delete: "Excluir",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Todos os dados da tarefa neste curso serão esvaziados após a exclusão. A exclusão do curso é confirmada?",
            courseName: "Nome do curso",
            creationTime: "Tempo de criação",
            releaseStatus: "Status de publicação",
            whole: "Todos",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Nenhum som detectado",
              voice: "Voz",
              text: "Texto",
              referenceScript: "Referência:",
              clickOnTheRightSideToStartRecording:
                "Clique à direita para iniciar a gravação",
              sendOut: "Enviar",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "O cliente atual está conectado ao servidor ASR Websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "Depois de processar o último quadro, restaure o estado",
                aSRConnectionClosed: "A conexão ASR está fechada...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "A gravação foi suspensa e processada, mas os dados ainda estão presentes no cache",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Descubra o fechamento do canal ASR, recrie o link Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Recriar o link Websocket, descobrindo que o canal ASR não está aberto",
                unableToRecord: "Não é possível gravar:",
                creatingARecorderService: "Criar um serviço de Recorder",
                turnOffTheRecorderService: "Fechar o serviço Recorder",
              },
              util: {
                recorded: "Gravado",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 bits pcm SendFrameSize deve ser um múltiplo inteiro de 2",
                pCMSamplingRateShouldNotOccur:
                  "Não deve haver taxa de amostragem de pcm",
                andTheRequiredSamplingRate: "E taxa de amostragem necessária",
                atypism: "Inconsistência",
                errorsThatShouldNotOccur: "Erros que não devem ocorrer:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> Dados desordenados, se os requisitos não forem altos, eles podem ser descartados diretamente </span>',
                flower: "Flor",
                TransferStopped: ": A transmissão foi interrompida",
              },
            },
            finishTest: {
              index: {
                endTest: "Fim do teste",
                testResult: "Resultados do teste",
                returnToCanvas: "Voltar para a tela",
                retest: "Teste novamente",
              },
            },
          },
          index: {
            testFromCurrentNode: "Teste do nó atual",
            listening: "No rádio...",
            canvasTesting: "Teste de tela",
            endOfProcess: "Fim do processo",
            restart: "Começar de novo",
            start: "Início",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Análise de fechamento",
                  expandParsing: "Expanda a análise",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: ", Muitas palavras de tom",
                  second: "Vezes",
                  ratingOverview: "Visão geral da pontuação",
                  totalScore: "Pontuação total",
                  section: "No.",
                  name: "Nome",
                  of: "(No total",
                  people: "Pessoas)",
                  scriptScore: "Pontuação de palavras",
                  common: "O total",
                  keyPoints: "Ponto importante,",
                  notReached: "Um não alcançado,",
                  have: "Há",
                  violationOfRegulations: "Violação",
                  emotionalScore: "Pontuação emocional",
                  appear: "Aparece",
                  secondaryNegativeEmotions:
                    "Emoções negativas em segundo lugar",
                  expressionScore: "Pontuação de expressão",
                  speakTooFast: "Muito rápido",
                  times: "Vezes,",
                  speakTooSlowly: "Velocidade de fala muito lenta",
                  average: "Média",
                  wordminute: "Palavra/minuto",
                },
              },
              score: {
                index: {
                  studentNodeName: "Nome do nó do aluno",
                  keyPointName: "Nome do ponto principal",
                  slideNodeName: "Nome do nó do slide",
                  dialogue: "Diálogo",
                  mainPoints: "Pontos-chave",
                  slide: "Slides",
                  detectionResult: "Resultados da detecção",
                  satisfy: "Satisfazer",
                  dissatisfaction: "Não satisfeito",
                  score: "Pontuação",
                  script: "A técnica da fala",
                  semantics: "Semântica",
                  scriptViolation: "Violação da fala",
                  emotion: "Emoções",
                  expression: "Expressão",
                },
              },
              taskTitle: { index: { branch: "Pontos" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Desta vez",
                  lastTime: "Última vez",
                  comprehensiveEvaluation: "Avaliação abrangente",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Acumulação padrão',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Uma palavra, o número de palavras que os alunos seguem",
                  aWordspan: "Palavra </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Ms </span><br /><span style = "margin-left: 8px">· Velocidade de fala muito lenta',
                  timesspan: "Vezes </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Muitas palavras de tom',
                  complete: "Conteúdo completo",
                  keyHit: "Ponto de acerto",
                  languageNorm: "Especificação de linguagem",
                  fluentExpression: "Expressão suave",
                  emotionallyPositive: "Emoção positiva",
                  radarChart: "Diagrama de radar",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Número completo de processos',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· Pontos para acertar',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· Há',
                  dealingWithIllegalWordsspan:
                    "Por violação de palavra </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· fala muito rápido',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Ms </span><br /><span style = "margin-left: 8px">· Muitas palavras de humor',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Emoções negativas',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Registro de pontuação",
                  firstRating: "Primeira classificação",
                  totalScore: "Pontuação total",
                  changeTo: "Mudança para",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Fase de comunicação",
                  communicationObjectives: "Objetivos de comunicação",
                  scoringCriteria: "Critérios de classificação",
                  suggestionsForImprovement: "Sugestões de elevação",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Número de vezes restantes:",
                  second: "Vezes",
                  doItAgain: "Mais uma vez",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "O navegador não suporta a reprodução de áudio.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Comentários e sugestões",
                  regenerate: "Re-geração",
                  generate: "Geração",
                  generationFailed: "Falha na geração",
                },
              },
              resultModal: {
                index: { return: "Voltar", viewDetails: "Ver detalhes" },
              },
            },
            index: {
              reRatingSuccessful: "Reclassificação para o sucesso",
              reRatingFailed: "Falha na reclassificação",
              downloadRecording: "Baixar gravações",
              downloadText: "Baixar o texto",
              download: "Download",
              reRating: "Reclassificação",
              submitARatingAppeal: "Enviar um recurso de pontuação",
              reviewSubmission: "Submissão de revisão",
              saveChanges: "Salvar modificações",
              previousSentence: "Última frase",
              nextSentence: "A próxima frase",
              score: "Classificação",
              scoringSituation: "Situação de pontuação",
              dialogueAnalysis: "Análise de diálogo",
            },
          },
          index: {
            ratingAppealInProgress: "Em recurso de pontuação",
            operation: "Operação",
            wrongQuestionSet: "Conjunto de perguntas erradas",
            ratingUpdate: "Atualizações de classificação",
            viewDetails: "Ver detalhes",
            curriculum: "Currículo",
            taskName: "Nome da tarefa",
            taskType: "Tipo de tarefa",
            score: "Pontuação",
            scoringResults: "Resultado da pontuação",
            time: "Tempo",
            courseClassification: "Classificação do curso",
            whole: "Todos",
            learningTime: "Tempo de estudo",
            historicalLearningData: "Dados históricos de aprendizagem",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Pratique uma pausa",
              lastProgress: "Último progresso",
              continuePracticing: "Continue praticando",
              practice: "Exercícios",
              startPracticing: "Comece a praticar",
              historicalData: "Dados históricos",
              courseIntroduction: "Introdução ao curso:",
              exerciseDifficulty: "Dificuldade de prática:",
              practiceCompletionRequirements:
                "Requisitos de conclusão do exercício:",
              practiceFrequency: "Número de exercícios ≥",
              second: "Vezes",
              anyScore: "Pontuação ≥ de qualquer vez",
              branch: "Pontos",
              or: "Ou",
              taskList: "Lista de tarefas",
              practiceTime: "Tempo de prática:",
              to: "Para",
              unlimitedTime: "Tempo ilimitado",
              completionStatus: "Estado de conclusão",
              numberOfExercises: "Número de exercícios",
              maximumScore: "Maior pontuação",
              operation: "Operação",
              enteringLearning: "Acesso à aprendizagem",
              historicalLearningData: "Dados históricos de aprendizagem",
              wrongQuestionSet: "Conjunto de perguntas erradas",
            },
          },
          index: {
            practiceTasks: "Tarefas de prática",
            examTasks: "Tarefas de exame",
            task: "Missão",
          },
          learn: {
            index: {
              courseName: "Nome do curso",
              courseIntroduction: "Introdução ao curso",
              learningContent: "Conteúdo de aprendizagem",
              downloadLearningMaterials: "Download de material de aprendizagem",
              startPracticing: "Comece a praticar",
            },
          },
          test: {
            index: {
              taskList: "Lista de tarefas",
              courseIntroduction: "Introdução ao curso:",
              examDifficulty: "Dificuldade do exame:",
              requirementsForPassingTheExam:
                "Requisitos de aprovação do exame:",
              examScore: "Pontuação do exame ≥",
              branch: "Pontos",
              examTime: "Tempo de exame:",
              to: "Para",
              unlimitedTime: "Tempo ilimitado",
              completionStatus: "Estado de conclusão",
              completionTimes: "Número de conclusão",
              maximumScore: "Maior pontuação",
              operation: "Operação",
              immediateExam: "Exame imediato",
              history: "História",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Referências" } },
              flow: {
                index: {
                  wrongQuestionItem: "Item errado (",
                  score: "Pontuação",
                  TotalScore: "/Total de pontos",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Erro no servidor" } },
                  index: {
                    startRecording: "Começar a gravar",
                    endRecording: "Fim da gravação",
                    startPracticing: "Comece a praticar",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Por favor, insira",
                    sendOut: "Enviar",
                    startPracticing: "Comece a praticar",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Começar a gravar",
                    endRecording: "Fim da gravação",
                    startPracticing: "Comece a praticar",
                  },
                  hook: { index: { failInSend: "Falha ao enviar" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Pratique pontuação para esta pergunta errada",
                  tips: "Dicas",
                  areYouSureToEnd: "Confirmar o fim?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Pratique novamente sem dominar a pergunta errada",
                  continue: "Continue",
                  returnToTheSetOfIncorrectQuestions:
                    "Retornar o conjunto de perguntas erradas",
                  end: "Fim",
                  wrongQuestionItem: "Item errado",
                  numberOfExercisesThisTime: "Número de exercícios",
                  masteringTheSituation: "Domine a situação",
                  mastered: "Dominar",
                  notMastered: "Não dominado",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Pratique esta questão novamente",
                  nextQuestion: "A próxima pergunta",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Exercícios de perguntas erradas",
                endExercise: "Exercícios de fim",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Exercícios de perguntas erradas",
                endExercise: "Exercícios de fim",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Exercícios de perguntas erradas",
                endExercise: "Exercícios de fim",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Veja a técnica",
                  section: "No.",
                  secondaryDiscourseTechnique: "Segunda conversa",
                },
              },
              tableItem: {
                count: { second: "Vezes" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Nenhuma informação relevante foi consultada",
                  recallWords: "Palavra de recall",
                  branch: "Pontos",
                  answerIncorrectly: "Resposta errada",
                  second: "Vezes",
                  wrongAnswerRate: "Taxa de resposta errada",
                },
                standardScript: {
                  standardScript: "A técnica padrão",
                  myScript: "Minhas palavras",
                },
                status: { mastered: "Dominar", notMastered: "Não dominado" },
                index: {
                  totalNumberOfQuestionsAnswered: "Número total de respostas",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "O número de vezes que as perguntas erradas foram praticadas",
                },
              },
            },
            index: {
              wrongQuestionList: "Lista de perguntas erradas",
              wrongQuestionSet: "Conjunto de perguntas erradas",
              mispracticeExercises: "Exercícios de perguntas erradas",
              taskName: "Nome da tarefa",
              staff: "Funcionários",
              masteringTheSituation: "Domine a situação",
              whole: "Todos",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Número de exercícios ≥",
              second: "Vezes",
              or: "Ou",
              excellentScoreAtAnyOneTime:
                "Excelente pontuação em qualquer ocasião (≥",
              points: "Pontos)",
              examScore: "Pontuação do exame ≥",
              branch: "Pontos",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Alocação de média aleatória",
              assignToDesignatedPersonnel: "Atribuído a pessoas designadas",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Aumentar o número de exames com sucesso",
              failedToIncreaseTheNumberOfExams:
                "Aumentar o número de falhas nos exames",
              increaseTheNumberOfExams: "Aumentar o número de exames",
              selectObjects: "Selecionar objetos",
            },
          },
          executionFilter: {
            index: { participationRate: "Taxa de participação" },
          },
          intelligentReview: {
            index: {
              overallReview: "Comentários gerais",
              pleaseEnterTheReviewCriterianotMandatory:
                "Por favor, insira o critério de revisão (não obrigatório)",
              commentaryOnSingleSentenceRhetoric: "Comentários de uma frase",
              realTimeReview: "Comentários em tempo real",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Ponta média do exame",
            averageExamDurationmin: "Média de tempo de exame (min)",
            accumulatedPracticeDurationmin:
              "A duração do exercício cumulativo (min)",
            accumulatedPracticeTimes: "Número de exercícios acumulados",
            totalScore: "Pontuação total",
            average: "Pontuação média",
            total: "Total",
            keyHitCount: "Pontos-chave Número de acertos",
            practiceAverageScore: "Média dos exercícios",
            averagePracticeDurationmin: "Média de tempo de prática (min)",
            topOfTheHighestScore: "Maior pontuação Top3",
            branch: "Pontos",
            errorProneItemTop: "Top5 de itens propensos a erros",
            hitRate: "Taxa de acerto",
            hitRateOfKeyPointsInScript: "Pontos-chave da conversa",
            averageScriptScore: "Pontuação média de palavras",
            sortByProcess: "Ordenação por processo",
            sortByScore: "Ordenação por pontuação",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Pontuação média da página/pontuação total da página",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Esta pontuação média/esta pontuação total",
            details: "Detalhes",
            viewRules: "Veja as regras",
            standardScript: "A técnica padrão",
          },
          scoreDetailList: {
            section: "No.",
            second: "Vezes",
            practice: "Exercícios",
            examination: "Exame",
            notMeetingStandards: "Não atingiu o padrão",
            meetingStandards: "Padrões",
            excellent: "Excelente",
            frequency: "Número de vezes",
            fraction: "Pontuação",
            result: "Resultados",
            operation: "Operação",
            viewDetails: "Ver detalhes",
          },
          index: {
            common: "O total",
            people: "Pessoas",
            staff: "Funcionários",
            notMeetingStandards: "Não atingiu o padrão",
            meetingStandards: "Padrões",
            excellent: "Excelente",
            notStarted: "Não começou",
            haveInHand: "Em andamento",
            completed: "Concluído",
            passed: "Foi aprovado",
            hangInTheAir: "Não concluído",
            failed: "Não passou",
            unlimitedTime: "Tempo ilimitado",
            to: "Para",
            maximumScoringCriteria: "Critérios de pontuação mais altos",
            completionStatus: "Estado de conclusão",
            employeeName: "Nome do funcionário",
            frequency: "Número de vezes",
            practice: "Exercícios",
            examination: "Exame",
            average: "Pontuação média",
            speechCraftAvgScore: "Pontuação média de palavras",
            emotionAvgScore: "Média de pontos de humor",
            speedAvgScore: "Média de pontuação expressa",
            flowAvgScore: "Pontuação média do processo",
            practiceCostTime: "Dduração cumulativa",
            participateCommitRate: "Taxa de conformidade",
            participateExcellentRate: "Taxa de excelência",
            duration: "Dduração",
            score: "Pontuação",
            scoringResults: "Resultado da pontuação",
            flowScore: "Pontuação do processo",
            craftScore: "Pontuação de palavras",
            emotionScore: "Pontuação emocional",
            speedScore: "Pontuação de expressão",
            contentIntegrity: "Completo de Conteúdo",
            pointHitRate: "Pontos-chave taxa de acerto",
            emotionNegativeRate: "A proporção negativa de emoções",
            paraPhaSiaRate: "Taxa de velocidade de fala anormal",
            toneOvermuchRate:
              "Muitas palavras de tom representam uma proporção",
            maximumScore: "Maior pontuação",
            highestScoreResult: "Maior pontuação resultado",
            state: "Estado",
            averageDurationmin: "Dduração média (min)",
            lately: "Recentemente",
            time: "Tempo",
            operation: "Operação",
            viewDetails: "Ver detalhes",
            wrongQuestionSet: "Conjunto de perguntas erradas",
            taskName: "Nome da tarefa",
            completeRequirements: "Requisitos de conclusão",
            publishTo: "Publicar objetos",
            taskTime: "Tempo de missão",
            fractionalComposition: "Composição fracionária",
            totalScore: "Pontuação total",
            branch: "Pontos",
            Script: "(Tese",
            scoreEmotion: "Emoções divididas",
            fractionExpression: "Expressão dividida",
            points: "Pontos)",
            implementationRate: "Taxa de participação",
            numberOfExecutors: "Número de participantes",
            numberOfPeopleNotExecuted: "Número de não participantes",
            completionRate: "Taxa de conclusão",
            numberOfCompletedPersonnel: "Número de pessoas concluídas",
            numberOfUnfinishedPersonnel: "Número de inacabados",
            complianceRate: "Taxa de conformidade",
            numberOfQualifiedPersonnel:
              "Número de pessoas que atendem ao padrão",
            numberOfNonCompliantIndividuals:
              "Número de pessoas que não atendem aos padrões",
            excellentRate: "Taxa de excelência",
            participateCompleteRate: "Taxa de conclusão de participação",
            numberOfOutstandingIndividuals: "Excelente número de pessoas",
            numberOfNonOutstandingIndividuals: "Número não excelente",
            export: "Exportar",
            taskData: "Dados da tarefa",
            resultAnalysis: "Análise dos resultados",
            scoreDetails: "Detalhes da pontuação-",
            dataOverview: "Visão geral dos dados",
            taskDetail: "Detalhes da tarefa",
            scriptAnalysis: "Análise da conversa",
            flowAnalysis: "Análise de processos",
            expressionAnalysis: "Análise de expressão",
            emotionAnalysis: "Análise de sentimentos",
            byTime: "Visualização de dimensão de tempo",
            byNumber: "Visualização de dimensão de número",
            personData: "Estatísticas por pessoal",
            numberData: "Detalhes do registro por tempo",
            count: "Número de vezes",
            person: "Por número de pessoas",
            reachTimes: "Número de toques",
            outOfReachTimes: "Número de vezes não atingido",
            seeOutOfReach: "Clique para ver não atingiu",
            accumulative: "Acumulado",
            script: "A técnica da fala",
            times: "Vezes",
            sentence: "Frase",
            expressionAverage: "Média de expressão",
            modalAnalysis: "Análise de palavras de tom",
            total: "O total",
            muchModalAnalysis: "Existem muitas palavras de tom",
            normal: "Normal",
            speedAnalysis: "Análise da velocidade da fala",
            speedDistribution: "Distribuição da velocidade da fala",
            speakingSpeed: "Velocidade de fala",
            normalSpeak: "Velocidade normal da fala",
            tooFastSpeak: "Muito rápido",
            tooSlowlySpeak: "Muito lento",
            violationWord: "Palavras de violação",
            violationWordAnalysis: "Análise de palavras ilegais",
            noData: "Não.",
            emotionalScore: "Pontuação emocional",
            emotionalDistribution: "Distribuição de emoções",
            negativeEmotion: "Emoções negativas",
            normalEmotion: "Emoções normais",
            getDetail: "Ver detalhes",
            barPattern: "Vista do gráfico de barras",
            timeDimension: "Vista de latitude de tempo",
            timesDimension: "Visualização de dimensão de número",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Pontuação média",
                  averageScoreOfScript: "Pontos médios na fala",
                  averageEmotionalScore: "Média emocional",
                  expressionAverageScore: "Média de pontuação expressa",
                  processAverageScore: "Média de processos",
                  accumulatedDuration: "Dduração da acumulação",
                  accumulatedTimes: "Número acumulado de vezes",
                  participationComplianceRate:
                    "Taxa de conformidade de participação",
                  participationRateForAchievingExcellence:
                    "Participação na taxa de excelência",
                  branch: "Pontos",
                  numberOfPeople: "Número de pessoas",
                  averageScoreOfExpression: "Média de expressão",
                  people: "Pessoas",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Emoções negativas" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Pontuação média de palavras",
                  branch: "Pontos",
                },
                useSummaryDetail: { keyHitRate: "Pontos-chave taxa de acerto" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Comentários inteligentes",
            pleaseImproveTheConfiguration: "Por favor, melhore a configuração",
            examResults: "Resultado do exame",
            showTheResultsAfterEachExam: "Mostre os resultados após cada exame",
            showTheResultsAfterTheLastExam:
              "Mostre os resultados após o último exame",
            doNotDisplayResults: "Não mostrar os resultados",
            resultDisplay: "Demonstrações de resultados",
            score: "Pontuação",
            scoringResults: "Resultado da pontuação",
            allowViewingScoreDetails: "Permitir ver detalhes da pontuação",
            viewCanvasConfiguration: "Ver a configuração da tela",
            synchronizationFailedProcessNodeHasChanged:
              "A sincronização falhou e o nó do processo produziu alterações",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Sincronizar com sucesso, clique em Publicar para atualizar o curso",
            retrievedTheLatestCanvasConfiguration:
              "A configuração mais recente da tela foi puxada",
            synchronizeCanvasConfiguration:
              "Configuração de tela de sincronização",
            scoringMethod: "Método de classificação",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Pontos, processo {0} pontos, habilidades de fala {1} pontos, emoções {2} pontos, velocidade de fala {3} pontos)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Padrões excelentes devem ser superiores aos padrões",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Os critérios de pontuação precisam satisfazer mais do que 0%, menos do que igual a 100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Os critérios de pontuação precisam atender a mais de 0 pontos, menos ou igual a {0} pontos",
            doRatingsRequireManualReview:
              "Se a pontuação precisa de revisão manual",
            allocationStrategy: "Estratégia de distribuição",
            pleaseSelectTheAssignedPersonnel:
              "Por favor, escolha o pessoal designado",
            pleaseSelectADesignatedPerson:
              "Por favor, escolha a pessoa designada",
            timeLimitForSingleQuestionAnswering:
              "Limite de duração para uma única pergunta",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Postar por padrão para novos funcionários adicionados",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "A reclassificação significa que será correspondida de acordo com as regras de pontuação da tela mais recente. Existem dados de pontuação revisados manualmente na tarefa atual. Se você deseja cobrir a pontuação",
            notCovered: "Não coberto",
            cover: "Cobertura",
            participationSituation: "Participação",
            numberOfParticipants: "Número de participantes",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "O número de pessoas selecionadas para praticar esta tarefa",
            sumOfTheNumberOfParticipantsInTheTask:
              "A soma do número de pessoas que participaram da missão",
            numberOfCompletedPersonnel: "Número de pessoas concluídas",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "O número de pessoas que atendem aos requisitos de conclusão da tarefa",
            numberOfQualifiedPersonnel:
              "Número de pessoas que atendem ao padrão",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "O número de pessoas que atendem aos padrões de pontuação da tarefa é a soma",
            excellentPersonnel: "Excelente número de pessoas",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "O número de pessoas que satisfazem a pontuação da tarefa é excelente",
            participationRate: "Taxa de participação",
            numberOfParticipantsNumberOfParticipants:
              "O número de participantes/o número de participantes",
            numberOfCompletedNumberOfParticipants:
              "Número de pessoas concluídas/participantes",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Número de pessoas que atendem ao padrão/número de participantes",
            excellentNumbernumberOfParticipants:
              "Excelentes números/participantes",
            participationCompletionRate: "Taxa de conclusão de participação",
            numberOfCompletedParticipated:
              "Número de pessoas concluídas/participantes",
            participationComplianceRate: "Taxa de conformidade de participação",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Número de pessoas/participantes",
            participationRateForAchievingExcellence:
              "Participação na taxa de excelência",
            accumulatedDuration: "Dduração da acumulação",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Os alunos participam da tarefa e buscam a paz",
            averageDuration: "Dduração média",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "O tempo de participação dos alunos na tarefa soma/número total de participação",
            accumulatedTimes: "Número de acumulações",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "O número de vezes que os alunos participam das tarefas",
            averageScore: "Pontuação média",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Pontuação média dos envolvidos na missão",
            maximumScore: "Maior pontuação",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Pontuação máxima de pessoas envolvidas na missão",
            averageScoreOfScript: "Pontos médios na fala",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Pontuação de pontuação do aluno/número total de participação",
            totalScoreOfScript: "Pontuação total",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Defina a pontuação total da técnica de palavras nesta tarefa",
            processAverageScore: "Média de processos",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Pontuação do processo do aluno/número total de participação",
            totalProcessScore: "Pontuação total do processo",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Pontuação total do processo definida nesta tarefa",
            averageEmotionalScore: "Média emocional",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Pontuação emocional do aluno/número total de participação",
            totalEmotionalScore: "Pontuação total de emoções",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Pontuação total de emoções definidas sob esta tarefa",
            averageScoreOfExpression: "Média de expressão",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Os alunos expressam pontuação e soma/participação total",
            totalScoreOfExpression: "Expresse a pontuação total",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Pontuação total de expressão definida sob esta tarefa",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Comece a executar a reclassificação, vá para o Centro de Tarefas para ver o progresso",
            reRating: "Reclassificação",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "A reclassificação indica que a correspondência será feita de acordo com as regras de pontuação da tela mais recente, o resultado da pontuação atual será coberto",
            releaseTime: "Tempo de lançamento",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Auditado",
              pendingReview: "A ser revisado",
              enterKeywordSearch: "Pesquisa de palavras-chave de entrada",
            },
            pageRightTable: {
              index: {
                employeeName: "Nome do funcionário",
                systemRating: "Classificação do sistema",
                scoringResults: "Resultado da pontuação",
                duration: "Dduração",
                time: "Tempo",
                state: "Estado",
                whetherToModifyTheRating: "Se deve corrigir a pontuação",
                finalScore: "Pontuação final",
                reviewedBy: "Auditores",
                operation: "Operação",
                viewScoreDetails: "Ver detalhes da pontuação",
                viewStatus: "Ver o estado",
                whole: "Todos",
                correctionStatus: "Status de correção",
                completeReview: "Conclusão da auditoria",
                tips: "Dicas",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "A pontuação final do aluno está sujeita aos resultados da revisão final atual, por favor confirme",
              },
            },
          },
          index: { manualReview: "Auditoria manual" },
        },
        review: {
          index: {
            employeeName: "Nome do funcionário",
            score: "Pontuação",
            scoringResults: "Resultado da pontuação",
            duration: "Dduração",
            taskName: "Nome da tarefa",
            numberOfAppealScripts: "Número de recursos",
            appealTime: "Tempo de reclamação",
            processStatus: "Estado do processo",
            scoreAfterReview: "Pontuação após revisão",
            reviewedBy: "O revisador",
            operation: "Operação",
            viewScoreStatus: "Veja a situação da pontuação",
            whole: "Todos",
            manualReview: "Revisão manual",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Pratique os dados da tarefa",
            implementationRate: "Taxa de execução",
            numberOfExecutors: "Número de execuções",
            numberOfPeopleNotExecuted: "Número de pessoas não executadas",
            completionRate: "Taxa de conclusão",
            numberOfCompletedPersonnel: "Número de pessoas concluídas",
            numberOfUnfinishedPersonnel: "Número de inacabados",
            complianceRate: "Taxa de conformidade",
            numberOfQualifiedPersonnel:
              "Número de pessoas que atendem ao padrão",
            numberOfNonCompliantIndividuals:
              "Número de pessoas que não atendem aos padrões",
            excellentRate: "Taxa de excelência",
            numberOfOutstandingIndividuals: "Excelente número de pessoas",
            numberOfNonOutstandingIndividuals: "Número não excelente",
            examTaskData: "Dados da tarefa do exame",
            published: "Publicado",
            courses: "Um curso",
            courseData: "Dados do curso",
            practiceTasks: "Tarefas de prática",
            examTasks: "Tarefas de exame",
          },
          practiceSummary: {
            practiceSummary: "Exercício de resumo",
            numberOfPracticeTasks: "Pratique o número de tarefas",
            numberOfExercises: "Número de exercícios",
            accumulatedPracticeDuration: "Dduração da prática cumulativa",
            complianceRate: "Taxa de conformidade",
            excellentRate: "Taxa de excelência",
            ranking: "Ranking",
          },
          rankingList: {
            numberOfExercises: "Número de exercícios",
            exerciseDuration: "Dduração do exercício",
            complianceRate: "Taxa de conformidade",
            excellentRate: "Taxa de excelência",
            diligenceChart: "Lista de diligência",
            excellentList: "Lista excelente",
            ranking: "Classificação",
            fullName: "Nome",
            i: "Eu",
          },
          taskCard: {
            end: "Prazo:",
            noDeadline: "Sem prazo",
            second: "Vezes",
            branch: "Pontos",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Nenhuma tarefa",
            completed: "Concluído",
          },
          filter: { employeeDepartment: "Departamento de funcionários" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Exportar com sucesso, consulte o Centro de Tarefas",
              export: "Exportar",
            },
          },
          filterConfig: {
            dropdown: { custom: "Personalização" },
            modal: {
              updateSuccessful: "A atualização foi bem-sucedida",
              successfullySaved: "Salve o sucesso",
              setAsACommonReport: "Definir como um relatório comum",
              updateCurrentReport: "Atualizar o relatório atual",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Pontuação média",
                  averageScoreOfScript: "Pontuação média de palavras",
                  averageEmotionalScore: "Média de pontos de humor",
                  expressionAverageScore: "Média de pontuação expressa",
                  processAverageScore: "Pontuação média do processo",
                  branch: "Pontos",
                },
              },
              comprehensiveEvaluation: { const: { data: "Dados" } },
              doneStatus: {
                index: {
                  practice: "Exercícios",
                  examination: "Exame",
                  executed: "Execução",
                  unexecuted: "Não executado",
                  accumulatedExamDuration: "Dduração do exame cumulativo",
                  examFrequency: "Número de exames",
                },
              },
              filter: {
                index: {
                  practice: "Exercícios",
                  examination: "Exame",
                  pleaseCompleteTheRequiredFields:
                    "Por favor, aperfeiçoe os itens obrigatórios",
                  staff: "Funcionários",
                  taskType: "Tipo de tarefa",
                  pleaseSelect: "Por favor, escolha",
                  taskName: "Nome da tarefa",
                },
              },
            },
            index: {
              completionStatus: "Situação de conclusão",
              comprehensiveEvaluation: "Avaliação abrangente",
              abilityGrowthTrend: "Tendências de crescimento de capacidade",
              errorProneAnalysis: "Análise de pontos de erro",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Número de pessoas ativas",
            accumulatedPracticeDuration: "Dduração da prática cumulativa",
            averagePracticeDuration: "Média de tempo de prática",
            people: "Pessoas",
            numberOfPracticeTasks: "Pratique o número de tarefas",
            numberOfExercises: "Número de exercícios",
            exerciseDuration: "Dduração do exercício",
            second: "Vezes",
            avgScore: "Pontuação média",
            speechCraftAvgScore: "Pontuação média de palavras",
            emotionAvgScore: "Média de pontos de humor",
            speedAvgScore: "Média de pontuação expressa",
            flowAvgScore: "Pontuação média do processo",
            practiceCostTime: "Dduração cumulativa",
            totalPracticeTimes: "Número acumulado de vezes",
            participateCommitRate: "Taxa de conformidade de participação",
            participateExcellentRate: "Participação na taxa de excelência",
          },
        },
        index: {
          employeeProfile: "Retratos de funcionários",
          overview: "Visão geral",
          personalSignage: "Kanban pessoal",
          ongoingExercises: "Exercícios em andamento",
          ongoingExams: "Exame em andamento",
          practiceTaskProgress: "Pratique o progresso da missão",
          examTaskProgress: "Progresso da tarefa do exame",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord:
                "Por favor, escolha a palavra de conceito",
              sessionDetectionSettings: "Configurações de detecção de sessão",
              successfullySaved: "Salve o sucesso",
              abnormalSpeechSpeed: "Velocidade de fala anormal",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Suporta padrões de detecção de velocidade de fala personalizados, e o resultado será julgado como velocidade de fala normal, velocidade de fala muito rápida e velocidade de fala muito lenta. Quando a velocidade da fala é anormal (muito rápido/muito lento), os pontos serão reduzidos de acordo com as regras de pontuação",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Velocidade de fala muito rápida: maior que por minuto",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Uma palavra, velocidade de fala muito lenta: menos que por minuto",
              charactersIgnoringLessThan: "Palavra, ignorar menos do que",
              aSentenceOfWords: "Palavra da frase",
              excessiveModalParticles: "Muitas palavras de tom",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Suporta palavras de tom personalizadas e define regras de excesso de palavras de tom. Quando há muitas palavras de tom, a pontuação será reduzida de acordo com as regras de pontuação",
              theNumberOfRepetitionsIs: "O número de repetições é",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "Na segunda vez, é julgado que há muitas palavras de tom",
              definitionOfSensitiveViolations: "Definição de violação sensível",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Suporta um dicionário de sinônimos ilegais personalizado para detectar se há uma palavra ilegal em uma frase. Quando uma palavra de violação aparece, a pontuação é reduzida de acordo com as regras de pontuação",
              violationWords: "Palavras de violação:",
              emotionalDetection: "Detecção de emoções",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "A detecção emocional é realizada de acordo com a técnica de diálogo do modelo emocional do sistema, e os resultados serão julgados como emoções normais e emoções negativas (incluindo negativas, raiva e reclamações). Quando há uma direção emocional negativa, a pontuação será reduzida de acordo com as regras de pontuação",
              preserve: "Salvar",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Dimensão de pontuação",
              ratingPoints: "Pontos de pontuação",
              explain: "Descrição",
              type: "Tipo",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Dados aleatórios" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Acumulação padrão',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Uma palavra, o número de palavras que os alunos seguem",
                  aWordspan: "Palavra </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Ms </span><br /><span style = "margin-left: 8px">· Velocidade de fala muito lenta',
                  timesspan: "Vezes </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Muitas palavras de tom',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "A pontuação é" },
                  item: {
                    violationOnceReduced: "Redução de 1 violação",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Velocidade de fala anormal (incluindo velocidade de fala muito rápida, velocidade de fala muito lenta) 1 redução",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Muitas palavras de tom são reduzidas uma vez",
                    oneNegativeDecreaseInEmotions:
                      "A direção negativa é reduzida 1 vez",
                    branch: "Pontos",
                  },
                  wrap: { name: "Nome", preserve: "Salvar" },
                },
              },
              index: {
                complete: "Conteúdo completo",
                keyHit: "Ponto de acerto",
                accurateFollowupReading: "Siga a leitura com precisão",
                languageNorm: "Especificação de linguagem",
                speechSpeed: "Velocidade da fala",
                modalParticles: "Palavras de tom",
                emotionallyPositive: "Emoção positiva",
                scoringModel: "Modelos de pontuação",
                successfullyModified: "Modificações bem-sucedidas",
                newSuccessfullyCreated: "Novo sucesso",
              },
            },
            index: {
              scoringModel: "Modelos de pontuação",
              newModel: "Novo modelo",
              model: "Modelos",
              type: "Tipo",
              operation: "Operação",
              preview: "Pré-visualização",
              edit: "Editar",
            },
          },
          index: {
            scoringModel: "Modelos de pontuação",
            ratingDimension: "Dimensão de pontuação",
            sessionDetectionSettings: "Configurações de detecção de sessão",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Por favor, escolha",
                hit: "Hit",
                misses: "Não atingido",
              },
            },
          },
          index: {
            duration: "Dduração",
            processScore: "Pontuação do processo",
            branch: "Pontos",
            contentCompleteness: "Completo de Conteúdo",
            scriptScore: "Pontuação de palavras",
            keyHitRate: "Pontos-chave taxa de acerto",
            emotionalScore: "Pontuação emocional",
            negativeProportionOfEmotions: "A proporção negativa de emoções",
            expressionScore: "Pontuação de expressão",
            theProportionOfAbnormalSpeechSpeed:
              "Taxa de velocidade de fala anormal",
            excessiveProportionOfModalParticles:
              "Muitas palavras de tom representam uma proporção",
            learningRecordData: "Dados de registro de aprendizagem",
            staff: "Funcionários",
            curriculum: "Currículo",
            mainPoints: "Pontos-chave",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Formulários",
                        lineChart: "Gráfico de linhas",
                        barChart: "Gráfico de colunas",
                        pieChart: "Gráfico de pizza",
                      },
                      index: { switchingTypes: "Tipo de comutação" },
                    },
                  },
                  index: {
                    saveSuccessful: "Salve o sucesso",
                    editChart: "Editar gráficos",
                    chartName: "Nome do gráfico",
                    deleteSuccessful: "Exclusão bem sucedida",
                    refresh: "Atualizar",
                    export: "Exportar",
                    areYouSureToDeleteIt: "Tá certo de excluir?",
                    delete: "Excluir",
                    viewFilteringCriteria: "Ver condições de filtro",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Exportar com sucesso, consulte o Centro de Tarefas",
              },
            },
            header: {
              index: {
                saveSuccessful: "Salve o sucesso",
                deleteSuccessful: "Exclusão bem sucedida",
                editReport: "Editar um relatório",
                reportName: "Nome do relatório",
                refresh: "Atualizar",
                areYouSureToDeleteIt: "Tá certo de excluir?",
                delete: "Excluir",
                exportToExcel: "Exportar Excel",
                exportSnapshot: "Exportar instantâneos",
                export: "Exportar",
                authority: "Permissões",
                permissionSettings: "Configurações de permissões",
                reportVisiblePermissions: "Permissões visíveis no relatório",
                visibleToEveryone: "Todos visíveis",
                onlyVisibleToOneself: "Só visível para si mesmo",
                designatedPersonnelVisible: "Pessoas designadas visíveis",
                pleaseSelect: "Por favor, escolha",
                reportDataPermissions: "Permissões de dados do relatório",
                restrictedByUserPermissions:
                  "Limitado por permissões de usuário",
                visiblePeopleAreTheSame: "Pessoas visíveis são as mesmas",
              },
            },
            titleFooter: {
              index: {
                addReport: "Novo relatório",
                reportName: "Nome do relatório",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Exportar com sucesso, consulte o Centro de Tarefas",
            dataQueryInProgress: "Na consulta de dados...",
            customKanban: "Kanban personalizado",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Formulários",
                lineChart: "Gráfico de linhas",
                barChart: "Gráfico de colunas",
                pieChart: "Gráfico de pizza",
              },
              index: {
                successfullyAdded: "Adição de sucesso",
                viewSQL: "Ver sql",
                export: "Exportar",
                addToReport: "Adicionar ao relatório",
              },
            },
            refer: {
              hooks: {
                data: "Dados",
                interrogativeSentence: "Perguntas",
                historicalQuery: "Consulta histórica",
              },
              index: {
                copySuccessful: "Replicação bem sucedida",
                copy: "Reprodução",
              },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Não atingiu o padrão",
                        meetingStandards: "Padrões",
                        excellent: "Excelente",
                        notStartedYet: "Não começou",
                        inProgress: "Em andamento",
                        completed: "Concluído",
                        incomplete: "Não concluído",
                      },
                    },
                  },
                  const: {
                    taskName: "Nome da tarefa",
                    department: "Departamento",
                    studentName: "Nome do aluno",
                    scoringResults: "Resultado da pontuação",
                    taskCompletionStatus: "Status de conclusão da tarefa",
                  },
                  index: { pleaseSelectADimension: "Escolha uma dimensão" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Digite @ para abrir a caixa para seleção de dimensão",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Insira a instrução de consulta diretamente para a consulta",
                query: "Inquérito",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Exportar com sucesso, consulte o Centro de Tarefas",
            dataQueryInProgress: "Na consulta de dados...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Pesquisa de palavras-chave",
                previous: "Anterior",
                next: "Próximo",
                theObjectThatTriggersTheScrollingEventIs:
                  "O objeto que aciona um evento de rolagem é",
                userScrolling: "Rolagem do usuário",
              },
            },
            summary: {
              index: {
                reminder: "Prompt:",
                edit: "Editar",
                name: "Nome",
                hintLanguage: "Prompt",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Extração de pontos de vista" },
            },
            outline: {
              index: {
                numberOfKeywords: "Número de palavras-chave",
                speaker: "Falador",
                speechDuration: "A duração da fala",
                proportionOfSpeaking: "Proporção da fala",
                maximumSpeechDuration: "A duração mais longa do discurso",
                numberOfQuestions: "Quantidade de perguntas",
                averageSpeechSpeed: "Velocidade média da fala",
                conversationalActions: "Ação de sessão",
                contentSummary: "Resumo do conteúdo",
                generateSummary: "Gerar um resumo",
                keywordCloud: "Palavras-chave nuvem de palavras",
              },
            },
          },
          index: {
            outline: "Resumo",
            viewpoint: "Ponto de vista",
            contentSummary: "Resumo do conteúdo",
            viewpointExtraction: "Extração de pontos de vista",
            summaryOfKeyPoints: "Resumo dos pontos principais",
            videoAnalysis: "Análise de vídeo",
          },
        },
        list: {
          index: {
            delete: "Excluir",
            uploadSuccessful: "Carregar com sucesso",
            videoList: "Lista de vídeos",
            uploadVideo: "Fazer upload de um vídeo",
            videoName: "Nome do vídeo",
            uploadTime: "Tempo de upload",
            endTime: "Hora do fim",
            analyzeProgress: "Analise o progresso",
            haveInHand: "Em andamento",
            completed: "Concluído",
            fail: "Falha",
            toBegin: "Para começar",
            operation: "Operação",
            see: "Veja",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Adicionar horas de trabalho" },
        },
      },
      index: {
        businessWorkingHours: "Horas de trabalho de negócios",
        newlyBuild: "Novo",
        name: "Nome",
        describe: "Descrição",
        operation: "Operação",
        edit: "Editar",
        delete: "Excluir",
        editBusinessWorkingHours: "Editar o horário de trabalho do negócio",
        newBusinessWorkingHours: "Novo horário de trabalho do negócio",
        workingHours: "Tempo de trabalho",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Extração inteligente",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Confirmou que deseja cancelar? Não será restaurado após o cancelamento",
        },
      },
    },
    accountManagement: {
      securitySetting: "Configurações de segurança",
      accountManagement: "Gestão de contas",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Para",
            switchToIPAddressMode: "Alternar para o modo de endereço IP",
            switchToIPSegmentMode: "Alternar para o modo de segmento IP",
          },
        },
      },
      index: {
        saveSuccessful: "Salve o sucesso",
        pleaseImproveTheConfiguration: "Por favor, melhore a configuração",
        securitySetting: "Configurações de segurança",
        employeeLoginIPRestrictions:
          "Restrições de IP de pouso de funcionários",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Limite o intervalo de IP que os funcionários podem fazer login, e o login não é permitido além do intervalo. Apenas em sites, o app móvel não é restrito e o administrador não é restrito.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Ao adicionar ou modificar, preencha o segmento IP da rede externa (o mesmo segmento C) ou um endereço IP da rede externa fixa.",
        allowLoginIPRange: "Intervalos de IP que permitem o pouso",
        preserve: "Salvar",
      },
    },
    coachAdminEntry: { speechRecognition: "Reconhecimento de voz" },
    billingCenter: {
      template: {
        details: "Detalhes",
        languageType: "Tipo de idioma",
        aSREngine: "Motor ASR",
        voiceQualityQuota: "Quota de qualidade de voz",
        realtimeData: "Dados em tempo real",
        calculatedByDay: "Calculado por dia",
      },
      component: {
        aSRName: "ASR Nome",
        language: "Linguagem",
        remainingQuota: "Cota residual",
        hours: "Horas",
        usedQuota: "Cota usada",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Operação salva com sucesso!",
        deletedSuccessfully: "A remoção foi bem sucedida!",
        pleaseSelectLanguage: "Escolha o idioma",
        pleaseConfigureRules: "Por favor, configure a regra",
        updateSuccessful: "A atualização foi bem sucedida!",
        operationSuccessful: "Operação bem sucedida!",
      },
      template: {
        aSRLanguage: "Linguagem ASR",
        pleaseSelect: "Por favor, escolha",
        model: "Modelos",
        pleaseSelectLanguage: "Escolha o idioma",
        delete: "Excluir",
        newASRModel: "Novo modelo ASR",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Análise de produtos concorrentes" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Filtro de revisão de comércio eletrônico",
        allComments: "Todos os comentários",
        ecommerceReviewManagement: "Gestão de revisão de comércio eletrônico",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Filtro de comentários da mídia social",
        allComments: "Todos os comentários",
        socialMediaReviewManagement: "Gestão de comentários de mídia social",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Análise de produtos concorrentes",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Nome",
        nickname: "Apelido",
        name: "Nome",
        email: "Conta (caixa de correio)",
        mobile: "Telefone",
        agentId: "Número de trabalho",
        position: "Postagens",
        role: "Personagens",
        actions: "Operação",
        createUser: "Novos funcionários",
        editUser: "Editar funcionários",
        account: "Conta",
        originPassword: "Senha inicial",
        newPassword: "Nova senha",
        resumePassword: "Confirmar a senha",
        userGroup: "Grupo de funcionários",
        authorizationGroup: "Grupos de permissões",
        resetPassword: "Redefinir a senha",
      },
      userGroups: {
        id: "ID",
        name: "Nome",
        userCount: "Número de funcionários",
        actions: "Operação",
        createUserGroup: "Novos grupos de funcionários",
        editUserGroup: "Editar grupos de funcionários",
        member: "Membros",
      },
      role: {
        id: "ID",
        name: "Nome",
        actions: "Operação",
        all: "Todos",
        taskName: "Missão",
        readTask: "Visualização de tarefas",
        manageTemplate: "Gerenciamento de modelos",
        manualInspection: "Reinspeção manual",
        readResultAll: "Visualização dos resultados",
        readResultPersonal: "Visualização dos resultados (individual)",
        readProcess: "Verificação do processo de inspeção de qualidade",
        readReport: "Estatísticas e relatórios",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Data",
        taskName: "Nome",
        size: "Tamanho",
        status: "Estado",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Nome",
            remark: "Descrição",
            actions: "Operação",
          },
          checkPoint: {
            name: "Nome",
            remark: "Descrição",
            type: "Tipo",
            gradeType: "Método de avaliação",
            conditionLogic: "Regras",
            predeterminedScore: "Pontos",
            weight: "Peso",
            status: "Estado",
            actions: "Operação",
            switchOn: "Habilitar",
            switchOff: "Desativação",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Tempo",
            customer: "Clientes",
            customerPhone: "Telefone do cliente",
            callType: "Tipo de chamada",
            source: "Fonte",
            voiceRecording: "Gravação de chamadas",
            graded: "Classificação",
            openingGreeting: "Saudações de abertura",
          },
          checkPointList: {
            name: "Ponto de inspeção de qualidade",
            type: "Tipo",
            deductScore: "Pontuação",
            isHit: "Situação de acerto",
            status: "Revisão",
            finalDeductScore: "Dedução de confirmação",
            hit: "Hit",
            notHit: "Não atingido",
          },
        },
      },
    },
    custom: { keywords: "Palavras-chave do diálogo" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Configurações do sistema" },
      systemIntegration: {
        text: "Integração de sistemas",
        subTitle: {
          udesk: "Sistema de atendimento ao cliente Udesk",
          udeskWhite: "Sistema de atendimento ao cliente",
          wechat: "WeChat corporativo",
          taobao: "Taobao Qianniu",
          weifeng: "Weifeng",
        },
      },
      columnsManage: {
        text: "Gerenciamento de campo de inspeção de qualidade",
      },
      customerField: { text: "Campos do cliente" },
      speechRecognition: { text: "Reconhecimento de voz" },
      user: { text: "Gestão de funcionários" },
      userGroups: { text: "Gestão de grupos de funcionários" },
      role: { text: "Gerenciamento de papéis" },
      dataMigration: { text: "Migração de dados" },
      taskCenter: { text: "Centro de tarefas" },
      recordUpload: { text: "Upload de gravação local" },
      anomalousData: { text: "Dados de exceção" },
      systemLog: { text: "Log de mensagens" },
      msgCenter: { text: "Centro de mensagens" },
      wechatDocking: { text: "Micro docagem empresarial" },
    },
    tasks: {
      homePage: { text: "Página inicial" },
      workbench: { text: "Bancada de trabalho" },
      semanticIntelligence: { text: "Inteligência Semântica" },
      intelligentAnalysis: { text: "Análise inteligente" },
      intelligentLabel: { text: "Rótulos inteligentes" },
      admin: { text: "Gestão" },
      callManage: { text: "Gerenciamento de chamadas" },
      dialogueManage: { text: "Gestão de Diálogo" },
      ticketManage: { text: "Gestão da ordem de trabalho" },
      dataAnalysis: { text: "Análise de dados" },
      customerService: { text: "Centro de atendimento ao cliente" },
      clientCenter: { text: "Centro de clientes" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Centro de treinamento" },
      templates: { text: "Gerenciamento de modelos" },
      wechatQaTasks: { text: "Bancada de trabalho do WeChat corporativo" },
      wechatQaSetting: {
        text: "Configuração de análise de WeChat empresarial",
      },
      wechatQaChartRecord: { text: "Sessões de WeChat Corporativo" },
      wechatStaffCenter: { text: "Centro de funcionários de micro empresas" },
      callAnalysis: { text: "Análise de sessão" },
      caseBaseFolder: { text: "Biblioteca de casos" },
      coachTask: { text: "Tarefas de coaching" },
      teamAnalysis: { text: "Análise da equipe" },
      customer: { text: "Centro de clientes" },
      analysisEnable: { text: "Capacitação analítica" },
      analysisHelp: { text: "Análise de ajuda" },
      analysisStudy: { text: "Análise de aprendizagem" },
      analysisReview: { text: "Análise de disco complexo" },
      analysisTags: { text: "Análise inteligente de etiquetas" },
      analysisSentiment: { text: "Análise da opinião pública" },
      analysisBusiness: { text: "Análise de negócios" },
      groupTest: { text: "Teste de agrupamento" },
    },
    navBar: {
      billingCenter: "Centro de Ajuda",
      uploadDownload: "Fazer upload do centro de download",
      setting: "Configurações do sistema",
    },
  },
  fix: {
    selectType: "Tipo de seleção:",
    all: "Todos",
    call: "Chamada",
    text: "Diálogo",
    wechat: "Sessões de WeChat Corporativo",
    businessTrendAnalysis:
      "Análise de tendência de negócios classificada de primeiro nível",
    noEchart: "Gráfico sem dados",
    checkPointisRequired: "A regra é configurada como obrigatória",
    voiceDownLoad: "Baixar gravações",
    exportTxt: "Exportar texto TXT",
    exportWord: "Exportar texto do word",
    sureReRole: "Confirmar o papel do falante invertido?",
    sureRecover: "Confirmar a recorreção de erros?",
    sureReAsr: "Confirmar a reidentificação?",
    reRole: "Inverter o falante",
    recover: "Recorreção de erros",
    reAsr: "Reidentificação",
    recordingOperation: "Operação de gravação",
    onlySave30Day: "(Reservado apenas por 30 dias)",
    appealError:
      "Por favor, faça uma reclamação sobre o ponto de verificação antes de enviar",
    exportToTaskCenter:
      "Exportado com sucesso para o centro de download de upload",
    caseBaseLimit: "O banco de casos não pode exceder 50",
    unnamed: "Sem nome",
    wordSpeed: "Palavra/minuto",
    hit: "Hit",
    checkPoint: "Ponto de inspeção de qualidade",
    realTimeAdditionTip1:
      "Adição em tempo real: a inspeção de qualidade começa imediatamente quando os dados entram no sistema de inspeção de qualidade",
    realTimeAdditionTip2:
      "Adição cronometrada: adicionar dados automaticamente para inspeção de qualidade em intervalos regulares",
    null: "Nenhum",
    collcetedProps: "Campo de aquisição",
    editingNameNow: "Está editando com um nome",
    upToFourLevels: "Classificação até quatro",
    default: "Padrão",
    nameCantBeNull: "O nome não pode estar vazio",
    editingNow: "Há uma operação a ser editada",
    nameError: "O nome está errado",
    nameMaxLength8: "O comprimento máximo do nome é 8",
    callTime: "Tempo de conversação",
    sentimentValue: "Valor do sentimento do cliente",
    agentSentimentValue: "Valor emocional do atendimento ao cliente",
    sentimentAnalysis: "Análise de sentimentos",
    associatedData: "Dados de estrada",
    notifyTheOwner: "Notificar o proprietário do grupo",
    notifyTheEmployee: "Notificar os próprios funcionários",
    notifyTheOwnerTip:
      "O proprietário do grupo será notificado quando o tipo de conversa for um bate-papo em grupo",
    customNotifyEmployee: "Funcionários de notificação personalizada",
    customNotifyEmployeeTip:
      "A pessoa acionada é um funcionário, o funcionário a quem a mensagem é notificada",
    clickToLearnMore: "Clique para saber mais",
    repeatMarkingTip:
      "Este registro foi marcado pelo ponto de inspeção de qualidade, sem marcação repetida",
    successfulEvaluation: "Avaliação do sucesso",
    evaluationFailed: "Falha na avaliação",
    qualityInspectionScore: "Pontuação de inspeção de qualidade",
    semanticTags: "Rótulos semânticos",
    smartTags: "Rótulos inteligentes",
    collect: "Coleta de informações",
    appealRecord: "Registro de recurso",
    satisfy: "Satisfação",
    notSatisfy: "Não satisfeito",
    appealPlaceHolder: "Por favor, insira o motivo da reclamação",
    giveUp: "Desista",
    rejectReasonPlaceHolder: "Insira o motivo da rejeição",
    reject: "Rejida",
    changeRejectReason: "Motivo da rejeição da modificação",
    pass: "Através",
    revise: "Modificações",
    machineEvaluation: "Avaliação da máquina",
    poor: "Má.",
    excellent: "Está bem.",
    moderate: "Geral",
    mustMarked: "O ponto de inspeção de qualidade deve ser marcado",
    pleaseChoose: "Por favor, escolha",
    manualEvaluation: "Avaliação artificial",
    qualityInspectionTemplate: "Modelo de inspeção de qualidade aplicável",
    collectionTemplate: "Modelos de aquisição aplicáveis",
    keywordsWordCloud: "Palavras-chave nuvem de palavras",
    editCategoryLabel: "Editar etiquetas de classificação",
    justCall: "Basta olhar para a chamada",
    justConversation: "Basta olhar para o diálogo",
    batchOperation: "Operação em lote",
    customerServicesGroup: "Grupo de atendimento ao cliente",
    joinGroup: "Junte-se a um grupo",
    removeGroup: "Grupo de remoção",
    enterInformationEntity: "Insira o conteúdo da entidade de informação",
    enterNotBeNull: "A entrada não pode estar vazia",
    operationGroup: "Grupo de operações",
    customerServiceSelected: "Atendimento ao cliente selecionado",
    callAndConversation: "Chamada/Diálogo",
    conversation: "Diálogo",
    system: "Sistema",
    customize: "Personalização",
    effective: "Eficaz",
    invalid: "Inválido",
    successfulAndToTaskCenter:
      "Execução bem-sucedida, consulte o Centro de Tarefas",
    recalculationFailed: "Falha na recontagem",
    selectRecalculatedIndicator: "Escolha o indicador para recontar",
    source: "Escolha a fonte de dados",
    placeholder: "Digite a palavra-chave",
    cumulative: "Acumulado",
    average: "Tipo médio",
    distribution: "Lei de distribuição",
    originalDataCannotEmpty: "Os dados originais não podem estar vazios",
    inspection: "Inspeção de qualidade",
    check: "Inspeção aleatória",
    review: "Revisão",
    draftSavedSuccessfully: "O rascunho foi salvo com sucesso",
    optimization: "Otimização",
    agentName: "Nome de atendimento ao cliente",
    allTasks: "Todas as tarefas de inspeção de qualidade",
    datePickerSelect: "Escolha uma data",
    callSelect: "Escolha uma conversa",
    taskSelect: "Escolha uma tarefa",
    genReport: "Gerar um relatório",
    dataSource: "Fontes de dados",
    callsTotal: "Total de chamadas",
    intelligentQualityInspection: "Inspeção de qualidade inteligente",
    percentage: "Representação",
    artificialSampling: "Inspeção de amostragem manual",
    appealNumber: "Quantidade de reclamações",
    totalConversation: "Total de diálogo",
    samplingReviewRate: "Taxa de reinspeção",
    data: "Dados",
    inspectionResults: "Resultados de inspeção de qualidade",
    inspected: "Inspeção de qualidade",
    totalScore: "Pontuação total",
    learnMore: "Veja mais",
    inspectionEffect: "Efeito de inspeção de qualidade",
    allTotal: "Total",
    manualInspection: "Reinspeção manual",
    artificialReview: "Revisão manual",
    approvalAmountAndRate: "Quantidade de aprovação & taxa de aprovação",
    customerScore: "Pontuação de atendimento ao cliente",
    inspectionRecommendations: "Recomendações de inspeção de qualidade",
    saveDraft: "Salvar rascunhos",
    historicalAdvice: "Sugestões históricas",
    advicePlaceHolder: "Por favor, insira uma sugestão...",
    upload: "Upload de arquivo",
    customAdd: "Adicionar filtros",
    advancedConditions: "Condições avançadas",
    keyWord: "Palavras-chave",
    containsAll: "Contém todos",
    containsAny: "Contém qualquer",
    matchAny: "Combinar qualquer",
    matchAll: "Combine tudo",
    smartTagsOne: "Etiqueta inteligente um",
    smartTagsTwo: "Etiqueta inteligente II",
    season: "Temporada",
    month: "Mês",
    week: "Semana",
    day: "Dia",
    hour: "Quando",
    notMoreThan366: "O tempo não pode exceder 366 dias",
    propsCannotBeNull: "Existem campos não preenchidos",
    other: "Outros",
    correlationCoefficient: "Coeficiente de correlação",
    repetitionRate: "Taxa de repetição",
    configurationItemsMoreThan20: "Adicionar até 20 itens de configuração",
    conversionAnalysis: "Análise de conversão",
    correlationAnalysis: "Análise de correlação",
    successfulExportAndToTaskCenter:
      "Exportar com sucesso, consulte o Centro de Tarefas",
    typeSelect: "Escolha o tipo",
    exportExcel: "Exportar excel",
    exportPng: "Exportar png",
    noDataChart: "Gráfico sem dados",
    placeOperator: "Por favor, escolha o rótulo inteligente",
    placeAdvancedConditions: "Por favor, escolha condições avançadas",
    createAdvancedConditions: "Nova triagem avançada",
    moveUp: "Mover para cima",
    moveDown: "Mover para baixo",
    moveLeft: "Mover para a esquerda",
    moveRight: "Mudança para a direita",
    statusChangeSuccess: "Modificação de status bem-sucedida",
    editSuccess: "Modificações bem-sucedidas",
    saveSuccess: "Salve o sucesso",
    firstPage: "Página inicial",
    lastPage: "Página final",
    customerInfo: "Informações do cliente",
    type: "Tipo",
    NumberTypeErrorMessage:
      'O conteúdo de informações de campo do tipo digital pode conter apenas números ou "."',
    taskStartPrompt: "Dicas de abertura de tarefas",
    startConfirm:
      "Por favor, clique no botão de confirmação para abrir a tarefa de inspeção de qualidade",
    continueToSubmitWithoutEvaluation: "Continue enviando sem avaliação",
    rejectTip:
      "Nenhuma operação de alteração foi detectada, você confirma a rejeição direta de todas as reclamações?",
    unmarkedSelfLearningTip:
      "Você tem um ponto de autoaprendizagem não marcado",
    markedSuccessfully: "Rotulagem bem-sucedida",
    markingFailed: "Falha na marcação",
    exceedingLength: "Além do comprimento",
    uncategorized: "Não classificado",
    addSuccess: "Novo sucesso",
    deleteSuccess: "Exclusão bem sucedida",
    featureName: "Nome do elemento",
    informationEntity: "Entidades de informação",
    standardAnswer: "Resposta padrão",
    keyElementAnswer: "Os elementos-chave respondem",
    generalStatement: "Declaração geral (sem necessidade de perguntar)",
    generalQuestionsAndConfirmation: "Dúvidas gerais e confirmação",
    specialQuestionsAndAnswers: "Perguntas especiais e respostas",
    agent: "Atendimento ao cliente",
    customer: "Clientes",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Rótulos semânticos de elementos-chave e entidades de informação não podem estar vazios ao mesmo tempo",
    standardSentence: "Sentença padrão",
    acquaintance: "Conhecimento",
    smartUePlaceholder: "Insira o nome da interação inteligente",
    typeSelectPlaceholder: "Por favor, escolha o tipo",
    keyElement: "Elementos-chave",
    reQuerySementicTags: "Selecione novamente os rótulos semânticos",
    querySementicTags: "Escolha um rótulo semântico",
    QATestForm: "Formulário de detecção de perguntas e respostas",
    selectNotBeNull: "A escolha não pode ser vazia",
    featureDetectionTarget: "Objetivo de detecção de elemento",
    categorized: "Classificação",
    effectiveness: "Eficácia",
    hideAdvancedSettings: "Ocultar configurações avançadas",
    showAdvancedSettings: "Mostrar configurações avançadas",
    generalStatementOnly: "Declaração geral",
    generalQuestions: "Perguntas gerais",
    affirmativeExpression: "Expressão afirmativa",
    negativeIntention: "Intenção negativa",
    searchSementicTags: "Rótulos semânticos de consulta",
    choiceQuestion: "Dúvida de escolha",
    selectiveAnswer: "Resposta seletiva",
    sementicTagsPlaceholder: "Insira o conteúdo do rótulo semântico",
    openSpecialQuestion: "Perguntas especiais abertas",
    openAnswer: "Resposta aberta",
    customQA: "Perguntas e respostas personalizadas",
    questions: "Método de questionamento",
    dataPlaceholder: "Por favor, insira o conteúdo",
    reply: "Resposta",
    notFindSuitableLabel: "Não encontrou o rótulo certo",
    addSemanticTag: "Adicionar um novo rótulo semântico",
    intelligentInteraction: "Interação inteligente",
    matching: "Correspondência",
    bout: "Vezes",
    keywordMatch: "Correspondência de palavras-chave",
    addFilterConditions: "Adicionar uma condição de filtro",
    buttonStartCollectionTask:
      "Por favor, clique no botão OK para abrir a tarefa de aquisição",
    buttonStartTask:
      "Por favor, clique no botão OK para abrir a tarefa de inspeção de qualidade",
    ruleConfigurationIsRequired: "A regra é configurada como obrigatória",
    asrRunning: "Execução ASR",
    errorCorrectionExecution: "Execução de correção de erros",
    emotionalVolumeRecognition: "Reconhecimento de volume emocional",
    dataPreprocessing: "Pré-processamento de dados",
    inspectionExecution: "Execução de inspeção de qualidade",
    businessId: "Id de negócios",
    businessTime: "Tempo de negócios",
    createTime: "Tempo de criação",
    abnormalReason: "Causas anormais",
    singleChat: "Um bate-papo",
    groupChat: "Bate-papo em grupo",
    taskContent: "Conteúdo da tarefa",
    submissionTime: "Tempo de envio",
    startTime: "Hora de início",
    endTime: "Hora do fim",
    runningTime: "A operação é demorada",
    totalAmountOfData: "Volume total de dados",
    resultsOfThe: "Resultados da execução",
    operator: "Operador",
    taskDetails: "Detalhes da tarefa",
    executiveFunction: "Função Executiva",
    qualityCheckTasks: "Tarefa de inspeção de qualidade",
    implementationDetails: "Detalhes da execução",
    conditionalRules: "Regras condicionais",
    smartCondition: "Condições inteligentes",
    goThis: "Mover para este",
    pleaseMarkClassification: "Por favor, marque a classificação",
    advancedScreening: "Filtragem avançada",
    noDataToManipulate: "Não há dados que possam ser operados",
    concept: "Palavra de conceito",
    checkPoint1: "Configuração da regra",
    requiredFieldsCannotEmpty: "Os itens obrigatórios não podem estar vazios",
    rule: "Regras",
    afterFixUnit: "Uma palavra",
    unmarkedSelfLearningPointsTip:
      "Você tem um ponto de autoaprendizagem não marcado",
    onlyReservedFor7Days: "(Reservado apenas por 7 dias)",
    downloadError: "Falha no download",
    pause: "Suspensão",
    continue: "Continue",
    recalcScore: "Apenas pontuação (incluindo classificação)",
    recalculatePointsAndScores:
      "Recalculação de pontos de inspeção de qualidade e pontuações",
    recalculatePointsAndScoresWithEmtry:
      "Recalcule o ponto de inspeção de qualidade e a pontuação (o ponto de inspeção de qualidade está vazio)",
    inProgress: "Em andamento",
    toBeExecuted: "A ser executado",
    executeImmediately: "Execução imediata",
    specialElements: "Elementos especiais",
    logicRule: "Regras lógicas",
    every: "Correspondência múltipla",
    hitRecall: "Palavras de recall de sucesso",
    notHitRecall: "Recall de falha",
    hitRecallRegular: "Acertar a correção do recall",
    notHitRecallRegular: "Não acertar a regular",
    hitRule: "Regras de acerto",
    notHitRule: "Regras de falha",
    hitSimilarSentence: "Acertar frases semelhantes",
    notHitSimilarSentence: "Frase semelhante falhou",
    hitExcludeSimilarSentences: "Acertar frases semelhantes",
    notHitExcludeSimilarSentences: "Excluir frases semelhantes",
    updateSuccess: "A atualização foi bem-sucedida",
    qualify: "A regular",
    recallKeyWord: "Palavras-chave de recall",
    grammar: "Regras de gramática",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Falha na mudança de eficácia, tente novamente",
    postAutoMateTasksCaseByTaskId:
      "O salvamento da configuração de sincronização da biblioteca de casos falhou",
    getAutoMateTasksCaseByTaskId:
      "Falha na aquisição da configuração de sincronização da biblioteca de casos",
    postInspectionRemarkModelConfigs: "Falha ao salvar o modelo de observação",
    putInspectionRemarkModelConfigsById:
      "Falha ao salvar o modelo de observação",
    getInspectionRemarkModelConfigsById:
      "Falha na obtenção de dados do modelo de nota",
    postKnowledgeDatas: "Nova base de conhecimento falhou, tente novamente",
    putKnowledgeDatasById:
      "A modificação da base de conhecimento falhou, tente novamente",
    getLibraryFiles: "Falha ao obter a lista de grampos de preço",
    getLibraryCalls: "Falha na obtenção de arquivos",
    getLibraryFilesSearch: "Falha na pesquisa",
    putLibraryFilesMoveById:
      "Falha no movimento do clipe de preço de interrogação",
    putLibraryCallsById: "A renomeação de arquivos falhou!",
    postLibraryFiles: "Falha ao adicionar uma pasta",
    putLibraryFilesById: "Falha na renomeação da pasta",
    deleteLibraryFilesById: "A exclusão falhou",
    deleteLibraryCallsById: "A exclusão falhou",
    postLibraryCalls: "Falha na adição",
    getVoiceUploadResultById: "Falha na exportação dos resultados do upload",
  },
};
