import React from 'react';
import Udesk from 'Udesk';
import { postSmartTagIgnores } from 'src/api/smartTagIgnores';
import { PostType } from 'Component/pages/intelligent-analysis/ignore-label';
import { message } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

class SmartTagsComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        smartTags: [],
    };
    //#endregion

    state = {};
    privates = {
        visible: false,
        closeId: null,
        smartTagsList: [],
    };
    static computes = {};
    actions = {
        samrTagChange(value) {
            this.trigger('treeListVisible', value);
        },
        onClose(smartTag) {
            this.privates.closeId = smartTag.id;
            this.actions.update();
        },
        onCloseVisibleChange(visible) {
            if (!visible) {
                this.actions.onCancel();
            }
        },
        onCancel() {
            this.privates.closeId = null;
            this.actions.update();
        },
        onVisibleChange(visible) {
            this.privates.visible = !this.privates.visible;
            this.actions.update();
        },
        samrTagClose(value) {
            let { sdkOptions, callId, onSamrTagDelete } = this.props;
            if (typeof onSamrTagDelete === 'function') {
                onSamrTagDelete(value);
                return;
            }
            let param = {
                callId,
                tagIds: [value.id],
            };
            let markUrl = Udesk.business.apiPath.concatApiPath(`callTags/remove`, sdkOptions);
            Udesk.ajax.post(markUrl, param).then(
                (resp) => {
                    let newSmartTags = this.props.smartTags.filter((tag) => value.id !== tag.id);
                    this.trigger('onSmartTagsChange', newSmartTags);
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        samrTagAdd(value, option) {
            let { sdkOptions, callId, onSamrTagAdd, selectMode } = this.props;
            if (selectMode !== 'multiple') {
                this.actions.onVisibleChange();
            }
            if (typeof onSamrTagAdd === 'function') {
                onSamrTagAdd(value, option);
                return;
            }
            let param = {
                callId,
                tagIds: [value],
            };
            let markUrl = Udesk.business.apiPath.concatApiPath(`callTags/mark`, sdkOptions);
            Udesk.ajax.post(markUrl, param).then(
                (resp) => {
                    let newSmartTags = [...this.props.smartTags];
                    newSmartTags.push({
                        categoryColor: option.categoryColor,
                        categoryName: option.children,
                        id: option.value,
                        tagType: 1,
                        highlightGroups: [],
                        markType: 1,
                    });
                    this.trigger('onSmartTagsChange', newSmartTags);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },

        onAddToIgnore(tag) {
            let { callId } = this.props;

            postSmartTagIgnores({
                name: tag.categoryName || tag.tagName,
                type: PostType.Backhand,
                tagId: tag.id,
                callId,
            }).then(() => {
                message.success(/* 添加成功 */UdeskLocales['current'].components.smartTags.component.successfullyAdded);
                let newSmartTags = this.props.smartTags.filter((_tag) => tag.id !== _tag.id);
                this.trigger('onSmartTagsChange', newSmartTags);
                this.actions.update();
            });
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        if (props.dataSource) {
            return {
                smartTagsList: props.dataSource,
            };
        }
    }
    componentDidMount() {
        let { sdkOptions, dataSource, disabled } = this.props;
        if (dataSource || disabled) {
            // this.privates.smartTagsList = dataSource;
            // this.actions.update();
            return;
        }
        let smartTagsListUrl = Udesk.business.apiPath.concatApiPath(
            `smartTags/findAll`,
            sdkOptions
        );
        Udesk.ajax
            .post(smartTagsListUrl, { pageNum: 1, pageSize: 1000, tagStatus: 1, tagType: 1 })
            .then(
                (resp) => {
                    if (resp.data) {
                        let smartTagsList = resp.data.filter((i) => !!i.tagStatus);
                        let smartTagsGroupsNameMap = {};
                        let smartTagsGroups = Array.from(
                            new Set(
                                smartTagsList.map((i) => {
                                    !smartTagsGroupsNameMap[`${i.categoryId}`] &&
                                        (smartTagsGroupsNameMap[`${i.categoryId}`] =
                                            i.categoryName);
                                    return i.categoryId;
                                })
                            )
                        );
                        this.privates.smartTagsList = smartTagsGroups.map((i) => ({
                            categoryId: i,
                            categoryName: smartTagsGroupsNameMap[`${i}`],
                            children: smartTagsList.filter((tag) => tag.categoryId === i),
                        }));
                    }
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            this.locales.components.pages.components.recordDetail.getSmartTagsFailed
                    );
                }
            );
    }
    componentWillUnmount() {}
    asyncModel(changedParams) {}
    //#endregion
}

export default SmartTagsComponent;
