import Udesk from 'Udesk';
import React, { useState, useEffect } from 'react';
import TasksAutomaticAddRoute from './route';
import TaskAssessRulesGather from 'Component/pages/components/task-assess-rules-gather';
import TaskConditionGather from 'Component/pages/components/task-condition-gather';
import TaskRuleGather from 'Component/pages/components/task-rule-gather';
import {
    Checkbox,
    Radio,
    Button,
    Alert,
    InputNumber,
    Row,
    Col,
    Select,
    Space,
    FormBuilder,
    Tabs,
    Input,
    Switch,
    Tooltip,
    Icon,
} from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
import './style-new.scss';
// import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
// import FilterModalEdit from './filter-modal';
import UdeskList from 'Component/common/udesk-list';
import MassageConfiguration from 'Component/pages/components/massage-configuration';
import CardLayout from './card-layout';
import AutoAddComponent from 'Component/pages/components/auto-add-component';
import SamplingTarget from 'Component/pages/components/sampling-target';
import Page from 'Component/common/udesk-qa-page-layout';
import { getCaseLibraryCategorys } from 'src/api/caseLibraryCategorys';
import { getCaseLibraryCategorysFromcc } from 'src/api/caseLibraryCategorys/fromcc';
import BusinessSummaryTable from './business-summary';

const CusTabs = (props) => {
    const { isWechatQa, isCollection, isTicket, activeKey } = props;
    const showList = getTabPanes(isWechatQa, isCollection, isTicket).map((i) => i.key);
    return (
        <React.Fragment>
            {React.Children.map(props.children, (child, index) => {
                if (!child) return null;
                if (!showList.includes(child.props.cardId)) return null;
                if (activeKey !== child.props.cardId) return null;
                return child;
            })}
        </React.Fragment>
    );
};

const NotMatchAnyRule = (props) => {
    const {
        title,
        radioValue,
        disabled,
        onRadioChange,
        getUserListUrl,
        selectValue,
        onSelectChange,
    } = props;
    const [options, setOptions] = useState([]);

    const handleSearch = (value) => {
        Udesk.ajax.get(`${getUserListUrl}${value ? `&keyword=${value}` : ''}`).then(
            (resp) => {
                const { data } = resp;
                setOptions((data || []).map((item) => ({ label: item.realname, value: item.id })));
            },
            (reason) => {
                Udesk.ui.notify.error(
                    reason.errorMsg || UdeskLocales.current.business.info.saveError
                );
            }
        );
    };
    useEffect(() => {
        handleSearch();
    }, []);
    return (
        <React.Fragment>
            <div className="automatic-content-item-body-item-title automatic-content-item-body-item-title-screening-condition">
                {title}
            </div>
            <div className="automatic-content-item-body-item-content">
                <div style={{ height: 30, lineHeight: '30px' }}>
                    <Radio.Group value={radioValue} disabled={disabled} onChange={onRadioChange}>
                        <For each="item" index="index" of={Udesk.enums.notMatchAnyRuleTypes}>
                            <Radio key={item.id} value={item.id}>
                                {item.name}
                            </Radio>
                        </For>
                    </Radio.Group>
                </div>
                {radioValue === Udesk.enums.notMatchAnyRuleTypes.assign.id && (
                    <Select
                        mode="multiple"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        value={selectValue || []}
                        onSearch={handleSearch}
                        onChange={onSelectChange}
                        notFoundContent={null}
                        options={options}
                        style={{ width: '100%' }}
                        maxTagCount="responsive"
                    ></Select>
                )}
            </div>
        </React.Fragment>
    );
};

const AutomaticAllocationItem = (props) => {
    const locales = UdeskLocales.current;
    const {
        children,
        title,
        assignModelValue,
        onAssignModelValueChange,
        disabled,
        assignConfList,
        onAssignConfListChange,
        fields,
        users,
        taskId,
        taskName,
        inspectDataSource,
        notMatchValue,
        onNotMatchValueChange,
        notMatchSelectValue,
        onNotMatchSelectValueChange,
        getUserListUrl,
    } = props;
    return (
        <React.Fragment>
            {children}
            <div className="automatic-content-item-body-item">
                <div className="automatic-content-item-body-item-title automatic-content-item-body-item-title-screening-condition">
                    {title}
                    <Tooltip
                        placement="top"
                        title={
                            /* 仅一级复核员分配策略 */ locales.pages.tasks.manage.template
                                .automaticAdd.templateNew
                                .onlyTheFirstLevelReviewerAllocationStrategy
                        }
                    >
                        <Icon type="InfoCircleTwoTone" antdIcon={true} style={{ marginRight: 4 }} />
                    </Tooltip>
                </div>
                <div className="automatic-content-item-body-item-content">
                    <Radio.Group
                        value={assignModelValue}
                        disabled={disabled}
                        onChange={onAssignModelValueChange}
                    >
                        <For each="item" index="index" of={Udesk.enums.distributeTypes}>
                            <Radio key={item.id} value={item.id}>
                                {item.name}
                            </Radio>
                        </For>
                    </Radio.Group>
                </div>
            </div>
            <If condition={assignModelValue === Udesk.enums.distributeTypes.rule.id}>
                <div className="automatic-content-item-body-item">
                    <NotMatchAnyRule
                        title={
                            locales.pages.tasks.manage.template.automaticAdd.index.notMatchAnyRule
                        }
                        radioValue={notMatchValue}
                        disabled={disabled}
                        onRadioChange={onNotMatchValueChange}
                        selectValue={notMatchSelectValue}
                        onSelectChange={onNotMatchSelectValueChange}
                        getUserListUrl={getUserListUrl}
                    />
                </div>
                <div className="automatic-content-item-body-item">
                    <div className="automatic-content-item-body-item-content automatic-content-item-body-item-content-allocation">
                        <TaskRuleGather
                            rules={assignConfList}
                            fields={fields}
                            onChanged={onAssignConfListChange}
                            users={users}
                            taskId={taskId}
                            taskName={taskName}
                            type={inspectDataSource}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </If>
        </React.Fragment>
    );
};

function getTabPanes(isWechatQa, isCollection, isTicket) {
    const TAB_PANE_LIST = [
        {
            key: 'automaticAddition',
            tab: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                .automaticAddition,
        },
        {
            key: 'automaticPush',
            tab: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.automaticPush,
        },
        {
            key: 'automaticSamplingInspection',
            tab: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                .automaticSamplingInspection,
        },
        {
            key: 'automaticAllocation',
            tab: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                .automaticAllocation,
        },
        {
            key: 'caseLibrary',
            tab: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.caseLibrary,
        },
        {
            key: 'automaticAssess',
            tab: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                .automaticAssess,
        },
        {
            key: 'samplingTarget',
            tab: /* 抽检目标 */ UdeskLocales.current.pages.tasks.manage.template.automaticAdd
                .templateNew.samplingInspectionTarget,
        },
        {
            key: 'reexaminationComplaint',
            tab: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                .spoktReviewDetail,
        },
        {
            key: 'businessSummary',
            tab: /* 业务小结 */ UdeskLocales['current'].pages.tasks.manage.template.automaticAdd
                .templateNew.businessSummary,
        },
    ];
    if (isWechatQa) {
        return TAB_PANE_LIST.filter((item) =>
            [
                'automaticAddition',
                'automaticPush',
                'automaticSamplingInspection',
                'automaticAllocation',
                'reexaminationComplaint',
                'businessSummary',
            ].includes(item.key)
        );
    }
    if (isCollection) {
        return TAB_PANE_LIST.filter((item) => ['automaticAddition'].includes(item.key));
    }
    if (isTicket) {
        return TAB_PANE_LIST.filter(
            (item) => !['caseLibrary', 'automaticAssess'].includes(item.key)
        );
    }
    return TAB_PANE_LIST;
}
// const OtherSettings = props => {
//     return <FormBuilder.Item name='syncHis' {...props}>
//         <Checkbox>{UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.syncHis}</Checkbox>
//     </FormBuilder.Item>;
// };
const CaseConfig = (props) => {
    const { taskId, autoMateCaseConf = {}, onChange } = props;

    const [caseLibrary, setCaseLibrary] = useState([]);
    const [ccLibrary, setCCLibrary] = useState([]);
    const [form] = FormBuilder.useForm();
    useEffect(() => {
        getCaseLibraryCategorys({
            params: {
                taskId,
            },
        }).then((resp) => {
            setCaseLibrary(resp.data);
        });
        getCaseLibraryCategorysFromcc().then((resp) => {
            setCCLibrary(resp.data);
        });
    }, []);
    const onValuesChange = (changedValues, allValues) => {
        onChange(allValues);
    };
    const config = {
        initialValues: autoMateCaseConf,
        // customComponents: { OtherSettings },
        onValuesChange: onValuesChange,
        fields: [
            {
                name: 'caseLibraryId',
                label: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                    .caseLibraryId,
                type: 'Select',
                tooltip:
                    UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                        .caseLibraryIdTip,
                props: {
                    style: {
                        width: 250,
                    },
                    allowClear: true,
                    options: caseLibrary.map((item) => ({ label: item.name, value: item.id })),
                    placeholder: UdeskLocales.current.labels.pleaseSelect,
                },
            },
            // {
            //     name: 'url',
            //     label: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.url,
            //     type: 'Input',
            //     props: {
            //         placeholder: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.urlTip
            //     },
            //     rules: [
            //         ({ getFieldValue }) => ({
            //             validator(_, value) {
            //                 if (!value || /(http|https):\/\/([\w.]+\/?)\S*/.test(value)) {
            //                     return Promise.resolve();
            //                 }
            //                 return Promise.reject(new Error(UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.urlTip));
            //             },
            //         }),
            //     ]
            // },
            {
                name: 'udeskCaseLibraryId',
                label: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                    .udeskCaseLibraryId,
                tooltip:
                    UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                        .udeskCaseLibraryIdTip,
                type: 'Select',
                props: {
                    style: {
                        width: 250,
                    },
                    allowClear: true,
                    options: ccLibrary.map((item) => ({ label: item.name, value: item.id })),
                    placeholder: UdeskLocales.current.labels.pleaseSelect,
                },
            },
            // {
            //     label: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.otherSettings,
            //     type: 'Checkbox',
            //     name: 'syncHis',
            //     props: {
            //         children: UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.syncHis
            //     }
            // },
        ],
        footerRender: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };
    return (
        <div className="auto-add-filter-edit-item-row-filter">
            <FormBuilder {...config} form={form} />
        </div>
    );
};

// const DynamicTabs = React.memo((props) => {
//     const { children, showKeys = [], ...originTabsProps } = props;
//     return (
//         <Tabs
//             {...originTabsProps}
//         >
//             {
//                 children.filter(child => Array.isArray(showKeys) && showKeys.includes(child.key) && child)
//             }
//         </Tabs>
//     );
// });
export default class TasksAutomaticAddTemplate extends TasksAutomaticAddRoute {
    render() {
        let { actions, locales, props, privates } = this;
        let hasFeature = props.sdkOptions.props.task
            ? props.sdkOptions.props.task._hasFeature
            : null;
        const isWechatQa = [
            Udesk.enums.inspectDataSources.wechatDialogue.id,
            Udesk.enums.inspectDataSources.wechatRadio.id,
        ].includes(this.privates.type);
        const isCollection =
            props.sdkOptions.props.task.taskType === Udesk.enums.taskType.informationCollection.id;
        const isTicket = privates.type === Udesk.enums.inspectDataSources.ticket.id;

        return (
            <Page
                pageBodyClassName="tasks-manage-template-automatic-add-page"
                title={locales.pages.tasks.manage.template.automaticAdd.index.name}
                // padding={true}
                footer={
                    <Tabs
                        onChange={actions.changeActiveTab}
                        activeKey={privates.activeKey}
                        size="small"
                    >
                        {getTabPanes(isWechatQa, isCollection, isTicket).map((item) => (
                            <Tabs.TabPane key={`${item.key}`} tab={item.tab}></Tabs.TabPane>
                        ))}
                    </Tabs>
                }
            >
                <If condition={hasFeature && hasFeature('task:template:auto:view')}>
                    <If condition={this.privates.model.processCreateStatus.processStatus === 4}>
                        <Alert
                            message={locales.pages.tasks.manage.template.basicInfo.index.conduct4}
                            type="info"
                            closeText={locales.pages.tasks.manage.template.basicInfo.index.quit}
                            onClose={actions.exitBoot}
                        />
                    </If>
                    <CusTabs
                        isWechatQa={isWechatQa}
                        isCollection={isCollection}
                        isTicket={isTicket}
                        activeKey={privates.activeKey}
                    >
                        {/* 1、自动添加 */}
                        <CardLayout
                            cardId="automaticAddition"
                            title={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticAddition
                            }
                            tip={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticAdditionTip
                            }
                            showContent={Boolean(privates.model.processStatus.AUTOLOAD)}
                            switchProps={{
                                checked: privates.model.processStatus.AUTOLOAD === 1,
                                onChange: actions.changeStatus.params('AUTOLOAD'),
                                disabled: !hasFeature || !hasFeature('task:template:auto:edit'),
                            }}
                            canEdit={hasFeature && hasFeature('task:template:auto:edit')}
                            onSave={actions.saveAutomaticAddition}
                        >
                            <AutoAddComponent
                                isEdit={
                                    privates.conditionList.length &&
                                    hasFeature &&
                                    hasFeature('task:template:auto:edit')
                                }
                                judgeStrategy={privates.model.automaticAdd.baseFilter.judgeStrategy}
                                customJudgeLogic={
                                    privates.model.automaticAdd.baseFilter.customJudgeLogic
                                }
                                conditionList={privates.conditionList}
                                editFilterOhter={actions.editFilterOhter}
                                fieldsFilterChange={actions.fieldsFilterChange}
                                querryType="manualAdd"
                                cancel={actions.handleConditionCancel}
                                applyAction={actions.handleConditionApply}
                                customName={privates.customName}
                                customId={privates.customId}
                                type={privates.type}
                                // taskId={this.props.match.params.taskId}
                                taskName={this.props.sdkOptions.props.task.name}
                                filterList={['f_8', 'f_4', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
                                disabled={!(hasFeature && hasFeature('task:template:auto:edit'))}
                                autoMateLoadConf={privates.model.automaticAdd.autoMateLoadConf}
                                isAutoExecute={privates.model.automaticAdd.isAutoExecute}
                                onTimingAddChange={actions.onTimingAddChange}
                                isCollection={isCollection}
                            />
                        </CardLayout>

                        {/* 2、自动抽检 */}
                        <CardLayout
                            cardId="automaticSamplingInspection"
                            title={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticSamplingInspection
                            }
                            tip={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticSamplingInspectionTip
                            }
                            showContent={Boolean(privates.model.processStatus.SAMPLE)}
                            switchProps={{
                                checked: privates.model.processStatus.SAMPLE === 1,
                                onChange: actions.changeStatus.params('SAMPLE'),
                                disabled: !hasFeature || !hasFeature('task:template:auto:edit'),
                            }}
                            canEdit={hasFeature && hasFeature('task:template:auto:edit')}
                            onSave={actions.saveAutomaticSamplingInspection}
                        >
                            <TaskConditionGather
                                conditions={privates.conditions}
                                fields={privates.model.customFields}
                                onChanged={actions.conditionsChanged}
                                disabled={!hasFeature || !hasFeature('task:template:auto:edit')}
                                taskId={this.props.match.params.taskId}
                                taskName={this.props.sdkOptions.props.task.name}
                                type={privates.type}
                            />

                            <div style={{ margin: '16px 0px' }}>
                                <Space>
                                    <Checkbox
                                        checked={Boolean(privates.everyAgentCheckUpperLimit.status)}
                                        onChange={actions.onEveryAgentCheckUpperLimitStatusChange}
                                    >
                                        {
                                            UdeskLocales.current.pages.tasks.manage.template
                                                .automaticAdd.index.everyAgentCheckLimit
                                        }
                                    </Checkbox>
                                    <Select
                                        options={Udesk.enums.samplingFrequencyTypes
                                            .filter((i) => i.id > 1)
                                            .map((i) => ({
                                                label: i.everyAgentCheckLimitName(),
                                                value: i.id,
                                            }))}
                                        style={{ width: 150 }}
                                        value={privates.everyAgentCheckUpperLimit.cycle}
                                        onChange={actions.onEveryAgentCheckUpperLimitChange.params(
                                            'cycle'
                                        )}
                                        disabled={!privates.everyAgentCheckUpperLimit.status}
                                    />
                                    <InputNumber
                                        style={{ width: 200 }}
                                        value={privates.everyAgentCheckUpperLimit.numLimit}
                                        onChange={actions.onEveryAgentCheckUpperLimitChange.params(
                                            'numLimit'
                                        )}
                                        disabled={!privates.everyAgentCheckUpperLimit.status}
                                    />
                                </Space>
                            </div>

                            <div>
                                <Space>
                                    <Checkbox
                                        checked={Boolean(privates.everyAgentCheckFloorLimit.status)}
                                        onChange={actions.onEveryAgentCheckFloorLimitStatusChange}
                                    >
                                        {/* 每客服抽取下限 */}
                                        {
                                            UdeskLocales['current'].pages.tasks.manage.template
                                                .automaticAdd.templateNew
                                                .eachCustomerServiceRepresentativeCanSelectALowerLimit
                                        }
                                    </Checkbox>

                                    <Select
                                        options={Udesk.enums.samplingFrequencyTypes
                                            .filter((i) => i.id > 1)
                                            .map((i) => ({
                                                label: i.everyAgentCheckLimitName(),
                                                value: i.id,
                                            }))}
                                        style={{ width: 150 }}
                                        value={privates.everyAgentCheckFloorLimit.cycle}
                                        onChange={actions.onEveryAgentCheckFloorLimitChange.params(
                                            'cycle'
                                        )}
                                        disabled={!privates.everyAgentCheckFloorLimit.status}
                                    />
                                    <InputNumber
                                        style={{ width: 200 }}
                                        value={privates.everyAgentCheckFloorLimit.numLimit}
                                        onChange={actions.onEveryAgentCheckFloorLimitChange.params(
                                            'numLimit'
                                        )}
                                        disabled={!privates.everyAgentCheckFloorLimit.status}
                                    />
                                </Space>
                            </div>
                        </CardLayout>

                        {/* 3、自动分配 */}
                        <CardLayout
                            cardId="automaticAllocation"
                            title={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticAllocation
                            }
                            tip={[
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticAllocationTip,
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticAllocationTip2,
                            ]}
                            showContent={Boolean(privates.model.processStatus.ASSIGN)}
                            switchProps={{
                                checked: privates.model.processStatus.ASSIGN === 1,
                                onChange: actions.changeStatus.params('ASSIGN'),
                                disabled: !hasFeature || !hasFeature('task:template:auto:edit'),
                            }}
                            canEdit={hasFeature && hasFeature('task:template:auto:edit')}
                            onSave={actions.saveAutomaticAllocation}
                        >
                            <If condition={privates.model.processStatus.ASSIGN}>
                                <Radio.Group
                                    defaultValue={privates.assignShowType}
                                    onChange={actions.onAssignTypeChange}
                                    buttonStyle="solid"
                                    style={{ marginBottom: 16 }}
                                >
                                    <Radio.Button value="sampleAssign">
                                        {
                                            locales.pages.tasks.manage.template.automaticAdd.index
                                                .sampleInspectionDistributionModel
                                        }
                                    </Radio.Button>
                                    <Radio.Button value="reviewAssign">
                                        {
                                            locales.pages.tasks.manage.template.automaticAdd.index
                                                .reviewAllocationModel
                                        }
                                    </Radio.Button>
                                </Radio.Group>
                                <div className="automatic-content-item-body">
                                    {privates.assignShowType === 'sampleAssign' && (
                                        <AutomaticAllocationItem
                                            title={
                                                locales.pages.tasks.manage.template.automaticAdd
                                                    .index.distributionModelTitle
                                            }
                                            assignModelValue={privates.sampleAssign.assignModel}
                                            onAssignModelValueChange={actions.onValueChanged.params(
                                                privates.sampleAssign,
                                                'assignModel'
                                            )}
                                            disabled={
                                                !hasFeature ||
                                                !hasFeature('task:template:auto:edit')
                                            }
                                            assignConfList={[
                                                ...privates.sampleAssign.assignConfList,
                                            ]}
                                            fields={privates.model.customFields}
                                            onAssignConfListChange={actions.sampleAssignListChanged}
                                            users={privates.model.users}
                                            taskId={this.props.match.params.taskId}
                                            taskName={this.props.sdkOptions.props.task.name}
                                            inspectDataSource={privates.type}
                                            notMatchValue={
                                                privates.defaultSampleAssignConf.assignType
                                            }
                                            onNotMatchValueChange={actions.onValueChanged.params(
                                                privates.defaultSampleAssignConf,
                                                'assignType'
                                            )}
                                            notMatchSelectValue={
                                                privates.defaultSampleAssignConf.inspectorList
                                            }
                                            onNotMatchSelectValueChange={actions.onValueChanged.params(
                                                privates.defaultSampleAssignConf,
                                                'inspectorList'
                                            )}
                                            getUserListUrl={Udesk.business.apiPath.concatApiPath(
                                                '/users?pageSize=1000',
                                                props.sdkOptions
                                            )}
                                        />
                                    )}
                                    {privates.assignShowType === 'reviewAssign' && (
                                        <AutomaticAllocationItem
                                            title={
                                                locales.pages.tasks.manage.template.automaticAdd
                                                    .index.distributionModelTitle
                                            }
                                            assignModelValue={privates.reviewAssign.assignModel}
                                            onAssignModelValueChange={actions.onValueChanged.params(
                                                privates.reviewAssign,
                                                'assignModel'
                                            )}
                                            disabled={
                                                !hasFeature ||
                                                !hasFeature('task:template:auto:edit')
                                            }
                                            assignConfList={[
                                                ...privates.reviewAssign.assignConfList,
                                            ]}
                                            fields={privates.model.customFields}
                                            onAssignConfListChange={actions.reviewAssignListChanged}
                                            users={privates.model.users}
                                            taskId={this.props.match.params.taskId}
                                            taskName={this.props.sdkOptions.props.task.name}
                                            inspectDataSource={privates.type}
                                            notMatchValue={
                                                privates.defaultReviewAssignConf.assignType
                                            }
                                            onNotMatchValueChange={actions.onValueChanged.params(
                                                privates.defaultReviewAssignConf,
                                                'assignType'
                                            )}
                                            notMatchSelectValue={
                                                privates.defaultReviewAssignConf.inspectorList
                                            }
                                            onNotMatchSelectValueChange={actions.onValueChanged.params(
                                                privates.defaultReviewAssignConf,
                                                'inspectorList'
                                            )}
                                            getUserListUrl={Udesk.business.apiPath.concatApiPath(
                                                '/users?pageSize=1000',
                                                props.sdkOptions
                                            )}
                                        >
                                            <div className="automatic-content-item-body-item">
                                                <div className="automatic-content-item-body-item-title automatic-content-item-body-item-title-screening-condition">
                                                    {/* 二级复核 */}
                                                    {
                                                        UdeskLocales['current'].pages.tasks.manage
                                                            .template.automaticAdd.templateNew
                                                            .secondaryReview
                                                    }
                                                    <Tooltip
                                                        placement="top"
                                                        title={
                                                            /* 二级复核员仅支持平均分配 */ locales
                                                                .pages.tasks.manage.template
                                                                .automaticAdd.templateNew
                                                                .secondLevelReviewersOnlySupportAverageDistribution
                                                        }
                                                    >
                                                        <Icon
                                                            type="InfoCircleTwoTone"
                                                            antdIcon={true}
                                                            style={{ marginRight: 4 }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                <div className="automatic-content-item-body-item-content">
                                                    <Switch
                                                        size="small"
                                                        checked={
                                                            !!privates.reviewAssign
                                                                .enabledSecondLevelRecheck
                                                        }
                                                        onChange={actions.onValueChanged.params(
                                                            privates.reviewAssign,
                                                            'enabledSecondLevelRecheck'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {privates.reviewAssign.enabledSecondLevelRecheck ? (
                                                <div className="automatic-content-item-body-item">
                                                    <div className="automatic-content-item-body-item-title automatic-content-item-body-item-title-screening-condition">
                                                        {
                                                            /* 二级复核员： */ locales.pages.tasks
                                                                .manage.template.automaticAdd
                                                                .templateNew.secondLevelReviewer
                                                        }
                                                    </div>
                                                    <div className="automatic-content-item-body-item-content">
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="label"
                                                            options={privates.model.users.map(
                                                                (user) => ({
                                                                    value: user.id,
                                                                    label: user.realname,
                                                                })
                                                            )}
                                                            style={{ width: 250 }}
                                                            value={
                                                                privates.reviewAssign
                                                                    .secondLevelInspectors
                                                            }
                                                            onChange={actions.onValueChanged.params(
                                                                privates.reviewAssign,
                                                                'secondLevelInspectors'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                        </AutomaticAllocationItem>
                                    )}
                                </div>
                            </If>
                        </CardLayout>
                        {/* 4、自动推送 */}
                        <CardLayout
                            cardId="automaticPush"
                            title={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticPush
                            }
                            tip={[
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticPushTip,
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticPushTip2,
                            ]}
                            showContent={Boolean(privates.model.processStatus.MONITOR)}
                            switchProps={{
                                checked: privates.model.processStatus.MONITOR === 1,
                                onChange: actions.changeStatus.params('MONITOR'),
                                disabled: !hasFeature || !hasFeature('task:template:auto:edit'),
                            }}
                            canEdit={hasFeature && hasFeature('task:template:auto:edit')}
                            onSave={actions.saveAutomaticPush}
                            cardBodyStyle={{ padding: '40px 0 24px' }}
                        >
                            <div className="tasks-automatic-add-card-body-push-list">
                                <div className={`tasks-automatic-add-push-item`}>
                                    <Radio.Group
                                        value={privates.pushTypeRadioValue}
                                        onChange={actions.onPushTypeRadioChange}
                                        style={{ marginBottom: 16 }}
                                        buttonStyle="solid"
                                    >
                                        {Udesk.enums.messageType
                                            .filter((messageType) => messageType > 1)
                                            .map((messageType, index, arr) => (
                                                <Radio.Button value={messageType.id}>
                                                    {messageType.name}
                                                </Radio.Button>
                                            ))}
                                    </Radio.Group>
                                    <div className="tasks-automatic-add-push-item-content">
                                        <Tabs
                                            type="editable-card"
                                            onChange={actions.onPushConfigTabsChange}
                                            // activeKey={activeKey}
                                            onEdit={actions.onEdit.params(
                                                privates.pushTypeRadioValue
                                            )}
                                            addIcon={
                                                <div style={{ padding: 8 }}>
                                                    {UdeskLocales.current.labels.create +
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.automaticAdd.index.push}
                                                </div>
                                            }
                                        >
                                            {privates.automaticPushConfigList
                                                .filter(
                                                    (item) =>
                                                        item.monitorConf &&
                                                        item.monitorConf.messageType ===
                                                            privates.pushTypeRadioValue
                                                )
                                                .map((item, index) => (
                                                    <Tabs.TabPane
                                                        tab={`${
                                                            UdeskLocales.current.pages.tasks.manage
                                                                .template.automaticAdd.index.push
                                                        }${index + 1}`}
                                                        key={item._id}
                                                        closable={true}
                                                    >
                                                        <MassageConfiguration
                                                            key={item._id}
                                                            type={privates.pushTypeRadioValue}
                                                            users={privates.model.users}
                                                            roles={privates.model.roles}
                                                            messagePushTypeFilter={
                                                                isWechatQa
                                                                    ? (i) => i.id <= 2
                                                                    : undefined
                                                            }
                                                            configuration={Udesk.utils.object.deepCopy(
                                                                item
                                                            )}
                                                            onMassageConfigurationChange={actions.onMassageConfigurationChange.params(
                                                                item
                                                            )}
                                                            onConfigurationChange={
                                                                actions.onConfigurationChange
                                                            }
                                                            taskId={this.props.match.params.taskId}
                                                            taskName={
                                                                this.props.sdkOptions.props.task
                                                                    .name
                                                            }
                                                            taskType={
                                                                privates.model.task
                                                                    .inspectDataSource
                                                            }
                                                            taskTypeList={[1, 3, 6, 8, 14, 17]}
                                                            disabled={
                                                                !hasFeature ||
                                                                !hasFeature(
                                                                    'task:template:auto:edit'
                                                                )
                                                            }
                                                            isWechatQa={isWechatQa}
                                                        />
                                                    </Tabs.TabPane>
                                                ))}
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </CardLayout>
                        {/* 5、自动评估 */}
                        <CardLayout
                            cardId="automaticAssess"
                            title={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticAssess
                            }
                            tip={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .automaticAssessTip
                            }
                            showContent={Boolean(privates.model.processStatus.AUTO_ASSESSMENT)}
                            switchProps={{
                                checked: privates.model.processStatus.AUTO_ASSESSMENT === 1,
                                onChange: actions.changeStatus.params('AUTO_ASSESSMENT'),
                                disabled: !hasFeature || !hasFeature('task:template:auto:edit'),
                            }}
                            canEdit={hasFeature && hasFeature('task:template:auto:edit')}
                            onSave={actions.saveAutomaticAssess}
                        >
                            <TaskAssessRulesGather
                                rules={[...privates.assessRules]}
                                taskId={privates.model.taskId}
                                fields={privates.model.customFields}
                                onChanged={actions.assessRulesChanged}
                                taskName={this.props.sdkOptions.props.task.name}
                                type={privates.type}
                                disabled={!hasFeature || !hasFeature('task:template:auto:edit')}
                            />
                        </CardLayout>
                        {/* 5、同步至案例库 */}
                        <CardLayout
                            cardId="caseLibrary"
                            title={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .caseLibrary
                            }
                            tip={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .caseLibrarySyncTip
                            }
                            showContent={Boolean(privates.model.processStatus.CASE)}
                            switchProps={{
                                checked: privates.model.processStatus.CASE === 1,
                                onChange: actions.changeStatus.params('CASE'),
                                disabled: !hasFeature || !hasFeature('task:template:auto:edit'),
                            }}
                            canEdit={hasFeature && hasFeature('task:template:auto:edit')}
                            onSave={actions.saveCaseLibrary}
                        >
                            <UdeskList
                                deleteItem={actions.deleteCaseItem}
                                addItem={actions.addCaseItem}
                                addBtnText={
                                    UdeskLocales.current.pages.tasks.manage.template.automaticAdd
                                        .index.addCase
                                }
                            >
                                {privates.caseConfigList.map((item, index) => {
                                    // privates.automaticPushConfigList.map((item, index) => {
                                    return (
                                        <CaseConfig
                                            key={item._id}
                                            onChange={actions.onCaseItemChange.params(index)}
                                            autoMateCaseConf={item}
                                            taskId={this.props.match.params.taskId}
                                        />
                                    );
                                })}
                            </UdeskList>
                        </CardLayout>
                        {/* 5、抽检目标 */}
                        <CardLayout
                            cardId="samplingTarget"
                            title={
                                /* 抽检目标 */ locales.pages.tasks.manage.template.automaticAdd
                                    .templateNew.samplingInspectionTarget
                            }
                            tip={
                                /* 设定人工抽检应完成的目标，目标未达成或者超过时给出提示，例如每客服每月应完成抽检100条，低于80条时，质检、抽检页面进行提示。 */ locales
                                    .pages.tasks.manage.template.automaticAdd.templateNew
                                    .setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage
                            }
                            showContent={true}
                            canEdit={hasFeature && hasFeature('task:template:auto:edit')}
                            onSave={actions.samplingTargetSave}
                        >
                            <SamplingTarget
                                ref={privates.samplingTargetRef}
                                taskId={this.props.match.params.taskId}
                            />
                        </CardLayout>
                        {/* 6、抽检复核详情配置 */}
                        <CardLayout
                            cardId="reexaminationComplaint"
                            title={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .spoktReviewDetail
                            }
                            tip={
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index
                                    .spoktReviewDetailTip
                            }
                            showContent={true}
                            canEdit={hasFeature && hasFeature('task:template:auto:edit')}
                            onSave={actions.saveReexaminationComplaint}
                        >
                            {!isTicket && (
                                <div className="automatic-content-item-body-item">
                                    <div className="automatic-content-item-body-item-title">
                                        {locales.pages.tasks.manage.template.automaticAdd.index
                                            .qaDetailAction + '：'}
                                    </div>
                                    <div
                                        className="automatic-content-item-body-item-content"
                                        style={{ width: 'calc(100% - 132px)' }}
                                    >
                                        <div>
                                            <Checkbox.Group
                                                value={privates.inspectionOptionsExport}
                                                disabled={
                                                    !hasFeature ||
                                                    !hasFeature('task:template:auto:edit')
                                                }
                                                style={{ width: '100%' }}
                                                onChange={actions.onValueChanged.params(
                                                    privates,
                                                    'inspectionOptionsExport'
                                                )}
                                            >
                                                <Row>
                                                    <For
                                                        each="item"
                                                        index="index"
                                                        of={privates.inspectionOptionsList}
                                                    >
                                                        <Col span={24}>
                                                            <Checkbox key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Checkbox>
                                                        </Col>
                                                    </For>
                                                </Row>
                                            </Checkbox.Group>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="automatic-content-item-body-item">
                                <div className="automatic-content-item-body-item-title">
                                    {locales.pages.tasks.manage.template.automaticAdd.index
                                        .spoktDetailAction + '：'}
                                </div>
                                <div
                                    className="automatic-content-item-body-item-content"
                                    style={{ width: 'calc(100% - 132px)', lineHeight: '30px' }}
                                >
                                    <div>
                                        <Checkbox.Group
                                            value={privates.spoktOptions}
                                            style={{ width: '100%' }}
                                            disabled={
                                                !hasFeature ||
                                                !hasFeature('task:template:auto:edit')
                                            }
                                            onChange={actions.onValueChanged.params(
                                                privates,
                                                'spoktOptions'
                                            )}
                                        >
                                            <Row>
                                                <For
                                                    each="item"
                                                    index="index"
                                                    of={Udesk.enums.spoktListResultOptions}
                                                >
                                                    <Col span={8}>
                                                        <Checkbox key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Checkbox>
                                                    </Col>
                                                </For>
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                    <div>
                                        <Checkbox.Group
                                            value={privates.fastSampleInDetailList}
                                            disabled={
                                                !hasFeature ||
                                                !hasFeature('task:template:auto:edit')
                                            }
                                            style={{ width: '100%' }}
                                            onChange={actions.onValueChanged.params(
                                                privates,
                                                'fastSampleInDetailList'
                                            )}
                                        >
                                            <Row>
                                                <For
                                                    each="item"
                                                    index="index"
                                                    of={Udesk.enums.fastSampleInDetailList.filter(
                                                        (i) =>
                                                            i.id !==
                                                            Udesk.enums.fastSampleInDetailList
                                                                .fastSampleInReviewDetail.id
                                                    )}
                                                >
                                                    <Col span={8}>
                                                        <Checkbox key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Checkbox>
                                                    </Col>
                                                </For>
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                    {!isTicket && (
                                        <div>
                                            <Checkbox.Group
                                                value={privates.spoketOptionsExport}
                                                disabled={
                                                    !hasFeature ||
                                                    !hasFeature('task:template:auto:edit')
                                                }
                                                style={{ width: '100%' }}
                                                onChange={actions.onValueChanged.params(
                                                    privates,
                                                    'spoketOptionsExport'
                                                )}
                                            >
                                                <Row>
                                                    <For
                                                        each="item"
                                                        index="index"
                                                        of={privates.spoktOptionsList}
                                                    >
                                                        <Col span={8}>
                                                            <Checkbox key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Checkbox>
                                                        </Col>
                                                    </For>
                                                </Row>
                                            </Checkbox.Group>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {!isTicket && (
                                <div className="automatic-content-item-body-item">
                                    <div className="automatic-content-item-body-item-title">
                                        {locales.pages.tasks.manage.template.automaticAdd.index
                                            .reviewDetailAction + '：'}
                                    </div>
                                    <div
                                        className="automatic-content-item-body-item-content"
                                        style={{ width: 'calc(100% - 132px)', lineHeight: '30px' }}
                                    >
                                        {/* <div>
                                                    <Checkbox.Group
                                                        value={privates.permitCustomReasonList}
                                                        disabled={!hasFeature || !hasFeature('task:template:auto:edit')}
                                                        style={{ width: '100%' }}
                                                        onChange={actions.onValueChanged.params(
                                                            privates,
                                                            'permitCustomReasonList'
                                                        )}
                                                    >
                                                        <Row>
                                                            <For
                                                                each='item'
                                                                index='index'
                                                                of={Udesk.enums.permitCustomReasonList}
                                                            >
                                                                <Col span={8}>
                                                                    <Checkbox key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </Checkbox>
                                                                </Col>
                                                            </For>
                                                        </Row>
                                                    </Checkbox.Group>
                                                </div> */}
                                        <div>
                                            <Checkbox.Group
                                                value={privates.reviewOptions}
                                                disabled={
                                                    !hasFeature ||
                                                    !hasFeature('task:template:auto:edit')
                                                }
                                                style={{ width: '100%' }}
                                                onChange={actions.onValueChanged.params(
                                                    privates,
                                                    'reviewOptions'
                                                )}
                                            >
                                                <Row>
                                                    <For
                                                        each="item"
                                                        index="index"
                                                        of={Udesk.enums.reviewlistResultOptions.filter(
                                                            (i) => i.id !== 1
                                                        )}
                                                    >
                                                        <Col span={8}>
                                                            <Checkbox key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Checkbox>
                                                        </Col>
                                                    </For>
                                                </Row>
                                            </Checkbox.Group>
                                        </div>
                                        <div>
                                            <Checkbox.Group
                                                value={privates.reviewOptionsExport}
                                                disabled={
                                                    !hasFeature ||
                                                    !hasFeature('task:template:auto:edit')
                                                }
                                                style={{ width: '100%' }}
                                                onChange={actions.onValueChanged.params(
                                                    privates,
                                                    'reviewOptionsExport'
                                                )}
                                            >
                                                <Row>
                                                    <For
                                                        each="item"
                                                        index="index"
                                                        of={privates.reviewOptionsList}
                                                    >
                                                        <Col span={8}>
                                                            <Checkbox key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Checkbox>
                                                        </Col>
                                                    </For>
                                                </Row>
                                            </Checkbox.Group>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="automatic-content-item-body-item">
                                <div className="automatic-content-item-body-item-title">
                                    {locales.pages.tasks.manage.template.automaticAdd.index
                                        .appealAction + '：'}
                                </div>
                                <div
                                    className="automatic-content-item-body-item-content"
                                    style={{ width: 'calc(100% - 132px)', lineHeight: '30px' }}
                                >
                                    <div style={{ marginBottom: '10px' }}>
                                        <span>
                                            <span>
                                                {/* 允许提交申诉次数 */}
                                                {
                                                    UdeskLocales['current'].pages.tasks.manage
                                                        .template.automaticAdd.templateNew
                                                        .numberOfAppealsAllowedToBeSubmitted
                                                }
                                            </span>
                                            <span>
                                                <InputNumber
                                                    value={privates.permitComplainCount}
                                                    onChange={actions.onValueChanged.params(
                                                        privates,
                                                        'permitComplainCount'
                                                    )}
                                                />
                                                {
                                                    locales.pages.tasks.manage.template.automaticAdd
                                                        .index.rewivewSecond
                                                }
                                            </span>
                                        </span>
                                        <span style={{ marginLeft: '20px' }}>
                                            <span>
                                                {/* 提交申诉有效期 */}
                                                {
                                                    UdeskLocales['current'].pages.tasks.manage
                                                        .template.automaticAdd.templateNew
                                                        .validityOfSubmissionOfAppeal
                                                }
                                            </span>
                                            <span>
                                                <InputNumber
                                                    value={privates.complainPeriod}
                                                    onChange={actions.onValueChanged.params(
                                                        privates,
                                                        'complainPeriod'
                                                    )}
                                                />
                                                {
                                                    locales.pages.tasks.manage.template.automaticAdd
                                                        .index.rewivewDays
                                                }
                                            </span>
                                        </span>
                                        <span style={{ marginLeft: '20px' }}>
                                            <span>
                                                {/* 申诉复核有效期 */}
                                                {
                                                    UdeskLocales['current'].pages.tasks.manage
                                                        .template.automaticAdd.templateNew
                                                        .validityOfAppealReview
                                                }
                                            </span>
                                            <span>
                                                <InputNumber
                                                    value={privates.reviewAging}
                                                    onChange={actions.onValueChanged.params(
                                                        privates,
                                                        'reviewAging'
                                                    )}
                                                    max={24}
                                                    precision={1}
                                                />
                                                {/* 小时 */}
                                                {
                                                    UdeskLocales['current'].pages.tasks.manage
                                                        .template.automaticAdd.templateNew.hours
                                                }
                                            </span>
                                        </span>
                                    </div>
                                    <div>
                                        <Checkbox.Group
                                            value={Object.keys(privates.appealOptionsExport).filter(
                                                (i) => privates.appealOptionsExport[i]
                                            )}
                                            disabled={
                                                !hasFeature ||
                                                !hasFeature('task:template:auto:edit')
                                            }
                                            style={{ width: '100%' }}
                                            onChange={actions.onAppealValueChanged}
                                        >
                                            <Row>
                                                <For
                                                    each="item"
                                                    index="index"
                                                    of={privates.appealOptionsList}
                                                >
                                                    <Col span={8}>
                                                        <Checkbox key={item.key} value={item.key}>
                                                            {item.label}
                                                        </Checkbox>
                                                    </Col>
                                                </For>
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </div>
                            </div>
                            {/* 备注模板 */}
                            <div className="automatic-content-item-body-item">
                                <div className="automatic-content-item-body-item-title">
                                    {locales.pages.tasks.manage.template.automaticAdd.index
                                        .remarkTemplate + '：'}
                                </div>
                                <div
                                    className="automatic-content-item-body-item-content"
                                    style={{ width: 'calc(100% - 132px)', lineHeight: '30px' }}
                                >
                                    <Input.TextArea
                                        onChange={actions.onRemarkModelChange}
                                        showCount={true}
                                        maxCount={1000}
                                        value={privates.remarkModel}
                                        autoSize={{ minRows: 4, maxRows: 6 }}
                                    ></Input.TextArea>
                                </div>
                            </div>
                        </CardLayout>
                        {/* 业务小结 */}
                        <CardLayout
                            cardId="businessSummary"
                            title={
                                /* 业务小结 */ UdeskLocales['current'].pages.tasks.manage.template
                                    .automaticAdd.templateNew.businessSummary
                            }
                            tip={
                                /* 由大模型自动生成业务小结，根据业务情况可自定义生成维度 */ UdeskLocales[
                                    'current'
                                ].pages.tasks.manage.template.automaticAdd.templateNew
                                    .automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations
                            }
                            showContent={true}
                            switchProps={{
                                checked: privates.model.processStatus.BUSINESS_SUMMARY === 1,
                                onChange: actions.changeStatus.params('BUSINESS_SUMMARY'),
                                disabled: !hasFeature || !hasFeature('task:template:auto:edit'),
                            }}
                            canEdit={false}
                            cardBodyStyle={{ padding: 16 }}
                        >
                            <BusinessSummaryTable
                                type={privates.type}
                                taskId={this.props.match.params.taskId}
                            />
                        </CardLayout>
                    </CusTabs>
                    <If condition={privates.model.processCreateStatus.processStatus === 4}>
                        <div
                            className="tasks-manage-template-basic-info-index-footer"
                            style={{ padding: 16 }}
                        >
                            <Button type="primary" onClick={actions.handleNext}>
                                {privates.model.processCreateStatus.related === 1 &&
                                Udesk.business.task.isTextDialogue(
                                    privates.model.processCreateStatus.inspectDataSource
                                )
                                    ? locales.pages.tasks.manage.template.basicInfo.index.complete
                                    : locales.pages.tasks.manage.template.basicInfo.index.next}
                            </Button>
                            {(this.privates.model.processCreateStatus.related === 1 &&
                                Udesk.business.task.isTextDialogue(
                                    privates.model.processCreateStatus.inspectDataSource
                                )) || (
                                <Button style={{ marginLeft: 8 }} onClick={actions.sendAjaxToNext}>
                                    {locales.pages.tasks.manage.template.basicInfo.index.skip}
                                </Button>
                            )}
                        </div>
                    </If>
                </If>
            </Page>
        );
    }
}
