var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
var TreeMenuWrap = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  height: 100%;\n  ", "\n  .", "-tree-title {\n    flex: 1;\n    overflow: hidden;\n  }\n  ", "\n  ", "\n  ", "\n"])), function (props) {
  return ".".concat(props.prefixCls, "-tree-node-content-wrapper {\n    overflow-x: hidden;\n    display: flex;\n  }");
}, function (props) {
  return props.prefixCls;
}, function (props) {
  return ".".concat(props.prefixCls, "-tree-node-selected {\n    .icon-EllipsisOutlined {\n      color: #fff;\n    }\n    .").concat(props.prefixCls, "-tree-title {\n        .switcher-icon-right {\n            color: #fff;\n        }\n    }\n  }");
}, function (props) {
  return ".".concat(props.prefixCls, "-tree-indent-unit {\n    width: ").concat(props.indentUnitWidth, "px;\n  }");
}, function (props) {
  return props.switcherIconPosition === 'right' ? ".".concat(props.prefixCls, "-tree-treenode {\n            .switcher-icon-right {\n                display: inline-block;\n                text-align: center;\n                width: 24px;\n                color: rgba(0, 0, 0, 0.35);\n                svg {\n                    transition: transform 0.3s;\n                }\n            }\n            .").concat(props.prefixCls, "-tree-switcher {\n                display: none;\n            }\n            .").concat(props.prefixCls, "-tree-node-content-wrapper-close {\n                .switcher-icon-right {\n                    svg {\n                        transform: rotate(-180deg);\n                    }\n                }\n            }\n        }") : '';
});
export { TreeMenuWrap };