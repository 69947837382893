// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { PostReviewRecheckSessionBody, BaseResponseboolean } from '../../../types';

/**
 * 销售赋能重新质检
 *
 * @export
 * @tags review-recheck-rule-controller
 * @link [POST] /review/recheck/session
 */
export function postReviewRecheckSession(
    data: PostReviewRecheckSessionBody
): Promise<BaseResponseboolean>;
export function postReviewRecheckSession(
    data: PostReviewRecheckSessionBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseboolean>;
export function postReviewRecheckSession(
    data: PostReviewRecheckSessionBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseboolean> {
    return request<PostReviewRecheckSessionBody, never, never>('/review/recheck/session', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewRecheckSession',
    });
}

export const meta = [
    { tags: ['review-recheck-rule-controller'], path: '/review/recheck/session', method: 'post' },
];
