import TemplateComponentsMyDetailComponent from './component';
import Udesk from 'Udesk';
class TemplateComponentsMyDetailRoute extends TemplateComponentsMyDetailComponent {
    asyncModel() {
        this.privates.spinLoading = true;
        this.actions.update();
        let { sdkOptions, match } = this.props;
        // let myDetailPromise = new Promise((resolve, reject) => {
        //     resolve({});
        // });

        let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionTasks/list/base`, sdkOptions);
        let taskPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(taskUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let tagUrl = Udesk.business.apiPath.concatApiPath(`semantic-tag/list/base`, sdkOptions);
        let tagPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(tagUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let interUrl = Udesk.business.apiPath.concatApiPath(`interactiveCategories/list/base`, sdkOptions);
        let interPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(interUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let conceptUrl = Udesk.business.apiPath.concatApiPath(`conceptWords/list/base`, sdkOptions);
        let conceptPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(conceptUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        // let infoUrl = Udesk.business.apiPath.concatApiPath(`information-entity/list/base?systemType=${Udesk.data.init.company.systemType}`, sdkOptions);
        let infoUrl = Udesk.business.apiPath.concatApiPath(`information-entity/list/base`, sdkOptions);
        let infoPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(infoUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let multiUrl = Udesk.business.apiPath.concatApiPath(`interactiveDatas/list/base`, sdkOptions);
        let multiPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(multiUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        Promise.all([taskPromise, tagPromise, interPromise, conceptPromise, infoPromise, multiPromise]).then(() => {
            if (match.params.id) {
                this.actions.geDetail();
            } else {
                this.privates.spinLoading = false;
                this.actions.update();
            }
        }).catch(() => {
            this.privates.spinLoading = false;
            this.actions.update();
        });
        return {
            taskData: taskPromise,
            tagData: tagPromise,
            interData: interPromise,
            conceptData: conceptPromise,
            infoData: infoPromise,
            multiData: multiPromise,
            // myDetailData: myDetailPromise,
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}

export default TemplateComponentsMyDetailRoute;
