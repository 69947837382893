// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostInspectionRuleApprovesSearchQuery,
    PostInspectionRuleApprovesSearchBody,
    BaseResponseListInspectionRuleApproveFoundResponse,
} from '../../../types';

/**
 * 审批列表查询
 *
 * @export
 * @tags 质检规则审核
 * @link [POST] /inspectionRule/approves/search
 */
export function postInspectionRuleApprovesSearch(
    data: PostInspectionRuleApprovesSearchBody
): Promise<BaseResponseListInspectionRuleApproveFoundResponse>;
export function postInspectionRuleApprovesSearch(
    data: PostInspectionRuleApprovesSearchBody,
    options: ApiOptions<never, PostInspectionRuleApprovesSearchQuery>
): Promise<BaseResponseListInspectionRuleApproveFoundResponse>;
export function postInspectionRuleApprovesSearch(
    data: PostInspectionRuleApprovesSearchBody,
    options?: ApiOptions<never, PostInspectionRuleApprovesSearchQuery>
): Promise<BaseResponseListInspectionRuleApproveFoundResponse> {
    return request<
        PostInspectionRuleApprovesSearchBody,
        never,
        PostInspectionRuleApprovesSearchQuery
    >('/inspectionRule/approves/search', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postInspectionRuleApprovesSearch',
    });
}

export const meta = [
    { tags: ['质检规则审核'], path: '/inspectionRule/approves/search', method: 'post' },
];
