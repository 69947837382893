// 统一合并为完整的 Locale
var locale = {
  lang: {
    export: 'export',
    successfully: 'Export task created successfully',
    pleaseJumpTo: 'Please jump to',
    uploadAndDownloadCenter: 'Upload and download center',
    check: 'Check',
    exportAll: 'Confirm to export all content ?',
    total: 'Total',
    confirm: 'data, confirm the export ?',
    error: 'The number of exportable data is 0, please change the filter conditions and try again'
  }
};
export default locale;