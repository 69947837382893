import React, { useState, useMemo, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import { Button, FormBuilder, Space, Popconfirm } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import cusConfigMap from 'Udesk/config/form-config/cus-component-map';
import { postBusinessAnalysisConditionConfigsSearch } from 'src/api/businessAnalysisConditionConfigs/search';
import { BusinessAnalysisConditionConfigSearchRequest } from 'src/api/types';
import { deleteBusinessAnalysisConditionConfigsById } from 'src/api/businessAnalysisConditionConfigs/{id}';
import { getCurrentRouteName } from 'Udesk/system/subApp';

// import './style.scss';

const Template = React.memo((props: any) => {
    const locales = Locales['current'];

    const { sdkOptions } = props;
    const [form] = FormBuilder.useForm();
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    // const [columns, setColumns] = useState<any[]>([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [searchData, setSearchData] = useState({});
    // const [filterConditions, setFilterConditions] =  useState({
    //     judgeStrategy: 1,
    //     conditionList: [],
    //     customJudgeLogic: ''
    // });

    // const filterProps = useMemo(() => {
    //     const onApply = ({ conditionList, customJudgeLogic, judgeStrategy, id, name }) => {
    //         setFilterConditions({
    //             judgeStrategy,
    //             conditionList,
    //             customJudgeLogic,
    //         });
    //     };
    //     return {
    //         ...filterConditions,
    //         onApply: onApply,
    //         // type: 9,
    //         // querryType: 'personaField',
    //     };
    // }, [filterConditions]);

    // const exportBtnProps = useMemo(() => {
    //     const onExport = (showFieldList) => {
    //     };
    //     return {
    //         onExport: onExport,
    //     };
    // }, []);
    const columns = useMemo<any[]>(() => {
        const deleteItem = async ({ id }) => {
            await deleteBusinessAnalysisConditionConfigsById({
                segments: {
                    id,
                },
            });
            search(searchData);
        };
        const goToDetail = (id) => {
            const routeOptions = {
                history: props.history,
                routeName: getCurrentRouteName('businessAnalysisDetail'),
                pathParams: {
                    id,
                },
            };
            if (props.route.name === 'analysisBusinessAnalysisList') {
                routeOptions.routeName = 'analysisBusinessAnalysisDetail';
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        };

        return [
            {
                title: locales.pages.businessAnalysis.keyword,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: locales.pages.businessAnalysis.createUser,
                dataIndex: 'createUserUsername',
                key: 'createUserUsername',
            },
            {
                title: locales.pages.businessAnalysis.createTime,
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: locales.pages.businessAnalysis.updateTime,
                dataIndex: 'updateTime',
                key: 'updateTime',
            },
            {
                title: locales.labels.action,
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                render: (name, item, index) => {
                    return (
                        <Space>
                            <Button
                                onClick={goToDetail.bind(null, item.id)}
                                size="small"
                                type="link"
                            >
                                {locales.labels.view}
                            </Button>
                            <Popconfirm
                                title={locales.labels.deleteContent}
                                onConfirm={deleteItem.bind(null, item)}
                                okText={locales.labels.confirm}
                                cancelText={locales.labels.cancel}
                            >
                                <Button size="small" type="link" danger>
                                    {locales.labels.delete}
                                </Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history, searchData]);
    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
        };
    }, [columns, dataSource]);
    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPageNum(page);
                setPageSize(pageSize);
                sendRequest(searchData, page, pageSize);
            },
        };
    }, [pageNum, pageSize, total, searchData]);
    const sendRequest = (searchData, pageNum, pageSize) => {
        setLoading(true);
        postBusinessAnalysisConditionConfigsSearch(searchData, {
            params: { pageNum, pageSize },
        }).then(
            (resp) => {
                setLoading(false);
                const { data = [], paging } = resp;
                setPageNum(paging?.pageNum ?? 1);
                setPageSize(paging?.pageSize ?? 20);
                setTotal(paging?.total ?? 0);
                setDataSource(data);
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    useEffect(() => {
        sendRequest(searchData, pageNum, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const search = (values) => {
        const { createUser = {}, time = [], name } = values;

        let data: BusinessAnalysisConditionConfigSearchRequest = {};
        if (time[1]) {
            data.endTime = time[1];
        }
        if (time[0]) {
            data.startTime = time[0];
        }
        if (name) {
            data.name = name;
        }
        if (createUser && createUser.id) {
            data.createUserId = createUser.id;
        }
        setSearchData(data);
        sendRequest(data, 1, pageSize);
    };
    const goToDetail = () => {
        const routeOptions = {
            history: props.history,
            routeName: getCurrentRouteName('businessAnalysisCreate'),
        };
        if (props.route.name === 'analysisBusinessAnalysisList') {
            routeOptions.routeName = 'analysisBusinessAnalysisCreate';
        }
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const config: FormProps = {
        customComponents: { ...cusConfigMap, RangePicker: UdeskDatePicker },
        fields: [
            {
                name: 'name',
                label: locales.pages.businessAnalysis.keyword,
                type: 'Input',
                props: {
                    placeholder: locales.labels.pleaseEnter,
                },
            },
            {
                name: 'createUser',
                label: locales.pages.businessAnalysis.createUser,
                type: 'UdAutoComplete',
                props: {
                    style: {
                        width: 200,
                    },
                    url: Udesk.business.apiPath.concatApiPath('/users?pageSize=1000', sdkOptions),
                    // isMulti: true,
                    method: 'GET',
                    queryParamName: 'keyword',
                    nameField: 'realname',
                    enableEmptySearch: true,
                    placeholder: locales.business.info.pleaseSearchAndSelect,
                },
            },
            {
                name: 'time',
                label: locales.pages.businessAnalysis.createTime,
                type: 'RangePicker',
            },
        ],
        layout: 'inline',
        footerRender: () => (
            <Button onClick={() => form?.submit?.()}>{locales.labels.query}</Button>
        ),
        onFinish: search,
    };
    return (
        <Page
            pageBodyClassName="xxx-page-index"
            title={locales.components.pages.businessAnalysis.title}
            padding={true}
            extra={
                <Button type="primary" onClick={goToDetail}>
                    {locales.labels.create}
                </Button>
            }
        >
            <CommonTablePage
                loading={loading}
                tableHeaderLeftRender={<FormBuilder {...config} form={form} />}
                // showExport={true}
                // showColumsControl={true}
                // showFilter={true}
                // filter={filterProps}
                // exportBtn={exportBtnProps}
            >
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
