function getFormatFrequency(frequencyNumber, unitsList) {
    for (let i = 0; i < unitsList.length; i++) {
        frequencyNumber /= 1000;
        if (1 <= frequencyNumber && frequencyNumber < 1000) {
            return frequencyNumber.toFixed(1) + unitsList[i];
        }
        if (frequencyNumber < 1 && frequencyNumber >= 0) {
            return frequencyNumber * 1000;
        }
    }
}
export default {
    getFormatFrequency,
};