import React from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Upload, Icon } from 'udesk-ui';
import './style.scss';
import { postStorageCreateResource } from 'src/api/storage/createResource';

const UploadButton = () => {
    const locales = Locales['current'];
    return (
        <div>
            <Icon type='PlusOutlined' antdIcon />
            <div style={{ marginTop: 8 }}>{locales.components.imgUpload.index.uploadPictures}</div>
        </div>
    );
};

const FormUpload = ({ value, onChange: onChangeProps }) => {
    const locales = Locales['current'];
    const uploadExcelProps = {
        beforeUpload: (file) => {
            if (file.size > 2048 * 1024) {
                Udesk.ui.notify.error(locales.components.imgUpload.index.pleaseSelectAPictureWithASizeOfNoMoreThankb);
                return false;
            }
            uploadExcel(file);
            return false;
        },
        // onRemove: (file) => {
        //     onChange && onChange(null);
        // },
        showUploadList: false,
        fileList: value ? [value] : [],
        // onChange: ({ fileList }) => {
        //     const [file] = fileList;
        //     onChangeProps?.(file);
        // },
        maxCount: 1,
        accept: '.jpg, .jpeg, .png',
    };
    const uploadExcelSuccess = (file, uploadData) => {
        postStorageCreateResource({
            keyName: uploadData.filename
        }).then((resp) => {
            onChangeProps?.(resp.data);
        });
    };
    const uploadExcelError = (file, uploadData) => {
        Udesk.ui.notify.error(locales.components.pages.recordUpload.baseInfo.excelUoloadFail);
    };
    const uploadExcel = (info) => {
        Udesk.app.fileStorage.upload(info, {
            token: new Promise((resolve, reject) => {
                Udesk.business
                    .getServerAuthorization({
                        type: 2,
                    })
                    .then(
                        function (resp) {
                            resolve(resp);
                        },
                        function (reason) {
                            reject(reason);
                        }
                    );
            }),
            //progressCallback: progressCallback.bind(this),
            successCallback: uploadExcelSuccess,
            errorCallback: uploadExcelError,
        });
    };

    return (
        <div className='udesk-qa-img-upload-component'>
            <Upload {...uploadExcelProps} listType='picture-card'>
                {value ? (
                    <img
                        src={value}
                        alt='img'
                        style={{ width: '100%' }}
                    />
                ) : (
                    <UploadButton />
                )}
            </Upload>
        </div>
    );
};

export default FormUpload;
