import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { isQaSubApp } from 'Udesk/system/subApp';

export default class AdminUserIndexComponent extends React.Component {
    //#region state
    state = {
        columns: [],
    };
    //#endregion

    //#region actions
    actions = {
        // 分页请求
        pageChanged(pageNum, pageSize) {
            this.actions.reloadModel({ pageNum, pageSize });
        },

        deleteUser(id) {
            let onConfirm = () => {
                Udesk.ajax.del(Udesk.business.apiPath.concatApiPath(`/users/${id}`, this.props.sdkOptions)).then(
                    resp => {
                        if (resp.data.failTotal) {
                            Udesk.ui.notify.error(resp.data.failMap[id]);
                        } else {
                            this.actions.reloadModel();
                        }
                    },
                    reason => {
                        Udesk.ui.notify.error(
                            reason.errorMsg || UdeskLocales.current.pages.admin.user.index.deleteFailed
                        );
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: UdeskLocales.current.labels.deleteContent,
                onOk: onConfirm
            });
        },

        enable(id) {
            let url = Udesk.business.apiPath.concatApiPath(`/users/${id}/status/1`, this.props.sdkOptions);
            Udesk.ajax
                .put(url, {
                    status: Udesk.enums.userEnableStatus.enabled.id
                })
                .then(
                    resp => {
                        let users = this.privates.model.users.data;
                        users.forEach(item => {
                            if (item.id === id) {
                                item.status = Udesk.enums.userEnableStatus.enabled.id;
                            }
                        });
                        this.actions.update();
                        Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.pages.admin.user.Failed);
                    }
                );
        },

        disable(id) {
            let url = Udesk.business.apiPath.concatApiPath(`/users/${id}/status/0`, this.props.sdkOptions);
            Udesk.ajax
                .put(url, {
                    status: Udesk.enums.userEnableStatus.disabled.id
                })
                .then(
                    resp => {
                        let users = this.privates.model.users.data;
                        users.forEach(item => {
                            if (item.id === id) {
                                item.status = Udesk.enums.userEnableStatus.disabled.id;
                            }
                        });
                        this.actions.update();
                        Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.pages.admin.user.disableFailed);
                    }
                );
        }
    };
    //#endregion

    //#region Life Cycle
    componentDidMount() {
        getColumns(this);
    }
    //#endregion
}

function getColumns(that) {
    const {
        actions,
        locales
    } = that;
    const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    const columns = [
        {
            name: 'id'
        },
        {
            name: 'headPortraitUrl',
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return (
                    <img src={item.headPortraitUrl} alt="" width="30" />
                );
            }
        },
        {
            name: 'realname'
        },
        {
            name: 'nickname'
        },
        {
            name: 'email'
        },
        {
            name: 'mobile'
        },
        {
            name: 'agentId'
        },
        {
            name: 'role',
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                if (!item.roleList || item.roleList.length === 0) {
                    return '';
                }
                return item.roleList[0].name;
            }
        },
        {
            name: 'actions',
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return (
                    <div>
                        <If condition={hasFeature && hasFeature('sys:user:view')}>
                            <NavLink
                                to={`/${isQaSubApp() ? 'site' : 'conversation-analysis'}/admin/user/view/${item.uuid}`}
                                className={'action-btn'}>
                                {locales.labels.view}
                            </NavLink>
                        </If>
                        <If condition={hasFeature && hasFeature('sys:user:edit')}>
                            <NavLink
                                to={`/${isQaSubApp() ? 'site' : 'conversation-analysis'}/admin/user/edit/${item.uuid}`}
                                style={{marginLeft: '5px'}}
                                className={'action-btn'}>
                                {locales.labels.edit}
                            </NavLink>
                        </If>
                        <If condition={hasFeature && hasFeature('sys:user:delete')}>
                            <button
                                style={{marginLeft: '5px'}}
                                className="action-btn delete-btn"
                                onClick={actions.deleteUser.params(item.id)}>
                                {locales.labels.delete}
                            </button>
                        </If>
                        <If condition={hasFeature && hasFeature('sys:user:onOff')}>
                            <Choose>
                                <When condition={item.status === Udesk.enums.userEnableStatus.enabled.id}>
                                    <button
                                        style={{marginLeft: '5px'}}
                                        className="action-btn disable-btn"
                                        onClick={actions.disable.params(item.id)}>
                                        {locales.pages.admin.user.detail.disabled}
                                    </button>
                                </When>
                                <Otherwise>
                                    <button
                                        style={{marginLeft: '5px'}}
                                        className="action-btn"
                                        onClick={actions.enable.params(item.id)}>
                                        {locales.pages.admin.user.detail.enabled}
                                    </button>
                                </Otherwise>
                            </Choose>
                        </If>
                    </div>
                );
            }
        }
    ];
    UdeskLocales.translate(columns, 'name', 'caption', UdeskLocales.current.columns.admin.user);
    that.setState({
        columns
    });
}
