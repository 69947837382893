// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerNodeKnowledgeConfigQuery,
    BaseResponseIntelligentPartnerDialogNodeKnowledgeConfigFoundResponse,
    PostReviewIntelligentPartnerNodeKnowledgeConfigBody,
} from '../../../../types';

/**
 * 查询知识库节点配置
 *
 * @export
 * @tags 智能陪练-知识库问答配置
 * @link [GET] /review/intelligentPartner/node/knowledgeConfig
 */
export function getReviewIntelligentPartnerNodeKnowledgeConfig(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodeKnowledgeConfigQuery> & {
        params: GetReviewIntelligentPartnerNodeKnowledgeConfigQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeKnowledgeConfigFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerNodeKnowledgeConfigQuery>(
        '/review/intelligentPartner/node/knowledgeConfig',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeKnowledgeConfig',
        }
    );
}

/**
 * 知识库问答节点保存
 *
 * @export
 * @tags 智能陪练-知识库问答配置
 * @link [POST] /review/intelligentPartner/node/knowledgeConfig
 */
export function postReviewIntelligentPartnerNodeKnowledgeConfig(
    data: PostReviewIntelligentPartnerNodeKnowledgeConfigBody
): Promise<BaseResponseIntelligentPartnerDialogNodeKnowledgeConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeKnowledgeConfig(
    data: PostReviewIntelligentPartnerNodeKnowledgeConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeKnowledgeConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeKnowledgeConfig(
    data: PostReviewIntelligentPartnerNodeKnowledgeConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeKnowledgeConfigFoundResponse> {
    return request<PostReviewIntelligentPartnerNodeKnowledgeConfigBody, never, never>(
        '/review/intelligentPartner/node/knowledgeConfig',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeKnowledgeConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-知识库问答配置'],
        path: '/review/intelligentPartner/node/knowledgeConfig',
        method: 'get',
    },
    {
        tags: ['智能陪练-知识库问答配置'],
        path: '/review/intelligentPartner/node/knowledgeConfig',
        method: 'post',
    },
];
