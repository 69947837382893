import confirms,{confirmsDefaults} from './confirms';
import bootstrapDurationPicker, { bootstrapDurationPickerDefaults } from './bootstrap-duration-picker';

export default {
    confirms,
    bootstrapDurationPicker,
    setLocale
};

export function initWidgets() {
    this.bootstrapDurationPicker.defaults = bootstrapDurationPickerDefaults();
    this.confirms.confirmDefaults= confirmsDefaults();
}

function setLocale(locale) {

}

export function destroy() {

}