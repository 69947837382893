// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetFieldDataTaskByTaskIdParam,
    BaseResponseListFieldFoundResponse,
} from '../../../../types';

/**
 * findTaskField
 *
 * @export
 * @tags 字段定义
 * @link [GET] /field/data/task/{taskId}
 * @param taskId
 */
export function getFieldDataTaskByTaskId(
    options: ApiOptions<GetFieldDataTaskByTaskIdParam, never> & {
        segments: GetFieldDataTaskByTaskIdParam;
    }
): Promise<BaseResponseListFieldFoundResponse> {
    return request<never, GetFieldDataTaskByTaskIdParam, never>('/field/data/task/{taskId}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getFieldDataTaskByTaskId',
    });
}

export const meta = [{ tags: ['字段定义'], path: '/field/data/task/{taskId}', method: 'get' }];
