import React, { useCallback, useMemo, useState } from 'react';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import { isDefined } from 'src/util/core';
import { Checkbox, Modal, Space, Table, Tag } from 'udesk-ui';
import { sum } from 'lodash-es';
import { TableRowSelection } from 'udesk-ui/lib/table';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { EnumSelect } from 'Component/common/enum-component';
import { useRequest } from 'src/util/hook';
import { EllipsisSpan } from 'src/util/tag';
import { Form } from '@alifd/next';
import { formItemLayout } from './template';
import { linkTo } from 'src/util/link';
import { isQaSubApp } from 'Udesk/system/subApp';
import { hasFeature } from 'src/util/permit';

export const TagTypeComponent = React.memo<any>((props) => {
    const { value, onChange, disabled, funcType } = props;

    const LabelTypes = [
        {
            label: /* 大模型扩充标签 */ UdeskLocales['current'].pages.intelligentAnalysis
                .intelligentTags.detail.formItemComponent.largeModelExtensionLabel,
            value: 4,
        },
    ];

    const checkboxValue = useMemo(() => {
        if (value > 4) {
            return [4];
        }
        return [];
    }, [value]);

    const selectValue = useMemo(() => {
        if (isDefined(value)) {
            if (value > 4) {
                return value - 4;
            }
            if (value < 4) {
                return value;
            }
        }
        return 1;
    }, [value]);

    const showCheckbox = useMemo(() => {
        if (funcType === 'REVIEW_CALL') {
            return hasFeature('sys:largeAi:sap:reviewTag');
        } else {
            return hasFeature('sys:largeAi:qa:smart');
        }
    }, [funcType]);

    return (
        <div>
            <Space>
                <EnumSelect
                    name="labelClassAtion"
                    enumKey="labelTypes"
                    disabled={disabled}
                    value={selectValue}
                    onChange={(value) => {
                        onChange(sum(checkboxValue) + value);
                    }}
                />
                {showCheckbox ? (
                    <Checkbox.Group
                        options={LabelTypes}
                        value={checkboxValue}
                        onChange={(value) => {
                            onChange(selectValue + sum(value));
                        }}
                    />
                ) : null}
            </Space>
        </div>
    );
});

interface DataType {
    recordId: React.Key;
    recordTime: string;
    tags: string[];
}

const DEFAULT_PAGE = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};

export const FlagDataComponent = React.memo<any>((props) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [pagination, setPagination] = useState<any>(DEFAULT_PAGE);
    const {
        loading,
        data: dataSource,
        run,
        refresh,
    } = useRequest({
        onSuccess(resp) {
            resp.data.forEach((item) => {
                item.id = item.callId;
            });
            setPagination((pagination) => {
                return {
                    ...pagination,
                    total: resp.paging.total,
                };
            });
        },
    });
    const { run: deleteRequest } = useRequest({
        onSuccess: useCallback(() => {
            Udesk.ui.notify.success(
                /* 删除成功 */ UdeskLocales['current'].business.info.deleteSuccess
            );
            refresh();
        }, [refresh]),
    });
    const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    }, []);
    const rowSelection: TableRowSelection<DataType> = useMemo(
        () => ({
            selectedRowKeys,
            onChange: onSelectChange,
            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
        }),
        [onSelectChange, selectedRowKeys]
    );

    const tableProps = useMemo<any>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                key: 'recordId',
                dataIndex: 'recordId',
                title: /* 会话记录id */ UdeskLocales['current'].pages.intelligentAnalysis
                    .intelligentTags.detail.formItemComponent.sessionRecordID,
                width: 160,
                fixed: 'left',
            },
            {
                key: 'recordTime',
                dataIndex: 'recordTime',
                width: 160,
                title: /* 会话时间 */ UdeskLocales['current'].pages.intelligentAnalysis
                    .intelligentTags.detail.formItemComponent.sessionTime,
            },
            {
                key: 'tags',
                dataIndex: 'tags',
                width: 320,
                title: /* 智能标签 */ UdeskLocales['current'].pages.intelligentAnalysis
                    .intelligentTags.detail.formItemComponent.smartTags,
                render(tags) {
                    return (
                        <Space wrap>
                            {tags.map((tag) => {
                                return (
                                    <Tag color="blue">
                                        <EllipsisSpan
                                            width="130px"
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            {tag}
                                        </EllipsisSpan>
                                    </Tag>
                                );
                            })}
                        </Space>
                    );
                },
            },
            {
                key: 'actions',
                renderType: 'actions',
                title: UdeskLocales['current'].pages.gong.coachTaskCenter.list.option,
                buttons: [
                    {
                        label: /* 查看详情 */ UdeskLocales['current'].pages.intelligentAnalysis
                            .intelligentTags.detail.formItemComponent.viewDetails,
                        onClick: (item) => {
                            linkTo(
                                props.history,
                                props.funcType === 'REVIEW_CALL'
                                    ? 'gongCallDetail'
                                    : item.inspectDataSource === 2
                                    ? isQaSubApp()
                                        ? 'dialogueManageView'
                                        : 'conversationAnalysisDialogueManageView'
                                    : isQaSubApp()
                                    ? 'callManageView'
                                    : 'conversationAnalysisCallManageView',
                                props.funcType === 'REVIEW_CALL'
                                    ? { callId: item.callId }
                                    : { id: item.callId }
                            );
                        },
                    },
                    {
                        label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.delete,
                        danger: true,
                        onClick: (item) => {
                            const url =
                                props.funcType === 'REVIEW_CALL'
                                    ? '/smartTags/review/related/call'
                                    : '/smartTags/related/call';

                            deleteRequest(
                                url,
                                {
                                    tagId: +props.id,
                                    callIds: [item.callId],
                                },
                                'del'
                            );
                        },
                        confirm: {
                            title: /* 删除后此会话记录的数据将会被清空，是否确认删除？ */ UdeskLocales[
                                'current'
                            ].pages.intelligentAnalysis.intelligentTags.detail.formItemComponent
                                .afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt,
                        },
                    },
                ],
            },
        ];

        return {
            rowKey: 'id',
            columns,
            dataSource,
            pagination,
            loading,
            rowSelection,
        };
    }, [
        dataSource,
        pagination,
        loading,
        rowSelection,
        props.history,
        props.funcType,
        props.id,
        deleteRequest,
    ]);

    const onTableRequest = useCallback(
        (data) => {
            if (props?.id) {
                const { pagination } = data;
                const params = {
                    tagId: props.id,
                    pageNum: pagination.current, // 页数
                    pageSize: pagination.pageSize, // 每页条数
                };

                const url =
                    props.funcType === 'REVIEW_CALL'
                        ? '/smartTags/review/related/call'
                        : '/smartTags/related/call';

                run(url, params);

                setPagination(pagination);
            }
        },
        [props.funcType, props.id, run]
    );

    return (
        <Form.Item
            label={
                /* 标记数据 */ UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags
                    .detail.formItemComponent.tagData
            }
            labelCol={{ fixedSpan: formItemLayout.labelCol.fixedSpan }}
            wrapperCol={{ span: formItemLayout.wrapperCol.span }}
        >
            <div style={{ height: 500 }}>
                <StandardizeTable.RequestTable
                    onRequest={onTableRequest}
                    table={tableProps}
                    toolBarRightButtons={[
                        {
                            label: /* 批量删除 */ UdeskLocales['current'].pages.intelligentAnalysis
                                .intelligentTags.detail.formItemComponent.batchDeletion,
                            disabled: selectedRowKeys.length <= 0,
                            onClick: () => {
                                Modal.confirm({
                                    content: UdeskLocales['current'].labels.deleteContent,
                                    onOk() {
                                        const url =
                                            props.funcType === 'REVIEW_CALL'
                                                ? '/smartTags/review/related/call'
                                                : '/smartTags/related/call';

                                        deleteRequest(
                                            url,
                                            {
                                                tagId: +props.id,
                                                callIds: selectedRowKeys,
                                            },
                                            'del'
                                        );
                                    },
                                });
                            },
                        },
                    ].filter(Boolean)}
                />
            </div>
        </Form.Item>
    );
});
