import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import Udesk from "../../udesk/index";
import Locales from "../../udesk/locales/index";

const DEFAULT_VALUE_FIELD = "id";
const DEFAULT_NAME_FIELD = "name";

const DEFAULT_CUSTOM_AUTO_COMPLETE_STYLES = {
    control: (provided, state) => ({
        ...provided,
        minHeight: "30px",
        backgroundColor: "#FFF"
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        padding: "1px"
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: "4px 8px"
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "4px 8px"
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: "0px 8px"
    }),
};

class AutoComplete extends React.Component {
    privates = {
        emptySearchOptions: []
    }

    static computes = {
        getOptionLabel: ({ props, state, privates, locales }) => {
            let {
                nameField
            } = props;
            return (option) => {
                return option[nameField];
            };
        },
        getOptionValue: ({ props, state, privates, locales }) => {
            let {
                valueField
            } = props;
            return (option) => {
                return option[valueField];
            };
        },
        noOptionsMessageFunc: ({ props, state, privates, locales }) => {
            let {
                noOptionsMessage
            } = props;
            let noOptionsMessageFunc = () => noOptionsMessage;
            if (noOptionsMessage == null) {
                noOptionsMessageFunc = () => Locales.current.components.autoComplete.noOptionsMessage;
            }
            return noOptionsMessageFunc;
        },
        classNames: ({ props, state, privates, locales }) => {
            let {
                classNames
            } = props;
            let componentClass = ["udesk-auto-complete"];
            if (classNames != null && classNames !== "") {
                componentClass.push(classNames);
            }
            return componentClass.join(" ");
        },
        customStyle: ({ props, state, privates, locales }) => {
            let {
                customStyle
            } = props;
            if (customStyle != null) {
                return customStyle;
            }
            return DEFAULT_CUSTOM_AUTO_COMPLETE_STYLES;
        },
        defaultOptions: ({ props, state, privates, locales }) => {
            let {
                defaultOptions,
                enableEmptySearch
            } = props;
            if (enableEmptySearch && (defaultOptions == null || (defaultOptions instanceof Array && defaultOptions.length === 0))) {
                return privates.emptySearchOptions;
            }
            return defaultOptions;
        },
    }

    componentDidMount() {
        let {
            defaultOptions,
            enableEmptySearch
        } = this.props;
        if (enableEmptySearch && (defaultOptions == null || ((defaultOptions instanceof Array) && defaultOptions.length === 0))) {
            let loadOptionsPrimise = this.actions.loadOptions();
            if (loadOptionsPrimise && typeof loadOptionsPrimise.then === "function") {
                loadOptionsPrimise.then((options) => {
                    this.privates.emptySearchOptions = options;
                    this.actions.update();
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            defaultOptions,
            enableEmptySearch,
            url
        } = this.props;
        if (url !== prevProps.url) {
            if (enableEmptySearch && (defaultOptions == null || ((defaultOptions instanceof Array) && defaultOptions.length === 0))) {
                let loadOptionsPrimise = this.actions.loadOptions();
                if (loadOptionsPrimise && typeof loadOptionsPrimise.then === "function") {
                    loadOptionsPrimise.then((options) => {
                        this.privates.emptySearchOptions = options;
                        this.actions.update();
                    });
                }
            }
        }
    }

    actions = {
        onChanged(value) {
            this.props.onChanged(value);
        },
        onFocused(value) {
            this.props.onFocused && this.props.onFocused(value);
        },
        onBlurred(value) {
            this.props.onBlurred && this.props.onBlurred(value);
        },
        loadOptions(inputValue, callback) {
            let {
                loadOptions
            } = this.props;
            if (typeof loadOptions === "function") {
                return loadOptions(inputValue, callback);
            } else {
                let {
                    url,
                    method,
                    queryParamName,
                    getQueryParams,
                    extraQueryParams
                } = this.props;
                if (url == null) {
                    throw new Error("Either `url` is required in auto-complete component!");
                }
                if (typeof getQueryParams !== "function") {
                    getQueryParams = () => {
                        return extraQueryParams || {};
                    };
                }
                let outerQueryParams = getQueryParams.apply(this, [inputValue]);
                let params = Object.assign({
                    [queryParamName]: inputValue
                }, outerQueryParams);
                let that = this;
                return new Promise((resolve, reject) => {
                    Udesk.ajax(url, params, method)
                        .then(
                            function (resp, textStatus, jqXHR) {
                                resolve(innerGetQueryResults(resp, that));
                            },
                            function (reason) {
                                reject(reason);
                            }
                        );
                });
            }
        }
    }

    render() {
        let {
            value,
            disabled,
            placeholder,
            isMulti,
            isClearable
        } = this.props;

        let {
            privates: { computes }
        } = this;


        if (isMulti) {
            return (
                <AsyncSelect value={value} defaultOptions={computes.defaultOptions} loadOptions={this.actions.loadOptions} styles={computes.customStyle} className={computes.classNames} classNamePrefix="udesk-auto-complete" isClearable={isClearable} isDisabled={disabled} placeholder={placeholder} getOptionLabel={computes.getOptionLabel} getOptionValue={computes.getOptionValue} onChange={this.actions.onChanged} noOptionsMessage={computes.noOptionsMessageFunc} isMulti onFocus={this.actions.onFocused} onBlur={this.actions.onBlurred} />
            );
        } else {
            return (
                <AsyncSelect value={value} defaultOptions={computes.defaultOptions} loadOptions={this.actions.loadOptions} styles={computes.customStyle} className={computes.classNames} classNamePrefix="udesk-auto-complete" isClearable={isClearable} isDisabled={disabled} placeholder={placeholder} getOptionLabel={computes.getOptionLabel} getOptionValue={computes.getOptionValue} onChange={this.actions.onChanged} noOptionsMessage={computes.noOptionsMessageFunc} onFocus={this.actions.onFocused} onBlur={this.actions.onBlurred} />
            );
        }
    }
}

function innerGetQueryResults(response, context) {
    let {
        value,
        valueField,
        isMulti,
        isFilterSelectedOption,
        getQueryResults
    } = context.props;
    if (typeof getQueryResults !== "function") {
        getQueryResults = defaultGetQueryResults;
    }
    return getQueryResults(response, {
        value,
        valueField,
        isMulti,
        isFilterSelectedOption
    });
}

function defaultGetQueryResults(resp, options) {
    let data = resp.data;
    if (options.value != null && options.value.length > 0) {
        if (options.isFilterSelectedOption) {
            let valueField = options.valueField;
            data = data.filter((item) => {
                if (options.isMulti) {
                    return !options.selected.some(select => select[valueField] === item[valueField]);
                } else {
                    return (options.selected[valueField] !== item[valueField]);
                }
            });
        }
    }
    return data;
}

AutoComplete.propTypes = {
    valueField: PropTypes.string,
    nameField: PropTypes.string,
    url: PropTypes.string,
    method: PropTypes.string,
    queryParamName: PropTypes.string,
    defaultOptions: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.array
    ]),
    classNames: PropTypes.string,
    customStyle: PropTypes.object,
    isClearable: PropTypes.bool,
    disabled: PropTypes.bool,
    enableEmptySearch: PropTypes.bool,
    isFilterSelectedOption: PropTypes.bool,
    placeholder: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    onChanged: PropTypes.func,
    onFocused: PropTypes.func,
    onBlurred: PropTypes.func,
};

AutoComplete.defaultProps = {
    valueField: DEFAULT_VALUE_FIELD,
    nameField: DEFAULT_NAME_FIELD,
    isMulti: false,
    url: "",
    method: "GET",
    queryParamName: "name",
    loadOptions: null,
    getQueryParams: null,
    extraQueryParams: null,
    defaultOptions: [],
    classNames: "",
    customStyle: null,
    isClearable: true,
    disabled: false,
    enableEmptySearch: false,
    isFilterSelectedOption: false,
    placeholder: "",
    noOptionsMessage: null,
    onChanged: null,
    onFocused: null,
    onBlurred: null,
};

export default Udesk.react.udeskify(AutoComplete);