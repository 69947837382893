// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostIntelligentPartnerTaskInfosWordsCorrectBatchBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 语音转写批量纠错
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/words/correct/batch
 */
export function postIntelligentPartnerTaskInfosWordsCorrectBatch(
    data: PostIntelligentPartnerTaskInfosWordsCorrectBatchBody
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerTaskInfosWordsCorrectBatch(
    data: PostIntelligentPartnerTaskInfosWordsCorrectBatchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerTaskInfosWordsCorrectBatch(
    data: PostIntelligentPartnerTaskInfosWordsCorrectBatchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentPartnerTaskInfosWordsCorrectBatchBody, never, never>(
        '/intelligentPartnerTaskInfos/words/correct/batch',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerTaskInfosWordsCorrectBatch',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/words/correct/batch',
        method: 'post',
    },
];
