import Udesk from 'Udesk';
import AdminUserIndexComponent from './component';

export default class AdminUserIndexRoute extends AdminUserIndexComponent {
    model(changedParams) {
        const { props } = this;
        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );
        if (changedParams) {
            params = Object.assign(params, changedParams);
        }
        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/users', props.sdkOptions);
            Udesk.ajax.get(url, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            users: modelPromise
        };
    }
}
