export default {
  lang: {
    num: '1.',
    total: 'Çubuk/toplam',
    strip: 'Madde',
    more: 'Daha fazla',
    chosen: 'Seçildi',
    cancel: 'Seçimi iptal et',
    bulkOperations: 'Toplu işlemler',
    import: 'İçe aktarma',
  },
};
