// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetSupervisionRuleConfigsByIdParam,
    BaseResponseSupervisionRuleConfigFoundResponse,
    DeleteSupervisionRuleConfigsByIdParam,
    BaseResponseVoid,
} from '../../types';

/**
 * 获取监督项配置详情
 *
 * @export
 * @tags supervision-rule-config-controller
 * @link [GET] /supervisionRuleConfigs/{id}
 * @param id
 */
export function getSupervisionRuleConfigsById(
    options: ApiOptions<GetSupervisionRuleConfigsByIdParam, never> & {
        segments: GetSupervisionRuleConfigsByIdParam;
    }
): Promise<BaseResponseSupervisionRuleConfigFoundResponse> {
    return request<never, GetSupervisionRuleConfigsByIdParam, never>(
        '/supervisionRuleConfigs/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getSupervisionRuleConfigsById' }
    );
}

/**
 * 删除监督项
 *
 * @export
 * @tags supervision-rule-config-controller
 * @link [DELETE] /supervisionRuleConfigs/{id}
 * @param id
 */
export function deleteSupervisionRuleConfigsById(
    options: ApiOptions<DeleteSupervisionRuleConfigsByIdParam, never> & {
        segments: DeleteSupervisionRuleConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteSupervisionRuleConfigsByIdParam, never>(
        '/supervisionRuleConfigs/{id}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteSupervisionRuleConfigsById' }
    );
}

export const meta = [
    {
        tags: ['supervision-rule-config-controller'],
        path: '/supervisionRuleConfigs/{id}',
        method: 'get',
    },
    {
        tags: ['supervision-rule-config-controller'],
        path: '/supervisionRuleConfigs/{id}',
        method: 'delete',
    },
];
