import React from 'react';
import Locales from 'UdeskLocales';

import { Button, Icon } from 'udesk-ui';
import { postCallTestsCallByCallId } from 'src/api/callTests/call/{callId}';

const DownloadAstText = (props) => {
    const locales = Locales['current'];
    const { taskId, callId } = props;
    const goToTest = () => {
        postCallTestsCallByCallId(
            {
                bindingTaskId: parseInt(taskId, 10),
                type: 1,
            },
            {
                segments: {
                    callId: callId,
                },
            }
        ).then((testData) => {
            window.open(`/site/tasks/manage/${taskId}/template/test-check-point/index`);
        });
    };

    return (
        <Button
            onClick={goToTest}
            type='text'
            style={{ paddingRight: 0 }}
            icon={<Icon type='BugOutlined' antdIcon={true} />}
            tooltip={/* 以当前通/对话文本进行测试 */locales.components.audioCallContentInfoV2.components.testBtn.testWithCurrentCommunicationDialogueText}
        ></Button>
    );
};

export default React.memo(DownloadAstText);
