import React from 'react';
import PropTypes from 'prop-types';
import UdeskReact from '../../udesk/react';

class InputList extends React.Component {
    state = {}

    componentDidMount() {
        let {
            list,
            addListValue
        } = this.props;
        if (list.length <= 0 && (typeof addListValue === "function")) {
            addListValue();
        }
    }

    render() {
        let {
            listItemTextFieldName,
            maxListValueLength,
            inputListClassNames,
            addIconClassNames,
            deleteIconClassNames,
            list,
            enableKeywordSearch,
            searchList,
            displayValueFieldName
        } = this.props;

        return (
            <ul className={`component-udesk-react-input-list ${inputListClassNames}`}>
                <For each="item" index="index" of={list}>
                    <li key={`input-list-item-${index}`} className="input-list-item">
                        <input type="text" className="input-box form-control" maxLength={maxListValueLength} value={item[listItemTextFieldName]} onChange={this.actions.inputTextChanged.params(index)} onFocus={this.actions.onFocusInput.params(index)} />
                        <i className={`action-icon ${index === 0 ? addIconClassNames : deleteIconClassNames}`} onClick={this.actions.addOrDeleteListValue.params(item, index)}></i>
                        <If condition={enableKeywordSearch}>
                            <If condition={item.isSearchVisible && searchList && searchList.length > 0}>
                                <ul className="search-list-container">
                                    <For each="searchItem" index="searchIndex" of={searchList}>
                                        <li key={searchIndex} className="search-list-item" onClick={this.actions.selectSearchValue.params(index, searchItem)}>
                                            {searchItem[displayValueFieldName]}
                                        </li>
                                    </For>
                                </ul>
                            </If>
                        </If>
                    </li>
                </For>
            </ul>
        );
    }

    actions = {
        inputTextChanged(index, event) {
            let value = event.target.value;
            let inputTextChanged = this.props.inputTextChanged;
            if (typeof inputTextChanged === "function") {
                inputTextChanged(index, value);
            }
        },
        addOrDeleteListValue(item, index) {
            let {
                emptyErrorTip,
                listItemTextFieldName,
                addListValue,
                deleteListValue
            } = this.props;

            if (index === 0) {
                if (item[listItemTextFieldName] && item[listItemTextFieldName].trim()) {
                    if (typeof addListValue === "function") {
                        addListValue();
                    }
                } else {
                    if (typeof emptyErrorTip === "function") {
                        emptyErrorTip(index);
                    }
                }
            } else {
                if (typeof deleteListValue === "function") {
                    deleteListValue(index);
                }
            }

            // if (item[listItemTextFieldName] && item[listItemTextFieldName].trim()) {
            //     let {
            //         addListValue,
            //         deleteListValue
            //     } = this.props;
            //     if (index === 0) {
            //         if (typeof addListValue === "function") {
            //             addListValue();
            //         }
            //     } else {
            //         if (typeof deleteListValue === "function") {
            //             deleteListValue(index);
            //         }
            //     }
            // } else {
            //     if (typeof emptyErrorTip === "function") {
            //         emptyErrorTip(index);
            //     }
            // }
        },
        selectSearchValue(index, searchItem) {
            let onSelectSearchValue = this.props.onSelectSearchValue;
            if (typeof onSelectSearchValue === "function") {
                onSelectSearchValue(index, searchItem);
            }
        },
        onFocusInput(index) {
            let onFocusInput = this.props.onFocusInput;
            if (typeof onFocusInput === "function") {
                onFocusInput(index);
            }
        }
    }
}


InputList.propTypes = {
    list: PropTypes.array,
    listItemTextFieldName: PropTypes.string,
    maxListValueLength: PropTypes.number,
    inputListClassNames: PropTypes.string,
    addIconClassNames: PropTypes.string,
    deleteIconClassNames: PropTypes.string,
    inputTextChanged: PropTypes.func,
    addListValue: PropTypes.func,
    deleteListValue: PropTypes.func,
    emptyErrorTip: PropTypes.func,
    enableKeywordSearch: PropTypes.bool,
    searchList: PropTypes.array,
    displayValueField: PropTypes.any,
    onSelectSearchValue: PropTypes.func
};

InputList.defaultProps = {
    list: [''],
    listItemTextFieldName: "name",
    maxListValueLength: 30,
    inputListClassNames: '',
    addIconClassNames: 'udesk-qc-iconfont icon-udesk-qc-add',
    deleteIconClassNames: 'udesk-qc-iconfont icon-udesk-qc-reduce',
    enableKeywordSearch: false,
    searchList: [],
    displayValueFieldName: "displayValue"
};

export default UdeskReact.udeskify(InputList);