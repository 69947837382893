import React from 'react';
import UdeskLocales from "UdeskLocales";

class SmartToolsIndexComponent extends React.Component {
    static propTypes = {

    };
    static defaultProps = {

    };
    state = {
        visible: false,
        tags: [
            {
                name: UdeskLocales.current.components.pages.smartTools.title.similarWords,
                key: '1'
            },
            {
                name: UdeskLocales.current.components.pages.smartTools.title.search,
                key: '2'
            },
            {
                name: UdeskLocales.current.components.pages.smartTools.title.semanticTags,
                key: '3'
            },
        ],
        activeTag: '1',
        rightLength: -326,
    };
    privates = {
        flag: true,
        timer: null,
        headerHeight: 155,
        elementId: null,
        startTime: 0,
        animationInFlag: false,
        animationOutFlag: false,
        isFront: true,
        smartTagsRef: React.createRef(),
        sentenceSearchRef: React.createRef(),
        smartTagsCallBack: null,
        smartTagsKeyword: '',
    };

    actions = {
        onClose(isForceClose){
            const defaultEvent = () => {
                this.setState({
                    visible: false,
                },() => {
                    let smartTags = this.privates.smartTagsRef && this.privates.smartTagsRef.current;
                    if(smartTags){
                        smartTags.privates.isClustering = false;
                        smartTags.privates.xhr && smartTags.privates.xhr.abort && smartTags.privates.xhr.abort();
                    }
                    this.actions.update();
                });
            };
            if (typeof isForceClose === 'boolean' && isForceClose) {
                defaultEvent();
            } else {
                if (this.privates.onClose) {
                    this.privates.onClose(defaultEvent);
                } else {
                    defaultEvent();
                }
            }
        },
        open(events, id, type){
            this.setState({
                visible: true,
            });
            let openEvent = events;
            if (typeof events === 'object') {
                openEvent = events.open;
                this.privates.onClose = events.close;
            }
            if(!type){
                this.privates.smartTagsCallBack = openEvent;
                this.privates.smartTagsKeyword = id;
                if(this.state.activeTag === '3'){
                    let smartTags = this.privates.smartTagsRef.current;
                    if(smartTags.privates.isFront){
                        smartTags.actions.search(smartTags.privates.searchValue);
                    }else {
                        if(smartTags.privates.currentTag && smartTags.privates.currentTag.id){
                            smartTags.actions.searchInDetail();
                        }
                    }
                }
            }
            if(type === 'sentenceSearch'){
                if(this.state.activeTag === '2'){
                    let sentenceSearch = this.privates.sentenceSearchRef.current;
                    sentenceSearch.privates.inputValue = id;
                    sentenceSearch.actions.search();
                }
            }
            if(type === 'clustering'){
                let keyWord = id;
                this.privates.smartTagsCallBack = openEvent;
                if(this.privates.smartTagsRef && this.privates.smartTagsRef.current){
                    let smartTags = this.privates.smartTagsRef.current;
                    smartTags.privates.clusteringUrl = `semantic-tag/similar-semantic-tag`;
                    smartTags.privates.isClustering = true;
                    this.actions.update();
                    smartTags.actions.reloadAsyncModel({keyword: keyWord});    
                }
            }
        },
        seleteTag(key){
            this.setState({
                activeTag: key
            });
        },
        onHelpMenuBarMouseDown(event) {
            //只允许鼠标左键拖动
            if (event.button !== 0) return;
            this.privates.startTime = event.timeStamp;
            let isMove = false;
            let helpBoxDom = document.querySelector(".smart-tools-sider-bar-index-page-btn");
            let windowH = document.documentElement.clientHeight;
            let baseY = event.clientY;
            let baseT = helpBoxDom.offsetTop;
            let headerHeight = this.privates.headerHeight;

            function Move(event) {
                let moveY = event.clientY - baseY;
                let resaultT = baseT + moveY;

                if (Math.abs(moveY) > 4) {
                    isMove = true;
                }
                if (resaultT < headerHeight) {
                    resaultT = headerHeight;
                }
                if (resaultT > (windowH - 46)) {
                    resaultT = windowH - 46;
                }
                helpBoxDom.style.top = resaultT + "px";
                return false;//解决拖动选择文字的BUG
            }

            function Up(event) {
                $(document).off('mousemove');
                $(document).off('mouseup');
                if (isMove) {
                    let top = helpBoxDom.style.top;
                    window.localStorage.setItem("udesk_smart_tool_bar_top", top);
                } else {
                    event.stopPropagation();
                }
            }
            $(document).mousemove(Move);
            $(document).mouseup(Up);

        },
        onSortMouseUp(e){
            if (this.privates.startTime) {
                let diffTime = e.timeStamp - this.privates.startTime;
                diffTime < 150 && this.actions.open(); //小于150就执行单击操作
                this.privates.startTime = 0;
            }
        },
        changeTabsDown(){
            this.privates.isFront = false;
            this.actions.update();
        },
        changeTabsUp(){
            this.privates.isFront = true;
            this.actions.update();
        },
        backToIndex(){
            if(this.privates.smartTagsRef.current && this.privates.smartTagsRef.current.actions){
                this.privates.smartTagsRef.current.actions.backToIndex();
            }
        },
        getKeyword(value){
            if(this.privates.smartTagsRef.current && this.privates.smartTagsRef.current.actions){
                this.privates.smartTagsRef.current.actions.getKeyword(value);
            }

        }
    };

    componentDidMount(){
        let context = this;
        
        this.privates.elementId = new Date().getTime() + Math.ceil(Math.random() * 10000000);
        window.$(document).on(`click.smart-tools-sider-bar-${this.privates.elementId}`, function (e) {
            if (!context.isDestroyed && e && e.target && !$(e.target).parents().is("#global-help-box")) {
                if (context.privates.showHelpList) {
                    context.privates.showHelpList = false;
                    context.actions.update();
                }
            }
        });
        //取缓存的meau_bar top值
        let udesk_smart_tool_bar_top = window.localStorage.getItem("udesk_smart_tool_bar_top");
        if (udesk_smart_tool_bar_top) {
            document.querySelector(".smart-tools-sider-bar-index-page-btn").style.top = udesk_smart_tool_bar_top;
        }
        // 窗口变化时，当超出屏幕高度，修改menu_bar的top
        $(window).on(`resize.smart-tools-sider-bar-${this.privates.elementId}`, function () {
            let windowH = document.documentElement.clientHeight;
            let helpBoxDom = document.querySelector(".smart-tools-sider-bar-index-page-btn");
            if (helpBoxDom.offsetTop > windowH - helpBoxDom.offsetHeight) {
                helpBoxDom.style.top = windowH - helpBoxDom.offsetHeight - 10 + "px";
            }
        });
    }
    componentWillUnmount(){
        window.$(document).off(`click.smart-tools-sider-bar-${this.privates.elementId}`);
        window.$(document).off(`resize.smart-tools-sider-bar-${this.privates.elementId}`);
        this.privates.elementId = null;
    }
}

export default SmartToolsIndexComponent;
