import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import React from "react";
export default class TaskBasicInfoComponent extends React.Component {
    privates = {
        inspector: {},
        fetching: false,
        searchValue: '',
        searchUserList: [],
        qualityUserSeleteList: [],
        isEdit: false,
        taskSource: null,
        taskRemark: '',
        taskName: '',
        qualityUser: null,
        inspectDataSources: [],
        // basicForm: Form.useForm(),
    };
    state = {
        inspectorList: []
    };
    static computes = {
        // tasks: [
        //     "model.tasks.data",
        //     function ({ props, state, privates, locales }) {
        //         return (
        //             //FIXME: [手动迁移] this.state中不存在属性audioCallCetailsNavSelected，请手动检查。
        //             this.get("states.audioCallCetailsNavSelected") === "audioText"
        //         );
        //     }
        // ],
        _autoCompleteUrl: function ({ props, state, privates, locales }) {
            return Udesk.business.apiPath.concatApiPath(
                "/users?pageSize=1000",
                props.sdkOptions
            );
        },

    };
    //#region actions
    actions = {
        userListChanged(values) {
            this.setState({
                inspectorList: values
            });
        },
        save() {
            let id = this.privates.model.task.id;
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionTasks/" + id,
                this.props.sdkOptions
            );
            let { privates } = this;
            // let values = this.props.form.getFieldsValue();
            // let inspectorIds = privates.qualityUser != null ? privates.qualityUser.filter(item => item != null) : [];

            let params = {
                name: privates.taskName,
                remark: privates.taskRemark,
                inspectDataSource: privates.taskSource,
                inspectorIds: this.state.inspectorList && this.state.inspectorList.length > 0 ? this.state.inspectorList.map(item => item.id) : null
            };
            Udesk.ajax.put(url, params).then(
                resp => {
                    Udesk.ui.notify.success(
                        UdeskLocales.current.business.info.saveSuccess
                    );
                    this.actions.reloadModel();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || UdeskLocales.current.business.info.saveError
                    );
                }
            );
        },

        deleteTask() {
            let id = this.privates.model.task.id;
            let onConfirm = () => {
                Udesk.ajax
                    .del(
                        Udesk.business.apiPath.concatApiPath(
                            `/inspectionTasks/${id}`,
                            this.props.sdkOptions
                        )
                    )
                    .then(
                        resp => {
                            Udesk.ui.notify.success(
                                UdeskLocales.current.business.info.deleteSuccess
                            );
                            const routeOptions = {
                                history: this.props.history,
                                routeName: 'tasksIndex'
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                        reason => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                UdeskLocales.current.business.info.deleteFailed
                            );
                        }
                    );
            };

            Udesk.ui.alerts.confirm({
                content: Udesk.utils.string.format(
                    UdeskLocales.current.business.info.deleteWarning,
                    UdeskLocales.current.pages.tasks.index.qualityCheckTasks
                ),
                onOk: onConfirm
            });
        },
        gotoTask() {
            const routeOptions = {
                history: this.props.history,
                routeName: "tasksIndex",
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        goToCheckPoint(id) {
            // let url = Udesk.business.apiPath.concatApiPath(
            //     "/inspectionTasks/" + id,
            //     this.props.sdkOptions
            // );
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${id}`,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, { processStatus: 2 })
                .then(
                    resp => { },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                    }
                );

            let routeOptions = {
                history: this.props.history,
                routeName: "tasksCheckPointCreateEntry",
                pathParams: {
                    taskId: id
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        fetchUser(value) {
            this.privates.searchValue = value;
            let params = {
                pageSize: 1000,
            };
            let url = Udesk.business.apiPath.concatApiPath(
                `/users?keyword=${value}`,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url, params)
                .then(
                    resp => {
                        this.privates.searchUserList = resp.data;
                        this.privates.fetching = false;
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                    }
                );

        },
        handleSubmit(e) {
            // e.preventDefault();
            // const [form] = Form.useForm();
            // const values = await form.validateFields();
            // let inspectorIds = this.privates.qualityUser != null ? this.privates.qualityUser.filter(item => item != null) : [];
            if (!this.privates.taskName || !this.privates.taskSource) {
                Udesk.ui.notify.error(UdeskLocales.current.fix.requiredFieldsCannotEmpty);
                return;
            }
            let params = {
                name: this.privates.taskName,
                remark: this.privates.taskRemark,
                inspectDataSource: this.privates.taskSource,
                singleItemScore: null,
                inspectorIds: this.state.inspectorList.map(item => item.id)
            };
            Udesk.ajax.post(
                Udesk.business.apiPath.concatApiPath(
                    `/inspectionTasks`,
                    this.props.sdkOptions
                ),
                params
            ).then(
                resp => {
                    Udesk.ui.notify.success(
                        UdeskLocales.current.business.info.creatSuccess
                    );
                    this.actions.goToCheckPoint(resp.data.id);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        UdeskLocales.current.business.info.creatError
                    );
                }
            );

            // try {
            // } catch (errorInfo) {
            //     Udesk.ui.notify.error('请填写完整');
            // }
            // this.props.form.validateFieldsAndScroll((err, values) => {
            //     if (!err) {
            //         let inspectorIds = values.qualityUser != null? values.qualityUser.filter(item => item != null):[];
            //         let params = {
            //             name: values.taskName,
            //             remark: values.taskRemark,
            //             inspectDataSource: values.taskSource,
            //             singleItemScore: null,
            //             inspectorIds: inspectorIds
            //         };
            //         Udesk.ajax.post(
            //             Udesk.business.apiPath.concatApiPath(
            //                 `/inspectionTasks`,
            //                 this.props.sdkOptions
            //             ),
            //             params
            //         ).then(
            //             resp => {
            //                 Udesk.ui.notify.success(
            //                     UdeskLocales.current.business.info.creatSuccess
            //                 );
            //                 this.actions.goToCheckPoint(resp.data.id);
            //             },
            //             reason => {
            //                 Udesk.ui.notify.error(
            //                     reason.errorMsg ||
            //                     UdeskLocales.current.business.info.creatError
            //                 );
            //             }
            //         );
            //     }else {
            //         Udesk.ui.notify.error('请填写完整');
            //     }
            // });
        },
        close() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'tasksIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        onFormValuesChange(changedValues, allValues) {
            this.privates.taskName = allValues.taskName;
            this.privates.taskRemark = allValues.taskRemark;
            this.privates.taskSource = allValues.taskSource;
            // this.privates.qualityUser = allValues.qualityUser;
            this.actions.update();
        },
    };
    //#endregion
    componentDidMount() {
        let {
            privates,
        } = this;
        if (privates.model && privates.model.task) {
            privates.inspector = privates.model.task.inspectorList && privates.model.task.inspectorList.length > 0 ?
                {
                    key: privates.model.task.inspectorList[0].id,
                    label: privates.model.task.inspectorList[0].realname,
                }
                : null;
            // this.props.form.setFieldsValue({
            //     taskName: privates.model.task.name,
            //     taskRemark: privates.model.task.remark,
            //     taskSource: privates.model.task.inspectDataSource,
            //     qualityUser: privates.inspector
            // });
            // privates.taskName = privates.model.task.name;
            // privates.taskRemark = privates.model.task.remark;
            // privates.taskSource = privates.model.task.inspectDataSource;
            // privates.qualityUser = privates.inspector;
            // this.actions.update();
        }
    }
    onModelResolved(model) {
        let company = model.company;
        let inspectDataSources = [];

        // enabledCallInspect 0:未启用 1:启用语音质检 2:启用实时语音质检 3:都启用
        // 1、语音通话
        if (company.enabledCallInspect === 1) {
            inspectDataSources.push(Udesk.enums.inspectDataSources.voiceCall);
        } else if (company.enabledCallInspect === 2) {
            inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeVoiceCall);
        } else if (company.enabledCallInspect === 3) {
            inspectDataSources.push(Udesk.enums.inspectDataSources.voiceCall);
            inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeVoiceCall);
        }

        // enabledImInspect 0:未启用 1:启用IM质检 2:启用实时IM质检 3:都启用
        // 2、文本对话
        if (company.enabledImInspect === 1) {
            inspectDataSources.push(Udesk.enums.inspectDataSources.textDialogue);
        } else if (company.enabledImInspect === 2) {
            inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeTextDialogue);
        } else if (company.enabledImInspect === 3) {
            inspectDataSources.push(Udesk.enums.inspectDataSources.textDialogue);
            inspectDataSources.push(Udesk.enums.inspectDataSources.realTimeTextDialogue);
        }
        this.privates.inspectDataSources = inspectDataSources;

        if (!this.privates.taskSource) {
            this.privates.taskSource = inspectDataSources[0].id;
        }
    }
}
