export default {
  labels: {
    clear: "Освободить",
    refresh: "Обновить",
    nextStep: "Следующий шаг",
    prevStep: "Шаг вперед",
    closeDriver: "Выход из руководства",
    complete: "Завершение",
    viewStatistics: "Просмотр статистики",
    colon: "：",
    splitter: "/",
    remark: "Примечания",
    correction: "Исправление ошибок",
    save: "Сохранить",
    reset: "Сброс",
    increase: "Добавить",
    preview: "Предварительный просмотр",
    unit: "Полоска",
    cancel: "Отмена",
    all: "Все.",
    searchPlaceHolder: "Введите запрос ключевого слова",
    greaterThan: "Больше, чем",
    lessThan: "Меньше, чем",
    second: "Секунд",
    description: "Описание",
    column: "Столбец",
    pleaseChoice: "Пожалуйста, выберите",
    filter: "Фильтрация",
    saveAndSubmit: "Сохранить и отправить",
    submit: "Представление",
    appeal: "Жалоба",
    review: "Обзор",
    choose: "Выбор",
    manage: "Управление",
    delete: "Удалить",
    add: "Добавлено",
    create: "Новое строительство",
    edit: "Редактирование",
    name: "Название",
    describe: "Описание",
    next: "Следующий шаг",
    percent: "%",
    export: "Экспорт",
    exportAll: "Экспорт все",
    confirm: "Определить",
    back: "Возвращение",
    versionUpdate:
      "Рад сообщить вам, что наш сайт только что выпустил новую версию! Чтобы гарантировать ваше нормальное использование, нам нужно, чтобы вы немедленно сохранили текущую работу, а затем обновить страницу (нажмите кнопку обновления браузера или нажмите клавишу F5), чтобы наша новая версия вступила в силу. Большое спасибо за сотрудничество!",
    more: "Больше",
    selected: "Выбранный",
    deduct: "Пряжка",
    maxScore: "Максимум",
    scoreUnit: "Очки",
    pointUnit: "-Что?",
    comma: "，",
    view: "Посмотреть",
    batchingImport: "Импорт",
    deleteContent:
      "Вы подтвердили, что хотите удалить? После удаления больше не может быть восстановлено",
    import: "Импорт",
    uploadFile: "Загрузка файлов",
    downloadFile: "Экспорт файла",
    upload: "Загрузка",
    click: "Нажмите",
    downloadTemplate: "Скачать шаблон",
    fillInTheForm: "Заполните по формату",
    UploadExcelfile: "Загрузка Excel для завершения пакетного импорта",
    uploadIdentical:
      "Если имя API существует, данные, уже имеющиеся в системе, обновляются при импорте",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Применимо",
    leftBracket: "(",
    rightBracket: ")",
    question: "Вопрос",
    answer: "Ответ",
    content: "Содержание",
    search: "Поиск",
    query: "Запрос",
    cancelEdit: "Отмена редактирования",
    noValue: "Нет данных",
    selectAll: "Полный выбор",
    changeAGroup: "Поменяй группу",
    arrow: " ---> ",
    saveReview: "Представлено на рассмотрение",
    saveSpotCheck: "Представление выборочной проверки",
    rapidSampling: "Быстрый отбор проб",
    file: "Архив",
    download: "Скачать",
    lastPage: "Предыдущая страница",
    nextPage: "Следующий",
    batchActions: "Операции партии",
    cancelDistribution: "Отмена распределения",
    againDistribution: "Перераспределение",
    deleteRecord: "Удаление записей",
    oneOptionOpertor: "Самостоятельный инспектор качества",
    allOptionOpertor: "Все инспекторы по качеству",
    distributionInfoFirstOne: "Подтвердите, что вы верны.",
    distributionInfoFirstTwo: "Перенос записей? Выберите цель распределения",
    distributionInfoSecondOne: "Определите, чтобы отменить",
    distributionInfoSecondTwo:
      "Является ли информация о назначении записи записи, будет изменена на нераспределенную запись обзора после отмены.",
    distributionInfoSecondThree:
      "Информация о назначении записи будет изменена на нераспределенную запись выборки после отмены.",
    distributionInfoThirdOne: "В выбранной записи есть",
    distributionInfoThirdTwo:
      "Статья Записи, которые были проверены, проверенные записи не будут обработаны.",
    distributionInfoThirdThree:
      "Статья Записи, которые были проверены, не будут обработаны.",
    exportError: "Сбой экспорта",
    exportSuccess: "Успешный экспорт",
    deleteRecordInfo:
      "Подтвердите, что вы хотите удалить запись {0}? Данные не будут восстановлены после удаления",
    pleaseSetFilter: "Выберите условия скрининга",
    noCountAfterFilter:
      "Нет экспортированных данных, пожалуйста, установите условия фильтрации",
    redo: "Повторное выполнение",
    batchMove: "Массовое перемещение",
    websiteName: "Интеллектуальная проверка качества",
    salesEnable: "Продаваемые возможности",
    systemName: "Интеллектуальная проверка качества",
    initSystemError:
      "Сетевая аномалия или сбой инициализации страницы, пожалуйста, свяжитесь со службой поддержки",
    day: "Боже!",
    hour: "Час",
    minute: "Очки",
    questionMark: "？",
    personalSettings: "Индивидуальные настройки",
    logout: "Списание",
    ok: "Определить",
    saveAs: "Сохранить как",
    pleaseEnter: "Пожалуйста, введите",
    executeMessage: {
      executeSuccess: "Успешное выполнение статьи {0}",
      executeError: "Сбой выполнения статьи {0}",
    },
    field: "Поля",
    operator: "Оператор",
    value: "Значение",
    placeholder: "Пожалуйста, выберите",
    pleaseSelect: "Пожалуйста, выберите",
    totalScore: "Общая оценка",
    hyphen: "-",
    semicolon: "；",
    wfm: "Интеллектуальное ранжение",
    user: "Пользователи",
    none: "Нет.",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Сохранить успех! Соответствующие сотрудники вступают в силу после обновления страницы",
    saveAsName: "{0}(копия)",
    systemInformation: "Просмотр данных системы",
    billingCenter: "Центр выставления счетов",
    warningMessage:
      "Остальная квота недостаточна, пожалуйста, пополните ее как можно скорее",
    fileTypeHolder:
      "Неверный формат файла, пожалуйста, загрузите файл формата {0}",
    dataNo: "Нет данных",
    move: "Мобильный",
    study: "У вас есть неотмеченные пункты контроля качества самообучения",
    studySuccess: "Успешная маркировка",
    studyError: "Сбой маркировки",
    editType: "Редактирование тегов классификации",
    copyed: "Копировано в буфер обмена",
    clickToCopy: "Нажмите, чтобы скопировать",
    addFilterConditions: "Добавить условия фильтрации",
    filterConditions: "Условия скрининга",
    messageTime: "Время сообщения",
    messageType: "Тип сообщения",
    pushType: "Каналы сообщений",
    messageContent: "Содержание сообщения",
    messageName: "Название сообщения",
    messageStatus: "Состояние сообщения",
    configuration: "Информация о конфигурации",
    sendTarget: "Отправить цель",
    guideUrl: "URL-адрес загрузки",
    noticeDetail: "Подробности сообщения",
    action: "Операция",
    seeDetail: "Посмотреть детали",
    theDay: "{0} день",
    theLastDayOfMonth: "Последний день месяца",
    ignore: "Игнорировать",
    seeNow: "Смотрите сейчас",
    exportConfirmText: {
      export: "Всего {0} данных, подтвердить экспорт?",
      exportAll: "Подтвердите экспорт всего контента?",
    },
    samrTagAdd: "Добавить ярлыки",
    deletePopconfirmContent: "Вы подтвердили, что хотите удалить?",
    TheLastThirtyDays: "Последние 30 дней",
    addNumber: "Плюс",
    subNumber: "Минус",
    addStrategy: "Добавить стратегию",
    minutes: "Минута.",
    status: "Состояние включения",
    pushConfig: "Push-конфигурация",
    category: "Классификация",
    and: "С.",
    interval: "Интервал",
    switchOn: "Открыть.",
    switchOff: "Закрыть",
    batchDel: "Пакетное удаление",
    exportTxt: "Экспорт текста TXT",
    playSpeed: "Удвоение скорости",
    messageLogBtn: "Просмотр звонков/разговоров, которые запускают уведомления",
    dataLink: "Ссылки на данные",
    yes: "Да, да.",
    no: "Нет",
    sentenceUnit: "Предложение",
    appealDetail: "Подробная информация о записи жалобы",
    detail: "Подробная информация",
    default: "По умолчанию",
    customer: "Клиенты",
    agent: "Обслуживание клиентов",
    sort: "Сортировка",
    applyNow: "Применение",
    index: "Серийный номер",
    allColumns: "Добавляемые столбцы",
    selectedColumns: "Добавленные столбцы",
    syn: "Синхронизация",
    saveAndNext: "Сохранить и создать следующий",
    viewReference: "Просмотр ссылок",
    referenceDetail: "Цитирование деталей",
    goToQaList: "Иди к проверке качества",
    goToCheck: "Для выборочной проверки.",
    businessCard: "Визитные карточки",
    weapp: "Малая программа",
    redpacket: "Красный конверт",
    externalRedpacket: "Красный конверт",
    sphfeed: "Номер видео",
    exportTaskCreated: "Успешное создание экспортной задачи",
    plzGoTo: "Пожалуйста, перейдите на",
    importTaskCreated: "Успешное создание задачи импорта",
    taskCreated: "Создание миссии успешно",
    reply: "Ответить",
    replied: "Ответ.",
    release: "Опубликовать",
    expandMore: "Развернуть другие {0} ответы",
    comment: "Комментарии",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Пожалуйста, иметь хотя бы один {0}",
      canNotBeEmptyFormat: "{0} не может быть пустым",
      repeatErrorTipFormat: "{0} с повторением",
      getInformationFailedFormat:
        "Сбой получения информации {0}, проверьте подключение к сети или повторите попытку позже",
      deleteSuccessFormat: "{0} Удаление прошло успешно.",
      deleteFailedFormat:
        "{0} Удаление не удалось, пожалуйста, попробуйте позже.",
      saveSuccessFormat: "{0} Сохранение прошло успешно.",
      saveFailedFormat:
        "{0} Сохранение не удалось, пожалуйста, попробуйте позже.",
      fileSuccessFormat: "{0} успешно архивируется.",
      fileFailedFormat:
        "{0} Заархивирование не удалось, пожалуйста, попробуйте позже.",
      exportSuccessFormat: "{0} Успешный экспорт",
      exportFailedFormat:
        "{0} не удалось экспортировать, пожалуйста, попробуйте позже.",
      submitSuccessFormat: "{0} Успешная подача.",
      submitFailedFormat:
        "{0} не удалось отправить, пожалуйста, попробуйте снова позже.",
      confirmSuccessFormat: "{0} Подтвердите успех.",
      confirmFailedFormat:
        "{0} Подтверждение неудачи, пожалуйста, попробуйте позже.",
      pleaseUploadFile: "Пожалуйста, сначала загрузите файл!",
      importFileSuccess: "Успешный импорт файлов!",
      importFildFailed: "Сбой импорта файлов!",
      deleteSuccess: "Удаление прошло успешно!",
      deleteFailed: "Удаление не удалось, повторите попытку позже!",
      illegalTitle: "Недостаточные разрешения",
      illegalMessage:
        "У вас нет некоторых или всех разрешений на следующий шаг, пожалуйста, свяжитесь с администратором конфигурации, нажмите кнопку «ОК», чтобы выйти из загрузки",
      illegalMessageCurrent:
        "У вас нет некоторых или всех разрешений на текущий шаг, пожалуйста, свяжитесь с администратором конфигурации, нажмите кнопку «ОК», чтобы выйти из загрузки",
      skipMessage:
        "Если вы хотите временно пропустить этот шаг, нажмите Пропустить",
      errorMsg500:
        "Ошибка запроса сервера, проверьте свои действия или обновите повторную попытку",
      errorMsg404: "Ooops... страница, которую вы ищете, пропала",
      errorMsg400: "Сбой запроса сервера, проверьте данные и повторите попытку",
      pageOverFlow:
        "В настоящее время поддерживается только отображение 10000 статей, пожалуйста, установите условия фильтрации для просмотра",
      isHandAddSmartTag: "Является умной меткой, добавляемой вручную",
      defaultGradeMustAtLast: "Рейтинг по умолчанию должен быть в конце",
      AutomaticAllocationInspectorsIsNull:
        "Пожалуйста, перейдите на страницу основной информации, чтобы добавить инспектора качества",
      timePickerTips:
        "Чтобы не повлиять на ваше нормальное использование, 23:00 ~ 2:00-это время обслуживания системы, и временные задачи не выполняются.",
      batchDeleteInfo:
        "Статья {0} была успешно удалена, статья {1} не удалась, и причиной неудачи было то, что данные были использованы.",
      batchDeleteTips: "Проверьте {0} данных и подтвердите удаление?",
      correctionSuccess: "Исправление ошибок успешно",
    },
    pages: {
      qualityInspectionForm: {
        list: { IsMaxFormNumberError: "Добавить до 10 форм контроля качества" },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "Сумма элементов индекса {0} должна составлять 100",
          banProjectMaxErrorTip: "Добавить до 2000 запрещенных предметов",
          deadlyProjectMaxErrorTip: "До 500 смертельных предметов",
        },
      },
    },
    info: {
      creatSuccess: "Создание успеха",
      creatError: "Сбой создания",
      editSuccess: "Модификация прошла успешно",
      editError: "Сбой модификации",
      deleteSuccess: "Удаление прошло успешно",
      addSuccess: "Новый успех",
      deleteError: "Сбой удаления",
      conditionError: "Ошибка логического выражения оператора",
      customJudgeLogic:
        "Пользовательские условные комбинированные ошибки логических выражений",
      exportError: "Сбой экспорта",
      searchError: "Сбой скрининга",
      loadError: "Ошибка загрузки {0}",
      saveSuccess: "Успешное сохранение",
      saveError: "Сбой сохранения",
      fileSuccess: "Успешное архивирование",
      fileError: "Сбой архивирования",
      cannotBeNull: "{0} не может быть пустым!",
      pleaseSearchAndSelect: "Пожалуйста, введите ключевое слово для поиска",
      clickAndToggle: "Нажмите, чтобы выбрать переключение",
      deleteWarning:
        "Удаление {0} не будет восстановлено, вы уверены, что хотите продолжить?",
      getOssAuthFailed:
        "Получить разрешение OSS не удалось, проверьте подключение к сети или попробуйте позже.",
      getInsightAuthFailed:
        "Получение разрешения Insight не удалось, проверьте подключение к сети или повторите попытку позже.",
      pictureUploadFailed: "Неудачная загрузка изображения",
      pictureUploadFailedSize:
        "Размер изображения превышает лимит, загрузка изображения не удалась",
      wecomTagsSyncInfo:
        "Последнее обновление:{0}, синхронизация {1} информации",
      collectSuccess: "Коллекция прошла успешно",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "Сбой получения данных отчета" },
      waveAudio: {
        noWaveMessage:
          "Чтобы сохранить ваш опыт, записи, превышающие {0} минут, пока не отображаются, вы можете воспроизводить их напрямую",
      },
      customFilter: {
        del: "Удалить",
        edit: "Редактирование",
        new: "Пользовательский фильтр",
        apply: "Применение",
        cancel: "Отмена",
        onlyApply: "Применимо только",
        ohterSave: "Сохранить как",
        save: "Сохранить",
        customFilter: "Общий скрининг",
        reset: "Сброс",
        holderKeyWord: "Введите ключевые слова",
        customFilterRule: "Логические правила фильтрации:",
        customFilterName: "Имя фильтра:",
        holderCustomFilterName: "Введите имя фильтра",
        manualConfiguration: "Ручная настройка",
        holderCustomRule: "Введите пользовательские логические условия",
        example: "Примеры",
        dragConfiguration: "Настройка перетаскивания",
        condition: "Условия",
        operation: "Вычисления",
        infoDel: "Удаление контента из области",
        toConfigure: "Конфигурация",
        holderCustomFilterNameLength: "Имя фильтра имеет максимальную длину 10",
        customFilterNameNone: "Имя фильтра не может быть пустым",
        and: "И",
        or: "Или",
        not: "Не",
        delSuccess: "Удаление прошло успешно",
        saveSuccess: "Успешное сохранение",
        updateSuccess: "Модификация прошла успешно",
        customFilterCondition: "Условия скрининга",
        conditionRule: "Условные правила",
        none: "Нет.",
        filterPlaceholder: "Введите ключевые слова",
      },
      labelTabs: {
        addThis: "Добавить к этому",
        goThis: "Перейти к этому",
        saveGo: "Сохранить и тренировать",
      },
      datePicker: {
        today: "Сегодня",
        yesterday: "Вчера",
        theDayBeforeYesterday: "Позавчера",
        thisWeek: "На этой неделе",
        lastWeek: "На прошлой неделе",
        thisMonth: "В этом месяце",
        lastMonth: "В прошлом месяце",
        lastSevenDay: "Последние 7 дней",
        lastThirtyDay: "Последние 30 дней",
        thisQuarter: "В этом квартале",
        lastQuarter: "Последний квартал",
        thisYear: "В этом году",
        lastYear: "В прошлом году",
        lastYearFromToday: "Последний год",
      },
      pagination: { page: "Всего {0}, страница {1}" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Загрузка более ранних сообщений",
          bottom: "Загрузка большего количества сообщений",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Задачи контроля качества",
        informationCollectTasksManageTitle: "Задачи сбора",
        customerServiceCenterTitle: "Центр обслуживания клиентов",
        informationTitle: "Центр обработки данных",
        intelligentAnalysisTitle: "Интеллектуальный анализ",
        semanticIntelligence: "Семантический интеллект",
        trainingCenterTitle: "Учебный центр",
        adminTitle: "Управление системами",
      },
      qualityCheckingTask: {
        title: "Проверка качества вызова",
        index: {
          tipOne:
            "Проверка качества вызова используется для оценки качества работы колл-центра сотрудников. Вы можете объединить свой бизнес и настроить соответствующую форму контроля качества",
          tipTwo:
            "Соответствующие правила могут быть установлены для задач контроля качества, включая скрининг, отбор проб и т. Д. После того, как инспектор по качеству проведет оценку, служба поддержки клиентов может проверить результаты проверки качества и подать дальнейшую апелляцию.",
          addTaskTitle: "Добавить задачу контроля качества",
          deleteTipTitle: "Удалить задачу контроля качества",
          deleteTipContent:
            "Задачи контроля качества не могут быть восстановлены после удаления. Подтвердите удаление?",
          checkingTask: "Задачи контроля качества",
          checkingTaskList: "Список задач контроля качества",
          formList: "Список форм",
          viewHelpDocuments: "Просмотр справочной документации",
        },
        manage: {
          taskSetting: {
            title: "Настройка задачи",
            form: "Форма",
            basicInfo: "Основная информация",
            visibleRange: "Видимый диапазон",
            visibleRangeTip:
              "Выбранная группа обслуживания клиентов и обслуживания клиентов может просматривать задачу контроля качества, а также может сочетать настройки разрешений для этой задачи с руководителем группы обслуживания клиентов, службой поддержки клиентов, инспектором по качеству и т. Д.",
            automaticSampling: "Автоматическая выборка",
            automaticSamplingTip:
              "Вы можете установить правила для автоматической выборки, чтобы система регулярно добавляла задачи контроля качества в соответствии с правилами, которые вы установили, например, каждый понедельник, чтобы вытягивать 5% звонков на прошлой неделе",
            samplingRate: "Частота выборки",
            samplingTime: "Время выборки",
            samplingRange: "Диапазон выборки",
            samplingRule: "Правила выборки",
            fillterCondition: "Условия скрининга",
            workflow: "Рабочий процесс контроля качества",
            appealsTime: "Разрешенное время для обжалования",
            appealsTimeTipOne: "После представления рейтинга",
            appealsTimeTipTwo: "В дни",
            appealsTimeTipThree:
              "После того, как разрешенное время апелляции, статус проверки качества записи автоматически изменяется на «Подтверждено»",
            auditTime: "Время обработки аудита",
            auditTimeTipOne: "После подачи жалобы",
            auditTimeTipTwo: "В дни",
            auditTimeTipThree:
              "После времени обработки аудита запись сохраняет исходный балл, и статус проверки качества автоматически изменяется на «Подтверждено»",
            noAppealsMode: "Нет модели апелляции",
            appealsMode: "Разрешенные модели жалоб",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Обслуживание клиентов",
            customerServicesGroup: "Группа обслуживания клиентов",
            surveyList: "Список оценок",
            customFields: "Пользовательские поля",
            fieldConfig: "Конфигурация поля",
            from: "Из",
            owner: "Ответственный",
            inspectorAndReviewerAuthority: "Полномочия проверки качества",
            inspector: "Инспектор качества",
            reviewer: "Рецензиер",
            inspectorTip: "Инспектор качества может оценить задачу",
            reviewerTip: "Рецензиат может оценить задачу",
            dataStatisticsAuthority: "Разрешения на статистику данных",
          },
          samplingRecords: {
            title: "Запись выборки",
            index: {},
            manualSampling: {
              title: "Выборка",
              fillterCondition: "Условия скрининга",
              samplingRule: "Правила выборки",
              agent: "Обслуживание клиентов",
              type: "Тип",
              result: "Результаты",
              evaluate: "Оценка",
              relayNumber: "Номер эстафеты",
              source: "Источник",
              task: "Задачи",
              queueState: "Статус очереди",
              callHanger: "Вызов",
              queueTime: "Выстраивка занимает много времени",
              talkTime: "Продолжительность звонка",
              bellTime: "Длина звонка",
              recordCountTipOne: "Итого",
              recordCountTipTwo: "Запись",
              customerServices: "Обслуживание клиентов",
              customerServicesGroup: "Группа обслуживания клиентов",
              surveyList: "Список оценок",
              customFields: "Пользовательские поля",
              fieldConfig: "Конфигурация поля",
              defaultFields: "Поле по умолчанию",
            },
          },
          taskList: {
            title: "Список задач",
            index: {
              batchDelete: "Пакетное удаление",
              batchSubmit: "Пакетное представление",
              batchConfirm: "Пакетное подтверждение",
              itemFields: "Оценка полей проекта",
              categoryFields: "Поля классификации второго уровня",
              forbidsAndDeadlies: "Запрещенные и смертельные",
              scoreFieldList: "Список полей подсчета очков",
              customerName: "Название клиента",
              cellphone: "Телефон",
              email: "Почтовый ящик",
              organization: "Компания",
              description: "Описание",
              tags: "Этикетки",
              taskUserRole: "Роль пользователя текущей задачи",
            },
            workBench: {
              title: "Подробности записи",
              checkForm: "Форма контроля качества",
              waitEvaluate: "Для оценки",
              alreadyEvaluate: "Оценка",
              waitSubmit: "В ожидании представления",
              alreadySubmit: "Представлено",
              waitConfirm: "Подтвердить",
              alreadyConfirm: "Подтверждено",
              waitReview: "В ожидании рассмотрения",
              alreadyReview: "Это было пересмотрено.",
              alreadyDone: "Завершено",
              comment: "Замечания по оценке",
              totalScore: "Общая оценка",
              score: "Очки",
              remark: "Примечания",
              changeRecords: "Запись изменений",
              subject: "Темы",
              secondLevelScore: "Второй рейтинг",
              deadlyList: "Смертельный элемент",
              forbidList: "Запрет",
              addToCheckTask: "Добавить в задачи контроля качества",
              submitCheckRemark: "Представлено для оценки качества",
              changeCheckRemark: "Изменена оценка качества",
              doneCheckRemark: "Завершена оценка качества",
              submitCheckReview: "Подан для проверки качества",
              submitCheckAppeal: "Подана жалоба на проверку качества",
              saveFlowSuccess: "Оценка была успешной.",
              saveFlowFailed:
                "Оценка не удалась, пожалуйста, попробуйте позже.",
              FormDetail: "Подробности формы",
              callDetail: "Сведения о записях звонков",
              sampleCalllogDetail: "Подробности записи",
              appealReasonTip:
                "Пожалуйста, введите причину апелляции (обязательно)",
              appeal: "Жалоба",
              appealReason: "Основания для апелляции",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Проверка качества формы",
        basicInformationTitle: "Основная информация",
        indicatorsProjectTitle: "Проект показателей",
        specialProjectTitle: "Специальные элементы",
        totalScore: "Общая оценка",
        indicatorsName: "Название индикатора",
        score: "Оценка",
        singleScore: "Индивидуальные баллы",
        weight: "Вес",
        maxDeductScore: "Максимальное количество очков",
        add: "Увеличение",
        detail: "Подробности формы",
        list: {
          title: "Форма контроля качества",
          tipOne:
            "Настройка формы контроля качества является первым шагом в работе по контролю качества. Вы можете настроить наиболее подходящую форму контроля качества в сочетании с бизнесом компании.",
          tipTwo:
            "Мы поддерживаем различные формы, такие как прямые оценки, взвешенные оценки и системы вычетов. После установки правил рекомендуется добавить их в систему.",
        },
        basicInformation: {
          formTemplate: "Шаблон формы",
          directScore: "Прямой рейтинг",
          weightedScore: "Оценка веса",
          deductScore: "Вычет баллов",
          directScoreSecondLevel: "Прямой рейтинг-второй уровень",
          weightedScoreSecondLevel: "Оценка веса-второй уровень",
          deductScoreSecondLevel: "Счет баллов-второй уровень",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Запрет",
          banProjectTip:
            "Он может использоваться для проверки при оценке качества, которая может рассматриваться как нарушение или как дополнение к примечания, не влияя на фактическую оценку.",
          deadlyProject: "Смертельный элемент",
          deadlyProjectTip:
            "Смертельный элемент может быть использован для проверки при оценке качества. Если флажок флажка имеет смертельный элемент, задача проверки качества напрямую оценивается в 0 баллов.",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Проверка качества вызова",
          entiretyReportTitle: "Общий отчет",
          serviceGroupReportTitle: "Отчет группы обслуживания клиентов",
          serviceReportTitle: "Отчеты по обслуживанию клиентов",
          entiretyReport: {
            pandect: "Обзор",
            contrast: "Контраст",
            trend: "Тенденции",
            analyze: "Анализ",
            totalScore: "Общая оценка",
            banProject: "Запрет",
            deadlyProject: "Смертельный элемент",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Список результатов контроля качества",
          taskSelectPlaceholder: "Выбор задачи контроля качества",
          agentSelectPlaceholder: "Выбор обслуживания клиентов",
          refresh: "Обновить",
          all: "Все.",
          unread: "Непрочитанный",
          inComplaint: "В жалобе",
          reviewed: "Это было пересмотрено.",
          totalScore: "Общий обзор",
          detail: "Детали контроля качества",
          audioText: "Текст записи",
          callInfo: "Информация о вызове",
          ticketInfo: "Информация о наработке",
          wechatInfo: "Информация о сеансе",
          dialogText: "Текст диалога",
          dialogInfo: "Информация для диалога",
          customer: "Клиенты",
          agent: "Обслуживание клиентов",
          none: "Нет данных контроля качества",
          appealSuccess: "Обжалование прошло успешно!",
          appealFailed: "Обжалование не удалось, пожалуйста, попробуйте позже!",
          pointListIsNull:
            "Точки проверки качества жалобы не могут быть пустыми!",
          pleaseSelectPoint: "Пожалуйста, выберите пункт контроля качества!",
          unsupportedFormat: "Формат пока не поддерживается",
          shardingInformation: "Ниже приводится осцилла {0}",
          smartTags: "Умные этикетки",
          businessRecords: "Бизнес-записи",
          selectedPointsWarning:
            "Пожалуйста, проверьте пункт проверки качества для апелляции, укажите соответствующие заявления для пунктов апелляции и нажмите «Подать».",
          applyReason: "Основания для апелляции (до 50 слов)",
          chatMessageTypes: {
            all: "Все.",
            text: "Текст",
            image: "Картинки",
            agree: "Согласитесь с содержанием сеанса",
            video: "Видео",
            voice: "Голос",
            file: "Документы",
            emotion: "Выражение",
            link: "Ссылки",
            chatrecord: "Запись чата",
            mixed: "Смешанные сообщения",
            default: "Сообщения не поддерживаются",
            deleteFriend: "Удалить друзей",
            redpacket: "Красный конверт",
            card: "Визитные карточки",
            disAgree: "Не согласен с содержанием сеанса",
            weapp: "Малая программа",
            externalRedpacket: "Красный конверт",
            meetingVoicevCall: "Сообщение о вызове",
            uRL: "Веб-страница",
            replyTimeout: "Ответы на сверхурочные",
            sphfeed: "Номер видео",
            chatRecordTitle: "Запись чата",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Общая оценка",
            actualScore: "Рейтинг",
            actualGrade: "Рейтинг",
            appeal: "Жалоба",
            remark: "Ручные замечания",
            none: "Нет подробных данных о проверке качества",
            pointEdit: "Точка контроля качества Редактирование классификации",
          },
          changeList: {
            title: "Запись изменений",
            user: "Пользователи",
            point: "Точка контроля качества",
          },
          relatedRecord: {
            title: "Связанные записи",
            qualityList: "Задачи контроля качества",
            none: "Нет.",
            check: "Посмотреть",
            loadMore: "Загрузка больше",
          },
        },
      },
      grade: {
        title: "Рейтинг контроля качества",
        subTitle:
          "Рейтинг контроля качества используется для оценки качества после завершения оценки качества качества.",
        list: {
          up: "Вниз вверх",
          down: "Сниз",
          del: "Удалить",
          case: "Автоматическое присоединение к библиотеке дел",
          orderCase: "Библиотека по умолчанию",
          info: "Рейтинг по умолчанию не соответствует критериям и не может быть удален.",
          add: "Добавить рейтинг",
          save: "Сохранить",
          close: "Отмена",
          max: "Оценки качества могут быть настроены до 20",
          name: "Без имени",
          success: "Успешное сохранение",
        },
      },
      qualityInspectionList: {
        title: "Список контроля качества",
        fastFilter: "Нарушения контроля качества",
        fastFilterTip:
          "Удар по системе очков-это нарушение, а промах по системе очков-это нарушение",
        titleWechat: "Список анализа",
        titleCollect: "Список коллекции",
        status: "Состояние анализа",
        reQualityInspectionConfig: "Опция задачи повторного контроля качества",
        pleaseSelectRecheckPoints:
          "Пожалуйста, выберите точку контроля качества, которая требует повторного учета",
        beginReQualityInspection: "Начало выполнения",
        cancelReQualityInspection: "Отмена задачи",
        reQualityInspection: "Повторная проверка качества",
        reQualityInspectionWechat: "Повторный анализ",
        reQualityInspectionUnderWay: "Повторная проверка качества...",
        reQualityInspectionUnderWayTip:
          "Оставить страницу после закрытия окна не повлияет на выполнение задачи",
        reQualityInspectionDone: "Проверка качества завершена",
        reQualityInspectionDoneTip:
          "Проверка качества была успешно завершена, нажмите кнопку «Подтвердить»",
        endReQualityInspection: "Прекращение миссии",
        alreadyDoneTip: "Выполнено {0}",
        totalTip: "Всего {0}",
        endReInspectionSuccess:
          "Задача повторного контроля качества прекращена",
        closeWindow: "Закрыть окно",
        endReInspectionFailed:
          "Повторная проверка качества не удалась, пожалуйста, попробуйте позже!",
        detail: {
          title: "Детали контроля качества",
          wechatTitle: "Детали анализа",
          titleCollect: "Сведения о сборе",
          callIndex: "Связанный звонок",
          textIndex: "Связанный диалог",
          fileConfirm: "Подтвердите подачу?",
          fileConfirmDesc:
            "Вы не сможете отредактировать оценку контроля качества после архивирования, пожалуйста, подтвердите, заархивировать ли",
        },
        sysCloumnsContal: "Управление полями системного столбца",
        personCloumnsContal: "Управление полями личных столбцов",
      },
      spotCheckList: {
        title: "Список выборочных проверок",
        onlyMyTip: "Просто посмотрите на мои выборочные проверки",
        plInspector: "Пожалуйста, выберите инспектора по отбору проб",
        allInspector: "Посмотреть все",
        seInspector: "Просмотр назначенных выборочных инспекторов",
        myInspector: "Только посмотри на меня.",
        noInspector: "Просмотр нераспределенных выборочных инспекторов",
        detail: { title: "Детали выборочной проверки" },
      },
      reviewList: {
        title: "Список обзора",
        onlyMyTip: "Просто посмотрите на мой обзор",
        plInspector: "Пожалуйста, выберите рецензиер",
        allInspector: "Посмотреть все",
        seInspector: "Просмотр назначенных рецензистов",
        myInspector: "Только посмотри на меня.",
        noInspector: "Просмотр нераспределенных рецензистов",
        detail: { title: "Подробности обзора" },
      },
      caseBaseList: {
        title: "Библиотека дел",
        del: "Удалить",
        delTitle: "Весь контент не сохраняется после удаления",
        cancel: "Отмена",
        actions: "Операция",
        seeCaseBase: "Посмотреть случай",
        seeDetail: "Посмотреть детали",
        edit: "Редактирование",
        save: "Сохранить",
        addCaseBase: "Добавлена библиотека случаев",
        export: "Экспорт",
        ok: "Определить",
        tip: "Типичные данные о вызовах и диалогах в списке контроля качества могут быть сохранены в библиотеке кейс для удобства обучения и обмена",
        detail: {
          choiceCaseBase: "Пожалуйста, выберите случай",
          delCaseBase: "Удаление случая",
          addCaseBase: "Добавить случай",
          successed: "Успешное сохранение",
          info: "Пожалуйста, сначала сохраните или отмените редактируемые материалы",
          caseType: "Выберите библиотеку дел для присоединения",
        },
      },
      testSetList: {
        title: "Тестовый набор контроля качества",
        tip: "Данные выборочной проверки инспектором качества будут сохранены в наборе тестов контроля качества, что удобно для оптимизации условий проверки качества и конфигурации правил проверки качества для некоторых ошибок проверки качества, которые были выровнены после выборочной проверки.",
        refresh: "Обновить",
        reQualityInspectionUnderWay: "Переоценка миссии...",
        reQualityInspectionUnderWayTip:
          "Оставить страницу после закрытия окна не повлияет на выполнение задачи",
        reQualityInspectionDone: "Повторная оценка завершена",
        reQualityInspectionDoneTip:
          "Повторная оценка была успешно завершена, нажмите кнопку «Подтвердить»",
        endReQualityInspection: "Прекращение миссии",
        alreadyDoneTip: "Выполнено {0}",
        totalTip: "Всего {0}",
        endReInspectionSuccess: "Миссия переоценки прекращена",
        closeWindow: "Закрыть окно",
        endReInspectionFailed:
          "Повторная оценка миссии не удалась, повторите попытку позже!",
        recheckPoint:
          "Повторный учет баллов и точности точек контроля качества",
        recheckPointTitle: "Повторная оценка вариантов",
        delete: {
          reQualityInspectionUnderWay: "Пакетное удаление задач в...",
          reQualityInspectionUnderWayTip:
            "Оставить страницу после закрытия окна не повлияет на выполнение задачи",
          reQualityInspectionDone: "Задача массового удаления завершена",
          reQualityInspectionDoneTip:
            "Пакетное удаление теперь успешно завершено, нажмите кнопку «Подтвердить»",
          endReQualityInspection: "Прекращение миссии",
          alreadyDoneTip: "Выполнено {0}",
          totalTip: "Всего {0}",
          endReInspectionSuccess: "Массовое удаление задачи прекращено",
          closeWindow: "Закрыть окно",
          endReInspectionFailed:
            "Массовое удаление не удалось, повторите попытку позже!",
        },
        reultList: {
          assessmentTime: "Время последней оценки",
          assessmentAll: "Общая оценка",
          strip: "Полоска",
          spot: "Точка",
          deviation: "Отклонение",
          accuracyRate: "Скорость точности",
          info: "Нет результатов оценки, пожалуйста, оцените",
          onlyDeviationInfo: "Просмотр только записей отклонений",
          againAssessment: "Переоценка",
          screen: "Фильтрация",
          batchDelete: "Пакетное удаление",
          matching: "Матч",
          ok: "Да, да.",
          noCancel: "Нет",
        },
      },
      smartWordsLibrary: {
        title: "Интеллектуальный тезаурус",
        tip1: "Синонимы: обучение для уменьшения количества семантических тегов, синонимические аналогичные предложения без повторного обучения",
        tip2: "Профессиональные существительные: оптимизация транслитерация и оптимизация понимания естественного языка для неправильной идентификации фирменных имен, таких как названия компаний, названия продуктов и отраслевой словарь, для повышения точности идентификации и точности анализа",
        tip3: "Следите за словами: сосредоточьте внимание на словах, которые вы хотите обратить внимание в анализе облака слов, чтобы помочь ИИ проанализировать горячие точки в соответствии с текущим бизнесом",
        tip4: "Блокировка слов: блокируйте слова, которые вы не хотите обращать внимание в анализе облака слов, чтобы помочь ИИ проанализировать горячие точки в соответствии с текущим бизнесом",
        tip5: "Вес слова: используется для исправления ошибок и оптимизации распознавания семантических тегов, путем изменения веса влияния слов в предложении и оптимизации эффектов семантического распознавания в определенных сценариях по мере необходимости",
        tip6: "Данные корпуса: введите текст большого абзаца для соответствующих бизнес-сценариев для коррекции ошибок и обучения ASR для повышения точности передачи ASR",
        upload: {
          uploadSynonymWords: "Импорт синонимов",
          uploadProfessionalWords: "Импорт профессиональных существительных",
          uploadAttentionWords: "Импорт слов внимания",
          uploadIgnoreWords: "Импорт игнорирует слова",
          uploadWordsWeight: "Импорт весовых слов",
          uploadTypo: "Импорт наборов опечаток",
          uploadCorpusData: "Импорт данных состава",
          uploadCorpusContent: "Импорт содержимого данных состава",
          firstUploadStep: "1. Скачать шаблон, заполнить по формату шаблона",
          downloadTemplateMessage: "Скачать шаблон:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Нажмите кнопку импорта, чтобы загрузить файл EXCEl, который соответствует формату шаблона",
          secondUploadCorpusDataStep:
            "2. Нажмите кнопку импорта, чтобы загрузить файл TXT в соответствии с форматом шаблона",
          postUploadDataFail: "Сбой отправки загруженных данных",
          pleaseUploadTemplate: "Пожалуйста, загрузите файл EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "Шаблон файла может быть только в формате JSON или xlsx",
          templateNeedTxt:
            "Шаблон файла данных корпусов может быть только для файлов TXT",
          uploadSuccess: "Успешный импорт",
          uploadFailed: "Сбой импорта",
          downloadError: "Сбой загрузки",
          downLoadTxtTitle: "Содержание состава. txt",
        },
        navTabNames: {
          synonymWords: "Синонимы",
          professionalWords: "Профессиональное существительное",
          attentionWords: "Слова внимания",
          ignoreWords: "Игнорировать слова",
          wordsWeight: "Вес слова",
          corpusData: "Данные состава",
          typoLibrary: "Набор опечаток",
        },
        synonymWords: {
          createSynonymWords: "Новый синоним",
          editSynonymWords: "Редактирование синонимов",
          addSynonymWords: "Добавить синонимы",
          pleaseEnterStandardWords: "Введите стандартное слово",
          synonymWordsExists:
            "Этот синоним уже существует и не может быть добавлен",
          synonymWordsCanNotNull: "Добавленный синоним не может быть пустым",
          pleaseAddSynonymWords: "Пожалуйста, добавьте синонимы",
          addSuccess: "Новый успех",
          editSuccess: "Модификация прошла успешно",
          deleteSuccess: "Удаление прошло успешно",
          deleteError: "Сбой удаления",
          required: "*",
        },
        professionalWords: {
          create: "Новые профессиональные существительные",
          edit: "Профессиональное редактирование существительных",
          addTargetWords: "Добавить заменяющие слова",
          targetWordsExists: "Замена уже существует и не может быть добавлена",
          targetWordsCanNotNull:
            "Добавленные заменяющие слова не могут быть пустыми",
          pleaseAddTargetWords: "Пожалуйста, добавьте заменяющие слова",
          pleaseEnter: "Пожалуйста, введите профессиональные существительные",
          wordsExists: "Существительное в профессии уже существует",
          createSuccess: "Добавить успех",
          editSuccess: "Модификация прошла успешно",
          deleteSuccess: "Удаление прошло успешно",
          deleteError: "Сбой удаления",
          saveError: "Сбой сохранения",
          addTargetWordsMaxNumberError: "Замена может добавить до 100 слов",
          addHomophonyWordsMaxNumberError:
            "Блокированные слова могут добавить до 100",
        },
        attentionWords: {
          create: "Новое слово внимания",
          edit: "Следите за редактированием слов",
          pleaseEnter: "Пожалуйста, введите слово",
          wordsExists: "Слова беспокойства уже существуют",
          createSuccess: "Добавить успех",
          editSuccess: "Модификация прошла успешно",
          deleteSuccess: "Удаление прошло успешно",
          deleteError: "Сбой удаления",
        },
        ignoreWords: {
          create: "Новое игнорирование слов",
          edit: "Игнорирование редактирования слов",
          pleaseEnter: "Пожалуйста, введите пренебрежительное слово",
          wordsExists: "Игнорирование слово уже существует",
          createSuccess: "Добавить успех",
          editSuccess: "Модификация прошла успешно",
          deleteSuccess: "Удаление прошло успешно",
          deleteError: "Сбой удаления",
        },
        wordsWeight: {
          createWordsWeight: "Новое весовое слово",
          editWordsWeight: "Редактирование весовых слов",
          addWordsWeight: "Добавить весовые слова",
          pleaseEnterWeightWords: "Введите весовые слова",
          pleaseEnterWeight: "Пожалуйста, введите вес",
          weightNeedInZeroToHundred: "Вес должен быть 0 ~ Между 100",
          synonymWordsExists: "Это весовое слово уже существует",
          weightNeedNumber: "Вес требует целочисленного формата",
          standardWeightInformation:
            "Ориентировочной вес 50, чем больше вес, тем сильнее влияние в анализе",
          createSuccess: "Добавить успех",
          editSuccess: "Модификация прошла успешно",
          deleteSuccess: "Удаление прошло успешно",
          deleteError: "Сбой удаления",
        },
        corpusData: {
          createCorpusData: "Новые данные состава",
          editCorpusData: "Редактирование данных состава",
          addCorpusData: "Добавление данных состава",
          pleaseEnterCorpusDescription: "Введите описание состава",
          pleaseEnterCorpusContent: "Введите содержание состава",
          synonymWordsExists: "Этот состав данных уже существует",
          CorpusContentWordsOverSetting:
            "Количество слов в содержании составляет более 20 000 слов,",
          download: "Скачать",
          upload: "Загрузка",
          see: "Посмотреть",
          canNotSave:
            "Пожалуйста, используйте функцию импорта, иначе она не может быть сохранена",
          createSuccess: "Добавить успех",
          editSuccess: "Модификация прошла успешно",
          deleteSuccess: "Удаление прошло успешно",
          deleteError: "Сбой удаления",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Правильное слово",
            wrongWord: "Неправильные слова",
            wrongWordCount: "Количество неправильных слов",
            wrongWordTip: "Нажмите Enter, чтобы добавить",
            status: "Эффективность",
          },
          create: "Новый набор слов опечаток",
          edit: "Редактирование наборов слов опечаток",
          tip: "Набор слов-опечаток используется для оптимизации эффекта «обнаружения опечаток». Например, «Возврат» по ошибке отправляется как «Возврат». Вы можете использовать эту функцию для установки общих опечаток и правильных слов. Обратите внимание, что он вступит в силу на следующий день после сохранения.",
          onlyChinese: "Поддержка ввода только китайских слов",
          wrongWordLengthErr:
            "Длина каждого неправильного слова должна быть 2 ~ 10 слов",
        },
      },
      semanticTags: {
        title: "Семантические метки",
        current: "Текущие семантические теги",
        reference1: "Задача контроля качества: {0} - {1}",
        reference2: "Умные метки: {0}",
        reference3: "Предварительная обработка данных: {0}",
        reference4: "База знаний: {0}",
        index: { import: "Импорт семантических тегов" },
        detail: {
          new: "Новые семантические теги",
          edit: "Редактирование семантических тегов",
          name: "Название",
          standardSentences: "Стандартное предложение",
          effectiveness: "Эффективность",
          similarSentences: "Похожие предложения",
          tip: "Поддержка вызова API сущностей с использованием # # Пример: Ваш номер телефона # sys_phone #?",
          excludeSimilarSentences: "Исключить похожие предложения",
          intelligentRecommend: "Смарт рекомендует похожий контент",
          fastTraining: "Быстрое обучение:",
          qualify: "Регулярный",
          recallKeyWord: "Отзыв ключевых слов",
          excludeKeywords: "Исключить ключевые слова",
          excludeKeywordsInfo1:
            "Исключить ключевые слова и регулярность имеют приоритет над всей логикой. Если попадание исключено, семантическая метка не будет попадать",
          keyWordInfo1:
            "Каждая строка отзыва ключевых слов поддерживает заполнение нескольких, разделенных запятыми на английском языке, и логика заполнения нескольких-это все попадание.",
          keyWordInfo2:
            "Ключевые слова отзыва поддерживают заполнение нескольких строк, а логика заполнения нескольких строк может быть создана для любого удара.",
          recallRegular: "Напомним, регулярный",
          excludeRegular: "Исключение регулярности",
          similaSentences: "Исключить похожие предложения",
          simim: "Степень сходства",
          keyWordInfo3:
            "Исключение подобных предложений имеет приоритет над всеми логическими действительными. Если вы ударите, чтобы исключить подобные предложения, вы не попадете на семантическую метку. Сходство по умолчанию составляет 80%. Чем больше сходство, тем больше сходство.",
          keyWordInfo4:
            "Правила похожи на регулярные правила, по умолчанию существует начало и конец. Не обязательно использовать скобки, например [не XXXX]",
          keyWordInfo5:
            "Функции в продвинутых модулях-это, как правило, экспериментальные функции, подготовленные для специалистов, знакомых с операцией, и если вы не уверены, как вам это делать, рекомендуется не изменять эту часть.",
          keyWordInfo6:
            "Функция обнаружения перекрестных предложений дает семантическим тегам возможность обнаруживать предложения.",
          keyWordInfo7: "До одного и того же персонажа",
          keyWordInfo8: "Игнорирование разных ролевых предложений не больше",
          keyWordInfo9:
            "Слова предложения для перекрестного определения предложения",
          keyWordInfo10:
            "Максимальная длина самого длинного перекрестного предложения не больше",
          keyWordInfo11: "Процент удаления сходства не меньше",
          keyWordInfo12: "Сходство не ниже",
          keyWordInfo13:
            "Аналогичные предложения в % будут сопоставлены аналогичным образом (по умолчанию сходство 80%)",
          keyWordInfo14:
            "Удаление сходства соответствует сходству предложений, и рекомендуется удалить слишком похожие предложения для повышения эффективности обнаружения.",
          keyWord: "Ключевые слова",
          entity: "Сущность",
          ruleField: "Понятие слова",
          customRule: "Дизайн речных правил",
          fanhua: "Аналогичные вопросы",
          test: "Тест",
          testOk: "Тест на попадание",
          senior: "Расширенные настройки",
          seniorList: "Обнаружение перекрестного предложения",
          up: "Включение",
          down: "Закрыть",
          ju: "Предложение",
          oneWord: "Слово",
          simimNo: "Подобный вес",
          seniorField: "Порог сходства",
          save: "Сохранить",
          cancel: "Отмена",
          specialElements: "Специальные элементы",
          logicRule: "Логические правила",
          every: "Несколько матчей",
          recallOk: "Хит отзыв слова:",
          recallNo: "Отзывное слово",
          ruleOk: "Хит напоминает регулярный",
          ruleNo: "Не попасть в регулярное",
          rularOk: "Правила попадания",
          rularNo: "Правила пропущенных",
          infoOk: "Хит стандартного предложения или аналогичного предложения:",
          infoNo: "Удар по стандартным предложам или подобным предложам",
          fieldOk: "Хит исключает похожие предложения:",
          fieldNo: "Исключить похожие предложения",
          excludeRuleNo: "Исключить регулярность",
          excludeRuleOk: "Удар, чтобы исключить регулярность",
          excludeKeywordNo: "Исключить ключевые слова",
          excludeKeywordOk: "Хит, чтобы исключить ключевые слова",
          update: "Обновление прошло успешно",
          fanhuaList:
            "Список похожих предложений, представленных на страницу обобщения",
          wordList:
            "Список обучения пуст и не может быть представлен в обобщенный список",
          start: "Начать",
          end: "Конец.",
          keyWordInfo15:
            "В списке обобщений есть похожие поля (расширенные настройки подобны для ограничения)",
          saveSuccess: "Успешное сохранение",
          errInfo: "Есть поля, которые не заполнены или заполнены неправильно",
        },
        table: {
          deepTraining: "Глубокая тренировка {0} колесо",
          fastTraining: "Быстрое обучение",
        },
      },
      intelligentCollection: {
        title: "Интеллектуальный сбор",
        classification: "Список групп",
        new: "Новый сбор",
        table: {
          name: "Название поля",
          props: "Свойства клиента",
          type: "Тип поля",
          desc: "Описание поля",
        },
        detail: {
          isCustomerProps: "Сохранить в свойствах клиента",
          customerPropsHelper:
            "После сохранения собранная информация обновляется в информацию о клиенте. Без сохранения не обновляется информация о клиенте",
          name: "Название поля",
          type: "Тип поля",
          text: "Текст",
          desc: "Описание поля",
          descPlaceHolder: "Описание полезности информации, собранной в поле",
          condition: "Условия",
          logic: "Правила",
          method: "Метод сбора",
          message: "Информационный контент",
          range: "Диапазон экстракции",
          entity: "Извлечение сущности",
          base: "Основная информация",
          collect: "Сбор информации",
        },
      },
      intelligentTags: {
        title: "Умные этикетки",
        sessionLabelTitle: "Сцена сеанса",
        keyEventsTitle: "Ключевые события",
        customerLabelTitle: "Теги клиентов",
        addClassification: "Новая классификация тегов",
        defaultCategoryName: "Классификация по умолчанию",
        index: {
          createTag: "Новые этикетки",
          createEvent: "Новое событие",
          editTagClassification: "Редактирование классификации тегов",
          selectTagClassification: "Выбор классификации этикеток",
          editTagGrounp: "Редактирование группировки",
          callTimeError:
            "Выбранный диапазон времени разговора/начала разговора не может превышать одного месяца (31 день)",
          callRecordCountError:
            "Количество записей равно 0, пожалуйста, повторно выберите время разговора/разговора",
          selectItmeCountError:
            "Выберите смарт-вкладку, которая требует выполнения пакетных операций",
          redoSuccess: "Повторное выполнение успешно, проверьте в Центре задач",
          redoError: "Повторное выполнение не удается, повторите попытку позже",
          timeError: "Время запроса не может превышать один месяц (31 день)",
          categoryName: "Название классификации",
          categoryColor: "Категорические цвета",
          newTagClassification: "Новая классификация тегов",
          newEventClassification: "Новая классификация",
          tagClassification: "Классификация этикеток",
          eventClassification: "Классификация событий",
        },
        detail: {
          new: "Новые интеллектуальные этикетки",
          edit: "Редактирование умных тегов",
          tagName: "Название",
          tagDesc: "Описание",
          category: "Классификация",
          tagStatus: "Эффективность",
          operator: "Оператор",
          rule: "Правила",
          labelClassAtion: "Типы этикеток",
          labelClassAtionTip1:
            "Маркировка: маркировка этикеток на основе правил",
          labelClassAtionTip2:
            "Категорические метки: маркировка этикеток на основе обучения модели",
          group: "Группировка",
          checkLabel: "Выбор классификации",
          warn: "Раннее предупреждение",
          warnTips:
            "После того, как раннее предупреждение включено, когда сеанс соответствует этому правилу ключевого события, назначенному персоналу будет предложено получить напоминание о задачах",
          warnError: "Выберите назначенный персонал для раннего предупреждения",
        },
        subTitle: {
          common: "Интеллектуальные этикетки для сеансов",
          wecom: "Предприятие микро этикетки",
        },
      },
      customerPortrait: {
        title: "Портрет клиента",
        index: { import: "Импорт портретов клиентов" },
        searchPlaceHolder: "ID клиента, псевдоним или телефон",
        callCount: "Количество разговоров:",
        callDuration: "Общая продолжительность разговора:",
        dialogCount: "Общее количество диалогов:",
        keyWordsStatistics:
          "Статистика по ключевым словам (Top 20 & Следите за словами)",
        keyWordsCount: "({0})",
        businessTag: "Бизнес теги",
        keyWordsCloudImg: "Ключевые слова изображение облака",
        customerEmotionTrend: "Тенденции изменения настроения клиентов",
        notFoundCustomer: "Не был получен соответствующий клиент",
        noKeyWords: "Связанные ключевые слова не были запрошены",
        noBusinessTags: "Не запрашивает соответствующие бизнес-теги",
        getKeyWordsStatisticsDataError:
          "Сбой запроса статистики ключевых слов клиента",
        getBusinessTagsStatisticsDataError: "Сбой запроса бизнес-тега клиента",
        personCustome: "Не запрашивает информацию о клиентах",
        notimeInfo: "Эмоциональная информация не запрашана",
        timeList: "Диапазон дат",
        operator: "Умные этикетки",
        placeHolder: "Пожалуйста, выберите",
        placeOperator: "Пожалуйста, выберите смарт-этикетку",
        search: "Запрос",
        reset: "Сброс",
        searchHolder: "Поиск",
        call: "Звонки",
        text: "Диалог",
      },
      sentimentAnalysis: { title: "Обнаружение общественного мнения" },
      saleSentimentAnalysis: { title: "Анализ общественного мнения" },
      dataPreprocess: {
        title: "Предварительная обработка данных",
        index: {
          createData: "Новые данные",
          selectDataSource: "Выбор источника данных",
          selectStatus: "Выбор эффективности",
          callTimeError:
            "Выбранный диапазон времени разговора/начала разговора не может превышать одного месяца (31 день)",
          callRecordCountError:
            "Количество записей равно 0, пожалуйста, повторно выберите время разговора/разговора",
          selectItmeCountError:
            "Выберите данные, которые необходимо выполнить пакетные операции",
          redoSuccess: "Повторное выполнение успешно, проверьте в Центре задач",
          redoError: "Повторное выполнение не удается, повторите попытку позже",
        },
        detail: {
          new: "Новые данные",
          edit: "Редактирование данных",
          name: "Название",
          desc: "Описание",
          dataSource: "Источник данных",
          status: "Эффективность",
          entity: "Извлечение сущности",
          operator: "Оператор",
          rule: "Правила",
        },
      },
      connectedByConversation: {
        title: "Связь диалога",
        list: {
          title: "Связь функции ассоциации диалога",
          info: "Функция связки диалога может связывать различные звонки с диалогами в соответствии с предустановленными правилами",
          rule: "Правила ассоциации диалога",
          source: "Выберите источник данных",
          classification: "Выберите классификацию",
          choose: "Пожалуйста, выберите",
          input: "Пожалуйста, введите",
          save: "Сохранить",
          cancel: "Отмена",
          fieldwei: "Бит",
          fieldNot: "Есть незаполненные поля",
          fiedldlengthNo: "Длина поля превышает предел",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Можно установить несколько ключевых слов, разделенных запятыми",
          applyRole: "Роль обнаружения",
          preOperator: "Предварительный оператор",
          applyOperatorScope: "Диапазон обнаружения",
          addOperator: "Добавить оператор",
          noPreOperator: "Нет.",
          from: "Первый",
          to: "Предложение до первого",
          end: "Предложение",
          testRegular: "Тестирование регулярных выражений",
          test: "Тест",
          matchSuccess: "Матч прошел успешно",
          matchError: "Матч не удался",
          querySementicTags: "Выбор семантической метки",
          reQuerySementicTags: "Повторный выбор семантической метки",
          sementicTag: "Семантические метки",
          standardSentence: "Стандартное предложение",
          similarity: "Степень сходства",
          actions: "Операция",
          noFitTag: "Не найдя подходящего ярлыка,",
          addSemanticTag: "Добавление новой семантической метки",
          pleaseEnterRegular: "Введите регулярное выражение",
          pleaseEnterRegularText: "Введите, что требуется для проверки",
          newAssociateComponentTip:
            "Если значение является числовым типом, используйте устаревшую функцию",
        },
        filterManage: {
          noValueTip: "Нет данных",
          allCall: "Все звонки",
          allDialogue: "Все разговоры",
          allWechat: "Все сеансы",
          getFiltersListFailed:
            "Получить список фильтров не удалось, повторите попытку позже!",
          getFilterDetailFailed:
            "Получить детали фильтра не удалось, повторите попытку позже!",
          getAllCountFailed:
            "Получить общее количество звонков/разговоров не удалось, повторите попытку позже!",
        },
        listManage: {
          searchPlaceHolder: "Пожалуйста, введите ключевое слово для поиска",
          filterConditions: "Условия скрининга",
          records: "Запись",
          getRecordsFailed:
            "Получить запись не удалось, повторите попытку позже!",
        },
        miniListManage: {
          getRecordsFailed:
            "Получить запись не удалось, повторите попытку позже!",
        },
        applyTemplates: {
          title: "Применимый шаблон контроля качества",
          collectionTitle: "Применимые шаблоны сбора",
        },
        recordDetail: {
          showWaveAudio: "Показать запись",
          getDetailFailed:
            "Получить детали не удалось, попробуйте снова позже!",
          getSmartTagsFailed:
            "Получить смарт-ярлык не удалось, попробуйте снова позже!",
          getTemplatesFailed:
            "Ошибка получения применимого шаблона, повторите попытку позже!",
        },
        changeList: {
          sampleStatus: "Статус выборочной проверки",
          sampleAssignInspector: "Назначить инспекторов по выборке",
          reviewAssignInspector: "Назначенный ревизор",
          read: "Прочитано",
          distributivePerson: "Назначает лицо",
          totalScore: "Общая оценка",
        },
        qualityCheckScore: {
          totalScore: "Общая оценка",
          actualScore: "Счет",
          actualGrade: "Рейтинг",
          appeal: "Жалоба",
          remark: "Примечания",
          manualRemark: "Ручные замечания",
          none: "Нет подробных данных о проверке качества",
          interactiveTip:
            "Использование интерактивной идентификации для обнаружения использования обслуживания клиентов",
          interactiveIsEmptyTip:
            "Контент распознавания взаимодействия не обнаружен",
        },
        semanticMark: {
          semanticContent: "Аннотное предложение",
          markedSimilarTitel: "Сходные семантические теги были помечены",
          markedExcludeTitle:
            "Сходные семантические теги были помечены для исключения",
          taskTagTitle: "Семантические теги, связанные с задачами",
          similarTagTitle:
            "Идентификация сходных семантических тегов (сходство> 40%,Top 20)",
          delete: "Удалить",
          markSimilar: "Пометить похожие",
          markExclude: "Исключить сходство",
          none: "Нет.",
        },
        selectCallTime: {
          callTime: "Время вызова/время начала сеанса",
          callTimeRemind:
            "Вы можете выбрать запись разговора не более одного месяца (31 день) за последний год",
          callRecordCount:
            "Вы выбрали в общей сложности {0} записей звонков/разговоров",
          getCallRecordError:
            "Получить запись не удалось, повторите попытку позже",
        },
      },
      informationEntities: {
        title: "Информационная сущность",
        index: { import: "Импорт информационных объектов" },
        detail: {
          new: "Новые информационные объекты",
          edit: "Редактирование информационных объектов",
          name: "Название",
          apiName: "Имя API",
          activeFlag: "Эффективность",
          remark: "Описание",
          logicExp: "Та сущность",
          sentenceList: "Содержание сущности",
          tip: "Содержимое сущности поддерживает ввод нескольких, разделенных пробелами, которые являются синонимами одной и той же сущности",
          remarkLengthError: "Длина описания не может превышать 255",
          nameLengthError: "Длина имени не может превышать 40",
          entityDuplicateError: "Повторение содержимого сущности",
        },
      },
      interactiveRecognition: {
        title: "Интерактивная идентификация",
        classify: "Классификация",
        addClassify: "Добавить классификацию",
        editClassify: "Редактирование классификации",
        index: { import: "Импорт распознавания взаимодействия" },
        detail: {
          new: "Новое интеллектуальное взаимодействие",
          edit: "Редактирование интеллектуального взаимодействия",
          type: "Тип",
          standardQuestion: "Стандартные вопросы",
          similarQList: "Подобные проблемы",
          exceptSimilarQList: "Исключение подобных проблем",
          category: "Классификация",
          targetType: "Цель обнаружения проблемы",
          status: "Эффективность",
          standardAnswer: "Стандартный ответ",
          similarAList: "Похожие ответы",
          wrongAList: "Неправильный ответ",
        },
      },
      businessAnalysis: {
        title: "Бизнес-анализ",
        tip1: "Анализ бизнес-тенденций: ордината-это количество сквозных диалогов, то есть количество сквозных диалогов, удовлетворяющих условию, а абсцисс-время",
        tip2: "Корреляционный анализ: анализ корреляции нескольких индикаторов, повисление мыши для отображения корреляционных данных, объем данных, корреляция, частота повторения",
        tip3: "Анализ конверсии: анализ нескольких индикаторов может сформировать взаимные конверсионные отношения. Критерием определения конверсии является один и тот же вызов/диалог. Если вы можете поразить два разных индикатора, вы можете считать, что эти два индикатора имеют конверсионные отношения.",
      },
      knowledgeBase: {
        title: "База знаний",
        list: {
          add: "Добавить подкатегории",
          delete: "Удалить классификацию",
          categoryName: "Название классификации",
          createSuccess: "Создание подкатегорий успешно",
          keyWord: "Ключевые слова",
        },
        detail: {
          type: "Тип",
          status: "Состояние",
          classification: "Классификация",
          standardQuestion: "Стандартные вопросы",
          answerDetectionRange: "Диапазон обнаружения ответов",
          standardAnswer: "Стандартный ответ",
        },
      },
      callManage: {
        title: "Фильтры записи звонков",
        listTitle: "Управление звонками",
        detailTitle: "Сведения о звонке",
      },
      dialogueManage: {
        title: "Диалог текстовый фильтр",
        listTitle: "Управление диалогом",
        detailTitle: "Подробности диалога",
      },
      weChatManage: {
        title: "Фильтр сеанса корпоративного WeChat",
        listTitle: "Управление сессиями WeChat предприятия",
        detailTitle: "Подробнее о сеансе WeChat предприятия",
      },
      speechRecognition: {
        title: "Распознавание речи",
        asr: "Распознавание речи",
        asrRemind:
          "Отключение этого переключателя приводит к сбою функции преобразования голоса в текст и функции контроля качества машины",
        asrConfig: "Фильтрация ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "После включения выполняется ASR-транслитерация IM",
        asrConfigRemind:
          "После включения только подходящие звонки будут выполнять контент ASR",
        correction: "Интеллектуальное исправление ошибок",
        correctionRemind:
          "После включения принудительное преобразование ошибок с помощью содержимого в «<a href = '/site/semantic-intelligence/smart-words-library/professional-words' target =' _ blank'> Smart Тезаурус-существительное профессионал </a>»",
        correctionConfig: "Фильтрация коррекции ошибок",
        advancedSettings: "Расширенные настройки",
        advancedSettingsTip:
          "Выберите заданные модели ASR для указанных данных.",
        mandarinASRModel: "Модель мандарина ASR",
        cantoneseASRModel: "Кантонская модель ASR",
        englishASRModel: "Английская модель ASR",
        correctionConfigRemind:
          "После включения исправления ошибок будут проводиться только звонки, которые соответствуют следующим условиям.",
        customJudgeLogicIsNull: "Пользовательская логика не может быть пустой",
        redoCorrection: "Повторное исправление ошибок",
        callTime: "Выбор времени разговора",
        callTimeRemind:
          "Вы можете выбрать историю звонков не более одного месяца (31 день) за последний год",
        callRecordCount: "Вы выбрали в общей сложности {0} записей звонков",
        redoCorrectionSubmit: "Представление для исполнения",
        callTimeError:
          "Выбранный диапазон времени разговора не может превышать один месяц (31 день)",
        callRecordCountError:
          "Количество записей вызовов равно 0, пожалуйста, выберите время разговора снова",
        redoCorrectionSuccess:
          "Повторное исправление ошибок успешно, проверьте это в Центре задач",
        redoCorrectionError:
          "Повторное исправление ошибок не удалось, повторите попытку позже",
      },
      role: {
        title: "Управление ролями",
        create: "Новые роли",
        detail: {
          title: "Роль",
          new: "Новые роли",
          view: "Просмотр ролей",
          edit: "Редактирование роли",
          navTabNames: {
            basicInfo: "Разрешения меню",
            taskPermissions: "Полномочия по контролю качества",
            dataPermissions: "Разрешения данных",
          },
          basicInfo: { roleName: "Название", permissions: "Разрешения" },
          taskPermissions: {
            allTask: "Все задачи контроля качества",
            myCreatedTask: "Задачи контроля качества, которые я создал",
          },
          dataPermissions: {
            dataPermissionsConfig: "Конфигурация разрешений на данные",
            tips: "Роль может видеть данные в выбранной классификации",
            select: "Выбор классификации",
            selectPlaceholder:
              "Выберите категории, требующие разрешения на видимость данных",
          },
        },
      },
      dataMigration: {
        title: "Миграция данных",
        dataUpload: "Перемещение данных",
        dataDowload: "Выезд данных",
        error:
          "Выберите задачу контроля качества, которую вы хотите экспортировать",
        list: {
          info: "Пожалуйста, выберите задачу контроля качества, которую вы хотите переместить",
          listInfo:
            "Выберите, хотите ли вы выйти из задач контроля качества, связанных с данными (например, интеллектуальные тезаурус, семантические теги, информационные объекты, интеллектуальные взаимодействия и т. Д.)",
          noInfo:
            "Выберите, хотите ли вы вывести другие данные, не относящиеся к контролю качества",
          ownInfo: "Только из миссии",
          everyInfo: "Выезд из миссии",
          allInfo: "Выехать из всех",
          callFilter: "Фильтры для звонков",
          talkFilter: "Диалоговые фильтры",
          smartTag: "Умные этикетки",
        },
        upload: {
          info: "1. Нажмите кнопку Загрузить, чтобы загрузить файл JSON в соответствии с форматом",
          uploadSuccess: "Успешная загрузка",
          uploadError: "Сбой загрузки",
        },
      },
      dataAnalysis: {
        businessStatistics: "Оперативная статистика",
        qualityEffect: "Проверка качества здоровья",
        qualityAnalysis: "Анализ качества",
        generalInspecitionReport: "Общий доклад",
        fullAmountWork: "Рабочая нагрузка инспектора качества",
        intelligentTagsAnalysis: "Анализ этикеток",
        saleIntelligentTagsAnalysis: "Интеллектуальный анализ этикеток",
        generalInspecitionReportTip:
          "Общий объем данных: статистика текущих ограничений по времени и задачам, общее количество данных о вызовах и разговоре, количество интеллектуальных данных контроля качества & процент, количество данных выборки вручную & процент, количество жалоб, скорость повторной проверки и график тренда времени",
        groupTest: "Тестирование групп",
      },
      uploadDownloadList: {
        title: "Центр миссий",
        tabs: {
          upload: "Загрузить импорт",
          download: "Скачать экспорт",
          taskCenter: "Выполнение задач",
        },
      },
      uploadDownloadModel: {
        title: "Загрузить Скачать",
        list: {
          clear: "Освободить",
          success: "Успех",
          fail: "Провал",
          hand: "В процессе",
          noInfo: "Нет данных",
        },
      },
      billingCenter: {
        title: "Центр выставления счетов",
        export: "Экспорт",
        limit: "Предел напоминания",
        tips: "Результаты выставления счетов будут отклонены из-за округления, только для справки",
        dashboard: {
          remainingQuota: "Текущая остаточная квота",
          usedQuota: "Накопленные квоты",
          voiceQuality: "Голосовой контроль качества:",
          voice: "Голос:",
          textQuality: "Текстовая проверка качества:",
          text: "Текст:",
          hour: "Час",
          tenThousandHour: "Десять тысяч часов",
          day: "Боже!",
          cutoff: "Крайний срок",
          warning:
            "(Оставшаяся квота недостаточна, пожалуйста, пополните ее как можно скорее)",
          isClosed: "(Статус выключения)",
        },
        table: {
          quotaRecord: "Запись квот",
          timeFilter: "Выбор даты:",
          search: "Запрос",
          reset: "Сброс",
          id: "Сводный номер",
          type: "Тип",
          qualityType: "Тип контроля качества",
          quotaChange: "Изменения",
          surplus: "Баланс",
          changeTime: "Время",
          voiceQuality: "Голосовой контроль качества",
          textQuality: "Контроль качества текста",
          sizeChangeText: "Показать на каждой странице",
          used: "Использование",
          give: "Награждение",
          deduction: "Вычет",
          callTime: "Время разговора",
          count: "Обработка объема данных",
          page: "Всего {0}, страница {1}",
        },
        limitModal: {
          call: "Голосовая квота контроля качества ниже",
          callFix: "Час напоминания",
          dialogue: "Оставшееся время проверки качества текста меньше",
          dialogueFix: "Дни напоминания",
        },
      },
      messageCenter: {
        replyTitle: "Ответить на мой",
        answerTitle: "@ Мой",
        shareTitle: "Поделиться моим",
      },
      columnsManage: { title: "Управление полями контроля качества" },
      recordUpload: {
        list: {
          task: "Задачи контроля качества",
          user: "Оператор",
          uploadTime: "Время загрузки",
          endTime: "Время окончания",
          total: "Общее количество загрузок",
          success: "Проверка качества успешно",
          failed: "Проверка качества не удалась",
          progress: "Прогресс контроля качества",
          inProgress: "В процессе",
          finished: "Завершено",
        },
        steps: {
          uploading: "Загрузка файлов записи",
          baseInfo: "Заполните основную информацию",
          start: "Начать проверку качества",
        },
        uploadComponent: {
          dragger: "Перетащите добавить файл",
          or: "Или",
          selectFile: "Выбор файла",
          des: "Поддержка формата wav, mp3, частота дискретизации 8000 Гц, 16-битные файлы",
          limit1: "Максимальная загрузка за один раз",
          limit2: "Файл, размер каждого файла не превышает",
          uploading: "Загружено",
          finished: "Завершение загрузки",
          success: "Успех",
          failure: "Провал",
        },
        baseInfo: {
          task: "Выбор задачи контроля качества",
          type: "Выбор формы записи",
          agentSide: "Канал обслуживания клиентов",
          excel: "Загрузка данных по дороге",
          checkFileInfo: "Проверка информации о файле",
          excelFileError: "Пожалуйста, загрузите данные с дороги",
          checking: "Проверка...",
          reCheck: "Повторная проверка",
          success: "Успешная проверка",
          excelUoloadFail: "Неудачная загрузка данных по дороге",
          tips: {
            tip1: "1. Имя файла записи в ecxel должно соответствовать названию загруженной записи и не может быть повторно назван",
            tip2: "2. Система проверяет имя файла записи и идентификатор вызова. Повторение приведет к срывам проверки качества",
            tip3: "3. скачать excel шаблон записи, цвет шрифта красный является обязательным полем",
            tip4: "4. Формат поля для типа времени: yyyy-mm-dd hh:mm:ss, например 2021-04-20 17:36:30",
          },
        },
        title: "Загрузка локальных записей",
        checkTitle: "Проверка качества записи",
        start: "Начать проверку качества",
        disabledTips:
          "Пожалуйста, проверьте записанные файлы перед проверкой качества",
        tips: "Файл записи с тем же именем, покрывающий результаты проверки качества и основную информацию",
        tips1: "Перетащите файл здесь или нажмите Загрузить",
        tips2: "Поддержка",
        tips3: "Формат",
        type: "wav、mp3",
        tips4: "Максимальная загрузка за один раз",
        tips5: "Файл, размер каждого файла не превышает",
        tipsOverSize:
          "Текущая очередь заполнена, пожалуйста, удалите файлы из очереди перед загрузкой",
        size: "500M",
        count: "20",
        uploaded: "Загруженный файл записи",
        uploading: "({0}%)",
        uploadFinished: "(Загрузка завершена)",
        bottomTitle: "Запись сбора информации:",
        recordType: "Выбор формы записи:",
        double: "Двухканальная запись звонков",
        single: "Одноканальная запись звонков",
        recordBaseInfo: "Загрузить основную информацию о записи:",
        addBtn: "Добавить файл",
        downloadTemplate: "Скачать шаблон. excel",
        checkRecord: "Проверка информации записи",
        checking: "Проверка...",
        reCheck: "Повторная проверка",
        checkAll: "Полный выбор",
        cancelCheckAll: "Отмена всех выборов",
        delete: "Удалить",
        deleteLotSize: "Пакетное удаление",
        agentChannel: "Канал обслуживания клиентов:",
        left: "Левый канал",
        right: "Правый канал",
        donotKonw: "Не знаю.",
        mission: "Выберите задачу контроля качества:",
        uploadFail: "Сбой загрузки",
        repeat: "Повторяющиеся файлы",
        overCount: "Количество файлов превышает лимит",
        overSize: "Размер файла превышает лимит",
        uoloadFail: "Неудача загрузки в облако",
        formatError: "Ошибка формата",
        seccess: "Успешная проверка",
        fail: "Сбой проверки",
        recordChecking: "Проверка качества записи, пожалуйста, позже...",
        recordCheckSuccess: "Проверка качества записи завершена",
        recordCheckFail:
          "Проверка качества записи не удалась, проверьте, является ли запись повторной или недействительной",
        backToIndex: "Вернуться к списку загрузки",
        recordCheckIsStarted:
          "В настоящее время выполняется задача контроля качества. Пожалуйста, начните новую задачу после завершения текущей задачи.",
      },
      customerServicePortrait: {
        title: "Портрет обслуживания клиентов",
        tip1: "Закон распределения длины предложения: распределение длины предложения, в соответствии с количеством длинных слов предложения, подсчитывается в сегментах, рассчитывается только служба поддержки клиентов",
        tip2: "Распределение скорости речи: Распределение скорости речи, в соответствии со скоростью речи (слово/в минуту), подсчитывается только служба поддержки",
        tip3: "Закон распределения времени отклика: распределение времени отклика, рассчитать только реакцию обслуживания клиентов",
        tip4: "Контроль качества: уровень соответствия для точек контроля качества",
        tip5: "Средняя оценка задачи контроля качества: средняя оценка задачи контроля качества",
        contol: {
          date: "Выбор даты:",
          today: "Сегодня",
          thisMonth: "В этом месяце",
          lastMonth: "В прошлом месяце",
          thisQuarter: "В этом квартале",
          lastQuarter: "Последний квартал",
          thisYear: "В этом году",
          lastYear: "В прошлом году",
          customer: "Обслуживание клиентов:",
          search: "Запрос",
          reset: "Сброс",
          export: "Экспорт",
          indicator: "Управление показателями",
          selectPlaceholder:
            "Пожалуйста, выберите службу поддержки клиентов, которая нуждается в сравнении",
          warning: "Выберите до 5 клиентов для сравнения",
          warning2:
            "Пожалуйста, выберите хотя бы 1 службу поддержки клиентов для запросов",
          dataWarning:
            "Поддержка запроса данных только за 366 дней с даты начала",
          seccuss: "Успешный экспорт записей",
        },
        echarts: {
          distributionLaw: "Закон распределения",
          count: "Количество раз",
          bout: "Раз",
        },
        portrait: {
          ability: "Размеры возможностей обслуживания клиентов",
          option: "Конфигурация",
          abilityOption: "Размеры возможностей конфигурации",
          placeholder: "Возможность ввода Размеры поиска по ключевым словам",
          save: "Сохранить",
          warning:
            "Пожалуйста, выберите по крайней мере 3 индикатора для настройки. Количество индикаторов может выбрать до 10",
        },
        label: { title: "Обслуживание клиентов бизнес теги" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Рейтинг обслуживания клиентов",
        select: {
          date: "Выбор даты:",
          today: "Сегодня",
          thisMonth: "В этом месяце",
          lastMonth: "В прошлом месяце",
          thisQuarter: "В этом квартале",
          lastQuarter: "Последний квартал",
          thisYear: "В этом году",
          lastYear: "В прошлом году",
          group: "Группа обслуживания клиентов:",
          groupPlaceholder: "Пожалуйста, выберите группу обслуживания клиентов",
          customer: "Обслуживание клиентов:",
          customerPlaceholder: "Пожалуйста, выберите обслуживание клиентов",
          search: "Запрос",
          reset: "Сброс",
          compared: "Контраст",
          export: "Экспорт",
          column: "Столбец",
          warning: "Выберите до 5 клиентов для сравнения",
          warning2: "Пожалуйста, выберите хотя бы одну службу поддержки",
          dataWarning:
            "Поддержка запроса данных только за 366 дней с даты начала",
          selected: "Отобранное обслуживание клиентов:",
          seccuss: "Успешный экспорт записей",
        },
        compared: {
          title: "Выбор обслуживания клиентов",
          return: "Отмена",
          compared: "Контраст",
          tips: "Пожалуйста, выберите 2-5 клиентов для сравнения",
          warning: "Поддержка только 2-5 клиентов для сравнения",
        },
        transfer: {
          leftTitle: "Список элементов",
          rightTitle: "Отображение предметов",
        },
        table: {
          page: "Всего {0}, страница {1}",
          actionTitle: "Операция",
          action: "Посмотреть",
        },
      },
      conceptWord: {
        title: "Понятие слова",
        new: "Новое концептуальное слово",
        control: {
          search: "Поиск:",
          searchPlaceholder: "Поиск по имени или ключевому слову",
          commit: "Запрос",
          reset: "Сброс",
          import: "Импорт",
          export: "Экспорт",
          seccuss: "Успешный экспорт записей",
        },
        modal: { title: "Импорт концептуальных слов" },
        table: {
          sizeChangeText: "Показать на каждой странице",
          page: "Всего {0}, страница {1}",
          edit: "Редактирование",
          delete: "Удалить",
          request:
            "Не будет восстановлен после удаления. Подтверждено ли удаление?",
          cancel: "Отмена",
          title: {
            conceptWord: "Понятие слова",
            describeWordsNum: "Количество описывающих слов",
            updateTime: "Время обновления",
            status: "Эффективность",
            effective: "Эффективно",
            invalid: "Недействительно",
            action: "Операция",
          },
        },
        detail: {
          title: {
            new: "Новое концептуальное слово",
            edit: "Редактирование концептуальных слов",
          },
          name: "Название концептуального слова",
          namePlaceholder: "Введите название концептуального слова",
          effectiveness: "Эффективность",
          effective: "Эффективно",
          invalid: "Недействительно",
          description: "Описание слова:",
          descriptionPlaceholder: "Введите описывающее слово",
          add: "Добавить",
          repeat: "Повторяющийся контент",
          empty: "Описывающее слово не может быть пустым",
          recommendEmpty:
            "Больше не рекомендуется описывать слова, введите их вручную",
          recommend: "Интеллектуальное рекомендуемое слово описания",
          cancel: "Отмена",
          change: "Поменяй группу",
          descriptionSelected: "Добавлены описывающие слова:",
          save: "Сохранить",
          success: "Концептуальные слова сохраняются успешно",
          warning: "Название понятийного слова не может быть пустым",
          warning2: "Пожалуйста, добавьте как минимум 1 описывающее слово",
          submitBtnHandle: {
            title:
              "Там, где флажок описания не добавлен, сохранение откажется от недобавленных описывающих слов",
            content:
              "Нажмите «ОК», чтобы вернуться на домашнюю страницу концептуального слова, подтвердите продолжение?",
          },
        },
      },
      smartTools: {
        btn: "Умные инструменты",
        title: {
          similarWords: "Обнаружение связанных слов",
          search: "Приговор по слову",
          semanticTags: "Семантические метки",
        },
        sentenceSearch: {
          placeholder:
            "Введите ключевые слова для поиска, поддержка нескольких ключевых слов",
          more: "Посмотреть полный текст",
          pageUp: "Предыдущая страница",
          pageDown: "Следующий",
          total: "Всего {0} записей",
        },
        similarDiscover: {
          placeholder: "Введите ключевые слова",
          copyed: "Скопировано",
          none: "Нет связанных слов, пожалуйста, попробуйте другие ключевые слова",
        },
        smartTags: {
          searchPlaceHolder: "Введите ключевое слово или предложение",
          createSmartTag: "Новое строительство",
          createClusteringSmartTag: "Новое с {0} строками данных",
          addFromKeyword: "Добавить поиск в похожие предложения",
          hit: "Хит",
          similar: "Похожие",
          name: "Название семантической метки",
          namePlaceHolder: "Введите название семантической метки",
          recallWords: "Отзыв слова",
          recallWordsPlaceHolder: "Введите ключевые слова отзыва",
          recallRegular: "Напомним, регулярный",
          recallRegularPlaceHolder: "Введите регулярный отзыв",
          rules: "Правила",
          standardSentences: "Стандартное предложение",
          standardSentencesPlaceHolder: "Введите стандартное предложение",
          similaSentences: "Похожие предложения",
          similaSentencesPlaceHolder: "Пожалуйста, введите похожие предложения",
          notSaveError:
            "Пожалуйста, сохраните семантическую метку перед поиском",
          add: "Добавить",
          newPage: "Открывается на новой странице",
          querryError:
            "В списке обобщений есть похожие поля (расширенные настройки подобны для ограничения)",
          detailTitle: "Сведения о семантической метке",
        },
      },
      trainingCenter: {
        title: "Учебный центр",
        edit: "Редактирование",
        delete: "Удалить",
        seleteAll: "Полный выбор",
        cancelCheckAll: "Отмена всех выборов",
        add: "Добавить",
        remove: "Удалить",
        save: "Сохранить",
        cancel: "Отмена",
        fastTraining: {
          title: "Быстрое обучение",
          titleDes:
            "Быстрое обучение направлено на то, чтобы помочь семантическим тегам быстро обобщить до 20 похожих предложений",
          semanticTags: "Семантические метки",
          semanticTagsPlaceholder: "Введите поиск по ключевым словам процесса",
          standardSentence: "Стандартное предложение:{0}",
          similarSentencesPlaceholder:
            "Введите похожие предложения для добавления или поиска",
          similarSentencesAdd: "Добавить",
          similarSentencesSearch: "Поиск",
          similarSentences: "Добавлены похожие предложения",
          trainingCount: "Количество тренировок:{0}",
          similarSentencesRecommend: "Рекомендуемые похожие предложения",
          noResp:
            "Нет связанных семантических тегов, пожалуйста, замените ключевые слова",
          similarSentencesModalTitle: "Поиск похожих предложений",
          moreInfo: "Просмотр контекста",
          moreInfoBtn: "Контекст",
          modalSearchType: {
            all: "Все.",
            customer: "Обслуживание клиентов",
            client: "Клиенты",
          },
          repetWarning:
            "Те же похожие предложения уже существуют, не добавляйте их повторно",
          repetRemoveTip:
            "Сохраните дублированное содержание в похожих предложениях, которое было автоматически удалено",
        },
        trainingHistory: {
          title: "История обучения",
          select: {
            date: "Время выбора:",
            today: "Сегодня",
            thisMonth: "В этом месяце",
            lastMonth: "В прошлом месяце",
            thisQuarter: "В этом квартале",
            lastQuarter: "Последний квартал",
            thisYear: "В этом году",
            lastYear: "В прошлом году",
            search: "Запрос",
            reset: "Сброс",
          },
          table: {
            time: "Время",
            user: "Оператор",
            tag: "Семантические метки",
            similarCount: "Количество логотипов аналогичных предложений",
            deleteCount: "Удалить количество предложений",
            page: "Всего {0}, страница {1}",
          },
        },
        classifiedTraining: {
          title: "Классификация обучения",
          tagsGrounp: "Группировка тегов",
          placeHolder: "Пожалуйста, выберите",
          cellTime: "Выбор времени",
          search: "Запрос",
          reset: "Сброс",
          classifiedFlag: "Классификация обучения",
          num: "Значение",
          gas: "Процентная доля",
          noData: "Обучение без категории",
          noDataHeight:
            "Доля неклассифицированных данных относительно высока, и требуется срочная подготовка",
          noDataSmall:
            "Неклассифицированные данные относительно высоки, рекомендуется обучение",
          noDataDays: "Последнее выполнение {0} дней назад",
          training: "Обучение",
          start: "Начать",
          addClass: "Добавлена классификация",
          custom: "Расширенный скрининг",
          marked: "Ошибка маркированных данных",
          markedHeight:
            "Ошибки не были проведены в течение {0} дней, рекомендуется ошибка",
          markedSmall:
            "Ошибки не были проведены в течение {0} дней, и необходимо срочно провести ошибки",
          mark: "Ошибки",
          dataCheck: "Проверка классифицированных данных",
          dataCheckSmall: "K-L распри выше 0,5, рекомендуется тренироваться",
          dataCheckHeight: "K-L распри выше 0,8, срочная тренировка",
          check: "Проверка",
          history: "История маркировки",
          historyTime:
            "Ежедневная маркировка и изменение будут автоматически выполняться в 2:00 утра.",
          today: "Сегодняшняя маркировка",
          article: "Полоска",
          none: "Нет.",
          historyCheck: "Итого Итого",
          look: "Просмотр записей",
          historyluate: "Пересчет исторических данных",
          startluate: "Подсчет",
          classData: "Классификация данных",
          classStatus: "Ход обучения",
          next: "Следующий",
          prev: "Предыдущий",
          finish: "Завершение обучения",
          stop: "Конец тренировки",
          classDetail: "Детали обучения",
          trainingNone: "Умные метки, которые не обучены",
        },
        classifiedTrainingHistory: {
          title: "История обучения",
          time: "Время выбора:",
          tag: "Тип метки:",
          user: "Оператор:",
          data: "Выберите источник данных:",
          search: "Запрос",
          searchBar: "Поиск:",
          searchBarPlaceholder:
            "Введите идентификатор вызова/идентификатор разговора для поиска",
          reset: "Сброс",
          export: "Экспорт",
          all: "Все.",
          select: {
            today: "Сегодня",
            thisMonth: "В этом месяце",
            lastMonth: "В прошлом месяце",
            thisQuarter: "В этом квартале",
            lastQuarter: "Последний квартал",
            thisYear: "В этом году",
            lastYear: "В прошлом году",
            search: "Запрос",
            reset: "Сброс",
          },
          table: {
            user: "Оператор",
            date: "Время работы",
            data: "Источник данных",
            id: "Идентификатор вызова/Идентификатор разговора",
            tagType: "Интеллектуальная группировка этикеток",
            tagName: "Название этикетки",
            state: "Состояние",
            sizeChangeText: "Показать на каждой странице",
            page: "Всего {0}, страница {1}",
            call: "Звонки",
            dialogue: "Диалог",
          },
        },
        clusteringAnnotation: {
          title: "Кластерные аннотации",
          left: { title: "Результаты кластеризации" },
          right: {
            title: "Кластерные предложения",
            checkAll: "Все на этой странице",
            tagging: "Маркировка",
            detail: "Посмотреть",
            meaningless: "Игнорировать",
            meaninglessTips:
              "Упущенные результаты кластеризации больше не будут отображаться в дальнейшем, вы подтверждаете, что хотите их игнорировать?",
            cancel: "Отмена",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Управление клиентами",
          tabTitle: "Клиенты центра обслуживания клиентов",
        },
        wechatClientManage: {
          title: "Корпоративные микро-клиенты",
          table: {
            id: "ID клиента",
            name: "Ник клиента",
            tag: "Теги клиентов",
          },
        },
      },
      collectionRule: {
        title: "Правила сбора",
        form: {
          info: "Интеллектуальный сбор информации",
          infoPlaceholder: "Выберите классификацию",
        },
        table: {
          name: "Название поля",
          props: "Свойства клиента",
          type: "Тип поля",
          desc: "Описание поля",
        },
      },
      dataAdd: {
        title: "Добавление данных",
        types: {
          auto: "Автоматическое добавление",
          manual: "Добавление вручную",
        },
      },
      wechatStaff: {
        table: {
          name: "Имя",
          part: "Отдел",
          phone: "Номер мобильного телефона",
          email: "Почтовый ящик",
          wechartId: "Корпоративный WeChat ID",
          status: "Статус службы",
        },
        info: "Сначала выберите отдел, который требует синхронизации данных",
        synSuccess: "Синхронизация успешно",
        init: "Инициализация",
        syn: "Синхронизация",
      },
      appealHistory: {
        appealTime: "Время обжалования",
        cancelTime: "Время отмены",
        reviewTime: "Время проверки",
        inspector: "Инспектор качества",
        result: "Результаты жалобы",
        complainResult:
          "Всего было подано {0} пунктов проверки качества, успешно подано {1}, отклонено {2}",
        reason: "Причина жалобы",
        rejection: "Причина отклонения",
        cancel: "Отмена",
        tip: "Подтвердите отзыв жалобы?",
      },
      appealSdk: {
        time: "Время разговора",
        externalIdCall: "Идентификатор записи звонков",
        externalIdText: "Идентификатор записи разговора",
        customerNickName: "Ник клиента",
        readStatus: "Проверка статуса",
        status: "Статус жалобы",
        isManual: "Является ли ручная проверка качества",
        complainButtonInfo:
          "Разрешено подавать жалобу {0} раз, если она уже была подана {1} раз, то может быть подана еще {2} раз. Может подать апелляцию до {3}",
        submit: "Подать жалобу",
        complain: "Жалоба",
        allComplain: "Все жалобы",
        pendingSubmit: "В ожидании представления",
        taskIds: "Задачи контроля качества",
        graded: "Рейтинг",
        complainSuccess: "Подать жалобу успешно",
        appealSdkIndex: {
          title: "Интеллектуальные результаты контроля качества",
        },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Получить информацию о списке фильтров не удалось, проверьте подключение к сети или попробуйте позже!",
      columns: "Столбец",
      filter: "Фильтрация",
      noSearchResult: "Записи не найдены",
      pleaseSearch: "Пожалуйста, выберите фильтр и попробуйте поиск",
      sift: "Фильтрация",
      create: "Новое строительство",
      search: "Поиск",
      createTaskTitle: "Новые задачи",
      title: "Заголовок",
      deadline: "Крайний срок",
      principal: "Ответственный",
      enqueueTime: "Время присоединения",
      computeError: "Расчет не удался, пожалуйста, попозже!",
      sum: "Мет",
      max: "Максимальное значение",
      min: "Минимальное значение",
      avg: "Среднее значение",
      count: "Подсчет",
      maxDate: "Максимум (неопр.)",
      minDate: "Минимальное значение (самое старое)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Получить адрес ссылки Insight не удалось, повторите попытку позже!",
      },
      template: {
        learningReminder: "Напоминания об обучении",
        subscriptionDynamics: "Подписка на динамические",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Управление задачами", chaneg: "Переключение" },
        qualityCheckTemplate: {
          text: "Шаблон контроля качества",
          collectText: "Шаблон сбора",
          condition: { text: "Условия проверки качества" },
          checkPoint: { text: "Конфигурация правил" },
          automaticAdd: { text: "Автоматизация процессов" },
          manualAdd: { text: "Добавление вручную" },
          roleConfig: { text: "Конфигурация роли" },
          basicInfo: { text: "Основная информация" },
          smartWordsLibrary: { text: "Интеллектуальный тезаурус" },
          grade: { text: "Рейтинг контроля качества" },
          collectionRule: { text: "Правила сбора" },
          addData: { text: "Добавление данных" },
          collectionList: { text: "Список коллекции" },
        },
        qualityCheckWorkbench: {
          text: "Рабочий стол контроля качества",
          wechatText: "Аналитический стол",
          informationCollectText: "Сбор рабочих столов",
          qualityInspectionList: {
            text: "Список контроля качества",
            wechatText: "Список анализа",
            informationCollectText: "Список коллекции",
          },
          condition: { text: "Список контроля качества (заброшен)" },
          reviewList: { text: "Список обзора" },
          spotCheckList: { text: "Список выборочных проверок" },
          caseBaseList: { text: "Библиотека дел" },
          testSetList: { text: "Тестовый набор контроля качества" },
        },
        statisticsAndReports: {
          text: "Статистика и отчеты",
          wholeReport: { text: "Общий отчет" },
          groupReport: { text: "Отчет группы обслуживания клиентов" },
          agentReport: { text: "Отчеты по обслуживанию клиентов" },
          customerServiceExecution: { text: "Анализ обслуживания клиентов" },
          analysisOverview: { text: "Аналитический обзор" },
          inspectionItemsAnalysis: { text: "Анализ качества" },
        },
        wechatQa: {
          text: "Корпоративная проверка качества WeChat",
          task: { text: "Аналитические задачи" },
          baseInfo: { text: "Основная информация" },
          checkPoint: { text: "Анализ конфигурации модели" },
          setting: { text: "Конфигурация корпоративного анализа WeChat" },
          automaticAdd: { text: "Автоматизация процессов" },
          manualAdd: { text: "Добавление вручную" },
        },
        clientCenter: { text: "Центр обслуживания клиентов" },
      },
      conditionDictionaries: {
        title: "Все условия",
        searchDefaultText: "Введите идентификатор или имя",
        id: "ID",
        name: "Название",
        conditionDetailedShow: "Развернуть",
        conditionDetailedHide: "Убей",
        remark: "Описание:",
        checkRole: "Роль:",
        operator: "Оператор:",
        operatorLogic: "Логика:",
      },
      audioTextView: {
        customer: "Клиенты",
        agent: "Обслуживание клиентов",
        unsupportedFormat: "Формат пока не поддерживается",
      },
      checkPointClassifications: {
        add: "Добавлена классификация",
        name: "Название",
        enterToSave: "Нажмите Enter, чтобы сохранить",
        highestScore: "Максимальное ограничение баллов",
        minimumScore: "Нижний предел минимального балла",
        defaultMinScore: "0 баллов по умолчанию",
        minimumScoreTitle: "Нижний предел баллов",
        beginScore: "Общее значение оценки",
        beginScoreTitle: "Значение оценки",
        scoreSetTip:
          "Максимальный верхний балл меньше суммы баллов, вы можете выбрать:",
        ignoreExcessSpillover: "Игнорирование избыточного переполнения",
        automaticWeightCalculation: "Автоматический учет весов",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Поля" },
    checkRange: {
      numberTipOne: "Первый",
      numberTipTwo: "Предложение",
      numberTipThree: "Предложение до первого",
      conditionLimit: "Условные ограничения",
      roleLimit: "Ограничения ролей",
      contextLimit: "Ограничение предложения",
      notZero: "Ограничение предложения не поддерживает ввод 0",
      startMustLessThanEnd:
        "Стартовая позиция предложения должна быть меньше, чем конечная позиция предложения",
    },
    waveAudio: {
      play: "Воспроизведение",
      pause: "Приостановка",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Пожалуйста, введите",
      pleaseEnterRegular: "Введите регулярное выражение",
      pleaseEnterGrammar: "Пожалуйста, введите грамматические правила",
      pleaseEnterTestText: "Введите, что требуется для проверки",
      pleaseEnterLoudness: "Пожалуйста, введите громкость децибел",
      pleaseEnterRangeAbility: "Введите величину изменения",
      afterHit: "Проверка после попадания",
      pleaseEnterContext: "Введите ссылку",
      wordNumber: "Разрыв в количестве слов",
      intervalSecond: "Интервал больше",
      minute: "Каждую минуту",
      wordUnit: "Слово",
      test: "Тест",
      testRegular: "Тестирование регулярных выражений",
      testGrammar: "Тестовая грамматия",
      testGrammarError: "Провал грамматические тесты",
      regularError: "Ошибка регулярного выражения, введите снова",
      pleaseEnterSemanticSentence:
        "Введите предложения, требующие семантического обнаружения",
      pleaseEnterAssociate:
        "Обратитесь к описи справа, чтобы создать выражение данных в пути",
      querySemanticTags: "Выбор семантической метки",
      reQuerySemanticTags: "Повторный выбор семантической метки",
      semanticTags: "Семантические метки",
      standardSentence: "Стандартное предложение",
      semanticName: "Семантические метки",
      sentence: "Стандартное предложение",
      similarity: "Степень сходства",
      actions: "Операция",
      tipOne: "Не нашли подходящего ярлыка,",
      tipTwo: "Добавление новой семантической метки",
      notLessThan: "Не меньше, чем",
      notGreaterThan: "Не больше",
      ignoreLessThan: "Игнорировать меньше",
      wordUnitSet: "Слово предложение",
      speedWordNotNull:
        "Минимальное количество слов и максимальное количество слов не могут быть пустыми",
      speedWordMinValueLessThanMaxValue:
        "Минимальное количество слов для определения скорости речи не может быть больше, чем максимальное количество слов",
      speechGrabbingIntervalTip: "Интервал перехвата> =",
      speechLengthTip: "Длинные слова> =",
      msUnit: "ms",
      sUnit: "Секунд",
      grabIntervalNotNull: "Интервал перехвата не может быть пустым",
      grabLengthNotNull: "Длинные слова не могут быть пустыми",
      pleaseSelectEntity: "Выберите объект информации",
      pleaseSelectOperator: "Пожалуйста, выберите операцию",
      pleaseSelectBasicInfo: "Пожалуйста, выберите основную информацию",
      pleaseEnterFixedValue: "Введите фиксированное значение",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Матч прошел успешно",
      matchError: "Матч не удался",
      mindetectionStatement: "Минимальный оператор обнаружения",
      conceptWordholder: "Пожалуйста, выберите концептуальное слово",
      elementHolder: "Пожалуйста, введите элемент",
      associateMedol: { tabs: { data: "Данные", operator: "Оператор" } },
      repet: "Повторение",
      bout: "Раз",
      ignore: "Игнорировать меньше",
      ignoreAffix: "Слово предложение",
      associateData: "Данные по дороге",
      pretreatmentData: "Предварительная обработка данных",
      reviewTaskData: "Обзор данных задачи",
      taskData: "Данные задачи контроля качества",
      businessData: "Бизнес-записи",
      functionOperator: "Оператор функции",
      operation: "Оператор операции",
      notAnyMatch: "При пропущенных проблемах:",
      times: "Количество появлений",
      newAssociateDataTip:
        "Если значение является числовым типом, используйте устаревшую функцию",
    },
    conditionGather: {
      cycle: "Цикл",
      rlue: "Правила",
      delete: "Удалить",
      sampling: "Экстракция",
      item: "Полоска",
      percent: "%",
      samplingSame: "Та же сумма для каждой службы поддержки",
      samplingRecheck: "Разрешить повторное извлечение выборочных записей",
      dateUnit: "Номер",
      samplingTip:
        "Разные правила, охватывающие один и тот же вызов, не будут повторяться, а извлеченные и непроверенные записи не будут извлечены снова.",
      add: "Добавление правил",
    },
    ruleGather: {
      title: "Правила",
      screeningCondition: "Условия скрининга",
      addRule: "Добавление правил",
      inspector: "Инспектор качества",
      ruleTip:
        "Режим распределения, где правила имеют приоритет, распределение сверху вниз последовательно, распределение не повторяется, правила или не совпадают произвольные элементы правила будут распределяться случайным образом",
      moveUp: "Вниз вверх",
      moveDown: "Сниз",
      top: "Наверху",
    },
    pushRulesGather: {
      title: "Правила",
      delete: "Удалить",
      newPush: "Добавить push",
      newRule: "Добавление правил",
      sampling: "Экстракция",
      item: "Полоска",
      percent: "%",
      samplingSame: "Та же сумма для каждой службы поддержки",
      dateUnit: "Номер",
      screeningCondition: "Условия скрининга",
      email: "Почтовый ящик",
      emailPlaceholder: "Введите почтовый ящик и разделите его запятыми",
      updateType: "Тип обновления",
      pushDetail: "Push детали",
      pushUrl: "Push URL",
      pushWay: "Тип толчка",
      pushMethod: "Push-метод",
      pushContentType: "Формат данных",
      pushData: "Push-данные",
      additionalData: "Дополнительные данные",
      inspectionType: "Тип контроля качества",
      all: "Все.",
    },
    taskAdditionalData: {
      newData: "Новые данные",
      delete: "Удалить",
      placeholder: "Введите имя",
      dataPlaceholder: "Введите содержание",
    },
    UdeskQaReact: {
      loadSdkError: "Ошибка загрузки SDK, повторите попытку позже!",
    },
    gearOptionList: { evaluation: "Оценка" },
    tableComponentTransfer: {
      leftTitle: "Список элементов",
      rightTitle: "Отображение предметов",
    },
    kmTinymce: {
      link: "Вставка/редактирование ссылок",
      modalTitle: "Вставить ссылку",
      href: "Адрес",
      text: "Отображение текста",
      title: "Заголовок",
      target: "Открыть способ",
      none: "Нет.",
      newWindow: "Откроется в новом окне",
      buttonStyle: "Стиль кнопки",
      close: "Закрыть",
      open: "Открыть.",
      answerMessage: "Перейти/Отправить сообщение",
      answerMessageTitle: "Кнопка отправки сообщений",
      answerMessageText: "Отображение текста",
      answerMessageContent: "Содержание сообщения",
      answerMessageButtonStyle: "Стиль кнопки",
      answerMessageTips:
        "Примечание. Кнопка отправки сообщений поддерживает только веб-каналы, пожалуйста, настройка с осторожностью",
      answerSwitchStaff: "Прыжок/ручная",
      answerSwitchStaffTitle: "Перейти на ручную кнопку",
      answerSwitchStaffText: "Отображение текста",
      answerSwitchStaffRule: "Правило кнопки",
      answerSwitchStaffButtonStyle: "Стиль кнопки",
      answerSwitchStaffTips:
        "Примечание. Кнопка «Ручной» поддерживает только веб-каналы, пожалуйста, настройте его с осторожностью",
      textButton: "Текстовая кнопка",
      borderButton: "Кнопка с рамкой",
      message: "Отправить сообщение",
      switchStaff: "Перейти к ручному",
      messageError: "Содержание кнопки не может превышать 10 слов",
      messageIsEmptyError: "Содержание кнопки не может быть пустым",
      okText: "Определить",
      cancelText: "Отмена",
      video: "Видео",
      uploadVideo: "Загрузить видео",
      uploadText: "Поддерживает только формат MP4, размер не превышает 20M",
      videoEmptyOrUploading: "Видео пустое или загруженное",
      customEmoji: "Выражение",
    },
    massageConfiguration: {
      pushTarget: "Цель уведомления",
      customizePushTarget: "Пользовательские push-цели",
      sysUser: "Текущие пользователи системы",
      sysRole: "Текущая роль системы",
      emailPushTarget: "Отправить цель",
      targetEmailsPlaceholder:
        "Введите почтовый ящик и разделите его запятыми на английском языке",
      subject: "Название сообщения",
      SDKID: "Идентификационный идентификатор SDK",
      content: "Содержание уведомления",
      sdkPushType: "Push-схема",
      sdkPushURL: "Push URL",
      pushData: "Push-данные",
      sdkPushWay: "Push-метод",
      dataType: "Формат данных",
      additionalData: "Дополнительные данные",
      name: "Название сообщения",
      filter: "Условия скрининга",
      triggerType: "Спусковой механизм",
      pushCycle: "Сроки и фиксированные частоты",
      pushContent: "Содержание доклада",
      qualityList: "Список результатов контроля качества",
      designated: "Назначенный персонал",
      messageType: "Тип сообщения",
    },
    taskCard: {
      template: {
        cancelCollection: "Отмена коллекции",
        collection: "Коллекции",
      },
    },
    semanticTagsSelect: {
      index: { standardSentence: "Стандартное предложение:" },
    },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Коллекции",
          calls: "Звоните.",
          createdBy: "Создатель:",
          createdOn: "Время создания:",
        },
        fileItem: {
          uIProvidesIcon: "Ui Предоставлять icon",
          minute: "Минута.",
          callClip: "Фрагмент звонка",
          start: "Начать",
          End: "Конец.",
          employees: "Сотрудники:",
          Customer: "Клиенты:",
          sessionTime: "Время сеанса:",
          reasonForRecommendation: "Рекомендуемые причины:",
          addedBy: "Добавлено:",
          addTime: "Добавить время:",
        },
        modalAddFile: {
          caseTitle: "Название дела",
          addSomeClipsToTheCaseLibrary: "Добавить часть фрагмента в библиотеку",
          addToCaseBase: "Добавить в библиотеку дел",
          storedIn: "Хранится в",
          pleaseSelect: "Пожалуйста, выберите",
          reasonsForRecommendation: "Рекомендуемые причины",
          pleaseEnter: "Пожалуйста, введите",
        },
      },
      shareModal: {
        index: {
          customer: "Клиенты:",
          employees: "Сотрудники:",
          timeliness: "Своевременность:",
          recordingLinkValidWithinDays:
            "Ссылка на запись в течение дня действительна",
          sharingInstructions: "Поделиться примечанием:",
          shareSuccess: "Поделитесь успехом!",
          cancel: "Отмена",
          share: "Поделиться",
          copySucceeded: "Копирование прошло успешно!",
          copyShareLink: "Копирование и обмен ссылками",
          shareWithColleagues: "Поделиться с коллегами",
          shareWithCustomers: "Поделиться с клиентами",
          staff: "Сотрудники",
          selectColleagues: "Выбор коллег",
          pleaseSelectColleaguesToShare:
            "Пожалуйста, выберите коллегу, которым вы хотите поделиться",
          pleaseEnterTheSharingDescription:
            "Пожалуйста, введите описание обмена",
          taskName: "Задачи:",
          recordingShareValidWithinDays:
            "Обмен ссылками в течение дня действителен",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Не удалось найти файл!",
          sale: "Продажи:",
          customer: "Клиенты:",
          topicDetection: "Темы обнаружения:",
          keyEvents: "Ключевые события:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Выбор коллег",
          helpContent: "Содержание помощи",
          visibleRange: "Видимый диапазон",
          seekCounseling: "В поисках коучинга",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Ищите предложения", concatText: "К." },
      },
      customerInsight: {
        index: {
          customerInformation: "Информация о клиенте",
          customerInsight: "Понимание клиентов",
          customerLabel: "Теги клиентов",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Рекомендуется",
          objectionHandlingScripts: "Обработка возражений",
          productSellingPointScripts: "Точка продажи продукта",
          knowledgePointRecommendation: "Рекомендации по знаниям",
          relatedKnowledgePoints: "Связанные точки знаний",
          recommendedCases: "Рекомендуемый случай",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Рекомендуется",
          caseRecommendation: "Рекомендуемый случай",
          hit: "Хит",
          miss: "Удар",
          supervisionItem: "Элемент надзора",
          implementation: "Осуществление",
          reInspection: "Повторная проверка качества",
          supervisionItemSettings: "Настройка элемента надзора",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Телефонный звонок",
          enterpriseWeChatCall: "Enterprise WeChat",
          enterpriseMicroConversation: "Корпоративный микро-разговор",
          open: "Открыть.",
          close: "Закрыть",
          conversationScenario: "Сцена сеанса",
          monitorConversationChannels: "Контролировать канал сеанса",
          permanentlyValid: "Постоянно действителен",
          permanentlyInvalid: "Неэффективно навсегда",
          customValidity: "Пользовательский срок действия",
          termOfValidity: "Срок действия",
          newRating: "Новый рейтинг",
          whenConversation: "Когда сеанс",
          hit: "Хит",
          miss: "Удар",
          time: "Время",
          rated: "Рейтинг",
          qualified: "Квалифицированный",
          unqualified: "Неквалифицированный",
          modifiedSuccessfully: "Модификация прошла успешно",
          classificationName: "Название классификации",
          supervisionObject: "Объект наблюдения",
          entryintoforceTime: "Время вступления в силу",
          supervisionItemRating: "Рейтинг контрольные элементы",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Пожалуйста, проверьте, правильно ли рейтинг элемента наблюдения",
          ratingRules: "Правила рейтинга",
          settingOfRatingRulesForSupervisionItems:
            "Настройка правил оценки контрольные элементы",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Ключевые события",
          supervisionItem: "Элемент надзора",
          conversationScenario: "Сцена сеанса",
          customerLabel: "Теги клиентов",
          reDetectionRules: "Правила повторного обнаружения",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Скачать запись",
          downloadText: "Скачать текст",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Загрузить фотографии",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Пожалуйста, выберите фотографии, размер которых не превышает 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Или",
            and: "И",
            wrong: "Не",
            matchTo: "Соответствие в",
            rule: "Правила",
            satisfactionOperator: "Удовлетворить оператор",
            customerLabel: "Теги клиентов",
            keyEvents: "Ключевые события",
            knowledgePoints: "Точки знаний",
            sessionLabel: "Этикетка сессии",
            supervisionItem: "Элемент надзора",
          },
          linkExplorer: { linkInTheText: "Ссылка на текст:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound: "Пользовательские компоненты не найдены:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "Пользовательские" },
      dropdownMonit: {
        updateSucceeded: "Обновление прошло успешно",
        areYouSureToDelete: "Определились с удалением?",
        custom: "Пользовательские",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Сохранение текущей конфигурации отчета === Форма",
        saveCurrentReportConfiguration:
          "Сохранение текущей конфигурации отчета",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Пожалуйста, введите название часто используемых настроек, максимальная длина составляет 20!",
        preservation: "Сохранить",
      },
      template: {
        saveCurrentReportConfiguration:
          "Сохранение текущей конфигурации отчета",
        updateCurrentReportConfiguration:
          "Обновление текущей конфигурации отчета",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Информация о наработке",
        workOrder: "Наработки",
        triggerScenario: "Сцена запуска",
        associatedCallConversation: "Связанный звонок/разговор",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Тестирование с предыдущим текстом/диалогом",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "Первый", day: "Боже!" },
      businessSummary: {
        normal: {
          equalTo: "Равно",
          notEqualTo: "Не равно",
          contain: "Содержит",
          excluding: "Не содержит",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Я есть я, это другой фейерверк @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Привет, я робот. Уведомление о предупреждении о проверке качества, добавляется в группу 17 октября.",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Я есть я, это другой фейерверк @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Привет, я робот. Уведомление о предупреждении о проверке качества, добавляется в группу 17 октября.",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Темы общения",
          conversationSummary: "Резюме сеанса",
          serviceSuggestions: "Сервисные рекомендации",
        },
        template: { smartTags: "Умные этикетки" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Нажмите, чтобы узнать больше" },
      schema: {
        textRobot: "Текст робот",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Эскорт тренера, вопросы и ответы на документы, диалоги на основе задач, вопросы и ответы на формы, вопросы и ответы на атлас",
        capitalRetentionRobot: "Робот удержания капитала",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Богатые стратегии удержания средств, высокоантропоморфное общение, гарантия высокой ставки удержания, эффективная автоматическая трансформация подсказок",
        outboundRobot: "Экзотические роботы",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Возведенные визиты, опрос удовлетворенности услугами, предупреждения о сбоях в эксплуатации и обслуживании, напоминания о доставке заказов, уведомления о деятельности электронной коммерции, назначения кандидатов, автоматизация бизнеса и повышение эффективности бизнеса",
        incomingRobot: "Входящий робот",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Гибкая интеграция бизнес-систем, таких как заказы и заказы, для автоматизации процессов, настраиваемая идентификация голосовой семантической модели, немедленный бизнес-эффект, сущность (номер мобильного телефона, сумма заказа, время заказа, адрес,...) Идентификация, быстрая поддержка сложного бизнеса",
        intelligentQualityInspection: "Интеллектуальная проверка качества",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Полная проверка качества, своевременная и эффективная, точность 85%",
        intelligentSessionAnalysis: "Интеллектуальный анализ сеанса",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Узнайте о недостатках бизнеса, прислушайтесь к мнению клиентов и улучшайте маркетинговые преобразования",
        enterpriseWechatConversationAnalysis:
          "Анализ сеансов корпоративного WeChat",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Эффективное определение потенциальных возможностей для бизнеса и рисков, увеличение конверсии маркетинга на 20%",
        assistant: "Ассистент",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Ассистент может гарантировать, что служба поддержки новичков завершит обслуживание и улучшит качество звонков в службу поддержки клиентов. Пусть каждое место будет вашим козырем.",
        digitalPeople: "Цифровой человек",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          "«Лицом к лицу» антропоморфное общение, поддержка звука, текста, видео, сенсорного многомодального взаимодействия, реализация погружного общения, улучшение имиджа бренда и обслуживания пользователей",
        aIKnowledgeZhongtaiEnterpriseSearch: "AI Знание",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Поиск знаний, обмен знаниями, поиск документов, поиск баз данных",
        aIKnowledgeCenterKCsKnowledgeBase: "База знаний AI в Тайване · KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Обеспечивает управление полным жизненным циклом знаний, унифицированное обслуживание ботов и баз знаний IM, поддерживает стыковку сторонних баз знаний, шаблоны знаний, атомные знания и другие мощные функции, чтобы обеспечить более удобный опыт управления знаниями",
        aIKnowledgeCenterKnowledgeMap: "AI Знание в Тайване · Атлас знаний",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "Универсальное построение карт объединяет корпоративные знания, большие карты показывают детали знаний, возможность стыковки создает пользовательский опыт",
        modularDevelopmentPlatformOriginalEngineASR:
          "Модульная платформа разработки · Оригинальный двигатель · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Принять сквозные алгоритмы для достижения эффекта от индивидуализации бизнеса, уровень распознавания естественной сцены более 90%, персонализированное распознавание словарного запаса более 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Модульная платформа разработки · Интеллектуальный маркетинг",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Использование алгоритмов глубокого обучения в сочетании с портретами пользователей для достижения точных рекомендаций по продуктам и улучшения конверсии продаж",
        modularDevelopmentPlatformUserPortrait:
          "Модульная платформа разработки · Портрет пользователя",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Создайте портрет пользователя точно на основе пользовательских данных и данных разговора, чтобы полностью описать характеристики клиента",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Модульная платформа разработки",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "В соответствии с портретом пользователя и портретом продукта, автоматически порекомендуйте пользователям продукты, которые могут представлять интерес, и достигните тысячи персонализированных рекомендаций",
        modularDevelopmentPlatformNLPPAAS:
          "Модульная платформа разработки · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Обеспечить высокую точность NLP базовой способности, охватывающей различные возможности детализации, такие как слова, предложения, статьи и т. Д.",
      },
    },
    qualityScore: {
      index: {
        show: "Показывать:",
        displayBySecondaryClassification:
          "Представление по вторичной классификации",
        showByRule: "Показывать по правилам",
        pleaseEnterClassificationruleName:
          "Введите название классификации/правила",
        clickCopy: "Нажмите, чтобы скопировать",
        hitDetailsOfOperatorsInRules: "Детали попадания оператора в правило:",
        hit: "Хит",
        miss: "Удар",
        filter: "Скрининг:",
        clickToViewDetails: "Нажмите, чтобы просмотреть подробную информацию",
      },
      components: {
        flowNodeList: {
          branch: "Очки",
          pleaseSelect: "Пожалуйста, выберите",
          viewTheCompleteProcess: "Просмотр полного процесса",
          thereIsCurrentlyNoProcessAvailable: "Нет процесса",
        },
        pointItem: { xiangHit: "Хит объекта" },
        flowNodeListModal: { index: { completeProcess: "Полный процесс" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Задачи контроля качества процесса изменились, пожалуйста, повторите апелляцию.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "Эти данные регулярно очищаются",
        subject: "Темы:",
        description: "Описание:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask: "Добавить в задачу контроля качества",
          },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Связанный звонок/разговор" },
    },
    samplingTarget: {
      index: {
        time: "Время",
        samplingQuantityIsLessThan: "Количество выборочных проверок ниже",
        alertWhen: "Предупреждение во времени",
        everyday: "Ежедневно",
        weekly: "Еженедельно",
        monthly: "В месяц",
        samplingInspectionTargetSavedSuccessfully:
          "Цель выборочной проверки успешно сохранена",
        personnel: "Персонал",
        eachCustomerService: "Каждый клиент",
        cycle: "Цикл",
        samplingInspectionTarget: "Цель выборочной проверки",
        strip: "Полоска",
        alertSettings: "Настройки раннего предупреждения",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Обслуживание клиентов",
        complete: "Завершение",
        Objective: "/Цель",
        everyday: "Ежедневно",
        weekly: "Еженедельно",
        monthly: "В месяц",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Скорость взаимодействия в групповом чате",
        maximumResponseIntervalTime: "Самый длинный интервал ответа",
        numberOfPreemptiveCalls: "Количество украденных звонков",
        numberOfMessages: "Количество сообщений",
        strip: "Полоска",
        proportionOfMessages: "Доля сообщений",
        speechPercentage: "Доля речи",
        longestCustomerTalk: "Самая длинная речь клиента",
        numberOfIndepthDiscussions: "Количество углубленных обсуждений",
        second: "Раз",
        maximumDurationOfContinuousSpeech:
          "Самая длинная продолжительность разговора",
        questionFrequency: "Частота вопросов",
        customer: "Клиенты",
        keyEvents: "Ключевые события:",
        opportunity: {
          index: {
            generationFailed: "Сбой генерации",
            generateOpportunityPoints: "Генерация точек возможностей",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Сбой генерации",
            generateSummary: "Создание резюме",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Определились с удалением?",
        add: "Добавить",
        opportunityPoints: "Точка шанса",
        conversationSummary: "Резюме сеанса",
        cancel: "Отмена",
        determine: "Определить",
        edit: "Редактирование",
        establish: "Создание",
        conversationalInsights: "Разговорные идеи",
        keyEvents: "Ключевые события",
        numKeyEvents: "Ключевое событие",
        questionAnalysis: "Анализ вопросов",
        find: "Найди",
        questions: "Второй вопрос",
        conversationAction: "Сеансовые действия",
        customer: "Клиенты",
        sale: "Продажа",
        sessionLabel: "Сцена сеанса",
        employeeQuestions: "Вопросы сотрудников",
        customerQuestions: "Запросы клиентов",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Клиенты",
        customerService: "Обслуживание клиентов",
        replyTo: "Ответить на",
        addressee: "Получатель:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Повторная идентификация",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Повторная идентификация потребляет время транслитерации, пожалуйста, будьте осторожны",
        determine: "Определить",
        executionSuccessful: "Успешное выполнение",
        addToQualityInspectionTask: "Добавить в задачу контроля качества",
        batchOperation: "Операции партии",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Добавить успех",
        addToQualityInspectionTask: "Добавить в задачу контроля качества",
        qualityInspectionTask: "Задачи контроля качества",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Роль обнаружения",
        detectionRange: "Диапазон обнаружения",
        sentence: "Предложение",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "Эти данные регулярно очищаются",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Возврат бизнес-системы" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Проблема извлечения",
        businessSummary: "Бизнес-резюме",
        keyWord: "Ключевые слова",
      },
      components: {
        problemExtraction: {
          no: "Нет",
          customerServiceDidNotAnswer: "Служба поддержки не ответила",
          customerServiceHasAnswered: "Служба поддержки ответила",
          problemUnresolved: "Проблема не решена",
          theProblemHasBeenResolved: "Проблема решена",
          customerIssue: "Вопрос клиента:",
          customerServiceAnswer: "Ответ службы поддержки клиентов:",
          reExtractQuestions: "Проблема повторного извлечения",
          clickToExtractQuestions: "Нажмите, чтобы извлечь проблему",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Название портрета",
            backgroundDescription: "Описание фона",
            customerLabel: "Теги клиентов",
            labelManagement: "Управление этикетками",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: {
              index: { newlyBuild: "Новое строительство" },
            },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Спросите настройки" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Увеличение запросов",
              remove: "Удалить",
              addQuestioning: "Добавить запрос",
              section: "Первый",
              secondInquiry: "Второй запрос",
              questioningScore: "Спроси счет",
            },
          },
        },
        index: {
          accordingToRules: "По правилам",
          byScore: "По очкам",
          whenMissRule: "Когда пропущено правило",
          whenConductingQuestioning: "Когда допрашивать",
          pleaseSelectARule: "Пожалуйста, выберите правило",
          ruleCannotBeEmpty: "Правила не могут быть пустыми",
          currentScriptScore: "Когда счет речи ≤",
          pleaseEnterTheScore: "Пожалуйста, введите счет",
          scoreCannotBeEmpty: "Счет не может быть пустым",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Рассказчик:" },
          pPT: { slide: "Слайды" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Комментарии",
              script: "Слова",
              semantics: "Семантика",
              violatingWords: "Незаконные слова",
              speechSpeed: "Скорость речи",
              emotion: "Эмоции",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Комментарии",
              regenerate: "Повторная генерация",
              generate: "Генерация",
              hitSituation: "Удар ситуации",
              noSemanticTagsMatched: "Несоответствие семантической метки",
              matchToSemanticTags: "Соответствие семантической метки",
              appealToBeSubmitted: "Жалоба должна быть подана",
              ratingHasBeenReviewed: "Оценка была пересмотрена",
              ratingAppealInProgress: "Оценка в жалобе",
              expressionScore: "Оценка выражения",
              moodParticlesTimes: "Слова, {0} раз",
              changeTheModalParticlesTo: "Изменить на",
              second: "Раз",
              interrogation: "Спросите",
              questioningWithoutScoring: "Спрашивать без оценки",
              branch: "Очки",
              matchingRate: "Коэффициент соответствия",
              ratingAppeal: "Оценка жалоб",
              changeTo: "Читать:",
              wordPerMinute: "Слово в минуту",
              completeScript: "Полный разговор",
              incompleteScript: "Неполная речь",
              semanticHit: "Семантический хит",
              semanticMisses: "Семантический промах",
              scriptViolation: "Нарушения речи",
              modified: "Изменено",
              notChanged: "Не изменено",
              keyWord: "Ключевые слова",
              semanticLabels: "Семантические метки",
              semanticMatchingRate: "Семантическая скорость соответствия",
              violatingWords: 'Незаконные слова"',
              delete: "Удалить",
              unmatched: "Не совпадают",
              matching: "Матч",
              matchTo: "Соответствие в",
              notMatchedTo: "Не соответствует",
              scriptScore: "Голосовой счет",
              standardScript: "Стандартная речь",
              emotionalScore: "Эмоциональный счет",
              speechSpeedScore: "Оценка скорости речи",
              viewResolution: "Просмотр анализа",
              nodeConfiguration: "Конфигурация узла",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Время ответа на этот вопрос превышено, и содержание ответа будет отправлено автоматически",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Время ответа на этот вопрос превышено, и содержание ответа будет отправлено автоматически",
          },
          correctBtn: {
            saveSuccessful: "Успешное сохранение",
            errorCorrection: "Исправление ошибок",
            cancel: "Отмена",
            preserve: "Сохранить",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Правила подсчета очков",
            cancel: "Отмена",
            determine: "Определить",
          },
          scoringRuleItem: {
            whole: "Все.",
            arbitrarily: "Произвольно",
            pleaseEnterARuleName: "Введите название правила",
            standardScript: "Стандартная речь",
            if: "Если",
            and: "И",
            or: "Или",
            thenScore: "Тогда счет",
            pleaseEnterAScore: "Пожалуйста, введите значение",
            bonusPoints: "Дополнительные очки",
            minusPoints: "Снижение баллов",
            hit: "Хит",
            misses: "Удар",
            keyWord: "Ключевые слова",
            semantics: "Семантика",
          },
          title: {
            ruleValidation: "Проверка правил",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Правила оценки (общая оценка текущей речи",
            points: "В)",
            editRules: "Правила редактирования",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Нет этикетки" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Коммуникационные цели",
              scoringCriteria: "Критерии оценки",
            },
          },
        },
        index: {
          generating: "В генерации",
          generateDialogueFramework: "Генерируемые рамки диалога",
          theme: "Темы",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Направление",
            generatePromptLanguage: "Генерируемые подсказки",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Этап общения",
          communicationObjectives: "Коммуникационные цели",
          scoringCriteria: "Критерии оценки",
          suggestionsForImprovement: "Рекомендации по повышению",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Оценка процесса",
            contentIntegrity: "Контент целостности:",
            NumberOfCompletedProcesses: "%, Количество завершенных процессов",
            keyHitPointsTotal: "Основные моменты хитов:",
            notReached: "Не достигнуто",
            accurateFollowupAccumulationOfStandardScripts:
              "Точное чтение: стандартное накопление речи",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Слова, студенты следуют за количеством слов",
            aWord: "Слово",
            languageNormsYes: "Языковая спецификация: есть",
            emotionsAppearing: "Эмоции: появляются",
            speechSpeedSpeakingTooFast:
              "Скорость речи: скорость речи слишком высокая",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Эстрагические слова: слишком много",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Добавить в игнорирование тегов",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Вы уверены, что хотите повторно выполнить смарт-теги?",
        reExecute: "Повторное выполнение",
      },
      component: { successfullyAdded: "Добавить успех" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Отключить все уведомления",
        reQualityInspectionTask: "Задачи повторного контроля качества",
        requalityInspection: "Повторная проверка качества",
        waiting: "В ожидании",
        viewDetails: "Посмотреть детали",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Время обнаружения:" } },
        },
        template: { responseOpeningSentence: "Ответ на начальное предложение" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "Сбой генерации", generate: "Генерация" },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Нет данных" } },
      },
      index: { regenerate: "Повторная генерация" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Определились с удалением?",
        operationSuccessful: "Операция прошла успешно",
      },
      template: { delete: "Удалить" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Пожалуйста, выберите вопрос!",
        similarQuestionRecommendation: "Похожие вопросы рекомендуется",
        selectAllOnPage: "Когда все страницы выбраны",
        cancel: "Отмена",
        preserve: "Сохранить",
        changeBatch: "Изменить партию",
      },
    },
    listControl: { index: { columnControl: "Контроль столбцов" } },
    loginUserAvatar: { index: { switch: "Переключение" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "При добавлении данных в режиме реального времени выполнение задачи контроля качества все еще требует времени. Конкретное время варьируется от нескольких минут до двух часов в зависимости от объема проверенных данных.",
      },
    },
    bEditor: {
      index: {
        delete: "Удалить",
        cancel: "Отмена",
        confirm: "Определить",
        insert: "Вставка",
        width: "Ширина",
        height: "Высота",
        clearContent: "Очистить содержание",
        revoked: "Отмена",
        redo: "Переделать",
        fontSize: "Размер шрифта",
        rowHeight: "Высота линии",
        wordSpacing: "Расстояние между словами",
        paragraphIndent: "Отступление абзаца",
        increaseIndent: "Увеличение отступление",
        decreaseIndent: "Уменьшение отступов",
        border: "Бордюр",
        color: "Цвет",
        textColor: "Цвет текста",
        backgroundColor: "Цвет фона",
        temporaryColor: "Временный цвет",
        bold: "Толстый шрифт",
        italic: "Курсив",
        underline: "Подчеркивание",
        strikethrough: "Зачеркивание",
        font: "Шрифты",
        textAlignment: "Выравнивание текста",
        left: "Цзо Цзо",
        centered: "Центрирование",
        right: "Правая",
        bothEnds: "На обоих концах",
        leftFloat: "Левый плавающий",
        rightFloat: "Правый плавающий",
        subrupt: "Надпись",
        subscript: "Подстрочная ставка",
        clearStyle: "Очистить стиль",
        title: "Заголовок",
        regular: "Рутина",
        orderedList: "Упорядоченный список",
        unorderedList: "Неупорядоченный список",
        reference: "Цитирование",
        code: "Код",
        link: "Ссылки",
        clearLink: "Очистить ссылку",
        horizontalLine: "Горизонтальные линии",
        media: "СМИ",
        mediaLibrary: "Медиатека",
        smallExpression: "Маленькое выражение",
        fullScreen: "Полный экран",
        exitFullScreen: "Выход из полноэкранного",
        enterLinkText: "Введите текст ссылки",
        enterLinkAddress: "Введите адрес ссылки",
        enterTheLinkAddressAndPressEnter:
          "Введите адрес ссылки и введите обратно",
        openInNewWindow: "Откроется в новом окне",
        removeLink: "Удалить ссылку",
        playingAudioFiles: "Воспроизведение аудиофайлов",
        playVideoFiles: "Воспроизведение видеофайлов",
        embeddedMedia: "Встроенные медиа",
        image: "Изображения",
        video: "Видео",
        audio: "Аудио",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Название",
        description: "Описание",
        type: "Тип",
        status: "Состояние",
        actions: "Операция",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Название задачи",
        description: "Описание",
        form: "Форма контроля качества",
        actions: "Операция",
        createUser: "Создатель",
      },
      samplingRecords: {
        createTime: "Время",
        type: "Тип",
        operateUser: "Оператор",
        number: "Добавить количество",
        filterConditionList: "Условия скрининга",
        sampleRuleStr: "Условия выборки",
        timeGreaterThan: "Время больше, чем",
        timeLessThan: "Меньше времени",
      },
    },
    customFilters: {
      createdAt: "Время разговора",
      customerId: "Клиенты",
      agentId: "Обслуживание клиентов",
      userGroupId: "Группа обслуживания клиентов",
      state: "Состояние",
      lastEvaluateUserId: "Последний рейтинг людей",
      submitTime: "Время окончательного представления",
      totalScore: "Общая оценка",
      formItemForbidIdList: "Запрет",
      formItemDeadlyIdList: "Смертельный элемент",
    },
    samplingInspection: {
      callTime: "Время разговора",
      dialogTime: "Время разговора",
      originator: "Назначает лицо",
      createTime: "Время создания",
      updateTime: "Время обновления",
      status: "Состояние процесса",
      score: "Оценка",
      actions: "Операция",
      reviewer: "Рецензиер",
      samplingOfficer: "Инспектор по выборке",
      id: "Идентификатор записи звонков",
      time: "Время разговора",
      phone: "Телефон клиента",
      staff: "Обслуживание клиентов",
      inspectionStatus: "Статус контроля качества",
      samplingStatus: "Статус выборочной проверки",
      inspectionGrade: "Рейтинг",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Стандартные слова",
        synonymWords: "Синонимы",
        synonymWordsNumber: "Количество синонимов",
        useFrequency: "Частота использования",
        effectiveness: "Эффективность",
        operation: "Операция",
      },
      common: {
        useFrequency: "Частота использования",
        effectiveness: "Эффективность",
        operation: "Операция",
      },
      professionalWords: {
        professionalWords: "Профессиональное существительное",
        useFrequency: "Использование статистики",
        homophonic: "Горячие слова (гомофтонная замена)",
        target: "Замена существительных",
        targetWords: "Заменить слово",
        homophonyWords: "Омофонически экранированные слова",
        operation: "Операция",
      },
      attentionWords: "Слова внимания",
      ignoreWords: "Игнорировать слова",
      wordsWeight: {
        selfDefineWeightWords: "Пользовательские весовые слова",
        weightWords: "Весовые слова",
        weight: "Вес",
        useFrequency: "Частота использования",
        effectiveness: "Эффективность",
        operation: "Операция",
      },
      corpusData: {
        corpusDescription: "Описание корпус",
        updateTime: "Время обновления",
        wordsCount: "Количество слов",
        effectiveness: "Эффективность",
        operation: "Операция",
        corpusContent: "Содержание",
      },
    },
    semanticTags: {
      title: "Семантические метки",
      tags: "Стандартное предложение",
      orgName: "Дочерняя организация",
      tagCount: "Количество тренировок",
      usingCount: "Количество ссылок",
      activeFlag: "Эффективность",
      suggestion: "Рекомендации по обучению",
      actions: "Операция",
    },
    intelligentTags: {
      tagName: "Название",
      tagDesc: "Описание",
      tagRefCnt: "Количество маркеров",
      categoryName: "Классификация этикеток",
      eventName: "Классификация событий",
      tagStatus: "Эффективность",
      actions: "Операция",
      tagType: "Типы этикеток",
    },
    dataPreprocess: {
      name: "Название",
      desc: "Описание",
      dataSource: "Ограничения источников данных",
      type: "Тип",
      status: "Эффективность",
      actions: "Операция",
    },
    informationEntities: {
      name: "Название",
      apiName: "Имя API",
      type: "Тип",
      remark: "Описание",
      activeFlag: "Эффективность",
      actions: "Операция",
    },
    interactiveRecognition: {
      questionAndAnswer: "Стандартные вопросы & Ответы",
      type: "Тип",
      status: "Состояние",
      actions: "Операция",
      question: "Вопрос",
    },
    role: { id: "ID", name: "Название", actions: "Операция" },
    uploadDownload: {
      taskName: "Название файла",
      createTime: "Дата",
      size: "Размер",
      createUserName: "Оператор",
      status: "Состояние",
      result: "Результаты",
      actions: "Операция",
    },
    taskCenterList: {
      name: "Содержание задачи",
      createTime: "Время представления",
      startTime: "Время начала",
      endTime: "Время окончания",
      createUserName: "Оператор",
      status: "Состояние",
      result: "Результаты",
      actions: "Операция",
    },
    customerInformation: {
      title: "Информация о обслуживании клиентов",
      data: "Просмотр только аномальных данных",
      list: {
        agentNo: "Номер работы/ID обслуживания клиентов",
        agentName: "Обслуживание клиентов",
        agentTel: "Телефон",
        agentEmail: "Почтовый ящик",
        updateTime: "Время обновления",
        actions: "Операция",
      },
      upload: {
        second:
          "2. Нажмите кнопку импорта, чтобы загрузить файл EXCEl, который соответствует формату шаблона",
      },
    },
    IndexManagement: {
      title: "Управление показателями",
      list: {
        indicatorName: "Название",
        indicatorDesc: "Описание",
        activity: "Эффективность",
        inspectDataSource: "Источник данных",
        sysType: "Тип",
        agentGroups: "Группа обслуживания клиентов",
        actions: "Операция",
      },
      detail: {
        nameEmpty: "Имя не может быть пустым",
        descript: "Описание",
        descriptEmpty: "Описание не может быть пустым",
        active: "Эффективность",
        activeEmpty: "Эффективность не может быть пустой",
        dataSource: "Источник данных",
        dataSourceEmpty: "Источник данных не может быть пустым",
        grounp: "Группа обслуживания клиентов",
        grounpEmpty: "Группа обслуживания клиентов не может быть пустой",
        grounpHolder: "Пожалуйста, выберите группу обслуживания клиентов",
        type: "Способ оценки",
        typeNone: "Метод оценки не может быть пустым",
        base: "Базовое очко",
        baseNone: "Базовое очко не может быть пустым",
        positive: "Положительные факторы",
        positiveNone: "Положительные факторы не могут быть пустыми",
        customAdd: "Добавить фильтр",
        negative: "Негативные факторы",
        negativeNone: "Негативные факторы не могут быть пустыми",
        customOK: "Условия скрининга:",
        judgeStrategy: "Условные правила:",
        other: "Другие факторы",
        otherAdd: "Добавить факторы",
        agent: "Необработанные данные",
        section: "Количество интервалов",
        sectionNone: "Количество интервалов не может быть пустым",
        step: "Интервальный шаг",
        stepNone: "Интервальный шаг",
        save: "Сохранить",
        cancel: "Отмена",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Все.",
      staff: "Только я",
      department: "Этот отдел и ниже",
    },
    gongStatisticsDate: { week: "Неделя", month: "Месяц", season: "Сезон" },
    gongCoachTaskStatus: {
      wait: "В ожидании консультирования",
      already: "Консультирование",
      ignore: "Игнорируется",
    },
    callRangeStatus: {
      all: "Все звонки",
      sale: "Сотрудник инициировал",
      customer: "Заказчик",
    },
    todoListType: {
      later: "Послушайте позже.",
      help: "Консультирование по вопросам помощи",
      contact: "Свяжитесь позже",
      send: "Отправить информацию",
      warn: "Предупреждение о рисках",
    },
    frequencyTypes: {
      everyDay: "Ежедневно",
      everyWeek: "Еженедельно",
      everyMonth: "В месяц",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Обучение неклассифицированным данным",
        markedDataCorrigenda: "Ошибка маркированных данных",
        classifiedDataCheck: "Проверка классифицированных данных",
        markedHistoryRecord: "История маркировки",
        historyDataRecalculate: "Пересчет исторических данных",
      },
      dataExtractMode: {
        random: "Полностью случайный",
        intelligentRecommend: "Умные рекомендации",
        custom: "Пользовательские",
        specifiedClass: "Указанная классификация",
      },
      smartRatesList: {
        markRate: "Коэффициент маркировки",
        corrigendaRate: "Коэффициент ошибок",
        kl: "K-L рассеянность",
        signalNoiseRate: "Отношение сигнал/шум",
      },
    },
    visibleRangeTypes: {
      all: "Все.",
      customerGroup: "Группа обслуживания клиентов",
      userDefined: "Пользовательские",
    },
    samplingRateTypes: {
      everyDay: "Доставка ежедневно",
      everyWeek: "Еженедельная добыча",
    },
    taskRemindTypes: {
      confirm:
        "Отправить напоминание, когда необходимо подтвердить запись задачи контроля качества",
      appeal:
        "Отправить напоминание, когда дело доходит до регистрации задачи проверки качества",
    },
    weekDays: {
      monday: "Понедельник",
      tuesday: "Вторник",
      wednesday: "Среда",
      thursday: "Четверг",
      friday: "Пятница",
      saturday: "Суббота",
      sunday: "Воскресенье",
    },
    reportDateTypes: {
      dailyReport: "Ежедневные отчеты",
      weeklyReport: "Еженедельный отчет",
    },
    samplingRangeDayTypes: { today: "Сегодня", yesterday: "Вчера" },
    samplingRangeWeekTypes: {
      thisWeek: "На этой неделе",
      lastWeek: "На прошлой неделе",
    },
    samplingRuleTypes: {
      proportion: "Пропорциональная выборка",
      random: "Случайная выборка",
    },
    taskStatusTypes: {
      waitEvaluate: "Для оценки",
      waitSubmit: "В ожидании представления",
      waitConfirm: "Подтвердить",
      waitReview: "В ожидании рассмотрения",
      completed: "Завершено",
      all: "Все.",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Прямой рейтинг",
        weightedScore: "Оценка веса",
        deductScore: "Вычет баллов",
        directScoreSecondLevel: "Прямой рейтинг-второй уровень",
        weightedScoreSecondLevel: "Оценка веса-второй уровень",
        deductScoreSecondLevel: "Счет баллов-второй уровень",
      },
    },
    fieldOperators: {
      is: "Равно",
      not: "Не равно",
      contains: "Содержит",
      notContains: "Не содержит",
      not_contains: "Не содержит",
      containsAny: "Содержит произвольные",
      contains_any: "Содержит произвольные",
      is_any: "Содержит произвольные",
      not_any: "Не содержит произвольного",
      notContainsAny: "Не содержит произвольного",
      not_contains_any: "Не содержит произвольного",
      containsAll: "Содержит все",
      contains_all: "Содержит все",
      notContainsAll: "Не содержит все",
      nont_contains_all: "Не содержит все",
      isNull: "Для пустых",
      is_null: "Для пустых",
      isNotNull: "Не пусто",
      is_not_null: "Не пусто",
      greaterThan: "Больше, чем",
      greater_than: "Больше, чем",
      greaterThanEq: "Больше или равно",
      greater_than_eq: "Больше или равно",
      lessThan: "Меньше, чем",
      less_than: "Меньше, чем",
      lessThanEq: "Меньше или равно",
      less_than_eq: "Меньше или равно",
      between: "Между",
      notBetween: "Не между",
      not_between: "Не между",
      relative: "Равно",
      set: "Установить как",
      belongs: "Принадлежит",
      notBelongs: "Не принадлежит",
      not_belongs: "Не равно",
      today: "Сегодня",
      yesterday: "Вчера",
      the_day_before_yesterday: "Позавчера",
      tomorrow: "Завтра.",
      next_seven_day: "Семь дней в ближайшие дни",
      last_seven_day: "Последние семь дней",
      this_week: "На этой неделе",
      next_week: "На следующей неделе",
      last_week: "На прошлой неделе",
      this_month: "В этом месяце",
      next_month: "В следующем месяце",
      last_month: "В прошлом месяце",
      this_year: "В этом году",
      next_year: "В следующем году",
      last_year: "В прошлом году",
      exact_match: "Точное соответствие",
      add_value: "Новая добавленная стоимость",
      remove_value: "Удалить значение",
      set_null: "Опустите",
      isAny: "Произвольно равно",
      notAny: "Произвольно не равно",
      belongTo: "Принадлежит",
      notBelongTo: "Не принадлежит",
      hasAny: "Содержит произвольные",
      notHasAny: "Не содержит произвольного",
      prefixContains: "Начало равно",
      prefixNotContains: "Начало не равно",
      suffixContains: "Конец равен",
      suffixNotContains: "Конец не равен",
      nextSevenDay: "Следующие 7 дней",
      lastSevenDay: "Последние 7 дней",
      thisWeek: "На этой неделе",
      nextWeek: "На следующей неделе",
      lastWeek: "На прошлой неделе",
      thisMonth: "В этом месяце",
      nextMonth: "В следующем месяце",
      lastMonth: "В прошлом месяце",
      thisYear: "В этом году",
      nextYear: "В следующем году",
      lastYear: "В прошлом году",
      after: "После (включили день)",
      before: "До (включили день)",
      allMatching: "Все совпадают",
      allNotMatching: "Все не совпадают",
      hit: "Хит",
      not_hit: "Удар",
    },
    fieldsOperators: {
      is: "Равно",
      is_null: "В то же время пустой",
      head_equal: "Начало равно",
      tail_equal: "Конец равен",
      is_not_null: "При этом не пуст",
      not: "Не равно",
      diff_equal: "Разница равна",
      diff_greater_than: "Разница больше, чем",
      diff_greater_than_eq: "Разница больше или равна",
      diff_less_than: "Разница меньше, чем",
      diff_less_than_eq: "Разница меньше или равна",
      hour_equal: "В тот же час",
      day_equal: "В тот же день",
      week_equal: "В ту же неделю",
      month_equal: "В том же месяце",
      year_equal: "В том же году",
      day: "Боже!",
      minute: "Минута.",
      hour: "Час",
      part_equal: "Часть равна",
    },
    samplingColumnCaptions: {
      created_at: "Время разговора",
      agent_id: "Обслуживание клиентов",
      get_direction: "Тип вызова",
      call_result: "Результат звонка",
      survey: "Оценка",
      derived_from_id: "Источник",
      quit_queue_why: "Статус очереди",
      queuing_duration: "Выстраивка занимает много времени",
      talking_seconds: "Продолжительность звонка",
      ringing_seconds: "Время звонка",
      hangup_by: "Вызов",
    },
    callTypes: {
      callIn: "Входящий",
      callOut: "Выдохнуть",
      threeParty: "(Три стороны)",
      consultation: "(Консультация)",
      insert: "(Сильная вставка)",
      monitor: "(Наблюдение)",
      transfer: "(Перенос)",
      intercept: "(Перехват)",
      transferOutside: "(Переню внешнюю линию)",
      threePartyOutside: "(Трехсторонняя внешняя линия)",
      consultingOutside: "(Консультации по внешней линии)",
    },
    callResults: {
      staffAnswer: "Ответы на обслуживание клиентов",
      staffNotAnswer: "Обслуживание клиентов не отвечает",
      customerAnswer: "Ответы клиентов",
      customerNotAnswer: "Клиент не ответил",
      phoneBusy: "Телефон занят",
      phoneOffline: "Телефон в автономном режиме",
      customerSpeedHangUp: "Клиент быстро повесить",
      customerHangUp: "Клиент повесил",
      queueTimeout: "Сентиал в очереди",
      queueGiveUp: "Отказаться от очередей",
      outlineAnswer: "Вне ответа",
      outlineNotAnswer: "Внешняя линия не подключена",
      noChooseQueue: "Очередь не выбрана",
    },
    defaultSurveys: {
      noEvaluation: "Оценка не была",
      noNeedToEvaluate: "Нет необходимости в оценке",
    },
    queueStates: {
      queueSuccess: "Успешная очередь",
      queueTimeout: "Сентиал в очереди",
      queueGiveUp: "Отказаться от очередей",
      noStaffOnline: "Нет обслуживания клиентов онлайн",
    },
    callHangers: {
      customer: "Клиенты",
      staff: "Обслуживание клиентов",
      outline: "Внешние линии",
    },
    workFlowTypes: {
      noAppealsMode: "Нет модели апелляции",
      appealsMode: "Разрешенные модели жалоб",
    },
    fieldCategories: {
      standardCondition: "Стандартные условия отбора",
      customerCondition: "Пользовательские условия фильтрации",
    },
    sampleTypes: {
      automatic: "Автоматическая выборка",
      manual: "Ручная выборка",
    },
    defaultFields: {
      createdAt: "Время разговора",
      agentId: "Обслуживание клиентов",
      getDirection: "Тип вызова",
      callResult: "Результат звонка",
      survey: "Оценка",
      derivedFrom: "Источник",
      quitQueueWhy: "Статус очереди",
      queuingDuration: "Выстраивка занимает много времени",
      talkingSeconds: "Продолжительность звонка",
      ringingSeconds: "Время звонка",
      hangupBy: "Вызов",
    },
    conditionFieldCategories: {
      fixedField: "Фиксированное поле",
      customField: "Пользовательские поля",
      secondLevelScore: "Вторичная классификация",
      formItemScore: "Элемент оценки",
      forbids: "Запрет",
      deadlies: "Смертельный элемент",
    },
    columnFieldCategories: {
      agent: "Обслуживание клиентов",
      callLog: "Запись звонков",
      businessFields: "Бизнес-информация",
      customer: "Клиенты",
      customerCustomFields: "Настройка клиента",
      source: "Источник",
      queue: "Очередь",
      fixed: "Фиксированный",
      secondLevelScore: "Вторичная классификация",
      formItemScore: "Элемент оценки",
      forbids: "Запрет",
      deadlies: "Смертельный элемент",
    },
    taskListDefaultFields: {
      startTime: "Время разговора",
      name: "Обслуживание клиентов",
      state: "Состояние",
      totalScore: "Общая оценка",
      formItemForbidList: "Запрет",
      formItemDeadlyList: "Смертельный элемент",
      comment: "Замечания по оценке",
      inspector: "Рейтинг людей",
      review: "Рецензиер",
    },
    evaluationStates: {
      initial: "Инициализация",
      waitEvaluate: "Для оценки",
      evaluated: "В ожидании представления",
      waitConfirm: "Подтвердить",
      waitCheck: "В ожидании рассмотрения",
      complete: "Завершено",
    },
    workBenchInfoTypes: {
      callInfo: "Информация о вызове",
      businessInfo: "Бизнес-информация",
      customerInfo: "Информация о клиенте",
    },
    evaluationEventStates: {
      create: "Создание",
      valuate: "Оценка",
      edit: "Редактирование",
      submit: "Представление",
      confirm: "Подтверждение",
      appeal: "Жалоба",
      checkConfirm: "Подтверждение проверки",
      check: "Обзор",
    },
    formItemTypes: {
      direct: "Прямой рейтинг",
      weight: "Вес",
      deduct: "Вычет баллов",
      forbid: "Запрет",
      deadly: "Смертельный элемент",
    },
    appealCheckCallTypes: {
      callIn: "Входящий",
      callBack: "Двусторонний обратный звонок",
      callOut: "Прямой вызов",
      autoCall: "Автоматический вызов",
    },
    appealCheckCallStatus: {
      waitReview: "В ожидании рассмотрения",
      reviewed: "Это было пересмотрено.",
      filed: "Архивировано.",
    },
    samplingStatus: {
      all: "Все.",
      unchecked: "Извлечение не проверено",
      checked: "Выборочная проверка",
    },
    inspectionStatus: {
      all: "Все.",
      unread: "Непрочитанный",
      readed: "Прочитано",
      appeal: "Жалоба",
      review: "Обзор",
      filed: "Архивировано.",
    },
    gradeChooseHits: { yes: "Да, да.", no: "Нет" },
    appealCheckStatus: {
      all: "Все состояние",
      unread: "Непрочитанный",
      inComplaint: "В жалобе",
      reviewed: "Это было пересмотрено.",
      readed: "Прочитано",
      filed: "Архивировано.",
      spotchecked: "Выборочная проверка",
    },
    flowStatus: {
      unread: "Непрочитанный",
      alreadyRead: "Прочитано",
      complaining: "В жалобе",
      reviewed: "Это было пересмотрено.",
      sampling: "Извлечение не проверено",
      samplingCompleted: "Выборочная проверка",
      complainCancel: "Отменено",
    },
    complainStatus: {
      success: "Успех",
      fail: "Провал",
      default: "В жалобе",
      canceled: "Отменено",
    },
    reviewInspectionStatus: {
      inComplaint: "В жалобе",
      reviewed: "Это было пересмотрено.",
    },
    gradeChangeTypes: {
      edit: "Редактирование",
      check: "Обзор",
      file: "Архив",
      remark: "Примечания",
      complain: "Жалоба",
      review: "Обзор",
      reviewEvaluation: "Обзор оценки",
      sample: "Выборочная проверка",
      sampleEvaluation: "Оценка выборочной проверки",
      sampleSubmit: "Представление выборочной проверки",
      sampleAssign: "Распределение выборочных проверок",
      reviewAssign: "Распределение обзора",
      read: "Прочитано",
      reviewConfirm: "Подтверждение проверки",
      sampleConfirm: "Подтверждение выборочной проверки",
      caseBase: "Добавить в группу случаев",
      sampleCancel: "Отмена распределения выборочной проверки",
      reviewCancel: "Расснение отмены распределения",
      sampleAgain: "Перераспределение выборочных проверок",
      reviewAgain: "Перераспределение обзора",
      btachDelete: "Удаление записей выборочной проверки",
      rapidSampling: "Быстрый отбор проб",
      reInspection: "Повторная проверка качества",
      smartTagsChange: "Интеллектуальные изменения этикетки",
      cancelAppeal: "Отмена жалобы",
    },
    appealTypes: {
      transliterationError: "Ошибка транслитерация",
      discriminationError: "Дискриминантная ошибка",
      otherError: "Другие ошибки",
    },
    inspectDataSources: {
      voiceCall: "Голосовой вызов",
      textDialogue: "Текстовый диалог",
      realTimeVoiceCall: "Голосовой вызов в реальном времени",
      realTimeTextDialogue: "Текстовые разговоры в реальном времени",
      wechatDialogue: "Корпоративный микро-разговор",
      taobao: "Текстовый диалог по электронной коммерции",
      ticket: "Наработки",
      wechatRadio: "Корпоративный микро-голос",
    },
    inspectTaskType: {
      all: "Все.",
      common: "Обычная проверка качества",
      relate: "Связанный контроль качества",
    },
    inspectApproveType: {
      all: "Все.",
      resolve: "Проверено",
      resolving: "На рассмотрении",
      reject: "Проверка не прошла",
    },
    dataSourceFlags: {
      all: "Без ограничений",
      voiceCall: "Звонки",
      textDialogue: "Диалог",
    },
    smartWordsLibrary: { effective: "Эффективно", invalid: "Недействительно" },
    activeFlags: { effiective: "Эффективно", invalid: "Недействительно" },
    labelTypes: {
      dimensionLabel: "Этикетки",
      classificationLabel: "Категорические метки",
    },
    pointTypes: {
      automatic: "Автоматическая оценка",
      manual: "Оценка вручную",
      interactiveDetection: "Обнаружение взаимодействия",
      smartRules: "Интеллектуальные правила",
      machineLearning: "Модель самообучения",
      intelligentModel: "Интеллектуальная модель",
    },
    pointGradeTypes: {
      radio: "Одиночный выбор",
      input: "Ввод",
      select: "Выбор",
      all: "Удовлетворить все",
      any: "Удовлетворить произвольное",
      customize: "Пользовательские",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Соответствие ключевых слов",
      question: "Вопросительное предложение",
      regular: "Регулярные выражения",
      context: "Повторение контекста",
      semantics: "Семантическое сопоставление",
      word: "Количество похожих предложений",
      dialogue: "Интервал диалога",
      speed: "Обнаружение скорости речи",
      grabDetection: "Обнаружение перехвата",
      semanticTags: "Семантические метки",
    },
    applyRoles: {
      all: "Все.",
      customer: "Клиенты",
      agent: "Обслуживание клиентов",
      robot: "Робот",
    },
    saleApplyRoles: { all: "Все.", customer: "Клиенты", agent: "Сотрудники" },
    applyOperatorScopes: {
      all: "Полный текст",
      preCondition: "Предварительные условия",
      scope: "Указанный диапазон",
    },
    keywordScopes: {
      all: "Обнаружение всех ключевых слов",
      any: "Обнаружение произвольных ключевых слов",
    },
    preOperatorHitTypes: {
      first: "Первый матч",
      every: "Каждое совпадение",
      last: "Последний матч",
      any: "Любое совпадение",
      none: "Не совпадают",
    },
    operatorRuleTypes: {
      all: "Удовлетворить все",
      any: "Удовлетворить произвольное",
      custom: "Пользовательская логика",
    },
    informationEntityTypes: {
      system: "Системы",
      custom: "Пользовательские",
      moduleCompany: "Модуль",
    },
    organizationTypes: { null: "Нет.", moduleCompany: "Модуль" },
    customType: {
      smartTag: "Извлечение сущности",
      scriptTimes: "Речевой учет",
      dataCategory: "Классификация данных",
    },
    interactiveQuestionTypes: {
      standard: "Стандартные вопросы и ответы",
      multiElement: "Многофакторные вопросы и ответы",
      guideMultiRound: "Многоколесное взаимодействие",
    },
    targetTypes: {
      all: "Все.",
      customerService: "Обслуживание клиентов",
      customer: "Клиенты",
    },
    interactiveTypes: {
      similarQList: "Подобные проблемы",
      exceptSimilarQList: "Исключение подобных проблем",
      similarAList: "Похожие ответы",
      wrongAList: "Неправильный ответ",
    },
    filterTypes: {
      call: "Запись звонков",
      dialogue: "Текст диалога",
      wechat: "Корпоративное WeChat",
      wechatAll: "Корпоративный WeChat все разговоры",
      ticket: "Разговоры рабочих заказов",
      taobao: "Сеанс электронной коммерции",
      wechatRadio: "Корпоративный микро-голос",
    },
    fieldTypes: {
      keyword: "Ключевые слова",
      agent: "Обслуживание клиентов",
      agentGroup: "Группа обслуживания клиентов",
      sigleListbox: "Одиночный выбор",
      multiListbox: "Мульти-выбор",
      text: "Однострочный текст",
      textArea: "Несколько строк текста",
      dateTime: "Время",
      number: "Цифры",
      voice: "",
      customer: "Клиенты",
      fieldWithLink: "Поля со ссылками",
      percentage: "Процентная доля",
      intelligentClassification: "Интеллектуальная классификация",
      testSetList: "Динамические поля набора тестов",
      inspector: "Инспектор качества",
    },
    saveTypes: { save: "Сохранить" },
    knowledgeType: { oneToOne: "Один вопрос и один ответ" },
    updateTypes: { update: "Обновление", saveAs: "Сохранить как" },
    recommendItemActionTypes: {
      similar: "Похожие",
      exclude: "Исключить",
      ignore: "Игнорировать",
    },
    gradeStatus: {
      unread: "Непрочитанный",
      alreadyReview: "Это было пересмотрено.",
      alreadyFiled: "Архивировано.",
      waitReview: "В ожидании рассмотрения",
      alreadyRead: "Прочитано",
    },
    qualityCheckDetailTypes: {
      review: "Обзор",
      spotCheck: "Выборочная проверка",
    },
    sampleAndReviewStatus: {
      init: "Не извлечено",
      unread: "Непрочитанный",
      alreadyRead: "Прочитано",
      complaining: "В жалобе",
      reviewed: "Это было пересмотрено.",
      sampling: "Извлечение не проверено",
      samplingCompleted: "Выборочная проверка",
    },
    interactiveAnswerTypes: {
      noAnswer: "Ответ не обнаружен",
      answer: "Обнаружен ответ",
      wrongAnswer: "Обнаружен неправильный ответ",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Проблема не обнаружена",
      question: "Обнаруженные проблемы",
      eliminateQuestion: "Обнаруженные проблемы с устранением",
    },
    isManual: {
      yes: "Проверка качества вручную",
      no: "Не ручная проверка качества",
    },
    readStatus: { yes: "Прочитано", no: "Непрочитанный" },
    reInspectionType: {
      recalcScore: "Пересчет только баллов (включая рейтинги)",
      recheckPoint: "Повторный учет точек контроля качества и баллов",
    },
    common: {
      eCommerceReview: "Обзор электронной коммерции",
      socialMediaComments: "Комментарии социальных СМИ",
      customer: "Клиенты",
      customerService: "Обслуживание клиентов",
      robot: "Робот",
      customerInformation: "Информация о клиенте",
      workOrderFields: "Поле рабочего заказа",
      intelligentConversationAnalysis: "Интеллектуальный анализ сеанса",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Узнайте о недостатках бизнеса, прислушайтесь к мнению клиентов и улучшайте маркетинговые преобразования",
      intelligentAccompanyingPractice: "Умный спарринг",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Создайте иммерсивный спарринг-практикум AI, который имитирует реальные сценарии бизнеса, реализуйте весь голос золотой медали и быстро улучшайте навыки диалога",
      intelligentQualityInspection: "Интеллектуальная проверка качества",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Полная проверка качества, своевременная и эффективная, с точностью более 85%",
      salesEmpowerment: "Продаваемые возможности",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI реконструирует управление продажами, реализует процесс связи в цифровом, визуализирует, прозрачно управляет каждым звеном продаж, мудро понимает каждый шаг коммуникации, точную диагностику бизнес-проблем, идеальный опыт повторения продаж",
      algorithmPlatform: "Алгоритмическая платформа",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Основываясь на собственной семантике оригинального механизма сердца, ASR и NLP модели настраиваются, чтобы открыть данные бизнес-сцены и реализовать самообучение системы.",
      generalSettings: "Общие настройки",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Унифицированное управление разрешениями данных и ролями сотрудников",
      callLog: "Запись звонков",
      intelligentRules: "Интеллектуальные правила",
      businessRecords: "Бизнес-записи",
      customFilter: {
        call: "Голосовой вызов",
        text: "Текстовый диалог",
        smartTags: "Умные этикетки",
        pretreatment: "Предварительная обработка",
        smartTagGroup: "Группа умных этикеток",
        currency: "Общие данные",
        wechat: "Корпоративное WeChat",
        spotCheck: "Выборочная проверка",
        personaField: "Список клиентов",
        ticket: "Разговоры рабочих заказов",
        taobao: "Сеанс электронной коммерции",
        wechatRadio: "Корпоративный микро-голос",
        reviewList: "Обзор",
      },
      talkerRecognitionType: {
        API: "API идентифицирует роль трека",
        text: "Распознавание текста",
      },
      operatorTypes: {
        keyword: "Соответствие ключевых слов",
        question: "Вопросительное предложение",
        regular: "Регулярные выражения",
        context: "Повторение контекста",
        semantics: "Семантическое сопоставление",
        word: "Количество похожих предложений",
        dialogue: "Интервал диалога",
        speed: "Обнаружение скорости речи",
        grabDetection: "Обнаружение перехвата",
        semanticTags: "Семантические метки",
        keywordStrict: "Соответствие ключевых слов (строго)",
        link: "Ссылки поля",
        entityInfoDetection: "Обнаружение информационных объектов",
        basicInfoDetection: "Обнаружение базовой информации",
        volume: "Обнаружение громкости",
        emotion: "Анализ настроений",
        grammar: "Грамматические правила",
        sentenceLength: "Обнаружение длины предложения",
        conceptWord: "Понятие слова",
        questionsAndAnswers: "Ответ базы знаний (старый)",
        element: "Элементы вопросов и ответов",
        silent: "Отзывчивый тайм-аут",
        associate: "Данные по дороге",
        repeat: "Повторная отправка",
        timeInterval: "Длина интервала",
        typoDetection: "Обнаружение опечаток",
        silentOld: "Молчание",
        knowledge: "Ответ на знание",
        wecomRemark: "Замечания клиента",
        customerAddTime: "Время добавления клиентов",
      },
      keywordOperators: {
        all: "Все совпадают",
        allNot: "Все не совпадают",
        any: "Произвольное совпадение",
      },
      applyRoles: {
        all: "Все.",
        customer: "Клиенты",
        agent: "Обслуживание клиентов",
      },
      sentenceLength: {
        less: "Меньше, чем",
        greater: "Больше, чем",
        equal: "Равно",
        greater_or_equal: "Больше или равно",
        less_or_equal: "Меньше или равно",
      },
      dialogueApplyScopes: {
        differentRoles: "Между разными ролями",
        agent: "Обслуживание клиентов",
        customer: "Клиенты",
        agentResponse: "Ответ службы поддержки клиентов",
        customerResponse: "Ответ клиента",
        customerNoResponse: "Клиенты не отвечают",
      },
      applyConditionHitTypes: {
        first: "Первое попадание",
        every: "Каждое попадание",
        last: "Последний удар",
      },
      applyConditionScopes: {
        current: "Текущий",
        before: "Бывший",
        after: "После",
        beforeAll: "Все раньше",
        afterAll: "После этого все",
        around: "Рядом",
      },
      voiceDemoTypes: {
        singleChannel: "Одноканальная запись звонков",
        dualChannel: "Двухканальная запись звонков",
      },
      sendStatus: {
        sending: "Отправить",
        arrive: "Доставлено",
        fail: "Сбой отправки",
        off_sending: "Отправка непрочитанных в автономном режиме",
        off_arrive: "Прочитано",
        rollback: "Снятие.",
      },
      collectionMethod: {
        assignment: "Назначение непосредственно",
        entity: "Извлечение сущности",
      },
      systemTypes: {
        qa: "Интеллектуальная проверка качества",
        wfm: "Интеллектуальное ранжение",
      },
      entityOperators: {
        equals: "Равно информации",
        notEquals: "Не равно информации",
        contains: "Содержит информацию",
        notContains: "Не содержит информации",
        equalsFix: "Равно фиксированным значениям",
        notEqualsFix: "Не равно фиксированным значениям",
        containsFix: "Содержит фиксированное значение",
        notContainsFix: "Не содержит фиксированных значений",
      },
      basicInfoOperators: {
        equals: "Равно",
        notEquals: "Не равно",
        contains: "Содержит",
        notContains: "Не содержит",
        isNull: "Для пустых",
        isNotNull: "Не пусто",
      },
      volumeDetectionWays: {
        loudness: "ДБ громкости",
        rangeAbilitySelf: "Величина изменения (чем мое предложение)",
        rangeAbilityOth: "Диапазон изменения (сравнение предложения)",
        rangeAbility3: "Величина изменения (чем это предложение)",
      },
      volumeDetectionOperators: {
        is: "Равно",
        not: "Не равно",
        greaterThan: "Больше, чем",
        greaterThanEq: "Больше или равно",
        lessThan: "Меньше, чем",
        lessThanEq: "Меньше или равно",
      },
      sentimentTypes: {
        positive: "Позитивные эмоции",
        negative: "Отрицательные эмоции",
        neutral: "Нейтральные эмоции",
        thankful: "Спасибо.",
        happy: "Счастлив.",
        complaining: "Жаловаться",
        angry: "Сердиться",
        post: "Лицевая сторона",
        negat: "Отрицательный",
        neut: "Нейтральный",
      },
      emotionOperators: { is: "Соответствие", not: "Не соответствует" },
      propTypes: { system: "Системы", user: "Пользовательские" },
      valueTypes: { text: "Текст", number: "Цифры" },
    },
    tasks: {
      allowCustomGrievance:
        "Разрешить пользовательские основания для апелляции",
      businessSummary: "Бизнес-резюме",
      theDayBeforeYesterday: "Позавчера",
      assessActionTypes: { reassess: "Переоценка" },
      scoreType: {
        aiScore: "Интеллектуальная оценка",
        manualScore: "Оценка вручную",
        interactiveDetection: "Обнаружение взаимодействия",
        smartRules: "Интеллектуальные правила",
        machineLearning: "Модель самообучения",
      },
      inspectType: { inspectTrue: "Проверьте", inspectFalse: "Не проверено" },
      operatorType: {
        keyword: "Соответствие ключевых слов",
        notKeyword: "Несоответствие ключевых слов",
      },
      applyRole: {
        all: "Все.",
        customer: "Клиенты",
        agent: "Обслуживание клиентов",
      },
      applyScope: {
        all: "Все.",
        specific: "Указанное предложение",
        scope: "Указанный диапазон",
      },
      judgeStrategy: {
        all: "Все условия выполнены",
        arbitrarily: "Удовлетворение произвольных условий",
        custom: "Пользовательская логика",
      },
      checkPointStatus: {
        waitFor: "В ожидании обзора",
        notDeductScore: "Нет очков",
        deductScore: "Вычет баллов",
      },
      applyOptions: {
        hit: "Условное попадание",
        notHit: "Нет указанных условий",
      },
      gradeTypes: {
        yesOrNo: "Соответствует ли",
        input: "Ввод вручную",
        level: "Оценка по разделу",
        multipleFactors: "Несколько элементов",
      },
      gradeChooseHits: { yes: "Да, да.", no: "Нет" },
      gradeChangeTypes: {
        edit: "Редактирование",
        check: "Обзор",
        file: "Архив",
        remark: "Примечания",
        complain: "Жалоба",
        review: "Обзор",
        reviewEvaluation: "Обзор оценки",
        sample: "Выборочная проверка",
        sampleEvaluation: "Оценка выборочной проверки",
        sampleSubmit: "Представление выборочной проверки",
        sampleAssign: "Распределение выборочных проверок",
        reviewAssign: "Распределение обзора",
        read: "Прочитано",
        reviewConfirm: "Подтверждение проверки",
        sampleConfirm: "Подтверждение выборочной проверки",
      },
      gradeStatus: {
        unread: "Непрочитанный",
        alreadyReview: "Это было пересмотрено.",
        alreadyFild: "Архивировано.",
        waitReview: "В ожидании рассмотрения",
        alreadyRead: "Прочитано",
      },
      samplingFrequencyTypes: {
        everyDay: "Ежедневно",
        everyWeek: "Еженедельно",
        everyMonth: "В месяц",
      },
      samplingEveryDayTimeTypes: { yesterday: "Вчера", today: "Сегодня" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "На этой неделе",
        lastWeek: "На прошлой неделе",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "В этом месяце",
        lastMonth: "В прошлом месяце",
      },
      samplingTypes: {
        random: "Случайная выборка",
        proportional: "Пропорциональная выборка",
      },
      weekdays: {
        Monday: "Понедельник",
        Tuesday: "Вторник",
        Wednesday: "Среда",
        Thursday: "Четверг",
        Friday: "Пятница",
        Saturday: "Суббота",
        Sunday: "Воскресенье",
      },
      distributeTypes: {
        rule: "Правило Приоритетное распределение",
        average: "Среднее случайное распределение",
      },
      notMatchAnyRuleTypes: {
        random: "Случайное среднее распределение",
        assign: "Назначен назначенному персоналу",
        ignore: "Не назначается",
      },
      inspectorTypes: {
        all: "Все инспекторы качества",
        some: "Назначение инспектора качества",
      },
      pushFrequencyTypes: {
        everyDay: "Ежедневно",
        everyWeek: "Еженедельно",
        everyMonth: "В месяц",
        inspectionUpdate: "Обновление контроля качества",
        timelyInspection: "Проверка качества в реальном времени",
      },
      pushObjectTypes: {
        email: "Указанный почтовый ящик",
        staff: "Аффилированные службы поддержки клиентов",
      },
      reviewOptions: {
        reviewResult:
          "Результаты проверки качества отображаются во время обзора",
        reviewRecord: "Отображение связанных записей при проверке",
        reviewAgentHidden:
          "Скрыть информацию по обслуживанию клиентов при проверке",
        samplingResult: "Результаты проверки качества при выборочной проверке",
        samplingRecord:
          "Отображение соответствующих записей при выборочной проверке",
        sampleAgentHidden:
          "Скрыть информацию по обслуживанию клиентов при выборочной проверке",
        checkExportAddCallInfoIphone:
          "Прикрепите историю звонков при экспорте списка",
        sampleExportAddCallInfoIphone:
          "Прикрепите историю звонков при экспорте списка",
        checkExportAddCallInfoText:
          "Прикрепите запись разговора при экспорте списка",
        sampleExportAddCallInfoText:
          "Прикрепите запись разговора при экспорте списка",
        inspectionExportAddCallInfoIphone:
          "Прикрепите историю вызовов при экспорте списка (до 10000 записей за раз)",
        inspectionExportAddCallInfoText:
          "Прикрепите запись разговора при экспорте списка (до 10000 записей за раз)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Разрешить быстрое выполнение выборочных проверок в деталях контроля качества",
        fastSampleInSampleDetail:
          "Разрешить быстрое выполнение выборочной проверки в деталях выборки",
        fastSampleInReviewDetail:
          "Разрешить быстрое выполнение выборочных проверок в деталях обзора",
      },
      permitCustomReasonList: {
        permitCustomReason:
          "Разрешить пользовательские основания для подачи заявления",
      },
      autoMateTasks: {
        autoLoad: "Автоматическое добавление",
        sample: "Автоматическая выборочная проверка",
        assign: "Автоматическое распределение",
        pushes: "Автоматический толчок",
        assess: "Автоматическая оценка",
        check: "Повторная жалоба",
        case: "Синхронизация библиотеки дел",
      },
      qualityCheckDetailTypes: {
        review: "Обзор",
        spotCheck: "Выборочная проверка",
      },
      appealTypes: {
        transliterationError: "Ошибка транслитерация",
        discriminationError: "Дискриминантная ошибка",
        otherError: "Другие ошибки",
      },
      inspectDataSources: {
        voiceCall: "Голосовой вызов",
        textDialogue: "Текстовый диалог",
        realTimeVoiceCall: "Голосовой вызов в реальном времени",
        realTimeTextDialogue: "Текстовые разговоры в реальном времени",
      },
      pointTypes: {
        automatic: "Автоматическая оценка",
        manual: "Оценка вручную",
        interactiveDetection: "Обнаружение взаимодействия",
      },
      pointGradeTypes: {
        radio: "Одиночный выбор",
        input: "Ввод",
        select: "Выбор",
      },
      sampleAndReviewStatus: {
        init: "Не извлечено",
        unread: "Непрочитанный",
        alreadyRead: "Прочитано",
        complaining: "В жалобе",
        reviewed: "Это было пересмотрено.",
        sampling: "Извлечение не проверено",
        samplingCompleted: "Выборочная проверка",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Игнорирование избыточного переполнения",
        automaticWeightCalculation: "Автоматический учет весов",
      },
      calculateScoreTypes: {
        add: "Дополнительные очки",
        sub: "Снижение баллов",
      },
      naChecked: { check: "Это не относится к разрешению обнаружения" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Пропорционально",
        allRight: "Все правильно дают очки",
        anyRight: "Произвольно правильно давать очки",
      },
      noHitQuestionResults: {
        true: 'Результат "да"',
        false: 'Результат "нет"',
      },
      repetRules: {
        same: "Точно такой же контент",
        similar: "Семантические сходства",
      },
      relateConditions: {
        highlightQuestion: "Проблемы с подсветкой",
        highlightAnswer: "Яркий ответ",
        hightlightFoctors: "Элементы подсветки",
      },
      interactiveQuestionTypes: {
        question: "Обнаруженные проблемы",
        eliminateQuestion: "Обнаруженные проблемы с устранением",
      },
      interactiveAnswerTypes: {
        noAnswer: "Ответ не обнаружен",
        answer: "Обнаружен ответ",
        wrongAnswer: "Обнаружен неправильный ответ",
      },
      inspectionUpdateTypes: {
        autoInspection: "Автоматическая проверка качества",
        autoInspectionWechat: "Автоматический анализ",
        artificialSampling: "Ручная выборочная проверка",
        artificialReview: "Ручной обзор",
        manualModification: "Быстрый отбор проб",
        timelyInspection: "Проверка качества в реальном времени",
        samplingAssign: "Распределение выборочных проверок",
        artificialComplain: "Представление жалобы",
        fastSampling: "Быстрый отбор проб",
        recheckInspection: "Повторная проверка качества",
        recheckInspectionWechat: "Повторный анализ",
      },
      timelyInspectionTypes: {
        timelyInspection: "Проверка качества в реальном времени",
      },
      pushActionTypes: { email: "Почта", interfacePush: "Интерфейс push" },
      pushDetailWays: {
        riskAlert: "Напоминание о риске",
        subscribe: "Подписаться на сообщения",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Сообщения о вызове/разговоре",
        inspectionResults: "Результаты проверки качества",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Сообщения о вызове/разговоре",
        textContent: "Текстовое содержание звонков/разговоров",
        inspectionResults: "Результаты проверки качества",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Средняя оценка в группе",
        addOrSubtract: "Прямое сложение или вычитание баллов",
        noChange: "Без добавления или вычитания баллов",
      },
      defaultScore: {
        empty: "Для пустых",
        add: "Дополнительные очки",
        notAdd: "Без дополнительных баллов",
        sub: "Снижение баллов",
        notSub: "Без снижения очков",
        na: "N/A",
        input: "Ввод вручную",
        choose: "Выбор",
      },
      strategyTypes: {
        hitAdd: "Хит плюс",
        hitNotAdd: "Хит без дополнительных очков",
        hitSub: "Удар минус",
        hitNotSub: "Хит без потери очков",
      },
    },
    download: {
      exportStatus: {
        prepare: "В подготовке",
        process: "В процессе",
        faild: "Сбой экспорта",
        success: "Успех",
      },
    },
    messageType: {
      all: "Все.",
      monitor: "Мониторинг в реальном времени",
      trigger: "Запуск уведомления",
      timing: "Сроки отчетности",
    },
    messagePushType: {
      all: "Все.",
      system: "Системные сообщения",
      sdk: "SDK для обмена сообщениями",
      push: "Интерфейс push",
      email: "Почта",
      udesk: "Уведомление udesk",
      udeskWhite: "Уведомление о системе обслуживания клиентов",
      weChat: "Корпоративные микро-новости",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Назначить инспекторов по выборке",
      sampleInspector: "Инспектор по выборке",
      assignReviewer: "Назначенный ревизор",
      reviewer: "Рецензиер",
      taskInspector: "Инспектор по качеству заданий",
    },
    sdkPushType: {
      webHook: "Стандартный webhook",
      dingDing: "Боты-роботы",
      weChat: "Корпоративные роботы WeChat",
      customer: "Пользовательский контент Json",
    },
    inspectionRemarksType: {
      inspection: "Проверка качества",
      check: "Выборочная проверка",
      review: "Обзор",
    },
    segmentationStrategyType: {
      count: "Количество сообщений",
      spacing: "Интервал сообщений",
    },
    segmentationStrategy: {
      byTime: "Сроки",
      customize: "Комбинированная нарезка",
    },
    inspectType: {
      commonInspect: "Обычная проверка качества",
      associateInspect: "Связанный контроль качества",
    },
    typoDetectionOperators: { less: "Меньше, чем", greater: "Больше, чем" },
    autoAddTypes: {
      realTimeAdd: "Добавление в режиме реального времени",
      timingAdd: "Добавление времени",
    },
    messageTypes: { text: "Текстовое сообщение", markdown: "Новости Markdown" },
    inServiceStatus: {
      activated: "Активирован",
      disabled: "Отключен",
      inactivated: "Не активирован",
      resigned: "Выход из бизнеса",
    },
    roles: { agent: "Сотрудники", customer: "Клиенты" },
    commentVisibleAuthority: {
      all: "Это видно всем.",
      self: "Виден только сам",
      designation: "Видимый указанное лицо",
    },
    appealCheckingTask: {
      eCommerceReview: "Обзор электронной коммерции",
      socialMediaComments: "Комментарии социальных СМИ",
      largeModel: "Большая модель",
      customModel: "Пользовательские модели",
      universalModel: "Универсальная модель",
      enterpriseWeChatText: "Микротекст",
      generalQualityInspection: "Обычная проверка качества",
      processQualityInspection: "Проверка качества процесса",
      voiceCopy: "Голосовой реплет",
      beEqualTo: "Равно",
      notEqualTo: "Не равно",
      canAppeal: "Можно подать апелляцию",
      noAppeal: "Не жаловаться",
    },
    wechat: {
      redEnvelopes: "Красный конверт",
      text: "Текст",
      picture: "Картинки",
      voice: "Голос",
      voiceCall: "Голосовой вызов",
      video: "Видео",
      businessCard: "Визитные карточки",
      position: "Расположение",
      expression: "Выражение",
      link: "Ссылки",
      applet: "Малая программа",
      chatRecord: "Запись чата",
      mixedMessage: "Смешанные сообщения",
      file: "Документы",
    },
    gong: {
      sessionActivity: "Сеансовая деятельность",
      conversationAction: "Сеансовые действия",
      riskSemantics: "Семантика риска",
      daysNotFollowedUp: "Количество дней без наблюдения",
      waysideData: "Данные по дороге",
      notStarted: "Не начал",
      completed: "Завершено",
      haveInHand: "В процессе",
      unpublished: "Не опубликовано",
      published: "Опубликовано",
      knowledgePoints: "Точки знаний",
      technologicalProcess: "Процесс",
      notMarked: "Не отмечен",
      inAnnotation: "В маркировке",
      annotated: "Помечено",
    },
    qualityChecingTask: {
      appealCanceled: "Отмена жалобы",
      complaintExpired: "Жалоба истекла",
      incomingCall: "Входящий",
      exhale: "Выдохнуть",
      incomingCallthirdParty: "Входящий (трехсторонний)",
      incomingCallconsultation: "Входящий (консультация)",
      incomingCallforcedInsertion: "Входящий (сильный вставка)",
      incomingCallmonitoring: "Входящий (мониторинг)",
      incomingCalltransfer: "Входящий (передача)",
      incomingCallinterception: "Входящий (перехват)",
      incomingCalltransferToExternalLine: "Входящий (передача внешней линии)",
      incomingCallthirdPartyExternalLine: "Входящий (трехсторонний внешний)",
      incomingCallexternalLine: "Входящий (консультационный внешний)",
      outgoingCallthirdParty: "Выдохни (три стороны)",
      outgoingCalltransfer: "Выдох (передача)",
      outgoingCallforcedInsertion: "Выдохни (сильная вставка)",
      outgoingCallmonitoring: "Выдох (мониторинг)",
      outgoingCallinterception: "Выдохните (перехват)",
      outgoingCalltransferToExternalLine: "Выдох (передача внешней линии)",
      outgoingCallthreePartyExternalLine:
        "Выдохни (трехсторонняя внешняя линия)",
      customerServiceAnswer: "Ответы на обслуживание клиентов",
      customerServiceMissed: "Обслуживание клиентов не отвечает",
      customerAnswer: "Ответы клиентов",
      customerMissed: "Клиент не ответил",
      theTelephoneIsBusy: "Телефон занят",
      phoneOffline: "Телефон в автономном режиме",
      customerQuickHanging: "Клиент быстро повесить",
      clientHangsUp: "Клиент повесил",
      queueTimeout: "Сентиал в очереди",
      giveUpQueuing: "Отказаться от очередей",
      outsideLineAnswering: "Вне ответа",
      externalLineIsNotConnected: "Внешняя линия не подключена",
      noQueueSelected: "Очередь не выбрана",
      notEvaluated: "Оценка не была",
      noEvaluationRequired: "Нет необходимости в оценке",
      queuingSucceeded: "Успешная очередь",
      noCustomerServiceOnline: "Нет обслуживания клиентов онлайн",
      customer: "Клиенты",
      customerService: "Обслуживание клиентов",
      outsideLines: "Внешние линии",
    },
    sessionActivity: {
      single: "Среднее время разговора за один раз",
      all: "Общий объем звонков",
      allTime: "Общая продолжительность звонка",
    },
    sessionAction: {
      salesProportion: "Доля продаж",
      salesLongest: "Средняя самая длинная распродажа",
      customerLongest: "Средний самый длинный клиент говорит",
      discussNumber: "Количество углубленных обсуждений",
      frequency: "Частота вопросов",
    },
    monitorCriteria: { all: "До и после", before: "Бывший", after: "После" },
    monitorTimeCycle: { day: "Боже!", week: "Неделя", month: "Месяц" },
    monitorGroupTestType: {
      ab: "Сравнение двух групп",
      a: "Группа А",
      b: "Группа B",
    },
    fields: {
      department: "Секторы",
      customerTags: "Теги клиентов",
      member: "Члены",
      time: "Время",
      cascade: "Каскад",
      anyMatch: "Произвольное совпадение",
      keyWord: "Ключевые слова",
      keyEvents: "Ключевые события",
    },
    semanticIntelligence: {
      staff: "Сотрудники",
      customer: "Клиенты",
      employeesAndCustomers: "Сотрудники и клиенты",
      notStarted: "Не начал",
      haveInHand: "В процессе",
      completed: "Завершено",
      callRecording: "Запись звонков",
      enterpriseWeChatCall: "Enterprise WeChat",
      enterpriseWeChatText: "Микротекст",
      discourseMiningForObjectionHandling: "Обработка возражений",
      productSellingPointScriptsMining: "Точка продажи продукта",
      conversationScenario: "Сцена сеанса",
      topicOfConversation: "Тема",
      keyEvents: "Ключевые события",
      customerLabel: "Теги клиентов",
      includeAny: "Содержит произвольные",
      includeAll: "Содержит все",
      and: "И",
      or: "Или",
      question: "Вопросительное предложение",
      nonInterrogativeSentence: "Не вопросительное предложение",
    },
    coach: {
      notPassed: "Не прошел",
      analogTelephone: "Аналоговый телефон",
      enterScenarioInformation: "Ввод информации о сценарии",
      generateDialogueFramework: "Генерируемые рамки диалога",
      generatePromptLanguage: "Генерируемые подсказки",
      aIGeneratedDialogue: "Генерируемые диалоги AI",
      system: "Системы",
      custom: "Пользовательские",
      flow: "Процесс",
      script: "Слова",
      express: "Выражение",
      emotion: "Эмоции",
      rateAccordingToCourseConfiguration: "Оценка по конфигурации курса",
      intelligentModelRating: "Оценка смарт-модели",
      allData: "Все данные",
      dataFromThisDepartment: "Данные отдела",
      multidepartmentalData: "Множекторальные данные",
      underReview: "На рассмотрении",
      no: "Нет",
      yes: "Да, да.",
      automaticRating: "Автоматическая оценка",
      auditRating: "Оценка аудита",
      reRating: "Повторная оценка",
      inAppeal: "В жалобе",
      reviewed: "Это было пересмотрено.",
      notViewed: "Не просмотрено",
      viewed: "Просмотрено",
      uncorrected: "Исправлено не было",
      corrected: "Исправлено",
      practice: "Упражнения",
      examination: "Экзамен",
      other: "Другие",
      notMeetingStandards: "Несоответствие",
      meetingStandards: "Стандарт",
      excellent: "Отлично.",
      invalid: "Сбой.",
      notStarted: "Не начал",
      haveInHand: "В процессе",
      ended: "Заканчено.",
      completed: "Завершено",
      hangInTheAir: "Не завершено",
      passed: "Принятая",
      voiceConversation: "Голосовой диалог",
      textDialogue: "Текстовый диалог",
      slidePresentation: "Презентация слайдов",
      unpublished: "Не опубликовано",
      published: "Опубликовано",
      narration: "Повествование",
      studentSpeaking: "Студенты говорят",
      robotTalk: "Робот говорит",
      knowledgeBaseQA: "Вопросы и ответы по базе знаний",
      slide: "Слайды",
      negativeEmotions: "Эмоциональный негативный",
      emotionallyNormal: "Настроение нормальное",
      incompleteScript: "Неполная речь",
      completeScript: "Полный разговор",
      normalSpeechSpeed: "Нормальная скорость речи",
      speakTooFast: "Быстрая скорость речи",
      speakTooSlowly: "Слишком медленно говорить",
      whole: "Все.",
      singlePage: "Одна страница",
      qA: "Один вопрос и один ответ",
      situationalDialogue: "Сценарий диалога",
      misses: "Удар",
      hit: "Хит",
      sequentialQA: "Последовательные вопросы и ответы",
      randomQA: "Случайные вопросы и ответы",
      mastered: "Освоил",
      notMastered: "Не освоил",
    },
    algorithm: {
      salesEmpowerment: "Продаваемые возможности",
      enterpriseWeChatVoice: "Корпоративный голос WeChat",
      enterpriseWeChatText: "Корпоративный WeChat Текст",
      waiting: "В ожидании",
      extracting: "В экстракции",
      success: "Успех",
      fail: "Провал",
      intelligentQualityInspection: "Интеллектуальная проверка качества",
      textDialogue: "Текстовый диалог",
      voiceCall: "Голосовой вызов",
      smartTags: "Умные этикетки",
      satisfactionEvaluation: "Оценка удовлетворенности",
      score: "Рейтинг",
      grade: "Рейтинг",
      customerServiceTeam: "Группа обслуживания клиентов",
      conversationAnalysis: "Анализ сеанса",
    },
    operatorListGather: {
      businessSummary: "Бизнес-резюме",
      firstSentence: "Первое предложение",
      lastSentence: "Последнее предложение",
      allTime: "Все время",
      nonBusinessWorkingHours: "Неоперативное рабочее время",
      businessWorkingHours: "Рабочие часы",
    },
    callDialogueManage: {
      eCommerceReview: "Обзор электронной коммерции",
      socialMediaComments: "Комментарии социальных СМИ",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Войти",
        email: "Почтовый ящик",
        password: "Пароль",
        forgetPwd: "Забыли пароль",
        emailCannotBeEmpty: "Почтовый ящик не должен быть пустым.",
        passwordCannotByEmpty: "Пароль не должен быть пустым.",
        mustChangeLogin:
          "Извините, ваш пароль должен быть сброшен, пожалуйста, свяжитесь с администратором, чтобы сбросить пароль.",
        dismissionLogin: "Извините, вы уже покинули свой пост.",
        forbiddenLogin: "Извините, ваш аккаунт отключен.",
        loginError:
          "Ошибка входа в систему, ошибка почтового ящика или пароля.",
        loginPrompt: "Вход в систему",
        rememberMe: "Помни меня.",
      },
      logout: {
        logoutError:
          "Сбой выхода из системы, проверьте подключение к сети или попробуйте позже.",
      },
      resetPassword: {
        forgetPwd: "Забыли пароль",
        inputEmailPrompt:
          "Введите адрес вашей учетной записи ниже, и мы отправим вам электронное письмо и инструкции по сбросу пароля.",
        sendEmailSuccess:
          "Письмо с подтверждением изменения пароля было успешно отправлено на указанный вами почтовый ящик, пожалуйста, проверьте его вовремя.",
        sendEmailFailed:
          "Отправка почтового ящика не удалась, проверьте подключение к сети или повторите попытку позже.",
        setNewPassword: "Установите новый пароль",
        passwordCannotEmpty: "Пароль не должен быть пустым",
        passwordFormWrong: "Неправильный формат пароля",
        confirmPasswordWrong: "Пароли, введенные дважды, не равны.",
        passwordResetSuccess: "Сброс пароля прошел успешно.",
        passwordResetFailed:
          "Сброс пароля не удается, проверьте подключение к сети или попробуйте позже.",
        linkTimeover: "Сброс ссылки на пароль истек, попробуйте еще раз",
      },
      form: { title: "Форма CASE" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Почтовый ящик",
          emailFormatError:
            "Неправильный формат почтового ящика, пожалуйста, введите снова!",
          forgotPassword: "Забыли пароль?",
          describe:
            "Введите адрес вашей учетной записи ниже, и мы отправим вам электронное письмо и инструкции по сбросу пароля.",
          send: "Отправить",
          emailCannotBeEmpty: "Почтовый ящик не может быть пустым",
          resetPasswordExpire:
            "Сброс ссылки на пароль истек, попробуйте еще раз",
        },
        resetPassword: {
          setPassword: "Установите новый пароль",
          newPassword: "Новый пароль",
          repeatPassword: "Повторяющиеся пароли",
          resetPassword: "Сброс пароля",
          passwordCannotBeEmpty: "Новый пароль не может быть пустым.",
          confirmPasswordCannotBeEmpty:
            "Повторяющиеся пароли не могут быть пустыми.",
          passwordsNotMatch: "Пароли не совпадают дважды!",
          passwordFormWrong:
            "Пароли не могут быть на китайском, и их число составляет 6-14 цифр!",
          linkExpiration: "Сброс ссылки на пароль истек, попробуйте еще раз",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "У вас нет разрешения на просмотр этой страницы",
      },
    },
    downloadCenter: {
      name: "Центр загрузки",
      saveTime: "(Сохранить 30 дней по умолчанию)",
      headerTitle: "Скачать файл",
      download: "Скачать",
      downloadPromp: "Скачать задание отправлено, перейдите",
      viewProgress: "Просмотр прогресса",
    },
    uploadCenter: { name: "Центр загрузки" },
    tasks: {
      index: {
        name: "Название",
        remark: "Описание",
        qualityCheckTasksTitle: "Рабочий стол",
        informationCollection: "Задачи сбора",
        qualityCheckTasks: "Задачи контроля качества",
        wechatQaTasks: "Аналитические задачи",
        moduleName: "Новый с помощью шаблона",
        creatNewQualityCheckTask: "Новые задачи",
        generalInspectionList: "Общий отчет о проверке качества",
        editQualityCheckTask: "Редактирование задач контроля качества",
        creatNewQualityCheckTaskError:
          "Новая задача проверки качества не удалась",
        editQualityCheckTaskError:
          "Сбой редактирования задачи контроля качества",
        type: "Тип формы",
        singleScore: "Одижные баллы",
        singleScoreNullError: "Одиночная оценка не может быть пустой",
        inspector: "Инспектор качества",
        inspectDataSource: "Источник данных",
        taskName: "Название задачи",
        inspectionType: "Тип контроля качества",
      },
      manage: {
        screeningCondition: "Условия скрининга",
        template: {
          condition: {
            index: {
              name: "Условия",
              existingConditions: "Существующие условия",
            },
            detail: {
              id: "ID",
              name: "Название условия",
              remark: "Описание",
              checkRange: "Диапазон обнаружения",
              operator: "Оператор",
              operatorLogic: "Операторная логика",
            },
          },
          checkPointTest: {
            title: "Тестирование правил",
            running: "Тестирование правил, пожалуйста, позже...",
            clearSuccess: "Моделирование диалога опустошено успешно",
            mock: "Моделирование диалога",
            save: "Сохранение выбранного диалога",
            saveAll: "Сохранить все разговоры",
            saveSuccess: "Успех сохранения диалога",
            close: "Выход",
            delete: "Удалить выбранный",
            clear: "Освободить",
            customer: "Клиенты",
            agent: "Обслуживание клиентов",
            test: "Тест",
            request:
              "После того, как смоделированный диалог опустошен, он не будет восстановлен.",
            deletePopconfirmContent:
              "Подтверждаете ли вы удаление выбранного диалога?",
          },
          checkPoint: {
            index: {
              name: "Конфигурация правил",
              wechatTitle: "Анализ конфигурации модели",
              existingCheckPoints: "Уже существуют правила контроля качества",
              currentCheckPointScore: "Текущий показатель контроля качества",
              currentCheckPointScoreWechat: "Текущий анализ модели оценки",
              currentCheckPointWeight:
                "Сумма весов текущих правил проверки качества равна",
              weightTotalScoreAndItemScore: "Общая оценка: 100 баллов:",
              template: "Шаблон правил контроля качества",
              import: "Импорт шаблонов правил контроля качества",
              getTemplateError:
                "Ошибка получения шаблона правил контроля качества",
              tabs: { template: "Шаблон", demo: "Пример" },
              customize: "Пустой шаблон",
              move: "Перетащите, чтобы настроить классификацию и сортировку",
              classification: "Классификация качества",
              classificationWeChat: "Классификация моделей",
              first: "Классификация первого уровня",
              second: "Вторичная классификация",
              new: "Новые правила проверки качества",
              weChatNew: "Добавление аналитической модели",
              createWeChat: "Новая аналитическая модель",
              empty: "Нет правил контроля качества,",
              wechatEmpty: "Нет аналитической модели,",
              newSecond: "Новая классификация уровня 2",
              newFirst: "Новая классификация первого уровня",
              checkPoint: "Правила контроля качества",
              checkPointWeChat: "Аналитическая модель",
            },
            detail: {
              name: "Название",
              remark: "Описание",
              type: "Тип",
              deduction: "Вычет баллов",
              rule: "Правила",
              highlighted: "Подсветка",
              gradeType: "Способ оценки",
              interactiveCategory: "Интерактивная классификация",
              predeterminedScore: "Оценка",
              weight: "Вес",
              na: "N/A",
              default: "Оценка по умолчанию",
              preRule: "Правила предварительных условий",
              analysisHighlighted: "Правила синтаксического анализа",
              categorySelectPrompt: "Выберите классификацию",
              checkHighlightArrayError:
                "Правила не совпадают с выделением, пожалуйста, повторно нажмите кнопку Разбор правил",
              foctorsHolder: "Выберите элементы",
              foctorsErrorNone:
                "Многофакторное правило пусто, пожалуйста, добавьте многофакторный тип",
              naError:
                "Невозможно закрыть пункт, когда оценка по умолчанию N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A-это Not Applicable. Установите предварительное условие для правила проверки качества. Обнаружение при соблюдении условия. Если условие не соблюдено, правило не применяется.\nПредварительные условия: «&&» означает «и», «||» означает «или», «!» Укажите "не", заполните пример "(R1 || R2) && R3"',
                applicationMessageType: "Тип сообщения приложения",
                selectAll: "Полный выбор",
                highlightTags: "Высвеченные этикетки:",
                ruleType: "Типы правил",
                dataSource: "Источник данных",
                whole: "Все.",
                enterpriseMicroConversation: "Корпоративный микро-разговор",
                enterpriseAndMicroCommunication: "Enterprise WeChat",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Узел оценки",
                    dialogueNode: "Диалогические узлы",
                    editNodes: "Редактирование узлов",
                  },
                  nodeConfig: {
                    nodeName: "Имя узла",
                    nodeRules: "Правила узлов",
                    scoringLogic: "Логика оценки",
                    score: "Оценка:",
                    rule: "Правила",
                    multiBranchLogic: "Многоветвистая логика",
                    highlight: "Подсветка",
                    submit: "Представление",
                  },
                  ruleList: {
                    branch: "Ветвь",
                    branchName: "Название ветви:",
                    addRules: "Добавлена правило",
                  },
                  sidebar: { node: "Узлы" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Автоматическая отделка",
                      canvasAdaptation: "Адаптивный холст",
                      copy: "Копирование",
                      delete: "Удалить",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Решение о том, что дочерние узлы узла не могут быть объектом соединения",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Пуск узел не может быть целью подключения",
                      startingNodeCannotConnectMultipleNodes:
                        "Первый узел не может соединить несколько узлов",
                      nodeCannotBeConnectedToItself:
                        "Узлы не могут быть подключены к себе",
                      startNode: "Начало узла",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Отмена всех выборов",
                    selectAll: "Полный выбор",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Соответствует ли",
                    gradedEvaluation: "Оценка по разделу",
                  },
                },
              },
            },
            configuration: {
              title: "Правила контроля качества",
              wechatTitle: "Аналитическая модель",
              neme: "Название",
              errorMessage: "Сбой удаления, хотя бы одно условие",
              desc: "Описание",
              status: "Состояние",
              yes: "Эффективно",
              no: "Недействительно",
              namePlaceHolder: "Введите название метки",
              desPlaceHolder: "Введите описание",
              nameEmpty: "Имя не может быть пустым",
              desEmpty: "Описание не может быть пустым",
              type: "Тип",
              ruler: "Интеллектуальные правила",
              machineLearning: "Модель самообучения",
              autoScore: "Интеллектуальный рейтинг (старый)",
              manualScore: "Ручная оценка",
              interaction: "Обнаружение взаимодействия (старый)",
              typeTips: {
                model:
                  "Интеллектуальная модель: это набор предустановленных систем контроля качества, которые не требуют настройки и обслуживания, и могут быстро определить проблемы в службе контроля качества. Эта модель является более общей. Если она не соответствует требованиям бизнеса, используйте «умные правила» или «ручную оценку».",
                rule: "Интеллектуальные правила: то есть автоматическая оценка на основе правил AI",
                manual:
                  "Ручная оценка: ручная проверка качества, ручная оценка",
              },
              model: "Модель:",
              ruleType: {
                words: "Слова",
                interaction: "Взаимодействие",
                feature: "Особенности",
                information: "Информация",
              },
              testingMode: {
                all: "Обнаружение всех ключевых слов",
                any: "Обнаружение произвольных ключевых слов",
              },
              operatorTypesOfInteraction: {
                question: "Один вопрос и один ответ",
                element: "Элемент ответа",
                repet: "Повторение контекста",
                snatch: "Схватить слова",
                silent: "Молчание",
              },
              role: "Роль обнаружения:",
              preposition: "Предварительный оператор:",
              range: "Диапазон обнаружения:",
              from: "Первый",
              to: "Предложение до первого",
              end: "Предложение",
              hasOldSilentTip:
                "Здравствуйте, для повышения точности «Интеллектуальные правила-взаимодействие-молчание» был обновлен до «Отзывчивый тайм-аут», рекомендуется обновить правила использования!",
              changeToOld: "Переключение на старую версию",
              changeToNew: "Переключиться на новую версию",
              logic: {
                logic: "Логика оценки",
                naTip:
                  "N/A является Not Applicable, устанавливает предварительное условие для этого правила проверки качества, обнаруживает, когда оно соответствует условиям, и не применяет правило, если оно не применяется.",
                score: "Оценка:",
                na: "N/A：",
                naDes: "Это не относится к разрешению обнаружения",
                preRule: "Правила предварительных условий:",
                notCatchPreRule: "При невыполнении предварительных условий:",
                naChecked: "При ударе N/A:",
                preRuleEmpty: "Предусловные правила не могут быть пустыми",
                operatorEmpty:
                  "Необходимые условия в оператором не могут быть пустыми",
                multipleEmpty:
                  "Необходимые условия в множественной логической оценке не могут быть пустыми",
                average: "Средняя оценка в группе",
                addOrSubtract: "Прямое сложение или вычитание баллов",
                noChange: "Без добавления или вычитания баллов",
                rule: "Правила:",
                all: "Удовлетворить все операторы",
                any: "Удовлетворить произвольный оператор",
                customize: "Пользовательская операторная логика",
                multiple: "Многочисленные логические оценки",
                heightLight: "Подсветка:",
                machineLearningTip:
                  "Режим самообучения в настоящее время эффективен только для речи в одном предложении. Для сложной речи используйте интеллектуальные правила.",
                strategy: "Стратегия:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Успешная подача одобрения",
            },
            components: {
              pageHeaderExtra: {
                test: "Тест",
                formal: "Официально",
                submit: "Представление",
              },
              createModal: { index: { myRules: "Мои правила" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Автоматизация процессов",
              screeningCondition: "Условия скрининга",
              aiScreeningCondition: "Интеллектуальные условия скрининга",
              rule: "Правила",
              automaticAddition: "Автоматическое добавление",
              automaticAdditionTip:
                "Автоматическое добавление данных в задачу контроля качества AI в режиме реального времени или по времени",
              autoAdd: {
                autoAddType: "Автоматический способ добавления",
                addFrequency: "Добавить частоту",
                add: "Добавить",
              },
              automaticSamplingInspection: "Автоматическая выборочная проверка",
              automaticSamplingInspectionTip:
                "Автоматическое извлечение данных, требующих ручного контроля качества, например, ежедневное извлечение случайных 10% данных вчера для ручной выборочной проверки",
              everyAgentCheckLimit:
                "Верхний предел для каждого обслуживания клиентов",
              automaticAllocation: "Автоматическое распределение",
              automaticAllocationTip:
                "Распределение выборочных проверок: извлеченные данные автоматически распределяются среди инспекторов по качеству.",
              automaticAllocationTip2:
                "Распределение обзора: данные жалобы автоматически назначаются инспектором по качеству для проверки, например, данные ежедневной жалобы в среднем случайным образом назначаются 2 инспекторов по качеству для проверки этой задачи",
              automaticPush: "Автоматический толчок",
              automaticPushTip:
                "Уведомление триггера: уведомление/предупреждение во время проверки качества, например, отправка уведомления в корпоративную микрогруппу в случае фатальной ошибки в службе поддержки клиентов",
              automaticPushTip2:
                "Отчет о времени: результаты проверки качества отправляются по электронной почте в виде отчета о времени. Например, результаты проверки качества, проверенные вручную вчера, отправляются в почтовый ящик руководителя службы поддержки клиентов в 9:00 каждый день.",
              automaticAssess: "Автоматическая оценка",
              automaticAssessTip:
                "Результаты ручной выборки регулярно сравниваются с результатами проверки качества AI для оценки точности. Например, ежедневная оценка точности данных вчерашней выборки в 8:00",
              reexaminationComplaint: "Повторная жалоба",
              spoktReviewDetail: "Конфигурация бизнеса",
              spoktReviewDetailTip:
                "Настройка функций, таких как список контроля качества, список выборочных проверок, бизнес-апелляций, список проверок, комментарии и т. Д.",
              qaDetailAction: "Конфигурация контроля качества",
              spoktDetailAction: "Конфигурация выборочной проверки",
              reviewDetailAction: "Конфигурация обзора",
              appealAction: "Настройка апелляций",
              remarkTemplate: "Шаблон заметок",
              automaticArchiving: "Автоматическое архивирование",
              conditionalRules: "Условные правила",
              qualityInspectionConditions: "Условия проверки качества",
              unopened: "Не открыт",
              sampleInspectionDistributionModel:
                "Распределение выборочных проверок",
              reviewAllocationModel: "Распределение обзора",
              distributionModelTitle: "Стратегия распределения:",
              pushRules: "Правила толчка",
              push: "Толчок",
              assessRules: "Правила оценки",
              on: "on",
              off: "off",
              updateStatusSuccess: "Смена статуса прошла успешно!",
              updateStatusFailed:
                "Смена статуса не удалась, повторите попытку позже!",
              sampleNumberOrPercent: "Количество или соотношение выборок",
              inspectorList: "Назначение инспектора качества",
              numberOfSubmission: "Количество разрешенных представлений",
              rewivewDays: "Боже!",
              TimeOfSubmission: "Срок подачи заявления",
              rewivewSecond: "Раз",
              switchOn: "Открыть.",
              switchOff: "Закрыть",
              notMatchAnyRule: "При несоответствии произвольных правил:",
              appealOptionsList: {
                hiddenInspector:
                  "Жалоба скрывает информацию инспектора по качеству",
                hiddenHit:
                  "Страница апелляции поддерживает яркое позиционирование",
              },
              caseLibrary: "Синхронизация библиотеки дел",
              caseLibrarySyncTip:
                "Синхронизация случаев, добавленных системой QA, с системой обслуживания клиентов",
              addCase: "Увеличение синхронизации",
              caseLibraryId: "Библиотека дел",
              caseLibraryIdTip: "Группировка библиотеки дел для системы QA",
              udeskCaseLibraryIdTip:
                "Тематическая группировка для системы обслуживания клиентов",
              syncHis: "Синхронизация истории кейсов",
              otherSettings: "Другие настройки",
              udeskCaseLibraryId: "Push Case Group",
              url: "Push URL",
              urlTip:
                "Введите адрес интерфейса, начинающийся с http:// или https://",
            },
            templateNew: {
              secondaryReview: "Вторичный обзор",
              numberOfAppealsAllowedToBeSubmitted:
                "Количество разрешенных жалоб",
              validityOfSubmissionOfAppeal: "Срок подачи жалобы",
              validityOfAppealReview: "Срок действия апелляции",
              hours: "Час",
              businessSummary: "Бизнес-резюме",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Нижний предел для каждого обслуживания клиентов",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Бизнес-резюме автоматически генерируется большой моделью, а измерение генерации может быть настроено в соответствии с бизнес-ситуацией",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Только стратегия распределения рецензистов первого уровня",
              secondaryCompounding: "Вторичный комплекс:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Рецензер второго уровня поддерживает только среднее распределение",
              secondLevelReviewer: "Второй ревизион:",
              samplingInspectionTarget: "Цель выборочной проверки",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Установите цели, которые должны быть выполнены для ручной выборочной проверки, и дайте подсказку, когда цель не достигнута или не превышена. Например, каждая служба поддержки клиентов должна выполнять 100 выборочных проверок в месяц.",
            },
            component: {
              verificationFailed: "Сбой проверки",
              pleaseAddASecondLevelQualityInspector:
                "Пожалуйста, добавьте инспектора качества второго уровня",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Наброски",
                    fieldName: "Название поля",
                    fieldDescription: "Описание поля",
                    generateContent: "Создание контента",
                    enabled: "Включено ли",
                    generationMethod: "Способ генерации",
                    automaticGeneration: "Автоматическая генерация",
                    manualTrigger: "Ручной срабатывание",
                    filterCondition: "Условия скрининга",
                    return: "Возвращение",
                    preserve: "Сохранить",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Создание результатов" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Не ограничено",
                    limitedScope: "Ограниченная область",
                    add: "Добавить",
                    thereIsCurrentlyNoDataAvailable: "Нет данных",
                  },
                },
              },
              index: {
                fieldName: "Название поля",
                fieldDescription: "Описание поля",
                nothing: "Нет.",
                generationMethod: "Способ генерации",
                generateManually: "Сгенерирование вручную",
                automaticGeneration: "Автоматическая генерация",
                generateContent: "Создание контента",
                notQualified: "Не ограничено",
                filterCondition: "Условия скрининга",
                enabled: "Включено ли",
                type: "Тип",
                system: "Системы",
                custom: "Пользовательские",
                operation: "Операция",
                edit: "Редактирование",
                areYouSureToDeleteIt: "Определились с удалением?",
                delete: "Удалить",
                addField: "Увеличение поля",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Конфигурация роли",
              roleConfigTip:
                "Он используется для настройки правил идентификации для обработки разделения вызовом, когда импортированные данные вызова являются моно. Роль обслуживания клиентов, которая соответствует правилам",
              operator: "Оператор",
              operatorLogic: "Операторная логика",
            },
          },
          manualAdd: {
            index: {
              name: "Добавление вручную",
              tip: "Используется для фильтрации данных предварительного просмотра путем настройки условий вручную и их быстрого добавления к задачам контроля качества",
              screeningCondition:
                "Добавить в соответствии с условиями фильтрации",
              aiScreeningCondition: "Интеллектуальные условия скрининга",
              rule: "Правила",
              createTime: "Добавить время",
              filterTitle: "Фильтровать контент",
              filterRule: "Условия скрининга",
              startTime: "Время начала",
              endTime: "Время окончания",
              records: "Импорт записей",
              createUserName: "Операторы",
              successCount: "Добавить успех",
              conditionRule: "Условные правила:",
              preview: "Предварительный просмотр",
              previewData:
                "Статья {0} в настоящее время соответствует условиям отбора, статья {1} была добавлена, статья {2} может быть добавлена к задаче, статья {3} не может быть добавлена к задаче",
              failCount: "Добавление сбоя",
            },
          },
          basicInfo: {
            index: {
              title: "Основная информация",
              name: "Название",
              taskName: "Название задачи",
              taskNamePlaceholder: "Введите название задачи",
              requiredName: "Имя задачи не может быть пустым",
              taskRemarkPlaceholder: "Введите описание задачи",
              requiredRemark: "Описание задачи не может быть пустым",
              remark: "Описание",
              taskRemark: "Описание задачи",
              source: "Источник данных",
              benchmarkScore: "Базовый балл",
              benchmarkScorePlaceholder: "Введите контрольный балл",
              scoreLowerLimit: "Нижний предел минимального балла",
              scoreUpperLimit: "Максимальное ограничение баллов",
              inspectType: "Тип контроля качества",
              nlpModel: "Модель NLP",
              commonInspect: "Обычная проверка качества",
              associateInspect: "Связанный контроль качества",
              associateCycle: "Связывающий цикл",
              associateRegulation: "Правила ассоциации",
              judgeStrategy: "Логика выполнения",
              rule: "Правила",
              commonTip:
                "Обычная проверка качества: подходит для однократных проходов и диалогов.",
              associateTip:
                "Связанное качество проверки качества: подходит для нескольких контактов и диалогов, связывает содержание нескольких сообщений для проверки качества",
              cycleTip:
                "Если несколько связанных каналов и диалогов соответствуют данным в течение цикла ассоциации, чтобы войти в контроль качества задачи",
              scoreLowerLimitPlaceholder:
                "Введите нижний предел минимального балла",
              scoreUpperLimitPlaceholder:
                "Введите верхний предел максимального балла",
              call: "Голосовой вызов",
              dialogue: "Текстовый диалог",
              user: "Инспектор качества",
              wechatUser: "Оператор",
              noDesignated: "Не указано",
              type: "Тип формы",
              singleScore: "Одижные баллы",
              conduct:
                "Пожалуйста, сначала улучшите следующую информацию, пожалуйста, нажмите Далее после завершения",
              conduct2:
                "Пожалуйста, завершите настройку правил контроля качества и точек контроля качества. Если вы завершили настройку, вы можете нажать Далее или выйти из руководства",
              conduct3:
                "Рейтинг контроля качества-это конфигурация рейтинга, основанная на точках контроля качества и их оценке. Вы можете определить различные уровни результатов контроля качества. Если у вас нет требований к рейтингу, вы можете пропустить этот шаг напрямую. Если вы завершили настройку, вы можете нажать Далее или выйти из руководства.",
              conduct4:
                "Вы можете настроить характеристики данных, которые будут автоматически введены в задачу в соответствии с характеристиками данных, которые должны быть проверены в этой задаче контроля качества в месте автоматического добавления. Если вам не нужно автоматически добавлять данные, вы можете нажать, чтобы пропустить. Если вы завершили настройку, вы можете нажать Далее",
              conduct5:
                "Если вы используете моно-запись, пожалуйста, не забудьте настроить этот и нажмите Далее, пожалуйста, используйте только службу поддержки клиентов, чтобы использовать методы, чтобы помочь системе определить различные дорожки обслуживания клиентов и клиентов.",
              conduct6:
                "Вы можете добавить функцию вручную, чтобы ввести существующие данные диалога в системе для проверки качества, или вы отправили задачу добавления вручную, пожалуйста, нажмите напрямую, чтобы завершить",
              helper: "Просмотр справочной документации",
              quit: "Выход из руководства",
              next: "Следующий шаг",
              complete: "Завершение",
              skip: "Пропустить",
              cancel: "Отмена",
              delete: "Удалить",
              save: "Сохранить",
            },
            template: {
              samplingInspector: "Инспектор по выборке",
              sessionType: "Тип сеанса",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Рабочий стол контроля качества",
              search: "Фильтрация",
              export: "Экспорт",
              condition: "Условия",
              logic: "Логика",
              reQualityInspection: "Повторная проверка качества",
              reQualityInspectionUnderWay: "Повторная проверка качества...",
              reQualityInspectionUnderWayTip:
                "Оставить страницу после закрытия окна не повлияет на выполнение задачи",
              reQualityInspectionDone: "Проверка качества завершена",
              reQualityInspectionDoneTip:
                "Проверка качества была успешно завершена, нажмите кнопку «Подтвердить»",
              endReQualityInspection: "Прекращение миссии",
              alreadyDoneTip: "Завершено",
              totalTip: "Итого",
              unit: "Полоска",
              endReInspectionSuccess:
                "Задача повторного контроля качества прекращена",
              closeWindow: "Закрыть окно",
              endReInspectionFailed:
                "Повторная проверка качества не удалась, пожалуйста, попробуйте позже!",
            },
            detail: {
              name: "Детали контроля качества",
              reviewName: "Подробности обзора",
              spotCheckName: "Детали выборочной проверки",
              audioText: "Текст записи",
              callInfo: "Информация о вызове",
              satisfactionEvaluation: "Оценка удовлетворенности:",
              queueTime: "Очередь занимает много времени:",
              ringingTime: "Время звонка:",
              callTime: "Событие вызова:",
              hangUpIdentity: "Вызов:",
              location: "Территория:",
              source: "Источник:",
              agent: "Обслуживание клиентов:",
              deviceType: "Тип оборудования:",
              callType: "Тип вызова:",
              phoneNumber: "Номер эстафеты:",
              task: "Задачи:",
              commentsTip: "Пожалуйста, введите отзывы о контроле качества",
              lastPage: "Предыдущая страница",
              nextPage: "Следующий",
              score: "Оценка качества",
              review: "Обзор",
              file: "Архив",
              changeList: "Запись изменений",
              fullMarks: "Полная оценка",
              most: "Максимум",
              saveReview: "Представлено на рассмотрение",
              saveSpotCheck: "Представление выборочной проверки",
              point: "Точка контроля качества",
              sampleAssignInspector: "Назначить инспекторов по выборке",
              reviewAssignInspector: "Назначенный ревизор",
              sampleStatus: "Статус выборочной проверки",
              read: "Прочитано",
              user: "Пользователи",
              confirmer: "Подтверждающий человек",
              voiceDownloadError:
                "Голосовая загрузка не удалась, попробуйте снова позже!",
              smartTags: "Умные этикетки",
              interactiveTip:
                "Использование интерактивной идентификации для обнаружения использования обслуживания клиентов",
              interactiveIsEmptyTip:
                "Контент распознавания взаимодействия не обнаружен",
              businessRecords: "Бизнес-записи",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Корпоративный анализ WeChat",
            },
          },
        },
      },
      cards: {
        health: "Проверка качества здоровья",
        accuracy: "Обнаруженный уровень одобрения",
        sessionsTotal: "Общее количество сеансов",
        enable: "Включение",
        disable: "Отключить",
        none: "Нет",
        call: "Звонки",
        dialogue: "Диалог",
        taobao: "Электронной коммерции",
        wecom: "Сеанс",
        ticket: "Наработки",
      },
      component: {
        backup: "(Резервное копирование)",
        taskSaveAsSucceeded: "Сохранить задачу как успех",
        copyContent: "Копирование содержимого",
        essentialInformation: "Основная информация",
        ruleConfiguration: "Конфигурация правил",
        basicInformationQualityInspector:
          "Основная информация-инспектор качества",
        qualityInspectionRating: "Рейтинг контроля качества",
        roleConfiguration: "Конфигурация роли",
        autoAddConfiguration: "Автоматическое добавление конфигурации",
        autoPushConfiguration: "Автоматическая настройка push",
        automaticSamplingConfiguration:
          "Автоматическая конфигурация выборочной проверки",
        automaticAllocationConfiguration:
          "Конфигурация автоматического назначения",
        businessConfiguration: "Конфигурация бизнеса",
        caseBaseConfiguration: "Библиотека дел",
      },
      template: {
        cancel: "Отмена",
        complete: "Завершение",
        sort: "Сортировка",
        ruleApproval: "Утверждение правил",
        enterpriseMicroConversationAnalysisTask:
          "Задачи анализа корпоративных микро-сеансов",
        enterpriseAndMicroVoiceAnalysisTask: "Задачи анализа микроголоса",
        saveTaskAs: "Сохранить задачу как",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Подробные сведения о правилах" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Модификация прошла успешно",
            automaticApproval: "Автоматическое утверждение",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Модификация прошла успешно",
            test: "Тест",
            formal: "Официально",
            adopt: "Через",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Нет, нет?",
              pleaseEnterTheRejectionReason:
                "Пожалуйста, введите причину отклонения",
              reject: "Отклонить",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Модификация прошла успешно",
              taskName: "Название задачи",
              dataSource: "Источник данных",
              qualityInspectionType: "Тип контроля качества",
              state: "Состояние",
              updateTime: "Время обновления",
              updatedBy: "Обновление человека",
              approver: "Утверждатель",
              reasonForRejection: "Причина отклонения",
              operation: "Операция",
              details: "Подробная информация",
              adopt: "Через",
              whole: "Все.",
              approvalStatus: "Статус утверждения",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Обзор",
      hi: "Привет,",
      standardEdition: "Стандартная версия",
      call: "Голосовой контроль качества баланса",
      text: "Срок действия проверки качества текста",
      collectionTasks: "Коллекционные задачи контроля качества",
      noCollection: "Нет задач по проверке качества,",
      goToCollecte: "Иди в коллекцию",
      yesterdayOverView: "Вчера бизнес-статистика",
      yesterdayAgent: "Рейтинг обслуживания клиентов вчера",
      yesterdayInspWork: "Работа инспектора качества вчера",
      operationalStatistics: {
        title: "Оперативная статистика",
        qualityTesting: "Проверка качества",
        spotCheck: "Выборочная проверка",
        appeal: "Жалоба",
        chartTitles: {
          qualityTestingTrend: "Тенденции контроля качества",
          qualityTestingAnalysis: "Задача контроля качества",
          spotCheckTrend: "Тенденции выборочной проверки",
          spotCheckAnalysis: "Задача выборочной проверки",
          appealTrend: "Тенденции в отношении жалоб",
          appealAnalysis: "Обжалование мандатов",
        },
        titles: {
          qualityDataTend: "Распределение данных контроля качества",
          qualityCover: "Охват проверки качества",
          qualityAverageTime:
            "Средняя продолжительность ожидания проверки качества",
          qualityCoverTend: "Тенденции контроля качества",
          handCover: "Ручная обработка ситуации",
          checkCover: "Охват выборочной проверки",
          resAverageTime: "Средняя продолжительность обработки",
          handTend: "Тенденции в ручном обращении",
          dataTend: "Распределение задач данных",
        },
        qualityAverage: "Средняя оценка качества",
        scoreTrend: "Тенденции оценки качества",
        qualityAverageTime:
          "Средняя продолжительность ожидания проверки качества",
        callCoverage: "Охват голосовой проверки качества",
        textCoverage: "Охват контроля качества текста",
        checkCount: "Количество данных выборочной проверки",
        siphonCount: "Извлечение объема данных",
        reviewCount: "Обзор объема данных",
        appealCount: "Объем данных жалоб",
        checkRate: "Коэффициент выполнения выборочной проверки",
        reviewRate: "Процент рассмотрения апелляций",
        PieTitle: {
          totalCount: "Общее количество диалогов",
          totalCall: "Количество голосовых вызовов",
          totalIm: "Количество текстовых диалогов",
          inspectionCall: "Проверка качества голоса",
          inspectionIm: "Проверка качества текста",
          checkCall: "Количество голосового отбора проб",
          unCheckCall: "Голос не проверен",
          checkIm: "Текстовая выборка",
          unCheckIm: "Текст не проверен",
          reviewCall: "Объем голосового обзора",
          reviewIm: "Объем обзора текста",
          unReviewCall: "Количество непроверенных голосов",
          unReviewIm: "Количество непроверенных текстов",
          unInspectionCall: "Голос не проверен",
          unInspectionIm: "Текст не проверен",
        },
        check_rate: "Охват выборочной проверки",
        voice_check_rate: "Охват голосовой выборки",
        text_check_rate: "Охват выборочной проверки текста",
        sample_execute_time: "Средняя продолжительность выборочной обработки",
        review_execute_time: "Средняя продолжительность обработки жалоб",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Правила контроля качества",
          agent: "Рейтинг обслуживания клиентов",
        },
        tableTips: {
          conditions:
            "<Div> Сортировать общее количество разговоров о нарушениях и соблюдении правил контроля качества. <Br/>\nКонтроль качества: название пункта контроля качества <br />\nОбщее количество разговоров о нарушениях: общее количество разговоров о нарушениях, которые были нарушены в этом пункте проверки качества <br />\nДоля нарушений: Доля нарушений = общее количество разговоров о нарушениях/общее количество разговоров о нарушениях </div>",
          agent:
            "<Div> Сортировать средний рейтинг мест <br />\nНазвание службы поддержки клиентов: имя службы поддержки клиентов <br />\nГруппа обслуживания клиентов: группа обслуживания клиентов (информация об организации в информации обслуживания клиентов)<br />\nОбщее количество оцененных диалогов: общее количество диалогов по качеству обслуживания клиентов <br />\nСредняя оценка качества: средняя оценка качества обслуживания клиентов (сумма баллов/общее количество разговоров)</div>",
        },
        tips: {
          total:
            "Общее количество проходов/разговоров, которые входят в задачи контроля качества",
          agentTotal:
            "Общее количество клиентов по контролю качества, которые вошли в задачу контроля качества",
          avg: "Средний балл для оценки качества",
          violation: "Все разговоры о проверке качества",
          compliance: "Разговор",
          check: "Общее количество выборочных проверок вручную",
          appeal:
            "Общее число поданных жалоб из общего числа проходных диалогов",
          review:
            "Общее число поданных на рассмотрение из общего числа диалогов",
        },
        subTips: {
          violation:
            "Уровень нарушений: общее количество нарушений/общее количество проверок качества * 100%",
          compliance:
            "Уровень соблюдения: общее количество соблюдения/общее количество проверок качества * 100%",
          check:
            "Коэффициент охвата выборочной проверкой: общее количество ручных выборочных проверок/общее качество проверки качества * 100%",
          appeal:
            "Уровень жалоб: общее количество апелляций/общее количество проверок качества * 100%",
          review:
            "Уровень проверки: общее количество проверок/общее качество проверки * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Настройка системы",
          systemName: "Название системы",
          speakerRecognize: "Идентификация слова",
          massage: "Уведомление о сообщениях",
          evaluate: "Искусственная когнитивная оценка",
          evaluateTip:
            "После открытия выберите быструю выборочную проверку в деталях контроля качества.",
          speakerRecognizeTip:
            "Доступ к записи звонков третьих лиц через API требует идентификации, является ли левый канал клиентом или клиентом",
          speakerRecognizeTip2:
            "Одноканальная запись определяет роль обслуживания клиентов через распознавание текста, конфигурацию ролей",
          enabledImSendStatusFilter:
            "Текстовая проверка качества фильтрации сообщений",
          enabledImSendStatusFilterTips:
            "Содержание обнаружения оператора проверки качества не включает сообщения с состояниями [отозвано], [сбой отправки], [непрочитанное автономное сообщение]",
          lang: "Настройки системного языка",
          zh: "Упрощенный китайский",
          en: "English",
          tw: "Традиционный китайский",
        },
        template: {
          largeModelSettings: "Большие настройки модели",
          indonesian: "Индонезийский язык",
          vietnamese: "Вьетнамский",
          portuguese: "Португальский",
          malay: "Малайский",
          french: "Французский",
          spanish: "Испанский",
          japanese: "Японский",
          thai: "Тайский",
          blockSystemSendingMessages:
            "Система экранирования для отправки сообщений",
          textDataDesensitization: "Десенсибилизация текстовых данных",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Когда переключатель включен, отобразится раскрывающий список десенсибилизируемого поля (поддержка множнего выбора), десенсибилизируемое поле: имя человека, номер мобильного телефона, адрес",
          desensitizingField: "Десенсибилизирующие поля",
          name: "Имя человека",
          cellphoneNumber: "Номер мобильного телефона",
          address: "Адрес",
          dataCleaning: "Очистка данных",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "После настройки данные сеанса контроля качества и корпоративного WeChat будут регулярно очищаться и не могут быть восстановлены. Пожалуйста, используйте их с осторожностью",
          newCleaningRules: "Новые правила очистки",
          qualityInspectionRuleApproval: "Утверждение правил контроля качества",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "После открытия изменения правил проверки качества должны быть одобрены, прежде чем они вступят в силу",
          maskOfflineMessageUnread:
            "Блокировка непрочитанных сообщений в автономном режиме",
          privacyNumberProtection: "Защита номеров конфиденциальности",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            "После включения десенсибилизуйте поле «Телефон клиента» в вызове, которое отображается как 1881 *******",
          shieldingWithdrawn: "Щит снят",
          maskSendingFailed: "Сбой передачи блокировки",
        },
        component: {
          conversation: "Звонки",
          dialogue: "Диалог",
          weCom: "Корпоративное WeChat",
          day: "День",
          week: "Неделя",
          month: "Месяц",
          year: "Год",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Диапазон очистки",
            dataCleaningTimeSetting: "Настройки времени очистки данных",
            afterCleanUpTheData: "После очистки данных",
            dataAfterComplaintCompletion: "Данные после завершения жалобы",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Успешное выполнение",
          saveSuccessful: "Успешное сохранение",
          speechRecognition: "Распознавание речи",
          reExecuteErrorCorrection: "Повторное исправление ошибок",
          confirmExecution: "Подтверждение выполнения",
          voiceDetection: "Обнаружение речи",
          noiseDetectionPowerLevel: "Обнаружение шума powerLevel ≤",
          pleaseEnterTheThreshold: "Введите пороговое значение",
          volumeDetectionPowerLevel: "Обнаружение громкости powerLevel ≥",
          reset: "Сброс",
          preserve: "Сохранить",
        },
        coachTemplate: {
          french: "Французский",
          spanish: "Испанский",
          japanese: "Японский",
          thai: "Тайский",
          loginPopupWindow: "Вход всплывающее окно",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "После включения всплывающие подсказки при входе в интеллектуальную систему спарринга",
          title: "Заголовок",
          text: "Текст",
          checkBoxContent: "Отметьте содержимое поля",
          informedConsentForm: "Информированное согласие",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Чтобы помочь вам улучшить навыки общения и речи, а также применение специальных знаний, которые позволяют вам практиковаться в любое время и в любом месте и получать обратную связь в режиме реального времени, мы собираем вашу следующую информацию с помощью этой платформы:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Я полностью прочитал и полностью понял эту форму информированного согласия и торжественно заявил о своем согласии на обработку моей конфиденциальной личной информации в соответствии с целями и способами, описанными в этой форме информированного согласия",
        },
      },
      systemIntegration: {
        title: "Настройка системы",
        ccIntegrationTips:
          "Интеллектуальная проверка качества для стыковки документов с описанием колл-центра Udesk",
        unauthorized: "Несанкционированный",
        authorized: "Авторизованный",
        udeskDomain: "Доменное имя системы обслуживания клиентов",
        udeskToken: "Ключ системы обслуживания клиентов",
        udeskAdminEmail:
          "Учетная запись администратора системы обслуживания клиентов",
        password: "Пароль учетной записи администратора",
        applyAuth: "Авторизация",
        cancelAuth: "Отмена авторизации",
        reApplyAuth: "Повторная авторизация",
        keyTips:
          "Фоновое управление системой обслуживания клиентов-аутентификация API Token в единый вход",
      },
      customerField: {
        title: "Поля клиента",
        table: {
          propName: "Название поля",
          propDesc: "Описание поля",
          valueType: "Тип поля",
          propType: "Способ создания",
          createTime: "Время создания",
          canNotEdit: "Системные поля не поддерживают редактирование",
        },
        edit: "Редактирование полей",
        new: "Новое поле",
      },
      wechatDocking: {
        title: "Предприятие микро-стыковка",
        formLabels: {
          companyId: "Идентификатор предприятия",
          communicationKey: "Ключ связи",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL обратного вызова события",
          applicationName: "Название приложения",
          applicationId: "ID веб-приложения",
          applicationSecret: "Применение secret",
        },
        formTitle: {
          initialForm: "Начальная настройка",
          synchronizeForm: "Синхронизация адресной книги",
          settingForm: "Настройки приложения",
        },
        qrCodeUrlTip:
          "Пожалуйста, попросите администратора сканировать двумерный код ниже для привязки приложения разработки",
      },
      taobao: {
        formLabels: {
          name: "Название магазина",
          account: "Номер счета",
          password: "Пароль",
          confirm: "Подтверждение пароля",
          authorization: "Авторизация",
          switchOn: "Открыть.",
          switchOff: "Закрыть",
        },
        tableLabels: {
          name: "Название магазина",
          account: "Номер счета",
          authorization: "Авторизация",
          updateUser: "Недавно обновленный человек",
          updateTime: "Время последнего обновления",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Виморфное доменное имя второго уровня",
          secretKeyTip:
            "Ключи арендатора, полученные в стыковке фоновой системы Micro",
        },
      },
      user: {
        index: {
          name: "Управление персоналом",
          existingUsers: "Уже есть сотрудники",
          deleteSuccess: "Удаление прошло успешно",
          deleteFailed: "Сбой удаления",
        },
        detail: {
          accountPrompt: "Введите адрес электронной почты",
          resetPassword: "Сброс пароля",
          passwordPrompt: "Пароль (6-14 цифр с комбинацией букв и цифр)",
          originPasswordPrompt: "Введите исходный пароль",
          resumePasswordPrompt: "Пожалуйста, введите пароль снова",
          passwordFormWrong: "Пожалуйста, введите пароль из 6-14 букв и цифр!",
          resumePasswordWrong: "Пароли не совпадают дважды!",
          suppertImageType: "Поддержка jpg, gif, png, максимум 500Kb",
          namePrompt: "Введите имя",
          nickNamePrompt: "Пожалуйста, введите псевдоним",
          telephonePrompt: "Введите номер телефона",
          roleSelectedPrompt: "Пожалуйста, выберите роль",
          agentIdPrompt: "Введите номер работы",
          disabled: "Отключить",
          enabled: "Включение",
          reloadBowerInfo:
            "Изменение информации пользователя, нужно ли переобновлять браузер?",
          saveUserError:
            "Сбой сохранения информации о пользователе, повторите попытку позже",
          resetPasswordSuccess: "Сброс пароля успешно",
          resetPasswordFailed:
            "Сброс пароля не удается, повторите попытку позже",
        },
      },
      userGroups: {
        index: {
          name: "Управление группами сотрудников",
          existingUserGroups: "Уже есть группа сотрудников",
        },
        detail: {},
      },
      role: {
        index: {
          name: "Управление ролями",
          existingRoles: "Существующие роли",
        },
        edit: {
          editRole: "Редактирование роли",
          createRole: "Создание роли",
          basicInfo: "Основная информация",
          taskPermissions: "Разрешения на задачи",
          existingTasks: "Уже существующие задачи",
        },
      },
      anomalousData: {
        title: "Аномальные данные",
        list: {
          selectHolder: "Выберите источник данных",
          businessTime: "Время работы",
          businessId: "Бизнес Id",
          export: "Экспорт",
        },
      },
      templateComponent: {
        list: {
          title: "Шаблон рынка",
          name: "Название шаблона",
          searchHolder: "Введите содержимое для запроса",
          update: "Обновления",
          sort: "Способ сортировки",
          moduleType: "Тип шаблона",
          moduleSourceType: "Применимый тип",
          fetchNum: "Количество полученных",
          newest: "Последние обновления",
          usedMost: "Получите максимум",
          call: "Звонки",
          dialogue: "Диалог",
          waiting: "В ожидании рассмотрения",
          cancel: "Отмена публикации",
          success: "Ревизия прошла, опубликовано",
          failed: "Отклонение от проверки",
          no: "Не обновляется",
          ok: "Можно обновить",
          all: "Все.",
          delete: "Удаление прошло успешно",
          downOk: "Сниз успешно",
          withdraw: "Снятие прошло успешно",
          updateOk: "Обновление прошло успешно",
          get: "Получить",
          width: "Снятие",
          getOk: "Получить успех",
          orgName: "Дочерняя организация",
          nowVersion: "Текущая версия",
          lastedVersion: "Последняя версия",
          appStatus: "Состояние приложения",
          actions: "Операция",
          down: "Сниз полки",
          updateTop: "Обновление",
          check: "Посмотреть",
          deleteWhen: "Подтвердите удаление?",
          yes: "Да, да.",
          noo: "Нет",
          deleteOk: "Удалить",
          permissible: "Разрешить",
          noPermissible: "Не допускается.",
          openUp: "Открыть",
          openDown: "Не открыт",
          task: "Задачи контроля качества",
          point: "Точка контроля качества",
          tag: "Семантические метки",
          muti: "Несколько элементов",
          inter: "Один вопрос и один ответ",
          info: "Информационная сущность",
          concept: "Понятие слова",
          detail: "Детали модуля",
          infomation: "Основная информация",
          detailConfig: "Подробная конфигурация",
          twoEdit: "Вторичное редактирование",
          mustUpdate: "Обновление сейчас",
          descition: "Описание шаблона",
          descitionSize: "Описание шаблона не может превышать 250 слов",
          stencilsDetail: "Детали шаблона",
          updatedRecords: "Обновление записей",
          version: "Номер версии",
          updateDescription: "Описание обновления",
          myModule: "Мой шаблон",
          addModule: "Новый модуль",
          publishVersion: "Выпуск версии",
          earliestVersion: "Самая ранняя версия",
          edit: "Редактирование",
          add: "Добавлено",
          configNo: "Детали конфигурации модуля не могут быть пустыми",
          updateNo: "Описание обновления не может быть пустым",
          editSuccess: "Модификация прошла успешно",
          saveSuccess: "Успешное сохранение",
          newer: "Обновление",
          sumbit: "Представление",
          cancelName: "Отмена",
          nameRequired: "Имя модуля является обязательным полем",
          nameSize: "Имя модуля не может превышать 16 слов",
          publishStatus: "Статус публикации",
          submitUpdate: "Подать обновление",
          updateVersion: "Обновленная версия",
          updateExplain: "Инструкции по обновлению",
        },
      },
      customDataSource: {
        amazonEcommerce: "Амазонка для электронной коммерции",
        youtubeVideo: "Youtube Видео",
        amazon: {
          component: {
            queuing: "В очереди",
            notStarted: "Не начал",
            inProgress: "В процессе",
            success: "Успех",
            failed: "Провал",
            successfullyCreated: "Создание успеха",
            editedSuccessfully: "Редактирование успешно",
            operationSucceeded: "Операция прошла успешно",
            deletedSuccessfully: "Удаление прошло успешно",
            link: "Ссылки",
            grabState: "Состояние захвата",
            updateTime: "Время обновления",
            grab: "Схватка",
            edit: "Редактирование",
          },
          template: {
            amazonEcommerce: "Амазонка для электронной коммерции",
            thisProduct: "Этот продукт",
            competition: "Конкурсный продукт",
            new: "Новое строительство",
            edit: "Редактирование",
            link: "Ссылки",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube Видео",
            thisProduct: "Этот продукт",
            competition: "Конкурсный продукт",
            new: "Новое строительство",
            edit: "Редактирование",
            link: "Ссылки",
          },
          component: {
            queuing: "В очереди",
            notStarted: "Не начал",
            inProgress: "В процессе",
            success: "Успех",
            failed: "Провал",
            successfullyCreated: "Создание успеха",
            editedSuccessfully: "Редактирование успешно",
            operationSucceeded: "Операция прошла успешно",
            deletedSuccessfully: "Удаление прошло успешно",
            youTubeVideoLink: "Ссылка на видео YouTube",
            grabState: "Состояние захвата",
            updateTime: "Время обновления",
            grab: "Схватка",
            edit: "Редактирование",
          },
        },
      },
    },
    demo: {
      title: "Интеллектуальная проверка качества",
      template: "Выбор шаблона контроля качества",
      radio: "Выбор формы записи",
      uploadAudio: "Загрузить запись",
      uploadAudioTip:
        "Загрузка записей поддерживает только форматы wav и mp3, размер не превышает 1G",
      uploadText: "Загрузить текст",
      uploadTextTip:
        "Загрузка текста поддерживает только форматы srt и txt, размер не превышает 10M, ASR больше не будет использоваться для анализа файлов записей после загрузки текста",
      uploadSuccess: "Загрузка файла записи успешно",
      uploadFailed: "Неудачная загрузка файла записи",
      startQualityInspection: "Начать проверку качества",
      qualityInspectionStarted: "Проверка качества",
      downloadText: "Скачать текст",
      checking: "Прогресс контроля качества",
      checkingTip:
        "Ожидайте, что это займет несколько минут, пожалуйста, подождите",
      noCheckContent: "Нет результатов проверки качества",
      noTextDownload: "Нет текста контроля качества для загрузки!",
      pleaseCheckParams:
        "Проверьте, выбрать ли шаблон, тип голоса и загрузить файл записи",
      inspectionFailed:
        "Проверка качества не удалась, пожалуйста, попробуйте позже!",
    },
    wechatQa: {
      baseInfo: { title: "Основная информация" },
      conversationSetting: {
        title: "Настройки сеанса",
        segmentationStrategy: "Стратегия разделения",
        rule: "Стратегии",
        time: "Время разделения",
        daily: "Каждый день",
        passScore: "Сессионные баллы",
        ruleLogic: "Логика выполнения",
      },
      message: {
        title: "Push событие",
        table: {
          index: "Серийный номер",
          name: "Название",
          type: "Push-метод",
          desc: "Описание",
          status: "Состояние",
        },
        subTitle: "Список событий",
      },
      checkPoint: {
        title: "Аналитическая модель",
        groups: "Группировка моделей",
        newClassification: "Создание новых групп",
        editClassification: "Редактирование группировки",
        benchmarkScore: "Базовый балл",
        pointCount: "Модель общего анализа в текущей классификации",
        openPointCount: "Включенное количество",
        closedPointCount: "Количество деактивации",
        scoreLowerLimit: "Нижний предел минимального балла",
        scoreUpperLimit: "Максимальное ограничение баллов",
        classificationName: "Название группы",
        classificationNamePlaceHolder: "Введите название группы",
        classificationRemark: "Описание группы",
        classificationRemarkPlaceHolder: "Введите описание группы",
      },
      chartRecord: {
        title: "Enterprise WeChat Conversation",
        table: {
          id: "Идентификатор сеанса",
          type: "Тип сеанса",
          startTime: "Время начала сеанса",
          endTime: "Время последнего окончания сеанса",
          groupId: "Идентификатор группы",
          client: "Клиенты",
          customer: "Обслуживание клиентов",
          averageScore: "Средняя оценка качества",
        },
        detail: {
          title: "Подробнее о сеансе WeChat предприятия",
          inspectionInfo: "Анализ информации",
          chartInfo: "Информация о сеансе",
          tasks: "Аналитические задачи",
          avgScore: "Средний балл анализа",
          qualified: "Квалифицированный",
          unqualified: "Неквалифицированный",
          taskTitle: "Применимые шаблоны анализа",
          info: "Анализ информации в реальном времени",
          today: "Новости дня",
          history: "Исторические новости",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Введите примечание/псевдоним",
                pleaseEnterTheGroupChatName: "Введите название группового чата",
              },
            },
          },
          template: { whole: "Все." },
        },
      },
      realTimeAnalysis: {
        title: "Анализ в реальном времени",
        table: {
          name: "Название сцены",
          count: "Количество аналитических моделей",
          baseScore: "Базовый балл",
          passScore: "Квалифицированные баллы",
          maxScore: "Максимальное ограничение баллов",
          minScore: "Нижний предел минимального балла",
          status: "Состояние",
          actions: "Операция",
        },
        detail: {
          title: "Анализ сцены в реальном времени",
          form: {
            name: "Название сцены",
            prevCondition: "Условия срабатывания сцены",
            prevConditionToolTip:
              "Если условие пусто, анализ сцены по умолчанию",
            prevConditionError:
              "Условия запуска сцены не могут совпадать с аналитической моделью",
            scoreInputPlaceholder: "Введите счет",
            baseScore: "Базовый балл",
            status: "Состояние",
            passScore: "Квалифицированные баллы",
            maxScore: "Максимальный лимит баллов",
            minScore: "Нижний предел наименьшего балла",
            analysisModel: "Аналитическая модель",
            message: "Запуск уведомления",
            pushType: "Push-метод",
            createAnalysisModel: "Добавление модели",
            scoreUpperLimitError:
              "Нижний предел наименьшего балла не может быть больше верхнего предела",
            table: {
              name: "Название модели",
              rule: "Правила",
              score: "Оценка",
            },
            pushConfigSwitchTip1: "Когда появляется сцена",
            pushConfigSwitchTip2: "Когда и выше, push-уведомления",
          },
        },
      },
      smartCustomerTag: {
        title: "Умные теги клиентов",
        table: {
          name: "Название правила",
          tags: "Корпоративная микро-клиентская этикетка",
          status: "Состояние включения",
          createUser: "Создатель",
          createTime: "Время создания",
        },
        detail: {
          name: "Название правила",
          condition: "Установить правило",
          logic: "Логика",
          status: "Состояние включения",
          tags: "Выбор ярлыков",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Количество тегов клиентов" },
        },
        messageFiltering: {
          template: {
            edit: "Редактирование",
            messageContent: "Содержание сообщения",
            messageTypeID: "Идентификатор типа сообщения",
            messageTypeDescription: "Описание типа сообщения",
            messageFiltering: "Фильтрация сообщений",
            suitableForFilteringDataWithoutQualityInspection:
              "Подходит для фильтрации данных без проверки качества",
            create: "Создание",
            messageDescription: "Описание сообщения",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Время разговора",
          sessionType: "Тип сеанса",
          whole: "Все.",
          staff: "Сотрудники",
          customer: "Клиенты",
          callKeywords: "Ключевые слова вызова",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Подробнее о корпоративном микро-голосовом вызове",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Архив сеансов",
        voiceCall: "Голосовой вызов",
        noMoreVoiceCalls: "Нет больше голосовых вызовов",
        enterpriseWechatVoiceCall: "Корпоративные голосовые вызовы WeChat",
        enterpriseWechatConversationDetails:
          "Подробнее о сеансе WeChat предприятия",
        previous: "Предыдущий",
        next: "Следующий",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Задачи анализа корпоративных микро-сеансов",
          enterpriseAndMicroVoiceAnalysisTask: "Задачи анализа микроголоса",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat: "Корпоративный анализ WeChat",
      },
      wechatQaSettingEntry: { messageFiltering: "Фильтрация сообщений" },
    },
    businessAnalysis: {
      keyword: "Название отчета",
      createUser: "Создатель",
      createTime: "Время создания",
      updateTime: "Время обновления",
    },
    semanticIntelligence: {
      applicationMessageType: "Тип сообщения приложения",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Пожалуйста, улучшите конфигурацию",
              generatePromptLanguage: "Генерируемые подсказки",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Соответствует ли",
              gradedEvaluation: "Оценка по разделу",
            },
          },
          training: {
            index: {
              pleaseEnter: "Пожалуйста, введите",
              pleaseGeneratePrompt: "Создайте подсказки",
              pleaseSelectADialogue: "Пожалуйста, выберите разговор",
              training: "Обучение",
              dataType: "Тип данных:",
              voiceCall: "Голосовой вызов",
              textDialogue: "Текстовый диалог",
              generate: "Генерация",
              qualityInspectionResults: "Результаты проверки качества:",
              hit: "Хит-речь:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Название модели",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Введите название модели, например, если клиент не действовал",
            modelDescription: "Описание модели",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Вы можете подробно описать то, что вам нужно проверить, чтобы легко понять модель. Например, есть ли какие-либо бездействия со стороны службы поддержки клиентов, то есть ответ на вопрос клиента, но вопрос клиента не был обработан, или содержание ответа не имеет ничего общего с ответом клиента.",
            validity: "Эффективность",
            effective: "Эффективно",
            invalid: "Недействительно",
            type: "Тип",
            hintLanguage: "Направление",
            editIntelligentModel: "Редактирование умных моделей",
            createANewIntelligentModel: "Новая интеллектуальная модель",
          },
        },
        index: {
          modelName: "Название модели",
          modelDescription: "Описание модели",
          type: "Тип",
          whetherItMeetsTheRequirements: "Соответствует ли",
          gradedEvaluation: "Оценка по разделу",
          validity: "Эффективность",
          intelligentModel: "Интеллектуальная модель",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Применимо к субъективной проверке качества (например, является ли отношение службы контроля качества восторженным, терпение обслуживания и т. Д.)",
        },
      },
      clusterAnalysis: "Кластерный анализ",
      speechMining: "Разговорные раскопки",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Поддержка пакетного импорта информационных объектов через интерфейс API, это имя API не поддерживает редактирование после сохранения",
        },
        component: { nothing: "Нет." },
      },
      knowledgeBase: {
        index: {
          classification: "Классификация",
          import: "Импорт",
          importKnowledgeBase: "Импорт базы знаний",
          moveKnowledgePointsInBatch: "Массовая мобильная точка знаний",
          moveTo: "Переезд к:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Выберите точки знаний, которые требуют массового перемещения",
        },
        detail: {
          index: {
            intrasentence: "В предложении",
            afterProblem: "После вопроса",
          },
        },
      },
      semanticTags: { component: { nothing: "Нет." } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Чувствительные слова",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Чувствительные слова: чувствительные слова могут быть настроены на слова бизнес-внимания, используемые для общественного мнения, чтобы найти чувствительные слова",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Новые чувствительные слова",
            editSensitiveWords: "Редактирование чувствительных слов",
            sensitiveWords: "Чувствительные слова",
          },
          template: {
            sensitiveWords: "Чувствительные слова",
            sensitiveWordsCannotBeEmpty:
              "Чувствительные слова не могут быть пустыми",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Пожалуйста, введите чувствительные слова. Несколько чувствительных слов используются в середине.",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "Выберите сценарий приложения",
            keyWord: "Ключевые слова",
            pleaseEnterKeywords: "Введите ключевые слова",
            keywordLengthWithinCharacters:
              "Длина ключевого слова в пределах 40 символов",
            dataSources: "Источник данных",
            pleaseSelectADataSource: "Выберите источник данных",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "В настоящее время кластеризация завершена, повторное редактирование приведет к созданию новой кластеризации данных, которые не будут перезаписаны.",
            savingSucceeded: "Успешное сохранение",
            clusteringStart: "Начало кластеризации",
            clustering: "Кластеризация",
            edit: "Редактирование",
            newlyBuild: "Новое строительство",
            clusterName: "Название кластера",
            pleaseEnterAName: "Введите имя",
            withinCharactersOfTheName: "Имя Длина в пределах 40 символов",
            speaker: "Говорящий человек",
            pleaseSelectASpeaker: "Пожалуйста, выберите говорящего",
            sessionDate: "Дата сеанса",
            pleaseEnterTheSessionDate: "Введите дату сеанса",
            selectUpToMonthOfHistoricalSessionData:
              "Выберите до 1 месяца исторических данных сеанса",
            automaticallyClusterDailyNewData:
              "Автоматическая кластеризация новых данных ежедневно",
            conversationChannel: "Сеансовый канал",
            pleaseSelectAConversationChannel: "Выберите канал сеанса",
            applicationScenario: "Сценарий применения",
            clusteringRules: "Правила кластеризации",
            incompleteClusteringRuleSettings:
              "Неполные настройки правил кластеризации",
            startClustering: "Начало кластеризации",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Пожалуйста, выберите задачу контроля качества",
              beEqualTo: "Равно",
            },
          },
        },
        detail: {
          index: {
            theme: "Темы",
            numberOfCorpora: "Количество корпус",
            corpusCoverage: "Покрытие корпус",
            clusterDetails: "Детали кластеризации",
          },
          ranking: {
            hotWords: "Горячие слова",
            numberOfCorpora: "Количество корпусов",
            speaker: "Говорящий человек",
            sit: "Сидеть",
            passenger: "Гость",
            hotSentence: "Горячие предложения",
            corpusDetails: "Подробнее о корпусов",
          },
        },
        index: {
          delete: "Удалить",
          deletionSucceeded: "Удаление прошло успешно",
          clusterName: "Название кластера",
          speaker: "Говорящий человек",
          creationTime: "Время создания",
          state: "Состояние",
          operation: "Операция",
          see: "Посмотреть",
          edit: "Редактирование",
          clusterAnalysis: "Кластерный анализ",
          newCluster: "Новые кластеры",
          clusteringStatus: "Состояние кластеризации",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Введите ключевые слова для поиска возражений",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Библиотека речи",
                recommendedScripts: "Рекомендуемая речь",
                common: "Всего",
                strip: "Полоска",
                scriptFavorites: "Слова избранное",
              },
              conditionPostfix: {
                detectRoles: "Роль обнаружения",
                detectionRange: "Диапазон обнаружения",
                sentence: "Предложение",
              },
              customerFilter: {
                scriptFiltering: "Фильтрация речи",
                cancel: "Отмена",
              },
              index: {
                viewDetails: "Посмотреть детали",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Вы подтверждаете, что хотите сдаться? После оставления он больше не может быть восстановлен",
                abandonment: "Оставить",
                deletionSucceeded: "Удаление прошло успешно",
                passenger: "Гость",
                sit: "Сидеть",
                adopted: "Приняты",
                determine: "Определить",
                cancel: "Отмена",
                adopt: "Принятие",
              },
              pageRightFrom: {
                savingSucceeded: "Успешное сохранение",
                pleaseEnterTheScriptContent: "Введите содержание речи",
                pleaseSelectAFavoriteGroup:
                  "Пожалуйста, выберите избранные группы",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Библиотека обработки возражений",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "Пожалуйста, выберите голосовой",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Обработка возражений Избранное",
            },
            pageRightTable: {
              likeCancel: "Нравится отменить",
              likeSuccessfully: "Нравится успех",
              adoptScripts: "Принять речь",
              operation: "Операция",
              viewConversationDetails: "Посмотреть детали диалога",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "Точки продажи продуктов",
              },
              pageRightTable: { keyPointsOfScript: "Ключевые моменты" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Обработка возражений Избранное",
              newGroup: "Создание новых групп",
              editGroup: "Редактирование группировки",
              addGroup: "Добавление группировки",
              savingSucceeded: "Успешное сохранение",
              groupName: "Название группы",
              pleaseEnterTheGroupName: "Введите название группы",
              groupNameWithinCharacters:
                "Длина имени группировки в пределах 20 символов",
              deletionSucceeded: "Удаление прошло успешно",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings: "Рекомендуемые настройки речи",
                },
                rightForm: {
                  savingSucceeded: "Успешное сохранение",
                  intelligentRecommendation: "Умные рекомендации",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Принятые превосходные речи автоматически отображаются на боковой панели сеанса аналогичных возражений. Сотрудники могут напрямую просматривать рекомендованные речи для обучения на странице сведений о возобновлении сеанса.",
                  objectionsIncludedByDefault:
                    "Возражения, включенные по умолчанию",
                  addRecommendationTriggerSemantics:
                    "Увеличение рекомендуемой семантики триггера",
                  remindEmployeesToView:
                    "Напомните сотрудникам, чтобы посмотреть",
                  shareWithMoreEmployees:
                    "Поделиться с большим количеством сотрудников",
                  allHands: "Весь персонал",
                },
                smartToolbar: {
                  selectSemanticTag: "Выбор семантической метки",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Экспортируете ли вы все записи данных в текущей группе?",
                newScript: "Новая речь",
                editingScript: "Редактирование речи",
                savingSucceeded: "Успешное сохранение",
                objection: "Возражение",
                pleaseEnterAScriptObjection:
                  "Пожалуйста, введите дисречь возражения",
                speechTechnique: "Слова",
                pleaseEnterTheScriptContent: "Введите содержание речи",
                grouping: "Группировка",
                pleaseSelectAFavoriteGroup:
                  "Пожалуйста, выберите избранные группы",
                adoptScripts: "Принять речь",
                passenger: "Гость",
                sit: "Сидеть",
                numberOfViews: "Просмотров",
                useful: "Полезно",
                operation: "Операция",
                edit: "Редактирование",
                delete: "Удалить",
                deletionSucceeded: "Удаление прошло успешно",
                scriptRecommendationSettings: "Рекомендуемые настройки речи",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Библиотека речи",
          scriptMining: "Разговорные раскопки",
          objectionHandlingScripts: "Обработка возражений",
          productSellingPointScripts: "Точка продажи продукта",
        },
        sellingPoint: {
          index: { newCluster: "Новые кластеры" },
          components: {
            callDetailModal: {
              sessionDetails: "Сведения о сеансе",
              cancel: "Отмена",
              determine: "Определить",
              choice: "Выбор",
            },
            pageCenterTable: { scriptOverview: "Обзор речи" },
            pageLeftTable: {
              enterSearchKeywords: "Введите ключевые слова поиска",
              keyWord: "Ключевые слова",
            },
            pageRightTable: {
              index: {
                viewDetails: "Посмотреть детали",
                scriptLibrary: "Библиотека речи",
                scriptRefinement: "Уточнение речи",
                adoptScript: "Принять речь",
              },
              tagsEdit: { keyPointsOfScript: "Ключевые моменты" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "Точки продажи продуктов",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Точки продажи, включенные по умолчанию",
                  },
                },
                index: {
                  keyWord: "Ключевые слова",
                  pleaseSelectAKeyword: "Пожалуйста, выберите ключевые слова",
                  keyPointsOfScript: "Ключевые моменты",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Пожалуйста, введите точки речи",
                  viewDetails: "Посмотреть детали",
                  pleaseChooseToAdoptScript: "Пожалуйста, выберите голосовой",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Логика соответствия",
            nLPMatching: "NLP сопоставление",
            strictlyMatchByWord: "Строгое соответствие по словам",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Название типа сообщения",
          messageTypeID: "Идентификатор типа сообщения",
          messageTypeDescription: "Описание типа сообщения",
          applicationMessageType: "Тип сообщения приложения",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Подходит для доступа к данным разных каналов для проверки качества, и его необходимо классифицировать в соответствии с идентификацией канала",
        },
        detail: {
          index: {
            messageTypeName: "Название типа сообщения",
            pleaseEnterAMessageTypeName: "Введите имя типа сообщения",
            messageTypeDescription: "Описание типа сообщения",
            describeYourMessageTypeInDetail:
              "Подробное описание вашего типа сообщения",
            editMessageType: "Редактирование типов сообщений",
            newMessageType: "Новый тип сообщения",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Пожалуйста, выберите члена команды",
        existingFilter: "Уже существующие фильтры",
        pleaseSelect: "Пожалуйста, выберите",
        filterCondition: "Условия фильтрации",
        areYouSureToDeleteTheCurrentFilter:
          "Подтверждено ли удаление текущего фильтра?",
        sessionDate: "Дата сеанса",
        staff: "Сотрудники",
        customer: "Клиенты",
        pleaseEnterCustomerName: "Введите имя клиента",
        keyWord: "Ключевые слова",
        whole: "Все.",
        pleaseEnterSessionKeywords: "Введите ключевые слова сеанса",
        sessionDuration: "Продолжительность сеанса",
        sessionLabel: "Сцена сеанса",
        pleaseSelectASessionLabel: "Выберите сцену сеанса",
        commentTopic: "Темы комментариев",
        pleaseSelectACommentTopic: "Пожалуйста, выберите тему для комментариев",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Подтверждено ли перепокрытие текущего фильтра?",
        overlayFilter: "Покрытие фильтра",
        NewFilter: "Новый фильтр",
        newFilter: "Новый фильтр",
        filterName: "Название фильтра",
        pleaseEnter: "Пожалуйста, введите",
        allCall: "Все звонки",
        percentage: "Процентная доля",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "Поддерживает поиск по нескольким ключевым словам. Отношения «или» разделены запятыми (на китайском и английском языках), а отношения «и» разделены словами, такими как:",
          callRecordID: "Идентификатор записи звонков",
          pleaseFillInTheCallRecordID: "Укажите идентификатор записи звонков",
          minute: "Минута.",
          second: "Секунд",
          whole: "Все.",
          callRecording: "Запись звонков",
          enterpriseWechat: "Корпоративное WeChat",
          conversationChannel: "Сеансовый канал",
          pleaseSelectASessionChannel: "Выберите канал сеанса",
          learningStatus: "Статус обучения",
          pleaseSelectLearningStatus: "Пожалуйста, выберите статус обучения",
          notLearned: "Не учился",
          learned: "Учился",
          Minutes: "Минута.",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Фильтр пустой, новый фильтр не поддерживается!",
          filterAddedSuccessfully: "Фильтр был добавлен успешно!",
          failedToAddFilter: "Новый фильтр не удалось!",
          filterDeletedSuccessfully: "Фильтр удален успешно!",
          filterDeletionFailed: "Фильтр не удалось удалить!",
          filterOverwriteSucceeded: "Фильтр наложения успешно!",
          filterOverwriteFailed: "Фильтр наложения не удалось!",
          theme: "Темы",
          pleaseSelectATheme: "Выберите тему",
          keyEvents: "Ключевые события",
          pleaseSelectAKeyEvent: "Выберите ключевые события",
        },
        filterItem: {
          startRetesting: "Начать повторное тестирование",
          time: "Время",
          talkTime: "Продолжительность звонка",
          keyEvents: "Ключевые события",
          totalImplementationRateOfSupervisionItems:
            "Общий показатель осуществления надзора",
          sort: "Сортировка:",
          seeMore: "Посмотреть больше",
        },
        filterStatistics: {
          conversation: "Звонки",
          weChat: "WeChat",
          quantity: "Количество",
        },
      },
      caseBase: {
        index: {
          copyTo: "Копирование",
          pleaseSelectTheLocationToMove:
            "Пожалуйста, выберите место для перемещения!",
          folderCopiedSuccessfully: "Копирование папки успешно!",
          cancelCollectionSuccessfully: "Отмена коллекции прошла успешно!",
          collectionSucceeded: "Коллекция прошла успешно!",
          starCaseBase: "Звездная библиотека",
          move: "Мобильный",
          rename: "Переименование",
          delete: "Удалить",
          folderMovedSuccessfully: "Перемещение папки успешно!",
          fileMovedSuccessfully: "Мобильные файлы успешно!",
          fileMoveFailed: "Сбой перемещения файлов!",
          folderAddedSuccessfully: "Добавить папку успешно!",
          folderRenameSucceeded: "Переименование папки прошло успешно!",
          fileRenameSucceeded: "Переименование файла прошло успешно!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "После удаления папки он не будет восстановлен. Вы подтверждаете удаление?",
          deleteSucceeded: "Удаление прошло успешно!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Файл не будет восстановлен после удаления. Подтверждаете ли вы удаление?",
          share: "Общие",
          folders: "Папка,",
          calls: "Звоните.",
          newFolder: "Добавлена папка",
          modifyFolder: "Изменение папки",
          title: "Заголовок",
          pleaseEnter: "Пожалуйста, введите",
          selectMoveLocation: "Выбор мобильного местоположения",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Напоминания об обучении",
          subscriptionDynamics: "Подписка на динамические",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "Сеанс, на который вы подписаны (ID)",
            thereAreDynamicUpdates: "Есть динамические обновления",
            newCommentsReplies: "Добавлены комментарии/ответы",
            strip: "Полоска",
            newHelp: "Добавлена помощь",
            goAndWatch: "Иди в толпа",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Ответил на мой отзыв",
            replySucceeded: "Ответ был успешным!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "В комментариях @ Я",
            replySucceeded: "Ответ был успешным!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Поделиться для вашего звонка",
            shareCasesForYou: "Делитесь случаем для вас",
          },
        },
        study: {
          index: {
            learned: "Учился",
            ignored: "Игнорируется",
            study: "Обучение",
            ignore: "Игнорировать",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Успешный импорт",
          account: "Номер счета",
          password: "Пароль",
          cover: "Покрытие",
          skip: "Пропустить",
          import: "Импорт",
          importEmployees: "Импорт сотрудников",
          importPrompt: "Советы по импорту",
          confirm: "Определить",
          theDataContainsDuplicateAccountsOrNames:
            "Наличие данных в учетной записи или дублирование имени",
          allCoverage: "Все покрыты",
          skipAll: "Пропустить все",
          headPortrait: "Аватар",
          fullName: "Имя",
          nickname: "Прозвище",
          excellentStaff: "Отличный персонал",
          yes: "Да, да.",
          no: "Нет",
          department: "Секторы",
          accountNumberemail: "Аккаунт (почтовый ящик)",
          telephone: "Телефон",
          jobNumber: "Номер работы",
          role: "Роль",
          pleaseEnterANameOrPhoneSearch:
            "Введите адрес электронной почты, имя или телефон для поиска",
          person: "Человек)",
          AddEmployee: "Добавление сотрудников",
        },
        edit: {
          jobNo: "Номер работы",
          udeskCustomerServiceSystemIdentity:
            "Идентификация системы обслуживания клиентов Udesk",
          enterpriseWechatIdentity: "Корпоративная идентификация WeChat",
          inputID: "Входные идентификационные данные",
          newIdentityBinding: "Добавлена привязка личности",
          identityBinding: "Привязка идентичности",
          avatarGreaterThan500kb: "Аватар больше, чем 500Kb",
          uploadSuccessful: "Успешная загрузка",
          upload: "Загрузка",
          supportJPGGIFAndPNGUpTo500kb:
            "Поддержка jpg, gif, png, максимум 500Kb",
          yes: "Да, да.",
          no: "Нет",
          accountNumber: "Номер счета",
          pleaseEnterTheAccountNumber: "Пожалуйста, введите номер счета",
          initialPassword: "Начальный пароль",
          pleaseInputAPassword: "Введите пароль",
          confirmPassword: "Подтверждение пароля",
          thePasswordsEnteredTwiceAreDifferent:
            "Пароли, введенные дважды, не совпадают!",
          headPortrait: "Аватар",
          fullName: "Имя",
          pleaseEnterYourName: "Введите имя",
          nickname: "Прозвище",
          department: "Секторы",
          excellentStaff: "Отличный персонал",
          sessionIdentity: "Идентификация сеанса",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Отметить запись сеанса, принадлежащую сотруднику",
          telephone: "Телефон",
          jobNumber: "Номер работы",
          role: "Роль",
          pleaseSelectARole: "Пожалуйста, выберите роль",
          preservation: "Сохранить",
          cancel: "Отмена",
          addedSuccessfully: "Добавить успех!",
          modificationSucceeded: "Модификация прошла успешно!",
          newEmployees: "Новые сотрудники",
          editEmployee: "Редактирование сотрудников",
        },
        detail: {
          viewDetails: "Посмотреть детали",
          accountNumber: "Номер счета",
          headPortrait: "Аватар",
          fullName: "Имя",
          nickname: "Прозвище",
          department: "Секторы",
          excellentStaff: "Отличный персонал",
          yes: "Да, да.",
          no: "Нет",
          sessionIdentity: "Идентификация сеанса",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Отметить запись сеанса, принадлежащую сотруднику",
          telephone: "Телефон",
          jobNumber: "Номер работы",
          role: "Роль",
        },
      },
      roleManage: {
        list: {
          menu: "Меню",
          functionalCategory: "Функциональная категория",
          functionalSubclass: "Функциональные категории",
          deletedSuccessfully: "Удаление прошло успешно",
          NewRole: "Новые роли",
          role: "Роль",
          dataPermissions: "Разрешения на данные:",
          edit: "Редактирование",
          areYouSureYouWantToDeleteTheRole: "Определение роли удаления",
          determine: "Определить",
          cancel: "Отмена",
          delete: "Удалить",
          applicationPermission: "Разрешения на применение",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Вы можете видеть только свои собственные данные сеанса",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Вы можете просматривать данные сеанса из нашего отдела и подчиненных отделов",
          menu: "Меню",
          functionalCategory: "Функциональная категория",
          functionalSubclass: "Функциональные категории",
          successfullyAdded: "Новый успех",
          modifiedSuccessfully: "Модификация прошла успешно",
          edit: "Редактирование",
          cancel: "Отмена",
          confirm: "Подтверждение",
          roleName: "Имя персонажа",
          pleaseEnterTheRoleName: "Введите имя роли",
          dataAuthority: "Разрешения данных",
          youCanViewCompanyWideSessionData:
            "Можно просматривать данные сеанса для всей компании",
          applicationPermission: "Разрешения на применение",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Процесс сеанса",
          generateProcess: "Процесс построения",
          addProcess: "Добавить процесс",
          cancel: "Отмена",
          complete: "Завершение",
          edit: "Редактирование",
          theSettingWillBeSuccessfulLater:
            "Установите позже, чтобы увидеть успех!",
          previous: "Предыдущий",
          next: "Следующий",
          debuggingMode: "Режим отладки",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Если сообщение после открытия соответствует критическим событиям, контрольные элементы, правила тегов клиентов или правила точек знаний, будет отображаться имя правила, совпадающей с сообщением, и имя точки знаний базы знаний.",
          seeLater: "Посмотри попозже.",
          determine: "Определить",
          conversationalInsights: "Разговорные идеи",
          customerInsight: "Понимание клиентов",
          knowledgePointRecommendation: "Рекомендации по знаниям",
          comment: "Комментарии",
          sessionQuality: "Качество сеанса",
          setToListenLaterSuccessfully: "Установите, чтобы слушать позже!",
          listenLater: "Послушайте позже.",
          seekCounselingSuccess: "Ищу коучинга для успеха!",
          altogether: "Общие",
          appearsAt: "Появляется",
          commentsPublishedSuccessfully: "Комментарии опубликованы успешно",
          callRecording: "Запись звонков",
          addedSuccessfully: "Добавить успех",
          shareWithColleagues: "Поделиться с коллегами",
          shareWithCustomers: "Поделиться с клиентами",
          resumptionDetails: "Подробности обзора",
          addToCaseBase: "Добавление в библиотеку случаев",
          shareCases: "Поделиться кейс",
          seekCounseling: "В поисках коучинга",
          sessionRecord: "Запись сеанса",
          businessRecord: "Бизнес-записи",
          keywordSearch: "Поиск по ключевым словам",
          whole: "Все.",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Пожалуйста, введите комментарий, введите @, чтобы уведомить других, введите #, чтобы добавить тему",
          subscriptionSession: "Подписка на сеанс",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "После подписки вы получите уведомление, когда появится новая помощь, комментарий, ответ",
        },
      },
      coachTask: {
        index: {
          employeeName: "Имя сотрудника",
          customerName: "Имя клиента",
          source: "Источник",
          viewSessionRecords: "Просмотр записей сеансов",
          coachingStatus: "Состояние коучинга",
          helpTime: "Время помощи",
          counselingTime: "Время репетиторства",
          helpContent: "Содержание помощи",
          coachingOperation: "Операция коучинга",
          areYouSureYouWantToIgnoreIt: "Определено игнорировать?",
          determine: "Определить",
          cancel: "Отмена",
          ignoreHelp: "Игнорировать помощь",
          coaching: "Консультирование",
          viewCoaching: "Просмотр коучинга",
          areYouSureToReturnToCounseling:
            "Вы уверены, чтобы вернуться к консультированию?",
          returnToPendingCoaching: "Восстановление до консультации",
          tutor: "Консультанты",
          helpSeekingStatus: "Статус помощи",
          recentCoachingTime: "Недавнее время репетиторства",
          operation: "Операция",
          ignoreSuccess: "Игнорировать успех!",
          recoverySucceeded: "Восстановление прошло успешно!",
          successfulCoaching: "Репетиторство прошло успешно!",
          myMission: "Моя миссия",
          selectCoachingStatus: "Выбор статуса коучинга",
          whole: "Все.",
          iAskedForHelp: "Я попросил помощи.",
          signOut: "Выход",
          submit: "Представление",
          helpPerson: "Спроси помощи",
          customer: "Клиенты",
          toBeCoached: "В ожидании консультирования",
          counselingSuggestions: "Рекомендации по консультированию",
          pleaseEnterCoachingSuggestions:
            "Пожалуйста, введите рекомендации по консультированию",
        },
      },
      coachTaskCenter: {
        title: "Центр миссий",
        list: {
          taskName: "Название задачи",
          lessonGroup: "Классификация курсов",
          lessonName: "К курсу",
          taskType: "Тип задачи",
          taskStatus: "Состояние задачи",
          taskExecutionInfo: "Осуществление",
          totalCount: "Число подлежащих выполнению",
          executeCount: "Количество выполненных",
          executeRate: "Показатели осуществления",
          completeCount: "Количество завершенных",
          completeRate: "Уровень завершения",
          commitCount: "Количество людей, которые соответствуют стандартам",
          commitRate: "Коэффициент соответствия",
          excellentRate: "Отлично ставка",
          taskTime: "Время выполнения задачи",
          option: "Операция",
          edit: "Редактирование",
          taskDetail: "Данные задачи",
          delete: "Удалить",
          new: "Новый выпуск",
          taskTypeAll: "Все.",
          taskTypePractice: "Упражнения",
          taskTypeTest: "Экзамен",
          taskStatusNotStarted: "Не начал",
          taskStatusRunning: "В процессе",
          taskStatusEnd: "Заканчено.",
          taskStatusInvalid: "Не вступил в силу",
          unlimitedTime: "Неограниченное время",
          to: "До",
          deleteConfirm:
            "После удаления все учебные записи по этой задаче будут очищены. Подтверждено ли удаление?",
          deleteSuccess: "Удаление задачи успешно",
        },
        edit: {
          addTask: "Новые задачи выпуска",
          editTask: "Редактирование задач публикации",
          publishSuccess: "Успешная публикация",
          publishFailed: "Провал релиза",
          updateSuccess: "Обновление прошло успешно",
          updateFailed: "Сбой обновления",
          cannotBeNull: "Не может быть пустым",
          explain: "Описание",
          taskDescExtra: "(Содержание будет отображаться на входе {0})",
          taskTargetObj: "Объект публикации",
          isEnable: "Вступивший в силу статус",
          dialogueMode: "Режим диалога",
          voice: "Голос",
          text: "Текст",
          userPortrait: "Портрет пользователя",
          difficultyLevel: "Сложность",
          limitDuration: "Ограничение длительной длины",
          tipLabel: "Слововой совет",
          tipTooltip: "Разрешить ли учащимся просматривать справочную речь",
          limitTimes: "Количество экзаменов",
          time: "Время",
          gradingStandart: "Критерии оценки",
          gradingStandartDescAffix: "(Общий балл текущего процесса диалога",
          gradingStandartDescSuffix:
            "Точки, слова {0}, эмоции {1}, скорость речи {2})",
          completeStandart: "Требования к выполнению задачи",
          byTimes: "По количеству раз",
          byScore: "По очкам",
          completeByTimes: "Количество упражнений",
          times: "Раз",
          score: "Очки",
          anyTimes: "В любой раз",
          getScore: "Счет",
          offLimitDuration: "Неограниченная продолжительность",
          onLimitDuration: "Ограниченная продолжительность времени",
          commitScore: "Стандарт, оценка",
          excellentScore: "Отличный критерий, оценка",
          offLimitTime: "Неограниченное время",
          onLimitTime: "Ограниченное время",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Привет," },
          callDynamic: {
            today: "Сегодня",
            yesterday: "Вчера",
            sessions: "Сеанс",
          },
          todoList: {
            strip: "Полоска",
            theSessionIsAddedTolistenLater:
              'Сеанс был добавлен "слушать позже"',
            askYouForHelp: "Обратитесь за помощью.",
            iHopeToContactYouLater: "Ожидайте связаться позже",
            lookingForwardToSendingTheInformationLater:
              "Ожидайте отправить информацию позже",
            dialogueRisk: "Есть точки риска для сеанса",
            tutored: "Консультирование",
            goToCounseling: "Пойдем на репетиторство",
            contacted: "Связались",
            hasBeenSent: "Отправлено",
            areYouSureYouWantToIgnoreIt: "Определено игнорировать?",
            determine: "Определить",
            cancel: "Отмена",
            ignore: "Игнорировать",
            checked: "Просмотрено",
          },
          callListItem: {
            strip: "Полоска",
            learned: "Учился",
            keyEvents: "Ключевые события",
            totalImplementationRateOfSupervisionItems:
              "Общий показатель осуществления надзора",
          },
        },
        index: {
          operationSucceeded: "Операция прошла успешно!",
          sessionDynamics: "Динамика сеанса",
          my: "Мой.",
          department: "Секторы",
          company: "Компания",
          toDoList: "Доска дел",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Эффективный объем вызовов",
          bestPractices: "Лучшие практики",
          averageDurationOfASingleCallminutes:
            "Средняя продолжительность одного разговора (минуты)",
          totalCallVolumePCs: "Общий объем звонков (один)",
          totalTalkTimehours: "Общая продолжительность разговора (час)",
          speakingPercentagepercentage: "Доля речи (в процентах)",
          maximumContinuousSpeakingTimeminutes:
            "Максимальная длительность разговора (минуты)",
          numberOfIndepthDiscussionsPCs: "Количество углубленных обсуждений",
          maximumCustomerTalkminutes: "Самая длинная речь клиента (минуты)",
          questionFrequencyPCs: "Частота вопросов (некоторые)",
          averageValue: "Среднее значение",
          selectDate: "Выбор даты",
          personnel: "Персонал",
          pleaseSelect: "Пожалуйста, выберите",
          callRange: "Диапазон звонков",
          conversationActivity: "Сеансовая деятельность",
          conversationAction: "Сеансовые действия",
          keyEvents: "Ключевые события",
          callRecord: "Запись звонков",
          filter: "Скрининг:",
          conversation: "Звонки",
          minute: "Минута.",
          sale: "Сотрудники",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Январь",
            numberOfCalls: "Количество звонков",
            accountInformation: "Информация о счете",
            month: "Месяц",
            today: "Сегодня",
            customerDetails: "Информация о клиенте",
            searchKeywordsInCommunicationContent:
              "Поиск ключевых слов в контенте связи",
            allMembers: "Все члены",
            reset: "Сброс",
            query: "Запрос",
            allTrip: "Полный показ путешествия",
            contactOverview: "Обзор контактов",
            lastContact: "Последний контакт",
            nextContact: "Следующий контакт",
            previous: "Предыдущий",
            next: "Следующий",
            viewDetails: "Посмотреть детали",
            contactDynamics: "Контактная динамика",
            lastYear: "Последний год",
            lastCall: "Последний звонок",
            hotTopicsForDiscussion: "Горячие темы для обсуждения",
            totalInTheLastYear: "Всего за последний год",
            callTimes: "Второй звонок",
          },
        },
        list: {
          index: {
            risk: "Риски",
            have: "Есть.",
            nothing: "Нет.",
            channel: "Каналы",
            callRecording: "Запись звонков",
            enterpriseWeChatConversation: "Корпоративный микро-разговор",
            enterpriseWeChatCall: "Enterprise WeChat",
            followUpSuggestions: "Рекомендации по последующей деятельности",
            customer: "Центр обслуживания клиентов",
            followUp: "Последующие действия",
            personInCharge: "Ответственный",
            recentFollowup: "Последние последующие лица",
            lastContactTime: "Время последнего контакта",
            operation: "Операция",
            see: "Посмотреть",
            exceed: "Более",
            noActivityForMoreThanDays: "День без активности",
            lately: "Недавно",
            risksOccurredInTheLastDays: "Семантика риска в течение дней",
            savedSuccessfully: "Успешное сохранение",
            today: "Сегодня",
            allDepartments: "Все сектора",
            allMembers: "Все члены",
            customerName: "Название клиента",
            reset: "Сброс",
            query: "Запрос",
            customers: "Клиент",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Показан контакт между сотрудниками и клиентами, из которых розовый пузырь-это сообщение клиента, а синий-сообщение сотрудника. Чем больше доля речи, тем больше пузырь",
            riskAllocation: "Конфигурация риска",
            preservation: "Сохранить",
            pleaseEnter: "Пожалуйста, введите",
            noActivityForDays: "День без активности",
            riskSemanticsWithinDays: "Семантика риска в течение дней",
            addRiskSemantics: "Добавить семантику риска",
            semanticTags: "Семантические теги:",
            standardSentence: "Стандартное предложение:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "Добавить новые условия" } },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Все следующие условия выполнены",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Фильтрация",
                pleaseSelectFilteringCriteria: "Выберите условия скрининга",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Изменение стадии бизнеса",
              theCurrentStageIs: "Текущий этап [",
              ConfirmToChangeItTo: "], Определите, чтобы изменить его на [",
              IsIt: "] Это?",
              StageCoStay: "] Стадия совместного пребывания",
              day: "Боже!",
              start: "Начать",
              end: "Конец.",
              orderInput: "Проигрыш",
              restart: "Перезапуск",
              pleaseConfigureTheSalesStageInformation:
                "Настройте информацию о стадии продаж",
              reasonForDocumentInput: "Причина потери заказа:",
              completeOrderInput: "Завершение заказа на потерю",
              pleaseSelectTheReason: "Пожалуйста, выберите причину",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "Менеджеры рекомендуют:",
              },
              index: {
                sessionDetails: "Сведения о сеансе",
                customerDynamics: "Динамика клиентов",
                riskPoint: "Точка риска",
                followUpSuggestions: "Рекомендации по последующей деятельности",
              },
              sessionDetails: {
                common: "Всего",
                currentlyViewingThe: "В настоящее время см.",
                sessions: "Сеанс",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Текущий этап остался",
              day: "Боже!",
            },
          },
          business: {
            index: {
              reEnableOpportunity:
                "Повторное использование возможностей для бизнеса",
            },
          },
          customerTag: { index: { label: "Этикетки" } },
          moreCustomer: {
            index: {
              essentialInformation: "Основная информация",
              cancel: "Отмена",
              confirm: "Подтверждение",
              customer: "Клиенты",
              currentFollowupStage: "Текущий этап отслеживания",
              telephone: "Телефон",
              followUpDays: "Количество дней наблюдения",
              day: "Боже!",
              remarks: "Примечания",
              channelPreference: "Канальные предпочтения",
              hello: "Здравствуйте.",
              customFields: "Пользовательские поля",
              opportunityAmount: "Количество возможностей для бизнеса",
              focus: "Внимание",
              customerInformation: "Информация о клиенте",
              viewMoreCustomerInformation:
                "Посмотреть больше информации о клиентах",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Ключевые события:" },
                row1: {
                  customerDynamics: "Динамика клиентов",
                  common: "Всего",
                  barDynamic: "Динамика полосы",
                },
                row2: {
                  riskPoint: "Точка риска",
                  common: "Всего",
                  risks: "Полоса риска",
                },
                row3: {
                  followUpSuggestions:
                    "Рекомендации по последующей деятельности",
                  intelligentRecommendation: "Умные рекомендации",
                  strip: "Полоска",
                  managersSuggestATotalOf: "Менеджеры рекомендуют",
                  recommendedByManagers: "Рекомендация менеджера",
                  creationTime: "Время создания",
                  administratorSuggestions: "Рекомендации администратора",
                  suggestionsForNewManagers: "Новое предложение менеджера",
                },
              },
            },
            index: { typeTime: "Тип/время" },
          },
          userInfo: {
            index: {
              personInCharge: "Ответственный",
              recentFollower: "Последние последующие лица",
              startTime: "Первое время касания",
              followUpDays: "Количество дней наблюдения",
              day: "Боже!",
              remarks: "Примечания",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Члены команды",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Участники изучают общее количество сеансов других людей (см.",
          totalNumberOfStudies: "Общее количество обучения",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Общее количество комментариев и ответов участников на чужие сеансы (без веса)",
          totalNumberOfInteractions: "Общее количество взаимодействий",
          learnTheSumOfConversationCallDuration:
            "Обучайте время разговора, чтобы суммить время разговора",
          lengthOfStudy: "Продолжительность обучения",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Общее количество участников, которые инициировали обращение за помощью в разговоре другого человека (до веса)",
          totalNumberOfRequests: "Общее число обращений за помощью",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Общее количество членов, которые консультирует по поводу помощи других лиц (до весов)",
          totalCounseling: "Общее количество коучинга",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Общее количество сеансов участников для других людей, добавленных в библиотеку дел (Do-To-To)",
          addCase: "Добавить случай",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Общее количество подписок участников на сеансы других лиц (удалено)",
          subscribe: "Подписка",
          totalNumberOfSessionsCommunicatedByMembers:
            "Общее количество сеансов, с которым общаются участники",
          totalSessions: "Общее количество сеансов",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Участники повторно проверили общее количество своих сеансов (удалили их), ознакомьтесь с деталями сеанса и нажмите, чтобы воспроизвести запись, считается, что он был просмотрен один раз",
          totalNumberOfDoubleDisk: "Общее количество восстановленных",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Общее количество дисков/общее количество сеансов * 100%",
          proportionOfDoubleDisk: "Доля повторного диска",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Длительное время разговора",
          resumptionDuration: "Длительное время повторного рассмотрения",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Общее количество членов, которые инициировали свои собственные сеансы помощи (чтобы перейти к весу)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Общее количество комментариев (включая ответы), полученных участниками сессий",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Общее количество сеансов участников, добавленных другими в библиотеку дел (Do-To-To)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Общее количество сеансов участников, подписанных другими лицами (в пересчете)",
          askForHelpInTheLastMonth: "Последний месяц помощи",
          lastTimeForHelp: "Время последней помощи",
          operation: "Операция",
          viewAllSessionsInTheLastMonth:
            "Посмотреть все сеансы за последний месяц",
          allDepartments: "Все сектора",
          reset: "Сброс",
          query: "Запрос",
          altogether: "Всего",
          secondSession: "Второй сеанс",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Текущий тестовый проект:",
            supervisionItem: "Элемент надзора",
            keyEvents: "Ключевые события",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Отметьте контрольные элементы или ключевые события, которые вы хотите протестировать, и убедитесь, что в моделируемой беседе есть содержимое вызова",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Анализ исполнения" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Процесс 1",
              technologicalProcess: "Процесс",
              newProcess: "Процесс создания нового здания",
            },
          },
          index: {
            upload: "Загрузка",
            preservation: "Сохранить",
            newlyBuild: "Новое строительство",
            cancel: "Отмена",
            name: "Название",
            describe: "Описание",
            type: "Тип",
            classification: "Классификация",
            content: "Содержание",
            enclosure: "Приложение",
            knowledgePointAssociation: "Связь точек знаний",
            open: "Открыть.",
            close: "Закрыть",
            operator: "Оператор",
            logic: "Логика",
            see: "Посмотреть",
            edit: "Редактирование",
          },
        },
        index: {
          name: "Название",
          describe: "Описание",
          createdBy: "Создатель",
          type: "Тип",
          lastUpdated: "Время последнего обновления",
          numberOfReferences: "Количество ссылок",
          operation: "Операция",
          see: "Посмотреть",
          edit: "Редактирование",
          delete: "Удалить",
          categoryDeletedSuccessfully: "Удаление классификации успешно",
          classificationOfKnowledgePoints: "Классификация точек знаний",
          newlyBuild: "Новое строительство",
          knowledgeBase: "База знаний",
          knowledgePoints: "Точки знаний",
          technologicalProcess: "Процесс",
          keyword: "Ключевые слова",
          batchExport: "Пакетный экспорт",
          batchDelete: "Пакетное удаление",
          importProcess: "Процесс импорта",
          importKnowledgePoints: "Импорт точек знаний",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Добавляйте точки знаний напрямую, если они не существуют в базе знаний, и пропускайте, если они существуют, без обновления",
          editKnowledgePointClassification:
            "Редактирование классификации баллов знаний",
          newKnowledgePointClassification: "Новая классификация точек знаний",
          categoryEditingSucceeded: "Успешное редактирование классификации",
          newClassificationSucceeded: "Новый успех классификации",
          classificationName: "Название классификации",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Исполнительный надзор" },
      },
      topicCallout: {
        index: {
          whole: "Все.",
          date: "Дата",
          dimensionStatus: "Состояние маркировки",
          callRecordID: "Идентификатор записи звонков",
          callRecordIDMustBeNumeric:
            "Идентификатор записи вызовов должен быть цифровым",
          strip: "Полоска",
          topicAnnotation: "Тематические аннотации",
          annotated: "Помечено",
          stripNotMarked: "Статья, не отмечена",
          barInDimension: "Статья, отмечена",
          customer: "Клиенты",
          sale: "Продажа",
          common: "Всего",
          sessions: "Сессия полосы",
          currentTopic: "Текущие темы:",
          staff: "Сотрудники",
          topicOfConversation: "Тема",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Операция прошла успешно, маркировка сброшена",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Все записи аннотации этого сеанса будут очищены после сброса, пожалуйста, подтвердите",
          resetAll: "Сброс всех",
          pleaseSelectTheTopicToBeMarked: "Выберите тему для маркировки",
          pleaseTickTheSentenceToBeMarked:
            "Пожалуйста, отметьте предложение, которое вы хотите отметить",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Операция прошла успешно, маркировка вступила в силу",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Диапазон, отмеченный на этот раз, содержит сообщения по другим темам, щелчок ОК будет перезаписан",
          confirmDimensions: "Подтверждение маркировки",
          talkTime: "Время разговора",
          callID: "Вызов id",
        },
      },
      topicList: {
        index: {
          callID: "Идентификатор вызова",
          latestMarkedTime: "Последнее время маркировки",
          topicOfConversation: "Тема",
          numberOfSentences: "Количество предложений",
          sentence: "Предложение",
          operation: "Операция",
          see: "Посмотреть",
          delete: "Удалить",
          edit: "Редактирование",
          newSubTopic: "Новая подтема",
          move: "Мобильный",
          whole: "Все.",
          topicDeletedSuccessfully: "Удаление темы успешно",
          newlyBuild: "Новое строительство",
          topicList: "Список тем",
          callIDMustBeNumeric: "Идентификатор вызова должен быть цифровым",
          leadInTopic: "Импорт темы",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Когда название классификации повторяется, исходная классификация тем не будет перезаписана, пункт будет пропущен напрямую и не будет импортирован",
          mobileClassification: "Мобильная классификация",
          editTopic: "Редактирование темы",
          newTopic: "Новые темы",
          topicName: "Название темы",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Модификация прошла успешно",
            createdSuccessfully: "Создание успеха",
            modificationFailed: "Сбой модификации",
            creationFailed: "Сбой создания",
            monitoringName: "Название мониторинга",
            upToCharacters: "Максимум 40 символов",
            pleaseEnterAName: "Введите имя",
            monitoringIndicators: "Показатели мониторинга",
            sessionActivity: "Сеансовая деятельность",
            conversationAction: "Сеансовые действия",
            keyEvents: "Ключевые события",
            supervisionItems: "Элемент надзора",
            monitoringMembers: "Члены по наблюдению",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Группы, в которых проходят инструктаж, обучение или управленческие мероприятия",
            groupA: "Группа А",
            pleaseSelectGroupAData: "Выберите группу данных A",
            thereAreDuplicateUsersInGroupB:
              "Есть дубликаты с пользователями в группе B",
            groupARemarks: "Замечания группы А",
            controlGroupGroupComparedWithGroupA:
              "Контрольная группа по сравнению с группой А",
            groupB: "Группа B",
            pleaseSelectGroupBData: "Выберите данные группы B",
            thereAreDuplicateUsersInGroupA:
              "Есть дубликаты с пользователями в группе A",
            groupBRemarks: "Замечания группы B",
            monitoringPeriod: "Цикл мониторинга",
            startNode: "Начало узла",
            monitoringScope: "Диапазон мониторинга",
            pleaseSelectMonitoringConditions:
              "Пожалуйста, выберите условия мониторинга",
            pleaseEnterANumber: "Введите номер",
            pleaseSelectATimePeriod: "Выберите период времени",
            preservation: "Сохранить",
            cancel: "Отмена",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Удаление прошло успешно",
            notStarted: "Не начал",
            haveInHand: "В процессе",
            closed: "Заканчено.",
            newGroupTest: "Новое групповое тестирование",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Этап продаж",
        communicationStrategy1: "Коммуникационные стратегии",
        riskAllocation: "Конфигурация риска",
        businessConfiguration: "Конфигурация бизнеса",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Обработка возражений",
                  productSellingPointScript: "Точка продажи продукта",
                  caseGrouping: "Тематические группы",
                  pleaseSelectExcellentCases:
                    "Пожалуйста, выберите отличный случай",
                  pleaseSelectKnowledgePoints: "Выберите пункт знаний",
                  associativeLinks: "Связанные ссылки",
                  jumpTo: "Перейти к",
                  jumpType: "Тип прыжка",
                  knowledgePoints: "Точки знаний",
                  excellentCases: "Отличный случай",
                  excellentScript: "Отличная речь",
                  pleaseSelectASingleKnowledgePoint:
                    "Выберите одну точку знаний",
                },
              },
            },
            index: {
              name: "Название",
              pushText: "Push текст",
              enableStatus: "Состояние включения",
              triggerRules: "Правила запуска",
              communicationStrategy: "Коммуникационные стратегии",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "Группа стратегий коммуникации",
              name: "Название",
              pushText: "Push текст",
              effectiveness: "Эффективность",
              openSuccessfully: "Открыть успех",
              closeSuccessfully: "Закрытие прошло успешно",
              operationFailed: "Сбой операции",
              operation: "Операция",
              edit: "Редактирование",
              delete: "Удалить",
              newlyBuild: "Новое строительство",
              communicationStrategy: "Коммуникационные стратегии",
              editGroup: "Редактирование группировки",
              groupName: "Название группы",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Все сеансы",
                  recentDays: "Последние дни",
                  recentSessions: "Последние сеансы",
                  scopeOfApplication: "Сфера применения:",
                  day: "Боже!",
                  through: "Через",
                },
              },
            },
            index: {
              describe: "Описание",
              applicableCustomers: "Применимые клиенты",
              enableStatus: "Состояние включения",
              triggerRules: "Правила запуска",
              riskRules: "Правила риска",
            },
          },
          list: {
            index: {
              riskAllocation: "Конфигурация риска",
              keyWord: "Ключевые слова",
              newRiskRule: "Новые правила риска",
              riskRuleName: "Название правила риска",
              describe: "Описание",
              state: "Состояние",
              openSuccessfully: "Открыть успех",
              closeSuccessfully: "Закрытие прошло успешно",
              operationFailed: "Сбой операции",
              operation: "Операция",
              edit: "Редактирование",
              delete: "Удалить",
              deletionSucceeded: "Удаление прошло успешно",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Проигрыш" },
              endReasonList: {
                addEndReason: "Добавить причину окончания",
                enterEndReason: "Причина окончания ввода",
              },
              stageList: {
                keyEvents: "Ключевые события",
                waysideData: "Данные по дороге",
                includeAny: "Содержит произвольные",
                includeAll: "Содержит все",
                pleaseEnterTheStageName: "Введите название этапа",
                setStageRules: "Установить правила этапа",
                stageName: "Название этапа",
                operator: "Оператор",
                logic: "Логика",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Формат ввода поля типа времени: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Сбой запроса, попробуйте позже",
              salesStage: "Этап продаж",
              essentialInformation: "Основная информация",
              name: "Название",
              applicableDepartments: "Применимый сектор",
              applicableToOthers: "Применимо к другим",
              manualChangePhase: "Этап изменения вручную",
              enableStatus: "Состояние включения",
              describe: "Описание",
              opportunityStageSettings: "Настройка этапа бизнес-возможностей",
              stageSettings: "Настройка этапа",
              aliasSettings: "Настройки псевдонима",
              closeOpportunity: "Конец бизнеса",
              newSuccessfully: "Новый успех",
              newFailed: "Новое строительство не удалось",
              savingSucceeded: "Успешное сохранение",
              saveFailed: "Сбой сохранения",
            },
          },
          list: {
            index: {
              salesStage: "Этап продаж",
              keyWord: "Ключевые слова",
              newSalesStage: "Новый этап продаж",
              salesStageName: "Название этапа продаж",
              describe: "Описание",
              state: "Состояние",
              openSuccessfully: "Открыть успех",
              closeSuccessfully: "Закрытие прошло успешно",
              operationFailed: "Сбой операции",
              operation: "Операция",
              edit: "Редактирование",
              delete: "Удалить",
              deletionSucceeded: "Удаление прошло успешно",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Генерация больших моделей",
            conversationInsights: "Разговорные идеи",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Автоматическая генерация" },
              },
              hooks: {
                conversationSummary: "Резюме сеанса",
                conversationProcess: "Процесс сеанса",
                opportunityPoints: "Точка шанса",
              },
              prompt: {
                index: {
                  edit: "Редактирование",
                  restoreDefault: "Восстановление по умолчанию",
                  cancel: "Отмена",
                  confirm: "Подтверждение",
                },
              },
            },
            index: {
              saveSuccessful: "Успешное сохранение",
              hintLanguage: "Направление",
              preserve: "Сохранить",
              cancel: "Отмена",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Генерация больших моделей",
        salesStage: "Этап продаж",
        communicationStrategy: "Коммуникационные стратегии",
        riskAllocation: "Конфигурация риска",
        businessConfiguration: "Конфигурация бизнеса",
      },
      salesLearningCenter: {
        knowledgeBase: "База знаний",
        speechCorpus: "Библиотека речи",
        learningCenter: "Учебный центр",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Информация о клиенте" },
          },
        },
        index: { otherInformation: "Дополнительная информация" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Импорт" } } },
        hooks: {
          index: {
            all: "Все.",
            intelligentModel: "Интеллектуальная модель",
            smartRules: "Интеллектуальные правила",
            manualScoring: "Ручная оценка",
            enable: "Включение",
            notEnabled: "Не включен",
            voiceCall: "Голосовой вызов",
            textDialogue: "Текстовый диалог",
            ecommerceTextSession: "Текстовая сессия для электронной коммерции",
            workOrder: "Наработки",
          },
        },
        index: {
          ruleName: "Название правила",
          ruleType: "Типы правил",
          pleaseSelect: "Пожалуйста, выберите",
          ruleStatus: "Статус правила",
          dataSourceType: "Тип источника данных",
          voiceCall: "Голосовой вызов",
          textDialogue: "Текстовый диалог",
          ecommerceTextSession: "Текстовая сессия для электронной коммерции",
          workOrder: "Наработки",
          status: "Состояние",
          operation: "Операция",
          edit: "Редактирование",
          ruleTemplate: "Шаблон правил",
        },
      },
      analysisReportTitle: "Аналитические отчеты",
      ruleTest: "Тестирование правил",
      intelligentTags: {
        labelDisplayQuantity: "Количество отображаемых меток",
        unlimited: "Не ограничиваясь",
        ignoringTagLibraries: "Игнорирование библиотеки тегов",
        automaticallyGenerateLabels: "Автоматическое создание меток",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "После открытия теги автоматически генерируются для сеанса на основе большой модели, и сгенерированные теги по умолчанию классифицируются как «неклассифицированные»",
        defaultValidity: "Действительность по умолчанию",
        supervisionItem: "Элемент надзора",
        customerLabel: "Теги клиентов",
        newSupervisionItemRule: "Новые правила для элементов надзора",
        editSupervisionItemRule: "Правила для элементов редакционного контроля",
        newCustomerLabel: "Новые теги клиентов",
        editCustomerLabel: "Редактирование тегов клиентов",
        newSessionLabel: "Новые теги сеанса",
        editSessionLabel: "Редактирование тегов сеанса",
        newKeyEvent: "Новые ключевые события",
        editKeyEvents: "Редактирование ключевых событий",
        detail: {
          template: { nameIsARequiredField: "Имя является обязательным полем" },
          formItemComponent: {
            largeModelExtensionLabel: "Большая модель расширения метки",
            sessionRecordID: "ID записи сеанса",
            sessionTime: "Время сеанса",
            smartTags: "Умные этикетки",
            viewDetails: "Посмотреть детали",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Данные, записанные в этом сеансе, будут очищены после удаления. Подтвердите ли вы удаление?",
            tagData: "Маркировка данных",
            batchDeletion: "Пакетное удаление",
          },
        },
        template: {
          advancedFiltering: "Расширенный скрининг",
          dataType: "Типы данных",
          filterCriteria: "Условия скрининга",
          edit: "Редактирование",
          createANewSubcategory: "Новая подклассификация",
          delete: "Удалить",
          nameLengthCannotExceed: "Длина имени не может превышать 40",
        },
        component: {
          modelExpansion: "Расширение модели",
          enable: "Включение",
          notEnabled: "Не включен",
          standardScreening: "Стандартный скрининг",
          advancedFiltering: "Расширенный скрининг",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Ключевые слова",
              sessionLabel: "Этикетка сессии",
              keyEvents: "Ключевые события",
              matchAny: "Матч произвольный",
              matchAll: "Матч все",
              beEqualTo: "Равно",
              includeAll: "Содержит все",
              includeAny: "Содержит произвольные",
              advancedConditions: "Премиум-условия",
              smartTag: "Умные этикетки",
              smartTagI: "Умная этикетка один",
              smartTagII: "Интеллектуальная этикетка II",
            },
            template: {
              pleaseSelectSmartTag: "Пожалуйста, выберите смарт-этикетку",
              pleaseSelectASessionLabel: "Выберите теги сеанса",
              pleaseSelectAKeyEvent: "Выберите ключевые события",
              pleaseSelectAdvancedCondition:
                "Пожалуйста, выберите расширенные условия",
              newAdvancedFilter: "Новый расширенный скрининг",
              moveUp: "Вниз вверх",
              moveDown: "Сниз",
              moveLeft: "Двигаться влево",
              shiftRight: "Сдвиг вправо",
            },
          },
          component: {
            businessAnalysispng: "Бизнес-анализ. png",
            savedSuccessfully: "Успешное сохранение",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Постоянно действителен",
            permanentlyInvalid: "Неэффективно навсегда",
            customValidity: "Пользовательский срок действия",
            termOfValidity: "Срок действия",
            name: "Название",
            explain: "Описание",
            state: "Состояние",
            classification: "Классификация",
            smartRecommendation: "Умные рекомендации",
            preconditions: "Предварительные условия",
            SetPreconditionsForThisSupervisionItem:
              "1. Установить предварительные условия для пункта контроля в этой статье:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              "Когда предварительные условия выполнены, это «элемент надзора должен быть выполнен», и элемент надзора проверен;",
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Если предварительные условия не соблюдены, элемент надзора не проверен;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Предварительные условия не установлены, то есть все сеансы обнаруживаются по умолчанию",
            have: "Есть.",
            nothing: "Нет.",
            preconditionRule: "Правила предварительных условий",
            preconditionLogic: "Логика предварительных условий",
          },
          recommendation: {
            caseGrouping: "Тематические группы",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Когда сотрудники не на месте, вы можете разумно рекомендовать отличные речи и отличные случаи",
            smartRecommendation: "Умные рекомендации",
            open: "Открыть.",
            close: "Закрыть",
            whenThisSupervisionItem: "Когда этот элемент мониторинга",
            implement: "Осуществление",
            unexecuted: "Не выполнено",
            when: "Когда:",
            recommendedKnowledgePoints: "Рекомендуемые очки знаний",
            caseRecommendation: "Рекомендуемый случай",
          },
        },
        index: {
          determine: "Определить",
          sort: "Сортировка",
          sortSucceeded: "Сортировка успешно",
          supervisionItemClassification: "Классификация контрольных элементов",
          pressEnterToCreateANew:
            "Нажмите кнопку возврата, чтобы построить новый",
          nameCannotBeEmpty: "Имя не может быть пустым",
          newlyBuild: "Новое строительство",
          name: "Название",
          explain: "Описание",
          validStatus: "Эффективное состояние",
          permanentlyValid: "Постоянно действителен",
          permanentlyInvalid: "Неэффективно навсегда",
          operation: "Операция",
          edit: "Редактирование",
          batchDelete: "Пакетное удаление",
          newSupervisionItem: "Новый проект надзора",
          keyWord: "Ключевые слова",
          delete: "Удалить",
          deleteSucceeded: "Удаление прошло успешно",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Данные не будут восстановлены после удаления. Подтверждено ли удаление?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Умные этикетки",
              beEqualTo: "Равно",
              includeAll: "Содержит все",
              includeAny: "Содержит произвольные",
              matchAny: "Матч произвольный",
              matchAll: "Матч все",
              smartTagI: "Умная этикетка один",
              smartTagII: "Интеллектуальная этикетка II",
            },
            template: {
              pleaseSelectASmartTag: "Пожалуйста, выберите смарт-этикетку",
              pleaseSelectAdvancedConditions:
                "Пожалуйста, выберите расширенные условия",
              newAdvancedFilter: "Новый расширенный скрининг",
              rename: "Переименование",
              moveUp: "Вниз вверх",
              moveDown: "Сниз",
              shiftLeft: "Двигаться влево",
              shiftRight: "Сдвиг вправо",
            },
          },
        },
        template: {
          delete: "Удалить",
          labelFiltering: "Фильтр этикеток:",
          satisfyAny: "Удовлетворить произвольное",
          meetAll: "Удовлетворить все",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Количество диалогов по меткам категории второго уровня ≥ классификация первого уровня, один сеанс может содержать несколько тегов",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Расчетная логика изменения: (количество диалогов в течение выбранного периода времени-количество диалогов в течение предыдущего периода времени рядом с ним)/количество диалогов в течение предыдущего периода времени",
          proportionOfPrimaryClassification:
            "Доля классификации первого уровня",
          saveAs: "Сохранить как",
          cover: "Покрытие",
          preservation: "Сохранить",
          qualityInspectionTask: "Аналитические задачи:",
          query: "Запрос",
          changeFromMonthToMonth: "Изменение по сравнению с предыдущим месяцем",
          tagRanking: "Рейтинги тегов",
          proportion: "Доля",
        },
        component: {
          numberOfConversations: "Количество диалогов",
          operation: "Операция",
          businessAnalysispng: "Бизнес-анализ. png",
          savingSucceeded: "Успешное сохранение",
          deletionSucceeded: "Удаление прошло успешно",
          sequence: "Последовательность",
          firstLevelClassification: "Классификация первого уровня",
          ringRatioChange: "Изменение по сравнению с предыдущим месяцем",
          classClassification: "Классификация уровня",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Подтвердите удаление документа и его извлечение?",
            addKnowledgeBase: "Добавление базы знаний",
            uploadDocument: "Загрузка документов",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Поддержка файлов формата Word, pdf, ppt, excel, txt",
            taskName: "Название задачи",
            extractConversationDate: "Извлечение даты разговора",
            pleaseSelectAConversationDate: "Выберите дату разговора",
            dataSource: "Источник данных",
            dataType: "Типы данных",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Успешный экспорт",
              problem: "Вопрос",
              answer: "Ответ",
              addToKnowledgeBase: "Добавить в базу знаний",
              pleaseConfirmWhetherToDeleteIt:
                "Пожалуйста, определите, удалить ли",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Добавить в базу знаний",
              selectClassification: "Выбор классификации",
              pleaseSelectAKnowledgeBaseClassification:
                "Выберите Классификацию базы знаний",
            },
          },
        },
        index: {
          intelligentExtraction: "Интеллектуальное извлечение",
          documentExtraction: "Извлечение документов",
          dialogueExtraction: "Диалог извлекается",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Операция прошла успешно",
          labelIgnored: "Игнорирование тегов",
          add: "Добавить",
          areYouSureToDeleteIt: "Определились с удалением?",
          batchFilterLabels: "Пакетная фильтрация этикетки",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Интеллектуальная проверка качества",
        savedSuccessfully: "Успешное сохранение",
        logoSettings: "Настройка логотипа",
        default: "По умолчанию",
        custom: "Пользовательские",
        title: "Заголовок",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Название заголовка, отображенное системой, рекомендуется в пределах 6 слов.",
        logoImageUpload: "Загрузка фотографий с логотипом",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Пожалуйста, выберите фотографии, размер которых не превышает 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Чтобы обеспечить эффект просмотра логотипа, загрузить фотографии рекомендуется:",
        Size5050Pixels: "1. Размер: 50*50 пикселей;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Формат: прозрачный фон для изображения, png или jpg, графический логотип использовать белый",
        browserLabelIcon: "Значок вкладки браузера",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Чтобы обеспечить эффект просмотра тегов, загрузить фотографии рекомендуется:",
        Size3232Pixels: "1. Размер: 32*32 пикселей;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Формат: прозрачный фон для изображения, png или jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Проверка качества продолжается, пожалуйста, позже...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "В 2015 году недвижимость в Китае достигла критического момента для выживания: большое количество домов осталось без присмотра, и люди не хотели покупать их. Ханчжоу однажды упал ниже 8000, и некоторые разработчики разбили рынок для отгрузки. В то время Шэньчжэнь все еще консолидировался около 2w-3w, и дома в городах первого уровня также были в опасности.",
        xiaoAn: "Сяо Ан",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload Эти барьеры памяти называются барьерами памяти jsr. Они находятся на уровне jvm. Существует два способа реализации в нижней части операционной системы: 1-это шина блокировки, а второй-барьер памяти, такой как sfance (барьер записи). (Барьер чтения). В нижней части виртуальной машины hotspots используется оператор ассемблера: lock addl,lock-пустая операция, то есть шина блокировки, потому что некоторые операционные системы не поддерживают барьер памяти.",
        xiaobei: "Сяобэй",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Попытайтесь мариновать мясо несколько раз, и блюда, обжаренные после добавления, мясо похоже на мясо, кислый запах! С тех пор маринованное мясо не будет приготовление вина",
        xiaodong: "Сяодун",
        xiaoxi: "Сяоси",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Попытайтесь мариновать мясо несколько раз, и блюда, обжаренные после добавления, мясо похоже на мясо, кислый запах! С тех пор маринованное мясо не будет приготовление вина",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement:
          "Управление комментариями электронной коммерции",
        socialMediaReviewManagement:
          "Управление комментариями в социальных средствах массовой информации",
        customDataSource: "Пользовательские исходные данные",
        qualityInspectionResults: "Результаты проверки качества",
        accountManagement: "Управление счетами",
        employeeProfile: "Портрет сотрудника",
        intelligentAnalysis: "Интеллектуальный анализ",
        customKanban: "Пользовательский канбан",
        dataScreening: "Обзор данных",
        learningRecord: "Запись обучения",
        dataAnalysis: "Анализ данных",
        scoringManagement: "Управление рейтингом",
        businessConfiguration: "Конфигурация бизнеса",
        manualReview: "Ручной обзор",
        taskList: "Список задач",
        videoAnalysis: "Анализ видео",
        overview: "Обзор",
        personalSignage: "Персональный канбан",
        learningCenter: "Учебный центр",
        wrongQuestionSet: "Ненеправильный набор вопросов",
        abilityImprovement: "Повышение способности",
        close: "Закрыть",
        switchingSystemApplications: "Применение системы переключения",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Перемещение мыши здесь, чтобы вызвать меню переключения приложений, нажмите пункт меню, чтобы перейти к различным приложениям",
        teamAnalysis: "Анализ команды",
        semanticIntelligence: "Семантический интеллект",
        permissionManagement: "Управление правами",
        dataConnection: "Стыковка данных",
        courseManagement: "Управление курсами",
        labelManagement: "Управление этикетками",
        curriculum: "Учебный центр",
        courseConfiguration: "Конфигурация курса",
        task: "Моя миссия",
        historicalLearningData: "Исторические записи",
        taskCenter: "Управление задачами",
        knowledgeBase: "База знаний",
        ecommerceSessionManagement: "Управление сеансами электронной коммерции",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Переключение приложений",
          descriptionOfIntelligentQualityInspectionSystem:
            "Описание Интеллектуальная система контроля качества",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Фильтр сеанса электронной коммерции",
        allEcommerceSessions: "Все сеансы электронной коммерции",
        ecommerceSessionManagement: "Управление сеансами электронной коммерции",
      },
    },
    analysisEnable: {
      bestPractices: "Лучшие практики",
      behaviorAnalysis: "Анализ поведения",
      teamAnalysis: "Анализ команды",
      executiveSupervision: "Исполнительный надзор",
      executiveForceAnalysis: "Анализ исполнения",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Рейтинг:",
          startTime: "Время начала",
          workOrderNo: "Номер рабочего заказа",
          customerServiceHasReadNotRead:
            "Обслуживание клиентов прочитано/непрочитано",
          canYouAppeal: "Можно ли подать апелляцию",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Заметки корпоративного микро",
          addFriendTime: "Добавить время друзей",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Начать повторную проверку качества",
          callID: "Идентификатор вызова",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Новые функции! Нажмите кнопку «Быстрая выборочная проверка», и после успешной отправки он автоматически перепрыгнет на следующую страницу ~~~",
        },
      },
      template: {
        deleteRecord: "Удаление записей",
        areYouSureToDeleteIt: "Определились с удалением?",
        confirm: "Определить",
        reIdentification: "Повторная идентификация",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Повторная идентификация потребляет время транслитерации, пожалуйста, будьте осторожны",
        determine: "Определить",
        executionSuccessful: "Успешное выполнение",
      },
      component: {
        pleaseSelectData: "Выберите данные",
        operationSuccessful: "Операция прошла успешно",
        startPerformingReQualityInspection:
          "Начать повторную проверку качества",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Загрузить имя файла",
        analysisTask: "Аналитические задачи",
        analyzeProgress: "Анализ прогресса",
        analysisSucceeded: "Анализ успеха",
        analysisFailed: "Сбой анализа",
        localDataUpload: "Локальная загрузка данных",
        recordingUpload: "Запись загрузки",
        textUpload: "Загрузка текста",
        uploadTime: "Время загрузки",
        taskName: "Название задачи",
        operator: "Оператор",
        qualityInspectionProgress: "Прогресс контроля качества",
        whole: "Все.",
        haveInHand: "В процессе",
        completed: "Завершено",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Экспортируете ли вы все завершенные записи данных в текущих условиях фильтрации?",
        upload: "Загрузка",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Заполните название",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Заполните имя, как тип/коллекция/цель этого импортированных данных",
            default: "По умолчанию",
            jDCOM: "Jingdong",
            dataType: "Типы данных",
            selectAnalysisTask: "Выбор задачи анализа",
            uploadFiles: "Загрузка файлов",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Скачать шаблон excel, заполнить по формату:",
            template: "Шаблон",
            SupportUpTo10000PiecesOfData:
              "2. Максимальная поддержка 10000 данных",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Поддержка загрузки данных диалога, экспортируемых JD.com, файл. txt, размер файла поддерживает до 100M",
            alongTheWayDataTemplate: "Шаблон данных по дороге",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Пожалуйста, будьте терпеливы в проверке качества текста",
            textQualityInspectionCompleted:
              "Завершение проверки качества текста",
          },
        },
        template: {
          startAnalysis: "Начать анализ",
          pleaseUploadTheFile: "Пожалуйста, загрузите файл",
          localDataUpload: "Локальная загрузка данных",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "Новые функции! Нажмите кнопку «Повторная отправка», и после успешной отправки он автоматически перепрыгнет на следующую страницу ~~~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "Полномочия по контролю качества",
            edit: "Редактирование",
            iParticipated: "Я участвовал в этом.",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Эта роль может видеть данные в выбранном отделе",
            dataPermissions: "Разрешения данных",
            selectDepartment: "Выбор сектора",
            edit: "Редактирование",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Начать повторную проверку качества",
          callID: "Идентификатор вызова",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Новые функции! Нажмите кнопки «Подать выборочную проверку» и «Быстрая выборочная проверка», и после успешной отправки они автоматически перепрыгнут на следующую страницу ~~~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Правила" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Управление наработками",
          workOrderDetails: "Подробная информация о труде",
        },
      },
      template: {
        workOrderFilter: "Фильтр рабочего заказа",
        allWorkOrders: "Все рабочие заказы",
        workOrderManagement: "Управление наработками",
      },
    },
    trainingCenter: {
      topicAnnotation: "Тематические аннотации",
      topicList: "Список тем",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Типы ролей",
              keyWord: "Ключевые слова",
              pleaseEnterKeywordSearch: "Введите поиск по ключевому слову",
              query: "Запрос",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Эта функция еще не открыта, пожалуйста, свяжитесь с системным администратором",
          pleaseSelectAQuestion: "Пожалуйста, выберите вопрос!",
          recommendedSimilarSentencesLargeModel:
            "Рекомендуемые похожие предложения (большая модель)",
          selectAll: "Полный выбор",
          add: "Добавить",
          changeBatch: "Изменить партию",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Рабочие часы",
      localDataUpload: "Локальная загрузка данных",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Сборка списка меню на основе текущих разрешений пользователя",
      sessionSettings: "Настройки сеанса",
      logoSettings: "Настройка логотипа",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "С возвращением! Пожалуйста, введите ваши данные",
        mailbox: "Почтовый ящик",
        password: "Пароль",
        signIn: "Войти",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin:
            "Текущий IP-адрес не позволяет войти в систему",
          welcomeToLogin: "Добро пожаловать в систему",
          pursuingExcellenceAndLeadingChange:
            "Стремление к совершенству и лидерство в переменах",
          intelligentPlatform: "Интеллектуальная платформа",
        },
        virtualLogin: { index: { pleaseSelect: "Пожалуйста, выберите" } },
      },
      oauth: {
        illegalCall: "Незаконный вызов",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "С возвращением! Нажмите кнопку ниже, чтобы перейти к платформе аутентификации личности",
        loggingIn: "Войти в...",
        unifiedIdentityAuthenticationLogin:
          "Унифицированный вход в систему аутентификации",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Тестирование правил: некоторые правила не могут использовать тесты правил, такие как громкость речи, данные в пути и т. Д.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Узнать больше",
          enteringTheSystem: "Вход в систему",
        },
      },
      index: {
        cancel: "Отмена",
        enteringTheSystem: "Вход в систему",
        close: "Закрыть",
        enterSystemApplication: "Вход в системное приложение",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          "Нажмите «Войти в систему», чтобы перейти к включенным системным приложениям",
      },
    },
    permissionManagement: {
      employeeManagement: "Управление персоналом",
      roleManagement: "Управление ролями",
      permissionManagement: "Управление правами",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Равно",
            contain: "Содержит",
            doesNotContain: "Не содержит",
            applicationSystem: "Прикладные системы",
            intelligentQualityInspection:
              "Интеллектуальный контроль качества/Интеллектуальный анализ сеанса",
            salesEmpowerment: "Продаваемые возможности",
            dataSourceType: "Тип источника данных",
            filterCriteria: "Условия скрининга",
          },
        },
        index: {
          dataConnection: "Стыковка данных",
          savingSucceeded: "Успешное сохранение",
          dataIsolation: "Изоляция данных",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "После включения вы можете установить диапазон данных каждой прикладной системы для доступа к вызову и корпоративному сеансу WeChat. Если переключатель не включен, каждая система получает полный объем данных",
          accessRules: "Правила доступа",
          preservation: "Сохранить",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Название приложения",
            accessRights: "Доступ",
            inAppPermissionSettings: "Настройки разрешений в приложении",
            setUp: "Настройка",
            applicationAccess: "Доступ к приложениям",
            newRole: "Добавлена роль",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Сведения о сеансе" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Сведения о сеансе",
          caseDetails: "Подробности дела",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Видимый персонал",
            newCourse: "Новый курс",
            editCourse: "Курс редактирования",
            courseName: "Название курса",
            courseIntroduction: "Введение в курс",
            courseType: "Типы курсов",
            courseClassification: "Классификация курсов",
            courseCover: "Обложка курса",
            learningContent: "Содержание обучения",
            uploadLearningMaterials: "Загрузить учебные материалы",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Тест",
              preservation: "Сохранить",
              cancel: "Отмена",
              editNodeName: "Редактирование имени узла",
              determine: "Определить",
              individual: "-Что?",
              of: ",",
              branch: "Очки",
            },
            configDrawerComponents: {
              pPTParsing: "Анализ PPT...",
              uploadSuccessful: "Успешная загрузка",
              uploadAttachments: "Загрузка вложений",
              pPTParsingPleaseWait: "Анализ PPT, пожалуйста, позже",
            },
            customerPortraitConfigDrawer: {
              cancel: "Отмена",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Невозможно восстановить после удаления, уверен, что вы хотите удалить этот портрет?",
              delete: "Удалить",
              determine: "Определить",
              customerPortrait: "Портрет клиента",
              bindSelectedCharacterPortrait:
                "Портрет персонажа выбора связывания",
              customerProfileDetails: "Портреты клиентов",
              createANewCustomerProfile: "Новый портрет клиента",
            },
            flow: {
              sidebar: { node: "Узлы" },
              useControlButtons: {
                cancellingPleaseWait: "Отмена, пожалуйста, позже...",
                redoInProgressPleaseWait: "В переделке, пожалуйста, позже...",
                automaticOrganization: "Автоматическая отделка",
                canvasAdaptation: "Адаптивный холст",
                delete: "Удалить",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Узлы не могут быть подключены к себе",
              },
            },
            globalConfigDrawer: {
              cancel: "Отмена",
              determine: "Определить",
              globalConfiguration: "Глобальная конфигурация",
              sessionDetection: "Обнаружение сеанса",
              reciprocalRule: "Правила взаимодействия",
              robotVoice: "Звук робота",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Узел студента не ответил на настройки тайм-аут",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Максимальное время молчания, разрешенное студентом без ответа (s)",
              promptScriptForFailureToAnswerOverTime:
                "Не отвечайте за тайм-аут",
              addScript: "Добавить речь",
            },
            robotTimbreForm: {
              aixianatural: "Айша (природа)",
              zhishaChat: "Жиша (чат)",
              zhiqiangentle: "Zhiqian (мягкий)",
              zhimiEmotion: "Жими (Эмоции)",
              aiShuoNatural: "Ай Шуо (естественно)",
              zhishuoChat: "Жишо (чат)",
              aiHaoAffinity: "Ай Хао (близость)",
              zhixiangEmotion: "Чжисян (Эмоции)",
              robotVoice: "Звук робота",
              schoolboy: "Мальчики",
              girlStudent: "Девочки",
              volume: "Объем",
              tone: "Тон",
              speechSpeed: "Скорость речи",
              test: "Тест",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Здравствуйте, я умный тренер по обучению и с нетерпением жду общения с вами",
            },
            sessionDetectionForm: {
              expressionDetection: "Обнаружение экспрессии",
              excessiveDetectionOfModalParticles: "Чрезмерное обнаружение слов",
              theNumberOfRepetitionsIs: "Число повторений",
              minus: "Во второй раз минус",
              appear: "Появляется",
              subtract: "Раз, минус",
              branch: "Очки",
              addRules: "Добавлена правило",
              includingNegativeAngryComplaining:
                "(В том числе негативные, злые, жалобы)",
              scorePointsDisplay: "Отображение очков",
              reminderOfSpeakingTooFasttooSlow:
                "Слишком быстрое/слишком медленное напоминание",
              scriptViolationReminder: "Напоминание о нарушениях речи",
              emotionalReminder: "Эмоциональное напоминание",
              speechSpeedDetection: "Обнаружение скорости речи",
              normalSpeechSpeedScore: "Нормальная скорость речи",
              speakTooFast: "Быстрая скорость речи",
              everyMinuteGreaterThan: "Более чем в минуту",
              aWordAppears: "Слово, появляется",
              speakTooSlowly: "Слишком медленно говорить",
              lessThanPerMinute: "Меньше, чем в минуту",
              ignoreLessThan: "Игнорировать меньше",
              aSentenceOfWords: "Слово предложение",
              emotionalDetection: "Обнаружение эмоций",
              emotionalNormalScore: "Настроение в нормальном счете",
              negativeEmotions: "Отрицательные эмоции",
              illegalWordDetection: "Обнаружение незаконных слов",
              scriptViolationConfiguration: "Нарушение конфигурации речи",
              realTimePromptOnTheFrontEnd:
                "Передние подсказки в реальном времени",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Вопросы и ответы по базе знаний",
                  qAMethod: "Вопросы и ответы",
                  numberOfQuestionsAndAnswers: "Количество вопросов и ответов",
                  scoringRules: "Правила подсчета очков",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Любой вопрос и ответ правильный,",
                  branch: "Очки",
                  successfullySaved: "Успешное сохранение",
                },
                pptNode: {
                  verificationPassed: "Проверка прошла",
                  upload: "Загрузка",
                  supportPptPdfFormatFiles:
                    "Поддержка файлов в формате ppt, pdf",
                  uploadSlide: "Загрузить слайд-шоу",
                  assessmentMethod: "Метод оценки",
                  associateSlide: "Связанные слайды",
                  standardScript: "Стандартная речь",
                  successfullySaved: "Успешное сохранение",
                },
                robotNode: {
                  robotScript: "Роботизированная речь",
                  randomScript: "Случайная речь",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Выбирая похожие семантические слова, робот произносит одно из предложений случайным образом",
                  customerPortrait: "Портрет клиента",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Робот говорит это, когда портрет клиента совпадает",
                  setAsDefaultScript: "Установить по умолчанию",
                  yes: "Да, да.",
                  no: "Нет",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Это говорит, когда робот не попадает ни на какие теги пользователей",
                  successfullySaved: "Успешное сохранение",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Этот узел ветки уже имеет речь по умолчанию. Замените ли вы слова по умолчанию на текущую речь?",
                },
                startNode: {
                  paragon: "Повествование",
                  successfullySaved: "Успешное сохранение",
                },
                userNode: {
                  verificationPassed: "Проверка прошла",
                  standardScript: "Стандартная речь",
                  successfullySaved: "Успешное сохранение",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Пожалуйста, улучшите конфигурацию",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "Введите пример разговора",
                  dialogueExample: "Примеры разговоров",
                  generateConversationExamples: "Создание примеров диалога",
                  mattersNeedingAttention: "Примечания",
                  pleaseEnterThePromptLanguage: "Введите подсказку",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Сцена диалога",
                  simulateCharacter: "Симулятор роли",
                  accompanyingTrainees: "Спарринг-объект",
                  selectFromTemplateLibrary: "Выбор из библиотеки шаблонов",
                  templateLibrary: "Библиотека шаблонов",
                  role: "Роль",
                  scene: "Сцена",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Окно теста слайдов",
              clearContent: "Очистить содержание",
              close: "Закрыть",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "Процесс генерации AI",
                scriptStyle: "Стиль речи",
                theMostConversationRounds: "Больше всего раундов разговоров",
                copyConversation: "Копирование диалога",
                application: "Применение",
                regenerate: "Повторная генерация",
                startGenerating: "Начало сборки",
                pleaseGenerateAProcess: "Пожалуйста, создайте процесс",
                copySuccessful: "Успешное копирование",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Торговые представители",
                    customerServicePersonnel: "Обслуживающий персонал",
                    storeCounter: "Счетчик магазина",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "Генерация полнотекстовой речи",
                speaker: "Спикер",
                scriptStyle: "Стиль речи",
                copyConversation: "Копирование диалога",
                application: "Применение",
                regenerate: "Повторная генерация",
                startGenerating: "Начало сборки",
                pleaseGenerateAProcess: "Пожалуйста, создайте процесс",
                operationSuccessful: "Операция прошла успешно",
                copySuccessful: "Успешное копирование",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Слововая ретушь" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Слова не могут быть пустыми!",
              copySuccessful: "Успешное копирование",
              cancel: "Отмена",
              copy: "Копирование",
              replaceTheOriginalText: "Замена оригинала",
              aiGeneration: "Генерация Ai",
              aiPolishing: "Ai ретушь",
              currentStyle: "Текущий стиль",
              rigorous: "Строгие",
              mild: "Нежный",
              lively: "Живой",
              simplicity: "Минималистский",
              pleaseEnterTheSpeakerRole: "Пожалуйста, введите роль говорящего",
              startGenerating: "Начало сборки",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Слова не могут быть пустыми!",
              copySuccessful: "Успешное копирование",
              aiRewrite: "Ai переписать",
              cancel: "Отмена",
              copy: "Копирование",
              replaceTheOriginalText: "Замена оригинала",
              currentStyle: "Текущий стиль",
              rigorous: "Строгие",
              mild: "Нежный",
              lively: "Живой",
              simplicity: "Минималистский",
              pleaseEnterTheSpeakerRole: "Пожалуйста, введите роль говорящего",
              startGenerating: "Начало сборки",
            },
          },
          index: {
            verificationPassed: "Проверка прошла",
            ruleValidation: "Проверка правил",
            aIGeneration: "Генерация AI",
            generateFullTextSpeechScript: "Генерация полнотекстовой речи",
            editCourse: "Курс редактирования",
            importConversation: "Импорт диалога",
            uploadExcelToCompleteDialogueImport:
              "Загрузить Excel для завершения импорта диалога",
            editBasicInformation: "Редактирование основной информации",
            test: "Тест",
            release: "Опубликовать",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Новые этикетки" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Не удается восстановить после удаления. Вы хотите удалить эту вкладку?",
              delete: "Удалить",
              cancel: "Отмена",
            },
          },
        },
        index: {
          labelManagement: "Управление этикетками",
          newGroup: "Создание новых групп",
          edit: "Редактирование",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Не удается восстановить после удаления. Вы хотите удалить эту группу тегов?",
          delete: "Удалить",
          cancel: "Отмена",
          editGroup: "Редактирование группировки",
          labelGroupName: "Название группы тегов",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Отключена камера",
              noCameraDevicesFound: "Камера не найдена",
            },
          },
          cue: {
            index: {
              regenerate: "Повторная генерация",
              generate: "Генерация",
              referenceScript: "Справочная речь",
              mattersNeedingAttention: "Примечания",
              examFrequency: "Количество экзаменов",
              section: "Первый",
              timestotal: "Время/общее",
              second: "Раз",
              requirementsForPassingTheExam: "Требования к сдаче экзамена",
              examScore: "Оценка экзамена ≥",
              branch: "Очки",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Нет портрета",
              customerBackground: "Фон клиента",
              customerLabel: "Теги клиентов",
            },
          },
          flow: {
            index: {
              processReminder: "Подсказка процесса",
              score: "Счет",
              TotalScore: "/Общая оценка",
            },
          },
          index: {
            taskName: "Название задачи",
            taskStatus: "Состояние задачи",
            whole: "Все.",
            completionStatus: "Состояние завершения",
            courseClassification: "Классификация курсов",
            practiceMode: "Режим практики",
            examMode: "Режим экзамена",
            practiceTime: "Время практики",
          },
          ppt: {
            index: {
              nextPage: "Следующий",
              tips: "Подсказка",
              continueWithCurrentPage: "Продолжить текущую страницу",
              goToTheNextPage: "Вход на следующую страницу",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "После входа на следующую страницу вы больше не сможете вернуться на предыдущую страницу, пожалуйста, подтвердите, что текущая страница завершена, прежде чем прыгать",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Этот совет не появится при нажатии на следующую страницу",
            },
            list: { index: { slide: "Слайды" } },
          },
          record: {
            index: {
              unableToRecord: "Невозможно записать:",
              creatingARecorderService: "Создание службы Recorder",
              turnOffTheRecorderService: "Отключение службы Recorder",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Текущий клиент подключен к серверу ASR Websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "После обработки последнего кадра состояние восстанавливается",
              aSRConnectionClosed: "Подключение ASR закрыто...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Запись была приостановлена и обработана, но данные все еще находятся в кэше",
              foundASRChannelClosedRecreateWebsocketLink:
                "Откройте, что канал ASR закрыт, воссоздайте ссылку Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Обнаружив, что канал ASR не открыт, воссоздайте ссылку Websocket",
            },
            ppt: { hook: { index: { serverError: "Ошибка сервера" } } },
            text: {
              index: {
                pleaseEnter: "Пожалуйста, введите",
                sendOut: "Отправить",
                startPracticing: "Начать практику",
                startTheExam: "Начало экзамена",
              },
            },
            util: {
              recorded: "Запись",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16-битный pcm SendFrameSize должен быть целым кратным 2",
              pCMSamplingRateShouldNotOccur:
                "Не должно быть частоты дискретизации pcm",
              andTheRequiredSamplingRate: "И требуемой частоты дискретизации",
              atypism: "Несоответствие",
              errorsThatShouldNotOccur: "Ошибки, которые не должны появляться:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> Неупорядоченные данные, если требования не высоки, вы можете напрямую выбросить </span>',
              flower: "Цветок",
              TransferStopped: ": Перенос остановлен",
            },
            video: { index: { camera: "Камера" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Текущий клиент подключен к серверу ASR Websocket",
                    aSRConnectionClosed: "Подключение ASR закрыто...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Откройте, что канал ASR закрыт, воссоздайте ссылку Websocket",
                    unableToRecord: "Невозможно записать:",
                    aSRChannelClosedReopened:
                      "Закрытие канала ASR, повторное открытие",
                    theASRChannelWasNotCreatedStartingNow:
                      "Канал ASR не создан, теперь начинается создание",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect обратный вызов, чтобы очистить канал ASR",
                    creatingARecorderService: "Создание службы Recorder",
                    turnOffTheRecorderService: "Отключение службы Recorder",
                  },
                  util: {
                    recorded: "Запись",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16-битный pcm SendFrameSize должен быть целым кратным 2",
                    pCMSamplingRateShouldNotOccur:
                      "Не должно быть частоты дискретизации pcm",
                    andTheRequiredSamplingRate:
                      "И требуемой частоты дискретизации",
                    atypism: "Несоответствие",
                    errorsThatShouldNotOccur:
                      "Ошибки, которые не должны появляться:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> Неупорядоченные данные, если требования не высоки, вы можете напрямую выбросить </span>',
                    flower: "Цветок",
                    TransferStopped: ": Перенос остановлен",
                  },
                },
                index: { failInSend: "Сбой отправки" },
              },
              index: {
                startRecording: "Начать запись",
                endRecording: "Завершение записи",
                startPracticing: "Начать практику",
                startTheExam: "Начало экзамена",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Оставшееся количество текущих экзаменов",
              totalScriptScore: "Общая оценка речи:",
              totalEmotionalScore: "Общая оценка эмоций:",
              totalScoreOfExpression: "Выражая общий балл:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Результаты текущей оценки задачи должны быть проверены вручную. Окончательные результаты будут уведомлены после завершения проверки. Пожалуйста, подождите",
              areYouSureToSubmit: "Подтверждено ли представление?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Упражнение закончено, подтверждаете ли вы представление?",
              theExamHasEndedAreYouSureToSubmit:
                "Экзамен завершен. Подтверждаете ли вы представление?",
              completed: "Завершено",
              page: "Страница",
              pageCount: "Общее количество страниц",
              topic: "Вопрос",
              totalNumberOfQuestions: "Общее количество вопросов",
              totalScore: "Общая оценка",
              branch: "Очки",
              accumulatedCompletionProgress: "Накопленный прогресс",
              accumulatedPracticeDuration:
                "Накопленная продолжительность практики",
              lastPauseExerciseResults: "Последняя пауза-результаты практики",
              exerciseInstructions: "Описание упражнения",
              examInstructions: "Описание экзамена",
              halfwayPausePracticeResults:
                "Пауза на полпути-результаты практики",
              tips: "Подсказка",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Это упражнение не учитывается в результатах после выхода. Подтвержден ли выход?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Выход не разрешен в середине экзамена. Подтверждаете ли вы подачу?",
              pleaseOperateWithCaution: "Раз, пожалуйста, будьте осторожны)",
              restart: "Начать снова",
              returnToLearning: "Вернуться к учебе",
              continuePracticing: "Продолжайте практиковать",
              continuingTheExam: "Продолжить экзамен",
              return: "Возвращение",
              notSubmittedTemporarily: "Не подает",
              iGotIt: "Я знаю.",
              confirmExit: "Подтверждение выхода",
              confirmSubmission: "Подтверждение представления",
              signOut: "Выход",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Приостановка",
              submit: "Представление",
              remainingTimeOfThisQuestion: "Остальное время для этого вопроса",
              SecondsLeftUntilTheEndTime: "До конца осталось 10 секунд",
              remainingTime: "Оставшееся время",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Голосовой контент пользователя не обнаружен, попробуйте снова",
          },
          wave: {
            index: {
              continueRecording: "Продолжить запись",
              submit: "Представление",
              endRecording: "Завершение записи",
              suspendRecording: "Приостановка записи",
              startPracticing: "Начать практику",
              startTheExam: "Начало экзамена",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Режим диалога",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Слишком шумный, пожалуйста, переключитесь на тихую обстановку",
              normalNoiseDetection: "Обнаружение шума в норме",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Слишком малая громкость, пожалуйста, увеличьте громкость",
              volumeDetectionIsNormal: "Нормальное определение громкости",
              noiseDetection: "Обнаружение шума",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Пожалуйста, будьте спокойны и записывайте окружающие звуки 3s",
              endRecording: "Завершение записи",
              startRecording: "Начать запись",
              volumeDetection: "Обнаружение громкости",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                "Пожалуйста, прочитайте «Тест громкости сейчас» с нормальной громкостью",
              playLastDetectedAudio:
                "Воспроизведение последнего обнаруженного аудио",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Пожалуйста, внимательно прочитайте требования перед началом",
              return: "Возвращение",
              iGotIt: "Я знаю.",
              timeLimitForSingleQuestionAnswering:
                "Ограничение длительных ответов на один вопрос",
              totalTimeLimit: "Общее время",
              unlimitedTime: "Неограниченное время",
              stillNeedToCompleteTheTaskInTotal:
                "Все задачи должны быть выполнены",
              practice: "Упражнения",
              second: "Раз",
              taskScore: "Один балл за миссию ≥",
              branch: "Очки",
              or: "Или",
              examScore: "Оценка экзамена ≥",
              remainingExamTimes: "Количество оставшихся экзаменов",
              totalScore: "Общая оценка",
              Script: "(Слова",
              scoreEmotion: "Подделить эмоции",
              fractionExpression: "Субэкспрессия",
              points: "В)",
              standardScore: "Стандарты",
              excellentScore: "Отличный балл",
              exerciseInstructions: "Описание упражнения",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Чтобы ответить на вопрос гладко, сначала завершите тестирование оборудования:",
              camera: "Камера",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Видеть себя в видео означает, что камера работает нормально",
              microphone: "Микрофон",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Пожалуйста, поговорите с микрофоном. Видеть форму сигнала означает, что микрофон нормальный",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Пустой",
                  copyTo: "Копирование",
                  classification: "Классификация",
                  rename: "Переименование",
                  newlyBuild: "Новое строительство",
                  categoryParent: "Высшая классификация",
                  moveTo: "Переезд",
                  name: "Название",
                },
              },
              util: {
                rename: "Переименование",
                moveTo: "Переезд",
                copyTo: "Копирование",
                delete: "Удалить",
                courseClassification: "Классификация курсов",
                newlyBuild: "Новое строительство",
              },
            },
          },
          edit: { index: { curriculum: "Курсы" } },
          index: {
            learn: "Обучение",
            practice: "Практика",
            examination: "Экзамен",
            curriculum: "Курсы",
            courseIntroduction: "Введение в курс:",
            createdOn: "Время создания:",
            published: "Опубликовано",
            tasks: "Задачи.",
            unpublishedTasks: "Неопубликованные задачи",
            task: "Задачи",
            operation: "Операция",
            learningMaterials: "Учебные материалы",
            goPractice: "Иди на практику",
            publishTask: "Задачи публикации",
            taskData: "Данные задачи",
            edit: "Редактирование",
            copy: "Копирование",
            successfullyCopied: "Успешное копирование",
            delete: "Удалить",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "После удаления все данные задачи в рамках этого курса будут очищены. Подтверждено ли удаление курса?",
            courseName: "Название курса",
            creationTime: "Время создания",
            releaseStatus: "Статус публикации",
            whole: "Все.",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Звук не обнаружен",
              voice: "Голос",
              text: "Текст",
              referenceScript: "Справочная речь:",
              clickOnTheRightSideToStartRecording:
                "Нажмите справа, чтобы начать запись",
              sendOut: "Отправить",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Текущий клиент подключен к серверу ASR Websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "После обработки последнего кадра состояние восстанавливается",
                aSRConnectionClosed: "Подключение ASR закрыто...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Запись была приостановлена и обработана, но данные все еще находятся в кэше",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Откройте, что канал ASR закрыт, воссоздайте ссылку Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Обнаружив, что канал ASR не открыт, воссоздайте ссылку Websocket",
                unableToRecord: "Невозможно записать:",
                creatingARecorderService: "Создание службы Recorder",
                turnOffTheRecorderService: "Отключение службы Recorder",
              },
              util: {
                recorded: "Запись",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16-битный pcm SendFrameSize должен быть целым кратным 2",
                pCMSamplingRateShouldNotOccur:
                  "Не должно быть частоты дискретизации pcm",
                andTheRequiredSamplingRate: "И требуемой частоты дискретизации",
                atypism: "Несоответствие",
                errorsThatShouldNotOccur:
                  "Ошибки, которые не должны появляться:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> Неупорядоченные данные, если требования не высоки, вы можете напрямую выбросить </span>',
                flower: "Цветок",
                TransferStopped: ": Перенос остановлен",
              },
            },
            finishTest: {
              index: {
                endTest: "Завершение теста",
                testResult: "Результаты испытаний",
                returnToCanvas: "Вернуться к холсту",
                retest: "Повторное тестирование",
              },
            },
          },
          index: {
            testFromCurrentNode: "Тестирование с текущего узла",
            listening: "В эфире...",
            canvasTesting: "Тест на холст",
            endOfProcess: "Конец процесса",
            restart: "Начать снова",
            start: "Начать",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Уборка анализа",
                  expandParsing: "Развернуть анализ",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Слишком много слов",
                  second: "Раз",
                  ratingOverview: "Обзор оценок",
                  totalScore: "Общая оценка",
                  section: "Первый",
                  name: "Имя",
                  of: "(Всего",
                  people: "Человек)",
                  scriptScore: "Голосовой счет",
                  common: "Всего",
                  keyPoints: "Точка,",
                  notReached: "Не достигнут,",
                  have: "Есть.",
                  violationOfRegulations: "Нарушения",
                  emotionalScore: "Оценка эмоций",
                  appear: "Появляется",
                  secondaryNegativeEmotions: "Вторые негативные эмоции",
                  expressionScore: "Оценка выражения",
                  speakTooFast: "Быстрая скорость речи",
                  times: "Раз",
                  speakTooSlowly: "Слишком медленно говорить",
                  average: "Средний",
                  wordminute: "Слово/минута",
                },
              },
              score: {
                index: {
                  studentNodeName: "Название узла студента",
                  keyPointName: "Название точки",
                  slideNodeName: "Имя узла слайда",
                  dialogue: "Диалог",
                  mainPoints: "Основные моменты",
                  slide: "Слайды",
                  detectionResult: "Результаты испытаний",
                  satisfy: "Удовлетворить",
                  dissatisfaction: "Не удовлетворен",
                  score: "Счет",
                  script: "Слова",
                  semantics: "Семантика",
                  scriptViolation: "Нарушения речи",
                  emotion: "Эмоции",
                  expression: "Выражение",
                },
              },
              taskTitle: { index: { branch: "Очки" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "На этот раз",
                  lastTime: "Последний раз.",
                  comprehensiveEvaluation: "Комплексная оценка",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Стандартное накопление речи',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Слова, студенты следуют за количеством слов",
                  aWordspan: "Слово </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Время </span><br /><span style = "margin-left: 8px">· Скорость речи слишком медленная',
                  timesspan: "Время </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Слишком много слов',
                  complete: "Полный контент",
                  keyHit: "Точка попадания",
                  languageNorm: "Языковая спецификация",
                  fluentExpression: "Свободное выражение",
                  emotionallyPositive: "Эмоциональный положительный",
                  radarChart: "Радарная карта",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Количество полных процессов',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· Хит для очков',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· Есть',
                  dealingWithIllegalWordsspan: "Слова нарушения </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· Скорость речи слишком высокая',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Время </span><br /><span style = "margin-left: 8px">· Слишком много слов',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Отрицательные эмоции',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Запись рейтинга",
                  firstRating: "Первый рейтинг",
                  totalScore: "Общая оценка",
                  changeTo: "Изменить на",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Этап общения",
                  communicationObjectives: "Коммуникационные цели",
                  scoringCriteria: "Критерии оценки",
                  suggestionsForImprovement: "Рекомендации по повышению",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Остальное число:",
                  second: "Раз",
                  doItAgain: "Еще раз.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Браузер не поддерживает воспроизведение аудио.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Комментарии и предложения",
                  regenerate: "Повторная генерация",
                  generate: "Генерация",
                  generationFailed: "Сбой генерации",
                },
              },
              resultModal: {
                index: {
                  return: "Возвращение",
                  viewDetails: "Посмотреть детали",
                },
              },
            },
            index: {
              reRatingSuccessful: "Повторная оценка успеха",
              reRatingFailed: "Переоценка не удалась",
              downloadRecording: "Скачать запись",
              downloadText: "Скачать текст",
              download: "Скачать",
              reRating: "Повторная оценка",
              submitARatingAppeal: "Подать жалоба на оценку",
              reviewSubmission: "Представлено на рассмотрение",
              saveChanges: "Сохранить изменения",
              previousSentence: "Предыдущее предложение",
              nextSentence: "Следующее предложение",
              score: "Рейтинг",
              scoringSituation: "Счет ситуации",
              dialogueAnalysis: "Анализ диалога",
            },
          },
          index: {
            ratingAppealInProgress: "Оценка в жалобе",
            operation: "Операция",
            wrongQuestionSet: "Ненеправильный набор вопросов",
            ratingUpdate: "Обновление рейтинга",
            viewDetails: "Посмотреть детали",
            curriculum: "Курсы",
            taskName: "Название задачи",
            taskType: "Тип задачи",
            score: "Счет",
            scoringResults: "Результаты",
            time: "Время",
            courseClassification: "Классификация курсов",
            whole: "Все.",
            learningTime: "Время обучения",
            historicalLearningData: "Исторические данные обучения",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Пауза на практике",
              lastProgress: "Последний прогресс",
              continuePracticing: "Продолжайте практиковать",
              practice: "Упражнения",
              startPracticing: "Начать практику",
              historicalData: "Исторические данные",
              courseIntroduction: "Введение в курс:",
              exerciseDifficulty: "Трудность упражнения:",
              practiceCompletionRequirements:
                "Требования к завершению упражнения:",
              practiceFrequency: "Количество упражнений ≥",
              second: "Раз",
              anyScore: "Любая оценка ≥",
              branch: "Очки",
              or: "Или",
              taskList: "Список задач",
              practiceTime: "Время практики:",
              to: "До",
              unlimitedTime: "Неограниченное время",
              completionStatus: "Состояние завершения",
              numberOfExercises: "Количество упражнений",
              maximumScore: "Максимальный балл",
              operation: "Операция",
              enteringLearning: "Вход в обучение",
              historicalLearningData: "Исторические данные обучения",
              wrongQuestionSet: "Ненеправильный набор вопросов",
            },
          },
          index: {
            practiceTasks: "Практические задания",
            examTasks: "Экзаменационные задания",
            task: "Задачи",
          },
          learn: {
            index: {
              courseName: "Название курса",
              courseIntroduction: "Введение в курс",
              learningContent: "Содержание обучения",
              downloadLearningMaterials: "Загрузка учебных материалов",
              startPracticing: "Начать практику",
            },
          },
          test: {
            index: {
              taskList: "Список задач",
              courseIntroduction: "Введение в курс:",
              examDifficulty: "Трудность экзамена:",
              requirementsForPassingTheExam: "Требования к сдаче экзамена:",
              examScore: "Оценка экзамена ≥",
              branch: "Очки",
              examTime: "Время экзамена:",
              to: "До",
              unlimitedTime: "Неограниченное время",
              completionStatus: "Состояние завершения",
              completionTimes: "Количество выполненных",
              maximumScore: "Максимальный балл",
              operation: "Операция",
              immediateExam: "Экзамен сейчас",
              history: "Исторические записи",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Справочная речь" } },
              flow: {
                index: {
                  wrongQuestionItem: "Ненеправильный вопрос (",
                  score: "Счет",
                  TotalScore: "/Общая оценка",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Ошибка сервера" } },
                  index: {
                    startRecording: "Начать запись",
                    endRecording: "Завершение записи",
                    startPracticing: "Начать практику",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Пожалуйста, введите",
                    sendOut: "Отправить",
                    startPracticing: "Начать практику",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Начать запись",
                    endRecording: "Завершение записи",
                    startPracticing: "Начать практику",
                  },
                  hook: { index: { failInSend: "Сбой отправки" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Оценка этой неправильной практики",
                  tips: "Подсказка",
                  areYouSureToEnd: "Конец подтвержден?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Практикуйте снова, не осваивая неправильные вопросы",
                  continue: "Продолжайте.",
                  returnToTheSetOfIncorrectQuestions:
                    "Возвращает неправильный набор вопросов",
                  end: "Конец.",
                  wrongQuestionItem: "Ненеправильные вопросы",
                  numberOfExercisesThisTime: "Количество упражнений",
                  masteringTheSituation: "Освоить ситуацию",
                  mastered: "Освоил",
                  notMastered: "Не освоил",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Практикуя этот вопрос снова",
                  nextQuestion: "Следующий вопрос",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Ненеправильные упражнения",
                endExercise: "Завершение упражнения",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Ненеправильные упражнения",
                endExercise: "Завершение упражнения",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Ненеправильные упражнения",
                endExercise: "Завершение упражнения",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Просмотр речи",
                  section: "Первый",
                  secondaryDiscourseTechnique: "Вторая речь",
                },
              },
              tableItem: {
                count: { second: "Раз" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Не запрашивает соответствующую информацию",
                  recallWords: "Отзыв слова",
                  branch: "Очки",
                  answerIncorrectly: "Неправильный ответ",
                  second: "Раз",
                  wrongAnswerRate: "Уровень ошибок",
                },
                standardScript: {
                  standardScript: "Стандартная речь",
                  myScript: "Мои слова",
                },
                status: { mastered: "Освоил", notMastered: "Не освоил" },
                index: {
                  totalNumberOfQuestionsAnswered: "Общее количество ответов",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Количество неправильных вопросов, которые были отработаны",
                },
              },
            },
            index: {
              wrongQuestionList: "Список неправильных вопросов",
              wrongQuestionSet: "Ненеправильный набор вопросов",
              mispracticeExercises: "Ненеправильные упражнения",
              taskName: "Название задачи",
              staff: "Сотрудники",
              masteringTheSituation: "Освоить ситуацию",
              whole: "Все.",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Количество упражнений ≥",
              second: "Раз",
              or: "Или",
              excellentScoreAtAnyOneTime:
                "Выдающийся результат в любом случае (≥",
              points: "В)",
              examScore: "Оценка экзамена ≥",
              branch: "Очки",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Случайное среднее распределение",
              assignToDesignatedPersonnel: "Назначен назначенному персоналу",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Увеличение числа успешных экзаменов",
              failedToIncreaseTheNumberOfExams:
                "Неудача увеличить количество экзаменов",
              increaseTheNumberOfExams: "Увеличение числа экзаменов",
              selectObjects: "Выбор объектов",
            },
          },
          executionFilter: {
            index: { participationRate: "Коэффициент участия" },
          },
          intelligentReview: {
            index: {
              overallReview: "Отзывы в целом",
              pleaseEnterTheReviewCriterianotMandatory:
                "Пожалуйста, введите критерии обзора (необязательно)",
              commentaryOnSingleSentenceRhetoric: "Одиночный комментарий",
              realTimeReview: "Отзывы в реальном времени",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Средний балл экзамена",
            averageExamDurationmin: "Средняя продолжительность экзамена (min)",
            accumulatedPracticeDurationmin:
              "Накопленная продолжительность упражнения (min)",
            accumulatedPracticeTimes: "Совокупное количество упражнений",
            totalScore: "Общая оценка",
            average: "Средний балл",
            total: "Общая сумма",
            keyHitCount: "Количество попаданий в точку",
            practiceAverageScore: "Упражнения средний балл",
            averagePracticeDurationmin:
              "Средняя продолжительность упражнения (min)",
            topOfTheHighestScore: "Максимальный балл Top3",
            branch: "Очки",
            errorProneItemTop: "Легкий элемент Top5",
            hitRate: "Скорость попадания",
            hitRateOfKeyPointsInScript: "Точки речи",
            averageScriptScore: "Средняя оценка",
            sortByProcess: "Сортировка по процессу",
            sortByScore: "Сортировка по балл",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Средняя оценка страницы/общая оценка страницы",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Средний балл/общий балл",
            details: "Подробная информация",
            viewRules: "Просмотр правил",
            standardScript: "Стандартная речь",
          },
          scoreDetailList: {
            section: "Первый",
            second: "Раз",
            practice: "Упражнения",
            examination: "Экзамен",
            notMeetingStandards: "Несоответствие",
            meetingStandards: "Стандарт",
            excellent: "Отлично.",
            frequency: "Количество раз",
            fraction: "Оценка",
            result: "Результаты",
            operation: "Операция",
            viewDetails: "Посмотреть детали",
          },
          index: {
            common: "Всего",
            people: "Человек",
            staff: "Сотрудники",
            notMeetingStandards: "Несоответствие",
            meetingStandards: "Стандарт",
            excellent: "Отлично.",
            notStarted: "Не начал",
            haveInHand: "В процессе",
            completed: "Завершено",
            passed: "Принятая",
            hangInTheAir: "Не завершено",
            failed: "Не прошел",
            unlimitedTime: "Неограниченное время",
            to: "До",
            maximumScoringCriteria: "Максимальный балл",
            completionStatus: "Состояние завершения",
            employeeName: "Имя сотрудника",
            frequency: "Количество раз",
            practice: "Упражнения",
            examination: "Экзамен",
            average: "Средний балл",
            speechCraftAvgScore: "Средняя оценка",
            emotionAvgScore: "Средняя оценка эмоций",
            speedAvgScore: "Выражение среднего балла",
            flowAvgScore: "Средний балл процесса",
            practiceCostTime: "Накопленная продолжительность",
            participateCommitRate: "Коэффициент соответствия",
            participateExcellentRate: "Отлично ставка",
            duration: "Продолжительность",
            score: "Счет",
            scoringResults: "Результаты",
            flowScore: "Оценка процесса",
            craftScore: "Голосовой счет",
            emotionScore: "Оценка эмоций",
            speedScore: "Оценка выражения",
            contentIntegrity: "Полный контент",
            pointHitRate: "Точка скорости попадания",
            emotionNegativeRate: "Эмоциональная отрицательная доля",
            paraPhaSiaRate: "Доля аномальной скорости речи",
            toneOvermuchRate: "Слишком много слов",
            maximumScore: "Максимальный балл",
            highestScoreResult: "Результаты с наибольшим счетом",
            state: "Состояние",
            averageDurationmin: "Средняя продолжительность (min)",
            lately: "Недавно",
            time: "Время",
            operation: "Операция",
            viewDetails: "Посмотреть детали",
            wrongQuestionSet: "Ненеправильный набор вопросов",
            taskName: "Название задачи",
            completeRequirements: "Требования к завершению",
            publishTo: "Объект публикации",
            taskTime: "Время выполнения задачи",
            fractionalComposition: "Состав дроби",
            totalScore: "Общая оценка",
            branch: "Очки",
            Script: "(Слова",
            scoreEmotion: "Подделить эмоции",
            fractionExpression: "Субэкспрессия",
            points: "В)",
            implementationRate: "Коэффициент участия",
            numberOfExecutors: "Число участников",
            numberOfPeopleNotExecuted: "Число неучастников",
            completionRate: "Уровень завершения",
            numberOfCompletedPersonnel: "Количество завершенных",
            numberOfUnfinishedPersonnel: "Число незавершенных",
            complianceRate: "Коэффициент соответствия",
            numberOfQualifiedPersonnel:
              "Количество людей, которые соответствуют стандартам",
            numberOfNonCompliantIndividuals:
              "Количество людей, которые не соответствуют стандартам",
            excellentRate: "Отлично ставка",
            participateCompleteRate: "Показатель завершения участия",
            numberOfOutstandingIndividuals: "Отличное количество",
            numberOfNonOutstandingIndividuals: "Количество неотличного",
            export: "Экспорт",
            taskData: "Данные задачи",
            resultAnalysis: "Анализ результатов",
            scoreDetails: "Подробности оценки-",
            dataOverview: "Обзор данных",
            taskDetail: "Счетные детали задачи",
            scriptAnalysis: "Анализ речи",
            flowAnalysis: "Анализ процессов",
            expressionAnalysis: "Анализ экспрессии",
            emotionAnalysis: "Анализ настроений",
            byTime: "Вид измерения времени",
            byNumber: "Вид измерения числа",
            personData: "Статистика по персоналу",
            numberData: "Сведения о регистрации",
            count: "По количеству раз",
            person: "В зависимости от количества людей",
            reachTimes: "Количество касаний",
            outOfReachTimes: "Не достигло количества раз",
            seeOutOfReach: "Нажмите, чтобы увидеть, что не достиг",
            accumulative: "Накопить",
            script: "Слова",
            times: "Раз",
            sentence: "Предложение",
            expressionAverage: "Выражение средний балл",
            modalAnalysis: "Анализ речи",
            total: "Всего",
            muchModalAnalysis: "Слишком много слов",
            normal: "Нормальный",
            speedAnalysis: "Анализ скорости речи",
            speedDistribution: "Распределение скорости речи",
            speakingSpeed: "Скорость речи",
            normalSpeak: "Нормальная скорость речи",
            tooFastSpeak: "Слишком быстро говорить",
            tooSlowlySpeak: "Слишком медленная скорость речи",
            violationWord: "Незаконные слова",
            violationWordAnalysis: "Анализ нарушенных слов",
            noData: "Нет",
            emotionalScore: "Оценка эмоций",
            emotionalDistribution: "Распределение эмоций",
            negativeEmotion: "Отрицательные эмоции",
            normalEmotion: "Нормальные эмоции",
            getDetail: "Посмотреть детали",
            barPattern: "Вид гистограммы",
            timeDimension: "Вид широты времени",
            timesDimension: "Вид измерения числа",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Средний балл",
                  averageScoreOfScript: "Средняя оценка",
                  averageEmotionalScore: "Средний эмоциональный балл",
                  expressionAverageScore: "Выражение среднего балла",
                  processAverageScore: "Средний балл процесса",
                  accumulatedDuration: "Накопительная продолжительность",
                  accumulatedTimes: "Совокупное число",
                  participationComplianceRate: "Участие в уровне соответствия",
                  participationRateForAchievingExcellence:
                    "Участие в превосходном уровне",
                  branch: "Очки",
                  numberOfPeople: "Число",
                  averageScoreOfExpression: "Выражение средний балл",
                  people: "Человек",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Отрицательные эмоции" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Средняя оценка",
                  branch: "Очки",
                },
                useSummaryDetail: { keyHitRate: "Точка скорости попадания" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Интеллектуальные отзывы",
            pleaseImproveTheConfiguration: "Пожалуйста, улучшите конфигурацию",
            examResults: "Результаты ЕГЭ",
            showTheResultsAfterEachExam:
              "Показать результаты после каждого экзамена",
            showTheResultsAfterTheLastExam:
              "Результаты после последнего экзамена",
            doNotDisplayResults: "Не показывать результаты",
            resultDisplay: "Результаты шоу",
            score: "Счет",
            scoringResults: "Результаты",
            allowViewingScoreDetails: "Разрешить просмотр деталей оценки",
            viewCanvasConfiguration: "Просмотр конфигурации холста",
            synchronizationFailedProcessNodeHasChanged:
              "Сбой синхронизации, изменения в узле процесса",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Синхронизация успешно, нажмите Опубликовать обновление курса",
            retrievedTheLatestCanvasConfiguration:
              "Последние конфигурации холста были извлечены",
            synchronizeCanvasConfiguration: "Синхронизация конфигурации холста",
            scoringMethod: "Способ оценки",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Баллов, процесс {0} баллов, речь {1} баллов, эмоции {2} баллов, скорость речи {3} балла)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Отличный стандарт должен быть выше, чем стандарт",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Критерии оценки должны соответствовать более 0%, менее 100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Критерии оценки должны соответствовать более чем 0 баллов, менее чем {0} баллов",
            doRatingsRequireManualReview: "Требуется ли оценка вручную",
            allocationStrategy: "Стратегии распределения",
            pleaseSelectTheAssignedPersonnel:
              "Пожалуйста, выберите назначенный персонал",
            pleaseSelectADesignatedPerson: "Выберите назначенного сотрудника",
            timeLimitForSingleQuestionAnswering:
              "Ограничение длительных ответов на один вопрос",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Опубликовано по умолчанию для новых сотрудников",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Переоценка указывает, что она будет сопоставлена в соответствии с правилами оценки последнего холста. Текущая задача имеет искусственно проверенные данные оценки.",
            notCovered: "Не покрывает",
            cover: "Покрытие",
            participationSituation: "Участие",
            numberOfParticipants: "Число участников",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Количество объектов упражнения, выбранных для этой задачи",
            sumOfTheNumberOfParticipantsInTheTask:
              "Количество участников миссии",
            numberOfCompletedPersonnel: "Количество завершенных",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Количество людей, удовлетворяющих требованиям для выполнения задачи",
            numberOfQualifiedPersonnel:
              "Количество людей, которые соответствуют стандартам",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Количество людей, которые соответствуют задачам",
            excellentPersonnel: "Отличное количество",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Выдающееся количество людей, которые отвечают задачам",
            participationRate: "Коэффициент участия",
            numberOfParticipantsNumberOfParticipants:
              "Число участников/число участников",
            numberOfCompletedNumberOfParticipants:
              "Количество завершенных/подлежащих участию",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Количество людей, которые соответствуют требованиям/должны участвовать",
            excellentNumbernumberOfParticipants:
              "Количество выдающихся людей/участников",
            participationCompletionRate: "Показатель завершения участия",
            numberOfCompletedParticipated: "Количество завершенных/участников",
            participationComplianceRate: "Участие в уровне соответствия",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Число участников/участников",
            participationRateForAchievingExcellence:
              "Участие в превосходном уровне",
            accumulatedDuration: "Накопительная продолжительность",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Студенты участвуют в задаче",
            averageDuration: "Средняя продолжительность",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Число участников, участвующих в миссии, продолжительность/общее участие",
            accumulatedTimes: "Количество накоплений",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Количество заданий, в которых участвуют студенты",
            averageScore: "Средний балл",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Средний балл участников миссии",
            maximumScore: "Максимальный балл",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Максимальный балл за участие в миссии",
            averageScoreOfScript: "Средняя оценка",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Студенская оценка речи/общее количество участников",
            totalScoreOfScript: "Общая оценка",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Общая оценка слов, установленных в этой задаче",
            processAverageScore: "Средний балл процесса",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Оценка процесса студента и суммирования/общее количество участников",
            totalProcessScore: "Общий балл процесса",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Общий балл процесса, установленный в рамках этой задачи",
            averageEmotionalScore: "Средний эмоциональный балл",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Эмоциональные оценки студентов и суммирования/общее участие",
            totalEmotionalScore: "Эмоциональный общий балл",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Настроенный общий балл эмоций под заданием",
            averageScoreOfExpression: "Выражение средний балл",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Участники выражают оценку суммирования/общее количество участников",
            totalScoreOfExpression: "Выразить общий балл",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Общая оценка выражений, установленных в рамках этой задачи",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Начните выполнять переоценку, переходите в центр задач для просмотра прогресса",
            reRating: "Повторная оценка",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Переоценка означает, что она будет сопоставлена в соответствии с правилами оценки последнего холста, которые будут перезаписывать текущий результат оценки",
            releaseTime: "Время выхода",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Проверено",
              pendingReview: "В ожидании рассмотрения",
              enterKeywordSearch: "Введите поиск по ключевым словам",
            },
            pageRightTable: {
              index: {
                employeeName: "Имя сотрудника",
                systemRating: "Оценка системы",
                scoringResults: "Результаты",
                duration: "Продолжительность",
                time: "Время",
                state: "Состояние",
                whetherToModifyTheRating: "Исправлена ли оценка",
                finalScore: "Итоговая оценка",
                reviewedBy: "Ревизионный",
                operation: "Операция",
                viewScoreDetails: "Посмотреть детали оценки",
                viewStatus: "Просмотр состояния",
                whole: "Все.",
                correctionStatus: "Исправлено состояние",
                completeReview: "Завершение аудита",
                tips: "Подсказка",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Окончательные результаты студентов подлежат текущему окончательному результату аудита, пожалуйста, подтвердите",
              },
            },
          },
          index: { manualReview: "Ручной аудит" },
        },
        review: {
          index: {
            employeeName: "Имя сотрудника",
            score: "Счет",
            scoringResults: "Результаты",
            duration: "Продолжительность",
            taskName: "Название задачи",
            numberOfAppealScripts: "Количество жалоб",
            appealTime: "Время обжалования",
            processStatus: "Состояние процесса",
            scoreAfterReview: "Оценка после обзора",
            reviewedBy: "Сниз",
            operation: "Операция",
            viewScoreStatus: "Просмотр оценки",
            whole: "Все.",
            manualReview: "Ручной обзор",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Практика данных задачи",
            implementationRate: "Показатели осуществления",
            numberOfExecutors: "Число исполнителей",
            numberOfPeopleNotExecuted: "Число невыполненных",
            completionRate: "Уровень завершения",
            numberOfCompletedPersonnel: "Количество завершенных",
            numberOfUnfinishedPersonnel: "Число незавершенных",
            complianceRate: "Коэффициент соответствия",
            numberOfQualifiedPersonnel:
              "Количество людей, которые соответствуют стандартам",
            numberOfNonCompliantIndividuals:
              "Количество людей, которые не соответствуют стандартам",
            excellentRate: "Отлично ставка",
            numberOfOutstandingIndividuals: "Отличное количество",
            numberOfNonOutstandingIndividuals: "Количество неотличного",
            examTaskData: "Данные экзаменационных заданий",
            published: "Опубликовано",
            courses: "Один курс",
            courseData: "Данные курса",
            practiceTasks: "Практические задания",
            examTasks: "Экзаменационные задания",
          },
          practiceSummary: {
            practiceSummary: "Практические резюме",
            numberOfPracticeTasks: "Количество тренировочных заданий",
            numberOfExercises: "Количество упражнений",
            accumulatedPracticeDuration:
              "Накопленная продолжительность практики",
            complianceRate: "Коэффициент соответствия",
            excellentRate: "Отлично ставка",
            ranking: "Рейтинги",
          },
          rankingList: {
            numberOfExercises: "Количество упражнений",
            exerciseDuration: "Длительные упражнения",
            complianceRate: "Коэффициент соответствия",
            excellentRate: "Отлично ставка",
            diligenceChart: "Список трудолюбия",
            excellentList: "Отличный список",
            ranking: "Ранжирование",
            fullName: "Имя",
            i: "Я.",
          },
          taskCard: {
            end: "Срок:",
            noDeadline: "Нет срока",
            second: "Раз",
            branch: "Очки",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Нет миссии",
            completed: "Завершено",
          },
          filter: { employeeDepartment: "Отдел персонала" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Успешный экспорт, перейдите в Центр задач для просмотра",
              export: "Экспорт",
            },
          },
          filterConfig: {
            dropdown: { custom: "Пользовательские" },
            modal: {
              updateSuccessful: "Обновление прошло успешно",
              successfullySaved: "Успешное сохранение",
              setAsACommonReport: "Настройка для часто используемых отчетов",
              updateCurrentReport: "Обновление текущего отчета",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Средний балл",
                  averageScoreOfScript: "Средняя оценка",
                  averageEmotionalScore: "Средняя оценка эмоций",
                  expressionAverageScore: "Выражение среднего балла",
                  processAverageScore: "Средний балл процесса",
                  branch: "Очки",
                },
              },
              comprehensiveEvaluation: { const: { data: "Данные" } },
              doneStatus: {
                index: {
                  practice: "Упражнения",
                  examination: "Экзамен",
                  executed: "Выполнено",
                  unexecuted: "Не выполнено",
                  accumulatedExamDuration:
                    "Накопленная продолжительность экзамена",
                  examFrequency: "Количество экзаменов",
                },
              },
              filter: {
                index: {
                  practice: "Упражнения",
                  examination: "Экзамен",
                  pleaseCompleteTheRequiredFields:
                    "Пожалуйста, улучшите обязательные для заполнения",
                  staff: "Сотрудники",
                  taskType: "Тип задачи",
                  pleaseSelect: "Пожалуйста, выберите",
                  taskName: "Название задачи",
                },
              },
            },
            index: {
              completionStatus: "Завершение",
              comprehensiveEvaluation: "Комплексная оценка",
              abilityGrowthTrend: "Тенденции роста способностей",
              errorProneAnalysis: "Анализ подверженных ошибкам точек",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Число активных",
            accumulatedPracticeDuration:
              "Накопленная продолжительность практики",
            averagePracticeDuration: "Средняя продолжительность упражнения",
            people: "Человек",
            numberOfPracticeTasks: "Количество тренировочных заданий",
            numberOfExercises: "Количество упражнений",
            exerciseDuration: "Длительные упражнения",
            second: "Раз",
            avgScore: "Средний балл",
            speechCraftAvgScore: "Средняя оценка",
            emotionAvgScore: "Средняя оценка эмоций",
            speedAvgScore: "Выражение среднего балла",
            flowAvgScore: "Средний балл процесса",
            practiceCostTime: "Накопленная продолжительность",
            totalPracticeTimes: "Совокупное число",
            participateCommitRate: "Участие в уровне соответствия",
            participateExcellentRate: "Участие в превосходном уровне",
          },
        },
        index: {
          employeeProfile: "Портрет сотрудника",
          overview: "Обзор",
          personalSignage: "Персональный канбан",
          ongoingExercises: "Упражнения в процессе",
          ongoingExams: "Экзамен в процессе",
          practiceTaskProgress: "Тренируйтесь на выполнении заданий",
          examTaskProgress: "Прогресс экзаменационных заданий",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord:
                "Пожалуйста, выберите концептуальное слово",
              sessionDetectionSettings: "Настройки обнаружения сеанса",
              successfullySaved: "Успешное сохранение",
              abnormalSpeechSpeed: "Аномальная скорость речи",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Поддерживает настраиваемые стандарты определения скорости речи, и результаты будут оцениваться как нормальная скорость речи, слишком высокая скорость речи и слишком медленная скорость речи. При ненормальной скорости речи (слишком быстро/слишком медленно), баллы будут уменьшены в соответствии с правилами подсчета очков",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Быстрая скорость речи: больше, чем в минуту",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Слово, скорость речи слишком низкая: меньше, чем в минуту",
              charactersIgnoringLessThan: "Слово, игнорируя меньше",
              aSentenceOfWords: "Слово предложение",
              excessiveModalParticles: "Слишком много слов",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Поддерживает пользовательские слова и устанавливает правила для слишком большого количества слов. Когда слишком много слов, очки вычитаются в соответствии с правилами подсчета очков",
              theNumberOfRepetitionsIs: "Число повторений",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "В то время было установлено, что слишком много слов",
              definitionOfSensitiveViolations:
                "Чувствительное определение нарушения",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Поддерживает пользовательский тезаурус нарушенных слов и обнаруживает, есть ли нарушенные слова в предложении. При появлении слов-нарушителей очки вычитаются по правилам подсчета очков",
              violationWords: "Незаконные слова:",
              emotionalDetection: "Обнаружение эмоций",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Эмоциональное обнаружение выполняется в соответствии с системной эмоциональной моделью диалога, и результаты будут оцениваться как нормальные эмоции и отрицательные эмоции (включая отрицательные, злые и жалобы). Когда возникает эмоциональный негативный, очки вычитаются в соответствии с правилами подсчета очков",
              preserve: "Сохранить",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Измерение оценки",
              ratingPoints: "Оценка баллов",
              explain: "Описание",
              type: "Тип",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Случайные данные" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Стандартное накопление речи',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Слова, студенты следуют за количеством слов",
                  aWordspan: "Слово </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Время </span><br /><span style = "margin-left: 8px">· Скорость речи слишком медленная',
                  timesspan: "Время </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Слишком много слов',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Оценка" },
                  item: {
                    violationOnceReduced: "1 сокращение нарушений",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Нарушения скорости речи (включая слишком быструю скорость речи и слишком медленную скорость речи)",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Слишком много слов",
                    oneNegativeDecreaseInEmotions:
                      "1 отрицательное снижение настроения",
                    branch: "Очки",
                  },
                  wrap: { name: "Название", preserve: "Сохранить" },
                },
              },
              index: {
                complete: "Полный контент",
                keyHit: "Точка попадания",
                accurateFollowupReading: "Точное чтение",
                languageNorm: "Языковая спецификация",
                speechSpeed: "Скорость речи",
                modalParticles: "Слова",
                emotionallyPositive: "Эмоциональный положительный",
                scoringModel: "Модель оценки",
                successfullyModified: "Модификация прошла успешно",
                newSuccessfullyCreated: "Новый успех",
              },
            },
            index: {
              scoringModel: "Модель оценки",
              newModel: "Новая модель",
              model: "Модель",
              type: "Тип",
              operation: "Операция",
              preview: "Предварительный просмотр",
              edit: "Редактирование",
            },
          },
          index: {
            scoringModel: "Модель оценки",
            ratingDimension: "Измерение оценки",
            sessionDetectionSettings: "Настройки обнаружения сеанса",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Пожалуйста, выберите",
                hit: "Хит",
                misses: "Удар",
              },
            },
          },
          index: {
            duration: "Продолжительность",
            processScore: "Оценка процесса",
            branch: "Очки",
            contentCompleteness: "Полный контент",
            scriptScore: "Голосовой счет",
            keyHitRate: "Точка скорости попадания",
            emotionalScore: "Оценка эмоций",
            negativeProportionOfEmotions: "Эмоциональная отрицательная доля",
            expressionScore: "Оценка выражения",
            theProportionOfAbnormalSpeechSpeed: "Доля аномальной скорости речи",
            excessiveProportionOfModalParticles: "Слишком много слов",
            learningRecordData: "Данные записи обучения",
            staff: "Сотрудники",
            curriculum: "Курсы",
            mainPoints: "Основные моменты",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Форма",
                        lineChart: "Линейный график",
                        barChart: "Гистограмма",
                        pieChart: "Круговые диаграммы",
                      },
                      index: { switchingTypes: "Тип переключения" },
                    },
                  },
                  index: {
                    saveSuccessful: "Успешное сохранение",
                    editChart: "Редактирование диаграмм",
                    chartName: "Название диаграммы",
                    deleteSuccessful: "Удаление прошло успешно",
                    refresh: "Обновить",
                    export: "Экспорт",
                    areYouSureToDeleteIt: "Определились с удалением?",
                    delete: "Удалить",
                    viewFilteringCriteria: "Просмотр условий скрининга",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Успешный экспорт, перейдите в Центр задач для просмотра",
              },
            },
            header: {
              index: {
                saveSuccessful: "Успешное сохранение",
                deleteSuccessful: "Удаление прошло успешно",
                editReport: "Редактирование отчетов",
                reportName: "Название отчета",
                refresh: "Обновить",
                areYouSureToDeleteIt: "Определились с удалением?",
                delete: "Удалить",
                exportToExcel: "Экспорт Excel",
                exportSnapshot: "Экспорт моментальных снимков",
                export: "Экспорт",
                authority: "Разрешения",
                permissionSettings: "Настройки разрешений",
                reportVisiblePermissions: "Видимые разрешения для отчета",
                visibleToEveryone: "Все видны.",
                onlyVisibleToOneself: "Виден только сам",
                designatedPersonnelVisible: "Видимый назначенный персонал",
                pleaseSelect: "Пожалуйста, выберите",
                reportDataPermissions: "Разрешения на данные отчета",
                restrictedByUserPermissions: "Ограничено правами пользователя",
                visiblePeopleAreTheSame: "Видно, что люди одинаковы",
              },
            },
            titleFooter: {
              index: {
                addReport: "Добавление отчетов",
                reportName: "Название отчета",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Успешный экспорт, перейдите в Центр задач для просмотра",
            dataQueryInProgress: "В запросе данных...",
            customKanban: "Пользовательский канбан",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Форма",
                lineChart: "Линейный график",
                barChart: "Гистограмма",
                pieChart: "Круговые диаграммы",
              },
              index: {
                successfullyAdded: "Добавить успех",
                viewSQL: "Посмотреть sql",
                export: "Экспорт",
                addToReport: "Добавление в отчет",
              },
            },
            refer: {
              hooks: {
                data: "Данные",
                interrogativeSentence: "Вопрос",
                historicalQuery: "Исторические запросы",
              },
              index: {
                copySuccessful: "Успешное копирование",
                copy: "Копирование",
              },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Несоответствие",
                        meetingStandards: "Стандарт",
                        excellent: "Отлично.",
                        notStartedYet: "Не начал",
                        inProgress: "В процессе",
                        completed: "Завершено",
                        incomplete: "Не завершено",
                      },
                    },
                  },
                  const: {
                    taskName: "Название задачи",
                    department: "Секторы",
                    studentName: "Имя студента",
                    scoringResults: "Результаты",
                    taskCompletionStatus: "Состояние завершения задачи",
                  },
                  index: { pleaseSelectADimension: "Выберите измерение" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Ввод @ Открываемая рамка для выбора измерения",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Пожалуйста, введите заявление запроса для прямого запроса",
                query: "Запрос",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Успешный экспорт, перейдите в Центр задач для просмотра",
            dataQueryInProgress: "В запросе данных...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Поиск по ключевым словам",
                previous: "Предыдущий",
                next: "Следующий",
                theObjectThatTriggersTheScrollingEventIs:
                  "Объект, который запускает событие прокрутки",
                userScrolling: "Пользователи прокрутки",
              },
            },
            summary: {
              index: {
                reminder: "Наговорка:",
                edit: "Редактирование",
                name: "Название",
                hintLanguage: "Направление",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Извлечение точек зрения" },
            },
            outline: {
              index: {
                numberOfKeywords: "Количество ключевых слов",
                speaker: "Говорящий человек",
                speechDuration: "Длительное время разговора",
                proportionOfSpeaking: "Доля речи",
                maximumSpeechDuration:
                  "Максимальная продолжительность выступления",
                numberOfQuestions: "Количество вопросов",
                averageSpeechSpeed: "Средняя скорость речи",
                conversationalActions: "Сеансовые действия",
                contentSummary: "Резюме содержания",
                generateSummary: "Создание резюме",
                keywordCloud: "Ключевые слова облако",
              },
            },
          },
          index: {
            outline: "Резюме",
            viewpoint: "Точка зрения",
            contentSummary: "Резюме содержания",
            viewpointExtraction: "Извлечение точек зрения",
            summaryOfKeyPoints: "Краткое изложение основных моментов",
            videoAnalysis: "Анализ видео",
          },
        },
        list: {
          index: {
            delete: "Удалить",
            uploadSuccessful: "Успешная загрузка",
            videoList: "Список видео",
            uploadVideo: "Загрузить видео",
            videoName: "Название видео",
            uploadTime: "Время загрузки",
            endTime: "Время окончания",
            analyzeProgress: "Анализ прогресса",
            haveInHand: "В процессе",
            completed: "Завершено",
            fail: "Провал",
            toBegin: "Начать.",
            operation: "Операция",
            see: "Посмотреть",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Добавить рабочее время" },
        },
      },
      index: {
        businessWorkingHours: "Рабочие часы",
        newlyBuild: "Новое строительство",
        name: "Название",
        describe: "Описание",
        operation: "Операция",
        edit: "Редактирование",
        delete: "Удалить",
        editBusinessWorkingHours: "Редактирование рабочего времени в бизнесе",
        newBusinessWorkingHours: "Новое рабочее время",
        workingHours: "Время работы",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Интеллектуальное извлечение",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Вы подтверждаете, что хотите отменить? После отмены больше не может быть восстановлено",
        },
      },
    },
    accountManagement: {
      securitySetting: "Настройки безопасности",
      accountManagement: "Управление счетами",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "До",
            switchToIPAddressMode: "Переключение в режим IP-адреса",
            switchToIPSegmentMode: "Переключение в режим сегмента IP",
          },
        },
      },
      index: {
        saveSuccessful: "Успешное сохранение",
        pleaseImproveTheConfiguration: "Пожалуйста, улучшите конфигурацию",
        securitySetting: "Настройки безопасности",
        employeeLoginIPRestrictions: "Ограничение доступа сотрудников к IP",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Ограничьте диапазон IP-адресов, в который могут входить сотрудники, и не разрешите вход за пределы диапазона. Действия только для веб-сайтов, мобильные приложения не ограничены, администраторы не ограничены.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "При добавлении или изменении введите IP-сегмент (тот же сегмент C) или фиксированный IP-адрес внешней сети.",
        allowLoginIPRange:
          "Диапазон IP-адресов, разрешенных для входа в систему",
        preserve: "Сохранить",
      },
    },
    coachAdminEntry: { speechRecognition: "Распознавание речи" },
    billingCenter: {
      template: {
        details: "Подробная информация",
        languageType: "Тип языка",
        aSREngine: "Двигатель ASR",
        voiceQualityQuota: "Голосовая квота контроля качества",
        realtimeData: "Данные в реальном времени",
        calculatedByDay: "По дням",
      },
      component: {
        aSRName: "Название ASR",
        language: "Язык",
        remainingQuota: "Остаточная квота",
        hours: "Час",
        usedQuota: "Использованы квоты",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Операция успешно сохранена!",
        deletedSuccessfully: "Удаление прошло успешно!",
        pleaseSelectLanguage: "Выберите язык",
        pleaseConfigureRules: "Пожалуйста, настройте правила",
        updateSuccessful: "Обновление прошло успешно!",
        operationSuccessful: "Операция прошла успешно!",
      },
      template: {
        aSRLanguage: "Язык ASR",
        pleaseSelect: "Пожалуйста, выберите",
        model: "Модель",
        pleaseSelectLanguage: "Выберите язык",
        delete: "Удалить",
        newASRModel: "Новая модель ASR",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Анализ конкурентных продуктов" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Фильтр комментариев поставщика электроэнергии",
        allComments: "Все комментарии",
        ecommerceReviewManagement:
          "Управление комментариями электронной коммерции",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Фильтр комментариев социальных СМИ",
        allComments: "Все комментарии",
        socialMediaReviewManagement:
          "Управление комментариями в социальных средствах массовой информации",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Анализ конкурентных продуктов",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Аватар",
        realname: "Имя",
        nickname: "Прозвище",
        name: "Имя",
        email: "Аккаунт (почтовый ящик)",
        mobile: "Телефон",
        agentId: "Номер работы",
        position: "Пост",
        role: "Роль",
        actions: "Операция",
        createUser: "Новые сотрудники",
        editUser: "Редактирование сотрудников",
        account: "Номер счета",
        originPassword: "Начальный пароль",
        newPassword: "Новый пароль",
        resumePassword: "Подтверждение пароля",
        userGroup: "Группа сотрудников",
        authorizationGroup: "Группы разрешений",
        resetPassword: "Сброс пароля",
      },
      userGroups: {
        id: "ID",
        name: "Название",
        userCount: "Количество сотрудников",
        actions: "Операция",
        createUserGroup: "Добавлена группа сотрудников",
        editUserGroup: "Редактирование группы сотрудников",
        member: "Члены",
      },
      role: {
        id: "ID",
        name: "Название",
        actions: "Операция",
        all: "Все.",
        taskName: "Задачи",
        readTask: "Просмотр задач",
        manageTemplate: "Управление шаблонами",
        manualInspection: "Ручная повторная проверка",
        readResultAll: "Результаты просмотра",
        readResultPersonal: "Результаты просмотра (личный)",
        readProcess: "Проверка качества процесса",
        readReport: "Статистика и отчеты",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Дата",
        taskName: "Название",
        size: "Размер",
        status: "Состояние",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Название",
            remark: "Описание",
            actions: "Операция",
          },
          checkPoint: {
            name: "Название",
            remark: "Описание",
            type: "Тип",
            gradeType: "Метод оценки",
            conditionLogic: "Правила",
            predeterminedScore: "Оценка",
            weight: "Вес",
            status: "Состояние",
            actions: "Операция",
            switchOn: "Включение",
            switchOff: "Отключение",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Время",
            customer: "Клиенты",
            customerPhone: "Телефон клиента",
            callType: "Тип вызова",
            source: "Источник",
            voiceRecording: "Запись звонков",
            graded: "Рейтинг",
            openingGreeting: "Вступительное приветствие",
          },
          checkPointList: {
            name: "Точка контроля качества",
            type: "Тип",
            deductScore: "Оценка",
            isHit: "Удар ситуации",
            status: "Обзор",
            finalDeductScore: "Подтвердите вычеты",
            hit: "Хит",
            notHit: "Удар",
          },
        },
      },
    },
    custom: { keywords: "Ключевые слова для диалога" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Настройка системы" },
      systemIntegration: {
        text: "Системная интеграция",
        subTitle: {
          udesk: "Система обслуживания клиентов Udesk",
          udeskWhite: "Система обслуживания клиентов",
          wechat: "Корпоративное WeChat",
          taobao: "Taobao Qianiu",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Управление полями контроля качества" },
      customerField: { text: "Поля клиента" },
      speechRecognition: { text: "Распознавание речи" },
      user: { text: "Управление персоналом" },
      userGroups: { text: "Управление группами сотрудников" },
      role: { text: "Управление ролями" },
      dataMigration: { text: "Миграция данных" },
      taskCenter: { text: "Центр миссий" },
      recordUpload: { text: "Загрузка локальных записей" },
      anomalousData: { text: "Аномальные данные" },
      systemLog: { text: "Журнал сообщений" },
      msgCenter: { text: "Центр сообщений" },
      wechatDocking: { text: "Предприятие микро-стыковка" },
    },
    tasks: {
      homePage: { text: "Главная" },
      workbench: { text: "Рабочий стол" },
      semanticIntelligence: { text: "Семантический интеллект" },
      intelligentAnalysis: { text: "Интеллектуальный анализ" },
      intelligentLabel: { text: "Умные этикетки" },
      admin: { text: "Управление" },
      callManage: { text: "Управление звонками" },
      dialogueManage: { text: "Управление диалогом" },
      ticketManage: { text: "Управление наработками" },
      dataAnalysis: { text: "Анализ данных" },
      customerService: { text: "Центр обслуживания клиентов" },
      clientCenter: { text: "Центр обслуживания клиентов" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Учебный центр" },
      templates: { text: "Управление шаблонами" },
      wechatQaTasks: { text: "Корпоративный WeChat Рабочий стол" },
      wechatQaSetting: { text: "Конфигурация корпоративного анализа WeChat" },
      wechatQaChartRecord: { text: "Enterprise WeChat Conversation" },
      wechatStaffCenter: { text: "Центр микросотрудников" },
      callAnalysis: { text: "Анализ сеанса" },
      caseBaseFolder: { text: "Библиотека дел" },
      coachTask: { text: "Задачи коучинга" },
      teamAnalysis: { text: "Анализ команды" },
      customer: { text: "Центр обслуживания клиентов" },
      analysisEnable: { text: "Аналитическая энергия" },
      analysisHelp: { text: "Анализ помощи" },
      analysisStudy: { text: "Анализ обучения" },
      analysisReview: { text: "Анализ повторного диска" },
      analysisTags: { text: "Интеллектуальный анализ этикеток" },
      analysisSentiment: { text: "Анализ общественного мнения" },
      analysisBusiness: { text: "Бизнес-анализ" },
      groupTest: { text: "Тестирование групп" },
    },
    navBar: {
      billingCenter: "Центр помощи",
      uploadDownload: "Загрузить центр загрузки",
      setting: "Настройка системы",
    },
  },
  fix: {
    selectType: "Выбор типа:",
    all: "Все.",
    call: "Звонки",
    text: "Диалог",
    wechat: "Enterprise WeChat Conversation",
    businessTrendAnalysis:
      "Анализ бизнес-тенденций в классификации первого уровня",
    noEchart: "Нет данных на графике",
    checkPointisRequired: "Настройка правила обязательна для заполнения",
    voiceDownLoad: "Скачать запись",
    exportTxt: "Экспорт текста TXT",
    exportWord: "Экспорт текста Word",
    sureReRole: "Подтвердите инвертировать роль говорящего?",
    sureRecover: "Подтвердите исправление ошибок?",
    sureReAsr: "Подтвердите повторную идентификацию?",
    reRole: "Вращение говорящего",
    recover: "Повторная коррекция ошибок",
    reAsr: "Повторная идентификация",
    recordingOperation: "Операции записи",
    onlySave30Day: "(Только 30 дней)",
    appealError:
      "Пожалуйста, подайте жалобу на пункт проверки качества до подачи",
    exportToTaskCenter: "Успешно экспортирован в центр загрузки",
    caseBaseLimit: "Библиотека случаев не может превышать 50",
    unnamed: "Без имени",
    wordSpeed: "Слово/очки",
    hit: "Хит",
    checkPoint: "Точка контроля качества",
    realTimeAdditionTip1:
      "Добавление в режиме реального времени: проверка качества начинается, когда данные попадают в систему контроля качества",
    realTimeAdditionTip2:
      "Добавление времени: синхронизация автоматически добавляет данные для проверки качества",
    null: "Нет.",
    collcetedProps: "Поля сбора",
    editingNameNow: "Имя редактирует",
    upToFourLevels: "Классификация до четырех уровней",
    default: "По умолчанию",
    nameCantBeNull: "Имя не может быть пустым",
    editingNow: "Есть операция в редактировании",
    nameError: "Неверное имя",
    nameMaxLength8: "Максимальная длина имени 8",
    callTime: "Время разговора",
    sentimentValue: "Значение настроения клиента",
    agentSentimentValue: "Настроение обслуживания клиентов",
    sentimentAnalysis: "Анализ настроений",
    associatedData: "Данные по дороге",
    notifyTheOwner: "Уведомление владельца группы",
    notifyTheEmployee: "Уведомление самого сотрудника",
    notifyTheOwnerTip:
      "Владелец группы будет уведомлен, когда тип сеанса-групповой чат",
    customNotifyEmployee: "Пользовательские уведомления для сотрудников",
    customNotifyEmployeeTip:
      "Триггерный персонал является сотрудником, и сам сотрудник, на который принадлежит сообщение, уведомляется",
    clickToLearnMore: "Нажмите, чтобы узнать больше",
    repeatMarkingTip:
      "Эта запись была помечена этой точкой контроля качества без повторной маркировки",
    successfulEvaluation: "Успешная оценка",
    evaluationFailed: "Оценка не удалась",
    qualityInspectionScore: "Оценка качества",
    semanticTags: "Семантические метки",
    smartTags: "Умные этикетки",
    collect: "Сбор информации",
    appealRecord: "Запись жалобы",
    satisfy: "Удовлетворение",
    notSatisfy: "Недовольны",
    appealPlaceHolder: "Пожалуйста, введите причину жалобы",
    giveUp: "Сдаться",
    rejectReasonPlaceHolder: "Пожалуйста, введите причину отклонения",
    reject: "Отклонить",
    changeRejectReason: "Изменение причины отклонения",
    pass: "Через",
    revise: "Модификация",
    machineEvaluation: "Оценка машины",
    poor: "Плохо.",
    excellent: "Хорошо.",
    moderate: "Общие",
    mustMarked: "Точка контроля качества должна быть отмечена",
    pleaseChoose: "Пожалуйста, выберите",
    manualEvaluation: "Ручная оценка",
    qualityInspectionTemplate: "Применимый шаблон контроля качества",
    collectionTemplate: "Применимые шаблоны сбора",
    keywordsWordCloud: "Ключевые слова облако",
    editCategoryLabel: "Редактирование тегов классификации",
    justCall: "Только смотреть на звонки",
    justConversation: "Просто посмотрите на диалог",
    batchOperation: "Операции партии",
    customerServicesGroup: "Группа обслуживания клиентов",
    joinGroup: "Присоединяйтесь к группе",
    removeGroup: "Выезд из группы",
    enterInformationEntity: "Введите информацию о содержании объекта",
    enterNotBeNull: "Ввод не может быть пустым",
    operationGroup: "Группа операций",
    customerServiceSelected: "Отобранное обслуживание клиентов",
    callAndConversation: "Звонок/разговор",
    conversation: "Диалог",
    system: "Системы",
    customize: "Пользовательские",
    effective: "Эффективно",
    invalid: "Недействительно",
    successfulAndToTaskCenter: "Выполнение успешно, проверьте в Центре задач",
    recalculationFailed: "Сбой пересчета",
    selectRecalculatedIndicator: "Выберите индикатор для пересчета",
    source: "Выберите источник данных",
    placeholder: "Введите ключевые слова",
    cumulative: "Кумулятивный тип",
    average: "Средний тип",
    distribution: "Закон распределения",
    originalDataCannotEmpty: "Исходные данные не могут быть пустыми",
    inspection: "Проверка качества",
    check: "Выборочная проверка",
    review: "Обзор",
    draftSavedSuccessfully: "Успешное сохранение черновика",
    optimization: "Оптимизация",
    agentName: "Название обслуживания клиентов",
    allTasks: "Все задачи контроля качества",
    datePickerSelect: "Выбор даты",
    callSelect: "Выбор диалога",
    taskSelect: "Выбор задачи",
    genReport: "Создание отчетов",
    dataSource: "Источник данных",
    callsTotal: "Общий объем звонков",
    intelligentQualityInspection: "Интеллектуальная проверка качества",
    percentage: "Доля",
    artificialSampling: "Ручная выборочная проверка",
    appealNumber: "Количество жалоб",
    totalConversation: "Общий объем диалога",
    samplingReviewRate: "Коэффициент повторной проверки",
    data: "Данные",
    inspectionResults: "Результаты проверки качества",
    inspected: "Проверка качества",
    totalScore: "Общая оценка",
    learnMore: "Посмотреть больше",
    inspectionEffect: "Эффект контроля качества",
    allTotal: "Общая сумма",
    manualInspection: "Ручная повторная проверка",
    artificialReview: "Ручной обзор",
    approvalAmountAndRate: "Утвержденный объем и уровень одобрения",
    customerScore: "Оценка обслуживания клиентов",
    inspectionRecommendations: "Рекомендации по проверке качества",
    saveDraft: "Сохранить черновик",
    historicalAdvice: "Исторические рекомендации",
    advicePlaceHolder: "Пожалуйста, введите предложение...",
    upload: "Загрузка файлов",
    customAdd: "Добавить фильтр",
    advancedConditions: "Премиум-условия",
    keyWord: "Ключевые слова",
    containsAll: "Содержит все",
    containsAny: "Содержит произвольные",
    matchAny: "Матч произвольный",
    matchAll: "Матч все",
    smartTagsOne: "Умная этикетка один",
    smartTagsTwo: "Интеллектуальная этикетка II",
    season: "Сезон",
    month: "Месяц",
    week: "Неделя",
    day: "Боже!",
    hour: "Время",
    notMoreThan366: "Время не может превышать 366 дней",
    propsCannotBeNull: "Есть поля, которые не заполнены",
    other: "Другие",
    correlationCoefficient: "Коэффициент корреляции",
    repetitionRate: "Скорость повторения",
    configurationItemsMoreThan20: "Добавить до 20 элементов конфигурации",
    conversionAnalysis: "Анализ конверсии",
    correlationAnalysis: "Корреляционный анализ",
    successfulExportAndToTaskCenter:
      "Успешный экспорт, перейдите в Центр задач для просмотра",
    typeSelect: "Выбор типа",
    exportExcel: "Экспорт excel",
    exportPng: "Экспорт png",
    noDataChart: "Нет данных на графике",
    placeOperator: "Пожалуйста, выберите смарт-этикетку",
    placeAdvancedConditions: "Пожалуйста, выберите расширенные условия",
    createAdvancedConditions: "Новый расширенный скрининг",
    moveUp: "Вниз вверх",
    moveDown: "Сниз",
    moveLeft: "Двигаться влево",
    moveRight: "Сдвиг вправо",
    statusChangeSuccess: "Изменение состояния успешно",
    editSuccess: "Модификация прошла успешно",
    saveSuccess: "Успешное сохранение",
    firstPage: "Главная",
    lastPage: "Хвост страницы",
    customerInfo: "Информация о клиенте",
    type: "Тип",
    NumberTypeErrorMessage:
      'Информационный контент поля цифрового типа может содержать только цифры или "."',
    taskStartPrompt: "Советы по открытию задачи",
    startConfirm:
      "Пожалуйста, нажмите кнопку подтверждения, чтобы открыть задачу контроля качества",
    continueToSubmitWithoutEvaluation: "Продолжение представления без оценки",
    rejectTip:
      "Никаких изменений не обнаружено, подтверждаете ли вы прямое отклонение всех жалоб?",
    unmarkedSelfLearningTip:
      "У вас есть неотмеченные пункты контроля качества самообучения",
    markedSuccessfully: "Успешная маркировка",
    markingFailed: "Сбой маркировки",
    exceedingLength: "За пределами длины",
    uncategorized: "Без классификации",
    addSuccess: "Новый успех",
    deleteSuccess: "Удаление прошло успешно",
    featureName: "Название элемента",
    informationEntity: "Информационная сущность",
    standardAnswer: "Стандартный ответ",
    keyElementAnswer: "Ответы на ключевые элементы",
    generalStatement: "Общие заявления (без запроса)",
    generalQuestionsAndConfirmation: "Общие сомнения и подтверждение",
    specialQuestionsAndAnswers: "Специальные вопросы и ответы",
    agent: "Обслуживание клиентов",
    customer: "Клиенты",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Критические элементы Семантическая метка и информационный объект не могут быть пустыми одновременно",
    standardSentence: "Стандартное предложение",
    acquaintance: "Знакомство",
    smartUePlaceholder: "Введите имя интеллектуального взаимодействия",
    typeSelectPlaceholder: "Выберите тип",
    keyElement: "Ключевые элементы",
    reQuerySementicTags: "Повторный выбор семантической метки",
    querySementicTags: "Выбор семантической метки",
    QATestForm: "Форма тестирования вопросов и ответов",
    selectNotBeNull: "Выбор не может быть пустым",
    featureDetectionTarget: "Цели обнаружения элементов",
    categorized: "Классификация",
    effectiveness: "Эффективность",
    hideAdvancedSettings: "Скрыть расширенные настройки",
    showAdvancedSettings: "Отображение расширенных настроек",
    generalStatementOnly: "Общие заявления",
    generalQuestions: "Общие сомнения",
    affirmativeExpression: "Определенные выражения",
    negativeIntention: "Отрицательное намерение",
    searchSementicTags: "Запрос семантических тегов",
    choiceQuestion: "Выбираемые вопросительные предложения",
    selectiveAnswer: "Отборочные ответы",
    sementicTagsPlaceholder: "Введите содержание семантической метки",
    openSpecialQuestion: "Особые вопросы открытого типа",
    openAnswer: "Открытые ответы",
    customQA: "Пользовательские вопросы и ответы",
    questions: "Спроси",
    dataPlaceholder: "Введите содержание",
    reply: "Ответить",
    notFindSuitableLabel: "Не нашел правильную этикетку",
    addSemanticTag: "Добавление новой семантической метки",
    intelligentInteraction: "Интеллектуальное взаимодействие",
    matching: "Матч",
    bout: "Раз",
    keywordMatch: "Соответствие ключевых слов",
    addFilterConditions: "Добавить условия фильтрации",
    buttonStartCollectionTask: "Нажмите кнопку ОК, чтобы открыть задачу сбора",
    buttonStartTask:
      "Нажмите кнопку «ОК», чтобы открыть задачу контроля качества",
    ruleConfigurationIsRequired: "Настройка правила обязательна для заполнения",
    asrRunning: "Выполнение ASR",
    errorCorrectionExecution: "Выполнение исправления ошибок",
    emotionalVolumeRecognition: "Распознавание эмоциональной громкости",
    dataPreprocessing: "Предварительная обработка данных",
    inspectionExecution: "Проверка качества",
    businessId: "Бизнес Id",
    businessTime: "Время работы",
    createTime: "Время создания",
    abnormalReason: "Необычные причины",
    singleChat: "Одиночный чат",
    groupChat: "Групповой чат",
    taskContent: "Содержание задачи",
    submissionTime: "Время представления",
    startTime: "Время начала",
    endTime: "Время окончания",
    runningTime: "Длительные эксплуатационные времени",
    totalAmountOfData: "Общий объем данных",
    resultsOfThe: "Результаты выполнения",
    operator: "Оператор",
    taskDetails: "Детали задачи",
    executiveFunction: "Исполнительная функция",
    qualityCheckTasks: "Задачи контроля качества",
    implementationDetails: "Детали выполнения",
    conditionalRules: "Условные правила",
    smartCondition: "Интеллектуальные условия",
    goThis: "Перейти к этому",
    pleaseMarkClassification: "Пожалуйста, пометьте классификацию",
    advancedScreening: "Расширенный скрининг",
    noDataToManipulate: "Нет данных, которые можно использовать",
    concept: "Понятие слова",
    checkPoint1: "Конфигурация правил",
    requiredFieldsCannotEmpty: "Обязательно не может быть пустым",
    rule: "Правила",
    afterFixUnit: "Слово",
    unmarkedSelfLearningPointsTip:
      "У вас есть неотмеченные пункты контроля качества самообучения",
    onlyReservedFor7Days: "(Только 7 дней)",
    downloadError: "Сбой загрузки",
    pause: "Приостановка",
    continue: "Продолжайте.",
    recalcScore: "Пересчет только баллов (включая рейтинги)",
    recalculatePointsAndScores:
      "Повторный учет точек контроля качества и баллов",
    recalculatePointsAndScoresWithEmtry:
      "Повторный учет точек контроля качества и баллов (точки контроля качества пусты)",
    inProgress: "В процессе",
    toBeExecuted: "В ожидании исполнения",
    executeImmediately: "Выполнить немедленно",
    specialElements: "Специальные элементы",
    logicRule: "Логические правила",
    every: "Несколько матчей",
    hitRecall: "Хит отзыв слова",
    notHitRecall: "Отзывное слово",
    hitRecallRegular: "Хит напоминает регулярный",
    notHitRecallRegular: "Не попасть в регулярное",
    hitRule: "Правила попадания",
    notHitRule: "Правила пропущенных",
    hitSimilarSentence: "Хит похожие предложения",
    notHitSimilarSentence: "Похожее предложение пропущено",
    hitExcludeSimilarSentences: "Хит, чтобы исключить похожие предложения",
    notHitExcludeSimilarSentences: "Исключить похожие предложения",
    updateSuccess: "Обновление прошло успешно",
    qualify: "Регулярный",
    recallKeyWord: "Отзыв ключевых слов",
    grammar: "Грамматические правила",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Сбой изменения действительности, попробуйте еще раз",
    postAutoMateTasksCaseByTaskId:
      "Сбой сохранения синхронизации конфигурации библиотеки дел",
    getAutoMateTasksCaseByTaskId:
      "Сбой получения синхронизации конфигурации библиотеки дел",
    postInspectionRemarkModelConfigs: "Ошибка сохранения шаблона заметок",
    putInspectionRemarkModelConfigsById: "Ошибка сохранения шаблона заметок",
    getInspectionRemarkModelConfigsById:
      "Ошибка получения данных шаблона заметок",
    postKnowledgeDatas: "Создание базы знаний не удалось, попробуйте еще раз",
    putKnowledgeDatasById: "Ошибка изменения базы знаний, попробуйте еще раз",
    getLibraryFiles: "Сбой получения списка ценовой папки",
    getLibraryCalls: "Сбой получения файла",
    getLibraryFilesSearch: "Сбой поиска",
    putLibraryFilesMoveById: "Спросите о сбой перемещения клипа",
    putLibraryCallsById: "Переименование файла не удалось!",
    postLibraryFiles: "Сбой добавления папки",
    putLibraryFilesById: "Неудачное переименование папки",
    deleteLibraryFilesById: "Сбой удаления",
    deleteLibraryCallsById: "Сбой удаления",
    postLibraryCalls: "Добавление сбоя",
    getVoiceUploadResultById: "Сбой экспорта результатов загрузки",
  },
};
