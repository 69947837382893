import React, { useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs } from 'udesk-ui';
import Locales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { useEffect } from 'react';
import './style.scss';

const { TabPane } = Tabs;

function CollectionDataAdd(props) {
    const locales = Locales['current'];
    const ADD_TYPES = [
        {
            pathName: 'coachMsgCenterShare',
            key: 'coach-share',
            text: locales.components.pages.messageCenter.shareTitle,
            id: '/coach-share',
        },
    ];
    const [activeKey, setActiveKey] = useState<'coach-share'>('coach-share');
    const onAddTypeChange = (activeKey) => {
        setActiveKey(activeKey);
        const tab: any = ADD_TYPES.find((t) => t.key === activeKey) ?? {};
        const routeOptions = {
            history: props.history,
            routeName: tab.pathName,
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    useEffect(() => {
        const currentPath = ADD_TYPES.find((item) => props.history.location.pathname.indexOf(item.id) >= 0);
        setActiveKey(currentPath?.key ?? ('coach-share' as any));
    }, [props.history.location]);
    return (
        <Page
            pageBodyClassName='client-center-root-page-index'
            title={locales.menu.admin.msgCenter.text}
            padding={true}
            footer={
                <Tabs activeKey={activeKey} onChange={onAddTypeChange}>
                    {props.routes.map((item, i) => {
                        const tab: any = ADD_TYPES.find((t) => t.pathName === item.name) ?? {};
                        if (tab.hidden) return null;
                        return (
                            <TabPane tab={tab.text} key={tab.key}>
                            </TabPane>
                        );
                    })}
                </Tabs>
            }
        >
            <SubRoutes
                route={props.routes.find((r) => r.name === ADD_TYPES.find((i) => i.key === activeKey)!.pathName)}
            />
        </Page>
    );
}

export default React.memo(CollectionDataAdd);
