// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type { GetReviewCallNextByIdByTypeParam, BaseResponseint } from '../../../../../types';

/**
 * findNext
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/next/{id}/{type}
 * @param id
 * @param type
 */
export function getReviewCallNextByIdByType(
    options: ApiOptions<GetReviewCallNextByIdByTypeParam, never> & {
        segments: GetReviewCallNextByIdByTypeParam;
    }
): Promise<BaseResponseint> {
    return request<never, GetReviewCallNextByIdByTypeParam, never>(
        '/review/call/next/{id}/{type}',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewCallNextByIdByType' }
    );
}

export const meta = [{ tags: ['通话记录'], path: '/review/call/next/{id}/{type}', method: 'get' }];
