// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetInspectionTestDatasByTaskIdParam,
    BaseResponseInspectionDataWrapper,
} from '../../types';

/**
 * 获取测试结果
 *
 * @export
 * @tags 质检规则测试
 * @link [GET] /inspectionTestDatas/{taskId}
 * @param taskId
 */
export function getInspectionTestDatasByTaskId(
    options: ApiOptions<GetInspectionTestDatasByTaskIdParam, never> & {
        segments: GetInspectionTestDatasByTaskIdParam;
    }
): Promise<BaseResponseInspectionDataWrapper> {
    return request<never, GetInspectionTestDatasByTaskIdParam, never>(
        '/inspectionTestDatas/{taskId}',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionTestDatasByTaskId' }
    );
}

export const meta = [
    { tags: ['质检规则测试'], path: '/inspectionTestDatas/{taskId}', method: 'get' },
];
