import React, { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Radio, Space, Spin, Switch } from 'udesk-ui';
import ContentScope, { TemplatePropsValue as ContentScopeValue } from '../content-scope';
import { Scope } from '../content-scope/const';
import { CustomFilter } from '../CustomFilter';
import { Enable, Mode } from './const';
import { postCallServiceSummaryFields } from 'src/api/callServiceSummaryFields';
import {
    getCallServiceSummaryFieldsById,
    putCallServiceSummaryFieldsById,
} from 'src/api/callServiceSummaryFields/{id}';
import { PostCallServiceSummaryFieldsBody } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';
import PromptArea from './components/PromptArea';
import Training from './components/Training';

const Wrapper = styled.div``;

type FormValues = Omit<PostCallServiceSummaryFieldsBody, 'generatedContent' | 'isEnable'> & {
    generatedContent: ContentScopeValue;
    isEnable: boolean;
    prompt?: string;
};

interface TemplateProps {
    type?: number;
    onBack?: () => void;
    id?: number;
    taskId?: number;
}

const Template: FC<TemplateProps> = (props) => {
    const { type, onBack, id, taskId } = props;

    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm<FormValues>();

    useEffect(() => {
        form.setFieldsValue({
            generatedContent: { scope: Scope.UNLIMITED },
            isEnable: false,
            generatedMethod: Mode.AUTO,
            filterCondition: {
                judgeStrategy: 1,
            },
        });

        if (id) {
            setLoading(true);
            getCallServiceSummaryFieldsById({ segments: { id } })
                .then((res) => {
                    form.setFieldsValue({
                        ...res.data,
                        generatedContent: res.data?.generatedContent?.length
                            ? { scope: Scope.LIMIT, content: res.data?.generatedContent }
                            : { scope: Scope.UNLIMITED },
                        isEnable: res.data?.isEnable === Enable.OPEN,
                        generatedMethod: res.data?.generatedMethod ?? Mode.AUTO,
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [form, id]);

    const onFinish = () => {
        if (!taskId) return;

        setLoading(true);
        form.validateFields()
            .then((values) => {
                const params: PostCallServiceSummaryFieldsBody = {
                    ...values,
                    taskId,
                    isEnable: values.isEnable ? Enable.OPEN : Enable.CLOSE,
                    generatedContent:
                        values.generatedContent.scope === Scope.LIMIT
                            ? values.generatedContent.content
                            : undefined,
                };

                const p = id
                    ? putCallServiceSummaryFieldsById(params, { segments: { id } })
                    : postCallServiceSummaryFields(params);

                p.then(() => {
                    onBack?.();
                }).finally(() => {
                    setLoading(false);
                });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <Wrapper>
            <Spin spinning={loading}>
                <Form<FormValues>
                    form={form}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={
                            /* 字段名称 */ UdeskLocales['current'].pages.tasks.manage.template
                                .automaticAdd.businessSummary.components.field.index.fieldName
                        }
                        name="name"
                        rules={[{ required: true }, { max: 20 }]}
                    >
                        <Input style={{ width: 500 }} />
                    </Form.Item>

                    <Form.Item
                        label={
                            /* 字段描述 */ UdeskLocales['current'].pages.tasks.manage.template
                                .automaticAdd.businessSummary.components.field.index
                                .fieldDescription
                        }
                        name="description"
                        rules={[{ max: 400 }]}
                    >
                        <Input.TextArea style={{ width: 500 }} autoSize />
                    </Form.Item>

                    <Form.Item
                        label={
                            /* 生成内容 */ UdeskLocales['current'].pages.tasks.manage.template
                                .automaticAdd.businessSummary.components.field.index.generateContent
                        }
                        name="generatedContent"
                    >
                        <ContentScope wrapperStyle={{ width: 500 }} />
                    </Form.Item>

                    <Form.Item label={/* 提示语 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.components.field.index.hintLanguage} name="prompt">
                        <PromptArea
                            getPromptParams={() => {
                                return new Promise((resolve, reject) => {
                                    form.validateFields()
                                        .then((values) => {
                                            const { name, description, generatedContent } =
                                                values || {};

                                            resolve({
                                                name,
                                                description,
                                                generatedContent:
                                                    generatedContent.scope === Scope.LIMIT
                                                        ? values.generatedContent.content
                                                        : undefined,
                                            });
                                        })
                                        .catch(() => {
                                            reject();
                                        });
                                });
                            }}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 4 }}>
                        <Training
                            getTrainingParams={() => {
                                return new Promise((resolve, reject) => {
                                    form.validateFields()
                                        .then((values) => {
                                            const { name, description, generatedContent, prompt } =
                                                values || {};

                                            resolve({
                                                name,
                                                description,
                                                generatedContent:
                                                    generatedContent.scope === Scope.LIMIT
                                                        ? values.generatedContent.content
                                                        : undefined,
                                                prompt,
                                            });
                                        })
                                        .catch(() => {
                                            reject();
                                        });
                                });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            /* 是否启用 */ UdeskLocales['current'].pages.tasks.manage.template
                                .automaticAdd.businessSummary.components.field.index.enabled
                        }
                        name="isEnable"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        label={
                            /* 生成方式 */ UdeskLocales['current'].pages.tasks.manage.template
                                .automaticAdd.businessSummary.components.field.index
                                .generationMethod
                        }
                        name="generatedMethod"
                    >
                        <Radio.Group>
                            <Radio value={Mode.AUTO}>
                                {/* 自动生成 */}
                                {
                                    UdeskLocales['current'].pages.tasks.manage.template.automaticAdd
                                        .businessSummary.components.field.index.automaticGeneration
                                }
                            </Radio>
                            <Radio value={Mode.MANUAL}>
                                {/* 手动触发 */}
                                {
                                    UdeskLocales['current'].pages.tasks.manage.template.automaticAdd
                                        .businessSummary.components.field.index.manualTrigger
                                }
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={
                            /* 筛选条件 */ UdeskLocales['current'].pages.tasks.manage.template
                                .automaticAdd.businessSummary.components.field.index.filterCondition
                        }
                        name="filterCondition"
                        rules={[{ required: true }]}
                    >
                        <CustomFilter type={type} wrapperStyle={{ width: 500 }} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 4 }}>
                        <Space>
                            <Button onClick={onBack}>
                                {/* 返回 */}
                                {
                                    UdeskLocales['current'].pages.tasks.manage.template.automaticAdd
                                        .businessSummary.components.field.index.return
                                }
                            </Button>
                            <Button type="primary" htmlType="submit">
                                {/* 保存 */}
                                {
                                    UdeskLocales['current'].pages.tasks.manage.template.automaticAdd
                                        .businessSummary.components.field.index.preserve
                                }
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </Wrapper>
    );
};

export default memo(Template);
