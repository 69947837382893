import Udesk from 'Udesk';
import type { Edge, Node } from '@reactflow/core';
import { IntelligentPartnerDialogFlowNodeFoundResponse } from 'src/api/types';
import { lineStyle, markerEnd } from '../components/const/lineStyle';
import { isDefined } from 'src/util/core';
export class NodeTypes {
    static allTypes = Udesk.enums.nodeTypes.map((node) => {
        return {
            ...node,
            label: node.name,
        };
    });

    static getNode(nodeType) {
        return Udesk.enums.nodeTypes.filter((node) => nodeType === node.id)[0];
    }
    static getNodeByKey(nodeTypeKey) {
        return Udesk.enums.nodeTypes[nodeTypeKey];
    }
}

type NodeFromBackend = IntelligentPartnerDialogFlowNodeFoundResponse;

class NodeEdge {
    animated = false;
    type = 'smoothstep';
    style = lineStyle;
    id;
    source;
    target;
    markerEnd;

    constructor(parentNodeId, node) {
        this.id = `e-${parentNodeId}-${node.id}`;
        this.source =  `${parentNodeId}`;
        this.target =  `${node.id}`;
        this.markerEnd = markerEnd;
    }

    active() {
        const activeColor = '#FF7A45';
        this.animated = true;
        this.style = {
            ...this.style,
            color: activeColor,
        };
        this.markerEnd = {
            ...markerEnd,
            color: activeColor,
        };
    }
}

export class NodeBuilder {
    nodes: Node<any>[] = [];
    edges: Edge<any>[] = [];
    constructor(backendNodes: NodeFromBackend[]) {
        // const backendNodesMap = backendNodes.reduce((prev, cur) => {
        //     prev[cur.id!] = cur;
        //     return prev;
        // }, {});
        
        backendNodes.forEach((node) => {
            // const parentNodeId = node.parentNodeId ? node.parentNodeId.split(',') : null;
            // if(parentNodeId && parentNodeId.map(id => backendNodesMap[id]).filter(Boolean).some(n => n.nodeType === Udesk.enums.nodeTypes.pptNode.id)) {
            //     (node as any).configType = Udesk.enums.pptConfigType.singlePage.id;
            // }
            // 这里是拿parentNodeId的类型区分，开始节点parentNodeId === undefined, 非开始节点 parentNodeId === ''.
            isDefined(node.parentNodeId && node.nodeType === Udesk.enums.nodeTypes.pptNode.id, () => {
                (node as any).configType = Udesk.enums.pptConfigType.singlePage.id;
            });
            this.nodes.push(this.buildNode(node));
            Array.prototype.push.apply(this.edges, this.buildEdge(node));
        });
    }

    buildNode(node: NodeFromBackend) {
        return createNode(node);
    }
    buildEdge(node: NodeFromBackend) {
        const parentNodeIds = Array.from(new Set((node.parentNodeId ?? '').split(',').filter(Boolean)));
        return parentNodeIds.map((parentNodeId) => new NodeEdge(parentNodeId, node)
        // ({
        //     id: `e-${parentNodeId}-${node.id}`,
        //     source: `${parentNodeId}`,
        //     type: 'smoothstep',
        //     target: `${node.id}`,
        //     // animated: true,
        //     markerEnd,
        //     style: lineStyle,
        // })
        );
    }

    // static buildBackendNodes(nodes: Node<any>[], edges: Edge<any>[]): NodeFromBackend[] {
    //     let res: NodeFromBackend[] = [];

    //     if (!Array.isArray(nodes)) return res;
    //     nodes.forEach((node: any) => {
    //         res.push({
    //             flowId: node.flowId,
    //             nodeName: node.data.label,
    //             nodeLocation: JSON.stringify(node.position),
    //             nodeType: NodeTypes.getNodeByKey(node.type).id,
    //             parentNodeId: edges
    //                 .filter((e) => e.target === node.id)
    //                 .map((e) => e.source)
    //                 .join(','),
    //             id: Number(node.id),
    //         });
    //     });
    //     return res;
    // }
}

class ReactFlowNode {
    id;
    type;
    position;
    data;
    flowId;
    parentNodeId;
    words;
    configType;
    constructor(newNode: NodeFromBackend) {      
        this.id = `${newNode.id}`;
        this.type = NodeTypes.getNode(newNode.nodeType).key;
        this.data = { label: newNode.nodeName, words: newNode.words };
        // 如果新建的是ppt节点，增加configType属性，标记这个节点是ppt配置节点
        if(newNode.nodeType === Udesk.enums.nodeTypes.pptNode.id) {
            this.data.configType = (newNode as any).configType || isDefined(newNode.parentNodeId, Udesk.enums.pptConfigType.singlePage.id, Udesk.enums.pptConfigType.pptConfig.id);
        }
        this.type = NodeTypes.getNode(newNode.nodeType).key;
        this.position = JSON.parse(newNode.nodeLocation!);
        this.flowId = newNode.flowId;
        this.parentNodeId = newNode.parentNodeId;
        this.words = newNode.words;
    }
}

export const createNode = (newNode) => {
    return new ReactFlowNode(newNode);
};

export const createBackEndNode = (newNode, propsChanged?) => {
    return Object.assign(
        {
            nodeName: newNode.nodeName || newNode.data.label,
            ...newNode,
            id: parseInt(newNode.id, 10),
            nodeType: NodeTypes.getNodeByKey(newNode.type).id,
            nodeLocation: JSON.stringify(newNode.position),
        },
        propsChanged
    );
};
