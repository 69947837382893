import React, { useEffect, useRef } from 'react';
// import Udesk from 'Udesk';
import BusinessAnalysisRoute from './route';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Select,
    Button,
    Tooltip,
    PageHeader,
    Input,
    Space,
    Form as UdeskUIForm,
    Icon,
    Table,
    Spin,
    Dropdown,
    Menu,
    Modal,
    TreeSelect,
} from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import _filter from 'lodash-es/filter';
import './style.scss';
import BusinessAnalysisFilter from './components/business-analysis-fiter';
import TagRank from './components/tag-rank';
import List from './components/list';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import ClassNames from 'classnames';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import Echarts from 'echarts';
import UdeskLocales from 'UdeskLocales';
// import ExportButton from 'Component/common/export-button';

const { confirm } = Modal;

export default class BusinessAnalysisTemplate extends BusinessAnalysisRoute {
    render() {
        // let { sdkOptions } = this.props;
        let { actions, locales, privates } = this;

        let {
            xAxisItems,
            seriversItems,
            searchTemplate,
            seriversItemsTitle,
            timeList,
            savedTemplates,
            proportionDataList,
            judgeStrategy,
            customJudgeLogic,
            _isFilterVisible,
            // graphData,
            // sanKeyItems,
            customName,
            customId,
            conditionList,
            name,
            dataSourceTypeOptions,
            allTasks,
            showAllConditionListFilter,
            // findAllSamrtTagsItems,
        } = privates;
        // let { Option } = Select;
        let { startTime, endTime, groupByType, data, dataSourceType, taskIds, num, smartTagsCondition } = this.privates.storages;
        // const company = Udesk.data.init.company;
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className='udesk-qa-web-page-header'
                    title={locales.components.pages.businessAnalysis.title}
                    // onBack={actions.backToList}
                    subTitle={
                        <Tooltip
                            title={
                                <div>
                                    <div>{locales.components.pages.businessAnalysis.tip1}</div>
                                    <div>{locales.components.pages.businessAnalysis.tip2}</div>
                                    <div>{locales.components.pages.businessAnalysis.tip3}</div>
                                </div>
                            }
                            overlayStyle={{ maxWidth: 'unset' }}
                            placement='bottomLeft'
                        >
                            <Icon type='QuestionCircleOutlined' antdIcon={true}></Icon>
                        </Tooltip>
                    }
                    footer={
                        <List
                            savedTemplates={savedTemplates}
                            activeTemplatId={privates.activeDetailId}
                            onChange={actions.onDetailIdChange}
                            // onDelete={actions.onDelete}
                        />
                    }
                />
                <div className='udesk-qa-web-page-body'>
                    <div
                        className='udesk-qa-web-page-body-root poc-business-analysis-page-list'
                        id='exportPDF'
                        style={{ background: '#f0f2f5' }}
                    >
                        <div style={{ padding: '10px', background: '#fff' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <UdeskUIForm ref={this.privates.formInstance} initialValues={{ name }}>
                                    <UdeskUIForm.Item
                                        name='name'
                                        label={locales.pages.businessAnalysis.keyword}
                                        required
                                        rules={[{ required: true }, { max: 20, type: 'string' }]}
                                    >
                                        <Input
                                            style={{ width: 250 }}
                                            onChange={actions.onNameChange}
                                            showCount={true}
                                            maxCount={20}
                                        />
                                    </UdeskUIForm.Item>
                                </UdeskUIForm>
                                {/* <Tooltip
                                    placement='bottom'
                                    title={
                                        <div className='custome-filter-tootip-button'>
                                            <p onClick={actions.fieldExport}>{`${locales.labels.export} excel`}</p>
                                            <p onClick={actions.fieldPdf} download>{`${locales.labels.export} png`}</p>
                                            <Button
                                                id='exprotLink'
                                                href='#'
                                                type='link'
                                                style={{ display: 'none' }}
                                            ></Button>
                                        </div>
                                    }
                                    overlayClassName='custome-filter-tootip'
                                >
                                    <Button style={{ float: 'right' }}>{locales.labels.export}</Button>
                                </Tooltip> */}
                                <Space>
                                    {this.privates.activeDetailId !== null && 
                                        <Button type='link' danger onClick={() => {
                                            confirm({
                                                icon: <Icon type='ExclamationCircleOutlined' antdIcon={true} />,
                                                content: /* 数据删除后将无法恢复，是否确认删除？ */locales.pages.intelligentAnalysis.analysisReport.components.list.index.theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt,
                                                onOk() {
                                                    actions.onDelete(privates.activeDetailId);
                                                },
                                            });
                                        }}>{/* 删除 */}{UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.template.delete}</Button>
                                    }
                                    <Dropdown
                                        overlay={
                                            <Menu onClick={actions.save}>
                                                <Menu.Item key={1}>{/* 另存为 */}{locales.pages.intelligentAnalysis.analysisReport.template.saveAs}</Menu.Item>
                                                <Menu.Item key={0}>{/* 保存 */}{locales.pages.intelligentAnalysis.analysisReport.template.preservation}</Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        <Button>
                                            <Space>{/* 保存 */}{locales.pages.intelligentAnalysis.analysisReport.template.preservation}<Icon type='DownOutlined' antdIcon={true} />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </Space>
                            </div>
                            <div style={{ marginLeft: 11 }}>
                                <span>{locales.fix.selectType}</span>
                                <Select
                                    style={{ width: 130 }}
                                    // disabled={!(company.enabledCallInspect !== 0 && company.enabledImInspect !== 0)}
                                    value={dataSourceType}
                                    onChange={actions.dataSourceTypeChange}
                                    options={dataSourceTypeOptions}
                                >
                                </Select>
                                <div style={{ display: 'inline-block', margin: '0 10px' }}>
                                    <UdeskDatePicker
                                        style={{ width: 246 }}
                                        allowClear={false}
                                        onChange={actions.dateChanged}
                                        value={[startTime, endTime]}
                                    />
                                </div>
                                {/* <Button onClick={actions.fieldSave}>查询</Button> */}
                            </div>
                            <div style={{ marginLeft: 11, marginTop: 24 }}>
                                <span>{/* 质检任务： */}{locales.pages.intelligentAnalysis.analysisReport.template.qualityInspectionTask}</span>
                                <Select
                                    style={{ minWidth: 200 }}
                                    value={taskIds}
                                    onChange={actions.onTaskIdsChange}
                                    options={allTasks.filter(i => i.inspectDataSource === dataSourceType)}
                                    mode='multiple'
                                    optionFilterProp='label'
                                >
                                </Select>
                            </div>
                            <div style={{ marginLeft: 11, marginTop: 24 }}>
                                <span>{/* 标签筛选： */}{UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.template.labelFiltering}</span>
                                <Select
                                    style={{ minWidth: 150, marginRight: 8 }}
                                    value={smartTagsCondition.operator}
                                    onChange={actions.onTagSelectModeChange}
                                    options={[
                                        {label: /* 满足任意 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.template.satisfyAny, value: 'is_any'},{label: /* 满足全部 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.template.meetAll, value: 'contains_all'}
                                    ]}
                                >
                                </Select>
                                {/* <Select
                                    style={{ minWidth: 200, marginRight: 8 }}
                                    value={smartTagsCondition.value}
                                    onChange={actions.onTagSelectValueChange}
                                    options={this.privates.smartTagsConditionOptions}
                                    mode='multiple'
                                    optionFilterProp='label'
                                >
                                </Select> */}
                                <TreeSelect
                                    treeDataSimpleMode={{ rootPId: 0 }}
                                    value={smartTagsCondition.value}
                                    treeData={this.privates.smartTagsConditionOptionsForm}
                                    treeCheckable={true}
                                    treeNodeFilterProp='_title'
                                    // loadData={onLoadData}
                                    style={{ width: 300, marginRight: 8 }}
                                    onChange={actions.onTagSelectValueChange}
                                    // getPopupContainer={() => wrapDom.current!}
                                    maxTagCount="responsive"
                                    allowClear
                                ></TreeSelect>
                            </div>
                        </div>
                        <div className='business-analysis-page-list-filter'>
                            <div className='qa-react-list-connenct-by-converstaion-custome-filter' style={{maxHeight: showAllConditionListFilter ? 360 : 62}}>
                                <UdeskUIForm layout='inline'>
                                    <div className='custome-filter-driver-box'>
                                        <Button
                                            type='text'
                                            size='small'
                                            onClick={actions.showConditionListFilter}
                                            icon={<Icon type='CaretRightOutlined' antdIcon={true} style={{ transform: showAllConditionListFilter ? 'rotateZ(90deg)' : undefined }} />}
                                            style={{
                                                position: 'absolute',
                                                left: '-10px',
                                                top: 0,
                                                bottom: 0,
                                                zIndex: 100,
                                                display: !_isEmpty(data) ? 'block' : 'none'
                                            }}
                                        ></Button>
                                        <div
                                            className={ClassNames(
                                                'customer-filter-driver-circle-add customer-filter-driver-circle-add-none',
                                                { 'customer-filter-driver-circle-add-block': !_isEmpty(data) }
                                            )}
                                            onClick={actions.addOneCustome}
                                        >
                                            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707'></i>
                                        </div>
                                        <BusinessAnalysisFilter
                                            data={data}
                                            searchTemplate={searchTemplate}
                                            // key={`${privates.activeDetailId}root`}
                                            fieldDelList={actions.fieldDelListChange}
                                            fieldAddList={actions.fieldAddListChange}
                                            num={num}
                                            dataSourceChanged={actions.dataSourceChange}
                                            dataSourceFromChanged={actions.dataSourceFromChange}
                                            dataSourceFromThreeChanged={actions.dataSourceFromThreeChange}
                                            dataSourceFromfiveChanged={actions.dataSourceFromfiveChange}
                                            driverFlag={true}
                                            newAddFilterFileds={actions.newAddFilterFileds}
                                            InputColorItemChange={actions.InputColorItemChange}
                                            renameItemChange={actions.renameItemChange}
                                            displacementChange={actions.displacementChange}
                                            dataSourceType={dataSourceType}
                                            findAllSamrtTagsItems={this.privates.smartTagsConditionOptions}
                                            // smartTagsConditionOptions={this.privates.smartTagsConditionOptions}
                                            activeTemplatId={privates.activeDetailId}
                                        />
                                    </div>
                                </UdeskUIForm>
                            </div>
                            <Space style={{ padding: '10px' }}>
                                <Button onClick={actions.fieldSave.params(0)} type='primary' style={{ marginLeft: 81 }}>{/* 查询 */}{locales.pages.intelligentAnalysis.analysisReport.template.query}</Button>
                            </Space>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div className='business-analysis-page-list-line left'>
                                {!_isEmpty(seriversItems) ? (
                                    <div>
                                        <div className='business-analysis-page-list-line-time'>
                                            {!_isEmpty(timeList) &&
                                                _filter(timeList, (current) => {
                                                    return (
                                                        (current.key !== '1h' &&
                                                            moment(endTime).diff(moment(startTime), 'day') > 180) ||
                                                        (moment(endTime) - moment(startTime)) / 1000 / 3600 / 24 < 180
                                                    );
                                                }).map((item) => {
                                                    return (
                                                        <div
                                                            className={ClassNames({
                                                                'timeColor-echars': item.key === groupByType,
                                                            })}
                                                            onClick={actions.timeClick.params(item.key)}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <LineEcharts
                                            locales={locales}
                                            seriversItemsTitle={seriversItemsTitle}
                                            xAxisItems={xAxisItems}
                                            seriversItems={seriversItems}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className='echarts-data-none'
                                        style={{ textAlign: 'center', lineHeight: '480px' }}
                                    >
                                        <Spin spinning={privates.lineEchartsLoading}>{locales.fix.noEchart}</Spin>
                                    </div>
                                )}
                            </div>
                            <div className='business-analysis-page-list-line right'>
                                {!_isEmpty(seriversItems) ? (
                                    <PieEcharts
                                        locales={locales}
                                        xAxisItems={xAxisItems}
                                        seriversItems={proportionDataList}
                                    />
                                ) : (
                                    <div
                                        className='echarts-data-none'
                                        style={{ textAlign: 'center', lineHeight: '480px' }}
                                    >
                                        <Spin spinning={privates.pieEchartsLoading}>{locales.fix.noEchart}</Spin>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='bussiness-analysis-page-list-table-list'>
                            <div className='title'>
                                <Space>
                                    <div>{/* 环比变化情况 */}{locales.pages.intelligentAnalysis.analysisReport.template.changeFromMonthToMonth}</div>
                                    <Button type='text' size='small' icon={<Icon type='QuestionCircleOutlined' antdIcon={true}></Icon>} tooltip={/* 环比变化计算逻辑：（所选时间段对话量-紧挨着的上个时间段的对话量）/上个时间段的对话量 */locales.pages.intelligentAnalysis.analysisReport.template.calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod}></Button>
                                </Space>
                            </div>
                            <Table
                                columns={privates.momChangeColumns}
                                dataSource={privates.momChangeData}
                                pagination={false}
                            ></Table>
                        </div>
                        <div className='bussiness-analysis-page-list-table-list' style={{ marginTop: 8 }}>
                            <div className='title'>
                                <Space>
                                    <div>{/* 标签排行 */}{locales.pages.intelligentAnalysis.analysisReport.template.tagRanking}</div>
                                    <Button type='text' size='small' icon={<Icon type='QuestionCircleOutlined' antdIcon={true}></Icon>} tooltip={/* 二级分类标签对话数≥一级分类，一个会话可能包含多个标签 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.template.theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels}></Button>
                                </Space>
                            </div>
                            <TagRank
                                loading={privates.rankTableLoading}
                                indentSize={0}
                                columns={privates.columns}
                                dataSource={privates.rankDataList}
                                getParams={actions.getParams}
                                onExpand={actions.onRankExpand}
                                key={privates.tagRankKey}
                                pagination={false}
                            />
                        </div>
                        <CustomFilterModal
                            visible={_isFilterVisible}
                            cancel={actions.handleConditionCancel}
                            applyAction={actions.handleConditionApply}
                            judgeStrategy={judgeStrategy}
                            customJudgeLogic={customJudgeLogic}
                            customName={customName}
                            customId={customId}
                            conditionList={conditionList}
                            type={dataSourceType}
                            businessFlag={true}
                            filterList={['f_8', 'r_1', 'r_2', 'r_3']}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
const LineEcharts = React.memo(({ locales, seriversItemsTitle, xAxisItems, seriversItems }) => {
    return (
        <ReactEcharts
            type='line'
            xLabelField='xLabel'
            enableDataZoom={true}
            height={460}
            finalizeChartOptions={(option) => {
                option.title = {
                    text: locales.fix.businessTrendAnalysis,
                };
                option.tooltip = {
                    trigger: 'axis',
                    position: function (pos, params, dom, rect, size) {
                        // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
                        var obj = {top: 60};
                        obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
                        return obj;
                    },
                    formatter:function(a){
                        let res = [];
                        a.forEach((item, index) => {
                            index === 0 && res.push(item.name + ':<br/><div style="max-width: 600px;">');
                            if(a.length > 10){
                                res.push(`<div style="width: 50%;float:left;">${item.marker} ${item.seriesName}: ${item.value}</div>`);
                            }else{
                                res.push(`<div>${item.marker} ${item.seriesName}: ${item.value}</div>`);
                            }
                            // res.push(item.marker + item.seriesName + ':' + item.value + '<br/>');
                        });
                        res.push('</div>');
                        return res.join('');
                    },
                };
                option.dataZoom = [
                    {
                        type: 'slider',
                        bottom: 40,
                    },
                ];
                option.yAxis = {
                    type: 'value',
                };
                option.legend = {
                    data: seriversItemsTitle,
                    type: 'scroll',
                    bottom: 10,
                };
                option.grid.bottom = 100;
                option.xAxis = {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisItems,
                };
                option.series = seriversItems;
                return option;
            }}
        />
    );
});

const colorList = [
    '#5B8FF9',
    '#5AD8A6',
    '#F6BD16',
    '#E86452',
    '#6DC8EC',
    '#945FB9',
    '#FF9845',
    '#1E9493',
    '#FF99C3',
    '#CDDDFD',
    '#CDF3E4',
    '#FCEBB9',
    '#F8D0CB',
    '#D3EEF9',
    '#DECFEA',
    '#FFE0C7',
    '#BBDEDE',
    '#FFE0ED',
];

const PieEcharts = React.memo(({ locales, xAxisItems, seriversItems }) => {
    const myChart = useRef(null);
    useEffect(() => {
        myChart.current = Echarts.init(document.getElementById('pieEchart'));
    }, []);
    useEffect(() => {
        const seriversItemsTitle = seriversItems.map((i) => i.name);
        let option = {
            title: {
                text: /* 一级分类占比情况 */locales.pages.intelligentAnalysis.analysisReport.template.proportionOfPrimaryClassification,
            },
            textStyle: {
                fontSize: 12, // 调节字体大小
            },
            color: colorList,
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'horizontal',
                type: 'scroll',
                bottom: 16,
                data: seriversItemsTitle,
            },
            calculable: true,
            series: [
                {
                    name: '',
                    type: 'pie', // 图表类型
                    radius: [30, 120], // 图表内外半径大小
                    center: ['50%', '50%'], // 图表位置
                    // roseType: 'area',
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                    },
                    data: seriversItems,
                },
            ],
        };

        myChart.current.setOption(option);
    }, [seriversItems]);

    return <div id='pieEchart' style={{ height: '100%' }}></div>;
});
