import React from 'react';
import styled from 'styled-components';

const CardTitle = styled.div`
    display: flex;
    height: 38px;
    align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 16px;
    position: relative;
    padding-left: 12px;
    &:before {
        position: absolute;
        width: 4px;
        background: #1b6dff;
        top: 6px;
        bottom: 6px;
        content: '';
        left: 0;
        border-radius: 4px;
    }
`;
const Card = (props) => {
    return (
        <div>
            <CardTitle>
                {props.title}
            </CardTitle>
            <div>{props.children}</div>
        </div>
    );
};

export { Card };
