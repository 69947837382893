// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostIntelligentPartnerTtsTestQuery,
    PostIntelligentPartnerTtsTestBody,
    BaseResponsestring,
} from '../../../types';

/**
 * tts语音测试接口
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [POST] /intelligentPartner/tts/test
 */
export function postIntelligentPartnerTtsTest(
    data: PostIntelligentPartnerTtsTestBody
): Promise<BaseResponsestring>;
export function postIntelligentPartnerTtsTest(
    data: PostIntelligentPartnerTtsTestBody,
    options: ApiOptions<never, PostIntelligentPartnerTtsTestQuery>
): Promise<BaseResponsestring>;
export function postIntelligentPartnerTtsTest(
    data: PostIntelligentPartnerTtsTestBody,
    options?: ApiOptions<never, PostIntelligentPartnerTtsTestQuery>
): Promise<BaseResponsestring> {
    return request<PostIntelligentPartnerTtsTestBody, never, PostIntelligentPartnerTtsTestQuery>(
        '/intelligentPartner/tts/test',
        { ...options, data, method: 'post', __$requestCalleeName: 'postIntelligentPartnerTtsTest' }
    );
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/tts/test',
        method: 'post',
    },
];
