import { useState } from 'react';

export const useFlowNodeListModal = () => {
    const [visible, setVisible] = useState(false);

    const changeModalVisible = () => {
        setVisible((p) => !p);
    };
    return {
        visible,
        changeModalVisible,
        onCancel: () => setVisible(false),
        onOk: () => setVisible(false),
    };
};
