import React, { useEffect, useState } from 'react';
import { Select } from 'udesk-ui';
import Locales from 'UdeskLocales';
import { getSmartTagsList } from 'src/api/smartTags/list';
import { RefSelectProps, SelectProps, SelectValue } from 'udesk-ui/lib/select';

const CustomerLabeSelect = <VT extends SelectValue = SelectValue>(
    props: SelectProps<VT> & {
        ref?: React.Ref<RefSelectProps> | undefined;
    }
) => {
    const locales = Locales['current'];
    const [customerLabelList, setCustomerLabelList] = useState<any[]>([]);
    const getCustomerTagDataList = () => {
        getSmartTagsList({
            params: {
                funcType: 'CUSTOMER_TAG',
            },
        }).then((res) => {
            setCustomerLabelList(res.data ?? []);
        });
    };
    useEffect(() => {
        getCustomerTagDataList();
    }, []);
    return (
        <Select
            showSearch
            showArrow
            allowClear
            mode="multiple"
            filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={locales.components.pages.intelligentTags.customerLabelTitle}
            style={{ width: 300 }}
            {...props}
        >
            {customerLabelList.map((item) => {
                return (
                    <Select.Option key={item.id} value={item.id.split('_').reverse()[0]}>
                        {item.tagName}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export { CustomerLabeSelect };
