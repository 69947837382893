import Locales from 'UdeskLocales';

export const useSchema = () => {
    const locales = Locales['current'];

    const schema = [
        {
            icon: 'logo_wbjqr',
            name: /* 文本机器人 */ locales.components.productMatrix.schema.textRobot,
            introduction:
                /* 训练师护航、文档问答、任务式对话、表格问答、图谱问答 */ locales.components
                    .productMatrix.schema.trainerEscortDocumentQATaskDialogueTableQAAtlasQA,
            url: 'https://www.udesk.cn/feature_urobot.html',
            status: 'hot',
        },
        {
            icon: 'logo_lzjqr',
            name: /* 留资机器人 */ locales.components.productMatrix.schema.capitalRetentionRobot,
            introduction:
                /* 丰富的追问留资策略，高拟人度沟通，保障高留资率、线索高效自动转化 */ locales
                    .components.productMatrix.schema
                    .richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues,
            url: 'https://www.udesk.cn/gaussmind/feature_presale_robot.html',
        },
        {
            icon: 'logo_whjqr',
            name: /* 外呼机器人 */ locales.components.productMatrix.schema.outboundRobot,
            introduction:
                /* 工单完结回访、服务满意度调研、运维故障警告提示、订单发货提醒、电商活动通知、候选人预约，助力业务自动化、业务效率提升 */ locales
                    .components.productMatrix.schema
                    .workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement,
            url: 'https://www.udesk.cn/gaussmind/outbound.html',
        },
        {
            icon: 'logo_hrjqr',
            name: /* 呼入机器人 */ locales.components.productMatrix.schema.incomingRobot,
            introduction:
                /* 灵活集成工单、订单等业务系统，实现流程自动化；语音语义模型定制化识别，业务效果立竿见影；实体（手机号、订单金额、订单时间、地址、……）识别，快速支持复杂业务 */ locales
                    .components.productMatrix.schema
                    .flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness,
            url: 'https://www.udesk.cn/gaussmind/inbound.html',
            status: 'hot',
        },
        {
            icon: 'logo_znzjz',
            name: /* 智能质检 */ locales.components.productMatrix.schema
                .intelligentQualityInspection,
            introduction:
                /* 全量质检，及时高效，准确率85% */ locales.components.productMatrix.schema
                    .fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf,
            url: 'https://www.udesk.cn/feature_check.html',
            status: 'hot',
        },
        {
            icon: 'logo_znhhfx',
            name: /* 智能会话分析 */ locales.components.productMatrix.schema
                .intelligentSessionAnalysis,
            introduction:
                /* 洞察业务短板，聆听客户心声，提升营销转化 */ locales.components.productMatrix
                    .schema
                    .insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation,
            url: 'https://www.udesk.cn/gaussmind/intelligent_session_analysis.html',
            status: 'hot',
        },
        {
            icon: 'logo_qywxhhfx',
            name: /* 企业微信会话分析 */ locales.components.productMatrix.schema
                .enterpriseWechatConversationAnalysis,
            introduction:
                /* 高效识别潜在商机、风险，营销转化率提升20% */ locales.components.productMatrix
                    .schema
                    .effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy,
            url: 'https://www.udesk.cn/gaussmind/qw_intelligent_session_analysis.html',
            status: 'hot',
        },
        {
            icon: 'logo-08',
            name: /* 坐席助手 */ locales.components.productMatrix.schema.assistant,
            introduction:
                /* 坐席助手能保障新手客服完成服务，提升熟手客服通话质量。让每一个坐席都是您的王牌 */ locales
                    .components.productMatrix.schema
                    .theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard,
            url: 'http://www.udesk.cn/feature_call_seat.html',
        },
        {
            icon: 'logo_szr',
            name: /* 数字人 */ locales.components.productMatrix.schema.digitalPeople,
            introduction:
                /* “面对面”拟人化交流沟通，支持声音、文字、视频、触控多模态交互，实现沉浸式交流体验，提升品牌形象及用户服务体验 */ locales
                    .components.productMatrix.schema
                    .FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience,
            url: 'https://www.udesk.cn/gaussmind/number_one.html',
        },
        {
            icon: 'logo_qyss',
            name: /* AI知识中台·企业搜索 */ locales.components.productMatrix.schema
                .aIKnowledgeZhongtaiEnterpriseSearch,
            introduction:
                /* 知识搜索，知识共享，文档搜索，数据库对接搜索 */ locales.components.productMatrix
                    .schema.knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch,
            url: 'http://www.udesk.cn/gaussmind/search.html',
            status: 'new',
        },
        {
            icon: 'logo_zsk',
            name: /* AI知识中台·KCS知识库 */ locales.components.productMatrix.schema
                .aIKnowledgeCenterKCsKnowledgeBase,
            introduction:
                /* 提供知识的全生命周期管理，机器人和IM知识库统一维护，支持对接第三方知识库，知识模板、原子知识等强大功能带来更加便捷的知识管理体验 */ locales
                    .components.productMatrix.schema
                    .provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience,
            url: 'http://www.udesk.cn/gaussmind/kcs.html',
            status: 'new',
        },
        {
            icon: 'logo_zstp',
            name: /* AI知识中台·知识图谱 */ locales.components.productMatrix.schema
                .aIKnowledgeCenterKnowledgeMap,
            introduction:
                /* 一站式图谱构建融合企业知识，大图展示知识细节，对接能力打造定制化体验 */ locales
                    .components.productMatrix.schema
                    .oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience,
            url: 'https://www.udesk.cn/gaussmind/knowledge_map.html',
            status: 'new',
        },
        {
            icon: 'logo_asr',
            name: /* 模块化开发平台·原心引擎·ASR */ locales.components.productMatrix.schema
                .modularDevelopmentPlatformOriginalEngineASR,
            introduction:
                /* 采用端到端算法，实现业务定制化的效果体验，自然场景识别率超过90%，个性化词汇识别超过97% */ locales
                    .components.productMatrix.schema
                    .theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97,
            url: 'https://www.udesk.cn/gaussmind/asr.html',
        },
        {
            icon: 'logo_znyx',
            name: /* 模块化开发平台·智能营销 */ locales.components.productMatrix.schema
                .modularDevelopmentPlatformIntelligentMarketing,
            introduction:
                /* 利用深度学习算法，结合用户画像，实现商品的精准推荐，提高销售转化 */ locales
                    .components.productMatrix.schema
                    .makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion,
            url: 'https://www.udesk.cn/gaussmind/smart_market.html',
            status: 'new',
        },
        {
            icon: 'logo_yhhx',
            name: /* 模块化开发平台·用户画像 */ locales.components.productMatrix.schema
                .modularDevelopmentPlatformUserPortrait,
            introduction:
                /* 根据用户数据和对话数据精准构建用户画像，充分描述客户特征 */ locales.components
                    .productMatrix.schema
                    .accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics,
            url: 'https://www.udesk.cn/gaussmind/smart_market.html',
            status: 'new',
        },
        {
            icon: 'logo_zntj',
            name: /* 模块化开发平台·智能推荐 */ locales.components.productMatrix.schema
                .modularDevelopmentPlatformIntelligentRecommendation,
            introduction:
                /* 根据用户画像和产品画像，自动给用户推荐可能感兴趣的产品，实现千人千面的个性化推荐 */ locales
                    .components.productMatrix.schema
                    .automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople,
            url: 'https://www.udesk.cn/gaussmind/smart_market.html',
            status: 'new',
        },
        {
            icon: 'logo_nlp',
            name: /* 模块化开发平台·NLP PaaS */ locales.components.productMatrix.schema
                .modularDevelopmentPlatformNLPPAAS,
            introduction:
                /* 提供高准确率的NLP基础能力，涵盖词、句子、文章等不同粒度的底层能力 */ locales
                    .components.productMatrix.schema
                    .provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles,
            url: 'https://www.udesk.cn/gaussmind/paas.html',
        },
    ];

    return {
        schema,
    };
};
