import React from 'react';
import './style.scss';

interface Times {
    startPoint: number;
    endPoint: number;
}

interface RoleTimes {
    type: 'agent' | 'customer';
    voiceLength: number;
    times: Times[];
}

const AUDIO_STICKER_COLORS = ['#BDDEFF', '#ABEDAB'];

// let timer: NodeJS.Timeout | null = null;

const AudioStickers = (props) => {
    const { times, voiceLength, type } = props;

    return (
        <div className='audio-sticker-view-v3'>
            <div className='audio-sticker-view-v3-label'></div>
            <div className='audio-sticker-view-v3-sticker'>
                {times.map((item, itemIndex) => {
                    return (
                        <div
                            key={itemIndex}
                            className='audio-sticker-item'
                            style={getAudioStickerItemStyle(item, type, voiceLength, AUDIO_STICKER_COLORS)}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
};

export default React.memo(AudioStickers);

function getAudioStickerItemStyle(
    item: Times,
    type: RoleTimes['type'],
    time: RoleTimes['voiceLength'],
    audioStickerColors: typeof AUDIO_STICKER_COLORS
) {
    let backgroundColor = audioStickerColors[type === 'agent' ? 0 : 1];
    return getAudioStickerItemStyleModel(item.startPoint, item.endPoint, time, backgroundColor);
}

function getAudioStickerItemStyleModel(
    startPoint: Times['startPoint'],
    endPoint: Times['endPoint'],
    time: RoleTimes['voiceLength'],
    backgroundColor: string
): React.CSSProperties {
    var start = parseFloat(`${startPoint}`);
    var end = parseFloat(`${endPoint}`);
    var timeLength = parseFloat(`${time}`);
    if (isNaN(start) || isNaN(end) || isNaN(timeLength)) {
        // return String.htmlSafe("width:0%;left:0%");
        return {
            width: '0%',
            left: '0%',
        };
    } else {
        let letfPoint = timeLength <= 0 ? '0%' : Math.floor((start / timeLength) * 10000) / 100.0 + '%';
        let width = timeLength <= 0 ? '0%' : Math.floor(((end - start) / timeLength) * 10000) / 100.0 + '%';
        // return String.htmlSafe(`width:${width};left:${letfPoint};background:${backgroundColor}`);
        return {
            width: width,
            left: letfPoint,
            backgroundColor: backgroundColor,
        };
    }
}
