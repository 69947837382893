import { rebuild as rebuildEnums } from 'udesk-web-toolkits/src/udesk/enums';
import locals from '../locales/index';

let echartTypes = [
    { id: 0, name: () => locals.current.enums.echartTypes.column, key: "column" },
    { id: 1, name: () => locals.current.enums.echartTypes.line, key: "line" },
    { id: 2, name: () => locals.current.enums.echartTypes.figure, key: "figure" },
    { id: 3, name: () => locals.current.enums.echartTypes.pie, key: "pie" },
    { id: 4, name: () => locals.current.enums.echartTypes.ring, key: "ring" },
    { id: 5, name: () => locals.current.enums.echartTypes.funnel, key: "funnel" },
    { id: 6, name: () => locals.current.enums.echartTypes.doubleAxises, key: "doubleAxises" },
    { id: 7, name: () => locals.current.enums.echartTypes.stackColumn, key: "stackColumn" },
    { id: 8, name: () => locals.current.enums.echartTypes.bar, key: "bar" },
    { id: 9, name: () => locals.current.enums.echartTypes.stackBar, key: "stackBar" },
    { id: 10, name: () => locals.current.enums.echartTypes.table, key: "table" },
    { id: 11, name: () => locals.current.enums.echartTypes.gauge, key: "gauge" },
    { id: 12, name: () => locals.current.enums.echartTypes.map, key: "map" },

    // react-echarts 枚举id为 13～16，在项目中应该使用key确定图形类型，而不是使用id
    { id: 13, name: () => locals.current.enums.echartTypes.wordCloud, key: "wordCloud" },
    { id: 14, name: () => locals.current.enums.echartTypes.customScatterPie, key: "customScatterPie" },
    { id: 15, name: () => locals.current.enums.echartTypes.radar, key: "radar" },
    { id: 16, name: () => locals.current.enums.echartTypes.customStackBar, key: "customStackBar" },

    // 水球图
    { id: 50, name: () => locals.current.enums.echartTypes.liquidFill, key: "liquidFill" }

];

let enumsContainer = {
    echartTypes
};
rebuildEnums.call(enumsContainer);
export default enumsContainer;
