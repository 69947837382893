import React from 'react';
import Udesk from 'Udesk';
// import ReactTable from 'udesk-react/src/components/react-table';
import UdeskTable from 'Component/common/udesk-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import ListManageComponent from './component';
import './style.scss';
import { Popover, Button, Space, Input, Select } from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import ExportButton from 'Component/common/export-button';
import { BatchedActions } from 'udesk_gm_ui';
import AddToTaskModal from '../AddToTaskModal';
import { ArrowDownSOutlined } from '@udesk/icons';
import { postCallManageBatchReAsr } from 'src/api/call/manage/batch/reAsr';
import { getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';
export default class ListManageTemplate extends ListManageComponent {
    render() {
        let {
            // sdkOptions,
            title,
            cacheKey,
            searchPlaceHolder,
            isWechatQa,
            hideKeywordType,
        } = this.props;
        let {
            judgeStrategy,
            customJudgeLogic,
            data,
            customName,
            customId,
            conditionList,
            tableDOMRef,
            tableRef,
            loading,
            selectedItems,
            addToTaskModalVisible,
        } = this.privates;
        let {
            searchField,
            paging,
            // type,
            keywordType,
        } = this.privates.storages;
        let { _columns, _isFilterVisible } = this.privates.computes;
        let { actions, locales } = this;
        let customCard = (
            <div className="custom-filter-info-type">
                <div className="index-management-custom-filter-Info">
                    <div>
                        {locales.components.common.customFilter.customFilterCondition}：
                        {_isEmpty(conditionList) && locales.components.common.customFilter.none}
                    </div>
                    <div>
                        {!_isEmpty(conditionList) &&
                            this.actions.fieldsFilterChange({ conditionList })}
                    </div>
                </div>
                <div>
                    {locales.components.common.customFilter.conditionRule}：
                    <span>
                        {judgeStrategy !== 3 &&
                            Udesk.enums.operatorRuleTypes.find(
                                (current) => current.id === judgeStrategy
                            ).name}
                        {judgeStrategy === 3 && customJudgeLogic}
                    </span>
                </div>
            </div>
        );
        return (
            <div className="qa-react-list-page">
                <div className="qa-react-list-page-header">
                    <div className="qa-react-list-page-header-title">{title}</div>
                </div>
                <div className="qa-react-list-page-body">
                    <div className="qa-react-list-page-functions">
                        <If condition={!isWechatQa}>
                            <div className="qa-react-list-page-functions-left-part pull-left">
                                <Space>
                                    {!hideKeywordType ? (
                                        <Select
                                            value={keywordType}
                                            style={{ width: 100 }}
                                            onSelect={actions.selectType}
                                            disabled={loading}
                                            options={Udesk.enums.applyRolesWithRobot.map((i) => ({
                                                label: i.name,
                                                value: i.keywordType,
                                            }))}
                                        />
                                    ) : null}

                                    <Input
                                        maxCount={20}
                                        showCount={true}
                                        maxLength={20}
                                        value={searchField}
                                        placeholder={searchPlaceHolder}
                                        onChange={actions.changeSearchField}
                                        disabled={loading}
                                    />
                                    <Button onClick={actions.searchItems} disabled={loading}>
                                        {locales.labels.query}
                                    </Button>
                                </Space>
                            </div>
                        </If>
                        <div className="qa-react-list-page-functions-right-part pull-right">
                            <Space>
                                {Number(this.props.type) ===
                                    Udesk.enums.inspectDataSources.voiceCall.id && (
                                    <BatchedActions
                                        menu={[
                                            {
                                                label: /* 添加至质检任务 */ locales.components
                                                    .listManage.template.addToQualityInspectionTask,
                                                onClick: (item) => {
                                                    this.privates.addToTaskModalVisible = true;
                                                    this.actions.update();
                                                },
                                            },
                                            {
                                                label: /* 重新识别 */ UdeskLocales['current']
                                                    .components.listManage.template
                                                    .reIdentification,
                                                confirm: {
                                                    title: /* 重新识别会消耗转写时长，请谨慎操作 */ UdeskLocales[
                                                        'current'
                                                    ].components.listManage.template
                                                        .rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution,
                                                    okText: /* 确定 */ UdeskLocales['current']
                                                        .components.listManage.template.determine,
                                                },
                                                onClick: (item) => {
                                                    postCallManageBatchReAsr(
                                                        {
                                                            callIdList: selectedItems.map(
                                                                (i) => i.dataId
                                                            ),
                                                            systemModule: getSystemModule(),
                                                        },
                                                        {
                                                            successMsg:
                                                                /* 执行成功 */ UdeskLocales[
                                                                    'current'
                                                                ].components.listManage.template
                                                                    .executionSuccessful,
                                                        }
                                                    );
                                                },
                                            },
                                        ]}
                                        disabled={!selectedItems.length}
                                    >
                                        <Button disabled={!selectedItems.length}>
                                            {/* 批量操作 */}
                                            {locales.components.listManage.template.batchOperation}
                                            <ArrowDownSOutlined style={{ marginLeft: 4 }} />
                                        </Button>
                                    </BatchedActions>
                                )}
                                <Popover content={customCard} placement="bottomRight">
                                    <Button
                                        onClick={actions.changeFilterVisible}
                                        disabled={loading}
                                    >
                                        {locales.labels.filter}
                                    </Button>
                                </Popover>
                                {!this.props.hiddenExportBtn && (
                                    <ExportButton
                                        disabled={loading}
                                        exportDataCount={paging.total}
                                        hasFilterCondition={!!conditionList.length}
                                        onClick={actions.exportCallList}
                                    />
                                )}
                                <Button onClick={actions.onPopoverVisible}>
                                    {locales.components.pages.customerServiceRankList.select.column}
                                </Button>
                            </Space>
                        </div>
                    </div>
                    <div ref={tableDOMRef} className="qa-react-list-page-content">
                        <UdeskTable
                            subtractedHeight={197}
                            // horizontalScrollbar={true}
                            // showHorizontalScrollbar={true}
                            columns={_columns}
                            loading={loading}
                            ref={tableRef}
                            cacheKey={cacheKey}
                            dataSource={data?.fieldDataList ?? []}
                            // enableChooseColumns={true}
                            // rowClick={actions.gotoDetail}
                            onRow={(record, index) => {
                                return { onClick: actions.gotoDetail.params(record) };
                            }}
                            pagination={false}
                            rowSelection={
                                Number(this.props.type) ===
                                Udesk.enums.inspectDataSources.voiceCall.id
                                    ? {
                                          selectedRowKeys: selectedItems.map((i) => i.dataId),
                                          onSelect: actions.onSelectChange,
                                          onSelectAll: actions.onSelectAllChange,
                                      }
                                    : undefined
                            }
                            // onColumnsChanged={actions.onColumnsChanged}
                        />
                    </div>
                </div>
                <div className="qa-react-list-page-footer">
                    <If condition={data && data.fieldDataList && data.fieldDataList.length > 0}>
                        {/* <ReactPager
                            showTotalPages={false}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={0}
                            pageSize={paging.pageSize}
                            total={paging.total}
                            pageNum={paging.pageNum}
                            languageCode={sdkOptions.props.language}
                            pageChanged={actions.pageChanged}
                        /> */}
                        <UdeskPagination
                            current={paging.pageNum}
                            pageSize={paging.pageSize}
                            total={paging.total}
                            onChange={actions.pageChanged}
                        />
                    </If>
                </div>
                {/* <ReactModal visible={_isFilterVisible} title={locales.components.pages.components.listManage.filterConditions} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.handleConditionCancel} onOk={actions.handleConditionFilter} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                    <div className="udesk-qa-modal-body-content">
                        <CustomFilters allowEmptyConditions={true} conditions={displayConditionList} fields={_fields} fieldValueSettings={_fieldValueSettings} theme="bootstrap" onChanged={actions.filterContidionChanged} fieldSelectorClass="form-control input-sm" operatorSelectorClass="form-control input-sm" languageCode={sdkOptions.props.language} fieldNameField="key" fieldKeyField="key" fieldTextField="label" showConditionNumber={true} />
                        <RadioBtn classNames="customer-filters-judgeStrategy-radios" itemClassNames="customer-filters-judgeStrategy-radio-item" options={Udesk.enums.operatorRuleTypes} value={judgeStrategy} onChanged={actions.changeJudgeStrategy} />
                        <If condition={judgeStrategy === Udesk.enums.operatorRuleTypes.custom.id}>
                            <input type="text" className="form-control" defaultValue={customJudgeLogic} onChange={actions.mutator("privates.customJudgeLogic", e => e.target.value)} />
                        </If>
                        <If condition={newFilterVisible}>
                            <CheckBox containerClassNames="customer-filters-save-options" itemClassNames="customer-filters-save-option-item" value={isSave} singleCheckBox={true} options={Udesk.enums.saveTypes} onChanged={actions.changeIsSaved} />
                            <If condition={isSave}>
                                <input type="text" className="form-control" defaultValue={filterName} onChange={actions.mutator("privates.filterName", e => e.target.value)} />
                            </If>
                        </If>
                        <If condition={editFilterVisible}>
                            <RadioBtn classNames="customer-filters-judgeStrategy-radios" itemClassNames="customer-filters-judgeStrategy-radio-item" options={Udesk.enums.updateTypes} value={updateType} onChanged={actions.changeUpdateType} />
                            <If condition={updateType}>
                                <input type="text" className="form-control" defaultValue={filterName} onChange={actions.mutator("privates.filterName", e => e.target.value)} />
                            </If>
                        </If>
                    </div>
                </ReactModal> */}
                <AddToTaskModal
                    visible={addToTaskModalVisible}
                    inspectDataSource={Number(this.props.type)}
                    onAdded={actions.close}
                    onCancel={actions.close}
                    dataId={selectedItems.map((i) => i.dataId)}
                />
                <CustomFilterModal
                    visible={_isFilterVisible}
                    applyAction={actions.handleConditionApply}
                    judgeStrategy={judgeStrategy}
                    customJudgeLogic={customJudgeLogic}
                    conditionList={conditionList}
                    customName={customName}
                    customId={customId}
                    onConditionFilterSaved={this.props.onConditionFilterSaved}
                    cancel={actions.handleConditionCancelWithoutClearData}
                    type={this.props.type}
                    filterList={['f_8']}
                    filterInspectData
                />
            </div>
        );
    }
}
