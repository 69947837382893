import React, { useEffect, useState, useMemo } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import RichTextList from './components/rich-text-list';
import { FormBuilder, Button, Upload, Space, Icon } from 'udesk-ui';
import { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import './style.scss';
import cusConfigMap from 'src/common/config/form-config/cus-component-map';
import { getKnowledgeDetailById } from 'src/api/knowledgeDetail/{id}';
import { postKnowledgeDetail } from 'src/api/knowledgeDetail';
import { getKnowledgeBase } from 'src/api/knowledgeBase';
import styled from 'styled-components';
import { postStorageCreateResource } from 'src/api/storage/createResource';

// import type { UploadProps } from 'udesk-ui';

const UploadListWrap = styled.div`
    .udesk-qa-ui-upload {
        display: ${(props) => (props.isView ? 'none' : 'block')};
    }
`;

const UploadList = (props) => {
    const { value: valueProps, onChange, isView, ...uploadProps } = props;
    const locales = Locales['current'];

    const value = valueProps ?? [];
    const beforeUpload = (file, fileList) => {
        Promise.all(
            fileList.map((file) => {
                return new Promise((res, rej) => {
                    Udesk.app.fileStorage.upload(file, {
                        token: new Promise((resolve, reject) => {
                            Udesk.business
                                .getServerAuthorization({
                                    type: 2,
                                })
                                .then(
                                    function (resp) {
                                        resolve(resp);
                                    },
                                    function (reason) {
                                        reject(reason);
                                    }
                                );
                        }),
                        //progressCallback: progressCallback.bind(this),
                        successCallback: (file, a) => {
                            postStorageCreateResource({
                                keyName: a.key
                            }).then((resp) => {
                                res({
                                    name: file.name,
                                    url: resp.data,
                                    status: 'success',
                                });
                            });
                            
                        },
                        errorCallback: (file, a) => {
                            res({
                                name: file.name,
                                url: a?.key,
                                status: 'error',
                            });
                        },
                    });
                });
            })
        ).then((res) => {
            onChange([...value, ...res]);
        });
        return false;
    };

    return (
        <UploadListWrap isView={isView}>
            <Upload
                multiple={true}
                beforeUpload={beforeUpload}
                onRemove={(file) => {
                    onChange(value.filter((i) => i.uid !== file.uid));
                }}
                {...uploadProps}
                fileList={value}
            >
                {isView || <Button icon={<Icon type='UploadOutlined' antdIcon={true} />}>{/* 上传 */}{locales.pages.gong.konwledgeBase.detail.index.upload}</Button>}
            </Upload>
            {isView && !value.length && '-'}
        </UploadListWrap>
    );
};

const Buttons = (props) => {
    const locales = Locales['current'];

    const { isEdit, onSave, onCancel } = props;
    return (
        <Space>
            <Button type='primary' onClick={onSave}>
                {isEdit ? /* 保存 */locales.pages.gong.konwledgeBase.detail.index.preservation : /* 新建 */locales.pages.gong.konwledgeBase.detail.index.newlyBuild}
            </Button>
            <Button onClick={onCancel}>{/* 取消 */}{locales.pages.gong.konwledgeBase.detail.index.cancel}</Button>
        </Space>
    );
};
const DEFAULT_CONDITION: any = {
    conditionType: 1, //1话术2交互3特征4信息
    taskConditionId: null,
    orderId: 1,
    operatorType: 11,
    idCode: 'R1',
    operatorList: [
        {
            applyConditionHitType: 1,
            applyOperatorScope: {
                key: 'all',
                id: 1,
            },
            type: 11,
            operator: 'all',
            applyRole: 'all',
        },
    ],
    applyConditionId: undefined,
    applyConditionIdCode: null,
    operatorLogic: '1',
    operator: 'all',
    applyRole: 'all',
    applyScope: null,
    similarityScore: null,
    differWordNumber: null,
    wordPerMinite: null,
    maxWordPerMinite: null,
    grabIntervalTime: null,
    grabMaxSpeakTime: null,
    intervalSecond: null,
    keyword: '',
    semanticSentence: null,
    applyConditionScope: null,
    applyConditionHitType: 1,
    // applyOperatorScope: {
    //     key: 'all',
    //     id: 1
    // },
    param: {},
};

const Div = (props) => <div style={{lineHeight: '30px'}}>{props.value}</div>;
const SelectDiv = (props) => {
    const { value, options } = props;
    const displayValue = useMemo(() => {
        return options.find((i) => i.value === value)?.label ?? value;
    }, [value, options]);
    return <div>{displayValue}</div>;
};

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const isView = props.history.location.pathname.includes('/sale-knowledge-base/view');
    const [conditions, setConditions] = useState<any[]>([]);
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [currentType, setCurrentType] = useState<1 | 2>(1);
    const [formInstance] = FormBuilder.useForm();

    const [initialValues, setInitialValues] = useState<any>(() => {
        const queryParams = new URLSearchParams(props.location.search);
        let categoryId = queryParams.get('categoryId');
        return {
            categoryId: isNaN(Number(categoryId)) ? undefined : Number(categoryId),
            conditionList: [DEFAULT_CONDITION],
            ruleList: {
                judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            },
            type: Udesk.enums.knowledgeBaseType.knowledge.id,
            relateFlag: 0,
            contentDetailList: [
                {
                    content: '',
                },
            ],
        };
    });
    const backToList = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'gongKnowledgeBaseList',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const config: FormProps = {
        onFinish: (values) => {
            const params = { ...values };
            if (initialValues.id) {
                params.id = initialValues.id;
            }
            if (params.ruleList) {
                params.ruleList = [{...params.ruleList, formula: params.ruleList.customJudgeLogic}];
            }
            if (Array.isArray(params.conditionList)) {
                params.relateConditionList = params.conditionList.map((i) => ({ idCode: i.idCode }));
            }
            postKnowledgeDetail(params).then(() => {
                backToList();
            });
        },
        form: formInstance,
        customComponents: { ...cusConfigMap, RichTextList, UploadList, Buttons, Div, SelectDiv },
        initialValues,
        fields: [
            {
                type: isView ? 'Div' : 'Input',
                name: 'pointName',
                label: /* 名称 */locales.pages.gong.konwledgeBase.detail.index.name,
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: isView ? 'Div' : 'Input',
                name: 'description',
                label: /* 描述 */locales.pages.gong.konwledgeBase.detail.index.describe,
            },
            {
                type: isView ? 'SelectDiv' : 'Select',
                name: 'type',
                label: /* 类型 */locales.pages.gong.konwledgeBase.detail.index.type,
                props: {
                    options: Udesk.enums.knowledgeBaseType.map((i) => ({
                        label: i.name,
                        value: i.id,
                    })),
                    onChange: (value) => {
                        setCurrentType(value);
                    },
                },
            },
            {
                type: isView ? 'SelectDiv' : 'Select',
                name: 'categoryId',
                label: /* 分类 */locales.pages.gong.konwledgeBase.detail.index.classification,
                props: {
                    options: categoryList,
                },
            },
            {
                type: 'RichTextList',
                name: 'contentDetailList',
                label: /* 内容 */locales.pages.gong.konwledgeBase.detail.index.content,
                props: {
                    type: currentType === 1 ? 'knowledge' : 'process',
                    isView,
                },
                required: true,
            },
            {
                type: 'UploadList',
                name: 'attachmentFileList',
                label: /* 附件 */locales.pages.gong.konwledgeBase.detail.index.enclosure,
                props: {
                    isView,
                },
            },
            {
                type: 'RadioGroup',
                name: 'relateFlag',
                label: /* 知识点关联 */locales.pages.gong.konwledgeBase.detail.index.knowledgePointAssociation,
                forceHidden: isView,
                props: {
                    options: [
                        { label: /* 开启 */locales.pages.gong.konwledgeBase.detail.index.open, value: 1 },
                        { label: /* 关闭 */locales.pages.gong.konwledgeBase.detail.index.close, value: 0 },
                    ],
                },
                effect: {
                    visible: {
                        1: ['conditionList', 'ruleList'],
                    },
                },
            },
            {
                type: 'ConditionListGather' as any,
                name: 'conditionList',
                label: /* 算子 */locales.pages.gong.konwledgeBase.detail.index.operator,
                forceHidden: isView,
                props: {
                    type: [0],
                    ruglar: true,
                    isCollection: true,
                    onChange: (value) => {
                        setConditions(value);
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'Logic',
                name: 'ruleList',
                label: /* 逻辑 */locales.pages.gong.konwledgeBase.detail.index.logic,
                forceHidden: isView,
                props: {
                    conditions: conditions,
                    isCondition: true,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                type: 'Buttons',
                label: ' ',
                colon: false,
                forceHidden: isView,
                props: {
                    onSave: () => formInstance.submit(),
                    onCancel: () => {
                        let routeOptions = {
                            history: props.history,
                            routeName: 'gongKnowledgeBaseList',
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    },
                    isEdit: !new URLSearchParams(props.location.search).get('categoryId'),
                },
            },
        ],
        footerRender: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        props.match.params.id &&
            getKnowledgeDetailById({
                segments: {
                    id: props.match.params.id,
                },
            }).then((resp) => {
                const originData = resp.data ?? {};
                const data: any = Object.keys(originData).reduce(
                    (prev, key) => {
                        if (originData[key]) {
                            prev[key] = originData[key];
                        }
                        return prev;
                    },
                    { ...initialValues }
                );
                if (Array.isArray(data.ruleList) && data.ruleList.length) {
                    data.ruleList = {
                        ...data.ruleList[0],
                        customJudgeLogic: data.ruleList[0].formula,
                    };
                }
                setInitialValues({ ...initialValues, ...data });
                setCurrentType(data.type ?? 1);
            });

        getKnowledgeBase().then((resp) => {
            setCategoryList(resp.data?.map((i) => ({ label: i.categoryName, value: i.id })) ?? []);
        });
    }, []);

    useEffect(() => {
        formInstance.resetFields();
    }, [initialValues]);

    return (
        <Page
            pageBodyClassName='sale-knowledge-base-detail-page-index'
            title={
                isView
                    ? `${initialValues.pointName ?? /* 查看 */locales.pages.gong.konwledgeBase.detail.index.see}`
                    : !new URLSearchParams(props.location.search).get('categoryId')
                        ? /* 编辑 */locales.pages.gong.konwledgeBase.detail.index.edit
                        : /* 新建 */locales.pages.gong.konwledgeBase.detail.index.newlyBuild
            }
            padding={true}
            onBack={backToList}
        >
            <FormBuilder {...config} />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
