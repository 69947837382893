// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerStudyTaskByTaskIdVoiceTestParam,
    BaseResponseIntelligentPartnerTaskInfoFoundResponse,
    PutIntelligentPartnerStudyTaskByTaskIdVoiceTestParam,
    PutIntelligentPartnerStudyTaskByTaskIdVoiceTestBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 获取语音检测文件
 *
 * @export
 * @tags 智能陪练学习任务接口
 * @link [GET] /intelligentPartnerStudyTask/{taskId}/voice/test
 * @param taskId
 */
export function getIntelligentPartnerStudyTaskByTaskIdVoiceTest(
    options: ApiOptions<GetIntelligentPartnerStudyTaskByTaskIdVoiceTestParam, never> & {
        segments: GetIntelligentPartnerStudyTaskByTaskIdVoiceTestParam;
    }
): Promise<BaseResponseIntelligentPartnerTaskInfoFoundResponse> {
    return request<never, GetIntelligentPartnerStudyTaskByTaskIdVoiceTestParam, never>(
        '/intelligentPartnerStudyTask/{taskId}/voice/test',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerStudyTaskByTaskIdVoiceTest',
        }
    );
}

/**
 * 保存/更新语音检测文件
 *
 * @export
 * @tags 智能陪练学习任务接口
 * @link [PUT] /intelligentPartnerStudyTask/{taskId}/voice/test
 * @param taskId
 */
export function putIntelligentPartnerStudyTaskByTaskIdVoiceTest(
    data: PutIntelligentPartnerStudyTaskByTaskIdVoiceTestBody,
    options: ApiOptions<PutIntelligentPartnerStudyTaskByTaskIdVoiceTestParam, never> & {
        segments: PutIntelligentPartnerStudyTaskByTaskIdVoiceTestParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentPartnerStudyTaskByTaskIdVoiceTestBody,
        PutIntelligentPartnerStudyTaskByTaskIdVoiceTestParam,
        never
    >('/intelligentPartnerStudyTask/{taskId}/voice/test', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerStudyTaskByTaskIdVoiceTest',
    });
}

export const meta = [
    {
        tags: ['智能陪练学习任务接口'],
        path: '/intelligentPartnerStudyTask/{taskId}/voice/test',
        method: 'get',
    },
    {
        tags: ['智能陪练学习任务接口'],
        path: '/intelligentPartnerStudyTask/{taskId}/voice/test',
        method: 'put',
    },
];
