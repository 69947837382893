import tranToTaskCenterNotification from 'Component/common/task-notification';
import React from 'react';
import Udesk from 'Udesk';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { getSystemModule } from 'Udesk/system/subApp';

class DataPreprocessComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {

    };
    //#endregion

    state = {
        columns: [],
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: Udesk.config.paging.defaultPageSize,
        total: null,
    };
    privates = {
        dataPreprocess: [],
        keyWords: undefined,
        dataSourceFlags: [],
        dataSource: null,
        statusFlags: [],
        status: null,
        selectedItems: [],
        selectCallTimeVisible: false,
        callTimeRange: [],
        redoCallIds: [],
    };

    actions = {
        onCheckChange(record, selected, selectedRows, nativeEvent) {
            this.privates.selectedItems = selectedRows;
            this.actions.update();
        },
        onSelectAll(selected, selectedRows, changeRows){
            this.privates.selectedItems = selectedRows;
            this.actions.update();
        },
        deletReordClick(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        deleteRecordCancel() { },
        deleteRecordOk() {
            let { locales } = this;
            let { sdkOptions } = this.props;
            let { selectedItems } = this.privates;
            let ids = selectedItems.map(item => item.id);
            if (ids.length === 0) return;
            let params = {
                ids,
            };
            let url = Udesk.business.apiPath.concatApiPath("preprocessRules", sdkOptions);
            Udesk.ajax.del(url, params).then(
                resp => {
                    Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                    this.privates.selectedItems = [];
                    this.actions.reloadAsyncModel();
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.deleteFailed);
                }
            );
        },
        selecthandleChange(value) {
            if (value === "delete") {
                return;
            }
            let { locales } = this;
            let { selectedItems } = this.privates;
            if (selectedItems.length === 0) {
                return Udesk.ui.notify.error(locales.components.pages.dataPreprocess.index.selectItmeCountError);
            }
            if (value === "redo") {
                this.privates.selectCallTimeVisible = true;
            }
            this.actions.update();
        },
        redo(value) {
            this.privates.selectCallTimeVisible = false;
            this.actions.update();
            if (!value) {
                this.privates.callTimeRange = [];
                this.privates.redoCallIds = [];
                return;
            }

            let {
                locales
            } = this;
            let {
                callTimeRange,
                redoCallIds,
                selectedItems,
            } = this.privates;
            if (moment(callTimeRange[0]).add(31, "days") <= callTimeRange[1]) {
                return Udesk.ui.notify.error(locales.components.pages.dataPreprocess.index.callTimeError);
            }
            if (redoCallIds.length === 0) {
                return Udesk.ui.notify.error(locales.components.pages.dataPreprocess.index.callRecordCountError);
            }
            let url = Udesk.business.apiPath.concatApiPath("preprocessRules/redo", this.props.sdkOptions);
            let data = {
                startTime: moment(callTimeRange[0]).hour(0).minute(0).second(0).millisecond(0).format("YYYY-MM-DD HH:mm:ss"),
                endTime: moment(callTimeRange[1]).hour(23).minute(59).second(59).millisecond(999).format("YYYY-MM-DD HH:mm:ss"),
                ids: selectedItems.map(item => item.id),
                processCount: redoCallIds.length,
                callIds: redoCallIds,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, data).then(
                (resp) => {
                    Udesk.ui.notify.success(locales.components.pages.dataPreprocess.index.redoSuccess);
                    tranToTaskCenterNotification();
                    this.privates.callTimeRange = [];
                    this.privates.redoCallIds = [];
                    this.privates.selectedItems = [];
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || locales.components.pages.dataPreprocess.redoError);
                    this.privates.callTimeRange = [];
                    this.privates.redoCallIds = [];
                }
            );
        },
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        dataSourceChanged(value) {
            this.privates.dataSource = value;
            this.actions.update();
            this.setState({
                pageNum: 1,
            }, () => {
                this.actions.reloadAsyncModel();
            });
        },
        statusChanged(value) {
            this.privates.status = value;
            this.actions.update();
            this.setState({
                pageNum: 1,
            }, () => {
                this.actions.reloadAsyncModel();
            });
        },
        searchItems(e) {
            if (e.keyCode === 13) {
                this.setState({
                    pageNum: 1,
                }, () => {
                    this.actions.reloadAsyncModel();
                });
            }
        },
        createItem() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName("dataPreprocessNew"),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        editItem(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName("dataPreprocessEdit"),
                pathParams: {
                    id: item.id
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        deleteItem(item) {
            let {
                locales
            } = this;
            let onConfirm = () => {
                let {
                    sdkOptions
                } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(`preprocessRules/${item.id}`, sdkOptions);
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.deleteFailed);
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: locales.labels.deleteContent,
                onOk: onConfirm
            });
        },
        onDateChanged(value) {
            this.privates.callTimeRange = value;
            if (moment(value[0]).add(31, "days") <= value[1]) {
                return Udesk.ui.notify.error(this.locales.components.pages.dataPreprocess.index.callTimeError);
            }
            let url = Udesk.business.apiPath.concatApiPath("data/call-num", this.props.sdkOptions);
            let data = {
                startTime: moment(value[0]).hour(0).minute(0).second(0).millisecond(0).format("YYYY-MM-DD HH:mm:ss"),
                endTime: moment(value[1]).hour(23).minute(59).second(59).millisecond(999).format("YYYY-MM-DD HH:mm:ss"),
            };
            Udesk.ajax.post(url, data).then(
                (resp) => {
                    this.privates.redoCallIds = resp.data.ids;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.selectCallTime.getCallRecordError);
                }
            );
        },
    };

    //#region Life Cycle
    // init() {
    // const company = Udesk.data.init.company;
    // if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
    //     this.privates.dataSource = null;
    // } else if (company.enabledCallInspect !== 0) {
    //     this.privates.dataSource = 1;
    // } else if (company.enabledImInspect !== 0) {
    //     this.privates.dataSource = 2;
    // }
    // }
    componentDidMount() {
        getColumns(this);
        let dataSourceFlags = Udesk.enums.dataPreprocessSourceFlags;
        const company = Udesk.data.init.company;
        if(company.enabledCallInspect === 0){
            dataSourceFlags = dataSourceFlags.filter(i => i.id !== Udesk.enums.dataPreprocessSourceFlags.voiceCall.id);
        }
        if(company.enabledImInspect === 0){
            dataSourceFlags = dataSourceFlags.filter(i => i.id !== Udesk.enums.dataPreprocessSourceFlags.textDialogue.id);
        }
        dataSourceFlags = dataSourceFlags.map(item => {
            return {
                value: item.id,
                label: item.name
            };
        });
        this.privates.dataSourceFlags = dataSourceFlags;
        let statusFlags = Udesk.enums.dataPreprocessStatusFlags;
        statusFlags = statusFlags.map(item => {
            return {
                value: item.id,
                label: item.name
            };
        });
        this.privates.statusFlags = statusFlags;
        this.actions.update();
    }
    componentWillUnmount() {

    }
    //#endregion
}

function getColumns(that) {
    let {
        actions,
        locales
    } = that;
    let columns = [
        {
            dataIndex: "ruleName",
            width: "20%",
            title: locales.fields.dataPreprocess.name
        },
        {
            dataIndex: "ruleDesc",
            width: "20%",
            title: locales.fields.dataPreprocess.desc
        },
        {
            dataIndex: "dataSource",
            width: "15%",
            title: locales.fields.dataPreprocess.dataSource,
            // hasTemplate: true,
            render: (name, item, index) => {
                return Udesk.enums.dataPreprocessSourceFlags.getName(item.dataSource);
            }
        },
        {
            dataIndex: "ruleType",
            width: "15%",
            title: locales.fields.dataPreprocess.type,
            // hasTemplate: true,
            render: (name, item, index) => {
                if (item.ruleType === Udesk.enums.dataPreprocessTypes.system.id) {
                    return Udesk.enums.dataPreprocessTypes.getName(item.ruleType);
                } else {
                    if (item.ruleSecondaryType){
                        return Udesk.enums.dataSourceCutom.getName(item.ruleSecondaryType);
                    }
                }

            }
        },
        {
            dataIndex: "ruleStatus",
            width: "15%",
            title: locales.fields.dataPreprocess.status,
            // hasTemplate: true,
            render: (name, item, index) => {
                return Udesk.enums.dataPreprocessStatusFlags.getName(item.ruleStatus);
            }
        },
        {
            dataIndex: "actions",
            width: "15%",
            title: locales.fields.dataPreprocess.actions,
            // hasTemplate: true,
            render: (name, item, index) => {
                return (
                    <div className="qa-react-page-body-content-table-actions">
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("datapre:rule:update")}>
                            <span className="action-btn" title={locales.labels.edit} onClick={actions.editItem.params(item)}>
                                {locales.labels.edit}
                            </span>
                        </If>
                        <If condition={item.ruleType !== 1 && Udesk.data.init.user && Udesk.data.init.user.hasFeature("datapre:rule:delete")}>
                            <span style={{marginLeft: 8, color: 'red'}} className="action-btn delete-btn" title={locales.labels.delete} onClick={actions.deleteItem.params(item)}>
                                {locales.labels.delete}
                            </span>
                        </If>
                    </div>
                );
            }
        }
    ];
    that.setState({
        columns
    });
}

export default DataPreprocessComponent;