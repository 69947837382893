import React, {useCallback, useMemo, useState} from 'react';
import Udesk from 'Udesk';
import {StandardizeTableProps} from 'udesk_gm_ui/es/standardize-table/types';
import {Button} from "udesk-ui";
import { array2tree } from 'Component/pages/intelligent-analysis/supervision/detail/recommendation';
import { inspect, posterity } from 'src/util/core';
import { useTreeActiveKey } from '../../../components';
import { useLiveEffect } from 'src/util/hook';
import type { TreeProps } from 'udesk-ui/es/tree';
import { TreeNode } from 'udesk_gm_ui/es/tree-menu/types';
import UdeskLocales from 'UdeskLocales';

type IconType = React.ReactNode | ((props: any) => React.ReactNode);
interface DataNode {
    checkable?: boolean;
    children?: DataNode[];
    disabled?: boolean;
    disableCheckbox?: boolean;
    icon?: IconType;
    isLeaf?: boolean;
    key: string | number;
    title?: React.ReactNode;
    selectable?: boolean;
    switcherIcon?: IconType;
    /** Set style of TreeNode. This is not recommend if you don't have any force requirement */
    className?: string;
    style?: React.CSSProperties;
}

const loop = (
    data: DataNode[],
    key: React.Key,
    callback: (node: DataNode, i: number, data: DataNode[]) => void,
) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
            return callback(data[i], i, data);
        }
        if (data[i].children) {
            loop(data[i].children!, key, callback);
        }
    }
};

export const useTree = (props) => {
    const {
        isEdit = true,
        locales, 
        editorModalRef, 
        request, 
        storages, 
        saveStorages
    } = props;
    const [selectedKey, setSelectedKey] = useTreeActiveKey({
        storages,
        saveStorages,
    });
    const [treeData, setTreeData] = useState<TreeNode[]>([]);

    const createFolderHandel = useCallback(() => {
        editorModalRef?.current?.open({}, 2);
    },[
        editorModalRef
    ]);

    const onDrop: TreeProps['onDrop'] = useCallback((info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const data = $.extend(true, [], treeData);
    
        let dragNode: DataNode;
        loop(data, dragKey, (item, index, array) => {
            array.splice(index, 1);
            dragNode = item;
        });
    
        if (!info.dropToGap) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                item.children.unshift(dragNode);
            });
        } else if (
            dropPosition === 1 &&
            info.node.props.children?.length > 0 &&
            info.node.props.expanded
        ) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                item.children.unshift(dragNode);
            });
        } else {
            let list: DataNode[] = [];
            let i: number;
            loop(data, dropKey, (node, index, array) => {
                list = array;
                i = index;
            });
            if (dropPosition === -1) {
                list.splice(i!, 0, dragNode!);
            } else {
                list.splice(i! + 1, 0, dragNode!);
            }
        }
        const list = new Array<any>();
        posterity(
            data, 
            (node) => {
                list.push({
                    id: node.id,
                    name: node.name,
                    parentId: node.parentId,
                });
            }, 
            node => node.children
        );
        console.log(list);

        request('/review/intelligentPartner/category/move', list, 'post').then(
            resp => {
                Udesk.ui.notify.success(locales.business.info.saveSuccess);
                setTreeData(data);
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        locales.business.info.saveSuccess, 
        request, 
        treeData
    ]);
      

    const getTreeData = useCallback(() => {
        request('/review/intelligentPartner/category' + inspect(isEdit, () => '', '/center')).then(
            resp => {
                setTreeData(array2tree(resp.data ?? [], (node) => {
                    node.label = node.name;
                    node.title = node.name;
                    node.key = node.id;
                    node.value = node.id;
                    return node;
                }) as any[]);
                if (!selectedKey) {
                    setSelectedKey(resp.data?.[0]?.id ?? null);
                }
            }
        );
    }, [request, selectedKey, setSelectedKey]);


    const classificationProps = useMemo<StandardizeTableProps['classification']>(() => {
        return {
            treeData,
            defaultExpandAll: true,
            onSelect: setSelectedKey,
            selectedKeys: [selectedKey],
            draggable: false,
            onDrop: onDrop as any,
            fieldNames: {
                title: 'name',
                key: 'id',
            },
            treeNodeActionMenu: [
                {
                    label: /* 重命名 */UdeskLocales['current'].pages.coach.learningCenter.course.components.folderTree.util.rename,
                    onClick: (item) => {
                        editorModalRef?.current?.open(item, 1);
                    },
                },
                {
                    label: /* 移动到 */UdeskLocales['current'].pages.coach.learningCenter.course.components.folderTree.util.moveTo,
                    onClick: (item) => {
                        editorModalRef?.current?.open(item, 3);
                    },
                },
                {
                    label: /* 复制到 */UdeskLocales['current'].pages.coach.learningCenter.course.components.folderTree.util.copyTo,
                    onClick: (item) => {
                        editorModalRef?.current?.open(item, 0);
                    },
                },
                {
                    label: /* 删除 */UdeskLocales['current'].pages.coach.learningCenter.course.components.folderTree.util.delete,
                    danger: true,
                    onClick: (item) => {
                        request(`/review/intelligentPartner/category/${item.id}`, {}, 'del').then(
                            resp => {
                                Udesk.ui.notify.success(locales.business.info.deleteSuccess);
                                getTreeData();
                            },
                            reason => {
                                Udesk.ui.notify.error(reason.errorMsg);
                            }
                        );
                    },         
                    disabled: (item) => Boolean(false),
                },
            ].filter(() => isEdit),
            title: isEdit ? (
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <div>{/* 课程分类 */UdeskLocales['current'].pages.coach.learningCenter.course.components.folderTree.util.courseClassification}</div>
                    <Button onClick={createFolderHandel} size="small" type="link">{/* 新建 */}{UdeskLocales['current'].pages.coach.learningCenter.course.components.folderTree.util.newlyBuild}</Button>
                </div>
            ): /* 课程分类 */UdeskLocales['current'].pages.coach.learningCenter.course.components.folderTree.util.courseClassification,
        };
    }, [
        treeData, 
        setSelectedKey, 
        selectedKey, 
        isEdit, 
        onDrop, 
        createFolderHandel, 
        editorModalRef, 
        request, 
        locales.business.info.deleteSuccess, 
        getTreeData
    ]);

    useLiveEffect(getTreeData, []);

    return [
        treeData,
        getTreeData,
        classificationProps,
        setSelectedKey,
    ];
};