import React from 'react';
import { getSmoothStepPath } from 'reactflow';
import { lineStyle } from '../const/lineStyle';

export default (props) => {

    const { fromX, fromY, toX, toY, fromPosition, toPosition } = props;

    const [edgePath] = getSmoothStepPath({
        sourceX: fromX,
        sourceY: fromY,
        sourcePosition: fromPosition,
        targetX: toX,
        targetY: toY,
        targetPosition: toPosition,
    });
    return (
        <g>
            <path
                fill="none"
                stroke={lineStyle.color}
                strokeWidth={lineStyle.strokeWidth}
                className="animated"
                d={edgePath}
                // d={`M${fromX},${fromY} L${toX},${toY} `}
                strokeDasharray="4 5"
            />
        </g>
    );
};
