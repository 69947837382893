import Udesk from 'Udesk';

export const linkTo = (
    history, 
    routeName, 
    params = {},
    state = {},
    query = {}
) => {
    const routeOptions = {
        history,
        routeName,
        pathParams: params,
        state,
        queryParams: query,
    };
    
    Udesk.ui.routing.transitionTo(routeOptions);
};


export const getSearchParams = (search) => {
    if (search) {
        const queryParams = new URLSearchParams(search);
        const result = {};
    
        queryParams.forEach((value, key) => {
            if (result[key]) {
                if (Array.isArray(result[key])) {
                    result[key].push(value);
                } else {
                    result[key] = [result[key], value];
                }
            } else {
                result[key] = value;
            }
        });
    
        return result;
    }
};