import React from 'react';
import { Dropdown, Menu } from 'udesk-ui';
import ApplyTemplatesComponent from './component';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

export default class ApplyTemplatesTemplate extends ApplyTemplatesComponent {
    render() {
        let { templates, title, extra = null } = this.props;
        let { actions, locales } = this;
        return (
            <div className="apply-templates-component">
                <div className="apply-templates-title">
                    <Choose>
                        <When condition={title}>{title}</When>
                        <Otherwise>
                            {locales.components.pages.components.applyTemplates.title}
                        </Otherwise>
                    </Choose>
                    <div style={{ flex: 1, textAlign: 'right' }}>{extra}</div>
                </div>
                <div className="apply-templates-content">
                    <If condition={templates && templates.length > 0}>
                        <For each="template" index="index" of={templates}>
                            <Dropdown
                                overlay={
                                    template.taskType === 1 ? (
                                        <Menu>
                                            <Menu.Item
                                                danger
                                                onClick={() => actions.deleteTemplate(template)}
                                            >
                                                <span>{/* 删除 */}{UdeskLocales['current'].components.applyTemplates.template.delete}</span>
                                            </Menu.Item>
                                        </Menu>
                                    ) : null
                                }
                            >
                                <div
                                    key={index}
                                    className="apply-templates-item"
                                    title={template.taskName}
                                    onClick={actions.transitionToTemplate.params(template)}
                                >
                                    <span className="apply-templates-item-name">
                                        {template.taskName}
                                    </span>
                                    <span className="apply-templates-item-score">
                                        {template.score}
                                    </span>
                                    <span className="apply-templates-item-grade">
                                        {template.inspectionGrade}
                                    </span>
                                </div>
                            </Dropdown>
                        </For>
                    </If>
                </div>
            </div>
        );
    }
}
