import {
    getReviewIntelligentPartnerNodePromptConfig,
    postReviewIntelligentPartnerNodePromptConfig,
} from 'src/api/review/intelligentPartner/node/promptConfig';
import { postReviewIntelligentPartnerNodePromptConfigPrompt } from 'src/api/review/intelligentPartner/node/promptConfig/prompt';
import { putReviewIntelligentPartnerNodePromptConfigById } from 'src/api/review/intelligentPartner/node/promptConfig/{id}';
import { getReviewIntelligentPartnerNodePromptConfigExampleDialoguesByFlowId } from 'src/api/review/intelligentPartner/node/promptConfig/exampleDialogues/{flowId}';
import UdeskLocales from 'UdeskLocales';

const fields = (props) => [
    {
        label: /* 注意事项 */ UdeskLocales['current'].pages.coach.courseManagement.flow.components
            .const.nodeConfigs.aiGenPromptNode.mattersNeedingAttention,
        type: 'TextArea',
        name: 'notes',
        props: {
            rows: 6,
            placeholder: UdeskLocales['current'].labels.pleaseEnter,
        },
    },
    {
        label: '',
        type: 'GenByAi',
        name: 'exampleDialogues',
        props: {
            rows: 6,
            placeholder:
                /* 请输入对话示例 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                    .components.const.nodeConfigs.aiGenPromptNode.pleaseEnterAConversationExample,
            // readOnly: true,
            onGen: () => {
                return getReviewIntelligentPartnerNodePromptConfigExampleDialoguesByFlowId({
                    segments: {
                        flowId: props.flowId,
                    },
                }).then((res) => {
                    return res.data || '';
                });
            },
            label: /* 对话示例 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                .components.const.nodeConfigs.aiGenPromptNode.dialogueExample,
            btnText:
                /* 生成对话示例 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                    .components.const.nodeConfigs.aiGenPromptNode.generateConversationExamples,
        },
    },
    {
        label: '',
        type: 'GenByAi',
        name: 'promptWords',
        props: {
            rows: 6,
            placeholder:
                /* 请输入提示语 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                    .components.const.nodeConfigs.aiGenPromptNode.pleaseEnterThePromptLanguage,
            // readOnly: true,
            onGen: () => {
                const notes = props.form?.getFieldValue?.('notes');

                return postReviewIntelligentPartnerNodePromptConfigPrompt(
                    {
                        flowId: props.flowId,
                        notes,
                    },
                    { timeout: 60000 }
                ).then((res) => {
                    return res.data || '';
                });
            },
            label: UdeskLocales['current'].components.coach.aiGenPrompt.components.title
                .hintLanguage,
            btnText:
                UdeskLocales['current'].components.coach.aiGenPrompt.components.title
                    .generatePromptLanguage,
        },
    },
];

const request = ({ nodeId }) => {
    return getReviewIntelligentPartnerNodePromptConfig({
        params: {
            nodeId,
        },
    }).then(async (resp) => {
        const res: any = resp.data || {};

        if (!resp.data?.notes) {
            res.notes = getAttentionMsg();
        }

        return res;
    });
};

const finish = ({ formInstanceCache, configId, nodeId }) => {
    return (values) => {
        const params = {
            ...values,
            nodeId,
        };
        console.log(params);

        if (!configId) {
            return postReviewIntelligentPartnerNodePromptConfig(params, {
                successMsg:
                    /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                        .components.const.nodeConfigs.knowledgeNode.successfullySaved,
            });
        } else {
            return putReviewIntelligentPartnerNodePromptConfigById(params, {
                segments: {
                    id: configId,
                },
                successMsg:
                    /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                        .components.const.nodeConfigs.knowledgeNode.successfullySaved,
            });
        }
    };
};

const getAttentionMsg = () => {
    // no-locale-translate
    return `        1.你作为[模拟角色]，需要向我表达[客户需求]，以提问的方式输出，每个问题需要单独提问，等回答完一个再问下一个，如果我回答的不清楚，你需要进行追问一次。
        2.当对话偏离了沟通框架你需要主动拉回对话主题。
        3.你要适当的评价我的回答是正确还是错误，如果我回答的不好你可以追问或质疑我。
        4.问题问完之后你需要回复“没有问题了”正常结束会话。
        5.请用中文与我进行对话。
        6.你只输出作为一个[模拟角色]应该说的话。`;
};

export default {
    fields,
    request,
    finish,
};
