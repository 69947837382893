import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import UdeskLocales from 'UdeskLocales';

class intelligentAnalysis extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let {
            route,
            location,
            sdkOptions,
        } = this.props as any;

        let {
            locales
        } = this as any;

        let navLists: any[] = [];
        // 根据当前用户权限，组装菜单列表
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('case:root')) {
            navLists.push({
                text: locales.menu.tasks.caseBaseFolder.text,
                icon: 'ic-anliku',
                pathName: 'caseBaseFolderList',
                isActive: (match, location) => {
                    return location.pathname.includes('/file-case-base/folder-list');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('sys:knowledge:root')) {
            navLists.push({
                text: /* 知识库 */UdeskLocales['current'].pages.gong.salesLearningCenter.knowledgeBase,
                pathName: "gongKnowledgeBase",
                isActive: (match, location) => {
                    return location.pathname.includes("/knowledge-base");
                }
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:WordsMining:root")) {
            navLists.push({
                text: /* 话术库 */UdeskLocales['current'].pages.gong.salesLearningCenter.speechCorpus,
                pathName: "salesDissentFavoriteWordsMining",
                isActive: (match, location) => {
                    return location.pathname.includes("/words-mining");
                }
            });
        }
        
        if (this.isRedirect(route.path, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName
            });
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else {
            return (
                <div className="semantic-intelligence">
                    {/* <div className="semantic-intelligence-nav col-md-2 col-sm-3 col-xs-12">
                        <NavBar navLists={navLists} classNames="semantic-intelligence-nav-container" navItemClassNames="semantic-intelligence-nav-item" activeClassNames="semantic-intelligence-nav-item-active" />
                    </div>
                    <div className="semantic-intelligence-content col-md-10 col-sm-9 col-xs-12">
                        <For each="route" index="i" of={routes}>
                            <If condition={!route.authCode || (route.authCode && Udesk.data.init.user.hasFeature(route.authCode))}>
                                <SubRoutes key={i} route={route} />
                            </If>
                        </For>
                    </div> */}
                    <LayoutMenu {...this.props} navLists={navLists} menuTitle={/* 学习中心 */UdeskLocales['current'].pages.gong.salesLearningCenter.learningCenter} />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(intelligentAnalysis);