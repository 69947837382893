import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import { Input, Select, Space, TreeSelect } from 'udesk-ui';
import { EnumSelect } from 'Component/common/enum-component';
import { inspect, isDefined, isEmpty, isNotEmpty, isNumber, isPlainObject, isUndefined } from 'src/util/core';
import { getSmartTagCategorys } from 'src/api/smartTagCategorys';
import { postSmartTagsFindAll } from 'src/api/smartTags/findAll';
import { useLiveEffect } from 'src/util/hook';
import { getFieldDataTaskByTaskId } from 'src/api/field/data/task/{taskId}';
import { getIsShowInspectionTasksSelect } from '..';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const FieldMap: any = {
    'v12': Udesk.enums.clusteringRulesType.satisfactionEvaluation.id,
    'call_smart_tags': Udesk.enums.clusteringRulesType.smartTags.id,
    'score': Udesk.enums.clusteringRulesType.rating.id,
    'inspect_grade_id': Udesk.enums.clusteringRulesType.actualGrade.id,
    'agent_group': Udesk.enums.clusteringRulesType.group.id,
};

export const getDefaultValue = (isSales, ...templates) => {
    return Object.assign(inspect(isSales, () => {
        return {
            conditionType: Udesk.enums.clusteringRulesTypeForSales.conversation.id,
            conditionScope: 1,
            conditionValue: {
                category: [],
                point: []
            },
        };
    }, () => {
        return {
            conditionType: Udesk.enums.clusteringRulesType.smartTags.id,
            conditionScope: 'is',
            conditionValue: undefined,
            inspectionTaskId: undefined,
        };
    }), ...templates);
};

export function addItem (list, options:any = {}) {
    Reflect.ownKeys(FieldMap).forEach(key => {
        const value = FieldMap[key];
        const item = list?.find(item => item.fieldName === key);

        if (item) {
            options[value.toString()] = Udesk.business.fields.buildConditionFields({
                fields: [item],
            })?.[0];
        }
    });
    return options;
}

function isMultiple (conditionScope, ...props) {
    return inspect(['is_any', 'not_any'].includes(conditionScope), ...props);
}

export function isHidden (conditionScope, ...props) {
    return inspect(['is_null', 'is_not_null'].includes(conditionScope), ...props);
}

export default React.memo((props: any) => {
    const {
        isSales,
        addBtn,
        delBtn,
        value = getDefaultValue(props.isSales),
        onChange, selectOptionData,
    } = props;
    const {
        inspectionTaskId,
        conditionType,
        conditionScope,
        conditionValue,
    } = value;

    const isShowInspectionTasksSelect = useMemo(() => {
        return getIsShowInspectionTasksSelect(isSales, conditionType);
    }, [
        isSales, 
        conditionType
    ]);
    const [options, setOptions] = useState<any>({});

    const formatValue = (value) => {
        return isPlainObject(value, () => {
            return [].concat(
                value?.category?.map((id) => `category-${id}`),
                value?.point?.map((id) => `point-${id}`)
            );
        }, () => value);
    };

    const changeHandle = (data, ) => {
        let result = {
            ...value, ...data,
        };

        if ('conditionType' in data) {
            result = getDefaultValue(isSales, {
                conditionType: result.conditionType
            });
        }
        if ('inspectionTaskId' in data) {
            result = getDefaultValue(isSales, {
                conditionType: result.conditionType,
                inspectionTaskId: result.inspectionTaskId,
            });
        }
        if ('conditionScope' in data) {
            result = getDefaultValue(isSales, {
                conditionType: result.conditionType,
                inspectionTaskId: result.inspectionTaskId,
                conditionScope: result.conditionScope,
            });
        }
        if ('conditionValue' in data) {
            if (isSales) {
                const defaultValue = {
                    category: [],
                    point: []
                };
                data.conditionValue.forEach((key) => {
                    const [, attr, id] = /^(\w*)-([-\d]*)$/.exec(key) || [];
                    if (attr) {
                        defaultValue[attr].push(+id);
                    }
                });
                result = ({
                    ...value,
                    conditionValue: defaultValue,
                }); 
            }
        } 
 
        if (Udesk.enums.clusteringRulesType.getName(result.conditionType)) {
            result.fieldConditionList = {
                field: options?.[result.conditionType]?.originalField,
                value: result.conditionValue,
                operator: result.conditionScope,
            };
        }

        onChange(result);
    };
    
    useEffect(() => {
        if (selectOptionData.searchTemplate) {
            setOptions(options => ({
                ...options, ...selectOptionData.searchTemplate
            }));
        }
    }, [selectOptionData.searchTemplate]);

    useEffect(() => {
        if (value.inspectionTaskId) {
            if (isNotEmpty(selectOptionData.inspectionTasks)) {
                const task = selectOptionData.inspectionTasks.find(item => item.key === value.inspectionTaskId);

                if (task) {
                    if (task._options) {
                        setOptions(options => {
                            return {...options, ...task._options};
                        });
                    } else {
                        if (!task._optionsPromise) {
                            task._optionsPromise = getFieldDataTaskByTaskId({
                                segments: {
                                    taskId: value.inspectionTaskId
                                }
                            }).then(
                                resp => {
                                    task._options = addItem(resp.data);
                                }
                            );
                        }
                        if (task._optionsPromise) {
                            task._optionsPromise.then(
                                () => {
                                    setOptions(options => {
                                        return {...options, ...task._options};
                                    });
                                }
                            );
                        }
                    }
                }
            }
        }
    }, [selectOptionData.inspectionTasks, value.inspectionTaskId]);

    return (
        <div className='condition-item'>
            <Space>
                {addBtn}
                <EnumSelect 
                    style={{minWidth: 100}}
                    value={formatValue(conditionType)} 
                    enumKey={isSales ? 'clusteringRulesTypeForSales' : 'clusteringRulesType'}
                    onChange={(value) => changeHandle({conditionType: value})}
                />
                {
                    inspect(isShowInspectionTasksSelect, () => {
                        return (
                            <Select
                                style={{minWidth: 150}}
                                placeholder={/* 请选择质检任务 */UdeskLocales['current'].pages.semanticIntelligence.clusteringAnalysis.add.components.conditionItem.pleaseSelectAQualityInspectionTask}
                                className={isDefined(inspectionTaskId, '', 'error')}
                                value={formatValue(inspectionTaskId)} 
                                onChange={(value) => changeHandle({inspectionTaskId: value})}
                                options={selectOptionData.inspectionTasks}
                            />
                        );
                    })
                }
                <EnumSelect 
                    style={{minWidth: 100}}
                    value={formatValue(conditionScope)} 
                    enumKey={inspect(isSales,"clusteringRulesContainType", () => {
                        let operators = options?.[conditionType]?.operators;
                        if (isUndefined(operators)) {
                            operators = [{
                                name: /* 等于 */UdeskLocales['current'].pages.semanticIntelligence.clusteringAnalysis.add.components.conditionItem.beEqualTo, id: 'is'
                            }];
                        }
                        return operators;
                    })}
                    onChange={(value) => changeHandle({conditionScope: value})}
                />
                {
                    isHidden(conditionScope, null, () => inspect(isSales, () => {
                        return (
                            <TreeSelect 
                                style={{width: 250}}
                                multiple
                                treeCheckable
                                className={conditionValue?.category?.length === 0 && conditionValue?.category?.length === 0 ? 'error' : ''}
                                value={formatValue(conditionValue)}
                                onChange={(value) => changeHandle({conditionValue: value})}
                                treeNodeFilterProp="title"
                                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                treeData={selectOptionData[isSales ? 'sales' : 'other'][conditionType - 1]}
                                treeDataSimpleMode={true}
                            />
                        );
                    }, () => {
                        return inspect(conditionType === Udesk.enums.clusteringRulesType.smartTags.id, () => {
                            return (
                                <TreeSelectForSmartTag 
                                    multiple={isMultiple(conditionScope)}
                                    treeCheckable={isMultiple(conditionScope)}
                                    style={{minWidth: 150}}
                                    className={isDefined(conditionValue, '', 'error')}
                                    value={formatValue(conditionValue)}
                                    onChange={(value) => changeHandle({conditionValue: value})}
                                />
                            );
                        },() => {
                            return inspect(isShowInspectionTasksSelect && options?.[conditionType]?.dataSource, () => {
                                return (
                                    <Select
                                        mode={isMultiple(conditionScope, 'multiple', () => undefined)}
                                        style={{minWidth: 150}}
                                        className={isDefined(conditionValue, '', 'error')}
                                        value={formatValue(conditionValue)}
                                        options={options?.[conditionType]?.dataSource?.map(item => ({
                                            label: item.name,
                                            value: item.id,
                                        })) ?? []} 
                                        onChange={(value) => changeHandle({conditionValue: value})} 
                                    />
                                );
                            }, () => {
                                return (
                                    <Input 
                                        className={isEmpty(conditionValue, 'error', '')}
                                        value={formatValue(conditionValue)}
                                        onChange={(e) => changeHandle({conditionValue: e.target.value})} 
                                    />
                                );
                            });
                        });
                    }))
                }
                {delBtn}
            </Space>
        </div>
        
    );
});

const createParentId = (id) => {
    return 'p/' + id;
};

export const TreeSelectForSmartTag = React.memo<any>((props) => {
    const {value, onChange, multiple, ...otherProps} = props;
    const [categories, setCategories] = useState<any[]>();

    const changeHandle = useCallback((value) => {
        onChange(multiple ? value : isNumber(value, value, () => undefined));
    }, [multiple, onChange]);

    useLiveEffect((success) => {
        Promise.all([
            getSmartTagCategorys({
                params:{ funcType: 'QUALITY_INSPECT'}
            }),
            postSmartTagsFindAll({
                funcType: 'QUALITY_INSPECT',
                pageNum: 1, pageSize: 1000, tagStatus: 1, tagType: 1
            })
        ]).then(([resp, resp2]) => {
            const set = new Set<any>();
            const cache = new Map<number, any>(
                resp.data?.map(item => [item.id, item]) as any
            );

            const getParent = (id) => {
                const parent = cache.get(id);
                if (parent) {
                    set.add(parent);
                    getParent(parent.parentId);
                }
            };

            const leafs = resp2.data?.map(item => {
                getParent(item.categoryId);

                return {
                    id: item.id,
                    pId: createParentId(item.categoryId),
                    value: item.id,
                    title: item.tagName,
                    isLeaf: true
                };
            });

            const parents = Array.from(set).map(item => {
                return {
                    id: createParentId(item.id),
                    pId: createParentId(item.parentId),
                    value: createParentId(item.id),
                    title: item.categoryName,
                    isLeaf: false,
                };
            });

            success(() => {
                setCategories(
                    (new Array<any>()).concat(leafs, parents)
                ); 
            });
        });
    }, []);
    
    return (
        <TreeSelect
            {...otherProps}
            multiple={multiple}
            treeDataSimpleMode
            value={value || inspect(multiple, [], '')}
            onChange={changeHandle}
            dropdownStyle={{ minWidth: 250, maxHeight: 400, overflow: 'auto' }}
            treeData={categories}
        />
    );
});