import Udesk from 'Udesk';
import React from 'react';

export default class ViewComponent extends React.Component {
    //#region actions
    actions = {
        userHistoryBack() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'adminUserIndex'
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        }
    };
    //#endregion
}
