import React, { FC, useEffect, useMemo, useState } from 'react';
import { Space, Button, Card, Checkbox, message, Typography, Empty, Spin } from 'udesk-ui';
import { MessageOutlined, ArrowDownSOutlined, RefreshOutlined } from '@udesk/icons';
import { postSemanticTagRecommend } from 'src/api/semantic-tag/recommend';
import debounce from 'lodash-es/debounce';
import './index.less';
import UdeskLocales from 'UdeskLocales';

type Question = {
    value: string;
    checked?: boolean;
    [key: string]: unknown;
};

type SimilarQuestionRecommendProps = {
    onSave?: (list: Question[]) => void;
    onCancel?: () => void;
    content?: string;
    btnProps?: { [key: string]: unknown };
    width?: number;
};

const SimilarQuestionRecommend: FC<SimilarQuestionRecommendProps> = (props) => {
    const { onSave, onCancel, content, btnProps, width } = props;

    const [isShow, setIsShow] = useState(false);
    const onShowToggle = () => {
        setIsShow(!isShow);
    };

    const [recommend, setRecommend] = useState<Question[]>([]);
    const [loading, setLoading] = useState(false);

    const getRecommend = useMemo(
        () =>
            debounce((content: string) => {
                if (content) {
                    setLoading(true);
                    postSemanticTagRecommend({ standardContent: content })
                        .then((res) => {
                            if (res.data) {
                                const questions = (res.data || []).map((q: any) => ({
                                    value: q,
                                    checked: false,
                                }));
                                setRecommend(questions);
                            }
                        })
                        .catch(() => {
                            setRecommend([]);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }
            }, 300),
        []
    );

    useEffect(() => {
        isShow && getRecommend(content || '');
    }, [content, getRecommend, isShow]);

    const onCheckedChange = (idx: number, e: any) => {
        setRecommend((_recommend) =>
            _recommend.map((r, _idx) => {
                if (_idx === idx) {
                    return {
                        ...r,
                        checked: e.target.checked,
                    };
                } else {
                    return r;
                }
            })
        );
    };

    const [allChecked, setAllChecked] = useState(false);
    const onAllCheckedChange = (e: any) => {
        setAllChecked(e.target.checked);
        setRecommend((_recommend) =>
            _recommend.map((r) => {
                return {
                    ...r,
                    checked: e.target.checked,
                };
            })
        );
    };

    const onSaveInner = () => {
        const _r = recommend.filter((r) => r.checked);
        if (!_r.length) {
            message.warning(/* 请选择问题！ */UdeskLocales['current'].components.similarQuestionRecommend.index.pleaseSelectAQuestion);
            return;
        }
        onSave?.(_r);
        setIsShow(false);
        setRecommend([]);
        setAllChecked(false);
    };

    const onCancelInner = () => {
        onCancel?.();
        setIsShow(false);
        setRecommend([]);
        setAllChecked(false);
    };

    const onRefresh = () => {
        setAllChecked(false);
        getRecommend(content || '');
    };

    return (
        <>
            <Button type="link" onClick={onShowToggle} {...btnProps}>
                <span>{/* 相似问推荐 */}{UdeskLocales['current'].components.similarQuestionRecommend.index.similarQuestionRecommendation}</span>
                <ArrowDownSOutlined
                    style={{
                        transform: isShow ? 'rotate(180deg)' : '',
                        transformOrigin: '50% 50%',
                        transition: 'transform .3s',
                    }}
                />
            </Button>

            {isShow ? (
                <Card
                    size="small"
                    title={
                        <Space>
                            <MessageOutlined />
                            <span>{/* 相似问推荐 */}{UdeskLocales['current'].components.similarQuestionRecommend.index.similarQuestionRecommendation}</span>
                        </Space>
                    }
                    extra={
                        <Checkbox
                            checked={allChecked}
                            onChange={onAllCheckedChange}
                            disabled={loading}
                        >{/* 当页全选 */}{UdeskLocales['current'].components.similarQuestionRecommend.index.selectAllOnPage}</Checkbox>
                    }
                    style={{ width: width || '100%' }}
                >
                    <Spin spinning={loading}>
                        <div style={{ minHeight: 60 }}>
                            {recommend.length ? (
                                recommend.map((r, idx) => (
                                    <div
                                        key={r.value + idx}
                                        style={{ width: '100%', display: 'flex' }}
                                    >
                                        <Checkbox
                                            style={{ marginRight: 10 }}
                                            checked={!!r.checked}
                                            onChange={(e) => onCheckedChange(idx, e)}
                                        ></Checkbox>
                                        <Typography.Text
                                            ellipsis={{
                                                tooltip: (
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                        {r.value}
                                                    </div>
                                                ),
                                            }}
                                        >
                                            {r.value}
                                        </Typography.Text>
                                    </div>
                                ))
                            ) : loading ? null : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </div>
                    </Spin>

                    <div style={{ padding: '4px 0' }}>
                        <Space>
                            <Button size="small" onClick={onCancelInner}>{/* 取消 */}{UdeskLocales['current'].components.similarQuestionRecommend.index.cancel}</Button>
                            <Button
                                type="primary"
                                size="small"
                                onClick={onSaveInner}
                                disabled={loading}
                            >{/* 保存 */}{UdeskLocales['current'].components.similarQuestionRecommend.index.preserve}</Button>
                            <Button
                                shape="round"
                                size="small"
                                icon={<RefreshOutlined />}
                                onClick={onRefresh}
                                disabled={loading}
                            >{/* 换一批 */}{UdeskLocales['current'].components.similarQuestionRecommend.index.changeBatch}</Button>
                        </Space>
                    </div>
                </Card>
            ) : null}
        </>
    );
};

export default SimilarQuestionRecommend;
