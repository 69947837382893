import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Dropdown, Menu } from 'udesk-ui';
// import './style.scss';
import styled from 'styled-components';
import VirtualLogin from 'src/components/pages/components/VirtualLogin';
import { RelationCompany } from 'src/api/types';

const LoginUserAvatarWrap = styled.div`
    cursor: pointer;
    &:hover,
    &:focus {
        color: rgba(27, 109, 255, 1);
    }
    .main-menu-component-nav-link {
        display: flex;
        align-items: center;
    }
    .avatar-name {
        &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.3em;
            vertical-align: middle;
            content: '';
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-left: 0.3em solid transparent;
        }
    }
    .avatar {
        margin-left: 18px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;

        img {
            margin: 0px;
            width: 100%;
            border-radius: 500px;
            height: ${(props) => props.height};
        }
    }
`;

export const LoginUserAvatar = React.memo((props: any) => {
    const locales = UdeskLocales['current'];

    const [visible, setVisible] = useState(false);
    const [relationCompanyList, setRelationCompanyList] = useState<RelationCompany[]>();

    useEffect(() => {
        setRelationCompanyList(Udesk.data.init?.allRelationCompanyList ?? []);
    }, []);

    const onToggle = () => {
        setVisible(true);
    };

    const onLogout = () => {
        const routeOptions = {
            history: props.history,
            routeName: 'authLogout',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    return (
        <LoginUserAvatarWrap height={props.height}>
            <Dropdown
                overlay={
                    <Menu>
                        {relationCompanyList?.length ? (
                            <Menu.Item key="toggle" onClick={() => onToggle()}>
                                {/* 切换 */}
                                {UdeskLocales['current'].components.loginUserAvatar.index.switch}
                            </Menu.Item>
                        ) : null}
                        <Menu.Item key="logout" onClick={() => onLogout()}>
                            {locales.labels.logout}
                        </Menu.Item>
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                <span className="main-menu-component-nav-link">
                    {Boolean(Udesk.data.init.user.realname) && (
                        <span className="avatar-name">{Udesk.data.init.user.realname}</span>
                    )}
                    <span className="avatar">
                        <img
                            src={
                                Udesk.data.init.user.headPortraitUrl || '/static/images/avatar.png'
                            }
                            alt="..."
                        />
                    </span>
                </span>
            </Dropdown>

            <VirtualLogin
                visible={visible}
                relationCompanyList={relationCompanyList}
                onCancel={() => setVisible(false)}
                closable
                maskClosable
            />
        </LoginUserAvatarWrap>
    );
});
