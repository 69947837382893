export default {
  lang: {
    num: 'Đầu tiên',
    total: 'Bài viết/Tổng số',
    strip: 'Sọc',
    more: 'Thêm',
    chosen: 'Đã chọn',
    cancel: 'Hủy lựa chọn',
    bulkOperations: 'Hoạt động hàng loạt',
    import: 'Nhập',
  },
};
