export default class UdeskError extends Error {
    constructor(message) {
        super(message);

        // For lower version of babel (<7) ONLY!
        let innerError = new Error(message);
        const excludePropNames = ["constructor"];
        for (let key of Object.keys(Object.getOwnPropertyDescriptors(Error.prototype))) {
            if (!excludePropNames.includes(key)) {
                this[key] = innerError[key];
            }
        }
        this.name = this.constructor.name;
    }
}
