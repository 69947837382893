// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetMessageUserNotificationsQuery,
    BaseResponseListMessageUserNotificationFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags message-user-notification-controller
 * @link [GET] /messageUserNotifications
 */
export function getMessageUserNotifications(
    options: ApiOptions<never, GetMessageUserNotificationsQuery> & {
        params: GetMessageUserNotificationsQuery;
    }
): Promise<BaseResponseListMessageUserNotificationFoundResponse> {
    return request<never, never, GetMessageUserNotificationsQuery>('/messageUserNotifications', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getMessageUserNotifications',
    });
}

export const meta = [
    {
        tags: ['message-user-notification-controller'],
        path: '/messageUserNotifications',
        method: 'get',
    },
];
