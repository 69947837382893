import Udesk from 'Udesk';
import React from 'react';
import DataMigrationRoute from './route';
import { Checkbox, Row, Col, Radio, PageHeader } from 'udesk-ui';
import DataMigrationUploadModal from './data-migration-upload-modal';
import './style.scss';

export default class dataMigrationTemplate extends DataMigrationRoute {
    render() {
        let { locales, actions } = this;
        let { isShowUploadModal, data, otherDatas, sexportStandard } = this.privates;
        return (
            // <div className='data-migration-page'>
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.dataMigration.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root data-migration-page' style={{ padding: 16 }}>

                        <div className='data-migration-page-body qa-react-page-body'>
                            <div className='data-migration-page-body-title-row'>
                                <div className='data-migration-page-body-title'>
                                    {locales.components.pages.dataMigration.dataUpload}
                                </div>
                                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:dataTransfer:manage")}>
                                    <div className='data-migration-page-body-content'>
                                        <div className='data-migration-body-load' onClick={actions.showUploadModal}>
                                            {locales.labels.uploadFile}
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <div className='data-migration-page-body-title-row data-migration-body-row-top'>
                                <div className='data-migration-page-body-title data-migration-body-title-top'>
                                    {locales.components.pages.dataMigration.dataDowload}
                                </div>
                                <div className='data-migration-page-body-content'>
                                    <div>{locales.components.pages.dataMigration.list.info}</div>
                                    <div style={{ width: '100%' }}>
                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Row>
                                                {data &&
                                                    data.length > 0 &&
                                                    data.map(item => {
                                                        return (
                                                            <Col span={6}>
                                                                <Checkbox
                                                                    value={item.id}
                                                                    onChange={actions.onTageChange.params(item.id)}>
                                                                    {item.name}
                                                                </Checkbox>
                                                            </Col>
                                                        );
                                                    })}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                    <div>{locales.components.pages.dataMigration.list.listInfo}</div>
                                    <div style={{ width: '500px' }}>
                                        <Radio.Group style={{ width: '100%' }} defaultValue={1}>
                                            <Row>
                                                {sexportStandard &&
                                                    sexportStandard.length > 0 &&
                                                    sexportStandard.map(item => {
                                                        return (
                                                            <Col span={6}>
                                                                <Radio
                                                                    value={item.value}
                                                                    onChange={actions.onSexportStandard.params(item.value)}>
                                                                    {item.name}
                                                                </Radio>
                                                            </Col>
                                                        );
                                                    })}
                                            </Row>
                                        </Radio.Group>
                                    </div>
                                    <div>{locales.components.pages.dataMigration.list.noInfo}</div>
                                    <div style={{ width: '500px' }}>
                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Row>
                                                {otherDatas &&
                                                    otherDatas.length > 0 &&
                                                    otherDatas.map(item => {
                                                        return (
                                                            <Col span={6}>
                                                                <Checkbox
                                                                    value={item.value}
                                                                    onChange={actions.onOtherDtasChange.params(item.value)}>
                                                                    {item.name}
                                                                </Checkbox>
                                                            </Col>
                                                        );
                                                    })}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                    <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:dataTransfer:manage")}>
                                        <div className='data-migration-body-load' onClick={actions.export}>
                                            {locales.labels.downloadFile}
                                        </div>
                                    </If>
                                </div>
                            </div>
                        </div>
                        <DataMigrationUploadModal
                            title={locales.fix.upload}
                            visible={isShowUploadModal}
                            headerClassName='udesk-qa-data-migration-modal-header'
                            onCancel={actions.closeUploadModal}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
