// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostVoiceUploadUploadRecordBody,
    BaseResponseVoiceUploadResponse,
    DeleteVoiceUploadUploadRecordBody,
    BaseResponseVoid,
} from '../../types';

/**
 * uploadRecord
 *
 * @export
 * @tags native-voice-upload-controller
 * @link [POST] /voice-upload/upload-record
 */
export function postVoiceUploadUploadRecord(
    data: PostVoiceUploadUploadRecordBody
): Promise<BaseResponseVoiceUploadResponse>;
export function postVoiceUploadUploadRecord(
    data: PostVoiceUploadUploadRecordBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoiceUploadResponse>;
export function postVoiceUploadUploadRecord(
    data: PostVoiceUploadUploadRecordBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoiceUploadResponse> {
    return request<PostVoiceUploadUploadRecordBody, never, never>('/voice-upload/upload-record', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postVoiceUploadUploadRecord',
    });
}

/**
 * deleteUploadedRecords
 *
 * @export
 * @tags native-voice-upload-controller
 * @link [DELETE] /voice-upload/upload-record
 */
export function deleteVoiceUploadUploadRecord(
    data: DeleteVoiceUploadUploadRecordBody
): Promise<BaseResponseVoid>;
export function deleteVoiceUploadUploadRecord(
    data: DeleteVoiceUploadUploadRecordBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function deleteVoiceUploadUploadRecord(
    data: DeleteVoiceUploadUploadRecordBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<DeleteVoiceUploadUploadRecordBody, never, never>('/voice-upload/upload-record', {
        ...options,
        data,
        method: 'delete',
        __$requestCalleeName: 'deleteVoiceUploadUploadRecord',
    });
}

export const meta = [
    {
        tags: ['native-voice-upload-controller'],
        path: '/voice-upload/upload-record',
        method: 'post',
    },
    {
        tags: ['native-voice-upload-controller'],
        path: '/voice-upload/upload-record',
        method: 'delete',
    },
];
