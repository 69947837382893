import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { Badge, Space, Button, PageHeader, Popconfirm, message } from 'udesk-ui';
import { useRequest } from 'src/util/hook';
import './index.scss';
import { getCurrentRouteName } from 'Udesk/system/subApp';

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};

const defaultStorageValue = {
    pagination: defaultPagination,
    name: undefined,
    createTime: undefined,
    speaker: undefined,
    status: undefined,
};

const linkTo = (
    history: any,
    routeName: string,
    params: { id: number } | undefined = undefined
) => {
    const routeOptions = {
        history,
        routeName,
        pathParams: params,
    };

    Udesk.ui.routing.transitionTo(routeOptions);
};

const DelBtn = (props) => {
    const { onDelete, locales } = props;
    return (
        <Popconfirm
            title={locales.labels.deleteContent}
            onConfirm={onDelete}
            onCancel={(e: any) => e.stopPropagation()}
            okText={locales.labels.confirm}
            placement="bottom"
            cancelText={locales.labels.cancel}
        >
            <Button danger type="link">
                {/* 删除 */ locales.pages.semanticIntelligence.clusteringAnalysis.index.delete}
            </Button>
        </Popconfirm>
    );
};

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const {
        history,
        saveStorages,
        storages: { pagination, ...filter },
    } = props;
    const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    const [tableData, setTableData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const request = useRequest();

    const viewHandle = useCallback(
        (id) => {
            linkTo(history, getCurrentRouteName('clusteringAnalysisDetail'), { id });
        },
        [history]
    );

    const editHandle = useCallback(
        (id) => {
            linkTo(history, getCurrentRouteName('clusteringAnalysisEdit'), { id });
        },
        [history]
    );

    const ajaxTableData = useCallback(
        (map) => {
            setLoading(true);
            return request('review/cluster', map)
                .then((resp) => {
                    const { pageNum, pageSize, ...filter } = map;
                    const pagination = {
                        total: resp.paging?.total,
                        current: resp.paging?.pageNum || pageNum,
                        pageSize: resp.paging?.pageSize || pageSize,
                    };
                    setTableData(resp.data ?? []);
                    saveStorages({
                        ...filter,
                        pagination,
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [saveStorages, request]
    );

    const delHandle = useCallback(
        (id: number) => {
            request(`/review/cluster/${id}`, {}, 'del').then(() => {
                message.success(
                    /* 删除成功 */ locales.pages.semanticIntelligence.clusteringAnalysis.index
                        .deletionSucceeded
                );

                const { createTime, ...other } = filter;
                const { current } = pagination;
                let map: any = {
                    pageNum: tableData.length > 1 ? current : current > 1 ? current - 1 : 1,
                    pageSize: pagination.pageSize,
                };

                if (createTime) {
                    const [startCreateTime, endCreateTime] = createTime;
                    map = {
                        startCreateTime,
                        endCreateTime,
                        ...map,
                    };
                }
                map = {
                    ...other,
                    ...map,
                };
                ajaxTableData(map);
            });
        },
        [locales, ajaxTableData, pagination, filter, request, tableData.length]
    );

    const addHandle = () => {
        linkTo(history, getCurrentRouteName('clusteringAnalysisNew'));
    };

    useEffect(() => {
        const $filterForm = $('.clustering-analysis-index .udesk-qa-ui-common-filter');
        $filterForm.on('click', '.udesk-qa-ui-btn-default', () => {
            saveStorages(defaultStorageValue);
        });

        return () => {
            $filterForm.off('click');
        };
    }, [saveStorages, viewHandle]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 聚类名称 */ locales.pages.semanticIntelligence.clusteringAnalysis.index
                    .clusterName,
                key: 'name',
                dataIndex: 'name',
            },
            {
                title: /* 说话人 */ locales.pages.semanticIntelligence.clusteringAnalysis.index
                    .speaker,
                key: 'speaker',
                dataIndex: 'speaker',
                render: (name, item, index) => {
                    return Udesk.enums.speakerTypes.getName(name);
                },
            },
            {
                title: /* 创建时间 */ locales.pages.semanticIntelligence.clusteringAnalysis.index
                    .creationTime,
                key: 'createTime',
                dataIndex: 'createTime',
            },
            {
                title: /* 状态 */ locales.pages.semanticIntelligence.clusteringAnalysis.index.state,
                key: 'status',
                dataIndex: 'status',
                render: (status, item, index) => {
                    return (
                        <React.Fragment>
                            <Badge
                                status={['', 'default', 'processing', 'success'][status] as any}
                            />
                            <span>{Udesk.enums.clusteringTypes.getName(item, '', 'status')}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                title: /* 操作 */ locales.pages.semanticIntelligence.clusteringAnalysis.index
                    .operation,
                key: 'actions',
                render({ id, status }) {
                    return (
                        <Space>
                            {[
                                <Button type="link" onClick={() => viewHandle(id)}>
                                    {
                                        /* 查看 */ locales.pages.semanticIntelligence
                                            .clusteringAnalysis.index.see
                                    }
                                </Button>,
                                <Button
                                    disabled={status === Udesk.enums.clusteringTypes.haveInHand.id}
                                    type="link"
                                    onClick={() => editHandle(id)}
                                >
                                    {
                                        /* 编辑 */ locales.pages.semanticIntelligence
                                            .clusteringAnalysis.index.edit
                                    }
                                </Button>,
                                <DelBtn onDelete={() => delHandle(id)} locales={locales} />,
                            ].filter((btn, index) => {
                                if (index === 0) {
                                    if (hasFeature?.('semantic:cluster:view')) {
                                        return status === Udesk.enums.clusteringTypes.completed.id;
                                    }
                                    return false;
                                }
                                if (index === 1) {
                                    return hasFeature?.('semantic:cluster:edit');
                                }
                                if (index === 2) {
                                    return hasFeature?.('semantic:cluster:delete');
                                }
                                return true;
                            })}
                        </Space>
                    );
                },
            },
        ];
        return {
            loading,
            columns,
            dataSource: tableData,
            pagination,
            rowSelectionEnhance: {},
        };
    }, [loading, locales, tableData, pagination, viewHandle, editHandle, delHandle, hasFeature]);

    return (
        <div className="udesk-qa-web-page">
            <PageHeader
                className="udesk-qa-web-page-header"
                title={
                    /* 聚类分析 */ locales.pages.semanticIntelligence.clusteringAnalysis.index
                        .clusterAnalysis
                }
                extra={
                    Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:cluster:add')
                        ? [
                              <Button key={'conceptWords'} type="primary" onClick={addHandle}>
                                  {
                                      /* 新建聚类 */ locales.pages.semanticIntelligence
                                          .clusteringAnalysis.index.newCluster
                                  }
                              </Button>,
                          ]
                        : []
                }
            />
            <div className="udesk-qa-web-page-body">
                <div className="udesk-qa-web-page-body-root clustering-analysis-index">
                    <StandardizeTable.RequestTable
                        onRequest={(params) => {
                            const { filter, pagination } = params;
                            const { createTime, ...other } = filter;
                            let map: any = {
                                pageNum: pagination.current,
                                pageSize: pagination.pageSize,
                            };

                            if (createTime) {
                                const [startCreateTime, endCreateTime] = createTime;
                                map = {
                                    startCreateTime,
                                    endCreateTime,
                                    ...map,
                                };
                            }
                            map = {
                                ...other,
                                ...map,
                            };

                            ajaxTableData(map);
                        }}
                        table={tableProps}
                        filter={{
                            fields: [
                                {
                                    type: 'Input',
                                    name: 'name',
                                    label: /* 聚类名称 */ locales.pages.semanticIntelligence
                                        .clusteringAnalysis.index.clusterName,
                                    props: {
                                        placeholder: locales.labels.pleaseEnter,
                                    },
                                },
                                {
                                    type: 'RangePicker',
                                    name: 'createTime',
                                    label: /* 创建时间 */ locales.pages.semanticIntelligence
                                        .clusteringAnalysis.index.creationTime,
                                    props: {
                                        style: {
                                            width: 340,
                                        },
                                        showTime: true,
                                    },
                                },
                                {
                                    type: 'Select',
                                    name: 'speaker',
                                    label: /* 说话人 */ locales.pages.semanticIntelligence
                                        .clusteringAnalysis.index.speaker,
                                    props: {
                                        optionFilterProp: 'label',
                                        showSearch: true,
                                        options: Udesk.enums.speakerTypes.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                        })),
                                        placeholder: locales.labels.pleaseSelect,
                                    },
                                },
                                {
                                    type: 'Select',
                                    name: 'status',
                                    label: /* 聚类状态 */ locales.pages.semanticIntelligence
                                        .clusteringAnalysis.index.clusteringStatus,
                                    props: {
                                        optionFilterProp: 'label',
                                        showSearch: true,
                                        options: Udesk.enums.clusteringTypes.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                        })),
                                        placeholder: locales.labels.pleaseSelect,
                                    },
                                },
                            ],
                            initialValues: filter,
                        }}
                    />
                </div>
            </div>
        </div>
    );
});

class Component extends React.Component {
    privates = {
        storages: defaultStorageValue,
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [/^\/site\/semantic-intelligence\/clustering-analysis\/*/i],
        },
    };

    actions = {
        saveStorages(changedParams: any) {
            (this as any).privates.storages = {
                ...(this as any).privates.storages,
                ...changedParams,
            };
            (this as any).actions.update();
        },
    };
    render() {
        return (
            <Template
                {...this.props}
                storages={this.privates.storages}
                saveStorages={this.actions.saveStorages}
            />
        );
    }
}
export default Udesk.react.udeskify(Component);
