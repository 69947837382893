// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostIntelligentPartnerTaskInfosStatisticsFindAllBody,
    BaseResponseListIntelligentPartnerTaskStatisticsFoundResponse,
} from '../../../types';

/**
 * 获取发布任务报表分页
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/statistics/findAll
 */
export function postIntelligentPartnerTaskInfosStatisticsFindAll(
    data: PostIntelligentPartnerTaskInfosStatisticsFindAllBody
): Promise<BaseResponseListIntelligentPartnerTaskStatisticsFoundResponse>;
export function postIntelligentPartnerTaskInfosStatisticsFindAll(
    data: PostIntelligentPartnerTaskInfosStatisticsFindAllBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerTaskStatisticsFoundResponse>;
export function postIntelligentPartnerTaskInfosStatisticsFindAll(
    data: PostIntelligentPartnerTaskInfosStatisticsFindAllBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerTaskStatisticsFoundResponse> {
    return request<PostIntelligentPartnerTaskInfosStatisticsFindAllBody, never, never>(
        '/intelligentPartnerTaskInfos/statistics/findAll',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerTaskInfosStatisticsFindAll',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/statistics/findAll',
        method: 'post',
    },
];
