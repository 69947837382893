import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Udesk from 'Udesk';
import { Table, Input, Icon, Tag } from 'udesk-ui';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const pageSize = Udesk.config.paging.defaultPageSize;

export default React.memo((props: any) => {
    const {
        data, 
        loading,
        leftPageKeyword: keyword,
        setLeftPageKeyword: setKeyword,
        leftPagePageNum: pageNum,
        setLeftPagePageNum: setPageNum,
        selectedRowKey, 
        setSelectedRowKey, 
    }= props;
    const [total, setTotal] = useState(0);

    const tableDataSource = useMemo(() => {
        return data.filter(item => item.taskName.indexOf(keyword) > -1);
    }, [
        data,
        keyword
    ]);

    const renderCell = useCallback((text, record) => {
        return (
            <div className='table-row-item'>
                <span onClick={() => setSelectedRowKey(record.id)}>{record.taskName}</span>
                <div className="toolkit-button">
                    <Tag color={record.reviewStatus === 1 ? "blue" : "red"}>
                        {record.reviewStatus === 1 ? /* 已审核 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageLeftTable.reviewed : /* 待审核 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageLeftTable.pendingReview}
                    </Tag>
                </div>
            </div>
        );
    }, [setSelectedRowKey]);

    useEffect(() => {
        setPageNum(1);
        setTotal(tableDataSource.length);
    }, [
        setPageNum, 
        tableDataSource
    ]);

    useEffect(() => {
        setSelectedRowKey((key) => key || data[0]?.id);
    }, [
        data, 
        setSelectedRowKey
    ]);

    return (
        <div className='task-center-audit-page-left'>
            <Table
                loading={loading}
                scroll={{
                    y: 'calc(100% - 100px)'
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    columnTitle: (
                        <div className='column-header'>
                            <Input value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder={/* 输入关键词搜索 */UdeskLocales['current'].pages.coach.taskCenter.audit.components.pageLeftTable.enterKeywordSearch} prefix={<Icon type='SearchOutlined' antdIcon={true} />} />
                        </div>
                    ),
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    current: pageNum,
                    pageSize,
                    total,
                    simple : true,
                    onChange: setPageNum,
                    hideOnSinglePage: true,
                }} 
                columns={[]}
                dataSource={tableDataSource.slice((pageNum - 1) * pageSize, pageNum * pageSize)}
            />
        </div>
    );
});
