// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetCommunicateStrategyCategoryByIdParam,
    BaseResponseCommunicateStrategyCategoryFoundResponse,
    PutCommunicateStrategyCategoryByIdParam,
    PutCommunicateStrategyCategoryByIdBody,
    BaseResponseVoid,
    DeleteCommunicateStrategyCategoryByIdParam,
} from '../../../types';

/**
 * 查询沟通策略详情分组-单个
 *
 * @export
 * @tags 沟通策略设置
 * @link [GET] /communicateStrategy/category/{id}
 * @param id
 */
export function getCommunicateStrategyCategoryById(
    options: ApiOptions<GetCommunicateStrategyCategoryByIdParam, never> & {
        segments: GetCommunicateStrategyCategoryByIdParam;
    }
): Promise<BaseResponseCommunicateStrategyCategoryFoundResponse> {
    return request<never, GetCommunicateStrategyCategoryByIdParam, never>(
        '/communicateStrategy/category/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getCommunicateStrategyCategoryById' }
    );
}

/**
 * 更新沟通策略详情分组
 *
 * @export
 * @tags 沟通策略设置
 * @link [PUT] /communicateStrategy/category/{id}
 * @param id
 */
export function putCommunicateStrategyCategoryById(
    data: PutCommunicateStrategyCategoryByIdBody,
    options: ApiOptions<PutCommunicateStrategyCategoryByIdParam, never> & {
        segments: PutCommunicateStrategyCategoryByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutCommunicateStrategyCategoryByIdBody,
        PutCommunicateStrategyCategoryByIdParam,
        never
    >('/communicateStrategy/category/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putCommunicateStrategyCategoryById',
    });
}

/**
 * 删除沟通策略详情分组
 *
 * @export
 * @tags 沟通策略设置
 * @link [DELETE] /communicateStrategy/category/{id}
 * @param id
 */
export function deleteCommunicateStrategyCategoryById(
    options: ApiOptions<DeleteCommunicateStrategyCategoryByIdParam, never> & {
        segments: DeleteCommunicateStrategyCategoryByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteCommunicateStrategyCategoryByIdParam, never>(
        '/communicateStrategy/category/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteCommunicateStrategyCategoryById',
        }
    );
}

export const meta = [
    { tags: ['沟通策略设置'], path: '/communicateStrategy/category/{id}', method: 'get' },
    { tags: ['沟通策略设置'], path: '/communicateStrategy/category/{id}', method: 'put' },
    { tags: ['沟通策略设置'], path: '/communicateStrategy/category/{id}', method: 'delete' },
];
