// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerTaskFinishRecordsShareLogByShareIdDetailParam,
    BaseResponseIntelligentPartnerTaskFinishRecordShareLogFoundResponse,
} from '../../../../types';

/**
 * 分享记录查看
 *
 * @export
 * @tags 智能陪练-历史学习数据
 * @link [GET] /intelligentPartnerTaskFinishRecords/shareLog/{shareId}/detail
 * @param shareId
 */
export function getIntelligentPartnerTaskFinishRecordsShareLogByShareIdDetail(
    options: ApiOptions<
        GetIntelligentPartnerTaskFinishRecordsShareLogByShareIdDetailParam,
        never
    > & { segments: GetIntelligentPartnerTaskFinishRecordsShareLogByShareIdDetailParam }
): Promise<BaseResponseIntelligentPartnerTaskFinishRecordShareLogFoundResponse> {
    return request<
        never,
        GetIntelligentPartnerTaskFinishRecordsShareLogByShareIdDetailParam,
        never
    >('/intelligentPartnerTaskFinishRecords/shareLog/{shareId}/detail', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerTaskFinishRecordsShareLogByShareIdDetail',
    });
}

export const meta = [
    {
        tags: ['智能陪练-历史学习数据'],
        path: '/intelligentPartnerTaskFinishRecords/shareLog/{shareId}/detail',
        method: 'get',
    },
];
