const isAuth = (pathname) => {
    return pathname === '/auth/login';
};
const isOauth = (pathname) => {
    return pathname.includes('/oauth/authenticate');
};
const isLogin = (pathname) => {
    return isAuth(pathname) || isOauth(pathname);
};

const getOauthSubdomain = () => localStorage.getItem('oauthSubdomain');
export const setOauthSubdomain = (subdomain) => localStorage.setItem('oauthSubdomain', subdomain);

/**
 * 自动跳转到正确的登录页面
 */
export const correctLoginPageRedirect = () => {
    if (getOauthSubdomain()) {
        window.location.href = `/${getOauthSubdomain()}/oauth/authenticate${window.location.search}`;
        return;
    }

    if (!getOauthSubdomain()) {
        window.location.href = `/auth/login${window.location.search}`;
        return;
    }
};

export const loginRedirect = () => {
    if (!isLogin(window.location.pathname)) {
        const referUrl = encodeURIComponent(window.location.pathname + window.location.search);
        const oauthSubdomain = localStorage.getItem('oauthSubdomain');
        console.log('oauthSubdomain:: ', oauthSubdomain);

        window.location.href = oauthSubdomain ? `/${oauthSubdomain}/oauth/authenticate?refer=${referUrl}` : `/auth/login?refer=${referUrl}`;
        return;
    }
};