import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Row, Col, Space, Tabs, Badge } from 'udesk-ui';
import './index.scss';
import UseMessage from './common/use-message';
import CallDynamic from './common/call-dynamic';
import TodoList from './common/todo-list';
import { getCallState } from 'src/api/callState';
import moment from 'moment';
import {
    BaseResponseListCallStateFoundResponse,
    BaseResponseListToDoFoundResponse,
    GetCallStateQuery,
    GetToDosQuery,
    ToDoFoundResponse,
} from 'src/api/types';
import { getToDos } from 'src/api/toDos';
import { deleteToDosById } from 'src/api/toDos/{id}';

const locales = Locales['current'];
const { TabPane } = Tabs;

const Template = React.memo((props: any) => {
    const { sdkOptions } = props;
    const [activeKey, setActiveKey] = useState<string>(props.location?.state?.activeKey ?? '1');
    const [callStatusData, setCallStatusData] = useState<any[]>([]);
    const [todoData, setTodoData] = useState<ToDoFoundResponse[]>([]);
    const [page, setPage] = useState(props.toDoPage);
    const [callPage, setCallPage] = useState(props.callPage);
    const [todoListLoading, setTodoListLoading] = useState(false);
    const [callListLoading, setCallListLoading] = useState(false);
    const [currentTodoListType, setCurrentTodoListType] = useState(
        String(Udesk.enums.todoListType.later.id)
    );
    const [currentTodoListTabs, setCurrentTodoListTabs] = useState(Udesk.enums.todoListType);

    const listDataChange = (data) => {
        let middle = {};
        let newArr: any = [];
        data.forEach((item) => {
            item.date = item.callTime;
            item.callTime = moment(item.callTime).format('YYYY-MM-DD');
            if (!middle[item.callTime]) {
                middle[item.callTime] = item.callTime;
                newArr.push({
                    callTime: item.callTime,
                    children: [item],
                });
            } else {
                newArr.forEach((record) => {
                    if (middle[record.callTime] === item.callTime) {
                        record.children.push(item);
                    }
                });
            }
        });
        return newArr;
    };
    const handlerTabsToggle = (key) => {
        setActiveKey(key);
        getCallDynamicData(1, callPage.pageSize, key);
    };
    const handlerTodoListTabsToggle = (key) => {
        setCurrentTodoListType(key);
        todoListData(page.current, page.pageSize, key);
    };
    const getCallDynamicData = (pageNum, pageSize, key?) => {
        setCallListLoading(true);
        const params: GetCallStateQuery = {
            pageNum: pageNum,
            pageSize: pageSize,
            status: key || parseInt(activeKey, 10),
        };
        getCallState({ params })
            .then((res: BaseResponseListCallStateFoundResponse) => {
                let result = listDataChange(res.data ?? []);

                setCallStatusData(result);
                setCallPage({
                    current: res.paging?.pageNum ?? 1,
                    pageSize: res.paging?.pageSize ?? 10,
                    total: res.paging?.total ?? 0,
                });
            })
            .finally(() => {
                setCallListLoading(false);
            });
    };
    const todoListData = (pageNum, pageSize, type) => {
        const params: GetToDosQuery = {
            pageNum,
            pageSize,
            type: Number(type),
        };
        setTodoListLoading(true);
        getToDos({ params })
            .then((res: BaseResponseListToDoFoundResponse) => {
                setTodoData(res.data ?? []);
                setPage({
                    current: res.paging?.pageNum ?? 1,
                    pageSize: res.paging?.pageSize ?? 10,
                    total: res.paging?.total ?? 0,
                });
                if (pageNum > 1 && res.paging?.total && !res.data?.length) {
                    todoListData(pageNum - 1, pageSize, type);
                }
            })
            .finally(() => {
                setTodoListLoading(false);
            });
    };
    const handlerPageChange = (current, pageSize) => {
        setPage({
            ...page,
            current,
            pageSize,
        });
        todoListData(current, pageSize, currentTodoListType);
    };
    const handlerCallPageChange = (current, pageSize) => {
        setCallPage({
            ...callPage,
            current,
            pageSize,
        });
        getCallDynamicData(current, pageSize);
    };
    const noticeChecked = (todoId) => {
        // 已和产品沟通：会话动态 和 待办事项 都统计一下用户查看状态
        let url = Udesk.business.apiPath.concatApiPath(`toDos/view/${todoId}`, sdkOptions);
        Udesk.ajax.get(url).then(
            (resp) => {
                // 响应为空
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const handlerCallDetail = (callId, todoId) => {
        if (todoId) {
            noticeChecked(todoId);
        }
        const routeOptions = {
            history: props.history,
            routeName: 'gongCallDetail',
            pathParams: { callId },
            state: {
                type: 'home',
                activeKey,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    // 忽略
    const handlerIgnore = (id) => {
        deleteToDosById({
            segments: { id },
            successMsg: locales.pages.gong.homePage.index.operationSucceeded,
        }).then((res) => {
            todoListData(page.current, page.pageSize, currentTodoListType);
        });
    };
    useEffect(() => {
        let url = Udesk.business.apiPath.concatApiPath(`toDos/count`, sdkOptions);
        Udesk.ajax.get(url).then(
            (resp) => {
                resp.data = resp.data || [];
                let hasCountTodoListTabs = Udesk.enums.todoListType.map((item) => {
                    let newItem = { ...item };
                    let foundItem = resp.data.find((i) => i.type === item.id);
                    newItem.count = foundItem ? foundItem.count : 0;
                    return newItem;
                });
                setCurrentTodoListTabs(hasCountTodoListTabs);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [sdkOptions, currentTodoListType]);

    useEffect(() => {
        todoListData(page.current, page.pageSize, currentTodoListType);
        getCallDynamicData(callPage.current, callPage.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        props.onToDoPageChange?.(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
    useEffect(() => {
        props.onCallPageChange?.(callPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callPage]);
    return (
        <Page
            pageBodyClassName="pages-gong-home-page-container"
            title={locales.menu.tasks.homePage.text}
            padding={true}
        >
            <div className="use-message">
                <UseMessage biUser={sdkOptions.props.biUser} />
            </div>
            <Row className="pages-home-page-content-style" style={{ display: 'flex' }}>
                <Col style={{ flex: 5 }}>
                    <Space direction="vertical" style={{ width: '100%', padding: 16 }}>
                        <h3 className="level-title">
                            {locales.pages.gong.homePage.index.sessionDynamics}
                        </h3>
                        <Tabs
                            activeKey={activeKey}
                            className="pages-home-page-content-style-tabs"
                            onChange={handlerTabsToggle}
                        >
                            <TabPane tab={locales.pages.gong.homePage.index.my} key="1"></TabPane>
                            <TabPane
                                tab={locales.pages.gong.homePage.index.department}
                                key="2"
                            ></TabPane>
                            <TabPane
                                tab={locales.pages.gong.homePage.index.company}
                                key="3"
                            ></TabPane>
                        </Tabs>
                        <CallDynamic
                            loading={callListLoading}
                            callStatusData={callStatusData}
                            handlerCallDetail={handlerCallDetail}
                            onPageChange={handlerCallPageChange}
                            page={callPage}
                        />
                    </Space>
                </Col>
                <Col style={{ flex: 4, overflow: 'hidden' }}>
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                            padding: 16,
                            height: '100%',
                            borderLeft: '8px solid #eee',
                        }}
                    >
                        <Space>
                            <h3 className="level-title">
                                {locales.pages.gong.homePage.index.toDoList}
                            </h3>
                        </Space>
                        <Tabs
                            activeKey={currentTodoListType}
                            className="pages-home-page-content-style-tabs"
                            onChange={handlerTodoListTabsToggle}
                        >
                            {currentTodoListTabs.map((item) => (
                                <TabPane
                                    tab={
                                        <Badge count={item.count} offset={[10, 0]}>
                                            {item.name}
                                        </Badge>
                                    }
                                    key={item.id}
                                />
                            ))}
                        </Tabs>
                        <TodoList
                            {...props}
                            loading={todoListLoading}
                            handlerIgnore={handlerIgnore}
                            handlerPageChange={handlerPageChange}
                            page={page}
                            todoData={todoData}
                            handlerCallDetail={handlerCallDetail}
                        />
                    </Space>
                </Col>
            </Row>
        </Page>
    );
});

class Component extends React.Component {
    privates = {
        storages: {
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            callPage: {
                current: 1,
                pageSize: 20,
                total: 0,
            },
        },
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [/^\/site\/call-analysis\/call-detail\/\d+/i],
        },
    };
    actions = {
        onToDoPageChange(newPage) {
            (this as any).privates.storages.page = newPage;
        },
        onCallPageChange(newPage) {
            (this as any).privates.storages.callPage = newPage;
        },
    };

    render() {
        return (
            <Template
                {...this.props}
                toDoPage={this.privates.storages.page}
                callPage={this.privates.storages.callPage}
                onCallPageChange={this.actions.onCallPageChange}
                onToDoPageChange={this.actions.onToDoPageChange}
            />
        );
    }
}
export default Udesk.react.udeskify(Component);
