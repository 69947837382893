import Udesk from 'Udesk';
import React from 'react';
import AdminUserIndexRoute from './route';
import UdeskPagination from 'Component/common/udesk-pagination';
import ReactTable from 'udesk-react/src/components/react-table';
import { NavLink } from 'react-router-dom';
import { PageHeader } from 'udesk-ui';
import { isQaSubApp } from 'Udesk/system/subApp';

export default class AdminUserIndexTemplate extends AdminUserIndexRoute {
    render() {
        const { actions, locales, /* props, */ state, privates } = this;
        const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
        return (
            <React.Fragment>
                {/* <div className="page admin-user-index"> */}
                <div className='udesk-qa-web-page'>
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={locales.pages.admin.user.index.name}
                    />
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root admin-user-index' style={{padding: 16}}>
                            <div className="page-content">
                                <div className="x_panel clearfix">
                                    <div className="x_content clearfix">
                                        <If condition={hasFeature && hasFeature('sys:user:list')}>
                                            <div className="ember-table-header-bar ember-table-header-bar-striped clearfix">
                                                <div className="pull-left">{locales.pages.admin.user.index.existingUsers}</div>
                                                <If condition={hasFeature && hasFeature('sys:user:add')}>
                                                    <div className="pull-right">
                                                        <NavLink to={isQaSubApp() ? "/site/admin/user/new" : '/conversation-analysis/admin/user/new'} className={'action-link'}>
                                                            <i
                                                                className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tianjia1"
                                                                title={locales.labels.create}></i>
                                                        </NavLink>
                                                    </div>
                                                </If>
                                            </div>
                                            <ReactTable columns={state.columns} items={privates.model.users.data} />
                                            {/* <ReactPager
                                                pageNum={privates.model.users.paging.pageNum}
                                                pageSize={privates.model.users.paging.pageSize}
                                                total={privates.model.users.paging.total}
                                                languageCode={props.sdkOptions.props.language}
                                                pageChanged={actions.pageChanged} /> */}
                                            <UdeskPagination
                                                current={privates.model.users.paging.pageNum}
                                                pageSize={privates.model.users.paging.pageSize}
                                                total={privates.model.users.paging.total}
                                                onChange={actions.pageChanged}
                                            />
                                        </If>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
