// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetSupervisionInspectionRecheckByCallIdParam,
    BaseResponseSupervisionInspectionResult,
} from '../../../types';

/**
 * recheck
 *
 * @export
 * @tags supervision-category-inspection-result-controller
 * @link [GET] /supervisionInspection/recheck/{callId}
 * @param callId
 */
export function getSupervisionInspectionRecheckByCallId(
    options: ApiOptions<GetSupervisionInspectionRecheckByCallIdParam, never> & {
        segments: GetSupervisionInspectionRecheckByCallIdParam;
    }
): Promise<BaseResponseSupervisionInspectionResult> {
    return request<never, GetSupervisionInspectionRecheckByCallIdParam, never>(
        '/supervisionInspection/recheck/{callId}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getSupervisionInspectionRecheckByCallId',
        }
    );
}

export const meta = [
    {
        tags: ['supervision-category-inspection-result-controller'],
        path: '/supervisionInspection/recheck/{callId}',
        method: 'get',
    },
];
