import Udesk from 'Udesk';
import React from 'react';
import TaskCardComponent from './component';
import { Switch, Progress, Button, Icon, Spin, Space } from 'udesk-ui';
// import { InView } from 'react-intersection-observer';
import './style.scss';
import { isOperator } from 'src/util/isOperator';

// const cardLoadingStyle = {
//     height: '100%',
//     width: '100%',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
// };

const columnHeightEmpty = [1, 2, 3, 4, 5];

export default class TaskCardTemplate extends TaskCardComponent {
    render() {
        let { actions, locales, state } = this;
        let {
            task,
            fulfilled,
            isWechatQa,
            isCollection,
            simple = false /*  InViewRef = null, hadRendered = true */,
        } = this.props;
        let { columnHeight, loading, collectType } = this.privates;

        const isTextDialogue = Udesk.business.task.isTextDialogue(task.inspectDataSource);
        const isTaobao = Udesk.business.task.isTaobao(task.inspectDataSource);
        return (
            <div className='task-card-box' /* ref={InViewRef} */>
                <div className='task-card'>
                    {/* <If condition={hadRendered}> */}
                    {!isWechatQa && (
                        <div
                            className={`task-card-type ${
                                isTextDialogue || isTaobao ? 'task-card-type-im' : 'task-card-type-call'
                            }`}
                        >
                            {
                                locales.pages.tasks.cards[
                                    isTextDialogue
                                        ? 'dialogue'
                                        : isTaobao
                                            ? 'taobao'
                                            : task.inspectDataSource === 5
                                                ? 'wecom'
                                                : task.inspectDataSource === Udesk.enums.inspectDataSources.ticket.id
                                                    ? 'ticket'
                                                    : 'call'
                                ]
                            }
                        </div>
                    )}

                    <div className='task-card-info'>
                        <Space style={{ marginBottom: 8, width: '100%' }}>
                            <div className='task-card-info-label' title={task.name}>
                                {`${isOperator() ? task.id + ' ' : ''}${task.name}`}
                            </div>
                            <Button
                                tooltip={
                                    collectType
                                        ? locales.components.taskCard.template.cancelCollection
                                        : locales.components.taskCard.template.collection
                                }
                                type='text'
                                size='small'
                                icon={
                                    <Icon
                                        style={{ color: collectType ? '#FFCF5B' : 'rgba(0, 0, 0, 0.25)' }}
                                        type='StarFilled'
                                        antdIcon={true}
                                    />
                                }
                                onClick={actions.collect.params(task)}
                            />
                        </Space>
                        <div className='task-card-info-remark' title={task.remark}>
                            {task.remark}
                        </div>
                    </div>
                    <If condition={!isWechatQa && !isCollection && !simple}>
                        <If condition={!fulfilled /* || !hadRendered */}>
                            {/* 未返回结果 */}
                            <div className='task-card-chart'>
                                <Spin spinning={true}>
                                    <div className='task-card-chart-item'>
                                        <div className='task-card-chart-item-left'>
                                            <div className='task-card-chart-count'>
                                                <div className='task-card-chart-main-count empty-task-card'></div>
                                            </div>
                                            <div className='task-card-chart-item-title empty-task-card'></div>
                                        </div>
                                        <div className='task-card-chart-item-right empty-task-card'></div>
                                    </div>
                                    <div className='task-card-chart-item  middle-chart-item'>
                                        <div className='task-card-chart-item-left'>
                                            <div className='task-card-chart-count'>
                                                <div className='task-card-chart-main-count empty-task-card'></div>
                                            </div>
                                            <div className='task-card-chart-item-title empty-task-card'></div>
                                        </div>
                                        <div className='task-card-chart-item-right empty-task-card'></div>
                                    </div>
                                </Spin>
                            </div>
                        </If>
                        <If condition={fulfilled /* && hadRendered */}>
                            {/* 已返回结果 */}
                            <If condition={'chart' in task && Object.keys(task.chart).length !== 0}>
                                {/* else 图标参数不为空 */}
                                <div className='task-card-chart'>
                                    <div className='task-card-chart-item'>
                                        <div className='task-card-chart-item-left'>
                                            <div className='task-card-chart-count'>
                                                <div className='task-card-chart-main-count'>
                                                    {task.chart.health + '%'}
                                                </div>
                                                <div
                                                    className='task-card-chart-growth-count'
                                                    style={{
                                                        color:
                                                            task.chart.growthHealth > 0
                                                                ? 'rgba(17,168,72,1)'
                                                                : 'rgba(251,81,39,1)',
                                                    }}
                                                >
                                                    {task.chart.growthHealth > 0 ? (
                                                        <Icon
                                                            style={{ marginRight: 4, fontSize: 10 }}
                                                            type='ic-arrow-up-s-shangsanjiao'
                                                        />
                                                    ) : (
                                                        <Icon
                                                            style={{ marginRight: 4, fontSize: 10 }}
                                                            type='ic-arrow-down-s-xiasanjiao'
                                                        />
                                                    )}
                                                    {task.chart.growthHealth + '%'}
                                                </div>
                                            </div>
                                            <div className='task-card-chart-item-title'>
                                                {locales.pages.tasks.cards.accuracy}
                                            </div>
                                        </div>
                                        <div className='task-card-chart-item-right'>
                                            <Progress
                                                className='task-card-chart-progress'
                                                type='circle'
                                                strokeLinecap='square'
                                                percent={task.chart.health}
                                                showInfo={false}
                                                strokeColor='rgba(17,168,72,1)'
                                                width={48}
                                                strokeWidth={17}
                                            />
                                        </div>
                                    </div>
                                    <div className='task-card-chart-item  middle-chart-item'>
                                        <div className='task-card-chart-item-left'>
                                            <div className='task-card-chart-count'>
                                                <div className='task-card-chart-main-count'>
                                                    {task.chart.totalConversation}
                                                </div>
                                                <div
                                                    className='task-card-chart-growth-count'
                                                    style={{
                                                        color:
                                                            task.chart.growthConversation > 0
                                                                ? 'rgba(17,168,72,1)'
                                                                : 'rgba(251,81,39,1)',
                                                    }}
                                                >
                                                    {task.chart.growthConversation > 0 ? (
                                                        <Icon
                                                            style={{ marginRight: 4, fontSize: 10 }}
                                                            type='ic-arrow-up-s-shangsanjiao'
                                                        />
                                                    ) : (
                                                        <Icon
                                                            style={{ marginRight: 4, fontSize: 10 }}
                                                            type='ic-arrow-down-s-xiasanjiao'
                                                        />
                                                    )}
                                                    {task.chart.growthConversation}
                                                </div>
                                            </div>
                                            <div className='task-card-chart-item-title'>
                                                {locales.pages.tasks.cards.sessionsTotal}
                                            </div>
                                        </div>
                                        <div className='task-card-chart-item-right'>
                                            {columnHeight.map((item, index) => {
                                                if (!item) {
                                                    return (
                                                        <div
                                                            className='task-card-chart-item-column'
                                                            style={{
                                                                height: 4,
                                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                            }}
                                                            key={task.chart.taskId + index}
                                                        ></div>
                                                    );
                                                }
                                                return (
                                                    <div
                                                        className='task-card-chart-item-column'
                                                        style={{ height: item }}
                                                        key={task.chart.taskId + index}
                                                    ></div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </If>
                            <If condition={!('chart' in task) || Object.keys(task.chart).length === 0}>
                                {/* 图标参数为空 */}
                                <div className='task-card-chart'>
                                    <div className='task-card-chart-item'>
                                        <div className='task-card-chart-item-left'>
                                            <div className='task-card-chart-count'>
                                                <div className='task-card-chart-main-count'>
                                                    {locales.pages.tasks.cards.none}
                                                </div>
                                            </div>
                                            <div className='task-card-chart-item-title'>
                                                {locales.pages.tasks.cards.health}
                                            </div>
                                        </div>
                                        <div className='task-card-chart-item-right'>
                                            <Progress
                                                className='task-card-chart-progress'
                                                type='circle'
                                                strokeLinecap='square'
                                                percent={0}
                                                showInfo={false}
                                                strokeColor='rgba(17,168,72,1)'
                                                width={48}
                                                strokeWidth={17}
                                            />
                                        </div>
                                    </div>
                                    <div className='task-card-chart-item  middle-chart-item'>
                                        <div className='task-card-chart-item-left'>
                                            <div className='task-card-chart-count'>
                                                <div className='task-card-chart-main-count'>
                                                    {locales.pages.tasks.cards.none}
                                                </div>
                                            </div>
                                            <div className='task-card-chart-item-title'>
                                                {locales.pages.tasks.cards.sessionsTotal}
                                            </div>
                                        </div>
                                        <div className='task-card-chart-item-right'>
                                            {columnHeightEmpty.map((item, index) => {
                                                return (
                                                    <div
                                                        className='task-card-chart-item-column'
                                                        style={{
                                                            height: 4,
                                                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                        }}
                                                        key={index}
                                                    ></div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </If>
                        </If>
                    </If>
                    <div className='task-card-btn'>
                        <Space className='task-card-btn-left' wrap>
                            <Button onClick={actions.turnToTaskManage}>{locales.labels.manage}</Button>
                            {!isCollection && !simple && (
                                <Button onClick={actions.openSaveAsModel} loading={loading}>
                                    {locales.labels.saveAs}
                                </Button>
                            )}
                            {simple && (task.processStatus === -1 || task.processStatus == null || isCollection) && (
                                <React.Fragment>
                                    <Button onClick={actions.turnToQaList}>
                                        {locales.labels.goToQaList}
                                    </Button>
                                    {!isCollection && !isWechatQa && (
                                        <Button onClick={actions.turnToCheckList}>
                                            {locales.labels.goToCheck}
                                        </Button>
                                    )}
                                </React.Fragment>
                            )}
                        </Space>
                        {!simple && (
                            <div className='task-card-btn-right'>
                                <div className='task-card-btn-right-des'>
                                    {state.taskStatus
                                        ? locales.pages.tasks.cards.enable
                                        : locales.pages.tasks.cards.disable}
                                </div>
                                <div style={{ lineHeight: '32px' }}>
                                    <Switch
                                        checked={state.taskStatus}
                                        onChange={actions.upTaskState}
                                        disabled={!Udesk.data.init.user.hasFeature('task:onOff')}
                                        style={{ marginLeft: 4 }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {/* </If> */}
                    {/* <If condition={!hadRendered}>
                        <Spin spinning={true} style={cardLoadingStyle} />
                    </If> */}
                </div>
            </div>
        );
    }
}
//性能优化尝试
// const CardInner = Udesk.react.udeskify(TaskCardTemplate);
// export default class Card extends React.Component {
//     privates={
//         hadRendered: false,
//     }
//     render() {
//         return (
//             // <CardInner {...this.props} />
//             <React.Fragment>
//                 {this.props.cardIndex > 20 ? (
//                     <InView>
//                         {({ inView, ref, entry }) => {
//                             if (inView && !this.privates.hadRendered) {
//                                 this.privates.hadRendered = true;
//                                 this.actions.update();
//                             }
//                             return <CardInner {...this.props} InViewRef={ref} hadRendered={this.privates.hadRendered} />;
//                         }}
//                     </InView>
//                 ) : (
//                     <CardInner {...this.props} />
//                 )}
//             </React.Fragment>
//         );
//     }
// }
