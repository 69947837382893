import Udesk from "Udesk";
import React from "react";
import CheckPointOperatorListGatherComponent from "./component";
import AutoComplete from 'udesk-react/src/components/auto-complete';
import { Select, Input, Button, TreeSelect, Radio, InputNumber, Modal, Table, Tabs, Popover, Icon } from 'udesk-ui';
import "./style.scss";

export default class CheckPointOperatorListGatherTemplate extends CheckPointOperatorListGatherComponent {
    render() {
        let { props, actions, locales, privates, state } = this;
        let { operatorItem } = privates;

        const operations = <Input.Search
            size='small'
            placeholder={locales.labels.searchPlaceHolder}
            onClick={actions.associateSearchChange}
            style={{ width: 146 }}
            onSearch={actions.setAssociateList}
            onChange={actions.associateListSearchChange}
            onBlur={actions.associateInputFocus}
        />;

        return (
            <div className="check-point-operator-list-gather-component" id={props.key}>
                <div>
                    <Select
                        // getPopupContainer={() => document.getElementById(props.key)}
                        disabled
                        value={props.conditionItem.operatorList[0].type ? props.conditionItem.operatorList[0].type : props.operatorTypes[0] ? props.operatorTypes[0].id : ''}
                        className="operator-list-item-type"
                        onChange={actions.changeOperatorItemType.params(privates.operatorItem)}
                    >
                        <For each="item" index="index" of={props.operatorTypes}>
                            <Select.Option key={item.id} value={item.id} title={item.name}>{item.name}</Select.Option>
                        </For>
                    </Select>
                </div>
                {/* 10、语义标签 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.semanticTags.id}>
                    <div className="operator-list-item-semantic-tags-container">
                        <div className="operator-list-item-semantic-tags-search-container">
                            <Input
                                // type="text"
                                disabled
                                className="operator-list-item-semantic-tags-sentence"
                                value={operatorItem.operatorList[0].semanticSentence}
                                onChange={actions.changeInputValue.params(operatorItem, "semanticSentence")}
                                placeholder={locales.components.operatorListGather.pleaseEnterSemanticSentence}
                            />
                            <Button
                                // type="button"
                                disabled
                                className="operator-list-item-button"
                                onClick={actions.changeSemanticTagsModalVisible.params(operatorItem)}
                            >
                                {locales.components.operatorListGather.querySemanticTags}
                            </Button>
                        </div>
                    </div>
                    <div className="operator-list-item-semantic-tags-result-container">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span title={operatorItem.operatorList[0].semanticTag && operatorItem.operatorList[0].semanticTag.semanticName} className="operator-list-item-semantic-tags-result-tag">
                                {locales.components.operatorListGather.semanticTags}
                                {locales.labels.colon}
                                {operatorItem.operatorList[0].semanticTag && operatorItem.operatorList[0].semanticTag.semanticName}
                            </span>
                            <span title={operatorItem.operatorList[0].semanticTag && operatorItem.operatorList[0].semanticTag.sentence} className="operator-list-item-semantic-tags-result-sentence">
                                {locales.components.operatorListGather.standardSentence}
                                {locales.labels.colon}
                                {operatorItem.operatorList[0].semanticTag && operatorItem.operatorList[0].semanticTag.sentence}
                            </span>
                        </div>
                    </div>
                </If>
                {/* 20、概念词 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.conceptWord.id}>
                    <div className="operator-list-item-entity-info-container">
                        <div className="operator-list-item-entity-operator-container">
                            <Select
                                disabled
                                value={operatorItem.operatorList[0].param.compareType}
                                className="operator-list-item-entity-operator"
                                onChange={actions.changeOperatorItem.params(operatorItem, "compareType")}
                            >
                                <For each="item" index="index" of={Udesk.enums.conceptWordOperators}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                        </div>
                        <div className="operator-list-item-entity-field-container" style={{ minWidth: '150px', height: '32px', lineHeight: '24px' }}>
                            {/* <Select
                                showSearch
                                value={operatorItem.operatorList[0].param.conceptWord?operatorItem.operatorList[0].param.conceptWord.id:null}
                                placeholder={locales.components.operatorListGather.conceptWordholder}
                                style={{ width: 128 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={actions.handleSelectSearch.params("conceptWord")}
                                onChange={actions.changeOperatorItem.params(operatorItem, "conceptWord")}
                                notFoundContent={null}
                            >
                                <For each="item" index="index" of={privates.selectOptionList.conceptWord}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select> */}
                            <AutoComplete
                                disabled
                                url={(props.conceptWordUrl && props.conceptWordUrl !== "") ? props.conceptWordUrl : Udesk.business.apiPath.concatApiPath(`/conceptWords/findAll`, props.sdkOptions)}
                                value={operatorItem.operatorList[0].param.conceptWord}
                                method={"POST"}
                                queryParamName={"keyword"}
                                onChanged={actions.conceptWordChanged.params(operatorItem)}
                                enableEmptySearch={true}
                                placeholder={locales.components.operatorListGather.conceptWordholder}
                            />
                        </div>
                    </div>
                </If>
                {/* 3、正则表达式 */}
                {/* <If condition={operatorItem.type === Udesk.enums.operatorTypes.regular.id}>
                    <input
                        type="text"
                        disabled
                        className="operator-list-item-regular"
                        value={operatorItem.operatorList[0].keyword}
                        onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                        placeholder={locales.components.operatorListGather.pleaseEnter}
                    />
                    <div className="operator-list-item-test-container-box">
                        <button
                            type="button"
                            className="btn btn-sm btn-default operator-list-item-button"
                        >
                            {locales.components.operatorListGather.testRegular}
                        </button>
                        <div className="operator-list-item-test-container">
                            <input
                                type="text"
                                className="form-control"
                                value={operatorItem.operatorList[0]._testText}
                                onChange={actions.changeInputValue.params(operatorItem, "_testText")}
                                onFocus={actions.cleanTestResult.params(operatorItem)}
                            />
                            <span
                                className="operator-list-item-test-container-action pull-left"
                                onClick={actions.regularTest.params(operatorItem)}
                            >
                                {locales.components.operatorListGather.test}
                            </span>
                            <span className="pull-right">{operatorItem._testResult}</span>
                        </div>
                    </div>
                </If> */}
                {/* 11、关键词匹配（严格） */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.keywordStrict.id}>
                    <div>
                        <Select
                            disabled
                            value={operatorItem.operatorList[0].operator}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, "operator")}
                        >
                            <For each="item" index="index" of={Udesk.enums.keywordOperators}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                        <Input
                            // type="text"
                            disabled
                            className="operator-list-item-keyword"
                            value={operatorItem.operatorList[0].keyword}
                            onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                        />
                    </div>
                </If>
                {/* 1、关键词匹配
                    <If condition={operatorItem.type === Udesk.enums.operatorTypes.keyword.id}>
                        <Select
                            disabled
                            value={operatorItem.operatorList[0].operator}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, "operator")}
                        >
                            <For each="item" index="index" of={Udesk.enums.keywordOperators}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                         </Select>
                        <input
                            type="text"
                            disabled
                            className="operator-list-item-keyword"
                            value={operatorItem.operatorList[0].keyword}
                            onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                        />
                    </If> */}
                {/* 17、文法规则
                    <If condition={operatorItem.type === Udesk.enums.operatorTypes.grammar.id}>
                        <textarea
                            disabled
                            className="operator-list-item-semantics"
                            rows={3}
                            value={operatorItem.operatorList[0].keyword}
                            onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                        />
                        <div className="operator-list-item-test-container-box">
                            <button type="button" className="btn btn-sm btn-default operator-list-item-button">
                                {locales.components.operatorListGather.testGrammar}
                            </button>
                            <div className="operator-list-item-test-container">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={operatorItem.operatorList[0]._testText}
                                    onChange={actions.changeInputValue.params(operatorItem, "_testText")}
                                    onFocus={actions.cleanTestResult.params(operatorItem)}
                                />
                                <span
                                    className="operator-list-item-test-container-action pull-left"
                                    onClick={actions.grammarTest.params(operatorItem)}
                                >
                                    {locales.components.operatorListGather.test}
                                </span>
                                <span className="pull-right">{operatorItem._testResult}</span>
                            </div>
                        </div>
                    </If> */}
                {/* 一问一答 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id}>
                    <div>
                        <TreeSelect
                            disabled
                            value={operatorItem.operatorList[0].param.interactiveCategoryId}
                            className="operator-list-item-keyword"
                            onChange={actions.changeOperatorItem.params(operatorItem, "interactiveCategory")}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={privates.treeSelectOptionList}
                            treeDefaultExpandAll
                        >
                        </TreeSelect>
                        <Radio.Group
                            disabled
                            style={{ marginLeft: '16px' }}
                            onChange={actions.changeOperatorItem.params(operatorItem, "interactiveRule")}
                            value={operatorItem.operatorList[0].param.interactiveRule}
                        >
                            {
                                Udesk.enums.interactiveRules.filter(item => {
                                    return item.id !== 1;
                                }).map(item => {
                                    return (
                                        <Radio key={item.id} value={item.id}>{item.name}</Radio>
                                    );
                                })
                            }
                        </Radio.Group>
                    </div>
                </If>
                {/* 要素问答 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.element.id}>
                    <div className="operator-list-item-entity-info-container">
                        <div className="operator-list-item-entity-operator-container">
                            <Select
                                disabled
                                value={operatorItem.operatorList[0].param.hit}
                                className="operator-list-item-entity-operator"
                                onChange={actions.changeOperatorItem.params(operatorItem, "compareType")}
                            >
                                <For each="item" index="index" of={Udesk.enums.inspectFortorsType}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                        </div>
                        <div className="operator-list-item-semantic-tags-search-container">
                            <Select
                                value={operatorItem.operatorList[0].param.mulElementId}
                                placeholder={locales.components.operatorListGather.elementHolder}
                                style={{ width: 200 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onChange={actions.handleSelectChange.params(operatorItem, "mulElementId")}
                                notFoundContent={null}
                                disabled
                            >
                                <For each="item" index="index" of={privates.selectOptionList.element}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                        </div>
                    </div>
                </If>
                {/* 9、抢话检测 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.grabDetection.id}>
                    <div className="operator-list-item-grab-detection-box">
                        {locales.components.operatorListGather.speechGrabbingIntervalTip}
                        <InputNumber
                            disabled
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].grabIntervalTime}
                            onChange={actions.changeInputValue.params(operatorItem, "grabIntervalTime")}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateGrabInterval.params(
                                operatorItem,
                                "grabIntervalTime",
                            )}
                        />
                        {locales.components.operatorListGather.msUnit}
                        <span style={{ width: 8, display: 'inline-block' }}></span>
                        {locales.components.operatorListGather.speechLengthTip}
                        <InputNumber
                            disabled
                            min={0}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].grabMaxSpeakTime}
                            onChange={actions.changeInputValue.params(operatorItem, "grabMaxSpeakTime")}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateGrabLength.params(
                                operatorItem,
                                "grabMaxSpeakTime",
                                operatorItem.operatorList[0].grabMaxSpeakTime
                            )}
                        />
                        {locales.components.operatorListGather.msUnit}
                    </div>
                </If>
                {/* 7、静默 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.dialogue.id}>
                    <div>
                        <Select
                            disabled
                            value={operatorItem.operatorList[0].applyScope}
                            className="operator-list-item-entity-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, "applyScope")}
                        >
                            <For each="item" index="index" of={Udesk.enums.dialogueApplyScopes}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                    </div>
                    <div className="operator-list-item-interval-second-box">
                        <span className="operator-list-item-interval-second-tip">
                            {locales.components.operatorListGather.intervalSecond}
                        </span>
                        <InputNumber
                            disabled
                            min={0}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].intervalSecond}
                            onChange={actions.changeInputValue.params(operatorItem, "intervalSecond")}
                            onBlur={actions.validateMinValue.params(
                                operatorItem,
                                "intervalSecond",
                                operatorItem.intervalSecond
                            )}
                        />
                        {locales.labels.second}
                    </div>
                </If>
                {/* 重复话术 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.repeat.id}>
                    <div style={{ marginLeft: 8 }}>
                        <Radio.Group
                            disabled
                            onChange={actions.changeOperatorItem.params(operatorItem, "repeatType")}
                            value={operatorItem.operatorList[0].param.repeatType}
                        >
                            {
                                Udesk.enums.repeatRules.map(item => {
                                    return (
                                        <Radio key={item.id} value={item.id}>{item.name}</Radio>
                                    );
                                })
                            }
                        </Radio.Group>
                    </div>
                    <If condition={operatorItem.operatorList[0].param.repeatType === Udesk.enums.repeatRules.similar.id}>
                        <div style={{ marginLeft: 8 }}>
                            {locales.components.operatorListGather.similarity}
                            <InputNumber
                                disabled
                                min={0}
                                max={100}
                                style={{ width: 50 }}
                                className="operator-list-item-grab-input"
                                value={operatorItem.operatorList[0].param.similarity}
                                onChange={actions.changeInputValue.params(operatorItem, "similarity")}
                            />
                            {locales.components.operatorListGather.percent}
                        </div>
                    </If>
                    <div style={{ marginLeft: 8 }}>
                        {locales.components.operatorListGather.repet}
                        <InputNumber
                            disabled
                            min={0}
                            style={{ width: 50 }}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].param.repeatTimes}
                            onChange={actions.changeInputValue.params(operatorItem, "repeatTimes")}
                        />
                        {locales.components.operatorListGather.bout}
                    </div>
                    <div style={{ marginLeft: 8 }}>
                        {locales.components.operatorListGather.ignore}
                        <InputNumber
                            disabled
                            min={0}
                            style={{ width: 50 }}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].param.wordsNum}
                            onChange={actions.changeInputValue.params(operatorItem, "wordsNum")}
                        />
                        {locales.components.operatorListGather.ignoreAffix}
                    </div>
                </If>
                {/* 4、上下文重复
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.context.id}>
                    <input
                        type="text"
                        disabled
                        className="operator-list-item-context"
                        value={operatorItem.operatorList[0].keyword}
                        onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                        placeholder={locales.components.operatorListGather.pleaseEnterRegular}
                    />
                    <div className="operator-list-item-apply-role-box">
                        <span className="operator-list-item-apply-role-tip">
                            {locales.components.operatorListGather.afterHit}
                        </span>
                        <Select
                            disabled
                            value={operatorItem.operatorList[0].applyRole}
                            className="operator-list-item-apply-role"
                            onChange={actions.changeOperatorItem.params(operatorItem, "applyRole")}
                        >
                            <For each="item" index="index" of={Udesk.enums.applyRoles}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                    </div>
                </If> */}
                {/* 2、疑问句 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.question.id}></If>
                {/* 18、句长检测 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.sentenceLength.id}>
                    <div className="operator-list-item-word-per-minute-box">
                        <Select
                            disabled
                            value={operatorItem.operatorList[0].param.compareType}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, "compareType")}
                        >
                            <For each="item" index="index" of={Udesk.enums.sentenceLength}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                        <InputNumber
                            disabled
                            min={0}
                            className="operator-list-item-word-per-minute"
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            value={operatorItem.operatorList[0].param.wordsNumber}
                            onChange={actions.changeInputValueInParam.params(operatorItem, "wordsNumber")}
                        // formatter={value => `${value}${locales.components.operatorListGather.wordUnit}`}
                        // parser={value => value.replace(`${locales.components.operatorListGather.wordUnit}`, '')}
                        // addonAfter={locales.components.operatorListGather.wordUnit}
                        />
                        {locales.components.operatorListGather.wordUnit}
                    </div>
                </If>
                {/* 8、语速检测 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.speed.id}>
                    <div className="operator-list-item-word-per-minute-box">
                        {locales.components.operatorListGather.minute}
                        {locales.components.operatorListGather.notLessThan}
                        <InputNumber
                            disabled
                            min={0}
                            className="operator-list-item-word-per-minute"
                            value={operatorItem.operatorList[0].wordPerMinite}
                            onChange={actions.changeInputValue.params(operatorItem, "wordPerMinite")}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateSpeedValue.params(
                                operatorItem,
                                "wordPerMinite",
                                operatorItem.operatorList[0].wordPerMinite
                            )}
                        />
                        {locales.components.operatorListGather.wordUnit}
                        {locales.components.operatorListGather.notGreaterThan}
                        <InputNumber
                            disabled
                            min={0}
                            className="operator-list-item-word-per-minute"
                            value={operatorItem.operatorList[0].maxWordPerMinite}
                            onChange={actions.changeInputValue.params(operatorItem, "maxWordPerMinite")}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateSpeedValue.params(
                                operatorItem,
                                "maxWordPerMinite",
                                operatorItem.operatorList[0].maxWordPerMinite
                            )}
                        />
                        {locales.components.operatorListGather.wordUnit}
                    </div>
                </If>
                {/* 15、音量检测 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.volume.id}>
                    <div>
                        <Select
                            disabled
                            value={operatorItem.operatorList[0].param.detectionWay}
                            className="operator-list-item-volume-detection-way"
                            onChange={actions.changeOperatorItem.params(operatorItem, "detectionWay")}
                        >
                            <For each="item" index="index" of={Udesk.enums.volumeDetectionWays}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                        <Select
                            disabled
                            value={operatorItem.operatorList[0].param.operator}
                            className="operator-list-item-volume-detection-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, "operator")}
                        >
                            <For each="item" index="index" of={Udesk.enums.volumeDetectionOperators}>
                                <Select.Option value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                    </div>
                    <div className="operator-list-item-volume-detection-value-box">
                        <Choose>
                            <When condition={operatorItem.operatorList[0].param.detectionWay === Udesk.enums.volumeDetectionWays.loudness.id}>
                                <InputNumber
                                    disabled
                                    min={0}
                                    max={200}
                                    step="0.01"
                                    className="operator-list-item-volume-detection-value"
                                    value={operatorItem.operatorList[0].param.detectionValue}
                                    onChange={actions.changeInputValueInParam.params(operatorItem, "detectionValue")}
                                    placeholder={locales.components.operatorListGather.pleaseEnterLoudness}
                                    onBlur={actions.validateVolumDetectionValue.params(
                                        operatorItem,
                                        operatorItem.operatorList[0].param.detectionValue
                                    )}
                                />
                                {locales.components.operatorListGather.loudnessUnit}
                            </When>
                            <Otherwise>
                                <InputNumber
                                    disabled
                                    min={-1000}
                                    max={1000}
                                    step="0.01"
                                    className="operator-list-item-volume-detection-value"
                                    value={operatorItem.operatorList[0].param.detectionValue}
                                    onChange={actions.changeInputValueInParam.params(operatorItem, "detectionValue")}
                                    placeholder={locales.components.operatorListGather.pleaseEnterRangeAbility}
                                    onBlur={actions.validateVolumDetectionValue.params(
                                        operatorItem,
                                        operatorItem.operatorList[0].param.detectionValue
                                    )}
                                />
                                {locales.components.operatorListGather.percent}
                            </Otherwise>
                        </Choose>
                    </div>
                </If>
                {/* 16、情绪分析 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.emotion.id}>
                    <div>
                        <Select
                            style={{ marginLeft: '8px' }}
                            disabled
                            value={operatorItem.operatorList[0].param.operator}
                            className="operator-list-item-type"
                            onChange={actions.changeOperatorItem.params(operatorItem, "operator")}
                        >
                            <For each="item" index="index" of={Udesk.enums.emotionOperators}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                        <Select
                            disabled
                            value={operatorItem.operatorList[0].param.sentiment}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, "sentiment")}
                        >
                            <For each="item" index="index" of={Udesk.enums.sentimentTypes}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                    </div>
                </If>
                {/* 13、信息实体检测 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id}>
                    <div className="operator-list-item-entity-info-container">
                        <div className="operator-list-item-entity-field-container" style={{ minWidth: '150px', height: '32px', lineHeight: '24px' }}>
                            <AutoComplete
                                disabled
                                url={(props.entityUrl && props.entityUrl !== "") ? props.entityUrl : Udesk.business.apiPath.concatApiPath(`/information-entity/list`, props.sdkOptions)}
                                value={operatorItem.operatorList[0].param.entity}
                                method={"POST"}
                                extraQueryParams={{ activeFlag: 1 }}
                                queryParamName={"keyword"}
                                onChanged={actions.changeOperatorItem.params(operatorItem, "entity")}
                                enableEmptySearch={true}
                                placeholder={locales.components.operatorListGather.pleaseSelectEntity}
                            />
                            {/* <Select
                                disabled
                                showSearch
                                value={operatorItem.operatorList[0].param.entity}
                                placeholder={locales.components.operatorListGather.pleaseSelectEntity}
                                style={{ width: 128 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={actions.handleSelectSearch.params("entity")}
                                onChange={actions.changeOperatorItem.params(operatorItem, "entity")}
                                notFoundContent={null}
                            >
                                <For each="item" index="index" of={privates.selectOptionList.entityInfoDetection}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select> */}
                        </div>
                        <div className="operator-list-item-entity-operator-container">
                            <Select
                                disabled
                                value={operatorItem.operatorList[0].param.operator}
                                style={{ width: '114px' }}
                                onChange={actions.changeOperatorItem.params(operatorItem, "operator")}
                            >
                                <Select.Option value={null}>
                                    {locales.components.operatorListGather.pleaseSelectOperator}
                                </Select.Option>
                                <For each="item" index="index" of={Udesk.enums.entityOperators}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                        </div>
                        <div className="operator-list-item-entity-value-container" style={{ marginLeft: '8px', minWidth: '150px', height: '32px', lineHeight: '24px' }}>
                            <Choose>
                                <When condition={
                                    (operatorItem.operatorList[0].param.operator === null) ||
                                    (operatorItem.operatorList[0].param.operator === Udesk.enums.entityOperators.equals.id) ||
                                    (operatorItem.operatorList[0].param.operator === Udesk.enums.entityOperators.notEquals.id) ||
                                    (operatorItem.operatorList[0].param.operator === Udesk.enums.entityOperators.contains.id) ||
                                    (operatorItem.operatorList[0].param.operator === Udesk.enums.entityOperators.notContains.id)
                                }>
                                    {/* <PowerSelect
                                                disabled
                                                dataSource={props.entityFields}
                                                nameField="label"
                                                value={operatorItem.operatorList[0].param.expectedValueObject}
                                                placeholder={locales.components.operatorListGather.pleaseSelectBasicInfo}
                                                onChanged={actions.changeOperatorItem.params(operatorItem.param, "expectedValueObject")}
                                            /> */}
                                    <AutoComplete
                                        disabled
                                        defaultOptions={props.entityFields}
                                        value={operatorItem.operatorList[0].param.expectedValueObject}
                                        nameField="label"
                                        placeholder={locales.components.operatorListGather.pleaseSelectBasicInfo}
                                        onChanged={actions.changeOperatorItem.params(operatorItem, "expectedValueObject")}
                                        isFilterSelectedOption={true}
                                    />
                                    {/* <Select
                                        disabled
                                        showSearch
                                        value={operatorItem.operatorList[0].param.expectedValueObject}
                                        placeholder={locales.components.operatorListGather.pleaseSelectBasicInfo}
                                        style={{ width: 128 }}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={actions.handleSelectSearch.params("expectedValueObject")}
                                        onChange={actions.changeOperatorItem.params(operatorItem, "expectedValueObject")}
                                        notFoundContent={null}
                                    >
                                        <For each="item" index="index" of={props.entityFields}>
                                            <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>
                                        </For>
                                    </Select> */}
                                </When>
                                <Otherwise>
                                    <Input
                                        disabled
                                        value={operatorItem.operatorList[0].param.expectedValue}
                                        onChange={actions.changeInputValue.params(operatorItem, "expectedValue")}
                                        placeholder={locales.components.operatorListGather.pleaseEnterFixedValue}
                                    />
                                </Otherwise>
                            </Choose>
                        </div>
                    </div>
                </If>
                {/* 随路字段 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.associate.id}>
                    <div style={{ position: 'relative', width: 'calc(100% - 400px)' }} ref={privates.associateDivRef}>
                        <Input.TextArea
                            ref={privates.associateInputRef}
                            disabled
                            style={{ width: '100%', margin: '16px 8px', resize: "none" }}
                            placeholder={locales.components.operatorListGather.pleaseEnterAssociate}
                            value={operatorItem.operatorList[0].param.inputContent}
                            onClick={actions.showAssociate.params('focus')}
                            autoSize
                            onChange={actions.changeInputValue.params(operatorItem, "inputContent")}
                        // onBlur={actions.showAssociate.params('blur')}
                        />
                        <Popover
                            autoAdjustOverflow={false}
                            disabled
                            trigger="click"
                            visible={privates.showAssociate}
                            onVisibleChange={actions.showAssociate.params('click')}
                            placement="right"
                            getPopupContainer={() => privates.associateDivRef.current}
                            content={(
                                <div style={{ width: 280, height: 400 }} onClick={(e) => actions.associateInputFocus()}>
                                    <div>
                                        <Tabs disabled size='small' activeKey={privates.associateActiveTab} tabBarExtraContent={operations} onChange={actions.associateTabsChange}>
                                            <Tabs.TabPane tab={locales.components.operatorListGather.associateMedol.tabs.data} key="data"></Tabs.TabPane>
                                            <Tabs.TabPane tab={locales.components.operatorListGather.associateMedol.tabs.operator} key="operator"></Tabs.TabPane>
                                        </Tabs>
                                    </div>
                                    <If condition={privates.associateActiveTab === 'data'}>
                                        <div className='operator-list-item-associate-model'>
                                            {
                                                privates.associateList && privates.associateList.map(associateData => {
                                                    return (
                                                        <If condition={associateData.fieldList && associateData.fieldList.length > 0}>
                                                            <div style={{ marginTop: 8, borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                                                <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.45)" }}>
                                                                    {
                                                                        associateData.type === 1 ? locales.components.operatorListGather.associateData :
                                                                            associateData.type === 3 ? locales.components.operatorListGather.pretreatmentData :
                                                                                associateData.type === 6 ? locales.components.operatorListGather.businessData : ''
                                                                    }
                                                                </div>
                                                                {
                                                                    associateData.fieldList && associateData.fieldList.map(item => {
                                                                        return (
                                                                            <div className="operator-list-item-associate-model-item" onClick={actions.associateDataClick.params(operatorItem, 'data', `[${item.label}]`)}>{`[${item.label}]`}</div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </If>
                                                    );
                                                })
                                            }
                                        </div>
                                    </If>
                                    <If condition={privates.associateActiveTab === 'operator'}>
                                        <div className='operator-list-item-associate-model'>
                                            {
                                                privates.associateOperatorList && privates.associateOperatorList.map(operatorList => {
                                                    return (
                                                        <If condition={operatorList.list && operatorList.list.length > 0}>
                                                            <div style={{ marginTop: 8, borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                                                <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.45)" }}>
                                                                    {operatorList.type === 2 ? locales.components.operatorListGather.functionOperator : locales.components.operatorListGather.operation}
                                                                </div>
                                                                <div>
                                                                    {operatorList.list && operatorList.list.map(operator => {
                                                                        let iconType = privates.associateOperatorIconList[0];
                                                                        if (operator.type === 1) {
                                                                            let res = privates.associateOperatorIconList.find(icon => icon.subType === operator.subType);
                                                                            if (res) {
                                                                                iconType = res;
                                                                            }
                                                                        }
                                                                        return (
                                                                            <div className="operator-list-item-associate-model-operator" onClick={actions.associateDataClick.params(operatorItem, operatorList.type, operator.view)}>
                                                                                <div className="operator-list-item-associate-model-operator-icon" >
                                                                                    <Icon type={iconType.icon} style={{ fontSize: '22px' }} />
                                                                                </div>
                                                                                <div className="operator-list-item-associate-model-operator-content">
                                                                                    <div className="operator-list-item-associate-model-operator-title">
                                                                                        {operator.view}
                                                                                    </div>
                                                                                    <div className="operator-list-item-associate-model-operator-des">
                                                                                        {operator.note}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </If>
                                                    );
                                                })
                                            }
                                        </div>
                                    </If>
                                </div>
                            )}
                        >
                            <i style={{ width: 1 }}></i>
                        </Popover>

                        {/* <div className="operator-list-item-associate-model" onClick={(e)=>actions.associateInputFocus()} style={{display:privates.showAssociate?'flex':'none'}}>
                            <Tabs size='small' tabBarExtraContent={operations} onChange={actions.associateTabsChange}>
                                <Tabs.TabPane tab="Tab 1" key="1"></Tabs.TabPane>
                                <Tabs.TabPane tab="Tab 2" key="2"></Tabs.TabPane>
                            </Tabs>
                        </div> */}
                    </div>
                </If>
                <Modal
                    headerClassName="udesk-qa-modal-default-header"
                    title={locales.components.operatorListGather.querySemanticTags}
                    visible={state.isSemanticTagsModalVisible}
                    onCancel={actions.changeSemanticTagsModalVisible.params(
                        state.operatorItem
                    )}
                    footer={null}
                    style={{ width: 1000 }}
                >
                    <div className="bs-modal-base">
                        <div className="semantic-tags-modal-container">
                            <div className="semantic-tags-search-container">
                                <Input
                                    disabled
                                    style={{ width: '70%' }}
                                    value={operatorItem.operatorList[0].semanticSentence}
                                    placeholder={locales.components.operatorListGather.pleaseEnterSemanticSentence}
                                    onChange={actions.changeInputValue.params(operatorItem, "semanticSentence")}
                                />
                                <Button
                                    disabled
                                    style={{ marginLeft: "5px" }}
                                    onClick={actions.querySemanticTags.params(operatorItem.operatorList[0].semanticSentence)}
                                >
                                    {locales.components.operatorListGather.querySemanticTags}
                                </Button>
                            </div>
                            <div className="semantic-tags-result-container">
                                {/* <ReactTable columns={state.columns} items={state.semanticTags} /> */}
                                <Table
                                    columns={state.columns}
                                    dataSource={state.semanticTags}
                                    rowSelection={{
                                        onChange: actions.seleteSemanticName,
                                        type: 'radio'
                                    }}
                                />
                            </div>
                            <div className="semantic-tags-tip-container">
                                <span>{locales.components.operatorListGather.tipOne}</span>
                                <span
                                    className="semantic-tags-tip-action"
                                    onClick={actions.openCreateTagWindow}
                                >
                                    {locales.components.operatorListGather.tipTwo}
                                </span>
                            </div>
                            <div style={{ textAlign: 'end' }}>
                                <Button
                                    disabled
                                    onClick={actions.changeSemanticTagsModalVisible.params(
                                        state.operatorItem
                                    )}
                                >
                                    {locales.labels.cancel}
                                </Button>
                                <Button
                                    disabled
                                    type="primary"
                                    style={{ marginLeft: '16px' }}
                                    onClick={actions.changeSemanticTagsModalVisible.params(
                                        state.operatorItem
                                    )}
                                >
                                    {locales.labels.ok}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}