import React from 'react';
import Udesk from 'Udesk';

class TagCategoriesComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        smartTagCategorys: [],
        isClassificationVisible: false,
    };
    //#endregion

    state = {};
    privates = {
        smartTagCategorys: [],
        funcType: '',
    };
    static computes = {};
    actions = {
        cancelSaveClassification() {
            this.trigger('onCancel');
        },
        saveClassification() {
            let {
                sdkOptions
            } = this.props;
            let {
                smartTagCategorys
            } = this.privates;
            let url = Udesk.business.apiPath.concatApiPath(`smartTagCategorys`, sdkOptions);
            let params = smartTagCategorys;
            Udesk.ajax.post(url, params).then(
                resp => {
                    this.trigger('onSave', resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        changeCategoryName(item, e) {
            item.categoryName = e.target.value;
            this.actions.update();
        },
        changeCategoryColor(item, e) {
            item.categoryColor = e.target.value;
            this.actions.update();
        },
        addClassification() {
            let {
                smartTagCategorys,
                funcType,
            } = this.privates;
            let newCategory = {
                categoryName: `${this.locales.components.pages.intelligentTags.defaultCategoryName}${smartTagCategorys.length + 1}`,
                categoryColor: "#001eff",
                funcType,
            };
            smartTagCategorys.push(newCategory);
            this.actions.update();
        },
        deleteClassification(item, index) {
            let {
                sdkOptions
            } = this.props;
            let {
                smartTagCategorys
            } = this.privates;

            if (item.id) {
                let url = Udesk.business.apiPath.concatApiPath(`smartTagCategorys/${item.id}`, sdkOptions);
                let params = {
                    id: item.id
                };
                Udesk.ajax.del(url, params).then(
                    resp => {
                        smartTagCategorys.splice(index, 1);
                        this.actions.update();
                        this.trigger('deleteItem', item);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            } else {
                smartTagCategorys.splice(index, 1);
                this.actions.update();
            }
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {
            smartTagCategorys: Udesk.utils.object.deepCopy(props.smartTagCategorys),
            funcType: props.funcType,
        };
    }
    componentDidMount() {

    }
    componentWillUnmount() {

    }
    asyncModel(changedParams) {

    }
    //#endregion
}

export default TagCategoriesComponent;