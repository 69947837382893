// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetIntelligentPartnerTagGroupsByIdParam,
    BaseResponseIntelligentPartnerTagGroupFoundResponse,
    PutIntelligentPartnerTagGroupsByIdParam,
    PutIntelligentPartnerTagGroupsByIdBody,
    BaseResponseVoid,
    DeleteIntelligentPartnerTagGroupsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 智能陪练-客户标签组
 * @link [GET] /intelligentPartnerTagGroups/{id}
 * @param id
 */
export function getIntelligentPartnerTagGroupsById(
    options: ApiOptions<GetIntelligentPartnerTagGroupsByIdParam, never> & {
        segments: GetIntelligentPartnerTagGroupsByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerTagGroupFoundResponse> {
    return request<never, GetIntelligentPartnerTagGroupsByIdParam, never>(
        '/intelligentPartnerTagGroups/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentPartnerTagGroupsById' }
    );
}

/**
 * 编辑客户标签组
 *
 * @export
 * @tags 智能陪练-客户标签组
 * @link [PUT] /intelligentPartnerTagGroups/{id}
 * @param id
 */
export function putIntelligentPartnerTagGroupsById(
    data: PutIntelligentPartnerTagGroupsByIdBody,
    options: ApiOptions<PutIntelligentPartnerTagGroupsByIdParam, never> & {
        segments: PutIntelligentPartnerTagGroupsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentPartnerTagGroupsByIdBody,
        PutIntelligentPartnerTagGroupsByIdParam,
        never
    >('/intelligentPartnerTagGroups/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerTagGroupsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 智能陪练-客户标签组
 * @link [DELETE] /intelligentPartnerTagGroups/{id}
 * @param id
 */
export function deleteIntelligentPartnerTagGroupsById(
    options: ApiOptions<DeleteIntelligentPartnerTagGroupsByIdParam, never> & {
        segments: DeleteIntelligentPartnerTagGroupsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentPartnerTagGroupsByIdParam, never>(
        '/intelligentPartnerTagGroups/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteIntelligentPartnerTagGroupsById',
        }
    );
}

export const meta = [
    { tags: ['智能陪练-客户标签组'], path: '/intelligentPartnerTagGroups/{id}', method: 'get' },
    { tags: ['智能陪练-客户标签组'], path: '/intelligentPartnerTagGroups/{id}', method: 'put' },
    { tags: ['智能陪练-客户标签组'], path: '/intelligentPartnerTagGroups/{id}', method: 'delete' },
];
