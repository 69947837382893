import { useEffect, useState } from 'react';
import { fire } from 'src/util/core';
import {AudioWorker} from './worker';

const INACTIVE = 'inactive';
const RECORDING = 'recording';
const PAUSED = 'paused';

export class UserMedia {
    stream;
    config;
    worker;
    recorder;

    static getSupportedMimeTypes () {
        const media = 'video';
        // 常用的视频格式
        const types = [
            'webm',
            'mp4',
            'ogg',
            'mov',
            'avi',
            'wmv',
            'flv',
            'mkv',
            'ts',
            'x-matroska',
        ];
        // 常用的视频编码
        const codecs = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h264'];
        // 支持的媒体类型
        const supported = [];
        const isSupported = MediaRecorder.isTypeSupported;
        // 遍历判断所有的媒体类型
        types.forEach((type) => {
            const mimeType = `${media}/${type}`;
            codecs.forEach((codec) =>
                [
                    `${mimeType};codecs=${codec}`,
                    `${mimeType};codecs=${codec.toUpperCase()}`,
                ].forEach((variation) => {
                    if (isSupported(variation)) supported.push(variation);
                }),
            );
            if (isSupported(mimeType)) supported.push(mimeType);
        });
        return supported;
    }

    constructor(config) {
        this.config = config;
        this.worker = new AudioWorker();
        this.chunks = [];
    }
    init(callback) {
        const me = this;
        const {config: {isDisplay, audio, video}, error} = me;

        let promise = null;
        if (isDisplay) {
            promise = navigator.mediaDevices.getDisplayMedia({
                video
            });
        } else {
            promise = navigator.mediaDevices.getUserMedia({
                audio,
                video,
            });
        }
        promise.then(
            (stream) => {
                fire.call(me, callback, me.stream = stream);
            },
            error.bind(me)
        );

        return me;
    }
    getUrl (chunks) {
        const data = chunks || this.chunks;
        const blob = new Blob(data, {
            type: data[0].type,
        });
        return URL.createObjectURL(blob);
    }
    download (name) {
        const url = this.getUrl();
        const link = document.createElement('a');
        link.href = url;
        link.style.display = 'none';
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));
    }
    takePicture ($video, $canvas, width, height, name, imageCompression= 0.8) {
        const ctx = $canvas.getContext('2d');
        ctx.drawImage($video, 0, 0, width, height);
        if (name) {
            ctx.font = '22px Sans-Serif';
            ctx.fillStyle = 'red';
            ctx.fillText(name, 20, 40);
        }
        return $canvas.toDataURL('image/jpeg', imageCompression);
    }
    applyConstraints(constraints) {
        return this.stream.getVideoTracks()[0].applyConstraints(constraints);
    }
    start (timeSlice = 1024) {
        const me = this;
        const {stream, recorder, chunks, config: {
            mimeType,
            onDataAvailable,
            stop
        }} = me;
        if (stream.active) {
            if (!recorder) {
                chunks.splice(0, chunks.length);
                me.recorder = new MediaRecorder(stream, {
                    mimeType: mimeType || UserMedia.getSupportedMimeTypes()[0]
                });
                me.recorder.addEventListener('dataavailable', (e) => {
                    chunks.push(e.data);
                    fire.call(me, onDataAvailable, e);
                });
                me.recorder.addEventListener('stop', (e) => {
                    fire.call(me, stop, e);
                });
            }
            if (me.recorder.state === INACTIVE) {
                me.recorder.start(timeSlice);
            }
        }
    }
    stop () {
        const me = this;
        const {recorder, stream} = me;
        if (recorder) {
            if (recorder.state === RECORDING) {
                recorder.stop();
                me.recorder = undefined;
            }
        }
        if (stream) {
            if (stream.active) {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        }
    }
    resume() {
        const {recorder} = this;
        if (recorder) {
            if (recorder.state === PAUSED) {
                recorder.resume();
            }
        }
    }
    pause () {
        const {recorder} = this;
        if (recorder) {
            if (recorder.state === RECORDING) {
                recorder.pause();
            }
        }
    }
    postMessage (data) {
        this.worker.postMessage(data);
    }
    onmessage(event) {
        this.worker.onmessage(event);
    }
    error({message}) {
        let code = 0;
        let errorMsg = '';
        if (message.indexOf('Permission denied') !== -1){
            code = 1;
            errorMsg = /* 禁用了摄像头 */UdeskLocales['current'].pages.coach.learningCenter.components.camera.index.cameraDisabled;
        }
        if (message.indexOf('Requested device not found') !== -1){
            code = 2;
            errorMsg = /* 没有找到摄像头设备 */UdeskLocales['current'].pages.coach.learningCenter.components.camera.index.noCameraDevicesFound;
        }
        this.config.onError(code, errorMsg);
    }
}

export const FacingMode = ['user', 'environment']; // 前置,后置摄像头

export const useVideo = (videoRef) => {
    const [isUserNotAllow, setIsUserNotAllow] = useState(0);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const userMedia = new UserMedia({
            audio: false,
            isDisplay: false,
            video: {
                facingMode: FacingMode[0]
            },
            onError (code, message) {
                setIsUserNotAllow(code > 0);
                setErrorMsg(message);
            }
        }).init(function (stream) {
            videoRef.current.srcObject = stream;
        });

        return () => {
            // 关闭设备通道
            const stream = userMedia.stream;
            if (stream) {
                stream.getTracks().forEach(track => {
                    track.stop();
                });
            }
        };
    }, []);

    return [isUserNotAllow, errorMsg];
};