import React, { useState, useCallback } from 'react';
import Locales from 'UdeskLocales';
import { Popconfirm, Button, Form, Input} from 'udesk-ui';
import './index.scss';

export default React.memo((props: any) => {
    const locales = Locales['current'];
    const {onSubmit, buttonType = 'link'} = props;
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const show = useCallback(() => {
        setOpen(true);
    }, []);

    const cancelHandle = useCallback(() => {
        setOpen(false);
    },[]);

    const submitHandle = useCallback((values) => {
        onSubmit(values).then(() => {
            cancelHandle();
        });
    }, [cancelHandle, onSubmit]);

    return ( 
        <Popconfirm
            overlayClassName='approve-reject-button'
            visible={open}
            placement="bottom"
            icon={null}
            title={(
                <Form
                    form={form}
                    requiredMark={false}
                    layout="vertical"
                    onFinish={submitHandle}
                >
                    <Form.Item
                        name="rejectReason"
                        label={/* 确定驳回吗 */locales.pages.tasks.approve.components.rejectButton.index.areYouSureToReject}
                        rules={[
                            { required: true, message: /* 请输入驳回原因 */locales.pages.tasks.approve.components.rejectButton.index.pleaseEnterTheRejectionReason }
                        ]}
                    >
                        <Input placeholder={/* 请输入驳回原因 */locales.pages.tasks.approve.components.rejectButton.index.pleaseEnterTheRejectionReason} />
                    </Form.Item>
                </Form>
            )} 
            okText={locales.labels.confirm} 
            cancelText={locales.labels.cancel} 
            onConfirm={form.submit}
            onCancel={cancelHandle}
        >
            <Button type={buttonType} onClick={show} danger >{/* 驳回 */locales.pages.tasks.approve.components.rejectButton.index.reject}</Button>
        </Popconfirm> 
    );
});