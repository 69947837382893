import TemplateComponentsMyComponent from './component';
import Udesk from 'Udesk';

class TemplateComponentsMyRoute extends TemplateComponentsMyComponent {
    asyncModel() {
        let { sdkOptions } = this.props;
        let { keywords, releaseStatus, paging } = this.privates;
        let data = {
            pageNum: paging.pageNum,
            pageSize: paging.pageSize,
        };
        if (!keywords) {
            keywords = '';
        }
        data.keywords = keywords;
        if (releaseStatus) {
            data.releaseStatus = releaseStatus;
        }
        let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionModules/my-list`, sdkOptions);
        let taskPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(taskUrl, data).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            taskData: taskPromise,
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}

export default TemplateComponentsMyRoute;
