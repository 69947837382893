// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { GetWeComDataCallByIdByTypeParam, BaseResponseint } from '../../../types';

/**
 * findNext
 *
 * @export
 * @tags 企微语音
 * @link [GET] /weComDataCall/{id}/{type}
 * @param id
 * @param type
 */
export function getWeComDataCallByIdByType(
    options: ApiOptions<GetWeComDataCallByIdByTypeParam, never> & {
        segments: GetWeComDataCallByIdByTypeParam;
    }
): Promise<BaseResponseint> {
    return request<never, GetWeComDataCallByIdByTypeParam, never>('/weComDataCall/{id}/{type}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getWeComDataCallByIdByType',
    });
}

export const meta = [{ tags: ['企微语音'], path: '/weComDataCall/{id}/{type}', method: 'get' }];
