import Udesk from 'Udesk';
import React from 'react';
import CustomerServiceGrounpComponent from './component';
import { Tooltip, Tree, Input, Icon, Space, Button } from 'udesk-ui';
import './style.scss';
import Locales from 'UdeskLocales';

const { TreeNode } = Tree;

const TitleNode = (props) => {
    const { item, grounpFlag, actions, jurisdiction } = props;
    const locales = Locales['current'];

    return (
        <div className="customer-service-node">
            <div>
                {grounpFlag[item.key] ? (
                    <Space>
                        <Input
                            value={item.title}
                            key={item}
                            onChange={actions.grounpChange.params(item)}
                            onFocus={actions.onFocusInpuit}
                            // onPressEnter={actions.grounpSave.params(item)}
                        />
                        <Button onClick={actions.grounpSave.params(item)} type="link" size="small">
                            {locales.labels.save}
                        </Button>
                    </Space>
                ) : (
                    <span>{item.title}</span>
                )}
            </div>
            {(item.nodeType === 1 || item.nodeType === 3) && !grounpFlag[item.key] && (
                <Tooltip
                    overlayClassName="customer-service-edit-list"
                    placement="bottom"
                    title={
                        item.nodeType === 3 ? (
                            <div className="customer-service-edit-list">
                                {jurisdiction &&
                                    Udesk.data.init.user &&
                                    (Udesk.data.init.user.hasFeature('agent:info:editGroup') ||
                                        Udesk.data.init.user.hasFeature('agent:info:addGroup') ||
                                        Udesk.data.init.user.hasFeature(
                                            'agent:info:deleteGroup'
                                        )) && (
                                        <div>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature(
                                                        'agent:info:editGroup'
                                                    )
                                                }
                                            >
                                                <p onClick={actions.groupNodeEdit.params(item)}>
                                                    {locales.labels.edit}
                                                </p>
                                            </If>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature(
                                                        'agent:info:addGroup'
                                                    )
                                                }
                                            >
                                                <p onClick={actions.groupNodeAdd.params(item)}>
                                                    {locales.labels.increase}
                                                </p>
                                            </If>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature(
                                                        'agent:info:deleteGroup'
                                                    )
                                                }
                                            >
                                                <p onClick={actions.groupNodeDel.params(item)}>
                                                    {locales.labels.delete}
                                                </p>
                                            </If>
                                        </div>
                                    )}
                                {!jurisdiction && (
                                    <div>
                                        <p onClick={actions.groupNodeEdit.params(item)}>
                                            {locales.labels.edit}
                                        </p>
                                        <p onClick={actions.groupNodeAdd.params(item)}>
                                            {locales.labels.increase}
                                        </p>
                                        <p onClick={actions.groupNodeDel.params(item)}>
                                            {locales.labels.delete}
                                        </p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            item.nodeType === 1 && (
                                <div className="customer-service-edit-list">
                                    {jurisdiction &&
                                        Udesk.data.init.user &&
                                        (Udesk.data.init.user.hasFeature('agent:info:editGroup') ||
                                            Udesk.data.init.user.hasFeature(
                                                'agent:info:addGroup'
                                            ) ||
                                            Udesk.data.init.user.hasFeature(
                                                'agent:info:deleteGroup'
                                            )) && (
                                            <div>
                                                <If
                                                    condition={
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'agent:info:editGroup'
                                                        )
                                                    }
                                                >
                                                    <p onClick={actions.groupNodeEdit.params(item)}>
                                                        {locales.labels.edit}
                                                    </p>
                                                </If>
                                                <If
                                                    condition={
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'agent:info:addGroup'
                                                        )
                                                    }
                                                >
                                                    <p onClick={actions.groupNodeAdd.params(item)}>
                                                        {locales.labels.increase}
                                                    </p>
                                                </If>
                                            </div>
                                        )}
                                    {!jurisdiction && (
                                        <div>
                                            <p onClick={actions.groupNodeEdit.params(item)}>
                                                {locales.labels.edit}
                                            </p>
                                            <p onClick={actions.groupNodeAdd.params(item)}>
                                                {locales.labels.increase}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )
                        )
                    }
                >
                    <Icon type="ic-ellipsis-shenglve" />
                </Tooltip>
            )}
        </div>
    );
};
export default class CustomerServiceGrounpTemplate extends CustomerServiceGrounpComponent {
    render() {
        let { actions } = this;
        let { jurisdiction } = this.props;
        let { data, grounpFlag, selectedKeys, expandedKeys } = this.privates;

        const loop = (data) =>
            data.map((item) => {
                if (item.children && item.children.length) {
                    return (
                        <TreeNode
                            key={item.key}
                            title={
                                <TitleNode
                                    item={item}
                                    grounpFlag={grounpFlag}
                                    actions={actions}
                                    jurisdiction={jurisdiction}
                                />
                            }
                        >
                            {loop(item.children)}
                        </TreeNode>
                    );
                }
                return (
                    <TreeNode
                        key={item.key}
                        title={
                            <TitleNode
                                item={item}
                                grounpFlag={grounpFlag}
                                actions={actions}
                                jurisdiction={jurisdiction}
                            />
                        }
                    />
                );
            });
        return (
            <div className="customer-service-grounp-field">
                <Tree
                    className="draggable-tree"
                    defaultExpandAll
                    expandedKeys={expandedKeys}
                    onSelect={actions.onSelect}
                    selectable={true}
                    onExpand={actions.onExpand}
                    selectedKeys={selectedKeys}
                    // draggable
                    blockNode
                    // onDragEnter={actions.onDragEnter}
                    // onDrop={actions.onDrop}
                    // onDragStart={actions.onDragStart}
                >
                    {loop(data)}
                </Tree>
            </div>
        );
    }
}
