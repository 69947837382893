import React from 'react';
// import styled from 'styled-components';
import { Button, Icon } from 'udesk-ui';
import { useDialogueContext } from '../Context';

type PlayBtnProps = {
    url?: string;
    position: 'left' | 'right';
};

export const PlayBtn = React.memo<PlayBtnProps>((props) => {
    const { audioInstance } = useDialogueContext();

    if (!props.url) return null;

    const isPlaying = audioInstance.playingUrl === props.url && audioInstance.playing;
    return (
        <Button
            size="small"
            style={{
                height: 22,
            }}
            icon={
                <Icon
                    style={
                        props.position === 'right'
                            ? { color: '#fff', marginLeft: 4 }
                            : { marginRight: 4 }
                    }
                    type={
                        isPlaying
                            ? 'ic-pause-zanting'
                            : props.position === 'right'
                                ? 'Frame14'
                                : 'a-Frame1'
                    }
                />
            }
            type="text"
            onClick={() => {
                isPlaying ? audioInstance.pause() : audioInstance.play(props.url);
            }}
        ></Button>
    );
});
