export default {
  lang: {
    export: 'Exportar',
    successfully: 'A tarefa de exportação foi criada com sucesso',
    pleaseJumpTo: 'Por favor, pule para',
    uploadAndDownloadCenter: 'Fazer upload do centro de download',
    check: 'Veja',
    exportAll: 'Confirmar a exportação de todo o conteúdo?',
    total: 'Total',
    confirm: 'Dados, confirmação da exportação?',
    error:
      'O número de dados que pode ser exportado é 0, tente novamente depois de alterar os filtros',
  },
};
