// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetPointTemplatePointsMyByInspectDataSourceParam,
    BaseResponseListInspectionPointFoundResponse,
} from '../../../../types';

/**
 * 查询我的规则
 *
 * @export
 * @tags 智能分析-规则模板
 * @link [GET] /pointTemplate/points/my/{inspectDataSource}
 * @param inspectDataSource
 */
export function getPointTemplatePointsMyByInspectDataSource(
    options: ApiOptions<GetPointTemplatePointsMyByInspectDataSourceParam, never> & {
        segments: GetPointTemplatePointsMyByInspectDataSourceParam;
    }
): Promise<BaseResponseListInspectionPointFoundResponse> {
    return request<never, GetPointTemplatePointsMyByInspectDataSourceParam, never>(
        '/pointTemplate/points/my/{inspectDataSource}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getPointTemplatePointsMyByInspectDataSource',
        }
    );
}

export const meta = [
    {
        tags: ['智能分析-规则模板'],
        path: '/pointTemplate/points/my/{inspectDataSource}',
        method: 'get',
    },
];
