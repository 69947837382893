import React from 'react';
import Udesk from 'Udesk';
import ChangeListComponent from './component';
import SafeHtml from 'Component/common/safe-html';
import { Tag } from 'udesk-ui';
import styles from './index.module.scss';

export default class ChangeListTemplate extends ChangeListComponent {
    render() {
        let {
            // sdkOptions,
            list,
            caseBase,
        } = this.props;
        let {
            actions,
            // privates,
            locales,
        } = this;
        return (
            <If condition={list && list.length > 0}>
                <div className="component-change-list">
                    <div className="change-list-title">
                        {locales.components.pages.appealChekingTask.components.changeList.title}
                    </div>
                    <For each="changeItem" index="index" of={list}>
                        <div key={index} className="change-list-item-container">
                            <div
                                className="change-list-item-avata-url"
                                style={{ textAlign: 'center' }}
                            >
                                <Choose>
                                    <When condition={changeItem.createUserAvatarUrl}>
                                        <img
                                            src={changeItem.createUserAvatarUrl}
                                            style={{ height: '100%' }}
                                            className="change-list-item-avata-img"
                                            alt="..."
                                        />
                                    </When>
                                    <Otherwise>
                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ren change-list-item-avata-iconfont"></i>
                                    </Otherwise>
                                </Choose>
                            </div>
                            <div class="change-list-item-info">
                                <p class="change-list-item-info-row">
                                    <span class="change-list-item-info-col">
                                        {actions.getCreateUserName(changeItem)}
                                    </span>
                                    <span class="change-list-item-info-col">
                                        {changeItem.createTime}
                                    </span>
                                    <span class="change-list-item-info-col">
                                        {Udesk.enums.gradeChangeTypes.getName(
                                            changeItem.changeType
                                        )}
                                        <If condition={changeItem.changeType === 16}>
                                            {' : '}
                                            {caseBase &&
                                                caseBase.map((item) => {
                                                    if (
                                                        item.id ===
                                                        parseInt(changeItem.data.caseLibrary, 10)
                                                    ) {
                                                        return item.name;
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            {/* {changeItem.data.caseLibrary}  */}
                                        </If>
                                    </span>
                                </p>
                                <p class="change-list-item-info-row">
                                    <If
                                        condition={
                                            changeItem.changeType !==
                                            Udesk.enums.gradeChangeTypes.file.id
                                        }
                                    >
                                        <If condition={changeItem.data}>
                                            <If
                                                condition={
                                                    changeItem.data.dataPointChangeList &&
                                                    changeItem.data.dataPointChangeList.length > 0
                                                }
                                            >
                                                <For
                                                    each="dataPointItem"
                                                    index="index"
                                                    of={changeItem.data.dataPointChangeList}
                                                >
                                                    {dataPointItem.pointName}
                                                    {locales.labels.colon}
                                                    <Choose>
                                                        {/* N/A项选中 */}
                                                        <When
                                                            condition={dataPointItem.newNaChecked}
                                                        >
                                                            {locales.enums.pointNaChecked.check}
                                                            {Udesk.enums.intBoolean.getName(
                                                                dataPointItem.newNaChecked
                                                            )}
                                                            <If
                                                                condition={
                                                                    dataPointItem.oldNaChecked === 0
                                                                }
                                                            >
                                                                {locales.labels.arrow}
                                                                {Udesk.enums.intBoolean.getName(
                                                                    dataPointItem.oldNaChecked
                                                                )}
                                                            </If>
                                                        </When>
                                                        {/* 未选择N/A项 */}
                                                        <Otherwise>
                                                            <span class="change-list-item-info-col">
                                                                {dataPointItem.oldScore}
                                                                {locales.labels.arrow}
                                                                {dataPointItem.newScore}
                                                            </span>
                                                        </Otherwise>
                                                    </Choose>
                                                </For>
                                            </If>
                                            {/* 申诉 */}
                                            <If
                                                condition={
                                                    changeItem.data.complainPoints &&
                                                    changeItem.data.complainPoints.length > 0
                                                }
                                            >
                                                <For
                                                    each="dataPointItem"
                                                    index="index"
                                                    of={changeItem.data.complainPoints}
                                                >
                                                    <If
                                                        condition={
                                                            dataPointItem.sentenceList &&
                                                            dataPointItem.sentenceList.length > 0
                                                        }
                                                    >
                                                        <For
                                                            each="sentence"
                                                            index="index"
                                                            of={dataPointItem.sentenceList}
                                                        >
                                                            <span class="change-list-item-info-col">
                                                                {actions.getPointName(
                                                                    dataPointItem.pointId
                                                                )}
                                                                {locales.labels.colon}
                                                                {sentence.text}
                                                                {locales.labels.arrow}
                                                                {Udesk.enums.appealTypes.getName(
                                                                    sentence.complainType
                                                                )}
                                                                {locales.labels.colon}
                                                                {sentence.complainReason}
                                                            </span>
                                                        </For>
                                                    </If>
                                                </For>
                                            </If>
                                            {/* 备注 */}
                                            <If
                                                condition={
                                                    changeItem.data.oldContent ||
                                                    changeItem.data.newContent
                                                }
                                            >
                                                <span class="change-list-item-info-col">
                                                    {locales.labels.description}
                                                    {locales.labels.colon}
                                                    {changeItem.data.oldContent}
                                                    {locales.labels.arrow}
                                                    {changeItem.data.newContent}
                                                </span>
                                            </If>
                                        </If>
                                        {/* 抽检 - 8 */}
                                        <If
                                            condition={
                                                changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.sample.id
                                            }
                                        >
                                            <span class="change-list-item-info-col">
                                                {
                                                    locales.components.pages.components.changeList
                                                        .sampleStatus
                                                }
                                                {locales.labels.colon}
                                                {changeItem.data.sampleStatusChange.oldStatus}
                                                {locales.labels.arrow}
                                                {changeItem.data.sampleStatusChange.newStatus}
                                            </span>
                                        </If>
                                        {/* 抽检分配 - 11 */}
                                        <If
                                            condition={
                                                changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.sampleAssign.id
                                            }
                                        >
                                            <span class="change-list-item-info-col">
                                                {
                                                    locales.components.pages.components.changeList
                                                        .sampleAssignInspector
                                                }
                                                {locales.labels.colon}
                                                {changeItem.data.assignInspector}
                                            </span>
                                        </If>
                                        {/* 复核分配 - 12 */}
                                        <If
                                            condition={
                                                changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.reviewAssign.id
                                            }
                                        >
                                            <span class="change-list-item-info-col">
                                                {
                                                    locales.components.pages.components.changeList
                                                        .reviewAssignInspector
                                                }
                                                {locales.labels.colon}
                                                {changeItem.data.assignInspector}
                                            </span>
                                        </If>
                                        {/* 已读 - 13 */}
                                        <If
                                            condition={
                                                changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.read.id
                                            }
                                        >
                                            <span class="change-list-item-info-col">
                                                {
                                                    locales.components.pages.components.changeList
                                                        .read
                                                }
                                                {locales.labels.colon}
                                                {changeItem.data.reader}
                                            </span>
                                        </If>
                                        {/* 复核确认 - 14、抽检确认 - 15 */}
                                        {/* <If condition={changeItem.changeType === Udesk.enums.gradeChangeTypes.reviewConfirm.id || changeItem.changeType === Udesk.enums.gradeChangeTypes.sampleConfirm.id}>
                                            <span class="change-list-item-info-col">
                                            </span>
                                        </If> */}
                                        {/* 重新质检 - 23 */}
                                        <If
                                            condition={
                                                changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.reInspection.id
                                            }
                                        >
                                            <For
                                                each="dataPointItem"
                                                index="index"
                                                of={changeItem.data.pointChangeList}
                                            >
                                                {dataPointItem.pointId
                                                    ? dataPointItem.pointName
                                                    : locales.components.pages.components.changeList
                                                          .totalScore}
                                                {locales.labels.colon}
                                                <Choose>
                                                    {/* N/A项选中 */}
                                                    <When condition={dataPointItem.newNaChecked}>
                                                        {locales.enums.pointNaChecked.check}
                                                        {Udesk.enums.intBoolean.getName(
                                                            dataPointItem.newNaChecked
                                                        )}
                                                        <If
                                                            condition={
                                                                dataPointItem.oldNaChecked === 0
                                                            }
                                                        >
                                                            {locales.labels.arrow}
                                                            {Udesk.enums.intBoolean.getName(
                                                                dataPointItem.oldNaChecked
                                                            )}
                                                        </If>
                                                    </When>
                                                    {/* 未选择N/A项 */}
                                                    <Otherwise>
                                                        <span class="change-list-item-info-col">
                                                            {dataPointItem.oldScore}
                                                            {locales.labels.arrow}
                                                            {dataPointItem.newScore}
                                                        </span>
                                                    </Otherwise>
                                                </Choose>
                                            </For>
                                        </If>
                                    </If>
                                    <If
                                        condition={
                                            changeItem.changeType ===
                                            Udesk.enums.gradeChangeTypes.file.id
                                        }
                                    >
                                        {Udesk.enums.gradeChangeTypes.file.name}
                                    </If>
                                    <If
                                        condition={
                                            changeItem.changeType ===
                                            Udesk.enums.gradeChangeTypes.caseBase.id
                                        }
                                    >
                                        <SafeHtml
                                            content={`${locales.components.pages.components.qualityCheckScore.remark}:${changeItem.data.content}`}
                                            className={styles.remark}
                                        />
                                    </If>

                                    <If
                                        condition={
                                            changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.sampleAgain.id ||
                                            changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.sampleCancel.id ||
                                            changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.reviewAgain.id ||
                                            changeItem.changeType ===
                                                Udesk.enums.gradeChangeTypes.reviewCancel.id
                                        }
                                    >
                                        <span class="change-list-item-info-col">
                                            {
                                                locales.components.pages.components.changeList
                                                    .distributivePerson
                                            }
                                            {locales.labels.colon}
                                            <If condition={changeItem.data.assignChange}>
                                                <If
                                                    condition={
                                                        changeItem.data.assignChange.oldAssign
                                                    }
                                                >
                                                    {changeItem.data.assignChange.oldAssign}
                                                    {locales.labels.arrow}
                                                </If>
                                                <If
                                                    condition={
                                                        changeItem.data.assignChange.newAssign
                                                    }
                                                >
                                                    {changeItem.data.assignChange.newAssign}
                                                </If>
                                            </If>
                                        </span>
                                    </If>
                                    <If
                                        condition={
                                            changeItem.changeType ===
                                            Udesk.enums.gradeChangeTypes.smartTagsChange.id
                                        }
                                    >
                                        <span class="change-list-item-info-col">
                                            <If condition={changeItem.data.callTagChange}>
                                                {changeItem.data.callTagChange.type &&
                                                    (changeItem.data.callTagChange.type === 'add'
                                                        ? locales.labels.add + locales.labels.colon
                                                        : locales.labels.delete +
                                                          locales.labels.colon)}
                                                {Array.isArray(
                                                    changeItem.data.callTagChange.tagList
                                                ) &&
                                                    changeItem.data.callTagChange.tagList.map(
                                                        (tag) => {
                                                            return <Tag>{tag.tagName}</Tag>;
                                                        }
                                                    )}
                                            </If>
                                        </span>
                                    </If>
                                </p>
                            </div>
                        </div>
                    </For>
                </div>
            </If>
        );
    }
}
