import { message } from 'udesk-ui';

export default {
    error: function (arg) {
        message.error(getArgs(arg));
    },
    success: function (arg) {
        message.success(getArgs(arg));
    },
};

function getArgs(arg) {
    if (typeof arg === 'string') {
        return { content: arg };
    } else {
        const {message: content, ...props} = arg;
        return {
            content, ...props
        };
    }
}
