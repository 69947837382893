// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostSubjectMarkCallsSearchBody,
    BaseResponseReviewDataCallFieldListWrapper,
} from '../../types';

/**
 * 查询原始通话列表
 *
 * @export
 * @tags 话题标注通话
 * @link [POST] /subjectMarkCalls/search
 */
export function postSubjectMarkCallsSearch(
    data: PostSubjectMarkCallsSearchBody
): Promise<BaseResponseReviewDataCallFieldListWrapper>;
export function postSubjectMarkCallsSearch(
    data: PostSubjectMarkCallsSearchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseReviewDataCallFieldListWrapper>;
export function postSubjectMarkCallsSearch(
    data: PostSubjectMarkCallsSearchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseReviewDataCallFieldListWrapper> {
    return request<PostSubjectMarkCallsSearchBody, never, never>('/subjectMarkCalls/search', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSubjectMarkCallsSearch',
    });
}

export const meta = [{ tags: ['话题标注通话'], path: '/subjectMarkCalls/search', method: 'post' }];
