
import defaultConfig from './config';
import logger from '../../logger';

export default {
    lazyLoad
};

export function lazyLoad(cacheKey, getPromise, options = {}) {
    if (cacheKey && getPromise) {
        options = Object.assign({}, defaultConfig, options);
        createPromise(this, cacheKey, getPromise, options);
    }
}

function createPromise(owner, cacheKey, getPromise, options) {
    Object.defineProperty(owner, cacheKey, {
        get: function () {
            let backingField = "__backingField_" + cacheKey;
            if (owner[backingField] === undefined) {
                //todo: 在Ember不使用专有的Promise是否会报错？
                let promise = new Promise(function (resolve, reject) {
                    getPromise()
                    .then(function (resp, textStatus, jqXHR) {
                        let expire = function () {
                            if (owner._timerToken) {
                                window.clearTimeout(owner._timerToken);
                                owner._timerToken = undefined;
                            }

                            // Clear the data by setting to another promise object.
                            createPromise(owner, cacheKey, getPromise, options);
                        };
                        let reload = function () {
                            this.expire();
                            // 触发一下getter
                            // eslint-disable-next-line
                            owner[cacheKey];
                        };

                        let parseData = options.parseData || defaultParseData;
                        let data = parseData(resp);
                        if (data != null) {
                            // Expose the `expire` method so we should manually expire it when the data was changed outside.
                            data.expire = expire;
                            data.reload = reload;
                        }

                        // Set the real cache data back permanently.
                        delete owner[backingField];
                        Object.defineProperty(owner, cacheKey, {
                            value: data,
                            enumerable: true,
                            configurable: true,
                            writable: false
                        });
                        if (typeof owner.notifyPropertyChange === "function") {
                            owner.notifyPropertyChange(cacheKey);
                        }
                        resolve(data);

                        // Schedule a timer to automatically clear the cache from memory.
                        if (options.timeout > 0) {
                            owner._timerToken = setTimeout(expire, options.timeout);
                        }
                    },
                        function (reason) {
                            delete owner[backingField];
                            logger.error(reason);
                            reject.apply(this, arguments);
                        });
                });

                // Create a dummy `expire` method in case someone calls this method before the data was loaded.
                promise.expire = function () { };
                promise.reload = function () { };
                owner[backingField] = promise;
            }
            return owner[backingField];
        },
        enumerable: true,
        configurable: true
    });
}

function defaultParseData(resp) {
    if (resp) {
        return resp.data;
    } else {
        return null;
    }
}
