export default {
  lang: {
    num: 'No.',
    total: 'Artículo/Total',
    strip: 'Artículo',
    more: 'Más',
    chosen: 'Selectado',
    cancel: 'Cancelar la selección',
    bulkOperations: 'Operación por lotes',
    import: 'Importar',
  },
};
