import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Dialogue, RefType } from 'Component/pages/components/coach/Dialogue';
import { Input } from 'src/pages/coach/learning-center/node-config-test/components/Input';
import { Button, Drawer, Icon, Space } from 'udesk-ui';
import { IntelligentPartnerDialogFlowGlobalConfig, WordsNode } from 'src/api/types';
import { getReviewIntelligentPartnerTestStart } from 'src/api/review/intelligentPartner/test/start';
import { postReviewIntelligentPartnerTest } from 'src/api/review/intelligentPartner/test';
import { getReviewIntelligentPartnerNodePptTestLogByNodeId } from 'src/api/review/intelligentPartner/node/ppt/testLog/{nodeId}';
import { deleteReviewIntelligentPartnerNodePptTestLogClearByNodeId } from 'src/api/review/intelligentPartner/node/ppt/testLog/clear/{nodeId}';
import { getDateTimeString } from 'src/util/moment';
import { createMessage } from 'src/pages/coach/learning-center/components/util';
import { EllipsisSpan } from 'src/util/tag';
import { isOperator } from 'src/util/isOperator';

export type TestRefType = {
    open: (node: any) => void;
}
export default React.forwardRef<TestRefType, any>((props, ref) => {
    const {flowId, evaluationMode, initialValues} = props;
    const [isStarted, setIsStarted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [taskDetail] = useState<any>({
        taskType: 1,
        id: Udesk.utils.string.uuid(),
    });
    const [currentInputType, setCurrentInputType] = useState(1);
    const {initialChatList, start, /* globalConfig, */ referenceWords, send, getHistory} = useChatInput(
        flowId,
        taskDetail,
        initialValues
    );

    const chatRecordRef = useRef<RefType>(null);
    const inputRef = useRef<any>(null);

    const [list, setList] = useState<any[]>([]);
    const [previewItem, setPreviewItem] = useState<any>(null);

    const dialogueSentenceList = useMemo(() => {
        if (previewItem) {
            return [...list, { ...previewItem, content: previewItem.content || /* 收音中... */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.listening }];
        }
        return [...list];
    }, [list, previewItem]);

    const clearChatRecord = useCallback(() => {
        if (!list?.length && !previewItem) return;
        setList([]);
        setPreviewItem(null);
    }, [list?.length, previewItem]);

    const reset = useCallback(() => {
        deleteReviewIntelligentPartnerNodePptTestLogClearByNodeId({
            segments: initialValues
        }).then(clearChatRecord);
    }, [
        clearChatRecord, 
        initialValues
    ]);

    useEffect(() => {
        setList(initialChatList);
    }, [initialChatList]);

    useEffect(() => {
        chatRecordRef.current?.scrollTo(list.length);
    }, [list.length]);

    const [title, setTitle] = useState('');
    useImperativeHandle(ref, () => {
        return {
            open(node) {
                if (isOperator()) {
                    setTitle(node.id + node.data.label);
                } else {
                    setTitle(node.data.label);
                }
                setVisible(visible => !visible);
            }
        };
    });

    useEffect(() => {
        if (visible && isStarted) {
            if (initialValues?.configType === Udesk.enums.pptConfigType.pptConfig.id) {
                setVisible(false);
            } else {
                getHistory()?.then((resp) => {
                    setList((prev) => {
                        let ret = prev.concat(
                            resp.data?.map((item) => {
                                const { resultAnalysis, ...others } = item.logResult?.traineeWordsResult || {};
                                return Object.assign(createMessage(), {
                                    content: item.logResult?.currentWords,
                                    traineeWordsResult: Object.assign(
                                        others,
                                        resultAnalysis
                                    ),
                                });
                            })
                        );
                        return ret;
                    });
                    setPreviewItem(null);
                });
            }
            clearChatRecord();
        }
    }, [initialValues, evaluationMode, visible, isStarted]);

    useEffect(() => {
        if (visible && !isStarted) {
            start();
            setIsStarted(true);
        }
    }, [start, visible, isStarted]);

    return (
        <Drawer
            visible={visible}
            mask={false}
            closable={false}
            width={500}
            style={{ position: 'absolute' }}
            bodyStyle={{ background: '#f5f6f8' }}
            placement={'left'}
            getContainer={document.getElementsByClassName('udesk-qa-web-page')[0] as HTMLElement}
            className="udesk-coach-web-config-drawer beautiful-scrollbar"
            title={
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <EllipsisSpan width={'250px'} style={{whiteSpace: 'nowrap'}}>{/* 幻灯片测试窗口 【 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.pPTTestDrawer.slideTestWindow}{title}】</EllipsisSpan>
                    <Space>
                        <Button onClick={reset}>{/* 清除内容 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.pPTTestDrawer.clearContent}</Button>
                        <Button onClick={() => setVisible(false)}>{/* 关闭 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.pPTTestDrawer.close}</Button>
                    </Space>
                </div>
            }
            footer={
                <Input
                    ref={inputRef}
                    currentInputType={currentInputType}
                    setCurrentInputType={setCurrentInputType}
                    onChange={(newItem) => {
                        send(newItem).then(
                            ({ traineeWordsResult, ajaxParams }) => {
                                const { resultAnalysis, ...others } = traineeWordsResult || {};
                                setList((prev) => {
                                    let ret = prev.concat({
                                        ...newItem,
                                        ajaxParams,
                                        traineeWordsResult: Object.assign(
                                            others,
                                            resultAnalysis
                                        ),
                                    });
                                    return ret;
                                });
                                setPreviewItem(null);
                            }
                        ).finally(() => {
                        });
                    }}
                    onPreviewChange={(newItem) => {
                        if (newItem.content !== previewItem?.content) {
                            setPreviewItem(newItem);
                        }
                    }}
                    onAbort={() => {
                        setPreviewItem(null);
                    }}
                    referenceWords={referenceWords}
                    extra={
                        <Button
                            type="primary"
                            size="small"
                            danger
                            onClick={reset}
                            icon={<Icon antdIcon={true} type="ReloadOutlined" />}
                            tooltip={/* 重新开始 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.index.restart}
                        ></Button>
                    }
                />
            }
        >
            <div className="node-test-page-chat ppt" style={{height: '100%'}}>
                <Dialogue
                    ref={chatRecordRef}
                    dialogueSentenceList={dialogueSentenceList}
                    itemRender={(props, index) => {
                        return (
                            <React.Fragment>
                                <Dialogue.Item
                                    {...props}
                                    // {...scoreDetailProps}
                                    enableScorePointDisplay={true}
                                    enableSpeedWarn={true}
                                    enableWordsViolationWarn={true}
                                    enableEmotionDisplay={true}
                                    index={index}
                                    isTest={true}
                                // isRta={false}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
        </Drawer>
    );
});

const useChatInput = (flowId, taskDetail, selectedNode) => {
    const [initialChatList] = useState<WordsNode[]>([]);
    const [globalConfig, setGlobalConfig] = useState<IntelligentPartnerDialogFlowGlobalConfig | {}>(
        {}
    );
    const nextRequestStaticParams = useRef<any>({});

    const referenceWords = useMemo(() => {
        const { words, scoreRuleList } = selectedNode;
        if (!words) {
            return scoreRuleList?.map((item, index) => `${index + 1}. ${item.words}`).join(``) || '';
        }
        return words;
    }, [
        selectedNode
    ]);

    const start = useCallback(() => {
        if (flowId) {
            getReviewIntelligentPartnerTestStart({
                params: {
                    flowId,
                    imageId: taskDetail.customerPersona?.id,
                },
            }).then((resp) => {
                const { nextNodeList, globalConfig, recordId } = resp.data ?? {};
                nextRequestStaticParams.current = {
                    ...nextNodeList![0],
                    recordId,
                };
                setGlobalConfig(globalConfig ?? {});
            });
        }
    }, [
        flowId,
        taskDetail?.customerPersona?.id
    ]);

    const sendRequest = useCallback((ajaxParams) => {
        return postReviewIntelligentPartnerTest(ajaxParams).then((resp) => {
            const { traineeWordsResult } = resp.data ?? {};
            return {
                ajaxParams,
                traineeWordsResult,
            };
        });
    }, []);

    const send = useCallback((newItem) => {
        const ajaxParams = {
            ...nextRequestStaticParams.current,
            bindNodeId: selectedNode.nodeId,
            dialogueMode: Udesk.enums.learningTaskDialogueModeType.text.id,
            flowId,
            imageId: taskDetail.customerPersona?.id,
            words: newItem.content,
            speakTime: getDateTimeString(),
        };
        return sendRequest(ajaxParams);
    }, [
        flowId,
        selectedNode?.nodeId,
        sendRequest,
        taskDetail?.customerPersona?.id
    ]);

    const refresh = useCallback((props) => {
        nextRequestStaticParams.current = props.ajaxParams;
    }, []);

    const getHistory = useCallback(() => {
        if (selectedNode?.nodeId) {
            return getReviewIntelligentPartnerNodePptTestLogByNodeId({
                segments: selectedNode
            });
        }
        return null;
    }, [
        selectedNode
    ]);

    return {
        start,
        initialChatList,
        globalConfig,
        referenceWords,
        send,
        refresh,
        getHistory,
    };
};
