import React from 'react';

export const Context = React.createContext<any>(undefined);

export const WrongQuestionSetProvider = ({ children, value }) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
};
export const useWrongQuestionSetContext = () => {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error(
            'useWrongQuestionSetContext must be used within a WrongQuestionSetProvider'
        );
    }
    return context;
};
