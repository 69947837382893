// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerHomePageAdminTaskStatisticsQuery,
    BaseResponseListIntelligentPartnerHomePageAdminTaskStatisticsResponse,
} from '../../../../types';

/**
 * 管理员端任务统计数据
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [GET] /intelligentPartner/homePage/admin/taskStatistics
 */
export function getIntelligentPartnerHomePageAdminTaskStatistics(): Promise<BaseResponseListIntelligentPartnerHomePageAdminTaskStatisticsResponse>;
export function getIntelligentPartnerHomePageAdminTaskStatistics(
    options: ApiOptions<never, GetIntelligentPartnerHomePageAdminTaskStatisticsQuery>
): Promise<BaseResponseListIntelligentPartnerHomePageAdminTaskStatisticsResponse>;
export function getIntelligentPartnerHomePageAdminTaskStatistics(
    options?: ApiOptions<never, GetIntelligentPartnerHomePageAdminTaskStatisticsQuery>
): Promise<BaseResponseListIntelligentPartnerHomePageAdminTaskStatisticsResponse> {
    return request<never, never, GetIntelligentPartnerHomePageAdminTaskStatisticsQuery>(
        '/intelligentPartner/homePage/admin/taskStatistics',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerHomePageAdminTaskStatistics',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/admin/taskStatistics',
        method: 'get',
    },
];
