import React from 'react';
// import Locales from 'UdeskLocales';
import { Avatar, Checkbox } from 'udesk-ui';
import styled from 'styled-components';
import { IntelligentPartnerCustomerPersonaFoundResponse } from 'src/api/types';

const Div = styled.div`
    display: inline-block;
    cursor: pointer;
    position: relative;
    > .customer-portrait-item-checkbox {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: baseline;
        height: 19px;
        overflow: hidden;
        
        .udesk-qa-ui-checkbox-wrapper {
            margin: 0 !important;
        }
    }
`;
interface CustomerPortraitItemProps extends IntelligentPartnerCustomerPersonaFoundResponse {
    selected?: boolean;
    onClick?: () => void;
    onChange?: (checked?: boolean) => void;
}

export const CustomerPortraitItem: React.FC<CustomerPortraitItemProps> = (props) => {
    // const locales = Locales['current'];
    const { name, cover, onClick, selected, onChange } = props;

    return (
        <Div onClick={onClick || (() => onChange?.(true))}>
            <Avatar
                src={cover}
                style={{ backgroundColor: '#fde3cf', color: '#f56a00', verticalAlign: 'middle' }}
                size="large"
                alt={name}
            >
                {name}
            </Avatar>
            <div className="customer-portrait-item-checkbox" onClick={(e) => e.stopPropagation()}>
                <Checkbox
                    checked={selected}
                    onChange={(e) => {
                        onChange?.(e.target.checked);
                    }}
                />
            </div>
        </Div>
    );
};
