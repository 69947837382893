import React, { useState } from 'react';
import { Icon, Button } from 'udesk-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Locales from 'UdeskLocales';

export const CopyButton = (props) => {
    const { text } = props;
    const locales = Locales['current'];

    const [copied, setCopied] = useState(false);
    const copyText = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };
    return (
        <CopyToClipboard text={text} onCopy={copyText}>
            <Button
                type="text"
                size="small"
                tooltip={
                    copied
                        ? locales.labels.copyed
                        : /* 点击复制 */ locales.components.qualityScore.index.clickCopy
                }
                icon={<Icon type="CopyOutlined" antdIcon={true} />}
                style={{ height: 22 }}
            ></Button>
        </CopyToClipboard>
    );
};
