import initReactTable from './init-react-table';
import initUploadAdapter from "./init-upload-adapter";
import initAjax from './init-ajax';
export default {
    run
};

function run() {
    initAjax();
    initReactTable();
    initUploadAdapter();
}