import React from 'react';
import { Button, notification } from 'udesk-ui';
import Locales from 'UdeskLocales';

const importSuccessNotification = () => {
    let locales = Locales['current'];

    const goToDownloadCenter = () => {
        window.open('/site/admin/task-center/upload-list');
    };

    notification.success({
        key: 'exportNotification',
        message: locales.labels.importTaskCreated,
        description: (
            <div>
                {locales.labels.plzGoTo}
                <Button prefixCls='udesk-qa-ui-btn' type='link' size='small' onClick={goToDownloadCenter}>
                    {locales.pages.uploadCenter.name}
                </Button>
                {locales.labels.view}
            </div>
        ),
    });
};

export default importSuccessNotification;
