import React, { RefObject, useRef, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import importSuccessNotification from 'Component/common/import-notification';
import { inspect } from 'src/util/core';
import { Modal } from 'udesk-ui';
// import './style.scss';

let locales = Locales['current'];

const useImportModal = ({
    request,
    formatParams,
    accept = ['xlsx'],
    isShowImportSuccessNotification = true,
    isShowImportSuccessMessage = true,
}) => {
    const [isUploadVisible, setIsUploadVisibleVisible] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [uploadData, setUploadData] = useState<any>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const [uploadLoading, setUploadLoading] = useState(false);

    const uploadFile = (e) => {
        const uploadFileSuccess = (file, uploadData) => {
            setFile(file);
            setUploadData(uploadData);
        };
        const uploadFileError = (file, uploadData) => {};
        let files = e.target.files;
        if (files.length === 0) {
            return;
        }
        // 取消文件大小限制
        // if (files[0] && (files[0].size > 2 * 1024 * 1024)) {
        //     return Udesk.ui.notify.error(UDL.business.notifyMessage.overFileSize);
        // }
        let fileName = fileType(files[0].name);
        if (!fileName) {
            return;
        } else if (!accept.includes(fileName)) {
            return Udesk.ui.notify.error(
                Udesk.utils.string.format(locales.labels.fileTypeHolder, accept.join())
            );
        }
        //type 1语音,2导入,3导出,4语料数据
        Udesk.app.fileStorage.upload(files[0], {
            token: new Promise((resolve, reject) => {
                Udesk.business
                    .getServerAuthorization({
                        type: 2,
                    })
                    .then(
                        function (resp) {
                            resolve(resp);
                        },
                        function (reason) {
                            reject(reason);
                        }
                    );
            }),
            //progressCallback: progressCallback.bind(this),
            successCallback: uploadFileSuccess.bind(this),
            errorCallback: uploadFileError.bind(this),
        });
    };
    const handleOnOk = () => {
        if (file == null || uploadData == null) {
            return Udesk.ui.notify.error(locales.business.notifyMessage.pleaseUploadFile);
        }
        setUploadLoading(true);
        return request?.(formatParams(file, uploadData))
            .then(
                (resp) => {
                    if (isShowImportSuccessMessage) {
                        Udesk.ui.notify.success(locales.business.notifyMessage.importFileSuccess);
                    }

                    setIsUploadVisibleVisible(false);
                    if (isShowImportSuccessNotification) {
                        importSuccessNotification();
                    }
                    return resp;
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || locales.business.notifyMessage.importFildFailed
                    );
                    setIsUploadVisibleVisible(false);
                }
            )
            .finally(() => {
                setUploadLoading(false);
            });
    };
    const handleOnCancel = () => setIsUploadVisibleVisible(false);
    const showImportModal = () => setIsUploadVisibleVisible(true);
    return {
        fileRef,
        handleOnOk,
        uploadFile,
        isUploadVisible,
        handleOnCancel,
        file,
        showImportModal,
        clearFile() {
            if (fileRef.current) {
                fileRef.current.value = ''; // 解决不能重复选取同一个文件的问题
            }
            setFile(null);
        },
        uploadLoading,
    };
};

interface ImportModalProps {
    visible: boolean;
    onCancel: () => void;
    onOk: () => void;
    uploadFile: (e: any) => void;
    file: any;
    downloadTemplate?: () => void;
    title: string;
    tip3?: string;
    fileRef?: RefObject<HTMLInputElement>;
    [key: string]: unknown;
}
type ImportModalType = React.FC<ImportModalProps> & {
    useImportModal: typeof useImportModal;
};

const ImportModal: ImportModalType = (props) => {
    const {
        children,
        visible,
        onCancel,
        onOk,
        uploadFile,
        file,
        downloadTemplate,
        title = '',
        tip3 = locales.labels.uploadIdentical,
        fileRef,
        ...restProps
    } = props;

    return (
        <Modal visible={visible} title={title} onCancel={onCancel} onOk={onOk} {...restProps}>
            <div className="udesk-qa-modal-body-content">
                <div className="form-group">
                    <label>
                        {locales.labels.uploadFile}
                        {locales.labels.colon}
                    </label>
                    <div className="upload-file-button-container">
                        <input
                            ref={fileRef}
                            type="file"
                            className="upload-file-inpiut"
                            onChange={uploadFile}
                        />
                        <button type="button" className="upload-file-button btn btn-sm btn-primary">
                            {locales.labels.upload}
                        </button>
                    </div>
                    {Boolean(file && file.name) && (
                        <span className="upload-file-name">{file.name}</span>
                    )}
                </div>
                {inspect(
                    children,
                    () => children,
                    () => (
                        <>
                            <div className="form-group">
                                {locales.labels.numOne}
                                {locales.labels.colon}&nbsp;&nbsp;
                                {locales.labels.click}&nbsp;&nbsp;
                                <span className="upload-file-template" onClick={downloadTemplate}>
                                    {locales.labels.downloadTemplate}
                                </span>
                                &nbsp;&nbsp;
                                {locales.labels.fillInTheForm}
                            </div>
                            <div className="form-group">
                                {locales.labels.numTwo}
                                {locales.labels.colon}&nbsp;&nbsp;
                                {locales.labels.UploadExcelfile}
                            </div>
                            <div className="form-group">
                                {locales.labels.numThree}
                                {locales.labels.colon}&nbsp;&nbsp;
                                {tip3}
                            </div>
                        </>
                    )
                )}
            </div>
        </Modal>
    );
};

ImportModal.useImportModal = useImportModal;

export default ImportModal;

function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
