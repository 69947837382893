let id = 1;
export const getId = () => id++;

export function fire(event, ...args) {
    if (typeof event === 'function') {
        return event.apply(this, args);
    }
    return event;
}

export function inspect (handle, success, fail) {
    success = success === undefined ? true : success;
    fail = fail === undefined ? false : fail;
    handle = fire.call(this, handle);
    return fire.call(
        this,
        handle ? success : fail
    );
}

export function array2tree(list, filter, id, pId, children, parent) {
    let i = 0,
        l = list.length;
    if (!list || l === 0) {
        return [];
    }

    let tree = [],
        tmp = {};

    if (typeof filter === 'string') {
        parent = children;
        children = pId;
        pId = id;
        id = filter;
        filter = !1;
    }

    id = id || 'id';
    pId = pId || 'parentId';
    children = children || 'children';

    if (filter) {
        for (i = 0; i < l; i++) {
            if (filter(list[i])) {
                tmp[list[i][id]] = list[i];
            }
        }
    } else {
        for (i = 0; i < l; i++) {
            tmp[list[i][id]] = list[i];
        }
    }

    for (i = 0; i < l; i++) {
        if (tmp[list[i][id]]) {
            if (tmp[list[i][pId]]) {
                (tmp[list[i][pId]][children] =
                    (parent ? (list[i][parent] = tmp[list[i][pId]]) : tmp[list[i][pId]])[children] || []).push(list[i]);
            } else {
                tree.push(list[i]);
            }
        }
    }
    return parent ? { list: tree, cache: tmp } : tree;
}


/**
 * 递归遍历列表及其子孙列表
 * @param list
 * @param callback
 * @param recursive 获取子孙列表的函数，如果函数存在则代表递归
 */
export function posterity (list, callback, recursive, result = [], index = 0) {
    if (Array.isArray(list)) {
        list.forEach((node, indexOfList) => {
            result.push({
                index: index++,
                data: node,
                result: fire(callback, node, indexOfList, list)
            });
            if (recursive) {
                posterity(recursive(node, indexOfList, list), callback, recursive, result, index);
            }
        });
    }

    return result;
}

export function createClassName (...names) {
    if (names) {
        return names.filter(item => isFunction(item) || isString(item) || isPlainObject(item)).map(item => {
            let data = fire(item);
            if (isPlainObject(data)) {
                data = Reflect.ownKeys(data).filter(key => !!Reflect.get(data, key)).join(' ');
            }
            return data;
        }).join(' ');
    }
    return '';
}


export function getPropByPath(dataSource, path = '', strict = false) {
    let temp = dataSource;
    path = path.replace(/\[(\w+)\]/g, '.$1');
    path = path.replace(/^\./, '');
    path = path.replaceAll(/\w*\.\./g, '');

    let keyArr = path.split('.');
    let index = 0;

    for (let length = keyArr.length; index < length - 1; ++index) {
        if (!temp && !strict) break;
        let key = keyArr[index];
        if (key in temp) {
            temp = temp[key];
        } else {
            if (strict) {
                throw new Error('please transfer a valid prop path to form item!');
            }
            break;
        }
    }
    return {
        data: temp,
        key: isEmpty(path, () => path, () => keyArr[index]),
        value: isEmpty(path, () => temp, () => temp ? temp[keyArr[index]] : null)
    };
}

export function setPropValue(dataSource, path, value, strict = false) {
    const {data, key, value: oldValue} = getPropByPath(dataSource, path, strict);
    if (data) {
        Reflect.set(data, key, value);
    } else {
        value = oldValue;
    }
    return {
        data, key, value 
    };
}

export function getType (value) {
    return ({}).toString.call(value).replace(/\[object (.+)\]/i, '$1').toLowerCase();
}

export function isBoolean (value, successHandle, errorHandle) {
    return inspect(getType(value) === 'boolean', successHandle, errorHandle);
}

export function isFunction (value, successHandle, errorHandle) {
    return inspect(getType(value) === 'function', successHandle, errorHandle);
}

export function isString (value, successHandle, errorHandle) {
    return inspect(getType(value) === 'string', successHandle, errorHandle);
}

export function isNumber (value, successHandle, errorHandle) {
    return inspect(getType(value) === 'number' && !isNaN(value), successHandle, errorHandle);
}

export function isSet (value, successHandle, errorHandle) {
    return inspect(getType(value) === 'set', successHandle, errorHandle);
}

export function isMap (value, successHandle, errorHandle) {
    return inspect(getType(value) === 'map', successHandle, errorHandle);
}

export function isArray (value, successHandle, errorHandle) {
    return inspect(getType(value) === 'array', successHandle, errorHandle);
}

export function isPlainObject (value, successHandle, errorHandle) {
    return inspect(getType(value) === 'object', successHandle, errorHandle);
}

export function isNull (value, successHandle, errorHandle) {
    return inspect(value === null, successHandle, errorHandle);
}

export function isUndefined (value, successHandle, errorHandle) {
    return inspect(value === undefined, successHandle, errorHandle);
}

export function isDefined (value, successHandle, errorHandle) {
    return inspect(!isNull(value) && !isUndefined(value), successHandle, errorHandle);
}

export function isEmpty (value, successHandle, errorHandle) {
    return inspect(
        isDefined(
            value, 
            () => {
                if (isString(value) || isArray(value)) {
                    return value.length === 0;
                }
                if (isSet(value) || isMap(value)) {
                    return value.size === 0;
                }
                if (isPlainObject(value)) {
                    return Reflect.ownKeys(value).length === 0;
                }
                if (isNaN(value)) {
                    return true;
                }
                return false;
            }, 
            true
        ),
        successHandle, 
        errorHandle
    );
}

export function isNotEmpty (value, successHandle, errorHandle) {
    return isEmpty(value, errorHandle || false, successHandle || true);
}