import React, { useState } from 'react';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import CustomFilterEdit from 'Component/pages/components/custom-filter-edit';

export const CustomFilter = (props) => {
    const { onChange, type, filterList } = props;
    const [filterVisible, setFilterVisible] = useState(false);
    const [customId, setCustomId] = useState<number>();
    const [customName] = useState('');

    const cancelHandle = () => {
        setFilterVisible(false);
    };

    const handleConditionApply = (conditionList, customJudgeLogic, judgeStrategy, id, name) => {
        onChange({
            conditionList,
            customJudgeLogic,
            judgeStrategy,
        });
        cancelHandle();
    };

    const onConditionFilterSaved = (flag, data) => {
        if (data) {
            setCustomId(data.id);
        }
    };

    return (
        <div>
            <CustomFilterModal
                visible={filterVisible}
                cancel={cancelHandle}
                applyAction={handleConditionApply}
                onConditionFilterSaved={onConditionFilterSaved}
                judgeStrategy={props.value?.judgeStrategy}
                customJudgeLogic={props.value?.customJudgeLogic}
                customName={customName}
                customId={customId}
                conditionList={props.value?.conditionList ?? []}
                type={type}
                // 因为是新的接口，这里无需传参，保证有值即可
                querryType={'empty'}
                filterList={filterList ?? ['empty']}
                filterTaskData={true}
                isAutoHideOperator={true}
            />
            <CustomFilterEdit
                cusBtnProps={{ type: 'default', icon: null }}
                onClick={() => setFilterVisible(true)}
                {...props.value}
            />
            {/* {Array.isArray(props.value?.conditionList) && props.value?.conditionList.length ? (
                <div>111</div>
            ) : (
                <Button onClick={() => setFilterVisible(true)}>123</Button>
            )} */}
        </div>
    );
};
