import React, { useMemo } from 'react';
import { PageHeader, Spin } from 'udesk-ui';
import type { PageHeaderProps } from 'udesk-ui/lib/page-header';
import './style.scss';
interface LayoutProps extends PageHeaderProps {
    pageBodyClassName: string;
    padding?: boolean | number;
    loading?: boolean;
    loadingTip?: string;
    /**
     * backGroundGhost 页面背景色是否为无色(transparent)
     */
    backGroundGhost?: boolean;
    footerRender?: () => React.ReactElement;
    /**
     * isRouteEntry 是否是路由引导页面
     */
    isRouteEntry?: boolean;
    pageClassName?: string;
    bodyStyle?: React.CSSProperties;
    children?: any;
}
const Layout: React.FC<LayoutProps> = (props) => {
    const {
        children,
        padding = false,
        pageBodyClassName,
        loading = false,
        loadingTip,
        backGroundGhost = false,
        footerRender,
        isRouteEntry = false,
        pageClassName = '',
        bodyStyle,
        ...pageHeaderProps
    } = props;
    const hasPadding = Boolean(padding);
    const styles = useMemo(() => {
        let ret: React.CSSProperties = {};
        if (typeof padding === 'number') {
            ret.padding = padding;
        }
        if (backGroundGhost) {
            ret.backgroundColor = 'transparent';
        }
        if (isRouteEntry) {
            ret.height = 'unset';
        }
        if (bodyStyle) {
            ret = { ...ret, ...bodyStyle };
        }
        return ret;
    }, [padding, backGroundGhost, isRouteEntry, bodyStyle]);
    const hasStyle = typeof padding === 'number' || backGroundGhost === true || isRouteEntry === true;
    const hasFooter = typeof footerRender === 'function';
    const showPageHeader = useMemo(() => {
        return props.title;
    }, [props.title]);
    return (
        <div className={`udesk-qa-web-page ${pageClassName}`}>
            {showPageHeader && <PageHeader className='udesk-qa-web-page-header' {...pageHeaderProps} />}
            <div className='udesk-qa-web-page-body'>
                <div
                    className={`udesk-qa-web-page-body-root ${pageBodyClassName}${hasPadding ? ' with-padding' : ''}`}
                    style={hasStyle ? styles : undefined}
                >
                    <Spin
                        wrapperClassName='udesk-qa-web-page-body-root-spin'
                        spinning={loading}
                        tip={loadingTip as any}
                    >
                        <div className={`udesk-qa-web-page-content${hasFooter ? '-with-footer' : ''}`}>{children}</div>
                        {hasFooter && <div className='udesk-qa-web-page-finxed-footer'>{footerRender?.()}</div>}
                    </Spin>
                </div>
            </div>
        </div>
    );
};
export default Layout;
