import React from 'react';
import PropTypes from 'prop-types';
import Udesk from "../../udesk/index";
import ClassNames from 'classnames';

export const ENABLE_RELOAD_BUTTON_SYMBOL = Symbol(`enableReloadButton`);

class NewVersion extends React.Component {

    [ENABLE_RELOAD_BUTTON_SYMBOL](reloadButtonDisabled = false) {
        this.privates.reloadButtonDisabled = reloadButtonDisabled;
        this.actions.update();
    }

    privates = {
        reloadButtonDisabled: false
    }

    static computes = {
        message: ({ props, state, privates, locales }) => {
            let message = props.message;
            if (message != null && message !== "") {
                return message;
            }
            return locales.components.newVersion.versionUpdateText;
        },
        reloadButtonText: ({ props, state, privates, locales }) => {
            let reloadButtonText = props.reloadButtonText;
            if (reloadButtonText != null && reloadButtonText !== "") {
                return reloadButtonText;
            }
            return locales.components.newVersion.reloadNewVersion;
        }
    }

    actions = {
        onReload() {
            if (this.props.autoReloadButtonDisabled) {
                this.privates.reloadButtonDisabled = true;
                this.actions.update();
            }
            if (this.props.onReload != null) {
                this.props.onReload();
            }
        },

        onClose() {
            if (this.props.onClose != null) {
                this.props.onClose();
            }
        }
    };

    render() {
        if (!this.props.isVisible) {
            return null;
        }
        let {
            classNames,
            showReloadButton,
            enableClose,
            closeIconClass,
            reloadButtonClass
        } = this.props;
        let {
            privates: { computes, reloadButtonDisabled }
        } = this;
        return (
            <div className={ClassNames("udesk-component-new-version", { [`${classNames}`]: classNames })}>
                {computes.message}
                <If condition={enableClose}>
                    <a className="new-version-close" onClick={this.actions.onClose}>
                        <i className={ClassNames({ [`${closeIconClass}`]: closeIconClass })}></i>
                    </a>
                </If>
                <If condition={showReloadButton}>
                    <button disabled={reloadButtonDisabled} className={ClassNames("new-version-button", { [`${reloadButtonClass}`]: reloadButtonClass, "disabled": reloadButtonDisabled })} onClick={this.actions.onReload}>{computes.reloadButtonText}</button>
                </If>
            </div>
        );
    }
}

NewVersion.propTypes = {
    classnames: PropTypes.string,
    isVisible: PropTypes.bool,
    message: PropTypes.string,
    autoReloadButtonDisabled: PropTypes.bool,
    showReloadButton: PropTypes.bool,
    enableClose: PropTypes.bool,
    closeIconClass: PropTypes.string,
    reloadButtonText: PropTypes.string,
    reloadButtonClass: PropTypes.string,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

NewVersion.defaultProps = {
    classNames: "",
    isVisible: false,
    message: "",
    autoReloadButtonDisabled: false,
    reloadButtonClass: "",
    showReloadButton: true,
    enableClose: false,
    closeIconClass: "",
    reloadButtonText: "",
    onClose: null,
    onReload: null
};

export default Udesk.react.udeskify(NewVersion);