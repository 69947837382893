import React from 'react';
import ImageViewerComponent from './component';

export default class ImageViewerTemplate extends ImageViewerComponent {
    render() {
        return (
            <div className={"udesk-image-viewer"} ref={this.privates.ownContainer}>
                {this.props.children}
            </div>
        );
    }
}