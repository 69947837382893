import React, { useCallback, useEffect, useState } from 'react';
import Locales from 'UdeskLocales';
import { isDefined, isNumber } from 'src/util/core';
import { Radio, Space } from 'udesk-ui';
import { UserTreeSelect } from 'src/util/hook/user';
import './index.less';

const ManualReviewConfig = (props) => {
    const locales = Locales['current'];
    const { value, onChange, treeData } = props;
    const [treeDataForRadio, setTreeDataForRadio] = useState<any[]>([]);
    const [type, setType] = useState(1);
    const [valueForRadio, setValueForRadio] = useState();
    const [valueForCheckbox, setValueForCheckbox] = useState();

    const changeHandle = useCallback((value) => {
        let selectTreeValue = value;
        if (type === 2) {
            selectTreeValue = {
                userIds: [value]
            };
        }
        onChange({
            type, ...selectTreeValue
        });
    }, [onChange, type]);

    const radioChangeHandle = useCallback((e) => {
        const type = e.target.value;
        const value = type === 1 ? valueForCheckbox : {
            userIds: isDefined(valueForRadio) ? [valueForRadio] : []
        };
        onChange({
            ...(value || {}), type
        });
    }, [onChange, valueForCheckbox, valueForRadio]);

    useEffect(() => {
        if (value) {
            const {type} = value;
            if (type === 1) {
                setValueForCheckbox(value || undefined);
            }
            if (type === 2) {
                setValueForRadio(value?.userIds?.[0] || undefined);
            }
            setType(type);
        }
    }, [value]);

    useEffect(() => {
        if (treeData?.length > 0) {
            setTreeDataForRadio(treeData.map((node) => {
                if (isNumber(node.id)) {
                    return {
                        ...node, selectable: false
                    };
                }
                return node;
            }));
        }
    }, [treeData]); 

    return (
        <Radio.Group onChange={radioChangeHandle} value={type}>
            <Space>
                <Space direction="vertical">
                    <Radio value={1}>
                        <span>{/* 随机平均分配 */}{Locales['current'].pages.coach.taskCenter.components.manualReviewConfig.index.randomAverageDistribution}</span>
                    </Radio>
                    <UserTreeSelect {...{
                        disabled: type === 2,
                        width: 400,
                        multiple: true,
                        treeData,
                        onChange: changeHandle,
                        value: valueForCheckbox,
                        showSearch: true,
                        allowClear: true,
                        showArrow: true,
                        maxTagCount: 3,
                        placeholder: locales.labels.pleaseSelect,
                        showCheckedStrategy: "SHOW_All",
                        treeDefaultExpandAll: false,
                        treeCheckable: true,
                    }}/>
                    
                </Space>
                <Space direction="vertical">
                    <Radio value={2}>
                        <span>{/* 分配给指定人员 */}{Locales['current'].pages.coach.taskCenter.components.manualReviewConfig.index.assignToDesignatedPersonnel}</span>
                    </Radio>
                    <UserTreeSelect {...{
                        disabled: type === 1,
                        width: 400,
                        treeData: treeDataForRadio,
                        onChange: changeHandle,
                        value: valueForRadio,
                        showSearch: true,
                        allowClear: true,
                        showArrow: true,
                        maxTagCount: 3,
                        placeholder: locales.labels.pleaseSelect,
                        treeDefaultExpandAll: false,
                    }}/>
                </Space>
            </Space>
        </Radio.Group>
    );
};

export default ManualReviewConfig;
