import React from 'react';
import SmartTagsComponent from './component';
import './style.scss';
import { Tag, Dropdown, Popconfirm, Icon, Tooltip, Button, Menu } from 'udesk-ui';
import SmartTagsList from '../smart-tags-list';
import UdeskLocales from 'UdeskLocales';

export default class SmartTagsTemplate extends SmartTagsComponent {
    render() {
        let { smartTags, smartTagsTitle, disabled, noStayle, selectMode, onRedoSmartTags } =
            this.props;
        let { locales, privates } = this;
        const text = (
            <span>
                <Icon type="kehu" />
                {locales.business.notifyMessage.isHandAddSmartTag}
            </span>
        );

        return (
            <div
                className={`smart-tags-component ${
                    noStayle ? 'smart-tags-component-no-style' : ''
                }`}
            >
                {!noStayle && (
                    <div className="smart-tags-title">
                        <div>{smartTagsTitle}</div>
                        <Tooltip placement="right" title={text}>
                            <Icon
                                type="QuestionCircleOutlined"
                                style={{ marginLeft: 8, color: 'rgba(0, 0, 0, .45)' }}
                                antdIcon={true}
                            />
                        </Tooltip>
                    </div>
                )}
                <div
                    className={`smart-tags-content ${
                        noStayle ? 'smart-tags-content-no-style' : ''
                    }`}
                >
                    <If condition={smartTags && smartTags.length > 0}>
                        <For each="smartTag" index="index" of={smartTags}>
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.actions.onAddToIgnore(smartTag)}
                                            >{/* 添加至忽略标签 */}{UdeskLocales['current'].components.smartTags.template.addToIgnoreTags}</div>
                                        </Menu.Item>
                                    </Menu>
                                }
                                placement="topLeft"
                                disabled={!smartTag.modelCallId}
                            >
                                <Popconfirm
                                    visible={privates.closeId === smartTag.id}
                                    onVisibleChange={this.actions.onCloseVisibleChange}
                                    onCancel={this.actions.onCancel}
                                    onConfirm={this.actions.samrTagClose.params(smartTag)}
                                    title={locales.labels.deletePopconfirmContent}
                                    okText={locales.labels.delete}
                                    cancelText={locales.labels.cancel}
                                >
                                    <Tag
                                        key={index}
                                        icon={
                                            smartTag.isEnableModel ? (
                                                <Icon type="CodeSandboxOutlined" antdIcon />
                                            ) : (
                                                <Icon type="a-peoplegroup-1-qunzu2" />
                                            )
                                        }
                                        color={
                                            smartTag.tagColor
                                                ? smartTag.tagColor + '1a'
                                                : smartTag.tagType === 1
                                                ? 'rgba(239,235,252,1)'
                                                : smartTag.tagType === 2
                                                ? 'rgba(232,244,255,1)'
                                                : undefined
                                        }
                                        closable={smartTag.tagType === 1 && !disabled}
                                        onClose={this.actions.onClose.params(smartTag)}
                                        style={{
                                            color: smartTag.tagColor || smartTag.categoryColor,
                                            cursor: 'pointer',
                                            //     background:
                                            //         smartTag.tagType === 1
                                            //             ? 'rgba(239,235,252,1)'
                                            //             : smartTag.tagType === 2
                                            //             ? 'rgba(232,244,255,1)'
                                            //             : 'none',
                                        }}
                                        title={smartTag.categoryName || smartTag.tagName}
                                        onClick={this.actions.samrTagChange.params(smartTag)}
                                    >
                                        <div className="smart-tags">
                                            {smartTag.markType && <Icon type="kehu" />}
                                            {smartTag.categoryName || smartTag.tagName}
                                        </div>
                                    </Tag>
                                </Popconfirm>
                            </Dropdown>
                        </For>
                    </If>

                    {!disabled && (
                        <Dropdown
                            onVisibleChange={this.actions.onVisibleChange}
                            visible={privates.visible}
                            overlay={
                                <SmartTagsList
                                    markedSmartTags={smartTags || []}
                                    smartTagsList={privates.smartTagsList}
                                    onSelect={this.actions.samrTagAdd}
                                    selectMode={selectMode}
                                />
                            }
                            trigger={['click']}
                        >
                            <Tag className="smart-tags-add" style={{ cursor: 'pointer' }}>
                                {this.locales.labels.samrTagAdd}
                            </Tag>
                        </Dropdown>
                    )}

                    {onRedoSmartTags && (
                        <Popconfirm
                            title={
                                /* 确定要重新执行智能标签吗？ */ UdeskLocales['current'].components
                                    .smartTags.template.areYouSureYouWantToReExecuteTheSmartLabel
                            }
                            onConfirm={onRedoSmartTags}
                        >
                            <Button
                                icon={<Icon type="RedoOutlined" antdIcon={true} />}
                                tooltip={
                                    /* 重新执行 */ UdeskLocales['current'].components.smartTags
                                        .template.reExecute
                                }
                                size="small"
                                type="text"
                                style={{
                                    height: '22px',
                                    width: '22px',
                                    marginBottom: '5px',
                                }}
                            ></Button>
                        </Popconfirm>
                    )}
                </div>
            </div>
        );
    }
}
