import React, { useEffect, useState, useMemo } from 'react';
import { Button, Form, Input, Modal, Popconfirm, Space } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { getWecomMessageFilters, postWecomMessageFilters } from 'src/api/wecomMessageFilters';
import {
    getWecomMessageFiltersById,
    putWecomMessageFiltersById,
    deleteWecomMessageFiltersById,
} from 'src/api/wecomMessageFilters/{id}';
import { WecomMessageFilterFoundResponse } from 'src/api/types';

function ClientManage(props) {
    const locales = UdeskLocales['current'];

    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [refresh, needRefresh] = useState(1);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<WecomMessageFilterFoundResponse[]>([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setLoading(true);
        getWecomMessageFilters({
            params: {
                pageNum,
                pageSize,
            },
        }).then(
            (resp) => {
                setLoading(false);
                setDataSource(resp.data || []);
                if (resp.paging) {
                    const { pageNum, pageSize, total } = resp.paging;
                    setPageNum(pageNum || 1);
                    setPageSize(pageSize || 20);
                    setTotal(total || 0);
                }
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [pageNum, pageSize, props.sdkOptions, refresh]);

    const [loadingDelete, setLoadingDelete] = useState(false);

    const [form] = Form.useForm();

    const [curId, setCurId] = useState();

    const columns = useMemo(() => {
        return [
            {
                key: 'content',
                dataIndex: 'content',
                title: /* 消息内容 */ UdeskLocales['current'].pages.wechatQa.setting
                    .messageFiltering.template.messageContent,
                width: '25%',
            },
            {
                key: 'id',
                dataIndex: 'id',
                title: /* 消息类型ID */ UdeskLocales['current'].pages.wechatQa.setting
                    .messageFiltering.template.messageTypeID,
                width: '25%',
            },
            {
                key: 'description',
                dataIndex: 'description',
                title: /* 消息类型描述 */ UdeskLocales['current'].pages.wechatQa.setting
                    .messageFiltering.template.messageTypeDescription,
                width: '25%',
            },
            {
                key: 'actions',
                // dataIndex: 'actions',
                title: locales.columns.tasks.manage.template.checkPoint.actions,
                align: 'center' as any,
                render: (name, item, index) => {
                    return (
                        <div>
                            <Space>
                                {/* {hasFeature && hasFeature('task:template:point:edit') && ( */}
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        getWecomMessageFiltersById({
                                            segments: { id: item.id },
                                        }).then((res) => {
                                            setVisible(true);
                                            form.setFieldsValue(res.data || {});
                                            setCurId(item.id);
                                        });
                                    }}
                                >
                                    {locales.labels.edit}
                                </Button>
                                {/* )} */}
                                {/* {hasFeature && hasFeature('task:template:point:delete') && ( */}
                                <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={() => {
                                        setLoadingDelete(true);
                                        deleteWecomMessageFiltersById({
                                            segments: { id: item.id },
                                        }).then(
                                            (resp) => {
                                                setLoadingDelete(false);
                                                needRefresh((prev) => prev + 1);
                                            },
                                            (reason) => {
                                                Udesk.ui.notify.error(reason.errorMsg);
                                                setLoadingDelete(false);
                                            }
                                        );
                                    }}
                                    onCancel={(e) => e!.stopPropagation()}
                                    okText={locales.labels.confirm}
                                    okButtonProps={{
                                        loading: loadingDelete,
                                    }}
                                    placement="topRight"
                                    cancelText={locales.labels.cancel}
                                >
                                    <Button danger type="link" size="small">
                                        {locales.labels.delete}
                                    </Button>
                                </Popconfirm>
                                {/* )} */}
                            </Space>
                        </div>
                    );
                },
            },
        ];
    }, [
        form,
        loadingDelete,
        locales.columns.tasks.manage.template.checkPoint.actions,
        locales.labels.cancel,
        locales.labels.confirm,
        locales.labels.delete,
        locales.labels.deleteContent,
        locales.labels.edit,
    ]);

    const tableProps = useMemo(() => {
        return {
            columns,
            dataSource,
            subtractedHeight: 136,
        };
    }, [columns, dataSource]);

    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPageNum(page);
                setPageSize(pageSize);
            },
        };
    }, [pageNum, pageSize, total]);

    const [visible, setVisible] = useState(false);

    const createBtn = useMemo(() => {
        const a = () => {
            setVisible(true);
            setCurId(undefined);
        };
        return <Button onClick={a}>{locales.labels.create}</Button>;
    }, [locales.labels.create]);

    const onCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const onOk = () => {
        form.validateFields().then((values) => {
            curId
                ? putWecomMessageFiltersById(values, { segments: { id: curId } }).then(() => {
                      onCancel();
                      needRefresh((prev) => prev + 1);
                  })
                : postWecomMessageFilters(values).then(() => {
                      onCancel();
                      needRefresh((prev) => prev + 1);
                  });
        });
    };

    return (
        <Page
            pageBodyClassName="real-time-analysis-page-index"
            title={
                /* 消息过滤 */ UdeskLocales['current'].pages.wechatQa.setting.messageFiltering
                    .template.messageFiltering
            }
            subTitle={
                /* 适用于过滤无需质检的数据 */ UdeskLocales['current'].pages.wechatQa.setting
                    .messageFiltering.template.suitableForFilteringDataWithoutQualityInspection
            }
            padding={true}
        >
            <CommonTablePage loading={loading} mainButton={createBtn}>
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>

            <Modal
                visible={visible}
                title={
                    curId
                        ? /* 编辑 */UdeskLocales['current'].pages.wechatQa.setting.messageFiltering.template.edit
                        : /* 创建 */ UdeskLocales['current'].pages.wechatQa.setting.messageFiltering
                              .template.create
                }
                onCancel={onCancel}
                onOk={onOk}
                destroyOnClose
            >
                <Form form={form}>
                    <Form.Item
                        label={
                            /* 消息内容 */ UdeskLocales['current'].pages.wechatQa.setting
                                .messageFiltering.template.messageContent
                        }
                        name="content"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={
                            /* 消息描述 */ UdeskLocales['current'].pages.wechatQa.setting
                                .messageFiltering.template.messageDescription
                        }
                        name="description"
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </Page>
    );
}

export default React.memo(ClientManage);
