import React from 'react';
import UdeskList from 'Component/common/udesk-list';
import { Input } from 'udesk-ui';
import { arrayMoveImmutable } from 'array-move';
import UdeskLocales from 'UdeskLocales';

const EndReasonList = (props) => {
    const { value = [], onChange } = props;

    // const [conditions, setConditions] = useState<any>({});
    // const [visible, setVisible] = useState<boolean>(false);
    const onNameChange = (name, index) => {
        let newValue = [...value];
        newValue[index] = name;
        onChange?.(newValue);
    };
    return (
        <React.Fragment>
            <UdeskList
                dragable={true}
                onSort={(oldIndex, newIndex) => {
                    onChange?.(arrayMoveImmutable(value, oldIndex, newIndex));
                }}
                deleteItem={(index) => {
                    let newValue = [...value];
                    newValue[index] = null;
                    onChange?.(newValue.filter(Boolean));
                }}
                addItem={() => {
                    onChange?.([...value, '']);
                }}
                addBtnText={/* 添加结束原因 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.components.endReasonList.addEndReason}
            >
                {value.map((item, index) => {
                    return (
                        <Input
                            onChange={(e) => {
                                onNameChange?.(e.target.value, index);
                            }}
                            placeholder={/* 输入结束原因 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.components.endReasonList.enterEndReason}
                            value={item}
                            style={{ width: 200 }}
                        ></Input>
                    );
                })}
            </UdeskList>
        </React.Fragment>
    );
};

export { EndReasonList };
