// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    BaseResponseListLlmModelInfo,
    PutCompanysModelQuery,
    BaseResponseVoid,
} from '../../types';

/**
 * 获取大模型列表
 *
 * @export
 * @tags 租户接口
 * @link [GET] /companys/model
 */
export function getCompanysModel(): Promise<BaseResponseListLlmModelInfo>;
export function getCompanysModel(
    options: ApiOptions<never, never>
): Promise<BaseResponseListLlmModelInfo>;
export function getCompanysModel(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListLlmModelInfo> {
    return request<never, never, never>('/companys/model', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCompanysModel',
    });
}

/**
 * 修改大模型
 *
 * @export
 * @tags 租户接口
 * @link [PUT] /companys/model
 */
export function putCompanysModel(
    options: ApiOptions<never, PutCompanysModelQuery> & { params: PutCompanysModelQuery }
): Promise<BaseResponseVoid> {
    return request<never, never, PutCompanysModelQuery>('/companys/model', {
        ...options,
        method: 'put',
        __$requestCalleeName: 'putCompanysModel',
    });
}

export const meta = [
    { tags: ['租户接口'], path: '/companys/model', method: 'get' },
    { tags: ['租户接口'], path: '/companys/model', method: 'put' },
];
