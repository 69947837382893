// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetShareLogsQuery,
    BaseResponseListShareLogFoundResponse,
    PostShareLogsBody,
    BaseResponseShareLogFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 分享
 * @link [GET] /shareLogs
 */
export function getShareLogs(): Promise<BaseResponseListShareLogFoundResponse>;
export function getShareLogs(
    options: ApiOptions<never, GetShareLogsQuery>
): Promise<BaseResponseListShareLogFoundResponse>;
export function getShareLogs(
    options?: ApiOptions<never, GetShareLogsQuery>
): Promise<BaseResponseListShareLogFoundResponse> {
    return request<never, never, GetShareLogsQuery>('/shareLogs', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getShareLogs',
    });
}

/**
 * 分享给同事
 *
 * @export
 * @tags 分享
 * @link [POST] /shareLogs
 */
export function postShareLogs(data: PostShareLogsBody): Promise<BaseResponseShareLogFoundResponse>;
export function postShareLogs(
    data: PostShareLogsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseShareLogFoundResponse>;
export function postShareLogs(
    data: PostShareLogsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseShareLogFoundResponse> {
    return request<PostShareLogsBody, never, never>('/shareLogs', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postShareLogs',
    });
}

export const meta = [
    { tags: ['分享'], path: '/shareLogs', method: 'get' },
    { tags: ['分享'], path: '/shareLogs', method: 'post' },
];
