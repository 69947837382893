// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetTaskHelpsByIdParam,
    BaseResponseTaskHelpFoundResponse,
    PutTaskHelpsByIdParam,
    PutTaskHelpsByIdBody,
    BaseResponseVoid,
    DeleteTaskHelpsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags task-help-controller
 * @link [GET] /taskHelps/{id}
 * @param id
 */
export function getTaskHelpsById(
    options: ApiOptions<GetTaskHelpsByIdParam, never> & { segments: GetTaskHelpsByIdParam }
): Promise<BaseResponseTaskHelpFoundResponse> {
    return request<never, GetTaskHelpsByIdParam, never>('/taskHelps/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getTaskHelpsById',
    });
}

/**
 * update
 *
 * @export
 * @tags task-help-controller
 * @link [PUT] /taskHelps/{id}
 * @param id
 */
export function putTaskHelpsById(
    data: PutTaskHelpsByIdBody,
    options: ApiOptions<PutTaskHelpsByIdParam, never> & { segments: PutTaskHelpsByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutTaskHelpsByIdBody, PutTaskHelpsByIdParam, never>('/taskHelps/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putTaskHelpsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags task-help-controller
 * @link [DELETE] /taskHelps/{id}
 * @param id
 */
export function deleteTaskHelpsById(
    options: ApiOptions<DeleteTaskHelpsByIdParam, never> & { segments: DeleteTaskHelpsByIdParam }
): Promise<BaseResponseVoid> {
    return request<never, DeleteTaskHelpsByIdParam, never>('/taskHelps/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteTaskHelpsById',
    });
}

export const meta = [
    { tags: ['task-help-controller'], path: '/taskHelps/{id}', method: 'get' },
    { tags: ['task-help-controller'], path: '/taskHelps/{id}', method: 'put' },
    { tags: ['task-help-controller'], path: '/taskHelps/{id}', method: 'delete' },
];
