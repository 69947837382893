import React from 'react';
// import Udesk from 'Udesk';
import { Button, Icon, Space, Slider, Tooltip, Menu, Dropdown } from 'udesk-ui';
import './style.scss';
import UdeskLocales from 'UdeskLocales';
import { formatDuration } from 'src/util/date';

// let timer: NodeJS.Timeout | null = null;
const defalueBtnProps: any = {
    type: 'link',
    size: 'small',
    style: {
        color: '#1A6EFF',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const SpeedMenu = ({ onPlaybackRateChange }) => (
    <Menu onClick={({ item, key, keyPath, domEvent }) => onPlaybackRateChange(key)}>
        <Menu.Item key="0.5">0.5</Menu.Item>
        <Menu.Item key="1">1</Menu.Item>
        <Menu.Item key="1.2">1.2</Menu.Item>
        <Menu.Item key="1.5">1.5</Menu.Item>
        <Menu.Item key="2">2</Menu.Item>
    </Menu>
);

const Player = (props) => {
    const {
        duration,
        currentTime,
        isPlaying,
        changeAudioPlayingStatus,
        onDownload,
        onDownloadText,
        onVolumeChange,
        volume,
        playbackRate,
        onPlaybackRateChange,
    } = props;
    // const [isShowVolumeRange, setIsShowVolumeRange] = useState<boolean>(false);
    return (
        <div className="audio-player-view">
            <div className="audio-player-view-block">
                {!isNaN(duration) && `${formatDuration(currentTime)} / ${formatDuration(duration)}`}
            </div>
            <div className="audio-player-view-block center" style={{ textAlign: 'center' }}>
                <Dropdown
                    placement="topCenter"
                    overlay={<SpeedMenu onPlaybackRateChange={onPlaybackRateChange} />}
                    trigger={['click']}
                >
                    <Button type="link" size="small">
                        {playbackRate}x
                    </Button>
                </Dropdown>
                <div
                    {...defalueBtnProps}
                    onClick={() => {
                        changeAudioPlayingStatus?.(currentTime);
                    }}
                >
                    <Icon
                        style={{ fontSize: '20px' }}
                        type={isPlaying ? 'PauseCircleFilled' : 'PlayCircleFilled'}
                        antdIcon={true}
                    />
                </div>
                <span className="volume-buttons">
                    <Tooltip
                        color="#fff"
                        title={
                            <div style={{ height: 100 }}>
                                <Slider
                                    style={{ margin: '6px 0' }}
                                    tipFormatter={null}
                                    vertical={true}
                                    step={5}
                                    value={volume * 100}
                                    onChange={(value) => onVolumeChange(value / 100)}
                                />
                            </div>
                        }
                        placement="top"
                    >
                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yinliang"></i>
                    </Tooltip>
                </span>
            </div>
            <div className="audio-player-view-block" style={{ textAlign: 'end' }}>
                <Space>
                    {onDownload || onDownloadText ? (
                        <Dropdown
                            overlay={
                                <Menu>
                                    {onDownload ? (
                                        <Menu.Item onClick={onDownload}>
                                            {/* 下载录音 */}
                                            {
                                                UdeskLocales['current'].components.gong
                                                    .audioPlayerBar.index.downloadRecording
                                            }
                                        </Menu.Item>
                                    ) : null}
                                    {onDownloadText ? (
                                        <Menu.Item onClick={onDownloadText}>
                                            {/* 下载文本 */}
                                            {
                                                UdeskLocales['current'].components.gong
                                                    .audioPlayerBar.index.downloadText
                                            }
                                        </Menu.Item>
                                    ) : null}
                                </Menu>
                            }
                        >
                            <Button {...defalueBtnProps} icon={<Icon type="xiazai" />}></Button>
                        </Dropdown>
                    ) : null}
                    {/* <Button {...defalueBtnProps}>转文本</Button> */}
                </Space>
            </div>
        </div>
    );
};

export default React.memo(Player);
