import React, { useMemo } from 'react';
import { Button, Switch } from 'udesk-ui';
import debounce from 'lodash-es/debounce';

export const PageHeaderExtra = (props) => {
    const {
        processStatus,
        locales,
        isWechatQa,
        enabledInspectRuleApprove,
        value,
        onChange,
        onSubmit,
        onImport,
        submitLoading,
    } = props;

    const changeHandle = (value) => {
        onChange(value);
    };

    const submit = useMemo(() => debounce(onSubmit, 300), [onSubmit]);

    return (
        <>
            {[
                <Switch
                    checked={value}
                    onChange={changeHandle}
                    checkedChildren={
                        /* 测试 */ locales.pages.tasks.manage.template.checkPoint.components
                            .pageHeaderExtra.test
                    }
                    unCheckedChildren={
                        /* 正式 */ locales.pages.tasks.manage.template.checkPoint.components
                            .pageHeaderExtra.formal
                    }
                />,
                <Button type="primary" onClick={onImport}>
                    {locales.labels.import}
                </Button>,
                <Button
                    type="primary"
                    onClick={submit}
                    loading={submitLoading}
                    disabled={submitLoading}
                >
                    {
                        /* 提交 */ locales.pages.tasks.manage.template.checkPoint.components
                            .pageHeaderExtra.submit
                    }
                </Button>,
            ].filter((btn, index) => {
                if (!isWechatQa) {
                    if (index === 0) {
                        return enabledInspectRuleApprove && processStatus === -1;
                    }
                    if (index === 1) {
                        return value;
                    }
                    if (index === 2) {
                        return value && enabledInspectRuleApprove && processStatus === -1;
                    }
                }
                return false;
            })}
        </>
    );
};
