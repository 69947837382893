import React from 'react';
import Udesk from 'Udesk';

class FilterManageComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {
        type: Udesk.enums.filterTypes.call.id,
        filterManageTitle: "",
        onEditFilter: null,
        allFilterTitle: "",
        onSelectedFilterChanged: null,
        isUpdate: false
    };
    //#endregion

    state = {

    };
    privates = {
        storages: {
            paging: {},
            selectedFilter: {},
        },
        filters: [],
        isActionsVisible: false,
        totalCount: '',
        defaultSearchTemplate: {},
    };
    enableStorage = true;
    _storageKey = "FilterManage";
    storageStrategies = {
        storageWay: "sessionStorage",
        extraStorages: [],
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [/^\/site\/dialogue-manage\/view/i, /^\/site\/call-manage\/view/i, /^\/site\/wechat-qa\/chart-record\/detail/i, /^\/site\/ticket-manage\/view/i, /^\/site\/e-commerce-manage\/view/i,],
        },
    };
    static computes = {

    };
    actions = {
        changeSelectFilter(filter, isDefault) {
            if (filter) {
                this.privates.storages.selectedFilter = filter;
            } else {
                this.privates.storages.selectedFilter = {
                    // id: null
                };
            }
            this.actions.getFilterDetail(this.privates.storages.selectedFilter, isDefault);
            this.actions.update();
        },
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum });
        },
        changeActionsVisible() {
            let {
                isActionsVisible
            } = this.privates;
            this.privates.isActionsVisible = !isActionsVisible;
            this.actions.update();
        },
        editFilter(filter) {
            this.actions.getFilterDetail(filter);
            this.trigger("onEditFilter", filter);
        },
        deleteFilter(filter) {
            let {
                locales,
                props: {
                    sdkOptions
                }
            } = this;
            let onConfirm = () => {
                let url = Udesk.business.apiPath.concatApiPath(`search-template/${filter.id}`, sdkOptions);
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.deleteFailed);
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: locales.labels.deleteContent,
                onOk: onConfirm
            });
        },
        getFilterDetail(filter, isDefault) {
            if (filter && filter.id) {
                let {
                    sdkOptions
                } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(`search-template/${filter.id}`, sdkOptions);
                if(isDefault){
                    this.trigger('onSelectedFilterChanged', this.privates.defaultSearchTemplate);
                    return;
                }
                Udesk.ajax.get(url).then(
                    (resp) => {
                        this.trigger('onSelectedFilterChanged', resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.filterManage.getFilterDetailFailed);
                    }
                );
            } else {
                this.trigger('onSelectedFilterChanged');
            }
        }
    };

    //#region Life Cycle
    init() {
        let type = this.props.type;
        //回显默认筛选器
        if(type === Udesk.enums.filterTypes.wechatAll.id){
            type = Udesk.enums.filterTypes.wechat.id;
        }
        let defaultSearchTemplate = this.props.sdkOptions.props.defaultSearchTemplate.find(template => `${template.templateType}` === type);
        let transitionParams = JSON.parse(localStorage.getItem('maualAddToManage_pamars'));

        if(defaultSearchTemplate){
            if(transitionParams === null){
                this.privates.storages.selectedFilter = defaultSearchTemplate;
            }
            this.privates.defaultSearchTemplate = defaultSearchTemplate;
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            isUpdate
        } = this.props;
        if (isUpdate !== prevProps.isUpdate) {
            this.actions.reloadAsyncModel();
        }
        
    }
    asyncModel(changedParams) {
        let {
            sdkOptions,
            type
        } = this.props;
        let params = Object.assign({}, {
            type: type,
            pageNum: this.privates.storages.paging.pageNum || Udesk.config.paging.defaultPageNumber,
            pageSize: Udesk.config.paging.defaultPageSize
        }, changedParams);
        let url = Udesk.business.apiPath.concatApiPath(`search-template`, sdkOptions);
        let filtersPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.filterManage.getFiltersListFailed);
                    reject(reason);
                }
            );
        });

        let totalCountUrl = Udesk.business.apiPath.concatApiPath(`data/${type}/count`, sdkOptions);
        let totalCountPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(totalCountUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.filterManage.getAllCountFailed);
                    reject(reason);
                }
            );
        });

        return ({
            filters: filtersPromise,
            totalCount: totalCountPromise
        });
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let {
            asyncResult,
            asyncKey
        } = parseOptions;
        if (asyncKey === "filters") {
            if (asyncResult) {
                if (asyncResult.data && asyncResult.data.length > 0) {
                    this.privates.filters = asyncResult.data;
                } else {
                    this.privates.filters = [];
                }
                if (asyncResult.paging) {
                    this.privates.storages.paging = asyncResult.paging;
                }
                this.actions.update();
            }
        } else if (asyncKey === "totalCount") {
            if (asyncResult) {
                if (asyncResult.data) {
                    this.privates.totalCount = asyncResult.data;
                }
                this.actions.update();
            }
        }
    }
    //#endregion
}

export default FilterManageComponent;