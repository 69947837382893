import React, { useContext } from 'react';
import ClusteringAnnotationIndexRoute from './route';
import { Menu, Empty, Pagination, Checkbox, Button, Tooltip, Spin, Space, Popconfirm } from 'udesk-ui';
import './style.scss';
import { CallSentenceClusterResultsWarnContext } from 'src/pages/home';
import { Filter } from './components/filter';
import Page from 'Component/common/udesk-qa-page-layout';

const Card = ({ sentence, count, isSelected }) => {
    const { threshold } = useContext(CallSentenceClusterResultsWarnContext);
    return (
        <div className='clustering-annotation-index-page-sentence'>
            <Tooltip placement='right' title={sentence}>
                <div className='clustering-annotation-index-page-sentence-content'>{sentence}</div>
            </Tooltip>
            <div
                style={{
                    background: isSelected ? '#1B6DFF' : count >= threshold ? '#ffa39e' : 'rgba(0, 0, 0, 0.05)',
                    color: isSelected ? '#fff' : 'rgba(0, 0, 0, 0.65)',
                }}
                className='clustering-annotation-index-page-sentence-count'
            >
                {count}
            </div>
        </div>
    );
};

export default class ClusteringAnnotationIndex extends ClusteringAnnotationIndexRoute {
    render() {
        let { actions, locales, state } = this;
        let { columsCount } = this.privates;

        const getIndeterminate = (pageNum, sentenceData) => {
            return (
                state.checkedIds.get(pageNum) &&
                !!state.checkedIds.get(pageNum).length &&
                state.checkedIds.get(pageNum).length < sentenceData.length
            );
        };
        const getCheckedAll = (pageNum, sentenceData) => {
            return (
                state.checkedIds.get(pageNum) &&
                !!state.checkedIds.get(pageNum).length &&
                state.checkedIds.get(pageNum).length === sentenceData.length
            );
        };

        return (
            <Page
                pageBodyClassName='clustering-annotation-index-page-wrap'
                title={locales.components.pages.trainingCenter.clusteringAnnotation.title}
                padding={false}
            >
                <CallSentenceClusterResultsWarnContext.Consumer>
                    {(ctx) => (
                        <>
                            <Filter onSearch={actions.onSearch} />
                            <div className='clustering-annotation-index-page'>
                                <div className='clustering-annotation-index-page-left'>
                                    <div className='clustering-annotation-index-page-part-title'>
                                        {locales.components.pages.trainingCenter.clusteringAnnotation.left.title}
                                    </div>
                                    <div className='clustering-annotation-index-page-part-content'>
                                        <Spin spinning={state.leftLoading}>
                                            {state.clusterData && state.clusterData.length ? (
                                                <Menu
                                                    onClick={actions.handleClick}
                                                    style={{ width: '100%' }}
                                                    selectedKeys={[`${state.current}`]}
                                                    mode='inline'
                                                >
                                                    {state.clusterData.map((data) => {
                                                        return (
                                                            <Menu.Item key={data.id}>
                                                                <Card
                                                                    sentence={data.mainSentence}
                                                                    count={data.sentenceNum}
                                                                    isSelected={data.id === state.current}
                                                                />
                                                            </Menu.Item>
                                                        );
                                                    })}
                                                </Menu>
                                            ) : (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )}
                                        </Spin>
                                    </div>
                                    <Pagination
                                        defaultCurrent={1}
                                        size='small'
                                        showSizeChanger={false}
                                        onChange={actions.onLeftPaginChange}
                                        pageSize={state.paging.pageSize}
                                        current={state.paging.pageNum}
                                        total={state.paging.total}
                                    />
                                </div>
                                <div className='clustering-annotation-index-page-right'>
                                    <div className='clustering-annotation-index-page-part-title'>
                                        {locales.components.pages.trainingCenter.clusteringAnnotation.right.title}
                                        <div style={{ margin: '-6px 0' }}>
                                            <Checkbox
                                                indeterminate={getIndeterminate(
                                                    state.sentencePaging.pageNum,
                                                    state.sentenceData
                                                )}
                                                onChange={actions.onCheckAllChange}
                                                checked={getCheckedAll(
                                                    state.sentencePaging.pageNum,
                                                    state.sentenceData
                                                )}
                                            // disabled={this.props.SmartToolBarRef && this.props.SmartToolBarRef.current && this.props.SmartToolBarRef.current.state.visible}
                                            >
                                                {
                                                    locales.components.pages.trainingCenter.clusteringAnnotation.right
                                                        .checkAll
                                                }
                                            </Checkbox>
                                            <Space>
                                                <Popconfirm
                                                    onConfirm={actions.markUseless}
                                                    title={
                                                        locales.components.pages.trainingCenter.clusteringAnnotation
                                                            .right.meaninglessTips
                                                    }
                                                    okText={
                                                        locales.components.pages.trainingCenter.clusteringAnnotation
                                                            .right.meaningless
                                                    }
                                                    cancelText={
                                                        locales.components.pages.trainingCenter.clusteringAnnotation
                                                            .right.cancel
                                                    }
                                                >
                                                    <Button>
                                                        {
                                                            locales.components.pages.trainingCenter.clusteringAnnotation
                                                                .right.meaningless
                                                        }
                                                    </Button>
                                                </Popconfirm>
                                                <Button
                                                    type='primary'
                                                    onClick={actions.openSiderToolBar.params(ctx.requestSentenceClusterWarn)}
                                                    disabled={
                                                        !Object.values(state.checkedSentence).flat(Infinity).length
                                                    }
                                                >
                                                    {
                                                        locales.components.pages.trainingCenter.clusteringAnnotation
                                                            .right.tagging
                                                    }
                                                </Button>
                                            </Space>
                                        </div>
                                    </div>
                                    <div className='clustering-annotation-index-page-part-content'>
                                        <Spin spinning={state.rightLoading}>
                                            {state.sentenceData && state.sentenceData.length ? (
                                                <Checkbox.Group
                                                    value={state.checkedIds.get(state.sentencePaging.pageNum) || []}
                                                    onChange={actions.checkSentence}
                                                // disabled={true}
                                                // disabled={this.props.SmartToolBarRef && this.props.SmartToolBarRef.current && this.props.SmartToolBarRef.current.state.visible}
                                                >
                                                    <div
                                                        id='clustering-annotation-index-page-checkbox-container'
                                                        className='clustering-annotation-index-page-checkbox-container'
                                                    >
                                                        {state.sentenceData.map((sentence) => (
                                                            <div
                                                                style={{ width: `${100 / columsCount}%` }}
                                                                key={sentence.id}
                                                                className='clustering-annotation-index-page-checkbox-item'
                                                            >
                                                                <Checkbox value={sentence.id}>
                                                                    <Card
                                                                        sentence={sentence.sentence}
                                                                        count={sentence.sentenceNum}
                                                                        key={sentence.id}
                                                                    />
                                                                </Checkbox>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Checkbox.Group>
                                            ) : (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )}
                                        </Spin>
                                    </div>
                                    <Pagination
                                        defaultCurrent={1}
                                        onChange={actions.onRightPaginChange}
                                        pageSize={state.sentencePaging.pageSize}
                                        current={state.sentencePaging.pageNum}
                                        total={state.sentencePaging.total}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </CallSentenceClusterResultsWarnContext.Consumer>
            </Page>
        );
    }
}
