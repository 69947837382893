// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewIntelligentPartnerNodeRobotConfigExistsDefaultQuery,
    BaseResponseboolean,
} from '../../../../../types';

/**
 * 查询同层级机器人节点是否有默认话术
 *
 * @export
 * @tags 智能陪练-机器人对话节点配置
 * @link [GET] /review/intelligentPartner/node/robotConfig/existsDefault
 */
export function getReviewIntelligentPartnerNodeRobotConfigExistsDefault(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodeRobotConfigExistsDefaultQuery> & {
        params: GetReviewIntelligentPartnerNodeRobotConfigExistsDefaultQuery;
    }
): Promise<BaseResponseboolean> {
    return request<never, never, GetReviewIntelligentPartnerNodeRobotConfigExistsDefaultQuery>(
        '/review/intelligentPartner/node/robotConfig/existsDefault',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeRobotConfigExistsDefault',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-机器人对话节点配置'],
        path: '/review/intelligentPartner/node/robotConfig/existsDefault',
        method: 'get',
    },
];
