export default {
  labels: {
    clear: "Tyhjä",
    refresh: "Päivitä",
    nextStep: "Seuraava vaihe",
    prevStep: "Edellinen vaihe",
    closeDriver: "Poistukäynnistä",
    complete: "Valmistus",
    viewStatistics: "Näytä tilastot",
    colon: "：",
    splitter: "/",
    remark: "Huomautukset",
    correction: "Virheenkorjaus",
    save: "Tallenna",
    reset: "Nollaa",
    increase: "Lisää",
    preview: "Esikatselue",
    unit: "Artikla",
    cancel: "Peruus",
    all: "Kaikki",
    searchPlaceHolder: "Anna avainsanan kysely",
    greaterThan: "Suurempaa",
    lessThan: "Vähemmäs",
    second: "Sekunteja",
    description: "Kuvaus",
    column: "Sarake",
    pleaseChoice: "Valitse:",
    filter: "Suodatin",
    saveAndSubmit: "Tallenna ja lähettää",
    submit: "Toimittaminen",
    appeal: "Valitus",
    review: "Uudelleentarkastelu",
    choose: "Valita",
    manage: "Hallinnointi",
    delete: "Poistaa",
    add: "Uusi:",
    create: "Uusi:",
    edit: "Muokkaa",
    name: "Nimi",
    describe: "Kuvaus",
    next: "Seuraava vaihe",
    percent: "%",
    export: "Vienti",
    exportAll: "Vie kaikki",
    confirm: "Hyvä on.",
    back: "Palautus",
    versionUpdate:
      "Olen iloinen voidessani ilmoittaa, että sivustomme on juuri julkaissut uuden version! Tavanomaisen käytön varmistamiseksi teidän täytyy säästää nykyinen työnne välittömästi. ja päivitä sivua (napsauta selaimen päivityspainiketta tai paina F5-näppäintä) Jotta uusi versio tulee voimaan. Paljon kiitoksia yhteistyöstänne!",
    more: "Lisää.",
    selected: "Valittu",
    deduct: "Solkki",
    maxScore: "Suurin osa",
    scoreUnit: "Pisteet",
    pointUnit: "Yksi.",
    comma: "，",
    view: "Näytä",
    batchingImport: "Tuo",
    deleteContent:
      "Haluatko varmasti poistaa sen? Et voi palauttaa sitä poistamisen jälkeen",
    import: "Tuo",
    uploadFile: "Lähetä tiedosto",
    downloadFile: "Vie tiedosto",
    upload: "Lähetä",
    click: "Napsauttaa",
    downloadTemplate: "Lataa malli",
    fillInTheForm: "Täytä muodon mukaan",
    UploadExcelfile: "Lataa Excel täydentääkseen erän tuontia",
    uploadIdentical:
      "Jos API-nimi on olemassa, järjestelmän olemassa olevat tiedot päivitetään tuonnin aikana.",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Sovellettava",
    leftBracket: "(",
    rightBracket: ")",
    question: "Kysymys",
    answer: "Vastaa",
    content: "Sisältö",
    search: "Etsi:",
    query: "Kysely",
    cancelEdit: "Peru muokkaus",
    noValue: "Ei tietoa",
    selectAll: "Valitse kaikki",
    changeAGroup: "Muuta ryhmää",
    arrow: " ---> ",
    saveReview: "Uudelleentarkastelu",
    saveSpotCheck: "Näytteenottotarkastus ja toimittaminen",
    rapidSampling: "Nopea näytteenotto.",
    file: "Arkistointi",
    download: "Lataus",
    lastPage: "Edellinen sivu",
    nextPage: "Seuraava sivu",
    batchActions: "Erän toiminta",
    cancelDistribution: "Ei siirretty",
    againDistribution: "Kohdista",
    deleteRecord: "Poista tietue",
    oneOptionOpertor: "Valinnainen laaduntarkastaja",
    allOptionOpertor: "Kaikki laaduntarkastajat",
    distributionInfoFirstOne: "Vahvista",
    distributionInfoFirstTwo: "Haluatko lisätä tietoja? Valitse kohde.",
    distributionInfoSecondOne: "OK perua",
    distributionInfoSecondTwo:
      "Onko tästä tieto? Peruutuksen jälkeen siitä tulee lisättämätön tarkastusrekisteri.",
    distributionInfoSecondThree:
      "Onko sijoittajan tiedot tämän tietueen, siitä tulee varaamaton näytteenotto tietueen peruutuksen jälkeen.",
    distributionInfoThirdOne: "Valituilla tietuilla on ollut:",
    distributionInfoThirdTwo: "Tarkistettuja tietoja ei käsitellä.",
    distributionInfoThirdThree: "Näytteenottotietoja ei käsitellä.",
    exportError: "Vienti epäonnistui",
    exportSuccess: "Vienti onnistui",
    deleteRecordInfo:
      "Haluatko varmasti poistaa tiedostot {0}? Tietoja ei voi palauttaa poistamisen jälkeen",
    pleaseSetFilter: "Valitse suodatinkriteerit",
    noCountAfterFilter: "Ei vientitietoja, nollaa suodinkriteerit",
    redo: "Uudelleen toteutus",
    batchMove: "Erän siirto",
    websiteName: "Älykäs laatutarkastus",
    salesEnable: "Myynnin vaikutus",
    systemName: "Älykäs laatutarkastus",
    initSystemError:
      "Verkkopoikkeus tai sivun alustamisen virhe, ota yhteyttä asiakaspalveluun.",
    day: "Päivä.",
    hour: "Tunnit",
    minute: "Pisteet",
    questionMark: "？",
    personalSettings: "Henkilökohtaiset asetukset",
    logout: "Luovutus",
    ok: "Hyvä on.",
    saveAs: "Tallenna",
    pleaseEnter: "Syötä",
    executeMessage: {
      executeSuccess: "{0} suoritettiin onnistuneesti",
      executeError: "{0} suoritus epäonnistui",
    },
    field: "Kenttä",
    operator: "Toiminnanharjoittaja",
    value: "Arvo",
    placeholder: "Valitse:",
    pleaseSelect: "Valitse:",
    totalScore: "Pisteet yhteensä",
    hyphen: "-",
    semicolon: "；",
    wfm: "Älykäs aikataulu",
    user: "Käyttäjä",
    none: "Ei ole.",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Tallenna onnistuneesti! Tehokas kun asianomainen työntekijä virkistää sivu",
    saveAsName: "{0}(kopio)",
    systemInformation: "Näytä järjestelmän tietoa",
    billingCenter: "Laskutuskeskus",
    warningMessage:
      "Jäljellä oleva kiintiö on riittämätön. Ladatkaa mahdollisimman pian.",
    fileTypeHolder:
      "Tiedostomuoto on virheellinen. Lataa tiedosto {0} muodossa.",
    dataNo: "Ei tietoa",
    move: "Siirrä",
    study: "Sinulla on merkitsemättömät itseoppimisen laadun tarkastuspisteet",
    studySuccess: "Merkinnän menestys",
    studyError: "Merkintä epäonnistui",
    editType: "Muokkaa luokan nimiä",
    copyed: "Kopioitu leikepöydälle",
    clickToCopy: "Napsauta kopioita",
    addFilterConditions: "Lisää suodin tila",
    filterConditions: "Suodatin tila",
    messageTime: "Viestin aika",
    messageType: "Viestin tyyppi",
    pushType: "Viestin kanava",
    messageContent: "Viestin sisältö",
    messageName: "Viestin nimi",
    messageStatus: "Viestin tila",
    configuration: "Asetustiedot",
    sendTarget: "Lähetä kohde",
    guideUrl: "Käynnistys- osoite",
    noticeDetail: "Viestin tiedot",
    action: "Toiminto",
    seeDetail: "Näytä tiedot",
    theDay: "Päivä {0}",
    theLastDayOfMonth: "Kuukauden viimeinen päivä",
    ignore: "Älä huomioi",
    seeNow: "Näytä nyt",
    exportConfirmText: {
      export: "Yhteensä {0} tiedot, vahvistetaan viennin?",
      exportAll: "Vahvistetaanko viedä koko sisältö?",
    },
    samrTagAdd: "Lisää tunnisteet",
    deletePopconfirmContent: "Haluatko varmasti poistaa?",
    TheLastThirtyDays: "Viimeiset 30 päivää",
    addNumber: "Lisässä",
    subNumber: "Minus",
    addStrategy: "Lisää toiminta",
    minutes: "Pöytäkirja",
    status: "Käytössä oleva tila",
    pushConfig: "Työnnän asetukset",
    category: "Luokittelu",
    and: "Jossa",
    interval: "Välitys",
    switchOn: "Avaa",
    switchOff: "SuljeComment",
    batchDel: "Erän poista",
    exportTxt: "Vie TXT- teksti",
    playSpeed: "Kaksinopeus",
    messageLogBtn: "Katso ilmoituksia ja keskusteluja",
    dataLink: "Tietoyhteys",
    yes: "Kyllä.",
    no: "Ei ei",
    sentenceUnit: "Tuomio",
    appealDetail: "Valituksen tiedot",
    detail: "Yksityiskohtaiset tiedot",
    default: "Oletus",
    customer: "Asiakasta",
    agent: "Asiakaspalvelut",
    sort: "Lajittelu",
    applyNow: "Soveltaminen",
    index: "Sarjanumero",
    allColumns: "Sarakkeet, jotka voidaan lisätä",
    selectedColumns: "Lisätty sarakkeet",
    syn: "Synkronisointi",
    saveAndNext: "Tallenna ja luo seuraavaa",
    viewReference: "Näytä viitteet",
    referenceDetail: "Viitetiedot",
    goToQaList: "Siirry laatutarkastukseen",
    goToCheck: "Siirry paikkatarkistukseen",
    businessCard: "Käyntikortti",
    weapp: "Pieni ohjelma",
    redpacket: "Punainen kirjekur",
    externalRedpacket: "Vaihda punaiset kirjekuorit",
    sphfeed: "Videonumero",
    exportTaskCreated: "Vie tehtävä onnistuneesti",
    plzGoTo: "Hyppää.",
    importTaskCreated: "Tuontitehtävä luotiin onnistuneesti",
    taskCreated: "Tehtävä luotiin onnistuneesti",
    reply: "Vastaus",
    replied: "Vastaus",
    release: "Julkaisu",
    expandMore: "Laajenna muut {0} vastaukset",
    comment: "Huomautukset",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Saat vähintään yksi {0}",
      canNotBeEmptyFormat: "{0} ei voi olla tyhjä",
      repeatErrorTipFormat: "{0} on kaksoiskappaleet",
      getInformationFailedFormat:
        "{0} tietoja ei voitu hankkia. Tarkista verkkoyhteys tai yritä myöhemmin uudelleen",
      deleteSuccessFormat: "{0} poistettiin onnistuneesti.",
      deleteFailedFormat:
        "{0} poistaminen epäonnistui. Yritä myöhemmin uudelleen.",
      saveSuccessFormat: "{0} tallennettu onnistuneesti.",
      saveFailedFormat: "{0} tallennus epäonnistui. Yritä myöhemmin uudelleen.",
      fileSuccessFormat: "{0} arkistoitu onnistuneesti.",
      fileFailedFormat:
        "{0} arkistointi epäonnistui. Yritä myöhemmin uudelleen.",
      exportSuccessFormat: "{0} vienti onnistui",
      exportFailedFormat: "{0} vienti epäonnistui. Yritä myöhemmin uudelleen.",
      submitSuccessFormat: "{0} lähetetty onnistuneesti.",
      submitFailedFormat:
        "{0} lähettäminen epäonnistui, yritä myöhemmin uudelleen.",
      confirmSuccessFormat: "{0} vahvistus oli onnistunut.",
      confirmFailedFormat:
        "{0} vahvistus epäonnistui. Yritä myöhemmin uudelleen.",
      pleaseUploadFile: "Lataa tiedosto ensin!",
      importFileSuccess: "Tiedoston tuonti onnistui!",
      importFildFailed: "Tiedoston tuonti epäonnistui!",
      deleteSuccess: "Poistettiin onnistuneesti!",
      deleteFailed: "Poista epäonnistui, yritä myöhemmin uudelleen!",
      illegalTitle: "Riittämättömät oikeudet",
      illegalMessage:
        "Sinulla ei ole joitakin tai kaikkia oikeuksia seuraavaan vaiheeseen, ota yhteyttä ylläpitäjään määrittääksesi, napsauta OK-painiketta poistaa käynnistyksestä",
      illegalMessageCurrent:
        "Sinulla ei ole joitakin tai kaikkia oikeuksia nykyiseen vaiheeseen, ota yhteyttä ylläpitäjään määrittääksesi, napsauta OK-painiketta poistaa käynnistyksestä",
      skipMessage: "Jos haluat ohittaa tämän vaiheen, napsauta Skip",
      errorMsg500:
        "Palvelimen pyyntö epäonnistui. Tarkista toiminta tai päivitä ja yritä uudelleen",
      errorMsg404: "Etsimänne sivu puuttuu.",
      errorMsg400:
        "Palvelimen pyyntö epäonnistui. Tarkista tiedot ja yritä uudelleen",
      pageOverFlow:
        "Tällä hetkellä voi näyttää vain 10000 baaria. Asettakaa suodatuskriteerit ylityksen tarkastelemiseksi.",
      isHandAddSmartTag: "On manuaalisesti lisätty älymerkintä",
      defaultGradeMustAtLast: "Oletusluokitus on sijoitettava loppuun.",
      AutomaticAllocationInspectorsIsNull:
        "Syötä perustiedon sivulle lisätäkseen laaduntarkastajaa",
      timePickerTips:
        "Jotta ei voi vaikuttaa normaalikäyttö, 23:00~ 2:00 on järjestelmän ylläpitoaika, älä suorita aikataulutehtäviä.",
      batchDeleteInfo:
        "{0} tietueet poistettiin, ja {1} tietueet epäonnistuivat, koska tietoja on käytetty.",
      batchDeleteTips: "Tarkista {0} tiedot, vahvistetaan poistetaan?",
      correctionSuccess: "Virhekorjauksen onnistus",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError: "Lisätään enintään 10 laatutarkastuslomakkeita",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "Metriset kohteet {0} täytyy lisätä jopa 100:iin",
          banProjectMaxErrorTip: "Lisää vuoteen 2000 saakka",
          deadlyProjectMaxErrorTip: "Lisää jopa 500 kuolemaan johtavaa tavaraa",
        },
      },
    },
    info: {
      creatSuccess: "Luotiin onnistuneesti",
      creatError: "Ei voitu luoda",
      editSuccess: "Muutos onnistui",
      editError: "Muutos epäonnistui",
      deleteSuccess: "Poistettiin onnistuneesti",
      addSuccess: "Uusi menestys",
      deleteError: "Poista epäonnistui",
      conditionError: "Operaattorin looginen lausekevirhe",
      customJudgeLogic: "Looginen lausekevirhe",
      exportError: "Vienti epäonnistui",
      searchError: "Suodin epäonnistui",
      loadError: "{0} ladata epäonnistui",
      saveSuccess: "Tallenna onnistuneesti",
      saveError: "Tallennus epäonnistui",
      fileSuccess: "Arkistointi menestys",
      fileError: "Arkisto epäonnistui",
      cannotBeNull: "{0} ei voi olla tyhjä!",
      pleaseSearchAndSelect: "Anna avainsanahaku",
      clickAndToggle: "Napsauta valitak",
      deleteWarning:
        "{0} poistoa ei ole palautettavissa, haluatko varmasti jatkaa?",
      getOssAuthFailed:
        "OSS- lupaa ei voitu hankkia. Tarkista verkkoyhteys tai yritä myöhemmin uudelleen.",
      getInsightAuthFailed:
        "Insight-lupaa ei voitu hankkia. Tarkista verkkoyhteys tai yritä myöhemmin uudelleen.",
      pictureUploadFailed: "Levykuvan lähetys epäonnistui",
      pictureUploadFailedSize:
        "Kuvan koko ylittää rajan, kuvan lähetys epäonnistui",
      wecomTagsSyncInfo: "Viimeisin päivitys: {0}, synkronoida {1} viestit",
      collectSuccess: "Kokoelman menestys.",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "Raporttia" },
      waveAudio: {
        noWaveMessage:
          "Kokemuksen ylläpitämiseksi aaltomuotoa ei näytetä tallenteita varten yli {0} minuuttia. Voit soittaa niitä suoraan.",
      },
      customFilter: {
        del: "Poistaa",
        edit: "Muokkaa",
        new: "Oma suodine",
        apply: "Soveltaminen",
        cancel: "Peruus",
        onlyApply: "Ainoastaan sovellettavissa",
        ohterSave: "Tallenna",
        save: "Tallenna",
        customFilter: "Yleinen suodatini",
        reset: "Nollaa",
        holderKeyWord: "Anna avainsana.",
        customFilterRule: "Suodata logiikkasäännöt:",
        customFilterName: "Suodin nimi:",
        holderCustomFilterName: "Anna suodattimen nimi",
        manualConfiguration: "Manuaalinen asetukset",
        holderCustomRule: "Anna mukautettu logiikka edellytys",
        example: "Esimerkki",
        dragConfiguration: "Raahaa ja pudota asetukset",
        condition: "Tila",
        operation: "Toiminto",
        infoDel: "Raahaa sisällön pois alueelta poistettavaksi",
        toConfigure: "Asetukset",
        holderCustomFilterNameLength: "Suodattimen nimi maksimipituus on 10",
        customFilterNameNone: "Suodattimen nimi ei voi olla tyhjä",
        and: "Ja",
        or: "Ei",
        not: "Ei muuta",
        delSuccess: "Poistettiin onnistuneesti",
        saveSuccess: "Tallenna onnistuneesti",
        updateSuccess: "Muutos onnistui",
        customFilterCondition: "Suodatin tila",
        conditionRule: "Tilaa koskevat säännöt",
        none: "Ei ole.",
        filterPlaceholder: "Anna avainsana.",
      },
      labelTabs: {
        addThis: "Lisää tähän",
        goThis: "Siirry tähän.",
        saveGo: "Tallenna ja harjoittela",
      },
      datePicker: {
        today: "Tänään",
        yesterday: "Eilen.",
        theDayBeforeYesterday: "Toissapäivänä eilen",
        thisWeek: "Tällä viikolla.",
        lastWeek: "Viime viikolla.",
        thisMonth: "Tällä kuukaudella",
        lastMonth: "Viime kuussa.",
        lastSevenDay: "Viimeiset 7 päivää",
        lastThirtyDay: "Viimeiset 30 päivää",
        thisQuarter: "Tämä vuosineljännes",
        lastQuarter: "Viimeinen neljännes",
        thisYear: "Tänä vuonna.",
        lastYear: "Viime vuonna",
        lastYearFromToday: "Viime vuonna",
      },
      pagination: { page: "Sivu {1}/{0}" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Lataa aiemmat viestit",
          bottom: "Lataa lisää viestejä",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Laaduntarkastustehtävät",
        informationCollectTasksManageTitle: "Kokoelman tehtävät",
        customerServiceCenterTitle: "Asiakaspalvelukeskus",
        informationTitle: "Datakeskus",
        intelligentAnalysisTitle: "Älykäs analyysi",
        semanticIntelligence: "Semanttinen älykkyys",
        trainingCenterTitle: "Koulutuskeskus",
        adminTitle: "Järjestelmän hallinta",
      },
      qualityCheckingTask: {
        title: "Kutsun laatutarkastus",
        index: {
          tipOne:
            "Puhelun laadun tarkastus käytetään arvioimaan laatua työntekijöiden puhelinkeskuksen työtä. Voit laatia asianmukaiset laatutarkastuslomakkeet yrityksesi perusteella.",
          tipTwo:
            "Laatutarkastustehtäviä varten voidaan vahvistaa asianmukaiset säännöt, mukaan lukien suodatus ja näytteenotto. Kun laatutarkastajan pisteet, asiakaspalvelu voi tarkastaa laatutarkastuksen tulokset ja lisätä valitusta,",
          addTaskTitle: "Lisää laatutarkastustehtäväComment",
          deleteTipTitle: "Poista laadun tarkastustehtävät",
          deleteTipContent:
            "Laatutarkastustehtäviä ei voida palauttaa poistamisen jälkeen. Haluatko varmasti poistaa ne?",
          checkingTask: "Laaduntarkastustehtävät",
          checkingTaskList: "Laaduntarkastustehtäväluettelo",
          formList: "Lomake luettelo",
          viewHelpDocuments: "Näytä ohje-dokumentaatiot",
        },
        manage: {
          taskSetting: {
            title: "Tehtäväasetukset",
            form: "Lomake",
            basicInfo: "Perustiedot",
            visibleRange: "Näkyvä alue",
            visibleRangeTip:
              "Valittu asiakaspalvelu ja asiakaspalvelutiimi voi tarkastella laatutarkastustehtävää. Lisäksi asiakaspalveluryhmän johtaja, asiakaspalvelu ja laatutarkastaja voidaan yhdistää tämän tehtävän lupaasetuksiin.",
            automaticSampling: "Automaattinen näytteenotto.",
            automaticSamplingTip:
              "Voit asettaa automaattiset näytteenottosäännöt niin, että järjestelmä lisää säännöllisin väliajoin laatutarkastustehtäviä asettamien sääntöjen mukaisesti. Kuten viisi prosenttia viime viikon puheluista joka maanantai.",
            samplingRate: "Näytteenottotiheys",
            samplingTime: "Näytteenottoaika",
            samplingRange: "Näytteenottoalue",
            samplingRule: "Näytteenottosäännöt",
            fillterCondition: "Suodatin tila",
            workflow: "Laatutarkastuksen työnkulu",
            appealsTime: "Annetaan valitusaikaa",
            appealsTimeTipOne: "Tulosten toimittamisen jälkeen",
            appealsTimeTipTwo: "Muutaman päivän kuluessa",
            appealsTimeTipThree:
              'Jos muutoksenhakuaika ylittyy, tietueen laatutarkastuksen tila muutetaan automaattisesti Convaded"',
            auditTime: "Tarkastuksen käsittelyaika",
            auditTimeTipOne: "Valituksen tekemisen jälkeen",
            auditTimeTipTwo: "Muutaman päivän kuluessa",
            auditTimeTipThree:
              'Hyväksynnän käsittelyajan ylityksen jälkeen rekisterissä säilytetään alkuperäinen pisteet, ja laaduntarkastusasema muuttuu automaattisesti Confirmed"',
            noAppealsMode: "Ei valitustilaa",
            appealsMode: "Salli valitustilan",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Asiakaspalvelut",
            customerServicesGroup: "Asiakaspalveluryhmä",
            surveyList: "Arviointiluettelo",
            customFields: "Omat",
            fieldConfig: "Kentän asetukset",
            from: "Alkaen",
            owner: "Vastuuhenkilö",
            inspectorAndReviewerAuthority:
              "Laaduntarkastuksen tarkastusviranomainen",
            inspector: "Laaduntarkastaja",
            reviewer: "Arvostelin",
            inspectorTip: "Tarkastaja voi tehdä tehtävän.",
            reviewerTip: "Arvostelija voi arvioida tehtävän",
            dataStatisticsAuthority: "Tietotilasto-oikeudet",
          },
          samplingRecords: {
            title: "Näytteenottorekisterit",
            index: {},
            manualSampling: {
              title: "Otanta",
              fillterCondition: "Suodatin tila",
              samplingRule: "Näytteenottosäännöt",
              agent: "Asiakaspalvelut",
              type: "Tyyppi",
              result: "Tulokset",
              evaluate: "Arviointi",
              relayNumber: "Rele-numero",
              source: "Lähde",
              task: "Tehtävät",
              queueState: "Aseta jonoon tila",
              callHanger: "Soita lopetusjuhlat.",
              queueTime: "Aikaa vievä jonus",
              talkTime: "Kutsun kesto",
              bellTime: "Kevyys",
              recordCountTipOne: "Yhteensä",
              recordCountTipTwo: "Tietue",
              customerServices: "Asiakaspalvelut",
              customerServicesGroup: "Asiakaspalveluryhmä",
              surveyList: "Arviointiluettelo",
              customFields: "Omat",
              fieldConfig: "Kentän asetukset",
              defaultFields: "Oletuskentät",
            },
          },
          taskList: {
            title: "Tehtäväluettelo",
            index: {
              batchDelete: "Erän poista",
              batchSubmit: "Erän toimittaminen",
              batchConfirm: "Erän vahvistus",
              itemFields: "Asetuskenttä",
              categoryFields: "Toissijainen luokituskenttä",
              forbidsAndDeadlies: "Kielletyt ja kuolemaan johtavat esineet",
              scoreFieldList: "Pisteiden kohteen kenttäluettelo",
              customerName: "Asiakkaan nimi",
              cellphone: "Puhelin",
              email: "Postilaatikko",
              organization: "Yritys",
              description: "Kuvaus",
              tags: "Merkinti",
              taskUserRole: "Tehtävän käyttäjän rooli",
            },
            workBench: {
              title: "Tallenna tiedot",
              checkForm: "Laatutarkastuslomake",
              waitEvaluate: "Arvioitava",
              alreadyEvaluate: "Arvioitu",
              waitSubmit: "Esitetään.",
              alreadySubmit: "Lähetetty",
              waitConfirm: "Vahvistettava",
              alreadyConfirm: "Vahvistettu",
              waitReview: "Tarkistetaan uudelleen",
              alreadyReview: "Tarkistettu",
              alreadyDone: "Valmistui",
              comment: "Arviointihuomautukset",
              totalScore: "Pisteet yhteensä",
              score: "Pisteet",
              remark: "Huomautukset",
              changeRecords: "Muuta tietuet",
              subject: "Teema",
              secondLevelScore: "Asteen II pisteet",
              deadlyList: "Kuolema kohta",
              forbidList: "Kielletyt kohteet",
              addToCheckTask: "Lisää laatuun tehtäväin",
              submitCheckRemark: "Toimitettu laatutarkastuksen arviointi",
              changeCheckRemark: "Laaduntarkastusten arviointia on muutettu",
              doneCheckRemark: "Laatutarkastuksen arviointi saatu päätössä",
              submitCheckReview: "Esitetty laatutarkastuksia varten",
              submitCheckAppeal: "Laatutarkastushakemus",
              saveFlowSuccess: "Arvioi menestystä.",
              saveFlowFailed:
                "Arviointi epäonnistui, yritä myöhemmin uudelleen.",
              FormDetail: "Lomakkeen tiedot",
              callDetail: "Kutsulokin tiedot",
              sampleCalllogDetail: "Tallenna tiedot",
              appealReasonTip: "Syy muutoksenhakuun (vaadittu)",
              appeal: "Valitus",
              appealReason: "Kantelun perusteet",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Laatutarkastuslomake",
        basicInformationTitle: "Perustiedot",
        indicatorsProjectTitle: "Indikaattorin kohta",
        specialProjectTitle: "Erityiset kohteet",
        totalScore: "Pisteet yhteensä",
        indicatorsName: "Indikaattorin nimi",
        score: "Fraktio",
        singleScore: "Yhden kohteen pisteet",
        weight: "Paino",
        maxDeductScore: "Enimmäispisteiden vähennys",
        add: "Lisäys",
        detail: "Lomakkeen tiedot",
        list: {
          title: "Laatutarkastuslomake",
          tipOne:
            "Laatutarkastuslomakkeen määrittäminen on ensimmäinen vaihe laatutarkastuksessa. Voit määrittää sopivimman laatutarkastuslomakkeen yrityksesi liiketoimintaan perustuvan.",
          tipTwo:
            "Tuemme erilaisia muotoja, kuten suora pisteytys, painotettu pisteytys ja pisteen vähennys järjestelmiä. On suositeltavaa, että asetat säännöt ennen kuin ne lisätään järjestelmään.",
        },
        basicInformation: {
          formTemplate: "Lomake malli",
          directScore: "Suora luokitus",
          weightedScore: "Painopisteet",
          deductScore: "Vähennykset",
          directScoreSecondLevel: "Suora luokitustaso 2",
          weightedScoreSecondLevel: "Paino pisteytystaso 2",
          deductScoreSecondLevel: "Vähennystaso 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Kielletyt kohteet",
          banProjectTip:
            "Sitä voidaan käyttää laadun tarkastuksen aikana. Se voidaan pitää rikkomuksena tai täydennys kommentteja vaikuttamatta todelliseen pisteeseen.",
          deadlyProject: "Kuolema kohta",
          deadlyProjectTip:
            "Kuolemaan johtavia esineitä voidaan tarkistaa laatutarkastuksen aikana. Jos kuolemaan johtava kohde tarkistetaan, laatutarkastustehtävä pisteet on suoraan 0 pistettä.",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Kutsun laatutarkastus",
          entiretyReportTitle: "Yleiskertomuus",
          serviceGroupReportTitle: "Asiakaspalveluryhmän raportti",
          serviceReportTitle: "Asiakaspalveluraportti",
          entiretyReport: {
            pandect: "Yleiskatsaus",
            contrast: "Kontrasti",
            trend: "Kehitys",
            analyze: "Analyysi",
            totalScore: "Pisteet yhteensä",
            banProject: "Kielletyt kohteet",
            deadlyProject: "Kuolema kohta",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Laaduntarkastusten tulosluettelo",
          taskSelectPlaceholder: "Valitse laatutehtäväComment",
          agentSelectPlaceholder: "Valitse asiakaspalvelun",
          refresh: "Päivitä",
          all: "Kaikki",
          unread: "Lukemattomat",
          inComplaint: "Valituksessa",
          reviewed: "Tarkistettu",
          totalScore: "Yleiset huomautukset",
          detail: "Laaduntarkastustiedot",
          audioText: "Tallennettu teksti",
          callInfo: "Puhelutiedot",
          ticketInfo: "Työmääräyksiä koskevat tiedot",
          wechatInfo: "Istunnon tiedot",
          dialogText: "Ikkuntekst",
          dialogInfo: "Tiedot vuoropuheluista",
          customer: "Asiakasta",
          agent: "Asiakaspalvelut",
          none: "Ei laaduntarkastustietoja",
          appealSuccess: "Valitus onnistui!",
          appealFailed: "Valitus epäonnistui, yritä myöhemmin uudelleen!",
          pointListIsNull:
            "Valituksen laaduntarkastuspistettä ei voi olla tyhjä!",
          pleaseSelectPoint: "Valitse laatutarkastuspiste!",
          unsupportedFormat: "Tätä muotoa ei tueta.",
          shardingInformation: "Seuraava on jaettu {0}",
          smartTags: "Älykäs nimi",
          businessRecords: "Liiketoimintaa koskevat tiedot",
          selectedPointsWarning:
            "Tarkistakaa laatutarkastuksen kohdat, esittäkää valituskohteita koskevat lausunnot, ja klikkaa Lähetä.",
          applyReason: "Valitusperusteet (enintään 50 sanaa)",
          chatMessageTypes: {
            all: "Kaikki",
            text: "Teksti",
            image: "Kuva",
            agree: "Suostumuksen sisältö",
            video: "Videoa",
            voice: "Ääni",
            file: "Tiedosto",
            emotion: "Lauseke",
            link: "Linkkiä",
            chatrecord: "Keskusteluluettelo",
            mixed: "Sekoitettu viesti",
            default: "Viestiä ei ole tuettu",
            deleteFriend: "Poista ystävä",
            redpacket: "Punainen kirjekur",
            card: "Käyntikortti",
            disAgree: "Eri mieltä keskustelun sisältöä",
            weapp: "Pieni ohjelma",
            externalRedpacket: "Vaihda punaiset kirjekuorit",
            meetingVoicevCall: "Kutsuviesti",
            uRL: "Verkkosivi",
            replyTimeout: "Aikatoimitusvasti",
            sphfeed: "Videonumero",
            chatRecordTitle: "Keskusteluluettelo",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Pisteet yhteensä",
            actualScore: "Luokitus",
            actualGrade: "Luokitus",
            appeal: "Valitus",
            remark: "Manuaaliset huomautukset",
            none: "Ei yksityiskohtaisia tietoja laaduntarkastuksissa",
            pointEdit: "Laaduntarkastuspisteiden muokkausluokitus",
          },
          changeList: {
            title: "Muuta tietuet",
            user: "Käyttäjä",
            point: "Laaduntarkastuspisteet",
          },
          relatedRecord: {
            title: "Asiaan liittyvät tiedot",
            qualityList: "Laaduntarkastustehtävät",
            none: "Ei ole.",
            check: "Näytä",
            loadMore: "Lataa lisää.",
          },
        },
      },
      grade: {
        title: "Laatutarkastuksen luokitus",
        subTitle:
          "Laatutarkastusluokitusta käytetään laatutarkastuskohteiden luokitteluun laatutarkastuksen jälkeen. Esimerkiksi se voidaan määrittää kelpaamattomaksi, jos se on alle 60, hyvä, jos se on yli 60, ja erinomainen, jos se on yli 80.",
        list: {
          up: "Siirry ylöspäin",
          down: "Siirry alas",
          del: "Poistaa",
          case: "Liity automaattisesti asiakirjastoon",
          orderCase: "Oletuskohtelukirjasto",
          info: "Oletusluokituksella ei ole vastaavia kriteerejä eikä sitä voi poistaa. Jos jokin edellä mainituista luokituksista ei täytetä, maksukyvyttömyys saadaan.",
          add: "Lisää arvostelu",
          save: "Tallenna",
          close: "Peruus",
          max: "Laaduntarkastusluokitus voidaan määrittää jopa 20 asti",
          name: "Nimettömä",
          success: "Tallenna onnistuneesti",
        },
      },
      qualityInspectionList: {
        title: "Laatutarkastusluettelo",
        fastFilter: "Laaduntarkastusten rikkominen",
        fastFilterTip: "Osuminen miinuspisteeseen on loukkaus.",
        titleWechat: "Analyysiluettelo",
        titleCollect: "Hankintaluettelo",
        status: "Analyysin tila",
        reQualityInspectionConfig: "Tehtävän laadun valinnat",
        pleaseSelectRecheckPoints:
          "Valitse uudelleen laskettava laatutarkastuspiste.",
        beginReQualityInspection: "Aloita suoritus",
        cancelReQualityInspection: "Peru tehtävä",
        reQualityInspection: "Uudelleentarkastus",
        reQualityInspectionWechat: "Uudelleenanalyys",
        reQualityInspectionUnderWay: "Tarkastustehtävä...",
        reQualityInspectionUnderWayTip:
          "Sivun poistuminen ikkunan sulkemisen jälkeen ei vaikuta tehtävän",
        reQualityInspectionDone: "Uudelleentarkastus saatu",
        reQualityInspectionDoneTip:
          "Uudelleentarkastus on onnistuneesti saatu päätökseen, napsauta vahvistuspainiketta.",
        endReQualityInspection: "Lopeta tehtäväComment",
        alreadyDoneTip: "{0} valmistui",
        totalTip: "Yhteensä {0}",
        endReInspectionSuccess: "Uudelleenlaatun tehtävä päättyi",
        closeWindow: "Sulje ikkuno",
        endReInspectionFailed:
          "Laatutehtävä epäonnistui, yritä myöhemmin uudelleen!",
        detail: {
          title: "Laaduntarkastustiedot",
          wechatTitle: "Analyysin yksityiskohdat",
          titleCollect: "Kokoelman tiedot",
          callIndex: "Liitännäinen puhelu",
          textIndex: "Liittyvät keskustelut",
          fileConfirm: "Vahvistakaa arkistointi?",
          fileConfirmDesc:
            "Arkistoinnin jälkeen laatutarkastuspisteitä ei voida muokata. Vahvista, arkistoidaanko.",
        },
        sysCloumnsContal: "Järjestelmän sarakkeenhallinta",
        personCloumnsContal: "Henkilökohtainen sarakkeenhallinta",
      },
      spotCheckList: {
        title: "Näytteenottoluettelo",
        onlyMyTip: "Näytä vain näytteenottoa",
        plInspector: "Valitse näytteenottohenkilö",
        allInspector: "Näytä kaikkia",
        seInspector: "Katso nimettyä näytteenottotarkastajaa",
        myInspector: "Näytä vain omani.",
        noInspector: "Tarkastetaan otantatarkastaja, jota ei ole siirretty",
        detail: { title: "Näytteenottoa koskevat tiedot" },
      },
      reviewList: {
        title: "Katsausluettelo",
        onlyMyTip: "Näytä vain arvostelu",
        plInspector: "Valitse arvostelija",
        allInspector: "Näytä kaikkia",
        seInspector: "Näytä nimetty arvostelija",
        myInspector: "Näytä vain omani.",
        noInspector: "Näytä varaamaton arvostelin",
        detail: { title: "Katsaus yksityiskohdat" },
      },
      caseBaseList: {
        title: "Kirjasto",
        del: "Poistaa",
        delTitle: "Kaikkia sisältöä ei tallenneta poistamisen jälkeen",
        cancel: "Peruus",
        actions: "Toiminto",
        seeCaseBase: "Näytä kirjaimet",
        seeDetail: "Näytä tiedot",
        edit: "Muokkaa",
        save: "Tallenna",
        addCaseBase: "Uusi kirjasto",
        export: "Vienti",
        ok: "Hyvä on.",
        tip: "Tyypilliset puhelutiedot ja keskustelutietot laaduntarkastuslistalla voidaan tallentaa kirjastoon helppoa oppimista ja jakamista varten.",
        detail: {
          choiceCaseBase: "Valitse tapaukset",
          delCaseBase: "Poista kirjaimet",
          addCaseBase: "Lisää kirjaimet",
          successed: "Tallenna onnistuneesti",
          info: "Tallenna tai peruuta aluksi muokkaamasi sisältö",
          caseType: "Valitse kirjasto, johon liittyy",
        },
      },
      testSetList: {
        title: "Laaduntarkastustesti",
        tip: "Laaduntarkastajan näytteenottotiedot säilytetään laatutarkastustestiin, joka on kätevä optimoida laadun tarkastusolosuhteiden ja sääntöjen määrittely joidenkin koneen tarkastus virhe kohteiden verrattuna näytteenottotarkastuksen jälkeen - Niin.",
        refresh: "Päivitä",
        reQualityInspectionUnderWay: "Arvioi tehtävä uudelleen...",
        reQualityInspectionUnderWayTip:
          "Sivun poistuminen ikkunan sulkemisen jälkeen ei vaikuta tehtävän",
        reQualityInspectionDone: "Uudelleenarviointi saatu päätös",
        reQualityInspectionDoneTip:
          "Uudelleenarviointi on onnistuneesti saatu päätökseen.",
        endReQualityInspection: "Lopeta tehtäväComment",
        alreadyDoneTip: "{0} valmistui",
        totalTip: "Yhteensä {0}",
        endReInspectionSuccess: "Uudelleenarviointitehtävä päättyi",
        closeWindow: "Sulje ikkuno",
        endReInspectionFailed:
          "Uudelleenarviointitehtävä epäonnistui, yritä myöhemmin uudelleen!",
        recheckPoint: "Laske uudelleen laatutarkastuspisteet ja tarkkuus",
        recheckPointTitle: "Uudelleenarviointivaihtoehdot",
        delete: {
          reQualityInspectionUnderWay: "Erä Poista tehtävät...",
          reQualityInspectionUnderWayTip:
            "Sivun poistuminen ikkunan sulkemisen jälkeen ei vaikuta tehtävän",
          reQualityInspectionDone: "Erän poisto tehtävä valmistui",
          reQualityInspectionDoneTip:
            "Poiste onnistuneesti saatu päätökseen, napsauta Vahvista-painiketta.",
          endReQualityInspection: "Lopeta tehtäväComment",
          alreadyDoneTip: "{0} valmistui",
          totalTip: "Yhteensä {0}",
          endReInspectionSuccess: "Erän poistaminen päättyi",
          closeWindow: "Sulje ikkuno",
          endReInspectionFailed:
            "Erän poisto epäonnistui, yritä myöhemmin uudelleen!",
        },
        reultList: {
          assessmentTime: "Viimeinen arviointiaika",
          assessmentAll: "Yhteensä",
          strip: "Artikla",
          spot: "Pistee",
          deviation: "Poikkeaminen",
          accuracyRate: "Tarkkuus",
          info: "Ei arviointituloksia, arvioi",
          onlyDeviationInfo: "Näytä poikkeamislevyt",
          againAssessment: "Uudelleenarviointi",
          screen: "Suodatin",
          batchDelete: "Poista erä",
          matching: "Täsmää",
          ok: "Kyllä.",
          noCancel: "Ei ei",
        },
      },
      smartWordsLibrary: {
        title: "Älykäs tesaurus.",
        tip1: "Synonyymit: käytetään vähentämään semanttisten merkkien koulutusta, samanlaisia synonyymien lauseita ei tarvitse kouluttaa toistuvasti.",
        tip2: "Professional substantiivit: Optimoida transkription ja luonnollinen kieli ymmärtää asianmukaisia substantiivia kuten yrityksen nimiä, tuotenimiä, ja alan sanoja, joita ei ole tarkasti tunnustettu parantaa tunnistamisen tarkkuutta ja analyysin tarkkuutta.",
        tip3: "Keskity sanoihin: Keskity sanoihin, joihin haluat keskittyä sanan pilvi-analyysissä auttaaksesi tekoälyn analyysin hotspotsit vastaamaan nykyiseen liiketoimintaan.",
        tip4: "Lohko sanat: Block sanoja, joihin et halua kiinnittää huomiota sanassa pilvi-analyysi auttaa tekoäly analysoimaan kuumia kohtia virran kanssa. Asiat.",
        tip5: "Sanapaino: käytetään korjaamaan ja optimoimaan semanttisten etikettien tunnistamista. Muokkaamalla sanojen vaikutuspainoa lauseissa, semanttinen tunnistusvaikutus tietyissä skenaarioissa voidaan optimoida tarvittaessa.",
        tip6: "Korpus-tiedot: Kirjoita suuria kappaleita tekstiä niiden liiketoiminta skenaarioiden transkoodaaminen virhekorjaus ja ASR koulutus parantaa ASR transkoodaamisen tarkkuutta.",
        upload: {
          uploadSynonymWords: "Tuo synonyymit",
          uploadProfessionalWords: "Tuonti ammattikäyttöehdot",
          uploadAttentionWords: "Tuo kiinnostavia sanoja",
          uploadIgnoreWords: "Tuo huomioi sanat",
          uploadWordsWeight: "Tuo painonsanat",
          uploadTypo: "Tuo kirjoitusjärjestelmiä",
          uploadCorpusData: "Tuo corpus-tiedot",
          uploadCorpusContent: "Tuo corpus-tietosisältöä",
          firstUploadStep: "1. Lataa malli ja täytä se mallin mukaan",
          downloadTemplateMessage: "Latausmalli:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Napsauta Tuo-painiketta lataa EXCEl-tiedoston täyttää mallin muodon mukaisesti",
          secondUploadCorpusDataStep:
            "2. Napsauta Tuo-painiketta ladataksesi TXT-tiedoston, joka on mallin mukainen.",
          postUploadDataFail:
            "Tietojen lähettäminen ja lähettäminen epäonnistui.",
          pleaseUploadTemplate: "Lataa EXCEl/JSON/TXT-tiedosto",
          templateNeedJSONOrXLSX:
            "Tiedostomallit voivat olla vain JSON- tai xlsx-muodossa",
          templateNeedTxt: "Corpus datamalli voi olla vain TXT- tiedosto",
          uploadSuccess: "Tuonti onnistui",
          uploadFailed: "Tuonti epäonnistui",
          downloadError: "Lataus epäonnistui",
          downLoadTxtTitle: "Corpus väittää. Txt",
        },
        navTabNames: {
          synonymWords: "Synonyymit",
          professionalWords: "Ammatillinen nimi",
          attentionWords: "Huolimattomat sanat",
          ignoreWords: "Älä huomioi sanat",
          wordsWeight: "Sanapaino",
          corpusData: "Yhteystiedot",
          typoLibrary: "Kirjoitusten asettaminen",
        },
        synonymWords: {
          createSynonymWords: "Uudet synonyymit",
          editSynonymWords: "Muokkaa synonyymit",
          addSynonymWords: "Lisää synonyymiä",
          pleaseEnterStandardWords: "Anna vakiosoa",
          synonymWordsExists: "Synonyymi on jo olemassa eikä sitä voi lisätä",
          synonymWordsCanNotNull: "Lisättyä synonyymiä ei voi olla tyhjä",
          pleaseAddSynonymWords: "Lisää synonyymit",
          addSuccess: "Uusi menestys",
          editSuccess: "Muutos onnistui",
          deleteSuccess: "Poistettiin onnistuneesti",
          deleteError: "Poista epäonnistui",
          required: "*",
        },
        professionalWords: {
          create: "Uudet ammatilliset termit",
          edit: "Professional substantiivin editori",
          addTargetWords: "Lisää korvaussa",
          targetWordsExists: "Korvaussana on jo olemassa, eikä sitä voi lisätä",
          targetWordsCanNotNull: "Lisättyä korvaussanaa ei voi olla tyhjä",
          pleaseAddTargetWords: "Lisää korvaussa",
          pleaseEnter: "Anna ammattimainen",
          wordsExists: "Termi on jo olemassa.",
          createSuccess: "Lisätty onnistuneesti",
          editSuccess: "Muutos onnistui",
          deleteSuccess: "Poistettiin onnistuneesti",
          deleteError: "Poista epäonnistui",
          saveError: "Tallennus epäonnistui",
          addTargetWordsMaxNumberError: "Voit lisätä jopa 100 korvaavan sanaa",
          addHomophonyWordsMaxNumberError:
            "Voit lisätä jopa 100 naamioitua sanaa",
        },
        attentionWords: {
          create: "Uusi huolestuneisuus",
          edit: "Huolestunut sanamuokkain",
          pleaseEnter: "Anna merkittävä sana.",
          wordsExists: "Kiinnostava sana on jo olemassa.",
          createSuccess: "Lisätty onnistuneesti",
          editSuccess: "Muutos onnistui",
          deleteSuccess: "Poistettiin onnistuneesti",
          deleteError: "Poista epäonnistui",
        },
        ignoreWords: {
          create: "Uusi huomioi sanaa",
          edit: "Jätä sanamuokkaukset",
          pleaseEnter: "Syötä huomioi",
          wordsExists: "Jätetään sana on jo olemassa",
          createSuccess: "Lisätty onnistuneesti",
          editSuccess: "Muutos onnistui",
          deleteSuccess: "Poistettiin onnistuneesti",
          deleteError: "Poista epäonnistui",
        },
        wordsWeight: {
          createWordsWeight: "Uusi painos",
          editWordsWeight: "Muokkaa painonsanaa",
          addWordsWeight: "Lisää painos",
          pleaseEnterWeightWords: "Anna painona",
          pleaseEnterWeight: "Anna paino.",
          weightNeedInZeroToHundred: "Painon on oltava 0 ja 100.",
          synonymWordsExists: "Painosana on jo olemassa",
          weightNeedNumber: "Paino vaatii kokonaislukumuotoa",
          standardWeightInformation:
            "Vertailuarvo on 50, ja mitä suurempi paino, sitä vahvempi vaikutus analyysi.",
          createSuccess: "Lisätty onnistuneesti",
          editSuccess: "Muutos onnistui",
          deleteSuccess: "Poistettiin onnistuneesti",
          deleteError: "Poista epäonnistui",
        },
        corpusData: {
          createCorpusData: "Uudet Corpus- tiedot",
          editCorpusData: "Muokkaa Corpus-tietoja",
          addCorpusData: "Lisää Corpus-tiedot",
          pleaseEnterCorpusDescription: "Anna kehon kuvaus",
          pleaseEnterCorpusContent: "Syötä kokoonpanon sisältö",
          synonymWordsExists: "Nämä tiedot ovat jo olemassa",
          CorpusContentWordsOverSetting:
            "Sisällön sanamäärä ylittää 20000 sanaa,",
          download: "Lataus",
          upload: "Lähetä",
          see: "Näytä",
          canNotSave: "Käytä tuontitoimintoa, muuten sitä ei voi tallentaa",
          createSuccess: "Lisätty onnistuneesti",
          editSuccess: "Muutos onnistui",
          deleteSuccess: "Poistettiin onnistuneesti",
          deleteError: "Poista epäonnistui",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Oikea sanana",
            wrongWord: "Väärä sanana",
            wrongWordCount: "Väärien sanojen määrä",
            wrongWordTip: "Paina Enter lisätäksesi",
            status: "Tehokkuus",
          },
          create: "Uudet kirjoitusta",
          edit: "Muokkaa kirjoitusta",
          tip: 'Kirjoitustekstiä käytetään optimoimaan "tyyppiset havaitsemisen" vaikutuksen. Esimerkiksi, jos "palautus" on virheellisesti lähetetty "leveyden palautus", yhteisiä kirjoituksia ja oikeita sanoja voidaan asettaa tämän funktion kautta, ja se tulee voimaan seuraavana päivänä säästämisen jälkeen.',
          onlyChinese: "Tue vain kiinan sanat",
          wrongWordLengthErr:
            "Jokaisen väärän sanan pituus pitäisi olla 2 ~ 10 sanaa",
        },
      },
      semanticTags: {
        title: "Semanttinen merkintä",
        current: "Nykyinen semanttinen nimi",
        reference1: "Laatutarkastustehtävä: {0} - {1}",
        reference2: "Älykäs tunnus: {0}",
        reference3: "Tietojen esikäsittely: {0}",
        reference4: "Tietopohja: {0}",
        index: { import: "Tuo semanttiset nimit" },
        detail: {
          new: "Uusi semanttinen nimi",
          edit: "Muokkaa semanttinen nimi",
          name: "Nimi",
          standardSentences: "Vakiolauseke",
          effectiveness: "Tehokkuus",
          similarSentences: "Samanlaisia lauseita",
          tip: "Tuki käyttämällä μέ # soittaa entity API, esimerkiksi: Onko matkapuhelinnumero# sys_puhelin?",
          excludeSimilarSentences: "Vastaavat virkkeet",
          intelligentRecommend: "Älykäs suositus samankaltaisesta sisällöstä",
          fastTraining: "Pikakoulutus:",
          qualify: "Säännöllisenä",
          recallKeyWord: "Palauta avainsanat",
          excludeKeywords: "Poista avainsanat",
          excludeKeywordsInfo1:
            "Jättäkää avainsanoja ja säännölliset ilmaisut ovat etusijalle kaikkien logiikkaan nähden. Jos ne suljetaan pois, semanttiseen merkkiin ei osu.",
          keyWordInfo1:
            "Useita palautus avainsanoja voidaan täyttää kussakin rivissä, erotettu Englanti pilkku. Kun useita palauttaa avainsanoja täytetään, logiikka on, että kaikki osumat osuvat.",
          keyWordInfo2:
            "Recall avainsanoja voidaan täyttää useita riviä. Kun useita linjoja täytetään, logiikka voidaan asettaa mikä tahansa osuma.",
          recallRegular: "Palauta säännöllisen",
          excludeRegular: "Säännöllinen ulkopuolelle",
          similaSentences: "Vastaavat virkkeet",
          simim: "Samankaltaisuus",
          keyWordInfo3:
            "Vastaavat lauseet ovat etusijalla kaikkeen logiikkaan nähden. Jos vastaava lause osuu, semanttiseen merkkiin ei osu. Oletusarvo samankaltaisuus on 80%, ja mitä suurempi yhtäläisyys, sitä samankaltaisempi se on.",
          keyWordInfo4:
            "Sääntö on samanlainen kuin säännöllinen, oletus aloitettu ja loppu. Ei välttämättä käytetä suluissa, esim. [ei XXXX]",
          keyWordInfo5:
            "Kehittyneen moduulin toiminnot ovat yleensä kokeellisia toimintoja asiantuntijoille, jotka tuntevat toimintaa. Jos et ole varma, miten käytetään, on suositeltavaa, ettet muuta tätä kohtaa.",
          keyWordInfo6:
            "Ristitunnistusominaisuus antaa semanttisille tunnisteille kyvyn tunnistaa lauseet.",
          keyWordInfo7: "Korkeintaan peräkkäin samalla roolilla",
          keyWordInfo8:
            "Jätä huomioimatta eri roolit Lauseen pituus ei ole suurempi.",
          keyWordInfo9: "Lauseet ristikäsikirjan havaitsemiseksi",
          keyWordInfo10:
            "Pisimmän ristiin raja-lausekkeen pituus ei ole enempää.",
          keyWordInfo11: "Poista vastaava prosenttiosuus vähintään:",
          keyWordInfo12: "Yhtäläisyys ei ole pienempi.",
          keyWordInfo13:
            "Prosenttia vastaavista lauseista vastaa samalla tavalla (oletusvastaavuus 80 %)",
          keyWordInfo14:
            "Samanlainen poistaminen vastaa lauseita, jotka perustuvat niiden samankaltaisuuteen. On suositeltavaa poistaa lauseet, jotka ovat liian samankaltaisia havaitsemisen tehokkuuden parantamiseksi.",
          keyWord: "Asiasanat",
          entity: "Yhteisö",
          ruleField: "Käsitteet sanat",
          customRule: "Puhesääntön suunnittelu",
          fanhua: "Samanlaisia kysymyksiä",
          test: "Testi",
          testOk: "Osumatesti",
          senior: "Lisäasetukset",
          seniorList: "Ristimittauksen havaitseminen",
          up: "Ota käyttöön",
          down: "SuljeComment",
          ju: "Tuomio",
          oneWord: "Sana",
          simimNo: "Samanlainen kuin paino",
          seniorField: "Samankaltaisuuskynnysarvo",
          save: "Tallenna",
          cancel: "Peruus",
          specialElements: "Erityiset tekijät",
          logicRule: "Logiikkasäännöt",
          every: "Useita osumia",
          recallOk: "Paina uudelleen sanaa:",
          recallNo: "Jättänyt palautussanan",
          ruleOk: "Hitti säännöllinen palautus",
          ruleNo: "Säännöllinen",
          rularOk: "Lyö säännöt",
          rularNo: "Sääntö epäonnistui",
          infoOk: "Hit vakiorake tai vastaava virke:",
          infoNo: "Vakiolauseen tai vastaavaan virkkeeen",
          fieldOk: "Osuma, jotta voidaan sulkea pois vastaavia lauseita:",
          fieldNo: "Jättämättä vastaavat virkkeet",
          excludeRuleNo: "Neiti Exclusion Regex",
          excludeRuleOk: "Osuma säännöllinen poissulkeminen",
          excludeKeywordNo: "Missit pois lukien avainsanat",
          excludeKeywordOk: "Osuma pois avainsanat",
          update: "Päivitys onnistui",
          fanhuaList: "Lähetetty yleistysvälilekkeeseen",
          wordList:
            "Harjoituslista on tyhjä eikä sitä voi lähettää yleisluetteloon",
          start: "Käynnistä",
          end: "LoppuComment",
          keyWordInfo15:
            "Yleisluettelossa on samanlaisia kenttiä (vastaavat poistumisrajoitukset kehittynyt kokoonpano)",
          saveSuccess: "Tallenna onnistuneesti",
          errInfo: "Joitakin kenttiä ei täytetä tai täytetä virheellisesti",
        },
        table: {
          deepTraining: "Syvä harjoittelu {0}",
          fastTraining: "Nopea harjoittelu",
        },
      },
      intelligentCollection: {
        title: "Älykäs hankinta",
        classification: "Ryhmäluettelo",
        new: "Uusi kokoelma",
        table: {
          name: "Kenttä nimi",
          props: "Asiakkaiden ominaisuudet",
          type: "Kenttätyyppi",
          desc: "Kentän kuvaus",
        },
        detail: {
          isCustomerProps: "Tallenna asiakkaiden attribuutit",
          customerPropsHelper:
            "Tallentamisen jälkeen kerätyt tiedot päivitetään asiakastietoihin. Älä tallenna, älä päivitä asiakastietoja",
          name: "Kenttä nimi",
          type: "Kenttätyyppi",
          text: "Teksti",
          desc: "Kentän kuvaus",
          descPlaceHolder: "Kuvaa tämän kentän käyttöä tietojen keräämiseen",
          condition: "Tila",
          logic: "Säännöt",
          method: "Hankintamenettely",
          message: "Tiedon sisältö",
          range: "Uutosalue",
          entity: "Yhteisöt",
          base: "Perustiedot",
          collect: "Kerää tietoa",
        },
      },
      intelligentTags: {
        title: "Älykäs nimi",
        sessionLabelTitle: "Istunnon skenaariot",
        keyEventsTitle: "Avaintapahtumat",
        customerLabelTitle: "Asiakasmerkki",
        addClassification: "Luo uusi merkintäluokitus",
        defaultCategoryName: "Oletusluokitus",
        index: {
          createTag: "Uusi nimi",
          createEvent: "Uusi tapahtuma",
          editTagClassification: "Muokkaa merkin luokittelua",
          selectTagClassification: "Valitse nimike luokittelu",
          editTagGrounp: "Muokkaa ryhmittymistä",
          callTimeError:
            "Valittu puheluaika / keskustelun alkamisajankohta ei saa ylittää kuukautta (31 päivää)",
          callRecordCountError:
            "Tietueiden määrä on 0, valitse uudelleen puhelu- ja keskusteluaika.",
          selectItmeCountError:
            "Valitse älymerkki, jolle haluat suorittaa irtotoimen",
          redoSuccess:
            "Uudelleen suorittaminen on onnistunut, mene tehtäväkeskukseen nähdäksesi",
          redoError:
            "Uudelleen suorittaminen epäonnistui, yritä myöhemmin uudelleen",
          timeError: "Kyselyaika ei saa ylittää yhtä kuukautta (31 päivää)",
          categoryName: "Luokan nimi",
          categoryColor: "Luokitusväri",
          newTagClassification: "Uusi merkintä luokittelu",
          newEventClassification: "Uusi luokka",
          tagClassification: "Merkintäluokitus",
          eventClassification: "Tapahtumien luokitus",
        },
        detail: {
          new: "Uusi älykäs nimi",
          edit: "Muokkaa älymerkkiä",
          tagName: "Nimi",
          tagDesc: "Kuvaus",
          category: "Luokittelu",
          tagStatus: "Tehokkuus",
          operator: "Toiminnanharjoittaja",
          rule: "Säännöt",
          labelClassAtion: "Merkintätyyppi",
          labelClassAtionTip1:
            "Kutsutun merkinnät: sääntöihin perustuva merkintä",
          labelClassAtionTip2:
            "Luokitusmerkintä: Mallikoulutukseen perustuva merkintä",
          group: "Ryhmittäminen",
          checkLabel: "Valitse luokka",
          warn: "Varoitus",
          warnTips:
            "Kun hälytys on käynnistetty, kun istunto täyttää tärkeän tapahtuman sääntön, nimetty henkilö pyydetään, ja se voi vastaanottaa muistutuksen tehtäväluettelosta.",
          warnError: "Valitse hälytys määritelty henkilökunta",
        },
        subTitle: {
          common: "Älykäs istunnon tunnisteet",
          wecom: "Yrityksen mikromerkintä",
        },
      },
      customerPortrait: {
        title: "Asiakaskuvaa",
        index: { import: "Tuo asiakaskuvaa" },
        searchPlaceHolder: "Asiakastunnus, nimi tai puhelin",
        callCount: "Keskustelujen määrä:",
        callDuration: "Puhelun kokonaiskesto:",
        dialogCount: "Keskustelujen kokonaismäärä:",
        keyWordsStatistics:
          "Avainsanojen tilastot (Kymppi 20 ja huolenaiheena)",
        keyWordsCount: "({0})",
        businessTag: "Yritysmerkintä",
        keyWordsCloudImg: "Asiasanat Pilvikaavio",
        customerEmotionTrend: "Asiakkaiden tunteiden suuntaukset",
        notFoundCustomer: "Etuyhteydessä olevia asiakkaita ei palautettu",
        noKeyWords: "Siihen liittyviä avainsanoja ei löytynyt",
        noBusinessTags: "Liiketoiminnan tunnisteita ei löytynyt",
        getKeyWordsStatisticsDataError:
          "Asiakkaan avainsanojen tilasto kysely epäonnistui",
        getBusinessTagsStatisticsDataError:
          "Asiakasliiketoiminnan etiketin kysely epäonnistui",
        personCustome: "Asiakastiedot, joita ei ole kyselyt",
        notimeInfo: "Tunteita ei löytynyt.",
        timeList: "Päivämääräalue",
        operator: "Älykäs nimi",
        placeHolder: "Valitse:",
        placeOperator: "Valitse älykäs nimi",
        search: "Kysely",
        reset: "Nollaa",
        searchHolder: "Etsi:",
        call: "Puhelu",
        text: "Dialogi",
      },
      sentimentAnalysis: { title: "Yleinen mielipide löydi" },
      saleSentimentAnalysis: { title: "Yleinen mielipide analyysi" },
      dataPreprocess: {
        title: "Tietojen esikäsittely",
        index: {
          createData: "Uudet tiedot",
          selectDataSource: "Valitse tietolähde",
          selectStatus: "Valitse tehokkuus",
          callTimeError:
            "Valittu puheluaika / keskustelun alkamisajankohta ei saa ylittää kuukautta (31 päivää)",
          callRecordCountError:
            "Tietueiden määrä on 0, valitse uudelleen puhelu- ja keskusteluaika.",
          selectItmeCountError:
            "Valitse tiedot, joita suoritetaan irtotoiminta",
          redoSuccess:
            "Uudelleen suorittaminen on onnistunut, mene tehtäväkeskukseen nähdäksesi",
          redoError:
            "Uudelleen suorittaminen epäonnistui, yritä myöhemmin uudelleen",
        },
        detail: {
          new: "Uudet tiedot",
          edit: "Muokkaa dataa",
          name: "Nimi",
          desc: "Kuvaus",
          dataSource: "Tietolähde",
          status: "Tehokkuus",
          entity: "Yhteisöt",
          operator: "Toiminnanharjoittaja",
          rule: "Säännöt",
        },
      },
      connectedByConversation: {
        title: "Viestintäkeskustelujärjestön",
        list: {
          title: "Viestintäikkunan assosiaatiotoiminto",
          info: "Keskusteluyhdistyksen toiminto voi yhdistää erilaisia puheluja ja keskusteluja asetettujen sääntöjen mukaisesti.",
          rule: "Keskusteluyhdistyssäännöt",
          source: "Valitse tietolähde",
          classification: "Valitse luokka",
          choose: "Valitse:",
          input: "Syötä",
          save: "Tallenna",
          cancel: "Peruus",
          fieldwei: "Biti",
          fieldNot: "Täyttämättömiä kenttiä",
          fiedldlengthNo: "Kentän pituus ylittää rajan",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip: "Useita avainsanoja voidaan asettaa, erottaa pilkuilla.",
          applyRole: "Tunnista rooli",
          preOperator: "Esikäyttöoikeus",
          applyOperatorScope: "Tunnistusalue",
          addOperator: "Lisää operaattori",
          noPreOperator: "Ei ole.",
          from: "- En.",
          to: "Tuomio ei.",
          end: "Tuomio",
          testRegular: "Testaa säännölliset lausekkeet",
          test: "Testi",
          matchSuccess: "Täsmää onnistuneesti",
          matchError: "Tulos epäonnistui",
          querySementicTags: "Valitse semanttinen nimi",
          reQuerySementicTags: "Valitse semanttiset merkinnät",
          sementicTag: "Semanttinen merkintä",
          standardSentence: "Vakiolauseke",
          similarity: "Samankaltaisuus",
          actions: "Toiminto",
          noFitTag: "En löytänyt oikeaa etikettiä.",
          addSemanticTag: "Lisää uusi semanttinen tagi",
          pleaseEnterRegular: "Anna säännöllinen lauseke",
          pleaseEnterRegularText: "Anna tarkistettava sisältö",
          newAssociateComponentTip:
            "Jos arvo on numeerinen tyyppi, käytä perintöominaisuutta",
        },
        filterManage: {
          noValueTip: "Ei tietoa",
          allCall: "Kaikki puhelut",
          allDialogue: "Kaikki dialogit",
          allWechat: "Kaikki istunnot",
          getFiltersListFailed:
            "Suodinlista ei voitu hakea, yritä myöhemmin uudelleen!",
          getFilterDetailFailed:
            "Suodattimen yksityiskohdat epäonnistui, yritä myöhemmin uudelleen!",
          getAllCountFailed:
            "Puhelujen ja keskustelujen kokonaismäärä ei saa, yritä myöhemmin uudelleen!",
        },
        listManage: {
          searchPlaceHolder: "Anna avainsanahaku",
          filterConditions: "Suodatin tila",
          records: "Tietue",
          getRecordsFailed: "Tietue ei saanut, yritä myöhemmin uudelleen!",
        },
        miniListManage: {
          getRecordsFailed: "Tietue ei saanut, yritä myöhemmin uudelleen!",
        },
        applyTemplates: {
          title: "Sovellettava laatutarkastusmalli",
          collectionTitle: "Sovellettava hankintamenettely",
        },
        recordDetail: {
          showWaveAudio: "Näytä nauhoitus",
          getDetailFailed:
            "Yksityiskohtia ei saatu, yritä myöhemmin uudelleen!",
          getSmartTagsFailed:
            "Älykäs etiketti epäonnistui, yritä myöhemmin uudelleen!",
          getTemplatesFailed:
            "Sovellettava malli epäonnistui, yritä myöhemmin uudelleen!",
        },
        changeList: {
          sampleStatus: "Näytteenottotilanta",
          sampleAssignInspector: "Anna näytteenottotarkastaja",
          reviewAssignInspector: "Antaa arvostelu",
          read: "Lue",
          distributivePerson: "Myönnetty henkilö.",
          totalScore: "Pisteet yhteensä",
        },
        qualityCheckScore: {
          totalScore: "Pisteet yhteensä",
          actualScore: "Pisteet",
          actualGrade: "Luokitus",
          appeal: "Valitus",
          remark: "Huomautukset",
          manualRemark: "Manuaaliset huomautukset",
          none: "Ei yksityiskohtaisia tietoja laaduntarkastuksissa",
          interactiveTip:
            "Käytä vuorovaikutustunnistusta tunnistaaksesi asiakaspalvelun käytön FAQ",
          interactiveIsEmptyTip:
            "Interaktiivista tunnistussisältöä ei havaittu",
        },
        semanticMark: {
          semanticContent: "Merkintälauseke",
          markedSimilarTitel: "Samanlaiset semanttiset nimit",
          markedExcludeTitle:
            "Merkitty jättämään samanlaisia semanttisia tunnisteita",
          taskTagTitle: "Tehtävään liittyvät semanttiset merkinnät",
          similarTagTitle:
            "Tunnista samanlaiset semanttiset tunnisteet (vastaavat > 40%, Top 20)",
          delete: "Poistaa",
          markSimilar: "Merkitse vastaavaa",
          markExclude: "Poista vastaavaa",
          none: "Ei ole.",
        },
        selectCallTime: {
          callTime: "Puheaika/Session aloitusaika",
          callTimeRemind:
            "Voit valita enintään kuukauden (31 päivää) keskustelutietoja viime vuonna.",
          callRecordCount: "Olet valinnut yhteensä {0} puhelutiedot",
          getCallRecordError:
            "Tietue ei voitu hakea, yritä myöhemmin uudelleen",
        },
      },
      informationEntities: {
        title: "Tietoyksikkö",
        index: { import: "Tuontitietoyksiköt" },
        detail: {
          new: "Uusi tietoyksikkö",
          edit: "Muokkaa tietoyksikköä",
          name: "Nimi",
          apiName: "API- nimi",
          activeFlag: "Tehokkuus",
          remark: "Kuvaus",
          logicExp: "Säännöllinen yhteisö",
          sentenceList: "Sisältö",
          tip: "Entitysisältö tukee useita merkintöjä, jotka erotetaan välilyönteillä, jotka ovat saman entiteetin synonyymiä.",
          remarkLengthError: "Kuvaus pituus ei saa ylittää 255",
          nameLengthError: "Nimen pituus ei saa ylittää 40.",
          entityDuplicateError: "Kaksinkertainen entiteetin sisältö",
        },
      },
      interactiveRecognition: {
        title: "Interaktiivinen tunnustaminen",
        classify: "Luokittelu",
        addClassify: "Lisää luokkaa",
        editClassify: "Muokkaa luokkaa",
        index: { import: "Tuo vuorovaikutustunnistus" },
        detail: {
          new: "Uusi älykäs vuorovaikutus",
          edit: "Muokkaa älykästä vuorovaikutusta",
          type: "Tyyppi",
          standardQuestion: "Vakiomuotoiset kysymykset",
          similarQList: "Samanlaista ongelma",
          exceptSimilarQList: "Poista samanlaisia ongelmia",
          category: "Luokittelu",
          targetType: "Ongelmien havaitsemisen tavoite",
          status: "Tehokkuus",
          standardAnswer: "Vakiovastaso",
          similarAList: "Samanlaisia vastauksia",
          wrongAList: "Väärä vastaus",
        },
      },
      businessAnalysis: {
        title: "Liiketoiminnan analyysi",
        tip1: "Liiketoiminnan trendi analyysi: vertikaalinen koordinaatti on keskustelujen määrä, I . E. ehdot täyttävien keskustelujen kokonaismäärä ja vaakasuuntainen koordinaatti on aika.",
        tip2: "Korrelaatioanalyysi: analysoida korrelaatio useiden indikaattorien, hiiren jousitus näyttö korrelaatiodata, datavolyymi, korrelaatio, toistonopeus",
        tip3: "Muutosanalyysi: useiden indikaattorien analysointi voi muodostaa keskinäisen muuntosuhteen, samalle puhelulle/keskustelulle muuntamiskriteerit, jos voit osua kahteen eri indikaattoriin, voit katsoa, että kaksi indikaattoria on muuntosuhde.",
      },
      knowledgeBase: {
        title: "Tietopohja",
        list: {
          add: "Lisää alaluokka",
          delete: "Poista luokittelu",
          categoryName: "Luokan nimi",
          createSuccess: "Alaluokka luotiin onnistuneesti",
          keyWord: "Asiasanat",
        },
        detail: {
          type: "Tyyppi",
          status: "Tilanne",
          classification: "Luokittelu",
          standardQuestion: "Vakiomuotoiset kysymykset",
          answerDetectionRange: "Vastausten havaitsemisalue",
          standardAnswer: "Vakiovastaso",
        },
      },
      callManage: {
        title: "Puhelun tallennussuodatit",
        listTitle: "Puhelun hallinta",
        detailTitle: "Puhelun yksityiskohdat",
      },
      dialogueManage: {
        title: "Keskustelun tekstisuodatin",
        listTitle: "Vuoropuhelu",
        detailTitle: "Ikkunan yksityiskohdat",
      },
      weChatManage: {
        title: "Enterprise We chat- istuntosuodinName",
        listTitle: "Enterprise WeChat istuntohallinta",
        detailTitle: "Enterprise We chat- istunnon yksityiskohdat",
      },
      speechRecognition: {
        title: "Puheen tunnistaminen",
        asr: "Puheen tunnistaminen",
        asrRemind:
          "Kytkin pois päältä aiheuttaa puhelu-tekstin toiminnon ja koneen laadun tarkastustoiminnon pois käytöstä.",
        asrConfig: "ASR-suodtaus",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "Kun käytössä, ASR- transkriptio IM:stä",
        asrConfigRemind:
          "Kun käytössä, vain hyväksyttävät puhelut suoritetaan ASR-sisältö",
        correction: "Älykäs virhekorjaus",
        correctionRemind:
          "Kun käytössä, sisältö \"<a href = '/site/semantic- Intelligence/smart-words-kirjailu/professional-words ' tavoite ='_blank'> Smart Thesaurus-Professional Nouns Uans>\"",
        correctionConfig: "Virhekorjaussuodatin",
        advancedSettings: "Lisäasetukset",
        advancedSettingsTip:
          "Valitsee määritellyn ASR-mallin määritellylle datalle.",
        mandarinASRModel: "Mandariini ASR mallille",
        cantoneseASRModel: "Yhdistynyt kuningaskunta",
        englishASRModel: "Englanti ASR malli",
        correctionConfigRemind:
          "Kun käytöstä on käytössä, vain puhelut, jotka täyttävät seuraavat edellytykset korjataan.",
        customJudgeLogicIsNull: "Oma logiikka ei voi olla tyhjä",
        redoCorrection: "Uudelleen tehtävä virheiden korjaus",
        callTime: "Valitse puheaika",
        callTimeRemind:
          "Voit valita enintään kuukauden (31 päivää) puhelutiedot viime vuonna.",
        callRecordCount: "Olet valinnut yhteensä {0} puhelutiedot",
        redoCorrectionSubmit: "Lähetä suoritustaksi",
        callTimeError:
          "Valittu puhelun aikaväli ei saa ylittää yhtä kuukautta (31 päivää)",
        callRecordCountError:
          "Puhelutinteiden lukumäärä on 0, valitse uudelleen puheluaika uudelleen.",
        redoCorrectionSuccess:
          "Uudelleenkorjaus on onnistunut, mene tehtäväkeskukseen nähdäksesi",
        redoCorrectionError:
          "Uudelleenvirhe epäonnistui, yritä myöhemmin uudelleen",
      },
      role: {
        title: "Roolihallinta",
        create: "Uusi rooli",
        detail: {
          title: "Rooli",
          new: "Uusi rooli",
          view: "Näytä roolit",
          edit: "Muokkaa rooli",
          navTabNames: {
            basicInfo: "Valikon oikeudet",
            taskPermissions: "Laadun tarkastustehtävälupa",
            dataPermissions: "Tietojen oikeudet",
          },
          basicInfo: { roleName: "Nimi", permissions: "Oikeudet" },
          taskPermissions: {
            allTask: "Kaikki laatutarkastustehtävät",
            myCreatedTask: "Laadun tarkastustehtävä, jonka olen luonut",
          },
          dataPermissions: {
            dataPermissionsConfig: "Tiedon käyttölupa",
            tips: "Tämä rooli näkee tiedot valitun luokituksen alla.",
            select: "Valitse luokka",
            selectPlaceholder:
              "Valitse luokitus, joka edellyttää tietojen näkyvyyslupaa",
          },
        },
      },
      dataMigration: {
        title: "Tietojen siirto",
        dataUpload: "Tietojen siirto",
        dataDowload: "Tietojen siirto",
        error: "Valitse laatutehtävä vientiä varten",
        list: {
          info: "Valitse laatutarkastustehtävä, joka siirtyy pois.",
          listInfo:
            "Valitse, siirretäänkö laatutarkastustehtävään liittyvät tiedot (kuten älykkäät tesaurus, semanttinen tunniste, tiedotusyhteisö, älykäs vuorovaikutus jne.)",
          noInfo: "Valitse, siirretäänkö pois muita kuin QC-tietoja",
          ownInfo: "Siirrä vain tehtäviä",
          everyInfo: "Siirtymiseen liittyvät tehtävät",
          allInfo: "Siirry pois kaikista.",
          callFilter: "Kutsusuodatin",
          talkFilter: "DialogisuodinName",
          smartTag: "Älykäs nimi",
        },
        upload: {
          info: "1. Napsauta Upload-painiketta ladataksesi JSON-tiedoston, joka vastaa muotoa.",
          uploadSuccess: "Lähetä onnistuneen",
          uploadError: "Lähetys epäonnistui",
        },
      },
      dataAnalysis: {
        businessStatistics: "Yritystilastot",
        qualityEffect: "Terveystarkastukset",
        qualityAnalysis: "Laatutarkastuksen analyysi",
        generalInspecitionReport: "Yleiskertomus",
        fullAmountWork: "Laaduntarkastajan työvoimat",
        intelligentTagsAnalysis: "Merkinnän analyysi",
        saleIntelligentTagsAnalysis: "Älykäs label-analyysi",
        generalInspecitionReportTip:
          "Kokonaistiedot: laskee puhelu- ja keskustelutietojen kokonaismäärän nykyisessä määräajassa ja tehtäväalueessa, älykkäiden laatutarkastustietojen lukumäärä ja prosenttiosuus, manuaalisten otantatietojen lukumäärä ja prosenttiosuus, valitusten lukumäärä, näytteenottoaste ja aika trendi kaavio.",
        groupTest: "Ryhmätesti",
      },
      uploadDownloadList: {
        title: "Tehtäväkeskus",
        tabs: {
          upload: "Lähetä tuontia",
          download: "Lataa vientiä",
          taskCenter: "Tehtävän suoritus",
        },
      },
      uploadDownloadModel: {
        title: "Lataa lataussuhteet",
        list: {
          clear: "Tyhjä",
          success: "Onnistui",
          fail: "Epäonnistui",
          hand: "Käynnissä olevat toimet",
          noInfo: "Ei tietoa",
        },
      },
      billingCenter: {
        title: "Laskutuskeskus",
        export: "Vienti",
        limit: "Muistutuksen raja-arvo",
        tips: "Laskutustulokset ovat eriarvoisia pyöristämisen vuoksi, ainoastaan viitearvot",
        dashboard: {
          remainingQuota: "Nykyinen jäljellä oleva kiintiö",
          usedQuota: "Kumulatiivinen käytetty kiintiö",
          voiceQuality: "Puhelun laatutarkastus:",
          voice: "Ääni:",
          textQuality: "Tekstin laatutarkastus:",
          text: "Teksti:",
          hour: "Tunnit",
          tenThousandHour: "Miljoonaa tuntia.",
          day: "Päivä.",
          cutoff: "Leikkaus",
          warning:
            "(Muut kiintiöt ovat riittämättömät, lataa se mahdollisimman pian)",
          isClosed: "(Suljettu tila)",
        },
        table: {
          quotaRecord: "Kiintiötietoja",
          timeFilter: "Valitse päivämäärä:",
          search: "Kysely",
          reset: "Nollaa",
          id: "Sarjanumero",
          type: "Tyyppi",
          qualityType: "Laaduntarkastustyyppi",
          quotaChange: "Muutta",
          surplus: "Saldo",
          changeTime: "Aika",
          voiceQuality: "Puhelun laatutarkastus",
          textQuality: "Tekstin laatutarkastus",
          sizeChangeText: "Näytä sivua kohti",
          used: "Käyttä",
          give: "Avustus",
          deduction: "Vähennys",
          callTime: "Puheaikaan",
          count: "Tietojen käsittely",
          page: "Sivu {1}/{0}",
        },
        limitModal: {
          call: "Puhelun laadun tarkastuskiintiö on alhaisempi.",
          callFix: "Tunteja muistuttaa",
          dialogue:
            "Jäljellä oleva aika tekstin laadun tarkastuksessa on lyhyt",
          dialogueFix: "Päiviä muistuttaa",
        },
      },
      messageCenter: {
        replyTitle: "Vastaa minulle",
        answerTitle: "Minun...",
        shareTitle: "Jaa minun.",
      },
      columnsManage: { title: "Laaduntarkastuskentän hallinta" },
      recordUpload: {
        list: {
          task: "Laaduntarkastustehtävät",
          user: "Toiminnanharjoittaja",
          uploadTime: "Lähetysaika",
          endTime: "Loppuaika",
          total: "Lähetys yhteensä",
          success: "Laadunvalvonnan menestys",
          failed: "Laadun tarkastus epäonnistui",
          progress: "Laaduntarkastuksen edistyminen",
          inProgress: "Käynnissä olevat toimet",
          finished: "Valmistui",
        },
        steps: {
          uploading: "Lähetä tallennustiedostoa",
          baseInfo: "Täytä perustiedot",
          start: "Aloita laatutarkastus",
        },
        uploadComponent: {
          dragger: "Raahaa ja pudota tiedostoja",
          or: "Ei",
          selectFile: "Valitse tiedosto",
          des: "Tuki wav, mp3-muoto, näytteenottonopeus 8000Hz,16-bittinen tiedosto",
          limit1: "Lataa korkeintaan kerran.",
          limit2: "Tiedostot, jokainen tiedostoko ei ylitä",
          uploading: "Lähetetän",
          finished: "Lähetys valmistui",
          success: "Onnistui",
          failure: "Epäonnistui",
        },
        baseInfo: {
          task: "Valitse laatutehtäväComment",
          type: "Valitse nauhoituslomake",
          agentSide: "Kanava, johon asiakaspalvelu sijaitsee",
          excel: "Lähetä tietoja matkan varla",
          checkFileInfo: "Tarkista tiedostotiedot",
          excelFileError: "Lataa tietoja matkan varrella.",
          checking: "Kalibrointi...",
          reCheck: "Uudelleenkalibrointi",
          success: "Tarkastusmenestys",
          excelUoloadFail: "Tietojen lähettäminen reitille epäonnistui.",
          tips: {
            tip1: "1. Ecxelin tallennustiedoston nimen on oltava sama kuin lähetetyn tallennuksen nimi, eikä se voi olla sama nimi.",
            tip2: "2. Järjestelmä tarkistaa kaksoiskappaleen tallennustiedoston nimi ja puhelun tunnus. Toistuvat tiedot johtavat laaduntarkastuksen epäonnistumiseen.",
            tip3: "3. Ladattu Excel tallennusmalli, fontin väri on punainen, joka on vaadittu kenttä.",
            tip4: "4. Aikatyypin kenttämuoto on: yyyy-mm-dd hh:mm:ss 2021-04-20 17:36:30",
          },
        },
        title: "Paikallinen tallentaminen",
        checkTitle: "Laaduntarkastuksen kirjaaminen",
        start: "Aloita laatutarkastus",
        disabledTips: "Tarkista tallennustiedosto ennen laatutarkastusta",
        tips: "Tallennustiedosto samalla nimellä korvaa laatutarkastuksen tulokset ja perustiedot.",
        tips1: "Raahaa ja pudota tiedosto tähän tai napsauta Lähetä",
        tips2: "Tuki",
        tips3: "Muoto",
        type: "wav、mp3",
        tips4: "Lataa korkeintaan kerran.",
        tips5: "Tiedostot, jokainen tiedostoko ei ylitä",
        tipsOverSize:
          "Nykyinen jono on täynnä. Poista tiedostot jonossa ennen lähettämistä.",
        size: "500M",
        count: "20",
        uploaded: "Tallennustiedosto lähetetty",
        uploading: "({0}%)",
        uploadFinished: "(Ulostettu valmis)",
        bottomTitle: "Tietojen keruu:",
        recordType: "Valitse tallennuslomake:",
        double: "Kaksikanavainen puhelu",
        single: "Yhden kanavan puhelun tallentaminen",
        recordBaseInfo: "Lataa tallentamisen perustiedot:",
        addBtn: "Lisää tiedosto",
        downloadTemplate: "Mallin lataus. excel",
        checkRecord: "Tarkista tallennustiedot",
        checking: "Kalibrointi...",
        reCheck: "Uudelleenkalibrointi",
        checkAll: "Valitse kaikki",
        cancelCheckAll: "Peruuta Valitse kaikkiComment",
        delete: "Poistaa",
        deleteLotSize: "Erän poista",
        agentChannel: "Asiakaspalvelukanava:",
        left: "Vasen kanava",
        right: "Oikea kanava",
        donotKonw: "En tiedä.",
        mission: "Valitse laatutehtävä:",
        uploadFail: "Lähetys epäonnistui",
        repeat: "Kaksinkertainen tiedosto",
        overCount: "Tiedostojen määrä ylittää rajan",
        overSize: "Tiedoston koko ylittää rajan",
        uoloadFail: "Pilvelle lataaminen epäonnistui",
        formatError: "Muotovirhe",
        seccess: "Tarkastusmenestys",
        fail: "Tarkastus epäonnistui",
        recordChecking: "Kirjaaminen laatutarkastukseen, myöhemmin...",
        recordCheckSuccess: "Laaduntarkastus suoritetut",
        recordCheckFail:
          "Laadun tarkastus epäonnistui, tarkista, onko tallennus kaksoiskappale vai virhe.",
        backToIndex: "Palauta latausluetteloon",
        recordCheckIsStarted:
          "Parhaillaan toteutetaan laatutarkastustehtävää. Aloita uusi tehtävä, kun nykyinen tehtävä on valmis.",
      },
      customerServicePortrait: {
        title: "Asiakaspalvelun muotokuvaa",
        tip1: "Tuomion pituus jakautumista koskeva laki: jakautuminen lauseen pituuden lasketaan segmentteinä sanamäärän mukaan lauseen pituus, ja ainoastaan asiakaspalvelun",
        tip2: "Nopeusjakelulainsäädäntö: nopeuden jakautuminen nopeuden mukaan (sanat/minuutti), segmenttilastot, Laske vain asiakaspalvelun",
        tip3: "Vastausajan jakautumista koskeva lainsäädäntö: vastausajan jakautuminen, ainoastaan asiakaspalvelun vastaustilanne lasketaan.",
        tip4: "Laaduntarkastusten vaatimustenmukaisuus: laatutarkastuspisteiden vaatimustenmukaisuus",
        tip5: "Keskimääräinen laatutarkastus tehtäväpisteet: keskimääräinen laadun tarkastuksen tehtäväpisteet",
        contol: {
          date: "Valitse päivämäärä:",
          today: "Tänään",
          thisMonth: "Tällä kuukaudella",
          lastMonth: "Viime kuussa.",
          thisQuarter: "Tämä vuosineljännes",
          lastQuarter: "Viimeinen neljännes",
          thisYear: "Tänä vuonna.",
          lastYear: "Viime vuonna",
          customer: "Asiakaspalvelu:",
          search: "Kysely",
          reset: "Nollaa",
          export: "Vienti",
          indicator: "Indikaattoreiden hallinnointi",
          selectPlaceholder: "Valitse asiakaspalvelu, jota täytyy vertailla",
          warning: "Valitse enintään 5 agenttia",
          warning2: "Valitse vähintään yksi asiamies tiedusteltavaksi",
          dataWarning:
            "Tiedot voidaan kysellä vain 366 päivän kuluttua aloituspäivästä.",
          seccuss: "Tietue onnistuneen",
        },
        echarts: {
          distributionLaw: "Jakeluoikeus",
          count: "Kertojen lukumäärä",
          bout: "Ajani",
        },
        portrait: {
          ability: "Asiakaspalvelukyvyn ulottuvuus",
          option: "Asetukset",
          abilityOption: "Aseta ominaisuuksien mitat",
          placeholder: "Syöttömyyden mitta avainsana haku",
          save: "Tallenna",
          warning:
            "Valitse vähintään 3 indikaattorit määriteltäväksi, ja voit valita enintään 10 indikaattoria",
        },
        label: { title: "Asiakaspalvelutunnus" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Asiakaspalveluasetus:",
        select: {
          date: "Valitse päivämäärä:",
          today: "Tänään",
          thisMonth: "Tällä kuukaudella",
          lastMonth: "Viime kuussa.",
          thisQuarter: "Tämä vuosineljännes",
          lastQuarter: "Viimeinen neljännes",
          thisYear: "Tänä vuonna.",
          lastYear: "Viime vuonna",
          group: "Asiakaspalveluryhmä:",
          groupPlaceholder: "Valitse asiantuntijaryhmä",
          customer: "Asiakaspalvelu:",
          customerPlaceholder: "Valitse asiakaspalvelu.",
          search: "Kysely",
          reset: "Nollaa",
          compared: "Kontrasti",
          export: "Vienti",
          column: "Sarake",
          warning: "Valitse enintään 5 agenttia",
          warning2: "Valitse ainakin yksi agenti",
          dataWarning:
            "Tiedot voidaan kysellä vain 366 päivän kuluttua aloituspäivästä.",
          selected: "Valittu tekijä:",
          seccuss: "Tietue onnistuneen",
        },
        compared: {
          title: "Valitse asiakaspalvelun",
          return: "Peruus",
          compared: "Kontrasti",
          tips: "Valitse 2-5 agenttia",
          warning: "Vertailua tuetaan vain 2-5 agenttia.",
        },
        transfer: { leftTitle: "Luettelo kohta", rightTitle: "Näytä kohta" },
        table: {
          page: "Sivu {1}/{0}",
          actionTitle: "Toiminto",
          action: "Näytä",
        },
      },
      conceptWord: {
        title: "Käsitteet sanat",
        new: "Uusi konsepti sanaa",
        control: {
          search: "Etsi:",
          searchPlaceholder: "Nimi- tai avainsanahaku",
          commit: "Kysely",
          reset: "Nollaa",
          import: "Tuo",
          export: "Vienti",
          seccuss: "Tietue onnistuneen",
        },
        modal: { title: "Tuo konseptisanat" },
        table: {
          sizeChangeText: "Näytä sivua kohti",
          page: "Sivu {1}/{0}",
          edit: "Muokkaa",
          delete: "Poistaa",
          request:
            "Poiston jälkeen sitä ei voida palauttaa. Haluatko varmasti poistaa sen?",
          cancel: "Peruus",
          title: {
            conceptWord: "Käsitteet sanat",
            describeWordsNum: "Kuvausten lukumäärä",
            updateTime: "Päivitysaika",
            status: "Tehokkuus",
            effective: "Tehokas",
            invalid: "Virheellinen",
            action: "Toiminto",
          },
        },
        detail: {
          title: {
            new: "Uusi konsepti sanaa",
            edit: "Muokkaa käsitteiden sanoja",
          },
          name: "Käsitteen nimi",
          namePlaceholder: "Anna konsepti sanan nimi",
          effectiveness: "Tehokkuus",
          effective: "Tehokas",
          invalid: "Virheellinen",
          description: "Kuvaavat sanat:",
          descriptionPlaceholder: "Anna kuvaaja",
          add: "Lisää",
          repeat: "Kaksinkertainen sisältö",
          empty: "Kuvaavia sanoja ei voi olla tyhjä",
          recommendEmpty:
            "Ei enää suositeltuja kuvauksia, syötä ja lisää ne käsin.",
          recommend: "Älykäs suosituksen kuvaaja",
          cancel: "Peruus",
          change: "Muuta ryhmää",
          descriptionSelected: "Lisätty kuvaaja:",
          save: "Tallenna",
          success: "Käsitysanat tallennettu onnistuneesti",
          warning: "Käsitteen sanan nimi ei voi olla tyhjä",
          warning2: "Lisää vähintään yksi kuvaaja",
          submitBtnHandle: {
            title:
              "Valittua kuvaajaa ei ole lisätty. Tallennus hylkää ilman kuvaajan.",
            content:
              "Klikkaa OK palata käsitteen sanan kotisivu, vahvistaa jatkaa?",
          },
        },
      },
      smartTools: {
        btn: "Älykkäät työkalut",
        title: {
          similarWords: "Asiaan liittyvät sanat löytyin",
          search: "Sanalla.",
          semanticTags: "Semanttinen merkintä",
        },
        sentenceSearch: {
          placeholder: "Anna avainsanahaku, tuke useita avainsanoja",
          more: "Näytä koko teksti",
          pageUp: "Edellinen sivu",
          pageDown: "Seuraava sivu",
          total: "{0} tietueet yhteensä",
        },
        similarDiscover: {
          placeholder: "Anna avainsana.",
          copyed: "Kopioitu",
          none: "Ei merkityksellisiä sanoja, kokeile muita avainsanoja",
        },
        smartTags: {
          searchPlaceHolder: "Anna avainsanat tai lauseet",
          createSmartTag: "Uusi:",
          createClusteringSmartTag: "Luo uusi tiedoilla {0}",
          addFromKeyword: "Lisää hakua samankaltaisiin lausein",
          hit: "Osuminen",
          similar: "Samanlaisia",
          name: "Semanttinen nimi",
          namePlaceHolder: "Anna semanttinen nimi",
          recallWords: "Palauta sanaa",
          recallWordsPlaceHolder: "Anna palautus avainsana.",
          recallRegular: "Palauta säännöllisen",
          recallRegularPlaceHolder: "Syötä Recall Säännöllinen",
          rules: "Säännöt",
          standardSentences: "Vakiolauseke",
          standardSentencesPlaceHolder: "Anna vakiolauseen",
          similaSentences: "Samanlaisia lauseita",
          similaSentencesPlaceHolder: "Anna vastaava virke",
          notSaveError: "Tallenna semanttinen nimi ennen etsimistä",
          add: "Lisää",
          newPage: "Avaa uudella sivulla",
          querryError:
            "Yleisluettelossa on samanlaisia kenttiä (vastaavat poistumisrajoitukset kehittynyt kokoonpano)",
          detailTitle: "Semanttinen nimi",
        },
      },
      trainingCenter: {
        title: "Koulutuskeskus",
        edit: "Muokkaa",
        delete: "Poistaa",
        seleteAll: "Valitse kaikki",
        cancelCheckAll: "Peruuta Valitse kaikkiComment",
        add: "Lisää",
        remove: "Poista",
        save: "Tallenna",
        cancel: "Peruus",
        fastTraining: {
          title: "Nopea harjoittelu",
          titleDes:
            "Nopea harjoittelu on suunniteltu auttamaan semanttisia merkkejä yleistymään nopeasti 20 vastaavaa lausetta.",
          semanticTags: "Semanttinen merkintä",
          semanticTagsPlaceholder: "Anna prosessin avainsanahaku",
          standardSentence: "Vakiolause:{0}.",
          similarSentencesPlaceholder:
            "Syötä samankaltaiset virkkeet lisätä tai hakua",
          similarSentencesAdd: "Lisää",
          similarSentencesSearch: "Etsi:",
          similarSentences: "Samanlainen virke lisätty",
          trainingCount: "Koulutusluku:{0}.",
          similarSentencesRecommend: "Suositeltavia samanlaisia lauseita",
          noResp: "Ei merkityksellistä semanttista merkkiä, vaihda avainsanata",
          similarSentencesModalTitle: "Samanlainen haku",
          moreInfo: "Näytä konteksti",
          moreInfoBtn: "Konteksti",
          modalSearchType: {
            all: "Kaikki",
            customer: "Asiakaspalvelut",
            client: "Asiakasta",
          },
          repetWarning:
            "Sama lause on jo olemassa, älkää lisätkö sitä toistuvasti.",
          repetRemoveTip:
            "Tallennettuun vastaavaan lauseeseen sisältyy kaksinkertainen sisältö, joka on automaattisesti poistettu.",
        },
        trainingHistory: {
          title: "Harjoituksen historiaa",
          select: {
            date: "Valitse aika:",
            today: "Tänään",
            thisMonth: "Tällä kuukaudella",
            lastMonth: "Viime kuussa.",
            thisQuarter: "Tämä vuosineljännes",
            lastQuarter: "Viimeinen neljännes",
            thisYear: "Tänä vuonna.",
            lastYear: "Viime vuonna",
            search: "Kysely",
            reset: "Nollaa",
          },
          table: {
            time: "Aika",
            user: "Toiminnanharjoittaja",
            tag: "Semanttinen merkintä",
            similarCount: "Vastaavien virkkeiden lukumäärä",
            deleteCount: "Poistettujen virkkeiden lukumäärä",
            page: "Sivu {1}/{0}",
          },
        },
        classifiedTraining: {
          title: "Luokituskoulutus",
          tagsGrounp: "Merkinnän ryhmittyminen",
          placeHolder: "Valitse:",
          cellTime: "Valitse aikaa",
          search: "Kysely",
          reset: "Nollaa",
          classifiedFlag: "Luokituskoulutus",
          num: "Numeerinen:",
          gas: "Prosenttiosuus",
          noData: "Luokittelematon koulutus",
          noDataHeight:
            "Luokittelemattomat tiedot ovat suuria ja koulutusta tarvitaan kiireesti.",
          noDataSmall:
            "Luokittelemattomien tietojen osuus on suhteellisen suuri, ja koulutusta suositellaan.",
          noDataDays: "{0} päivää ennen viimeistä suoritusta",
          training: "Koulutus",
          start: "Käynnistä",
          addClass: "Lisää luokittelu",
          custom: "Edistynyt suodatus",
          marked: "Merkitty tieto Erratat",
          markedHeight:
            "Errataa ei ole suoritettu {0} päivään, ja se on suositeltavaa.",
          markedSmall:
            "Errataa ei ole suoritettu {0} päivään. Errataa tarvitaan kipeästi.",
          mark: "Errata.",
          dataCheck: "Turvaluokiteltujen tietojen todentaminen",
          dataCheckSmall:
            "K-L ero on suurempi kuin 0,5, on suositeltavaa harjoitella.",
          dataCheckHeight:
            "K-L erot ovat yli 0,8 ja koulutusta tarvitaan kiireellisesti.",
          check: "Kalibrointi",
          history: "Kutsu historiaa",
          historyTime:
            "Päivittäinen merkintä ja muuttaminen suorittaa automaattisesti mallikoulutusta klo 2:00.",
          today: "Tunnistettu tänään",
          article: "Artikla",
          none: "Ei ole.",
          historyCheck: "Historiallinen yhteensä",
          look: "Näytä tietueita",
          historyluate: "Historiallisten tietojen palauttaminen",
          startluate: "Nosturilaskelma",
          classData: "Turvalliset tiedot",
          classStatus: "Koulutuksen edistyminen",
          next: "Seuraava artikkeli",
          prev: "Edellinen artikkeli",
          finish: "Täydellinen koulutus",
          stop: "Koulutuksen päättyminen",
          classDetail: "Koulutustiedot",
          trainingNone: "Ei koulutusta älykkäälle etiketille",
        },
        classifiedTrainingHistory: {
          title: "Harjoituksen historiaa",
          time: "Valitse aika:",
          tag: "Merkintätyyppi:",
          user: "Operaattori:",
          data: "Valitse tietolähde:",
          search: "Kysely",
          searchBar: "Etsi:",
          searchBarPlaceholder: "Syötä puhelun tunnus / keskustelun tunnushaku",
          reset: "Nollaa",
          export: "Vienti",
          all: "Kaikki",
          select: {
            today: "Tänään",
            thisMonth: "Tällä kuukaudella",
            lastMonth: "Viime kuussa.",
            thisQuarter: "Tämä vuosineljännes",
            lastQuarter: "Viimeinen neljännes",
            thisYear: "Tänä vuonna.",
            lastYear: "Viime vuonna",
            search: "Kysely",
            reset: "Nollaa",
          },
          table: {
            user: "Toiminnanharjoittaja",
            date: "Toimintaaika:",
            data: "Tietolähde",
            id: "Puhelun tunnus/keskustelun tunnukset",
            tagType: "Älykäs tunniste ryhmitys",
            tagName: "Merkinnän nimi",
            state: "Tilanne",
            sizeChangeText: "Näytä sivua kohti",
            page: "Sivu {1}/{0}",
            call: "Puhelu",
            dialogue: "Dialogi",
          },
        },
        clusteringAnnotation: {
          title: "Ryhmähuomautus",
          left: { title: "Tulosten ryhmittyminen" },
          right: {
            title: "Lauseen ryhmittyminen",
            checkAll: "Valitse kaikki tämä sivu",
            tagging: "Kutsuminen",
            detail: "Näytä",
            meaningless: "Älä huomioi",
            meaninglessTips:
              "Jättämättä jätettyjä klusterin tuloksia ei tule tulevaisuudessa näyttämään. Haluatko varmasti jättää ne huomiotta?",
            cancel: "Peruus",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Asiakashallinta",
          tabTitle: "Asiakaspalvelukeskus",
        },
        wechatClientManage: {
          title: "Yrittäjyyden mikroasiaka",
          table: {
            id: "Asiakastunnukset",
            name: "Asiakkaan nimi",
            tag: "Asiakasmerkki",
          },
        },
      },
      collectionRule: {
        title: "Keräyssäännöt",
        form: { info: "Älykäs tiedonkeruu", infoPlaceholder: "Valitse luokka" },
        table: {
          name: "Kenttä nimi",
          props: "Asiakkaiden ominaisuudet",
          type: "Kenttätyyppi",
          desc: "Kentän kuvaus",
        },
      },
      dataAdd: {
        title: "Lisää tietoa",
        types: { auto: "Lisää automaattisesti", manual: "Lisää käsin." },
      },
      wechatStaff: {
        table: {
          name: "Nimi",
          part: "Osasto",
          phone: "Matkapuhelinnumero",
          email: "Postilaatikko",
          wechartId: "Enterprise Wechat ID",
          status: "Työssä oleva tila",
        },
        info: "Valitse osasto, jonka täytyy synkronoida tiedot ensin.",
        synSuccess: "Synkronointi onnistui",
        init: "Alustaminen",
        syn: "Synkronisointi",
      },
      appealHistory: {
        appealTime: "Muutoksenhakuaika",
        cancelTime: "Peruutusaika",
        reviewTime: "Arviointiaika",
        inspector: "Laaduntarkastaja",
        result: "Kantelun tulokset",
        complainResult:
          "Yhteensä {0} laatutarkastuksen kohtia muutettiin, {1} haki muutosta menestyksekkäästi ja {2} hylättiin.",
        reason: "Kantelun perusteet",
        rejection: "Hylkäämisen syy",
        cancel: "Peruuttu",
        tip: "Vahvistatko peruuttavansa valituksen?",
      },
      appealSdk: {
        time: "Ikkunan aika",
        externalIdCall: "Kutsulokin ID",
        externalIdText: "Keskustelutietojen tunnukset",
        customerNickName: "Asiakkaan nimi",
        readStatus: "Tarkistus tila",
        status: "Valituksen asema",
        isManual: "Manuaalinen laatutarkastus",
        complainButtonInfo:
          "{0} muutoksenhakut sallittu, {1} jo tehdyt muutoksenhakut, {2} muutoksenhakut jäljellä. {3} voi hakea muutosta aiemmin.",
        submit: "Tee valitus.",
        complain: "Valitus",
        allComplain: "Kaikki kantelut",
        pendingSubmit: "Esitetään.",
        taskIds: "Laaduntarkastustehtävät",
        graded: "Luokitus",
        complainSuccess: "Valitus onnistuneesti",
        appealSdkIndex: { title: "Älykkäät laatutarkastustulokset" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Suodatterilistatietoja ei voitu hakea, tarkista verkkoyhteys tai yritä myöhemmin uudelleen!",
      columns: "Sarake",
      filter: "Suodatin",
      noSearchResult: "Tietueita ei löytynyt",
      pleaseSearch: "Valitse suodin ja yritä etsiä",
      sift: "Suodatin",
      create: "Uusi:",
      search: "Etsi:",
      createTaskTitle: "Uusi tehtävä",
      title: "Otsikko",
      deadline: "Määräaika",
      principal: "Vastuuhenkilö",
      enqueueTime: "Sijoitusaika",
      computeError: "Laskelma epäonnistui, odota!",
      sum: "Yhteensä",
      max: "Enimmäismäärä",
      min: "Vähimmässä",
      avg: "Keskimäärä",
      count: "Määrä",
      maxDate: "Maksimi (viimeisin)",
      minDate: "Vähimmäis (vanhin)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Insight linkin osoitteen saaminen epäonnistui, yritä myöhemmin uudelleen!",
      },
      template: {
        learningReminder: "Oppimisen muistutus",
        subscriptionDynamics: "Tilaus Dynamics",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Tehtävähallinta", chaneg: "Vaihda" },
        qualityCheckTemplate: {
          text: "Laatutarkastusmalli",
          collectText: "Hankintamallit",
          condition: { text: "Laaduntarkastusehdot" },
          checkPoint: { text: "Säännön asetukset" },
          automaticAdd: { text: "Prosessin automaatio" },
          manualAdd: { text: "Lisää käsin." },
          roleConfig: { text: "Roolin asetukset" },
          basicInfo: { text: "Perustiedot" },
          smartWordsLibrary: { text: "Älykäs tesaurus." },
          grade: { text: "Laatutarkastuksen luokitus" },
          collectionRule: { text: "Keräyssäännöt" },
          addData: { text: "Lisää tietoa" },
          collectionList: { text: "Hankintaluettelo" },
        },
        qualityCheckWorkbench: {
          text: "Laaduntarkastuspenki",
          wechatText: "Analyysi työpenkki",
          informationCollectText: "Hankintamenettely",
          qualityInspectionList: {
            text: "Laatutarkastusluettelo",
            wechatText: "Analyysiluettelo",
            informationCollectText: "Hankintaluettelo",
          },
          condition: { text: "Laaduntarkastusluettelo (Obsolete)" },
          reviewList: { text: "Katsausluettelo" },
          spotCheckList: { text: "Näytteenottoluettelo" },
          caseBaseList: { text: "Kirjasto" },
          testSetList: { text: "Laaduntarkastustesti" },
        },
        statisticsAndReports: {
          text: "Tilastot ja raportit",
          wholeReport: { text: "Yleiskertomuus" },
          groupReport: { text: "Asiakaspalveluryhmän raportti" },
          agentReport: { text: "Asiakaspalveluraportti" },
          customerServiceExecution: { text: "Asiakaspalveluanalyysi" },
          analysisOverview: { text: "Analyysi katsaus" },
          inspectionItemsAnalysis: { text: "Laaduntarkastuksen kohdeanalyysi" },
        },
        wechatQa: {
          text: "Enterprise WeChat-laadun tarkastustoiminta",
          task: { text: "Analyysitehtävä." },
          baseInfo: { text: "Perustiedot" },
          checkPoint: { text: "Analyysimallin asetukset" },
          setting: { text: "Enterprise WeChat-analyysin asetukset" },
          automaticAdd: { text: "Prosessin automaatio" },
          manualAdd: { text: "Lisää käsin." },
        },
        clientCenter: { text: "Asiakaspalvelukeskus" },
      },
      conditionDictionaries: {
        title: "Kaikki edellytykset",
        searchDefaultText: "Anna tunnus tai nimi",
        id: "ID",
        name: "Nimi",
        conditionDetailedShow: "Laajenna",
        conditionDetailedHide: "Varasto",
        remark: "Kuvaus:",
        checkRole: "Rooli:",
        operator: "Operaattori:",
        operatorLogic: "Logiikka:",
      },
      audioTextView: {
        customer: "Asiakasta",
        agent: "Asiakaspalvelut",
        unsupportedFormat: "Tätä muotoa ei tueta.",
      },
      checkPointClassifications: {
        add: "Lisää luokkaa",
        name: "Nimi",
        enterToSave: "Paina rekisterin tallentamiseksi",
        highestScore: "Suurin pistemäärän korko",
        minimumScore: "Vähimmäispisteiden alempi raja-arvo",
        defaultMinScore: "Oletus on 0 pistettä",
        minimumScoreTitle: "Alempi pisteytysraja.",
        beginScore: "Aloituspisteiden kokonaisarvo",
        beginScoreTitle: "Aloitetaan pistearvo",
        scoreSetTip:
          "Maksimi pistemäärä on pienempi kuin kaikki pisteet. Voit valita:",
        ignoreExcessSpillover: "Älä huomioi ylituotu",
        automaticWeightCalculation: "Automaattinen painolaskenta",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Kenttä" },
    checkRange: {
      numberTipOne: "- En.",
      numberTipTwo: "Tuomio",
      numberTipThree: "Tuomio ei.",
      conditionLimit: "Ehdolliset rajoitukset",
      roleLimit: "Roolirajoitukset",
      contextLimit: "Lauseen rajoitukset",
      notZero: "Syöte 0 ei tueta lauseen rajoituksessa",
      startMustLessThanEnd:
        "Lähtölauseen aseman on oltava pienempi kuin loppulauseen asenta",
    },
    waveAudio: {
      play: "Soita soita",
      pause: "Tauo",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Syötä",
      pleaseEnterRegular: "Anna säännöllinen lauseke",
      pleaseEnterGrammar: "Anna kielioppisäännös",
      pleaseEnterTestText: "Anna tarkistettava sisältö",
      pleaseEnterLoudness: "Syötä kovaäänisyys.",
      pleaseEnterRangeAbility: "Anna muutoksen alue",
      afterHit: "Tarkista osuman jälkeen",
      pleaseEnterContext: "Syötä viitelauseke.",
      wordNumber: "Sana-aukka",
      intervalSecond: "Pidempi aikaväli",
      minute: "Joka minuutti.",
      wordUnit: "Sana",
      test: "Testi",
      testRegular: "Testaa säännölliset lausekkeet",
      testGrammar: "Testin kieliopio",
      testGrammarError: "Kieliopintotesti epäonnistui",
      regularError: "Säännöllisen lausekkeen virhe, syötä uudestaan",
      pleaseEnterSemanticSentence:
        "Syötä lause, joka vaatii semanttista tunnistamista.",
      pleaseEnterAssociate:
        "Katso ohjeita oikeuteen rakentaa data-yhteen lauseksi",
      querySemanticTags: "Valitse semanttinen nimi",
      reQuerySemanticTags: "Valitse semanttiset merkinnät",
      semanticTags: "Semanttinen merkintä",
      standardSentence: "Vakiolauseke",
      semanticName: "Semanttinen merkintä",
      sentence: "Vakiolauseke",
      similarity: "Samankaltaisuus",
      actions: "Toiminto",
      tipOne: "En löytänyt oikeaa etikettiä.",
      tipTwo: "Lisää uusi semanttinen tagi",
      notLessThan: "En vähemmän.",
      notGreaterThan: "Ei suurempaa",
      ignoreLessThan: "Älä huomioi vähemmälle",
      wordUnitSet: "Sanojen lauseet",
      speedWordNotNull:
        "Sanojen pienin määrä ja sanan enimmäismäärä puheopeuden havaitsemisessa ei voi olla sekä tyhjiä.",
      speedWordMinValueLessThanMaxValue:
        "Sanojen pienin määrä puheopeuden havaitsemisessa ei voi olla suurempi kuin sanamäärän enimmäismäärä.",
      speechGrabbingIntervalTip: "Väli>",
      speechLengthTip: "Lause on pitkä>",
      msUnit: "ms",
      sUnit: "Sekunteja",
      grabIntervalNotNull: "Kiinnitysväli ei voi olla tyhjä",
      grabLengthNotNull: "Lause ei voi olla tyhjä.",
      pleaseSelectEntity: "Valitse tietoliikko",
      pleaseSelectOperator: "Valitse toiminto",
      pleaseSelectBasicInfo: "Valitse perustiedot",
      pleaseEnterFixedValue: "Anna kiinteä arvo",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Täsmää onnistuneesti",
      matchError: "Tulos epäonnistui",
      mindetectionStatement: "Vähimmäisvakuutus",
      conceptWordholder: "Valitse konseptisana.",
      elementHolder: "Anna elementi",
      associateMedol: {
        tabs: { data: "Tiedot", operator: "Toiminnanharjoittaja" },
      },
      repet: "Toistan",
      bout: "Ajani",
      ignore: "Älä huomioi vähemmälle",
      ignoreAffix: "Sanojen lauseet",
      associateData: "Tietojen seurantatiedot",
      pretreatmentData: "Tietojen esikäsittely",
      reviewTaskData: "Tarkista tehtävätiedot",
      taskData: "Laaduntarkastustehtävät",
      businessData: "Liiketoimintaa koskevat tiedot",
      functionOperator: "Toiminnan operaattorit",
      operation: "Toiminnanharjoittajan",
      notAnyMatch: "Kun mitään ongelmia ei osuteta:",
      times: "Tapahtumien lukumäärä",
      newAssociateDataTip:
        "Jos arvo on numeerinen tyyppi, käytä perintöominaisuutta",
    },
    conditionGather: {
      cycle: "Sykli:",
      rlue: "Säännöt",
      delete: "Poista",
      sampling: "Uuttaminen",
      item: "Artikla",
      percent: "%",
      samplingSame: "Sama määrä ainetta kohti",
      samplingRecheck:
        "Sallitaan näytteitä saaneiden tuotteiden toistuvan uuttamisen",
      dateUnit: "- En.",
      samplingTip:
        "Samaa pyyntöä koskevat eri sääntöjä ei toistuvasti. ja tallenteita, jotka on poistettu mutta joita ei tarkisteta, ei poisteta uudelleen.",
      add: "Uusi sääntö",
    },
    ruleGather: {
      title: "Säännöt",
      screeningCondition: "Suodatin tila",
      addRule: "Uusi sääntö",
      inspector: "Laaduntarkastaja",
      ruleTip:
        "Säännön ensimmäinen kohdentamismuoto ei toista, sääntöjen sisällä tai ei täsmää mihinkään sääntö kohdistuu satunnaisesti.",
      moveUp: "Siirry ylöspäin",
      moveDown: "Siirry alas",
      top: "Yllä",
    },
    pushRulesGather: {
      title: "Säännöt",
      delete: "Poista",
      newPush: "Uusi työntä",
      newRule: "Uusi sääntö",
      sampling: "Uuttaminen",
      item: "Artikla",
      percent: "%",
      samplingSame: "Sama määrä ainetta kohti",
      dateUnit: "- En.",
      screeningCondition: "Suodatin tila",
      email: "Postilaatikko",
      emailPlaceholder: "Kirjoita postilaatikot",
      updateType: "Päivitä tyyppi",
      pushDetail: "Työnnän yksityiskohdat",
      pushUrl: "Työnnä osoite",
      pushWay: "Työntötyyppi",
      pushMethod: "Työntötilas",
      pushContentType: "Tiedon muoto",
      pushData: "Paina tietoa",
      additionalData: "Lisätiedot",
      inspectionType: "Laaduntarkastustyyppi",
      all: "Kaikki",
    },
    taskAdditionalData: {
      newData: "Uudet tiedot",
      delete: "Poista",
      placeholder: "Anna nimi",
      dataPlaceholder: "Anna sisältö",
    },
    UdeskQaReact: {
      loadSdkError: "SDK- lataus epäonnistui, yritä myöhemmin uudelleen!",
    },
    gearOptionList: { evaluation: "Arviointi" },
    tableComponentTransfer: {
      leftTitle: "Luettelo kohta",
      rightTitle: "Näytä kohta",
    },
    kmTinymce: {
      link: "Lisää tai muokkaa linkki",
      modalTitle: "Lisää linki",
      href: "Osoite",
      text: "Näytä teksti",
      title: "Otsikko",
      target: "Avoin tila",
      none: "Ei ole.",
      newWindow: "Avaa uudessa ikkunassa",
      buttonStyle: "Painikkeen tyyli",
      close: "SuljeComment",
      open: "Avaa",
      answerMessage: "Hyppää tai lähetä viesti",
      answerMessageTitle: "Lähetä viestipainike",
      answerMessageText: "Näytä teksti",
      answerMessageContent: "Viestin sisältö",
      answerMessageButtonStyle: "Painikkeen tyyli",
      answerMessageTips:
        "Huomautus: Lähetä viesti-painike tukee vain web-kanavaa, aseta se huolellisesti.",
      answerSwitchStaff: "Hyppy/käytön ohje",
      answerSwitchStaffTitle: "Käännä manuaalinen painike",
      answerSwitchStaffText: "Näytä teksti",
      answerSwitchStaffRule: "Nappisäännöt",
      answerSwitchStaffButtonStyle: "Painikkeen tyyli",
      answerSwitchStaffTips:
        "Huomautus: Vaihdon manuaalipainike tukee vain web-kanavaa, aseta se huolellisesti.",
      textButton: "Tekstipainike",
      borderButton: "Kehytetty painike",
      message: "Lähetä viesti",
      switchStaff: "Käännä käsikirja",
      messageError: "Painikkeen sisältö ei saa ylittää 10 sanaa",
      messageIsEmptyError: "Painikkeiden sisältöä ei voi olla tyhjä",
      okText: "Hyvä on.",
      cancelText: "Peruus",
      video: "Videoa",
      uploadVideo: "Lähetä videoa",
      uploadText: "Vain tukee MP4-muotoa, koko enintään 20M",
      videoEmptyOrUploading: "Video on tyhjä tai lähetetän",
      customEmoji: "Lauseke",
    },
    massageConfiguration: {
      pushTarget: "Ilmoitustavoite:",
      customizePushTarget: "Oma kohde",
      sysUser: "Nykyinen järjestelmän käyttäjä",
      sysRole: "Nykyinen järjestelmän rooli",
      emailPushTarget: "Lähetä kohde",
      targetEmailsPlaceholder:
        "Syötä postilaatikot, jotka erotetaan pilkuilla.",
      subject: "Viestin nimi",
      SDKID: "SDK-tunnus",
      content: "Ilmoituksen sisältö",
      sdkPushType: "Työntämiste",
      sdkPushURL: "Työnnä osoite",
      pushData: "Paina tietoa",
      sdkPushWay: "Työntötilas",
      dataType: "Tiedon muoto",
      additionalData: "Lisätiedot",
      name: "Viestin nimi",
      filter: "Suodatin tila",
      triggerType: "Laukaisumekanismi",
      pushCycle: "Ajoitus",
      pushContent: "Raportin sisältö",
      qualityList: "Laaduntarkastusten tulosluettelo",
      designated: "Nimetty henkilöstö",
      messageType: "Viestin tyyppi",
    },
    taskCard: {
      template: { cancelCollection: "Peru suosiki", collection: "Kokoelma" },
    },
    semanticTagsSelect: { index: { standardSentence: "Vakiolause:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Kokoelma",
          calls: "Puhelu",
          createdBy: "Luotu:",
          createdOn: "Luonnon aika:",
        },
        fileItem: {
          uIProvidesIcon: "Ui- kuvakkeet",
          minute: "Pöytäkirja",
          callClip: "Kutsun osio",
          start: "Käynnistä",
          End: "LoppuComment",
          employees: "Työntekijät:",
          Customer: "Asiakas:",
          sessionTime: "Istunnon aika:",
          reasonForRecommendation: "Suosituksen syy:",
          addedBy: "Lisätty:",
          addTime: "Lisäaika:",
        },
        modalAddFile: {
          caseTitle: "Tapauksen nimi",
          addSomeClipsToTheCaseLibrary: "Lisää osittaiset tiedostot kirjastoon",
          addToCaseBase: "Lisää kirjastoon",
          storedIn: "Säilytetty",
          pleaseSelect: "Valitse:",
          reasonsForRecommendation: "Suosituksen syy",
          pleaseEnter: "Syötä",
        },
      },
      shareModal: {
        index: {
          customer: "Asiakas:",
          employees: "Työntekijät:",
          timeliness: "Oikaisu:",
          recordingLinkValidWithinDays:
            "Tallennuslinkki voimassa muutaman päivän kuluessa",
          sharingInstructions: "SHARE KUVAUS:",
          shareSuccess: "Jaan menestystä!",
          cancel: "Peruus",
          share: "Jaa",
          copySucceeded: "Kopio onnistunut!",
          copyShareLink: "Kopioi jaa linkki",
          shareWithColleagues: "Jaa kollegojen kanssa",
          shareWithCustomers: "Jaa asiakkaiden kanssa",
          staff: "Työntekijä",
          selectColleagues: "Valitse työtoverit",
          pleaseSelectColleaguesToShare: "Valitse jaettava kollega.",
          pleaseEnterTheSharingDescription: "Anna jakamisohjeet",
          taskName: "Tehtävät:",
          recordingShareValidWithinDays:
            "Jaan linkki voimassa muutaman päivän kuluessa",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Tiedostoa ei löydy!",
          sale: "Myynti:",
          customer: "Asiakas:",
          topicDetection: "Aiheen tunnistaminen:",
          keyEvents: "Keskeiset tapahtumat:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Valitse työtoverit",
          helpContent: "Ohjeen sisältö",
          visibleRange: "Näkyvä alue",
          seekCounseling: "Hae neuvonta",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Pyydä neuvoja", concatText: "Suuntaan" },
      },
      customerInsight: {
        index: {
          customerInformation: "Asiakastiedot",
          customerInsight: "Asiakastiedot",
          customerLabel: "Asiakasmerkki",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Sanat suositeltu",
          objectionHandlingScripts: "Vastalauseiden käsittely",
          productSellingPointScripts: "Tuotteiden myyntipisteet",
          knowledgePointRecommendation: "Tietopisteen suositus",
          relatedKnowledgePoints: "Yhdistetyt tietämyspisteet",
          recommendedCases: "Suositellut tapaukset",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Sanat suositeltu",
          caseRecommendation: "Tapaussuositus",
          hit: "Osuminen",
          miss: "Osui",
          supervisionItem: "Valvonta-aineet",
          implementation: "Täytäntöönpanon asema",
          reInspection: "Uudelleentarkastus",
          supervisionItemSettings: "Valvonta- kohteen asettaminen",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Puhelut",
          enterpriseWeChatCall: "Yritysten mikrokäsittely",
          enterpriseMicroConversation: "Yritystoiminnan mikroistunnot",
          open: "Avaa",
          close: "SuljeComment",
          conversationScenario: "Istunnon skenaariot",
          monitorConversationChannels: "Valvo keskustelun kanavaa",
          permanentlyValid: "Pysyvästi tehokas",
          permanentlyInvalid: "Pysyvästi virheellinen",
          customValidity: "Voimassaoloaika",
          termOfValidity: "Voimassaoloaika",
          newRating: "Uusi luokitus",
          whenConversation: "Istunnon aikana",
          hit: "Osuminen",
          miss: "Osui",
          time: "Milloin",
          rated: "Luokitus on",
          qualified: "Pätevä",
          unqualified: "Pätee",
          modifiedSuccessfully: "Muutos onnistui",
          classificationName: "Luokan nimi",
          supervisionObject: "Valvonnan kohde",
          entryintoforceTime: "Voimassaoloaika",
          supervisionItemRating: "Valvonnan erien luokitus",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Tarkista, onko valvontalaatu oikein.",
          ratingRules: "Luokitussäännöt",
          settingOfRatingRulesForSupervisionItems:
            "Valvonnan luokitussäännön asettaminen",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Avaintapahtumat",
          supervisionItem: "Valvonta-aineet",
          conversationScenario: "Istunnon skenaariot",
          customerLabel: "Asiakasmerkki",
          reDetectionRules: "Korjaa säännöt",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Lataa tallentaminen",
          downloadText: "Lataa tekstiä",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Lähetä kuvaa",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Valitse kuva, jonka koko on enintään 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Ei",
            and: "Ja",
            wrong: "Ei muuta",
            matchTo: "Täsmää",
            rule: "Säännöt",
            satisfactionOperator: "Tyydyttää käyttäjän toiminta",
            customerLabel: "Asiakasmerkki",
            keyEvents: "Avaintapahtumat",
            knowledgePoints: "Tietämyspisteet",
            sessionLabel: "Istunnon nimi",
            supervisionItem: "Valvonta-aineet",
          },
          linkExplorer: { linkInTheText: "Linkki artiklassa:" },
        },
      },
    },
    createForm: {
      field: { customComponentNotFound: "Omaa komponenttia ei löytynyt:" },
    },
    sdkLoaderBase: {
      component: { custom: "Oma" },
      dropdownMonit: {
        updateSucceeded: "Päivitys onnistui",
        areYouSureToDelete: "Haluatko varmasti poistaa?",
        custom: "Oma",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Tallenna nykyisen raportin asetukset === Lomake",
        saveCurrentReportConfiguration: "Tallenna nykyinen raportin asetukset",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Anna yhteinen nimi, maksimipituus on 20!",
        preservation: "Tallenna",
      },
      template: {
        saveCurrentReportConfiguration: "Tallenna nykyinen raportin asetukset",
        updateCurrentReportConfiguration: "Päivitä nykyisen raportin asetukset",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Työmääräyksiä koskevat tiedot",
        workOrder: "Työskentelyjärjestys",
        triggerScenario: "Laukaisevakenaus",
        associatedCallConversation: "Liitännäinen puhelu/keskustelu",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Testi nykyisellä läpäisy-dialogi-tekstil",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "- En.", day: "Päivä." },
      businessSummary: {
        normal: {
          equalTo: "Yhtäläinen",
          notEqualTo: "Ei yhtä suuri",
          contain: "Sisältää.",
          excluding: "Ei sisällä",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827: "Olen kuka olen, eri ilotulitus.",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hei, olen ilmoitus robotin laadun tarkastus varoitus viesti, joka lisättiin ryhmään 17 lokakuu",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Olen kuka olen, eri ilotulitus.",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hei, olen ilmoitus robotin laadun tarkastus varoitus viesti, joka lisättiin ryhmään 17 lokakuu",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Viestinnän aiheet",
          conversationSummary: "Istunnon yhteenveto",
          serviceSuggestions: "Palvelusuositukset",
        },
        template: { smartTags: "Älykäs nimi" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Klikkaa saadaksesi lisää tietoa" },
      schema: {
        textRobot: "Tekstirobotti",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Kouluttaja saattue, asiakirja kysymys ja vastaus, tehtäväpohjainen vuoropuhelu, pöytä kysymys ja vastaus, kaavio kysymys ja vastaus.",
        capitalRetentionRobot: "Säilytysrobotti",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Rikas seurantastrategia, korkea personoitumisviestintä, korkean säilytysnopeuden varmistamiseksi, vihjeet tehokkaasta automaattisesta muuntamisesta.",
        outboundRobot: "Lähtevä puhelu robotti",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Työjärjestyksen täyttäminen paluuvierailu, palvelut Sähköistä kaupankäyntiä koskeva ilmoitus, ehdokkaiden nimitys, auttaminen liiketoiminnan automaatiota ja liiketoiminnan tehokkuuden parantaminen",
        incomingRobot: "Saapuva robotti",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Joustava integrointi työtilauksia, tilauksia ja muita liiketoimintojärjestelmiä prosessiautomaatioiden saavuttamiseksi; ääni semanttinen malli räätälöity tunnistus, liiketoiminnan tulokset välittömästi; yhteisö (mobiilipuhelinnumero, tilausmäärä, tilausaika, osoite,...) tunnistus, nopea tuki monimutkaiselle liiketoiminnalle.",
        intelligentQualityInspection: "Älykäs laatutarkastus",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Täydellinen laatutarkastus, oikea-aikainen ja tehokas, tarkkuusaste 85 %",
        intelligentSessionAnalysis: "Älykäs istuntoanalyysi",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Ymmärrys lyhyt toimiala, kuuntele asiakkaiden ääntä, parantaa markkinointimuutos",
        enterpriseWechatConversationAnalysis:
          "Enterprise WeChat-istuntoanalyysi",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Mahdollisten liiketoimintamahdollisuuksien, riskien tehokas tunnistaminen, markkinoiden muuntokurssi kasvoi 20 %.",
        assistant: "Istuimen avustaja",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Agentti avustaja voi varmistaa palvelun aloittelevan asiakaspalvelun ja parantaa puhelun laatua osaava asiakaspalvelu. Olkoon jokainen paikka trump kortti.",
        digitalPeople: "Digitaalimies",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          '"Puolikasvoihin" ihmisviestintä, tukeva ääni, teksti, video, kosketus multimodaalinen vuorovaikutus, viestintäkokemuksen saavuttaminen, brändikuvan ja käyttäjäpalvelukokemuksen parantaminen',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "AI Tietämys keskivaiheen yritysten haku",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Tietohaku, osaamisen jakaminen, asiakirjahaku, tietokannan telakoituminen haku",
        aIKnowledgeCenterKCsKnowledgeBase:
          "AI Knowledge Middle Desk · KCS KCS Tietopohja",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Tarjoaa täyden elinkaaren hallinnan tietämyksen, yhtenäisen ylläpito robottien ja tietopohjojen, tukee telakoitumista kolmansien osapuolten tietopohjoilla, osaamismalleilla, Atomien tietämys ja muut tehokkaat toiminnot, joiden avulla voidaan tuoda mukavampaa tiedonhallintakokemusta.",
        aIKnowledgeCenterKnowledgeMap: "AI-tietojärjestelmän osaamiskarta",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "Yhden luukun kartoitus rakentaminen yhdistää yritystoiminnan tietämystä, ja telakointivalmiuksia luoda räätälöity kokemus.",
        modularDevelopmentPlatformOriginalEngineASR:
          "Modulaarinen kehitys Alkuperäinen Sydänmoottori-ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Hyväksy algoritmi toteuttaa räätälöity palvelu vaikutus kokemus, jonka luonnonpaineen tunnistusaste on suurempi kuin 90 % ja henkilökohtainen sanaston tunnistus yli 97 %.",
        modularDevelopmentPlatformIntelligentMarketing:
          "Modulaarinen kehitysfoorumi · Älykäs markkinointi",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Käyttämällä syvällisen oppimisen algoritmeja yhdistettynä käyttäjien muotokuviin, saada aikaan tarkkoja tuotesuosituksia ja parantaa myynnin muuntamista.",
        modularDevelopmentPlatformUserPortrait:
          "Modulaarinen kehitysjärjestelmä",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Luo käyttäjäprofiileja tarkasti käyttäjätietojen ja vuoropuhelutietojen perusteella, jossa kuvataan asiakkaiden ominaisuuksia",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Modulaarinen kehitysfoorumi · Älykäs suositus",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Mukaan käyttäjän muotokuvan ja tuotteen muotokuvan, suositellaan automaattisesti tuotteita, jotka voivat kiinnostaa käyttäjiä, toteuttaa henkilökohtaisen suosituksen tuhansille ihmisille.",
        modularDevelopmentPlatformNLPPAAS:
          "Modulaarinen kehitysfoorumi · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Tarjoaa NLP:n perusominaisuuksia korkealla tarkkuudella, kattaa matalan tason ominaisuudet eri rakeisuus kuten sanoja, lauseita, ja artikkeleita.",
      },
    },
    qualityScore: {
      index: {
        show: "Näytä:",
        displayBySecondaryClassification:
          "Näyttö toissijaisen luokituksen mukaan",
        showByRule: "Näytä sääntöjen mukaan",
        pleaseEnterClassificationruleName: "Syötä luokitus/säännön nimi",
        clickCopy: "Napsauta kopioita",
        hitDetailsOfOperatorsInRules:
          "Operaattori osui yksityiskohtia säännössä:",
        hit: "Osuminen",
        miss: "Osui",
        filter: "Suodin:",
        clickToViewDetails: "Napsauta tarkastellaksesi tiedot",
      },
      components: {
        flowNodeList: {
          branch: "Pisteet",
          pleaseSelect: "Valitse:",
          viewTheCompleteProcess: "Näytä koko prosessi",
          thereIsCurrentlyNoProcessAvailable: "Ei prosessia",
        },
        pointItem: { xiangHit: "Kohde osui" },
        flowNodeListModal: {
          index: { completeProcess: "Täydellinen prosessi" },
        },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Prosessin laatutarkastustehtävä on muuttunut, valittakaa uudelleen.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Nämä tiedot on puhdistettu määräajoin.",
        subject: "Aihe:",
        description: "Kuvaus:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "Lisää laatuun tehtäväin" },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Liitännäinen puhelu/keskustelu" },
    },
    samplingTarget: {
      index: {
        time: "Milloin",
        samplingQuantityIsLessThan: "Näytteenottomäärä on pienempiä",
        alertWhen: "Varhaisvaroitus",
        everyday: "Päivittäinen",
        weekly: "Viikoittain",
        monthly: "Kuukausittain.",
        samplingInspectionTargetSavedSuccessfully:
          "Näytteenottotarkastuskohte tallennettu onnistuneesti",
        personnel: "Henkilöstö",
        eachCustomerService: "Jokainen asiakaspalvelut",
        cycle: "Sykli:",
        samplingInspectionTarget: "Näytteenottotarkastuksen tavoite",
        strip: "Artikla",
        alertSettings: "Hälytysasetukset",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Asiakaspalvelut",
        complete: "Valmistus",
        Objective: "/Kohde",
        everyday: "Päivittäinen",
        weekly: "Viikoittain",
        monthly: "Kuukausittain.",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Ryhmän chat vuorovaikutustaus",
        maximumResponseIntervalTime: "Vasteiden enimmäisväli",
        numberOfPreemptiveCalls: "Pyydysten lukumäärä",
        numberOfMessages: "Viestien lukumäärä",
        strip: "Artikla",
        proportionOfMessages: "Viestien lukumäärän prosenttiosuus",
        speechPercentage: "Puhesuhde",
        longestCustomerTalk: "Pidin puhuva asiakas.",
        numberOfIndepthDiscussions: "Perusteellisten keskustelujen lukumäärä",
        second: "Ajani",
        maximumDurationOfContinuousSpeech: "Suurin jatkuva puhumisen kesto",
        questionFrequency: "Kyselytiheys",
        customer: "Asiakasta",
        keyEvents: "Keskeiset tapahtumat:",
        opportunity: {
          index: {
            generationFailed: "Rakentaminen epäonnistui",
            generateOpportunityPoints: "Luo mahdollisuuspisteet",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Rakentaminen epäonnistui",
            generateSummary: "Luo yhteenveto",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Haluatko varmasti poistaa?",
        add: "Lisää",
        opportunityPoints: "Mahdollisuuspisteestä",
        conversationSummary: "Istunnon yhteenveto",
        cancel: "Peruus",
        determine: "Hyvä on.",
        edit: "Muokkaa",
        establish: "Luo",
        conversationalInsights: "Istunnon oivallukset",
        keyEvents: "Avaintapahtumat",
        numKeyEvents: "Avaintapahtumat",
        questionAnalysis: "Kysymysten analysointi",
        find: "Löytyi",
        questions: "Toinen kysymys",
        conversationAction: "Istunnon toiminta",
        customer: "Asiakasta",
        sale: "Myynti",
        sessionLabel: "Istunnon skenaariot",
        employeeQuestions: "Henkilöstökysymyksiä",
        customerQuestions: "Asiakaskysymyksiä",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Asiakasta",
        customerService: "Asiakaspalvelut",
        replyTo: "Vastaus",
        addressee: "Kohti:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Uudelleen tunnistaminen",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Uudelleen tunnistaminen kuluttaa siirtoaikaa, olkaa varovaisia.",
        determine: "Hyvä on.",
        executionSuccessful: "Onnistunut suoritus",
        addToQualityInspectionTask: "Lisää laatuun tehtäväin",
        batchOperation: "Erän toiminta",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Lisätty onnistuneesti",
        addToQualityInspectionTask: "Lisää laatuun tehtäväin",
        qualityInspectionTask: "Laaduntarkastustehtävät",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Tunnista rooli",
        detectionRange: "Tunnistusalue",
        sentence: "Tuomio",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Nämä tiedot on puhdistettu määräajoin.",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Palautus liiketoimintojärjestelmään" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Ongelmanuutos",
        businessSummary: "Yhteenveto",
        keyWord: "Asiasanat",
      },
      components: {
        problemExtraction: {
          no: "Ei ei",
          customerServiceDidNotAnswer: "Asiakaspalvelu ei vastatu",
          customerServiceHasAnswered: "Asiakaspalvelu on vastattu",
          problemUnresolved: "Ongelmaa ei ole ratkaistu.",
          theProblemHasBeenResolved: "Ratkaistu ongelma",
          customerIssue: "Asiakaskysymykset:",
          customerServiceAnswer: "Asiakaspalvelu vastaus:",
          reExtractQuestions: "Uudellepoistoongelma",
          clickToExtractQuestions: "Napsauta poistaaksesi kysymyksiä",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Muotokuvan nimi",
            backgroundDescription: "Taustan kuvaus",
            customerLabel: "Asiakasmerkki",
            labelManagement: "Merkinnän hallinta",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Uusi:" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Kyselyasetukset" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Lisätään kysymyksiä",
              remove: "Poista",
              addQuestioning: "Lisää kysymyksiä",
              section: "- En.",
              secondInquiry: "Toinen kysymys",
              questioningScore: "Kyselypisteet",
            },
          },
        },
        index: {
          accordingToRules: "Sääntöjen mukaan",
          byScore: "Pisteittain",
          whenMissRule: "Kun sääntö jää",
          whenConductingQuestioning: "Kun kysytään kysymyksiä",
          pleaseSelectARule: "Valitse säännöt",
          ruleCannotBeEmpty: "Sääntöä ei voi olla tyhjä",
          currentScriptScore: "Kun puhe pisteet ovat ≤",
          pleaseEnterTheScore: "Anna pistee",
          scoreCannotBeEmpty: "Pisteetä ei voi olla tyhjä",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "- Ei, ei." },
          pPT: { slide: "Liukus" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Huomautukset",
              script: "Sanat",
              semantics: "Semanttinen",
              violatingWords: "Sanojen rikkominen",
              speechSpeed: "Puhenopeus",
              emotion: "Tunteet",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Huomautukset",
              regenerate: "Uudessa",
              generate: "Luo",
              hitSituation: "Hit cases",
              noSemanticTagsMatched: "Ei täsmää semanttiseen merkkiin.",
              matchToSemanticTags: "Täsmää semanttiseen etiketiin",
              appealToBeSubmitted: "Esitettävä valitus",
              ratingHasBeenReviewed: "Arvostelu",
              ratingAppealInProgress: "Valehteluja",
              expressionScore: "Lausekkeen pistemäärä",
              moodParticlesTimes: "Sävysana, {0} kertaa",
              changeTheModalParticlesTo: "Modaaliset sanat",
              second: "Ajani",
              interrogation: "Kuulustelu",
              questioningWithoutScoring: "Kysy ilman pisteitä",
              branch: "Pisteet",
              matchingRate: "Yhteensopivuus",
              ratingAppeal: "Luokituksen valinta",
              changeTo: "Muutta",
              wordPerMinute: "Sanoja minuutissa",
              completeScript: "Täydellinen puhe",
              incompleteScript: "Epätäydellinen puheName",
              semanticHit: "Semanttinen osuus",
              semanticMisses: "Semanttiset loukkaukset",
              scriptViolation: "Puherikkoja",
              modified: "Muokattu",
              notChanged: "Ei muutettu",
              keyWord: "Asiasanat",
              semanticLabels: "Semanttinen merkintä",
              semanticMatchingRate: "Semanttinen vastaavuusosuus",
              violatingWords: 'Sanan rikkominen"',
              delete: "Poistaa",
              unmatched: "Ei täsmää",
              matching: "Täsmää",
              matchTo: "Täsmää",
              notMatchedTo: "Ei täsmää",
              scriptScore: "Puhepisteet",
              standardScript: "Vakiopuhee",
              emotionalScore: "Tunteellinen pisteet",
              speechSpeedScore: "Nopeuspisteet",
              viewResolution: "Näytä resoluutio",
              nodeConfiguration: "Solmujen asetukset",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Vastausaika tähän kysymykseen on ylitöitä, ja vastauksen sisältö toimitetaan automaattisesti.",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Vastausaika tähän kysymykseen on ylitöitä, ja vastauksen sisältö toimitetaan automaattisesti.",
          },
          correctBtn: {
            saveSuccessful: "Tallenna onnistuneesti",
            errorCorrection: "Virheenkorjaus",
            cancel: "Peruus",
            preserve: "Tallenna",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Pisteissäännöt",
            cancel: "Peruus",
            determine: "Hyvä on.",
          },
          scoringRuleItem: {
            whole: "Kaikki",
            arbitrarily: "Mielivaltainen",
            pleaseEnterARuleName: "Anna sääntön nimi",
            standardScript: "Vakiopuhee",
            if: "Jos epäonnistui",
            and: "Ja",
            or: "Ei",
            thenScore: "Sitten pisteet",
            pleaseEnterAScore: "Anna pistee",
            bonusPoints: "Bonuspisteet",
            minusPoints: "Miinuspisteet",
            hit: "Osuminen",
            misses: "Osui",
            keyWord: "Asiasanat",
            semantics: "Semanttinen",
          },
          title: {
            ruleValidation: "Sääntöjen tarkastaminen",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Pistetyssäännöt (nykyisen puheen kokonaispisteet on pidetty",
            points: "Pisteet)",
            editRules: "Muokkaa sääntöä",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Ei merkintä" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Viestintätavoitteet",
              scoringCriteria: "Pisteysperusteet",
            },
          },
        },
        index: {
          generating: "Luota",
          generateDialogueFramework: "Vuoropuhelun luominen",
          theme: "Teema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Kysy",
            generatePromptLanguage: "Nopea luotu",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Viestintävaihe",
          communicationObjectives: "Viestintätavoitteet",
          scoringCriteria: "Pisteysperusteet",
          suggestionsForImprovement: "Myynninedistämissuositukset",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Prosessin pisteet",
            contentIntegrity: "Sisällön eheys:",
            NumberOfCompletedProcesses:
              "%, Loppuun saatettujen prosessien lukumäärä",
            keyHitPointsTotal: "Tärkeimmät osumat: Yhteensä",
            notReached: "Ei saavutettu",
            accurateFollowupAccumulationOfStandardScripts:
              "Tarkka seuranta: Normaali puhe kertyminen",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Sana, opiskelija osui sanoja.",
            aWord: "Sana",
            languageNormsYes: "Kielietelmä: Kyllä",
            emotionsAppearing: "Tunteet: ilmestyvät",
            speechSpeedSpeakingTooFast: "SPEED: SPEET liian nopeaa",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Tone sanoja: liiallinen esiintyminen sävysanat",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Lisää tunnisteisiin huomioimattaan",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Haluatko varmasti suorittaa älymerkin uudelleen?",
        reExecute: "Uudelleen toteutus",
      },
      component: { successfullyAdded: "Lisätty onnistuneesti" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Sulje kaikki huomautukset",
        reQualityInspectionTask: "Uudelleentarkastustehtävät",
        requalityInspection: "Uudelleentarkastus",
        waiting: "Odotetaan",
        viewDetails: "Näytä tiedot",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Tunnistusaika:" } },
        },
        template: { responseOpeningSentence: "Vasteen alkamislauseke" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Rakentaminen epäonnistui",
            generate: "Luo",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Ei tietoa" } },
      },
      index: { regenerate: "Uudessa" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Haluatko varmasti poistaa?",
        operationSuccessful: "Toiminta onnistui",
      },
      template: { delete: "Poistaa" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Valitse kysymys!",
        similarQuestionRecommendation: "Samanlaisia suosituksia",
        selectAllOnPage: "Valitaan kaikkia sivuja",
        cancel: "Peruus",
        preserve: "Tallenna",
        changeBatch: "Vaihda erä",
      },
    },
    listControl: { index: { columnControl: "Sarakkeen hallinta" } },
    loginUserAvatar: { index: { switch: "Vaihda" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Kun tiedot lisätään reaaliajassa, laatutarkastustehtävä vie edelleen aikaa toteuttaa. Määräaika riippuu laatutarkastustietojen koosta, joka vaihtelee muutamasta minuutista 2 tuntiin.",
      },
    },
    bEditor: {
      index: {
        delete: "Poistaa",
        cancel: "Peruus",
        confirm: "Hyvä on.",
        insert: "Lisää sisällä",
        width: "Leveys",
        height: "Korkeus",
        clearContent: "Tyhjennä sisältö",
        revoked: "Peruuttu",
        redo: "Tee uudestaan",
        fontSize: "Kirjasin koko",
        rowHeight: "Rivin korkeus",
        wordSpacing: "Sanilähetys",
        paragraphIndent: "Sisennä kappaleet",
        increaseIndent: "Lisää sisennyksiä",
        decreaseIndent: "Pienennä sisennys",
        border: "Reunus",
        color: "Väri",
        textColor: "Tekstin väri",
        backgroundColor: "Taustan väri",
        temporaryColor: "Väliaikainen väri",
        bold: "Lihavoita",
        italic: "Kursiiviseksi",
        underline: "Alleviivattua",
        strikethrough: "Yliviivaus",
        font: "Kirjasin",
        textAlignment: "Tekstinmukaisuus",
        left: "Vasemmalle",
        centered: "Keskitetty",
        right: "Oikea",
        bothEnds: "Molemmat päät",
        leftFloat: "Vasen kellu",
        rightFloat: "Oikea kellu",
        subrupt: "Vaihtelu",
        subscript: "Osa-arvo",
        clearStyle: "Tyhjennä tyyli",
        title: "Otsikko",
        regular: "Säännöllinen",
        orderedList: "Järjestysluettelo",
        unorderedList: "Järjestämätön luettelo",
        reference: "Viittaus",
        code: "Koodi",
        link: "Linkkiä",
        clearLink: "Tyhjennä linkki",
        horizontalLine: "Vaakaviiva",
        media: "Tiedotusvälineet",
        mediaLibrary: "Mediakirjasto",
        smallExpression: "Pieni lauseke",
        fullScreen: "Koko näyttö",
        exitFullScreen: "Poistu kokoruutusta",
        enterLinkText: "Kirjoita linkin teksti",
        enterLinkAddress: "Anna linkin osoite",
        enterTheLinkAddressAndPressEnter:
          "Kirjoita linkin osoite ja paina Enter",
        openInNewWindow: "Avaa uudessa ikkunassa",
        removeLink: "Poista linkki",
        playingAudioFiles: "Soitetaan äänitiedostoja",
        playVideoFiles: "Soita videotiedostoja",
        embeddedMedia: "Upotettu mediaComment",
        image: "Kuva",
        video: "Videoa",
        audio: "Ääni",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Nimi",
        description: "Kuvaus",
        type: "Tyyppi",
        status: "Tilanne",
        actions: "Toiminto",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Tehtävän nimi",
        description: "Kuvaus",
        form: "Laatutarkastuslomake",
        actions: "Toiminto",
        createUser: "Luoja",
      },
      samplingRecords: {
        createTime: "Aika",
        type: "Tyyppi",
        operateUser: "Toiminnanharjoittaja",
        number: "Lisää määrä",
        filterConditionList: "Suodatin tila",
        sampleRuleStr: "Näytteenottovaatimukset",
        timeGreaterThan: "Aika on pidempi.",
        timeLessThan: "Aika vähemmän.",
      },
    },
    customFilters: {
      createdAt: "Puheaikaan",
      customerId: "Asiakasta",
      agentId: "Asiakaspalvelut",
      userGroupId: "Asiakaspalveluryhmä",
      state: "Tilanne",
      lastEvaluateUserId: "Viimeinen rateija",
      submitTime: "Viimeisin lähetyksen aika",
      totalScore: "Pisteet yhteensä",
      formItemForbidIdList: "Kielletyt kohteet",
      formItemDeadlyIdList: "Kuolema kohta",
    },
    samplingInspection: {
      callTime: "Puheaikaan",
      dialogTime: "Ikkunan aika",
      originator: "Myönnetty henkilö.",
      createTime: "Luomisaikaan",
      updateTime: "Päivitysaika",
      status: "Prosessin tila",
      score: "Fraktio",
      actions: "Toiminto",
      reviewer: "Arvostelin",
      samplingOfficer: "Näytteenottotarkastaja",
      id: "Kutsulokin ID",
      time: "Puheaikaan",
      phone: "Asiakaspuhelin",
      staff: "Asiakaspalvelut",
      inspectionStatus: "Laaduntarkastuksen tila",
      samplingStatus: "Näytteenottotilanta",
      inspectionGrade: "Luokitus",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Vakiosoa",
        synonymWords: "Synonyymit",
        synonymWordsNumber: "Synonyymien lukumäärä",
        useFrequency: "Käyttötiheys",
        effectiveness: "Tehokkuus",
        operation: "Toiminto",
      },
      common: {
        useFrequency: "Käyttötiheys",
        effectiveness: "Tehokkuus",
        operation: "Toiminto",
      },
      professionalWords: {
        professionalWords: "Ammatillinen nimi",
        useFrequency: "Käytä tilastoja",
        homophonic: "Kuumat sanat (homofoninen korvaus)",
        target: "Substantiivin korvaaminen",
        targetWords: "Korvaa sanat",
        homophonyWords: "Homofoni peittävä sanan",
        operation: "Toiminto",
      },
      attentionWords: "Huolimattomat sanat",
      ignoreWords: "Älä huomioi sanat",
      wordsWeight: {
        selfDefineWeightWords: "Oma painos",
        weightWords: "Painosana",
        weight: "Paino",
        useFrequency: "Käyttötiheys",
        effectiveness: "Tehokkuus",
        operation: "Toiminto",
      },
      corpusData: {
        corpusDescription: "Kuvaus",
        updateTime: "Päivitysaika",
        wordsCount: "Sanien lukumäärä",
        effectiveness: "Tehokkuus",
        operation: "Toiminto",
        corpusContent: "Sisältö",
      },
    },
    semanticTags: {
      title: "Semanttinen merkintä",
      tags: "Vakiolauseke",
      orgName: "Organisaatio",
      tagCount: "Koulutuksen lukumäärä",
      usingCount: "Viittausten lukumäärä",
      activeFlag: "Tehokkuus",
      suggestion: "Koulutussuositukset",
      actions: "Toiminto",
    },
    intelligentTags: {
      tagName: "Nimi",
      tagDesc: "Kuvaus",
      tagRefCnt: "Merkkien lukumäärä",
      categoryName: "Merkintäluokitus",
      eventName: "Tapahtumien luokitus",
      tagStatus: "Tehokkuus",
      actions: "Toiminto",
      tagType: "Merkintätyyppi",
    },
    dataPreprocess: {
      name: "Nimi",
      desc: "Kuvaus",
      dataSource: "Tietolähteen raja-arvot",
      type: "Tyyppi",
      status: "Tehokkuus",
      actions: "Toiminto",
    },
    informationEntities: {
      name: "Nimi",
      apiName: "API- nimi",
      type: "Tyyppi",
      remark: "Kuvaus",
      activeFlag: "Tehokkuus",
      actions: "Toiminto",
    },
    interactiveRecognition: {
      questionAndAnswer: "Vakiomuotoisia kysymyksiä ja vastauksia",
      type: "Tyyppi",
      status: "Tilanne",
      actions: "Toiminto",
      question: "Kysymys",
    },
    role: { id: "ID", name: "Nimi", actions: "Toiminto" },
    uploadDownload: {
      taskName: "Tiedoston nimi",
      createTime: "Päivämäärä",
      size: "Koko",
      createUserName: "Toiminnanharjoittaja",
      status: "Tilanne",
      result: "Tulokset",
      actions: "Toiminto",
    },
    taskCenterList: {
      name: "Tehtävän sisältö",
      createTime: "Lähetysaika",
      startTime: "Aloitusaika",
      endTime: "Loppuaika",
      createUserName: "Toiminnanharjoittaja",
      status: "Tilanne",
      result: "Tulokset",
      actions: "Toiminto",
    },
    customerInformation: {
      title: "Asiakaspalvelutiedot",
      data: "Näytä vain poikkeustiedot",
      list: {
        agentNo: "Työnumero / asiakaspalvelutunnus",
        agentName: "Asiakaspalvelut",
        agentTel: "Puhelin",
        agentEmail: "Postilaatikko",
        updateTime: "Päivitysaika",
        actions: "Toiminto",
      },
      upload: {
        second:
          "2. Napsauta Tuo-painiketta lataa EXCEl-tiedoston täyttää mallin muodon mukaisesti",
      },
    },
    IndexManagement: {
      title: "Indikaattoreiden hallinnointi",
      list: {
        indicatorName: "Nimi",
        indicatorDesc: "Kuvaus",
        activity: "Tehokkuus",
        inspectDataSource: "Tietolähde",
        sysType: "Tyyppi",
        agentGroups: "Asiakaspalveluryhmä",
        actions: "Toiminto",
      },
      detail: {
        nameEmpty: "Nimiä ei voi olla tyhjä",
        descript: "Kuvaus",
        descriptEmpty: "Kuvausta ei voi olla tyhjä",
        active: "Tehokkuus",
        activeEmpty: "Voimassaoloa ei voi olla tyhjä",
        dataSource: "Tietolähde",
        dataSourceEmpty: "Tietolähde ei voi olla tyhjä",
        grounp: "Asiakaspalveluryhmä",
        grounpEmpty: "Asiantuntijaryhmä ei voi olla tyhjä",
        grounpHolder: "Valitse asiantuntijaryhmä",
        type: "Pisteysmenetelmä",
        typeNone: "Pisteysmenetelmää ei voi olla tyhjä",
        base: "Peruspisteet",
        baseNone: "Peruspisteetä ei voi olla tyhjä",
        positive: "Positiiviset tekijät",
        positiveNone: "Positiivinen kerroin ei voi olla tyhjä",
        customAdd: "Lisää suodin",
        negative: "Negatiiviset tekijät",
        negativeNone: "Negatiiviset tekijät eivät voi olla tyhjä",
        customOK: "Suodatuskriteerit:",
        judgeStrategy: "Tilaa koskevat säännöt:",
        other: "Muut tekijät",
        otherAdd: "Lisää tekijä",
        agent: "Raakatiedot",
        section: "Jaksojen lukumäärä",
        sectionNone: "Jaksojen määrää ei voi olla tyhjä",
        step: "Välivaiheen koko",
        stepNone: "Välivaiheen koko",
        save: "Tallenna",
        cancel: "Peruus",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Kaikki",
      staff: "Vain itseni.",
      department: "Osasto ja jäljempänä",
    },
    gongStatisticsDate: { week: "Viikko", month: "Kuukaus", season: "Kausi:" },
    gongCoachTaskStatus: {
      wait: "Ohjattavaksi.",
      already: "Valmennettu",
      ignore: "Älä huomioi",
    },
    callRangeStatus: {
      all: "Kaikki puhelut",
      sale: "Aloitettu työntekijä",
      customer: "Asiakas aloitettu",
    },
    todoListType: {
      later: "Kuuntele myöhemmin.",
      help: "Neuvontaa apua varten",
      contact: "Yhteystiedot myöhemmin.",
      send: "Lähetä tietoa",
      warn: "Varhaisvaroitus",
    },
    frequencyTypes: {
      everyDay: "Päivittäinen",
      everyWeek: "Viikoittain",
      everyMonth: "Kuukausittain.",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Luokittelematon datakoulutus",
        markedDataCorrigenda: "Merkitty tieto Erratat",
        classifiedDataCheck: "Turvaluokiteltujen tietojen todentaminen",
        markedHistoryRecord: "Kutsu historiaa",
        historyDataRecalculate: "Historiallisten tietojen palauttaminen",
      },
      dataExtractMode: {
        random: "Täysin satunnainen.",
        intelligentRecommend: "Älykäs suositus",
        custom: "Oma",
        specifiedClass: "Täsmennetään luokittelu",
      },
      smartRatesList: {
        markRate: "Merkkinopeus",
        corrigendaRate: "Errata-aste",
        kl: "K-L poikkeaminen",
        signalNoiseRate: "Signaalin ja melun suhde:",
      },
    },
    visibleRangeTypes: {
      all: "Kaikki",
      customerGroup: "Asiakaspalveluryhmä",
      userDefined: "Oma",
    },
    samplingRateTypes: {
      everyDay: "Piirrä päivittäin",
      everyWeek: "Viikoittainen piirto",
    },
    taskRemindTypes: {
      confirm: "Lähetä muistutus, kun laatutarkastustehtävät on vahvistettava.",
      appeal:
        "Kun laatutarkastustehtävän kirjanpito aloittaa valituksen, lähetä muistutus.",
    },
    weekDays: {
      monday: "Maanantai.",
      tuesday: "Tiistai",
      wednesday: "Keskiviikko.",
      thursday: "Torstai",
      friday: "Perjantai",
      saturday: "Lauantai.",
      sunday: "Sunnunti",
    },
    reportDateTypes: {
      dailyReport: "Päivittäinen raportti",
      weeklyReport: "Viikoittainen kertomus",
    },
    samplingRangeDayTypes: { today: "Tänään", yesterday: "Eilen." },
    samplingRangeWeekTypes: {
      thisWeek: "Tällä viikolla.",
      lastWeek: "Viime viikolla.",
    },
    samplingRuleTypes: {
      proportion: "Suhteellinen näytteenotto.",
      random: "Satunnainen näytteenotto.",
    },
    taskStatusTypes: {
      waitEvaluate: "Arvioitava",
      waitSubmit: "Esitetään.",
      waitConfirm: "Vahvistettava",
      waitReview: "Tarkistetaan uudelleen",
      completed: "Valmistui",
      all: "Kaikki",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Suora luokitus",
        weightedScore: "Painopisteet",
        deductScore: "Vähennykset",
        directScoreSecondLevel: "Suora luokitustaso 2",
        weightedScoreSecondLevel: "Paino pisteytystaso 2",
        deductScoreSecondLevel: "Vähennystaso 2",
      },
    },
    fieldOperators: {
      is: "Yhtäläinen",
      not: "Ei yhtä suuri",
      contains: "Sisältää.",
      notContains: "Ei sisällä",
      not_contains: "Ei sisällä",
      containsAny: "Sisältää mitä tahansa.",
      contains_any: "Sisältää mitä tahansa.",
      is_any: "Sisältää mitä tahansa.",
      not_any: "Ei sisällä",
      notContainsAny: "Ei sisällä",
      not_contains_any: "Ei sisällä",
      containsAll: "Sisältää kaikki.",
      contains_all: "Sisältää kaikki.",
      notContainsAll: "Ei sisällä kaikkia",
      nont_contains_all: "Ei sisällä kaikkia",
      isNull: "Tyhjä",
      is_null: "Tyhjä",
      isNotNull: "Ei tyhjä",
      is_not_null: "Ei tyhjä",
      greaterThan: "Suurempaa",
      greater_than: "Suurempaa",
      greaterThanEq: "Suurempi tai yhtä suuri",
      greater_than_eq: "Suurempi tai yhtä suuri",
      lessThan: "Vähemmäs",
      less_than: "Vähemmäs",
      lessThanEq: "Vähemmän tai yhtä suuri",
      less_than_eq: "Vähemmän tai yhtä suuri",
      between: "Välille",
      notBetween: "Ei ole.",
      not_between: "Ei ole.",
      relative: "Yhtäläinen",
      set: "Asetata",
      belongs: "Kuuluu",
      notBelongs: "Ei kuulu mihinkään.",
      not_belongs: "Ei yhtä suuri",
      today: "Tänään",
      yesterday: "Eilen.",
      the_day_before_yesterday: "Toissapäivänä eilen",
      tomorrow: "Huomenna.",
      next_seven_day: "Seuraavat seitsemän päivää",
      last_seven_day: "Viimeiset seitsemän päivää",
      this_week: "Tällä viikolla.",
      next_week: "Ensi viikolla.",
      last_week: "Viime viikolla.",
      this_month: "Tällä kuukaudella",
      next_month: "Ensi kuussa.",
      last_month: "Viime kuussa.",
      this_year: "Tänä vuonna.",
      next_year: "Ensi vuosi",
      last_year: "Viime vuonna",
      exact_match: "Tarkka vastaavus",
      add_value: "Uusi lisäarvo",
      remove_value: "Poista arvo",
      set_null: "Tyhjä",
      isAny: "Mielivaltaisesti on yhtä suuri kuin mitään.",
      notAny: "Mielivaltainen ei yhtä suuri",
      belongTo: "Kuuluu",
      notBelongTo: "Ei kuulu mihinkään.",
      hasAny: "Sisältää mitä tahansa.",
      notHasAny: "Ei sisällä",
      prefixContains: "Alkaa yhtä suuri",
      prefixNotContains: "Alku ei ole yhtä suuri",
      suffixContains: "Loppu vastaa",
      suffixNotContains: "Loppu ei ole yhtä suuri",
      nextSevenDay: "Seuraavat 7 päivää",
      lastSevenDay: "Viimeiset 7 päivää",
      thisWeek: "Tällä viikolla.",
      nextWeek: "Ensi viikolla.",
      lastWeek: "Viime viikolla.",
      thisMonth: "Tällä kuukaudella",
      nextMonth: "Ensi kuussa.",
      lastMonth: "Viime kuussa.",
      thisYear: "Tänä vuonna.",
      nextYear: "Ensi vuosi",
      lastYear: "Viime vuonna",
      after: "Myöhemmin kuin (mukaan lukien nykyinen päivä)",
      before: "Aiemmin kuin (mukaan lukien nykyinen päivä)",
      allMatching: "Täsmää kaikkiin",
      allNotMatching: "Kaikki eivät täsmää",
      hit: "Osuminen",
      not_hit: "Osui",
    },
    fieldsOperators: {
      is: "Yhtäläinen",
      is_null: "Myös tyhjä",
      head_equal: "Alkaa yhtä suuri",
      tail_equal: "Loppu vastaa",
      is_not_null: "Ei myöskään tyhjä",
      not: "Ei yhtä suuri",
      diff_equal: "Ero on yhtä suuri",
      diff_greater_than: "Ero on suurempi.",
      diff_greater_than_eq: "Ero suurempi tai yhtä suuri",
      diff_less_than: "Ero on pienempiä",
      diff_less_than_eq: "Vähemmän tai yhtä suuri ero",
      hour_equal: "Samaan aikaan.",
      day_equal: "Sama päivä.",
      week_equal: "Sama viikko",
      month_equal: "Sama kuukaus",
      year_equal: "Samana vuonna.",
      day: "Päivä.",
      minute: "Pöytäkirja",
      hour: "Tunnit",
      part_equal: "Osittain yhtä suuri",
    },
    samplingColumnCaptions: {
      created_at: "Puheaikaan",
      agent_id: "Asiakaspalvelut",
      get_direction: "Kutsutyyppi",
      call_result: "Kutsutulokset",
      survey: "Arviointi",
      derived_from_id: "Lähde",
      quit_queue_why: "Aseta jonoon tila",
      queuing_duration: "Aikaa vievä jonus",
      talking_seconds: "Kutsun kesto",
      ringing_seconds: "Soriaika",
      hangup_by: "Soita lopetusjuhlat.",
    },
    callTypes: {
      callIn: "Saapuva puhelu",
      callOut: "Ulos hengittää",
      threeParty: "(Kolmikanta)",
      consultation: "(Konsultointi)",
      insert: "(Vahva lisäys)",
      monitor: "(Kuuntelu)",
      transfer: "(Siirto)",
      intercept: "(Siippaus)",
      transferOutside: "(Siirto ulkoinen linja)",
      threePartyOutside: "(Kolmikulkoinen linja)",
      consultingOutside: "(Kuuntele ulkopuolella)",
    },
    callResults: {
      staffAnswer: "Asiakaspalvelu vastaus",
      staffNotAnswer: "Asiakaspalvelu epäonnistui",
      customerAnswer: "Asiakasvastus",
      customerNotAnswer: "Asiakas epäonnistui",
      phoneBusy: "Puhelin käynnissä",
      phoneOffline: "Puhelin pois verkostostaName",
      customerSpeedHangUp: "Asiakas nopea riippuu",
      customerHangUp: "Asiakas katkaisee puhelimen.",
      queueTimeout: "Jonon aikakatkaisu",
      queueGiveUp: "Hylä jooka",
      outlineAnswer: "Ulkopuolinen vastaus",
      outlineNotAnswer: "Ulkopuolinen rivi ei ole liitetty",
      noChooseQueue: "Jonoa ei ole valittu",
    },
    defaultSurveys: {
      noEvaluation: "Ei arvioitua",
      noNeedToEvaluate: "Ei tarvitse arvioida.",
    },
    queueStates: {
      queueSuccess: "Jonon menestys",
      queueTimeout: "Jonon aikakatkaisu",
      queueGiveUp: "Hylä jooka",
      noStaffOnline: "Ei asiakaspalvelua verkossa",
    },
    callHangers: {
      customer: "Asiakasta",
      staff: "Asiakaspalvelut",
      outline: "Ulkopuolella",
    },
    workFlowTypes: {
      noAppealsMode: "Ei valitustilaa",
      appealsMode: "Salli valitustilan",
    },
    fieldCategories: {
      standardCondition: "Vakiosoitinkriteerit",
      customerCondition: "Käyttäjän määritellyt suodinkriteerit",
    },
    sampleTypes: {
      automatic: "Automaattinen näytteenotto.",
      manual: "Manuaalinen näytteenotto.",
    },
    defaultFields: {
      createdAt: "Puheaikaan",
      agentId: "Asiakaspalvelut",
      getDirection: "Kutsutyyppi",
      callResult: "Kutsutulokset",
      survey: "Arviointi",
      derivedFrom: "Lähde",
      quitQueueWhy: "Aseta jonoon tila",
      queuingDuration: "Aikaa vievä jonus",
      talkingSeconds: "Kutsun kesto",
      ringingSeconds: "Soriaika",
      hangupBy: "Soita lopetusjuhlat.",
    },
    conditionFieldCategories: {
      fixedField: "Kiinteä kenttä",
      customField: "Omat",
      secondLevelScore: "Toissijainen luokitus",
      formItemScore: "Pistee",
      forbids: "Kielletyt kohteet",
      deadlies: "Kuolema kohta",
    },
    columnFieldCategories: {
      agent: "Asiakaspalvelut",
      callLog: "Puhelujen tallentaminen",
      businessFields: "Liiketoiminnan tiedot",
      customer: "Asiakasta",
      customerCustomFields: "Asiakkaiden mukauttaminen",
      source: "Lähde",
      queue: "Jono",
      fixed: "Kiinteä",
      secondLevelScore: "Toissijainen luokitus",
      formItemScore: "Pistee",
      forbids: "Kielletyt kohteet",
      deadlies: "Kuolema kohta",
    },
    taskListDefaultFields: {
      startTime: "Puheaikaan",
      name: "Asiakaspalvelut",
      state: "Tilanne",
      totalScore: "Pisteet yhteensä",
      formItemForbidList: "Kielletyt kohteet",
      formItemDeadlyList: "Kuolema kohta",
      comment: "Arviointihuomautukset",
      inspector: "Luokitukset",
      review: "Arvostelin",
    },
    evaluationStates: {
      initial: "Alustaminen",
      waitEvaluate: "Arvioitava",
      evaluated: "Esitetään.",
      waitConfirm: "Vahvistettava",
      waitCheck: "Tarkistetaan uudelleen",
      complete: "Valmistui",
    },
    workBenchInfoTypes: {
      callInfo: "Puhelutiedot",
      businessInfo: "Liiketoiminnan tiedot",
      customerInfo: "Asiakastiedot",
    },
    evaluationEventStates: {
      create: "Luo",
      valuate: "Arviointi",
      edit: "Muokkaa",
      submit: "Toimittaminen",
      confirm: "Vahvista",
      appeal: "Valitus",
      checkConfirm: "Arviointivahvistus",
      check: "Uudelleentarkastelu",
    },
    formItemTypes: {
      direct: "Suora luokitus",
      weight: "Paino",
      deduct: "Vähennykset",
      forbid: "Kielletyt kohteet",
      deadly: "Kuolema kohta",
    },
    appealCheckCallTypes: {
      callIn: "Saapuva puhelu",
      callBack: "Kaksisuuntainen palautus",
      callOut: "Ulkopuolell suora soitaus",
      autoCall: "Automaattinen lähtöpuhelu",
    },
    appealCheckCallStatus: {
      waitReview: "Tarkistetaan uudelleen",
      reviewed: "Tarkistettu",
      filed: "Arkistoidut",
    },
    samplingStatus: {
      all: "Kaikki",
      unchecked: "Poistumista ei tarkastettua",
      checked: "Otanta",
    },
    inspectionStatus: {
      all: "Kaikki",
      unread: "Lukemattomat",
      readed: "Lue",
      appeal: "Valitus",
      review: "Uudelleentarkastelu",
      filed: "Arkistoidut",
    },
    gradeChooseHits: { yes: "Kyllä.", no: "Ei ei" },
    appealCheckStatus: {
      all: "Kaikki tila",
      unread: "Lukemattomat",
      inComplaint: "Valituksessa",
      reviewed: "Tarkistettu",
      readed: "Lue",
      filed: "Arkistoidut",
      spotchecked: "Otanta",
    },
    flowStatus: {
      unread: "Lukemattomat",
      alreadyRead: "Lue",
      complaining: "Valituksessa",
      reviewed: "Tarkistettu",
      sampling: "Poistumista ei tarkastettua",
      samplingCompleted: "Otanta",
      complainCancel: "Peruuttu",
    },
    complainStatus: {
      success: "Onnistui",
      fail: "Epäonnistui",
      default: "Valituksessa",
      canceled: "Peruuttu",
    },
    reviewInspectionStatus: {
      inComplaint: "Valituksessa",
      reviewed: "Tarkistettu",
    },
    gradeChangeTypes: {
      edit: "Muokkaa",
      check: "Uudelleentarkastelu",
      file: "Arkistointi",
      remark: "Huomautukset",
      complain: "Valitus",
      review: "Uudelleentarkastelu",
      reviewEvaluation: "Uudelleentarkastelun arviointi",
      sample: "Satunnaistarkastus",
      sampleEvaluation: "Näytteenottotarkastus ja arviointi",
      sampleSubmit: "Näytteenottotarkastus ja toimittaminen",
      sampleAssign: "Näytteenotto",
      reviewAssign: "Uudelleentarkastelun myöntäminen",
      read: "Lue",
      reviewConfirm: "Arviointivahvistus",
      sampleConfirm: "Näytteenottotarkastusvahvistus",
      caseBase: "Lisää tapausryhmiin",
      sampleCancel: "Näytteenotto Peruuttaminen",
      reviewCancel: "Tarkista peruutus",
      sampleAgain: "Näytteenotto uudelleenkohdentaminen",
      reviewAgain: "Tarkista uudelleensijoittaminen",
      btachDelete: "Poista satunnaiset tarkastustiedot",
      rapidSampling: "Nopea näytteenotto.",
      reInspection: "Uudelleentarkastus",
      smartTagsChange: "Älykäs nimike muutokset",
      cancelAppeal: "Kantelun peruuttaminen",
    },
    appealTypes: {
      transliterationError: "Transkriptor virhe",
      discriminationError: "Tuomiovirhe",
      otherError: "Muut virheet",
    },
    inspectDataSources: {
      voiceCall: "Äänipuhelu",
      textDialogue: "Tekstiikkuna",
      realTimeVoiceCall: "Reaaliaikaiset äänipuhelut",
      realTimeTextDialogue: "Reaaliaikaiset tekstikeskustelut",
      wechatDialogue: "Yritystoiminnan mikroistunnot",
      taobao: "Sähköinen kaupankäynti",
      ticket: "Työskentelyjärjestys",
      wechatRadio: "Enterprise mikroääni",
    },
    inspectTaskType: {
      all: "Kaikki",
      common: "Yleinen laatutarkastus",
      relate: "Liitännäinen laatutarkastus",
    },
    inspectApproveType: {
      all: "Kaikki",
      resolve: "Hyväksytty",
      resolving: "Katsauksessa",
      reject: "Tarkastus ei läpäisty",
    },
    dataSourceFlags: {
      all: "Ei rajoituksia",
      voiceCall: "Puhelu",
      textDialogue: "Dialogi",
    },
    smartWordsLibrary: { effective: "Tehokas", invalid: "Virheellinen" },
    activeFlags: { effiective: "Tehokas", invalid: "Virheellinen" },
    labelTypes: {
      dimensionLabel: "Kutsutunnukset",
      classificationLabel: "Luokan merkintä",
    },
    pointTypes: {
      automatic: "Automaattinen arviointi",
      manual: "Käsin arviointi",
      interactiveDetection: "Yhteisvaikutuksen havaitseminen",
      smartRules: "Älykkäät säännöt",
      machineLearning: "Itseoppimista",
      intelligentModel: "Älykäs malli",
    },
    pointGradeTypes: {
      radio: "Yksi valinta",
      input: "Syöte",
      select: "Valita",
      all: "Tapaamme kaikki.",
      any: "Täyttää mielivaltainen",
      customize: "Oma",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Avainsana täsmään",
      question: "Kyselylauseke",
      regular: "Säännölliset lausekkeet",
      context: "Kontekstin kopiointi",
      semantics: "Semanttinen täsmäys",
      word: "Samanlaisia sanoja",
      dialogue: "Ikkunanväli",
      speed: "Puheen nopeuden havaitseminen",
      grabDetection: "Tartunnan havaitseminen",
      semanticTags: "Semanttinen merkintä",
    },
    applyRoles: {
      all: "Kaikki",
      customer: "Asiakasta",
      agent: "Asiakaspalvelut",
      robot: "Robot",
    },
    saleApplyRoles: {
      all: "Kaikki",
      customer: "Asiakasta",
      agent: "Työntekijä",
    },
    applyOperatorScopes: {
      all: "Koko teksti",
      preCondition: "Ehdot",
      scope: "Määritetty alue",
    },
    keywordScopes: {
      all: "Tunnistetaan kaikkia avainsanoja.",
      any: "Tunnista mielivaltaiset avainsanat",
    },
    preOperatorHitTypes: {
      first: "Ensimmäinen osuus",
      every: "Jokainen vastaavuus",
      last: "Viimeinen osuus",
      any: "Mikä tahansa vastaavuus",
      none: "Ei täsmää",
    },
    operatorRuleTypes: {
      all: "Tapaamme kaikki.",
      any: "Täyttää mielivaltainen",
      custom: "Oma logiikka",
    },
    informationEntityTypes: {
      system: "Järjestelmä",
      custom: "Oma",
      moduleCompany: "Modul",
    },
    organizationTypes: { null: "Ei ole.", moduleCompany: "Modul" },
    customType: {
      smartTag: "Yhteisöt",
      scriptTimes: "Puheen laskeminen",
      dataCategory: "Tiedon luokitus",
    },
    interactiveQuestionTypes: {
      standard: "Normaali kysymys",
      multiElement: "Monielementti kysymys ja vastaus",
      guideMultiRound: "Ohjattu monipyöräinen vuorovaikutus",
    },
    targetTypes: {
      all: "Kaikki",
      customerService: "Asiakaspalvelut",
      customer: "Asiakasta",
    },
    interactiveTypes: {
      similarQList: "Samanlaista ongelma",
      exceptSimilarQList: "Poista samanlaisia ongelmia",
      similarAList: "Samanlaisia vastauksia",
      wrongAList: "Väärä vastaus",
    },
    filterTypes: {
      call: "Puhelujen tallentaminen",
      dialogue: "Ikkuntekst",
      wechat: "Enterprise We chatt",
      wechatAll: "Enterprise We chat Kaikki vuoropuhelu",
      ticket: "Työjärjestyksen istunto",
      taobao: "Sähköinen kaupankäynti",
      wechatRadio: "Enterprise mikroääni",
    },
    fieldTypes: {
      keyword: "Asiasanat",
      agent: "Asiakaspalvelut",
      agentGroup: "Asiakaspalveluryhmä",
      sigleListbox: "Yksi valinta",
      multiListbox: "Monivalintavaihtoehto",
      text: "Yksi rivi",
      textArea: "Useita tekstiriviä",
      dateTime: "Aika",
      number: "Numerot",
      voice: "",
      customer: "Asiakasta",
      fieldWithLink: "Kenttä, jossa on linkki",
      percentage: "Prosenttiosuus",
      intelligentClassification: "Älykäs luokitus",
      testSetList: "Dynaamiset kentät",
      inspector: "Laaduntarkastaja",
    },
    saveTypes: { save: "Tallenna" },
    knowledgeType: { oneToOne: "Yksi kysymys ja yksi vastaus" },
    updateTypes: { update: "Päivitä", saveAs: "Tallenna" },
    recommendItemActionTypes: {
      similar: "Samanlaisia",
      exclude: "Sulje pois...",
      ignore: "Älä huomioi",
    },
    gradeStatus: {
      unread: "Lukemattomat",
      alreadyReview: "Tarkistettu",
      alreadyFiled: "Arkistoidut",
      waitReview: "Tarkistetaan uudelleen",
      alreadyRead: "Lue",
    },
    qualityCheckDetailTypes: {
      review: "Uudelleentarkastelu",
      spotCheck: "Satunnaistarkastus",
    },
    sampleAndReviewStatus: {
      init: "Erottamattu",
      unread: "Lukemattomat",
      alreadyRead: "Lue",
      complaining: "Valituksessa",
      reviewed: "Tarkistettu",
      sampling: "Poistumista ei tarkastettua",
      samplingCompleted: "Otanta",
    },
    interactiveAnswerTypes: {
      noAnswer: "Vastausta ei havaittu",
      answer: "Vastaus havaittu",
      wrongAnswer: "Havaittu virheellinen vastaus",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Ei havaittuja ongelmia",
      question: "Havaittu ongelma",
      eliminateQuestion: "Havaittu vianmäärityksen ongelma",
    },
    isManual: {
      yes: "Manuaalinen laatutarkastus",
      no: "Ei manuaalista laatutarkastusta",
    },
    readStatus: { yes: "Lue", no: "Lukemattomat" },
    reInspectionType: {
      recalcScore: "Laske vain pisteet uudelleen (luokituksilla)",
      recheckPoint: "Laske laadun tarkastuspisteet ja pisteet uudelleen",
    },
    common: {
      eCommerceReview: "Sähköinen kaupankäynti",
      socialMediaComments: "Huomautuksia sosiaalisessa mediassa",
      customer: "Asiakasta",
      customerService: "Asiakaspalvelut",
      robot: "Robot",
      customerInformation: "Asiakastiedot",
      workOrderFields: "Työnjärjestyksen kentät",
      intelligentConversationAnalysis: "Älykäs istuntoanalyysi",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Ymmärrys lyhyt toimiala, kuuntele asiakkaiden ääntä, parantaa markkinointimuutos",
      intelligentAccompanyingPractice: "Älykäs sparraus",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Luo upottava tekoäly sparraus, joka simuloi todellisia liiketoimintoja saavuttaakseen täyden hallinnan kultamitalin puhetaidot ja nopea kehittäminen dialogu taidot.",
      intelligentQualityInspection: "Älykäs laatutarkastus",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Täydellinen laatutarkastus, oikea-aikainen ja tehokas, tarkkuusaste yli 85 %",
      salesEmpowerment: "Myynnin vaikutus",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "Tekoäly muokkaa myynnin hallintaa, ymmärtää digitalisaation ja viestintäprosessin visualisoinnin. älykkäästi havaitsee jokaisen viestinnän askeleen, diagnosoi hienovaraisesti liiketoiminnan ongelmia ja uudistaa täydellisesti myyntikokemuksen.",
      algorithmPlatform: "Algoritmialusto",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Alkuperäisen sydänmoottorin puheesta... ASR- ja NLP-malleja räätälöidään päästä läpi liikeskenaario data ja toteuttaa järjestelmän itseoppimisen, älykkäämmän ja tarkemman käyttötarkoituksen saavuttamiseksi.",
      generalSettings: "Yleiset asetukset",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Aseta ja hallita tietojen käyttöoikeudet ja työntekijän roolet yhtenäisellä tavalla",
      callLog: "Puhelujen tallentaminen",
      intelligentRules: "Älykkäät säännöt",
      businessRecords: "Liiketoimintaa koskevat tiedot",
      customFilter: {
        call: "Äänipuhelu",
        text: "Tekstiikkuna",
        smartTags: "Älykäs nimi",
        pretreatment: "Aikaisemma",
        smartTagGroup: "Älykkäät tunnisteet ryhmä",
        currency: "Yleiset tiedot",
        wechat: "Enterprise We chatt",
        spotCheck: "Satunnaistarkastus",
        personaField: "Asiakasluettelo",
        ticket: "Työjärjestyksen istunto",
        taobao: "Sähköinen kaupankäynti",
        wechatRadio: "Enterprise mikroääni",
        reviewList: "Uudelleentarkastelu",
      },
      talkerRecognitionType: {
        API: "API tunnistaa kappaleen roolin",
        text: "Tekstin tunnistaminen",
      },
      operatorTypes: {
        keyword: "Avainsana täsmään",
        question: "Kyselylauseke",
        regular: "Säännölliset lausekkeet",
        context: "Kontekstin kopiointi",
        semantics: "Semanttinen täsmäys",
        word: "Samanlaisia sanoja",
        dialogue: "Ikkunanväli",
        speed: "Puheen nopeuden havaitseminen",
        grabDetection: "Tartunnan havaitseminen",
        semanticTags: "Semanttinen merkintä",
        keywordStrict: "Avainsana täsmäävät (karasti)",
        link: "Linkki- kenttä",
        entityInfoDetection: "Tietoyksikön havaitseminen",
        basicInfoDetection: "Perustietojen havaitseminen",
        volume: "Tilavuuden havaitseminen",
        emotion: "Tunteiden analyysi",
        grammar: "Kielioptiosäännöt",
        sentenceLength: "Lauseen pituuden havaitseminen",
        conceptWord: "Käsitteet sanat",
        questionsAndAnswers: "Tietopohjaratkaisu (vanha)",
        element: "Olennaisia kysymyksiä ja vastauksia",
        silent: "Vasteen aikakatkaisu",
        associate: "Tietojen seurantatiedot",
        repeat: "Toista lähetty",
        timeInterval: "Ajan kesto",
        typoDetection: "Tyyppi",
        silentOld: "Hiljaisuus",
        knowledge: "Osaamisratkaisu",
        wecomRemark: "Asiakasyrityksen mikrohuomautukset",
        customerAddTime: "Asiakkaan lisäaika",
      },
      keywordOperators: {
        all: "Täsmää kaikkiin",
        allNot: "Kaikki eivät täsmää",
        any: "Mikä tahansa vastaavuus",
      },
      applyRoles: {
        all: "Kaikki",
        customer: "Asiakasta",
        agent: "Asiakaspalvelut",
      },
      sentenceLength: {
        less: "Vähemmäs",
        greater: "Suurempaa",
        equal: "Yhtäläinen",
        greater_or_equal: "Suurempi tai yhtä suuri",
        less_or_equal: "Vähemmän tai yhtä suuri",
      },
      dialogueApplyScopes: {
        differentRoles: "Eri roolit",
        agent: "Asiakaspalvelut",
        customer: "Asiakasta",
        agentResponse: "Asiakasvasti",
        customerResponse: "Asiakasvasti",
        customerNoResponse: "Asiakas ei vastaa",
      },
      applyConditionHitTypes: {
        first: "Ensimmäinen osuma.",
        every: "Jokainen osuma.",
        last: "Viimeisin osuminen",
      },
      applyConditionScopes: {
        current: "Nykyinen",
        before: "Ennen:",
        after: "Jälkeenpäin.",
        beforeAll: "Kaikki ennen.",
        afterAll: "Loppujen lopuksi.",
        around: "Lähistöllä",
      },
      voiceDemoTypes: {
        singleChannel: "Yhden kanavan puhelun tallentaminen",
        dualChannel: "Kaksikanavainen puhelu",
      },
      sendStatus: {
        sending: "Lähetetän",
        arrive: "Toimitettu",
        fail: "Lähetys epäonnistui",
        off_sending: "Lähettämätön lukemattoma",
        off_arrive: "Lue",
        rollback: "Peruutettu",
      },
      collectionMethod: {
        assignment: "Suorat toimet",
        entity: "Yksikön poistaminen",
      },
      systemTypes: { qa: "Älykäs laatutarkastus", wfm: "Älykäs aikataulu" },
      entityOperators: {
        equals: "Yhdenmukaistaa tietoa",
        notEquals: "Ei yhtä suuri kuin tiedot",
        contains: "Sisältää tietoa",
        notContains: "Ei sisällä tietoja.",
        equalsFix: "Yhtä suuri kuin kiinteä arvo",
        notEqualsFix: "Ei yhtä suuri kuin kiinteä arvo",
        containsFix: "Sisältää kiinteitä arvoja",
        notContainsFix: "Ei sisällä kiinteää arvo",
      },
      basicInfoOperators: {
        equals: "Yhtäläinen",
        notEquals: "Ei yhtä suuri",
        contains: "Sisältää.",
        notContains: "Ei sisällä",
        isNull: "Tyhjä",
        isNotNull: "Ei tyhjä",
      },
      volumeDetectionWays: {
        loudness: "Voimakkuus desibeli",
        rangeAbilitySelf: "Muutosväli (kuin tein edellisessä lauseessa)",
        rangeAbilityOth:
          "Muutosväli (verrattuna edellä mainittuun virkkeeseen)",
        rangeAbility3: "Muutosväli (kuin olen tässä lauseessa)",
      },
      volumeDetectionOperators: {
        is: "Yhtäläinen",
        not: "Ei yhtä suuri",
        greaterThan: "Suurempaa",
        greaterThanEq: "Suurempi tai yhtä suuri",
        lessThan: "Vähemmäs",
        lessThanEq: "Vähemmän tai yhtä suuri",
      },
      sentimentTypes: {
        positive: "Myönteiset tunteet",
        negative: "Kielteinen tunne.",
        neutral: "Neutraali tunteet",
        thankful: "Kiitos.",
        happy: "Onnellinen.",
        complaining: "Valitus",
        angry: "Vihainen.",
        post: "Edestä",
        negat: "Negatiivinen.",
        neut: "Neutraalinen",
      },
      emotionOperators: {
        is: "Vaatimustenmukaisuus",
        not: "Ei vaatimustenmukaisuus",
      },
      propTypes: { system: "Järjestelmä", user: "Oma" },
      valueTypes: { text: "Teksti", number: "Numerot" },
    },
    tasks: {
      allowCustomGrievance: "Salli mukautettu valitus",
      businessSummary: "Yhteenveto",
      theDayBeforeYesterday: "Toissapäivänä eilen",
      assessActionTypes: { reassess: "Uudelleenarviointi" },
      scoreType: {
        aiScore: "Älykäs arviointi",
        manualScore: "Käsin arviointi",
        interactiveDetection: "Yhteisvaikutuksen havaitseminen",
        smartRules: "Älykkäät säännöt",
        machineLearning: "Itseoppimista",
      },
      inspectType: {
        inspectTrue: "Tarkistus",
        inspectFalse: "Ei tarkastettua",
      },
      operatorType: {
        keyword: "Avainsana täsmään",
        notKeyword: "Avainsana epäsyttö",
      },
      applyRole: {
        all: "Kaikki",
        customer: "Asiakasta",
        agent: "Asiakaspalvelut",
      },
      applyScope: {
        all: "Kaikki",
        specific: "Määritelty virke",
        scope: "Määritetty alue",
      },
      judgeStrategy: {
        all: "Täyttää kaikki edellytykset",
        arbitrarily: "Täyttävät kaikki ehdot",
        custom: "Oma logiikka",
      },
      checkPointStatus: {
        waitFor: "Odotetaan arviointia",
        notDeductScore: "Ei vähennystä",
        deductScore: "Vähennykset",
      },
      applyOptions: { hit: "Kunnossa", notHit: "Ei ehtoja" },
      gradeTypes: {
        yesOrNo: "Vaatimustenmukaisuus",
        input: "Manuaalinen syöte",
        level: "Palkkaluokan arviointi",
        multipleFactors: "Useita elementtejä",
      },
      gradeChooseHits: { yes: "Kyllä.", no: "Ei ei" },
      gradeChangeTypes: {
        edit: "Muokkaa",
        check: "Uudelleentarkastelu",
        file: "Arkistointi",
        remark: "Huomautukset",
        complain: "Valitus",
        review: "Uudelleentarkastelu",
        reviewEvaluation: "Uudelleentarkastelun arviointi",
        sample: "Satunnaistarkastus",
        sampleEvaluation: "Näytteenottotarkastus ja arviointi",
        sampleSubmit: "Näytteenottotarkastus ja toimittaminen",
        sampleAssign: "Näytteenotto",
        reviewAssign: "Uudelleentarkastelun myöntäminen",
        read: "Lue",
        reviewConfirm: "Arviointivahvistus",
        sampleConfirm: "Näytteenottotarkastusvahvistus",
      },
      gradeStatus: {
        unread: "Lukemattomat",
        alreadyReview: "Tarkistettu",
        alreadyFild: "Arkistoidut",
        waitReview: "Tarkistetaan uudelleen",
        alreadyRead: "Lue",
      },
      samplingFrequencyTypes: {
        everyDay: "Päivittäinen",
        everyWeek: "Viikoittain",
        everyMonth: "Kuukausittain.",
      },
      samplingEveryDayTimeTypes: { yesterday: "Eilen.", today: "Tänään" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Tällä viikolla.",
        lastWeek: "Viime viikolla.",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Tällä kuukaudella",
        lastMonth: "Viime kuussa.",
      },
      samplingTypes: {
        random: "Satunnainen näytteenotto.",
        proportional: "Suhteellinen näytteenotto.",
      },
      weekdays: {
        Monday: "Maanantai.",
        Tuesday: "Tiistai",
        Wednesday: "Keskiviikko.",
        Thursday: "Torstai",
        Friday: "Perjantai",
        Saturday: "Lauantai.",
        Sunday: "Sunnunti",
      },
      distributeTypes: {
        rule: "Ensisijaisten sääntöjen jakaminen",
        average: "Keskimääräinen satunnainen jakautuminen",
      },
      notMatchAnyRuleTypes: {
        random: "Keskimääräinen satunnaisjakaus",
        assign: "Määrätään nimetyille henkilöille",
        ignore: "Ei annetu",
      },
      inspectorTypes: {
        all: "Kaikki laaduntarkastajat",
        some: "Nimetty laaduntarkastaja",
      },
      pushFrequencyTypes: {
        everyDay: "Päivittäinen",
        everyWeek: "Viikoittain",
        everyMonth: "Kuukausittain.",
        inspectionUpdate: "Laatutarkastuksen päivittäminen",
        timelyInspection: "Reaaliaikainen laatutarkastus",
      },
      pushObjectTypes: {
        email: "Määritä postilaatikki",
        staff: "Asiakaspalvelut",
      },
      reviewOptions: {
        reviewResult:
          "Näytetään laaduntarkastusten tulokset uudelleentarkastelun aikana",
        reviewRecord: "Näytä asiaankuuluvat tietueet tarkastelun aikana",
        reviewAgentHidden: "Piilota agentin tiedot arvioinnin aikana",
        samplingResult:
          "Näytetään laatutarkastustulokset satunnaisessa tarkastuksessa",
        samplingRecord:
          "Näytä asiaankuuluvat tietueet satunnaisessa tarkastuksessa",
        sampleAgentHidden:
          "Piilota asiakaspalvelutiedot satunnaisen tarkastuksen aikana",
        checkExportAddCallInfoIphone: "Liitä puhelutiedot vietäessä luetteloa",
        sampleExportAddCallInfoIphone: "Liitä puhelutiedot vietäessä luetteloa",
        checkExportAddCallInfoText:
          "Liitä keskustelutietoja vietäessä luetteloa",
        sampleExportAddCallInfoText:
          "Liitä keskustelutietoja vietäessä luetteloa",
        inspectionExportAddCallInfoIphone:
          "Liitä puhelutiedot vietäessä luetteloa (enintään 10000 voidaan viedä kerralla)",
        inspectionExportAddCallInfoText:
          "Liitä keskustelusta tietoja vietäessä luetteloa (enintään 10000 voidaan viedä kerralla)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Salli pikatarkastus laadun yksityiskohdissa",
        fastSampleInSampleDetail:
          "Satunnaistarkastuksen nopea suorittaminen satunnaisten tarkastusten yksityiskohdissa",
        fastSampleInReviewDetail:
          "Mahdollistaa pistetarkastusten nopean suorittamisen katsauksen yksityiskohdissa",
      },
      permitCustomReasonList: {
        permitCustomReason: "Salli omat syyt väitteille",
      },
      autoMateTasks: {
        autoLoad: "Lisää automaattisesti",
        sample: "Automaattinen näytteenottotarkastus",
        assign: "Automaattinen kohdentaminen",
        pushes: "Automaattinen työnnä",
        assess: "Automaattinen arviointi",
        check: "Valituksen tarkastelu",
        case: "Kirjaston synkronointi",
      },
      qualityCheckDetailTypes: {
        review: "Uudelleentarkastelu",
        spotCheck: "Satunnaistarkastus",
      },
      appealTypes: {
        transliterationError: "Transkriptor virhe",
        discriminationError: "Tuomiovirhe",
        otherError: "Muut virheet",
      },
      inspectDataSources: {
        voiceCall: "Äänipuhelu",
        textDialogue: "Tekstiikkuna",
        realTimeVoiceCall: "Reaaliaikaiset äänipuhelut",
        realTimeTextDialogue: "Reaaliaikaiset tekstikeskustelut",
      },
      pointTypes: {
        automatic: "Automaattinen arviointi",
        manual: "Käsin arviointi",
        interactiveDetection: "Yhteisvaikutuksen havaitseminen",
      },
      pointGradeTypes: {
        radio: "Yksi valinta",
        input: "Syöte",
        select: "Valita",
      },
      sampleAndReviewStatus: {
        init: "Erottamattu",
        unread: "Lukemattomat",
        alreadyRead: "Lue",
        complaining: "Valituksessa",
        reviewed: "Tarkistettu",
        sampling: "Poistumista ei tarkastettua",
        samplingCompleted: "Otanta",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Älä huomioi ylituotu",
        automaticWeightCalculation: "Automaattinen painolaskenta",
      },
      calculateScoreTypes: { add: "Bonuspisteet", sub: "Miinuspisteet" },
      naChecked: {
        check: "Tämä kohde ei ole mukana sallittuun havaitsemiseen",
      },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Prosenttiyksikköä",
        allRight: "Kaikki oikeat kohdat",
        anyRight: "Mikä tahansa oikea pisteet",
      },
      noHitQuestionResults: {
        true: 'Tulos on "Kyllä"',
        false: 'Tulos on "ei"',
      },
      repetRules: {
        same: "Täsmälleen sama sisältö",
        similar: "Samankaltaisuus",
      },
      relateConditions: {
        highlightQuestion: "Korostusongelma",
        highlightAnswer: "Korosta vastaus",
        hightlightFoctors: "Korosta elementtiä",
      },
      interactiveQuestionTypes: {
        question: "Havaittu ongelma",
        eliminateQuestion: "Havaittu vianmäärityksen ongelma",
      },
      interactiveAnswerTypes: {
        noAnswer: "Vastausta ei havaittu",
        answer: "Vastaus havaittu",
        wrongAnswer: "Havaittu virheellinen vastaus",
      },
      inspectionUpdateTypes: {
        autoInspection: "Automaattinen laatutarkastus",
        autoInspectionWechat: "Automaattinen analyysi",
        artificialSampling: "Manuaalinen näytteenotto.",
        artificialReview: "Käsin uudelleentarkastelu",
        manualModification: "Nopea näytteenotto.",
        timelyInspection: "Reaaliaikainen laatutarkastus",
        samplingAssign: "Näytteenotto",
        artificialComplain: "Valituksen esittäminen",
        fastSampling: "Nopean näytteenottotarkastus ja toimittaminen",
        recheckInspection: "Uudelleentarkastus",
        recheckInspectionWechat: "Uudelleenanalyys",
      },
      timelyInspectionTypes: {
        timelyInspection: "Reaaliaikainen laatutarkastus",
      },
      pushActionTypes: { email: "Sähköposti", interfacePush: "Käyttöliittymä" },
      pushDetailWays: {
        riskAlert: "Riskien varoitus",
        subscribe: "Tilaa viestit",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Puhelu-/keskusteluviesti",
        inspectionResults: "Laaduntarkastusten tulokset",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Puhelu-/keskusteluviesti",
        textContent: "Puhelujen ja keskustelujen tekstin sisältö",
        inspectionResults: "Laaduntarkastusten tulokset",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Ryhmän keskimääräinen pistemäärä",
        addOrSubtract: "Suorat plus- tai miinuspisteet",
        noChange: "Ei plus- tai miinuspistettä",
      },
      defaultScore: {
        empty: "Tyhjä",
        add: "Bonuspisteet",
        notAdd: "Ei ylimääräisiä pisteitä",
        sub: "Miinuspisteet",
        notSub: "Ei pisteitä pienennetty",
        na: "N/A",
        input: "Manuaalinen syöte",
        choose: "Valita",
      },
      strategyTypes: {
        hitAdd: "Osui bonus",
        hitNotAdd: "Ei pisteitä lyömiseen.",
        hitSub: "Osuminen miinus pisteet",
        hitNotSub: "Osuma ei vähennä pisteitä",
      },
    },
    download: {
      exportStatus: {
        prepare: "Valmiina valmistelua",
        process: "Käynnissä olevat toimet",
        faild: "Vienti epäonnistui",
        success: "Onnistui",
      },
    },
    messageType: {
      all: "Kaikki",
      monitor: "Välitön seuranta",
      trigger: "Laukaiseva ilmoitus",
      timing: "Ajoitusraportti",
    },
    messagePushType: {
      all: "Kaikki",
      system: "Järjestelmäviestit",
      sdk: "Viesti SDK",
      push: "Käyttöliittymä",
      email: "Sähköposti",
      udesk: "Udesk-ilmoitukset",
      udeskWhite: "Asiakaspalvelujärjestelmäilmoitus",
      weChat: "Yrittäjyyden mikroviesti",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Anna näytteenottotarkastaja",
      sampleInspector: "Näytteenottotarkastaja",
      assignReviewer: "Antaa arvostelu",
      reviewer: "Arvostelin",
      taskInspector: "Tehtävätarkastaja",
    },
    sdkPushType: {
      webHook: "Vakio",
      dingDing: "Naularobotti",
      weChat: "Enterprise We chat-robot",
      customer: "Omaa Json-sisältöä",
    },
    inspectionRemarksType: {
      inspection: "Laaduntarkastus",
      check: "Satunnaistarkastus",
      review: "Uudelleentarkastelu",
    },
    segmentationStrategyType: {
      count: "Viestien lukumäärä",
      spacing: "Viestiväli",
    },
    segmentationStrategy: {
      byTime: "Ajoituksen segmentoituminen",
      customize: "Yhdistelmän viipalointi",
    },
    inspectType: {
      commonInspect: "Yleinen laatutarkastus",
      associateInspect: "Liitännäinen laatutarkastus",
    },
    typoDetectionOperators: { less: "Vähemmäs", greater: "Suurempaa" },
    autoAddTypes: {
      realTimeAdd: "Lisää reaaliajassa",
      timingAdd: "Ajoitus Lisää",
    },
    messageTypes: { text: "Tekstiviesti", markdown: "Merkintäviesti" },
    inServiceStatus: {
      activated: "Aktivoitu",
      disabled: "Ei käytössä",
      inactivated: "Ei aktivoittu",
      resigned: "Yrityksestä poistuminen",
    },
    roles: { agent: "Työntekijä", customer: "Asiakasta" },
    commentVisibleAuthority: {
      all: "Näkyvissä kaikille",
      self: "Vain itselleen näkyvissä.",
      designation: "Nimetty näkyvä henkilö.",
    },
    appealCheckingTask: {
      eCommerceReview: "Sähköinen kaupankäynti",
      socialMediaComments: "Huomautuksia sosiaalisessa mediassa",
      largeModel: "Suuri malli",
      customModel: "Oma malli",
      universalModel: "Yleinen malli",
      enterpriseWeChatText: "Yrityksen mikrotekstit",
      generalQualityInspection: "Yleinen laatutarkastus",
      processQualityInspection: "Prosessin laatutarkastus",
      voiceCopy: "Ääni kaksoislevy",
      beEqualTo: "Yhtäläinen",
      notEqualTo: "Ei yhtä suuri",
      canAppeal: "Voidaan valittaa",
      noAppeal: "Ei-vastaa",
    },
    wechat: {
      redEnvelopes: "Punainen kirjekur",
      text: "Teksti",
      picture: "Kuva",
      voice: "Ääni",
      voiceCall: "Äänipuhelu",
      video: "Videoa",
      businessCard: "Käyntikortti",
      position: "Sijainti",
      expression: "Lauseke",
      link: "Linkkiä",
      applet: "Pieni ohjelma",
      chatRecord: "Keskusteluluettelo",
      mixedMessage: "Sekoitettu viesti",
      file: "Tiedosto",
    },
    gong: {
      sessionActivity: "Istunnon toiminta",
      conversationAction: "Istunnon toiminta",
      riskSemantics: "Riski semantiikkaa",
      daysNotFollowedUp: "Päiviä ei seuratta",
      waysideData: "Tietojen seurantatiedot",
      notStarted: "Ei käynnistynyt",
      completed: "Valmistui",
      haveInHand: "Käynnissä olevat toimet",
      unpublished: "Ei julkaistu",
      published: "Julkaistut",
      knowledgePoints: "Tietämyspisteet",
      technologicalProcess: "Prosessi",
      notMarked: "Ei merkintä",
      inAnnotation: "Kutsuun",
      annotated: "Merkinnöt",
    },
    qualityChecingTask: {
      appealCanceled: "Muutoksenhaku peruutettu",
      complaintExpired: "Valituksen voimassaolo päättyi",
      incomingCall: "Saapuva puhelu",
      exhale: "Ulos hengittää",
      incomingCallthirdParty: "Saapuva puhelu (kolmenvälinen)",
      incomingCallconsultation: "Saapuva puhelu (Consulting)",
      incomingCallforcedInsertion: "Saapuva puhelu (vahva)",
      incomingCallmonitoring: "Saapuva puhelu (kuuntelu)",
      incomingCalltransfer: "Saapuva puhelu (siirto)",
      incomingCallinterception: "Saapuva puhelu (leikkaus)",
      incomingCalltransferToExternalLine:
        "Saapuva puhelu (siirto ulkopuolinen rivi)",
      incomingCallthirdPartyExternalLine:
        "Saapuva puhelu (kolmen ulkopuolinen rivi)",
      incomingCallexternalLine: "Saapuva puhelu",
      outgoingCallthirdParty: "Lähtevä (kolmenvälinen)",
      outgoingCalltransfer: "Tulokset (siirto)",
      outgoingCallforcedInsertion: "Lähtevä (vahva)",
      outgoingCallmonitoring: "Lähtevät (kuuntelu)",
      outgoingCallinterception: "Lähtevä kutsu (leikkaus)",
      outgoingCalltransferToExternalLine: "Lähde (siirto ulkopuolella)",
      outgoingCallthreePartyExternalLine:
        "Lähtevä puhelu (kolme ulkopuolinen rivi)",
      customerServiceAnswer: "Asiakaspalvelu vastaus",
      customerServiceMissed: "Asiakaspalvelu epäonnistui",
      customerAnswer: "Asiakasvastus",
      customerMissed: "Asiakas epäonnistui",
      theTelephoneIsBusy: "Puhelin käynnissä",
      phoneOffline: "Puhelin pois verkostostaName",
      customerQuickHanging: "Asiakas nopea riippuu",
      clientHangsUp: "Asiakas katkaisee puhelimen.",
      queueTimeout: "Jonon aikakatkaisu",
      giveUpQueuing: "Hylä jooka",
      outsideLineAnswering: "Ulkopuolinen vastaus",
      externalLineIsNotConnected: "Ulkopuolinen rivi ei ole liitetty",
      noQueueSelected: "Jonoa ei ole valittu",
      notEvaluated: "Ei arvioitua",
      noEvaluationRequired: "Ei tarvitse arvioida.",
      queuingSucceeded: "Jonon menestys",
      noCustomerServiceOnline: "Ei asiakaspalvelua verkossa",
      customer: "Asiakasta",
      customerService: "Asiakaspalvelut",
      outsideLines: "Ulkopuolella",
    },
    sessionActivity: {
      single: "Keskimääräinen kertakäyttöaika",
      all: "Puhelut yhteensä",
      allTime: "Puhelun kokonaiskesto",
    },
    sessionAction: {
      salesProportion: "Myynnin keskustelus",
      salesLongest: "Keskimäärin pisin myyntilue",
      customerLongest: "Keskimäärin pisin asiakas puhuu",
      discussNumber: "Perusteellisten keskustelujen lukumäärä",
      frequency: "Kyselytiheys",
    },
    monitorCriteria: {
      all: "Ennen ja jälkeen",
      before: "Ennen:",
      after: "Jälkeenpäin.",
    },
    monitorTimeCycle: { day: "Päivä.", week: "Viikko", month: "Kuukaus" },
    monitorGroupTestType: {
      ab: "Näiden kahden ryhmän vertailu",
      a: "Ryhmä A",
      b: "Ryhmä B",
    },
    fields: {
      department: "Osasto",
      customerTags: "Asiakasmerkki",
      member: "Jäsenet",
      time: "Aika",
      cascade: "Kaskadi",
      anyMatch: "Mikä tahansa vastaavuus",
      keyWord: "Asiasanat",
      keyEvents: "Avaintapahtumat",
    },
    semanticIntelligence: {
      staff: "Työntekijä",
      customer: "Asiakasta",
      employeesAndCustomers: "Työntekijät ja asiakkaat",
      notStarted: "Ei käynnistynyt",
      haveInHand: "Käynnissä olevat toimet",
      completed: "Valmistui",
      callRecording: "Puhelujen tallentaminen",
      enterpriseWeChatCall: "Yritysten mikrokäsittely",
      enterpriseWeChatText: "Yrityksen mikrotekstit",
      discourseMiningForObjectionHandling:
        "Vastalauseiden käsittely puhekaivosto",
      productSellingPointScriptsMining:
        "Tuotteiden myyntipiste puhuminen kaivostoiminta",
      conversationScenario: "Istunnon skenaariot",
      topicOfConversation: "Aiheet",
      keyEvents: "Avaintapahtumat",
      customerLabel: "Asiakasmerkki",
      includeAny: "Sisältää mitä tahansa.",
      includeAll: "Sisältää kaikki.",
      and: "Ja",
      or: "Ei",
      question: "Kyselylauseke",
      nonInterrogativeSentence: "Ei kuulu kuuluva lause",
    },
    coach: {
      notPassed: "Ei läpäisty",
      analogTelephone: "Analoginen puhelin",
      enterScenarioInformation: "Syötä skenaariotiedot",
      generateDialogueFramework: "Vuoropuhelun luominen",
      generatePromptLanguage: "Nopea luotu",
      aIGeneratedDialogue: "Luotettu tekoälyikkuno",
      system: "Järjestelmä",
      custom: "Oma",
      flow: "Prosessi",
      script: "Sanat",
      express: "Lauseke",
      emotion: "Tunteet",
      rateAccordingToCourseConfiguration: "Pisteet kurssin asetusten mukaan",
      intelligentModelRating: "Älykäs mallin pisteytys",
      allData: "Kaikki tiedot",
      dataFromThisDepartment: "Osaston tiedot",
      multidepartmentalData: "Monialaiset tiedot",
      underReview: "Katsauksessa",
      no: "Ei ei",
      yes: "Kyllä.",
      automaticRating: "Automaattinen pisteytys",
      auditRating: "Tarkastuspisteet",
      reRating: "Uudelleen tulostus",
      inAppeal: "Valituksessa",
      reviewed: "Tarkistettu",
      notViewed: "Ei katsottu",
      viewed: "Katsottu",
      uncorrected: "Ei oikaista",
      corrected: "Korjattu",
      practice: "Liikunta",
      examination: "Tarkastelu",
      other: "Muu",
      notMeetingStandards: "Ei vakio",
      meetingStandards: "Standardi",
      excellent: "Loistavaa",
      invalid: "Epäonnistuminen",
      notStarted: "Ei käynnistynyt",
      haveInHand: "Käynnissä olevat toimet",
      ended: "Päättynyt",
      completed: "Valmistui",
      hangInTheAir: "Viimeistelemättömät",
      passed: "Läpäivä",
      voiceConversation: "Ääniikkuna",
      textDialogue: "Tekstiikkuna",
      slidePresentation: "LiukuesitysName",
      unpublished: "Ei julkaistu",
      published: "Julkaistut",
      narration: "Kannattaja",
      studentSpeaking: "Kadetti puhuu",
      robotTalk: "Robotit puhuvat.",
      knowledgeBaseQA: "Tietopohjaa",
      slide: "Liukus",
      negativeEmotions: "Kielteinen tunne.",
      emotionallyNormal: "Normaali mielil",
      incompleteScript: "Epätäydellinen puheName",
      completeScript: "Täydellinen puhe",
      normalSpeechSpeed: "LIITTYVÄ NORMAALI",
      speakTooFast: "Liian nopea.",
      speakTooSlowly: "LAHTÄVÄ",
      whole: "Kaikki",
      singlePage: "Yksi sivu:",
      qA: "Yksi kysymys ja yksi vastaus",
      situationalDialogue: "Tilannevuoropuhelu",
      misses: "Osui",
      hit: "Osuminen",
      sequentialQA: "Peräkkäinen kysymys",
      randomQA: "Satunnaisia kysymyksiä",
      mastered: "Hallinnointi",
      notMastered: "Ei hallittu",
    },
    algorithm: {
      salesEmpowerment: "Myynnin vaikutus",
      enterpriseWeChatVoice: "Enterprise WeChat-ääni",
      enterpriseWeChatText: "Enterprise WeChat-teksti",
      waiting: "Odotetaan",
      extracting: "Uutteet",
      success: "Onnistui",
      fail: "Epäonnistui",
      intelligentQualityInspection: "Älykäs laatutarkastus",
      textDialogue: "Tekstiikkuna",
      voiceCall: "Äänipuhelu",
      smartTags: "Älykäs nimi",
      satisfactionEvaluation: "Tyytyväisyyden arviointi",
      score: "Luokitus",
      grade: "Luokitus",
      customerServiceTeam: "Asiakaspalveluryhmä",
      conversationAnalysis: "Keskustelun analyysi",
    },
    operatorListGather: {
      businessSummary: "Yhteenveto",
      firstSentence: "Ensimmäinen virke",
      lastSentence: "Viimeinen virke",
      allTime: "Koko ajan",
      nonBusinessWorkingHours: "Muu kuin liiketoiminta-ajat",
      businessWorkingHours: "Liiketoiminnan työaikat",
    },
    callDialogueManage: {
      eCommerceReview: "Sähköinen kaupankäynti",
      socialMediaComments: "Huomautuksia sosiaalisessa mediassa",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Kirjautuminen",
        email: "Postilaatikko",
        password: "Salasana:",
        forgetPwd: "Unohtui salasana.",
        emailCannotBeEmpty: "Postilaatikko ei saa olla tyhjä.",
        passwordCannotByEmpty: "Salasana ei saa olla tyhjä.",
        mustChangeLogin:
          "Valitan, salasanasi täytyy olla nollattu, ota yhteyttä ylläpitäjään voit palauttaa salasanan.",
        dismissionLogin: "Anteeksi, jätit työsi.",
        forbiddenLogin: "Valitettavasti tilisi ei ole käytössä.",
        loginError:
          "Kirjautuminen epäonnistui, postilaatikko tai salasanavirhe.",
        loginPrompt: "Kirjaudu järjestelmään",
        rememberMe: "Muista minut.",
      },
      logout: {
        logoutError:
          "Kirjautuminen epäonnistui. Tarkista verkkoyhteys tai yritä myöhemmin uudelleen.",
      },
      resetPassword: {
        forgetPwd: "Unohtui salasana.",
        inputEmailPrompt:
          "Kirjoita tilisi sähköposti alla ja lähetämme sinulle sähköpostia ja ohjeet salasanan nollaamiseksi.",
        sendEmailSuccess:
          "Salasanan muutoskirje on lähetetty onnistuneesti sähköpostiin, tarkista se ajoissa.",
        sendEmailFailed:
          "Postilaatikko epäonnistui. Tarkista verkkoyhteys tai yritä myöhemmin uudelleen.",
        setNewPassword: "Aseta uusi salasanasi.",
        passwordCannotEmpty: "Salasana ei saa olla tyhjä",
        passwordFormWrong: "Virheellinen salasanamuoto",
        confirmPasswordWrong: "Syötetyt kaksi salasanaa eivät ole yhtäläisiä.",
        passwordResetSuccess: "Salasanan nollaus onnistui.",
        passwordResetFailed:
          "Salasanan nollaus epäonnistui. Tarkista verkkoyhteys tai yritä myöhemmin uudelleen.",
        linkTimeover: "Nollaa salasanalinkki vanhentunut, yritä uudelleen",
      },
      form: { title: "CASE-lomakkeen näyttö" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Postilaatikko",
          emailFormatError: "Postilaatikkovirhe, ole hyvä ja palaa!",
          forgotPassword: "Unohditko salasanasi?",
          describe:
            "Kirjoita tilisi sähköposti alla ja lähetämme sinulle sähköpostia ja ohjeet salasanan nollaamiseksi.",
          send: "Lähetä",
          emailCannotBeEmpty: "Kansiota ei voi olla tyhjä",
          resetPasswordExpire:
            "Nollaa salasanalinkki vanhentunut, yritä uudelleen",
        },
        resetPassword: {
          setPassword: "Aseta uusi salasanasi.",
          newPassword: "Uusi salasana:",
          repeatPassword: "Kahdenlaista salasanaa",
          resetPassword: "Nollaa salasanaa",
          passwordCannotBeEmpty: "Uusi salasana ei voi olla tyhjä.",
          confirmPasswordCannotBeEmpty: "Salasanaa ei voi olla tyhjä.",
          passwordsNotMatch: "Kaksi salasanaa ovat epäjohdonmukaiset!",
          passwordFormWrong:
            "Salasana ei voi olla kiinaksi, ja numero on 6-14!",
          linkExpiration: "Nollaa salasanalinkki vanhentunut, yritä uudelleen",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Sinulla ei ole oikeutta katsoa tätä sivua",
      },
    },
    downloadCenter: {
      name: "Lataa keskusta",
      saveTime: "(30 päivää oletuksena)",
      headerTitle: "Lataa tiedosto",
      download: "Lataus",
      downloadPromp: "Ladatuste",
      viewProgress: "Näytä eteneminen",
    },
    uploadCenter: { name: "Lähetä keskus" },
    tasks: {
      index: {
        name: "Nimi",
        remark: "Kuvaus",
        qualityCheckTasksTitle: "Työpinen",
        informationCollection: "Kokoelman tehtävät",
        qualityCheckTasks: "Laaduntarkastustehtävät",
        wechatQaTasks: "Analyysitehtävä.",
        moduleName: "Käytä uutta mallia",
        creatNewQualityCheckTask: "Uusi tehtävä",
        generalInspectionList: "Yleinen laatutarkastuskertomus",
        editQualityCheckTask: "Muokkaa laadun tarkastustehtävää",
        creatNewQualityCheckTaskError:
          "Uutta laatutarkastustehtävää ei voitu luoda.",
        editQualityCheckTaskError: "Laadun tarkastustehtävää ei voitu muokata.",
        type: "Lomaket",
        singleScore: "Yksi pisteinen",
        singleScoreNullError: "Yksittäistä pistemäärää ei voi olla tyhjä",
        inspector: "Laaduntarkastaja",
        inspectDataSource: "Tietolähde",
        taskName: "Tehtävän nimi",
        inspectionType: "Laaduntarkastustyyppi",
      },
      manage: {
        screeningCondition: "Suodatin tila",
        template: {
          condition: {
            index: {
              name: "Tila",
              existingConditions: "Voimassa olevat edellytykset",
            },
            detail: {
              id: "ID",
              name: "Ehdon nimi",
              remark: "Kuvaus",
              checkRange: "Tunnistusalue",
              operator: "Toiminnanharjoittaja",
              operatorLogic: "Käyttäjälogia",
            },
          },
          checkPointTest: {
            title: "Sään testi",
            running: "Säännöt kokeessa, myöhemmin...",
            clearSuccess: "Simuloituikkuna tyhjentyi onnistuneesti",
            mock: "SimuloituikkunaComment",
            save: "Tallenna valittu ikkuna",
            saveAll: "Tallenna kaikki keskustelut",
            saveSuccess: "Keskustelu tallennettiin onnistuneesti",
            close: "Poistu...",
            delete: "Poista valitut",
            clear: "Tyhjä",
            customer: "Asiakasta",
            agent: "Asiakaspalvelut",
            test: "Testi",
            request:
              "Simulaatioikkuna on tyhjennetty, sitä ei voi palauttaa. Vahvistaa sen?",
            deletePopconfirmContent:
              "Haluatko varmasti poistaa valitun ikkunan?",
          },
          checkPoint: {
            index: {
              name: "Säännön asetukset",
              wechatTitle: "Analyysimallin asetukset",
              existingCheckPoints: "Voimassa olevat laaduntarkastussäännöt",
              currentCheckPointScore:
                "Nykyinen laaduntarkastussääntöjen pistemäärä on:",
              currentCheckPointScoreWechat:
                "Nykyinen analyysimalli pisteet on:",
              currentCheckPointWeight:
                "Nykyisen laatutarkastussäännön kokonaispaino on oltava:",
              weightTotalScoreAndItemScore:
                "Kokonaismäärä: 100, yksi pistemäärä:",
              template: "Laaduntarkastussäännön malli",
              import: "Tuo laatutarkastussäännön malli",
              getTemplateError: "Laaduntarkastussäännön mallia epäonnistui.",
              tabs: { template: "Malli", demo: "Näytteet" },
              customize: "Tyhjä malli",
              move: "Raahaa ja pudota säätääkseen luokitusta ja lajittelua",
              classification: "Laaduntarkastusluokitus",
              classificationWeChat: "Malli luokittelu",
              first: "Tason 1 luokittelu",
              second: "Toissijainen luokitus",
              new: "Uusi laatutarkastussäännö",
              weChatNew: "Lisää analyysimalli",
              createWeChat: "Uusi analyysimalli",
              empty: "Laaduntarkastussääntöjä ei ole toistaiseksi,",
              wechatEmpty: "Ei analyysimallia.",
              newSecond: "Uusi toissijainen luokittelu",
              newFirst: "Uusi ensimmäisen tason luokittelu",
              checkPoint: "Laaduntarkastussäännöt",
              checkPointWeChat: "Analyysimalli",
            },
            detail: {
              name: "Nimi",
              remark: "Kuvaus",
              type: "Tyyppi",
              deduction: "Vähennykset",
              rule: "Säännöt",
              highlighted: "Korosto",
              gradeType: "Pisteysmenetelmä",
              interactiveCategory: "Yhteisvaikutuksen luokitus",
              predeterminedScore: "Pisteet",
              weight: "Paino",
              na: "N/A",
              default: "Oletuspisteet",
              preRule: "Ehtoa koskeva sääntö",
              analysisHighlighted: "Jäsennyssäännöt",
              categorySelectPrompt: "Valitse luokka",
              checkHighlightArrayError:
                "Sääntö ei täsmää kohokohtaan, napsauta uudelleen pars- sääntöpainiketta.",
              foctorsHolder: "Valitse elementti",
              foctorsErrorNone: "Monielementti on tyhjä, lisää monielementti.",
              naError: "Kohdetta ei voi sulkea, kun oletuspisteet ovat Ei/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'Ei ole sovellettava, jossa asetetaan laadun tarkastussäännön edellytykset. Jos ehto täyttyy, sääntöä ei sovelleta. Jos ehto ei täyty, sääntöä ei havaita.\nEdellytysten kuvaus: \'&&\' tarkoittaa \'ja\', \'||\' tarkoittaa "tai", "ja" Ilmoitetaan "EI" esimerkki "(R1 || R2) && R3"',
                applicationMessageType: "Sovelluksen viestin tyyppi",
                selectAll: "Valitse kaikki",
                highlightTags: "Korostuksen nimi:",
                ruleType: "Säännötyyppi",
                dataSource: "Tietolähde",
                whole: "Kaikki",
                enterpriseMicroConversation: "Yritystoiminnan mikroistunnot",
                enterpriseAndMicroCommunication: "Yritysten mikrokäsittely",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Tuomiolma",
                    dialogueNode: "Ikkuno",
                    editNodes: "Muokkaa solmua",
                  },
                  nodeConfig: {
                    nodeName: "Solmin nimi",
                    nodeRules: "Solmusäännöt",
                    scoringLogic: "Pisteet logiikka",
                    score: "Pisteet:",
                    rule: "Säännöt",
                    multiBranchLogic: "Monitoimialalogia",
                    highlight: "Korosto",
                    submit: "Toimittaminen",
                  },
                  ruleList: {
                    branch: "Haara",
                    branchName: "Haaran nimi:",
                    addRules: "Lisää sääntö",
                  },
                  sidebar: { node: "Solmi" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Automaattinen viimeistely",
                      canvasAdaptation: "Adaptiivinen kankaa",
                      copy: "Kopioi",
                      delete: "Poistaa",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Lapsen solmua ei voida käyttää yhteyden kohteena.",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Alku solmu ei voi olla yhteystiedot",
                      startingNodeCannotConnectMultipleNodes:
                        "Aloitussolmu ei voi yhdistää useita solmuja",
                      nodeCannotBeConnectedToItself:
                        "Solmu ei voi yhdistää itseensä",
                      startNode: "Aloitussolmi",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Peruuta Valitse kaikkiComment",
                    selectAll: "Valitse kaikki",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Vaatimustenmukaisuus",
                    gradedEvaluation: "Palkkaluokan arviointi",
                  },
                },
              },
            },
            configuration: {
              title: "Laaduntarkastussäännöt",
              wechatTitle: "Analyysimalli",
              neme: "Nimi",
              errorMessage: "Poista epäonnistui vähintään yhdellä ehdolla",
              desc: "Kuvaus",
              status: "Tilanne",
              yes: "Tehokas",
              no: "Virheellinen",
              namePlaceHolder: "Anna nimi",
              desPlaceHolder: "Anna kuvaus",
              nameEmpty: "Nimiä ei voi olla tyhjä",
              desEmpty: "Kuvausta ei voi olla tyhjä",
              type: "Tyyppi",
              ruler: "Älykkäät säännöt",
              machineLearning: "Itseoppimista",
              autoScore: "Älykäs pisteytys (vanha)",
              manualScore: "Manuaalinen pisteytys",
              interaction: "Yhteisvaikutuksen havaitseminen (vanha)",
              typeTips: {
                model:
                  'Älykäs malli: Se on laadunvalvontajärjestelmä, joka voi nopeasti tarkistaa palvelun ongelmat ilman konfigurointia ja huoltoa. Tämä malli on yleisempi, jos et täytä liiketoimintaa koskevia vaatimuksia, käytä "älykkäitä sääntöjä" tai "henkilökohtainen pisteytys."',
                rule: "Älykkäät säännöt: automaattinen pisteytys perustuu sääntöön AI",
                manual:
                  "Manuaalinen pisteytys: manuaalinen laadun tarkastus, manuaalinen pisteytys manuaalisella pisteytyksellä",
              },
              model: "Malli:",
              ruleType: {
                words: "Sanat",
                interaction: "Yhteisvaikutus",
                feature: "Ominaisuudet",
                information: "Tiedot",
              },
              testingMode: {
                all: "Tunnistetaan kaikkia avainsanoja.",
                any: "Tunnista mielivaltaiset avainsanat",
              },
              operatorTypesOfInteraction: {
                question: "Yksi kysymys ja yksi vastaus",
                element: "Tekijän vastaus",
                repet: "Kontekstin kopiointi",
                snatch: "Nappaa sanat",
                silent: "Hiljaisuus",
              },
              role: "Tunnistusrooli:",
              preposition: "Esittelijä:",
              range: "Tunnistusalue:",
              from: "- En.",
              to: "Tuomio ei.",
              end: "Tuomio",
              hasOldSilentTip:
                'Haloo, parantaakseen tarkkuutta, Älykkäitä sääntöjä, vuorovaikutusta, hiljaisuutta, on päivitetty "Response Timeout". On suositeltavaa päivittää käyttösäännöt!',
              changeToOld: "Vaihda perintöon",
              changeToNew: "Vaihda uuteen versioon",
              logic: {
                logic: "Pisteet logiikka",
                naTip:
                  "Ei ole sovellettava, jossa asetetaan laadun tarkastussäännön edellytykset. Jos edellytykset täyttyvät, sääntöä ei sovelleta. Jos edellytykset eivät täyty, sääntöä ei havaita.",
                score: "Pisteet:",
                na: "N/A：",
                naDes: "Tämä kohde ei ole mukana sallittuun havaitsemiseen",
                preRule: "Ehtoja koskevat säännöt:",
                notCatchPreRule: "Jos edellytykset eivät täyty:",
                naChecked: "Kun isketään N/A:",
                preRuleEmpty: "Edellytyssääntöä ei voi olla tyhjä",
                operatorEmpty: "Tarvittava tila ei voi olla nolla",
                multipleEmpty:
                  "Tarvittavaa ehtoa ei voi olla tyhjä usealla logiikalla pisteestä",
                average: "Ryhmän keskimääräinen pistemäärä",
                addOrSubtract: "Suorat plus- tai miinuspisteet",
                noChange: "Ei plus- tai miinuspistettä",
                rule: "Säännöt:",
                all: "Täyttää kaikki toiminnanharjoittajat",
                any: "Täyttää mielivaltainen operaattori.",
                customize: "Oma käyttäjän logiikka",
                multiple: "Useita logiikkapisteet",
                heightLight: "Korostus:",
                machineLearningTip:
                  "Tällä hetkellä itseoppimisto on tehokas ainoastaan yksittäispuheessa. Monimutkaista puhetta varten käytä älykkäitä sääntöjä.",
                strategy: "Strategia:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Hyväksyntä onnistuneesti",
            },
            components: {
              pageHeaderExtra: {
                test: "Testi",
                formal: "Muodollisen",
                submit: "Toimittaminen",
              },
              createModal: { index: { myRules: "Minun säännöt" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Prosessin automaatio",
              screeningCondition: "Suodatin tila",
              aiScreeningCondition: "Älykäs suodin tila",
              rule: "Säännöt",
              automaticAddition: "Lisää automaattisesti",
              automaticAdditionTip:
                "Lisää tietoja automaattisesti tehtäviin reaaliajassa tai säännöllisin väliajoin tekoälyn laatutarkastukseen",
              autoAdd: {
                autoAddType: "Automaattista lisäämistä",
                addFrequency: "Lisää taajuus",
                add: "Lisää",
              },
              automaticSamplingInspection:
                "Automaattinen näytteenottotarkastus",
              automaticSamplingInspectionTip:
                "Esimerkiksi manuaalista laatutarkastusta edellyttää tietoja automaattisesti. Poista 10 % eilisistä satunnaisista datasta joka päivä manuaaliseen näytteenottoon.",
              everyAgentCheckLimit: "Enimmäispitoisuus ainetta kohti",
              automaticAllocation: "Automaattinen kohdentaminen",
              automaticAllocationTip:
                "Näytteenottotarkastusten jakaminen: tuotetut tiedot jaetaan automaattisesti laaduntarkastajille. Esimerkiksi 10 prosenttia eilisistä satunnaisista tiedoista otetaan päivittäin manuaalisia näytteenottotarkastuksia varten. ja kaksi laaduntarkastajaa siirretään keskimäärin satunnaisesti tähän tehtävään.",
              automaticAllocationTip2:
                "Uudelleentarkastelu: muutoksenhakutiedot jaetaan automaattisesti laaduntarkastajalle esimerkiksi tarkastusta varten, päivittäiset muutoksenhakutiedot osoitetaan yhtä sattumanvaraisesti kahdelle tämän tarkastustehtävän laatutarkastajalle.",
              automaticPush: "Automaattinen työnnä",
              automaticPushTip:
                "Laukaisinilmoitus: ilmoitus/hälytys laatutarkastuksen aikana esimerkiksi silloin, kun asiakaspalvelussa tapahtuu kuolemaan johtava virhe, yrityksen mikroryhmälle lähetetään ilmoitusviesti.",
              automaticPushTip2:
                "Aikaistu raportti: Lähetä laatutarkastuksen tulokset esimerkiksi postin muodossa, Lähetä eilisen manuaalisen näytteenottotulokset asiakaspalveluvalvojan postilaatikkoon joka päivä.",
              automaticAssess: "Automaattinen arviointi",
              automaticAssessTip:
                "Manuaalisia näytteenottotuloksia verrataan säännöllisesti tekoälyperäisen laaduntarkastuksen tuloksiin tarkkuuden arvioinnissa. Esimerkiksi eilisten näytteenottotietojen tarkkuus arvioidaan joka päivä kello 8:00.",
              reexaminationComplaint: "Valituksen tarkastelu",
              spoktReviewDetail: "Yrityksen asetukset",
              spoktReviewDetailTip:
                "Määrittele toimintoja, kuten laatutarkastusluettelo, satunnainen tarkastusluettelo, valitusliiketoiminta, katsausluettelo ja huomautukset",
              qaDetailAction: "Laaduntarkastuksen asetukset",
              spoktDetailAction: "Näytteenoton kokoonpanot",
              reviewDetailAction: "Tarkista asetukset",
              appealAction: "Väitteen asetukset",
              remarkTemplate: "Muistion malli",
              automaticArchiving: "Automaattinen arkistointi",
              conditionalRules: "Tilaa koskevat säännöt",
              qualityInspectionConditions: "Laaduntarkastusehdot",
              unopened: "Ei vielä avaa",
              sampleInspectionDistributionModel: "Näytteenotto",
              reviewAllocationModel: "Uudelleentarkastelun myöntäminen",
              distributionModelTitle: "Jakopolitiikka:",
              pushRules: "Paina säännöt",
              push: "Työnnä.",
              assessRules: "Arviointisäännöt",
              on: "on",
              off: "off",
              updateStatusSuccess: "Tilan muutos onnistunut!",
              updateStatusFailed:
                "Tilan muutos epäonnistui, yritä myöhemmin uudelleen!",
              sampleNumberOrPercent: "Näytteenottonumero tai -osuus",
              inspectorList: "Nimetty laaduntarkastaja",
              numberOfSubmission: "Sallittujen esitysten lukumäärä",
              rewivewDays: "Päivä.",
              TimeOfSubmission: "Huomautusten toimittamisen voimassaolopäivä",
              rewivewSecond: "Ajani",
              switchOn: "Avaa",
              switchOff: "SuljeComment",
              notMatchAnyRule: "Kun sääntöjä ei vastaa:",
              appealOptionsList: {
                hiddenInspector: "Valituksen piilotetut tiedot tarkastajalle",
                hiddenHit:
                  "Vetoomuksen sivulla tuetaan osumien korostamista ja paikannusta.",
              },
              caseLibrary: "Kirjaston synkronointi",
              caseLibrarySyncTip:
                "Synkronoi QA-järjestelmän lisäämät tapaukset asiakaspalvelujärjestelmään.",
              addCase: "Lisää synkronointia",
              caseLibraryId: "Kirjasto",
              caseLibraryIdTip: "Tapauspohjan ryhmittyminen",
              udeskCaseLibraryIdTip:
                "Asiakaspalvelujärjestelmän tapausryhmittyminen",
              syncHis: "Synkroninen historiakohtaus",
              otherSettings: "Muut asetukset",
              udeskCaseLibraryId: "Työnnä asiakirjaryhmä",
              url: "Työnnä osoite",
              urlTip:
                "Anna käyttöliittymän osoite, joka alkaa osoitteella http:// tai https://",
            },
            templateNew: {
              secondaryReview: "Toissijainen tarkastelu",
              numberOfAppealsAllowedToBeSubmitted:
                "Sallittujen valitusten lukumäärä",
              validityOfSubmissionOfAppeal: "Valituksen jättämisen voimassaolo",
              validityOfAppealReview: "Valitusmenettelyn voimassaolo",
              hours: "Tunnit",
              businessSummary: "Yhteenveto",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Uuttamisen alaraja ainetta kohti",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Yhteenveto tuottaa automaattisesti suuri malli, ja ulottuvuutta voidaan räätälöidä liiketoimintatilanteen mukaan.",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Taso 1 Reviewer Ainoastaan toteutusstrategia",
              secondaryCompounding: "Toissijainen komposiitti:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Toissijaiset arvostelijat tukevat vain tasavertaista jakautumista.",
              secondLevelReviewer: "Toisen tason arvostelija:",
              samplingInspectionTarget: "Näytteenottotarkastuksen tavoite",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Määritä kohde, joka on täytettävä manuaalisella näytteenottotarkastuksella, ja antaa kiinnitys, kun kohdetta ei saavuteta tai ylitty. Esimerkiksi kunkin asiakaspalvelun olisi suoritettava näytteenottotarkastus 100 tuotteen kuukaudessa ja kun tavoite on alle 80 tuotetta, laatutarkastus- ja näytteenotto-tarkastussivu antaa viipymättä.",
            },
            component: {
              verificationFailed: "Tarkastus epäonnistui",
              pleaseAddASecondLevelQualityInspector:
                "Lisää toisen tason laatutarkastaja",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Kysy",
                    fieldName: "Kenttä nimi",
                    fieldDescription: "Kentän kuvaus",
                    generateContent: "Luo sisältö",
                    enabled: "Käytössä",
                    generationMethod: "Tuotantomenetelmä",
                    automaticGeneration: "Luotu automaattisesti",
                    manualTrigger: "Manuaalinen laukaisinen",
                    filterCondition: "Suodatin tila",
                    return: "Palautus",
                    preserve: "Tallenna",
                  },
                  components: {
                    training: { index: { generateResults: "Luo tulokset" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Ei kelpaa",
                    limitedScope: "Rajoitettu soveltamisala",
                    add: "Lisää",
                    thereIsCurrentlyNoDataAvailable: "Ei tietoa",
                  },
                },
              },
              index: {
                fieldName: "Kenttä nimi",
                fieldDescription: "Kentän kuvaus",
                nothing: "Ei ole.",
                generationMethod: "Tuotantomenetelmä",
                generateManually: "Luo käsin",
                automaticGeneration: "Luotu automaattisesti",
                generateContent: "Luo sisältö",
                notQualified: "Ei kelpaa",
                filterCondition: "Suodatin tila",
                enabled: "Käytössä",
                type: "Tyyppi",
                system: "Järjestelmä",
                custom: "Oma",
                operation: "Toiminto",
                edit: "Muokkaa",
                areYouSureToDeleteIt: "Haluatko varmasti poistaa?",
                delete: "Poistaa",
                addField: "Lisää kenttä",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Roolin asetukset",
              roleConfigTip:
                "Käytetään määrittämään tunnistussäännöt kaiuttimen erottelun käsittelyyn, kun tuotu puhelutiedot ovat mono. Täyttää asiamiehen roolia koskevia sääntöjä",
              operator: "Toiminnanharjoittaja",
              operatorLogic: "Käyttäjälogia",
            },
          },
          manualAdd: {
            index: {
              name: "Lisää käsin.",
              tip: "Käytetään esikatselutietojen suodattamiseen määrittämällä kriteerit manuaalisesti ja lisäämällä ne nopeasti laatutarkastustehtäväin",
              screeningCondition: "Lisää suodin kriteerit",
              aiScreeningCondition: "Älykäs suodin tila",
              rule: "Säännöt",
              createTime: "Lisää aika",
              filterTitle: "Suodatin sisältö",
              filterRule: "Suodatin tila",
              startTime: "Aloitusaika",
              endTime: "Loppuaika",
              records: "Tuo tietue",
              createUserName: "Toiminnanharjoittaja",
              successCount: "Lisätty onnistuneesti",
              conditionRule: "Tilaa koskevat säännöt:",
              preview: "Esikatselue",
              previewData:
                "Tällä hetkellä on {0} vastaavat suodinkriteerit, {1} tehtäviä on lisätty, {2} tehtäviä voidaan lisätä, ja {3} tehtäviä ei voi lisätä.",
              failCount: "Lisääminen epäonnistui",
            },
          },
          basicInfo: {
            index: {
              title: "Perustiedot",
              name: "Nimi",
              taskName: "Tehtävän nimi",
              taskNamePlaceholder: "Anna tehtävän nimi",
              requiredName: "Tehtävän nimi ei voi olla tyhjä",
              taskRemarkPlaceholder: "Anna tehtäväkuvaus",
              requiredRemark: "Tehtävän kuvausta ei voi olla tyhjä",
              remark: "Kuvaus",
              taskRemark: "Tehtävän kuvaus",
              source: "Tietolähde",
              benchmarkScore: "Vertailuarvot",
              benchmarkScorePlaceholder: "Anna vertailuarvojen pistemäärä",
              scoreLowerLimit: "Vähimmäispisteiden alempi raja-arvo",
              scoreUpperLimit: "Suurin pistemäärän korko",
              inspectType: "Laaduntarkastustyyppi",
              nlpModel: "NLP-malli",
              commonInspect: "Yleinen laatutarkastus",
              associateInspect: "Liitännäinen laatutarkastus",
              associateCycle: "Vastaavuusjakso",
              associateRegulation: "Yhdistyssäännöt",
              judgeStrategy: "Suorituslogia",
              rule: "Säännöt",
              commonTip:
                "Yleinen laatutarkastus: sovelletaan yhtenäiseen viestintään ja vuoropuheluun sekä yhtenäisen viestinnän sisällön laatutarkastukseen.",
              associateTip:
                "Tähän liittyvä laatutarkastus: sovelletaan useisiin yhteyksiin ja keskusteluihin, yhdistämällä useita viestintää laaduntarkastuksia varten.",
              cycleTip:
                "Jos siihen liittyy useita viestintää ja vuoropuhelua, siihen liittyvän kauden tiedot syötetään laatutarkastustehtävään.",
              scoreLowerLimitPlaceholder:
                "Syötä vähimmäispistemäärä rajoitukset",
              scoreUpperLimitPlaceholder: "Anna pistemäärän enimmäistaski",
              call: "Äänipuhelu",
              dialogue: "Tekstiikkuna",
              user: "Laaduntarkastaja",
              wechatUser: "Toiminnanharjoittaja",
              noDesignated: "Ei",
              type: "Lomaket",
              singleScore: "Yksi pisteinen",
              conduct:
                "Täytä seuraavat tiedot ensin ja klikkaa sitten Seuraava.",
              conduct2:
                "Täyttäkää laaduntarkastussäännöt ja laaduntarkastuspisteet. Jos olet suorittanut asetukset, voit napsauttaa Seuraava tai poistuopas",
              conduct3:
                "Laatutarkastuksen luokitus määritetään laaduntarkastuspisteen ja sen luokituksen mukaisesti. Voit määritellä erilaiset laatutarkastustasot. Jos et tarvitse luokitusta, voit jättää tämän vaiheen väliin. Jos olet suorittanut asetukset, voit napsauttaa Next tai poistua opasta.",
              conduct4:
                "Voit määrittää tietojen ominaisuudet, jotka automaattisesti lisätään tehtävään tietojen mukaan lisäasento. Jos sinun ei tarvitse automaattisesti lisätä tietoja toistaiseksi, voit napsauttaa Skip. Jos olet suorittanut asetukset, voit napsauttaa Next.",
              conduct5:
                "Jos käytät mono tallennusta, muista määrittää tämä kohde ja napsauta Seuraava. Käytä vain asiakaspalvelun ääntä auttaa järjestelmää tunnistamaan eri raitoja asiakaspalvelun ja asiakkaan välillä. Jos käytät kaksikanavaista tallennusta tai olet suorittanut asetukset, voit napsauttaa Skip",
              conduct6:
                "Voit käyttää manuaalista lisätoimintoa laaduntarkastusjärjestelmään nykyisten viestintätietojen käyttöönottoon, tai olet lähettänyt manuaalisen lisätehtävän, napsauta Loppu suoraan.",
              helper: "Näytä ohje-dokumentaatiot",
              quit: "Poistukäynnistä",
              next: "Seuraava vaihe",
              complete: "Valmistus",
              skip: "Ohita",
              cancel: "Peruus",
              delete: "Poistaa",
              save: "Tallenna",
            },
            template: {
              samplingInspector: "Näytteenottotarkastaja",
              sessionType: "Istunnon tyyppi",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Laaduntarkastuspenki",
              search: "Suodatin",
              export: "Vienti",
              condition: "Tila",
              logic: "Logiikka",
              reQualityInspection: "Uudelleentarkastus",
              reQualityInspectionUnderWay: "Tarkastustehtävä...",
              reQualityInspectionUnderWayTip:
                "Sivun poistuminen ikkunan sulkemisen jälkeen ei vaikuta tehtävän",
              reQualityInspectionDone: "Uudelleentarkastus saatu",
              reQualityInspectionDoneTip:
                "Uudelleentarkastus on onnistuneesti saatu päätökseen, napsauta vahvistuspainiketta.",
              endReQualityInspection: "Lopeta tehtäväComment",
              alreadyDoneTip: "Valmistui",
              totalTip: "Yhteensä",
              unit: "Artikla",
              endReInspectionSuccess: "Uudelleenlaatun tehtävä päättyi",
              closeWindow: "Sulje ikkuno",
              endReInspectionFailed:
                "Laatutehtävä epäonnistui, yritä myöhemmin uudelleen!",
            },
            detail: {
              name: "Laaduntarkastustiedot",
              reviewName: "Katsaus yksityiskohdat",
              spotCheckName: "Näytteenottoa koskevat tiedot",
              audioText: "Tallennettu teksti",
              callInfo: "Puhelutiedot",
              satisfactionEvaluation: "Tyytyväisyyden arviointi:",
              queueTime: "Aika jonossa:",
              ringingTime: "Sointiaika:",
              callTime: "Kutsutapahtumat:",
              hangUpIdentity: "Puhelu bileet:",
              location: "Koti:",
              source: "Lähde:",
              agent: "Asiakaspalvelu:",
              deviceType: "Laitetyyppi:",
              callType: "Kutsutyyppi:",
              phoneNumber: "Rele-numero:",
              task: "Tehtävät:",
              commentsTip: "Syötä laatutarkastus kommentit",
              lastPage: "Edellinen sivu",
              nextPage: "Seuraava sivu",
              score: "Laaduntarkastuksen pisteet",
              review: "Uudelleentarkastelu",
              file: "Arkistointi",
              changeList: "Muuta tietuet",
              fullMarks: "Täysi merkkit",
              most: "Suurin osa",
              saveReview: "Uudelleentarkastelu",
              saveSpotCheck: "Näytteenottotarkastus ja toimittaminen",
              point: "Laaduntarkastuspisteet",
              sampleAssignInspector: "Anna näytteenottotarkastaja",
              reviewAssignInspector: "Antaa arvostelu",
              sampleStatus: "Näytteenottotilanta",
              read: "Lue",
              user: "Käyttäjä",
              confirmer: "Vahvistettu henkilö.",
              voiceDownloadError:
                "Ääni lataus epäonnistui, yritä myöhemmin uudelleen!",
              smartTags: "Älykäs nimi",
              interactiveTip:
                "Käytä vuorovaikutustunnistusta tunnistaaksesi asiakaspalvelun käytön FAQ",
              interactiveIsEmptyTip:
                "Interaktiivista tunnistussisältöä ei havaittu",
              businessRecords: "Liiketoimintaa koskevat tiedot",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Enterprise WeChat Groupin analyysi",
            },
          },
        },
      },
      cards: {
        health: "Terveystarkastukset",
        accuracy: "Etsintähyväksyntänopeus",
        sessionsTotal: "Istunnot yhteensä",
        enable: "Ota käyttöön",
        disable: "Poista käytöstä",
        none: "Ei ei",
        call: "Puhelu",
        dialogue: "Dialogi",
        taobao: "Sähköinen kaupankäynti",
        wecom: "Istunto",
        ticket: "Työskentelyjärjestys",
      },
      component: {
        backup: "(Takauppi)",
        taskSaveAsSucceeded: "Tehtävä tallennettiin onnistuneeksi",
        copyContent: "Kopioi sisältö",
        essentialInformation: "Perustiedot",
        ruleConfiguration: "Säännön asetukset",
        basicInformationQualityInspector: "Perustietojen laadun tarkastaja",
        qualityInspectionRating: "Laatutarkastuksen luokitus",
        roleConfiguration: "Roolin asetukset",
        autoAddConfiguration: "Lisää asetukset",
        autoPushConfiguration: "Automaattinen työntöasetukset",
        automaticSamplingConfiguration:
          "Automaattinen näytteenottokonfiguraatio",
        automaticAllocationConfiguration: "Automaattinen kohdistaminen",
        businessConfiguration: "Yrityksen asetukset",
        caseBaseConfiguration: "Kirjasto",
      },
      template: {
        cancel: "Peruus",
        complete: "Valmistus",
        sort: "Lajittelu",
        ruleApproval: "Säännön hyväksyminen",
        enterpriseMicroConversationAnalysisTask:
          "Yritystoiminnan mikroistunto-analyysit",
        enterpriseAndMicroVoiceAnalysisTask:
          "Enterprise mikroäänen analysointi",
        saveTaskAs: "Tallenna tehty",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Säännön yksityiskohdat" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Muutos onnistui",
            automaticApproval: "Automaattinen hyväksyntä",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Muutos onnistui",
            test: "Testi",
            formal: "Muodollisen",
            adopt: "Jäsenvaltiot",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Hylkäätkö varmasti?",
              pleaseEnterTheRejectionReason: "Syy hylkäämiseen",
              reject: "Hylätty",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Muutos onnistui",
              taskName: "Tehtävän nimi",
              dataSource: "Tietolähde",
              qualityInspectionType: "Laaduntarkastustyyppi",
              state: "Tilanne",
              updateTime: "Päivitysaika",
              updatedBy: "Päivitä henkilöä",
              approver: "Hyväksyntä",
              reasonForRejection: "Hylkäämisen syy",
              operation: "Toiminto",
              details: "Yksityiskohtaiset tiedot",
              adopt: "Jäsenvaltiot",
              whole: "Kaikki",
              approvalStatus: "Hyväksynnän tila",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Yleiskatsaus",
      hi: "Terve.",
      standardEdition: "Vakiopio",
      call: "Puhelun laatutase",
      text: "Tekstin laatutarkastuksen voimassaoloaika",
      collectionTasks: "Kerätyt laatutarkastustehtävät",
      noCollection: "Ei kerätä laatutarkastustehtäviä,",
      goToCollecte: "Siirry kokoelmaan",
      yesterdayOverView: "Eilisen yritystilastot",
      yesterdayAgent: "Eilisen asiakaspalveluluettelo",
      yesterdayInspWork: "Laaduntarkastajan eilinen työtilanne",
      operationalStatistics: {
        title: "Yritystilastot",
        qualityTesting: "Laaduntarkastus",
        spotCheck: "Satunnaistarkastus",
        appeal: "Valitus",
        chartTitles: {
          qualityTestingTrend: "Laaduntarkastuksen määrän trendi",
          qualityTestingAnalysis: "Laaduntarkastustehtävät",
          spotCheckTrend: "Näytteenottosuuntaus",
          spotCheckAnalysis: "Näytteenottotehtävän tilanne",
          appealTrend: "Valitetuuntaukset",
          appealAnalysis: "Muutoksenhakuelimeksiannon asema",
        },
        titles: {
          qualityDataTend: "Laaduntarkastustietojen jakaminen",
          qualityCover: "Laatutarkastuksen kattavuus",
          qualityAverageTime: "Keskimääräinen laatutarkastus odotusaika.",
          qualityCoverTend: "Laaduntarkastuksen kattavuussuuntaus",
          handCover: "Käsittely",
          checkCover: "Näytteenotto kattavuus",
          resAverageTime: "Keskimääräinen käsittelyaika",
          handTend: "Käsittelysuuntaus",
          dataTend: "Tietotehtävien jakautuminen",
        },
        qualityAverage: "Keskimääräinen laatutarkastuspisteet",
        scoreTrend: "Laaduntarkastuksen pisteytys trend",
        qualityAverageTime: "Keskimääräinen laatutarkastus odotusaika.",
        callCoverage: "Puhelun laadun kattavuus",
        textCoverage: "Tekstin laadun kattavuus",
        checkCount: "Näytteenottotietojen määrä",
        siphonCount: "Poista tietojen määrä",
        reviewCount: "Tarkista tietojen määrä",
        appealCount: "Valituksen tiedot",
        checkRate: "Sattumanvaraisten tarkastusten prosenttiosuus",
        reviewRate: "Prosenttiosuus kanteen tarkistuksesta",
        PieTitle: {
          totalCount: "Keskustelujen kokonaismäärä",
          totalCall: "Puhelujen määrä",
          totalIm: "Tekstikeskustelujen määrä",
          inspectionCall: "Puhelun laadun määrä",
          inspectionIm: "Tekstin laadun tarkastuksen määrä",
          checkCall: "Äänenäytteenottomäärä",
          unCheckCall: "Ääniä, jota ei oteta näytteet",
          checkIm: "Tekstinäytteenottomäärä",
          unCheckIm: "Näytteenottoton",
          reviewCall: "Ääniarvostelun äänenvoimakkuus",
          reviewIm: "Tekstiarvioinnin määrä",
          unReviewCall: "Arvioimaton äänimäärä",
          unReviewIm: "Tarkistamattoman tekstin määrä",
          unInspectionCall: "Äänimäärää ei tarkastettu",
          unInspectionIm: "Tekstin tarkastamaton määrä",
        },
        check_rate: "Näytteenotto kattavuus",
        voice_check_rate: "Äänenäytteenottokattavuus",
        text_check_rate: "Tekstinäytteenottokato",
        sample_execute_time: "Keskimääräinen näytteenottokäsittelyaika",
        review_execute_time: "Keskimääräinen valituskäsittelyaika",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Laaduntarkastussäännöt",
          agent: "Asiakaspalveluluokitusluettelo",
        },
        tableTips: {
          conditions:
            "<Div> lajittelee niiden keskustelujen kokonaismäärän, jotka rikkovat ja noudattavat laatutarkastussääntöjä. <Br />\nLaadunvalvonnan kohde: laadun tarkastuspisteen nimi <br />\nLaittomien keskustelujen kokonaismäärä: laittomien keskustelujen kokonaismäärä tässä laadun tarkastuksen kohdassa <br />\nRikkomusten prosenttiosuus: Rikkomusten prosenttiosuus = rikkomusten kokonaismäärä / rikkomusten kokonaismäärä",
          agent:
            "<Div> Lajittele agenttien keskiarvo <br />\nAsiakaspalvelun nimi: asiakaspalvelun nimi <br />\nAsiakaspalveluryhmä: asiakaspalveluryhmä, jossa asiakaspalvelu sijaitsee (organisaation tiedot asiakaspalvelutiedoissa)<br />\nTehtyjen keskustelujen kokonaismäärä: asiakaspalvelun laatutarkastuskeskustelujen kokonaismäärä <br />\nKeskimääräinen laatutarkastuspisteet: asiamiehen keskimääräinen laatutarkastuspisteet (kokonaispisteet / keskustelujen kokonaismäärä) Älä viitsi.",
        },
        tips: {
          total:
            "Laatutarkastustehtäviin suoritettujen kulkulumien/keskustelujen kokonaismäärä",
          agentTotal:
            "Laaduntarkastustehtävään osallistuneiden laatutarkastustehtävien kokonaismäärä",
          avg: "Laaduntarkastuspisteiden keskimääräinen pistemäärä",
          violation:
            "Yhteenlaskettujen kertojen lukumäärä kertojen lukumäärä, joka on käytetty vuoropuhelun laatutarkastuksessa.",
          compliance:
            "Yhteenlaskettujen kertojen määrä laatutarkastuksissa käydään vuoropuhelun avulla.",
          check: "Käsin näytteenottojen kokonaismäärä",
          appeal: "Keskustelujen kokonaismäärä",
          review:
            "Esitettyjen katsausten kokonaismäärä viestintäkeskustelujen kokonaismäärä",
        },
        subTips: {
          violation:
            "Rikkomisen määrä: rikkomusten kokonaismäärä / laaduntarkastuskohteiden kokonaismäärä * 100%",
          compliance:
            "Vaatimustenmukaisuus: vaatimusten noudattamisajan kokonaismäärä / laaduntarkastusten kokonaismäärä * 100 %",
          check:
            "Näytteenotto: manuaalinen näytteenotto / kokonaislaattatarkastus * 100%",
          appeal:
            "Muutoksenhaku: valitusten kokonaismäärä / laatutarkastusten kokonaismäärä * 100 %",
          review:
            "Tarkasteluaste: tarkastusten kokonaismäärä / laatutarkastusten kokonaismäärä * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Järjestelmän asetukset",
          systemName: "Järjestelmän nimi",
          speakerRecognize: "Kaiuttimen tunnukset",
          massage: "Viestin ilmoitus",
          evaluate: "Keinotekoinen kognitiivinen arviointi",
          evaluateTip:
            "Avaamisen jälkeen valitse pikatarkastus laatutarkastuksen yksityiskohdista ja nopea manuaalinen arviointi",
          speakerRecognizeTip:
            "Käytä kolmannen osapuolen puhelun tallentamista API:n kautta, on tarpeen tunnistaa, onko vasen kanava asiakaspalvelu vai asiakas.",
          speakerRecognizeTip2:
            "Yhden kanavan tallentaminen käyttää tekstin tunnistusta ja roolin kokoonpanoa määritellään välineen roolin.",
          enabledImSendStatusFilter: "Tekstin laatutarkastus viestien suodatus",
          enabledImSendStatusFilterTips:
            "Operaattorin puheen havaitsemisen sisältö laatutarkastuksessa ei sisällä viestejä tilassa [Noutettu] d] ja [Viestin lukematon]",
          lang: "Järjestelmän kielen asetukset",
          zh: "Yksinkertaistettu kiinalainen",
          en: "English",
          tw: "Perinteinen kiinalainen",
        },
        template: {
          largeModelSettings: "Suuret mallin asetukset",
          indonesian: "Indonesian",
          vietnamese: "Vietnamia",
          portuguese: "Portugali",
          malay: "Malai",
          french: "Ranskan",
          spanish: "Espanja",
          japanese: "Japani",
          thai: "Thai",
          blockSystemSendingMessages: "Mask-järjestelmän lähettämä viesti",
          textDataDesensitization: "Tekstitiedon desensitisointi",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Kun kytkin on päällä, näytetään senssitointikentän pudotuslista (monivalinta on tuettu). Henkilön nimi, matkapuhelinnumero ja osoite.",
          desensitizingField: "Herätetty kenttä",
          name: "Henkilön nimi",
          cellphoneNumber: "Matkapuhelinnumero",
          address: "Osoite",
          dataCleaning: "Tietojen puhdistus",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Asetuksen jälkeen - laatutarkastuskeskustelu ja yrityksen WeChat-istuntotiedot puhdistetaan säännöllisesti eikä niitä voi palauttaa. Toimikaa huolellisesti.",
          newCleaningRules: "Uusi puhdistussäännö",
          qualityInspectionRuleApproval:
            "Laaduntarkastussääntöjen hyväksyminen",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Avaamisen jälkeen laatutarkastussäännön muutos on hyväksyttävä ennen kuin se tulee voimaan.",
          maskOfflineMessageUnread: "Mask offline-viesti lukemattomat",
          privacyNumberProtection: "Yksityisyyden numeron suoja",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            "Avaamisen jälkeen",
          shieldingWithdrawn: "Naamio on poistettu.",
          maskSendingFailed: "Maskin lähettäminen epäonnistui",
        },
        component: {
          conversation: "Puhelu",
          dialogue: "Dialogi",
          weCom: "Enterprise We chatt",
          day: "Päivä.",
          week: "Viikko",
          month: "Kuukaus",
          year: "Vuosi:",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Puhdistuksen soveltamisala",
            dataCleaningTimeSetting: "Tietojen puhdistusajan asetukset",
            afterCleanUpTheData: "Tietojen puhdistamisen jälkeen",
            dataAfterComplaintCompletion: "Tiedot jälkeen",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Onnistunut suoritus",
          saveSuccessful: "Tallenna onnistuneesti",
          speechRecognition: "Puheen tunnistaminen",
          reExecuteErrorCorrection: "Uudelleen tehtävä virheiden korjaus",
          confirmExecution: "Vahvista suoritus",
          voiceDetection: "Äänen havaitseminen",
          noiseDetectionPowerLevel: "Melun havaitsemisen teho Taso ≤",
          pleaseEnterTheThreshold: "Anna kynnysarvo",
          volumeDetectionPowerLevel: "Volyymin havaitsemisen teho Taso ≥",
          reset: "Nollaa",
          preserve: "Tallenna",
        },
        coachTemplate: {
          french: "Ranskan",
          spanish: "Espanja",
          japanese: "Japani",
          thai: "Thai",
          loginPopupWindow: "Kirjautumisen ponnahdusikkuna",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Avaamisen jälkeen näytetään ponnahdusikkuna älykäs sparrausjärjestelmä.",
          title: "Otsikko",
          text: "Teksti",
          checkBoxContent: "Valitse ruutun sisältö",
          informedConsentForm: "Tietoinen suostumuslomake",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Jotta voit parantaa viestintä- ja puhetaitojasi sekä ammattitaidon soveltamista, Voit harjoitella milloin tahansa ja missä tahansa ja saada reaaliaikaisen palautteen, keräämme sinulta seuraavat tiedot tämän alustan kautta:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Olen lukenut ja ymmärtänyt täysin tämän tiedotettu suostumuslomakkeen ja suostun juhlallisesti käsittelemään arkaluonteisia henkilötietojani mukaisesti. Tässä tiedotetun suostumuslomakkeessa esitetyt tarkoitukset",
        },
      },
      systemIntegration: {
        title: "Järjestelmän asetukset",
        ccIntegrationTips:
          "Intelligent Quality Inspection Docking Udesk Call Center Kuvaus Dokumentti",
        unauthorized: "Luvato",
        authorized: "Sallittu",
        udeskDomain: "Asiakaspalvelujärjestelmän verkkotunnus",
        udeskToken: "Agentti- järjestelmän avain",
        udeskAdminEmail: "Asiakaspalvelujärjestelmän ylläpitäjän tili",
        password: "Ylläpitäjän tilin salasana:",
        applyAuth: "Valtuus",
        cancelAuth: "Peru hyväksyntä",
        reApplyAuth: "Uudelleen hyväksyminen",
        keyTips:
          "Asiakaspalvelujärjestelmän ja API-tunnistusmerkin taustahallinta yhdellä merkinnällä",
      },
      customerField: {
        title: "Asiakaskenttä",
        table: {
          propName: "Kenttä nimi",
          propDesc: "Kentän kuvaus",
          valueType: "Kenttätyyppi",
          propType: "Luomismenetelmä",
          createTime: "Luomisaikaan",
          canNotEdit: "Järjestelmäkentät eivät tue muokkaamista",
        },
        edit: "Muokkaa kenttää",
        new: "Uusi kentä",
      },
      wechatDocking: {
        title: "Yrityksen mikro telakoitus",
        formLabels: {
          companyId: "Yrityksen tunnukset",
          communicationKey: "Viestintä-avaini",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "Tapahtuma-osoite:",
          applicationName: "Sovelluksen nimi",
          applicationId: "Web-sovelluksen tunnukset",
          applicationSecret: "Salaisuuden soveltaminen",
        },
        formTitle: {
          initialForm: "Alkuperäinen asetukset",
          synchronizeForm: "Osoitekirjan synkronointi",
          settingForm: "Sovelluksen asetukset",
        },
        qrCodeUrlTip:
          "Pyydä ylläpitäjää skannaamaan alla olevan kaksiulotteisen koodin sitoaksesi kehityssovelluksen.",
      },
      taobao: {
        formLabels: {
          name: "Tallenna nimi",
          account: "Tilin numero",
          password: "Salasana:",
          confirm: "Vahvista salasanaa",
          authorization: "Valtuus",
          switchOn: "Avaa",
          switchOff: "SuljeComment",
        },
        tableLabels: {
          name: "Tallenna nimi",
          account: "Tilin numero",
          authorization: "Valtuus",
          updateUser: "Äskettäin päivitetty henkilö.",
          updateTime: "Viimeisin päivitysaika",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Weifengin toissijainen verkkotunnus",
          secretKeyTip:
            "Vuokralaisen salainen avain saadaan Weifengin taustajärjestelmän telakointipaikasta.",
        },
      },
      user: {
        index: {
          name: "Työntekijöiden johto",
          existingUsers: "Työntekijöitä on jo.",
          deleteSuccess: "Poistettiin onnistuneesti",
          deleteFailed: "Poista epäonnistui",
        },
        detail: {
          accountPrompt: "Anna postilaatikko.",
          resetPassword: "Nollaa salasanaa",
          passwordPrompt: "Salasana (6-14 kirjaimien ja numeroiden yhdistelmä)",
          originPasswordPrompt: "Anna alkuperäinen salasana.",
          resumePasswordPrompt: "Syötä salasanasi uudelleen.",
          passwordFormWrong: "Syötä 6-14 kirjaimen ja numeron yhdistelmä!",
          resumePasswordWrong: "Kaksi salasanaa ovat epäjohdonmukaiset!",
          suppertImageType: "Tuki jpg, gif, png, maksimi 500Kb",
          namePrompt: "Anna nimi",
          nickNamePrompt: "Anna nimitys",
          telephonePrompt: "Syötä puhelinnumero",
          roleSelectedPrompt: "Valitse rooli",
          agentIdPrompt: "Anna työnumero",
          disabled: "Poista käytöstä",
          enabled: "Ota käyttöön",
          reloadBowerInfo:
            "Käyttäjätiedot muuttuvat, tarvitsee päivittää selainta?",
          saveUserError:
            "Käyttäjätietoja ei voitu tallentaa, yritä myöhemmin uudelleen",
          resetPasswordSuccess: "Salasanan nollaus onnistui",
          resetPasswordFailed:
            "Salasanan nollaus epäonnistui, yritä myöhemmin uudelleen",
        },
      },
      userGroups: {
        index: {
          name: "Työntekijäryhmän johto",
          existingUserGroups: "Olemassa oleva työntekijäryhmä",
        },
        detail: {},
      },
      role: {
        index: {
          name: "Roolihallinta",
          existingRoles: "Olemassa olevat roolet",
        },
        edit: {
          editRole: "Muokkaa rooli",
          createRole: "Luo rooli",
          basicInfo: "Perustiedot",
          taskPermissions: "Tehtävän oikeudet",
          existingTasks: "Jo tehtävät",
        },
      },
      anomalousData: {
        title: "Epänormaali tieto",
        list: {
          selectHolder: "Valitse tietolähde",
          businessTime: "Työaika",
          businessId: "Yrityksen tunniste",
          export: "Vienti",
        },
      },
      templateComponent: {
        list: {
          title: "Mallimarkkinot",
          name: "Mallin nimi",
          searchHolder: "Anna kyseltävä sisältö",
          update: "Päivitä",
          sort: "Lajittelu",
          moduleType: "Mallin tyyppi",
          moduleSourceType: "Sovellettava tyyppi",
          fetchNum: "Hankittujen kertojen lukumäärä",
          newest: "Äskettäin päivitetty",
          usedMost: "Hanki eniten.",
          call: "Puhelu",
          dialogue: "Dialogi",
          waiting: "Tarkistetaan uudelleen",
          cancel: "Poista julkaisu",
          success: "Hyväksytty ja julkaistut",
          failed: "Tarkastuksen hylkääminen",
          no: "Ei päivitettävissä",
          ok: "Voi päivittää",
          all: "Kaikki",
          delete: "Poistettiin onnistuneesti",
          downOk: "Poistettiin onnistuneesti",
          withdraw: "Eroaminen onnistui",
          updateOk: "Päivitys onnistuneesti",
          get: "Mene pois.",
          width: "Lopettaminen",
          getOk: "Hankinnan menestys",
          orgName: "Organisaatio",
          nowVersion: "Nykyinen versio",
          lastedVersion: "Viimeisin versio",
          appStatus: "Sovelluksen tila",
          actions: "Toiminto",
          down: "Hyllyltä.",
          updateTop: "Päivittää",
          check: "Näytä",
          deleteWhen: "Vahvistaa poiston?",
          yes: "Kyllä.",
          noo: "Ei ei",
          deleteOk: "Poistaa",
          permissible: "Sallitut",
          noPermissible: "Ei sallittu",
          openUp: "Avaa",
          openDown: "Ei avaa",
          task: "Laaduntarkastustehtävät",
          point: "Laaduntarkastuspisteet",
          tag: "Semanttinen merkintä",
          muti: "Useita elementtejä",
          inter: "Yksi kysymys ja yksi vastaus",
          info: "Tietoyksikkö",
          concept: "Käsitteet sanat",
          detail: "Modulin yksityiskohdat",
          infomation: "Perustiedot",
          detailConfig: "Yksityiskohtaiset asetukset",
          twoEdit: "Toissijainen muokkaus",
          mustUpdate: "Päivitä nyt",
          descition: "Mallin kuvaus",
          descitionSize: "Mallin kuvaus ei voi ylittää 250 sanaa",
          stencilsDetail: "Mallin tiedot",
          updatedRecords: "Päivitä tietue",
          version: "Versionumero",
          updateDescription: "Päivitä kuvaus",
          myModule: "Pohjani",
          addModule: "Uusi moduul",
          publishVersion: "Julkaisuversio",
          earliestVersion: "Varhaisin versio",
          edit: "Muokkaa",
          add: "Uusi:",
          configNo: "Modulin asetustiedot eivät voi olla tyhjiä",
          updateNo: "Päivitä kuvausta ei voi olla tyhjä",
          editSuccess: "Muutos onnistui",
          saveSuccess: "Tallenna onnistuneesti",
          newer: "Päivitä",
          sumbit: "Toimittaminen",
          cancelName: "Peruus",
          nameRequired: "Moduulin nimi on pakollinen kenttä",
          nameSize: "Modulin nimi ei voi ylittää 16 merkkiä",
          publishStatus: "Julkaise tila",
          submitUpdate: "Lähetä päivitys",
          updateVersion: "Päivitä versio",
          updateExplain: "Päivitä ohjeet",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazonin sähköinen kaupankäynti",
        youtubeVideo: "Youtube-video",
        amazon: {
          component: {
            queuing: "Jonoi",
            notStarted: "Ei aloitettu",
            inProgress: "Käynnissä olevat toimet",
            success: "Onnistui",
            failed: "Epäonnistui",
            successfullyCreated: "Luotiin onnistuneesti",
            editedSuccessfully: "Muokattu onnistuneesti",
            operationSucceeded: "Toiminta onnistui",
            deletedSuccessfully: "Poistettiin onnistuneesti",
            link: "Linkkiä",
            grabState: "Tartu tilaa",
            updateTime: "Päivitysaika",
            grab: "Tartu kiinni.",
            edit: "Muokkaa",
          },
          template: {
            amazonEcommerce: "Amazonin sähköinen kaupankäynti",
            thisProduct: "Tämä tuote",
            competition: "Kilpailu",
            new: "Uusi:",
            edit: "Muokkaa",
            link: "Linkkiä",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube-video",
            thisProduct: "Tämä tuote",
            competition: "Kilpailu",
            new: "Uusi:",
            edit: "Muokkaa",
            link: "Linkkiä",
          },
          component: {
            queuing: "Jonoi",
            notStarted: "Ei aloitettu",
            inProgress: "Käynnissä olevat toimet",
            success: "Onnistui",
            failed: "Epäonnistui",
            successfullyCreated: "Luotiin onnistuneesti",
            editedSuccessfully: "Muokattu onnistuneesti",
            operationSucceeded: "Toiminta onnistui",
            deletedSuccessfully: "Poistettiin onnistuneesti",
            youTubeVideoLink: "YouTube videolinkki",
            grabState: "Tartu tilaa",
            updateTime: "Päivitysaika",
            grab: "Tartu kiinni.",
            edit: "Muokkaa",
          },
        },
      },
    },
    demo: {
      title: "Älykäs laatutarkastus",
      template: "Valitse laatutarkastusmalli",
      radio: "Valitse nauhoituslomake",
      uploadAudio: "Lataa nauhoitus",
      uploadAudioTip:
        "Ladattu tallennus tukee vain wav ja mp3 muodot, ja koko ei ylitä 1G",
      uploadText: "Lähetä tekstiä",
      uploadTextTip:
        "Ladattu teksti tukee vain srt- ja txt-formaatteja, ja koko ei ylitä 10M. Tekstin lataamisen jälkeen ASR ei enää käytetä tallennustiedostoa.",
      uploadSuccess: "Tallennustiedosto lähetettiin onnistuneesti",
      uploadFailed: "Tallennustiedoston lähettäminen epäonnistui",
      startQualityInspection: "Aloita laatutarkastus",
      qualityInspectionStarted: "Käynnissä oleva laatutarkastus",
      downloadText: "Tekstin lataus",
      checking: "Laaduntarkastuksen edistyminen",
      checkingTip: "Olkaa kärsivällisiä.",
      noCheckContent: "Laaduntarkastuksen tuloksia ei ole vielä",
      noTextDownload: "Laatutarkastustekstiä ei voi ladata!",
      pleaseCheckParams:
        "Tarkista, valitaanko mallin, äänityyppi ja lähetetäänkö tallennustiedosto?",
      inspectionFailed:
        "Laatutarkastus epäonnistui, yritä myöhemmin uudelleen!",
    },
    wechatQa: {
      baseInfo: { title: "Perustiedot" },
      conversationSetting: {
        title: "Istunnon asetukset",
        segmentationStrategy: "Leikkausstrategia",
        rule: "Strategia",
        time: "Leikkausaika",
        daily: "Joka päivä",
        passScore: "Istunnon tukikelpoinen pisteet",
        ruleLogic: "Suorituslogia",
      },
      message: {
        title: "Työnnä tapahtumia",
        table: {
          index: "Sarjanumero",
          name: "Nimi",
          type: "Työntötilas",
          desc: "Kuvaus",
          status: "Tilanne",
        },
        subTitle: "Tapahtumaluettelo",
      },
      checkPoint: {
        title: "Analyysimalli",
        groups: "Malli ryhmitys",
        newClassification: "Uusi ryhmä",
        editClassification: "Muokkaa ryhmittymistä",
        benchmarkScore: "Vertailuarvot",
        pointCount: "Yhteinen analyysimalli nykyisessä luokituksessa",
        openPointCount: "Käyttäjien määrä on:",
        closedPointCount: "Deaktivoitu määrä",
        scoreLowerLimit: "Vähimmäispisteiden alempi raja-arvo",
        scoreUpperLimit: "Suurin pistemäärän korko",
        classificationName: "Ryhmän nimi",
        classificationNamePlaceHolder: "Anna ryhmän nimi",
        classificationRemark: "Ryhmän kuvaus",
        classificationRemarkPlaceHolder: "Anna ryhmäkuvaus",
      },
      chartRecord: {
        title: "Enterprise We chat-istunto",
        table: {
          id: "Istunnon tunnukset",
          type: "Istunnon tyyppi",
          startTime: "Istunnon aloitusaika",
          endTime: "Istunto Viimeinen päättymisaika",
          groupId: "Ryhmätunnus:",
          client: "Asiakasta",
          customer: "Asiakaspalvelut",
          averageScore: "Keskimääräinen laatutarkastuspisteet",
        },
        detail: {
          title: "Enterprise We chat- istunnon yksityiskohdat",
          inspectionInfo: "Analyysitiedot",
          chartInfo: "Istunnon tiedot",
          tasks: "Analyysitehtävä.",
          avgScore: "Analyysin keskimääräinen pisteet",
          qualified: "Pätevä",
          unqualified: "Pätee",
          taskTitle: "Sovellettava analyysimalli",
          info: "Tietojen reaaliaikainen analyysi",
          today: "Päivän uutiset",
          history: "Historiallisia viestejä",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Anna kommentti/nimi.",
                pleaseEnterTheGroupChatName: "Anna ryhmäkeskustelun nimi",
              },
            },
          },
          template: { whole: "Kaikki" },
        },
      },
      realTimeAnalysis: {
        title: "Reaaliaikainen analyysi",
        table: {
          name: "Kohtauksen nimi",
          count: "Analyysimallien lukumäärä",
          baseScore: "Vertailuarvot",
          passScore: "Pätevä pisteet",
          maxScore: "Suurin pistemäärän korko",
          minScore: "Vähimmäispisteiden alempi raja-arvo",
          status: "Tilanne",
          actions: "Toiminto",
        },
        detail: {
          title: "Skenaarioiden reaaliaikainen analysointi",
          form: {
            name: "Kohtauksen nimi",
            prevCondition: "Skenaarion käynnistymisehdot",
            prevConditionToolTip:
              "Jos ehto on tyhjä, skenaario analysoidaan oletuksena",
            prevConditionError:
              "Skenaarion laukaisuolosuhteet eivät saa yhdistää analyysimallien kanssa",
            scoreInputPlaceholder: "Syötä pisteet",
            baseScore: "Vertailuarvot",
            status: "Tilanne",
            passScore: "Pätevä pisteet",
            maxScore: "Enimmäispisteiden ylärajat",
            minScore: "Vähimmäispisteiden alarajan",
            analysisModel: "Analyysimalli",
            message: "Laukaiseva ilmoitus",
            pushType: "Työntötilas",
            createAnalysisModel: "Lisää malli",
            scoreUpperLimitError:
              "Vähimmäispistemäärän alaraja ei voi olla suurempi kuin enimmäispistemäärän yläraja.",
            table: { name: "Mallin nimi", rule: "Säännöt", score: "Pisteet" },
            pushConfigSwitchTip1: "Kun kohtaus ilmestyy",
            pushConfigSwitchTip2: "Ajat ja enemmän, työnnäilmoitukset",
          },
        },
      },
      smartCustomerTag: {
        title: "Älykäs asiakasmerkintä",
        table: {
          name: "Säännön nimi",
          tags: "Yrityksen mikroasiakasmerkintä",
          status: "Käytössä oleva tila",
          createUser: "Luoja",
          createTime: "Luomisaikaan",
        },
        detail: {
          name: "Säännön nimi",
          condition: "Aseta säännöt",
          logic: "Logiikka",
          status: "Käytössä oleva tila",
          tags: "Valitse nimi",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Asiakasmerkitty määrä" },
        },
        messageFiltering: {
          template: {
            edit: "Muokkaa",
            messageContent: "Viestin sisältö",
            messageTypeID: "Viestin tyyppi ID",
            messageTypeDescription: "Viestin tyyppi Kuvaus",
            messageFiltering: "Viestin suodatus",
            suitableForFilteringDataWithoutQualityInspection:
              "Soveltuu tietojen suodattamiseen ilman laatutarkastusta",
            create: "Luo",
            messageDescription: "Viestin kuvaus",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Puheaikaan",
          sessionType: "Istunnon tyyppi",
          whole: "Kaikki",
          staff: "Työntekijä",
          customer: "Asiakasta",
          callKeywords: "Avainsanat",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails: "Yrityksen mikroäänipuhelutiedot",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Istuntojärjestelmä",
        voiceCall: "Äänipuhelu",
        noMoreVoiceCalls: "Ei enää puheluja.",
        enterpriseWechatVoiceCall: "Enterprise WeChat-äänipuhelu",
        enterpriseWechatConversationDetails:
          "Enterprise We chat- istunnon yksityiskohdat",
        previous: "Edellinen artikkeli",
        next: "Seuraava artikkeli",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Yritystoiminnan mikroistunto-analyysit",
          enterpriseAndMicroVoiceAnalysisTask:
            "Enterprise mikroäänen analysointi",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Enterprise WeChat Groupin analyysi",
      },
      wechatQaSettingEntry: { messageFiltering: "Viestin suodatus" },
    },
    businessAnalysis: {
      keyword: "Raportin nimi",
      createUser: "Luoja",
      createTime: "Luomisaikaan",
      updateTime: "Päivitysaika",
    },
    semanticIntelligence: {
      applicationMessageType: "Sovelluksen viestin tyyppi",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Ole hyvä ja täydellinen kokoonpano.",
              generatePromptLanguage: "Nopea luotu",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Vaatimustenmukaisuus",
              gradedEvaluation: "Palkkaluokan arviointi",
            },
          },
          training: {
            index: {
              pleaseEnter: "Syötä",
              pleaseGeneratePrompt: "Luo nopea",
              pleaseSelectADialogue: "Valitse ikkuna",
              training: "Koulutus",
              dataType: "Tietotyyppi:",
              voiceCall: "Äänipuhelu",
              textDialogue: "Tekstiikkuna",
              generate: "Luo",
              qualityInspectionResults: "Laatutarkastuksen tulokset:",
              hit: "Osuma:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Mallin nimi",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Syötä esimerkiksi mallin nimi, onko asiamiehellä toimintaa.",
            modelDescription: "Mallin kuvaus",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Voit kuvata yksityiskohtaisesti, mitä sinun on testattava, mikä on helppoa mallin ymmärtää. Esimerkiksi asiakaspalvelu ei toimi, eli asiakaspalvelu on vastannut asiakkaan kysymykseen, mutta asiakkaan kysymystä ei ole käsitelty tai vastauksen sisältö ei liity asiakkaan vastaukseen.",
            validity: "Tehokkuus",
            effective: "Tehokas",
            invalid: "Virheellinen",
            type: "Tyyppi",
            hintLanguage: "Kysy",
            editIntelligentModel: "Muokkaa älykästä mallista",
            createANewIntelligentModel: "Uusi älykäs malli",
          },
        },
        index: {
          modelName: "Mallin nimi",
          modelDescription: "Mallin kuvaus",
          type: "Tyyppi",
          whetherItMeetsTheRequirements: "Vaatimustenmukaisuus",
          gradedEvaluation: "Palkkaluokan arviointi",
          validity: "Tehokkuus",
          intelligentModel: "Älykäs malli",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Sovelletaan subjektiiviseen laatutarkastukseen (esimerkiksi se, onko laatutarkastuspalvelu asenne innostunut ja onko se, palvelu on potilas)",
        },
      },
      clusterAnalysis: "Klusterin analyysi",
      speechMining: "Sanat kaivostoiminta",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Voit tuoda tietoyksiköitä erissä API-käyttöliittymän kautta. Kun API-nimi on tallennettu, sitä ei voi muokata.",
        },
        component: { nothing: "Ei ole." },
      },
      knowledgeBase: {
        index: {
          classification: "Luokittelu",
          import: "Tuo",
          importKnowledgeBase: "Tuo tietopohja",
          moveKnowledgePointsInBatch: "Erän siirtäminen tietopisteet",
          moveTo: "Siirrä:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Valitse erissä siirrettävät osaamispisteet",
        },
        detail: {
          index: {
            intrasentence: "Virkkeen sisällä",
            afterProblem: "Ongelman jälkeen",
          },
        },
      },
      semanticTags: { component: { nothing: "Ei ole." } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Herkkä sanat",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Herkkä sanoja: herkät sanat voidaan määrittää yrityksen huolehtimiseen sanoilla, joita käytetään yleisen mielipiteen löytämiseksi herkkää sanaa pilviä",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Uusi herkkä sana.",
            editSensitiveWords: "Muokkaa herkkiä sanoja",
            sensitiveWords: "Herkkä sanat",
          },
          template: {
            sensitiveWords: "Herkkä sanat",
            sensitiveWordsCannotBeEmpty: "Herkkä sana ei voi olla tyhjä",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Anna herkkä sana, useita arkaluonteisia sanoja keskellä ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "Valitse ohjelmisto",
            keyWord: "Asiasanat",
            pleaseEnterKeywords: "Anna avainsana.",
            keywordLengthWithinCharacters: "Avainsana pituus 40 merkkin",
            dataSources: "Tietolähde",
            pleaseSelectADataSource: "Valitse tietolähde",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Nykyinen ryhmä on saatu päätökseen. Uudelleen muokkaaminen tuottaa uuden kappaleen ryhmittymistä tietoa eikä korvaa valmiita klustering dataa. Vahvistakaa.",
            savingSucceeded: "Tallenna onnistuneesti",
            clusteringStart: "Ryhmittyminen alkaa",
            clustering: "Klusteri",
            edit: "Muokkaa",
            newlyBuild: "Uusi:",
            clusterName: "Ryhmän nimi",
            pleaseEnterAName: "Anna nimi",
            withinCharactersOfTheName: "Nimen pituus 40 merkin sisällä",
            speaker: "Kaiutin",
            pleaseSelectASpeaker: "Valitse kaiutin",
            sessionDate: "Istunnon päivämäärä",
            pleaseEnterTheSessionDate: "Anna istunnon päivämäärä",
            selectUpToMonthOfHistoricalSessionData:
              "Valitse enintään 1 kuukausi historiallisia istuntotietoja",
            automaticallyClusterDailyNewData:
              "Päivittäisten uusien tietojen automaattinen klusteri",
            conversationChannel: "Keskustelun kanava",
            pleaseSelectAConversationChannel: "Valitse istunnon kanava",
            applicationScenario: "Sovellusskenaariot",
            clusteringRules: "Ryhmäsäännöt",
            incompleteClusteringRuleSettings:
              "Ryhmittymissääntöjen asetus on epätäydellinen",
            startClustering: "Aloita ryhmät",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask: "Valitse laatutehtävä.",
              beEqualTo: "Yhtäläinen",
            },
          },
        },
        detail: {
          index: {
            theme: "Teema",
            numberOfCorpora: "Yhdysvaltojen lukumäärä",
            corpusCoverage: "Yhdistynyt kuningaskunta",
            clusterDetails: "Yksityiskohtaiset tiedot",
          },
          ranking: {
            hotWords: "Kuumat sanat",
            numberOfCorpora: "Yhdysvaltojen lukumäärä",
            speaker: "Kaiutin",
            sit: "Istu.",
            passenger: "Vieras.",
            hotSentence: "Kuuma lause.",
            corpusDetails: "Corpus-tiedot",
          },
        },
        index: {
          delete: "Poistaa",
          deletionSucceeded: "Poistettiin onnistuneesti",
          clusterName: "Ryhmän nimi",
          speaker: "Kaiutin",
          creationTime: "Luomisaikaan",
          state: "Tilanne",
          operation: "Toiminto",
          see: "Näytä",
          edit: "Muokkaa",
          clusterAnalysis: "Klusterin analyysi",
          newCluster: "Uusi klusteri",
          clusteringStatus: "Ryhmittymisen tila",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Syötä avainsanoja vastaväitteiden hakemiseksi",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Puhekirjasto",
                recommendedScripts: "Suositeltuja sanat",
                common: "Yhteensä",
                strip: "Artikla",
                scriptFavorites: "Sanat suosikkit",
              },
              conditionPostfix: {
                detectRoles: "Tunnista rooli",
                detectionRange: "Tunnistusalue",
                sentence: "Tuomio",
              },
              customerFilter: {
                scriptFiltering: "Puheen suodatus",
                cancel: "Peruus",
              },
              index: {
                viewDetails: "Näytä tiedot",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Haluatko varmasti hylätä sen? Et voi palauttaa sitä hylättyään.",
                abandonment: "Hävitä",
                deletionSucceeded: "Poistettiin onnistuneesti",
                passenger: "Vieras.",
                sit: "Istu.",
                adopted: "Hyväksytty",
                determine: "Hyvä on.",
                cancel: "Peruus",
                adopt: "Hyväksyminen",
              },
              pageRightFrom: {
                savingSucceeded: "Tallenna onnistuneesti",
                pleaseEnterTheScriptContent: "Anna puheen sisältö",
                pleaseSelectAFavoriteGroup: "Valitse suosikki ryhmitys",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Vastalauseiden käsittely puhekirjasta",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "Ole hyvä ja valitse puhe.",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Vastalause käsittely sanoja Suosikkia",
            },
            pageRightTable: {
              likeCancel: "Kuten peruttu",
              likeSuccessfully: "Pidän menestyksestä.",
              adoptScripts: "Hyväksy puheen",
              operation: "Toiminto",
              viewConversationDetails: "Näytä keskustelun yksityiskohtia",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Tuotteiden myyntipiste Talk-kirjasto",
              },
              pageRightTable: { keyPointsOfScript: "Puhekohteita" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Vastalause käsittely sanoja Suosikkia",
              newGroup: "Uusi ryhmä",
              editGroup: "Muokkaa ryhmittymistä",
              addGroup: "Lisää ryhmät",
              savingSucceeded: "Tallenna onnistuneesti",
              groupName: "Ryhmän nimi",
              pleaseEnterTheGroupName: "Anna ryhmän nimi",
              groupNameWithinCharacters:
                "Ryhmän nimen pituus 20 merkin sisällä",
              deletionSucceeded: "Poistettiin onnistuneesti",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings: "Suositellut puheasetukset",
                },
                rightForm: {
                  savingSucceeded: "Tallenna onnistuneesti",
                  intelligentRecommendation: "Älykäs suositus",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Hyväksytty erinomainen puhetaidot näkyvät automaattisesti sivupaljassa istuntojen samanlaisia vastalauseita, ja työntekijät voivat suoraan katsella suositeltuja puhetaitoja oppimiseksi istunnon jatkaa yksityiskohtia sivulla.",
                  objectionsIncludedByDefault: "Vastalause sisältyy oletuksena",
                  addRecommendationTriggerSemantics:
                    "Lisää suositus käynnistää semantiikkaa",
                  remindEmployeesToView: "Muistuta työntekijöitä katsomaan",
                  shareWithMoreEmployees: "Jaa lisää työntekijöitä.",
                  allHands: "Koko henkilöstö",
                },
                smartToolbar: { selectSemanticTag: "Valitse semanttinen nimi" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Vie kaikki tiedostot nykyisessä ryhmässä?",
                newScript: "Uusi puheName",
                editingScript: "Muokkaa puhetta",
                savingSucceeded: "Tallenna onnistuneesti",
                objection: "Vastalause",
                pleaseEnterAScriptObjection: "Syötä puhevasto",
                speechTechnique: "Sanat",
                pleaseEnterTheScriptContent: "Anna puheen sisältö",
                grouping: "Ryhmittäminen",
                pleaseSelectAFavoriteGroup: "Valitse suosikki ryhmitys",
                adoptScripts: "Hyväksy puheen",
                passenger: "Vieras.",
                sit: "Istu.",
                numberOfViews: "Näkymien määrä",
                useful: "Hyödyllinen",
                operation: "Toiminto",
                edit: "Muokkaa",
                delete: "Poistaa",
                deletionSucceeded: "Poistettiin onnistuneesti",
                scriptRecommendationSettings: "Suositellut puheasetukset",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Puhekirjasto",
          scriptMining: "Sanat kaivostoiminta",
          objectionHandlingScripts: "Vastalauseiden käsittely",
          productSellingPointScripts: "Tuotteiden myyntipisteet",
        },
        sellingPoint: {
          index: { newCluster: "Uusi klusteri" },
          components: {
            callDetailModal: {
              sessionDetails: "Istunnon yksityiskohdat",
              cancel: "Peruus",
              determine: "Hyvä on.",
              choice: "Valita",
            },
            pageCenterTable: { scriptOverview: "Puhekatsaus" },
            pageLeftTable: {
              enterSearchKeywords: "Anna hakusanat",
              keyWord: "Asiasanat",
            },
            pageRightTable: {
              index: {
                viewDetails: "Näytä tiedot",
                scriptLibrary: "Puhekirjasto",
                scriptRefinement: "Puhejalostus",
                adoptScript: "Hyväksy puheen",
              },
              tagsEdit: { keyPointsOfScript: "Puhekohteita" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Tuotteiden myyntipiste Talk-kirjasto",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Erityisesti sisällytetyt myyntipisteet",
                  },
                },
                index: {
                  keyWord: "Asiasanat",
                  pleaseSelectAKeyword: "Valitse avainsana.",
                  keyPointsOfScript: "Puhekohteita",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Kirjoittakaa keskeiset puhekohdat",
                  viewDetails: "Näytä tiedot",
                  pleaseChooseToAdoptScript: "Ole hyvä ja valitse puhe.",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Täsmäytys logiikka",
            nLPMatching: "NLP-ottelua",
            strictlyMatchByWord: "Tiukka vastaus sanalta",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Viestin tyypin nimi",
          messageTypeID: "Viestin tyyppi ID",
          messageTypeDescription: "Viestin tyyppi Kuvaus",
          applicationMessageType: "Sovelluksen viestin tyyppi",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Sitä sovelletaan eri kanavista saataviin tietoihin, jotka olisi luokiteltava kanavan tunnistamisen mukaan.",
        },
        detail: {
          index: {
            messageTypeName: "Viestin tyypin nimi",
            pleaseEnterAMessageTypeName: "Anna viestin tyyppi nimi",
            messageTypeDescription: "Viestin tyyppi Kuvaus",
            describeYourMessageTypeInDetail:
              "Kuvaa viestityyppisi yksityiskohdissa",
            editMessageType: "Muokkaa viestintyyppiä",
            newMessageType: "Uusi viestityyppi",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Valitse tiimin jäsen.",
        existingFilter: "Suodattimia on jo.",
        pleaseSelect: "Valitse:",
        filterCondition: "Suodatusolosuhteet",
        areYouSureToDeleteTheCurrentFilter:
          "Haluatko varmasti poistaa nykyisen suodin?",
        sessionDate: "Istunnon päivämäärä",
        staff: "Työntekijä",
        customer: "Asiakasta",
        pleaseEnterCustomerName: "Anna asiakkaan nimi",
        keyWord: "Asiasanat",
        whole: "Kaikki",
        pleaseEnterSessionKeywords: "Anna istunnon avainsanat",
        sessionDuration: "Istunnon kesto",
        sessionLabel: "Istunnon skenaariot",
        pleaseSelectASessionLabel: "Valitse istuntoskenairi",
        commentTopic: "Kommentin aiheet",
        pleaseSelectACommentTopic: "Valitse kommenttiaiheen",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Haluatko varmasti korvata nykyisen suodin?",
        overlayFilter: "Kannesuodatin",
        NewFilter: "Uusi suodine",
        newFilter: "Uusi suodine",
        filterName: "Suodin nimi",
        pleaseEnter: "Syötä",
        allCall: "Kaikki puhelut",
        percentage: "Prosenttiosuus",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "Voit etsiä useita avainsanoja. Käytä pilkkoja (englanniksi ja kiinaksi) erottaa OR ja tauko erottaa ja OR. Esimerkiksi:",
          callRecordID: "Kutsulokin ID",
          pleaseFillInTheCallRecordID: "Täytä puhelutiedot ID",
          minute: "Pöytäkirja",
          second: "Sekunteja",
          whole: "Kaikki",
          callRecording: "Puhelujen tallentaminen",
          enterpriseWechat: "Enterprise We chatt",
          conversationChannel: "Keskustelun kanava",
          pleaseSelectASessionChannel: "Valitse istunnon kanava",
          learningStatus: "Oppimistila",
          pleaseSelectLearningStatus: "Valitse oppiminen tila",
          notLearned: "Ei opittu",
          learned: "Opetut",
          Minutes: "Pöytäkirja",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Suodatin on tyhjä, uusi suodatin ei tueta!",
          filterAddedSuccessfully: "Suodin lisätty onnistuneesti!",
          failedToAddFilter: "Suodin lisääminen epäonnistui!",
          filterDeletedSuccessfully: "Suodin poistettiin onnistuneesti!",
          filterDeletionFailed: "Suodatin poisto epäonnistui!",
          filterOverwriteSucceeded: "Suodatin ohitus onnistui!",
          filterOverwriteFailed: "Suodatin ohitus epäonnistui!",
          theme: "Teema",
          pleaseSelectATheme: "Valitse teema",
          keyEvents: "Avaintapahtumat",
          pleaseSelectAKeyEvent: "Valitse avaintaha",
        },
        filterItem: {
          startRetesting: "Aloita uudelleen havaitseminen",
          time: "Aika",
          talkTime: "Kutsun kesto",
          keyEvents: "Avaintapahtumat",
          totalImplementationRateOfSupervisionItems:
            "Valvonta-aineiden yleinen täytäntöönpanoaste",
          sort: "Lajittelu:",
          seeMore: "Näytä lisää",
        },
        filterStatistics: {
          conversation: "Puhelu",
          weChat: "Wechat",
          quantity: "Määrä",
        },
      },
      caseBase: {
        index: {
          copyTo: "Kopioi",
          pleaseSelectTheLocationToMove: "Valitse siirrettävä sijainti!",
          folderCopiedSuccessfully: "Kansion kopiointi onnistui!",
          cancelCollectionSuccessfully: "Peru kokoelma onnistuneesti!",
          collectionSucceeded: "Kokoelma menestys!",
          starCaseBase: "Tähden kirjasto",
          move: "Siirrä",
          rename: "Nimeä uudelleen",
          delete: "Poistaa",
          folderMovedSuccessfully: "Kansio siirrettiin onnistuneesti!",
          fileMovedSuccessfully: "Tiedosto siirrettiin onnistuneesti!",
          fileMoveFailed: "Tiedosto siirto epäonnistui!",
          folderAddedSuccessfully: "Lisää kansio onnistuneesti!",
          folderRenameSucceeded: "Kansion nimi onnistuneesti nimetty!",
          fileRenameSucceeded: "Tiedosto nimettiin onnistuneesti!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Kansion poistamisen jälkeen et voi hakea sitä. Poistatko sen varmasti?",
          deleteSucceeded: "Poistettiin onnistuneesti!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Kun tiedosto on poistettu, et voi hakea sitä. Poistatko sen varmasti?",
          share: "Yhteensä",
          folders: "Kansio,",
          calls: "Puhelu",
          newFolder: "Uusi kansio",
          modifyFolder: "Muokkaa kansiota",
          title: "Otsikko",
          pleaseEnter: "Syötä",
          selectMoveLocation: "Valitse siirrä sijainti",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Oppimisen muistutus",
          subscriptionDynamics: "Tilaus Dynamics",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "Tilaamasi istunto (ID)",
            thereAreDynamicUpdates: "Dynaaminen päivitys",
            newCommentsReplies: "Lisää kommentteja/vastauksia",
            strip: "Artikla",
            newHelp: "Lisää ohje",
            goAndWatch: "Mene katsomaan.",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Vastaa huomautuksiini.",
            replySucceeded: "Vastaus onnistunut!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Minä kommentteja.",
            replySucceeded: "Vastaus onnistunut!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Puhelu sinulle jakaa",
            shareCasesForYou: "Jaan jutun puolestasi.",
          },
        },
        study: {
          index: {
            learned: "Opetut",
            ignored: "Älä huomioi",
            study: "Oppiminen",
            ignore: "Älä huomioi",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Tuonti onnistui",
          account: "Tilin numero",
          password: "Salasana:",
          cover: "Kansit",
          skip: "Ohita",
          import: "Tuo",
          importEmployees: "Tuontityöntekijä",
          importPrompt: "Tuo vinkkeet",
          confirm: "Hyvä on.",
          theDataContainsDuplicateAccountsOrNames:
            "Tässä tietotietossa on kaksinkertainen tili tai nimi",
          allCoverage: "Suojaa kaikki.",
          skipAll: "Ohita kaikki",
          headPortrait: "Avatar",
          fullName: "Nimi",
          nickname: "Lempinimi",
          excellentStaff: "Erinomainen henkilöstö",
          yes: "Kyllä.",
          no: "Ei ei",
          department: "Osasto",
          accountNumberemail: "Tilinumero (sähköposti)",
          telephone: "Puhelin",
          jobNumber: "Työn",
          role: "Rooli",
          pleaseEnterANameOrPhoneSearch:
            "Anna sähköposti, nimi tai puhelinhaku",
          person: "Potilaat)",
          AddEmployee: "Lisää työntekijä",
        },
        edit: {
          jobNo: "Työn",
          udeskCustomerServiceSystemIdentity:
            "Udeskin asiakaspalvelujärjestelmän identitet",
          enterpriseWechatIdentity: "Enterprise We chat- identitet",
          inputID: "Syöttötiedot",
          newIdentityBinding: "Uusi henkilöllisyyden sidos",
          identityBinding: "Identiteettisitys",
          avatarGreaterThan500kb: "Avatari suurempi kuin 500Kb",
          uploadSuccessful: "Lähetä onnistuneen",
          upload: "Lähetä",
          supportJPGGIFAndPNGUpTo500kb: "Tuki jpg, gif, png, maksimi 500Kb",
          yes: "Kyllä.",
          no: "Ei ei",
          accountNumber: "Tilin numero",
          pleaseEnterTheAccountNumber: "Syötä tilinumero",
          initialPassword: "Alkuperäinen salasana:",
          pleaseInputAPassword: "Anna salasana.",
          confirmPassword: "Vahvista salasanaa",
          thePasswordsEnteredTwiceAreDifferent:
            "Kahdesti syötetty salasana ei ole sama!",
          headPortrait: "Avatar",
          fullName: "Nimi",
          pleaseEnterYourName: "Anna nimi",
          nickname: "Lempinimi",
          department: "Osasto",
          excellentStaff: "Erinomainen henkilöstö",
          sessionIdentity: "Istunnon henkilöllisyys",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Tunniste istuntorekisteri kuuluu työntekijän",
          telephone: "Puhelin",
          jobNumber: "Työn",
          role: "Rooli",
          pleaseSelectARole: "Valitse rooli",
          preservation: "Tallenna",
          cancel: "Peruus",
          addedSuccessfully: "Lisätty onnistuneesti!",
          modificationSucceeded: "Muokattu onnistuneesti!",
          newEmployees: "Uusi työntekijä",
          editEmployee: "Muokkaa työntekijää",
        },
        detail: {
          viewDetails: "Näytä tiedot",
          accountNumber: "Tilin numero",
          headPortrait: "Avatar",
          fullName: "Nimi",
          nickname: "Lempinimi",
          department: "Osasto",
          excellentStaff: "Erinomainen henkilöstö",
          yes: "Kyllä.",
          no: "Ei ei",
          sessionIdentity: "Istunnon henkilöllisyys",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Tunniste istuntorekisteri kuuluu työntekijän",
          telephone: "Puhelin",
          jobNumber: "Työn",
          role: "Rooli",
        },
      },
      roleManage: {
        list: {
          menu: "Valikko",
          functionalCategory: "Toiminnalliset luokat",
          functionalSubclass: "Funktion alaluokka",
          deletedSuccessfully: "Poistettiin onnistuneesti",
          NewRole: "Uusi rooli",
          role: "Rooli",
          dataPermissions: "Tietojen oikeudet:",
          edit: "Muokkaa",
          areYouSureYouWantToDeleteTheRole: "OK poistaa roolia",
          determine: "Hyvä on.",
          cancel: "Peruus",
          delete: "Poistaa",
          applicationPermission: "Käytä oikeudet",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData: "Näet vain omat istuntotiedot",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Voit katsella osaston ja alaosastojen istuntotiedot",
          menu: "Valikko",
          functionalCategory: "Toiminnalliset luokat",
          functionalSubclass: "Funktion alaluokka",
          successfullyAdded: "Uusi menestys",
          modifiedSuccessfully: "Muutos onnistui",
          edit: "Muokkaa",
          cancel: "Peruus",
          confirm: "Vahvista",
          roleName: "Roolin nimi",
          pleaseEnterTheRoleName: "Anna roolinimi",
          dataAuthority: "Tietojen oikeudet",
          youCanViewCompanyWideSessionData:
            "Voi nähdä yrityksen laajuisia istuntotietoja",
          applicationPermission: "Käytä oikeudet",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Istuntoprosessi",
          generateProcess: "Generointiprosessi",
          addProcess: "Lisää prosessi",
          cancel: "Peruus",
          complete: "Valmistus",
          edit: "Muokkaa",
          theSettingWillBeSuccessfulLater: "Aseta menestykseen myöhemmin!",
          previous: "Edellinen artikkeli",
          next: "Seuraava artikkeli",
          debuggingMode: "Vianetsintätila",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Kun viesti on aktivoitu, jos se vastaa tärkeimpien tapahtumien, seuranta- ja asiakasmerkkien sääntöjä, tai tietämyspisteet, sen sääntön nimi, johon viesti vastaa, sekä tietopohjan tietämyspisteet.",
          seeLater: "Katso myöhemmin.",
          determine: "Hyvä on.",
          conversationalInsights: "Istunnon oivallukset",
          customerInsight: "Asiakastiedot",
          knowledgePointRecommendation: "Tietopisteen suositus",
          comment: "Huomautukset",
          sessionQuality: "Istunnon laatu",
          setToListenLaterSuccessfully:
            "Aseta kuuntelemaan menestystä myöhemmin!",
          listenLater: "Kuuntele myöhemmin.",
          seekCounselingSuccess: "Etsi valmennus menestystä!",
          altogether: "A yhteensä",
          appearsAt: "Ilmestyi.",
          commentsPublishedSuccessfully: "Kommentit onnistuneesti",
          callRecording: "Puhelujen tallentaminen",
          addedSuccessfully: "Lisätty onnistuneesti",
          shareWithColleagues: "Jaa kollegojen kanssa",
          shareWithCustomers: "Jaa asiakkaiden kanssa",
          resumptionDetails: "Tarjouksen tiedot",
          addToCaseBase: "Lisää kirjastoon",
          shareCases: "Tapausten jakaminen",
          seekCounseling: "Hae neuvonta",
          sessionRecord: "Istunnon tietue",
          businessRecord: "Liiketoimintaa koskevat tiedot",
          keywordSearch: "Avainsana haku",
          whole: "Kaikki",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Anna kommentti, kirjoita @ ilmoittaaksesi toisille, kirjoita # lisätäksesi säikeä",
          subscriptionSession: "Tilaa istunto",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Tilauksen jälkeen, kun on uutta apua, kommentteja ja vastauksia, saat ilmoituksia ja muistutuksia.",
        },
      },
      coachTask: {
        index: {
          employeeName: "Työntekijän nimi",
          customerName: "Asiakkaan nimi",
          source: "Lähde",
          viewSessionRecords: "Näytä istuntotietoja",
          coachingStatus: "Valmennuksen tila",
          helpTime: "Apun aika.",
          counselingTime: "Neuvonta-aika",
          helpContent: "Ohjeen sisältö",
          coachingOperation: "Valmennustoiminta",
          areYouSureYouWantToIgnoreIt:
            "Haluatko varmasti jättää sen huomiotta?",
          determine: "Hyvä on.",
          cancel: "Peruus",
          ignoreHelp: "Älä huomioi ohje",
          coaching: "Valmennukset",
          viewCoaching: "Näytä valmennus",
          areYouSureToReturnToCounseling: "Palaatko varmasti terapiaan?",
          returnToPendingCoaching: "Jatka neuvontaan",
          tutor: "Neuvonantaja",
          helpSeekingStatus: "Ohjeen tila",
          recentCoachingTime: "Viimeaikainen neuvontaan",
          operation: "Toiminto",
          ignoreSuccess: "Älä huomioi menestystä!",
          recoverySucceeded: "Toipuminen onnistunut!",
          successfulCoaching: "Menestys!",
          myMission: "Tehtäväni.",
          selectCoachingStatus: "Valitse valmennuksen tila",
          whole: "Kaikki",
          iAskedForHelp: "Pyysin apua.",
          signOut: "Poistu...",
          submit: "Toimittaminen",
          helpPerson: "Pyydä apua",
          customer: "Asiakasta",
          toBeCoached: "Ohjattavaksi.",
          counselingSuggestions: "Neuvonnan neuvonta",
          pleaseEnterCoachingSuggestions: "Syötä valmennusehdotukset",
        },
      },
      coachTaskCenter: {
        title: "Tehtäväkeskus",
        list: {
          taskName: "Tehtävän nimi",
          lessonGroup: "Kurssin luokittelu",
          lessonName: "Kursi",
          taskType: "Tehtävätyyppi",
          taskStatus: "Tehtävän tila",
          taskExecutionInfo: "Täytäntöönpanon asema",
          totalCount: "Teloitettavien henkilöiden lukumäärä",
          executeCount: "Toteutettujen määrä",
          executeRate: "Täytäntöönpanoaste",
          completeCount: "Valmistettujen määrä",
          completeRate: "Täytäntöönpanoaste",
          commitCount: "Saavutuksen lukumäärä",
          commitRate: "Vaatimustenmukaisuus",
          excellentRate: "Erinomainen korko",
          taskTime: "Tehtävän aika",
          option: "Toiminto",
          edit: "Muokkaa",
          taskDetail: "Tehtävätiedot",
          delete: "Poistaa",
          new: "Uusi julkaisu",
          taskTypeAll: "Kaikki",
          taskTypePractice: "Liikunta",
          taskTypeTest: "Tarkastelu",
          taskStatusNotStarted: "Ei käynnistynyt",
          taskStatusRunning: "Käynnissä olevat toimet",
          taskStatusEnd: "Päättynyt",
          taskStatusInvalid: "Ei voimassa olla",
          unlimitedTime: "Rajoittamaton aikaa",
          to: "Suuntaan",
          deleteConfirm:
            "Poistamisen jälkeen kaikki tämän tehtävän kohdalla olevat oppimisen tallenteet poistetaan. Poistatko ne varmasti?",
          deleteSuccess: "Tehtävä poistettiin onnistuneesti",
        },
        edit: {
          addTask: "Uusi julkaistu",
          editTask: "Muokkaa julkaista",
          publishSuccess: "Julkaistu onnistuneesti",
          publishFailed: "Julkaisu epäonnistui",
          updateSuccess: "Päivitys onnistui",
          updateFailed: "Päivitys epäonnistui",
          cannotBeNull: "Ei voi olla tyhjä",
          explain: "Kuvaus",
          taskDescExtra: "(Tämä näytetään {0} sisäänkäynnissä)",
          taskTargetObj: "Julkaistaan objektit",
          isEnable: "Tehokas asema",
          dialogueMode: "Ikkunan tila",
          voice: "Ääni",
          text: "Teksti",
          userPortrait: "Käyttäjän muotokuva",
          difficultyLevel: "Vaikeus",
          limitDuration: "Kestorajat",
          tipLabel: "Vinkkeet",
          tipTooltip: "Salli osallistujien nähdä viitepuheen",
          limitTimes: "Kokeiden lukumäärä",
          time: "Aika",
          gradingStandart: "Pisteysperusteet",
          gradingStandartDescAffix: "( Nykyinen keskusteluprosessi on jaettu",
          gradingStandartDescSuffix:
            "Pisteet, puhetaito {0} pistettä, mieliala {1} pistettä, nopeus {2} pistettä)",
          completeStandart: "Tehtävän suorittamista koskevat vaatimukset",
          byTimes: "Määrä kertaa",
          byScore: "Pisteittain",
          completeByTimes: "Harjoitusten lukumäärä",
          times: "Ajani",
          score: "Pisteet",
          anyTimes: "Mikä tahansa kertaa",
          getScore: "Pisteet",
          offLimitDuration: "Rajoittamaton kesto",
          onLimitDuration: "Rajallinen aikapituus",
          commitScore: "Vakio, pisteet",
          excellentScore: "Erinomainen standardi, pisteet",
          offLimitTime: "Rajoittamaton aikaa",
          onLimitTime: "Rajoitettu aikaa",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Terve." },
          callDynamic: {
            today: "Tänään",
            yesterday: "Eilen.",
            sessions: "Istunto",
          },
          todoList: {
            strip: "Artikla",
            theSessionIsAddedTolistenLater:
              'Istunto liitetään "Kuunnelkaa myöhemmin"',
            askYouForHelp: "Pyytää apua",
            iHopeToContactYouLater: "Ota yhteyttä myöhemmin.",
            lookingForwardToSendingTheInformationLater:
              "Odota lähettäväsi aineistoa myöhemmin myöhemmin.",
            dialogueRisk: "Keskustelussa on riskipisteitä",
            tutored: "Valmennettu",
            goToCounseling: "Mene terapiaan.",
            contacted: "Yhteystiedot",
            hasBeenSent: "Lähetetty",
            areYouSureYouWantToIgnoreIt:
              "Haluatko varmasti jättää sen huomiotta?",
            determine: "Hyvä on.",
            cancel: "Peruus",
            ignore: "Älä huomioi",
            checked: "Katsottu",
          },
          callListItem: {
            strip: "Artikla",
            learned: "Opetut",
            keyEvents: "Avaintapahtumat",
            totalImplementationRateOfSupervisionItems:
              "Valvonta-aineiden yleinen täytäntöönpanoaste",
          },
        },
        index: {
          operationSucceeded: "Operaatio onnistui!",
          sessionDynamics: "Istunnon dynamiikka",
          my: "Minun.",
          department: "Osasto",
          company: "Yritys",
          toDoList: "Toiminta",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Tehokkaan puhelun määrä (yksikkö)",
          bestPractices: "Parhaat käytännöt",
          averageDurationOfASingleCallminutes:
            "Keskimääräinen yhden puhelun kesto (minuuttia)",
          totalCallVolumePCs: "Kutsut yhteensä (yksikkö)",
          totalTalkTimehours: "Puhelun kokonaiskesto (tunnit)",
          speakingPercentagepercentage: "Puheprosentti (prosentti)",
          maximumContinuousSpeakingTimeminutes:
            "Suurin jatkuva puheaika (minuuttia)",
          numberOfIndepthDiscussionsPCs:
            "Perusteellisten keskustelujen lukumäärä (yksikkö)",
          maximumCustomerTalkminutes: "Asiakkaan enimmäispuhe (minuuttia)",
          questionFrequencyPCs: "Kysymysten taajuus (yksikkö)",
          averageValue: "Keskimäärä",
          selectDate: "Valitse päivämäärä",
          personnel: "Henkilöstö",
          pleaseSelect: "Valitse:",
          callRange: "Kutsu-alue",
          conversationActivity: "Istunnon toiminta",
          conversationAction: "Istunnon toiminta",
          keyEvents: "Avaintapahtumat",
          callRecord: "Puhelujen tallentaminen",
          filter: "Suodin:",
          conversation: "Puhelu",
          minute: "Pöytäkirja",
          sale: "Työntekijä",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Tammikuu",
            numberOfCalls: "Puhelujen lukumäärä",
            accountInformation: "Tilitiedot",
            month: "Kuukaus",
            today: "Tänään",
            customerDetails: "Asiakastiedot",
            searchKeywordsInCommunicationContent:
              "Hae avainsanoja viestinnän sisällöstä",
            allMembers: "Kaikki jäsenet",
            reset: "Nollaa",
            query: "Kysely",
            allTrip: "Koko matkan näyttä",
            contactOverview: "Yhteystiedot",
            lastContact: "Viimeinen yhteystiedot",
            nextContact: "Seuraava yhteystieto",
            previous: "Edellinen artikkeli",
            next: "Seuraava artikkeli",
            viewDetails: "Näytä tiedot",
            contactDynamics: "Yhteystiedot",
            lastYear: "Viime vuonna",
            lastCall: "Viimeinen puhelu",
            hotTopicsForDiscussion: "Kuuma keskustelua",
            totalInTheLastYear: "Viime vuonna kokonaan.",
            callTimes: "Toissijainen puhelu",
          },
        },
        list: {
          index: {
            risk: "Riskit",
            have: "Niitä on.",
            nothing: "Ei ole.",
            channel: "Kanava",
            callRecording: "Puhelujen tallentaminen",
            enterpriseWeChatConversation: "Yritystoiminnan mikroistunnot",
            enterpriseWeChatCall: "Yritysten mikrokäsittely",
            followUpSuggestions: "Seurantasuositukset",
            customer: "Asiakaskeskus",
            followUp: "Seuranta",
            personInCharge: "Vastuuhenkilö",
            recentFollowup: "Se on äskettäin seurannut.",
            lastContactTime: "Viimeinen yhteystiedot aika",
            operation: "Toiminto",
            see: "Näytä",
            exceed: "Lisää.",
            noActivityForMoreThanDays: "Päivät ilman toimintaa",
            lately: "Viime aikoina:",
            risksOccurredInTheLastDays:
              "Riskit ilmaantuvat muutaman päivän kuluessa",
            savedSuccessfully: "Tallenna onnistuneesti",
            today: "Tänään",
            allDepartments: "Kaikki osastot",
            allMembers: "Kaikki jäsenet",
            customerName: "Asiakkaan nimi",
            reset: "Nollaa",
            query: "Kysely",
            customers: "Asiakasta",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Näytä yhteyden työntekijöiden ja asiakkaiden välillä, jossa vaaleanpunaiset kuplat ovat asiakasviestejä ja siniset kuplat ovat työntekijöiden viestejä. Mitä suurempi puheet ovat, sitä suuremmat kuplat.",
            riskAllocation: "Riskin määrittely",
            preservation: "Tallenna",
            pleaseEnter: "Syötä",
            noActivityForDays: "Päivät ilman toimintaa",
            riskSemanticsWithinDays:
              "Riskit ilmaantuvat muutaman päivän kuluessa",
            addRiskSemantics: "Lisätään riski semantiikkaa",
            semanticTags: "Semanttiset merkinnät:",
            standardSentence: "Vakiolause:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "Lisää uusi tila" } },
              },
              index: {
                meetAllOfTheFollowingConditions: "Täytä kaikki seuraavat ehdot",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Suodatin",
                pleaseSelectFilteringCriteria: "Valitse suodatinkriteerit",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Muuta mahdollisuuksien vaihta",
              theCurrentStageIs: "Nykyinen vaihe on C",
              ConfirmToChangeItTo: "), Vahvistaa muuttaa sen ec",
              IsIt: "- Mitä?",
              StageCoStay: "] Vaihe kokonaiskäynni",
              day: "Päivä.",
              start: "Käynnistä",
              end: "LoppuComment",
              orderInput: "Järjestyksen menettäminen",
              restart: "Käynnistäkää uudestaan",
              pleaseConfigureTheSalesStageInformation:
                "Aseta myyntivaiheen tiedot",
              reasonForDocumentInput: "Syy menettää tilauksen:",
              completeOrderInput: "Tohtori menettää täysin.",
              pleaseSelectTheReason: "Valitse syy",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "Johto suosittelee:" },
              index: {
                sessionDetails: "Istunnon yksityiskohdat",
                customerDynamics: "Asiakasdynamiikka",
                riskPoint: "Riskipiste",
                followUpSuggestions: "Seurantasuositukset",
              },
              sessionDetails: {
                common: "Yhteensä",
                currentlyViewingThe: "Tällä hetkellä näkymä nro.",
                sessions: "Istunto",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Nykyinen vaihe on pysähtynyt",
              day: "Päivä.",
            },
          },
          business: {
            index: { reEnableOpportunity: "Mahdollisuuksien uudelleen" },
          },
          customerTag: { index: { label: "Merkinti" } },
          moreCustomer: {
            index: {
              essentialInformation: "Perustiedot",
              cancel: "Peruus",
              confirm: "Vahvista",
              customer: "Asiakasta",
              currentFollowupStage: "Nykyinen seurantavaihe:",
              telephone: "Puhelin",
              followUpDays: "Seurantapäivät",
              day: "Päivä.",
              remarks: "Huomautukset",
              channelPreference: "Kanavan asetukset",
              hello: "Haloo.",
              customFields: "Omat",
              opportunityAmount: "Mahdollisuuksien määrä",
              focus: "Huolet",
              customerInformation: "Asiakastiedot",
              viewMoreCustomerInformation: "Katso lisää asiakastietoja",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Keskeiset tapahtumat:" },
                row1: {
                  customerDynamics: "Asiakasdynamiikka",
                  common: "Yhteensä",
                  barDynamic: "Dynaaminen",
                },
                row2: {
                  riskPoint: "Riskipiste",
                  common: "Yhteensä",
                  risks: "Artikla riski",
                },
                row3: {
                  followUpSuggestions: "Seurantasuositukset",
                  intelligentRecommendation: "Älykäs suositus",
                  strip: "Artikla",
                  managersSuggestATotalOf: "Johtaja suosittelee yhteensä",
                  recommendedByManagers: "Johtaja suosittelee",
                  creationTime: "Luomisaikaan",
                  administratorSuggestions: "Valvojan neuvot",
                  suggestionsForNewManagers: "Uuden johtajan neuvonta",
                },
              },
            },
            index: { typeTime: "Tyyppi/aika" },
          },
          userInfo: {
            index: {
              personInCharge: "Vastuuhenkilö",
              recentFollower: "Se on äskettäin seurannut.",
              startTime: "Ensimmäinen ulottuvuus",
              followUpDays: "Seurantapäivät",
              day: "Päivä.",
              remarks: "Huomautukset",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Ryhmän jäsenet",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Jäsenten muilta oppimien istuntojen kokonaismäärä Istunnon yksityiskohtien etsiminen ja napsauttaminen toistamiseen pidetään oppimisen kerran.",
          totalNumberOfStudies: "Oppimisen kokonaismäärä",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Jäsenten muiden istunnoihin aloittamien huomautusten ja vastausten kokonaismäärä",
          totalNumberOfInteractions: "Yhteisvaikutusten kokonaismäärä",
          learnTheSumOfConversationCallDuration:
            "Opetetun istunnon keston yhteenlasku",
          lengthOfStudy: "Oppimisen kesto",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Jäsenten avunpyyntöjen kokonaismäärä",
          totalNumberOfRequests: "Avunpyyntöjen kokonaismäärä",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Muiden neuvontaa saavien jäsenten kokonaismäärä",
          totalCounseling: "Ohjeiden kokonaismäärä",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Asiakirjastoon lisättyjen jäsenten istuntojen kokonaismäärä (de- duplointi)",
          addCase: "Lisää kirjaimet",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Muiden henkilöiden istuntoihin liittyvien jäsenten kokonaismäärä (poistaminen)",
          subscribe: "Tilausmerkintä",
          totalNumberOfSessionsCommunicatedByMembers:
            "Jäsenten ilmoittamien istuntojen kokonaismäärä",
          totalSessions: "Istunnot yhteensä",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Istuntojaan toistavien jäsenten kokonaismäärä kerran uudelleen.",
          totalNumberOfDoubleDisk: "Yhteensä",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Riskipisteiden kokonaismäärä / istuntojen kokonaismäärä * 100%",
          proportionOfDoubleDisk: "Jäljellä oleva osuus",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Kaksoislevyn keskustelun keston summa.",
          resumptionDuration: "Toistamisen kesto",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Niiden jäsenten kokonaismäärä, jotka aloittivat apua omiin istuntoihinsa varten (poistettu kaksinkertainen)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Jäsenen istunnossa saadut huomautukset (mukaan lukien vastaukset) yhteensä",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Muiden tapauskirjastoon lisättyjen jäsenten istuntojen kokonaismäärä",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Muiden tilaamien jäsenten kokonaismäärä",
          askForHelpInTheLastMonth: "Viime kuussa apua.",
          lastTimeForHelp: "Viimeinen kerta pyytää apua.",
          operation: "Toiminto",
          viewAllSessionsInTheLastMonth:
            "Näytä kaikki viimeisen kuukauden istunnot",
          allDepartments: "Kaikki osastot",
          reset: "Nollaa",
          query: "Kysely",
          altogether: "A yhteensä",
          secondSession: "Toissijainen istunnos",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Nykyinen testiprojekti:",
            supervisionItem: "Valvonta-aineet",
            keyEvents: "Avaintapahtumat",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Tarkista tarkkailukohteet tai avaintapahtumat, jotka haluat testata ja varmista, että simuloidussa keskustelussa on puhesisältöä",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Suoritusanalyysi" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Prosessi 1",
              technologicalProcess: "Prosessi",
              newProcess: "Uusi prosessi",
            },
          },
          index: {
            upload: "Lähetä",
            preservation: "Tallenna",
            newlyBuild: "Uusi:",
            cancel: "Peruus",
            name: "Nimi",
            describe: "Kuvaus",
            type: "Tyyppi",
            classification: "Luokittelu",
            content: "Sisältö",
            enclosure: "Liite",
            knowledgePointAssociation: "Tietopisteyhdistykset",
            open: "Avaa",
            close: "SuljeComment",
            operator: "Toiminnanharjoittaja",
            logic: "Logiikka",
            see: "Näytä",
            edit: "Muokkaa",
          },
        },
        index: {
          name: "Nimi",
          describe: "Kuvaus",
          createdBy: "Luoja",
          type: "Tyyppi",
          lastUpdated: "Viimeisin päivitysaika",
          numberOfReferences: "Viittausten lukumäärä",
          operation: "Toiminto",
          see: "Näytä",
          edit: "Muokkaa",
          delete: "Poistaa",
          categoryDeletedSuccessfully: "Luokka poistettiin onnistuneesti",
          classificationOfKnowledgePoints: "Tietämyspisteiden luokittelu",
          newlyBuild: "Uusi:",
          knowledgeBase: "Tietopohja",
          knowledgePoints: "Tietämyspisteet",
          technologicalProcess: "Prosessi",
          keyword: "Avainsa",
          batchExport: "Erä Vie",
          batchDelete: "Erän poista",
          importProcess: "Tuonti",
          importKnowledgePoints: "Tuo tietopisteet",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Jos osaamispistettä ei ole olemassa tietopohjassa, lisätään tietämyspiste suoraan; jos se on olemassa, Jätä se ohi äläkä päivitä sitä.",
          editKnowledgePointClassification:
            "Muokkaa osaamispisteiden luokitusta",
          newKnowledgePointClassification: "Uusi tietopiste luokittelu",
          categoryEditingSucceeded: "Luokan muokkaus menestys",
          newClassificationSucceeded: "Uusi luokka onnistuneesti",
          classificationName: "Luokan nimi",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Joukkojen valvonta" },
      },
      topicCallout: {
        index: {
          whole: "Kaikki",
          date: "Päivämäärä",
          dimensionStatus: "Kutsumisen tila",
          callRecordID: "Kutsulokin ID",
          callRecordIDMustBeNumeric: "Kutsulokin ID:n täytyy olla numero.",
          strip: "Artikla",
          topicAnnotation: "Aihekohtainen kutsuus",
          annotated: "Merkinnöt",
          stripNotMarked: "Bar, merkitsemättö",
          barInDimension: "Kalkit, kutsuun",
          customer: "Asiakasta",
          sale: "Myynti",
          common: "Yhteensä",
          sessions: "Istunnot",
          currentTopic: "Nykyinen aihe:",
          staff: "Työntekijä",
          topicOfConversation: "Aiheet",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Toiminta onnistui, nousu nollatus",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Kun palautetaan, kaikki tämän istunnon merkintöjä koskevat tiedot poistetaan. Vahvistakaa.",
          resetAll: "Nollaa kaikki",
          pleaseSelectTheTopicToBeMarked: "Valitse aihe, jolle nimeään",
          pleaseTickTheSentenceToBeMarked: "Tarkista merkittävä virke.",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Toiminta onnistui, merkki on tullut voimaan.",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Tämän merkinnän laajuuteen sisältyy viestejä muista aiheista. Napsauta OK korvataksesi ne.",
          confirmDimensions: "Vahvista kutsuminen",
          talkTime: "Puheaikaan",
          callID: "Kutsutunnukset",
        },
      },
      topicList: {
        index: {
          callID: "Kutsutunnukset",
          latestMarkedTime: "Viimeisin merkintäaika",
          topicOfConversation: "Aiheet",
          numberOfSentences: "Virkeiden lukumäärä",
          sentence: "Tuomio",
          operation: "Toiminto",
          see: "Näytä",
          delete: "Poistaa",
          edit: "Muokkaa",
          newSubTopic: "Uusi aliaiheet",
          move: "Siirrä",
          whole: "Kaikki",
          topicDeletedSuccessfully: "Aihe poistettiin onnistuneesti",
          newlyBuild: "Uusi:",
          topicList: "Aiheluettelo",
          callIDMustBeNumeric: "Kutsutunnuksen täytyy olla numero.",
          leadInTopic: "Tuo aihe",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Jos luokan nimi on kaksoiskappale, alkuperäistä aiheluokkaa ei korvata. ja kohde ohitetaan eikä sitä tuoda.",
          mobileClassification: "Liikkuva luokitus",
          editTopic: "Muokkaa aihetta",
          newTopic: "Uusi aima",
          topicName: "Aiheen nimi",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Muutos onnistui",
            createdSuccessfully: "Luotiin onnistuneesti",
            modificationFailed: "Muutos epäonnistui",
            creationFailed: "Ei voitu luoda",
            monitoringName: "Seurannan nimi",
            upToCharacters: "Enintään 40 merkkiä",
            pleaseEnterAName: "Anna nimi",
            monitoringIndicators: "Seurantakeskus",
            sessionActivity: "Istunnon toiminta",
            conversationAction: "Istunnon toiminta",
            keyEvents: "Avaintapahtumat",
            supervisionItems: "Valvonta-aineet",
            monitoringMembers: "Valvonnan jäsenet",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Ohjausta, koulutusta tai johtamista vastaanottavat ryhmät",
            groupA: "Ryhmä A",
            pleaseSelectGroupAData: "Valitse A-ryhmän tiedot",
            thereAreDuplicateUsersInGroupB: "Kaksinkertaista ryhmän käyttäjät",
            groupARemarks: "Ryhmä A Huomautukset",
            controlGroupGroupComparedWithGroupA:
              "Vertailuryhmään verrattuna ryhmään A",
            groupB: "Ryhmä B",
            pleaseSelectGroupBData: "Valitse B-ryhmätiedot",
            thereAreDuplicateUsersInGroupA: "Ryhmässä A on kaksoiskappaleet.",
            groupBRemarks: "Ryhmä B Huomautukset",
            monitoringPeriod: "Seurantakyrki",
            startNode: "Aloitussolmi",
            monitoringScope: "Seuranta-alue",
            pleaseSelectMonitoringConditions: "Valitse seurantaolosuhteet",
            pleaseEnterANumber: "Anna numero.",
            pleaseSelectATimePeriod: "Valitse aikajakso",
            preservation: "Tallenna",
            cancel: "Peruus",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Poistettiin onnistuneesti",
            notStarted: "Ei käynnistynyt",
            haveInHand: "Käynnissä olevat toimet",
            closed: "Päättynyt",
            newGroupTest: "Uusi ryhmätesti",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Myyntivaiheet",
        communicationStrategy1: "Viestintästrategia",
        riskAllocation: "Riskin määrittely",
        businessConfiguration: "Yrityksen asetukset",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Vastalauseiden käsittely",
                  productSellingPointScript: "Tuotteiden myyntipisteet",
                  caseGrouping: "Tapauksen ryhmittyminen",
                  pleaseSelectExcellentCases: "Valitse hyvä tapaus.",
                  pleaseSelectKnowledgePoints: "Valitse tietopisteet",
                  associativeLinks: "Liitännäiset linkit",
                  jumpTo: "Hyppäys",
                  jumpType: "Hyppytyyppi",
                  knowledgePoints: "Tietämyspisteet",
                  excellentCases: "Erinomainen tapaus",
                  excellentScript: "Erinomainen puhe.",
                  pleaseSelectASingleKnowledgePoint: "Valitse yksi tietopiste.",
                },
              },
            },
            index: {
              name: "Nimi",
              pushText: "Työnnä teksti",
              enableStatus: "Käytössä oleva tila",
              triggerRules: "Liipaisin sääntö",
              communicationStrategy: "Viestintästrategia",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "Viestintästrategiaryhmä",
              name: "Nimi",
              pushText: "Työnnä teksti",
              effectiveness: "Tehokkuus",
              openSuccessfully: "Avaa onnistuneesti",
              closeSuccessfully: "Suljettu onnistuneesti",
              operationFailed: "Toiminto epäonnistui",
              operation: "Toiminto",
              edit: "Muokkaa",
              delete: "Poistaa",
              newlyBuild: "Uusi:",
              communicationStrategy: "Viestintästrategia",
              editGroup: "Muokkaa ryhmittymistä",
              groupName: "Ryhmän nimi",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Kaikki istunnot",
                  recentDays: "Viimeaikaiset päivät",
                  recentSessions: "Viimeaikaiset istunnot",
                  scopeOfApplication: "Soveltamisala:",
                  day: "Päivä.",
                  through: "Läpäissä",
                },
              },
            },
            index: {
              describe: "Kuvaus",
              applicableCustomers: "Sovelletaan asiakkain",
              enableStatus: "Käytössä oleva tila",
              triggerRules: "Liipaisin sääntö",
              riskRules: "Riskisäännöt",
            },
          },
          list: {
            index: {
              riskAllocation: "Riskin määrittely",
              keyWord: "Asiasanat",
              newRiskRule: "Uusi riskissäännös",
              riskRuleName: "Riskisäännön nimi",
              describe: "Kuvaus",
              state: "Tilanne",
              openSuccessfully: "Avaa onnistuneesti",
              closeSuccessfully: "Suljettu onnistuneesti",
              operationFailed: "Toiminto epäonnistui",
              operation: "Toiminto",
              edit: "Muokkaa",
              delete: "Poistaa",
              deletionSucceeded: "Poistettiin onnistuneesti",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Järjestyksen menettäminen" },
              endReasonList: {
                addEndReason: "Lisää loppusyy",
                enterEndReason: "Syötä loppusysi",
              },
              stageList: {
                keyEvents: "Avaintapahtumat",
                waysideData: "Tietojen seurantatiedot",
                includeAny: "Sisältää mitä tahansa.",
                includeAll: "Sisältää kaikki.",
                pleaseEnterTheStageName: "Anna vaiheen nimi",
                setStageRules: "Aseta vaiheen säännöt",
                stageName: "Vaiheen nimi",
                operator: "Toiminnanharjoittaja",
                logic: "Logiikka",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Ajan tyyppikentän syöttötietojen muoto: 2022-12-31 00:00:000",
              requestFailedPleaseTryAgainLater:
                "Pyyntö epäonnistui, yritä myöhemmin uudelleen",
              salesStage: "Myyntivaiheet",
              essentialInformation: "Perustiedot",
              name: "Nimi",
              applicableDepartments: "Sovellettava osasto",
              applicableToOthers: "Sovelletaan toisiinsa",
              manualChangePhase: "Manuaalinen vaihto",
              enableStatus: "Käytössä oleva tila",
              describe: "Kuvaus",
              opportunityStageSettings: "Mahdollisuuden vaiheen asetukset",
              stageSettings: "Vaiheasetukset",
              aliasSettings: "Alias-asetukset",
              closeOpportunity: "Lopputilaisuus",
              newSuccessfully: "Uusi menestys",
              newFailed: "Uusi epäonnistui",
              savingSucceeded: "Tallenna onnistuneesti",
              saveFailed: "Tallennus epäonnistui",
            },
          },
          list: {
            index: {
              salesStage: "Myyntivaiheet",
              keyWord: "Asiasanat",
              newSalesStage: "Uusi myyntivaihe:",
              salesStageName: "Myyntivaiheen nimi",
              describe: "Kuvaus",
              state: "Tilanne",
              openSuccessfully: "Avaa onnistuneesti",
              closeSuccessfully: "Suljettu onnistuneesti",
              operationFailed: "Toiminto epäonnistui",
              operation: "Toiminto",
              edit: "Muokkaa",
              delete: "Poistaa",
              deletionSucceeded: "Poistettiin onnistuneesti",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Suuri mallin tuottaminen",
            conversationInsights: "Istunnon oivallukset",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Automaattinen tuottaminen" },
              },
              hooks: {
                conversationSummary: "Istunnon yhteenveto",
                conversationProcess: "Istuntoprosessi",
                opportunityPoints: "Mahdollisuuspisteestä",
              },
              prompt: {
                index: {
                  edit: "Muokkaa",
                  restoreDefault: "Palauta oletusarvo",
                  cancel: "Peruus",
                  confirm: "Vahvista",
                },
              },
            },
            index: {
              saveSuccessful: "Tallenna onnistuneesti",
              hintLanguage: "Kysy",
              preserve: "Tallenna",
              cancel: "Peruus",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Suuri mallin tuottaminen",
        salesStage: "Myyntivaiheet",
        communicationStrategy: "Viestintästrategia",
        riskAllocation: "Riskin määrittely",
        businessConfiguration: "Yrityksen asetukset",
      },
      salesLearningCenter: {
        knowledgeBase: "Tietopohja",
        speechCorpus: "Puhekirjasto",
        learningCenter: "Oppimiskeskus",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "Asiakastiedot" } },
        },
        index: { otherInformation: "Muut tiedot" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Tuo" } } },
        hooks: {
          index: {
            all: "Kaikki",
            intelligentModel: "Älykäs malli",
            smartRules: "Älykkäät säännöt",
            manualScoring: "Manuaalinen pisteytys",
            enable: "Ota käyttöön",
            notEnabled: "Ei käytössä",
            voiceCall: "Äänipuhelu",
            textDialogue: "Tekstiikkuna",
            ecommerceTextSession: "Sähköinen kaupankäynti",
            workOrder: "Työskentelyjärjestys",
          },
        },
        index: {
          ruleName: "Säännön nimi",
          ruleType: "Säännötyyppi",
          pleaseSelect: "Valitse:",
          ruleStatus: "Säännön tila",
          dataSourceType: "Tietolähdetyyppi",
          voiceCall: "Äänipuhelu",
          textDialogue: "Tekstiikkuna",
          ecommerceTextSession: "Sähköinen kaupankäynti",
          workOrder: "Työskentelyjärjestys",
          status: "Tilanne",
          operation: "Toiminto",
          edit: "Muokkaa",
          ruleTemplate: "Sääntömalli",
        },
      },
      analysisReportTitle: "Analyysiraportti",
      ruleTest: "Sään testi",
      intelligentTags: {
        labelDisplayQuantity: "Merkinnäytön määrä",
        unlimited: "Rajoittamaton",
        ignoringTagLibraries: "Jätä merkkikirjasto",
        automaticallyGenerateLabels: "Luo etiketit automaattisesti",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "Kun istunto on käytössä, tunnisteet luodaan automaattisesti suuren mallin perusteella, ja syntyneet tunnisteet luokitellaan oletusarvoisesti.",
        defaultValidity: "Tehokkuus",
        supervisionItem: "Valvonta-aineet",
        customerLabel: "Asiakasmerkki",
        newSupervisionItemRule: "Uusi valvontaa koskeva sääntö",
        editSupervisionItemRule: "Muokkaa valvottuja kohtia",
        newCustomerLabel: "Uusi asiakasmerkki",
        editCustomerLabel: "Muokkaa asiakastunnisteita",
        newSessionLabel: "Uusi istunnon nimi",
        editSessionLabel: "Muokkaa istunnon nimiä",
        newKeyEvent: "Uusi avaintapaha",
        editKeyEvents: "Muokkaa avaintapahtumaa",
        detail: {
          template: { nameIsARequiredField: "Nimi on vaadittu kenttä" },
          formItemComponent: {
            largeModelExtensionLabel: "Suuri mallin laajennustunnisteet",
            sessionRecordID: "Istunnon tallennustunnukset",
            sessionTime: "Istunnon aika",
            smartTags: "Älykäs nimi",
            viewDetails: "Näytä tiedot",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Tämän istunnon tiedot poistetaan poistamisen jälkeen. Poistatko sen varmasti?",
            tagData: "Merkintätiedot",
            batchDeletion: "Erän poista",
          },
        },
        template: {
          advancedFiltering: "Edistynyt suodatus",
          dataType: "Tietotyyppi",
          filterCriteria: "Suodatin tila",
          edit: "Muokkaa",
          createANewSubcategory: "Uusi alaluokka",
          delete: "Poistaa",
          nameLengthCannotExceed: "Nimen pituus ei saa ylittää 40.",
        },
        component: {
          modelExpansion: "Mallin laajennus",
          enable: "Ota käyttöön",
          notEnabled: "Ei käytössä",
          standardScreening: "Vakiosin",
          advancedFiltering: "Edistynyt suodatus",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Asiasanat",
              sessionLabel: "Istunnon nimi",
              keyEvents: "Avaintapahtumat",
              matchAny: "Täsmää kaikkia",
              matchAll: "Täsmää kaikkiin",
              beEqualTo: "Yhtäläinen",
              includeAll: "Sisältää kaikki.",
              includeAny: "Sisältää mitä tahansa.",
              advancedConditions: "Lisäedellytykset",
              smartTag: "Älykäs nimi",
              smartTagI: "Älykäs nimi",
              smartTagII: "Älykäs merkki II",
            },
            template: {
              pleaseSelectSmartTag: "Valitse älykäs nimi",
              pleaseSelectASessionLabel: "Valitse istunnon nimi",
              pleaseSelectAKeyEvent: "Valitse avaintaha",
              pleaseSelectAdvancedCondition: "Valitse lisävaatimukset",
              newAdvancedFilter: "Uusi kehittynyt suodin",
              moveUp: "Siirry ylöspäin",
              moveDown: "Siirry alas",
              moveLeft: "Siirry vasemmalle",
              shiftRight: "Siirrä oikealle",
            },
          },
          component: {
            businessAnalysispng: "Liiketoiminnan analytics.png.",
            savedSuccessfully: "Tallenna onnistuneesti",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Pysyvästi tehokas",
            permanentlyInvalid: "Pysyvästi virheellinen",
            customValidity: "Voimassaoloaika",
            termOfValidity: "Voimassaoloaika",
            name: "Nimi",
            explain: "Kuvaus",
            state: "Tilanne",
            classification: "Luokittelu",
            smartRecommendation: "Älykäs suositus",
            preconditions: "Ehdot",
            SetPreconditionsForThisSupervisionItem:
              "1. Aseta ennakkoedellytykset tälle valvontaa varten:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Kun ennakkoedellytykset täyttyvät, se katsotaan "sovellettavaksi valvonta-alaksi", ja valvonta-ala on testattava.',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Jos ennakkoedellytyksiä ei täyty, valvontaa ei havaita;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Älä aseta ennakkoehtoja, eli kaikki istunnot havaitaan oletuksena.",
            have: "Niitä on.",
            nothing: "Ei ole.",
            preconditionRule: "Ehtoa koskeva sääntö",
            preconditionLogic: "Esikahtelulogiikka",
          },
          recommendation: {
            caseGrouping: "Tapauksen ryhmittyminen",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Kun työntekijät eivät toimi kunnolla, he voivat älykkäästi suositella erinomaisia puhetaitoja ja erinomaisia tapauksia.",
            smartRecommendation: "Älykäs suositus",
            open: "Avaa",
            close: "SuljeComment",
            whenThisSupervisionItem: "Kun tämä valvonta kohta",
            implement: "Toteutus",
            unexecuted: "Ei suoritettu",
            when: "Milloin:",
            recommendedKnowledgePoints: "Suositeltuja osaamispisteitä",
            caseRecommendation: "Tapaussuositus",
          },
        },
        index: {
          determine: "Hyvä on.",
          sort: "Lajittelu",
          sortSucceeded: "Järjestely onnistuneesti",
          supervisionItemClassification: "Valvonta- kohtien luokittelu",
          pressEnterToCreateANew: "Paina Enter Uus",
          nameCannotBeEmpty: "Nimiä ei voi olla tyhjä",
          newlyBuild: "Uusi:",
          name: "Nimi",
          explain: "Kuvaus",
          validStatus: "Kelvollinen tila",
          permanentlyValid: "Pysyvästi tehokas",
          permanentlyInvalid: "Pysyvästi virheellinen",
          operation: "Toiminto",
          edit: "Muokkaa",
          batchDelete: "Erän poista",
          newSupervisionItem: "Uusi valvonta-arvo",
          keyWord: "Asiasanat",
          delete: "Poistaa",
          deleteSucceeded: "Poistettiin onnistuneesti",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Tietoja ei voi palauttaa poistamisen jälkeen. Haluatko varmasti poistaa sen?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Älykäs nimi",
              beEqualTo: "Yhtäläinen",
              includeAll: "Sisältää kaikki.",
              includeAny: "Sisältää mitä tahansa.",
              matchAny: "Täsmää kaikkia",
              matchAll: "Täsmää kaikkiin",
              smartTagI: "Älykäs nimi",
              smartTagII: "Älykäs merkki II",
            },
            template: {
              pleaseSelectASmartTag: "Valitse älykäs nimi",
              pleaseSelectAdvancedConditions: "Valitse lisävaatimukset",
              newAdvancedFilter: "Uusi kehittynyt suodin",
              rename: "Nimeä uudelleen",
              moveUp: "Siirry ylöspäin",
              moveDown: "Siirry alas",
              shiftLeft: "Siirry vasemmalle",
              shiftRight: "Siirrä oikealle",
            },
          },
        },
        template: {
          delete: "Poistaa",
          labelFiltering: "Merkin suodatus:",
          satisfyAny: "Täyttää mielivaltainen",
          meetAll: "Tapaamme kaikki.",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Toissijaisten luokitusmerkkien lukumäärä ≥ ensisijainen luokitus, istunto voi sisältää useita merkkejä",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logiikka rengasmuutoksen laskemiseksi: (valitun ajanjakson ja vuoropuhelun tilavuus välittömästi edeltävällä ajanjaksolla) / vuoropuhelun tilavuus edellisellä ajanjaksolla",
          proportionOfPrimaryClassification:
            "Prosenttiosuus ensimmäisen tason luokituksesta",
          saveAs: "Tallenna",
          cover: "Kansit",
          preservation: "Tallenna",
          qualityInspectionTask: "Analyysitehtävät:",
          query: "Kysely",
          changeFromMonthToMonth: "Sormuksen vaihto",
          tagRanking: "Merkinnän sijoitus",
          proportion: "Tilanne prosenttiin",
        },
        component: {
          numberOfConversations: "Keskustelujen lukumäärä",
          operation: "Toiminto",
          businessAnalysispng: "Liiketoiminnan analytics.png.",
          savingSucceeded: "Tallenna onnistuneesti",
          deletionSucceeded: "Poistettiin onnistuneesti",
          sequence: "Järjestys",
          firstLevelClassification: "Tason 1 luokittelu",
          ringRatioChange: "Sormuksen vaihto",
          classClassification: "Luokittelu",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Vahvistaisiko asiakirjan ja sen poistetun sisällön poistaminen?",
            addKnowledgeBase: "Lisää tietopohja",
            uploadDocument: "Lähetä asiakirja",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Tuki sana, pdf, ppt, excel, txt-muoto tiedostot",
            taskName: "Tehtävän nimi",
            extractConversationDate: "Otetaan neuvottelupäivä.",
            pleaseSelectAConversationDate: "Valitse keskustelupäiväksi",
            dataSource: "Tietolähde",
            dataType: "Tietotyyppi",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Vienti onnistui",
              problem: "Kysymys",
              answer: "Vastaa",
              addToKnowledgeBase: "Lisää tietopohjaan",
              pleaseConfirmWhetherToDeleteIt: "Tarkista, poistetaanko se.",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Lisää tietopohjaan",
              selectClassification: "Valitse luokka",
              pleaseSelectAKnowledgeBaseClassification:
                "Valitse tietopohjan luokitus",
            },
          },
        },
        index: {
          intelligentExtraction: "Älykäs uuttaminen",
          documentExtraction: "Asiakirjan poistaminen",
          dialogueExtraction: "Valintaikkunan",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Toiminta onnistui",
          labelIgnored: "Tagi ohitettiin",
          add: "Lisää",
          areYouSureToDeleteIt: "Haluatko varmasti poistaa?",
          batchFilterLabels: "Erän suodin nimi",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Älykäs laatutarkastus",
        savedSuccessfully: "Tallenna onnistuneesti",
        logoSettings: "Logon asetukset",
        default: "Oletus",
        custom: "Oma",
        title: "Otsikko",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Järjestelmän näyttämän nimen suositellaan olevan 6 merkin sisällä.",
        logoImageUpload: "Logon kuva lähettää",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Valitse kuva, jonka koko on enintään 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Jotta varmistetaan logon selausvaikutus, on ehdotettu ladata kuvia:",
        Size5050Pixels: "1. Koko: 50*50 pikseliä;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Muoto: kuva läpinäkyvä tausta, png tai jpg, logo graafinen valkoisella",
        browserLabelIcon: "Selaimen välilehden kuvake",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Tagselauksen vaikutuksen varmistamiseksi on suositeltavaa ladata kuvia:",
        Size3232Pixels: "1. Koko: 32*32 pikseliä;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Muoto: kuva läpinäkyvä tausta, png tai jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Laatutarkastus käynnissä, odota...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "Vuonna 2015 maani kiinteistö saavutti kriittisen selviytymishetken. Suuri määrä taloja oli ilman valvontaa, ja ihmiset eivät halunneet ostaa niitä. Hangzhou laski kerran alle 8000, ja jotkut kehittäjät murskasivat markkinat alukselle. Shenzhen oli edelleen vahvistamassa noin 2w-3w tuolloin, ja talot ensimmäisen tason kaupungeissa olivat myös vaarassa.",
        xiaoAn: "Ann.",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload näitä muistiesteitä kutsutaan jsr muistiesteiksi, jotka ovat jvm tasolla. Käyttöjärjestelmän pohjassa on kaksi toteutusta: toinen on linja-auton lukitseminen, ja toinen muistieste primitiivinen, kuten sfance (kirjoituseste) mfence (täydellinen este) ifence (luku este). Pohjakerroksessa hotspots virtuaalikone käyttää kokoonpanoilmoitus: lock addl, lukko tyhjä toiminta, Se on lukkobussi. Tämä tapahtuu, koska jotkin käyttöjärjestelmät eivät tue muistiesteen alkukantaisia.",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Yritä marinoida liha useita kertoja ja paista kasvikset viinin lisäämisen jälkeen. Liha on raivoissaan ja haisee hapanta! Tästä lähtien ei enää keittoviiniä pekonia varten.",
        xiaodong: "Xiaodong.",
        xiaoxi: "Xiao Xi.",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Yritä marinoida liha useita kertoja, ja viinin lisäämisen jälkeen paistetut vihannekset ovat kuin pilaantunut liha, Haju. Tästä lähtien ei enää keittoviiniä pekonia varten.",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement:
          "Sähköisen kaupankäynnin arvioinnin hallinta",
        socialMediaReviewManagement: "Sosiaalisen median katsaus",
        customDataSource: "Oma tietolähde",
        qualityInspectionResults: "Laaduntarkastusten tulokset",
        accountManagement: "Tilinhallinta",
        employeeProfile: "Työntekijän muotokuvat",
        intelligentAnalysis: "Älykäs analyysi",
        customKanban: "Oma Kanbanin",
        dataScreening: "Tietojen yleiskatsaus",
        learningRecord: "Levyn oppiminen",
        dataAnalysis: "Tietojen analyysi",
        scoringManagement: "Pisteiden hallinta",
        businessConfiguration: "Yrityksen asetukset",
        manualReview: "Käsin uudelleentarkastelu",
        taskList: "Tehtäväluettelo",
        videoAnalysis: "Videoanalyysi",
        overview: "Yleiskatsaus",
        personalSignage: "Henkilökohtainen Kanbanin",
        learningCenter: "Oppimiskeskus",
        wrongQuestionSet: "Väärä ongelma asetettu",
        abilityImprovement: "Kyky päivittää",
        close: "SuljeComment",
        switchingSystemApplications: "Vaihdetaan järjestelmäsovelluksia",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Siirrä hiirtä tähän kutsuaksesi sovelluksen vaihtovalikon ja napsauta valikkoa hypätäksesi eri sovelluksiin.",
        teamAnalysis: "Tiimianalyysi",
        semanticIntelligence: "Semanttinen älykkyys",
        permissionManagement: "Lupahallinta",
        dataConnection: "Tiedon telakoitus",
        courseManagement: "Kurssin hallinta",
        labelManagement: "Merkinnän hallinta",
        curriculum: "Kurssikeskus",
        courseConfiguration: "Kurssin asetukset",
        task: "Tehtäväni.",
        historicalLearningData: "Historia",
        taskCenter: "Tehtävähallinta",
        knowledgeBase: "Tietopohja",
        ecommerceSessionManagement: "Sähköisen kaupankäynnin hallinta",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Sovelluksen vaihto",
          descriptionOfIntelligentQualityInspectionSystem:
            "Älykkään laatutarkastusjärjestelmän kuvaus",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Sähköinen istuntosuodatin",
        allEcommerceSessions: "Kaikki sähköisen kaupankäynnin istunnot",
        ecommerceSessionManagement: "Sähköisen kaupankäynnin hallinta",
      },
    },
    analysisEnable: {
      bestPractices: "Parhaat käytännöt",
      behaviorAnalysis: "Käyttäytymisanalyys",
      teamAnalysis: "Tiimianalyysi",
      executiveSupervision: "Joukkojen valvonta",
      executiveForceAnalysis: "Suoritusanalyysi",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Arvostelu:",
          startTime: "Aloitusaika",
          workOrderNo: "Työjärjestyksen numero",
          customerServiceHasReadNotRead: "Luettu/lukematon agentti",
          canYouAppeal: "Muutoksenhaku",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Mikrohuomautukset",
          addFriendTime: "Lisää kaverin aika",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Aloita tarkastus uudelleen",
          callID: "Kutsutunnukset",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Uusia ominaisuuksia! Napsauta 'Pieni näytteenotto Tarkista'-painiketta hyppää automaattisesti seuraavalle sivulle onnistuneen lähettämisen jälkeen ~ ~ ~ ~",
        },
      },
      template: {
        deleteRecord: "Poista tietue",
        areYouSureToDeleteIt: "Haluatko varmasti poistaa?",
        confirm: "Hyvä on.",
        reIdentification: "Uudelleen tunnistaminen",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Uudelleen tunnistaminen kuluttaa siirtoaikaa, olkaa varovaisia.",
        determine: "Hyvä on.",
        executionSuccessful: "Onnistunut suoritus",
      },
      component: {
        pleaseSelectData: "Valitse tiedot",
        operationSuccessful: "Toiminta onnistui",
        startPerformingReQualityInspection: "Aloita tarkastus uudelleen",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Lähetä tiedoston nimi",
        analysisTask: "Analyysitehtävä.",
        analyzeProgress: "Analysoi edistymistä",
        analysisSucceeded: "Onnistumisen analyysi",
        analysisFailed: "Analyysi epäonnistui",
        localDataUpload: "Paikallinen data lähettää",
        recordingUpload: "Äänen tallennuslähetys",
        textUpload: "Tekstin lähetystä",
        uploadTime: "Lähetysaika",
        taskName: "Tehtävän nimi",
        operator: "Toiminnanharjoittaja",
        qualityInspectionProgress: "Laaduntarkastuksen edistyminen",
        whole: "Kaikki",
        haveInHand: "Käynnissä olevat toimet",
        completed: "Valmistui",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Vie kaikki valmistuneet tietueet nykyisten suodinkriteerien mukaisesti?",
        upload: "Lähetä",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Täytetään nimi",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Syötä nimi, kuten tuotujen tietojen tyyppi/keruu/tarkoitus.",
            default: "Oletus",
            jDCOM: "Jingdong.",
            dataType: "Tietotyyppi",
            selectAnalysisTask: "Valitse analyysityö",
            uploadFiles: "Lähetä tiedosto",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Lataa Excel-malli ja täytä se muodossa:",
            template: "Malli",
            SupportUpTo10000PiecesOfData:
              "2. Maksimi tuki 10000 kappaletta dataa",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Tuki Jingdong viety dialogin tiedot lataus,.txt tiedosto, tiedoston koko maksimaalinen tuki 100M",
            alongTheWayDataTemplate: "Tietojen seurantatiedon malli",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Odota kärsivällisesti tekstin laatutarkastuksen aikana.",
            textQualityInspectionCompleted:
              "Tekstin laadun tarkastus valmistui",
          },
        },
        template: {
          startAnalysis: "Aloita analyysi",
          pleaseUploadTheFile: "Lähetä tiedosto",
          localDataUpload: "Paikallinen data lähettää",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            'Uusia ominaisuuksia! Klikkaa "Review Lähetä"-painiketta, kun lähetys on onnistunut, se hyppää automaattisesti seuraavalle sivulle ~ ~ ~ ~',
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "Laadun tarkastustehtävälupa",
            edit: "Muokkaa",
            iParticipated: "Olen mukana.",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Tämä rooli näkee tiedot valitun osaston alla.",
            dataPermissions: "Tietojen oikeudet",
            selectDepartment: "Valitse osasto",
            edit: "Muokkaa",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Aloita tarkastus uudelleen",
          callID: "Kutsutunnukset",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Uusia ominaisuuksia! Napsauta näytteenotto lähettämistä ja pikainen näytteenotto lähettämistä - painikkeet hyppäävät automaattisesti seuraavalle sivulle onnistuneen lähettämisen jälkeen ~ ~ ~ ~ ~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Säännöt" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Työmääräyksen hallinnointi",
          workOrderDetails: "Työmääräyksen tiedot",
        },
      },
      template: {
        workOrderFilter: "Työnjärjestyksen suodinComment",
        allWorkOrders: "Kaikki työmääräykset",
        workOrderManagement: "Työmääräyksen hallinnointi",
      },
    },
    trainingCenter: {
      topicAnnotation: "Aihekohtainen kutsuus",
      topicList: "Aiheluettelo",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Roolityyppi",
              keyWord: "Asiasanat",
              pleaseEnterKeywordSearch: "Anna avainsanahaku",
              query: "Kysely",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Tätä funktiota ei ole vielä avattu. Ota tarvittaessa yhteyttä järjestelmän valvojaan.",
          pleaseSelectAQuestion: "Valitse kysymys!",
          recommendedSimilarSentencesLargeModel:
            "Suositeltu vastaavat lauseet (suuri malli)",
          selectAll: "Valitse kaikki",
          add: "Lisää",
          changeBatch: "Vaihda erä",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Liiketoiminnan työaikat",
      localDataUpload: "Paikallinen data lähettää",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Kokoa valikko nykyisten käyttäjien oikeuksien mukaan",
      sessionSettings: "Istunnon asetukset",
      logoSettings: "Logon asetukset",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails: "Tervetuloa takaisin! Anna tiedot",
        mailbox: "Postilaatikko",
        password: "Salasana:",
        signIn: "Kirjautuminen",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "Nykyinen IP ei salli kirjautumisen",
          welcomeToLogin: "Tervetuloa kirjautumiseen",
          pursuingExcellenceAndLeadingChange:
            "Huippuosaamisen tavoittelu, johtaa muutokset",
          intelligentPlatform: "Älykäs foorumi",
        },
        virtualLogin: { index: { pleaseSelect: "Valitse:" } },
      },
      oauth: {
        illegalCall: "Laiton puhelu",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Tervetuloa takaisin! Napsauta alla olevaa painiketta hyppääksesi tunnistusalustaan",
        loggingIn: "Kirjaudu...",
        unifiedIdentityAuthenticationLogin:
          "Yhdistetyn tunnistuksen tunnistamisen kirjautuminen",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Sääntötestaus: Joitakin sääntöjä ei voida testata käyttäen sääntöjä, kuten puhenopeutta ja äänenvoimakkuutta, matkan varrella olevaa tietoa jne.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Lue lisää",
          enteringTheSystem: "Syötä järjestelmä",
        },
      },
      index: {
        cancel: "Peruus",
        enteringTheSystem: "Syötä järjestelmä",
        close: "SuljeComment",
        enterSystemApplication: "Syötä järjestelmäsovellus",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Napsauta "Enter System" hyppääksesi käytössä olevaan järjestelmäsovellukseen.',
      },
    },
    permissionManagement: {
      employeeManagement: "Työntekijöiden johto",
      roleManagement: "Roolihallinta",
      permissionManagement: "Lupahallinta",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Yhtäläinen",
            contain: "Sisältää.",
            doesNotContain: "Ei sisällä",
            applicationSystem: "Sovellusjärjestelmä",
            intelligentQualityInspection:
              "Älykäs laatutarkastus/älykäs istuntoanalyysi",
            salesEmpowerment: "Myynnin vaikutus",
            dataSourceType: "Tietolähdetyyppi",
            filterCriteria: "Suodatin tila",
          },
        },
        index: {
          dataConnection: "Tiedon telakoitus",
          savingSucceeded: "Tallenna onnistuneesti",
          dataIsolation: "Tietojen eristys",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Avaamisen jälkeen voit asettaa jokaisen sovellusjärjestelmän pääsypuhelun ja yrityksen WeChat-istunnon tietoalueen. Jos kytkin ei ole päällä, kaikki järjestelmät on kytketty koko tietoon.",
          accessRules: "Pääsysäännöt",
          preservation: "Tallenna",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Sovelluksen nimi",
            accessRights: "Pääsy",
            inAppPermissionSettings:
              "Ohjelman sisäisen käyttöoikeuden asetukset",
            setUp: "Asetus",
            applicationAccess: "Sovellukseen pääsy",
            newRole: "Uusi rooli",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Istunnon yksityiskohdat" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Istunnon yksityiskohdat",
          caseDetails: "Asiakirjan yksityiskohdat",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Näkyvät työntekijät",
            newCourse: "Uusi kurssi",
            editCourse: "Muokkaa kurssia",
            courseName: "Kurssin nimi",
            courseIntroduction: "Kurssin johdanto",
            courseType: "Kurssityyppi",
            courseClassification: "Kurssin luokittelu",
            courseCover: "Kurssin kansi",
            learningContent: "Oppimisisältö",
            uploadLearningMaterials: "Lataa oppimateriaalit",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Testi",
              preservation: "Tallenna",
              cancel: "Peruus",
              editNodeName: "Muokkaa solmun",
              determine: "Hyvä on.",
              individual: "Yksi.",
              of: ", Yhteensä",
              branch: "Pisteet",
            },
            configDrawerComponents: {
              pPTParsing: "PPT-analyysi...",
              uploadSuccessful: "Lähetä onnistuneen",
              uploadAttachments: "Lataa liitteet",
              pPTParsingPleaseWait: "PPT:n jäsenyys, odota.",
            },
            customerPortraitConfigDrawer: {
              cancel: "Peruus",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Sitä ei voi palauttaa poistamisen jälkeen. Haluatko varmasti poistaa tämän muotokuvan?",
              delete: "Poistaa",
              determine: "Hyvä on.",
              customerPortrait: "Asiakaskuvaa",
              bindSelectedCharacterPortrait: "Siitä valittu merkki muotokuva",
              customerProfileDetails: "Asiakasprofiilin tiedot",
              createANewCustomerProfile: "Uusi asiakasprofiili",
            },
            flow: {
              sidebar: { node: "Solmi" },
              useControlButtons: {
                cancellingPleaseWait: "Peruutukaa.",
                redoInProgressPleaseWait: "Tulkaa myöhemmin.",
                automaticOrganization: "Automaattinen viimeistely",
                canvasAdaptation: "Adaptiivinen kankaa",
                delete: "Poistaa",
              },
              useFlow: {
                nodeCannotBeConnectedToItself: "Solmu ei voi yhdistää itseensä",
              },
            },
            globalConfigDrawer: {
              cancel: "Peruus",
              determine: "Hyvä on.",
              globalConfiguration: "Yleiset asetukset",
              sessionDetection: "Istunnon havaitseminen",
              reciprocalRule: "Yhteisvaikutussäännöt",
              robotVoice: "Robottiääni",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Opiskelijoiden aikaväli vastaamattomat asetukset",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Suurin sallittu hiljainen aika (s)",
              promptScriptForFailureToAnswerOverTime: "Aikaisu",
              addScript: "Lisää sanat",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (Luonto)",
              zhishaChat: "Zhisha (keskustelu)",
              zhiqiangentle: "Zhiqian (Lempeä)",
              zhimiEmotion: "Tietää riisi (tunteet)",
              aiShuoNatural: "Aishuo (luonto)",
              zhishuoChat: "Zhishuo.",
              aiHaoAffinity: "Ai Hao",
              zhixiangEmotion: "Zhixiang (Emotion)",
              robotVoice: "Robottiääni",
              schoolboy: "Pojat.",
              girlStudent: "Tytöt",
              volume: "Määrä",
              tone: "Toni",
              speechSpeed: "Puhenopeus",
              test: "Testi",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Hei, olen älykäs valmentaja ja odotan kommunikointia kanssasi.",
            },
            sessionDetectionForm: {
              expressionDetection: "Lausekkeen havaitseminen",
              excessiveDetectionOfModalParticles:
                "Liiallinen modaalinen sananpaljastaminen",
              theNumberOfRepetitionsIs: "Toistojen määrä on:",
              minus: "Aika, miinus.",
              appear: "Ilmesty",
              subtract: "Kertaa miinusta",
              branch: "Pisteet",
              addRules: "Lisää sääntö",
              includingNegativeAngryComplaining:
                "(Mukaan lukien negatiivinen, vihainen, valittaminen)",
              scorePointsDisplay: "Pistepisteiden näyttö",
              reminderOfSpeakingTooFasttooSlow:
                "Nopeus liian nopea/liian hidas muistutus",
              scriptViolationReminder: "Muistutus puherikkomuksesta",
              emotionalReminder: "Tunneperäinen muistutus",
              speechSpeedDetection: "Puheen nopeuden havaitseminen",
              normalSpeechSpeedScore: "LIITTYVÄ NORMAALINEN SOVELTAMISALA",
              speakTooFast: "Liian nopea.",
              everyMinuteGreaterThan: "Minuutissa enemmän:",
              aWordAppears: "Sana, ilmesty.",
              speakTooSlowly: "LAHTÄVÄ",
              lessThanPerMinute: "Vähemmän kuin minuutissa",
              ignoreLessThan: "Älä huomioi vähemmälle",
              aSentenceOfWords: "Sanojen lauseet",
              emotionalDetection: "Tunteiden havaitseminen",
              emotionalNormalScore: "Normaali mielialan pisteet",
              negativeEmotions: "Kielteinen tunne.",
              illegalWordDetection: "Laiton sanan havaitseminen",
              scriptViolationConfiguration: "Puherikkomusten asetukset",
              realTimePromptOnTheFrontEnd: "Edessä oleva reaaliaikainen nopeus",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Tietopohjaa",
                  qAMethod: "Kysymys- ja vastausmenetelmä",
                  numberOfQuestionsAndAnswers: "Kysymysten ja vastausten määrä",
                  scoringRules: "Pisteissäännöt",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Kaikki kysymykset ja vastaukset ovat oikeita.",
                  branch: "Pisteet",
                  successfullySaved: "Tallenna onnistuneesti",
                },
                pptNode: {
                  verificationPassed: "Tarkastus hyväksytty",
                  upload: "Lähetä",
                  supportPptPdfFormatFiles: "Tuki ppt- ja pdf-muototiedostoja",
                  uploadSlide: "Lähetä diat",
                  assessmentMethod: "Arviointimenetelmä",
                  associateSlide: "Liitä diat",
                  standardScript: "Vakiopuhee",
                  successfullySaved: "Tallenna onnistuneesti",
                },
                robotNode: {
                  robotScript: "RobottipuheName",
                  randomScript: "Satunnainen puheName",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Valitse sanoja samalla tavalla ja robotti sanoo satunnaisesti yhden lauseista.",
                  customerPortrait: "Asiakaskuvaa",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Kun asiakas muotokuva on johdonmukainen, robotti puhuu tätä.",
                  setAsDefaultScript: "Aseta oletukseksi",
                  yes: "Kyllä.",
                  no: "Ei ei",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Näin sanotaan, kun robotti myöhästyy mitään käyttäjän tunniste.",
                  successfullySaved: "Tallenna onnistuneesti",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Tämä haaran solmu on jo oletuspuhe. Haluatko korvata oletuspuheen nykyisellä puheella?",
                },
                startNode: {
                  paragon: "Puhelin",
                  successfullySaved: "Tallenna onnistuneesti",
                },
                userNode: {
                  verificationPassed: "Tarkastus hyväksytty",
                  standardScript: "Vakiopuhee",
                  successfullySaved: "Tallenna onnistuneesti",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Ole hyvä ja täydellinen kokoonpano.",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "Anna näytekeskustelu",
                  dialogueExample: "Keskusteluesimerkki",
                  generateConversationExamples: "Luo ikkunan esimerkki",
                  mattersNeedingAttention: "Varotoimet",
                  pleaseEnterThePromptLanguage: "Anna kysymys",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Valmisteluikkuna",
                  simulateCharacter: "Simuloitu merkki",
                  accompanyingTrainees: "Liitännäinen kohte",
                  selectFromTemplateLibrary: "Valitse pohjakirjasta",
                  templateLibrary: "Mallikirsto",
                  role: "Rooli",
                  scene: "Kohtaus",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Liuku testiikkuna e",
              clearContent: "Tyhjennä sisältö",
              close: "SuljeComment",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI-muodostusprosessi",
                scriptStyle: "Puhetyyli",
                theMostConversationRounds: "Useimmat neuvottelukierrokset",
                copyConversation: "Kopioi ikkuno",
                application: "Soveltaminen",
                regenerate: "Uudessa",
                startGenerating: "Käynnistys",
                pleaseGenerateAProcess: "Luo prosessi",
                copySuccessful: "Kopioi menestys",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Myyntiedustaja",
                    customerServicePersonnel: "Asiakaspalveluhenkilöstö",
                    storeCounter: "Tallenna laskija",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "Luo koko tekstin puhetaitoja",
                speaker: "Kaiutin",
                scriptStyle: "Puhetyyli",
                copyConversation: "Kopioi ikkuno",
                application: "Soveltaminen",
                regenerate: "Uudessa",
                startGenerating: "Käynnistys",
                pleaseGenerateAProcess: "Luo prosessi",
                operationSuccessful: "Toiminta onnistui",
                copySuccessful: "Kopioi menestys",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Puhekiillosta" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Sanat eivät voi olla tyhjiä!",
              copySuccessful: "Kopioi menestys",
              cancel: "Peruus",
              copy: "Kopioi",
              replaceTheOriginalText: "Korvaa alkuperäistä",
              aiGeneration: "Tuen tuottaminen",
              aiPolishing: "Aikiolaksi",
              currentStyle: "Nykyinen tyyli",
              rigorous: "Tiukkaa",
              mild: "Hellästi",
              lively: "Elävää",
              simplicity: "Yksinkertainen",
              pleaseEnterTheSpeakerRole: "Anna puhujan rooli",
              startGenerating: "Käynnistys",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Sanat eivät voi olla tyhjiä!",
              copySuccessful: "Kopioi menestys",
              aiRewrite: "Ai uudelleen",
              cancel: "Peruus",
              copy: "Kopioi",
              replaceTheOriginalText: "Korvaa alkuperäistä",
              currentStyle: "Nykyinen tyyli",
              rigorous: "Tiukkaa",
              mild: "Hellästi",
              lively: "Elävää",
              simplicity: "Yksinkertainen",
              pleaseEnterTheSpeakerRole: "Anna puhujan rooli",
              startGenerating: "Käynnistys",
            },
          },
          index: {
            verificationPassed: "Tarkastus hyväksytty",
            ruleValidation: "Sääntöjen tarkastaminen",
            aIGeneration: "Tekoälytuotteet",
            generateFullTextSpeechScript: "Luo koko tekstin puhetaitoja",
            editCourse: "Muokkaa kurssia",
            importConversation: "Tuo keskustelua",
            uploadExcelToCompleteDialogueImport:
              "Lataa Excel täydellinen tuontiikkuno",
            editBasicInformation: "Muokkaa perustiedota",
            test: "Testi",
            release: "Julkaisu",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Uusi nimi" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Ei voi palauttaa poistamisen jälkeen. Haluatko varmasti poistaa tämän etiketin?",
              delete: "Poistaa",
              cancel: "Peruus",
            },
          },
        },
        index: {
          labelManagement: "Merkinnän hallinta",
          newGroup: "Uusi ryhmä",
          edit: "Muokkaa",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Ei voi palauttaa poistamisen jälkeen. Haluatko varmasti poistaa tämän etikettiryhmän?",
          delete: "Poistaa",
          cancel: "Peruus",
          editGroup: "Muokkaa ryhmittymistä",
          labelGroupName: "Etikettiryhmän nimi",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Kamera ei käytössä",
              noCameraDevicesFound: "Kameralaitetta ei löytynyt",
            },
          },
          cue: {
            index: {
              regenerate: "Uudessa",
              generate: "Luo",
              referenceScript: "Viitepuheet",
              mattersNeedingAttention: "Varotoimet",
              examFrequency: "Kokeiden lukumäärä",
              section: "- En.",
              timestotal: "Aikaa/Yhteensä",
              second: "Ajani",
              requirementsForPassingTheExam: "Tentin läpäisemisen vaatimukset",
              examScore: "Testipisteet ≥",
              branch: "Pisteet",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Ei muotokuvaa",
              customerBackground: "Asiakastausta",
              customerLabel: "Asiakasmerkki",
            },
          },
          flow: {
            index: {
              processReminder: "Prosessin noudattaminen ()",
              score: "Pisteet",
              TotalScore: "/Yhteensä",
            },
          },
          index: {
            taskName: "Tehtävän nimi",
            taskStatus: "Tehtävän tila",
            whole: "Kaikki",
            completionStatus: "Täydentämisen tila",
            courseClassification: "Kurssin luokittelu",
            practiceMode: "Harjoittelutila",
            examMode: "Tutkimustila",
            practiceTime: "Harjoitusaika",
          },
          ppt: {
            index: {
              nextPage: "Seuraava sivu",
              tips: "Kysy",
              continueWithCurrentPage: "Jatka nykyistä sivua",
              goToTheNextPage: "Siirry seuraavalle sivulle",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Kun olet syöttänyt seuraavalle sivulle, et voi palata edelliselle sivulle. Vahvista, että nykyisen sivun selitys on valmis ennen hyppäämistä.",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Napsauta seuraavalla sivulla ei näytä tätä vihjailua",
            },
            list: { index: { slide: "Liukus" } },
          },
          record: {
            index: {
              unableToRecord: "Ei voitu tallentaa:",
              creatingARecorderService: "Luo nauhoituspalvelun",
              turnOffTheRecorderService: "Sammuta tallennuspalvelut",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Nykyinen asiakas on jo kytketty ASR Websocket-palvelimeen",
              afterProcessingTheLastFrameRestoreTheState:
                "Viimeisen kehyksen käsittelyn jälkeen palauta tila",
              aSRConnectionClosed: "ASR-yhteys suljettu.",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Tallennus on keskeytetty ja on käsitelty, mutta välimuistissa on edelleen tietoa",
              foundASRChannelClosedRecreateWebsocketLink:
                "Löytyi ASR-kanava suljettu, luo Websocket linkki",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Löytyi, että ASR-kanava ei ole auki, luo Websocket linkki",
            },
            ppt: { hook: { index: { serverError: "Palvelimen virhe" } } },
            text: {
              index: {
                pleaseEnter: "Syötä",
                sendOut: "Lähetä",
                startPracticing: "Aloita harjoittelua",
                startTheExam: "Aloita tentti.",
              },
            },
            util: {
              recorded: "Tallennettu",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16-bittinen pcm SendFrameSize täytyy olla kokonaisluku moninkertainen 2",
              pCMSamplingRateShouldNotOccur:
                "Pcm näytteenottonopeutta ei saa näkyä.",
              andTheRequiredSamplingRate: "Ja vaadittu näytteenottoaste",
              atypism: "Epäjohdonmukainen",
              errorsThatShouldNotOccur: "Virheitä, joita ei pitäisi tapahtua:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span-tyyli = "color:red> Tiedot, jotka eivät ole järjestyksessä, voidaan hylätä, jos vaatimukset eivät ole korkeita. _BAR_',
              flower: "Kukkit",
              TransferStopped: ": Siirto keskeytetty",
            },
            video: { index: { camera: "Kamera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Nykyinen asiakas on jo kytketty ASR Websocket-palvelimeen",
                    aSRConnectionClosed: "ASR-yhteys suljettu.",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Löytyi ASR-kanava suljettu, luo Websocket linkki",
                    unableToRecord: "Ei voitu tallentaa:",
                    aSRChannelClosedReopened: "ASR-kanava suljettu.",
                    theASRChannelWasNotCreatedStartingNow:
                      "ASR-kanavaa ei ole luotu, aloitetaan luoda nyt",
                    useEffectCallbackToClearTheASRChannel:
                      "KäyttöEhkäisy soittaa takaisin ASR-kanavan tyhjentämiseksi",
                    creatingARecorderService: "Luo nauhoituspalvelun",
                    turnOffTheRecorderService: "Sammuta tallennuspalvelut",
                  },
                  util: {
                    recorded: "Tallennettu",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16-bittinen pcm SendFrameSize täytyy olla kokonaisluku moninkertainen 2",
                    pCMSamplingRateShouldNotOccur:
                      "Pcm näytteenottonopeutta ei saa näkyä.",
                    andTheRequiredSamplingRate: "Ja vaadittu näytteenottoaste",
                    atypism: "Epäjohdonmukainen",
                    errorsThatShouldNotOccur:
                      "Virheitä, joita ei pitäisi tapahtua:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span-tyyli = "color:red> Tiedot, jotka eivät ole järjestyksessä, voidaan hylätä, jos vaatimukset eivät ole korkeita. _BAR_',
                    flower: "Kukkit",
                    TransferStopped: ": Siirto keskeytetty",
                  },
                },
                index: { failInSend: "Lähetys epäonnistui" },
              },
              index: {
                startRecording: "Aloita nauhoitus",
                endRecording: "Lopettaminen",
                startPracticing: "Aloita harjoittelua",
                startTheExam: "Aloita tentti.",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Nykyinen määrä kokeita jäljellä on",
              totalScriptScore: "Puheen kokonaispisteet:",
              totalEmotionalScore: "Tunteiden kokonaispisteet:",
              totalScoreOfExpression: "Lausekkeen kokonaispisteet:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Nykyiset tehtävän pisteytystulokset on tarkistettava manuaalisesti. Kun tarkastelu on suoritettu, sinulle ilmoitetaan lopputulokset. Odottakaa kärsivällisesti.",
              areYouSureToSubmit: "Vahvistan?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Liikunta on päättynyt. Haluatko varmasti lähettää sen?",
              theExamHasEndedAreYouSureToSubmit: "Haluatko varmasti tehdä sen?",
              completed: "Valmistui",
              page: "Sivua",
              pageCount: "Sivuja yhteensä",
              topic: "Aiheet",
              totalNumberOfQuestions: "Kysymysten kokonaismäärä",
              totalScore: "Pisteet yhteensä",
              branch: "Pisteet",
              accumulatedCompletionProgress:
                "Kumulatiivinen edistyminen loppuun saattamisesta",
              accumulatedPracticeDuration: "Käytännön kumulatiivinen kesto",
              lastPauseExerciseResults: "Viimeisimmät ajan lopputulokset",
              exerciseInstructions: "Liikuntaohjeet",
              examInstructions: "Tenttikuvaus",
              halfwayPausePracticeResults: "Puolivälin tauon tulokset",
              tips: "Kysy",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Lopettamisen jälkeen tämä harjoitus ei sisälly pisteeseen. Haluatko varmasti lopettaa?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Lopettaminen ei ole sallittua keskellä tenttiä. Vahvistan?",
              pleaseOperateWithCaution: "Times, toimikaa huolellisesti)",
              restart: "Käynnistäkää uudestaan",
              returnToLearning: "Palaa oppimiseen",
              continuePracticing: "Jatka harjoittelua",
              continuingTheExam: "Jatka tenttiä",
              return: "Palautus",
              notSubmittedTemporarily: "Ei lähetetty",
              iGotIt: "Tiedän.",
              confirmExit: "Vahvista poistuminen",
              confirmSubmission: "Vahvista toimitus",
              signOut: "Poistu...",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Tauo",
              submit: "Toimittaminen",
              remainingTimeOfThisQuestion: "Jäljellä oleva aika",
              SecondsLeftUntilTheEndTime: "10 sekuntia päättymisajan saakkaan",
              remainingTime: "Jäljellä oleva aika",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Käyttäjän äänisisältöä ei havaittu, yritä uudelleen",
          },
          wave: {
            index: {
              continueRecording: "Jatka tallentamista",
              submit: "Toimittaminen",
              endRecording: "Lopettaminen",
              suspendRecording: "Pysäyttäminen tallennus",
              startPracticing: "Aloita harjoittelua",
              startTheExam: "Aloita tentti.",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Ikkunan tila",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Liikaa melua, vaihda hiljaiseen ympäristöön.",
              normalNoiseDetection: "Melun havaitseminen on normaalia.",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Tilavuus on liian alhainen, lisätään tilavuutta.",
              volumeDetectionIsNormal:
                "Äänenvoimakkuuden havaitseminen normaalin",
              noiseDetection: "Melun havaitseminen",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Ole hiljaa ja tallentaa 3s ympäristöääni.",
              endRecording: "Lopettaminen",
              startRecording: "Aloita nauhoitus",
              volumeDetection: "Tilavuuden havaitseminen",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Lue "volyymitesti nyt" normaalissa tilavuudessa.',
              playLastDetectedAudio: "Soita viimeisin tunnistusääni",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Lue vaatimukset huolellisesti ennen kuin aloitat.",
              return: "Palautus",
              iGotIt: "Tiedän.",
              timeLimitForSingleQuestionAnswering:
                "Yhden kysymyksen vastaukseen",
              totalTimeLimit: "Yhteensä",
              unlimitedTime: "Rajoittamaton aikaa",
              stillNeedToCompleteTheTaskInTotal:
                "Tehtävä on edelleen saatettava päätökseen.",
              practice: "Liikunta",
              second: "Ajani",
              taskScore: "Yksi tehtäväpiste ≥",
              branch: "Pisteet",
              or: "Ei",
              examScore: "Testipisteet ≥",
              remainingExamTimes: "Jäljellä olevien tenttien lukumäärä",
              totalScore: "Pisteet yhteensä",
              Script: "Hyvä on.",
              scoreEmotion: "Tunteet",
              fractionExpression: "Sub-ilmaista",
              points: "Pisteet)",
              standardScore: "Vakiopisteet",
              excellentScore: "Erinomainen pisteet",
              exerciseInstructions: "Liikuntaohjeet",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Jotta voitte vastata sujuvasti kysymykseen, suorittakaa laitteiden testi ensin:",
              camera: "Kamera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Videon näkeminen tarkoittaa, että kamera on normaali.",
              microphone: "Mikrofoni",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Puhu mikrofoniin.",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Tyhjä",
                  copyTo: "Kopioi",
                  classification: "Luokittelu",
                  rename: "Nimeä uudelleen",
                  newlyBuild: "Uusi:",
                  categoryParent: "Ylivertainen luokitus",
                  moveTo: "Siirrä",
                  name: "Nimi",
                },
              },
              util: {
                rename: "Nimeä uudelleen",
                moveTo: "Siirrä",
                copyTo: "Kopioi",
                delete: "Poistaa",
                courseClassification: "Kurssin luokittelu",
                newlyBuild: "Uusi:",
              },
            },
          },
          edit: { index: { curriculum: "Opetussuunnitelma" } },
          index: {
            learn: "Oppiminen",
            practice: "Harjoittelu",
            examination: "Testi",
            curriculum: "Opetussuunnitelma",
            courseIntroduction: "Kurssin johdanto:",
            createdOn: "Luonnon aika:",
            published: "Julkaistut",
            tasks: "Tehtävät",
            unpublishedTasks: "Julkaisematon tehtävä",
            task: "Tehtävät",
            operation: "Toiminto",
            learningMaterials: "Oppimateriaalit",
            goPractice: "Siirry harjoitteluon",
            publishTask: "Julkaise tehtävät",
            taskData: "Tehtävätiedot",
            edit: "Muokkaa",
            copy: "Kopioi",
            successfullyCopied: "Kopioi menestys",
            delete: "Poistaa",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Poistamisen jälkeen kaikki tämän kurssin tehtävätiedot selvitetään. Voitko varmasti poistaa kurssin?",
            courseName: "Kurssin nimi",
            creationTime: "Luomisaikaan",
            releaseStatus: "Julkaise tila",
            whole: "Kaikki",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Ääntä ei havaittu",
              voice: "Ääni",
              text: "Teksti",
              referenceScript: "Viitepuhe:",
              clickOnTheRightSideToStartRecording:
                "Napsauta oikealla nauhoituksen aloittamiseksi",
              sendOut: "Lähetä",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Nykyinen asiakas on jo kytketty ASR Websocket-palvelimeen",
                afterProcessingTheLastFrameRestoreTheState:
                  "Viimeisen kehyksen käsittelyn jälkeen palauta tila",
                aSRConnectionClosed: "ASR-yhteys suljettu.",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Tallennus on keskeytetty ja on käsitelty, mutta välimuistissa on edelleen tietoa",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Löytyi ASR-kanava suljettu, luo Websocket linkki",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Löytyi, että ASR-kanava ei ole auki, luo Websocket linkki",
                unableToRecord: "Ei voitu tallentaa:",
                creatingARecorderService: "Luo nauhoituspalvelun",
                turnOffTheRecorderService: "Sammuta tallennuspalvelut",
              },
              util: {
                recorded: "Tallennettu",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16-bittinen pcm SendFrameSize täytyy olla kokonaisluku moninkertainen 2",
                pCMSamplingRateShouldNotOccur:
                  "Pcm näytteenottonopeutta ei saa näkyä.",
                andTheRequiredSamplingRate: "Ja vaadittu näytteenottoaste",
                atypism: "Epäjohdonmukainen",
                errorsThatShouldNotOccur:
                  "Virheitä, joita ei pitäisi tapahtua:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span-tyyli = "color:red> Tiedot, jotka eivät ole järjestyksessä, voidaan hylätä, jos vaatimukset eivät ole korkeita. _BAR_',
                flower: "Kukkit",
                TransferStopped: ": Siirto keskeytetty",
              },
            },
            finishTest: {
              index: {
                endTest: "Lopputesti",
                testResult: "Testitulokset",
                returnToCanvas: "Palaa kankaaseen",
                retest: "Uudelleen testi",
              },
            },
          },
          index: {
            testFromCurrentNode: "Testi nykyisestä solmusta",
            listening: "Radio...",
            canvasTesting: "Kanvastesti",
            endOfProcess: "Prosessin päättyminen",
            restart: "Käynnistäkää uudestaan",
            start: "Käynnistä",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Murtautumisen analyysi",
                  expandParsing: "Laajenna tarkkailu",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Liian monta modaalista sanaa",
                  second: "Ajani",
                  ratingOverview: "Pisteet yleiskatsaus",
                  totalScore: "Pisteet yhteensä",
                  section: "- En.",
                  name: "Nimi",
                  of: "(Yhteensä",
                  people: "Potilaat)",
                  scriptScore: "Puhepisteet",
                  common: "Yhteensä",
                  keyPoints: "Keskeinen kohta,",
                  notReached: "Ei saavutettu,",
                  have: "Niitä on.",
                  violationOfRegulations: "Rikkomukset",
                  emotionalScore: "Tunteiden pisteet",
                  appear: "Ilmesty",
                  secondaryNegativeEmotions: "Toissijaiset kielteiset tunteet",
                  expressionScore: "Lausekkeen pistemäärä",
                  speakTooFast: "Liian nopea.",
                  times: "Kertaa,",
                  speakTooSlowly: "LAHTÄVÄ",
                  average: "Keskimäärä",
                  wordminute: "Sana/minui",
                },
              },
              score: {
                index: {
                  studentNodeName: "Opiskelijoukon nimi",
                  keyPointName: "Avainpisteen nimi",
                  slideNodeName: "Liukusolmen nimi",
                  dialogue: "Dialogi",
                  mainPoints: "Avainkohdat",
                  slide: "Liukus",
                  detectionResult: "Testitulokset",
                  satisfy: "Kokoukset",
                  dissatisfaction: "Ei tyytymätty",
                  score: "Pisteet",
                  script: "Sanat",
                  semantics: "Semanttinen",
                  scriptViolation: "Puherikkoja",
                  emotion: "Tunteet",
                  expression: "Lauseke",
                },
              },
              taskTitle: { index: { branch: "Pisteet" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Tällä kertaa",
                  lastTime: "Viimeinen kerta",
                  comprehensiveEvaluation: "Kattava arviointi",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span-tyyli = "margin- vasemmalle: 8px > · Standard Speech kumulatiivinen',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Sana, opiskelija osui sanoja.",
                  aWordspan: "Sana helvetti>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Ajattelua / ̈span tyyli = "margin-vasen: 8px> · Hidas nopeus',
                  timesspan: "_BAR_",
                  tooManyModalParticles:
                    '<Span tyyli = "margin vasemmalle: 8px > · Liian monta modaalinen sanat',
                  complete: "Täydellinen sisältö",
                  keyHit: "Tärkeimmät osumat",
                  languageNorm: "Kielietelmät",
                  fluentExpression: "Sileä lauseke",
                  emotionallyPositive: "Tunteellisesti positiivinen.",
                  radarChart: "Tutkakaavio",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span tyyli = "margin vasemmalle: 8px >· Täydellisten virtojen määrä',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span tyyli = "margin vasemmisto: 8px >',
                  spanStylemarginLeftpxYes:
                    '<Span tyyli = "margin vasemmalle: 8px > · Kyllä',
                  dealingWithIllegalWordsspan: "Ristiriidassa sanaa!",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span tyyli = "margin vasen: 8px > · Puhu liian nopeaa',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Tyyli = "margin vasemmalle: 8px" liian monta modaalinen sanat',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span tyyli = "margin vasemmalle: 8px > · Negatiiviset tunteet',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Pisteetue",
                  firstRating: "Ensimmäinen luokitus",
                  totalScore: "Pisteet yhteensä",
                  changeTo: "Muutta",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Viestintävaihe",
                  communicationObjectives: "Viestintätavoitteet",
                  scoringCriteria: "Pisteysperusteet",
                  suggestionsForImprovement: "Myynninedistämissuositukset",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Jäljellä:",
                  second: "Ajani",
                  doItAgain: "Vielä kerran.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Selain ei tue äänen soittoa.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions:
                    "Puheeseen liittyviä kommentteja ja ehdotuksia",
                  regenerate: "Uudessa",
                  generate: "Luo",
                  generationFailed: "Rakentaminen epäonnistui",
                },
              },
              resultModal: {
                index: { return: "Palautus", viewDetails: "Näytä tiedot" },
              },
            },
            index: {
              reRatingSuccessful: "Uudelleen tulostus",
              reRatingFailed: "Uudelleen tulos epäonnistui",
              downloadRecording: "Lataa tallentaminen",
              downloadText: "Lataa tekstiä",
              download: "Lataus",
              reRating: "Uudelleen tulostus",
              submitARatingAppeal: "Lähetä luokittelun valinta",
              reviewSubmission: "Uudelleentarkastelu",
              saveChanges: "Tallenna muutokset",
              previousSentence: "Edellinen virke",
              nextSentence: "Seuraava virke",
              score: "Luokitus",
              scoringSituation: "Pisteet",
              dialogueAnalysis: "Dialogianalyysi",
            },
          },
          index: {
            ratingAppealInProgress: "Valehteluja",
            operation: "Toiminto",
            wrongQuestionSet: "Väärä ongelma asetettu",
            ratingUpdate: "Luokituksen päivitys",
            viewDetails: "Näytä tiedot",
            curriculum: "Opetussuunnitelma",
            taskName: "Tehtävän nimi",
            taskType: "Tehtävätyyppi",
            score: "Pisteet",
            scoringResults: "Tulokset",
            time: "Aika",
            courseClassification: "Kurssin luokittelu",
            whole: "Kaikki",
            learningTime: "Oppimaika",
            historicalLearningData: "Historialliset oppimista koskevat tiedot",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Harjoittelu tauo",
              lastProgress: "Edeneminen",
              continuePracticing: "Jatka harjoittelua",
              practice: "Liikunta",
              startPracticing: "Aloita harjoittelua",
              historicalData: "Historialliset tiedot",
              courseIntroduction: "Kurssin johdanto:",
              exerciseDifficulty: "Käytännön vaikeudet:",
              practiceCompletionRequirements:
                "Liikunnan suorittamista koskevat vaatimukset:",
              practiceFrequency: "Harjoitusten lukumäärä ≥",
              second: "Ajani",
              anyScore: "Mikä tahansa pistemäärä ≥",
              branch: "Pisteet",
              or: "Ei",
              taskList: "Tehtäväluettelo",
              practiceTime: "Harjoitusaika:",
              to: "Suuntaan",
              unlimitedTime: "Rajoittamaton aikaa",
              completionStatus: "Täydentämisen tila",
              numberOfExercises: "Harjoitusten lukumäärä",
              maximumScore: "Korkein pisteet",
              operation: "Toiminto",
              enteringLearning: "Siirry oppimiseen",
              historicalLearningData:
                "Historialliset oppimista koskevat tiedot",
              wrongQuestionSet: "Väärä ongelma asetettu",
            },
          },
          index: {
            practiceTasks: "Harjoittelu",
            examTasks: "Tarkastustehtävät",
            task: "Tehtävät",
          },
          learn: {
            index: {
              courseName: "Kurssin nimi",
              courseIntroduction: "Kurssin johdanto",
              learningContent: "Oppimisisältö",
              downloadLearningMaterials: "Oppimateriaalit Lataa",
              startPracticing: "Aloita harjoittelua",
            },
          },
          test: {
            index: {
              taskList: "Tehtäväluettelo",
              courseIntroduction: "Kurssin johdanto:",
              examDifficulty: "Vaikeus tentti:",
              requirementsForPassingTheExam: "Vaatimukset läpäisee tentti:",
              examScore: "Testipisteet ≥",
              branch: "Pisteet",
              examTime: "Tarkastusaika:",
              to: "Suuntaan",
              unlimitedTime: "Rajoittamaton aikaa",
              completionStatus: "Täydentämisen tila",
              completionTimes: "Valmistelujen lukumäärä",
              maximumScore: "Korkein pisteet",
              operation: "Toiminto",
              immediateExam: "Välitön tutkinta",
              history: "Historia",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Viitepuheet" } },
              flow: {
                index: {
                  wrongQuestionItem: "Väärä kohta",
                  score: "Pisteet",
                  TotalScore: "/Yhteensä",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Palvelimen virhe" } },
                  index: {
                    startRecording: "Aloita nauhoitus",
                    endRecording: "Lopettaminen",
                    startPracticing: "Aloita harjoittelua",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Syötä",
                    sendOut: "Lähetä",
                    startPracticing: "Aloita harjoittelua",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Aloita nauhoitus",
                    endRecording: "Lopettaminen",
                    startPracticing: "Aloita harjoittelua",
                  },
                  hook: { index: { failInSend: "Lähetys epäonnistui" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Tämän väärän harjoituksen pisteet",
                  tips: "Kysy",
                  areYouSureToEnd: "Vahvistatko lopun?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Harjoittele uudelleen äläkä hallitse vääriä kysymyksiä.",
                  continue: "Jatka",
                  returnToTheSetOfIncorrectQuestions:
                    "Palaa vääriin kysymyksiin",
                  end: "LoppuComment",
                  wrongQuestionItem: "Väärä kohta",
                  numberOfExercisesThisTime: "Tämän tehtävän lukumäärä",
                  masteringTheSituation: "Ymmärrä tilannetta.",
                  mastered: "Hallinnointi",
                  notMastered: "Ei hallittu",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Käytä tätä kysymystä uudelleen",
                  nextQuestion: "Seuraava kysymys",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Väärä liikunta",
                endExercise: "Loppuharjoitus",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Väärä liikunta",
                endExercise: "Loppuharjoitus",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Väärä liikunta",
                endExercise: "Loppuharjoitus",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Näytä puheName",
                  section: "- En.",
                  secondaryDiscourseTechnique: "Toissijainen puhe.",
                },
              },
              tableItem: {
                count: { second: "Ajani" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Asiaan liittyviä tietoja ei löytynyt",
                  recallWords: "Palauta sanaa",
                  branch: "Pisteet",
                  answerIncorrectly: "Väärä vastaus",
                  second: "Ajani",
                  wrongAnswerRate: "Väärä vastausprosentti",
                },
                standardScript: {
                  standardScript: "Vakiopuhee",
                  myScript: "Minun sanani.",
                },
                status: {
                  mastered: "Hallinnointi",
                  notMastered: "Ei hallittu",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "Vastausten määrä yhteensä",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Vääriä kysymyksiä on harjoitettu",
                },
              },
            },
            index: {
              wrongQuestionList: "Luettelo vääristä kysymyksistä",
              wrongQuestionSet: "Väärä ongelma asetettu",
              mispracticeExercises: "Väärä liikunta",
              taskName: "Tehtävän nimi",
              staff: "Työntekijä",
              masteringTheSituation: "Ymmärrä tilannetta.",
              whole: "Kaikki",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Harjoitusten lukumäärä ≥",
              second: "Ajani",
              or: "Ei",
              excellentScoreAtAnyOneTime:
                "Mikä tahansa pistemäärä on erinomainen (≥)",
              points: "Pisteet)",
              examScore: "Testipisteet ≥",
              branch: "Pisteet",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Keskimääräinen satunnaisjakaus",
              assignToDesignatedPersonnel: "Määrätään nimetyille henkilöille",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Onnistuminen kokeiden määrän lisäämisessä",
              failedToIncreaseTheNumberOfExams:
                "Testivirheen määrän lisääminen",
              increaseTheNumberOfExams: "Lisää tenttien määrä",
              selectObjects: "Valitse objekti",
            },
          },
          executionFilter: {
            index: { participationRate: "Osallistumisasteet" },
          },
          intelligentReview: {
            index: {
              overallReview: "Yleiskatsaus",
              pleaseEnterTheReviewCriterianotMandatory:
                "Syötä kommenttikriteerit (ei vaadi)",
              commentaryOnSingleSentenceRhetoric:
                "Huomautuksia yhtenäisestä lauseesta",
              realTimeReview: "Reaaliaikaiset arviot",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Keskimääräinen testauspisteet",
            averageExamDurationmin: "Keskimääräinen tentti kesto (min)",
            accumulatedPracticeDurationmin:
              "Käytännön kumulatiivinen kesto (min)",
            accumulatedPracticeTimes: "Harjoitusten kumulatiivinen lukumäärä",
            totalScore: "Pisteet yhteensä",
            average: "Keskimäärä",
            total: "Yhteensä",
            keyHitCount: "Avainpisteet osuma lukumäärä",
            practiceAverageScore: "Käytännön keskimääräinen pistemäärä",
            averagePracticeDurationmin: "Keskimääräinen liikunnan kesto (min)",
            topOfTheHighestScore: "Korkein pistemäärä Top3",
            branch: "Pisteet",
            errorProneItemTop: "Virheelle asetetut kohteet Top5",
            hitRate: "Osumaasto",
            hitRateOfKeyPointsInScript: "Puhe avainpisteet Hit määrä",
            averageScriptScore: "Keskimääräinen puhepisteet",
            sortByProcess: "Lajittele prosessin mukaan",
            sortByScore: "Lajittele pisteiden mukaan",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Tämän sivun keskimääräinen pistemäärä ja kokonaispisteet",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Tämän erän keskimääräinen pistemäärä / kokonaispisteet",
            details: "Yksityiskohtaiset tiedot",
            viewRules: "Näytä säännöt",
            standardScript: "Vakiopuhee",
          },
          scoreDetailList: {
            section: "- En.",
            second: "Ajani",
            practice: "Liikunta",
            examination: "Tarkastelu",
            notMeetingStandards: "Ei vakio",
            meetingStandards: "Standardi",
            excellent: "Loistavaa",
            frequency: "Kertojen lukumäärä",
            fraction: "Fraktio",
            result: "Tulokset",
            operation: "Toiminto",
            viewDetails: "Näytä tiedot",
          },
          index: {
            common: "Yhteensä",
            people: "Ihmiset",
            staff: "Työntekijä",
            notMeetingStandards: "Ei vakio",
            meetingStandards: "Standardi",
            excellent: "Loistavaa",
            notStarted: "Ei käynnistynyt",
            haveInHand: "Käynnissä olevat toimet",
            completed: "Valmistui",
            passed: "Läpäivä",
            hangInTheAir: "Viimeistelemättömät",
            failed: "Ei läpäisty",
            unlimitedTime: "Rajoittamaton aikaa",
            to: "Suuntaan",
            maximumScoringCriteria: "Korkeimmat pisteytysvaatimukset",
            completionStatus: "Täydentämisen tila",
            employeeName: "Työntekijän nimi",
            frequency: "Kertojen lukumäärä",
            practice: "Liikunta",
            examination: "Tarkastelu",
            average: "Keskimäärä",
            speechCraftAvgScore: "Keskimääräinen puhepisteet",
            emotionAvgScore: "Keskimääräinen emotionaalinen pisteet",
            speedAvgScore: "Express keskiarvo",
            flowAvgScore: "Prosessin keskimääräinen pistemäärä",
            practiceCostTime: "Kumulatiivinen kesto",
            participateCommitRate: "Vaatimustenmukaisuus",
            participateExcellentRate: "Erinomainen korko",
            duration: "Voimassaoloaika",
            score: "Pisteet",
            scoringResults: "Tulokset",
            flowScore: "Prosessin pisteet",
            craftScore: "Puhepisteet",
            emotionScore: "Tunteiden pisteet",
            speedScore: "Lausekkeen pistemäärä",
            contentIntegrity: "Sisällön eheys",
            pointHitRate: "Keskeiset pisteet hittihus",
            emotionNegativeRate: "Tunteiden negatiivinen osuus",
            paraPhaSiaRate: "Epänormaalin puhenopeuden osuus",
            toneOvermuchRate: "Liian suuri osuus",
            maximumScore: "Korkein pisteet",
            highestScoreResult: "Korkeimmat tulokset",
            state: "Tilanne",
            averageDurationmin: "Keskimääräinen kesto (min)",
            lately: "Viime aikoina:",
            time: "Aika",
            operation: "Toiminto",
            viewDetails: "Näytä tiedot",
            wrongQuestionSet: "Väärä ongelma asetettu",
            taskName: "Tehtävän nimi",
            completeRequirements: "Täydelliset vaatimukset",
            publishTo: "Julkaistaan objektit",
            taskTime: "Tehtävän aika",
            fractionalComposition: "Jakeen koostumus",
            totalScore: "Pisteet yhteensä",
            branch: "Pisteet",
            Script: "Hyvä on.",
            scoreEmotion: "Tunteet",
            fractionExpression: "Sub-ilmaista",
            points: "Pisteet)",
            implementationRate: "Osallistumisasteet",
            numberOfExecutors: "Osallistujien lukumäärä",
            numberOfPeopleNotExecuted: "Osallistumattomien lukumäärä",
            completionRate: "Täytäntöönpanoaste",
            numberOfCompletedPersonnel: "Valmistettujen määrä",
            numberOfUnfinishedPersonnel: "Keskeytymättömien lukumäärä",
            complianceRate: "Vaatimustenmukaisuus",
            numberOfQualifiedPersonnel: "Saavutuksen lukumäärä",
            numberOfNonCompliantIndividuals:
              "Niiden henkilöiden määrä, jotka eivät täytä standardia",
            excellentRate: "Erinomainen korko",
            participateCompleteRate: "Osallistumisen loppuunsaattaminen",
            numberOfOutstandingIndividuals: "Huomattavat numerot",
            numberOfNonOutstandingIndividuals: "Ei-Erinomainen luku",
            export: "Vienti",
            taskData: "Tehtävätiedot",
            resultAnalysis: "Tulosten analyysi",
            scoreDetails: "Pisteet...",
            dataOverview: "Tietojen yleiskatsaus",
            taskDetail: "Tehtävän tiedot",
            scriptAnalysis: "Puheanalyysi",
            flowAnalysis: "Prosessin analyysi",
            expressionAnalysis: "Lausekeanalyysi",
            emotionAnalysis: "Tunteiden analyysi",
            byTime: "Ajan mitan näkymä",
            byNumber: "Asteen mitanäkymä",
            personData: "Henkilöstön tilastot",
            numberData: "Yksityiskohtainen tietue",
            count: "Määrä kertaa",
            person: "Henkilömäärän mukaan",
            reachTimes: "Lukemat",
            outOfReachTimes: "Saavuttamattomat ajat",
            seeOutOfReach: "Napsauta näyttääksesi tavoitteeta",
            accumulative: "Kumulatiivinen",
            script: "Sanat",
            times: "Ajani",
            sentence: "Tuomio",
            expressionAverage: "Keskimääräinen lausekepisteet",
            modalAnalysis: "Modaalihiukkasten analyysi",
            total: "Yhteensä",
            muchModalAnalysis: "Liian monta modaalista sanaa",
            normal: "Normaali",
            speedAnalysis: "Puheen nopeuden analyysi",
            speedDistribution: "Puhenopeuden jakautuminen",
            speakingSpeed: "Puhenopeus",
            normalSpeak: "Normaali puheopet",
            tooFastSpeak: "Nopea",
            tooSlowlySpeak: "Hidas nopeus",
            violationWord: "Sanojen rikkominen",
            violationWordAnalysis: "Rikkomisen sanojen analysointi",
            noData: "Ei ei",
            emotionalScore: "Tunteiden pisteet",
            emotionalDistribution: "Tunteiden jakautuminen",
            negativeEmotion: "Kielteinen tunne.",
            normalEmotion: "Normaali tunteet",
            getDetail: "Näytä tiedot",
            barPattern: "Palkinäkymä",
            timeDimension: "Aikanäkymä",
            timesDimension: "Asteen mitanäkymä",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Keskimäärä",
                  averageScoreOfScript: "Keskimääräinen puhepisteet",
                  averageEmotionalScore:
                    "Keskimääräinen emotionaalinen pisteet",
                  expressionAverageScore: "Express keskiarvo",
                  processAverageScore: "Prosessin keskimääräinen pistemäärä",
                  accumulatedDuration: "Kumulatiivinen kesto",
                  accumulatedTimes: "Kumulatiiviset ajat",
                  participationComplianceRate: "Osallistumisasteet",
                  participationRateForAchievingExcellence: "Osallistumisasteet",
                  branch: "Pisteet",
                  numberOfPeople: "Henkilöiden lukumäärä",
                  averageScoreOfExpression: "Keskimääräinen lausekepisteet",
                  people: "Ihmiset",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Kielteinen tunne." },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Keskimääräinen puhepisteet",
                  branch: "Pisteet",
                },
                useSummaryDetail: { keyHitRate: "Keskeiset pisteet hittihus" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Älykkäät arviot",
            pleaseImproveTheConfiguration:
              "Ole hyvä ja täydellinen kokoonpano.",
            examResults: "Testitulokset",
            showTheResultsAfterEachExam:
              "Näytä tulokset jokaisen tentin jälkeen.",
            showTheResultsAfterTheLastExam:
              "Näytä tulokset viimeisen tentin jälkeen.",
            doNotDisplayResults: "Älä näytä tuloksia",
            resultDisplay: "Tulokset",
            score: "Pisteet",
            scoringResults: "Tulokset",
            allowViewingScoreDetails: "Salli pistemäärän tiedot",
            viewCanvasConfiguration: "Näytä kankaan asetukset",
            synchronizationFailedProcessNodeHasChanged:
              "Synkronointi epäonnistui, prosessin solmu on luonut muutoksia",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Synkronointi on onnistunut, napsauta Julkaise päivittääksesi kurssin",
            retrievedTheLatestCanvasConfiguration:
              "Viimeisin kankaan asetus vedetty",
            synchronizeCanvasConfiguration: "Synkronoi kankaan asetukset",
            scoringMethod: "Pisteysmenetelmä",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Pisteet, virtaus {0} pisteet, puhetaidot {1} pisteet, tunteet {2} pisteet, nopeus {3} pistettä)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Huippuosaamisen tason on oltava korkeampi kuin vaatimustenmukaisuuden vaatimus.",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Pisteysperusteiden on oltava enemmän kuin 0 prosenttia ja enintään 100:aa",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Pisteytyskriteerien on oltava suurempi kuin 0 pistettä ja alle {0} pisteitä.",
            doRatingsRequireManualReview:
              "Tarvitseeko pisteytys manuaalisen arvioinnin",
            allocationStrategy: "Jakopolitiikka",
            pleaseSelectTheAssignedPersonnel: "Valitse määrätty henkilö.",
            pleaseSelectADesignatedPerson: "Valitse nimetty henkilö.",
            timeLimitForSingleQuestionAnswering: "Yhden kysymyksen vastaukseen",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Julkaise uusille työntekijöille oletuksena",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Uudelleen pisteytys osoittaa, että pisteet sovitetaan uusimman kankaan pisteytyssääntöjen mukaisesti. Nykyinen tehtävä on tarkistettu pisteytystiedot manuaalisesti. Haluatko korvata tuloksen?",
            notCovered: "Ei kuulumattomat",
            cover: "Kansit",
            participationSituation: "Osallistuminen",
            numberOfParticipants: "Osallistujien lukumäärä",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Yhteenveto tehtävälle valittujen harjoitusobjektien määrä",
            sumOfTheNumberOfParticipantsInTheTask:
              "Yhteenveto tehtävään osallistuneiden henkilöiden määrä",
            numberOfCompletedPersonnel: "Valmistettujen määrä",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Yhteenveto niiden henkilöiden määrä, jotka täyttävät tehtävän suorittamista koskevat vaatimukset",
            numberOfQualifiedPersonnel: "Saavutuksen lukumäärä",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Yhteenveto niiden henkilöiden määrä, jotka täyttävät tehtävän tulokset",
            excellentPersonnel: "Huomattavat numerot",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Yhteenveto määrä ihmisiä, jotka täyttävät tehtävän erinomainen pisteen",
            participationRate: "Osallistumisasteet",
            numberOfParticipantsNumberOfParticipants: "Osallistujien lukumäärä",
            numberOfCompletedNumberOfParticipants:
              "Täytettyjen / Osallistujien lukumäärä",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Osallistujien lukumäärä",
            excellentNumbernumberOfParticipants: "Osallistujien lukumäärä",
            participationCompletionRate: "Osallistumisen loppuunsaattaminen",
            numberOfCompletedParticipated: "Valmistuvien/osallistuvien määrä",
            participationComplianceRate: "Osallistumisasteet",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Saavutuksen/osallistumisen määrä",
            participationRateForAchievingExcellence: "Osallistumisasteet",
            accumulatedDuration: "Kumulatiivinen kesto",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Osallistu tehtävän kestoon ja summan",
            averageDuration: "Keskimääräinen kesto",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Osallistujien kokonaiskesto / osallistujien kokonaismäärä",
            accumulatedTimes: "Kumulatiiviset ajat",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Yhteenveto tehtävään osallistujien määrä",
            averageScore: "Keskimäärä",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Tehtävään osallistuvan henkilöstön keskimääräinen pisteet",
            maximumScore: "Korkein pisteet",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Tehtävään osallistuvan henkilöstön suurin pisteet",
            averageScoreOfScript: "Keskimääräinen puhepisteet",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Opiskelija puhepisteet summa/Yhteensä osallistumisen ajat",
            totalScoreOfScript: "Puheen kokonaispisteet",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Tämän tehtävän kohdalla asetettu puhepistemäärä yhteensä",
            processAverageScore: "Prosessin keskimääräinen pistemäärä",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Opiskelijaprosessin pistemäärä/ sitoumusten kokonaismäärä",
            totalProcessScore: "Prosessin kokonaispisteet",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Tehtävässä asetetun prosessin kokonaispisteet",
            averageEmotionalScore: "Keskimääräinen emotionaalinen pisteet",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Harjoittelijan tunteiden pisteet / sitoumusten kokonaismäärä",
            totalEmotionalScore: "Tunteiden kokonaispisteet",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Tässä tehtävässä asetettu tunneperäinen pisteet",
            averageScoreOfExpression: "Keskimääräinen lausekepisteet",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Opiskelijan ilmaisun pisteet / sitoumusten kokonaismäärä",
            totalScoreOfExpression: "Lausekkeen kokonaispisteet",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Tässä tehtävässä asetettu lausekkeen kokonaispisteet",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Aloita suorittaa uudelleen pisteytys, voit hypätä tehtäväkeskukseen nähdäksesi edistystä.",
            reRating: "Uudelleen tulostus",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Uudelleen pisteytys osoittaa, että pisteet sovitetaan uusimman kankaan pisteytyssääntöjen mukaan ja nykyinen pisteytys korvataan. ..",
            releaseTime: "Vapautumisaika",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Tarkistettu",
              pendingReview: "Tarkistetaan uudelleen",
              enterKeywordSearch: "Syötä hakuavat avainsanat",
            },
            pageRightTable: {
              index: {
                employeeName: "Työntekijän nimi",
                systemRating: "Järjestelmän pisteet",
                scoringResults: "Tulokset",
                duration: "Voimassaoloaika",
                time: "Aika",
                state: "Tilanne",
                whetherToModifyTheRating: "Oikea pistemäärä",
                finalScore: "Lopullinen pisteet",
                reviewedBy: "Arvostelin",
                operation: "Toiminto",
                viewScoreDetails: "Näytä pistemäärän yksityiskohdat",
                viewStatus: "Näytä tila",
                whole: "Kaikki",
                correctionStatus: "Oikaisun tila",
                completeReview: "Täydellinen arviointi",
                tips: "Kysy",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Harjoittelijoiden lopullisiin tuloksiin sovelletaan nykyisiä lopputarkastelun tuloksia. Vahvistakaa.",
              },
            },
          },
          index: { manualReview: "Käsin uudelleentarkastelu" },
        },
        review: {
          index: {
            employeeName: "Työntekijän nimi",
            score: "Pisteet",
            scoringResults: "Tulokset",
            duration: "Voimassaoloaika",
            taskName: "Tehtävän nimi",
            numberOfAppealScripts: "Väitteiden määrä",
            appealTime: "Muutoksenhakuaika",
            processStatus: "Prosessin tila",
            scoreAfterReview: "Pisteet arvioinnin jälkeen",
            reviewedBy: "Arvostelin",
            operation: "Toiminto",
            viewScoreStatus: "Näytä pisteet",
            whole: "Kaikki",
            manualReview: "Käsin uudelleentarkastelu",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Tehtävätiedot",
            implementationRate: "Täytäntöönpanoaste",
            numberOfExecutors: "Toteutuksen numero",
            numberOfPeopleNotExecuted: "Leikkaamattomien määrä",
            completionRate: "Täytäntöönpanoaste",
            numberOfCompletedPersonnel: "Valmistettujen määrä",
            numberOfUnfinishedPersonnel: "Keskeytymättömien lukumäärä",
            complianceRate: "Vaatimustenmukaisuus",
            numberOfQualifiedPersonnel: "Saavutuksen lukumäärä",
            numberOfNonCompliantIndividuals:
              "Niiden henkilöiden määrä, jotka eivät täytä standardia",
            excellentRate: "Erinomainen korko",
            numberOfOutstandingIndividuals: "Huomattavat numerot",
            numberOfNonOutstandingIndividuals: "Ei-Erinomainen luku",
            examTaskData: "Tenttitehtävätiedot",
            published: "Julkaistut",
            courses: "Kurssi",
            courseData: "Kurssitiedot",
            practiceTasks: "Harjoittelu",
            examTasks: "Tarkastustehtävät",
          },
          practiceSummary: {
            practiceSummary: "Käytännön yhteenveto",
            numberOfPracticeTasks: "Käytännön tehtävien lukumäärä",
            numberOfExercises: "Harjoitusten lukumäärä",
            accumulatedPracticeDuration: "Käytännön kumulatiivinen kesto",
            complianceRate: "Vaatimustenmukaisuus",
            excellentRate: "Erinomainen korko",
            ranking: "Sijoitus:",
          },
          rankingList: {
            numberOfExercises: "Harjoitusten lukumäärä",
            exerciseDuration: "Liikunnan kesto",
            complianceRate: "Vaatimustenmukaisuus",
            excellentRate: "Erinomainen korko",
            diligenceChart: "Tarkkailuettelo",
            excellentList: "Erinomainen luettelo",
            ranking: "Sijoitusjärjestelmä",
            fullName: "Nimi",
            i: "Minä...",
          },
          taskCard: {
            end: "Määräaika:",
            noDeadline: "Ei määräaikaa",
            second: "Ajani",
            branch: "Pisteet",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Ei tehtäviä",
            completed: "Valmistui",
          },
          filter: { employeeDepartment: "Työntekijäosasto" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Vienti on onnistunut, siirry tehtäväkeskukseen nähdäksesi",
              export: "Vienti",
            },
          },
          filterConfig: {
            dropdown: { custom: "Oma" },
            modal: {
              updateSuccessful: "Päivitys onnistui",
              successfullySaved: "Tallenna onnistuneesti",
              setAsACommonReport: "Yhteisiä raportteja",
              updateCurrentReport: "Päivitä nykyinen raportti",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Keskimäärä",
                  averageScoreOfScript: "Keskimääräinen puhepisteet",
                  averageEmotionalScore:
                    "Keskimääräinen emotionaalinen pisteet",
                  expressionAverageScore: "Express keskiarvo",
                  processAverageScore: "Prosessin keskimääräinen pistemäärä",
                  branch: "Pisteet",
                },
              },
              comprehensiveEvaluation: { const: { data: "Tiedot" } },
              doneStatus: {
                index: {
                  practice: "Liikunta",
                  examination: "Tarkastelu",
                  executed: "Suorita",
                  unexecuted: "Ei suoritettu",
                  accumulatedExamDuration: "Kumulatiivinen testin kesto",
                  examFrequency: "Kokeiden lukumäärä",
                },
              },
              filter: {
                index: {
                  practice: "Liikunta",
                  examination: "Tarkastelu",
                  pleaseCompleteTheRequiredFields: "Täytä vaaditut kohteet",
                  staff: "Työntekijä",
                  taskType: "Tehtävätyyppi",
                  pleaseSelect: "Valitse:",
                  taskName: "Tehtävän nimi",
                },
              },
            },
            index: {
              completionStatus: "Täydentämisen tila",
              comprehensiveEvaluation: "Kattava arviointi",
              abilityGrowthTrend: "Kapasiteetin kasvun trendi",
              errorProneAnalysis: "Virheelle altistuva pisteen analyysi",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Aktiivinen lukumäärä",
            accumulatedPracticeDuration: "Käytännön kumulatiivinen kesto",
            averagePracticeDuration: "Keskimääräinen liikuntaaika",
            people: "Ihmiset",
            numberOfPracticeTasks: "Käytännön tehtävien lukumäärä",
            numberOfExercises: "Harjoitusten lukumäärä",
            exerciseDuration: "Liikunnan kesto",
            second: "Ajani",
            avgScore: "Keskimäärä",
            speechCraftAvgScore: "Keskimääräinen puhepisteet",
            emotionAvgScore: "Keskimääräinen emotionaalinen pisteet",
            speedAvgScore: "Express keskiarvo",
            flowAvgScore: "Prosessin keskimääräinen pistemäärä",
            practiceCostTime: "Kumulatiivinen kesto",
            totalPracticeTimes: "Kumulatiiviset ajat",
            participateCommitRate: "Osallistumisasteet",
            participateExcellentRate: "Osallistumisasteet",
          },
        },
        index: {
          employeeProfile: "Työntekijän muotokuvat",
          overview: "Yleiskatsaus",
          personalSignage: "Henkilökohtainen Kanbanin",
          ongoingExercises: "Käynnissä oleva liikunta",
          ongoingExams: "Käynnissä oleva tarkastelu",
          practiceTaskProgress: "Tehtävän eteneminen",
          examTaskProgress: "Testaustehtävän eteneminen",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Valitse konseptisana.",
              sessionDetectionSettings: "Istunnon tunnistusasetukset",
              successfullySaved: "Tallenna onnistuneesti",
              abnormalSpeechSpeed: "Epänormaali puheopeus",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Käyttäjän määrittelemä puheennopeuden havaitsemistandardi tuetaan. Tuloksena on normaali puhe, liian nopea puheopeus ja liian hidas puhenopeus. Kun puhepuhe on epänormaali (liian nopea/liian hidas), pisteet pienennetään pisteytyssääntöjen mukaisesti.",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Nopeus liian nopea: yli minuutti",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Sana, liian hidas: vähemmän kuin minuutissa",
              charactersIgnoringLessThan: "Sanoja, huomioi vähempä",
              aSentenceOfWords: "Sanojen lauseet",
              excessiveModalParticles: "Liian monta modaalista sanaa",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Tukea mukautettu sävy sanoja, ja asettaa sääntöjä liian monta sävy sanoja. Kun liikaa liikennemuotojen sanoja on liian paljon, pisteet pienennetään pisteytyssääntöjen mukaisesti.",
              theNumberOfRepetitionsIs: "Toistojen määrä on:",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "Toisen kerran päätetään, että liikaa liikennemuotojen sanoja on liian paljon.",
              definitionOfSensitiveViolations: "Herkkä rikkomus määritelmä",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Voit muokata sanakirjaa laittomien sanojen havaitsemiseksi, näkyykö laittomia sanoja lauseessa. Kun on olemassa laiton sana, pisteet pienennetään pisteytyssääntöjen mukaisesti.",
              violationWords: "Rikkomussanat:",
              emotionalDetection: "Tunteiden havaitseminen",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Järjestelmän tunnemallin vuoropuhelun mukaan - Tunteiden testi katsotaan normaali tunteeksi ja negatiiviseksi tunneeksi (mukaan lukien negatiivinen, vihainen ja valittava. Kun on negatiivinen tunne, pisteet pienennetään pisteytyssääntöjen mukaisesti.",
              preserve: "Tallenna",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Luokitusmita",
              ratingPoints: "Pisteytyspisteet",
              explain: "Kuvaus",
              type: "Tyyppi",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Satunnaiset tiedot" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span-tyyli = "margin- vasemmalle: 8px > · Standard Speech kumulatiivinen',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Sana, opiskelija osui sanoja.",
                  aWordspan: "Sana helvetti>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Ajattelua / ̈span tyyli = "margin-vasen: 8px> · Hidas nopeus',
                  timesspan: "_BAR_",
                  tooManyModalParticles:
                    '<Span tyyli = "margin vasemmalle: 8px > · Liian monta modaalinen sanat',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Tulos on." },
                  item: {
                    violationOnceReduced: "1 rikkomus miinus.",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Epänormaali nopeus (mukaan lukien liian nopeus ja liian hidas nopeus) 1 lasku",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Liian monta modaalista sanaa 1 miinus.",
                    oneNegativeDecreaseInEmotions:
                      "Negatiivinen tunne miinus 1",
                    branch: "Pisteet",
                  },
                  wrap: { name: "Nimi", preserve: "Tallenna" },
                },
              },
              index: {
                complete: "Täydellinen sisältö",
                keyHit: "Tärkeimmät osumat",
                accurateFollowupReading: "Tarkka lukeminen",
                languageNorm: "Kielietelmät",
                speechSpeed: "Puhenopeus",
                modalParticles: "Modaalinen sanan",
                emotionallyPositive: "Tunteellisesti positiivinen.",
                scoringModel: "Pisteytysmalli",
                successfullyModified: "Muutos onnistui",
                newSuccessfullyCreated: "Uusi menestys",
              },
            },
            index: {
              scoringModel: "Pisteytysmalli",
              newModel: "Uusi malli",
              model: "Malli:",
              type: "Tyyppi",
              operation: "Toiminto",
              preview: "Esikatselue",
              edit: "Muokkaa",
            },
          },
          index: {
            scoringModel: "Pisteytysmalli",
            ratingDimension: "Luokitusmita",
            sessionDetectionSettings: "Istunnon tunnistusasetukset",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Valitse:",
                hit: "Osuminen",
                misses: "Osui",
              },
            },
          },
          index: {
            duration: "Voimassaoloaika",
            processScore: "Prosessin pisteet",
            branch: "Pisteet",
            contentCompleteness: "Sisällön eheys",
            scriptScore: "Puhepisteet",
            keyHitRate: "Keskeiset pisteet hittihus",
            emotionalScore: "Tunteiden pisteet",
            negativeProportionOfEmotions: "Tunteiden negatiivinen osuus",
            expressionScore: "Lausekkeen pistemäärä",
            theProportionOfAbnormalSpeechSpeed:
              "Epänormaalin puhenopeuden osuus",
            excessiveProportionOfModalParticles: "Liian suuri osuus",
            learningRecordData: "Tietojen oppiminen",
            staff: "Työntekijä",
            curriculum: "Opetussuunnitelma",
            mainPoints: "Avainkohdat",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Taulukka",
                        lineChart: "Rivi kaavio",
                        barChart: "Histogrammi",
                        pieChart: "Piirakkakaavio",
                      },
                      index: { switchingTypes: "Vaihteen tyyppi" },
                    },
                  },
                  index: {
                    saveSuccessful: "Tallenna onnistuneesti",
                    editChart: "Muokkaa kaavioa",
                    chartName: "Kaavion nimi",
                    deleteSuccessful: "Poistettiin onnistuneesti",
                    refresh: "Päivitä",
                    export: "Vienti",
                    areYouSureToDeleteIt: "Haluatko varmasti poistaa?",
                    delete: "Poistaa",
                    viewFilteringCriteria: "Näytä suodin kriteerit",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Vienti on onnistunut, siirry tehtäväkeskukseen nähdäksesi",
              },
            },
            header: {
              index: {
                saveSuccessful: "Tallenna onnistuneesti",
                deleteSuccessful: "Poistettiin onnistuneesti",
                editReport: "Muokkaa raporttia",
                reportName: "Raportin nimi",
                refresh: "Päivitä",
                areYouSureToDeleteIt: "Haluatko varmasti poistaa?",
                delete: "Poistaa",
                exportToExcel: "Vie Excelin",
                exportSnapshot: "Vie kuvakuvaus",
                export: "Vienti",
                authority: "Oikeudet",
                permissionSettings: "Luvan asetukset",
                reportVisiblePermissions: "Raportoi näkyvät oikeudet",
                visibleToEveryone: "Näkyvissä kaikille",
                onlyVisibleToOneself: "Vain itselleen näkyvissä.",
                designatedPersonnelVisible: "Näkyvä henkilö.",
                pleaseSelect: "Valitse:",
                reportDataPermissions: "Raportoi tiedonsioikeus",
                restrictedByUserPermissions: "Käyttäjän oikeudet",
                visiblePeopleAreTheSame: "Näkyvät ihmiset ovat samanlaisia.",
              },
            },
            titleFooter: {
              index: {
                addReport: "Uusi kertomus",
                reportName: "Raportin nimi",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Vienti on onnistunut, siirry tehtäväkeskukseen nähdäksesi",
            dataQueryInProgress: "Datakysely...",
            customKanban: "Oma Kanbanin",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Taulukka",
                lineChart: "Rivi kaavio",
                barChart: "Histogrammi",
                pieChart: "Piirakkakaavio",
              },
              index: {
                successfullyAdded: "Lisätty onnistuneesti",
                viewSQL: "Näkymä SQL",
                export: "Vienti",
                addToReport: "Lisää raporttiin",
              },
            },
            refer: {
              hooks: {
                data: "Tiedot",
                interrogativeSentence: "Kysymyksiä",
                historicalQuery: "Historiallinen kysely",
              },
              index: { copySuccessful: "Kopioi menestys", copy: "Kopioi" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Ei vakio",
                        meetingStandards: "Standardi",
                        excellent: "Loistavaa",
                        notStartedYet: "Ei käynnistynyt",
                        inProgress: "Käynnissä olevat toimet",
                        completed: "Valmistui",
                        incomplete: "Viimeistelemättömät",
                      },
                    },
                  },
                  const: {
                    taskName: "Tehtävän nimi",
                    department: "Osasto",
                    studentName: "Opiskelijan nimi",
                    scoringResults: "Tulokset",
                    taskCompletionStatus: "Tehtävän suorittamisen tila",
                  },
                  index: { pleaseSelectADimension: "Valitse ulottuvuus" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Syötä @ avataksesi luotilaatikkon ulottuvuuden valintaa varten",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Syötä kyselyilmoitus suoraan kyselykseen",
                query: "Kysely",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Vienti on onnistunut, siirry tehtäväkeskukseen nähdäksesi",
            dataQueryInProgress: "Datakysely...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Avainsana haku",
                previous: "Edellinen",
                next: "Seuraava",
                theObjectThatTriggersTheScrollingEventIs:
                  "Vieritystapahtuman käynnistävä objekti",
                userScrolling: "Käyttäjän vierintä",
              },
            },
            summary: {
              index: {
                reminder: "Muistutus:",
                edit: "Muokkaa",
                name: "Nimi",
                hintLanguage: "Kysy",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Näkökulmasta poistaminen" },
            },
            outline: {
              index: {
                numberOfKeywords: "Avainsanojen lukumäärä",
                speaker: "Kaiutin",
                speechDuration: "Puheen kesto",
                proportionOfSpeaking: "Puhesuhde",
                maximumSpeechDuration: "Puheen enimmäispituus",
                numberOfQuestions: "Kysymysten lukumäärä",
                averageSpeechSpeed: "Keskimääräinen nopeus",
                conversationalActions: "Istunnon toiminta",
                contentSummary: "Sisällön yhteenveto",
                generateSummary: "Luo yhteenveto",
                keywordCloud: "Asiasanat",
              },
            },
          },
          index: {
            outline: "Yhteenveto",
            viewpoint: "LAUSUNTO",
            contentSummary: "Sisällön yhteenveto",
            viewpointExtraction: "Näkökulmasta poistaminen",
            summaryOfKeyPoints: "Yhteenveto keskeisistä kohdista",
            videoAnalysis: "Videoanalyysi",
          },
        },
        list: {
          index: {
            delete: "Poistaa",
            uploadSuccessful: "Lähetä onnistuneen",
            videoList: "Videoluettelo",
            uploadVideo: "Lähetä videoa",
            videoName: "Videon nimi",
            uploadTime: "Lähetysaika",
            endTime: "Loppuaika",
            analyzeProgress: "Analysoi edistymistä",
            haveInHand: "Käynnissä olevat toimet",
            completed: "Valmistui",
            fail: "Epäonnistui",
            toBegin: "Aloitetaan.",
            operation: "Toiminto",
            see: "Näytä",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Lisää työaika" },
        },
      },
      index: {
        businessWorkingHours: "Liiketoiminnan työaikat",
        newlyBuild: "Uusi:",
        name: "Nimi",
        describe: "Kuvaus",
        operation: "Toiminto",
        edit: "Muokkaa",
        delete: "Poistaa",
        editBusinessWorkingHours: "Muokkaa työtunteja",
        newBusinessWorkingHours: "Uudet liiketoiminnan työaikat",
        workingHours: "Työaika",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Älykäs uuttaminen",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Haluatko varmasti perua? Peruuttaminen ei ole mahdollista.",
        },
      },
    },
    accountManagement: {
      securitySetting: "Turvallisuusasetukset",
      accountManagement: "Tilinhallinta",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Suuntaan",
            switchToIPAddressMode: "Vaihda IP-osoitteen tilaan",
            switchToIPSegmentMode: "Vaihda IP- segmenttiin tilaan",
          },
        },
      },
      index: {
        saveSuccessful: "Tallenna onnistuneesti",
        pleaseImproveTheConfiguration: "Ole hyvä ja täydellinen kokoonpano.",
        securitySetting: "Turvallisuusasetukset",
        employeeLoginIPRestrictions: "Työntekijän kirjautumisen IP-rajoitukset",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Rajoita IP-valikoimaa, johon työntekijät voivat kirjautua. Kirjautuminen ei ole sallittua alueen ulkopuolella. Voimassa vain verkkosivustoissa, mobiilisovelluksia ei ole rajoitettu ja hallinnoijia ei rajoiteta.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Kun lisätään tai muutetaan, syötä ulkoisen verkon IP-segmentti (sama C-segmentti) tai kiinteän ulkoisen verkon IP-osoite.",
        allowLoginIPRange: "Sisään kirjautumisen sallittu IP-alue",
        preserve: "Tallenna",
      },
    },
    coachAdminEntry: { speechRecognition: "Puheen tunnistaminen" },
    billingCenter: {
      template: {
        details: "Yksityiskohtaiset tiedot",
        languageType: "Kielityyppi",
        aSREngine: "ASR-moottori",
        voiceQualityQuota: "Puhelinlaadukiintiö",
        realtimeData: "Reaaliaikaiset tiedot",
        calculatedByDay: "Laskettu päiväksi",
      },
      component: {
        aSRName: "ASR nimi",
        language: "Kieli",
        remainingQuota: "Jäljellä oleva kiintiö",
        hours: "Tunnit",
        usedQuota: "Käytetty kiintiö",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Toiminto tallennettu onnistuneesti!",
        deletedSuccessfully: "Poistettiin onnistuneesti!",
        pleaseSelectLanguage: "Valitse kieli",
        pleaseConfigureRules: "Aseta säännöt",
        updateSuccessful: "Päivitys onnistunut!",
        operationSuccessful: "Operaatio onnistui!",
      },
      template: {
        aSRLanguage: "ASR-kiis",
        pleaseSelect: "Valitse:",
        model: "Malli:",
        pleaseSelectLanguage: "Valitse kieli",
        delete: "Poistaa",
        newASRModel: "Uusi ASR-malli",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Kilpailun analyysi" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Sähköisen kaupankäynnin katsaussuodatin",
        allComments: "Kaikki huomautukset",
        ecommerceReviewManagement:
          "Sähköisen kaupankäynnin arvioinnin hallinta",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Sosiaalinen media katsaus suodatini",
        allComments: "Kaikki huomautukset",
        socialMediaReviewManagement: "Sosiaalisen median katsaus",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Kilpailun analyysi",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Nimi",
        nickname: "Lempinimi",
        name: "Nimi",
        email: "Tilinumero (sähköposti)",
        mobile: "Puhelin",
        agentId: "Työn",
        position: "Tiedosto",
        role: "Rooli",
        actions: "Toiminto",
        createUser: "Uusi työntekijä",
        editUser: "Muokkaa työntekijää",
        account: "Tilin numero",
        originPassword: "Alkuperäinen salasana:",
        newPassword: "Uusi salasana:",
        resumePassword: "Vahvista salasanaa",
        userGroup: "Työntekijät",
        authorizationGroup: "Lupien ryhmä",
        resetPassword: "Nollaa salasanaa",
      },
      userGroups: {
        id: "ID",
        name: "Nimi",
        userCount: "Työntekijöiden lukumäärä",
        actions: "Toiminto",
        createUserGroup: "Lisää työntekijäryhmä",
        editUserGroup: "Muokkaa työntekijäryhmää",
        member: "Jäsenet",
      },
      role: {
        id: "ID",
        name: "Nimi",
        actions: "Toiminto",
        all: "Kaikki",
        taskName: "Tehtävät",
        readTask: "Tehtävänäkymä",
        manageTemplate: "Pohjanhallinta",
        manualInspection: "Käsin uudelleentarkastelu",
        readResultAll: "Tuloksenäkymä",
        readResultPersonal: "Tuloksenäkymä (yksilöllinen)",
        readProcess: "Näytä laatutarkastusprosessin",
        readReport: "Tilastot ja raportit",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Päivämäärä",
        taskName: "Nimi",
        size: "Koko",
        status: "Tilanne",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Nimi",
            remark: "Kuvaus",
            actions: "Toiminto",
          },
          checkPoint: {
            name: "Nimi",
            remark: "Kuvaus",
            type: "Tyyppi",
            gradeType: "Arviointimenetelmä",
            conditionLogic: "Säännöt",
            predeterminedScore: "Pisteet",
            weight: "Paino",
            status: "Tilanne",
            actions: "Toiminto",
            switchOn: "Ota käyttöön",
            switchOff: "Poista käytöstä",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Aika",
            customer: "Asiakasta",
            customerPhone: "Asiakaspuhelin",
            callType: "Kutsutyyppi",
            source: "Lähde",
            voiceRecording: "Puhelujen tallentaminen",
            graded: "Luokitus",
            openingGreeting: "Tervehdysten avaaminen",
          },
          checkPointList: {
            name: "Laaduntarkastuspisteet",
            type: "Tyyppi",
            deductScore: "Fraktio",
            isHit: "Hit cases",
            status: "Uudelleentarkastelu",
            finalDeductScore: "Vahvista pisteiden vähennys",
            hit: "Osuminen",
            notHit: "Osui",
          },
        },
      },
    },
    custom: { keywords: "Avainsanat" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Järjestelmän asetukset" },
      systemIntegration: {
        text: "Järjestelmän integrointi",
        subTitle: {
          udesk: "Udesk asiakaspalvelujärjestelmän",
          udeskWhite: "Asiakaspalvelujärjestelmä",
          wechat: "Enterprise We chatt",
          taobao: "Taobao Qianniuu",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Laaduntarkastuskentän hallinta" },
      customerField: { text: "Asiakaskenttä" },
      speechRecognition: { text: "Puheen tunnistaminen" },
      user: { text: "Työntekijöiden johto" },
      userGroups: { text: "Työntekijäryhmän johto" },
      role: { text: "Roolihallinta" },
      dataMigration: { text: "Tietojen siirto" },
      taskCenter: { text: "Tehtäväkeskus" },
      recordUpload: { text: "Paikallinen tallentaminen" },
      anomalousData: { text: "Epänormaali tieto" },
      systemLog: { text: "Viestiloki" },
      msgCenter: { text: "Viestikeskus" },
      wechatDocking: { text: "Yrityksen mikro telakoitus" },
    },
    tasks: {
      homePage: { text: "Kotisivun" },
      workbench: { text: "Työpinen" },
      semanticIntelligence: { text: "Semanttinen älykkyys" },
      intelligentAnalysis: { text: "Älykäs analyysi" },
      intelligentLabel: { text: "Älykäs nimi" },
      admin: { text: "Hallinnointi" },
      callManage: { text: "Puhelun hallinta" },
      dialogueManage: { text: "Vuoropuhelu" },
      ticketManage: { text: "Työmääräyksen hallinnointi" },
      dataAnalysis: { text: "Tietojen analyysi" },
      customerService: { text: "Asiakaspalvelukeskus" },
      clientCenter: { text: "Asiakaskeskus" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Koulutuskeskus" },
      templates: { text: "Pohjanhallinta" },
      wechatQaTasks: { text: "Enterprise WeChat työpenki" },
      wechatQaSetting: { text: "Enterprise WeChat-analyysin asetukset" },
      wechatQaChartRecord: { text: "Enterprise We chat-istunto" },
      wechatStaffCenter: { text: "Yrityksen mikrohenkilöstökeskus" },
      callAnalysis: { text: "Keskustelun analyysi" },
      caseBaseFolder: { text: "Kirjasto" },
      coachTask: { text: "Mentorointitehtäviä" },
      teamAnalysis: { text: "Tiimianalyysi" },
      customer: { text: "Asiakaskeskus" },
      analysisEnable: { text: "Analyysin vaikutus" },
      analysisHelp: { text: "Apuanalyys" },
      analysisStudy: { text: "Oppimisen analyysi" },
      analysisReview: { text: "Yhdistelmälevyjen analyysi" },
      analysisTags: { text: "Älykäs label-analyysi" },
      analysisSentiment: { text: "Yleinen mielipide analyysi" },
      analysisBusiness: { text: "Liiketoiminnan analyysi" },
      groupTest: { text: "Ryhmätesti" },
    },
    navBar: {
      billingCenter: "Ohjekeskus",
      uploadDownload: "Lataa ja lataa keskusta",
      setting: "Järjestelmän asetukset",
    },
  },
  fix: {
    selectType: "Valitse tyyppi:",
    all: "Kaikki",
    call: "Puhelu",
    text: "Dialogi",
    wechat: "Enterprise We chat-istunto",
    businessTrendAnalysis: "Tason 1 luokitus liiketoiminnan trendi-analyysi",
    noEchart: "Ei tietoja kaaviolle",
    checkPointisRequired: "Säännön asetukset vaaditaan",
    voiceDownLoad: "Lataa tallentaminen",
    exportTxt: "Vie TXT- teksti",
    exportWord: "Vie sanatekstiName",
    sureReRole: "Kääntääkö vahvistus puhujan roolin?",
    sureRecover: "Vahvistaa korjauksen?",
    sureReAsr: "Vahvistatko uudelleen tunnistuksen?",
    reRole: "Käänteinen kaiutin",
    recover: "Virheen uudelleenkorjaus",
    reAsr: "Uudelleen tunnistaminen",
    recordingOperation: "Tallennustoiminto",
    onlySave30Day: "(30 päivää)",
    appealError: "Vetoa laatutarkastuspisteeseen ennen sen toimittamista.",
    exportToTaskCenter: "Onnistuneesti viety lähetys- ja latauskeskukseen.",
    caseBaseLimit: "Kirjasto ei voi ylittää 50:ta",
    unnamed: "Nimettömä",
    wordSpeed: "Sana/min",
    hit: "Osuminen",
    checkPoint: "Laaduntarkastuspisteet",
    realTimeAdditionTip1:
      "Reaaliaikainen lisäys: laatutarkastus alkaa välittömästi, kun tiedot saapuvat laaduntarkastusjärjestelmään.",
    realTimeAdditionTip2: "Ajoitus",
    null: "Ei ole.",
    collcetedProps: "Kokoelman kentät",
    editingNameNow: "Nimi muokataan parhaillaan",
    upToFourLevels: "Luokitus enintään neljä tasoa",
    default: "Oletus",
    nameCantBeNull: "Nimiä ei voi olla tyhjä",
    editingNow: "Toimintaa muokataan parhaillaan",
    nameError: "Väärä nimi",
    nameMaxLength8: "Nimen enimmäispituus on 8",
    callTime: "Puheaikaan",
    sentimentValue: "Asiakkaan tunteiden arvo",
    agentSentimentValue: "Asiakaspalvelun tunnearvo",
    sentimentAnalysis: "Tunteiden analyysi",
    associatedData: "Tietojen seurantatiedot",
    notifyTheOwner: "Ilmoita ryhmän omistajalle",
    notifyTheEmployee: "Ilmoita työntekijälle itse.",
    notifyTheOwnerTip:
      "Kun keskustelun tyyppi on ryhmächat, ryhmän omistaja ilmoitetaan.",
    customNotifyEmployee: "Ilmoituksen mukainen työntekijä",
    customNotifyEmployeeTip:
      "Jos laukaiseva henkilö on työntekijä, ilmoitetaan työntekijälle, jolle viesti kuuluu.",
    clickToLearnMore: "Klikkaa saadaksesi lisää tietoa",
    repeatMarkingTip:
      "Tämä merkintä on merkitty laatutarkastuspisteellä, eikä merkintää ole tarpeen toistaa.",
    successfulEvaluation: "Onnistumisen arviointi",
    evaluationFailed: "Arviointi epäonnistui",
    qualityInspectionScore: "Laaduntarkastuksen pisteet",
    semanticTags: "Semanttinen merkintä",
    smartTags: "Älykäs nimi",
    collect: "Kerää tietoa",
    appealRecord: "Huolestus",
    satisfy: "Tyytyväinen",
    notSatisfy: "Ei tyytymätty",
    appealPlaceHolder: "Ilmoittakaa valituksen syy",
    giveUp: "Hylätys",
    rejectReasonPlaceHolder: "Syy hylkäämiseen",
    reject: "Hylätty",
    changeRejectReason: "Tarkista hylkäämissyt",
    pass: "Jäsenvaltiot",
    revise: "Muutos",
    machineEvaluation: "Koneiden arviointi",
    poor: "Raukka.",
    excellent: "Hyvä.",
    moderate: "Yleinen:",
    mustMarked: "Tämä laatutarkastuspiste on merkittävä.",
    pleaseChoose: "Valitse:",
    manualEvaluation: "Käsin arviointi",
    qualityInspectionTemplate: "Sovellettava laatutarkastusmalli",
    collectionTemplate: "Sovellettava hankintamenettely",
    keywordsWordCloud: "Asiasanat",
    editCategoryLabel: "Muokkaa luokan nimiä",
    justCall: "Katso vain puhelua.",
    justConversation: "Katso vuoropuhelua.",
    batchOperation: "Erän toiminta",
    customerServicesGroup: "Asiakaspalveluryhmä",
    joinGroup: "Liity ryhmään:",
    removeGroup: "Siirrä ulos ryhmästä",
    enterInformationEntity: "Syötä tietokokonaisuuden sisältö",
    enterNotBeNull: "Syöte ei voi olla tyhjä",
    operationGroup: "Operaatioryhmä",
    customerServiceSelected: "Valittu asiakaspalvelut",
    callAndConversation: "Puhelu/keskustelu",
    conversation: "Dialogi",
    system: "Järjestelmä",
    customize: "Oma",
    effective: "Tehokas",
    invalid: "Virheellinen",
    successfulAndToTaskCenter:
      "Suoritus on onnistunut, mene tehtäväkeskukseen tarkistamaan",
    recalculationFailed: "Uudelleenlaskenta epäonnistui",
    selectRecalculatedIndicator: "Valitse indikaattori, jolla palautetaan.",
    source: "Valitse tietolähde",
    placeholder: "Anna avainsana.",
    cumulative: "Kumulatiivinen",
    average: "Keskimääräinen tyyppi",
    distribution: "Jakeluoikeus",
    originalDataCannotEmpty: "Raakatietoja ei voi olla tyhjä",
    inspection: "Laaduntarkastus",
    check: "Satunnaistarkastus",
    review: "Uudelleentarkastelu",
    draftSavedSuccessfully: "Luonnos tallennettu onnistuneesti",
    optimization: "Optimointi",
    agentName: "Asiakaspalvelun nimi",
    allTasks: "Kaikki laatutarkastustehtävät",
    datePickerSelect: "Valitse päivämäärä",
    callSelect: "Valitse valinta",
    taskSelect: "Valitse tehtävä",
    genReport: "Luo raportti",
    dataSource: "Tietolähde",
    callsTotal: "Puhelut yhteensä",
    intelligentQualityInspection: "Älykäs laatutarkastus",
    percentage: "Osuus",
    artificialSampling: "Manuaalinen näytteenotto.",
    appealNumber: "Kantelujen määrä",
    totalConversation: "Vuoropuhelun yhteensä",
    samplingReviewRate: "Pumpun tarkasteluaste",
    data: "Tiedot",
    inspectionResults: "Laaduntarkastusten tulokset",
    inspected: "Laaduntarkastus",
    totalScore: "Pisteet yhteensä",
    learnMore: "Näytä lisää",
    inspectionEffect: "Laaduntarkastusvaikutus",
    allTotal: "Yhteensä",
    manualInspection: "Käsin uudelleentarkastelu",
    artificialReview: "Käsin uudelleentarkastelu",
    approvalAmountAndRate: "Hyväksytty määrä ja hyväksynnän määrä",
    customerScore: "Asiakaspalvelupisteet",
    inspectionRecommendations: "Laatutarkastusehdotukset",
    saveDraft: "Tallenna luonno",
    historicalAdvice: "Historialliset neuvot",
    advicePlaceHolder: "Anna ehdotus...",
    upload: "Lähetä tiedosto",
    customAdd: "Lisää suodin",
    advancedConditions: "Lisäedellytykset",
    keyWord: "Asiasanat",
    containsAll: "Sisältää kaikki.",
    containsAny: "Sisältää mitä tahansa.",
    matchAny: "Täsmää kaikkia",
    matchAll: "Täsmää kaikkiin",
    smartTagsOne: "Älykäs nimi",
    smartTagsTwo: "Älykäs merkki II",
    season: "Kausi:",
    month: "Kuukaus",
    week: "Viikko",
    day: "Päivä.",
    hour: "Milloin",
    notMoreThan366: "Enintään 366 päivää.",
    propsCannotBeNull: "Joitakin kenttiä ei ole täytetty",
    other: "Muu",
    correlationCoefficient: "Korrelaatiokerroin",
    repetitionRate: "Toistonopeus",
    configurationItemsMoreThan20: "Lisää enintään 20 asetustiedostoa",
    conversionAnalysis: "Muuntamisanalyys",
    correlationAnalysis: "Korrelaatioanalyysi",
    successfulExportAndToTaskCenter:
      "Vienti on onnistunut, siirry tehtäväkeskukseen nähdäksesi",
    typeSelect: "Valitse tyyppi",
    exportExcel: "Vie Excelin",
    exportPng: "Vie png",
    noDataChart: "Ei tietoja kaaviolle",
    placeOperator: "Valitse älykäs nimi",
    placeAdvancedConditions: "Valitse lisävaatimukset",
    createAdvancedConditions: "Uusi kehittynyt suodin",
    moveUp: "Siirry ylöspäin",
    moveDown: "Siirry alas",
    moveLeft: "Siirry vasemmalle",
    moveRight: "Siirrä oikealle",
    statusChangeSuccess: "Tilaa muutettiin onnistuneesti",
    editSuccess: "Muutos onnistui",
    saveSuccess: "Tallenna onnistuneesti",
    firstPage: "Kotisivun",
    lastPage: "Päätäsivi",
    customerInfo: "Asiakastiedot",
    type: "Tyyppi",
    NumberTypeErrorMessage:
      'Numerotyypin kenttätietojen sisältö voi sisältää vain numeroita tai "."',
    taskStartPrompt: "Tehtävän avaamisvapöydö",
    startConfirm:
      "Napsauta vahvistuspainiketta avataksesi laatutarkastustehtävän",
    continueToSubmitWithoutEvaluation: "Jatka toimittamista ilman arviointia",
    rejectTip:
      "Muutostoimia ei havaittu. Hylkäätkö varmasti kaikki muutoksenhakut suoraan?",
    unmarkedSelfLearningTip:
      "Sinulla on merkitsemättömät itseoppimisen laadun tarkastuspisteet",
    markedSuccessfully: "Merkinnän menestys",
    markingFailed: "Merkintä epäonnistui",
    exceedingLength: "Yli pituus",
    uncategorized: "Luokittelemattomat",
    addSuccess: "Uusi menestys",
    deleteSuccess: "Poistettiin onnistuneesti",
    featureName: "Elementin nimi",
    informationEntity: "Tietoyksikkö",
    standardAnswer: "Vakiovastaso",
    keyElementAnswer: "Tärkeimmät vastaukset",
    generalStatement: "Yleinen lausuma (ei tarvitse pyytää)",
    generalQuestionsAndConfirmation: "Yleiset kysymykset ja vahvistus",
    specialQuestionsAndAnswers: "Erityiskysymyksiä ja vastauksia",
    agent: "Asiakaspalvelut",
    customer: "Asiakasta",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Keskeinen elementti semanttinen merkki- ja tietoyksikkö ei voi samaan aikaan olla tyhjiä",
    standardSentence: "Vakiolauseke",
    acquaintance: "Tuttu.",
    smartUePlaceholder: "Anna älykäs vuorovaikutus nimi",
    typeSelectPlaceholder: "Valitse tyyppi",
    keyElement: "Keskeiset tekijät",
    reQuerySementicTags: "Valitse semanttiset merkinnät",
    querySementicTags: "Valitse semanttinen nimi",
    QATestForm: "Kysymyksen ja vastauksen havaitsemislomake",
    selectNotBeNull: "Valintaa ei voi olla tyhjä",
    featureDetectionTarget: "Alkuaineen tunnistamisen kohde",
    categorized: "Luokittelu",
    effectiveness: "Tehokkuus",
    hideAdvancedSettings: "Piilota lisäasetukset",
    showAdvancedSettings: "Näytä lisäasetukset",
    generalStatementOnly: "YLEINEN LAUSUNTO",
    generalQuestions: "Yleinen epäilys",
    affirmativeExpression: "Myönteinen ilmaisu",
    negativeIntention: "Kielteinen aikomus",
    searchSementicTags: "Kysy semanttiset merkinnät",
    choiceQuestion: "Valikoiva kyselylauseen",
    selectiveAnswer: "Valintatyypin vastaus",
    sementicTagsPlaceholder: "Anna semanttinen merkintä sisältö",
    openSpecialQuestion: "Avoimet erityiskysymykset",
    openAnswer: "Avaa vastaus.",
    customQA: "Omat",
    questions: "Pyyntömenetelmä",
    dataPlaceholder: "Anna sisältö",
    reply: "Vastaa",
    notFindSuitableLabel: "Sopivaa etikettiä ei löytynyt.",
    addSemanticTag: "Lisää uusi semanttinen tagi",
    intelligentInteraction: "Älykäs vuorovaikutus",
    matching: "Täsmää",
    bout: "Ajani",
    keywordMatch: "Avainsana täsmään",
    addFilterConditions: "Lisää suodin tila",
    buttonStartCollectionTask:
      "Napsauta OK-painiketta aloittaaksesi kokoelman tehtävän",
    buttonStartTask: "Napsauta OK-painiketta avataksesi laatutarkastustehtävän",
    ruleConfigurationIsRequired: "Säännön asetukset vaaditaan",
    asrRunning: "ASR toteuttaminen",
    errorCorrectionExecution: "Virhekorjaus",
    emotionalVolumeRecognition: "Tunnevoimakkuuden tunnistaminen",
    dataPreprocessing: "Tietojen esikäsittely",
    inspectionExecution: "Laaduntarkastuksen suorittaminen",
    businessId: "Yrityksen tunniste",
    businessTime: "Työaika",
    createTime: "Luomisaikaan",
    abnormalReason: "Epänormaali syy",
    singleChat: "Yksittäinen keskustelu",
    groupChat: "Ryhmäkeskustelu",
    taskContent: "Tehtävän sisältö",
    submissionTime: "Lähetysaika",
    startTime: "Aloitusaika",
    endTime: "Loppuaika",
    runningTime: "Aikaa vievä toiminta",
    totalAmountOfData: "Tietojen määrä yhteensä",
    resultsOfThe: "Suorituksen tulokset",
    operator: "Toiminnanharjoittaja",
    taskDetails: "Tehtävän tiedot",
    executiveFunction: "Toimeenpaneva toiminta",
    qualityCheckTasks: "Laaduntarkastustehtävät",
    implementationDetails: "Suorituksen tiedot",
    conditionalRules: "Tilaa koskevat säännöt",
    smartCondition: "Älykkäät olosuhteet",
    goThis: "Siirry tähän.",
    pleaseMarkClassification: "Merkitkää luokittelu",
    advancedScreening: "Edistynyt suodatus",
    noDataToManipulate: "Ei käsiteltävää tietoa",
    concept: "Käsitteet sanat",
    checkPoint1: "Säännön asetukset",
    requiredFieldsCannotEmpty: "Vaadittua kohdetta ei voi olla tyhjä",
    rule: "Säännöt",
    afterFixUnit: "Sana",
    unmarkedSelfLearningPointsTip:
      "Sinulla on merkitsemättömät itseoppimisen laadun tarkastuspisteet",
    onlyReservedFor7Days: "(7 päivää)",
    downloadError: "Lataus epäonnistui",
    pause: "Tauo",
    continue: "Jatka",
    recalcScore: "Laske vain pisteet uudelleen (luokituksilla)",
    recalculatePointsAndScores:
      "Laaduntarkastuspisteet ja -pisteet lasketaan uudelleen",
    recalculatePointsAndScoresWithEmtry:
      "Laske uudelleen laatutarkastuspisteet ja -pisteet (laadun tarkastuspisteet ovat tyhjiä)",
    inProgress: "Käynnissä olevat toimet",
    toBeExecuted: "Toteutetaan.",
    executeImmediately: "Välitön toteutus",
    specialElements: "Erityiset tekijät",
    logicRule: "Logiikkasäännöt",
    every: "Useita osumia",
    hitRecall: "Paina palautussanaa",
    notHitRecall: "Jättänyt palautussanan",
    hitRecallRegular: "Hitti säännöllinen palautus",
    notHitRecallRegular: "Säännöllinen",
    hitRule: "Lyö säännöt",
    notHitRule: "Sääntö epäonnistui",
    hitSimilarSentence: "Lyö samanlaisia lauseita.",
    notHitSimilarSentence: "Vastaavat lauseet jääneet",
    hitExcludeSimilarSentences:
      "Osuma, jotta voidaan sulkea pois vastaavia lauseita.",
    notHitExcludeSimilarSentences: "Jättämättä vastaavat virkkeet",
    updateSuccess: "Päivitys onnistui",
    qualify: "Säännöllisenä",
    recallKeyWord: "Palauta avainsanat",
    grammar: "Kielioptiosäännöt",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Voimassaolon muutos epäonnistui, yritä uudelleen",
    postAutoMateTasksCaseByTaskId:
      "Kirjaston synkronointiasetusten tallentaminen epäonnistui",
    getAutoMateTasksCaseByTaskId:
      "Kirjaston synkronointiasetuksen hankkiminen epäonnistui",
    postInspectionRemarkModelConfigs:
      "Kommentin mallin tallentaminen epäonnistui",
    putInspectionRemarkModelConfigsById:
      "Kommentin mallin tallentaminen epäonnistui",
    getInspectionRemarkModelConfigsById: "Kommenttimallitietoja ei voitu hakea",
    postKnowledgeDatas: "Tietopohjan luominen epäonnistui, yritä uudelleen",
    putKnowledgeDatasById: "Versiovaraston muutos epäonnistui, yritä uudelleen",
    getLibraryFiles: "Hintakasioiden luettelon hankkiminen epäonnistui.",
    getLibraryCalls: "Tiedostoa ei voitu hakea",
    getLibraryFilesSearch: "Haku epäonnistui",
    putLibraryFilesMoveById: "Hinnan kansion siirto epäonnistui.",
    putLibraryCallsById: "Tiedoston nimi epäonnistui!",
    postLibraryFiles: "Kansion lisääminen epäonnistui",
    putLibraryFilesById: "Kansion nimi epäonnistui",
    deleteLibraryFilesById: "Poista epäonnistui",
    deleteLibraryCallsById: "Poista epäonnistui",
    postLibraryCalls: "Lisääminen epäonnistui",
    getVoiceUploadResultById: "Lähetystuloksen vienti epäonnistui",
  },
};
