/*
 * @Author: 唐毓斌
 * @Description: 任务详情
 */
import React, { useCallback, useEffect, useState,useMemo } from 'react';
import Page from 'Component/common/udesk-qa-page-layout';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import Udesk from 'Udesk';
import moment from 'moment'; 
import UdeskLocales from 'UdeskLocales';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import OrganizationMemberSelect from '../components/OrganizationMemberSelect';
import { Tabs,Popover,Space } from 'udesk-ui';
import { InformationOutlined } from '@udesk/icons';
import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import { CompleteStandartView } from '../components/CompleteStandart';
import { linkTo } from 'src/util/link';
import { useCoachUserTreeDataWithPermit } from 'src/util/hook/user';
import './index.less';
import { getIntelligentPartnerTaskInfosTaskScore } from 'src/api/intelligentPartnerTaskInfos/task/score';

const ADD_TYPES = [
    {
        pathName: 'coachLearningCenterDetailOverview',
        key: 'overview',
        text: /* 总览 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.dataOverview,
        routes: [] as string[]
    },
    {
        pathName: 'coachLearningCenterDetailTaskDetail',
        key: 'details',
        text: /* 任务明细 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.taskDetail,
        routes: [] as string[]
    },
    {
        pathName: 'coachLearningCenterDetailScriptAnalysis',
        key: 'scripts',
        text: /* 话术分析 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.scriptAnalysis,
        routes: [] as string[]
    },
    {
        pathName: 'coachLearningCenterDetailFlowAnalysis',
        key: 'flow',
        text: /* 流程分析 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.flowAnalysis,
        routes: [] as string[]
    },
    {
        pathName: 'coachLearningCenterDetailExpressionAnalysis',
        key: 'expression',
        text: /* 表达分析 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.expressionAnalysis,
        routes: [] as string[]
    },
    {
        pathName: 'coachLearningCenterDetailEmotionAnalysis',
        key: 'emotion',
        text: /* 情绪分析 */UdeskLocales['current'].pages.coach.taskCenter.detail.index.emotionAnalysis,
        routes: [] as string[]
    },
];

const TaskCenterDetail: React.FC<any> = (props) => {
    // const locales = UdeskLocales['current'];
    const { history, match, routes, location } = props;
    console.log('task-center:routes', routes);
    const [taskInfo, setTaskInfo] = useState<any>();
    const [lessonScore, setLessonScore] = useState<any>({
        total: '-',
        speechCraftScore: '-', // 话术得分
        emotionScore: '-', // 情绪得分
        speedScore: '-', // 语速得分
    });
    const [tab, setTab] = useState('overview');

    const { treeData } = useCoachUserTreeDataWithPermit();
    const onBack = () => {
        const routeName = 'coachTaskCenterList';
        Udesk.ui.routing.transitionTo({
            history,
            routeName,
        });
    };
    const getTaskScore = (taskInfo) => {
        if (taskInfo?.lessonId) {
            getIntelligentPartnerTaskInfosTaskScore({
                params: { taskId: taskInfo.id },
            }).then((resp) => {
                if (resp?.data) {
                    setLessonScore({
                        ...resp?.data,
                        total:
                            (resp?.data.speechCraftScore || 0) +
                            (resp?.data.emotionScore || 0) +
                            (resp?.data.speedScore || 0),
                    });
                }
            });
        }
    };
    const init = () => {
        const id = match?.params?.id || '0';
        if (id !== '0') {
            getIntelligentPartnerTaskInfosById({
                segments: { id },
            })
                .then((resp) => {
                    let values: any = {
                        ...resp?.data,
                    };
                    //画像
                    values.imageIdInfo = {
                        status: 2,
                    };
                    if (values.imageId != null) {
                        values.imageIdInfo.status = 1;
                        values.imageIdInfo.selectedItemId = values.imageId;
                    }
                    // 时长限制
                    values.limitDurationInfo = {
                        status: 1,
                    };
                    if (values.limitDuration != null) {
                        values.limitDurationInfo.status = 2;
                        values.limitDurationInfo.inputValue = values.limitDuration;
                    }
                    // 时间
                    values.taskTimeInfo = {
                        status: 1,
                        viewText:
                            /* 不限时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .index.unlimitedTime,
                    };
                    if (values.taskStartTime && values.taskStartTime) {
                        values.taskTimeInfo.status = 2;
                        values.taskTimeInfo.viewText =
                            moment(values.taskStartTime).format('YYYY-MM-DD HH:mm') +
                            /* 至 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .to +
                            moment(values.taskEndTime).format('YYYY-MM-DD HH:mm');
                    }
                    // 评分标准
                    values.gradingStandartInfo = {
                        commitScore: values.commitScore,
                        excellentScore: values.excellentScore,
                    };
                    // 完成要求
                    values.completeStandartInfo = {};
                    if (values.completeByTimes != null) {
                        values.completeStandartInfo.timesStatus = true;
                        values.completeStandartInfo.completeByTimes = values.completeByTimes;
                    }
                    if (values.completeByScore != null) {
                        values.completeStandartInfo.scoreStatus = true;
                        values.completeStandartInfo.completeByScore = values.completeByScore;
                    }
                    setTaskInfo(values);
                    getTaskScore(values);
                    // setLoading(false);
                })
                .catch(() => {
                    // setLoading(false);
                });
        }
    };

    

    const onTabsChange = useCallback((activeTabKey) => {
        setTab(activeTabKey);
        const tab = ADD_TYPES.find((tab) => tab.key === activeTabKey);
        if (tab) {
            linkTo(history, tab.pathName, {id: match?.params?.id || '0'});
        }
    },[history]);

    // 获取当前路由
    const activeRoute = useMemo(() => {
        console.log('activeRoute:location',location.pathname);
        let resultRoute =  routes.find(({path}) => {
            if (path.includes(':')) {
                let locationPathName = location.pathname.split('/').pop();
                // let routePathName = path.split('/').pop();
                if (locationPathName == path.split('/').pop()) {
                    setTab(locationPathName);
                    return path;
                }
            }
        });
        // if (resultRoute)
        console.log('activeRoute:res',resultRoute);
        if (resultRoute) {
            return resultRoute;
        } else {
            return routes && routes[0];
        }
    }, [
        location.pathname, 
        routes
    ]);

    const renderSubRoutes = useCallback(() => {
        if (activeRoute) {
            console.log('activeRoute1',activeRoute);
            return (
                <SubRoutes route={ activeRoute } />
            );
        } else {
            console.log('activeRoute2',activeRoute, routes);
            return routes.map((routeItem, index) => <SubRoutes key={index} route={routeItem} />);
            
        }
    }, [activeRoute, routes]);

    const pageTitleContent = (
        <Space
                        style={{
                            whiteSpace: 'nowrap',
                            background: '#fff',
                            padding: '16px',
                            borderRadius: '3px',
                        }}
                        direction="vertical"
                    >
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <div className="tcd-header-base-info-item-title">
                                {
                                    /* 任务名称 */ UdeskLocales['current'].pages.coach.taskCenter
                                        .detail.index.taskName
                                }
                            </div>
                            <div>{taskInfo?.taskName}</div>
                        </Space>
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <span className="tcd-header-base-info-item-title">
                                {
                                    /* 完成要求 */ UdeskLocales['current'].pages.coach.taskCenter
                                        .detail.index.completeRequirements
                                }
                            </span>
                            <CompleteStandartView
                                taskType={taskInfo?.taskType}
                                completeStandartInfo={taskInfo?.completeStandartInfo}
                            />
                        </Space>
                        <Space
                            className="tcd-header-base-info-item organization-member-select"
                            direction="horizontal"
                            align="center"
                        >
                            <span className="tcd-header-base-info-item-title">
                                {
                                    /* 发布对象 */ UdeskLocales['current'].pages.coach.taskCenter
                                        .detail.index.publishTo
                                }
                            </span>
                            <span>{/* 共 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.index.common}{taskInfo?.taskTargetCount || 0}{/* 人 */}{UdeskLocales['current'].pages.coach.taskCenter.detail.index.people}</span>
                            <span>(</span>
                            <OrganizationMemberSelect
                                isView={true}
                                treeData={treeData}
                                value={taskInfo?.taskTargetObj}
                            />
                            <span>)</span>
                        </Space>
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <span className="tcd-header-base-info-item-title">
                                {
                                    /* 任务时间 */ UdeskLocales['current'].pages.coach.taskCenter
                                        .detail.index.taskTime
                                }
                            </span>
                            <span>{taskInfo?.taskTimeInfo.viewText}</span>
                        </Space>
                        <Space
                            className="tcd-header-base-info-item"
                            direction="horizontal"
                            align="center"
                        >
                            <span className="tcd-header-base-info-item-title">
                                {
                                    /* 分数构成 */ UdeskLocales['current'].pages.coach.taskCenter
                                        .detail.index.fractionalComposition
                                }
                            </span>
                            <span>
                                <span>{`${
                                    /* 总分 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                        .index.totalScore
                                }${lessonScore.total}${
                                    /* 分 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                        .index.branch
                                }`}</span>
                                <span style={{ color: '#585858' }}>
                                    {`${
                                        /* （话术 */ UdeskLocales['current'].pages.coach.taskCenter
                                            .detail.index.Script
                                    }${lessonScore.speechCraftScore}${
                                        /* 分+情绪 */ UdeskLocales['current'].pages.coach.taskCenter
                                            .detail.index.scoreEmotion
                                    }${lessonScore.emotionScore}${
                                        /* 分+表达 */ UdeskLocales['current'].pages.coach.taskCenter
                                            .detail.index.fractionExpression
                                    }${lessonScore.speedScore}${
                                        /* 分） */ UdeskLocales['current'].pages.coach.taskCenter
                                            .detail.index.points
                                    }`}
                                </span>
                            </span>
                        </Space>
                    </Space>
    );

    const pageTitle = (
        <p>
            <span>
                {`${taskInfo?.taskName || ''}-${
                    taskInfo?.taskType === 1
                        ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                            .practice
                        : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                            .examination
                }`}
            </span>
            <Popover placement="right" content={pageTitleContent} trigger="hover">
                <InformationOutlined style={{ cursor: 'pointer' }}/>
            </Popover>
        </p>
    );

    useEffect(() => {
        init();
    }, []);

    return (
        <Page
            pageBodyClassName="task-center-detail-container"
            backGroundGhost={true}
            title={pageTitle}
            // loading={loading}
            onBack={onBack}
            extra={
                <Tabs size='small' activeKey={tab} onChange={onTabsChange}>
                    {routes.map(route => {
                        const tab = ADD_TYPES.find(item => item.pathName === route.name);
                        if (tab) {
                            return (
                                <Tabs.TabPane tab={tab.text} key={tab.key} />
                            );
                        }
                        return (<Tabs.TabPane tab={'111'} key={'111'} />);
                    })}
                </Tabs>
            }
        >
            <div className="tcd-header-wrapper">
                
                <div className="tcd-header-echarts">
                    
                </div>
            </div>

            <div>{renderSubRoutes()}</div>
        </Page>
    );
};

export default hookProps(TaskCenterDetail);
