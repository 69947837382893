import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import React from "react";
import { Button, notification } from 'udesk-ui';

export default class TaskRoleConfigComponent extends React.Component {
    //#region state
    state = {
        operatorTypes: []
    };
    //#endregion

    //#region Life Cycle
    componentDidMount() {
        const operatorTypes = Udesk.enums.operatorTypes.filter(type => {
            return (
                type.id !== Udesk.enums.operatorTypes.context.id &&
                type.id !== Udesk.enums.operatorTypes.dialogue.id &&
                type.id !== Udesk.enums.operatorTypes.volume.id &&
                type.id !== Udesk.enums.operatorTypes.emotion.id &&
                type.id !== Udesk.enums.operatorTypes.entityInfoDetection.id &&
                type.id !== Udesk.enums.operatorTypes.sentenceLength.id &&
                type.id !== Udesk.enums.operatorTypes.questionsAndAnswers.id &&
                type.id !== Udesk.enums.operatorTypes.element.id
            );
        });
        this.setState({
            operatorTypes: [].concat(operatorTypes)
        });
    }
    privates = {
        disabled: false
    };
    //#endregion

    //#region actions
    actions = {
        save() {
            this.privates.disabled = true;
            this.actions.update();
            const { condition, taskId } = this.privates.model;
            const operatorList = condition.operatorList;
            const operatorLogic = condition.operatorLogic;
            // const reg = /^[\d\s()&&||!]+$/;

            // if (!reg.test(operatorLogic)) {
            //     Udesk.ui.notify.error(
            //         UdeskLocales.current.business.info.conditionError
            //     );
            //     return;
            // }

            const params = { operatorList, operatorLogic };
            const url = Udesk.business.apiPath.concatApiPath(
                "/inspectionTasks/roleSetting/" + taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, params).then(
                resp => {
                    this.privates.disabled = false;
                    this.actions.update();
                    Udesk.ui.notify.success(
                        `${UdeskLocales.current.pages.tasks.manage.template.roleConfig.index.name}${UdeskLocales.current.business.info.editSuccess}`
                    );
                },
                reason => {
                    this.privates.disabled = false;
                    this.actions.update();
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        `${UdeskLocales.current.pages.tasks.manage.template.roleConfig.index.name}${UdeskLocales.current.business.info.editError}`
                    );
                }
            );
        },

        operatorListChanged(operatorList) {
            this.privates.model.condition.operatorList = operatorList;
            this.actions.update();
        },
        close() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'tasksIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sendAjaxToNext() {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            const { props } = this;
            let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
            const openNotification = () => {
                const key = `open${Date.now()}`;
                const btn = (
                    <Button type="primary"
                        prefixCls='udesk-qa-ui-btn'
                        size="small"
                        onClick={() => {
                            this.actions.close();
                            notification.close(key);
                        }
                        }
                    >
                        {UdeskLocales.current.labels.ok}
                    </Button>
                );
                notification.open({
                    prefixCls: 'udesk-qa-ui-notification',
                    message: UdeskLocales.current.business.notifyMessage.illegalTitle,
                    description: UdeskLocales.current.business.notifyMessage.illegalMessage,
                    btn,
                    key,
                    onClose: this.actions.close,
                    duration: 0,
                    style: {
                        top: '60px'
                    }
                });
            };
            if (this.privates.model.processCreateStatus.inspectDataSource === 1) {
                if (
                    (hasFeature && !hasFeature('task:template:manual:view'))
                    || (hasFeature && !hasFeature('task:template:manual:import'))
                    || (hasFeature && !hasFeature('task:template:manual:history'))
                ) {
                    openNotification();
                } else {
                    Udesk.ajax.put(url, { processStatus: 6 })
                        .then(
                            resp => {
                                let routeOptions = {
                                    history: this.props.history,
                                    routeName: "tasksManualAddCreateIndex",
                                    pathParams: {
                                        taskId: this.privates.model.taskId
                                    }
                                };
                                Udesk.ui.routing.transitionTo(routeOptions);
                            },
                            reason => {
                                Udesk.ui.notify.error(reason.errorMsg);
                            }
                        );
                }
            }
        },
        handleNext() {
            let url2 = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/nextCheck/${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            if (this.privates.model.processCreateStatus.related === 1) {
                this.actions.exitBoot();
                return;
            }
            Udesk.ajax.post(url2, { processStatus: 5 })
                .then(
                    resp => {
                        if (resp.data) {
                            this.actions.sendAjaxToNext();
                        } else {
                            Udesk.ui.notify.error(UdeskLocales.current.business.notifyMessage.skipMessage);
                        }
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            
        },
        exitBoot() {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, { processStatus: -1 })
                .then(
                    resp => {
                        const routeOptions = {
                            history: this.props.history,
                            routeName: 'tasksRoleConfigIndex',
                            pathParams: { taskId: this.privates.model.taskId }
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        }
    };
    //#endregion
}
