import React from 'react';
// import Udesk from 'Udesk';
import MassagePushConfigurationComponent from './component';
import './style.scss';
import { Checkbox, Select } from 'udesk-ui';

const { Option } = Select;

export default class MassagePushConfigurationTemplate extends MassagePushConfigurationComponent {
    render() {
        let { privates, props, actions, locales } = this;
        return (
            <div className='massage-push-configuration-wechat'>
                {privates.messagePushType.map((type) => {
                    let pushConf = props.pushConf.find(pushConf => parseInt(pushConf.actionType, 10) === type.id) || {};
                    return (
                        <React.Fragment>
                            <Checkbox checked={pushConf.enabled} onChange={actions.onMessagePushTypeChange.params(pushConf, type.id)}>{type.name}</Checkbox>
                            <Select
                                // mode='multiple'
                                disabled={!pushConf.enabled}
                                style={{ width: '100%' }}
                                placeholder={locales.labels.pleaseChoice + locales.pages.wechatQa.message.title}
                                value={pushConf.actionDetailId}
                                onChange={actions.handleChange.params(pushConf)}
                            >
                                {
                                    privates.messagePushList[type.id]?.map(item => {
                                        return (
                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                        );
                                    })
                                }
                            </Select>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }
}
