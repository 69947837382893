import Udesk from 'Udesk';
import IntelligentTagsComponent from './component';

class IntelligentTagsRoute extends IntelligentTagsComponent {
    asyncModel(changedParams) {
        const { location } = this.props;
        let categoryId = this.privates.selectTagClassification || location?.state?.categoryId;
        let {
            sdkOptions,
        } = this.props;
        let {
            pageSize,
            pageNum,
        } = this.state;
        let {
            tagName,
            funcType,
        } = this.privates;

        let url2 = Udesk.business.apiPath.concatApiPath(`smartTagCategorys?funcType=${funcType}`, sdkOptions);
        let smartTagCategorysPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url2).then(
                resp => {
                    resolve(resp);
                    this.privates.smartTagCategorys = resp.data??[];
                    this.privates.smartTagCategorysTree = getTreeData(this.privates.smartTagCategorys);

                    if (Array.isArray(this.privates.smartTagCategorys)) {
                        if (this.privates.smartTagCategorys.length) {
                            if (categoryId) {
                                if (!this.privates.smartTagCategorys.find(item => item.id === +categoryId)) {
                                    categoryId = `${this.privates.smartTagCategorysTree[0].id}`;
                                }
                            } else {
                                categoryId = `${this.privates.smartTagCategorysTree[0].id}`;
                            }
                        } else {
                            categoryId = undefined;
                        }
                    }

                    this.privates.selectTagClassification = categoryId ? `${categoryId}` : undefined;
                    let params = {
                        pageNum,
                        pageSize,
                        tagName,
                        categoryId: this.privates.selectTagClassification,
                        funcType,
                    };            
                    if (changedParams != null) {
                        Object.assign(params, changedParams);
                    }
                    let url1 = Udesk.business.apiPath.concatApiPath(`smartTags/findAll`, sdkOptions);
                    this.privates.loading = true;
                    this.actions.update();
                    Udesk.ajax.post(url1, params).then(
                        resp => {
                            if (resp.data) {
                                this.privates.smartTags = resp.data.map(i => ({...i, key: i.id}));
                                this.privates.loading = false;
                                this.actions.update();
                            }
                            if (resp.paging) {
                                this.setState({
                                    pageNum: resp.paging.pageNum,
                                    pageSize: resp.paging.pageSize,
                                    total: resp.paging.total
                                });
                            }            
                        },
                        reason => {
                            this.privates.loading = false;
                            this.actions.update();
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                    );
            
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        return ({
            smartTagCategorys: smartTagCategorysPromise
        });
    }
}

export default IntelligentTagsRoute;

function getTreeData(list) {
    if (!Array.isArray(list) || list.length === 0) return [];
    const res = [];
    const map = list.reduce((prev, i) => {
        prev[i.id] = i;
        return prev;
    }, {});
    list.forEach((item) => {
        item.title = item.categoryName;
        item.key = `${item.id}`;
        if (!map[item.parentId]) {
            res.push(item);
        } else {
            if(!map[item.parentId].children) {
                map[item.parentId].children = [];
            }
            map[item.parentId].children.push(item);
        }
    });

    return res;
}