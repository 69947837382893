// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PutIntelligentExtractTasksResultBulkDeleteBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 批量删除抽取结果
 *
 * @export
 * @tags 智能抽取接口
 * @link [PUT] /intelligentExtractTasks/result/bulkDelete
 */
export function putIntelligentExtractTasksResultBulkDelete(
    data: PutIntelligentExtractTasksResultBulkDeleteBody
): Promise<BaseResponseVoid>;
export function putIntelligentExtractTasksResultBulkDelete(
    data: PutIntelligentExtractTasksResultBulkDeleteBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putIntelligentExtractTasksResultBulkDelete(
    data: PutIntelligentExtractTasksResultBulkDeleteBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutIntelligentExtractTasksResultBulkDeleteBody, never, never>(
        '/intelligentExtractTasks/result/bulkDelete',
        {
            ...options,
            data,
            method: 'put',
            __$requestCalleeName: 'putIntelligentExtractTasksResultBulkDelete',
        }
    );
}

export const meta = [
    { tags: ['智能抽取接口'], path: '/intelligentExtractTasks/result/bulkDelete', method: 'put' },
];
