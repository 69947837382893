import Locales from '../locales/index';

let trainTypeList = [
    { id: 1, key: "noneDataTrain", name: () => Locales.current.enums.classifiedTraing.trainTypeList.noneDataTrain },
    { id: 2, key: "markedDataCorrigenda", name: () => Locales.current.enums.classifiedTraing.trainTypeList.markedDataCorrigenda },
    { id: 3, key: "classifiedDataCheck", name: () => Locales.current.enums.classifiedTraing.trainTypeList.classifiedDataCheck },
    { id: 4, key: "markedHistoryRecord", name: () => Locales.current.enums.classifiedTraing.trainTypeList.markedHistoryRecord },
    { id: 5, key: "historyDataRecalculate", name: () => Locales.current.enums.classifiedTraing.trainTypeList.historyDataRecalculate },
];
let dataExtractMode = [
    { id: 1, key: "random", name: () => Locales.current.enums.classifiedTraing.dataExtractMode.random },
    { id: 2, key: "intelligentRecommend", name: () => Locales.current.enums.classifiedTraing.dataExtractMode.intelligentRecommend },
    { id: 3, key: "custom", name: () => Locales.current.enums.classifiedTraing.dataExtractMode.custom },
    { id: 4, key: "specifiedClass", name: () => Locales.current.enums.classifiedTraing.dataExtractMode.specifiedClass },
];
let dataExtractModeTypeOne = [
    { id: 1, key: "random", name: () => Locales.current.enums.classifiedTraing.dataExtractMode.random },
    { id: 2, key: "intelligentRecommend", name: () => Locales.current.enums.classifiedTraing.dataExtractMode.intelligentRecommend },
    { id: 3, key: "custom", name: () => Locales.current.enums.classifiedTraing.dataExtractMode.custom },
];
let smartRatesList = [
    { id: 1, key: "markRate", name: () => Locales.current.enums.classifiedTraing.smartRatesList.markRate },
    { id: 2, key: "corrigendaRate", name: () => Locales.current.enums.classifiedTraing.smartRatesList.corrigendaRate },
    { id: 3, key: "kl", name: () => Locales.current.enums.classifiedTraing.smartRatesList.kl },
    { id: 4, key: "signalNoiseRate", name: () => Locales.current.enums.classifiedTraing.smartRatesList.signalNoiseRate },
];
export default {
    trainTypeList,
    dataExtractMode,
    dataExtractModeTypeOne,
    smartRatesList
};