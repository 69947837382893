export default {
  lang: {
    num: 'رقم',
    total: 'المادة/المجموع',
    strip: 'المادة',
    more: 'المزيد',
    chosen: 'تم اختياره',
    cancel: 'إلغاء الاختيار',
    bulkOperations: 'عمليات الدُفعات',
    import: 'استيراد',
  },
};
