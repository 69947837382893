export default {
  labels: {
    clear: "清空",
    refresh: "刷新",
    nextStep: "下一步",
    prevStep: "上一步",
    closeDriver: "退出引導",
    complete: "完成",
    viewStatistics: "檢視統計",
    colon: "：",
    splitter: "/",
    remark: "備註",
    correction: "糾錯",
    save: "保存",
    reset: "重置",
    increase: "添加",
    preview: "預覽",
    unit: "條",
    cancel: "取消",
    all: "全部",
    searchPlaceHolder: "輸入關鍵字查詢",
    greaterThan: "大於",
    lessThan: "小於",
    second: "秒",
    description: "描述",
    column: "列",
    pleaseChoice: "請選擇",
    filter: "篩選",
    saveAndSubmit: "保存並提交",
    submit: "提交",
    appeal: "申訴",
    review: "複核",
    choose: "選擇",
    manage: "管理",
    delete: "刪除",
    add: "新增",
    create: "新建",
    edit: "編輯",
    name: "名稱",
    describe: "描述",
    next: "下一步",
    percent: "%",
    export: "導出",
    exportAll: "全部導出",
    confirm: "確定",
    back: "返回",
    versionUpdate:
      "好開心嘅通知你,我哋網站啱啱發佈咗新版本! 為咗保證你嘅正常使用,我哋需要你即刻保存當前嘅工作,然後刷新頁面(點擊瀏覽器嘅刷新按鈕,或者按下F5鍵),以令我哋嘅新版本生效。好多謝你嘅配合!",
    more: "更多",
    selected: "已經選",
    deduct: "扣",
    maxScore: "最多",
    scoreUnit: "分",
    pointUnit: "個",
    comma: "，",
    view: "檢視",
    batchingImport: "導入",
    deleteContent: "你確認要刪除呀? 刪除後將唔能再恢復",
    import: "導入",
    uploadFile: "上傳文件",
    downloadFile: "導出文件",
    upload: "上傳",
    click: "點擊",
    downloadTemplate: "下載模板",
    fillInTheForm: "按格式填寫",
    UploadExcelfile: "上傳Excel完成批量導入",
    uploadIdentical:
      "如果 API 名稱存喺, 導入時, 會對系統中已經有嘅資料進行更新",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "適用",
    leftBracket: "(",
    rightBracket: ")",
    question: "問題",
    answer: "答案",
    content: "內容",
    search: "搜索",
    query: "查詢",
    cancelEdit: "取消編輯",
    noValue: "暫無資料",
    selectAll: "全選",
    changeAGroup: "換一組",
    arrow: " ---> ",
    saveReview: "複核提交",
    saveSpotCheck: "抽檢提交",
    rapidSampling: "快速抽檢",
    file: "歸檔",
    download: "下載",
    lastPage: "上一頁",
    nextPage: "下一頁",
    batchActions: "批量操作",
    cancelDistribution: "取消分配",
    againDistribution: "重新分配",
    deleteRecord: "刪除記錄",
    oneOptionOpertor: "自選質檢員",
    allOptionOpertor: "所有質檢員",
    distributionInfoFirstOne: "確認要對",
    distributionInfoFirstTwo: "條記錄重新分配呀? 請選擇分配目標",
    distributionInfoSecondOne: "確定要取消",
    distributionInfoSecondTwo:
      "條記錄嘅分配人信息呀,取消後將變為未分配嘅複核記錄.",
    distributionInfoSecondThree:
      "條記錄嘅分配人信息呀,取消後將變為未分配嘅抽檢記錄.",
    distributionInfoThirdOne: "所選記錄中有",
    distributionInfoThirdTwo: "條已經複核嘅記錄, 已經複核記錄將唔會畀處理.",
    distributionInfoThirdThree: "條已經抽檢嘅記錄, 已經抽檢記錄將唔會畀處理.",
    exportError: "導出失敗",
    exportSuccess: "導出成功",
    deleteRecordInfo: "確認要刪除{0}條記錄呀? 刪除後資料將唔能恢復",
    pleaseSetFilter: "請選擇篩選條件",
    noCountAfterFilter: "暫無導出資料,請重新設置篩選條件",
    redo: "重新執行",
    batchMove: "批量移動",
    websiteName: "智能質檢",
    salesEnable: "銷售賦能",
    systemName: "智能質檢",
    initSystemError: "網絡異常或頁面初始化失敗,請聯繫客服",
    day: "日",
    hour: "小時",
    minute: "分",
    questionMark: "？",
    personalSettings: "個人設置",
    logout: "登出",
    ok: "確定",
    saveAs: "另存為",
    pleaseEnter: "請輸入",
    executeMessage: {
      executeSuccess: "成功執行{0}條",
      executeError: "{0}條執行失敗",
    },
    field: "字段",
    operator: "運算符",
    value: "值",
    placeholder: "請選擇",
    pleaseSelect: "請選擇",
    totalScore: "總分",
    hyphen: "-",
    semicolon: "；",
    wfm: "智能排班",
    user: "用户",
    none: "無",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload: "保存成功! 相關員工刷新頁面後生效",
    saveAsName: "{0}(副本)",
    systemInformation: "檢視系統資料",
    billingCenter: "計費中心",
    warningMessage: "剩餘配額唔足,請盡快充值",
    fileTypeHolder: "文件格式有誤,請上傳{0}格式文件",
    dataNo: "暫無資料",
    move: "移動",
    study: "你有未識認嘅自學習質檢點",
    studySuccess: "標註成功",
    studyError: "標註失敗",
    editType: "編輯分類標籤",
    copyed: "已經複製到剪貼板",
    clickToCopy: "點擊複製",
    addFilterConditions: "添加篩選條件",
    filterConditions: "篩選條件",
    messageTime: "消息時間",
    messageType: "消息類型",
    pushType: "消息渠道",
    messageContent: "消息內容",
    messageName: "消息名稱",
    messageStatus: "消息狀態",
    configuration: "配置信息",
    sendTarget: "發送目標",
    guideUrl: "引導URL",
    noticeDetail: "消息詳情",
    action: "操作",
    seeDetail: "檢視詳情",
    theDay: "{0}日",
    theLastDayOfMonth: "每月嘅最後一日",
    ignore: "忽略",
    seeNow: "即刻檢視",
    exportConfirmText: {
      export: "總共{0}條資料,確認導出?",
      exportAll: "確認導出全部內容?",
    },
    samrTagAdd: "添加標籤",
    deletePopconfirmContent: "你確認要刪除呀?",
    TheLastThirtyDays: "最近30天",
    addNumber: "加",
    subNumber: "減",
    addStrategy: "添加策略",
    minutes: "分鐘",
    status: "啟用狀態",
    pushConfig: "推送配置",
    category: "分類",
    and: "同",
    interval: "間隔",
    switchOn: "開啟",
    switchOff: "關閉",
    batchDel: "批量刪除",
    exportTxt: "導出TXT文本",
    playSpeed: "倍速",
    messageLogBtn: "檢視觸發通知嘅通/對話",
    dataLink: "資料連結",
    yes: "係",
    no: "否",
    sentenceUnit: "句",
    appealDetail: "申訴記錄詳情",
    detail: "詳情",
    default: "默認",
    customer: "客户",
    agent: "客服",
    sort: "排序",
    applyNow: "應用",
    index: "序號",
    allColumns: "可添加嘅列",
    selectedColumns: "已經添加嘅列",
    syn: "同步",
    saveAndNext: "保存並創建下一條",
    viewReference: "檢視引用",
    referenceDetail: "引用詳情",
    goToQaList: "去質檢",
    goToCheck: "去抽檢",
    businessCard: "名片",
    weapp: "小程序",
    redpacket: "利是",
    externalRedpacket: "互通利是",
    sphfeed: "視頻號",
    exportTaskCreated: "導出任務創建成功",
    plzGoTo: "請跳轉至",
    importTaskCreated: "導入任務創建成功",
    taskCreated: "任務創建成功",
    reply: "回覆",
    replied: "回覆咗",
    release: "發佈",
    expandMore: "展開其他 {0} 條回覆",
    comment: "評論",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "請至少有一個一個{0}",
      canNotBeEmptyFormat: "{0}唔能為空",
      repeatErrorTipFormat: "{0}有重複",
      getInformationFailedFormat: "獲取{0}信息失敗, 請檢查網絡連接或者等陣再試",
      deleteSuccessFormat: "{0}刪除成功。",
      deleteFailedFormat: "{0}刪除失敗,請等陣再試。",
      saveSuccessFormat: "{0}保存成功。",
      saveFailedFormat: "{0}保存失敗,請等陣再試。",
      fileSuccessFormat: "{0}歸檔成功。",
      fileFailedFormat: "{0}歸檔失敗,請等陣再試。",
      exportSuccessFormat: "{0}導出成功",
      exportFailedFormat: "{0}導出失敗,請等陣再試。",
      submitSuccessFormat: "{0}提交成功。",
      submitFailedFormat: "{0}提交失敗,請稍後重試。",
      confirmSuccessFormat: "{0}確認成功。",
      confirmFailedFormat: "{0}確認失敗,請稍後重試。",
      pleaseUploadFile: "請先上傳文件!",
      importFileSuccess: "文件導入成功!",
      importFildFailed: "文件導入失敗!",
      deleteSuccess: "刪除成功!",
      deleteFailed: "刪除失敗,請稍後重試!",
      illegalTitle: "權限唔足",
      illegalMessage:
        "你冇下一步驟嘅地方或者全部權限,請聯系管理員配置,點擊肯定撳掣將退出引導",
      illegalMessageCurrent:
        "你冇當前步驟嘅地方或者全部權限,請聯系管理員配置,點擊肯定撳掣將退出引導",
      skipMessage: "如果你想暫時跳過此步,請點擊跳過",
      errorMsg500: "服務器請求失敗, 請檢查你嘅操作或者重繪重試",
      errorMsg404: "Ooops...你揾嘅頁面唔見咗",
      errorMsg400: "服務器請求失敗,請檢查你嘅資料後重試",
      pageOverFlow: "當前僅支持顯示10000條,超出部分請設置篩選條件檢視",
      isHandAddSmartTag: "係手動添加嘅智能標籤",
      defaultGradeMustAtLast: "默認評級必須置於最末尾",
      AutomaticAllocationInspectorsIsNull: "請進入基本信息頁面添加質檢員",
      timePickerTips:
        "為咗唔影響你嘅正常使用,23:00~ 2:00為系統維護時間,唔執行定時任務。",
      batchDeleteInfo: "成功刪除{0}條,失敗{1}條,失敗因由為要資料已經畀使用。",
      batchDeleteTips: "共勾選{0}條資料,確認刪除?",
      correctionSuccess: "糾錯成功",
    },
    pages: {
      qualityInspectionForm: {
        list: { IsMaxFormNumberError: "最多添加10個質檢表單" },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "指標項目{0}之同加起來必須為100",
          banProjectMaxErrorTip: "禁止項最多添加2000個",
          deadlyProjectMaxErrorTip: "致命項最多添加500個",
        },
      },
    },
    info: {
      creatSuccess: "創建成功",
      creatError: "創建失敗",
      editSuccess: "修改成功",
      editError: "修改失敗",
      deleteSuccess: "刪除成功",
      addSuccess: "新增成功",
      deleteError: "刪除失敗",
      conditionError: "算子邏輯表達式錯誤",
      customJudgeLogic: "自定義條件組合邏輯表達式錯誤",
      exportError: "導出失敗",
      searchError: "篩選失敗",
      loadError: "{0}加載失敗",
      saveSuccess: "保存成功",
      saveError: "保存失敗",
      fileSuccess: "歸檔成功",
      fileError: "歸檔失敗",
      cannotBeNull: "{0} 唔能為空!",
      pleaseSearchAndSelect: "請輸入關鍵字搜索",
      clickAndToggle: "點擊選擇切換",
      deleteWarning: "{0} 刪除將唔回覆, 你肯定要繼續呀?",
      getOssAuthFailed: "獲取 OSS 授權失敗, 請檢查網絡連接或者等陣再試。",
      getInsightAuthFailed:
        "獲取 Insight 授權失敗, 請檢查網絡連接或者等陣再試。",
      pictureUploadFailed: "圖片上傳失敗",
      pictureUploadFailedSize: "圖片大小超過限制,圖片上傳失敗",
      wecomTagsSyncInfo: "最近更新:{0} ,同步{1}條信息",
      collectSuccess: "收藏成功",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "獲取報表資料失敗" },
      waveAudio: {
        noWaveMessage:
          "為咗維護你嘅體驗,超過{0}分鐘嘅錄音暫唔顯示波形,你可以直接播放",
      },
      customFilter: {
        del: "刪除",
        edit: "編輯",
        new: "自定義篩選",
        apply: "應用",
        cancel: "取消",
        onlyApply: "僅適用",
        ohterSave: "另存為",
        save: "保存",
        customFilter: "常用篩選",
        reset: "重置",
        holderKeyWord: "請輸入關鍵詞",
        customFilterRule: "篩選邏輯規則:",
        customFilterName: "篩選器名稱:",
        holderCustomFilterName: "請輸入篩選器名稱",
        manualConfiguration: "手動配置",
        holderCustomRule: "請輸入自定義邏輯條件",
        example: "示例",
        dragConfiguration: "拖拽配置",
        condition: "條件",
        operation: "運算",
        infoDel: "內容拖出區域外刪除",
        toConfigure: "配置",
        holderCustomFilterNameLength: "篩選器名稱最大長度為10",
        customFilterNameNone: "篩選器名稱唔能為空",
        and: "同",
        or: "或",
        not: "非",
        delSuccess: "刪除成功",
        saveSuccess: "保存成功",
        updateSuccess: "修改成功",
        customFilterCondition: "篩選條件",
        conditionRule: "條件規則",
        none: "無",
        filterPlaceholder: "請輸入關鍵詞",
      },
      labelTabs: {
        addThis: "添加到此",
        goThis: "移動到此",
        saveGo: "保存並訓練",
      },
      datePicker: {
        today: "今日",
        yesterday: "尋日",
        theDayBeforeYesterday: "前日",
        thisWeek: "本週",
        lastWeek: "上週",
        thisMonth: "本月",
        lastMonth: "上個月",
        lastSevenDay: "最近7天",
        lastThirtyDay: "最近30天",
        thisQuarter: "本季度",
        lastQuarter: "上個季度",
        thisYear: "今年",
        lastYear: "舊年",
        lastYearFromToday: "最近一年",
      },
      pagination: { page: "共{0}條,{1}頁" },
      wechatRecord: {
        loadMoreBtn: { top: "加載更早嘅消息", bottom: "加載更多消息" },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "質檢任務",
        informationCollectTasksManageTitle: "採集任務",
        customerServiceCenterTitle: "客服中心",
        informationTitle: "資料中心",
        intelligentAnalysisTitle: "智能分析",
        semanticIntelligence: "語義智能",
        trainingCenterTitle: "訓練中心",
        adminTitle: "系統管理",
      },
      qualityCheckingTask: {
        title: "通話質檢",
        index: {
          tipOne:
            "通話質檢用於評價員工嘅呼叫中心工作質量,你可以結合自己嘅業務,設置合適嘅質檢表單",
          tipTwo:
            "可以為質檢任務設定合適嘅規則,包括篩選、抽樣等。 質檢員進行評分後,客服可以檢視質檢結果並進一步申訴,",
          addTaskTitle: "添加質檢任務",
          deleteTipTitle: "刪除質檢任務",
          deleteTipContent: "質檢任務刪除後唔可恢復,確認刪除呀?",
          checkingTask: "質檢任務",
          checkingTaskList: "質檢任務列表",
          formList: "表單列表",
          viewHelpDocuments: "檢視幫助文檔",
        },
        manage: {
          taskSetting: {
            title: "任務設置",
            form: "表單",
            basicInfo: "基本信息",
            visibleRange: "可見範圍",
            visibleRangeTip:
              "選擇嘅客服、客服組可以檢視應該質檢任務, 另外可以結合權限設置, 為呢個任務客服組長、客服、質檢員等",
            automaticSampling: "自動抽樣",
            automaticSamplingTip:
              "你可以設置自動抽樣嘅規則,噉樣系統將按照你設置嘅規則定期添加質檢任務,例如每週一抽取上週5%嘅通話",
            samplingRate: "抽樣頻率",
            samplingTime: "抽樣時間",
            samplingRange: "抽樣範圍",
            samplingRule: "抽樣規則",
            fillterCondition: "篩選條件",
            workflow: "質檢工作流程",
            appealsTime: "允許申訴時間",
            appealsTimeTipOne: "評分提交後",
            appealsTimeTipTwo: "日內",
            appealsTimeTipThree:
              '超過允許申訴時間, 記錄質檢狀態自動修改為 “已經確認"',
            auditTime: "審核處理時間",
            auditTimeTipOne: "提出申訴後",
            auditTimeTipTwo: "日內",
            auditTimeTipThree:
              '超過審核處理時間, 記錄維持原評分, 質檢狀態自動修改為 “已經確認"',
            noAppealsMode: "無申訴模式",
            appealsMode: "允許申訴模式",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "客服",
            customerServicesGroup: "客服組",
            surveyList: "評價列表",
            customFields: "自定義字段",
            fieldConfig: "字段配置",
            from: "來自",
            owner: "負責人",
            inspectorAndReviewerAuthority: "質檢複核權限",
            inspector: "質檢員",
            reviewer: "複核員",
            inspectorTip: "質檢員可以對應該任務進行評分",
            reviewerTip: "複核員可以對應該任務進行評分",
            dataStatisticsAuthority: "資料統計權限",
          },
          samplingRecords: {
            title: "抽樣記錄",
            index: {},
            manualSampling: {
              title: "抽樣",
              fillterCondition: "篩選條件",
              samplingRule: "抽樣規則",
              agent: "客服",
              type: "類型",
              result: "結果",
              evaluate: "評價",
              relayNumber: "中繼號",
              source: "來源",
              task: "任務",
              queueState: "排隊狀態",
              callHanger: "通話掛斷方",
              queueTime: "排隊耗時",
              talkTime: "通話時長",
              bellTime: "響鈴時長",
              recordCountTipOne: "共計",
              recordCountTipTwo: "條記錄",
              customerServices: "客服",
              customerServicesGroup: "客服組",
              surveyList: "評價列表",
              customFields: "自定義字段",
              fieldConfig: "字段配置",
              defaultFields: "默認字段",
            },
          },
          taskList: {
            title: "任務列表",
            index: {
              batchDelete: "批量刪除",
              batchSubmit: "批量提交",
              batchConfirm: "批量確認",
              itemFields: "評分項目字段",
              categoryFields: "二級分類字段",
              forbidsAndDeadlies: "禁止項同致命項",
              scoreFieldList: "評分項字段列表",
              customerName: "客戶名稱",
              cellphone: "電話",
              email: "郵箱",
              organization: "公司",
              description: "描述",
              tags: "標籤",
              taskUserRole: "當前任務用戶角色",
            },
            workBench: {
              title: "記錄詳情",
              checkForm: "質檢表單",
              waitEvaluate: "待評價",
              alreadyEvaluate: "已經評價",
              waitSubmit: "待提交",
              alreadySubmit: "已經提交",
              waitConfirm: "待確認",
              alreadyConfirm: "已經確認",
              waitReview: "待複核",
              alreadyReview: "已經複核",
              alreadyDone: "已經完成",
              comment: "評價備註",
              totalScore: "總分",
              score: "分",
              remark: "備註",
              changeRecords: "變更記錄",
              subject: "主題",
              secondLevelScore: "二級評分",
              deadlyList: "致命項",
              forbidList: "禁止項",
              addToCheckTask: "添加到質檢任務",
              submitCheckRemark: "提交咗質檢評價",
              changeCheckRemark: "修改咗質檢評價",
              doneCheckRemark: "完成咗質檢評價",
              submitCheckReview: "提交咗質檢複核",
              submitCheckAppeal: "提交咗質檢申訴",
              saveFlowSuccess: "評價成功。",
              saveFlowFailed: "評價失敗,請稍後重試。",
              FormDetail: "表單詳情",
              callDetail: "通話記錄詳情",
              sampleCalllogDetail: "記錄詳情",
              appealReasonTip: "請輸入申訴理由(必填)",
              appeal: "申訴",
              appealReason: "申訴理由",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0}質檢表單",
        basicInformationTitle: "基本信息",
        indicatorsProjectTitle: "指標項目",
        specialProjectTitle: "特殊項",
        totalScore: "總分",
        indicatorsName: "指標名稱",
        score: "分數",
        singleScore: "單項分",
        weight: "權重",
        maxDeductScore: "最多扣分",
        add: "增加",
        detail: "表單詳情",
        list: {
          title: "質檢表單",
          tipOne:
            "配置質檢表單係質檢工作嘅第一步,你可以結合公司嘅業務,配置最適合嘅質檢表單。",
          tipTwo:
            "我哋支持直接評分、權重評分、扣分制等多種表單。 建議你設置好規則後,再添加到系統。",
        },
        basicInformation: {
          formTemplate: "表單模板",
          directScore: "直接評分",
          weightedScore: "權重評分",
          deductScore: "扣分",
          directScoreSecondLevel: "直接評分-二級",
          weightedScoreSecondLevel: "權重評分-二級",
          deductScoreSecondLevel: "扣分-二級",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "禁止項",
          banProjectTip:
            "可以用於喺質檢評分嘅時候勾選, 可以視作違規件, 亦都可以視為備註嘅補充, 唔影響實際分數。",
          deadlyProject: "致命項",
          deadlyProjectTip:
            "致命項可以用於喺質檢評分嘅時候勾選,如果勾選咗致命項,就要條質檢任務直接評分0分",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "通話質檢",
          entiretyReportTitle: "整體報表",
          serviceGroupReportTitle: "客服組報表",
          serviceReportTitle: "客服報表",
          entiretyReport: {
            pandect: "總覽",
            contrast: "對比",
            trend: "趨勢",
            analyze: "分析",
            totalScore: "總分",
            banProject: "禁止項",
            deadlyProject: "致命項",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "質檢結果列表",
          taskSelectPlaceholder: "選擇質檢任務",
          agentSelectPlaceholder: "選擇客服",
          refresh: "刷新",
          all: "全部",
          unread: "未讀",
          inComplaint: "申訴中",
          reviewed: "已經複核",
          totalScore: "總評",
          detail: "質檢詳情",
          audioText: "錄音文本",
          callInfo: "通話信息",
          ticketInfo: "工單信息",
          wechatInfo: "會話信息",
          dialogText: "對話文本",
          dialogInfo: "對話信息",
          customer: "客户",
          agent: "客服",
          none: "暫無質檢資料",
          appealSuccess: "申訴成功!",
          appealFailed: "申訴失敗,請稍後重試!",
          pointListIsNull: "申訴質檢點唔能為空!",
          pleaseSelectPoint: "請選擇質檢點!",
          unsupportedFormat: "暫唔支持該格式",
          shardingInformation: "以下為分片{0}",
          smartTags: "智能標籤",
          businessRecords: "業務記錄",
          selectedPointsWarning:
            "請勾選要申訴嘅質檢項,分別為申訴項指定相關語句,然後點擊提交。",
          applyReason: "申訴理由(最多50字)",
          chatMessageTypes: {
            all: "全部",
            text: "文本",
            image: "圖片",
            agree: "同意會話內容",
            video: "視頻",
            voice: "語音",
            file: "文件",
            emotion: "表情",
            link: "連結",
            chatrecord: "聊天記錄",
            mixed: "混合消息",
            default: "未支持消息",
            deleteFriend: "刪除好友",
            redpacket: "利是",
            card: "名片",
            disAgree: "唔同意會話內容",
            weapp: "小程序",
            externalRedpacket: "互通利是",
            meetingVoicevCall: "通話消息",
            uRL: "網頁",
            replyTimeout: "超時回覆",
            sphfeed: "視頻號",
            chatRecordTitle: "聊天記錄",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "總分",
            actualScore: "評分",
            actualGrade: "評級",
            appeal: "申訴",
            remark: "人工備註",
            none: "暫無質檢詳情資料",
            pointEdit: "質檢點編輯分類",
          },
          changeList: { title: "變更記錄", user: "用户", point: "質檢點" },
          relatedRecord: {
            title: "相關記錄",
            qualityList: "質檢任務",
            none: "無",
            check: "檢視",
            loadMore: "加載更多",
          },
        },
      },
      grade: {
        title: "質檢評級",
        subTitle:
          "質檢評級用於質檢打分完成後對質檢對象進行分檔評級,例如可配置低於60分為唔合格,60-85分為良好,高於80分為優秀",
        list: {
          up: "上移",
          down: "下移",
          del: "刪除",
          case: "自動加入案例庫",
          orderCase: "默認案例庫",
          info: "默認評級無匹配條件,唔可刪除,若唔符合上述任何評級,則獲得默認評級",
          add: "添加評級",
          save: "保存",
          close: "取消",
          max: "質檢評級最多可配置20条",
          name: "未命名",
          success: "保存成功",
        },
      },
      qualityInspectionList: {
        title: "質檢列表",
        fastFilter: "質檢違規",
        fastFilterTip: "減分制命中為違規,加分制未命中為違規",
        titleWechat: "分析列表",
        titleCollect: "採集列表",
        status: "分析狀態",
        reQualityInspectionConfig: "重新質檢任務選項",
        pleaseSelectRecheckPoints: "請選擇需要重新核算嘅質檢點",
        beginReQualityInspection: "開始執行",
        cancelReQualityInspection: "取消任務",
        reQualityInspection: "重新質檢",
        reQualityInspectionWechat: "重新分析",
        reQualityInspectionUnderWay: "重新質檢任務中...",
        reQualityInspectionUnderWayTip: "關閉窗口後離開頁面唔會影響任務進行",
        reQualityInspectionDone: "重新質檢已經完成",
        reQualityInspectionDoneTip: "現已經成功完成重新質檢, 請點擊確認按鈕",
        endReQualityInspection: "終止任務",
        alreadyDoneTip: "已經完成 {0} 條",
        totalTip: "合計 {0} 條",
        endReInspectionSuccess: "重新質檢任務已經終止",
        closeWindow: "關閉窗口",
        endReInspectionFailed: "重新質檢任務失敗,請稍後重試!",
        detail: {
          title: "質檢詳情",
          wechatTitle: "分析詳情",
          titleCollect: "採集詳情",
          callIndex: "關聯通話",
          textIndex: "關聯對話",
          fileConfirm: "確認歸檔呀?",
          fileConfirmDesc: "歸檔後將無法編輯質檢得分,請確認係咪歸檔",
        },
        sysCloumnsContal: "系統列字段管理",
        personCloumnsContal: "個人列字段管理",
      },
      spotCheckList: {
        title: "抽檢列表",
        onlyMyTip: "特檢視我嘅抽檢",
        plInspector: "請選擇抽檢員",
        allInspector: "檢視全部",
        seInspector: "檢視指定抽檢員",
        myInspector: "特檢視我嘅",
        noInspector: "檢視未分配抽檢員",
        detail: { title: "抽檢詳情" },
      },
      reviewList: {
        title: "複核列表",
        onlyMyTip: "特檢視我嘅複核",
        plInspector: "請選擇複核員",
        allInspector: "檢視全部",
        seInspector: "檢視指定複核員",
        myInspector: "特檢視我嘅",
        noInspector: "檢視未分配複核員",
        detail: { title: "複核詳情" },
      },
      caseBaseList: {
        title: "案例庫",
        del: "刪除",
        delTitle: "刪除後所有內容唔會畀保存",
        cancel: "取消",
        actions: "操作",
        seeCaseBase: "檢視案例",
        seeDetail: "檢視詳情",
        edit: "編輯",
        save: "保存",
        addCaseBase: "新增案例庫",
        export: "導出",
        ok: "確定",
        tip: "質檢列表中典型嘅通話同對話資料,可以保存至案例庫方便學習同分享",
        detail: {
          choiceCaseBase: "請選擇案例",
          delCaseBase: "刪除案例",
          addCaseBase: "添加案例",
          successed: "保存成功",
          info: "請先保存或取消正喺編輯嘅內容",
          caseType: "請選擇要加入嘅案例庫",
        },
      },
      testSetList: {
        title: "質檢測試集",
        tip: "質檢員抽檢嘅資料會保存喺質檢測試集中,方便針對抽檢後比對出嚟嘅一啲機檢錯誤項去優化質檢條件同質檢規則嘅設定",
        refresh: "刷新",
        reQualityInspectionUnderWay: "重新評估任務中...",
        reQualityInspectionUnderWayTip: "關閉窗口後離開頁面唔會影響任務進行",
        reQualityInspectionDone: "重新評估已經完成",
        reQualityInspectionDoneTip: "現已經成功完成重新評估, 請點擊確認按鈕",
        endReQualityInspection: "終止任務",
        alreadyDoneTip: "已經完成 {0} 條",
        totalTip: "合計 {0} 條",
        endReInspectionSuccess: "重新評估任務已經終止",
        closeWindow: "關閉窗口",
        endReInspectionFailed: "重新評估任務失敗,請稍後重試!",
        recheckPoint: "重新核算質檢點分數同準確率",
        recheckPointTitle: "重新評估選項",
        delete: {
          reQualityInspectionUnderWay: "批量刪除任務中...",
          reQualityInspectionUnderWayTip: "關閉窗口後離開頁面唔會影響任務進行",
          reQualityInspectionDone: "批量刪除任務已經完成",
          reQualityInspectionDoneTip: "現已經成功完成批量刪除, 請點擊確認按鈕",
          endReQualityInspection: "終止任務",
          alreadyDoneTip: "已經完成 {0} 條",
          totalTip: "合計 {0} 條",
          endReInspectionSuccess: "批量刪除任務已經終止",
          closeWindow: "關閉窗口",
          endReInspectionFailed: "批量刪除任務失敗,請稍後重試!",
        },
        reultList: {
          assessmentTime: "上次評估時間",
          assessmentAll: "合計評估",
          strip: "條",
          spot: "點",
          deviation: "偏差",
          accuracyRate: "準確率",
          info: "無評估結果,請評估",
          onlyDeviationInfo: "特檢視偏差記錄",
          againAssessment: "重新評估",
          screen: "篩選",
          batchDelete: "批量移除",
          matching: "匹配",
          ok: "係",
          noCancel: "否",
        },
      },
      smartWordsLibrary: {
        title: "智能詞庫",
        tip1: "同義詞:用於降低語義標籤嘅訓練量,同義詞嘅相似句無需重複訓練",
        tip2: "專業名詞:針對識別唔準確嘅企業名稱、產品名、行業詞彙等專有名詞進行轉寫優化、自然語言理解優化,提升識別準確率、分析準確率",
        tip3: "關注詞:對詞雲分析中想關注嘅詞進行關注,幫助AI分析熱點符合當前業務",
        tip4: "屏蔽詞:對詞雲分析中唔想關注嘅詞進行屏蔽,幫助AI分析熱點符合當前業務",
        tip5: "詞權重:用於對語義標籤嘅識別進行糾錯優化,透過修改詞喺句中嘅影響權重,根據需要優化特定場景下嘅語義識別效果",
        tip6: "語料資料:錄入針對各自業務場景嘅大段落文本,用於轉寫糾錯同ASR訓練,提高ASR轉寫準確率",
        upload: {
          uploadSynonymWords: "導入同義詞",
          uploadProfessionalWords: "導入專業名詞",
          uploadAttentionWords: "導入關注詞",
          uploadIgnoreWords: "導入忽略詞",
          uploadWordsWeight: "導入權重詞",
          uploadTypo: "導入錯別字詞集",
          uploadCorpusData: "導入語料資料",
          uploadCorpusContent: "導入語料資料內容",
          firstUploadStep: "1.下載模板,按模板格式填寫",
          downloadTemplateMessage: "下載模板:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep: "2.點擊導入撳掣,上傳符合模板格式嘅EXCEl文件",
          secondUploadCorpusDataStep:
            "2.點擊導入撳掣,上傳符合模板格式嘅TXT文件",
          postUploadDataFail: "提交上傳資料失敗",
          pleaseUploadTemplate: "請上傳EXCEl/JSON/TXT文件",
          templateNeedJSONOrXLSX: "文件模板只能為JSON或xlsx格式",
          templateNeedTxt: "語料資料文件模板只能為TXT文件",
          uploadSuccess: "導入成功",
          uploadFailed: "導入失敗",
          downloadError: "下載失敗",
          downLoadTxtTitle: "語料內容.txt",
        },
        navTabNames: {
          synonymWords: "同義詞",
          professionalWords: "專業名詞",
          attentionWords: "關注詞",
          ignoreWords: "忽略詞",
          wordsWeight: "詞權重",
          corpusData: "語料資料",
          typoLibrary: "錯別字詞集",
        },
        synonymWords: {
          createSynonymWords: "新建同義詞",
          editSynonymWords: "編輯同義詞",
          addSynonymWords: "添加同義詞",
          pleaseEnterStandardWords: "請輸入標準詞",
          synonymWordsExists: "該同義詞已經存喺, 無法添加",
          synonymWordsCanNotNull: "添加嘅同義詞唔能為空",
          pleaseAddSynonymWords: "請添加同義詞",
          addSuccess: "新增成功",
          editSuccess: "修改成功",
          deleteSuccess: "刪除成功",
          deleteError: "刪除失敗",
          required: "*",
        },
        professionalWords: {
          create: "新建專業名詞",
          edit: "專業名詞編輯",
          addTargetWords: "添加替換詞",
          targetWordsExists: "該替換詞已經存喺, 無法添加",
          targetWordsCanNotNull: "添加嘅替換詞唔能為空",
          pleaseAddTargetWords: "請添加替換詞",
          pleaseEnter: "請輸入專業名詞",
          wordsExists: "該專業名詞已經存喺",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "刪除成功",
          deleteError: "刪除失敗",
          saveError: "保存失敗",
          addTargetWordsMaxNumberError: "替換詞最多可以添加100個",
          addHomophonyWordsMaxNumberError: "屏蔽詞最多可以添加100個",
        },
        attentionWords: {
          create: "新建關注詞",
          edit: "關注詞編輯",
          pleaseEnter: "請輸入關注詞",
          wordsExists: "該關注詞已經存喺",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "刪除成功",
          deleteError: "刪除失敗",
        },
        ignoreWords: {
          create: "新建忽略詞",
          edit: "忽略詞編輯",
          pleaseEnter: "請輸入忽略詞",
          wordsExists: "該忽略詞已經存喺",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "刪除成功",
          deleteError: "刪除失敗",
        },
        wordsWeight: {
          createWordsWeight: "新建權重詞",
          editWordsWeight: "編輯權重詞",
          addWordsWeight: "添加權重詞",
          pleaseEnterWeightWords: "請輸入權重詞",
          pleaseEnterWeight: "請輸入權重",
          weightNeedInZeroToHundred: "權重需要喺0~ 100之間",
          synonymWordsExists: "該權重詞已經存喺",
          weightNeedNumber: "權重需要齊頭數格式",
          standardWeightInformation: "基準權重為50,權重越大喺分析中影響力越強",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "刪除成功",
          deleteError: "刪除失敗",
        },
        corpusData: {
          createCorpusData: "新建語料資料",
          editCorpusData: "編輯語料資料",
          addCorpusData: "添加語料資料",
          pleaseEnterCorpusDescription: "請輸入語料描述",
          pleaseEnterCorpusContent: "請輸入語料內容",
          synonymWordsExists: "該語料資料已經存喺",
          CorpusContentWordsOverSetting: "內容字數統計超過20000字,",
          download: "下載",
          upload: "上傳",
          see: "檢視",
          canNotSave: "請使用導入功能,否則無法保存",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "刪除成功",
          deleteError: "刪除失敗",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "正確詞",
            wrongWord: "錯詞",
            wrongWordCount: "錯詞個數",
            wrongWordTip: "按下鍵添加",
            status: "有效性",
          },
          create: "新建錯別字詞集",
          edit: "編輯錯別字詞集",
          tip: '錯別字詞集用於優化 “錯別字檢測” 嘅效果,例如 "退款" 誤發送為 "退寬", 則可以透過此功能設置常見嘅錯別字同正確嘅字,注意保存後次日生效。',
          onlyChinese: "只支持輸入中文詞語",
          wrongWordLengthErr: "每個錯詞長度需喺2~ 10個字",
        },
      },
      semanticTags: {
        title: "語義標籤",
        current: "當前語義標籤",
        reference1: "質檢任務: {0} - {1}",
        reference2: "智能標籤: {0}",
        reference3: "資料預處理: {0}",
        reference4: "知識庫: {0}",
        index: { import: "導入語義標籤" },
        detail: {
          new: "新建语义標籤",
          edit: "編輯語義標籤",
          name: "名稱",
          standardSentences: "標準句",
          effectiveness: "有效性",
          similarSentences: "相似句",
          tip: "支持使用##調用實體API,示例:你嘅手機號係#sys_phone#呀?",
          excludeSimilarSentences: "排除相似句",
          intelligentRecommend: "智能推薦相似內容",
          fastTraining: "快速訓練:",
          qualify: "正則",
          recallKeyWord: "召回關鍵詞",
          excludeKeywords: "排除關鍵詞",
          excludeKeywordsInfo1:
            "排除關鍵詞、正則優先於所有邏輯生效,若命中排除則唔會命中該語義標籤",
          keyWordInfo1:
            "召回關鍵詞每行支持填寫多個,用英文逗號分隔,填寫多個時邏輯為全部命中。",
          keyWordInfo2:
            "召回關鍵詞支持填寫多行,填寫多行時邏輯為命中任意均可成立。",
          recallRegular: "召回正則",
          excludeRegular: "排除正則",
          similaSentences: "排除相似句",
          simim: "相似度",
          keyWordInfo3:
            "排除相似句優先於所有邏輯生效,若命中排除相似句則唔會命中該語義標籤,默認相似度80%,相似度越大越相似。",
          keyWordInfo4:
            "規則類似正則,默認存喺開始同結束。 非必須配合中括號使用,例如[非XXXX]",
          keyWordInfo5:
            "高級模塊中嘅功能通常係為熟悉操作嘅專家準備嘅實驗功能,如果你並唔能確定你應如何操作,建議你唔要改動此部分。",
          keyWordInfo6: "跨句檢測功能賦予咗語義標籤跨越句子進行檢測嘅能力。",
          keyWordInfo7: "最多跨越同一角色連續嘅",
          keyWordInfo8: "忽略唔同角色句長唔大於",
          keyWordInfo9: "個字嘅句子進行跨句檢測",
          keyWordInfo10: "最長跨句極限句長唔大於",
          keyWordInfo11: "去除相似百分比唔小於",
          keyWordInfo12: "相似度唔低於",
          keyWordInfo13: "%嘅相似句會畀相似匹配(默認相似度80%)",
          keyWordInfo14:
            "相似去重會依據句子嘅相似度進行匹配,建議去除過於相似嘅句子以提升檢測效率。",
          keyWord: "關鍵詞",
          entity: "實體",
          ruleField: "概念詞",
          customRule: "話術規則設計",
          fanhua: "相似問",
          test: "測試",
          testOk: "命中測試",
          senior: "高級設置",
          seniorList: "跨句檢測",
          up: "啟用",
          down: "關閉",
          ju: "句",
          oneWord: "個字",
          simimNo: "相似去重",
          seniorField: "相似度閾值",
          save: "保存",
          cancel: "取消",
          specialElements: "特殊要素",
          logicRule: "邏輯規則",
          every: "多次匹配",
          recallOk: "命中召回詞:",
          recallNo: "未命中召回詞",
          ruleOk: "命中召回正則",
          ruleNo: "未命中正則",
          rularOk: "命中規則",
          rularNo: "未命中規則",
          infoOk: "命中標準句或相似句:",
          infoNo: "未命中標準句或相似句",
          fieldOk: "命中排除相似句:",
          fieldNo: "未命中排除相似句",
          excludeRuleNo: "未命中排除正則",
          excludeRuleOk: "命中排除正則",
          excludeKeywordNo: "未命中排除關鍵詞",
          excludeKeywordOk: "命中排除關鍵詞",
          update: "更新成功",
          fanhuaList: "已經提交到泛化頁簽相似句列表",
          wordList: "訓練列表為空,無法提交到泛化列表",
          start: "開始",
          end: "結束",
          keyWordInfo15: "泛化列表中存喺相似字段(高級配置相似去重約束)",
          saveSuccess: "保存成功",
          errInfo: "有字段未填寫或填寫唔正確",
        },
        table: { deepTraining: "深度訓練{0}輪", fastTraining: "快速訓練" },
      },
      intelligentCollection: {
        title: "智能採集",
        classification: "分組列表",
        new: "新建採集",
        table: {
          name: "字段名稱",
          props: "客户內容",
          type: "字段類型",
          desc: "字段描述",
        },
        detail: {
          isCustomerProps: "保存至客户內容",
          customerPropsHelper:
            "保存後,採集嘅信息會更新至客户信息。 唔保存,則唔會更新至客户信息",
          name: "字段名稱",
          type: "字段類型",
          text: "文本",
          desc: "字段描述",
          descPlaceHolder: "描述該字段採集信息嘅用處",
          condition: "條件",
          logic: "規則",
          method: "採集方式",
          message: "信息內容",
          range: "抽取範圍",
          entity: "抽取實體",
          base: "基本信息",
          collect: "採集信息",
        },
      },
      intelligentTags: {
        title: "智能標籤",
        sessionLabelTitle: "會話場景",
        keyEventsTitle: "關鍵事件",
        customerLabelTitle: "客戶標籤",
        addClassification: "新建一個標籤分類",
        defaultCategoryName: "默認分類",
        index: {
          createTag: "新建標籤",
          createEvent: "新建事件",
          editTagClassification: "編輯標籤分類",
          selectTagClassification: "選擇標籤分類",
          editTagGrounp: "編輯分組",
          callTimeError: "選擇嘅通話時間/對話開始時間範圍唔能超過一個月(31日)",
          callRecordCountError: "記錄條數為0,請重新選擇通話/對話時間",
          selectItmeCountError: "請選擇需要執行批量用嘅智能標籤",
          redoSuccess: "重新執行成功,請到任務中心中檢視",
          redoError: "重新執行失敗,請稍後重試",
          timeError: "查詢時間唔能超過一個月(31日)",
          categoryName: "分類名稱",
          categoryColor: "分類顏色",
          newTagClassification: "新建標籤分類",
          newEventClassification: "新建分類",
          tagClassification: "標籤分類",
          eventClassification: "事件分類",
        },
        detail: {
          new: "新建智能標籤",
          edit: "編輯智能標籤",
          tagName: "名稱",
          tagDesc: "説明",
          category: "分類",
          tagStatus: "有效性",
          operator: "算子",
          rule: "規則",
          labelClassAtion: "標籤類型",
          labelClassAtionTip1: "標註標籤:基於規則進行標籤標記",
          labelClassAtionTip2: "分類標籤:基於模型訓練進行標籤標記",
          group: "分組",
          checkLabel: "選擇分類",
          warn: "預警",
          warnTips:
            "開啟預警後,當會話滿足此關鍵事件規則時,會提示到指定人員,可喺待辦事項處收到提醒",
          warnError: "請選擇預警指定人員",
        },
        subTitle: { common: "智能會話標籤", wecom: "企微標籤" },
      },
      customerPortrait: {
        title: "客户畫像",
        index: { import: "導入客戶畫像" },
        searchPlaceHolder: "客户ID、暱稱或電話",
        callCount: "通對話數:",
        callDuration: "總通話時長:",
        dialogCount: "總對話條數:",
        keyWordsStatistics: "關鍵詞統計(Top 20 & 關注詞)",
        keyWordsCount: "({0})",
        businessTag: "業務標籤",
        keyWordsCloudImg: "關鍵詞雲圖",
        customerEmotionTrend: "客户情緒變化趨勢",
        notFoundCustomer: "未檢索到相關客戶",
        noKeyWords: "未查詢到相關關鍵詞",
        noBusinessTags: "未查詢到相關業務標籤",
        getKeyWordsStatisticsDataError: "客戶關鍵詞統計查詢失敗",
        getBusinessTagsStatisticsDataError: "客户業務標籤查詢失敗",
        personCustome: "未查詢客戶信息",
        notimeInfo: "未查詢到情緒信息",
        timeList: "日期範圍",
        operator: "智能標籤",
        placeHolder: "請選擇",
        placeOperator: "請選擇智慧標籤",
        search: "查詢",
        reset: "重置",
        searchHolder: "搜索",
        call: "通話",
        text: "對話",
      },
      sentimentAnalysis: { title: "輿情發現" },
      saleSentimentAnalysis: { title: "輿情分析" },
      dataPreprocess: {
        title: "資料預處理",
        index: {
          createData: "新建資料",
          selectDataSource: "選擇資料源",
          selectStatus: "選擇有效性",
          callTimeError: "選擇嘅通話時間/對話開始時間範圍唔能超過一個月(31日)",
          callRecordCountError: "記錄條數為0,請重新選擇通話/對話時間",
          selectItmeCountError: "請選擇需要執行批量用嘅資料",
          redoSuccess: "重新執行成功,請到任務中心中檢視",
          redoError: "重新執行失敗,請稍後重試",
        },
        detail: {
          new: "新建資料",
          edit: "編輯資料",
          name: "名稱",
          desc: "説明",
          dataSource: "資料源",
          status: "有效性",
          entity: "抽取實體",
          operator: "算子",
          rule: "規則",
        },
      },
      connectedByConversation: {
        title: "通對話關聯",
        list: {
          title: "通對話關聯功能",
          info: "通對話關聯功能可按照預設規則,將唔同嘅通話同對話關聯起來",
          rule: "通對話關聯規則",
          source: "請選擇資料源",
          classification: "請選擇分類",
          choose: "請選擇",
          input: "請輸入",
          save: "保存",
          cancel: "取消",
          fieldwei: "位",
          fieldNot: "有未填寫字段",
          fiedldlengthNo: "字段長度超過限制",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip: "關鍵詞可設置多個,使用逗號分隔",
          applyRole: "檢測角色",
          preOperator: "前置算子",
          applyOperatorScope: "檢測範圍",
          addOperator: "添加算子",
          noPreOperator: "無",
          from: "第",
          to: "句至第",
          end: "句",
          testRegular: "測試正則表達式",
          test: "測試",
          matchSuccess: "匹配成功",
          matchError: "匹配失敗",
          querySementicTags: "選擇語義標籤",
          reQuerySementicTags: "重新選擇語義標籤",
          sementicTag: "語義標籤",
          standardSentence: "標準句",
          similarity: "相似度",
          actions: "操作",
          noFitTag: "冇揾到啱嘅標籤,",
          addSemanticTag: "添加一個新嘅語義標籤",
          pleaseEnterRegular: "請輸入正則表達式",
          pleaseEnterRegularText: "請輸入需要驗證嘅內容",
          newAssociateComponentTip: "如果值為數字類型,請使用舊版功能",
        },
        filterManage: {
          noValueTip: "暫無資料",
          allCall: "全部通話",
          allDialogue: "全部對話",
          allWechat: "全部會話",
          getFiltersListFailed: "獲取過濾器列表失敗,請稍後重試!",
          getFilterDetailFailed: "獲取過濾器詳情失敗,請稍後重試!",
          getAllCountFailed: "獲取通話/對話總條數失敗,請稍後重試!",
        },
        listManage: {
          searchPlaceHolder: "請輸入關鍵字搜索",
          filterConditions: "篩選條件",
          records: "記錄",
          getRecordsFailed: "獲取記錄失敗,請稍後重試!",
        },
        miniListManage: { getRecordsFailed: "獲取記錄失敗,請稍後重試!" },
        applyTemplates: {
          title: "適用質檢模板",
          collectionTitle: "適用採集模板",
        },
        recordDetail: {
          showWaveAudio: "顯示錄音",
          getDetailFailed: "獲取詳情失敗,請稍後重試!",
          getSmartTagsFailed: "獲取智能標籤失敗,請稍後重試!",
          getTemplatesFailed: "獲取適用模板失敗,請稍後重試!",
        },
        changeList: {
          sampleStatus: "抽檢狀態",
          sampleAssignInspector: "分配抽檢員",
          reviewAssignInspector: "分配複核員",
          read: "已經讀",
          distributivePerson: "分配人",
          totalScore: "總分",
        },
        qualityCheckScore: {
          totalScore: "總分",
          actualScore: "得分",
          actualGrade: "評級",
          appeal: "申訴",
          remark: "備註",
          manualRemark: "人工備註",
          none: "暫無質檢詳情資料",
          interactiveTip: "使用交互識別對客服FAQ嘅使用情況進行檢測",
          interactiveIsEmptyTip: "未檢測到交互識別內容",
        },
        semanticMark: {
          semanticContent: "標註句",
          markedSimilarTitel: "已經標相似語義標籤",
          markedExcludeTitle: "已經標排除相似語義標籤",
          taskTagTitle: "任務相關語義標籤",
          similarTagTitle: "識別相似語義標籤(相似度>40%,Top 20)",
          delete: "刪除",
          markSimilar: "標記相似",
          markExclude: "排除相似",
          none: "無",
        },
        selectCallTime: {
          callTime: "通話時間/會話開始時間",
          callTimeRemind:
            "你最多可以選擇最近一年內單次唔超過一個月(31 日) 嘅通呢個對話呢紀錄",
          callRecordCount: "你已經選擇通話/對話記錄共計 {0} 條",
          getCallRecordError: "獲取記錄失敗,請稍後重試",
        },
      },
      informationEntities: {
        title: "信息實體",
        index: { import: "導入信息實體" },
        detail: {
          new: "新建信息實體",
          edit: "編輯信息實體",
          name: "名稱",
          apiName: "API名稱",
          activeFlag: "有效性",
          remark: "描述",
          logicExp: "實體正則",
          sentenceList: "實體內容",
          tip: "實體內容支持輸入多個,以空格分隔,為同一實體嘅同義詞條",
          remarkLengthError: "描述長度唔能超過255",
          nameLengthError: "名稱長度唔能超過40",
          entityDuplicateError: "實體內容重複",
        },
      },
      interactiveRecognition: {
        title: "交互識別",
        classify: "分類",
        addClassify: "添加分類",
        editClassify: "編輯分類",
        index: { import: "導入交互識別" },
        detail: {
          new: "新建智能交互",
          edit: "編輯智能交互",
          type: "類型",
          standardQuestion: "標準問題",
          similarQList: "相似問題",
          exceptSimilarQList: "排除相似問題",
          category: "分類",
          targetType: "問題檢測目標",
          status: "有效性",
          standardAnswer: "標準答案",
          similarAList: "相似答案",
          wrongAList: "錯誤答案",
        },
      },
      businessAnalysis: {
        title: "業務分析",
        tip1: "業務趨勢分析:縱坐標為通對話數,即滿足條件嘅通對話共計幾多條,橫坐標為時間",
        tip2: "相關性分析:分析多個指標嘅關聯情況,鼠標懸浮顯示相關性資料情況,資料量,相關性,重複率",
        tip3: "轉化分析:分析多指標間可形成相互嘅轉化關係,轉化嘅判定標準為同一條通話/對話,若能命中兩個唔同嘅指標,則可認為呢兩個指標存喺轉化關係",
      },
      knowledgeBase: {
        title: "知識庫",
        list: {
          add: "添加子分類",
          delete: "刪除分類",
          categoryName: "分類名稱",
          createSuccess: "子分類創建成功",
          keyWord: "關鍵詞",
        },
        detail: {
          type: "類型",
          status: "狀態",
          classification: "分類",
          standardQuestion: "標準問題",
          answerDetectionRange: "答案檢測範圍",
          standardAnswer: "標準答案",
        },
      },
      callManage: {
        title: "通話錄音篩選器",
        listTitle: "通話管理",
        detailTitle: "通話詳情",
      },
      dialogueManage: {
        title: "對話文本篩選器",
        listTitle: "對話管理",
        detailTitle: "對話詳情",
      },
      weChatManage: {
        title: "企業微信會話篩選器",
        listTitle: "企業微信會話管理",
        detailTitle: "企業微信會話詳情",
      },
      speechRecognition: {
        title: "語音識別",
        asr: "語音識別",
        asrRemind: "關閉此掣會導致語音轉文本功能同機器質檢功能失效",
        asrConfig: "ASR過濾",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "啟用後,會執行IM嘅ASR轉寫",
        asrConfigRemind: "啟用後,僅符合條件嘅通話會執行ASR內容",
        correction: "智能糾錯",
        correctionRemind:
          "啟用後, 會使用 “<a href = '/site/ semantic-intelligence/ smart-words-library/ professional-words' target =' _> 智能詞庫-專業名詞 </a>\" 中嘅內容進行強制糾錯轉換",
        correctionConfig: "糾錯過濾",
        advancedSettings: "高級設置",
        advancedSettingsTip: "為指定資料選擇指定嘅ASR模型。",
        mandarinASRModel: "普通話ASR模型",
        cantoneseASRModel: "粵語ASR模型",
        englishASRModel: "英語ASR模型",
        correctionConfigRemind: "啟用後,僅符合下述條件嘅通話會進行糾錯",
        customJudgeLogicIsNull: "自定義邏輯唔能為空",
        redoCorrection: "重新執行糾錯",
        callTime: "選擇通話時間",
        callTimeRemind:
          "你最多可以選擇最近一年內單次唔超過一個月(31日)嘅通話記錄",
        callRecordCount: "你已經選擇通話記錄共計 {0} 條",
        redoCorrectionSubmit: "提交執行",
        callTimeError: "選擇嘅通話時間範圍唔能超過一個月(31日)",
        callRecordCountError: "通話記錄條數為0,請重新選擇通話時間",
        redoCorrectionSuccess: "重新糾錯成功,請到任務中心中檢視",
        redoCorrectionError: "重新糾錯失敗,請稍後重試",
      },
      role: {
        title: "角色管理",
        create: "新建角色",
        detail: {
          title: "角色",
          new: "新建角色",
          view: "檢視角色",
          edit: "編輯角色",
          navTabNames: {
            basicInfo: "菜單權限",
            taskPermissions: "質檢任務權限",
            dataPermissions: "資料權限",
          },
          basicInfo: { roleName: "名稱", permissions: "權限" },
          taskPermissions: {
            allTask: "全部質檢任務",
            myCreatedTask: "我創建嘅質檢任務",
          },
          dataPermissions: {
            dataPermissionsConfig: "資料權限設定",
            tips: "該角色能夠見到所選分類下嘅資料",
            select: "選擇分類",
            selectPlaceholder: "請選擇需要資料可見權限嘅分類",
          },
        },
      },
      dataMigration: {
        title: "資料遷移",
        dataUpload: "資料遷入",
        dataDowload: "資料遷出",
        error: "請選擇要導出嘅質檢任務",
        list: {
          info: "請選擇要遷出嘅質檢任務",
          listInfo:
            "請選擇係咪要遷出質檢任務嘅相關資料(例如智能詞庫、語義標籤、信息實體、智能交互等)",
          noInfo: "請選擇係咪要遷出其他非質檢資料",
          ownInfo: "僅遷出任務",
          everyInfo: "遷出任務相關",
          allInfo: "遷出所有",
          callFilter: "通話過濾器",
          talkFilter: "對話過濾器",
          smartTag: "智能標籤",
        },
        upload: {
          info: "1.點擊上傳撳掣,上傳符合格式嘅JSON文件",
          uploadSuccess: "上傳成功",
          uploadError: "上傳失敗",
        },
      },
      dataAnalysis: {
        businessStatistics: "業務統計",
        qualityEffect: "質檢健康度",
        qualityAnalysis: "質檢分析",
        generalInspecitionReport: "通用報告",
        fullAmountWork: "質檢員工作量",
        intelligentTagsAnalysis: "標籤分析",
        saleIntelligentTagsAnalysis: "智能標籤分析",
        generalInspecitionReportTip:
          "資料總量: 統計當前限制嘅時間同任務範圍內, 通話同對話資料總量,其中智能質檢資料數量 & 百分比,人工抽檢資料數量 & 百分比,申訴量,抽復檢率同時間趨勢圖",
        groupTest: "分組測試",
      },
      uploadDownloadList: {
        title: "任務中心",
        tabs: {
          upload: "上傳導入",
          download: "下載導出",
          taskCenter: "任務執行",
        },
      },
      uploadDownloadModel: {
        title: "上傳下載",
        list: {
          clear: "清空",
          success: "成功",
          fail: "失敗",
          hand: "進行中",
          noInfo: "冇資料",
        },
      },
      billingCenter: {
        title: "計費中心",
        export: "導出",
        limit: "提醒限額",
        tips: "計費結果會因四捨五入有所偏差,僅供參考",
        dashboard: {
          remainingQuota: "當前剩餘配額",
          usedQuota: "累計已經用配額",
          voiceQuality: "語音質檢:",
          voice: "語音:",
          textQuality: "文本質檢:",
          text: "文本:",
          hour: "小時",
          tenThousandHour: "萬小時",
          day: "日",
          cutoff: "截止",
          warning: "(剩餘配額唔足,請盡快充值)",
          isClosed: "(關閉狀態)",
        },
        table: {
          quotaRecord: "配額記錄",
          timeFilter: "選擇日期:",
          search: "查詢",
          reset: "重置",
          id: "流水號",
          type: "類型",
          qualityType: "質檢類型",
          quotaChange: "變動",
          surplus: "餘額",
          changeTime: "時間",
          voiceQuality: "語音質檢",
          textQuality: "文本質檢",
          sizeChangeText: "每頁顯示",
          used: "使用",
          give: "授予",
          deduction: "扣除",
          callTime: "通話時間",
          count: "處理資料量",
          page: "共{0}條,{1}頁",
        },
        limitModal: {
          call: "語音質檢配額低於",
          callFix: "小時進行提醒",
          dialogue: "文本質檢剩餘時間小於",
          dialogueFix: "日進行提醒",
        },
      },
      messageCenter: {
        replyTitle: "回覆我嘅",
        answerTitle: "@我嘅",
        shareTitle: "分享我嘅",
      },
      columnsManage: { title: "質檢字段管理" },
      recordUpload: {
        list: {
          task: "質檢任務",
          user: "操作人",
          uploadTime: "上傳時間",
          endTime: "結束時間",
          total: "上傳總量",
          success: "質檢成功",
          failed: "質檢失敗",
          progress: "質檢進度",
          inProgress: "進行中",
          finished: "已經完成",
        },
        steps: {
          uploading: "上傳錄音文件",
          baseInfo: "填寫基本信息",
          start: "開始質檢",
        },
        uploadComponent: {
          dragger: "拖拽添加文件",
          or: "或",
          selectFile: "選擇文件",
          des: "支持wav、mp3格式,採樣率為8000Hz,16位嘅文件",
          limit1: "一次最多上傳",
          limit2: "個文件,每個文件大小唔超過",
          uploading: "上傳中",
          finished: "上傳完成",
          success: "成功",
          failure: "失敗",
        },
        baseInfo: {
          task: "選擇質檢任務",
          type: "選擇錄音形式",
          agentSide: "客服所喺通道",
          excel: "上傳隨路資料",
          checkFileInfo: "校驗文件信息",
          excelFileError: "請上傳隨路資料",
          checking: "校驗中...",
          reCheck: "重新校驗",
          success: "校驗成功",
          excelUoloadFail: "隨路資料上傳失敗",
          tips: {
            tip1: "1.ecxel中錄音文件名稱需要同上傳嘅錄音名稱一致,且唔能重名",
            tip2: "2.系統以錄音文件名稱、通話ID查重,重複會導致質檢失敗",
            tip3: "3.下載嘅excel錄音模版,字體顏色為紅色嘅係必填字段",
            tip4: "4.時間類型嘅字段格式為:yyyy-mm-dd hh:mm:ss,例如2021-04-20 17:36:30",
          },
        },
        title: "本地錄音上傳",
        checkTitle: "錄音質檢",
        start: "開始質檢",
        disabledTips: "請先校驗錄音文件再進行質檢",
        tips: "同名錄音文件覆蓋質檢結果同基本信息",
        tips1: "將文件拖拽到此,或者點擊上傳",
        tips2: "支持",
        tips3: "格式",
        type: "wav、mp3",
        tips4: "一次最多上傳",
        tips5: "個文件,每個文件大小唔超過",
        tipsOverSize: "當前隊列已經滿, 請先移除隊列中嘅文件再上傳",
        size: "500M",
        count: "20",
        uploaded: "已經上傳錄音文件",
        uploading: "({0}%)",
        uploadFinished: "(上傳完成)",
        bottomTitle: "錄音信息採集:",
        recordType: "選擇錄音形式:",
        double: "雙通道通話錄音",
        single: "單通道通話錄音",
        recordBaseInfo: "上傳錄音基本信息:",
        addBtn: "添加文件",
        downloadTemplate: "糢闆下載.excel",
        checkRecord: "校驗錄音信息",
        checking: "校驗中...",
        reCheck: "重新校驗",
        checkAll: "全選",
        cancelCheckAll: "取消全選",
        delete: "刪除",
        deleteLotSize: "批量刪除",
        agentChannel: "客服所喺通道:",
        left: "左通道",
        right: "右通道",
        donotKonw: "唔知道",
        mission: "選擇質檢任務:",
        uploadFail: "上傳失敗",
        repeat: "重複文件",
        overCount: "文件數量超出限制",
        overSize: "文件大小超出限制",
        uoloadFail: "上傳至雲端失敗",
        formatError: "格式錯誤",
        seccess: "校驗成功",
        fail: "校驗失敗",
        recordChecking: "錄音質檢中,請稍後...",
        recordCheckSuccess: "錄音質檢完成",
        recordCheckFail: "錄音質檢失敗,請檢查錄音係否重複或無效",
        backToIndex: "返回上傳列表",
        recordCheckIsStarted:
          "當前有質檢任務正喺執行,請喺當前任務結束後,開始新嘅任務",
      },
      customerServicePortrait: {
        title: "客服個樣",
        tip1: "句長分佈律:句長嘅分佈情況,按照句長字數,分段進行統計,只計算客服嘅",
        tip2: "語速分佈律:語速嘅分佈情況,按照語速(字/每分鐘),分段進行統計,只計算客服嘅",
        tip3: "響應時間分佈律:響應時間嘅分佈情況,只計算客服響應客戶嘅情況",
        tip4: "質檢合規:質檢點嘅合規率",
        tip5: "質檢任務平均分:質檢任務平均分",
        contol: {
          date: "選擇日期:",
          today: "今日",
          thisMonth: "本月",
          lastMonth: "上個月",
          thisQuarter: "本季度",
          lastQuarter: "上個季度",
          thisYear: "今年",
          lastYear: "舊年",
          customer: "客服:",
          search: "查詢",
          reset: "重置",
          export: "導出",
          indicator: "指標管理",
          selectPlaceholder: "請選擇需要對比嘅客服",
          warning: "最多選擇5名客服進行對比",
          warning2: "請至少選擇1名客服進行查詢",
          dataWarning: "最多只支持查詢由開始日期後366天嘅資料",
          seccuss: "記錄導出成功",
        },
        echarts: { distributionLaw: "分佈律", count: "次數", bout: "次" },
        portrait: {
          ability: "客服能力維度",
          option: "配置",
          abilityOption: "配置能力維度",
          placeholder: "輸入能力維度關鍵詞搜索",
          save: "保存",
          warning: "請至少選擇3項指標進行配置,指標數至多可選擇10項",
        },
        label: { title: "客服業務標籤" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "客服排名",
        select: {
          date: "選擇日期:",
          today: "今日",
          thisMonth: "本月",
          lastMonth: "上個月",
          thisQuarter: "本季度",
          lastQuarter: "上個季度",
          thisYear: "今年",
          lastYear: "舊年",
          group: "客服組:",
          groupPlaceholder: "請選擇客服組",
          customer: "客服:",
          customerPlaceholder: "請選擇客服",
          search: "查詢",
          reset: "重置",
          compared: "對比",
          export: "導出",
          column: "列",
          warning: "最多選擇5名客服進行對比",
          warning2: "請至少選擇一名客服",
          dataWarning: "最多只支持查詢由開始日期後366天嘅資料",
          selected: "已經選客服:",
          seccuss: "記錄導出成功",
        },
        compared: {
          title: "選擇客服",
          return: "取消",
          compared: "對比",
          tips: "請選擇2-5名客服進行對比",
          warning: "僅支持2-5名客服進行對比",
        },
        transfer: { leftTitle: "列表項", rightTitle: "展示項" },
        table: { page: "共{0}條,{1}頁", actionTitle: "操作", action: "檢視" },
      },
      conceptWord: {
        title: "概念詞",
        new: "新建概念詞",
        control: {
          search: "搜索:",
          searchPlaceholder: "名稱或關鍵字搜索",
          commit: "查詢",
          reset: "重置",
          import: "導入",
          export: "導出",
          seccuss: "記錄導出成功",
        },
        modal: { title: "導入概念詞" },
        table: {
          sizeChangeText: "每頁顯示",
          page: "共{0}條,{1}頁",
          edit: "編輯",
          delete: "刪除",
          request: "刪除後將唔可恢復,係否確認刪除?",
          cancel: "取消",
          title: {
            conceptWord: "概念詞",
            describeWordsNum: "描述詞數",
            updateTime: "更新時間",
            status: "有效性",
            effective: "有效",
            invalid: "無效",
            action: "操作",
          },
        },
        detail: {
          title: { new: "新建概念詞", edit: "編輯概念詞" },
          name: "概念詞名稱",
          namePlaceholder: "請輸入概念詞名稱",
          effectiveness: "有效性",
          effective: "有效",
          invalid: "無效",
          description: "描述詞 :",
          descriptionPlaceholder: "請輸入描述詞",
          add: "添加",
          repeat: "重複內容",
          empty: "描述詞唔能為空",
          recommendEmpty: "暫無更多推薦描述詞,請手動輸入添加",
          recommend: "智能推薦描述詞",
          cancel: "取消",
          change: "換一組",
          descriptionSelected: "已經添加描述詞:",
          save: "保存",
          success: "概念詞保存成功",
          warning: "概念詞名稱唔能為空",
          warning2: "請至少添加1個描述詞",
          submitBtnHandle: {
            title: "有勾選嘅描述詞未添加,保存將放棄未添加嘅描述詞",
            content: "點擊肯定會回到概念詞首頁,確認繼續呀?",
          },
        },
      },
      smartTools: {
        btn: "智能工具",
        title: {
          similarWords: "相關詞發現",
          search: "按詞索句",
          semanticTags: "語義標籤",
        },
        sentenceSearch: {
          placeholder: "請輸入關鍵詞尋找,支持多個關鍵詞",
          more: "檢視全文",
          pageUp: "上一頁",
          pageDown: "下一頁",
          total: "共 {0} 條記錄",
        },
        similarDiscover: {
          placeholder: "請輸入關鍵詞",
          copyed: "已經複製",
          none: "暫無相關詞語,請嘗試其他關鍵詞",
        },
        smartTags: {
          searchPlaceHolder: "請輸入關鍵詞或句子",
          createSmartTag: "新建",
          createClusteringSmartTag: "用 {0} 條資料新建",
          addFromKeyword: "添加搜索內容至相似句",
          hit: "命中",
          similar: "相似",
          name: "语义標簽名稱",
          namePlaceHolder: "請輸入語義標籤名稱",
          recallWords: "召回詞",
          recallWordsPlaceHolder: "請輸入召回關鍵詞",
          recallRegular: "召回正則",
          recallRegularPlaceHolder: "請輸入召回正則",
          rules: "規則",
          standardSentences: "標準句",
          standardSentencesPlaceHolder: "請輸入標準句",
          similaSentences: "相似句",
          similaSentencesPlaceHolder: "請輸入相似句",
          notSaveError: "請先保存語義標籤,再進行查找",
          add: "添加",
          newPage: "喺新頁面打開",
          querryError: "泛化列表中存喺相似字段(高級配置相似去重約束)",
          detailTitle: "语义標籤詳情",
        },
      },
      trainingCenter: {
        title: "訓練中心",
        edit: "編輯",
        delete: "刪除",
        seleteAll: "全選",
        cancelCheckAll: "取消全選",
        add: "添加",
        remove: "移除",
        save: "保存",
        cancel: "取消",
        fastTraining: {
          title: "快速訓練",
          titleDes: "快速訓練旨喺幫助語義標籤快速泛化到達20個相似句",
          semanticTags: "語義標籤",
          semanticTagsPlaceholder: "請輸入流程關鍵字搜索",
          standardSentence: "標準句:{0}",
          similarSentencesPlaceholder: "輸入相似句添加或搜索",
          similarSentencesAdd: "添加",
          similarSentencesSearch: "搜索",
          similarSentences: "已經添加相似句",
          trainingCount: "訓練數:{0}",
          similarSentencesRecommend: "推薦相似句",
          noResp: "暫無相關語義標籤,請更換關鍵詞",
          similarSentencesModalTitle: "相似句搜索",
          moreInfo: "檢視上下文",
          moreInfoBtn: "上下文",
          modalSearchType: { all: "全部", customer: "客服", client: "客户" },
          repetWarning: "已經存喺相同嘅相似句, 請勿重複添加",
          repetRemoveTip: "保存相似句中存喺重複內容, 已經自動去重",
        },
        trainingHistory: {
          title: "訓練歷史",
          select: {
            date: "選擇時間:",
            today: "今日",
            thisMonth: "本月",
            lastMonth: "上個月",
            thisQuarter: "本季度",
            lastQuarter: "上個季度",
            thisYear: "今年",
            lastYear: "舊年",
            search: "查詢",
            reset: "重置",
          },
          table: {
            time: "時間",
            user: "操作人",
            tag: "語義標籤",
            similarCount: "相似句標識數量",
            deleteCount: "移除句數量",
            page: "共{0}條,{1}頁",
          },
        },
        classifiedTraining: {
          title: "分類訓練",
          tagsGrounp: "標籤分組",
          placeHolder: "請選擇",
          cellTime: "選擇時間",
          search: "查詢",
          reset: "重置",
          classifiedFlag: "分類訓練情況",
          num: "數值",
          gas: "百分比",
          noData: "未分類訓練",
          noDataHeight: "未分類資料佔比較高,急需進行訓練",
          noDataSmall: "未分類資料佔比較高,建議進行訓練",
          noDataDays: "上次執行{0}日前",
          training: "訓練",
          start: "開始",
          addClass: "增加分類",
          custom: "高級篩選",
          marked: "已經標註資料勘誤",
          markedHeight: "已經{0}日未進行勘誤,建議進行勘誤",
          markedSmall: "已經{0}日未進行勘誤,急需進行勘誤",
          mark: "勘誤",
          dataCheck: "已經分類資料校驗",
          dataCheckSmall: "K-L散度高於0.5,建議訓練",
          dataCheckHeight: "K-L散度高於0.8,急需訓練",
          check: "校驗",
          history: "標註歷史記錄",
          historyTime: "每日標註同修改會喺凌晨2:00自動執行模型訓練",
          today: "今日標註",
          article: "條",
          none: "無",
          historyCheck: "歷史合計標註",
          look: "檢視紀錄",
          historyluate: "歷史資料重算",
          startluate: "發起重算",
          classData: "分類資料",
          classStatus: "訓練進度",
          next: "下一條",
          prev: "上一條",
          finish: "完成訓練",
          stop: "結束訓練",
          classDetail: "訓練詳情",
          trainingNone: "暫無可訓練嘅智能標籤",
        },
        classifiedTrainingHistory: {
          title: "訓練歷史",
          time: "選擇時間:",
          tag: "標籤類型:",
          user: "操作員:",
          data: "選擇資料源:",
          search: "查詢",
          searchBar: "搜索:",
          searchBarPlaceholder: "請輸入通話ID/對話 ID 搜索",
          reset: "重置",
          export: "導出",
          all: "全部",
          select: {
            today: "今日",
            thisMonth: "本月",
            lastMonth: "上個月",
            thisQuarter: "本季度",
            lastQuarter: "上個季度",
            thisYear: "今年",
            lastYear: "舊年",
            search: "查詢",
            reset: "重置",
          },
          table: {
            user: "操作員",
            date: "操作時間",
            data: "資料源",
            id: "通話ID/對話ID",
            tagType: "智能標籤分組",
            tagName: "標籤名稱",
            state: "狀態",
            sizeChangeText: "每頁顯示",
            page: "共{0}條,{1}頁",
            call: "通話",
            dialogue: "對話",
          },
        },
        clusteringAnnotation: {
          title: "聚類標註",
          left: { title: "聚類結果" },
          right: {
            title: "聚類句子",
            checkAll: "全選本頁",
            tagging: "標註",
            detail: "檢視",
            meaningless: "忽略",
            meaninglessTips:
              "已經忽略嘅聚類結果後續將唔再顯示, 你確認要忽略呀?",
            cancel: "取消",
          },
        },
      },
      clientCenter: {
        clientManage: { title: "客户管理", tabTitle: "客服中心客户" },
        wechatClientManage: {
          title: "企微客户",
          table: { id: "客户ID", name: "客户暱稱", tag: "客戶標籤" },
        },
      },
      collectionRule: {
        title: "採集規則",
        form: { info: "智能採集信息", infoPlaceholder: "請選擇分類" },
        table: {
          name: "字段名稱",
          props: "客户內容",
          type: "字段類型",
          desc: "字段描述",
        },
      },
      dataAdd: {
        title: "資料添加",
        types: { auto: "自動添加", manual: "手動添加" },
      },
      wechatStaff: {
        table: {
          name: "姓名",
          part: "所屬部門",
          phone: "手機號",
          email: "郵箱",
          wechartId: "企業微信ID",
          status: "喺職狀態",
        },
        info: "請先選擇需要同步資料嘅部門",
        synSuccess: "同步成功",
        init: "初始化",
        syn: "同步",
      },
      appealHistory: {
        appealTime: "申訴時間",
        cancelTime: "撤銷時間",
        reviewTime: "複核時間",
        inspector: "質檢員",
        result: "申訴結果",
        complainResult: "一共申訴 {0} 個質檢項,成功申訴 {1} 個,駁回 {2} 個",
        reason: "申訴原因",
        rejection: "駁回原因",
        cancel: "撤銷",
        tip: "確認要撤銷申訴嗎?",
      },
      appealSdk: {
        time: "通對話時間",
        externalIdCall: "通話記錄ID",
        externalIdText: "對話記錄ID",
        customerNickName: "客户暱稱",
        readStatus: "查閱狀態",
        status: "申訴狀態",
        isManual: "係咪人工質檢",
        complainButtonInfo:
          "允許申訴 {0} 次, 已經申訴 {1} 次,可申訴剩餘 {2} 次。 {3} 前可申訴",
        submit: "提交申訴",
        complain: "申訴",
        allComplain: "申訴全部",
        pendingSubmit: "待提交",
        taskIds: "質檢任務",
        graded: "評分",
        complainSuccess: "申訴提交成功",
        appealSdkIndex: { title: "智能質檢結果" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "獲取過濾器列表信息失敗, 請檢查網絡連接或者等陣再試!",
      columns: "列",
      filter: "篩選",
      noSearchResult: "冇揾到記錄",
      pleaseSearch: "請選擇一個過濾器,並嘗試搜索",
      sift: "篩選",
      create: "新建",
      search: "搜索",
      createTaskTitle: "新建任務",
      title: "標題",
      deadline: "截止時間",
      principal: "負責人",
      enqueueTime: "入隊時間",
      computeError: "計算失敗,請等陣!",
      sum: "求同",
      max: "最大值",
      min: "最小值",
      avg: "平均值",
      count: "計數",
      maxDate: "最大值(最新)",
      minDate: "最小值(最舊)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError: "獲取Insight連結地址失敗,請稍後重試!",
      },
      template: {
        learningReminder: "學習提醒",
        subscriptionDynamics: "訂閱動態",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "任務管理", chaneg: "切換" },
        qualityCheckTemplate: {
          text: "質檢模板",
          collectText: "採集模板",
          condition: { text: "質檢條件" },
          checkPoint: { text: "規則配置" },
          automaticAdd: { text: "流程自動化" },
          manualAdd: { text: "手動添加" },
          roleConfig: { text: "角色配置" },
          basicInfo: { text: "基本信息" },
          smartWordsLibrary: { text: "智能詞庫" },
          grade: { text: "質檢評級" },
          collectionRule: { text: "採集規則" },
          addData: { text: "添加資料" },
          collectionList: { text: "採集列表" },
        },
        qualityCheckWorkbench: {
          text: "質檢工作台",
          wechatText: "分析工作台",
          informationCollectText: "採集工作台",
          qualityInspectionList: {
            text: "質檢列表",
            wechatText: "分析列表",
            informationCollectText: "採集列表",
          },
          condition: { text: "質檢列表 (已經廢棄)" },
          reviewList: { text: "複核列表" },
          spotCheckList: { text: "抽檢列表" },
          caseBaseList: { text: "案例庫" },
          testSetList: { text: "質檢測試集" },
        },
        statisticsAndReports: {
          text: "統計同報表",
          wholeReport: { text: "整體報表" },
          groupReport: { text: "客服組報表" },
          agentReport: { text: "客服報表" },
          customerServiceExecution: { text: "客服分析" },
          analysisOverview: { text: "分析概覽" },
          inspectionItemsAnalysis: { text: "質檢項分析" },
        },
        wechatQa: {
          text: "企業微信質檢",
          task: { text: "分析任務" },
          baseInfo: { text: "基本信息" },
          checkPoint: { text: "分析模型配置" },
          setting: { text: "企業微信分析配置" },
          automaticAdd: { text: "流程自動化" },
          manualAdd: { text: "手動添加" },
        },
        clientCenter: { text: "客服中心" },
      },
      conditionDictionaries: {
        title: "所有條件",
        searchDefaultText: "請輸入ID或名稱",
        id: "ID",
        name: "名稱",
        conditionDetailedShow: "展開",
        conditionDetailedHide: "收起",
        remark: "描述:",
        checkRole: "角色:",
        operator: "算子:",
        operatorLogic: "邏輯:",
      },
      audioTextView: {
        customer: "客户",
        agent: "客服",
        unsupportedFormat: "暫唔支持該格式",
      },
      checkPointClassifications: {
        add: "新增分類",
        name: "名稱",
        enterToSave: "按囘車鍵保存",
        highestScore: "最高得分上限",
        minimumScore: "最低得分下限",
        defaultMinScore: "預設為0分",
        minimumScoreTitle: "得分下限",
        beginScore: "總體起評分值",
        beginScoreTitle: "起評分值",
        scoreSetTip: "最高得分上限小於各項分值總同,你可以選擇:",
        ignoreExcessSpillover: "忽略超額溢出",
        automaticWeightCalculation: "自動權重核算",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "字段" },
    checkRange: {
      numberTipOne: "第",
      numberTipTwo: "句",
      numberTipThree: "句 到 第",
      conditionLimit: "條件限制",
      roleLimit: "角色限制",
      contextLimit: "句子限制",
      notZero: "句子限制唔支持輸入0",
      startMustLessThanEnd: "起始句位置必須小於結束句位置",
    },
    waveAudio: {
      play: "播放",
      pause: "暫停",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "請輸入",
      pleaseEnterRegular: "請輸入正則表達式",
      pleaseEnterGrammar: "請輸入文法規則",
      pleaseEnterTestText: "請輸入需要驗證嘅內容",
      pleaseEnterLoudness: "請輸入響度分貝",
      pleaseEnterRangeAbility: "請輸入變化幅度",
      afterHit: "命中後檢查",
      pleaseEnterContext: "請輸入參考語句",
      wordNumber: "字數差距",
      intervalSecond: "時間間隔大於",
      minute: "每分鐘",
      wordUnit: "個字",
      test: "測試",
      testRegular: "測試正則表達式",
      testGrammar: "測試文法",
      testGrammarError: "文法測試失敗",
      regularError: "正則表達式錯誤,請重新輸入",
      pleaseEnterSemanticSentence: "請輸入需要進行語義檢測嘅句子",
      pleaseEnterAssociate: "請參考右側説明,以構建隨路資料表達式",
      querySemanticTags: "選擇語義標籤",
      reQuerySemanticTags: "重新選擇語義標籤",
      semanticTags: "語義標籤",
      standardSentence: "標準句",
      semanticName: "語義標籤",
      sentence: "標準句",
      similarity: "相似度",
      actions: "操作",
      tipOne: "未搵到合適嘅標籤,",
      tipTwo: "添加一個新嘅語義標籤",
      notLessThan: "唔小於",
      notGreaterThan: "唔大於",
      ignoreLessThan: "忽略小於",
      wordUnitSet: "字嘅句子",
      speedWordNotNull: "語速檢測最少字數同最多字數唔能都為空",
      speedWordMinValueLessThanMaxValue: "語速檢測最少字數唔能大於最多字數",
      speechGrabbingIntervalTip: "搶話間隔 >=",
      speechLengthTip: "搶話句長 >=",
      msUnit: "ms",
      sUnit: "秒",
      grabIntervalNotNull: "搶話間隔唔能為空",
      grabLengthNotNull: "搶話句長唔能為空",
      pleaseSelectEntity: "請選擇信息實體",
      pleaseSelectOperator: "請選擇操作",
      pleaseSelectBasicInfo: "請選擇基礎信息",
      pleaseEnterFixedValue: "請輸入固定值",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "匹配成功",
      matchError: "匹配失敗",
      mindetectionStatement: "最小檢測語句",
      conceptWordholder: "請選擇概念詞",
      elementHolder: "請輸入一個要素",
      associateMedol: { tabs: { data: "資料", operator: "操作符" } },
      repet: "重複",
      bout: "次",
      ignore: "忽略小於",
      ignoreAffix: "字嘅句子",
      associateData: "隨路資料",
      pretreatmentData: "預處理資料",
      reviewTaskData: "複核任務資料",
      taskData: "質檢任務資料",
      businessData: "業務記錄",
      functionOperator: "函數操作符",
      operation: "運算操作符",
      notAnyMatch: "未命中任何問題時:",
      times: "出現次數",
      newAssociateDataTip: "如果值為數字類型,請使用舊版功能",
    },
    conditionGather: {
      cycle: "週期",
      rlue: "規則",
      delete: "移除",
      sampling: "抽取",
      item: "條",
      percent: "%",
      samplingSame: "每客服抽取數量相同",
      samplingRecheck: "允許重複抽取已經抽檢條目",
      dateUnit: "號",
      samplingTip:
        "唔同規則覆蓋相同通話唔會重複抽取,已經撍未檢查嘅記錄唔會畀再次抽取。",
      add: "新增規則",
    },
    ruleGather: {
      title: "規則",
      screeningCondition: "篩選條件",
      addRule: "新增規則",
      inspector: "質檢員",
      ruleTip:
        "規則優先嘅分配模式, 喺度向下依次分配, 唔重複派, 規則內或唔匹配任意規則項嘅將隨機分配",
      moveUp: "上移",
      moveDown: "下移",
      top: "置頂",
    },
    pushRulesGather: {
      title: "規則",
      delete: "移除",
      newPush: "新增推送",
      newRule: "新增規則",
      sampling: "抽取",
      item: "條",
      percent: "%",
      samplingSame: "每客服抽取數量相同",
      dateUnit: "號",
      screeningCondition: "篩選條件",
      email: "郵箱",
      emailPlaceholder: "輸入郵箱,用逗號分割",
      updateType: "更新類型",
      pushDetail: "推送詳情",
      pushUrl: "推送URL",
      pushWay: "推送類型",
      pushMethod: "推送方式",
      pushContentType: "資料格式",
      pushData: "推送資料",
      additionalData: "附加資料",
      inspectionType: "質檢類型",
      all: "全部",
    },
    taskAdditionalData: {
      newData: "新增資料",
      delete: "移除",
      placeholder: "請輸入名稱",
      dataPlaceholder: "請輸入內容",
    },
    UdeskQaReact: { loadSdkError: "SDK加載失敗,請稍後重試!" },
    gearOptionList: { evaluation: "評價" },
    tableComponentTransfer: { leftTitle: "列表項", rightTitle: "展示項" },
    kmTinymce: {
      link: "插入/編輯連結",
      modalTitle: "插入連結",
      href: "地址",
      text: "顯示文字",
      title: "標題",
      target: "打開方式",
      none: "無",
      newWindow: "喺新窗口打開",
      buttonStyle: "按鈕款式",
      close: "關閉",
      open: "開啟",
      answerMessage: "跳轉/發送消息",
      answerMessageTitle: "發送消息撳掣",
      answerMessageText: "顯示文字",
      answerMessageContent: "消息內容",
      answerMessageButtonStyle: "按鈕款式",
      answerMessageTips: "注:發送消息按鈕僅支持web渠道,請謹慎配置",
      answerSwitchStaff: "跳轉/轉人工",
      answerSwitchStaffTitle: "轉人工按鈕",
      answerSwitchStaffText: "顯示文字",
      answerSwitchStaffRule: "按鈕規則",
      answerSwitchStaffButtonStyle: "按鈕款式",
      answerSwitchStaffTips: "注:轉人工按鈕僅支持web渠道,請謹慎配置",
      textButton: "文字撳掣",
      borderButton: "帶框撳掣",
      message: "發送消息",
      switchStaff: "轉人工",
      messageError: "撳掣內容唔能超過10個字",
      messageIsEmptyError: "撳掣內容唔能為空",
      okText: "確定",
      cancelText: "取消",
      video: "視頻",
      uploadVideo: "上傳視頻",
      uploadText: "僅支持MP4格式,大小唔超過20M",
      videoEmptyOrUploading: "視頻為空或者上載中",
      customEmoji: "表情",
    },
    massageConfiguration: {
      pushTarget: "通知目標",
      customizePushTarget: "自定義推送目標",
      sysUser: "當前系統用户",
      sysRole: "當前系統角色",
      emailPushTarget: "發送目標",
      targetEmailsPlaceholder: "請輸入郵箱,並使用英文逗號分隔",
      subject: "郵件名稱",
      SDKID: "SDK識別ID",
      content: "通知內容",
      sdkPushType: "推送方案",
      sdkPushURL: "推送URL",
      pushData: "推送資料",
      sdkPushWay: "推送方式",
      dataType: "資料格式",
      additionalData: "附加資料",
      name: "消息名稱",
      filter: "篩選條件",
      triggerType: "觸發機制",
      pushCycle: "定時定頻",
      pushContent: "報告內容",
      qualityList: "質檢結果列表",
      designated: "指定人員",
      messageType: "消息類型",
    },
    taskCard: {
      template: { cancelCollection: "取消收藏", collection: "收藏" },
    },
    semanticTagsSelect: { index: { standardSentence: "標準句:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "收藏",
          calls: "個通話",
          createdBy: "創建人:",
          createdOn: "創建時間:",
        },
        fileItem: {
          uIProvidesIcon: "Ui提供icon",
          minute: "分鐘",
          callClip: "通話片段",
          start: "開始",
          End: "結束",
          employees: "員工:",
          Customer: "客户:",
          sessionTime: "會話時間:",
          reasonForRecommendation: "推薦理由:",
          addedBy: "添加者:",
          addTime: "添加時間:",
        },
        modalAddFile: {
          caseTitle: "案例標題",
          addSomeClipsToTheCaseLibrary: "添加部分片段至案例庫",
          addToCaseBase: "添加到案例庫",
          storedIn: "存儲於",
          pleaseSelect: "請選擇",
          reasonsForRecommendation: "推薦理由",
          pleaseEnter: "請輸入",
        },
      },
      shareModal: {
        index: {
          customer: "客户:",
          employees: "員工:",
          timeliness: "時效性:",
          recordingLinkValidWithinDays: "日內錄音鏈接有效",
          sharingInstructions: "分享説明:",
          shareSuccess: "分享成功!",
          cancel: "取消",
          share: "分享",
          copySucceeded: "複製成功!",
          copyShareLink: "複製分享連結",
          shareWithColleagues: "分享畀同事",
          shareWithCustomers: "分享畀客户",
          staff: "員工",
          selectColleagues: "選擇同事",
          pleaseSelectColleaguesToShare: "請選擇要分享嘅同事",
          pleaseEnterTheSharingDescription: "請輸入分享説明",
          taskName: "任務:",
          recordingShareValidWithinDays: "日內分享連結有效",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "唔揾到應該文件!",
          sale: "銷售:",
          customer: "客户:",
          topicDetection: "話題檢測:",
          keyEvents: "關鍵事件:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "選擇同事",
          helpContent: "求助內容",
          visibleRange: "可見範圍",
          seekCounseling: "尋求輔導",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "尋求建議", concatText: "向" },
      },
      customerInsight: {
        index: {
          customerInformation: "客户信息",
          customerInsight: "客户洞察",
          customerLabel: "客戶標籤",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "話術推薦",
          objectionHandlingScripts: "異議處理話術",
          productSellingPointScripts: "產品賣點話術",
          knowledgePointRecommendation: "知識點推薦",
          relatedKnowledgePoints: "關聯知識啲",
          recommendedCases: "推薦案例",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "話術推薦",
          caseRecommendation: "案例推薦",
          hit: "命中",
          miss: "未命中",
          supervisionItem: "監督項",
          implementation: "執行情況",
          reInspection: "重新質檢",
          supervisionItemSettings: "監督項設置",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "電話通話",
          enterpriseWeChatCall: "企微通話",
          enterpriseMicroConversation: "企微會話",
          open: "開啟",
          close: "關閉",
          conversationScenario: "會話場景",
          monitorConversationChannels: "監督會話渠道",
          permanentlyValid: "永久有效",
          permanentlyInvalid: "永久無效",
          customValidity: "自定義有效期",
          termOfValidity: "有效期",
          newRating: "新建評級",
          whenConversation: "當會話",
          hit: "命中",
          miss: "未命中",
          time: "時",
          rated: "評級為",
          qualified: "合格",
          unqualified: "唔合格",
          modifiedSuccessfully: "修改成功",
          classificationName: "分類名稱",
          supervisionObject: "監督對象",
          entryintoforceTime: "生效時間",
          supervisionItemRating: "監督項評級",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "請檢查監督項評級係否正確",
          ratingRules: "評級規則",
          settingOfRatingRulesForSupervisionItems: "監督項評級規則設置",
        },
      },
      reInspect: {
        index: {
          keyEvents: "關鍵事件",
          supervisionItem: "監督項",
          conversationScenario: "會話場景",
          customerLabel: "客戶標籤",
          reDetectionRules: "重新檢測規則",
        },
      },
      audioPlayerBar: {
        index: { downloadRecording: "下載錄音", downloadText: "下載文本" },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "上傳圖片",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "請選擇大小唔超過2048kb嘅圖片",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "或",
            and: "同",
            wrong: "非",
            matchTo: "匹配到",
            rule: "規則",
            satisfactionOperator: "滿足算子",
            customerLabel: "客戶標籤",
            keyEvents: "關鍵事件",
            knowledgePoints: "知識點",
            sessionLabel: "會話標籤",
            supervisionItem: "監督項",
          },
          linkExplorer: { linkInTheText: "文中連結:" },
        },
      },
    },
    createForm: { field: { customComponentNotFound: "未揾到自定義組件:" } },
    sdkLoaderBase: {
      component: { custom: "自定義" },
      dropdownMonit: {
        updateSucceeded: "更新成功",
        areYouSureToDelete: "肯定刪除呀?",
        custom: "自定義",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm: "保存當前報表配置===表單",
        saveCurrentReportConfiguration: "保存當前報表設定",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "請輸入常用設置名稱,最大長度為20!",
        preservation: "保存",
      },
      template: {
        saveCurrentReportConfiguration: "保存當前報表設定",
        updateCurrentReportConfiguration: "更新當前報表設定",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "工單信息",
        workOrder: "工單",
        triggerScenario: "觸發場景",
        associatedCallConversation: "關聯通話/對話",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText: "以當前通/對話文本進行測試",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "第", day: "日" },
      businessSummary: {
        normal: {
          equalTo: "等於",
          notEqualTo: "唔等於",
          contain: "包含",
          excluding: "唔包含",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "我就係我, 係唔一樣嘅煙火@156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi,我係機械人質檢預警消息通知,由於10月17日添加到群",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "我就係我, 係唔一樣嘅煙火@156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi,我係機械人質檢預警消息通知,由於10月17日添加到群",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "溝通主題",
          conversationSummary: "會話小結",
          serviceSuggestions: "服務建議",
        },
        template: { smartTags: "智能標籤" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "點擊了解更多" },
      schema: {
        textRobot: "文本機械人",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "訓練師護航、文檔問答、任務式對話、表格問答、圖譜問答",
        capitalRetentionRobot: "留資機械人",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "豐富嘅追問留資策略,高擬人度溝通,保障高留資率、線索高效自動轉化",
        outboundRobot: "外呼機械人",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "工單完結回訪、服務滿意度調研、運維故障警告提示、訂單發貨提醒、電商活動通知、候選人預約,助力業務自動化、業務效率提升",
        incomingRobot: "呼入機械人",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "靈活集成工單、訂單等業務系統, 實現流程自動化; 語音语义模型定製化識別, 業務效果立竿見影; 實體 (手機號、訂單金額、訂單時間、地址、 ......) 識別,快速支持複雜業務",
        intelligentQualityInspection: "智能質檢",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "全量質檢,同時高效,準確率85%",
        intelligentSessionAnalysis: "智能會話分析",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "洞察業務短板,聆聽客户心聲,提升營銷轉化",
        enterpriseWechatConversationAnalysis: "企業微信會話分析",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "高效識別潛喺商機、風險,營銷轉化率提升20%",
        assistant: "坐席助手",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "坐席助手能保障新手客服完成服務,提升熟手客服通話質量。 令每一個坐席都係你嘅王牌",
        digitalPeople: "數字人",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          '"面對面"擬人化交流溝通,支持聲音、文字、視頻、觸控多模態交互,實現沉浸式交流體驗,提升品牌形象同用户服務體驗',
        aIKnowledgeZhongtaiEnterpriseSearch: "AI知識中台·企業搜索",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "知識搜索,知識共享,文檔搜索,資料庫對接搜索",
        aIKnowledgeCenterKCsKnowledgeBase: "AI知識中台·KCS知識庫",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "提供知識嘅全生命週期管理,機械人同IM知識庫統一維護,支持對接第三方知識庫,知識模板、原子知識等強大功能帶嚟更加便捷嘅知識管理體驗",
        aIKnowledgeCenterKnowledgeMap: "AI知識中台·知識圖譜",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "一站式圖譜構建融合企業知識,大圖展示知識細節,對接能力打造定製化體驗",
        modularDevelopmentPlatformOriginalEngineASR:
          "模塊化開發平台·原心引擎·ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "採用端到端算法,實現業務定製化嘅效果體驗,自然場景識別率超過90%,個性化詞彙識別超過97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "模塊化開發平台·智慧營銷",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "利用深度學習算法,結合用户畫像,實現商品嘅精準推薦,提高銷售轉化",
        modularDevelopmentPlatformUserPortrait: "模塊化開發平台·用户畫像",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "根據用户資料同對話資料精準構建用户畫像,充分描述客户特徵",
        modularDevelopmentPlatformIntelligentRecommendation:
          "模塊化開發平台·智慧推薦",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "根據用户畫像同產品畫像,自動畀用戶推薦可能感興趣嘅產品,實現千人千面嘅個性化推薦",
        modularDevelopmentPlatformNLPPAAS: "模塊化開發平台·NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "提供高準確率嘅NLP基礎能力,涵蓋詞、句子、文章等唔同粒度嘅底層能力",
      },
    },
    qualityScore: {
      index: {
        show: "展示:",
        displayBySecondaryClassification: "按二級分類展示",
        showByRule: "按規則展示",
        pleaseEnterClassificationruleName: "請輸入分類/規則名稱",
        clickCopy: "點擊複製",
        hitDetailsOfOperatorsInRules: "規則內算子命中詳情:",
        hit: "命中",
        miss: "未命中",
        filter: "篩選:",
        clickToViewDetails: "點擊查看詳細信息",
      },
      components: {
        flowNodeList: {
          branch: "分",
          pleaseSelect: "請選擇",
          viewTheCompleteProcess: "檢視哂成流程",
          thereIsCurrentlyNoProcessAvailable: "暫無流程",
        },
        pointItem: { xiangHit: "項命中" },
        flowNodeListModal: { index: { completeProcess: "完整流程" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "流程質檢任務發生變更,請重新申訴。",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "該資料已經畀定期清除",
        subject: "主題:",
        description: "描述:",
      },
      components: {
        addToTask: { index: { addToQualityInspectionTask: "添加至質檢任務" } },
      },
    },
    relatedDetail: { index: { associatedCallConversation: "關聯通話/對話" } },
    samplingTarget: {
      index: {
        time: "時",
        samplingQuantityIsLessThan: "抽檢數量低於",
        alertWhen: "時進行預警",
        everyday: "每日",
        weekly: "每週",
        monthly: "每月",
        samplingInspectionTargetSavedSuccessfully: "抽檢目標保存成功",
        personnel: "人員",
        eachCustomerService: "每位客服",
        cycle: "週期",
        samplingInspectionTarget: "抽檢目標",
        strip: "條",
        alertSettings: "預警設置",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "客服",
        complete: "完成",
        Objective: "/ 目標",
        everyday: "每日",
        weekly: "每週",
        monthly: "每月",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "群聊互動率",
        maximumResponseIntervalTime: "應答最長間隔時間",
        numberOfPreemptiveCalls: "搶話次數",
        numberOfMessages: "消息條數",
        strip: "條",
        proportionOfMessages: "消息條數佔比",
        speechPercentage: "講嘢佔比",
        longestCustomerTalk: "最長客户發言",
        numberOfIndepthDiscussions: "深入討論次數",
        second: "次",
        maximumDurationOfContinuousSpeech: "最長持續説話時長",
        questionFrequency: "提問頻次",
        customer: "客户",
        keyEvents: "關鍵事件:",
        opportunity: {
          index: {
            generationFailed: "生成失敗",
            generateOpportunityPoints: "生成機會點",
          },
        },
        sessionSummary: {
          index: { generationFailed: "生成失敗", generateSummary: "生成小結" },
        },
      },
      index: {
        areYouSureToDeleteIt: "肯定刪除呀?",
        add: "添加",
        opportunityPoints: "機會點",
        conversationSummary: "會話小結",
        cancel: "取消",
        determine: "確定",
        edit: "編輯",
        establish: "創建",
        conversationalInsights: "會話洞察",
        keyEvents: "關鍵事件",
        numKeyEvents: "個關鍵事件",
        questionAnalysis: "提問分析",
        find: "發現",
        questions: "次提問",
        conversationAction: "會話動作",
        customer: "客户",
        sale: "銷售",
        sessionLabel: "會話場景",
        employeeQuestions: "員工提問",
        customerQuestions: "客戶提問",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "客户",
        customerService: "客服",
        replyTo: "回覆至",
        addressee: "收件人:",
      },
    },
    listManage: {
      template: {
        reIdentification: "重新識別",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "重新識別會消耗轉寫時長,請謹慎操作",
        determine: "確定",
        executionSuccessful: "執行成功",
        addToQualityInspectionTask: "添加至質檢任務",
        batchOperation: "批量操作",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "添加成功",
        addToQualityInspectionTask: "添加至質檢任務",
        qualityInspectionTask: "質檢任務",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "檢測角色",
        detectionRange: "檢測範圍",
        sentence: "句",
      },
    },
    inspectionDetail: {
      template: { theDataHasBeenClearedPeriodically: "該資料已經畀定期清除" },
    },
    backToSubSystem: { index: { returnToBusinessSystem: "返回業務系統" } },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "問題抽取",
        businessSummary: "業務小結",
        keyWord: "關鍵詞",
      },
      components: {
        problemExtraction: {
          no: "否",
          customerServiceDidNotAnswer: "客服未解答",
          customerServiceHasAnswered: "客服已經解答",
          problemUnresolved: "問題未解決",
          theProblemHasBeenResolved: "問題已經解決",
          customerIssue: "客户問題:",
          customerServiceAnswer: "客服解答:",
          reExtractQuestions: "重新抽取問題",
          clickToExtractQuestions: "點擊撍問題",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "畫像名稱",
            backgroundDescription: "背景描述",
            customerLabel: "客戶標籤",
            labelManagement: "標籤管理",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "新建" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "追問設置" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "增加追問",
              remove: "移除",
              addQuestioning: "添加追問",
              section: "第",
              secondInquiry: "次追問",
              questioningScore: "追問得分",
            },
          },
        },
        index: {
          accordingToRules: "按規則",
          byScore: "按得分",
          whenMissRule: "當未命中規則",
          whenConductingQuestioning: "時,進行追問",
          pleaseSelectARule: "請選擇規則",
          ruleCannotBeEmpty: "規則唔能為空",
          currentScriptScore: "當話術得分≤",
          pleaseEnterTheScore: "請輸入得分",
          scoreCannotBeEmpty: "得分唔能為空",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "旁白:" },
          pPT: { slide: "幻燈片" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "話術點評",
              script: "話術",
              semantics: "語義",
              violatingWords: "違規詞",
              speechSpeed: "語速",
              emotion: "情緒",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "話術點評",
              regenerate: "重新生成",
              generate: "生成",
              hitSituation: "命中情況",
              noSemanticTagsMatched: "未匹配到語義標籤",
              matchToSemanticTags: "匹配到語義標籤",
              appealToBeSubmitted: "申訴待提交",
              ratingHasBeenReviewed: "評分已經複核",
              ratingAppealInProgress: "評分申訴中",
              expressionScore: "表達得分",
              moodParticlesTimes: "語氣詞, {0}次",
              changeTheModalParticlesTo: "語氣詞改為",
              second: "次",
              interrogation: "追問",
              questioningWithoutScoring: "追問唔計分",
              branch: "分",
              matchingRate: "匹配率",
              ratingAppeal: "評分申訴",
              changeTo: "改為",
              wordPerMinute: "字每分鐘",
              completeScript: "話術完整",
              incompleteScript: "話術唔完整",
              semanticHit: "語義命中",
              semanticMisses: "語義未命中",
              scriptViolation: "話術違規",
              modified: "已經修改",
              notChanged: "未修改",
              keyWord: "關鍵詞",
              semanticLabels: "語義標籤",
              semanticMatchingRate: "語義匹配率",
              violatingWords: '違規詞"',
              delete: "刪除",
              unmatched: "未匹配",
              matching: "匹配",
              matchTo: "匹配到",
              notMatchedTo: "未匹配到",
              scriptScore: "話術得分",
              standardScript: "標準話術",
              emotionalScore: "情感得分",
              speechSpeedScore: "語速得分",
              viewResolution: "檢視解析",
              nodeConfiguration: "節點配置",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "本題作答時間超時,自動提交作答內容",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "本題作答時間超時,自動提交作答內容",
          },
          correctBtn: {
            saveSuccessful: "保存成功",
            errorCorrection: "糾錯",
            cancel: "取消",
            preserve: "保存",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "得分規則",
            cancel: "取消",
            determine: "確定",
          },
          scoringRuleItem: {
            whole: "全部",
            arbitrarily: "任意",
            pleaseEnterARuleName: "請輸入規則名稱",
            standardScript: "標準話術",
            if: "如果",
            and: "且",
            or: "或",
            thenScore: "則得分",
            pleaseEnterAScore: "請輸入分值",
            bonusPoints: "加分",
            minusPoints: "減分",
            hit: "命中",
            misses: "未命中",
            keyWord: "關鍵詞",
            semantics: "語義",
          },
          title: {
            ruleValidation: "規則校驗",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "得分規則(當前話術總得分為",
            points: "分 )",
            editRules: "編輯規則",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "暫無標籤" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "溝通目標",
              scoringCriteria: "評分標準",
            },
          },
        },
        index: {
          generating: "生成中",
          generateDialogueFramework: "生成對話框架",
          theme: "主題",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "提示語",
            generatePromptLanguage: "生成提示語",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "溝通階段",
          communicationObjectives: "溝通目標",
          scoringCriteria: "評分標準",
          suggestionsForImprovement: "提升建議",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "流程得分",
            contentIntegrity: "內容完整性:",
            NumberOfCompletedProcesses: "%,完成流程數",
            keyHitPointsTotal: "要點命中:共",
            notReached: "個未達到",
            accurateFollowupAccumulationOfStandardScripts:
              "跟讀準確:標準話術累積",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "個字,學員跟讀命中嘅字數共",
            aWord: "個字",
            languageNormsYes: "語言規範:有",
            emotionsAppearing: "情緒:出現",
            speechSpeedSpeakingTooFast: "語速:語速過快",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "語氣詞:語氣詞過多出現",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "添加至忽略標籤",
        areYouSureYouWantToReExecuteTheSmartLabel: "確定要重新執行智能標籤呀?",
        reExecute: "重新執行",
      },
      component: { successfullyAdded: "添加成功" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "關閉所有通知",
        reQualityInspectionTask: "重新質檢任務",
        requalityInspection: "重新質檢",
        waiting: "等待中",
        viewDetails: "檢視詳情",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "檢測時間:" } },
        },
        template: { responseOpeningSentence: "應答起始句" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "生成失敗", generate: "生成" },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "暫無資料" } },
      },
      index: { regenerate: "重新生成" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "肯定刪除呀?",
        operationSuccessful: "操作成功",
      },
      template: { delete: "刪除" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "請選擇問題!",
        similarQuestionRecommendation: "相似問推薦",
        selectAllOnPage: "當頁全選",
        cancel: "取消",
        preserve: "保存",
        changeBatch: "換一批",
      },
    },
    listControl: { index: { columnControl: "列控制" } },
    loginUserAvatar: { index: { switch: "切換" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "實時添加資料時,質檢任務執行仍需耗時。 具體時間取決於質檢資料量大小,喺幾分鐘到2小時唔等。",
      },
    },
    bEditor: {
      index: {
        delete: "刪除",
        cancel: "取消",
        confirm: "確定",
        insert: "插入",
        width: "寬度",
        height: "高度",
        clearContent: "清除內容",
        revoked: "撤銷",
        redo: "重做",
        fontSize: "字號",
        rowHeight: "行高",
        wordSpacing: "字間距",
        paragraphIndent: "段落縮進",
        increaseIndent: "增加縮進",
        decreaseIndent: "減少縮進",
        border: "邊框",
        color: "顏色",
        textColor: "文字顏色",
        backgroundColor: "背景顏色",
        temporaryColor: "臨時顏色",
        bold: "加粗",
        italic: "斜體",
        underline: "下劃線",
        strikethrough: "刪除線",
        font: "字體",
        textAlignment: "文本對齊",
        left: "居左",
        centered: "居中",
        right: "居右",
        bothEnds: "兩端",
        leftFloat: "左浮動",
        rightFloat: "右浮動",
        subrupt: "上標",
        subscript: "下標",
        clearStyle: "清除款式",
        title: "標題",
        regular: "常規",
        orderedList: "有序列表",
        unorderedList: "無序列表",
        reference: "引用",
        code: "代碼",
        link: "連結",
        clearLink: "清除連結",
        horizontalLine: "水平線",
        media: "媒體",
        mediaLibrary: "媒體庫",
        smallExpression: "小表情",
        fullScreen: "全屏",
        exitFullScreen: "退出全屏",
        enterLinkText: "輸入連結文字",
        enterLinkAddress: "輸入連結地址",
        enterTheLinkAddressAndPressEnter: "輸入連結地址並回車",
        openInNewWindow: "喺新窗口打開",
        removeLink: "移除連結",
        playingAudioFiles: "播放音頻文件",
        playVideoFiles: "播放視頻文件",
        embeddedMedia: "嵌入式媒體",
        image: "圖像",
        video: "視頻",
        audio: "音頻",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "名稱",
        description: "描述",
        type: "類型",
        status: "狀態",
        actions: "操作",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "任務名稱",
        description: "描述",
        form: "質檢表單",
        actions: "操作",
        createUser: "創建人",
      },
      samplingRecords: {
        createTime: "時間",
        type: "類型",
        operateUser: "操作人",
        number: "添加數量",
        filterConditionList: "篩選條件",
        sampleRuleStr: "抽樣條件",
        timeGreaterThan: "時間大於",
        timeLessThan: "時間小於",
      },
    },
    customFilters: {
      createdAt: "通話時間",
      customerId: "客户",
      agentId: "客服",
      userGroupId: "客服組",
      state: "狀態",
      lastEvaluateUserId: "最後評分人",
      submitTime: "最後提交時間",
      totalScore: "總分",
      formItemForbidIdList: "禁止項",
      formItemDeadlyIdList: "致命項",
    },
    samplingInspection: {
      callTime: "通話時間",
      dialogTime: "對話時間",
      originator: "分配人",
      createTime: "創建時間",
      updateTime: "更新時間",
      status: "流程狀態",
      score: "分數",
      actions: "操作",
      reviewer: "複核員",
      samplingOfficer: "抽檢員",
      id: "通話記錄ID",
      time: "通話時間",
      phone: "客户電話",
      staff: "客服",
      inspectionStatus: "質檢狀態",
      samplingStatus: "抽檢狀態",
      inspectionGrade: "評級",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "標準詞",
        synonymWords: "同義詞",
        synonymWordsNumber: "同義詞數",
        useFrequency: "使用頻率",
        effectiveness: "有效性",
        operation: "操作",
      },
      common: {
        useFrequency: "使用頻率",
        effectiveness: "有效性",
        operation: "操作",
      },
      professionalWords: {
        professionalWords: "專業名詞",
        useFrequency: "使用統計",
        homophonic: "熱詞(同音替換)",
        target: "名詞替換",
        targetWords: "替換詞",
        homophonyWords: "同音屏蔽詞",
        operation: "操作",
      },
      attentionWords: "關注詞",
      ignoreWords: "忽略詞",
      wordsWeight: {
        selfDefineWeightWords: "自定義權重詞",
        weightWords: "權重詞",
        weight: "權重",
        useFrequency: "使用頻率",
        effectiveness: "有效性",
        operation: "操作",
      },
      corpusData: {
        corpusDescription: "語料描述",
        updateTime: "更新時間",
        wordsCount: "詞數",
        effectiveness: "有效性",
        operation: "操作",
        corpusContent: "內容",
      },
    },
    semanticTags: {
      title: "語義標籤",
      tags: "標準句",
      orgName: "所屬組織",
      tagCount: "訓練數",
      usingCount: "引用數",
      activeFlag: "有效性",
      suggestion: "訓練建議",
      actions: "操作",
    },
    intelligentTags: {
      tagName: "名稱",
      tagDesc: "説明",
      tagRefCnt: "標記數",
      categoryName: "標籤分類",
      eventName: "事件分類",
      tagStatus: "有效性",
      actions: "操作",
      tagType: "標籤類型",
    },
    dataPreprocess: {
      name: "名稱",
      desc: "説明",
      dataSource: "資料源限制",
      type: "類型",
      status: "有效性",
      actions: "操作",
    },
    informationEntities: {
      name: "名稱",
      apiName: "API名稱",
      type: "類型",
      remark: "描述",
      activeFlag: "有效性",
      actions: "操作",
    },
    interactiveRecognition: {
      questionAndAnswer: "標準問題&答案",
      type: "類型",
      status: "狀態",
      actions: "操作",
      question: "問題",
    },
    role: { id: "ID", name: "名稱", actions: "操作" },
    uploadDownload: {
      taskName: "文件名稱",
      createTime: "日期",
      size: "大小",
      createUserName: "操作員",
      status: "狀態",
      result: "結果",
      actions: "操作",
    },
    taskCenterList: {
      name: "任務內容",
      createTime: "提交時間",
      startTime: "開始時間",
      endTime: "結束時間",
      createUserName: "操作員",
      status: "狀態",
      result: "結果",
      actions: "操作",
    },
    customerInformation: {
      title: "客服信息",
      data: "特檢視異常資料",
      list: {
        agentNo: "工號/客服ID",
        agentName: "客服",
        agentTel: "電話",
        agentEmail: "郵箱",
        updateTime: "更新時間",
        actions: "操作",
      },
      upload: { second: "2.點擊導入撳掣,上傳符合模板格式嘅EXCEl文件" },
    },
    IndexManagement: {
      title: "指標管理",
      list: {
        indicatorName: "名稱",
        indicatorDesc: "描述",
        activity: "有效性",
        inspectDataSource: "資料源",
        sysType: "類型",
        agentGroups: "客服組",
        actions: "操作",
      },
      detail: {
        nameEmpty: "名稱唔能為空",
        descript: "描述",
        descriptEmpty: "描述唔能為空",
        active: "有效性",
        activeEmpty: "有效性唔能為空",
        dataSource: "資料源",
        dataSourceEmpty: "資料源唔能為空",
        grounp: "客服組",
        grounpEmpty: "客服組唔能為空",
        grounpHolder: "請選擇客服組",
        type: "評分方式",
        typeNone: "評分方式唔能為空",
        base: "基礎分",
        baseNone: "基礎分唔能為空",
        positive: "正面因素",
        positiveNone: "正面因素唔能為空",
        customAdd: "添加篩選",
        negative: "負面因素",
        negativeNone: "負面因素唔能為空",
        customOK: "篩選條件:",
        judgeStrategy: "條件規則:",
        other: "其他因素",
        otherAdd: "添加因素",
        agent: "原始資料",
        section: "區間數量",
        sectionNone: "區間數量唔能為空",
        step: "區間步長",
        stepNone: "區間步長",
        save: "保存",
        cancel: "取消",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "全部",
      staff: "僅本人",
      department: "本部門同以下",
    },
    gongStatisticsDate: { week: "週", month: "月", season: "季" },
    gongCoachTaskStatus: {
      wait: "待輔導",
      already: "已經輔導",
      ignore: "已經忽略",
    },
    callRangeStatus: {
      all: "全部通話",
      sale: "員工發起",
      customer: "客户發起",
    },
    todoListType: {
      later: "等陣聽",
      help: "輔導求助",
      contact: "稍後聯繫",
      send: "發送資料",
      warn: "風險預警",
    },
    frequencyTypes: { everyDay: "每日", everyWeek: "每週", everyMonth: "每月" },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "未分類資料訓練",
        markedDataCorrigenda: "已經標註資料勘誤",
        classifiedDataCheck: "已經分類資料校驗",
        markedHistoryRecord: "標註歷史記錄",
        historyDataRecalculate: "歷史資料重算",
      },
      dataExtractMode: {
        random: "完全隨機",
        intelligentRecommend: "智能推薦",
        custom: "自定義",
        specifiedClass: "指定分類",
      },
      smartRatesList: {
        markRate: "標註率",
        corrigendaRate: "勘誤率",
        kl: "K-L散度",
        signalNoiseRate: "信噪比",
      },
    },
    visibleRangeTypes: {
      all: "全部",
      customerGroup: "客服組",
      userDefined: "自定義",
    },
    samplingRateTypes: { everyDay: "每日抽取", everyWeek: "每週抽取" },
    taskRemindTypes: {
      confirm: "質檢任務記錄需要確認時,發送提醒",
      appeal: "質檢任務記錄發起申訴時,發送提醒",
    },
    weekDays: {
      monday: "星期一",
      tuesday: "星期二",
      wednesday: "星期三",
      thursday: "星期四",
      friday: "星期五",
      saturday: "星期六",
      sunday: "星期日",
    },
    reportDateTypes: { dailyReport: "日報表", weeklyReport: "週報表" },
    samplingRangeDayTypes: { today: "今日", yesterday: "尋日" },
    samplingRangeWeekTypes: { thisWeek: "本週", lastWeek: "上週" },
    samplingRuleTypes: { proportion: "比例抽樣", random: "隨機抽樣" },
    taskStatusTypes: {
      waitEvaluate: "待評價",
      waitSubmit: "待提交",
      waitConfirm: "待確認",
      waitReview: "待複核",
      completed: "已經完成",
      all: "全部",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "直接評分",
        weightedScore: "權重評分",
        deductScore: "扣分",
        directScoreSecondLevel: "直接評分-二級",
        weightedScoreSecondLevel: "權重評分-二級",
        deductScoreSecondLevel: "扣分-二級",
      },
    },
    fieldOperators: {
      is: "等於",
      not: "唔等於",
      contains: "包含",
      notContains: "唔包含",
      not_contains: "唔包含",
      containsAny: "包含任意",
      contains_any: "包含任意",
      is_any: "包含任意",
      not_any: "唔包含任意",
      notContainsAny: "唔包含任意",
      not_contains_any: "唔包含任意",
      containsAll: "包含所有",
      contains_all: "包含所有",
      notContainsAll: "唔包含所有",
      nont_contains_all: "唔包含所有",
      isNull: "為空",
      is_null: "為空",
      isNotNull: "唔為空",
      is_not_null: "唔為空",
      greaterThan: "大於",
      greater_than: "大於",
      greaterThanEq: "大於等於",
      greater_than_eq: "大於等於",
      lessThan: "小於",
      less_than: "小於",
      lessThanEq: "小於等於",
      less_than_eq: "小於等於",
      between: "介乎",
      notBetween: "唔介乎",
      not_between: "唔介乎",
      relative: "等於",
      set: "設置為",
      belongs: "屬於",
      notBelongs: "唔屬於",
      not_belongs: "唔等於",
      today: "今日",
      yesterday: "尋日",
      the_day_before_yesterday: "前日",
      tomorrow: "聽日",
      next_seven_day: "今後七天",
      last_seven_day: "最近七天",
      this_week: "本週",
      next_week: "下週",
      last_week: "上週",
      this_month: "本月",
      next_month: "下月",
      last_month: "上月",
      this_year: "今年",
      next_year: "明年",
      last_year: "舊年",
      exact_match: "精確匹配",
      add_value: "新增值",
      remove_value: "刪除值",
      set_null: "置空",
      isAny: "任意等於",
      notAny: "任意唔等於",
      belongTo: "屬於",
      notBelongTo: "唔屬於",
      hasAny: "包含任意",
      notHasAny: "唔包含任意",
      prefixContains: "開頭等於",
      prefixNotContains: "開頭唔等於",
      suffixContains: "結尾等於",
      suffixNotContains: "結尾唔等於",
      nextSevenDay: "今後7天",
      lastSevenDay: "最近7天",
      thisWeek: "本週",
      nextWeek: "下週",
      lastWeek: "上週",
      thisMonth: "本月",
      nextMonth: "下月",
      lastMonth: "上月",
      thisYear: "今年",
      nextYear: "明年",
      lastYear: "舊年",
      after: "晚於(包含當天)",
      before: "早於(包含當天)",
      allMatching: "全部匹配",
      allNotMatching: "全部唔匹配",
      hit: "命中",
      not_hit: "未命中",
    },
    fieldsOperators: {
      is: "等於",
      is_null: "同時為空",
      head_equal: "開頭等於",
      tail_equal: "結尾等於",
      is_not_null: "同時唔為空",
      not: "唔等於",
      diff_equal: "相差等於",
      diff_greater_than: "相差大於",
      diff_greater_than_eq: "相差大於等於",
      diff_less_than: "相差小於",
      diff_less_than_eq: "相差小於等於",
      hour_equal: "同小時",
      day_equal: "同日",
      week_equal: "同週",
      month_equal: "同月",
      year_equal: "同年",
      day: "日",
      minute: "分鐘",
      hour: "小時",
      part_equal: "部分等於",
    },
    samplingColumnCaptions: {
      created_at: "通話時間",
      agent_id: "客服",
      get_direction: "通話類型",
      call_result: "通話結果",
      survey: "評價",
      derived_from_id: "來源",
      quit_queue_why: "排隊狀態",
      queuing_duration: "排隊耗時",
      talking_seconds: "通話時長",
      ringing_seconds: "響鈴時間",
      hangup_by: "通話掛斷方",
    },
    callTypes: {
      callIn: "呼入",
      callOut: "呼出",
      threeParty: "(三方)",
      consultation: "(諮詢)",
      insert: "(強插)",
      monitor: "(監聽)",
      transfer: "(轉接)",
      intercept: "(攔截)",
      transferOutside: "(轉接外線)",
      threePartyOutside: "(三方外線)",
      consultingOutside: "(諮詢外線)",
    },
    callResults: {
      staffAnswer: "客服接聽",
      staffNotAnswer: "客服未接",
      customerAnswer: "客戶接聽",
      customerNotAnswer: "客戶未接",
      phoneBusy: "話機佔線",
      phoneOffline: "話機離線",
      customerSpeedHangUp: "客户速掛",
      customerHangUp: "客户掛機",
      queueTimeout: "排隊超時",
      queueGiveUp: "放棄排隊",
      outlineAnswer: "外線接聽",
      outlineNotAnswer: "外線未接",
      noChooseQueue: "未選擇隊列",
    },
    defaultSurveys: { noEvaluation: "未評價", noNeedToEvaluate: "無需評價" },
    queueStates: {
      queueSuccess: "排隊成功",
      queueTimeout: "排隊超時",
      queueGiveUp: "放棄排隊",
      noStaffOnline: "無客服喺線",
    },
    callHangers: { customer: "客户", staff: "客服", outline: "外線" },
    workFlowTypes: { noAppealsMode: "無申訴模式", appealsMode: "允許申訴模式" },
    fieldCategories: {
      standardCondition: "標準篩選條件",
      customerCondition: "用户自定義篩選條件",
    },
    sampleTypes: { automatic: "自動抽樣", manual: "手動抽樣" },
    defaultFields: {
      createdAt: "通話時間",
      agentId: "客服",
      getDirection: "通話類型",
      callResult: "通話結果",
      survey: "評價",
      derivedFrom: "來源",
      quitQueueWhy: "排隊狀態",
      queuingDuration: "排隊耗時",
      talkingSeconds: "通話時長",
      ringingSeconds: "響鈴時間",
      hangupBy: "通話掛斷方",
    },
    conditionFieldCategories: {
      fixedField: "固定字段",
      customField: "自定義字段",
      secondLevelScore: "二級分類",
      formItemScore: "分值項",
      forbids: "禁止項",
      deadlies: "致命項",
    },
    columnFieldCategories: {
      agent: "客服",
      callLog: "通話記錄",
      businessFields: "業務信息",
      customer: "客户",
      customerCustomFields: "客户自定義",
      source: "來源",
      queue: "隊列",
      fixed: "固定",
      secondLevelScore: "二級分類",
      formItemScore: "分值項",
      forbids: "禁止項",
      deadlies: "致命項",
    },
    taskListDefaultFields: {
      startTime: "通話時間",
      name: "客服",
      state: "狀態",
      totalScore: "總分",
      formItemForbidList: "禁止項",
      formItemDeadlyList: "致命項",
      comment: "評價備註",
      inspector: "評分人",
      review: "複核員",
    },
    evaluationStates: {
      initial: "初始化",
      waitEvaluate: "待評價",
      evaluated: "待提交",
      waitConfirm: "待確認",
      waitCheck: "待複核",
      complete: "已經完成",
    },
    workBenchInfoTypes: {
      callInfo: "通話信息",
      businessInfo: "業務信息",
      customerInfo: "客户信息",
    },
    evaluationEventStates: {
      create: "創建",
      valuate: "評價",
      edit: "編輯",
      submit: "提交",
      confirm: "確認",
      appeal: "申訴",
      checkConfirm: "複核確認",
      check: "複核",
    },
    formItemTypes: {
      direct: "直接評分",
      weight: "權重",
      deduct: "扣分",
      forbid: "禁止項",
      deadly: "致命項",
    },
    appealCheckCallTypes: {
      callIn: "呼入",
      callBack: "雙向回撥",
      callOut: "外呼直撥",
      autoCall: "自動外呼",
    },
    appealCheckCallStatus: {
      waitReview: "待複核",
      reviewed: "已經複核",
      filed: "已經歸檔",
    },
    samplingStatus: { all: "全部", unchecked: "抽取未檢", checked: "已經抽檢" },
    inspectionStatus: {
      all: "全部",
      unread: "未讀",
      readed: "已經讀",
      appeal: "申訴",
      review: "複核",
      filed: "已經歸檔",
    },
    gradeChooseHits: { yes: "係", no: "否" },
    appealCheckStatus: {
      all: "全部狀態",
      unread: "未讀",
      inComplaint: "申訴中",
      reviewed: "已經複核",
      readed: "已經讀",
      filed: "已經歸檔",
      spotchecked: "已經抽檢",
    },
    flowStatus: {
      unread: "未讀",
      alreadyRead: "已經讀",
      complaining: "申訴中",
      reviewed: "已經複核",
      sampling: "抽取未檢",
      samplingCompleted: "已經抽檢",
      complainCancel: "已經撤銷",
    },
    complainStatus: {
      success: "成功",
      fail: "失敗",
      default: "申訴中",
      canceled: "已經撤銷",
    },
    reviewInspectionStatus: { inComplaint: "申訴中", reviewed: "已經複核" },
    gradeChangeTypes: {
      edit: "編輯",
      check: "複核",
      file: "歸檔",
      remark: "備註",
      complain: "申訴",
      review: "複核",
      reviewEvaluation: "複核評價",
      sample: "抽檢",
      sampleEvaluation: "抽檢評價",
      sampleSubmit: "抽檢提交",
      sampleAssign: "抽檢分配",
      reviewAssign: "複核分配",
      read: "已經讀",
      reviewConfirm: "複核確認",
      sampleConfirm: "抽檢確認",
      caseBase: "添加至案例組",
      sampleCancel: "抽檢取消分配",
      reviewCancel: "複核取消分配",
      sampleAgain: "抽檢重新分配",
      reviewAgain: "複核重新分配",
      btachDelete: "刪除抽檢記錄",
      rapidSampling: "快速抽檢",
      reInspection: "重新質檢",
      smartTagsChange: "智能標籤變更",
      cancelAppeal: "撤銷申訴",
    },
    appealTypes: {
      transliterationError: "轉寫錯誤",
      discriminationError: "判別錯誤",
      otherError: "其他錯誤",
    },
    inspectDataSources: {
      voiceCall: "語音通話",
      textDialogue: "文本對話",
      realTimeVoiceCall: "實時語音通話",
      realTimeTextDialogue: "實時文本對話",
      wechatDialogue: "企微會話",
      taobao: "電商文本對話",
      ticket: "工單",
      wechatRadio: "企微語音",
    },
    inspectTaskType: { all: "全部", common: "常規質檢", relate: "關聯質檢" },
    inspectApproveType: {
      all: "全部",
      resolve: "審核透過",
      resolving: "審核中",
      reject: "審核未透過",
    },
    dataSourceFlags: { all: "唔限制", voiceCall: "通話", textDialogue: "對話" },
    smartWordsLibrary: { effective: "有效", invalid: "無效" },
    activeFlags: { effiective: "有效", invalid: "無效" },
    labelTypes: { dimensionLabel: "標註標籤", classificationLabel: "分類標籤" },
    pointTypes: {
      automatic: "自動評價",
      manual: "手動評價",
      interactiveDetection: "交互檢測",
      smartRules: "智能規則",
      machineLearning: "自學習模式",
      intelligentModel: "智能模型",
    },
    pointGradeTypes: {
      radio: "單選",
      input: "輸入",
      select: "選擇",
      all: "滿足全部",
      any: "滿足任意",
      customize: "自定義",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "關鍵詞匹配",
      question: "疑問句",
      regular: "正則表達式",
      context: "上下文重複",
      semantics: "語義匹配",
      word: "字數相似句",
      dialogue: "對話時間間隔",
      speed: "語速檢測",
      grabDetection: "搶話檢測",
      semanticTags: "語義標籤",
    },
    applyRoles: {
      all: "全部",
      customer: "客户",
      agent: "客服",
      robot: "機械人",
    },
    saleApplyRoles: { all: "全部", customer: "客户", agent: "員工" },
    applyOperatorScopes: {
      all: "全文",
      preCondition: "前置條件",
      scope: "指定範圍",
    },
    keywordScopes: { all: "檢測全部關鍵詞", any: "檢測任意關鍵詞" },
    preOperatorHitTypes: {
      first: "首次匹配",
      every: "每次匹配",
      last: "最後一次匹配",
      any: "任意一次匹配",
      none: "未匹配",
    },
    operatorRuleTypes: {
      all: "滿足所有",
      any: "滿足任意",
      custom: "自定義邏輯",
    },
    informationEntityTypes: {
      system: "系統",
      custom: "自定義",
      moduleCompany: "模組",
    },
    organizationTypes: { null: "無", moduleCompany: "模組" },
    customType: {
      smartTag: "抽取實體",
      scriptTimes: "話術計次",
      dataCategory: "資料分類",
    },
    interactiveQuestionTypes: {
      standard: "標準問答",
      multiElement: "多要素問答",
      guideMultiRound: "引導式多輪交互",
    },
    targetTypes: { all: "全部", customerService: "客服", customer: "客户" },
    interactiveTypes: {
      similarQList: "相似問題",
      exceptSimilarQList: "排除相似問題",
      similarAList: "相似答案",
      wrongAList: "錯誤答案",
    },
    filterTypes: {
      call: "通話錄音",
      dialogue: "對話文本",
      wechat: "企業微信",
      wechatAll: "企業微信全部對話",
      ticket: "工單會話",
      taobao: "電商會話",
      wechatRadio: "企微語音",
    },
    fieldTypes: {
      keyword: "關鍵詞",
      agent: "客服",
      agentGroup: "客服組",
      sigleListbox: "單選",
      multiListbox: "多選",
      text: "單行文本",
      textArea: "多行文本",
      dateTime: "時間",
      number: "數字",
      voice: "",
      customer: "客户",
      fieldWithLink: "帶連結字段",
      percentage: "百分比",
      intelligentClassification: "智能分類",
      testSetList: "測試集動態字段",
      inspector: "質檢員",
    },
    saveTypes: { save: "保存" },
    knowledgeType: { oneToOne: "一問一答" },
    updateTypes: { update: "更新", saveAs: "另存為" },
    recommendItemActionTypes: {
      similar: "相似",
      exclude: "排除",
      ignore: "忽略",
    },
    gradeStatus: {
      unread: "未讀",
      alreadyReview: "已經複核",
      alreadyFiled: "已經歸檔",
      waitReview: "待複核",
      alreadyRead: "已經讀",
    },
    qualityCheckDetailTypes: { review: "複核", spotCheck: "抽檢" },
    sampleAndReviewStatus: {
      init: "未抽取",
      unread: "未讀",
      alreadyRead: "已經讀",
      complaining: "申訴中",
      reviewed: "已經複核",
      sampling: "抽取未檢",
      samplingCompleted: "已經抽檢",
    },
    interactiveAnswerTypes: {
      noAnswer: "未檢測到答案",
      answer: "檢測到答案",
      wrongAnswer: "檢測到錯誤答案",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "未檢測到問題",
      question: "檢測到問題",
      eliminateQuestion: "檢測到排除問題",
    },
    isManual: { yes: "已經人工質檢", no: "未人工質檢" },
    readStatus: { yes: "已經讀", no: "未讀" },
    reInspectionType: {
      recalcScore: "僅重新核算分數(含評級)",
      recheckPoint: "重新核算質檢點同分數",
    },
    common: {
      eCommerceReview: "電商評論",
      socialMediaComments: "社媒評論",
      customer: "客户",
      customerService: "客服",
      robot: "機械人",
      customerInformation: "客户信息",
      workOrderFields: "工單字段",
      intelligentConversationAnalysis: "智能會話分析",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "洞察業務短板,聆聽客户心聲,提升營銷轉化",
      intelligentAccompanyingPractice: "智能陪練",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "打造模擬業務真實場景嘅沉浸式AI陪練,實現金牌話術全員掌握,對話技巧快速提升",
      intelligentQualityInspection: "智能質檢",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "全量質檢,同時高效,準確率85%以上",
      salesEmpowerment: "銷售賦能",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI重塑銷售管理,實現溝透過程數字化、可視化,透明管理銷售每一環,智慧洞察溝通每一步,精細化診斷業務問題,完美復刻銷冠經驗",
      algorithmPlatform: "算法平台",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "基於自研原心引擎語音语义,ASR、NLP模型定製,打通業務場景資料,實現系統自學習,達到越用越智能,越用越準確嘅目嘅",
      generalSettings: "通用設置",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "對資料權限、員工角色進行統一設置管理",
      callLog: "通話記錄",
      intelligentRules: "智能規則",
      businessRecords: "業務記錄",
      customFilter: {
        call: "語音通話",
        text: "文本對話",
        smartTags: "智能標籤",
        pretreatment: "預處理",
        smartTagGroup: "智能標籤組",
        currency: "通用資料",
        wechat: "企業微信",
        spotCheck: "抽檢",
        personaField: "客戶列表",
        ticket: "工單會話",
        taobao: "電商會話",
        wechatRadio: "企微語音",
        reviewList: "複核",
      },
      talkerRecognitionType: { API: "API標識軌道角色", text: "文本識別" },
      operatorTypes: {
        keyword: "關鍵詞匹配",
        question: "疑問句",
        regular: "正則表達式",
        context: "上下文重複",
        semantics: "語義匹配",
        word: "字數相似句",
        dialogue: "對話時間間隔",
        speed: "語速檢測",
        grabDetection: "搶話檢測",
        semanticTags: "語義標籤",
        keywordStrict: "關鍵詞匹配(嚴格)",
        link: "連結字段",
        entityInfoDetection: "信息實體檢測",
        basicInfoDetection: "基礎信息檢測",
        volume: "音量檢測",
        emotion: "情緒分析",
        grammar: "文法規則",
        sentenceLength: "句長檢測",
        conceptWord: "概念詞",
        questionsAndAnswers: "知識庫解答(舊)",
        element: "要素問答",
        silent: "響應超時",
        associate: "隨路資料",
        repeat: "重複發送",
        timeInterval: "隔時長",
        typoDetection: "錯別字檢測",
        silentOld: "靜默",
        knowledge: "知識解答",
        wecomRemark: "客户企微備註",
        customerAddTime: "客户添加時間",
      },
      keywordOperators: {
        all: "全部匹配",
        allNot: "全部唔匹配",
        any: "任意匹配",
      },
      applyRoles: { all: "全部", customer: "客户", agent: "客服" },
      sentenceLength: {
        less: "小於",
        greater: "大於",
        equal: "等於",
        greater_or_equal: "大於等於",
        less_or_equal: "小於等於",
      },
      dialogueApplyScopes: {
        differentRoles: "唔同角色之間",
        agent: "客服",
        customer: "客户",
        agentResponse: "客服應答",
        customerResponse: "客户應答",
        customerNoResponse: "客戶無響應",
      },
      applyConditionHitTypes: {
        first: "第一次命中",
        every: "每一次命中",
        last: "最後一次命中",
      },
      applyConditionScopes: {
        current: "當前",
        before: "前",
        after: "後",
        beforeAll: "之前全部",
        afterAll: "之後全部",
        around: "附近",
      },
      voiceDemoTypes: {
        singleChannel: "單通道通話錄音",
        dualChannel: "雙通道通話錄音",
      },
      sendStatus: {
        sending: "發送中",
        arrive: "已經送達",
        fail: "發送失敗",
        off_sending: "離線發送未讀",
        off_arrive: "已經讀",
        rollback: "已經撤回",
      },
      collectionMethod: { assignment: "直接賦值", entity: "實體抽取" },
      systemTypes: { qa: "智能質檢", wfm: "智能排班" },
      entityOperators: {
        equals: "等於信息",
        notEquals: "唔等於信息",
        contains: "包含信息",
        notContains: "唔包含信息",
        equalsFix: "等於固定值",
        notEqualsFix: "唔等於固定值",
        containsFix: "包含固定值",
        notContainsFix: "唔包含固定值",
      },
      basicInfoOperators: {
        equals: "等於",
        notEquals: "唔等於",
        contains: "包含",
        notContains: "唔包含",
        isNull: "為空",
        isNotNull: "唔為空",
      },
      volumeDetectionWays: {
        loudness: "響度分貝",
        rangeAbilitySelf: "變化幅度(比本人上句)",
        rangeAbilityOth: "變化幅度(比對方上句)",
        rangeAbility3: "變化幅度(比本人本句)",
      },
      volumeDetectionOperators: {
        is: "等於",
        not: "唔等於",
        greaterThan: "大於",
        greaterThanEq: "大於等於",
        lessThan: "小於",
        lessThanEq: "小於等於",
      },
      sentimentTypes: {
        positive: "正面情緒",
        negative: "負面情緒",
        neutral: "中性情緒",
        thankful: "多謝",
        happy: "開心",
        complaining: "抱怨",
        angry: "生氣",
        post: "正面",
        negat: "負面",
        neut: "中性",
      },
      emotionOperators: { is: "符合", not: "唔符合" },
      propTypes: { system: "系統", user: "自定義" },
      valueTypes: { text: "文本", number: "數字" },
    },
    tasks: {
      allowCustomGrievance: "允許自定義申訴理由",
      businessSummary: "業務小結",
      theDayBeforeYesterday: "前日",
      assessActionTypes: { reassess: "重新評估" },
      scoreType: {
        aiScore: "智能評價",
        manualScore: "手動評價",
        interactiveDetection: "交互檢測",
        smartRules: "智能規則",
        machineLearning: "自學習模式",
      },
      inspectType: { inspectTrue: "檢查到", inspectFalse: "未檢查到" },
      operatorType: { keyword: "關鍵詞匹配", notKeyword: "關鍵詞唔匹配" },
      applyRole: { all: "全部", customer: "客户", agent: "客服" },
      applyScope: { all: "全部", specific: "指定句", scope: "指定範圍" },
      judgeStrategy: {
        all: "滿足所有條件",
        arbitrarily: "滿足任意條件",
        custom: "自定義邏輯",
      },
      checkPointStatus: {
        waitFor: "等待複核",
        notDeductScore: "唔扣分",
        deductScore: "扣分",
      },
      applyOptions: { hit: "條件命中", notHit: "無指定條件" },
      gradeTypes: {
        yesOrNo: "係咪符合",
        input: "手動輸入",
        level: "分檔評價",
        multipleFactors: "多要素",
      },
      gradeChooseHits: { yes: "係", no: "否" },
      gradeChangeTypes: {
        edit: "編輯",
        check: "複核",
        file: "歸檔",
        remark: "備註",
        complain: "申訴",
        review: "複核",
        reviewEvaluation: "複核評價",
        sample: "抽檢",
        sampleEvaluation: "抽檢評價",
        sampleSubmit: "抽檢提交",
        sampleAssign: "抽檢分配",
        reviewAssign: "複核分配",
        read: "已經讀",
        reviewConfirm: "複核確認",
        sampleConfirm: "抽檢確認",
      },
      gradeStatus: {
        unread: "未讀",
        alreadyReview: "已經複核",
        alreadyFild: "已經歸檔",
        waitReview: "待複核",
        alreadyRead: "已經讀",
      },
      samplingFrequencyTypes: {
        everyDay: "每日",
        everyWeek: "每週",
        everyMonth: "每月",
      },
      samplingEveryDayTimeTypes: { yesterday: "尋日", today: "今日" },
      samplingEveryWeekTimeTypes: { thisWeek: "本週", lastWeek: "上週" },
      samplingEveryMonthTimeTypes: { thisMonth: "本月", lastMonth: "上月" },
      samplingTypes: { random: "隨機抽樣", proportional: "比例抽樣" },
      weekdays: {
        Monday: "週一",
        Tuesday: "週二",
        Wednesday: "週三",
        Thursday: "週四",
        Friday: "週五",
        Saturday: "週六",
        Sunday: "週日",
      },
      distributeTypes: { rule: "規則優先分配", average: "平均隨機分配" },
      notMatchAnyRuleTypes: {
        random: "隨機平均分配",
        assign: "派畀指定人員",
        ignore: "唔分配",
      },
      inspectorTypes: { all: "全部質檢員", some: "指定質檢員" },
      pushFrequencyTypes: {
        everyDay: "每日",
        everyWeek: "每週",
        everyMonth: "每月",
        inspectionUpdate: "質檢更新",
        timelyInspection: "實時質檢",
      },
      pushObjectTypes: { email: "指定郵箱", staff: "所屬客服" },
      reviewOptions: {
        reviewResult: "複核時顯示質檢結果",
        reviewRecord: "複核時顯示相關記錄",
        reviewAgentHidden: "複核時隱藏客服信息",
        samplingResult: "抽檢時顯示質檢結果",
        samplingRecord: "抽檢時顯示相關記錄",
        sampleAgentHidden: "抽檢時隱藏客服信息",
        checkExportAddCallInfoIphone: "導出列表時附加通話記錄",
        sampleExportAddCallInfoIphone: "導出列表時附加通話記錄",
        checkExportAddCallInfoText: "導出列表時附加對話記錄",
        sampleExportAddCallInfoText: "導出列表時附加對話記錄",
        inspectionExportAddCallInfoIphone:
          "導出列表時附加通話記錄(每次最多可導出10000條)",
        inspectionExportAddCallInfoText:
          "導出列表時附加對話記錄(每次最多可導出10000條)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail: "允許喺質檢詳情快速執行抽檢",
        fastSampleInSampleDetail: "允許喺抽檢詳情快速執行抽檢",
        fastSampleInReviewDetail: "允許喺複核詳情快速執行抽檢",
      },
      permitCustomReasonList: { permitCustomReason: "允許自定義申述理由" },
      autoMateTasks: {
        autoLoad: "自動添加",
        sample: "自動抽檢",
        assign: "自動分配",
        pushes: "自動推送",
        assess: "自動評估",
        check: "申訴復檢",
        case: "案例庫同步",
      },
      qualityCheckDetailTypes: { review: "複核", spotCheck: "抽檢" },
      appealTypes: {
        transliterationError: "轉寫錯誤",
        discriminationError: "判別錯誤",
        otherError: "其他錯誤",
      },
      inspectDataSources: {
        voiceCall: "語音通話",
        textDialogue: "文本對話",
        realTimeVoiceCall: "實時語音通話",
        realTimeTextDialogue: "實時文本對話",
      },
      pointTypes: {
        automatic: "自動評價",
        manual: "手動評價",
        interactiveDetection: "交互檢測",
      },
      pointGradeTypes: { radio: "單選", input: "輸入", select: "選擇" },
      sampleAndReviewStatus: {
        init: "未抽取",
        unread: "未讀",
        alreadyRead: "已經讀",
        complaining: "申訴中",
        reviewed: "已經複核",
        sampling: "抽取未檢",
        samplingCompleted: "已經抽檢",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "忽略超額溢出",
        automaticWeightCalculation: "自動權重核算",
      },
      calculateScoreTypes: { add: "加分", sub: "減分" },
      naChecked: { check: "允許檢測中唔涉同此項" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "按比例畀分",
        allRight: "全部正確畀分",
        anyRight: "任意正確畀分",
      },
      noHitQuestionResults: { true: '結果為"係"', false: '結果為"否"' },
      repetRules: { same: "內容一個餅印", similar: "語義相似" },
      relateConditions: {
        highlightQuestion: "高亮問題",
        highlightAnswer: "高亮答案",
        hightlightFoctors: "高亮要素",
      },
      interactiveQuestionTypes: {
        question: "檢測到問題",
        eliminateQuestion: "檢測到排除問題",
      },
      interactiveAnswerTypes: {
        noAnswer: "未檢測到答案",
        answer: "檢測到答案",
        wrongAnswer: "檢測到錯誤答案",
      },
      inspectionUpdateTypes: {
        autoInspection: "自動質檢",
        autoInspectionWechat: "自動分析",
        artificialSampling: "人工抽檢",
        artificialReview: "人工複核",
        manualModification: "快速抽檢",
        timelyInspection: "實時質檢",
        samplingAssign: "抽檢分配",
        artificialComplain: "申訴提交",
        fastSampling: "快速抽檢提交",
        recheckInspection: "重新質檢",
        recheckInspectionWechat: "重新分析",
      },
      timelyInspectionTypes: { timelyInspection: "實時質檢" },
      pushActionTypes: { email: "郵件", interfacePush: "接口推送" },
      pushDetailWays: { riskAlert: "風險提醒", subscribe: "訂閱消息" },
      riskAlertPushDataTypes: {
        dialogueMessage: "通話/對話消息",
        inspectionResults: "質檢結果",
      },
      subscribePushDataTypes: {
        dialogueMessage: "通話/對話消息",
        textContent: "通話/對話文本內容",
        inspectionResults: "質檢結果",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "組內分數平均",
        addOrSubtract: "直接加減分",
        noChange: "唔加減分",
      },
      defaultScore: {
        empty: "為空",
        add: "加分",
        notAdd: "唔加分",
        sub: "減分",
        notSub: "唔減分",
        na: "N/A",
        input: "手動輸入",
        choose: "選擇",
      },
      strategyTypes: {
        hitAdd: "命中加分",
        hitNotAdd: "命中唔加分",
        hitSub: "命中減分",
        hitNotSub: "命中唔減分",
      },
    },
    download: {
      exportStatus: {
        prepare: "準備中",
        process: "進行中",
        faild: "導出失敗",
        success: "成功",
      },
    },
    messageType: {
      all: "全部",
      monitor: "即時監測",
      trigger: "觸發通知",
      timing: "定時報告",
    },
    messagePushType: {
      all: "全部",
      system: "系統消息",
      sdk: "消息SDK",
      push: "接口推送",
      email: "郵件",
      udesk: "Udesk通知",
      udeskWhite: "客服系統通知",
      weChat: "企微消息",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "分配抽檢員",
      sampleInspector: "抽檢員",
      assignReviewer: "分配複核員",
      reviewer: "複核員",
      taskInspector: "任務質檢員",
    },
    sdkPushType: {
      webHook: "標準webhook",
      dingDing: "釘釘機械人",
      weChat: "企業微信機械人",
      customer: "自定義Json內容",
    },
    inspectionRemarksType: {
      inspection: "質檢",
      check: "抽檢",
      review: "複核",
    },
    segmentationStrategyType: { count: "消息數量", spacing: "消息間隔" },
    segmentationStrategy: { byTime: "定時切分", customize: "組合切分" },
    inspectType: { commonInspect: "常規質檢", associateInspect: "關聯質檢" },
    typoDetectionOperators: { less: "小於", greater: "大於" },
    autoAddTypes: { realTimeAdd: "實時添加", timingAdd: "定時添加" },
    messageTypes: { text: "文本消息", markdown: "Markdown消息" },
    inServiceStatus: {
      activated: "已經激活",
      disabled: "已經禁用",
      inactivated: "未激活",
      resigned: "退出企業",
    },
    roles: { agent: "員工", customer: "客户" },
    commentVisibleAuthority: {
      all: "對所有人可見",
      self: "僅自己可見",
      designation: "指定人可見",
    },
    appealCheckingTask: {
      eCommerceReview: "電商評論",
      socialMediaComments: "社媒評論",
      largeModel: "大模型",
      customModel: "自定義模型",
      universalModel: "通用模型",
      enterpriseWeChatText: "企微文本",
      generalQualityInspection: "普通質檢",
      processQualityInspection: "流程質檢",
      voiceCopy: "語音復盤",
      beEqualTo: "等於",
      notEqualTo: "唔等於",
      canAppeal: "可以申訴",
      noAppeal: "唔可申訴",
    },
    wechat: {
      redEnvelopes: "利是",
      text: "文本",
      picture: "圖片",
      voice: "語音",
      voiceCall: "語音通話",
      video: "視頻",
      businessCard: "名片",
      position: "位置",
      expression: "表情",
      link: "連結",
      applet: "小程序",
      chatRecord: "聊天記錄",
      mixedMessage: "混合消息",
      file: "文件",
    },
    gong: {
      sessionActivity: "會話活動",
      conversationAction: "會話動作",
      riskSemantics: "風險語義",
      daysNotFollowedUp: "未跟進天數",
      waysideData: "隨路資料",
      notStarted: "未開始",
      completed: "已經完成",
      haveInHand: "進行中",
      unpublished: "未發佈",
      published: "已經發佈",
      knowledgePoints: "知識點",
      technologicalProcess: "流程",
      notMarked: "未標註",
      inAnnotation: "標註中",
      annotated: "已經標註",
    },
    qualityChecingTask: {
      appealCanceled: "申訴取消",
      complaintExpired: "申訴過期",
      incomingCall: "呼入",
      exhale: "呼出",
      incomingCallthirdParty: "呼入(三方)",
      incomingCallconsultation: "呼入(諮詢)",
      incomingCallforcedInsertion: "呼入(強插)",
      incomingCallmonitoring: "呼入(監聽)",
      incomingCalltransfer: "呼入(轉接)",
      incomingCallinterception: "呼入(攔截)",
      incomingCalltransferToExternalLine: "呼入(轉接外線)",
      incomingCallthirdPartyExternalLine: "呼入(三方外線)",
      incomingCallexternalLine: "呼入(諮詢外線)",
      outgoingCallthirdParty: "呼出(三方)",
      outgoingCalltransfer: "呼出(轉接)",
      outgoingCallforcedInsertion: "呼出(強插)",
      outgoingCallmonitoring: "呼出(監聽)",
      outgoingCallinterception: "呼出(攔截)",
      outgoingCalltransferToExternalLine: "呼出(轉接外線)",
      outgoingCallthreePartyExternalLine: "呼出(三方外線)",
      customerServiceAnswer: "客服接聽",
      customerServiceMissed: "客服未接",
      customerAnswer: "客戶接聽",
      customerMissed: "客戶未接",
      theTelephoneIsBusy: "話機佔線",
      phoneOffline: "話機離線",
      customerQuickHanging: "客户速掛",
      clientHangsUp: "客户掛機",
      queueTimeout: "排隊超時",
      giveUpQueuing: "放棄排隊",
      outsideLineAnswering: "外線接聽",
      externalLineIsNotConnected: "外線未接",
      noQueueSelected: "未選擇隊列",
      notEvaluated: "未評價",
      noEvaluationRequired: "無需評價",
      queuingSucceeded: "排隊成功",
      noCustomerServiceOnline: "無客服喺線",
      customer: "客户",
      customerService: "客服",
      outsideLines: "外線",
    },
    sessionActivity: {
      single: "單次平均通話時間",
      all: "總通話量",
      allTime: "通話總時長",
    },
    sessionAction: {
      salesProportion: "銷售説話佔比",
      salesLongest: "平均最長銷售發言",
      customerLongest: "平均最長客户發言",
      discussNumber: "深入討論次數",
      frequency: "提問頻次",
    },
    monitorCriteria: { all: "前後", before: "前", after: "後" },
    monitorTimeCycle: { day: "日", week: "週", month: "月" },
    monitorGroupTestType: { ab: "兩個組對比", a: "A組", b: "B組" },
    fields: {
      department: "部門",
      customerTags: "客戶標籤",
      member: "成員",
      time: "時間",
      cascade: "級聯",
      anyMatch: "任意匹配",
      keyWord: "關鍵詞",
      keyEvents: "關鍵事件",
    },
    semanticIntelligence: {
      staff: "員工",
      customer: "客户",
      employeesAndCustomers: "員工同客户",
      notStarted: "未開始",
      haveInHand: "進行中",
      completed: "已經完成",
      callRecording: "通話錄音",
      enterpriseWeChatCall: "企微通話",
      enterpriseWeChatText: "企微文本",
      discourseMiningForObjectionHandling: "異議處理話術挖掘",
      productSellingPointScriptsMining: "產品賣點話術挖掘",
      conversationScenario: "會話場景",
      topicOfConversation: "話題",
      keyEvents: "關鍵事件",
      customerLabel: "客戶標籤",
      includeAny: "包含任意",
      includeAll: "包含全部",
      and: "且",
      or: "或",
      question: "疑問句",
      nonInterrogativeSentence: "非疑問句",
    },
    coach: {
      notPassed: "未透過",
      analogTelephone: "模擬電話",
      enterScenarioInformation: "輸入情景信息",
      generateDialogueFramework: "生成對話框架",
      generatePromptLanguage: "生成提示語",
      aIGeneratedDialogue: "AI生成對話",
      system: "系統",
      custom: "自定義",
      flow: "流程",
      script: "話術",
      express: "表達",
      emotion: "情緒",
      rateAccordingToCourseConfiguration: "按照課程配置評分",
      intelligentModelRating: "智能模型評分",
      allData: "全部資料",
      dataFromThisDepartment: "本部門資料",
      multidepartmentalData: "多部門資料",
      underReview: "審核中",
      no: "否",
      yes: "係",
      automaticRating: "自動評分",
      auditRating: "審核評分",
      reRating: "重新評分",
      inAppeal: "申訴中",
      reviewed: "已經複核",
      notViewed: "未檢視",
      viewed: "已經檢視",
      uncorrected: "未修正",
      corrected: "已經修正",
      practice: "練習",
      examination: "考試",
      other: "其他",
      notMeetingStandards: "未達標",
      meetingStandards: "達標",
      excellent: "優秀",
      invalid: "失效",
      notStarted: "未開始",
      haveInHand: "進行中",
      ended: "已經結束",
      completed: "已經完成",
      hangInTheAir: "未完成",
      passed: "已經透過",
      voiceConversation: "語音對話",
      textDialogue: "文本對話",
      slidePresentation: "幻燈片演講",
      unpublished: "未發佈",
      published: "已經發佈",
      narration: "旁白",
      studentSpeaking: "學員講嘢",
      robotTalk: "機械人講話",
      knowledgeBaseQA: "知識庫問答",
      slide: "幻燈片",
      negativeEmotions: "情緒負向",
      emotionallyNormal: "情緒正常",
      incompleteScript: "話術唔完整",
      completeScript: "話術完整",
      normalSpeechSpeed: "語速正常",
      speakTooFast: "語速過快",
      speakTooSlowly: "語速過慢",
      whole: "全部",
      singlePage: "單頁",
      qA: "一問一答",
      situationalDialogue: "情景對話",
      misses: "未命中",
      hit: "命中",
      sequentialQA: "順序問答",
      randomQA: "隨機問答",
      mastered: "已經掌握",
      notMastered: "未掌握",
    },
    algorithm: {
      salesEmpowerment: "銷售賦能",
      enterpriseWeChatVoice: "企業微信語音",
      enterpriseWeChatText: "企業微信文本",
      waiting: "等待中",
      extracting: "抽取中",
      success: "成功",
      fail: "失敗",
      intelligentQualityInspection: "智能質檢",
      textDialogue: "文本對話",
      voiceCall: "語音通話",
      smartTags: "智能標籤",
      satisfactionEvaluation: "滿意度評價",
      score: "評分",
      grade: "評級",
      customerServiceTeam: "客服組",
      conversationAnalysis: "會話分析",
    },
    operatorListGather: {
      businessSummary: "業務小結",
      firstSentence: "首句",
      lastSentence: "末句",
      allTime: "全部時間",
      nonBusinessWorkingHours: "非業務工作時間",
      businessWorkingHours: "業務工作時間",
    },
    callDialogueManage: {
      eCommerceReview: "電商評論",
      socialMediaComments: "社媒評論",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "登錄",
        email: "郵箱",
        password: "密碼",
        forgetPwd: "忘記密碼",
        emailCannotBeEmpty: "郵箱唔得為空。",
        passwordCannotByEmpty: "密碼唔得為空。",
        mustChangeLogin: "對唔起,你嘅密碼必須重置,請聯系管理員重置密碼。",
        dismissionLogin: "對唔起,你已經離職。",
        forbiddenLogin: "對唔起,你嘅賬户係禁用狀態。",
        loginError: "登錄失敗,郵箱或密碼錯誤。",
        loginPrompt: "登錄到系統",
        rememberMe: "記住我",
      },
      logout: { logoutError: "註銷失敗, 請檢視網絡連接或者等陣再試。" },
      resetPassword: {
        forgetPwd: "忘記密碼",
        inputEmailPrompt:
          "喺下方輸入你嘅帳號郵箱,我哋將向你發送重置密碼嘅電子郵件同説明。",
        sendEmailSuccess:
          "修改密碼確認信函已經成功發送至你填寫嘅郵箱中,請同時查收。",
        sendEmailFailed: "郵箱發送失敗, 請檢查網絡連接或者等陣再試。",
        setNewPassword: "設置你嘅新密碼",
        passwordCannotEmpty: "密碼唔得為空",
        passwordFormWrong: "密碼格式唔正確",
        confirmPasswordWrong: "兩次輸入嘅密碼唔相等。",
        passwordResetSuccess: "密碼重置成功。",
        passwordResetFailed: "密碼重置失敗, 請檢查網絡連接或者等陣再試。",
        linkTimeover: "重置密碼連結到期,請重試",
      },
      form: { title: "CASE表單展示" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "郵箱",
          emailFormatError: "郵箱格式錯誤,請重新輸入!",
          forgotPassword: "忘記密碼?",
          describe:
            "喺下方輸入你嘅帳號郵箱,我哋將向你發送重置密碼嘅電子郵件同説明。",
          send: "發送",
          emailCannotBeEmpty: "郵箱唔能為空",
          resetPasswordExpire: "重置密碼連結到期,請重試",
        },
        resetPassword: {
          setPassword: "設置你嘅新密碼",
          newPassword: "新密碼",
          repeatPassword: "重複密碼",
          resetPassword: "重置密碼",
          passwordCannotBeEmpty: "新密碼唔能為空。",
          confirmPasswordCannotBeEmpty: "重複密碼唔能為空。",
          passwordsNotMatch: "兩次密碼唔一致!",
          passwordFormWrong: "密碼唔能帶有中文,並且個數喺6-14位!",
          linkExpiration: "重置密碼連結到期,請重試",
        },
      },
      errors: { haveNoPermissionWarningText: "你冇此頁面嘅檢視權限" },
    },
    downloadCenter: {
      name: "下載中心",
      saveTime: "(默認保存30天)",
      headerTitle: "下載文件",
      download: "下載",
      downloadPromp: "下載任務已經提交, 請前往",
      viewProgress: "檢視進度",
    },
    uploadCenter: { name: "上傳中心" },
    tasks: {
      index: {
        name: "名稱",
        remark: "描述",
        qualityCheckTasksTitle: "工作台",
        informationCollection: "採集任務",
        qualityCheckTasks: "質檢任務",
        wechatQaTasks: "分析任務",
        moduleName: "使用模板新建",
        creatNewQualityCheckTask: "新建任務",
        generalInspectionList: "通用質檢報告",
        editQualityCheckTask: "編輯質檢任務",
        creatNewQualityCheckTaskError: "新建質檢任務失敗",
        editQualityCheckTaskError: "編輯質檢任務失敗",
        type: "表單類型",
        singleScore: "單項分數",
        singleScoreNullError: "單項分數唔能為空",
        inspector: "質檢員",
        inspectDataSource: "資料源",
        taskName: "任務名稱",
        inspectionType: "質檢類型",
      },
      manage: {
        screeningCondition: "篩選條件",
        template: {
          condition: {
            index: { name: "條件", existingConditions: "已經有條件" },
            detail: {
              id: "ID",
              name: "條件名稱",
              remark: "描述",
              checkRange: "檢測範圍",
              operator: "算子",
              operatorLogic: "算子邏輯",
            },
          },
          checkPointTest: {
            title: "規則測試",
            running: "規則測試中,請稍後...",
            clearSuccess: "模擬呢個對話呢清空成功",
            mock: "模擬呢個對話呢",
            save: "保存選中呢個對話呢",
            saveAll: "保存全部對話",
            saveSuccess: "對話保存成功",
            close: "退出",
            delete: "刪除選中",
            clear: "清空",
            customer: "客户",
            agent: "客服",
            test: "測試",
            request: "模擬呢個對話呢清空後將唔可恢復, 係否確認清空?",
            deletePopconfirmContent: "係咪確認刪除選中對話?",
          },
          checkPoint: {
            index: {
              name: "規則配置",
              wechatTitle: "分析模型配置",
              existingCheckPoints: "已經有質檢規則",
              currentCheckPointScore: "當前質檢規則分值為",
              currentCheckPointScoreWechat: "當前分析模型分值為",
              currentCheckPointWeight: "當前質檢規則權重總同為",
              weightTotalScoreAndItemScore: "總分:100分 單項分:",
              template: "質檢規則模板",
              import: "導入質檢規則模板",
              getTemplateError: "質檢規則模板獲取失敗",
              tabs: { template: "模板", demo: "樣例" },
              customize: "空白模板",
              move: "拖拽以調整分類同排序",
              classification: "質檢分類",
              classificationWeChat: "模型分類",
              first: "一級分類",
              second: "二級分類",
              new: "新建質檢規則",
              weChatNew: "添加分析模型",
              createWeChat: "新建分析模型",
              empty: "暫無質檢規則,",
              wechatEmpty: "暫無分析模型,",
              newSecond: "新建二級分類",
              newFirst: "新建一級分類",
              checkPoint: "質檢規則",
              checkPointWeChat: "分析模型",
            },
            detail: {
              name: "名稱",
              remark: "描述",
              type: "類型",
              deduction: "扣分",
              rule: "規則",
              highlighted: "高亮",
              gradeType: "評分方式",
              interactiveCategory: "交互分類",
              predeterminedScore: "分值",
              weight: "權重",
              na: "N/A",
              default: "默認得分",
              preRule: "前置條件規則",
              analysisHighlighted: "解析規則",
              categorySelectPrompt: "請選擇分類",
              checkHighlightArrayError:
                "規則同高亮唔匹配,請重新點擊解析規則撳掣",
              foctorsHolder: "請選擇要素",
              foctorsErrorNone: "多要素規則為空,請新增多要素類型",
              naError: "默認得分為N/A時無法關閉該項",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A即Not Applicable,為該條質檢規則設置前置條件,符合條件時檢測,唔符合條件時即該規則唔適用,則唔檢測該條規則;\n前置條件填寫說明:"&&” 表示 “同”,“||” 表示 “或者”,“!” 表示"非",填寫示例"(R1||R2)&&R3"',
                applicationMessageType: "應用消息類型",
                selectAll: "全選",
                highlightTags: "高亮標籤:",
                ruleType: "規則類型",
                dataSource: "資料源",
                whole: "全部",
                enterpriseMicroConversation: "企微會話",
                enterpriseAndMicroCommunication: "企微通話",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "判斷節點",
                    dialogueNode: "對話節點",
                    editNodes: "編輯節點",
                  },
                  nodeConfig: {
                    nodeName: "節點名稱",
                    nodeRules: "節點規則",
                    scoringLogic: "評分邏輯",
                    score: "分值:",
                    rule: "規則",
                    multiBranchLogic: "多分支邏輯",
                    highlight: "高亮",
                    submit: "提交",
                  },
                  ruleList: {
                    branch: "分支",
                    branchName: "分支名稱:",
                    addRules: "增加規則",
                  },
                  sidebar: { node: "節點" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "自動整理",
                      canvasAdaptation: "畫布自適應",
                      copy: "複製",
                      delete: "刪除",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "判斷節點嘅子節點唔能作為連接目標",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "開始節點唔能作為連接目標",
                      startingNodeCannotConnectMultipleNodes:
                        "開始節點唔能連接多個節點",
                      nodeCannotBeConnectedToItself: "節點唔能同自己相連接",
                      startNode: "開始節點",
                    },
                  },
                },
                msgTypesSelect: {
                  index: { cancelSelectAll: "取消全選", selectAll: "全選" },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "係咪符合",
                    gradedEvaluation: "分檔評價",
                  },
                },
              },
            },
            configuration: {
              title: "質檢規則",
              wechatTitle: "分析模型",
              neme: "名稱",
              errorMessage: "刪除失敗,至少要有一個條件",
              desc: "描述",
              status: "狀態",
              yes: "有效",
              no: "無效",
              namePlaceHolder: "請輸入標籤名稱",
              desPlaceHolder: "請輸入描述",
              nameEmpty: "名稱唔能為空",
              desEmpty: "描述唔能為空",
              type: "類型",
              ruler: "智能規則",
              machineLearning: "自學習模式",
              autoScore: "智能評分(舊)",
              manualScore: "手動評分",
              interaction: "交互檢測(舊)",
              typeTips: {
                model:
                  '智能模型:係預設嘅一套質檢體系,無需配置維護,能夠快速質檢出服務中存喺嘅問題。 該模型較通用,如唔滿足業務訴求請使用“智慧規則"或"手動評分',
                rule: "智能規則:即基於規則AI自動進行評分",
                manual: "手動評分:即人工質檢,由人工手動打分",
              },
              model: "模型:",
              ruleType: {
                words: "話術",
                interaction: "交互",
                feature: "特徵",
                information: "信息",
              },
              testingMode: { all: "檢測全部關鍵詞", any: "檢測任意關鍵詞" },
              operatorTypesOfInteraction: {
                question: "一問一答",
                element: "要素答",
                repet: "上下文重複",
                snatch: "搶話",
                silent: "靜默",
              },
              role: "檢測角色:",
              preposition: "前置算子:",
              range: "檢測範圍:",
              from: "第",
              to: "句至第",
              end: "句",
              hasOldSilentTip:
                '你好, 為提升準確率,“智慧規則-交互-靜默” 已經升級為 "響應超時”,建議更新使用規則!',
              changeToOld: "切換至舊版",
              changeToNew: "切換至新版",
              logic: {
                logic: "評分邏輯",
                naTip:
                  "N/A即Not Applicable,為該條質檢規則設置前置條件,符合條件時檢測,唔符合條件時即該規則唔適用,則唔檢測該條規則",
                score: "分值:",
                na: "N/A：",
                naDes: "允許檢測中唔涉同此項",
                preRule: "前置條件規則:",
                notCatchPreRule: "唔滿足前置條件時:",
                naChecked: "命中N/A時:",
                preRuleEmpty: "前置條件規則唔能為空",
                operatorEmpty: "算子中必要條件唔能為空",
                multipleEmpty: "多重邏輯評分中必要條件唔能為空",
                average: "組內分數平均",
                addOrSubtract: "直接加減分",
                noChange: "唔加減分",
                rule: "規則:",
                all: "滿足所有算子",
                any: "滿足任意算子",
                customize: "自定義算子邏輯",
                multiple: "多重邏輯評分",
                heightLight: "高亮:",
                machineLearningTip:
                  "自學習模式目前僅針對單句話術生效,複雜話術請使用智能規則",
                strategy: "策略:",
              },
            },
            component: { approvalSubmittedSuccessfully: "審批提交成功" },
            components: {
              pageHeaderExtra: { test: "測試", formal: "正式", submit: "提交" },
              createModal: { index: { myRules: "我嘅規則" } },
            },
          },
          automaticAdd: {
            index: {
              name: "流程自動化",
              screeningCondition: "篩選條件",
              aiScreeningCondition: "智能篩選條件",
              rule: "規則",
              automaticAddition: "自動添加",
              automaticAdditionTip:
                "自動實時或者定時將資料添加至任務進行AI質檢",
              autoAdd: {
                autoAddType: "自動添加方式",
                addFrequency: "添加頻率",
                add: "添加",
              },
              automaticSamplingInspection: "自動抽檢",
              automaticSamplingInspectionTip:
                "自動抽取需要人工質檢嘅資料,例如,每日抽取昨日隨機10%嘅資料進行人工抽檢",
              everyAgentCheckLimit: "每客服抽取上限",
              automaticAllocation: "自動分配",
              automaticAllocationTip:
                "抽檢分配:抽取嘅資料自動派畀質檢員, 例如,每日抽取昨日隨機 10% 嘅資料進行人工抽檢, 平均隨機派畀此任務嘅 2 位質檢員",
              automaticAllocationTip2:
                "複核分配:申訴嘅資料自動派畀質檢員複核,例如,每日申訴嘅資料平均隨機派畀此任務嘅 2 位質檢員進行複核",
              automaticPush: "自動推送",
              automaticPushTip:
                "觸發通知:質檢時進行通知/告警,例如,當客服出現致命錯誤時,畀企微群內發送通知消息",
              automaticPushTip2:
                "定時報告:將質檢結果以郵件形式發送定時報告,例如,每日9:00將昨日人工抽檢嘅質檢結果發送至客服主管郵箱",
              automaticAssess: "自動評估",
              automaticAssessTip:
                "定時將人工抽檢結果對比AI質檢結果進行準確率評估。 例如,每日8:00將昨日抽檢嘅資料進行準確率評估",
              reexaminationComplaint: "申訴復檢",
              spoktReviewDetail: "業務配置",
              spoktReviewDetailTip:
                "對質檢列表、抽檢列表、申訴業務、複核列表、備註等功能配置",
              qaDetailAction: "質檢配置",
              spoktDetailAction: "抽檢配置",
              reviewDetailAction: "複核配置",
              appealAction: "申訴配置",
              remarkTemplate: "備註模板",
              automaticArchiving: "自動歸檔",
              conditionalRules: "條件規則",
              qualityInspectionConditions: "質檢條件",
              unopened: "暫未開啟",
              sampleInspectionDistributionModel: "抽檢分配",
              reviewAllocationModel: "複核分配",
              distributionModelTitle: "分配策略:",
              pushRules: "推送規則",
              push: "推送",
              assessRules: "評估規則",
              on: "on",
              off: "off",
              updateStatusSuccess: "狀態更改成功!",
              updateStatusFailed: "狀態更改失敗,請稍後重試!",
              sampleNumberOrPercent: "抽樣條數或者比例",
              inspectorList: "指定質檢員",
              numberOfSubmission: "允許提交申述次數",
              rewivewDays: "日",
              TimeOfSubmission: "提交申述有效期",
              rewivewSecond: "次",
              switchOn: "開啟",
              switchOff: "關閉",
              notMatchAnyRule: "唔匹配任意規則時:",
              appealOptionsList: {
                hiddenInspector: "申訴隱藏質檢員信息",
                hiddenHit: "申訴頁面支持高亮定位命中",
              },
              caseLibrary: "案例庫同步",
              caseLibrarySyncTip: "將QA系統添加嘅案例同步至客服系統",
              addCase: "增加同步",
              caseLibraryId: "案例庫",
              caseLibraryIdTip: "QA系統嘅案例庫分組",
              udeskCaseLibraryIdTip: "客服系統嘅案例分組",
              syncHis: "同步歷史案例",
              otherSettings: "其他設置",
              udeskCaseLibraryId: "推送案例組",
              url: "推送URL",
              urlTip: "請輸入以 http:// 或者 https:// 開頭嘅接口地址",
            },
            templateNew: {
              secondaryReview: "二級複核",
              numberOfAppealsAllowedToBeSubmitted: "允許提交申訴次數",
              validityOfSubmissionOfAppeal: "提交申訴有效期",
              validityOfAppealReview: "申訴複核有效期",
              hours: "小時",
              businessSummary: "業務小結",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "每客服抽取下限",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "由大模型自動生成業務小結,根據業務情況可自定義建置維度",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "僅一級複核員分配策略",
              secondaryCompounding: "二級複合:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "二級複核員僅支持平均分配",
              secondLevelReviewer: "二級複核員:",
              samplingInspectionTarget: "抽檢目標",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "設定人工抽檢應完成嘅目標,目標未達成或者超過時給出顯示,例如每客服每月應完成抽檢100條,低於80條時,質檢、抽檢頁面進行提示。",
            },
            component: {
              verificationFailed: "校驗失敗",
              pleaseAddASecondLevelQualityInspector: "請添加二級質檢員",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "提示語",
                    fieldName: "字段名稱",
                    fieldDescription: "字段描述",
                    generateContent: "生成內容",
                    enabled: "係咪啟用",
                    generationMethod: "生成方式",
                    automaticGeneration: "自動生成",
                    manualTrigger: "手動觸發",
                    filterCondition: "篩選條件",
                    return: "返回",
                    preserve: "保存",
                  },
                  components: {
                    training: { index: { generateResults: "生成結果" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "唔限定",
                    limitedScope: "限定範圍",
                    add: "添加",
                    thereIsCurrentlyNoDataAvailable: "暫無資料",
                  },
                },
              },
              index: {
                fieldName: "字段名稱",
                fieldDescription: "字段描述",
                nothing: "無",
                generationMethod: "生成方式",
                generateManually: "手動生成",
                automaticGeneration: "自動生成",
                generateContent: "生成內容",
                notQualified: "唔限定",
                filterCondition: "篩選條件",
                enabled: "係咪啟用",
                type: "類型",
                system: "系統",
                custom: "自定義",
                operation: "操作",
                edit: "編輯",
                areYouSureToDeleteIt: "肯定刪除呀?",
                delete: "刪除",
                addField: "增加字段",
              },
            },
          },
          roleConfig: {
            index: {
              name: "角色配置",
              roleConfigTip:
                "用於對導入嘅通話資料為單聲道時,為話者分離處理配置識別規則。 符合規則嘅為客服角色",
              operator: "算子",
              operatorLogic: "算子邏輯",
            },
          },
          manualAdd: {
            index: {
              name: "手動添加",
              tip: "用於透過棍設定條件篩選預覽資料同快速將其加入所處質檢任務",
              screeningCondition: "按照篩選條件添加",
              aiScreeningCondition: "智能篩選條件",
              rule: "規則",
              createTime: "添加時間",
              filterTitle: "篩選內容",
              filterRule: "篩選條件",
              startTime: "開始時間",
              endTime: "結束時間",
              records: "導入記錄",
              createUserName: "操作人員",
              successCount: "添加成功",
              conditionRule: "條件規則:",
              preview: "預覽",
              previewData:
                "當前符合篩選條件{0} 條, 已經加入任務 {1} 條, 可加入任務 {2} 條, 唔可加入任務 {3} 條",
              failCount: "添加失敗",
            },
          },
          basicInfo: {
            index: {
              title: "基本信息",
              name: "名稱",
              taskName: "任務名稱",
              taskNamePlaceholder: "請輸入任務名稱",
              requiredName: "任務名稱唔能為空",
              taskRemarkPlaceholder: "請輸入任務描述",
              requiredRemark: "任務描述唔能為空",
              remark: "描述",
              taskRemark: "任務描述",
              source: "資料源",
              benchmarkScore: "基準分",
              benchmarkScorePlaceholder: "請輸入基準分",
              scoreLowerLimit: "最低得分下限",
              scoreUpperLimit: "最高得分上限",
              inspectType: "質檢類型",
              nlpModel: "NLP模型",
              commonInspect: "常規質檢",
              associateInspect: "關聯質檢",
              associateCycle: "關聯週期",
              associateRegulation: "關聯規則",
              judgeStrategy: "執行邏輯",
              rule: "規則",
              commonTip: "常規質檢:適用於單次通、對話,將單次溝通嘅內容進行質檢",
              associateTip:
                "關聯質檢:適用於多次嘅通、對話,將多次溝通嘅內容關聯起來進行質檢",
              cycleTip:
                "如果關聯嘅多個通、對話,符合關聯週期內嘅資料進入該任務質檢",
              scoreLowerLimitPlaceholder: "請輸入最低得分下限",
              scoreUpperLimitPlaceholder: "請輸入最高得分上限",
              call: "語音通話",
              dialogue: "文本對話",
              user: "質檢員",
              wechatUser: "操作員",
              noDesignated: "唔指定",
              type: "表單類型",
              singleScore: "單項分數",
              conduct: "請先完善下列信息,完成後請點擊下一步",
              conduct2:
                "請完成質檢規則同質檢點嘅配置,若你已經完成配置, 你可以點擊下一步或退出引導",
              conduct3:
                "質檢評級係依據質檢點同其評分情況進行評級嘅配置,你可以定義唔同嘅質檢結果級別,若你對評級冇需求你可以直接跳過此步驟,若你已經完成配置,你可以點擊下一步或退出引導",
              conduct4:
                "你可以喺自動添加位置按照本質檢任務應當檢查嘅資料特性, 對將會畀自動引入任務嘅資料特性進行配置,若你暫時唔需要自動添加資料, 可以點擊跳過, 若你已經完成配置, 你可以點擊下一步",
              conduct5:
                "若你使用嘅係單聲道錄音, 請你務必配置此項並點擊下一步, 請使用僅客服會使用嘅話術幫助系統識別出客服同客户嘅唔同軌道.若你使用雙聲道錄音或已經完成配置, 你可以點擊跳過",
              conduct6:
                "你可以透過手動添加功能,引入系統里已經有嘅通對話資料進行質檢, 或者你已經提交手動添加任務, 請你直接點擊完成",
              helper: "檢視幫助文檔",
              quit: "退出引導",
              next: "下一步",
              complete: "完成",
              skip: "跳過",
              cancel: "取消",
              delete: "刪除",
              save: "保存",
            },
            template: { samplingInspector: "抽檢員", sessionType: "會話類型" },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "質檢工作台",
              search: "篩選",
              export: "導出",
              condition: "條件",
              logic: "邏輯",
              reQualityInspection: "重新質檢",
              reQualityInspectionUnderWay: "重新質檢任務中...",
              reQualityInspectionUnderWayTip:
                "關閉窗口後離開頁面唔會影響任務進行",
              reQualityInspectionDone: "重新質檢已經完成",
              reQualityInspectionDoneTip:
                "現已經成功完成重新質檢, 請點擊確認按鈕",
              endReQualityInspection: "終止任務",
              alreadyDoneTip: "已經完成",
              totalTip: "合計",
              unit: "條",
              endReInspectionSuccess: "重新質檢任務已經終止",
              closeWindow: "關閉窗口",
              endReInspectionFailed: "重新質檢任務失敗,請稍後重試!",
            },
            detail: {
              name: "質檢詳情",
              reviewName: "複核詳情",
              spotCheckName: "抽檢詳情",
              audioText: "錄音文本",
              callInfo: "通話信息",
              satisfactionEvaluation: "滿意度評價:",
              queueTime: "排隊耗時:",
              ringingTime: "振鈴時間:",
              callTime: "通話單嘢:",
              hangUpIdentity: "通話掛斷方:",
              location: "歸屬地:",
              source: "來源:",
              agent: "客服:",
              deviceType: "設備類型:",
              callType: "通話類型:",
              phoneNumber: "中繼號:",
              task: "任務:",
              commentsTip: "請輸入質檢評語",
              lastPage: "上一頁",
              nextPage: "下一頁",
              score: "質檢評分",
              review: "複核",
              file: "歸檔",
              changeList: "變更記錄",
              fullMarks: "滿分",
              most: "最多",
              saveReview: "複核提交",
              saveSpotCheck: "抽檢提交",
              point: "質檢點",
              sampleAssignInspector: "分配抽檢員",
              reviewAssignInspector: "分配複核員",
              sampleStatus: "抽檢狀態",
              read: "已經讀",
              user: "用户",
              confirmer: "確認人",
              voiceDownloadError: "語音下載失敗,請稍後重試!",
              smartTags: "智能標籤",
              interactiveTip: "使用交互識別對客服FAQ嘅使用情況進行檢測",
              interactiveIsEmptyTip: "未檢測到交互識別內容",
              businessRecords: "業務記錄",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: { analysisOfEnterpriseWeChatGroupChat: "企業微信群聊分析" },
          },
        },
      },
      cards: {
        health: "質檢健康度",
        accuracy: "發現核准率",
        sessionsTotal: "會話總數",
        enable: "啟用",
        disable: "禁用",
        none: "暫無",
        call: "通話",
        dialogue: "對話",
        taobao: "電商",
        wecom: "會話",
        ticket: "工單",
      },
      component: {
        backup: "(備份)",
        taskSaveAsSucceeded: "任務另存為成功",
        copyContent: "複製內容",
        essentialInformation: "基本信息",
        ruleConfiguration: "規則配置",
        basicInformationQualityInspector: "基本信息-質檢員",
        qualityInspectionRating: "質檢評級",
        roleConfiguration: "角色配置",
        autoAddConfiguration: "自動添加配置",
        autoPushConfiguration: "自動推送配置",
        automaticSamplingConfiguration: "自動抽檢配置",
        automaticAllocationConfiguration: "自動分配配置",
        businessConfiguration: "業務配置",
        caseBaseConfiguration: "案例庫",
      },
      template: {
        cancel: "取消",
        complete: "完成",
        sort: "排序",
        ruleApproval: "規則審批",
        enterpriseMicroConversationAnalysisTask: "企微會話分析任務",
        enterpriseAndMicroVoiceAnalysisTask: "企微語音分析任務",
        saveTaskAs: "任務另存為",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "規則詳情" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "修改成功",
            automaticApproval: "自動審批",
          },
          pageHeaderExtra: {
            modificationSucceeded: "修改成功",
            test: "測試",
            formal: "正式",
            adopt: "透過",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "確定駁回呀",
              pleaseEnterTheRejectionReason: "請輸入駁回原因",
              reject: "駁回",
            },
          },
          table: {
            index: {
              modificationSucceeded: "修改成功",
              taskName: "任務名稱",
              dataSource: "資料源",
              qualityInspectionType: "質檢類型",
              state: "狀態",
              updateTime: "更新時間",
              updatedBy: "更新人",
              approver: "審批人",
              reasonForRejection: "駁回原因",
              operation: "操作",
              details: "詳情",
              adopt: "透過",
              whole: "全部",
              approvalStatus: "審批狀態",
            },
          },
        },
      },
    },
    dashboard: {
      title: "概覽",
      hi: "閪,",
      standardEdition: "標準版",
      call: "語音質檢餘額",
      text: "文本質檢有效期",
      collectionTasks: "收藏嘅質檢任務",
      noCollection: "暫無收藏嘅質檢任務,",
      goToCollecte: "去收藏",
      yesterdayOverView: "昨日業務統計",
      yesterdayAgent: "昨日客服排行榜",
      yesterdayInspWork: "昨日質檢員工作情況",
      operationalStatistics: {
        title: "業務統計",
        qualityTesting: "質檢",
        spotCheck: "抽檢",
        appeal: "申訴",
        chartTitles: {
          qualityTestingTrend: "質檢量趨勢",
          qualityTestingAnalysis: "質檢任務情況",
          spotCheckTrend: "抽檢趨勢",
          spotCheckAnalysis: "抽檢任務情況",
          appealTrend: "申訴趨勢",
          appealAnalysis: "申訴任務情況",
        },
        titles: {
          qualityDataTend: "質檢資料分佈",
          qualityCover: "質檢覆蓋情況",
          qualityAverageTime: "平均質檢等待時長",
          qualityCoverTend: "質檢覆蓋趨勢",
          handCover: "人工處理情況",
          checkCover: "抽檢覆蓋率",
          resAverageTime: "平均處理時長",
          handTend: "人工處理趨勢",
          dataTend: "資料任務分佈",
        },
        qualityAverage: "質檢平均分",
        scoreTrend: "質檢評分趨勢",
        qualityAverageTime: "平均質檢等待時長",
        callCoverage: "語音質檢覆蓋率",
        textCoverage: "文本質檢覆蓋率",
        checkCount: "抽檢資料量",
        siphonCount: "抽取資料量",
        reviewCount: "複核資料量",
        appealCount: "申訴資料量",
        checkRate: "抽檢執行比例",
        reviewRate: "申訴複核比例",
        PieTitle: {
          totalCount: "通對話總數",
          totalCall: "語音通話數",
          totalIm: "文本對話數",
          inspectionCall: "語音質檢量",
          inspectionIm: "文本質檢量",
          checkCall: "語音抽檢量",
          unCheckCall: "語音未抽檢量",
          checkIm: "文本抽檢量",
          unCheckIm: "文本未抽檢量",
          reviewCall: "語音複核量",
          reviewIm: "文本複核量",
          unReviewCall: "語音未複核量",
          unReviewIm: "文本未複核量",
          unInspectionCall: "語音未質檢量",
          unInspectionIm: "文本未質檢量",
        },
        check_rate: "抽檢覆蓋率",
        voice_check_rate: "語音抽檢覆蓋率",
        text_check_rate: "文本抽檢覆蓋率",
        sample_execute_time: "平均抽檢處理時長",
        review_execute_time: "平均申訴處理時長",
      },
      analysisOverview: {
        tableTitle: { conditions: "質檢規則看板", agent: "客服排行榜" },
        tableTips: {
          conditions:
            "<Div>對質檢規則違規、合規嘅通對話總數進行排序。 <Br />\n質檢項:質檢點嘅名稱<br />\n違規通對話總數: 喺應該質檢項上違規嘅通對話總數 <br />\n違規佔比:違規佔比=違規通對話總數/通對話總數</div>",
          agent:
            "<Div>對坐席嘅平均評分進行排序<br />\n客服名稱:客服嘅名稱<br />\n客服組:客服所喺客服組(客服信息內嘅組織信息)<br />\n評分嘅通對話總數:該客服嘅質檢總通對話數<br />\n質檢平均得分:該客服嘅質檢平均得分(得分求同/通對話總數)</div>",
        },
        tips: {
          total: "進入質檢任務嘅通/對話總數",
          agentTotal: "進入質檢任務嘅質檢客服總數",
          avg: "質檢評分嘅平均分",
          violation: "所有嘅通呢個對話呢質檢命中質檢項違規嘅次數求同",
          compliance: "通呢個對話呢質檢過程中命中質檢項合規嘅次數求同",
          check: "人工抽檢嘅總數",
          appeal: "通對話總數中提交過申訴嘅總數",
          review: "通對話總數中提交過複核嘅總數",
        },
        subTips: {
          violation: "違規率:違規總次數/質檢項總次數*100%",
          compliance: "合規率:合規總次數/質檢項總次數*100%",
          check: "抽檢覆蓋率:人工抽檢總數/質檢總量*100%",
          appeal: "申訴率:申訴總數/質檢總量*100%",
          review: "複核率:複核總數/質檢總量*100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "系統設置",
          systemName: "系統名稱",
          speakerRecognize: "話者識別",
          massage: "消息通知",
          evaluate: "人工認知評價",
          evaluateTip: "開啟後喺質檢詳情中選擇快速抽檢會提示進行人工評價",
          speakerRecognizeTip:
            "透過API接入第三方嘅通話錄音需要標識左聲道係客服仲係客戶",
          speakerRecognizeTip2: "單通道錄音透過文本識別,角色配置定義客服嘅角色",
          enabledImSendStatusFilter: "文本質檢消息過濾",
          enabledImSendStatusFilterTips:
            "質檢嘅算子話術嘅檢測內容唔包含狀態為【已經撤回】、【發送失敗】、【離線消息未讀】嘅消息",
          lang: "系統語言設置",
          zh: "簡體中文",
          en: "English",
          tw: "繁體中文",
        },
        template: {
          largeModelSettings: "大模型設置",
          indonesian: "印尼語",
          vietnamese: "越南語",
          portuguese: "葡萄牙語",
          malay: "馬來語",
          french: "法語",
          spanish: "西班牙語",
          japanese: "日文",
          thai: "泰語",
          blockSystemSendingMessages: "屏蔽系統發送消息",
          textDataDesensitization: "文本資料脫敏",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "開關開啟則展示脫敏字段下拉列表(支持多選),脫敏字段:人名、手機號、地址",
          desensitizingField: "脫敏字段",
          name: "人名",
          cellphoneNumber: "手機號",
          address: "地址",
          dataCleaning: "資料清理",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "設置後,質檢通對話、企業微信會話資料將定期畀清理,且唔可恢復,請謹慎操作",
          newCleaningRules: "新增清理規則",
          qualityInspectionRuleApproval: "質檢規則審批",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "開啟後, 質檢規則修改需要畀審批透過後先可以生效",
          maskOfflineMessageUnread: "屏蔽離線消息未讀",
          privacyNumberProtection: "私隱號碼保護",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            '開啟後對通話中"客戶電話”字段進行脱敏,顯示為1881*******',
          shieldingWithdrawn: "屏蔽已經撤回",
          maskSendingFailed: "屏蔽發送失敗",
        },
        component: {
          conversation: "通話",
          dialogue: "對話",
          weCom: "企業微信",
          day: "日",
          week: "週",
          month: "月",
          year: "年",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "清理範圍",
            dataCleaningTimeSetting: "資料清理時間設置",
            afterCleanUpTheData: "後,清理資料",
            dataAfterComplaintCompletion: "申訴完成後資料",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "執行成功",
          saveSuccessful: "保存成功",
          speechRecognition: "語音識別",
          reExecuteErrorCorrection: "重新執行糾錯",
          confirmExecution: "確認執行",
          voiceDetection: "語音檢測",
          noiseDetectionPowerLevel: "噪音檢測 powerLevel ≤",
          pleaseEnterTheThreshold: "請輸入閾值",
          volumeDetectionPowerLevel: "音量檢測 powerLevel ≥",
          reset: "重置",
          preserve: "保存",
        },
        coachTemplate: {
          french: "法語",
          spanish: "西班牙語",
          japanese: "日文",
          thai: "泰語",
          loginPopupWindow: "登錄彈窗",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "開啟後會喺進入智能陪練系統時進行彈窗提示",
          title: "標題",
          text: "正文",
          checkBoxContent: "勾選框內容",
          informedConsentForm: "知情同意書",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "為幫助你提高溝通同話術散手同專業知識嘅應用,允許你隨時隨地進行練習並接受實時反饋, 我哋將透過本平台,收集你嘅以下信息:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "本人已經完整閱讀並充分理解本知情同意書,並鄭重聲明同意根據本知情同意書所述嘅目嘅同方式處理我嘅敏感個人信息",
        },
      },
      systemIntegration: {
        title: "系統設置",
        ccIntegrationTips: "智能質檢對接Udesk呼叫中心説明檔案",
        unauthorized: "未授權",
        authorized: "已經授權",
        udeskDomain: "客服系統域名",
        udeskToken: "客服系統密鑰",
        udeskAdminEmail: "客服系統管理員帳號",
        password: "管理員帳號密碼",
        applyAuth: "授權",
        cancelAuth: "取消授權",
        reApplyAuth: "重新授權",
        keyTips: "客服系統後台管理-單點登錄中嘅API鑑權Token",
      },
      customerField: {
        title: "客户字段",
        table: {
          propName: "字段名稱",
          propDesc: "字段描述",
          valueType: "字段類型",
          propType: "創建方式",
          createTime: "創建時間",
          canNotEdit: "系統字段唔支持編輯",
        },
        edit: "編輯字段",
        new: "新建字段",
      },
      wechatDocking: {
        title: "企微對接",
        formLabels: {
          companyId: "企業ID",
          communicationKey: "通訊密鑰",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "事件回調URL",
          applicationName: "應用名稱",
          applicationId: "網頁應用ID",
          applicationSecret: "應用secret",
        },
        formTitle: {
          initialForm: "初始設置",
          synchronizeForm: "通訊錄同步",
          settingForm: "應用設置",
        },
        qrCodeUrlTip: "請管理員掃描下方二維碼進行代開發應用綁定",
      },
      taobao: {
        formLabels: {
          name: "門店名稱",
          account: "賬號",
          password: "密碼",
          confirm: "確認密碼",
          authorization: "授權",
          switchOn: "開啟",
          switchOff: "關閉",
        },
        tableLabels: {
          name: "門店名稱",
          account: "賬號",
          authorization: "授權",
          updateUser: "最近更新人",
          updateTime: "最近更新時間",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "微豐二級域名",
          secretKeyTip: "租户祕鑰,喺微豐系統後台系統對接處獲取",
        },
      },
      user: {
        index: {
          name: "員工管理",
          existingUsers: "已經有員工",
          deleteSuccess: "刪除成功",
          deleteFailed: "刪除失敗",
        },
        detail: {
          accountPrompt: "請輸入郵箱",
          resetPassword: "重置密碼",
          passwordPrompt: "密碼(6-14位字母同數字組合)",
          originPasswordPrompt: "請輸入初始密碼",
          resumePasswordPrompt: "請重新輸入密碼",
          passwordFormWrong: "請輸入6-14位字母同數字組合嘅密碼!",
          resumePasswordWrong: "兩次密碼唔一致!",
          suppertImageType: "支持jpg、gif、 png ,最大 500Kb",
          namePrompt: "請輸入姓名",
          nickNamePrompt: "請輸入暱稱",
          telephonePrompt: "請輸入電話號碼",
          roleSelectedPrompt: "請選擇角色",
          agentIdPrompt: "請輸入工號",
          disabled: "禁用",
          enabled: "啟用",
          reloadBowerInfo: "用户信息變更,需要重新刷新瀏覽器呀?",
          saveUserError: "用户信息保存失敗,請稍後重試",
          resetPasswordSuccess: "密碼重置成功",
          resetPasswordFailed: "密碼重置失敗,請稍後重試",
        },
      },
      userGroups: {
        index: { name: "員工組管理", existingUserGroups: "已經有員工組" },
        detail: {},
      },
      role: {
        index: { name: "角色管理", existingRoles: "已經有角色" },
        edit: {
          editRole: "編輯角色",
          createRole: "創建角色",
          basicInfo: "基本信息",
          taskPermissions: "任務權限",
          existingTasks: "已經有任務",
        },
      },
      anomalousData: {
        title: "異常資料",
        list: {
          selectHolder: "請選擇資料源",
          businessTime: "業務時間",
          businessId: "業務Id",
          export: "導出",
        },
      },
      templateComponent: {
        list: {
          title: "模板市場",
          name: "模板名稱",
          searchHolder: "請輸入要查詢嘅內容",
          update: "更新情況",
          sort: "排序方式",
          moduleType: "模板類型",
          moduleSourceType: "適用類型",
          fetchNum: "獲取次數",
          newest: "最近更新",
          usedMost: "獲取最多",
          call: "通話",
          dialogue: "對話",
          waiting: "待審核",
          cancel: "取消發佈",
          success: "審核透過, 已經發佈",
          failed: "審核駁回",
          no: "唔可升級",
          ok: "可以升級",
          all: "全部",
          delete: "刪除成功",
          downOk: "下架成功",
          withdraw: "撤回成功",
          updateOk: "升級成功",
          get: "獲取",
          width: "撤回",
          getOk: "獲取成功",
          orgName: "所屬組織",
          nowVersion: "當前版本",
          lastedVersion: "最新版本",
          appStatus: "應用狀態",
          actions: "操作",
          down: "下架",
          updateTop: "升級",
          check: "檢視",
          deleteWhen: "確認刪除呀?",
          yes: "係",
          noo: "否",
          deleteOk: "刪除",
          permissible: "允許",
          noPermissible: "唔允許",
          openUp: "開放",
          openDown: "唔開放",
          task: "質檢任務",
          point: "質檢點",
          tag: "語義標籤",
          muti: "多要素",
          inter: "一問一答",
          info: "信息實體",
          concept: "概念詞",
          detail: "模組詳情",
          infomation: "基本信息",
          detailConfig: "詳情配置",
          twoEdit: "二次編輯",
          mustUpdate: "立即升級",
          descition: "模板描述",
          descitionSize: "模板描述唔能超過250個字",
          stencilsDetail: "模板詳情",
          updatedRecords: "更新記錄",
          version: "版本號",
          updateDescription: "更新描述",
          myModule: "我嘅模板",
          addModule: "新建模組",
          publishVersion: "發佈版本",
          earliestVersion: "最早版本",
          edit: "編輯",
          add: "新增",
          configNo: "模組配置詳情唔能為空",
          updateNo: "更新説明唔能為空",
          editSuccess: "修改成功",
          saveSuccess: "保存成功",
          newer: "更新",
          sumbit: "提交",
          cancelName: "取消",
          nameRequired: "模組名稱係必填字段",
          nameSize: "模組名稱唔能超過16個字",
          publishStatus: "發佈狀態",
          submitUpdate: "提交更新",
          updateVersion: "更新版本",
          updateExplain: "更新説明",
        },
      },
      customDataSource: {
        amazonEcommerce: "亞馬遜電商",
        youtubeVideo: "Youtube視頻",
        amazon: {
          component: {
            queuing: "排隊中",
            notStarted: "未開始",
            inProgress: "進行中",
            success: "成功",
            failed: "失敗",
            successfullyCreated: "創建成功",
            editedSuccessfully: "編輯成功",
            operationSucceeded: "操作成功",
            deletedSuccessfully: "刪除成功",
            link: "連結",
            grabState: "抓取狀態",
            updateTime: "更新時間",
            grab: "抓取",
            edit: "編輯",
          },
          template: {
            amazonEcommerce: "亞馬遜電商",
            thisProduct: "本品",
            competition: "競品",
            new: "新建",
            edit: "編輯",
            link: "連結",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube視頻",
            thisProduct: "本品",
            competition: "競品",
            new: "新建",
            edit: "編輯",
            link: "連結",
          },
          component: {
            queuing: "排隊中",
            notStarted: "未開始",
            inProgress: "進行中",
            success: "成功",
            failed: "失敗",
            successfullyCreated: "創建成功",
            editedSuccessfully: "編輯成功",
            operationSucceeded: "操作成功",
            deletedSuccessfully: "刪除成功",
            youTubeVideoLink: "YouTube視頻連結",
            grabState: "抓取狀態",
            updateTime: "更新時間",
            grab: "抓取",
            edit: "編輯",
          },
        },
      },
    },
    demo: {
      title: "智能質檢",
      template: "選擇質檢模板",
      radio: "選擇錄音形式",
      uploadAudio: "上傳錄音",
      uploadAudioTip: "上傳錄音僅支持wav同mp3格式,大小唔超過1G",
      uploadText: "上傳文本",
      uploadTextTip:
        "上傳文本僅支持srt同txt格式,大小唔超過10M,上傳文本後將唔再使用ASR解析錄音文件",
      uploadSuccess: "錄音文件上傳成功",
      uploadFailed: "錄音文件上傳失敗",
      startQualityInspection: "開始質檢",
      qualityInspectionStarted: "正喺質檢中",
      downloadText: "文本下載",
      checking: "質檢進度",
      checkingTip: "預計需要數分鐘,請耐心等待",
      noCheckContent: "尚無質檢結果",
      noTextDownload: "暫無質檢文本可下載!",
      pleaseCheckParams: "請檢查係咪選擇模板、語音類型同上傳錄音文件",
      inspectionFailed: "質檢失敗,請稍後重試!",
    },
    wechatQa: {
      baseInfo: { title: "基本信息" },
      conversationSetting: {
        title: "會話設置",
        segmentationStrategy: "切分策略",
        rule: "策略",
        time: "切分時間",
        daily: "每日",
        passScore: "會話合格分",
        ruleLogic: "執行邏輯",
      },
      message: {
        title: "推送事件",
        table: {
          index: "序號",
          name: "名稱",
          type: "推送方式",
          desc: "描述",
          status: "狀態",
        },
        subTitle: "事件列表",
      },
      checkPoint: {
        title: "分析模型",
        groups: "模型分組",
        newClassification: "新建分組",
        editClassification: "編輯分組",
        benchmarkScore: "基準分",
        pointCount: "當前分類下共有分析模型",
        openPointCount: "啟用數量為",
        closedPointCount: "停用數量為",
        scoreLowerLimit: "最低得分下限",
        scoreUpperLimit: "最高得分上限",
        classificationName: "分組名稱",
        classificationNamePlaceHolder: "請輸入分組名稱",
        classificationRemark: "分組描述",
        classificationRemarkPlaceHolder: "請輸入分組描述",
      },
      chartRecord: {
        title: "企業微信會話",
        table: {
          id: "會話ID",
          type: "會話類型",
          startTime: "會話開始時間",
          endTime: "會話最近結束時間",
          groupId: "群ID",
          client: "客户",
          customer: "客服",
          averageScore: "平均質檢評分",
        },
        detail: {
          title: "企業微信會話詳情",
          inspectionInfo: "分析信息",
          chartInfo: "會話信息",
          tasks: "分析任務",
          avgScore: "分析平均分",
          qualified: "合格",
          unqualified: "唔合格",
          taskTitle: "適用分析模板",
          info: "實時分析信息",
          today: "今日消息",
          history: "歷史消息",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "請輸入備註/暱稱",
                pleaseEnterTheGroupChatName: "請輸入群聊名稱",
              },
            },
          },
          template: { whole: "全部" },
        },
      },
      realTimeAnalysis: {
        title: "實時分析",
        table: {
          name: "場景名稱",
          count: "分析模型數量",
          baseScore: "基準分",
          passScore: "合格分",
          maxScore: "最高得分上限",
          minScore: "最低得分下限",
          status: "狀態",
          actions: "操作",
        },
        detail: {
          title: "實時分析場景",
          form: {
            name: "場景名稱",
            prevCondition: "場景觸發條件",
            prevConditionToolTip: "若條件為空,則默認對此場景進行分析",
            prevConditionError: "場景觸發條件唔能同分析模型重合",
            scoreInputPlaceholder: "輸入分數",
            baseScore: "基準分",
            status: "狀態",
            passScore: "合格分",
            maxScore: "最高分上限",
            minScore: "最低分下限",
            analysisModel: "分析模型",
            message: "觸發通知",
            pushType: "推送方式",
            createAnalysisModel: "添加模型",
            scoreUpperLimitError: "最低分下限唔能大於最高分上限",
            table: { name: "模型名稱", rule: "規則", score: "分值" },
            pushConfigSwitchTip1: "當該場景出現",
            pushConfigSwitchTip2: "次同以上時,推送通知",
          },
        },
      },
      smartCustomerTag: {
        title: "智能客戶標籤",
        table: {
          name: "規則名稱",
          tags: "企微客戶標籤",
          status: "啟用狀態",
          createUser: "創建人",
          createTime: "創建時間",
        },
        detail: {
          name: "規則名稱",
          condition: "設置規則",
          logic: "邏輯",
          status: "啟用狀態",
          tags: "選擇標籤",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "客户識認數量" },
        },
        messageFiltering: {
          template: {
            edit: "編輯",
            messageContent: "消息內容",
            messageTypeID: "消息類型ID",
            messageTypeDescription: "消息類型描述",
            messageFiltering: "消息過濾",
            suitableForFilteringDataWithoutQualityInspection:
              "適用於過濾無需質檢嘅資料",
            create: "創建",
            messageDescription: "消息描述",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "通話時間",
          sessionType: "會話類型",
          whole: "全部",
          staff: "員工",
          customer: "客户",
          callKeywords: "通話關鍵詞",
        },
        detail: {
          template: { enterpriseMicroVoiceCallDetails: "企微語音通話詳情" },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "會話存檔",
        voiceCall: "語音通話",
        noMoreVoiceCalls: "暫無更多語音通話",
        enterpriseWechatVoiceCall: "企業微信語音通話",
        enterpriseWechatConversationDetails: "企業微信會話詳情",
        previous: "上一條",
        next: "下一條",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask: "企微會話分析任務",
          enterpriseAndMicroVoiceAnalysisTask: "企微語音分析任務",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat: "企業微信群聊分析",
      },
      wechatQaSettingEntry: { messageFiltering: "消息過濾" },
    },
    businessAnalysis: {
      keyword: "報表名稱",
      createUser: "創建人",
      createTime: "創建時間",
      updateTime: "更新時間",
    },
    semanticIntelligence: {
      applicationMessageType: "應用消息類型",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "請完善配置",
              generatePromptLanguage: "生成提示語",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "係咪符合",
              gradedEvaluation: "分檔評價",
            },
          },
          training: {
            index: {
              pleaseEnter: "請輸入",
              pleaseGeneratePrompt: "請生成提示語",
              pleaseSelectADialogue: "請選擇對話",
              training: "訓練",
              dataType: "資料類型:",
              voiceCall: "語音通話",
              textDialogue: "文本對話",
              generate: "生成",
              qualityInspectionResults: "質檢結果:",
              hit: "命中話術:",
            },
          },
        },
        detail: {
          index: {
            modelName: "模型名稱",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "請輸入模型名稱,例如:客服係否存喺無作為嘅情況",
            modelDescription: "模型描述",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "可詳細描述你需要檢測嘅內容,便於模型理解,如:客服係否存喺無作為嘅情況, 即針對客户嘅提問客服有回覆, 但客戶問題通篇冇得到處理或回覆內容同客人回覆無關",
            validity: "有效性",
            effective: "有效",
            invalid: "無效",
            type: "類型",
            hintLanguage: "提示語",
            editIntelligentModel: "編輯智能模型",
            createANewIntelligentModel: "新建智能模型",
          },
        },
        index: {
          modelName: "模型名稱",
          modelDescription: "模型描述",
          type: "類型",
          whetherItMeetsTheRequirements: "係咪符合",
          gradedEvaluation: "分檔評價",
          validity: "有效性",
          intelligentModel: "智能模型",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "適用於主觀項質檢 (如質檢服務態度係咪熱烈、服務係咪好有耐性等場景)",
        },
      },
      clusterAnalysis: "聚類分析",
      speechMining: "話術挖掘",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "支持透過API接口批量導入信息實體,該API名稱保存後唔支持編輯",
        },
        component: { nothing: "無" },
      },
      knowledgeBase: {
        index: {
          classification: "分類",
          import: "導入",
          importKnowledgeBase: "導入知識庫",
          moveKnowledgePointsInBatch: "批量移動知識點",
          moveTo: "移動到:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "請選擇需要批量移動嘅知識點",
        },
        detail: { index: { intrasentence: "句內", afterProblem: "問題後" } },
      },
      semanticTags: { component: { nothing: "無" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "敏感詞",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "敏感詞:敏感詞可配置業務關注詞,用於輿情發現敏感詞詞雲",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "新建敏感詞",
            editSensitiveWords: "編輯敏感詞",
            sensitiveWords: "敏感詞",
          },
          template: {
            sensitiveWords: "敏感詞",
            sensitiveWordsCannotBeEmpty: "敏感詞唔能為空",
            pleaseEnterSensitiveWordsSeparatedBy:
              "請輸入敏感詞,多個敏感詞中間用','隔開",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "請選擇應用場景",
            keyWord: "關鍵詞",
            pleaseEnterKeywords: "請輸入關鍵詞",
            keywordLengthWithinCharacters: "關鍵詞長度40字符內",
            dataSources: "資料來源",
            pleaseSelectADataSource: "請選擇資料來源",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "當前聚類已經完成,重新編輯將產生一條新嘅聚類資料, 唔會覆蓋已經完成嘅聚類資料, 請確認",
            savingSucceeded: "保存成功",
            clusteringStart: "聚類開始",
            clustering: "聚類",
            edit: "編輯",
            newlyBuild: "新建",
            clusterName: "聚類名稱",
            pleaseEnterAName: "請輸入名稱",
            withinCharactersOfTheName: "名稱長度40字符內",
            speaker: "説話人",
            pleaseSelectASpeaker: "請選擇説話人",
            sessionDate: "會話日期",
            pleaseEnterTheSessionDate: "請輸入會話日期",
            selectUpToMonthOfHistoricalSessionData:
              "最多選擇1個月嘅歷史會話資料",
            automaticallyClusterDailyNewData: "自動聚類每日新增資料",
            conversationChannel: "會話渠道",
            pleaseSelectAConversationChannel: "請選擇會話渠道",
            applicationScenario: "應用場景",
            clusteringRules: "聚類規則",
            incompleteClusteringRuleSettings: "聚類規則設置唔完整",
            startClustering: "開始聚類",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask: "請選擇質檢任務",
              beEqualTo: "等於",
            },
          },
        },
        detail: {
          index: {
            theme: "主題",
            numberOfCorpora: "語料數",
            corpusCoverage: "語料覆蓋",
            clusterDetails: "聚類詳情",
          },
          ranking: {
            hotWords: "熱詞",
            numberOfCorpora: "語料數",
            speaker: "説話人",
            sit: "坐",
            passenger: "客",
            hotSentence: "熱句",
            corpusDetails: "語料詳情",
          },
        },
        index: {
          delete: "刪除",
          deletionSucceeded: "刪除成功",
          clusterName: "聚類名稱",
          speaker: "説話人",
          creationTime: "創建時間",
          state: "狀態",
          operation: "操作",
          see: "檢視",
          edit: "編輯",
          clusterAnalysis: "聚類分析",
          newCluster: "新建聚類",
          clusteringStatus: "聚類狀態",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections: "輸入關鍵詞搜索異議",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "話術庫",
                recommendedScripts: "推薦話術",
                common: "共",
                strip: "條",
                scriptFavorites: "話術收藏夾",
              },
              conditionPostfix: {
                detectRoles: "檢測角色",
                detectionRange: "檢測範圍",
                sentence: "句",
              },
              customerFilter: { scriptFiltering: "話術過濾", cancel: "取消" },
              index: {
                viewDetails: "檢視詳情",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "你確認要捨棄呀? 捨棄後將唔能再恢復",
                abandonment: "捨棄",
                deletionSucceeded: "刪除成功",
                passenger: "客",
                sit: "坐",
                adopted: "已經採納",
                determine: "確定",
                cancel: "取消",
                adopt: "採納",
              },
              pageRightFrom: {
                savingSucceeded: "保存成功",
                pleaseEnterTheScriptContent: "請輸入話術內容",
                pleaseSelectAFavoriteGroup: "請選擇收藏夾分組",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary: "異議處理話術庫",
              },
              pageRightTable: {
                index: { pleaseChooseToAdoptScript: "請選擇採納話術" },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "異議處理話術收藏夾",
            },
            pageRightTable: {
              likeCancel: "啲贊取消",
              likeSuccessfully: "啲贊成功",
              adoptScripts: "採納話術",
              operation: "操作",
              viewConversationDetails: "檢視對話詳情",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "產品賣點話術庫",
              },
              pageRightTable: { keyPointsOfScript: "話術要點" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "異議處理話術收藏夾",
              newGroup: "新建分組",
              editGroup: "編輯分組",
              addGroup: "添加分組",
              savingSucceeded: "保存成功",
              groupName: "分組名稱",
              pleaseEnterTheGroupName: "請輸入分組名稱",
              groupNameWithinCharacters: "分組名稱長度20字符內",
              deletionSucceeded: "刪除成功",
            },
            pageRightTable: {
              configDrawer: {
                index: { scriptRecommendationSettings: "話術推薦設置" },
                rightForm: {
                  savingSucceeded: "保存成功",
                  intelligentRecommendation: "智能推薦",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "將已經採納嘅優秀話術自動顯示喺相似異議嘅會話側邊欄, 員工喺會話復盤詳情頁可直接檢視推薦話術進行學習",
                  objectionsIncludedByDefault: "默認包含嘅異議",
                  addRecommendationTriggerSemantics: "增加推薦觸發語義",
                  remindEmployeesToView: "提醒員工檢視",
                  shareWithMoreEmployees: "分享給更多員工",
                  allHands: "全體員工",
                },
                smartToolbar: { selectSemanticTag: "選擇語義標籤" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "係咪導出當前分組下所有嘅資料記錄?",
                newScript: "新建話術",
                editingScript: "編輯話術",
                savingSucceeded: "保存成功",
                objection: "異議",
                pleaseEnterAScriptObjection: "請輸入話術異議",
                speechTechnique: "話術",
                pleaseEnterTheScriptContent: "請輸入話術內容",
                grouping: "分組",
                pleaseSelectAFavoriteGroup: "請選擇收藏夾分組",
                adoptScripts: "採納話術",
                passenger: "客",
                sit: "坐",
                numberOfViews: "查看次數",
                useful: "有用",
                operation: "操作",
                edit: "編輯",
                delete: "刪除",
                deletionSucceeded: "刪除成功",
                scriptRecommendationSettings: "話術推薦設置",
              },
            },
          },
        },
        index: {
          scriptLibrary: "話術庫",
          scriptMining: "話術挖掘",
          objectionHandlingScripts: "異議處理話術",
          productSellingPointScripts: "產品賣點話術",
        },
        sellingPoint: {
          index: { newCluster: "新建聚類" },
          components: {
            callDetailModal: {
              sessionDetails: "會話詳情",
              cancel: "取消",
              determine: "確定",
              choice: "選擇",
            },
            pageCenterTable: { scriptOverview: "話術概況" },
            pageLeftTable: {
              enterSearchKeywords: "輸入搜尋關鍵詞",
              keyWord: "關鍵詞",
            },
            pageRightTable: {
              index: {
                viewDetails: "檢視詳情",
                scriptLibrary: "話術庫",
                scriptRefinement: "話術提煉",
                adoptScript: "採納話術",
              },
              tagsEdit: { keyPointsOfScript: "話術要點" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "產品賣點話術庫",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: { defaultIncludedSellingPoints: "默認包含嘅賣點" },
                },
                index: {
                  keyWord: "關鍵詞",
                  pleaseSelectAKeyword: "請選擇關鍵詞",
                  keyPointsOfScript: "話術要點",
                  pleaseInputTheKeyPointsOfTheScript: "請輸入話術要點",
                  viewDetails: "檢視詳情",
                  pleaseChooseToAdoptScript: "請選擇採納話術",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "匹配邏輯",
            nLPMatching: "NLP匹配",
            strictlyMatchByWord: "按詞嚴格匹配",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "消息類型名稱",
          messageTypeID: "消息類型ID",
          messageTypeDescription: "消息類型描述",
          applicationMessageType: "應用消息類型",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "適用於接入唔同渠道資料進行質檢,需依據渠道標識進行分類",
        },
        detail: {
          index: {
            messageTypeName: "消息類型名稱",
            pleaseEnterAMessageTypeName: "請輸入消息類型名稱",
            messageTypeDescription: "消息類型描述",
            describeYourMessageTypeInDetail: "可詳細描述你嘅消息類型",
            editMessageType: "編輯消息類型",
            newMessageType: "新建消息類型",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "請選擇團隊成員",
        existingFilter: "已經有過濾器",
        pleaseSelect: "請選擇",
        filterCondition: "隔條件",
        areYouSureToDeleteTheCurrentFilter: "係咪確認刪除當前過濾器?",
        sessionDate: "會話日期",
        staff: "員工",
        customer: "客户",
        pleaseEnterCustomerName: "請輸入客戶名稱",
        keyWord: "關鍵詞",
        whole: "全部",
        pleaseEnterSessionKeywords: "請輸入會話關鍵詞",
        sessionDuration: "會話時長",
        sessionLabel: "會話場景",
        pleaseSelectASessionLabel: "請選擇會話場景",
        commentTopic: "評論話題",
        pleaseSelectACommentTopic: "請選擇評論話題",
        areYouSureYouWantToOverwriteTheCurrentFilter: "係咪確認覆蓋當前過濾器?",
        overlayFilter: "覆蓋過濾器",
        NewFilter: "新建過濾器",
        newFilter: "新建過濾器",
        filterName: "過濾器名稱",
        pleaseEnter: "請輸入",
        allCall: "所有通話",
        percentage: "百分比",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            '支持搜索多個關鍵詞, “或者” 關係嘅用逗號 (中英文)隔開, "且" 關係嘅用頓號隔開, 如:',
          callRecordID: "通話記錄ID",
          pleaseFillInTheCallRecordID: "請填寫通話記錄ID",
          minute: "分鐘",
          second: "秒",
          whole: "全部",
          callRecording: "通話錄音",
          enterpriseWechat: "企業微信",
          conversationChannel: "會話渠道",
          pleaseSelectASessionChannel: "請選擇會話渠道",
          learningStatus: "學習狀態",
          pleaseSelectLearningStatus: "請選擇學習狀態",
          notLearned: "未學習",
          learned: "已經學習",
          Minutes: "分鐘",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "隔條件為空, 唔支持新建過濾器!",
          filterAddedSuccessfully: "過濾器新增成功!",
          failedToAddFilter: "過濾器新增失敗!",
          filterDeletedSuccessfully: "過濾器刪除成功!",
          filterDeletionFailed: "過濾器刪除失敗!",
          filterOverwriteSucceeded: "過濾器覆蓋成功!",
          filterOverwriteFailed: "過濾器覆蓋失敗!",
          theme: "主題",
          pleaseSelectATheme: "請選擇主題",
          keyEvents: "關鍵事件",
          pleaseSelectAKeyEvent: "請選擇關鍵事件",
        },
        filterItem: {
          startRetesting: "開始重新檢測",
          time: "時間",
          talkTime: "通話時長",
          keyEvents: "關鍵事件",
          totalImplementationRateOfSupervisionItems: "監督項總執行率",
          sort: "排序:",
          seeMore: "檢視多啲",
        },
        filterStatistics: {
          conversation: "通話",
          weChat: "微信",
          quantity: "數量",
        },
      },
      caseBase: {
        index: {
          copyTo: "複製到",
          pleaseSelectTheLocationToMove: "請選擇移動嘅位置!",
          folderCopiedSuccessfully: "文件夾複製成功!",
          cancelCollectionSuccessfully: "取消收藏成功!",
          collectionSucceeded: "收藏成功!",
          starCaseBase: "星標案例庫",
          move: "移動",
          rename: "重命名",
          delete: "刪除",
          folderMovedSuccessfully: "文件夾移動成功!",
          fileMovedSuccessfully: "文件移動成功!",
          fileMoveFailed: "文件移動失敗!",
          folderAddedSuccessfully: "添加文件夾成功!",
          folderRenameSucceeded: "文件夾重命名成功!",
          fileRenameSucceeded: "文件重命名成功!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "刪除文件夾後將無法搵返,你確認刪除呀?",
          deleteSucceeded: "刪除成功!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "刪除文件後將無法搵返,你確認刪除呀?",
          share: "共有",
          folders: "個文件夾,",
          calls: "個通話",
          newFolder: "新增文件夾",
          modifyFolder: "修改文件夾",
          title: "標題",
          pleaseEnter: "請輸入",
          selectMoveLocation: "選擇移動位置",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "學習提醒",
          subscriptionDynamics: "訂閱動態",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "你訂閱嘅會話(ID",
            thereAreDynamicUpdates: "有動態更新啦",
            newCommentsReplies: "新增評論/回覆",
            strip: "條",
            newHelp: "新增求助",
            goAndWatch: "去圍觀",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "回覆咗我嘅評論",
            replySucceeded: "回覆成功!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "喺評論中@咗我",
            replySucceeded: "回覆成功!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "為你通話分享",
            shareCasesForYou: "為你分享案例",
          },
        },
        study: {
          index: {
            learned: "已經學習",
            ignored: "已經忽略",
            study: "學習",
            ignore: "忽略",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "導入成功",
          account: "賬號",
          password: "密碼",
          cover: "覆蓋",
          skip: "跳過",
          import: "導入",
          importEmployees: "導入員工",
          importPrompt: "導入提示",
          confirm: "確定",
          theDataContainsDuplicateAccountsOrNames: "條資料存喺賬號或姓名重複",
          allCoverage: "全部覆蓋",
          skipAll: "全部跳過",
          headPortrait: "頭像",
          fullName: "姓名",
          nickname: "暱稱",
          excellentStaff: "優秀員工",
          yes: "係",
          no: "否",
          department: "部門",
          accountNumberemail: "賬號(郵箱)",
          telephone: "電話",
          jobNumber: "工號",
          role: "角色",
          pleaseEnterANameOrPhoneSearch: "請輸入郵箱、名或者電話搜索",
          person: "人)",
          AddEmployee: "添加員工",
        },
        edit: {
          jobNo: "工號",
          udeskCustomerServiceSystemIdentity: "Udesk客服系統身份",
          enterpriseWechatIdentity: "企業微信身份",
          inputID: "輸入標識",
          newIdentityBinding: "新增身份綁定",
          identityBinding: "身份綁定",
          avatarGreaterThan500kb: "頭像大於500Kb",
          uploadSuccessful: "上傳成功",
          upload: "上傳",
          supportJPGGIFAndPNGUpTo500kb: "支持jpg、gif、png,最大500Kb",
          yes: "係",
          no: "否",
          accountNumber: "賬號",
          pleaseEnterTheAccountNumber: "請輸入賬號",
          initialPassword: "初始密碼",
          pleaseInputAPassword: "請輸入密碼",
          confirmPassword: "確認密碼",
          thePasswordsEnteredTwiceAreDifferent: "兩次輸入嘅密碼唔相同!",
          headPortrait: "頭像",
          fullName: "姓名",
          pleaseEnterYourName: "請輸入姓名",
          nickname: "暱稱",
          department: "部門",
          excellentStaff: "優秀員工",
          sessionIdentity: "會話身份標識",
          markTheSessionRecordAsBelongingToTheEmployee:
            "標記會話記錄歸屬於該員工",
          telephone: "電話",
          jobNumber: "工號",
          role: "角色",
          pleaseSelectARole: "請選擇角色",
          preservation: "保存",
          cancel: "取消",
          addedSuccessfully: "添加成功!",
          modificationSucceeded: "修改成功!",
          newEmployees: "新增員工",
          editEmployee: "編輯員工",
        },
        detail: {
          viewDetails: "檢視詳情",
          accountNumber: "賬號",
          headPortrait: "頭像",
          fullName: "姓名",
          nickname: "暱稱",
          department: "部門",
          excellentStaff: "優秀員工",
          yes: "係",
          no: "否",
          sessionIdentity: "會話身份標識",
          markTheSessionRecordAsBelongingToTheEmployee:
            "標記會話記錄歸屬於該員工",
          telephone: "電話",
          jobNumber: "工號",
          role: "角色",
        },
      },
      roleManage: {
        list: {
          menu: "菜單",
          functionalCategory: "功能大類",
          functionalSubclass: "功能小類",
          deletedSuccessfully: "刪除成功",
          NewRole: "新建角色",
          role: "角色",
          dataPermissions: "資料權限:",
          edit: "編輯",
          areYouSureYouWantToDeleteTheRole: "肯定刪除角色",
          determine: "確定",
          cancel: "取消",
          delete: "刪除",
          applicationPermission: "應用權限",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData: "只可以睇到自己嘅會話資料",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "可以檢視本部門同下屬部門嘅會話資料",
          menu: "菜單",
          functionalCategory: "功能大類",
          functionalSubclass: "功能小類",
          successfullyAdded: "新增成功",
          modifiedSuccessfully: "修改成功",
          edit: "編輯",
          cancel: "取消",
          confirm: "確認",
          roleName: "角色名稱",
          pleaseEnterTheRoleName: "請輸入角色名稱",
          dataAuthority: "資料權限",
          youCanViewCompanyWideSessionData: "可以檢視全公司嘅會話資料",
          applicationPermission: "應用權限",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "會話流程",
          generateProcess: "生成流程",
          addProcess: "添加流程",
          cancel: "取消",
          complete: "完成",
          edit: "編輯",
          theSettingWillBeSuccessfulLater: "設置等陣看成功!",
          previous: "上一條",
          next: "下一條",
          debuggingMode: "調試模式",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "開通後消息如果匹配關鍵事件、監督項、客戶標籤嘅規則或者知識點規則, 則會顯示消息匹配到嘅規則名稱, 同知識庫嘅知識點名稱。",
          seeLater: "等陣睇",
          determine: "確定",
          conversationalInsights: "會話洞察",
          customerInsight: "客户洞察",
          knowledgePointRecommendation: "知識點推薦",
          comment: "評論",
          sessionQuality: "會話質量",
          setToListenLaterSuccessfully: "設置等陣聽成功!",
          listenLater: "等陣聽",
          seekCounselingSuccess: "尋求輔導成功!",
          altogether: "一共有",
          appearsAt: "處出現 '",
          commentsPublishedSuccessfully: "評論發表成功",
          callRecording: "通話錄音",
          addedSuccessfully: "添加成功",
          shareWithColleagues: "分享畀同事",
          shareWithCustomers: "分享畀客户",
          resumptionDetails: "復盤詳情",
          addToCaseBase: "添加至案例庫",
          shareCases: "分享案例",
          seekCounseling: "尋求輔導",
          sessionRecord: "會話記錄",
          businessRecord: "業務記錄",
          keywordSearch: "關鍵詞搜索",
          whole: "全部",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "請輸入評論,輸入@以通知其他人,輸入#以添加話題",
          subscriptionSession: "訂閲會話",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "訂閲後,當有新嘅求助、評論、回覆會收到通知提醒",
        },
      },
      coachTask: {
        index: {
          employeeName: "員工姓名",
          customerName: "客戶姓名",
          source: "來源",
          viewSessionRecords: "檢視會話記錄",
          coachingStatus: "輔導狀態",
          helpTime: "求助時間",
          counselingTime: "輔導時間",
          helpContent: "求助內容",
          coachingOperation: "輔導操作",
          areYouSureYouWantToIgnoreIt: "確定忽略呀?",
          determine: "確定",
          cancel: "取消",
          ignoreHelp: "忽略求助",
          coaching: "進行輔導",
          viewCoaching: "檢視輔導",
          areYouSureToReturnToCounseling: "確定恢復至待輔導呀?",
          returnToPendingCoaching: "恢復至待輔導",
          tutor: "輔導人",
          helpSeekingStatus: "求助狀態",
          recentCoachingTime: "最近輔導時間",
          operation: "操作",
          ignoreSuccess: "忽略成功!",
          recoverySucceeded: "恢復成功!",
          successfulCoaching: "輔導成功!",
          myMission: "我嘅任務",
          selectCoachingStatus: "選擇輔導狀態",
          whole: "全部",
          iAskedForHelp: "我求助嘅",
          signOut: "退出",
          submit: "提交",
          helpPerson: "求助人",
          customer: "客户",
          toBeCoached: "待輔導",
          counselingSuggestions: "輔導建議",
          pleaseEnterCoachingSuggestions: "請輸入輔導建議",
        },
      },
      coachTaskCenter: {
        title: "任務中心",
        list: {
          taskName: "任務名稱",
          lessonGroup: "課程分類",
          lessonName: "所屬課程",
          taskType: "任務類型",
          taskStatus: "任務狀態",
          taskExecutionInfo: "執行情況",
          totalCount: "應執行人數",
          executeCount: "已經執行人數",
          executeRate: "執行率",
          completeCount: "完成人數",
          completeRate: "完成率",
          commitCount: "達標人數",
          commitRate: "達標率",
          excellentRate: "達優率",
          taskTime: "任務時間",
          option: "操作",
          edit: "編輯",
          taskDetail: "任務資料",
          delete: "刪除",
          new: "新建發佈",
          taskTypeAll: "全部",
          taskTypePractice: "練習",
          taskTypeTest: "考試",
          taskStatusNotStarted: "未開始",
          taskStatusRunning: "進行中",
          taskStatusEnd: "已經結束",
          taskStatusInvalid: "未生效",
          unlimitedTime: "唔限時間",
          to: "至",
          deleteConfirm: "刪除後此任務下所有學習記錄將畀清空,係咪確認刪除?",
          deleteSuccess: "任務刪除成功",
        },
        edit: {
          addTask: "新建發佈任務",
          editTask: "編輯發佈任務",
          publishSuccess: "發佈成功",
          publishFailed: "發佈失敗",
          updateSuccess: "更新成功",
          updateFailed: "更新失敗",
          cannotBeNull: "唔能為空",
          explain: "説明",
          taskDescExtra: "(此處內容將喺{0}入口處展示)",
          taskTargetObj: "發佈對象",
          isEnable: "生效狀態",
          dialogueMode: "對話模式",
          voice: "語音",
          text: "文本",
          userPortrait: "用户畫像",
          difficultyLevel: "難度",
          limitDuration: "時長限制",
          tipLabel: "話術提示",
          tipTooltip: "係咪允許學員檢視參考話術",
          limitTimes: "考試次數",
          time: "時間",
          gradingStandart: "評分標準",
          gradingStandartDescAffix: "(當前呢個對話呢流程總分為",
          gradingStandartDescSuffix: "分,話術{0}分,情緒{1}分,語速{2}分)",
          completeStandart: "任務完成要求",
          byTimes: "按次數",
          byScore: "按得分",
          completeByTimes: "練習次數",
          times: "次",
          score: "分",
          anyTimes: "任意一次",
          getScore: "得分",
          offLimitDuration: "唔限時長",
          onLimitDuration: "限定時長",
          commitScore: "達標標準,得分",
          excellentScore: "優秀標準,得分",
          offLimitTime: "唔限時間",
          onLimitTime: "限定時間",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "閪," },
          callDynamic: { today: "今日", yesterday: "昨日", sessions: "個會話" },
          todoList: {
            strip: "條",
            theSessionIsAddedTolistenLater: '會話畀加入"稍後聽”',
            askYouForHelp: "向你求助",
            iHopeToContactYouLater: "期望稍後聯繫",
            lookingForwardToSendingTheInformationLater: "期望等陣發送資料",
            dialogueRisk: "會話存在風險啲",
            tutored: "已經輔導",
            goToCounseling: "去輔導",
            contacted: "已經聯繫",
            hasBeenSent: "已發送",
            areYouSureYouWantToIgnoreIt: "確定忽略呀?",
            determine: "確定",
            cancel: "取消",
            ignore: "忽略",
            checked: "已經檢視",
          },
          callListItem: {
            strip: "條",
            learned: "已經學習",
            keyEvents: "關鍵事件",
            totalImplementationRateOfSupervisionItems: "監督項總執行率",
          },
        },
        index: {
          operationSucceeded: "操作成功!",
          sessionDynamics: "會話動態",
          my: "我嘅",
          department: "部門",
          company: "公司",
          toDoList: "待辦事項",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "有效通話量(個)",
          bestPractices: "最佳實踐",
          averageDurationOfASingleCallminutes: "單次平均通話時長(分鐘)",
          totalCallVolumePCs: "總通話量(個)",
          totalTalkTimehours: "通話總時長(小時)",
          speakingPercentagepercentage: "講嘢佔比(百分比)",
          maximumContinuousSpeakingTimeminutes: "最長持續説話時長(分鐘)",
          numberOfIndepthDiscussionsPCs: "深入討論次數(個)",
          maximumCustomerTalkminutes: "最長客户發言(分鐘)",
          questionFrequencyPCs: "提問頻次(個)",
          averageValue: "平均值",
          selectDate: "選擇日期",
          personnel: "人員",
          pleaseSelect: "請選擇",
          callRange: "通話範圍",
          conversationActivity: "會話活動",
          conversationAction: "會話動作",
          keyEvents: "關鍵事件",
          callRecord: "通話記錄",
          filter: "篩選:",
          conversation: "通話",
          minute: "分鐘",
          sale: "員工",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "1月",
            numberOfCalls: "通話數",
            accountInformation: "賬户信息",
            month: "月",
            today: "今日",
            customerDetails: "客戶詳情",
            searchKeywordsInCommunicationContent: "溝通內容中檢索關鍵詞",
            allMembers: "所有成員",
            reset: "重置",
            query: "查詢",
            allTrip: "全旅程展示",
            contactOverview: "聯絡概覽",
            lastContact: "上次聯絡",
            nextContact: "下次聯絡",
            previous: "上一條",
            next: "下一條",
            viewDetails: "檢視詳情",
            contactDynamics: "聯絡動態",
            lastYear: "最近一年",
            lastCall: "最近一次通話",
            hotTopicsForDiscussion: "討論話題熱點",
            totalInTheLastYear: "最近一年一共",
            callTimes: "次通話",
          },
        },
        list: {
          index: {
            risk: "風險",
            have: "有",
            nothing: "無",
            channel: "渠道",
            callRecording: "通話錄音",
            enterpriseWeChatConversation: "企微會話",
            enterpriseWeChatCall: "企微通話",
            followUpSuggestions: "跟進建議",
            customer: "客户中心",
            followUp: "跟進情況",
            personInCharge: "負責人",
            recentFollowup: "最近跟進人",
            lastContactTime: "最近一次聯繫時間",
            operation: "操作",
            see: "檢視",
            exceed: "超過",
            noActivityForMoreThanDays: "日冇活動",
            lately: "最近",
            risksOccurredInTheLastDays: "日內出現風險語義",
            savedSuccessfully: "保存成功",
            today: "今日",
            allDepartments: "所有部門",
            allMembers: "所有成員",
            customerName: "客戶名稱",
            reset: "重置",
            query: "查詢",
            customers: "個客户",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "展示員工同客户嘅聯繫情況,其中粉色泡泡為客户消息,藍色為員工消息,發言揾過越大,氣泡越大",
            riskAllocation: "風險配置",
            preservation: "保存",
            pleaseEnter: "請輸入",
            noActivityForDays: "日冇活動",
            riskSemanticsWithinDays: "日內出現風險語義",
            addRiskSemantics: "添加風險語義",
            semanticTags: "语义標籤:",
            standardSentence: "標準句:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "添加新條件" } },
              },
              index: { meetAllOfTheFollowingConditions: "滿足以下全部條件" },
            },
            advancedFilterButton: {
              index: {
                screen: "篩選",
                pleaseSelectFilteringCriteria: "請選擇篩選條件",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "變更商機階段",
              theCurrentStageIs: "當前階段為【",
              ConfirmToChangeItTo: "】,確定將其變更為【",
              IsIt: "】呀?",
              StageCoStay: "】 階段共停留",
              day: "日",
              start: "開始",
              end: "結束",
              orderInput: "輸單",
              restart: "重新啟動",
              pleaseConfigureTheSalesStageInformation: "請設定銷售階段信息",
              reasonForDocumentInput: "輸單原因:",
              completeOrderInput: "完成輸單",
              pleaseSelectTheReason: "請選擇原因",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "管理者建議:" },
              index: {
                sessionDetails: "會話詳情",
                customerDynamics: "客户動態",
                riskPoint: "風險啲",
                followUpSuggestions: "跟進建議",
              },
              sessionDetails: {
                common: "共",
                currentlyViewingThe: "當前檢視第",
                sessions: "個會話",
              },
            },
          },
          businessTitle: {
            index: { theCurrentStageHasStayed: "當前階段已經停留", day: "日" },
          },
          business: { index: { reEnableOpportunity: "重新啟用商機" } },
          customerTag: { index: { label: "標籤" } },
          moreCustomer: {
            index: {
              essentialInformation: "基本信息",
              cancel: "取消",
              confirm: "確認",
              customer: "客户",
              currentFollowupStage: "當前跟進階段",
              telephone: "電話",
              followUpDays: "跟進天數",
              day: "日",
              remarks: "備註",
              channelPreference: "渠道偏好",
              hello: "你好",
              customFields: "自定義字段",
              opportunityAmount: "商機金額",
              focus: "關注點",
              customerInformation: "客户信息",
              viewMoreCustomerInformation: "檢視多啲客户信息",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "關鍵事件:" },
                row1: {
                  customerDynamics: "客户動態",
                  common: "共",
                  barDynamic: "條動態",
                },
                row2: { riskPoint: "風險啲", common: "共", risks: "條風險" },
                row3: {
                  followUpSuggestions: "跟進建議",
                  intelligentRecommendation: "智能推薦",
                  strip: "條",
                  managersSuggestATotalOf: "管理者建議 共",
                  recommendedByManagers: "管理者推薦",
                  creationTime: "創建時間",
                  administratorSuggestions: "管理員建議",
                  suggestionsForNewManagers: "新增管理者建議",
                },
              },
            },
            index: { typeTime: "類型/時間" },
          },
          userInfo: {
            index: {
              personInCharge: "負責人",
              recentFollower: "最近跟進人",
              startTime: "首次觸達時間",
              followUpDays: "跟進天數",
              day: "日",
              remarks: "備註",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "團隊成員",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "成員學習佢人會話嘅總數(去重),查閲會話詳情且點擊過錄音播放視為學習一次",
          totalNumberOfStudies: "學習總數",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "成員對佢人會話發起評論、回覆嘅總數(去重)",
          totalNumberOfInteractions: "互動總數",
          learnTheSumOfConversationCallDuration: "學習嘅會話通話時長求同",
          lengthOfStudy: "學習時長",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "成員對佢人嘅會話發起求助嘅總數(去重)",
          totalNumberOfRequests: "求助總數",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "成員對佢人嘅求助進行輔導嘅總數(去重)",
          totalCounseling: "輔導總數",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "成員對佢人嘅會話添加到案例庫嘅總數(去重)",
          addCase: "添加案例",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "成員對佢人嘅會話進行訂閱嘅總數(去重)",
          subscribe: "訂閱",
          totalNumberOfSessionsCommunicatedByMembers: "成員溝通嘅會話總數",
          totalSessions: "會話總數",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "成員復盤自己會話嘅總數(去重),查閲會話詳情且點擊過錄音播放視為復盤一次",
          totalNumberOfDoubleDisk: "復盤總數",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "復盤總數/會話總數*100%",
          proportionOfDoubleDisk: "復盤佔比",
          summationOfConversationCallDurationOfDuplicateDisk:
            "覆盤嘅會話通話時長求同",
          resumptionDuration: "覆盤時長",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "成員對自己嘅會話發起求助嘅總數(去重)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "成員嘅會話收到佢人評論(包括回復)嘅總數",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "成員嘅會話畀佢人添加到案例庫嘅總數(去重)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "成員嘅會話畀佢人進行訂閱嘅總數(去重)",
          askForHelpInTheLastMonth: "最近一個月求助",
          lastTimeForHelp: "最近一次求助時間",
          operation: "操作",
          viewAllSessionsInTheLastMonth: "檢視最近一個月嘅所有會話",
          allDepartments: "所有部門",
          reset: "重置",
          query: "查詢",
          altogether: "一共",
          secondSession: "次會話",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "當前測試項目:",
            supervisionItem: "監督項",
            keyEvents: "關鍵事件",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "請勾選要測試嘅監督項或關鍵事件,並確保模擬對話中有通話內容",
        },
      },
      executionAnalysis: { index: { executiveForceAnalysis: "執行力分析" } },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "流程1",
              technologicalProcess: "流程",
              newProcess: "新建流程",
            },
          },
          index: {
            upload: "上傳",
            preservation: "保存",
            newlyBuild: "新建",
            cancel: "取消",
            name: "名稱",
            describe: "描述",
            type: "類型",
            classification: "分類",
            content: "內容",
            enclosure: "附件",
            knowledgePointAssociation: "知識點關聯",
            open: "開啟",
            close: "關閉",
            operator: "算子",
            logic: "邏輯",
            see: "檢視",
            edit: "編輯",
          },
        },
        index: {
          name: "名稱",
          describe: "描述",
          createdBy: "創建人",
          type: "類型",
          lastUpdated: "最近更新時間",
          numberOfReferences: "引用次數",
          operation: "操作",
          see: "檢視",
          edit: "編輯",
          delete: "刪除",
          categoryDeletedSuccessfully: "分類刪除成功",
          classificationOfKnowledgePoints: "知識點分類",
          newlyBuild: "新建",
          knowledgeBase: "知識庫",
          knowledgePoints: "知識點",
          technologicalProcess: "流程",
          keyword: "關鍵字",
          batchExport: "批量導出",
          batchDelete: "批量刪除",
          importProcess: "導入流程",
          importKnowledgePoints: "導入知識點",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "若知識點喺知識庫中唔存喺則直接新增知識點,若存喺則跳過,唔做更新",
          editKnowledgePointClassification: "編輯知識點分類",
          newKnowledgePointClassification: "新建知識點分類",
          categoryEditingSucceeded: "分類編輯成功",
          newClassificationSucceeded: "新建分類成功",
          classificationName: "分類名稱",
        },
      },
      monitoringReport: { index: { executiveSupervision: "執行力監督" } },
      topicCallout: {
        index: {
          whole: "全部",
          date: "日期",
          dimensionStatus: "標註狀態",
          callRecordID: "通話記錄ID",
          callRecordIDMustBeNumeric: "通話記錄ID必須為數字",
          strip: "條",
          topicAnnotation: "話題標註",
          annotated: "已經標註",
          stripNotMarked: "條,未標註",
          barInDimension: "條,標註中",
          customer: "客户",
          sale: "銷售",
          common: "共",
          sessions: "條會話",
          currentTopic: "當前話題:",
          staff: "員工",
          topicOfConversation: "話題",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "操作成功, 標註已經重置",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "重置後將清空本通會話嘅所有標註記錄,請確認",
          resetAll: "全部重置",
          pleaseSelectTheTopicToBeMarked: "請選擇要標註到嘅話題",
          pleaseTickTheSentenceToBeMarked: "請勾選要標註嘅句子",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "操作成功, 標註已經生效",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "本次標註嘅範圍包含其他話題嘅消息,點擊確定將進行覆蓋",
          confirmDimensions: "確認標註",
          talkTime: "通話時間",
          callID: "通話id",
        },
      },
      topicList: {
        index: {
          callID: "通話ID",
          latestMarkedTime: "最新標註時間",
          topicOfConversation: "話題",
          numberOfSentences: "句子數量",
          sentence: "句子",
          operation: "操作",
          see: "檢視",
          delete: "刪除",
          edit: "編輯",
          newSubTopic: "新建子話題",
          move: "移動",
          whole: "全部",
          topicDeletedSuccessfully: "刪除話題成功",
          newlyBuild: "新建",
          topicList: "話題列表",
          callIDMustBeNumeric: "通話ID必須為數字",
          leadInTopic: "導入話題",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "分類名稱重複嘅時候, 唔會覆蓋原有嘅話題分類, 該項會畀直接跳過, 唔會導入",
          mobileClassification: "移動分類",
          editTopic: "編輯話題",
          newTopic: "新建話題",
          topicName: "話題名稱",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "修改成功",
            createdSuccessfully: "創建成功",
            modificationFailed: "修改失敗",
            creationFailed: "創建失敗",
            monitoringName: "監測名稱",
            upToCharacters: "最多40個字符",
            pleaseEnterAName: "請輸入名稱",
            monitoringIndicators: "監測指標",
            sessionActivity: "會話活動",
            conversationAction: "會話動作",
            keyEvents: "關鍵事件",
            supervisionItems: "監督項",
            monitoringMembers: "監測成員",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "接受指導、培訓或管理干預嘅組別",
            groupA: "A組",
            pleaseSelectGroupAData: "請選擇A組資料",
            thereAreDuplicateUsersInGroupB: "同B組中存喺重複嘅用户",
            groupARemarks: "A組備註",
            controlGroupGroupComparedWithGroupA: "對照組,與A組進行對比嘅組別",
            groupB: "B組",
            pleaseSelectGroupBData: "請選擇B組數據",
            thereAreDuplicateUsersInGroupA: "與A組中存在重複嘅用户",
            groupBRemarks: "B組備註",
            monitoringPeriod: "監測週期",
            startNode: "開始節點",
            monitoringScope: "監測範圍",
            pleaseSelectMonitoringConditions: "請選擇監測條件",
            pleaseEnterANumber: "請輸入數字",
            pleaseSelectATimePeriod: "請選擇時間週期",
            preservation: "保存",
            cancel: "取消",
          },
        },
        list: {
          template: {
            deletionSucceeded: "刪除成功",
            notStarted: "未開始",
            haveInHand: "進行中",
            closed: "已經結束",
            newGroupTest: "新建分組測試",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "銷售階段",
        communicationStrategy1: "溝通策略",
        riskAllocation: "風險配置",
        businessConfiguration: "業務配置",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "異議處理話術",
                  productSellingPointScript: "產品賣點話術",
                  caseGrouping: "案例分組",
                  pleaseSelectExcellentCases: "請選擇優秀案例",
                  pleaseSelectKnowledgePoints: "請選擇知識點",
                  associativeLinks: "關聯連結",
                  jumpTo: "跳轉至",
                  jumpType: "跳轉類型",
                  knowledgePoints: "知識點",
                  excellentCases: "優秀案例",
                  excellentScript: "優秀話術",
                  pleaseSelectASingleKnowledgePoint: "請選擇單個知識點",
                },
              },
            },
            index: {
              name: "名稱",
              pushText: "推送文字",
              enableStatus: "啟用狀態",
              triggerRules: "觸發規則",
              communicationStrategy: "溝通策略",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "溝通策略分組",
              name: "名稱",
              pushText: "推送文字",
              effectiveness: "有效性",
              openSuccessfully: "開啟成功",
              closeSuccessfully: "關閉成功",
              operationFailed: "操作失敗",
              operation: "操作",
              edit: "編輯",
              delete: "刪除",
              newlyBuild: "新建",
              communicationStrategy: "溝通策略",
              editGroup: "編輯分組",
              groupName: "分組名稱",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "所有會話",
                  recentDays: "最近天數",
                  recentSessions: "最近會話",
                  scopeOfApplication: "適用範圍:",
                  day: "日",
                  through: "通",
                },
              },
            },
            index: {
              describe: "描述",
              applicableCustomers: "適用客户",
              enableStatus: "啟用狀態",
              triggerRules: "觸發規則",
              riskRules: "風險規則",
            },
          },
          list: {
            index: {
              riskAllocation: "風險配置",
              keyWord: "關鍵詞",
              newRiskRule: "新建風險規則",
              riskRuleName: "風險規則名稱",
              describe: "描述",
              state: "狀態",
              openSuccessfully: "開啟成功",
              closeSuccessfully: "關閉成功",
              operationFailed: "操作失敗",
              operation: "操作",
              edit: "編輯",
              delete: "刪除",
              deletionSucceeded: "刪除成功",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "輸單" },
              endReasonList: {
                addEndReason: "添加結束原因",
                enterEndReason: "輸入結束原因",
              },
              stageList: {
                keyEvents: "關鍵事件",
                waysideData: "隨路資料",
                includeAny: "包含任意",
                includeAll: "包含全部",
                pleaseEnterTheStageName: "請輸入階段名稱",
                setStageRules: "設置階段規則",
                stageName: "階段名稱",
                operator: "算子",
                logic: "邏輯",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "時間類型字段輸入格式為:2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater: "請求失敗,請等陣再試",
              salesStage: "銷售階段",
              essentialInformation: "基本信息",
              name: "名稱",
              applicableDepartments: "適用部門",
              applicableToOthers: "適用其他",
              manualChangePhase: "手動變更階段",
              enableStatus: "啟用狀態",
              describe: "描述",
              opportunityStageSettings: "商機階段設置",
              stageSettings: "階段設置",
              aliasSettings: "別名設置",
              closeOpportunity: "結束商機",
              newSuccessfully: "新建成功",
              newFailed: "新建失敗",
              savingSucceeded: "保存成功",
              saveFailed: "保存失敗",
            },
          },
          list: {
            index: {
              salesStage: "銷售階段",
              keyWord: "關鍵詞",
              newSalesStage: "新建銷售階段",
              salesStageName: "銷售階段名稱",
              describe: "描述",
              state: "狀態",
              openSuccessfully: "開啟成功",
              closeSuccessfully: "關閉成功",
              operationFailed: "操作失敗",
              operation: "操作",
              edit: "編輯",
              delete: "刪除",
              deletionSucceeded: "刪除成功",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "大模型建置",
            conversationInsights: "會話洞察",
          },
          sessionsInsight: {
            components: {
              autoGenerate: { index: { automaticGeneration: "自動生成" } },
              hooks: {
                conversationSummary: "會話小結",
                conversationProcess: "會話流程",
                opportunityPoints: "機會點",
              },
              prompt: {
                index: {
                  edit: "編輯",
                  restoreDefault: "恢復默認",
                  cancel: "取消",
                  confirm: "確認",
                },
              },
            },
            index: {
              saveSuccessful: "保存成功",
              hintLanguage: "提示語",
              preserve: "保存",
              cancel: "取消",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "大模型建置",
        salesStage: "銷售階段",
        communicationStrategy: "溝通策略",
        riskAllocation: "風險配置",
        businessConfiguration: "業務配置",
      },
      salesLearningCenter: {
        knowledgeBase: "知識庫",
        speechCorpus: "話術庫",
        learningCenter: "學習中心",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "客户信息" } },
        },
        index: { otherInformation: "其他信息" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "導入" } } },
        hooks: {
          index: {
            all: "全部",
            intelligentModel: "智能模型",
            smartRules: "智能規則",
            manualScoring: "手動評分",
            enable: "啟用",
            notEnabled: "未啟用",
            voiceCall: "語音通話",
            textDialogue: "文本對話",
            ecommerceTextSession: "電商文本會話",
            workOrder: "工單",
          },
        },
        index: {
          ruleName: "規則名稱",
          ruleType: "規則類型",
          pleaseSelect: "請選擇",
          ruleStatus: "規則狀態",
          dataSourceType: "資料源類型",
          voiceCall: "語音通話",
          textDialogue: "文本對話",
          ecommerceTextSession: "電商文本會話",
          workOrder: "工單",
          status: "狀態",
          operation: "操作",
          edit: "編輯",
          ruleTemplate: "規則模版",
        },
      },
      analysisReportTitle: "分析報表",
      ruleTest: "規則測試",
      intelligentTags: {
        labelDisplayQuantity: "標籤顯示數量",
        unlimited: "唔限",
        ignoringTagLibraries: "忽略標籤庫",
        automaticallyGenerateLabels: "自動生成標籤",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "開啟後,基於大模型對會話自動生成標籤,生成嘅標籤默認歸類到“未分類”下",
        defaultValidity: "默認有效性",
        supervisionItem: "監督項",
        customerLabel: "客戶標籤",
        newSupervisionItemRule: "新建監督項規則",
        editSupervisionItemRule: "編輯監督項規則",
        newCustomerLabel: "新建客戶標籤",
        editCustomerLabel: "編輯客戶標籤",
        newSessionLabel: "新建會話標籤",
        editSessionLabel: "編輯會話標籤",
        newKeyEvent: "新建關鍵事件",
        editKeyEvents: "編輯關鍵事件",
        detail: {
          template: { nameIsARequiredField: "名稱係必填字段" },
          formItemComponent: {
            largeModelExtensionLabel: "大模型擴充標籤",
            sessionRecordID: "會話記錄id",
            sessionTime: "會話時間",
            smartTags: "智能標籤",
            viewDetails: "檢視詳情",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "刪除後此會話記錄的數據將會被清空,系咪確認刪除?",
            tagData: "識認資料",
            batchDeletion: "批量刪除",
          },
        },
        template: {
          advancedFiltering: "高級篩選",
          dataType: "資料類型",
          filterCriteria: "篩選條件",
          edit: "編輯",
          createANewSubcategory: "新建子分類",
          delete: "刪除",
          nameLengthCannotExceed: "名稱長度唔能超過40",
        },
        component: {
          modelExpansion: "模型擴充",
          enable: "啟用",
          notEnabled: "未啟用",
          standardScreening: "標準篩選",
          advancedFiltering: "高級篩選",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "關鍵詞",
              sessionLabel: "會話標籤",
              keyEvents: "關鍵事件",
              matchAny: "匹配任意",
              matchAll: "匹配全部",
              beEqualTo: "等於",
              includeAll: "包含全部",
              includeAny: "包含任意",
              advancedConditions: "高級條件",
              smartTag: "智能標籤",
              smartTagI: "智能標籤一",
              smartTagII: "智能標籤二",
            },
            template: {
              pleaseSelectSmartTag: "請選擇智慧標籤",
              pleaseSelectASessionLabel: "請選擇會話標籤",
              pleaseSelectAKeyEvent: "請選擇關鍵事件",
              pleaseSelectAdvancedCondition: "請選擇高級條件",
              newAdvancedFilter: "新建高級篩選",
              moveUp: "上移",
              moveDown: "下移",
              moveLeft: "左移",
              shiftRight: "右移",
            },
          },
          component: {
            businessAnalysispng: "業務分析.png",
            savedSuccessfully: "保存成功",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "永久有效",
            permanentlyInvalid: "永久無效",
            customValidity: "自定義有效期",
            termOfValidity: "有效期",
            name: "名稱",
            explain: "説明",
            state: "狀態",
            classification: "分類",
            smartRecommendation: "智能推薦",
            preconditions: "前置條件",
            SetPreconditionsForThisSupervisionItem:
              "1.為該條監督項設置前置條件:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              "符合前置條件時,即為“應執行監督項”,則對該條監督項進行檢測;",
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "唔符合前置條件時,則不檢測該條監督項;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2.唔設置前置條件,即預設對所有會話進行檢測",
            have: "有",
            nothing: "無",
            preconditionRule: "前置條件規則",
            preconditionLogic: "前置條件邏輯",
          },
          recommendation: {
            caseGrouping: "案例分組",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "當員工執行唔過水時,可智慧推薦優秀話術同優秀案例",
            smartRecommendation: "智能推薦",
            open: "開啟",
            close: "關閉",
            whenThisSupervisionItem: "當此監督項",
            implement: "執行",
            unexecuted: "未執行",
            when: "時:",
            recommendedKnowledgePoints: "推薦知識點",
            caseRecommendation: "案例推薦",
          },
        },
        index: {
          determine: "確定",
          sort: "排序",
          sortSucceeded: "排序成功",
          supervisionItemClassification: "監督項分類",
          pressEnterToCreateANew: "按回車新建",
          nameCannotBeEmpty: "名稱唔能為空",
          newlyBuild: "新建",
          name: "名稱",
          explain: "説明",
          validStatus: "有效狀態",
          permanentlyValid: "永久有效",
          permanentlyInvalid: "永久無效",
          operation: "操作",
          edit: "編輯",
          batchDelete: "批量刪除",
          newSupervisionItem: "新建監督項",
          keyWord: "關鍵詞",
          delete: "刪除",
          deleteSucceeded: "刪除成功",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "資料刪除後將唔回覆, 係否確認刪除?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "智能標籤",
              beEqualTo: "等於",
              includeAll: "包含全部",
              includeAny: "包含任意",
              matchAny: "匹配任意",
              matchAll: "匹配全部",
              smartTagI: "智能標籤一",
              smartTagII: "智能標籤二",
            },
            template: {
              pleaseSelectASmartTag: "請選擇智慧標籤",
              pleaseSelectAdvancedConditions: "請選擇高級條件",
              newAdvancedFilter: "新建高級篩選",
              rename: "重命名",
              moveUp: "上移",
              moveDown: "下移",
              shiftLeft: "左移",
              shiftRight: "右移",
            },
          },
        },
        template: {
          delete: "刪除",
          labelFiltering: "標籤篩選:",
          satisfyAny: "滿足任意",
          meetAll: "滿足全部",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "二級分類標籤對話數≥一級分類,一個會話可能包含多個標籤",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "環比變化計算邏輯:(所選時間段對話量-緊抵住嘅上個時間段嘅對話量)/上個時間段嘅對話量",
          proportionOfPrimaryClassification: "一級分類揾過情況",
          saveAs: "另存為",
          cover: "覆蓋",
          preservation: "保存",
          qualityInspectionTask: "分析任務:",
          query: "查詢",
          changeFromMonthToMonth: "環比變化情況",
          tagRanking: "標籤排行",
          proportion: "揾過情況",
        },
        component: {
          numberOfConversations: "對話數",
          operation: "操作",
          businessAnalysispng: "業務分析.png",
          savingSucceeded: "保存成功",
          deletionSucceeded: "刪除成功",
          sequence: "序列",
          firstLevelClassification: "一級分類",
          ringRatioChange: "環比變化",
          classClassification: "級分類",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "確認刪除檔案及其抽取內容?",
            addKnowledgeBase: "添加知識庫",
            uploadDocument: "上載檔案",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "支持word、pdf、ppt、excel、txt格式文件",
            taskName: "任務名稱",
            extractConversationDate: "抽取對話日期",
            pleaseSelectAConversationDate: "請選擇對話日期",
            dataSource: "資料源",
            dataType: "資料類型",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "導出成功",
              problem: "問題",
              answer: "答案",
              addToKnowledgeBase: "添加到知識庫",
              pleaseConfirmWhetherToDeleteIt: "請確定係否刪除",
            },
            pageRightFrom: {
              addToKnowledgeBase: "添加到知識庫",
              selectClassification: "選擇分類",
              pleaseSelectAKnowledgeBaseClassification: "請選擇知識庫分類",
            },
          },
        },
        index: {
          intelligentExtraction: "智能抽取",
          documentExtraction: "文檔抽取",
          dialogueExtraction: "對話抽取",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "操作成功",
          labelIgnored: "已經忽略標籤",
          add: "添加",
          areYouSureToDeleteIt: "肯定刪除呀?",
          batchFilterLabels: "批量過濾標籤",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "智能質檢",
        savedSuccessfully: "保存成功",
        logoSettings: "Logo設置",
        default: "默認",
        custom: "自定義",
        title: "標題",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "系統展示嘅標題名稱,建議6字以內。",
        logoImageUpload: "Logo圖片上傳",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "請選擇大小唔超過2048kb嘅圖片",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "為保證logo瀏覽效果,上傳圖片建議:",
        Size5050Pixels: "1.尺寸:50*50點數;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2.格式:透明背景嘅圖片,png或jpg,logo圖形使用白色",
        browserLabelIcon: "瀏覽器標籤圖標",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "為保證標籤瀏覽效果,上傳圖片建議:",
        Size3232Pixels: "1.尺寸:32*32點數;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2.格式:透明背景嘅圖片,png或jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater: "質檢進行中,請稍後...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "2015年噉會,我國房地產到咗生存嘅關鍵時刻,大量嘅單位無人問津,老百姓唔願意購買。杭州一度跌破 8000,有開發商砸盤出貨, 深圳當時仲喺 2w-3w 左右盤整,一線城市嘅房子亦都岌岌可危",
        xiaoAn: "小安",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload呢啲內存屏障叫jsr內存屏障,係jvm層面嘅,喺操作系統底層實現方式有二:1係鎖總線,二係內存屏障元語,如sfance(寫屏障)mfence(全屏障)ifence(讀屏障)。 Hotspots 虛擬機底層採用嘅係一條匯編語句:lock addl,lock 一個空操作,即為鎖總線, 咁做係因為部分操作系統唔支持內存屏障元語。",
        xiaobei: "小北",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. 試住醃過幾次肉,加料酒後炒出嚟嘅菜,肉同宿咗似嘅,一股酸臭味! 從此醃肉再唔放料酒",
        xiaodong: "小東",
        xiaoxi: "小西",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. 試住醃過幾次肉,加料酒後炒出嚟嘅菜,肉同宿咗似嘅,一股酸臭味! 從此醃肉再唔放料酒",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "電商評論管理",
        socialMediaReviewManagement: "社媒評論管理",
        customDataSource: "自定義資料原",
        qualityInspectionResults: "質檢結果",
        accountManagement: "賬户管理",
        employeeProfile: "員工畫像",
        intelligentAnalysis: "智能分析",
        customKanban: "自定義看板",
        dataScreening: "資料總覽",
        learningRecord: "學習記錄",
        dataAnalysis: "資料分析",
        scoringManagement: "評分管理",
        businessConfiguration: "業務配置",
        manualReview: "人工複核",
        taskList: "任務列表",
        videoAnalysis: "視頻分析",
        overview: "總覽",
        personalSignage: "個人看板",
        learningCenter: "學習中心",
        wrongQuestionSet: "錯題集",
        abilityImprovement: "能力提升",
        close: "關閉",
        switchingSystemApplications: "切換系統應用",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "鼠標移動至此處可喚出應用切換菜單,點擊菜單項跳轉唔同應用",
        teamAnalysis: "團隊分析",
        semanticIntelligence: "語義智能",
        permissionManagement: "權限管理",
        dataConnection: "資料對接",
        courseManagement: "課程管理",
        labelManagement: "標籤管理",
        curriculum: "課程中心",
        courseConfiguration: "課程配置",
        task: "我嘅任務",
        historicalLearningData: "歷史記錄",
        taskCenter: "任務管理",
        knowledgeBase: "知識庫",
        ecommerceSessionManagement: "電商會話管理",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "應用切換",
          descriptionOfIntelligentQualityInspectionSystem: "智能質檢系統描述",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "電商會話篩選器",
        allEcommerceSessions: "全部電商會話",
        ecommerceSessionManagement: "電商會話管理",
      },
    },
    analysisEnable: {
      bestPractices: "最佳實踐",
      behaviorAnalysis: "行為分析",
      teamAnalysis: "團隊分析",
      executiveSupervision: "執行力監督",
      executiveForceAnalysis: "執行力分析",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "評級:",
          startTime: "開始時間",
          workOrderNo: "工單編號",
          customerServiceHasReadNotRead: "客服已經讀/未讀",
          canYouAppeal: "係咪可申訴",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "企微備註",
          addFriendTime: "添加好友時間",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "開始執行重新質檢",
          callID: "通話ID",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "新功能! 點擊 '快速抽檢' 撳掣,提交成功後會自動跳轉到下一頁啦~~~",
        },
      },
      template: {
        deleteRecord: "刪除記錄",
        areYouSureToDeleteIt: "肯定刪除呀?",
        confirm: "確定",
        reIdentification: "重新識別",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "重新識別會消耗轉寫時長,請謹慎操作",
        determine: "確定",
        executionSuccessful: "執行成功",
      },
      component: {
        pleaseSelectData: "請選擇資料",
        operationSuccessful: "操作成功",
        startPerformingReQualityInspection: "開始執行重新質檢",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "上傳文件名稱",
        analysisTask: "分析任務",
        analyzeProgress: "分析進度",
        analysisSucceeded: "分析成功",
        analysisFailed: "分析失敗",
        localDataUpload: "本地資料上傳",
        recordingUpload: "錄音上傳",
        textUpload: "文本上傳",
        uploadTime: "上傳時間",
        taskName: "任務名稱",
        operator: "操作人",
        qualityInspectionProgress: "質檢進度",
        whole: "全部",
        haveInHand: "進行中",
        completed: "已經完成",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "係咪導出當前篩選條件下所有已經完成嘅資料記錄?",
        upload: "上傳",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "填寫名稱",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "填寫名稱,如本次導入資料嘅類型/集合/目嘅",
            default: "默認",
            jDCOM: "京東",
            dataType: "資料類型",
            selectAnalysisTask: "選擇分析任務",
            uploadFiles: "上傳文件",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1.下載excel模板,按格式填寫:",
            template: "模板",
            SupportUpTo10000PiecesOfData: "2.最大支持10000條數據",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "支持京東導出嘅對話數據上傳,.txt文件,文件大小最大支持100M",
            alongTheWayDataTemplate: "隨路資料模板",
          },
        },
        result: {
          template: {
            textUnderInspection: "文本質檢中,請耐心等待",
            textQualityInspectionCompleted: "文本質檢完成",
          },
        },
        template: {
          startAnalysis: "開始分析",
          pleaseUploadTheFile: "請上傳文件",
          localDataUpload: "本地資料上傳",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "新功能! 點擊 '複核提交' 撳掣,提交成功後會自動跳轉到下一頁啦~~~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "質檢任務權限",
            edit: "編輯",
            iParticipated: "我參與嘅",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "該角色能夠見到所選部門下嘅數據",
            dataPermissions: "資料權限",
            selectDepartment: "選擇部門",
            edit: "編輯",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "開始執行重新質檢",
          callID: "通話ID",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "新功能! 點擊 '抽檢提交'同'快速抽檢' 撳掣,提交成功後會自動跳轉到下一頁啦~~~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "規則" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "工單管理",
          workOrderDetails: "工單詳情",
        },
      },
      template: {
        workOrderFilter: "工單篩選器",
        allWorkOrders: "全部工單",
        workOrderManagement: "工單管理",
      },
    },
    trainingCenter: {
      topicAnnotation: "話題標註",
      topicList: "話題列表",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "角色類型",
              keyWord: "關鍵詞",
              pleaseEnterKeywordSearch: "請輸入關鍵詞搜索",
              query: "查詢",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "暫未開放此功能,如需要請聯繫系統管理員",
          pleaseSelectAQuestion: "請選擇問題!",
          recommendedSimilarSentencesLargeModel: "推薦相似句(大模型)",
          selectAll: "全選",
          add: "添加",
          changeBatch: "換一批",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "業務工作時間",
      localDataUpload: "本地資料上傳",
      assembleMenuListAccordingToCurrentUserPermissions:
        "根據當前用戶權限,組裝餐牌列表",
      sessionSettings: "會話設置",
      logoSettings: "Logo設置",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails: "歡迎返嚟! 唔該輸入你嘅詳細信息",
        mailbox: "郵箱",
        password: "密碼",
        signIn: "登錄",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "當前IP唔允許登陸",
          welcomeToLogin: "歡迎登錄",
          pursuingExcellenceAndLeadingChange: "追求卓越,引領變革",
          intelligentPlatform: "智能平台",
        },
        virtualLogin: { index: { pleaseSelect: "請選擇" } },
      },
      oauth: {
        illegalCall: "違法調用",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "歡迎返嚟! 點擊下方按鈕跳轉身份認證平台",
        loggingIn: "登錄中...",
        unifiedIdentityAuthenticationLogin: "統一身份認證登錄",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "規則測試:部分規則唔能使用規則測試,如語速音量、隨路資料等",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "了解更多",
          enteringTheSystem: "進入系統",
        },
      },
      index: {
        cancel: "取消",
        enteringTheSystem: "進入系統",
        close: "關閉",
        enterSystemApplication: "進入系統應用",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          "點擊 “進入系統” 跳轉至已經啟用嘅系統應用",
      },
    },
    permissionManagement: {
      employeeManagement: "員工管理",
      roleManagement: "角色管理",
      permissionManagement: "權限管理",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "等於",
            contain: "包含",
            doesNotContain: "唔包含",
            applicationSystem: "應用系統",
            intelligentQualityInspection: "智能質檢/智能會話分析",
            salesEmpowerment: "銷售賦能",
            dataSourceType: "資料源類型",
            filterCriteria: "篩選條件",
          },
        },
        index: {
          dataConnection: "資料對接",
          savingSucceeded: "保存成功",
          dataIsolation: "資料隔離",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "開啟後,可設置各應用系統接入通話、企業微信會話嘅資料範圍。 開關唔開啟,則各個系統都接入全量資料",
          accessRules: "接入規則",
          preservation: "保存",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "應用名稱",
            accessRights: "訪問權限",
            inAppPermissionSettings: "應用內權限設置",
            setUp: "設置",
            applicationAccess: "應用訪問權限",
            newRole: "新增角色",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "會話詳情" } },
      messageNotification: {
        index: { sessionDetails: "會話詳情", caseDetails: "案例詳情" },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "可見員工",
            newCourse: "新建課程",
            editCourse: "老編課程",
            courseName: "課程名稱",
            courseIntroduction: "課程介紹",
            courseType: "課程類型",
            courseClassification: "課程分類",
            courseCover: "課程封面",
            learningContent: "學習內容",
            uploadLearningMaterials: "上載學習資料",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "測試",
              preservation: "保存",
              cancel: "取消",
              editNodeName: "編輯節點名稱",
              determine: "確定",
              individual: "個",
              of: ", 共",
              branch: "分",
            },
            configDrawerComponents: {
              pPTParsing: "PPT解析中...",
              uploadSuccessful: "上傳成功",
              uploadAttachments: "上傳附件",
              pPTParsingPleaseWait: "PPT解析中,請等陣",
            },
            customerPortraitConfigDrawer: {
              cancel: "取消",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "刪除後唔回覆, 確定要刪除此畫像嗎?",
              delete: "刪除",
              determine: "確定",
              customerPortrait: "客户畫像",
              bindSelectedCharacterPortrait: "綁定選中角色, 個樣",
              customerProfileDetails: "客户畫像詳情",
              createANewCustomerProfile: "新建客戶畫像",
            },
            flow: {
              sidebar: { node: "節點" },
              useControlButtons: {
                cancellingPleaseWait: "撤銷中,請稍後...",
                redoInProgressPleaseWait: "重做中,請稍後...",
                automaticOrganization: "自動整理",
                canvasAdaptation: "畫布自適應",
                delete: "刪除",
              },
              useFlow: {
                nodeCannotBeConnectedToItself: "節點唔能同自己相連接",
              },
            },
            globalConfigDrawer: {
              cancel: "取消",
              determine: "確定",
              globalConfiguration: "全局配置",
              sessionDetection: "會話檢測",
              reciprocalRule: "互動規則",
              robotVoice: "機械人音色",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "學員節點超時未作答設置",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "學員未作答允許嘅最大靜默時間(s)",
              promptScriptForFailureToAnswerOverTime: "超時未作答提示話術",
              addScript: "添加話術",
            },
            robotTimbreForm: {
              aixianatural: "艾夏(自然)",
              zhishaChat: "知莎(傾計)",
              zhiqiangentle: "知倩(温同)",
              zhimiEmotion: "知米(情感)",
              aiShuoNatural: "艾碩(自然)",
              zhishuoChat: "知碩(傾計)",
              aiHaoAffinity: "艾浩(親同)",
              zhixiangEmotion: "知祥(情感)",
              robotVoice: "機械人音色",
              schoolboy: "男生",
              girlStudent: "女生",
              volume: "音量",
              tone: "音調",
              speechSpeed: "語速",
              test: "測試",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "你好,我係智能培訓教練,期待同你嘅交流",
            },
            sessionDetectionForm: {
              expressionDetection: "表達檢測",
              excessiveDetectionOfModalParticles: "語氣詞過多檢測",
              theNumberOfRepetitionsIs: "重複次數為",
              minus: "次時,減",
              appear: "出現",
              subtract: "次,減",
              branch: "分",
              addRules: "增加規則",
              includingNegativeAngryComplaining: "(包括負面、生氣、抱怨)",
              scorePointsDisplay: "得分要點顯示",
              reminderOfSpeakingTooFasttooSlow: "語速過快/過慢提醒",
              scriptViolationReminder: "話術違規提醒",
              emotionalReminder: "情緒提醒",
              speechSpeedDetection: "語速檢測",
              normalSpeechSpeedScore: "語速正常得分",
              speakTooFast: "語速過快",
              everyMinuteGreaterThan: "每分鐘大於",
              aWordAppears: "個字,出現",
              speakTooSlowly: "語速過慢",
              lessThanPerMinute: "每分鐘小於",
              ignoreLessThan: "忽略小於",
              aSentenceOfWords: "字嘅句子",
              emotionalDetection: "情緒檢測",
              emotionalNormalScore: "情緒正常得分",
              negativeEmotions: "負向情緒",
              illegalWordDetection: "違規詞檢測",
              scriptViolationConfiguration: "話術違規配置",
              realTimePromptOnTheFrontEnd: "前端即時提示",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "知識庫問答",
                  qAMethod: "問答方式",
                  numberOfQuestionsAndAnswers: "問答數量",
                  scoringRules: "得分規則",
                  ifAnyQuestionAndAnswerIsCorrect: "任意一個問答正確,得",
                  branch: "分",
                  successfullySaved: "保存成功",
                },
                pptNode: {
                  verificationPassed: "校驗透過",
                  upload: "上傳",
                  supportPptPdfFormatFiles: "支持ppt、pdf格式文件",
                  uploadSlide: "上傳幻燈片",
                  assessmentMethod: "考核方式",
                  associateSlide: "關聯幻燈片",
                  standardScript: "標準話術",
                  successfullySaved: "保存成功",
                },
                robotNode: {
                  robotScript: "機械人話術",
                  randomScript: "隨機話術",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "選擇相似語義嘅話術,機械人將隨機講出其中一句",
                  customerPortrait: "客户畫像",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "當客户畫像一致時, 機器人爆系咪術",
                  setAsDefaultScript: "設為默認話術",
                  yes: "係",
                  no: "否",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "當機械人未命中任何用戶標籤時出此話術",
                  successfullySaved: "保存成功",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "此分支節點已經設有默認話術, 係咪將默認話說替換為當前話術?",
                },
                startNode: {
                  paragon: "旁白話術",
                  successfullySaved: "保存成功",
                },
                userNode: {
                  verificationPassed: "校驗透過",
                  standardScript: "標準話術",
                  successfullySaved: "保存成功",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "請完善配置",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "請輸入呢個對話呢示例",
                  dialogueExample: "呢個對話呢示例",
                  generateConversationExamples: "生成呢個對話呢示例",
                  mattersNeedingAttention: "注意事項",
                  pleaseEnterThePromptLanguage: "請輸入提示語",
                },
                aiGenSceneNode: {
                  dialogueScenario: "對話場景",
                  simulateCharacter: "模擬角色",
                  accompanyingTrainees: "陪練對象",
                  selectFromTemplateLibrary: "由模板庫中選擇",
                  templateLibrary: "模板庫",
                  role: "角色",
                  scene: "場景",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "幻燈片測試窗口 【",
              clearContent: "清除內容",
              close: "關閉",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI生成流程話術",
                scriptStyle: "話術風格",
                theMostConversationRounds: "對話輪次最多",
                copyConversation: "複製對話",
                application: "應用",
                regenerate: "重新生成",
                startGenerating: "開始生成",
                pleaseGenerateAProcess: "請生成流程",
                copySuccessful: "複製成功",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "銷售代表",
                    customerServicePersonnel: "客服人員",
                    storeCounter: "門店專櫃",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "生成全文演講話術",
                speaker: "演講者",
                scriptStyle: "話術風格",
                copyConversation: "複製對話",
                application: "應用",
                regenerate: "重新生成",
                startGenerating: "開始生成",
                pleaseGenerateAProcess: "請生成流程",
                operationSuccessful: "操作成功",
                copySuccessful: "複製成功",
              },
            },
            aiTextArea: { index: { scriptPolishing: "話術潤色" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "話術唔能為空!",
              copySuccessful: "複製成功",
              cancel: "取消",
              copy: "複製",
              replaceTheOriginalText: "替換原文",
              aiGeneration: "Ai生成",
              aiPolishing: "Ai潤色",
              currentStyle: "當前風格",
              rigorous: "嚴謹",
              mild: "温同",
              lively: "活潑",
              simplicity: "簡約",
              pleaseEnterTheSpeakerRole: "請輸入説話者角色",
              startGenerating: "開始生成",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "話術唔能為空!",
              copySuccessful: "複製成功",
              aiRewrite: "Ai重寫",
              cancel: "取消",
              copy: "複製",
              replaceTheOriginalText: "替換原文",
              currentStyle: "當前風格",
              rigorous: "嚴謹",
              mild: "温同",
              lively: "活潑",
              simplicity: "簡約",
              pleaseEnterTheSpeakerRole: "請輸入説話者角色",
              startGenerating: "開始生成",
            },
          },
          index: {
            verificationPassed: "校驗透過",
            ruleValidation: "規則校驗",
            aIGeneration: "AI生成",
            generateFullTextSpeechScript: "生成全文演講話術",
            editCourse: "老編課程",
            importConversation: "導入對話",
            uploadExcelToCompleteDialogueImport: "上傳Excel完成對話導入",
            editBasicInformation: "編輯基本信息",
            test: "測試",
            release: "發佈",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "新建標籤" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "刪除後唔回覆, 確定要刪除此 label 呀?",
              delete: "刪除",
              cancel: "取消",
            },
          },
        },
        index: {
          labelManagement: "標籤管理",
          newGroup: "新建分組",
          edit: "編輯",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "刪除後唔回覆, 確定要刪除此標籤組呀?",
          delete: "刪除",
          cancel: "取消",
          editGroup: "編輯分組",
          labelGroupName: "標籤分組名稱",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "禁用咗監視器",
              noCameraDevicesFound: "冇揾到監視器設備",
            },
          },
          cue: {
            index: {
              regenerate: "重新生成",
              generate: "生成",
              referenceScript: "參考話術",
              mattersNeedingAttention: "注意事項",
              examFrequency: "考試次數",
              section: "第",
              timestotal: "次 / 共",
              second: "次",
              requirementsForPassingTheExam: "考試透過要求",
              examScore: "考試得分 ≥",
              branch: "分",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "暫無個樣",
              customerBackground: "客戶背景",
              customerLabel: "客戶標籤",
            },
          },
          flow: {
            index: {
              processReminder: "流程提示 (",
              score: "得分",
              TotalScore: "/總分",
            },
          },
          index: {
            taskName: "任務名稱",
            taskStatus: "任務狀態",
            whole: "全部",
            completionStatus: "完成狀態",
            courseClassification: "課程分類",
            practiceMode: "練習模式",
            examMode: "考試模式",
            practiceTime: "練習時間",
          },
          ppt: {
            index: {
              nextPage: "下一頁",
              tips: "提示",
              continueWithCurrentPage: "繼續當前頁",
              goToTheNextPage: "進入下一頁",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "進入下一頁後將唔能再返回上一頁,請確認當前頁講解完整後再跳轉",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "點擊下一頁將唔喺彈出此提示",
            },
            list: { index: { slide: "幻燈片" } },
          },
          record: {
            index: {
              unableToRecord: "無法錄音:",
              creatingARecorderService: "創建Recorder服務",
              turnOffTheRecorderService: "關閉Recorder服務",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "當前客户端已經連接到ASR Websocket服務器",
              afterProcessingTheLastFrameRestoreTheState:
                "處理完最後一幀後, 將狀態恢復",
              aSRConnectionClosed: "ASR 連接已經關閉...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "錄音暫停, 並且已經處理完,但係緩存中仍然存喺資料",
              foundASRChannelClosedRecreateWebsocketLink:
                "發現ASR通道關閉,重新創建Websocket連結",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "發現ASR通道未開啟,重新創建Websocket連結",
            },
            ppt: { hook: { index: { serverError: "服務器錯誤" } } },
            text: {
              index: {
                pleaseEnter: "請輸入",
                sendOut: "發送",
                startPracticing: "開始練習",
                startTheExam: "開始考試",
              },
            },
            util: {
              recorded: "已經錄製",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16位pcm SendFrameSize 必須為2嘅整數倍",
              pCMSamplingRateShouldNotOccur: "唔應該出現pcm採樣率",
              andTheRequiredSamplingRate: "同需要嘅採樣率",
              atypism: "唔一致",
              errorsThatShouldNotOccur: "唔應該出現嘅錯誤:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> 順序錯亂嘅資料, 如果要求唔高可以直接掉 </span>',
              flower: "花",
              TransferStopped: ": 已經停止傳輸",
            },
            video: { index: { camera: "監視器" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "當前客户端已經連接到ASR Websocket服務器",
                    aSRConnectionClosed: "ASR 連接已經關閉...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "發現ASR通道關閉,重新創建Websocket連結",
                    unableToRecord: "無法錄音:",
                    aSRChannelClosedReopened: "ASR通道關閉,重新打開",
                    theASRChannelWasNotCreatedStartingNow:
                      "ASR通道未創建,而家開始創建",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect 回調,進行清除ASR通道",
                    creatingARecorderService: "創建Recorder服務",
                    turnOffTheRecorderService: "關閉Recorder服務",
                  },
                  util: {
                    recorded: "已經錄製",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16位pcm SendFrameSize 必須為2嘅整數倍",
                    pCMSamplingRateShouldNotOccur: "唔應該出現pcm採樣率",
                    andTheRequiredSamplingRate: "同需要嘅採樣率",
                    atypism: "唔一致",
                    errorsThatShouldNotOccur: "唔應該出現嘅錯誤:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> 順序錯亂嘅資料, 如果要求唔高可以直接掉 </span>',
                    flower: "花",
                    TransferStopped: ": 已經停止傳輸",
                  },
                },
                index: { failInSend: "發送失敗" },
              },
              index: {
                startRecording: "開始錄音",
                endRecording: "結束錄音",
                startPracticing: "開始練習",
                startTheExam: "開始考試",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(當前考試剩餘次數為",
              totalScriptScore: "話術總分:",
              totalEmotionalScore: "情緒總分:",
              totalScoreOfExpression: "表達總分:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "當前任務評分結果需人工審核,審核完成將通知你最終成績,請耐心等待",
              areYouSureToSubmit: "係咪確認提交?",
              theExerciseHasEndedAreYouSureToSubmit:
                "練習已經結束, 係否確認提交?",
              theExamHasEndedAreYouSureToSubmit: "考試已經結束, 係否確認提交?",
              completed: "已經完成",
              page: "頁",
              pageCount: "總頁數",
              topic: "題",
              totalNumberOfQuestions: "總題數",
              totalScore: "總分",
              branch: "分",
              accumulatedCompletionProgress: "累計完成進度",
              accumulatedPracticeDuration: "累計練習時長",
              lastPauseExerciseResults: "上次暫停-練習結果",
              exerciseInstructions: "練習説明",
              examInstructions: "考試説明",
              halfwayPausePracticeResults: "中途暫停-練習結果",
              tips: "提示",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "退出後此練習唔計入成績, 係否確認退出?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "考試中途唔允許退出,係否確認提交?",
              pleaseOperateWithCaution: "次,請謹慎操作)",
              restart: "重新開始",
              returnToLearning: "返回學習",
              continuePracticing: "繼續練習",
              continuingTheExam: "繼續考試",
              return: "返回",
              notSubmittedTemporarily: "暫唔提交",
              iGotIt: "我知道咗",
              confirmExit: "確認退出",
              confirmSubmission: "確認提交",
              signOut: "退出",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "暫停",
              submit: "提交",
              remainingTimeOfThisQuestion: "本題剩餘時間",
              SecondsLeftUntilTheEndTime: "距離結束時間仲剩10秒",
              remainingTime: "剩餘時間",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "未檢測到用户語音內容, 請重新嘗試",
          },
          wave: {
            index: {
              continueRecording: "繼續錄音",
              submit: "提交",
              endRecording: "結束錄音",
              suspendRecording: "暫停錄音",
              startPracticing: "開始練習",
              startTheExam: "開始考試",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "對話模式",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "噪音過大,請切換安靜環境",
              normalNoiseDetection: "噪音檢測正常",
              theVolumeIsTooLowPleaseIncreaseTheVolume: "音量過小,請增大音量",
              volumeDetectionIsNormal: "音量檢測正常",
              noiseDetection: "噪音檢測",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "請保持安靜,錄製3s環境音",
              endRecording: "結束錄音",
              startRecording: "開始錄音",
              volumeDetection: "音量檢測",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                "請用正常音量朗讀“而家進行音量測試”",
              playLastDetectedAudio: "播放上次檢測音頻",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "開始前請你認真閱讀一下要求",
              return: "返回",
              iGotIt: "我知道咗",
              timeLimitForSingleQuestionAnswering: "單題作答時長限制",
              totalTimeLimit: "總限時",
              unlimitedTime: "唔限時",
              stillNeedToCompleteTheTaskInTotal: "總完成任務仲需",
              practice: "練習",
              second: "次",
              taskScore: "任務一次得分≥",
              branch: "分",
              or: "或",
              examScore: "考試得分≥",
              remainingExamTimes: "剩餘考試次數",
              totalScore: "總分",
              Script: "(話術",
              scoreEmotion: "分 情緒",
              fractionExpression: "分 表達",
              points: "分)",
              standardScore: "達標分",
              excellentScore: "優秀分",
              exerciseInstructions: "練習説明",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "為咗答題順利,請先完成設備檢測:",
              camera: "監視器",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "能喺視頻內見到自己,意味住監視器正常",
              microphone: "支咪",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "請對支咪講嘢, 睇到波形圖樣意味住支咪正常",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "空",
                  copyTo: "複製到",
                  classification: "分類",
                  rename: "重命名",
                  newlyBuild: "新建",
                  categoryParent: "上級分類",
                  moveTo: "移動到",
                  name: "名稱",
                },
              },
              util: {
                rename: "重命名",
                moveTo: "移動到",
                copyTo: "複製到",
                delete: "刪除",
                courseClassification: "課程分類",
                newlyBuild: "新建",
              },
            },
          },
          edit: { index: { curriculum: "課程" } },
          index: {
            learn: "學",
            practice: "練",
            examination: "考",
            curriculum: "課程",
            courseIntroduction: "課程介紹:",
            createdOn: "創建時間:",
            published: "已經發佈",
            tasks: "個任務",
            unpublishedTasks: "未發佈任務",
            task: "任務",
            operation: "操作",
            learningMaterials: "學習資料",
            goPractice: "去練習",
            publishTask: "發佈任務",
            taskData: "任務資料",
            edit: "編輯",
            copy: "複製",
            successfullyCopied: "複製成功",
            delete: "刪除",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "刪除後此課程下嘅所有任務資料都將畀清空,係咪確認刪除課程?",
            courseName: "課程名稱",
            creationTime: "創建時間",
            releaseStatus: "發佈狀態",
            whole: "全部",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "未檢測到聲音",
              voice: "語音",
              text: "文本",
              referenceScript: "參考話術:",
              clickOnTheRightSideToStartRecording: "點擊右側開始錄音",
              sendOut: "發送",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "當前客户端已經連接到ASR Websocket服務器",
                afterProcessingTheLastFrameRestoreTheState:
                  "處理完最後一幀後, 將狀態恢復",
                aSRConnectionClosed: "ASR 連接已經關閉...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "錄音暫停, 並且已經處理完,但係緩存中仍然存喺資料",
                foundASRChannelClosedRecreateWebsocketLink:
                  "發現ASR通道關閉,重新創建Websocket連結",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "發現ASR通道未開啟,重新創建Websocket連結",
                unableToRecord: "無法錄音:",
                creatingARecorderService: "創建Recorder服務",
                turnOffTheRecorderService: "關閉Recorder服務",
              },
              util: {
                recorded: "已經錄製",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16位pcm SendFrameSize 必須為2嘅整數倍",
                pCMSamplingRateShouldNotOccur: "唔應該出現pcm採樣率",
                andTheRequiredSamplingRate: "同需要嘅採樣率",
                atypism: "唔一致",
                errorsThatShouldNotOccur: "唔應該出現嘅錯誤:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> 順序錯亂嘅資料, 如果要求唔高可以直接掉 </span>',
                flower: "花",
                TransferStopped: ": 已經停止傳輸",
              },
            },
            finishTest: {
              index: {
                endTest: "結束測試",
                testResult: "測試結果",
                returnToCanvas: "返回畫布",
                retest: "重新測試",
              },
            },
          },
          index: {
            testFromCurrentNode: "從當前節點測試",
            listening: "收音中...",
            canvasTesting: "畫布測試",
            endOfProcess: "流程結束",
            restart: "重新開始",
            start: "開始",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "收起解析",
                  expandParsing: "展開解析",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: ", 語氣詞過多",
                  second: "次",
                  ratingOverview: "評分總覽",
                  totalScore: "總分",
                  section: "第",
                  name: "名",
                  of: "(共",
                  people: "人)",
                  scriptScore: "話術得分",
                  common: "共",
                  keyPoints: "個要點,",
                  notReached: "個未達到,",
                  have: "有",
                  violationOfRegulations: "處違規",
                  emotionalScore: "情緒得分",
                  appear: "出現",
                  secondaryNegativeEmotions: "次負面情緒",
                  expressionScore: "表達得分",
                  speakTooFast: "語速過快",
                  times: "次,",
                  speakTooSlowly: "語速過慢",
                  average: "平均",
                  wordminute: "字/分鐘",
                },
              },
              score: {
                index: {
                  studentNodeName: "學員節點名稱",
                  keyPointName: "要點名稱",
                  slideNodeName: "幻燈片節點名稱",
                  dialogue: "對話",
                  mainPoints: "要點",
                  slide: "幻燈片",
                  detectionResult: "檢測結果",
                  satisfy: "滿足",
                  dissatisfaction: "唔滿足",
                  score: "得分",
                  script: "話術",
                  semantics: "語義",
                  scriptViolation: "話術違規",
                  emotion: "情緒",
                  expression: "表達",
                },
              },
              taskTitle: { index: { branch: "分" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "本次",
                  lastTime: "上次",
                  comprehensiveEvaluation: "綜合評價",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style="margin-left: 8px">· 標準話術累積',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "個字,學員跟讀命中嘅字數共",
                  aWordspan: "個字</span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    '次</span><br /><span style="margin-left: 8px">· 語速過慢',
                  timesspan: "次</span>",
                  tooManyModalParticles:
                    '<Span style="margin-left: 8px">· 語氣詞過多',
                  complete: "內容完整",
                  keyHit: "要點命中",
                  languageNorm: "語言規範",
                  fluentExpression: "表達流暢",
                  emotionallyPositive: "情感積極",
                  radarChart: "雷達圖",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style="margin-left: 8px">· 完整流程數',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style="margin-left: 8px">· 命中要點數',
                  spanStylemarginLeftpxYes:
                    '<Span style="margin-left: 8px">· 有',
                  dealingWithIllegalWordsspan: "處違規詞</span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style="margin-left: 8px">· 語速過快',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '次</span><br /><span style="margin-left: 8px">· 語氣詞過多',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style="margin-left: 8px">· 負面情緒',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "評分記錄",
                  firstRating: "首次評分",
                  totalScore: "總分",
                  changeTo: "變更為",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "溝通階段",
                  communicationObjectives: "溝通目標",
                  scoringCriteria: "評分標準",
                  suggestionsForImprovement: "提升建議",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "剩餘次數:",
                  second: "次",
                  doItAgain: "再嚟一次",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "瀏覽器唔支持音頻播放。",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "話術點評同建議",
                  regenerate: "重新生成",
                  generate: "生成",
                  generationFailed: "生成失敗",
                },
              },
              resultModal: {
                index: { return: "返回", viewDetails: "檢視詳情" },
              },
            },
            index: {
              reRatingSuccessful: "重新評分成功",
              reRatingFailed: "重新評分失敗",
              downloadRecording: "下載錄音",
              downloadText: "下載文本",
              download: "下載",
              reRating: "重新評分",
              submitARatingAppeal: "提交評分申訴",
              reviewSubmission: "複核提交",
              saveChanges: "保存修改",
              previousSentence: "上一句",
              nextSentence: "下一句",
              score: "評分",
              scoringSituation: "得分情況",
              dialogueAnalysis: "對話解析",
            },
          },
          index: {
            ratingAppealInProgress: "評分申訴中",
            operation: "操作",
            wrongQuestionSet: "錯題集",
            ratingUpdate: "評分更新",
            viewDetails: "檢視詳情",
            curriculum: "課程",
            taskName: "任務名稱",
            taskType: "任務類型",
            score: "得分",
            scoringResults: "得分結果",
            time: "時間",
            courseClassification: "課程分類",
            whole: "全部",
            learningTime: "學習時間",
            historicalLearningData: "歷史學習資料",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "練習暫停",
              lastProgress: "上次進度",
              continuePracticing: "繼續練習",
              practice: "練習",
              startPracticing: "開始練習",
              historicalData: "歷史資料",
              courseIntroduction: "課程介紹:",
              exerciseDifficulty: "練習難度:",
              practiceCompletionRequirements: "練習完成要求:",
              practiceFrequency: "練習次數≥",
              second: "次",
              anyScore: "任意一次得分≥",
              branch: "分",
              or: "或",
              taskList: "任務列表",
              practiceTime: "練習時間:",
              to: "至",
              unlimitedTime: "唔限時間",
              completionStatus: "完成狀態",
              numberOfExercises: "練習次數",
              maximumScore: "最高分",
              operation: "操作",
              enteringLearning: "進入學習",
              historicalLearningData: "歷史學習資料",
              wrongQuestionSet: "錯題集",
            },
          },
          index: {
            practiceTasks: "練習任務",
            examTasks: "考試任務",
            task: "任務",
          },
          learn: {
            index: {
              courseName: "課程名稱",
              courseIntroduction: "課程介紹",
              learningContent: "學習內容",
              downloadLearningMaterials: "學習資料下載",
              startPracticing: "開始練習",
            },
          },
          test: {
            index: {
              taskList: "任務列表",
              courseIntroduction: "課程介紹:",
              examDifficulty: "考試難度:",
              requirementsForPassingTheExam: "考試透過要求:",
              examScore: "考試得分≥",
              branch: "分",
              examTime: "考試時間:",
              to: "至",
              unlimitedTime: "唔限時間",
              completionStatus: "完成狀態",
              completionTimes: "完成次數",
              maximumScore: "最高分",
              operation: "操作",
              immediateExam: "立即考試",
              history: "歷史記錄",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "參考話術" } },
              flow: {
                index: {
                  wrongQuestionItem: "錯題項 (",
                  score: "得分",
                  TotalScore: "/總分",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "服務器錯誤" } },
                  index: {
                    startRecording: "開始錄音",
                    endRecording: "結束錄音",
                    startPracticing: "開始練習",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "請輸入",
                    sendOut: "發送",
                    startPracticing: "開始練習",
                  },
                },
                voice: {
                  index: {
                    startRecording: "開始錄音",
                    endRecording: "結束錄音",
                    startPracticing: "開始練習",
                  },
                  hook: { index: { failInSend: "發送失敗" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions: "本次錯題練習得分",
                  tips: "提示",
                  areYouSureToEnd: "係咪確認結束?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "再次練習未掌握錯題",
                  continue: "繼續",
                  returnToTheSetOfIncorrectQuestions: "返回錯題集",
                  end: "結束",
                  wrongQuestionItem: "錯題項",
                  numberOfExercisesThisTime: "本次練習次數",
                  masteringTheSituation: "掌握情況",
                  mastered: "已經掌握",
                  notMastered: "未掌握",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "再次練習本題",
                  nextQuestion: "下一題",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "錯題練習",
                endExercise: "結束練習",
              },
            },
            text: {
              index: {
                mispracticeExercises: "錯題練習",
                endExercise: "結束練習",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "錯題練習",
                endExercise: "結束練習",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "檢視話術",
                  section: "第",
                  secondaryDiscourseTechnique: "次話術",
                },
              },
              tableItem: {
                count: { second: "次" },
                scoreRuleList: {
                  noRelevantInformationFound: "未查詢到相關信息",
                  recallWords: "召回詞",
                  branch: "分",
                  answerIncorrectly: "答錯",
                  second: "次",
                  wrongAnswerRate: "答錯率",
                },
                standardScript: {
                  standardScript: "標準話術",
                  myScript: "我嘅話術",
                },
                status: { mastered: "已經掌握", notMastered: "未掌握" },
                index: {
                  totalNumberOfQuestionsAnswered: "共計答題次數",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "錯題已經練習次數",
                },
              },
            },
            index: {
              wrongQuestionList: "錯題列表",
              wrongQuestionSet: "錯題集",
              mispracticeExercises: "錯題練習",
              taskName: "任務名稱",
              staff: "員工",
              masteringTheSituation: "掌握情況",
              whole: "全部",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "練習次數≥",
              second: "次",
              or: "或",
              excellentScoreAtAnyOneTime: "任意一次得分優秀(≥",
              points: "分)",
              examScore: "考試得分≥",
              branch: "分",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "隨機平均分配",
              assignToDesignatedPersonnel: "派畀指定人員",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams: "增加考試次數成功",
              failedToIncreaseTheNumberOfExams: "增加考試次數失敗",
              increaseTheNumberOfExams: "增加考試次數",
              selectObjects: "選擇對象",
            },
          },
          executionFilter: { index: { participationRate: "參與率" } },
          intelligentReview: {
            index: {
              overallReview: "整體點評",
              pleaseEnterTheReviewCriterianotMandatory:
                "請輸入點評標準(非必填)",
              commentaryOnSingleSentenceRhetoric: "單句話術點評",
              realTimeReview: "實時點評",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "考試平均分",
            averageExamDurationmin: "平均考試時長(min)",
            accumulatedPracticeDurationmin: "累計練習時長(min)",
            accumulatedPracticeTimes: "累計練習次數",
            totalScore: "總分",
            average: "平均分",
            total: "總數",
            keyHitCount: "要點命中次數",
            practiceAverageScore: "練習平均分",
            averagePracticeDurationmin: "平均練習時長(min)",
            topOfTheHighestScore: "最高分Top3",
            branch: "分",
            errorProneItemTop: "易錯項Top5",
            hitRate: "命中率",
            hitRateOfKeyPointsInScript: "話術要點命中率",
            averageScriptScore: "話術平均得分",
            sortByProcess: "按流程排序",
            sortByScore: "按得分排序",
            averageScoreOfThisPagetotalScoreOfThisPage: "該頁平均分/該頁總分",
            averageScoreOfThisItemtotalScoreOfThisItem: "該項平均分/該項總分",
            details: "詳情",
            viewRules: "檢視規則",
            standardScript: "標準話術",
          },
          scoreDetailList: {
            section: "第",
            second: "次",
            practice: "練習",
            examination: "考試",
            notMeetingStandards: "未達標",
            meetingStandards: "達標",
            excellent: "優秀",
            frequency: "次數",
            fraction: "分數",
            result: "結果",
            operation: "操作",
            viewDetails: "檢視詳情",
          },
          index: {
            common: "共",
            people: "人",
            staff: "員工",
            notMeetingStandards: "未達標",
            meetingStandards: "達標",
            excellent: "優秀",
            notStarted: "未開始",
            haveInHand: "進行中",
            completed: "已經完成",
            passed: "已經透過",
            hangInTheAir: "未完成",
            failed: "未透過",
            unlimitedTime: "唔限時間",
            to: "至",
            maximumScoringCriteria: "最高得分標準",
            completionStatus: "完成狀態",
            employeeName: "員工姓名",
            frequency: "次數",
            practice: "練習",
            examination: "考試",
            average: "平均分",
            speechCraftAvgScore: "話術平均得分",
            emotionAvgScore: "情緒平均得分",
            speedAvgScore: "表達平均得分",
            flowAvgScore: "流程平均得分",
            practiceCostTime: "累計時長",
            participateCommitRate: "達標率",
            participateExcellentRate: "達優率",
            duration: "時長",
            score: "得分",
            scoringResults: "得分結果",
            flowScore: "流程得分",
            craftScore: "話術得分",
            emotionScore: "情緒得分",
            speedScore: "表達得分",
            contentIntegrity: "內容完整度",
            pointHitRate: "要點命中率",
            emotionNegativeRate: "情緒負向佔比",
            paraPhaSiaRate: "語速異常佔比",
            toneOvermuchRate: "語氣詞過多佔比",
            maximumScore: "最高分",
            highestScoreResult: "最高得分結果",
            state: "狀態",
            averageDurationmin: "平均時長(min)",
            lately: "最近",
            time: "時間",
            operation: "操作",
            viewDetails: "檢視詳情",
            wrongQuestionSet: "錯題集",
            taskName: "任務名稱",
            completeRequirements: "完成要求",
            publishTo: "發佈對象",
            taskTime: "任務時間",
            fractionalComposition: "分數構成",
            totalScore: "總分",
            branch: "分",
            Script: "(話術",
            scoreEmotion: "分 情緒",
            fractionExpression: "分 表達",
            points: "分)",
            implementationRate: "參與率",
            numberOfExecutors: "參與人數",
            numberOfPeopleNotExecuted: "未參與人數",
            completionRate: "完成率",
            numberOfCompletedPersonnel: "完成人數",
            numberOfUnfinishedPersonnel: "未完成人數",
            complianceRate: "達標率",
            numberOfQualifiedPersonnel: "達標人數",
            numberOfNonCompliantIndividuals: "未達標人數",
            excellentRate: "達優率",
            participateCompleteRate: "參與完成率",
            numberOfOutstandingIndividuals: "優秀人數",
            numberOfNonOutstandingIndividuals: "非優秀人數",
            export: "導出",
            taskData: "任務資料",
            resultAnalysis: "結果分析",
            scoreDetails: "得分詳情 -",
            dataOverview: "資料概覽",
            taskDetail: "任務明細",
            scriptAnalysis: "話術分析",
            flowAnalysis: "流程分析",
            expressionAnalysis: "表達分析",
            emotionAnalysis: "情緒分析",
            byTime: "時間維度視圖",
            byNumber: "次數維度視圖",
            personData: "按人員統計",
            numberData: "按次記錄明細",
            count: "按次數",
            person: "按人數",
            reachTimes: "觸達次數",
            outOfReachTimes: "未觸達次數",
            seeOutOfReach: "點擊檢視未觸達",
            accumulative: "累計",
            script: "話術",
            times: "次",
            sentence: "句",
            expressionAverage: "表達平均分",
            modalAnalysis: "語氣詞分析",
            total: "共",
            muchModalAnalysis: "存喺語氣詞過多",
            normal: "正常",
            speedAnalysis: "語速分析",
            speedDistribution: "語速分佈",
            speakingSpeed: "說話語速",
            normalSpeak: "正常語速",
            tooFastSpeak: "過快語速",
            tooSlowlySpeak: "過慢語速",
            violationWord: "違規詞",
            violationWordAnalysis: "違規詞分析",
            noData: "暫無",
            emotionalScore: "情緒得分",
            emotionalDistribution: "情緒分佈",
            negativeEmotion: "負向情緒",
            normalEmotion: "正常情緒",
            getDetail: "檢視詳情",
            barPattern: "條形圖視圖",
            timeDimension: "時間緯度視圖",
            timesDimension: "次數維度視圖",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "平均分",
                  averageScoreOfScript: "話術平均分",
                  averageEmotionalScore: "情緒平均分",
                  expressionAverageScore: "表達平均得分",
                  processAverageScore: "流程平均分",
                  accumulatedDuration: "累積時長",
                  accumulatedTimes: "累計次數",
                  participationComplianceRate: "參與達標率",
                  participationRateForAchievingExcellence: "參與達優率",
                  branch: "分",
                  numberOfPeople: "人數",
                  averageScoreOfExpression: "表達平均分",
                  people: "人",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: { index: { negativeEmotions: "負向情緒" } },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "話術平均得分",
                  branch: "分",
                },
                useSummaryDetail: { keyHitRate: "要點命中率" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "智能點評",
            pleaseImproveTheConfiguration: "請完善配置",
            examResults: "考試結果",
            showTheResultsAfterEachExam: "每次考試完展示結果",
            showTheResultsAfterTheLastExam: "最後一次考試完展示結果",
            doNotDisplayResults: "唔展示結果",
            resultDisplay: "結果展示",
            score: "得分",
            scoringResults: "得分結果",
            allowViewingScoreDetails: "允許檢視得分詳情",
            viewCanvasConfiguration: "檢視畫布配置",
            synchronizationFailedProcessNodeHasChanged:
              "同步失敗, 流程節點已經產生變更",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "同步成功,請點擊發佈更新課程",
            retrievedTheLatestCanvasConfiguration: "已經拉取最新畫布配置",
            synchronizeCanvasConfiguration: "同步畫布配置",
            scoringMethod: "評分方式",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "分,流程{0}分,話術{1}分,情緒{2}分,語速{3}分)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "優秀標準需高於達標標準",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "評分標準需要滿足大於0%,小於等於100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "評分標準需要滿足大於0分,小於等於{0}分",
            doRatingsRequireManualReview: "評分係咪需人工審核",
            allocationStrategy: "分配策略",
            pleaseSelectTheAssignedPersonnel: "請選擇分配人員",
            pleaseSelectADesignatedPerson: "請選擇指定人員",
            timeLimitForSingleQuestionAnswering: "單題作答時長限制",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault: "默認發佈畀新添加員工",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "重新評分表示將按照最新畫布嘅得分規則進行匹配, 當前任務存喺人工已經複核嘅評分資料, 係咪要覆蓋分數",
            notCovered: "唔覆蓋",
            cover: "覆蓋",
            participationSituation: "參與情況",
            numberOfParticipants: "參與人數",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "此任務選擇嘅練習對象人數求同",
            sumOfTheNumberOfParticipantsInTheTask: "已經參與任務嘅人數求同",
            numberOfCompletedPersonnel: "完成人數",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "滿足任務完成要求嘅人數求同",
            numberOfQualifiedPersonnel: "達標人數",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "滿足任務得分達標嘅人數求同",
            excellentPersonnel: "優秀人數",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "滿足任務得分優秀嘅人數求同",
            participationRate: "參與率",
            numberOfParticipantsNumberOfParticipants: "已經應參人數/應參與人數",
            numberOfCompletedNumberOfParticipants: "完成人數 / 應參與人數",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "達標人數 / 應參與人數",
            excellentNumbernumberOfParticipants: "優秀人數 / 參與人數",
            participationCompletionRate: "參與完成率",
            numberOfCompletedParticipated: "完成人數 / 參與人數",
            participationComplianceRate: "參與達標率",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "達標人數 / 參與人數",
            participationRateForAchievingExcellence: "參與達優率",
            accumulatedDuration: "累積時長",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "學員參與任務時長求同",
            averageDuration: "平均時長",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "學員參與任務時長求同/總參與次數",
            accumulatedTimes: "累積次數",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "學員參與任務次數求同",
            averageScore: "平均分",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "參與該任務人員嘅平均得分",
            maximumScore: "最高分",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "參與該任務人員嘅最高分",
            averageScoreOfScript: "話術平均分",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "學員話術得分求同/總參與次數",
            totalScoreOfScript: "話術總分",
            theTotalScoreOfTheScriptSetUnderThisTask: "該任務下設置嘅話術總分",
            processAverageScore: "流程平均分",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "學員流程得分求同/總參與次數",
            totalProcessScore: "流程總分",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "該任務下設置嘅流程總分",
            averageEmotionalScore: "情緒平均分",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "學員情緒得分求同/總參與次數",
            totalEmotionalScore: "情緒總分",
            theTotalScoreOfEmotionsSetUnderThisTask: "該任務下設置嘅情緒總分",
            averageScoreOfExpression: "表達平均分",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "學員表達得分求同/總參與次數",
            totalScoreOfExpression: "表達總分",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "該任務下設置嘅表達總分",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "開始執行重新評分,可跳轉至任務中心檢視進度",
            reRating: "重新評分",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "重新評分表示將按照最新畫布嘅得分規則進行匹配,會覆蓋當前得分結果",
            releaseTime: "發佈時間",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "已經審核",
              pendingReview: "待審核",
              enterKeywordSearch: "輸入關鍵詞搜索",
            },
            pageRightTable: {
              index: {
                employeeName: "員工姓名",
                systemRating: "系統評分",
                scoringResults: "得分結果",
                duration: "時長",
                time: "時間",
                state: "狀態",
                whetherToModifyTheRating: "係咪修正評分",
                finalScore: "最終得分",
                reviewedBy: "審核人",
                operation: "操作",
                viewScoreDetails: "檢視得分詳情",
                viewStatus: "檢視狀態",
                whole: "全部",
                correctionStatus: "修正狀態",
                completeReview: "完成審核",
                tips: "提示",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "學員最終成績依當前最終審核結果為準,請確認",
              },
            },
          },
          index: { manualReview: "人工審核" },
        },
        review: {
          index: {
            employeeName: "員工姓名",
            score: "得分",
            scoringResults: "得分結果",
            duration: "時長",
            taskName: "任務名稱",
            numberOfAppealScripts: "申訴話術數量",
            appealTime: "申訴時間",
            processStatus: "流程狀態",
            scoreAfterReview: "複核後得分",
            reviewedBy: "複核人",
            operation: "操作",
            viewScoreStatus: "檢視得分情況",
            whole: "全部",
            manualReview: "人工複核",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "練習任務資料",
            implementationRate: "執行率",
            numberOfExecutors: "執行人數",
            numberOfPeopleNotExecuted: "未執行人數",
            completionRate: "完成率",
            numberOfCompletedPersonnel: "完成人數",
            numberOfUnfinishedPersonnel: "未完成人數",
            complianceRate: "達標率",
            numberOfQualifiedPersonnel: "達標人數",
            numberOfNonCompliantIndividuals: "未達標人數",
            excellentRate: "達優率",
            numberOfOutstandingIndividuals: "優秀人數",
            numberOfNonOutstandingIndividuals: "非優秀人數",
            examTaskData: "考試任務資料",
            published: "已經發佈",
            courses: "個課程",
            courseData: "課程資料",
            practiceTasks: "練習任務",
            examTasks: "考試任務",
          },
          practiceSummary: {
            practiceSummary: "練習小結",
            numberOfPracticeTasks: "練習任務數",
            numberOfExercises: "練習次數",
            accumulatedPracticeDuration: "累計練習時長",
            complianceRate: "達標率",
            excellentRate: "達優率",
            ranking: "排行",
          },
          rankingList: {
            numberOfExercises: "練習次數",
            exerciseDuration: "練習時長",
            complianceRate: "達標率",
            excellentRate: "達優率",
            diligenceChart: "勤奮榜",
            excellentList: "優秀榜",
            ranking: "排名",
            fullName: "姓名",
            i: "我",
          },
          taskCard: {
            end: "截止:",
            noDeadline: "無截止日期",
            second: "次",
            branch: "分",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "暫無任務",
            completed: "已經完成",
          },
          filter: { employeeDepartment: "員工部門" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "導出成功,請前往任務中心檢視",
              export: "導出",
            },
          },
          filterConfig: {
            dropdown: { custom: "自定義" },
            modal: {
              updateSuccessful: "更新成功",
              successfullySaved: "保存成功",
              setAsACommonReport: "設置為常用報表",
              updateCurrentReport: "更新當前報表",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "平均分",
                  averageScoreOfScript: "話術平均得分",
                  averageEmotionalScore: "情緒平均得分",
                  expressionAverageScore: "表達平均得分",
                  processAverageScore: "流程平均得分",
                  branch: "分",
                },
              },
              comprehensiveEvaluation: { const: { data: "資料" } },
              doneStatus: {
                index: {
                  practice: "練習",
                  examination: "考試",
                  executed: "已經執行",
                  unexecuted: "未執行",
                  accumulatedExamDuration: "累計考試時長",
                  examFrequency: "考試次數",
                },
              },
              filter: {
                index: {
                  practice: "練習",
                  examination: "考試",
                  pleaseCompleteTheRequiredFields: "請完善必填項",
                  staff: "員工",
                  taskType: "任務類型",
                  pleaseSelect: "請選擇",
                  taskName: "任務名稱",
                },
              },
            },
            index: {
              completionStatus: "完成情況",
              comprehensiveEvaluation: "綜合評價",
              abilityGrowthTrend: "能力成長趨勢",
              errorProneAnalysis: "易錯點分析",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "活躍人數",
            accumulatedPracticeDuration: "累計練習時長",
            averagePracticeDuration: "平均練習時長",
            people: "人",
            numberOfPracticeTasks: "練習任務數",
            numberOfExercises: "練習次數",
            exerciseDuration: "練習時長",
            second: "次",
            avgScore: "平均分",
            speechCraftAvgScore: "話術平均得分",
            emotionAvgScore: "情緒平均得分",
            speedAvgScore: "表達平均得分",
            flowAvgScore: "流程平均得分",
            practiceCostTime: "累計時長",
            totalPracticeTimes: "累計次數",
            participateCommitRate: "參與達標率",
            participateExcellentRate: "參與達優率",
          },
        },
        index: {
          employeeProfile: "員工畫像",
          overview: "總覽",
          personalSignage: "個人看板",
          ongoingExercises: "進行中嘅練習",
          ongoingExams: "進行中嘅考試",
          practiceTaskProgress: "練習任務進度",
          examTaskProgress: "考試任務進度",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "請選擇概念詞",
              sessionDetectionSettings: "會話檢測設置",
              successfullySaved: "保存成功",
              abnormalSpeechSpeed: "語速異常",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "支持自定義語速檢測標準,結果會判斷為語速正常、語速過快、語速過慢。 當出現語速異常(過快/過慢),會按評分規則進行減分",
              speechSpeedTooFastGreaterThanEveryMinute: "語速過快:每分鐘大於",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "個字,語速過慢: 每分鐘小於",
              charactersIgnoringLessThan: "個字,忽略小於",
              aSentenceOfWords: "字嘅句子",
              excessiveModalParticles: "語氣詞過多",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "支持自定義語氣詞,並設定語氣詞過多規則。 當出現語氣詞過多時,會按評分規則進行減分",
              theNumberOfRepetitionsIs: "重複次數為",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "次時,判定為語氣詞過多",
              definitionOfSensitiveViolations: "敏感違規定義",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "支持自定義違規詞詞庫,檢測一句話中係咪出現違規詞。 當出現違規詞時,會按評分規則進行減分",
              violationWords: "違規詞:",
              emotionalDetection: "情緒檢測",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "按系統情緒模型對話術進行情緒檢測,結果會判斷為情緒正常、情緒負向 (包括負面、生氣、抱怨)。 當出現情緒負向時,會按評分規則進行減分",
              preserve: "保存",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "評分維度",
              ratingPoints: "評分點",
              explain: "説明",
              type: "類型",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "隨機資料" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style="margin-left: 8px">· 標準話術累積',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "個字,學員跟讀命中嘅字數共",
                  aWordspan: "個字</span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    '次</span><br /><span style="margin-left: 8px">· 語速過慢',
                  timesspan: "次</span>",
                  tooManyModalParticles:
                    '<Span style="margin-left: 8px">· 語氣詞過多',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "分值為" },
                  item: {
                    violationOnceReduced: "違規1次減",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "語速異常(包括語速過快、語速過慢)1次減",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "語氣詞過多1次減",
                    oneNegativeDecreaseInEmotions: "情緒負向1次減",
                    branch: "分",
                  },
                  wrap: { name: "名稱", preserve: "保存" },
                },
              },
              index: {
                complete: "內容完整",
                keyHit: "要點命中",
                accurateFollowupReading: "跟讀準確",
                languageNorm: "語言規範",
                speechSpeed: "語速",
                modalParticles: "語氣詞",
                emotionallyPositive: "情感積極",
                scoringModel: "評分模型",
                successfullyModified: "修改成功",
                newSuccessfullyCreated: "新建成功",
              },
            },
            index: {
              scoringModel: "評分模型",
              newModel: "新建模型",
              model: "模型",
              type: "類型",
              operation: "操作",
              preview: "預覽",
              edit: "編輯",
            },
          },
          index: {
            scoringModel: "評分模型",
            ratingDimension: "評分維度",
            sessionDetectionSettings: "會話檢測設置",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: { pleaseSelect: "請選擇", hit: "命中", misses: "未命中" },
            },
          },
          index: {
            duration: "時長",
            processScore: "流程得分",
            branch: "分",
            contentCompleteness: "內容完整度",
            scriptScore: "話術得分",
            keyHitRate: "要點命中率",
            emotionalScore: "情緒得分",
            negativeProportionOfEmotions: "情緒負向佔比",
            expressionScore: "表達得分",
            theProportionOfAbnormalSpeechSpeed: "語速異常佔比",
            excessiveProportionOfModalParticles: "語氣詞過多佔比",
            learningRecordData: "學習記錄資料",
            staff: "員工",
            curriculum: "課程",
            mainPoints: "要點",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "表格",
                        lineChart: "折線圖",
                        barChart: "柱狀圖",
                        pieChart: "餅圖",
                      },
                      index: { switchingTypes: "切換類型" },
                    },
                  },
                  index: {
                    saveSuccessful: "保存成功",
                    editChart: "編輯圖表",
                    chartName: "圖表名稱",
                    deleteSuccessful: "刪除成功",
                    refresh: "刷新",
                    export: "導出",
                    areYouSureToDeleteIt: "肯定刪除呀?",
                    delete: "刪除",
                    viewFilteringCriteria: "檢視篩選條件",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "導出成功,請前往任務中心檢視",
              },
            },
            header: {
              index: {
                saveSuccessful: "保存成功",
                deleteSuccessful: "刪除成功",
                editReport: "編輯報表",
                reportName: "報表名稱",
                refresh: "刷新",
                areYouSureToDeleteIt: "肯定刪除呀?",
                delete: "刪除",
                exportToExcel: "導出Excel",
                exportSnapshot: "導出快照",
                export: "導出",
                authority: "權限",
                permissionSettings: "權限設置",
                reportVisiblePermissions: "報表可見權限",
                visibleToEveryone: "所有人可見",
                onlyVisibleToOneself: "僅自己可見",
                designatedPersonnelVisible: "指定人員可見",
                pleaseSelect: "請選擇",
                reportDataPermissions: "報表資料權限",
                restrictedByUserPermissions: "受限於用户權限",
                visiblePeopleAreTheSame: "可見人相同",
              },
            },
            titleFooter: {
              index: { addReport: "新增報表", reportName: "報表名稱" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "導出成功,請前往任務中心檢視",
            dataQueryInProgress: "資料查詢中...",
            customKanban: "自定義看板",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "表格",
                lineChart: "折線圖",
                barChart: "柱狀圖",
                pieChart: "餅圖",
              },
              index: {
                successfullyAdded: "添加成功",
                viewSQL: "檢視sql",
                export: "導出",
                addToReport: "添加到報表",
              },
            },
            refer: {
              hooks: {
                data: "資料",
                interrogativeSentence: "問句",
                historicalQuery: "歷史查詢",
              },
              index: { copySuccessful: "複製成功", copy: "複製" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "未達標",
                        meetingStandards: "達標",
                        excellent: "優秀",
                        notStartedYet: "未開始",
                        inProgress: "進行中",
                        completed: "已經完成",
                        incomplete: "未完成",
                      },
                    },
                  },
                  const: {
                    taskName: "任務名稱",
                    department: "部門",
                    studentName: "學員姓名",
                    scoringResults: "得分結果",
                    taskCompletionStatus: "任務完成狀態",
                  },
                  index: { pleaseSelectADimension: "請選擇維度" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "輸入@可打開彈框進行維度選擇",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "請輸入問詢語句直接進行查詢",
                query: "查詢",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "導出成功,請前往任務中心檢視",
            dataQueryInProgress: "資料查詢中...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "關鍵詞搜索",
                previous: "上一個",
                next: "下一個",
                theObjectThatTriggersTheScrollingEventIs:
                  "觸發滾動事件嘅對象係",
                userScrolling: "用户滾動",
              },
            },
            summary: {
              index: {
                reminder: "提示語:",
                edit: "編輯",
                name: "名稱",
                hintLanguage: "提示語",
              },
            },
            viewpoint: { index: { viewpointExtraction: "觀點提取" } },
            outline: {
              index: {
                numberOfKeywords: "關鍵詞次數",
                speaker: "説話人",
                speechDuration: "說話時長",
                proportionOfSpeaking: "講嘢佔比",
                maximumSpeechDuration: "最長發言時長",
                numberOfQuestions: "提問數量",
                averageSpeechSpeed: "平均語速",
                conversationalActions: "會話動作",
                contentSummary: "內容摘要",
                generateSummary: "生成摘要",
                keywordCloud: "關鍵詞詞雲",
              },
            },
          },
          index: {
            outline: "概要",
            viewpoint: "觀點",
            contentSummary: "內容摘要",
            viewpointExtraction: "觀點提取",
            summaryOfKeyPoints: "要點總結",
            videoAnalysis: "視頻分析",
          },
        },
        list: {
          index: {
            delete: "刪除",
            uploadSuccessful: "上傳成功",
            videoList: "視頻列表",
            uploadVideo: "上傳視頻",
            videoName: "視頻名稱",
            uploadTime: "上傳時間",
            endTime: "結束時間",
            analyzeProgress: "分析進度",
            haveInHand: "進行中",
            completed: "已經完成",
            fail: "失敗",
            toBegin: "待開始",
            operation: "操作",
            see: "檢視",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "添加工作時間" },
        },
      },
      index: {
        businessWorkingHours: "業務工作時間",
        newlyBuild: "新建",
        name: "名稱",
        describe: "描述",
        operation: "操作",
        edit: "編輯",
        delete: "刪除",
        editBusinessWorkingHours: "編輯業務工作時間",
        newBusinessWorkingHours: "新建業務工作時間",
        workingHours: "工作時間",
      },
    },
    algorithmSemanticIntelligence: { intelligentExtraction: "智能抽取" },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "你確認要取消呀? 取消後將唔能再恢復",
        },
      },
    },
    accountManagement: {
      securitySetting: "安全設置",
      accountManagement: "賬户管理",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "至",
            switchToIPAddressMode: "切換為IP地址模式",
            switchToIPSegmentMode: "切換為IP段模式",
          },
        },
      },
      index: {
        saveSuccessful: "保存成功",
        pleaseImproveTheConfiguration: "請完善配置",
        securitySetting: "安全設置",
        employeeLoginIPRestrictions: "員工登陸IP限制",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "限制員工可登錄嘅IP範圍,超出範圍唔允許登陸。 僅對網站生效,移動app唔受限制,管理員唔受限制。",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "新增或者修改時, 請填寫外網 IP 段(同一 C 段)或者某個固定外網 IP 地址。",
        allowLoginIPRange: "允許登陸嘅IP範圍",
        preserve: "保存",
      },
    },
    coachAdminEntry: { speechRecognition: "語音識別" },
    billingCenter: {
      template: {
        details: "詳情",
        languageType: "語言類型",
        aSREngine: "ASR引擎",
        voiceQualityQuota: "語音質檢配額",
        realtimeData: "實時資料",
        calculatedByDay: "按日計算",
      },
      component: {
        aSRName: "ASR名稱",
        language: "語言",
        remainingQuota: "剩餘配額",
        hours: "小時",
        usedQuota: "已經用配額",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "操作保存成功!",
        deletedSuccessfully: "刪除成功!",
        pleaseSelectLanguage: "請選擇語言",
        pleaseConfigureRules: "請設定規則",
        updateSuccessful: "更新成功!",
        operationSuccessful: "操作成功!",
      },
      template: {
        aSRLanguage: "ASR語言",
        pleaseSelect: "請選擇",
        model: "模型",
        pleaseSelectLanguage: "請選擇語言",
        delete: "刪除",
        newASRModel: "新建ASR模型",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "競品分析" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "電商評論篩選器",
        allComments: "全部評論",
        ecommerceReviewManagement: "電商評論管理",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "社媒評論篩選器",
        allComments: "全部評論",
        socialMediaReviewManagement: "社媒評論管理",
      },
    },
    conversationAnalysisDataAnalysis: { competitionAnalysis: "競品分析" },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "頭像",
        realname: "姓名",
        nickname: "暱稱",
        name: "姓名",
        email: "賬號(郵箱)",
        mobile: "電話",
        agentId: "工號",
        position: "崗位",
        role: "角色",
        actions: "操作",
        createUser: "新增員工",
        editUser: "編輯員工",
        account: "賬號",
        originPassword: "初始密碼",
        newPassword: "新密碼",
        resumePassword: "確認密碼",
        userGroup: "員工組",
        authorizationGroup: "權限組",
        resetPassword: "重置密碼",
      },
      userGroups: {
        id: "ID",
        name: "名稱",
        userCount: "員工數",
        actions: "操作",
        createUserGroup: "新增員工組",
        editUserGroup: "編輯員工組",
        member: "成員",
      },
      role: {
        id: "ID",
        name: "名稱",
        actions: "操作",
        all: "全部",
        taskName: "任務",
        readTask: "任務檢視",
        manageTemplate: "模板管理",
        manualInspection: "人工復檢",
        readResultAll: "結果查看",
        readResultPersonal: "結果檢視(個人)",
        readProcess: "質檢進程檢視",
        readReport: "統計同報表",
      },
    },
    download: {
      downloadCenter: {
        createTime: "日期",
        taskName: "名稱",
        size: "大小",
        status: "狀態",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "名稱",
            remark: "描述",
            actions: "操作",
          },
          checkPoint: {
            name: "名稱",
            remark: "描述",
            type: "類型",
            gradeType: "評價方式",
            conditionLogic: "規則",
            predeterminedScore: "分值",
            weight: "權重",
            status: "狀態",
            actions: "操作",
            switchOn: "啟用",
            switchOff: "停用",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "時間",
            customer: "客户",
            customerPhone: "客户電話",
            callType: "通話類型",
            source: "來源",
            voiceRecording: "通話錄音",
            graded: "評分",
            openingGreeting: "開場問候",
          },
          checkPointList: {
            name: "質檢點",
            type: "類型",
            deductScore: "分數",
            isHit: "命中情況",
            status: "複核",
            finalDeductScore: "確認扣分",
            hit: "命中",
            notHit: "未命中",
          },
        },
      },
    },
    custom: { keywords: "呢個對話呢關鍵詞" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "系統設置" },
      systemIntegration: {
        text: "系統集成",
        subTitle: {
          udesk: "Udesk客服系統",
          udeskWhite: "客服系統",
          wechat: "企業微信",
          taobao: "淘寶千牛",
          weifeng: "微豐",
        },
      },
      columnsManage: { text: "質檢字段管理" },
      customerField: { text: "客户字段" },
      speechRecognition: { text: "語音識別" },
      user: { text: "員工管理" },
      userGroups: { text: "員工組管理" },
      role: { text: "角色管理" },
      dataMigration: { text: "資料遷移" },
      taskCenter: { text: "任務中心" },
      recordUpload: { text: "本地錄音上傳" },
      anomalousData: { text: "異常資料" },
      systemLog: { text: "消息日誌" },
      msgCenter: { text: "消息中心" },
      wechatDocking: { text: "企微對接" },
    },
    tasks: {
      homePage: { text: "首頁" },
      workbench: { text: "工作台" },
      semanticIntelligence: { text: "語義智能" },
      intelligentAnalysis: { text: "智能分析" },
      intelligentLabel: { text: "智能標籤" },
      admin: { text: "管理" },
      callManage: { text: "通話管理" },
      dialogueManage: { text: "對話管理" },
      ticketManage: { text: "工單管理" },
      dataAnalysis: { text: "資料分析" },
      customerService: { text: "客服中心" },
      clientCenter: { text: "客户中心" },
      insight: { text: "Insight" },
      trainingCenter: { text: "訓練中心" },
      templates: { text: "模板管理" },
      wechatQaTasks: { text: "企業微信工作台" },
      wechatQaSetting: { text: "企業微信分析配置" },
      wechatQaChartRecord: { text: "企業微信會話" },
      wechatStaffCenter: { text: "企微員工中心" },
      callAnalysis: { text: "會話分析" },
      caseBaseFolder: { text: "案例庫" },
      coachTask: { text: "輔導任務" },
      teamAnalysis: { text: "團隊分析" },
      customer: { text: "客户中心" },
      analysisEnable: { text: "分析賦能" },
      analysisHelp: { text: "求助分析" },
      analysisStudy: { text: "學習分析" },
      analysisReview: { text: "復盤分析" },
      analysisTags: { text: "智能標籤分析" },
      analysisSentiment: { text: "輿情分析" },
      analysisBusiness: { text: "業務分析" },
      groupTest: { text: "分組測試" },
    },
    navBar: {
      billingCenter: "幫助中心",
      uploadDownload: "上傳下載中心",
      setting: "系統設置",
    },
  },
  fix: {
    selectType: "選擇類型:",
    all: "全部",
    call: "通話",
    text: "對話",
    wechat: "企業微信會話",
    businessTrendAnalysis: "一級分類業務趨勢分析",
    noEchart: "圖表暫無資料",
    checkPointisRequired: "規則設定為必填項",
    voiceDownLoad: "下載錄音",
    exportTxt: "導出TXT文本",
    exportWord: "導出word文本",
    sureReRole: "確認反轉説話人嘅角色呀?",
    sureRecover: "確認重新糾錯呀?",
    sureReAsr: "確認重新識別嗎?",
    reRole: "反轉説話人",
    recover: "重新糾錯",
    reAsr: "重新識別",
    recordingOperation: "錄音操作",
    onlySave30Day: "(僅保留30天)",
    appealError: "請先對質檢點進行申訴再提交",
    exportToTaskCenter: "已經成功導出到上傳下載中心",
    caseBaseLimit: "案例庫唔能超過50個",
    unnamed: "未命名",
    wordSpeed: "字/分",
    hit: "命中",
    checkPoint: "質檢點",
    realTimeAdditionTip1: "實時添加:資料進入質檢系統時立即開始質檢",
    realTimeAdditionTip2: "定時添加:定時自動添加資料進行質檢",
    null: "無",
    collcetedProps: "採集字段",
    editingNameNow: "有名稱正喺編輯",
    upToFourLevels: "分類最多四級",
    default: "默認",
    nameCantBeNull: "名稱唔能為空",
    editingNow: "有操作正喺編輯",
    nameError: "名稱有誤",
    nameMaxLength8: "名稱最大長度為8",
    callTime: "通話時間",
    sentimentValue: "客户情緒值",
    agentSentimentValue: "客服情緒值",
    sentimentAnalysis: "情緒分析",
    associatedData: "隨路資料",
    notifyTheOwner: "通知群主",
    notifyTheEmployee: "通知員工本人",
    notifyTheOwnerTip: "當會話類型為班傾,才會通知群主",
    customNotifyEmployee: "自定義通知員工",
    customNotifyEmployeeTip: "觸發人員為員工,則通知消息歸屬嘅員工本人",
    clickToLearnMore: "點擊了解更多",
    repeatMarkingTip: "該條記錄已經畀該質檢點標註, 無需重複標註",
    successfulEvaluation: "評價成功",
    evaluationFailed: "評價失敗",
    qualityInspectionScore: "質檢評分",
    semanticTags: "語義標籤",
    smartTags: "智能標籤",
    collect: "採集信息",
    appealRecord: "申訴記錄",
    satisfy: "滿意",
    notSatisfy: "唔滿意",
    appealPlaceHolder: "請輸入申訴原因",
    giveUp: "放棄",
    rejectReasonPlaceHolder: "請輸入駁回理由",
    reject: "駁回",
    changeRejectReason: "修改駁回原因",
    pass: "透過",
    revise: "修改",
    machineEvaluation: "機器評價",
    poor: "差",
    excellent: "好",
    moderate: "一般",
    mustMarked: "該質檢點必須進行標註",
    pleaseChoose: "請選擇",
    manualEvaluation: "人工評價",
    qualityInspectionTemplate: "適用質檢模板",
    collectionTemplate: "適用採集模板",
    keywordsWordCloud: "關鍵詞詞雲",
    editCategoryLabel: "編輯分類標籤",
    justCall: "僅睇通話",
    justConversation: "僅睇對話",
    batchOperation: "批量操作",
    customerServicesGroup: "客服組",
    joinGroup: "加入組",
    removeGroup: "移出組",
    enterInformationEntity: "請輸入信息實體內容",
    enterNotBeNull: "輸入唔能為空",
    operationGroup: "操作組",
    customerServiceSelected: "已經選客服",
    callAndConversation: "通話/對話",
    conversation: "對話",
    system: "系統",
    customize: "自定義",
    effective: "有效",
    invalid: "無效",
    successfulAndToTaskCenter: "執行成功,請到任務中心檢視",
    recalculationFailed: "重算失敗",
    selectRecalculatedIndicator: "請選擇要重算嘅指標",
    source: "請選擇資料源",
    placeholder: "請輸入關鍵詞",
    cumulative: "累計型",
    average: "平均型",
    distribution: "分佈律",
    originalDataCannotEmpty: "原始資料唔能為空",
    inspection: "質檢",
    check: "抽檢",
    review: "複核",
    draftSavedSuccessfully: "草稿保存成功",
    optimization: "優化",
    agentName: "客服名稱",
    allTasks: "全部質檢任務",
    datePickerSelect: "選擇日期",
    callSelect: "選擇對話",
    taskSelect: "選擇任務",
    genReport: "生成報告",
    dataSource: "資料來源",
    callsTotal: "通話總量",
    intelligentQualityInspection: "智能質檢",
    percentage: "佔比",
    artificialSampling: "人工抽檢",
    appealNumber: "申訴量",
    totalConversation: "對話總量",
    samplingReviewRate: "抽復檢率",
    data: "資料",
    inspectionResults: "質檢結果",
    inspected: "已經質檢",
    totalScore: "總分",
    learnMore: "檢視多啲",
    inspectionEffect: "質檢效果",
    allTotal: "總量",
    manualInspection: "人工復檢",
    artificialReview: "人工複核",
    approvalAmountAndRate: "核准量&核准率",
    customerScore: "客服得分",
    inspectionRecommendations: "質檢建議",
    saveDraft: "保存草稿",
    historicalAdvice: "歷史建議",
    advicePlaceHolder: "請輸入建議...",
    upload: "文件上傳",
    customAdd: "添加篩選",
    advancedConditions: "高級條件",
    keyWord: "關鍵詞",
    containsAll: "包含全部",
    containsAny: "包含任意",
    matchAny: "匹配任意",
    matchAll: "匹配全部",
    smartTagsOne: "智能標籤一",
    smartTagsTwo: "智能標籤二",
    season: "季",
    month: "月",
    week: "週",
    day: "日",
    hour: "時",
    notMoreThan366: "時間唔能超過366天",
    propsCannotBeNull: "有字段未填寫",
    other: "其他",
    correlationCoefficient: "相關係數",
    repetitionRate: "重複率",
    configurationItemsMoreThan20: "配置項最多添加20個",
    conversionAnalysis: "轉化分析",
    correlationAnalysis: "相關性分析",
    successfulExportAndToTaskCenter: "導出成功,請前往任務中心檢視",
    typeSelect: "選擇類型",
    exportExcel: "導出excel",
    exportPng: "導出png",
    noDataChart: "圖表暫無資料",
    placeOperator: "請選擇智慧標籤",
    placeAdvancedConditions: "請選擇高級條件",
    createAdvancedConditions: "新建高級篩選",
    moveUp: "上移",
    moveDown: "下移",
    moveLeft: "左移",
    moveRight: "右移",
    statusChangeSuccess: "狀態修改成功",
    editSuccess: "修改成功",
    saveSuccess: "保存成功",
    firstPage: "首頁",
    lastPage: "尾页",
    customerInfo: "客户信息",
    type: "類型",
    NumberTypeErrorMessage: "數字類型嘅字段信息內容只能包含數字或“.”",
    taskStartPrompt: "任務開啟提示",
    startConfirm: "請點擊確認撳掣開啟質檢任務",
    continueToSubmitWithoutEvaluation: "唔進行評價繼續提交",
    rejectTip: "冇檢測到變更操作, 你確認直接駁回所有申訴嗎?",
    unmarkedSelfLearningTip: "你有未識認嘅自學習質檢點",
    markedSuccessfully: "標註成功",
    markingFailed: "標註失敗",
    exceedingLength: "超出長度",
    uncategorized: "未分類",
    addSuccess: "新增成功",
    deleteSuccess: "刪除成功",
    featureName: "要素名稱",
    informationEntity: "信息實體",
    standardAnswer: "標準答",
    keyElementAnswer: "關鍵要素答",
    generalStatement: "一般陳述(無需詢問)",
    generalQuestionsAndConfirmation: "一般疑問同確認",
    specialQuestionsAndAnswers: "特殊疑問同答",
    agent: "客服",
    customer: "客户",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "關鍵要素 語義標籤同信息實體唔能同時為空",
    standardSentence: "標準句",
    acquaintance: "相識度",
    smartUePlaceholder: "請輸入智能交互名稱",
    typeSelectPlaceholder: "請選擇類型",
    keyElement: "關鍵要素",
    reQuerySementicTags: "重新選擇語義標籤",
    querySementicTags: "選擇語義標籤",
    QATestForm: "問答檢測形式",
    selectNotBeNull: "選擇唔能為空",
    featureDetectionTarget: "要素檢測目標",
    categorized: "分類",
    effectiveness: "有效性",
    hideAdvancedSettings: "隱藏高級設置",
    showAdvancedSettings: "顯示高級設置",
    generalStatementOnly: "一般陳述",
    generalQuestions: "一般疑問",
    affirmativeExpression: "肯定表達",
    negativeIntention: "否定意圖",
    searchSementicTags: "查詢語義標籤",
    choiceQuestion: "選擇型疑問句",
    selectiveAnswer: "選擇型答",
    sementicTagsPlaceholder: "請輸入語義標籤內容",
    openSpecialQuestion: "開放型特殊疑問",
    openAnswer: "開放型答",
    customQA: "自定義問答",
    questions: "問法",
    dataPlaceholder: "請輸入內容",
    reply: "回答",
    notFindSuitableLabel: "未搵到合適嘅標籤",
    addSemanticTag: "添加一個新嘅語義標籤",
    intelligentInteraction: "智能交互",
    matching: "匹配",
    bout: "次",
    keywordMatch: "關鍵詞匹配",
    addFilterConditions: "添加篩選條件",
    buttonStartCollectionTask: "請點擊肯定撳掣開啟採集任務",
    buttonStartTask: "請點擊肯定撳掣開啟質檢任務",
    ruleConfigurationIsRequired: "規則設定為必填項",
    asrRunning: "ASR執行",
    errorCorrectionExecution: "糾錯執行",
    emotionalVolumeRecognition: "情緒音量識別",
    dataPreprocessing: "資料預處理",
    inspectionExecution: "質檢執行",
    businessId: "業務Id",
    businessTime: "業務時間",
    createTime: "創建時間",
    abnormalReason: "異常原因",
    singleChat: "單傾",
    groupChat: "群聊",
    taskContent: "任務內容",
    submissionTime: "提交時間",
    startTime: "開始時間",
    endTime: "結束時間",
    runningTime: "運行耗時",
    totalAmountOfData: "總資料量",
    resultsOfThe: "執行結果",
    operator: "操作員",
    taskDetails: "任務詳情",
    executiveFunction: "執行功能",
    qualityCheckTasks: "質檢任務",
    implementationDetails: "執行詳情",
    conditionalRules: "條件規則",
    smartCondition: "智能條件",
    goThis: "移動到此",
    pleaseMarkClassification: "請標註分類",
    advancedScreening: "高級篩選",
    noDataToManipulate: "冇可以操作嘅資料",
    concept: "概念詞",
    checkPoint1: "規則配置",
    requiredFieldsCannotEmpty: "必填項唔能為空",
    rule: "規則",
    afterFixUnit: "個字",
    unmarkedSelfLearningPointsTip: "你有未識認嘅自學習質檢點",
    onlyReservedFor7Days: "(僅保留7天)",
    downloadError: "下載失敗",
    pause: "暫停",
    continue: "繼續",
    recalcScore: "僅重新核算分數(含評級)",
    recalculatePointsAndScores: "重新核算質檢點同分數",
    recalculatePointsAndScoresWithEmtry: "重新核算質檢點同分數(質檢點為空)",
    inProgress: "進行中",
    toBeExecuted: "待執行",
    executeImmediately: "立即執行",
    specialElements: "特殊要素",
    logicRule: "邏輯規則",
    every: "多次匹配",
    hitRecall: "命中召回詞",
    notHitRecall: "未命中召回詞",
    hitRecallRegular: "命中召回正則",
    notHitRecallRegular: "未命中正則",
    hitRule: "命中規則",
    notHitRule: "未命中規則",
    hitSimilarSentence: "命中相似句",
    notHitSimilarSentence: "未命中相似句",
    hitExcludeSimilarSentences: "命中排除相似句",
    notHitExcludeSimilarSentences: "未命中排除相似句",
    updateSuccess: "更新成功",
    qualify: "正則",
    recallKeyWord: "召回關鍵詞",
    grammar: "文法規則",
  },
  api: {
    putTypoDatasByIdStatusByStatus: "有效性變更失敗,請重試",
    postAutoMateTasksCaseByTaskId: "案例庫同步配置保存失敗",
    getAutoMateTasksCaseByTaskId: "案例庫同步配置獲取失敗",
    postInspectionRemarkModelConfigs: "備註模板保存失敗",
    putInspectionRemarkModelConfigsById: "備註模板保存失敗",
    getInspectionRemarkModelConfigsById: "獲取備註模板資料失敗",
    postKnowledgeDatas: "知識庫新建失敗,請重試",
    putKnowledgeDatasById: "知識庫修改失敗,請重試",
    getLibraryFiles: "獲取文價夾列表失敗",
    getLibraryCalls: "獲取文件失敗",
    getLibraryFilesSearch: "搜索失敗",
    putLibraryFilesMoveById: "問價夾移動失敗",
    putLibraryCallsById: "文件重命名失敗!",
    postLibraryFiles: "添加文件夾失敗",
    putLibraryFilesById: "文件夾重命名失敗",
    deleteLibraryFilesById: "刪除失敗",
    deleteLibraryCallsById: "刪除失敗",
    postLibraryCalls: "添加失敗",
    getVoiceUploadResultById: "上傳結果導出失敗",
  },
};
