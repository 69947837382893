// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostIntelligentPartnerTaskFinishRecordsAdminBody,
    BaseResponseListIntelligentPartnerTaskFinishRecordFoundResponse,
} from '../../types';

/**
 * 管理员端查询学员的历史记录
 *
 * @export
 * @tags 智能陪练-历史学习数据
 * @link [POST] /intelligentPartnerTaskFinishRecords/admin
 */
export function postIntelligentPartnerTaskFinishRecordsAdmin(
    data: PostIntelligentPartnerTaskFinishRecordsAdminBody
): Promise<BaseResponseListIntelligentPartnerTaskFinishRecordFoundResponse>;
export function postIntelligentPartnerTaskFinishRecordsAdmin(
    data: PostIntelligentPartnerTaskFinishRecordsAdminBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerTaskFinishRecordFoundResponse>;
export function postIntelligentPartnerTaskFinishRecordsAdmin(
    data: PostIntelligentPartnerTaskFinishRecordsAdminBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerTaskFinishRecordFoundResponse> {
    return request<PostIntelligentPartnerTaskFinishRecordsAdminBody, never, never>(
        '/intelligentPartnerTaskFinishRecords/admin',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerTaskFinishRecordsAdmin',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-历史学习数据'],
        path: '/intelligentPartnerTaskFinishRecords/admin',
        method: 'post',
    },
];
