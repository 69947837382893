import ColumnOptionsBuilderClass from './option-builders/column';
import StackColumnOptionsBuilderClass from './option-builders/stack-column';
import LineOptionsBuilderClass from './option-builders/line';
import PieOptionsBuilderClass from './option-builders/pie';
import RingOptionsBuilderClass from './option-builders/ring';
import FunnelOptionsBuilderClass from './option-builders/funnel';
import DoubleAxisesOptionsBuilderClass from './option-builders/double-axises';
import BarOptionsBuilderClass from "./option-builders/bar";
import StackBarOptionsBuilderClass from "./option-builders/stack-bar";
import GaugeOptionsBuilderClass from "./option-builders/gauge";
import MapOptionsBuilderClass from "./option-builders/map";
import WordCloudOptionsBuilderClass from "./option-builders/word-cloud";
import ScatterPieOptionsBuilderClass from "./option-builders/custom/scatter-pie";
import RadarOptionsBuilderClass from "./option-builders/radar";
import CustomStackBarClass from "./option-builders/custom/stack-bar";
import LiquidFillClass from "./option-builders/liquid-fill";

import common from '../../../udesk/index';

/**
 *创建图表类
 */
class OptionsBuilderFactoryClass {
    getBuilder(chartType) {
        let chartTypeEnum = null;
        if (chartType instanceof common.enums.EnumItemClass) {
            chartTypeEnum = chartType;
        } else {
            let possibleEnum = common.enums.echartTypes.get(chartType);
            if (possibleEnum != null) {
                chartTypeEnum = possibleEnum;
            }
        }
        if (chartTypeEnum == null) {
            throw new Error(`\`chartType\` must be a valid value of enums \`enums.echartTypes\``);
        }
        /*eslint-disable */
        switch (chartTypeEnum) {
            case common.enums.echartTypes.pie:
                return new PieOptionsBuilderClass();
            case common.enums.echartTypes.ring:
                return new RingOptionsBuilderClass();
            case common.enums.echartTypes.funnel:
                return new FunnelOptionsBuilderClass();
            case common.enums.echartTypes.doubleAxises:
                return new DoubleAxisesOptionsBuilderClass();
            case common.enums.echartTypes.column:
                return new ColumnOptionsBuilderClass();
            case common.enums.echartTypes.stackColumn:
                return new StackColumnOptionsBuilderClass();
            case common.enums.echartTypes.line:
                return new LineOptionsBuilderClass();
            case common.enums.echartTypes.bar:
                return new BarOptionsBuilderClass();
            case common.enums.echartTypes.stackBar:
                return new StackBarOptionsBuilderClass();
            case common.enums.echartTypes.gauge:
                return new GaugeOptionsBuilderClass();
            case common.enums.echartTypes.map:
                return new MapOptionsBuilderClass();
            case common.enums.echartTypes.wordCloud:
                return new WordCloudOptionsBuilderClass();
            case common.enums.echartTypes.customScatterPie:
                return new ScatterPieOptionsBuilderClass();
            case common.enums.echartTypes.radar:
                return new RadarOptionsBuilderClass();
            case common.enums.echartTypes.customStackBar:
                return new CustomStackBarClass();
            case common.enums.echartTypes.liquidFill:
                return new LiquidFillClass()
            default:
                return new OptionsBuilderFactoryClass();
            /*eslint-enable */
        }
    }
}

export default OptionsBuilderFactoryClass;
