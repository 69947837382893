import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Card, Tag, Spin } from 'udesk-ui';
import './style.scss';
import { getCustomerTagGroups } from 'src/api/customerTagGroups';
import { CustomerTagGroupFoundResponse, SyncTaskInfoDTO } from 'src/api/types';
import { getCustomerTagGroupsSync } from 'src/api/customerTagGroups/sync';
import { getSyncTaskInfoByType } from 'src/api/syncTaskInfo/{type}';
import { getSystemModule } from 'Udesk/system/subApp';

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const [customerTagGroups, setCustomerTagGroups] = useState<CustomerTagGroupFoundResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [syncInfo, setSyncInfo] = useState<SyncTaskInfoDTO | null>(null);
    const queryCustomerTagGroups = async () => {
        setLoading(true);
        try {
            const { data = [] } = await getCustomerTagGroups();
            setCustomerTagGroups(data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };
    const getSyncTaskInfo = async () => {
        const { data } = await getSyncTaskInfoByType({
            segments: {
                type: 'WF_SYNC_WECOM_TAG',
            },
        });
        setSyncInfo(data ?? null);
    };
    useEffect(() => {
        queryCustomerTagGroups();
        getSyncTaskInfo();
    }, []);
    const syn = async () => {
        setLoading(true);
        try {
            await getCustomerTagGroupsSync({
                params: {
                    systemModule: getSystemModule(),
                }
            } as any);
            queryCustomerTagGroups();
        } catch {
            setLoading(false);
        }
    };
    return (
        <div className='wechat-intelligent-tags-page-index'>
            <div style={{ marginBottom: 16, textAlign: 'right' }}>
                <Button
                    loading={loading && { delay: 200 }}
                    onClick={syn}
                    type='default'
                    tooltip={
                        syncInfo
                            ? Udesk.utils.string.format(
                                locales.business.info.wecomTagsSyncInfo,
                                syncInfo.updateTime ?? '-',
                                syncInfo.syncNum ?? 0
                            )
                            : undefined
                    }
                >
                    {locales.labels.syn}
                </Button>
            </div>
            <div style={{ overflowY: 'auto', flex: 1 }}>
                <Spin spinning={loading} delay={200}>
                    {customerTagGroups.map((item) => {
                        return (
                            <Card type='inner' title={item.groupName}>
                                {(item.tags || []).map((tag) => {
                                    return (
                                        <Tag
                                            style={{color: tag.tagColor??'#000'}}
                                            color={tag.tagColor ? tag.tagColor + '1a' : undefined}
                                        >
                                            {tag.tagName}
                                        </Tag>
                                    );
                                })}
                            </Card>
                        );
                    })}
                </Spin>
            </div>
        </div>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
