// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    PostIntelligentPartnerAiAnalysisTextToSqlResultExportBody,
    BaseResponseVoid,
} from '../../../../../../types';

/**
 * 用户输入转SQL结果导出
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [POST] /intelligentPartner/ai/analysis/textToSql/result/export
 */
export function postIntelligentPartnerAiAnalysisTextToSqlResultExport(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultExportBody
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerAiAnalysisTextToSqlResultExport(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultExportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerAiAnalysisTextToSqlResultExport(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultExportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentPartnerAiAnalysisTextToSqlResultExportBody, never, never>(
        '/intelligentPartner/ai/analysis/textToSql/result/export',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerAiAnalysisTextToSqlResultExport',
        }
    );
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/textToSql/result/export',
        method: 'post',
    },
];
