import React from 'react';
import PropTypes from 'prop-types';

class NavBar extends React.Component {
    //#region defaultProps
    static defaultProps = {
        classNames: "",
        theme: "nav-bar-theme-default",
        navLists: [],
        pathValueField: "pathName",
        pathParamsValueField: "pathParams",
        queryParamsValueField: "queryParams",
        textValueField: "text",
        tooltipValue: "tooltipData",
        navItemClassNames: "",
        activeClassNames: "link-active",
        onChanged: null,
        CustomReactTool: null,
        CustomReactToolExportUrl: ""
    }
    static propTypes = {
        classNames: PropTypes.string,
        theme: PropTypes.string,
        navLists: PropTypes.array,
        pathValueField: PropTypes.string,
        pathParamsValueField: PropTypes.string,
        queryParamsValueField: PropTypes.string,
        textValueField: PropTypes.string,
        tooltipValue: PropTypes.string,
        navItemClassNames: PropTypes.string,
        activeClassNames: PropTypes.string,
        onChanged: PropTypes.func,
        CustomReactToolExportUrl: PropTypes.string,
    }
    //#endregion

    actions = {
        onClicked(item, event) {
            this.trigger("onChanged", item);
        }
    }
}

export default NavBar;