// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseVoid } from '../../types';

/**
 * sync
 *
 * @export
 * @tags smart-tag-ignore-controller
 * @link [POST] /smartTagIgnores/sync
 */
export function postSmartTagIgnoresSync(): Promise<BaseResponseVoid>;
export function postSmartTagIgnoresSync(
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postSmartTagIgnoresSync(
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<never, never, never>('/smartTagIgnores/sync', {
        ...options,
        method: 'post',
        __$requestCalleeName: 'postSmartTagIgnoresSync',
    });
}

export const meta = [
    { tags: ['smart-tag-ignore-controller'], path: '/smartTagIgnores/sync', method: 'post' },
];
