
import React from 'react';
import Udesk from 'Udesk';
import PropTypes from 'prop-types';

// const UNITS_LIST = ['K', 'M', 'G'];



export default class SmartWordsLibraryCommonComponent extends React.Component {
    static defaultProps = {
        getCommonListPath: '',//获取列表数据路径    
        navItemType: null,// 组件类别:1同义词 2专业名词 3关注词 4忽略词 5词权重 6语料数据
        navItemName: '', //组件名称
        uploadModalTitle: '',//组件上传模板title,
        boardName: ''//上传时,所需下载模板的名字
    };
    static propTypes = {
        getCommonListPath: PropTypes.string,
        navItemType: PropTypes.number,
        navItemName: PropTypes.string,
        uploadModalTitle: PropTypes.string,
        boardName: PropTypes.string,
    };

    // #region privates
    privates = {
        isCommonWordsModalShow: false,
        commonWordsModalTitle: '',
        corporaName: '',
        corporaStatus: '1',
        commonWordsId: '',
        smartWordsLibraryListInstance: null
    };
    //#endregion

    // #region cycles
    init() {

        let columns = this.getColumns();

        this.privates.columns = columns;
        // this.actions.update();
    }
    componentWillUnmount() {
        this.privates.smartWordsLibraryListInstance = null;
    }
    //#endregion

    // #region actions
    actions = {

        createCommonWords() {

            let {
                locales
            } = this;

            let {
                navItemName
            } = this.props;

            this.privates.isCommonWordsModalShow = true;
            this.privates.commonWordsModalTitle = locales.components.pages.smartWordsLibrary[navItemName].create;
            this.actions.update();
        },
        editCommonWords(id) {

            let {
                locales
            } = this;

            let {
                navItemName,
                sdkOptions
            } = this.props;

            let url = Udesk.business.apiPath.concatApiPath(`corpora/common/${id}`, sdkOptions);

            Udesk.ajax.get(url).then(
                (resp, textStatus, jqXHR) => {
                    let responseItem = $.extend(true, {}, resp.data);
                    this.privates.isCommonWordsModalShow = true;
                    this.privates.commonWordsModalTitle = locales.components.pages.smartWordsLibrary[navItemName].edit;
                    this.privates.corporaName = responseItem.corporaName;
                    this.privates.corporaStatus = responseItem.corporaStatus;
                    this.privates.commonWordsId = responseItem.id;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.smartWordsLibrary[navItemName].deleteErr);
                });
        },
        closeCommonWordsModal() {
            this.privates.corporaName = '';
            this.privates.corporaStatus = '1';
            this.privates.isCommonWordsModalShow = false;
            this.privates.commonWordsId = '';
            this.actions.update();
        },
        deleteCommonWords(id) {

            let that = this;

            let {
                sdkOptions
            } = this.props;

            let {
                navItemName
            } = this.props;

            Udesk.ui.alerts.warning({
                content: this.locales.labels.deleteContent,
                onOk: () => {
                    Udesk.ajax.del(
                        `${Udesk.business.apiPath.concatApiPath(`corpora/${id}`, sdkOptions)}`
                    ).then(
                        (resp, textStatus, jqXHR) => {
                            Udesk.ui.notify.success(this.locales.components.pages.smartWordsLibrary[navItemName].deleteSuccess);
                            that.privates.smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.smartWordsLibrary[navItemName].deleteError);
                        }
                    );
                }
            });
        },
        saveCommonWords() {

            let isEdit = false;

            if (this.privates.commonWordsId) {
                isEdit = true;
            }

            this.props.form.validateFields((err, values) => {
                if (!err) {

                    let url = '';

                    let {
                        locales
                    } = this;

                    let {
                        sdkOptions,
                        navItemName
                    } = this.props;

                    let params = Object.assign(
                        {},
                        values,
                        {
                            corporaType: this.props.navItemType
                        }
                    );

                    let title = isEdit ? locales.components.pages.smartWordsLibrary[navItemName].editSuccess : locales.components.pages.smartWordsLibrary[navItemName].createSuccess;

                    let requestType = isEdit ? 'put' : 'post';

                    if (isEdit) {
                        let id = this.privates.commonWordsId;
                        url = Udesk.business.apiPath.concatApiPath(`corpora/common/${id}`, sdkOptions);
                    } else {
                        url = Udesk.business.apiPath.concatApiPath('corpora/common', sdkOptions);
                    }

                    let that = this;

                    Udesk.ajax[requestType](url, params).then(
                        (resp, textStatus, jqXHR) => {
                            that.actions.closeCommonWordsModal();
                            Udesk.ui.notify.success(title);
                            that.privates.smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || that.locales.components.pages.smartWordsLibrary[navItemName].deleteErr);
                        });

                } else {
                    return;
                }
            });
        },
        changeCommonWordsEffectiveness(value) {
            this.privates.corporaStatus = value;
            this.actions.update();
        },
    };
    //#endregion

    // #region public methods used "this":
    getColumns() {
        let { locales } = this;

        let {
            navItemName
        } = this.props;

        let columns = [
            {
                name: "corporaName",
                width: "10%",
                caption: locales.fields.smartWordsLibrary[navItemName]
            },
            // {
            //     name: "corporaRate",
            //     width: "10%",
            //     caption: locales.fields.smartWordsLibrary.common.useFrequency,
            //     hasTemplate: true,
            //     getYieldContent: (name, item, index) => {
            //         return (
            //             <span>
            //                 {Udesk.business.smartWordsMethods.getFormatFrequency(item.corporaRate, UNITS_LIST)}
            //             </span>
            //         );
            //     }
            // },
            {
                name: "corporaStatus",
                width: "10%",
                caption: locales.fields.smartWordsLibrary.common.effectiveness,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    let targetItem = Udesk.enums.wordsEffectiveness.get(item.corporaStatus);
                    return (
                        <span>
                            {targetItem.name}
                        </span>
                    );
                }
            },
            {
                name: "operation",
                width: "15%",
                caption: locales.fields.smartWordsLibrary.common.operation,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return (
                        <div className="smart-words-library-professional-words-operation">
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:edit")}>
                                <span className="smart-words-library-operation-edit" title={locales.labels.edit} onClick={this.actions.editCommonWords.params(item.id)}>
                                    {locales.labels.edit}
                                </span>
                            </If>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:delete")}>
                                <span className="smart-words-library-operation-delete" title={locales.labels.delete} onClick={this.actions.deleteCommonWords.params(item.id)}>
                                    {locales.labels.delete}
                                </span>
                            </If>
                        </div>

                    );
                }
            }
        ];
        return columns;
    }
}