import React, { useState, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { Button, Divider, Empty, Icon, Space, Tag, Popconfirm, Dropdown } from 'udesk-ui';
import { AddOutlined } from '@udesk/icons';
import { ClientAction, EmployeeAction, Card, CountTag, ListItem } from './components';
import SmartTagsList from '../smart-tags-list';
import SessionSummary from './components/SessionSummary';
import Opportunity from './components/Opportunity';
import styled from 'styled-components';
import { postSmartTagsFindAll } from 'src/api/smartTags/findAll';
import {
    postSmartTagsReviewRelatedCall,
    deleteSmartTagsReviewRelatedCall,
} from 'src/api/smartTags/review/related/call';
import { hasFeature } from 'src/util/permit';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

const DrawerWrapper = styled.div`
    /* position: relative; */
    /* overflow: hidden; */
    margin: 0px -16px 16px;
    padding: 0 16px;
    flex: 1;
    overflow: auto;
`;
const DrawerTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 16px;
    display: flex;
    align-items: center;
`;
const DrawerTotal = styled.div`
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
    span {
        color: #1a6eff;
    }
`;
const ListWrapper = styled.div`
    overflow-y: auto;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
    margin: -16px -16px 16px -16px;
`;

const Template = React.memo((props: any) => {
    const locales = UdeskLocales['current'];
    const {
        insightInfo,
        keyEvents,
        onClick,
        tags,
        customer,
        user,
        // knowledgePoint,
        /* onCaseFragment, */ keyEventTag,
        onKeyEventTagClick,
        callId,
        onReset,
    } = props;

    const [detailVisible, setDetailVisible] = useState<false | 'keyEvents' | 'agent' | 'customer'>(
        false
    );
    const [indices, setIndices] = useState<any[]>([]);

    const agentInfo = useMemo(() => {
        if (!insightInfo) return {};
        if (props.isWechatIm) {
            return {
                imCount: insightInfo.agentImCount,
                imPercentage: insightInfo.agentImPercentage,
                sessionType: insightInfo.sessionType,
                agentLongestAnswerIntervalDuration: insightInfo.agentLongestAnswerIntervalDuration,
            };
        }
        return {
            longestSpeechDuration: insightInfo.agentLongestSpeechDuration,
            questionFrequency: insightInfo.agentQuestionFrequency,
            speechPercentage: insightInfo.agentSpeechPercentage,
            interceptCount: insightInfo.agentInterceptCount,
            // chanelType:1 //改字段为区分企业类型和回话类型的字段和后端约定的
        };
    }, [insightInfo]);

    const customerInfo = useMemo(() => {
        if (!insightInfo) return {};
        if (props.isWechatIm) {
            return {
                imCount: insightInfo.customerImCount,
                imPercentage: insightInfo.customerImPercentage,
                sessionType: insightInfo.sessionType,
                customerLongestSpeechDuration: insightInfo.customerLongestSpeechDuration,
                customerGroupChatInteractivePercentage:
                    insightInfo.customerGroupChatInteractivePercentage,
            };
        }
        return {
            longestSpeechDuration: insightInfo.customerLongestSpeechDuration,
            questionFrequency: insightInfo.customerQuestionCount,
            speechPercentage: insightInfo.customerSpeechPercentage,
            // chanelType:1  //改字段为区分企业类型和回话类型的字段和后端约定的
        };
    }, [insightInfo]);

    const [sessionTagList, setSessionTagList] = useState<any>([]);

    const { refresh: refreshSmartTags } = useRequest(
        () =>
            postSmartTagsFindAll({
                pageNum: 1,
                pageSize: 1000,
                tagStatus: 1,
                tagType: 1,
                funcType: 'REVIEW_CALL',
            }),
        {
            onSuccess: (res) => {
                if (res.data) {
                    let smartTagsList = res.data.filter((i) => !!i.tagStatus);
                    let smartTagsGroupsNameMap = {};
                    let smartTagsGroups = Array.from(
                        new Set(
                            smartTagsList.map((i) => {
                                !smartTagsGroupsNameMap[`${i.categoryId}`] &&
                                    (smartTagsGroupsNameMap[`${i.categoryId}`] = i.categoryName);
                                return i.categoryId;
                            })
                        )
                    );
                    const list = smartTagsGroups.map((i) => ({
                        categoryId: i,
                        categoryName: smartTagsGroupsNameMap[`${i}`],
                        children: smartTagsList.filter((tag) => tag.categoryId === i),
                    }));
                    setSessionTagList(list);
                }
            },
        }
    );

    const [sessionTagVisible, setSessionTagVisible] = useState(false);

    const onVisibleChange = () => {
        setSessionTagVisible((sessionTagVisible) => !sessionTagVisible);
    };

    const onAddSessionTagById = (tagId) => {
        setSessionTagVisible(false);
        if (tagId) {
            postSmartTagsReviewRelatedCall({ callId, tagIds: [tagId] }).then(() => {
                onReset();
            });
        }
    };

    const [searchTagName, setSearchTagName] = useState<string>();

    const onSessionTagChange = (value?: string) => {
        setSearchTagName(value);
    };

    const onAddSessionTagByName: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter' && searchTagName) {
            setSessionTagVisible(false);
            postSmartTagsReviewRelatedCall({ callId, tagName: searchTagName }).then(() => {
                setSearchTagName('');
                onReset();
                refreshSmartTags();
            });
        }
    };

    const onDeleteSessionTag = (tagId: number) => {
        deleteSmartTagsReviewRelatedCall({ callIds: [callId], tagId }).then(() => {
            onReset();
        });
    };

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Title>
                {/* 会话洞察 */}
                {locales.components.sessionInsights.index.conversationalInsights}
            </Title>
            <DrawerWrapper>
                {detailVisible ? (
                    <>
                        <DrawerTitle>
                            <Button
                                type="text"
                                size="small"
                                onClick={() => setDetailVisible(false)}
                                icon={<Icon type="LeftOutlined" antdIcon={true} />}
                            ></Button>
                            {detailVisible === 'keyEvents'
                                ? /* 关键事件 */ locales.components.sessionInsights.index.keyEvents
                                : /* 提问分析 */ locales.components.sessionInsights.index
                                      .questionAnalysis}
                        </DrawerTitle>
                        <DrawerTotal>
                            {detailVisible === 'keyEvents' ? (
                                keyEvents?.length ? (
                                    <>
                                        {/* 发现 */}
                                        {locales.components.sessionInsights.index.find}
                                        <span>{keyEvents?.length}</span>
                                        {/* 个关键事件 */}
                                        {locales.components.sessionInsights.index.numKeyEvents}
                                    </>
                                ) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )
                            ) : indices.length ? (
                                <>
                                    {/* 发现 */}
                                    {locales.components.sessionInsights.index.find}
                                    <span>{indices?.length}</span>
                                    {/* 次提问 */}
                                    {locales.components.sessionInsights.index.questions}
                                </>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </DrawerTotal>
                        <ListWrapper>
                            {detailVisible === 'keyEvents'
                                ? keyEvents?.map((keyEvent) => {
                                      return (
                                          <ListItem
                                              {...keyEvent}
                                              backgroundColor="#F9F9F9"
                                              onClick={(...args) =>
                                                  onClick(...args, keyEvent.sentencesIndices?.[0])
                                              }
                                          />
                                      );
                                  })
                                : indices.map((indice) => {
                                      return (
                                          <ListItem
                                              {...indice}
                                              onClick={(...args) =>
                                                  onClick(...args, indice.indice + 1)
                                              }
                                              bordered={true}
                                          />
                                      );
                                  })}
                        </ListWrapper>
                    </>
                ) : (
                    <>
                        <Card
                            title={
                                /* 会话动作 */ locales.components.sessionInsights.index
                                    .conversationAction
                            }
                        >
                            <ClientAction
                                info={customerInfo}
                                userName={
                                    customer.nickname ??
                                    /* 客户 */ locales.components.sessionInsights.index.customer
                                }
                                avatarUrl={customer.headPortraitUrl}
                                isWechatIm={props.isWechatIm}
                            />
                            <Divider style={{ margin: '16px 0' }} />
                            <EmployeeAction
                                info={agentInfo}
                                userName={
                                    user.realname ??
                                    /* 销售 */ locales.components.sessionInsights.index.sale
                                }
                                avatarUrl={user.headPortraitUrl}
                                isWechatIm={props.isWechatIm}
                            />
                            <Divider style={{ margin: '16px 0' }} />
                        </Card>

                        {hasFeature('sys:largeAi:sap:sessionSummary') ? (
                            <Card
                                title={
                                    /* 会话小结 */ UdeskLocales['current'].components
                                        .sessionInsights.index.conversationSummary
                                }
                            >
                                <SessionSummary callId={callId} />
                                <Divider style={{ margin: '16px 0' }} />
                            </Card>
                        ) : null}

                        <Card
                            title={
                                /* 关键事件 */ locales.components.sessionInsights.index.keyEvents
                            }
                        >
                            <CountTag
                                title={`${
                                    /* 关键事件 */ locales.components.sessionInsights.index
                                        .keyEvents
                                }`}
                                count={keyEvents?.length ?? 0}
                                backgroundColor="rgba(197, 228, 255, .5)"
                                onClick={() => setDetailVisible('keyEvents')}
                            />

                            {Boolean(keyEventTag.length) &&
                                keyEventTag.map((tag, idx) => {
                                    return (
                                        // <span
                                        //     style={{
                                        //         background: item.categoryColor + '1a',
                                        //         color: item.categoryColor,
                                        //         border: `1px solid ${item.categoryColor}`,
                                        //         whiteSpace: 'nowrap',
                                        //     }}
                                        //     key={item.id}
                                        //     onClick={onKeyEventTagClick.bind(null, item.sentencesIndices?.[0])}
                                        // >
                                        //     {item.categoryName} | {item.tagName}
                                        // </span>
                                        <Tag
                                            onClick={onKeyEventTagClick.bind(
                                                null,
                                                tag.sentencesIndices?.[0]
                                            )}
                                            key={tag.id}
                                            style={{
                                                marginBottom: 4,
                                                background: tag.categoryColor + '1a',
                                                color: tag.categoryColor,
                                                maxWidth: '100%',
                                                overflowX: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            color={tag.categoryColor}
                                            title={`${tag.categoryName} | ${tag.tagName}`}
                                        >
                                            <span
                                                style={{
                                                    width: '100%',
                                                    height: '100',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {tag.categoryName} | {tag.tagName}
                                            </span>
                                        </Tag>
                                    );
                                })}
                        </Card>
                        <Divider style={{ margin: '16px 0' }} />
                        <Card
                            title={
                                /* 会话场景 */ locales.components.sessionInsights.index.sessionLabel
                            }
                        >
                            {tags.map((tag) => (
                                <Tag
                                    key={tag.id}
                                    style={{
                                        marginBottom: 4,
                                        background: tag.categoryColor + '1a',
                                        color: tag.categoryColor,
                                        maxWidth: '100%',
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                    color={tag.categoryColor}
                                    title={tag.tagName}
                                >
                                    <Space>
                                        {tag.tagName}
                                        <Popconfirm
                                            title={/* 确定删除吗？ */UdeskLocales['current'].components.sessionInsights.index.areYouSureToDeleteIt}
                                            onConfirm={() => onDeleteSessionTag(tag.id)}
                                        >
                                            <span
                                                style={{
                                                    color: 'rgba(0, 0, 0, 0.45)',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                X
                                            </span>
                                        </Popconfirm>
                                    </Space>
                                </Tag>
                            ))}
                            <Dropdown
                                onVisibleChange={onVisibleChange}
                                visible={sessionTagVisible}
                                overlay={
                                    <SmartTagsList
                                        markedSmartTags={tags || []}
                                        smartTagsList={sessionTagList}
                                        onSelect={onAddSessionTagById}
                                        onSearch={onSessionTagChange}
                                        onInputKeyDown={onAddSessionTagByName}
                                    />
                                }
                                trigger={['click']}
                            >
                                <Tag
                                    style={{
                                        cursor: 'pointer',
                                        marginBottom: 4,
                                        verticalAlign: 'top',
                                    }}
                                >
                                    <Space>
                                        <AddOutlined />{/* 添加 */}{UdeskLocales['current'].components.sessionInsights.index.add}</Space>
                                </Tag>
                            </Dropdown>
                        </Card>
                        <Divider style={{ margin: '16px 0' }} />
                        <Card
                            title={
                                /* 提问分析 */ locales.components.sessionInsights.index
                                    .questionAnalysis
                            }
                        >
                            <CountTag
                                title={
                                    /* 员工提问 */ locales.components.sessionInsights.index
                                        .employeeQuestions
                                }
                                count={insightInfo.agentQuestionIndices?.length ?? 0}
                                backgroundColor="rgba(255, 211, 225, 0.5)"
                                onClick={() => {
                                    setDetailVisible('agent');
                                    setIndices(insightInfo.agentQuestionIndices ?? []);
                                }}
                            />
                            <CountTag
                                title={
                                    /* 客户提问 */ locales.components.sessionInsights.index
                                        .customerQuestions
                                }
                                count={insightInfo.customerQuestionIndices?.length ?? 0}
                                backgroundColor="rgba(246, 214, 255, 0.5)"
                                onClick={() => {
                                    setDetailVisible('customer');
                                    setIndices(insightInfo.customerQuestionIndices ?? []);
                                }}
                            />
                        </Card>
                        <Divider style={{ margin: '16px 0' }} />

                        {hasFeature('sys:largeAi:sap:opportunity') ? (
                            <Card title={/* 机会点 */UdeskLocales['current'].components.sessionInsights.index.opportunityPoints}>
                                <Opportunity callId={callId} />
                            </Card>
                        ) : null}

                        {/* <Divider style={{ margin: '16px 0' }} />
                        <Card title='关联知识点'>
                            {knowledgePoint.length ? (
                                knowledgePoint.map((tag) => (
                                    <Tag
                                        key={tag.id}
                                        style={{
                                            marginBottom: 4,
                                            background: tag.categoryColor + '1a',
                                            color: tag.categoryColor,
                                            maxWidth: '100%',
                                            overflowX: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        color={tag.categoryColor}
                                        title={tag.tagName}
                                        onClick={() => {
                                            window.open(`/site/sale-knowledge-base/view/${tag.id}`);
                                        }}
                                    >
                                        {tag.tagName}
                                    </Tag>
                                ))
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </Card>
                        <Divider style={{ margin: '16px 0' }} />
                        <Card title='案列片段'>
                            {tags.length ? (
                                tags.map((tag) => (
                                    <Tag
                                        onClick={(e) => onCaseFragment(e, tag)}
                                        key={tag.id}
                                        style={{ marginBottom: 4 }}
                                        color={tag.categoryColor}
                                    >
                                        <span style={{ width: '100%', height: '100', cursor: 'pointer' }}>
                                            {tag.tagName}
                                        </span>
                                    </Tag>
                                ))
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </Card> */}
                    </>
                )}
            </DrawerWrapper>
        </div>
    );
});

class SessionInsights extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(SessionInsights);
