import Udesk from 'Udesk';
import TasksCheckPointCreateEdit from '../../../manage/template/check-point/edit/route';

function extend(object, attrs) {
    Reflect.ownKeys(attrs).forEach((key) => {
        Reflect.set(object, key, attrs[key]);
    });
    return object;
}

class ApproveTasksCheckPointView extends TasksCheckPointCreateEdit {
    constructor(props) {
        super(props);

        extend(this.actions, {
            transitionToCheckPointIndex: () => {
                let taskId = this.privates.model.taskId;
                let routeOptions = {
                    history: this.props.history,
                    routeName: 'approveTasksCheckPointIndex',
                    pathParams: {
                        taskId: taskId,
                        approveId: props.match.params.approveId,
                        approveStatus: props.match.params.approveStatus,
                    },
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        });
    }
}

export default Udesk.react.udeskify(ApproveTasksCheckPointView);
