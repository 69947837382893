import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { PageHeader /*DynamicForm as CreateForm */ } from 'udesk-ui';
// import { CreateFromProps } from 'udesk-ui';

import CreateForm, { CreateFromProps } from 'Component/common/create-form';
import cusConfigMap from '../../../../../common/config/form-config/cus-component-map';
import './style.scss';
import { getCallWeComConfigs, postCallWeComConfigs } from 'src/api/callWeComConfigs';
import { putCallWeComConfigsById } from 'src/api/callWeComConfigs/{id}';

export default function ConversationSetting(props) {
    const locales = Locales['current'];

    const [initialValues, setInitialValues] = useState<any>({
        splitType: Udesk.enums.segmentationStrategy.byTime.id,
        executeTime: '23:59',
        standardScore: 60,
        configJson: {
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        },
    });
    const staticQuery = {
        params:
            props.route.name === 'sessionSetings'
                ? {
                    sysName: 'review',
                }
                : undefined,
    };
    const onFinish = (values) => {
        let xhr: Promise<any> | null = null;
        let params = { ...values };
        if (params.executeTime) {
            // if(Array.isArray(params.executeTime)){
            //     params.executeTime = params.executeTime.join(':');
            // }
            params.executeTime += ':00';
        }
        if (params.configJson) {
            params.configJson = JSON.stringify(params.configJson);
        }
        if (initialValues?.id) {
            xhr = putCallWeComConfigsById(params, {
                segments: {
                    id: initialValues?.id,
                },
            });
        } else {
            xhr = postCallWeComConfigs(
                Object.assign(params, {
                    systemName: props.route.name === 'sessionSetings' ? 'review' : undefined,
                })
            );
        }
        xhr &&
            xhr.then(
                (resp) => {
                    if (resp?.data) {
                        let values = { ...resp.data };
                        if (values.configJson) {
                            values.configJson = JSON.parse(values.configJson);
                        }
                        setInitialValues(values);
                    }
                    Udesk.ui.notify.success(locales.business.info.saveSuccess);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
    };

    const config: CreateFromProps = {
        initialValues: initialValues,
        configMap: cusConfigMap,
        onFinish: onFinish,
        fields: [
            {
                name: 'splitType',
                label: locales.pages.wechatQa.conversationSetting.segmentationStrategy,
                field: {
                    type: 'RadioGroup',
                    props: {
                        options: Udesk.enums.segmentationStrategy.map((item) => ({ label: item.name, value: item.id })),
                        optionType: 'button',
                        // size: 'small',
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
                effect: {
                    visible: {
                        [Udesk.enums.segmentationStrategy.customize.id]: [
                            ['configJson', 'conditionList'],
                            ['configJson', 'judgeStrategy'],
                        ],
                        [Udesk.enums.segmentationStrategy.byTime.id]: ['executeTime'],
                    },
                    value: {
                        [Udesk.enums.segmentationStrategy.customize.id]: [
                            {
                                name: ['configJson', 'judgeStrategy'],
                                output: Udesk.enums.operatorRuleTypes.all.id,
                            },
                        ],
                    },
                },
            },
            {
                name: ['configJson', 'conditionList'],
                label: locales.pages.wechatQa.conversationSetting.rule,
                field: {
                    type: 'SegmentationStrategy',
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: ['configJson', 'judgeStrategy'],
                label: locales.pages.wechatQa.conversationSetting.ruleLogic,
                field: {
                    type: 'RadioGroup',
                    props: {
                        options: Udesk.enums.operatorRuleTypes
                            .filter((item) => item.id !== Udesk.enums.operatorRuleTypes.custom.id)
                            .map((item) => ({ label: item.name, value: item.id })),
                    },
                },
            },
            {
                name: 'executeTime',
                label: locales.pages.wechatQa.conversationSetting.time,
                addonBefore: <div>{locales.pages.wechatQa.conversationSetting.daily}</div>,
                field: {
                    type: 'TimePicker',
                    props: {
                        format: 'HH:mm',
                        allowClear: false,
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'standardScore',
                label: locales.pages.wechatQa.conversationSetting.passScore,
                field: {
                    type: 'InputNumber',
                    props: {
                        style: {
                            width: 175,
                        },
                    },
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            // {
            //     name: 'ProxyPassword',
            //     label: 'ProxyPassword',
            //     field: {
            //         type: 'ProxyPassword',
            //         props: {
            //             style: {
            //                 width: 175,
            //             },
            //         },
            //     },
            // },
        ],
        col: {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
        },
        btnsCol: {
            offset: 4,
            span: 16,
        },
    };
    const [wrapForm] = CreateForm.useForm();

    useEffect(() => {
        (getCallWeComConfigs as any)(staticQuery).then(
            (resp) => {
                if (resp.data) {
                    let values = { ...resp.data };
                    if (values.configJson) {
                        values.configJson = JSON.parse(values.configJson);
                    }

                    setInitialValues(values);
                }
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [props.sdkOptions]);
    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues, wrapForm]);
    return (
        <div className='udesk-qa-web-page'>
            <PageHeader className='udesk-qa-web-page-header' title={locales.pages.wechatQa.conversationSetting.title} />
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root wechat-qa-tasks-conversation-setting'>
                    <CreateForm {...config} form={wrapForm}></CreateForm>
                </div>
            </div>
        </div>
    );
}
