// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostVoiceUploadUploadBody, BaseResponseVoiceUploadResponse } from '../../types';

/**
 * upload
 *
 * @export
 * @tags native-voice-upload-controller
 * @link [POST] /voice-upload/upload
 */
export function postVoiceUploadUpload(
    data: PostVoiceUploadUploadBody
): Promise<BaseResponseVoiceUploadResponse>;
export function postVoiceUploadUpload(
    data: PostVoiceUploadUploadBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoiceUploadResponse>;
export function postVoiceUploadUpload(
    data: PostVoiceUploadUploadBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoiceUploadResponse> {
    return request<PostVoiceUploadUploadBody, never, never>('/voice-upload/upload', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postVoiceUploadUpload',
    });
}

export const meta = [
    { tags: ['native-voice-upload-controller'], path: '/voice-upload/upload', method: 'post' },
];
