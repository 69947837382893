// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListAiModelFoundResponse } from '../../types';

/**
 * 查询租户下所有有效智能模型
 *
 * @export
 * @tags ai-model-controller
 * @link [GET] /aiModels/findAll
 */
export function getAiModelsFindAll(): Promise<BaseResponseListAiModelFoundResponse>;
export function getAiModelsFindAll(
    options: ApiOptions<never, never>
): Promise<BaseResponseListAiModelFoundResponse>;
export function getAiModelsFindAll(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListAiModelFoundResponse> {
    return request<never, never, never>('/aiModels/findAll', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getAiModelsFindAll',
    });
}

export const meta = [{ tags: ['ai-model-controller'], path: '/aiModels/findAll', method: 'get' }];
