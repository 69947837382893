import React from 'react';
import PropTypes from 'prop-types';
import Udesk from '../../../udesk';
import ConditionModuleEnums from './condition-module-enums';
import ConditionModuleStatesClass from './condition-module-states-class';

class CustomFiltersComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        // 基础设置
        fields: [], // 【必填】第一个下拉框，字段数组（每个字段包含了操作符数组），支持异步数据（即Promise类型）。非受控属性，如果发生变化，可以给过滤器传入一个新的key来强制创建一个新组件实例
        fieldValueSettings: [],  // 【必填】第三列的配置，指示渲染什么样的输入组件。非受控属性，如果发生变化，可以给过滤器传入一个新的key来强制创建一个新组件实例
        conditions: [], // 【通常必填】要回显的条件数组

        // UI设置
        showConditionFieldColumn: true, // 显示或隐藏字段列。除了可以全局设置外，还支持对行级别的显示隐藏
        showConditionOperatorColumn: true, // 显示或隐藏操作符列。除了可以全局设置外，还支持对行级别的显示隐藏
        showConditionValueColumn: true, // 显示或显示值列。除了可以全局设置外，还支持对行级别的显示隐藏
        showRemovalButton: true, // 是否显示删除按钮
        showAddButton: true, // 是否显示新增按钮
        inlineAddButton: false, // 新增按钮是否以行内形式展示，放到删除按钮的后面，而不是另起一行
        showConditionHeaders: false, // 是否显示每一列的表头，更接近表格的风格
        showConditionHeadersWhenEmpty: false, // 在没有conditions数据时，是否仍然显示表头
        showConditionNumber: false, // 是否在最左侧显示行号
        showFieldsSearch: true, //字段是否支持搜索。仅实现了antdSelectComponent 的搜索。默认的Select未实现
        allowEmptyConditions: false, // 是否允许空行，默认值是false，即会自动添加一行，且最后一行不允许删除。如果希望有空条件的存在，设置为true
        autoSelectField: true, // 当添加一行时，是否自动选中第一个字段，默认是true，新行的字段、操作符和值组件都会显示出来，看起来更自然一些。如果设置为false，则新行除了字段下拉框其它都是空的
        defaultField: null, // 如果不希望总是默认选择第一个，可以设置一个默认的字段，添加新行时自动使用此字段作为默认值
        displayFieldSectionAsText: false, // 是否把字段设置为文本模式，即禁用字段的选择。这种模式下会自动设置为禁用的样式，如果不需要，可以在项目内重写相关的css样式
        disabled: false, // 是否禁用整个组件。除了可以全局设置外，还支持对行级别的禁用
        theme: 'default', // 主题风格
        antdStyle: false, // 是否启用AntDesign风格，字段和操作符下拉框会替换成antd的Select
        antdSelectComponent: null, // 传入antd的Select组件，一般要使用udesk-ui中的Select。自定义过滤器组件不内置Antd。
        enableValidation: true, // 是否启用验证，当值输入错误时，会在底部显示错误消息

        conditionPrefixColumnHeader: '', // 前缀列的表头文字，默认为空
        conditionFieldColumnHeader: '', // 字段列的表头文字，默认为空
        conditionOperatorColumnHeader: '', // 操作符列的表头文字，默认为空
        conditionValueColumnHeader: '', // 值列的表头文字，默认为空
        conditionPostfixColumnHeader: '', // 后缀列的表头文字，默认为空
        conditionRemovalColumnHeader: '', // 删除按钮列的表头文字，通常保持为空
        fieldTextPrefix: '', // 给每个字段的文字前面添加统一的前缀
        fieldPrefixClass: '', // 每一行前缀内容的className
        fieldSelectorClass: '', // 每一行字段下拉框的className
        operatorSelectorClass: '', // 每一行操作符下拉框的className
        valueWrapperClass: '', // 每一行值组件（单值）父容器的className
        rangeWrapperClass: '', // 每一行值组件（区间多值）父容器的className
        rangeFromText: '', // 区间组件的左侧组件前面的文本，可以配置成“从”，以实现类似 从___到___ 的效果
        rangeFromTextClass: '', // 左侧文本的className
        rangeFromClass: '', // 每一行区左侧组件（区间多值）父容器的className
        rangeToText: '', // 区间组件的右侧组件前面的文本，可以配置成“到”，以实现类似 从___到___ 的效果
        rangeToTextClass: '', // 每一行区右侧组件（区间多值）父容器的className
        rangeToClass: '', // 每一行区右侧组件（区间多值）父容器的className
        fieldPostfixClass: '', // 每一行后缀内容的className
        fieldNotExistsErrorMsg: '', // 如果选择的字段在fields集合中不存在的话（一般是在自定义了字段组件时可能会发生，默认组件不会出现这种情况），可自定义错误消息
        operatorNotExistsErrorMsg: '', // 同上，自定义操作符不存在时的错误消息
        fieldsLoadingText: '', // 如果props.fields是异步的话，支持显示一段指定的loading文字
        fieldsLoadingErrorMsg: '', // 如果props.fields是异步的话，且加载失败的话，可自定义错误消息
        validationSummary: '', // 自定义组件底部的validation消息的header文本，不传入的话则使用默认文本
        dataSourceLoadingErrorMsgFormat: '', // 如果value组件的dataSource加载失败，则可以自定义错误消息内容。使用 {0} 占位符来表示本行选择的字段名称
        rejectReasonMessageField: 'errorMsg', // 如果在异步获取dataSource或fields数组时，Promise失败了，自动从reject的reason对象上查找这个字段，如果能找到的话就显示此错误消息。优先级更高一些。


        conditionFieldField: 'field', // 自定义输出condition对象中的所选字段的字段名，不设置则使用默认值
        conditionOperatorField: 'operator', // 自定义输出condition对象中的所选操作符的字段名，不设置则使用默认值
        conditionValueField: 'value', // 自定义输出condition对象中组件值的字段名，不设置则使用默认值
        conditionPrefixField: 'prefix', // 自定义输出condition对象中的前缀组件值的字段名，不设置则使用默认值。如果不使用前缀的话，可以忽略
        conditionPostfixField: 'postfix', // 自定义输出condition对象中的后缀组件值的字段名，不设置则使用默认值。如果不使用后缀的话，可以忽略
        conditionRemovableField: 'removable', // 可以在condition对象上添加一个布尔标识，控制此行是否可以被删除。而此属性则是自定义字段名，不设置则使用默认值
        fieldInputTypeField: 'inputType', // 自定义设置field对象中 inputType 枚举值的字段名
        fieldOperatorsField: 'operators', // 自定义设置field对象中操作符数组的字段名
        fieldDataSourceField: 'dataSource', // 自定义设置field对象中字段下拉数据源的字段名
        fieldAttributesField: 'attributes', // 自定义设置值组件自定义attributes的字段名
        fieldDefaultValueField: 'attributes.defaultValue', // 自定义如何获取值组件默认值的字段名
        valueSettingsInputTypeField: 'inputType', // 自定义components配置文件中存储 inputType 的字段名
        valueSettingsOperatorsField: 'operators', // 自定义components配置文件中存储 操作符下拉数组 的字段名
        valueSettingsComponentField: 'component', // 自定义components配置文件中存储 值组件 的字段名
        valueSettingsAttributesField: 'attributes', // 自定义components配置文件中存储 值组件attributes 的字段名
        operatorIdField: 'id', // 自定义operator枚举值的字段名
        operatorNameField: 'name', // 自定义operator枚举名称的字段名
        fieldNameField: 'name', // 自定义field对象中 字段值 的字段名
        fieldKeyField: 'name', // 自定义field对象中 字段key 的字段名
        fieldTextField: 'text', // 自定义field对象中 显示文本 的字段名
        inputTypeIdField: 'id', // 自定义 inputType 枚举值的字段名

        // 强大的自定义能力，每一部分都可以自定义实现
        conditionFieldComponent: null, // 字段自定义组件
        conditionFieldComponentAttrs: {}, // 自定义字段组件的props
        conditionPrefixComponent: null, // 自定义前缀组件，在字段下拉框前面，可以用来显示序号等
        conditionPrefixComponentAttrs: {}, // 自定义前缀组件的props
        conditionPostfixComponent: null, // 自定义后缀组件，在输入组件的最后面
        conditionPostfixComponentAttrs: {}, // 自定义后缀组件的props
        operatorComponent: null, // 操作符自定义组件
        operatorComponentAttrs: {}, // 操作符自定义组件的props
        conditionValueComponentAttrs: {}, // 透传给自定义值输入框组件的props。由于值组件是变化的，所以需要把所有类型组件的props合并在一起
        valueLoadingText: '', // 值组件支持异步加载数据源，数据源加载完成后才开始渲染组件。可以设置一个loading文本，加载期间显示。
        valueLoadingComponent: null, // 还可以设置一个自定义loading组件
        header: "", // 自定义头部信息，支持任意ReactNode
        headerComponent: null, // 自定义头部组件
        headerComponentAttrs: null, // 自定义头部组件的props
        footer: "", // 自定义footer，支持任意ReactNode
        footerComponent: null, // 或者可以传入自定义footer组件，可以拿到更多内部信息
        footerComponentAttrs: {}, // 自定义footer组件的props

        // 自定义按钮
        removalIconClass: 'udesk-react-iconfont icon-udesk-react-remove-circle', // 删除按钮图标的class
        removalIconText: '', // 删除图标的文字
        removalIconTitle: '', // 删除图标的tooltip文本
        removalConditionComponent: null, // 还可以完全自定义一个删除按钮   
        addIconClass: 'udesk-react-iconfont icon-udesk-react-add-square', // 新增按钮的图标class
        addIconText: '', // 新增按钮的文字
        addIconTitle: '', // 新增按钮的tooltip
        addConditionComponent: null, // 还可以完全自定义一个新增按钮
        actionsComponent: null, // 底部自定义操作按钮。可以隐藏新增按钮，替换成自定义按钮
        actionsComponentAttrs: {}, // 自定义操作组件的props

        getFieldFromCondition: null, // 自定义函数，高级用法，用来从回显的condition对象中获取对应的field对象。一般情况下condition.field即是字段值，然后从fields数组中匹配对应的字段。但如果你的应用中比较特殊，无法使用默认逻辑的话，可以自己控制如何匹配并返回field对象。
        getFieldFromConditionContext: null, // 设置 getFieldFromCondition 函数的 this 上下文
        attachExtraConditionData: null, // 自定义函数，高级用法，给输出的condition对象上附加上任意自定义数据，在 onChange 事件之前调用，传入三个参数：condition对象、当前field、field的数据源，可以在方法中直接修改condition对象，无需返回值。
        getConditionModuleStates: null, // 自定义函数，高级用法，可以直接返回condition对象的内部初始状态，以达到细粒度控制condition行为的目的。传入 { condition }，返回一个 ConditionModuleStatesClass 实例。
        getConditionModuleStatesContext: null, // 设置 getConditionModuleStates 函数的 this 上下文
        getFieldKeyFromCondition: null, // 自定义函数，高级用法，用来从回显的condition对象中获取对应的field key。默认就是 condition.field，如果你的应用有特殊逻辑的话，可以自己控制返回具体的key。
        getFieldKeyFromConditionContext: null, // getFieldKeyFromCondition 函数的 this 上下文
        getFieldDataSource: getFieldDataSource, // 自定义函数，高级用法，用来获取field对象的自定义数据源
        getFieldDataSourceContext: null, // getFieldDataSource 函数的 this 上下文对象
        formatConditionField: null, // 自定义函数，高级用法，自定义condition对应字段值。默认逻辑是直接使用 codition.field，但也可以自定义。输入field、fieldValue、condition、isNewCondition，返回新的field值
        formatConditionFieldContext: null, // formatConditionField 函数的 this 上下文对象
        filterFieldsByPrefix: null, // 自定义函数，高级用法，根据前缀值过滤fields
        filterFieldsByPrefixContext: null, // filterFieldsByPrefix 函数的 this 上下文对象
        isFieldKeyEqual: null, // 自定义函数，高级用法，自定义判断两个field是否相等的逻辑，默认逻辑使用 === 操作符
        isConditionOperatorEqual: null, // 自定义函数，高级用法，自定义判断两个操作符是否相等的逻辑，默认逻辑使用 === 操作符
        
        onChanged: null, // 事件，当 conditions 发生变化时触发。输入：conditions数组
        onAdded: null, // 事件，当添加按钮时触发。输入：condition对象。
        onRemoved: null, // 事件，当点击某行的删除按钮时触发。输入：行index、被删除的condition对象、删除后的conditions数组。
        onFieldChanged: null, // 事件，当字段下拉框变化时触发。输入：field对象、当前condition对象。
        onOperatorChanged: null, // 事件，当操作符下拉框变化时触发。输入：操作符Id、当前condition对象。
        onValueChanged: null, // 事件，当值组件发生变化时触发。输入：新值、当前condition对象。
        onPrefixChanged: null, // 事件，当前缀发生变化时触发。输入：一个大对象，{ prefix, condition }
        onPostfixChanged: null // 事件，当后缀发生变化时触发。输入：一个大对象，{ postfix, condition }
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        conditions: PropTypes.array,
        fields: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.instanceOf(Promise),
            PropTypes.shape({
                then: PropTypes.func
            })
        ]),
        fieldValueSettings: PropTypes.array,

        header: PropTypes.string,
        headerComponent: PropTypes.node,
        headerComponentAttrs: PropTypes.object,
        conditionFieldComponent: PropTypes.node,
        conditionFieldComponentAttrs: PropTypes.object,
        conditionPrefixComponent: PropTypes.node,
        conditionPrefixComponentAttrs: PropTypes.object,
        conditionPostfixComponent: PropTypes.node,
        conditionPostfixComponentAttrs: PropTypes.object,
        operatorComponent: PropTypes.node,
        operatorComponentAttrs: PropTypes.object,
        conditionValueComponentAttrs: PropTypes.object,
        valueLoadingComponent: PropTypes.node,
        removalConditionComponent: PropTypes.node,
        addConditionComponent: PropTypes.node,
        actionsComponent: PropTypes.node,
        actionsComponentAttrs: PropTypes.object,
        footer: PropTypes.string,
        footerComponent: PropTypes.node,
        footerComponentAttrs: PropTypes.object,

        showConditionFieldColumn: PropTypes.bool,
        showConditionOperatorColumn: PropTypes.bool,
        showConditionValueColumn: PropTypes.bool,
        showRemovalButton: PropTypes.bool,
        showAddButton: PropTypes.bool,
        fieldTextPrefix: PropTypes.string,
        showConditionHeaders: PropTypes.bool,
        showConditionHeadersWhenEmpty: PropTypes.bool,
        showFieldsSearch: PropTypes.bool,
        conditionPrefixColumnHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node)
        ]),
        conditionFieldColumnHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node)
        ]),
        conditionOperatorColumnHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node)
        ]),
        conditionValueColumnHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node)
        ]),
        conditionPostfixColumnHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node)
        ]),
        conditionRemovalColumnHeader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node)
        ]),
        fieldPrefixClass: PropTypes.string,
        fieldSelectorClass: PropTypes.string,
        operatorSelectorClass: PropTypes.string,
        valueWrapperClass: PropTypes.string,
        fieldPostfixClass: PropTypes.string,
        fieldsLoadingText: PropTypes.string,
        fieldsLoadingErrorMsg: PropTypes.string,
        valueLoadingText: PropTypes.string,
        dataSourceLoadingErrorMsgFormat: PropTypes.string,
        rejectReasonMessageField: PropTypes.string,
        rangeWrapperClass: PropTypes.string,
        rangeFromText: PropTypes.string,
        rangeFromTextClass: PropTypes.string,
        rangeFromClass: PropTypes.string,
        rangeToText: PropTypes.string,
        rangeToTextClass: PropTypes.string,
        rangeToClass: PropTypes.string,
        validationSummary: PropTypes.string,
        fieldNotExistsErrorMsg: PropTypes.string,
        operatorNotExistsErrorMsg: PropTypes.string,
        removalIconClass: PropTypes.string,
        removalIconText: PropTypes.string,
        removalIconTitle: PropTypes.string,
        addIconClass: PropTypes.string,
        addIconText: PropTypes.string,
        addIconTitle: PropTypes.string,
        showConditionNumber: PropTypes.bool,
        enableValidation: PropTypes.bool,
        disabled: PropTypes.bool,
        theme: PropTypes.string,
        antdStyle: PropTypes.bool,

        conditionFieldField: PropTypes.string,
        conditionPrefixField: PropTypes.string,
        conditionPostfixField: PropTypes.string,
        conditionOperatorField: PropTypes.string,
        conditionValueField: PropTypes.string,
        conditionRemovableField: PropTypes.string,
        fieldInputTypeField: PropTypes.string,
        fieldOperatorsField: PropTypes.string,
        fieldDataSourceField: PropTypes.string,
        fieldAttributesField: PropTypes.string,
        fieldDefaultValueField: PropTypes.string,
        valueSettingsInputTypeField: PropTypes.string,
        valueSettingsOperatorsField: PropTypes.string,
        valueSettingsComponentField: PropTypes.string,
        valueSettingsAttributesField: PropTypes.string,
        operatorIdField: PropTypes.string,
        operatorNameField: PropTypes.string,
        fieldNameField: PropTypes.string,
        fieldKeyField: PropTypes.string,
        fieldTextField: PropTypes.string,
        inputTypeIdField: PropTypes.string,
        allowEmptyConditions: PropTypes.bool,
        autoSelectField: PropTypes.bool,
        defaultField: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
        displayFieldSectionAsText: PropTypes.bool,

        getFieldFromCondition: PropTypes.func,
        getFieldFromConditionContext: PropTypes.object,
        attachExtraConditionData: PropTypes.func,
        getConditionModuleStates: PropTypes.func,
        getConditionModuleStatesContext: PropTypes.object,
        getFieldKeyFromCondition: PropTypes.func,
        getFieldKeyFromConditionContext: PropTypes.object,
        getFieldDataSource: PropTypes.func,
        getFieldDataSourceContext: PropTypes.object,
        formatConditionField: PropTypes.func,
        formatConditionFieldContext: PropTypes.object,
        filterFieldsByPrefix: PropTypes.func,
        filterFieldsByPrefixContext: PropTypes.object,
        isFieldKeyEqual: PropTypes.func,
        isConditionOperatorEqual: PropTypes.func,

        onChanged: PropTypes.func,
        onAdded: PropTypes.func,
        onRemoved: PropTypes.func,
        onFieldChanged: PropTypes.func,
        onOperatorChanged: PropTypes.func,
        onValueChanged: PropTypes.func,
        onPrefixChanged: PropTypes.func,
        onPostfixChanged: PropTypes.func
    };
    //#endregion

    state = {};
    privates = {
        element: null,
        fields: this.props.fields,
        isFieldsLoaded: false,
        idSeed: 0,
        dirtyFullConditions: null,
        previousChangedConditions: null,
        EmptyDataSource: {},


    };

    static computesUsedActions = ['_getFieldFromCondition'];
    static computes = {
        className: ({ props, state, privates, locales }) => {
            let classNames = ['udesk-custom-filters-react'];
            if (props.theme) {
                classNames.push(props.theme);
            }
            return classNames.join(' ');
        },
        fieldsLoadingText: ({ props, state, privates, locales }) =>
            props.fieldsLoadingText || locales.components.customFilters.fieldsLoadingText,
        fieldsLoadingErrorMsg: ({ props, state, privates, locales }) =>
            props.fieldsLoadingErrorMsg || locales.components.customFilters.fieldsLoadingErrorMsg,
        valueLoadingText: ({ props, state, privates, locales }) =>
            props.valueLoadingText || locales.components.customFilters.valueLoadingText,
        dataSourceLoadingErrorMsgFormat: ({ props, state, privates, locales }) =>
            props.dataSourceLoadingErrorMsgFormat || locales.components.customFilters.dataSourceLoadingErrorMsgFormat,
        rangeFromText: ({ props, state, privates, locales }) =>
            props.rangeFromText || locales.components.customFilters.rangeFrom,
        rangeToText: ({ props, state, privates, locales }) =>
            props.rangeToText || locales.components.customFilters.rangeTo,
        validationSummary: ({ props, state, privates, locales }) =>
            props.validationSummary || locales.components.customFilters.validationSummary,
        fieldNotExistsErrorMsg: ({ props, state, privates, locales }) =>
            props.fieldNotExistsErrorMsg || locales.components.customFilters.fieldNotExistsErrorMsg,
        operatorNotExistsErrorMsg: ({ props, state, privates, locales }) =>
            props.operatorNotExistsErrorMsg || locales.components.customFilters.operatorNotExistsErrorMsg,
        _valueSettingsMappings({ props, state, privates, locales }) {
            // Build the internal cache of value component settings.
            let {
                fieldValueSettings,
                valueSettingsInputTypeField,
                inputTypeIdField,
                valueSettingsOperatorsField,
                operatorIdField
            } = props;
            let mappings = {};
            if (fieldValueSettings) {
                for (let settings of fieldValueSettings) {
                    let inputTypeId = Udesk.utils.object.get(
                        Udesk.utils.object.get(settings, valueSettingsInputTypeField),
                        inputTypeIdField
                    );
                    if (!Object.prototype.hasOwnProperty.call(mappings, inputTypeId)) {
                        mappings[inputTypeId] = {};
                    }

                    let operatorsMapping = mappings[inputTypeId];
                    let operators = Udesk.utils.object.get(settings, valueSettingsOperatorsField);
                    if (operators && operators.length > 0) {
                        for (let operator of operators) {
                            let operatorId = Udesk.utils.object.get(operator, operatorIdField);
                            operatorsMapping[operatorId] = settings;
                        }
                    } else {
                        operatorsMapping[''] = settings;
                    }
                }
            }
            return mappings;
        },
        _conditions: ["props.conditions", "privates.dirtyFullConditions", "privates.counter",
            "privates.previousChangedConditions", ({
                props,
                state,
                privates,
                actions,
                locales,
                NEVER_USE_THIS_UNLESS_YOU_REALLY_KNOW_WHAT_YOU_ARE_DOING
            }) => {
                // If the "conditions" is changed because of internal change,
                // don't regenerate it but use the internal value directly.
                if (privates.dirtyFullConditions != null) {
                    let bakFullConditions = privates.dirtyFullConditions;
                    if (process.env.NODE_ENV === 'production') {
                        privates.dirtyFullConditions = null;
                    } else {
                        if (!privates.counter) {
                            privates.counter = true;
                        } else {
                            privates.counter = false;
                            privates.dirtyFullConditions = null;
                        }
                    }
                    return bakFullConditions;
                }
                // If this component sends `conditions` from `onChanged` event, and user sends
                // it back immediately, this computes would be "refreshed", which leads to some states missing.
                if (
                    props.conditions ===
                    privates.previousChangedConditions /* || (!privates.dirtyProps.conditions && privates.computes._conditions != null) */
                ) {
                    // privates.previousChangedConditions = null;
                    return privates.computes._conditions;
                }
                let { conditions } = props;

                if (conditions == null) {
                    conditions = [];
                }
                if (!Array.isArray(conditions)) {
                    conditions = [conditions];
                }

                let fullConditions = conditions.map(condition => {
                    let field = actions._getFieldFromCondition(condition);
                    // 现在已经可以支持field为空的情况，而且在有些项目里默认值就是空，所以不再做此验证。
                    // if (field == null) {
                    //     let conditionFieldName = Udesk.utils.object.get(condition, conditionFieldField);
                    //     throw new Error(`Cannot find matched field by condition name '${conditionFieldName}'.`);
                    // }
                    let fullCondition = wrapCondition(
                        condition,
                        field,
                        NEVER_USE_THIS_UNLESS_YOU_REALLY_KNOW_WHAT_YOU_ARE_DOING
                    );
                    return fullCondition;
                });

                for (let fullCondition of fullConditions) {
                    fullCondition.states.removalIconVisible = isConditionRemovable(
                        fullCondition,
                        fullConditions,
                        NEVER_USE_THIS_UNLESS_YOU_REALLY_KNOW_WHAT_YOU_ARE_DOING
                    );
                }
                return fullConditions;
            }],
        _conditionsMinCount({ props, state, privates, locales }) {
            let { allowEmptyConditions } = props;
            if (allowEmptyConditions) {
                return 0;
            } else {
                return 1;
            }
        },
        _hasError: [
            '_conditions',
            ({ props, state, privates, locales }) => {
                let { _conditions: fullConditions } = privates.computes;
                for (let fullCondition of fullConditions) {
                    if (Udesk.utils.object.get(fullCondition.states, 'hasError')) {
                        return true;
                    }
                }
                return false;
            }
        ]
    };

    // 清空condition
    empty() {
        this.privates.dirtyFullConditions = null;
        this.trigger('onChanged', []);
    }

    //#region 生命周期
    componentWillUnmount() { }
    //#endregion

    actions = {
        notifyConditionsChanged() {
            let fullConditions = this.privates.computes._conditions;
            let innerConditions = fullConditions.map(item => item.data);
            this.actions.updateConditionsWithoutNotification(fullConditions);
            this.privates.previousChangedConditions = innerConditions;
            this.actions.update();

            this.trigger('onChanged', innerConditions);
        },
        updateConditionsWithoutNotification(fullConditions) {
            // The conditions are dirty just because of internal changes,
            // we don't want '_conditions' have to recalculate, so we set a flag when setting value,
            // and return it when retrieve it later.
            this.privates.dirtyFullConditions = fullConditions;
        },
        _fieldsPromiseResolved(result) {
            if (!this.privates.isFieldsLoaded) {
                this.privates.isFieldsLoaded = true;
                this.privates.fields = result;
                let { _conditions: fullConditions } = this.privates.computes;
                if (!this.props.allowEmptyConditions && fullConditions.length === 0) {
                    setTimeout(() => {
                        this.actions.onAdd();
                    });
                }
            }
            return {
                status: 'resolved',
                result
            };
        },
        onConditionPrefixChanged: function (fullCondition, value) {
            let { conditionPrefixField } = this.props;
            let { fields } = this.privates;
            Udesk.utils.object.set(fullCondition.data, conditionPrefixField, value);
            fullCondition.increaseVersion();
            this.trigger('onPrefixChanged', {
                prefix: value,
                condition: fullCondition.data
            });
            if (typeof this.props.filterFieldsByPrefix === 'function') {
                let filteredFields = this.actions._filterFieldsByPrefix(fields, value);
                if (filteredFields && filteredFields.length > 0) {
                    let firstField = filteredFields[0];
                    this.actions._innerFieldChanged(firstField, fullCondition);
                }
            }
            this.actions.notifyConditionsChanged();
        },
        onConditionPostfixChanged: function (fullCondition, value) {
            let { conditionPostfixField } = this.props;
            Udesk.utils.object.set(fullCondition.data, conditionPostfixField, value);
            fullCondition.increaseVersion();
            this.trigger('onPostfixChanged', {
                postfix: value,
                condition: fullCondition.data
            });
            this.actions.notifyConditionsChanged();
        },
        onFieldSelectChanged: function (fullCondition, e) {
            this.actions.onFieldValueChanged(fullCondition, e.target.value);
        },
        onFieldValueChanged: function (fullCondition, value) {
            let newFieldKey = value;
            let field = getFieldByKey(newFieldKey, this);
            let { fieldNameField } = this.props;
            let fieldValue = Udesk.utils.object.get(field, fieldNameField);
            this.actions._innerFieldChanged(field, fieldValue, fullCondition);
        },
        onExternalFieldChanged: function (fullCondition, value) {
            let field = getFieldByKey(value, this);
            if (field == null) {
                // If the value is not a field key, then it should be a field object itself.
                let tryCondition = { ...fullCondition.data };
                Udesk.utils.object.set(tryCondition, this.props.conditionFieldField, value);
                field = this.actions._getFieldFromCondition(tryCondition);
            }
            if (field) {
                this.actions._innerFieldChanged(field, value, fullCondition);
            }
        },
        _innerFieldChanged: function (field, fieldValue, fullCondition) {
            if (field != null) {
                fullCondition.popFieldError('fieldNotExists');
                let { conditionFieldField, fieldOperatorsField, operatorIdField } = this.props;

                let previousField = this.actions._getFieldFromCondition(fullCondition.data);
                let tryCondition = { ...fullCondition.data };
                Udesk.utils.object.set(tryCondition, this.props.conditionFieldField, fieldValue);
                Udesk.utils.object.set(
                    fullCondition.data,
                    conditionFieldField,
                    innerFormatConditionField(field, fieldValue, tryCondition, this)
                );

                if (field === previousField) {
                    // 如果field没有发生变化，则不需要进行后面的操作了，但需要触发onChanged事件以及重新渲染UI。
                    this.actions.notifyConditionsChanged();
                    return;
                }

                // Reset field's dataSource, since the field is changed, and the data source is outdated.
                fullCondition.states.dataSource = null;
                fullCondition.resetValidStates();
                this.trigger('attachExtraConditionData', fullCondition.data, field, fullCondition.states.dataSource);
                fullCondition.generateKey();

                this.trigger('onFieldChanged', field, fullCondition.data);
                let fieldOperatorId = getFieldDefaultOperatorId(field, fieldOperatorsField, operatorIdField);
                this.actions._innerOperatorChanged(fieldOperatorId, fullCondition);
                // 不需要调用increaseVersion了，因为已经在_innerOperatorChanged事件中触发过了。
                // 可以理解为当field变化时，field 和 operator同时变化。
                // fullCondition.increaseVersion();
            } else {
                let errorMessageFormat = this.privates.computes.fieldNotExistsErrorMsg || '';
                fullCondition.pushFieldError(
                    'fieldNotExists',
                    errorMessageFormat.replace(/\{0\}/g, Udesk.utils.object.get(field, this.props.fieldKeyField))
                );
            }
            this.actions.notifyConditionsChanged();
        },

        onOperatorSelectChanged: function (fullCondition, e) {
            this.actions.onOperatorValueChanged(fullCondition, e.target.value);
        },
        onOperatorValueChanged: function (fullCondition, value) {
            let newOperatorId = value;
            this.actions._innerOperatorChanged(newOperatorId, fullCondition);
        },
        onExternalOperatorChanged: function (fullCondition, value) {
            this.actions._innerOperatorChanged(value, fullCondition);
        },
        _innerOperatorChanged: function (newOperatorId, fullCondition) {
            let field = this.actions._getFieldFromCondition(fullCondition.data);
            if (field) {
                let { fieldOperatorsField, operatorIdField } = this.props;
                let operators = Udesk.utils.object.get(field, fieldOperatorsField);
                let operatorExists = false;
                if (operators && operators.length > 0) {
                    let matchedOperator = operators.find(item =>
                        this.actions._isConditionOperatorEqual(
                            Udesk.utils.object.get(item, operatorIdField),
                            newOperatorId
                        )
                    );
                    if (matchedOperator) {
                        operatorExists = true;
                    }
                } else if (newOperatorId == null) {
                    operatorExists = true;
                }
                let {
                    fieldInputTypeField,
                    inputTypeIdField,
                    fieldDefaultValueField,
                    conditionValueField,
                    conditionOperatorField,
                    valueSettingsAttributesField
                } = this.props;
                let recalculatedField = this.actions._getFieldFromCondition(fullCondition.data);
                if (operatorExists) {
                    Udesk.utils.object.set(fullCondition.data, conditionOperatorField, newOperatorId);
                    this.trigger(
                        'attachExtraConditionData',
                        fullCondition.data,
                        recalculatedField,
                        fullCondition.states.dataSource
                    );
                    fullCondition.generateKey();

                    this.trigger('onOperatorChanged', newOperatorId, fullCondition.data);
                    let defaultValueOptions = {
                        field: recalculatedField,
                        operatorId: newOperatorId,
                        fieldInputTypeField,
                        inputTypeIdField,
                        fieldDefaultValueField,
                        valueSettingsAttributesField
                    };
                    let fieldValue = getFieldDefaultValue(defaultValueOptions, this);
                    Udesk.utils.object.set(fullCondition.data, conditionValueField, fieldValue);
                    fullCondition.increaseVersion();
                } else {
                    Udesk.utils.object.set(fullCondition.data, conditionOperatorField, null);
                    this.trigger(
                        'attachExtraConditionData',
                        fullCondition.data,
                        recalculatedField,
                        fullCondition.states.dataSource
                    );
                    fullCondition.generateKey();

                    this.trigger('onOperatorChanged', null, fullCondition.data);
                    let defaultValueOptions = {
                        field: recalculatedField,
                        operatorId: null,
                        fieldInputTypeField,
                        inputTypeIdField,
                        fieldDefaultValueField,
                        valueSettingsAttributesField
                    };
                    let fieldValue = getFieldDefaultValue(defaultValueOptions, this);
                    Udesk.utils.object.set(fullCondition.data, conditionValueField, fieldValue);
                    fullCondition.increaseVersion();
                }
                this.actions.notifyConditionsChanged();
            }
        },
        onRangeValueChanged: function (index, fullCondition, field, value) {
            let { conditionValueField } = this.props;
            Udesk.utils.object.set(fullCondition.data, conditionValueField + '.' + index, value);
            this.actions._postValueChange(fullCondition, field, value);
        },
        onValueChanged: function (fullCondition, field, value) {
            let { conditionValueField } = this.props;
            Udesk.utils.object.set(fullCondition.data, conditionValueField, value);
            this.actions._postValueChange(fullCondition, field, value);
        },
        _postValueChange: function (fullCondition, field, value) {
            this.trigger('attachExtraConditionData', fullCondition.data, field, fullCondition.states.dataSource);
            fullCondition.generateKey();
            this.trigger('onValueChanged', value, fullCondition.data);
            fullCondition.increaseVersion();
            this.actions.notifyConditionsChanged();
        },

        onExternalAdd: function (condition) {
            let field = null;
            if (condition == null) {
                let conditionResult = generateNewCondition(this);
                condition = conditionResult.condition;
                field = conditionResult.field;
            } else {
                field = this.actions._getFieldFromCondition(condition);
            }
            this.actions._innerAdd(condition, field);
        },
        onAdd: function () {
            let conditionResult = generateNewCondition(this);
            this.actions._innerAdd(conditionResult.condition, conditionResult.field);
        },
        _innerAdd: function (condition, field) {
            let { _conditions: fullConditions } = this.privates.computes;

            let fullCondition = wrapCondition(condition, field, this);

            fullConditions.push(fullCondition);
            this.actions.updateConditionsWithoutNotification(fullConditions);
            Udesk.utils.object.set(
                fullCondition.states,
                'removalIconVisible',
                isConditionRemovable(fullCondition, fullConditions, this)
            );

            this.trigger('onAdded', fullCondition.data);

            let otherFullConditions = fullConditions.filter(item => item !== fullCondition);
            for (let otherOne of otherFullConditions) {
                let removalIconVisible = isConditionRemovable(otherOne, fullConditions, this);
                if (removalIconVisible !== Udesk.utils.object.get(otherOne, 'states.removalIconVisible')) {
                    Udesk.utils.object.set(
                        otherOne.states,
                        'removalIconVisible',
                        isConditionRemovable(otherOne, fullConditions, this)
                    );
                }
            }
            this.actions.notifyConditionsChanged();
        },

        onRemoved: function (fullCondition) {
            this.actions._innerRemoved(fullCondition);
        },
        onExternalRemoved: function (fullCondition) {
            this.actions._innerRemoved(fullCondition);
        },
        _innerRemoved: function (fullCondition) {
            let { _conditions: fullConditions } = this.privates.computes;
            let index = fullConditions.indexOf(fullCondition);
            if (index !== -1) {
                Udesk.utils.object.set(fullCondition.states, 'removalIconVisible', false);

                let recalculatedIndex = fullConditions.indexOf(fullCondition);
                fullConditions.splice(recalculatedIndex, 1);
                this.actions.updateConditionsWithoutNotification(fullConditions);

                let otherFullConditions = fullConditions.filter(item => item !== fullCondition);
                for (let otherOne of otherFullConditions) {
                    let removalIconVisible = isConditionRemovable(otherOne, fullConditions, this);
                    if (removalIconVisible !== Udesk.utils.object.get(otherOne, 'states.removalIconVisible')) {
                        Udesk.utils.object.set(otherOne.states, 'removalIconVisible', false);
                    }
                }

                this.trigger('onRemoved', recalculatedIndex, fullCondition.data, fullConditions.map(f => f.data));
                this.actions.notifyConditionsChanged();
            }
        },
        _receiveContainerDom(dom) {
            this.privates.element = dom;
        },
        _getFieldFromCondition(condition) {
            let {
                fieldKeyField,
                getFieldFromCondition: getFieldFromConditionMethod,
                getFieldFromConditionContext
            } = this.props;
            let { fields } = this.privates;

            let fieldKey = innerGetFieldKeyFromCondition(condition, this);
            let field = fields.find(f =>
                this.actions._isFieldKeyEqual(Udesk.utils.object.get(f, fieldKeyField), fieldKey)
            );
            if (field == null) {
                if (typeof getFieldFromConditionMethod === 'function') {
                    field = getFieldFromConditionMethod.call(getFieldFromConditionContext, {
                        condition
                    });
                }
            }
            return field;
        },
        _getConditionModuleStates(fullCondition) {
            let { getConditionModuleStates, getConditionModuleStatesContext } = this.props;

            if (getConditionModuleStates != null && typeof getConditionModuleStates === 'function') {
                return getConditionModuleStates.call(getConditionModuleStatesContext, {
                    condition: fullCondition.data
                });
            } else {
                return null;
            }
        },
        _getFieldValueSettings(condition) {
            // Extract custom attributes of each field and operator, to enable customization of value component.
            let {
                fieldInputTypeField,
                fieldOperatorsField,
                fieldAttributesField,
                inputTypeIdField,
                valueSettingsAttributesField,
                operatorIdField
            } = this.props;
            let { _valueSettingsMappings: allValueSettingsMappings } = this.privates.computes;

            let settingMappings = {};
            let field = this.actions._getFieldFromCondition(condition);
            if (field) {
                let inputType = Udesk.utils.object.get(field, fieldInputTypeField);
                let inputTypeId = Udesk.utils.object.get(inputType, inputTypeIdField);
                let fieldAttributes = Udesk.utils.object.get(field, fieldAttributesField);

                // Mix the attributes from both field configuration and value configuration together.
                let operators = Udesk.utils.object.get(field, fieldOperatorsField);
                if (operators) {
                    for (let operator of operators) {
                        let operatorId = Udesk.utils.object.get(operator, operatorIdField);
                        let valueAttributes = {};
                        if (
                            allValueSettingsMappings[inputTypeId] != null &&
                            allValueSettingsMappings[inputTypeId][operatorId] != null
                        ) {
                            valueAttributes =
                                allValueSettingsMappings[inputTypeId][operatorId][valueSettingsAttributesField];
                        }
                        let attributes = Object.assign({}, valueAttributes);
                        attributes = Object.assign(attributes, fieldAttributes);
                        settingMappings[operatorId] = attributes;
                    }
                }
            }
            return settingMappings;
        },
        _filterFieldsByPrefix(localFields, prefix) {
            let { filterFieldsByPrefix, filterFieldsByPrefixContext } = this.props;

            if (filterFieldsByPrefix != null && typeof filterFieldsByPrefix === 'function') {
                let resultFields = filterFieldsByPrefix.call(filterFieldsByPrefixContext, localFields, prefix);
                if (resultFields != null && resultFields.length != null) {
                    return resultFields;
                }
            }
            return localFields;
        },
        _isFieldKeyEqual(value1, value2) {
            return this.actions._isConditionMetaValueEqualCore('isFieldKeyEqual', value1, value2);
        },
        _isConditionOperatorEqual(value1, value2) {
            return this.actions._isConditionMetaValueEqualCore('isConditionOperatorEqual', value1, value2);
        },
        _isConditionMetaValueEqualCore(methodName, value1, value2) {
            let method = this.props[methodName];
            if (method != null && typeof method === 'function') {
                return !!method(value1, value2);
            } else {
                return value1 === value2;
            }
        },
        _mergeValueSettings(valueSettings, field) {
            let valueSettingsComponentField = this.props.valueSettingsComponentField;
            let newValueSettings = Object.assign({}, valueSettings);
            if (field[valueSettingsComponentField]) {
                Udesk.utils.object.set(
                    newValueSettings,
                    valueSettingsComponentField,
                    field[valueSettingsComponentField]
                );
                this.actions.notifyConditionsChanged();
            }
            return newValueSettings;
        },
        _getFieldDataSource(fieldDataSource, field, fieldSettings) {
            if (typeof this.props.getFieldDataSource === 'function') {
                return this.trigger('getFieldDataSource', fieldDataSource, field, fieldSettings);
            } else {
                if (typeof fieldDataSource === 'function') {
                    return fieldDataSource.call(field);
                } else {
                    return fieldDataSource;
                }
            }
        }
    };
}

function getFieldDataSource(value, field, fieldSettings) {
    if (typeof value === 'function') {
        return value.call(field);
    } else {
        return value;
    }
}

function generateNewCondition(context) {
    let {
        defaultField,
        fieldKeyField,
        fieldNameField,
        conditionFieldField,
        conditionOperatorField,
        conditionValueField,
        fieldInputTypeField,
        inputTypeIdField,
        fieldOperatorsField,
        operatorIdField,
        fieldDefaultValueField,
        valueSettingsAttributesField,
        autoSelectField
    } = context.props;
    let { fields } = context.privates;

    let newCondition = {};
    let newField = null;
    if (autoSelectField) {
        if (fields && fields.length > 0) {
            let defaultFieldObj = null;
            if (typeof defaultField === 'function') {
                defaultFieldObj = defaultField();
            } else {
                defaultFieldObj = defaultField;
            }
            if (defaultFieldObj != null) {
                let autoSelectedField = fields.find(f => f === defaultFieldObj);
                if (autoSelectedField == null) {
                    autoSelectedField = getFieldByKey(defaultFieldObj, context);
                }
                if (autoSelectedField == null) {
                    autoSelectedField = getFieldByKey(Udesk.utils.object.get(defaultFieldObj, fieldKeyField), context);
                }
                newField = autoSelectedField;
            }
            if (newField == null) {
                newField = fields[0];
            }

            let fieldValue = Udesk.utils.object.get(newField, fieldNameField);
            Udesk.utils.object.set(
                newCondition,
                conditionFieldField,
                innerFormatConditionField(newField, fieldValue, null, context)
            );

            let fieldOperatorId = getFieldDefaultOperatorId(newField, fieldOperatorsField, operatorIdField);
            Udesk.utils.object.set(newCondition, conditionOperatorField, fieldOperatorId);

            let defaultValueOptions = {
                field: newField,
                operatorId: fieldOperatorId,
                fieldInputTypeField,
                inputTypeIdField,
                fieldDefaultValueField,
                valueSettingsAttributesField
            };
            let conditionValue = getFieldDefaultValue(defaultValueOptions, context);
            Udesk.utils.object.set(newCondition, conditionValueField, conditionValue);
        }
    }
    return {
        condition: newCondition,
        field: newField
    };
}

function getFieldDefaultOperatorId(field, fieldOperatorsField, operatorIdField) {
    let operators = Udesk.utils.object.get(field, fieldOperatorsField);
    if (operators && operators.length > 0) {
        let defaultOperator = operators[0];
        return Udesk.utils.object.get(defaultOperator, operatorIdField);
    }
    return null;
}

function getFieldDefaultValue(options, context) {
    let value = null;
    let defaultValue = Udesk.utils.object.get(options.field, options.fieldDefaultValueField);
    if (defaultValue != null) {
        if (typeof defaultValue === 'function') {
            value = defaultValue.apply(options.field, []);
        } else {
            value = defaultValue;
        }
    } else if (options.operatorId != null) {
        let inputTypeId = Udesk.utils.object.get(
            Udesk.utils.object.get(options.field, options.fieldInputTypeField),
            options.inputTypeIdField
        );
        let settings = getComponentSettings(inputTypeId, options.operatorId, context);
        if (settings != null) {
            let attributes = Udesk.utils.object.get(settings, options.valueSettingsAttributesField);
            if (attributes != null && attributes.isRange) {
                value = [undefined, undefined];
            }
        }
    }
    return value;
}

function getComponentSettings(inputTypeId, operator, context) {
    let mappings = context.privates.computes._valueSettingsMappings;
    if (mappings) {
        let operatorsMapping = mappings[inputTypeId];
        let settings = null;
        if (operatorsMapping) {
            settings = operatorsMapping[operator];
        }
        if (settings) {
            return settings;
        }
    }
    return null;
}

function wrapCondition(condition, field, component) {
    let { enableValidation, disabled } = component.props;
    let idSeed = component.privates.idSeed;
    idSeed++;
    component.privates.idSeed = idSeed;

    let fullCondition = {
        id: idSeed,
        data: condition,
        owner: component,
        key: null,
        states: {
            filteredFieldsByPrefix: [],
            removalIconVisible: true,
            dataSource: null,
            isDataSourcePending: false,
            prefixValue: null,
            postfixValue: null,
            isValid: true,
            errors: [],
            isRangeFromValid: true,
            rangeFromErrors: [],
            isRangeToValid: true,
            rangeToErrors: [],
            fieldErrors: [],
            operatorErrors: [],
            version: 0,
            isConditionOperatorRendered: true,
            isConditionValueRendered: true,
            moduleStates: new ConditionModuleStatesClass({ disabled }),
            renderedModules: getDefaultRenderedModules(),
            hasError: false,
            hasValueError: false
        },
        generateKey() {
            Udesk.utils.object.set(this, 'key', innerGetFieldKeyFromCondition(this.data, this.owner));
        },
        fieldDataSourceResolving(curField, dataSource) {
            if (dataSource != null) {
                if (typeof dataSource.then !== 'function') {
                    component.trigger('attachExtraConditionData', this.data, curField, dataSource);
                    this.generateKey();
                } else {
                    this.states.isDataSourcePending = true;
                }
            }
        },
        fieldDataSourceResolved(curField, dataSource) {
            if (this.states.isDataSourcePending) {
                this.states.isDataSourcePending = false;
                component.trigger('attachExtraConditionData', this.data, curField, dataSource);
                this.generateKey();
            }
        },
        resetValidStates() {
            Udesk.utils.object.set(this.states, 'isValid', true);
            Udesk.utils.object.set(this.states, 'errors', []);
            Udesk.utils.object.set(this.states, 'isRangeFromValid', true);
            Udesk.utils.object.set(this.states, 'rangeFromErrors', []);
            Udesk.utils.object.set(this.states, 'isRangeToValid', true);
            Udesk.utils.object.set(this.states, 'rangeToErrors', []);
            calculateConditionHasValueError(this.states, enableValidation);
            calculateConditionHasError(this.states, enableValidation);
        },
        pushFieldError: function (errorKey, errorContent) {
            pushConditionError.apply(this, ['fieldErrors', errorKey, errorContent]);
            calculateConditionHasError(this.states, enableValidation);
        },
        popFieldError: function (errorKey) {
            popConditionError.apply(this, ['fieldErrors', errorKey]);
            calculateConditionHasError(this.states, enableValidation);
        },
        pushOperatorError: function (errorKey, errorContent) {
            pushConditionError.apply(this, ['operatorErrors', errorKey, errorContent]);
            calculateConditionHasError(this.states, enableValidation);
        },
        popOperatorError: function (errorKey) {
            popConditionError.apply(this, ['operatorErrors', errorKey]);
            calculateConditionHasError(this.states, enableValidation);
        },
        increaseVersion: function () {
            Udesk.utils.object.set(this, 'states.version', Udesk.utils.object.get(this, 'states.version') + 1);
            Object.assign(this.states.renderedModules, getDefaultRenderedModules());
            let newModuleStates = component.actions._getConditionModuleStates(fullCondition);
            fullCondition.states.moduleStates.mergeFrom(newModuleStates);
        }
    };
    fullCondition.generateKey();
    component.trigger('attachExtraConditionData', fullCondition.data, field, fullCondition.states.dataSource);

    let newModuleStates = component.actions._getConditionModuleStates(fullCondition);
    fullCondition.states.moduleStates.mergeFrom(newModuleStates);

    if (enableValidation) {
        fullCondition.resetValidStates();
    }
    return fullCondition;
}

function calculateConditionHasValueError(conditionStates, enableValidation) {
    if (enableValidation) {
        let { isValid, isRangeFromValid, isRangeToValid } = conditionStates;
        conditionStates.hasValueError = !isValid || !isRangeFromValid || !isRangeToValid;
    } else {
        conditionStates.hasValueError = false;
    }
}

function calculateConditionHasError(conditionStates, enableValidation) {
    if (enableValidation) {
        let { hasValueError, fieldErrors, operatorErrors } = conditionStates;
        conditionStates.hasError = hasValueError || fieldErrors.length > 0 || operatorErrors.length > 0;
    } else {
        conditionStates.hasError = false;
    }
}

function getDefaultRenderedModules() {
    let modules = {};
    for (let item of ConditionModuleEnums) {
        modules[item.key] = false;
    }
    return modules;
}

function isConditionRemovable(fullCondition, fullConditions, context) {
    let { conditionRemovableField } = context.props;
    let { _conditionsMinCount: conditionsMinCount } = context.privates.computes;

    if (fullCondition.data[conditionRemovableField] === false) {
        return false;
    } else {
        return fullConditions.length > conditionsMinCount;
    }
}

function getFieldByKey(fieldKey, context) {
    let { fieldKeyField } = context.props;
    let { fields } = context.privates;
    return fields.find(field =>
        context.actions._isFieldKeyEqual(Udesk.utils.object.get(field, fieldKeyField), fieldKey)
    );
}

function innerGetFieldKeyFromCondition(conditionData, context) {
    let { getFieldKeyFromCondition, getFieldKeyFromConditionContext, conditionFieldField } = context.props;
    if (typeof getFieldKeyFromCondition === 'function') {
        let key = getFieldKeyFromCondition.apply(getFieldKeyFromConditionContext, [conditionData]);
        if (key == null) {
            key = Udesk.utils.object.get(conditionData, conditionFieldField);
        }
        return key;
    } else {
        return Udesk.utils.object.get(conditionData, conditionFieldField);
    }
}

function innerFormatConditionField(field, fieldValue, conditionData, context) {
    let { formatConditionField, formatConditionFieldContext } = context.props;
    if (typeof formatConditionField === 'function') {
        return formatConditionField.apply(formatConditionFieldContext, [
            {
                field,
                fieldValue,
                condition: conditionData,
                isNewCondition: conditionData == null
            }
        ]);
    } else {
        return fieldValue;
    }
}

function pushConditionError(errorsArrayName, errorKey, errorContent) {
    let errorsArray = this.states[errorsArrayName];
    let index = errorsArray.findIndex(item => item.key === errorKey);
    if (index !== -1) {
        errorsArray[index].content = errorContent;
    } else {
        errorsArray.push({
            key: errorKey,
            content: errorContent
        });
    }
    Udesk.utils.object.set(this.states, errorsArrayName, errorsArray.concat([]));
}

function popConditionError(errorsArrayName, errorKey) {
    let errorsArray = this.states[errorsArrayName];
    let index = errorsArray.findIndex(item => item.key === errorKey);
    if (index !== -1) {
        errorsArray.splice(index, 1);
    }
    Udesk.utils.object.set(this.states, errorsArrayName, errorsArray.concat([]));
}
//#endregion

/*  
    PropTypes.any
    PropTypes.array
    PropTypes.bool
    PropTypes.func
    PropTypes.number
    PropTypes.object
    PropTypes.string
    PropTypes.node
    PropTypes.element
    PropTypes.symbol
*/

export default CustomFiltersComponent;
