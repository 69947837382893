import React from 'react';
import PropTypes from 'prop-types';
import Udesk from 'Udesk';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';

class MassagePushConfigurationComponent extends React.Component {
    //#region Default Props
    static propTypes = {
        type: PropTypes.number,
        onPushTypesChange: PropTypes.func,
    };
    static defaultProps = {
        type: Udesk.enums.messageType.trigger.id,
        onPushTypesChange: null,
    };
    //#endregion

    state = {};
    privates = {
        messagePushType: Udesk.enums.messagePushType.filter((item) => Boolean(item.id)),
        inputValue: '',
        checkedTypesOpened: null,
        targets: [],
        showCustomize: false,
    };
    actions = {
        onInputChange(actionType, key, e) {
            let inputValue = e;
            if (e.target) {
                inputValue = e.target.value;
            }
            let newPushConf = this.actions.setActionDetailsValue(key, inputValue, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        setActionDetailsValue(key, value, actionType) {
            let { pushConf } = this.props;
            let newPushConf = { ...pushConf };
            if (!newPushConf.actionDetails) {
                newPushConf.actionDetails = {};
            }
            newPushConf.actionDetails[key] = value;
            return newPushConf;
        },
        onTargetsChange(actionType, checkedValue) {
            let newPushConf = this.actions.setActionDetailsValue('pushTargetList', checkedValue, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        onSelectChange(type, actionType, value) {
            let newPushConf = this.actions.setActionDetailsValue(type, value, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        handleEditorChange(actionType, editorState) {
            // let newPushConf = this.actions.setActionDetailsValue('content', editorState.toHTML(), actionType);
            let { pushConf } = this.props;
            let newPushConf = { ...pushConf };
            if (!newPushConf.actionDetails) {
                newPushConf.actionDetails = {};
            }
            newPushConf.actionDetails._editorState = editorState;
            newPushConf.actionDetails.content = editorState.toHTML();
            this.trigger('onPushConfItemChange', newPushConf);
        },
        showCustomize(pushConf, e) {
            this.privates.showCustomize = e.target.checked;
            if (!e.target.checked) {
                this.actions.onSelectChange('pushUserList', pushConf.actionType, []);
                this.actions.onSelectChange('pushRoleList', pushConf.actionType, []);
            }
            this.actions.update();
        },
        onPushObjectTypeChange(actionType, e) {
            let checked = e.target.checked;
            let checkedValue = Udesk.enums.pushObjectTypes.staff.id;
            if (checked) {
                checkedValue = Udesk.enums.pushObjectTypes.staff.id;
            } else {
                checkedValue = Udesk.enums.pushObjectTypes.email.id;
            }
            let newPushConf = this.actions.setActionDetailsValue('pushTargetType', checkedValue, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        associateDataClickInRich(pushConf, editorState, content){
            let newPushConf = this.actions.setActionDetailsValue('content', ContentUtils.insertText(editorState, content).toHTML());
            newPushConf.actionDetails._editorState = ContentUtils.insertText(editorState, content);
            this.trigger('onPushConfItemChange', newPushConf);
        },
    };

    //#region Life Cycle
    init() {
        this.privates.autoAddPushTargets = Udesk.enums.autoAddPushTarget.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        this.privates.sdkPushType = Udesk.enums.sdkPushType.map((item) => ({ label: item.name, value: item.id }));
        if(Array.isArray(this.props.pushTypeFilter)){
            this.privates.sdkPushType = this.privates.sdkPushType.filter(i => this.props.pushTypeFilter.includes(i.value));
        }
    }
    parseProps({ props, prevProps }) {
        let res = {};
        res.pushConf = Udesk.utils.object.deepCopy(props.pushConf);
        if (res.pushConf.actionDetails && !res.pushConf.actionDetails._editorState) {
            res.pushConf.actionDetails._editorState = BraftEditor.createEditorState(
                res.pushConf.actionDetails.content || null
            );
        }
        if (
            (res.pushConf.actionDetails && res.pushConf.actionDetails.pushRoleList && res.pushConf.actionDetails.pushRoleList.length) ||
            (res.pushConf.actionDetails && res.pushConf.actionDetails.pushUserList && res.pushConf.actionDetails.pushUserList.length)
        ) {
            res.showCustomize = true;
        }
        if(!res.pushConf.actionType){
            res.pushConf.actionType = parseInt(res.pushConf.actionDetails.actionType, 10);
        }
        return res;
    }
    //#endregion
}

export default MassagePushConfigurationComponent;
