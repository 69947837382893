import React, { useState, useEffect, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Popover, Icon, Space, Input, Button } from 'udesk-ui';
import { isDefined } from 'src/util/core';
import { EnumRadio } from 'Component/common/enum-component';

export const ComplainButton = React.memo((props) => {
    const locales = Locales['current'];

    const {
        isEdit = true,
        btnText,
        visible,
        pointId,
        onSubmitClick,
        onCancelClick,
        onVisibleChange: propsOnVisibleChange,
        hasOtherPopoverOpen,
        originValue,
        inspectDataSource,
        btnProps = {},
    } = props;
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [complainType, setComplainType] = useState(Udesk.enums.appealTypes.otherError.id);
    const [complainReason, setComplainReason] = useState('');

    const onChange = useCallback((e) => {
        setComplainType(e.target.value);
    }, []);

    const onInput = useCallback((e) => {
        setComplainReason(e.target.value);
    },[]);

    const onSubmit = useCallback(() => {
        onSubmitClick?.({ complainType, complainReason, pointId });
        setPopoverVisible(false);
    }, [
        complainReason, 
        complainType, 
        onSubmitClick, 
        pointId
    ]);

    const onCancel = useCallback(() => {
        onCancelClick?.(pointId);
        setPopoverVisible(false);
        setComplainReason('');
        setComplainType(Udesk.enums.appealTypes.otherError.id);
    }, [
        onCancelClick, 
        pointId
    ]);
    
    const resetValues = useCallback(() => {
        setComplainReason(originValue?.complainReason);
        setComplainType(originValue?.complainType);
    }, [
        originValue?.complainReason, 
        originValue?.complainType
    ]);

    const onVisibleChange = useCallback((visible) => {
        setPopoverVisible(true);
    }, []);

    useEffect(() => {
        propsOnVisibleChange?.(popoverVisible ? pointId : false);
    }, [popoverVisible]);

    useEffect(() => {
        isDefined(originValue?.complainReason, () => setComplainReason(originValue?.complainReason));
        isDefined(originValue?.complainType, () => setComplainType(originValue?.complainType));
    }, []);

    const content = (
        <div style={{ width: 300}}>
            <EnumRadio 
                enumKey='appealTypes' 
                onChange={onChange}
                value={isEdit ? complainType : originValue?.complainType}
                filter={
                    (item) => {
                        if (
                            item.id === 1 &&
                            inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id
                        ) {
                            return false;
                        }
                        if (
                            item.id === 1 &&
                            inspectDataSource === Udesk.enums.inspectDataSources.ticket.id
                        ) {
                            return false;
                        }
                        return true;
                    }
                } 
            />
            <Input.TextArea
                onChange={onInput}
                value={isEdit ? complainReason : originValue?.complainReason}
                autoSize={{ minRows: 3, maxRows: 3 }}
                style={{ marginTop: 8 }}
                maxCount={200}
                maxLength={200}
                showCount={true}
                placeholder={locales.fix.appealPlaceHolder}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
                <Button
                    disabled={!isEdit || !originValue}
                    onClick={onCancel}
                    size="small"
                    type="primary"
                    danger
                >
                    {locales.fix.giveUp}
                </Button>
                <Space>
                    <Button size="small" disabled={!isEdit || !originValue} onClick={resetValues}>
                        {locales.labels.cancel}
                    </Button>
                    <Button disabled={!isEdit} size="small" type="primary" onClick={onSubmit}>
                        {locales.labels.submit}
                    </Button>
                </Space>
            </div>
        </div>
    );

    const title = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{btnText}</div>
            <Button
                onClick={() => setPopoverVisible(false)}
                type="text"
                icon={<Icon type="CloseOutlined" antdIcon={true} />}
                size="small"
            />
        </div>
    );
    return (
        <React.Fragment>
            {visible && (
                <div
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    className="quality-check-talk-point-appeal-drawer"
                    style={popoverVisible ? { transform: 'translateX(0)' } : undefined}
                >
                    <Popover
                        visible={popoverVisible}
                        onVisibleChange={onVisibleChange}
                        trigger="click"
                        content={content}
                        title={title}
                    >
                        <Button
                            disabled={hasOtherPopoverOpen && hasOtherPopoverOpen !== pointId}
                            type="primary"
                            size="small"
                            {...btnProps}
                        >
                            {btnText}
                        </Button>
                    </Popover>
                </div>
            )}
        </React.Fragment>
    );
});
