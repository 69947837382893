import React from 'react';
const INTERVAL = 500;

class ContainerWidth extends React.Component {
    constructor(props) {
        super(props);
        this._timer = undefined;
    }
    componentDidMount() {
        let that = this;
        if (that.containerWidthINstance) {
            that.props.onWidthCalculated($(that.containerWidthINstance).width());
            let t = setInterval(function () {
                that.props.onWidthCalculated($(that.containerWidthINstance).width());
            }, INTERVAL);
            this._timer = t;
        }
    }
    componentWillUnmount() {
        let t = this._timer;
        if (t) {
            clearInterval(t);
            this.containerWidthINstance = null;
        }
    }
    render() {
        return (
            <div ref={(containerWidth) => this.containerWidthINstance = containerWidth}></div >
        );
    }
}

ContainerWidth.defaultProps = {
    onWidthCalculated: undefined
};

export default ContainerWidth;