//const queryString = require("query-string");
import Web from 'udesk-web-toolkits/src/udesk/utils/web';
let innerRoutes = [];
let innerUnfoldedRoutes = [];

function getRoutes() {
    return innerRoutes;
}

function getUnfoldedRoutes() {
    return innerUnfoldedRoutes;
}

function getRoutePath({ routeName, pathParams, queryParams }) {
    let routePathObj = _buildRoutePathObj({ routeName, pathParams, queryParams });

    if (routePathObj == null) {
        throw new Error(`
            Wrong configuration in 'ui/routing/index.js' method 'getRoutePath'.
            The routePathObj is null
        `);
    }

    let routePath = "/";
    let hasPathName = Object.prototype.hasOwnProperty.call(routePathObj,"pathname") && ("pathname" in routePathObj);
    if (hasPathName) {
        routePath = routePathObj.pathname;
    }

    let hasSearch = Object.prototype.hasOwnProperty.call(routePathObj,"search") && ("search" in routePathObj);
    if (hasSearch && routePathObj.search) {
        routePath += routePathObj.search;
    }
    return routePath;
}

function getChildRoutes(routeName) {
    let targetRoutes = _getRoute(routeName);
    if (targetRoutes != null) {
        return targetRoutes[0].routes || [];
    }
    return [];
}

function getFirstChildPath(routeName) {
    let targetRoutes = _getRoute(routeName);
    if (targetRoutes != null) {
        let childRoutes = targetRoutes[0].routes;
        if (childRoutes != null && childRoutes instanceof Array && childRoutes.length > 0) {
            return childRoutes[0].path;
        }
    }
    return '/';
}

function getRouteFirstChildPath({ routeName, pathParams, queryParams }) {
    let targetRoutes = _getRoute(routeName);
    if (targetRoutes != null) {
        let routeFirstChildRoute = _getRouteFirstChildRoute(targetRoutes);
        if (routeFirstChildRoute != null) {
            return getRoutePath({
                routeName: routeFirstChildRoute.name,
                pathParams,
                queryParams
            });
        }
    }
    return '/';
}


function getRouteFirstBottomedChildRoutePath({ routeName, pathParams, queryParams }) {
    let targetRoutes = _getRoute(routeName);
    if (targetRoutes != null) {
        let routeFirstBottomedChildRoute = _getRouteFirstBottomedChildRoute(targetRoutes);
        if (routeFirstBottomedChildRoute != null) {
            return getRoutePath({
                routeName: routeFirstBottomedChildRoute.name,
                pathParams,
                queryParams
            });
        }
    }
    return '/';
}

function _traverseRoutes(routes, callback) {
    for (var route of routes) {
        callback(route);
        if (route.routes && (route.routes instanceof Array && route.routes.length > 0)) {
            _traverseRoutes(route.routes, callback);
        }
    }
}

function _findRoutes(routes, matcher) {
    var matchedRoutes = [];
    _traverseRoutes(routes, (route) => {
        if (matcher(route)) {
            matchedRoutes.push(route);
        }
    });
    return matchedRoutes;
}

function _buildRoutes(routes, parentPath) {
    if (routes == null || !(routes instanceof Array)) {
        routes = [];
    }
    routes = routes.map((route) => {
        if (parentPath != null) {
            route.path = parentPath + route.path;
        }
        if (route.outRoutePath != null) {
            route.path = route.outRoutePath + route.path;
        }
        if (route.routes instanceof Array && route.routes.length > 0) {
            route.routes = _buildRoutes(route.routes, route.path);
        }
        return route;
    });
    return routes;
}

function _validRoutes(routes) {
    let routesNameMappings = {};
    _traverseRoutes(routes, function (item) {
        let routeItemName = item.name;
        if (routeItemName == null || routeItemName !== "") {
            if (Object.prototype.hasOwnProperty.call(routesNameMappings,item.name)) {
                throw new Error(`
                    Wrong configuration in 'ui/routing/index.js'.
                    The routePath is '${item.path}' of  route, name are repeated
                `);
            } else {
                routesNameMappings[item.name] = true;
            }
        }
    });
}

function _initRoutes(routes) {
    if (routes == null) {
        routes = [];
    }
    if (!(routes instanceof Array)) {
        routes = [routes];
    }
    _validRoutes(routes);
    innerRoutes = _buildRoutes(routes);
    _traverseRoutes(routes, function (route) {
        innerUnfoldedRoutes.push(route);
    });
}

function _getRoute(routeName) {
    let targetRoutes = _findRoutes(innerRoutes, function (route) {
        return route.name === routeName;
    });
    if (targetRoutes != null && targetRoutes instanceof Array && targetRoutes.length === 1) {
        return targetRoutes[0];
    }
    return null;
}

function _getRouteFirstBottomedChildRoute(route) {
    let routeFirstBottomedChildRoute = route;
    let routes = route.routes;
    if (routes != null && routes instanceof Array && routes.length > 0) {
        let firstChildRoute = routes[0];
        routeFirstBottomedChildRoute = _getRouteFirstBottomedChildRoute(firstChildRoute);
    }
    return routeFirstBottomedChildRoute;
}
function _getRouteFirstChildRoute(route) {
    let routeFirstChildRoute = route;
    let routes = route.routes;
    if (routes != null && routes instanceof Array && routes.length > 0) {
        routeFirstChildRoute = routes[0];
    }
    return routeFirstChildRoute;
}

function _buildRoutePathObj({ routeName, pathParams, queryParams }) {
    if (routeName == null) {
        throw new Error(`
            Wrong configuration in 'ui/routing/index.js' method 'transitionTo'.
            The routeName is required
        `);
    }

    let routePath = '/';
    let targetRoutes = _getRoute(routeName);
    if (targetRoutes != null) {
        routePath = targetRoutes.path;
    }

    if (pathParams == null || typeof pathParams !== "object") {
        pathParams = {};
    }
    for (let pathParamsKey in pathParams) {
        if (Object.prototype.hasOwnProperty.call(pathParams,pathParamsKey)) {
            routePath = routePath.replace(new RegExp(`:${pathParamsKey}`), pathParams[pathParamsKey]);
        }
    }

    let routePathObj = {
        pathname: routePath
    };
    if (queryParams == null || typeof queryParams !== "object") {
        queryParams = {};
    }
    let search = "";
    for (let queryParamsKey in queryParams) {
        if (Object.prototype.hasOwnProperty.call(queryParams,queryParamsKey)) {
            if (search === "") {
                search += `?${queryParamsKey}=${queryParams[queryParamsKey]}`;
            } else {
                search += `&${queryParamsKey}=${queryParams[queryParamsKey]}`;
            }
        }
    }
    if (search !== "") {
        routePathObj.search = search;
    }
    return routePathObj;
}

function transitionTo({ history, routeName, pathParams, queryParams, state = undefined }) {
    if (history == null || typeof history.push !== "function") {
        throw new Error(`
            Wrong configuration in 'ui/routing/index.js' method 'transitionTo'.
            The history is must be form react page component 'this.props.history'
        `);
    }
    let to = _buildRoutePathObj({ routeName, pathParams, queryParams });
    history.push(to, state);
}

function replaceTo({ history, routeName, pathParams, queryParams, state = undefined }) {
    if (history == null || typeof history.push !== "function") {
        throw new Error(`
            Wrong configuration in 'ui/routing/index.js' method 'transitionTo'.
            The history is must be form react page component 'this.props.history'
        `);
    }
    let to = _buildRoutePathObj({ routeName, pathParams, queryParams });
    history.replace(to, state);
}

function getQueryParams(searchUrl) {
    searchUrl = searchUrl || window.location.search;
    return Web.queryString.parse(searchUrl);
}

export {
    _initRoutes,
    _traverseRoutes,
    getRoutes,
    getUnfoldedRoutes,
    getRoutePath,
    getChildRoutes,
    getRouteFirstChildPath,
    getFirstChildPath,
    getRouteFirstBottomedChildRoutePath,
    transitionTo,
    getQueryParams,
    replaceTo
};

export default {
    _initRoutes,
    _traverseRoutes,
    getRoutes,
    getUnfoldedRoutes,
    getRoutePath,
    getChildRoutes,
    getRouteFirstChildPath,
    getFirstChildPath,
    getRouteFirstBottomedChildRoutePath,
    transitionTo,
    getQueryParams,
    replaceTo
};