// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PutLibraryFilesMoveByIdParam,
    PutLibraryFilesMoveByIdBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * move
 *
 * @export
 * @tags 案例库文件夹
 * @link [PUT] /libraryFiles/move/{id}
 * @param id
 */
export function putLibraryFilesMoveById(
    data: PutLibraryFilesMoveByIdBody,
    options: ApiOptions<PutLibraryFilesMoveByIdParam, never> & {
        segments: PutLibraryFilesMoveByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutLibraryFilesMoveByIdBody, PutLibraryFilesMoveByIdParam, never>(
        '/libraryFiles/move/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putLibraryFilesMoveById' }
    );
}

export const meta = [{ tags: ['案例库文件夹'], path: '/libraryFiles/move/{id}', method: 'put' }];
