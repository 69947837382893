import React from 'react';
import SystemLogRoute from './route';
import { Button, PageHeader, Space, Drawer, Spin, Input } from 'udesk-ui';
import './style.scss';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import UdeskSelect from 'Component/common/udesk-select';
import UdeskPagination from 'Component/common/udesk-pagination';
import XssComponent from 'udesk-react/src/components/xss';
import AutoSizeTable from 'Component/common/auto-size-table';
import ExportButton from 'Component/common/export-button';

export default class SystemLogTemplate extends SystemLogRoute {
    render() {
        let { locales, actions, state, privates } = this;

        return (
            // <div className="speech-recognition-page">
            <div className='udesk-qa-web-page system-log-index-page-wrapper'>
                <PageHeader className='udesk-qa-web-page-header' title={locales.menu.admin.systemLog.text} subTitle={locales.fix.onlyReservedFor7Days} />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root system-log-index-page'>
                        <div className='system-log-index-page-header'>
                            <Space>
                                <div>{locales.labels.messageTime}</div>
                                <UdeskDatePicker
                                    value={[state.startTime, state.endTime]}
                                    onChange={actions.selectTime}
                                />
                                <div>{locales.labels.messageType}</div>
                                <UdeskSelect
                                    value={state.messageType}
                                    optionList={privates.typeOptList}
                                    onChange={actions.selectType}
                                />
                                <div>{locales.labels.pushType}</div>
                                <UdeskSelect
                                    mode='multiple'
                                    value={state.messagePushType}
                                    optionList={privates.channelOptList}
                                    onChange={actions.selectChannel}
                                />
                                <div>{locales.labels.messageName}</div>
                                <Input value={state.messageNameKeyword} onChange={actions.onMessageNameKeywordChange} />
                                <Button type='primary' onClick={actions.search}>
                                    {locales.labels.query}
                                </Button>
                                <ExportButton disabled={state.modelLoading} exportDataCount={state.total} hasFilterCondition={true} onClick={actions.export} />
                            </Space>
                        </div>
                        <div className='system-log-index-page-body'>
                            <AutoSizeTable
                                subtractedHeight={
                                    document.getElementsByClassName('system-log-index-page-header')[0] &&
                                    window.getComputedStyle(
                                        document.getElementsByClassName('system-log-index-page-header')[0]
                                    ).height
                                }
                                loading={state.modelLoading}
                                pagination={false}
                                dataSource={state.dataSource}
                                columns={privates.columns}
                            />
                            {!state.total ? null : (
                                <UdeskPagination
                                    current={state.pageNum}
                                    pageSize={state.pageSize}
                                    // onShowSizeChange={actions.onShowSizeChange}
                                    total={state.total}
                                    onChange={actions.pageChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Drawer
                    title={locales.labels.noticeDetail}
                    placement='right'
                    closable={false}
                    onClose={actions.onClose}
                    visible={state.visible}
                    getContainer={false}
                    width={382}
                    style={{ position: 'absolute' }}
                    className='system-log-index-page-drawer'
                >
                    <Spin spinning={state.loading}>
                        <div className='system-log-index-page-drawer-list'>
                            {privates.detailMapKeys.map((key) => {
                                return (
                                    <div className='system-log-index-page-drawer-item'>
                                        <Space>
                                            <div className='system-log-index-page-drawer-item-label'>
                                                {locales.labels[key === 'content' ? 'messageContent' : key]}
                                            </div>
                                            <div className='system-log-index-page-drawer-item-content'>
                                                <XssComponent value={state.detailData[key]} />
                                            </div>
                                        </Space>
                                    </div>
                                );
                            })}
                            {state.detailData.dataLink && (
                                <Button href={state.detailData.dataLink} target="_blank" style={{ alignSelf: 'flex-start', paddingLeft: 0 }} size='small' type='link'>
                                    {locales.labels.messageLogBtn}
                                </Button>
                            )}
                        </div>
                    </Spin>
                </Drawer>
            </div>
        );
    }
}
