let normalFieldCommonConditionOperators = {
    keyword: {
        default: [
            'is',
            'not',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'is_null',
            'is_not_null',
        ],
        nested: [
            'is',
            'not',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'is_null',
            'is_not_null',
        ],
    },
    agent: {
        default: [
            'is',
            'not',
            'is_any',
            'not_any',
            'is_null',
            'is_not_null',
            'belongTo',
            'notBelongTo',
        ],
        nested: [
            'is',
            'not',
            'is_any',
            'not_any',
            'is_null',
            'is_not_null',
            'belongTo',
            'notBelongTo',
        ],
    },
    customer: {
        default: [
            'is',
            'not',
            'contains',
            'not_contains',
            'is_null',
            'is_not_null',
            // 'prefix_contains',
            // 'prefix_not_contains',
            // 'suffix_contains',
            // 'suffix_not_contains'
        ],
        nested: [
            'is',
            'not',
            'contains',
            'not_contains',
            'is_null',
            'is_not_null',
            // 'prefix_contains',
            // 'prefix_not_contains',
            // 'suffix_contains',
            // 'suffix_not_contains'
        ],
    },
    text: {
        default: [
            'is',
            'not',
            'contains',
            'not_contains',
            'is_null',
            'is_not_null',
            // 'prefix_contains',
            // 'prefix_not_contains',
            // 'suffix_contains',
            // 'suffix_not_contains'
        ],
        nested: [
            'is',
            'not',
            'contains',
            'not_contains',
            'is_null',
            'is_not_null',
            // 'prefix_contains',
            // 'prefix_not_contains',
            // 'suffix_contains',
            // 'suffix_not_contains'
        ],
    },
    number: {
        default: [
            'is',
            'not',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'is_null',
            'is_not_null',
        ],
        nested: [
            'is',
            'not',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'is_null',
            'is_not_null',
        ],
    },
    textArea: {
        default: [
            'is',
            'not',
            'contains',
            'not_contains',
            'is_null',
            'is_not_null',
            // 'prefix_contains',
            // 'prefix_not_contains',
            // 'suffix_contains',
            // 'suffix_not_contains'
        ],
        nested: [
            'is',
            'not',
            'contains',
            'not_contains',
            'is_null',
            'is_not_null',
            // 'prefix_contains',
            // 'prefix_not_contains',
            // 'suffix_contains',
            // 'suffix_not_contains'
        ],
    },
    dateTime: {
        default: [
            'is',
            'not',
            'between',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'today',
            'yesterday',
            'the_day_before_yesterday',
            'tomorrow',
            'next_seven_day',
            'last_seven_day',
            'this_week',
            'next_week',
            'last_week',
            'this_month',
            'next_month',
            'last_month',
            'this_year',
            'next_year',
            'last_year',
            // 'after',
            // 'before',
            'is_null',
            'is_not_null',
        ],
        nested: [
            'is',
            'not',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'today',
            'yesterday',
            'tomorrow',
            'next_seven_day',
            'last_seven_day',
            'this_week',
            'next_week',
            'last_week',
            'this_month',
            'next_month',
            'last_month',
            'this_year',
            'next_year',
            'last_year',
            // 'after',
            // 'before',
            'is_null',
            'is_not_null',
        ],
    },
    sigleListbox: {
        default: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
        nested: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
    },
    supervision: {
        default: ['hit', 'not_hit'],
        nested: ['hit', 'not_hit'],
    },
    keyEvents: {
        default: ['hit', 'not_hit'],
        nested: ['hit', 'not_hit'],
    },
    keywords: {
        default: ['all', 'all_not', 'any'],
        nested: ['all', 'all_not', 'any'],
    },
    multiListbox: {
        default: [
            'is',
            'not',
            'is_any',
            'not_any',
            'is_null',
            'is_not_null',
            'contains_all',
            'not_contains_all',
        ],
        nested: [
            'is',
            'not',
            'is_any',
            'not_any',
            'is_null',
            'is_not_null',
            'contains_all',
            'not_contains_all',
        ],
    },
    fieldWithLink: {
        default: ['is', 'not', 'contains', 'not_contains', 'is_null', 'is_not_null'],
        nested: ['is', 'not', 'contains', 'not_contains', 'is_null', 'is_not_null'],
    },
    percentage: {
        default: [
            'is',
            'not',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'is_null',
            'is_not_null',
        ],
        nested: [
            'is',
            'not',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'is_null',
            'is_not_null',
        ],
    },
    intelligentClassification: {
        default: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
        nested: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
    },
    inspector: {
        default: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
        nested: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
    },
    executeUser: {
        default: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
        nested: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
    },
    department: {
        default: ['contains'],
        nested: ['contains'],
    },
    customerTag: {
        default: ['contains'],
        nested: ['contains'],
    },
    wfAgent: {
        default: ['contains'],
        nested: ['contains'],
    },
    date: {
        default: [
            'is',
            'not',
            'between',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'today',
            'yesterday',
            'the_day_before_yesterday',
            'tomorrow',
            'next_seven_day',
            'last_seven_day',
            'this_week',
            'next_week',
            'last_week',
            'this_month',
            'next_month',
            'last_month',
            'this_year',
            'next_year',
            'last_year',
            // 'after',
            // 'before',
            'is_null',
            'is_not_null',
        ],
        nested: [
            'is',
            'not',
            'greater_than',
            'greater_than_eq',
            'less_than',
            'less_than_eq',
            'today',
            'yesterday',
            'tomorrow',
            'next_seven_day',
            'last_seven_day',
            'this_week',
            'next_week',
            'last_week',
            'this_month',
            'next_month',
            'last_month',
            'this_year',
            'next_year',
            'last_year',
            // 'after',
            // 'before',
            'is_null',
            'is_not_null',
        ],
    },
    cascader: {
        default: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
        nested: ['is', 'not', 'is_any', 'not_any', 'is_null', 'is_not_null'],
    },
};

export default normalFieldCommonConditionOperators;
