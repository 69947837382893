import React from 'react';
import { putAccountSetReminder } from 'src/api/account/set/reminder';
import Udesk from 'Udesk';
import { getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

class BillingCenterIndexComponent extends React.Component {
    static propTypes = {};
    static defaultProps = {};
    state = {
        surplus: '',
        deadline: '',
        callUse: '',
        textUse: '',
        data: [],
        isWarning: false,
        pageSize: 10,
        pageNum: 1,
        columns: [],
        total: 0,
        startTime: moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00',
        endTime: moment().format('YYYY-MM-DD') + ' 23:59:59',
        warningColor: 'rgba(251,81,39,1)',
        normalColor: 'rgba(0,0,0,0.85);',
        callWarning: false,
        enabledImInspect: 1,
        enabledCallInspect: 1,
        visibleLimitEdit: false,
        callLimit: 50,
        dialogueLimit: 15,
        visibleDetail: false,
        detailColumns: [
            {
                title: /* ASR名称 */UdeskLocales['current'].pages.billingCenter.component.aSRName,
                dataIndex: 'asrName',
                key: 'asrName',
            },
            {
                title: /* 语言 */UdeskLocales['current'].pages.billingCenter.component.language,
                dataIndex: 'languageName',
                key: 'languageName',
            },
            {
                title: /* 剩余配额 */UdeskLocales['current'].pages.billingCenter.component.remainingQuota,
                dataIndex: 'balance',
                key: 'balance',
                render(value, item) {
                    return <span>{value}{/* 小时 */}{UdeskLocales['current'].pages.billingCenter.component.hours}</span>;
                },
            },
            {
                title: /* 已用配额 */UdeskLocales['current'].pages.billingCenter.component.usedQuota,
                dataIndex: 'callUse',
                key: 'callUse',
                render(value, item) {
                    return <span>{value}{/* 小时 */}{UdeskLocales['current'].pages.billingCenter.component.hours}</span>;
                },
            },
        ],
        detailDataSource: [{ name: 123 }],
    };
    privates = {
        expandedRowKeys: [],
    };

    actions = {
        showLimitEdit() {
            this.setState({
                visibleLimitEdit: true,
            });
        },
        handleCancel() {
            this.setState({
                visibleLimitEdit: false,
            });
        },
        showBalanceDetail() {
            this.setState({
                visibleDetail: true,
            });
        },
        handleBalanceDetailCancel() {
            this.setState({
                visibleDetail: false,
            });
        },
        handleOk() {
            const { callLimit, dialogueLimit } = this.state;
            putAccountSetReminder(
                {
                    callReminderHour: callLimit,
                    imReminderDay: dialogueLimit,
                },
                {
                    successMsg: this.locales.business.info.editSuccess,
                }
            ).then(() => {
                this.actions.handleCancel();
            });
        },
        onLimitValueChange(type, value) {
            this.setState({
                [type]: value,
            });
        },
        exportExcel() {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`exps/account-charge`, sdkOptions);
            let params = {
                startTime: this.state.startTime,
                endTime: this.state.endTime,
                total: this.state.total,
                systemModule: getSystemModule(),
            };
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(this.locales.fix.exportToTaskCenter);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        checkTime(dateStrings) {
            this.setState({
                startTime: dateStrings[0],
                endTime: dateStrings[1],
            });
            // this.privates.startTime = dateStrings[0] + ' 23:59:59';
            // this.privates.endTime = dateStrings[1] + ' 23:59:59';
        },
        onShowSizeChange(current, pageSize) {
            this.privates.expandedRowKeys = [];
            this.setState(
                {
                    pageSize,
                    pageNum: 1,
                },
                () => {
                    this.actions.reloadAsyncModel({
                        startTime: this.state.startTime,
                        endTime: this.state.endTime,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                    });
                }
            );
        },
        showTotal(total, range) {
            let { locales } = this;
            // return `共${total}条，${total/range[1]}页`;
            return Udesk.utils.string.format(
                locales.components.pages.billingCenter.table.page,
                total,
                Math.ceil(total / this.state.pageSize)
            );
        },
        getSearchData() {
            this.privates.expandedRowKeys = [];
            this.actions.update();
            this.actions.reloadAsyncModel({
                startTime: this.state.startTime,
                endTime: this.state.endTime,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
            });
        },
        resetSearchData() {
            this.privates.expandedRowKeys = [];
            this.setState(
                {
                    startTime:
                        getFormatDate(new Date(new Date().getTime() - 30 * 86400000)) + ' 23:59:59',
                    endTime: getFormatDate(new Date()) + ' 23:59:59',
                },
                () => {
                    this.actions.reloadAsyncModel({
                        startTime: this.state.startTime,
                        endTime: this.state.endTime,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                    });
                }
            );
            // this.state.startTime = getFormatDate(new Date(new Date().getTime()-30*86400000)) + ' 23:59:59';
            // this.state.endTime = getFormatDate(new Date()) + ' 23:59:59';
        },
        pageChange(page) {
            this.privates.expandedRowKeys = [];
            this.setState(
                {
                    pageNum: page,
                },
                () => {
                    this.actions.reloadAsyncModel({
                        startTime: this.state.startTime,
                        endTime: this.state.endTime,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                    });
                }
            );
        },
        getFormatDate(date) {
            var seperator1 = '/';
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        },
        onExpand(expanded, record) {
            if (expanded) {
                this.privates.expandedRowKeys.push(record.id);
            } else {
                this.privates.expandedRowKeys = this.privates.expandedRowKeys.filter(
                    (key) => key !== record.id
                );
            }
            this.actions.update();
            let { sdkOptions } = this.props;
            let dataClone = JSON.parse(JSON.stringify(this.state.data));
            let url = Udesk.business.apiPath.concatApiPath(
                `account/charges/${record.id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        let list = dataClone.map((data) => {
                            if (data.id === record.id) {
                                data.children = resp.data;
                            }
                            return data;
                        });
                        resolve(resp);
                        this.setState({
                            data: list,
                        });
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
    };

    componentDidMount() {}
}

function getFormatDate(date) {
    var seperator1 = '-';
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = '0' + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}

export default BillingCenterIndexComponent;
