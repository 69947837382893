// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetIntelligentPartnerTagsByIdParam,
    BaseResponseIntelligentPartnerTagFoundResponse,
    PutIntelligentPartnerTagsByIdParam,
    PutIntelligentPartnerTagsByIdBody,
    BaseResponseVoid,
    DeleteIntelligentPartnerTagsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 智能陪练-客户标签
 * @link [GET] /intelligentPartnerTags/{id}
 * @param id
 */
export function getIntelligentPartnerTagsById(
    options: ApiOptions<GetIntelligentPartnerTagsByIdParam, never> & {
        segments: GetIntelligentPartnerTagsByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerTagFoundResponse> {
    return request<never, GetIntelligentPartnerTagsByIdParam, never>(
        '/intelligentPartnerTags/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentPartnerTagsById' }
    );
}

/**
 * 编辑客户标签
 *
 * @export
 * @tags 智能陪练-客户标签
 * @link [PUT] /intelligentPartnerTags/{id}
 * @param id
 */
export function putIntelligentPartnerTagsById(
    data: PutIntelligentPartnerTagsByIdBody,
    options: ApiOptions<PutIntelligentPartnerTagsByIdParam, never> & {
        segments: PutIntelligentPartnerTagsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutIntelligentPartnerTagsByIdBody, PutIntelligentPartnerTagsByIdParam, never>(
        '/intelligentPartnerTags/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putIntelligentPartnerTagsById' }
    );
}

/**
 * 删除客户标签
 *
 * @export
 * @tags 智能陪练-客户标签
 * @link [DELETE] /intelligentPartnerTags/{id}
 * @param id
 */
export function deleteIntelligentPartnerTagsById(
    options: ApiOptions<DeleteIntelligentPartnerTagsByIdParam, never> & {
        segments: DeleteIntelligentPartnerTagsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentPartnerTagsByIdParam, never>(
        '/intelligentPartnerTags/{id}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteIntelligentPartnerTagsById' }
    );
}

export const meta = [
    { tags: ['智能陪练-客户标签'], path: '/intelligentPartnerTags/{id}', method: 'get' },
    { tags: ['智能陪练-客户标签'], path: '/intelligentPartnerTags/{id}', method: 'put' },
    { tags: ['智能陪练-客户标签'], path: '/intelligentPartnerTags/{id}', method: 'delete' },
];
