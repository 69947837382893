import React from 'react';
import Udesk from 'Udesk';
import _filter from 'lodash-es/filter';
import _size from 'lodash-es/size';
import { getSemanticTagDrawer } from 'src/api/semantic-tag/drawer';

class SmartTagsSimpleIndexComponent extends React.Component {
    static propTypes = {};
    static defaultProps = {};
    state = {};
    privates = {
        loading: false,
        detailLoading: false,
        smartTagsList: [],
        activiteTagId: null,
        isFront: true,
        searchValue: '',
        recallKeyWord: [],
        recallKeyWordCount: 0,
        standardSentences: '',
        currentTag: {},
        similaSentences: [],
        similaSentencesCount: 1,
        form: React.createRef(),
        hasKeyword: false,
        clusteringUrl: null,
        isClustering: false,
        xhr: null,
        sentenceCount: 0,
        keyword: '',
    };

    actions = {
        scrollTo(id) {
            const list = this.privates.smartTagsList;
            if (list) {
                const index = list.findIndex((item) => item.id === id);
                const $content = this.privates.smartTagLitContent;
                if ($content) {
                    $content.scrollTo(0, index * 60);
                }
            }
        },
        setActiveTagId(value) {
            this.privates.activiteTagId = value;
            this.actions.update();
        },
        search(value) {
            this.privates.clusteringUrl = null;
            this.actions.update();
            this.actions.reloadAsyncModel();
        },
        searchValueChange(e) {
            this.privates.searchValue = e.target.value;
            this.actions.update();
        },
        createNewSmartTag() {
            this.privates.currentTag = {
                activeFlag: 1,
                checkIds: [],
                customRules: [],
                recallWords: [],
                standardContent: '',
                tags: [],
                title: '',
            };
            this.privates.isFront = false;
            this.trigger('changeTabsDown');
            this.privates.similaSentences = [];
            this.privates.recallKeyWord = [];
            this.privates.form.current.setFieldsValue({
                name: '',
                standardSentences: '',
                recallRegular: '',
            });
            this.actions.update();
        },
        createNewClusteringSmartTag() {
            let res = this.props.smartTagsCallBack();
            let detailSentenceList = res.checkedSentence;
            let mainSentence = res.mainSentence;
            this.privates.currentTag = {
                activeFlag: 1,
                checkIds: [],
                customRules: [],
                recallWords: [],
                standardContent: '',
                tags: [],
                title: '',
            };
            this.privates.isFront = false;
            this.trigger('changeTabsDown');
            this.privates.recallKeyWord = [];
            let similaSentencesList = {};
            this.privates.similaSentences = detailSentenceList
                .filter((i) => i.sentence !== mainSentence.mainSentence)
                .map((item, index) => {
                    similaSentencesList[`similaSentences${index}`] = item.sentence;
                    return {
                        ...item,
                        content: item.sentence,
                        standardFlag: 0,
                        order: index,
                    };
                });
            this.privates.similaSentences.push({
                ...mainSentence,
                standardFlag: 1,
                content: mainSentence.mainSentence,
                order: this.privates.similaSentences.length,
            });
            this.privates.similaSentencesCount = _size(this.privates.similaSentences) + 1;
            this.privates.currentTag.tags = this.privates.similaSentences;
            this.privates.form.current.setFieldsValue({
                name: '',
                standardSentences:
                    mainSentence && mainSentence.mainSentence ? mainSentence.mainSentence : '',
                recallRegular: '',
                ...similaSentencesList,
            });
            this.actions.update();
        },
        tranToDetail(tag) {
            //触发动画
            this.privates.detailLoading = true;
            this.privates.isFront = false;
            this.trigger('changeTabsDown');
            this.actions.update();
            this.privates.form.current.resetFields();
            let {
                sdkOptions,
                // match
            } = this.props;

            let { searchValue } = this.privates;

            let params = {
                keyword: searchValue,
                groupId: tag.id,
            };
            if (this.privates.searchValue) {
                this.privates.hasKeyword = true;
            }
            let url = Udesk.business.apiPath.concatApiPath(`semantic-tag/drawerDetail`, sdkOptions);
            return new Promise((resolve, reject) => {
                Udesk.ajax.get(url, params).then(
                    (resp) => {
                        this.privates.currentTag = resp.data;
                        //回显相似句
                        let similaSentencesList = {};
                        this.privates.similaSentences = this.privates.currentTag.tags.map(
                            (item, index) => {
                                similaSentencesList[`similaSentences${index}`] = item.content;
                                return {
                                    ...item,
                                    order: index,
                                };
                            }
                        );
                        // 回显召回关键词
                        let recallKeyWordList = {};
                        this.privates.recallKeyWord = this.privates.currentTag.recallWords
                            ? this.privates.currentTag.recallWords.map((item, index) => {
                                  let count = index + 1;
                                  recallKeyWordList['recallWords' + count] = item;
                                  this.privates.recallKeyWordCount = count;
                                  return {
                                      id: 'recallWords' + count,
                                      text: item,
                                  };
                              })
                            : [];
                        this.privates.form.current.setFieldsValue({
                            name: this.privates.currentTag.title,
                            standardSentences: this.privates.similaSentences.find(
                                (item) => item.standardFlag === 1
                            )
                                ? this.privates.similaSentences.find(
                                      (item) => item.standardFlag === 1
                                  ).content
                                : '',
                            ...similaSentencesList,
                            ...recallKeyWordList,
                            recallRegular: this.privates.currentTag.recallRegular,
                        });
                        this.privates.similaSentencesCount =
                            _size(this.privates.similaSentences) + 1;
                        this.privates.detailLoading = false;
                        this.actions.update();

                        resolve(resp);
                    },
                    (reason) => {
                        this.privates.loading = false;
                        Udesk.ui.notify.error(reason.errorMsg);
                        // this.privates.smartTagsList = [];
                        // this.actions.update();
                        reject(reason);
                    }
                );
            });
        },
        backToIndex() {
            this.privates.isFront = true;
            this.trigger('changeTabsUp');
            if (this.privates.isClustering) {
                this.actions.reloadAsyncModel({ keyword: this.privates.keyword });
            } else {
                this.actions.reloadAsyncModel();
            }
        },
        selectSmartTag(tag) {
            if (
                this.props.smartTagsCallBack &&
                typeof this.props.smartTagsCallBack === 'function'
            ) {
                if (!this.privates.isClustering) {
                    if (this.privates.isNotOnlyOneActiveKey) {
                        let activeTagIds = this.privates.activiteTagId;
                        if (activeTagIds) {
                            activeTagIds = [...activeTagIds];
                        } else {
                            activeTagIds = [];
                        }
                        const index = activeTagIds.findIndex((id) => id === tag.id);
                        if (index === -1) {
                            activeTagIds.push(tag.id);
                        } else {
                            activeTagIds.splice(index, 1);
                        }
                        this.privates.activiteTagId = activeTagIds;
                    } else {
                        this.privates.activiteTagId = [tag.id];
                    }

                    this.privates.searchValue = '';

                    if (this.props.smartTagsCallBack) {
                        const result = this.props.smartTagsCallBack(tag);
                        if (typeof result === 'undefined') {
                            this.props.siderClose();
                        } else {
                            if (result) {
                                this.props.siderClose();
                            }
                        }
                    }
                    this.actions.update();
                }
            }
        },
        tagging(tag) {
            if (
                this.props.smartTagsCallBack &&
                typeof this.props.smartTagsCallBack === 'function'
            ) {
                let res = this.props.smartTagsCallBack();
                let { sdkOptions } = this.props;
                if (!res.mainSentence || !res.checkedSentence) {
                    return;
                }
                let params = {
                    id: tag.id,
                    detailIdList: res.checkedSentence.map((i) => i.id),
                    clusterId: res.mainSentence.id,
                };
                let url = Udesk.business.apiPath.concatApiPath(`semantic-tag/mark`, sdkOptions);
                Udesk.ajax.post(url, params).then(
                    (resp) => {
                        this.privates.searchValue = '';
                        this.props.siderClose();
                        res && res.taggingSuccessCallBack && res.taggingSuccessCallBack();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            }
        },
        getRecallKeyWord(count) {
            let { recallKeyWord } = this.privates;
            recallKeyWord = [
                ...recallKeyWord,
                {
                    id: 'recallWords' + count,
                    text: '',
                },
            ];
            this.privates.recallKeyWord = recallKeyWord;
        },
        recallKeyWordAdd() {
            let { recallKeyWordCount } = this.privates;
            let count = recallKeyWordCount + 1;
            this.actions.getRecallKeyWord(count);
            this.privates.recallKeyWordCount = count;
            this.actions.update();
        },
        recallKeyWordDel(value, index) {
            let { recallKeyWord } = this.privates;
            let list = _filter(recallKeyWord, (current) => current.id !== value);
            this.privates.recallKeyWord = list;
            this.actions.update();
            this.privates.currentTag.recallWords = this.privates.recallKeyWord.map(
                (item) => item.text
            );
        },
        similaSentencesDel(value) {
            let { similaSentences } = this.privates;
            let list = _filter(similaSentences, (current) => current.order !== value);
            this.privates.similaSentences = list;
            this.privates.currentTag.tags = [...this.privates.similaSentences];
            this.actions.update();
        },
        similaSentencesAdd(value) {
            let { similaSentences, similaSentencesCount } = this.privates;
            let count = similaSentencesCount + 1;
            let list = [
                ...similaSentences,
                {
                    order: count,
                    content: '',
                    standardFlag: value,
                    similarityDegree: value === 2 ? 80 : '',
                },
            ];
            this.privates.similaSentences = list;
            this.privates.similaSentencesCount = count;
            this.actions.update();
        },
        similaSentencesRecommendAdd(recommends) {
            let { similaSentences, similaSentencesCount } = this.privates;

            let count = similaSentencesCount;
            let similaSentencesList = {};

            const sentences = (recommends || []).map((r) => {
                count = count + 1;
                similaSentencesList[`similaSentences${count}`] = r.value;

                return {
                    order: count,
                    content: r.value,
                    standardFlag: 0,
                    similarityDegree: '',
                };
            });

            this.privates.similaSentences = [...similaSentences, ...sentences];
            this.privates.similaSentencesCount = count;
            this.privates.form.current.setFieldsValue({
                ...similaSentencesList,
            });
            this.privates.currentTag.tags = this.privates.similaSentences;
            this.actions.update();
        },
        onValuesChange(changedValues, allValues) {
            if (Object.keys(changedValues)[0].includes('similaSentences')) {
                let index = this.privates.similaSentences.findIndex(
                    (item) => `similaSentences${item.order}` === Object.keys(changedValues)[0]
                );
                if (index > -1) {
                    this.privates.similaSentences[index].content = Object.values(changedValues)[0];
                }
                this.privates.currentTag.tags = this.privates.similaSentences;
            } else if (Object.keys(changedValues)[0].includes('name')) {
                this.privates.currentTag.title = Object.values(changedValues)[0];
            } else if (Object.keys(changedValues)[0].includes('standardSentences')) {
                let index = this.privates.similaSentences.findIndex(
                    (item) => item.standardFlag === 1
                );
                if (index > -1) {
                    this.privates.similaSentences[index].content = Object.values(changedValues)[0];
                } else {
                    this.privates.similaSentences.push({
                        content: Object.values(changedValues)[0],
                        order: this.privates.similaSentencesCount,
                        similarityDegree: null,
                        standardFlag: 1,
                    });
                    this.privates.similaSentencesCount += 1;
                }
                this.privates.currentTag.tags = this.privates.similaSentences;
            } else if (Object.keys(changedValues)[0].includes('recallRegular')) {
                this.privates.currentTag.recallRegular = Object.values(changedValues)[0];
            } else if (Object.keys(changedValues)[0].includes('recallWords')) {
                let index = this.privates.recallKeyWord.findIndex(
                    (item) => item.id === Object.keys(changedValues)[0]
                );
                if (index > -1) {
                    this.privates.recallKeyWord[index].text = Object.values(changedValues)[0];
                }
                this.privates.currentTag.recallWords = this.privates.recallKeyWord.map(
                    (item) => item.text
                );
            }
        },
        onFinish(values) {
            let { sdkOptions } = this.props;
            this.privates.currentTag.tags = this.privates.currentTag.tags.filter(
                (item) => item.content
            );
            if (Array.isArray(this.privates.currentTag.recallWords)) {
                this.privates.currentTag.recallWords = this.privates.currentTag.recallWords.filter(
                    (item) => item
                );
            }
            return new Promise((resolve, reject) => {
                if (this.privates.currentTag.id) {
                    let url = Udesk.business.apiPath.concatApiPath(
                        `semantic-tag/${this.privates.currentTag.id}`,
                        sdkOptions
                    );
                    Udesk.ajax.put(url, this.privates.currentTag).then(
                        (resp) => {
                            resolve(resp.data);
                            // this.privates.isClustering = false;
                            // this.actions.update();
                            Udesk.ui.notify.success(this.locales.business.info.saveSuccess);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                } else {
                    let url = Udesk.business.apiPath.concatApiPath(`semantic-tag/save`, sdkOptions);
                    let params = { ...this.privates.currentTag };
                    if (this.props.smartTagsCallBack && this.privates.isClustering) {
                        let res = this.props.smartTagsCallBack();
                        params.clusterId = res.mainSentence.id;
                        params.checkClusterDetailIds = res.checkedSentence
                            .filter((sentence) =>
                                this.privates.currentTag.tags.some(
                                    (tag) => tag.content === sentence.sentence
                                )
                            )
                            .map((i) => i.id);
                    }
                    Udesk.ajax.post(url, params).then(
                        (resp) => {
                            resolve(resp.data);

                            if (this.privates.isClustering) {
                                let res = this.props.smartTagsCallBack();
                                this.privates.isClustering = false;
                                this.actions.update();
                                this.props.siderClose();
                                res && res.taggingSuccessCallBack && res.taggingSuccessCallBack();
                            }

                            Udesk.ui.notify.success(this.locales.business.info.saveSuccess);
                            this.actions.backToIndex();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                }
            });
        },
        searchInDetail(value) {
            let { locales } = this;
            if (this.privates.currentTag && this.privates.currentTag.id) {
                this.actions.tranToDetail(this.privates.currentTag);
            } else {
                Udesk.ui.notify.error(locales.components.pages.smartTools.smartTags.notSaveError);
            }
        },
        openInNewPage() {
            window.open(
                `/site/semantic-intelligence/semantic-tags/edit/${this.privates.currentTag.id}`
            );
        },
        addFromKeyword(value) {
            let { similaSentences, similaSentencesCount } = this.privates;
            let count = similaSentencesCount + 1;
            let list = [
                ...similaSentences,
                {
                    order: count,
                    content: '',
                    standardFlag: value,
                    similarityDegree: value === 2 ? 80 : '',
                },
            ];
            this.privates.similaSentences = list;
            this.privates.similaSentencesCount = count;
            this.privates.form.current.setFieldsValue({
                [`similaSentences${this.privates.similaSentencesCount}`]: this.privates.searchValue,
            });
            this.actions.onValuesChange({
                [`similaSentences${this.privates.similaSentencesCount}`]: this.privates.searchValue,
            });
            this.privates.hasKeyword = false;
            this.actions.update();
        },
        getKeyword(value) {
            this.privates.searchValue = value;
            if (!this.privates.isFront) {
                this.privates.hasKeyword = true;
            }
            this.actions.update();
            if (this.privates.isFront) {
                this.actions.reloadAsyncModel();
            }
        },
    };

    componentDidMount() {}
    parseProps({ props, prevProps }) {
        return {
            activiteTagId:
                typeof props.smartTagsKeyword === 'undefined'
                    ? []
                    : Array.isArray(props.smartTagsKeyword)
                    ? props.smartTagsKeyword
                    : [props.smartTagsKeyword],
        };
    }
    asyncModel(changedParams) {
        let { sdkOptions } = this.props;

        let { searchValue } = this.privates;

        let params = {
            keyword: searchValue,
        };
        if (changedParams != null) {
            this.privates.keyword = changedParams.keyword;
            Object.assign(params, changedParams);
        }
        this.privates.loading = true;
        this.actions.update();
        // let url = Udesk.business.apiPath.concatApiPath(`semantic-tag/drawer`, sdkOptions);
        let url = '';
        if (this.privates.clusteringUrl) {
            url = Udesk.business.apiPath.concatApiPath(this.privates.clusteringUrl, sdkOptions);
            this.privates.isClustering = true;
            let res = this.props.smartTagsCallBack();
            this.privates.sentenceCount = res.checkedSentence.length;
            this.actions.update();
        }
        if (this.privates.xhr) {
            this.privates.xhr.abort && this.privates.xhr.abort();
        }
        return new Promise((resolve, reject) => {
            this.privates.xhr = url
                ? Udesk.ajax.get(url, params)
                : getSemanticTagDrawer({
                      params,
                      useCache: true,
                  });
            this.privates.xhr.then(
                (resp) => {
                    this.privates.smartTagsList = resp.data;
                    this.privates.loading = false;
                    // this.privates.xhr = null;
                    this.actions.update();
                    resolve(resp);
                },
                (reason) => {
                    if (reason.statusText !== 'abort') {
                        this.privates.loading = false;
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                    this.actions.update();
                    reject(reason);
                }
            );
        });
    }
}

export default SmartTagsSimpleIndexComponent;
