import React from 'react';
import Udesk from 'Udesk';

class sentenceSearchIndexComponent extends React.Component {
    static propTypes = {

    };
    static defaultProps = {

    };
    state = {
        total: 0,
    };
    privates = {
        inputValue: '',
        inputValueForSearch: '',
        pageNum: 1,
        pageSize: 20,
        sentenceDataList: [//按词索句中句子的合辑
        ],
        sentencesRef: React.createRef(),
        keys: [],
        searchFlag: false,
        loading: false,
        showTransBtn: false,
    };

    actions = {
        inputValueChangeHandle(e){
            this.privates.inputValue = e.target.value;
            this.actions.update();
        },
        inputValueFormatter(){
            this.privates.inputValue = this.privates.inputValue.trim();
            this.actions.update();
        },
        search(){
            //点击搜索按钮会失去焦点，自动调用formatter
            if (this.privates.inputValue === "") return;
            this.privates.pageNum = 1;//因不显示页码 故无须刷新
            this.privates.searchFlag = true;
            this.privates.showTransBtn = true;
            this.privates.inputValueForSearch = this.privates.inputValue;
            this.actions.getSearchData();
        },
        keyupadditem(e){
            if (e.which !== 13) return;
            this.actions.inputValueFormatter();
            this.actions.search();
        },
        //按词索句的请求方法
        getSearchData(){
            this.privates.sentenceDataList = [];
            this.privates.loading = true;
            this.actions.update();

            let pamars = {
                keywords: this.privates.inputValueForSearch,
                pageNum: this.privates.pageNum,
                pageSize: this.privates.pageSize
            };
            let url = `/backend/semantic-tag/wordgetsentence`;
            Udesk.ajax.post(url, pamars).then(
                resp => {
                    this.privates.sentenceDataList = resp.data;
                    this.privates.loading = false;
                    this.setState({
                        total: resp.paging.total
                    }, () => {
                        document.getElementById('drawerBodySentenceContent').scrollTop = 0;
                        this.actions.addHighlight();
                        this.privates.searchFlag = false;
                    });
                    // this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        addHighlight(){
            const keywords = this.privates.inputValueForSearch.split(/\s+/);
            let sentencesDOM = this.privates.sentencesRef.current;
            let sentencesElems = sentencesDOM.getElementsByClassName('smart-tools-sider-bar-index-page-drawer-body-sentence-content-item-text');
            for (let i = 0; i < sentencesElems.length; i++) {
                // Udesk.utils.dom.clearHighlight(sentencesElems[i]);
                keywords.forEach(item => {
                    Udesk.utils.dom.highlightText(sentencesElems[i], item);
                });
            }
            // this.actions.update();
        },
        pageUp(){
            if(this.privates.pageNum > 1){
                this.privates.pageNum -= 1;
                this.actions.getSearchData();
                this.actions.update();
            }
        },
        pageDown(){
            if(this.privates.pageNum * this.privates.pageSize < this.state.total){
                this.privates.pageNum += 1;
                this.actions.getSearchData();
                this.actions.update();
            }
        },
        learnMore(id, dataSource){
            // this.trigger('learnMore', id, dataSource);
            if(id){
                if(dataSource === 1){
                    window.open(`/site/call-manage/view/${id}?newTab=true&keyword=${this.privates.inputValueForSearch}`);
                }
                if(dataSource === 2){
                    window.open(`/site/dialogue-manage/view/${id}?newTab=true&keyword=${this.privates.inputValueForSearch}`);
                }
            }
        },
        setKey(index){
            if(this.privates.searchFlag) this.privates.keys[index] = Udesk.utils.string.uuid();
            return this.privates.keys[index];
        },
        goToCallList(){
            window.open(`/site/call-manage/index?newTab=true&keyword=${this.privates.inputValueForSearch}`);
        },
        goToImList(){
            window.open(`/site/dialogue-manage/index?newTab=true&keyword=${this.privates.inputValueForSearch}`);
        }
    };

    componentDidMount(){
    }
}

export default sentenceSearchIndexComponent;
