import Udesk from 'Udesk';
import React, { useState, useEffect, useMemo } from 'react';
import {
    TreeSelect,
    PageHeader,
    Select,
    Form,
    Input,
    Radio,
    Button,
    InputNumber,
    Checkbox,
    Tabs,
    Row,
    Col,
    Alert,
    Tooltip,
    Icon,
    Switch,
} from 'udesk-ui';
import './style-new.scss';
import UdeskLocales from 'UdeskLocales';
import TasksCheckPointDetailComponent from './component-new';
import TaskConditionDictionaries from 'Component/pages/components/task-condition-dictionaries';
import TaskGearOptionList from 'Component/pages/components/task-gear-option-list';
import ConditionListGather from 'Component/pages/components/check-point-condition-list-gather';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getInspectionPointsInlinePointBySource } from 'src/api/inspectionPoints/inlinePoint/{source}';
import { NA } from './components/NA';
import { AutomaticPoint } from './components/AutomaticPoint';
import { Flow } from './components/Flow/Flow';
import CustomModelsSelect from './components/CustomModelsSelect';
import MsgTypesSelect from './components/MsgTypesSelect';
import { TypeEnum } from 'Component/pages/semantic-intelligence/intelligent-model/const';
import { hasFeature } from 'src/util/permit';
// const locales = UdeskLocales['current'];

const { TabPane } = Tabs;
const { Option } = Select;
const grid = 8;
const getListStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});
const getListDropStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    width: '100%',
    border: '1px solid #e8e8e8',
    minHeight: '40px',
});
const getListDropStyles = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    minWidth: '400px',
    border: '1px solid #e8e8e8',
    minHeight: '40px',
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 8px',
    minWidth: '32px',
    height: '24px',
    color: 'rgba(0,0,0,0.65)',
    borderRadius: '3px',
    margin: `0 ${grid}px 0 0 `,
    textAlign: 'center',
    lineHeight: '24px',
    // 拖拽的时候背景变化
    background: isDragging ? 'lightgreen' : 'rgba(232,244,255,1)',
    marginBottom: '3px',
    ...draggableStyle,
});

const TypeTooltip = ({ isWechatQa }) => (
    <div style={{ whiteSpace: 'pre-wrap' }}>
        {Object.keys(
            UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.typeTips
        )
            .filter((i) => (isWechatQa ? i !== 'model' : true))
            .map((i) => (
                <div>
                    {
                        UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration
                            .typeTips[i]
                    }
                </div>
            ))}
    </div>
);

const IntelligentModel = (props) => {
    const { value, onChange, source } = props;
    const [inlinePoints, setInlinePoints] = useState([]);
    const [inlinePointId, setInlinePointId] = useState(undefined);
    useEffect(() => {
        getInspectionPointsInlinePointBySource({
            segments: {
                source,
            },
        }).then((resp) => {
            if (Array.isArray(resp.data) && resp.data.length) {
                setInlinePoints(resp.data);
                setInlinePointId(resp.data[0].id);
                const newInlinePoint = resp.data.find((i) =>
                    i.operators.some((o) => o.id === value)
                );
                if (newInlinePoint && newInlinePoint.id !== inlinePointId) {
                    setInlinePointId(newInlinePoint.id);
                }
            }
        });
    }, []);
    const options = useMemo(() => {
        const selectedInlinePoint = inlinePoints.filter((i) => i.id === inlinePointId);
        let ret = [];
        if (selectedInlinePoint.length) {
            ret =
                selectedInlinePoint?.[0]?.operators.map((i) => ({
                    ...i,
                    value: i.id,
                    label: i.name,
                })) || [];
        }
        return ret;
    }, [inlinePoints, inlinePointId]);
    return (
        <div
            style={{
                backgroundColor: '#F7F7F7',
                padding: 16,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Radio.Group
                value={inlinePointId}
                onChange={(e) => setInlinePointId(e.target.value)}
                style={{ marginBottom: 16 }}
            >
                {inlinePoints.map((item) => (
                    <Radio.Button value={item.id}>{item.name}</Radio.Button>
                ))}
            </Radio.Group>
            <Select style={{ width: 200 }} value={value} onChange={onChange}>
                {options.map((item) => (
                    <Select.Option {...item}>
                        <Tooltip
                            placement="right"
                            title={
                                <div
                                    style={{ color: 'rgba(0, 0, 0, 0.45)', whiteSpace: 'pre-wrap' }}
                                >
                                    <Icon
                                        style={{ color: '#1A6EFF', marginRight: 8 }}
                                        type="InfoCircleFilled"
                                        antdIcon={true}
                                    />
                                    {item.desc}
                                </div>
                            }
                            color="#E6F3FF"
                        >
                            <div>{item.label}</div>
                        </Tooltip>
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default class TasksCheckPointDetailTemplate extends TasksCheckPointDetailComponent {
    render() {
        let { actions, privates, state, props, locales } = this;
        let { loading, hasOldSilent } = this.privates;
        const isSales =
            props.route.name === 'sessionLabelTagsEdit' ||
            props.route.name === 'keyEventsTagsEdit' ||
            props.route.name === 'sessionLabelTagsNew' ||
            props.route.name === 'keyEventsTagsNew';
        const isTicket =
            this.props.sdkOptions.props.task?.inspectDataSource ===
            Udesk.enums.inspectDataSources.ticket.id;

        return (
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    onBack={() => actions.transitionToCheckPointIndex()}
                    title={
                        UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration[
                            privates.isWechatQa ? 'wechatTitle' : 'title'
                        ]
                    }
                />
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root tasks-manage-template-configuration-detail-body-content">
                        <div>
                            {hasOldSilent && (
                                <Alert
                                    message={
                                        UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                            .configuration.hasOldSilentTip
                                    }
                                    style={{ margin: '-24px -24px 16px' }}
                                    type="info"
                                    showIcon
                                />
                            )}
                            <div className="tasks-manage-template-configuration-detail-form">
                                <Form
                                    wrapperCol={{ span: 23, offset: 1 }}
                                    initialValues={{
                                        type: privates.type,
                                        tagName: privates.name,
                                        desc: privates.remark,
                                        status: privates.status,
                                        weComSourceType: privates.weComSourceType,
                                        ruleType: privates.ruleType,
                                        applyMessageTypeList: privates.applyMessageTypeList,
                                    }}
                                    ref={this.formRef}
                                    onValuesChange={actions.onFormValuesChange}
                                >
                                    <Form.Item
                                        name="tagName"
                                        label={
                                            UdeskLocales.current.pages.tasks.manage.template
                                                .checkPoint.configuration.neme
                                        }
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: true,
                                                message:
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.configuration.nameEmpty,
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ width: 304 }}
                                            placeholder={
                                                UdeskLocales.current.pages.tasks.manage.template
                                                    .checkPoint.configuration.desPlaceHolder
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="desc"
                                        style={{ marginLeft: 10 }}
                                        label={
                                            UdeskLocales.current.pages.tasks.manage.template
                                                .checkPoint.configuration.desc
                                        }
                                        rules={[{ max: 1024 }]}
                                    >
                                        <Input.TextArea
                                            style={{ width: 500 }}
                                            placeholder={
                                                UdeskLocales.current.pages.tasks.manage.template
                                                    .checkPoint.configuration.desPlaceHolder
                                            }
                                            // showCount={true}
                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                        />
                                    </Form.Item>

                                    {hasFeature('semantic:applyMsg:root') ? (
                                        <Form.Item
                                            label={
                                                /* 应用消息类型 */ UdeskLocales['current'].pages
                                                    .tasks.manage.template.checkPoint.detail
                                                    .templateNew.applicationMessageType
                                            }
                                            name="applyMessageTypeList"
                                        >
                                            <MsgTypesSelect />
                                        </Form.Item>
                                    ) : null}

                                    <If
                                        condition={
                                            this.privates.model &&
                                            (!this.privates.model.taskVersion ||
                                                (this.privates.model.taskVersion &&
                                                    this.privates.model.taskVersion
                                                        .pointVersion)) &&
                                            [
                                                Udesk.enums.pointTypes.smartRules.id,
                                                Udesk.enums.pointTypes.intelligentModel.id,
                                            ].includes(privates.type)
                                        }
                                    >
                                        <Form.Item
                                            name="status"
                                            label={
                                                UdeskLocales.current.pages.tasks.manage.template
                                                    .checkPoint.configuration.status
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Radio value={1}>
                                                    {
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.configuration.yes
                                                    }
                                                </Radio>
                                                <Radio value={0}>
                                                    {
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.configuration.no
                                                    }
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </If>
                                    <If condition={privates.isWechatQa}>
                                        <Form.Item
                                            name="weComSourceType"
                                            label={
                                                /* 数据源 */ locales.pages.tasks.manage.template
                                                    .checkPoint.detail.templateNew.dataSource
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Radio.Group style={{ marginLeft: '-14px' }}>
                                                <Radio value={1}>
                                                    {/* 全部 */}
                                                    {
                                                        locales.pages.tasks.manage.template
                                                            .checkPoint.detail.templateNew.whole
                                                    }
                                                </Radio>
                                                <Radio value={2}>
                                                    {/* 企微会话 */}
                                                    {
                                                        locales.pages.tasks.manage.template
                                                            .checkPoint.detail.templateNew
                                                            .enterpriseMicroConversation
                                                    }
                                                </Radio>
                                                <Radio value={3}>
                                                    {/* 企微通话 */}
                                                    {
                                                        locales.pages.tasks.manage.template
                                                            .checkPoint.detail.templateNew
                                                            .enterpriseAndMicroCommunication
                                                    }
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </If>
                                    <Form.Item
                                        name="type"
                                        label={
                                            UdeskLocales.current.pages.tasks.manage.template
                                                .checkPoint.configuration.type
                                        }
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        tooltip={<TypeTooltip isWechatQa={privates.isWechatQa} />}
                                    >
                                        <Radio.Group style={{ marginLeft: '-18px' }}>
                                            {!privates.isWechatQa && !isTicket && (
                                                <Radio
                                                    value={
                                                        Udesk.enums.pointTypes.intelligentModel.id
                                                    }
                                                >
                                                    {Udesk.enums.pointTypes.intelligentModel.name}
                                                </Radio>
                                            )}
                                            <Radio value={Udesk.enums.pointTypes.smartRules.id}>
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.configuration.ruler
                                                }
                                            </Radio>
                                            <If
                                                condition={
                                                    this.privates.model.taskVersion &&
                                                    !this.privates.model.taskVersion.pointVersion
                                                }
                                            >
                                                <Radio value={Udesk.enums.pointTypes.automatic.id}>
                                                    {
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.configuration
                                                            .autoScore
                                                    }
                                                </Radio>
                                                <Radio
                                                    value={
                                                        Udesk.enums.pointTypes.interactiveDetection
                                                            .id
                                                    }
                                                >
                                                    {
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.configuration
                                                            .interaction
                                                    }
                                                </Radio>
                                            </If>
                                            <Radio value={Udesk.enums.pointTypes.manual.id}>
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.configuration.manualScore
                                                }
                                            </Radio>
                                            {/* <Radio value={Udesk.enums.pointTypes.machineLearning.id}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.machineLearning}</Radio> */}
                                        </Radio.Group>
                                    </Form.Item>
                                    {Udesk.enums.pointTypes.smartRules.id === privates.type &&
                                        !isSales && (
                                            <Form.Item
                                                name="ruleType"
                                                label={
                                                    /* 规则类型 */ UdeskLocales['current'].pages
                                                        .tasks.manage.template.checkPoint.detail
                                                        .templateNew.ruleType
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    disabled={privates.computes.isEditing}
                                                    style={{ marginLeft: '-18px' }}
                                                    optionType="button"
                                                    onChange={actions.onShowFlowChange}
                                                >
                                                    {Udesk.enums.ruleTypes.map((item) => (
                                                        <Radio value={item.id}>{item.name}</Radio>
                                                    ))}
                                                </Radio.Group>
                                            </Form.Item>
                                        )}
                                </Form>
                            </div>
                            <If
                                condition={
                                    (!privates.showFlow &&
                                        Udesk.enums.pointTypes.smartRules.id === privates.type) ||
                                    Udesk.enums.pointTypes.intelligentModel.id === privates.type
                                }
                            >
                                {privates.type === Udesk.enums.pointTypes.smartRules.id && (
                                    <div style={{ marginLeft: '76px' }}>
                                        <ConditionListGather
                                            conditionList={privates.ruleList}
                                            type={
                                                privates.isWechatQa &&
                                                privates.weComSourceType === 3
                                                    ? [Udesk.enums.inspectDataSources.voiceCall.id]
                                                    : [privates.model.task?.inspectDataSource]
                                            }
                                            onChange={actions.getConditionList}
                                            isWechatQa={privates.isWechatQa}
                                            isSales={isSales}
                                            showApplyTimeType={true}
                                            taskId={privates.model.taskId}
                                            funcType={'QUALITY_INSPECT'}
                                            hasSummaryRule={
                                                !privates.isRuleTemplate && !privates.isWechatQa
                                            }
                                        ></ConditionListGather>
                                    </div>
                                )}
                                {privates.type === Udesk.enums.pointTypes.intelligentModel.id && (
                                    <div style={{ marginLeft: '76px' }}>
                                        <div style={{ marginBottom: 10 }}>
                                            {
                                                /** 模型 */ UdeskLocales.current.pages.tasks.manage
                                                    .template.checkPoint.configuration.model
                                            }

                                            <Radio.Group
                                                value={privates.intelligentSecondType}
                                                onChange={actions.onModelTypeChange}
                                            >
                                                {Udesk.enums.modelTypes.map((item) => (
                                                    <Radio value={item.id}>{item.name}</Radio>
                                                ))}
                                            </Radio.Group>
                                        </div>

                                        {privates.intelligentSecondType ===
                                        Udesk.enums.modelTypes.custom.id ? (
                                            <CustomModelsSelect
                                                value={privates.intelligentCustomModelId}
                                                onChange={actions.onModelChange}
                                            />
                                        ) : privates.intelligentSecondType ===
                                          Udesk.enums.modelTypes.common.id ? (
                                            <IntelligentModel
                                                value={privates.secondType}
                                                onChange={actions.onSecondTypeChange}
                                                source={
                                                    this.props.sdkOptions.props.task
                                                        .inspectDataSource
                                                }
                                            />
                                        ) : null}
                                    </div>
                                )}
                                {privates.intelligentCustomModelType ===
                                TypeEnum.GradedEvaluation ? null : (
                                    <div className="tasks-manage-template-configuration-detail-logic">
                                        <div className="tasks-manage-template-configuration-detail-logic-title">
                                            {
                                                /** 评分逻辑 */ UdeskLocales.current.pages.tasks
                                                    .manage.template.checkPoint.configuration.logic
                                                    .logic
                                            }
                                            <Button
                                                size="small"
                                                type="text"
                                                tooltip={
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                        {
                                                            /* N/A即Not Applicable，为该条质检规则设置前置条件，符合条件时检测，不符合条件时即该规则不适用，则不检测该条规则;
前置条件填写说明："&&”表示“和”，“||”表示“或”，“!”表示“非”，填写示例“(R1||R2)&&R3" */ UdeskLocales[
                                                                'current'
                                                            ].pages.tasks.manage.template.checkPoint
                                                                .detail.templateNew
                                                                .nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3
                                                        }
                                                    </div>
                                                }
                                                icon={
                                                    <Icon
                                                        type="QuestionCircleOutlined"
                                                        antdIcon={true}
                                                        style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                                                    />
                                                }
                                            ></Button>
                                            {UdeskLocales.current.labels.colon}
                                        </div>
                                        <div className="tasks-manage-template-configuration-detail-logic-score">
                                            {
                                                /** 分值 */ UdeskLocales.current.pages.tasks.manage
                                                    .template.checkPoint.configuration.logic.score
                                            }
                                            <Select
                                                value={privates.scoreType}
                                                style={{ width: 86 }}
                                                onChange={actions.handleScoreChange}
                                            >
                                                {Udesk.enums.calculateScoreTypes
                                                    .filter((i) => {
                                                        if (
                                                            privates.type ===
                                                                Udesk.enums.pointTypes
                                                                    .intelligentModel.id &&
                                                            privates.intelligentSecondType ===
                                                                Udesk.enums.modelTypes.common.id
                                                        ) {
                                                            return (
                                                                Udesk.enums.calculateScoreTypes.sub
                                                                    .id === i.id
                                                            );
                                                        }
                                                        return true;
                                                    })
                                                    .map((item) => {
                                                        return (
                                                            <Option value={item.id}>
                                                                {item.name}
                                                            </Option>
                                                        );
                                                    })}
                                                {/* {UdeskLocales.current.enums.tasks.calculateScoreTypes}
                                        Udesk.enums.calculateScoreTypes.add.id */}
                                            </Select>
                                            <InputNumber
                                                min={0}
                                                step={0.5}
                                                style={{ width: 200 }}
                                                value={privates.predeterminedScore}
                                                onChange={actions.handleScoreInput}
                                                addonAfter={UdeskLocales.current.labels.scoreUnit}
                                            ></InputNumber>
                                        </div>
                                        {privates.type === Udesk.enums.pointTypes.smartRules.id &&
                                            !isTicket && (
                                                <NA
                                                    onNaCheckedChange={actions.onNaCheckedChange}
                                                    naChecked={privates.naChecked}
                                                    naPreviousFormula={privates.naPreviousFormula}
                                                    onNaPreviousFormulaChange={
                                                        actions.onNaPreviousFormulaChange
                                                    }
                                                    naScoreType={privates.naScoreType}
                                                    onNotCatchPreRuleChange={
                                                        actions.onNotCatchPreRuleChange
                                                    }
                                                />
                                            )}
                                        <div className="tasks-manage-template-configuration-detail-logic-rule">
                                            {
                                                /** 规则 */ UdeskLocales.current.pages.tasks.manage
                                                    .template.checkPoint.configuration.logic.rule
                                            }
                                            <Radio.Group
                                                onChange={actions.onGradeTypeChange}
                                                value={privates.gradeType}
                                            >
                                                <Radio value={1}>
                                                    {
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.configuration.logic
                                                            .all
                                                    }
                                                </Radio>
                                                {privates.type ===
                                                    Udesk.enums.pointTypes.smartRules.id && (
                                                    <React.Fragment>
                                                        <Radio value={2}>
                                                            {
                                                                UdeskLocales.current.pages.tasks
                                                                    .manage.template.checkPoint
                                                                    .configuration.logic.any
                                                            }
                                                        </Radio>
                                                        <Radio value={3}>
                                                            {
                                                                UdeskLocales.current.pages.tasks
                                                                    .manage.template.checkPoint
                                                                    .configuration.logic.customize
                                                            }
                                                        </Radio>
                                                        <Radio value={31}>
                                                            {
                                                                UdeskLocales.current.pages.tasks
                                                                    .manage.template.checkPoint
                                                                    .configuration.logic.multiple
                                                            }
                                                        </Radio>
                                                    </React.Fragment>
                                                )}
                                            </Radio.Group>
                                            <If condition={privates.gradeType === 3}>
                                                {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                                <Tabs defaultActiveKey="2">
                                                    <TabPane
                                                        tab={
                                                            UdeskLocales.current.components.common
                                                                .customFilter.manualConfiguration
                                                        }
                                                        key="1"
                                                    >
                                                        <Input
                                                            placeholder={
                                                                UdeskLocales.current.components
                                                                    .common.customFilter
                                                                    .holderCustomRule
                                                            }
                                                            value={privates.operatorLogic}
                                                            onChange={
                                                                actions.customJudgeLogicChange
                                                            }
                                                        />
                                                        {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                                    </TabPane>
                                                    <TabPane
                                                        tab={
                                                            UdeskLocales.current.components.common
                                                                .customFilter.dragConfiguration
                                                        }
                                                        key="2"
                                                    >
                                                        <DragDropContext
                                                            onDragEnd={actions.onDragEnd}
                                                            onDragStart={actions.onDragStart}
                                                        >
                                                            <div className="data-preprocess-custom">
                                                                <div className="data-preprocess-custom-drap-title">
                                                                    {
                                                                        UdeskLocales.current
                                                                            .components.common
                                                                            .customFilter.condition
                                                                    }
                                                                    ：
                                                                </div>
                                                                <div className="data-preprocess-cutom-drap-info">
                                                                    <Droppable
                                                                        droppableId="droppables"
                                                                        direction="horizontal"
                                                                        isDropDisabled={true}
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                style={getListStyle(
                                                                                    snapshot.isDraggingOver
                                                                                )}
                                                                            >
                                                                                {privates.conditionListFilter.map(
                                                                                    (
                                                                                        item,
                                                                                        index
                                                                                    ) => (
                                                                                        <Draggable
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            draggableId={
                                                                                                item.id
                                                                                            }
                                                                                            index={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {(
                                                                                                provided,
                                                                                                snapshot
                                                                                            ) => (
                                                                                                <div
                                                                                                    ref={
                                                                                                        provided.innerRef
                                                                                                    }
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided
                                                                                                            .draggableProps
                                                                                                            .style
                                                                                                    )}
                                                                                                >
                                                                                                    {
                                                                                                        item.content
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    )
                                                                                )}
                                                                                {
                                                                                    provided.placeholder
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </div>
                                                            </div>
                                                            <div className="data-preprocess-custom">
                                                                <div className="data-preprocess-custom-drap-title">
                                                                    {
                                                                        UdeskLocales.current
                                                                            .components.common
                                                                            .customFilter.condition
                                                                    }
                                                                    ：
                                                                </div>
                                                                <div className="data-preprocess-cutom-drap-info">
                                                                    <Droppable
                                                                        droppableId="droppabless"
                                                                        direction="horizontal"
                                                                        isDropDisabled={true}
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                style={getListStyle(
                                                                                    snapshot.isDragging
                                                                                )}
                                                                            >
                                                                                {privates.logicList
                                                                                    .filter(
                                                                                        (item) =>
                                                                                            item.flag
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item,
                                                                                            index
                                                                                        ) => (
                                                                                            <Draggable
                                                                                                key={
                                                                                                    item.id
                                                                                                }
                                                                                                draggableId={
                                                                                                    item.id
                                                                                                }
                                                                                                index={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {(
                                                                                                    provided,
                                                                                                    snapshot
                                                                                                ) => (
                                                                                                    <div
                                                                                                        ref={
                                                                                                            provided.innerRef
                                                                                                        }
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        style={getItemStyle(
                                                                                                            snapshot.isDragging,
                                                                                                            provided
                                                                                                                .draggableProps
                                                                                                                .style
                                                                                                        )}
                                                                                                    >
                                                                                                        {
                                                                                                            item.content
                                                                                                        }
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        )
                                                                                    )}
                                                                                {
                                                                                    provided.placeholder
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </div>
                                                            </div>
                                                            <div className="data-preprocess-custom">
                                                                {privates.tarskDel && (
                                                                    <div className="index-manmagement-custom-drag-tarsk">
                                                                        {
                                                                            UdeskLocales.current
                                                                                .components.common
                                                                                .customFilter
                                                                                .infoDel
                                                                        }
                                                                    </div>
                                                                )}
                                                                <div className="data-preprocess-custom-drap-title">
                                                                    {
                                                                        UdeskLocales.current
                                                                            .components.common
                                                                            .customFilter
                                                                            .toConfigure
                                                                    }
                                                                    ：
                                                                </div>
                                                                <div className="data-preprocess-cutom-drap-info">
                                                                    <Droppable
                                                                        droppableId="droppable"
                                                                        direction="horizontal"
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                style={getListDropStyle(
                                                                                    snapshot.isDraggingOver
                                                                                )}
                                                                            >
                                                                                {this.privates.items.map(
                                                                                    (
                                                                                        item,
                                                                                        index
                                                                                    ) => {
                                                                                        let isDeleted =
                                                                                            item.prevClass ===
                                                                                                'conditionListFilter' &&
                                                                                            !privates.conditionListFilter.some(
                                                                                                (
                                                                                                    condition
                                                                                                ) =>
                                                                                                    condition.id ===
                                                                                                    item.prevId
                                                                                            );
                                                                                        let extraDeleted =
                                                                                            actions.extraDeleted(
                                                                                                item
                                                                                            );
                                                                                        return (
                                                                                            <Draggable
                                                                                                key={
                                                                                                    item.id
                                                                                                }
                                                                                                draggableId={
                                                                                                    item.id
                                                                                                }
                                                                                                index={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {(
                                                                                                    provided,
                                                                                                    snapshot
                                                                                                ) => (
                                                                                                    <div
                                                                                                        ref={
                                                                                                            provided.innerRef
                                                                                                        }
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        style={getItemStyle(
                                                                                                            snapshot.isDragging,
                                                                                                            provided
                                                                                                                .draggableProps
                                                                                                                .style
                                                                                                        )}
                                                                                                        className={
                                                                                                            isDeleted ||
                                                                                                            extraDeleted
                                                                                                                ? 'deleted-item'
                                                                                                                : ''
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            item.content
                                                                                                        }
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                                {
                                                                                    provided.placeholder
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </div>
                                                            </div>
                                                        </DragDropContext>
                                                    </TabPane>
                                                </Tabs>
                                            </If>
                                            <If condition={privates.gradeType === 31}>
                                                <Tabs defaultActiveKey="2">
                                                    <TabPane
                                                        tab={
                                                            UdeskLocales.current.components.common
                                                                .customFilter.manualConfiguration
                                                        }
                                                        key="1"
                                                    >
                                                        {privates.operatorMultipleLogicList.map(
                                                            (item, index) => {
                                                                return (
                                                                    <div
                                                                        style={{ marginBottom: 8 }}
                                                                    >
                                                                        <Input
                                                                            placeholder={
                                                                                UdeskLocales.current
                                                                                    .components
                                                                                    .common
                                                                                    .customFilter
                                                                                    .holderCustomRule
                                                                            }
                                                                            value={item.formula}
                                                                            style={{
                                                                                marginRight: 16,
                                                                                width: 200,
                                                                            }}
                                                                            onChange={actions.operatorMultipleLogicChange.params(
                                                                                index
                                                                            )}
                                                                        />
                                                                        {privates.scoreType ===
                                                                        Udesk.enums
                                                                            .calculateScoreTypes.add
                                                                            .id
                                                                            ? UdeskLocales.current
                                                                                  .enums.tasks
                                                                                  .calculateScoreTypes
                                                                                  .add
                                                                            : UdeskLocales.current
                                                                                  .enums.tasks
                                                                                  .calculateScoreTypes
                                                                                  .sub}
                                                                        <InputNumber
                                                                            min={0}
                                                                            style={{
                                                                                marginRight: 16,
                                                                                width: 100,
                                                                            }}
                                                                            value={item.score}
                                                                            onChange={actions.operatorMultipleFractionChange.params(
                                                                                index
                                                                            )}
                                                                            // addonAfter={UdeskLocales.current.labels.scoreUnit}
                                                                        />
                                                                        {
                                                                            UdeskLocales.current
                                                                                .components
                                                                                .gearOptionList
                                                                                .evaluation
                                                                        }
                                                                        <Input
                                                                            // placeholder={UdeskLocales.current.components.common.customFilter.holderCustomRule}
                                                                            value={item.evaluation}
                                                                            style={{
                                                                                marginRight: 16,
                                                                                width: 200,
                                                                            }}
                                                                            onChange={actions.operatorMultipleAssessChange.params(
                                                                                index
                                                                            )}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'inline-block',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={actions.operatorMultipleDelete.params(
                                                                                index
                                                                            )}
                                                                        >
                                                                            <span class="udesk-qa-react-web-iconfont">
                                                                                &#xe653;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                        <div
                                                            onClick={actions.operatorMultipleAdd}
                                                            style={{ display: 'inline-block' }}
                                                        >
                                                            <i
                                                                class="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-add-circle-jia"
                                                                style={{
                                                                    fontSize: '25px',
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </div>
                                                        {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                                    </TabPane>
                                                    <TabPane
                                                        tab={
                                                            UdeskLocales.current.components.common
                                                                .customFilter.dragConfiguration
                                                        }
                                                        key="2"
                                                    >
                                                        <DragDropContext
                                                            onDragEnd={actions.onDragEndMultiple}
                                                            onDragStart={actions.onDragStart}
                                                        >
                                                            <div className="data-preprocess-custom">
                                                                <div className="data-preprocess-custom-drap-title">
                                                                    {
                                                                        UdeskLocales.current
                                                                            .components.common
                                                                            .customFilter.condition
                                                                    }
                                                                    ：
                                                                </div>
                                                                <div className="data-preprocess-cutom-drap-info">
                                                                    <Droppable
                                                                        droppableId="droppables"
                                                                        direction="horizontal"
                                                                        isDropDisabled={true}
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                style={getListStyle(
                                                                                    snapshot.isDraggingOver
                                                                                )}
                                                                            >
                                                                                {privates.conditionListFilter.map(
                                                                                    (
                                                                                        item,
                                                                                        index
                                                                                    ) => (
                                                                                        <Draggable
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            draggableId={
                                                                                                item.id
                                                                                            }
                                                                                            index={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {(
                                                                                                provided,
                                                                                                snapshot
                                                                                            ) => (
                                                                                                <div
                                                                                                    ref={
                                                                                                        provided.innerRef
                                                                                                    }
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided
                                                                                                            .draggableProps
                                                                                                            .style
                                                                                                    )}
                                                                                                >
                                                                                                    {
                                                                                                        item.content
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    )
                                                                                )}
                                                                                {
                                                                                    provided.placeholder
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </div>
                                                            </div>
                                                            <div className="data-preprocess-custom">
                                                                <div className="data-preprocess-custom-drap-title">
                                                                    {
                                                                        UdeskLocales.current
                                                                            .components.common
                                                                            .customFilter.condition
                                                                    }
                                                                    ：
                                                                </div>
                                                                <div className="data-preprocess-cutom-drap-info">
                                                                    <Droppable
                                                                        droppableId="droppabless"
                                                                        direction="horizontal"
                                                                        isDropDisabled={true}
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                style={getListStyle(
                                                                                    snapshot.isDragging
                                                                                )}
                                                                            >
                                                                                {privates.logicList
                                                                                    .filter(
                                                                                        (item) =>
                                                                                            item.flag
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item,
                                                                                            index
                                                                                        ) => (
                                                                                            <Draggable
                                                                                                key={
                                                                                                    item.id
                                                                                                }
                                                                                                draggableId={
                                                                                                    item.id
                                                                                                }
                                                                                                index={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {(
                                                                                                    provided,
                                                                                                    snapshot
                                                                                                ) => (
                                                                                                    <div
                                                                                                        ref={
                                                                                                            provided.innerRef
                                                                                                        }
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        style={getItemStyle(
                                                                                                            snapshot.isDragging,
                                                                                                            provided
                                                                                                                .draggableProps
                                                                                                                .style
                                                                                                        )}
                                                                                                    >
                                                                                                        {
                                                                                                            item.content
                                                                                                        }
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        )
                                                                                    )}
                                                                                {
                                                                                    provided.placeholder
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </div>
                                                            </div>
                                                            <div className="data-preprocess-custom">
                                                                {privates.tarskDel && (
                                                                    <div className="index-manmagement-custom-drag-tarsk">
                                                                        {
                                                                            UdeskLocales.current
                                                                                .components.common
                                                                                .customFilter
                                                                                .infoDel
                                                                        }
                                                                    </div>
                                                                )}
                                                                <div className="data-preprocess-custom-drap-title">
                                                                    {
                                                                        UdeskLocales.current
                                                                            .components.common
                                                                            .customFilter
                                                                            .toConfigure
                                                                    }
                                                                    ：
                                                                </div>
                                                                <div
                                                                    className="data-preprocess-cutom-drap-info"
                                                                    style={{
                                                                        flexDirection: 'column',
                                                                    }}
                                                                >
                                                                    {privates.operatorMultipleLogicitems.map(
                                                                        (items, index) => {
                                                                            return (
                                                                                <div
                                                                                    key={index}
                                                                                    style={{
                                                                                        display:
                                                                                            'flex',
                                                                                        marginBottom:
                                                                                            '8px',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            height: 44,
                                                                                            lineHeight:
                                                                                                '44px',
                                                                                            marginRight: 8,
                                                                                        }}
                                                                                    >
                                                                                        {index + 1}
                                                                                    </div>
                                                                                    <Droppable
                                                                                        droppableId={`configuration${index}`}
                                                                                        direction="horizontal"
                                                                                    >
                                                                                        {(
                                                                                            provided,
                                                                                            snapshot
                                                                                        ) => (
                                                                                            <div
                                                                                                {...provided.droppableProps}
                                                                                                ref={
                                                                                                    provided.innerRef
                                                                                                }
                                                                                                style={getListDropStyles(
                                                                                                    snapshot.isDraggingOver
                                                                                                )}
                                                                                            >
                                                                                                {items
                                                                                                    .filter(
                                                                                                        (
                                                                                                            item
                                                                                                        ) =>
                                                                                                            !!item
                                                                                                    )
                                                                                                    .map(
                                                                                                        (
                                                                                                            item,
                                                                                                            index
                                                                                                        ) => {
                                                                                                            let isDeleted =
                                                                                                                item.prevClass ===
                                                                                                                    'conditionListFilter' &&
                                                                                                                !privates.conditionListFilter.some(
                                                                                                                    (
                                                                                                                        condition
                                                                                                                    ) =>
                                                                                                                        condition.id ===
                                                                                                                        item.prevId
                                                                                                                );
                                                                                                            let extraDeleted =
                                                                                                                actions.multipleExtraDeleted(
                                                                                                                    item,
                                                                                                                    items
                                                                                                                );
                                                                                                            return (
                                                                                                                <Draggable
                                                                                                                    key={
                                                                                                                        item.id
                                                                                                                    }
                                                                                                                    draggableId={
                                                                                                                        item.id
                                                                                                                    }
                                                                                                                    index={
                                                                                                                        index
                                                                                                                    }
                                                                                                                >
                                                                                                                    {(
                                                                                                                        provided,
                                                                                                                        snapshot
                                                                                                                    ) => (
                                                                                                                        <div
                                                                                                                            ref={
                                                                                                                                provided.innerRef
                                                                                                                            }
                                                                                                                            {...provided.draggableProps}
                                                                                                                            {...provided.dragHandleProps}
                                                                                                                            style={getItemStyle(
                                                                                                                                snapshot.isDragging,
                                                                                                                                provided
                                                                                                                                    .draggableProps
                                                                                                                                    .style
                                                                                                                            )}
                                                                                                                            className={
                                                                                                                                isDeleted ||
                                                                                                                                extraDeleted
                                                                                                                                    ? 'deleted-item'
                                                                                                                                    : ''
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {
                                                                                                                                item.content
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </Draggable>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                                {
                                                                                                    provided.placeholder
                                                                                                }
                                                                                            </div>
                                                                                        )}
                                                                                    </Droppable>
                                                                                    <span
                                                                                        style={{
                                                                                            // width: 30,
                                                                                            whiteSpace:
                                                                                                'nowrap',
                                                                                            marginLeft: 8,
                                                                                            marginRight: 8,
                                                                                            lineHeight:
                                                                                                '44px',
                                                                                        }}
                                                                                    >
                                                                                        {privates.scoreType ===
                                                                                        Udesk.enums
                                                                                            .calculateScoreTypes
                                                                                            .add.id
                                                                                            ? UdeskLocales
                                                                                                  .current
                                                                                                  .enums
                                                                                                  .tasks
                                                                                                  .calculateScoreTypes
                                                                                                  .add
                                                                                            : UdeskLocales
                                                                                                  .current
                                                                                                  .enums
                                                                                                  .tasks
                                                                                                  .calculateScoreTypes
                                                                                                  .sub}
                                                                                    </span>
                                                                                    <InputNumber
                                                                                        min={0}
                                                                                        style={{
                                                                                            marginRight: 16,
                                                                                            width: 100,
                                                                                            lineHeight:
                                                                                                '44px',
                                                                                        }}
                                                                                        value={
                                                                                            privates
                                                                                                .operatorMultipleLogicList[
                                                                                                index
                                                                                            ].score
                                                                                        }
                                                                                        onChange={actions.operatorMultipleFractionChange.params(
                                                                                            index
                                                                                        )}
                                                                                        // addonAfter={UdeskLocales.current.labels.scoreUnit}
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            // width: 30,
                                                                                            whiteSpace:
                                                                                                'nowrap',
                                                                                            marginLeft: 8,
                                                                                            marginRight: 8,
                                                                                            lineHeight:
                                                                                                '44px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            UdeskLocales
                                                                                                .current
                                                                                                .components
                                                                                                .gearOptionList
                                                                                                .evaluation
                                                                                        }
                                                                                    </span>
                                                                                    <Input
                                                                                        // placeholder={UdeskLocales.current.components.common.customFilter.holderCustomRule}
                                                                                        value={
                                                                                            privates
                                                                                                .operatorMultipleLogicList[
                                                                                                index
                                                                                            ]
                                                                                                .evaluation
                                                                                        }
                                                                                        style={{
                                                                                            marginRight: 16,
                                                                                            width: 200,
                                                                                        }}
                                                                                        onChange={actions.operatorMultipleAssessChange.params(
                                                                                            index
                                                                                        )}
                                                                                    />
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'inline-block',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={actions.operatorMultipleDelete.params(
                                                                                            index
                                                                                        )}
                                                                                    >
                                                                                        <span
                                                                                            class="udesk-qa-react-web-iconfont"
                                                                                            style={{
                                                                                                lineHeight:
                                                                                                    '46px',
                                                                                            }}
                                                                                        >
                                                                                            &#xe653;
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </DragDropContext>
                                                        <div
                                                            onClick={actions.operatorMultipleAdd}
                                                            style={{
                                                                marginLeft: '32px',
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            <i
                                                                class="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-add-circle-jia"
                                                                style={{
                                                                    fontSize: '25px',
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </div>
                                                    </TabPane>
                                                </Tabs>
                                            </If>
                                            {/* <Input placeholder={UdeskLocales.current.components.common.customFilter.holderCustomRule} value={privates.customJudgeLogic} onChange={actions.customJudgeLogicChange} /> */}
                                            {/* <div style={{ marginTop: '10px' }}>
                                            {UdeskLocales.current.components.common.customFilter.example}：1 or 2 and (3 and 4)
                                        </div> */}
                                        </div>
                                        {privates.type === Udesk.enums.pointTypes.smartRules.id && (
                                            <>
                                                <div className="tasks-manage-template-configuration-detail-logic-heightLight">
                                                    {
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.configuration.logic
                                                            .heightLight
                                                    }
                                                    <Checkbox.Group
                                                        options={
                                                            privates.ruleList &&
                                                            privates.ruleList.length > 0 &&
                                                            privates.ruleList.map((item) => {
                                                                return {
                                                                    label: item.idCode,
                                                                    value: item.idCode,
                                                                };
                                                            })
                                                        }
                                                        onChange={actions.heightLightChange}
                                                        value={privates.heightLightList}
                                                    />
                                                    <Checkbox
                                                        checked={
                                                            privates.computes.heightLightAllRule
                                                        }
                                                        onChange={(e) =>
                                                            actions.heightLightAllChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    >
                                                        {/* 全选 */}
                                                        {
                                                            UdeskLocales['current'].pages.tasks
                                                                .manage.template.checkPoint.detail
                                                                .templateNew.selectAll
                                                        }
                                                    </Checkbox>
                                                </div>

                                                <div>
                                                    {
                                                        /* 高亮标签： */ UdeskLocales['current']
                                                            .pages.tasks.manage.template.checkPoint
                                                            .detail.templateNew.highlightTags
                                                    }
                                                    <Switch
                                                        checked={privates.highlightTag === 1}
                                                        onChange={actions.highlightTagChange}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </If>
                            <If
                                condition={
                                    privates.showFlow &&
                                    [Udesk.enums.pointTypes.smartRules.id].includes(privates.type)
                                }
                            >
                                <Flow
                                    initNodes={privates.initNodes}
                                    inspectDataSource={privates.model.task.inspectDataSource}
                                    ref={this.flowRef}
                                />
                            </If>
                            <If condition={privates.type === Udesk.enums.pointTypes.automatic.id}>
                                <AutomaticPoint
                                    onGradeTypeChange={actions.onValueChanged.params(
                                        privates.model.inspectionPoint,
                                        'gradeType'
                                    )}
                                    onScoreTypeChange={actions.onValueChanged.params(
                                        privates.model.inspectionPoint,
                                        'scoreType'
                                    )}
                                    onPredeterminedScoreChange={actions.onValueChanged.params(
                                        privates.model.inspectionPoint,
                                        'predeterminedScore'
                                    )}
                                    onNaCheckedChange={actions.changePointNaChecked}
                                    onNaPreviousFormulaChange={actions.onValueChanged.params(
                                        privates.model.inspectionPoint,
                                        'naPreviousFormula'
                                    )}
                                    inspectionPoint={privates.model.inspectionPoint}
                                    getGearOptionListOld={actions.getGearOptionListOld}
                                    conditionLogic={state.conditionLogic}
                                    onConditionLogicChange={actions.onValueChanged.params(
                                        state,
                                        'conditionLogic'
                                    )}
                                    relateConditionList={state.relateConditionList}
                                    onGenerateHighlightClick={actions.generateHighlight}
                                    onHighlightChange={actions.onValueChanged}
                                    onSave={actions.save}
                                    inspectionConditions={privates.model.inspectionConditions}
                                />
                            </If>
                            <If
                                condition={
                                    privates.type === Udesk.enums.pointTypes.interactiveDetection.id
                                }
                            >
                                <form className="form-horizontal form-label-left">
                                    {/* <div className="form-group">
                                        <label
                                            style={{ padding: "0 15px" }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.type}
                                            <span className="required">*</span>
                                        </label>
                                        <div className="select-container col-lg-7 col-md-7 col-xs-12 col-12">
                                            <Select
                                                value={privates.model.inspectionPoint.type}
                                                className="form-control form-control-score-type"
                                                onChange={actions.changeScoreType}>
                                                {
                                                    state.scoreType.map(item => {
                                                        if(item.id !== Udesk.enums.scoreType.manualScore.id){
                                                            return (
                                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                            );
                                                        }
                                                    })
                                                } */}
                                    {/* <For each="item" index="index" of={state.scoreType}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For> */}
                                    {/* </Select>
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <label
                                            htmlFor="checkPoint_gradeType"
                                            style={{ padding: '0 15px' }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                        >
                                            <span className="required">*</span>
                                            {
                                                UdeskLocales.current.pages.tasks.manage.template
                                                    .checkPoint.detail.gradeType
                                            }
                                        </label>
                                        <div className="select-container col-lg-7 col-md-7 col-xs-12 col-12">
                                            <Select
                                                value={privates.model.inspectionPoint.gradeType}
                                                className="form-control form-control-score-type"
                                                onChange={actions.onValueChanged.params(
                                                    privates.model.inspectionPoint,
                                                    'gradeType'
                                                )}
                                            >
                                                <For
                                                    each="item"
                                                    index="index"
                                                    of={privates.computes.gradeTypes}
                                                >
                                                    <Select.Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                </For>
                                            </Select>
                                        </div>
                                    </div>
                                    {/* 交互检测 */}
                                    <If
                                        condition={
                                            privates.type ===
                                                Udesk.enums.scoreType.interactiveDetection.id &&
                                            privates.model.inspectionPoint.gradeType ===
                                                Udesk.enums.gradeTypes.yesOrNo.id
                                        }
                                    >
                                        <div className="form-group">
                                            <label
                                                htmlFor="checkPoint_interactive_category"
                                                style={{ padding: '0 15px' }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                            >
                                                <span className="required">*</span>
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.detail.interactiveCategory
                                                }
                                            </label>
                                            <div className="interactive-category-container col-lg-7 col-md-7 col-xs-12 col-12">
                                                <TreeSelect
                                                    dropdownStyle={{
                                                        maxHeight: 400,
                                                        overflow: 'auto',
                                                    }}
                                                    value={state.selectedCategory.id}
                                                    treeData={privates.model.categories}
                                                    treeDefaultExpandAll
                                                    onChange={actions.switchNode}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    <div className="form-group">
                                        <label
                                            style={{ padding: '0 15px' }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                            htmlFor="checkPoint_deduction"
                                        >
                                            <span className="required">*</span>
                                            {
                                                UdeskLocales.current.pages.tasks.manage.template
                                                    .checkPoint.detail.predeterminedScore
                                            }
                                        </label>
                                        <div className="col-lg-2 col-md-3 col-xs-12 col-12">
                                            <Select
                                                value={privates.model.inspectionPoint.scoreType}
                                                className="form-control form-control-score-type"
                                                onChange={actions.onValueChanged.params(
                                                    privates.model.inspectionPoint,
                                                    'scoreType'
                                                )}
                                            >
                                                <For
                                                    each="item"
                                                    index="index"
                                                    of={Udesk.enums.calculateScoreTypes}
                                                >
                                                    <Select.Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                </For>
                                            </Select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-xs-12 col-12">
                                            <input
                                                type="number"
                                                id="checkPoint_deduction"
                                                className="form-control input-sm-wide deduction-input"
                                                value={
                                                    privates.model.inspectionPoint
                                                        .predeterminedScore
                                                }
                                                onChange={actions.onValueChanged.params(
                                                    privates.model.inspectionPoint,
                                                    'predeterminedScore'
                                                )}
                                            />
                                            <span>{UdeskLocales.current.labels.minute}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            style={{ padding: '0 15px' }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                            htmlFor="checkPoint_na"
                                        >
                                            {
                                                UdeskLocales.current.pages.tasks.manage.template
                                                    .checkPoint.detail.na
                                            }
                                        </label>
                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                            <Checkbox
                                                checked={privates.model.inspectionPoint.naChecked}
                                                onChange={actions.changePointNaChecked}
                                            >
                                                {UdeskLocales.current.enums.tasks.naChecked.check}
                                            </Checkbox>
                                        </div>
                                    </div>
                                    <If
                                        condition={
                                            privates.model.inspectionPoint.gradeType ===
                                            Udesk.enums.gradeTypes.multipleFactors.id
                                        }
                                    >
                                        <div className="form-group">
                                            <label className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.detail.preRule
                                                }
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <Input
                                                    className="check-point-na-previous-formula"
                                                    value={
                                                        privates.model.inspectionPoint
                                                            .naPreviousFormula
                                                    }
                                                    onChange={actions.onValueChanged.params(
                                                        privates.model.inspectionPoint,
                                                        'naPreviousFormula'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    {/* 交互检测 */}
                                    <If
                                        condition={
                                            privates.type ===
                                            Udesk.enums.scoreType.interactiveDetection.id
                                        }
                                    >
                                        <div className="form-group">
                                            <label
                                                style={{ padding: '0 15px' }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_na"
                                            >
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.detail.rule
                                                }
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <If
                                                    condition={
                                                        privates.model.inspectionPoint.gradeType ===
                                                            Udesk.enums.gradeTypes.multipleFactors
                                                                .id && state.foctorsData.length > 0
                                                    }
                                                >
                                                    <Select
                                                        value={state.foctorsValue}
                                                        style={{ width: '40%' }}
                                                        className="form-control form-control-score-type foctors-select-style"
                                                        onChange={actions.onValueChanged.params(
                                                            state,
                                                            'foctorsValue'
                                                        )}
                                                    >
                                                        <If condition={state.foctorsFlag === true}>
                                                            <Select.Option
                                                                value={state.foctorsNoneValue.id}
                                                            >
                                                                {state.foctorsNoneValue.name}
                                                            </Select.Option>
                                                        </If>
                                                        <For
                                                            each="item"
                                                            index="index"
                                                            of={state.foctorsData}
                                                        >
                                                            <Select.Option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </Select.Option>
                                                        </For>
                                                    </Select>
                                                    <Select
                                                        value={state.foctorsHit}
                                                        style={{ width: '40%', marginLeft: '5px' }}
                                                        className="form-control form-control-score-type foctors-select-style"
                                                        onChange={actions.onValueChanged.params(
                                                            state,
                                                            'foctorsHit'
                                                        )}
                                                    >
                                                        <For
                                                            each="item"
                                                            index="index"
                                                            of={Udesk.enums.inspectFortorsType}
                                                        >
                                                            <Select.Option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </Select.Option>
                                                        </For>
                                                    </Select>
                                                </If>
                                                <If
                                                    condition={
                                                        privates.model.inspectionPoint.gradeType ===
                                                        Udesk.enums.gradeTypes.yesOrNo.id
                                                    }
                                                >
                                                    <Radio.Group
                                                        value={
                                                            privates.model.inspectionPoint
                                                                .interactiveRule
                                                        }
                                                        onChange={actions.onValueChanged.params(
                                                            privates.model.inspectionPoint,
                                                            'interactiveRule'
                                                        )}
                                                    >
                                                        <For
                                                            each="item"
                                                            index="index"
                                                            of={Udesk.enums.interactiveRules}
                                                        >
                                                            <Radio key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Radio>
                                                        </For>
                                                    </Radio.Group>
                                                </If>
                                            </div>
                                        </div>
                                    </If>
                                    <If
                                        condition={
                                            privates.model.inspectionPoint.gradeType ===
                                            Udesk.enums.gradeTypes.level.id
                                        }
                                    >
                                        <div className="form-group">
                                            <label
                                                style={{ padding: '0 15px' }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_na"
                                            >
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.detail.rule
                                                }
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <TaskGearOptionList
                                                    gearOptions={
                                                        privates.model.inspectionPoint.ruleList
                                                    }
                                                    onChange={actions.getGearOptionListOld}
                                                    scoreType={
                                                        privates.model.inspectionPoint.scoreType
                                                    }
                                                    valueField="score"
                                                    evaluationField="evaluation"
                                                    formulaField="formula"
                                                    type={privates.type}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    {/* 交互检测 */}
                                    <If
                                        condition={
                                            privates.type ===
                                            Udesk.enums.scoreType.interactiveDetection.id
                                        }
                                    >
                                        <div className="form-group">
                                            <label
                                                style={{ padding: '0 15px' }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_highlighted"
                                            >
                                                <span className="required">*</span>
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.detail.highlighted
                                                }
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <If
                                                    condition={
                                                        privates.model.inspectionPoint.gradeType ===
                                                        Udesk.enums.gradeTypes.yesOrNo.id
                                                    }
                                                >
                                                    <Checkbox.Group
                                                        value={state.interactiveRelateConditionList}
                                                        onChange={actions.onValueChanged.params(
                                                            state,
                                                            'interactiveRelateConditionList'
                                                        )}
                                                    >
                                                        <For
                                                            each="item"
                                                            index="index"
                                                            of={Udesk.enums.relateConditions}
                                                        >
                                                            <Checkbox key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Checkbox>
                                                        </For>
                                                    </Checkbox.Group>
                                                </If>
                                                <If
                                                    condition={
                                                        privates.model.inspectionPoint.gradeType ===
                                                        Udesk.enums.gradeTypes.multipleFactors.id
                                                    }
                                                >
                                                    <Checkbox.Group
                                                        value={state.interactiveRelateConditionList}
                                                        onChange={actions.onValueChanged.params(
                                                            state,
                                                            'interactiveRelateConditionList'
                                                        )}
                                                    >
                                                        <For
                                                            each="item"
                                                            index="index"
                                                            of={Udesk.enums.relateConditionsHit}
                                                        >
                                                            <Checkbox key={item.id} value={item.id}>
                                                                {item.name}
                                                            </Checkbox>
                                                        </For>
                                                    </Checkbox.Group>
                                                </If>
                                            </div>
                                        </div>
                                    </If>
                                    <div className="form-group">
                                        <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={actions.save}
                                            >
                                                {UdeskLocales.current.labels.save}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <TaskConditionDictionaries
                                    items={privates.model.inspectionConditions}
                                />
                            </If>
                            <If
                                condition={
                                    privates.type === Udesk.enums.pointTypes.manual.id ||
                                    (privates.type === Udesk.enums.pointTypes.intelligentModel.id &&
                                        privates.intelligentSecondType ===
                                            Udesk.enums.modelTypes.custom.id &&
                                        privates.intelligentCustomModelType ===
                                            TypeEnum.GradedEvaluation)
                                }
                            >
                                <div style={{ width: 700, marginLeft: 76 }}>
                                    <If
                                        condition={
                                            privates.type === Udesk.enums.pointTypes.manual.id
                                        }
                                    >
                                        <Row>
                                            <Col span={3}>
                                                <label htmlFor="checkPoint_gradeType">
                                                    <span
                                                        style={{ color: '#ff4d4f', marginRight: 4 }}
                                                    >
                                                        *
                                                    </span>
                                                    <span
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        title={
                                                            UdeskLocales.current.pages.tasks.manage
                                                                .template.checkPoint.detail
                                                                .gradeType
                                                        }
                                                    >
                                                        {
                                                            UdeskLocales.current.pages.tasks.manage
                                                                .template.checkPoint.detail
                                                                .gradeType
                                                        }
                                                    </span>
                                                </label>
                                            </Col>
                                            <Col span={8}>
                                                <div>
                                                    <Select
                                                        style={{ width: 112 }}
                                                        value={
                                                            privates.model.inspectionPoint.gradeType
                                                        }
                                                        onChange={actions.onValueChanged.params(
                                                            privates.model.inspectionPoint,
                                                            'gradeType'
                                                        )}
                                                    >
                                                        <For
                                                            each="item"
                                                            index="index"
                                                            of={privates.computes.gradeTypes}
                                                        >
                                                            <Select.Option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </Select.Option>
                                                        </For>
                                                    </Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </If>

                                    <Row style={{ marginTop: 24 }}>
                                        <Col span={3}>
                                            <label htmlFor="checkPoint_deduction">
                                                <span style={{ color: '#ff4d4f', marginRight: 4 }}>
                                                    *
                                                </span>
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    title={
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.detail
                                                            .predeterminedScore
                                                    }
                                                >
                                                    {
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.detail
                                                            .predeterminedScore
                                                    }
                                                </span>
                                            </label>
                                        </Col>
                                        <Col span={4}>
                                            <div>
                                                <Select
                                                    style={{ width: 86 }}
                                                    value={privates.model.inspectionPoint.scoreType}
                                                    onChange={actions.onValueChanged.params(
                                                        privates.model.inspectionPoint,
                                                        'scoreType'
                                                    )}
                                                >
                                                    <For
                                                        each="item"
                                                        index="index"
                                                        of={Udesk.enums.calculateScoreTypes}
                                                    >
                                                        <Select.Option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </Select.Option>
                                                    </For>
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div>
                                                <InputNumber
                                                    style={{ width: 198 }}
                                                    id="checkPoint_deduction"
                                                    value={
                                                        privates.model.inspectionPoint
                                                            .predeterminedScore
                                                    }
                                                    onChange={actions.onValueChanged.params(
                                                        privates.model.inspectionPoint,
                                                        'predeterminedScore'
                                                    )}
                                                />
                                                <span>{UdeskLocales.current.labels.minute}</span>
                                            </div>
                                        </Col>
                                    </Row>

                                    {!isTicket && (
                                        <Row style={{ marginTop: 24 }}>
                                            <NA
                                                onNaCheckedChange={actions._changePointNaChecked}
                                                naChecked={privates.model.inspectionPoint.naChecked}
                                                // naPreviousFormula={privates.naPreviousFormula}
                                                // onNaPreviousFormulaChange={actions.onNaPreviousFormulaChange}
                                                naScoreType={privates.naScoreType}
                                                onNotCatchPreRuleChange={
                                                    actions.onNotCatchPreRuleChange
                                                }
                                                showNAPreviousFormula={false}
                                                labelPaddingTop={0}
                                            />
                                            {/* <Col span={3}>
                                            <label
                                                htmlFor="checkPoint_na">
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.na}
                                            </label>
                                        </Col>
                                        <Col span={16}>
                                            <div>
                                                <Checkbox checked={privates.model.inspectionPoint.naChecked} onChange={actions.changePointNaChecked}>
                                                    {UdeskLocales.current.enums.tasks.naChecked.check}
                                                </Checkbox>
                                            </div>
                                            <div style={{ marginTop: 16 }}>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.naChecked}
                                                <Radio.Group onChange={e => actions.onNotCatchPreRuleChange(e.target.value)} value={privates.naScoreType}>
                                                    {Udesk.enums.naScoreType.map((item, index) => {
                                                        return (
                                                            <Radio value={item.id}>{item.name}</Radio>
                                                        );
                                                    })}
                                                </Radio.Group>
                                            </div>
                                        </Col> */}
                                        </Row>
                                    )}

                                    <Row style={{ marginTop: 24 }}>
                                        <Col span={3}>
                                            <label htmlFor="checkPoint_defaultScore">
                                                {
                                                    UdeskLocales.current.pages.tasks.manage.template
                                                        .checkPoint.detail.default
                                                }
                                            </label>
                                        </Col>
                                        <Col span={16}>
                                            <Radio.Group
                                                onChange={actions.defauleScoreChange}
                                                value={privates.manualDefaultScore}
                                            >
                                                {Udesk.enums.defaultScore
                                                    .filter((item) => {
                                                        if (
                                                            privates.model.inspectionPoint
                                                                .gradeType ===
                                                            Udesk.enums.gradeTypes.yesOrNo.id
                                                        ) {
                                                            return (
                                                                item.id !==
                                                                    Udesk.enums.defaultScore.input
                                                                        .id &&
                                                                item.id !==
                                                                    Udesk.enums.defaultScore.choose
                                                                        .id
                                                            );
                                                        }
                                                        if (
                                                            privates.model.inspectionPoint
                                                                .gradeType ===
                                                            Udesk.enums.gradeTypes.input.id
                                                        ) {
                                                            return (
                                                                item.id !==
                                                                    Udesk.enums.defaultScore
                                                                        .addOrSub.id &&
                                                                item.id !==
                                                                    Udesk.enums.defaultScore
                                                                        .notAddOrSub.id &&
                                                                item.id !==
                                                                    Udesk.enums.defaultScore.choose
                                                                        .id
                                                            );
                                                        }
                                                        if (
                                                            privates.model.inspectionPoint
                                                                .gradeType ===
                                                            Udesk.enums.gradeTypes.level.id
                                                        ) {
                                                            return (
                                                                item.id ===
                                                                    Udesk.enums.defaultScore.na
                                                                        .id ||
                                                                item.id ===
                                                                    Udesk.enums.defaultScore.empty
                                                                        .id
                                                            );
                                                        }
                                                        return false;
                                                    })
                                                    .map((item, index) => {
                                                        let name = item.name;
                                                        let isAdd =
                                                            privates.model.inspectionPoint
                                                                .scoreType ===
                                                            Udesk.enums.calculateScoreTypes.add.id;
                                                        if (
                                                            item.id ===
                                                            Udesk.enums.defaultScore.addOrSub.id
                                                        ) {
                                                            name = isAdd
                                                                ? UdeskLocales.current.enums.tasks
                                                                      .defaultScore.add
                                                                : UdeskLocales.current.enums.tasks
                                                                      .defaultScore.sub;
                                                        }
                                                        if (
                                                            item.id ===
                                                            Udesk.enums.defaultScore.notAddOrSub.id
                                                        ) {
                                                            name = isAdd
                                                                ? UdeskLocales.current.enums.tasks
                                                                      .defaultScore.notAdd
                                                                : UdeskLocales.current.enums.tasks
                                                                      .defaultScore.notSub;
                                                        }
                                                        return (
                                                            <Radio
                                                                value={item.id}
                                                                disabled={
                                                                    item.id ===
                                                                        Udesk.enums.defaultScore.na
                                                                            .id &&
                                                                    !privates.model.inspectionPoint
                                                                        .naChecked
                                                                }
                                                            >
                                                                {name}
                                                            </Radio>
                                                        );
                                                    })}
                                                <If
                                                    condition={
                                                        privates.model.inspectionPoint.gradeType ===
                                                            Udesk.enums.gradeTypes.level.id &&
                                                        privates.model.inspectionPoint.ruleList
                                                    }
                                                >
                                                    {privates.model.inspectionPoint.ruleList
                                                        .filter((item) => item.evaluation)
                                                        .map((item) => {
                                                            return (
                                                                <Radio
                                                                    value={item.id || `${item._id}`}
                                                                >
                                                                    {item.evaluation}
                                                                </Radio>
                                                            );
                                                        })}
                                                </If>
                                            </Radio.Group>
                                            <If
                                                condition={
                                                    privates.model.inspectionPoint.gradeType ===
                                                        Udesk.enums.gradeTypes.input.id &&
                                                    privates.manualDefaultType ===
                                                        Udesk.enums.defaultScore.input.id
                                                }
                                            >
                                                <Input
                                                    style={{ width: 100 }}
                                                    defaultValue={
                                                        privates.manualDefaultValue || '0'
                                                    }
                                                    onChange={actions.defaultValueChange}
                                                ></Input>
                                            </If>
                                        </Col>
                                    </Row>

                                    <If
                                        condition={
                                            privates.model.inspectionPoint.gradeType ===
                                                Udesk.enums.gradeTypes.level.id &&
                                            privates.TaskGearOptionListupdateToggle
                                        }
                                    >
                                        <Row style={{ marginTop: 24 }}>
                                            <Col span={3}>
                                                <label htmlFor="checkPoint_na">
                                                    {
                                                        UdeskLocales.current.pages.tasks.manage
                                                            .template.checkPoint.detail.rule
                                                    }
                                                </label>
                                            </Col>
                                            <Col span={8}>
                                                <div style={{ width: 500 }}>
                                                    <TaskGearOptionList
                                                        gearOptions={
                                                            privates.model.inspectionPoint.ruleList
                                                        }
                                                        onChange={actions.getGearOptionListOld}
                                                        scoreType={
                                                            privates.model.inspectionPoint.scoreType
                                                        }
                                                        valueField="score"
                                                        evaluationField="evaluation"
                                                        formulaField="formula"
                                                        type={Udesk.enums.scoreType.manualScore.id}
                                                        evaluationDisabled={
                                                            privates.type ===
                                                                Udesk.enums.pointTypes
                                                                    .intelligentModel.id &&
                                                            privates.intelligentSecondType ===
                                                                Udesk.enums.modelTypes.custom.id &&
                                                            privates.intelligentCustomModelType ===
                                                                TypeEnum.GradedEvaluation
                                                        }
                                                        addDisabled={
                                                            privates.type ===
                                                                Udesk.enums.pointTypes
                                                                    .intelligentModel.id &&
                                                            privates.intelligentSecondType ===
                                                                Udesk.enums.modelTypes.custom.id &&
                                                            privates.intelligentCustomModelType ===
                                                                TypeEnum.GradedEvaluation
                                                        }
                                                        removeDisabled={
                                                            privates.type ===
                                                                Udesk.enums.pointTypes
                                                                    .intelligentModel.id &&
                                                            privates.intelligentSecondType ===
                                                                Udesk.enums.modelTypes.custom.id &&
                                                            privates.intelligentCustomModelType ===
                                                                TypeEnum.GradedEvaluation
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </If>

                                    <Row style={{ marginTop: 24 }}>
                                        <Col span={8}>
                                            <div>
                                                <Button type="primary" onClick={actions.save}>
                                                    {UdeskLocales.current.labels.save}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <If condition={privates.type === Udesk.enums.pointTypes.manual.id}>
                                    <TaskConditionDictionaries
                                        items={privates.model.inspectionConditions}
                                    />
                                </If>
                            </If>
                            <If
                                condition={
                                    privates.type === Udesk.enums.pointTypes.machineLearning.id
                                }
                            >
                                <div className="tasks-manage-template-configuration-detail-machine-learning">
                                    <div className="tasks-manage-template-configuration-detail-machine-learning-logic">
                                        {
                                            UdeskLocales.current.pages.tasks.manage.template
                                                .checkPoint.configuration.logic.logic
                                        }
                                        <span style={{ color: 'rgba(0,0,0,0.45)' }}>
                                            {
                                                UdeskLocales.current.pages.tasks.manage.template
                                                    .checkPoint.configuration.logic
                                                    .machineLearningTip
                                            }
                                        </span>
                                    </div>
                                    <div className="tasks-manage-template-configuration-detail-machine-learning-score">
                                        {
                                            UdeskLocales.current.pages.tasks.manage.template
                                                .checkPoint.configuration.logic.score
                                        }
                                        <Select
                                            value={privates.scoreType}
                                            style={{ width: 86 }}
                                            onChange={actions.handleMachineLearningScoreChange}
                                        >
                                            {Udesk.enums.calculateScoreTypes.map((item) => {
                                                return <Option value={item.id}>{item.name}</Option>;
                                            })}
                                            {/* {UdeskLocales.current.enums.tasks.calculateScoreTypes}
                                            Udesk.enums.calculateScoreTypes.add.id */}
                                        </Select>
                                        <InputNumber
                                            min={0}
                                            step={0.5}
                                            style={{ width: 200 }}
                                            value={privates.predeterminedScore}
                                            onChange={actions.handleScoreInput}
                                            addonAfter={UdeskLocales.current.labels.scoreUnit}
                                        ></InputNumber>
                                    </div>
                                    <div className="tasks-manage-template-configuration-detail-machine-learning-strategy">
                                        {
                                            UdeskLocales.current.pages.tasks.manage.template
                                                .checkPoint.configuration.logic.strategy
                                        }
                                        <Radio.Group
                                            onChange={actions.onStrategyTypeChange}
                                            value={privates.gradeType}
                                        >
                                            <If
                                                condition={
                                                    privates.scoreType ===
                                                    Udesk.enums.calculateScoreTypes.add.id
                                                }
                                            >
                                                <Radio value={Udesk.enums.strategyTypes.hitAdd.id}>
                                                    {Udesk.enums.strategyTypes.hitAdd.name}
                                                </Radio>
                                                <Radio
                                                    value={Udesk.enums.strategyTypes.hitNotAdd.id}
                                                >
                                                    {Udesk.enums.strategyTypes.hitNotAdd.name}
                                                </Radio>
                                            </If>
                                            <If
                                                condition={
                                                    privates.scoreType ===
                                                    Udesk.enums.calculateScoreTypes.sub.id
                                                }
                                            >
                                                <Radio value={Udesk.enums.strategyTypes.hitSub.id}>
                                                    {Udesk.enums.strategyTypes.hitSub.name}
                                                </Radio>
                                                <Radio
                                                    value={Udesk.enums.strategyTypes.hitNotSub.id}
                                                >
                                                    {Udesk.enums.strategyTypes.hitNotSub.name}
                                                </Radio>
                                            </If>
                                        </Radio.Group>
                                    </div>
                                    <div className="tasks-manage-template-configuration-detail-machine-learning-footer">
                                        <Button
                                            type="primary"
                                            onClick={actions.machineLearningCommit}
                                            style={{ marginRight: 8 }}
                                        >
                                            {this.locales.labels.submit}
                                        </Button>
                                        <Button onClick={actions.transitionToCheckPointIndex}>
                                            {this.locales.labels.cancel}
                                        </Button>
                                    </div>
                                </div>
                            </If>
                        </div>
                        <If
                            condition={
                                (privates.type === Udesk.enums.pointTypes.intelligentModel.id &&
                                    !(
                                        privates.intelligentSecondType ===
                                            Udesk.enums.modelTypes.custom.id &&
                                        privates.intelligentCustomModelType ===
                                            TypeEnum.GradedEvaluation
                                    )) ||
                                privates.type === Udesk.enums.pointTypes.smartRules.id
                            }
                        >
                            <div className="udesk-qa-web-page-footer tasks-manage-template-configuration-detail-body-footer">
                                {props.history.location.pathname.match(/\d+\/view\/\d+/) ||
                                !(privates.isWechatQa || privates.isRuleTemplate
                                    ? true
                                    : privates.enabledInspectRuleApprove &&
                                      privates.computes.isEditing
                                    ? privates.model.inspectionPoint?.configVersion !== 2 ||
                                      privates.model.inspectionPoint?.historyVersion === 1
                                    : true) ? null : (
                                    <Button
                                        type="primary"
                                        onClick={actions.commit}
                                        loading={loading}
                                        style={{ marginRight: 8 }}
                                    >
                                        {UdeskLocales.current.labels.submit}
                                    </Button>
                                )}
                                <Button onClick={actions.transitionToCheckPointIndex}>
                                    {UdeskLocales.current.labels.cancel}
                                </Button>
                            </div>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}
