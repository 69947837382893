import React from 'react';
import Udesk from 'Udesk';
import { Badge, Image } from 'udesk-ui';
import './index.less';

export const Cover = React.memo((props: { src: string; courseType?: number, isPad?: boolean }) => {
    const courseType = Udesk.enums.lessonType.find(t => t.id === props.courseType);
    return courseType ? (
        <Badge.Ribbon text={courseType.name} color={courseType.color} placement="start">
            <Image
                wrapperClassName={'udesk-qa-coach-cover-image'}
                width={props.isPad ? 150 : 250}
                height={150}
                placeholder={true}
                src={props.src || '/static/images/course-cover.png'}
            />
        </Badge.Ribbon>
    ) : (
        <Image
            wrapperClassName={'udesk-qa-coach-cover-image'}
            width={props.isPad ? 150 : 250}
            height={150}
            placeholder={true}
            src={props.src || '/static/images/course-cover.png'}
        />
    );
});
