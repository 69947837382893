import React from 'react';
import { Icon, Tooltip } from 'udesk-ui';

export default function Card(props) {
    const tip = props.subTip ? (
        <div>
            <div style={{ whiteSpace: 'pre-wrap' }}>{props.tip}</div>
            <div style={{ whiteSpace: 'pre-wrap' }}>{props.subTip}</div>
        </div>
    ) : (
        <div style={{ whiteSpace: 'pre-wrap' }}>{props.tip}</div>
    );
    return (
        <div className="numerical-card">
            <div className="numerical-card-tooltip">
                <Tooltip title={tip} placement="topRight">
                    <Icon
                        antdIcon={true}
                        type="InfoCircleOutlined"
                        style={{ color: 'rgba(0, 0, 0, 0.25)' }}
                    />
                </Tooltip>
            </div>
            <div className="numerical-card-main">
                <div className="numerical-card-main-icon">
                    <Icon type={props.icon} style={{ fontSize: '48px' }} />
                </div>
                <div className="numerical-card-main-count">
                    <div className="title">{props.name}</div>
                    <div className="count">{props.data || '-'}</div>
                </div>
            </div>
            {props.percentName && (
                <div className="numerical-card-desc">
                    {`${props.percentName}: ${props.percent || '-'}`}
                </div>
            )}
        </div>
    );
}
