import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Form, Avatar, Tooltip, Icon } from 'udesk-ui';
import { getUsersByUuid as getUsersById} from 'src/api/users/{uuid}';
import { BaseResponseUserFoundResponse } from 'src/api/types';

const locales = Locales['current'];

const AdminGongUserDetail = React.memo((props: any) => {
    const [detailData, setDetailData] = useState<any>({});
    const formItemLayout = {
        labelCol: {
            sm: {
                span: 4,
            },
        },
    };
    useEffect(() => {
        getUsersById({ segments: { uuid: props.match.params.id } }).then((res: BaseResponseUserFoundResponse) => {
            setDetailData(res.data);
        });
    }, []);
    return (
        <Page pageBodyClassName='page-gong-user-manage-edit' onBack={() => window.history.back()} title={locales.pages.gong.userManage.detail.viewDetails} padding={true}>
            <Form {...formItemLayout}>
                <Form.Item label={locales.pages.gong.userManage.detail.accountNumber}>{detailData?.email}</Form.Item>
                <Form.Item label={locales.pages.gong.userManage.detail.headPortrait}>
                    <Avatar src={detailData?.headPortraitUrl} />
                </Form.Item>
                <Form.Item label={locales.pages.gong.userManage.detail.fullName}>{detailData?.realname}</Form.Item>
                <Form.Item label={locales.pages.gong.userManage.detail.nickname}>{detailData?.nickname??'--'}</Form.Item>
                <Form.Item label={locales.pages.gong.userManage.detail.department}>{detailData?.organizationName}</Form.Item>
                <Form.Item label={locales.pages.gong.userManage.detail.excellentStaff}>{detailData?.isExample ? locales.pages.gong.userManage.detail.yes : locales.pages.gong.userManage.detail.no}</Form.Item>
                <Form.Item
                    label={
                        <span>{locales.pages.gong.userManage.detail.sessionIdentity}
                            <Tooltip title={locales.pages.gong.userManage.detail.markTheSessionRecordAsBelongingToTheEmployee}>
                                <Icon type='QuestionCircleOutlined' antdIcon={true}></Icon>
                            </Tooltip>
                        </span>
                    }
                >
                    {detailData?.callIdentification??'--'}
                </Form.Item>
                <Form.Item label={locales.pages.gong.userManage.detail.telephone}>{detailData?.mobile??'--'}</Form.Item>
                <Form.Item label={locales.pages.gong.userManage.detail.jobNumber}>{detailData?.agentId??'--'}</Form.Item>
                <Form.Item label={locales.pages.gong.userManage.detail.role}>{detailData?.roleList?.[0]?.name}</Form.Item>
            </Form>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongUserDetail {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
