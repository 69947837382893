import React from "react";
import Udesk from 'Udesk';
import isNumber from 'lodash-es/isNumber';
import isString from 'lodash-es/isString';
import isArray from 'lodash-es/isArray';
import Locales from 'UdeskLocales';
import importSuccessNotification from "Component/common/import-notification";
import { getSystemModule } from 'Udesk/system/subApp';

class InteractiveRecognitionComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
        columns: [],

    };
    privates = {
        keyword: null,
        isUploadVisible: false,
        file: null,
        uploadData: null,
        isNodeModelVisible: false, //编辑/新增节点弹框显示不显示
        isEditNodeModel: false, //是编辑还是新增节点
        selectedNode: {},
        currentNode: {},
        activeNode: {}, //当前选中显示的节点,
        interactiveDatas: [],
        categories: [],
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: Udesk.config.paging.defaultPageSize,
        total: null
    };

    actions = {
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        searchItems(e) {
            if (e.keyCode === 13) {
                this.actions.reloadAsyncModel({
                    pageNum: Udesk.config.paging.defaultPageNumber,
                    pageSize: Udesk.config.paging.defaultPageSize
                });
            }
            return;
        },
        createItem() {
            let routeOptions = {
                history: this.props.history,
                routeName: "interactiveRecognitionNew"
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        editItem(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: "interactiveRecognitionEdit",
                pathParams: {
                    id: item.id
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        deleteItem(item) {
            let { locales } = this;
            let onConfirm = () => {
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(
                    `interactiveDatas/${item.id}`,
                    sdkOptions
                );
                Udesk.ajax.del(url).then(
                    resp => {
                        Udesk.ui.notify.success(
                            locales.business.notifyMessage.deleteSuccess
                        );
                        this.actions.reloadAsyncModel();
                    },
                    reason => {
                        Udesk.ui.notify.error(
                            reason.errorMsg || locales.business.notifyMessage.deleteFailed
                        );
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: locales.labels.deleteContent,
                onOk: onConfirm
            });
        },
        changeUploadVisible() {
            let { isUploadVisible } = this.privates;
            this.privates.isUploadVisible = !isUploadVisible;
            this.actions.update();
        },
        uploadFile(e) {
            let {
                locales
            } = this;
            let files = e.target.files;

            if (files.length === 0) {
                return;
            }

            // 取消文件大小限制
            // if (files[0] && (files[0].size > 2 * 1024 * 1024)) {
            //     return Udesk.ui.notify.error(UDL.business.notifyMessage.overFileSize);
            // }
            let fileName = fileType(files[0].name);
            if (!fileName) {
                return;
            } else if (fileName !== 'xlsx') {
                return Udesk.ui.notify.error(Udesk.utils.string.format(locales.labels.fileTypeHolder, 'xlsx'));
            }
            //type 1语音,2导入,3导出,4语料数据
            Udesk.app.fileStorage.upload(files[0], {
                token: new Promise((resolve, reject) => {
                    Udesk.business
                        .getServerAuthorization({
                            type: 2
                        })
                        .then(
                            function (resp) {
                                resolve(resp);
                            },
                            function (reason) {
                                reject(reason);
                            }
                        );
                }),
                //progressCallback: progressCallback.bind(this),
                successCallback: this.actions.uploadFileSuccess.bind(this),
                errorCallback: this.actions.uploadFileError.bind(this)
            });
        },
        downloadTemplate() {
            let { sdkOptions } = this.props;

            let templateLocalUrl = `/import/interactive-recognitions-template.xlsx`;
            let templateUrl =
                Udesk.business.apiPath.getStaticBasePath(
                    sdkOptions.props.domain,
                    sdkOptions.props.pathPrefix
                ) + templateLocalUrl;

            window.location = templateUrl;
        },
        uploadFileSuccess(file, uploadData) {
            this.privates.file = file;
            this.privates.uploadData = uploadData;
            this.actions.update();
        },
        uploadFileError(file, uploadData) { },
        importRecognitions() {
            let { sdkOptions } = this.props;
            let { file, uploadData } = this.privates;
            if (file == null || uploadData == null) {
                return Udesk.ui.notify.error(
                    this.locales.business.notifyMessage.pleaseUploadFile
                );
            }
            let params = {
                keyName: uploadData.key,
                originalFileName: file.name,
                systemModule: getSystemModule(),
            };
            let url = Udesk.business.apiPath.concatApiPath(
                `interactiveDatas/import`,
                sdkOptions
            );
            Udesk.ajax.post(url, params).then(
                resp => {
                    Udesk.ui.notify.success(
                        this.locales.business.notifyMessage.importFileSuccess
                    );
                    this.actions.changeUploadVisible();
                    importSuccessNotification();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        this.locales.business.notifyMessage.importFildFailed
                    );
                    this.actions.changeUploadVisible();
                }
            );
        },
        addNode(node) {
            this.privates.currentNode = node;
            this.privates.isEditNodeModel = false;
            return new Promise((resolve, reject) => {
                let modalData = {
                    name: "",
                    onSaveSuccessCallback: resolve,
                    onSaveErrorCallback: reject
                };
                this.privates.selectedNode = modalData;
                this.privates.isNodeModelVisible = true;
                this.actions.update();
            });
        },
        editNode(node) {
            this.privates.currentNode = node;
            this.privates.isEditNodeModel = true;
            return new Promise((resolve, reject) => {
                let modalData = {
                    id: node.id,
                    name: node.name,
                    onSaveSuccessCallback: resolve,
                    onSaveErrorCallback: reject
                };
                this.privates.selectedNode = modalData;
                this.privates.isNodeModelVisible = true;
                this.actions.update();
            });
        },
        saveNode() {
            let { locales } = this;
            let { sdkOptions } = this.props;
            let {
                selectedNode, //当前正在新建或者编辑的节点
                currentNode //当前点击的节点，用于新建时取参数pid
            } = this.privates;
            if (selectedNode.name == null || selectedNode.name.trim() === "") {
                return;
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `interactiveCategories`,
                sdkOptions
            );
            let method = Udesk.enums.requestMethods.create.name;
            let params = {
                name: selectedNode.name,
                pid: currentNode.id
            };
            if (selectedNode.id) {
                url = Udesk.business.apiPath.concatApiPath(
                    `interactiveCategories/${selectedNode.id}`,
                    sdkOptions
                );
                method = Udesk.enums.requestMethods.update.name;
                params = selectedNode;
            }
            Udesk.ajax(url, params, method).then(
                resp => {
                    selectedNode.onSaveSuccessCallback(resp.data);
                    this.actions.changeNodeModelVisible();
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locales.components.pages.interactiveRecognition.title
                        )
                    );
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveFailedFormat,
                            locales.components.pages.interactiveRecognition.title
                        )
                    );
                }
            );
        },
        changeNodeModelVisible() {
            let { isNodeModelVisible } = this.privates;
            this.privates.isNodeModelVisible = !isNodeModelVisible;
            this.actions.update();
        },
        changeNodeName(e) {
            let { selectedNode } = this.privates;
            selectedNode.name = e.target.value;
            // this.actions.update();
        },
        deleteNode(node) {
            let { sdkOptions } = this.props;
            return new Promise((resolve, reject) => {
                Udesk.ui.alerts.confirm({
                    onOk: function () {
                        let url = Udesk.business.apiPath.concatApiPath(
                            `interactiveCategories/${node.id}`,
                            sdkOptions
                        );
                        Udesk.ajax.del(url).then(
                            resp => {
                                resolve(resp);
                            },
                            reason => {
                                reject(reason.errorMsg);
                                Udesk.ui.notify.error(reason.errorMsg);
                            }
                        );
                    }
                });
            });
        },
        switchNode(node) {
            this.privates.activeNode = node;
            this.actions.reloadAsyncModel();
        },
        getNodeBehaviors(node) {
            if (node.level && node.level === 5) {
                return {
                    expanded: true,
                    actions: {
                        visible: true,
                        add: {
                            visible: false
                        }
                    }
                };
            }
            if (node.level && node.level === 1) {
                return {
                    expanded: true,
                    actions: {
                        visible: true,
                        delete: {
                            visible: false
                        },
                        edit: {
                            visible: false
                        }
                    }
                };
            }
            if (node.name && node.name === Locales['current'].fix.uncategorized) {
                return {
                    expanded: true,
                    actions: {
                        visible: true,
                        add: {
                            visible: false
                        },
                        delete: {
                            visible: false
                        },
                        edit: {
                            visible: false
                        }
                    }
                };
            }
            return {
                expanded: true
            };
        },
        export() {
            let { sdkOptions } = this.props;
            let getListUrl = Udesk.business.apiPath.concatApiPath(
                `/interactiveDatas/export`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(getListUrl, { type: 1, systemModule: getSystemModule(), }).then(
                    resp => {
                        resolve(resp);
                        Udesk.ui.notify.success(resp.message);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        grounpSaveChange(value, id) {
            if (!id) {
                let data = {
                    name: value.agentGroupName,
                    pid: value.parentId
                };
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(
                    `interactiveCategories`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(url, data).then(
                        resp => {
                            Udesk.ui.notify.success(Locales['current'].fix.addSuccess);
                            this.actions.reloadAsyncModel();
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            } else {
                let data = {
                    id: value.id,
                    name: value.agentGroupName
                };
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(
                    `interactiveCategories/${value.id}`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.put(url, data).then(
                        resp => {
                            Udesk.ui.notify.success(Locales['current'].fix.editSuccess);
                            this.actions.reloadAsyncModel();
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
        groupNodeDelChange(id) {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `interactiveCategories/${id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.del(url).then(
                    resp => {
                        Udesk.ui.notify.success(Locales['current'].fix.deleteSuccess);
                        if(`${id}` === `${this.privates.activeNode.id}`){
                            this.privates.activeNode = {};
                        }
                        this.actions.reloadAsyncModel();
                        this.privates.selectedItems = [];
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onSelectSearch(list) {
            let id = '';
            if (isNumber(list) || isString(list)) {
                id = list;
            } else if (isArray(list)) {
                let value = list[0];
                if (value.indexOf('grounp') !== -1) {
                    id = value.split('-')[1];
                }
            }
            this.privates.activeNode.id = id;
            this.actions.reloadAsyncModel();
        },
        categoriesDataMap(data) {
            if (data && data.length > 0) {
                data.forEach(item => {
                    item.title = item.name;
                    item.value = item.id;
                    item.key = `grounp-${item.id}`;
                    item.level = item.level;
                    if (item.level === 1 && item.defaultFlag === 1) {
                        item.nodeType = 1;
                    } else if (item.level === 2 && item.defaultFlag === 1) {
                        item.nodeType = 2;
                    } else {
                        item.nodeType = 3;
                    }
                    if (item.children) {
                        this.actions.categoriesDataMap(item.children);
                    }
                });
            }
        }
    };
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult, asyncKey } = parseOptions;
        if (asyncKey === "categories") {
            if (asyncResult.interactiveDatas.data) {
                this.privates.interactiveDatas = asyncResult.interactiveDatas.data;
            }
            if (asyncResult.interactiveDatas.paging) {
                this.privates.pageNum = asyncResult.interactiveDatas.paging.pageNum;
                this.privates.pageSize = asyncResult.interactiveDatas.paging.pageSize;
                this.privates.total = asyncResult.interactiveDatas.paging.total;
            }
            if (asyncResult.categories) {
                this.privates.categories = asyncResult.categories;
                this.actions.categoriesDataMap(this.privates.categories);
            }
            this.actions.update();
        }
    }
    //#region Life Cycle
    componentDidMount() {
        getColumns(this);
    }
    componentWillUnmount() { }
    //#endregion
}

function getColumns(that) {
    let { actions, locales } = that;
    let columns = [
        {
            name: "questionAndAnswer",
            width: "60%",
            caption: locales.fields.interactiveRecognition.questionAndAnswer,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                if (item.details && item.type === 2) {
                    if (item.details.length > 0) {
                        return (
                            <div>
                                <div className="question" title={item.name}>
                                    {locales.fix.featureName}：
                                    {
                                        item.name
                                    }
                                </div>
                                <div className="question" title={item.details[0].content}>
                                    {
                                        item.details[0].elementParamType === 1 ? locales.fix.informationEntity + '：' : item.details[0].elementParamType === 2 && locales.fix.semanticTags+ '：'
                                    }
                                    {item.details[0].content}
                                </div>
                                {
                                    item.details.length > 1 && (
                                        <div className="answer" title={item.details[1].content}>

                                            {
                                                item.details[1].elementParamType === 1 ? locales.fix.informationEntity + '：' : item.details[1].elementParamType === 2 && locales.fix.semanticTags+ '：'
                                            }
                                            {item.details[1].content}
                                        </div>
                                    )

                                }

                            </div>
                        );
                    } else {
                        return (
                            <div className="question" title={item.name}>
                                {locales.fix.featureName}：
                                {
                                    item.name
                                }
                            </div>
                        );
                    }

                } else {
                    return (
                        <div>
                            <div className="question" title={item.standardQuestion}>
                                {locales.labels.question}
                                {locales.labels.colon}
                                {item.standardQuestion}
                            </div>
                            <div className="answer" title={item.standardAnswer}>
                                {locales.labels.answer}
                                {locales.labels.colon}
                                {item.standardAnswer}
                            </div>
                        </div >
                    );
                }

            }
        },
        {
            name: "moduleCompany",
            width: "10%",
            caption: locales.fields.semanticTags.orgName,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return item.sourceType === 3 ? Udesk.enums.organizationTypes.getName(item.sourceType) : locales.fix.null ;
            }
        },
        {
            name: "type",
            width: "10%",
            caption: locales.fields.interactiveRecognition.type,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return Udesk.enums.interactiveQuestionTypes.getName(item.type);
            }
        },
        {
            name: "status",
            width: "10%",
            caption: locales.fields.interactiveRecognition.status,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return Udesk.enums.activeFlags.getName(item.status);
            }
        },
        {
            name: "actions",
            width: "10%",
            caption: locales.fields.interactiveRecognition.actions,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return (
                    <div className="qa-react-page-body-content-table-actions">
                        <If
                            condition={
                                Udesk.data.init.user &&
                                Udesk.data.init.user.hasFeature("semantic:interactive:edit")
                            }
                        >
                            <If condition={(item.moduleId && item.editEnable && item.sourceType === 3) || !item.moduleId || item.sourceType !== 3}>
                                <span
                                    className="action-btn"
                                    title={locales.labels.edit}
                                    onClick={actions.editItem.params(item)}
                                >
                                    {locales.labels.edit}
                                </span>
                            </If>
                            <If condition={item.moduleId && item.showEnable && !item.editEnable && item.sourceType === 3}>
                                <span className="action-btn" title={locales.labels.view} onClick={actions.editItem.params(item)}>
                                    {locales.labels.view}
                                </span>
                            </If>
                        </If>
                        <If
                            condition={
                                Udesk.data.init.user &&
                                Udesk.data.init.user.hasFeature("semantic:interactive:delete")
                            }
                        >
                            <span
                                className="action-btn delete-btn"
                                title={locales.labels.delete}
                                onClick={actions.deleteItem.params(item)}
                            >
                                {locales.labels.delete}
                            </span>
                        </If>
                    </div>
                );
            }
        }
    ];
    that.setState({
        columns
    });
}
function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
export default InteractiveRecognitionComponent;
