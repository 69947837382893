import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import uniq from 'lodash-es/uniq';
import { postFieldDataSearchTemplate } from 'src/api/field/data/search-template';
import {
    FieldWithTypeWrapperResponse,
    OperatorMetaWrapperResponse,
    // CallWorkTimeFoundResponse,
} from 'src/api/types';
import { postOperatorMeta } from 'src/api/operator/meta';
import { isQaSubApp } from 'Udesk/system/subApp';
import { getCallWorkTimes } from 'src/api/callWorkTimes';

export const AssociateContext = React.createContext<any>(undefined);
export const WorkTimeContext = React.createContext<any>(undefined);

export const AssociateProvider = ({ children, type }) => {
    const [associateList, setAssociateList] = useState<FieldWithTypeWrapperResponse[]>([]);
    const [associateOperatorList, setAssociateOperatorList] = useState<
        OperatorMetaWrapperResponse[]
    >([]);

    const getCurrentTypeDataSearchTemplate = useCallback((type) => {
        const list = Array.isArray(type) ? [...type] : [type];

        const sourceList = uniq(
            list
                .map((type) => {
                    const item = Udesk.enums.inspectDataSources.get(type);
                    if (item) {
                        if (Udesk.enums.inspectDataSources.realTimeVoiceCall.id === type) {
                            return Udesk.enums.inspectDataSources.voiceCall.id;
                        }
                        if (Udesk.enums.inspectDataSources.realTimeTextDialogue.id === type) {
                            return Udesk.enums.inspectDataSources.textDialogue.id;
                        }

                        return type;
                    }
                    return null;
                })
                .filter((type) => type !== null)
        );

        if (sourceList.length > 0) {
            let data = {
                sourceList,
                typeList: [1, 3, 6],
            };
            if (isQaSubApp()) {
                data.typeList.push(15); // 工单随路数据
            }
            return postFieldDataSearchTemplate(data).then((resp) => {
                return resp?.data?.[0]?.typeList ?? [];
            });
        }
        return Promise.resolve([]);
    }, []);
    useEffect(() => {
        let data = {
            typeList: [1, 2],
        };
        postOperatorMeta(data).then(
            (resp) => {
                setAssociateOperatorList(resp?.data ?? []);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, []);
    useEffect(() => {
        getCurrentTypeDataSearchTemplate(type).then((typeList) => {
            setAssociateList(typeList);
        });
    }, [JSON.stringify(type), getCurrentTypeDataSearchTemplate]);

    const value = useMemo(
        () => ({
            associateList,
            associateOperatorList,
            updateDataSearchTemplateByDynamicType: getCurrentTypeDataSearchTemplate,
        }),
        [associateList, associateOperatorList, getCurrentTypeDataSearchTemplate]
    );
    return <AssociateContext.Provider value={value}>{children}</AssociateContext.Provider>;
};

export const useAssociateContext = () => {
    const ctx = useContext(AssociateContext);
    return ctx;
};

export const WorkTimeProvider = ({ children }) => {
    const [workTimes, steWorkTimes] = useState<any[]>([]);
    useEffect(() => {
        getCallWorkTimes({
            params: {
                pageNum: 1,
                pageSize: 100,
            },
        }).then((resp) => {
            steWorkTimes((resp.data ?? []).map((i) => ({ label: i.name, value: i.id })));
        });
    }, []);
    const value = useMemo(
        () => ({
            workTimes,
        }),
        [workTimes]
    );
    return <WorkTimeContext.Provider value={value}>{children}</WorkTimeContext.Provider>;
};

export const useWorkTimeContext = () => {
    const ctx = useContext(WorkTimeContext);
    return ctx;
};
