import Udesk from 'Udesk';
import config from '../config';

function buildConditionFields({ fields, scenarioEnum }) {
    if (fields == null) {
        fields = [];
    }
    if (!(fields instanceof Array)) {
        fields = [fields];
    }
    let fullFields = fields.map((field) => {
        let fullField = config.buildConditionField([field], scenarioEnum);
        fullField.fieldPath = [field];
        // 对筛选项进行设置 dataSource
        if (
            [
                Udesk.enums.inputTypes.multiListbox.id,
                Udesk.enums.inputTypes.sigleListbox.id,
                Udesk.enums.inputTypes.supervision.id,
                Udesk.enums.inputTypes.keyEvents.id,
                Udesk.enums.inputTypes.cascader.id,
            ].includes(fullField.inputType.id)
        ) {
            fullField.dataSource = fullField.originalField.optionValueList || [];
        }
        return fullField;
    });
    return fullFields;
}

function attachExtraConditionData(condition, field) {
    if (condition != null && field != null) {
        if (field.fieldType != null) {
            condition.fieldType = field.fieldType.id;
        }
    }
    return condition;
}

function conditionFieldsTransferToField({ condition, scenarioEnum }) {
    let fields = condition.fieldList;
    if (fields == null) {
        return null;
    }
    if (!(fields instanceof Array)) {
        fields = [fields];
    }
    if (fields.length < 1) {
        return null;
    }
    return config.buildConditionField(fields, scenarioEnum);
}

export default {
    buildConditionFields,
    attachExtraConditionData,
    conditionFieldsTransferToField,
};
