// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type { BaseResponseListIntelligentPartnerDialogNodeAiTemplateFoundResponse } from '../../../../../types';

/**
 * 查询绑定的客户��像(包含固定的4个模板)
 *
 * @export
 * @tags 智能陪练-AI输入场景节点配置
 * @link [GET] /review/intelligentPartner/node/inputSceneConfig/template
 */
export function getReviewIntelligentPartnerNodeInputSceneConfigTemplate(): Promise<BaseResponseListIntelligentPartnerDialogNodeAiTemplateFoundResponse>;
export function getReviewIntelligentPartnerNodeInputSceneConfigTemplate(
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogNodeAiTemplateFoundResponse>;
export function getReviewIntelligentPartnerNodeInputSceneConfigTemplate(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogNodeAiTemplateFoundResponse> {
    return request<never, never, never>(
        '/review/intelligentPartner/node/inputSceneConfig/template',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeInputSceneConfigTemplate',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-AI输入场景节点配置'],
        path: '/review/intelligentPartner/node/inputSceneConfig/template',
        method: 'get',
    },
];
