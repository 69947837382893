import Udesk from 'Udesk';
import React from 'react';
import LayoutMenuComponent from './component';
import './style.scss';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Menu, Layout, Badge } from 'udesk-ui';
const { SubMenu } = Menu;
const { Sider, Content } = Layout;

export default class LayoutMenuTemplate extends LayoutMenuComponent {
    render() {
        let { actions } = this;
        let { routes, route, sdkOptions, navLists, menuTitle, hideSider } = this.props;
        let { layoutSiderRef, collapsed } = this.privates;
        const hasFeature =
            (sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null) ||
            (Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null);
        const siderAction = (
            <div className="layout-menu-pages-sider-actions-arrow">
                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-zhankaitixing"></i>
                {!collapsed ? (
                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-left-s-line"></i>
                ) : (
                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-youjiantou"></i>
                )}
            </div>
        );
        return (
            <div className="layout-menu-pages">
                <Layout style={{ height: '100%' }}>
                    {hideSider ? null : (
                        <Sider
                            ref={layoutSiderRef}
                            collapsed={collapsed}
                            width={Udesk.data.init.userLanguage === 'en' ? 300 : 200}
                            collapsedWidth={1}
                            collapsible
                            className="layout-menu-pages-sider"
                            onCollapse={actions.siderActionsChange}
                            trigger={siderAction}
                        >
                            <div className="layout-second-level-header">
                                <span className="layout-second-level-header-title">
                                    {menuTitle}
                                </span>
                            </div>
                            <Menu
                                theme="light"
                                mode="inline"
                                onClick={actions.transitionToPage}
                                selectedKeys={actions.navlistMap(navLists)}
                                defaultOpenKeys={actions.openMap(navLists)}
                                style={{
                                    height: 'calc(100% - 58px)',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                            >
                                {navLists &&
                                    navLists.length > 0 &&
                                    navLists.map((item, index) => {
                                        if (item.children && item.children.length > 0) {
                                            return (
                                                <SubMenu title={item.text} key={`group-${index}`}>
                                                    {item.children.map((subItem) => {
                                                        return (
                                                            <Menu.Item
                                                                key={subItem.pathName}
                                                                disabled={subItem.disabled}
                                                                _navitem={subItem}
                                                            >
                                                                {subItem.text}
                                                            </Menu.Item>
                                                        );
                                                    })}
                                                </SubMenu>
                                            );
                                        } else {
                                            return (
                                                <Menu.Item
                                                    key={item.pathName}
                                                    disabled={item.disabled}
                                                    _navitem={item}
                                                >
                                                    <Badge dot={item.dot}>
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                                width: '100%',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                            title={item.text}
                                                        >
                                                            {item.text}
                                                        </span>
                                                    </Badge>
                                                </Menu.Item>
                                            );
                                        }
                                    })}
                            </Menu>
                        </Sider>
                    )}

                    <Content style={{ background: 'rgba(240,242,245,1)' }}>
                        <div style={{ height: '100%' }}>
                            <For each="route" index="i" of={routes}>
                                <If
                                    condition={
                                        !route.authCode ||
                                        (route.authCode && hasFeature && hasFeature(route.authCode))
                                    }
                                >
                                    <SubRoutes key={i} route={route} />
                                </If>
                            </For>
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}
