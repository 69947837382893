import Udesk from 'Udesk';

function getOssAuthorization(dataType, sdkOptions) {
    // 上传头像之前，先向服务器发送请求获得OSS签名
    return new Promise((resolve, reject) => {
        let url = Udesk.business.apiPath.concatApiPath(`oss/tokens/${dataType}`, sdkOptions);
        Udesk.ajax.get(url)
            .then(
                (resp) => {
                    resolve(resp);
                    // let host = resp.data.host;
                    // let tokenData = Object.assign(resp.data);
                    // let data = Udesk.app.fileStorage.upload.defaults.uploadAdapter.getRequestBody(files, tokenData);
                    // resolve({
                    //     action: host,
                    //     data: data,
                    //     onSuccess: () => {
                    //         onSuccess(`${host}/${data.Filename}`);
                    //     },
                    //     onError: () => {
                    //         onError();
                    //     }
                    // });
                },
                (reason) => {
                    // onError();
                    reject(reason);
                }
            );
    });
}
export default {
    getOssAuthorization
};