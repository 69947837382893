import React from 'react';
import LinkTo from '../link-to';
import NavBarComponent from './component';

class NavBarTemplate extends NavBarComponent {
    render() {
        let {
            navLists,
            textValueField,
            pathValueField,
            classNames,
            navItemClassNames,
            activeClassNames,
            theme,
            tooltipValue,
            pathParamsValueField,
            queryParamsValueField,
            CustomReactTool,
            CustomReactToolExportUrl
        } = this.props;

        return (
            <div className={`${classNames} ${theme} component-report-nav-bar`}>
                <For each="list" index="index" of={navLists}>
                    <LinkTo
                        linkConfig={list}
                        key={`${list[pathValueField]}-${index}`}
                        navItemClassNames={navItemClassNames}
                        activeClassNames={activeClassNames}
                        pathParamsValueField={pathParamsValueField}
                        queryParamsValueField={queryParamsValueField}
                        textValueField={textValueField}
                        tooltipValue={tooltipValue}
                        activeClassName={`report-nav-bar-item-active ${activeClassNames}`} onChanged={this.actions.onClicked} CustomReactTool={CustomReactTool} CustomReactToolExportUrl={CustomReactToolExportUrl} />
                </For>
            </div>
        );
    }
}

export default NavBarTemplate;