import Udesk from 'Udesk';
import React from 'react';
import TaskCheckRangeComponent from './component';
import { Select } from 'udesk-ui';
import './style.scss';

export default class TaskCheckRangeTemplate extends TaskCheckRangeComponent {
    render() {
        let { actions, locales, state } = this;
        return (
            <div className="check-range-component">
                <div>
                    <p className="check-range-condition-title">{locales.components.checkRange.conditionLimit}</p>
                    <div>
                        <Select
                            value={state.applyOption}
                            className="form-control manage-bot check-range-apply-option"
                            onChange={actions.changeApplyConditionId}>
                            <For each="item" index="index" of={Udesk.enums.applyOptions}>
                                <Select.Option value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                        <If condition={state.applyOption === Udesk.enums.applyOptions.hit.id}>
                            <Select
                                value={state.condition.applyConditionId}
                                onChange={actions.changeCondition.params('applyConditionId')}
                                className="form-control manage-bot check-range-apply-condition-id">
                                <For each="condition" index="index" of={state.inspectionConditions}>
                                    <Select.Option value={condition.id}>{condition.name}</Select.Option>
                                </For>
                            </Select>
                            <Select
                                value={state.condition.applyConditionHitType}
                                onChange={actions.changeCondition.params('applyConditionHitType')}
                                className="form-control manage-bot check-range-apply-condition-hit-type">
                                <For each="item" index="index" of={Udesk.enums.applyConditionHitTypes}>
                                    <Select.Option value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                            <Select
                                value={state.condition.applyConditionScope}
                                onChange={actions.changeCondition.params('applyConditionScope')}
                                className="form-control manage-bot check-range-apply-condition-scope">
                                <For each="item" index="index" of={Udesk.enums.applyConditionScopes}>
                                    <Select.Option value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                            <If
                                condition={
                                    state.condition.applyConditionScope === Udesk.enums.applyConditionScopes.before.id ||
                                    state.condition.applyConditionScope === Udesk.enums.applyConditionScopes.after.id
                                }>
                                <input
                                    type="number"
                                    min="0"
                                    className="form-control check-range-apply-condition-limit"
                                    value={state.condition.applyConditionLimit}
                                    onChange={actions.changeCondition.params('applyConditionLimit')}
                                    onBlur={actions.validateRangeValue.params(
                                        state.condition,
                                        'applyConditionLimit',
                                        state.condition.applyConditionLimit
                                    )}
                                />
                                {locales.components.checkRange.numberTipTwo}
                            </If>
                        </If>
                    </div>
                </div>
                <div>
                    <p className="check-range-condition-title">{locales.components.checkRange.roleLimit}</p>
                    <Select
                        value={state.condition.applyRole}
                        onChange={actions.changeCondition.params('applyRole')}
                        className="form-control manage-bot check-range-apply-role">
                        <For each="role" index="index" of={Udesk.enums.applyRoles}>
                            <Select.Option value={role.id}>{role.name}</Select.Option>
                        </For>
                    </Select>
                </div>
                <div>
                    <p className="check-range-condition-title">{locales.components.checkRange.contextLimit}</p>
                    <div>
                        {locales.components.checkRange.numberTipOne}
                        <input
                            type="number"
                            className="form-control check-range-apply-scope-input"
                            value={state.condition.applyScopeStart}
                            onChange={actions.changeCondition.params('applyScopeStart')}
                            onBlur={actions.validateRangeValue.params(
                                state.condition,
                                'applyScopeStart',
                                state.condition.applyScopeStart
                            )}
                        />
                        {locales.components.checkRange.numberTipThree}
                        <input
                            type="number"
                            className="form-control check-range-apply-scope-input"
                            value={state.condition.applyScopeEnd}
                            onChange={actions.changeCondition.params('applyScopeEnd')}
                            onBlur={actions.validateRangeValue.params(
                                state.condition,
                                'applyScopeEnd',
                                state.condition.applyScopeEnd
                            )}
                        />
                        {locales.components.checkRange.numberTipTwo}
                    </div>
                </div>
            </div>
        );
    }
}
