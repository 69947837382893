// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetRiskConfigsQuery,
    BaseResponseListRiskConfigFoundResponse,
    PostRiskConfigsBody,
    BaseResponseRiskConfigFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags risk-config-controller
 * @link [GET] /riskConfigs
 */
export function getRiskConfigs(): Promise<BaseResponseListRiskConfigFoundResponse>;
export function getRiskConfigs(
    options: ApiOptions<never, GetRiskConfigsQuery>
): Promise<BaseResponseListRiskConfigFoundResponse>;
export function getRiskConfigs(
    options?: ApiOptions<never, GetRiskConfigsQuery>
): Promise<BaseResponseListRiskConfigFoundResponse> {
    return request<never, never, GetRiskConfigsQuery>('/riskConfigs', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getRiskConfigs',
    });
}

/**
 * save
 *
 * @export
 * @tags risk-config-controller
 * @link [POST] /riskConfigs
 */
export function postRiskConfigs(
    data: PostRiskConfigsBody
): Promise<BaseResponseRiskConfigFoundResponse>;
export function postRiskConfigs(
    data: PostRiskConfigsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseRiskConfigFoundResponse>;
export function postRiskConfigs(
    data: PostRiskConfigsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseRiskConfigFoundResponse> {
    return request<PostRiskConfigsBody, never, never>('/riskConfigs', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postRiskConfigs',
    });
}

export const meta = [
    { tags: ['risk-config-controller'], path: '/riskConfigs', method: 'get' },
    { tags: ['risk-config-controller'], path: '/riskConfigs', method: 'post' },
];
