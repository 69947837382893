export default {
  highlightText: highlightText,
  clearHighlight: clearHighlight
};
var HIGHLIGHT_ATTRIBUTE_NAME = '_udeskGmUiHighlight';
function highlightText(elem, keyword, options) {
  if (!keyword) return;
  var textNodes = getTextNodesIn(elem, function (n) {
    var _n$nodeValue, _n$nodeValue2;
    var keywordRegExp = new RegExp(keyword);
    return !/^\n\s*$/.test((_n$nodeValue = n.nodeValue) !== null && _n$nodeValue !== void 0 ? _n$nodeValue : '') && keywordRegExp.test((_n$nodeValue2 = n.nodeValue) !== null && _n$nodeValue2 !== void 0 ? _n$nodeValue2 : '');
  });
  var splitRegExp = new RegExp("(".concat(keyword, ")"));
  textNodes.forEach(function (node) {
    var _node$nodeValue;
    var txtArr = ((_node$nodeValue = node.nodeValue) !== null && _node$nodeValue !== void 0 ? _node$nodeValue : '').split(splitRegExp).filter(function (txt) {
      return txt !== '';
    });
    txtArr.forEach(function (txt) {
      var newNode;
      if (txt !== keyword) {
        newNode = document.createTextNode(txt);
      } else {
        var tagName = options && options.tagName || 'em';
        newNode = document.createElement(tagName);
        newNode.setAttribute(HIGHLIGHT_ATTRIBUTE_NAME, '');
        if (options && options.className) {
          newNode.className = options.className;
        }
        newNode.appendChild(document.createTextNode(txt));
      }
      node.parentNode && node.parentNode.insertBefore(newNode, node);
    });
    node.parentNode && node.parentNode.removeChild(node);
  });
}
function clearHighlight(elem) {
  var emElems = getElementsByAttributeName(elem, HIGHLIGHT_ATTRIBUTE_NAME);
  for (var i = emElems.length - 1; i >= 0; i--) {
    var emNode = emElems[i];
    var parentNode = emNode.parentNode;
    if (parentNode && emNode.textContent) {
      var newNode = document.createTextNode(emNode.textContent);
      parentNode.insertBefore(newNode, emNode);
      parentNode.removeChild(emNode);
      parentNode.normalize();
    }
  }
}
function getTextNodesIn(elem, opt_fnFilter) {
  var textNodes = [];
  if (elem) {
    for (var nodes = elem.childNodes, i = nodes.length; i--;) {
      var node = nodes[i],
        nodeType = node.nodeType;
      if (nodeType === 3) {
        if (!opt_fnFilter || opt_fnFilter(node, elem)) {
          textNodes.push(node);
        }
      } else if (nodeType === 1 || nodeType === 9 || nodeType === 11) {
        textNodes = textNodes.concat(getTextNodesIn(node, opt_fnFilter));
      }
    }
  }
  return textNodes;
}
function getElementsByAttributeName(elem, attributeName) {
  var matchNodes = [];
  if (elem) {
    var nodes = elem.children;
    for (var i = 0; i < nodes.length; i++) {
      var node = nodes[i];
      if (node.hasAttribute(attributeName)) {
        matchNodes.push(node);
      } else {
        matchNodes = matchNodes.concat(getElementsByAttributeName(node, attributeName));
      }
    }
  }
  return matchNodes;
}