import React, { useMemo } from 'react';
// import './style.scss';
// import styled from 'styled-components';
import UdeskList from 'Component/common/udesk-list';
import RichText from 'Component/pages/components/rich-text-component';
import { Card, Input } from 'udesk-ui';
import Locales from 'UdeskLocales';

const RichTextListWrap: React.FC<any> = (props) => {
    const { isView, children, ...listProps } = props;
    return isView ? (
        <div>
            {React.Children.map(children, (child) => (
                <div style={{ marginBottom: 8 }}>{child}</div>
            ))}
        </div>
    ) : (
        <UdeskList {...listProps}>{children}</UdeskList>
    );
};

const RichTextList = (props) => {
    const locales = Locales['current'];

    const { value: valueProps = [{ title: /* 流程1 */locales.pages.gong.konwledgeBase.detail.components.richTextList.process, content: '' }], onChange, type, isView } = props;
    const value = useMemo(() => {
        return valueProps.map((i) => {
            let res = { ...i };
            if (!i._uid) {
                i._uid = Math.random();
            }
            return res;
        });
    }, [valueProps]);
    const onItemRichTextChange = (index, newValue) => {
        let res = [...value];
        res[index].content = newValue;
        onChange(res);
    };

    if (type === 'knowledge') {
        return <RichText isView={isView} value={valueProps[0].content} onChange={onItemRichTextChange.bind(null, 0)} />;
    }

    const addItem = () => {
        let res = [...value, { title: `${/* 流程 */locales.pages.gong.konwledgeBase.detail.components.richTextList.technologicalProcess}${value.length + 1}` }];
        onChange(res);
    };
    const deleteItem = (index) => {
        let res = value.filter((_, i) => i !== index);

        onChange(res);
    };
    return (
        <RichTextListWrap deleteItem={deleteItem} addItem={addItem} addBtnText={/* 新建流程 */locales.pages.gong.konwledgeBase.detail.components.richTextList.newProcess} isView={isView}>
            {value.map((item, index) => {
                return (
                    <div key={item._uid}>
                        <Card
                            size='small'
                            title={
                                isView ? (
                                    <div>{item.title}</div>
                                ) : (
                                    <Input
                                        value={item.title}
                                        onChange={(e) => {
                                            let res = [...value];
                                            res[index].title = e.target.value;
                                            onChange(res);
                                        }}
                                        // style={{ marginBottom: 8 }}
                                        key={item._uid}
                                    />
                                )
                            }
                        >
                            <RichText
                                key={item._uid}
                                value={item.content}
                                onChange={onItemRichTextChange.bind(null, index)}
                                isView={isView}
                            />
                        </Card>
                    </div>
                );
            })}
        </RichTextListWrap>
    );
};

export default RichTextList;
