import React from 'react';
import { TableOutlined, LineChartFilled, BarChartOutlined, PieChartOutlined } from '@udesk/icons';
import UdeskLocales from 'UdeskLocales';

export const ChartType = {
    Table: 'table',
    Line: 'line',
    Bar: 'bar',
    Pie: 'pie',
};

type UseChartProps = {
    chartDisable: boolean;
    pieChartDisabled: boolean;
};

export const useChart = (props: UseChartProps) => {
    const { chartDisable, pieChartDisabled } = props;

    const chartTypeList = [
        {
            type: ChartType.Table,
            name: /* 表格 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.header.hooks.form,
            icon: <TableOutlined />,
        },
        {
            type: ChartType.Line,
            name: /* 折线图 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.header.hooks.lineChart,
            icon: <LineChartFilled />,
            disabled: chartDisable,
        },
        {
            type: ChartType.Bar,
            name: /* 柱状图 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.header.hooks.barChart,
            icon: <BarChartOutlined />,
            disabled: chartDisable,
        },
        {
            type: ChartType.Pie,
            name: /* 饼图 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.header.hooks.pieChart,
            icon: <PieChartOutlined />,
            disabled: chartDisable || pieChartDisabled,
        },
    ];

    return {
        chartTypeList,
    };
};
