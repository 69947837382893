import React from 'react';
import Udesk from 'Udesk';
import moment from 'moment';
import _debounce from 'lodash-es/debounce';
import _map from 'lodash-es/map';
const CUSTOMER_LIST_PAGE_SIZE = 10;
const FIRST_PAGER_NUMBER = 1;

let timer = null;
let querryTimer = null;
export default class CustomerPortraitComponent extends React.Component {
    CUSTOMER_LIST_PAGE_SIZE = CUSTOMER_LIST_PAGE_SIZE;
    FIRST_PAGER_NUMBER = FIRST_PAGER_NUMBER;
    state = {
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: CUSTOMER_LIST_PAGE_SIZE,
        total: 0,
    };
    privates = {
        keyword: '',
        customerPortrait: [],
        getKeyWordsStatisticsUrl: 'personaDatas/keyword',
        getKeyBusinessTagsUrl: 'personaDatas/smartTag',
        keyWordsData: [],
        businessTagsData: [],
        keywordWordCloudUrl: '',
        reportEchartShow: false,
        defaultShowUserId: '',
        currentCostomer: '',
        statAt: [
            moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
            moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
        ],
        keyWordsEacharts: {},
        findAllSamrtTagsItems: [],
        tagList: [],
        timeLineData: [],
        personaData: [],
        operator: 'please',
        wordCloudRef: React.createRef(),
    };
    static computes = {
        lastPagerNumbers: ({ privates, props, state }) => {
            let totalCustomer = Number(state.total);
            let lastPagerNumber;
            if (!!totalCustomer && totalCustomer > 0) {
                lastPagerNumber = Math.ceil(totalCustomer / CUSTOMER_LIST_PAGE_SIZE);
            } else {
                lastPagerNumber = FIRST_PAGER_NUMBER;
            }
            return lastPagerNumber;
        },
    };
    actions = {
        pageChanged(pageNum, pageSize) {
            // this.setState({ pageNum, pageSize});
            let { keyword, tagList } = this.privates;
            let tagIdList = tagList.map((item) => item.key) || [];
            this.actions.reloadAsyncModel({ pageNum, pageSize, tagIdList, keyword });
        },
        operatorChanged(value) {
            this.privates.operator = value;
            if (value === 'please') {
                this.privates.tagList = undefined;
            }
            this.actions.update();
        },
        onSearchCustomer(value) {
            this.privates.defaultShowUserId = '';
            let { tagList } = this.privates;
            let tagIdList = tagList.map((item) => item.key) || [];
            this.privates.keyword = value;
            this.actions.reloadAsyncModel({
                keyword: value,
                pageNum: Udesk.config.paging.defaultPageNumber,
                pageSize: CUSTOMER_LIST_PAGE_SIZE,
                tagIdList,
            });
        },
        searchChange(e) {
            this.privates.keyword = e.target.value;
            this.actions.update();
        },
        onSearchSubmit() {
            if(timer){
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                this.privates.defaultShowUserId = '';
                let { keyword, tagList, operator } = this.privates;
                let operators = null;
                if (operator !== 'please') {
                    operators = operator;
                }
                let tagIdList = tagList.map((item) => item.key) || [];
                this.actions.reloadAsyncModel({
                    tagIdList,
                    operator: operators,
                    keyword,
                    pageNum: Udesk.config.paging.defaultPageNumber,
                    pageSize: CUSTOMER_LIST_PAGE_SIZE,
                });
                timer = null;
            }, 800);
        },
        reset() {
            this.privates.statAt = [
                moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
                moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
            ];
            this.privates.tagList = [];
            this.privates.keyword = undefined;
            this.actions.update();
        },
        disabledDate(current) {
            // let [statAt] = this.privates;
            // if (!statAt || statAt.length === 0) {
            //     return false;
            // }
            // const tooLate = statAt[0] && current.diff(statAt[0], 'month') > 0;
            // const tooEarly = statAt[1] && statAt[1].diff(current, 'month') > 0;
            // return tooEarly || tooLate;
            // return current && current < moment().endOf('month');
        },
        getStatisticsData(id, customerId) {
            let that = this;
            let userId = id;
            let { sdkOptions } = this.props;
            let { statAt } = this.privates;
            this.privates.currentCostomer = userId;
            this.actions.update();
            let data = {
                id: userId,
                customerId,
            };
            if (statAt) {
                Object.assign(data, {
                    beginTime: statAt[0],
                    endTime: statAt[1],
                });
            }
            if(querryTimer){
                clearTimeout(querryTimer);
            }
            querryTimer = setTimeout(() => {
                that.privates.keywordWordCloudUrl =
                    Udesk.business.apiPath.concatApiPath(that.privates.getKeyWordsStatisticsUrl, sdkOptions) + id;
                let keyWordsDataPromise = new Promise((resolve, reject) => {
                    // 关键词统计
                    Udesk.ajax
                        .post(
                            Udesk.business.apiPath.concatApiPath(that.privates.getKeyWordsStatisticsUrl, sdkOptions),
                            data
                        )
                        .then(
                            (resp) => {
                                resolve(resp.data);
                            },
                            (reason) => {
                                Udesk.ui.notify.error(
                                    reason.errorMsg ||
                                        that.locales.components.pages.customerPortrait.getKeyWordsStatisticsDataError
                                );
                                reject(reason);
                            }
                        );
                });
                let businessTagsDataPromise = new Promise((resolve, reject) => {
                    // 业务标签
                    Udesk.ajax
                        .post(Udesk.business.apiPath.concatApiPath(that.privates.getKeyBusinessTagsUrl, sdkOptions), data)
                        .then(
                            (resp) => {
                                resolve(resp.data);
                            },
                            (reason) => {
                                Udesk.ui.notify.error(
                                    reason.errorMsg ||
                                        that.locales.components.pages.customerPortrait.getBusinessTagsStatisticsDataError
                                );
                                reject(reason);
                            }
                        );
                });
                let personaDatasPromise = new Promise((resolve, reject) => {
                    if (customerId) {
                        Udesk.ajax
                            .post(Udesk.business.apiPath.concatApiPath('personaDatas', sdkOptions), {
                                customerId: customerId,
                            })
                            .then(
                                (resp) => {
                                    resolve(resp.data);
                                },
                                (reason) => {
                                    Udesk.ui.notify.error(
                                        reason.errorMsg ||
                                            that.locales.components.pages.customerPortrait
                                                .getBusinessTagsStatisticsDataError
                                    );
                                    reject(reason);
                                }
                            );
                    } else {
                        resolve(null);
                    }
                });
                let timeLinePromise = new Promise((resolve, reject) => {
                    Udesk.ajax.post(Udesk.business.apiPath.concatApiPath('personaDatas/time-line', sdkOptions), data).then(
                        (resp) => {
                            resolve(resp.data);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    that.locales.components.pages.customerPortrait.getBusinessTagsStatisticsDataError
                            );
                            reject(reason);
                        }
                    );
                });
                Promise.all([keyWordsDataPromise, businessTagsDataPromise, personaDatasPromise, timeLinePromise]).then(
                    (result) => {
                        let [keyWordsData, businessTagsData, personaData, timeLineData] = result;
                        if (keyWordsData && keyWordsData.rows) {
                            let dataRows = keyWordsData.rows;
                            that.privates.keyWordsData = dataRows || [];
                            that.privates.keyWordsEacharts = keyWordsData;
                        }
                        if (businessTagsData != null && businessTagsData !== '') {
                            that.privates.businessTagsData = businessTagsData || [];
                        }
                        if (personaData != null && personaData !== '') {
                            let data = JSON.parse(personaData);
                            this.privates.personaData = _map(data, (item, key) => {
                                return { key: `${key}:${item ? JSON.stringify(item) : item}` };
                            });
                        }else{
                            this.privates.personaData = [];
                        }
                        if (timeLineData !== null && timeLineData !== '') {
                            this.privates.timeLineData = timeLineData;
                        }
                        that.actions.update();
                    }
                );
                querryTimer = null;
            }, 500);
        },
        searchField(value) {
            let { sdkOptions } = this.props;
            let smartTagsUrl = Udesk.business.apiPath.concatApiPath(`smartTags/findAll`, sdkOptions);

            let findAllSamrtTagsData = {
                pageNum: 1,
                pageSize: 20,
            };
            if (value) {
                findAllSamrtTagsData.tagName = value;
            }
            new Promise((resolve, reject) => {
                Udesk.ajax.post(smartTagsUrl, findAllSamrtTagsData).then(
                    (resp) => {
                        this.privates.findAllSamrtTagsItems = resp.data;
                        resolve(resp.data);
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onChangeSelect(value) {
            this.privates.tagList = value;
            this.actions.update();
        },
        onSearchSelect: _debounce(
            (value) => {
                this.actions.searchField(value);
            },
            300,
            { trailing: true }
        ),
        onFocusSelect(e) {
            this.actions.searchField(e.target.value);
        },
        onSeriesClicked() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'sentimentAnalysisIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        timeChange(start) {
            if (start) {
                this.privates.statAt = [
                    moment(start[0]).startOf('month').format('YYYY-MM-DD 00:00:00'),
                    moment(start[1]).endOf('month').format('YYYY-MM-DD 23:59:59'),
                ];
            } else {
                this.privates.statAt = [
                    moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
                    moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
                ];
            }
            this.actions.update();
        },
        transToDetail(id, inspectDataSource){
            if(id){
                if(inspectDataSource === 1){
                    window.open(`/site/call-manage/view/${id}`);
                }
                if(inspectDataSource === 2){
                    window.open(`/site/dialogue-manage/view/${id}`);
                }
            }

        },
        addRightClickEvent(){
            let customerPortraitKeyWordsCloud = document.getElementById("customerPortraitKeyWordsCloud");
            customerPortraitKeyWordsCloud && (customerPortraitKeyWordsCloud.oncontextmenu = function() { return false; });
            if(this.privates.wordCloudRef.current){
                this.privates.wordCloudRef.current.privates.echartsAPI.on('contextmenu', (params) => {
                    this.props.SmartToolBarRef.current.actions.seleteTag('2');
                    this.props.SmartToolBarRef.current.actions.open(null, params.name, 'sentenceSearch');
                });
            }
        },
        seleteSemanticNameBySider(tag) {
            let operatorItem = this.privates.operatorItem;
            operatorItem.operatorList[0].keyword = tag.id;
            let standard = tag.tags.find(item => item.standardFlag === 1);
            if (standard) {
                operatorItem.operatorList[0].semanticId = standard.id;
            }
            operatorItem.operatorList[0].semanticTag = {
                groupId: tag.id,
                key: tag.id,
                semanticName: tag.title,
                sentence: standard ? standard.content : '',
            };
            this.actions.handleOperatorItemChange();
        },
    };
    componentDidMount(){
        if(this.props?.location?.state){
            const {clientId} = this.props.location.state;
            if(clientId){
                this.privates.keyword = clientId;
                this.actions.update();
            }
        }
        this.actions.addRightClickEvent();
    }
    componentDidUpdate(){
        this.actions.addRightClickEvent();
    }
}
