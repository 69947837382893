// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostIntelligentPartnerTaskFinishRecordsByIdShareParam,
    PostIntelligentPartnerTaskFinishRecordsByIdShareBody,
    BaseResponseIntelligentPartnerTaskFinishRecordShareFoundResponse,
} from '../../../types';

/**
 * 分享记录
 *
 * @export
 * @tags 智能陪练-历史学习数据
 * @link [POST] /intelligentPartnerTaskFinishRecords/{id}/share
 * @param id
 */
export function postIntelligentPartnerTaskFinishRecordsByIdShare(
    data: PostIntelligentPartnerTaskFinishRecordsByIdShareBody,
    options: ApiOptions<PostIntelligentPartnerTaskFinishRecordsByIdShareParam, never> & {
        segments: PostIntelligentPartnerTaskFinishRecordsByIdShareParam;
    }
): Promise<BaseResponseIntelligentPartnerTaskFinishRecordShareFoundResponse> {
    return request<
        PostIntelligentPartnerTaskFinishRecordsByIdShareBody,
        PostIntelligentPartnerTaskFinishRecordsByIdShareParam,
        never
    >('/intelligentPartnerTaskFinishRecords/{id}/share', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTaskFinishRecordsByIdShare',
    });
}

export const meta = [
    {
        tags: ['智能陪练-历史学习数据'],
        path: '/intelligentPartnerTaskFinishRecords/{id}/share',
        method: 'post',
    },
];
