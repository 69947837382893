import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { Tag, Space, Tooltip, Spin } from 'udesk-ui';
import { useWrongQuestionSetContext } from '../../context';
import { EllipsisSpan } from 'src/util/tag';
import { useRequest } from 'src/util/hook';
import { inspect } from 'src/util/core';

const Text = (props) => {
    const { before, after, children, color } = props;
    return (
        <Space>
            {!!before && <span>{before}</span>}
            <span
                style={{
                    color,
                }}
            >
                {children ?? '-'}
            </span>
            {!!after && <span>{after}</span>}
        </Space>
    );
};

const ScoreRuleListWrap = styled.div`
    height: 100%;
    table {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;
        table-layout: fixed;
        /* text-align: center; */
    }

    table td {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 8px;
        .udesk-qa-ui-tag {
            color: #1a6eff;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const ScoreRuleList = () => {
    const context = useWrongQuestionSetContext();
    const { mistakeRuleDetailList = [] } = context;

    const getData = useCallback((ruleId) => {
        return {
            ruleId,
            taskId: context.taskId,
            nodeId: context.nodeId,
        };
    }, [context]);

    return (
        <ScoreRuleListWrap>
            <table>
                <colgroup>
                    <col width="40%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                </colgroup>
                {mistakeRuleDetailList?.map((i) => (
                    <tr>
                        {React.Children.map(
                            [
                                <Tooltip trigger={'click'} color={'white'} placement="rightTop" title={<RuleView data={getData(i.ruleId)} />}>
                                    <Tag color="#E6F3FF" title={i.ruleName ?? '-'}>
                                        {i.ruleName ?? '-'}
                                    </Tag>
                                </Tooltip>,
                                <Text after={/* 分 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.branch} color="#1A6EFF">
                                    {i.ruleScore}
                                </Text>,
                                <Text before={/* 答错 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.answerIncorrectly} after={/* 次 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.second} color="#FB5127">
                                    {i.wrongTimes}
                                </Text>,
                                <Text before={/* 答错率 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.wrongAnswerRate} color="#FB5127">
                                    {`${i.wrongRatio ?? '-'}%`}
                                </Text>,
                            ],
                            (child: any, index: number) => {
                                return <td>{child}</td>;
                            }
                        )}
                    </tr>
                )) ?? null}
            </table>
        </ScoreRuleListWrap>
    );
};

const RuleView = React.memo<any>((props) => {
    const [isKnowledgeNode, setIsKnowledgeNode] = useState(false);
    const { loading, data } = useRequest({
        manual: false,
        defaultApi: '/intelligentPartnerMistakeCollections/ruleInfo',
        defaultParams: [props.data],
        onSuccess(resp) {
            const rule = resp.data?.ruleInfo?.rule;
            const isKnowledgeNode = resp.data?.nodeType === Udesk.enums.nodeTypes.knowledgeNode.id;
            if (isKnowledgeNode) {
                resp.data = resp.data?.ruleInfo?.answerSemanticTagGroup?.recallWords;
            }else if (rule) {
                resp.data = JSON.parse(rule);
            } else {
                resp.data = null;
            }
            setIsKnowledgeNode(isKnowledgeNode);
        },
    });

    return (
        <Spin spinning={loading}>
            <div style={{ color: '#555' }}>
                {
                    inspect(
                        data,
                        () => isKnowledgeNode ? <RecallWordsItem data={data} /> :<ScoringRuleItem data={data} />,
                        () => <div style={{
                            width: 170,
                            height: 44,
                            lineHeight: '44px',
                            textAlign: 'center',
                        }}>{loading ? '' : /* 未查询到相关信息 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.noRelevantInformationFound}</div>
                    )
                }
            </div>
        </Spin>
    );
});

export const RecallWordsItem = React.memo((props: any) => {
    const {data} = props;

    return (
        <Space direction={'vertical'}>
            <span>{/* 召回词 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.scoreRuleList.recallWords}</span>
            <Space direction={'vertical'} style={{maxHeight: 300, overflow: 'auto'}}>
                {
                    data?.map(item => <Tag color="blue">{item}</Tag>)
                }
            </Space>
        </Space>
    );
});

export const ScoringRuleItem = React.memo((props: any) => {
    const { data } = props;
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '6px 10px',
        }}>
            {
                inspect(data.conditionList?.length > 1, () => (
                    <div style={{ paddingRight: 8 }}>
                        {
                            getLabel([
                                {
                                    value: '&&',
                                    label: /* 且 */ UdeskLocales['current']
                                        .components.coach.scoringRules.components
                                        .scoringRuleItem.and,
                                },
                                {
                                    value: '||',
                                    label: /* 或 */ UdeskLocales['current']
                                        .components.coach.scoringRules.components
                                        .scoringRuleItem.or,
                                },
                            ], data.logicalOperator)
                        }
                    </div>
                ))
            }
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: inspect(data.conditionList?.length > 1, 8, 0)
            }}>
                {data.conditionList.map((item) => <ScoringRuleItemIfItem {...item} />)}
            </div>
        </div>
    );
});

function getLabel(list, value) {
    return list.find(item => item.value === value)?.label;
}

function ScoringRuleItemIfItem(props) {
    return (
        <Space style={{ padding: '5px 0' }}>
            <span>
                {
                    getLabel([
                        {
                            label: /* 命中 */ UdeskLocales['current'].components.coach.scoringRules
                                .components.scoringRuleItem.hit,
                            value: 'hit',
                        },
                        {
                            label: /* 未命中 */ UdeskLocales['current'].components.coach.scoringRules
                                .components.scoringRuleItem.misses,
                            value: 'not_hit',
                        },
                    ], props.operator)
                }
            </span>
            <span>
                {
                    getLabel([
                        {
                            label: /* 关键词 */ UdeskLocales['current'].components.coach.scoringRules
                                .components.scoringRuleItem.keyWord,
                            value: 1,
                        },
                        {
                            label: /* 语义 */ UdeskLocales['current'].components.coach.scoringRules
                                .components.scoringRuleItem.semantics,
                            value: 2,
                        },
                    ], props.conditionType)
                }
            </span>
            {props.conditionType === 1 && (
                <>
                    <span>
                        {
                            getLabel([
                                {
                                    label: /* 全部 */UdeskLocales['current'].components.coach.scoringRules.components.scoringRuleItem.whole,
                                    value: 'all',
                                },
                                {
                                    label: /* 任意 */UdeskLocales['current'].components.coach.scoringRules.components.scoringRuleItem.arbitrarily,
                                    value: 'any',
                                },
                            ], props.matchOperator)
                        }
                    </span>
                    <EllipsisSpan width={'200px'} title={props.keyword} color={'#1a6eff'}>
                        {props.keyword}
                    </EllipsisSpan>
                </>
            )}
            {props.conditionType === 2 && (
                <EllipsisSpan width={'200px'} title={`${props.semanticName}：${props.sentence}`} color={'#1a6eff'}>
                    {`${props.semanticName}：${props.sentence}`}
                </EllipsisSpan>
            )}
        </Space>
    );
}
