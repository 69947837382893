import Udesk from 'Udesk';
import React from 'react';
import TaskRuleGatherComponent from './component';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import { Select } from 'udesk-ui';
import './style.scss';
import FilterModalEdit from '../../../../pages/tasks/manage/template/automatic-add/filter-modal';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';

export default class TaskRuleGatherTemplate extends TaskRuleGatherComponent {
    render() {
        let { actions, locales, props, state, privates } = this;
        return (
            <div className='task-rule-gather-component'>
                <For each='rule' index='index' of={privates.rules}>
                    <div key={rule._id} className='rule-item'>
                        <span className='rule-item-title'>
                            {locales.components.ruleGather.title}
                            {index + 1}
                            {locales.labels.colon}
                        </span>
                        <div className='rule-item-rule-control'>
                            <div style={{display: 'flex', alignItems: 'flex-start'}}>
                                <Select
                                    disabled={props.disabled}
                                    value={rule.assignConf.assignType}
                                    className='rule-item-inspector-type'
                                    onChange={actions.onValueChanged.params(rule.assignConf, 'assignType')}
                                >
                                    <For each='item' index='index' of={Udesk.enums.inspectorTypes}>
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    </For>
                                </Select>
                                <If condition={rule.assignConf.assignType === Udesk.enums.inspectorTypes.some.id}>
                                    <div className='rule-item-inspector-select'>
                                        <AutoComplete
                                            disabled={props.disabled}
                                            url={privates.autoCompleteUrl}
                                            isMulti={true}
                                            value={rule.assignConf.inspectorList}
                                            method={'GET'}
                                            queryParamName={'keyword'}
                                            nameField='realname'
                                            onChanged={actions.inspectorListChanged.params(rule)}
                                            enableEmptySearch={true}
                                        />
                                    </div>
                                </If>
                            </div>
                            {rule.baseFilter.conditionList && rule.baseFilter.conditionList.length ? (
                                <FilterModalEdit
                                    conditionJson={privates.conditionJson[index]}
                                    judgeStrategy={rule.baseFilter.judgeStrategy}
                                    customJudgeLogic={rule.baseFilter.customJudgeLogic}
                                    conditionList={rule.baseFilter.conditionList}
                                    conditionJsonInfo={privates.conditionJsonInfo[index]}
                                    editFilterOhter={actions.editFilterOhter.params(rule)}
                                    fieldsFilterChange={actions.fieldsFilterChange}
                                    disabled={props.disabled}
                                />
                            ) : (
                                <div
                                    className='rule-item-rule-filter-btn'
                                    onClick={actions.changeBaseFilterVisible.params(rule)}
                                >
                                    <i
                                        className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1'
                                        style={{ marginRight: 4 }}
                                    ></i>
                                    <span>{locales.components.ruleGather.screeningCondition}</span>
                                </div>
                            )}
                        </div>
                        <If condition={!props.disabled}>
                            <span
                                className={
                                    'rule-item-action rule-item-action-move-up' + (index === 0 ? ' disabled' : '')
                                }
                                onClick={actions.moveUp.params(rule, index)}
                            >
                                {locales.components.ruleGather.moveUp}
                            </span>
                            <span
                                className={
                                    'rule-item-action rule-item-action-move-down' +
                                    (index + 1 === props.rules.length ? ' disabled' : '')
                                }
                                onClick={actions.moveDown.params(rule, index)}
                            >
                                {locales.components.ruleGather.moveDown}
                            </span>
                            <span
                                className={
                                    'rule-item-action rule-item-action-move-top ' + (index === 0 ? ' disabled' : '')
                                }
                                onClick={actions.moveTop.params(rule, index)}
                            >
                                {locales.components.ruleGather.top}
                            </span>
                            <span className='rule-item-delete' onClick={actions.deleteRule.params(index)}>
                                <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-delete_minus'></i>
                                {locales.components.conditionGather.delete}
                            </span>
                        </If>
                    </div>
                </For>
                <div className='rult-gather-add-icon-container'>
                    <If condition={!props.disabled}>
                        <span className='rule-gather-add-icon' onClick={actions.addRule}>
                            <i
                                className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1'
                                style={{ marginRight: 4 }}
                            ></i>
                            <span>{locales.components.ruleGather.addRule}</span>
                        </span>
                    </If>
                    <span style={{ color: 'rgba(0,0,0,0.45)' }}>{locales.components.ruleGather.ruleTip}</span>
                </div>
                <CustomFilterModal
                    visible={state.baseFilterVisible}
                    cancel={actions.handleConditionCancel}
                    applyAction={actions.handleConditionApply}
                    //条件规则类型
                    judgeStrategy={state.currentRule.baseFilter.judgeStrategy}
                    //自定义规则
                    customJudgeLogic={state.currentRule.baseFilter.customJudgeLogic}
                    //添加的条件
                    conditionList={state.currentRule.baseFilter.conditionList}
                    customName={privates.customName}
                    customId={privates.customId}
                    type={props.type}
                    taskId={props.taskId}
                    taskName={props.taskName}
                    filterList={['f_8', 'f_4', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
                />
                {/* <ReactModal
                    headerClassName="udesk-qa-modal-default-header"
                    title={locales.pages.tasks.manage.template.automaticAdd.index.screeningCondition}
                    visible={state.baseFilterVisible}
                    onCancel={actions.cancelConditionListChanged}
                    footer={false}
                >
                    <div className="bs-modal-base">
                        <div className="content-box">
                            <div className="con-body form-horizontal form-label-left">
                                <CustomFilters
                                    showConditionNumber={true}
                                    allowEmptyConditions={true}
                                    conditions={state.conditionList}
                                    fields={privates.computes._fields}
                                    fieldValueSettings={privates.computes._fieldValueSettings}
                                    theme="bootstrap"
                                    onChanged={actions.conditionListChanged}
                                    fieldSelectorClass="form-control input-sm"
                                    operatorSelectorClass="form-control input-sm"
                                    languageCode={props.sdkOptions.props.language}
                                    fieldNameField="key"
                                    fieldKeyField="key"
                                    fieldTextField="label"
                                    disabled={props.disabled}
                                />
                                <Radio.Group
                                    value={state.judgeStrategy}
                                    disabled={props.disabled}
                                    onChange={actions.onStateChanged.params('judgeStrategy')}>
                                    <For each="item" index="index" of={Udesk.enums.judgeStrategy}>
                                        <Radio key={item.id} value={item.id}>{item.name}</Radio>
                                    </For>
                                </Radio.Group>
                                <If condition={state.judgeStrategy === Udesk.enums.judgeStrategy.custom.id}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ margin: '10px 0px' }}
                                        maxLength="255"
                                        value={state.customJudgeLogic}
                                        disabled={props.disabled}
                                        onChange={actions.onStateChanged.params('customJudgeLogic')}
                                    />
                                </If>
                                <div className="form-group action" style={{textAlign: 'end'}}>
                                    <Space>
                                        <Button
                                            onClick={actions.cancelConditionListChanged}>
                                            {locales.labels.cancel}
                                        </Button>
                                        <If condition={!props.disabled}>
                                            <Button
                                                type='primary'
                                                onClick={actions.confirmConditionListChanged}>
                                                {locales.labels.ok}
                                            </Button>
                                        </If>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactModal> */}
            </div>
        );
    }
}
