import React from 'react';
import Udesk from 'Udesk';
import WaveSurfer from 'wavesurfer.js';
import PropTypes from 'prop-types';
import { formatDuration } from 'src/util/date';

class WaveAudioComponent extends React.Component {
    //#region Default Props
    static propTypes = {
        src: PropTypes.string,
        options: PropTypes.object,
        rates: PropTypes.array,
        isShowEmotionButton: PropTypes.any,
    };
    static defaultProps = {
        src: 'https://wavesurfer-js.org/example/split-channels/stereo.mp3',
        options: {},
        rates: ['0.5', '1.0', '1.2', '1.5', '2.0'],
        isShowEmotionButton: false,
    };
    //#endregion

    state = {
        isShowPlayButton: true,
        duration: '00:00:00',
        currentTime: '00:00:00',
        playbackRate: '1.0',
        isShowRateOptions: false,
        volume: 0.5,
        isShowVolumeRange: false,
    };
    privates = {
        waveAudioRef: React.createRef(),
        defaultOptions: {
            waveColor: '#00b38b',
            progressColor: '#00B3C6',
            barWidth: 2,
            backend: 'MediaElement',
        },
        limitTimeOfWave: 30, // 单位：分钟
        noWave: false,
        noWaveCursorPosition: 0,
    };

    actions = {
        play() {
            this.wavesurfer.play();
            this.setState({
                isShowPlayButton: false,
            });
        },
        pause() {
            this.wavesurfer.pause();
            this.setState({
                isShowPlayButton: true,
            });
        },
        showRateOptions() {
            let { isShowRateOptions } = this.state;
            this.setState({
                isShowRateOptions: !isShowRateOptions,
            });
        },
        changePlaybackRate(rate) {
            this.setState({
                playbackRate: rate,
                isShowRateOptions: false,
            });
            this.wavesurfer.setPlaybackRate(rate);
        },
        changeVolume(e) {
            let newVolume = e.target.value;
            this.setState({
                volume: newVolume,
            });
            this.wavesurfer.setVolume(newVolume);
        },
        showVolumeRange() {
            let { isShowVolumeRange } = this.state;
            this.setState({
                isShowVolumeRange: !isShowVolumeRange,
            });
        },
        playSpecificTimePeriod(startTime, endTime) {
            if (startTime != null) {
                this.wavesurfer.play(startTime, endTime);
                this.setState({
                    isShowPlayButton: false,
                });
                this.wavesurfer.on('pause', () => {
                    this.setState({
                        isShowPlayButton: true,
                    });
                });
            }
        },
        showEmotion() {
            this.trigger('onClickEmotionAnalysis');
        },
        downloadVoice() {
            let src = this.props.src;
            if (src) {
                Udesk.utils.web.openWindow(src, '_blank');
            }
        },
    };

    //#region Life Cycle
    componentDidMount() {
        initWaveAudioContent(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.src !== this.props.src) {
            this.privates.noWave = false;
            this.privates.noWaveCursorPosition = 0;
            this.actions.update();
            this.setState({
                isShowPlayButton: true,
                duration: '00:00:00',
                currentTime: '00:00:00',
                playbackRate: '1.0',
                isShowRateOptions: false,
                volume: 0.5,
                isShowVolumeRange: false,
            });
            initWaveAudioContent(this);
        }
    }
    componentWillUnmount() {
        if (this.wavesurfer) {
            // this.wavesurfer.pause();
            this.wavesurfer.destroy();
        }
    }
    //#endregion
}

function normalizeAudioSrc(src) {
    if (src == null) {
        return src;
    }
    const protocolRegExp = /^http:\/\/(.+?)$/i;
    if (window.location.protocol === 'https:' && protocolRegExp.test(src)) {
        src = src.replace(protocolRegExp, 'https://$1');
    }
    let domainRegExp = /^(http[s]?):\/\/ccrecords\.alioss\.udesk\.cn(.+?)$/i;
    if (domainRegExp.test(src)) {
        src = src.replace(domainRegExp, '$1://pro-ccrecords.oss-cn-hangzhou.aliyuncs.com$2');
    }
    return src;
}

function initWaveAudioContent(that) {
    let { src, options } = that.props;
    let { duration, currentTime } = that.state;
    let { defaultOptions } = that.privates;
    src = normalizeAudioSrc(src);
    that.$el = that.privates.waveAudioRef.current;
    that.$waveAudioContainer = that.$el.querySelector('.wave-audio-container');
    // if (that.$waveAudioContainer.childElementCount > 0) {
    //     that.$waveAudioContainer.removeChild(that.$waveAudioContainer.children[0]);
    // }
    let wavesurferOptions = Object.assign(
        { container: that.$waveAudioContainer },
        defaultOptions,
        options
    );
    if (that.wavesurfer) that.wavesurfer.destroy();
    that.wavesurfer = WaveSurfer.create(wavesurferOptions);
    that.wavesurfer.load(src);
    that.wavesurfer.on('ready', () => {
        duration = formatDuration(that.wavesurfer.getDuration());
        // 超过30分钟录音暂时不显示波形，因为配置较低机器可能出现浏览器崩溃问题
        if (that.wavesurfer.getDuration() > that.privates.limitTimeOfWave * 60) {
            that.privates.noWave = true;
            that.wavesurfer.empty();
            that.wavesurfer.setCursorColor('#fff');
            that.privates.noWaveCursorPosition = 0;
        } else {
            that.privates.noWave = false;
        }
        that.actions.update();
        that.setState({
            duration,
        });
    });
    that.wavesurfer.on('seek', () => {
        currentTime = formatDuration(that.wavesurfer.getCurrentTime());
        that.setState({
            currentTime,
        });
    });
    that.wavesurfer.on('audioprocess', () => {
        let current = that.wavesurfer.getCurrentTime();
        currentTime = formatDuration(current);
        if (that.privates.noWave) {
            let duration = that.wavesurfer.getDuration();
            that.privates.noWaveCursorPosition = (current / duration) * 100;
            that.actions.update();
        }
        that.setState({
            currentTime,
        });
    });
    that.wavesurfer.on('finish', () => {
        that.setState({
            isShowPlayButton: true,
        });
    });
}

export default WaveAudioComponent;
