import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { Button, Space, message } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import { getInspectionPointSystemDialogs } from 'src/api/inspectionPointSystemDialogs';
import { deleteInspectionPointSystemDialogsById } from 'src/api/inspectionPointSystemDialogs/{id}';
import { InspectionPointSystemDialogFoundResponse } from 'src/api/types';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import './style.scss';

const Template = React.memo((props: any) => {
    const { current: locales } = useRef(UdeskLocales['current']);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<InspectionPointSystemDialogFoundResponse[]>([]);
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });
    const ref = useRef<any>({});

    const queryList = useCallback(
        ({ pageSize, pageNum }: { pageSize: number; pageNum: number }) => {
            setLoading(true);
            getInspectionPointSystemDialogs({
                params: {
                    pageNum,
                    pageSize,
                },
            }).then((resp) => {
                setDataSource(resp.data || []);
                setLoading(false);
                setPaging(resp.paging as any);
            });
        },
        []
    );

    useEffect(() => {
        queryList({ pageSize: 20, pageNum: 1 });
    }, [queryList]);

    const paginationProps = useMemo(() => {
        const { pageSize, total } = paging;
        return {
            current: paging.pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                const newPages = {
                    ...paging,
                    pageNum: page,
                    pageSize,
                };
                queryList({ ...newPages });
                setPaging(newPages);
            },
        };
    }, [paging, queryList]);

    const columns = useMemo(
        () => [
            {
                title: /* 消息类型名称 */ UdeskLocales['current'].pages.semanticIntelligence
                    .applicationMessage.index.messageTypeName,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: /* 消息类型ID */ UdeskLocales['current'].pages.semanticIntelligence
                    .applicationMessage.index.messageTypeID,
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: /* 消息类型描述 */ UdeskLocales['current'].pages.semanticIntelligence
                    .applicationMessage.index.messageTypeDescription,
                dataIndex: 'messageDesc',
                key: 'messageDesc',
            },
            {
                dataIndex: 'actions',
                title: locales.labels.action,
                renderType: 'actions',
                onEdit: (item) => {
                    let routeOptions = {
                        history: props.history,
                        routeName: 'applicationMessageEdit',
                        pathParams: { id: item.id },
                        state: {},
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                },
                onDelete: (item) => {
                    deleteInspectionPointSystemDialogsById({
                        segments: {
                            id: item.id,
                        },
                    }).then((res) => {
                        message.success(locales.business.notifyMessage.deleteSuccess);
                        queryList({
                            pageNum: 1,
                            pageSize: paging.pageSize,
                        });
                    });
                },
            },
        ],
        [
            locales.business.notifyMessage.deleteSuccess,
            locales.labels.action,
            paging.pageSize,
            props.history,
            queryList,
        ]
    );

    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
        };
    }, [columns, dataSource]);

    const createBtn = (
        <Button
            onClick={() => {
                let routeOptions = {
                    history: props.history,
                    routeName: 'applicationMessageNew',
                    state: {},
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }}
        >
            {locales.labels.create}
        </Button>
    );

    return (
        <Page
            pageBodyClassName="knowledge-base-page-index"
            title={
                <Space>
                    <span>
                        {/* 应用消息类型 */}
                        {
                            UdeskLocales['current'].pages.semanticIntelligence.applicationMessage
                                .index.applicationMessageType
                        }
                    </span>
                    <span style={{ fontSize: 12, color: 'rgba(0,0,0,0.45)' }}>
                        {/* 适用于接入不同渠道数据进行质检，需依据渠道标识进行分类 */}
                        {
                            UdeskLocales['current'].pages.semanticIntelligence.applicationMessage
                                .index
                                .itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification
                        }
                    </span>
                </Space>
            }
            padding={true}
        >
            <CommonTablePage ref={ref} loading={loading} mainButton={createBtn}>
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
