// 统一合并为完整的 Locale
var locale = {
  lang: {
    confirm: {
      title: '您确认要删除吗？删除后将不能再恢复',
      okText: '删除',
      cancelText: '取消'
    }
  }
};
export default locale;