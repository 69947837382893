// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskIdParam,
    PostIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskIdBody,
    BaseResponseFinishRecordResultAnalysisEmotionScore,
} from '../../../../../types';

/**
 * 获取情绪分析
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/emotions/analysis/record/{taskId}
 * @param taskId
 */
export function postIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskId(
    data: PostIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskIdBody,
    options: ApiOptions<
        PostIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskIdParam,
        never
    > & { segments: PostIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskIdParam }
): Promise<BaseResponseFinishRecordResultAnalysisEmotionScore> {
    return request<
        PostIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskIdBody,
        PostIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskIdParam,
        never
    >('/intelligentPartnerTaskInfos/emotions/analysis/record/{taskId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTaskInfosEmotionsAnalysisRecordByTaskId',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/emotions/analysis/record/{taskId}',
        method: 'post',
    },
];
