// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type { GetCallStateQuery, BaseResponseListCallStateFoundResponse } from '../types';

/**
 * findAll
 *
 * @export
 * @tags call-state-controller
 * @link [GET] /callState
 */
export function getCallState(
    options: ApiOptions<never, GetCallStateQuery> & { params: GetCallStateQuery }
): Promise<BaseResponseListCallStateFoundResponse> {
    return request<never, never, GetCallStateQuery>('/callState', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCallState',
    });
}

export const meta = [{ tags: ['call-state-controller'], path: '/callState', method: 'get' }];
