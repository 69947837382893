// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetBusinessAnalysisConditionConfigsByIdParam,
    BaseResponseBusinessAnalysisConditionConfigFoundResponse,
    PutBusinessAnalysisConditionConfigsByIdParam,
    PutBusinessAnalysisConditionConfigsByIdBody,
    BaseResponseVoid,
    DeleteBusinessAnalysisConditionConfigsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags business-analysis-condition-config-controller
 * @link [GET] /businessAnalysisConditionConfigs/{id}
 * @param id
 */
export function getBusinessAnalysisConditionConfigsById(
    options: ApiOptions<GetBusinessAnalysisConditionConfigsByIdParam, never> & {
        segments: GetBusinessAnalysisConditionConfigsByIdParam;
    }
): Promise<BaseResponseBusinessAnalysisConditionConfigFoundResponse> {
    return request<never, GetBusinessAnalysisConditionConfigsByIdParam, never>(
        '/businessAnalysisConditionConfigs/{id}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getBusinessAnalysisConditionConfigsById',
        }
    );
}

/**
 * update
 *
 * @export
 * @tags business-analysis-condition-config-controller
 * @link [PUT] /businessAnalysisConditionConfigs/{id}
 * @param id
 */
export function putBusinessAnalysisConditionConfigsById(
    data: PutBusinessAnalysisConditionConfigsByIdBody,
    options: ApiOptions<PutBusinessAnalysisConditionConfigsByIdParam, never> & {
        segments: PutBusinessAnalysisConditionConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutBusinessAnalysisConditionConfigsByIdBody,
        PutBusinessAnalysisConditionConfigsByIdParam,
        never
    >('/businessAnalysisConditionConfigs/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putBusinessAnalysisConditionConfigsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags business-analysis-condition-config-controller
 * @link [DELETE] /businessAnalysisConditionConfigs/{id}
 * @param id
 */
export function deleteBusinessAnalysisConditionConfigsById(
    options: ApiOptions<DeleteBusinessAnalysisConditionConfigsByIdParam, never> & {
        segments: DeleteBusinessAnalysisConditionConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteBusinessAnalysisConditionConfigsByIdParam, never>(
        '/businessAnalysisConditionConfigs/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteBusinessAnalysisConditionConfigsById',
        }
    );
}

export const meta = [
    {
        tags: ['business-analysis-condition-config-controller'],
        path: '/businessAnalysisConditionConfigs/{id}',
        method: 'get',
    },
    {
        tags: ['business-analysis-condition-config-controller'],
        path: '/businessAnalysisConditionConfigs/{id}',
        method: 'put',
    },
    {
        tags: ['business-analysis-condition-config-controller'],
        path: '/businessAnalysisConditionConfigs/{id}',
        method: 'delete',
    },
];
