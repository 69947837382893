// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetTopicDetailsFindTopicByNameQuery,
    BaseResponseListTopicDetailFoundResponse,
} from '../../types';

/**
 * findTopicByName
 *
 * @export
 * @tags topic-detail-controller
 * @link [GET] /topicDetails/findTopicByName
 */
export function getTopicDetailsFindTopicByName(
    options: ApiOptions<never, GetTopicDetailsFindTopicByNameQuery> & {
        params: GetTopicDetailsFindTopicByNameQuery;
    }
): Promise<BaseResponseListTopicDetailFoundResponse> {
    return request<never, never, GetTopicDetailsFindTopicByNameQuery>(
        '/topicDetails/findTopicByName',
        { ...options, method: 'get', __$requestCalleeName: 'getTopicDetailsFindTopicByName' }
    );
}

export const meta = [
    { tags: ['topic-detail-controller'], path: '/topicDetails/findTopicByName', method: 'get' },
];
