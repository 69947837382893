import React/**, { useState } */ from 'react';
import { FormInstance as AFormInstance } from 'udesk-ui/lib/form';
import { Form as AForm } from 'udesk-ui';
import { FormInstance, CreateFromProps } from './types/form';
import {
    filterEffectFields,
    refershFieldsMap,
    getFieldsMapValue,
    getPrevFieldsValue,
    buildObjectFromNamePaht,
} from './utils/effect';

class ContextStore {
    private formInstance: AFormInstance;

    private formFields: CreateFromProps['fields'] = [];
    private visibleFieldsMap = new Map();
    private fieldMap = new Map();
    private disableFieldsMap = new Map();
    /**
     * prevFidldsValue
     * 记录上一次form存储的值，用于防止在值联动时重复setFieldsValue
     */
    private prevFidldsValue = {};

    constructor(formInstance: AFormInstance) {
        this.formInstance = formInstance;
    }

    public getForm = () => {
        return {
            ...this.formInstance,
            /**
             * 覆盖的方法
             */
            resetFields: this.resetFields,
            /**
             * 扩展的方法
             */
            getInternalHooks: this.getInternalHooks,
            computedEffecties: this.computedEffecties,
            registerField: this.registerField,
            getVisibleFieldsMap: this.getVisibleFieldsMap,
            getDisableFieldsMap: this.getDisableFieldsMap,
            getFieldVisible: this.getFieldVisible,
            getFieldDisable: this.getFieldDisable,
            setFields: this.setFields,
        };
    };

    // ================== 实例 API ==================
    private getInternalHooks = () => {
        return {
            /** useForm */
            getAFormInstance: this.getAFormInstance,
        };
    };
    //重算依赖规则
    private computedEffecties = (skipComputeValues) => {
        let effectFields = filterEffectFields(this.formFields);
        let visibleFieldsMap = new Map();
        let disableFieldsMap = new Map();
        // this.oldValueFieldsMap.clear();

        effectFields.forEach((field) => {
            //是否可见
            refershFieldsMap('visible', field, visibleFieldsMap, this.formInstance);
            //是否置灰
            refershFieldsMap('disable', field, disableFieldsMap, this.formInstance);
            //值联动
            let valueNameEntries = field?.effect?.value ? (Object.entries(field.effect.value) as any) : [];
            // 
            !skipComputeValues && valueNameEntries.forEach((value) => {
                //当前form中field的值
                let fieldValue = this.formInstance.getFieldValue(field.name);
                //所有被影响的field的List
                let effectedFields: any[] = value[1];
                //控制项触发effect的值
                let effectWantedValue = value[0];
                //对比当前form中field的值和上一次重算时field的值，防止重复触发造成用户无法修改数据
                if (getPrevFieldsValue(field.name, this.prevFidldsValue) !== fieldValue) {
                    effectedFields.forEach((effectedField) => {
                        if (effectWantedValue == fieldValue) {
                            //构建setFieldsValue所需的对象结构体
                            let setObj = buildObjectFromNamePaht(effectedField);
                            this.formInstance.setFieldsValue(setObj);
                        }
                    });
                }
            });
        });
        this.prevFidldsValue = this.formInstance.getFieldsValue();
        let oldVisibleFieldsMap = this.visibleFieldsMap;
        let oldDisableFieldsMap = this.disableFieldsMap;
        this.visibleFieldsMap = visibleFieldsMap;
        this.disableFieldsMap = disableFieldsMap;
        //重新渲染触发了显示隐藏/置灰联动的表单项
        this.triggerFieldsReRender(oldVisibleFieldsMap, oldDisableFieldsMap);
    };
    //初始化 获取表单配置
    private setFields = (fields) => {
        this.formFields = fields;
    };

    private getAFormInstance = (): AFormInstance => {
        return this.formInstance;
    };
    //对外提供有显示隐藏依赖的表单项映射
    private getVisibleFieldsMap = () => {
        return this.visibleFieldsMap;
    };
    //对外提供有置灰依赖的表单项映射
    private getDisableFieldsMap = () => {
        return this.disableFieldsMap;
    };

    //注册表单项实例
    private registerField = (name: string | string[], field: React.Component) => {
        this.fieldMap.set(name, field);
    };
    //重置表单
    private resetFields = () => {
        this.formInstance.resetFields();
        this.computedEffecties(true);
    };
    private getFieldVisible = (name) => {
        return getFieldsMapValue(name, this.visibleFieldsMap);
    };
    private getFieldDisable = (name) => {
        return getFieldsMapValue(name, this.disableFieldsMap);
    };
    private triggerFieldsReRender = (oldVisibleFieldsMap, oldDisableFieldsMap) => {
        //使用Set防止重复触发
        let dirtyFields = new Set();
        this.fieldMap.forEach((field: any, key) => {
            //过滤需要重新渲染的表单项
            if (getFieldsMapValue(key, oldVisibleFieldsMap) !== this.getFieldVisible(key)) {
                dirtyFields.add(field);
            }
            if (getFieldsMapValue(key, oldDisableFieldsMap) !== this.getFieldDisable(key)) {
                dirtyFields.add(field);
            }
        });
        //手动触发重新渲染
        dirtyFields.forEach((field: any) => {
            field.reRender();
        });
    };
}

export default function useFormContext(formInstance?: FormInstance): [FormInstance] {
    // const [a] = useState(()=>{
    //     if(formInstance){
    //         return formInstance;
    //     }
    //     else{
    //         const [aFormInstance] = AForm.useForm();
    //         const contextStore = new ContextStore(aFormInstance);
    //         return contextStore.getForm();
    //     }
    // });
    // return [a];

    const formRef = React.useRef(null);

    const [aFormInstance] = AForm.useForm(
        formInstance ? formInstance.getInternalHooks().getAFormInstance() : undefined
    );

    if (!formRef.current) {
        if (formInstance) {
            (formRef as any).current = formInstance;
        } else {
            const contextStore = new ContextStore(aFormInstance);
            (formRef as any).current = contextStore.getForm();
        }
    }

    return [formRef.current!];
}
