import React, { useState, useEffect, useMemo } from 'react';
import { DatePicker } from 'udesk-ui';
import moment from 'moment';
import Locales from 'UdeskLocales';
// import {DatePickerProps, RangePickerProps} from 'udesk-ui/lib/date-picker/index.d';

import './style.scss';

const { RangePicker } = DatePicker;
const FORMAT = 'YYYY-MM-DD';

interface propsValue {
    onChange?: (value: [string | null, string | null]) => void;
    value?: [string | null, string | null];
    allowClear?: boolean;
    defaultValue?: [string, string];
    style?: { [key: string]: any };
    disabledDate?: (currentDate: any) => boolean;
    format?: string;
    showTime?: Object | boolean;
    getPopupContainer?: (trigger: HTMLElement) => HTMLElement;
    disabled?: boolean;
    size?: 'small';
}
// interface LocalesType {
//     components: {
//         common: {
//             datePicker: {
//                 [proName: string]: string;
//             };
//         };
//     };
// }
const UdeskDatePicker = (props: propsValue) => {
    let locales = Locales['current'];

    const [startTime, setStartTime] = useState<string | null>(
        props.value ? props.value[0] : props.defaultValue ? props.defaultValue[0] : null
    );
    const [endTime, setEndTime] = useState<string | null>(
        props.value ? props.value[1] : props.defaultValue ? props.defaultValue[1] : null
    );
    //每次数据变化触发回调
    useEffect(() => {
        if (
            (Array.isArray(props.value) && (props.value[0] !== startTime || props.value[1] !== endTime)) ||
            !props.value
        ) {
            props?.onChange?.([startTime, endTime]);
        }
    }, [startTime, endTime]);
    useEffect(() => {
        if (Array.isArray(props.value)) {
            if (props.value[0] !== startTime || props.value[1] !== endTime) {
                setStartTime(props.value[0]);
                setEndTime(props.value[1]);
            }
        }
    }, [props.value]);
    //扩展onChange方法
    const onChangeExtra = (dates: any, dateStrings: string[]) => {
        if (dates) {
            setStartTime(dateStrings[0] + ' 00:00:00');
            setEndTime(dateStrings[1] + ' 23:59:59');
        } else {
            setStartTime(null);
            setEndTime(null);
        }
    };
    return (
        <RangePicker
            value={
                startTime && endTime
                    ? [
                        moment(startTime, props.format ? props.format : FORMAT),
                        moment(endTime, props.format ? props.format : FORMAT),
                    ]
                    : null
            }
            ranges={{
                [locales.components.common.datePicker.today]: [moment(), moment()],
                [locales.components.common.datePicker.yesterday]: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                ],
                [locales.components.common.datePicker.theDayBeforeYesterday]: [
                    moment().subtract(2, 'days'),
                    moment().subtract(2, 'days'),
                ],
                [locales.components.common.datePicker.thisWeek]: [moment().startOf('week'), moment().endOf('week')],
                [locales.components.common.datePicker.lastWeek]: [
                    moment()
                        .week(moment().week() - 1)
                        .startOf('week'),
                    moment()
                        .week(moment().week() - 1)
                        .endOf('week'),
                ],
                [locales.components.common.datePicker.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
                [locales.components.common.datePicker.lastMonth]: [
                    moment()
                        .month(moment().month() - 1)
                        .startOf('month'),
                    moment()
                        .month(moment().month() - 1)
                        .endOf('month'),
                ],
                [locales.components.common.datePicker.lastSevenDay]: [moment().subtract('days', 6), moment()],
                [locales.components.common.datePicker.lastThirtyDay]: [moment().subtract('days', 30), moment()],
                [locales.components.common.datePicker.thisQuarter]: [
                    moment().startOf('quarter'),
                    moment().endOf('quarter'),
                ],
                [locales.components.common.datePicker.lastQuarter]: [
                    moment()
                        .quarter(moment().quarter() - 1)
                        .startOf('quarter'),
                    moment()
                        .quarter(moment().quarter() - 1)
                        .endOf('quarter'),
                ],
                [locales.components.common.datePicker.thisYear]: [moment().startOf('year'), moment().endOf('year')],
                [locales.components.common.datePicker.lastYear]: [
                    moment()
                        .year(moment().year() - 1)
                        .startOf('year'),
                    moment()
                        .year(moment().year() - 1)
                        .endOf('year'),
                ],
                [locales.components.common.datePicker.lastYearFromToday]: [moment().subtract('year', 1), moment()],
            }}
            style={props.style || {}}
            format={props.format ? props.format : FORMAT}
            onChange={onChangeExtra}
            allowClear={typeof props.allowClear === 'boolean' ? props.allowClear : true}
            disabledDate={props.disabledDate}
            disabled={props.disabled}
            getPopupContainer={props.getPopupContainer}
            showTime={props.showTime}
            size={props.size}
        />
    );
};

export const useUdeskDatePicker = () => {
    const [startTime, setStartTime] = useState(
        moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00'
    );
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DD') + ' 23:59:59');

    const onChange = (dateStrings) => {
        setStartTime(dateStrings[0]);
        setEndTime(dateStrings[1]);
    };

    const value = useMemo<[string | null, string | null]>(() => {
        return [startTime, endTime];
    }, [startTime, endTime]);

    return {
        startTime,
        endTime,
        onChange,
        value,
    };
};

export default UdeskDatePicker;
