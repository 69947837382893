// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostOperatorMetaBody,
    BaseResponseListOperatorMetaWrapperResponse,
} from '../../types';

/**
 * getOperatorMeta
 *
 * @export
 * @tags 算子
 * @link [POST] /operator/meta
 */
export function postOperatorMeta(
    data: PostOperatorMetaBody
): Promise<BaseResponseListOperatorMetaWrapperResponse>;
export function postOperatorMeta(
    data: PostOperatorMetaBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListOperatorMetaWrapperResponse>;
export function postOperatorMeta(
    data: PostOperatorMetaBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListOperatorMetaWrapperResponse> {
    return request<PostOperatorMetaBody, never, never>('/operator/meta', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postOperatorMeta',
    });
}

export const meta = [{ tags: ['算子'], path: '/operator/meta', method: 'post' }];
