import React, { useState, useEffect } from 'react';
import UdeskList from 'Component/common/udesk-list';
import Locales from 'UdeskLocales';
import { Select, InputNumber, Space } from 'udesk-ui';
import Udesk from 'Udesk';

const { Option } = Select;

interface SegmentationStrategyItem {
    fieldType: number;
    operator: string;
    value: number;
}
interface SegmentationStrategyItemComponent {
    onChange: (index: number, newItem: SegmentationStrategyItem) => void;
    index: number;
    disabled: boolean;
}
interface SegmentationStrategyProps {
    value: SegmentationStrategyItem[];
    onChange: (value: SegmentationStrategyItem[]) => void;
}
const SegmentationStrategy: React.FC<SegmentationStrategyProps> = ({ value, onChange }) => {
    const locsles: any = Locales['current'];

    const [list, setList] = useState<SegmentationStrategyItem[]>(value || []);
    const addItem = () => {
        let newItem = {
            fieldType: list.some((i) => i.fieldType === Udesk.enums.segmentationStrategyType.count.id)
                ? Udesk.enums.segmentationStrategyType.spacing.id
                : Udesk.enums.segmentationStrategyType.count.id,
            operator: Udesk.enums.fieldOperators.greater_than_eq.id,
            value: 0,
        };
        setList([...list, newItem]);
    };
    const deleteItem = (index, childProps) => {
        setList(list.filter((item, itemIndex) => itemIndex !== index));
    };
    const onItemChange = (index, newItem) => {
        let newList = [...list];
        newList[index] = newItem;
        setList(newList);
    };
    useEffect(() => {
        onChange(list);
    }, [list]);
    return (
        <div>
            <UdeskList
                addBtnText={locsles.labels.addStrategy}
                addItem={addItem}
                deleteItem={deleteItem}
                addBtnDisabled={Array.isArray(list) && list.length === 2}
            >
                {list.map((item, index) => {
                    return <Item {...item} onChange={onItemChange} index={index} disabled={list.length !== 1} />;
                })}
            </UdeskList>
        </div>
    );
};

const COUNT_OPERATOR = ['greater_than_eq'];
const SPACING_OPERATOR = ['greater_than_eq'];

const Item: React.FC<SegmentationStrategyItem & SegmentationStrategyItemComponent> = (props) => {
    const locsles: any = Locales['current'];

    const [type, setType] = React.useState(props.fieldType || Udesk.enums.segmentationStrategyType.count.id);
    const [operator, setOperator] = React.useState(props.operator || Udesk.enums.fieldOperators[COUNT_OPERATOR[0]].id);
    const [operatorList, setOperatorList] = React.useState(COUNT_OPERATOR);
    const [inputValue, setInputValue] = React.useState(props.value || 0);

    const handleTypeChange = (value) => {
        setType(value);
        if (value === Udesk.enums.segmentationStrategyType.count.id) {
            setOperatorList(COUNT_OPERATOR);
            setOperator(Udesk.enums.fieldOperators[COUNT_OPERATOR[0]].id);
        } else if (value === Udesk.enums.segmentationStrategyType.spacing.id) {
            setOperatorList(SPACING_OPERATOR);
            setOperator(Udesk.enums.fieldOperators[SPACING_OPERATOR[0]].id);
        }
        setInputValue(0);
    };

    const onSecondCityChange = (value) => {
        setOperator(value);
    };
    useEffect(() => {
        let newItem: SegmentationStrategyItem = {
            fieldType: type,
            operator: operator,
            value: inputValue,
        };
        props.onChange(props.index, newItem);
    }, [type, operator, inputValue]);
    return (
        <Space>
            <Select defaultValue={type} style={{ width: 120 }} onChange={handleTypeChange} disabled={props.disabled}>
                {Udesk.enums.segmentationStrategyType.map((type) => (
                    <Option key={type.id} value={type.id}>
                        {type.name}
                    </Option>
                ))}
            </Select>
            <Select style={{ width: 120 }} value={operator} onChange={onSecondCityChange}>
                {Udesk.enums.fieldOperators
                    .filter((item) => operatorList.includes(item.id))
                    .map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.name}
                        </Option>
                    ))}
            </Select>
            <InputNumber
                value={inputValue}
                onChange={(value) => setInputValue(value as number)}
                formatter={(value) =>
                    `${value}${
                        type === Udesk.enums.segmentationStrategyType.count.id
                            ? locsles.labels.unit
                            : locsles.labels.minutes
                    }`
                }
                parser={(value: any) =>
                    value.replace(
                        `${
                            type === Udesk.enums.segmentationStrategyType.count.id
                                ? locsles.labels.unit
                                : locsles.labels.minutes
                        }`,
                        ''
                    )
                }
                min={0}
            />
            {/* <div style={{minWidth: 28}}>
                {type === Udesk.enums.segmentationStrategyType.count.id ? locsles.labels.unit : locsles.labels.minute}
            </div> */}
        </Space>
    );
};

export default SegmentationStrategy;
