// 统一合并为完整的 Locale
var locale = {
  lang: {
    num: '第',
    total: '条/总共',
    strip: '条',
    more: '更多',
    chosen: '已选择',
    cancel: '取消选择',
    bulkOperations: '批量操作',
    import: '导入'
  }
};
export default locale;