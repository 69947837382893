import React, { useLayoutEffect, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import CSSMotion from 'rc-animate/lib/CSSMotion';
import classNames from 'classnames';
import List, { ListRef } from 'rc-virtual-list';

interface Item {
    id: string | number;
    content: any;
}
interface MyItemProps {
    item: Item;
    index: number;
    itemRender: (props: any) => React.ReactNode;
    visible: boolean;
    motionAppear: boolean;
    onLeave: (id: string) => void;
    onAppear: (...args: any[]) => void;
}

const getCurrentHeight = (node: HTMLElement) => ({ height: node.offsetHeight });
const getMaxHeight = (node: HTMLElement) => {
    return { height: node.scrollHeight };
};
const getCollapsedHeight = () => ({ height: 0, opacity: 0 });

const MyItem: React.ForwardRefRenderFunction<any, MyItemProps> = (
    { item, visible, onLeave, onAppear, motionAppear, itemRender, ...args },
    ref
) => {
    const motionRef = useRef(false);
    useLayoutEffect(() => {
        return () => {
            if (motionRef.current) {
                onAppear();
            }
        };
    }, []);

    return (
        <CSSMotion
            visible={visible}
            ref={ref}
            motionName='motion'
            motionAppear={motionAppear}
            onAppearStart={getCollapsedHeight}
            onAppearActive={(node) => {
                motionRef.current = true;
                return getMaxHeight(node);
            }}
            onAppearEnd={onAppear}
            onLeaveStart={getCurrentHeight}
            onLeaveActive={getCollapsedHeight}
            onLeaveEnd={() => {
                onLeave(item.id as string);
            }}
        >
            {({ className, style }, passedMotionRef) => {
                return (
                    <div
                        ref={passedMotionRef}
                        className={classNames('item', className)}
                        style={style}
                        data-id={item.id}
                    >
                        {itemRender({ item, ...args })}
                    </div>
                );
            }}
        </CSSMotion>
    );
};

const ForwardMyItem = forwardRef(MyItem);
interface DataItem {
    id: string | number;
    [key: string]: any;
}
interface VirtualListProps {
    data: (DataItem & Item)[];
    itemRender: ({ item, index }) => React.ReactNode;
    height: number;
    dataDidUpdate?: (data, prevData, listRef) => void;
}
const VirtualList: React.ForwardRefExoticComponent<VirtualListProps & React.RefAttributes<any>> = React.forwardRef(
    (props, ref) => {
        
        const { data: dataProps, itemRender, height, dataDidUpdate, ...args } = props;
        const [animating, setAnimating] = useState(false);
        const [insertIndex /*setInsertIndex*/] = useState<number>();
        const [data, setData] = useState(dataProps);
        const [prevData, setPrevData] = useState<any[]>([]);

        const listRef = useRef<ListRef | null>(null);
        useImperativeHandle(ref, () => listRef.current);

        const onLeave = (id: string) => {
            const newData = data.filter((item) => item.id !== id);
            setData(newData);
        };

        const onAppear = (...args: any[]) => {
            setAnimating(false);
        };
        useEffect(() => {
            setData(dataProps);
            setPrevData(data);
            setAnimating(true);
        }, [dataProps]);
        useEffect(() => {
            typeof dataDidUpdate === 'function' && dataDidUpdate(data, prevData, listRef.current);
        }, [data]);
        return (
            <List<Item> data={data} data-id='list' height={height} itemHeight={100} itemKey='id' ref={listRef}>
                {(item, index) => {
                    return (
                        <ForwardMyItem
                            item={item}
                            index={index}
                            itemRender={itemRender}
                            motionAppear={animating && insertIndex === index}
                            visible={true}
                            onLeave={onLeave}
                            onAppear={onAppear}
                            {...args}
                        />
                    );
                }}
            </List>
        );
    }
);

export default VirtualList;
export type VirtualListRef = ListRef;
// export { clearAll, cache };
