import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import { getSystemModule } from 'Udesk/system/subApp';

class DataMigrationComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};

    //#endregion

    privates = {
        isShowUploadModal: false,
        data: [],
        sexportStandard: [],
        otherDatas: [],
        listCheckbox: [],
        exportStandard: 1,
        otherDatasList: {
            call: 0,
            session: 0,
            smartTag: 0
        }
    };

    static computes = {};

    actions = {
        showUploadModal() {
            this.privates.isShowUploadModal = true;
            // this.privates.uploadModalTitle = this.locales.components.pages.smartWordsLibrary.upload.uploadSynonymWords;
            this.actions.update();
        },

        closeUploadModal() {
            this.privates.isShowUploadModal = false;
            this.actions.update();
        },
        onTageChange(value, e) {
            if (e.target.checked) {
                if (this.privates.listCheckbox.indexOf(value) === -1) {
                    this.privates.listCheckbox.push(value);
                    this.actions.update();
                }
            } else {
                if (this.privates.listCheckbox.indexOf(value) !== -1) {
                    let list = [];
                    for (var i = 0; i < this.privates.listCheckbox.length; i++) {
                        if (this.privates.listCheckbox[i] !== value) {
                            list.push(this.privates.listCheckbox[i]);
                        }
                    }
                    this.privates.listCheckbox = list;
                    this.actions.update();
                }
            }
        },
        onSexportStandard(value) {
            this.privates.exportStandard = value;
            this.actions.update();
        },
        onOtherDtasChange(value, e) {
            if (e.target.checked) {
                this.privates.otherDatasList[value] = 1;
            } else {
                this.privates.otherDatasList[value] = 0;
            }
            this.actions.update();
        },
        export() {
            let { sdkOptions } = this.props;
            var data = {
                taskIds: this.privates.listCheckbox,
                exportStandard: this.privates.exportStandard,
                otherDatas: this.privates.otherDatasList,
                systemModule: getSystemModule(),
            };
            if (_isEmpty(this.privates.listCheckbox)) {
                Udesk.ui.notify.error(this.locales.components.pages.dataMigration.error);
            } else {
                let url = Udesk.business.apiPath.concatApiPath('inspectionTasks/export', sdkOptions);
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(url, data).then(
                        resp => {
                            resolve(resp.data);
                            Udesk.ui.notify.success(resp.message);
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        }
    };
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult } = parseOptions;
        if (asyncResult) {
            if (asyncResult) {
                this.privates.data = asyncResult;
            }
            this.actions.update();
        }
    }
    componentDidMount() {
        this.privates.sexportStandard = [
            {
                value: 1,
                name: this.locales.components.pages.dataMigration.list.ownInfo
            },
            {
                value: 2,
                name: this.locales.components.pages.dataMigration.list.everyInfo
            },
            {
                value: 3,
                name: this.locales.components.pages.dataMigration.list.allInfo
            }
        ];
        this.privates.otherDatas = [
            {
                value: 'call',
                name: this.locales.components.pages.dataMigration.list.callFilter
            },
            {
                value: 'session',
                name: this.locales.components.pages.dataMigration.list.talkFilter
            },
            {
                value: 'smartTag',
                name: this.locales.components.pages.dataMigration.list.smartTag
            }
        ];
    }
    //#endregion
}

export default DataMigrationComponent;
