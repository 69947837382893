// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetKnowledgeDatasByIdParam,
    BaseResponseKnowledgeDataFoundResponse,
    PutKnowledgeDatasByIdParam,
    PutKnowledgeDatasByIdBody,
    BaseResponseVoid,
    DeleteKnowledgeDatasByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags knowledge-data-controller
 * @link [GET] /knowledgeDatas/{id}
 * @param id
 */
export function getKnowledgeDatasById(
    options: ApiOptions<GetKnowledgeDatasByIdParam, never> & {
        segments: GetKnowledgeDatasByIdParam;
    }
): Promise<BaseResponseKnowledgeDataFoundResponse> {
    return request<never, GetKnowledgeDatasByIdParam, never>('/knowledgeDatas/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getKnowledgeDatasById',
    });
}

/**
 * update
 *
 * @export
 * @tags knowledge-data-controller
 * @link [PUT] /knowledgeDatas/{id}
 * @param id
 */
export function putKnowledgeDatasById(
    data: PutKnowledgeDatasByIdBody,
    options: ApiOptions<PutKnowledgeDatasByIdParam, never> & {
        segments: PutKnowledgeDatasByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutKnowledgeDatasByIdBody, PutKnowledgeDatasByIdParam, never>(
        '/knowledgeDatas/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putKnowledgeDatasById' }
    );
}

/**
 * deleteById
 *
 * @export
 * @tags knowledge-data-controller
 * @link [DELETE] /knowledgeDatas/{id}
 * @param id
 */
export function deleteKnowledgeDatasById(
    options: ApiOptions<DeleteKnowledgeDatasByIdParam, never> & {
        segments: DeleteKnowledgeDatasByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteKnowledgeDatasByIdParam, never>('/knowledgeDatas/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteKnowledgeDatasById',
    });
}

export const meta = [
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas/{id}', method: 'get' },
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas/{id}', method: 'put' },
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas/{id}', method: 'delete' },
];
