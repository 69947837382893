import React from 'react';
import { Select } from 'udesk-ui';
const { Option, OptGroup } = Select;

const SmartTagsList = (props) => {
    return (
        <Select
            value={props.selectMode ? [] : null}
            style={{ width: 200 }}
            onChange={props.onSelect}
            showSearch={true}
            optionFilterProp="children"
            mode={props.selectMode}
            onInputKeyDown={props.onInputKeyDown}
            onSearch={props.onSearch}
            // filterOption={(input, option) => !option.children || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {props.smartTagsList
                .filter((category) => Array.isArray(category.children) && category.children.length)
                .map((category) => {
                    return (
                        <OptGroup label={category.categoryName}>
                            {category.children.map((tag) => {
                                return (
                                    <Option
                                        key={tag.id}
                                        categoryColor={tag.categoryColor}
                                        value={tag.id}
                                        disabled={props.markedSmartTags.some(
                                            (markedTag) =>
                                                tag.id === (markedTag.tagId || markedTag.id)
                                        )}
                                    >
                                        {tag.tagName}
                                    </Option>
                                );
                            })}
                        </OptGroup>
                    );
                })}
        </Select>
    );
};

export default SmartTagsList;
