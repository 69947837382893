// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetInspectionPointTemplatesQuery,
    BaseResponseListInspectionPointTemplateFoundResponse,
    PostInspectionPointTemplatesBody,
    BaseResponseInspectionPointTemplateFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags inspection-point-template-controller
 * @link [GET] /inspectionPointTemplates
 */
export function getInspectionPointTemplates(
    options: ApiOptions<never, GetInspectionPointTemplatesQuery> & {
        params: GetInspectionPointTemplatesQuery;
    }
): Promise<BaseResponseListInspectionPointTemplateFoundResponse> {
    return request<never, never, GetInspectionPointTemplatesQuery>('/inspectionPointTemplates', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getInspectionPointTemplates',
    });
}

/**
 * save
 *
 * @export
 * @tags inspection-point-template-controller
 * @link [POST] /inspectionPointTemplates
 */
export function postInspectionPointTemplates(
    data: PostInspectionPointTemplatesBody
): Promise<BaseResponseInspectionPointTemplateFoundResponse>;
export function postInspectionPointTemplates(
    data: PostInspectionPointTemplatesBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseInspectionPointTemplateFoundResponse>;
export function postInspectionPointTemplates(
    data: PostInspectionPointTemplatesBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseInspectionPointTemplateFoundResponse> {
    return request<PostInspectionPointTemplatesBody, never, never>('/inspectionPointTemplates', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postInspectionPointTemplates',
    });
}

export const meta = [
    {
        tags: ['inspection-point-template-controller'],
        path: '/inspectionPointTemplates',
        method: 'get',
    },
    {
        tags: ['inspection-point-template-controller'],
        path: '/inspectionPointTemplates',
        method: 'post',
    },
];
