// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetInspectionTestDatasProcessByTaskIdParam,
    BaseResponseInspectionTestProcessFoundResponse,
} from '../../../types';

/**
 * 测试进度
 *
 * @export
 * @tags 质检规则测试
 * @link [GET] /inspectionTestDatas/process/{taskId}
 * @param taskId
 */
export function getInspectionTestDatasProcessByTaskId(
    options: ApiOptions<GetInspectionTestDatasProcessByTaskIdParam, never> & {
        segments: GetInspectionTestDatasProcessByTaskIdParam;
    }
): Promise<BaseResponseInspectionTestProcessFoundResponse> {
    return request<never, GetInspectionTestDatasProcessByTaskIdParam, never>(
        '/inspectionTestDatas/process/{taskId}',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionTestDatasProcessByTaskId' }
    );
}

export const meta = [
    { tags: ['质检规则测试'], path: '/inspectionTestDatas/process/{taskId}', method: 'get' },
];
