// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetReviewWecomUserQuery,
    BaseResponseListWeComDictionaryFoundResponse,
} from '../../../types';

/**
 * findWeComUser
 *
 * @export
 * @tags 企微用户信息
 * @link [GET] /review/wecom/user
 */
export function getReviewWecomUser(
    options: ApiOptions<never, GetReviewWecomUserQuery> & { params: GetReviewWecomUserQuery }
): Promise<BaseResponseListWeComDictionaryFoundResponse> {
    return request<never, never, GetReviewWecomUserQuery>('/review/wecom/user', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewWecomUser',
    });
}

export const meta = [{ tags: ['企微用户信息'], path: '/review/wecom/user', method: 'get' }];
