import { UserOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { getIntelligentPartnerCustomerPersonas } from 'src/api/intelligentPartnerCustomerPersonas';
import { Avatar, Radio, Select } from 'udesk-ui';
import './index.less';
import Locales from 'UdeskLocales';

interface UserPortraitSelectValue {
    status?: number;
    selectedItemId?: number;
}

interface UserPortraitSelectProps {
    disabled?: boolean;
    courseId: number;
    value?: UserPortraitSelectValue;
    onChange?: (value?: UserPortraitSelectValue) => void;
}

const UserPortraitSelect: React.FC<UserPortraitSelectProps> = (props) => {
    const locales = Locales['current'];
    const { value, onChange, courseId, disabled } = props;
    const [portraitList, setPortraitList] = useState<any>([]);
    const [status, setStatus] = useState(2);
    const [selectedItemId, setSelectedItemId] = useState<number | undefined>(undefined);

    const triggerChange = useCallback(
        (changedValue: UserPortraitSelectValue) => {
            const newValue = {
                status,
                selectedItemId,
                ...changedValue,
            };
            if (newValue.status === 1 && newValue.selectedItemId === undefined) {
                onChange?.(undefined);
            } else {
                onChange?.(newValue);
            }
        },
        [onChange, selectedItemId, status]
    );

    const onStatusChange = useCallback(
        (e) => {
            setStatus(e.target.value);
            triggerChange({ status: e.target.value });
        },
        [triggerChange]
    );

    const onPortraitChange = useCallback(
        (val) => {
            setSelectedItemId(val);
            triggerChange({ selectedItemId: val });
        },
        [triggerChange]
    );

    useEffect(() => {
        if (value) {
            setStatus(value?.status || 2);
            setSelectedItemId(value?.selectedItemId);
        }
    }, [value]);

    useEffect(() => {
        getIntelligentPartnerCustomerPersonas({
            params: {
                courseId,
            },
        } as any).then((resp) => {
            setPortraitList(resp?.data || []);
        });
    }, [courseId]);

    return (
        <div>
            <Radio.Group
                disabled={disabled}
                value={value?.status || status}
                onChange={onStatusChange}
            >
                <Radio value={1}>{locales.labels.switchOn}</Radio>
                <Radio value={2}>{locales.labels.switchOff}</Radio>
            </Radio.Group>
            {(value?.status || status) === 1 && (
                <Select
                    disabled={disabled}
                    style={{ marginTop: 10 }}
                    value={value?.selectedItemId || selectedItemId}
                    onChange={onPortraitChange}
                >
                    {portraitList.map((item) => (
                        <Select.Option value={item.id} label={item.name}>
                            <div className="user-portrait-select-drop-wrapper">
                                <Avatar
                                    // className="user-portrait-select-drop-icon"
                                    size={20}
                                    src={item.cover}
                                    icon={<UserOutlined />}
                                />
                                <span className="user-portrait-select-drop-label">{item.name}</span>
                            </div>
                        </Select.Option>
                    ))}
                </Select>
            )}
        </div>
    );
};

export default UserPortraitSelect;
