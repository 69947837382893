import Udesk from 'Udesk';
import TaskCenterListComponent from "./component";
import { getSystemModule } from 'Udesk/system/subApp';
class TaskCenterListRoute extends TaskCenterListComponent {
    asyncModel(changedParams, topId) {
        let { sdkOptions } = this.props;
        let params = {
            pageNum: 1,
            pageSize: 20,
            moduleType: getSystemModule(),
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }
        let url = Udesk.business.apiPath.concatApiPath(`inspectionManualLoads/tasks`, sdkOptions);
        return new Promise((resolve, reject) => {
            Udesk.ajax.get(url, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    }
    parseAsyncModel(asyncModel) {
        return asyncModel;
    }
}
export default TaskCenterListRoute;
