import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import { CallSentenceClusterResultsWarnContext } from 'src/pages/home';

class trainingCenter extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { route, location, sdkOptions } = this.props;

        let { locales } = this;
        return (
            <CallSentenceClusterResultsWarnContext.Consumer>
                {({ showSentenceClusterResultsWarn }) => {
                    let navLists = [];
                    // 根据当前用户权限，组装菜单列表
                    //&& Udesk.data.init.user.hasFeature("smart:tag:root")

                    if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('training:fast:root')) {
                        navLists.push({
                            text: locales.components.pages.trainingCenter.fastTraining.title,
                            pathName: 'fastTrainingIndex',
                            isActive: (match, location) => {
                                return location.pathname.includes('/fast-training');
                            },
                        });
                    }
                    if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('training:history:root')) {
                        navLists.push({
                            text: locales.components.pages.trainingCenter.trainingHistory.title,
                            pathName: 'trainingHistoryIndex',
                            isActive: (match, location) => {
                                return location.pathname.includes('/training-history');
                            },
                        });
                    }
                    let grounp = [];
                    if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('training:categoryTrain:train')) {
                        let grounpChildren = {
                            text: locales.components.pages.trainingCenter.classifiedTraining.title,
                            pathName: 'classifiedTraining',
                            isActive: (match, location) => {
                                return location.pathname.includes('/classified-training');
                            },
                        };
                        grounp.push(grounpChildren);
                    }
                    if (
                        Udesk.data.init.user &&
                        Udesk.data.init.user.hasFeature('training:categoryTrain:trainHistory')
                    ) {
                        let grounpChildren = {
                            text: locales.components.pages.trainingCenter.classifiedTrainingHistory.title,
                            pathName: 'classifiedTrainingHistoryIndex',
                            isActive: (match, location) => {
                                return location.pathname.includes('/classified-history-training');
                            },
                        };
                        grounp.push(grounpChildren);
                    }
                    if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('training:categoryTrain:root')) {
                        navLists.push({
                            text: locales.components.pages.trainingCenter.classifiedTraining.title,
                            children: grounp,
                        });
                    }
                    if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('training:clusterMark:root')) {
                        navLists.push({
                            text: locales.components.pages.trainingCenter.clusteringAnnotation.title,
                            pathName: 'clusteringAnnotationIndex',
                            isActive: (match, location) => {
                                return location.pathname.includes('/clustering-annotation-index');
                            },
                            dot: showSentenceClusterResultsWarn,
                        });
                    }
                    // if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('training:subjectMark:root')) {
                    //     navLists.push({
                    //         text: /* 话题标注 */ locales.pages.trainingCenter.topicAnnotation,
                    //         pathName: 'topicCallout',
                    //         isActive: (match, location) => {
                    //             return location.pathname.includes('/topic-callout');
                    //         },
                    //     });
                    // }
                    // if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('training:subjectList:root')) {
                    //     navLists.push({
                    //         text: /* 话题列表 */ locales.pages.trainingCenter.topicList,
                    //         pathName: 'topicList',
                    //         isActive: (match, location) => {
                    //             return location.pathname.includes('/topic-index');
                    //         },
                    //     });
                    // }
                    grounp = [];
                    if (sdkOptions.props.landingPath != null && this.isRedirect(route.path, location.pathname)) {
                        let toPath = location.pathname + sdkOptions.props.landingPath;
                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
                        let toPath = null;
                        if (navLists[0].pathName) {
                            toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                                routeName: navLists[0].pathName,
                            });
                        } else {
                            toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                                routeName: navLists[0].children[0].pathName,
                            });
                        }

                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else {
                        return (
                            <div className='semantic-intelligence'>
                                <LayoutMenu
                                    {...this.props}
                                    navLists={navLists}
                                    menuTitle={locales.components.pages.menuTitleList.trainingCenterTitle}
                                />
                            </div>
                        );
                    }
                }}
            </CallSentenceClusterResultsWarnContext.Consumer>
        );
    }
}

export default Udesk.react.udeskify(trainingCenter);
