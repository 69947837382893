import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import { useDimensionality } from './components/hooks';
import { Button, Form, Space, message } from 'udesk-ui';
import Dimensionality from './components/dimensionality';
import AutoGenerate from './components/auto-generate';
import Prompt from './components/prompt';
import { DimensionalityType } from './components/hooks';
import { getLargeModelConfigs, postLargeModelConfigs } from 'src/api/largeModelConfigs';
import { LargeModelConfigFoundResponse } from 'src/api/types';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

type FormValues = {
    config?: LargeModelConfigFoundResponse[];
};

const Template = React.memo((props: any) => {
    const [form] = Form.useForm<FormValues>();

    const [initialValues, setInitialValues] = useState<FormValues>();

    const { defaultPromptMap } = useDimensionality({});

    const { run: getConfig } = useRequest(getLargeModelConfigs, {
        onSuccess: (res) => {
            const config = res.data?.length
                ? res.data
                : [
                      {
                          type: DimensionalityType.SesstionSummary,
                          prompt: defaultPromptMap.SesstionSummary,
                      },
                      {
                          type: DimensionalityType.SesstionFlow,
                          prompt: defaultPromptMap.SesstionFlow,
                      },
                      { type: DimensionalityType.Oppotunity, prompt: defaultPromptMap.Oppotunity },
                  ];

            form.setFieldsValue({
                config,
            });

            setInitialValues({ config });
        },
    });

    const { run: saveConfig } = useRequest(postLargeModelConfigs, {
        manual: true,
        onSuccess: () => {
            message.success(/* 保存成功 */UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.index.saveSuccessful);
            getConfig({});
        },
    });

    const onFinish = (values?: FormValues) => {
        saveConfig(values?.config || [], {});
    };

    return (
        <Form<FormValues>
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Form.List name="config">
                {(fileds) => {
                    return fileds.map((field, idx) => {
                        return (
                            <Form.Item noStyle key={field.key}>
                                <Form.Item
                                    label={
                                        <Form.Item noStyle shouldUpdate>
                                            {() => {
                                                const { config } = form.getFieldsValue();
                                                const type = config?.[idx].type;
                                                return <Dimensionality type={type} />;
                                            }}
                                        </Form.Item>
                                    }
                                >
                                    <Form.Item name={[field.name, 'isAutoGenerate']} noStyle>
                                        <AutoGenerate />
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item noStyle shouldUpdate>
                                    {() => {
                                        const { config } = form.getFieldsValue();
                                        const type = config?.[idx].type;
                                        return (
                                            <Form.Item
                                                name={[field.name, 'prompt']}
                                                label={/* 提示语 */UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.index.hintLanguage}
                                                rules={[{ required: true }]}
                                                required={false}
                                            >
                                                <Prompt type={type} />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Form.Item>
                        );
                    });
                }}
            </Form.List>

            <Form.Item wrapperCol={{ offset: 4 }}>
                <Space>
                    <Button type="primary" htmlType="submit">{/* 保存 */}{UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.index.preserve}</Button>
                    <Button htmlType="reset">{/* 取消 */}{UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.index.cancel}</Button>
                </Space>
            </Form.Item>
        </Form>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
