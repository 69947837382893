import React, { useCallback, useState } from 'react';
import { Table } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { inspect, isDefined } from 'src/util/core';
import './index.scss';

type PropsType = {
    scenario?: number;
    data: any[];
    setData: Function;
    selectedRowKey: number;
    setSelectedRowKey: (key: number | ((key: number) => number)) => void;
    sdkOptions: any;
    history: any;
    locales: any;
}

export default React.memo((props: PropsType) => {
    const {
        scenario = 1,
        data,
        setData,
        selectedRowKey, 
        setSelectedRowKey, 
        locales
    }= props;
    const [loading, setLoading] = useState(false);
    const request = useRequest();

    const renderCell = useCallback((text, record) => {
        return (
            <div className='table-row-item' onClick={() => setSelectedRowKey(record.id)}>
                <span>{record.name}</span>
            </div>
        );
    }, [setSelectedRowKey]);


    useLiveEffect(() => {
        setLoading(true);
        const url = `/review/words/favorite/${scenario}`;
        request(url, {
            pageNum: 1,
            pageSize: 99999,
        }).then(({data}) => {
            setData(data.map(item => {
                return {
                    ...item, key: item.id
                };
            }));
            inspect(data.length > 0 && !isDefined(selectedRowKey), () => {
                setSelectedRowKey(data[0].id);
            });
        }).finally(() => {
            setLoading(false);
        });
    }, [scenario]);

    return (
        <div className='words-mining-favorite-view-page-left'>
            <Table
                loading={loading}
                scroll={{
                    y: '100%'
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    columnTitle: (
                        <div className='column-header'>{locales.pages.semanticIntelligence.wordsMining.favoriteView.components.pageLeftTable.objectionHandlingScriptFavorites}</div>
                    ),
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    pageSize: 99999,
                    total: data.length,
                    hideOnSinglePage: true,
                }} 
                columns={[]}
                dataSource={data}
            />
        </div>
    );
});
