import React, { useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { message, Switch, Button} from 'udesk-ui';
import { putInspectionRuleApprovesById } from 'src/api/inspectionRule/approves/{id}';
import RejectButton from './rejectButton/index';

export const PageHeaderExtra = (props) => {
    const locales = Locales.current;
    const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    const {
        approveId,
        approveStatus,
        enabledInspectRuleApprove,
        value,
        onChange,
    } = props;

    const submit = useCallback((id, params) => {
        return putInspectionRuleApprovesById(params, {
            segments: { 
                id 
            }
        }).then(() => {
            message.success(/* 修改成功 */locales.pages.tasks.approve.components.pageHeaderExtra.modificationSucceeded);
        });
    }, [locales]);

    const changeHandle = (value) => {
        onChange(value);
    };

    return (
        <>
            {
                [
                    <Switch 
                        checked={value} 
                        onChange={changeHandle} 
                        checkedChildren={/* 测试 */locales.pages.tasks.approve.components.pageHeaderExtra.test} 
                        unCheckedChildren={/* 正式 */locales.pages.tasks.approve.components.pageHeaderExtra.formal} />,
                    <Button type="primary" onClick={() => {
                        submit(approveId, {
                            approveStatus: Udesk.enums.inspectApproveType.resolve.id
                        });
                    }}>
                        {/* 通过 */locales.pages.tasks.approve.components.pageHeaderExtra.adopt}
                    </Button>,
                    <RejectButton buttonType="primary" onSubmit={(values) => {
                        return submit(approveId, {
                            ...values,
                            approveStatus: Udesk.enums.inspectApproveType.reject.id
                        });
                    }}/>
                ].filter((btn, index) => {
                    if (index > 0) {
                        return hasFeature?.('task:approve') && approveStatus === Udesk.enums.inspectApproveType.resolving.id;
                    }
                    return !!enabledInspectRuleApprove;
                })
            }
        </>
    );
};