import React, { CSSProperties, FC, memo, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Radio, Input, Card, Tag, Button } from 'udesk-ui';
import { Scope } from './const';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div``;

const Empty = styled.div`
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
`;

export type TemplatePropsValue = {
    scope?: number;
    content?: string[];
};

type TemplateProps = {
    value?: TemplatePropsValue;
    onChange?: (v?: TemplatePropsValue) => void;
    wrapperStyle?: CSSProperties;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange, wrapperStyle } = props;

    const content = useMemo(() => {
        return value?.content?.map((c) => {
            return {
                id: Udesk.utils.string.uuid(),
                text: c,
            };
        });
    }, [value?.content]);

    const onScopeChange = (v: number) => {
        onChange?.({
            ...value,
            scope: v,
        });
    };

    const [temp, setTemp] = useState<string>();

    const onTempChange = (t) => {
        setTemp(t);
    };

    const onAdd = () => {
        if (!temp?.trim()) return;
        onChange?.({
            ...value,
            content: [...(value?.content || []), temp],
        });
        setTemp('');
    };

    const onRemove = (id: number) => {
        onChange?.({
            ...value,
            content: content?.filter((c) => c.id !== id).map((c) => c.text),
        });
    };

    return (
        <Wrapper style={{ ...wrapperStyle }}>
            <Radio.Group
                value={value?.scope}
                onChange={(e) => onScopeChange(e.target.value)}
                style={{ marginBottom: 8 }}
            >
                <Radio value={Scope.UNLIMITED}>{/* 不限定 */}{UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.components.contentScope.index.notQualified}</Radio>
                <Radio value={Scope.LIMIT}>{/* 限定范围 */}{UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.components.contentScope.index.limitedScope}</Radio>
            </Radio.Group>

            {value?.scope === Scope.LIMIT ? (
                <>
                    <Input
                        value={temp}
                        onChange={(e) => onTempChange(e.target.value)}
                        suffix={
                            <Button type="primary" size="small" onClick={onAdd}>{/* 添加 */}{UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.components.contentScope.index.add}</Button>
                        }
                        style={{ marginBottom: 8 }}
                    />

                    <Card bodyStyle={{ padding: 8 }}>
                        {content?.length ? (
                            content?.map((c) => {
                                return (
                                    <Tag
                                        key={c.id}
                                        color="processing"
                                        closable
                                        onClose={() => onRemove(c.id)}
                                        style={{ marginBottom: 8 }}
                                    >
                                        {c.text}
                                    </Tag>
                                );
                            })
                        ) : (
                            <Empty>{/* 暂无数据 */}{UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.components.contentScope.index.thereIsCurrentlyNoDataAvailable}</Empty>
                        )}
                    </Card>
                </>
            ) : null}
        </Wrapper>
    );
};

export default memo(Template);
