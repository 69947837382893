import React, { useCallback, useMemo } from 'react';
import OrganizationMemberSelect from '../components/OrganizationMemberSelect';
import { union, intersection } from 'lodash/array';
import { isNumber, posterity } from 'src/util/core';
import { getUserKeyById } from 'src/util/hook/user';

export default React.memo((props: any) => {
    const {
        value, onChange, 
        finalValue, treeData,
        getUserByIds, getOrgByIds, ...otherProps
    } = props;

    const changeHandle = useCallback((values) => {
        const {organizationIds: disabledOrganizationIds, userIds: disabledUserIds} = finalValue || {
            organizationIds: [], userIds: []
        };
        const {organizationIds: realOrgIds, userIds: realUserIds}  = values || {
            organizationIds: [], userIds: []
        };

        let organizationIds = union(disabledOrganizationIds, realOrgIds);
        let userIds = union(disabledUserIds, realUserIds);

        const getPIds = (node) => {
            return posterity([node], () => null, (node) => {
                const parent = treeData.find(item => item.id === node.pId);
                if (parent) {
                    return [parent];
                }
                return [];
            }).map(item => {
                return item.data.id;
            });
        };

        organizationIds = organizationIds.filter(id => {
            const node = treeData.find(item => item.id === id);
            if (node) {
                return intersection(getPIds(node), organizationIds).length === 1;
            }
            return false;
        });

        userIds = userIds.filter(id => {
            const node = treeData.find(item => item.id === getUserKeyById(id));
            if (node) {
                return intersection(getPIds(node), organizationIds).length === 0;
            }
            return false;
        });

        onChange({
            organizationIds,
            userIds
        });
        
    }, [finalValue, onChange, treeData]);
    
    const tempValue = useMemo(() => {
        const {organizationIds: disabledOrganizationIds, userIds: disabledUserIds} = finalValue || {
            organizationIds: [], userIds: []
        };
        const {organizationIds: realOrgIds, userIds: realUserIds}  = value || {
            organizationIds: [], userIds: []
        };

        let organizationIds = union([...realOrgIds, ...disabledOrganizationIds]);
        let userIds = union([...realUserIds, ...disabledUserIds]);

        posterity(
            organizationIds.map(id =>  treeData.find(item => item.id ===id)),
            () => {},
            (node) => {
                if (isNumber(node?.id)) {
                    return treeData.filter(item => item.pId === node.id);
                }
                return undefined;
            }
        ).forEach((item) =>{
            const node = item.data;
            if (node) {
                if (isNumber(node.id)) {
                    organizationIds.push(node.id);
                } else {
                    userIds.push(node.data.id);
                }
            }
        });

        return {
            userIds: union(userIds), organizationIds: union(organizationIds)
        };
    }, [
        finalValue, 
        value, 
        treeData
    ]);

    const treeDataWithDisabledProps= useMemo(() => {
        if (finalValue && treeData.length > 0) {
            const {organizationIds, userIds} = finalValue;
            const list: any[] = [].concat(getUserByIds(userIds) || [], getOrgByIds(organizationIds) || []);

            posterity(
                list,
                (node) => {
                    if (node) {
                        node.disableCheckbox = true;
                    }
                },
                (node) => {
                    if (isNumber(node?.id)) {
                        return treeData.filter(item => item.pId === node.id);
                    }
                    return undefined;
                }
            );
        }
        return treeData;
    }, [
        finalValue, 
        getOrgByIds, 
        getUserByIds, 
        treeData
    ]);

    return (
        <OrganizationMemberSelect {...otherProps} treeData={treeDataWithDisabledProps} value={tempValue} onChange={changeHandle}/>
    );
});