import Udesk from 'Udesk';
import React from 'react';
import { Button } from 'udesk-ui';
import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import FilterModalRoute from './route';

export default class FilterModalTempalte extends FilterModalRoute {
    render() {
        let { locales, props, actions } = this;
        return (
            <React.Fragment>
                {
                    !_isEmpty(props.conditionJson) && (
                        <div
                            className='auto-add-filter-edit-item-row-filter'
                            onClick={props.disabled ?
                                null :
                                actions.editFilterOhter}
                        >
                            <div className='auto-add-filter-edit-item-row-filter-title'>
                                <div>
                                    {
                                        locales.pages.tasks.manage.template.manualAdd.index
                                            .filterTitle
                                    }
                                </div>
                                {
                                    props.disabled ?
                                        null :
                                        <div className='index-management-custom-filter-edit'>
                                            <Button
                                                type='link'
                                                size='small'
                                                onClick={actions.editFilterOhter}
                                            >
                                                {locales.labels.edit}
                                            </Button>
                                        </div>
                                }
                            </div>
                            <div className='index-management-custom-filter-Info'>
                                <div className='auto-add-filter-edit-item-row-filter-title'>
                                    {
                                        locales.pages.tasks.manage.template.manualAdd.index
                                            .filterRule
                                    }
                                    {locales.labels.colon}
                                </div>
                                <div className='auto-add-filter-edit-item-row-filter-rules'>
                                    {props.conditionJsonInfo.length &&
                                        props.fieldsFilterChange(
                                            props.conditionJsonInfo
                                        )}
                                </div>
                            </div>
                            <div className='index-management-custom-filter-Info' style={{ marginBottom: 0 }}>
                                <div className='auto-add-filter-edit-item-row-filter-title' style={{ marginBottom: 0 }}>
                                    {locales.components.common.customFilter.conditionRule}：
                                </div>
                                <div className='auto-add-filter-edit-item-row-filter-rules'>
                                    {props.judgeStrategy !== 3 &&
                                        Udesk.enums.operatorRuleTypes.find(
                                            (current) =>
                                                current.id === (props.judgeStrategy || Udesk.enums.operatorRuleTypes.all.id)
                                        ).name}
                                    {props.judgeStrategy === 3 &&
                                        props.customJudgeLogic}
                                </div>
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        );
    }
}

