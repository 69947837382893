import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FilterContext, InitialValuesContext } from '../../../../context';
import { Button, Form, message, Select, Space } from 'udesk-ui';
import { useCoachUserTreeDataWithPermit, UserTreeSelect } from 'src/util/hook/user';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { useUdeskDatePicker } from 'Component/common/udesk-date-picker/template';
import { useTask } from './hooks';
import Select2 from './components/Select2';
import isNumber from 'lodash/isNumber';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

const TaskTypeOptions = [
    {
        label: /* 练习 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.filter.index.practice,
        value: Udesk.enums.learningTaskType.notStarted.id,
    },
    {
        label: /* 考试 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.filter.index.examination,
        value: Udesk.enums.learningTaskType.underWay.id,
    },
];

type FilterProps = {};

const Filter: FC<FilterProps> = (props) => {
    const [form] = Form.useForm();

    const [, setParams] = useContext(FilterContext);

    const [initialValues, setInitialValues] = useContext(InitialValuesContext);

    const { treeData: _treeData } = useCoachUserTreeDataWithPermit();

    const treeData = useMemo(() => {
        return (_treeData || []).map((node) => {
            if (isNumber(node.id)) {
                return {
                    ...node,
                    selectable: false,
                };
            }
            return node;
        });
    }, [_treeData]);

    const [curUserId, setCurUserId] = useState<number>();

    const [curTaskType, setCurTaskType] = useState<number>();

    const { options: taskOptopns, tasks } = useTask({ userId: curUserId, taskType: curTaskType });

    const { startTime, endTime } = useUdeskDatePicker();

    const [firstSearch, setFirstSearch] = useState(true);

    useEffect(() => {
        // 初次查询处理 - 默认值
        if (firstSearch) {
            const defaultUserId = treeData?.[0]?.id ? parseInt(treeData?.[0]?.id) : undefined;
            setCurUserId(defaultUserId);

            const defaultTaskId = tasks?.[0]?.id ? tasks?.[0]?.id : undefined;

            const v = {
                employee: defaultUserId,
                taskId: defaultTaskId,
                date: [startTime, endTime],
            };
            setInitialValues(v);

            const p = {
                userId: defaultUserId,
                taskId: defaultTaskId,
                startTime,
                endTime,
            };

            if (Object.values(p).every((v) => !!v)) {
                setParams(p);
                setFirstSearch(false);
            }
        }
    }, [endTime, firstSearch, form, setInitialValues, setParams, startTime, tasks, treeData]);

    const onUserIdChange = (v) => {
        setCurUserId(v);
        form.setFieldsValue({ taskId: undefined });
    };

    const onTaskTypeChange = (v) => {
        setCurTaskType(v);
        form.setFieldsValue({ taskId: undefined });
    };

    const onFinish = (values) => {
        if (!values.employee || !values.taskId) {
            message.error(/* 请完善必填项 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.filter.index.pleaseCompleteTheRequiredFields);
            return;
        }

        const params = {
            userId: values.employee,
            taskType: values.taskType,
            taskId: values.taskId,
            startTime: values.date?.[0]?.split?.(' ')?.[0],
            endTime: values.date?.[1]?.split?.(' ')?.[0],
        };

        setParams(params);
    };

    const onReset = useCallback(() => {
        form.resetFields();
        form.submit();
        setCurTaskType(undefined);
    }, [form]);

    useEffect(() => {
        form.resetFields();
    }, [initialValues, form]);

    return (
        <Form
            form={form}
            layout="inline"
            onFinish={onFinish}
            style={{
                padding: '16px',
                justifyContent: 'space-between',
            }}
            initialValues={initialValues}
        >
            <Space>
                <Form.Item name="employee" label={/* 员工 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.filter.index.staff} required>
                    <UserTreeSelect
                        {...{
                            width: 200,
                            treeData,
                            showSearch: true,
                            showArrow: true,
                            maxTagCount: 1,
                            placeholder: UdeskLocales['current'].labels.pleaseSelect,
                            treeDefaultExpandAll: true,
                            onChange: onUserIdChange,
                        }}
                    />
                </Form.Item>

                <Form.Item name="taskType" label={/* 任务类型 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.filter.index.taskType}>
                    <Select2
                        placeholder={/* 请选择 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.filter.index.pleaseSelect}
                        options={TaskTypeOptions}
                        style={{ width: 200 }}
                        allowClear={true}
                        showArrow={true}
                        onChangeAfter={onTaskTypeChange}
                    />
                </Form.Item>

                <Form.Item name="taskId" label={/* 任务名称 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.filter.index.taskName} required>
                    <Select
                        options={taskOptopns}
                        placeholder={/* 请选择 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.filter.index.pleaseSelect}
                        style={{ width: 200 }}
                        showArrow={true}
                        optionFilterProp="label"
                        showSearch={true}
                    />
                </Form.Item>

                <Form.Item name="date">
                    <UdeskDatePicker allowClear={false} />
                </Form.Item>
            </Space>

            <Form.Item>
                <Space>
                    <Button onClick={onReset}>{UdeskLocales['current'].labels.reset}</Button>
                    <Button type="primary" htmlType="submit">
                        {UdeskLocales['current'].labels.confirm}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default memo(Filter);
