// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostRiskSettingsSearchTemplateBody,
    BaseResponseListFieldFoundAdvanceWrapperResponse,
} from '../../types';

/**
 * 查询风险配置规则项
 *
 * @export
 * @tags 销售赋能-风险配置
 * @link [POST] /riskSettings/search-template
 */
export function postRiskSettingsSearchTemplate(
    data: PostRiskSettingsSearchTemplateBody
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse>;
export function postRiskSettingsSearchTemplate(
    data: PostRiskSettingsSearchTemplateBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse>;
export function postRiskSettingsSearchTemplate(
    data: PostRiskSettingsSearchTemplateBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse> {
    return request<PostRiskSettingsSearchTemplateBody, never, never>(
        '/riskSettings/search-template',
        { ...options, data, method: 'post', __$requestCalleeName: 'postRiskSettingsSearchTemplate' }
    );
}

export const meta = [
    { tags: ['销售赋能-风险配置'], path: '/riskSettings/search-template', method: 'post' },
];
