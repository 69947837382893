import React from "react";
import Udesk from "Udesk";
import UdeskLocales from "UdeskLocales";
import { Popconfirm } from "udesk-ui";
import importSuccessNotification from "Component/common/import-notification";
import { isAlgorithm, isSalesSubApp } from "Udesk/system/subApp";
import { getSystemModule } from 'Udesk/system/subApp';

class ConceptWordComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion
    state = {};
    privates = {
        searchInput: "", //输入框中 输入的内容
        searchInputForSearch: "",
        tableColumns: [
            {
                title:
                    UdeskLocales.current.components.pages.conceptWord.table.title
                        .conceptWord,
                dataIndex: "name",
                sorter: (a, b) => { }
            },
            {
                title:
                    UdeskLocales.current.components.pages.conceptWord.table.title
                        .describeWordsNum,
                dataIndex: "describeWordsNum",
                sorter: (a, b) => { }
            },
            {
                title: UdeskLocales.current.fields.semanticTags.orgName,
                dataIndex: "type",
                render: (name, record) => record.type === 3 ? Udesk.enums.organizationTypes.getName(record.type) : UdeskLocales.current.fix.null
            },
            {
                title:
                    UdeskLocales.current.components.pages.conceptWord.table.title
                        .updateTime,
                dataIndex: "updateTime",
                sorter: (a, b) => { }
            },
            {
                title:
                    UdeskLocales.current.components.pages.conceptWord.table.title.status,
                dataIndex: "status",
                render: status => {
                    let color = status === 1 ? "rgba(17,168,72,1)" : "rgba(251,81,39,1)";
                    return (
                        <span>
                            <span
                                style={{
                                    backgroundColor: color,
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginRight: "8px"
                                }}
                            ></span>
                            {status === 1
                                ? UdeskLocales.current.components.pages.conceptWord.table.title
                                    .effective
                                : UdeskLocales.current.components.pages.conceptWord.table.title
                                    .invalid}
                        </span>
                    );
                }
            },
            {
                title:
                    UdeskLocales.current.components.pages.conceptWord.table.title.action,
                dataIndex: "action",
                key: "action",
                render: (text, record) => {
                    return (
                        <div>
                            <If
                                condition={
                                    Udesk.data.init.user &&
                                    Udesk.data.init.user.hasFeature("semantic:conceptWords:edit")
                                }
                            >
                                <If condition={(record.moduleId && record.editEnable && record.type === 3) || !record.moduleId || record.type !== 3}>
                                    <span
                                        style={{
                                            marginRight: 8,
                                            color: "rgba(27,109,255,1)",
                                            cursor: "pointer"
                                        }}
                                        onClick={this.actions.edit.params(text, record)}
                                    >
                                        {UdeskLocales.current.components.pages.conceptWord.table.edit}
                                    </span>
                                </If>
                                <If condition={record.moduleId && record.showEnable && !record.editEnable && record.type === 3}>
                                    <span
                                        style={{
                                            marginRight: 8,
                                            color: "rgba(27,109,255,1)",
                                            cursor: "pointer"
                                        }}
                                        onClick={this.actions.edit.params(text, record)}
                                    >
                                        {UdeskLocales.current.labels.view}
                                    </span>
                                </If>
                            </If>
                            <If
                                condition={
                                    Udesk.data.init.user &&
                                    Udesk.data.init.user.hasFeature("semantic:conceptWords:delete")
                                }
                            >
                                <Popconfirm
                                    title={
                                        UdeskLocales.current.components.pages.conceptWord.table
                                            .request
                                    }
                                    okText={
                                        UdeskLocales.current.components.pages.conceptWord.table
                                            .delete
                                    }
                                    cancelText={
                                        UdeskLocales.current.components.pages.conceptWord.table
                                            .cancel
                                    }
                                    onConfirm={this.actions.delete.params(text, record)}
                                    onCancel={this.actions.cancel}
                                >
                                    <span
                                        style={{ color: "rgba(27,109,255,1)", cursor: "pointer" }}
                                    >
                                        {
                                            UdeskLocales.current.components.pages.conceptWord.table
                                                .delete
                                        }
                                    </span>
                                </Popconfirm>
                            </If>
                        </div>
                    );
                }
            }
        ],
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
        sort: "updateTime",
        asc: 0,
        file: null,
        uploadData: null,
        isUploadVisible: false,
        loading: false,
        selectedItems: [],
        failedCount: 0,
        moduleType: null,
    };
    actions = {
        //#region header/control-bar Button & SearchInput
        linkToNew() {
            let routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm() ? 'algorithmConceptWordNew' : isSalesSubApp() ? 'salesConceptWordNew' : 'conceptWordNew',
                // pathParams:{
                //     id:item.dataId
                // },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        moduleTypeChange(value) {
            this.privates.moduleType = value;
            this.actions.update();
        },
        searchInputChange(e) {
            this.privates.searchInput = e.target.value;
            this.actions.update();
        },
        search(value) {
            if (this.privates.searchInput)
                this.privates.searchInput = this.privates.searchInput.trim();
            this.privates.searchInputForSearch = this.privates.searchInput;
            //发送ajax，input输入参数为：this.privates.searchInput
            // this.actions.getTableData();
            this.privates.selectedItems = [];
            this.actions.reloadAsyncModel();
        },
        reset() {
            this.privates.searchInput = "";
            this.actions.update();
        },
        import() {
            this.privates.isUploadVisible = true;
            this.actions.update();
        },
        changeUploadVisible() {
            let { isUploadVisible } = this.privates;
            this.privates.isUploadVisible = !isUploadVisible;
            this.actions.update();
        },
        uploadFile(e) {
            let {
                locales
            } = this;
            let files = e.target.files;

            if (files.length === 0) {
                return;
            }

            // 取消文件大小限制
            // if (files[0] && (files[0].size > 2 * 1024 * 1024)) {
            //     return Udesk.ui.notify.error(UDL.business.notifyMessage.overFileSize);
            // }
            let fileName = fileType(files[0].name);
            if (!fileName) {
                return;
            } else if (fileName !== 'xlsx') {
                return Udesk.ui.notify.error(Udesk.utils.string.format(locales.labels.fileTypeHolder, 'xlsx'));
            }
            //type 1语音,2导入,3导出,4语料数据
            Udesk.app.fileStorage.upload(files[0], {
                token: new Promise((resolve, reject) => {
                    Udesk.business
                        .getServerAuthorization({
                            type: 2
                        })
                        .then(
                            function (resp) {
                                resolve(resp);
                            },
                            function (reason) {
                                reject(reason);
                            }
                        );
                }),
                //progressCallback: progressCallback.bind(this),
                successCallback: this.actions.uploadFileSuccess.bind(this),
                errorCallback: this.actions.uploadFileError.bind(this)
            });
        },
        uploadFileSuccess(file, uploadData) {
            this.privates.file = file;
            this.privates.uploadData = uploadData;
            this.actions.update();
        },
        uploadFileError(file, uploadData) { },
        importConceptWords() {
            let { sdkOptions } = this.props;
            let { file, uploadData } = this.privates;
            if (file == null || uploadData == null) {
                return Udesk.ui.notify.error(
                    this.locales.business.notifyMessage.pleaseUploadFile
                );
            }
            let params = {
                keyName: uploadData.key,
                fileName: file.name,
                fileSize: file.size,
                systemModule: getSystemModule(),
            };
            let url = Udesk.business.apiPath.concatApiPath(
                `conceptWords/import`,
                sdkOptions
            );
            Udesk.ajax.post(url, params).then(
                resp => {
                    Udesk.ui.notify.success(
                        this.locales.business.notifyMessage.importFileSuccess
                    );
                    this.actions.changeUploadVisible();
                    importSuccessNotification();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        this.locales.business.notifyMessage.importFildFailed
                    );
                    this.actions.changeUploadVisible();
                }
            );
        },
        downloadTemplate() {
            let { sdkOptions } = this.props;

            let templateLocalUrl = `/import/semantic-intelligence/concept-word/concept-words-template.xlsx`;
            let templateUrl =
                Udesk.business.apiPath.getStaticBasePath(
                    sdkOptions.props.domain,
                    sdkOptions.props.pathPrefix
                ) + templateLocalUrl;

            window.location = templateUrl;
        },
        export() {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `conceptWords/exp?systemModule=${getSystemModule()}`,
                sdkOptions
            );
            Udesk.ajax.post(url).then(
                resp => {
                    Udesk.ui.notify.success(
                        UdeskLocales.current.components.pages.conceptWord.control.seccuss
                    );
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        //#endregion
        tableChangeHandle(pagination, filters, sorter, extra) {
            this.privates.asc = sorter.order && sorter.order === "descend" ? 0 : 1;
            this.privates.sort = sorter.columnKey;
            // this.actions.getTableData(params);
            this.actions.reloadAsyncModel();
        },
        //#region Table & Pagination
        showTotal(total, range) {
            // return `共${total}条，${total/range[1]}页`;
            return Udesk.utils.string.format(UdeskLocales.current.components.pages.conceptWord.table.page, total, Math.ceil(total / this.privates.pageSize));
        },
        pageChange(page) {
            this.privates.pageNum = page;
            // this.actions.getTableData(params);
            this.actions.reloadAsyncModel();
            this.actions.update();
        },
        onShowSizeChange(current, pageSize) {
            this.privates.pageSize = pageSize;
            this.privates.pageNum = 1;
            // this.actions.getTableData(params);
            this.actions.reloadAsyncModel();
            this.actions.update();
        },
        edit(text, record) {
            //record是所在行的参数的合辑obj
            let routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm() ? 'algorithmConceptWordEdit' : isSalesSubApp() ? 'salesConceptWordEdit' : 'conceptWordEdit',
                pathParams: {
                    id: record.key
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        delete(text, record) {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `conceptWords/${record.key}`,
                sdkOptions
            );
            Udesk.ajax.del(url).then(
                resp => {
                    // this.actions.getTableData(params);
                    this.actions.reloadAsyncModel();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        cancel() { },
        batchDelete() {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `conceptWords/delete`,
                sdkOptions
            );
            let params = {
                ids: this.privates.selectedItems.map(i => i.id),
            };
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    resp => {
                        this.actions.reloadAsyncModel();
                        let failedCount = resp.data.failed;
                        let successCount = resp.data.success;
                        this.privates.selectedItems = [];
                        resolve({
                            failedCount,
                            successCount
                        });
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );    
            });
        },
        onCheckChange(record, selected, selectedRows, nativeEvent) {
            let newSelectedItems = [...this.privates.selectedItems];
            if(selected){
                newSelectedItems.push(record);
            }else{
                newSelectedItems = newSelectedItems.filter(item => item.id !== record.id);
            }
            this.privates.selectedItems = newSelectedItems;
            this.actions.update();
        },
        onSelectAll(selected, selectedRows, changeRows) {
            let newSelectedItems = [...this.privates.selectedItems];
            if(selected){
                newSelectedItems = [...newSelectedItems, ...changeRows];
            }else{
                newSelectedItems = newSelectedItems.filter(item => !changeRows.find(r => r.id === item.id));
            }
            this.privates.selectedItems = newSelectedItems;
            this.actions.update();
        },
        //#endregion
    };
    //#region Life Cycle
    componentDidMount() { }
    componentWillUnmount() { }
    //#endregion
}
function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
export default ConceptWordComponent;
