import React, { FC, useCallback, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import { Form, Spin, Space, Button } from 'udesk-ui';
import styled from 'styled-components';
import FieldItem from './components/field-item';
import { getCallServiceSummaryFields } from 'src/api/callServiceSummaryFields';
import UdeskLocales from 'UdeskLocales';
import { Field } from './const';
import { Enable } from 'src/pages/tasks/manage/template/automatic-add/business-summary/components/Field/const';
import { RefreshOutlined } from '@udesk/icons';

const Wrapper = styled.div`
    height: 100%;
    padding: 16px 16px 0;
    overflow-y: auto;
    position: relative;

    .udesk-qa-ui-spin-nested-loading,
    .udesk-qa-ui-spin-container {
        position: static;
    }

    .udesk-qa-ui-form-item {
        margin-bottom: 12px;
    }
`;

const Label = styled.div`
    font-weight: bold;
`;

const Operation = styled.div`
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    height: 40px;
    background-color: #fff;
`;

type BusinessSummaryProps = {
    callId: number;
    taskId?: number;
};

const BusinessSummary: FC<BusinessSummaryProps> = (props) => {
    const { callId, taskId } = props;

    const [form] = Form.useForm();

    const [fields, setFields] = useState<Field[]>([]);

    const { loading: getFieldsLoading } = useRequest(
        () => getCallServiceSummaryFields({ params: { taskId: taskId! } }),
        {
            ready: !!taskId,
            onSuccess: (res) => {
                setFields(
                    (res.data || []).map((f) => {
                        return {
                            ...f,
                            fieldName: `f-${f.id}`,
                        };
                    })
                );
            },
        }
    );

    const feildRefs = useRef<any[]>([]);

    const getRef = (ref) => {
        if (ref?.id) {
            feildRefs.current = [...feildRefs.current.filter((r) => r.id !== ref.id), ref];
        }
    };

    const [isEdit, setIsEdit] = useState(false);

    const onFinish = useCallback(() => {
        Promise.all(feildRefs.current.map((r) => r?.putData())).finally(() => {
            setIsEdit(false);
        });
    }, []);

    const [showEdit, setShowEdit] = useState(false);

    const checkEditShow = () => {
        const summary = form.getFieldsValue();
        const show = fields.some((f) => !!summary?.[f.fieldName]);
        setShowEdit(show);
    };

    const [, forceUpdate] = useState<number>(0);
    const updateView = () => {
        forceUpdate((n) => n + 1);
    };

    return (
        <Wrapper>
            <Spin spinning={getFieldsLoading}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    style={{ flex: 1 }}
                    layout="vertical"
                    size="small"
                >
                    {fields.map((f) => {
                        const ref = feildRefs.current.find((ref) => ref.id === f.id);

                        return f.isEnable === Enable.OPEN ? (
                            <Form.Item
                                key={f.id}
                                name={f.fieldName}
                                label={
                                    <Space>
                                        <Label>{f.name}</Label>
                                        {ref?.getDataContent?.() ? (
                                            <Button
                                                type="link"
                                                size="small"
                                                icon={<RefreshOutlined />}
                                                onClick={() => {
                                                    ref?.genData();
                                                }}
                                            >
                                                {/* 重新生成 */}
                                                {
                                                    UdeskLocales['current'].components
                                                        .businessSummary.index.regenerate
                                                }
                                            </Button>
                                        ) : null}
                                    </Space>
                                }
                            >
                                <FieldItem
                                    callId={callId}
                                    taskId={taskId}
                                    field={f}
                                    isEdit={isEdit}
                                    forwardRef={getRef}
                                    checkEditShow={checkEditShow}
                                    updateView={updateView}
                                />
                            </Form.Item>
                        ) : null;
                    })}
                </Form>

                {showEdit ? (
                    <Operation>
                        <Space>
                            {isEdit ? (
                                <>
                                    <Button size="small" onClick={() => setIsEdit(false)}>
                                        {/* 取消 */}
                                        {
                                            UdeskLocales['current'].components.sessionInsights.index
                                                .cancel
                                        }
                                    </Button>
                                    <Button
                                        size="small"
                                        type="primary"
                                        onClick={() => form.submit()}
                                    >
                                        {/* 确定 */}
                                        {
                                            UdeskLocales['current'].components.sessionInsights.index
                                                .determine
                                        }
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    onClick={() => setIsEdit(true)}
                                    size="small"
                                    type="primary"
                                    style={{ float: 'right' }}
                                >
                                    {/* 编辑 */}
                                    {UdeskLocales['current'].components.sessionInsights.index.edit}
                                </Button>
                            )}
                        </Space>
                    </Operation>
                ) : null}
            </Spin>
        </Wrapper>
    );
};

export default BusinessSummary;
