import { useMemo } from 'react';
import UdeskLocales from 'UdeskLocales';

export const DimensionalityType = {
    SesstionSummary: 1,
    SesstionFlow: 2,
    Oppotunity: 3,
};

type UseDimensionalityPorps = {
    type?: number;
};

export const useDimensionality = (props: UseDimensionalityPorps) => {
    const { type } = props;

    const defaultPromptMap = useMemo(() => {
        // no-locale-translate
        return {
            SesstionSummary: '根据以上对话内容生成会话小结',
            SesstionFlow: '根据以上对话内容生成会话流程',
            Oppotunity: '根据以上对话内容生成机会点',
        };
    }, []);

    const { dimension, defaultPrompt } = useMemo(() => {
        switch (type) {
            case DimensionalityType.SesstionSummary:
                return {
                    dimension:
                        /* 会话小结 */ UdeskLocales['current'].pages.gong.businessConfiguration
                            .largeModelGeneration.sessionsInsight.components.hooks
                            .conversationSummary,
                    defaultPrompt: defaultPromptMap.SesstionSummary,
                };

            case DimensionalityType.SesstionFlow:
                return {
                    dimension:
                        /* 会话流程 */ UdeskLocales['current'].pages.gong.businessConfiguration
                            .largeModelGeneration.sessionsInsight.components.hooks
                            .conversationProcess,
                    defaultPrompt: defaultPromptMap.SesstionFlow,
                };

            case DimensionalityType.Oppotunity:
                return {
                    dimension:
                        /* 机会点 */ UdeskLocales['current'].pages.gong.businessConfiguration
                            .largeModelGeneration.sessionsInsight.components.hooks
                            .opportunityPoints,
                    defaultPrompt: defaultPromptMap.Oppotunity,
                };

            default:
                return {
                    dimension: '',
                    defaultPrompt: '',
                };
        }
    }, [
        defaultPromptMap.Oppotunity,
        defaultPromptMap.SesstionFlow,
        defaultPromptMap.SesstionSummary,
        type,
    ]);

    return {
        dimension,
        defaultPrompt,
        defaultPromptMap,
    };
};
