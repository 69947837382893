// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostKnowledgeDetailImportDataBody, BaseResponseVoid } from '../../types';

/**
 * importKnowledgeData
 *
 * @export
 * @tags 知识点/流程API
 * @link [POST] /knowledgeDetail/importData
 */
export function postKnowledgeDetailImportData(
    data: PostKnowledgeDetailImportDataBody
): Promise<BaseResponseVoid>;
export function postKnowledgeDetailImportData(
    data: PostKnowledgeDetailImportDataBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postKnowledgeDetailImportData(
    data: PostKnowledgeDetailImportDataBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostKnowledgeDetailImportDataBody, never, never>('/knowledgeDetail/importData', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postKnowledgeDetailImportData',
    });
}

export const meta = [
    { tags: ['知识点/流程API'], path: '/knowledgeDetail/importData', method: 'post' },
];
