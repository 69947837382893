// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PutUsersByIdParam, PutUsersByIdBody, BaseResponseVoid } from '../../types';

/**
 * update
 *
 * @export
 * @tags 用户
 * @link [PUT] /users/{id}
 * @param id
 */
export function putUsersById(
    data: PutUsersByIdBody,
    options: ApiOptions<PutUsersByIdParam, never> & { segments: PutUsersByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutUsersByIdBody, PutUsersByIdParam, never>('/users/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putUsersById',
    });
}

export const meta = [{ tags: ['用户'], path: '/users/{id}', method: 'put' }];
