import { MarkerType } from 'reactflow';

export const markerEnd = {
    type: MarkerType.ArrowClosed,
};

export const lineStyle = {
    strokeWidth: 3,
    color: 'rgba(0, 0, 0, 0.2)',
};
