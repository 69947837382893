import React, { useState, useEffect } from 'react';
import { Tree, Icon, Space, Dropdown } from 'udesk-ui';
import './style.scss';

function FilesTree(props) {
    const [treeData, setTreeData] = useState(props.treeData);
    const handlerActions = (e) => {
        e.stopPropagation();
    };
    const handlerAdd = (nodeData, e) => {
        props.handlerAdd && props.handlerAdd(nodeData);
        e.stopPropagation();
    };
    const titleRender = (nodeData) => {
        return (
            <div className='files-tree-title' key={nodeData.id}>
                <p>{nodeData.title}</p>
                {!props.isAction ? (
                    <div className='files-tree-actions'>
                        <Space style={{display: 'flex'}}>
                            {nodeData.parentId === 0 ? null : (
                                <Dropdown overlay={props.handlerMenuAction(nodeData)}>
                                    <span onClick={handlerActions}>
                                        <Icon style={{ fontSize: 20, display: 'flex', alignItems: 'center' }} type='EllipsisOutlined' antdIcon={true} />
                                    </span>
                                </Dropdown>
                            )}
                            <span onClick={handlerAdd.bind(null, nodeData)}>
                                <Icon style={{ fontSize: 20, color: '#1A6EFF', display: 'flex', alignItems: 'center' }} type='add-jia'></Icon>
                            </span>
                        </Space>
                    </div>
                ) : null}
            </div>
        );
    };
    const handlerTreeNodes = (selectedKeys, { selected: bool, selectedNodes, node, event }) => {
        props.handlerTreeNodes &&
            props.handlerTreeNodes(selectedKeys, { selected: bool, selectedNodes, node, event }, props.isAction);
    };
    const handlerTreeExpand = (expandedKeys, { expanded: bool, node }) => {
        props.handlerTreeExpand && props.handlerTreeExpand(expandedKeys, { expanded: bool, node }, props.isAction);
    };
    useEffect(() => {
        setTreeData(props.treeData);
    }, [props.treeData]);
    return (
        <div className='common-component-files-tree-container'>
            {treeData.map((item) => {
                return (
                    <React.Fragment key={item.key}>
                        {props.isAction ? (
                            <Tree
                                autoExpandParent
                                onSelect={handlerTreeNodes}
                                blockNode={true}
                                selectedKeys={item.selectedKeys}
                                key={item.key}
                                treeData={[item]}
                                titleRender={titleRender}
                            />
                        ) : (
                            <Tree
                                onSelect={handlerTreeNodes}
                                onExpand={handlerTreeExpand}
                                blockNode={true}
                                key={item.key}
                                selectedKeys={item.selectedKeys}
                                expandedKeys={item.expandedKeys}
                                treeData={[item]}
                                titleRender={titleRender}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default FilesTree;
