import React, { useEffect, useRef, useState } from 'react';
// import Locales from 'UdeskLocales';
// import Udesk from 'Udesk';
import { Button, Icon } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const StandardScriptWrap = styled.div`
    background: #f5f6f8;
    border-radius: 2px;
    padding: 12px 16px;

    .standard-script-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .udesk-qa-ui-btn {
            display: flex;
            align-items: center;
            .anticon {
                margin-left: 4px !important;
            }
        }
    }
    .standard-script-content {
        white-space: break-spaces;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        -webkit-line-clamp: ${(props) => props.clamp};
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const StandardScript = (props) => {
    // const locales = Locales['current'];

    const { showDetail, ellipsis, words } = props;
    const standardScriptWrapRef = useRef(null);
    const standardScriptContentRef = useRef(null);
    const [clamp, setClamp] = useState(1);

    useEffect(() => {
        if (ellipsis && standardScriptWrapRef.current && standardScriptContentRef.current) {
            const height =
                parseInt(window.getComputedStyle(standardScriptWrapRef.current).height, 10) -
                12 * 2 - // padding
                24 - // title
                8; // title margin bottom
            const innerHeight = parseInt(
                window.getComputedStyle(standardScriptContentRef.current).height,
                10
            );

            const lineHeight = parseInt(
                window.getComputedStyle(standardScriptContentRef.current).lineHeight,
                10
            );
            if (innerHeight < height) {
                const newClamp = Math.floor(height / lineHeight);
                if (newClamp !== clamp) {

                    setClamp(Math.floor(height / lineHeight));
                }
            }
        }
    });

    return (
        <StandardScriptWrap ref={standardScriptWrapRef} clamp={ellipsis ? clamp : 'none'}>
            <div className="standard-script-title">
                <div>{/* 标准话术 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.standardScript.standardScript}</div>
                {typeof showDetail === 'function' && (
                    <Button type="link" size="small" onClick={showDetail}>
                        <span>{/* 我的话术 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.standardScript.myScript}</span>
                        <Icon type="ic-arrow-right-s-youjiantou" />
                    </Button>
                )}
            </div>
            <div className="standard-script-content" ref={standardScriptContentRef}>
                {words}
            </div>
        </StandardScriptWrap>
    );
};
