// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostWeComSessionLogTypeSearchBody,
    BaseResponseWeComSessionLogWrapper,
} from '../../../types';

/**
 * searchByType
 *
 * @export
 * @tags 企微会话
 * @link [POST] /weComSessionLog/type/search
 */
export function postWeComSessionLogTypeSearch(
    data: PostWeComSessionLogTypeSearchBody
): Promise<BaseResponseWeComSessionLogWrapper>;
export function postWeComSessionLogTypeSearch(
    data: PostWeComSessionLogTypeSearchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseWeComSessionLogWrapper>;
export function postWeComSessionLogTypeSearch(
    data: PostWeComSessionLogTypeSearchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseWeComSessionLogWrapper> {
    return request<PostWeComSessionLogTypeSearchBody, never, never>(
        '/weComSessionLog/type/search',
        { ...options, data, method: 'post', __$requestCalleeName: 'postWeComSessionLogTypeSearch' }
    );
}

export const meta = [{ tags: ['企微会话'], path: '/weComSessionLog/type/search', method: 'post' }];
