// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostInspectionTasksSaveAsBody,
    BaseResponseInspectionTaskFoundResponse,
} from '../../types';

/**
 * saveAs
 *
 * @export
 * @tags 质检任务
 * @link [POST] /inspectionTasks/saveAs
 */
export function postInspectionTasksSaveAs(
    data: PostInspectionTasksSaveAsBody
): Promise<BaseResponseInspectionTaskFoundResponse>;
export function postInspectionTasksSaveAs(
    data: PostInspectionTasksSaveAsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseInspectionTaskFoundResponse>;
export function postInspectionTasksSaveAs(
    data: PostInspectionTasksSaveAsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseInspectionTaskFoundResponse> {
    return request<PostInspectionTasksSaveAsBody, never, never>('/inspectionTasks/saveAs', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postInspectionTasksSaveAs',
    });
}

export const meta = [{ tags: ['质检任务'], path: '/inspectionTasks/saveAs', method: 'post' }];
