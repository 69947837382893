/* originArr必须为数组,arg可以为任意值,如果arg在originArr中存在则不添加*/
function addObject(originArr,...args){
    //只取一个值
    var arg1 = args[0];
    if(!originArr || !Array.isArray(originArr) ){
        window.console.warn('第一个参数应为数组');
        return originArr;
    }

    if(originArr.indexOf(arg1) > -1){
        return originArr;
    }else{
        return originArr.push(arg1);
    }
}
/** 
 * 为和原方法保持相同逻辑，需要判断参数个数
 * filterBy(originArr)返回originArr
 * filterBy(originArr,arg1)  arrItem[arg1]的真假
 * filterBy(originArr,arg1,arg2)  arrItem[arg1] === arg2
 * 如果有arg3...等表现同只有arg1  //表现很奇怪
 * */
function filterBy(originArr,...args){
    if(!originArr || !Array.isArray(originArr) ){
        window.console.warn('第一个参数应为数组');
        return originArr;
    }

    if(args.length === 0){
        return originArr;
    }

    if(args.length === 2){
        let arg1 = args[0];
        let arg2 = args[1];
        return originArr.filter(item=>{
            return item[arg1] === arg2;
        });
    }

    let arg1 = args[0];
    return originArr.filter(item=>{
        return item[arg1];
    });
}

function findBy(originArr,...args){
    if(!originArr || !Array.isArray(originArr) ){
        window.console.warn('第一个参数应为数组');
        return originArr;
    }

    if(args.length === 0){
        return originArr;
    }

    if(args.length === 2){
        let arg1 = args[0];
        let arg2 = args[1];
        return originArr.find(item=>{
            return item[arg1] === arg2;
        });
    }

    let arg1 = args[0];
    return originArr.find(item=>{
        return item[arg1];
    });
}
/**
 * 
 * @param {*} originArr 
 * @param  {...any} args 只取args[0],其他多余参数忽略
 */
function mapBy(originArr,...args){
    if(!originArr || !Array.isArray(originArr) ){
        window.console.warn('第一个参数应为数组');
        return originArr;
    }

    if(args.length === 0){
        return originArr;
    }

    let emptyArr = [];

    if(args.length >=1){
        let arg1 = args[0];
        originArr.every(item=>{
            emptyArr.push(item[arg1]);
            return item;
        });
    }

    return emptyArr;
}


//没做好
/**
 * 
 * @param {*} originArr 
 * @param  {...any} args 
 */
function sortBy(originArr,...args){
    if(!originArr || !Array.isArray(originArr) ){
        window.console.warn('第一个参数应为数组');
        return originArr;
    }

    if(args.length === 0){
        return originArr;
    }

    let newArr = originArr.concat();
    //实现和原来一样效果，需要翻转下参数顺序
    args.reverse();
    let argsLen = args.length ;
   
    for(let i=0;i<argsLen;i++){

        let noKeyArr = [];
        let hasKeyArr = [];
        newArr.map(item=>{
            if(Object.prototype.hasOwnProperty.call(item,args[i])){
                hasKeyArr.push(item);
            }else{
                noKeyArr.push(item);
            }
            return item;
        });

        let falseValArr = [];
        let trueValArr = [];
        hasKeyArr.map(item=>{
            if(item[args[i]]!==0&&!item[args[i]]){
                falseValArr.push(item);
            }else{
                trueValArr.push(item);
            }
            return item;
        });

        trueValArr = trueValArr.sort(
            function(value1,value2){
                if(value1[args[i]] < value2[args[i]]){
                    return -1;
                }else if(value1[args[i]] > value2[args[i]]){
                    return 1;
                }else{
                    return 0;
                }
            }
        );
        newArr = noKeyArr.concat(falseValArr,trueValArr);
    }

    return newArr; 
}

/**
 * 
 * @param {*} originArr 
 * @param  {...any} args 
 */
function removeObject(originArr,...args){
    if(!originArr || !Array.isArray(originArr) ){
        window.console.warn('第一个参数应为数组');
        return originArr;
    }

    if(args.length === 0){
        return originArr;
    }

    let arg1 = args[0];
    return originArr.filter(item=>{
        return item!==arg1;
    });
}


/**
 * 
 * @param {*} originArr 
 * @param  {...any} args 
 */
function uniqBy(originArr,...args){
    if(!originArr || !Array.isArray(originArr) ){
        window.console.warn('第一个参数应为数组');
        return originArr;
    }

    if(args.length === 0){
        return originArr;
    }

    let prevKeyArr = [];

    let arg1 = args[0];
    return originArr.filter(item=>{
        let key = item[arg1];
        if(prevKeyArr.indexOf(key) < 0 ){
            prevKeyArr.push(key);
            return true;
        }
        return false;
    });
}


export default { 
    addObject,
    filterBy,
    findBy,
    mapBy,
    sortBy,
    removeObject,
    uniqBy,
};