import React, { useCallback, useContext, useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs, Badge } from 'udesk-ui';
import Locales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { useEffect } from 'react';
import { MsgCenterContext } from 'src/pages/home';
import { fire } from 'src/util/core';
import './style.scss';

const { TabPane } = Tabs;

function CollectionDataAdd(props) {
    const [count, setCount] = useState(0);
    const getUnreadCount = useContext(MsgCenterContext);
    const locales = Locales['current'];
    const ADD_TYPES = [
        {
            pathName: 'adminMsgCenterReply',
            key: 'reply',
            text: locales.components.pages.messageCenter.replyTitle,
            id: '/reply',
        },
        {
            pathName: 'adminMsgCenterAnswer',
            key: 'answer',
            text: locales.components.pages.messageCenter.answerTitle,
            id: '/answer',
        },
        {
            pathName: 'adminMsgCenterShare',
            key: 'share',
            text: locales.components.pages.messageCenter.shareTitle,
            id: '/share',
        },
        {
            pathName: 'adminMsgCenterSubscription',
            key: 'subscription',
            text: locales.pages.gong.msgCenter.index.subscriptionDynamics,
            id: '/subscription',
        },
        {
            pathName: 'adminMsgCenterStudy',
            key: 'study',
            text: /* 学习提醒 */locales.pages.gong.msgCenter.index.learningReminder,
            id: '/study',
        },
    ];
    const [activeKey, setActiveKey] = useState<'reply' | 'answer' | 'share' | 'subscription' | 'study'>('reply');
    const onAddTypeChange = (activeKey) => {
        setActiveKey(activeKey);
        const tab: any = ADD_TYPES.find((t) => t.key === activeKey) ?? {};
        const routeOptions = {
            history: props.history,
            routeName: tab.pathName,
            pathParams: { taskId: props.match.params.taskId },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const updateStudyCount = useCallback(() => {
        fire(getUnreadCount)?.then(data => {
            setCount(data.STUDY);
        });
    }, []);

    useEffect(() => {
        updateStudyCount();
    }, []);

    useEffect(() => {
        const currentPath = ADD_TYPES.find((item) => props.history.location.pathname.indexOf(item.id) >= 0);
        setActiveKey(currentPath?.key ?? ('reply' as any));
    }, [props.history.location]);
    return (
        <Page
            pageBodyClassName='client-center-root-page-index'
            title={locales.menu.admin.msgCenter.text}
            padding={true}
            footer={
                <Tabs activeKey={activeKey} onChange={onAddTypeChange}>
                    {props.routes.map((item, i) => {
                        const tab: any = ADD_TYPES.find((t) => t.pathName === item.name) ?? {};
                        if (tab.hidden) return null;
                        return (
                            <TabPane tab={
                                tab.key === 'study' 
                                    ? (
                                        <Badge count={count} offset={[21, 0]}>
                                            {tab.text}
                                        </Badge> 
                                    ) 
                                    : tab.text
                            } key={tab.key}>
                            </TabPane>
                        );
                    })}
                </Tabs>
            }
        >
            <SubRoutes
                childProps={{
                    updateStudyCount
                }}
                route={props.routes.find((r) => r.name === ADD_TYPES.find((i) => i.key === activeKey)!.pathName)}
            />
        </Page>
    );
}

export default React.memo(CollectionDataAdd);
