// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostKnowledgeDetailExpQuery,
    PostKnowledgeDetailExpBody,
    BaseResponseVoid,
} from '../../types';

/**
 * 下载知识点
 *
 * @export
 * @tags 知识点/流程API
 * @link [POST] /knowledgeDetail/exp
 */
export function postKnowledgeDetailExp(
    data: PostKnowledgeDetailExpBody,
    options: ApiOptions<never, PostKnowledgeDetailExpQuery> & {
        params: PostKnowledgeDetailExpQuery;
    }
): Promise<BaseResponseVoid> {
    return request<PostKnowledgeDetailExpBody, never, PostKnowledgeDetailExpQuery>(
        '/knowledgeDetail/exp',
        { ...options, data, method: 'post', __$requestCalleeName: 'postKnowledgeDetailExp' }
    );
}

export const meta = [{ tags: ['知识点/流程API'], path: '/knowledgeDetail/exp', method: 'post' }];
