import Udesk from 'Udesk';
import { useCallback } from "react";
import { linkTo } from "src/util/link";
import { getCurrentRouteName } from "Udesk/system/subApp";
import { fire } from 'src/util/core';

export const getGraspSituation = (messageList: any[]) => {
    return getScore(messageList, (score, totalScore, isGrasp) => isGrasp);
};

export const getScore = (messageList: any[], callback) => {
    let score = 0;
    let totalScore = 0;

    if (messageList) {
        let scoreRuleResult = new Array<number>();
        let list = messageList.filter(item => !!item.traineeWordsResult);
        if (list.length > 0) {
            list.forEach(item => {
                const {traineeWordsResult} = item;
                const {
                    isEnableQuestionClosely,
                    isQuestionCloselyScore,
                    resultAnalysis,
                    wordsTotalPoints, 
                    knowLedgeScoreResult
                } = traineeWordsResult;

                if (knowLedgeScoreResult) {
                    if (knowLedgeScoreResult.isHit) {
                        score += knowLedgeScoreResult.score;
                    }
                    totalScore += (isEnableQuestionClosely && isQuestionCloselyScore) ? 0 : knowLedgeScoreResult.totalScore;
                } else {
                    // 话术得分, 通过缓存解决追问的问题
                    if (!(isEnableQuestionClosely && isQuestionCloselyScore)) {
                        scoreRuleResult =  new Array<number>();
                    }
                    const scoreRuleResultList = resultAnalysis.scoreRuleResultList;
                    if (scoreRuleResultList) {
                        scoreRuleResultList.forEach((item) => {
                            if (item.hit) {
                                if (!scoreRuleResult.includes(item.id)) {
                                    score += item.score;
                                    scoreRuleResult.push(item.id);
                                }
                            }
                        });
                    }
                    // 话术总分
                    totalScore += (isEnableQuestionClosely && isQuestionCloselyScore) ? 0 : wordsTotalPoints;
                }
            });
            return fire(callback, score, totalScore, score === totalScore);
        }
    }
    
    return fire(callback, score, totalScore, undefined);
};

export const getTaskScoreData = (wrongQuestionSet: any[]) => {
    return wrongQuestionSet.map(item => {
        let grasp = !!item.isAbsorb;
        if (item.messageList) {
            grasp = !!(getGraspSituation(item.messageList) && !item.cue);
        }
        return {
            key: item.id,
            name: item.nodeName,
            times: item.exerciseTimes,
            grasp
        };
    });
};

export const useCancelTask = (history, request, wrongQuestionSet) => {
    return useCallback(() => {
        const dataSource = getTaskScoreData(wrongQuestionSet);
        if (dataSource.length > 0) {
            request(
                '/intelligentPartnerMistakeCollections/finish', 
                dataSource.map(item => {
                    return {
                        id: item.key,
                        isAbsorb: item.grasp ? 1: 0,
                        exerciseTimes: item.times
                    };
                }),
                'post'
            ).then(
                resp => {
                    linkTo(history, getCurrentRouteName('wrongQuestionSetList'));
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        } else {
            linkTo(history, getCurrentRouteName('wrongQuestionSetList'));
        }
    }, [history, request, wrongQuestionSet]);
};