// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { PutInspectionRuleApprovesConfigBody, BaseResponseVoid } from '../../../types';

/**
 * 开启或者关闭自动审批
 *
 * @export
 * @tags 质检规则审核
 * @link [PUT] /inspectionRule/approves/config
 */
export function putInspectionRuleApprovesConfig(
    data: PutInspectionRuleApprovesConfigBody
): Promise<BaseResponseVoid>;
export function putInspectionRuleApprovesConfig(
    data: PutInspectionRuleApprovesConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putInspectionRuleApprovesConfig(
    data: PutInspectionRuleApprovesConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutInspectionRuleApprovesConfigBody, never, never>(
        '/inspectionRule/approves/config',
        { ...options, data, method: 'put', __$requestCalleeName: 'putInspectionRuleApprovesConfig' }
    );
}

export const meta = [
    { tags: ['质检规则审核'], path: '/inspectionRule/approves/config', method: 'put' },
];
