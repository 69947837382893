import Udesk from 'Udesk';
import SpeechRecognitionComponent from './component';

class SpeechRecognitionRoute extends SpeechRecognitionComponent {
    model() {
        let { sdkOptions } = this.props;

        let url = Udesk.business.apiPath.concatApiPath('asr-filter-config', sdkOptions);
        let dataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        url = Udesk.business.apiPath.concatApiPath('/field/data/1?type=associate', sdkOptions);
        let fieldPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        // 获取ASR预览列表
        let asrLanguageUrl = Udesk.business.apiPath.concatApiPath(
            '/asr-filter-config/asr/language',
            sdkOptions
        );
        let asrLanguagePromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(asrLanguageUrl).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        return {
            data: dataPromise,
            field: fieldPromise,
            asrLanguage: asrLanguagePromise,
        };
    }

    parseModel(model) {
        console.log('model', model);
        let { privates } = this;
        model.data.forEach((item) => {
            if (
                item.type === Udesk.enums.speechRecognitionSwitchType.asr.id &&
                item.secondaryType === Udesk.enums.speechRecognitionSecondaryType.onOff.id
            ) {
                privates.asrSwitch = item.activeFlag === 1;
            } else if (
                item.type === Udesk.enums.speechRecognitionSwitchType.correction.id &&
                item.secondaryType === Udesk.enums.speechRecognitionSecondaryType.onOff.id
            ) {
                privates.correctionSwitch = item.activeFlag === 1;
            } else if (
                item.type === Udesk.enums.speechRecognitionSwitchType.asr.id &&
                item.secondaryType === Udesk.enums.speechRecognitionSecondaryType.config.id
            ) {
                privates.asrConfigSwitch = item.activeFlag === 1;
                privates.asrConfigId = item.id;
                if (item.filter.conditionList != null)
                    privates.asrConfig.conditionList = item.filter.conditionList;
                if (item.filter.judgeStrategy != null)
                    privates.asrConfig.judgeStrategy = item.filter.judgeStrategy;
                if (item.filter.customJudgeLogic != null)
                    privates.asrConfig.customJudgeLogic = item.filter.customJudgeLogic;
            } else if (
                item.type === Udesk.enums.speechRecognitionSwitchType.correction.id &&
                item.secondaryType === Udesk.enums.speechRecognitionSecondaryType.config.id
            ) {
                privates.correctionConfigSwitch = item.activeFlag === 1;
                privates.correctionConfigId = item.id;
                if (item.filter.conditionList != null)
                    privates.correctionConfig.conditionList = item.filter.conditionList;
                if (item.filter.judgeStrategy != null)
                    privates.correctionConfig.judgeStrategy = item.filter.judgeStrategy;
                if (item.filter.customJudgeLogic != null)
                    privates.correctionConfig.customJudgeLogic = item.filter.customJudgeLogic;
            } else if (
                item.type === Udesk.enums.speechRecognitionSwitchType.asr.id &&
                item.secondaryType === Udesk.enums.asrDataFilterSecondaryType.default.id
            ) {
                privates.defaultAsrLanguage = item.filter.languageType || 0;
                privates.defaultAsrLanguageObj = item;
            } else if (
                item.type === Udesk.enums.speechRecognitionSwitchType.asr.id &&
                item.secondaryType === Udesk.enums.asrDataFilterSecondaryType.filter.id &&
                item.filter
                // Object.values(this.privates.asrModelMap).includes(item.secondaryType)
            ) {
                const {
                    conditionList = [],
                    judgeStrategy = 1,
                    customJudgeLogic = '',
                } = item.filter;
                this.privates.asrModelNew.push({
                    ...item.filter,
                    id: item.id,
                    conditionList,
                    judgeStrategy,
                    customJudgeLogic,
                });
                // Object.entries(this.privates.asrModelMap).some(([key, value]) => {
                //     if (value === item.secondaryType) {
                //         const {
                //             conditionList = [],
                //             judgeStrategy = 1,
                //             customJudgeLogic = '',
                //         } = item.filter;
                //         this.privates.asrModel[key] = {
                //             ...item.filter,
                //             conditionList,
                //             judgeStrategy,
                //             customJudgeLogic,
                //         };
                //     }
                //     return value === item.secondaryType;
                // });
            }
        });
        privates.asrModelSwitch = model.data
            .filter((item) => Object.values(this.privates.asrModelMap).includes(item.secondaryType))
            .some((item) => item.activeFlag);
        privates.fieldList = model.field;
        privates.asrBackup.asrConfigSwitch = privates.asrConfigSwitch;
        privates.asrBackup.asrConfig = Udesk.utils.object.deepCopy(privates.asrConfig);
        privates.correctionBackup.correctionConfigSwitch = privates.correctionConfigSwitch;
        privates.correctionBackup.correctionConfig = Udesk.utils.object.deepCopy(
            privates.correctionConfig
        );
        privates.canSelectAsrLanguage = getCanSelectAsrLanguageList(
            model.asrLanguage,
            this.privates.asrModelNew
        );
        privates.asrLanguageList = Udesk.utils.object.deepCopy(model.asrLanguage || []);
        // privates.asrLanguageList.unshift({ desc: '使用默认', value: 0 });
        return {};
    }
}

function getCanSelectAsrLanguageList(allList, asrModelConfig) {
    let usedArr = asrModelConfig.map((item) => {
        return item.languageType && item.languageType;
    });
    // let res = [];
    allList.reduce((total = [], pre) => {
        if (!usedArr.includes(pre.value)) {
            console.log(pre, total);
            pre.disabled = false;
        } else {
            pre.disabled = true;
        }
        return [];
    }, []);
    console.log('getCanSelectAsrLanguageList', usedArr, allList);
    return Udesk.utils.object.deepCopy(allList) || [];
}

// function getDisplayConditionList(conditionList) {
//     return conditionList.map(item => {
//         item.field = item.field.id;
//         return item;
//     });
// }

export default SpeechRecognitionRoute;
