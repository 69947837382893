import Udesk from 'Udesk';
import React from 'react';
import moment from 'moment';

export default class UdeskMenuBarSdkComponent extends React.Component {
    //#region state
    state = {
        releaseVersionUrl: '',
        menuBarSdkJsUrl: '',
        menuBarSdkCssUrl: '',
        udeskCaseReactInstance: null,
        sdkLoaderInstance: null,
    };
    //#endregion

    //#region Computed properties
    static computes = {
        currentSdkUrl: function ({ props, state, privates, locales }) {
            return 'https://sgo-assets.udesk.cn/menu-bar';
        },
    };
    //#endregion

    //#region Life Cycle
    model() {
        let currUser = Udesk.data.init.user.email;
        let ticketSdkConfig = new Promise((resolve, reject) => {
            Udesk.ajax
                .get(
                    Udesk.business.apiPath.concatApiPath('/sign/ticket_sdk', this.props.sdkOptions)
                )
                .then(
                    (resp) => resolve(resp.data),
                    (reason) => Udesk.ui.notify.error(reason.errorMsg)
                );
        });
        let imSdkConfig = new Promise((resolve, reject) => {
            Udesk.ajax
                .post(Udesk.business.apiPath.concatApiPath('/sign/web_im', this.props.sdkOptions), {
                    webToken: currUser,
                })
                .then(
                    (resp) =>
                        resolve(
                            Object.assign(
                                {
                                    targetSelector: '#start-chat-btn',
                                    panel: { css: { top: '62px' } },
                                },
                                resp.data
                            )
                        ),
                    (reason) => Udesk.ui.notify.error(reason.errorMsg)
                );
        });
        return {
            ticketSdkConfig,
            imSdkConfig,
        };
    }
    componentDidMount() {
        // let currentSdkUrl = this.privates.computes.currentSdkUrl;
        let releaseVersionUrl = `${window.location.origin}/RELEASE-VERSION`;
        let menuBarSdkJsUrl = `${window.location.origin}/static/sdk/udesk-qa-react-sdk.js`;
        let menuBarSdkCssUrl = `${window.location.origin}/static/sdk/udesk-qa-react-sdk.css`;

        this.setState({
            releaseVersionUrl: releaseVersionUrl,
            menuBarSdkJsUrl: menuBarSdkJsUrl,
            menuBarSdkCssUrl: menuBarSdkCssUrl,
        });
    }
    //#endregion

    //#region actions
    actions = {
        onLoaded(SdkClass) {
            SdkClass.getUserForSentry = function () {
                return {
                    id: Udesk.data.init.user.id,
                    realname: Udesk.data.init.user.realname,
                    email: Udesk.data.init.user.email,
                    mobile: Udesk.data.init.user.mobile,
                };
            };

            SdkClass.getTagsContextForSentry = function () {
                return {
                    id: Udesk.data.init.company.id,
                    logoUrl: Udesk.data.init.company.logo,
                    icon: Udesk.data.init.company.icon,
                    systemName: Udesk.data.init.company.sysName,
                };
            };

            let renderToOptions = {
                props: {
                    domain: window.location.origin,
                    isAppealCheck: true,
                    // appealStatusDisabled: true,
                    // readStatus: 1,
                    // appealCheckStatus: 1,
                    defaultDateRange: [moment().subtract(1, 'M'), moment()],
                    // language: 'cs',
                },
                methods: {
                    getToken() {
                        const token = {
                            // app_id: 'udesk',
                            // timestamp: 1663126386,
                            // signature: '150d75a62ba7214a8e8cac93639163b2b998a46e',
                            // agent: 'liyongtian@udesk.cn',
                            // timestamp: 1644991083,
                            // signature: '0c5782bc912e95cdfc4067f4d64ef2c2c3b9a329',
                            // agent: 'liyongtian@t2.tryudesk.com',
                            // agent: 'agent@udesk.cn',
                            // agent: 'admin233@udesk.cn',
                            // subdomain: 'demo.t2',
                            // type: 'SDK',
                            // timestamp: 1701746777,
                            // signature: '1b6d9f54083ef40908eeb0c62831bd39a2eb66d1',
                            // agent: 'fantt@udesk.cn',
                            ...(Udesk.data.init.sdkParams || {}),
                            app_id: Udesk.data.init.sdkParams?.appid,
                        };

                        return new Promise((resolve, reject) => {
                            resolve(token);
                        });
                    },
                    tip: (message) => {
                        console.log(message);
                    },
                    log: (message) => {
                        console.log(message);
                    },
                },
            };
            let udeskCaseReactInstance = new SdkClass(renderToOptions);
            let renderSdkContainer = document.getElementById(this.props.renderSdkContainerId);

            if (renderSdkContainer) {
                udeskCaseReactInstance.renderTo(renderSdkContainer);
                this.setState({
                    udeskCaseReactInstance: udeskCaseReactInstance,
                });
            }
        },

        onDestroying() {
            let udeskCaseReactInstance = this.state.udeskCaseReactInstance;

            if (udeskCaseReactInstance && typeof udeskCaseReactInstance.destroy === 'function') {
                udeskCaseReactInstance.destroy();
                this.setState({
                    udeskCaseReactInstance: null,
                });
            }
        },
    };
    //#endregion
}
