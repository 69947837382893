import React from 'react';

class AudioStickersTalkComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        detail: []
    };
    //#endregion

    state = {};
    privates = {
        detail: [],
        audioStickerColors: ['#BFDFFF', '#D9D9D9']
    };
    static computes = {
        _audioStickers: [
            'privates.detail',
            function({ props, privates, locales }) {
                let { detail } = privates;
                let sentenceList = [];
                if (
                    detail &&
                    detail.dialogRecords &&
                    detail.dialogRecords.dialogSentenceList &&
                    detail.dialogRecords.dialogSentenceList.length > 0
                ) {
                    sentenceList = detail.dialogRecords.dialogSentenceList;
                }
                let timeLength = detail.voiceLength;
                let agentTimes = { type: 'agent', timeLength: timeLength, times: [] };
                let customerTimes = { type: 'customer', timeLength: timeLength, times: [] };
                sentenceList.forEach(item => {
                    if (item.role === 'agent') {
                        agentTimes.times.push({
                            startPoint: item.fromSec,
                            endPoint: item.endSec
                        });
                    } else {
                        customerTimes.times.push({
                            startPoint: item.fromSec,
                            endPoint: item.endSec
                        });
                    }
                });
                let audioStickers = [agentTimes, customerTimes];
                return audioStickers;
            }
        ]
    };
    actions = {};

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {
            detail: props.detail
        };
    }
    componentDidMount() {}
    componentDidUpdate(prevProps, prevState, snapshot) {}
    componentWillUnmount() {}
    //#endregion
}

export default AudioStickersTalkComponent;
