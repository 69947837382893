import React from 'react';
import Udesk from 'Udesk';
import { Popconfirm, Button } from 'udesk-ui';
import Locales from 'UdeskLocales';

class TemplateComponentsMyComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        paging: {
            pageSize: 20,
            total: 0,
            pageNum: 1,
        },
        targetItems: [],
        tableData: [],
        keywords: '',
        releaseStatus: 0,
        loading: false,
    };

    actions = {
        search() {
            this.actions.reloadAsyncModel();
        },
        inputChange(e) {
            this.privates.keywords = e.target.value;
            this.actions.update();
        },
        reset() {
            this.privates.paging = {
                pageSize: 20,
                total: 0,
                pageNum: 1,
            };
            this.privates.keywords = '';
            this.privates.releaseStatus = 0;
            this.actions.update();
        },
        pageNumChange(pageNum) {
            this.privates.paging.pageNum = pageNum;
            this.actions.reloadAsyncModel();
        },
        pageSizeChange(pageNum, pageSize) {
            this.privates.paging.pageNum = pageNum;
            this.privates.paging.pageSize = pageSize;
            this.actions.reloadAsyncModel();
        },
        inputSearch(value) {
            this.privates.keywords = value;
            this.actions.reloadAsyncModel();
        },
        selectChange(value) {
            this.privates.releaseStatus = value;
            this.actions.update();
        },
        goDetail(id, value) {
            let routeOptions = {
                history: this.props.history,
                routeName: 'templateComponentMyDetailEdit',
                pathParams: {
                    id: id,
                },
                queryParams: {
                    version: value,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        goDetailNew() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'templateComponentMyDetailNew',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        delChange(id) {
            let { sdkOptions } = this.props;
            this.privates.loading = true;
            this.actions.update();
            let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionModules/${id}`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.del(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(Locales.current.pages.admin.templateComponent.list.delete);
                        this.privates.loading = false;
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onlineChange(id) {
            this.privates.loading = true;
            this.actions.update();
            let { sdkOptions } = this.props;
            let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionModules/${id}/offline`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.put(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(Locales.current.pages.admin.templateComponent.list.downOk);
                        this.privates.loading = false;
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        goBack(id) {
            let { sdkOptions } = this.props;
            if (id) {
                this.privates.loading = true;
                this.actions.update();
                let myDetail = Udesk.business.apiPath.concatApiPath(`inspectionModules/${id}/cancel`, sdkOptions);
                new Promise((resolve, reject) => {
                    Udesk.ajax.put(myDetail).then(
                        (resp) => {
                            resolve(resp);
                            Udesk.ui.notify.success(Locales.current.pages.admin.templateComponent.list.widthdraw);
                            this.privates.loading = false;
                            this.actions.reloadAsyncModel();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
    };

    //#region Life Cycle
    componentDidMount() {
        getColumn(this);
    }
    componentWillUnmount() {}
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'taskData') {
            this.privates.tableData = asyncResult.data;
            this.privates.paging = asyncResult.paging;
            this.actions.update();
        }
    }

    //#endregion
}
function getColumn(that) {
    let hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    let column = [
        {
            title: Locales.current.pages.admin.templateComponent.list.name,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: Locales.current.pages.admin.templateComponent.list.lastedVersion,
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: Locales.current.pages.admin.templateComponent.list.publishVersion,
            dataIndex: 'releaseVersion',
            key: 'releaseVersion',
        },
        {
            title: Locales.current.pages.admin.templateComponent.list.earliestVersion,
            dataIndex: 'firstVersion',
            key: 'firstVersion',
        },
        {
            title: Locales.current.pages.admin.templateComponent.list.appStatus,
            dataIndex: 'releaseStatus',
            key: 'releaseStatus',
            render: (text, record, index) => {
                let obj = Udesk.enums.templateComponentList.find((item) => item.id === record.releaseStatus);
                if (obj) {
                    return obj.name;
                } else {
                    return;
                }
            },
        },
        {
            title: Locales.current.pages.admin.templateComponent.list.actions,
            dataIndex: 'actions',
            key: 'actions',
            width: 216,
            render: (text, record, index) => {
                return (
                    <div>
                        <If condition={hasFeature('sys:module:my:offline')}>
                            {record.online ? (
                                <Button
                                    style={{ paddingLeft: 0 }}
                                    type='link'
                                    onClick={that.actions.onlineChange.params(record.id)}
                                >
                                    {Locales.current.pages.admin.templateComponent.list.down}
                                </Button>
                            ) : null}
                        </If>
                        <If condition={hasFeature('sys:module:my:cancel')}>
                            {record.releaseStatus === Udesk.enums.templateModuleStatus.status.id && (
                                <Button
                                    style={{ paddingLeft: 0 }}
                                    type='link'
                                    onClick={that.actions.goBack.params(record.id)}
                                >
                                    {Locales.current.pages.admin.templateComponent.list.width}
                                </Button>
                            )}
                        </If>
                        <If condition={hasFeature('sys:module:my:edit')}>
                            <Button
                                style={{ paddingLeft: !record.online && record.releaseStatus !== Udesk.enums.templateModuleStatus.status.id && 0 }}
                                type='link'
                                onClick={that.actions.goDetail.params(record.id, record.version)}
                            >
                                {Locales.current.pages.admin.templateComponent.list.edit}
                            </Button>
                        </If>
                        <If condition={hasFeature('sys:module:my:delete')}>
                            {!record.online ? (
                                <Popconfirm
                                    title={Locales.current.pages.admin.templateComponent.list.deleteWhen}
                                    onConfirm={that.actions.delChange.params(record.id)}
                                    onText={Locales.current.pages.admin.templateComponent.list.yes}
                                    cancelText={Locales.current.pages.admin.templateComponent.list.noo}
                                >
                                    <Button type='link'>
                                        {Locales.current.pages.admin.templateComponent.list.deleteOk}
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </If>
                    </div>
                );
            },
        },
    ];
    that.privates.targetItems = column;
    that.actions.update();
}

export default TemplateComponentsMyComponent;
