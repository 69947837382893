import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import { Empty, Space } from 'udesk-ui';
import SimplePieChart from 'src/pages/coach/task-center/components/SimplePieChart';
import DataCard from './components/DataCard';
import styled from 'styled-components';
import { getTotalTime, getRate } from './utils';
import { postIntelligentPartnerHomePageStudentPictureFinishStatus } from 'src/api/intelligentPartner/homePage/student/picture/finishStatus';
import { IntelligentPartnerHomePageStudentPictureFinishStatusResponse } from 'src/api/types';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    .udesk-qa-ui-space {
        width: 100%;

        .udesk-qa-ui-space-item {
            flex: 1;
        }
    }
`;

const ItemWrapper = styled.div`
    margin-bottom: 5px;
`;

type TemplateProps = {
    params?: any;
};

const Template: React.FC<TemplateProps> = (props) => {
    const { params } = props;

    const [data, setData] =
        useState<IntelligentPartnerHomePageStudentPictureFinishStatusResponse[]>();

    useRequest(() => postIntelligentPartnerHomePageStudentPictureFinishStatus(params), {
        ready: !!params?.userId && !!params?.startTime && !!params?.endTime,
        refreshDeps: [params],
        onSuccess: (res) => {
            setData(res.data || []);
        },
    });

    const isPractise = (taskType?: number) =>
        taskType === Udesk.enums.learningTaskType.notStarted.id;

    return (
        <Wrapper>
            {data?.length ? (
                data?.map((d) => {
                    return (
                        <ItemWrapper>
                            <div style={{ marginBottom: 5 }}>
                                {isPractise(d.taskType) ? /* 练习 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.doneStatus.index.practice : /* 考试 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.doneStatus.index.examination}
                            </div>

                            <div>
                                <Space>
                                    <SimplePieChart
                                        id={
                                            isPractise(d.taskType)
                                                ? 'DoneStatusPractisePie'
                                                : 'DoneStatusExamPie'
                                        }
                                        loading={false}
                                        height={140}
                                        title={`${d.finishNum ?? 0} / ${d.totalNum ?? 0}`}
                                        data={[
                                            {
                                                name: /* 已执行 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.doneStatus.index.executed,
                                                value: d.finishNum ?? 0,
                                            },
                                            {
                                                name: /* 未执行 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.doneStatus.index.unexecuted,
                                                value: (d.totalNum ?? 0) - (d.finishNum ?? 0),
                                            },
                                        ]}
                                        textFontSize={16}
                                    />

                                    <DataCard
                                        title={
                                            isPractise(d.taskType)
                                                ? /* 累计练习时长 */ UdeskLocales['current'].pages
                                                      .coach.dashboard.components.practiceSummary
                                                      .accumulatedPracticeDuration
                                                : /* 累计考试时长 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.doneStatus.index.accumulatedExamDuration
                                        }
                                        value={getTotalTime(d?.accumulatePracticeTime)}
                                        rank={`${d?.accumulatePracticeTimeRank ?? '-'}/${
                                            d?.totalUserNum ?? '-'
                                        }`}
                                    />

                                    <DataCard
                                        title={
                                            isPractise(d.taskType)
                                                ? /* 练习次数 */ UdeskLocales['current'].pages.coach
                                                      .dashboard.components.practiceSummary
                                                      .numberOfExercises
                                                : /* 考试次数 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.doneStatus.index.examFrequency
                                        }
                                        value={d?.totalPracticeTimes}
                                    />

                                    <DataCard
                                        title={
                                            /* 达标率 */ UdeskLocales['current'].pages.coach
                                                .dashboard.components.practiceSummary.complianceRate
                                        }
                                        value={getRate(d?.commitRate)}
                                        rank={`${d?.commitRateRank ?? '-'}/${
                                            d?.totalUserNum ?? '-'
                                        }`}
                                    />

                                    <DataCard
                                        title={
                                            /* 达优率 */ UdeskLocales['current'].pages.coach
                                                .dashboard.components.practiceSummary.excellentRate
                                        }
                                        value={getRate(d?.excellentRate)}
                                        rank={`${d?.excellentRateRank ?? '-'}/${
                                            d?.totalUserNum ?? '-'
                                        }`}
                                    />
                                </Space>
                            </div>
                        </ItemWrapper>
                    );
                })
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Wrapper>
    );
};

export default Template;
