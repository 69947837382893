// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetInspectionPointSystemDialogsQuery,
    BaseResponseListInspectionPointSystemDialogFoundResponse,
    PostInspectionPointSystemDialogsBody,
    BaseResponseInspectionPointSystemDialogFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 应用消息类型
 * @link [GET] /inspectionPointSystemDialogs
 */
export function getInspectionPointSystemDialogs(): Promise<BaseResponseListInspectionPointSystemDialogFoundResponse>;
export function getInspectionPointSystemDialogs(
    options: ApiOptions<never, GetInspectionPointSystemDialogsQuery>
): Promise<BaseResponseListInspectionPointSystemDialogFoundResponse>;
export function getInspectionPointSystemDialogs(
    options?: ApiOptions<never, GetInspectionPointSystemDialogsQuery>
): Promise<BaseResponseListInspectionPointSystemDialogFoundResponse> {
    return request<never, never, GetInspectionPointSystemDialogsQuery>(
        '/inspectionPointSystemDialogs',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionPointSystemDialogs' }
    );
}

/**
 * save
 *
 * @export
 * @tags 应用消息类型
 * @link [POST] /inspectionPointSystemDialogs
 */
export function postInspectionPointSystemDialogs(
    data: PostInspectionPointSystemDialogsBody
): Promise<BaseResponseInspectionPointSystemDialogFoundResponse>;
export function postInspectionPointSystemDialogs(
    data: PostInspectionPointSystemDialogsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseInspectionPointSystemDialogFoundResponse>;
export function postInspectionPointSystemDialogs(
    data: PostInspectionPointSystemDialogsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseInspectionPointSystemDialogFoundResponse> {
    return request<PostInspectionPointSystemDialogsBody, never, never>(
        '/inspectionPointSystemDialogs',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postInspectionPointSystemDialogs',
        }
    );
}

export const meta = [
    { tags: ['应用消息类型'], path: '/inspectionPointSystemDialogs', method: 'get' },
    { tags: ['应用消息类型'], path: '/inspectionPointSystemDialogs', method: 'post' },
];
