import React, { useCallback, useRef } from 'react';
import { useLiveEffect } from 'src/util/hook';
import { Empty, Timeline } from "udesk-ui";
import './index.less';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const { index, list, isLastNode } = props;
    const divRef = useRef<HTMLDivElement>(null);

    const color = useCallback((item, indexInList) => {
        let isHit = item.score === item.wordTotalScore;
        if (indexInList < index || isLastNode) {
            return isHit ? 'green' : 'red';
        }
        if (indexInList === index) {
            return 'blue';
        }
        return 'gray';
    }, [
        index, 
        isLastNode
    ]);

    useLiveEffect(() => {
        setTimeout(() => {
            const $content =  divRef.current;
            if ($content) {
                if (index >= 0) {
                    $('li:eq('+ index +') ', $content).get(0)?.scrollIntoView({
                        block: 'nearest',
                        inline:'start',
                        behavior: 'smooth',
                    });
                }
            }
        }, 200);
    }, [index]);

    return (
        <div className='flow-panel' ref={divRef}>
            <div className='flow-title'>
                <div><span>{/* 流程提示 （ */}{UdeskLocales['current'].pages.coach.learningCenter.components.flow.index.processReminder}{index + 1}/{list?.length || 0}）</span></div>
                <span>
                    <span>{/* 得分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.flow.index.score}</span><span>{/* /总分 */}{UdeskLocales['current'].pages.coach.learningCenter.components.flow.index.TotalScore}</span>
                </span>
            </div>
            <Timeline className='flow-list'>
                {
                    list ? list.map((item, indexInList) => (
                        <Timeline.Item color={color(item, indexInList)}>
                            <div className='flow-item'>
                                <div className={index === indexInList ? 'active' : ''}>{item.nodeName}</div>
                                <div className={'score'}>
                                    <span>{item.score || 0}</span><span>/{item.wordTotalScore || 0}</span>
                                </div>
                            </div>
                        </Timeline.Item>
                    )): <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </Timeline>
        </div>
    );
});

