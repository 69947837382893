
import UploadBaseAdapterClass from "./base";
import UploadOssAdapterClass from "./oss";
import UploadCosAdapterClass from "./cos";
import UploadObsAdapterClass from "./obs";
import UploadMinioAdapterClass from "./minio";
import UploadAwsAdapterClass from "./aws";

export default {
    UploadBaseAdapterClass,
    UploadOssAdapterClass,
    UploadCosAdapterClass,
    UploadObsAdapterClass,
    UploadMinioAdapterClass,
    oss: new UploadOssAdapterClass(),
    cos: new UploadCosAdapterClass(),
    obs: new UploadObsAdapterClass(),
    minio: new UploadMinioAdapterClass(),
    aws: new UploadAwsAdapterClass(),
};