import React from 'react';
import PropTypes from 'prop-types';
import _concat from 'lodash-es/concat';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';

const STORAGE_KEY_PREFIX = 'udeskQa.React-table.columns.key';

class UdeskTableComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        overlayClassName: '',
        pagination: {},
        transfer: {},
        columns: [],
        type: 'outer',
        cacheKey: '',
        inspectSearchDefaultShowFields: null,
        onColumnsChange: () => { },
        needFixedActionColumn: false,
    };
    static propTypes = {
        overlayClassName: PropTypes.string,
        pagination: PropTypes.object,
        transfer: PropTypes.shape({
            titles: PropTypes.array,
        }),
        columns: PropTypes.array,
        type: PropTypes.string,
        cacheKey: PropTypes.string,
        inspectSearchDefaultShowFields: PropTypes.array,
        onColumnsChange: PropTypes.func,
        needFixedActionColumn: PropTypes.bool,
    };
    //#endregion
    state = {
        selectedKeys: [],
        isHidden: false,
    };
    privates = {
        storageKey: '',
        targetKeys: null,
        targetItems: null,
        columnsAfterSearch: null,
        transferSearchValueLeft: '',
        transferSearchValueRight: '',
        pagination: {
            showSizeChanger: true,
            total: 0,
            pageSize: 10,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            showQuickJumper: false,
        },
        transfer: {
            listStyle: {
                width: 300,
                height: 300,
            },
            overlayClassName: 'udesk-qa-table-component-transfer',
            showSearch: true,
            titles: [
                UdeskLocales.current.components.tableComponentTransfer.leftTitle,
                UdeskLocales.current.components.tableComponentTransfer.rightTitle,
            ],
            render: (item) => item.title,
        },
        visible: false,
        visibleCount: 0,
        visibleName: null,
    };
    actions = {
        onTransferChangeWithoutSort(targetKeys) {
            this.privates.targetKeys = targetKeys;
            this.privates.targetItems = this.actions.getNewColumns(this.props, this.privates.targetKeys);
            this.actions.handleTransferSearch('right', this.privates.transferSearchValueRight);
            saveLocalStorage(this.privates.storageKey, this.privates.targetKeys);
            let that = this;
            setTimeout(() => {
                that.trigger('onColumnsChange', targetKeys);
            }, 1);
            this.actions.update();
        },
        /**
         * 穿梭框使展示的列发生变化时调用，将改变后的列抛给页面。
         * @param {*} targetKeys //新的列
         * @param {*} direction 
         * @param {*} moveKeys 
         */
        onTransferChange(targetKeys, direction, moveKeys) {            
            let targetKeysSort = [];
            if (this.privates.targetKeys.length === 0) {
                targetKeysSort = targetKeys;
            }
            if (this.privates.targetKeys.length !== 0) {
                let lastIndex = 0;
                targetKeys.reverse().forEach((key, index) => {
                    if (this.privates.targetKeys.some((element) => element === key)) {
                        targetKeysSort.unshift(key);
                        lastIndex = targetKeysSort.length;
                    } else {
                        targetKeysSort.splice(lastIndex, 0, key);
                    }
                });
            }
            this.actions.onTransferChangeWithoutSort(targetKeysSort);
        },
        onSortEnd({ oldIndex, newIndex }) {
            let targetItems = _concat([], this.privates.targetItems),
                target = targetItems.splice(oldIndex, 1);
            let targetKeys = _concat([], this.privates.targetKeys),
                target2 = targetKeys.splice(oldIndex, 1);
            targetItems.splice(newIndex, 0, target[0]);
            targetKeys.splice(newIndex, 0, target2[0]);
            this.privates.targetKeys = _concat([], targetKeys);
            this.privates.targetItems = _concat([], targetItems);
            this.privates.columnsAfterSearch = _concat([], targetItems);
            saveLocalStorage(this.privates.storageKey, this.privates.targetKeys);
            this.actions.update();
        },
        handleTransferSearch(dir, value) {
            if (dir === 'right') {
                this.privates.transferSearchValueRight = value;
                if (value) {
                    let columnsAfterSearch = this.privates.targetItems.filter(
                        (item) => item.title.indexOf(this.privates.transferSearchValueRight) !== -1
                    );
                    this.privates.columnsAfterSearch = columnsAfterSearch;
                    this.setState({
                        isHidden: true,
                    });
                } else {
                    this.privates.columnsAfterSearch = this.privates.targetItems;
                    this.setState({
                        isHidden: false,
                    });
                }
            }
            if (dir === 'left') {
                this.privates.transferSearchValueLeft = value;
            }
        },
        columnsControl(e) {
            clearTimeout(this.privates.showPopoverTimer);
            this.privates.showPopoverTimer = setTimeout(() => {
                this.privates.visible = false;
                this.actions.update();
            }, 300);
        },
        getNewColumns(props, targetKeys) {
            let newColumns = [];
            targetKeys &&
                targetKeys.forEach((targetKey) => {
                    props.columns &&
                        props.columns.forEach((column) => {
                            if (column.key === targetKey || column.dataIndex === targetKey)
                                if (column.key === targetKey || column.dataIndex === targetKey) {
                                    let renderExtra = (name, items, index) => {
                                        let renderRes = typeof column.render === 'function' ? column.render(name, items, index) : name;
                                        if (typeof renderRes === 'string') {
                                            if (renderRes.length > 20) {
                                                let text = renderRes.substring(0, 20) + '...';
                                                return <div title={renderRes}>{text}</div>;
                                            } else {
                                                return <div title={renderRes}>{renderRes}</div>;
                                            }
                                        } else {
                                            return renderRes;
                                        }
                                    };
                                    if (typeof column.title === 'string') {
                                        let width = column.title.length * 14 + 19 * 2;
                                        if (column.sorter) {
                                            width += 20;
                                        }

                                        if (width < 100) {
                                            width = 100;
                                        }
                                        newColumns.push({ ...column, width: column.width || width, render: renderExtra });
                                    } else {
                                        if (Array.isArray(column.title.props.children)) {
                                            let length = this.actions.getTitleLength(column.title);
                                            let width = length * 14 + 19 * 2;
                                            if (column.sorter) {
                                                width += 20;
                                            }
                                            if (width < 100) {
                                                width = 100;
                                            }
                                            newColumns.push({ ...column, width: column.width || width, render: renderExtra });
                                        }
                                    }
                                }
                        });
                });
            
            return newColumns;
        },
        fixedActionColumn() {
            if (this.props.needFixedActionColumn && Array.isArray(this.privates.targetItems)) {
                this.privates.targetItems.forEach((item, index) => {
                    if (item.key === "actions" ||  item.key === "action") {
                        if (index === this.privates.targetItems.length - 1) {
                            if (item.fixed !== 'right') {
                                item.fixed = 'right';
                                this.actions.update();
                            }
                        } else {
                            if (item.fixed !== false) {
                                item.fixed = false;
                                this.actions.update();
                            }
                        }
                    }
                });
            }
        },
        getTitleLength(data) {
            let length = 0;
            if (typeof data.props.children === 'string') {
                length += data.props.children.length;
                data.props.children.split('').forEach(i => {
                    if (!Number.isNaN(parseInt(i, 10))) {
                        length -= 0.5;
                    }
                });
            } else {
                data.props.children.forEach((item) => {
                    if (typeof item === 'string') {
                        length += item.length;
                        item.split('').forEach(i => {
                            if (!Number.isNaN(parseInt(i, 10))) {
                                length -= 0.5;
                            }
                        });
                    } else {
                        const childrenLength = this.actions.getTitleLength(item);
                        length += childrenLength;
                    }
                });
            }
            return length;
        }
    };
    triggerColumnsControl(value, e) {
        clearTimeout(this.privates.showPopoverTimer);
        this.privates.visible = !this.privates.visible;
        this.actions.update();
    }
    //#region Life Cycle
    componentDidMount() {
        this.actions.fixedActionColumn();
        // if(hasVersion(this.props)){
        //     //请求后端数据
        //     let showListUrl = Udesk.business.apiPath.concatApiPath(`inspectionTaskShowFields/${this.props.taskId}`, this.props.sdkOptions);
        //     Udesk.ajax.get(showListUrl).then(resp => {
        //         if(Array.isArray(resp?.data?.showList) && resp?.data?.updateTime){
        //             this.privates.newVersion = resp.data.updateTime;
        //             this.privates.originColumnsCache = resp.data.showList;
        //             this.actions.update();
        //         }
        //     });
        // }
    }
    componentDidUpdate(prevProps) {
        this.actions.fixedActionColumn();
        let storageKey = computeStorageKey.bind(this)();
        if(prevProps.cacheKey !== this.props.cacheKey) {
            if(this.privates.storageKey !== storageKey) {
                this.privates.storageKey = storageKey;
                this.actions.update();
            }
        }
    }
    parseProps({ props, prevProps, state, privates, isInitial }) {
        let result = {
            transfer: Object.assign(privates.transfer, props.transfer),
        };
        if(props.columns !== prevProps.columns) {
            result.targetItems = this.actions.getNewColumns(props, privates.targetKeys);
            result.columnsAfterSearch = this.actions.getNewColumns(props, privates.targetKeys);
        }
        
        if (!privates.targetKeys || privates.targetKeys.length === 0) {
            let targetKeys = [];
            let targetKeysLocal;
            if (privates.storageKey !== false) {
                if(props.hasOriginColumnsControl){
                    let localVersion = Udesk.browser.storage.localStorage.getItem(props.versionCacheKey);                    
                    if(props.originVersion && props.originVersion !== localVersion){
                        
                        targetKeysLocal = props.originColumns;
                        saveLocalStorage(this.privates.storageKey, props.originColumns);
                        props.columns && props.columns.length !== 0 && saveLocalStorage(props.versionCacheKey, props.originVersion);
                    }
                    if(!props.originVersion || (props.originVersion && props.originVersion === localVersion)){
                        targetKeysLocal = JSON.parse(Udesk.browser.storage.localStorage.getItem(privates.storageKey));
                    }
                }else{
                    targetKeysLocal = JSON.parse(Udesk.browser.storage.localStorage.getItem(privates.storageKey));
                }
                //降级兼容
                if (!targetKeysLocal) {
                    let fixStorageKey = privates.storageKey.split('.').filter(i => !i.includes('taskId-')).join('.');
                    targetKeysLocal = JSON.parse(Udesk.browser.storage.localStorage.getItem(fixStorageKey));
                }
                
            }

            if (props.columns && props.columns.length !== 0) {
                if (targetKeysLocal) {
                    targetKeys = targetKeysLocal.filter(i => props.columns.find(c => c.key === i));
                } else {
                    props.columns.forEach((column) => {
                        if (column.key) {
                            targetKeys.push(column.key);
                        } else if (column.dataIndex) {
                            targetKeys.push(column.dataIndex);
                        }
                    });
                }
            }
            result.targetItems = this.actions.getNewColumns(props, targetKeys);
            result.columnsAfterSearch = this.actions.getNewColumns(props, targetKeys);
            targetKeys.length && this.trigger('onColumnsChange', targetKeys);
            result.targetKeys = targetKeys;
        } else {
            if(prevProps.columns !== props.columns){
                result.targetItems = this.actions.getNewColumns(props, privates.targetKeys);
                result.columnsAfterSearch = this.actions.getNewColumns(props, privates.targetKeys);    
            }
        }
        if (props.pagination != null && !Object.is(prevProps.pagination, props.pagination)) {
            let pagination = privates.pagination;
            Object.assign(pagination, props.pagination);
            if (!pagination.showTotal) {
                pagination.showTotal = (total, range) => {
                    return Udesk.utils.string.format(
                        UdeskLocales.current.components.pages.customerServiceRankList.table.page,
                        total,
                        Math.ceil(total / privates.pagination.pageSize)
                    );
                };
            }
            result.pagination = pagination;
        }
        if (props.transfer != null && !Object.is(prevProps.transfer, props.transfer)) {
            let transfer = privates.transfer;
            Object.assign(transfer, props.transfer);
            result.transfer = transfer;
        }
        return result;
    }
    init() {
        let storageKey = computeStorageKey.bind(this)();
        this.privates.storageKey = storageKey;
    }
    //#endregion
}
export function computeStorageKey() {
    let cacheKey = this.props.cacheKey || false;
    if (cacheKey === false) {
        return false;
    }
    if (cacheKey == null || cacheKey === '') {
        cacheKey = 'no_cache_key';
    }
    let storageKey = STORAGE_KEY_PREFIX + '.' + cacheKey;
    if (this.privates && storageKey !== this.privates.storageKey) {
        this.privates.storageKey = storageKey;
    }
    return storageKey;
}
export function saveLocalStorage(key, target) {
    if (Udesk.browser.storage.localStorage.available && key !== false) {
        if(typeof target !== 'string'){
            Udesk.browser.storage.localStorage.setItem(key, JSON.stringify(target));
        }else{
            Udesk.browser.storage.localStorage.setItem(key, target);
        }
    }
}

export default UdeskTableComponent;
