// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PutIntelligentPartnerHomePageByIdFilterParam,
    PutIntelligentPartnerHomePageByIdFilterBody,
    BaseResponseVoid,
    DeleteIntelligentPartnerHomePageByIdFilterParam,
} from '../../../../types';

/**
 * 修改首页筛选器
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [PUT] /intelligentPartner/homePage/{id}/filter
 * @param id
 */
export function putIntelligentPartnerHomePageByIdFilter(
    data: PutIntelligentPartnerHomePageByIdFilterBody,
    options: ApiOptions<PutIntelligentPartnerHomePageByIdFilterParam, never> & {
        segments: PutIntelligentPartnerHomePageByIdFilterParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentPartnerHomePageByIdFilterBody,
        PutIntelligentPartnerHomePageByIdFilterParam,
        never
    >('/intelligentPartner/homePage/{id}/filter', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerHomePageByIdFilter',
    });
}

/**
 * 删除首页筛选器
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [DELETE] /intelligentPartner/homePage/{id}/filter
 * @param id
 */
export function deleteIntelligentPartnerHomePageByIdFilter(
    options: ApiOptions<DeleteIntelligentPartnerHomePageByIdFilterParam, never> & {
        segments: DeleteIntelligentPartnerHomePageByIdFilterParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentPartnerHomePageByIdFilterParam, never>(
        '/intelligentPartner/homePage/{id}/filter',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteIntelligentPartnerHomePageByIdFilter',
        }
    );
}

export const meta = [
    { tags: ['智能陪练首页接口'], path: '/intelligentPartner/homePage/{id}/filter', method: 'put' },
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/{id}/filter',
        method: 'delete',
    },
];
