import React, { useEffect, useState } from 'react';
import List from 'Component/common/udesk-list';
import Udesk from 'Udesk';
import { Select, Space } from 'udesk-ui';
import Locales from 'UdeskLocales';


export default function AssociateRegulation(props) {
    const { value = [], onChange } = props;
    const locales = Locales['current'];

    const [associateList, setAssociateList] = useState([]);
    const deleteItem = (index, childProps) => {
        let newValue = [...value];
        newValue.splice(index, 1);
        
        onChange?.(newValue);
    };
    const addItem = () => {
        onChange?.([...value, {}]);
    };
    const handleRegulationChange = (index, v, option) => {
        let newValue = [...value];
        
        newValue[index] = option;
        onChange?.(newValue);
    };
    useEffect(() => {
        let { sdkOptions, type } = props;
        let fieldUrl = Udesk.business.apiPath.concatApiPath(`field/data/search-template`, sdkOptions);
        let types = Udesk.enums.inspectDataSources.voiceCall.id;
        if (
            type.includes(Udesk.enums.inspectDataSources.voiceCall.id) ||
            type.includes(Udesk.enums.inspectDataSources.realTimeVoiceCall.id)
        ) {
            types = Udesk.enums.inspectDataSources.voiceCall.id;
        } else if (
            type.includes(Udesk.enums.inspectDataSources.textDialogue.id) ||
            type.includes(Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
        ) {
            types = Udesk.enums.inspectDataSources.textDialogue.id;
        } else if (type.includes(Udesk.enums.inspectDataSources.wechatDialogue.id)) {
            types = Udesk.enums.inspectDataSources.wechatDialogue.id;
        } else {
            return;
        }
        let data = {
            sourceList: [types],
            typeList: [1, 3, 6],
        };
        Udesk.ajax.post(fieldUrl, data).then(
            (resp) => {
                if (resp?.data?.[0]?.typeList) {
                    let fieldList = resp?.data?.[0]?.typeList.map((type) => type.fieldList);
                    let associateList = fieldList
                        .flat(1)
                        .filter((i) => i.id)
                        .map((i) => ({ ...i, value: i.id }));
                    setAssociateList(associateList);
                }
                
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [props.type]);
    
    return (
        <List
            deleteItem={deleteItem}
            addItem={addItem}
            addBtnText={locales.labels.add + locales.pages.tasks.manage.template.basicInfo.index.rule}
        >
            {value.map((regulation, index) => {
                if(!regulation) return null;
                return (
                    <Space>
                        <div>{index + 1}</div>
                        <Select
                            style={{ width: 120 }}
                            onSelect={handleRegulationChange.bind(null, index)}
                            options={associateList.map((i: any) => ({
                                ...i,
                                disabled: !!value.find((v) => v.id === i.id),
                            }))}
                            value={regulation?.id || regulation?.value}
                            optionLabelProp='label'
                        ></Select>
                    </Space>
                );
            })}
        </List>
    );
}
