import React from 'react';
import Udesk from 'Udesk';
import InspectionDetail from '../../components/inspection-detail';
import ReviewListDetailRoute from './route';
import './style.scss';
import { PageHeader, Button, Space, Modal, Icon, Popconfirm } from 'udesk-ui';
import ClassNames from 'classnames';

const LOCAL_STORAGE_KEY = 'spotCheckScoreSubmittedVerifyPopconfirmShowed';

export default class ReviewListDetailTemplate extends ReviewListDetailRoute {
    render() {
        let { match, sdkOptions } = this.props;
        let {
            detail,
            smartTags,
            changeList,
            qualityCheckScoreSaving,
            caseBase,
            relatedDatas,
            relatedLoading,
            pointChildrenComputer,
            pointChildrenPerson,
            resetStatus,
            evaluteVisibe,
            activeFlag,
            dialogRemarkList,
            enabledEvaluate,
            submitButtonDisabled,
            showHit,
        } = this.privates;
        let { relatedDatasPaging } = this.privates.storages;
        let { actions, locales } = this;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        return (
            // <div className="review-list-detail-page">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    onBack={actions.backToIndexPage}
                    title={locales.components.pages.reviewList.detail.title}
                    extra={
                        <If
                            condition={
                                hasFeature &&
                                hasFeature('task:work:inpectionList:view') &&
                                !this.privates.testSetListFlag
                            }
                        >
                            <Space>
                                <Button onClick={actions.changePages.params(1)}>
                                    {locales.labels.lastPage}
                                </Button>
                                <Button onClick={actions.changePages.params(2)}>
                                    {locales.labels.nextPage}
                                </Button>
                                <If condition={hasFeature && hasFeature('task:work:sample:edit')}>
                                    <Choose>
                                        <When
                                            condition={
                                                detail.reviewStatus &&
                                                detail.reviewStatus !==
                                                    Udesk.enums.sampleAndReviewStatus.reviewed.id &&
                                                !submitButtonDisabled
                                            }
                                        >
                                            <Button
                                                disabled={qualityCheckScoreSaving}
                                                onClick={actions.cancel}
                                            >
                                                {locales.labels.cancel}
                                            </Button>
                                            <Popconfirm
                                                visible={!localStorage.getItem(LOCAL_STORAGE_KEY)}
                                                icon={
                                                    <Icon
                                                        antdIcon={true}
                                                        type="InfoCircleTwoTone"
                                                    />
                                                }
                                                title={
                                                    /* 新功能！点击 ‘复核提交’ 按钮，提交成功后会自动跳转到下一页啦~~~ */ locales
                                                        .pages.reviewList.detail.template
                                                        .newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful
                                                }
                                                onConfirm={() => {
                                                    localStorage.setItem(LOCAL_STORAGE_KEY, true);
                                                    this.actions.update();
                                                }}
                                                showCancel={false}
                                                disabled={true}
                                                placement="bottomRight"
                                                cancelButtonProps={{ style: { display: 'none' } }}
                                            >
                                                <Button
                                                    disabled={
                                                        qualityCheckScoreSaving ||
                                                        (detail.reviewStatus &&
                                                            detail.reviewStatus ===
                                                                Udesk.enums.sampleAndReviewStatus
                                                                    .complainExpired.id)
                                                    }
                                                    type="primary"
                                                    onClick={actions.spotCheckScoreSubmittedVerify}
                                                >
                                                    {locales.labels.saveReview}
                                                </Button>
                                            </Popconfirm>
                                        </When>
                                        <When
                                            condition={
                                                detail.reviewStatus &&
                                                detail.reviewStatus ===
                                                    Udesk.enums.sampleAndReviewStatus.reviewed.id
                                            }
                                        >
                                            <Button
                                                disabled={qualityCheckScoreSaving}
                                                onClick={actions.cancel}
                                            >
                                                {locales.labels.cancel}
                                            </Button>
                                            <Button
                                                disabled={qualityCheckScoreSaving}
                                                type="primary"
                                                onClick={actions.onQualityCheckScoreRapidSampling}
                                            >
                                                {locales.labels.rapidSampling}
                                            </Button>
                                        </When>
                                    </Choose>
                                </If>
                            </Space>
                        </If>
                    }
                />
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root review-list-detail-page">
                        <Modal
                            visible={evaluteVisibe && enabledEvaluate}
                            closable={false}
                            onOk={actions.evaluateSave}
                            okText={locales.fix.continueToSubmitWithoutEvaluation}
                            onCancel={actions.cancelSave}
                            mask={false}
                        >
                            <div className="quality-check-score-talk-header-eveluation-person">
                                <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '16px' }}>
                                    {locales.fix.manualEvaluation}：
                                </span>
                                <Space>
                                    <Icon
                                        className={ClassNames({
                                            'quality-check-score-talk-header-xiao':
                                                activeFlag === 'true',
                                        })}
                                        type="xiaolian"
                                        onClick={actions.activeChange.params('true')}
                                    />
                                    <Icon
                                        className={ClassNames({
                                            'quality-check-score-talk-header-ku':
                                                activeFlag === 'false',
                                        })}
                                        type="kulian"
                                        onClick={actions.activeChange.params('false')}
                                    />
                                </Space>
                            </div>
                        </Modal>
                        <div className="udesk-qa-layout-main-page-body">
                            <div className="review-list-detail-page-body qa-react-page-body">
                                {/* <If condition={hasFeature && hasFeature('task:work:review:view')}> */}
                                <InspectionDetail
                                    resetStatus={resetStatus}
                                    onQualityCheckScoreSave={actions.qualityCheckScoreSaved}
                                    handMarkMap={actions.handMarkMap}
                                    pointChildrenComputer={pointChildrenComputer}
                                    pointChildrenPerson={pointChildrenPerson}
                                    caseBaseStatus={true}
                                    onUpdate={actions.onUpdate}
                                    id={match.params.id}
                                    caseBase={caseBase}
                                    sdkOptions={sdkOptions}
                                    caseLibraryId={match.params.caseLibraryId}
                                    taskId={match.params.taskId}
                                    hasFeature={hasFeature}
                                    detail={detail}
                                    smartTags={smartTags}
                                    changeList={changeList}
                                    qualityCheckScoreSaving={qualityCheckScoreSaving}
                                    qualityCheckScoreType={
                                        Udesk.enums.qualityCheckDetailTypes.review.id
                                    }
                                    relatedDatas={relatedDatas}
                                    relatedDatasPaging={relatedDatasPaging}
                                    parentLoadMore={actions.parentLoadMore}
                                    relatedRecordsLoading={relatedLoading}
                                    dialogRemarkList={dialogRemarkList}
                                    changeDialogRemarkList={actions.changeDialogRemarkList}
                                    querryChangeList={actions.querryChangeList}
                                    refresh={actions.reloadAsyncModel}
                                    isReview={true}
                                    trainRecords={actions.trainRecords}
                                    onShowHitChange={actions.showHitChange}
                                    showHit={showHit}
                                    isTaskChanged={this.privates.isTaskChanged}
                                />
                                {/* </If> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
