import React from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { FormBuilder, Modal } from 'udesk-ui';
import UserVisibleSelect from 'Component/pages/components/gong/user-visible-select';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import type { ModalProps as AntdModalProps } from 'udesk-ui/lib/modal';
import { formatDuration } from 'src/util/date';

const locales = Locales['current'];

interface ModalProps extends Omit<AntdModalProps, 'onOk' | 'onCancel'> {
    helpTime: number;
    userList: any[];
    onOk?: (values: any) => Promise<any>;
    onCancel?: () => void;
}

const Text = (props) => {
    return <span style={{ color: '#FB5127' }}>{formatDuration(props.helpTime)}</span>;
};

const HelpModal: React.FC<ModalProps> = (props) => {
    const { userList = [], helpTime = 0, onOk, onCancel, ...modalProps } = props;
    const [form] = FormBuilder.useForm();
    const onOkWithForm = (values) => {
        onOk?.(values).then(() => {
            form.resetFields();
        });
    };
    const onCancelWithForm = () => {
        form.resetFields();
        onCancel?.();
    };
    const config: FormProps = {
        customComponents: { UserVisibleSelect, Text },
        onFinish: onOkWithForm,
        initialValues: {
            authority: {
                authority: Udesk.enums.commentVisibleAuthority.all.id,
                authorityUser: [],
            },
        },
        fields: [
            {
                label: '',
                type: 'Text',
                name: '',
                wrapperCol: { offset: 4 },
                props: {
                    helpTime,
                },
            },
            {
                label: locales.components.gong.helpCommentModal.index.selectColleagues,
                type: 'Select',
                name: 'helpUserIdList',
                props: {
                    options: userList,
                    mode: 'multiple',
                    optionFilterProp: 'label',
                    placeholder: locales.labels.pleaseSelect,
                },
                rules: [
                    {
                        required: true,
                        message: locales.components.gong.helpCommentModal.index.selectColleagues,
                    },
                ],
            },
            {
                label: locales.components.gong.helpCommentModal.index.helpContent,
                name: 'content',
                type: 'TextArea',
                props: {
                    maxLength: 1024,
                    showCount: true,
                    maxCount: 1024,
                    placeholder: locales.labels.pleaseEnter,
                },
                rules: [
                    {
                        required: true,
                        message: locales.components.gong.helpCommentModal.index.helpContent,
                    },
                ],
            },
            {
                label: locales.components.gong.helpCommentModal.index.visibleRange,
                name: 'authority',
                type: 'UserVisibleSelect',
                props: {
                    userList,
                },
            },
        ],
        footerRender: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };

    return (
        <Modal
            title={locales.components.gong.helpCommentModal.index.seekCounseling}
            {...modalProps}
            onOk={() => form.submit()}
            onCancel={onCancelWithForm}
        >
            <FormBuilder {...config} form={form} />
        </Modal>
    );
};

export default HelpModal;
