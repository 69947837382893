export default {
  labels: {
    clear: "Kosongkan",
    refresh: "Menyegarkan",
    nextStep: "Berikutnya",
    prevStep: "Langkah sebelumnya",
    closeDriver: "Panduan keluar",
    complete: "Selesai",
    viewStatistics: "Lihat statistik",
    colon: "：",
    splitter: "/",
    remark: "Catatan",
    correction: "Koreksi kesalahan",
    save: "Simpan",
    reset: "Atur ulang",
    increase: "Tambahkan",
    preview: "Pratinjau",
    unit: "Strip",
    cancel: "Pembatalan",
    all: "Semua",
    searchPlaceHolder: "Masukkan query kata kunci",
    greaterThan: "Lebih besar dari",
    lessThan: "Kurang dari",
    second: "Detik",
    description: "Deskripsi",
    column: "Kolom",
    pleaseChoice: "Silakan pilih",
    filter: "Penyaringan",
    saveAndSubmit: "Simpan dan kirimkan",
    submit: "Pengajuan",
    appeal: "Keluhan",
    review: "Ulasan",
    choose: "Pilih",
    manage: "Manajemen",
    delete: "Hapus",
    add: "Baru",
    create: "Baru dibangun",
    edit: "Mengedit",
    name: "Nama",
    describe: "Deskripsi",
    next: "Berikutnya",
    percent: "%",
    export: "Ekspor",
    exportAll: "Semua diekspor",
    confirm: "Penentuan",
    back: "Kembali",
    versionUpdate:
      "Senang untuk memberitahu Anda bahwa situs kami baru saja merilis versi baru! Untuk menjamin penggunaan normal Anda, kami mengharuskan Anda untuk segera menyimpan pekerjaan Anda saat ini dan kemudian refresh halaman (klik tombol refresh browser, atau tekan tombol F5) untuk membuat versi baru kami berlaku. Terima kasih banyak atas kerja sama Anda!",
    more: "Lebih banyak",
    selected: "Dipilih",
    deduct: "Gesper",
    maxScore: "Paling banyak",
    scoreUnit: "Poin",
    pointUnit: "Satu",
    comma: "，",
    view: "Lihat",
    batchingImport: "Impor",
    deleteContent:
      "Apakah Anda mengonfirmasi bahwa Anda ingin menghapusnya? Tidak dapat dipulihkan setelah dihapus",
    import: "Impor",
    uploadFile: "Unggah file",
    downloadFile: "Mengekspor file",
    upload: "Unggah",
    click: "Klik",
    downloadTemplate: "Unduh Template",
    fillInTheForm: "Isi dengan format",
    UploadExcelfile: "Upload Excel untuk menyelesaikan impor massal",
    uploadIdentical:
      "Jika nama API ada, data yang sudah ada dalam sistem akan diperbarui saat diimpor",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Berlaku",
    leftBracket: "(",
    rightBracket: ")",
    question: "Pertanyaan",
    answer: "Jawaban",
    content: "Konten",
    search: "Pencarian",
    query: "Pertanyaan",
    cancelEdit: "Membatalkan pengeditan",
    noValue: "Tidak ada data",
    selectAll: "Pilih semua",
    changeAGroup: "Ubah satu set",
    arrow: " ---> ",
    saveReview: "Pengajuan ulasan",
    saveSpotCheck: "Pengambilan Sampel",
    rapidSampling: "Pengambilan Sampel Cepat",
    file: "Arsip",
    download: "Unduh",
    lastPage: "Sebelumnya",
    nextPage: "Halaman berikutnya",
    batchActions: "Operasi massal",
    cancelDistribution: "Batalkan distribusi",
    againDistribution: "Realokasi",
    deleteRecord: "Menghapus catatan",
    oneOptionOpertor: "Inspektur kualitas opsional",
    allOptionOpertor: "Semua inspektur kualitas",
    distributionInfoFirstOne: "Konfirmasi benar",
    distributionInfoFirstTwo:
      "Apakah redistribusi catatan? Silakan pilih tujuan distribusi",
    distributionInfoSecondOne: "Tentukan untuk membatalkan",
    distributionInfoSecondTwo:
      "Apakah informasi penugasan catatan akan menjadi catatan ulasan yang tidak ditugaskan setelah pembatalan?",
    distributionInfoSecondThree:
      "Apakah informasi penugasan catatan akan menjadi catatan pengambilan sampel yang tidak ditugaskan setelah pembatalan?",
    distributionInfoThirdOne: "Ada rekaman yang dipilih",
    distributionInfoThirdTwo:
      "Catatan yang telah ditinjau, catatan yang telah ditinjau tidak akan diproses.",
    distributionInfoThirdThree:
      "Catatan pengambilan sampel, catatan pengambilan sampel tidak akan diproses.",
    exportError: "Ekspor gagal",
    exportSuccess: "Ekspor berhasil",
    deleteRecordInfo:
      "Pastikan Anda ingin menghapus catatan {0}? Data tidak akan dipulihkan setelah dihapus",
    pleaseSetFilter: "Silakan pilih kondisi filter",
    noCountAfterFilter:
      "Tidak ada data yang diekspor, silakan atur ulang filter",
    redo: "Eksekusi ulang",
    batchMove: "Bergerak massal",
    websiteName: "Pemeriksaan kualitas yang cerdas",
    salesEnable: "Pemberdayaan penjualan",
    systemName: "Pemeriksaan kualitas yang cerdas",
    initSystemError:
      "Kelainan jaringan atau inisialisasi halaman gagal, silakan hubungi layanan pelanggan",
    day: "Hari",
    hour: "Jam",
    minute: "Poin",
    questionMark: "？",
    personalSettings: "Pengaturan pribadi",
    logout: "Batalkan",
    ok: "Penentuan",
    saveAs: "Simpan sebagai",
    pleaseEnter: "Silakan masukkan",
    executeMessage: {
      executeSuccess: "Berhasil mengeksekusi {0} bar",
      executeError: "{0} kegagalan eksekusi",
    },
    field: "Bidang",
    operator: "Operator",
    value: "Nilai",
    placeholder: "Silakan pilih",
    pleaseSelect: "Silakan pilih",
    totalScore: "Total skor",
    hyphen: "-",
    semicolon: "；",
    wfm: "Jadwal cerdas",
    user: "Pengguna",
    none: "Tidak ada",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Simpan sukses! Efektif setelah karyawan yang relevan menyegarkan halaman",
    saveAsName: "{0}(salinan)",
    systemInformation: "Melihat data sistem",
    billingCenter: "Pusat penagihan",
    warningMessage:
      "Kuota yang tersisa tidak mencukupi, silakan isi ulang secepatnya",
    fileTypeHolder: "Format file yang salah, silakan unggah file format {0}",
    dataNo: "Tidak ada data",
    move: "Bergerak",
    study:
      "Anda memiliki titik pemeriksaan kualitas belajar mandiri yang tidak ditandai",
    studySuccess: "Menandai kesuksesan",
    studyError: "Menandai kegagalan",
    editType: "Mengedit label kategori",
    copyed: "Disalin ke clipboard",
    clickToCopy: "Klik untuk menyalin",
    addFilterConditions: "Menambahkan filter",
    filterConditions: "Kondisi penyaringan",
    messageTime: "Waktu pesan",
    messageType: "Jenis pesan",
    pushType: "Saluran pesan",
    messageContent: "Konten pesan",
    messageName: "Nama Pesan",
    messageStatus: "Status pesan",
    configuration: "Informasi konfigurasi",
    sendTarget: "Kirim target",
    guideUrl: "URL Bootstrap",
    noticeDetail: "Detail pesan",
    action: "Operasi",
    seeDetail: "Lihat detail",
    theDay: "{0} hari",
    theLastDayOfMonth: "Hari terakhir setiap bulan",
    ignore: "Abaikan",
    seeNow: "Lihat sekarang",
    exportConfirmText: {
      export: "Total {0} data, konfirmasi ekspor?",
      exportAll: "Konfirmasi mengekspor semua konten?",
    },
    samrTagAdd: "Tambahkan label",
    deletePopconfirmContent:
      "Apakah Anda mengonfirmasi bahwa Anda ingin menghapusnya?",
    TheLastThirtyDays: "30 hari terakhir",
    addNumber: "Ditambah",
    subNumber: "Kurangi",
    addStrategy: "Menambahkan strategi",
    minutes: "Menit",
    status: "Aktifkan Status",
    pushConfig: "Konfigurasi push",
    category: "Klasifikasi",
    and: "Dengan",
    interval: "Interval",
    switchOn: "Buka",
    switchOff: "Tutup",
    batchDel: "Penghapusan massal",
    exportTxt: "Mengekspor teks TXT",
    playSpeed: "Kecepatan ganda",
    messageLogBtn: "Melihat komunikasi/percakapan yang memicu pemberitahuan",
    dataLink: "Tautan Data",
    yes: "Ya",
    no: "Tidak.",
    sentenceUnit: "Kalimat",
    appealDetail: "Rincian catatan banding",
    detail: "Detail",
    default: "Default",
    customer: "Pelanggan",
    agent: "Layanan Pelanggan",
    sort: "Menyortir",
    applyNow: "Aplikasi",
    index: "Nomor seri",
    allColumns: "Kolom yang dapat ditambahkan",
    selectedColumns: "Kolom yang ditambahkan",
    syn: "Sinkronisasi",
    saveAndNext: "Simpan dan buat yang berikutnya",
    viewReference: "Lihat referensi",
    referenceDetail: "Rincian kutipan",
    goToQaList: "Pergi ke pemeriksaan kualitas",
    goToCheck: "Pergi ke pengambilan sampel",
    businessCard: "Kartu nama",
    weapp: "Program kecil",
    redpacket: "Amplop merah",
    externalRedpacket: "Amplop merah",
    sphfeed: "Nomor video",
    exportTaskCreated: "Ekspor tugas berhasil dibuat",
    plzGoTo: "Silakan melompat ke",
    importTaskCreated: "Impor tugas untuk membuat berhasil",
    taskCreated: "Tugas menciptakan sukses",
    reply: "Membalas",
    replied: "Membalas",
    release: "Rilis",
    expandMore: "Memperluas balasan {0} bar lainnya",
    comment: "Komentar",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Harap setidaknya satu {0}",
      canNotBeEmptyFormat: "{0} tidak bisa kosong",
      repeatErrorTipFormat: "{0} dengan pengulangan",
      getInformationFailedFormat:
        "Mendapatkan informasi {0} gagal, periksa koneksi jaringan atau coba lagi nanti",
      deleteSuccessFormat: "{0} Berhasil dihapus.",
      deleteFailedFormat: "{0} Hapus gagal, silakan coba lagi nanti.",
      saveSuccessFormat: "{0} Simpan berhasil.",
      saveFailedFormat: "{0} Simpan gagal, silakan coba lagi nanti.",
      fileSuccessFormat: "{0} Arsip berhasil.",
      fileFailedFormat: "{0} Arsip gagal, silakan coba lagi nanti.",
      exportSuccessFormat: "{0} Ekspor berhasil",
      exportFailedFormat: "Ekspor {0} gagal, silakan coba lagi nanti.",
      submitSuccessFormat: "{0} Pengajuan berhasil.",
      submitFailedFormat: "{0} Pengajuan gagal, silakan coba lagi nanti.",
      confirmSuccessFormat: "{0} Konfirmasi kesuksesan.",
      confirmFailedFormat: "{0} Konfirmasi kegagalan, silakan coba lagi nanti.",
      pleaseUploadFile: "Silakan unggah file terlebih dahulu!",
      importFileSuccess: "File berhasil diimpor!",
      importFildFailed: "Impor file gagal!",
      deleteSuccess: "Hapus Sukses!",
      deleteFailed: "Hapus gagal, coba lagi nanti!",
      illegalTitle: "Izin yang tidak memadai",
      illegalMessage:
        "Anda tidak memiliki beberapa atau semua izin untuk langkah berikutnya, silakan hubungi administrator konfigurasi, klik tombol OK akan keluar dari booting",
      illegalMessageCurrent:
        "Anda tidak memiliki izin parsial atau penuh untuk langkah saat ini, hubungi administrator konfigurasi, klik tombol OK akan keluar dari boot",
      skipMessage:
        "Jika Anda ingin melewatkan langkah ini untuk sementara waktu, klik Lewati",
      errorMsg500:
        "Permintaan server gagal, periksa operasi Anda atau refresh dan coba lagi",
      errorMsg404: "Ooops... halaman yang Anda cari hilang",
      errorMsg400:
        "Permintaan server gagal, coba lagi setelah memeriksa data Anda",
      pageOverFlow:
        "Saat ini hanya mendukung tampilan 10000 bar, silakan atur kondisi filter untuk melihat",
      isHandAddSmartTag: "Adalah label pintar yang ditambahkan secara manual",
      defaultGradeMustAtLast:
        "Peringkat default harus ditempatkan pada akhirnya",
      AutomaticAllocationInspectorsIsNull:
        "Silakan masukkan halaman informasi dasar untuk menambahkan inspektur kualitas",
      timePickerTips:
        "Agar tidak mempengaruhi penggunaan normal Anda, 23:00 ~ 2:00 adalah waktu pemeliharaan sistem dan tidak ada tugas waktunya yang dilakukan.",
      batchDeleteInfo:
        "Berhasil menghapus {0} bar dan gagal {1} bar. Alasan kegagalan adalah bahwa data telah digunakan.",
      batchDeleteTips: "Periksa data {0} untuk mengonfirmasi penghapusan?",
      correctionSuccess: "Koreksi kesalahan berhasil",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError:
            "Tambahkan hingga 10 formulir inspeksi kualitas",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "Total item indikator {0} harus ditambahkan ke 100",
          banProjectMaxErrorTip: "Tambahkan hingga 2.000 item yang dilarang",
          deadlyProjectMaxErrorTip: "Tambahkan hingga 500 item yang mematikan",
        },
      },
    },
    info: {
      creatSuccess: "Menciptakan Sukses",
      creatError: "Gagal membuat",
      editSuccess: "Berhasil dimodifikasi",
      editError: "Gagal memodifikasi",
      deleteSuccess: "Hapus Sukses",
      addSuccess: "Tambahkan Sukses",
      deleteError: "Gagal menghapus",
      conditionError: "Kesalahan ekspresi logika operator",
      customJudgeLogic: "Kondisi kustom kombinasi logika ekspresi kesalahan",
      exportError: "Ekspor gagal",
      searchError: "Penyaringan gagal",
      loadError: "{0} kegagalan pemuatan",
      saveSuccess: "Simpan Sukses",
      saveError: "Simpan gagal",
      fileSuccess: "Sukses Arsip",
      fileError: "Pengarsipan gagal",
      cannotBeNull: "{0} tidak bisa kosong!",
      pleaseSearchAndSelect: "Silakan masukkan pencarian kata kunci",
      clickAndToggle: "Klik untuk memilih Beralih",
      deleteWarning:
        "{0} Hapus tidak akan dipulihkan, apakah Anda yakin ingin melanjutkan?",
      getOssAuthFailed:
        "Untuk mendapatkan otorisasi OSS gagal, periksa koneksi jaringan atau coba lagi nanti.",
      getInsightAuthFailed:
        "Jika Anda gagal mendapatkan otorisasi Insight, periksa koneksi jaringan atau coba lagi nanti.",
      pictureUploadFailed: "Unggahan gambar gagal",
      pictureUploadFailedSize:
        "Ukuran gambar melebihi batas, gambar gagal upload",
      wecomTagsSyncInfo: "Update terbaru:{0}, sinkronisasi {1} informasi",
      collectSuccess: "Koleksi Sukses",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "Gagal mendapatkan data laporan" },
      waveAudio: {
        noWaveMessage:
          "Untuk mempertahankan pengalaman Anda, rekaman lebih dari {0} menit tidak menunjukkan bentuk gelombang, Anda dapat memainkannya secara langsung",
      },
      customFilter: {
        del: "Hapus",
        edit: "Mengedit",
        new: "Filter kustom",
        apply: "Aplikasi",
        cancel: "Pembatalan",
        onlyApply: "Hanya berlaku",
        ohterSave: "Simpan sebagai",
        save: "Simpan",
        customFilter: "Penyaringan umum",
        reset: "Atur ulang",
        holderKeyWord: "Silakan masukkan kata kunci",
        customFilterRule: "Filter aturan logika:",
        customFilterName: "Nama Filter:",
        holderCustomFilterName: "Masukkan nama filter",
        manualConfiguration: "Konfigurasi manual",
        holderCustomRule: "Masukkan kondisi logika kustom",
        example: "Contoh",
        dragConfiguration: "Konfigurasi seret",
        condition: "Kondisi",
        operation: "Perhitungan",
        infoDel: "Menghapus konten di luar area",
        toConfigure: "Konfigurasi",
        holderCustomFilterNameLength: "Panjang maksimum nama filter adalah 10",
        customFilterNameNone: "Nama filter tidak boleh kosong",
        and: "Dan",
        or: "Atau",
        not: "Tidak",
        delSuccess: "Hapus Sukses",
        saveSuccess: "Simpan Sukses",
        updateSuccess: "Berhasil dimodifikasi",
        customFilterCondition: "Kondisi penyaringan",
        conditionRule: "Aturan Kondisi",
        none: "Tidak ada",
        filterPlaceholder: "Silakan masukkan kata kunci",
      },
      labelTabs: {
        addThis: "Ditambahkan di sini",
        goThis: "Pindah ke sini",
        saveGo: "Simpan dan latih",
      },
      datePicker: {
        today: "Hari ini",
        yesterday: "Kemarin",
        theDayBeforeYesterday: "Sehari sebelum kemarin",
        thisWeek: "Minggu ini",
        lastWeek: "Minggu lalu",
        thisMonth: "Bulan ini",
        lastMonth: "Bulan lalu",
        lastSevenDay: "7 hari terakhir",
        lastThirtyDay: "30 hari terakhir",
        thisQuarter: "Kuartal ini",
        lastQuarter: "Kuartal terakhir",
        thisYear: "Tahun ini",
        lastYear: "Tahun lalu",
        lastYearFromToday: "Tahun terakhir",
      },
      pagination: { page: "Total {0} bar,{1} halaman" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Memuat pesan sebelumnya",
          bottom: "Muat lebih banyak pesan",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Tugas pemeriksaan kualitas",
        informationCollectTasksManageTitle: "Tugas pengumpulan",
        customerServiceCenterTitle: "Pusat Layanan Pelanggan",
        informationTitle: "Pusat Data",
        intelligentAnalysisTitle: "Analisis cerdas",
        semanticIntelligence: "Kecerdasan semantik",
        trainingCenterTitle: "Pusat Pelatihan",
        adminTitle: "Manajemen sistem",
      },
      qualityCheckingTask: {
        title: "Pemeriksaan kualitas panggilan",
        index: {
          tipOne:
            "Panggilan pemeriksaan kualitas digunakan untuk mengevaluasi kualitas kerja call center karyawan, Anda dapat menggabungkan bisnis Anda sendiri, mengatur formulir pemeriksaan kualitas yang sesuai",
          tipTwo:
            "Aturan yang sesuai dapat ditetapkan untuk tugas inspeksi kualitas, termasuk penyaringan dan pengambilan sampel. Setelah pemeriksa kualitas menilai, layanan pelanggan dapat memeriksa hasil pemeriksaan kualitas dan mengajukan banding lebih lanjut,",
          addTaskTitle: "Menambahkan tugas pemeriksaan kualitas",
          deleteTipTitle: "Hapus tugas pemeriksaan kualitas",
          deleteTipContent:
            "Tidak dapat dipulihkan setelah tugas inspeksi kualitas dihapus. Apakah Anda mengonfirmasi penghapusan?",
          checkingTask: "Tugas pemeriksaan kualitas",
          checkingTaskList: "Daftar tugas pemeriksaan kualitas",
          formList: "Daftar formulir",
          viewHelpDocuments: "Melihat dokumen bantuan",
        },
        manage: {
          taskSetting: {
            title: "Pengaturan tugas",
            form: "Formulir",
            basicInfo: "Informasi dasar",
            visibleRange: "Rentang yang terlihat",
            visibleRangeTip:
              "Tim layanan pelanggan dan layanan pelanggan yang dipilih dapat melihat tugas pemeriksaan kualitas, dan juga dapat diatur dalam kombinasi dengan izin, untuk tugas ini ketua tim layanan pelanggan, layanan pelanggan, inspektur kualitas, dll.",
            automaticSampling: "Pengambilan Sampel Otomatis",
            automaticSamplingTip:
              "Anda dapat mengatur aturan untuk pengambilan sampel otomatis, sehingga sistem akan menambahkan tugas pemeriksaan kualitas secara teratur sesuai dengan aturan yang Anda tetapkan, seperti mengambil 5% dari panggilan minggu lalu setiap hari Senin",
            samplingRate: "Frekuensi pengambilan sampel",
            samplingTime: "Waktu pengambilan sampel",
            samplingRange: "Rentang pengambilan sampel",
            samplingRule: "Aturan pengambilan sampel",
            fillterCondition: "Kondisi penyaringan",
            workflow: "Alur kerja pemeriksaan kualitas",
            appealsTime: "Izinkan waktu banding",
            appealsTimeTipOne: "Setelah penilaian diajukan",
            appealsTimeTipTwo: "Dalam Hari",
            appealsTimeTipThree:
              'Melebihi waktu banding yang diizinkan, status pemeriksaan kualitas catatan secara otomatis diubah menjadi "dikonfirmasi"',
            auditTime: "Waktu pemrosesan audit",
            auditTimeTipOne: "Setelah mengajukan banding",
            auditTimeTipTwo: "Dalam Hari",
            auditTimeTipThree:
              'Melebihi waktu pemrosesan audit, catatan mempertahankan skor asli, status pemeriksaan kualitas secara otomatis diubah menjadi "dikonfirmasi"',
            noAppealsMode: "Tidak ada mode banding",
            appealsMode: "Memungkinkan modus banding",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Layanan Pelanggan",
            customerServicesGroup: "Layanan Pelanggan",
            surveyList: "Daftar evaluasi",
            customFields: "Bidang kustom",
            fieldConfig: "Konfigurasi bidang",
            from: "Dari",
            owner: "Penanggung jawab",
            inspectorAndReviewerAuthority: "Pemeriksaan kualitas",
            inspector: "Inspektur kualitas",
            reviewer: "Pengulang",
            inspectorTip: "Inspektur kualitas dapat menilai tugas",
            reviewerTip: "Pengulas dapat menilai tugas",
            dataStatisticsAuthority: "Izin Statistik Data",
          },
          samplingRecords: {
            title: "Rekaman pengambilan sampel",
            index: {},
            manualSampling: {
              title: "Pengambilan Sampel",
              fillterCondition: "Kondisi penyaringan",
              samplingRule: "Aturan pengambilan sampel",
              agent: "Layanan Pelanggan",
              type: "Jenis",
              result: "Hasil",
              evaluate: "Evaluasi",
              relayNumber: "Nomor relay",
              source: "Sumber",
              task: "Misi",
              queueState: "Status antrian",
              callHanger: "Telepon menutup",
              queueTime: "Antrian memakan waktu",
              talkTime: "Durasi panggilan",
              bellTime: "Durasi dering",
              recordCountTipOne: "Total",
              recordCountTipTwo: "Catatan strip",
              customerServices: "Layanan Pelanggan",
              customerServicesGroup: "Layanan Pelanggan",
              surveyList: "Daftar evaluasi",
              customFields: "Bidang kustom",
              fieldConfig: "Konfigurasi bidang",
              defaultFields: "Bidang default",
            },
          },
          taskList: {
            title: "Daftar tugas",
            index: {
              batchDelete: "Penghapusan massal",
              batchSubmit: "Pengajuan massal",
              batchConfirm: "Konfirmasi massal",
              itemFields: "Skor bidang proyek",
              categoryFields: "Bidang klasifikasi tingkat kedua",
              forbidsAndDeadlies: "Item yang dilarang dan fatal",
              scoreFieldList: "Daftar bidang item penilaian",
              customerName: "Nama Pelanggan",
              cellphone: "Telepon",
              email: "Kotak surat",
              organization: "Perusahaan",
              description: "Deskripsi",
              tags: "Label",
              taskUserRole: "Peran pengguna tugas saat ini",
            },
            workBench: {
              title: "Merekam detail",
              checkForm: "Formulir pemeriksaan kualitas",
              waitEvaluate: "Untuk dievaluasi",
              alreadyEvaluate: "Dievaluasi",
              waitSubmit: "Untuk diserahkan",
              alreadySubmit: "Diserahkan",
              waitConfirm: "Untuk konfirmasi",
              alreadyConfirm: "Terkonfirmasi",
              waitReview: "Untuk diperiksa ulang",
              alreadyReview: "Telah ditinjau",
              alreadyDone: "Selesai",
              comment: "Komentar Komentar",
              totalScore: "Total skor",
              score: "Poin",
              remark: "Catatan",
              changeRecords: "Perubahan catatan",
              subject: "Tema",
              secondLevelScore: "Skor tingkat kedua",
              deadlyList: "Mematikan",
              forbidList: "Item yang dilarang",
              addToCheckTask: "Tambahkan ke tugas pemeriksaan kualitas",
              submitCheckRemark: "Mengajukan evaluasi pemeriksaan kualitas",
              changeCheckRemark:
                "Evaluasi pemeriksaan kualitas yang dimodifikasi",
              doneCheckRemark: "Evaluasi pemeriksaan kualitas selesai",
              submitCheckReview: "Diserahkan untuk pemeriksaan kualitas",
              submitCheckAppeal: "Mengajukan banding inspeksi kualitas",
              saveFlowSuccess: "Evaluasi berhasil.",
              saveFlowFailed: "Evaluasi gagal, silakan coba lagi nanti.",
              FormDetail: "Rincian formulir",
              callDetail: "Detail Rekaman Panggilan",
              sampleCalllogDetail: "Merekam detail",
              appealReasonTip: "Silakan masukkan alasan banding (wajib)",
              appeal: "Keluhan",
              appealReason: "Alasan banding",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Formulir Pemeriksaan Kualitas",
        basicInformationTitle: "Informasi dasar",
        indicatorsProjectTitle: "Proyek Indikator",
        specialProjectTitle: "Item khusus",
        totalScore: "Total skor",
        indicatorsName: "Nama indikator",
        score: "Skor",
        singleScore: "Poin tunggal",
        weight: "Berat",
        maxDeductScore: "Pengurangan poin terbanyak",
        add: "Peningkatan",
        detail: "Rincian formulir",
        list: {
          title: "Formulir pemeriksaan kualitas",
          tipOne:
            "Mengonfigurasi formulir inspeksi kualitas adalah langkah pertama dalam pekerjaan inspeksi kualitas. Anda dapat mengkonfigurasi formulir inspeksi kualitas yang paling cocok dengan bisnis perusahaan.",
          tipTwo:
            "Kami mendukung berbagai bentuk seperti penilaian langsung, penilaian berat, dan sistem pengurangan poin. Disarankan agar Anda menetapkan aturan dan menambahkannya ke sistem.",
        },
        basicInformation: {
          formTemplate: "Template bentuk",
          directScore: "Penilaian langsung",
          weightedScore: "Skor berat",
          deductScore: "Poin pengurangan",
          directScoreSecondLevel: "Skor Langsung-Level 2",
          weightedScoreSecondLevel: "Skor berat-Level 2",
          deductScoreSecondLevel: "Pengurangan poin-tingkat kedua",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Item yang dilarang",
          banProjectTip:
            "Ini dapat digunakan untuk memeriksa selama penilaian inspeksi kualitas, dapat dianggap sebagai pelanggaran, atau dapat dianggap sebagai suplemen untuk komentar tanpa mempengaruhi skor aktual.",
          deadlyProject: "Mematikan",
          deadlyProjectTip:
            "Item yang fatal dapat digunakan untuk memeriksa ketika skor pemeriksaan kualitas. Jika item yang fatal diperiksa, item pemeriksaan kualitas langsung akan dinilai 0 poin.",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Pemeriksaan kualitas panggilan",
          entiretyReportTitle: "Laporan keseluruhan",
          serviceGroupReportTitle: "Laporan kelompok layanan pelanggan",
          serviceReportTitle: "Laporan Layanan Pelanggan",
          entiretyReport: {
            pandect: "Ikhtisar",
            contrast: "Kontras",
            trend: "Tren",
            analyze: "Analisis",
            totalScore: "Total skor",
            banProject: "Item yang dilarang",
            deadlyProject: "Mematikan",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Daftar hasil pemeriksaan kualitas",
          taskSelectPlaceholder: "Pilih tugas pemeriksaan kualitas",
          agentSelectPlaceholder: "Pilih layanan pelanggan",
          refresh: "Menyegarkan",
          all: "Semua",
          unread: "Belum dibaca",
          inComplaint: "Banding",
          reviewed: "Telah ditinjau",
          totalScore: "Ulasan umum",
          detail: "Rincian pemeriksaan kualitas",
          audioText: "Merekam teks",
          callInfo: "Informasi panggilan",
          ticketInfo: "Informasi pesanan kerja",
          wechatInfo: "Informasi sesi",
          dialogText: "Teks percakapan",
          dialogInfo: "Pesan percakapan",
          customer: "Pelanggan",
          agent: "Layanan Pelanggan",
          none: "Tidak ada data inspeksi kualitas",
          appealSuccess: "Banding berhasil!",
          appealFailed: "Banding gagal, silakan coba lagi nanti!",
          pointListIsNull:
            "Titik pemeriksaan kualitas banding tidak bisa kosong!",
          pleaseSelectPoint: "Silakan pilih titik pemeriksaan kualitas!",
          unsupportedFormat: "Format ini tidak didukung untuk saat ini",
          shardingInformation: "Berikut ini adalah fragmentasi {0}",
          smartTags: "Label pintar",
          businessRecords: "Catatan bisnis",
          selectedPointsWarning:
            "Silakan centang item pemeriksaan kualitas untuk banding, tentukan pernyataan yang relevan untuk banding, dan kemudian klik Kirim.",
          applyReason: "Alasan banding (hingga 50 kata)",
          chatMessageTypes: {
            all: "Semua",
            text: "Teks",
            image: "Gambar",
            agree: "Setuju dengan konten sesi",
            video: "Video",
            voice: "Suara",
            file: "Dokumen",
            emotion: "Ekspresi",
            link: "Tautan",
            chatrecord: "Catatan obrolan",
            mixed: "Pesan Campuran",
            default: "Pesan tidak didukung",
            deleteFriend: "Menghapus teman",
            redpacket: "Amplop merah",
            card: "Kartu nama",
            disAgree: "Tidak setuju dengan konten percakapan",
            weapp: "Program kecil",
            externalRedpacket: "Amplop merah",
            meetingVoicevCall: "Pesan panggilan",
            uRL: "Halaman web",
            replyTimeout: "Balasan batas waktu",
            sphfeed: "Nomor video",
            chatRecordTitle: "Catatan obrolan",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Total skor",
            actualScore: "Penilaian",
            actualGrade: "Peringkat",
            appeal: "Keluhan",
            remark: "Catatan manual",
            none: "Tidak ada data detail pemeriksaan kualitas",
            pointEdit: "Klasifikasi pengeditan titik pemeriksaan kualitas",
          },
          changeList: {
            title: "Perubahan catatan",
            user: "Pengguna",
            point: "Titik pemeriksaan kualitas",
          },
          relatedRecord: {
            title: "Catatan terkait",
            qualityList: "Tugas pemeriksaan kualitas",
            none: "Tidak ada",
            check: "Lihat",
            loadMore: "Memuat lebih banyak",
          },
        },
      },
      grade: {
        title: "Peringkat pemeriksaan kualitas",
        subTitle:
          "Peringkat inspeksi kualitas digunakan untuk peringkat peringkat objek inspeksi kualitas setelah penilaian inspeksi kualitas selesai. Misalnya, dapat dikonfigurasi di bawah 60 poin tidak memenuhi syarat, 60-85 poin baik, dan lebih tinggi dari 80 poin sangat baik.",
        list: {
          up: "Pindah ke atas",
          down: "Geser ke bawah",
          del: "Hapus",
          case: "Secara otomatis bergabung dengan perpustakaan kasus",
          orderCase: "Basis data kasus default",
          info: "Peringkat default tidak cocok dan tidak dapat dihapus, jika tidak memenuhi salah satu peringkat di atas, dapatkan peringkat default",
          add: "Menambahkan rating",
          save: "Simpan",
          close: "Pembatalan",
          max: "Peringkat inspeksi kualitas dapat dikonfigurasi hingga 20",
          name: "Tidak disebutkan namanya",
          success: "Simpan Sukses",
        },
      },
      qualityInspectionList: {
        title: "Daftar pemeriksaan kualitas",
        fastFilter: "Pelanggaran inspeksi kualitas",
        fastFilterTip:
          "Sistem pengurangan poin adalah pelanggaran, dan sistem bonus adalah pelanggaran",
        titleWechat: "Menganalisis daftar",
        titleCollect: "Daftar akuisisi",
        status: "Menganalisis status",
        reQualityInspectionConfig: "Pilihan untuk memeriksa ulang tugas",
        pleaseSelectRecheckPoints:
          "Silakan pilih titik pemeriksaan kualitas yang perlu dipertanggungjawabkan ulang",
        beginReQualityInspection: "Mulai eksekusi",
        cancelReQualityInspection: "Batalkan tugas",
        reQualityInspection: "Pemeriksaan ulang",
        reQualityInspectionWechat: "Analisis ulang",
        reQualityInspectionUnderWay: "Tugas pemeriksaan ulang...",
        reQualityInspectionUnderWayTip:
          "Meninggalkan halaman setelah menutup jendela tidak akan mempengaruhi tugas",
        reQualityInspectionDone: "Pemeriksaan ulang telah selesai",
        reQualityInspectionDoneTip:
          "Sekarang telah berhasil menyelesaikan pemeriksaan ulang, silakan klik tombol konfirmasi",
        endReQualityInspection: "Mengakhiri tugas",
        alreadyDoneTip: "Selesai {0} bar",
        totalTip: "Total {0}",
        endReInspectionSuccess: "Tugas pemeriksaan ulang telah dihentikan",
        closeWindow: "Tutup jendela",
        endReInspectionFailed:
          "Misi pemeriksaan ulang gagal, silakan coba lagi nanti!",
        detail: {
          title: "Rincian pemeriksaan kualitas",
          wechatTitle: "Rincian analisis",
          titleCollect: "Rincian koleksi",
          callIndex: "Menghubungkan panggilan",
          textIndex: "Terkait percakapan",
          fileConfirm: "Konfirmasikan arsip?",
          fileConfirmDesc:
            "Skor pemeriksaan kualitas tidak dapat diedit setelah pengarsipan, mohon konfirmasi apakah akan mengajukan",
        },
        sysCloumnsContal: "Manajemen kolom sistem",
        personCloumnsContal: "Manajemen kolom pribadi",
      },
      spotCheckList: {
        title: "Daftar pengambilan sampel",
        onlyMyTip: "Lihat saja sampe saya",
        plInspector: "Silakan pilih petugas pengambilan sampel",
        allInspector: "Lihat semua",
        seInspector: "Lihat pemeriksa sampel yang ditunjuk",
        myInspector: "Lihat saja",
        noInspector: "Lihat petugas pengambilan sampel yang tidak ditugaskan",
        detail: { title: "Rincian pengambilan sampel" },
      },
      reviewList: {
        title: "Daftar ulasan",
        onlyMyTip: "Lihat saja ulasan saya",
        plInspector: "Silakan pilih resepsionis",
        allInspector: "Lihat semua",
        seInspector: "Lihat reviewer yang ditunjuk",
        myInspector: "Lihat saja",
        noInspector: "Lihat reviewer yang tidak ditugaskan",
        detail: { title: "Rincian ulasan" },
      },
      caseBaseList: {
        title: "Basis data kasus",
        del: "Hapus",
        delTitle: "Semua konten tidak akan disimpan setelah dihapus",
        cancel: "Pembatalan",
        actions: "Operasi",
        seeCaseBase: "Lihat kasus",
        seeDetail: "Lihat detail",
        edit: "Mengedit",
        save: "Simpan",
        addCaseBase: "Tambahkan basis data kasus",
        export: "Ekspor",
        ok: "Penentuan",
        tip: "Data panggilan dan percakapan khas dalam daftar pemeriksaan kualitas dapat disimpan ke perpustakaan kasus untuk pembelajaran dan berbagi yang mudah",
        detail: {
          choiceCaseBase: "Silakan pilih kasus",
          delCaseBase: "Menghapus kasus",
          addCaseBase: "Menambahkan kasus",
          successed: "Simpan Sukses",
          info: "Simpan atau batalkan konten yang sedang diedit terlebih dahulu",
          caseType: "Silakan pilih perpustakaan kasus yang ingin Anda gabung",
        },
      },
      testSetList: {
        title: "Set tes pemeriksaan kualitas",
        tip: "Data inspeksi acak oleh inspektur kualitas akan disimpan dalam konsentrasi tes inspeksi kualitas, yang nyaman untuk mengoptimalkan konfigurasi kondisi inspeksi kualitas dan aturan inspeksi kualitas untuk beberapa item kesalahan inspeksi mesin yang dibandingkan setelah inspeksi acak.",
        refresh: "Menyegarkan",
        reQualityInspectionUnderWay: "Dalam tugas evaluasi ulang...",
        reQualityInspectionUnderWayTip:
          "Meninggalkan halaman setelah menutup jendela tidak akan mempengaruhi tugas",
        reQualityInspectionDone: "Evaluasi ulang dilakukan",
        reQualityInspectionDoneTip:
          "Sekarang evaluasi ulang telah berhasil diselesaikan, silakan klik tombol konfirmasi",
        endReQualityInspection: "Mengakhiri tugas",
        alreadyDoneTip: "Selesai {0} bar",
        totalTip: "Total {0}",
        endReInspectionSuccess: "Tugas penilaian ulang telah berakhir",
        closeWindow: "Tutup jendela",
        endReInspectionFailed:
          "Evaluasi ulang misi gagal, silakan coba lagi nanti!",
        recheckPoint: "Menghitung ulang skor dan akurasi inspeksi kualitas",
        recheckPointTitle: "Opsi evaluasi ulang",
        delete: {
          reQualityInspectionUnderWay: "Massal menghapus tugas di...",
          reQualityInspectionUnderWayTip:
            "Meninggalkan halaman setelah menutup jendela tidak akan mempengaruhi tugas",
          reQualityInspectionDone: "Tugas penghapusan massal telah selesai",
          reQualityInspectionDoneTip:
            "Sekarang telah berhasil menyelesaikan penghapusan massal, silakan klik tombol konfirmasi",
          endReQualityInspection: "Mengakhiri tugas",
          alreadyDoneTip: "Selesai {0} bar",
          totalTip: "Total {0}",
          endReInspectionSuccess: "Penghapusan massal telah berakhir",
          closeWindow: "Tutup jendela",
          endReInspectionFailed:
            "Tugas penghapusan massal gagal, silakan coba lagi nanti!",
        },
        reultList: {
          assessmentTime: "Waktu penilaian terakhir",
          assessmentAll: "Penilaian Total",
          strip: "Strip",
          spot: "Titik",
          deviation: "Penyimpangan",
          accuracyRate: "Akurasi",
          info: "Tidak ada hasil evaluasi, silakan evaluasi",
          onlyDeviationInfo: "Lihat hanya catatan penyimpangan",
          againAssessment: "Penilaian ulang",
          screen: "Penyaringan",
          batchDelete: "Penghapusan massal",
          matching: "Cocok",
          ok: "Ya",
          noCancel: "Tidak.",
        },
      },
      smartWordsLibrary: {
        title: "Tesaurus cerdas",
        tip1: "Sinonim: Digunakan untuk mengurangi jumlah pelatihan untuk label semantik, kalimat yang mirip dengan sinonim tidak perlu mengulangi pelatihan",
        tip2: "Istilah profesional: Mengoptimalkan transliterasi, mengoptimalkan pemahaman bahasa alami, dan meningkatkan akurasi pengenalan dan akurasi analisis untuk kata benda yang tepat seperti nama perusahaan, nama produk, dan kosakata industri yang tidak akurat.",
        tip3: "Kepedulian: Fokus pada kata-kata yang ingin Anda ikuti dalam analisis cloud kata untuk membantu AI menganalisis hotspot sesuai dengan bisnis Anda saat ini",
        tip4: "Kata-kata pemblokiran: Memblokir kata-kata yang tidak ingin diperhatikan dalam analisis cloud kata untuk membantu AI menganalisis hotspot sesuai dengan bisnis saat ini",
        tip5: "Bobot kata: Digunakan untuk mengoptimalkan koreksi kesalahan pada pengenalan label semantik, dengan memodifikasi bobot pengaruh kata dalam kalimat, mengoptimalkan efek pengenalan semantik dalam skenario tertentu sesuai kebutuhan",
        tip6: "Data korpus: Masukkan teks paragraf besar untuk skenario bisnis masing-masing, untuk koreksi kesalahan transliterasi dan pelatihan ASR, untuk meningkatkan akurasi transliterasi ASR",
        upload: {
          uploadSynonymWords: "Mengimpor sinonim",
          uploadProfessionalWords: "Mengimpor istilah profesional",
          uploadAttentionWords: "Mengimpor kata fokus",
          uploadIgnoreWords: "Mengimpor Abaikan Kata",
          uploadWordsWeight: "Impor kata berat",
          uploadTypo: "Mengimpor set kata yang salah ketik",
          uploadCorpusData: "Mengimpor data porteks",
          uploadCorpusContent: "Mengimpor konten data corpus",
          firstUploadStep: "1. Unduh templat, isi dalam format templat",
          downloadTemplateMessage: "Download Template:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Klik tombol impor untuk mengunggah file EXCEl yang sesuai dengan format template",
          secondUploadCorpusDataStep:
            "2. Klik tombol impor untuk mengunggah file TXT yang sesuai dengan format template",
          postUploadDataFail: "Gagal mengirimkan data upload",
          pleaseUploadTemplate: "Silakan unggah file EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "Template file hanya dalam format JSON atau xlsx",
          templateNeedTxt:
            "Template file data berbasis bahan hanya untuk file TXT",
          uploadSuccess: "Impor berhasil",
          uploadFailed: "Gagal impor",
          downloadError: "Gagal mengunduh",
          downLoadTxtTitle: "Konten bahan. txt",
        },
        navTabNames: {
          synonymWords: "Sinonim",
          professionalWords: "Kata benda profesional",
          attentionWords: "Kata-kata perhatian",
          ignoreWords: "Abaikan kata",
          wordsWeight: "Berat kata",
          corpusData: "Data bahan",
          typoLibrary: "Kumpulan kata yang salah ketik",
        },
        synonymWords: {
          createSynonymWords: "Sinonim baru",
          editSynonymWords: "Sinonim untuk mengedit",
          addSynonymWords: "Menambahkan sinonim",
          pleaseEnterStandardWords: "Masukkan kata standar",
          synonymWordsExists:
            "Sinonim itu sudah ada dan tidak bisa ditambahkan",
          synonymWordsCanNotNull: "Sinonim yang ditambahkan tidak bisa kosong",
          pleaseAddSynonymWords: "Silakan tambahkan sinonim",
          addSuccess: "Tambahkan Sukses",
          editSuccess: "Berhasil dimodifikasi",
          deleteSuccess: "Hapus Sukses",
          deleteError: "Gagal menghapus",
          required: "*",
        },
        professionalWords: {
          create: "Kata benda baru",
          edit: "Pengeditan kata benda profesional",
          addTargetWords: "Menambahkan kata pengganti",
          targetWordsExists:
            "Kata pengganti sudah ada dan tidak dapat ditambahkan",
          targetWordsCanNotNull:
            "Kata-kata pengganti yang ditambahkan tidak bisa kosong",
          pleaseAddTargetWords: "Silakan tambahkan kata pengganti",
          pleaseEnter: "Masukkan kata benda profesional",
          wordsExists: "Kata benda itu sudah ada",
          createSuccess: "Tambahkan Sukses",
          editSuccess: "Berhasil dimodifikasi",
          deleteSuccess: "Hapus Sukses",
          deleteError: "Gagal menghapus",
          saveError: "Simpan gagal",
          addTargetWordsMaxNumberError:
            "Kata pengganti dapat ditambahkan hingga 100",
          addHomophonyWordsMaxNumberError:
            "Kata perisai dapat ditambahkan hingga 100",
        },
        attentionWords: {
          create: "Kata-kata perhatian baru",
          edit: "Fokus pada pengeditan kata",
          pleaseEnter: "Masukkan kata perhatian",
          wordsExists: "Kepedulian itu sudah ada",
          createSuccess: "Tambahkan Sukses",
          editSuccess: "Berhasil dimodifikasi",
          deleteSuccess: "Hapus Sukses",
          deleteError: "Gagal menghapus",
        },
        ignoreWords: {
          create: "Kata Abaikan Baru",
          edit: "Mengabaikan kata mengedit",
          pleaseEnter: "Masukkan Abaikan Kata",
          wordsExists: "The Abaikan kata sudah ada",
          createSuccess: "Tambahkan Sukses",
          editSuccess: "Berhasil dimodifikasi",
          deleteSuccess: "Hapus Sukses",
          deleteError: "Gagal menghapus",
        },
        wordsWeight: {
          createWordsWeight: "Kata bobot baru",
          editWordsWeight: "Mengedit kata berat",
          addWordsWeight: "Menambahkan kata bobot",
          pleaseEnterWeightWords: "Masukkan kata bobot",
          pleaseEnterWeight: "Masukkan bobot",
          weightNeedInZeroToHundred: "Bobot harus 0 ~ Antara 100",
          synonymWordsExists: "Kata bobot sudah ada",
          weightNeedNumber: "Berat Membutuhkan Format Integer",
          standardWeightInformation:
            "Berat referensi adalah 50, semakin besar bobotnya, semakin kuat pengaruhnya dalam analisis",
          createSuccess: "Tambahkan Sukses",
          editSuccess: "Berhasil dimodifikasi",
          deleteSuccess: "Hapus Sukses",
          deleteError: "Gagal menghapus",
        },
        corpusData: {
          createCorpusData: "Data bahan baru",
          editCorpusData: "Mengedit data bahan",
          addCorpusData: "Menambahkan data bahan",
          pleaseEnterCorpusDescription: "Silakan masukkan deskripsi bahan",
          pleaseEnterCorpusContent: "Masukkan konten bahan",
          synonymWordsExists: "Data Berbahan Sudah Ada",
          CorpusContentWordsOverSetting:
            "Jumlah kata konten dihitung lebih dari 20000 kata,",
          download: "Unduh",
          upload: "Unggah",
          see: "Lihat",
          canNotSave:
            "Silakan gunakan fitur impor, jika tidak, Anda tidak dapat menyimpan",
          createSuccess: "Tambahkan Sukses",
          editSuccess: "Berhasil dimodifikasi",
          deleteSuccess: "Hapus Sukses",
          deleteError: "Gagal menghapus",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Kata yang benar",
            wrongWord: "Kata-kata yang salah",
            wrongWordCount: "Jumlah kata yang salah",
            wrongWordTip: "Tekan tombol Enter untuk menambahkan",
            status: "Efektivitas",
          },
          create: "Kumpulan kata kesalahan ketik baru",
          edit: "Mengedit kata-kata yang salah ketik",
          tip: 'Kumpulan kata kesalahan ketik digunakan untuk mengoptimalkan efek "deteksi kesalahan ketik". Misalnya, "pengembalian uang" dikirim secara tidak sengaja sebagai "pengembalian uang". Anda dapat mengatur kesalahan ketik umum dan kata yang benar melalui fungsi ini. Perhatikan bahwa itu akan berlaku pada hari berikutnya setelah disimpan.',
          onlyChinese: "Hanya mendukung mengetik kata-kata Cina",
          wrongWordLengthErr:
            "Panjang setiap kata yang salah harus 2 ~ 10 kata",
        },
      },
      semanticTags: {
        title: "Label semantik",
        current: "Label semantik saat ini",
        reference1: "Tugas pemeriksaan kualitas: {0} - {1}",
        reference2: "Cerdas Tag: {0}",
        reference3: "Preprocessing Data: {0}",
        reference4: "Basis Pengetahuan: {0}",
        index: { import: "Impor label semantik" },
        detail: {
          new: "Label semantik baru",
          edit: "Menyunting label semantik",
          name: "Nama",
          standardSentences: "Kalimat standar",
          effectiveness: "Efektivitas",
          similarSentences: "Kalimat serupa",
          tip: "Dukungan untuk memanggil API entitas dengan # #, contoh: Apakah nomor ponsel Anda # sys_phone #?",
          excludeSimilarSentences: "Kecualikan kalimat serupa",
          intelligentRecommend: "Cerdas merekomendasikan konten serupa",
          fastTraining: "Pelatihan cepat:",
          qualify: "Reguler",
          recallKeyWord: "Kata kunci recall",
          excludeKeywords: "Mengecualikan kata kunci",
          excludeKeywordsInfo1:
            "Mengecualikan kata kunci dan aturan berlaku di atas semua logika, jika Anda menekan pengecualian, Anda tidak akan menekan label semantik",
          keyWordInfo1:
            "Kata kunci yang ditarik kembali mendukung beberapa baris untuk diisi, dipisahkan oleh koma bahasa Inggris, dan secara logis mengenai semua ketika mengisi banyak.",
          keyWordInfo2:
            "Kata kunci penarikan mendukung pengisian beberapa baris, dan logika mengisi beberapa baris dapat dibuat untuk setiap hit.",
          recallRegular: "Ingat aturan",
          excludeRegular: "Mengecualikan aturan",
          similaSentences: "Kecualikan kalimat serupa",
          simim: "Kesamaan",
          keyWordInfo3:
            "Mengecualikan kalimat serupa lebih disukai daripada semua logika. Jika Anda menekan kalimat serupa, Anda tidak akan menekan label semantik. Kesamaan default adalah 80%. Semakin besar kemiripan, semakin mirip.",
          keyWordInfo4:
            "Aturannya mirip dengan aturan, dengan awal dan akhir secara default. Non-harus digunakan dengan tanda kurung, misalnya [non-XXXX]",
          keyWordInfo5:
            "Fungsi-fungsi dalam modul lanjutan biasanya merupakan fungsi-fungsi eksperimental yang disiapkan untuk para ahli yang akrab dengan operasi. Jika Anda tidak yakin bagaimana Anda harus melakukannya, disarankan agar Anda tidak mengubah bagian ini.",
          keyWordInfo6:
            "Fungsi deteksi kalimat silang memberikan kemampuan tag semantik untuk mendeteksi kalimat.",
          keyWordInfo7:
            "Paling-paling berturut-turut melintasi karakter yang sama",
          keyWordInfo8: "Abaikan karakter yang berbeda",
          keyWordInfo9: "Kalimat kata untuk deteksi kalimat silang",
          keyWordInfo10: "Kalimat batas terpanjang tidak lebih panjang dari",
          keyWordInfo11: "Hapus persentase yang sama tidak kurang dari",
          keyWordInfo12: "Kesamaan tidak kurang dari",
          keyWordInfo13:
            "Kalimat serupa % akan dicocokkan dengan kesamaan (kemiripan default 80%)",
          keyWordInfo14:
            "Penghapusan kesamaan akan cocok sesuai dengan kesamaan kalimat. Dianjurkan untuk menghapus kalimat yang terlalu mirip untuk meningkatkan efisiensi deteksi.",
          keyWord: "Kata kunci",
          entity: "Entitas",
          ruleField: "Kata konsep",
          customRule: "Desain Aturan Wacana",
          fanhua: "Pertanyaan serupa",
          test: "Pengujian",
          testOk: "Uji Hit",
          senior: "Pengaturan lanjutan",
          seniorList: "Deteksi kalimat silang",
          up: "Aktifkan",
          down: "Tutup",
          ju: "Kalimat",
          oneWord: "Kata",
          simimNo: "Keturunan serupa",
          seniorField: "Ambang batas kesamaan",
          save: "Simpan",
          cancel: "Pembatalan",
          specialElements: "Elemen khusus",
          logicRule: "Aturan logika",
          every: "Beberapa pertandingan",
          recallOk: "Hit Recall Kata:",
          recallNo: "Kata-kata recall terjawab",
          ruleOk: "Memukul aturan recall",
          ruleNo: "Tidak ada aturan",
          rularOk: "Aturan hit",
          rularNo: "Aturan meleset",
          infoOk: "Hit kalimat standar atau kalimat serupa:",
          infoNo: "Kalimat standar atau kalimat serupa",
          fieldOk: "Hit untuk mengecualikan kalimat yang sama:",
          fieldNo: "Hilangkan kalimat serupa",
          excludeRuleNo: "Kecualikan aturan",
          excludeRuleOk: "Memukul aturan pengecualian",
          excludeKeywordNo: "Kata kunci absen",
          excludeKeywordOk: "Tekan kata kunci pengecualian",
          update: "Pembaruan berhasil",
          fanhuaList: "Dikirimkan ke halaman generalisasi",
          wordList:
            "Daftar pelatihan kosong dan tidak dapat dikirim ke daftar generalisasi",
          start: "Mulai",
          end: "Akhir",
          keyWordInfo15:
            "Bidang serupa ada dalam daftar generalisasi (konfigurasi lanjutan serupa untuk menghapus batasan)",
          saveSuccess: "Simpan Sukses",
          errInfo: "Ada bidang yang tidak diisi atau diisi dengan salah",
        },
        table: {
          deepTraining: "Pelatihan mendalam {0} roda",
          fastTraining: "Pelatihan cepat",
        },
      },
      intelligentCollection: {
        title: "Akuisisi cerdas",
        classification: "Daftar grup",
        new: "Koleksi baru",
        table: {
          name: "Nama Bidang",
          props: "Atribut Pelanggan",
          type: "Tipe Bidang",
          desc: "Deskripsi bidang",
        },
        detail: {
          isCustomerProps: "Simpan ke properti pelanggan",
          customerPropsHelper:
            "Setelah menyimpan, informasi yang dikumpulkan akan diperbarui ke informasi pelanggan. Tanpa menyimpan, informasi pelanggan tidak akan diperbarui",
          name: "Nama Bidang",
          type: "Tipe Bidang",
          text: "Teks",
          desc: "Deskripsi bidang",
          descPlaceHolder:
            "Jelaskan kegunaan dari informasi yang dikumpulkan di lapangan",
          condition: "Kondisi",
          logic: "Aturan",
          method: "Metode pengumpulan",
          message: "Konten informasi",
          range: "Rentang ekstraksi",
          entity: "Ekstraksi entitas",
          base: "Informasi dasar",
          collect: "Mengumpulkan informasi",
        },
      },
      intelligentTags: {
        title: "Label pintar",
        sessionLabelTitle: "Adegan sesi",
        keyEventsTitle: "Peristiwa kunci",
        customerLabelTitle: "Pelanggan Label",
        addClassification: "Buat klasifikasi label baru",
        defaultCategoryName: "Klasifikasi default",
        index: {
          createTag: "Label baru",
          createEvent: "Acara baru",
          editTagClassification: "Mengedit klasifikasi label",
          selectTagClassification: "Memilih Klasifikasi Label",
          editTagGrounp: "Mengedit pengelompokan",
          callTimeError:
            "Waktu bicara yang dipilih/rentang waktu mulai percakapan tidak dapat melebihi satu bulan (31 hari)",
          callRecordCountError:
            "Jumlah catatan adalah 0. Silakan pilih ulang waktu panggilan/percakapan",
          selectItmeCountError:
            "Silakan pilih label pintar yang membutuhkan operasi batch",
          redoSuccess:
            "Jalankan kembali berhasil, silakan pergi ke pusat misi untuk melihat",
          redoError: "Eksekusi ulang gagal, coba lagi nanti",
          timeError:
            "Waktu permintaan tidak dapat melebihi satu bulan (31 hari)",
          categoryName: "Nama Kategori",
          categoryColor: "Klasifikasi Warna",
          newTagClassification: "Klasifikasi Label Baru",
          newEventClassification: "Klasifikasi baru",
          tagClassification: "Klasifikasi Label",
          eventClassification: "Klasifikasi Peristiwa",
        },
        detail: {
          new: "Label Cerdas Baru",
          edit: "Mengedit label pintar",
          tagName: "Nama",
          tagDesc: "Deskripsi",
          category: "Klasifikasi",
          tagStatus: "Efektivitas",
          operator: "Operator",
          rule: "Aturan",
          labelClassAtion: "Jenis Label",
          labelClassAtionTip1: "Label: Tag berdasarkan aturan",
          labelClassAtionTip2:
            "Kategori label: tag tag berdasarkan pelatihan model",
          group: "Pengelompokan",
          checkLabel: "Pilih kategori",
          warn: "Peringatan dini",
          warnTips:
            "Setelah membuka peringatan, ketika sesi memenuhi aturan peristiwa kritis ini, orang yang ditunjuk akan diminta untuk menerima pengingat di tempat yang harus dilakukan",
          warnError: "Silakan pilih personil peringatan",
        },
        subTitle: {
          common: "Sesi cerdas Tags",
          wecom: "Label mikro perusahaan",
        },
      },
      customerPortrait: {
        title: "Potret pelanggan",
        index: { import: "Impor potret pelanggan" },
        searchPlaceHolder: "ID pelanggan, nama panggilan, atau telepon",
        callCount: "Jumlah percakapan:",
        callDuration: "Total durasi panggilan:",
        dialogCount: "Jumlah total percakapan:",
        keyWordsStatistics: "Kata kunci statistik (Top 20 & Kepedulian)",
        keyWordsCount: "({0})",
        businessTag: "Label bisnis",
        keyWordsCloudImg: "Kata kunci gambar awan",
        customerEmotionTrend: "Tren perubahan sentimen pelanggan",
        notFoundCustomer: "Tidak ada pelanggan yang diambil",
        noKeyWords: "Kata kunci terkait tidak ditemukan",
        noBusinessTags: "Label bisnis terkait tidak ditemukan",
        getKeyWordsStatisticsDataError:
          "Permintaan statistik kata kunci pelanggan gagal",
        getBusinessTagsStatisticsDataError:
          "Kegagalan permintaan label bisnis pelanggan",
        personCustome: "Tidak memeriksa informasi pelanggan",
        notimeInfo: "Tidak ada informasi emosional yang ditemukan",
        timeList: "Rentang tanggal",
        operator: "Label pintar",
        placeHolder: "Silakan pilih",
        placeOperator: "Silakan pilih label pintar",
        search: "Pertanyaan",
        reset: "Atur ulang",
        searchHolder: "Pencarian",
        call: "Panggilan telepon",
        text: "Percakapan",
      },
      sentimentAnalysis: { title: "Temuan opini publik" },
      saleSentimentAnalysis: { title: "Analisis opini publik" },
      dataPreprocess: {
        title: "Preprocessing data",
        index: {
          createData: "Buat data baru",
          selectDataSource: "Memilih sumber data",
          selectStatus: "Memilih validitas",
          callTimeError:
            "Waktu bicara yang dipilih/rentang waktu mulai percakapan tidak dapat melebihi satu bulan (31 hari)",
          callRecordCountError:
            "Jumlah catatan adalah 0. Silakan pilih ulang waktu panggilan/percakapan",
          selectItmeCountError:
            "Silakan pilih data yang Anda butuhkan untuk melakukan operasi massal",
          redoSuccess:
            "Jalankan kembali berhasil, silakan pergi ke pusat misi untuk melihat",
          redoError: "Eksekusi ulang gagal, coba lagi nanti",
        },
        detail: {
          new: "Buat data baru",
          edit: "Mengedit data",
          name: "Nama",
          desc: "Deskripsi",
          dataSource: "Sumber Data",
          status: "Efektivitas",
          entity: "Ekstraksi entitas",
          operator: "Operator",
          rule: "Aturan",
        },
      },
      connectedByConversation: {
        title: "Koneksi dialog",
        list: {
          title: "Fungsi koneksi dialog",
          info: "Fungsi asosiasi percakapan dapat menghubungkan panggilan yang berbeda dengan percakapan sesuai dengan aturan yang telah ditetapkan",
          rule: "Aturan terkait percakapan",
          source: "Silakan pilih sumber data",
          classification: "Silakan pilih kategori",
          choose: "Silakan pilih",
          input: "Silakan masukkan",
          save: "Simpan",
          cancel: "Pembatalan",
          fieldwei: "Buang",
          fieldNot: "Ada bidang yang belum diisi",
          fiedldlengthNo: "Panjang bidang melebihi batas",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Kata kunci dapat diatur beberapa, dipisahkan dengan koma",
          applyRole: "Mendeteksi peran",
          preOperator: "Operator depan",
          applyOperatorScope: "Rentang deteksi",
          addOperator: "Menambahkan operator",
          noPreOperator: "Tidak ada",
          from: "UU No.",
          to: "Kalimat ke No.",
          end: "Kalimat",
          testRegular: "Menguji ekspresi reguler",
          test: "Pengujian",
          matchSuccess: "Cocokkan Sukses",
          matchError: "Gagal cocok",
          querySementicTags: "Memilih label semantik",
          reQuerySementicTags: "Memilih ulang label semantik",
          sementicTag: "Label semantik",
          standardSentence: "Kalimat standar",
          similarity: "Kesamaan",
          actions: "Operasi",
          noFitTag: "Tidak menemukan label yang tepat,",
          addSemanticTag: "Tambahkan tab semantik baru",
          pleaseEnterRegular: "Masukkan ekspresi reguler",
          pleaseEnterRegularText: "Masukkan konten yang perlu diverifikasi",
          newAssociateComponentTip:
            "Jika nilainya adalah tipe angka, gunakan fitur versi lama",
        },
        filterManage: {
          noValueTip: "Tidak ada data",
          allCall: "Semua panggilan",
          allDialogue: "Semua percakapan",
          allWechat: "Sesi penuh",
          getFiltersListFailed:
            "Mendapatkan daftar filter gagal, coba lagi nanti!",
          getFilterDetailFailed:
            "Gagal mendapatkan detail filter, silakan coba lagi nanti!",
          getAllCountFailed:
            "Dapatkan jumlah total panggilan/percakapan yang gagal, silakan coba lagi nanti!",
        },
        listManage: {
          searchPlaceHolder: "Silakan masukkan pencarian kata kunci",
          filterConditions: "Kondisi penyaringan",
          records: "Rekam",
          getRecordsFailed: "Gagal mendapatkan catatan, coba lagi nanti!",
        },
        miniListManage: {
          getRecordsFailed: "Gagal mendapatkan catatan, coba lagi nanti!",
        },
        applyTemplates: {
          title: "Template pemeriksaan kualitas yang berlaku",
          collectionTitle: "Template akuisisi yang berlaku",
        },
        recordDetail: {
          showWaveAudio: "Tampilkan rekaman",
          getDetailFailed: "Gagal mendapatkan detail, silakan coba lagi nanti!",
          getSmartTagsFailed:
            "Gagal mendapatkan label pintar, coba lagi nanti!",
          getTemplatesFailed:
            "Gagal mendapatkan template yang berlaku, silakan coba lagi nanti!",
        },
        changeList: {
          sampleStatus: "Status pengambilan sampel",
          sampleAssignInspector: "Penugasan petugas pengambilan sampel",
          reviewAssignInspector: "Penugasan reaudit",
          read: "Sudah dibaca",
          distributivePerson: "Penugasan",
          totalScore: "Total skor",
        },
        qualityCheckScore: {
          totalScore: "Total skor",
          actualScore: "Skor",
          actualGrade: "Peringkat",
          appeal: "Keluhan",
          remark: "Catatan",
          manualRemark: "Catatan manual",
          none: "Tidak ada data detail pemeriksaan kualitas",
          interactiveTip:
            "Gunakan identifikasi interaktif untuk mendeteksi penggunaan FAQ layanan pelanggan",
          interactiveIsEmptyTip:
            "Konten identifikasi interaktif tidak terdeteksi",
        },
        semanticMark: {
          semanticContent: "Kalimat pelabelan",
          markedSimilarTitel: "Label semantik serupa telah ditandai",
          markedExcludeTitle:
            "Label semantik serupa tidak termasuk yang ditandai",
          taskTagTitle: "Tugas terkait semantik Tags",
          similarTagTitle:
            "Identifikasi tag semantik serupa (Kesamaan> 40%,Top 20)",
          delete: "Hapus",
          markSimilar: "Tanda serupa",
          markExclude: "Kecualikan kesamaan",
          none: "Tidak ada",
        },
        selectCallTime: {
          callTime: "Waktu bicara/waktu mulai sesi",
          callTimeRemind:
            "Anda dapat memilih hingga satu kali dalam satu tahun terakhir untuk satu percakapan dalam satu bulan (31 hari)",
          callRecordCount:
            "Anda telah memilih total {0} log panggilan/percakapan",
          getCallRecordError: "Gagal mendapatkan rekor, coba lagi nanti",
        },
      },
      informationEntities: {
        title: "Entitas informasi",
        index: { import: "Mengimpor entitas informasi" },
        detail: {
          new: "Entitas Informasi Baru",
          edit: "Mengedit entitas informasi",
          name: "Nama",
          apiName: "Nama API",
          activeFlag: "Efektivitas",
          remark: "Deskripsi",
          logicExp: "Aturan entitas",
          sentenceList: "Konten entitas",
          tip: "Konten entitas mendukung input beberapa, dipisahkan oleh spasi, untuk identik dengan entitas yang sama",
          remarkLengthError: "Panjang deskripsi tidak dapat melebihi 255",
          nameLengthError: "Panjang nama tidak dapat melebihi 40",
          entityDuplicateError: "Pengulangan konten entitas",
        },
      },
      interactiveRecognition: {
        title: "Identifikasi interaktif",
        classify: "Klasifikasi",
        addClassify: "Tambahkan klasifikasi",
        editClassify: "Mengedit kategori",
        index: { import: "Mengimpor identifikasi interaksi" },
        detail: {
          new: "Interaksi Cerdas Baru",
          edit: "Interaksi Cerdas Mengedit",
          type: "Jenis",
          standardQuestion: "Pertanyaan Standar",
          similarQList: "Masalah serupa",
          exceptSimilarQList: "Mengecualikan masalah serupa",
          category: "Klasifikasi",
          targetType: "Target Deteksi Masalah",
          status: "Efektivitas",
          standardAnswer: "Jawaban standar",
          similarAList: "Jawaban serupa",
          wrongAList: "Jawaban yang salah",
        },
      },
      businessAnalysis: {
        title: "Analisis Bisnis",
        tip1: "Analisis tren bisnis: ordinat adalah jumlah dialog, yaitu, jumlah total dialog yang memenuhi kondisi, dan absis adalah waktu",
        tip2: "Analisis relevansi: analisis korelasi dari beberapa indikator, tampilan suspensi mouse data korelasi situasi, jumlah data, korelasi, tingkat pengulangan",
        tip3: "Analisis konversi: Analisis beberapa indikator dapat membentuk hubungan konversi timbal balik. Standar penentuan konversi adalah panggilan/dialog yang sama. Jika dua indikator yang berbeda dapat dipukul, kedua indikator ini dapat dianggap memiliki hubungan konversi.",
      },
      knowledgeBase: {
        title: "Basis pengetahuan",
        list: {
          add: "Tambahkan sub-klasifikasi",
          delete: "Hapus klasifikasi",
          categoryName: "Nama Kategori",
          createSuccess: "Subkategori berhasil dibuat",
          keyWord: "Kata kunci",
        },
        detail: {
          type: "Jenis",
          status: "Status",
          classification: "Klasifikasi",
          standardQuestion: "Pertanyaan Standar",
          answerDetectionRange: "Jangkauan deteksi jawaban",
          standardAnswer: "Jawaban standar",
        },
      },
      callManage: {
        title: "Filter rekaman panggilan",
        listTitle: "Manajemen panggilan",
        detailTitle: "Detail panggilan",
      },
      dialogueManage: {
        title: "Filter teks percakapan",
        listTitle: "Manajemen percakapan",
        detailTitle: "Rincian percakapan",
      },
      weChatManage: {
        title: "Perusahaan WeChat Sesi Filter",
        listTitle: "Manajemen sesi perusahaan WeChat",
        detailTitle: "Detail Sesi WeChat Perusahaan",
      },
      speechRecognition: {
        title: "Pengenalan suara",
        asr: "Pengenalan suara",
        asrRemind:
          "Mematikan sakelar ini akan menyebabkan kegagalan fungsi suara ke teks dan fungsi pemeriksaan kualitas mesin",
        asrConfig: "Penyaringan ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Setelah diaktifkan, transliterasi ASR IM akan dilakukan",
        asrConfigRemind:
          "Setelah diaktifkan, hanya panggilan yang memenuhi syarat yang akan melakukan konten ASR",
        correction: "Koreksi kesalahan cerdas",
        correctionRemind:
          "Setelah diaktifkan, kontennya dari \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words' target = '_ blank'> smart tesaurus-kata benda profesi </a>\" dipaksa untuk konversi kesalahan",
        correctionConfig: "Filter koreksi kesalahan",
        advancedSettings: "Pengaturan lanjutan",
        advancedSettingsTip:
          "Pilih model ASR yang ditentukan untuk data yang ditentukan.",
        mandarinASRModel: "Model ASR Mandarin",
        cantoneseASRModel: "Model ASR Kanton",
        englishASRModel: "Model ASR Bahasa Inggris",
        correctionConfigRemind:
          "Setelah diaktifkan, hanya panggilan yang memenuhi kriteria berikut yang akan diperbaiki",
        customJudgeLogicIsNull: "Kustom logika tidak bisa kosong",
        redoCorrection: "Jalankan kembali koreksi kesalahan",
        callTime: "Pilih waktu bicara",
        callTimeRemind:
          "Anda dapat memilih catatan panggilan tidak lebih dari satu bulan (31 hari) dalam satu tahun terakhir",
        callRecordCount: "Anda telah memilih catatan panggilan total {0}",
        redoCorrectionSubmit: "Mengajukan eksekusi",
        callTimeError:
          "Rentang waktu bicara yang dipilih tidak dapat melebihi satu bulan (31 hari)",
        callRecordCountError:
          "Jumlah catatan panggilan adalah 0. Silakan pilih kembali waktu panggilan",
        redoCorrectionSuccess:
          "Koreksi ulang berhasil, silakan periksa di pusat tugas",
        redoCorrectionError: "Koreksi ulang gagal, silakan coba lagi nanti",
      },
      role: {
        title: "Manajemen peran",
        create: "Peran baru",
        detail: {
          title: "Karakter",
          new: "Peran baru",
          view: "Lihat karakter",
          edit: "Mengedit peran",
          navTabNames: {
            basicInfo: "Izin menu",
            taskPermissions: "Izin tugas pemeriksaan kualitas",
            dataPermissions: "Izin Data",
          },
          basicInfo: { roleName: "Nama", permissions: "Izin" },
          taskPermissions: {
            allTask: "Semua tugas pemeriksaan kualitas",
            myCreatedTask: "Tugas pemeriksaan kualitas yang saya buat",
          },
          dataPermissions: {
            dataPermissionsConfig: "Konfigurasi izin data",
            tips: "Karakter ini dapat melihat data di bawah kategori yang dipilih",
            select: "Pilih kategori",
            selectPlaceholder:
              "Silakan pilih kategori yang memerlukan izin untuk melihat data",
          },
        },
      },
      dataMigration: {
        title: "Migrasi data",
        dataUpload: "Data masuk",
        dataDowload: "Data bergerak keluar",
        error: "Silakan pilih tugas pemeriksaan kualitas untuk diekspor",
        list: {
          info: "Silakan pilih tugas pemeriksaan kualitas untuk pindah",
          listInfo:
            "Pilih apakah Anda ingin memindahkan data yang relevan untuk tugas pemeriksaan kualitas (misalnya, tesaurus cerdas, label semantik, entitas informasi, interaksi cerdas, dll.)",
          noInfo:
            "Silakan pilih apakah Anda ingin memindahkan data non-kualitas lainnya",
          ownInfo: "Hanya pindah dari misi",
          everyInfo: "Pindah tugas terkait",
          allInfo: "Pindah semua",
          callFilter: "Filter panggilan",
          talkFilter: "Filter percakapan",
          smartTag: "Label pintar",
        },
        upload: {
          info: "1. Klik tombol Upload untuk mengunggah file JSON yang memenuhi format",
          uploadSuccess: "Berhasil diunggah",
          uploadError: "Gagal mengunggah",
        },
      },
      dataAnalysis: {
        businessStatistics: "Statistik Bisnis",
        qualityEffect: "Kesehatan pemeriksaan kualitas",
        qualityAnalysis: "Analisis pemeriksaan kualitas",
        generalInspecitionReport: "Laporan Umum",
        fullAmountWork: "Beban kerja inspektur kualitas",
        intelligentTagsAnalysis: "Analisis Label",
        saleIntelligentTagsAnalysis: "Analisis label cerdas",
        generalInspecitionReportTip:
          "Total data: Statistik waktu dan rentang tugas saat ini terbatas, jumlah total data panggilan dan percakapan, di antaranya jumlah & persentase data inspeksi kualitas cerdas, jumlah & persentase data inspeksi acak, jumlah keluhan, tingkat pemeriksaan ulang acak dan grafik tren waktu",
        groupTest: "Tes kelompok",
      },
      uploadDownloadList: {
        title: "Pusat Misi",
        tabs: {
          upload: "Mengunggah impor",
          download: "Download ekspor",
          taskCenter: "Pelaksanaan tugas",
        },
      },
      uploadDownloadModel: {
        title: "Unggah dan unduh",
        list: {
          clear: "Kosongkan",
          success: "Sukses",
          fail: "Gagal",
          hand: "Dalam proses",
          noInfo: "Tidak ada data",
        },
      },
      billingCenter: {
        title: "Pusat penagihan",
        export: "Ekspor",
        limit: "Batas Pengingat",
        tips: "Hasil penagihan akan bias karena pembulatan, hanya untuk referensi",
        dashboard: {
          remainingQuota: "Sisa kuota saat ini",
          usedQuota: "Akumulasi kuota yang digunakan",
          voiceQuality: "Pemeriksaan kualitas suara:",
          voice: "Suara:",
          textQuality: "Pemeriksaan kualitas teks:",
          text: "Teks:",
          hour: "Jam",
          tenThousandHour: "Sepuluh ribu jam",
          day: "Hari",
          cutoff: "Batas waktu",
          warning:
            "(Kuota yang tersisa tidak mencukupi, silakan isi ulang secepatnya)",
          isClosed: "(Status tertutup)",
        },
        table: {
          quotaRecord: "Catatan Kuota",
          timeFilter: "Pilih tanggal:",
          search: "Pertanyaan",
          reset: "Atur ulang",
          id: "Nomor aliran air",
          type: "Jenis",
          qualityType: "Jenis pemeriksaan kualitas",
          quotaChange: "Perubahan",
          surplus: "Saldo",
          changeTime: "Waktu",
          voiceQuality: "Pemeriksaan kualitas suara",
          textQuality: "Pemeriksaan kualitas teks",
          sizeChangeText: "Tampilan per halaman",
          used: "Menggunakan",
          give: "Diberikan",
          deduction: "Pengurangan",
          callTime: "Waktu bicara",
          count: "Jumlah data yang diproses",
          page: "Total {0} bar,{1} halaman",
        },
        limitModal: {
          call: "Kuota pemeriksaan kualitas suara lebih rendah dari",
          callFix: "Pengingat jam",
          dialogue:
            "Waktu yang tersisa untuk pemeriksaan kualitas teks kurang dari",
          dialogueFix: "Hari untuk pengingat",
        },
      },
      messageCenter: {
        replyTitle: "Membalas saya",
        answerTitle: "@ My",
        shareTitle: "Bagikan milik saya",
      },
      columnsManage: { title: "Manajemen bidang pemeriksaan kualitas" },
      recordUpload: {
        list: {
          task: "Tugas pemeriksaan kualitas",
          user: "Operator",
          uploadTime: "Mengunggah waktu",
          endTime: "Waktu akhir",
          total: "Jumlah total yang diunggah",
          success: "Pemeriksaan kualitas berhasil",
          failed: "Pemeriksaan kualitas gagal",
          progress: "Kemajuan pemeriksaan kualitas",
          inProgress: "Dalam proses",
          finished: "Selesai",
        },
        steps: {
          uploading: "Unggah file rekaman",
          baseInfo: "Isi informasi dasar",
          start: "Mulai pemeriksaan kualitas",
        },
        uploadComponent: {
          dragger: "Seret untuk menambahkan file",
          or: "Atau",
          selectFile: "Memilih file",
          des: "Mendukung wav, format mp3, tingkat sampel 8000Hz,16-bit file",
          limit1: "Unggah paling banyak sekaligus",
          limit2: "File, setiap ukuran file tidak melebihi",
          uploading: "Dalam unggahan",
          finished: "Upload selesai",
          success: "Sukses",
          failure: "Gagal",
        },
        baseInfo: {
          task: "Pilih tugas pemeriksaan kualitas",
          type: "Memilih bentuk rekaman",
          agentSide: "Saluran layanan pelanggan",
          excel: "Mengunggah data on-road",
          checkFileInfo: "Informasi file verifikasi",
          excelFileError: "Silahkan upload data on-road",
          checking: "Verifikasi...",
          reCheck: "Verifikasi ulang",
          success: "Verifikasi berhasil",
          excelUoloadFail: "Gagal meng-upload data on-road",
          tips: {
            tip1: "1. Nama file rekaman di ecxel harus sama dengan nama rekaman yang diunggah, dan tidak dapat diganti namanya",
            tip2: "2. Sistem memeriksa ulang dengan nama file rekaman dan ID panggilan, dan pengulangan akan menyebabkan kegagalan pemeriksaan kualitas",
            tip3: "3. Templat rekaman excel yang diunduh, warna font merah adalah bidang wajib",
            tip4: "4. Format bidang untuk tipe waktu adalah: yyyy-mm-dd hh:mm:ss, mis. 2021-04-20 17:36:30",
          },
        },
        title: "Unggah rekaman lokal",
        checkTitle: "Pemeriksaan kualitas rekaman",
        start: "Mulai pemeriksaan kualitas",
        disabledTips:
          "Silakan periksa file rekaman sebelum pemeriksaan kualitas",
        tips: "Dokumen rekaman dengan nama yang sama mencakup hasil pemeriksaan kualitas dan informasi dasar",
        tips1: "Seret file ke sini atau klik Unggah",
        tips2: "Dukungan",
        tips3: "Format",
        type: "wav、mp3",
        tips4: "Unggah paling banyak sekaligus",
        tips5: "File, setiap ukuran file tidak melebihi",
        tipsOverSize:
          "Antrean saat ini sudah penuh. Harap hapus file dalam antrian sebelum diunggah",
        size: "500M",
        count: "20",
        uploaded: "File rekaman yang diunggah",
        uploading: "({0}%)",
        uploadFinished: "(Upload selesai)",
        bottomTitle: "Pengumpulan informasi rekaman:",
        recordType: "Pilih bentuk rekaman:",
        double: "Rekaman panggilan dua saluran",
        single: "Rekaman panggilan saluran tunggal",
        recordBaseInfo: "Unggah informasi dasar rekaman:",
        addBtn: "Menambahkan file",
        downloadTemplate: "Unduh template. excel",
        checkRecord: "Informasi rekaman verifikasi",
        checking: "Verifikasi...",
        reCheck: "Verifikasi ulang",
        checkAll: "Pilih semua",
        cancelCheckAll: "Batalkan semua pilihan",
        delete: "Hapus",
        deleteLotSize: "Penghapusan massal",
        agentChannel: "Saluran layanan pelanggan:",
        left: "Saluran kiri",
        right: "Saluran kanan",
        donotKonw: "Tidak tahu",
        mission: "Memilih tugas pemeriksaan kualitas:",
        uploadFail: "Gagal mengunggah",
        repeat: "File duplikat",
        overCount: "Jumlah file melebihi batas",
        overSize: "Ukuran file melebihi batas",
        uoloadFail: "Gagal mengunggah ke cloud",
        formatError: "Kesalahan Format",
        seccess: "Verifikasi berhasil",
        fail: "Kegagalan verifikasi",
        recordChecking: "Dalam pemeriksaan kualitas rekaman, silakan...",
        recordCheckSuccess: "Pemeriksaan kualitas rekaman selesai",
        recordCheckFail:
          "Pemeriksaan kualitas rekaman gagal, harap periksa apakah rekaman duplikat atau tidak valid",
        backToIndex: "Mengembalikan daftar upload",
        recordCheckIsStarted:
          "Saat ini ada tugas pemeriksaan kualitas yang sedang dilakukan, silakan memulai tugas baru setelah tugas saat ini selesai",
      },
      customerServicePortrait: {
        title: "Potret Layanan Pelanggan",
        tip1: "Hukum distribusi panjang kalimat: Distribusi panjang kalimat, statistik sesuai dengan jumlah kata panjang kalimat, hanya menghitung layanan pelanggan",
        tip2: "Hukum distribusi kecepatan bicara: Distribusi kecepatan bicara, statistik sesuai dengan kecepatan bicara (kata/menit), hanya menghitung layanan pelanggan",
        tip3: "Hukum distribusi waktu respons: distribusi waktu respons, hanya menghitung respons layanan pelanggan terhadap situasi pelanggan",
        tip4: "Kepatuhan pemeriksaan kualitas: tingkat kepatuhan untuk titik pemeriksaan kualitas",
        tip5: "Skor rata-rata tugas inspeksi kualitas: skor rata-rata tugas inspeksi kualitas",
        contol: {
          date: "Pilih tanggal:",
          today: "Hari ini",
          thisMonth: "Bulan ini",
          lastMonth: "Bulan lalu",
          thisQuarter: "Kuartal ini",
          lastQuarter: "Kuartal terakhir",
          thisYear: "Tahun ini",
          lastYear: "Tahun lalu",
          customer: "Layanan Pelanggan:",
          search: "Pertanyaan",
          reset: "Atur ulang",
          export: "Ekspor",
          indicator: "Manajemen Indikator",
          selectPlaceholder:
            "Silakan pilih layanan pelanggan yang perlu dibandingkan",
          warning: "Pilih hingga 5 layanan pelanggan untuk perbandingan",
          warning2:
            "Silakan pilih setidaknya 1 layanan pelanggan untuk pertanyaan",
          dataWarning:
            "Mendukung hanya permintaan data hingga 366 hari setelah tanggal mulai",
          seccuss: "Keberhasilan ekspor rekaman",
        },
        echarts: {
          distributionLaw: "Hukum distribusi",
          count: "Jumlah",
          bout: "Kali",
        },
        portrait: {
          ability: "Kapasitas Layanan Pelanggan Dimensi",
          option: "Konfigurasi",
          abilityOption: "Konfigurasi Dimensi Kemampuan",
          placeholder:
            "Kemampuan untuk memasukkan dimensi pencarian kata kunci",
          save: "Simpan",
          warning:
            "Silakan pilih setidaknya 3 indikator untuk konfigurasi, jumlah indikator hingga 10 item",
        },
        label: { title: "Layanan Pelanggan Label Bisnis" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Peringkat Layanan Pelanggan",
        select: {
          date: "Pilih tanggal:",
          today: "Hari ini",
          thisMonth: "Bulan ini",
          lastMonth: "Bulan lalu",
          thisQuarter: "Kuartal ini",
          lastQuarter: "Kuartal terakhir",
          thisYear: "Tahun ini",
          lastYear: "Tahun lalu",
          group: "Layanan Pelanggan:",
          groupPlaceholder: "Silakan pilih layanan pelanggan",
          customer: "Layanan Pelanggan:",
          customerPlaceholder: "Silakan pilih layanan pelanggan",
          search: "Pertanyaan",
          reset: "Atur ulang",
          compared: "Kontras",
          export: "Ekspor",
          column: "Kolom",
          warning: "Pilih hingga 5 layanan pelanggan untuk perbandingan",
          warning2: "Silakan pilih setidaknya satu layanan pelanggan",
          dataWarning:
            "Mendukung hanya permintaan data hingga 366 hari setelah tanggal mulai",
          selected: "Layanan pelanggan terpilih:",
          seccuss: "Keberhasilan ekspor rekaman",
        },
        compared: {
          title: "Pilih layanan pelanggan",
          return: "Pembatalan",
          compared: "Kontras",
          tips: "Silakan pilih 2-5 layanan pelanggan untuk perbandingan",
          warning: "Hanya mendukung 2-5 layanan pelanggan untuk perbandingan",
        },
        transfer: { leftTitle: "Daftar item", rightTitle: "Item tampilan" },
        table: {
          page: "Total {0} bar,{1} halaman",
          actionTitle: "Operasi",
          action: "Lihat",
        },
      },
      conceptWord: {
        title: "Kata konsep",
        new: "Kata konsep baru",
        control: {
          search: "Pencarian:",
          searchPlaceholder: "Pencarian dengan nama atau kata kunci",
          commit: "Pertanyaan",
          reset: "Atur ulang",
          import: "Impor",
          export: "Ekspor",
          seccuss: "Keberhasilan ekspor rekaman",
        },
        modal: { title: "Mengimpor kata konsep" },
        table: {
          sizeChangeText: "Tampilan per halaman",
          page: "Total {0} bar,{1} halaman",
          edit: "Mengedit",
          delete: "Hapus",
          request:
            "Tidak dapat dipulihkan setelah dihapus. Apakah Anda mengonfirmasi penghapusan?",
          cancel: "Pembatalan",
          title: {
            conceptWord: "Kata konsep",
            describeWordsNum: "Jelaskan jumlah kata",
            updateTime: "Perbarui waktu",
            status: "Efektivitas",
            effective: "Efektif",
            invalid: "Tidak valid",
            action: "Operasi",
          },
        },
        detail: {
          title: { new: "Kata konsep baru", edit: "Mengedit kata konsep" },
          name: "Nama kata konsep",
          namePlaceholder: "Masukkan nama kata konsep",
          effectiveness: "Efektivitas",
          effective: "Efektif",
          invalid: "Tidak valid",
          description: "Kata deskripsi:",
          descriptionPlaceholder: "Silakan masukkan kata deskripsi",
          add: "Tambahkan",
          repeat: "Mengulangi konten",
          empty: "Kata deskripsi tidak bisa kosong",
          recommendEmpty:
            "Tidak ada lagi deskripsi yang disarankan, silakan masukkan secara manual untuk menambahkan",
          recommend: "Deskripsi yang disarankan cerdas",
          cancel: "Pembatalan",
          change: "Ubah satu set",
          descriptionSelected: "Kata deskripsi telah ditambahkan:",
          save: "Simpan",
          success: "Konsep kata berhasil disimpan",
          warning: "Nama kata konsep tidak bisa kosong",
          warning2: "Harap tambahkan setidaknya 1 kata deskripsi",
          submitBtnHandle: {
            title:
              "Kata deskripsi dengan centang tidak ditambahkan, menyimpan akan meninggalkan kata deskripsi yang tidak ditambahkan",
            content:
              "Klik OK untuk kembali ke beranda kata konsep, apakah Anda mengonfirmasi untuk melanjutkan?",
          },
        },
      },
      smartTools: {
        btn: "Alat cerdas",
        title: {
          similarWords: "Kata Terkait Penemuan",
          search: "Dengan kata",
          semanticTags: "Label semantik",
        },
        sentenceSearch: {
          placeholder:
            "Silakan masukkan pencarian kata kunci, mendukung beberapa kata kunci",
          more: "Lihat teks lengkap",
          pageUp: "Sebelumnya",
          pageDown: "Halaman berikutnya",
          total: "Total {0} catatan",
        },
        similarDiscover: {
          placeholder: "Silakan masukkan kata kunci",
          copyed: "Telah disalin",
          none: "Tidak ada kata terkait, coba kata kunci lain",
        },
        smartTags: {
          searchPlaceHolder: "Masukkan kata kunci atau kalimat",
          createSmartTag: "Baru dibangun",
          createClusteringSmartTag: "Buat baru dengan {0} data",
          addFromKeyword: "Menambahkan konten pencarian ke kalimat yang serupa",
          hit: "Hit",
          similar: "Mirip",
          name: "Nama label semantik",
          namePlaceHolder: "Masukkan nama label semantik",
          recallWords: "Kata-kata ingat",
          recallWordsPlaceHolder: "Silakan masukkan kata kunci penarikan",
          recallRegular: "Ingat aturan",
          recallRegularPlaceHolder: "Silakan masukkan aturan penarikan",
          rules: "Aturan",
          standardSentences: "Kalimat standar",
          standardSentencesPlaceHolder: "Silakan masukkan kalimat standar",
          similaSentences: "Kalimat serupa",
          similaSentencesPlaceHolder: "Masukkan kalimat serupa",
          notSaveError: "Simpan label semantik sebelum Anda mencarinya",
          add: "Tambahkan",
          newPage: "Buka di halaman baru",
          querryError:
            "Bidang serupa ada dalam daftar generalisasi (konfigurasi lanjutan serupa untuk menghapus batasan)",
          detailTitle: "Rincian label semantik",
        },
      },
      trainingCenter: {
        title: "Pusat Pelatihan",
        edit: "Mengedit",
        delete: "Hapus",
        seleteAll: "Pilih semua",
        cancelCheckAll: "Batalkan semua pilihan",
        add: "Tambahkan",
        remove: "Hapus",
        save: "Simpan",
        cancel: "Pembatalan",
        fastTraining: {
          title: "Pelatihan cepat",
          titleDes:
            "Pelatihan cepat dirancang untuk membantu tag semantik dengan cepat mencapai 20 kalimat serupa",
          semanticTags: "Label semantik",
          semanticTagsPlaceholder:
            "Silahkan masukkan proses pencarian kata kunci",
          standardSentence: "Kalimat standar:{0}",
          similarSentencesPlaceholder:
            "Masukkan kalimat yang sama untuk menambahkan atau mencari",
          similarSentencesAdd: "Tambahkan",
          similarSentencesSearch: "Pencarian",
          similarSentences: "Kalimat serupa telah ditambahkan",
          trainingCount: "Jumlah pelatihan:{0}",
          similarSentencesRecommend: "Kalimat serupa yang disarankan",
          noResp:
            "Tidak ada label semantik yang relevan, silakan ganti kata kunci",
          similarSentencesModalTitle: "Mirip kalimat pencarian",
          moreInfo: "Melihat Konteks",
          moreInfoBtn: "Konteks",
          modalSearchType: {
            all: "Semua",
            customer: "Layanan Pelanggan",
            client: "Pelanggan",
          },
          repetWarning:
            "Kalimat serupa yang sama sudah ada, jangan tambahkan berulang kali",
          repetRemoveTip:
            "Menyimpan konten duplikat dalam kalimat serupa, telah dihapus secara otomatis",
        },
        trainingHistory: {
          title: "Sejarah pelatihan",
          select: {
            date: "Pilih waktu:",
            today: "Hari ini",
            thisMonth: "Bulan ini",
            lastMonth: "Bulan lalu",
            thisQuarter: "Kuartal ini",
            lastQuarter: "Kuartal terakhir",
            thisYear: "Tahun ini",
            lastYear: "Tahun lalu",
            search: "Pertanyaan",
            reset: "Atur ulang",
          },
          table: {
            time: "Waktu",
            user: "Operator",
            tag: "Label semantik",
            similarCount: "Jumlah logo kalimat serupa",
            deleteCount: "Hapus jumlah kalimat",
            page: "Total {0} bar,{1} halaman",
          },
        },
        classifiedTraining: {
          title: "Pelatihan klasifikasi",
          tagsGrounp: "Pengelompokan label",
          placeHolder: "Silakan pilih",
          cellTime: "Memilih Waktu",
          search: "Pertanyaan",
          reset: "Atur ulang",
          classifiedFlag: "Pelatihan klasifikasi",
          num: "Nilai numerik",
          gas: "Persentase",
          noData: "Pelatihan Tidak Dikategorikan",
          noDataHeight:
            "Data yang tidak terklasifikasi relatif tinggi, dan pelatihan sangat dibutuhkan",
          noDataSmall:
            "Data yang tidak terklasifikasi relatif tinggi, disarankan untuk melakukan pelatihan",
          noDataDays: "Eksekusi terakhir {0} hari yang lalu",
          training: "Pelatihan",
          start: "Mulai",
          addClass: "Tingkatkan klasifikasi",
          custom: "Penyaringan lanjutan",
          marked: "Ralat data yang ditandai",
          markedHeight: "Sudah {0} hari tanpa ralat, disarankan untuk ralat",
          markedSmall:
            "Tidak ada ralat yang dilakukan di {0} hari, dan ada kebutuhan mendesak untuk ralat",
          mark: "Ralat",
          dataCheck: "Verifikasi data yang diklasifikasikan",
          dataCheckSmall:
            "K-L diversitas di atas 0,5, disarankan untuk melatih",
          dataCheckHeight:
            "Dipersi K-L lebih tinggi dari 0,8, sangat membutuhkan pelatihan",
          check: "Verifikasi",
          history: "Menandai catatan sejarah",
          historyTime:
            "Pelabelan dan modifikasi harian akan secara otomatis melakukan pelatihan model pada pukul 2:00 pagi",
          today: "Tandai hari ini",
          article: "Strip",
          none: "Tidak ada",
          historyCheck: "Total sejarah",
          look: "Lihat catatan",
          historyluate: "Perhitungan ulang data historis",
          startluate: "Menghitung ulang",
          classData: "Klasifikasi Data",
          classStatus: "Kemajuan pelatihan",
          next: "Berikutnya",
          prev: "Satu",
          finish: "Menyelesaikan pelatihan",
          stop: "Akhir pelatihan",
          classDetail: "Rincian pelatihan",
          trainingNone: "Label pintar tanpa pelatihan",
        },
        classifiedTrainingHistory: {
          title: "Sejarah pelatihan",
          time: "Pilih waktu:",
          tag: "Jenis label:",
          user: "Operator:",
          data: "Pilih sumber data:",
          search: "Pertanyaan",
          searchBar: "Pencarian:",
          searchBarPlaceholder:
            "Silakan masukkan ID panggilan/pencarian ID percakapan",
          reset: "Atur ulang",
          export: "Ekspor",
          all: "Semua",
          select: {
            today: "Hari ini",
            thisMonth: "Bulan ini",
            lastMonth: "Bulan lalu",
            thisQuarter: "Kuartal ini",
            lastQuarter: "Kuartal terakhir",
            thisYear: "Tahun ini",
            lastYear: "Tahun lalu",
            search: "Pertanyaan",
            reset: "Atur ulang",
          },
          table: {
            user: "Operator",
            date: "Waktu operasi",
            data: "Sumber Data",
            id: "ID panggilan/ID percakapan",
            tagType: "Pengelompokan label cerdas",
            tagName: "Nama Label",
            state: "Status",
            sizeChangeText: "Tampilan per halaman",
            page: "Total {0} bar,{1} halaman",
            call: "Panggilan telepon",
            dialogue: "Percakapan",
          },
        },
        clusteringAnnotation: {
          title: "Label cluster",
          left: { title: "Hasil pengelompokan" },
          right: {
            title: "Kalimat kluster",
            checkAll: "Pilih semua halaman ini",
            tagging: "Label",
            detail: "Lihat",
            meaningless: "Abaikan",
            meaninglessTips:
              "Hasil pengelompokan yang diabaikan tidak akan lagi ditampilkan di masa depan. Apakah Anda yakin Anda ingin mengabaikannya?",
            cancel: "Pembatalan",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Manajemen Pelanggan",
          tabTitle: "Pelanggan Pusat Layanan Pelanggan",
        },
        wechatClientManage: {
          title: "Pelanggan mikro",
          table: {
            id: "ID pelanggan",
            name: "Nama panggilan pelanggan",
            tag: "Pelanggan Label",
          },
        },
      },
      collectionRule: {
        title: "Aturan pengumpulan",
        form: {
          info: "Informasi pengumpulan cerdas",
          infoPlaceholder: "Silakan pilih kategori",
        },
        table: {
          name: "Nama Bidang",
          props: "Atribut Pelanggan",
          type: "Tipe Bidang",
          desc: "Deskripsi bidang",
        },
      },
      dataAdd: {
        title: "Data ditambahkan",
        types: {
          auto: "Ditambahkan secara otomatis",
          manual: "Menambah secara manual",
        },
      },
      wechatStaff: {
        table: {
          name: "Nama",
          part: "Departemen",
          phone: "Nomor ponsel",
          email: "Kotak surat",
          wechartId: "Enterprise WeChat ID",
          status: "Status kerja",
        },
        info: "Silakan pilih departemen yang memerlukan sinkronisasi data terlebih dahulu",
        synSuccess: "Sukses Sinkronisasi",
        init: "Inisialisasi",
        syn: "Sinkronisasi",
      },
      appealHistory: {
        appealTime: "Waktu banding",
        cancelTime: "Waktu pencabutan",
        reviewTime: "Waktu ulasan",
        inspector: "Inspektur kualitas",
        result: "Hasil banding",
        complainResult:
          "Sebanyak {0} item inspeksi kualitas naik banding, {1} berhasil naik banding, dan {2} ditolak",
        reason: "Alasan banding",
        rejection: "Alasan untuk menolak",
        cancel: "Pencabutan",
        tip: "Apakah Anda mengkonfirmasi bahwa Anda ingin menarik banding?",
      },
      appealSdk: {
        time: "Waktu berbicara",
        externalIdCall: "ID perekam panggilan",
        externalIdText: "Percakapan Rekaman ID",
        customerNickName: "Nama panggilan pelanggan",
        readStatus: "Periksa Status",
        status: "Status banding",
        isManual: "Apakah Pemeriksaan Kualitas Buatan",
        complainButtonInfo:
          "Banding {0} kali diizinkan, banding {1} kali telah diajukan, dan sisanya {2} kali dapat diajukan banding. Banding sebelum {3}",
        submit: "Ajukan banding",
        complain: "Keluhan",
        allComplain: "Keluhan Semua",
        pendingSubmit: "Untuk diserahkan",
        taskIds: "Tugas pemeriksaan kualitas",
        graded: "Penilaian",
        complainSuccess: "Pengajuan banding berhasil",
        appealSdkIndex: { title: "Hasil pemeriksaan kualitas yang cerdas" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Mendapatkan informasi daftar filter gagal, periksa koneksi jaringan atau coba lagi nanti!",
      columns: "Kolom",
      filter: "Penyaringan",
      noSearchResult: "Tidak ada catatan yang ditemukan",
      pleaseSearch: "Silakan pilih filter dan mencoba untuk mencari",
      sift: "Penyaringan",
      create: "Baru dibangun",
      search: "Pencarian",
      createTaskTitle: "Tugas baru",
      title: "Judul",
      deadline: "Batas waktu",
      principal: "Penanggung jawab",
      enqueueTime: "Waktu bergabung dengan tim",
      computeError: "Perhitungan gagal, silakan nanti!",
      sum: "Untuk berdamai",
      max: "Nilai maksimum",
      min: "Nilai minimum",
      avg: "Rata-rata",
      count: "Menghitung",
      maxDate: "Maksimum (Terbaru)",
      minDate: "Nilai minimum (tertua)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Gagal mendapatkan alamat tautan Insight, coba lagi nanti!",
      },
      template: {
        learningReminder: "Pengingat belajar",
        subscriptionDynamics: "Berlangganan Dinamis",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Manajemen tugas", chaneg: "Beralih" },
        qualityCheckTemplate: {
          text: "Template pemeriksaan kualitas",
          collectText: "Template koleksi",
          condition: { text: "Kondisi pemeriksaan kualitas" },
          checkPoint: { text: "Konfigurasi aturan" },
          automaticAdd: { text: "Otomatisasi proses" },
          manualAdd: { text: "Menambah secara manual" },
          roleConfig: { text: "Konfigurasi peran" },
          basicInfo: { text: "Informasi dasar" },
          smartWordsLibrary: { text: "Tesaurus cerdas" },
          grade: { text: "Peringkat pemeriksaan kualitas" },
          collectionRule: { text: "Aturan pengumpulan" },
          addData: { text: "Menambahkan data" },
          collectionList: { text: "Daftar akuisisi" },
        },
        qualityCheckWorkbench: {
          text: "Meja kerja pemeriksaan kualitas",
          wechatText: "Meja kerja analisis",
          informationCollectText: "Meja pengumpulan",
          qualityInspectionList: {
            text: "Daftar pemeriksaan kualitas",
            wechatText: "Menganalisis daftar",
            informationCollectText: "Daftar akuisisi",
          },
          condition: { text: "Daftar pemeriksaan kualitas (ditinggalkan)" },
          reviewList: { text: "Daftar ulasan" },
          spotCheckList: { text: "Daftar pengambilan sampel" },
          caseBaseList: { text: "Basis data kasus" },
          testSetList: { text: "Set tes pemeriksaan kualitas" },
        },
        statisticsAndReports: {
          text: "Statistik dan Laporan",
          wholeReport: { text: "Laporan keseluruhan" },
          groupReport: { text: "Laporan kelompok layanan pelanggan" },
          agentReport: { text: "Laporan Layanan Pelanggan" },
          customerServiceExecution: { text: "Analisis Layanan Pelanggan" },
          analysisOverview: { text: "Ringkasan analisis" },
          inspectionItemsAnalysis: {
            text: "Analisis item pemeriksaan kualitas",
          },
        },
        wechatQa: {
          text: "Pemeriksaan kualitas perusahaan WeChat",
          task: { text: "Menganalisis tugas" },
          baseInfo: { text: "Informasi dasar" },
          checkPoint: { text: "Menganalisis konfigurasi model" },
          setting: { text: "Konfigurasi analisis WeChat perusahaan" },
          automaticAdd: { text: "Otomatisasi proses" },
          manualAdd: { text: "Menambah secara manual" },
        },
        clientCenter: { text: "Pusat Layanan Pelanggan" },
      },
      conditionDictionaries: {
        title: "Semua kondisi",
        searchDefaultText: "Masukkan ID atau nama",
        id: "ID",
        name: "Nama",
        conditionDetailedShow: "Perluas",
        conditionDetailedHide: "Pijakan",
        remark: "Deskripsi:",
        checkRole: "Peran:",
        operator: "Operator:",
        operatorLogic: "Logika:",
      },
      audioTextView: {
        customer: "Pelanggan",
        agent: "Layanan Pelanggan",
        unsupportedFormat: "Format ini tidak didukung untuk saat ini",
      },
      checkPointClassifications: {
        add: "Kategori baru",
        name: "Nama",
        enterToSave: "Tekan Enter untuk menyimpan",
        highestScore: "Batas skor tertinggi",
        minimumScore: "Batas bawah skor minimum",
        defaultMinScore: "Default adalah 0 poin",
        minimumScoreTitle: "Skor batas bawah",
        beginScore: "Nilai penilaian keseluruhan",
        beginScoreTitle: "Nilai awal",
        scoreSetTip:
          "Batas skor maksimum kurang dari jumlah poin, Anda dapat memilih:",
        ignoreExcessSpillover: "Abaikan overflow berlebih",
        automaticWeightCalculation: "Penghitungan berat otomatis",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Bidang" },
    checkRange: {
      numberTipOne: "UU No.",
      numberTipTwo: "Kalimat",
      numberTipThree: "Kalimat ke No.",
      conditionLimit: "Kondisi pembatasan",
      roleLimit: "Keterbatasan peran",
      contextLimit: "Batas Kalimat",
      notZero: "Batas kalimat tidak mendukung input 0",
      startMustLessThanEnd:
        "Posisi kalimat awal harus lebih kecil dari posisi kalimat akhir",
    },
    waveAudio: {
      play: "Bermain",
      pause: "Jeda",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Silakan masukkan",
      pleaseEnterRegular: "Masukkan ekspresi reguler",
      pleaseEnterGrammar: "Silakan masukkan aturan tata bahasa",
      pleaseEnterTestText: "Masukkan konten yang perlu diverifikasi",
      pleaseEnterLoudness: "Silakan masukkan desibel kenyaringan",
      pleaseEnterRangeAbility: "Masukkan Amplitudo perubahan",
      afterHit: "Periksa setelah memukul",
      pleaseEnterContext: "Silahkan masukkan pernyataan referensi",
      wordNumber: "Jumlah Kata Kesenjangan",
      intervalSecond: "Interval waktu lebih besar dari",
      minute: "Per menit",
      wordUnit: "Kata",
      test: "Pengujian",
      testRegular: "Menguji ekspresi reguler",
      testGrammar: "Tata bahasa uji",
      testGrammarError: "Tes tata bahasa gagal",
      regularError: "Kesalahan ekspresi reguler, masukkan kembali",
      pleaseEnterSemanticSentence:
        "Masukkan kalimat yang membutuhkan deteksi semantik",
      pleaseEnterAssociate:
        "Silakan lihat petunjuk di sebelah kanan untuk membangun ekspresi data yang menyertainya",
      querySemanticTags: "Memilih label semantik",
      reQuerySemanticTags: "Memilih ulang label semantik",
      semanticTags: "Label semantik",
      standardSentence: "Kalimat standar",
      semanticName: "Label semantik",
      sentence: "Kalimat standar",
      similarity: "Kesamaan",
      actions: "Operasi",
      tipOne: "Tidak menemukan label yang tepat,",
      tipTwo: "Tambahkan tab semantik baru",
      notLessThan: "Tidak kurang dari",
      notGreaterThan: "Tidak lebih besar dari",
      ignoreLessThan: "Abaikan kurang dari",
      wordUnitSet: "Kalimat kata",
      speedWordNotNull:
        "Deteksi kecepatan bicara minimum dan maksimum kata tidak boleh kosong",
      speedWordMinValueLessThanMaxValue:
        "Deteksi kecepatan bicara minimum kata tidak boleh lebih besar dari jumlah kata maksimum",
      speechGrabbingIntervalTip: "Interval terburu-buru> =",
      speechLengthTip: "Kalimat panjang> =",
      msUnit: "ms",
      sUnit: "Detik",
      grabIntervalNotNull: "Interval terburu-buru tidak bisa kosong",
      grabLengthNotNull: "Kalimat yang terburu-buru tidak bisa kosong",
      pleaseSelectEntity: "Silakan pilih entitas informasi",
      pleaseSelectOperator: "Silakan pilih operasi",
      pleaseSelectBasicInfo: "Silakan pilih informasi dasar",
      pleaseEnterFixedValue: "Silakan masukkan nilai tetap",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Cocokkan Sukses",
      matchError: "Gagal cocok",
      mindetectionStatement: "Pernyataan deteksi minimum",
      conceptWordholder: "Silakan pilih kata konsep",
      elementHolder: "Silakan masukkan elemen",
      associateMedol: { tabs: { data: "Data", operator: "Operator" } },
      repet: "Ulangi",
      bout: "Kali",
      ignore: "Abaikan kurang dari",
      ignoreAffix: "Kalimat kata",
      associateData: "Data bersama jalan",
      pretreatmentData: "Data preprocessing",
      reviewTaskData: "Meninjau data misi",
      taskData: "Data tugas pemeriksaan kualitas",
      businessData: "Catatan bisnis",
      functionOperator: "Fungsi operator",
      operation: "Operator operasi",
      notAnyMatch: "Ketika melewatkan masalah:",
      times: "Jumlah kejadian",
      newAssociateDataTip:
        "Jika nilainya adalah tipe angka, gunakan fitur versi lama",
    },
    conditionGather: {
      cycle: "Siklus",
      rlue: "Aturan",
      delete: "Hapus",
      sampling: "Ekstraksi",
      item: "Strip",
      percent: "%",
      samplingSame: "Jumlah yang sama untuk setiap layanan pelanggan",
      samplingRecheck: "Izinkan pengambilan sampel berulang",
      dateUnit: "No.",
      samplingTip:
        "Aturan yang berbeda mencakup panggilan yang sama tidak akan diekstraksi berulang kali, dan catatan yang belum diekstraksi tidak akan diekstraksi lagi.",
      add: "Aturan baru",
    },
    ruleGather: {
      title: "Aturan",
      screeningCondition: "Kondisi penyaringan",
      addRule: "Aturan baru",
      inspector: "Inspektur kualitas",
      ruleTip:
        "Aturan memprioritaskan pola distribusi, dari atas ke bawah, tidak berulang, dan mereka yang dalam aturan atau tidak cocok dengan item aturan sewenang-wenang akan dialokasikan secara acak",
      moveUp: "Pindah ke atas",
      moveDown: "Geser ke bawah",
      top: "Bagian atas",
    },
    pushRulesGather: {
      title: "Aturan",
      delete: "Hapus",
      newPush: "Dorong baru",
      newRule: "Aturan baru",
      sampling: "Ekstraksi",
      item: "Strip",
      percent: "%",
      samplingSame: "Jumlah yang sama untuk setiap layanan pelanggan",
      dateUnit: "No.",
      screeningCondition: "Kondisi penyaringan",
      email: "Kotak surat",
      emailPlaceholder: "Masukkan kotak surat, dibagi dengan koma",
      updateType: "Diperbarui Jenis",
      pushDetail: "Rincian push",
      pushUrl: "URL push",
      pushWay: "Jenis push",
      pushMethod: "Metode push",
      pushContentType: "Format data",
      pushData: "Data push",
      additionalData: "Data tambahan",
      inspectionType: "Jenis pemeriksaan kualitas",
      all: "Semua",
    },
    taskAdditionalData: {
      newData: "Data baru",
      delete: "Hapus",
      placeholder: "Silakan masukkan nama",
      dataPlaceholder: "Silakan masukkan konten",
    },
    UdeskQaReact: { loadSdkError: "Pemuatan SDK gagal, coba lagi nanti!" },
    gearOptionList: { evaluation: "Evaluasi" },
    tableComponentTransfer: {
      leftTitle: "Daftar item",
      rightTitle: "Item tampilan",
    },
    kmTinymce: {
      link: "Menyisipkan/mengedit link",
      modalTitle: "Menyisipkan link",
      href: "Alamat",
      text: "Tampilkan teks",
      title: "Judul",
      target: "Buka cara",
      none: "Tidak ada",
      newWindow: "Buka di jendela baru",
      buttonStyle: "Gaya tombol",
      close: "Tutup",
      open: "Buka",
      answerMessage: "Melompat/mengirim pesan",
      answerMessageTitle: "Kirim tombol pesan",
      answerMessageText: "Tampilkan teks",
      answerMessageContent: "Konten pesan",
      answerMessageButtonStyle: "Gaya tombol",
      answerMessageTips:
        "Catatan: Tombol Kirim pesan hanya mendukung saluran web, harap hati-hati dikonfigurasi",
      answerSwitchStaff: "Melompat/putar buatan",
      answerSwitchStaffTitle: "Putar tombol buatan",
      answerSwitchStaffText: "Tampilkan teks",
      answerSwitchStaffRule: "Aturan tombol",
      answerSwitchStaffButtonStyle: "Gaya tombol",
      answerSwitchStaffTips:
        "Catatan: Tombol manual hanya mendukung saluran web, harap hati-hati dikonfigurasi",
      textButton: "Tombol teks",
      borderButton: "Tombol dengan bingkai",
      message: "Mengirim pesan",
      switchStaff: "Putar manual",
      messageError: "Konten tombol tidak dapat melebihi 10 kata",
      messageIsEmptyError: "Konten tombol tidak bisa kosong",
      okText: "Penentuan",
      cancelText: "Pembatalan",
      video: "Video",
      uploadVideo: "Unggah video",
      uploadText: "Hanya mendukung MP4 format, ukuran tidak lebih dari 20M",
      videoEmptyOrUploading: "Video kosong atau diunggah",
      customEmoji: "Ekspresi",
    },
    massageConfiguration: {
      pushTarget: "Menginformasikan tujuan",
      customizePushTarget: "Target push kustom",
      sysUser: "Pengguna Sistem Saat Ini",
      sysRole: "Peran sistem saat ini",
      emailPushTarget: "Kirim target",
      targetEmailsPlaceholder:
        "Silakan masukkan kotak surat dan dipisahkan dengan koma bahasa Inggris",
      subject: "Nama Pesan",
      SDKID: "ID identifikasi SDK",
      content: "Konten pemberitahuan",
      sdkPushType: "Rencana push",
      sdkPushURL: "URL push",
      pushData: "Data push",
      sdkPushWay: "Metode push",
      dataType: "Format data",
      additionalData: "Data tambahan",
      name: "Nama Pesan",
      filter: "Kondisi penyaringan",
      triggerType: "Mekanisme Pemicu",
      pushCycle: "Waktu tetap frekuensi",
      pushContent: "Isi laporan",
      qualityList: "Daftar hasil pemeriksaan kualitas",
      designated: "Personel yang ditunjuk",
      messageType: "Jenis pesan",
    },
    taskCard: {
      template: {
        cancelCollection: "Membatalkan koleksi",
        collection: "Koleksi",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Kalimat standar:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Koleksi",
          calls: "Panggilan telepon",
          createdBy: "Buat orang:",
          createdOn: "Waktu pembuatan:",
        },
        fileItem: {
          uIProvidesIcon: "Ui menawarkan icon",
          minute: "Menit",
          callClip: "Klip panggilan",
          start: "Mulai",
          End: "Akhir",
          employees: "Karyawan:",
          Customer: "Pelanggan:",
          sessionTime: "Sesi Waktu:",
          reasonForRecommendation: "Alasan yang disarankan:",
          addedBy: "Ditambahkan:",
          addTime: "Menambahkan waktu:",
        },
        modalAddFile: {
          caseTitle: "Judul Kasus",
          addSomeClipsToTheCaseLibrary:
            "Menambahkan beberapa fragmen ke perpustakaan kasus",
          addToCaseBase: "Tambahkan ke perpustakaan kasus",
          storedIn: "Untuk penyimpanan",
          pleaseSelect: "Silakan pilih",
          reasonsForRecommendation: "Alasan yang disarankan",
          pleaseEnter: "Silakan masukkan",
        },
      },
      shareModal: {
        index: {
          customer: "Pelanggan:",
          employees: "Karyawan:",
          timeliness: "Ketepatan waktu:",
          recordingLinkValidWithinDays:
            "Tautan rekaman aktif dalam beberapa hari",
          sharingInstructions: "Berbagi catatan:",
          shareSuccess: "Berbagi kesuksesan!",
          cancel: "Pembatalan",
          share: "Berbagi",
          copySucceeded: "Copy berhasil!",
          copyShareLink: "Menyalin tautan berbagi",
          shareWithColleagues: "Bagikan ke rekan kerja",
          shareWithCustomers: "Bagikan ke pelanggan",
          staff: "Karyawan",
          selectColleagues: "Memilih rekan kerja",
          pleaseSelectColleaguesToShare:
            "Silakan pilih rekan kerja yang ingin Anda bagikan",
          pleaseEnterTheSharingDescription:
            "Silakan masukkan instruksi berbagi",
          taskName: "Tugas:",
          recordingShareValidWithinDays:
            "Berbagi tautan dalam beberapa hari efektif",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Tidak dapat menemukan file!",
          sale: "Penjualan:",
          customer: "Pelanggan:",
          topicDetection: "Topik deteksi:",
          keyEvents: "Peristiwa kunci:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Memilih rekan kerja",
          helpContent: "Konten bantuan",
          visibleRange: "Rentang yang terlihat",
          seekCounseling: "Mencari konseling",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Mencari saran", concatText: "Untuk" },
      },
      customerInsight: {
        index: {
          customerInformation: "Informasi pelanggan",
          customerInsight: "Wawasan pelanggan",
          customerLabel: "Pelanggan Label",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Rekomendasi bicara",
          objectionHandlingScripts: "Penanganan keberatan",
          productSellingPointScripts: "Nilai jual produk",
          knowledgePointRecommendation:
            "Poin pengetahuan yang direkomendasikan",
          relatedKnowledgePoints: "Poin pengetahuan terkait",
          recommendedCases: "Kasus yang direkomendasikan",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Rekomendasi bicara",
          caseRecommendation: "Rekomendasi kasus",
          hit: "Hit",
          miss: "Terpukul",
          supervisionItem: "Item pengawasan",
          implementation: "Eksekusi",
          reInspection: "Pemeriksaan ulang",
          supervisionItemSettings: "Pengaturan item pengawasan",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Panggilan telepon",
          enterpriseWeChatCall: "Panggilan perusahaan mikro",
          enterpriseMicroConversation: "Sesi Mikro",
          open: "Buka",
          close: "Tutup",
          conversationScenario: "Adegan sesi",
          monitorConversationChannels: "Mengawasi saluran percakapan",
          permanentlyValid: "Efektif permanen",
          permanentlyInvalid: "Tidak aktif permanen",
          customValidity: "Masa berlaku kustom",
          termOfValidity: "Masa berlaku",
          newRating: "Peringkat baru",
          whenConversation: "Ketika sesi",
          hit: "Hit",
          miss: "Terpukul",
          time: "Waktu",
          rated: "Peringkat adalah",
          qualified: "Memenuhi syarat",
          unqualified: "Tidak memenuhi syarat",
          modifiedSuccessfully: "Berhasil dimodifikasi",
          classificationName: "Nama Kategori",
          supervisionObject: "Objek pengawasan",
          entryintoforceTime: "Waktu efektif",
          supervisionItemRating: "Mengawasi peringkat item",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Silakan periksa apakah peringkat item pengawasan sudah benar",
          ratingRules: "Aturan Peringkat",
          settingOfRatingRulesForSupervisionItems:
            "Mengawasi pengaturan aturan peringkat item",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Peristiwa kunci",
          supervisionItem: "Item pengawasan",
          conversationScenario: "Adegan sesi",
          customerLabel: "Pelanggan Label",
          reDetectionRules: "Mendeteksi kembali aturan",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Mengunduh rekaman",
          downloadText: "Mengunduh teks",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Mengunggah gambar",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Silakan pilih gambar dengan ukuran tidak lebih dari 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Atau",
            and: "Dan",
            wrong: "Tidak",
            matchTo: "Cocok untuk",
            rule: "Aturan",
            satisfactionOperator: "Memenuhi operator",
            customerLabel: "Pelanggan Label",
            keyEvents: "Peristiwa kunci",
            knowledgePoints: "Poin pengetahuan",
            sessionLabel: "Tab Sesi",
            supervisionItem: "Item pengawasan",
          },
          linkExplorer: { linkInTheText: "Tautan dalam teks:" },
        },
      },
    },
    createForm: {
      field: { customComponentNotFound: "Komponen kustom tidak ditemukan:" },
    },
    sdkLoaderBase: {
      component: { custom: "Kustom" },
      dropdownMonit: {
        updateSucceeded: "Pembaruan berhasil",
        areYouSureToDelete: "Apakah Anda yakin untuk menghapusnya?",
        custom: "Kustom",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Simpan Konfigurasi Laporan Saat Ini === Formulir",
        saveCurrentReportConfiguration:
          "Menyimpan konfigurasi laporan saat ini",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Silakan masukkan nama pengaturan umum, panjang maksimum adalah 20!",
        preservation: "Simpan",
      },
      template: {
        saveCurrentReportConfiguration:
          "Menyimpan konfigurasi laporan saat ini",
        updateCurrentReportConfiguration:
          "Memperbarui konfigurasi laporan saat ini",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Informasi pesanan kerja",
        workOrder: "Pesanan kerja",
        triggerScenario: "Memicu adegan",
        associatedCallConversation: "Menghubungkan panggilan/percakapan",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Diuji dengan teks pass/dialog saat ini",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "UU No.", day: "Hari" },
      businessSummary: {
        normal: {
          equalTo: "Sama dengan",
          notEqualTo: "Tidak sama dengan",
          contain: "Berisi",
          excluding: "Tidak mengandung",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Saya hanya saya, kembang api yang berbeda @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi, saya adalah pemberitahuan pesan peringatan kualitas robot, karena penambahan grup pada 17 Oktober",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Saya hanya saya, kembang api yang berbeda @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi, saya adalah pemberitahuan pesan peringatan kualitas robot, karena penambahan grup pada 17 Oktober",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Topik Komunikasi",
          conversationSummary: "Ringkasan percakapan",
          serviceSuggestions: "Rekomendasi layanan",
        },
        template: { smartTags: "Label pintar" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Klik untuk tahu lebih banyak" },
      schema: {
        textRobot: "Teks Robot",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Escort pelatih, tanya jawab dokumen, dialog misi, tanya jawab formulir, tanya jawab peta",
        capitalRetentionRobot: "Robot yang didanai",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Strategi yang kaya untuk mempertahankan modal, komunikasi antropomorfik yang tinggi, menjamin tingkat retensi modal yang tinggi, petunjuk, dan transformasi otomatis yang efisien",
        outboundRobot: "Robot panggilan keluar",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Kunjungan kembali ke akhir perintah kerja, survei kepuasan layanan, peringatan kegagalan operasi dan pemeliharaan, pengingat pengiriman pesanan, pemberitahuan aktivitas e-commerce, janji temu kandidat, membantu otomatisasi bisnis dan meningkatkan efisiensi bisnis",
        incomingRobot: "Robot panggilan masuk",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Integrasi fleksibel sistem bisnis seperti perintah kerja dan pesanan untuk mewujudkan otomatisasi proses; model semantik suara disesuaikan dan identifikasi, dan efek bisnis langsung; entitas (nomor ponsel, jumlah pesanan, waktu pesanan, alamat,...) Identifikasi, dukungan cepat untuk bisnis yang kompleks",
        intelligentQualityInspection: "Pemeriksaan kualitas yang cerdas",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Kualitas penuh, tepat waktu dan efisien, tingkat akurasi 85%",
        intelligentSessionAnalysis: "Analisis sesi cerdas",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Wawasan tentang kekurangan bisnis, dengarkan pelanggan, dan tingkatkan transformasi pemasaran",
        enterpriseWechatConversationAnalysis: "Analisis Sesi WeChat Perusahaan",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Mengidentifikasi peluang dan risiko bisnis potensial secara efisien, dan meningkatkan tingkat konversi pemasaran sebesar 20%",
        assistant: "Kursi asisten",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Asisten agen dapat memastikan bahwa layanan pelanggan pemula menyelesaikan layanan dan meningkatkan kualitas panggilan layanan pelanggan. Biarkan setiap kursi menjadi kartu truf Anda",
        digitalPeople: "Orang digital",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          'Komunikasi antropomorfik "tatap muka", mendukung interaksi multi-mode suara, teks, video, dan sentuhan, mewujudkan pengalaman komunikasi yang mendalam, meningkatkan citra merek dan pengalaman layanan pengguna',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Pengetahuan AI Zhongtai · Pencarian Perusahaan",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Pencarian pengetahuan, berbagi pengetahuan, pencarian dokumen, pencarian database docking",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Basis Pengetahuan Taiwan · KCS Pengetahuan AI",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Menyediakan manajemen siklus hidup penuh pengetahuan, pemeliharaan terpadu basis pengetahuan robot dan IM, dukungan untuk docking basis pengetahuan pihak ketiga, templat pengetahuan, pengetahuan atom dan fungsi kuat lainnya untuk menghadirkan pengalaman manajemen pengetahuan yang lebih nyaman",
        aIKnowledgeCenterKnowledgeMap:
          "Taiwan Pengetahuan AI · Peta Pengetahuan",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "Konstruksi peta satu atap mengintegrasikan pengetahuan perusahaan, gambar besar menunjukkan detail pengetahuan, kemampuan docking untuk menciptakan pengalaman yang disesuaikan",
        modularDevelopmentPlatformOriginalEngineASR:
          "Platform Pengembangan Modular · Mesin Asli · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Menggunakan algoritma end-to-end untuk mencapai pengalaman efek yang disesuaikan dengan bisnis, tingkat pengenalan adegan alami lebih dari 90%, pengenalan kosakata yang dipersonalisasi lebih dari 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Platform Pengembangan Modular · Pemasaran Cerdas",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Gunakan algoritma pembelajaran mendalam, dikombinasikan dengan potret pengguna, untuk mencapai rekomendasi produk yang akurat, dan meningkatkan konversi penjualan",
        modularDevelopmentPlatformUserPortrait:
          "Platform Pengembangan Modular · Potret Pengguna",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Secara akurat membangun potret pengguna berdasarkan data pengguna dan data percakapan, sepenuhnya menggambarkan karakteristik pelanggan",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Platform Pengembangan Modular · Rekomendasi Cerdas",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Menurut potret pengguna dan potret produk, secara otomatis merekomendasikan produk yang mungkin menarik bagi pengguna, dan mencapai rekomendasi yang dipersonalisasi untuk ribuan orang.",
        modularDevelopmentPlatformNLPPAAS:
          "Platform Pengembangan Modular · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Memberikan kemampuan dasar NLP dengan akurasi tinggi yang mencakup kemampuan yang mendasari dalam partikel yang berbeda, seperti kata, kalimat, artikel",
      },
    },
    qualityScore: {
      index: {
        show: "Tampilkan:",
        displayBySecondaryClassification:
          "Display berdasarkan klasifikasi sekunder",
        showByRule: "Tampilkan sesuai aturan",
        pleaseEnterClassificationruleName:
          "Silakan masukkan nama kategori/aturan",
        clickCopy: "Klik untuk menyalin",
        hitDetailsOfOperatorsInRules: "Rincian hit operator dalam aturan:",
        hit: "Hit",
        miss: "Terpukul",
        filter: "Filter:",
        clickToViewDetails: "Klik untuk melihat detail",
      },
      components: {
        flowNodeList: {
          branch: "Poin",
          pleaseSelect: "Silakan pilih",
          viewTheCompleteProcess: "Lihat proses lengkap",
          thereIsCurrentlyNoProcessAvailable: "Tidak ada proses",
        },
        pointItem: { xiangHit: "Item hit" },
        flowNodeListModal: { index: { completeProcess: "Proses lengkap" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Jika ada perubahan dalam tugas inspeksi kualitas proses, harap ajukan banding lagi.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Data ini telah dihapus secara berkala",
        subject: "Tema:",
        description: "Deskripsi:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask:
              "Tambahkan ke tugas pemeriksaan kualitas",
          },
        },
      },
    },
    relatedDetail: {
      index: {
        associatedCallConversation: "Menghubungkan panggilan/percakapan",
      },
    },
    samplingTarget: {
      index: {
        time: "Waktu",
        samplingQuantityIsLessThan:
          "Jumlah pengambilan sampel lebih rendah dari",
        alertWhen: "Peringatan dini",
        everyday: "Setiap hari",
        weekly: "Mingguan",
        monthly: "Bulanan",
        samplingInspectionTargetSavedSuccessfully:
          "Target pengambilan sampel berhasil disimpan",
        personnel: "Personil",
        eachCustomerService: "Layanan pelanggan per orang",
        cycle: "Siklus",
        samplingInspectionTarget: "Target inspeksi acak",
        strip: "Strip",
        alertSettings: "Pengaturan peringatan dini",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Layanan Pelanggan",
        complete: "Selesai",
        Objective: "/Tujuan",
        everyday: "Setiap hari",
        weekly: "Mingguan",
        monthly: "Bulanan",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Tingkat interaksi obrolan grup",
        maximumResponseIntervalTime: "Interval respons terpanjang",
        numberOfPreemptiveCalls: "Jumlah panggilan",
        numberOfMessages: "Jumlah pesan",
        strip: "Strip",
        proportionOfMessages: "Proporsi pesan",
        speechPercentage: "Berbicara",
        longestCustomerTalk: "Pelanggan terpanjang berbicara",
        numberOfIndepthDiscussions: "Jumlah diskusi mendalam",
        second: "Kali",
        maximumDurationOfContinuousSpeech:
          "Durasi maksimum berbicara terus menerus",
        questionFrequency: "Frekuensi pertanyaan",
        customer: "Pelanggan",
        keyEvents: "Peristiwa kunci:",
        opportunity: {
          index: {
            generationFailed: "Gagal menghasilkan",
            generateOpportunityPoints: "Menghasilkan titik peluang",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Gagal menghasilkan",
            generateSummary: "Menghasilkan Ringkasan",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Apakah Anda yakin untuk menghapusnya?",
        add: "Tambahkan",
        opportunityPoints: "Titik peluang",
        conversationSummary: "Ringkasan percakapan",
        cancel: "Pembatalan",
        determine: "Penentuan",
        edit: "Mengedit",
        establish: "Membuat",
        conversationalInsights: "Wawasan percakapan",
        keyEvents: "Peristiwa kunci",
        numKeyEvents: "Peristiwa penting",
        questionAnalysis: "Analisis pertanyaan",
        find: "Ditemukan",
        questions: "Pertanyaan kedua",
        conversationAction: "Aksi sesi",
        customer: "Pelanggan",
        sale: "Penjualan",
        sessionLabel: "Adegan sesi",
        employeeQuestions: "Pertanyaan karyawan",
        customerQuestions: "Pertanyaan pelanggan",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Pelanggan",
        customerService: "Layanan Pelanggan",
        replyTo: "Membalas ke",
        addressee: "Penerima:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Identifikasi ulang",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Reidentifikasi akan memakan waktu untuk menulis ulang, harap berhati-hati",
        determine: "Penentuan",
        executionSuccessful: "Eksekusi Sukses",
        addToQualityInspectionTask: "Tambahkan ke tugas pemeriksaan kualitas",
        batchOperation: "Operasi massal",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Tambahkan Sukses",
        addToQualityInspectionTask: "Tambahkan ke tugas pemeriksaan kualitas",
        qualityInspectionTask: "Tugas pemeriksaan kualitas",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Mendeteksi peran",
        detectionRange: "Rentang deteksi",
        sentence: "Kalimat",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Data ini telah dihapus secara berkala",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Mengembalikan sistem bisnis" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Pertanyaan ekstraksi",
        businessSummary: "Ringkasan bisnis",
        keyWord: "Kata kunci",
      },
      components: {
        problemExtraction: {
          no: "Tidak.",
          customerServiceDidNotAnswer: "Layanan pelanggan tidak terjawab",
          customerServiceHasAnswered: "Layanan pelanggan telah menjawab",
          problemUnresolved: "Masalah belum terselesaikan",
          theProblemHasBeenResolved: "Masalah terpecahkan",
          customerIssue: "Masalah pelanggan:",
          customerServiceAnswer: "Jawaban layanan pelanggan:",
          reExtractQuestions: "Masalah ekstraksi ulang",
          clickToExtractQuestions: "Klik untuk menggambar pertanyaan",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Nama Potret",
            backgroundDescription: "Deskripsi latar belakang",
            customerLabel: "Pelanggan Label",
            labelManagement: "Manajemen label",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: {
              index: { newlyBuild: "Baru dibangun" },
            },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Pengaturan tindak lanjut" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Tingkatkan pertanyaan",
              remove: "Hapus",
              addQuestioning: "Tambahkan pertanyaan",
              section: "UU No.",
              secondInquiry: "Pertanyaan kedua",
              questioningScore: "Menanyakan skor",
            },
          },
        },
        index: {
          accordingToRules: "Sesuai aturan",
          byScore: "Tekan Skor",
          whenMissRule: "Ketika aturan kehilangan",
          whenConductingQuestioning: "Saat bertanya",
          pleaseSelectARule: "Silakan pilih aturan",
          ruleCannotBeEmpty: "Aturan tidak bisa kosong",
          currentScriptScore: "Ketika skor bicara ≤",
          pleaseEnterTheScore: "Silakan masukkan skor",
          scoreCannotBeEmpty: "Skor tidak bisa kosong",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Narasi:" },
          pPT: { slide: "Slide" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Ulasan bicara",
              script: "Kata-kata",
              semantics: "Semantik",
              violatingWords: "Kata-kata pelanggaran",
              speechSpeed: "Kecepatan bicara",
              emotion: "Emosi",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Ulasan bicara",
              regenerate: "Ulang",
              generate: "Menghasilkan",
              hitSituation: "Situasi hit",
              noSemanticTagsMatched: "Tidak cocok ke semantik Tags",
              matchToSemanticTags: "Cocok untuk Semantik Tags",
              appealToBeSubmitted: "Keluhan tertunda",
              ratingHasBeenReviewed: "Skor telah ditinjau",
              ratingAppealInProgress: "Peringkat banding",
              expressionScore: "Skor ekspresi",
              moodParticlesTimes: "Kata gas, {0} kali",
              changeTheModalParticlesTo: "Kata gas diubah menjadi",
              second: "Kali",
              interrogation: "Mempertanyakan",
              questioningWithoutScoring: "Mempertanyakan tanpa skor",
              branch: "Poin",
              matchingRate: "Tingkat pencocokan",
              ratingAppeal: "Peringkat banding",
              changeTo: "Diubah menjadi",
              wordPerMinute: "Kata per menit",
              completeScript: "Kata-kata lengkap",
              incompleteScript: "Kata-kata tidak lengkap",
              semanticHit: "Memukul semantik",
              semanticMisses: "Semantik terjawab",
              scriptViolation: "Pelanggaran bicara",
              modified: "Diubah",
              notChanged: "Tidak dimodifikasi",
              keyWord: "Kata kunci",
              semanticLabels: "Label semantik",
              semanticMatchingRate: "Tingkat pencocokan semantik",
              violatingWords: 'Kata-kata pelanggaran"',
              delete: "Hapus",
              unmatched: "Tidak cocok",
              matching: "Cocok",
              matchTo: "Cocok untuk",
              notMatchedTo: "Tidak cocok",
              scriptScore: "Skor bicara",
              standardScript: "Kata-kata standar",
              emotionalScore: "Skor emosional",
              speechSpeedScore: "Skor kecepatan bicara",
              viewResolution: "Lihat analisis",
              nodeConfiguration: "Konfigurasi node",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Waktu jawaban untuk pertanyaan ini habis, dan konten jawaban dikirimkan secara otomatis",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Waktu jawaban untuk pertanyaan ini habis, dan konten jawaban dikirimkan secara otomatis",
          },
          correctBtn: {
            saveSuccessful: "Simpan Sukses",
            errorCorrection: "Koreksi kesalahan",
            cancel: "Pembatalan",
            preserve: "Simpan",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Aturan skor",
            cancel: "Pembatalan",
            determine: "Penentuan",
          },
          scoringRuleItem: {
            whole: "Semua",
            arbitrarily: "Apa saja",
            pleaseEnterARuleName: "Silakan masukkan nama aturan",
            standardScript: "Kata-kata standar",
            if: "Jika",
            and: "Dan",
            or: "Atau",
            thenScore: "Skor",
            pleaseEnterAScore: "Silakan masukkan skor",
            bonusPoints: "Poin tambahan",
            minusPoints: "Kurangi poin",
            hit: "Hit",
            misses: "Terpukul",
            keyWord: "Kata kunci",
            semantics: "Semantik",
          },
          title: {
            ruleValidation: "Verifikasi aturan",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Aturan skor (skor total untuk pidato saat ini",
            points: "Poin)",
            editRules: "Mengedit aturan",
          },
        },
      },
      userTagsSelect: {
        index: { noLabelsCurrentlyAvailable: "Tidak ada label" },
      },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Tujuan Komunikasi",
              scoringCriteria: "Kriteria penilaian",
            },
          },
        },
        index: {
          generating: "Menghasilkan",
          generateDialogueFramework: "Menghasilkan bingkai dialog",
          theme: "Tema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Pesan",
            generatePromptLanguage: "Menghasilkan petunjuk",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Tahap komunikasi",
          communicationObjectives: "Tujuan Komunikasi",
          scoringCriteria: "Kriteria penilaian",
          suggestionsForImprovement: "Saran promosi",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Skor proses",
            contentIntegrity: "Integritas konten:",
            NumberOfCompletedProcesses: "%, Menyelesaikan jumlah proses",
            keyHitPointsTotal: "Poin hit: total",
            notReached: "Tidak mencapai",
            accurateFollowupAccumulationOfStandardScripts:
              "Tindak lanjut akurat: akumulasi bicara standar",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Kata, siswa mengikuti jumlah kata yang dipukul",
            aWord: "Kata",
            languageNormsYes: "Spesifikasi bahasa: Ya",
            emotionsAppearing: "Emosi: muncul",
            speechSpeedSpeakingTooFast:
              "Kecepatan bicara: berbicara terlalu cepat",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Katekisme: Kata-kata yang terlalu banyak muncul",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Tambahkan ke Abaikan Label",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Yakin ingin menjalankan kembali label pintar?",
        reExecute: "Eksekusi ulang",
      },
      component: { successfullyAdded: "Tambahkan Sukses" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Matikan semua pemberitahuan",
        reQualityInspectionTask: "Tugas pemeriksaan ulang",
        requalityInspection: "Pemeriksaan ulang",
        waiting: "Menunggu",
        viewDetails: "Lihat detail",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Waktu deteksi:" } },
        },
        template: { responseOpeningSentence: "Menjawab kalimat awal" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Gagal menghasilkan",
            generate: "Menghasilkan",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Tidak ada data" } },
      },
      index: { regenerate: "Ulang" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Apakah Anda yakin untuk menghapusnya?",
        operationSuccessful: "Operasi yang sukses",
      },
      template: { delete: "Hapus" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Silakan pilih pertanyaan!",
        similarQuestionRecommendation: "Pertanyaan serupa direkomendasikan",
        selectAllOnPage: "Saat halaman dipilih",
        cancel: "Pembatalan",
        preserve: "Simpan",
        changeBatch: "Ubah batch",
      },
    },
    listControl: { index: { columnControl: "Kontrol Kolom" } },
    loginUserAvatar: { index: { switch: "Beralih" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Saat menambahkan data secara real time, tugas inspeksi kualitas masih membutuhkan waktu. Waktu spesifik tergantung pada jumlah data inspeksi kualitas, mulai dari beberapa menit hingga 2 jam.",
      },
    },
    bEditor: {
      index: {
        delete: "Hapus",
        cancel: "Pembatalan",
        confirm: "Penentuan",
        insert: "Sisipan",
        width: "Lebar",
        height: "Tinggi",
        clearContent: "Menghapus konten",
        revoked: "Pencabutan",
        redo: "Ulang",
        fontSize: "Ukuran font",
        rowHeight: "Tinggi garis",
        wordSpacing: "Jarak antar kata",
        paragraphIndent: "Lekukan paragraf",
        increaseIndent: "Meningkatkan Indentasi",
        decreaseIndent: "Mengurangi Indentasi",
        border: "Bingkai",
        color: "Warna",
        textColor: "Warna Teks",
        backgroundColor: "Warna latar belakang",
        temporaryColor: "Warna sementara",
        bold: "Dipertebal",
        italic: "Miring",
        underline: "Garis bawah",
        strikethrough: "Hapus garis",
        font: "Font",
        textAlignment: "Penyelarasan teks",
        left: "Kiri",
        centered: "Pusat",
        right: "Kit kanan",
        bothEnds: "Kedua ujungnya",
        leftFloat: "Mengambang kiri",
        rightFloat: "Mengambang kanan",
        subrupt: "Label",
        subscript: "Subskrip",
        clearStyle: "Hapus Gaya",
        title: "Judul",
        regular: "Biasa",
        orderedList: "Daftar yang dipesan",
        unorderedList: "Daftar tidak teratur",
        reference: "Kutipan",
        code: "Kode",
        link: "Tautan",
        clearLink: "Menghapus tautan",
        horizontalLine: "Garis Horizontal",
        media: "Media",
        mediaLibrary: "Perpustakaan media",
        smallExpression: "Ekspresi kecil",
        fullScreen: "Layar penuh",
        exitFullScreen: "Keluar Layar Penuh",
        enterLinkText: "Masukkan teks tautan",
        enterLinkAddress: "Masukkan alamat tautan",
        enterTheLinkAddressAndPressEnter:
          "Masukkan alamat tautan dan tekan enter",
        openInNewWindow: "Buka di jendela baru",
        removeLink: "Menghapus tautan",
        playingAudioFiles: "Memutar file audio",
        playVideoFiles: "Memutar file video",
        embeddedMedia: "Media Tertanam",
        image: "Gambar",
        video: "Video",
        audio: "Audio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Nama",
        description: "Deskripsi",
        type: "Jenis",
        status: "Status",
        actions: "Operasi",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Nama tugas",
        description: "Deskripsi",
        form: "Formulir pemeriksaan kualitas",
        actions: "Operasi",
        createUser: "Pencipta orang",
      },
      samplingRecords: {
        createTime: "Waktu",
        type: "Jenis",
        operateUser: "Operator",
        number: "Menambahkan jumlah",
        filterConditionList: "Kondisi penyaringan",
        sampleRuleStr: "Kondisi pengambilan sampel",
        timeGreaterThan: "Waktu lebih besar dari",
        timeLessThan: "Waktu kurang dari",
      },
    },
    customFilters: {
      createdAt: "Waktu bicara",
      customerId: "Pelanggan",
      agentId: "Layanan Pelanggan",
      userGroupId: "Layanan Pelanggan",
      state: "Status",
      lastEvaluateUserId: "Penilaian terakhir",
      submitTime: "Waktu pengiriman terakhir",
      totalScore: "Total skor",
      formItemForbidIdList: "Item yang dilarang",
      formItemDeadlyIdList: "Mematikan",
    },
    samplingInspection: {
      callTime: "Waktu bicara",
      dialogTime: "Waktu percakapan",
      originator: "Penugasan",
      createTime: "Waktu pembuatan",
      updateTime: "Perbarui waktu",
      status: "Status Proses",
      score: "Skor",
      actions: "Operasi",
      reviewer: "Pengulang",
      samplingOfficer: "Petugas pengambilan sampel",
      id: "ID perekam panggilan",
      time: "Waktu bicara",
      phone: "Telepon pelanggan",
      staff: "Layanan Pelanggan",
      inspectionStatus: "Status pemeriksaan kualitas",
      samplingStatus: "Status pengambilan sampel",
      inspectionGrade: "Peringkat",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Kata Standar",
        synonymWords: "Sinonim",
        synonymWordsNumber: "Jumlah sinonim",
        useFrequency: "Menggunakan Frekuensi",
        effectiveness: "Efektivitas",
        operation: "Operasi",
      },
      common: {
        useFrequency: "Menggunakan Frekuensi",
        effectiveness: "Efektivitas",
        operation: "Operasi",
      },
      professionalWords: {
        professionalWords: "Kata benda profesional",
        useFrequency: "Menggunakan Statistik",
        homophonic: "Kata-kata panas (penggantian homonim)",
        target: "Ganti kata benda",
        targetWords: "Ganti kata",
        homophonyWords: "Kata perisai homonim",
        operation: "Operasi",
      },
      attentionWords: "Kata-kata perhatian",
      ignoreWords: "Abaikan kata",
      wordsWeight: {
        selfDefineWeightWords: "Kata bobot kustom",
        weightWords: "Kata-kata berat",
        weight: "Berat",
        useFrequency: "Menggunakan Frekuensi",
        effectiveness: "Efektivitas",
        operation: "Operasi",
      },
      corpusData: {
        corpusDescription: "Deskripsi Bahan",
        updateTime: "Perbarui waktu",
        wordsCount: "Jumlah kata",
        effectiveness: "Efektivitas",
        operation: "Operasi",
        corpusContent: "Konten",
      },
    },
    semanticTags: {
      title: "Label semantik",
      tags: "Kalimat standar",
      orgName: "Organisasi yang berafiliasi",
      tagCount: "Jumlah pelatihan",
      usingCount: "Jumlah referensi",
      activeFlag: "Efektivitas",
      suggestion: "Saran pelatihan",
      actions: "Operasi",
    },
    intelligentTags: {
      tagName: "Nama",
      tagDesc: "Deskripsi",
      tagRefCnt: "Jumlah tanda",
      categoryName: "Klasifikasi Label",
      eventName: "Klasifikasi Peristiwa",
      tagStatus: "Efektivitas",
      actions: "Operasi",
      tagType: "Jenis Label",
    },
    dataPreprocess: {
      name: "Nama",
      desc: "Deskripsi",
      dataSource: "Batasan sumber data",
      type: "Jenis",
      status: "Efektivitas",
      actions: "Operasi",
    },
    informationEntities: {
      name: "Nama",
      apiName: "Nama API",
      type: "Jenis",
      remark: "Deskripsi",
      activeFlag: "Efektivitas",
      actions: "Operasi",
    },
    interactiveRecognition: {
      questionAndAnswer: "Pertanyaan & Jawaban Standar",
      type: "Jenis",
      status: "Status",
      actions: "Operasi",
      question: "Pertanyaan",
    },
    role: { id: "ID", name: "Nama", actions: "Operasi" },
    uploadDownload: {
      taskName: "Nama file",
      createTime: "Tanggal",
      size: "Ukuran",
      createUserName: "Operator",
      status: "Status",
      result: "Hasil",
      actions: "Operasi",
    },
    taskCenterList: {
      name: "Isi tugas",
      createTime: "Waktu penyerahan",
      startTime: "Waktu mulai",
      endTime: "Waktu akhir",
      createUserName: "Operator",
      status: "Status",
      result: "Hasil",
      actions: "Operasi",
    },
    customerInformation: {
      title: "Informasi Layanan Pelanggan",
      data: "Lihat hanya data anomali",
      list: {
        agentNo: "Nomor kerja/ID layanan pelanggan",
        agentName: "Layanan Pelanggan",
        agentTel: "Telepon",
        agentEmail: "Kotak surat",
        updateTime: "Perbarui waktu",
        actions: "Operasi",
      },
      upload: {
        second:
          "2. Klik tombol impor untuk mengunggah file EXCEl yang sesuai dengan format template",
      },
    },
    IndexManagement: {
      title: "Manajemen Indikator",
      list: {
        indicatorName: "Nama",
        indicatorDesc: "Deskripsi",
        activity: "Efektivitas",
        inspectDataSource: "Sumber Data",
        sysType: "Jenis",
        agentGroups: "Layanan Pelanggan",
        actions: "Operasi",
      },
      detail: {
        nameEmpty: "Nama tidak boleh kosong",
        descript: "Deskripsi",
        descriptEmpty: "Deskripsi tidak bisa kosong",
        active: "Efektivitas",
        activeEmpty: "Efektivitas tidak bisa kosong",
        dataSource: "Sumber Data",
        dataSourceEmpty: "Sumber data tidak bisa kosong",
        grounp: "Layanan Pelanggan",
        grounpEmpty: "Grup layanan pelanggan tidak bisa kosong",
        grounpHolder: "Silakan pilih layanan pelanggan",
        type: "Metode penilaian",
        typeNone: "Metode penilaian tidak bisa kosong",
        base: "Poin dasar",
        baseNone: "Poin dasar tidak bisa kosong",
        positive: "Faktor positif",
        positiveNone: "Faktor positif tidak bisa kosong",
        customAdd: "Menambahkan Filter",
        negative: "Faktor Negatif",
        negativeNone: "Faktor negatif tidak bisa kosong",
        customOK: "Filter kondisi:",
        judgeStrategy: "Aturan Kondisi:",
        other: "Faktor lainnya",
        otherAdd: "Menambahkan faktor",
        agent: "Data asli",
        section: "Jumlah interval",
        sectionNone: "Jumlah interval tidak boleh kosong",
        step: "Panjang langkah interval",
        stepNone: "Panjang langkah interval",
        save: "Simpan",
        cancel: "Pembatalan",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Semua",
      staff: "Hanya saya",
      department: "Departemen ini dan di bawah",
    },
    gongStatisticsDate: { week: "Minggu", month: "Bulan", season: "Musim" },
    gongCoachTaskStatus: {
      wait: "Untuk konseling",
      already: "Dibimbing",
      ignore: "Telah diabaikan",
    },
    callRangeStatus: {
      all: "Semua panggilan",
      sale: "Inisiasi karyawan",
      customer: "Peluncuran pelanggan",
    },
    todoListType: {
      later: "Dengar nanti",
      help: "Konseling untuk bantuan",
      contact: "Hubungi nanti",
      send: "Kirim informasi",
      warn: "Peringatan risiko",
    },
    frequencyTypes: {
      everyDay: "Setiap hari",
      everyWeek: "Mingguan",
      everyMonth: "Bulanan",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Pelatihan Data Tidak Dikategorikan",
        markedDataCorrigenda: "Ralat data yang ditandai",
        classifiedDataCheck: "Verifikasi data yang diklasifikasikan",
        markedHistoryRecord: "Menandai catatan sejarah",
        historyDataRecalculate: "Perhitungan ulang data historis",
      },
      dataExtractMode: {
        random: "Sepenuhnya acak",
        intelligentRecommend: "Rekomendasi cerdas",
        custom: "Kustom",
        specifiedClass: "Tentukan klasifikasi",
      },
      smartRatesList: {
        markRate: "Tingkat pelabelan",
        corrigendaRate: "Tingkat Kesalahan",
        kl: "Diversitas K-L",
        signalNoiseRate: "Rasio signal-to-noise",
      },
    },
    visibleRangeTypes: {
      all: "Semua",
      customerGroup: "Layanan Pelanggan",
      userDefined: "Kustom",
    },
    samplingRateTypes: {
      everyDay: "Ekstraksi setiap hari",
      everyWeek: "Ekstraksi mingguan",
    },
    taskRemindTypes: {
      confirm:
        "Kirim pengingat ketika catatan tugas pemeriksaan kualitas perlu dikonfirmasi",
      appeal:
        "Kirim pengingat ketika catatan tugas pemeriksaan kualitas memulai banding",
    },
    weekDays: {
      monday: "Senin",
      tuesday: "Selasa",
      wednesday: "Rabu",
      thursday: "Kamis",
      friday: "Jumat",
      saturday: "Sabtu",
      sunday: "Minggu",
    },
    reportDateTypes: {
      dailyReport: "Laporan harian",
      weeklyReport: "Laporan mingguan",
    },
    samplingRangeDayTypes: { today: "Hari ini", yesterday: "Kemarin" },
    samplingRangeWeekTypes: { thisWeek: "Minggu ini", lastWeek: "Minggu lalu" },
    samplingRuleTypes: {
      proportion: "Sampel proporsional",
      random: "Pengambilan Sampel Acak",
    },
    taskStatusTypes: {
      waitEvaluate: "Untuk dievaluasi",
      waitSubmit: "Untuk diserahkan",
      waitConfirm: "Untuk konfirmasi",
      waitReview: "Untuk diperiksa ulang",
      completed: "Selesai",
      all: "Semua",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Penilaian langsung",
        weightedScore: "Skor berat",
        deductScore: "Poin pengurangan",
        directScoreSecondLevel: "Skor Langsung-Level 2",
        weightedScoreSecondLevel: "Skor berat-Level 2",
        deductScoreSecondLevel: "Pengurangan poin-tingkat kedua",
      },
    },
    fieldOperators: {
      is: "Sama dengan",
      not: "Tidak sama dengan",
      contains: "Berisi",
      notContains: "Tidak mengandung",
      not_contains: "Tidak mengandung",
      containsAny: "Berisi apa pun",
      contains_any: "Berisi apa pun",
      is_any: "Berisi apa pun",
      not_any: "Tidak mengandung sewenang-wenang",
      notContainsAny: "Tidak mengandung sewenang-wenang",
      not_contains_any: "Tidak mengandung sewenang-wenang",
      containsAll: "Berisi semua",
      contains_all: "Berisi semua",
      notContainsAll: "Tidak mengandung semua",
      nont_contains_all: "Tidak mengandung semua",
      isNull: "Kosong",
      is_null: "Kosong",
      isNotNull: "Tidak kosong",
      is_not_null: "Tidak kosong",
      greaterThan: "Lebih besar dari",
      greater_than: "Lebih besar dari",
      greaterThanEq: "Lebih besar dari sama dengan",
      greater_than_eq: "Lebih besar dari sama dengan",
      lessThan: "Kurang dari",
      less_than: "Kurang dari",
      lessThanEq: "Kurang dari sama dengan",
      less_than_eq: "Kurang dari sama dengan",
      between: "Antara",
      notBetween: "Tidak",
      not_between: "Tidak",
      relative: "Sama dengan",
      set: "Ditetapkan sebagai",
      belongs: "Milik",
      notBelongs: "Bukan milik",
      not_belongs: "Tidak sama dengan",
      today: "Hari ini",
      yesterday: "Kemarin",
      the_day_before_yesterday: "Sehari sebelum kemarin",
      tomorrow: "Besok",
      next_seven_day: "Tujuh hari ke depan",
      last_seven_day: "Tujuh hari terakhir",
      this_week: "Minggu ini",
      next_week: "Minggu depan",
      last_week: "Minggu lalu",
      this_month: "Bulan ini",
      next_month: "Bulan depan",
      last_month: "Bulan lalu",
      this_year: "Tahun ini",
      next_year: "Tahun depan",
      last_year: "Tahun lalu",
      exact_match: "Pertandingan yang tepat",
      add_value: "Nilai tambah baru",
      remove_value: "Menghapus nilai",
      set_null: "Atur kosong",
      isAny: "Setiap sama",
      notAny: "Apa pun tidak sama dengan",
      belongTo: "Milik",
      notBelongTo: "Bukan milik",
      hasAny: "Berisi apa pun",
      notHasAny: "Tidak mengandung sewenang-wenang",
      prefixContains: "Awal sama dengan",
      prefixNotContains: "Tidak sama dengan awal",
      suffixContains: "Akhir sama dengan",
      suffixNotContains: "Akhir tidak sama dengan",
      nextSevenDay: "7 hari ke depan",
      lastSevenDay: "7 hari terakhir",
      thisWeek: "Minggu ini",
      nextWeek: "Minggu depan",
      lastWeek: "Minggu lalu",
      thisMonth: "Bulan ini",
      nextMonth: "Bulan depan",
      lastMonth: "Bulan lalu",
      thisYear: "Tahun ini",
      nextYear: "Tahun depan",
      lastYear: "Tahun lalu",
      after: "Lebih lambat dari (termasuk hari)",
      before: "Lebih awal dari (termasuk hari)",
      allMatching: "Semua cocok",
      allNotMatching: "Semua tidak cocok",
      hit: "Hit",
      not_hit: "Terpukul",
    },
    fieldsOperators: {
      is: "Sama dengan",
      is_null: "Sementara itu kosong",
      head_equal: "Awal sama dengan",
      tail_equal: "Akhir sama dengan",
      is_not_null: "Pada saat yang sama tidak kosong",
      not: "Tidak sama dengan",
      diff_equal: "Perbedaannya sama dengan",
      diff_greater_than: "Perbedaannya lebih besar dari",
      diff_greater_than_eq: "Perbedaannya lebih besar dari sama dengan",
      diff_less_than: "Perbedaannya kurang dari",
      diff_less_than_eq: "Perbedaannya kurang dari sama dengan",
      hour_equal: "Jam yang sama",
      day_equal: "Pada hari yang sama",
      week_equal: "Minggu yang sama",
      month_equal: "Pada bulan yang sama",
      year_equal: "Pada tahun yang sama",
      day: "Hari",
      minute: "Menit",
      hour: "Jam",
      part_equal: "Bagian sama dengan",
    },
    samplingColumnCaptions: {
      created_at: "Waktu bicara",
      agent_id: "Layanan Pelanggan",
      get_direction: "Jenis panggilan",
      call_result: "Hasil panggilan",
      survey: "Evaluasi",
      derived_from_id: "Sumber",
      quit_queue_why: "Status antrian",
      queuing_duration: "Antrian memakan waktu",
      talking_seconds: "Durasi panggilan",
      ringing_seconds: "Waktu Bel",
      hangup_by: "Telepon menutup",
    },
    callTypes: {
      callIn: "Panggilan masuk",
      callOut: "Hembuskan",
      threeParty: "(Tiga pihak)",
      consultation: "(Konsultasi)",
      insert: "(Kuat plug)",
      monitor: "(Mendengarkan)",
      transfer: "(Transfer)",
      intercept: "(Intersepsi)",
      transferOutside: "(Transfer jalur luar)",
      threePartyOutside: "(Tiga jalur luar)",
      consultingOutside: "(Konsultasi luar)",
    },
    callResults: {
      staffAnswer: "Layanan Pelanggan Menjawab",
      staffNotAnswer: "Layanan pelanggan tidak terhubung",
      customerAnswer: "Pelanggan menjawab",
      customerNotAnswer: "Pelanggan tidak terhubung",
      phoneBusy: "Telepon sibuk",
      phoneOffline: "Telepon offline",
      customerSpeedHangUp: "Gantungan cepat pelanggan",
      customerHangUp: "Pelanggan menutup mesin",
      queueTimeout: "Antrian waktu lembur",
      queueGiveUp: "Menyerah dalam antrean",
      outlineAnswer: "Penjawab luar",
      outlineNotAnswer: "Jalur luar tidak terhubung",
      noChooseQueue: "Antrian tidak dipilih",
    },
    defaultSurveys: {
      noEvaluation: "Tidak dievaluasi",
      noNeedToEvaluate: "Tidak ada evaluasi yang diperlukan",
    },
    queueStates: {
      queueSuccess: "Sukses dalam antrean",
      queueTimeout: "Antrian waktu lembur",
      queueGiveUp: "Menyerah dalam antrean",
      noStaffOnline: "Tidak ada layanan pelanggan online",
    },
    callHangers: {
      customer: "Pelanggan",
      staff: "Layanan Pelanggan",
      outline: "Jalur luar",
    },
    workFlowTypes: {
      noAppealsMode: "Tidak ada mode banding",
      appealsMode: "Memungkinkan modus banding",
    },
    fieldCategories: {
      standardCondition: "Kondisi penyaringan standar",
      customerCondition: "Filter yang ditentukan pengguna",
    },
    sampleTypes: {
      automatic: "Pengambilan Sampel Otomatis",
      manual: "Sampling manual",
    },
    defaultFields: {
      createdAt: "Waktu bicara",
      agentId: "Layanan Pelanggan",
      getDirection: "Jenis panggilan",
      callResult: "Hasil panggilan",
      survey: "Evaluasi",
      derivedFrom: "Sumber",
      quitQueueWhy: "Status antrian",
      queuingDuration: "Antrian memakan waktu",
      talkingSeconds: "Durasi panggilan",
      ringingSeconds: "Waktu Bel",
      hangupBy: "Telepon menutup",
    },
    conditionFieldCategories: {
      fixedField: "Bidang tetap",
      customField: "Bidang kustom",
      secondLevelScore: "Klasifikasi tingkat kedua",
      formItemScore: "Item nilai",
      forbids: "Item yang dilarang",
      deadlies: "Mematikan",
    },
    columnFieldCategories: {
      agent: "Layanan Pelanggan",
      callLog: "Rekaman panggilan",
      businessFields: "Informasi Bisnis",
      customer: "Pelanggan",
      customerCustomFields: "Kustom Pelanggan",
      source: "Sumber",
      queue: "Antrian",
      fixed: "Tetap",
      secondLevelScore: "Klasifikasi tingkat kedua",
      formItemScore: "Item nilai",
      forbids: "Item yang dilarang",
      deadlies: "Mematikan",
    },
    taskListDefaultFields: {
      startTime: "Waktu bicara",
      name: "Layanan Pelanggan",
      state: "Status",
      totalScore: "Total skor",
      formItemForbidList: "Item yang dilarang",
      formItemDeadlyList: "Mematikan",
      comment: "Komentar Komentar",
      inspector: "Pemeringkat",
      review: "Pengulang",
    },
    evaluationStates: {
      initial: "Inisialisasi",
      waitEvaluate: "Untuk dievaluasi",
      evaluated: "Untuk diserahkan",
      waitConfirm: "Untuk konfirmasi",
      waitCheck: "Untuk diperiksa ulang",
      complete: "Selesai",
    },
    workBenchInfoTypes: {
      callInfo: "Informasi panggilan",
      businessInfo: "Informasi Bisnis",
      customerInfo: "Informasi pelanggan",
    },
    evaluationEventStates: {
      create: "Membuat",
      valuate: "Evaluasi",
      edit: "Mengedit",
      submit: "Pengajuan",
      confirm: "Konfirmasi",
      appeal: "Keluhan",
      checkConfirm: "Konfirmasi ulasan",
      check: "Ulasan",
    },
    formItemTypes: {
      direct: "Penilaian langsung",
      weight: "Berat",
      deduct: "Poin pengurangan",
      forbid: "Item yang dilarang",
      deadly: "Mematikan",
    },
    appealCheckCallTypes: {
      callIn: "Panggilan masuk",
      callBack: "Panggilan balik dua arah",
      callOut: "Panggilan langsung keluar",
      autoCall: "Panggilan keluar otomatis",
    },
    appealCheckCallStatus: {
      waitReview: "Untuk diperiksa ulang",
      reviewed: "Telah ditinjau",
      filed: "Diarsipkan",
    },
    samplingStatus: {
      all: "Semua",
      unchecked: "Ekstraksi tidak diperiksa",
      checked: "Telah diperiksa di tempat",
    },
    inspectionStatus: {
      all: "Semua",
      unread: "Belum dibaca",
      readed: "Sudah dibaca",
      appeal: "Keluhan",
      review: "Ulasan",
      filed: "Diarsipkan",
    },
    gradeChooseHits: { yes: "Ya", no: "Tidak." },
    appealCheckStatus: {
      all: "Semua Status",
      unread: "Belum dibaca",
      inComplaint: "Banding",
      reviewed: "Telah ditinjau",
      readed: "Sudah dibaca",
      filed: "Diarsipkan",
      spotchecked: "Telah diperiksa di tempat",
    },
    flowStatus: {
      unread: "Belum dibaca",
      alreadyRead: "Sudah dibaca",
      complaining: "Banding",
      reviewed: "Telah ditinjau",
      sampling: "Ekstraksi tidak diperiksa",
      samplingCompleted: "Telah diperiksa di tempat",
      complainCancel: "Dicabut",
    },
    complainStatus: {
      success: "Sukses",
      fail: "Gagal",
      default: "Banding",
      canceled: "Dicabut",
    },
    reviewInspectionStatus: {
      inComplaint: "Banding",
      reviewed: "Telah ditinjau",
    },
    gradeChangeTypes: {
      edit: "Mengedit",
      check: "Ulasan",
      file: "Arsip",
      remark: "Catatan",
      complain: "Keluhan",
      review: "Ulasan",
      reviewEvaluation: "Evaluasi ulasan",
      sample: "Inspeksi acak",
      sampleEvaluation: "Evaluasi pengambilan sampel",
      sampleSubmit: "Pengambilan Sampel",
      sampleAssign: "Distribusi pengambilan sampel",
      reviewAssign: "Distribusi ulasan",
      read: "Sudah dibaca",
      reviewConfirm: "Konfirmasi ulasan",
      sampleConfirm: "Konfirmasi pengambilan sampel",
      caseBase: "Tambahkan ke grup kasus",
      sampleCancel: "Pengambilan sampel membatalkan alokasi",
      reviewCancel: "Tinjauan pembatalan distribusi",
      sampleAgain: "Pengambilan Sampel Realokasi",
      reviewAgain: "Tinjauan redistribusi",
      btachDelete: "Hapus catatan pengambilan sampel",
      rapidSampling: "Pengambilan Sampel Cepat",
      reInspection: "Pemeriksaan ulang",
      smartTagsChange: "Perubahan Label Cerdas",
      cancelAppeal: "Cabut banding",
    },
    appealTypes: {
      transliterationError: "Kesalahan transliterasi",
      discriminationError: "Kesalahan diskriminasi",
      otherError: "Kesalahan lainnya",
    },
    inspectDataSources: {
      voiceCall: "Panggilan suara",
      textDialogue: "Percakapan teks",
      realTimeVoiceCall: "Panggilan suara langsung",
      realTimeTextDialogue: "Percakapan teks real-time",
      wechatDialogue: "Sesi Mikro",
      taobao: "Dialog teks e-commerce",
      ticket: "Pesanan kerja",
      wechatRadio: "Suara mikro",
    },
    inspectTaskType: {
      all: "Semua",
      common: "Pemeriksaan kualitas rutin",
      relate: "Pemeriksaan kualitas terkait",
    },
    inspectApproveType: {
      all: "Semua",
      resolve: "Lulus audit",
      resolving: "Dalam audit",
      reject: "Audit gagal",
    },
    dataSourceFlags: {
      all: "Tidak ada batasan",
      voiceCall: "Panggilan telepon",
      textDialogue: "Percakapan",
    },
    smartWordsLibrary: { effective: "Efektif", invalid: "Tidak valid" },
    activeFlags: { effiective: "Efektif", invalid: "Tidak valid" },
    labelTypes: {
      dimensionLabel: "Label label",
      classificationLabel: "Label kategori",
    },
    pointTypes: {
      automatic: "Evaluasi otomatis",
      manual: "Evaluasi manual",
      interactiveDetection: "Deteksi interaksi",
      smartRules: "Aturan cerdas",
      machineLearning: "Mode belajar mandiri",
      intelligentModel: "Model cerdas",
    },
    pointGradeTypes: {
      radio: "Pilihan tunggal",
      input: "Masukan",
      select: "Pilih",
      all: "Memenuhi semua",
      any: "Memenuhi apa pun",
      customize: "Kustom",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Pencocokan kata kunci",
      question: "Pertanyaan",
      regular: "Ekspresi reguler",
      context: "Konteks berulang",
      semantics: "Pencocokan semantik",
      word: "Jumlah kata yang serupa",
      dialogue: "Interval waktu percakapan",
      speed: "Deteksi kecepatan bicara",
      grabDetection: "Deteksi terburu-buru",
      semanticTags: "Label semantik",
    },
    applyRoles: {
      all: "Semua",
      customer: "Pelanggan",
      agent: "Layanan Pelanggan",
      robot: "Robot",
    },
    saleApplyRoles: { all: "Semua", customer: "Pelanggan", agent: "Karyawan" },
    applyOperatorScopes: {
      all: "Teks lengkap",
      preCondition: "Kondisi Depan",
      scope: "Menentukan rentang",
    },
    keywordScopes: {
      all: "Mendeteksi semua kata kunci",
      any: "Mendeteksi kata kunci apa pun",
    },
    preOperatorHitTypes: {
      first: "Pertandingan pertama",
      every: "Setiap pertandingan",
      last: "Pertandingan terakhir",
      any: "Setiap pertandingan",
      none: "Tidak cocok",
    },
    operatorRuleTypes: {
      all: "Memenuhi semua",
      any: "Memenuhi apa pun",
      custom: "Kustom Logika",
    },
    informationEntityTypes: {
      system: "Sistem",
      custom: "Kustom",
      moduleCompany: "Modul",
    },
    organizationTypes: { null: "Tidak ada", moduleCompany: "Modul" },
    customType: {
      smartTag: "Ekstraksi entitas",
      scriptTimes: "Penghitungan bicara",
      dataCategory: "Klasifikasi data",
    },
    interactiveQuestionTypes: {
      standard: "Tanya Jawab Standar",
      multiElement: "Pertanyaan dan jawaban multi-elemen",
      guideMultiRound: "Interaksi multi-roda yang dipandu",
    },
    targetTypes: {
      all: "Semua",
      customerService: "Layanan Pelanggan",
      customer: "Pelanggan",
    },
    interactiveTypes: {
      similarQList: "Masalah serupa",
      exceptSimilarQList: "Mengecualikan masalah serupa",
      similarAList: "Jawaban serupa",
      wrongAList: "Jawaban yang salah",
    },
    filterTypes: {
      call: "Rekaman panggilan",
      dialogue: "Teks percakapan",
      wechat: "Perusahaan WeChat",
      wechatAll: "Semua dialog WeChat perusahaan",
      ticket: "Sesi pesanan kerja",
      taobao: "Percakapan e-commerce",
      wechatRadio: "Suara mikro",
    },
    fieldTypes: {
      keyword: "Kata kunci",
      agent: "Layanan Pelanggan",
      agentGroup: "Layanan Pelanggan",
      sigleListbox: "Pilihan tunggal",
      multiListbox: "Pilihan ganda",
      text: "Satu baris teks",
      textArea: "Beberapa baris teks",
      dateTime: "Waktu",
      number: "Angka",
      voice: "",
      customer: "Pelanggan",
      fieldWithLink: "Bidang dengan link",
      percentage: "Persentase",
      intelligentClassification: "Klasifikasi cerdas",
      testSetList: "Uji Set Bidang Dinamis",
      inspector: "Inspektur kualitas",
    },
    saveTypes: { save: "Simpan" },
    knowledgeType: { oneToOne: "Tanya dan jawab" },
    updateTypes: { update: "Pembaruan", saveAs: "Simpan sebagai" },
    recommendItemActionTypes: {
      similar: "Mirip",
      exclude: "Pengecualian",
      ignore: "Abaikan",
    },
    gradeStatus: {
      unread: "Belum dibaca",
      alreadyReview: "Telah ditinjau",
      alreadyFiled: "Diarsipkan",
      waitReview: "Untuk diperiksa ulang",
      alreadyRead: "Sudah dibaca",
    },
    qualityCheckDetailTypes: { review: "Ulasan", spotCheck: "Inspeksi acak" },
    sampleAndReviewStatus: {
      init: "Tidak dipilih",
      unread: "Belum dibaca",
      alreadyRead: "Sudah dibaca",
      complaining: "Banding",
      reviewed: "Telah ditinjau",
      sampling: "Ekstraksi tidak diperiksa",
      samplingCompleted: "Telah diperiksa di tempat",
    },
    interactiveAnswerTypes: {
      noAnswer: "Tidak ada jawaban yang terdeteksi",
      answer: "Mendeteksi jawaban",
      wrongAnswer: "Jawaban yang salah terdeteksi",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Tidak ada masalah yang terdeteksi",
      question: "Masalah terdeteksi",
      eliminateQuestion: "Menghilangkan masalah terdeteksi",
    },
    isManual: {
      yes: "Pemeriksaan kualitas manual",
      no: "Pemeriksaan kualitas tidak manual",
    },
    readStatus: { yes: "Sudah dibaca", no: "Belum dibaca" },
    reInspectionType: {
      recalcScore: "Hanya menghitung ulang skor (termasuk peringkat)",
      recheckPoint: "Hitung ulang poin dan skor inspeksi kualitas",
    },
    common: {
      eCommerceReview: "Ulasan e-commerce",
      socialMediaComments: "Komentar media sosial",
      customer: "Pelanggan",
      customerService: "Layanan Pelanggan",
      robot: "Robot",
      customerInformation: "Informasi pelanggan",
      workOrderFields: "Bidang perintah kerja",
      intelligentConversationAnalysis: "Analisis sesi cerdas",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Wawasan tentang kekurangan bisnis, dengarkan pelanggan, dan tingkatkan transformasi pemasaran",
      intelligentAccompanyingPractice: "Pelatihan cerdas",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Buat sparring AI imersif yang mensimulasikan adegan nyata bisnis, untuk mencapai penguasaan penuh pidato emas, meningkatkan keterampilan dialog dengan cepat",
      intelligentQualityInspection: "Pemeriksaan kualitas yang cerdas",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Inspeksi kualitas volume penuh, tepat waktu dan efisien, tingkat akurasi lebih dari 85%",
      salesEmpowerment: "Pemberdayaan penjualan",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI membentuk kembali manajemen penjualan, mewujudkan digitalisasi proses komunikasi, visualisasi, manajemen transparan setiap cincin penjualan, wawasan cerdas setiap langkah komunikasi, diagnosis masalah bisnis yang disempurnakan, sempurna pengalaman mahkota penjualan",
      algorithmPlatform: "Platform Algoritma",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Berdasarkan semantik suara mesin asli yang dikembangkan sendiri, kustomisasi model ASR dan NLP, membuka data skenario bisnis, mewujudkan pembelajaran mandiri sistem, untuk mencapai tujuan lebih banyak penggunaan lebih cerdas, lebih banyak penggunaan lebih akurat",
      generalSettings: "Pengaturan umum",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Manajemen pengaturan terpadu untuk izin data dan peran karyawan",
      callLog: "Rekaman panggilan",
      intelligentRules: "Aturan cerdas",
      businessRecords: "Catatan bisnis",
      customFilter: {
        call: "Panggilan suara",
        text: "Percakapan teks",
        smartTags: "Label pintar",
        pretreatment: "Pretreatment",
        smartTagGroup: "Kelompok label pintar",
        currency: "Data umum",
        wechat: "Perusahaan WeChat",
        spotCheck: "Inspeksi acak",
        personaField: "Daftar pelanggan",
        ticket: "Sesi pesanan kerja",
        taobao: "Percakapan e-commerce",
        wechatRadio: "Suara mikro",
        reviewList: "Ulasan",
      },
      talkerRecognitionType: {
        API: "Peran Track Identifikasi API",
        text: "Pengenalan teks",
      },
      operatorTypes: {
        keyword: "Pencocokan kata kunci",
        question: "Pertanyaan",
        regular: "Ekspresi reguler",
        context: "Konteks berulang",
        semantics: "Pencocokan semantik",
        word: "Jumlah kata yang serupa",
        dialogue: "Interval waktu percakapan",
        speed: "Deteksi kecepatan bicara",
        grabDetection: "Deteksi terburu-buru",
        semanticTags: "Label semantik",
        keywordStrict: "Pencocokan Kata Kunci (Ketat)",
        link: "Bidang link",
        entityInfoDetection: "Deteksi entitas informasi",
        basicInfoDetection: "Deteksi informasi dasar",
        volume: "Deteksi volume",
        emotion: "Analisis emosi",
        grammar: "Aturan tata bahasa",
        sentenceLength: "Deteksi panjang kalimat",
        conceptWord: "Kata konsep",
        questionsAndAnswers: "Solusi basis pengetahuan (lama)",
        element: "Pertanyaan dan jawaban elemen",
        silent: "Menanggapi batas waktu",
        associate: "Data bersama jalan",
        repeat: "Kirim berulang kali",
        timeInterval: "Panjang interval",
        typoDetection: "Deteksi kesalahan ketik",
        silentOld: "Diam",
        knowledge: "Jawaban pengetahuan",
        wecomRemark: "Catatan mikro perusahaan pelanggan",
        customerAddTime: "Pelanggan menambahkan waktu",
      },
      keywordOperators: {
        all: "Semua cocok",
        allNot: "Semua tidak cocok",
        any: "Pencocokan apa pun",
      },
      applyRoles: {
        all: "Semua",
        customer: "Pelanggan",
        agent: "Layanan Pelanggan",
      },
      sentenceLength: {
        less: "Kurang dari",
        greater: "Lebih besar dari",
        equal: "Sama dengan",
        greater_or_equal: "Lebih besar dari sama dengan",
        less_or_equal: "Kurang dari sama dengan",
      },
      dialogueApplyScopes: {
        differentRoles: "Antara peran yang berbeda",
        agent: "Layanan Pelanggan",
        customer: "Pelanggan",
        agentResponse: "Jawaban layanan pelanggan",
        customerResponse: "Jawaban pelanggan",
        customerNoResponse: "Tidak ada respon dari pelanggan",
      },
      applyConditionHitTypes: {
        first: "Hit pertama",
        every: "Setiap hit",
        last: "Pukulan terakhir",
      },
      applyConditionScopes: {
        current: "Saat ini",
        before: "Sebelum",
        after: "Setelah",
        beforeAll: "Sebelum semua",
        afterAll: "Setelah itu semua",
        around: "Dekat",
      },
      voiceDemoTypes: {
        singleChannel: "Rekaman panggilan saluran tunggal",
        dualChannel: "Rekaman panggilan dua saluran",
      },
      sendStatus: {
        sending: "Mengirim",
        arrive: "Dikirim",
        fail: "Gagal mengirim",
        off_sending: "Kirim offline yang belum dibaca",
        off_arrive: "Sudah dibaca",
        rollback: "Ditarik",
      },
      collectionMethod: {
        assignment: "Penugasan langsung",
        entity: "Ekstraksi entitas",
      },
      systemTypes: {
        qa: "Pemeriksaan kualitas yang cerdas",
        wfm: "Jadwal cerdas",
      },
      entityOperators: {
        equals: "Sama dengan informasi",
        notEquals: "Tidak sama dengan informasi",
        contains: "Berisi informasi",
        notContains: "Tidak mengandung informasi",
        equalsFix: "Sama dengan nilai tetap",
        notEqualsFix: "Tidak sama dengan nilai tetap",
        containsFix: "Berisi nilai tetap",
        notContainsFix: "Tidak mengandung nilai tetap",
      },
      basicInfoOperators: {
        equals: "Sama dengan",
        notEquals: "Tidak sama dengan",
        contains: "Berisi",
        notContains: "Tidak mengandung",
        isNull: "Kosong",
        isNotNull: "Tidak kosong",
      },
      volumeDetectionWays: {
        loudness: "DB kenyaringan",
        rangeAbilitySelf:
          "Amplitudo perubahan (dibandingkan dengan kalimat saya)",
        rangeAbilityOth: "Amplitudo perubahan (di atas kalimat)",
        rangeAbility3: "Perubahan amplitudo (dari kalimat sendiri)",
      },
      volumeDetectionOperators: {
        is: "Sama dengan",
        not: "Tidak sama dengan",
        greaterThan: "Lebih besar dari",
        greaterThanEq: "Lebih besar dari sama dengan",
        lessThan: "Kurang dari",
        lessThanEq: "Kurang dari sama dengan",
      },
      sentimentTypes: {
        positive: "Suasana positif",
        negative: "Emosi negatif",
        neutral: "Emosi netral",
        thankful: "Terima kasih",
        happy: "Senang",
        complaining: "Mengeluh",
        angry: "Marah",
        post: "Bagian depan",
        negat: "Negatif",
        neut: "Netral",
      },
      emotionOperators: { is: "Memenuhi", not: "Tidak cocok" },
      propTypes: { system: "Sistem", user: "Kustom" },
      valueTypes: { text: "Teks", number: "Angka" },
    },
    tasks: {
      allowCustomGrievance: "Memungkinkan untuk menyesuaikan alasan banding",
      businessSummary: "Ringkasan bisnis",
      theDayBeforeYesterday: "Sehari sebelum kemarin",
      assessActionTypes: { reassess: "Penilaian ulang" },
      scoreType: {
        aiScore: "Evaluasi cerdas",
        manualScore: "Evaluasi manual",
        interactiveDetection: "Deteksi interaksi",
        smartRules: "Aturan cerdas",
        machineLearning: "Mode belajar mandiri",
      },
      inspectType: {
        inspectTrue: "Periksa ke",
        inspectFalse: "Tidak diperiksa",
      },
      operatorType: {
        keyword: "Pencocokan kata kunci",
        notKeyword: "Kata kunci tidak cocok",
      },
      applyRole: {
        all: "Semua",
        customer: "Pelanggan",
        agent: "Layanan Pelanggan",
      },
      applyScope: {
        all: "Semua",
        specific: "Menentukan kalimat",
        scope: "Menentukan rentang",
      },
      judgeStrategy: {
        all: "Semua kondisi terpenuhi",
        arbitrarily: "Memenuhi kondisi apa pun",
        custom: "Kustom Logika",
      },
      checkPointStatus: {
        waitFor: "Menunggu ulasan",
        notDeductScore: "Tidak ada poin",
        deductScore: "Poin pengurangan",
      },
      applyOptions: {
        hit: "Kondisi Hit",
        notHit: "Tidak ada kondisi yang ditentukan",
      },
      gradeTypes: {
        yesOrNo: "Apakah sesuai",
        input: "Masukan manual",
        level: "Evaluasi peringkat",
        multipleFactors: "Multi-elemen",
      },
      gradeChooseHits: { yes: "Ya", no: "Tidak." },
      gradeChangeTypes: {
        edit: "Mengedit",
        check: "Ulasan",
        file: "Arsip",
        remark: "Catatan",
        complain: "Keluhan",
        review: "Ulasan",
        reviewEvaluation: "Evaluasi ulasan",
        sample: "Inspeksi acak",
        sampleEvaluation: "Evaluasi pengambilan sampel",
        sampleSubmit: "Pengambilan Sampel",
        sampleAssign: "Distribusi pengambilan sampel",
        reviewAssign: "Distribusi ulasan",
        read: "Sudah dibaca",
        reviewConfirm: "Konfirmasi ulasan",
        sampleConfirm: "Konfirmasi pengambilan sampel",
      },
      gradeStatus: {
        unread: "Belum dibaca",
        alreadyReview: "Telah ditinjau",
        alreadyFild: "Diarsipkan",
        waitReview: "Untuk diperiksa ulang",
        alreadyRead: "Sudah dibaca",
      },
      samplingFrequencyTypes: {
        everyDay: "Setiap hari",
        everyWeek: "Mingguan",
        everyMonth: "Bulanan",
      },
      samplingEveryDayTimeTypes: { yesterday: "Kemarin", today: "Hari ini" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Minggu ini",
        lastWeek: "Minggu lalu",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Bulan ini",
        lastMonth: "Bulan lalu",
      },
      samplingTypes: {
        random: "Pengambilan Sampel Acak",
        proportional: "Sampel proporsional",
      },
      weekdays: {
        Monday: "Senin",
        Tuesday: "Selasa",
        Wednesday: "Rabu",
        Thursday: "Kamis",
        Friday: "Jumat",
        Saturday: "Sabtu",
        Sunday: "Minggu",
      },
      distributeTypes: {
        rule: "Aturan prioritas distribusi",
        average: "Distribusi acak rata-rata",
      },
      notMatchAnyRuleTypes: {
        random: "Distribusi acak dan merata",
        assign: "Ditugaskan untuk orang yang ditunjuk",
        ignore: "Tidak ada alokasi",
      },
      inspectorTypes: {
        all: "Semua inspektur kualitas",
        some: "Inspektur kualitas yang ditunjuk",
      },
      pushFrequencyTypes: {
        everyDay: "Setiap hari",
        everyWeek: "Mingguan",
        everyMonth: "Bulanan",
        inspectionUpdate: "Pembaruan pemeriksaan kualitas",
        timelyInspection: "Pemeriksaan kualitas real-time",
      },
      pushObjectTypes: {
        email: "Kotak surat yang ditentukan",
        staff: "Layanan Pelanggan",
      },
      reviewOptions: {
        reviewResult:
          "Hasil pemeriksaan kualitas ditampilkan selama peninjauan",
        reviewRecord: "Menampilkan catatan yang relevan saat meninjau",
        reviewAgentHidden:
          "Sembunyikan informasi layanan pelanggan saat meninjau",
        samplingResult:
          "Hasil pemeriksaan kualitas ditampilkan selama pengambilan sampel",
        samplingRecord: "Catatan terkait ditampilkan selama pengambilan sampel",
        sampleAgentHidden:
          "Sembunyikan informasi layanan pelanggan selama pengambilan sampel",
        checkExportAddCallInfoIphone:
          "Menambahkan catatan panggilan saat mengekspor daftar",
        sampleExportAddCallInfoIphone:
          "Menambahkan catatan panggilan saat mengekspor daftar",
        checkExportAddCallInfoText:
          "Menambahkan rekaman percakapan saat mengekspor daftar",
        sampleExportAddCallInfoText:
          "Menambahkan rekaman percakapan saat mengekspor daftar",
        inspectionExportAddCallInfoIphone:
          "Menambahkan catatan panggilan saat mengekspor daftar (hingga 10.000 per ekspor)",
        inspectionExportAddCallInfoText:
          "Menambahkan rekaman percakapan saat mengekspor daftar (hingga 10.000 per ekspor)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Memungkinkan pemeriksaan spot cepat pada rincian pemeriksaan kualitas",
        fastSampleInSampleDetail:
          "Memungkinkan pelaksanaan pengambilan sampel cepat pada rincian pengambilan sampel",
        fastSampleInReviewDetail:
          "Memungkinkan pelaksanaan pengambilan sampel cepat pada rincian ulasan",
      },
      permitCustomReasonList: {
        permitCustomReason: "Izinkan alasan klaim kustom",
      },
      autoMateTasks: {
        autoLoad: "Ditambahkan secara otomatis",
        sample: "Pengambilan Sampel Otomatis",
        assign: "Alokasi otomatis",
        pushes: "Dorong otomatis",
        assess: "Penilaian otomatis",
        check: "Pemeriksaan ulang banding",
        case: "Sinkronisasi basis data kasus",
      },
      qualityCheckDetailTypes: { review: "Ulasan", spotCheck: "Inspeksi acak" },
      appealTypes: {
        transliterationError: "Kesalahan transliterasi",
        discriminationError: "Kesalahan diskriminasi",
        otherError: "Kesalahan lainnya",
      },
      inspectDataSources: {
        voiceCall: "Panggilan suara",
        textDialogue: "Percakapan teks",
        realTimeVoiceCall: "Panggilan suara langsung",
        realTimeTextDialogue: "Percakapan teks real-time",
      },
      pointTypes: {
        automatic: "Evaluasi otomatis",
        manual: "Evaluasi manual",
        interactiveDetection: "Deteksi interaksi",
      },
      pointGradeTypes: {
        radio: "Pilihan tunggal",
        input: "Masukan",
        select: "Pilih",
      },
      sampleAndReviewStatus: {
        init: "Tidak dipilih",
        unread: "Belum dibaca",
        alreadyRead: "Sudah dibaca",
        complaining: "Banding",
        reviewed: "Telah ditinjau",
        sampling: "Ekstraksi tidak diperiksa",
        samplingCompleted: "Telah diperiksa di tempat",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Abaikan overflow berlebih",
        automaticWeightCalculation: "Penghitungan berat otomatis",
      },
      calculateScoreTypes: { add: "Poin tambahan", sub: "Kurangi poin" },
      naChecked: { check: "Tes izin tidak melibatkan ini" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Berikan poin secara proporsional",
        allRight: "Semua poin diberikan dengan benar",
        anyRight: "Berikan poin dengan benar",
      },
      noHitQuestionResults: {
        true: 'Hasilnya adalah "Ya"',
        false: 'Hasilnya adalah "Tidak"',
      },
      repetRules: {
        same: "Konten yang persis sama",
        similar: "Kesamaan semantik",
      },
      relateConditions: {
        highlightQuestion: "Masalah penyorotan",
        highlightAnswer: "Jawaban yang disorot",
        hightlightFoctors: "Elemen penyorotan",
      },
      interactiveQuestionTypes: {
        question: "Masalah terdeteksi",
        eliminateQuestion: "Menghilangkan masalah terdeteksi",
      },
      interactiveAnswerTypes: {
        noAnswer: "Tidak ada jawaban yang terdeteksi",
        answer: "Mendeteksi jawaban",
        wrongAnswer: "Jawaban yang salah terdeteksi",
      },
      inspectionUpdateTypes: {
        autoInspection: "Pemeriksaan kualitas otomatis",
        autoInspectionWechat: "Analisis otomatis",
        artificialSampling: "Inspeksi manual",
        artificialReview: "Pemeriksaan manual",
        manualModification: "Pengambilan Sampel Cepat",
        timelyInspection: "Pemeriksaan kualitas real-time",
        samplingAssign: "Distribusi pengambilan sampel",
        artificialComplain: "Pengajuan banding",
        fastSampling: "Pengajuan pengambilan sampel cepat",
        recheckInspection: "Pemeriksaan ulang",
        recheckInspectionWechat: "Analisis ulang",
      },
      timelyInspectionTypes: {
        timelyInspection: "Pemeriksaan kualitas real-time",
      },
      pushActionTypes: { email: "Surat", interfacePush: "Mendorong antarmuka" },
      pushDetailWays: {
        riskAlert: "Pengingat risiko",
        subscribe: "Berlangganan pesan",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Pesan Panggilan/Percakapan",
        inspectionResults: "Hasil pemeriksaan kualitas",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Pesan Panggilan/Percakapan",
        textContent: "Konten teks panggilan/percakapan",
        inspectionResults: "Hasil pemeriksaan kualitas",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Rata-rata skor dalam grup",
        addOrSubtract: "Poin penambahan dan pengurangan langsung",
        noChange: "Tidak ada poin tambahan atau pengurangan",
      },
      defaultScore: {
        empty: "Kosong",
        add: "Poin tambahan",
        notAdd: "Tidak ada poin",
        sub: "Kurangi poin",
        notSub: "Tidak ada pengurangan poin",
        na: "N/A",
        input: "Masukan manual",
        choose: "Pilih",
      },
      strategyTypes: {
        hitAdd: "Tekan poin ekstra",
        hitNotAdd: "Tidak ada poin hit",
        hitSub: "Kehilangan poin",
        hitNotSub: "Jangan kehilangan poin",
      },
    },
    download: {
      exportStatus: {
        prepare: "Persiapan",
        process: "Dalam proses",
        faild: "Ekspor gagal",
        success: "Sukses",
      },
    },
    messageType: {
      all: "Semua",
      monitor: "Pemantauan instan",
      trigger: "Memicu pemberitahuan",
      timing: "Laporan waktu",
    },
    messagePushType: {
      all: "Semua",
      system: "Pesan Sistem",
      sdk: "Pesan SDK",
      push: "Mendorong antarmuka",
      email: "Surat",
      udesk: "Udesk Pemberitahuan",
      udeskWhite: "Pemberitahuan sistem layanan pelanggan",
      weChat: "Berita mikro",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Penugasan petugas pengambilan sampel",
      sampleInspector: "Petugas pengambilan sampel",
      assignReviewer: "Penugasan reaudit",
      reviewer: "Pengulang",
      taskInspector: "Inspektur Kualitas Tugas",
    },
    sdkPushType: {
      webHook: "Webhook standar",
      dingDing: "Robot paku",
      weChat: "Perusahaan WeChat Robot",
      customer: "Kustom Konten Json",
    },
    inspectionRemarksType: {
      inspection: "Pemeriksaan kualitas",
      check: "Inspeksi acak",
      review: "Ulasan",
    },
    segmentationStrategyType: {
      count: "Jumlah pesan",
      spacing: "Interval pesan",
    },
    segmentationStrategy: {
      byTime: "Waktu pemotongan",
      customize: "Kombinasi potongan",
    },
    inspectType: {
      commonInspect: "Pemeriksaan kualitas rutin",
      associateInspect: "Pemeriksaan kualitas terkait",
    },
    typoDetectionOperators: {
      less: "Kurang dari",
      greater: "Lebih besar dari",
    },
    autoAddTypes: {
      realTimeAdd: "Ditambahkan secara real time",
      timingAdd: "Waktu ditambahkan",
    },
    messageTypes: { text: "Pesan teks", markdown: "Berita Markdown" },
    inServiceStatus: {
      activated: "Diaktifkan",
      disabled: "Dinonaktifkan",
      inactivated: "Tidak diaktifkan",
      resigned: "Keluar dari bisnis",
    },
    roles: { agent: "Karyawan", customer: "Pelanggan" },
    commentVisibleAuthority: {
      all: "Terlihat untuk semua orang",
      self: "Hanya bisa dilihat sendiri",
      designation: "Orang yang ditunjuk terlihat",
    },
    appealCheckingTask: {
      eCommerceReview: "Ulasan e-commerce",
      socialMediaComments: "Komentar media sosial",
      largeModel: "Model besar",
      customModel: "Model kustom",
      universalModel: "Model universal",
      enterpriseWeChatText: "Teks perusahaan",
      generalQualityInspection: "Pemeriksaan kualitas biasa",
      processQualityInspection: "Proses pemeriksaan kualitas",
      voiceCopy: "Piringan suara",
      beEqualTo: "Sama dengan",
      notEqualTo: "Tidak sama dengan",
      canAppeal: "Dapat mengajukan banding",
      noAppeal: "Tidak bisa mengeluh",
    },
    wechat: {
      redEnvelopes: "Amplop merah",
      text: "Teks",
      picture: "Gambar",
      voice: "Suara",
      voiceCall: "Panggilan suara",
      video: "Video",
      businessCard: "Kartu nama",
      position: "Lokasi",
      expression: "Ekspresi",
      link: "Tautan",
      applet: "Program kecil",
      chatRecord: "Catatan obrolan",
      mixedMessage: "Pesan Campuran",
      file: "Dokumen",
    },
    gong: {
      sessionActivity: "Kegiatan sesi",
      conversationAction: "Aksi sesi",
      riskSemantics: "Semantik risiko",
      daysNotFollowedUp: "Jumlah hari yang belum ditindaklanjuti",
      waysideData: "Data bersama jalan",
      notStarted: "Belum dimulai",
      completed: "Selesai",
      haveInHand: "Dalam proses",
      unpublished: "Belum dirilis",
      published: "Dirilis",
      knowledgePoints: "Poin pengetahuan",
      technologicalProcess: "Proses",
      notMarked: "Tidak ditandai",
      inAnnotation: "Dalam label",
      annotated: "Ditandai",
    },
    qualityChecingTask: {
      appealCanceled: "Pembatalan banding",
      complaintExpired: "Banding kedaluwarsa",
      incomingCall: "Panggilan masuk",
      exhale: "Hembuskan",
      incomingCallthirdParty: "Panggilan masuk (tiga pihak)",
      incomingCallconsultation: "Panggilan masuk (konsultasi)",
      incomingCallforcedInsertion: "Panggilan masuk (steker kuat)",
      incomingCallmonitoring: "Panggilan masuk (mendengarkan)",
      incomingCalltransfer: "Panggilan masuk (transfer)",
      incomingCallinterception: "Panggilan masuk (intersepsi)",
      incomingCalltransferToExternalLine:
        "Panggilan masuk (jalur luar transfer)",
      incomingCallthirdPartyExternalLine: "Panggilan masuk (tiga jalur luar)",
      incomingCallexternalLine: "Panggilan masuk (konsultasi luar)",
      outgoingCallthirdParty: "Hembuskan (tiga pihak)",
      outgoingCalltransfer: "Hembuskan (Transfer)",
      outgoingCallforcedInsertion: "Hembuskan (steker kuat)",
      outgoingCallmonitoring: "Hembuskan (mendengarkan)",
      outgoingCallinterception: "Hembuskan (intersep)",
      outgoingCalltransferToExternalLine:
        "Panggilan keluar (jalur luar transfer)",
      outgoingCallthreePartyExternalLine: "Hembuskan (tiga jalur luar)",
      customerServiceAnswer: "Layanan Pelanggan Menjawab",
      customerServiceMissed: "Layanan pelanggan tidak terhubung",
      customerAnswer: "Pelanggan menjawab",
      customerMissed: "Pelanggan tidak terhubung",
      theTelephoneIsBusy: "Telepon sibuk",
      phoneOffline: "Telepon offline",
      customerQuickHanging: "Gantungan cepat pelanggan",
      clientHangsUp: "Pelanggan menutup mesin",
      queueTimeout: "Antrian waktu lembur",
      giveUpQueuing: "Menyerah dalam antrean",
      outsideLineAnswering: "Penjawab luar",
      externalLineIsNotConnected: "Jalur luar tidak terhubung",
      noQueueSelected: "Antrian tidak dipilih",
      notEvaluated: "Tidak dievaluasi",
      noEvaluationRequired: "Tidak ada evaluasi yang diperlukan",
      queuingSucceeded: "Sukses dalam antrean",
      noCustomerServiceOnline: "Tidak ada layanan pelanggan online",
      customer: "Pelanggan",
      customerService: "Layanan Pelanggan",
      outsideLines: "Jalur luar",
    },
    sessionActivity: {
      single: "Rata-rata waktu bicara tunggal",
      all: "Total panggilan",
      allTime: "Total Durasi Panggilan",
    },
    sessionAction: {
      salesProportion: "Penjualan berbicara",
      salesLongest: "Rata-rata penjualan terpanjang berbicara",
      customerLongest: "Rata-rata pelanggan terpanjang berbicara",
      discussNumber: "Jumlah diskusi mendalam",
      frequency: "Frekuensi pertanyaan",
    },
    monitorCriteria: {
      all: "Depan dan belakang",
      before: "Sebelum",
      after: "Setelah",
    },
    monitorTimeCycle: { day: "Hari", week: "Minggu", month: "Bulan" },
    monitorGroupTestType: {
      ab: "Perbandingan dua kelompok",
      a: "Grup A",
      b: "Grup B",
    },
    fields: {
      department: "Departemen",
      customerTags: "Pelanggan Label",
      member: "Anggota",
      time: "Waktu",
      cascade: "Tingkat",
      anyMatch: "Pencocokan apa pun",
      keyWord: "Kata kunci",
      keyEvents: "Peristiwa kunci",
    },
    semanticIntelligence: {
      staff: "Karyawan",
      customer: "Pelanggan",
      employeesAndCustomers: "Karyawan dan pelanggan",
      notStarted: "Belum dimulai",
      haveInHand: "Dalam proses",
      completed: "Selesai",
      callRecording: "Rekaman panggilan",
      enterpriseWeChatCall: "Panggilan perusahaan mikro",
      enterpriseWeChatText: "Teks perusahaan",
      discourseMiningForObjectionHandling: "Penanganan keberatan",
      productSellingPointScriptsMining: "Menambang titik penjualan produk",
      conversationScenario: "Adegan sesi",
      topicOfConversation: "Topik",
      keyEvents: "Peristiwa kunci",
      customerLabel: "Pelanggan Label",
      includeAny: "Berisi apa pun",
      includeAll: "Berisi semua",
      and: "Dan",
      or: "Atau",
      question: "Pertanyaan",
      nonInterrogativeSentence: "Kalimat non-interogatif",
    },
    coach: {
      notPassed: "Tidak lulus",
      analogTelephone: "Telepon analog",
      enterScenarioInformation: "Masukkan informasi skenario",
      generateDialogueFramework: "Menghasilkan bingkai dialog",
      generatePromptLanguage: "Menghasilkan petunjuk",
      aIGeneratedDialogue: "AI menghasilkan percakapan",
      system: "Sistem",
      custom: "Kustom",
      flow: "Proses",
      script: "Kata-kata",
      express: "Ekspresi",
      emotion: "Emosi",
      rateAccordingToCourseConfiguration: "Skor berdasarkan konfigurasi kursus",
      intelligentModelRating: "Penilaian model cerdas",
      allData: "Semua data",
      dataFromThisDepartment: "Data departemen ini",
      multidepartmentalData: "Data multi-sektor",
      underReview: "Dalam audit",
      no: "Tidak.",
      yes: "Ya",
      automaticRating: "Penilaian otomatis",
      auditRating: "Skor audit",
      reRating: "Skor ulang",
      inAppeal: "Banding",
      reviewed: "Telah ditinjau",
      notViewed: "Tidak dilihat",
      viewed: "Telah dilihat",
      uncorrected: "Tidak dikoreksi",
      corrected: "Diubah",
      practice: "Latihan",
      examination: "Ujian",
      other: "Lainnya",
      notMeetingStandards: "Tidak memenuhi standar",
      meetingStandards: "Standar",
      excellent: "Luar biasa",
      invalid: "Kegagalan",
      notStarted: "Belum dimulai",
      haveInHand: "Dalam proses",
      ended: "Sudah berakhir",
      completed: "Selesai",
      hangInTheAir: "Belum selesai",
      passed: "Telah melewati",
      voiceConversation: "Percakapan suara",
      textDialogue: "Percakapan teks",
      slidePresentation: "Pidato slide",
      unpublished: "Belum dirilis",
      published: "Dirilis",
      narration: "Narasi",
      studentSpeaking: "Siswa berbicara",
      robotTalk: "Robot berbicara",
      knowledgeBaseQA: "Tanya Jawab Basis Pengetahuan",
      slide: "Slide",
      negativeEmotions: "Emosi negatif",
      emotionallyNormal: "Suasana hati normal",
      incompleteScript: "Kata-kata tidak lengkap",
      completeScript: "Kata-kata lengkap",
      normalSpeechSpeed: "Kecepatan bicara normal",
      speakTooFast: "Berbahasa terlalu cepat",
      speakTooSlowly: "Kecepatan bicara terlalu lambat",
      whole: "Semua",
      singlePage: "Satu Halaman",
      qA: "Tanya dan jawab",
      situationalDialogue: "Dialog Skenario",
      misses: "Terpukul",
      hit: "Hit",
      sequentialQA: "Pertanyaan dan jawaban berurutan",
      randomQA: "Pertanyaan dan jawaban acak",
      mastered: "Telah menguasai",
      notMastered: "Tidak menguasai",
    },
    algorithm: {
      salesEmpowerment: "Pemberdayaan penjualan",
      enterpriseWeChatVoice: "Suara perusahaan WeChat",
      enterpriseWeChatText: "Teks WeChat perusahaan",
      waiting: "Menunggu",
      extracting: "Dalam ekstraksi",
      success: "Sukses",
      fail: "Gagal",
      intelligentQualityInspection: "Pemeriksaan kualitas yang cerdas",
      textDialogue: "Percakapan teks",
      voiceCall: "Panggilan suara",
      smartTags: "Label pintar",
      satisfactionEvaluation: "Evaluasi kepuasan",
      score: "Penilaian",
      grade: "Peringkat",
      customerServiceTeam: "Layanan Pelanggan",
      conversationAnalysis: "Analisis sesi",
    },
    operatorListGather: {
      businessSummary: "Ringkasan bisnis",
      firstSentence: "Kalimat pertama",
      lastSentence: "Kalimat terakhir",
      allTime: "Waktu penuh",
      nonBusinessWorkingHours: "Jam kerja non-bisnis",
      businessWorkingHours: "Jam kerja bisnis",
    },
    callDialogueManage: {
      eCommerceReview: "Ulasan e-commerce",
      socialMediaComments: "Komentar media sosial",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Masuk",
        email: "Kotak surat",
        password: "Kata sandi",
        forgetPwd: "Lupa kata sandi",
        emailCannotBeEmpty: "Kotak surat tidak boleh kosong.",
        passwordCannotByEmpty: "Kata sandi tidak boleh kosong.",
        mustChangeLogin:
          "Maaf, kata sandi Anda harus disetel ulang. Hubungi administrator untuk menyetel ulang kata sandi Anda.",
        dismissionLogin: "Maaf, Anda telah pergi.",
        forbiddenLogin: "Maaf, akun Anda dinonaktifkan.",
        loginError: "Gagal login, email atau kata sandi salah.",
        loginPrompt: "Login ke Sistem",
        rememberMe: "Ingat aku",
      },
      logout: {
        logoutError:
          "Logout gagal, lihat koneksi jaringan atau coba lagi nanti.",
      },
      resetPassword: {
        forgetPwd: "Lupa kata sandi",
        inputEmailPrompt:
          "Masukkan email akun Anda di bawah ini dan kami akan mengirimkan email dan instruksi untuk mengatur ulang kata sandi Anda.",
        sendEmailSuccess:
          "Ubah kata sandi untuk mengonfirmasi bahwa surat itu telah berhasil dikirim ke kotak surat yang Anda isi, silakan periksa tepat waktu.",
        sendEmailFailed:
          "Pengiriman kotak surat gagal, periksa koneksi jaringan atau coba lagi nanti.",
        setNewPassword: "Mengatur kata sandi baru Anda",
        passwordCannotEmpty: "Kata sandi tidak boleh kosong",
        passwordFormWrong: "Format kata sandi tidak benar",
        confirmPasswordWrong: "Kata sandi yang dimasukkan dua kali tidak sama.",
        passwordResetSuccess: "Kata sandi berhasil diatur ulang.",
        passwordResetFailed:
          "Jika pengaturan ulang kata sandi gagal, periksa koneksi jaringan atau coba lagi nanti.",
        linkTimeover:
          "Atur ulang tautan kata sandi kedaluwarsa, silakan coba lagi",
      },
      form: { title: "CASE Bentuk Showcase" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Kotak surat",
          emailFormatError:
            "Format kotak surat salah, silakan masukkan kembali!",
          forgotPassword: "Lupa kata sandi?",
          describe:
            "Masukkan email akun Anda di bawah ini dan kami akan mengirimkan email dan instruksi untuk mengatur ulang kata sandi Anda.",
          send: "Kirim",
          emailCannotBeEmpty: "Kotak surat tidak bisa kosong",
          resetPasswordExpire:
            "Atur ulang tautan kata sandi kedaluwarsa, silakan coba lagi",
        },
        resetPassword: {
          setPassword: "Mengatur kata sandi baru Anda",
          newPassword: "Kata sandi baru",
          repeatPassword: "Ulangi kata sandi",
          resetPassword: "Atur ulang kata sandi",
          passwordCannotBeEmpty: "Kata sandi baru tidak bisa kosong.",
          confirmPasswordCannotBeEmpty:
            "Kata sandi duplikat tidak bisa kosong.",
          passwordsNotMatch: "Kata sandi tidak konsisten dua kali!",
          passwordFormWrong:
            "Kata sandi tidak dapat membawa bahasa Mandarin, dan jumlahnya 6-14 digit!",
          linkExpiration:
            "Atur ulang tautan kata sandi kedaluwarsa, silakan coba lagi",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Anda tidak memiliki izin untuk melihat halaman ini",
      },
    },
    downloadCenter: {
      name: "Pusat Unduhan",
      saveTime: "(Simpan secara default selama 30 hari)",
      headerTitle: "Mengunduh file",
      download: "Unduh",
      downloadPromp: "Tugas unduhan telah dikirimkan, silakan kunjungi",
      viewProgress: "Lihat kemajuan",
    },
    uploadCenter: { name: "Pusat Unggah" },
    tasks: {
      index: {
        name: "Nama",
        remark: "Deskripsi",
        qualityCheckTasksTitle: "Meja kerja",
        informationCollection: "Tugas pengumpulan",
        qualityCheckTasks: "Tugas pemeriksaan kualitas",
        wechatQaTasks: "Menganalisis tugas",
        moduleName: "Menggunakan template untuk membuat baru",
        creatNewQualityCheckTask: "Tugas baru",
        generalInspectionList: "Laporan pemeriksaan kualitas umum",
        editQualityCheckTask: "Mengedit tugas pemeriksaan kualitas",
        creatNewQualityCheckTaskError: "Misi inspeksi kualitas baru gagal",
        editQualityCheckTaskError: "Mengedit tugas pemeriksaan kualitas gagal",
        type: "Jenis Formulir",
        singleScore: "Skor tunggal",
        singleScoreNullError: "Skor tunggal tidak bisa kosong",
        inspector: "Inspektur kualitas",
        inspectDataSource: "Sumber Data",
        taskName: "Nama tugas",
        inspectionType: "Jenis pemeriksaan kualitas",
      },
      manage: {
        screeningCondition: "Kondisi penyaringan",
        template: {
          condition: {
            index: { name: "Kondisi", existingConditions: "Kondisi yang ada" },
            detail: {
              id: "ID",
              name: "Nama Kondisi",
              remark: "Deskripsi",
              checkRange: "Rentang deteksi",
              operator: "Operator",
              operatorLogic: "Logika operator",
            },
          },
          checkPointTest: {
            title: "Uji Aturan",
            running: "Dalam uji aturan, silakan nanti...",
            clearSuccess: "Dialog analog berhasil dikosongkan",
            mock: "Dialog analog",
            save: "Simpan percakapan yang dipilih",
            saveAll: "Simpan semua percakapan",
            saveSuccess: "Percakapan berhasil disimpan",
            close: "Keluar",
            delete: "Hapus yang dipilih",
            clear: "Kosongkan",
            customer: "Pelanggan",
            agent: "Layanan Pelanggan",
            test: "Pengujian",
            request:
              "Dialog yang disimulasikan tidak akan dapat dipulihkan setelah dikosongkan. Apakah sudah dikonfirmasi?",
            deletePopconfirmContent:
              "Apakah Anda mengonfirmasi menghapus dialog yang dipilih?",
          },
          checkPoint: {
            index: {
              name: "Konfigurasi aturan",
              wechatTitle: "Menganalisis konfigurasi model",
              existingCheckPoints: "Aturan pemeriksaan kualitas yang ada",
              currentCheckPointScore:
                "Skor aturan inspeksi kualitas saat ini adalah",
              currentCheckPointScoreWechat:
                "Skor model analisis saat ini adalah",
              currentCheckPointWeight:
                "Jumlah berat aturan inspeksi kualitas saat ini adalah",
              weightTotalScoreAndItemScore:
                "Total skor: 100 poin Poin tunggal:",
              template: "Templat aturan pemeriksaan kualitas",
              import: "Mengimpor templat aturan pemeriksaan kualitas",
              getTemplateError:
                "Aturan pemeriksaan kualitas gagal mendapatkan template",
              tabs: { template: "Template", demo: "Contoh" },
              customize: "Template kosong",
              move: "Seret untuk menyesuaikan klasifikasi dan pengurutan",
              classification: "Klasifikasi pemeriksaan kualitas",
              classificationWeChat: "Klasifikasi Model",
              first: "Klasifikasi tingkat pertama",
              second: "Klasifikasi tingkat kedua",
              new: "Aturan pemeriksaan kualitas baru",
              weChatNew: "Menambahkan model analisis",
              createWeChat: "Model analisis baru",
              empty: "Tidak ada aturan pemeriksaan kualitas,",
              wechatEmpty: "Tidak ada model analisis,",
              newSecond: "Klasifikasi sekunder baru",
              newFirst: "Klasifikasi baru",
              checkPoint: "Aturan pemeriksaan kualitas",
              checkPointWeChat: "Model analisis",
            },
            detail: {
              name: "Nama",
              remark: "Deskripsi",
              type: "Jenis",
              deduction: "Poin pengurangan",
              rule: "Aturan",
              highlighted: "Terang",
              gradeType: "Metode penilaian",
              interactiveCategory: "Klasifikasi Interaksi",
              predeterminedScore: "Skor",
              weight: "Berat",
              na: "N/A",
              default: "Skor default",
              preRule: "Aturan pra-kondisi",
              analysisHighlighted: "Mengurai aturan",
              categorySelectPrompt: "Silakan pilih kategori",
              checkHighlightArrayError:
                "Aturan tidak cocok dengan sorot, silakan klik lagi tombol mengurai aturan",
              foctorsHolder: "Silakan pilih elemen",
              foctorsErrorNone:
                "Aturan multi-elemen kosong, silakan tambahkan tipe multi-elemen",
              naError:
                "Item ini tidak dapat dimatikan ketika skor default adalah N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A adalah Aplikasi Not. Pra-kondisi ditetapkan untuk aturan inspeksi kualitas artikel ini, dan diuji ketika kondisi terpenuhi. Ketika kondisi tidak terpenuhi, aturan tidak berlaku, dan aturan artikel tidak terdeteksi;\nCatatan pengisian pra-syarat: "&&" berarti "dan", "||" berarti "atau", "!" Berarti "tidak", isi contoh "(R1 || R2) && R3"',
                applicationMessageType: "Jenis pesan aplikasi",
                selectAll: "Pilih semua",
                highlightTags: "Label sorot:",
                ruleType: "Jenis Aturan",
                dataSource: "Sumber Data",
                whole: "Semua",
                enterpriseMicroConversation: "Sesi Mikro",
                enterpriseAndMicroCommunication: "Panggilan perusahaan mikro",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Menilai node",
                    dialogueNode: "Node Percakapan",
                    editNodes: "Mengedit node",
                  },
                  nodeConfig: {
                    nodeName: "Nama simpul",
                    nodeRules: "Aturan Node",
                    scoringLogic: "Logika penilaian",
                    score: "Skor:",
                    rule: "Aturan",
                    multiBranchLogic: "Logika multi-cabang",
                    highlight: "Terang",
                    submit: "Pengajuan",
                  },
                  ruleList: {
                    branch: "Cabang",
                    branchName: "Nama cabang:",
                    addRules: "Menambah aturan",
                  },
                  sidebar: { node: "Node" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Pengaturan otomatis",
                      canvasAdaptation: "Kanvas Adaptif",
                      copy: "Menyalin",
                      delete: "Hapus",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Menilai bahwa node anak dari node tidak dapat digunakan sebagai tujuan koneksi",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Node awal tidak dapat digunakan sebagai tujuan koneksi",
                      startingNodeCannotConnectMultipleNodes:
                        "Node awal tidak dapat menghubungkan beberapa node",
                      nodeCannotBeConnectedToItself:
                        "Node tidak dapat terhubung dengan diri mereka sendiri",
                      startNode: "Mulai node",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Batalkan semua pilihan",
                    selectAll: "Pilih semua",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Apakah sesuai",
                    gradedEvaluation: "Evaluasi peringkat",
                  },
                },
              },
            },
            configuration: {
              title: "Aturan pemeriksaan kualitas",
              wechatTitle: "Model analisis",
              neme: "Nama",
              errorMessage: "Hapus kegagalan, setidaknya satu syarat",
              desc: "Deskripsi",
              status: "Status",
              yes: "Efektif",
              no: "Tidak valid",
              namePlaceHolder: "Silahkan masukkan nama label",
              desPlaceHolder: "Silakan masukkan deskripsi",
              nameEmpty: "Nama tidak boleh kosong",
              desEmpty: "Deskripsi tidak bisa kosong",
              type: "Jenis",
              ruler: "Aturan cerdas",
              machineLearning: "Mode belajar mandiri",
              autoScore: "Penilaian Cerdas (Lama)",
              manualScore: "Penilaian manual",
              interaction: "Deteksi Interaksi (Lama)",
              typeTips: {
                model:
                  'Model cerdas: Ini adalah sistem inspeksi kualitas yang telah ditetapkan, yang dapat dengan cepat memeriksa masalah dalam layanan tanpa konfigurasi dan pemeliharaan. Model ini lebih umum. Jika Anda tidak memenuhi persyaratan bisnis, silakan gunakan "aturan pintar" atau "skor manual',
                rule: "Aturan cerdas: penilaian otomatis berdasarkan aturan AI",
                manual:
                  "Penilaian manual: yaitu pemeriksaan kualitas manual, penilaian manual",
              },
              model: "Model:",
              ruleType: {
                words: "Kata-kata",
                interaction: "Interaksi",
                feature: "Fitur",
                information: "Informasi",
              },
              testingMode: {
                all: "Mendeteksi semua kata kunci",
                any: "Mendeteksi kata kunci apa pun",
              },
              operatorTypesOfInteraction: {
                question: "Tanya dan jawab",
                element: "Jawaban elemen",
                repet: "Konteks berulang",
                snatch: "Kata terburu-buru",
                silent: "Diam",
              },
              role: "Mendeteksi peran:",
              preposition: "Operator depan:",
              range: "Rentang deteksi:",
              from: "UU No.",
              to: "Kalimat ke No.",
              end: "Kalimat",
              hasOldSilentTip:
                'Halo, untuk meningkatkan akurasi, "Aturan pintar-Interaktif-Diam" telah ditingkatkan menjadi "Response Timeout", disarankan untuk memperbarui aturan penggunaan!',
              changeToOld: "Beralih ke versi lama",
              changeToNew: "Beralih ke versi baru",
              logic: {
                logic: "Logika penilaian",
                naTip:
                  "N/A adalah Aplikasi Not, menetapkan prasyarat untuk aturan inspeksi kualitas artikel ini, dan mendeteksi ketika kondisi terpenuhi. Jika kondisi tidak terpenuhi, aturan tidak berlaku, maka aturan artikel tidak terdeteksi.",
                score: "Skor:",
                na: "N/A：",
                naDes: "Tes izin tidak melibatkan ini",
                preRule: "Aturan pra-kondisi:",
                notCatchPreRule: "Ketika prasyarat tidak terpenuhi:",
                naChecked: "Saat memukul N/A:",
                preRuleEmpty: "Aturan pra-kondisi tidak bisa kosong",
                operatorEmpty:
                  "Kondisi yang diperlukan dalam operator tidak bisa kosong",
                multipleEmpty:
                  "Kondisi yang diperlukan dalam penilaian multi-logika tidak boleh kosong",
                average: "Rata-rata skor dalam grup",
                addOrSubtract: "Poin penambahan dan pengurangan langsung",
                noChange: "Tidak ada poin tambahan atau pengurangan",
                rule: "Aturan:",
                all: "Memenuhi semua operator",
                any: "Memenuhi operator apa pun",
                customize: "Kustom operator logika",
                multiple: "Beberapa penilaian logika",
                heightLight: "Terang:",
                machineLearningTip:
                  "Mode pembelajaran mandiri saat ini hanya berlaku untuk teknik kalimat tunggal, silakan gunakan aturan cerdas untuk teknik yang kompleks",
                strategy: "Strategi:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Persetujuan pengiriman berhasil",
            },
            components: {
              pageHeaderExtra: {
                test: "Pengujian",
                formal: "Resmi",
                submit: "Pengajuan",
              },
              createModal: { index: { myRules: "Aturan saya" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Otomatisasi proses",
              screeningCondition: "Kondisi penyaringan",
              aiScreeningCondition: "Kondisi penyaringan cerdas",
              rule: "Aturan",
              automaticAddition: "Ditambahkan secara otomatis",
              automaticAdditionTip:
                "Secara otomatis menambahkan data ke tugas secara real time atau waktu untuk pemeriksaan kualitas AI",
              autoAdd: {
                autoAddType: "Cara menambahkan secara otomatis",
                addFrequency: "Menambahkan frekuensi",
                add: "Tambahkan",
              },
              automaticSamplingInspection: "Pengambilan Sampel Otomatis",
              automaticSamplingInspectionTip:
                "Secara otomatis mengekstrak data yang memerlukan pemeriksaan kualitas manual, misalnya, setiap hari mengambil 10% dari data yang diambil secara acak kemarin untuk pengambilan sampel manual",
              everyAgentCheckLimit: "Batas ditarik per layanan pelanggan",
              automaticAllocation: "Alokasi otomatis",
              automaticAllocationTip:
                "Alokasi inspeksi acak: Data yang diambil secara otomatis dialokasikan ke inspektur kualitas. Misalnya, 10% dari data acak kemarin diambil setiap hari untuk inspeksi acak manual. Rata-rata, 2 inspektur kualitas ditugaskan secara acak untuk tugas ini.",
              automaticAllocationTip2:
                "Penugasan ulasan: Data untuk pengaduan secara otomatis ditetapkan untuk pemeriksa kualitas, misalnya, data untuk pengaduan harian dialokasikan secara acak untuk 2 digit pemeriksa kualitas untuk tugas ini",
              automaticPush: "Dorong otomatis",
              automaticPushTip:
                "Pemberitahuan pemicu: Pemberitahuan/peringatan saat pemeriksaan kualitas, misalnya, ketika terjadi kesalahan fatal pada layanan pelanggan, kirim pesan pemberitahuan ke grup mikro",
              automaticPushTip2:
                "Laporan waktu: Kirim hasil pemeriksaan kualitas ke laporan waktu melalui surat, misalnya, pada jam 9:00 setiap hari, kirim hasil pemeriksaan kualitas dari pengambilan sampel manual kemarin ke kotak surat supervisor layanan pelanggan",
              automaticAssess: "Penilaian otomatis",
              automaticAssessTip:
                "Secara teratur, hasil pengambilan sampel manual dibandingkan dengan hasil pemeriksaan kualitas AI untuk evaluasi akurasi. Misalnya, pada pukul 8:00 setiap hari, data yang diambil sampel kemarin akan dievaluasi untuk akurasi",
              reexaminationComplaint: "Pemeriksaan ulang banding",
              spoktReviewDetail: "Konfigurasi bisnis",
              spoktReviewDetailTip:
                "Konfigurasi fungsi untuk daftar inspeksi kualitas, daftar inspeksi acak, bisnis banding, daftar ulasan, komentar, dll.",
              qaDetailAction: "Konfigurasi pemeriksaan kualitas",
              spoktDetailAction: "Konfigurasi inspeksi acak",
              reviewDetailAction: "Konfigurasi ulasan",
              appealAction: "Konfigurasi banding",
              remarkTemplate: "Templat Catatan",
              automaticArchiving: "Arsip otomatis",
              conditionalRules: "Aturan Kondisi",
              qualityInspectionConditions: "Kondisi pemeriksaan kualitas",
              unopened: "Belum dibuka",
              sampleInspectionDistributionModel:
                "Distribusi pengambilan sampel",
              reviewAllocationModel: "Distribusi ulasan",
              distributionModelTitle: "Strategi distribusi:",
              pushRules: "Aturan push",
              push: "Dorong",
              assessRules: "Aturan penilaian",
              on: "on",
              off: "off",
              updateStatusSuccess: "Perubahan status berhasil!",
              updateStatusFailed: "Perubahan status gagal, coba lagi nanti!",
              sampleNumberOrPercent: "Jumlah sampel atau proporsi",
              inspectorList: "Inspektur kualitas yang ditunjuk",
              numberOfSubmission: "Jumlah pengajuan yang diizinkan",
              rewivewDays: "Hari",
              TimeOfSubmission: "Masa berlaku pengajuan",
              rewivewSecond: "Kali",
              switchOn: "Buka",
              switchOff: "Tutup",
              notMatchAnyRule:
                "Ketika tidak cocok dengan aturan sewenang-wenang:",
              appealOptionsList: {
                hiddenInspector:
                  "Keluhan menyembunyikan informasi pemeriksa kualitas",
                hiddenHit: "Halaman keluhan mendukung hit posisi sorot",
              },
              caseLibrary: "Sinkronisasi basis data kasus",
              caseLibrarySyncTip:
                "Sinkronkan kasus yang ditambahkan dengan sistem QA ke sistem layanan pelanggan",
              addCase: "Meningkatkan sinkronisasi",
              caseLibraryId: "Basis data kasus",
              caseLibraryIdTip: "Pengelompokan basis data kasus sistem QA",
              udeskCaseLibraryIdTip:
                "Pengelompokan kasus sistem layanan pelanggan",
              syncHis: "Kasus Sejarah Sinkron",
              otherSettings: "Pengaturan lainnya",
              udeskCaseLibraryId: "Kelompok kasus push",
              url: "URL push",
              urlTip:
                "Masukkan alamat antarmuka yang dimulai dengan http:// atau https://",
            },
            templateNew: {
              secondaryReview: "Tingkat kedua ulasan",
              numberOfAppealsAllowedToBeSubmitted:
                "Jumlah banding yang diizinkan",
              validityOfSubmissionOfAppeal: "Masa berlaku pengajuan banding",
              validityOfAppealReview: "Masa berlaku ulasan banding",
              hours: "Jam",
              businessSummary: "Ringkasan bisnis",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Batas bawah per layanan pelanggan",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Ringkasan bisnis secara otomatis dihasilkan oleh model besar, dimensi yang dapat dihasilkan sesuai dengan situasi bisnis",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Strategi Penugasan Pengulang Hanya Satu Tingkat",
              secondaryCompounding: "Komposit sekunder:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Pengulang tingkat kedua hanya mendukung distribusi yang merata",
              secondLevelReviewer: "Pengulang tingkat kedua:",
              samplingInspectionTarget: "Target inspeksi acak",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Tetapkan tujuan yang harus diselesaikan untuk inspeksi acak, dan berikan petunjuk ketika target tidak tercapai atau terlampaui. Misalnya, setiap layanan pelanggan harus menyelesaikan 100 inspeksi acak setiap bulan, dan ketika kurang dari 80, halaman inspeksi dan inspeksi acak akan diminta.",
            },
            component: {
              verificationFailed: "Kegagalan verifikasi",
              pleaseAddASecondLevelQualityInspector:
                "Silakan tambahkan inspektur kualitas sekunder",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Pesan",
                    fieldName: "Nama Bidang",
                    fieldDescription: "Deskripsi bidang",
                    generateContent: "Menghasilkan Konten",
                    enabled: "Apakah Diaktifkan",
                    generationMethod: "Cara menghasilkan",
                    automaticGeneration: "Menghasilkan secara otomatis",
                    manualTrigger: "Pemicu manual",
                    filterCondition: "Kondisi penyaringan",
                    return: "Kembali",
                    preserve: "Simpan",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Menghasilkan hasil" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Tidak terbatas",
                    limitedScope: "Rentang Terbatas",
                    add: "Tambahkan",
                    thereIsCurrentlyNoDataAvailable: "Tidak ada data",
                  },
                },
              },
              index: {
                fieldName: "Nama Bidang",
                fieldDescription: "Deskripsi bidang",
                nothing: "Tidak ada",
                generationMethod: "Cara menghasilkan",
                generateManually: "Menghasilkan secara manual",
                automaticGeneration: "Menghasilkan secara otomatis",
                generateContent: "Menghasilkan Konten",
                notQualified: "Tidak terbatas",
                filterCondition: "Kondisi penyaringan",
                enabled: "Apakah Diaktifkan",
                type: "Jenis",
                system: "Sistem",
                custom: "Kustom",
                operation: "Operasi",
                edit: "Mengedit",
                areYouSureToDeleteIt: "Apakah Anda yakin untuk menghapusnya?",
                delete: "Hapus",
                addField: "Menambahkan bidang",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Konfigurasi peran",
              roleConfigTip:
                "Ketika data panggilan yang diimpor adalah mono, aturan pengenalan konfigurasi dipisahkan untuk panggilan. Peran layanan pelanggan yang memenuhi aturan",
              operator: "Operator",
              operatorLogic: "Logika operator",
            },
          },
          manualAdd: {
            index: {
              name: "Menambah secara manual",
              tip: "Digunakan untuk memfilter data pratinjau dengan konfigurasi manual dan dengan cepat menambahkannya ke tugas pemeriksaan kualitas",
              screeningCondition: "Tambahkan berdasarkan filter",
              aiScreeningCondition: "Kondisi penyaringan cerdas",
              rule: "Aturan",
              createTime: "Menambahkan waktu",
              filterTitle: "Memfilter konten",
              filterRule: "Kondisi penyaringan",
              startTime: "Waktu mulai",
              endTime: "Waktu akhir",
              records: "Impor catatan",
              createUserName: "Operator",
              successCount: "Tambahkan Sukses",
              conditionRule: "Aturan Kondisi:",
              preview: "Pratinjau",
              previewData:
                "Saat ini memenuhi persyaratan penyaringan {0}, telah menambahkan item {1}, Anda dapat menambahkan item {2}, dan Anda tidak dapat menambahkan item {3}",
              failCount: "Menambahkan kegagalan",
            },
          },
          basicInfo: {
            index: {
              title: "Informasi dasar",
              name: "Nama",
              taskName: "Nama tugas",
              taskNamePlaceholder: "Masukkan nama tugas",
              requiredName: "Nama tugas tidak bisa kosong",
              taskRemarkPlaceholder: "Masukkan deskripsi tugas",
              requiredRemark: "Deskripsi tugas tidak bisa kosong",
              remark: "Deskripsi",
              taskRemark: "Deskripsi tugas",
              source: "Sumber Data",
              benchmarkScore: "Poin dasar",
              benchmarkScorePlaceholder: "Silakan masukkan skor",
              scoreLowerLimit: "Batas bawah skor minimum",
              scoreUpperLimit: "Batas skor tertinggi",
              inspectType: "Jenis pemeriksaan kualitas",
              nlpModel: "Model NLP",
              commonInspect: "Pemeriksaan kualitas rutin",
              associateInspect: "Pemeriksaan kualitas terkait",
              associateCycle: "Siklus asosiasi",
              associateRegulation: "Aturan asosiasi",
              judgeStrategy: "Logika eksekusi",
              rule: "Aturan",
              commonTip:
                "Pemeriksaan kualitas rutin: berlaku untuk komunikasi tunggal, dialog, pemeriksaan kualitas konten komunikasi tunggal",
              associateTip:
                "Pemeriksaan kualitas terkait: Berlaku untuk beberapa komunikasi dan dialog, menghubungkan konten beberapa komunikasi untuk pemeriksaan kualitas",
              cycleTip:
                "Jika beberapa komunikasi dan dialog terkait, memenuhi data dalam siklus terkait untuk memasuki tugas ini",
              scoreLowerLimitPlaceholder:
                "Silakan masukkan skor minimum yang lebih rendah",
              scoreUpperLimitPlaceholder:
                "Silakan masukkan batas skor tertinggi",
              call: "Panggilan suara",
              dialogue: "Percakapan teks",
              user: "Inspektur kualitas",
              wechatUser: "Operator",
              noDesignated: "Tidak ditentukan",
              type: "Jenis Formulir",
              singleScore: "Skor tunggal",
              conduct:
                "Silakan menyempurnakan informasi berikut terlebih dahulu, setelah selesai silakan klik Berikutnya",
              conduct2:
                "Silakan lengkapi aturan pemeriksaan kualitas dan konfigurasi titik pemeriksaan kualitas. Jika Anda telah menyelesaikan konfigurasi, Anda dapat mengklik Berikutnya atau keluar dari panduan",
              conduct3:
                "Peringkat inspeksi kualitas adalah konfigurasi peringkat berdasarkan titik inspeksi kualitas dan situasi penilaian. Anda dapat menentukan tingkat hasil inspeksi kualitas yang berbeda. Jika Anda tidak memerlukan peringkat, Anda dapat langsung melewati langkah ini. Jika Anda telah menyelesaikan konfigurasi, Anda dapat mengklik Berikutnya atau keluar dari panduan.",
              conduct4:
                "Anda dapat mengkonfigurasi karakteristik data yang akan secara otomatis dimasukkan ke dalam tugas sesuai dengan karakteristik data yang harus diperiksa dalam tugas pemeriksaan kualitas ini. Jika Anda tidak perlu menambahkan data secara otomatis untuk saat ini, Anda dapat mengklik untuk melewati. Jika Anda telah menyelesaikan konfigurasi, Anda dapat mengklik Berikutnya",
              conduct5:
                "Jika Anda menggunakan rekaman mono, pastikan untuk mengonfigurasi item ini dan klik berikutnya, silakan gunakan hanya layanan pelanggan yang akan digunakan untuk membantu sistem mengidentifikasi trek yang berbeda dari layanan pelanggan dan pelanggan. Jika Anda menggunakan rekaman dua saluran atau konfigurasi selesai, Anda dapat mengklik untuk melewatkan",
              conduct6:
                "Anda dapat menambahkan fungsi secara manual untuk memperkenalkan data percakapan yang sudah ada dalam sistem untuk pemeriksaan kualitas, atau Anda telah mengirimkan tugas penambahan manual, silakan klik langsung untuk menyelesaikan",
              helper: "Melihat dokumen bantuan",
              quit: "Panduan keluar",
              next: "Berikutnya",
              complete: "Selesai",
              skip: "Melompati",
              cancel: "Pembatalan",
              delete: "Hapus",
              save: "Simpan",
            },
            template: {
              samplingInspector: "Petugas pengambilan sampel",
              sessionType: "Jenis Sesi",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Meja kerja pemeriksaan kualitas",
              search: "Penyaringan",
              export: "Ekspor",
              condition: "Kondisi",
              logic: "Logika",
              reQualityInspection: "Pemeriksaan ulang",
              reQualityInspectionUnderWay: "Tugas pemeriksaan ulang...",
              reQualityInspectionUnderWayTip:
                "Meninggalkan halaman setelah menutup jendela tidak akan mempengaruhi tugas",
              reQualityInspectionDone: "Pemeriksaan ulang telah selesai",
              reQualityInspectionDoneTip:
                "Sekarang telah berhasil menyelesaikan pemeriksaan ulang, silakan klik tombol konfirmasi",
              endReQualityInspection: "Mengakhiri tugas",
              alreadyDoneTip: "Selesai",
              totalTip: "Total",
              unit: "Strip",
              endReInspectionSuccess:
                "Tugas pemeriksaan ulang telah dihentikan",
              closeWindow: "Tutup jendela",
              endReInspectionFailed:
                "Misi pemeriksaan ulang gagal, silakan coba lagi nanti!",
            },
            detail: {
              name: "Rincian pemeriksaan kualitas",
              reviewName: "Rincian ulasan",
              spotCheckName: "Rincian pengambilan sampel",
              audioText: "Merekam teks",
              callInfo: "Informasi panggilan",
              satisfactionEvaluation: "Evaluasi kepuasan:",
              queueTime: "Antrian memakan waktu:",
              ringingTime: "Waktu dering:",
              callTime: "Acara panggilan:",
              hangUpIdentity: "Pihak yang menutup panggilan:",
              location: "Atrium:",
              source: "Sumber:",
              agent: "Layanan Pelanggan:",
              deviceType: "Jenis perangkat:",
              callType: "Jenis panggilan:",
              phoneNumber: "Nomor relay:",
              task: "Tugas:",
              commentsTip: "Silakan masukkan ulasan pemeriksaan kualitas",
              lastPage: "Sebelumnya",
              nextPage: "Halaman berikutnya",
              score: "Skor pemeriksaan kualitas",
              review: "Ulasan",
              file: "Arsip",
              changeList: "Perubahan catatan",
              fullMarks: "Skor sempurna",
              most: "Paling banyak",
              saveReview: "Pengajuan ulasan",
              saveSpotCheck: "Pengambilan Sampel",
              point: "Titik pemeriksaan kualitas",
              sampleAssignInspector: "Penugasan petugas pengambilan sampel",
              reviewAssignInspector: "Penugasan reaudit",
              sampleStatus: "Status pengambilan sampel",
              read: "Sudah dibaca",
              user: "Pengguna",
              confirmer: "Konfirmasi",
              voiceDownloadError:
                "Unduhan suara gagal, silakan coba lagi nanti!",
              smartTags: "Label pintar",
              interactiveTip:
                "Gunakan identifikasi interaktif untuk mendeteksi penggunaan FAQ layanan pelanggan",
              interactiveIsEmptyTip:
                "Konten identifikasi interaktif tidak terdeteksi",
              businessRecords: "Catatan bisnis",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Analisis obrolan grup WeChat perusahaan",
            },
          },
        },
      },
      cards: {
        health: "Kesehatan pemeriksaan kualitas",
        accuracy: "Temukan tingkat persetujuan",
        sessionsTotal: "Jumlah total sesi",
        enable: "Aktifkan",
        disable: "Menonaktifkan",
        none: "Tidak ada",
        call: "Panggilan telepon",
        dialogue: "Percakapan",
        taobao: "E-commerce",
        wecom: "Sesi",
        ticket: "Pesanan kerja",
      },
      component: {
        backup: "(Backup)",
        taskSaveAsSucceeded: "Tugas disimpan untuk sukses",
        copyContent: "Menyalin konten",
        essentialInformation: "Informasi dasar",
        ruleConfiguration: "Konfigurasi aturan",
        basicInformationQualityInspector: "Informasi dasar-inspektur kualitas",
        qualityInspectionRating: "Peringkat pemeriksaan kualitas",
        roleConfiguration: "Konfigurasi peran",
        autoAddConfiguration: "Secara otomatis menambahkan konfigurasi",
        autoPushConfiguration: "Konfigurasi push otomatis",
        automaticSamplingConfiguration: "Konfigurasi inspeksi acak otomatis",
        automaticAllocationConfiguration: "Konfigurasi alokasi otomatis",
        businessConfiguration: "Konfigurasi bisnis",
        caseBaseConfiguration: "Basis data kasus",
      },
      template: {
        cancel: "Pembatalan",
        complete: "Selesai",
        sort: "Menyortir",
        ruleApproval: "Persetujuan Aturan",
        enterpriseMicroConversationAnalysisTask:
          "Tugas analisis percakapan mikro perusahaan",
        enterpriseAndMicroVoiceAnalysisTask:
          "Tugas analisis suara mikro perusahaan",
        saveTaskAs: "Tugas disimpan sebagai",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Rincian Aturan" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Berhasil dimodifikasi",
            automaticApproval: "Persetujuan otomatis",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Berhasil dimodifikasi",
            test: "Pengujian",
            formal: "Resmi",
            adopt: "Melalui",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Apakah Anda yakin untuk menolak?",
              pleaseEnterTheRejectionReason:
                "Silakan masukkan alasan penolakan",
              reject: "Penolakan",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Berhasil dimodifikasi",
              taskName: "Nama tugas",
              dataSource: "Sumber Data",
              qualityInspectionType: "Jenis pemeriksaan kualitas",
              state: "Status",
              updateTime: "Perbarui waktu",
              updatedBy: "Memperbarui orang",
              approver: "Pemberi persetujuan",
              reasonForRejection: "Alasan untuk menolak",
              operation: "Operasi",
              details: "Detail",
              adopt: "Melalui",
              whole: "Semua",
              approvalStatus: "Status persetujuan",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Sekilas",
      hi: "Hai,",
      standardEdition: "Versi standar",
      call: "Saldo pemeriksaan kualitas suara",
      text: "Validitas pemeriksaan kualitas teks",
      collectionTasks: "Tugas pemeriksaan kualitas koleksi",
      noCollection: "Tidak ada tugas pemeriksaan kualitas untuk koleksi,",
      goToCollecte: "Pergi ke koleksi",
      yesterdayOverView: "Statistik bisnis kemarin",
      yesterdayAgent: "Peringkat layanan pelanggan kemarin",
      yesterdayInspWork: "Pekerjaan inspektur kualitas kemarin",
      operationalStatistics: {
        title: "Statistik Bisnis",
        qualityTesting: "Pemeriksaan kualitas",
        spotCheck: "Inspeksi acak",
        appeal: "Keluhan",
        chartTitles: {
          qualityTestingTrend: "Tren pemeriksaan kualitas",
          qualityTestingAnalysis: "Tugas pemeriksaan kualitas",
          spotCheckTrend: "Tren pengambilan sampel",
          spotCheckAnalysis: "Tugas pengambilan sampel",
          appealTrend: "Tren banding",
          appealAnalysis: "Tugas banding",
        },
        titles: {
          qualityDataTend: "Distribusi data inspeksi kualitas",
          qualityCover: "Cakupan inspeksi kualitas",
          qualityAverageTime: "Waktu tunggu pemeriksaan kualitas rata-rata",
          qualityCoverTend: "Tren cakupan pemeriksaan kualitas",
          handCover: "Penanganan manual",
          checkCover: "Cakupan pengambilan sampel",
          resAverageTime: "Waktu pemrosesan rata-rata",
          handTend: "Tren pemrosesan manual",
          dataTend: "Distribusi tugas data",
        },
        qualityAverage: "Nilai rata-rata pemeriksaan kualitas",
        scoreTrend: "Tren skor pemeriksaan kualitas",
        qualityAverageTime: "Waktu tunggu pemeriksaan kualitas rata-rata",
        callCoverage: "Cakupan pemeriksaan kualitas suara",
        textCoverage: "Cakupan pemeriksaan kualitas teks",
        checkCount: "Jumlah Data Inspeksi Sampel",
        siphonCount: "Jumlah data yang diekstraksi",
        reviewCount: "Meninjau jumlah data",
        appealCount: "Jumlah data banding",
        checkRate: "Rasio eksekusi pengambilan sampel",
        reviewRate: "Rasio ulasan banding",
        PieTitle: {
          totalCount: "Jumlah total percakapan",
          totalCall: "Jumlah panggilan suara",
          totalIm: "Jumlah percakapan teks",
          inspectionCall: "Kuantitas pemeriksaan kualitas suara",
          inspectionIm: "Kuantitas pemeriksaan kualitas teks",
          checkCall: "Jumlah pengambilan sampel suara",
          unCheckCall: "Jumlah suara yang tidak diperiksa",
          checkIm: "Jumlah pengambilan sampel teks",
          unCheckIm: "Jumlah teks yang tidak diperiksa",
          reviewCall: "Jumlah ulasan suara",
          reviewIm: "Jumlah ulasan teks",
          unReviewCall: "Jumlah suara yang tidak diperiksa",
          unReviewIm: "Jumlah teks yang belum diperiksa",
          unInspectionCall: "Suara tanpa pemeriksaan kualitas",
          unInspectionIm: "Jumlah teks yang tidak diperiksa",
        },
        check_rate: "Cakupan pengambilan sampel",
        voice_check_rate: "Cakupan pengambilan sampel suara",
        text_check_rate: "Cakupan pengambilan sampel teks",
        sample_execute_time: "Waktu pengambilan sampel rata-rata",
        review_execute_time: "Waktu pemrosesan banding rata-rata",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Aturan pemeriksaan kualitas Kanban",
          agent: "Peringkat Layanan Pelanggan",
        },
        tableTips: {
          conditions:
            "<Div> Urutkan jumlah total percakapan umum yang melanggar aturan pemeriksaan kualitas dan kepatuhan. <Br/>\nItem pemeriksaan kualitas: Nama titik pemeriksaan kualitas <br />\nJumlah total dialog ilegal: Jumlah total dialog ilegal pada item inspeksi kualitas <br />\nProporsi pelanggaran: Proporsi pelanggaran = Jumlah total percakapan pelanggaran/total percakapan komunikasi </div>",
          agent:
            "<Div> Urutkan peringkat rata-rata untuk kursi <br />\nNama Layanan Pelanggan: Nama Layanan Pelanggan <br />\nGrup Layanan Pelanggan: Grup Layanan Pelanggan di mana layanan pelanggan berada (informasi organisasi dalam informasi layanan pelanggan)<br />\nJumlah total percakapan yang dinilai: Jumlah percakapan pemeriksaan kualitas layanan pelanggan <br />\nNilai rata-rata pemeriksaan kualitas: nilai rata-rata pemeriksaan kualitas layanan pelanggan (jumlah total skor/percakapan)</div>",
        },
        tips: {
          total:
            "Jumlah total pass/dialog yang masuk ke tugas pemeriksaan kualitas",
          agentTotal:
            "Jumlah total layanan pelanggan inspeksi kualitas yang memasuki tugas inspeksi kualitas",
          avg: "Skor rata-rata untuk pemeriksaan kualitas",
          violation:
            "Semua dialog pemeriksaan kualitas mencapai jumlah pelanggaran pemeriksaan kualitas",
          compliance:
            "Berbicara tentang jumlah kepatuhan terhadap item pemeriksaan kualitas selama proses pemeriksaan kualitas",
          check: "Jumlah total sampel manual",
          appeal:
            "Jumlah total pengaduan yang diajukan dalam jumlah total percakapan",
          review:
            "Jumlah total yang diajukan untuk ditinjau dalam jumlah total dialog",
        },
        subTips: {
          violation:
            "Tingkat pelanggaran: Jumlah pelanggaran/Jumlah pemeriksaan kualitas * 100%",
          compliance:
            "Tingkat Kepatuhan: Jumlah Kepatuhan/Jumlah Pemeriksaan Kualitas * 100%",
          check:
            "Cakupan inspeksi pengambilan sampel: total inspeksi pengambilan sampel manual/total inspeksi kualitas * 100%",
          appeal:
            "Tingkat banding: jumlah total banding/total pemeriksaan kualitas * 100%",
          review:
            "Tingkat ulasan: total ulasan/total pemeriksaan kualitas * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Pengaturan sistem",
          systemName: "Nama sistem",
          speakerRecognize: "Identifikasi pembicara",
          massage: "Pemberitahuan pesan",
          evaluate: "Evaluasi kognitif buatan",
          evaluateTip: "Setelah dibuka, pilih di detail pemeriksaan kualitas",
          speakerRecognizeTip:
            "Akses rekaman panggilan pihak ketiga melalui API perlu mengidentifikasi apakah saluran kiri adalah layanan pelanggan atau pelanggan",
          speakerRecognizeTip2:
            "Rekaman satu saluran menentukan peran layanan pelanggan melalui pengenalan teks, konfigurasi peran",
          enabledImSendStatusFilter: "Filter pesan kualitas teks",
          enabledImSendStatusFilterTips:
            "Isi tes dari operator pemeriksaan kualitas tidak termasuk pesan dengan status [ditarik], [gagal mengirim], [pesan offline belum dibaca]",
          lang: "Pengaturan bahasa sistem",
          zh: "Bahasa Cina Sederhana",
          en: "English",
          tw: "Cina Tradisional",
        },
        template: {
          largeModelSettings: "Pengaturan Model Besar",
          indonesian: "Bahasa Indonesia",
          vietnamese: "Bahasa Vietnam",
          portuguese: "Bahasa Portugis",
          malay: "Bahasa Melayu",
          french: "Bahasa Prancis",
          spanish: "Bahasa Spanyol",
          japanese: "Bahasa Jepang",
          thai: "Bahasa Thailand",
          blockSystemSendingMessages: "Sistem perisai untuk mengirim pesan",
          textDataDesensitization: "Data teks desensitisasi",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Ketika sakelar dihidupkan, daftar drop-down bidang desensitisasi (mendukung pilihan ganda) ditampilkan, bidang desensitisasi: nama orang, nomor ponsel, alamat",
          desensitizingField: "Bidang desensitisasi",
          name: "Nama manusia",
          cellphoneNumber: "Nomor ponsel",
          address: "Alamat",
          dataCleaning: "Pembersihan data",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Setelah pengaturan, dialog QAQitong dan data sesi WeChat perusahaan akan dibersihkan secara teratur dan tidak dapat dipulihkan. Harap berhati-hati",
          newCleaningRules: "Ditambahkan aturan pembersihan",
          qualityInspectionRuleApproval:
            "Persetujuan aturan pemeriksaan kualitas",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Setelah dibuka, perubahan aturan inspeksi kualitas harus disetujui sebelum dapat berlaku",
          maskOfflineMessageUnread: "Memblokir pesan offline yang belum dibaca",
          privacyNumberProtection: "Nomor Privasi dilindungi",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Setelah membuka, desensitisasi bidang "Telepon Pelanggan" dalam panggilan ditampilkan sebagai 1881 *******',
          shieldingWithdrawn: "Perisai telah ditarik",
          maskSendingFailed: "Pengiriman perisai gagal",
        },
        component: {
          conversation: "Panggilan telepon",
          dialogue: "Percakapan",
          weCom: "Perusahaan WeChat",
          day: "Hari",
          week: "Minggu",
          month: "Bulan",
          year: "Tahun",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Rentang pembersihan",
            dataCleaningTimeSetting: "Pengaturan waktu pembersihan data",
            afterCleanUpTheData: "Setelah itu, bersihkan data",
            dataAfterComplaintCompletion: "Data setelah pengaduan selesai",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Eksekusi Sukses",
          saveSuccessful: "Simpan Sukses",
          speechRecognition: "Pengenalan suara",
          reExecuteErrorCorrection: "Jalankan kembali koreksi kesalahan",
          confirmExecution: "Konfirmasi eksekusi",
          voiceDetection: "Deteksi suara",
          noiseDetectionPowerLevel: "Deteksi kebisingan powerLevel ≤",
          pleaseEnterTheThreshold: "Silakan masukkan ambang batas",
          volumeDetectionPowerLevel: "Deteksi volume powerLevel ≥",
          reset: "Atur ulang",
          preserve: "Simpan",
        },
        coachTemplate: {
          french: "Bahasa Prancis",
          spanish: "Bahasa Spanyol",
          japanese: "Bahasa Jepang",
          thai: "Bahasa Thailand",
          loginPopupWindow: "Jendela masuk",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Setelah dibuka, prompt pop-up akan dilakukan saat memasuki sistem sparring cerdas",
          title: "Judul",
          text: "Teks",
          checkBoxContent: "Centang isi kotak",
          informedConsentForm: "Formulir persetujuan informasi",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Untuk membantu Anda meningkatkan keterampilan komunikasi dan berbicara serta penerapan pengetahuan profesional, memungkinkan Anda untuk berlatih kapan saja, di mana saja dan menerima umpan balik langsung, kami akan mengumpulkan informasi berikut Anda melalui platform ini:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Saya telah membaca dan sepenuhnya memahami formulir persetujuan ini, dan dengan sungguh-sungguh menyatakan bahwa saya setuju untuk memproses informasi pribadi saya yang sensitif sesuai dengan tujuan dan metode yang dijelaskan dalam formulir persetujuan ini.",
        },
      },
      systemIntegration: {
        title: "Pengaturan sistem",
        ccIntegrationTips:
          "Cerdas pemeriksaan kualitas docking Udesk call center dokumentasi",
        unauthorized: "Tidak berwenang",
        authorized: "Resmi",
        udeskDomain: "Nama domain sistem layanan pelanggan",
        udeskToken: "Kunci sistem layanan pelanggan",
        udeskAdminEmail: "Akun administrator sistem layanan pelanggan",
        password: "Kata sandi akun administrator",
        applyAuth: "Otorisasi",
        cancelAuth: "Batalkan otorisasi",
        reApplyAuth: "Otorisasi ulang",
        keyTips:
          "Manajemen latar belakang sistem layanan pelanggan-otentikasi API Token dalam satu login",
      },
      customerField: {
        title: "Bidang Pelanggan",
        table: {
          propName: "Nama Bidang",
          propDesc: "Deskripsi bidang",
          valueType: "Tipe Bidang",
          propType: "Cara membuat",
          createTime: "Waktu pembuatan",
          canNotEdit: "Bidang sistem tidak mendukung pengeditan",
        },
        edit: "Mengedit bidang",
        new: "Bidang baru",
      },
      wechatDocking: {
        title: "Perusahaan mikro docking",
        formLabels: {
          companyId: "ID perusahaan",
          communicationKey: "Kunci komunikasi",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL panggilan balik acara",
          applicationName: "Nama aplikasi",
          applicationId: "ID Aplikasi Web",
          applicationSecret: "Aplikasi secret",
        },
        formTitle: {
          initialForm: "Pengaturan awal",
          synchronizeForm: "Sinkronisasi Kontak",
          settingForm: "Pengaturan aplikasi",
        },
        qrCodeUrlTip:
          "Minta administrator untuk memindai kode dua dimensi di bawah ini untuk mengikat aplikasi pengembangan",
      },
      taobao: {
        formLabels: {
          name: "Nama toko",
          account: "Akun",
          password: "Kata sandi",
          confirm: "Konfirmasi kata sandi",
          authorization: "Otorisasi",
          switchOn: "Buka",
          switchOff: "Tutup",
        },
        tableLabels: {
          name: "Nama toko",
          account: "Akun",
          authorization: "Otorisasi",
          updateUser: "Update terbaru orang",
          updateTime: "Terakhir diperbarui",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Nama domain tingkat kedua Weifeng",
          secretKeyTip:
            "Kunci penyewa, diperoleh di docking sistem latar belakang sistem Weifeng",
        },
      },
      user: {
        index: {
          name: "Manajemen Karyawan",
          existingUsers: "Sudah punya karyawan",
          deleteSuccess: "Hapus Sukses",
          deleteFailed: "Gagal menghapus",
        },
        detail: {
          accountPrompt: "Silakan masukkan email Anda",
          resetPassword: "Atur ulang kata sandi",
          passwordPrompt: "Kata sandi (kombinasi 6-14 huruf dan angka)",
          originPasswordPrompt: "Masukkan kata sandi awal",
          resumePasswordPrompt: "Silakan masukkan kembali kata sandi",
          passwordFormWrong:
            "Masukkan kata sandi untuk kombinasi 6-14 huruf dan angka!",
          resumePasswordWrong: "Kata sandi tidak konsisten dua kali!",
          suppertImageType: "Dukungan jpg, gif, png, maksimum 500Kb",
          namePrompt: "Silakan masukkan nama",
          nickNamePrompt: "Silakan masukkan nama panggilan",
          telephonePrompt: "Masukkan nomor telepon",
          roleSelectedPrompt: "Silakan pilih peran",
          agentIdPrompt: "Silakan masukkan nomor pekerjaan",
          disabled: "Menonaktifkan",
          enabled: "Aktifkan",
          reloadBowerInfo:
            "Apakah informasi pengguna berubah, apakah saya perlu me-refresh browser?",
          saveUserError:
            "Pengguna gagal menyimpan informasi, silakan coba lagi nanti",
          resetPasswordSuccess: "Kata sandi berhasil diatur ulang",
          resetPasswordFailed: "Reset kata sandi gagal, coba lagi nanti",
        },
      },
      userGroups: {
        index: {
          name: "Manajemen Grup Karyawan",
          existingUserGroups: "Sudah ada kelompok karyawan",
        },
        detail: {},
      },
      role: {
        index: {
          name: "Manajemen peran",
          existingRoles: "Peran yang sudah ada",
        },
        edit: {
          editRole: "Mengedit peran",
          createRole: "Membuat karakter",
          basicInfo: "Informasi dasar",
          taskPermissions: "Izin tugas",
          existingTasks: "Ada misi",
        },
      },
      anomalousData: {
        title: "Data anomali",
        list: {
          selectHolder: "Silakan pilih sumber data",
          businessTime: "Waktu Bisnis",
          businessId: "Bisnis Id",
          export: "Ekspor",
        },
      },
      templateComponent: {
        list: {
          title: "Pasar Template",
          name: "Nama Template",
          searchHolder: "Masukkan konten yang ingin Anda query",
          update: "Pembaruan",
          sort: "Metode penyortiran",
          moduleType: "Jenis Template",
          moduleSourceType: "Jenis yang berlaku",
          fetchNum: "Berapa kali Anda mendapatkan",
          newest: "Update terbaru",
          usedMost: "Dapatkan yang terbanyak",
          call: "Panggilan telepon",
          dialogue: "Percakapan",
          waiting: "Untuk ditinjau",
          cancel: "Membatalkan rilis",
          success: "Diaudit, dirilis",
          failed: "Penolakan audit",
          no: "Tidak dapat ditingkatkan",
          ok: "Dapat ditingkatkan",
          all: "Semua",
          delete: "Hapus Sukses",
          downOk: "Berhasil dihapus",
          withdraw: "Penarikan berhasil",
          updateOk: "Upgrade berhasil",
          get: "Dapatkan",
          width: "Penarikan",
          getOk: "Dapatkan Sukses",
          orgName: "Organisasi yang berafiliasi",
          nowVersion: "Versi saat ini",
          lastedVersion: "Versi terbaru",
          appStatus: "Status aplikasi",
          actions: "Operasi",
          down: "Rak Bawah",
          updateTop: "Upgrade",
          check: "Lihat",
          deleteWhen: "Konfirmasi penghapusan?",
          yes: "Ya",
          noo: "Tidak.",
          deleteOk: "Hapus",
          permissible: "Diizinkan",
          noPermissible: "Tidak diperbolehkan",
          openUp: "Terbuka",
          openDown: "Tidak terbuka",
          task: "Tugas pemeriksaan kualitas",
          point: "Titik pemeriksaan kualitas",
          tag: "Label semantik",
          muti: "Multi-elemen",
          inter: "Tanya dan jawab",
          info: "Entitas informasi",
          concept: "Kata konsep",
          detail: "Rincian modul",
          infomation: "Informasi dasar",
          detailConfig: "Detail Konfigurasi",
          twoEdit: "Pengeditan sekunder",
          mustUpdate: "Upgrade sekarang",
          descition: "Deskripsi Template",
          descitionSize: "Deskripsi template tidak dapat melebihi 250 kata",
          stencilsDetail: "Rincian Template",
          updatedRecords: "Memperbarui catatan",
          version: "Nomor Versi",
          updateDescription: "Deskripsi Diperbarui",
          myModule: "Template saya",
          addModule: "Modul baru",
          publishVersion: "Rilis Versi",
          earliestVersion: "Versi paling awal",
          edit: "Mengedit",
          add: "Baru",
          configNo: "Rincian konfigurasi modul tidak bisa kosong",
          updateNo: "Deskripsi pembaruan tidak bisa kosong",
          editSuccess: "Berhasil dimodifikasi",
          saveSuccess: "Simpan Sukses",
          newer: "Pembaruan",
          sumbit: "Pengajuan",
          cancelName: "Pembatalan",
          nameRequired: "Nama modul adalah bidang yang diperlukan",
          nameSize: "Nama modul tidak boleh melebihi 16 kata",
          publishStatus: "Status rilis",
          submitUpdate: "Kirim pembaruan",
          updateVersion: "Versi yang diperbarui",
          updateExplain: "Petunjuk pembaruan",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon e-commerce",
        youtubeVideo: "Video Youtube",
        amazon: {
          component: {
            queuing: "Dalam antrean",
            notStarted: "Belum dimulai",
            inProgress: "Dalam proses",
            success: "Sukses",
            failed: "Gagal",
            successfullyCreated: "Menciptakan Sukses",
            editedSuccessfully: "Sukses Mengedit",
            operationSucceeded: "Operasi yang sukses",
            deletedSuccessfully: "Hapus Sukses",
            link: "Tautan",
            grabState: "Status perayapan",
            updateTime: "Perbarui waktu",
            grab: "Ambil",
            edit: "Mengedit",
          },
          template: {
            amazonEcommerce: "Amazon e-commerce",
            thisProduct: "Produk ini",
            competition: "Produk kompetitif",
            new: "Baru dibangun",
            edit: "Mengedit",
            link: "Tautan",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Video Youtube",
            thisProduct: "Produk ini",
            competition: "Produk kompetitif",
            new: "Baru dibangun",
            edit: "Mengedit",
            link: "Tautan",
          },
          component: {
            queuing: "Dalam antrean",
            notStarted: "Belum dimulai",
            inProgress: "Dalam proses",
            success: "Sukses",
            failed: "Gagal",
            successfullyCreated: "Menciptakan Sukses",
            editedSuccessfully: "Sukses Mengedit",
            operationSucceeded: "Operasi yang sukses",
            deletedSuccessfully: "Hapus Sukses",
            youTubeVideoLink: "Tautan Video YouTube",
            grabState: "Status perayapan",
            updateTime: "Perbarui waktu",
            grab: "Ambil",
            edit: "Mengedit",
          },
        },
      },
    },
    demo: {
      title: "Pemeriksaan kualitas yang cerdas",
      template: "Pilih templat pemeriksaan kualitas",
      radio: "Memilih bentuk rekaman",
      uploadAudio: "Unggah rekaman",
      uploadAudioTip:
        "Upload rekaman hanya mendukung wav dan mp3 format, ukuran tidak lebih dari 1G",
      uploadText: "Mengunggah teks",
      uploadTextTip:
        "Meng-upload teks hanya mendukung srt dan txt format, ukuran tidak lebih dari 10M, setelah meng-upload teks tidak akan lagi menggunakan ASR untuk parsing file rekaman",
      uploadSuccess: "File rekaman berhasil diunggah",
      uploadFailed: "Gagal meng-upload file rekaman",
      startQualityInspection: "Mulai pemeriksaan kualitas",
      qualityInspectionStarted: "Sedang dalam pemeriksaan kualitas",
      downloadText: "Unduhan teks",
      checking: "Kemajuan pemeriksaan kualitas",
      checkingTip: "Diperkirakan beberapa menit, harap bersabar",
      noCheckContent: "Tidak ada hasil pemeriksaan kualitas",
      noTextDownload: "Tidak ada teks inspeksi kualitas untuk diunduh!",
      pleaseCheckParams:
        "Periksa untuk memilih templat, jenis suara, dan mengunggah file rekaman",
      inspectionFailed: "Pemeriksaan kualitas gagal, silakan coba lagi nanti!",
    },
    wechatQa: {
      baseInfo: { title: "Informasi dasar" },
      conversationSetting: {
        title: "Pengaturan sesi",
        segmentationStrategy: "Strategi Potong",
        rule: "Strategi",
        time: "Membagi waktu",
        daily: "Setiap hari",
        passScore: "Sesi poin kualifikasi",
        ruleLogic: "Logika eksekusi",
      },
      message: {
        title: "Acara push",
        table: {
          index: "Nomor seri",
          name: "Nama",
          type: "Metode push",
          desc: "Deskripsi",
          status: "Status",
        },
        subTitle: "Daftar acara",
      },
      checkPoint: {
        title: "Model analisis",
        groups: "Pengelompokan model",
        newClassification: "Pengelompokan baru",
        editClassification: "Mengedit pengelompokan",
        benchmarkScore: "Poin dasar",
        pointCount: "Model analisis umum di bawah kategori saat ini",
        openPointCount: "Jumlah yang diaktifkan adalah",
        closedPointCount: "Jumlah penonaktifan adalah",
        scoreLowerLimit: "Batas bawah skor minimum",
        scoreUpperLimit: "Batas skor tertinggi",
        classificationName: "Nama grup",
        classificationNamePlaceHolder: "Masukkan nama grup",
        classificationRemark: "Deskripsi grup",
        classificationRemarkPlaceHolder: "Silakan masukkan deskripsi grup",
      },
      chartRecord: {
        title: "Sesi WeChat Perusahaan",
        table: {
          id: "ID sesi",
          type: "Jenis Sesi",
          startTime: "Waktu mulai sesi",
          endTime: "Sesi terakhir berakhir",
          groupId: "ID grup",
          client: "Pelanggan",
          customer: "Layanan Pelanggan",
          averageScore: "Skor pemeriksaan kualitas rata-rata",
        },
        detail: {
          title: "Detail Sesi WeChat Perusahaan",
          inspectionInfo: "Informasi analisis",
          chartInfo: "Informasi sesi",
          tasks: "Menganalisis tugas",
          avgScore: "Analisis skor rata-rata",
          qualified: "Memenuhi syarat",
          unqualified: "Tidak memenuhi syarat",
          taskTitle: "Templat Analisis Berlaku",
          info: "Analisis informasi secara real time",
          today: "Berita Hari Ini",
          history: "Pesan sejarah",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname:
                  "Silakan masukkan catatan/nama panggilan",
                pleaseEnterTheGroupChatName:
                  "Silakan masukkan nama obrolan grup",
              },
            },
          },
          template: { whole: "Semua" },
        },
      },
      realTimeAnalysis: {
        title: "Analisis real-time",
        table: {
          name: "Nama Adegan",
          count: "Menganalisis jumlah model",
          baseScore: "Poin dasar",
          passScore: "Poin kualifikasi",
          maxScore: "Batas skor tertinggi",
          minScore: "Batas bawah skor minimum",
          status: "Status",
          actions: "Operasi",
        },
        detail: {
          title: "Analisis real-time skenario",
          form: {
            name: "Nama Adegan",
            prevCondition: "Kondisi Pemicu Adegan",
            prevConditionToolTip:
              "Jika kondisinya kosong, skenario ini dianalisis secara default",
            prevConditionError:
              "Kondisi pemicu adegan tidak dapat bertepatan dengan model analisis",
            scoreInputPlaceholder: "Masukkan Skor",
            baseScore: "Poin dasar",
            status: "Status",
            passScore: "Poin kualifikasi",
            maxScore: "Batas skor tertinggi",
            minScore: "Batas bawah",
            analysisModel: "Model analisis",
            message: "Memicu pemberitahuan",
            pushType: "Metode push",
            createAnalysisModel: "Menambahkan model",
            scoreUpperLimitError:
              "Batas bawah skor minimum tidak boleh lebih besar dari batas atas skor tertinggi",
            table: { name: "Nama Model", rule: "Aturan", score: "Skor" },
            pushConfigSwitchTip1: "Ketika adegan itu muncul",
            pushConfigSwitchTip2: "Dorong pemberitahuan",
          },
        },
      },
      smartCustomerTag: {
        title: "Pelanggan Cerdas Tags",
        table: {
          name: "Nama aturan",
          tags: "Label pelanggan mikro",
          status: "Aktifkan Status",
          createUser: "Pencipta orang",
          createTime: "Waktu pembuatan",
        },
        detail: {
          name: "Nama aturan",
          condition: "Menetapkan aturan",
          logic: "Logika",
          status: "Aktifkan Status",
          tags: "Memilih label",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Pelanggan menandai jumlah" },
        },
        messageFiltering: {
          template: {
            edit: "Mengedit",
            messageContent: "Konten pesan",
            messageTypeID: "ID tipe pesan",
            messageTypeDescription: "Deskripsi tipe pesan",
            messageFiltering: "Pemfilteran pesan",
            suitableForFilteringDataWithoutQualityInspection:
              "Cocok untuk memfilter data yang tidak memerlukan pemeriksaan kualitas",
            create: "Membuat",
            messageDescription: "Deskripsi pesan",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Waktu bicara",
          sessionType: "Jenis Sesi",
          whole: "Semua",
          staff: "Karyawan",
          customer: "Pelanggan",
          callKeywords: "Kata kunci panggilan",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails: "Detail Panggilan Suara Weiwei",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Sesi Arsip",
        voiceCall: "Panggilan suara",
        noMoreVoiceCalls: "Tidak ada lagi panggilan suara",
        enterpriseWechatVoiceCall: "Panggilan suara perusahaan WeChat",
        enterpriseWechatConversationDetails: "Detail Sesi WeChat Perusahaan",
        previous: "Satu",
        next: "Berikutnya",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Tugas analisis percakapan mikro perusahaan",
          enterpriseAndMicroVoiceAnalysisTask:
            "Tugas analisis suara mikro perusahaan",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Analisis obrolan grup WeChat perusahaan",
      },
      wechatQaSettingEntry: { messageFiltering: "Pemfilteran pesan" },
    },
    businessAnalysis: {
      keyword: "Nama laporan",
      createUser: "Pencipta orang",
      createTime: "Waktu pembuatan",
      updateTime: "Perbarui waktu",
    },
    semanticIntelligence: {
      applicationMessageType: "Jenis pesan aplikasi",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Silakan menyempurnakan konfigurasi",
              generatePromptLanguage: "Menghasilkan petunjuk",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Apakah sesuai",
              gradedEvaluation: "Evaluasi peringkat",
            },
          },
          training: {
            index: {
              pleaseEnter: "Silakan masukkan",
              pleaseGeneratePrompt: "Membuat petunjuk",
              pleaseSelectADialogue: "Silakan pilih percakapan",
              training: "Pelatihan",
              dataType: "Tipe data:",
              voiceCall: "Panggilan suara",
              textDialogue: "Percakapan teks",
              generate: "Menghasilkan",
              qualityInspectionResults: "Hasil pemeriksaan kualitas:",
              hit: "Memukul kata-kata:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Nama Model",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Silakan masukkan nama model, misalnya, layanan pelanggan jika tidak ada tindakan",
            modelDescription: "Deskripsi model",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Ini dapat menjelaskan secara rinci apa yang perlu Anda deteksi, sehingga model dapat memahami, seperti: apakah ada tidak adanya layanan pelanggan, yaitu, untuk pertanyaan pelanggan, layanan pelanggan memiliki balasan, tetapi pertanyaan pelanggan tidak diproses atau konten balasan tidak terkait dengan balasan pelanggan.",
            validity: "Efektivitas",
            effective: "Efektif",
            invalid: "Tidak valid",
            type: "Jenis",
            hintLanguage: "Pesan",
            editIntelligentModel: "Mengedit model cerdas",
            createANewIntelligentModel: "Model cerdas baru",
          },
        },
        index: {
          modelName: "Nama Model",
          modelDescription: "Deskripsi model",
          type: "Jenis",
          whetherItMeetsTheRequirements: "Apakah sesuai",
          gradedEvaluation: "Evaluasi peringkat",
          validity: "Efektivitas",
          intelligentModel: "Model cerdas",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Berlaku untuk pemeriksaan kualitas subyektif (seperti apakah layanan pemeriksaan kualitas antusias, apakah layanan sabar, dll.)",
        },
      },
      clusterAnalysis: "Analisis pengelompokan",
      speechMining: "Penggalian Kata-kata",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Mendukung impor massal entitas informasi melalui antarmuka API yang tidak mendukung pengeditan setelah nama API disimpan",
        },
        component: { nothing: "Tidak ada" },
      },
      knowledgeBase: {
        index: {
          classification: "Klasifikasi",
          import: "Impor",
          importKnowledgeBase: "Impor basis pengetahuan",
          moveKnowledgePointsInBatch: "Poin pengetahuan seluler batch",
          moveTo: "Pindah ke:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Silakan pilih poin pengetahuan yang membutuhkan gerakan massal",
        },
        detail: {
          index: {
            intrasentence: "Dalam kalimat",
            afterProblem: "Setelah masalah",
          },
        },
      },
      semanticTags: { component: { nothing: "Tidak ada" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Kata-kata sensitif",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Kata-kata sensitif: kata-kata sensitif dapat dikonfigurasi untuk kata-kata fokus bisnis, digunakan untuk menemukan kata-kata sensitif dalam opini publik",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Buat kata-kata sensitif baru",
            editSensitiveWords: "Mengedit kata-kata sensitif",
            sensitiveWords: "Kata-kata sensitif",
          },
          template: {
            sensitiveWords: "Kata-kata sensitif",
            sensitiveWordsCannotBeEmpty: "Kata-kata sensitif tidak bisa kosong",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Silakan masukkan kata-kata sensitif, beberapa kata sensitif di tengah dengan ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Silakan pilih skenario aplikasi",
            keyWord: "Kata kunci",
            pleaseEnterKeywords: "Silakan masukkan kata kunci",
            keywordLengthWithinCharacters:
              "Kata kunci panjang 40 karakter dalam",
            dataSources: "Sumber data",
            pleaseSelectADataSource: "Silakan pilih sumber data",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Saat ini cluster sudah selesai, mengedit ulang akan menghasilkan data cluster baru, tidak akan menimpa data cluster yang sudah selesai, mohon konfirmasi",
            savingSucceeded: "Simpan Sukses",
            clusteringStart: "Mulai clusteron",
            clustering: "Kelompok",
            edit: "Mengedit",
            newlyBuild: "Baru dibangun",
            clusterName: "Nama pengelompokan",
            pleaseEnterAName: "Silakan masukkan nama",
            withinCharactersOfTheName: "Nama panjang 40 karakter dalam",
            speaker: "Pembicara",
            pleaseSelectASpeaker: "Silakan pilih pembicara",
            sessionDate: "Tanggal sesi",
            pleaseEnterTheSessionDate: "Masukkan tanggal sesi",
            selectUpToMonthOfHistoricalSessionData:
              "Pilih data sesi historis hingga 1 bulan",
            automaticallyClusterDailyNewData:
              "Otomatis pengelompokan data harian baru",
            conversationChannel: "Saluran percakapan",
            pleaseSelectAConversationChannel: "Silakan pilih saluran sesi",
            applicationScenario: "Skenario Aplikasi",
            clusteringRules: "Aturan pengelompokan",
            incompleteClusteringRuleSettings:
              "Aturan pengelompokan tidak lengkap",
            startClustering: "Mulai pengelompokan",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Silakan pilih tugas pemeriksaan kualitas",
              beEqualTo: "Sama dengan",
            },
          },
        },
        detail: {
          index: {
            theme: "Tema",
            numberOfCorpora: "Jumlah bahan",
            corpusCoverage: "Penutup bahan",
            clusterDetails: "Rincian pengelompokan",
          },
          ranking: {
            hotWords: "Kata-kata panas",
            numberOfCorpora: "Jumlah bahan",
            speaker: "Pembicara",
            sit: "Duduk",
            passenger: "Tamu",
            hotSentence: "Kalimat panas",
            corpusDetails: "Rincian bahan",
          },
        },
        index: {
          delete: "Hapus",
          deletionSucceeded: "Hapus Sukses",
          clusterName: "Nama pengelompokan",
          speaker: "Pembicara",
          creationTime: "Waktu pembuatan",
          state: "Status",
          operation: "Operasi",
          see: "Lihat",
          edit: "Mengedit",
          clusterAnalysis: "Analisis pengelompokan",
          newCluster: "Clusteron baru",
          clusteringStatus: "Status pengelompokan",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Masukkan kata kunci untuk mencari keberatan",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Perpustakaan bicara",
                recommendedScripts: "Kata-kata yang disarankan",
                common: "Total",
                strip: "Strip",
                scriptFavorites: "Favorit percakapan",
              },
              conditionPostfix: {
                detectRoles: "Mendeteksi peran",
                detectionRange: "Rentang deteksi",
                sentence: "Kalimat",
              },
              customerFilter: {
                scriptFiltering: "Filter bicara",
                cancel: "Pembatalan",
              },
              index: {
                viewDetails: "Lihat detail",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Apakah Anda mengkonfirmasi bahwa Anda ingin menyerah? Tidak akan ada pemulihan setelah ditinggalkan",
                abandonment: "Dibuang",
                deletionSucceeded: "Hapus Sukses",
                passenger: "Tamu",
                sit: "Duduk",
                adopted: "Diadopsi",
                determine: "Penentuan",
                cancel: "Pembatalan",
                adopt: "Mengadopsi",
              },
              pageRightFrom: {
                savingSucceeded: "Simpan Sukses",
                pleaseEnterTheScriptContent: "Masukkan konten bicara",
                pleaseSelectAFavoriteGroup:
                  "Silakan pilih pengelompokan favorit",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Perpustakaan Penanganan Keberatan",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript:
                    "Silakan pilih untuk mengadopsi kata-kata",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Keberatan Penanganan Panggilan Favorit",
            },
            pageRightTable: {
              likeCancel: "Suka pembatalan",
              likeSuccessfully: "Suka Sukses",
              adoptScripts: "Mengadopsi kata-kata",
              operation: "Operasi",
              viewConversationDetails: "Lihat rincian percakapan",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Perpustakaan Penjualan Produk",
              },
              pageRightTable: { keyPointsOfScript: "Poin bicara" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Keberatan Penanganan Panggilan Favorit",
              newGroup: "Pengelompokan baru",
              editGroup: "Mengedit pengelompokan",
              addGroup: "Menambahkan pengelompokan",
              savingSucceeded: "Simpan Sukses",
              groupName: "Nama grup",
              pleaseEnterTheGroupName: "Masukkan nama grup",
              groupNameWithinCharacters: "Nama grup dalam 20 karakter panjang",
              deletionSucceeded: "Hapus Sukses",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings:
                    "Pengaturan yang disarankan untuk berbicara",
                },
                rightForm: {
                  savingSucceeded: "Simpan Sukses",
                  intelligentRecommendation: "Rekomendasi cerdas",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Secara otomatis menampilkan kata-kata yang sangat baik yang telah diadopsi di sidebar percakapan dengan keberatan yang sama. Karyawan dapat langsung melihat kata-kata yang direkomendasikan di halaman detail ulasan percakapan untuk belajar.",
                  objectionsIncludedByDefault:
                    "Keberatan yang disertakan secara default",
                  addRecommendationTriggerSemantics:
                    "Meningkatkan semantik pemicu yang disarankan",
                  remindEmployeesToView: "Ingatkan karyawan untuk melihat",
                  shareWithMoreEmployees: "Bagikan ke lebih banyak karyawan",
                  allHands: "Semua karyawan",
                },
                smartToolbar: { selectSemanticTag: "Memilih label semantik" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Apakah Anda mengekspor semua catatan data dalam pengelompokan saat ini?",
                newScript: "Kata-kata baru",
                editingScript: "Mengedit kata-kata",
                savingSucceeded: "Simpan Sukses",
                objection: "Keberatan",
                pleaseEnterAScriptObjection:
                  "Silakan masukkan keberatan bicara",
                speechTechnique: "Kata-kata",
                pleaseEnterTheScriptContent: "Masukkan konten bicara",
                grouping: "Pengelompokan",
                pleaseSelectAFavoriteGroup:
                  "Silakan pilih pengelompokan favorit",
                adoptScripts: "Mengadopsi kata-kata",
                passenger: "Tamu",
                sit: "Duduk",
                numberOfViews: "Jumlah tampilan",
                useful: "Berguna",
                operation: "Operasi",
                edit: "Mengedit",
                delete: "Hapus",
                deletionSucceeded: "Hapus Sukses",
                scriptRecommendationSettings:
                  "Pengaturan yang disarankan untuk berbicara",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Perpustakaan bicara",
          scriptMining: "Penggalian Kata-kata",
          objectionHandlingScripts: "Penanganan keberatan",
          productSellingPointScripts: "Nilai jual produk",
        },
        sellingPoint: {
          index: { newCluster: "Clusteron baru" },
          components: {
            callDetailModal: {
              sessionDetails: "Detail Sesi",
              cancel: "Pembatalan",
              determine: "Penentuan",
              choice: "Pilih",
            },
            pageCenterTable: { scriptOverview: "Ikhtisar berbicara" },
            pageLeftTable: {
              enterSearchKeywords: "Masukkan kata kunci pencarian",
              keyWord: "Kata kunci",
            },
            pageRightTable: {
              index: {
                viewDetails: "Lihat detail",
                scriptLibrary: "Perpustakaan bicara",
                scriptRefinement: "Pemurnian Kata-kata",
                adoptScript: "Mengadopsi kata-kata",
              },
              tagsEdit: { keyPointsOfScript: "Poin bicara" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Perpustakaan Penjualan Produk",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Nilai jual yang disertakan secara default",
                  },
                },
                index: {
                  keyWord: "Kata kunci",
                  pleaseSelectAKeyword: "Silakan pilih kata kunci",
                  keyPointsOfScript: "Poin bicara",
                  pleaseInputTheKeyPointsOfTheScript: "Masukkan poin bicara",
                  viewDetails: "Lihat detail",
                  pleaseChooseToAdoptScript:
                    "Silakan pilih untuk mengadopsi kata-kata",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Pencocokan logika",
            nLPMatching: "Pencocokan NLP",
            strictlyMatchByWord: "Pertandingan ketat dengan kata",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Nama Jenis Pesan",
          messageTypeID: "ID tipe pesan",
          messageTypeDescription: "Deskripsi tipe pesan",
          applicationMessageType: "Jenis pesan aplikasi",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Sangat cocok untuk mengakses data saluran yang berbeda untuk pemeriksaan kualitas, dan perlu diklasifikasikan sesuai dengan identifikasi saluran",
        },
        detail: {
          index: {
            messageTypeName: "Nama Jenis Pesan",
            pleaseEnterAMessageTypeName: "Masukkan nama tipe pesan",
            messageTypeDescription: "Deskripsi tipe pesan",
            describeYourMessageTypeInDetail:
              "Jelaskan secara rinci jenis pesan Anda",
            editMessageType: "Mengedit jenis pesan",
            newMessageType: "Tipe Pesan Baru",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Silakan pilih anggota tim",
        existingFilter: "Filter yang sudah ada",
        pleaseSelect: "Silakan pilih",
        filterCondition: "Kondisi filter",
        areYouSureToDeleteTheCurrentFilter:
          "Apakah Anda mengkonfirmasi penghapusan filter saat ini?",
        sessionDate: "Tanggal sesi",
        staff: "Karyawan",
        customer: "Pelanggan",
        pleaseEnterCustomerName: "Masukkan nama pelanggan",
        keyWord: "Kata kunci",
        whole: "Semua",
        pleaseEnterSessionKeywords: "Masukkan kata kunci percakapan",
        sessionDuration: "Durasi sesi",
        sessionLabel: "Adegan sesi",
        pleaseSelectASessionLabel: "Silakan pilih adegan sesi",
        commentTopic: "Komentar pada topik",
        pleaseSelectACommentTopic: "Silakan pilih topik komentar",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Apakah Anda mengkonfirmasi overlay filter saat ini?",
        overlayFilter: "Tutup filter",
        NewFilter: "Filter baru",
        newFilter: "Filter baru",
        filterName: "Nama Filter",
        pleaseEnter: "Silakan masukkan",
        allCall: "Semua panggilan",
        percentage: "Persentase",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'Mendukung pencarian beberapa kata kunci. Hubungan "atau" dipisahkan oleh koma (Cina dan Inggris), dan hubungan "dan" dipisahkan oleh tanda, seperti:',
          callRecordID: "ID perekam panggilan",
          pleaseFillInTheCallRecordID: "Silakan isi ID log panggilan",
          minute: "Menit",
          second: "Detik",
          whole: "Semua",
          callRecording: "Rekaman panggilan",
          enterpriseWechat: "Perusahaan WeChat",
          conversationChannel: "Saluran percakapan",
          pleaseSelectASessionChannel: "Silakan pilih saluran sesi",
          learningStatus: "Status belajar",
          pleaseSelectLearningStatus: "Silakan pilih status belajar",
          notLearned: "Tidak belajar",
          learned: "Telah belajar",
          Minutes: "Menit",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Kondisi filter kosong, filter baru tidak didukung!",
          filterAddedSuccessfully: "Filter menambahkan sukses!",
          failedToAddFilter: "Filter menambahkan gagal!",
          filterDeletedSuccessfully: "Filter dihapus berhasil!",
          filterDeletionFailed: "Filter untuk menghapus gagal!",
          filterOverwriteSucceeded: "Filter overlay berhasil!",
          filterOverwriteFailed: "Filter overlay gagal!",
          theme: "Tema",
          pleaseSelectATheme: "Silakan pilih tema",
          keyEvents: "Peristiwa kunci",
          pleaseSelectAKeyEvent: "Silakan pilih peristiwa penting",
        },
        filterItem: {
          startRetesting: "Mulai deteksi ulang",
          time: "Waktu",
          talkTime: "Durasi panggilan",
          keyEvents: "Peristiwa kunci",
          totalImplementationRateOfSupervisionItems:
            "Tingkat implementasi umum dari item pengawasan",
          sort: "Urutkan:",
          seeMore: "Lihat lebih",
        },
        filterStatistics: {
          conversation: "Panggilan telepon",
          weChat: "WeChat",
          quantity: "Jumlah",
        },
      },
      caseBase: {
        index: {
          copyTo: "Salin ke",
          pleaseSelectTheLocationToMove: "Silakan pilih lokasi untuk bergerak!",
          folderCopiedSuccessfully: "Folder berhasil disalin!",
          cancelCollectionSuccessfully: "Membatalkan koleksi berhasil!",
          collectionSucceeded: "Koleksi berhasil!",
          starCaseBase: "Basis data kasus bintang",
          move: "Bergerak",
          rename: "Mengganti nama",
          delete: "Hapus",
          folderMovedSuccessfully: "Folder berhasil bergerak!",
          fileMovedSuccessfully: "File berhasil bergerak!",
          fileMoveFailed: "Gagal memindahkan file!",
          folderAddedSuccessfully: "Menambahkan folder berhasil!",
          folderRenameSucceeded: "Folder berhasil diganti namanya!",
          fileRenameSucceeded: "File berhasil diganti namanya!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Setelah menghapus folder, Anda tidak dapat mengambilnya. Apakah Anda mengonfirmasi penghapusan?",
          deleteSucceeded: "Hapus Sukses!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Setelah menghapus file, Anda tidak dapat mengambilnya. Apakah Anda mengonfirmasi penghapusan?",
          share: "Total",
          folders: "Sebuah folder,",
          calls: "Panggilan telepon",
          newFolder: "Menambahkan folder",
          modifyFolder: "Memodifikasi folder",
          title: "Judul",
          pleaseEnter: "Silakan masukkan",
          selectMoveLocation: "Pilih lokasi ponsel",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Pengingat belajar",
          subscriptionDynamics: "Berlangganan Dinamis",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "Sesi langganan Anda (ID",
            thereAreDynamicUpdates: "Ada pembaruan dinamis",
            newCommentsReplies: "Ditambahkan komentar/balasan",
            strip: "Strip",
            newHelp: "Tambahkan bantuan",
            goAndWatch: "Pergi menonton",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Membalas komentar saya",
            replySucceeded: "Balas berhasil!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "Di komentar @ dan saya",
            replySucceeded: "Balas berhasil!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Berbagi untuk panggilan Anda",
            shareCasesForYou: "Bagikan kasus untuk Anda",
          },
        },
        study: {
          index: {
            learned: "Telah belajar",
            ignored: "Telah diabaikan",
            study: "Belajar",
            ignore: "Abaikan",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Impor berhasil",
          account: "Akun",
          password: "Kata sandi",
          cover: "Meliputi",
          skip: "Melompati",
          import: "Impor",
          importEmployees: "Impor karyawan",
          importPrompt: "Tips Impor",
          confirm: "Penentuan",
          theDataContainsDuplicateAccountsOrNames:
            "Data dalam akun atau nama duplikat",
          allCoverage: "Cakupan penuh",
          skipAll: "Melompati semua",
          headPortrait: "Avatar",
          fullName: "Nama",
          nickname: "Nama panggilan",
          excellentStaff: "Staf yang luar biasa",
          yes: "Ya",
          no: "Tidak.",
          department: "Departemen",
          accountNumberemail: "Akun (email)",
          telephone: "Telepon",
          jobNumber: "Nomor kerja",
          role: "Karakter",
          pleaseEnterANameOrPhoneSearch:
            "Masukkan email, nama, atau pencarian telepon",
          person: "Orang)",
          AddEmployee: "Menambahkan karyawan",
        },
        edit: {
          jobNo: "Nomor kerja",
          udeskCustomerServiceSystemIdentity:
            "Identitas sistem layanan pelanggan Udesk",
          enterpriseWechatIdentity: "Identitas perusahaan WeChat",
          inputID: "Input logo",
          newIdentityBinding: "Menambahkan pengikatan identitas",
          identityBinding: "Pengikatan identitas",
          avatarGreaterThan500kb: "Avatar lebih besar dari 500Kb",
          uploadSuccessful: "Berhasil diunggah",
          upload: "Unggah",
          supportJPGGIFAndPNGUpTo500kb:
            "Dukungan jpg, gif, png, maksimum 500Kb",
          yes: "Ya",
          no: "Tidak.",
          accountNumber: "Akun",
          pleaseEnterTheAccountNumber: "Silakan masukkan akun",
          initialPassword: "Kata sandi awal",
          pleaseInputAPassword: "Silahkan masukkan kata sandi",
          confirmPassword: "Konfirmasi kata sandi",
          thePasswordsEnteredTwiceAreDifferent:
            "Kata sandi yang dimasukkan dua kali berbeda!",
          headPortrait: "Avatar",
          fullName: "Nama",
          pleaseEnterYourName: "Silakan masukkan nama",
          nickname: "Nama panggilan",
          department: "Departemen",
          excellentStaff: "Staf yang luar biasa",
          sessionIdentity: "Identifikasi identitas sesi",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Tandai rekaman sesi milik karyawan itu",
          telephone: "Telepon",
          jobNumber: "Nomor kerja",
          role: "Karakter",
          pleaseSelectARole: "Silakan pilih peran",
          preservation: "Simpan",
          cancel: "Pembatalan",
          addedSuccessfully: "Tambahkan sukses!",
          modificationSucceeded: "Revisi berhasil!",
          newEmployees: "Tambahkan karyawan baru",
          editEmployee: "Mengedit karyawan",
        },
        detail: {
          viewDetails: "Lihat detail",
          accountNumber: "Akun",
          headPortrait: "Avatar",
          fullName: "Nama",
          nickname: "Nama panggilan",
          department: "Departemen",
          excellentStaff: "Staf yang luar biasa",
          yes: "Ya",
          no: "Tidak.",
          sessionIdentity: "Identifikasi identitas sesi",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Tandai rekaman sesi milik karyawan itu",
          telephone: "Telepon",
          jobNumber: "Nomor kerja",
          role: "Karakter",
        },
      },
      roleManage: {
        list: {
          menu: "Menu",
          functionalCategory: "Kategori fungsional",
          functionalSubclass: "Kategori kecil fungsional",
          deletedSuccessfully: "Hapus Sukses",
          NewRole: "Peran baru",
          role: "Karakter",
          dataPermissions: "Izin Data:",
          edit: "Mengedit",
          areYouSureYouWantToDeleteTheRole: "Menentukan peran yang dihapus",
          determine: "Penentuan",
          cancel: "Pembatalan",
          delete: "Hapus",
          applicationPermission: "Izin aplikasi",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Hanya melihat data sesi Anda sendiri",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Anda dapat melihat data sesi dari departemen ini dan departemen bawahan",
          menu: "Menu",
          functionalCategory: "Kategori fungsional",
          functionalSubclass: "Kategori kecil fungsional",
          successfullyAdded: "Tambahkan Sukses",
          modifiedSuccessfully: "Berhasil dimodifikasi",
          edit: "Mengedit",
          cancel: "Pembatalan",
          confirm: "Konfirmasi",
          roleName: "Nama Karakter",
          pleaseEnterTheRoleName: "Masukkan nama karakter",
          dataAuthority: "Izin Data",
          youCanViewCompanyWideSessionData:
            "Anda dapat melihat semua data sesi perusahaan",
          applicationPermission: "Izin aplikasi",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Proses sesi",
          generateProcess: "Proses pembuatan",
          addProcess: "Menambahkan proses",
          cancel: "Pembatalan",
          complete: "Selesai",
          edit: "Mengedit",
          theSettingWillBeSuccessfulLater:
            "Atur untuk melihat kesuksesan nanti!",
          previous: "Satu",
          next: "Berikutnya",
          debuggingMode: "Mode debugging",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Setelah pesan dibuka, jika pesan cocok dengan peristiwa penting, item pengawasan, aturan label pelanggan, atau aturan poin pengetahuan, nama aturan yang cocok dengan pesan, dan nama poin pengetahuan dari basis pengetahuan akan ditampilkan.",
          seeLater: "Lihat nanti",
          determine: "Penentuan",
          conversationalInsights: "Wawasan percakapan",
          customerInsight: "Wawasan pelanggan",
          knowledgePointRecommendation:
            "Poin pengetahuan yang direkomendasikan",
          comment: "Komentar",
          sessionQuality: "Kualitas sesi",
          setToListenLaterSuccessfully:
            "Pengaturan untuk mendengarkan sukses nanti!",
          listenLater: "Dengar nanti",
          seekCounselingSuccess: "Mencari keberhasilan pelatihan!",
          altogether: "Total",
          appearsAt: "Muncul di mana-mana'",
          commentsPublishedSuccessfully: "Komentar posting berhasil",
          callRecording: "Rekaman panggilan",
          addedSuccessfully: "Tambahkan Sukses",
          shareWithColleagues: "Bagikan ke rekan kerja",
          shareWithCustomers: "Bagikan ke pelanggan",
          resumptionDetails: "Rincian ulasan",
          addToCaseBase: "Tambahkan ke Perpustakaan Kasus",
          shareCases: "Berbagi Kasus",
          seekCounseling: "Mencari konseling",
          sessionRecord: "Rekaman sesi",
          businessRecord: "Catatan bisnis",
          keywordSearch: "Pencarian kata kunci",
          whole: "Semua",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Silakan masukkan komentar, masukkan @ untuk memberi tahu orang lain, masukkan # untuk menambahkan topik",
          subscriptionSession: "Berlangganan sesi",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Setelah berlangganan, Anda akan menerima pemberitahuan pemberitahuan untuk bantuan, komentar, dan balasan baru",
        },
      },
      coachTask: {
        index: {
          employeeName: "Nama karyawan",
          customerName: "Nama pelanggan",
          source: "Sumber",
          viewSessionRecords: "Melihat rekaman sesi",
          coachingStatus: "Status Konseling",
          helpTime: "Waktu untuk bantuan",
          counselingTime: "Waktu pelatihan",
          helpContent: "Konten bantuan",
          coachingOperation: "Operasi pembinaan",
          areYouSureYouWantToIgnoreIt: "Yakin diabaikan?",
          determine: "Penentuan",
          cancel: "Pembatalan",
          ignoreHelp: "Abaikan bantuan",
          coaching: "Melakukan pembinaan",
          viewCoaching: "Lihat tutorial",
          areYouSureToReturnToCounseling:
            "Apakah Anda yakin untuk melanjutkan konseling?",
          returnToPendingCoaching: "Kembalikan ke konseling",
          tutor: "Konseling orang",
          helpSeekingStatus: "Status bantuan",
          recentCoachingTime: "Waktu Konseling Terbaru",
          operation: "Operasi",
          ignoreSuccess: "Abaikan kesuksesan!",
          recoverySucceeded: "Pemulihan berhasil!",
          successfulCoaching: "Pelatihan berhasil!",
          myMission: "Tugas saya",
          selectCoachingStatus: "Pilih Status Konseling",
          whole: "Semua",
          iAskedForHelp: "Aku meminta bantuan",
          signOut: "Keluar",
          submit: "Pengajuan",
          helpPerson: "Bantu orang lain",
          customer: "Pelanggan",
          toBeCoached: "Untuk konseling",
          counselingSuggestions: "Saran Konseling",
          pleaseEnterCoachingSuggestions: "Silakan masukkan saran tutorial",
        },
      },
      coachTaskCenter: {
        title: "Pusat Misi",
        list: {
          taskName: "Nama tugas",
          lessonGroup: "Klasifikasi Kursus",
          lessonName: "Kursus milik",
          taskType: "Jenis tugas",
          taskStatus: "Status tugas",
          taskExecutionInfo: "Eksekusi",
          totalCount: "Jumlah orang yang harus dieksekusi",
          executeCount: "Jumlah yang Dieksekusi",
          executeRate: "Tingkat eksekusi",
          completeCount: "Jumlah Lengkap",
          completeRate: "Tingkat penyelesaian",
          commitCount: "Jumlah orang yang memenuhi standar",
          commitRate: "Tingkat kepatuhan",
          excellentRate: "Tingkat keuntungan",
          taskTime: "Waktu Misi",
          option: "Operasi",
          edit: "Mengedit",
          taskDetail: "Tugas Data",
          delete: "Hapus",
          new: "Rilis baru",
          taskTypeAll: "Semua",
          taskTypePractice: "Latihan",
          taskTypeTest: "Ujian",
          taskStatusNotStarted: "Belum dimulai",
          taskStatusRunning: "Dalam proses",
          taskStatusEnd: "Sudah berakhir",
          taskStatusInvalid: "Tidak berlaku",
          unlimitedTime: "Waktu Tak Terbatas",
          to: "Untuk",
          deleteConfirm:
            "Setelah dihapus, semua catatan pembelajaran di bawah tugas ini akan dihapus. Apakah Anda mengkonfirmasi penghapusan?",
          deleteSuccess: "Tugas dihapus berhasil",
        },
        edit: {
          addTask: "Tugas rilis baru",
          editTask: "Mengedit tugas penerbitan",
          publishSuccess: "Rilis sukses",
          publishFailed: "Rilis gagal",
          updateSuccess: "Pembaruan berhasil",
          updateFailed: "Pembaruan gagal",
          cannotBeNull: "Tidak bisa kosong",
          explain: "Deskripsi",
          taskDescExtra: "(Konten akan ditampilkan di sini di pintu masuk {0})",
          taskTargetObj: "Menerbitkan objek",
          isEnable: "Status efektif",
          dialogueMode: "Mode percakapan",
          voice: "Suara",
          text: "Teks",
          userPortrait: "Potret pengguna",
          difficultyLevel: "Kesulitan",
          limitDuration: "Batas Durasi",
          tipLabel: "Tips bicara",
          tipTooltip: "Apakah siswa diizinkan untuk melihat referensi",
          limitTimes: "Jumlah ujian",
          time: "Waktu",
          gradingStandart: "Kriteria penilaian",
          gradingStandartDescAffix:
            "(Skor total dari proses dialog saat ini adalah",
          gradingStandartDescSuffix:
            "Poin, bicara {0} poin, emosi {1} poin, kecepatan bicara {2} poin)",
          completeStandart: "Persyaratan penyelesaian tugas",
          byTimes: "Dengan jumlah kali",
          byScore: "Tekan Skor",
          completeByTimes: "Jumlah latihan",
          times: "Kali",
          score: "Poin",
          anyTimes: "Setiap kali",
          getScore: "Skor",
          offLimitDuration: "Panjang tak terbatas",
          onLimitDuration: "Panjang waktu terbatas",
          commitScore: "Standar standar, skor",
          excellentScore: "Standar yang sangat baik, skor",
          offLimitTime: "Waktu Tak Terbatas",
          onLimitTime: "Waktu Terbatas",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Hai," },
          callDynamic: {
            today: "Hari ini",
            yesterday: "Kemarin",
            sessions: "Sesi",
          },
          todoList: {
            strip: "Strip",
            theSessionIsAddedTolistenLater:
              'Sesi ditambahkan ke "Dengarkan nanti"',
            askYouForHelp: "Minta bantuan",
            iHopeToContactYouLater: "Berharap untuk menghubungi nanti",
            lookingForwardToSendingTheInformationLater:
              "Berharap untuk mengirim informasi nanti",
            dialogueRisk: "Ada titik risiko dalam sesi",
            tutored: "Dibimbing",
            goToCounseling: "Pergi ke konseling",
            contacted: "Sudah dihubungi",
            hasBeenSent: "Dikirim",
            areYouSureYouWantToIgnoreIt: "Yakin diabaikan?",
            determine: "Penentuan",
            cancel: "Pembatalan",
            ignore: "Abaikan",
            checked: "Telah dilihat",
          },
          callListItem: {
            strip: "Strip",
            learned: "Telah belajar",
            keyEvents: "Peristiwa kunci",
            totalImplementationRateOfSupervisionItems:
              "Tingkat implementasi umum dari item pengawasan",
          },
        },
        index: {
          operationSucceeded: "Operasi berhasil!",
          sessionDynamics: "Sesi Dinamis",
          my: "Dari saya",
          department: "Departemen",
          company: "Perusahaan",
          toDoList: "Item yang harus dilakukan",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Volume panggilan yang efektif (satu)",
          bestPractices: "Praktik Terbaik",
          averageDurationOfASingleCallminutes:
            "Durasi panggilan tunggal rata-rata (menit)",
          totalCallVolumePCs: "Total panggilan (per)",
          totalTalkTimehours: "Total Durasi Panggilan (Jam)",
          speakingPercentagepercentage: "Berbicara proporsi (persentase)",
          maximumContinuousSpeakingTimeminutes: "Durasi maksimum (menit)",
          numberOfIndepthDiscussionsPCs: "Jumlah diskusi mendalam (s)",
          maximumCustomerTalkminutes: "Pelanggan terpanjang berbicara (menit)",
          questionFrequencyPCs: "Frekuensi pertanyaan (s)",
          averageValue: "Rata-rata",
          selectDate: "Memilih tanggal",
          personnel: "Personil",
          pleaseSelect: "Silakan pilih",
          callRange: "Rentang panggilan",
          conversationActivity: "Kegiatan sesi",
          conversationAction: "Aksi sesi",
          keyEvents: "Peristiwa kunci",
          callRecord: "Rekaman panggilan",
          filter: "Filter:",
          conversation: "Panggilan telepon",
          minute: "Menit",
          sale: "Karyawan",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Januari",
            numberOfCalls: "Jumlah panggilan",
            accountInformation: "Informasi Akun",
            month: "Bulan",
            today: "Hari ini",
            customerDetails: "Rincian pelanggan",
            searchKeywordsInCommunicationContent:
              "Cari kata kunci dalam konten komunikasi",
            allMembers: "Semua anggota",
            reset: "Atur ulang",
            query: "Pertanyaan",
            allTrip: "Tampilan perjalanan penuh",
            contactOverview: "Ikhtisar Kontak",
            lastContact: "Kontak terakhir",
            nextContact: "Kontak berikutnya",
            previous: "Satu",
            next: "Berikutnya",
            viewDetails: "Lihat detail",
            contactDynamics: "Dinamika kontak",
            lastYear: "Tahun terakhir",
            lastCall: "Panggilan terakhir",
            hotTopicsForDiscussion: "Topik hangat untuk diskusi",
            totalInTheLastYear: "Tahun terakhir total",
            callTimes: "Panggilan sekunder",
          },
        },
        list: {
          index: {
            risk: "Risiko",
            have: "Ada",
            nothing: "Tidak ada",
            channel: "Saluran",
            callRecording: "Rekaman panggilan",
            enterpriseWeChatConversation: "Sesi Mikro",
            enterpriseWeChatCall: "Panggilan perusahaan mikro",
            followUpSuggestions: "Rekomendasi tindak lanjut",
            customer: "Pusat Pelanggan",
            followUp: "Tindak lanjut",
            personInCharge: "Penanggung jawab",
            recentFollowup: "Baru-baru ini tindak lanjut",
            lastContactTime: "Waktu kontak terbaru",
            operation: "Operasi",
            see: "Lihat",
            exceed: "Lebih dari",
            noActivityForMoreThanDays: "Tidak ada kegiatan",
            lately: "Baru-baru ini",
            risksOccurredInTheLastDays: "Semantik risiko dalam sehari",
            savedSuccessfully: "Simpan Sukses",
            today: "Hari ini",
            allDepartments: "Semua departemen",
            allMembers: "Semua anggota",
            customerName: "Nama Pelanggan",
            reset: "Atur ulang",
            query: "Pertanyaan",
            customers: "Pelanggan",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Menunjukkan kontak antara karyawan dan pelanggan, di mana gelembung merah muda adalah pesan pelanggan, dan biru adalah pesan karyawan.",
            riskAllocation: "Konfigurasi risiko",
            preservation: "Simpan",
            pleaseEnter: "Silakan masukkan",
            noActivityForDays: "Tidak ada kegiatan",
            riskSemanticsWithinDays: "Semantik risiko dalam sehari",
            addRiskSemantics: "Menambahkan risiko semantik",
            semanticTags: "Label semantik:",
            standardSentence: "Kalimat standar:",
          },
          components: {
            advancedFilter: {
              components: {
                list: {
                  index: { addNewConditions: "Menambahkan kondisi baru" },
                },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Memenuhi semua kondisi berikut",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Penyaringan",
                pleaseSelectFilteringCriteria: "Silakan pilih kondisi filter",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Mengubah tahap peluang bisnis",
              theCurrentStageIs: "Tahap saat ini adalah [",
              ConfirmToChangeItTo: "], Pastikan untuk mengubahnya menjadi [",
              IsIt: "】 Apakah ini?",
              StageCoStay: "] Stay Stay",
              day: "Hari",
              start: "Mulai",
              end: "Akhir",
              orderInput: "Kalah",
              restart: "Mulai ulang",
              pleaseConfigureTheSalesStageInformation:
                "Silakan mengonfigurasi informasi tahap penjualan",
              reasonForDocumentInput: "Kehilangan alasan:",
              completeOrderInput: "Selesaikan pesanan pecundang",
              pleaseSelectTheReason: "Silakan pilih penyebabnya",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "Saran manajer:" },
              index: {
                sessionDetails: "Detail Sesi",
                customerDynamics: "Dinamika pelanggan",
                riskPoint: "Titik risiko",
                followUpSuggestions: "Rekomendasi tindak lanjut",
              },
              sessionDetails: {
                common: "Total",
                currentlyViewingThe: "Saat ini melihat No.",
                sessions: "Sesi",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Tahap saat ini tetap",
              day: "Hari",
            },
          },
          business: {
            index: { reEnableOpportunity: "Aktifkan kembali peluang bisnis" },
          },
          customerTag: { index: { label: "Label" } },
          moreCustomer: {
            index: {
              essentialInformation: "Informasi dasar",
              cancel: "Pembatalan",
              confirm: "Konfirmasi",
              customer: "Pelanggan",
              currentFollowupStage: "Tahap tindak lanjut saat ini",
              telephone: "Telepon",
              followUpDays: "Hari tindak lanjut",
              day: "Hari",
              remarks: "Catatan",
              channelPreference: "Preferensi saluran",
              hello: "Halo",
              customFields: "Bidang kustom",
              opportunityAmount: "Jumlah peluang bisnis",
              focus: "Kekhawatiran",
              customerInformation: "Informasi pelanggan",
              viewMoreCustomerInformation:
                "Lihat lebih banyak informasi pelanggan",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Peristiwa kunci:" },
                row1: {
                  customerDynamics: "Dinamika pelanggan",
                  common: "Total",
                  barDynamic: "Strip dinamis",
                },
                row2: {
                  riskPoint: "Titik risiko",
                  common: "Total",
                  risks: "Risiko strip",
                },
                row3: {
                  followUpSuggestions: "Rekomendasi tindak lanjut",
                  intelligentRecommendation: "Rekomendasi cerdas",
                  strip: "Strip",
                  managersSuggestATotalOf: "Manajer menyarankan total",
                  recommendedByManagers: "Rekomendasi manajer",
                  creationTime: "Waktu pembuatan",
                  administratorSuggestions: "Saran admin",
                  suggestionsForNewManagers: "Tambahkan saran manajer",
                },
              },
            },
            index: { typeTime: "Jenis/Waktu" },
          },
          userInfo: {
            index: {
              personInCharge: "Penanggung jawab",
              recentFollower: "Baru-baru ini tindak lanjut",
              startTime: "Waktu sentuhan pertama",
              followUpDays: "Hari tindak lanjut",
              day: "Hari",
              remarks: "Catatan",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Anggota tim",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Jumlah total percakapan yang anggota pelajari dari orang lain (tidak berat), melihat detail percakapan dan mengklik rekaman untuk memutar dianggap belajar sekali",
          totalNumberOfStudies: "Jumlah total pembelajaran",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Jumlah total komentar dan respons yang dilakukan oleh anggota pada sesi orang lain (tidak berat)",
          totalNumberOfInteractions: "Jumlah total interaksi",
          learnTheSumOfConversationCallDuration:
            "Durasi panggilan percakapan belajar",
          lengthOfStudy: "Lama belajar",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Jumlah total bantuan yang dilakukan oleh anggota pada sesi orang lain (berat)",
          totalNumberOfRequests: "Jumlah total bantuan",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Jumlah total konseling anggota untuk bantuan orang lain (berat)",
          totalCounseling: "Jumlah total konseling",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Jumlah total sesi anggota terhadap orang lain yang ditambahkan ke galeri kasus (Go-re)",
          addCase: "Menambahkan kasus",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Jumlah total langganan yang dilakukan oleh anggota pada sesi orang lain (Go-re)",
          subscribe: "Berlangganan",
          totalNumberOfSessionsCommunicatedByMembers:
            "Jumlah total sesi yang dikomunikasikan oleh anggota",
          totalSessions: "Jumlah total sesi",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Jumlah total sesi anggota yang ditinjau (tidak berat), periksa detail sesi dan klik pada rekaman untuk diputar sebagai satu kali",
          totalNumberOfDoubleDisk: "Jumlah total ulasan",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Jumlah total sesi/total sesi * 100%",
          proportionOfDoubleDisk: "Rasio ulasan",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Waktu panggilan percakapan untuk ulasan",
          resumptionDuration: "Durasi reset",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Jumlah total anggota yang memulai bantuan pada sesi mereka sendiri (pergi ke berat)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Jumlah total sesi anggota yang menerima komentar dari orang lain (termasuk balasan)",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Jumlah total sesi anggota yang ditambahkan ke galeri kasus oleh orang lain (Go-re)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Jumlah total anggota sesi yang dilanggan oleh orang lain (Go-re)",
          askForHelpInTheLastMonth: "Bantuan sebulan terakhir",
          lastTimeForHelp: "Waktu bantuan terbaru",
          operation: "Operasi",
          viewAllSessionsInTheLastMonth:
            "Lihat semua sesi dalam sebulan terakhir",
          allDepartments: "Semua departemen",
          reset: "Atur ulang",
          query: "Pertanyaan",
          altogether: "Total",
          secondSession: "Sesi",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Item pengujian saat ini:",
            supervisionItem: "Item pengawasan",
            keyEvents: "Peristiwa kunci",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Centang item pengawasan atau peristiwa penting yang ingin Anda uji dan pastikan ada konten panggilan dalam percakapan yang disimulasikan",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Analisis eksekusi" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Proses 1",
              technologicalProcess: "Proses",
              newProcess: "Proses baru",
            },
          },
          index: {
            upload: "Unggah",
            preservation: "Simpan",
            newlyBuild: "Baru dibangun",
            cancel: "Pembatalan",
            name: "Nama",
            describe: "Deskripsi",
            type: "Jenis",
            classification: "Klasifikasi",
            content: "Konten",
            enclosure: "Aksesoris",
            knowledgePointAssociation: "Asosiasi poin pengetahuan",
            open: "Buka",
            close: "Tutup",
            operator: "Operator",
            logic: "Logika",
            see: "Lihat",
            edit: "Mengedit",
          },
        },
        index: {
          name: "Nama",
          describe: "Deskripsi",
          createdBy: "Pencipta orang",
          type: "Jenis",
          lastUpdated: "Terakhir diperbarui",
          numberOfReferences: "Jumlah kutipan",
          operation: "Operasi",
          see: "Lihat",
          edit: "Mengedit",
          delete: "Hapus",
          categoryDeletedSuccessfully: "Berhasil menghapus kategori",
          classificationOfKnowledgePoints: "Klasifikasi poin pengetahuan",
          newlyBuild: "Baru dibangun",
          knowledgeBase: "Basis pengetahuan",
          knowledgePoints: "Poin pengetahuan",
          technologicalProcess: "Proses",
          keyword: "Kata kunci",
          batchExport: "Ekspor massal",
          batchDelete: "Penghapusan massal",
          importProcess: "Proses impor",
          importKnowledgePoints: "Memperkenalkan poin pengetahuan",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Jika poin pengetahuan tidak ada dalam basis pengetahuan, poin pengetahuan akan ditambahkan secara langsung. Jika ada, lewati tanpa pembaruan.",
          editKnowledgePointClassification:
            "Mengedit klasifikasi poin pengetahuan",
          newKnowledgePointClassification: "Klasifikasi poin pengetahuan baru",
          categoryEditingSucceeded: "Sukses Mengedit Kategori",
          newClassificationSucceeded: "Sukses klasifikasi baru",
          classificationName: "Nama Kategori",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Pengawasan Eksekusi" },
      },
      topicCallout: {
        index: {
          whole: "Semua",
          date: "Tanggal",
          dimensionStatus: "Status label",
          callRecordID: "ID perekam panggilan",
          callRecordIDMustBeNumeric: "ID perekam panggilan harus nomor",
          strip: "Strip",
          topicAnnotation: "Menandai topik",
          annotated: "Ditandai",
          stripNotMarked: "Strip, tidak ditandai",
          barInDimension: "Strip, ditandai",
          customer: "Pelanggan",
          sale: "Penjualan",
          common: "Total",
          sessions: "Sesi Artikel",
          currentTopic: "Topik saat ini:",
          staff: "Karyawan",
          topicOfConversation: "Topik",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Operasi berhasil, label telah diatur ulang",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Setelah reset, semua catatan yang ditandai dari sesi pass akan dihapus. Harap konfirmasi",
          resetAll: "Semua Reset",
          pleaseSelectTheTopicToBeMarked:
            "Silakan pilih topik yang akan ditandai",
          pleaseTickTheSentenceToBeMarked:
            "Silakan centang kalimat yang akan ditandai",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Operasi berhasil, label telah berlaku",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Ruang lingkup yang ditandai kali ini berisi berita tentang topik lain, klik OK akan dibahas",
          confirmDimensions: "Konfirmasi label",
          talkTime: "Waktu bicara",
          callID: "Id panggilan",
        },
      },
      topicList: {
        index: {
          callID: "ID panggilan",
          latestMarkedTime: "Waktu Terbaru",
          topicOfConversation: "Topik",
          numberOfSentences: "Jumlah kalimat",
          sentence: "Kalimat",
          operation: "Operasi",
          see: "Lihat",
          delete: "Hapus",
          edit: "Mengedit",
          newSubTopic: "Subtopik baru",
          move: "Bergerak",
          whole: "Semua",
          topicDeletedSuccessfully: "Menghapus topik dengan sukses",
          newlyBuild: "Baru dibangun",
          topicList: "Daftar topik",
          callIDMustBeNumeric: "ID panggilan harus nomor",
          leadInTopic: "Topik Impor",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Ketika nama kategori diulang, itu tidak akan mencakup kategori topik asli, item akan dilewati secara langsung dan tidak akan diimpor",
          mobileClassification: "Klasifikasi ponsel",
          editTopic: "Mengedit topik",
          newTopic: "Topik baru",
          topicName: "Nama topik",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Berhasil dimodifikasi",
            createdSuccessfully: "Menciptakan Sukses",
            modificationFailed: "Gagal memodifikasi",
            creationFailed: "Gagal membuat",
            monitoringName: "Nama Pemantauan",
            upToCharacters: "Hingga 40 karakter",
            pleaseEnterAName: "Silakan masukkan nama",
            monitoringIndicators: "Indikator pemantauan",
            sessionActivity: "Kegiatan sesi",
            conversationAction: "Aksi sesi",
            keyEvents: "Peristiwa kunci",
            supervisionItems: "Item pengawasan",
            monitoringMembers: "Memantau anggota",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Kelompok yang menerima bimbingan, pelatihan, atau intervensi manajemen",
            groupA: "Grup A",
            pleaseSelectGroupAData: "Silakan pilih Data Grup A",
            thereAreDuplicateUsersInGroupB:
              "Pengguna dengan grup B memiliki duplikat",
            groupARemarks: "Komentar Grup A",
            controlGroupGroupComparedWithGroupA:
              "Grup kontrol, grup yang dibandingkan dengan grup A",
            groupB: "Grup B",
            pleaseSelectGroupBData: "Silakan pilih data Grup B",
            thereAreDuplicateUsersInGroupA:
              "Pengguna dengan grup A memiliki duplikat",
            groupBRemarks: "Catatan Grup B",
            monitoringPeriod: "Siklus pemantauan",
            startNode: "Mulai node",
            monitoringScope: "Rentang pemantauan",
            pleaseSelectMonitoringConditions:
              "Silakan pilih kondisi pemantauan",
            pleaseEnterANumber: "Silakan masukkan angka",
            pleaseSelectATimePeriod: "Silakan pilih periode waktu",
            preservation: "Simpan",
            cancel: "Pembatalan",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Hapus Sukses",
            notStarted: "Belum dimulai",
            haveInHand: "Dalam proses",
            closed: "Sudah berakhir",
            newGroupTest: "Pengujian paket baru",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Tahap penjualan",
        communicationStrategy1: "Strategi Komunikasi",
        riskAllocation: "Konfigurasi risiko",
        businessConfiguration: "Konfigurasi bisnis",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Penanganan keberatan",
                  productSellingPointScript: "Nilai jual produk",
                  caseGrouping: "Pengelompokan kasus",
                  pleaseSelectExcellentCases:
                    "Silakan pilih kasus yang sangat baik",
                  pleaseSelectKnowledgePoints: "Silakan pilih poin pengetahuan",
                  associativeLinks: "Tautan asosiasi",
                  jumpTo: "Melompat ke",
                  jumpType: "Jenis Lompat",
                  knowledgePoints: "Poin pengetahuan",
                  excellentCases: "Kasus yang sangat baik",
                  excellentScript: "Kata-kata yang sangat baik",
                  pleaseSelectASingleKnowledgePoint:
                    "Silakan pilih satu poin pengetahuan",
                },
              },
            },
            index: {
              name: "Nama",
              pushText: "Teks push",
              enableStatus: "Aktifkan Status",
              triggerRules: "Aturan pemicu",
              communicationStrategy: "Strategi Komunikasi",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping:
                "Pengelompokan strategi komunikasi",
              name: "Nama",
              pushText: "Teks push",
              effectiveness: "Efektivitas",
              openSuccessfully: "Sukses terbuka",
              closeSuccessfully: "Penutupan Sukses",
              operationFailed: "Kegagalan operasi",
              operation: "Operasi",
              edit: "Mengedit",
              delete: "Hapus",
              newlyBuild: "Baru dibangun",
              communicationStrategy: "Strategi Komunikasi",
              editGroup: "Mengedit pengelompokan",
              groupName: "Nama grup",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Semua sesi",
                  recentDays: "Jumlah hari terakhir",
                  recentSessions: "Sesi Terbaru",
                  scopeOfApplication: "Lingkup aplikasi:",
                  day: "Hari",
                  through: "Melalui",
                },
              },
            },
            index: {
              describe: "Deskripsi",
              applicableCustomers: "Pelanggan yang berlaku",
              enableStatus: "Aktifkan Status",
              triggerRules: "Aturan pemicu",
              riskRules: "Aturan Risiko",
            },
          },
          list: {
            index: {
              riskAllocation: "Konfigurasi risiko",
              keyWord: "Kata kunci",
              newRiskRule: "Aturan Risiko Baru",
              riskRuleName: "Nama Aturan Risiko",
              describe: "Deskripsi",
              state: "Status",
              openSuccessfully: "Sukses terbuka",
              closeSuccessfully: "Penutupan Sukses",
              operationFailed: "Kegagalan operasi",
              operation: "Operasi",
              edit: "Mengedit",
              delete: "Hapus",
              deletionSucceeded: "Hapus Sukses",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Kalah" },
              endReasonList: {
                addEndReason: "Tambahkan penyebab akhir",
                enterEndReason: "Penyebab akhir masukan",
              },
              stageList: {
                keyEvents: "Peristiwa kunci",
                waysideData: "Data bersama jalan",
                includeAny: "Berisi apa pun",
                includeAll: "Berisi semua",
                pleaseEnterTheStageName: "Masukkan nama panggung",
                setStageRules: "Menetapkan Aturan Tahapan",
                stageName: "Nama panggung",
                operator: "Operator",
                logic: "Logika",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Format input bidang tipe waktu adalah: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Permintaan gagal, silakan coba lagi nanti",
              salesStage: "Tahap penjualan",
              essentialInformation: "Informasi dasar",
              name: "Nama",
              applicableDepartments: "Departemen yang berlaku",
              applicableToOthers: "Cocok untuk yang lain",
              manualChangePhase: "Tahap perubahan manual",
              enableStatus: "Aktifkan Status",
              describe: "Deskripsi",
              opportunityStageSettings: "Pengaturan tahap peluang bisnis",
              stageSettings: "Pengaturan tahap",
              aliasSettings: "Pengaturan Alias",
              closeOpportunity: "Mengakhiri peluang bisnis",
              newSuccessfully: "Sukses baru",
              newFailed: "Gagal membangun baru",
              savingSucceeded: "Simpan Sukses",
              saveFailed: "Simpan gagal",
            },
          },
          list: {
            index: {
              salesStage: "Tahap penjualan",
              keyWord: "Kata kunci",
              newSalesStage: "Tahap penjualan baru",
              salesStageName: "Nama tahap penjualan",
              describe: "Deskripsi",
              state: "Status",
              openSuccessfully: "Sukses terbuka",
              closeSuccessfully: "Penutupan Sukses",
              operationFailed: "Kegagalan operasi",
              operation: "Operasi",
              edit: "Mengedit",
              delete: "Hapus",
              deletionSucceeded: "Hapus Sukses",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Generasi model besar",
            conversationInsights: "Wawasan percakapan",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Menghasilkan secara otomatis" },
              },
              hooks: {
                conversationSummary: "Ringkasan percakapan",
                conversationProcess: "Proses sesi",
                opportunityPoints: "Titik peluang",
              },
              prompt: {
                index: {
                  edit: "Mengedit",
                  restoreDefault: "Mengembalikan default",
                  cancel: "Pembatalan",
                  confirm: "Konfirmasi",
                },
              },
            },
            index: {
              saveSuccessful: "Simpan Sukses",
              hintLanguage: "Pesan",
              preserve: "Simpan",
              cancel: "Pembatalan",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Generasi model besar",
        salesStage: "Tahap penjualan",
        communicationStrategy: "Strategi Komunikasi",
        riskAllocation: "Konfigurasi risiko",
        businessConfiguration: "Konfigurasi bisnis",
      },
      salesLearningCenter: {
        knowledgeBase: "Basis pengetahuan",
        speechCorpus: "Perpustakaan bicara",
        learningCenter: "Pusat Pembelajaran",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "Informasi pelanggan" } },
        },
        index: { otherInformation: "Informasi lainnya" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Impor" } } },
        hooks: {
          index: {
            all: "Semua",
            intelligentModel: "Model cerdas",
            smartRules: "Aturan cerdas",
            manualScoring: "Penilaian manual",
            enable: "Aktifkan",
            notEnabled: "Tidak diaktifkan",
            voiceCall: "Panggilan suara",
            textDialogue: "Percakapan teks",
            ecommerceTextSession: "Percakapan teks e-commerce",
            workOrder: "Pesanan kerja",
          },
        },
        index: {
          ruleName: "Nama aturan",
          ruleType: "Jenis Aturan",
          pleaseSelect: "Silakan pilih",
          ruleStatus: "Status aturan",
          dataSourceType: "Tipe Sumber Data",
          voiceCall: "Panggilan suara",
          textDialogue: "Percakapan teks",
          ecommerceTextSession: "Percakapan teks e-commerce",
          workOrder: "Pesanan kerja",
          status: "Status",
          operation: "Operasi",
          edit: "Mengedit",
          ruleTemplate: "Cetakan aturan",
        },
      },
      analysisReportTitle: "Analisis laporan",
      ruleTest: "Uji Aturan",
      intelligentTags: {
        labelDisplayQuantity: "Jumlah tampilan label",
        unlimited: "Tidak terbatas",
        ignoringTagLibraries: "Abaikan Perpustakaan Label",
        automaticallyGenerateLabels: "Menghasilkan label secara otomatis",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'Setelah diaktifkan, secara otomatis menghasilkan label untuk sesi berdasarkan model besar, dan label yang dihasilkan diklasifikasikan secara default ke "Tidak Dikategorikan"',
        defaultValidity: "Efektivitas default",
        supervisionItem: "Item pengawasan",
        customerLabel: "Pelanggan Label",
        newSupervisionItemRule: "Aturan untuk item pengawasan baru",
        editSupervisionItemRule: "Aturan untuk mengedit item pengawasan",
        newCustomerLabel: "Label pelanggan baru",
        editCustomerLabel: "Mengedit label pelanggan",
        newSessionLabel: "Tab Sesi Baru",
        editSessionLabel: "Mengedit percakapan Tags",
        newKeyEvent: "Buat acara kunci baru",
        editKeyEvents: "Mengedit peristiwa penting",
        detail: {
          template: {
            nameIsARequiredField: "Nama adalah bidang yang diperlukan",
          },
          formItemComponent: {
            largeModelExtensionLabel: "Label ekspansi model besar",
            sessionRecordID: "Rekaman sesi id",
            sessionTime: "Sesi Waktu",
            smartTags: "Label pintar",
            viewDetails: "Lihat detail",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Data yang direkam dalam sesi ini akan dihapus setelah dihapus. Apakah Anda mengonfirmasi penghapusan?",
            tagData: "Menandai data",
            batchDeletion: "Penghapusan massal",
          },
        },
        template: {
          advancedFiltering: "Penyaringan lanjutan",
          dataType: "Tipe Data",
          filterCriteria: "Kondisi penyaringan",
          edit: "Mengedit",
          createANewSubcategory: "Sub-kategori baru",
          delete: "Hapus",
          nameLengthCannotExceed: "Panjang nama tidak dapat melebihi 40",
        },
        component: {
          modelExpansion: "Ekspansi model",
          enable: "Aktifkan",
          notEnabled: "Tidak diaktifkan",
          standardScreening: "Pemfilteran standar",
          advancedFiltering: "Penyaringan lanjutan",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Kata kunci",
              sessionLabel: "Tab Sesi",
              keyEvents: "Peristiwa kunci",
              matchAny: "Cocokkan apa pun",
              matchAll: "Mencocokkan semua",
              beEqualTo: "Sama dengan",
              includeAll: "Berisi semua",
              includeAny: "Berisi apa pun",
              advancedConditions: "Kondisi Lanjutan",
              smartTag: "Label pintar",
              smartTagI: "Label pintar satu",
              smartTagII: "Label pintar dua",
            },
            template: {
              pleaseSelectSmartTag: "Silakan pilih label pintar",
              pleaseSelectASessionLabel: "Silahkan pilih percakapan tab",
              pleaseSelectAKeyEvent: "Silakan pilih peristiwa penting",
              pleaseSelectAdvancedCondition: "Silakan pilih kondisi lanjutan",
              newAdvancedFilter: "Penyaringan lanjutan baru",
              moveUp: "Pindah ke atas",
              moveDown: "Geser ke bawah",
              moveLeft: "Geser ke kiri",
              shiftRight: "Pergeseran ke kanan",
            },
          },
          component: {
            businessAnalysispng: "Analisis bisnis. png",
            savedSuccessfully: "Simpan Sukses",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Efektif permanen",
            permanentlyInvalid: "Tidak aktif permanen",
            customValidity: "Masa berlaku kustom",
            termOfValidity: "Masa berlaku",
            name: "Nama",
            explain: "Deskripsi",
            state: "Status",
            classification: "Klasifikasi",
            smartRecommendation: "Rekomendasi cerdas",
            preconditions: "Kondisi Depan",
            SetPreconditionsForThisSupervisionItem:
              "1. Tetapkan prasyarat untuk item pengawasan ini:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Ketika prasyarat terpenuhi, itu adalah "item pengawasan yang harus dilakukan", dan item pengawasan artikel diuji;',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Jika prasyarat tidak terpenuhi, item pengawasan artikel tidak akan diuji;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Jangan mengatur pra-kondisi, yaitu untuk mendeteksi semua sesi secara default",
            have: "Ada",
            nothing: "Tidak ada",
            preconditionRule: "Aturan pra-kondisi",
            preconditionLogic: "Logika pra-kondisi",
          },
          recommendation: {
            caseGrouping: "Pengelompokan kasus",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Ketika karyawan tidak melakukan dengan benar, mereka dapat secara cerdas merekomendasikan kata-kata yang sangat baik dan kasus yang sangat baik",
            smartRecommendation: "Rekomendasi cerdas",
            open: "Buka",
            close: "Tutup",
            whenThisSupervisionItem: "Ketika ini adalah item pengawasan",
            implement: "Eksekusi",
            unexecuted: "Tidak dieksekusi",
            when: "Waktu:",
            recommendedKnowledgePoints:
              "Poin pengetahuan yang direkomendasikan",
            caseRecommendation: "Rekomendasi kasus",
          },
        },
        index: {
          determine: "Penentuan",
          sort: "Menyortir",
          sortSucceeded: "Menyortir kesuksesan",
          supervisionItemClassification: "Klasifikasi item pengawasan",
          pressEnterToCreateANew: "Tekan Entruck Baru",
          nameCannotBeEmpty: "Nama tidak boleh kosong",
          newlyBuild: "Baru dibangun",
          name: "Nama",
          explain: "Deskripsi",
          validStatus: "Status efektif",
          permanentlyValid: "Efektif permanen",
          permanentlyInvalid: "Tidak aktif permanen",
          operation: "Operasi",
          edit: "Mengedit",
          batchDelete: "Penghapusan massal",
          newSupervisionItem: "Item pengawasan baru",
          keyWord: "Kata kunci",
          delete: "Hapus",
          deleteSucceeded: "Hapus Sukses",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Data tidak akan dapat dipulihkan setelah dihapus. Apakah penghapusan dikonfirmasi?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Label pintar",
              beEqualTo: "Sama dengan",
              includeAll: "Berisi semua",
              includeAny: "Berisi apa pun",
              matchAny: "Cocokkan apa pun",
              matchAll: "Mencocokkan semua",
              smartTagI: "Label pintar satu",
              smartTagII: "Label pintar dua",
            },
            template: {
              pleaseSelectASmartTag: "Silakan pilih label pintar",
              pleaseSelectAdvancedConditions: "Silakan pilih kondisi lanjutan",
              newAdvancedFilter: "Penyaringan lanjutan baru",
              rename: "Mengganti nama",
              moveUp: "Pindah ke atas",
              moveDown: "Geser ke bawah",
              shiftLeft: "Geser ke kiri",
              shiftRight: "Pergeseran ke kanan",
            },
          },
        },
        template: {
          delete: "Hapus",
          labelFiltering: "Label filter:",
          satisfyAny: "Memenuhi apa pun",
          meetAll: "Memenuhi semua",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Label klasifikasi tingkat kedua Jumlah dialog ≥ klasifikasi tingkat pertama, satu sesi mungkin berisi beberapa label",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logika perhitungan perubahan rantai: (jumlah percakapan dalam periode waktu yang dipilih-jumlah percakapan di sebelah periode waktu sebelumnya)/Jumlah percakapan dalam periode waktu sebelumnya",
          proportionOfPrimaryClassification:
            "Proporsi klasifikasi tingkat pertama",
          saveAs: "Simpan sebagai",
          cover: "Meliputi",
          preservation: "Simpan",
          qualityInspectionTask: "Tugas analisis:",
          query: "Pertanyaan",
          changeFromMonthToMonth: "Perubahan rantai",
          tagRanking: "Pemeringkatan label",
          proportion: "Proporsi",
        },
        component: {
          numberOfConversations: "Jumlah percakapan",
          operation: "Operasi",
          businessAnalysispng: "Analisis bisnis. png",
          savingSucceeded: "Simpan Sukses",
          deletionSucceeded: "Hapus Sukses",
          sequence: "Urutan",
          firstLevelClassification: "Klasifikasi tingkat pertama",
          ringRatioChange: "Perubahan bulan ke bulan",
          classClassification: "Klasifikasi tingkat",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Konfirmasi penghapusan dokumen dan ekstraksi?",
            addKnowledgeBase: "Menambahkan basis pengetahuan",
            uploadDocument: "Mengunggah dokumen",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Mendukung file format kata, pdf, ppt, excel, txt",
            taskName: "Nama tugas",
            extractConversationDate: "Ekstraksi Tanggal Percakapan",
            pleaseSelectAConversationDate: "Silakan pilih tanggal percakapan",
            dataSource: "Sumber Data",
            dataType: "Tipe Data",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Ekspor berhasil",
              problem: "Pertanyaan",
              answer: "Jawaban",
              addToKnowledgeBase: "Tambahkan ke Basis Pengetahuan",
              pleaseConfirmWhetherToDeleteIt: "Pastikan Anda menghapus",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Tambahkan ke Basis Pengetahuan",
              selectClassification: "Pilih kategori",
              pleaseSelectAKnowledgeBaseClassification:
                "Silakan pilih klasifikasi basis pengetahuan",
            },
          },
        },
        index: {
          intelligentExtraction: "Ekstraksi cerdas",
          documentExtraction: "Ekstraksi dokumen",
          dialogueExtraction: "Ekstraksi dialog",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operasi yang sukses",
          labelIgnored: "Label diabaikan",
          add: "Tambahkan",
          areYouSureToDeleteIt: "Apakah Anda yakin untuk menghapusnya?",
          batchFilterLabels: "Label filter massal",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Pemeriksaan kualitas yang cerdas",
        savedSuccessfully: "Simpan Sukses",
        logoSettings: "Pengaturan logo",
        default: "Default",
        custom: "Kustom",
        title: "Judul",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Nama judul yang ditampilkan oleh sistem direkomendasikan dalam 6 kata.",
        logoImageUpload: "Unggah gambar logo",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Silakan pilih gambar dengan ukuran tidak lebih dari 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Untuk memastikan efek browsing logo, unggah gambar yang disarankan:",
        Size5050Pixels: "1. Ukuran: 50*50 piksel;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Format: gambar latar belakang transparan, png atau jpg, grafis logo menggunakan putih",
        browserLabelIcon: "Ikon tab browser",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Untuk memastikan efek penelusuran label, unggah gambar yang disarankan:",
        Size3232Pixels: "1. Ukuran: 32*32 piksel;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Format: gambar latar belakang transparan, png atau jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Pemeriksaan kualitas sedang berlangsung, silakan nanti...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "Pada tahun 2015, real estat Tiongkok telah mencapai momen kritis untuk bertahan hidup, sejumlah besar rumah dibiarkan tanpa pengawasan, dan orang-orang tidak mau membeli. Hangzhou pernah jatuh di bawah 8000, dan beberapa pengembang menghancurkan pengiriman. Shenzhen masih berkonsolidasi sekitar 2w-3w pada waktu itu, dan rumah-rumah di kota-kota tingkat pertama juga dalam bahaya.",
        xiaoAn: "Xiao'an",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload Hambatan memori ini disebut penghalang memori jsr, yang merupakan tingkat jvm. Ada dua cara untuk mengimplementasikan di bagian bawah sistem operasi: 1 adalah bus kunci, dan yang lainnya adalah meta-bahasa penghalang memori, seperti sfance (menulis penghalang) mfence (penghalang baca). Bagian bawah mesin virtual hotspots menggunakan pernyataan rakitan: kunci addl, kunci operasi kosong, yaitu bus kunci, ini dilakukan karena beberapa sistem operasi tidak mendukung metadata penghalang memori.",
        xiaobei: "Utara kecil",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Cobalah mengasinkan daging beberapa kali, dan sayuran yang digoreng setelah menambahkan makanan, dagingnya seperti daging, bau asam! Sejak saat itu, bacon tidak lagi memasak anggur",
        xiaodong: "Xiaodong",
        xiaoxi: "Barat kecil",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Cobalah mengasinkan daging beberapa kali, dan sayuran yang digoreng setelah menambahkan makanan, dagingnya seperti daging, bau asam! Sejak saat itu, bacon tidak lagi memasak anggur",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Manajemen ulasan e-commerce",
        socialMediaReviewManagement: "Manajemen Ulasan Media Sosial",
        customDataSource: "Asli data kustom",
        qualityInspectionResults: "Hasil pemeriksaan kualitas",
        accountManagement: "Manajemen Akun",
        employeeProfile: "Potret karyawan",
        intelligentAnalysis: "Analisis cerdas",
        customKanban: "Kanban kustom",
        dataScreening: "Gambaran Data",
        learningRecord: "Catatan belajar",
        dataAnalysis: "Analisis data",
        scoringManagement: "Manajemen Penilaian",
        businessConfiguration: "Konfigurasi bisnis",
        manualReview: "Pemeriksaan manual",
        taskList: "Daftar tugas",
        videoAnalysis: "Analisis Video",
        overview: "Ikhtisar",
        personalSignage: "Kanban pribadi",
        learningCenter: "Pusat Pembelajaran",
        wrongQuestionSet: "Set pertanyaan yang salah",
        abilityImprovement: "Peningkatan kemampuan",
        close: "Tutup",
        switchingSystemApplications: "Beralih aplikasi sistem",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Gerakkan mouse di sini untuk memanggil menu switching aplikasi, klik item menu untuk melompat ke aplikasi yang berbeda",
        teamAnalysis: "Analisis tim",
        semanticIntelligence: "Kecerdasan semantik",
        permissionManagement: "Manajemen izin",
        dataConnection: "Docking Data",
        courseManagement: "Manajemen Kurikulum",
        labelManagement: "Manajemen label",
        curriculum: "Pusat Kursus",
        courseConfiguration: "Konfigurasi Kursus",
        task: "Tugas saya",
        historicalLearningData: "Sejarah",
        taskCenter: "Manajemen tugas",
        knowledgeBase: "Basis pengetahuan",
        ecommerceSessionManagement: "Manajemen sesi e-commerce",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Beralih aplikasi",
          descriptionOfIntelligentQualityInspectionSystem:
            "Deskripsi sistem pemeriksaan kualitas yang cerdas",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Filter sesi e-commerce",
        allEcommerceSessions: "Semua percakapan e-commerce",
        ecommerceSessionManagement: "Manajemen sesi e-commerce",
      },
    },
    analysisEnable: {
      bestPractices: "Praktik Terbaik",
      behaviorAnalysis: "Analisis perilaku",
      teamAnalysis: "Analisis tim",
      executiveSupervision: "Pengawasan Eksekusi",
      executiveForceAnalysis: "Analisis eksekusi",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Peringkat:",
          startTime: "Waktu mulai",
          workOrderNo: "Nomor pesanan kerja",
          customerServiceHasReadNotRead:
            "Bacaan/belum dibaca Layanan Pelanggan",
          canYouAppeal: "Apakah bisa naik banding",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Catatan perusahaan mikro",
          addFriendTime: "Menambahkan waktu teman",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Mulai pemeriksaan ulang",
          callID: "ID panggilan",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Fitur baru! Klik tombol 'Pemeriksaan Sampel Cepat', dan itu akan secara otomatis melompat ke halaman berikutnya setelah pengiriman berhasil ~~~",
        },
      },
      template: {
        deleteRecord: "Menghapus catatan",
        areYouSureToDeleteIt: "Apakah Anda yakin untuk menghapusnya?",
        confirm: "Penentuan",
        reIdentification: "Identifikasi ulang",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Reidentifikasi akan memakan waktu untuk menulis ulang, harap berhati-hati",
        determine: "Penentuan",
        executionSuccessful: "Eksekusi Sukses",
      },
      component: {
        pleaseSelectData: "Silakan pilih data",
        operationSuccessful: "Operasi yang sukses",
        startPerformingReQualityInspection: "Mulai pemeriksaan ulang",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Unggah nama file",
        analysisTask: "Menganalisis tugas",
        analyzeProgress: "Analisis kemajuan",
        analysisSucceeded: "Analisis keberhasilan",
        analysisFailed: "Analisis kegagalan",
        localDataUpload: "Unggah data lokal",
        recordingUpload: "Mengunggah rekaman",
        textUpload: "Upload teks",
        uploadTime: "Mengunggah waktu",
        taskName: "Nama tugas",
        operator: "Operator",
        qualityInspectionProgress: "Kemajuan pemeriksaan kualitas",
        whole: "Semua",
        haveInHand: "Dalam proses",
        completed: "Selesai",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Apakah Anda mengekspor semua rekaman data yang sudah selesai dalam kondisi filter saat ini?",
        upload: "Unggah",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Mengisi nama",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Isi nama seperti jenis/koleksi/tujuan data yang diimpor ini",
            default: "Default",
            jDCOM: "JD",
            dataType: "Tipe Data",
            selectAnalysisTask: "Memilih tugas analisis",
            uploadFiles: "Unggah file",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Unduh templat excel, isi sesuai format:",
            template: "Template",
            SupportUpTo10000PiecesOfData: "2. Dukungan maksimum 10000 data",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Dukungan untuk ekspor Jingdong data percakapan upload, file. txt, ukuran file mendukung hingga 100M",
            alongTheWayDataTemplate: "Templat Data Dengan Jalan",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Dalam pemeriksaan kualitas teks, harap bersabar",
            textQualityInspectionCompleted: "Pemeriksaan kualitas teks selesai",
          },
        },
        template: {
          startAnalysis: "Mulai menganalisis",
          pleaseUploadTheFile: "Silakan unggah file",
          localDataUpload: "Unggah data lokal",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "Fitur baru! Klik tombol 'Tinjauan Kirim ', dan itu akan secara otomatis melompat ke halaman berikutnya setelah pengiriman berhasil ~~~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "Izin tugas pemeriksaan kualitas",
            edit: "Mengedit",
            iParticipated: "Aku terlibat",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Karakter ini dapat melihat data di bawah departemen yang dipilih",
            dataPermissions: "Izin Data",
            selectDepartment: "Pilih departemen",
            edit: "Mengedit",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Mulai pemeriksaan ulang",
          callID: "ID panggilan",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Fitur baru! Klik tombol 'Kirim Inspeksi Pengambilan Sampel 'dan 'Pemeriksaan Sampel Cepat'. Setelah pengiriman berhasil, mereka akan secara otomatis melompat ke halaman berikutnya ~~~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Aturan" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Manajemen pesanan kerja",
          workOrderDetails: "Rincian pesanan kerja",
        },
      },
      template: {
        workOrderFilter: "Filter tenaga kerja",
        allWorkOrders: "Semua pesanan kerja",
        workOrderManagement: "Manajemen pesanan kerja",
      },
    },
    trainingCenter: {
      topicAnnotation: "Menandai topik",
      topicList: "Daftar topik",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Tipe Karakter",
              keyWord: "Kata kunci",
              pleaseEnterKeywordSearch: "Silakan masukkan pencarian kata kunci",
              query: "Pertanyaan",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Fitur ini belum dibuka, silakan hubungi administrator sistem jika perlu",
          pleaseSelectAQuestion: "Silakan pilih pertanyaan!",
          recommendedSimilarSentencesLargeModel:
            "Kalimat serupa yang direkomendasikan (model besar)",
          selectAll: "Pilih semua",
          add: "Tambahkan",
          changeBatch: "Ubah batch",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Jam kerja bisnis",
      localDataUpload: "Unggah data lokal",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Merakit daftar menu sesuai dengan izin pengguna saat ini",
      sessionSettings: "Pengaturan sesi",
      logoSettings: "Pengaturan logo",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Selamat datang kembali! Masukkan rincian Anda",
        mailbox: "Kotak surat",
        password: "Kata sandi",
        signIn: "Masuk",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "IP saat ini tidak memungkinkan login",
          welcomeToLogin: "Selamat datang untuk masuk",
          pursuingExcellenceAndLeadingChange:
            "Mengejar keunggulan dan memimpin perubahan",
          intelligentPlatform: "Platform cerdas",
        },
        virtualLogin: { index: { pleaseSelect: "Silakan pilih" } },
      },
      oauth: {
        illegalCall: "Panggilan ilegal",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Selamat datang kembali! Klik tombol di bawah ini untuk melompat ke platform otentikasi identitas",
        loggingIn: "Masuk...",
        unifiedIdentityAuthenticationLogin:
          "Login otentikasi identitas terpadu",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Uji aturan: Beberapa aturan tidak dapat menggunakan uji aturan, seperti volume kecepatan bicara, data on-road, dll.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Pelajari lebih lanjut",
          enteringTheSystem: "Masuk ke Sistem",
        },
      },
      index: {
        cancel: "Pembatalan",
        enteringTheSystem: "Masuk ke Sistem",
        close: "Tutup",
        enterSystemApplication: "Masuk ke aplikasi sistem",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Klik "Masuk ke Sistem" untuk melompat ke aplikasi sistem yang diaktifkan',
      },
    },
    permissionManagement: {
      employeeManagement: "Manajemen Karyawan",
      roleManagement: "Manajemen peran",
      permissionManagement: "Manajemen izin",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Sama dengan",
            contain: "Berisi",
            doesNotContain: "Tidak mengandung",
            applicationSystem: "Sistem Aplikasi",
            intelligentQualityInspection:
              "Cerdas pemeriksaan kualitas/analisis sesi cerdas",
            salesEmpowerment: "Pemberdayaan penjualan",
            dataSourceType: "Tipe Sumber Data",
            filterCriteria: "Kondisi penyaringan",
          },
        },
        index: {
          dataConnection: "Docking Data",
          savingSucceeded: "Simpan Sukses",
          dataIsolation: "Isolasi data",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Setelah dihidupkan, Anda dapat mengatur rentang data setiap sistem aplikasi untuk panggilan dan sesi WeChat perusahaan. Jika sakelar tidak dinyalakan, setiap sistem akan terhubung ke data volume penuh",
          accessRules: "Aturan akses",
          preservation: "Simpan",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Nama aplikasi",
            accessRights: "Izin akses",
            inAppPermissionSettings: "Pengaturan izin dalam aplikasi",
            setUp: "Pengaturan",
            applicationAccess: "Izin akses aplikasi",
            newRole: "Menambahkan karakter",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "Detail Sesi" } },
      messageNotification: {
        index: { sessionDetails: "Detail Sesi", caseDetails: "Rincian kasus" },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Terlihat karyawan",
            newCourse: "Kursus Baru",
            editCourse: "Kursus Penyuntingan",
            courseName: "Nama Kursus",
            courseIntroduction: "Pengantar kursus",
            courseType: "Jenis Kursus",
            courseClassification: "Klasifikasi Kursus",
            courseCover: "Sampul kursus",
            learningContent: "Konten pembelajaran",
            uploadLearningMaterials: "Mengunggah materi pembelajaran",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Pengujian",
              preservation: "Simpan",
              cancel: "Pembatalan",
              editNodeName: "Mengedit nama node",
              determine: "Penentuan",
              individual: "Satu",
              of: ", Total",
              branch: "Poin",
            },
            configDrawerComponents: {
              pPTParsing: "Analisis PPT...",
              uploadSuccessful: "Berhasil diunggah",
              uploadAttachments: "Unggah lampiran",
              pPTParsingPleaseWait: "Analisis PPT, silakan nanti",
            },
            customerPortraitConfigDrawer: {
              cancel: "Pembatalan",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Tidak dapat dipulihkan setelah dihapus, apakah Anda yakin ingin menghapus potret ini?",
              delete: "Hapus",
              determine: "Penentuan",
              customerPortrait: "Potret pelanggan",
              bindSelectedCharacterPortrait:
                "Mengikat potret karakter yang dipilih",
              customerProfileDetails: "Rincian potret pelanggan",
              createANewCustomerProfile: "Potret pelanggan baru",
            },
            flow: {
              sidebar: { node: "Node" },
              useControlButtons: {
                cancellingPleaseWait: "Dicabut, silakan nanti...",
                redoInProgressPleaseWait: "Re-do, silakan nanti...",
                automaticOrganization: "Pengaturan otomatis",
                canvasAdaptation: "Kanvas Adaptif",
                delete: "Hapus",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Node tidak dapat terhubung dengan diri mereka sendiri",
              },
            },
            globalConfigDrawer: {
              cancel: "Pembatalan",
              determine: "Penentuan",
              globalConfiguration: "Konfigurasi global",
              sessionDetection: "Deteksi sesi",
              reciprocalRule: "Aturan Interaksi",
              robotVoice: "Warna Suara Robot",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Pengaturan batas waktu siswa",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Waktu diam maksimum yang diizinkan oleh siswa yang tidak menjawab (s)",
              promptScriptForFailureToAnswerOverTime: "Tips lembur",
              addScript: "Tambahkan Kata-kata",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (alam)",
              zhishaChat: "Zhisha (obrolan)",
              zhiqiangentle: "Zhiqian (moderat)",
              zhimiEmotion: "Zhimi (emosi)",
              aiShuoNatural: "Ai Shuo (Alam)",
              zhishuoChat: "Zhishuo (obrolan)",
              aiHaoAffinity: "Ai Hao (afinitas)",
              zhixiangEmotion: "Zhixiang (emosi)",
              robotVoice: "Warna Suara Robot",
              schoolboy: "Anak laki-laki",
              girlStudent: "Gadis",
              volume: "Volume",
              tone: "Nada",
              speechSpeed: "Kecepatan bicara",
              test: "Pengujian",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Halo, saya adalah pelatih pelatihan cerdas, saya berharap untuk berkomunikasi dengan Anda",
            },
            sessionDetectionForm: {
              expressionDetection: "Deteksi ekspresi",
              excessiveDetectionOfModalParticles:
                "Terlalu banyak deteksi kata gas",
              theNumberOfRepetitionsIs: "Jumlah pengulangan adalah",
              minus: "Waktu berikutnya, kurangi",
              appear: "Muncul",
              subtract: "Kali, minus",
              branch: "Poin",
              addRules: "Menambah aturan",
              includingNegativeAngryComplaining:
                "(Termasuk negatif, marah, mengeluh)",
              scorePointsDisplay: "Skor poin menunjukkan",
              reminderOfSpeakingTooFasttooSlow:
                "Mengingatkan terlalu cepat/terlalu lambat",
              scriptViolationReminder: "Pengingat pelanggaran bicara",
              emotionalReminder: "Pengingat emosional",
              speechSpeedDetection: "Deteksi kecepatan bicara",
              normalSpeechSpeedScore: "Kecepatan bicara skor normal",
              speakTooFast: "Berbahasa terlalu cepat",
              everyMinuteGreaterThan: "Per menit lebih besar dari",
              aWordAppears: "Kata, muncul",
              speakTooSlowly: "Kecepatan bicara terlalu lambat",
              lessThanPerMinute: "Kurang dari per menit",
              ignoreLessThan: "Abaikan kurang dari",
              aSentenceOfWords: "Kalimat kata",
              emotionalDetection: "Deteksi emosi",
              emotionalNormalScore: "Skor Emosi Normal",
              negativeEmotions: "Emosi negatif",
              illegalWordDetection: "Deteksi kata-kata pelanggaran",
              scriptViolationConfiguration: "Konfigurasi pelanggaran bicara",
              realTimePromptOnTheFrontEnd: "Kiat real-time front-end",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Tanya Jawab Basis Pengetahuan",
                  qAMethod: "Metode tanya jawab",
                  numberOfQuestionsAndAnswers: "Jumlah Tanya Jawab",
                  scoringRules: "Aturan skor",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Pertanyaan dan jawaban yang benar",
                  branch: "Poin",
                  successfullySaved: "Simpan Sukses",
                },
                pptNode: {
                  verificationPassed: "Verifikasi lulus",
                  upload: "Unggah",
                  supportPptPdfFormatFiles: "Mendukung file format ppt, pdf",
                  uploadSlide: "Mengunggah slide",
                  assessmentMethod: "Metode penilaian",
                  associateSlide: "Terkait Slide",
                  standardScript: "Kata-kata standar",
                  successfullySaved: "Simpan Sukses",
                },
                robotNode: {
                  robotScript: "Robot berbicara",
                  randomScript: "Kata-kata acak",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Memilih kata-kata semantik yang serupa, robot akan secara acak mengatakan salah satu dari mereka",
                  customerPortrait: "Potret pelanggan",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Robot mengatakan ini ketika potret pelanggan konsisten",
                  setAsDefaultScript: "Diatur sebagai panggilan default",
                  yes: "Ya",
                  no: "Tidak.",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Kata-kata ini terjadi ketika robot gagal memukul tag pengguna mana pun",
                  successfullySaved: "Simpan Sukses",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Node cabang ini sudah memiliki kata-kata default. Apakah kata-kata default diganti dengan kata-kata saat ini?",
                },
                startNode: {
                  paragon: "Narasi",
                  successfullySaved: "Simpan Sukses",
                },
                userNode: {
                  verificationPassed: "Verifikasi lulus",
                  standardScript: "Kata-kata standar",
                  successfullySaved: "Simpan Sukses",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Silakan menyempurnakan konfigurasi",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "Masukkan contoh percakapan",
                  dialogueExample: "Contoh percakapan",
                  generateConversationExamples:
                    "Menghasilkan contoh percakapan",
                  mattersNeedingAttention: "Catatan",
                  pleaseEnterThePromptLanguage: "Silakan masukkan petunjuk",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Adegan dialog",
                  simulateCharacter: "Peran simulasi",
                  accompanyingTrainees: "Objek sparring",
                  selectFromTemplateLibrary: "Pilih dari galeri templat",
                  templateLibrary: "Perpustakaan Template",
                  role: "Karakter",
                  scene: "Adegan",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Jendela Uji Slide [",
              clearContent: "Menghapus konten",
              close: "Tutup",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI menghasilkan aliran bicara",
                scriptStyle: "Gaya bicara",
                theMostConversationRounds: "Putaran percakapan paling banyak",
                copyConversation: "Salin percakapan",
                application: "Aplikasi",
                regenerate: "Ulang",
                startGenerating: "Mulai menghasilkan",
                pleaseGenerateAProcess: "Membuat proses",
                copySuccessful: "Menyalin berhasil",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Perwakilan penjualan",
                    customerServicePersonnel: "Staf layanan pelanggan",
                    storeCounter: "Konter toko",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Menghasilkan pidato teks lengkap",
                speaker: "Pembicara",
                scriptStyle: "Gaya bicara",
                copyConversation: "Salin percakapan",
                application: "Aplikasi",
                regenerate: "Ulang",
                startGenerating: "Mulai menghasilkan",
                pleaseGenerateAProcess: "Membuat proses",
                operationSuccessful: "Operasi yang sukses",
                copySuccessful: "Menyalin berhasil",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Sentuhan kata-kata" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Kata-kata tidak bisa kosong!",
              copySuccessful: "Menyalin berhasil",
              cancel: "Pembatalan",
              copy: "Menyalin",
              replaceTheOriginalText: "Ganti teks asli",
              aiGeneration: "Generasi Ai",
              aiPolishing: "Ai memoles",
              currentStyle: "Gaya saat ini",
              rigorous: "Ketat",
              mild: "Lembut",
              lively: "Hidup",
              simplicity: "Sederhana",
              pleaseEnterTheSpeakerRole: "Masukkan karakter pembicara",
              startGenerating: "Mulai menghasilkan",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Kata-kata tidak bisa kosong!",
              copySuccessful: "Menyalin berhasil",
              aiRewrite: "Ai menulis ulang",
              cancel: "Pembatalan",
              copy: "Menyalin",
              replaceTheOriginalText: "Ganti teks asli",
              currentStyle: "Gaya saat ini",
              rigorous: "Ketat",
              mild: "Lembut",
              lively: "Hidup",
              simplicity: "Sederhana",
              pleaseEnterTheSpeakerRole: "Masukkan karakter pembicara",
              startGenerating: "Mulai menghasilkan",
            },
          },
          index: {
            verificationPassed: "Verifikasi lulus",
            ruleValidation: "Verifikasi aturan",
            aIGeneration: "Generasi AI",
            generateFullTextSpeechScript: "Menghasilkan pidato teks lengkap",
            editCourse: "Kursus Penyuntingan",
            importConversation: "Mengimpor percakapan",
            uploadExcelToCompleteDialogueImport:
              "Upload Excel untuk menyelesaikan impor percakapan",
            editBasicInformation: "Mengedit informasi dasar",
            test: "Pengujian",
            release: "Rilis",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Label baru" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Tidak dapat dipulihkan setelah dihapus, yakin ingin menghapus label ini?",
              delete: "Hapus",
              cancel: "Pembatalan",
            },
          },
        },
        index: {
          labelManagement: "Manajemen label",
          newGroup: "Pengelompokan baru",
          edit: "Mengedit",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Tidak dapat dipulihkan setelah dihapus, yakin ingin menghapus grup label ini?",
          delete: "Hapus",
          cancel: "Pembatalan",
          editGroup: "Mengedit pengelompokan",
          labelGroupName: "Nama grup label",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Kamera dinonaktifkan",
              noCameraDevicesFound: "Tidak menemukan perangkat kamera",
            },
          },
          cue: {
            index: {
              regenerate: "Ulang",
              generate: "Menghasilkan",
              referenceScript: "Referensi",
              mattersNeedingAttention: "Catatan",
              examFrequency: "Jumlah ujian",
              section: "UU No.",
              timestotal: "Kali/umum",
              second: "Kali",
              requirementsForPassingTheExam: "Persyaratan kelulusan ujian",
              examScore: "Skor ujian ≥",
              branch: "Poin",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Tidak ada potret",
              customerBackground: "Latar Belakang Pelanggan",
              customerLabel: "Pelanggan Label",
            },
          },
          flow: {
            index: {
              processReminder: "Tips Proses (",
              score: "Skor",
              TotalScore: "/Total skor",
            },
          },
          index: {
            taskName: "Nama tugas",
            taskStatus: "Status tugas",
            whole: "Semua",
            completionStatus: "Status selesai",
            courseClassification: "Klasifikasi Kursus",
            practiceMode: "Mode Latihan",
            examMode: "Mode Ujian",
            practiceTime: "Waktu latihan",
          },
          ppt: {
            index: {
              nextPage: "Halaman berikutnya",
              tips: "Tips",
              continueWithCurrentPage: "Lanjutkan halaman saat ini",
              goToTheNextPage: "Ke halaman berikutnya",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Setelah memasuki halaman berikutnya, Anda tidak dapat kembali ke halaman sebelumnya. Harap konfirmasi bahwa halaman saat ini telah dijelaskan secara lengkap sebelum melompat",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Tips ini tidak akan muncul dengan mengklik pada halaman berikutnya",
            },
            list: { index: { slide: "Slide" } },
          },
          record: {
            index: {
              unableToRecord: "Tidak dapat merekam:",
              creatingARecorderService: "Membuat layanan Recorder",
              turnOffTheRecorderService: "Tutup layanan Recorder",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Klien saat ini telah terhubung ke server ASR Websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "Setelah frame terakhir diproses, status dipulihkan",
              aSRConnectionClosed: "Koneksi ASR ditutup...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Rekaman dihentikan sementara dan diproses, tetapi masih ada data dalam cache",
              foundASRChannelClosedRecreateWebsocketLink:
                "Menemukan saluran ASR ditutup, membuat ulang tautan Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Temukan bahwa saluran ASR tidak terbuka, buat ulang tautan Websocket",
            },
            ppt: { hook: { index: { serverError: "Kesalahan Server" } } },
            text: {
              index: {
                pleaseEnter: "Silakan masukkan",
                sendOut: "Kirim",
                startPracticing: "Mulai latihan",
                startTheExam: "Mulai ujian",
              },
            },
            util: {
              recorded: "Terekam",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "SendFrameSize 16-bit harus merupakan kelipatan bilangan bulat dari 2",
              pCMSamplingRateShouldNotOccur:
                "Seharusnya tidak ada laju sampling pcm",
              andTheRequiredSamplingRate:
                "Dan tingkat sampling yang dibutuhkan",
              atypism: "Tidak konsisten",
              errorsThatShouldNotOccur:
                "Kesalahan yang seharusnya tidak terjadi:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> urutan data yang tidak teratur, jika tidak tinggi, Anda dapat langsung membuang </span>',
              flower: "Bunga",
              TransferStopped: ": Berhenti transmisi",
            },
            video: { index: { camera: "Kamera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Klien saat ini telah terhubung ke server ASR Websocket",
                    aSRConnectionClosed: "Koneksi ASR ditutup...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Menemukan saluran ASR ditutup, membuat ulang tautan Websocket",
                    unableToRecord: "Tidak dapat merekam:",
                    aSRChannelClosedReopened:
                      "Saluran ASR ditutup, dibuka kembali",
                    theASRChannelWasNotCreatedStartingNow:
                      "Saluran ASR tidak dibuat, sekarang mulai dibuat",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect callback untuk membersihkan saluran ASR",
                    creatingARecorderService: "Membuat layanan Recorder",
                    turnOffTheRecorderService: "Tutup layanan Recorder",
                  },
                  util: {
                    recorded: "Terekam",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "SendFrameSize 16-bit harus merupakan kelipatan bilangan bulat dari 2",
                    pCMSamplingRateShouldNotOccur:
                      "Seharusnya tidak ada laju sampling pcm",
                    andTheRequiredSamplingRate:
                      "Dan tingkat sampling yang dibutuhkan",
                    atypism: "Tidak konsisten",
                    errorsThatShouldNotOccur:
                      "Kesalahan yang seharusnya tidak terjadi:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> urutan data yang tidak teratur, jika tidak tinggi, Anda dapat langsung membuang </span>',
                    flower: "Bunga",
                    TransferStopped: ": Berhenti transmisi",
                  },
                },
                index: { failInSend: "Gagal mengirim" },
              },
              index: {
                startRecording: "Mulai merekam",
                endRecording: "Akhir rekaman",
                startPracticing: "Mulai latihan",
                startTheExam: "Mulai ujian",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Jumlah ujian yang tersisa adalah",
              totalScriptScore: "Total skor bicara:",
              totalEmotionalScore: "Total skor emosional:",
              totalScoreOfExpression: "Mengekspresikan skor total:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Hasil penilaian tugas saat ini perlu ditinjau secara manual, hasil akhir akan diberitahukan kepada Anda setelah selesai, harap bersabar",
              areYouSureToSubmit: "Apakah Anda mengkonfirmasi pengiriman?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Latihan telah selesai, apakah Anda mengkonfirmasi pengajuan?",
              theExamHasEndedAreYouSureToSubmit:
                "Pemeriksaan telah selesai, apakah Anda mengkonfirmasi pengajuan?",
              completed: "Selesai",
              page: "Halaman",
              pageCount: "Jumlah halaman",
              topic: "Pertanyaan",
              totalNumberOfQuestions: "Jumlah pertanyaan",
              totalScore: "Total skor",
              branch: "Poin",
              accumulatedCompletionProgress: "Akumulasi kemajuan penyelesaian",
              accumulatedPracticeDuration: "Durasi latihan kumulatif",
              lastPauseExerciseResults: "Jeda Terakhir-Hasil Latihan",
              exerciseInstructions: "Instruksi Latihan",
              examInstructions: "Instruksi ujian",
              halfwayPausePracticeResults: "Midway Jeda-Hasil Latihan",
              tips: "Tips",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Setelah keluar, latihan ini tidak termasuk dalam hasil. Apakah Anda mengkonfirmasi penarikan?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Tidak ada penarikan yang diizinkan di tengah ujian. Apakah Anda mengkonfirmasi pengajuan?",
              pleaseOperateWithCaution: "Kali, harap berhati-hati)",
              restart: "Mulai lagi",
              returnToLearning: "Kembali ke Belajar",
              continuePracticing: "Lanjutkan latihan",
              continuingTheExam: "Lanjutkan ujian",
              return: "Kembali",
              notSubmittedTemporarily: "Tidak mengirimkan",
              iGotIt: "Aku tahu",
              confirmExit: "Konfirmasi keluar",
              confirmSubmission: "Konfirmasi pengajuan",
              signOut: "Keluar",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Jeda",
              submit: "Pengajuan",
              remainingTimeOfThisQuestion: "Sisa waktu untuk pertanyaan ini",
              SecondsLeftUntilTheEndTime: "10 detik tersisa untuk mengakhiri",
              remainingTime: "Waktu yang tersisa",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Konten suara pengguna tidak terdeteksi, coba lagi",
          },
          wave: {
            index: {
              continueRecording: "Lanjutkan rekaman",
              submit: "Pengajuan",
              endRecording: "Akhir rekaman",
              suspendRecording: "Jeda rekaman",
              startPracticing: "Mulai latihan",
              startTheExam: "Mulai ujian",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Mode percakapan",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Kebisingan berlebihan, silakan beralih ke lingkungan yang tenang",
              normalNoiseDetection: "Deteksi kebisingan normal",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Volume terlalu kecil, tolong tingkatkan volume",
              volumeDetectionIsNormal: "Deteksi volume normal",
              noiseDetection: "Deteksi kebisingan",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Harap tetap tenang dan merekam suara lingkungan 3s",
              endRecording: "Akhir rekaman",
              startRecording: "Mulai merekam",
              volumeDetection: "Deteksi volume",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Silakan baca "Lakukan tes volume sekarang" dengan volume normal',
              playLastDetectedAudio: "Memutar audio deteksi terakhir",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Silakan baca persyaratan dengan seksama sebelum Anda mulai",
              return: "Kembali",
              iGotIt: "Aku tahu",
              timeLimitForSingleQuestionAnswering:
                "Batas waktu untuk menjawab pertanyaan tunggal",
              totalTimeLimit: "Batas waktu",
              unlimitedTime: "Tidak terbatas",
              stillNeedToCompleteTheTaskInTotal:
                "Diperlukan untuk menyelesaikan tugas",
              practice: "Latihan",
              second: "Kali",
              taskScore: "Skor misi ≥",
              branch: "Poin",
              or: "Atau",
              examScore: "Skor ujian ≥",
              remainingExamTimes: "Jumlah ujian yang tersisa",
              totalScore: "Total skor",
              Script: "(Kata-kata",
              scoreEmotion: "Membagi emosi",
              fractionExpression: "Ekspresi",
              points: "Poin)",
              standardScore: "Poin standar",
              excellentScore: "Poin yang sangat baik",
              exerciseInstructions: "Instruksi Latihan",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Untuk menjawab pertanyaan dengan lancar, silakan lengkapi pengujian peralatan terlebih dahulu:",
              camera: "Kamera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Dapat melihat diri Anda dalam video, berarti kamera normal",
              microphone: "Mikrofon",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Silakan berbicara dengan mikrofon, melihat pola gelombang berarti mikrofon normal",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Kosong",
                  copyTo: "Salin ke",
                  classification: "Klasifikasi",
                  rename: "Mengganti nama",
                  newlyBuild: "Baru dibangun",
                  categoryParent: "Klasifikasi atasan",
                  moveTo: "Pindah ke",
                  name: "Nama",
                },
              },
              util: {
                rename: "Mengganti nama",
                moveTo: "Pindah ke",
                copyTo: "Salin ke",
                delete: "Hapus",
                courseClassification: "Klasifikasi Kursus",
                newlyBuild: "Baru dibangun",
              },
            },
          },
          edit: { index: { curriculum: "Kursus" } },
          index: {
            learn: "Belajar",
            practice: "Latihan",
            examination: "Tes",
            curriculum: "Kursus",
            courseIntroduction: "Pengantar kursus:",
            createdOn: "Waktu pembuatan:",
            published: "Dirilis",
            tasks: "Tugas",
            unpublishedTasks: "Tugas yang belum dirilis",
            task: "Misi",
            operation: "Operasi",
            learningMaterials: "Bahan Pembelajaran",
            goPractice: "Pergi berlatih",
            publishTask: "Tugas rilis",
            taskData: "Tugas Data",
            edit: "Mengedit",
            copy: "Menyalin",
            successfullyCopied: "Menyalin berhasil",
            delete: "Hapus",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Setelah dihapus, semua data tugas di bawah kursus ini akan dikosongkan. Apakah Anda mengkonfirmasi penghapusan kursus?",
            courseName: "Nama Kursus",
            creationTime: "Waktu pembuatan",
            releaseStatus: "Status rilis",
            whole: "Semua",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Tidak ada suara yang terdeteksi",
              voice: "Suara",
              text: "Teks",
              referenceScript: "Referensi:",
              clickOnTheRightSideToStartRecording:
                "Klik kanan untuk memulai rekaman",
              sendOut: "Kirim",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Klien saat ini telah terhubung ke server ASR Websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "Setelah frame terakhir diproses, status dipulihkan",
                aSRConnectionClosed: "Koneksi ASR ditutup...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Rekaman dihentikan sementara dan diproses, tetapi masih ada data dalam cache",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Menemukan saluran ASR ditutup, membuat ulang tautan Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Temukan bahwa saluran ASR tidak terbuka, buat ulang tautan Websocket",
                unableToRecord: "Tidak dapat merekam:",
                creatingARecorderService: "Membuat layanan Recorder",
                turnOffTheRecorderService: "Tutup layanan Recorder",
              },
              util: {
                recorded: "Terekam",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "SendFrameSize 16-bit harus merupakan kelipatan bilangan bulat dari 2",
                pCMSamplingRateShouldNotOccur:
                  "Seharusnya tidak ada laju sampling pcm",
                andTheRequiredSamplingRate:
                  "Dan tingkat sampling yang dibutuhkan",
                atypism: "Tidak konsisten",
                errorsThatShouldNotOccur:
                  "Kesalahan yang seharusnya tidak terjadi:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> urutan data yang tidak teratur, jika tidak tinggi, Anda dapat langsung membuang </span>',
                flower: "Bunga",
                TransferStopped: ": Berhenti transmisi",
              },
            },
            finishTest: {
              index: {
                endTest: "Mengakhiri tes",
                testResult: "Hasil tes",
                returnToCanvas: "Kembali ke kanvas",
                retest: "Pengujian ulang",
              },
            },
          },
          index: {
            testFromCurrentNode: "Pengujian dari node saat ini",
            listening: "Di radio...",
            canvasTesting: "Tes kanvas",
            endOfProcess: "Proses berakhir",
            restart: "Mulai lagi",
            start: "Mulai",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Mengatasi",
                  expandParsing: "Memperluas analisis",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Terlalu banyak kata",
                  second: "Kali",
                  ratingOverview: "Tinjauan Peringkat",
                  totalScore: "Total skor",
                  section: "UU No.",
                  name: "Nama",
                  of: "(Total",
                  people: "Orang)",
                  scriptScore: "Skor bicara",
                  common: "Total",
                  keyPoints: "Poin utama,",
                  notReached: "Yang belum tercapai,",
                  have: "Ada",
                  violationOfRegulations: "Pelanggaran",
                  emotionalScore: "Skor emosi",
                  appear: "Muncul",
                  secondaryNegativeEmotions: "Sentimen negatif kedua",
                  expressionScore: "Skor ekspresi",
                  speakTooFast: "Berbahasa terlalu cepat",
                  times: "Kali,",
                  speakTooSlowly: "Kecepatan bicara terlalu lambat",
                  average: "Rata-rata",
                  wordminute: "Kata/menit",
                },
              },
              score: {
                index: {
                  studentNodeName: "Nama simpul siswa",
                  keyPointName: "Nama Poin",
                  slideNodeName: "Nama Node Slide",
                  dialogue: "Percakapan",
                  mainPoints: "Poin penting",
                  slide: "Slide",
                  detectionResult: "Hasil tes",
                  satisfy: "Puas",
                  dissatisfaction: "Tidak puas",
                  score: "Skor",
                  script: "Kata-kata",
                  semantics: "Semantik",
                  scriptViolation: "Pelanggaran bicara",
                  emotion: "Emosi",
                  expression: "Ekspresi",
                },
              },
              taskTitle: { index: { branch: "Poin" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Kali ini",
                  lastTime: "Terakhir kali",
                  comprehensiveEvaluation: "Evaluasi komprehensif",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Akumulasi bicara standar',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Kata, siswa mengikuti jumlah kata yang dipukul",
                  aWordspan: "Kata </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Kedua </span><br /><span style = "margin-kiri: 8px">· berbicara terlalu lambat',
                  timesspan: "Kali </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Terlalu banyak kata gas',
                  complete: "Konten lengkap",
                  keyHit: "Titik hit",
                  languageNorm: "Spesifikasi bahasa",
                  fluentExpression: "Ekspresi halus",
                  emotionallyPositive: "Emosi positif",
                  radarChart: "Peta Radar",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Jumlah proses lengkap',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· Hit poin yang Anda inginkan',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· memiliki',
                  dealingWithIllegalWordsspan: "Kata-kata pelanggaran </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· Berbahasa terlalu cepat',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Kedua </span><br /><span style = "margin-left: 8px">· Terlalu banyak kata',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Emosi negatif',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Catatan Skor",
                  firstRating: "Skor pertama",
                  totalScore: "Total skor",
                  changeTo: "Ubah ke",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Tahap komunikasi",
                  communicationObjectives: "Tujuan Komunikasi",
                  scoringCriteria: "Kriteria penilaian",
                  suggestionsForImprovement: "Saran promosi",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Jumlah waktu yang tersisa:",
                  second: "Kali",
                  doItAgain: "Sekali lagi",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Browser tidak mendukung pemutaran audio.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Komentar dan saran",
                  regenerate: "Ulang",
                  generate: "Menghasilkan",
                  generationFailed: "Gagal menghasilkan",
                },
              },
              resultModal: {
                index: { return: "Kembali", viewDetails: "Lihat detail" },
              },
            },
            index: {
              reRatingSuccessful: "Skor ulang berhasil",
              reRatingFailed: "Gagal mencetak ulang",
              downloadRecording: "Mengunduh rekaman",
              downloadText: "Mengunduh teks",
              download: "Unduh",
              reRating: "Skor ulang",
              submitARatingAppeal: "Ajukan banding penilaian",
              reviewSubmission: "Pengajuan ulasan",
              saveChanges: "Simpan modifikasi",
              previousSentence: "Kalimat sebelumnya",
              nextSentence: "Kalimat selanjutnya",
              score: "Penilaian",
              scoringSituation: "Situasi skor",
              dialogueAnalysis: "Analisis dialog",
            },
          },
          index: {
            ratingAppealInProgress: "Peringkat banding",
            operation: "Operasi",
            wrongQuestionSet: "Set pertanyaan yang salah",
            ratingUpdate: "Update Rating",
            viewDetails: "Lihat detail",
            curriculum: "Kursus",
            taskName: "Nama tugas",
            taskType: "Jenis tugas",
            score: "Skor",
            scoringResults: "Hasil Skor",
            time: "Waktu",
            courseClassification: "Klasifikasi Kursus",
            whole: "Semua",
            learningTime: "Waktu belajar",
            historicalLearningData: "Data pembelajaran sejarah",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Latihan Jeda",
              lastProgress: "Kemajuan terakhir",
              continuePracticing: "Lanjutkan latihan",
              practice: "Latihan",
              startPracticing: "Mulai latihan",
              historicalData: "Data historis",
              courseIntroduction: "Pengantar kursus:",
              exerciseDifficulty: "Latihan kesulitan:",
              practiceCompletionRequirements:
                "Latihan persyaratan penyelesaian:",
              practiceFrequency: "Jumlah latihan ≥",
              second: "Kali",
              anyScore: "Skor acak ≥",
              branch: "Poin",
              or: "Atau",
              taskList: "Daftar tugas",
              practiceTime: "Waktu latihan:",
              to: "Untuk",
              unlimitedTime: "Waktu Tak Terbatas",
              completionStatus: "Status selesai",
              numberOfExercises: "Jumlah latihan",
              maximumScore: "Skor tertinggi",
              operation: "Operasi",
              enteringLearning: "Masuk ke pembelajaran",
              historicalLearningData: "Data pembelajaran sejarah",
              wrongQuestionSet: "Set pertanyaan yang salah",
            },
          },
          index: {
            practiceTasks: "Tugas latihan",
            examTasks: "Tugas ujian",
            task: "Misi",
          },
          learn: {
            index: {
              courseName: "Nama Kursus",
              courseIntroduction: "Pengantar kursus",
              learningContent: "Konten pembelajaran",
              downloadLearningMaterials: "Unduh materi pembelajaran",
              startPracticing: "Mulai latihan",
            },
          },
          test: {
            index: {
              taskList: "Daftar tugas",
              courseIntroduction: "Pengantar kursus:",
              examDifficulty: "Kesulitan ujian:",
              requirementsForPassingTheExam: "Persyaratan kelulusan ujian:",
              examScore: "Skor ujian ≥",
              branch: "Poin",
              examTime: "Waktu ujian:",
              to: "Untuk",
              unlimitedTime: "Waktu Tak Terbatas",
              completionStatus: "Status selesai",
              completionTimes: "Jumlah penyelesaian",
              maximumScore: "Skor tertinggi",
              operation: "Operasi",
              immediateExam: "Ujian segera",
              history: "Sejarah",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Referensi" } },
              flow: {
                index: {
                  wrongQuestionItem: "Item pertanyaan yang salah (",
                  score: "Skor",
                  TotalScore: "/Total skor",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Kesalahan Server" } },
                  index: {
                    startRecording: "Mulai merekam",
                    endRecording: "Akhir rekaman",
                    startPracticing: "Mulai latihan",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Silakan masukkan",
                    sendOut: "Kirim",
                    startPracticing: "Mulai latihan",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Mulai merekam",
                    endRecording: "Akhir rekaman",
                    startPracticing: "Mulai latihan",
                  },
                  hook: { index: { failInSend: "Gagal mengirim" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Skor latihan yang salah ini",
                  tips: "Tips",
                  areYouSureToEnd: "Apakah itu dikonfirmasi?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Berlatih lagi tanpa menguasai pertanyaan yang salah",
                  continue: "Lanjutkan",
                  returnToTheSetOfIncorrectQuestions:
                    "Kembali ke set pertanyaan yang salah",
                  end: "Akhir",
                  wrongQuestionItem: "Item pertanyaan yang salah",
                  numberOfExercisesThisTime: "Jumlah latihan ini",
                  masteringTheSituation: "Menguasai situasi",
                  mastered: "Telah menguasai",
                  notMastered: "Tidak menguasai",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Berlatih pertanyaan ini lagi",
                  nextQuestion: "Pertanyaan selanjutnya",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Latihan untuk pertanyaan yang salah",
                endExercise: "Akhiri latihan",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Latihan untuk pertanyaan yang salah",
                endExercise: "Akhiri latihan",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Latihan untuk pertanyaan yang salah",
                endExercise: "Akhiri latihan",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Lihat kata-kata",
                  section: "UU No.",
                  secondaryDiscourseTechnique: "Kata-kata kedua",
                },
              },
              tableItem: {
                count: { second: "Kali" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Tidak ada informasi yang ditemukan",
                  recallWords: "Kata-kata ingat",
                  branch: "Poin",
                  answerIncorrectly: "Jawaban yang salah",
                  second: "Kali",
                  wrongAnswerRate: "Tingkat jawaban yang salah",
                },
                standardScript: {
                  standardScript: "Kata-kata standar",
                  myScript: "Kata-kata saya",
                },
                status: {
                  mastered: "Telah menguasai",
                  notMastered: "Tidak menguasai",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "Jumlah total jawaban",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Pertanyaan yang salah telah dipraktikkan",
                },
              },
            },
            index: {
              wrongQuestionList: "Daftar pertanyaan yang salah",
              wrongQuestionSet: "Set pertanyaan yang salah",
              mispracticeExercises: "Latihan untuk pertanyaan yang salah",
              taskName: "Nama tugas",
              staff: "Karyawan",
              masteringTheSituation: "Menguasai situasi",
              whole: "Semua",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Jumlah latihan ≥",
              second: "Kali",
              or: "Atau",
              excellentScoreAtAnyOneTime: "Setiap skor yang sangat baik (≥",
              points: "Poin)",
              examScore: "Skor ujian ≥",
              branch: "Poin",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Distribusi acak dan merata",
              assignToDesignatedPersonnel:
                "Ditugaskan untuk orang yang ditunjuk",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Meningkatkan jumlah ujian yang berhasil",
              failedToIncreaseTheNumberOfExams:
                "Gagal meningkatkan jumlah ujian",
              increaseTheNumberOfExams: "Tingkatkan jumlah ujian",
              selectObjects: "Memilih objek",
            },
          },
          executionFilter: {
            index: { participationRate: "Tingkat partisipasi" },
          },
          intelligentReview: {
            index: {
              overallReview: "Ulasan keseluruhan",
              pleaseEnterTheReviewCriterianotMandatory:
                "Silakan masukkan ulasan standar (tidak diperlukan)",
              commentaryOnSingleSentenceRhetoric: "Ulasan kalimat tunggal",
              realTimeReview: "Ulasan real-time",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Skor rata-rata ujian",
            averageExamDurationmin: "Rata-rata durasi ujian (min)",
            accumulatedPracticeDurationmin: "Akumulasi durasi latihan (min)",
            accumulatedPracticeTimes: "Jumlah latihan kumulatif",
            totalScore: "Total skor",
            average: "Skor rata-rata",
            total: "Jumlah total",
            keyHitCount: "Jumlah hit poin",
            practiceAverageScore: "Skor rata-rata latihan",
            averagePracticeDurationmin: "Rata-rata durasi latihan (min)",
            topOfTheHighestScore: "Top3 Skor tertinggi",
            branch: "Poin",
            errorProneItemTop: "Top5 yang rawan kesalahan",
            hitRate: "Tingkat hit",
            hitRateOfKeyPointsInScript: "Poin bicara",
            averageScriptScore: "Rata-rata skor bicara",
            sortByProcess: "Urutkan menurut proses",
            sortByScore: "Diurutkan berdasarkan skor",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Halaman nilai rata-rata/skor total halaman",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Skor rata-rata/skor total",
            details: "Detail",
            viewRules: "Lihat aturan",
            standardScript: "Kata-kata standar",
          },
          scoreDetailList: {
            section: "UU No.",
            second: "Kali",
            practice: "Latihan",
            examination: "Ujian",
            notMeetingStandards: "Tidak memenuhi standar",
            meetingStandards: "Standar",
            excellent: "Luar biasa",
            frequency: "Jumlah",
            fraction: "Skor",
            result: "Hasil",
            operation: "Operasi",
            viewDetails: "Lihat detail",
          },
          index: {
            common: "Total",
            people: "Orang",
            staff: "Karyawan",
            notMeetingStandards: "Tidak memenuhi standar",
            meetingStandards: "Standar",
            excellent: "Luar biasa",
            notStarted: "Belum dimulai",
            haveInHand: "Dalam proses",
            completed: "Selesai",
            passed: "Telah melewati",
            hangInTheAir: "Belum selesai",
            failed: "Tidak lulus",
            unlimitedTime: "Waktu Tak Terbatas",
            to: "Untuk",
            maximumScoringCriteria: "Kriteria skor tertinggi",
            completionStatus: "Status selesai",
            employeeName: "Nama karyawan",
            frequency: "Jumlah",
            practice: "Latihan",
            examination: "Ujian",
            average: "Skor rata-rata",
            speechCraftAvgScore: "Rata-rata skor bicara",
            emotionAvgScore: "Skor emosi rata-rata",
            speedAvgScore: "Ekspresi skor rata-rata",
            flowAvgScore: "Skor rata-rata proses",
            practiceCostTime: "Durasi kumulatif",
            participateCommitRate: "Tingkat kepatuhan",
            participateExcellentRate: "Tingkat keuntungan",
            duration: "Durasi",
            score: "Skor",
            scoringResults: "Hasil Skor",
            flowScore: "Skor proses",
            craftScore: "Skor bicara",
            emotionScore: "Skor emosi",
            speedScore: "Skor ekspresi",
            contentIntegrity: "Integritas konten",
            pointHitRate: "Titik hit",
            emotionNegativeRate: "Proporsi negatif emosional",
            paraPhaSiaRate: "Proporsi kecepatan bicara yang tidak normal",
            toneOvermuchRate: "Terlalu banyak bicara",
            maximumScore: "Skor tertinggi",
            highestScoreResult: "Hasil Skor Tertinggi",
            state: "Status",
            averageDurationmin: "Rata-rata Durasi (min)",
            lately: "Baru-baru ini",
            time: "Waktu",
            operation: "Operasi",
            viewDetails: "Lihat detail",
            wrongQuestionSet: "Set pertanyaan yang salah",
            taskName: "Nama tugas",
            completeRequirements: "Persyaratan lengkap",
            publishTo: "Menerbitkan objek",
            taskTime: "Waktu Misi",
            fractionalComposition: "Komposisi Skor",
            totalScore: "Total skor",
            branch: "Poin",
            Script: "(Kata-kata",
            scoreEmotion: "Membagi emosi",
            fractionExpression: "Ekspresi",
            points: "Poin)",
            implementationRate: "Tingkat partisipasi",
            numberOfExecutors: "Jumlah peserta",
            numberOfPeopleNotExecuted: "Jumlah peserta yang tidak",
            completionRate: "Tingkat penyelesaian",
            numberOfCompletedPersonnel: "Jumlah Lengkap",
            numberOfUnfinishedPersonnel: "Jumlah yang belum selesai",
            complianceRate: "Tingkat kepatuhan",
            numberOfQualifiedPersonnel: "Jumlah orang yang memenuhi standar",
            numberOfNonCompliantIndividuals:
              "Jumlah orang yang tidak memenuhi standar",
            excellentRate: "Tingkat keuntungan",
            participateCompleteRate: "Tingkat partisipasi",
            numberOfOutstandingIndividuals: "Jumlah yang sangat baik",
            numberOfNonOutstandingIndividuals: "Jumlah yang tidak baik",
            export: "Ekspor",
            taskData: "Tugas Data",
            resultAnalysis: "Analisis Hasil",
            scoreDetails: "Detail Skor-",
            dataOverview: "Ringkasan data",
            taskDetail: "Detail tugas",
            scriptAnalysis: "Analisis bicara",
            flowAnalysis: "Analisis proses",
            expressionAnalysis: "Analisis ekspresi",
            emotionAnalysis: "Analisis emosi",
            byTime: "Tampilan Dimensi Waktu",
            byNumber: "Tampilan Dimensi Jumlah Kali",
            personData: "Statistik menurut staf",
            numberData: "Detail catatan per waktu",
            count: "Dengan jumlah kali",
            person: "Dengan jumlah orang",
            reachTimes: "Jumlah sentuhan",
            outOfReachTimes: "Jumlah Tidak Tersentuh",
            seeOutOfReach: "Klik untuk melihat tidak tersentuh",
            accumulative: "Akumulasi",
            script: "Kata-kata",
            times: "Kali",
            sentence: "Kalimat",
            expressionAverage: "Ekspresi skor rata-rata",
            modalAnalysis: "Analisis Kata Gas",
            total: "Total",
            muchModalAnalysis: "Ada terlalu banyak kata dengan semangat",
            normal: "Normal",
            speedAnalysis: "Analisis kecepatan bicara",
            speedDistribution: "Distribusi kecepatan bicara",
            speakingSpeed: "Kecepatan bicara",
            normalSpeak: "Kecepatan bicara normal",
            tooFastSpeak: "Berbahasa terlalu cepat",
            tooSlowlySpeak: "Terlalu lambat",
            violationWord: "Kata-kata pelanggaran",
            violationWordAnalysis: "Analisis kata-kata ilegal",
            noData: "Tidak ada",
            emotionalScore: "Skor emosi",
            emotionalDistribution: "Distribusi emosional",
            negativeEmotion: "Emosi negatif",
            normalEmotion: "Suasana hati normal",
            getDetail: "Lihat detail",
            barPattern: "Tampilan diagram batang",
            timeDimension: "Tampilan Lintang Waktu",
            timesDimension: "Tampilan Dimensi Jumlah Kali",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Skor rata-rata",
                  averageScoreOfScript: "Rata-rata bicara",
                  averageEmotionalScore: "Rata-rata emosi",
                  expressionAverageScore: "Ekspresi skor rata-rata",
                  processAverageScore: "Rata-rata proses",
                  accumulatedDuration: "Durasi kumulatif",
                  accumulatedTimes: "Jumlah kumulatif",
                  participationComplianceRate:
                    "Partisipasi dalam tingkat kepatuhan",
                  participationRateForAchievingExcellence:
                    "Partisipasi dalam mencapai tingkat superior",
                  branch: "Poin",
                  numberOfPeople: "Jumlah orang",
                  averageScoreOfExpression: "Ekspresi skor rata-rata",
                  people: "Orang",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Emosi negatif" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Rata-rata skor bicara",
                  branch: "Poin",
                },
                useSummaryDetail: { keyHitRate: "Titik hit" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Ulasan cerdas",
            pleaseImproveTheConfiguration: "Silakan menyempurnakan konfigurasi",
            examResults: "Hasil ujian",
            showTheResultsAfterEachExam: "Tampilkan hasil setelah setiap ujian",
            showTheResultsAfterTheLastExam: "Hasil ujian akhir ditunjukkan",
            doNotDisplayResults: "Tidak menunjukkan hasil",
            resultDisplay: "Hasil Tampilkan",
            score: "Skor",
            scoringResults: "Hasil Skor",
            allowViewingScoreDetails: "Izinkan untuk melihat rincian skor",
            viewCanvasConfiguration: "Lihat konfigurasi kanvas",
            synchronizationFailedProcessNodeHasChanged:
              "Sinkronisasi gagal, node proses telah berubah",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Sinkronisasi berhasil, silakan klik posting untuk memperbarui kursus",
            retrievedTheLatestCanvasConfiguration:
              "Konfigurasi kanvas terbaru telah ditarik",
            synchronizeCanvasConfiguration: "Konfigurasi kanvas sinkron",
            scoringMethod: "Metode penilaian",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Poin, proses {0} poin, bicara {1} poin, emosi {2} poin, kecepatan bicara {3} poin)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Standar yang sangat baik harus lebih tinggi dari standar",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Kriteria penilaian harus memenuhi lebih dari 0%, kurang dari sama dengan 100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Kriteria penilaian harus memenuhi lebih dari 0 poin, kurang dari atau sama dengan {0} poin",
            doRatingsRequireManualReview:
              "Apakah penilaian perlu ditinjau secara manual",
            allocationStrategy: "Strategi distribusi",
            pleaseSelectTheAssignedPersonnel:
              "Silakan pilih orang yang ditugaskan",
            pleaseSelectADesignatedPerson: "Silakan pilih orang yang ditunjuk",
            timeLimitForSingleQuestionAnswering:
              "Batas waktu untuk menjawab pertanyaan tunggal",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Rilis default ke karyawan baru yang ditambahkan",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Penilaian ulang menunjukkan bahwa itu akan dicocokkan sesuai dengan aturan penilaian kanvas terbaru. Tugas saat ini memiliki data penilaian yang ditinjau secara manual.",
            notCovered: "Tidak tertutup",
            cover: "Meliputi",
            participationSituation: "Partisipasi",
            numberOfParticipants: "Jumlah peserta",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Jumlah objek latihan yang dipilih untuk tugas ini",
            sumOfTheNumberOfParticipantsInTheTask:
              "Jumlah orang yang terlibat dalam misi",
            numberOfCompletedPersonnel: "Jumlah Lengkap",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Jumlah orang yang memenuhi persyaratan penyelesaian tugas",
            numberOfQualifiedPersonnel: "Jumlah orang yang memenuhi standar",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Jumlah orang yang memenuhi skor tugas",
            excellentPersonnel: "Jumlah yang sangat baik",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Jumlah orang yang memenuhi skor tugas yang sangat baik",
            participationRate: "Tingkat partisipasi",
            numberOfParticipantsNumberOfParticipants:
              "Jumlah peserta/jumlah peserta",
            numberOfCompletedNumberOfParticipants:
              "Jumlah orang yang selesai/harus berpartisipasi",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Jumlah orang yang memenuhi standar/harus berpartisipasi",
            excellentNumbernumberOfParticipants:
              "Jumlah/Partisipasi Luar Biasa",
            participationCompletionRate: "Tingkat partisipasi",
            numberOfCompletedParticipated: "Jumlah/Partisipasi Lengkap",
            participationComplianceRate: "Partisipasi dalam tingkat kepatuhan",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Jumlah orang yang memenuhi standar/peserta",
            participationRateForAchievingExcellence:
              "Partisipasi dalam mencapai tingkat superior",
            accumulatedDuration: "Durasi kumulatif",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Siswa mencari perdamaian ketika mereka berpartisipasi dalam tugas",
            averageDuration: "Durasi rata-rata",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Waktu partisipasi peserta pelatihan dalam tugas",
            accumulatedTimes: "Jumlah kumulatif",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Jumlah partisipasi siswa dalam tugas",
            averageScore: "Skor rata-rata",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Skor rata-rata orang yang terlibat dalam misi ini",
            maximumScore: "Skor tertinggi",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Skor tertinggi untuk personel yang terlibat dalam misi ini",
            averageScoreOfScript: "Rata-rata bicara",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Skor bicara siswa",
            totalScoreOfScript: "Total skor bicara",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Atur skor total kata di bawah tugas ini",
            processAverageScore: "Rata-rata proses",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Skor Proses Siswa Menjumlahkan/Total Partisipasi",
            totalProcessScore: "Total skor proses",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Nilai total proses yang ditetapkan di bawah tugas ini",
            averageEmotionalScore: "Rata-rata emosi",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Skor emosional siswa",
            totalEmotionalScore: "Skor emosi total",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Skor total emosi yang ditetapkan di bawah tugas ini",
            averageScoreOfExpression: "Ekspresi skor rata-rata",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Skor ekspresi siswa",
            totalScoreOfExpression: "Ekspresikan skor total",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Skor total ekspresi diatur di bawah tugas ini",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Mulai melakukan penilaian ulang, Anda dapat melompat ke pusat tugas untuk melihat kemajuan",
            reRating: "Skor ulang",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Skor ulang menunjukkan bahwa mereka akan dicocokkan sesuai dengan aturan skor kanvas terbaru dan akan menutupi hasil skor saat ini",
            releaseTime: "Waktu rilis",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Diaudit",
              pendingReview: "Untuk ditinjau",
              enterKeywordSearch: "Masukkan kata kunci pencarian",
            },
            pageRightTable: {
              index: {
                employeeName: "Nama karyawan",
                systemRating: "Penilaian sistem",
                scoringResults: "Hasil Skor",
                duration: "Durasi",
                time: "Waktu",
                state: "Status",
                whetherToModifyTheRating: "Apakah Skor Koreksi",
                finalScore: "Skor akhir",
                reviewedBy: "Auditor",
                operation: "Operasi",
                viewScoreDetails: "Lihat rincian skor",
                viewStatus: "Melihat status",
                whole: "Semua",
                correctionStatus: "Status koreksi",
                completeReview: "Menyelesaikan audit",
                tips: "Tips",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Hasil akhir siswa didasarkan pada hasil tinjauan akhir saat ini, harap konfirmasi",
              },
            },
          },
          index: { manualReview: "Audit manual" },
        },
        review: {
          index: {
            employeeName: "Nama karyawan",
            score: "Skor",
            scoringResults: "Hasil Skor",
            duration: "Durasi",
            taskName: "Nama tugas",
            numberOfAppealScripts: "Jumlah banding",
            appealTime: "Waktu banding",
            processStatus: "Status Proses",
            scoreAfterReview: "Skor setelah ulasan",
            reviewedBy: "Pengulas",
            operation: "Operasi",
            viewScoreStatus: "Melihat skor",
            whole: "Semua",
            manualReview: "Pemeriksaan manual",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Data tugas latihan",
            implementationRate: "Tingkat eksekusi",
            numberOfExecutors: "Jumlah eksekusi",
            numberOfPeopleNotExecuted: "Jumlah yang Tidak Dieksekusi",
            completionRate: "Tingkat penyelesaian",
            numberOfCompletedPersonnel: "Jumlah Lengkap",
            numberOfUnfinishedPersonnel: "Jumlah yang belum selesai",
            complianceRate: "Tingkat kepatuhan",
            numberOfQualifiedPersonnel: "Jumlah orang yang memenuhi standar",
            numberOfNonCompliantIndividuals:
              "Jumlah orang yang tidak memenuhi standar",
            excellentRate: "Tingkat keuntungan",
            numberOfOutstandingIndividuals: "Jumlah yang sangat baik",
            numberOfNonOutstandingIndividuals: "Jumlah yang tidak baik",
            examTaskData: "Data tugas ujian",
            published: "Dirilis",
            courses: "Kursus",
            courseData: "Data kursus",
            practiceTasks: "Tugas latihan",
            examTasks: "Tugas ujian",
          },
          practiceSummary: {
            practiceSummary: "Ringkasan latihan",
            numberOfPracticeTasks: "Jumlah tugas latihan",
            numberOfExercises: "Jumlah latihan",
            accumulatedPracticeDuration: "Durasi latihan kumulatif",
            complianceRate: "Tingkat kepatuhan",
            excellentRate: "Tingkat keuntungan",
            ranking: "Peringkat",
          },
          rankingList: {
            numberOfExercises: "Jumlah latihan",
            exerciseDuration: "Durasi latihan",
            complianceRate: "Tingkat kepatuhan",
            excellentRate: "Tingkat keuntungan",
            diligenceChart: "Daftar Rajin",
            excellentList: "Daftar Luar Biasa",
            ranking: "Peringkat",
            fullName: "Nama",
            i: "Saya",
          },
          taskCard: {
            end: "Tenggat:",
            noDeadline: "Tidak ada tenggat waktu",
            second: "Kali",
            branch: "Poin",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Tidak ada misi",
            completed: "Selesai",
          },
          filter: { employeeDepartment: "Departemen karyawan" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Ekspor berhasil, silakan buka pusat tugas untuk melihat",
              export: "Ekspor",
            },
          },
          filterConfig: {
            dropdown: { custom: "Kustom" },
            modal: {
              updateSuccessful: "Pembaruan berhasil",
              successfullySaved: "Simpan Sukses",
              setAsACommonReport: "Ditetapkan sebagai laporan umum",
              updateCurrentReport: "Memperbarui laporan saat ini",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Skor rata-rata",
                  averageScoreOfScript: "Rata-rata skor bicara",
                  averageEmotionalScore: "Skor emosi rata-rata",
                  expressionAverageScore: "Ekspresi skor rata-rata",
                  processAverageScore: "Skor rata-rata proses",
                  branch: "Poin",
                },
              },
              comprehensiveEvaluation: { const: { data: "Data" } },
              doneStatus: {
                index: {
                  practice: "Latihan",
                  examination: "Ujian",
                  executed: "Dieksekusi",
                  unexecuted: "Tidak dieksekusi",
                  accumulatedExamDuration: "Akumulasi durasi ujian",
                  examFrequency: "Jumlah ujian",
                },
              },
              filter: {
                index: {
                  practice: "Latihan",
                  examination: "Ujian",
                  pleaseCompleteTheRequiredFields:
                    "Silakan menyempurnakan item yang diperlukan",
                  staff: "Karyawan",
                  taskType: "Jenis tugas",
                  pleaseSelect: "Silakan pilih",
                  taskName: "Nama tugas",
                },
              },
            },
            index: {
              completionStatus: "Penyelesaian",
              comprehensiveEvaluation: "Evaluasi komprehensif",
              abilityGrowthTrend: "Tren pertumbuhan kapasitas",
              errorProneAnalysis: "Analisis titik rawan kesalahan",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Jumlah aktif",
            accumulatedPracticeDuration: "Durasi latihan kumulatif",
            averagePracticeDuration: "Durasi latihan rata-rata",
            people: "Orang",
            numberOfPracticeTasks: "Jumlah tugas latihan",
            numberOfExercises: "Jumlah latihan",
            exerciseDuration: "Durasi latihan",
            second: "Kali",
            avgScore: "Skor rata-rata",
            speechCraftAvgScore: "Rata-rata skor bicara",
            emotionAvgScore: "Skor emosi rata-rata",
            speedAvgScore: "Ekspresi skor rata-rata",
            flowAvgScore: "Skor rata-rata proses",
            practiceCostTime: "Durasi kumulatif",
            totalPracticeTimes: "Jumlah kumulatif",
            participateCommitRate: "Partisipasi dalam tingkat kepatuhan",
            participateExcellentRate:
              "Partisipasi dalam mencapai tingkat superior",
          },
        },
        index: {
          employeeProfile: "Potret karyawan",
          overview: "Ikhtisar",
          personalSignage: "Kanban pribadi",
          ongoingExercises: "Latihan yang sedang berlangsung",
          ongoingExams: "Ujian yang sedang berlangsung",
          practiceTaskProgress: "Latihan kemajuan tugas",
          examTaskProgress: "Kemajuan tugas ujian",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Silakan pilih kata konsep",
              sessionDetectionSettings: "Pengaturan deteksi sesi",
              successfullySaved: "Simpan Sukses",
              abnormalSpeechSpeed: "Kecepatan bicara abnormal",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Mendukung standar deteksi kecepatan bicara khusus, hasilnya akan dinilai sebagai kecepatan bicara normal, kecepatan bicara terlalu cepat, dan kecepatan bicara terlalu lambat. Ketika kecepatan bicara tidak normal (terlalu cepat/terlalu lambat), poin akan dikurangi sesuai dengan aturan penilaian",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Kecepatan bicara cepat: lebih besar dari setiap menit",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Kata, bicara lambat: kurang dari per menit",
              charactersIgnoringLessThan: "Kata, abaikan kurang dari",
              aSentenceOfWords: "Kalimat kata",
              excessiveModalParticles: "Kata-kata yang berlebihan",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Mendukung kata-kata gaya kustom dan menetapkan aturan untuk kata-kata gaya yang berlebihan. Ketika ada terlalu banyak kata gas, poin akan dikurangi sesuai dengan aturan penilaian",
              theNumberOfRepetitionsIs: "Jumlah pengulangan adalah",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "Pada saat berikutnya, dinilai bahwa ada terlalu banyak kata",
              definitionOfSensitiveViolations: "Definisi pelanggaran sensitif",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Mendukung tesaurus pelanggaran khusus untuk mendeteksi apakah kata-kata pelanggaran muncul dalam kalimat. Ketika kata-kata pelanggaran muncul, poin akan dikurangi sesuai dengan aturan penilaian",
              violationWords: "Kata-kata pelanggaran:",
              emotionalDetection: "Deteksi emosi",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Tes emosi dilakukan sesuai dengan dialog model emosi sistem, dan hasilnya akan dinilai sebagai emosi normal dan emosi negatif (termasuk negatif, marah, mengeluh). Ketika emosi negatif terjadi, poin akan dikurangi sesuai dengan aturan penilaian",
              preserve: "Simpan",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Dimensi penilaian",
              ratingPoints: "Poin penilaian",
              explain: "Deskripsi",
              type: "Jenis",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Data acak" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Akumulasi bicara standar',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Kata, siswa mengikuti jumlah kata yang dipukul",
                  aWordspan: "Kata </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Kedua </span><br /><span style = "margin-kiri: 8px">· berbicara terlalu lambat',
                  timesspan: "Kali </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Terlalu banyak kata gas',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Skor adalah" },
                  item: {
                    violationOnceReduced: "1 pengurangan pelanggaran",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Kecepatan bicara yang tidak normal (termasuk kecepatan bicara yang terlalu cepat dan kecepatan bicara yang terlalu lambat)",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Terlalu banyak kata gas",
                    oneNegativeDecreaseInEmotions: "Penurunan emosional",
                    branch: "Poin",
                  },
                  wrap: { name: "Nama", preserve: "Simpan" },
                },
              },
              index: {
                complete: "Konten lengkap",
                keyHit: "Titik hit",
                accurateFollowupReading: "Ikuti dengan akurat",
                languageNorm: "Spesifikasi bahasa",
                speechSpeed: "Kecepatan bicara",
                modalParticles: "Kata-kata",
                emotionallyPositive: "Emosi positif",
                scoringModel: "Model penilaian",
                successfullyModified: "Berhasil dimodifikasi",
                newSuccessfullyCreated: "Sukses baru",
              },
            },
            index: {
              scoringModel: "Model penilaian",
              newModel: "Model baru",
              model: "Model",
              type: "Jenis",
              operation: "Operasi",
              preview: "Pratinjau",
              edit: "Mengedit",
            },
          },
          index: {
            scoringModel: "Model penilaian",
            ratingDimension: "Dimensi penilaian",
            sessionDetectionSettings: "Pengaturan deteksi sesi",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Silakan pilih",
                hit: "Hit",
                misses: "Terpukul",
              },
            },
          },
          index: {
            duration: "Durasi",
            processScore: "Skor proses",
            branch: "Poin",
            contentCompleteness: "Integritas konten",
            scriptScore: "Skor bicara",
            keyHitRate: "Titik hit",
            emotionalScore: "Skor emosi",
            negativeProportionOfEmotions: "Proporsi negatif emosional",
            expressionScore: "Skor ekspresi",
            theProportionOfAbnormalSpeechSpeed:
              "Proporsi kecepatan bicara yang tidak normal",
            excessiveProportionOfModalParticles: "Terlalu banyak bicara",
            learningRecordData: "Data Catatan Pembelajaran",
            staff: "Karyawan",
            curriculum: "Kursus",
            mainPoints: "Poin penting",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Formulir",
                        lineChart: "Diagram garis",
                        barChart: "Gambar Kolom",
                        pieChart: "Diagram kue",
                      },
                      index: { switchingTypes: "Tipe Beralih" },
                    },
                  },
                  index: {
                    saveSuccessful: "Simpan Sukses",
                    editChart: "Mengedit bagan",
                    chartName: "Nama bagan",
                    deleteSuccessful: "Hapus Sukses",
                    refresh: "Menyegarkan",
                    export: "Ekspor",
                    areYouSureToDeleteIt:
                      "Apakah Anda yakin untuk menghapusnya?",
                    delete: "Hapus",
                    viewFilteringCriteria: "Melihat filter",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Ekspor berhasil, silakan buka pusat tugas untuk melihat",
              },
            },
            header: {
              index: {
                saveSuccessful: "Simpan Sukses",
                deleteSuccessful: "Hapus Sukses",
                editReport: "Mengedit laporan",
                reportName: "Nama laporan",
                refresh: "Menyegarkan",
                areYouSureToDeleteIt: "Apakah Anda yakin untuk menghapusnya?",
                delete: "Hapus",
                exportToExcel: "Ekspor Excel",
                exportSnapshot: "Snapshot ekspor",
                export: "Ekspor",
                authority: "Izin",
                permissionSettings: "Pengaturan izin",
                reportVisiblePermissions: "Izin terlihat untuk laporan",
                visibleToEveryone: "Semua terlihat",
                onlyVisibleToOneself: "Hanya bisa dilihat sendiri",
                designatedPersonnelVisible:
                  "Terlihat oleh personel yang ditunjuk",
                pleaseSelect: "Silakan pilih",
                reportDataPermissions: "Izin data laporan",
                restrictedByUserPermissions: "Terbatas oleh izin pengguna",
                visiblePeopleAreTheSame: "Terlihat orang yang sama",
              },
            },
            titleFooter: {
              index: {
                addReport: "Menambahkan laporan",
                reportName: "Nama laporan",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Ekspor berhasil, silakan buka pusat tugas untuk melihat",
            dataQueryInProgress: "Kueri data...",
            customKanban: "Kanban kustom",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Formulir",
                lineChart: "Diagram garis",
                barChart: "Gambar Kolom",
                pieChart: "Diagram kue",
              },
              index: {
                successfullyAdded: "Tambahkan Sukses",
                viewSQL: "Lihat sql",
                export: "Ekspor",
                addToReport: "Tambahkan ke laporan",
              },
            },
            refer: {
              hooks: {
                data: "Data",
                interrogativeSentence: "Pertanyaan",
                historicalQuery: "Pertanyaan sejarah",
              },
              index: { copySuccessful: "Menyalin berhasil", copy: "Menyalin" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Tidak memenuhi standar",
                        meetingStandards: "Standar",
                        excellent: "Luar biasa",
                        notStartedYet: "Belum dimulai",
                        inProgress: "Dalam proses",
                        completed: "Selesai",
                        incomplete: "Belum selesai",
                      },
                    },
                  },
                  const: {
                    taskName: "Nama tugas",
                    department: "Departemen",
                    studentName: "Nama siswa",
                    scoringResults: "Hasil Skor",
                    taskCompletionStatus: "Status penyelesaian tugas",
                  },
                  index: { pleaseSelectADimension: "Silakan pilih dimensi" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Masukkan @ untuk membuka bom untuk pemilihan dimensi",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Silakan masukkan pernyataan pertanyaan untuk pertanyaan secara langsung",
                query: "Pertanyaan",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Ekspor berhasil, silakan buka pusat tugas untuk melihat",
            dataQueryInProgress: "Kueri data...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Pencarian kata kunci",
                previous: "Sebelumnya",
                next: "Berikutnya",
                theObjectThatTriggersTheScrollingEventIs:
                  "Objek yang memicu peristiwa bergulir adalah",
                userScrolling: "Pengguna bergulir",
              },
            },
            summary: {
              index: {
                reminder: "Prompt:",
                edit: "Mengedit",
                name: "Nama",
                hintLanguage: "Pesan",
              },
            },
            viewpoint: { index: { viewpointExtraction: "Ekstraksi opini" } },
            outline: {
              index: {
                numberOfKeywords: "Jumlah kata kunci",
                speaker: "Pembicara",
                speechDuration: "Waktu bicara",
                proportionOfSpeaking: "Berbicara",
                maximumSpeechDuration: "Durasi bicara terpanjang",
                numberOfQuestions: "Jumlah pertanyaan",
                averageSpeechSpeed: "Kecepatan bicara rata-rata",
                conversationalActions: "Aksi sesi",
                contentSummary: "Ringkasan konten",
                generateSummary: "Menghasilkan ringkasan",
                keywordCloud: "Kata kunci cloud kata",
              },
            },
          },
          index: {
            outline: "Ringkasan",
            viewpoint: "Opini",
            contentSummary: "Ringkasan konten",
            viewpointExtraction: "Ekstraksi opini",
            summaryOfKeyPoints: "Ringkasan poin",
            videoAnalysis: "Analisis Video",
          },
        },
        list: {
          index: {
            delete: "Hapus",
            uploadSuccessful: "Berhasil diunggah",
            videoList: "Daftar video",
            uploadVideo: "Unggah video",
            videoName: "Nama Video",
            uploadTime: "Mengunggah waktu",
            endTime: "Waktu akhir",
            analyzeProgress: "Analisis kemajuan",
            haveInHand: "Dalam proses",
            completed: "Selesai",
            fail: "Gagal",
            toBegin: "Untuk memulai",
            operation: "Operasi",
            see: "Lihat",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Menambahkan jam kerja" },
        },
      },
      index: {
        businessWorkingHours: "Jam kerja bisnis",
        newlyBuild: "Baru dibangun",
        name: "Nama",
        describe: "Deskripsi",
        operation: "Operasi",
        edit: "Mengedit",
        delete: "Hapus",
        editBusinessWorkingHours: "Mengedit jam kerja bisnis",
        newBusinessWorkingHours: "Jam kerja bisnis baru",
        workingHours: "Jam kerja",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Ekstraksi cerdas",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Apakah Anda mengkonfirmasi bahwa Anda ingin membatalkan? Tidak dapat dipulihkan setelah pembatalan",
        },
      },
    },
    accountManagement: {
      securitySetting: "Pengaturan keamanan",
      accountManagement: "Manajemen Akun",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Untuk",
            switchToIPAddressMode: "Beralih ke mode alamat IP",
            switchToIPSegmentMode: "Beralih ke modus segmen IP",
          },
        },
      },
      index: {
        saveSuccessful: "Simpan Sukses",
        pleaseImproveTheConfiguration: "Silakan menyempurnakan konfigurasi",
        securitySetting: "Pengaturan keamanan",
        employeeLoginIPRestrictions: "Batasan IP Login Karyawan",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Batasi rentang IP yang dapat diakses karyawan, dan tidak diperbolehkan masuk di luar jangkauan. Hanya berlaku untuk situs web, aplikasi seluler tidak dibatasi, administrator tidak dibatasi.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Saat menambahkan atau memodifikasi, silakan isi segmen IP jaringan eksternal (segmen C yang sama) atau alamat IP jaringan eksternal tetap.",
        allowLoginIPRange: "Mengizinkan rentang IP untuk login",
        preserve: "Simpan",
      },
    },
    coachAdminEntry: { speechRecognition: "Pengenalan suara" },
    billingCenter: {
      template: {
        details: "Detail",
        languageType: "Jenis Bahasa",
        aSREngine: "Mesin ASR",
        voiceQualityQuota: "Kuota pemeriksaan kualitas suara",
        realtimeData: "Data real-time",
        calculatedByDay: "Dihitung berdasarkan hari",
      },
      component: {
        aSRName: "Nama ASR",
        language: "Bahasa",
        remainingQuota: "Kuota yang tersisa",
        hours: "Jam",
        usedQuota: "Kuota yang digunakan",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Operasi berhasil disimpan!",
        deletedSuccessfully: "Hapus Sukses!",
        pleaseSelectLanguage: "Silakan pilih bahasa",
        pleaseConfigureRules: "Silahkan konfigurasi aturan",
        updateSuccessful: "Pembaruan berhasil!",
        operationSuccessful: "Operasi berhasil!",
      },
      template: {
        aSRLanguage: "Bahasa ASR",
        pleaseSelect: "Silakan pilih",
        model: "Model",
        pleaseSelectLanguage: "Silakan pilih bahasa",
        delete: "Hapus",
        newASRModel: "Model ASR baru",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Analisis produk kompetitif" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Filter ulasan e-commerce",
        allComments: "Semua Komentar",
        ecommerceReviewManagement: "Manajemen ulasan e-commerce",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Filter Komentar Media Sosial",
        allComments: "Semua Komentar",
        socialMediaReviewManagement: "Manajemen Ulasan Media Sosial",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Analisis produk kompetitif",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Nama",
        nickname: "Nama panggilan",
        name: "Nama",
        email: "Akun (email)",
        mobile: "Telepon",
        agentId: "Nomor kerja",
        position: "Pos",
        role: "Karakter",
        actions: "Operasi",
        createUser: "Tambahkan karyawan baru",
        editUser: "Mengedit karyawan",
        account: "Akun",
        originPassword: "Kata sandi awal",
        newPassword: "Kata sandi baru",
        resumePassword: "Konfirmasi kata sandi",
        userGroup: "Kelompok karyawan",
        authorizationGroup: "Grup Izin",
        resetPassword: "Atur ulang kata sandi",
      },
      userGroups: {
        id: "ID",
        name: "Nama",
        userCount: "Jumlah karyawan",
        actions: "Operasi",
        createUserGroup: "Menambahkan kelompok karyawan",
        editUserGroup: "Mengedit grup karyawan",
        member: "Anggota",
      },
      role: {
        id: "ID",
        name: "Nama",
        actions: "Operasi",
        all: "Semua",
        taskName: "Misi",
        readTask: "Lihat tugas",
        manageTemplate: "Manajemen Template",
        manualInspection: "Pemeriksaan ulang manual",
        readResultAll: "Hasil Lihat",
        readResultPersonal: "Hasil View (Pribadi)",
        readProcess: "Lihat proses pemeriksaan kualitas",
        readReport: "Statistik dan Laporan",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Tanggal",
        taskName: "Nama",
        size: "Ukuran",
        status: "Status",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Nama",
            remark: "Deskripsi",
            actions: "Operasi",
          },
          checkPoint: {
            name: "Nama",
            remark: "Deskripsi",
            type: "Jenis",
            gradeType: "Metode evaluasi",
            conditionLogic: "Aturan",
            predeterminedScore: "Skor",
            weight: "Berat",
            status: "Status",
            actions: "Operasi",
            switchOn: "Aktifkan",
            switchOff: "Nonaktifkan",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Waktu",
            customer: "Pelanggan",
            customerPhone: "Telepon pelanggan",
            callType: "Jenis panggilan",
            source: "Sumber",
            voiceRecording: "Rekaman panggilan",
            graded: "Penilaian",
            openingGreeting: "Salam pembuka",
          },
          checkPointList: {
            name: "Titik pemeriksaan kualitas",
            type: "Jenis",
            deductScore: "Skor",
            isHit: "Situasi hit",
            status: "Ulasan",
            finalDeductScore: "Poin konfirmasi",
            hit: "Hit",
            notHit: "Terpukul",
          },
        },
      },
    },
    custom: { keywords: "Kata kunci percakapan" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Pengaturan sistem" },
      systemIntegration: {
        text: "Integrasi sistem",
        subTitle: {
          udesk: "Sistem Layanan Pelanggan Udesk",
          udeskWhite: "Sistem layanan pelanggan",
          wechat: "Perusahaan WeChat",
          taobao: "Taobao Qianniu",
          weifeng: "Ringan",
        },
      },
      columnsManage: { text: "Manajemen bidang pemeriksaan kualitas" },
      customerField: { text: "Bidang Pelanggan" },
      speechRecognition: { text: "Pengenalan suara" },
      user: { text: "Manajemen Karyawan" },
      userGroups: { text: "Manajemen Grup Karyawan" },
      role: { text: "Manajemen peran" },
      dataMigration: { text: "Migrasi data" },
      taskCenter: { text: "Pusat Misi" },
      recordUpload: { text: "Unggah rekaman lokal" },
      anomalousData: { text: "Data anomali" },
      systemLog: { text: "Log pesan" },
      msgCenter: { text: "Pusat pesan" },
      wechatDocking: { text: "Perusahaan mikro docking" },
    },
    tasks: {
      homePage: { text: "Rumah" },
      workbench: { text: "Meja kerja" },
      semanticIntelligence: { text: "Kecerdasan semantik" },
      intelligentAnalysis: { text: "Analisis cerdas" },
      intelligentLabel: { text: "Label pintar" },
      admin: { text: "Manajemen" },
      callManage: { text: "Manajemen panggilan" },
      dialogueManage: { text: "Manajemen percakapan" },
      ticketManage: { text: "Manajemen pesanan kerja" },
      dataAnalysis: { text: "Analisis data" },
      customerService: { text: "Pusat Layanan Pelanggan" },
      clientCenter: { text: "Pusat Pelanggan" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Pusat Pelatihan" },
      templates: { text: "Manajemen Template" },
      wechatQaTasks: { text: "Meja kerja perusahaan WeChat" },
      wechatQaSetting: { text: "Konfigurasi analisis WeChat perusahaan" },
      wechatQaChartRecord: { text: "Sesi WeChat Perusahaan" },
      wechatStaffCenter: { text: "Pusat Karyawan Mikro Perusahaan" },
      callAnalysis: { text: "Analisis sesi" },
      caseBaseFolder: { text: "Basis data kasus" },
      coachTask: { text: "Tugas pembinaan" },
      teamAnalysis: { text: "Analisis tim" },
      customer: { text: "Pusat Pelanggan" },
      analysisEnable: { text: "Menganalisis pemberdayaan" },
      analysisHelp: { text: "Analisis bantuan" },
      analysisStudy: { text: "Analisis pembelajaran" },
      analysisReview: { text: "Analisis ulasan" },
      analysisTags: { text: "Analisis label cerdas" },
      analysisSentiment: { text: "Analisis opini publik" },
      analysisBusiness: { text: "Analisis Bisnis" },
      groupTest: { text: "Tes kelompok" },
    },
    navBar: {
      billingCenter: "Pusat Bantuan",
      uploadDownload: "Unggah Pusat Unduhan",
      setting: "Pengaturan sistem",
    },
  },
  fix: {
    selectType: "Pilih jenis:",
    all: "Semua",
    call: "Panggilan telepon",
    text: "Percakapan",
    wechat: "Sesi WeChat Perusahaan",
    businessTrendAnalysis: "Analisis tren bisnis klasifikasi tingkat pertama",
    noEchart: "Tidak ada data untuk grafik",
    checkPointisRequired: "Konfigurasi aturan diperlukan",
    voiceDownLoad: "Mengunduh rekaman",
    exportTxt: "Mengekspor teks TXT",
    exportWord: "Mengekspor teks kata",
    sureReRole: "Konfirmasikan peran pembicara terbalik?",
    sureRecover: "Konfirmasikan koreksi ulang?",
    sureReAsr: "Konfirmasi untuk mengidentifikasi kembali?",
    reRole: "Pembicara terbalik",
    recover: "Koreksi ulang",
    reAsr: "Identifikasi ulang",
    recordingOperation: "Operasi rekaman",
    onlySave30Day: "(Hanya 30 hari)",
    appealError:
      "Silakan ajukan banding ke pos pemeriksaan kualitas sebelum mengirimkan",
    exportToTaskCenter: "Berhasil diekspor ke Pusat Unduhan Upload",
    caseBaseLimit: "Tidak lebih dari 50 basis data kasus",
    unnamed: "Tidak disebutkan namanya",
    wordSpeed: "Kata/menit",
    hit: "Hit",
    checkPoint: "Titik pemeriksaan kualitas",
    realTimeAdditionTip1:
      "Tambahkan secara real time: Mulai pemeriksaan kualitas segera setelah data memasuki sistem pemeriksaan kualitas",
    realTimeAdditionTip2:
      "Waktu menambahkan: Waktu menambahkan data secara otomatis untuk pemeriksaan kualitas",
    null: "Tidak ada",
    collcetedProps: "Bidang akuisisi",
    editingNameNow: "Ada nama yang sedang diedit",
    upToFourLevels: "Klasifikasi hingga empat tingkat",
    default: "Default",
    nameCantBeNull: "Nama tidak boleh kosong",
    editingNow: "Ada operasi yang sedang diedit",
    nameError: "Nama yang salah",
    nameMaxLength8: "Panjang maksimum nama adalah 8",
    callTime: "Waktu bicara",
    sentimentValue: "Nilai emosi pelanggan",
    agentSentimentValue: "Nilai emosi layanan pelanggan",
    sentimentAnalysis: "Analisis emosi",
    associatedData: "Data bersama jalan",
    notifyTheOwner: "Beri tahu pemilik grup",
    notifyTheEmployee: "Beri tahu karyawan",
    notifyTheOwnerTip:
      "Pemilik grup akan diberitahu ketika jenis percakapan adalah obrolan grup",
    customNotifyEmployee: "Karyawan pemberitahuan kustom",
    customNotifyEmployeeTip:
      "Pemicu adalah karyawan, dan karyawan yang diberi tahu informasi",
    clickToLearnMore: "Klik untuk tahu lebih banyak",
    repeatMarkingTip:
      "Catatan ini telah ditandai oleh titik inspeksi kualitas, tidak perlu mengulangi",
    successfulEvaluation: "Evaluasi keberhasilan",
    evaluationFailed: "Evaluasi gagal",
    qualityInspectionScore: "Skor pemeriksaan kualitas",
    semanticTags: "Label semantik",
    smartTags: "Label pintar",
    collect: "Mengumpulkan informasi",
    appealRecord: "Catatan keluhan",
    satisfy: "Puas",
    notSatisfy: "Tidak puas",
    appealPlaceHolder: "Silakan masukkan alasan banding",
    giveUp: "Menyerah",
    rejectReasonPlaceHolder: "Silakan masukkan alasan penolakan",
    reject: "Penolakan",
    changeRejectReason: "Modifikasi alasan penolakan",
    pass: "Melalui",
    revise: "Modifikasi",
    machineEvaluation: "Evaluasi mesin",
    poor: "Buruk",
    excellent: "Baik",
    moderate: "Umum",
    mustMarked: "Titik pemeriksaan kualitas ini harus ditandai",
    pleaseChoose: "Silakan pilih",
    manualEvaluation: "Evaluasi manual",
    qualityInspectionTemplate: "Template pemeriksaan kualitas yang berlaku",
    collectionTemplate: "Template akuisisi yang berlaku",
    keywordsWordCloud: "Kata kunci cloud kata",
    editCategoryLabel: "Mengedit label kategori",
    justCall: "Hanya Lihat Panggilan",
    justConversation: "Hanya Lihat Percakapan",
    batchOperation: "Operasi massal",
    customerServicesGroup: "Layanan Pelanggan",
    joinGroup: "Bergabung dengan grup",
    removeGroup: "Grup pemindahan",
    enterInformationEntity: "Silakan masukkan konten entitas informasi",
    enterNotBeNull: "Masukan tidak bisa kosong",
    operationGroup: "Kelompok operasi",
    customerServiceSelected: "Layanan Pelanggan Terpilih",
    callAndConversation: "Panggilan/Percakapan",
    conversation: "Percakapan",
    system: "Sistem",
    customize: "Kustom",
    effective: "Efektif",
    invalid: "Tidak valid",
    successfulAndToTaskCenter:
      "Berhasil dieksekusi, silakan pergi ke pusat misi untuk melihat",
    recalculationFailed: "Gagal menghitung ulang",
    selectRecalculatedIndicator:
      "Silakan pilih indikator yang akan dihitung ulang",
    source: "Silakan pilih sumber data",
    placeholder: "Silakan masukkan kata kunci",
    cumulative: "Jenis kumulatif",
    average: "Tipe rata-rata",
    distribution: "Hukum distribusi",
    originalDataCannotEmpty: "Data mentah tidak bisa kosong",
    inspection: "Pemeriksaan kualitas",
    check: "Inspeksi acak",
    review: "Ulasan",
    draftSavedSuccessfully: "Draft berhasil disimpan",
    optimization: "Dioptimalkan",
    agentName: "Nama Layanan Pelanggan",
    allTasks: "Semua tugas pemeriksaan kualitas",
    datePickerSelect: "Memilih tanggal",
    callSelect: "Pilih percakapan",
    taskSelect: "Memilih tugas",
    genReport: "Menghasilkan laporan",
    dataSource: "Sumber data",
    callsTotal: "Jumlah total panggilan",
    intelligentQualityInspection: "Pemeriksaan kualitas yang cerdas",
    percentage: "Proporsi",
    artificialSampling: "Inspeksi manual",
    appealNumber: "Jumlah keluhan",
    totalConversation: "Total dialog",
    samplingReviewRate: "Tingkat pemeriksaan ulang",
    data: "Data",
    inspectionResults: "Hasil pemeriksaan kualitas",
    inspected: "Inspeksi kualitas",
    totalScore: "Total skor",
    learnMore: "Lihat lebih",
    inspectionEffect: "Efek pemeriksaan kualitas",
    allTotal: "Jumlah total",
    manualInspection: "Pemeriksaan ulang manual",
    artificialReview: "Pemeriksaan manual",
    approvalAmountAndRate: "Volume yang disetujui & tingkat persetujuan",
    customerScore: "Skor layanan pelanggan",
    inspectionRecommendations: "Rekomendasi pemeriksaan kualitas",
    saveDraft: "Simpan draf",
    historicalAdvice: "Saran Sejarah",
    advicePlaceHolder: "Silakan masukkan saran...",
    upload: "Unggah file",
    customAdd: "Menambahkan Filter",
    advancedConditions: "Kondisi Lanjutan",
    keyWord: "Kata kunci",
    containsAll: "Berisi semua",
    containsAny: "Berisi apa pun",
    matchAny: "Cocokkan apa pun",
    matchAll: "Mencocokkan semua",
    smartTagsOne: "Label pintar satu",
    smartTagsTwo: "Label pintar dua",
    season: "Musim",
    month: "Bulan",
    week: "Minggu",
    day: "Hari",
    hour: "Waktu",
    notMoreThan366: "Waktu tidak dapat melebihi 366 hari",
    propsCannotBeNull: "Ada bidang yang tidak diisi",
    other: "Lainnya",
    correlationCoefficient: "Koefisien korelasi",
    repetitionRate: "Tingkat pengulangan",
    configurationItemsMoreThan20: "Tambahkan hingga 20 item konfigurasi",
    conversionAnalysis: "Analisis konversi",
    correlationAnalysis: "Analisis relevansi",
    successfulExportAndToTaskCenter:
      "Ekspor berhasil, silakan buka pusat tugas untuk melihat",
    typeSelect: "Pilih Jenis",
    exportExcel: "Ekspor excel",
    exportPng: "Ekspor png",
    noDataChart: "Tidak ada data untuk grafik",
    placeOperator: "Silakan pilih label pintar",
    placeAdvancedConditions: "Silakan pilih kondisi lanjutan",
    createAdvancedConditions: "Penyaringan lanjutan baru",
    moveUp: "Pindah ke atas",
    moveDown: "Geser ke bawah",
    moveLeft: "Geser ke kiri",
    moveRight: "Pergeseran ke kanan",
    statusChangeSuccess: "Modifikasi status berhasil",
    editSuccess: "Berhasil dimodifikasi",
    saveSuccess: "Simpan Sukses",
    firstPage: "Rumah",
    lastPage: "Halaman ekor",
    customerInfo: "Informasi pelanggan",
    type: "Jenis",
    NumberTypeErrorMessage:
      'Konten informasi bidang tipe digital hanya dapat berisi angka atau "."',
    taskStartPrompt: "Tugas membuka Tips",
    startConfirm:
      "Silahkan klik tombol konfirmasi untuk membuka tugas pemeriksaan kualitas",
    continueToSubmitWithoutEvaluation: "Lanjutkan pengajuan tanpa evaluasi",
    rejectTip:
      "Tidak ada operasi perubahan yang terdeteksi, apakah Anda mengkonfirmasi bahwa semua keluhan ditolak secara langsung?",
    unmarkedSelfLearningTip:
      "Anda memiliki titik pemeriksaan kualitas belajar mandiri yang tidak ditandai",
    markedSuccessfully: "Menandai kesuksesan",
    markingFailed: "Menandai kegagalan",
    exceedingLength: "Melebihi panjang",
    uncategorized: "Tidak Dikategorikan",
    addSuccess: "Tambahkan Sukses",
    deleteSuccess: "Hapus Sukses",
    featureName: "Nama elemen",
    informationEntity: "Entitas informasi",
    standardAnswer: "Jawaban standar",
    keyElementAnswer: "Elemen kunci menjawab",
    generalStatement: "Pernyataan umum (tidak perlu bertanya)",
    generalQuestionsAndConfirmation: "Pertanyaan umum dan konfirmasi",
    specialQuestionsAndAnswers: "Pertanyaan dan jawaban khusus",
    agent: "Layanan Pelanggan",
    customer: "Pelanggan",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Elemen kunci label semantik dan entitas informasi tidak dapat kosong pada saat yang sama",
    standardSentence: "Kalimat standar",
    acquaintance: "Kenalan",
    smartUePlaceholder: "Masukkan nama interaksi cerdas",
    typeSelectPlaceholder: "Silakan pilih jenis",
    keyElement: "Elemen kunci",
    reQuerySementicTags: "Memilih ulang label semantik",
    querySementicTags: "Memilih label semantik",
    QATestForm: "Formulir uji tanya jawab",
    selectNotBeNull: "Pilihan tidak bisa kosong",
    featureDetectionTarget: "Target Deteksi Elemen",
    categorized: "Klasifikasi",
    effectiveness: "Efektivitas",
    hideAdvancedSettings: "Menyembunyikan pengaturan lanjutan",
    showAdvancedSettings: "Menampilkan pengaturan lanjutan",
    generalStatementOnly: "Pernyataan umum",
    generalQuestions: "Pertanyaan umum",
    affirmativeExpression: "Ekspresi afirmatif",
    negativeIntention: "Niat Negatif",
    searchSementicTags: "Label semantik query",
    choiceQuestion: "Pertanyaan pilihan",
    selectiveAnswer: "Jawaban pilihan",
    sementicTagsPlaceholder: "Masukkan konten label semantik",
    openSpecialQuestion: "Pertanyaan khusus terbuka",
    openAnswer: "Jawaban terbuka",
    customQA: "Pertanyaan dan Jawaban Kustom",
    questions: "Pertanyaan",
    dataPlaceholder: "Silakan masukkan konten",
    reply: "Jawab",
    notFindSuitableLabel: "Tidak menemukan label yang tepat",
    addSemanticTag: "Tambahkan tab semantik baru",
    intelligentInteraction: "Interaksi Cerdas",
    matching: "Cocok",
    bout: "Kali",
    keywordMatch: "Pencocokan kata kunci",
    addFilterConditions: "Menambahkan filter",
    buttonStartCollectionTask:
      "Silahkan klik tombol OK untuk membuka tugas akuisisi",
    buttonStartTask:
      "Silakan klik tombol OK untuk membuka tugas pemeriksaan kualitas",
    ruleConfigurationIsRequired: "Konfigurasi aturan diperlukan",
    asrRunning: "Eksekusi ASR",
    errorCorrectionExecution: "Eksekusi koreksi kesalahan",
    emotionalVolumeRecognition: "Pengenalan Volume Emosional",
    dataPreprocessing: "Preprocessing data",
    inspectionExecution: "Eksekusi pemeriksaan kualitas",
    businessId: "Bisnis Id",
    businessTime: "Waktu Bisnis",
    createTime: "Waktu pembuatan",
    abnormalReason: "Penyebab anomali",
    singleChat: "Obrolan tunggal",
    groupChat: "Obrolan grup",
    taskContent: "Isi tugas",
    submissionTime: "Waktu penyerahan",
    startTime: "Waktu mulai",
    endTime: "Waktu akhir",
    runningTime: "Menjalankan waktu",
    totalAmountOfData: "Total volume data",
    resultsOfThe: "Hasil eksekusi",
    operator: "Operator",
    taskDetails: "Rincian tugas",
    executiveFunction: "Fungsi Eksekusi",
    qualityCheckTasks: "Tugas pemeriksaan kualitas",
    implementationDetails: "Detail eksekusi",
    conditionalRules: "Aturan Kondisi",
    smartCondition: "Kondisi cerdas",
    goThis: "Pindah ke sini",
    pleaseMarkClassification: "Tolong tandai klasifikasi",
    advancedScreening: "Penyaringan lanjutan",
    noDataToManipulate: "Tidak ada data yang dapat dioperasikan",
    concept: "Kata konsep",
    checkPoint1: "Konfigurasi aturan",
    requiredFieldsCannotEmpty: "Item yang diperlukan tidak boleh kosong",
    rule: "Aturan",
    afterFixUnit: "Kata",
    unmarkedSelfLearningPointsTip:
      "Anda memiliki titik pemeriksaan kualitas belajar mandiri yang tidak ditandai",
    onlyReservedFor7Days: "(Hanya 7 hari)",
    downloadError: "Gagal mengunduh",
    pause: "Jeda",
    continue: "Lanjutkan",
    recalcScore: "Hanya menghitung ulang skor (termasuk peringkat)",
    recalculatePointsAndScores:
      "Hitung ulang poin dan skor pemeriksaan kualitas",
    recalculatePointsAndScoresWithEmtry:
      "Hitung ulang poin dan skor pemeriksaan kualitas (titik pemeriksaan kualitas kosong)",
    inProgress: "Dalam proses",
    toBeExecuted: "Untuk dieksekusi",
    executeImmediately: "Segera Dieksekusi",
    specialElements: "Elemen khusus",
    logicRule: "Aturan logika",
    every: "Beberapa pertandingan",
    hitRecall: "Hit Recall Kata",
    notHitRecall: "Kata-kata recall terjawab",
    hitRecallRegular: "Memukul aturan recall",
    notHitRecallRegular: "Tidak ada aturan",
    hitRule: "Aturan hit",
    notHitRule: "Aturan meleset",
    hitSimilarSentence: "Kalimat serupa",
    notHitSimilarSentence: "Kalimat serupa yang gagal",
    hitExcludeSimilarSentences: "Hit untuk mengecualikan kalimat yang sama",
    notHitExcludeSimilarSentences: "Hilangkan kalimat serupa",
    updateSuccess: "Pembaruan berhasil",
    qualify: "Reguler",
    recallKeyWord: "Kata kunci recall",
    grammar: "Aturan tata bahasa",
  },
  api: {
    putTypoDatasByIdStatusByStatus: "Perubahan validitas gagal, coba lagi",
    postAutoMateTasksCaseByTaskId:
      "Contoh kegagalan penyimpanan konfigurasi sinkronisasi perpustakaan",
    getAutoMateTasksCaseByTaskId:
      "Gagal mendapatkan konfigurasi sinkronisasi galeri kasus",
    postInspectionRemarkModelConfigs: "Templat Catatan Gagal Menyimpan",
    putInspectionRemarkModelConfigsById: "Templat Catatan Gagal Menyimpan",
    getInspectionRemarkModelConfigsById:
      "Gagal mendapatkan data template catatan",
    postKnowledgeDatas: "Basis pengetahuan baru gagal, silakan coba lagi",
    putKnowledgeDatasById:
      "Modifikasi basis pengetahuan gagal, silakan coba lagi",
    getLibraryFiles: "Gagal mendapatkan daftar klip teks",
    getLibraryCalls: "Gagal mendapatkan file",
    getLibraryFilesSearch: "Pencarian gagal",
    putLibraryFilesMoveById: "Kegagalan untuk memindahkan klip pertanyaan",
    putLibraryCallsById: "Penamaan file gagal!",
    postLibraryFiles: "Gagal menambahkan folder",
    putLibraryFilesById: "Penamaan folder gagal",
    deleteLibraryFilesById: "Gagal menghapus",
    deleteLibraryCallsById: "Gagal menghapus",
    postLibraryCalls: "Menambahkan kegagalan",
    getVoiceUploadResultById: "Hasil unggahan gagal diekspor",
  },
};
