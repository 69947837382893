import Locales from '../locales/index';
let fieldsOperatorString = [
    { id: 'is', key: 'is', name: () => Locales.current.enums.fieldsOperators.is },
    { id: 'is_null', key: 'is_null', name: () => Locales.current.enums.fieldsOperators.is_null },
    { id: 'head_equal', key: 'head_equal', name: () => Locales.current.enums.fieldsOperators.head_equal },
    { id: 'tail_equal', key: 'tail_equal', name: () => Locales.current.enums.fieldsOperators.tail_equal },
    { id: 'is_not_null', key: 'is_not_null', name: () => Locales.current.enums.fieldsOperators.is_not_null },
    { id: 'part_equal', key: 'part_equal', name: () => Locales.current.enums.fieldsOperators.part_equal }
];
let fieldsOperatorNumber = [
    { id: 'is', key: 'is', name: () => Locales.current.enums.fieldsOperators.is },
    { id: 'not', key: 'not', name: () => Locales.current.enums.fieldsOperators.not },
    { id: 'diff_equal', key: 'diff_equal', name: () => Locales.current.enums.fieldsOperators.diff_equal },
    {
        id: 'diff_greater_than',
        key: 'diff_greater_than',
        name: () => Locales.current.enums.fieldsOperators.diff_greater_than
    },
    {
        id: 'diff_greater_than_eq',
        key: 'diff_greater_than_eq',
        name: () => Locales.current.enums.fieldsOperators.diff_greater_than_eq
    },
    {
        id: 'diff_less_than',
        key: 'diff_less_than',
        name: () => Locales.current.enums.fieldsOperators.diff_less_than
    },
    {
        id: 'diff_less_than_eq',
        key: 'diff_less_than_eq',
        name: () => Locales.current.enums.fieldsOperators.diff_less_than_eq
    },
    { id: 'is_null', key: 'is_null', name: () => Locales.current.enums.fieldsOperators.is_null },
    { id: 'is_not_null', key: 'is_not_null', name: () => Locales.current.enums.fieldsOperators.is_not_null }
];
let fieldsOperatorDateTime = [
    { id: 'is', key: 'is', name: () => Locales.current.enums.fieldsOperators.is },
    { id: 'hour_equal', key: 'hour_equal', name: () => Locales.current.enums.fieldsOperators.hour_equal },
    { id: 'day_equal', key: 'day_equal', name: () => Locales.current.enums.fieldsOperators.day_equal },
    { id: 'week_equal', key: 'week_equal', name: () => Locales.current.enums.fieldsOperators.week_equal },
    { id: 'month_equal', key: 'month_equal', name: () => Locales.current.enums.fieldsOperators.month_equal },
    { id: 'year_equal', key: 'year_equal', name: () => Locales.current.enums.fieldsOperators.year_equal },
    {
        id: 'diff_greater_than',
        key: 'diff_greater_than',
        name: () => Locales.current.enums.fieldsOperators.diff_greater_than
    },
    {
        id: 'diff_greater_than_eq',
        key: 'diff_greater_than_eq',
        name: () => Locales.current.enums.fieldsOperators.diff_greater_than_eq
    },
    {
        id: 'diff_less_than',
        key: 'diff_less_than',
        name: () => Locales.current.enums.fieldsOperators.diff_less_than
    },
    {
        id: 'diff_less_than_eq',
        key: 'diff_less_than_eq',
        name: () => Locales.current.enums.fieldsOperators.diff_less_than_eq
    }
];

let segmentedData = [
    { id: 'is', key: 'is', name: () => Locales.current.enums.fieldsOperators.is },
    { id: 'part_equal', key: 'part_equal', name: () => Locales.current.enums.fieldsOperators.part_equal }
];

let connectedByConverstationSourcesVoiceCall = [
    { id: 1, key: 'voiceCall', name: () => Locales.current.enums.inspectDataSources.voiceCall }
];
let connectedByConverstationSourcesTextDialogue = [
    { id: 2, key: 'textDialogue', name: () => Locales.current.enums.inspectDataSources.textDialogue }
];
let connectedByConverstationSourcesRealTimeVoiceCall = [
    { id: 3, key: 'realTimeVoiceCall', name: () => Locales.current.enums.inspectDataSources.realTimeVoiceCall }
];
let connectedByConverstationSourcesrealTimeTextDialogue = [
    { id: 4, key: 'realTimeTextDialogue', name: () => Locales.current.enums.inspectDataSources.realTimeTextDialogue }
];
let dateTimeCompany = [
    {
        id: 'day',
        key: 'day',
        name: () => Locales.current.enums.fieldsOperators.day
    },
    {
        id: 'hour',
        key: 'hour',
        name: () => Locales.current.enums.fieldsOperators.hour
    },
    {
        id: 'minute',
        key: 'minute',
        name: () => Locales.current.enums.fieldsOperators.minute
    }
];
export default {
    fieldsOperatorString,
    fieldsOperatorNumber,
    fieldsOperatorDateTime,
    dateTimeCompany,
    segmentedData,
    connectedByConverstationSourcesVoiceCall,
    connectedByConverstationSourcesTextDialogue,
    connectedByConverstationSourcesRealTimeVoiceCall,
    connectedByConverstationSourcesrealTimeTextDialogue
};
