import React from 'react';
import XssComponent from './component';


export default class XssTemplate extends XssComponent {
    render() {
        let {
            classNames
        } = this.props;

        let {
            computes
        } = this.privates;

        return (
            <span className={classNames} dangerouslySetInnerHTML={{ __html: computes.content }}></span>
        );
    }
}