import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Udesk from '../../udesk/index';

class ReactTreeNodeText extends React.Component {
    //#region defaultProps
    static defaultProps = {
        node: {},
        nameField: "name",
        isShowNodeNum: false,
        numField: "num",
        click: null,
    }
    static propTypes = {
        node: PropTypes.object,
        nameField: PropTypes.string,
        isShowNodeNum: PropTypes.bool,
        numField: PropTypes.string,
        click: PropTypes.func

    }
    //#endregion

    actions = {
        click(node) {
            this.trigger("click", node);
        },
    }
}

export default ReactTreeNodeText;