import React from 'react';
import AdminUserGroupsDetailComponent from './component';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import { PageHeader } from 'udesk-ui';

export default class AdminUserGroupsDetailTemplate extends AdminUserGroupsDetailComponent {
    render() {
        let { actions, locales, privates } = this;
        return (
            // <div className="sub-page admin-user-groups-detail">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={privates.computes.isEditing ? locales.columns.admin.userGroups.editUserGroup : locales.columns.admin.userGroups.createUserGroup}
                    onBack={actions.userGroupsHistoryBack}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root admin-user-groups-detail' style={{ padding: 16 }}>
                        <div className="page-content">
                            <div className="x_panel clearfix custom-object-list">
                                <div className="x_content row clearfix">
                                    <div className="col-lg-8 col-md-8 col-xs-12 col-12 power">
                                        <form className="form-horizontal form-label-left">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="user_groups_name"
                                                    style={{ padding: "0 15px" }}
                                                    className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.userGroups.name}
                                                    <span className="required">*</span>
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={privates.model.userGroup.name}
                                                        id="user_groups_name"
                                                        className="form-control"
                                                        maxLength={500}
                                                        onChange={actions.onValueChanged.params(privates.model.userGroup, 'name')}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-2 col-xs-12 col-12"></div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.userGroups.member}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <AutoComplete
                                                        url={privates.computes._autoCompleteUrl}
                                                        value={privates.model.userGroup.userList}
                                                        method={"GET"}
                                                        queryParamName={"keyword"}
                                                        nameField="realname"
                                                        onChanged={actions.onValueChanged.params(privates.model.userGroup, "userList")}
                                                        enableEmptySearch={true}
                                                        isMulti={true}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-2 col-xs-12 col-12"></div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                                    <button type="button" style={{ marginRight: '5px' }} className="btn primary btn-md" onClick={actions.save}>
                                                        {locales.labels.save}
                                                    </button>
                                                    <button type="button" className="btn white btn-md" onClick={actions.userGroupsHistoryBack}>
                                                        {locales.labels.cancel}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
