import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Form, Button, Input, DatePicker, message, InputNumber } from 'udesk-ui';
import { useDetailSelectDataSource} from '../hook';
import {
    useRequest,
    EnumSelect, 
    EnumCheckbox, 
    MTMTreeSelect, 
    useLiveEffect, 
    array2string, 
    string2moment,
    string2IntArray, 
    moment2string
} from '../util';
import './index.scss';

const { Item } = Form;

const getRule4User = (name, message) => {
    return ({ getFieldValue }) => ({
        validator (_, groupUsers) {
            const otherUsers = getFieldValue(name);
        
            if (groupUsers && otherUsers) {
                const repeatUsers = groupUsers.filter((user) => {
                    return otherUsers.some((other) => other === user);
                });
                if (repeatUsers.length > 0) {
                    return Promise.reject(new Error(message));
                }
            }
            return Promise.resolve();
        }
    });
};

const GroupTestDetail = (props) => {
    const { 
        sdkOptions, history, match 
    } = props;
    const locales = Locales.current;
    const request = useRequest();

    const [aGroupUsers, setAGroupUsers] = useState([]);
    const [bGroupUsers, setBGroupUsers] = useState([]);
    
    const [form] = Form.useForm();
    const [
        userTreeData, 
        supervisionTreeData, 
        keyEventTreeData
    ] = useDetailSelectDataSource(request);

    const handlerBack = () => {
        const routeOptions = {
            history,
            routeName: 'groupTestAnalysis'
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    useLiveEffect(() => {
        const id = match.params.id ;
        if (id) {
            let url = `review/call/analysis/groupTest/${id}`;
            request(url).then((r) => {
                const values = r.data;
                const data = {
                    ...values,
                    monitorIndicatorCallAction: string2IntArray(values.monitorIndicatorCallAction),
                    monitorIndicatorCallActive: string2IntArray(values.monitorIndicatorCallActive),
                    monitorIndicatorKeyEvents: string2IntArray(values.monitorIndicatorKeyEvents),
                    monitorIndicatorSupervisions: string2IntArray(values.monitorIndicatorSupervisions),
                    monitorMembersGroupA: string2IntArray(values.monitorMembersGroupA),
                    monitorMembersGroupB: string2IntArray(values.monitorMembersGroupB),
                    monitorStartTime: string2moment(values.monitorStartTime),
                };
                form.setFieldsValue(data);
            });
        }
    }, [match.params.id]);

    const submit = (values) => {
        const id = match.params.id ;
        const params = {
            ...values,
            monitorIndicatorCallAction: array2string(values.monitorIndicatorCallAction),
            monitorIndicatorCallActive: array2string(values.monitorIndicatorCallActive),
            monitorIndicatorKeyEvents: array2string(values.monitorIndicatorKeyEvents),
            monitorIndicatorSupervisions: array2string(values.monitorIndicatorSupervisions),
            monitorMembersGroupA: array2string(values.monitorMembersGroupA),
            monitorMembersGroupB: array2string(values.monitorMembersGroupB),
            monitorStartTime: moment2string(values.monitorStartTime),
        };
        let method = 'post';
        let url = `review/call/analysis/groupTest`;

        if (id) {
            method = 'put';
            url = `review/call/analysis/groupTest/${id}`;
        }

        request(url, params, method).then((r) => {
            message.success(id ? /* 修改成功 */locales.pages.gong.groupTest.detail.template.modificationSucceeded : /* 创建成功 */locales.pages.gong.groupTest.detail.template.createdSuccessfully);
            handlerBack();
        }).catch(() => {
            message.error(id ? /* 修改失败 */locales.pages.gong.groupTest.detail.template.modificationFailed : /* 创建失败 */locales.pages.gong.groupTest.detail.template.creationFailed);
        });
    };

    const change = ({monitorMembersGroupA, monitorMembersGroupB}) => {
        if (monitorMembersGroupA) {
            setAGroupUsers(monitorMembersGroupA);
        }
        if (monitorMembersGroupB) {
            setBGroupUsers(monitorMembersGroupB);
        }
    };

    useEffect(() => {
        const showBack = sdkOptions &&  sdkOptions.methods &&  sdkOptions.methods.showBack;
        if(typeof showBack === "function"){
            showBack((privates) => {
                privates.showBack = true;
            });
            return () => {
                showBack((privates) => {
                    privates.showBack = true;
                });
            };
        }
    }, []);

    return (
        <Page
            onBack={handlerBack}
            pageBodyClassName='group-test-page-body detail'
            title={Locales.current.components.pages.dataAnalysis.groupTest}
            padding={true}
        >
            <div className='udesk-qa-group-test-detail'>
                <Form 
                    form={form} 
                    labelCol={{ span: 4 }} 
                    wrapperCol={{ span: 20 }} 
                    onFinish={submit}
                    onValuesChange={change}
                >
                    <Item 
                        name='monitorName' 
                        label={/* 监测名称 */locales.pages.gong.groupTest.detail.template.monitoringName} 
                        wrapperCol={{ span: 10 }} 
                        rules={[{ required: true }, { max: 40, type:'string', message: /* 最多40个字符 */locales.pages.gong.groupTest.detail.template.upToCharacters }]}
                    >
                        <Input placeholder={/* 请输入名称 */locales.pages.gong.groupTest.detail.template.pleaseEnterAName} />
                    </Item>
                    <Item label={/* 监测指标 */locales.pages.gong.groupTest.detail.template.monitoringIndicators} className='offset50' required>
                        <Item 
                            name='monitorIndicatorCallActive' 
                            label={/* 会话活动 */locales.pages.gong.groupTest.detail.template.sessionActivity} 
                            rules={[{ required: true }]}>
                            <EnumCheckbox enumKey='sessionActivity' />
                        </Item>
                        <Item 
                            name='monitorIndicatorCallAction' 
                            label={/* 会话动作 */locales.pages.gong.groupTest.detail.template.conversationAction}
                            rules={[{ required: true }]}>
                            <EnumCheckbox enumKey='sessionAction' />
                        </Item>
                        <Item 
                            name='monitorIndicatorKeyEvents' 
                            label={/* 关键事件 */locales.pages.gong.groupTest.detail.template.keyEvents}
                            rules={[{ required: true }]}>
                            <MTMTreeSelect data={keyEventTreeData} disableKeys={[]} fieldNames={{children: 'smartTags'}}/>
                        </Item>
                        <Item 
                            name='monitorIndicatorSupervisions' 
                            label={/* 监督项 */locales.pages.gong.groupTest.detail.template.supervisionItems} 
                            rules={[{ required: true }]}>
    
                            <MTMTreeSelect data={supervisionTreeData} disableKeys={[]} fieldNames={{children: 'points'}}/>
                        </Item>
                    </Item>
                    <Item label={/* 监测成员 */locales.pages.gong.groupTest.detail.template.monitoringMembers} className='offset50' required>
                        <Item
                            tooltip={/* 接受指导、培训或管理干预的组别 */locales.pages.gong.groupTest.detail.template.groupReceivingGuidanceTrainingOrManagementIntervention}
                            style={{ 
                                display: 'inline-flex', 
                                width: 'calc(50% - 8px)' 
                            }}
                            label={/* A组 */locales.pages.gong.groupTest.detail.template.groupA}
                        >
                            <Item name='monitorMembersGroupA' rules={[
                                { 
                                    required: true, 
                                    message: /* 请选择A组数据 */locales.pages.gong.groupTest.detail.template.pleaseSelectGroupAData 
                                },
                                getRule4User('monitorMembersGroupB', /* 与B组中存在重复的用户 */locales.pages.gong.groupTest.detail.template.thereAreDuplicateUsersInGroupB),
                            ]}>
                                <MTMTreeSelect data={userTreeData} disableKeys={bGroupUsers} />
                            </Item>
                            <Item name='monitorGroupRemarkA' rules={[{ max: 200, type:'string', message: /* 最多200个字符 */locales.pages.gong.groupTest.detail.template.upToCharacters }]}>
                                <Input placeholder={/* A组备注 */locales.pages.gong.groupTest.detail.template.groupARemarks} />
                            </Item>
                        </Item>
                        <Item
                            tooltip={/* 对照组，与A组进行对比的组别 */locales.pages.gong.groupTest.detail.template.controlGroupGroupComparedWithGroupA}
                            style={{ display: 'inline-flex', marginLeft: 16, width: 'calc(50% - 8px)' }}
                            label={/* B组 */locales.pages.gong.groupTest.detail.template.groupB}
                        >
                            <Item 
                                name='monitorMembersGroupB' 
                                rules={[
                                    { 
                                        required: true, 
                                        message: /* 请选择B组数据 */locales.pages.gong.groupTest.detail.template.pleaseSelectGroupBData 
                                    },
                                    getRule4User('monitorMembersGroupA', /* 与A组中存在重复的用户 */locales.pages.gong.groupTest.detail.template.thereAreDuplicateUsersInGroupA),
                                ]}>
                                <MTMTreeSelect data={userTreeData} disableKeys={aGroupUsers}/>
                            </Item>
                            <Item name='monitorGroupRemarkB' rules={[{ max: 200, type:'string', message: /* 最多200个字符 */locales.pages.gong.groupTest.detail.template.upToCharacters }]}>
                                <Input placeholder={/* B组备注 */locales.pages.gong.groupTest.detail.template.groupBRemarks} />
                            </Item>
                        </Item>
                    </Item>
                    <Item 
                        label={/* 监测周期 */locales.pages.gong.groupTest.detail.template.monitoringPeriod} 
                        className='offset50' 
                        required
                    >
                        <Item 
                            name='monitorStartTime' 
                            label={/* 开始节点 */locales.pages.gong.groupTest.detail.template.startNode} 
                            rules={[{ required: true }]}
                        >
                            <DatePicker format='YYYY-MM-DD' />
                        </Item>
                        <Item wrapperCol={{ span: 12 }} label={/* 监测范围 */locales.pages.gong.groupTest.detail.template.monitoringScope}>
                            <Item
                                style={{ display: 'inline-flex', width: 'calc(30% - 8px)' }}
                                name='monitorScope'
                                rules={[{ required: true, message: /* 请选择监测条件 */locales.pages.gong.groupTest.detail.template.pleaseSelectMonitoringConditions }]}
                            >
                                <EnumSelect enumKey='monitorCriteria' />
                            </Item>
                            <Item
                                style={{ display: 'inline-flex', marginLeft: 12}}
                                name='monitorNumber'
                                rules={[{ required: true, message: /* 请输入数字 */locales.pages.gong.groupTest.detail.template.pleaseEnterANumber }]}
                            >
                                <InputNumber  min={1} max={60} />
                            </Item>
                            <Item
                                style={{ display: 'inline-flex', marginLeft: 12, width: 'calc(30% - 8px)' }}
                                name='monitorUnit'
                                rules={[{ required: true, message: /* 请选择时间周期 */locales.pages.gong.groupTest.detail.template.pleaseSelectATimePeriod }]}
                            >
                                <EnumSelect enumKey='monitorTimeCycle' />
                            </Item>
                        </Item>
                        <Item style={{ marginLeft: 90 }}>
                            <Button 
                                style={{ padding: '4px 15px' }} 
                                type='primary' 
                                htmlType='submit'>
                                {/* 保存 */locales.pages.gong.groupTest.detail.template.preservation}
                            </Button>
                            <Button 
                                style={{ padding: '4px 15px', marginLeft: 8 }} 
                                onClick={handlerBack}
                            >
                                {/* 取消 */locales.pages.gong.groupTest.detail.template.cancel}
                            </Button>
                        </Item>
                    </Item>
                </Form>
            </div>
        </Page>
    );
};


export default class Template extends React.Component {
    render() {
        return <GroupTestDetail {...this.props} />;
    }
}
