import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import DemoIndexComponent from './component';

export default class DemoIndexRoute extends DemoIndexComponent {
    model() {
        let params = Object.assign(
            {},
            Object.fromEntries(new URLSearchParams(this.props.location.search))
        );
        const match = this.props.location.pathname.match(/^\/site\/demo\/(\S*)/i);
        if (match && match[1] !== '') {
            params.type = match[1];
        } else {
            params.type = 'demo';
        }
        let templatesPromise = new Promise((resolve, reject) => {
            let url = `/api/demo/tasks?email=${params.type}@udesk.cn`;
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        let loginPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get('/backend/isLogin').then(
                resp => {
                    resolve();
                },
                reason => {
                    if (reason.status === Udesk.system.apiCodes.unauthenticated) {
                        let referUrl = encodeURIComponent(window.location.pathname + window.location.search);
                        window.location.href = `/auth/login?refer=${referUrl}`;
                        reject();
                    } else {
                        Udesk.logger.error(reason);
                        Udesk.ui.notify.error({
                            message: reason.errorMsg || UdeskLocales.current.labels.initSystemError,
                            duration: 0
                        });
                        reject();
                    }
                }
            );
        });
        return {
            login: loginPromise,
            templates: templatesPromise,
            account: params.type,
        };
    }
}
