// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostLibraryFileCollectsByFileIdParam,
    BaseResponseLibraryFileCollectFoundResponse,
    DeleteLibraryFileCollectsByFileIdParam,
    BaseResponseVoid,
} from '../../types';

/**
 * save
 *
 * @export
 * @tags 星标案例库
 * @link [POST] /libraryFileCollects/{fileId}
 * @param fileId
 */
export function postLibraryFileCollectsByFileId(
    options: ApiOptions<PostLibraryFileCollectsByFileIdParam, never> & {
        segments: PostLibraryFileCollectsByFileIdParam;
    }
): Promise<BaseResponseLibraryFileCollectFoundResponse> {
    return request<never, PostLibraryFileCollectsByFileIdParam, never>(
        '/libraryFileCollects/{fileId}',
        { ...options, method: 'post', __$requestCalleeName: 'postLibraryFileCollectsByFileId' }
    );
}

/**
 * update
 *
 * @export
 * @tags 星标案例库
 * @link [DELETE] /libraryFileCollects/{fileId}
 * @param fileId
 */
export function deleteLibraryFileCollectsByFileId(
    options: ApiOptions<DeleteLibraryFileCollectsByFileIdParam, never> & {
        segments: DeleteLibraryFileCollectsByFileIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteLibraryFileCollectsByFileIdParam, never>(
        '/libraryFileCollects/{fileId}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteLibraryFileCollectsByFileId' }
    );
}

export const meta = [
    { tags: ['星标案例库'], path: '/libraryFileCollects/{fileId}', method: 'post' },
    { tags: ['星标案例库'], path: '/libraryFileCollects/{fileId}', method: 'delete' },
];
