import Locales from '../locales/index';

const inServiceStatus = [
    { id: 1, key: 'activated', name: () => Locales.current.enums.inServiceStatus.activated },
    { id: 2, key: 'disabled', name: () => Locales.current.enums.inServiceStatus.disabled },
    { id: 4, key: 'inactivated', name: () => Locales.current.enums.inServiceStatus.inactivated },
    { id: 5, key: 'resigned', name: () => Locales.current.enums.inServiceStatus.resigned },
];

const chatTypes = [
    { id: '1', key: 'singleChat', name: () => Locales.current.fix.singleChat },
    { id: '2', key: 'groupChat', name: () => Locales.current.fix.groupChat },
];

const chatMessageTypes = [
    { id: 'text', key: 'text', name: () => /* 文本 */ Locales.current.enums.wechat.text },
    { id: 'image', key: 'image', name: () => /* 图片 */ Locales.current.enums.wechat.picture },
    { id: 'voice', key: 'voice', name: () => /* 语音 */ Locales.current.enums.wechat.voice },
    // {
    //     id: 'meeting_voice_call',
    //     key: 'meeting_voice_call',
    //     name: () => /* 语音通话 */ Locales.current.enums.wechat.voiceCall,
    // },
    { id: 'video', key: 'video', name: () => /* 视频 */ Locales.current.enums.wechat.video },
    { id: 'card', key: 'card', name: () => /* 名片 */ Locales.current.enums.wechat.businessCard },
    // { id: 'location', key: 'location', name: () => /* 位置 */ Locales.current.enums.wechat.position },
    { id: 'emotion', key: 'emotion', name: () => /* 表情 */ Locales.current.enums.wechat.expression },
    { id: 'link', key: 'link', name: () => /* 链接 */ Locales.current.enums.wechat.link },
    { id: 'weapp', key: 'weapp', name: () => /* 小程序 */ Locales.current.enums.wechat.applet },
    // { id: 'chatrecord', key: 'chatrecord', name: () => /* 聊天记录 */ Locales.current.enums.wechat.chatRecord },
    // { id: 'mixed', key: 'mixed', name: () => /* 混合消息 */ Locales.current.enums.wechat.mixedMessage },
    { id: 'file', key: 'file', name: () => /* 文件 */ Locales.current.enums.wechat.file },
    { id: 'redpacket', key: 'redpacket', name: () => /* 红包 */Locales.current.enums.wechat.redEnvelopes },
];

export default {
    inServiceStatus,
    chatTypes,
    chatMessageTypes,
};
