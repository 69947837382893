import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import { NewAssociateComponent } from './normal';
import { AdvancedAssociate } from './advanced';
import { Select } from 'udesk-ui';
import './style.scss';
import { useAssociateContext } from '../../check-point-condition-list-gather/Contxt';
import { isQaSubApp, isSalesSubApp, isConversationAnalysis } from 'Udesk/system/subApp';

const useAssociateData = (props) => {
    const {
        type: typeInProps,
        isAutoAssociateDateSource = 1, // 是否自动选择随路数据的数据源，此处适用于销售赋能，默认为 1
        operatorItem,
    } = props;

    const [associateList, setAssociateList] = useState([]);
    const {
        associateList: defaultAssociateList,
        associateOperatorList,
        updateDataSearchTemplateByDynamicType,
    } = useAssociateContext();

    useEffect(() => {
        setAssociateList(defaultAssociateList);
    }, [defaultAssociateList]);

    const type = useMemo(() => {
        if (isAutoAssociateDateSource) {
            return typeInProps;
        }
        return operatorItem.operatorList[0].param?.inspectSource;
    }, [isAutoAssociateDateSource, operatorItem.operatorList, typeInProps]);

    useEffect(() => {
        // 销售赋能 关键事件之类的配置可以在算子内改变数据源
        if (!isAutoAssociateDateSource) {
            updateDataSearchTemplateByDynamicType(type).then((typeList) => {
                setAssociateList(typeList);
            });
        }
    }, [type, isAutoAssociateDateSource, updateDataSearchTemplateByDynamicType]);

    return [associateList, associateOperatorList];
};

export default React.memo((props) => {
    const {
        locales,
        isNewAssociate,
        isAutoAssociateDateSource,
        operatorItem,
        changeInputValue,
        handleOperatorItemChange,
    } = props;
    const [associateList, associateOperatorList] = useAssociateData(props);

    const changeHandle = useCallback(
        (event) => {
            return changeInputValue(operatorItem, 'inputContent', event);
        },
        [changeInputValue, operatorItem]
    );

    const enumSelectChangeHandle = useCallback(
        (value) => {
            if (value) {
                operatorItem.operatorList[0].param.inspectSource = value;
                changeHandle('');
            }
        },
        [changeHandle, operatorItem.operatorList]
    );

    const selectOptions = useMemo(() => {
        if (isSalesSubApp()) {
            // 9, 13, 14
            return [
                Udesk.enums.inspectDataSources.reviewCall,
                Udesk.enums.inspectDataSources.salesWechatRadio,
                Udesk.enums.inspectDataSources.salesWechatText,
            ].map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });
        } else if (isQaSubApp() || isConversationAnalysis()) {
            // 1, 2, 5, 10, 12
            return [
                Udesk.enums.inspectDataSources.voiceCall,
                Udesk.enums.inspectDataSources.textDialogue,
                Udesk.enums.inspectDataSources.wechatDialogue,
                Udesk.enums.inspectDataSources.ticket,
                Udesk.enums.inspectDataSources.wechatRadio,
            ].map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });
        } else {
            return [];
        }
    }, []);

    return (
        <>
            {!isAutoAssociateDateSource && (
                <div style={{ marginLeft: 8 }}>
                    <Select
                        style={{ minWidth: 100 }}
                        value={operatorItem.operatorList[0].param?.inspectSource}
                        onChange={enumSelectChangeHandle}
                        options={selectOptions}
                    />
                </div>
            )}
            {isNewAssociate ? (
                <NewAssociateComponent
                    {...{
                        locales,
                        associateList,
                        value: operatorItem.operatorList[0].param?.inputContent,
                        onChange: changeHandle,
                    }}
                />
            ) : (
                <AdvancedAssociate
                    {...{
                        locales,
                        operatorItem,
                        associateList,
                        associateOperatorList,
                        changeInputValue: changeHandle,
                        handleOperatorItemChange,
                    }}
                />
            )}
        </>
    );
});
