import Udesk from 'Udesk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce, useStateForPropsAttribute } from 'src/util/hook';
import { fire } from 'src/util/core';
import { usePPTRecorder } from 'src/pages/coach/learning-center/components/record';
import { usePassThroughParams } from 'src/pages/coach/learning-center/components';
import { useStartSecond } from 'src/pages/coach/learning-center/components/record/hook';
import { createMessage } from 'src/pages/coach/learning-center/components/util';
import UdeskLocales from 'UdeskLocales';

export const isCustomMessage = (item) => typeof item.isSync === 'number';
// 获取用户自己定义的消息，而非自动生成的消息
export const getCustomMessageList = (messageList: any[]) => {
    return messageList.filter(isCustomMessage);
};

export const useChatRecord = (props: any) => {
    const { request, wrongQuestion } = props;
    const {
        isPauseRecord,
        clearList,
        recordList,
        startRecord,
        pauseRecord,
        stopRecord,
        uploadFile,
    } = usePPTRecorder(wrongQuestion);
    const [getPassThroughParams, setPassThroughParams, passThroughParams] = usePassThroughParams();
    const [initStartSecond, newStartSecond] = useStartSecond();
    const [record, setRecord] = useState<any>(null);

    const [loadingForTaskStart, setLoadingForTaskStart] = useState(false);
    const [loadingForSendMessage, setLoadingForSendMessage] = useState(false);
    const [list, setList] = useStateForPropsAttribute(
        wrongQuestion,
        'messageList',
        useCallback(() => [], [])
    );
    const [referenceWords] = useStateForPropsAttribute(wrongQuestion, 'nodeWords', '', false);
    const [isLastNode, setIsLastNode] = useState<boolean>(false);
    const [lastRecord, lastRecordText] = useMemo(() => {
        if (recordList && recordList.length > 0) {
            const last = recordList[recordList.length - 1];
            return [last, last?.text];
        }
        return [undefined, ''];
    }, [recordList]);

    useEffect(() => {
        setIsLastNode(false);
        clearList();
    }, [clearList, wrongQuestion]);

    const startAudioRecord = useDebounce(
        (info) => {
            const newMessage = createMessage({
                isSync: 0,
                startSecond: newStartSecond(),
            });
            setList((list) => {
                const newList = [...list];
                const index = list.findIndex((item) => item.id === newMessage.id);

                if (index > -1) {
                    newList.splice(index, 1, newMessage);
                } else {
                    newList.push(newMessage);
                }
                return newList;
            });
            startRecord();
        },
        [startRecord, setList]
    );

    const pauseAudioRecord = useCallback(() => {
        pauseRecord();
    }, [pauseRecord]);

    const stopAudioRecord = useCallback(() => {
        stopRecord();
        setLoadingForSendMessage(true);
    }, [stopRecord]);

    const updateDataInfo = useCallback(
        (data, info) => {
            setList((list) => {
                return list.map((item) => {
                    if (item.id === data.id) {
                        return $.extend(true, {}, item, info);
                    }

                    return item;
                });
            });
        },
        [setList]
    );

    const sendMessage = useDebounce(
        (data: any, fileInfo?) => {
            const params = getPassThroughParams({
                words: fileInfo?.text, // 用户说的话，如果是语音，需转化为文字
                speakDuration: fileInfo?.duration, // 说话时长 毫秒
                speakTime: data.createDate, // 说话时间
                ossKey: fileInfo?.url, // 语音上传的地址
            });
            request(
                `/intelligentPartnerMistakeCollections/${wrongQuestion.id}/interactive`,
                params,
                'post'
            )
                .then(
                    (resp) => {
                        const isLastNode = true;
                        const { traineeWordsResult } = resp.data;
                        setList((list) => {
                            if (traineeWordsResult) {
                                list[list.length - 1]['traineeWordsResult'] = traineeWordsResult;
                            }
                            return [...list];
                        });

                        if (isLastNode) {
                            wrongQuestion.exerciseTimes += 1; // 练习次数加一
                            setIsLastNode(isLastNode);
                        }
                        updateDataInfo(data, {
                            isSync: 2,
                        });
                    },
                    (reason) => {
                        updateDataInfo(data, {
                            isSync: 0,
                        });
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                /* 服务器错误 */ UdeskLocales['current'].pages.coach.learningCenter
                                    .wrongQuestionSet.exercise.components.record.ppt.hook.index
                                    .serverError
                        );
                    }
                )
                .finally(() => {
                    setLoadingForSendMessage(false);
                });
        },
        [setList, wrongQuestion?.id, request]
    );

    // 文件是否已经停止，并且已经完成了ASR转译及上传
    const isOver = useMemo(() => {
        return recordList.every((item) => {
            if (item.isAsrOver) {
                if (item.text) {
                    return item.isFileUploadOver;
                }
                return true;
            }
            return false;
        });
    }, [recordList]);

    useEffect(() => {
        const isReady = (isSync, recorder) => {
            return isSync === 0 && recorder.text && recorder.isAsrOver && recorder.isFileUploadOver;
        };
        const isResetLoadingReady = (isSync, recorder) => {
            return isSync === 0 && recorder.text === '' && recorder.isAsrOver;
        };
        const isUploadReady = (isSync, recorder) => {
            return (
                isSync === 0 &&
                recorder.text &&
                recorder.isAsrOver &&
                recorder.isFileUploadOver === false
            );
        };

        if (recordList.length > 0) {
            setList((list) => {
                const newList = [...list];
                getCustomMessageList(list).forEach((item, index) => {
                    const recorder = recordList[index];
                    if (recorder) {
                        item.ossKey = recorder.url || '/';
                        item.content = recorder.text;

                        if (isUploadReady(item.isSync, recorder)) {
                            uploadFile(recorder, () => {
                                setLoadingForSendMessage(false); // 上传失败将接触按钮loading状态
                            });
                        }
                        if (isResetLoadingReady(item.isSync, recorder)) {
                            item.isSync = 2; // 这里将ASR转译为空的消息标记为已经完成
                            setLoadingForSendMessage(false);
                        }
                        if (isReady(item.isSync, recorder)) {
                            item.isSync = 1;
                            sendMessage(item, recorder);
                        }
                    }
                });

                return newList;
            });
        }
    }, [recordList, sendMessage, setList, uploadFile]);

    // 开始任务
    const startTaskHandle = useCallback(
        (callback?) => {
            if (wrongQuestion) {
                setLoadingForTaskStart(true);
                request('/intelligentPartnerMistakeCollections/dialogDetail', {
                    // taskId: wrongQuestion.taskId,
                    // nodeId: wrongQuestion.nodeId,
                    // dialogueMode : wrongQuestion.dialogueMode ,
                    // ruleId: wrongQuestion.mistakeRuleDetailList?.[0].ruleId,
                    collectionId: wrongQuestion.id,
                })
                    .then(
                        (resp) => {
                            setPassThroughParams({
                                nodeType: resp.data?.nodeType, // 对话流程ID，后续交互需要作为参数传入
                                bindNodeId: resp.data?.bindNodeId,
                            });
                            setRecord(resp.data?.record);
                            initStartSecond(); // 初始化任务开始时间
                            fire(callback);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                    )
                    .finally(() => {
                        setLoadingForTaskStart(false);
                    });
            }
        },
        [wrongQuestion, request, setPassThroughParams, initStartSecond]
    );

    return {
        loadingForSendMessage,
        loadingForTaskStart, // 任务开始的loading
        referenceWords,
        passThroughParams,
        record,
        isLastNode,
        isPauseRecord,
        isOver,
        pause: pauseAudioRecord,
        stop: stopAudioRecord,
        list,
        recordList,
        lastRecord,
        lastRecordText,
        startTaskHandle,
        startAudioRecord,
    };
};
