import React, { useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs } from 'udesk-ui';
import Locales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { useEffect } from 'react';
import './style.scss';
import { getCurrentRouteName, isSalesSubApp } from 'Udesk/system/subApp';

const { TabPane } = Tabs;

function CollectionDataAdd(props) {
    const locales = Locales['current'];
    const company = Udesk.data.init.company ? Udesk.data.init.company : null;
    const ADD_TYPES = [
        {
            pathName: getCurrentRouteName('systemIntegrationCCpass'),
            key: 'ccpass',
            text: locales.menu.admin.systemIntegration.subTitle[Udesk.data.init.company.enabledWhiteSystem ? 'udeskWhite' : 'udesk'],
            id: 'cc-pass',
        },
        {
            pathName: getCurrentRouteName('wechatDocking'),
            key: 'wechat',
            text: locales.menu.admin.systemIntegration.subTitle.wechat,
            id: 'wecom',
        },
        {
            pathName: getCurrentRouteName('taobaoDocking'),
            key: 'taobao',
            text: locales.menu.admin.systemIntegration.subTitle.taobao,
            id: 'taobao',
            hidden: !Udesk.data.init.company.enabledMallInspect,
        },
        {
            pathName: getCurrentRouteName('weifengDocking'),
            key: 'weifeng',
            text: locales.menu.admin.systemIntegration.subTitle.weifeng,
            id: 'weifeng',
        },
    ].filter(item => {
        if (isSalesSubApp()) {
            return item.id === 'cc-pass' || item.id === 'weifeng';
        } else {
            return item.id !== 'weifeng' || (company && company.enabledWeComInspect !== 0);
        }
    });
    
    const [activeKey, setActiveKey] = useState<'ccpass' | 'wechat' | 'taobao' | 'weifeng'>('ccpass');
    const onAddTypeChange = (activeKey) => {
        setActiveKey(activeKey);
        const tab: any = ADD_TYPES.find(t => t.key === activeKey)??{};
        const routeOptions = {
            history: props.history,
            routeName: tab.pathName,
            pathParams: { taskId: props.match.params.taskId }
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() =>{
        const currentPath = ADD_TYPES.find(item => props.history.location.pathname.indexOf(item.id) >= 0);
        if(currentPath){
            setActiveKey(currentPath.key as any);
        }
        // if(props.history.location.pathname.indexOf('/integration/cc-pass') >= 0){
        //     setActiveKey('ccpass');
        // }else if(props.history.location.pathname.indexOf('/integration/taobao') >= 0){
        //     setActiveKey('taobao');
        // } else {
        //     setActiveKey('wechat');
        // }
    }, [props.history.location]);
    return (
        <Page
            pageBodyClassName='data-collection-add-page-index'
            title={locales.menu.admin.systemIntegration.text}
            padding={true}
        >
            <Tabs activeKey={activeKey} onChange={onAddTypeChange}>
                {props.routes.map((item, i) => {
                    const tab: any = ADD_TYPES.find(t => t.pathName === item.name);
                    
                    if(!tab || tab.hidden) return null;
                    return <TabPane tab={tab.text} key={tab.key}>
                        <SubRoutes key={i} route={item} />
                    </TabPane>;
                })}
            </Tabs>
        </Page>
    );
}

export default React.memo(CollectionDataAdd);
