import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Group, Item, Wrap } from './components/Model';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import './style.scss';
import { getIntelligentPartnerScoringPoints } from 'src/api/intelligentPartner/scoring/points';
import { postIntelligentPartnerScoringModels } from 'src/api/intelligentPartner/scoring/models';
import { linkTo } from 'src/util/link';
import { getRouteNameWithCoach } from 'src/routes/coach/const';
import {
    getIntelligentPartnerScoringModelsById,
    putIntelligentPartnerScoringModelsById,
} from 'src/api/intelligentPartner/scoring/models/{id}';
import UdeskLocales from 'UdeskLocales';

const usePoints = (id) => {
    const [pointGroup, setPointGroup] = useState<any[]>([]);
    const [name, setName] = useState<string>('');
    useEffect(() => {
        const defaultName = {
            1: /* 内容完整 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.scoreModel.detail.index.complete,
            2: /* 要点命中 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.scoreModel.detail.index.keyHit,
            3: /* 要点命中 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.scoreModel.detail.index.keyHit,
            4: /* 跟读准确 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.scoreModel.detail.index.accurateFollowupReading,
            5: /* 语言规范 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.scoreModel.detail.index.languageNorm,
            6: /* 语速 */ UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement
                .scoreModel.detail.index.speechSpeed,
            7: /* 语气词 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.scoreModel.detail.index.modalParticles,
            8: /* 情感积极 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.scoreModel.detail.index.emotionallyPositive,
        };

        const requests: [
            ReturnType<typeof getIntelligentPartnerScoringPoints>,
            ReturnType<typeof getIntelligentPartnerScoringModelsById> | Promise<undefined>
        ] = [getIntelligentPartnerScoringPoints(), Promise.resolve(undefined)];
        if (id) {
            requests[1] = getIntelligentPartnerScoringModelsById({
                segments: {
                    id,
                },
            });
        }

        Promise.all(requests).then(([points, models]) => {
            console.log(points.data);
            const map = new Map();
            points.data!.forEach((element) => {
                if (map.has(element.dimension)) {
                    map.get(element.dimension).push(element);
                } else {
                    map.set(element.dimension, [element]);
                }
            });

            const detailDataMap =
                models?.data?.modelRuleList?.reduce((result, r) => {
                    if (r.pointId) {
                        result[r.pointId] = r;
                    }
                    return result;
                }, {}) || {};

            if (models) {
                setName(models?.data?.name ?? '');
            }

            Udesk.enums.dimension.forEach((dimension) => {
                map.set(
                    dimension.id,
                    Array.from(
                        map
                            .get(dimension.id)
                            .reduce((p, c) => {
                                const option = {
                                    ...c,
                                    label: c.pointName,
                                    value: c.id,
                                };
                                const setValue = () => {
                                    p.set(
                                        c.pointType,
                                        Object.assign({}, detailDataMap[option.value], {
                                            ...c,
                                            options: [option],
                                            pointId: option.value,
                                            name: defaultName[c.pointType],
                                            _enable: !!detailDataMap[option.value],
                                        })
                                    );
                                };
                                if ([2, 3].includes(c.pointType)) {
                                    if (!p.has(2) && !p.has(3)) {
                                        setValue();
                                    } else {
                                        // 处理要点命中的特殊情况
                                        const point = p.get(2) || p.get(3);
                                        point.options.push(option);
                                        const pointHit = detailDataMap[2] || detailDataMap[3];
                                        if (pointHit) {
                                            point.pointId = pointHit.pointId;
                                            point._enable = !!pointHit;
                                        }
                                    }
                                } else {
                                    setValue();
                                }
                                return p;
                            }, new Map())
                            .values()
                    )
                );
            });

            setPointGroup(
                Udesk.enums.dimension.map((d) => {
                    return {
                        groupName: d.name,
                        children: map.get(d.id),
                        dimension: d.id,
                        scoreKey: d.scoreKey,
                        score: models?.data?.[d.scoreKey],
                    };
                })
            );
        });
    }, [id]);

    return {
        pointGroup,
        name,
    };
};

const Template = React.memo((props: any) => {
    const { match } = props;
    const id = match.params.id;
    const { pointGroup, name } = usePoints(id);
    console.log('pointGroup:: ', pointGroup);

    const onBack = () => {
        linkTo(props.history, getRouteNameWithCoach('scoreModel'));
    };

    return (
        <Page
            pageBodyClassName="udesk-coach-score-model-detail"
            title={
                /* 评分模型 */ UdeskLocales['current'].pages.coach.businessConfiguration
                    .scoreManagement.scoreModel.detail.index.scoringModel
            }
            padding={true}
            onBack={onBack}
        >
            <Wrap
                pointGroup={pointGroup}
                name={name}
                onSubmit={(values) => {
                    // /intelligentPartner/scoring/models
                    console.log('values:: ', values);
                    if (id) {
                        putIntelligentPartnerScoringModelsById(values, {
                            successMsg:
                                /* 修改成功 */ UdeskLocales['current'].pages.coach
                                    .businessConfiguration.scoreManagement.scoreModel.detail.index
                                    .successfullyModified,
                            segments: {
                                id,
                            },
                        }).then(onBack);
                    } else {
                        postIntelligentPartnerScoringModels(values, {
                            successMsg:
                                /* 新建成功 */ UdeskLocales['current'].pages.coach
                                    .businessConfiguration.scoreManagement.scoreModel.detail.index
                                    .newSuccessfullyCreated,
                        }).then(onBack);
                    }
                }}
            >
                {(field, group) => (
                    <Group group={group} field={field}>
                        {(field, item) => {
                            return <Item field={field} item={item}></Item>;
                        }}
                    </Group>
                )}
            </Wrap>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
