// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListIntelligentPartnerCourseCategoryFoundResponse } from '../../types';

/**
 * 获取当前用户下的课程分类
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/courseCategory
 */
export function getIntelligentPartnerTaskInfosCourseCategory(): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse>;
export function getIntelligentPartnerTaskInfosCourseCategory(
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse>;
export function getIntelligentPartnerTaskInfosCourseCategory(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse> {
    return request<never, never, never>('/intelligentPartnerTaskInfos/courseCategory', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerTaskInfosCourseCategory',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/courseCategory',
        method: 'get',
    },
];
