// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewCallAnalysisOrganizationByIdQuery,
    GetReviewCallAnalysisOrganizationByIdParam,
    BaseResponseDataCallAnalysisOrgResultResponse,
} from '../../../../../types';

/**
 * byOrganization
 *
 * @export
 * @tags 团队分析
 * @link [GET] /review/call/analysis/organization/{id}
 * @param id
 */
export function getReviewCallAnalysisOrganizationById(
    options: ApiOptions<
        GetReviewCallAnalysisOrganizationByIdParam,
        GetReviewCallAnalysisOrganizationByIdQuery
    > & { segments: GetReviewCallAnalysisOrganizationByIdParam } & {
        params: GetReviewCallAnalysisOrganizationByIdQuery;
    }
): Promise<BaseResponseDataCallAnalysisOrgResultResponse> {
    return request<
        never,
        GetReviewCallAnalysisOrganizationByIdParam,
        GetReviewCallAnalysisOrganizationByIdQuery
    >('/review/call/analysis/organization/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallAnalysisOrganizationById',
    });
}

export const meta = [
    { tags: ['团队分析'], path: '/review/call/analysis/organization/{id}', method: 'get' },
];
