import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'udesk-ui';
import Locales from 'UdeskLocales';
import Udesk from 'Udesk';


interface Props {
    deleteItemsCount: number;
    onDelete: () => Promise<any>;
    // failedCount?: number;
}

const BatchDelButton: React.FC<Props> = (props) => {
    let locales = Locales['current'];

    const { deleteItemsCount } = props;
    const isEmpty = deleteItemsCount <= 0;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [showRet, setShowRet] = useState(false);
    const [successCount, setSuccessCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const openModal = useCallback(() => {
        if (isEmpty) return;
        setModalVisible(true);
    }, [isEmpty, deleteItemsCount]);
    const closeModal = useCallback(() => {
        setModalVisible(false);
        setShowRet(false);
        setFailedCount(0);
        setSuccessCount(0);
    }, [isEmpty, deleteItemsCount]);
    const onMdalOk = () => {
        setConfirmLoading(true);
        Promise.resolve(props?.onDelete?.())
            .then(({ failedCount, successCount }) => {
                setFailedCount(failedCount);
                setSuccessCount(successCount);
                setShowRet(true);
            })
            .catch((error) => {
                Udesk.ui.notify.error(error.errorMsg);
            })
            .finally(() => {
                setConfirmLoading(false);
            });
    };
    return (
        <React.Fragment>
            <Button disabled={isEmpty} onClick={openModal}>
                {locales.labels.batchDel}
            </Button>
            <Modal
                onCancel={closeModal}
                visible={modalVisible}
                title={locales.labels.batchDel}
                footer={
                    !showRet
                        ? [
                            <Button key='back' onClick={closeModal}>
                                {locales.labels.cancel}
                            </Button>,
                            <Button key='submit' type='primary' loading={confirmLoading} onClick={onMdalOk}>
                                {locales.labels.batchDel}
                            </Button>,
                        ]
                        : [
                            <Button key='back' onClick={closeModal}>
                                {locales.labels.confirm}
                            </Button>,
                        ]
                }
            >
                {showRet ? (
                    <div>
                        {failedCount ? Udesk.utils.string.format(
                            locales.business.notifyMessage.batchDeleteInfo,
                            successCount,
                            failedCount
                        ) : locales.business.notifyMessage.deleteSuccess}
                    </div>
                ) : (
                    Udesk.utils.string.format(locales.business.notifyMessage.batchDeleteTips, deleteItemsCount)
                )}
            </Modal>
        </React.Fragment>
    );
};
export default BatchDelButton;
