import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'udesk-ui';
import useRow0 from './components/row/row0';
import useRow1 from './components/row/row1';
import useRow2 from './components/row/row2';
import useRow3 from './components/row/row3';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props:any) => {
    const {
        bindModal,
        communicateModal,
        locales, 
        userNameList, 
        handlerModal, 
        monthData, 
        dataCallList,
        userName,
        companyName,
        rowTotal,
    } = props;

    const [data, setData] = useState<any>([]);
    const [columns, setColumns] = useState<any>([]);
    const [title0, render0] = useRow0({
        locales, companyName, userName, userNameList, dataCallList, handlerModal, bindModal
    });
    const [title1, render1] = useRow1({
        locales, rowTotal, bindModal
    });
    const [title2, render2] = useRow2({
        locales, rowTotal, bindModal
    });
    const [title3, render3, title4, render4] = useRow3({
        locales, rowTotal, bindModal, communicateModal
    });

    const config = useMemo<any[]>(() => {
        return [
            {title: title0, render: render0},
            {title: title1, render: render1},
            {title: title2, render: render2},
            {title: title3, render: render3},
            {title: title4, render: render4},
        ];
    }, [title0, render0, title1, render1, title2, render2, title3, render3, title4, render4]);

    useEffect(() => {
        const data = config.map(row => {
            return {
                name: row.title
            };
        });

        const monLen = monthData.filter((item)=>item?.isActive).length;
        const newMonthData = monLen > 0 ? monthData.filter((item) => item?.isActive) : monthData.slice(-7);
        newMonthData.forEach((item, index) => {
            const dataIndex = `col-${index}`;
            data.forEach((row) => {
                Reflect.defineProperty(row, dataIndex, {
                    value: { ...item },
                });
            });
        });
        setData(data);
    }, [config, monthData]);

    useEffect(() => {
        const columns:any[] = [
            {
                title: /* 类型/时间 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.index.typeTime,
                dataIndex: 'name',
                fixed: 'left',
                width: 160,
            },
        ];
        const monLen = monthData.filter((item) => item?.isActive).length;
        const newMonthData =
            monLen > 0 ? monthData.filter((item) => item?.isActive) : monthData.slice(-7);
        
        const width = (document.documentElement.clientWidth - 310) / newMonthData.length;
        // console.log(newMonthData);
        newMonthData.forEach((item, colIndex, list) => {
            const dataIndex = `col-${colIndex}`;
            columns.push({
                title:
                    item.dayText === locales.pages.gong.saleClientCenter.detail.index.today
                        ? item.dayText
                        : item.day + '/' + item.month,
                dataIndex,
                width: Math.max(width, 51.8),
                render(data, record, rowIndex) {
                    if (config[rowIndex]) {
                        return config[rowIndex].render(
                            data,
                            `row-${rowIndex}/${dataIndex}`,
                            colIndex,
                            list
                        );
                    }
                    return <ul></ul>;
                },
            });  
        });

        setColumns(columns);
    }, [config, monthData]);

    return (
        <div 
            className='sales-comparison' 
            style={{
                width: '100%',
                overflow: 'auto'
            }}
        >
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
});