// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    PostKnowledgeDetailBody,
    BaseResponseKnowledgePointDetailFoundResponse,
    DeleteKnowledgeDetailQuery,
    BaseResponseVoid,
} from '../types';

/**
 * 更新/保存知识点/流程
 *
 * @export
 * @tags 知识点/流程API
 * @link [POST] /knowledgeDetail
 */
export function postKnowledgeDetail(
    data: PostKnowledgeDetailBody
): Promise<BaseResponseKnowledgePointDetailFoundResponse>;
export function postKnowledgeDetail(
    data: PostKnowledgeDetailBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseKnowledgePointDetailFoundResponse>;
export function postKnowledgeDetail(
    data: PostKnowledgeDetailBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseKnowledgePointDetailFoundResponse> {
    return request<PostKnowledgeDetailBody, never, never>('/knowledgeDetail', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postKnowledgeDetail',
    });
}

/**
 * 删除知识点/流程
 *
 * @export
 * @tags 知识点/流程API
 * @link [DELETE] /knowledgeDetail
 */
export function deleteKnowledgeDetail(
    options: ApiOptions<never, DeleteKnowledgeDetailQuery> & { params: DeleteKnowledgeDetailQuery }
): Promise<BaseResponseVoid> {
    return request<never, never, DeleteKnowledgeDetailQuery>('/knowledgeDetail', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteKnowledgeDetail',
    });
}

export const meta = [
    { tags: ['知识点/流程API'], path: '/knowledgeDetail', method: 'post' },
    { tags: ['知识点/流程API'], path: '/knowledgeDetail', method: 'delete' },
];
