// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostIntelligentPartnerTaskInfosStatisticsByIdParam,
    PostIntelligentPartnerTaskInfosStatisticsByIdBody,
    BaseResponseListIntelligentPartnerTaskUser,
} from '../../../types';

/**
 * 获取单个任务的执行情况
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/statistics/{id}
 * @param id
 */
export function postIntelligentPartnerTaskInfosStatisticsById(
    data: PostIntelligentPartnerTaskInfosStatisticsByIdBody,
    options: ApiOptions<PostIntelligentPartnerTaskInfosStatisticsByIdParam, never> & {
        segments: PostIntelligentPartnerTaskInfosStatisticsByIdParam;
    }
): Promise<BaseResponseListIntelligentPartnerTaskUser> {
    return request<
        PostIntelligentPartnerTaskInfosStatisticsByIdBody,
        PostIntelligentPartnerTaskInfosStatisticsByIdParam,
        never
    >('/intelligentPartnerTaskInfos/statistics/{id}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTaskInfosStatisticsById',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/statistics/{id}',
        method: 'post',
    },
];
