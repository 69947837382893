// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { DeleteInspectionDataByIdDeleteParam, BaseResponseVoid } from '../../../types';

/**
 * deleteInspectData
 *
 * @export
 * @tags 质检工作台
 * @link [DELETE] /inspectionData/{id}/delete
 * @param id
 */
export function deleteInspectionDataByIdDelete(
    options: ApiOptions<DeleteInspectionDataByIdDeleteParam, never> & {
        segments: DeleteInspectionDataByIdDeleteParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteInspectionDataByIdDeleteParam, never>(
        '/inspectionData/{id}/delete',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteInspectionDataByIdDelete' }
    );
}

export const meta = [
    { tags: ['质检工作台'], path: '/inspectionData/{id}/delete', method: 'delete' },
];
