// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetTypoDatasByIdParam,
    BaseResponseTypoDataFoundResponse,
    PutTypoDatasByIdParam,
    PutTypoDatasByIdBody,
    BaseResponseVoid,
    DeleteTypoDatasByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags typo-data-controller
 * @link [GET] /typoDatas/{id}
 * @param id
 */
export function getTypoDatasById(
    options: ApiOptions<GetTypoDatasByIdParam, never> & { segments: GetTypoDatasByIdParam }
): Promise<BaseResponseTypoDataFoundResponse> {
    return request<never, GetTypoDatasByIdParam, never>('/typoDatas/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getTypoDatasById',
    });
}

/**
 * update
 *
 * @export
 * @tags typo-data-controller
 * @link [PUT] /typoDatas/{id}
 * @param id
 */
export function putTypoDatasById(
    data: PutTypoDatasByIdBody,
    options: ApiOptions<PutTypoDatasByIdParam, never> & { segments: PutTypoDatasByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutTypoDatasByIdBody, PutTypoDatasByIdParam, never>('/typoDatas/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putTypoDatasById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags typo-data-controller
 * @link [DELETE] /typoDatas/{id}
 * @param id
 */
export function deleteTypoDatasById(
    options: ApiOptions<DeleteTypoDatasByIdParam, never> & { segments: DeleteTypoDatasByIdParam }
): Promise<BaseResponseVoid> {
    return request<never, DeleteTypoDatasByIdParam, never>('/typoDatas/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteTypoDatasById',
    });
}

export const meta = [
    { tags: ['typo-data-controller'], path: '/typoDatas/{id}', method: 'get' },
    { tags: ['typo-data-controller'], path: '/typoDatas/{id}', method: 'put' },
    { tags: ['typo-data-controller'], path: '/typoDatas/{id}', method: 'delete' },
];
