// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerMistakeCollectionsByIdWordDetailParam,
    BaseResponseIntelligentPartnerMistakeRuleWord,
} from '../../../types';

/**
 * findById
 *
 * @export
 * @tags intelligent-partner-mistake-collection-controller
 * @link [GET] /intelligentPartnerMistakeCollections/{id}/wordDetail
 * @param id
 */
export function getIntelligentPartnerMistakeCollectionsByIdWordDetail(
    options: ApiOptions<GetIntelligentPartnerMistakeCollectionsByIdWordDetailParam, never> & {
        segments: GetIntelligentPartnerMistakeCollectionsByIdWordDetailParam;
    }
): Promise<BaseResponseIntelligentPartnerMistakeRuleWord> {
    return request<never, GetIntelligentPartnerMistakeCollectionsByIdWordDetailParam, never>(
        '/intelligentPartnerMistakeCollections/{id}/wordDetail',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerMistakeCollectionsByIdWordDetail',
        }
    );
}

export const meta = [
    {
        tags: ['intelligent-partner-mistake-collection-controller'],
        path: '/intelligentPartnerMistakeCollections/{id}/wordDetail',
        method: 'get',
    },
];
