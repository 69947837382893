import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import styled from 'styled-components';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Collapse, Menu, Space, Drawer, Button, Empty, Tooltip, Spin, Icon } from 'udesk-ui';
// import './style.scss';
import { ListItem } from 'Component/pages/components/session-insights/components';
import SupervisionClassificationModal from 'Component/pages/components/gong/supervision-classification-modal';
import { getSupervisionInspectionByCallId } from 'src/api/supervisionInspection/{callId}';
import { SupervisionCategoryInspectionResult } from 'src/api/types';
import { getSupervisionInspectionRecheckByCallId } from 'src/api/supervisionInspection/recheck/{callId}';
import { getSupervisionRuleDetailsRecommendById } from 'src/api/supervisionRuleDetails/recommend/{id}';

const { Panel } = Collapse;
const BoxWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    .udesk-qa-ui-drawer-body {
        padding: 8px 16px;
    }
    .udesk-qa-ui-collapse-arrow {
        left: 6px !important;
    }
    .udesk-qa-ui-collapse-header {
        padding-left: 20px !important;
        padding-right: 0 !important;
    }
    .udesk-qa-ui-collapse-content-box {
        border-radius: 12px;
        padding: 0 0 0 20px !important;
        .supervision-point-item {
            background: #f9f9f9;

            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &:hover {
                background: #e6f3ff;
            }
        }
        .supervision-point-miss-item {
            .udesk-qa-ui-collapse-header {
                padding: 0 !important;
            }
        }
    }
    .udesk-qa-ui-menu-inline {
        border-right: 1px solid #fff;
        .udesk-qa-ui-menu-item {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin: 0;
            height: unset;
            line-height: 22px;
            padding: 0 !important;
            .anticon {
                margin-right: 0;
            }
            &::after {
                border-right: none;
            }
            .call-review-detail-page-index-list-item {
                border-radius: 2px;
                margin-top: 8px;
                margin-bottom: 8px;
                // width: 272px;
                padding: 8px;
                width: 100%;
                .call-review-detail-page-index-list-item-header {
                    display: flex;
                    justify-content: space-between;
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: 500;
                    font-size: 14px;
                    .anticon {
                        color: #ff7a52;
                        margin-right: -4px;
                    }
                }
                .call-review-detail-page-index-list-item-content {
                    white-space: pre-wrap;
                    margin-top: 4px;
                    font-size: 12px;
                    line-height: 20px;
                    color: rgba(0, 0, 0, 0.45);
                }
            }
            &.udesk-qa-ui-menu-item-selected {
                background-color: #fff;
                .call-review-detail-page-index-list-item {
                    background: #e6f3ff;
                }
            }
        }
    }
`;
const BaseTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .anticon {
        color: rgba(0, 0, 0, 0.25);
        &:hover {
            color: #1b6dff;
        }
    }
`;
const ClassTitle = styled(BaseTitle)`
    .title {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 8px;
    }
`;
const ListTitle = styled(BaseTitle)`
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const PointTitle = styled(BaseTitle)`
    padding: 2px 16px;
    margin-bottom: 8px;
    border-radius: 13px;
    .udesk-qa-ui-btn {
        display: none !important;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &:hover {
        .udesk-qa-ui-btn {
            display: inline-flex !important;
        }
    }
`;
const ListWrap = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const RecommendItem = styled.div`
    display: flex;
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 2px 16px;
    margin-bottom: 8px;

    &:hover {
        background-color: #e6f3ff;
    }

    > span {
        flex: 0 0 56px;
    }

    > ul {
        position: relative;
        flex: 1;
        margin-left: 8px;
        padding-left: 8px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 5px;
            bottom: 5px;
            width: 1px;
            background-color: rgba(0, 0, 0, 0.06);
        }

        > li {
            display: flex;
            justify-content: space-between;

            > p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 140px;
                line-height: 24px;
            }
            > button {
                display: none !important;
            }

            &:hover {
                > p {
                    width: 120px;
                }
                > button {
                    display: inline-flex !important;
                }
            }
        }
    }
`;

const SupervisionItemTitle = (props) => {
    const { title, hitCount, total, grade, openSettings, levelItemColor } = props;
    return (
        <ClassTitle>
            <div className="title" title={title}>
                {title}
            </div>
            <Space>
                <div>{`${hitCount} / ${total}`}</div>
                <div style={{ color: levelItemColor }}>{grade}</div>
                <Button
                    className="hidden"
                    type="text"
                    size="small"
                    icon={<Icon type="SettingOutlined" antdIcon={true} />}
                    onClick={(e) => {
                        e.stopPropagation();
                        openSettings(props);
                    }}
                />
            </Space>
        </ClassTitle>
    );
};

const link = function (recommend) {
    let url = `/sales/learning-center/knowledge-base/view/${recommend.id}`;
    if (recommend.type) {
        url = `/sales/call-analysis/call-detail/${recommend.id}?libraryCallId=${recommend.callId}`;
    }
    window.open(url);
};

const PointMiss = (props) => {
    const { callId, pointId } = props;
    const locales = Locales['current'];

    const [recommendList, setRecommendList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getSupervisionRuleDetailsRecommendById({
            segments: {
                id: pointId,
            },
            params: {
                callId,
            },
        })
            .then((res) => {
                const result = [] as any;
                const { knowledgePointDetailList, libraryCallList } = res.data || {};
                if (knowledgePointDetailList && knowledgePointDetailList.length > 0) {
                    result.push({
                        title: /* 话术推荐 */ locales.components.gong.supervision.index
                            .scriptRecommendation,
                        list: knowledgePointDetailList.map((item) => {
                            return {
                                type: 0,
                                id: item.id,
                                name: item.pointName,
                            };
                        }),
                    });
                }
                if (libraryCallList && libraryCallList.length > 0) {
                    result.push({
                        title: /* 案例推荐 */ locales.components.gong.supervision.index
                            .caseRecommendation,
                        list: libraryCallList.map((item) => {
                            return {
                                type: 1,
                                id: item.callId,
                                name: item.name,
                                callId: item.id,
                                recommendReason: item.recommendReason,
                            };
                        }),
                    });
                }
                return result;
            })
            .then((list) => {
                setRecommendList(list);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [pointId]);

    return recommendList.length > 0 ? (
        <Collapse
            defaultActiveKey={['1']}
            ghost
            className="supervision-point-miss-item"
            expandIcon={() => null}
        >
            <Panel header={<PointHit {...props} />} key="1">
                {loading ? (
                    <Spin
                        style={{
                            display: 'block',
                            margin: '20px auto',
                        }}
                        size="small"
                    />
                ) : (
                    recommendList.map((recommend) => (
                        <RecommendItem>
                            <span>{recommend.title}</span>
                            <ul>
                                {recommend.list.map((item) => (
                                    <li>
                                        <p title={item.name}>{item.name}</p>
                                        {item.recommendReason ? (
                                            <Tooltip title={item.recommendReason}>
                                                <Button
                                                    onClick={() => link(item)}
                                                    type="text"
                                                    size="small"
                                                    icon={
                                                        <Icon type="LinkOutlined" antdIcon={true} />
                                                    }
                                                ></Button>
                                            </Tooltip>
                                        ) : (
                                            <Button
                                                onClick={() => link(item)}
                                                type="text"
                                                size="small"
                                                icon={<Icon type="LinkOutlined" antdIcon={true} />}
                                            ></Button>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </RecommendItem>
                    ))
                )}
            </Panel>
        </Collapse>
    ) : (
        <PointHit {...props} />
    );
};

const PointHit = (props) => {
    const { title, status, isHitPre } = props;
    const { onItemClick, ...point } = props;
    const locales = Locales['current'];

    return (
        <PointTitle className="supervision-point-item">
            <Space>
                <div style={{ lineHeight: '25px' }}>{title}</div>
                <Button
                    type="text"
                    size="small"
                    icon={<Icon type="EditOutlined" antdIcon={true} />}
                    onClick={(e) => {
                        e.stopPropagation();
                        // openSettings(props);
                        window.open(
                            `/sales/business-configuration/intelligent-tags/supervision/edit/${point.pointId}`
                        );
                    }}
                />
                {point.highlightGroupList?.length > 0 && (
                    <Button
                        type="text"
                        size="small"
                        icon={
                            <Icon
                                type="EnvironmentOutlined"
                                antdIcon={true}
                                style={{ color: '#1b6dff' }}
                            />
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            // openSettings(props);
                            onItemClick(point);
                        }}
                    />
                )}
            </Space>
            <div style={{ color: status ? '#0FBA26' : 'rgba(0, 0, 0, 0.65)' }}>
                {isHitPre === 0
                    ? '-'
                    : status
                    ? /* 命中 */ locales.components.gong.supervision.index.hit
                    : /* 未命中 */ locales.components.gong.supervision.index.miss}
            </div>
        </PointTitle>
    );
};

const Point = (props) => {
    const { isHitPre, status } = props;

    if (isHitPre !== 0 && status) {
        // 需求变更，监督项命中也需要推荐
        return <PointMiss {...props} />;
    }
    return <PointMiss {...props} />;
};

const SupervisionItem = (props) => {
    const { callId, points, onItemClick, ...headerProps } = props;
    return (
        <Collapse
            defaultActiveKey={['1']}
            ghost
            expandIcon={({ isActive }) => (
                <Icon type="CaretRightOutlined" antdIcon={true} rotate={isActive ? 90 : 0} />
            )}
        >
            <Panel header={<SupervisionItemTitle {...headerProps} />} key="1">
                {points.map((p) => (
                    <Point {...p} callId={callId} onItemClick={onItemClick} />
                ))}
            </Panel>
        </Collapse>
    );
};
const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const { onClick, callId, onSupervisionInspectionDetailUpdate, dialogSentenceList } = props;
    // const [highlightItemList /* setHighlightItemList */] = useState([{ title: 'aaa' }]);
    const [visible, setVisible] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [recheckLoading, setRecheckLoading] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<any>(null);
    const [categoryList, setCategoryList] = useState<SupervisionCategoryInspectionResult[]>([]);
    const [currentCategoryId, setCurrentCategoryId] = useState<any>(null);
    const [showHighlights, setShowHighlights] = useState<any[]>([]);
    const requestSupervisionInspectionDetail = (callId) => {
        getSupervisionInspectionByCallId({
            segments: {
                callId,
            },
        }).then((resp) => {
            setCategoryList(resp.data?.categoryInspectionResultList ?? []);
            onSupervisionInspectionDetailUpdate?.(resp.data);
        });
    };
    useEffect(() => {
        requestSupervisionInspectionDetail(callId);
    }, [callId]);
    return (
        <BoxWrap>
            <ListTitle>
                <div>
                    {/* 监督项 */}
                    {locales.components.gong.supervision.index.supervisionItem}
                </div>
                <div>
                    {/* 执行情况 */}
                    {locales.components.gong.supervision.index.implementation}
                </div>
            </ListTitle>

            <ListWrap>
                {categoryList.length ? (
                    categoryList.map((category) => {
                        return (
                            <SupervisionItem
                                callId={callId}
                                categoryId={category.categoryId}
                                title={category.categoryName}
                                levelItemColor={category.levelItemColor}
                                hitCount={category.children?.filter((i) => i.isHit).length ?? 0}
                                total={category.children?.length ?? 0}
                                grade={category.levelItemValue}
                                points={category.children?.map((i) => ({
                                    ...i,
                                    title: i.pointName,
                                    status: i.isHit,
                                }))}
                                onItemClick={(item) => {
                                    setVisible(true);
                                    setCurrentItem(item);
                                    const showHighlights = item.highlightGroupList.map((group) => {
                                        return group.highlightItemList.map((item) => {
                                            return {
                                                ...dialogSentenceList[item.index - 1],
                                                ...item,
                                            };
                                        });
                                    });
                                    setShowHighlights(showHighlights.flat(1));
                                    // dialogSentenceList[]
                                }}
                                openSettings={(item) => {
                                    setModalVisible(true);
                                    setCurrentCategoryId(item.categoryId);
                                }}
                            />
                        );
                    })
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </ListWrap>

            <Button
                style={{ marginBottom: 8 }}
                loading={recheckLoading}
                onClick={() => {
                    setRecheckLoading(true);
                    getSupervisionInspectionRecheckByCallId({
                        segments: {
                            callId,
                        },
                    })
                        .then((resp) => {
                            setCategoryList(resp.data?.categoryInspectionResultList ?? []);
                            onSupervisionInspectionDetailUpdate?.(resp.data);
                        })
                        .finally(() => {
                            setRecheckLoading(false);
                        });
                }}
            >
                {/* 重新质检 */}
                {locales.components.gong.supervision.index.reInspection}
            </Button>
            <Button
                type="primary"
                onClick={() => {
                    window.open(`/sales/business-configuration/intelligent-tags/supervision/index`);
                }}
            >
                {/* 监督项设置 */}
                {locales.components.gong.supervision.index.supervisionItemSettings}
            </Button>
            <SupervisionClassificationModal
                // dataSource={dataSource}
                visible={modalVisible}
                onCancel={() => {
                    setModalVisible(false);
                    setCurrentCategoryId(null);
                }}
                currentClassificationId={currentCategoryId}
                onSuccess={() => {
                    setModalVisible(false);
                    requestSupervisionInspectionDetail(callId);
                    setCurrentCategoryId(null);
                }}
            />
            <Drawer
                // mask={false}
                maskStyle={{
                    backgroundColor: 'transparent',
                }}
                onClose={() => {
                    setVisible(false);
                    setCurrentItem(null);
                    setShowHighlights([]);
                }}
                visible={visible}
                getContainer={false}
                title={currentItem?.title}
                style={{ position: 'absolute', marginRight: '-24px' }}
                // bodyStyle={{
                //     padding: '8px 16px 0',
                //     display: 'flex',
                //     flexDirection: 'column',
                // }}
            >
                <Menu style={{ width: '100%' }} mode="inline">
                    {showHighlights.map((item: any) => (
                        <Menu.Item onClick={onClick} key={`${item.index}`}>
                            <ListItem {...item} />
                        </Menu.Item>
                    ))}
                </Menu>
            </Drawer>
        </BoxWrap>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
