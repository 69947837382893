import React from 'react';
import styled from 'styled-components';

const CardTitle = styled.div`
    display: flex;
    height: 38px;
    align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 16px;
    .tip {
        width: 2px;
        background-color: #1b6dff;
        height: 60%;
        margin-right: 8px;
    }
`;
const Card = (props) => {
    return (
        <div>
            <CardTitle>
                <div className='tip'></div>
                {props.title}
            </CardTitle>
            <div>{props.children}</div>
        </div>
    );
};

export { Card };
