import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import React from "react";
import PropTypes from "prop-types";

export default class TaskOperatorListGatherComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        operatorList: [],
        operatorTypes: [],
        onChange: null,
        entityUrl: "",
        conceptWordUrl: "",
        entities: [],
        entityFields: [],
        disabled: false,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        operatorList: PropTypes.array,
        operatorTypes: PropTypes.array,
        onChange: PropTypes.func,
        entityUrl: PropTypes.string,
        conceptWordUrl: PropTypes.string,
        entities: PropTypes.array,
        entityFields: PropTypes.array,
        disabled: PropTypes.bool,
    };
    //#endregion

    //#region state
    state = {
        isSemanticTagsModalVisible: false,
        semanticSentence: null,
        semanticTags: [],
        operatorItem: {},
        columns: [],
    };
    //#endregion

    //#region privates
    privates = {
        entityUrl: "",
        conceptWordUrl: "",
        operatorList: [],
        operatorListIndex: 0,
    };
    //#endregion

    //#region actions
    actions = {
        handleOperatorItemChange() {
            this.actions.update();
            this.trigger("onChange", this.privates.operatorList);
        },

        addNewOperatorItem() {
            let { operatorTypes } = this.props;
            if (!operatorTypes || operatorTypes.length === 0) {
                return;
            }

            if (!this.privates.operatorList) {
                this.privates.operatorList = [];
            }
            let { operatorList, operatorListIndex } = this.privates;

            let newOperatorItem = {
                _id: operatorListIndex,
                type: operatorTypes[0].id
            };
            operatorList.push(newOperatorItem);
            this.privates.operatorListIndex += 1;

            this.actions.handleOperatorItemChange();
        },

        deleteOperatorItem(operatorItem) {
            let operatorList = this.privates.operatorList;
            let existIndex = operatorList.indexOf(operatorItem);
            operatorList.splice(existIndex, 1);

            this.actions.handleOperatorItemChange();
        },

        changeOperatorItemType(operatorItem, value) {
            operatorItem.type = value;
            Object.keys(operatorItem).forEach(key => {
                if (key !== "_id" && key !== "type") {
                    delete operatorItem[key];
                }
            });

            if (operatorItem.type === Udesk.enums.operatorTypes.entityInfoDetection.id) {
                operatorItem.param = {
                    type: Udesk.enums.operatorTypes.entityInfoDetection.id
                };
            } else if (operatorItem.type === Udesk.enums.operatorTypes.volume.id) {
                operatorItem.param = {
                    type: Udesk.enums.operatorTypes.volume.id
                };
            } else if (operatorItem.type === Udesk.enums.operatorTypes.emotion.id) {
                operatorItem.param = {
                    type: Udesk.enums.operatorTypes.emotion.id
                };
            } else if (operatorItem.type === Udesk.enums.operatorTypes.sentenceLength.id) {
                operatorItem.param = {
                    type: Udesk.enums.operatorTypes.sentenceLength.id
                };
            } else if (operatorItem.type === Udesk.enums.operatorTypes.semanticTags.id) {
                operatorItem.semanticTag = {};
            } else if (operatorItem.type === Udesk.enums.operatorTypes.conceptWord.id) {
                operatorItem.param = {
                    type: Udesk.enums.operatorTypes.conceptWord.id
                };
            }

            this.actions.handleOperatorItemChange();
        },

        changeOperatorItem(operatorItem, key, value) {
            operatorItem[key] = value;
            this.actions.handleOperatorItemChange();
        },

        changeInputValue(operatorItem, key, e) {
            operatorItem[key] = e.target.value;
            this.actions.handleOperatorItemChange();
        },

        validateMinValue(operatorItem, attribute, value) {
            if (value < 0) {
                operatorItem[attribute] = 0;
                this.actions.handleOperatorItemChange();
            }
        },

        validateSpeedValue(operatorItem, attribute, value) {
            let changed = false;
            if (value < 0) {
                operatorItem[attribute] = 0;
                changed = true;
            }

            if ((operatorItem.wordPerMinite == null || operatorItem.wordPerMinite === "") &&
            (operatorItem.maxWordPerMinite == null || operatorItem.maxWordPerMinite === "")) {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.speedWordNotNull
                );
            }

            if (operatorItem.wordPerMinite && operatorItem.maxWordPerMinite) {
                if (parseInt(operatorItem.wordPerMinite, 10) > parseInt(operatorItem.maxWordPerMinite, 10)) {
                    operatorItem[attribute] = 0;
                    changed = true;
                    return Udesk.ui.notify.error(
                        UdeskLocales.current.components.operatorListGather.speedWordMinValueLessThanMaxValue
                    );
                }
            }

            if (changed) {
                this.actions.handleOperatorItemChange();
            }
        },

        validateVolumDetectionValue(operatorItem, value) {
            let changed = false;
            if (operatorItem.param.detectionWay === Udesk.enums.volumeDetectionWays.loudness.id) {
                if (value < 0) {
                    operatorItem.param.detectionValue = 0;
                    changed = true;
                }
                if (value > 200) {
                    operatorItem.param.detectionValue = 200;
                    changed = true;
                }
            } else {
                if (value < -1000) {
                    operatorItem.param.detectionValue = -1000;
                    changed = true;
                }
                if (value > 1000) {
                    operatorItem.param.detectionValue = 1000;
                    changed = true;
                }
            }
            if (changed) {
                this.actions.handleOperatorItemChange();
            }
        },

        cleanTestResult(operatorItem) {
            operatorItem["_testResult"] = "";
            this.actions.update();
        },

        regularTest(operatorItem) {
            let testText = operatorItem._testText;
            let regular = operatorItem.keyword;

            if (regular == null || regular.trim() === "") {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.pleaseEnterRegular
                );
            }

            if (testText == null || testText.trim() === "") {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.pleaseEnterTestText
                );
            }

            try {
                let reg = new RegExp(regular);

                if (reg.test(testText)) {
                    operatorItem["_testResult"] = UdeskLocales.current.components.operatorListGather.matchSuccess;
                } else {
                    operatorItem["_testResult"] = UdeskLocales.current.components.operatorListGather.matchError;
                }
            } catch (e) {
                Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.regularError
                );
            }
            this.actions.update();
        },

        grammarTest(operatorItem) {
            let testText = operatorItem._testText;
            let grammar = operatorItem.keyword;

            if (grammar == null || grammar.trim() === "") {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.pleaseEnterGrammar
                );
            }

            if (testText == null || testText.trim() === "") {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.pleaseEnterTestText
                );
            }

            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionConditions/testGrammar",
                this.props.sdkOptions
            );
            let params = {
                expression: grammar,
                sentences: [testText]
            };
            Udesk.ajax.post(url, params).then(
                resp => {
                    if (resp && resp.data && resp.data.result) {
                        operatorItem["_testResult"] = UdeskLocales.current.components.operatorListGather.matchSuccess;
                    } else {
                        operatorItem["_testResult"] = UdeskLocales.current.components.operatorListGather.matchError;
                    }
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(
                        UdeskLocales.current.components.operatorListGather.testGrammarError
                    );
                }
            );
        },

        changeSemanticTagsModalVisible(operatorItem) {
            let isSemanticTagsModalVisible = this.state.isSemanticTagsModalVisible;
            this.setState({
                isSemanticTagsModalVisible: !isSemanticTagsModalVisible
            });

            if (!isSemanticTagsModalVisible) {
                this.setState({
                    operatorItem: operatorItem
                });
                this.setState({
                    semanticSentence: operatorItem.semanticSentence
                });
                getColumns(this, operatorItem);
                this.actions.querySemanticTags(operatorItem.semanticSentence);
            }
        },

        changeSemanticSentence(e) {
            let operatorItem = this.state.operatorItem;
            this.setState({
                semanticSentence: e.target.value
            });
            operatorItem.semanticSentence = e.target.value;
        },

        querySemanticTags(semanticSentence) {
            if (semanticSentence == null || semanticSentence.trim() === "") {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.components.operatorListGather.querySemanticTags
                    )
                );
            }

            let url = Udesk.business.apiPath.concatApiPath(
                "/smartTags/findSemanticList",
                this.props.sdkOptions
            );
            let params = {
                keyword: semanticSentence
            };
            Udesk.ajax.get(url, params).then(
                resp => {
                    if (resp && resp.data) {
                        this.setState({
                            semanticTags: resp.data
                        });
                    }
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },

        applyToCondition(item) {
            let operatorItem = this.state.operatorItem;
            operatorItem.keyword = item.groupId;
            operatorItem.semanticId = item.semanticId;

            if (!operatorItem.semanticTag) {
                operatorItem.semanticTag = {};
            }

            operatorItem.semanticTag.semanticName = item.semanticName;
            operatorItem.semanticTag.sentence = item.sentence;
            this.actions.handleOperatorItemChange();
        },

        openEditTagWindow(item) {
            let url = `/site/semantic-intelligence/semantic-tags/edit/${item.groupId}`;
            if (this.props.sdkOptions.props.domain) {
                url = this.props.sdkOptions.props.domain + url;
            }
            Udesk.utils.web.openWindow(url, "_blank");
        },

        openCreateTagWindow() {
            let url = "/site/semantic-intelligence/semantic-tags/new";
            if (this.props.sdkOptions.props.domain) {
                url = this.props.sdkOptions.props.domain + url;
            }
            Udesk.utils.web.openWindow(url, "_blank");
        },

        validateGrabInterval(operatorItem, attribute, value) {
            if (operatorItem.grabIntervalTime == null || operatorItem.grabIntervalTime === "") {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.grabIntervalNotNull
                );
            }
        },

        validateGrabLength(operatorItem, attribute, value) {
            if (value < 1) {
                operatorItem[attribute] = 1;
                this.actions.handleOperatorItemChange();
            }

            if (operatorItem.grabMaxSpeakTime == null || operatorItem.grabMaxSpeakTime === "") {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.grabLengthNotNull
                );
            }
        },

        conceptWordChanged(operatorItem, value) {
            operatorItem.param.conceptWord = value;
            operatorItem.keyword = value != null ? value.id.toString() : null;
            this.actions.handleOperatorItemChange();
        }
    };
    //#endregion
    //#region Life Cycle
    init() {
        let { privates, props } = this;
        privates.entityUrl = (props.entityUrl && props.entityUrl !== "") ? props.entityUrl : Udesk.business.apiPath.concatApiPath(`/information-entity/list`, props.sdkOptions);
        privates.conceptWordUrl = (props.conceptWordUrl && props.conceptWordUrl !== "") ? props.conceptWordUrl : Udesk.business.apiPath.concatApiPath(`/conceptWords/findAll`, props.sdkOptions);
        privates.operatorList = props.operatorList.map((item, index) => {
            if (item.type === Udesk.enums.operatorTypes.entityInfoDetection.id && item.param == null) {
                item.param = {
                    type: Udesk.enums.operatorTypes.entityInfoDetection.id
                };
            } else if (item.type === Udesk.enums.operatorTypes.volume.id && item.param == null) {
                item.param = {
                    type: Udesk.enums.operatorTypes.volume.id
                };
            } else if (item.type === Udesk.enums.operatorTypes.emotion.id && item.param == null) {
                item.param = {
                    type: Udesk.enums.operatorTypes.emotion.id
                };
            } else if (item.type === Udesk.enums.operatorTypes.sentenceLength.id && item.param == null) {
                item.param = {
                    type: Udesk.enums.operatorTypes.sentenceLength.id
                };
            } else if (item.type === Udesk.enums.operatorTypes.semanticTags.id && item.semanticTag == null) {
                item.semanticTag = {};
            }
            return {_id: index, ...item};
        });
        privates.operatorListIndex = props.operatorList.length;
    }
    componentDidMount() {
    }
    //#endregion
}

function getColumns(that, operatorItem) {
    let {
        actions,
        locales
    } = that;
    let columns = [
        {
            name: "semanticName",
            caption:
                UdeskLocales.current.components.operatorListGather.semanticName,
            width: "25%"
        },
        {
            name: "sentence",
            caption: UdeskLocales.current.components.operatorListGather.sentence,
            width: "25%"
        },
        {
            name: "similarity",
            caption: UdeskLocales.current.components.operatorListGather.similarity,
            width: "25%"
        },
        {
            name: "actions",
            caption: UdeskLocales.current.components.operatorListGather.actions,
            width: "25%",
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return (
                    <div>
                        <button
                            className="action-btn delete-btn"
                            onClick={actions.openEditTagWindow.params(item)}
                        >
                            {locales.labels.edit}
                        </button>
                        <button
                            className="action-btn delete-btn"
                            style={{marginLeft: "5px"}}
                            onClick={actions.applyToCondition.params(item)}
                        >
                            {locales.labels.apply}
                        </button>
                        <If condition={operatorItem.semanticId===item.semanticId}>
                            <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong' style={{ marginLeft: '5px', color: "#00c49b" }}></i>
                        </If>
                    </div>
                );
            }
        }
    ];
    that.setState({
        columns
    });
}