import React from 'react';
// import Udesk from 'Udesk';
import './style.scss';
import uploadDownRoute from './route';
import {Button} from 'udesk-ui';

export default class uploadDownTemplate extends uploadDownRoute {
    render() {
        let { show } = this.props;
        let { data } = this.privates;
        let { actions, locales } = this;
        return (
            <If condition={show}>
                <Choose>
                    <When condition={data && data.length > 0}>
                        <div className='upload-download-model'>
                            <div className='upload-download-title'>
                                {locales.components.pages.uploadDownloadModel.title}
                                <a href='' className='title-clear' onClick={actions.clearImpExp}>
                                    {locales.components.pages.uploadDownloadModel.list.clear}
                                </a>
                            </div>
                            {data.map(item => {
                                return (
                                    <div className='model-content'>
                                        <div style={{ float: 'left' }}>
                                            {item.taskType === 5 ? (
                                                <i
                                                    className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-shangchuan'
                                                    style={{ fontSize: '22px', color: '#1296db' }}></i>
                                            ) : (
                                                <i
                                                    className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazai1'
                                                    style={{ fontSize: '22px', color: '#1296db' }}></i>
                                            )}
                                        </div>
                                        <div className='content-list'>
                                            <div onClick={actions.updoadDownload.params(item.taskType, item.id)}>
                                                {
                                                    item.taskName.length>25 ? <span title={item.taskName}>{item.taskName.substring(0,25)}...</span>:<span title={item.taskName}>{item.taskName}</span>
                                                }
                                            </div>
                                            <div>
                                                <span class='model-content-list-left'>{item.size}</span>
                                                <span
                                                    class='model-content-list-right'
                                                    onClick={actions.updoadDownload.params(item.taskType, item.id)}>
                                                    {item.status === 4 ? (
                                                        <span>
                                                            {' '}
                                                            <i
                                                                className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai'
                                                                style={{ fontSize: '12px', color: 'd81e06' }}></i>
                                                            {locales.components.pages.uploadDownloadModel.list.fail}
                                                        </span>
                                                    ) : item.status === 5 ? (
                                                        <span>
                                                            {' '}
                                                            <i
                                                                className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-chenggong'
                                                                style={{ fontSize: '12px', color: '#1afa29' }}></i>
                                                            {locales.components.pages.uploadDownloadModel.list.success}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {' '}
                                                            <i
                                                                className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jinhangzhong'
                                                                style={{ fontSize: '12px', color: '#EE6723' }}></i>
                                                            {locales.components.pages.uploadDownloadModel.list.hand}
                                                        </span>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        {item.resultLink && (
                                            <Button
                                                type='link'
                                                href={item.resultLink ? item.resultLink : ''}
                                                style={{position: 'absolute', right: 0}}>
                                                {locales.labels.download}
                                            </Button>
                                        )}
                                    </div>
                                );
                            })}

                            <div class='model-bottom'>
                                <Button
                                    type='link'
                                    style={{ position: 'absolute', right: '10px', bottom: '10px' }}
                                    onClick={actions.updoadDownload.params(1, null)}>
                                    {locales.components.pages.uploadDownloadList.title}
                                </Button>
                            </div>
                        </div>
                    </When>
                    <Otherwise>
                        <div className='upload-download-model'>
                            <div className='upload-download-title'>
                                {locales.components.pages.uploadDownloadModel.title}
                                <a href='' className='title-clear' onClick={actions.clearImpExp}>
                                    {locales.components.pages.uploadDownloadModel.list.clear}
                                </a>
                            </div>
                            <div class='clearModel'>{locales.components.pages.uploadDownloadModel.list.noInfo}</div>
                            <div class='model-bottom'>
                                <Button
                                    type='link'
                                    style={{ position: 'absolute', right: '10px', bottom: '10px' }}
                                    onClick={actions.updoadDownload.params(1, null)}>
                                    {locales.components.pages.uploadDownloadList.title}
                                </Button>
                            </div>
                        </div>
                    </Otherwise>
                </Choose>
            </If>
        );
    }
}
