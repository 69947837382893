// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerByRecordIdEvaluationQuery,
    GetIntelligentPartnerByRecordIdEvaluationParam,
    BaseResponseIntelligentPartnerScoreEvaluation,
} from '../../../types';

/**
 * 考试/练习综合评价
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [GET] /intelligentPartner/{recordId}/evaluation
 * @param recordId
 */
export function getIntelligentPartnerByRecordIdEvaluation(
    options: ApiOptions<
        GetIntelligentPartnerByRecordIdEvaluationParam,
        GetIntelligentPartnerByRecordIdEvaluationQuery
    > & { segments: GetIntelligentPartnerByRecordIdEvaluationParam } & {
        params: GetIntelligentPartnerByRecordIdEvaluationQuery;
    }
): Promise<BaseResponseIntelligentPartnerScoreEvaluation> {
    return request<
        never,
        GetIntelligentPartnerByRecordIdEvaluationParam,
        GetIntelligentPartnerByRecordIdEvaluationQuery
    >('/intelligentPartner/{recordId}/evaluation', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerByRecordIdEvaluation',
    });
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/{recordId}/evaluation',
        method: 'get',
    },
];
