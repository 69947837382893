import React, { useContext } from 'react';
import { Button } from 'udesk-ui';
import { postIntelligentPartnerHomePageAdminStatisticsExport } from 'src/api/intelligentPartner/homePage/admin/statistics/export/index';
import { ExportContext } from '../../context';
import { getSystemModule } from 'Udesk/system/subApp';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

export const ExportButton = () => {
    const [conditions] = useContext(ExportContext);

    const onExport = () => {
        postIntelligentPartnerHomePageAdminStatisticsExport({
            ...conditions,
            systemModule: getSystemModule(),
        })
            .then(() => {
                Udesk.ui.notify.success(
                    /* 导出成功，请前往任务中心查看 */ UdeskLocales['current'].pages.coach.dashboard
                        .components.exportButton.index.exportSuccessfulPleaseGoToTheTaskCenterToView
                );
            })
            .catch((reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            });
    };

    return (
        <Button type="primary" onClick={onExport}>
            {/* 导出 */}
            {UdeskLocales['current'].pages.coach.dashboard.components.exportButton.index.export}
        </Button>
    );
};
