export const FiledType = {
    SYSTEM: 1,
    CUSTOM: 2,
};

export const Mode = {
    AUTO: 1,
    MANUAL: 2,
};

export const Enable = {
    CLOSE: 0,
    OPEN: 1,
};
