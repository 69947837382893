// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PutSummaryByIdParam, PutSummaryByIdBody, BaseResponseVoid } from '../../types';

/**
 * update
 *
 * @export
 * @tags call-service-summary-controller
 * @link [PUT] /summary/{id}
 * @param id
 */
export function putSummaryById(
    data: PutSummaryByIdBody,
    options: ApiOptions<PutSummaryByIdParam, never> & { segments: PutSummaryByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutSummaryByIdBody, PutSummaryByIdParam, never>('/summary/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putSummaryById',
    });
}

export const meta = [
    { tags: ['call-service-summary-controller'], path: '/summary/{id}', method: 'put' },
];
