// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { PutMallConfigsByIdStatusByStatusParam, BaseResponseVoid } from '../../../../types';

/**
 * status
 *
 * @export
 * @tags mall-config-controller
 * @link [PUT] /mallConfigs/{id}/status/{status}
 * @param id
 * @param status
 */
export function putMallConfigsByIdStatusByStatus(
    options: ApiOptions<PutMallConfigsByIdStatusByStatusParam, never> & {
        segments: PutMallConfigsByIdStatusByStatusParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PutMallConfigsByIdStatusByStatusParam, never>(
        '/mallConfigs/{id}/status/{status}',
        { ...options, method: 'put', __$requestCalleeName: 'putMallConfigsByIdStatusByStatus' }
    );
}

export const meta = [
    { tags: ['mall-config-controller'], path: '/mallConfigs/{id}/status/{status}', method: 'put' },
];
