// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostIntelligentPartnerTaskInfosFindAllBody,
    BaseResponseListIntelligentPartnerTaskInfoFoundResponse,
} from '../../types';

/**
 * 获取所有的发布任务详情-分页
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/findAll
 */
export function postIntelligentPartnerTaskInfosFindAll(
    data: PostIntelligentPartnerTaskInfosFindAllBody
): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse>;
export function postIntelligentPartnerTaskInfosFindAll(
    data: PostIntelligentPartnerTaskInfosFindAllBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse>;
export function postIntelligentPartnerTaskInfosFindAll(
    data: PostIntelligentPartnerTaskInfosFindAllBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse> {
    return request<PostIntelligentPartnerTaskInfosFindAllBody, never, never>(
        '/intelligentPartnerTaskInfos/findAll',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerTaskInfosFindAll',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/findAll',
        method: 'post',
    },
];
