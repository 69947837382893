import React from 'react';
import Udesk from 'Udesk';

const DEFAULT_PAGING = {
    pageNum: 1,
    total: 0,
};
let timer = null;
class ClusteringAnnotationIndexComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion
    privates = {
        columsCount: 1,
        searchValues: {},
    };
    state = {
        current: null,
        currentCluster: null,
        paging: {
            pageNum: 1,
            pageSize: 20,
            total: 0,
        },
        sentencePaging: {
            pageNum: 1,
            pageSize: 50,
            // pageSize: 3,
            total: 0,
        },
        clusterData: [],
        sentenceData: [],
        checkedIds: new Map(),
        checkedSentence: {},
        rightLoading: false,
        leftLoading: false,
        disabled: false,
    };
    actions = {
        onSearch(values){
            this.privates.searchValues = values;
            this.actions.update();
            this.actions.getClusterData();
        },
        handleClick(e) {
            let currentCluster = this.state.clusterData.find((data) => `${data.id}` === `${e.key}`);
            this.props.SmartToolBarRef.current.actions.onClose();
            this.setState({
                current: parseInt(e.key, 10),
                currentCluster,
                checkedIds: new Map(),
                checkedSentence: {},
                sentencePaging: { ...this.state.sentencePaging, ...DEFAULT_PAGING },
            }, () => {
                this.actions.getSentence(e.key);
            });
        },
        //获取聚类结果
        getClusterData() {
            let {
                sdkOptions,
                // match
            } = this.props;
            const { pageNum } = this.state.paging;
            const url = Udesk.business.apiPath.concatApiPath(
                `call-sentence-cluster-results?pageNum=${pageNum}`,
                sdkOptions
            );
            let urlParams = new URL(url);
            Object.keys(this.privates.searchValues).forEach(key => {
                this.privates.searchValues[key] && urlParams.searchParams.set(key, this.privates.searchValues[key]);
            });
            this.setState({ rightLoading: true, leftLoading: true });
            Udesk.ajax.get(urlParams.toString()).then(
                (resp) => {
                    this.setState({ clusterData: resp.data, paging: resp.paging, leftLoading: false });
                    if (resp.data && resp.data.length) {
                        this.setState(
                            {
                                currentCluster: resp.data[0],
                                current: resp.data[0].id,
                                sentencePaging: { ...this.state.sentencePaging, ...DEFAULT_PAGING },
                            },
                            () => {
                                this.actions.handleClick({ key: resp.data[0].id });
                            }
                        );
                        // this.props.SmartToolBarRef.current.actions.onClose();
                        // this.actions.getSentence(resp.data[0].id);
                    }else{
                        this.setState({
                            sentenceData: [],
                            sentencePaging: {
                                ...this.state.sentencePaging,
                                pageNum: 1,
                                total: 0,
                            },
                            current: null,
                            rightLoading: false,
                        });
                    }
                    if (resp.data && !resp.data.length) {
                        if (resp.paging.pageNum > 1) {
                            this.actions.onLeftPaginChange(resp.paging.pageNum - 1);
                        } else {
                            this.setState({
                                rightLoading: false,
                            });
                        }
                    }
                },
                (reason) => {
                    this.setState({ leftLoading: false });
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        //获取聚类句子
        getSentence(id) {
            let {
                sdkOptions,
                // match
            } = this.props;
            const { pageNum, pageSize } = this.state.sentencePaging;
            const url = Udesk.business.apiPath.concatApiPath(
                `call-sentence-cluster-result-details?pageNum=${pageNum}&pageSize=${pageSize}&clusterId=${
                    id || this.state.current
                }`,
                sdkOptions
            );
            let urlParams = new URL(url);
            Object.keys(this.privates.searchValues).forEach(key => {
                this.privates.searchValues[key] && urlParams.searchParams.set(key, this.privates.searchValues[key]);
            });
            this.setState({
                rightLoading: true,
            });
            Udesk.ajax.get(urlParams.toString()).then(
                (resp) => {
                    let sentenceData = resp.data || [];
                    sentenceData = sentenceData.map((item) => ({ ...item, label: item.sentence, value: item.id }));
                    let current = sentenceData.length ? sentenceData[0].clusterId : null;
                    this.setState({
                        sentenceData: resp.data,
                        sentencePaging: resp.paging,
                        current: current,
                        rightLoading: false,
                    });
                },
                (reason) => {
                    this.setState({ rightLoading: false });
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        onLeftPaginChange(page, pageSize) {
            this.setState({ paging: { ...this.state.paging, pageNum: page } }, () => {
                this.actions.getClusterData();
            });
        },
        onRightPaginChange(page, pageSize) {
            this.setState({ sentencePaging: { ...this.state.sentencePaging, pageNum: page } }, () => {
                this.actions.getSentence();
            });
        },
        checkSentence(list) {
            let { checkedIds, sentencePaging, checkedSentence, sentenceData } = this.state;
            checkedIds.set(sentencePaging.pageNum, list);
            this.props.SmartToolBarRef.current.actions.onClose();
            checkedSentence[sentencePaging.pageNum] = sentenceData.filter((data) => list.includes(data.id));
            this.setState({ checkedIds, checkedSentence });
        },
        onCheckAllChange(e) {
            let { checkedIds, sentencePaging, sentenceData, checkedSentence } = this.state;
            this.props.SmartToolBarRef.current.actions.onClose();
            if (e.target.checked) {
                checkedIds.set(
                    sentencePaging.pageNum,
                    sentenceData.map((i) => i.id)
                );
                checkedSentence[sentencePaging.pageNum] = sentenceData;
            } else {
                checkedIds.set(sentencePaging.pageNum, []);
                checkedSentence[sentencePaging.pageNum] = [];
            }
            this.setState({ checkedIds, checkedSentence });
        },
        openSiderToolBar(requestSentenceClusterWarn) {
            let keyword = this.state.currentCluster ? this.state.currentCluster.mainSentence : '';
            this.props.SmartToolBarRef.current.actions.seleteTag('3');
            setTimeout(() => {
                this.props.SmartToolBarRef.current.actions.open(
                    this.actions.seleteSemanticNameBySider.bind(this, requestSentenceClusterWarn),
                    keyword,
                    'clustering'
                );
                if (!this.props.SmartToolBarRef.current.privates.isFront) {
                    this.props.SmartToolBarRef.current.actions.backToIndex();
                }
            });
        },
        seleteSemanticNameBySider(requestSentenceClusterWarn) {
            let checkedSentence = Object.values(this.state.checkedSentence).flat(Infinity);
            let taggingSuccessCallBack = () => {
                this.setState(
                    {
                        checkedIds: new Map(),
                        checkedSentence: {},
                        paging: { ...this.state.paging, ...DEFAULT_PAGING },
                        sentencePaging: { ...this.state.sentencePaging, ...DEFAULT_PAGING },
                    },
                    () => {
                        this.actions.getClusterData();
                        requestSentenceClusterWarn?.();
                    }
                );
            };
            return {
                mainSentence: this.state.currentCluster,
                checkedSentence: checkedSentence,
                taggingSuccessCallBack: taggingSuccessCallBack,
            };
        },
        changeContainerSize() {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                let container = document.getElementById('clustering-annotation-index-page-checkbox-container');
                if (container) {
                    setTimeout(() => {
                        let width = parseInt(window.getComputedStyle(container).width, 10);
                        if (Math.min(Math.floor(width / 350), Math.floor(width / 290)) !== this.privates.columsCount) {
                            this.privates.columsCount = Math.min(Math.floor(width / 350), Math.floor(width / 290));
                            this.actions.update();
                        }
                    }, 500);
                }
            }, 100);
        },
        markUseless() {
            let {
                sdkOptions,
                // match
            } = this.props;
            const { current } = this.state;
            const url = Udesk.business.apiPath.concatApiPath(
                `call-sentence-cluster-results/mark-useless/${current}`,
                sdkOptions
            );
            Udesk.ajax.put(url).then(
                (resp) => {
                    this.actions.getClusterData();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
    };
    //#region Life Cycle
    componentDidMount() {
        this.actions.getClusterData();
        window.addEventListener('resize', this.actions.changeContainerSize);
        this.actions.changeContainerSize();
    }
    componentDidUpdate() {
        // setTimeout(() => {
        //     changeContainerSize();
        // },500);
        this.actions.changeContainerSize();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.actions.changeContainerSize);
    }
    //#endregion
}

export default ClusteringAnnotationIndexComponent;
