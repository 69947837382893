import React, { FC, memo, useState } from 'react';
import { useRequest } from 'ahooks';
import styled from 'styled-components';
import { Button, Input, Select, Space, message } from 'udesk-ui';
import { isEmpty } from 'src/util/core';
import { DataType } from './const';
import { getAiModelsGetUniqueRecordFieldByDataSourceType } from 'src/api/aiModels/getUniqueRecordField/{dataSourceType}';
import { postCallServiceSummaryFieldsTraining } from 'src/api/callServiceSummaryFields/training';
import { PostAiModelsTrainingBody } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    margin-bottom: 16px;
`;

const Item = styled.div`
    margin-top: 12px;
`;

interface TemplateProps {
    getTrainingParams?: () => Promise<any>;
}

const Template: FC<TemplateProps> = (props) => {
    const { getTrainingParams } = props;

    const [isShow, setIsShow] = useState(false);

    const [dataType, setDataType] = useState(DataType.VOICE);

    const onDataTypeChange = (t: number) => {
        setDataType(t);
    };

    const [idLabel, setIdLabel] = useState('');

    useRequest(
        () =>
            getAiModelsGetUniqueRecordFieldByDataSourceType({
                segments: { dataSourceType: dataType },
            }),
        {
            refreshDeps: [dataType],
            onSuccess: (res) => {
                setIdLabel(
                    /* 请输入 */ UdeskLocales['current'].pages.semanticIntelligence.intelligentModel
                        .components.training.index.pleaseEnter + res.data?.label
                );
            },
        }
    );

    const [trainingDataId, setTrainingDataId] = useState();

    const onDialogChange = (id) => {
        setTrainingDataId(id);
    };

    const onTrainingShow = () => {
        setIsShow((s) => !s);
    };

    const [trainingResult, setTrainingResult] = useState('');

    const { run: train, loading } = useRequest(
        (params: PostAiModelsTrainingBody) => postCallServiceSummaryFieldsTraining(params),
        {
            manual: true,
            onSuccess: (res) => {
                setTrainingResult(res.data || '');
            },
        }
    );

    const onTrain = () => {
        getTrainingParams?.().then((values) => {
            if (isEmpty(values?.prompt)) {
                message.warning(
                    /* 请生成提示语 */ UdeskLocales['current'].pages.semanticIntelligence
                        .intelligentModel.components.training.index.pleaseGeneratePrompt
                );
                return;
            }

            if (isEmpty(trainingDataId)) {
                message.warning(
                    /* 请选择对话 */ UdeskLocales['current'].pages.semanticIntelligence
                        .intelligentModel.components.training.index.pleaseSelectADialogue
                );
                return;
            }

            train({ ...values, trainingDataSourceType: dataType, trainingDataId });
        });
    };

    return (
        <Wrapper>
            <Button onClick={() => onTrainingShow()}>
                {/* 训练 */}
                {
                    UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components
                        .training.index.training
                }
            </Button>

            {isShow ? (
                <>
                    <Item>
                        <Space>
                            <div>
                                {/* 数据类型： */}
                                {
                                    UdeskLocales['current'].pages.semanticIntelligence
                                        .intelligentModel.components.training.index.dataType
                                }
                            </div>
                            <Select
                                style={{ width: 200 }}
                                options={[
                                    {
                                        label: /* 语音通话 */ UdeskLocales['current'].pages
                                            .semanticIntelligence.intelligentModel.components
                                            .training.index.voiceCall,
                                        value: DataType.VOICE,
                                    },
                                    {
                                        label: /* 文本对话 */ UdeskLocales['current'].pages
                                            .semanticIntelligence.intelligentModel.components
                                            .training.index.textDialogue,
                                        value: DataType.TEXT,
                                    },
                                ]}
                                value={dataType}
                                onChange={(v) => onDataTypeChange(v)}
                            />
                            <Input
                                style={{ width: 200 }}
                                value={trainingDataId}
                                onChange={(e) => onDialogChange(e.target.value)}
                                placeholder={idLabel}
                            />
                            <Button
                                type="primary"
                                onClick={() => onTrain()}
                                loading={loading}
                                disabled={loading}
                            >
                                {/* 生成 */}
                                {
                                    UdeskLocales['current'].pages.semanticIntelligence
                                        .intelligentModel.components.training.index.generate
                                }
                            </Button>
                        </Space>
                    </Item>

                    <Item>
                        <Space>
                            <div>{/* 生成结果 */}{UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.components.field.components.training.index.generateResults}</div>
                            <Input.TextArea
                                style={{ width: 400 }}
                                readOnly
                                autoSize
                                value={trainingResult}
                            />
                        </Space>
                    </Item>
                </>
            ) : null}
        </Wrapper>
    );
};

export default memo(Template);
