import React, { useEffect, useState } from 'react';
import { Icon, Empty, Space, Button } from 'udesk-ui';
import Udesk from 'Udesk';
// import CommentDispalyItem from 'Component/common/gong/comment-dispaly-item';
import '../style.scss';
import Locales from 'UdeskLocales';
import { getReviewSubscribes } from 'src/api/review/subscribes';
import { SubscribeFoundResponse, SubscribeCallDetail } from 'src/api/types';
import UdeskPagination from 'Component/common/udesk-pagination';

const locales = Locales['current'];

interface CallCardProps extends SubscribeCallDetail {
    onClick?: (id: number) => void;
}
const noop = () => {};

const CallCard: React.FC<CallCardProps> = (props) => {
    const { callDuration, callTime, agentName, customerName, id } = props;
    const { onClick = noop } = props;
    const timeFormat = (value) => {
        let hh: number = Math.floor(value / 60 / 60);
        let mm: number = Math.floor((value / 60) % 60);
        let ss: number = Math.floor(value % 60);
        return (hh < 10 ? '0' + hh : hh) + ':' + (mm < 10 ? '0' + mm : mm) + ':' + (ss < 10 ? '0' + ss : ss);
    };
    return (
        <div onClick={onClick.bind(null, id!)} className='message-center-audio-message'>
            <div className='message-center-audio-message-text'>
                <h3>
                    {agentName || '--'} | {customerName || '--'}
                </h3>
                <p>{timeFormat(callDuration ?? 0)}</p>
                <p>{callTime}</p>
            </div>
            <Icon
                style={{ fontSize: 40, color: '#BDDEFF' }}
                type='a-maikefengshengyinluyinluzhishengyin1'
                antdIcon={true}
            ></Icon>
        </div>
    );
};

const SubscriptionDetail = (props) => {
    const { dataCallDetail, callId, onClick } = props;
    const { commentCount, helpCount, lastCommentTime } = dataCallDetail;
    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Space>
                    <div style={{ fontWeight: 600 }}>{`${locales.pages.gong.msgCenter.subscription.index.theSessionYouSubscribedToID}${callId}）`}</div>
                    <div style={{ color: 'rgba(0, 0, 0, 0.25)' }}>{locales.pages.gong.msgCenter.subscription.index.thereAreDynamicUpdates}</div>
                </Space>
                <div style={{ color: 'rgba(0, 0, 0, 0.25)' }}>{lastCommentTime}</div>
            </div>
            {!!commentCount && (
                <Space>
                    <Icon type='MessageTwoTone' antdIcon />
                    <div>{locales.pages.gong.msgCenter.subscription.index.newCommentsReplies}</div>
                    <div style={{ fontWeight: 600 }}>{commentCount > 99 ? '99+' : commentCount}</div>
                    <div>{locales.pages.gong.msgCenter.subscription.index.strip}</div>
                </Space>
            )}
            {!!helpCount && (
                <Space>
                    {/* @ts-ignore */}
                    <Icon type='BulbTwoTone' antdIcon twoToneColor='#F6987B' />
                    <div>{locales.pages.gong.msgCenter.subscription.index.newHelp}</div>
                    <div style={{ fontWeight: 600 }}>{helpCount > 99 ? '99+' : helpCount}</div>
                    <div>{locales.pages.gong.msgCenter.subscription.index.strip}</div>
                </Space>
            )}
            <div>
                <Button size='small' type='link' onClick={onClick.bind(null, callId)} style={{ paddingLeft: 0 }}>{locales.pages.gong.msgCenter.subscription.index.goAndWatch}</Button>
            </div>
        </div>
    );
};

function AdminMsgCenterAnswer(props) {
    const [page, setPage] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [listData, setListData] = useState<SubscribeFoundResponse[]>([]);
    const pageChanged = (pageNum, pageSize) => {
        setPage({
            ...page,
            current: pageNum,
            pageSize,
        });
        getAnswerData(pageNum, pageSize);
    };
    const getAnswerData = (pageNum: number, pageSize: number) => {
        const params = {
            pageNum,
            pageSize,
        };
        getReviewSubscribes({
            params,
        }).then((res) => {
            setListData(res.data ?? []);
            setPage({
                current: res.paging?.pageNum || page.current,
                pageSize: res.paging?.pageSize || page.pageSize,
                total: res.paging?.total || page.total,
            });
        });
    };
    const handlerOpenDetail = (id) => {
        const routeOptions = {
            history: props.history,
            routeName: 'gongCallDetail',
            pathParams: { callId: id },
            state: { type: 'subscription' },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    useEffect(() => {
        getAnswerData(page.current, page.pageSize);
    }, []);
    return (
        <React.Fragment>
            {listData.length ? (
                listData.map((item) => {
                    return (
                        <div key={item.id} className='page-qa-message-center-notice-item'>
                            <SubscriptionDetail {...item} onClick={handlerOpenDetail} />
                            <CallCard onClick={handlerOpenDetail} {...item.dataCallDetail} />
                        </div>
                    );
                })
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={locales.labels.noValue} />
            )}
            {page.total ? (
                <UdeskPagination style={{ marginTop: 20 }} {...page} showSizeChanger={false} onChange={pageChanged} />
            ) : null}
        </React.Fragment>
    );
}

export default AdminMsgCenterAnswer;
