import React, { useState, useEffect, useMemo } from 'react';
// import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import CallListItem from '../../../../home-page/common/call-list-item';
import ChatRecord from 'Component/common/chat-record';
import useResizeObserver from 'use-resize-observer';
import { Space, Icon, Button, Spin, Empty } from 'udesk-ui';
import moment from 'moment';
import { formatDuration } from 'src/util/date';

export const playIconContext = React.createContext(true);

function getAudioItemTime(startPoint, endPoint) {
    let start = formatDuration(startPoint);
    let end = formatDuration(endPoint);
    return `${start} - ${end}`;
}

export default React.memo((props: any) => {
    const { date, loading, callDataList, listData } = props;
    const locales = UdeskLocales['current'];
    const { ref, height: h = 1 } = useResizeObserver<HTMLDivElement>();
    const height = Math.max(h, 0);
    const [index, setIndex] = useState(0);
    const [callIndexInDay, setCallIndexInDay] = useState(0);
    const [list, setList] = useState<any[]>([]);
    const [title, setTitle] = useState('');

    useEffect(() => {
        const index = listData?.findIndex((item) => item.callTime === date);
        if (index > -1) {
            setIndex(index);
        }
    }, [date, listData]);

    useEffect(() => {
        if (index > -1) {
            const allCallInDay = listData?.[index];
            if (allCallInDay) {
                setTitle(allCallInDay.callTime);
            }
        }
    }, [index, listData]);

    useEffect(() => {
        setCallIndexInDay(0);
        const allCallInDay = listData?.[index];
        if (allCallInDay) {
            setList(
                allCallInDay.children.map((item) => {
                    return {
                        info: item,
                        content: callDataList.find((call) => call.callId === item.id),
                    };
                })
            );
        }
    }, [callDataList, index, listData]);

    const callData = useMemo(() => list[callIndexInDay], [callIndexInDay, list]);

    const timeFormatter = (time?) => {
        let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
        let today = moment().format('YYYY-MM-DD');
        let defaultTime = moment(time).format('YYYY-MM-DD');
        switch (defaultTime) {
            case today:
                return locales.pages.gong.homePage.common.callDynamic.today;
            case yesterday:
                return locales.pages.gong.homePage.common.callDynamic.yesterday;
            default: {
                return time;
            }
        }
    };

    const handlerCallData = (callItem) => {
        if (!callItem) return;
        let ret = callItem.map((item, index) => {
            let ret = {
                ...item,
                // playRudio: playRudio,
                canRemarkEdit: false,
                fieldFlag: false,
                dataIdForCreateRemark: props.id,
            };

            //格式化语音类型的数据
            // eslint-disable-next-line no-lone-blocks
            {
                //构造开始截止时间
                let created_at = getAudioItemTime(item.fromSec, item.endSec);
                //后端返回的语音类型的数据没有id，规则测试页面造了id字段，所以判断一下
                if (!ret.id) {
                    ret.id = `dialogSentenceList-item-${index}`;
                }
                //企业微信类型的数据解构和文本类型是一致的，所以这里只对语音类型的数据增加__type以作区分
                ret.__type = 'call';
                ret.sender = item.role;
                ret.created_at = created_at;
                ret.content = {
                    // ...item,
                    type: 'message',
                    data: {
                        content: item.text,
                        fromSec: item.fromSec,
                        endSec: item.endSec,
                    },
                };
            }
            return ret;
        });
        return ret;
    };

    const handlerViewPrevCall = () => {
        setIndex(Math.floor(index - 1));
    };
    const handlerViewNextCall = () => {
        setIndex(Math.floor(index + 1));
    };
    const handlerCallDetailPrev = () => {
        setCallIndexInDay(Math.floor(callIndexInDay - 1));
    };
    const handlerCallDetailNext = () => {
        setCallIndexInDay(Math.floor(callIndexInDay + 1));
    };
    const handlerCallDetail = (callId) => {
        props.handlerCallDetail(
            callId,
            index,
            callDataList.findIndex((call) => call === callData.content)
        );
    };

    return (
        <Spin spinning={loading}>
            {listData?.length > 0 ? (
                <div className="comparison-call-container">
                    <div className="comparison-common comparison-call">
                        <div className="call-top">
                            <div>
                                {timeFormatter(title)}
                                <Space size={0}>
                                    <Button
                                        className="button-link"
                                        onClick={handlerViewPrevCall}
                                        disabled={index === 0}
                                        type="link"
                                        icon={<Icon type="ic-arrow-left-s-line" />}
                                    >
                                        {
                                            locales.pages.gong.saleClientCenter.detail.index
                                                .lastContact
                                        }
                                    </Button>
                                    <Button
                                        className="button-link reverse"
                                        onClick={handlerViewNextCall}
                                        disabled={index === listData.length - 1}
                                        type="link"
                                        icon={<Icon type="ic-arrow-right-s-youjiantou" />}
                                    >
                                        {
                                            locales.pages.gong.saleClientCenter.detail.index
                                                .nextContact
                                        }
                                    </Button>
                                </Space>
                            </div>
                            <div>
                                <Space>
                                    <span>
                                        {
                                            /* 共 */ UdeskLocales['current'].pages.gong
                                                .saleClientCenter.components.businessStage.modal
                                                .sessionDetails.common
                                        }{' '}
                                        {list?.length ?? 0}{' '}
                                        {locales.pages.gong.homePage.common.callDynamic.sessions}
                                    </span>
                                    <span>
                                        {
                                            /* 当前查看第 */ UdeskLocales['current'].pages.gong
                                                .saleClientCenter.components.businessStage.modal
                                                .sessionDetails.currentlyViewingThe
                                        }{' '}
                                        {callIndexInDay + 1}{' '}
                                        {
                                            /* 个会话 */ UdeskLocales['current'].pages.gong
                                                .saleClientCenter.components.businessStage.modal
                                                .sessionDetails.sessions
                                        }
                                    </span>
                                </Space>
                                <Space size={0}>
                                    <Button
                                        className="button-link"
                                        onClick={handlerCallDetailPrev}
                                        disabled={callIndexInDay === 0}
                                        type="link"
                                        icon={<Icon type="ic-arrow-left-s-line" />}
                                    >
                                        {locales.pages.gong.saleClientCenter.detail.index.previous}
                                    </Button>
                                    <Button
                                        className="button-link reverse"
                                        onClick={handlerCallDetailNext}
                                        disabled={callIndexInDay === list.length - 1}
                                        type="link"
                                        icon={<Icon type="ic-arrow-right-s-youjiantou" />}
                                    >
                                        {locales.pages.gong.saleClientCenter.detail.index.next}
                                    </Button>
                                </Space>
                            </div>
                        </div>
                        <div className="call-detail">
                            {callData ? (
                                <CallListItem
                                    isHome={true}
                                    {...props}
                                    key={callData?.info.date}
                                    {...callData?.info}
                                    tag={callData?.info.smartTagList?.filter((s, i) => i < 3) ?? []}
                                    agentName={callData?.info.userName}
                                    asrVoiceDuration={callData?.info.callDuration}
                                    handlerCallDetail={handlerCallDetail}
                                    callId={callData?.info.id}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="comparison-common comparison-detail">
                        <div className="chatroom-detail">
                            <div className="chatroom-detail-container" ref={ref}>
                                {callData?.content?.children.length > 0 ? (
                                    <playIconContext.Provider value={true}>
                                        <ChatRecord
                                            key={height}
                                            data={handlerCallData(callData?.content?.children)}
                                            height={height}
                                            allHighlightPoints={[]}
                                            showTopMore={false}
                                            loading={false}
                                            defaultScrollToTop={true}
                                            highlightPoint={null}
                                            virtual={true}
                                        />
                                    </playIconContext.Provider>
                                ) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )}
                            </div>
                            {!!callData?.content && (
                                <div className="chatroom-detail-btn">
                                    <Button
                                        type="primary"
                                        onClick={handlerCallDetail.bind(
                                            null,
                                            callData?.content?.callId
                                        )}
                                    >
                                        {
                                            locales.pages.gong.saleClientCenter.detail.index
                                                .viewDetails
                                        }
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Spin>
    );
});
