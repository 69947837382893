import React, { useState, useCallback, useMemo } from 'react';
import Udesk from 'Udesk';
import { useStorageProps } from 'src/util/component';
import { Icon, Input, Table } from 'udesk-ui';
import { createClassName } from 'src/util/core';
import { useLiveEffect, useRequest } from 'src/util/hook';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const pageSize = Udesk.config.paging.defaultPageSize;

export default React.memo((props: any) => {
    const {updateStorages} = props;
    const [loading, setLoading] = useState(false);
    const [tableDataSource, setTableDataSource] = useState<any[]>([]);
    const [searchText, setSearchText] = useStorageProps(props, 'leftPageSearchText');
    const [pageNum, setPageNum] = useStorageProps(props, 'leftPagePageNum', 1);
    const [selectedRow, setSelectedRow] = useStorageProps(props, 'leftPageSelectedRow');
    const request = useRequest();

    const renderCell = useCallback((text, record) => {
        return (
            <div 
                onClick={() => {
                    updateStorages(false, {
                        leftPageSelectedRow: record,
                        centerPagePageNum: 1,
                        centerPageSelectedRow: null,
                        rightPagePagination(paging) {
                            return {
                                current: 1,
                                pageSize: paging.pageSize,
                                total: 0,
                            };
                        }
                    });
                }}
                className={
                    createClassName({
                        'table-row-item': true,
                        'active': record.id === selectedRow?.id
                    })
                }
            >
                <span title={record.name}>{record.name}</span>
            </div>
        );
    }, [selectedRow?.id, updateStorages]);

    const columnTitle = useMemo(() => {
        return (
            <Input
                allowClear
                value={searchText}
                onChange={e => {
                    setPageNum(1);
                    setSearchText(e.target.value);
                }}
                placeholder={/* 输入搜索关键词 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.pageLeftTable.enterSearchKeywords}
                suffix={<Icon style={{marginLeft: 10}} antdIcon={true} type='SearchOutlined' /> }
            />
        );
    }, [searchText, setPageNum, setSearchText]);

    const tableDataSourceWithFilter = useMemo(() => {
        if (searchText) {
            return tableDataSource.filter(item => item.name.indexOf(searchText) >=0);
        }
        return tableDataSource;
    }, [searchText, tableDataSource]);

    useLiveEffect((success) => {
        setLoading(true);
        request(`/review/wordsOperation/${Udesk.enums.applicationScenarios.sellingPoints.id}`, {}).then(
            resp => {
                success(() => {
                    const {data} = resp;
                    setTableDataSource(data);
                    if (!selectedRow) {
                        if (data.length > 0) {
                            setSelectedRow(data[0]);
                        }
                    }
                });
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        ).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <div className='selling-point-dissent-page-left'>
            <div>
                <div>{/* 关键词 */}{UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.pageLeftTable.keyWord}</div>
            </div>
            <div>
                <Table
                    loading={loading}
                    scroll={{
                        y: 'calc(100% - 60px)'
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedRow ? [selectedRow.id] : [],
                        columnTitle,
                        renderCell,
                    }}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total: tableDataSourceWithFilter.length,
                        simple : true,
                        onChange: setPageNum,
                        hideOnSinglePage: true,
                    }} 
                    columns={[]}
                    dataSource={tableDataSourceWithFilter.slice((pageNum - 1) * pageSize, pageNum * pageSize)}
                />
            </div>
        </div>
    );
});
