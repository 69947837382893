import React from 'react';
import Udesk from 'Udesk';
import GradeRoute from './route';
import './style.scss';
import { Collapse, Input, Checkbox, Select, Button, PageHeader, Alert, Icon } from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import _isEqual from 'lodash-es/isEqual';
// import _size from 'lodash-es/size';
import _includes from 'lodash-es/includes';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


export default class GradeTemplate extends GradeRoute {
    render() {
        let { sdkOptions } = this.props;
        let {
            judgeStrategy = 1,
            conditionJson,
            isFilterVisible,
            customJudgeLogic,
            panelChangeList,
            judgeStrategyList,
            customJudgeLogicList,
            conditionList,
            customName,
            customId,
            loading
        } = this.privates;
        let { locales, actions, privates } = this;
        let { Panel } = Collapse;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        return (
            <If condition={hasFeature && hasFeature("task:template:grade:view")}>
                {/* <If condition={true}> */}
                <div className='udesk-qa-web-page'>
                    {/* <div className='qa-react-list-page-header grade-list-header'>
                        <div className='qa-react-list-page-header-title'>{locales.components.pages.grade.title}</div>
                    </div> */}
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={locales.components.pages.grade.title}
                        subTitle={locales.components.pages.grade.subTitle}
                    />
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root grade-task-index-page-body'>
                            <div style={{ flex: 1 }}>
                                <If condition={this.privates.processStatus.processStatus === 3}>
                                    <Alert
                                        message={locales.pages.tasks.manage.template.basicInfo.index.conduct3}
                                        type="info"
                                        closeText={locales.pages.tasks.manage.template.basicInfo.index.quit}
                                        onClose={actions.exitBoot}
                                    />
                                    {/* <div className="tasks-manage-template-basic-info-index-conduct">
                            <div className="tasks-manage-template-basic-info-index-conduct-left">
                                {locales.pages.tasks.manage.template.basicInfo.index.conduct3}
                                <span style={{marginLeft: 16, color: 'rgba(27,109,255,1)', textDecoration: 'underline', cursor: 'pointer'}}>
                                    {locales.pages.tasks.manage.template.basicInfo.index.helper}
                                </span>
                            </div>
                            <div className="tasks-manage-template-basic-info-index-conduct-right" onClick={actions.exitBoot}>
                                <span className="udesk-qa-react-web-iconfont" style={{marginRight: 4}}>
                                    &#xe6ae;
                                </span>
                                {locales.pages.tasks.manage.template.basicInfo.index.quit}
                            </div>
                        </div> */}
                                </If>
                                <div className='udesk-qa-modal-body-content' style={{ background: '#fff', minHeight: '100%', padding: '16px' }}>
                                    <DragDropContext onDragEnd={actions.onDragEnd} onBeforeDragStart={actions.onDragStart} onBeforeCapture={actions.onBeforeCapture}>
                                        <Droppable droppableId={`Collapse`} type={`point`}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    <div ref={privates.draggableContinaRef} style={{height: this.privates.heightMemo ? this.privates.heightMemo : undefined}}>
                                                        {this.privates.panelList && !_isEmpty(this.privates.panelList) && (
                                                            <For each='panel' index='index' of={this.privates.panelList}>
                                                                <Draggable
                                                                    key={`${panel.keyIds}`}
                                                                    draggableId={panel.keyIds}
                                                                    index={index}
                                                                    isDragDisabled={!_isEqual(panel.defaultGrade, 0)}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div style={{marginTop: '-1px'}}>
                                                                            <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                                                                                <Collapse activeKey={panelChangeList} expandIconPosition='right' onChange={actions.panelChange}>
                                                                                    <Panel
                                                                                        header={
                                                                                            _includes(panelChangeList, panel.keyIds) ? (
                                                                                                <Input
                                                                                                    defaultValue={panel.gradeName}
                                                                                                    style={{ width: '300px' }}
                                                                                                    onClick={actions.onInputClick}
                                                                                                    disabled={!(hasFeature && hasFeature("task:template:grade:edit"))}
                                                                                                    onChange={actions.InputChange.params(panel)}
                                                                                                    maxLength={500}
                                                                                                />
                                                                                            ) : (panel.gradeName)
                                                                                        }
                                                                                        key={panel.keyIds}
                                                                                        extra={
                                                                                            _isEqual(panel.defaultGrade, 0) && [
                                                                                                // this.privates.panelList.indexOf(panel) !== 0 && (
                                                                                                //     <a href='' onClick={actions.moveUpward.params(panel)}>
                                                                                                //         {locales.components.pages.grade.list.up}
                                                                                                //     </a>
                                                                                                // ),
                                                                                                // this.privates.panelList.indexOf(panel) !==
                                                                                                // _size(this.privates.panelList) - 2 && (
                                                                                                //     <a href='' onClick={actions.moveDown.params(panel)}>
                                                                                                //         {locales.components.pages.grade.list.down}
                                                                                                //     </a>
                                                                                                // ),
                                                                                                <Icon type='UnorderedListOutlined' antdIcon={true} style={{ marginRight: 16 }} />,
                                                                                                hasFeature && hasFeature("task:template:grade:edit") && <a href='' onClick={actions.panelDel.params(panel)}>
                                                                                                    {locales.components.pages.grade.list.del}
                                                                                                </a>
                                                                                            ]
                                                                                        }>
                                                                                        {_isEqual(panel.defaultGrade, 0) ? (
                                                                                            <div>
                                                                                                {
                                                                                                    !_isEmpty(conditionJson[panel.keyIds]) && (
                                                                                                        <div className='index-management-custom-filter index-management-custom-filter-width-other'>
                                                                                                            <div className='index-management-custom-filter-edit'>
                                                                                                                {
                                                                                                                    hasFeature && hasFeature("task:template:grade:edit") && (
                                                                                                                        <span onClick={actions.editFilterOhter.params(panel.keyIds, conditionJson[panel.keyIds], judgeStrategy[panel.keyIds], customJudgeLogic[panel.keyIds])}>{locales.labels.edit}</span>
                                                                                                                    )
                                                                                                                }

                                                                                                            </div>
                                                                                                            <div className='index-management-custom-filter-Info'>
                                                                                                                <div>{locales.components.common.customFilter.customFilterCondition}：
                                                                                                                    {
                                                                                                                        _isEmpty(conditionJson[panel.keyIds]) && (locales.components.common.customFilter.none)
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {!_isEmpty(conditionJson[panel.keyIds]) && actions.fieldsFilterChange({ conditionList: conditionJson[panel.keyIds] })}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                {locales.components.common.customFilter.conditionRule}：
                                                                                                                <span>
                                                                                                                    {
                                                                                                                        judgeStrategy[panel.keyIds] !== 3 && Udesk.enums.operatorRuleTypes.find(current => current.id === (judgeStrategy[panel.keyIds] || Udesk.enums.operatorRuleTypes.all.id)).name
                                                                                                                    }
                                                                                                                    {
                                                                                                                        judgeStrategy[panel.keyIds] === 3 && customJudgeLogic[panel.keyIds]
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                {
                                                                                                    _isEmpty(conditionJson[panel.keyIds]) && (hasFeature && hasFeature("task:template:grade:edit")) && (
                                                                                                        <Button onClick={actions.editFilterOhter.params(panel.keyIds, [], 1, '')} >{locales.fix.customAdd}</Button>
                                                                                                    )
                                                                                                }
                                                                                                {
                                                                                                    hasFeature && hasFeature("task:template:grade:edit") && !sdkOptions.props.task.related && <div style={{ padding: '0 30px' }}>
                                                                                                        <Checkbox value={panel.keyIds} defaultChecked={panel.autoInCaseLibrary === 1 ? true : false} onChange={actions.checkBoxChange.params(panel.keyIds)}>
                                                                                                            {locales.components.pages.grade.list.case}
                                                                                                        </Checkbox>
                                                                                                        <Select defaultValue={panel.caseLibraryId} style={{ marginLeft: '15px', width: "150px" }} onChange={actions.selectChange.params(panel.keyIds)}>
                                                                                                            {
                                                                                                                privates.selectOptionList && !_isEmpty(privates.selectOptionList) && privates.selectOptionList.map(item => {
                                                                                                                    return (<Select.Option value={item.id}>
                                                                                                                        {item.name}
                                                                                                                    </Select.Option>);
                                                                                                                })
                                                                                                            }

                                                                                                        </Select>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        ) : (<div>{locales.components.pages.grade.list.info}</div>)}
                                                                                    </Panel>
                                                                                </Collapse>
                                                                            </div>
                                                                            {provided.placeholder}
                                                                        </div>
                                                                        
                                                                    )}
                                                                </Draggable>
                                                            </For>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Droppable>
                                        {
                                            hasFeature && hasFeature("task:template:grade:edit") && <div style={{ marginTop: '20px', overflow: 'hidden' }}>
                                                <div className='grade-page-btn-add' onClick={actions.panelNodeAdd}>
                                                    {locales.components.pages.grade.list.add}
                                                </div>

                                                <Button className='grade-page-btn-save' onClick={actions.panelSave} loading={loading}>
                                                    {locales.components.pages.grade.list.save}
                                                </Button>
                                                <Button className='grade-page-btn-close' onClick={actions.panelClose}>
                                                    {locales.components.pages.grade.list.close}
                                                </Button>
                                            </div>
                                        }
                                    </DragDropContext>
                                </div>
                            </div>
                            <CustomFilterModal
                                visible={isFilterVisible}
                                cancel={actions.handleConditionCancel}
                                applyAction={actions.handleConditionApply}
                                onConditionFilterSaved={actions.onConditionFilterSaved}
                                judgeStrategy={judgeStrategyList}
                                customJudgeLogic={customJudgeLogicList}
                                conditionList={conditionList}
                                customName={customName}
                                customId={customId}
                                type={this.privates.type}
                                taskId={this.props.match.params.taskId}
                                taskName={this.props.sdkOptions.props.task.name}
                                filterList={['f_8', 'f_4', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
                            />
                            <If
                                condition={
                                    this.privates.processStatus.processStatus === 3
                                }
                            >
                                <div className='tasks-manage-template-basic-info-index-footer'>
                                    <Button type="primary" onClick={actions.handleNext}>
                                        {locales.pages.tasks.manage.template.basicInfo.index.next}
                                    </Button>
                                    <Button style={{ marginLeft: 8 }} onClick={actions.sendAjaxToNext} >
                                        {locales.pages.tasks.manage.template.basicInfo.index.skip}
                                    </Button>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>

            </If >
        );
    }
}
