export default {
  lang: {
    export: 'Ekspor',
    successfully: 'Ekspor tugas berhasil dibuat',
    pleaseJumpTo: 'Silakan melompat ke',
    uploadAndDownloadCenter: 'Unggah Pusat Unduhan',
    check: 'Lihat',
    exportAll: 'Konfirmasi mengekspor semua konten?',
    total: 'Total',
    confirm: 'Data, konfirmasi ekspor?',
    error:
      'Jumlah data yang dapat diekspor adalah 0, silakan coba lagi setelah mengubah kondisi filter',
  },
};
