// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { PutTypoDatasByIdStatusByStatusParam, BaseResponseVoid } from '../../../../types';

/**
 * status
 *
 * @export
 * @tags typo-data-controller
 * @link [PUT] /typoDatas/{id}/status/{status}
 * @param id
 * @param status
 */
export function putTypoDatasByIdStatusByStatus(
    options: ApiOptions<PutTypoDatasByIdStatusByStatusParam, never> & {
        segments: PutTypoDatasByIdStatusByStatusParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PutTypoDatasByIdStatusByStatusParam, never>(
        '/typoDatas/{id}/status/{status}',
        { ...options, method: 'put', __$requestCalleeName: 'putTypoDatasByIdStatusByStatus' }
    );
}

export const meta = [
    { tags: ['typo-data-controller'], path: '/typoDatas/{id}/status/{status}', method: 'put' },
];
