// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostReviewIntelligentPartnerNodeRecoverByIdParam,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 恢复对话流程节点
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/node/recover/{id}
 * @param id
 */
export function postReviewIntelligentPartnerNodeRecoverById(
    options: ApiOptions<PostReviewIntelligentPartnerNodeRecoverByIdParam, never> & {
        segments: PostReviewIntelligentPartnerNodeRecoverByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PostReviewIntelligentPartnerNodeRecoverByIdParam, never>(
        '/review/intelligentPartner/node/recover/{id}',
        {
            ...options,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeRecoverById',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/recover/{id}',
        method: 'post',
    },
];
