// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetSubjectMarkCallsQuery,
    BaseResponseListSubjectMarkCallFoundResponse,
    PostSubjectMarkCallsBody,
    BaseResponseVoid,
} from '../types';

/**
 * 根据fileId查询标注记录
 *
 * @export
 * @tags 话题标注通话
 * @link [GET] /subjectMarkCalls
 */
export function getSubjectMarkCalls(): Promise<BaseResponseListSubjectMarkCallFoundResponse>;
export function getSubjectMarkCalls(
    options: ApiOptions<never, GetSubjectMarkCallsQuery>
): Promise<BaseResponseListSubjectMarkCallFoundResponse>;
export function getSubjectMarkCalls(
    options?: ApiOptions<never, GetSubjectMarkCallsQuery>
): Promise<BaseResponseListSubjectMarkCallFoundResponse> {
    return request<never, never, GetSubjectMarkCallsQuery>('/subjectMarkCalls', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSubjectMarkCalls',
    });
}

/**
 * 标注通话
 *
 * @export
 * @tags 话题标注通话
 * @link [POST] /subjectMarkCalls
 */
export function postSubjectMarkCalls(data: PostSubjectMarkCallsBody): Promise<BaseResponseVoid>;
export function postSubjectMarkCalls(
    data: PostSubjectMarkCallsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postSubjectMarkCalls(
    data: PostSubjectMarkCallsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostSubjectMarkCallsBody, never, never>('/subjectMarkCalls', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSubjectMarkCalls',
    });
}

export const meta = [
    { tags: ['话题标注通话'], path: '/subjectMarkCalls', method: 'get' },
    { tags: ['话题标注通话'], path: '/subjectMarkCalls', method: 'post' },
];
