import React from 'react';
import CustomizeModelErrorComponent from './component';
import { Button, Space } from 'udesk-ui';
import './style.scss';

export default class CustomizeModelErrorTemplate extends CustomizeModelErrorComponent {
    render() {
        let {
            actions,
            locales,
            props
        } = this;
        return (
            <div className='customize-model-error-template-page'>
                <If condition={props.componentPrivates.modelError}>
                    <img alt='' src={`/static/images/${props.componentPrivates.modelError.status || 404}.svg`} style={{ height: 302 }}></img>
                    <div className='customize-model-error-template-page-error-msg'>
                        {
                            props.componentPrivates.modelError.status === 500 ?
                                locales.business.notifyMessage[`errorMsg${props.componentPrivates.modelError.status}`] :
                                props.componentPrivates.modelErrorMsg || (props.componentPrivates.modelError.status && locales.business.notifyMessage[`errorMsg${props.componentPrivates.modelError.status}`]) || ''
                        }
                    </div>
                    <div>
                        <Space>
                            <Button onClick={actions.backToTasks}>{locales.labels.back}{locales.menu.tasks.workbench.text}</Button>
                            <Button type='primary' onClick={actions.reload}>{locales.labels.refresh}</Button>
                        </Space>
                    </div>
                </If>
            </div>
        );
    }
}