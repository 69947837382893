// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { DeleteUsersByIdsParam, BaseResponseBatchResponse } from '../../types';

/**
 * deleteByIds
 *
 * @export
 * @tags 用户
 * @link [DELETE] /users/{ids}
 * @param ids
 */
export function deleteUsersByIds(
    options: ApiOptions<DeleteUsersByIdsParam, never> & { segments: DeleteUsersByIdsParam }
): Promise<BaseResponseBatchResponse> {
    return request<never, DeleteUsersByIdsParam, never>('/users/{ids}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteUsersByIds',
    });
}

export const meta = [{ tags: ['用户'], path: '/users/{ids}', method: 'delete' }];
