import React from 'react';
// import Locales from 'UdeskLocales';
// import Udesk from 'Udesk';
import { Tag } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const StatusWrap = styled.div`
    display: flex;
    margin-left: 16px;
    .udesk-qa-ui-tag {
        color: ${(props) => (props.status ? '#0FBA26' : '#FB5127')};
    }
`;

export const Status = (props) => {
    // const locales = Locales['current'];

    const { status } = props;

    return (
        <StatusWrap status={status}>
            <Tag color={status ? '#E2FAE1' : '#FFF5F0'}>{status ? /* 已掌握 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.status.mastered : /* 未掌握 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.status.notMastered}</Tag>
        </StatusWrap>
    );
};
