// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetEmployeesByIdSessionSearchByTypeQuery,
    GetEmployeesByIdSessionSearchByTypeParam,
    BaseResponseListWeComEmployeeSessionDto,
} from '../../../../../types';

/**
 * searchSession
 *
 * @export
 * @tags employee-controller
 * @link [GET] /employees/{id}/session/search/{type}
 * @param id
 * @param type
 */
export function getEmployeesByIdSessionSearchByType(
    options: ApiOptions<
        GetEmployeesByIdSessionSearchByTypeParam,
        GetEmployeesByIdSessionSearchByTypeQuery
    > & { segments: GetEmployeesByIdSessionSearchByTypeParam } & {
        params: GetEmployeesByIdSessionSearchByTypeQuery;
    }
): Promise<BaseResponseListWeComEmployeeSessionDto> {
    return request<
        never,
        GetEmployeesByIdSessionSearchByTypeParam,
        GetEmployeesByIdSessionSearchByTypeQuery
    >('/employees/{id}/session/search/{type}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getEmployeesByIdSessionSearchByType',
    });
}

export const meta = [
    { tags: ['employee-controller'], path: '/employees/{id}/session/search/{type}', method: 'get' },
];
