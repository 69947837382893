import React, { useRef, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { PageHeader, Steps, Button, Space, Row, Col, Spin } from 'udesk-ui';
import './style.scss';
import Upload from './upload';
import Info from './info';
import Result from './result';
import { postVoiceUploadUpload } from 'src/api/voice-upload/upload';
import { postLocalInspectRecordUpload } from 'src/api/local/inspect/record/upload';
import { getCurrentRouteName, getSystemModule, isSalesSubApp } from 'Udesk/system/subApp';

const { Step } = Steps;

export default function ConversationSetting(props) {
    const locales = UdeskLocales['current'];

    //0, 1, 2分别是上传录音、基本信息、质检进度三个界面
    const [current, setCurrent] = React.useState(0);
    const [steps, setSteps] = React.useState(() => {
        return [
            {
                title: locales.components.pages.recordUpload.steps.uploading,
                btnDisable: true,
            },
            {
                title: locales.components.pages.recordUpload.steps.baseInfo,
                btnDisable: true,
            },
            {
                title: isSalesSubApp() ? /* 开始分析 */UdeskLocales['current'].pages.recordUpload.detail.template.startAnalysis : locales.components.pages.recordUpload.steps.start,
                btnDisable: true,
            },
        ].filter((item, index) => props.route.name !== getCurrentRouteName('recordUploadDetailIm') || index);
    });
    const [userUploadList, saveUserUploadList] = React.useState<any>(/* demo */ []);
    const [userUploadInfo, saveUserUploadInfo] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [loadLoading, setLoadLoading] = React.useState(false);
    const [id, setId] = useState<number | null | undefined>(props?.location?.state?.id);
    const [fileCount, setFileCount] = useState<number>(0);
    const recordBatchId = useRef<number | null>(null);
    const goToIndex = () => {
        let routeOptions = {
            history: props.history,
            routeName: getCurrentRouteName('recordUploadIndex'),
            state: {
                recordType: props.route.name === getCurrentRouteName('recordUploadDetail') ? 1 : 2,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const onStepsChange = (current) => {
        setCurrent(current);
    };
    const goToStep2 = () => {
        setCurrent(steps.length - 2);
    };
    const startInspection = () => {
        const { excel, taskIds, ossKeys, uploadDataSource, ...args } = userUploadInfo;
        
        let params = {
            ...args,
            taskIds: [taskIds],
            // ossKeys: userUploadList.filter((f) => f.status === 'success' && f.checked).map((f) => f.key),
            recordBatchId: recordBatchId.current,
            checkOssKey: excel?.key ?? '',
            systemModule: getSystemModule()
        };
        let request: any = null;
        if (props.route.name === getCurrentRouteName('recordUploadDetailIm')) {
            if(!ossKeys.key) {
                Udesk.ui.notify.error(/* 请上传文件 */locales.pages.recordUpload.detail.template.pleaseUploadTheFile);
                return;
            }
            params = {
                taskIds: [taskIds],
                ossKeys: [ossKeys.key], // 上传到阿里云的log或excel文件返回的key值
                checkOssKey: '', // 默认数据源会上传随录数据模板到文件服务器返回的key，文本数据源默认为空
                uploadType: 2, //上传类型 1-语音 2-文本，文本上传默认为2
                uploadDataSource, // 数据源 1-默认 2-京东
                systemModule: getSystemModule(),
            };
            if(uploadDataSource === 1) {
                params.checkOssKey = excel?.key ?? '';
            }
            
            request = postLocalInspectRecordUpload(params);
        } else {
            request = postVoiceUploadUpload(params);
        }
        setLoading(true);
        request?.then(
            (resp) => {
                setId(resp?.data?.id);
                setCurrent(steps.length - 1);
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.message);
                setLoading(false);
            }
        );
    };
    React.useEffect(() => {
        let newSteps = [...steps];
        if (steps.length - 3 < 0) return;
        let prevState = newSteps[steps.length - 3].btnDisable;
        //有没上传完的文件
        newSteps[steps.length - 3].btnDisable = !userUploadList.length || userUploadList.length !== fileCount;
        //都上传完了，判断有没有成功的文件，有才允许下一步
        if (!newSteps[steps.length - 3].btnDisable) {
            newSteps[steps.length - 3].btnDisable = userUploadList.every(
                (f: any) => f.status && f.status !== 'success'
            );
        }
        if (prevState === newSteps[steps.length - 3].btnDisable) {
            return;
        }
        //只要录音列表有修改，就把基本信息页面的开始质检置灰
        newSteps[steps.length - 2].btnDisable = true;
        setSteps(newSteps);
    }, [userUploadList, fileCount]);
    React.useEffect(() => {
        let newSteps = [...steps];
        if (userUploadInfo?.checkSuccess) {
            newSteps[steps.length - 2].btnDisable = false;
        } else {
            newSteps[steps.length - 2].btnDisable = true;
        }
        setSteps(newSteps);
    }, [userUploadInfo]);
    React.useEffect(() => {
        if (current === steps.length - 1) {
            let newSteps = steps.map((step, index) => {
                if (index === current) {
                    return { ...step, btnDisable: false };
                }
                return { ...step, btnDisable: true };
            });
            setSteps(newSteps);
        }
    }, [current]);
    React.useEffect(() => {
        let { sdkOptions } = props;
        if (!id) return;
        //查进度
        setLoadLoading(true);
        let url = Udesk.business.apiPath.concatApiPath(`voice-upload/progress/${id}`, sdkOptions);
        Udesk.ajax.get(url).then(
            (resp) => {
                //-1是没有任务进行中，0～100是有任务在进行
                if (resp?.data?.progress >= 0) {
                    setCurrent(steps.length - 1);
                }
                setLoadLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.message);
                setLoadLoading(false);
            }
        );
    }, []);
    return (
        <div className='udesk-qa-web-page'>
            <PageHeader
                className='udesk-qa-web-page-header'
                title={/* 本地数据上传 */locales.pages.recordUpload.detail.template.localDataUpload}
                onBack={goToIndex}
            />
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root record-upload-operating-page'>
                    <Spin spinning={loadLoading}>
                        <div className='record-upload-operating-page-steps'>
                            <Steps current={current} onChange={onStepsChange}>
                                {steps.map((item, index) => (
                                    <Step
                                        key={item.title}
                                        title={item.title}
                                        disabled={
                                            index > 0
                                                ? steps[index - 1].btnDisable
                                                : current === steps.length - 1
                                                    ? true
                                                    : false
                                        }
                                    />
                                ))}
                            </Steps>
                        </div>
                        <div className='record-upload-operating-page-steps-content'>
                            {current === steps.length - 3 && (
                                <Upload
                                    saveUserUploadList={saveUserUploadList}
                                    propsUserUploadList={userUploadList}
                                    setFileCount={setFileCount}
                                    fileCount={fileCount}
                                    saveRecordBatchId={(id) => (recordBatchId.current = id)}
                                />
                            )}
                            {current === steps.length - 2 && (
                                <Info
                                    saveUserUploadInfo={saveUserUploadInfo}
                                    userUploadInfo={userUploadInfo}
                                    userUploadListLength={userUploadList.filter((f) => f.checked && f.status === 'success').length}
                                    type={props.route.name === getCurrentRouteName('recordUploadDetailIm') ? 'im' : 'call'}
                                />
                            )}
                            {current === steps.length - 1 && <Result backToIndex={goToIndex} id={id} type={props.route.name === getCurrentRouteName('recordUploadDetailIm') ? 'im' : 'call'} />}
                        </div>
                        {current !== steps.length - 1 && (
                            <div className='record-upload-operating-page-footer'>
                                <Row>
                                    <Col span={14} offset={10}>
                                        <Space>
                                            {current === steps.length - 3 && (
                                                <Button
                                                    onClick={goToStep2}
                                                    type='primary'
                                                    disabled={steps[steps.length - 3].btnDisable}
                                                >
                                                    {locales.labels.nextStep}
                                                </Button>
                                            )}
                                            {current === steps.length - 2 && (
                                                <Button
                                                    loading={loading}
                                                    onClick={startInspection}
                                                    type='primary'
                                                    disabled={steps[steps.length - 2].btnDisable}
                                                >
                                                    {props.route.name === getCurrentRouteName('recordUploadDetailIm') ? /* 开始分析 */locales.pages.recordUpload.detail.template.startAnalysis : locales.components.pages.recordUpload.start}
                                                </Button>
                                            )}
                                            <Button onClick={goToIndex}>{locales.labels.cancel}</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Spin>
                </div>
            </div>
        </div>
    );
}
