class FormatSeriesLabelParamsClass {
    constructor(options) {
        this.value = null;
        this.record = null;
        this.column = null;
        this.seriesColumns = [];
        this.originParams = null;

        Object.assign(this, options);
    }
}

export default {
    FormatSeriesLabelParamsClass
};
