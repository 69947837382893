import Locales from '../locales';
import StringUtils from '../utils/string';

export default Object.assign(ajax, {
    get,
    post,
    put,
    del,
    create: post,
    update: put
});

export {
    registerResponseParser
};

function ajax(url, data, type = "GET", options = undefined) {
    let supportCORSNatively = (window.jQuery.support.cors || !window.jQuery.ajaxTransport || !window.XDomainRequest);
    if (typeof url === "object") {
        options = url;
    } else {
        options = Object.assign({
            url: url,
            data: (type.toUpperCase() === "GET" ? data : JSON.stringify(data)),
            type: type,
            dataType: 'json',
            contentType: (!supportCORSNatively && type.toUpperCase() === "POST") ? "text/plain" : 'application/json'
        }, options);
    }
    return window.jQuery.ajax(options);
}

function get(url, data, options) {
    let ajaxInner = this || ajax;
    return ajaxInner(url, data, 'GET', options);
}

function post(url, data, options) {
    let ajaxInner = this || ajax;
    return ajaxInner(url, data, 'POST', options);
}

function put(url, data, options) {
    let ajaxInner = this || ajax;
    return ajaxInner(url, data, 'PUT', options);
}

function del(url, data, options) {
    let ajaxInner = this || ajax;
    return ajaxInner(url, data, 'DELETE', options);
}

function registerResponseParser() {
    window.jQuery && window.jQuery.ajaxPrefilter("json", function (options, originalOptions, jsXHR) {
        jsXHR.fail(function (jsXhr, textStatus, errorThrown) {
            /*
                Expect all responses to follow such structure:

                {
                    code: 0,
                    data: {}|[],
                    message: "",
                    visible: true|false,
                    paging: {
                        pageNum: 1
                        pageSize: 20
                        total: 0
                    }
                }

                Note: 
                `visible` indicates that whether the `message` should be displayed to the user when the request has an error.
                It only makes sense when response code is NOT 2xx, i.e. the request is not successful.
             */
            switch (jsXhr.status) {
                case 404:
                    normalizeResponse(jsXhr);
                    if (!jsXhr.errorMsg) {
                        let message = Locales.get("labels.notFoundDefaultMessage", options.lang);
                        if (options.notFoundName) {
                            message = StringUtils.format(Locales.get("labels.notFoundFormat", options.lang), options.notFoundName);
                        }
                        jsXhr.errorMsg = message;
                    }
                    break;
                default:
                    normalizeResponse(jsXhr);
                    break;
            }
        });
    });
}

function normalizeResponse(jsXhr) {
    let response = null;
    try {
        response = JSON.parse(jsXhr.responseText);
        jsXhr.responseJSON = response;
    } catch (err) {
        // Just try if the response is valid JSON. Should NOT raise any errors even if it fails.
    }
    if (response) {
        // eslint-disable-next-line
        jsXhr._serverMsg = response.message;
        if (response.visible) {
            jsXhr.errorMsg = response.message;
        } else {
            jsXhr.errorMsg = "";
        }
    }
}