// 统一合并为完整的 Locale
var locale = {
  lang: {
    num: '',
    total: ' /total',
    strip: '',
    more: 'more',
    chosen: 'selected',
    cancel: 'cancel',
    bulkOperations: 'Bulk operations',
    import: 'import'
  }
};
export default locale;