import React, { useMemo } from 'react';
import './style.scss';

interface Times {
    startPoint: number;
    endPoint: number;
}

interface RoleTimes {
    type: 'agent' | 'customer';
    voiceLength: number;
    times: Times[];
}

const AUDIO_STICKER_COLORS = ['#BDDEFF', '#ABEDAB'];

// let timer: NodeJS.Timeout | null = null;

const AudioStickers = (props) => {
    const { dialogSentenceList, voiceLength } = props;
    const audioStickers = useMemo(() => {
        let agentTimes: RoleTimes = { type: 'agent', voiceLength: voiceLength, times: [] };
        let customerTimes: RoleTimes = { type: 'customer', voiceLength: voiceLength, times: [] };
        dialogSentenceList.forEach((item) => {
            if (item.role === 'agent') {
                agentTimes.times.push({
                    startPoint: item.fromSec,
                    endPoint: item.endSec,
                });
            } else {
                customerTimes.times.push({
                    startPoint: item.fromSec,
                    endPoint: item.endSec,
                });
            }
        });
        return [agentTimes, customerTimes];
    }, [dialogSentenceList, voiceLength]);

    return (
        <div className='audio-stickers-talk-v2'>
            {audioStickers.map((audioSticker, index) => {
                return (
                    <div key={index} className='audio-sticker-view'>
                        {audioSticker.times.map((item, itemIndex) => {
                            return (
                                <div
                                    key={itemIndex}
                                    className='audio-sticker-item'
                                    style={getAudioStickerItemStyle(
                                        item,
                                        index,
                                        audioSticker.voiceLength,
                                        AUDIO_STICKER_COLORS
                                    )}
                                ></div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(AudioStickers);

function getAudioStickerItemStyle(
    item: Times,
    index: number,
    time: RoleTimes['voiceLength'],
    audioStickerColors: typeof AUDIO_STICKER_COLORS
) {
    let backgroundColor = audioStickerColors[index % 2];
    return getAudioStickerItemStyleModel(item.startPoint, item.endPoint, time, backgroundColor);
}

function getAudioStickerItemStyleModel(
    startPoint: Times['startPoint'],
    endPoint: Times['endPoint'],
    time: RoleTimes['voiceLength'],
    backgroundColor: string
): React.CSSProperties {
    var start = parseFloat(`${startPoint}`);
    var end = parseFloat(`${endPoint}`);
    var timeLength = parseFloat(`${time}`);
    if (isNaN(start) || isNaN(end) || isNaN(timeLength)) {
        // return String.htmlSafe("width:0%;left:0%");
        return {
            width: '0%',
            left: '0%',
        };
    } else {
        let letfPoint = timeLength <= 0 ? '0%' : Math.round((start / timeLength) * 10000) / 100.0 + '%';
        let width = timeLength <= 0 ? '0%' : Math.round(((end - start) / timeLength) * 10000) / 100.0 + '%';
        // return String.htmlSafe(`width:${width};left:${letfPoint};background:${backgroundColor}`);
        return {
            width: width,
            left: letfPoint,
            backgroundColor: backgroundColor,
        };
    }
}
