import React, { memo, useState, useCallback, useEffect, FC } from 'react';
import { useRequest } from 'ahooks';
import { Button, Empty, Form, Input, Space, Spin, message } from 'udesk-ui';
import {
    getReviewCallOpportunityByCallId,
    putReviewCallOpportunityByCallId,
} from 'src/api/review/call/opportunity/{callId}';
import { getReviewCallOpportunityCreate } from 'src/api/review/call/opportunity/create';
import { inspect } from 'src/util/core';
import isNil from 'lodash-es/isNil';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

type TemplateProps = {
    callId?: number;
    value?: string;
    onChange?: (v: string) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { callId, onChange } = props;

    const [opportunity, setOpportunity] = useState<string>('');

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ opportunity });
    }, [form, opportunity]);

    const [isEdit, setIsEdit] = useState(false);

    const onFinish = useCallback(
        (values) => {
            if (onChange) {
                onChange(values.opportunity);
            } else {
                if (!isNil(callId)) {
                    putReviewCallOpportunityByCallId(values, {
                        segments: { callId: callId! },
                    }).then(
                        (resp) => {
                            setOpportunity(values.opportunity);
                            setIsEdit(false);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                    );
                }
            }
        },
        [callId, onChange]
    );

    const [getOpportunityLoading, setGetOpportunityLoading] = useState(false);

    const { run: getOpportunity, cancel: cancelGetOpportunity } = useRequest(
        (callId) => getReviewCallOpportunityByCallId({ segments: { callId } }),
        {
            manual: true,
            pollingInterval: 3000,
            onSuccess: (res) => {
                // 1-等待中 2-进行中 3-成功 4-失败
                if (res.data?.status === 3 || !res.data?.status) {
                    setOpportunity(res.data?.opportunity || '');
                    cancelGetOpportunity();
                    setGetOpportunityLoading(false);
                } else if (res.data?.status === 4) {
                    setOpportunity('');
                    cancelGetOpportunity();
                    setGetOpportunityLoading(false);
                    message.error(res.data.failReason);
                }
            },
            onError: (e) => {
                message.error(e.message || /* 生成失败 */UdeskLocales['current'].components.sessionInsights.components.opportunity.index.generationFailed);
                cancelGetOpportunity();
                setGetOpportunityLoading(false);
            },
        }
    );

    useEffect(() => {
        getOpportunity(callId);
    }, [callId, getOpportunity]);

    const { run: genOpportunity, loading: genOpportunityLoading } = useRequest(
        (callId) => getReviewCallOpportunityCreate({ params: { callId } }),
        {
            manual: true,
            onSuccess: () => {
                setGetOpportunityLoading(true);
                getOpportunity(callId);
            },
            onError: (e) => {
                message.error(e.message || /* 生成失败 */UdeskLocales['current'].components.sessionInsights.components.opportunity.index.generationFailed);
            },
        }
    );

    const onGen = () => {
        genOpportunity(callId);
    };

    return (
        <Spin spinning={getOpportunityLoading || genOpportunityLoading}>
            {inspect(
                opportunity,
                () => {
                    return (
                        <Form form={form} onFinish={onFinish} style={{ flex: 1 }}>
                            <Form.Item name="opportunity">
                                <Input.TextArea rows={8} readOnly={!isEdit} bordered={isEdit} />
                            </Form.Item>

                            <Space style={{ float: 'right', marginBottom: 16 }}>
                                {isEdit ? (
                                    <>
                                        <Button size="small" onClick={() => setIsEdit(false)}>
                                            {/* 取消 */}
                                            {
                                                UdeskLocales['current'].components.sessionInsights
                                                    .index.cancel
                                            }
                                        </Button>
                                        <Button size="small" type="primary" htmlType="submit">
                                            {/* 确定 */}
                                            {
                                                UdeskLocales['current'].components.sessionInsights
                                                    .index.determine
                                            }
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        onClick={() => setIsEdit(true)}
                                        size="small"
                                        type="primary"
                                    >
                                        {/* 编辑 */}
                                        {
                                            UdeskLocales['current'].components.sessionInsights.index
                                                .edit
                                        }
                                    </Button>
                                )}
                            </Space>
                        </Form>
                    );
                },
                () => {
                    return (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ flex: 1 }}>
                            <Button onClick={onGen} type="primary">{/* 生成机会点 */}{UdeskLocales['current'].components.sessionInsights.components.opportunity.index.generateOpportunityPoints}</Button>
                        </Empty>
                    );
                }
            )}
        </Spin>
    );
};

export default memo(Template);
