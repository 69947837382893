// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PutInspectionTasksRankBody, BaseResponseVoid } from '../../types';

/**
 * rankTask
 *
 * @export
 * @tags 质检任务
 * @link [PUT] /inspectionTasks/rank
 */
export function putInspectionTasksRank(data: PutInspectionTasksRankBody): Promise<BaseResponseVoid>;
export function putInspectionTasksRank(
    data: PutInspectionTasksRankBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putInspectionTasksRank(
    data: PutInspectionTasksRankBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutInspectionTasksRankBody, never, never>('/inspectionTasks/rank', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putInspectionTasksRank',
    });
}

export const meta = [{ tags: ['质检任务'], path: '/inspectionTasks/rank', method: 'put' }];
