// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskIdParam,
    PostIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskIdBody,
    BaseResponseFinishRecordResultAnalysisSpeedScore,
} from '../../../../../types';

/**
 * 获取表达分析
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/speed/analysis/record/{taskId}
 * @param taskId
 */
export function postIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskId(
    data: PostIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskIdBody,
    options: ApiOptions<PostIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskIdParam, never> & {
        segments: PostIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskIdParam;
    }
): Promise<BaseResponseFinishRecordResultAnalysisSpeedScore> {
    return request<
        PostIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskIdBody,
        PostIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskIdParam,
        never
    >('/intelligentPartnerTaskInfos/speed/analysis/record/{taskId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskId',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/speed/analysis/record/{taskId}',
        method: 'post',
    },
];
