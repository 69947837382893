import TasksCheckPointIndexComponent from './component';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

export default class TasksCheckPointIndexRoute extends TasksCheckPointIndexComponent {
    model() {
        const { props } = this;
        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );
        let taskVersionPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/${params.taskId}/version`,
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index
                                    .title
                            )
                    );
                    reject(reason);
                }
            );
        });

        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionTasks/' + params.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index
                                    .title
                            )
                    );
                    reject(reason);
                }
            );
        });
        let sysConfigPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/companys/sys/config',
                this.props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            taskId: params.taskId,
            task: props.sdkOptions.props.task,
            inspectionConditions: props.sdkOptions.props.inspectionConditions,
            status: modelPromise,
            taskVersion: taskVersionPromise,
            sysConfig: sysConfigPromise,
        };
    }
}
