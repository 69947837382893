import React from 'react';
import DataSourceBaseClass from './data-source-base-class';
import AutoCompleteComponent from "../auto-complete/index";
import Udesk from '../../udesk/index';

class AutoComplete extends DataSourceBaseClass {
    actions = {
        _onInputValueChanged(internalValue) {
            this.actions._onChanged(internalValue);
        },
        _validate() {
            return true;
        }
    }

    static get emberName() {
        return 'udesk/custom-field-inputs/auto-complete';
    }

    render() {
        let {
            privates: { computes },
        } = this;

        let {
            internalValue,
            mergedAttributes
        } = computes;


        let {
            disabled,
            dataSource,
            dataSourceValueFeild,
            dataSourceNameField,
            DefaultValueField,
            DefaultNameField,
        } = this.props;
        let valueField = DefaultValueField;
        if (mergedAttributes[dataSourceValueFeild] != null) {
            valueField = mergedAttributes[dataSourceValueFeild];
        }
        let nameField = DefaultNameField;
        if (mergedAttributes[dataSourceNameField] != null) {
            nameField = mergedAttributes[dataSourceNameField];
        }

        return (
            <AutoCompleteComponent value={internalValue} classNames={mergedAttributes.classNames} disabled={disabled} options={dataSource} valueField={valueField} nameField={nameField} placeholder={mergedAttributes.placeholder} isMulti={mergedAttributes.isMultiSelect} url={mergedAttributes.url} method={mergedAttributes.method} queryParamName={mergedAttributes.queryParamName} loadOptions={mergedAttributes.loadOptions} getQueryParams={mergedAttributes.getQueryParams} getQueryResults={mergedAttributes.getQueryResults} customStyle={mergedAttributes.customStyle} extraQueryParams={mergedAttributes.extraQueryParams} defaultOptions={mergedAttributes.defaultOptions} noOptionsMessage={mergedAttributes.noOptionsMessage} onChanged={this.actions._onInputValueChanged} enableEmptySearch={mergedAttributes.enableEmptySearch} isClearable={mergedAttributes.isClearable} />
        );
    }
}

export default Udesk.react.udeskify(AutoComplete);