import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import styled from 'styled-components';
import SessionDetectionForm, { initDefaultValues } from './SessionDetectionForm';
import RobotTimbreForm, { DefaultConfig } from './RobotTimbreForm';
import InteractionRuleForm from './InteractionRuleForm';
import UdeskLocales from 'UdeskLocales';
import _isEqual from 'lodash-es/isEqual';
import {
    Button,
    Drawer,
    Space,
    FormBuilder,
    Form,
    Tabs,
} from 'udesk-ui';
import { postConceptWordsFindAll } from 'src/api/conceptWords/findAll';
import { getReviewIntelligentPartnerDialogFlowConfig, postReviewIntelligentPartnerDialogFlowConfig } from 'src/api/review/intelligentPartner/dialogFlow/config';
import { IntelligentPartnerDialogFlowGlobalConfigFoundResponse } from 'src/api/types';
import { putReviewIntelligentPartnerDialogFlowConfigById } from 'src/api/review/intelligentPartner/dialogFlow/config/{id}';
import './style.scss';

const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Div = styled.div`
    height: 100%;
    overflow: auto;
    padding: 5px 24px;

    .udesk-qa-ui-form-builder-container {
        .udesk-qa-ui-space-item {
            > .udesk-qa-ui-form-item {
                margin-bottom: 0;
            }
        }
    }
`;

const LAYOUT = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

const booleanToNumber = (values) => {
    Object.keys(values).forEach((key) => {
        if (typeof values[key] === 'boolean') {
            values[key] = Number(values[key]);
        }
    });
    return values;
};

export const DetailDrawerTitle = React.memo((props: any) => {
    const { onClose, onSubmit, title, submitText, showCancel = true, disabled=false, readOnly } = props;
    return (
        <TitleWrap className="udesk-coach-web-scoring-rules-drawer-title">
            <div>{title}</div>
            <Space>
                {showCancel && <Button onClick={onClose}>{/* 取消 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.globalConfigDrawer.cancel}</Button>}
                {!readOnly && (
                    <Button onClick={onSubmit} type="primary" disabled={disabled}>
                        {submitText ||
                        /* 确定 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                            .components.globalConfigDrawer.determine}
                    </Button>
                )}
            </Space>
        </TitleWrap>
    );
});

const InitializedConfigDrawer = React.memo((props: any) => {
    const { onClose, flowId, readOnly, ...drawerProps } = props;
    const locales = UdeskLocales['current'];
    const [conceptWords, setConceptWords] = useState<any[]>([]);
    const [id, setId] = useState<null | number>(null);
    const [activeTab, setActiveTab] = useState('0');

    const [sessionDetectionForm] = FormBuilder.useForm();
    const [interactionRuleForm] = Form.useForm();
    const [robotTimbreForm] = Form.useForm();
    const [data, setData] = useState<IntelligentPartnerDialogFlowGlobalConfigFoundResponse>();
    const [isDisabled, setIsDisabled] = useState(true);

    const form = useMemo(() => {
        if (activeTab === '0') {
            return sessionDetectionForm;
        }
        if (activeTab === '1') {
            return interactionRuleForm;
        }
        return robotTimbreForm;
    }, [
        activeTab, 
        interactionRuleForm, 
        robotTimbreForm, 
        sessionDetectionForm
    ]);

    useEffect(() => {
        postConceptWordsFindAll({}).then((resp) => {
            setConceptWords((resp.data ?? []).map((i) => ({ label: i.name, value: i.id })));
        });
    }, []);

    useEffect(() => {
        if (!flowId) return;
        getReviewIntelligentPartnerDialogFlowConfig({
            params: {
                flowId,
            },
        }).then((resp) => {
            if (resp?.data) {
                const data = initDefaultValues(resp.data);
                // 初始化默认值
                if (!data.robotTimbreConfig) {
                    data.robotTimbreConfig = DefaultConfig;
                }
                if (!data.enableModalParticleTooMuchDetection) {
                    data.enableModalParticleTooMuchDetection = 0;
                    data.modalParticleTooMuchConfig = {};
                }
                if (!data.timeoutHintWords) {
                    data.timeoutHintWords = [];
                }
                setData(data);
                setId(data?.id ?? null);
                sessionDetectionForm.setFieldsValue(data);
                interactionRuleForm.setFieldsValue(data);
                robotTimbreForm.setFieldsValue(data.robotTimbreConfig);
            }
        });
    }, [
        flowId, 
        interactionRuleForm, 
        robotTimbreForm, 
        sessionDetectionForm
    ]);

    const submitHandle = useCallback((values: any) => {
        let params;
        if (activeTab === '2') {
            params = { ...data, robotTimbreConfig: values, flowId };
        } else {
            params = { ...data, ...values, flowId };
        }
        
        const success = () => {
            setData(params);
            Udesk.ui.notify.success(locales.business.info.saveSuccess);
        };
        const error = reason => {
            Udesk.ui.notify.error(reason.errorMsg);
        };

        booleanToNumber(params);
        if (id) {
            putReviewIntelligentPartnerDialogFlowConfigById(params, {
                segments: {
                    id,
                },
            }).then(success, error);
        } else {
            postReviewIntelligentPartnerDialogFlowConfig(params).then(success, error);
        }
    }, [
        activeTab, 
        id, 
        data, 
        flowId, 
        locales.business.info.saveSuccess
    ]);
      
    const isEqual = useCallback(() => {
        if (data) {
            let values = form.getFieldsValue();
            let timeoutHintWords = values.timeoutHintWords;
            let cache: any = $.extend({}, data);
            
            if (activeTab === '2') {
                cache = data?.robotTimbreConfig;
            } else {
                values = $.extend(true, {}, data, values);
                if (timeoutHintWords) {
                    values.timeoutHintWords = timeoutHintWords;
                }
            }

            return _isEqual(booleanToNumber(cache), booleanToNumber(values));
        }
        return false;
    }, [activeTab, data, form]);

    const changeHandle = useCallback((...args) => {
        setIsDisabled(isEqual());
    }, [isEqual]);

    useEffect(() => {
        setIsDisabled(isEqual());
    }, [isEqual]);

    return (
        <Drawer
            {...drawerProps}
            mask={false}
            width={700}
            closable={false}
            title={
                <DetailDrawerTitle
                    title={/* 全局配置 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.globalConfigDrawer.globalConfiguration}
                    disabled={isDisabled}
                    onClose={onClose}
                    onSubmit={() => {
                        form.submit();
                    }}
                    readOnly={readOnly}
                />
            }
            className='beautiful-scrollbar'
            bodyStyle={{
                padding: '0'
            }}
            headerStyle={{
                paddingBottom: 0,
                borderBottomWidth: 0
            }}
        >
            <Tabs defaultActiveKey={activeTab} onChange={key => setActiveTab(key)} className='udesk-coach-flow-config-drawer-tabs'>
                <Tabs.TabPane tab={/* 会话检测 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.globalConfigDrawer.sessionDetection} key='0'>
                    <Div>
                        <SessionDetectionForm {...{
                            data,
                            submit: submitHandle, change: changeHandle,
                            form: sessionDetectionForm, 
                            conceptWords, layout: LAYOUT
                        }}/>
                    </Div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={/* 交互规则 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.globalConfigDrawer.reciprocalRule} key='1'>
                    <Div>
                        <InteractionRuleForm submit={submitHandle} change={changeHandle} form={interactionRuleForm} />
                    </Div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={/* 机器人音色 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.globalConfigDrawer.robotVoice} key='2'>
                    <Div>
                        <RobotTimbreForm submit={submitHandle} change={changeHandle} form={robotTimbreForm} />
                    </Div>
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    );
});

const useDrawer = () => {
    const [visible, setVisible] = useState(false);

    const onClose = () => setVisible(false);
    const openDrawer = () => setVisible(true);

    return {
        visible,
        onClose,
        openDrawer,
    };
};

type ConfigDrawerType = typeof InitializedConfigDrawer & {
    useDrawer: typeof useDrawer;
};
const GlobalConfigDrawer: ConfigDrawerType = InitializedConfigDrawer as ConfigDrawerType;

GlobalConfigDrawer.useDrawer = useDrawer;
export { GlobalConfigDrawer };