import { postStorageCreateResource } from 'src/api/storage/createResource';
import Udesk from 'Udesk';

export const getToken = (type = 2) => {
    return Udesk.business.getServerAuthorization({
        type,
    });
};

const defaultSuccessCallback = (file, uploadData, successCallback, errorCallback) => {
    return postStorageCreateResource({
        keyName: uploadData.key
    }).then(
        resp => successCallback({name: file.name, url: resp.data}, file, uploadData),
        errorCallback
    );
};

export const UploadFile = (isCreateLocalResource, file, successCallback, errorCallback) => {
    if (typeof isCreateLocalResource !== 'boolean') {
        errorCallback = successCallback;
        successCallback = file;
        file = isCreateLocalResource;
        isCreateLocalResource = true;
    }
    const customerSuccessCallback = successCallback;
    if (isCreateLocalResource) {
        successCallback = (resp, uploadData) => {
            return defaultSuccessCallback(file, uploadData, customerSuccessCallback, errorCallback);
        };
    }

    return Udesk.app.fileStorage.upload(
        file, 
        {
            token: getToken(),
            errorCallback,
            successCallback,
        }
    );
};