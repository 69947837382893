import React, { useEffect, useState } from 'react';
import { Form as AlifdForm} from '@alifd/next';
import { FormBuilder as AntForm, Select, TreeSelect, Radio, Space, Icon } from 'udesk-ui';
import { getKnowledgeBaseSearch } from 'src/api/knowledgeBase/search';
import { getLibraryFilesPublicSearch } from 'src/api/libraryFiles/public/search';
import Locales from 'UdeskLocales';

export function findDefaultExpandedKeys(list: any[], type: number, result = new Array<number>(), deep = 0) {
    list.forEach((item) => {
        if (item.children) {
            result.push(item.key);
            if (deep + 1 < 2) {
                findDefaultExpandedKeys(item.children, type, result, deep + 1);
            }
        }
    });
    return result;
}

export function array2tree(list: any[], filter?: any, id?: string, pId?: string, children?: string, parent?: string) {
    let i = 0,
        l = list.length;
    if (!list || l === 0) {
        return [];
    }

    let tree = [] as any[],
        tmp = {} as any;

    if (typeof filter === 'string') {
        parent = children;
        children = pId;
        pId = id;
        id = filter;
        filter = !1;
    }

    id = id || 'id';
    pId = pId || 'parentId';
    children = children || 'children';

    if (filter) {
        for (i = 0; i < l; i++) {
            if (filter(list[i])) {
                tmp[list[i][id]] = list[i];
            }
        }
    } else {
        for (i = 0; i < l; i++) {
            tmp[list[i][id]] = list[i];
        }
    }

    for (i = 0; i < l; i++) {
        if (tmp[list[i][id]]) {
            if (tmp[list[i][pId]]) {
                (tmp[list[i][pId]][children] =
                    (parent ? (list[i][parent] = tmp[list[i][pId]]) : tmp[list[i][pId]])[children] || []).push(list[i]);
            } else {
                tree.push(list[i]);
            }
        }
    }
    return parent ? { list: tree, cache: tmp } : tree;
}

const formatIds = (ids: string | number[]) => {
    if (ids) {
        if (typeof ids === 'string') {
            return ids.split(',').map((id) => +id);
        }
        return ids;
    }
    return [];
};

const getIcon = (prop: any) => {
    const {expanded, eventKey} = prop;
    const key = eventKey + '';

    return key.indexOf('/') >= 0 ? (
        expanded ? (
            <Icon type='FolderOpenOutlined' antdIcon={true} />
        ) : (
            <Icon type='FolderOutlined' antdIcon={true} />
        )
    ) : (
        <Icon type='FileTextOutlined' antdIcon={true} />
    );
};

function IntelligentRecommend(props) {
    const { type, value, knowledgeBases, libraryFiles, formItemLayout } = props;
    const locales = Locales['current'];
    const [knowledgeBaseOptions, setKnowledgeBasesOptions] = useState<any[]>([]);
    const [knowledgeBaseDefaultExpandedKeys, setKnowledgeBaseDefaultExpandedKeys] = useState<number[]>([]);
    const [libraryFileOptions, setLibraryFileOptions] = useState<any[]>([]);
    const [libraryFileDefaultExpandedKeys, setLibraryFileDefaultExpandedKeys] = useState<number[]>([]);

    const changeHandle = (value: any, attr?: string) => {
        props.onChange?.(value, attr);
    };

    const Form = type ? AlifdForm : AntForm;

    const initSelectProps = (options, expandedKeys) => {
        return {
            style: {
                width: 300,
            },
            treeNodeFilterProp: 'title',
            treeDefaultExpandedKeys: expandedKeys,
            treeData: options,
            showSearch: true,
            multiple: true,
            allowClear: true,
            virtual: true,
            treeIcon: true, // 此属性与node数据中的icon配合使用
            showArrow: true,
        };
    };

    const getOptions = () => {
        getKnowledgeBaseSearch()
            .then((res) => {
                const result = new Array<any>();
                if (res.data) {
                    res.data.forEach((item) => {
                        const id = ['category', item.id].join('/');
                        const category = {
                            key: id,
                            value: id,
                            icon: getIcon,
                            selectable: false,
                            children: null as any,
                            title: item.categoryName,
                        };
                        const children = item.knowledgePointDetailList || [];
                        if (children.length > 0) {
                            category.children = children.map((item) => {
                                return {
                                    key: item.id,
                                    value: item.id,
                                    icon: getIcon,
                                    title: item.pointName,
                                };
                            });
                            result.push(category);
                        }
                    });
                }

                return result;
            })
            .then((list) => {
                setKnowledgeBaseDefaultExpandedKeys(findDefaultExpandedKeys(list, type));
                setKnowledgeBasesOptions(list);
            });
        getLibraryFilesPublicSearch({
            params: {
                name: '',
            },
        })
            .then((res) => {
                const fileList: any[] = [];
                const cache: any = {};

                const addParent = (id) => {
                    const parent = cache[id];
                    if (parent) {
                        if (fileList.indexOf(parent) === -1) {
                            fileList.push(parent);
                        }
                        addParent(parent.parentId);
                    }
                };

                const createFolder = (list) => {
                    if (list) {
                        list.forEach((item) => {
                            const { libraryCallList, children } = item;
                            if (children) {
                                createFolder(children);
                                if (libraryCallList && libraryCallList.length > 0) {
                                    const folderId = ['folder', item.id].join('/');
                                    item.children.unshift({
                                        key: folderId,
                                        value: folderId,
                                        selectable: false,
                                        icon: getIcon,
                                        title: /* 案例分组 */locales.pages.intelligentAnalysis.supervision.detail.recommendation.caseGrouping,
                                        children: libraryCallList.map((item) => {
                                            return {
                                                key: item.id,
                                                value: item.id,
                                                icon: getIcon,
                                                title: item.name,
                                            };
                                        }),
                                    });
                                }
                            } else {
                                if (libraryCallList && libraryCallList.length > 0) {
                                    item.children = libraryCallList.map((item) => {
                                        return {
                                            key: item.id,
                                            value: item.id,
                                            icon: getIcon,
                                            title: item.name,
                                        };
                                    });
                                }
                            }
                        });
                    }
                    return list;
                };

                res.data?.forEach((item) => {
                    const list = item.libraryCallList;
                    if (list && list.length > 0) {
                        fileList.push(item);
                    }
                    cache[item.id!] = item;
                });

                fileList.forEach((item) => addParent(item.parentId));

                const tree = array2tree(fileList!, (item) => {
                    const parentId = ['parent', item.id].join('/');
                    item.key = parentId;
                    item.value = parentId;
                    item.selectable = false;
                    item.icon = getIcon;
                    item.title = item.name;
                    return !0;
                }) as [];

                return createFolder(tree);
            })
            .then((list) => {
                setLibraryFileDefaultExpandedKeys(findDefaultExpandedKeys(list, type));
                setLibraryFileOptions(list);
            });
    };

    useEffect(() => {
        getOptions();
    }, []);

    return (
        <>
            <Form.Item
                tooltip={/* 当员工执行不到位时，可智能推荐优秀话术和优秀案例 */locales.pages.intelligentAnalysis.supervision.detail.recommendation.whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases}
                label={/* 智能推荐 */locales.pages.intelligentAnalysis.supervision.detail.recommendation.smartRecommendation}
                {...formItemLayout}
                name='smartRecommendStatus'
            >
                <Radio.Group
                    size='small'
                    value={value || 0}
                    onChange={(e) => changeHandle(e.target.value, 'smartRecommendStatus')}
                    buttonStyle='solid'
                >
                    <Radio.Button value={1}>{/* 开启 */}{locales.pages.intelligentAnalysis.supervision.detail.recommendation.open}</Radio.Button>
                    <Radio.Button value={0}>{/* 关闭 */}{locales.pages.intelligentAnalysis.supervision.detail.recommendation.close}</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {!!value && (
                <>
                    {!type && (
                        <Form.Item label={/* 当此监督项 */locales.pages.intelligentAnalysis.supervision.detail.recommendation.whenThisSupervisionItem}>
                            <Space>
                                <Form.Item noStyle name='inspectionExecuteStatus'>
                                    <Select
                                        style={{
                                            width: 120,
                                        }}
                                        options={[
                                            { label: /* 执行 */locales.pages.intelligentAnalysis.supervision.detail.recommendation.implement, value: 1 },
                                            { label: /* 未执行 */locales.pages.intelligentAnalysis.supervision.detail.recommendation.unexecuted, value: 0 },
                                        ]}
                                    />
                                </Form.Item>
                                <span>{/* 时： */}{locales.pages.intelligentAnalysis.supervision.detail.recommendation.when}</span>
                            </Space>
                        </Form.Item>
                    )}
                    <Form.Item {...formItemLayout} name='knowledgeBases' label={/* 推荐知识点 */locales.pages.intelligentAnalysis.supervision.detail.recommendation.recommendedKnowledgePoints}>
                        <TreeSelect
                            {...(type
                                ? {
                                    value: formatIds(knowledgeBases),
                                    onChange(value) {
                                        changeHandle(value, 'knowledgeBases');
                                    },
                                }
                                : {})}
                            {...initSelectProps(knowledgeBaseOptions, knowledgeBaseDefaultExpandedKeys)}
                        />
                    </Form.Item>
                    <Form.Item {...formItemLayout} name='libraryFiles' label={/* 案例推荐 */locales.pages.intelligentAnalysis.supervision.detail.recommendation.caseRecommendation}>
                        <TreeSelect
                            {...(type
                                ? {
                                    value: formatIds(libraryFiles),
                                    onChange(value) {
                                        changeHandle(value, 'libraryFiles');
                                    },
                                }
                                : {})}
                            {...initSelectProps(libraryFileOptions, libraryFileDefaultExpandedKeys)}
                        />
                    </Form.Item>
                </>
            )}
        </>
    );
}

export default IntelligentRecommend;
