import xss from './xss';

export default {
    xss,
};

export function initWidgets() {

}

export function destroy() {

}