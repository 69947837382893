import React, { useState } from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Select,
    Space,
    Card,
    message,
    Tabs,
    Tooltip,
    Icon,
} from 'udesk-ui';
import { postReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowId } from 'src/api/review/intelligentPartner/node/pptConfig/singleAiWords/{flowId}';
import { postReviewIntelligentPartnerNodePptConfigWordsPolish } from 'src/api/review/intelligentPartner/node/pptConfig/wordsPolish';
import UdeskLocales from 'UdeskLocales';

const TabsKeyMap = {
    AiGen: 'aiGen',
    AiPolish: 'aiPolish',
};

export default React.memo((props: any) => {
    const { value, onChange, flowId, configId, ...textareaProps } = props;

    const [activeKey, setActiveKey] = useState(TabsKeyMap.AiGen);

    const onActiveKeyChange = (k) => {
        setActiveKey(k);
        setWords('');
    };

    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const onShow = () => {
        setVisible(true);
    };

    const onCancel = () => {
        setVisible(false);
        setWords('');
        form.setFieldsValue({ wordsStyle: 1, role: undefined });
        setActiveKey(TabsKeyMap.AiGen);
    };

    const [words, setWords] = useState('');
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        if (activeKey === TabsKeyMap.AiGen) {
            setLoading(true);
            postReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowId(
                {
                    role: values.role,
                    wordsStyle: values.wordsStyle,
                    configId,
                },
                { segments: { flowId } }
            )
                .then((res) => {
                    setWords(res.data || '');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (activeKey === TabsKeyMap.AiPolish) {
            if (!value) {
                message.warning(/* 话术不能为空！ */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.theScriptCannotBeEmpty);
                return;
            }
            setLoading(true);
            postReviewIntelligentPartnerNodePptConfigWordsPolish({
                role: values.role,
                wordsStyle: values.wordsStyle,
                words: value,
            })
                .then((res) => {
                    setWords(res.data || '');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onCopy = () => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', words);
        input.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            message.success(/* 复制成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.copySuccessful);
        }
        document.body.removeChild(input);
    };

    const onReplace = () => {
        onChange(words);
        onCancel();
    };

    return (
        <>
            <Space direction="vertical" style={{ display: 'flex' }}>
                <div style={{ position: 'relative' }}>
                    <Input.TextArea value={value} onChange={onChange} {...textareaProps} />
                    <Tooltip mouseEnterDelay={1} color={'blue'} placement="left" title="Ai">
                        <Button
                            onClick={onShow}
                            icon={<Icon type="zhinengtuijian" />}
                            type="link"
                            style={{ position: 'absolute', bottom: 0, right: 0 }}
                        />
                    </Tooltip>
                </div>
            </Space>

            <Modal
                visible={visible}
                title={null}
                onCancel={onCancel}
                footer={
                    <Space>
                        <Button onClick={onCancel}>{/* 取消 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.cancel}</Button>
                        <Button type="primary" disabled={!words} onClick={onCopy}>{/* 复制 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.copy}</Button>
                        <Button type="primary" disabled={!words} onClick={onReplace}>{/* 替换原文 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.replaceTheOriginalText}</Button>
                    </Space>
                }
            >
                <Tabs activeKey={activeKey} onChange={onActiveKeyChange}>
                    <Tabs.TabPane tab={/* Ai生成 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.aiGeneration} key={TabsKeyMap.AiGen} />
                    <Tabs.TabPane tab={/* Ai润色 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.aiPolishing} key={TabsKeyMap.AiPolish} />
                </Tabs>

                <Form form={form} onFinish={onFinish} initialValues={{ wordsStyle: 1 }}>
                    <Form.Item label={/* 当前风格 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.currentStyle} required>
                        <Space>
                            <Form.Item noStyle name="wordsStyle">
                                <Select
                                    style={{ width: 140 }}
                                    options={[
                                        {
                                            label: /* 严谨 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.rigorous,
                                            value: 1,
                                        },
                                        {
                                            label: /* 温和 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.mild,
                                            value: 2,
                                        },
                                        {
                                            label: /* 活泼 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.lively,
                                            value: 3,
                                        },
                                        {
                                            label: /* 简约 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.simplicity,
                                            value: 4,
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item noStyle name="role">
                                <Input placeholder={/* 请输入说话者角色 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.pleaseEnterTheSpeakerRole} />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}
                                    loading={loading}
                                >{/* 开始生成 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiWordsTextArea.startGenerating}</Button>
                            </Form.Item>
                        </Space>
                    </Form.Item>
                </Form>

                <Card bodyStyle={{ backgroundColor: '#eee' }}>
                    <div style={{ height: 160, overflowY: 'auto' }}>{words}</div>
                </Card>
            </Modal>
        </>
    );
});
