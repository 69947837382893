// @ts-nocheck

import { request } from '../../../../../../../context';
import type { ApiOptions } from '../../../../../../../context';
import type {
    DeleteReviewIntelligentPartnerNodePptTestLogClearByNodeIdParam,
    BaseResponseVoid,
} from '../../../../../../../types';

/**
 * ppt清除测试记录
 *
 * @export
 * @tags intelligent-partner-dialog-node-ppt-test-log-controller
 * @link [DELETE] /review/intelligentPartner/node/ppt/testLog/clear/{nodeId}
 * @param nodeId
 */
export function deleteReviewIntelligentPartnerNodePptTestLogClearByNodeId(
    options: ApiOptions<DeleteReviewIntelligentPartnerNodePptTestLogClearByNodeIdParam, never> & {
        segments: DeleteReviewIntelligentPartnerNodePptTestLogClearByNodeIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReviewIntelligentPartnerNodePptTestLogClearByNodeIdParam, never>(
        '/review/intelligentPartner/node/ppt/testLog/clear/{nodeId}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerNodePptTestLogClearByNodeId',
        }
    );
}

export const meta = [
    {
        tags: ['intelligent-partner-dialog-node-ppt-test-log-controller'],
        path: '/review/intelligentPartner/node/ppt/testLog/clear/{nodeId}',
        method: 'delete',
    },
];
