// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetAutoMateTasksCaseByTaskIdParam,
    BaseResponseListAutoMateCaseFoundResponse,
    PostAutoMateTasksCaseByTaskIdParam,
    PostAutoMateTasksCaseByTaskIdBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 案例库同步-查询
 *
 * @export
 * @tags 案例库同步, 自动化配置
 * @link [GET] /autoMateTasks/case/{taskId}
 * @param taskId
 */
export function getAutoMateTasksCaseByTaskId(
    options: ApiOptions<GetAutoMateTasksCaseByTaskIdParam, never> & {
        segments: GetAutoMateTasksCaseByTaskIdParam;
    }
): Promise<BaseResponseListAutoMateCaseFoundResponse> {
    return request<never, GetAutoMateTasksCaseByTaskIdParam, never>(
        '/autoMateTasks/case/{taskId}',
        { ...options, method: 'get', __$requestCalleeName: 'getAutoMateTasksCaseByTaskId' }
    );
}

/**
 * 案例库同步-保存
 *
 * @export
 * @tags 案例库同步, 自动化配置
 * @link [POST] /autoMateTasks/case/{taskId}
 * @param taskId
 */
export function postAutoMateTasksCaseByTaskId(
    data: PostAutoMateTasksCaseByTaskIdBody,
    options: ApiOptions<PostAutoMateTasksCaseByTaskIdParam, never> & {
        segments: PostAutoMateTasksCaseByTaskIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PostAutoMateTasksCaseByTaskIdBody, PostAutoMateTasksCaseByTaskIdParam, never>(
        '/autoMateTasks/case/{taskId}',
        { ...options, data, method: 'post', __$requestCalleeName: 'postAutoMateTasksCaseByTaskId' }
    );
}

export const meta = [
    { tags: ['案例库同步', '自动化配置'], path: '/autoMateTasks/case/{taskId}', method: 'get' },
    { tags: ['案例库同步', '自动化配置'], path: '/autoMateTasks/case/{taskId}', method: 'post' },
];
