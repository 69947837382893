import UdeskLocales from "UdeskLocales";
import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';
class TasksCheckPointEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: this.props.match.params
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let {
            routes,
            route,
            location,
            sdkOptions,
            match
        } = this.props;
        
        if (this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name,
                pathParams: match.params
            });
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else {
            return (
                <div style={{height: '100%'}}>
                    {routes.map((routeItem, i) => (
                        <SubRoutes key={i} route={routeItem} showModelError={true}/>
                    ))}
                </div>
            );
        }
    }

    model() {
        const { props } = this;
        let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        params.type = 1;
        let inspectionConditionsPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionConditions",
                props.sdkOptions
            );
            Udesk.ajax.get(url, params)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.condition.index.title));
                        reject(reason);
                    }
                );
        });

        return {
            inspectionConditions: inspectionConditionsPromise
        };
    }

    onModelResolved(model) {
        this.props.sdkOptions.props.inspectionConditions = model.inspectionConditions;
    }
    componentWillUnmount() {
        // 清理掉相关数据，否则会污染其他页面
        this.props.sdkOptions.props.inspectionConditions = null;
    }
}
export default Udesk.react.udeskify(TasksCheckPointEntry);