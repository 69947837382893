import React from 'react';
import SynonymWordsRoute from './route';
import ReactModal from 'udesk-react/src/components/react-modal';

export default class SmartWordsLibraryUploadModalTemplate extends SynonymWordsRoute {

    render() {
        let { locales, actions } = this;
        let {
            visible,
            title,
            onCancel,
            headerClassName,
            boardName,
        } = this.props;
        return (
            <div>
                <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save}
                    visible={visible}
                    title={title}
                    headerClassName={headerClassName}
                    footer={null}
                    onCancel={onCancel}
                    boardName={boardName}
                >
                    <div className="udesk-qa-upload-modal-content">

                        <div className="upload-modal-content-top udesk-qa-upload-modal-item">
                            <span className="upload-modal-top-json-button-download-template-message">
                                {locales.components.pages.smartWordsLibrary.upload.downloadTemplateMessage}
                            </span>
                            <With isCorpusContentTitle={this.props.title === locales.components.pages.smartWordsLibrary.upload.uploadCorpusData}>

                                <If condition={!isCorpusContentTitle}>
                                    <button type="button" className="upload-item-btn upload-modal-top-excel-btn" onClick={actions.downloadTemplate.params('xlsx')} >
                                        {locales.components.pages.smartWordsLibrary.upload.firstUploadExcelButton}
                                    </button>
                                    {/* <button type="button" className="upload-item-btn upload-modal-top-json-btn" onClick={actions.downloadTemplate.params('json')}>
                                        {locales.components.pages.smartWordsLibrary.upload.firstUploadJSONButton}
                                    </button> */}
                                </If>

                                <If condition={isCorpusContentTitle}>
                                    <button type="button" className="upload-item-btn upload-modal-top-txt-btn" onClick={actions.downloadTemplate.params('txt')}>
                                        {locales.components.pages.smartWordsLibrary.upload.firstUploadTxtButton}
                                    </button>
                                </If>
                            </With>

                        </div>

                        <div className="upload-modal-content-first-step-information udesk-qa-upload-modal-item">
                            <span>{locales.components.pages.smartWordsLibrary.upload.firstUploadStep}</span>
                        </div>

                        <div className="upload-modal-content-secnond-step-information udesk-qa-upload-modal-item">
                            <With isCorpusContentTitle={this.props.title === locales.components.pages.smartWordsLibrary.upload.uploadCorpusData}>
                                <If condition={isCorpusContentTitle}>
                                    <span>{locales.components.pages.smartWordsLibrary.upload.secondUploadCorpusDataStep}</span>
                                </If>
                                <If condition={!isCorpusContentTitle}>
                                    <span>{locales.components.pages.smartWordsLibrary.upload.secondUploadStep}</span>
                                </If>
                            </With>

                        </div>

                        <div className="upload-modal-content-bottom">
                            <label htmlFor="uploadfile" className="upload-item-btn upload-modal-content-bottom-upload-btn">
                                {locales.labels.batchingImport}
                            </label>
                            <input className="upload-file-input" type="file" name="uploadfile" id="uploadfile" onInput={actions.upload} />
                            <button type="button" className="upload-item-btn upload-modal-content-bottom-cancel-btn" onClick={onCancel}>{locales.labels.cancel}</button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

