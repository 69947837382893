// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewSpectacularsSaleStageByCustomerIdParam,
    BaseResponseCustomerSaleAndStageFoundResponse,
    PostReviewSpectacularsSaleStageByCustomerIdParam,
    PostReviewSpectacularsSaleStageByCustomerIdBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 查询当前客户商机阶段
 *
 * @export
 * @tags 客户看板-商机阶段
 * @link [GET] /review/spectaculars/saleStage/{customerId}
 * @param customerId
 */
export function getReviewSpectacularsSaleStageByCustomerId(
    options: ApiOptions<GetReviewSpectacularsSaleStageByCustomerIdParam, never> & {
        segments: GetReviewSpectacularsSaleStageByCustomerIdParam;
    }
): Promise<BaseResponseCustomerSaleAndStageFoundResponse> {
    return request<never, GetReviewSpectacularsSaleStageByCustomerIdParam, never>(
        '/review/spectaculars/saleStage/{customerId}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewSpectacularsSaleStageByCustomerId',
        }
    );
}

/**
 * 变更客户商机当前阶段
 *
 * @export
 * @tags 客户看板-商机阶段
 * @link [POST] /review/spectaculars/saleStage/{customerId}
 * @param customerId
 */
export function postReviewSpectacularsSaleStageByCustomerId(
    data: PostReviewSpectacularsSaleStageByCustomerIdBody,
    options: ApiOptions<PostReviewSpectacularsSaleStageByCustomerIdParam, never> & {
        segments: PostReviewSpectacularsSaleStageByCustomerIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PostReviewSpectacularsSaleStageByCustomerIdBody,
        PostReviewSpectacularsSaleStageByCustomerIdParam,
        never
    >('/review/spectaculars/saleStage/{customerId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewSpectacularsSaleStageByCustomerId',
    });
}

export const meta = [
    {
        tags: ['客户看板-商机阶段'],
        path: '/review/spectaculars/saleStage/{customerId}',
        method: 'get',
    },
    {
        tags: ['客户看板-商机阶段'],
        path: '/review/spectaculars/saleStage/{customerId}',
        method: 'post',
    },
];
