import Udesk from 'Udesk';
import InformationEntitiesDetailComponent from './component';

class InformationEntitiesDetailRoute extends InformationEntitiesDetailComponent {
    asyncModel() {
        // let {
        //     match,
        //     sdkOptions
        // } = this.props;
        let url = Udesk.business.apiPath.concatApiPath(`/information-entity/module/${this.props.match.params.moduleVersionId}/${this.props.match.params.taskId}`, this.props.sdkOptions);
        return new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                resp => {
                    if (!resp.data.sentenceList) resp.data.sentenceList = [];
                    resolve(resp.data);
                },
                reason => {
                    reject(reason);
                }
            );
        });
    }
}

export default InformationEntitiesDetailRoute;
