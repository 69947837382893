import React, { useEffect, useRef, useState } from 'react';
import { Form, Space, Switch } from "udesk-ui";
import { UserMedia } from '../../camera';
import './index.less';
import UdeskLocales from 'UdeskLocales';

const FacingMode = ['user', 'environment']; // 前置,后置摄像头

export default React.memo((props: any) => {
    const [isShowCamera, setIsShowCamera] = useState<boolean>(true);
    const videoRef = useRef<any>(null);

    useEffect(() => {
        const userMedia = new UserMedia({
            audio: false,
            isDisplay: false,
            video: {
                facingMode: FacingMode[0]
            },
            onDataAvailable (e) {
                // const reader = new FileReader();
                // reader.onload = function() {
                //     console.log(reader.result);
                // };
                // reader.readAsArrayBuffer(e.data);               
            },
            stop () {
                this.download('record.webm');
            },
        }).init(function (stream) {
            videoRef.current.srcObject = stream;
        });

        return () => {
            // 关闭设备通道
            const tracks = userMedia.stream.getTracks();
            tracks.forEach(track => {
                track.stop();
            });
        };
    }, []);
      
    return (
        <Space direction={'vertical'} className={'video-record'}>
            <div className='video-panel'>
                <video
                    style={{
                        display: isShowCamera ? 'block' : 'none'
                    }}
                    width={'100%'}
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                />
            </div>

            <Form layout="horizontal">
                <Form.Item label={/* 摄像头 */UdeskLocales['current'].pages.coach.learningCenter.components.record.video.index.camera}>
                    <Switch checked={isShowCamera} onChange={setIsShowCamera}/>
                </Form.Item>
            </Form>
        </Space>
    );
});

