import OptionsBuilderClass from '../options-builder';
import common from '../../../../udesk/index';

const MAX_VALUE = 10000;

class RadarOptionsBuilderClass extends OptionsBuilderClass {
    _getChartType() {
        return common.enums.echartTypes.radar.key;
    }
    getRadarOptions(optionsOwner) {
        let {
            seriesColumns,
            records
        } = optionsOwner.props;

        let indicator = [];
        records.forEach((record) => {
            indicator.push({
                name: record.xLabelOriginal,
                max: MAX_VALUE
            });
        });
        return {
            name: {
                textStyle: {
                    color: '#000',
                }
            },
            indicator,
        };
    }
    getSeriesOptions(optionsOwner) {
        let {
            records,
            seriesColumns,
        } = optionsOwner.props;

        let field = seriesColumns[0].field;
        let values = [];
        records.forEach((record) => {
            let value = record[field].originalValue * MAX_VALUE;
            values.push(
                value
            );
        });

        return [{
            type: 'radar',
            itemStyle: {
                normal: {
                    areaStyle: {
                        type: 'default'
                    }
                }
            },
            data: [
                {
                    value: values,
                },
            ]
        }];
    }
}

export default RadarOptionsBuilderClass;