export default {
  labels: {
    clear: "ว่างเปล่า",
    refresh: "รีเฟรช",
    nextStep: "ขั้นตอนต่อไป",
    prevStep: "ขั้นตอนก่อนหน้า",
    closeDriver: "ออกจากการบูต",
    complete: "เสร็จแล้ว",
    viewStatistics: "ดูสถิติ",
    colon: "：",
    splitter: "/",
    remark: "หมายเหตุ",
    correction: "แก้ไขข้อผิดพลาด",
    save: "บันทึก",
    reset: "รีเซ็ต",
    increase: "เพิ่ม",
    preview: "ดูตัวอย่าง",
    unit: "แถบ",
    cancel: "การยกเลิก",
    all: "ทั้งหมด",
    searchPlaceHolder: "ป้อนคำค้นหา",
    greaterThan: "มากกว่า",
    lessThan: "น้อยกว่า",
    second: "วินาที",
    description: "คำอธิบาย",
    column: "คอลัมน์",
    pleaseChoice: "โปรดเลือก",
    filter: "การคัดกรอง",
    saveAndSubmit: "บันทึกและส่ง",
    submit: "ส่ง",
    appeal: "การอุทธรณ์",
    review: "รีวิว",
    choose: "เลือก",
    manage: "การจัดการ",
    delete: "ลบ",
    add: "เพิ่มใหม่",
    create: "สร้างใหม่",
    edit: "บรรณาธิการ",
    name: "ชื่อ",
    describe: "คำอธิบาย",
    next: "ขั้นตอนต่อไป",
    percent: "%",
    export: "ส่งออก",
    exportAll: "ส่งออกทั้งหมด",
    confirm: "กำหนด",
    back: "กลับ",
    versionUpdate:
      "ยินดีที่จะแจ้งให้คุณทราบว่าเว็บไซต์ของเราเพิ่งเปิดตัวรุ่นใหม่! เพื่อให้แน่ใจว่าการใช้งานปกติของคุณเราต้องการให้คุณบันทึกการทำงานปัจจุบันของคุณทันทีจากนั้นรีเฟรชหน้า (คลิกปุ่มรีเฟรชของเบราว์เซอร์หรือกดปุ่ม F5) เพื่อให้เวอร์ชันใหม่ของเรามีผลขอบคุณมากสำหรับความร่วมมือ!",
    more: "มากกว่า",
    selected: "เลือกแล้ว",
    deduct: "หัวเข็มขัด",
    maxScore: "มากที่สุด",
    scoreUnit: "แบ่ง",
    pointUnit: "หนึ่ง",
    comma: "，",
    view: "ดู",
    batchingImport: "นำเข้า",
    deleteContent:
      "คุณยืนยันว่าต้องการลบหรือไม่? จะไม่สามารถกู้คืนได้หลังจากลบ",
    import: "นำเข้า",
    uploadFile: "อัปโหลดไฟล์",
    downloadFile: "ส่งออกไฟล์",
    upload: "อัปโหลด",
    click: "คลิก",
    downloadTemplate: "ดาวน์โหลดแม่แบบ",
    fillInTheForm: "กรอกตามรูปแบบ",
    UploadExcelfile: "อัปโหลด Excel เพื่อทำการนำเข้าเป็นกลุ่ม",
    uploadIdentical:
      "หากมีชื่อ API อยู่เมื่อนำเข้าข้อมูลที่มีอยู่ในระบบจะได้รับการอัปเดต",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "ใช้บังคับ",
    leftBracket: "(",
    rightBracket: ")",
    question: "คำถาม",
    answer: "คำตอบ",
    content: "เนื้อหา",
    search: "ค้นหา",
    query: "สอบถามข้อมูล",
    cancelEdit: "ยกเลิกการแก้ไข",
    noValue: "ไม่มีข้อมูล",
    selectAll: "เลือกทั้งหมด",
    changeAGroup: "เปลี่ยนชุด",
    arrow: " ---> ",
    saveReview: "การส่งรีวิว",
    saveSpotCheck: "การส่งแบบสุ่ม",
    rapidSampling: "การสุ่มตัวอย่างอย่างรวดเร็ว",
    file: "เก็บถาวร",
    download: "ดาวน์โหลด",
    lastPage: "ก่อนหน้า",
    nextPage: "หน้าถัดไป",
    batchActions: "การดำเนินการแบทช์",
    cancelDistribution: "ยกเลิกการจัดสรร",
    againDistribution: "แจกจ่ายใหม่",
    deleteRecord: "ลบระเบียน",
    oneOptionOpertor: "ผู้ตรวจสอบคุณภาพเสริม",
    allOptionOpertor: "ผู้ตรวจสอบคุณภาพทั้งหมด",
    distributionInfoFirstOne: "ยืนยัน",
    distributionInfoFirstTwo:
      "มีการแจกจ่ายบันทึกใหม่หรือไม่? โปรดเลือกเป้าหมายการจัดสรร",
    distributionInfoSecondOne: "ตกลงที่จะยกเลิก",
    distributionInfoSecondTwo:
      "ข้อมูลของผู้มอบหมายที่บันทึกไว้หรือไม่จะกลายเป็นบันทึกการตรวจสอบที่ไม่ได้จัดสรรหลังจากการยกเลิก",
    distributionInfoSecondThree:
      "ข้อมูลของผู้มอบหมายที่บันทึกไว้ในบทความหรือไม่จะกลายเป็นบันทึกการตรวจสอบแบบสุ่มที่ไม่ได้จัดสรรหลังจากการยกเลิก",
    distributionInfoThirdOne: "ในบันทึกที่เลือก",
    distributionInfoThirdTwo: "บันทึกที่ได้รับการตรวจสอบแล้วจะไม่ถูกประมวลผล",
    distributionInfoThirdThree:
      "บันทึกที่ได้รับการตรวจสอบแบบสุ่มจะไม่ถูกประมวลผล",
    exportError: "การส่งออกล้มเหลว",
    exportSuccess: "ส่งออกสำเร็จ",
    deleteRecordInfo:
      "คุณยืนยันที่จะลบระเบียน {0} หรือไม่? ข้อมูลจะไม่สามารถกู้คืนได้หลังจากลบ",
    pleaseSetFilter: "กรุณาเลือกเงื่อนไขการคัดกรอง",
    noCountAfterFilter: "ไม่มีข้อมูลที่ส่งออกโปรดรีเซ็ตเกณฑ์การกรอง",
    redo: "ดำเนินการใหม่",
    batchMove: "ย้ายแบทช์",
    websiteName: "การตรวจสอบคุณภาพอัจฉริยะ",
    salesEnable: "การเพิ่มขีดความสามารถในการขาย",
    systemName: "การตรวจสอบคุณภาพอัจฉริยะ",
    initSystemError:
      "ความผิดปกติของเครือข่ายหรือการเริ่มต้นหน้าล้มเหลวโปรดติดต่อฝ่ายบริการลูกค้า",
    day: "วัน",
    hour: "ชั่วโมง",
    minute: "แบ่ง",
    questionMark: "？",
    personalSettings: "การตั้งค่าส่วนบุคคล",
    logout: "ออกจากระบบ",
    ok: "กำหนด",
    saveAs: "บันทึกเป็น",
    pleaseEnter: "กรุณาใส่",
    executeMessage: {
      executeSuccess: "ดำเนินการ {0} สำเร็จ",
      executeError: "การดำเนินการ {0} ล้มเหลว",
    },
    field: "ฟิลด์",
    operator: "ตัวดำเนินการ",
    value: "ค่า",
    placeholder: "โปรดเลือก",
    pleaseSelect: "โปรดเลือก",
    totalScore: "คะแนนรวม",
    hyphen: "-",
    semicolon: "；",
    wfm: "การจัดตารางเวลาอัจฉริยะ",
    user: "ผู้ใช้",
    none: "ไม่มี",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "บันทึกสำเร็จ! พนักงานที่เกี่ยวข้องจะมีผลหลังจากรีเฟรชหน้า",
    saveAsName: "{0}(สำเนา)",
    systemInformation: "ดูข้อมูลระบบ",
    billingCenter: "ศูนย์การเรียกเก็บเงิน",
    warningMessage: "โควต้าที่เหลือไม่เพียงพอโปรดเติมเงินโดยเร็วที่สุด",
    fileTypeHolder: "รูปแบบไฟล์ไม่ถูกต้องโปรดอัปโหลดไฟล์รูปแบบ {0}",
    dataNo: "ไม่มีข้อมูล",
    move: "ย้าย",
    study: "คุณมีจุดตรวจสอบคุณภาพการเรียนรู้ด้วยตนเองที่ไม่มีเครื่องหมาย",
    studySuccess: "ติดฉลากเรียบร้อยแล้ว",
    studyError: "การติดฉลากล้มเหลว",
    editType: "แก้ไขแท็กหมวดหมู่",
    copyed: "คัดลอกไปยังคลิปบอร์ดแล้ว",
    clickToCopy: "คลิกคัดลอก",
    addFilterConditions: "เพิ่มเกณฑ์การกรอง",
    filterConditions: "เงื่อนไขการคัดกรอง",
    messageTime: "เวลาข้อความ",
    messageType: "ประเภทข้อความ",
    pushType: "ช่องข้อความ",
    messageContent: "เนื้อหาข้อความ",
    messageName: "ชื่อข้อความ",
    messageStatus: "สถานะข้อความ",
    configuration: "ข้อมูลการกำหนดค่า",
    sendTarget: "ส่งเป้าหมาย",
    guideUrl: "URL บูต",
    noticeDetail: "รายละเอียดข้อความ",
    action: "การดำเนินงาน",
    seeDetail: "ดูรายละเอียด",
    theDay: "{0} วัน",
    theLastDayOfMonth: "วันสุดท้ายของเดือน",
    ignore: "ละเว้น",
    seeNow: "ดูทันที",
    exportConfirmText: {
      export: "ข้อมูล {0} ทั้งหมดยืนยันการส่งออก?",
      exportAll: "ยืนยันส่งออกเนื้อหาทั้งหมด?",
    },
    samrTagAdd: "เพิ่มแท็ก",
    deletePopconfirmContent: "คุณยืนยันว่าต้องการลบหรือไม่?",
    TheLastThirtyDays: "30วันที่ผ่านมา",
    addNumber: "เพิ่ม",
    subNumber: "ลด",
    addStrategy: "เพิ่มกลยุทธ์",
    minutes: "นาที",
    status: "เปิดใช้งานสถานะ",
    pushConfig: "การกำหนดค่าผลักดัน",
    category: "หมวดหมู่",
    and: "กับ",
    interval: "ช่วงเวลา",
    switchOn: "เปิด",
    switchOff: "ปิด",
    batchDel: "การลบแบทช์",
    exportTxt: "ส่งออกข้อความ TXT",
    playSpeed: "ความเร็วสองเท่า",
    messageLogBtn: "ดูผ่าน/การสนทนาที่เรียกการแจ้งเตือน",
    dataLink: "การเชื่อมโยงข้อมูล",
    yes: "ใช่",
    no: "ไม่ใช่",
    sentenceUnit: "ประโยค",
    appealDetail: "รายละเอียดบันทึกการอุทธรณ์",
    detail: "รายละเอียด",
    default: "เริ่มต้น",
    customer: "ลูกค้า",
    agent: "บริการลูกค้า",
    sort: "เรียงลำดับ",
    applyNow: "การประยุกต์ใช้งาน",
    index: "หมายเลขซีเรียล",
    allColumns: "คอลัมน์ที่สามารถเพิ่มได้",
    selectedColumns: "เพิ่มคอลัมน์",
    syn: "ซิงค์",
    saveAndNext: "บันทึกและสร้างถัดไป",
    viewReference: "ดูการอ้างอิง",
    referenceDetail: "รายละเอียดการอ้างอิง",
    goToQaList: "ไปตรวจสอบคุณภาพ",
    goToCheck: "ไปที่การสุ่มตัวอย่าง",
    businessCard: "นามบัตร",
    weapp: "โปรแกรมขนาดเล็ก",
    redpacket: "ซองอั่งเปา",
    externalRedpacket: "ซองจดหมายสีแดง",
    sphfeed: "หมายเลขวิดีโอ",
    exportTaskCreated: "สร้างงานส่งออกสำเร็จ",
    plzGoTo: "กรุณาข้ามไปที่",
    importTaskCreated: "งานนำเข้าสร้างสำเร็จ",
    taskCreated: "การสร้างงานที่ประสบความสำเร็จ",
    reply: "ตอบ",
    replied: "ตอบกลับ",
    release: "โพสต์",
    expandMore: "ขยายการตอบกลับ {0} อื่นๆ",
    comment: "ความคิดเห็น",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "กรุณามีอย่างน้อยหนึ่ง {0}",
      canNotBeEmptyFormat: "{0} ต้องไม่ว่างเปล่า",
      repeatErrorTipFormat: "{0} มีการทำซ้ำ",
      getInformationFailedFormat:
        "การรับข้อมูล {0} ล้มเหลวโปรดตรวจสอบการเชื่อมต่อเครือข่ายหรือลองอีกครั้งในภายหลัง",
      deleteSuccessFormat: "{0} ลบสำเร็จ",
      deleteFailedFormat: "{0} การลบล้มเหลวโปรดลองอีกครั้งในภายหลัง",
      saveSuccessFormat: "{0} บันทึกสำเร็จ",
      saveFailedFormat: "{0} บันทึกล้มเหลวโปรดลองอีกครั้งในภายหลัง",
      fileSuccessFormat: "{0} เก็บสำเร็จ",
      fileFailedFormat: "{0} การเก็บถาวรล้มเหลวโปรดลองอีกครั้งในภายหลัง",
      exportSuccessFormat: "{0} ส่งออกสำเร็จ",
      exportFailedFormat: "{0} การส่งออกล้มเหลวโปรดลองอีกครั้งในภายหลัง",
      submitSuccessFormat: "{0} ส่งสำเร็จ",
      submitFailedFormat: "{0} การส่งล้มเหลวโปรดลองอีกครั้งในภายหลัง",
      confirmSuccessFormat: "{0} ยืนยันความสำเร็จ",
      confirmFailedFormat: "{0} ยืนยันความล้มเหลวโปรดลองอีกครั้งในภายหลัง",
      pleaseUploadFile: "กรุณาอัปโหลดไฟล์ก่อน!",
      importFileSuccess: "นำเข้าไฟล์สำเร็จ!",
      importFildFailed: "การนำเข้าไฟล์ล้มเหลว!",
      deleteSuccess: "ลบสำเร็จ!",
      deleteFailed: "การลบล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
      illegalTitle: "สิทธิ์ไม่เพียงพอ",
      illegalMessage:
        "คุณไม่มีสิทธิ์บางส่วนหรือทั้งหมดสำหรับขั้นตอนถัดไปโปรดติดต่อผู้ดูแลระบบเพื่อกำหนดค่าคลิกปุ่มตกลงเพื่อออกจากการบูต",
      illegalMessageCurrent:
        "คุณไม่มีสิทธิ์บางส่วนหรือทั้งหมดของขั้นตอนปัจจุบันโปรดติดต่อผู้ดูแลระบบเพื่อกำหนดค่าคลิกปุ่มตกลงเพื่อออกจากการบูต",
      skipMessage: "หากคุณต้องการข้ามขั้นตอนนี้ชั่วคราวให้คลิกข้าม",
      errorMsg500:
        "คำขอเซิร์ฟเวอร์ล้มเหลวโปรดตรวจสอบการทำงานของคุณหรือรีเฟรชลองใหม่",
      errorMsg404: "Ooops... หน้าที่คุณกำลังมองหาหายไป",
      errorMsg400:
        "คำขอเซิร์ฟเวอร์ล้มเหลวโปรดตรวจสอบข้อมูลของคุณและลองอีกครั้ง",
      pageOverFlow:
        "ขณะนี้รองรับการแสดงผลเพียง10,000รายการโปรดตั้งค่าตัวกรองเพื่อดูส่วนที่เกิน",
      isHandAddSmartTag: "เป็นฉลากอัจฉริยะที่เพิ่มด้วยตนเอง",
      defaultGradeMustAtLast: "การให้คะแนนเริ่มต้นจะต้องอยู่ในตอนท้าย",
      AutomaticAllocationInspectorsIsNull:
        "โปรดป้อนหน้าข้อมูลพื้นฐานเพื่อเพิ่มผู้ตรวจสอบคุณภาพ",
      timePickerTips:
        "เพื่อไม่ให้ส่งผลกระทบต่อการใช้งานปกติของคุณ23:00 ~ 2:00เป็นเวลาบำรุงรักษาระบบและไม่มีการดำเนินการตามกำหนดเวลา",
      batchDeleteInfo:
        "ลบแถบ {0} สำเร็จและแถบ {1} ล้มเหลวสาเหตุของความล้มเหลวคือมีการใช้ข้อมูล",
      batchDeleteTips: "ตรวจสอบข้อมูล {0} ทั้งหมดเพื่อยืนยันการลบ?",
      correctionSuccess: "การแก้ไขข้อผิดพลาดที่ประสบความสำเร็จ",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError: "เพิ่มแบบฟอร์มการตรวจสอบคุณภาพสูงสุด10แบบ",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "ผลรวมของรายการตัวบ่งชี้ {0} ต้องเพิ่มเป็น100",
          banProjectMaxErrorTip: "เพิ่มข้อห้ามสูงสุด2,000รายการ",
          deadlyProjectMaxErrorTip: "เพิ่มรายการร้ายแรงได้ถึง500รายการ",
        },
      },
    },
    info: {
      creatSuccess: "สร้างสำเร็จ",
      creatError: "การสร้างล้มเหลว",
      editSuccess: "แก้ไขสำเร็จ",
      editError: "การแก้ไขล้มเหลว",
      deleteSuccess: "ลบสำเร็จ",
      addSuccess: "เพิ่มความสำเร็จ",
      deleteError: "ลบล้มเหลว",
      conditionError: "ข้อผิดพลาดในการแสดงออกทางตรรกะของตัวดำเนินการ",
      customJudgeLogic: "ข้อผิดพลาดนิพจน์ตรรกะผสมเงื่อนไขที่กำหนดเอง",
      exportError: "การส่งออกล้มเหลว",
      searchError: "การคัดกรองล้มเหลว",
      loadError: "{0} โหลดล้มเหลว",
      saveSuccess: "บันทึกความสำเร็จ",
      saveError: "บันทึกล้มเหลว",
      fileSuccess: "เก็บเรียบร้อยแล้ว",
      fileError: "การเก็บถาวรล้มเหลว",
      cannotBeNull: "{0} ไม่ว่างเปล่า!",
      pleaseSearchAndSelect: "กรุณาป้อนคำสำคัญในการค้นหา",
      clickAndToggle: "คลิกเลือกสลับ",
      deleteWarning:
        "{0} การลบจะไม่สามารถกู้คืนได้คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ",
      getOssAuthFailed:
        "การได้รับใบอนุญาต OSS ล้มเหลวโปรดตรวจสอบการเชื่อมต่อเครือข่ายหรือลองอีกครั้งในภายหลัง",
      getInsightAuthFailed:
        "การได้รับการอนุมัติ Insight ล้มเหลวโปรดตรวจสอบการเชื่อมต่อเครือข่ายหรือลองอีกครั้งในภายหลัง",
      pictureUploadFailed: "อัปโหลดรูปภาพล้มเหลว",
      pictureUploadFailedSize: "ขนาดรูปภาพเกินขีดจำกัดการอัปโหลดรูปภาพล้มเหลว",
      wecomTagsSyncInfo: "อัปเดตล่าสุด:{0} ซิงโครไนซ์ {1} ข้อความ",
      collectSuccess: "คอลเลกชันที่ประสบความสำเร็จ",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "ล้มเหลวในการรับข้อมูลรายงาน" },
      waveAudio: {
        noWaveMessage:
          "เพื่อรักษาประสบการณ์ของคุณรูปคลื่นจะไม่แสดงชั่วคราวสำหรับการบันทึกที่เกิน {0} คุณสามารถเล่นได้โดยตรง",
      },
      customFilter: {
        del: "ลบ",
        edit: "บรรณาธิการ",
        new: "ตัวกรองที่กำหนดเอง",
        apply: "การประยุกต์ใช้งาน",
        cancel: "การยกเลิก",
        onlyApply: "ใช้ได้เท่านั้น",
        ohterSave: "บันทึกเป็น",
        save: "บันทึก",
        customFilter: "การคัดกรองทั่วไป",
        reset: "รีเซ็ต",
        holderKeyWord: "กรุณาใส่คำสำคัญ",
        customFilterRule: "กฎตรรกะตัวกรอง:",
        customFilterName: "ชื่อตัวกรอง:",
        holderCustomFilterName: "โปรดป้อนชื่อตัวกรอง",
        manualConfiguration: "การกำหนดค่าด้วยตนเอง",
        holderCustomRule: "โปรดป้อนเงื่อนไขตรรกะที่กำหนดเอง",
        example: "ตัวอย่าง",
        dragConfiguration: "ลากและวางการกำหนดค่า",
        condition: "เงื่อนไข",
        operation: "การดำเนินการ",
        infoDel: "เนื้อหาถูกลากออกจากพื้นที่เพื่อลบ",
        toConfigure: "การกำหนดค่า",
        holderCustomFilterNameLength: "ความยาวสูงสุดของชื่อตัวกรองคือ10",
        customFilterNameNone: "ชื่อตัวกรองต้องไม่ว่างเปล่า",
        and: "และ",
        or: "หรือ",
        not: "ไม่ใช่",
        delSuccess: "ลบสำเร็จ",
        saveSuccess: "บันทึกความสำเร็จ",
        updateSuccess: "แก้ไขสำเร็จ",
        customFilterCondition: "เงื่อนไขการคัดกรอง",
        conditionRule: "กฎเงื่อนไข",
        none: "ไม่มี",
        filterPlaceholder: "กรุณาใส่คำสำคัญ",
      },
      labelTabs: {
        addThis: "เพิ่มที่นี่",
        goThis: "ย้ายไปที่นี่",
        saveGo: "บันทึกและฝึกอบรม",
      },
      datePicker: {
        today: "วันนี้",
        yesterday: "เมื่อวานนี้",
        theDayBeforeYesterday: "วันก่อนเมื่อวานนี้",
        thisWeek: "สัปดาห์นี้",
        lastWeek: "สัปดาห์ที่แล้ว",
        thisMonth: "เดือนนี้",
        lastMonth: "เมื่อเดือนที่แล้ว",
        lastSevenDay: "7วันที่ผ่านมา",
        lastThirtyDay: "30วันที่ผ่านมา",
        thisQuarter: "ไตรมาสนี้",
        lastQuarter: "ไตรมาสที่แล้ว",
        thisYear: "ในปีนี้",
        lastYear: "ปีที่แล้ว",
        lastYearFromToday: "ปีล่าสุด",
      },
      pagination: { page: "ทั้งหมด {0},{1} หน้า" },
      wechatRecord: {
        loadMoreBtn: {
          top: "โหลดข้อความก่อนหน้านี้",
          bottom: "โหลดข้อความเพิ่มเติม",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "งานตรวจสอบคุณภาพ",
        informationCollectTasksManageTitle: "รวบรวมงาน",
        customerServiceCenterTitle: "ศูนย์บริการลูกค้า",
        informationTitle: "ศูนย์ข้อมูล",
        intelligentAnalysisTitle: "การวิเคราะห์อัจฉริยะ",
        semanticIntelligence: "ความฉลาดเชิงความหมาย",
        trainingCenterTitle: "ศูนย์ฝึกอบรม",
        adminTitle: "การจัดการระบบ",
      },
      qualityCheckingTask: {
        title: "การตรวจสอบคุณภาพการโทร",
        index: {
          tipOne:
            "การตรวจสอบคุณภาพการโทรใช้เพื่อประเมินคุณภาพของงานคอลเซ็นเตอร์ของพนักงานคุณสามารถรวมธุรกิจของคุณเองและตั้งค่าแบบฟอร์มการตรวจสอบคุณภาพที่เหมาะสม",
          tipTwo:
            "สามารถกำหนดกฎที่เหมาะสมสำหรับงานตรวจสอบคุณภาพรวมถึงการคัดกรองการสุ่มตัวอย่างฯลฯหลังจากผู้ตรวจสอบคุณภาพให้คะแนนแล้วฝ่ายบริการลูกค้าสามารถตรวจสอบผลการตรวจสอบคุณภาพและอุทธรณ์เพิ่มเติมได้",
          addTaskTitle: "เพิ่มงานตรวจสอบคุณภาพ",
          deleteTipTitle: "ลบงานตรวจสอบคุณภาพ",
          deleteTipContent:
            "ไม่สามารถกู้คืนงานตรวจสอบคุณภาพได้หลังจากลบแล้วคุณยืนยันการลบหรือไม่?",
          checkingTask: "งานตรวจสอบคุณภาพ",
          checkingTaskList: "รายการงานตรวจสอบคุณภาพ",
          formList: "รายการแบบฟอร์ม",
          viewHelpDocuments: "ดูเอกสารความช่วยเหลือ",
        },
        manage: {
          taskSetting: {
            title: "การตั้งค่างาน",
            form: "แบบฟอร์ม",
            basicInfo: "ข้อมูลพื้นฐาน",
            visibleRange: "ช่วงที่มองเห็นได้",
            visibleRangeTip:
              "ฝ่ายบริการลูกค้าและทีมบริการลูกค้าที่เลือกสามารถดูงานตรวจสอบคุณภาพได้และยังสามารถรวมการตั้งค่าการอนุญาตสำหรับหัวหน้าทีมบริการลูกค้าฝ่ายบริการลูกค้าผู้ตรวจสอบคุณภาพฯลฯ",
            automaticSampling: "การสุ่มตัวอย่างอัตโนมัติ",
            automaticSamplingTip:
              "คุณสามารถตั้งค่ากฎสำหรับการสุ่มตัวอย่างอัตโนมัติเพื่อให้ระบบเพิ่มงานตรวจสอบคุณภาพเป็นประจำตามกฎที่คุณตั้งไว้เช่นการโทร5% ของสัปดาห์ที่แล้วทุกวันจันทร์",
            samplingRate: "ความถี่ในการสุ่มตัวอย่าง",
            samplingTime: "เวลาสุ่มตัวอย่าง",
            samplingRange: "ช่วงการสุ่มตัวอย่าง",
            samplingRule: "กฎการสุ่มตัวอย่าง",
            fillterCondition: "เงื่อนไขการคัดกรอง",
            workflow: "กระบวนการตรวจสอบคุณภาพ",
            appealsTime: "อนุญาตให้เวลาอุทธรณ์",
            appealsTimeTipOne: "หลังจากส่งคะแนนแล้ว",
            appealsTimeTipTwo: "ภายในวัน",
            appealsTimeTipThree:
              'หลังจากเวลาอุทธรณ์ที่อนุญาตสถานะการตรวจสอบคุณภาพของบันทึกจะถูกแก้ไขเป็น "ยืนยัน" โดยอัตโนมัติ',
            auditTime: "เวลาดำเนินการตรวจสอบ",
            auditTimeTipOne: "หลังยื่นเรื่องร้องเรียน",
            auditTimeTipTwo: "ภายในวัน",
            auditTimeTipThree:
              'หากเกินเวลาดำเนินการตรวจสอบบันทึกจะคงคะแนนเดิมและสถานะการตรวจสอบคุณภาพจะถูกแก้ไขเป็น "ยืนยัน" โดยอัตโนมัติ',
            noAppealsMode: "ไม่มีโหมดอุทธรณ์",
            appealsMode: "อนุญาตโหมดการอุทธรณ์",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "บริการลูกค้า",
            customerServicesGroup: "กลุ่มบริการลูกค้า",
            surveyList: "รายการประเมิน",
            customFields: "ฟิลด์ที่กำหนดเอง",
            fieldConfig: "การกำหนดค่าฟิลด์",
            from: "จาก",
            owner: "ผู้รับผิดชอบ",
            inspectorAndReviewerAuthority: "ผู้มีอำนาจตรวจสอบการตรวจสอบคุณภาพ",
            inspector: "ผู้ตรวจสอบคุณภาพ",
            reviewer: "ผู้ตรวจสอบ",
            inspectorTip: "ผู้ตรวจสอบคุณภาพสามารถให้คะแนนงานได้",
            reviewerTip: "ผู้ตรวจสอบสามารถให้คะแนนงานได้",
            dataStatisticsAuthority: "สิทธิ์สถิติข้อมูล",
          },
          samplingRecords: {
            title: "บันทึกการสุ่มตัวอย่าง",
            index: {},
            manualSampling: {
              title: "การสุ่มตัวอย่าง",
              fillterCondition: "เงื่อนไขการคัดกรอง",
              samplingRule: "กฎการสุ่มตัวอย่าง",
              agent: "บริการลูกค้า",
              type: "ประเภท",
              result: "ผล",
              evaluate: "การประเมินผล",
              relayNumber: "รีเลย์",
              source: "แหล่งที่มา",
              task: "ภารกิจ",
              queueState: "สถานะคิว",
              callHanger: "วางสาย",
              queueTime: "คิวใช้เวลานาน",
              talkTime: "ระยะเวลาการโทร",
              bellTime: "ความยาวของระฆัง",
              recordCountTipOne: "รวม",
              recordCountTipTwo: "บันทึกบทความ",
              customerServices: "บริการลูกค้า",
              customerServicesGroup: "กลุ่มบริการลูกค้า",
              surveyList: "รายการประเมิน",
              customFields: "ฟิลด์ที่กำหนดเอง",
              fieldConfig: "การกำหนดค่าฟิลด์",
              defaultFields: "ฟิลด์เริ่มต้น",
            },
          },
          taskList: {
            title: "รายการงาน",
            index: {
              batchDelete: "การลบแบทช์",
              batchSubmit: "การส่งแบทช์",
              batchConfirm: "ยืนยันแบทช์",
              itemFields: "การให้คะแนนฟิลด์รายการ",
              categoryFields: "ฟิลด์การจำแนกรอง",
              forbidsAndDeadlies: "รายการต้องห้ามและรายการร้ายแรง",
              scoreFieldList: "การให้คะแนนรายการฟิลด์",
              customerName: "ชื่อลูกค้า",
              cellphone: "โทรศัพท์",
              email: "กล่องจดหมาย",
              organization: "บริษัท",
              description: "คำอธิบาย",
              tags: "แท็บ",
              taskUserRole: "บทบาทของผู้ใช้งานปัจจุบัน",
            },
            workBench: {
              title: "บันทึกรายละเอียด",
              checkForm: "แบบฟอร์มการตรวจสอบคุณภาพ",
              waitEvaluate: "ที่จะประเมิน",
              alreadyEvaluate: "ได้รับการประเมิน",
              waitSubmit: "ที่จะส่ง",
              alreadySubmit: "ส่งมาแล้ว",
              waitConfirm: "จะได้รับการยืนยัน",
              alreadyConfirm: "ยืนยันแล้ว",
              waitReview: "ที่จะตรวจสอบ",
              alreadyReview: "ตรวจสอบแล้ว",
              alreadyDone: "เสร็จเรียบร้อยแล้ว",
              comment: "หมายเหตุการประเมินผล",
              totalScore: "คะแนนรวม",
              score: "แบ่ง",
              remark: "หมายเหตุ",
              changeRecords: "บันทึกการเปลี่ยนแปลง",
              subject: "ธีม",
              secondLevelScore: "คะแนนระดับ2",
              deadlyList: "รายการร้ายแรง",
              forbidList: "รายการต้องห้าม",
              addToCheckTask: "เพิ่มลงในงานตรวจสอบคุณภาพ",
              submitCheckRemark: "ส่งการประเมินคุณภาพ",
              changeCheckRemark: "แก้ไขการประเมินการตรวจสอบคุณภาพ",
              doneCheckRemark: "เสร็จสิ้นการประเมินคุณภาพ",
              submitCheckReview: "ส่งการตรวจสอบคุณภาพ",
              submitCheckAppeal: "ยื่นเรื่องร้องเรียนการตรวจสอบคุณภาพ",
              saveFlowSuccess: "การประเมินประสบความสำเร็จ",
              saveFlowFailed: "การประเมินล้มเหลวโปรดลองอีกครั้งในภายหลัง",
              FormDetail: "รายละเอียดแบบฟอร์ม",
              callDetail: "รายละเอียดบันทึกการโทร",
              sampleCalllogDetail: "บันทึกรายละเอียด",
              appealReasonTip: "โปรดป้อนเหตุผลในการอุทธรณ์ (จำเป็น)",
              appeal: "การอุทธรณ์",
              appealReason: "เหตุผลในการอุทธรณ์",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} แบบฟอร์มการตรวจสอบคุณภาพ",
        basicInformationTitle: "ข้อมูลพื้นฐาน",
        indicatorsProjectTitle: "รายการดัชนี",
        specialProjectTitle: "รายการพิเศษ",
        totalScore: "คะแนนรวม",
        indicatorsName: "ชื่อตัวบ่งชี้",
        score: "คะแนน",
        singleScore: "จุดเดียว",
        weight: "น้ำหนัก",
        maxDeductScore: "การหักเงินสูงสุด",
        add: "เพิ่มขึ้น",
        detail: "รายละเอียดแบบฟอร์ม",
        list: {
          title: "แบบฟอร์มการตรวจสอบคุณภาพ",
          tipOne:
            "การกำหนดค่าแบบฟอร์มการตรวจสอบคุณภาพเป็นขั้นตอนแรกของงานตรวจสอบคุณภาพคุณสามารถกำหนดค่าแบบฟอร์มการตรวจสอบคุณภาพที่เหมาะสมที่สุดร่วมกับธุรกิจของบริษัท",
          tipTwo:
            "เราสนับสนุนรูปแบบต่างๆเช่นการให้คะแนนโดยตรงการให้คะแนนน้ำหนักและระบบการหักเงินขอแนะนำให้คุณตั้งกฎแล้วเพิ่มลงในระบบ",
        },
        basicInformation: {
          formTemplate: "แม่แบบฟอร์ม",
          directScore: "การให้คะแนนโดยตรง",
          weightedScore: "การให้คะแนนน้ำหนัก",
          deductScore: "หักเงิน",
          directScoreSecondLevel: "คะแนนโดยตรง-ระดับ2",
          weightedScoreSecondLevel: "คะแนนน้ำหนัก-ระดับ2",
          deductScoreSecondLevel: "หัก-ระดับ2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "รายการต้องห้าม",
          banProjectTip:
            "สามารถใช้เพื่อตรวจสอบในระหว่างการให้คะแนนการตรวจสอบคุณภาพถือได้ว่าเป็นการละเมิดหรือเสริมข้อสังเกตและไม่มีผลต่อคะแนนจริง",
          deadlyProject: "รายการร้ายแรง",
          deadlyProjectTip:
            "รายการที่ร้ายแรงสามารถใช้เพื่อตรวจสอบในระหว่างการให้คะแนนการตรวจสอบคุณภาพหากเลือกรายการที่ร้ายแรงงานตรวจสอบคุณภาพจะได้คะแนน0คะแนนโดยตรง",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "การตรวจสอบคุณภาพการโทร",
          entiretyReportTitle: "รายงานโดยรวม",
          serviceGroupReportTitle: "รายงานกลุ่มบริการลูกค้า",
          serviceReportTitle: "รายงานการบริการลูกค้า",
          entiretyReport: {
            pandect: "ภาพรวม",
            contrast: "ความคมชัด",
            trend: "แนวโน้ม",
            analyze: "การวิเคราะห์",
            totalScore: "คะแนนรวม",
            banProject: "รายการต้องห้าม",
            deadlyProject: "รายการร้ายแรง",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "รายการผลการตรวจสอบคุณภาพ",
          taskSelectPlaceholder: "เลือกงานตรวจสอบคุณภาพ",
          agentSelectPlaceholder: "เลือกบริการลูกค้า",
          refresh: "รีเฟรช",
          all: "ทั้งหมด",
          unread: "ยังไม่ได้อ่าน",
          inComplaint: "อุทธรณ์",
          reviewed: "ตรวจสอบแล้ว",
          totalScore: "ความคิดเห็นทั่วไป",
          detail: "รายละเอียดการตรวจสอบคุณภาพ",
          audioText: "บันทึกข้อความ",
          callInfo: "ข้อมูลการโทร",
          ticketInfo: "ข้อมูลใบสั่งงาน",
          wechatInfo: "ข้อมูลเซสชัน",
          dialogText: "ข้อความสนทนา",
          dialogInfo: "ข้อมูลการสนทนา",
          customer: "ลูกค้า",
          agent: "บริการลูกค้า",
          none: "ไม่มีข้อมูลการตรวจสอบคุณภาพ",
          appealSuccess: "อุทธรณ์สำเร็จ!",
          appealFailed: "การอุทธรณ์ล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
          pointListIsNull: "จุดตรวจสอบคุณภาพการอุทธรณ์ต้องไม่ว่างเปล่า!",
          pleaseSelectPoint: "โปรดเลือกจุดตรวจสอบคุณภาพ!",
          unsupportedFormat: "ไม่รองรับรูปแบบนี้ชั่วคราว",
          shardingInformation: "ต่อไปนี้เป็นชิ้นส่วน {0}",
          smartTags: "สมาร์ทแท็ก",
          businessRecords: "บันทึกธุรกิจ",
          selectedPointsWarning:
            "โปรดตรวจสอบรายการตรวจสอบคุณภาพที่จะอุทธรณ์ระบุประโยคที่เกี่ยวข้องสำหรับรายการอุทธรณ์จากนั้นคลิกส่ง",
          applyReason: "เหตุผลในการอุทธรณ์ (ไม่เกิน50คำ)",
          chatMessageTypes: {
            all: "ทั้งหมด",
            text: "ข้อความ",
            image: "รูปภาพ",
            agree: "เห็นด้วยกับเนื้อหาของเซสชัน",
            video: "วิดีโอ",
            voice: "เสียง",
            file: "เอกสาร",
            emotion: "อีโมติคอน",
            link: "การเชื่อมโยง",
            chatrecord: "ประวัติการแชท",
            mixed: "ข้อความผสม",
            default: "ข้อความที่ไม่ได้รับการสนับสนุน",
            deleteFriend: "ลบเพื่อน",
            redpacket: "ซองอั่งเปา",
            card: "นามบัตร",
            disAgree: "ไม่เห็นด้วยกับเนื้อหาของการสนทนา",
            weapp: "โปรแกรมขนาดเล็ก",
            externalRedpacket: "ซองจดหมายสีแดง",
            meetingVoicevCall: "ข้อความการโทร",
            uRL: "หน้าเว็บ",
            replyTimeout: "การตอบกลับหมดเวลา",
            sphfeed: "หมายเลขวิดีโอ",
            chatRecordTitle: "ประวัติการแชท",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "คะแนนรวม",
            actualScore: "การให้คะแนน",
            actualGrade: "การจัดอันดับ",
            appeal: "การอุทธรณ์",
            remark: "หมายเหตุเทียม",
            none: "ไม่มีข้อมูลรายละเอียดการตรวจสอบคุณภาพ",
            pointEdit: "การจัดหมวดหมู่การแก้ไขจุดตรวจสอบคุณภาพ",
          },
          changeList: {
            title: "บันทึกการเปลี่ยนแปลง",
            user: "ผู้ใช้",
            point: "จุดตรวจสอบคุณภาพ",
          },
          relatedRecord: {
            title: "บันทึกที่เกี่ยวข้อง",
            qualityList: "งานตรวจสอบคุณภาพ",
            none: "ไม่มี",
            check: "ดู",
            loadMore: "โหลดเพิ่มเติม",
          },
        },
      },
      grade: {
        title: "การให้คะแนนการตรวจสอบคุณภาพ",
        subTitle:
          "การจัดอันดับการตรวจสอบคุณภาพใช้สำหรับการให้คะแนนการตรวจสอบคุณภาพหลังจากเสร็จสิ้นการให้คะแนนการตรวจสอบคุณภาพตัวอย่างเช่นสามารถกำหนดค่าได้ต่ำกว่า60คะแนนไม่มีเงื่อนไข60-85คะแนนดีและสูงกว่า80คะแนนดีเยี่ยม",
        list: {
          up: "เลื่อนขึ้น",
          down: "เลื่อนลง",
          del: "ลบ",
          case: "เข้าร่วมไลบรารีกรณีโดยอัตโนมัติ",
          orderCase: "ไลบรารีเคสเริ่มต้น",
          info: "การให้คะแนนเริ่มต้นไม่มีเงื่อนไขที่ตรงกันและไม่สามารถลบได้หากไม่ตรงตามการให้คะแนนใดๆข้างต้นจะได้รับการให้คะแนนเริ่มต้น",
          add: "เพิ่มการจัดอันดับ",
          save: "บันทึก",
          close: "การยกเลิก",
          max: "สามารถกำหนดค่าคะแนนการตรวจสอบคุณภาพได้สูงสุด20คะแนน",
          name: "ไม่มีชื่อ",
          success: "บันทึกความสำเร็จ",
        },
      },
      qualityInspectionList: {
        title: "รายการตรวจสอบคุณภาพ",
        fastFilter: "การละเมิดการตรวจสอบคุณภาพ",
        fastFilterTip:
          "การตีระบบลดคะแนนถือเป็นการละเมิดและการพลาดระบบคะแนนโบนัสถือเป็นการละเมิด",
        titleWechat: "รายการวิเคราะห์",
        titleCollect: "รายการคอลเลกชัน",
        status: "วิเคราะห์สถานะ",
        reQualityInspectionConfig: "ตัวเลือกงานตรวจสอบคุณภาพใหม่",
        pleaseSelectRecheckPoints:
          "โปรดเลือกจุดตรวจสอบคุณภาพที่คุณต้องการคำนวณใหม่",
        beginReQualityInspection: "เริ่มดำเนินการ",
        cancelReQualityInspection: "ยกเลิกภารกิจ",
        reQualityInspection: "ตรวจสอบคุณภาพใหม่",
        reQualityInspectionWechat: "การวิเคราะห์ใหม่",
        reQualityInspectionUnderWay: "งานตรวจสอบคุณภาพใหม่...",
        reQualityInspectionUnderWayTip:
          "การออกจากหน้าหลังจากปิดหน้าต่างจะไม่ส่งผลกระทบต่องาน",
        reQualityInspectionDone: "การตรวจสอบคุณภาพใหม่เสร็จสมบูรณ์แล้ว",
        reQualityInspectionDoneTip:
          "การตรวจสอบคุณภาพใหม่เสร็จสมบูรณ์แล้วโปรดคลิกปุ่มยืนยัน",
        endReQualityInspection: "ยุติภารกิจ",
        alreadyDoneTip: "{0} เสร็จสมบูรณ์",
        totalTip: "รวม {0}",
        endReInspectionSuccess: "งานตรวจสอบคุณภาพซ้ำสิ้นสุดลงแล้ว",
        closeWindow: "ปิดหน้าต่าง",
        endReInspectionFailed:
          "งานตรวจสอบคุณภาพใหม่ล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
        detail: {
          title: "รายละเอียดการตรวจสอบคุณภาพ",
          wechatTitle: "รายละเอียดการวิเคราะห์",
          titleCollect: "รายละเอียดการรวบรวม",
          callIndex: "การเชื่อมโยงการโทร",
          textIndex: "การสนทนาที่เกี่ยวข้อง",
          fileConfirm: "คุณยืนยันการเก็บถาวรหรือไม่?",
          fileConfirmDesc:
            "คะแนนการตรวจสอบคุณภาพจะไม่สามารถแก้ไขได้หลังจากเก็บถาวรโปรดยืนยันว่าจะเก็บถาวรหรือไม่",
        },
        sysCloumnsContal: "การจัดการฟิลด์คอลัมน์ระบบ",
        personCloumnsContal: "การจัดการฟิลด์คอลัมน์ส่วนบุคคล",
      },
      spotCheckList: {
        title: "รายการตรวจสอบการสุ่มตัวอย่าง",
        onlyMyTip: "เพียงแค่ตรวจสอบการสุ่มตัวอย่างของฉัน",
        plInspector: "โปรดเลือกผู้ตรวจสอบ",
        allInspector: "ดูทั้งหมด",
        seInspector: "ดูผู้ตรวจสอบที่กำหนด",
        myInspector: "ตรวจสอบเฉพาะของฉัน",
        noInspector: "ดูผู้ตรวจสอบตัวอย่างที่ไม่ได้รับการจัดสรร",
        detail: { title: "รายละเอียดการสุ่มตัวอย่าง" },
      },
      reviewList: {
        title: "รายการตรวจสอบ",
        onlyMyTip: "ดูการตรวจสอบของฉันเท่านั้น",
        plInspector: "โปรดเลือกผู้ตรวจสอบ",
        allInspector: "ดูทั้งหมด",
        seInspector: "ดูผู้ตรวจสอบที่กำหนด",
        myInspector: "ตรวจสอบเฉพาะของฉัน",
        noInspector: "ดูผู้ตรวจสอบที่ไม่ได้รับมอบหมาย",
        detail: { title: "ตรวจสอบรายละเอียด" },
      },
      caseBaseList: {
        title: "ห้องสมุดกรณี",
        del: "ลบ",
        delTitle: "เนื้อหาทั้งหมดจะไม่ถูกบันทึกหลังจากลบ",
        cancel: "การยกเลิก",
        actions: "การดำเนินงาน",
        seeCaseBase: "ดูกรณี",
        seeDetail: "ดูรายละเอียด",
        edit: "บรรณาธิการ",
        save: "บันทึก",
        addCaseBase: "เพิ่มไลบรารีกรณี",
        export: "ส่งออก",
        ok: "กำหนด",
        tip: "ข้อมูลการโทรและการสนทนาทั่วไปในรายการตรวจสอบคุณภาพสามารถบันทึกไว้ในฐานข้อมูลกรณีเพื่ออำนวยความสะดวกในการเรียนรู้และการแบ่งปัน",
        detail: {
          choiceCaseBase: "โปรดเลือกกรณี",
          delCaseBase: "ลบกรณี",
          addCaseBase: "เพิ่มกรณี",
          successed: "บันทึกความสำเร็จ",
          info: "โปรดบันทึกหรือยกเลิกเนื้อหาที่กำลังแก้ไขก่อน",
          caseType: "โปรดเลือกไลบรารีเคสที่จะเข้าร่วม",
        },
      },
      testSetList: {
        title: "ชุดทดสอบการตรวจสอบคุณภาพ",
        tip: "ข้อมูลการสุ่มตัวอย่างของผู้ตรวจสอบคุณภาพจะถูกเก็บไว้ในการทดสอบการตรวจสอบคุณภาพซึ่งสะดวกสำหรับการเพิ่มประสิทธิภาพการกำหนดค่าเงื่อนไขการตรวจสอบคุณภาพและกฎการตรวจสอบคุณภาพสำหรับข้อผิดพลาดในการตรวจสอบเครื่องจักรบางอย่างที่เปรียบเทียบหลังจากการตรวจสอบแบบสุ่ม",
        refresh: "รีเฟรช",
        reQualityInspectionUnderWay: "ประเมินงานใหม่...",
        reQualityInspectionUnderWayTip:
          "การออกจากหน้าหลังจากปิดหน้าต่างจะไม่ส่งผลกระทบต่องาน",
        reQualityInspectionDone: "การประเมินใหม่เสร็จสมบูรณ์แล้ว",
        reQualityInspectionDoneTip:
          "การประเมินใหม่เสร็จสมบูรณ์แล้วโปรดคลิกปุ่มยืนยัน",
        endReQualityInspection: "ยุติภารกิจ",
        alreadyDoneTip: "{0} เสร็จสมบูรณ์",
        totalTip: "รวม {0}",
        endReInspectionSuccess: "งานประเมินใหม่สิ้นสุดลงแล้ว",
        closeWindow: "ปิดหน้าต่าง",
        endReInspectionFailed:
          "ประเมินความล้มเหลวของงานอีกครั้งโปรดลองอีกครั้งในภายหลัง!",
        recheckPoint: "คำนวณคะแนนและความแม่นยำของจุดตรวจสอบคุณภาพใหม่",
        recheckPointTitle: "ตัวเลือกการประเมินใหม่",
        delete: {
          reQualityInspectionUnderWay: "แบทช์ลบงาน...",
          reQualityInspectionUnderWayTip:
            "การออกจากหน้าหลังจากปิดหน้าต่างจะไม่ส่งผลกระทบต่องาน",
          reQualityInspectionDone: "งานลบแบทช์เสร็จสมบูรณ์แล้ว",
          reQualityInspectionDoneTip:
            "การลบแบทช์เสร็จสมบูรณ์แล้วโปรดคลิกปุ่มยืนยัน",
          endReQualityInspection: "ยุติภารกิจ",
          alreadyDoneTip: "{0} เสร็จสมบูรณ์",
          totalTip: "รวม {0}",
          endReInspectionSuccess: "งานลบแบทช์ถูกยกเลิก",
          closeWindow: "ปิดหน้าต่าง",
          endReInspectionFailed: "แบทช์ลบงานล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
        },
        reultList: {
          assessmentTime: "เวลาประเมินล่าสุด",
          assessmentAll: "การประเมินรวม",
          strip: "แถบ",
          spot: "จุด",
          deviation: "ส่วนเบี่ยงเบน",
          accuracyRate: "อัตราความแม่นยำ",
          info: "ไม่มีผลการประเมินโปรดประเมิน",
          onlyDeviationInfo: "ดูเฉพาะบันทึกค่าเบี่ยงเบน",
          againAssessment: "ประเมินใหม่",
          screen: "การคัดกรอง",
          batchDelete: "การกำจัดแบทช์",
          matching: "การจับคู่",
          ok: "ใช่",
          noCancel: "ไม่ใช่",
        },
      },
      smartWordsLibrary: {
        title: "พจนานุกรมอัจฉริยะ",
        tip1: "คำพ้องความหมาย: ใช้เพื่อลดปริมาณการฝึกอบรมของแท็กความหมายประโยคที่คล้ายกันของคำพ้องความหมายไม่จำเป็นต้องฝึกซ้ำ",
        tip2: "คำศัพท์ทางวิชาชีพ: การเพิ่มประสิทธิภาพการถอดเสียงและการเพิ่มประสิทธิภาพความเข้าใจภาษาธรรมชาติสำหรับคำนามที่เหมาะสมเช่นชื่อบริษัทชื่อผลิตภัณฑ์และคำศัพท์ในอุตสาหกรรมที่ระบุไม่ถูกต้องเพื่อปรับปรุงความแม่นยำในการจดจำและความแม่นยำในการวิเคราะห์",
        tip3: "คำที่น่ากังวล: ให้ความสนใจกับคำที่คุณต้องการให้ความสนใจในการวิเคราะห์คำบนคลาวด์และช่วยให้ฮอตสปอตการวิเคราะห์ AI สอดคล้องกับธุรกิจปัจจุบัน",
        tip4: "คำที่ถูกบล็อก: บล็อกคำที่คุณไม่ต้องการให้ความสนใจในการวิเคราะห์คำบนคลาวด์และช่วยให้ฮอตสปอตการวิเคราะห์ AI สอดคล้องกับธุรกิจปัจจุบัน",
        tip5: "น้ำหนักคำ: ใช้เพื่อเพิ่มประสิทธิภาพการแก้ไขข้อผิดพลาดในการจดจำแท็กความหมายโดยการแก้ไขน้ำหนักอิทธิพลของคำในประโยคเพื่อเพิ่มประสิทธิภาพเอฟเฟกต์การจดจำความหมายในสถานการณ์เฉพาะตามความจำเป็น",
        tip6: "ข้อมูลคลังข้อมูล: ป้อนข้อความย่อหน้าขนาดใหญ่สำหรับสถานการณ์ทางธุรกิจที่เกี่ยวข้องสำหรับการแก้ไขข้อผิดพลาดในการถอดเสียงและการฝึกอบรม ASR เพื่อปรับปรุงความแม่นยำในการถอดเสียง ASR",
        upload: {
          uploadSynonymWords: "นำเข้าคำพ้องความหมาย",
          uploadProfessionalWords: "นำเข้าคำนามมืออาชีพ",
          uploadAttentionWords: "นำเข้าคำที่น่ากังวล",
          uploadIgnoreWords: "นำเข้าคำที่ละเว้น",
          uploadWordsWeight: "นำเข้าคำถ่วงน้ำหนัก",
          uploadTypo: "นำเข้าชุดคำที่พิมพ์ผิด",
          uploadCorpusData: "นำเข้าข้อมูลคลังข้อมูล",
          uploadCorpusContent: "นำเข้าเนื้อหาข้อมูลคลังข้อมูล",
          firstUploadStep: "1.ดาวน์โหลดเทมเพลตและกรอกข้อมูลในรูปแบบเทมเพลต",
          downloadTemplateMessage: "ดาวน์โหลดแม่แบบ:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2.คลิกที่ปุ่มนำเข้าเพื่ออัปโหลดไฟล์ EXCEl ที่สอดคล้องกับรูปแบบเทมเพลต",
          secondUploadCorpusDataStep:
            "2.คลิกที่ปุ่มนำเข้าเพื่ออัปโหลดไฟล์ TXT ที่สอดคล้องกับรูปแบบเทมเพลต",
          postUploadDataFail: "ล้มเหลวในการส่งข้อมูลที่อัปโหลด",
          pleaseUploadTemplate: "กรุณาอัปโหลดไฟล์ EXCel/JSON/TXT",
          templateNeedJSONOrXLSX:
            "เทมเพลตไฟล์เท่านั้นสำหรับรูปแบบ JSON หรือ xlsx",
          templateNeedTxt: "เทมเพลตไฟล์ข้อมูลคลังข้อมูลเท่านั้นสำหรับไฟล์ TXT",
          uploadSuccess: "นำเข้าเรียบร้อยแล้ว",
          uploadFailed: "การนำเข้าล้มเหลว",
          downloadError: "การดาวน์โหลดล้มเหลว",
          downLoadTxtTitle: "เนื้อหาคลังข้อมูล .txt",
        },
        navTabNames: {
          synonymWords: "คำพ้องความหมาย",
          professionalWords: "คำนามมืออาชีพ",
          attentionWords: "คำที่เกี่ยวข้อง",
          ignoreWords: "ละเว้นคำ",
          wordsWeight: "น้ำหนักคำ",
          corpusData: "ข้อมูลคลังข้อมูล",
          typoLibrary: "การรวบรวมคำที่พิมพ์ผิด",
        },
        synonymWords: {
          createSynonymWords: "คำพ้องความหมายใหม่",
          editSynonymWords: "แก้ไขคำพ้องความหมาย",
          addSynonymWords: "เพิ่มคำพ้องความหมาย",
          pleaseEnterStandardWords: "กรุณาป้อนคำมาตรฐาน",
          synonymWordsExists: "คำพ้องความหมายนี้มีอยู่แล้วและไม่สามารถเพิ่มได้",
          synonymWordsCanNotNull: "คำพ้องที่เพิ่มเข้ามาต้องไม่ว่างเปล่า",
          pleaseAddSynonymWords: "กรุณาเพิ่มคำพ้องความหมาย",
          addSuccess: "เพิ่มความสำเร็จ",
          editSuccess: "แก้ไขสำเร็จ",
          deleteSuccess: "ลบสำเร็จ",
          deleteError: "ลบล้มเหลว",
          required: "*",
        },
        professionalWords: {
          create: "คำนามมืออาชีพใหม่",
          edit: "บรรณาธิการคำนามมืออาชีพ",
          addTargetWords: "เพิ่มคำแทน",
          targetWordsExists: "คำแทนมีอยู่แล้วและไม่สามารถเพิ่มได้",
          targetWordsCanNotNull: "คำทดแทนที่เพิ่มเข้ามาต้องไม่ว่างเปล่า",
          pleaseAddTargetWords: "กรุณาเพิ่มคำแทน",
          pleaseEnter: "กรุณาป้อนคำนามมืออาชีพ",
          wordsExists: "คำนามมืออาชีพมีอยู่แล้ว",
          createSuccess: "เพิ่มความสำเร็จ",
          editSuccess: "แก้ไขสำเร็จ",
          deleteSuccess: "ลบสำเร็จ",
          deleteError: "ลบล้มเหลว",
          saveError: "บันทึกล้มเหลว",
          addTargetWordsMaxNumberError: "สามารถเพิ่มคำทดแทนได้ถึง100คำ",
          addHomophonyWordsMaxNumberError:
            "คำที่ถูกบล็อกสามารถเพิ่มได้ถึง100คำ",
        },
        attentionWords: {
          create: "คำที่น่ากังวลใหม่",
          edit: "ติดตามคำแก้ไข",
          pleaseEnter: "โปรดป้อนคำที่สนใจ",
          wordsExists: "คำที่น่ากังวลมีอยู่แล้ว",
          createSuccess: "เพิ่มความสำเร็จ",
          editSuccess: "แก้ไขสำเร็จ",
          deleteSuccess: "ลบสำเร็จ",
          deleteError: "ลบล้มเหลว",
        },
        ignoreWords: {
          create: "คำที่ละเว้นใหม่",
          edit: "ละเว้นการแก้ไขคำ",
          pleaseEnter: "โปรดป้อนคำที่ละเว้น",
          wordsExists: "คำที่ไม่สนใจมีอยู่แล้ว",
          createSuccess: "เพิ่มความสำเร็จ",
          editSuccess: "แก้ไขสำเร็จ",
          deleteSuccess: "ลบสำเร็จ",
          deleteError: "ลบล้มเหลว",
        },
        wordsWeight: {
          createWordsWeight: "คำถ่วงน้ำหนักใหม่",
          editWordsWeight: "แก้ไขคำถ่วงน้ำหนัก",
          addWordsWeight: "เพิ่มคำถ่วงน้ำหนัก",
          pleaseEnterWeightWords: "กรุณาใส่คำถ่วงน้ำหนัก",
          pleaseEnterWeight: "กรุณาใส่น้ำหนัก",
          weightNeedInZeroToHundred: "น้ำหนักต้องเป็น0 ~ ระหว่าง100",
          synonymWordsExists: "มีคำถ่วงน้ำหนักอยู่แล้ว",
          weightNeedNumber: "น้ำหนักต้องมีรูปแบบจำนวนเต็ม",
          standardWeightInformation:
            "น้ำหนักมาตรฐานคือ50ยิ่งน้ำหนักมากอิทธิพลในการวิเคราะห์ก็จะยิ่งแข็งแกร่งขึ้น",
          createSuccess: "เพิ่มความสำเร็จ",
          editSuccess: "แก้ไขสำเร็จ",
          deleteSuccess: "ลบสำเร็จ",
          deleteError: "ลบล้มเหลว",
        },
        corpusData: {
          createCorpusData: "สร้างข้อมูลคลังข้อมูลใหม่",
          editCorpusData: "แก้ไขข้อมูลคลังข้อมูล",
          addCorpusData: "เพิ่มข้อมูลคลังข้อมูล",
          pleaseEnterCorpusDescription: "โปรดป้อนคำอธิบายคลังข้อมูล",
          pleaseEnterCorpusContent: "กรุณากรอกเนื้อหาคลังข้อมูล",
          synonymWordsExists: "ข้อมูลคลังข้อมูลนี้มีอยู่แล้ว",
          CorpusContentWordsOverSetting: "จำนวนคำในเนื้อหาเกิน20,000คำ",
          download: "ดาวน์โหลด",
          upload: "อัปโหลด",
          see: "ดู",
          canNotSave: "โปรดใช้ฟังก์ชันนำเข้ามิฉะนั้นจะไม่สามารถบันทึกได้",
          createSuccess: "เพิ่มความสำเร็จ",
          editSuccess: "แก้ไขสำเร็จ",
          deleteSuccess: "ลบสำเร็จ",
          deleteError: "ลบล้มเหลว",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "คำที่ถูกต้อง",
            wrongWord: "คำผิด",
            wrongWordCount: "จำนวนคำผิด",
            wrongWordTip: "กดปุ่ม Enter เพื่อเพิ่ม",
            status: "ประสิทธิผล",
          },
          create: "สร้างชุดคำพิมพ์ผิดใหม่",
          edit: "แก้ไขชุดคำที่พิมพ์ผิด",
          tip: 'ชุดคำพิมพ์ผิดใช้เพื่อเพิ่มประสิทธิภาพของ "การตรวจจับการพิมพ์ผิด" ตัวอย่างเช่นหาก "การคืนเงิน" ถูกส่งโดยไม่ถูกต้องเป็น "คืนเงิน" คุณสามารถตั้งค่าการพิมพ์ผิดทั่วไปและคำที่ถูกต้องผ่านฟังก์ชันนี้และโปรดทราบว่าจะมีผลในวันถัดไปหลังจากบันทึก',
          onlyChinese: "รองรับการป้อนคำภาษาจีนเท่านั้น",
          wrongWordLengthErr: "ความยาวของคำผิดแต่ละคำต้องเป็น2 ~ 10คำ",
        },
      },
      semanticTags: {
        title: "แท็กความหมาย",
        current: "แท็กความหมายปัจจุบัน",
        reference1: "งานตรวจสอบคุณภาพ: {0} - {1}",
        reference2: "สมาร์ทแท็ก: {0}",
        reference3: "การประมวลผลข้อมูลล่วงหน้า: {0}",
        reference4: "ฐานความรู้: {0}",
        index: { import: "นำเข้าแท็กความหมาย" },
        detail: {
          new: "สร้างแท็กความหมายใหม่",
          edit: "แก้ไขแท็กความหมาย",
          name: "ชื่อ",
          standardSentences: "ประโยคมาตรฐาน",
          effectiveness: "ประสิทธิผล",
          similarSentences: "ประโยคที่คล้ายกัน",
          tip: "รองรับการใช้ # # เรียก API เอนทิตีตัวอย่าง: หมายเลขโทรศัพท์มือถือของคุณคือ # sys_phone # หรือไม่?",
          excludeSimilarSentences: "ไม่รวมประโยคที่คล้ายกัน",
          intelligentRecommend: "แนะนำเนื้อหาที่คล้ายกันอย่างชาญฉลาด",
          fastTraining: "การฝึกอบรมอย่างรวดเร็ว:",
          qualify: "ปกติ",
          recallKeyWord: "คีย์เวิร์ดเรียกคืน",
          excludeKeywords: "ไม่รวมคำหลัก",
          excludeKeywordsInfo1:
            "ไม่รวมคำหลักกฎจะมีผลเหนือกว่าตรรกะทั้งหมดหากไม่รวมการตีแท็กความหมายจะไม่ถูกตี",
          keyWordInfo1:
            "แต่ละบรรทัดของคีย์เวิร์ดเรียกคืนรองรับการกรอกข้อมูลหลายคำโดยคั่นด้วยเครื่องหมายจุลภาคภาษาอังกฤษเมื่อกรอกหลายคำตรรกะคือการตีทั้งหมด",
          keyWordInfo2:
            "คีย์เวิร์ดเรียกคืนรองรับการกรอกข้อมูลหลายบรรทัดเมื่อกรอกหลายบรรทัดตรรกะคือการตีใดๆ",
          recallRegular: "เรียกคืนปกติ",
          excludeRegular: "ไม่รวมกฎ",
          similaSentences: "ไม่รวมประโยคที่คล้ายกัน",
          simim: "ความคล้ายคลึงกัน",
          keyWordInfo3:
            "การยกเว้นประโยคที่คล้ายกันมีความสำคัญเหนือตรรกะทั้งหมดหากการตีไม่รวมประโยคที่คล้ายกันจะไม่ตีแท็กความหมายความคล้ายคลึงกันเริ่มต้นคือ80% ยิ่งความคล้ายคลึงกันมากเท่าไหร่ก็ยิ่งคล้ายกันมากขึ้นเท่านั้น",
          keyWordInfo4:
            "กฎจะคล้ายกับกฎเริ่มต้นและสิ้นสุดโดยค่าเริ่มต้นไม่จำเป็นต้องใช้กับวงเล็บเช่น [non-XXXX]",
          keyWordInfo5:
            "ฟังก์ชันในโมดูลขั้นสูงมักเป็นฟังก์ชันการทดลองที่เตรียมไว้สำหรับผู้เชี่ยวชาญที่คุ้นเคยกับการใช้งานหากคุณไม่แน่ใจว่าคุณควรใช้งานอย่างไรขอแนะนำว่าอย่าเปลี่ยนส่วนนี้",
          keyWordInfo6:
            "ฟังก์ชันการตรวจจับข้ามประโยคช่วยให้แท็กความหมายสามารถตรวจจับข้ามประโยคได้",
          keyWordInfo7: "ต่อเนื่องมากที่สุดในบทบาทเดียวกัน",
          keyWordInfo8: "ไม่สนใจบทบาทที่แตกต่างกันความยาวของประโยคไม่เกิน",
          keyWordInfo9: "การตรวจจับประโยคข้ามประโยค",
          keyWordInfo10: "ประโยคขีดจำกัดข้ามประโยคที่ยาวที่สุดไม่เกิน",
          keyWordInfo11: "ลบเปอร์เซ็นต์ที่คล้ายกันไม่น้อยกว่า",
          keyWordInfo12: "ความคล้ายคลึงกันไม่น้อยกว่า",
          keyWordInfo13:
            "ประโยคที่คล้ายกันของ % จะตรงกัน (ความคล้ายคลึงกันเริ่มต้นคือ80%)",
          keyWordInfo14:
            "การลบความคล้ายคลึงกันจะจับคู่ตามความคล้ายคลึงกันของประโยคขอแนะนำให้ลบประโยคที่คล้ายกันเกินไปเพื่อปรับปรุงประสิทธิภาพการตรวจจับ",
          keyWord: "คำสำคัญ",
          entity: "เอนทิตี",
          ruleField: "คำแนวคิด",
          customRule: "การออกแบบกฎ",
          fanhua: "คำถามที่คล้ายกัน",
          test: "การทดสอบ",
          testOk: "ทดสอบตี",
          senior: "การตั้งค่าขั้นสูง",
          seniorList: "การตรวจจับประโยค",
          up: "เปิดใช้งาน",
          down: "ปิด",
          ju: "ประโยค",
          oneWord: "คำ",
          simimNo: "การลบที่คล้ายกัน",
          seniorField: "เกณฑ์ความคล้ายคลึงกัน",
          save: "บันทึก",
          cancel: "การยกเลิก",
          specialElements: "องค์ประกอบพิเศษ",
          logicRule: "กฎตรรกะ",
          every: "การจับคู่หลายครั้ง",
          recallOk: "ตีเรียกคืนคำ:",
          recallNo: "จำไม่ได้",
          ruleOk: "ตีเรียกคืนปกติ",
          ruleNo: "พลาดปกติ",
          rularOk: "กฎ Hit",
          rularNo: "กฎพลาด",
          infoOk: "ตีประโยคมาตรฐานหรือประโยคที่คล้ายกัน:",
          infoNo: "ประโยคมาตรฐานที่ไม่เป็นที่นิยมหรือประโยคที่คล้ายกัน",
          fieldOk: "Hit ไม่รวมประโยคที่คล้ายกัน:",
          fieldNo: "ไม่รวมประโยคที่คล้ายกัน",
          excludeRuleNo: "พลาดกฎ",
          excludeRuleOk: "Hit ยกเว้นกฎ",
          excludeKeywordNo: "ไม่รวมคีย์เวิร์ด",
          excludeKeywordOk: "ตีคำหลักที่ไม่รวม",
          update: "อัปเดตเรียบร้อยแล้ว",
          fanhuaList: "ส่งไปยังรายการประโยคที่คล้ายกันในหน้าทั่วไป",
          wordList:
            "รายการการฝึกอบรมว่างเปล่าและไม่สามารถส่งไปยังรายการทั่วไปได้",
          start: "เริ่มต้น",
          end: "สิ้นสุด",
          keyWordInfo15:
            "มีฟิลด์ที่คล้ายกันในรายการทั่วไป (การกำหนดค่าขั้นสูงคล้ายกับการลบข้อจำกัดใหม่)",
          saveSuccess: "บันทึกความสำเร็จ",
          errInfo: "มีฟิลด์ที่ไม่ได้กรอกหรือกรอกไม่ถูกต้อง",
        },
        table: {
          deepTraining: "การฝึกอบรมเชิงลึกรอบ {0}",
          fastTraining: "การฝึกอบรมอย่างรวดเร็ว",
        },
      },
      intelligentCollection: {
        title: "คอลเลกชันอัจฉริยะ",
        classification: "การจัดกลุ่มรายการ",
        new: "คอลเลกชันใหม่",
        table: {
          name: "ชื่อฟิลด์",
          props: "คุณสมบัติของลูกค้า",
          type: "ประเภทฟิลด์",
          desc: "คำอธิบายฟิลด์",
        },
        detail: {
          isCustomerProps: "บันทึกไปยังแอตทริบิวต์ของลูกค้า",
          customerPropsHelper:
            "หลังจากบันทึกแล้วข้อมูลที่รวบรวมจะได้รับการอัปเดตเป็นข้อมูลของลูกค้าไม่บันทึกข้อมูลลูกค้าจะไม่ได้รับการอัปเดต",
          name: "ชื่อฟิลด์",
          type: "ประเภทฟิลด์",
          text: "ข้อความ",
          desc: "คำอธิบายฟิลด์",
          descPlaceHolder: "อธิบายถึงประโยชน์ของฟิลด์ในการรวบรวมข้อมูล",
          condition: "เงื่อนไข",
          logic: "กฎ",
          method: "วิธีการรวบรวม",
          message: "เนื้อหาข้อมูล",
          range: "ช่วงการสกัด",
          entity: "แยกเอนทิตี",
          base: "ข้อมูลพื้นฐาน",
          collect: "รวบรวมข้อมูล",
        },
      },
      intelligentTags: {
        title: "สมาร์ทแท็ก",
        sessionLabelTitle: "ฉากสนทนา",
        keyEventsTitle: "เหตุการณ์สำคัญ",
        customerLabelTitle: "แท็กของลูกค้า",
        addClassification: "สร้างหมวดหมู่ฉลากใหม่",
        defaultCategoryName: "หมวดหมู่เริ่มต้น",
        index: {
          createTag: "แท็บใหม่",
          createEvent: "เหตุการณ์ใหม่",
          editTagClassification: "แก้ไขแท็กหมวดหมู่",
          selectTagClassification: "เลือกหมวดหมู่แท็บ",
          editTagGrounp: "แก้ไขการจัดกลุ่ม",
          callTimeError:
            "ระยะเวลาการสนทนา/การสนทนาที่เลือกต้องไม่เกินหนึ่งเดือน (31วัน)",
          callRecordCountError: "จำนวนบันทึกคือ0โปรดเลือกเวลาโทร/สนทนาอีกครั้ง",
          selectItmeCountError:
            "โปรดเลือกแท็บสมาร์ทที่คุณต้องการดำเนินการแบทช์",
          redoSuccess:
            "หากการดำเนินการสำเร็จอีกครั้งโปรดไปที่ศูนย์งานเพื่อตรวจสอบ",
          redoError: "การดำเนินการใหม่ล้มเหลวโปรดลองอีกครั้งในภายหลัง",
          timeError: "เวลาสอบถามต้องไม่เกินหนึ่งเดือน (31วัน)",
          categoryName: "ชื่อหมวดหมู่",
          categoryColor: "หมวดหมู่สี",
          newTagClassification: "การจัดประเภทฉลากใหม่",
          newEventClassification: "หมวดหมู่ใหม่",
          tagClassification: "หมวดหมู่ฉลาก",
          eventClassification: "การจำแนกเหตุการณ์",
        },
        detail: {
          new: "สร้างสมาร์ทแท็กใหม่",
          edit: "แก้ไขสมาร์ทแท็ก",
          tagName: "ชื่อ",
          tagDesc: "คำอธิบาย",
          category: "หมวดหมู่",
          tagStatus: "ประสิทธิผล",
          operator: "โอเปอเรเตอร์",
          rule: "กฎ",
          labelClassAtion: "ประเภทฉลาก",
          labelClassAtionTip1: "การติดฉลาก: การติดฉลากตามกฎ",
          labelClassAtionTip2: "แท็กหมวดหมู่: แท็กตามการฝึกอบรมแบบจำลอง",
          group: "การจัดกลุ่ม",
          checkLabel: "เลือกหมวดหมู่",
          warn: "การเตือนภัยล่วงหน้า",
          warnTips:
            "หลังจากเปิดคำเตือนเมื่อเซสชันตรงตามกฎของเหตุการณ์สำคัญนี้บุคคลที่กำหนดจะได้รับแจ้งและคุณจะได้รับการแจ้งเตือนในรายการสิ่งที่ต้องทำ",
          warnError: "โปรดเลือกบุคลากรที่กำหนดในการเตือนภัยล่วงหน้า",
        },
        subTitle: {
          common: "แท็กเซสชันอัจฉริยะ",
          wecom: "ฉลากขนาดเล็กขององค์กร",
        },
      },
      customerPortrait: {
        title: "ภาพลูกค้า",
        index: { import: "นำเข้าภาพลูกค้า" },
        searchPlaceHolder: "รหัสลูกค้าชื่อเล่นหรือโทรศัพท์",
        callCount: "จำนวนการสนทนา:",
        callDuration: "ระยะเวลาการโทรทั้งหมด:",
        dialogCount: "จำนวนการสนทนาทั้งหมด:",
        keyWordsStatistics: "สถิติคำหลัก (Top 20 & คำที่เกี่ยวข้อง)",
        keyWordsCount: "({0})",
        businessTag: "ป้ายธุรกิจ",
        keyWordsCloudImg: "คำสำคัญภาพเมฆ",
        customerEmotionTrend: "แนวโน้มการเปลี่ยนแปลงอารมณ์ของลูกค้า",
        notFoundCustomer: "ไม่พบลูกค้าที่เกี่ยวข้อง",
        noKeyWords: "ไม่พบคำหลักที่เกี่ยวข้อง",
        noBusinessTags: "ไม่พบป้ายกำกับธุรกิจที่เกี่ยวข้อง",
        getKeyWordsStatisticsDataError: "แบบสอบถามสถิติคำหลักของลูกค้าล้มเหลว",
        getBusinessTagsStatisticsDataError:
          "แบบสอบถามฉลากธุรกิจของลูกค้าล้มเหลว",
        personCustome: "ไม่สอบถามข้อมูลลูกค้า",
        notimeInfo: "ไม่พบข้อมูลอารมณ์",
        timeList: "ช่วงวันที่",
        operator: "สมาร์ทแท็ก",
        placeHolder: "โปรดเลือก",
        placeOperator: "โปรดเลือกสมาร์ทแท็บ",
        search: "สอบถามข้อมูล",
        reset: "รีเซ็ต",
        searchHolder: "ค้นหา",
        call: "โทร",
        text: "การสนทนา",
      },
      sentimentAnalysis: { title: "การค้นพบความคิดเห็นของประชาชน" },
      saleSentimentAnalysis: { title: "การวิเคราะห์ความคิดเห็นของประชาชน" },
      dataPreprocess: {
        title: "การประมวลผลข้อมูลล่วงหน้า",
        index: {
          createData: "สร้างข้อมูลใหม่",
          selectDataSource: "เลือกแหล่งข้อมูล",
          selectStatus: "เลือกความถูกต้อง",
          callTimeError:
            "ระยะเวลาการสนทนา/การสนทนาที่เลือกต้องไม่เกินหนึ่งเดือน (31วัน)",
          callRecordCountError: "จำนวนบันทึกคือ0โปรดเลือกเวลาโทร/สนทนาอีกครั้ง",
          selectItmeCountError: "โปรดเลือกข้อมูลที่คุณต้องการดำเนินการแบทช์",
          redoSuccess:
            "หากการดำเนินการสำเร็จอีกครั้งโปรดไปที่ศูนย์งานเพื่อตรวจสอบ",
          redoError: "การดำเนินการใหม่ล้มเหลวโปรดลองอีกครั้งในภายหลัง",
        },
        detail: {
          new: "สร้างข้อมูลใหม่",
          edit: "แก้ไขข้อมูล",
          name: "ชื่อ",
          desc: "คำอธิบาย",
          dataSource: "แหล่งข้อมูล",
          status: "ประสิทธิผล",
          entity: "แยกเอนทิตี",
          operator: "โอเปอเรเตอร์",
          rule: "กฎ",
        },
      },
      connectedByConversation: {
        title: "การเชื่อมโยงการสนทนาผ่าน",
        list: {
          title: "ฟังก์ชั่นการเชื่อมโยงการสนทนาผ่าน",
          info: "ฟังก์ชั่นการเชื่อมโยงการสนทนาสามารถเชื่อมโยงการโทรและการสนทนาที่แตกต่างกันตามกฎที่ตั้งไว้ล่วงหน้า",
          rule: "กฎการเชื่อมโยงการสนทนา",
          source: "โปรดเลือกแหล่งข้อมูล",
          classification: "โปรดเลือกหมวดหมู่",
          choose: "โปรดเลือก",
          input: "กรุณาใส่",
          save: "บันทึก",
          cancel: "การยกเลิก",
          fieldwei: "บิต",
          fieldNot: "มีฟิลด์ที่ไม่ได้กรอก",
          fiedldlengthNo: "ความยาวฟิลด์เกินขีดจำกัด",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip: "คำหลักสามารถตั้งค่าได้หลายคำคั่นด้วยเครื่องหมายจุลภาค",
          applyRole: "ตรวจจับบทบาท",
          preOperator: "ตัวดำเนินการด้านหน้า",
          applyOperatorScope: "ช่วงการตรวจสอบ",
          addOperator: "เพิ่มตัวดำเนินการ",
          noPreOperator: "ไม่มี",
          from: "ลำดับที่",
          to: "ประโยคถึง",
          end: "ประโยค",
          testRegular: "ทดสอบนิพจน์ทั่วไป",
          test: "การทดสอบ",
          matchSuccess: "จับคู่สำเร็จ",
          matchError: "การจับคู่ล้มเหลว",
          querySementicTags: "เลือกแท็บความหมาย",
          reQuerySementicTags: "เลือกแท็บความหมายอีกครั้ง",
          sementicTag: "แท็กความหมาย",
          standardSentence: "ประโยคมาตรฐาน",
          similarity: "ความคล้ายคลึงกัน",
          actions: "การดำเนินงาน",
          noFitTag: "ไม่พบฉลากที่เหมาะสม",
          addSemanticTag: "เพิ่มแท็บความหมายใหม่",
          pleaseEnterRegular: "กรุณาป้อนนิพจน์ทั่วไป",
          pleaseEnterRegularText: "โปรดป้อนเนื้อหาที่ต้องตรวจสอบ",
          newAssociateComponentTip:
            "ใช้ฟังก์ชันเวอร์ชันเก่าหากค่าเป็นประเภทตัวเลข",
        },
        filterManage: {
          noValueTip: "ไม่มีข้อมูล",
          allCall: "โทรทั้งหมด",
          allDialogue: "การสนทนาทั้งหมด",
          allWechat: "เซสชันทั้งหมด",
          getFiltersListFailed:
            "การได้รับรายการตัวกรองล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
          getFilterDetailFailed:
            "การรับรายละเอียดตัวกรองล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
          getAllCountFailed:
            "ไม่สามารถรับจำนวนการโทร/การสนทนาทั้งหมดโปรดลองอีกครั้งในภายหลัง!",
        },
        listManage: {
          searchPlaceHolder: "กรุณาป้อนคำสำคัญในการค้นหา",
          filterConditions: "เงื่อนไขการคัดกรอง",
          records: "บันทึก",
          getRecordsFailed: "การได้รับบันทึกล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
        },
        miniListManage: {
          getRecordsFailed: "การได้รับบันทึกล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
        },
        applyTemplates: {
          title: "เทมเพลตการตรวจสอบคุณภาพที่ใช้งานได้",
          collectionTitle: "เทมเพลตการรวบรวมที่ใช้งานได้",
        },
        recordDetail: {
          showWaveAudio: "แสดงการบันทึก",
          getDetailFailed:
            "การได้รับรายละเอียดล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
          getSmartTagsFailed:
            "ล้มเหลวในการรับสมาร์ทแท็กโปรดลองอีกครั้งในภายหลัง!",
          getTemplatesFailed:
            "ล้มเหลวในการรับเทมเพลตที่เกี่ยวข้องโปรดลองอีกครั้งในภายหลัง!",
        },
        changeList: {
          sampleStatus: "สถานะการสุ่มตัวอย่าง",
          sampleAssignInspector: "มอบหมายผู้ตรวจสอบการสุ่มตัวอย่าง",
          reviewAssignInspector: "มอบหมายผู้ตรวจสอบ",
          read: "อ่านแล้ว",
          distributivePerson: "จัดสรรคน",
          totalScore: "คะแนนรวม",
        },
        qualityCheckScore: {
          totalScore: "คะแนนรวม",
          actualScore: "คะแนน",
          actualGrade: "การจัดอันดับ",
          appeal: "การอุทธรณ์",
          remark: "หมายเหตุ",
          manualRemark: "หมายเหตุเทียม",
          none: "ไม่มีข้อมูลรายละเอียดการตรวจสอบคุณภาพ",
          interactiveTip:
            "ใช้การระบุตัวตนแบบโต้ตอบเพื่อตรวจสอบการใช้คำถามที่พบบ่อยในการบริการลูกค้า",
          interactiveIsEmptyTip: "ไม่พบเนื้อหาที่ระบุตัวตนแบบโต้ตอบ",
        },
        semanticMark: {
          semanticContent: "ประโยคติดฉลาก",
          markedSimilarTitel: "ป้ายกำกับความหมายที่คล้ายกัน",
          markedExcludeTitle: "แท็กไม่รวมแท็กความหมายที่คล้ายกัน",
          taskTagTitle: "แท็กความหมายที่เกี่ยวข้องกับงาน",
          similarTagTitle:
            "ระบุแท็กความหมายที่คล้ายกัน (ความคล้ายคลึงกัน> 40%,Top 20)",
          delete: "ลบ",
          markSimilar: "เครื่องหมายที่คล้ายกัน",
          markExclude: "ไม่รวมความคล้ายคลึงกัน",
          none: "ไม่มี",
        },
        selectCallTime: {
          callTime: "เวลาสนทนา/เวลาเริ่มต้นเซสชัน",
          callTimeRemind:
            "คุณสามารถเลือกบันทึกการสนทนาได้สูงสุดไม่เกินหนึ่งเดือน (31วัน) ในปีล่าสุด",
          callRecordCount: "คุณได้เลือกบันทึกการโทร/การสนทนาทั้งหมด {0}",
          getCallRecordError: "การได้รับบันทึกล้มเหลวโปรดลองอีกครั้งในภายหลัง",
        },
      },
      informationEntities: {
        title: "เอนทิตีข้อมูล",
        index: { import: "นำเข้าเอนทิตีข้อมูล" },
        detail: {
          new: "เอนทิตีข้อมูลใหม่",
          edit: "แก้ไขข้อมูลเอนทิตี",
          name: "ชื่อ",
          apiName: "ชื่อ API",
          activeFlag: "ประสิทธิผล",
          remark: "คำอธิบาย",
          logicExp: "เอนทิตีปกติ",
          sentenceList: "เนื้อหาทางกายภาพ",
          tip: "เนื้อหาเอนทิตีรองรับอินพุตหลายรายการคั่นด้วยช่องว่างซึ่งเป็นแถบคำพ้องความหมายของเอนทิตีเดียวกัน",
          remarkLengthError: "ความยาวคำอธิบายต้องไม่เกิน255",
          nameLengthError: "ความยาวของชื่อต้องไม่เกิน40",
          entityDuplicateError: "การทำซ้ำเนื้อหาทางกายภาพ",
        },
      },
      interactiveRecognition: {
        title: "การรับรู้แบบโต้ตอบ",
        classify: "หมวดหมู่",
        addClassify: "เพิ่มหมวดหมู่",
        editClassify: "แก้ไขหมวดหมู่",
        index: { import: "นำเข้าการจดจำแบบโต้ตอบ" },
        detail: {
          new: "สร้างการโต้ตอบอัจฉริยะใหม่",
          edit: "แก้ไขการโต้ตอบอัจฉริยะ",
          type: "ประเภท",
          standardQuestion: "ปัญหามาตรฐาน",
          similarQList: "คำถามที่คล้ายกัน",
          exceptSimilarQList: "ไม่รวมปัญหาที่คล้ายกัน",
          category: "หมวดหมู่",
          targetType: "เป้าหมายการตรวจสอบปัญหา",
          status: "ประสิทธิผล",
          standardAnswer: "คำตอบมาตรฐาน",
          similarAList: "คำตอบที่คล้ายกัน",
          wrongAList: "คำตอบที่ผิด",
        },
      },
      businessAnalysis: {
        title: "การวิเคราะห์ธุรกิจ",
        tip1: "การวิเคราะห์แนวโน้มทางธุรกิจ: การกำหนดคือจำนวนบทสนทนานั่นคือจำนวนบทสนทนาทั้งหมดที่ตรงตามเงื่อนไขและ abscissa คือเวลา",
        tip2: "การวิเคราะห์ความสัมพันธ์: วิเคราะห์ความสัมพันธ์ของตัวบ่งชี้หลายตัวเมาส์ลอยเพื่อแสดงข้อมูลความสัมพันธ์ปริมาณข้อมูลความสัมพันธ์อัตราการทำซ้ำ",
        tip3: "การวิเคราะห์การแปลง: การวิเคราะห์ตัวบ่งชี้หลายตัวสามารถสร้างความสัมพันธ์ในการแปลงร่วมกันได้เกณฑ์การตัดสินสำหรับการแปลงคือการโทร/การสนทนาเดียวกันหากตัวบ่งชี้สองตัวสามารถตีได้ก็จะถือว่ามีความสัมพันธ์ในการแปลงระหว่างตัวบ่งชี้ทั้งสองนี้",
      },
      knowledgeBase: {
        title: "ฐานความรู้",
        list: {
          add: "เพิ่มหมวดหมู่ย่อย",
          delete: "ลบหมวดหมู่",
          categoryName: "ชื่อหมวดหมู่",
          createSuccess: "การสร้างหมวดหมู่ย่อยที่ประสบความสำเร็จ",
          keyWord: "คำสำคัญ",
        },
        detail: {
          type: "ประเภท",
          status: "สถานะ",
          classification: "หมวดหมู่",
          standardQuestion: "ปัญหามาตรฐาน",
          answerDetectionRange: "คำตอบช่วงการตรวจจับ",
          standardAnswer: "คำตอบมาตรฐาน",
        },
      },
      callManage: {
        title: "ตัวกรองการบันทึกการโทร",
        listTitle: "การจัดการการโทร",
        detailTitle: "รายละเอียดการโทร",
      },
      dialogueManage: {
        title: "ตัวกรองข้อความสนทนา",
        listTitle: "การจัดการการสนทนา",
        detailTitle: "รายละเอียดการสนทนา",
      },
      weChatManage: {
        title: "ตัวกรองเซสชัน WeChat ขององค์กร",
        listTitle: "การจัดการเซสชัน WeChat ขององค์กร",
        detailTitle: "รายละเอียดเซสชัน WeChat ขององค์กร",
      },
      speechRecognition: {
        title: "การรู้จำเสียง",
        asr: "การรู้จำเสียง",
        asrRemind:
          "การปิดสวิตช์นี้จะทำให้ฟังก์ชันเสียงเป็นข้อความและฟังก์ชันการตรวจสอบคุณภาพของเครื่องล้มเหลว",
        asrConfig: "กรอง ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "หลังจากเปิดใช้งานการถอดเสียง IM ASR จะดำเนินการ",
        asrConfigRemind:
          "เมื่อเปิดใช้งานเฉพาะสายที่มีสิทธิ์จะดำเนินการเนื้อหา ASR",
        correction: "การแก้ไขข้อผิดพลาดอัจฉริยะ",
        correctionRemind:
          "หลังจากเปิดใช้งานแล้วเนื้อหาใน \"<a href = '/site/semantic-intelligence/smart-words-ibrary/professional-words'target = '_blank'> คำศัพท์อัจฉริยะ-มืออาชีพ </a>\" สำหรับการแก้ไขข้อผิดพลาดบังคับแปลง",
        correctionConfig: "ตัวกรองแก้ไขข้อผิดพลาด",
        advancedSettings: "การตั้งค่าขั้นสูง",
        advancedSettingsTip: "เลือกรุ่น ASR ที่ระบุสำหรับข้อมูลที่ระบุ",
        mandarinASRModel: "โมเดลภาษาจีนกลาง ASR",
        cantoneseASRModel: "โมเดล ASR กวางตุ้ง",
        englishASRModel: "โมเดล ASR ภาษาอังกฤษ",
        correctionConfigRemind:
          "หลังจากเปิดใช้งานเฉพาะการโทรที่ตรงตามเงื่อนไขต่อไปนี้เท่านั้นที่จะได้รับการแก้ไขข้อผิดพลาด",
        customJudgeLogicIsNull: "ตรรกะที่กำหนดเองไม่สามารถว่างเปล่า",
        redoCorrection: "ดำเนินการแก้ไขข้อผิดพลาดอีกครั้ง",
        callTime: "เลือกเวลาโทร",
        callTimeRemind:
          "คุณสามารถเลือกบันทึกการโทรได้สูงสุดไม่เกินหนึ่งเดือน (31วัน) สำหรับครั้งเดียวในปีล่าสุด",
        callRecordCount: "คุณได้เลือกบันทึกการโทรทั้งหมด {0}",
        redoCorrectionSubmit: "ส่งการดำเนินการ",
        callTimeError: "ช่วงเวลาการโทรที่เลือกต้องไม่เกินหนึ่งเดือน (31วัน)",
        callRecordCountError: "จำนวนบันทึกการโทรคือ0โปรดเลือกเวลาโทรอีกครั้ง",
        redoCorrectionSuccess:
          "การแก้ไขข้อผิดพลาดใหม่สำเร็จโปรดไปที่ศูนย์งานเพื่อตรวจสอบ",
        redoCorrectionError:
          "การแก้ไขข้อผิดพลาดใหม่ล้มเหลวโปรดลองอีกครั้งในภายหลัง",
      },
      role: {
        title: "การจัดการบทบาท",
        create: "ตัวละครใหม่",
        detail: {
          title: "บทบาท",
          new: "ตัวละครใหม่",
          view: "ดูบทบาท",
          edit: "แก้ไขบทบาท",
          navTabNames: {
            basicInfo: "สิทธิ์เมนู",
            taskPermissions: "อำนาจงานตรวจสอบคุณภาพ",
            dataPermissions: "สิทธิ์ข้อมูล",
          },
          basicInfo: { roleName: "ชื่อ", permissions: "สิทธิ์" },
          taskPermissions: {
            allTask: "งานตรวจสอบคุณภาพทั้งหมด",
            myCreatedTask: "งานตรวจสอบคุณภาพที่ฉันสร้างขึ้น",
          },
          dataPermissions: {
            dataPermissionsConfig: "การกำหนดค่าสิทธิ์ข้อมูล",
            tips: "ตัวละครสามารถดูข้อมูลในหมวดหมู่ที่เลือก",
            select: "เลือกหมวดหมู่",
            selectPlaceholder:
              "โปรดเลือกหมวดหมู่ที่ต้องการสิทธิ์ในการมองเห็นข้อมูล",
          },
        },
      },
      dataMigration: {
        title: "การโยกย้ายข้อมูล",
        dataUpload: "ข้อมูลย้ายเข้า",
        dataDowload: "การย้ายข้อมูล",
        error: "โปรดเลือกงานตรวจสอบคุณภาพที่จะส่งออก",
        list: {
          info: "โปรดเลือกงานตรวจสอบคุณภาพที่จะย้ายออก",
          listInfo:
            "โปรดเลือกว่าจะย้ายออกจากข้อมูลที่เกี่ยวข้องของงานตรวจสอบคุณภาพหรือไม่ (เช่นพจนานุกรมอัจฉริยะแท็กความหมายเอนทิตีข้อมูลการโต้ตอบอัจฉริยะฯลฯ)",
          noInfo:
            "โปรดเลือกว่าจะย้ายออกจากข้อมูลอื่นที่ไม่ใช่การตรวจสอบคุณภาพหรือไม่",
          ownInfo: "ย้ายออกจากภารกิจเท่านั้น",
          everyInfo: "งานย้ายออกที่เกี่ยวข้อง",
          allInfo: "ย้ายออกทั้งหมด",
          callFilter: "ตัวกรองการโทร",
          talkFilter: "ตัวกรองการสนทนา",
          smartTag: "สมาร์ทแท็ก",
        },
        upload: {
          info: "1.คลิกปุ่มอัปโหลดเพื่ออัปโหลดไฟล์ JSON ที่สอดคล้องกับรูปแบบ",
          uploadSuccess: "อัพโหลดสำเร็จ",
          uploadError: "อัปโหลดล้มเหลว",
        },
      },
      dataAnalysis: {
        businessStatistics: "สถิติธุรกิจ",
        qualityEffect: "การตรวจสอบคุณภาพสุขภาพ",
        qualityAnalysis: "การวิเคราะห์การตรวจสอบคุณภาพ",
        generalInspecitionReport: "รายงานทั่วไป",
        fullAmountWork: "ภาระงานของผู้ตรวจสอบคุณภาพ",
        intelligentTagsAnalysis: "การวิเคราะห์ฉลาก",
        saleIntelligentTagsAnalysis: "การวิเคราะห์ฉลากอัจฉริยะ",
        generalInspecitionReportTip:
          "จำนวนข้อมูลทั้งหมด: นับจำนวนข้อมูลการโทรและการสนทนาทั้งหมดภายในเวลาและขอบเขตงานที่จำกัดในปัจจุบันรวมถึงจำนวนและเปอร์เซ็นต์ของข้อมูลการตรวจสอบคุณภาพอัจฉริยะจำนวนและเปอร์เซ็นต์ของข้อมูลการสุ่มตัวอย่างด้วยตนเองจำนวนข้อร้องเรียนอัตราการสุ่มตัวอย่างและแผนภูมิแนวโน้มเวลา",
        groupTest: "การทดสอบกลุ่ม",
      },
      uploadDownloadList: {
        title: "ศูนย์ภารกิจ",
        tabs: {
          upload: "อัพโหลดและนำเข้า",
          download: "ดาวน์โหลดส่งออก",
          taskCenter: "การปฏิบัติงาน",
        },
      },
      uploadDownloadModel: {
        title: "อัปโหลดและดาวน์โหลด",
        list: {
          clear: "ว่างเปล่า",
          success: "ประสบความสำเร็จ",
          fail: "ล้มเหลว",
          hand: "กำลังดำเนินการอยู่",
          noInfo: "ไม่มีข้อมูล",
        },
      },
      billingCenter: {
        title: "ศูนย์การเรียกเก็บเงิน",
        export: "ส่งออก",
        limit: "ขีดจำกัดการแจ้งเตือน",
        tips: "ผลการเรียกเก็บเงินจะถูกปัดเศษเพื่อการอ้างอิงเท่านั้น",
        dashboard: {
          remainingQuota: "โควต้าที่เหลือในปัจจุบัน",
          usedQuota: "โควต้าที่ใช้สะสม",
          voiceQuality: "การตรวจสอบคุณภาพเสียง:",
          voice: "เสียง:",
          textQuality: "การตรวจสอบคุณภาพข้อความ:",
          text: "ข้อความ:",
          hour: "ชั่วโมง",
          tenThousandHour: "ล้านชั่วโมง",
          day: "วัน",
          cutoff: "กำหนดเวลา",
          warning: "(โควต้าที่เหลือไม่เพียงพอโปรดเติมเงินโดยเร็วที่สุด)",
          isClosed: "(ปิดสถานะ)",
        },
        table: {
          quotaRecord: "บันทึกโควต้า",
          timeFilter: "เลือกวันที่:",
          search: "สอบถามข้อมูล",
          reset: "รีเซ็ต",
          id: "หมายเลขซีเรียล",
          type: "ประเภท",
          qualityType: "ประเภทการตรวจสอบคุณภาพ",
          quotaChange: "การเปลี่ยนแปลง",
          surplus: "ยอดคงเหลือ",
          changeTime: "เวลา",
          voiceQuality: "การตรวจสอบคุณภาพเสียง",
          textQuality: "การตรวจสอบคุณภาพข้อความ",
          sizeChangeText: "แสดงต่อหน้า",
          used: "ใช้",
          give: "ได้รับรางวัล",
          deduction: "การหักเงิน",
          callTime: "เวลาพูดคุย",
          count: "ปริมาณข้อมูลที่ประมวลผล",
          page: "ทั้งหมด {0},{1} หน้า",
        },
        limitModal: {
          call: "โควต้าการตรวจสอบคุณภาพเสียงต่ำกว่า",
          callFix: "เตือนความจำชั่วโมง",
          dialogue: "เวลาที่เหลือสำหรับการตรวจสอบข้อความน้อยกว่า",
          dialogueFix: "วันเตือนความจำ",
        },
      },
      messageCenter: {
        replyTitle: "ตอบกลับของฉัน",
        answerTitle: "@ ของฉัน",
        shareTitle: "แบ่งปันของฉัน",
      },
      columnsManage: { title: "การจัดการฟิลด์การตรวจสอบคุณภาพ" },
      recordUpload: {
        list: {
          task: "งานตรวจสอบคุณภาพ",
          user: "ผู้ดำเนินการ",
          uploadTime: "เวลาอัปโหลด",
          endTime: "เวลาสิ้นสุด",
          total: "จำนวนการอัปโหลดทั้งหมด",
          success: "การตรวจสอบคุณภาพที่ประสบความสำเร็จ",
          failed: "การตรวจสอบคุณภาพล้มเหลว",
          progress: "ความคืบหน้าการตรวจสอบคุณภาพ",
          inProgress: "กำลังดำเนินการอยู่",
          finished: "เสร็จเรียบร้อยแล้ว",
        },
        steps: {
          uploading: "อัปโหลดไฟล์บันทึก",
          baseInfo: "กรอกข้อมูลพื้นฐาน",
          start: "เริ่มการตรวจสอบคุณภาพ",
        },
        uploadComponent: {
          dragger: "ลากและวางเพื่อเพิ่มไฟล์",
          or: "หรือ",
          selectFile: "เลือกไฟล์",
          des: "สนับสนุนรูปแบบ wav, mp3, อัตราตัวอย่าง8000Hz, ไฟล์16บิต",
          limit1: "อัปโหลดสูงสุดในครั้งเดียว",
          limit2: "ไฟล์แต่ละไฟล์มีขนาดไม่เกิน",
          uploading: "กำลังอัปโหลด",
          finished: "อัพโหลดเสร็จ",
          success: "ประสบความสำเร็จ",
          failure: "ล้มเหลว",
        },
        baseInfo: {
          task: "เลือกงานตรวจสอบคุณภาพ",
          type: "เลือกรูปแบบการบันทึก",
          agentSide: "ช่องทางที่ฝ่ายบริการลูกค้าตั้งอยู่",
          excel: "อัปโหลดข้อมูลตามเส้นทาง",
          checkFileInfo: "ตรวจสอบข้อมูลเอกสาร",
          excelFileError: "กรุณาอัปโหลดข้อมูล",
          checking: "กำลังตรวจสอบ...",
          reCheck: "ตรวจสอบซ้ำ",
          success: "ตรวจสอบสำเร็จ",
          excelUoloadFail: "การอัปโหลดข้อมูลบนท้องถนนล้มเหลว",
          tips: {
            tip1: "1.ชื่อของไฟล์บันทึกใน ecxel ต้องตรงกับชื่อบันทึกที่อัปโหลดและไม่สามารถใช้ชื่อเดียวกันได้",
            tip2: "2.ระบบใช้ชื่อไฟล์บันทึกและรหัสการโทรเพื่อตรวจสอบซ้ำการทำซ้ำจะทำให้การตรวจสอบคุณภาพล้มเหลว",
            tip3: "3.เทมเพลตการบันทึก excel ที่ดาวน์โหลดมาสีตัวอักษรสีแดงเป็นฟิลด์ที่จำเป็น",
            tip4: "4.รูปแบบฟิลด์ประเภทเวลา: yyyy-mm-dd hh: ss เช่น2021-04-20 17:36:30",
          },
        },
        title: "อัปโหลดการบันทึกในท้องถิ่น",
        checkTitle: "การตรวจสอบคุณภาพการบันทึก",
        start: "เริ่มการตรวจสอบคุณภาพ",
        disabledTips: "โปรดตรวจสอบไฟล์บันทึกก่อนทำการตรวจสอบคุณภาพ",
        tips: "ไฟล์บันทึกที่มีชื่อเดียวกันครอบคลุมผลการตรวจสอบคุณภาพและข้อมูลพื้นฐาน",
        tips1: "ลากและวางไฟล์ที่นี่หรือคลิกอัปโหลด",
        tips2: "สนับสนุน",
        tips3: "รูปแบบ",
        type: "wav、mp3",
        tips4: "อัปโหลดสูงสุดในครั้งเดียว",
        tips5: "ไฟล์แต่ละไฟล์มีขนาดไม่เกิน",
        tipsOverSize: "คิวปัจจุบันเต็มโปรดลบไฟล์ในคิวก่อนอัพโหลด",
        size: "500M",
        count: "20",
        uploaded: "อัปโหลดไฟล์บันทึก",
        uploading: "({0}%)",
        uploadFinished: "(อัพโหลดเสร็จ)",
        bottomTitle: "การรวบรวมข้อมูลการบันทึก:",
        recordType: "เลือกรูปแบบการบันทึก:",
        double: "บันทึกการโทรสองช่อง",
        single: "บันทึกการโทรช่องเดียว",
        recordBaseInfo: "อัปโหลดข้อมูลพื้นฐานของการบันทึก:",
        addBtn: "เพิ่มไฟล์",
        downloadTemplate: "ดาวน์โหลดเทมเพลต .excel",
        checkRecord: "ตรวจสอบข้อมูลการบันทึก",
        checking: "กำลังตรวจสอบ...",
        reCheck: "ตรวจสอบซ้ำ",
        checkAll: "เลือกทั้งหมด",
        cancelCheckAll: "ยกเลิกการเลือกทั้งหมด",
        delete: "ลบ",
        deleteLotSize: "การลบแบทช์",
        agentChannel: "ช่องทางการบริการลูกค้า:",
        left: "ช่องซ้าย",
        right: "ช่องขวา",
        donotKonw: "ไม่รู้สิ",
        mission: "เลือกงานตรวจสอบคุณภาพ:",
        uploadFail: "อัปโหลดล้มเหลว",
        repeat: "ไฟล์ซ้ำ",
        overCount: "จำนวนไฟล์เกินขีดจำกัด",
        overSize: "ขนาดไฟล์เกินขีดจำกัด",
        uoloadFail: "การอัปโหลดไปยังระบบคลาวด์ล้มเหลว",
        formatError: "ข้อผิดพลาดในการจัดรูปแบบ",
        seccess: "ตรวจสอบสำเร็จ",
        fail: "การตรวจสอบล้มเหลว",
        recordChecking: "การตรวจสอบคุณภาพการบันทึกโปรดรอสักครู่...",
        recordCheckSuccess: "การตรวจสอบคุณภาพการบันทึกเสร็จสมบูรณ์",
        recordCheckFail:
          "การตรวจสอบคุณภาพการบันทึกล้มเหลวโปรดตรวจสอบว่าการบันทึกซ้ำหรือไม่ถูกต้อง",
        backToIndex: "กลับไปที่รายการอัปโหลด",
        recordCheckIsStarted:
          "ขณะนี้มีงานตรวจสอบคุณภาพอยู่ระหว่างดำเนินการโปรดเริ่มงานใหม่หลังจากงานปัจจุบันเสร็จสิ้น",
      },
      customerServicePortrait: {
        title: "ภาพบริการลูกค้า",
        tip1: "กฎการแจกแจงความยาวของประโยค: การกระจายความยาวของประโยคจะถูกนับตามจำนวนคำที่ยาวของประโยคและจะคำนวณเฉพาะการบริการลูกค้าเท่านั้น",
        tip2: "กฎการกระจายความเร็วในการพูด: การกระจายความเร็วในการพูดตามความเร็วในการพูด (คำ/นาที) สถิติจะแบ่งออกเป็นส่วนๆและจะคำนวณเฉพาะการบริการลูกค้าเท่านั้น",
        tip3: "กฎหมายการกระจายเวลาตอบสนอง: การกระจายเวลาตอบสนองคำนวณเฉพาะการตอบสนองของลูกค้าต่อลูกค้า",
        tip4: "การปฏิบัติตามการตรวจสอบคุณภาพ: อัตราการปฏิบัติตามของจุดตรวจสอบคุณภาพ",
        tip5: "คะแนนเฉลี่ยของงานตรวจสอบคุณภาพ: คะแนนเฉลี่ยของงานตรวจสอบคุณภาพ",
        contol: {
          date: "เลือกวันที่:",
          today: "วันนี้",
          thisMonth: "เดือนนี้",
          lastMonth: "เมื่อเดือนที่แล้ว",
          thisQuarter: "ไตรมาสนี้",
          lastQuarter: "ไตรมาสที่แล้ว",
          thisYear: "ในปีนี้",
          lastYear: "ปีที่แล้ว",
          customer: "บริการลูกค้า:",
          search: "สอบถามข้อมูล",
          reset: "รีเซ็ต",
          export: "ส่งออก",
          indicator: "การจัดการดัชนี",
          selectPlaceholder: "โปรดเลือกบริการลูกค้าที่ต้องการเปรียบเทียบ",
          warning: "เลือกฝ่ายบริการลูกค้าสูงสุด5คนเพื่อเปรียบเทียบ",
          warning2: "โปรดเลือกบริการลูกค้าอย่างน้อย1รายเพื่อสอบถามข้อมูล",
          dataWarning:
            "รองรับการสอบถามข้อมูล366วันหลังจากวันที่เริ่มต้นเท่านั้น",
          seccuss: "บันทึกการส่งออกที่ประสบความสำเร็จ",
        },
        echarts: {
          distributionLaw: "กฎหมายการกระจาย",
          count: "จำนวนครั้ง",
          bout: "ครั้ง",
        },
        portrait: {
          ability: "มิติความสามารถในการบริการลูกค้า",
          option: "การกำหนดค่า",
          abilityOption: "มิติความสามารถในการกำหนดค่า",
          placeholder: "ความสามารถในการป้อนข้อมูลมิติการค้นหาคำหลัก",
          save: "บันทึก",
          warning:
            "โปรดเลือกตัวบ่งชี้อย่างน้อย3ตัวสำหรับการกำหนดค่าคุณสามารถเลือกตัวบ่งชี้ได้มากถึง10ตัว",
        },
        label: { title: "ป้ายบริการลูกค้า" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "การจัดอันดับการบริการลูกค้า",
        select: {
          date: "เลือกวันที่:",
          today: "วันนี้",
          thisMonth: "เดือนนี้",
          lastMonth: "เมื่อเดือนที่แล้ว",
          thisQuarter: "ไตรมาสนี้",
          lastQuarter: "ไตรมาสที่แล้ว",
          thisYear: "ในปีนี้",
          lastYear: "ปีที่แล้ว",
          group: "กลุ่มบริการลูกค้า:",
          groupPlaceholder: "โปรดเลือกกลุ่มบริการลูกค้า",
          customer: "บริการลูกค้า:",
          customerPlaceholder: "โปรดเลือกบริการลูกค้า",
          search: "สอบถามข้อมูล",
          reset: "รีเซ็ต",
          compared: "ความคมชัด",
          export: "ส่งออก",
          column: "คอลัมน์",
          warning: "เลือกฝ่ายบริการลูกค้าสูงสุด5คนเพื่อเปรียบเทียบ",
          warning2: "โปรดเลือกฝ่ายบริการลูกค้าอย่างน้อยหนึ่งคน",
          dataWarning:
            "รองรับการสอบถามข้อมูล366วันหลังจากวันที่เริ่มต้นเท่านั้น",
          selected: "ฝ่ายบริการลูกค้าที่เลือก:",
          seccuss: "บันทึกการส่งออกที่ประสบความสำเร็จ",
        },
        compared: {
          title: "เลือกบริการลูกค้า",
          return: "การยกเลิก",
          compared: "ความคมชัด",
          tips: "โปรดเลือกบริการลูกค้า2-5คนเพื่อเปรียบเทียบ",
          warning: "รองรับเฉพาะลูกค้า2-5คนสำหรับการเปรียบเทียบ",
        },
        transfer: { leftTitle: "รายการรายการ", rightTitle: "แสดงรายการ" },
        table: {
          page: "ทั้งหมด {0},{1} หน้า",
          actionTitle: "การดำเนินงาน",
          action: "ดู",
        },
      },
      conceptWord: {
        title: "คำแนวคิด",
        new: "คำแนวคิดใหม่",
        control: {
          search: "ค้นหา:",
          searchPlaceholder: "ค้นหาตามชื่อหรือคำหลัก",
          commit: "สอบถามข้อมูล",
          reset: "รีเซ็ต",
          import: "นำเข้า",
          export: "ส่งออก",
          seccuss: "บันทึกการส่งออกที่ประสบความสำเร็จ",
        },
        modal: { title: "นำเข้าคำแนวคิด" },
        table: {
          sizeChangeText: "แสดงต่อหน้า",
          page: "ทั้งหมด {0},{1} หน้า",
          edit: "บรรณาธิการ",
          delete: "ลบ",
          request: "หลังจากลบแล้วจะไม่สามารถกู้คืนได้คุณยืนยันการลบหรือไม่?",
          cancel: "การยกเลิก",
          title: {
            conceptWord: "คำแนวคิด",
            describeWordsNum: "อธิบายจำนวนคำ",
            updateTime: "เวลาอัพเดต",
            status: "ประสิทธิผล",
            effective: "มีประสิทธิภาพ",
            invalid: "ไม่ถูกต้อง",
            action: "การดำเนินงาน",
          },
        },
        detail: {
          title: { new: "คำแนวคิดใหม่", edit: "แก้ไขคำแนวคิด" },
          name: "ชื่อคำแนวคิด",
          namePlaceholder: "โปรดป้อนชื่อคำแนวคิด",
          effectiveness: "ประสิทธิผล",
          effective: "มีประสิทธิภาพ",
          invalid: "ไม่ถูกต้อง",
          description: "คำอธิบายคำ:",
          descriptionPlaceholder: "โปรดป้อนคำอธิบาย",
          add: "เพิ่ม",
          repeat: "เนื้อหาที่ซ้ำกัน",
          empty: "คำอธิบายต้องไม่ว่างเปล่า",
          recommendEmpty:
            "ไม่มีคำอธิบายที่แนะนำเพิ่มเติมโปรดป้อนและเพิ่มด้วยตนเอง",
          recommend: "คำอธิบายคำแนะนำที่ชาญฉลาด",
          cancel: "การยกเลิก",
          change: "เปลี่ยนชุด",
          descriptionSelected: "เพิ่มคำอธิบายคำ:",
          save: "บันทึก",
          success: "แนวคิดคำบันทึกความสำเร็จ",
          warning: "ชื่อคำแนวคิดต้องไม่ว่างเปล่า",
          warning2: "กรุณาเพิ่มคำอธิบายอย่างน้อย1คำ",
          submitBtnHandle: {
            title:
              "หากไม่มีการเพิ่มคำอธิบายที่ตรวจสอบการบันทึกจะละทิ้งคำอธิบายที่ไม่ได้เพิ่ม",
            content:
              "คลิกตกลงจะกลับไปที่หน้าแรกของคำแนวคิดยืนยันดำเนินการต่อหรือไม่?",
          },
        },
      },
      smartTools: {
        btn: "เครื่องมืออัจฉริยะ",
        title: {
          similarWords: "การค้นพบคำที่เกี่ยวข้อง",
          search: "ประโยคตามคำ",
          semanticTags: "แท็กความหมาย",
        },
        sentenceSearch: {
          placeholder: "โปรดป้อนคำหลักเพื่อค้นหารองรับคำหลักหลายคำ",
          more: "ดูข้อความแบบเต็ม",
          pageUp: "ก่อนหน้า",
          pageDown: "หน้าถัดไป",
          total: "บันทึกทั้งหมด {0}",
        },
        similarDiscover: {
          placeholder: "กรุณาใส่คำสำคัญ",
          copyed: "คัดลอกแล้ว",
          none: "ไม่มีคำที่เกี่ยวข้องโปรดลองใช้คำหลักอื่นๆ",
        },
        smartTags: {
          searchPlaceHolder: "โปรดป้อนคำหลักหรือประโยค",
          createSmartTag: "สร้างใหม่",
          createClusteringSmartTag: "สร้างใหม่ด้วย {0} ข้อมูล",
          addFromKeyword: "เพิ่มเนื้อหาการค้นหาลงในประโยคที่คล้ายกัน",
          hit: "ตี",
          similar: "คล้ายกัน",
          name: "ชื่อแท็กความหมาย",
          namePlaceHolder: "กรุณากรอกชื่อแท็กความหมาย",
          recallWords: "คำเรียกคืน",
          recallWordsPlaceHolder: "โปรดป้อนคีย์เวิร์ดเรียกคืน",
          recallRegular: "เรียกคืนปกติ",
          recallRegularPlaceHolder: "กรุณากรอกกฎการเรียกคืน",
          rules: "กฎ",
          standardSentences: "ประโยคมาตรฐาน",
          standardSentencesPlaceHolder: "กรุณาป้อนประโยคมาตรฐาน",
          similaSentences: "ประโยคที่คล้ายกัน",
          similaSentencesPlaceHolder: "โปรดป้อนประโยคที่คล้ายกัน",
          notSaveError: "โปรดบันทึกแท็กความหมายก่อนค้นหา",
          add: "เพิ่ม",
          newPage: "เปิดในหน้าใหม่",
          querryError:
            "มีฟิลด์ที่คล้ายกันในรายการทั่วไป (การกำหนดค่าขั้นสูงคล้ายกับการลบข้อจำกัดใหม่)",
          detailTitle: "รายละเอียดแท็กความหมาย",
        },
      },
      trainingCenter: {
        title: "ศูนย์ฝึกอบรม",
        edit: "บรรณาธิการ",
        delete: "ลบ",
        seleteAll: "เลือกทั้งหมด",
        cancelCheckAll: "ยกเลิกการเลือกทั้งหมด",
        add: "เพิ่ม",
        remove: "เอาออก",
        save: "บันทึก",
        cancel: "การยกเลิก",
        fastTraining: {
          title: "การฝึกอบรมอย่างรวดเร็ว",
          titleDes:
            "การฝึกอบรมอย่างรวดเร็วได้รับการออกแบบมาเพื่อช่วยให้แท็กความหมายทั่วไปได้อย่างรวดเร็วถึง20ประโยคที่คล้ายกัน",
          semanticTags: "แท็กความหมาย",
          semanticTagsPlaceholder: "โปรดป้อนการค้นหาคำหลักของกระบวนการ",
          standardSentence: "ประโยคมาตรฐาน:{0}",
          similarSentencesPlaceholder:
            "ป้อนประโยคที่คล้ายกันเพื่อเพิ่มหรือค้นหา",
          similarSentencesAdd: "เพิ่ม",
          similarSentencesSearch: "ค้นหา",
          similarSentences: "เพิ่มประโยคที่คล้ายกัน",
          trainingCount: "จำนวนการฝึกอบรม:{0}",
          similarSentencesRecommend: "แนะนำประโยคที่คล้ายกัน",
          noResp: "ไม่มีแท็กความหมายที่เกี่ยวข้องโปรดเปลี่ยนคำหลัก",
          similarSentencesModalTitle: "ค้นหาประโยคที่คล้ายกัน",
          moreInfo: "ดูบริบท",
          moreInfoBtn: "บริบท",
          modalSearchType: {
            all: "ทั้งหมด",
            customer: "บริการลูกค้า",
            client: "ลูกค้า",
          },
          repetWarning: "มีประโยคที่คล้ายกันอยู่แล้วโปรดอย่าเพิ่มซ้ำ",
          repetRemoveTip:
            "บันทึกเนื้อหาที่ซ้ำกันในประโยคที่คล้ายกันซึ่งจะถูกลบโดยอัตโนมัติ",
        },
        trainingHistory: {
          title: "ประวัติการฝึกอบรม",
          select: {
            date: "เลือกเวลา:",
            today: "วันนี้",
            thisMonth: "เดือนนี้",
            lastMonth: "เมื่อเดือนที่แล้ว",
            thisQuarter: "ไตรมาสนี้",
            lastQuarter: "ไตรมาสที่แล้ว",
            thisYear: "ในปีนี้",
            lastYear: "ปีที่แล้ว",
            search: "สอบถามข้อมูล",
            reset: "รีเซ็ต",
          },
          table: {
            time: "เวลา",
            user: "ผู้ดำเนินการ",
            tag: "แท็กความหมาย",
            similarCount: "จำนวนประโยคที่คล้ายกัน",
            deleteCount: "ลบจำนวนประโยค",
            page: "ทั้งหมด {0},{1} หน้า",
          },
        },
        classifiedTraining: {
          title: "การฝึกอบรมประเภท",
          tagsGrounp: "การจัดกลุ่มแท็บ",
          placeHolder: "โปรดเลือก",
          cellTime: "เลือกเวลา",
          search: "สอบถามข้อมูล",
          reset: "รีเซ็ต",
          classifiedFlag: "การจำแนกสถานการณ์การฝึกอบรม",
          num: "ค่าตัวเลข",
          gas: "ร้อยละ",
          noData: "การฝึกอบรมที่ไม่มีหมวดหมู่",
          noDataHeight:
            "ข้อมูลที่ไม่ได้จัดประเภทมีสัดส่วนที่ค่อนข้างสูงและจำเป็นต้องมีการฝึกอบรมอย่างเร่งด่วน",
          noDataSmall:
            "ข้อมูลที่ไม่ได้จัดประเภทมีสัดส่วนที่ค่อนข้างสูงขอแนะนำให้ทำการฝึกอบรม",
          noDataDays: "ล่าสุดดำเนินการ {0} วันที่ผ่านมา",
          training: "การฝึกอบรม",
          start: "เริ่มต้น",
          addClass: "เพิ่มหมวดหมู่",
          custom: "การคัดกรองขั้นสูง",
          marked: "แก้ไขข้อผิดพลาดของข้อมูลที่ทำเครื่องหมายไว้",
          markedHeight:
            "ไม่มีการแก้ไขข้อผิดพลาดในวันที่ {0} ขอแนะนำให้ทำการแก้ไขข้อผิดพลาด",
          markedSmall:
            "ไม่มีข้อผิดพลาดใน {0} และจำเป็นต้องมีการแก้ไขอย่างเร่งด่วน",
          mark: "แก้ไขข้อผิดพลาด",
          dataCheck: "การตรวจสอบข้อมูลที่จัดประเภท",
          dataCheckSmall: "การกระจาย K-L สูงกว่า0.5ขอแนะนำให้ฝึก",
          dataCheckHeight:
            "การกระจาย K-L สูงกว่า0.8และจำเป็นต้องมีการฝึกอบรมอย่างเร่งด่วน",
          check: "การตรวจสอบ",
          history: "บันทึกประวัติติดฉลาก",
          historyTime:
            "การติดฉลากและการแก้ไขรายวันจะดำเนินการฝึกอบรมแบบจำลองโดยอัตโนมัติในเวลา02:00น.",
          today: "ทำเครื่องหมายวันนี้",
          article: "แถบ",
          none: "ไม่มี",
          historyCheck: "การติดฉลากรวมทางประวัติศาสตร์",
          look: "ดูบันทึก",
          historyluate: "การคำนวณข้อมูลทางประวัติศาสตร์ใหม่",
          startluate: "ส่งการคำนวณการยก",
          classData: "จำแนกข้อมูล",
          classStatus: "ความคืบหน้าการฝึกอบรม",
          next: "ถัดไป",
          prev: "ก่อนหน้านี้",
          finish: "เสร็จสิ้นการฝึกอบรม",
          stop: "สิ้นสุดการฝึกอบรม",
          classDetail: "รายละเอียดการฝึกอบรม",
          trainingNone: "ไม่มีสมาร์ทแท็กที่ผ่านการฝึกอบรม",
        },
        classifiedTrainingHistory: {
          title: "ประวัติการฝึกอบรม",
          time: "เลือกเวลา:",
          tag: "ประเภทแท็บ:",
          user: "ผู้ประกอบการ:",
          data: "เลือกแหล่งข้อมูล:",
          search: "สอบถามข้อมูล",
          searchBar: "ค้นหา:",
          searchBarPlaceholder: "โปรดป้อน ID การโทร/ค้นหา ID การสนทนา",
          reset: "รีเซ็ต",
          export: "ส่งออก",
          all: "ทั้งหมด",
          select: {
            today: "วันนี้",
            thisMonth: "เดือนนี้",
            lastMonth: "เมื่อเดือนที่แล้ว",
            thisQuarter: "ไตรมาสนี้",
            lastQuarter: "ไตรมาสที่แล้ว",
            thisYear: "ในปีนี้",
            lastYear: "ปีที่แล้ว",
            search: "สอบถามข้อมูล",
            reset: "รีเซ็ต",
          },
          table: {
            user: "ผู้ประกอบการ",
            date: "เวลาในการดำเนินงาน",
            data: "แหล่งข้อมูล",
            id: "โทร ID/ID การสนทนา",
            tagType: "การจัดกลุ่มฉลากอัจฉริยะ",
            tagName: "ชื่อฉลาก",
            state: "สถานะ",
            sizeChangeText: "แสดงต่อหน้า",
            page: "ทั้งหมด {0},{1} หน้า",
            call: "โทร",
            dialogue: "การสนทนา",
          },
        },
        clusteringAnnotation: {
          title: "การทำเครื่องหมายคลัสเตอร์",
          left: { title: "ผลการจัดกลุ่ม" },
          right: {
            title: "ประโยคคลัสเตอร์",
            checkAll: "เลือกหน้านี้ทั้งหมด",
            tagging: "ป้ายกำกับ",
            detail: "ดู",
            meaningless: "ละเว้น",
            meaninglessTips:
              "ผลการจัดกลุ่มที่ถูกละเลยจะไม่แสดงอีกต่อไปคุณแน่ใจหรือไม่ว่าคุณต้องการเพิกเฉย",
            cancel: "การยกเลิก",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "การจัดการลูกค้า",
          tabTitle: "ศูนย์บริการลูกค้า",
        },
        wechatClientManage: {
          title: "ลูกค้าขนาดเล็ก",
          table: {
            id: "รหัสลูกค้า",
            name: "ชื่อเล่นของลูกค้า",
            tag: "แท็กของลูกค้า",
          },
        },
      },
      collectionRule: {
        title: "กฎการรวบรวม",
        form: {
          info: "รวบรวมข้อมูลอย่างชาญฉลาด",
          infoPlaceholder: "โปรดเลือกหมวดหมู่",
        },
        table: {
          name: "ชื่อฟิลด์",
          props: "คุณสมบัติของลูกค้า",
          type: "ประเภทฟิลด์",
          desc: "คำอธิบายฟิลด์",
        },
      },
      dataAdd: {
        title: "เพิ่มข้อมูล",
        types: { auto: "เพิ่มอัตโนมัติ", manual: "เพิ่มด้วยตนเอง" },
      },
      wechatStaff: {
        table: {
          name: "ชื่อ",
          part: "แผนก",
          phone: "หมายเลขโทรศัพท์มือถือ",
          email: "กล่องจดหมาย",
          wechartId: "องค์กร WeChat ID",
          status: "สถานะการทำงาน",
        },
        info: "โปรดเลือกแผนกที่ต้องการซิงค์ข้อมูลก่อน",
        synSuccess: "ซิงค์สำเร็จ",
        init: "การเริ่มต้น",
        syn: "ซิงค์",
      },
      appealHistory: {
        appealTime: "เวลาอุทธรณ์",
        cancelTime: "เวลาเพิกถอน",
        reviewTime: "เวลาตรวจสอบ",
        inspector: "ผู้ตรวจสอบคุณภาพ",
        result: "ผลการอุทธรณ์",
        complainResult:
          "การอุทธรณ์ทั้งหมด {0} รายการตรวจสอบคุณภาพการอุทธรณ์ที่ประสบความสำเร็จ {1} และการปฏิเสธ {2}",
        reason: "เหตุผลในการอุทธรณ์",
        rejection: "เหตุผลในการปฏิเสธ",
        cancel: "เพิกถอน",
        tip: "ยืนยันว่าจะถอนการอุทธรณ์หรือไม่?",
      },
      appealSdk: {
        time: "เวลาสนทนา",
        externalIdCall: "ID บันทึกการโทร",
        externalIdText: "ID บันทึกการสนทนา",
        customerNickName: "ชื่อเล่นของลูกค้า",
        readStatus: "ตรวจสอบสถานะ",
        status: "สถานะการอุทธรณ์",
        isManual: "การตรวจสอบคุณภาพด้วยตนเองหรือไม่",
        complainButtonInfo:
          "อนุญาตให้มีการอุทธรณ์ {0} ครั้งมีการอุทธรณ์ {1} ครั้งและสามารถอุทธรณ์ได้ที่เหลือ {2} ครั้ง {3} สามารถอุทธรณ์ได้ก่อน",
        submit: "ยื่นเรื่องร้องเรียน",
        complain: "การอุทธรณ์",
        allComplain: "อุทธรณ์ทั้งหมด",
        pendingSubmit: "ที่จะส่ง",
        taskIds: "งานตรวจสอบคุณภาพ",
        graded: "การให้คะแนน",
        complainSuccess: "ยื่นอุทธรณ์เรียบร้อยแล้ว",
        appealSdkIndex: { title: "ผลการตรวจสอบคุณภาพอัจฉริยะ" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "ได้รับข้อมูลรายการตัวกรองล้มเหลวโปรดตรวจสอบการเชื่อมต่ออินเทอร์เน็ตหรือลองอีกครั้งในภายหลัง!",
      columns: "คอลัมน์",
      filter: "การคัดกรอง",
      noSearchResult: "ไม่พบบันทึก",
      pleaseSearch: "โปรดเลือกตัวกรองและพยายามที่จะค้นหา",
      sift: "การคัดกรอง",
      create: "สร้างใหม่",
      search: "ค้นหา",
      createTaskTitle: "งานใหม่",
      title: "ชื่อเรื่อง",
      deadline: "กำหนดเวลา",
      principal: "ผู้รับผิดชอบ",
      enqueueTime: "เวลาเข้าทีม",
      computeError: "การคำนวณล้มเหลวโปรดในภายหลัง!",
      sum: "แสวงหาสันติภาพ",
      max: "ค่าสูงสุด",
      min: "ค่าต่ำสุด",
      avg: "ค่าเฉลี่ย",
      count: "นับ",
      maxDate: "สูงสุด (ล่าสุด)",
      minDate: "ค่าต่ำสุด (เก่าที่สุด)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "ไม่สามารถรับที่อยู่ลิงก์ Insight โปรดลองอีกครั้งในภายหลัง!",
      },
      template: {
        learningReminder: "การแจ้งเตือนการเรียนรู้",
        subscriptionDynamics: "สมัครรับข่าวสาร",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "การจัดการงาน", chaneg: "สลับ" },
        qualityCheckTemplate: {
          text: "แม่แบบการตรวจสอบคุณภาพ",
          collectText: "เทมเพลตคอลเลกชัน",
          condition: { text: "เงื่อนไขการตรวจสอบคุณภาพ" },
          checkPoint: { text: "การกำหนดค่ากฎ" },
          automaticAdd: { text: "กระบวนการอัตโนมัติ" },
          manualAdd: { text: "เพิ่มด้วยตนเอง" },
          roleConfig: { text: "การกำหนดค่าบทบาท" },
          basicInfo: { text: "ข้อมูลพื้นฐาน" },
          smartWordsLibrary: { text: "พจนานุกรมอัจฉริยะ" },
          grade: { text: "การให้คะแนนการตรวจสอบคุณภาพ" },
          collectionRule: { text: "กฎการรวบรวม" },
          addData: { text: "เพิ่มข้อมูล" },
          collectionList: { text: "รายการคอลเลกชัน" },
        },
        qualityCheckWorkbench: {
          text: "โต๊ะตรวจสอบคุณภาพ",
          wechatText: "โต๊ะวิเคราะห์",
          informationCollectText: "โต๊ะรวบรวม",
          qualityInspectionList: {
            text: "รายการตรวจสอบคุณภาพ",
            wechatText: "รายการวิเคราะห์",
            informationCollectText: "รายการคอลเลกชัน",
          },
          condition: { text: "รายการตรวจสอบคุณภาพ (ทิ้ง)" },
          reviewList: { text: "รายการตรวจสอบ" },
          spotCheckList: { text: "รายการตรวจสอบการสุ่มตัวอย่าง" },
          caseBaseList: { text: "ห้องสมุดกรณี" },
          testSetList: { text: "ชุดทดสอบการตรวจสอบคุณภาพ" },
        },
        statisticsAndReports: {
          text: "สถิติและรายงาน",
          wholeReport: { text: "รายงานโดยรวม" },
          groupReport: { text: "รายงานกลุ่มบริการลูกค้า" },
          agentReport: { text: "รายงานการบริการลูกค้า" },
          customerServiceExecution: { text: "การวิเคราะห์การบริการลูกค้า" },
          analysisOverview: { text: "ภาพรวมการวิเคราะห์" },
          inspectionItemsAnalysis: { text: "การวิเคราะห์รายการตรวจสอบคุณภาพ" },
        },
        wechatQa: {
          text: "การตรวจสอบคุณภาพ WeChat ขององค์กร",
          task: { text: "งานวิเคราะห์" },
          baseInfo: { text: "ข้อมูลพื้นฐาน" },
          checkPoint: { text: "การวิเคราะห์การกำหนดค่าโมเดล" },
          setting: { text: "การกำหนดค่าการวิเคราะห์ WeChat ขององค์กร" },
          automaticAdd: { text: "กระบวนการอัตโนมัติ" },
          manualAdd: { text: "เพิ่มด้วยตนเอง" },
        },
        clientCenter: { text: "ศูนย์บริการลูกค้า" },
      },
      conditionDictionaries: {
        title: "เงื่อนไขทั้งหมด",
        searchDefaultText: "กรุณากรอก ID หรือชื่อ",
        id: "ID",
        name: "ชื่อ",
        conditionDetailedShow: "ขยาย",
        conditionDetailedHide: "เก็บไว้",
        remark: "คำอธิบาย:",
        checkRole: "บทบาท:",
        operator: "ตัวดำเนินการ:",
        operatorLogic: "ตรรกะ:",
      },
      audioTextView: {
        customer: "ลูกค้า",
        agent: "บริการลูกค้า",
        unsupportedFormat: "ไม่รองรับรูปแบบนี้ชั่วคราว",
      },
      checkPointClassifications: {
        add: "เพิ่มหมวดหมู่",
        name: "ชื่อ",
        enterToSave: "กด Enter เพื่อบันทึก",
        highestScore: "คะแนนสูงสุด",
        minimumScore: "คะแนนขั้นต่ำ",
        defaultMinScore: "ค่าเริ่มต้นคือ0คะแนน",
        minimumScoreTitle: "ขีดจำกัดล่างของคะแนน",
        beginScore: "ค่าคะแนนโดยรวม",
        beginScoreTitle: "ค่าคะแนนเริ่มต้น",
        scoreSetTip:
          "ขีดจำกัดสูงสุดของคะแนนสูงสุดน้อยกว่าผลรวมของแต่ละคะแนนคุณสามารถเลือก:",
        ignoreExcessSpillover: "ละเว้นการล้นเกิน",
        automaticWeightCalculation: "การบัญชีน้ำหนักอัตโนมัติ",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "ฟิลด์" },
    checkRange: {
      numberTipOne: "ลำดับที่",
      numberTipTwo: "ประโยค",
      numberTipThree: "ประโยคแรก",
      conditionLimit: "ข้อจำกัดเงื่อนไข",
      roleLimit: "จำกัดบทบาท",
      contextLimit: "ข้อจำกัดประโยค",
      notZero: "ข้อจำกัดประโยคไม่รองรับการป้อนข้อมูล0",
      startMustLessThanEnd:
        "ตำแหน่งของประโยคเริ่มต้นต้องน้อยกว่าตำแหน่งของประโยคจบ",
    },
    waveAudio: {
      play: "เล่น",
      pause: "หยุดชั่วคราว",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "กรุณาใส่",
      pleaseEnterRegular: "กรุณาป้อนนิพจน์ทั่วไป",
      pleaseEnterGrammar: "กรุณากรอกกฎไวยากรณ์",
      pleaseEnterTestText: "โปรดป้อนเนื้อหาที่ต้องตรวจสอบ",
      pleaseEnterLoudness: "กรุณาใส่ความดังเดซิเบล",
      pleaseEnterRangeAbility: "โปรดป้อนช่วงการเปลี่ยนแปลง",
      afterHit: "ตรวจสอบหลังจากตี",
      pleaseEnterContext: "โปรดป้อนคำสั่งอ้างอิง",
      wordNumber: "ช่องว่างของจำนวนคำ",
      intervalSecond: "ช่วงเวลามากกว่า",
      minute: "ต่อนาที",
      wordUnit: "คำ",
      test: "การทดสอบ",
      testRegular: "ทดสอบนิพจน์ทั่วไป",
      testGrammar: "ทดสอบไวยากรณ์",
      testGrammarError: "การทดสอบไวยากรณ์ล้มเหลว",
      regularError: "ข้อผิดพลาดในนิพจน์ทั่วไปโปรดป้อนใหม่",
      pleaseEnterSemanticSentence:
        "โปรดป้อนประโยคที่ต้องการสำหรับการตรวจจับความหมาย",
      pleaseEnterAssociate:
        "โปรดดูคำอธิบายด้านขวาเพื่อสร้างนิพจน์ข้อมูลที่มาพร้อมกับถนน",
      querySemanticTags: "เลือกแท็บความหมาย",
      reQuerySemanticTags: "เลือกแท็บความหมายอีกครั้ง",
      semanticTags: "แท็กความหมาย",
      standardSentence: "ประโยคมาตรฐาน",
      semanticName: "แท็กความหมาย",
      sentence: "ประโยคมาตรฐาน",
      similarity: "ความคล้ายคลึงกัน",
      actions: "การดำเนินงาน",
      tipOne: "ไม่พบฉลากที่เหมาะสม",
      tipTwo: "เพิ่มแท็บความหมายใหม่",
      notLessThan: "ไม่น้อยกว่า",
      notGreaterThan: "ไม่เกิน",
      ignoreLessThan: "ละเว้นน้อยกว่า",
      wordUnitSet: "ประโยคคำ",
      speedWordNotNull:
        "การตรวจจับความเร็วในการพูดจำนวนคำขั้นต่ำและจำนวนคำสูงสุดต้องไม่ว่างเปล่า",
      speedWordMinValueLessThanMaxValue:
        "จำนวนคำขั้นต่ำในการตรวจจับความเร็วในการพูดต้องไม่เกินจำนวนคำสูงสุด",
      speechGrabbingIntervalTip: "ช่วงเวลาการสนทนา> =",
      speechLengthTip: "คว้าประโยคยาว> =",
      msUnit: "ms",
      sUnit: "วินาที",
      grabIntervalNotNull: "ช่วงเวลาแห่งการคว้าไม่สามารถว่างเปล่าได้",
      grabLengthNotNull: "ความยาวของประโยคไม่สามารถว่างเปล่าได้",
      pleaseSelectEntity: "โปรดเลือกเอนทิตีข้อมูล",
      pleaseSelectOperator: "โปรดเลือกการดำเนินการ",
      pleaseSelectBasicInfo: "โปรดเลือกข้อมูลพื้นฐาน",
      pleaseEnterFixedValue: "กรุณาใส่ค่าคงที่",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "จับคู่สำเร็จ",
      matchError: "การจับคู่ล้มเหลว",
      mindetectionStatement: "คำสั่งการตรวจสอบขั้นต่ำ",
      conceptWordholder: "โปรดเลือกคำแนวคิด",
      elementHolder: "กรุณาใส่องค์ประกอบ",
      associateMedol: { tabs: { data: "ข้อมูล", operator: "ตัวดำเนินการ" } },
      repet: "ซ้ำ",
      bout: "ครั้ง",
      ignore: "ละเว้นน้อยกว่า",
      ignoreAffix: "ประโยคคำ",
      associateData: "ข้อมูลถนน",
      pretreatmentData: "ข้อมูลก่อนการประมวลผล",
      reviewTaskData: "ตรวจสอบข้อมูลงาน",
      taskData: "ข้อมูลงานตรวจสอบคุณภาพ",
      businessData: "บันทึกธุรกิจ",
      functionOperator: "ตัวดำเนินการฟังก์ชัน",
      operation: "ตัวดำเนินการ",
      notAnyMatch: "เมื่อไม่ตีปัญหาใดๆ:",
      times: "จำนวนครั้งที่ปรากฏ",
      newAssociateDataTip: "ใช้ฟังก์ชันเวอร์ชันเก่าหากค่าเป็นประเภทตัวเลข",
    },
    conditionGather: {
      cycle: "วงจร",
      rlue: "กฎ",
      delete: "เอาออก",
      sampling: "สกัด",
      item: "แถบ",
      percent: "%",
      samplingSame: "จำนวนการจับฉลากต่อฝ่ายบริการลูกค้าเท่ากัน",
      samplingRecheck: "อนุญาตให้ทำการสุ่มตัวอย่างรายการซ้ำ",
      dateUnit: "จำนวน",
      samplingTip:
        "กฎที่แตกต่างกันครอบคลุมการโทรเดียวกันจะไม่ถูกดึงซ้ำและบันทึกที่ถูกดึงออกมาแต่ไม่ได้ตรวจสอบจะไม่ถูกดึงออกมาอีก",
      add: "เพิ่มกฎ",
    },
    ruleGather: {
      title: "กฎ",
      screeningCondition: "เงื่อนไขการคัดกรอง",
      addRule: "เพิ่มกฎ",
      inspector: "ผู้ตรวจสอบคุณภาพ",
      ruleTip:
        "โหมดการจัดสรรลำดับความสำคัญของกฎจะถูกจัดสรรจากบนลงล่างโดยไม่มีการแจกแจงซ้ำและกฎหรือรายการกฎใดๆที่ไม่ตรงกับกฎจะถูกจัดสรรแบบสุ่ม",
      moveUp: "เลื่อนขึ้น",
      moveDown: "เลื่อนลง",
      top: "ติดด้านบน",
    },
    pushRulesGather: {
      title: "กฎ",
      delete: "เอาออก",
      newPush: "เพิ่มการผลักดัน",
      newRule: "เพิ่มกฎ",
      sampling: "สกัด",
      item: "แถบ",
      percent: "%",
      samplingSame: "จำนวนการจับฉลากต่อฝ่ายบริการลูกค้าเท่ากัน",
      dateUnit: "จำนวน",
      screeningCondition: "เงื่อนไขการคัดกรอง",
      email: "กล่องจดหมาย",
      emailPlaceholder: "ป้อนกล่องจดหมายและแบ่งด้วยเครื่องหมายจุลภาค",
      updateType: "ประเภทการปรับปรุง",
      pushDetail: "ผลักดันรายละเอียด",
      pushUrl: "ผลักดัน URL",
      pushWay: "ประเภทผลักดัน",
      pushMethod: "วิธีการผลักดัน",
      pushContentType: "รูปแบบข้อมูล",
      pushData: "ผลักดันข้อมูล",
      additionalData: "ข้อมูลเพิ่มเติม",
      inspectionType: "ประเภทการตรวจสอบคุณภาพ",
      all: "ทั้งหมด",
    },
    taskAdditionalData: {
      newData: "เพิ่มข้อมูล",
      delete: "เอาออก",
      placeholder: "กรุณาใส่ชื่อ",
      dataPlaceholder: "กรุณาใส่เนื้อหา",
    },
    UdeskQaReact: {
      loadSdkError: "การโหลด SDK ล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
    },
    gearOptionList: { evaluation: "การประเมินผล" },
    tableComponentTransfer: {
      leftTitle: "รายการรายการ",
      rightTitle: "แสดงรายการ",
    },
    kmTinymce: {
      link: "แทรก/แก้ไขการเชื่อมโยง",
      modalTitle: "แทรกลิงก์",
      href: "ที่อยู่",
      text: "แสดงข้อความ",
      title: "ชื่อเรื่อง",
      target: "วิธีการเปิด",
      none: "ไม่มี",
      newWindow: "เปิดในหน้าต่างใหม่",
      buttonStyle: "สไตล์ปุ่ม",
      close: "ปิด",
      open: "เปิด",
      answerMessage: "กระโดด/ส่งข้อความ",
      answerMessageTitle: "ปุ่มส่งข้อความ",
      answerMessageText: "แสดงข้อความ",
      answerMessageContent: "เนื้อหาข้อความ",
      answerMessageButtonStyle: "สไตล์ปุ่ม",
      answerMessageTips:
        "หมายเหตุ: ปุ่มส่งข้อความสนับสนุนเฉพาะช่องทางเว็บโปรดกำหนดค่าด้วยความระมัดระวัง",
      answerSwitchStaff: "กระโดด/เลี้ยวด้วยตนเอง",
      answerSwitchStaffTitle: "เปลี่ยนปุ่มด้วยตนเอง",
      answerSwitchStaffText: "แสดงข้อความ",
      answerSwitchStaffRule: "กฎของปุ่ม",
      answerSwitchStaffButtonStyle: "สไตล์ปุ่ม",
      answerSwitchStaffTips:
        "หมายเหตุ: ปุ่มถ่ายโอนด้วยตนเองรองรับเฉพาะช่องเว็บโปรดกำหนดค่าด้วยความระมัดระวัง",
      textButton: "ปุ่มข้อความ",
      borderButton: "ปุ่มที่มีกรอบ",
      message: "ส่งข้อความ",
      switchStaff: "โอนแรงงาน",
      messageError: "เนื้อหาของปุ่มต้องไม่เกิน10คำ",
      messageIsEmptyError: "เนื้อหาของปุ่มต้องไม่ว่างเปล่า",
      okText: "กำหนด",
      cancelText: "การยกเลิก",
      video: "วิดีโอ",
      uploadVideo: "อัปโหลดวิดีโอ",
      uploadText: "รองรับเฉพาะรูปแบบ MP4ขนาดไม่เกิน20M",
      videoEmptyOrUploading: "วิดีโอว่างเปล่าหรือกำลังอัปโหลด",
      customEmoji: "อีโมติคอน",
    },
    massageConfiguration: {
      pushTarget: "เป้าหมายการแจ้งเตือน",
      customizePushTarget: "เป้าหมายผลักดันที่กำหนดเอง",
      sysUser: "ผู้ใช้ระบบปัจจุบัน",
      sysRole: "บทบาทของระบบปัจจุบัน",
      emailPushTarget: "ส่งเป้าหมาย",
      targetEmailsPlaceholder:
        "กรุณาใส่กล่องจดหมายและคั่นด้วยเครื่องหมายจุลภาคภาษาอังกฤษ",
      subject: "ชื่อข้อความ",
      SDKID: "รหัสประจำตัว SDK",
      content: "เนื้อหาการแจ้งเตือน",
      sdkPushType: "แผนผลักดัน",
      sdkPushURL: "ผลักดัน URL",
      pushData: "ผลักดันข้อมูล",
      sdkPushWay: "วิธีการผลักดัน",
      dataType: "รูปแบบข้อมูล",
      additionalData: "ข้อมูลเพิ่มเติม",
      name: "ชื่อข้อความ",
      filter: "เงื่อนไขการคัดกรอง",
      triggerType: "กลไกทริกเกอร์",
      pushCycle: "เวลาความถี่คงที่",
      pushContent: "เนื้อหารายงาน",
      qualityList: "รายการผลการตรวจสอบคุณภาพ",
      designated: "กำหนดบุคลากร",
      messageType: "ประเภทข้อความ",
    },
    taskCard: {
      template: {
        cancelCollection: "ยกเลิกรายการโปรด",
        collection: "คอลเลกชัน",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "ประโยคมาตรฐาน:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "คอลเลกชัน",
          calls: "โทร",
          createdBy: "สร้างโดย:",
          createdOn: "สร้างเวลา:",
        },
        fileItem: {
          uIProvidesIcon: "Ui ให้ไอคอน",
          minute: "นาที",
          callClip: "คลิปการโทร",
          start: "เริ่มต้น",
          End: "สิ้นสุด",
          employees: "พนักงาน:",
          Customer: "ลูกค้า:",
          sessionTime: "เวลาสนทนา:",
          reasonForRecommendation: "เหตุผลที่แนะนำ:",
          addedBy: "เพิ่มโดย:",
          addTime: "เพิ่มเวลา:",
        },
        modalAddFile: {
          caseTitle: "ชื่อกรณี",
          addSomeClipsToTheCaseLibrary: "เพิ่มส่วนของคลิปลงในไลบรารีเคส",
          addToCaseBase: "เพิ่มลงในไลบรารีกรณี",
          storedIn: "เก็บไว้ใน",
          pleaseSelect: "โปรดเลือก",
          reasonsForRecommendation: "เหตุผลในการแนะนำ",
          pleaseEnter: "กรุณาใส่",
        },
      },
      shareModal: {
        index: {
          customer: "ลูกค้า:",
          employees: "พนักงาน:",
          timeliness: "ตรงเวลา:",
          recordingLinkValidWithinDays: "ลิงก์บันทึกใช้ได้ภายในไม่กี่วัน",
          sharingInstructions: "แบ่งปันคำแนะนำ:",
          shareSuccess: "แบ่งปันความสำเร็จ!",
          cancel: "การยกเลิก",
          share: "แชร์",
          copySucceeded: "คัดลอกสำเร็จ!",
          copyShareLink: "คัดลอกลิงก์แชร์",
          shareWithColleagues: "แบ่งปันกับเพื่อนร่วมงาน",
          shareWithCustomers: "แบ่งปันให้กับลูกค้า",
          staff: "พนักงาน",
          selectColleagues: "เลือกเพื่อนร่วมงาน",
          pleaseSelectColleaguesToShare:
            "โปรดเลือกเพื่อนร่วมงานที่คุณต้องการแบ่งปัน",
          pleaseEnterTheSharingDescription: "โปรดป้อนคำแนะนำในการแบ่งปัน",
          taskName: "ภารกิจ:",
          recordingShareValidWithinDays: "ลิงก์แชร์ใช้ได้ภายในไม่กี่วัน",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "ไม่พบไฟล์!",
          sale: "ขาย:",
          customer: "ลูกค้า:",
          topicDetection: "การตรวจสอบหัวข้อ:",
          keyEvents: "เหตุการณ์สำคัญ:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "เลือกเพื่อนร่วมงาน",
          helpContent: "เนื้อหาความช่วยเหลือ",
          visibleRange: "ช่วงที่มองเห็นได้",
          seekCounseling: "ขอคำปรึกษา",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "ขอคำแนะนำ", concatText: "ต่อ" },
      },
      customerInsight: {
        index: {
          customerInformation: "ข้อมูลลูกค้า",
          customerInsight: "ข้อมูลเชิงลึกของลูกค้า",
          customerLabel: "แท็กของลูกค้า",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "คำแนะนำ",
          objectionHandlingScripts: "การจัดการการคัดค้าน",
          productSellingPointScripts: "จุดขายสินค้า",
          knowledgePointRecommendation: "คำแนะนำความรู้",
          relatedKnowledgePoints: "ประเด็นความรู้ที่เกี่ยวข้อง",
          recommendedCases: "กรณีแนะนำ",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "คำแนะนำ",
          caseRecommendation: "คำแนะนำกรณี",
          hit: "ตี",
          miss: "พลาด",
          supervisionItem: "รายการกำกับดูแล",
          implementation: "สถานการณ์การดำเนินการ",
          reInspection: "ตรวจสอบคุณภาพใหม่",
          supervisionItemSettings: "การตั้งค่ารายการกำกับดูแล",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "การโทรทางโทรศัพท์",
          enterpriseWeChatCall: "การโทรไมโคร",
          enterpriseMicroConversation: "เซสชันไมโครขององค์กร",
          open: "เปิด",
          close: "ปิด",
          conversationScenario: "ฉากสนทนา",
          monitorConversationChannels: "ตรวจสอบช่องทางการสนทนา",
          permanentlyValid: "ถาวรและมีประสิทธิภาพ",
          permanentlyInvalid: "ไม่ถูกต้องถาวร",
          customValidity: "ระยะเวลาที่กำหนดเอง",
          termOfValidity: "ระยะเวลาที่ใช้ได้",
          newRating: "การจัดอันดับใหม่",
          whenConversation: "เมื่อเซสชัน",
          hit: "ตี",
          miss: "พลาด",
          time: "เมื่อ",
          rated: "ได้รับการจัดอันดับเป็น",
          qualified: "มีคุณสมบัติเหมาะสม",
          unqualified: "ไม่มีเงื่อนไข",
          modifiedSuccessfully: "แก้ไขสำเร็จ",
          classificationName: "ชื่อหมวดหมู่",
          supervisionObject: "วัตถุกำกับดูแล",
          entryintoforceTime: "เวลาที่มีผลบังคับใช้",
          supervisionItemRating: "การจัดอันดับรายการกำกับดูแล",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "โปรดตรวจสอบว่าการจัดอันดับรายการกำกับดูแลถูกต้องหรือไม่",
          ratingRules: "กฎการให้คะแนน",
          settingOfRatingRulesForSupervisionItems:
            "การตั้งค่ากฎการจัดอันดับรายการกำกับดูแล",
        },
      },
      reInspect: {
        index: {
          keyEvents: "เหตุการณ์สำคัญ",
          supervisionItem: "รายการกำกับดูแล",
          conversationScenario: "ฉากสนทนา",
          customerLabel: "แท็กของลูกค้า",
          reDetectionRules: "ตรวจสอบกฎอีกครั้ง",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "ดาวน์โหลดการบันทึก",
          downloadText: "ดาวน์โหลดข้อความ",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "อัปโหลดรูปภาพ",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "กรุณาเลือกภาพที่มีขนาดไม่เกิน2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "หรือ",
            and: "และ",
            wrong: "ไม่ใช่",
            matchTo: "จับคู่กับ",
            rule: "กฎ",
            satisfactionOperator: "พบกับตัวดำเนินการ",
            customerLabel: "แท็กของลูกค้า",
            keyEvents: "เหตุการณ์สำคัญ",
            knowledgePoints: "ประเด็นความรู้",
            sessionLabel: "แท็กเซสชัน",
            supervisionItem: "รายการกำกับดูแล",
          },
          linkExplorer: { linkInTheText: "ลิงค์ในบทความ:" },
        },
      },
    },
    createForm: {
      field: { customComponentNotFound: "ไม่พบส่วนประกอบที่กำหนดเอง:" },
    },
    sdkLoaderBase: {
      component: { custom: "ที่กำหนดเอง" },
      dropdownMonit: {
        updateSucceeded: "อัปเดตเรียบร้อยแล้ว",
        areYouSureToDelete: "แน่ใจว่าจะลบ?",
        custom: "ที่กำหนดเอง",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "บันทึกการกำหนดค่ารายงานปัจจุบัน === แบบฟอร์ม",
        saveCurrentReportConfiguration: "บันทึกการกำหนดค่ารายงานปัจจุบัน",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "โปรดป้อนชื่อการตั้งค่าทั่วไปความยาวสูงสุดคือ20!",
        preservation: "บันทึก",
      },
      template: {
        saveCurrentReportConfiguration: "บันทึกการกำหนดค่ารายงานปัจจุบัน",
        updateCurrentReportConfiguration: "อัปเดตการกำหนดค่ารายงานปัจจุบัน",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "ข้อมูลใบสั่งงาน",
        workOrder: "ใบสั่งงาน",
        triggerScenario: "ฉากทริกเกอร์",
        associatedCallConversation: "การเชื่อมโยงการโทร/การสนทนา",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "ทดสอบด้วยข้อความปัจจุบัน/บทสนทนา",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "ลำดับที่", day: "วัน" },
      businessSummary: {
        normal: {
          equalTo: "เท่ากับ",
          notEqualTo: "ไม่เท่ากับ",
          contain: "ประกอบด้วย",
          excluding: "ไม่รวม",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "ฉันคือฉันดอกไม้ไฟที่แตกต่าง @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "สวัสดีฉันเป็นประกาศเตือนการตรวจสอบคุณภาพหุ่นยนต์เนื่องจากเพิ่มในกลุ่มในวันที่17ตุลาคม",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "ฉันคือฉันดอกไม้ไฟที่แตกต่าง @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "สวัสดีฉันเป็นประกาศเตือนการตรวจสอบคุณภาพหุ่นยนต์เนื่องจากเพิ่มในกลุ่มในวันที่17ตุลาคม",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "หัวข้อการสื่อสาร",
          conversationSummary: "บทสรุปของวาระงาน",
          serviceSuggestions: "คำแนะนำบริการ",
        },
        template: { smartTags: "สมาร์ทแท็ก" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "คลิกเพื่อทราบข้อมูลเพิ่มเติม" },
      schema: {
        textRobot: "หุ่นยนต์ข้อความ",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "การคุ้มกันของผู้ฝึกสอนคำถามและคำตอบเกี่ยวกับเอกสารบทสนทนาตามภารกิจคำถามและคำตอบเกี่ยวกับแบบฟอร์มคำถามและคำตอบเกี่ยวกับกราฟ",
        capitalRetentionRobot: "หุ่นยนต์รักษาทุน",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "กลยุทธ์การเก็บรักษาคำถามที่หลากหลายการสื่อสารด้วยมนุษย์ในระดับสูงรับประกันอัตราการเก็บรักษาที่สูงและการแปลงเบาะแสโดยอัตโนมัติอย่างมีประสิทธิภาพ",
        outboundRobot: "หุ่นยนต์โทรออก",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "การกลับมาเยี่ยมหลังจากเสร็จสิ้นใบสั่งงานการสำรวจความพึงพอใจในการบริการการแจ้งเตือนความล้มเหลวในการดำเนินการและการบำรุงรักษาการแจ้งเตือนการจัดส่งคำสั่งซื้อการแจ้งเตือนกิจกรรมอีคอมเมิร์ซการนัดหมายผู้สมัครช่วยให้ธุรกิจอัตโนมัติและปรับปรุงประสิทธิภาพทางธุรกิจ",
        incomingRobot: "หุ่นยนต์โทรเข้า",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "รวมใบสั่งงานคำสั่งซื้อและระบบธุรกิจอื่นๆอย่างยืดหยุ่นเพื่อให้เกิดกระบวนการอัตโนมัติการระบุแบบจำลองความหมายเสียงที่กำหนดเองและผลกระทบทางธุรกิจจะเกิดขึ้นทันทีเอนทิตี (หมายเลขโทรศัพท์มือถือจำนวนคำสั่งซื้อเวลาสั่งซื้อที่อยู่...) ระบุการสนับสนุนอย่างรวดเร็วสำหรับธุรกิจที่ซับซ้อน",
        intelligentQualityInspection: "การตรวจสอบคุณภาพอัจฉริยะ",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "การตรวจสอบคุณภาพเต็มรูปแบบทันเวลาและมีประสิทธิภาพอัตราความแม่นยำ85%",
        intelligentSessionAnalysis: "การวิเคราะห์เซสชันอัจฉริยะ",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "รับข้อมูลเชิงลึกเกี่ยวกับข้อบกพร่องทางธุรกิจรับฟังเสียงของลูกค้าและปรับปรุงการเปลี่ยนแปลงทางการตลาด",
        enterpriseWechatConversationAnalysis:
          "การวิเคราะห์เซสชัน WeChat ขององค์กร",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "ระบุโอกาสทางธุรกิจและความเสี่ยงที่อาจเกิดขึ้นได้อย่างมีประสิทธิภาพและเพิ่มอัตรา Conversion ทางการตลาด20%",
        assistant: "ผู้ช่วยที่นั่ง",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "ผู้ช่วยตัวแทนสามารถมั่นใจได้ว่าบริการลูกค้ามือใหม่จะเสร็จสิ้นและปรับปรุงคุณภาพของการโทรบริการลูกค้าที่มีทักษะให้ทุกที่นั่งเป็นไม้เด็ดของคุณ",
        digitalPeople: "คนดิจิทัล",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          'การสื่อสารแบบมนุษย์แบบ "ตัวต่อตัว" รองรับการโต้ตอบแบบหลายโหมดของเสียงข้อความวิดีโอและการสัมผัสเพื่อให้ได้ประสบการณ์การสื่อสารที่สมจริงและเพิ่มภาพลักษณ์ของแบรนด์และประสบการณ์การบริการผู้ใช้',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "ความรู้ AI Zhongtai · ค้นหาองค์กร",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "การค้นหาความรู้การแบ่งปันความรู้การค้นหาเอกสารการค้นหาการเชื่อมต่อฐานข้อมูล",
        aIKnowledgeCenterKCsKnowledgeBase: "ความรู้ด้าน AI · ฐานความรู้ KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "ให้การจัดการวงจรชีวิตของความรู้การบำรุงรักษาหุ่นยนต์และฐานความรู้ IM แบบครบวงจรรองรับการเชื่อมต่อกับฐานความรู้ของบุคคลที่สามและฟังก์ชันที่มีประสิทธิภาพเช่นเทมเพลตความรู้และความรู้เกี่ยวกับอะตอมเพื่อมอบประสบการณ์การจัดการความรู้ที่สะดวกยิ่งขึ้น",
        aIKnowledgeCenterKnowledgeMap: "สถานีความรู้ AI · แผนที่ความรู้",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "การสร้างแผนที่แบบครบวงจรและการบูรณาการความรู้ขององค์กรรูปภาพขนาดใหญ่แสดงรายละเอียดความรู้และความสามารถในการเชื่อมต่อเพื่อสร้างประสบการณ์ที่กำหนดเอง",
        modularDevelopmentPlatformOriginalEngineASR:
          "แพลตฟอร์มการพัฒนาโมดูลาร์ · เครื่องยนต์เดิม · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "ใช้อัลกอริทึมแบบ end-to-end เพื่อให้ได้ประสบการณ์เอฟเฟกต์ที่กำหนดเองทางธุรกิจอัตราการรับรู้ฉากธรรมชาติเกิน90% และการจดจำคำศัพท์ส่วนบุคคลเกิน97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "แพลตฟอร์มการพัฒนาแบบแยกส่วน · การตลาดอัจฉริยะ",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "ใช้อัลกอริธึมการเรียนรู้เชิงลึกรวมกับภาพผู้ใช้เพื่อให้ได้คำแนะนำที่ถูกต้องของผลิตภัณฑ์และปรับปรุงการแปลงการขาย",
        modularDevelopmentPlatformUserPortrait:
          "แพลตฟอร์มการพัฒนาแบบแยกส่วน · ภาพผู้ใช้",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "สร้างภาพผู้ใช้อย่างถูกต้องตามข้อมูลผู้ใช้และข้อมูลการสนทนาและอธิบายลักษณะของลูกค้าอย่างครบถ้วน",
        modularDevelopmentPlatformIntelligentRecommendation:
          "แพลตฟอร์มการพัฒนาแบบแยกส่วน · คำแนะนำอัจฉริยะ",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "ตามภาพผู้ใช้และภาพผลิตภัณฑ์แนะนำผลิตภัณฑ์ที่อาจสนใจให้กับผู้ใช้โดยอัตโนมัติและตระหนักถึงคำแนะนำส่วนบุคคลของผู้คนหลายพันคน",
        modularDevelopmentPlatformNLPPAAS:
          "แพลตฟอร์มการพัฒนาโมดูลาร์ · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "ให้ความสามารถพื้นฐาน NLP ที่มีความแม่นยำสูงครอบคลุมความสามารถพื้นฐานที่มีความละเอียดที่แตกต่างกันเช่นคำประโยคบทความฯลฯ",
      },
    },
    qualityScore: {
      index: {
        show: "แสดง:",
        displayBySecondaryClassification: "แสดงตามประเภทรอง",
        showByRule: "แสดงตามกฎ",
        pleaseEnterClassificationruleName: "กรุณากรอกชื่อหมวดหมู่/กฎ",
        clickCopy: "คลิกคัดลอก",
        hitDetailsOfOperatorsInRules: "รายละเอียดการตีตัวดำเนินการภายในกฎ:",
        hit: "ตี",
        miss: "พลาด",
        filter: "กรอง:",
        clickToViewDetails: "คลิกเพื่อดูรายละเอียด",
      },
      components: {
        flowNodeList: {
          branch: "แบ่ง",
          pleaseSelect: "โปรดเลือก",
          viewTheCompleteProcess: "ดูกระบวนการทั้งหมด",
          thereIsCurrentlyNoProcessAvailable: "ไม่มีกระบวนการ",
        },
        pointItem: { xiangHit: "รายการฮิต" },
        flowNodeListModal: {
          index: { completeProcess: "กระบวนการที่สมบูรณ์" },
        },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "หากงานตรวจสอบคุณภาพของกระบวนการมีการเปลี่ยนแปลงโปรดอุทธรณ์อีกครั้ง",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "ข้อมูลที่ได้รับการล้างอย่างสม่ำเสมอ",
        subject: "หัวข้อ:",
        description: "คำอธิบาย:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "เพิ่มลงในงานตรวจสอบคุณภาพ" },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "การเชื่อมโยงการโทร/การสนทนา" },
    },
    samplingTarget: {
      index: {
        time: "เมื่อ",
        samplingQuantityIsLessThan: "จำนวนการสุ่มตัวอย่างต่ำกว่า",
        alertWhen: "เตือนเวลา",
        everyday: "ทุกวัน",
        weekly: "ทุกสัปดาห์",
        monthly: "รายเดือน",
        samplingInspectionTargetSavedSuccessfully:
          "บันทึกเป้าหมายการสุ่มตัวอย่างเรียบร้อยแล้ว",
        personnel: "บุคลากร",
        eachCustomerService: "บริการลูกค้าแต่ละราย",
        cycle: "วงจร",
        samplingInspectionTarget: "เป้าหมายการสุ่มตัวอย่าง",
        strip: "แถบ",
        alertSettings: "การตั้งค่าการเตือนภัยล่วงหน้า",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "บริการลูกค้า",
        complete: "เสร็จแล้ว",
        Objective: "/เป้าหมาย",
        everyday: "ทุกวัน",
        weekly: "ทุกสัปดาห์",
        monthly: "รายเดือน",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "อัตราการโต้ตอบการสนทนากลุ่ม",
        maximumResponseIntervalTime: "ช่วงเวลาการตอบสนองที่ยาวที่สุด",
        numberOfPreemptiveCalls: "จำนวนการโทร",
        numberOfMessages: "จำนวนข้อความ",
        strip: "แถบ",
        proportionOfMessages: "สัดส่วนของข้อความ",
        speechPercentage: "สัดส่วนการพูด",
        longestCustomerTalk: "คำพูดของลูกค้าที่ยาวที่สุด",
        numberOfIndepthDiscussions: "จำนวนการอภิปรายเชิงลึก",
        second: "ครั้ง",
        maximumDurationOfContinuousSpeech: "ระยะเวลาการพูดต่อเนื่องสูงสุด",
        questionFrequency: "ความถี่ของคำถาม",
        customer: "ลูกค้า",
        keyEvents: "เหตุการณ์สำคัญ:",
        opportunity: {
          index: {
            generationFailed: "ความล้มเหลวในการสร้าง",
            generateOpportunityPoints: "สร้างจุดโอกาส",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "ความล้มเหลวในการสร้าง",
            generateSummary: "สร้างสรุป",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "แน่ใจว่าจะลบ?",
        add: "เพิ่ม",
        opportunityPoints: "จุดโอกาส",
        conversationSummary: "บทสรุปของวาระงาน",
        cancel: "การยกเลิก",
        determine: "กำหนด",
        edit: "บรรณาธิการ",
        establish: "สร้าง",
        conversationalInsights: "ข้อมูลเชิงลึกการสนทนา",
        keyEvents: "เหตุการณ์สำคัญ",
        numKeyEvents: "เหตุการณ์สำคัญ",
        questionAnalysis: "การวิเคราะห์คำถาม",
        find: "การค้นพบ",
        questions: "คำถาม",
        conversationAction: "การดำเนินการเซสชัน",
        customer: "ลูกค้า",
        sale: "การขาย",
        sessionLabel: "ฉากสนทนา",
        employeeQuestions: "คำถามพนักงาน",
        customerQuestions: "คำถามของลูกค้า",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "ลูกค้า",
        customerService: "บริการลูกค้า",
        replyTo: "ตอบกลับไปที่",
        addressee: "ผู้รับ:",
      },
    },
    listManage: {
      template: {
        reIdentification: "การระบุตัวตนใหม่",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "การระบุซ้ำจะใช้เวลาในการถอดเสียงโปรดใช้ความระมัดระวัง",
        determine: "กำหนด",
        executionSuccessful: "ดำเนินการได้สำเร็จ",
        addToQualityInspectionTask: "เพิ่มลงในงานตรวจสอบคุณภาพ",
        batchOperation: "การดำเนินการแบทช์",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "เพิ่มความสำเร็จ",
        addToQualityInspectionTask: "เพิ่มลงในงานตรวจสอบคุณภาพ",
        qualityInspectionTask: "งานตรวจสอบคุณภาพ",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "ตรวจจับบทบาท",
        detectionRange: "ช่วงการตรวจสอบ",
        sentence: "ประโยค",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "ข้อมูลที่ได้รับการล้างอย่างสม่ำเสมอ",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "กลับไปที่ระบบธุรกิจ" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "การสกัดคำถาม",
        businessSummary: "สรุปธุรกิจ",
        keyWord: "คำสำคัญ",
      },
      components: {
        problemExtraction: {
          no: "ไม่ใช่",
          customerServiceDidNotAnswer: "ฝ่ายบริการลูกค้าไม่ตอบ",
          customerServiceHasAnswered: "ฝ่ายบริการลูกค้าตอบแล้ว",
          problemUnresolved: "ปัญหาไม่ได้รับการแก้ไข",
          theProblemHasBeenResolved: "ปัญหาได้รับการแก้ไขแล้ว",
          customerIssue: "ปัญหาของลูกค้า:",
          customerServiceAnswer: "คำตอบการบริการลูกค้า:",
          reExtractQuestions: "ดึงปัญหาอีกครั้ง",
          clickToExtractQuestions: "คลิกเพื่อแยกคำถาม",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "ชื่อภาพ",
            backgroundDescription: "คำอธิบายพื้นหลัง",
            customerLabel: "แท็กของลูกค้า",
            labelManagement: "การจัดการฉลาก",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "สร้างใหม่" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "ติดตามการตั้งค่า" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "เพิ่มการติดตาม",
              remove: "เอาออก",
              addQuestioning: "เพิ่มการติดตาม",
              section: "ลำดับที่",
              secondInquiry: "คำถามที่สอง",
              questioningScore: "ถามคะแนน",
            },
          },
        },
        index: {
          accordingToRules: "ตามกฎ",
          byScore: "คะแนนตาม",
          whenMissRule: "กฎเมื่อพลาด",
          whenConductingQuestioning: "เมื่อถาม",
          pleaseSelectARule: "โปรดเลือกกฎ",
          ruleCannotBeEmpty: "กฎไม่สามารถว่างเปล่า",
          currentScriptScore: "เมื่อคะแนนทักษะการพูด ≤",
          pleaseEnterTheScore: "กรุณาใส่คะแนน",
          scoreCannotBeEmpty: "คะแนนไม่สามารถว่างเปล่า",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "ผู้บรรยาย:" },
          pPT: { slide: "สไลด์โชว์" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "ความคิดเห็นเกี่ยวกับทักษะการพูด",
              script: "ทักษะการพูด",
              semantics: "ความหมาย",
              violatingWords: "การละเมิด",
              speechSpeed: "ความเร็วในการพูด",
              emotion: "อารมณ์",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "ความคิดเห็นเกี่ยวกับทักษะการพูด",
              regenerate: "สร้างใหม่",
              generate: "สร้าง",
              hitSituation: "สถานการณ์ตี",
              noSemanticTagsMatched: "ไม่ตรงกับแท็กความหมาย",
              matchToSemanticTags: "ตรงกับแท็บความหมาย",
              appealToBeSubmitted: "การอุทธรณ์ที่จะส่ง",
              ratingHasBeenReviewed: "ตรวจสอบคะแนนแล้ว",
              ratingAppealInProgress: "การอุทธรณ์การให้คะแนน",
              expressionScore: "คะแนนการแสดงออก",
              moodParticlesTimes: "โมดอล, {0} ครั้ง",
              changeTheModalParticlesTo: "เปลี่ยนคำก๊าซเป็น",
              second: "ครั้ง",
              interrogation: "ถาม",
              questioningWithoutScoring: "ถามไม่ให้คะแนน",
              branch: "แบ่ง",
              matchingRate: "อัตราการจับคู่",
              ratingAppeal: "การให้คะแนนการอุทธรณ์",
              changeTo: "เปลี่ยนเป็น",
              wordPerMinute: "คำต่อนาที",
              completeScript: "คำพูดที่สมบูรณ์",
              incompleteScript: "คำพูดไม่สมบูรณ์",
              semanticHit: "การตีความหมาย",
              semanticMisses: "ความหมายพลาด",
              scriptViolation: "การละเมิดคำพูด",
              modified: "แก้ไขแล้ว",
              notChanged: "ไม่มีการแก้ไข",
              keyWord: "คำสำคัญ",
              semanticLabels: "แท็กความหมาย",
              semanticMatchingRate: "อัตราการจับคู่ความหมาย",
              violatingWords: 'การละเมิดคำ"',
              delete: "ลบ",
              unmatched: "ไม่ตรงกัน",
              matching: "การจับคู่",
              matchTo: "จับคู่กับ",
              notMatchedTo: "ไม่ตรงกัน",
              scriptScore: "คะแนนการพูด",
              standardScript: "ทักษะการพูดมาตรฐาน",
              emotionalScore: "คะแนนอารมณ์",
              speechSpeedScore: "คะแนนความเร็วในการพูด",
              viewResolution: "ดูการวิเคราะห์",
              nodeConfiguration: "การกำหนดค่าโหนด",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "เวลาในการตอบคำถามนี้หมดเวลาและเนื้อหาของคำตอบจะถูกส่งโดยอัตโนมัติ",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "เวลาในการตอบคำถามนี้หมดเวลาและเนื้อหาของคำตอบจะถูกส่งโดยอัตโนมัติ",
          },
          correctBtn: {
            saveSuccessful: "บันทึกความสำเร็จ",
            errorCorrection: "แก้ไขข้อผิดพลาด",
            cancel: "การยกเลิก",
            preserve: "บันทึก",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "กฎการให้คะแนน",
            cancel: "การยกเลิก",
            determine: "กำหนด",
          },
          scoringRuleItem: {
            whole: "ทั้งหมด",
            arbitrarily: "ใดๆ",
            pleaseEnterARuleName: "โปรดป้อนชื่อกฎ",
            standardScript: "ทักษะการพูดมาตรฐาน",
            if: "ถ้า",
            and: "และ",
            or: "หรือ",
            thenScore: "คะแนน",
            pleaseEnterAScore: "กรุณากรอกคะแนน",
            bonusPoints: "คะแนนพิเศษ",
            minusPoints: "คะแนนลดลง",
            hit: "ตี",
            misses: "พลาด",
            keyWord: "คำสำคัญ",
            semantics: "ความหมาย",
          },
          title: {
            ruleValidation: "การตรวจสอบกฎ",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "กฎการให้คะแนน (คะแนนรวมของคำปัจจุบันคือ",
            points: "คะแนน)",
            editRules: "แก้ไขกฎ",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "ไม่มีฉลาก" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "เป้าหมายการสื่อสาร",
              scoringCriteria: "เกณฑ์การให้คะแนน",
            },
          },
        },
        index: {
          generating: "สร้างขึ้น",
          generateDialogueFramework: "สร้างกรอบการสนทนา",
          theme: "ธีม",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "คำใบ้",
            generatePromptLanguage: "สร้างพรอมต์",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "ขั้นตอนการสื่อสาร",
          communicationObjectives: "เป้าหมายการสื่อสาร",
          scoringCriteria: "เกณฑ์การให้คะแนน",
          suggestionsForImprovement: "คำแนะนำในการอัพเกรด",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "คะแนนกระบวนการ",
            contentIntegrity: "ความสมบูรณ์ของเนื้อหา:",
            NumberOfCompletedProcesses: "% จำนวนกระบวนการที่เสร็จสมบูรณ์",
            keyHitPointsTotal: "ประเด็นสำคัญ: ทั้งหมด",
            notReached: "ไม่ถึง",
            accurateFollowupAccumulationOfStandardScripts:
              "ติดตามผลที่ถูกต้อง: การสะสมคำมาตรฐาน",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "คำจำนวนคำที่นักเรียนตีด้วยการอ่าน",
            aWord: "คำ",
            languageNormsYes: "ข้อกำหนดภาษา: ใช่",
            emotionsAppearing: "อารมณ์: ปรากฏ",
            speechSpeedSpeakingTooFast:
              "ความเร็วในการพูด: ความเร็วในการพูดเร็วเกินไป",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "อนุภาคของอนุภาค: อนุภาคของอนุภาคมากเกินไป",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "เพิ่มลงในแท็บไม่สนใจ",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "แน่ใจหรือไม่ว่าคุณต้องการเรียกใช้สมาร์ทแท็กอีกครั้ง",
        reExecute: "ดำเนินการใหม่",
      },
      component: { successfullyAdded: "เพิ่มความสำเร็จ" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "ปิดการแจ้งเตือนทั้งหมด",
        reQualityInspectionTask: "งานตรวจสอบคุณภาพใหม่",
        requalityInspection: "ตรวจสอบคุณภาพใหม่",
        waiting: "กำลังรอ",
        viewDetails: "ดูรายละเอียด",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "เวลาในการตรวจสอบ:" } },
        },
        template: { responseOpeningSentence: "คำตอบประโยคเริ่มต้น" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "ความล้มเหลวในการสร้าง",
            generate: "สร้าง",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "ไม่มีข้อมูล" } },
      },
      index: { regenerate: "สร้างใหม่" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "แน่ใจว่าจะลบ?",
        operationSuccessful: "การดำเนินงานที่ประสบความสำเร็จ",
      },
      template: { delete: "ลบ" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "กรุณาเลือกคำถาม!",
        similarQuestionRecommendation: "คำแนะนำคำถามที่คล้ายกัน",
        selectAllOnPage: "เลือกหน้าปัจจุบันทั้งหมด",
        cancel: "การยกเลิก",
        preserve: "บันทึก",
        changeBatch: "เปลี่ยนชุด",
      },
    },
    listControl: { index: { columnControl: "การควบคุมคอลัมน์" } },
    loginUserAvatar: { index: { switch: "สลับ" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "เมื่อเพิ่มข้อมูลแบบเรียลไทม์งานตรวจสอบคุณภาพยังคงต้องใช้เวลาเวลาที่กำหนดขึ้นอยู่กับปริมาณของข้อมูลการตรวจสอบคุณภาพตั้งแต่ไม่กี่นาทีถึง2ชั่วโมง",
      },
    },
    bEditor: {
      index: {
        delete: "ลบ",
        cancel: "การยกเลิก",
        confirm: "กำหนด",
        insert: "แทรก",
        width: "ความกว้าง",
        height: "ความสูง",
        clearContent: "ล้างเนื้อหา",
        revoked: "เพิกถอน",
        redo: "ทำใหม่",
        fontSize: "ขนาดตัวอักษร",
        rowHeight: "แถวสูง",
        wordSpacing: "ระยะห่างของคำ",
        paragraphIndent: "เยื้องย่อหน้า",
        increaseIndent: "เพิ่มการเยื้อง",
        decreaseIndent: "ลดการเยื้อง",
        border: "เส้นขอบ",
        color: "สี",
        textColor: "สีข้อความ",
        backgroundColor: "สีพื้นหลัง",
        temporaryColor: "สีชั่วคราว",
        bold: "เพิ่มความหนา",
        italic: "ตัวเอียง",
        underline: "ขีดเส้นใต้",
        strikethrough: "เส้นลบ",
        font: "แบบอักษร",
        textAlignment: "การจัดตำแหน่งข้อความ",
        left: "ซ้าย",
        centered: "ตรงกลาง",
        right: "ขวา",
        bothEnds: "ปลายทั้งสองข้าง",
        leftFloat: "ลอยซ้าย",
        rightFloat: "ลอยขวา",
        subrupt: "การเสนอราคา",
        subscript: "ตัวห้อย",
        clearStyle: "ล้างสไตล์",
        title: "ชื่อเรื่อง",
        regular: "ปกติ",
        orderedList: "รายการสั่งซื้อ",
        unorderedList: "รายการที่ไม่เป็นระเบียบ",
        reference: "อ้างอิง",
        code: "รหัส",
        link: "การเชื่อมโยง",
        clearLink: "ล้างการเชื่อมโยง",
        horizontalLine: "เส้นแนวนอน",
        media: "สื่อ",
        mediaLibrary: "ไลบรารีสื่อ",
        smallExpression: "อิโมจิขนาดเล็ก",
        fullScreen: "เต็มหน้าจอ",
        exitFullScreen: "ออกจากแบบเต็มหน้าจอ",
        enterLinkText: "ป้อนข้อความลิงก์",
        enterLinkAddress: "ป้อนที่อยู่ลิงก์",
        enterTheLinkAddressAndPressEnter: "ป้อนที่อยู่ลิงก์และกด Enter",
        openInNewWindow: "เปิดในหน้าต่างใหม่",
        removeLink: "ลบลิงก์",
        playingAudioFiles: "เล่นไฟล์เสียง",
        playVideoFiles: "เล่นไฟล์วิดีโอ",
        embeddedMedia: "สื่อฝังตัว",
        image: "ภาพ",
        video: "วิดีโอ",
        audio: "เสียง",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "ชื่อ",
        description: "คำอธิบาย",
        type: "ประเภท",
        status: "สถานะ",
        actions: "การดำเนินงาน",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "ชื่องาน",
        description: "คำอธิบาย",
        form: "แบบฟอร์มการตรวจสอบคุณภาพ",
        actions: "การดำเนินงาน",
        createUser: "ผู้สร้าง",
      },
      samplingRecords: {
        createTime: "เวลา",
        type: "ประเภท",
        operateUser: "ผู้ดำเนินการ",
        number: "เพิ่มจำนวน",
        filterConditionList: "เงื่อนไขการคัดกรอง",
        sampleRuleStr: "เงื่อนไขการสุ่มตัวอย่าง",
        timeGreaterThan: "เวลามากกว่า",
        timeLessThan: "เวลาน้อยกว่า",
      },
    },
    customFilters: {
      createdAt: "เวลาพูดคุย",
      customerId: "ลูกค้า",
      agentId: "บริการลูกค้า",
      userGroupId: "กลุ่มบริการลูกค้า",
      state: "สถานะ",
      lastEvaluateUserId: "คะแนนสุดท้าย",
      submitTime: "เวลาส่งครั้งสุดท้าย",
      totalScore: "คะแนนรวม",
      formItemForbidIdList: "รายการต้องห้าม",
      formItemDeadlyIdList: "รายการร้ายแรง",
    },
    samplingInspection: {
      callTime: "เวลาพูดคุย",
      dialogTime: "เวลาสนทนา",
      originator: "จัดสรรคน",
      createTime: "สร้างเวลา",
      updateTime: "เวลาอัพเดต",
      status: "สถานะกระบวนการ",
      score: "คะแนน",
      actions: "การดำเนินงาน",
      reviewer: "ผู้ตรวจสอบ",
      samplingOfficer: "ผู้ตรวจสอบการสุ่มตัวอย่าง",
      id: "ID บันทึกการโทร",
      time: "เวลาพูดคุย",
      phone: "โทรศัพท์ของลูกค้า",
      staff: "บริการลูกค้า",
      inspectionStatus: "สถานะการตรวจสอบคุณภาพ",
      samplingStatus: "สถานะการสุ่มตัวอย่าง",
      inspectionGrade: "การจัดอันดับ",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "คำมาตรฐาน",
        synonymWords: "คำพ้องความหมาย",
        synonymWordsNumber: "จำนวนคำพ้องความหมาย",
        useFrequency: "ใช้ความถี่",
        effectiveness: "ประสิทธิผล",
        operation: "การดำเนินงาน",
      },
      common: {
        useFrequency: "ใช้ความถี่",
        effectiveness: "ประสิทธิผล",
        operation: "การดำเนินงาน",
      },
      professionalWords: {
        professionalWords: "คำนามมืออาชีพ",
        useFrequency: "ใช้สถิติ",
        homophonic: "คำร้อน (แทนที่คำพ้องเสียง)",
        target: "การแทนที่คำนาม",
        targetWords: "คำแทน",
        homophonyWords: "คำป้องกันคำพ้องเสียง",
        operation: "การดำเนินงาน",
      },
      attentionWords: "คำที่เกี่ยวข้อง",
      ignoreWords: "ละเว้นคำ",
      wordsWeight: {
        selfDefineWeightWords: "น้ำหนักที่กำหนดเองคำ",
        weightWords: "น้ำหนักคำ",
        weight: "น้ำหนัก",
        useFrequency: "ใช้ความถี่",
        effectiveness: "ประสิทธิผล",
        operation: "การดำเนินงาน",
      },
      corpusData: {
        corpusDescription: "คำอธิบายคลังข้อมูล",
        updateTime: "เวลาอัพเดต",
        wordsCount: "จำนวนคำ",
        effectiveness: "ประสิทธิผล",
        operation: "การดำเนินงาน",
        corpusContent: "เนื้อหา",
      },
    },
    semanticTags: {
      title: "แท็กความหมาย",
      tags: "ประโยคมาตรฐาน",
      orgName: "องค์กรในเครือ",
      tagCount: "จำนวนการฝึกอบรม",
      usingCount: "จำนวนการอ้างอิง",
      activeFlag: "ประสิทธิผล",
      suggestion: "คำแนะนำการฝึกอบรม",
      actions: "การดำเนินงาน",
    },
    intelligentTags: {
      tagName: "ชื่อ",
      tagDesc: "คำอธิบาย",
      tagRefCnt: "จำนวนเครื่องหมาย",
      categoryName: "หมวดหมู่ฉลาก",
      eventName: "การจำแนกเหตุการณ์",
      tagStatus: "ประสิทธิผล",
      actions: "การดำเนินงาน",
      tagType: "ประเภทฉลาก",
    },
    dataPreprocess: {
      name: "ชื่อ",
      desc: "คำอธิบาย",
      dataSource: "ข้อจำกัดของแหล่งข้อมูล",
      type: "ประเภท",
      status: "ประสิทธิผล",
      actions: "การดำเนินงาน",
    },
    informationEntities: {
      name: "ชื่อ",
      apiName: "ชื่อ API",
      type: "ประเภท",
      remark: "คำอธิบาย",
      activeFlag: "ประสิทธิผล",
      actions: "การดำเนินงาน",
    },
    interactiveRecognition: {
      questionAndAnswer: "คำถามมาตรฐาน & คำตอบ",
      type: "ประเภท",
      status: "สถานะ",
      actions: "การดำเนินงาน",
      question: "คำถาม",
    },
    role: { id: "ID", name: "ชื่อ", actions: "การดำเนินงาน" },
    uploadDownload: {
      taskName: "ชื่อไฟล์",
      createTime: "วันที่",
      size: "ขนาด",
      createUserName: "ผู้ประกอบการ",
      status: "สถานะ",
      result: "ผล",
      actions: "การดำเนินงาน",
    },
    taskCenterList: {
      name: "เนื้อหางาน",
      createTime: "เวลาส่ง",
      startTime: "เวลาเริ่มต้น",
      endTime: "เวลาสิ้นสุด",
      createUserName: "ผู้ประกอบการ",
      status: "สถานะ",
      result: "ผล",
      actions: "การดำเนินงาน",
    },
    customerInformation: {
      title: "ข้อมูลการบริการลูกค้า",
      data: "ดูเฉพาะข้อมูลที่ผิดปกติ",
      list: {
        agentNo: "หมายเลขงาน/รหัสบริการลูกค้า",
        agentName: "บริการลูกค้า",
        agentTel: "โทรศัพท์",
        agentEmail: "กล่องจดหมาย",
        updateTime: "เวลาอัพเดต",
        actions: "การดำเนินงาน",
      },
      upload: {
        second:
          "2.คลิกที่ปุ่มนำเข้าเพื่ออัปโหลดไฟล์ EXCEl ที่สอดคล้องกับรูปแบบเทมเพลต",
      },
    },
    IndexManagement: {
      title: "การจัดการดัชนี",
      list: {
        indicatorName: "ชื่อ",
        indicatorDesc: "คำอธิบาย",
        activity: "ประสิทธิผล",
        inspectDataSource: "แหล่งข้อมูล",
        sysType: "ประเภท",
        agentGroups: "กลุ่มบริการลูกค้า",
        actions: "การดำเนินงาน",
      },
      detail: {
        nameEmpty: "ชื่อต้องไม่ว่างเปล่า",
        descript: "คำอธิบาย",
        descriptEmpty: "คำอธิบายต้องไม่ว่างเปล่า",
        active: "ประสิทธิผล",
        activeEmpty: "ความถูกต้องต้องไม่ว่างเปล่า",
        dataSource: "แหล่งข้อมูล",
        dataSourceEmpty: "แหล่งข้อมูลต้องไม่ว่างเปล่า",
        grounp: "กลุ่มบริการลูกค้า",
        grounpEmpty: "ทีมบริการลูกค้าต้องไม่ว่างเปล่า",
        grounpHolder: "โปรดเลือกกลุ่มบริการลูกค้า",
        type: "วิธีการให้คะแนน",
        typeNone: "วิธีการให้คะแนนต้องไม่ว่างเปล่า",
        base: "คะแนนพื้นฐาน",
        baseNone: "คะแนนพื้นฐานต้องไม่ว่างเปล่า",
        positive: "ปัจจัยบวก",
        positiveNone: "ปัจจัยบวกต้องไม่ว่างเปล่า",
        customAdd: "เพิ่มการกรอง",
        negative: "ปัจจัยลบ",
        negativeNone: "ปัจจัยลบไม่สามารถว่างเปล่า",
        customOK: "กรองเงื่อนไข:",
        judgeStrategy: "กฎเงื่อนไข:",
        other: "ปัจจัยอื่นๆ",
        otherAdd: "เพิ่มปัจจัย",
        agent: "ข้อมูลดิบ",
        section: "จำนวนช่วงเวลา",
        sectionNone: "จำนวนช่วงเวลาต้องไม่ว่างเปล่า",
        step: "ความยาวขั้นตอน",
        stepNone: "ความยาวขั้นตอน",
        save: "บันทึก",
        cancel: "การยกเลิก",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "ทั้งหมด",
      staff: "เท่านั้น",
      department: "แผนกนี้และด้านล่าง",
    },
    gongStatisticsDate: { week: "สัปดาห์", month: "เดือน", season: "ฤดูกาล" },
    gongCoachTaskStatus: {
      wait: "กำลังรอการให้คำปรึกษา",
      already: "ติวแล้ว",
      ignore: "ไม่สนใจ",
    },
    callRangeStatus: {
      all: "โทรทั้งหมด",
      sale: "พนักงานเริ่มต้น",
      customer: "ลูกค้าเริ่มต้น",
    },
    todoListType: {
      later: "ฟังในภายหลัง",
      help: "การให้คำปรึกษาเพื่อขอความช่วยเหลือ",
      contact: "ติดต่อในภายหลัง",
      send: "ส่งข้อมูล",
      warn: "คำเตือนความเสี่ยง",
    },
    frequencyTypes: {
      everyDay: "ทุกวัน",
      everyWeek: "ทุกสัปดาห์",
      everyMonth: "รายเดือน",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "การฝึกอบรมข้อมูลที่ไม่มีหมวดหมู่",
        markedDataCorrigenda: "แก้ไขข้อผิดพลาดของข้อมูลที่ทำเครื่องหมายไว้",
        classifiedDataCheck: "การตรวจสอบข้อมูลที่จัดประเภท",
        markedHistoryRecord: "บันทึกประวัติติดฉลาก",
        historyDataRecalculate: "การคำนวณข้อมูลทางประวัติศาสตร์ใหม่",
      },
      dataExtractMode: {
        random: "สุ่มสมบูรณ์",
        intelligentRecommend: "คำแนะนำที่ชาญฉลาด",
        custom: "ที่กำหนดเอง",
        specifiedClass: "ระบุหมวดหมู่",
      },
      smartRatesList: {
        markRate: "อัตราการติดฉลาก",
        corrigendaRate: "อัตราการแก้ไขข้อผิดพลาด",
        kl: "K-L กระจาย",
        signalNoiseRate: "อัตราส่วนสัญญาณต่อเสียงรบกวน",
      },
    },
    visibleRangeTypes: {
      all: "ทั้งหมด",
      customerGroup: "กลุ่มบริการลูกค้า",
      userDefined: "ที่กำหนดเอง",
    },
    samplingRateTypes: {
      everyDay: "สกัดทุกวัน",
      everyWeek: "การสกัดรายสัปดาห์",
    },
    taskRemindTypes: {
      confirm: "ส่งการแจ้งเตือนเมื่อจำเป็นต้องยืนยันบันทึกงานตรวจสอบคุณภาพ",
      appeal: "เมื่อบันทึกงานตรวจสอบคุณภาพเริ่มต้นการอุทธรณ์ให้ส่งการแจ้งเตือน",
    },
    weekDays: {
      monday: "วันจันทร์",
      tuesday: "วันอังคาร",
      wednesday: "วันพุธ",
      thursday: "วันพฤหัสบดี",
      friday: "วันศุกร์",
      saturday: "วันเสาร์",
      sunday: "วันอาทิตย์",
    },
    reportDateTypes: {
      dailyReport: "รายงานประจำวัน",
      weeklyReport: "รายงานรายสัปดาห์",
    },
    samplingRangeDayTypes: { today: "วันนี้", yesterday: "เมื่อวานนี้" },
    samplingRangeWeekTypes: {
      thisWeek: "สัปดาห์นี้",
      lastWeek: "สัปดาห์ที่แล้ว",
    },
    samplingRuleTypes: {
      proportion: "การสุ่มตัวอย่างตามสัดส่วน",
      random: "การสุ่มตัวอย่างแบบสุ่ม",
    },
    taskStatusTypes: {
      waitEvaluate: "ที่จะประเมิน",
      waitSubmit: "ที่จะส่ง",
      waitConfirm: "จะได้รับการยืนยัน",
      waitReview: "ที่จะตรวจสอบ",
      completed: "เสร็จเรียบร้อยแล้ว",
      all: "ทั้งหมด",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "การให้คะแนนโดยตรง",
        weightedScore: "การให้คะแนนน้ำหนัก",
        deductScore: "หักเงิน",
        directScoreSecondLevel: "คะแนนโดยตรง-ระดับ2",
        weightedScoreSecondLevel: "คะแนนน้ำหนัก-ระดับ2",
        deductScoreSecondLevel: "หัก-ระดับ2",
      },
    },
    fieldOperators: {
      is: "เท่ากับ",
      not: "ไม่เท่ากับ",
      contains: "ประกอบด้วย",
      notContains: "ไม่รวม",
      not_contains: "ไม่รวม",
      containsAny: "ประกอบด้วยใดๆ",
      contains_any: "ประกอบด้วยใดๆ",
      is_any: "ประกอบด้วยใดๆ",
      not_any: "ไม่รวมโดยพลการ",
      notContainsAny: "ไม่รวมโดยพลการ",
      not_contains_any: "ไม่รวมโดยพลการ",
      containsAll: "มีทั้งหมด",
      contains_all: "มีทั้งหมด",
      notContainsAll: "ไม่รวมทั้งหมด",
      nont_contains_all: "ไม่รวมทั้งหมด",
      isNull: "ว่างเปล่า",
      is_null: "ว่างเปล่า",
      isNotNull: "ไม่ว่างเปล่า",
      is_not_null: "ไม่ว่างเปล่า",
      greaterThan: "มากกว่า",
      greater_than: "มากกว่า",
      greaterThanEq: "มากกว่าหรือเท่ากับ",
      greater_than_eq: "มากกว่าหรือเท่ากับ",
      lessThan: "น้อยกว่า",
      less_than: "น้อยกว่า",
      lessThanEq: "น้อยกว่าหรือเท่ากับ",
      less_than_eq: "น้อยกว่าหรือเท่ากับ",
      between: "ระหว่าง",
      notBetween: "ไม่ระหว่าง",
      not_between: "ไม่ระหว่าง",
      relative: "เท่ากับ",
      set: "ตั้งค่าเป็น",
      belongs: "เป็นของ",
      notBelongs: "ไม่ได้เป็นของ",
      not_belongs: "ไม่เท่ากับ",
      today: "วันนี้",
      yesterday: "เมื่อวานนี้",
      the_day_before_yesterday: "วันก่อนเมื่อวานนี้",
      tomorrow: "พรุ่งนี้",
      next_seven_day: "เจ็ดวันข้างหน้า",
      last_seven_day: "เจ็ดวันที่ผ่านมา",
      this_week: "สัปดาห์นี้",
      next_week: "สัปดาห์หน้า",
      last_week: "สัปดาห์ที่แล้ว",
      this_month: "เดือนนี้",
      next_month: "เดือนหน้า",
      last_month: "เดือนที่แล้ว",
      this_year: "ในปีนี้",
      next_year: "ปีหน้า",
      last_year: "ปีที่แล้ว",
      exact_match: "การจับคู่ที่แม่นยำ",
      add_value: "เพิ่มมูลค่าใหม่",
      remove_value: "ลบค่า",
      set_null: "ว่างเปล่า",
      isAny: "เท่ากับโดยพลการ",
      notAny: "โดยพลการไม่เท่ากับ",
      belongTo: "เป็นของ",
      notBelongTo: "ไม่ได้เป็นของ",
      hasAny: "ประกอบด้วยใดๆ",
      notHasAny: "ไม่รวมโดยพลการ",
      prefixContains: "จุดเริ่มต้นเท่ากับ",
      prefixNotContains: "จุดเริ่มต้นไม่เท่ากับ",
      suffixContains: "จุดสิ้นสุดเท่ากับ",
      suffixNotContains: "ตอนจบไม่เท่ากับ",
      nextSevenDay: "7วันข้างหน้า",
      lastSevenDay: "7วันที่ผ่านมา",
      thisWeek: "สัปดาห์นี้",
      nextWeek: "สัปดาห์หน้า",
      lastWeek: "สัปดาห์ที่แล้ว",
      thisMonth: "เดือนนี้",
      nextMonth: "เดือนหน้า",
      lastMonth: "เดือนที่แล้ว",
      thisYear: "ในปีนี้",
      nextYear: "ปีหน้า",
      lastYear: "ปีที่แล้ว",
      after: "ช้ากว่า (รวมวัน)",
      before: "เร็วกว่า (รวมวัน)",
      allMatching: "จับคู่ทั้งหมด",
      allNotMatching: "ทั้งหมดไม่ตรงกัน",
      hit: "ตี",
      not_hit: "พลาด",
    },
    fieldsOperators: {
      is: "เท่ากับ",
      is_null: "ว่างเปล่าในเวลาเดียวกัน",
      head_equal: "จุดเริ่มต้นเท่ากับ",
      tail_equal: "จุดสิ้นสุดเท่ากับ",
      is_not_null: "ในขณะเดียวกันก็ไม่ว่างเปล่า",
      not: "ไม่เท่ากับ",
      diff_equal: "ความแตกต่างเท่ากับ",
      diff_greater_than: "ความแตกต่างมากกว่า",
      diff_greater_than_eq: "ความแตกต่างมากกว่าหรือเท่ากับ",
      diff_less_than: "ความแตกต่างน้อยกว่า",
      diff_less_than_eq: "ความแตกต่างน้อยกว่าหรือเท่ากับ",
      hour_equal: "ชั่วโมงเดียวกัน",
      day_equal: "วันเดียวกัน",
      week_equal: "สัปดาห์เดียวกัน",
      month_equal: "เดือนเดียวกัน",
      year_equal: "ในปีเดียวกัน",
      day: "วัน",
      minute: "นาที",
      hour: "ชั่วโมง",
      part_equal: "บางส่วนเท่ากับ",
    },
    samplingColumnCaptions: {
      created_at: "เวลาพูดคุย",
      agent_id: "บริการลูกค้า",
      get_direction: "ประเภทการโทร",
      call_result: "ผลการโทร",
      survey: "การประเมินผล",
      derived_from_id: "แหล่งที่มา",
      quit_queue_why: "สถานะคิว",
      queuing_duration: "คิวใช้เวลานาน",
      talking_seconds: "ระยะเวลาการโทร",
      ringing_seconds: "เวลาแหวน",
      hangup_by: "วางสาย",
    },
    callTypes: {
      callIn: "โทรเข้า",
      callOut: "หายใจออก",
      threeParty: "(ไตรภาคี)",
      consultation: "(ปรึกษา)",
      insert: "(ปลั๊กที่แข็งแกร่ง)",
      monitor: "(การตรวจสอบ)",
      transfer: "(โอน)",
      intercept: "(การสกัดกั้น)",
      transferOutside: "(โอนสายนอก)",
      threePartyOutside: "(สายนอกสามทาง)",
      consultingOutside: "(ปรึกษาสายนอก)",
    },
    callResults: {
      staffAnswer: "ฝ่ายบริการลูกค้าตอบ",
      staffNotAnswer: "ฝ่ายบริการลูกค้าไม่รับ",
      customerAnswer: "ลูกค้าตอบ",
      customerNotAnswer: "ลูกค้าไม่ได้รับ",
      phoneBusy: "โทรศัพท์ไม่ว่าง",
      phoneOffline: "โทรศัพท์ออฟไลน์",
      customerSpeedHangUp: "แขวนความเร็วของลูกค้า",
      customerHangUp: "เครื่องแขวนลูกค้า",
      queueTimeout: "คิวหมดเวลา",
      queueGiveUp: "เลิกเข้าคิว",
      outlineAnswer: "สายนอกรับสาย",
      outlineNotAnswer: "สายนอกไม่ได้รับ",
      noChooseQueue: "ไม่ได้เลือกคิว",
    },
    defaultSurveys: {
      noEvaluation: "ไม่ได้แสดงความคิดเห็น",
      noNeedToEvaluate: "ไม่ต้องประเมิน",
    },
    queueStates: {
      queueSuccess: "ประสบความสำเร็จในการเข้าคิว",
      queueTimeout: "คิวหมดเวลา",
      queueGiveUp: "เลิกเข้าคิว",
      noStaffOnline: "ไม่มีบริการลูกค้าออนไลน์",
    },
    callHangers: {
      customer: "ลูกค้า",
      staff: "บริการลูกค้า",
      outline: "สายนอก",
    },
    workFlowTypes: {
      noAppealsMode: "ไม่มีโหมดอุทธรณ์",
      appealsMode: "อนุญาตโหมดการอุทธรณ์",
    },
    fieldCategories: {
      standardCondition: "เกณฑ์การคัดกรองมาตรฐาน",
      customerCondition: "ตัวกรองที่ผู้ใช้กำหนดเอง",
    },
    sampleTypes: {
      automatic: "การสุ่มตัวอย่างอัตโนมัติ",
      manual: "การสุ่มตัวอย่างด้วยตนเอง",
    },
    defaultFields: {
      createdAt: "เวลาพูดคุย",
      agentId: "บริการลูกค้า",
      getDirection: "ประเภทการโทร",
      callResult: "ผลการโทร",
      survey: "การประเมินผล",
      derivedFrom: "แหล่งที่มา",
      quitQueueWhy: "สถานะคิว",
      queuingDuration: "คิวใช้เวลานาน",
      talkingSeconds: "ระยะเวลาการโทร",
      ringingSeconds: "เวลาแหวน",
      hangupBy: "วางสาย",
    },
    conditionFieldCategories: {
      fixedField: "ฟิลด์คงที่",
      customField: "ฟิลด์ที่กำหนดเอง",
      secondLevelScore: "การจำแนกประเภทรอง",
      formItemScore: "รายการคะแนน",
      forbids: "รายการต้องห้าม",
      deadlies: "รายการร้ายแรง",
    },
    columnFieldCategories: {
      agent: "บริการลูกค้า",
      callLog: "บันทึกการโทร",
      businessFields: "ข้อมูลธุรกิจ",
      customer: "ลูกค้า",
      customerCustomFields: "ลูกค้าที่กำหนดเอง",
      source: "แหล่งที่มา",
      queue: "คิว",
      fixed: "คงที่",
      secondLevelScore: "การจำแนกประเภทรอง",
      formItemScore: "รายการคะแนน",
      forbids: "รายการต้องห้าม",
      deadlies: "รายการร้ายแรง",
    },
    taskListDefaultFields: {
      startTime: "เวลาพูดคุย",
      name: "บริการลูกค้า",
      state: "สถานะ",
      totalScore: "คะแนนรวม",
      formItemForbidList: "รายการต้องห้าม",
      formItemDeadlyList: "รายการร้ายแรง",
      comment: "หมายเหตุการประเมินผล",
      inspector: "คนให้คะแนน",
      review: "ผู้ตรวจสอบ",
    },
    evaluationStates: {
      initial: "การเริ่มต้น",
      waitEvaluate: "ที่จะประเมิน",
      evaluated: "ที่จะส่ง",
      waitConfirm: "จะได้รับการยืนยัน",
      waitCheck: "ที่จะตรวจสอบ",
      complete: "เสร็จเรียบร้อยแล้ว",
    },
    workBenchInfoTypes: {
      callInfo: "ข้อมูลการโทร",
      businessInfo: "ข้อมูลธุรกิจ",
      customerInfo: "ข้อมูลลูกค้า",
    },
    evaluationEventStates: {
      create: "สร้าง",
      valuate: "การประเมินผล",
      edit: "บรรณาธิการ",
      submit: "ส่ง",
      confirm: "ยืนยัน",
      appeal: "การอุทธรณ์",
      checkConfirm: "ยืนยันการตรวจสอบ",
      check: "รีวิว",
    },
    formItemTypes: {
      direct: "การให้คะแนนโดยตรง",
      weight: "น้ำหนัก",
      deduct: "หักเงิน",
      forbid: "รายการต้องห้าม",
      deadly: "รายการร้ายแรง",
    },
    appealCheckCallTypes: {
      callIn: "โทรเข้า",
      callBack: "โทรกลับสองทาง",
      callOut: "โทรออกสายตรง",
      autoCall: "โทรออกอัตโนมัติ",
    },
    appealCheckCallStatus: {
      waitReview: "ที่จะตรวจสอบ",
      reviewed: "ตรวจสอบแล้ว",
      filed: "ยื่นแล้ว",
    },
    samplingStatus: {
      all: "ทั้งหมด",
      unchecked: "สกัดโดยไม่ตรวจสอบ",
      checked: "การสุ่มตัวอย่าง",
    },
    inspectionStatus: {
      all: "ทั้งหมด",
      unread: "ยังไม่ได้อ่าน",
      readed: "อ่านแล้ว",
      appeal: "การอุทธรณ์",
      review: "รีวิว",
      filed: "ยื่นแล้ว",
    },
    gradeChooseHits: { yes: "ใช่", no: "ไม่ใช่" },
    appealCheckStatus: {
      all: "สถานะทั้งหมด",
      unread: "ยังไม่ได้อ่าน",
      inComplaint: "อุทธรณ์",
      reviewed: "ตรวจสอบแล้ว",
      readed: "อ่านแล้ว",
      filed: "ยื่นแล้ว",
      spotchecked: "การสุ่มตัวอย่าง",
    },
    flowStatus: {
      unread: "ยังไม่ได้อ่าน",
      alreadyRead: "อ่านแล้ว",
      complaining: "อุทธรณ์",
      reviewed: "ตรวจสอบแล้ว",
      sampling: "สกัดโดยไม่ตรวจสอบ",
      samplingCompleted: "การสุ่มตัวอย่าง",
      complainCancel: "ถูกเพิกถอน",
    },
    complainStatus: {
      success: "ประสบความสำเร็จ",
      fail: "ล้มเหลว",
      default: "อุทธรณ์",
      canceled: "ถูกเพิกถอน",
    },
    reviewInspectionStatus: { inComplaint: "อุทธรณ์", reviewed: "ตรวจสอบแล้ว" },
    gradeChangeTypes: {
      edit: "บรรณาธิการ",
      check: "รีวิว",
      file: "เก็บถาวร",
      remark: "หมายเหตุ",
      complain: "การอุทธรณ์",
      review: "รีวิว",
      reviewEvaluation: "รีวิวการประเมินผล",
      sample: "การสุ่มตัวอย่าง",
      sampleEvaluation: "การประเมินการสุ่มตัวอย่าง",
      sampleSubmit: "การส่งแบบสุ่ม",
      sampleAssign: "การสุ่มตรวจ",
      reviewAssign: "การจัดสรรการตรวจสอบ",
      read: "อ่านแล้ว",
      reviewConfirm: "ยืนยันการตรวจสอบ",
      sampleConfirm: "การยืนยันการสุ่มตัวอย่าง",
      caseBase: "เพิ่มไปยังกลุ่มกรณี",
      sampleCancel: "การตรวจสอบแบบสุ่มยกเลิกการจัดสรร",
      reviewCancel: "ตรวจสอบการยกเลิกการจัดสรร",
      sampleAgain: "การสุ่มตรวจแจกจ่ายใหม่",
      reviewAgain: "แจกจ่ายรีวิว",
      btachDelete: "ลบบันทึกการสุ่มตัวอย่าง",
      rapidSampling: "การสุ่มตัวอย่างอย่างรวดเร็ว",
      reInspection: "ตรวจสอบคุณภาพใหม่",
      smartTagsChange: "สมาร์ทแท็กเปลี่ยน",
      cancelAppeal: "การเพิกถอนการอุทธรณ์",
    },
    appealTypes: {
      transliterationError: "ข้อผิดพลาดในการถอดเสียง",
      discriminationError: "การตัดสินผิด",
      otherError: "ข้อผิดพลาดอื่นๆ",
    },
    inspectDataSources: {
      voiceCall: "การโทรด้วยเสียง",
      textDialogue: "บทสนทนาข้อความ",
      realTimeVoiceCall: "การโทรด้วยเสียงแบบเรียลไทม์",
      realTimeTextDialogue: "การสนทนาข้อความแบบเรียลไทม์",
      wechatDialogue: "เซสชันไมโครขององค์กร",
      taobao: "บทสนทนาข้อความอีคอมเมิร์ซ",
      ticket: "ใบสั่งงาน",
      wechatRadio: "เสียงไมโคร",
    },
    inspectTaskType: {
      all: "ทั้งหมด",
      common: "การตรวจสอบคุณภาพตามปกติ",
      relate: "การตรวจสอบคุณภาพที่เกี่ยวข้อง",
    },
    inspectApproveType: {
      all: "ทั้งหมด",
      resolve: "ผ่านการตรวจสอบ",
      resolving: "อยู่ระหว่างการตรวจสอบ",
      reject: "ล้มเหลวในการตรวจสอบ",
    },
    dataSourceFlags: {
      all: "ไม่จำกัด",
      voiceCall: "โทร",
      textDialogue: "การสนทนา",
    },
    smartWordsLibrary: { effective: "มีประสิทธิภาพ", invalid: "ไม่ถูกต้อง" },
    activeFlags: { effiective: "มีประสิทธิภาพ", invalid: "ไม่ถูกต้อง" },
    labelTypes: {
      dimensionLabel: "ป้ายกำกับ",
      classificationLabel: "แท็กหมวดหมู่",
    },
    pointTypes: {
      automatic: "การประเมินอัตโนมัติ",
      manual: "การประเมินด้วยตนเอง",
      interactiveDetection: "การตรวจจับแบบโต้ตอบ",
      smartRules: "กฎอัจฉริยะ",
      machineLearning: "โหมดการเรียนรู้ด้วยตนเอง",
      intelligentModel: "โมเดลอัจฉริยะ",
    },
    pointGradeTypes: {
      radio: "ตัวเลือกเดียว",
      input: "อินพุต",
      select: "เลือก",
      all: "ตอบสนองทั้งหมด",
      any: "ตอบสนองใดๆ",
      customize: "ที่กำหนดเอง",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "การจับคู่คำหลัก",
      question: "ประโยคคำถาม",
      regular: "นิพจน์ทั่วไป",
      context: "การทำซ้ำตามบริบท",
      semantics: "การจับคู่ความหมาย",
      word: "ประโยคที่คล้ายกัน",
      dialogue: "ช่วงเวลาการสนทนา",
      speed: "การตรวจจับความเร็วในการพูด",
      grabDetection: "การตรวจจับการโทร",
      semanticTags: "แท็กความหมาย",
    },
    applyRoles: {
      all: "ทั้งหมด",
      customer: "ลูกค้า",
      agent: "บริการลูกค้า",
      robot: "หุ่นยนต์",
    },
    saleApplyRoles: { all: "ทั้งหมด", customer: "ลูกค้า", agent: "พนักงาน" },
    applyOperatorScopes: {
      all: "ข้อความเต็ม",
      preCondition: "สภาพก่อน",
      scope: "ระบุช่วง",
    },
    keywordScopes: { all: "ตรวจสอบคำหลักทั้งหมด", any: "ตรวจจับคำหลักใดๆ" },
    preOperatorHitTypes: {
      first: "การจับคู่ครั้งแรก",
      every: "การจับคู่ทุกครั้ง",
      last: "นัดสุดท้าย",
      any: "การจับคู่ใดๆ",
      none: "ไม่ตรงกัน",
    },
    operatorRuleTypes: {
      all: "ตอบสนองทั้งหมด",
      any: "ตอบสนองใดๆ",
      custom: "ตรรกะที่กำหนดเอง",
    },
    informationEntityTypes: {
      system: "ระบบ",
      custom: "ที่กำหนดเอง",
      moduleCompany: "โมดูล",
    },
    organizationTypes: { null: "ไม่มี", moduleCompany: "โมดูล" },
    customType: {
      smartTag: "แยกเอนทิตี",
      scriptTimes: "การนับคำพูด",
      dataCategory: "การจำแนกข้อมูล",
    },
    interactiveQuestionTypes: {
      standard: "คำถามและคำตอบมาตรฐาน",
      multiElement: "คำถามและคำตอบหลายองค์ประกอบ",
      guideMultiRound: "แนะนำการโต้ตอบหลายรอบ",
    },
    targetTypes: {
      all: "ทั้งหมด",
      customerService: "บริการลูกค้า",
      customer: "ลูกค้า",
    },
    interactiveTypes: {
      similarQList: "คำถามที่คล้ายกัน",
      exceptSimilarQList: "ไม่รวมปัญหาที่คล้ายกัน",
      similarAList: "คำตอบที่คล้ายกัน",
      wrongAList: "คำตอบที่ผิด",
    },
    filterTypes: {
      call: "บันทึกการโทร",
      dialogue: "ข้อความสนทนา",
      wechat: "องค์กร WeChat",
      wechatAll: "บทสนทนาทั้งหมดบน WeChat ขององค์กร",
      ticket: "เซสชันใบสั่งงาน",
      taobao: "เซสชันอีคอมเมิร์ซ",
      wechatRadio: "เสียงไมโคร",
    },
    fieldTypes: {
      keyword: "คำสำคัญ",
      agent: "บริการลูกค้า",
      agentGroup: "กลุ่มบริการลูกค้า",
      sigleListbox: "ตัวเลือกเดียว",
      multiListbox: "เลือกได้หลายแบบ",
      text: "ข้อความบรรทัดเดียว",
      textArea: "ข้อความหลายบรรทัด",
      dateTime: "เวลา",
      number: "ตัวเลข",
      voice: "",
      customer: "ลูกค้า",
      fieldWithLink: "ด้วยการเชื่อมโยงฟิลด์",
      percentage: "ร้อยละ",
      intelligentClassification: "การจำแนกอัจฉริยะ",
      testSetList: "ฟิลด์แบบไดนามิกชุดทดสอบ",
      inspector: "ผู้ตรวจสอบคุณภาพ",
    },
    saveTypes: { save: "บันทึก" },
    knowledgeType: { oneToOne: "คำถามและคำตอบ" },
    updateTypes: { update: "อัพเดต", saveAs: "บันทึกเป็น" },
    recommendItemActionTypes: {
      similar: "คล้ายกัน",
      exclude: "การยกเว้น",
      ignore: "ละเว้น",
    },
    gradeStatus: {
      unread: "ยังไม่ได้อ่าน",
      alreadyReview: "ตรวจสอบแล้ว",
      alreadyFiled: "ยื่นแล้ว",
      waitReview: "ที่จะตรวจสอบ",
      alreadyRead: "อ่านแล้ว",
    },
    qualityCheckDetailTypes: { review: "รีวิว", spotCheck: "การสุ่มตัวอย่าง" },
    sampleAndReviewStatus: {
      init: "ไม่สกัด",
      unread: "ยังไม่ได้อ่าน",
      alreadyRead: "อ่านแล้ว",
      complaining: "อุทธรณ์",
      reviewed: "ตรวจสอบแล้ว",
      sampling: "สกัดโดยไม่ตรวจสอบ",
      samplingCompleted: "การสุ่มตัวอย่าง",
    },
    interactiveAnswerTypes: {
      noAnswer: "ไม่พบคำตอบ",
      answer: "ตรวจพบคำตอบ",
      wrongAnswer: "ตรวจพบคำตอบที่ผิด",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "ไม่พบปัญหา",
      question: "ตรวจพบปัญหา",
      eliminateQuestion: "ตรวจพบการแก้ไขปัญหา",
    },
    isManual: {
      yes: "การตรวจสอบคุณภาพด้วยตนเอง",
      no: "ไม่มีการตรวจสอบด้วยตนเอง",
    },
    readStatus: { yes: "อ่านแล้ว", no: "ยังไม่ได้อ่าน" },
    reInspectionType: {
      recalcScore: "คำนวณคะแนนใหม่เท่านั้น (รวมคะแนน)",
      recheckPoint: "คำนวณจุดตรวจสอบคุณภาพและคะแนนใหม่",
    },
    common: {
      eCommerceReview: "รีวิวอีคอมเมิร์ซ",
      socialMediaComments: "รีวิวโซเชียลมีเดีย",
      customer: "ลูกค้า",
      customerService: "บริการลูกค้า",
      robot: "หุ่นยนต์",
      customerInformation: "ข้อมูลลูกค้า",
      workOrderFields: "ฟิลด์ใบสั่งงาน",
      intelligentConversationAnalysis: "การวิเคราะห์เซสชันอัจฉริยะ",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "รับข้อมูลเชิงลึกเกี่ยวกับข้อบกพร่องทางธุรกิจรับฟังเสียงของลูกค้าและปรับปรุงการเปลี่ยนแปลงทางการตลาด",
      intelligentAccompanyingPractice: "การฝึกอบรมอัจฉริยะ",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "สร้างคู่ฝึก AI ที่สมจริงซึ่งจำลองสถานการณ์จริงของธุรกิจตระหนักถึงความเชี่ยวชาญของทักษะการพูดเหรียญทองและพัฒนาทักษะการสนทนาอย่างรวดเร็ว",
      intelligentQualityInspection: "การตรวจสอบคุณภาพอัจฉริยะ",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "การตรวจสอบคุณภาพเต็มรูปแบบทันเวลาและมีประสิทธิภาพอัตราความแม่นยำมากกว่า85%",
      salesEmpowerment: "การเพิ่มขีดความสามารถในการขาย",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI ปรับรูปแบบการจัดการการขายตระหนักถึงการแปลงเป็นดิจิทัลและการแสดงภาพของกระบวนการสื่อสารการจัดการที่โปร่งใสของทุกการเชื่อมโยงของการขายข้อมูลเชิงลึกที่ชาญฉลาดในทุกขั้นตอนของการสื่อสารการวินิจฉัยปัญหาทางธุรกิจที่ละเอียดอ่อนและการสร้างประสบการณ์การขายใหม่ที่สมบูรณ์แบบ",
      algorithmPlatform: "แพลตฟอร์มอัลกอริทึม",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "ตามความหมายการออกเสียงของเครื่องยนต์ดั้งเดิมที่พัฒนาขึ้นเองการปรับแต่งโมเดล ASR และ NLP การเปิดข้อมูลสถานการณ์ทางธุรกิจการเรียนรู้ด้วยตนเองของระบบและบรรลุวัตถุประสงค์ของการใช้อย่างชาญฉลาดและแม่นยำยิ่งขึ้น",
      generalSettings: "การตั้งค่าสากล",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "การตั้งค่าและการจัดการสิทธิ์ข้อมูลและบทบาทของพนักงานแบบรวม",
      callLog: "บันทึกการโทร",
      intelligentRules: "กฎอัจฉริยะ",
      businessRecords: "บันทึกธุรกิจ",
      customFilter: {
        call: "การโทรด้วยเสียง",
        text: "บทสนทนาข้อความ",
        smartTags: "สมาร์ทแท็ก",
        pretreatment: "การปรับสภาพ",
        smartTagGroup: "กลุ่มสมาร์ทแท็ก",
        currency: "ข้อมูลทั่วไป",
        wechat: "องค์กร WeChat",
        spotCheck: "การสุ่มตัวอย่าง",
        personaField: "รายชื่อลูกค้า",
        ticket: "เซสชันใบสั่งงาน",
        taobao: "เซสชันอีคอมเมิร์ซ",
        wechatRadio: "เสียงไมโคร",
        reviewList: "รีวิว",
      },
      talkerRecognitionType: {
        API: "API ระบุบทบาทการติดตาม",
        text: "การรับรู้ข้อความ",
      },
      operatorTypes: {
        keyword: "การจับคู่คำหลัก",
        question: "ประโยคคำถาม",
        regular: "นิพจน์ทั่วไป",
        context: "การทำซ้ำตามบริบท",
        semantics: "การจับคู่ความหมาย",
        word: "ประโยคที่คล้ายกัน",
        dialogue: "ช่วงเวลาการสนทนา",
        speed: "การตรวจจับความเร็วในการพูด",
        grabDetection: "การตรวจจับการโทร",
        semanticTags: "แท็กความหมาย",
        keywordStrict: "การจับคู่คำหลัก (เข้มงวด)",
        link: "ฟิลด์การเชื่อมโยง",
        entityInfoDetection: "การตรวจจับเอนทิตีข้อมูล",
        basicInfoDetection: "การตรวจจับข้อมูลพื้นฐาน",
        volume: "การตรวจจับระดับเสียง",
        emotion: "การวิเคราะห์อารมณ์",
        grammar: "กฎไวยากรณ์",
        sentenceLength: "การตรวจจับความยาวประโยค",
        conceptWord: "คำแนวคิด",
        questionsAndAnswers: "คำตอบฐานความรู้ (เก่า)",
        element: "คำถามและคำตอบเกี่ยวกับองค์ประกอบ",
        silent: "หมดเวลาตอบสนอง",
        associate: "ข้อมูลถนน",
        repeat: "ส่งซ้ำ",
        timeInterval: "ความยาวช่วงเวลา",
        typoDetection: "การตรวจจับการพิมพ์ผิด",
        silentOld: "เงียบ",
        knowledge: "คำตอบความรู้",
        wecomRemark: "ข้อสังเกตขององค์กรลูกค้า",
        customerAddTime: "ลูกค้าเพิ่มเวลา",
      },
      keywordOperators: {
        all: "จับคู่ทั้งหมด",
        allNot: "ทั้งหมดไม่ตรงกัน",
        any: "จับคู่โดยพลการ",
      },
      applyRoles: { all: "ทั้งหมด", customer: "ลูกค้า", agent: "บริการลูกค้า" },
      sentenceLength: {
        less: "น้อยกว่า",
        greater: "มากกว่า",
        equal: "เท่ากับ",
        greater_or_equal: "มากกว่าหรือเท่ากับ",
        less_or_equal: "น้อยกว่าหรือเท่ากับ",
      },
      dialogueApplyScopes: {
        differentRoles: "ระหว่างตัวละครที่แตกต่างกัน",
        agent: "บริการลูกค้า",
        customer: "ลูกค้า",
        agentResponse: "การตอบสนองการบริการลูกค้า",
        customerResponse: "การตอบสนองของลูกค้า",
        customerNoResponse: "ลูกค้าไม่ตอบสนอง",
      },
      applyConditionHitTypes: {
        first: "ตีครั้งแรก",
        every: "ทุกฮิต",
        last: "ตีครั้งสุดท้าย",
      },
      applyConditionScopes: {
        current: "ปัจจุบัน",
        before: "ด้านหน้า",
        after: "หลังจากที่",
        beforeAll: "ทั้งหมดก่อน",
        afterAll: "หลังจากนั้นทั้งหมด",
        around: "บริเวณใกล้เคียง",
      },
      voiceDemoTypes: {
        singleChannel: "บันทึกการโทรช่องเดียว",
        dualChannel: "บันทึกการโทรสองช่อง",
      },
      sendStatus: {
        sending: "กำลังส่ง",
        arrive: "จัดส่งแล้ว",
        fail: "ส่งล้มเหลว",
        off_sending: "ยังไม่ได้อ่านส่งแบบออฟไลน์",
        off_arrive: "อ่านแล้ว",
        rollback: "ถอนไปแล้ว",
      },
      collectionMethod: {
        assignment: "มอบหมายโดยตรง",
        entity: "การสกัดเอนทิตี",
      },
      systemTypes: {
        qa: "การตรวจสอบคุณภาพอัจฉริยะ",
        wfm: "การจัดตารางเวลาอัจฉริยะ",
      },
      entityOperators: {
        equals: "เท่ากับข้อมูล",
        notEquals: "ไม่เท่ากับข้อมูล",
        contains: "มีข้อมูล",
        notContains: "ไม่มีข้อมูล",
        equalsFix: "เท่ากับค่าคงที่",
        notEqualsFix: "ไม่เท่ากับค่าคงที่",
        containsFix: "มีค่าคงที่",
        notContainsFix: "ไม่รวมค่าคงที่",
      },
      basicInfoOperators: {
        equals: "เท่ากับ",
        notEquals: "ไม่เท่ากับ",
        contains: "ประกอบด้วย",
        notContains: "ไม่รวม",
        isNull: "ว่างเปล่า",
        isNotNull: "ไม่ว่างเปล่า",
      },
      volumeDetectionWays: {
        loudness: "เดซิเบลความดัง",
        rangeAbilitySelf: "ช่วงการเปลี่ยนแปลง (มากกว่าประโยคก่อนหน้าของฉัน)",
        rangeAbilityOth: "ช่วงของการเปลี่ยนแปลง (เปรียบเทียบกับประโยคก่อนหน้า)",
        rangeAbility3: "ช่วงของการเปลี่ยนแปลง (มากกว่าประโยคของตัวเอง)",
      },
      volumeDetectionOperators: {
        is: "เท่ากับ",
        not: "ไม่เท่ากับ",
        greaterThan: "มากกว่า",
        greaterThanEq: "มากกว่าหรือเท่ากับ",
        lessThan: "น้อยกว่า",
        lessThanEq: "น้อยกว่าหรือเท่ากับ",
      },
      sentimentTypes: {
        positive: "อารมณ์เชิงบวก",
        negative: "อารมณ์เชิงลบ",
        neutral: "อารมณ์เป็นกลาง",
        thankful: "ขอบคุณ",
        happy: "มีความสุข",
        complaining: "บ่น",
        angry: "โกรธ",
        post: "ด้านหน้า",
        negat: "ลบ",
        neut: "เป็นกลาง",
      },
      emotionOperators: { is: "พบ", not: "ไม่เป็นไปตาม" },
      propTypes: { system: "ระบบ", user: "ที่กำหนดเอง" },
      valueTypes: { text: "ข้อความ", number: "ตัวเลข" },
    },
    tasks: {
      allowCustomGrievance: "อนุญาตให้มีการอุทธรณ์ที่กำหนดเอง",
      businessSummary: "สรุปธุรกิจ",
      theDayBeforeYesterday: "วันก่อนเมื่อวานนี้",
      assessActionTypes: { reassess: "ประเมินใหม่" },
      scoreType: {
        aiScore: "การประเมินอัจฉริยะ",
        manualScore: "การประเมินด้วยตนเอง",
        interactiveDetection: "การตรวจจับแบบโต้ตอบ",
        smartRules: "กฎอัจฉริยะ",
        machineLearning: "โหมดการเรียนรู้ด้วยตนเอง",
      },
      inspectType: { inspectTrue: "ตรวจสอบ", inspectFalse: "ไม่ได้ตรวจสอบ" },
      operatorType: {
        keyword: "การจับคู่คำหลัก",
        notKeyword: "คำหลักไม่ตรงกัน",
      },
      applyRole: { all: "ทั้งหมด", customer: "ลูกค้า", agent: "บริการลูกค้า" },
      applyScope: {
        all: "ทั้งหมด",
        specific: "ประโยคที่ระบุ",
        scope: "ระบุช่วง",
      },
      judgeStrategy: {
        all: "ตรงตามเงื่อนไขทั้งหมด",
        arbitrarily: "ตรงตามเงื่อนไขใดๆ",
        custom: "ตรรกะที่กำหนดเอง",
      },
      checkPointStatus: {
        waitFor: "กำลังรอการตรวจสอบ",
        notDeductScore: "ไม่มีการหักคะแนน",
        deductScore: "หักเงิน",
      },
      applyOptions: { hit: "เงื่อนไขฮิต", notHit: "ไม่มีเงื่อนไขที่ระบุ" },
      gradeTypes: {
        yesOrNo: "พบหรือไม่",
        input: "การป้อนข้อมูลด้วยตนเอง",
        level: "การประเมินไฟล์",
        multipleFactors: "หลายองค์ประกอบ",
      },
      gradeChooseHits: { yes: "ใช่", no: "ไม่ใช่" },
      gradeChangeTypes: {
        edit: "บรรณาธิการ",
        check: "รีวิว",
        file: "เก็บถาวร",
        remark: "หมายเหตุ",
        complain: "การอุทธรณ์",
        review: "รีวิว",
        reviewEvaluation: "รีวิวการประเมินผล",
        sample: "การสุ่มตัวอย่าง",
        sampleEvaluation: "การประเมินการสุ่มตัวอย่าง",
        sampleSubmit: "การส่งแบบสุ่ม",
        sampleAssign: "การสุ่มตรวจ",
        reviewAssign: "การจัดสรรการตรวจสอบ",
        read: "อ่านแล้ว",
        reviewConfirm: "ยืนยันการตรวจสอบ",
        sampleConfirm: "การยืนยันการสุ่มตัวอย่าง",
      },
      gradeStatus: {
        unread: "ยังไม่ได้อ่าน",
        alreadyReview: "ตรวจสอบแล้ว",
        alreadyFild: "ยื่นแล้ว",
        waitReview: "ที่จะตรวจสอบ",
        alreadyRead: "อ่านแล้ว",
      },
      samplingFrequencyTypes: {
        everyDay: "ทุกวัน",
        everyWeek: "ทุกสัปดาห์",
        everyMonth: "รายเดือน",
      },
      samplingEveryDayTimeTypes: { yesterday: "เมื่อวานนี้", today: "วันนี้" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "สัปดาห์นี้",
        lastWeek: "สัปดาห์ที่แล้ว",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "เดือนนี้",
        lastMonth: "เดือนที่แล้ว",
      },
      samplingTypes: {
        random: "การสุ่มตัวอย่างแบบสุ่ม",
        proportional: "การสุ่มตัวอย่างตามสัดส่วน",
      },
      weekdays: {
        Monday: "วันจันทร์",
        Tuesday: "วันอังคาร",
        Wednesday: "วันพุธ",
        Thursday: "วันพฤหัสบดี",
        Friday: "วันศุกร์",
        Saturday: "วันเสาร์",
        Sunday: "วันอาทิตย์",
      },
      distributeTypes: {
        rule: "การจัดสรรลำดับความสำคัญของกฎ",
        average: "การจัดสรรแบบสุ่มโดยเฉลี่ย",
      },
      notMatchAnyRuleTypes: {
        random: "การกระจายเฉลี่ยแบบสุ่ม",
        assign: "มอบหมายให้บุคคลที่กำหนด",
        ignore: "ไม่จัดสรร",
      },
      inspectorTypes: {
        all: "ผู้ตรวจสอบคุณภาพทั้งหมด",
        some: "กำหนดผู้ตรวจสอบคุณภาพ",
      },
      pushFrequencyTypes: {
        everyDay: "ทุกวัน",
        everyWeek: "ทุกสัปดาห์",
        everyMonth: "รายเดือน",
        inspectionUpdate: "การปรับปรุงการตรวจสอบคุณภาพ",
        timelyInspection: "การตรวจสอบคุณภาพแบบเรียลไทม์",
      },
      pushObjectTypes: {
        email: "กล่องจดหมายที่กำหนด",
        staff: "ฝ่ายบริการลูกค้า",
      },
      reviewOptions: {
        reviewResult: "แสดงผลการตรวจสอบคุณภาพระหว่างการตรวจสอบ",
        reviewRecord: "แสดงบันทึกที่เกี่ยวข้องระหว่างการตรวจสอบ",
        reviewAgentHidden: "ซ่อนข้อมูลการบริการลูกค้าระหว่างการตรวจสอบ",
        samplingResult: "แสดงผลการตรวจสอบคุณภาพระหว่างการสุ่มตรวจ",
        samplingRecord: "แสดงบันทึกที่เกี่ยวข้องระหว่างการสุ่มตรวจ",
        sampleAgentHidden: "ซ่อนข้อมูลการบริการลูกค้าระหว่างการสุ่มตรวจ",
        checkExportAddCallInfoIphone: "แนบประวัติการโทรเมื่อส่งออกรายการ",
        sampleExportAddCallInfoIphone: "แนบประวัติการโทรเมื่อส่งออกรายการ",
        checkExportAddCallInfoText: "แนบบันทึกการสนทนาเมื่อส่งออกรายการ",
        sampleExportAddCallInfoText: "แนบบันทึกการสนทนาเมื่อส่งออกรายการ",
        inspectionExportAddCallInfoIphone:
          "แนบบันทึกการโทรเมื่อส่งออกรายการ (สามารถส่งออกได้สูงสุด10,000รายการในแต่ละครั้ง)",
        inspectionExportAddCallInfoText:
          "แนบบันทึกการสนทนาเมื่อส่งออกรายการ (สามารถส่งออกได้สูงสุด10,000รายการในแต่ละครั้ง)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "อนุญาตให้ดำเนินการตรวจสอบแบบสุ่มได้อย่างรวดเร็วในรายละเอียดการตรวจสอบคุณภาพ",
        fastSampleInSampleDetail:
          "อนุญาตให้ดำเนินการสุ่มตรวจได้อย่างรวดเร็วในรายละเอียดการสุ่มตรวจ",
        fastSampleInReviewDetail:
          "อนุญาตให้ดำเนินการตรวจสอบแบบสุ่มได้อย่างรวดเร็วในรายละเอียดการตรวจสอบ",
      },
      permitCustomReasonList: {
        permitCustomReason: "เหตุผลในการอนุญาตให้กำหนดเอง",
      },
      autoMateTasks: {
        autoLoad: "เพิ่มอัตโนมัติ",
        sample: "การสุ่มตัวอย่างอัตโนมัติ",
        assign: "การกระจายอัตโนมัติ",
        pushes: "ผลักดันอัตโนมัติ",
        assess: "การประเมินอัตโนมัติ",
        check: "การตรวจสอบการอุทธรณ์อีกครั้ง",
        case: "การซิงโครไนซ์ไลบรารีกรณี",
      },
      qualityCheckDetailTypes: {
        review: "รีวิว",
        spotCheck: "การสุ่มตัวอย่าง",
      },
      appealTypes: {
        transliterationError: "ข้อผิดพลาดในการถอดเสียง",
        discriminationError: "การตัดสินผิด",
        otherError: "ข้อผิดพลาดอื่นๆ",
      },
      inspectDataSources: {
        voiceCall: "การโทรด้วยเสียง",
        textDialogue: "บทสนทนาข้อความ",
        realTimeVoiceCall: "การโทรด้วยเสียงแบบเรียลไทม์",
        realTimeTextDialogue: "การสนทนาข้อความแบบเรียลไทม์",
      },
      pointTypes: {
        automatic: "การประเมินอัตโนมัติ",
        manual: "การประเมินด้วยตนเอง",
        interactiveDetection: "การตรวจจับแบบโต้ตอบ",
      },
      pointGradeTypes: {
        radio: "ตัวเลือกเดียว",
        input: "อินพุต",
        select: "เลือก",
      },
      sampleAndReviewStatus: {
        init: "ไม่สกัด",
        unread: "ยังไม่ได้อ่าน",
        alreadyRead: "อ่านแล้ว",
        complaining: "อุทธรณ์",
        reviewed: "ตรวจสอบแล้ว",
        sampling: "สกัดโดยไม่ตรวจสอบ",
        samplingCompleted: "การสุ่มตัวอย่าง",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "ละเว้นการล้นเกิน",
        automaticWeightCalculation: "การบัญชีน้ำหนักอัตโนมัติ",
      },
      calculateScoreTypes: { add: "คะแนนพิเศษ", sub: "คะแนนลดลง" },
      naChecked: { check: "รายการนี้ไม่เกี่ยวข้องกับการทดสอบที่อนุญาต" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "ให้คะแนนตามสัดส่วน",
        allRight: "ให้คะแนนถูกต้องทั้งหมด",
        anyRight: "คะแนนที่ถูกต้องโดยพลการ",
      },
      noHitQuestionResults: {
        true: 'ผลที่ได้คือ "ใช่"',
        false: 'ผลลัพธ์คือ "ไม่"',
      },
      repetRules: {
        same: "เนื้อหาเหมือนกันทุกประการ",
        similar: "ความหมายคล้ายกัน",
      },
      relateConditions: {
        highlightQuestion: "ปัญหาไฮไลต์",
        highlightAnswer: "เน้นคำตอบ",
        hightlightFoctors: "องค์ประกอบไฮไลท์",
      },
      interactiveQuestionTypes: {
        question: "ตรวจพบปัญหา",
        eliminateQuestion: "ตรวจพบการแก้ไขปัญหา",
      },
      interactiveAnswerTypes: {
        noAnswer: "ไม่พบคำตอบ",
        answer: "ตรวจพบคำตอบ",
        wrongAnswer: "ตรวจพบคำตอบที่ผิด",
      },
      inspectionUpdateTypes: {
        autoInspection: "การตรวจสอบคุณภาพอัตโนมัติ",
        autoInspectionWechat: "การวิเคราะห์อัตโนมัติ",
        artificialSampling: "การสุ่มตัวอย่างด้วยตนเอง",
        artificialReview: "การตรวจสอบด้วยตนเอง",
        manualModification: "การสุ่มตัวอย่างอย่างรวดเร็ว",
        timelyInspection: "การตรวจสอบคุณภาพแบบเรียลไทม์",
        samplingAssign: "การสุ่มตรวจ",
        artificialComplain: "ยื่นเรื่องร้องเรียน",
        fastSampling: "การส่งแบบสุ่มอย่างรวดเร็ว",
        recheckInspection: "ตรวจสอบคุณภาพใหม่",
        recheckInspectionWechat: "การวิเคราะห์ใหม่",
      },
      timelyInspectionTypes: {
        timelyInspection: "การตรวจสอบคุณภาพแบบเรียลไทม์",
      },
      pushActionTypes: { email: "จดหมาย", interfacePush: "กดอินเทอร์เฟซ" },
      pushDetailWays: {
        riskAlert: "เตือนความเสี่ยง",
        subscribe: "สมัครรับข้อความ",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "ข้อความการโทร/การสนทนา",
        inspectionResults: "ผลการตรวจสอบคุณภาพ",
      },
      subscribePushDataTypes: {
        dialogueMessage: "ข้อความการโทร/การสนทนา",
        textContent: "เนื้อหาข้อความสนทนา/การสนทนา",
        inspectionResults: "ผลการตรวจสอบคุณภาพ",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "คะแนนเฉลี่ยในกลุ่ม",
        addOrSubtract: "บวกและลบคะแนนโดยตรง",
        noChange: "ไม่มีคะแนนบวกหรือลบ",
      },
      defaultScore: {
        empty: "ว่างเปล่า",
        add: "คะแนนพิเศษ",
        notAdd: "ไม่มีคะแนนพิเศษ",
        sub: "คะแนนลดลง",
        notSub: "ไม่ลดคะแนน",
        na: "N/A",
        input: "การป้อนข้อมูลด้วยตนเอง",
        choose: "เลือก",
      },
      strategyTypes: {
        hitAdd: "ตีคะแนนพิเศษ",
        hitNotAdd: "ตีโดยไม่มีคะแนนพิเศษ",
        hitSub: "ตีคะแนนลดลง",
        hitNotSub: "ตีโดยไม่เสียคะแนน",
      },
    },
    download: {
      exportStatus: {
        prepare: "กำลังเตรียมการ",
        process: "กำลังดำเนินการอยู่",
        faild: "การส่งออกล้มเหลว",
        success: "ประสบความสำเร็จ",
      },
    },
    messageType: {
      all: "ทั้งหมด",
      monitor: "การตรวจสอบทันที",
      trigger: "ทริกเกอร์การแจ้งเตือน",
      timing: "รายงานการกำหนดเวลา",
    },
    messagePushType: {
      all: "ทั้งหมด",
      system: "ข้อความระบบ",
      sdk: "ข้อความ SDK",
      push: "กดอินเทอร์เฟซ",
      email: "จดหมาย",
      udesk: "การแจ้งเตือน udesk",
      udeskWhite: "การแจ้งเตือนระบบบริการลูกค้า",
      weChat: "ไมโครนิวส์",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "มอบหมายผู้ตรวจสอบการสุ่มตัวอย่าง",
      sampleInspector: "ผู้ตรวจสอบการสุ่มตัวอย่าง",
      assignReviewer: "มอบหมายผู้ตรวจสอบ",
      reviewer: "ผู้ตรวจสอบ",
      taskInspector: "ผู้ตรวจสอบคุณภาพงาน",
    },
    sdkPushType: {
      webHook: "Webhook มาตรฐาน",
      dingDing: "หุ่นยนต์เล็บ",
      weChat: "หุ่นยนต์ WeChat ขององค์กร",
      customer: "เนื้อหา Json ที่กำหนดเอง",
    },
    inspectionRemarksType: {
      inspection: "การตรวจสอบคุณภาพ",
      check: "การสุ่มตัวอย่าง",
      review: "รีวิว",
    },
    segmentationStrategyType: {
      count: "จำนวนข้อความ",
      spacing: "ช่วงเวลาข้อความ",
    },
    segmentationStrategy: { byTime: "แบ่งเวลา", customize: "แบ่งการรวมกัน" },
    inspectType: {
      commonInspect: "การตรวจสอบคุณภาพตามปกติ",
      associateInspect: "การตรวจสอบคุณภาพที่เกี่ยวข้อง",
    },
    typoDetectionOperators: { less: "น้อยกว่า", greater: "มากกว่า" },
    autoAddTypes: { realTimeAdd: "เพิ่มในเวลาจริง", timingAdd: "เพิ่มเวลา" },
    messageTypes: { text: "ข้อความข้อความ", markdown: "ข้อความ Markdown" },
    inServiceStatus: {
      activated: "เปิดใช้งานแล้ว",
      disabled: "ปิดใช้งาน",
      inactivated: "ไม่ได้เปิดใช้งาน",
      resigned: "ออกจากธุรกิจ",
    },
    roles: { agent: "พนักงาน", customer: "ลูกค้า" },
    commentVisibleAuthority: {
      all: "ทุกคนสามารถมองเห็นได้",
      self: "มองเห็นได้ด้วยตัวเองเท่านั้น",
      designation: "บุคคลที่ระบุสามารถมองเห็นได้",
    },
    appealCheckingTask: {
      eCommerceReview: "รีวิวอีคอมเมิร์ซ",
      socialMediaComments: "รีวิวโซเชียลมีเดีย",
      largeModel: "รุ่นใหญ่",
      customModel: "รูปแบบที่กำหนดเอง",
      universalModel: "โมเดลสากล",
      enterpriseWeChatText: "ไมโครข้อความ",
      generalQualityInspection: "การตรวจสอบคุณภาพทั่วไป",
      processQualityInspection: "การตรวจสอบคุณภาพกระบวนการ",
      voiceCopy: "รีเพลย์เสียง",
      beEqualTo: "เท่ากับ",
      notEqualTo: "ไม่เท่ากับ",
      canAppeal: "สามารถอุทธรณ์ได้",
      noAppeal: "ไม่สามารถอุทธรณ์ได้",
    },
    wechat: {
      redEnvelopes: "ซองอั่งเปา",
      text: "ข้อความ",
      picture: "รูปภาพ",
      voice: "เสียง",
      voiceCall: "การโทรด้วยเสียง",
      video: "วิดีโอ",
      businessCard: "นามบัตร",
      position: "ตำแหน่ง",
      expression: "อีโมติคอน",
      link: "การเชื่อมโยง",
      applet: "โปรแกรมขนาดเล็ก",
      chatRecord: "ประวัติการแชท",
      mixedMessage: "ข้อความผสม",
      file: "เอกสาร",
    },
    gong: {
      sessionActivity: "กิจกรรมการสนทนา",
      conversationAction: "การดำเนินการเซสชัน",
      riskSemantics: "ความหมายของความเสี่ยง",
      daysNotFollowedUp: "จำนวนวันที่ไม่ได้ติดตาม",
      waysideData: "ข้อมูลถนน",
      notStarted: "ยังไม่เริ่ม",
      completed: "เสร็จเรียบร้อยแล้ว",
      haveInHand: "กำลังดำเนินการอยู่",
      unpublished: "ไม่ได้เผยแพร่",
      published: "โพสต์แล้ว",
      knowledgePoints: "ประเด็นความรู้",
      technologicalProcess: "กระบวนการ",
      notMarked: "ไม่มีเครื่องหมาย",
      inAnnotation: "ติดฉลาก",
      annotated: "ทำเครื่องหมายแล้ว",
    },
    qualityChecingTask: {
      appealCanceled: "การยกเลิกการอุทธรณ์",
      complaintExpired: "การอุทธรณ์หมดอายุ",
      incomingCall: "โทรเข้า",
      exhale: "หายใจออก",
      incomingCallthirdParty: "โทรเข้า (สามทาง)",
      incomingCallconsultation: "โทรเข้า (ปรึกษา)",
      incomingCallforcedInsertion: "สายเรียกเข้า (ปลั๊กบังคับ)",
      incomingCallmonitoring: "ขาเข้า (การตรวจสอบ)",
      incomingCalltransfer: "ขาเข้า (โอน)",
      incomingCallinterception: "ขาเข้า (สกัดกั้น)",
      incomingCalltransferToExternalLine: "สายเรียกเข้า (โอนสายนอก)",
      incomingCallthirdPartyExternalLine: "สายขาเข้า (สายภายนอกสามทาง)",
      incomingCallexternalLine: "สายเรียกเข้า (ปรึกษาสายนอก)",
      outgoingCallthirdParty: "โทรออก (สามทาง)",
      outgoingCalltransfer: "โทรออก (โอน)",
      outgoingCallforcedInsertion: "โทรออก (ปลั๊กบังคับ)",
      outgoingCallmonitoring: "โทรออก (การตรวจสอบ)",
      outgoingCallinterception: "โทรออก (สกัดกั้น)",
      outgoingCalltransferToExternalLine: "โทรออก (โอนสายนอก)",
      outgoingCallthreePartyExternalLine: "โทรออก (สายภายนอกสามทาง)",
      customerServiceAnswer: "ฝ่ายบริการลูกค้าตอบ",
      customerServiceMissed: "ฝ่ายบริการลูกค้าไม่รับ",
      customerAnswer: "ลูกค้าตอบ",
      customerMissed: "ลูกค้าไม่ได้รับ",
      theTelephoneIsBusy: "โทรศัพท์ไม่ว่าง",
      phoneOffline: "โทรศัพท์ออฟไลน์",
      customerQuickHanging: "แขวนความเร็วของลูกค้า",
      clientHangsUp: "เครื่องแขวนลูกค้า",
      queueTimeout: "คิวหมดเวลา",
      giveUpQueuing: "เลิกเข้าคิว",
      outsideLineAnswering: "สายนอกรับสาย",
      externalLineIsNotConnected: "สายนอกไม่ได้รับ",
      noQueueSelected: "ไม่ได้เลือกคิว",
      notEvaluated: "ไม่ได้แสดงความคิดเห็น",
      noEvaluationRequired: "ไม่ต้องประเมิน",
      queuingSucceeded: "ประสบความสำเร็จในการเข้าคิว",
      noCustomerServiceOnline: "ไม่มีบริการลูกค้าออนไลน์",
      customer: "ลูกค้า",
      customerService: "บริการลูกค้า",
      outsideLines: "สายนอก",
    },
    sessionActivity: {
      single: "เวลาสนทนาเฉลี่ยต่อครั้ง",
      all: "ปริมาณการโทรทั้งหมด",
      allTime: "ระยะเวลาการโทรทั้งหมด",
    },
    sessionAction: {
      salesProportion: "สัดส่วนการพูดในการขาย",
      salesLongest: "คำพูดขายที่ยาวที่สุดโดยเฉลี่ย",
      customerLongest: "ลูกค้าที่พูดได้นานที่สุดโดยเฉลี่ย",
      discussNumber: "จำนวนการอภิปรายเชิงลึก",
      frequency: "ความถี่ของคำถาม",
    },
    monitorCriteria: {
      all: "ด้านหน้าและด้านหลัง",
      before: "ด้านหน้า",
      after: "หลังจากที่",
    },
    monitorTimeCycle: { day: "วัน", week: "สัปดาห์", month: "เดือน" },
    monitorGroupTestType: {
      ab: "การเปรียบเทียบสองกลุ่ม",
      a: "กลุ่ม A",
      b: "กลุ่ม B",
    },
    fields: {
      department: "แผนก",
      customerTags: "แท็กของลูกค้า",
      member: "สมาชิก",
      time: "เวลา",
      cascade: "น้ำตก",
      anyMatch: "จับคู่โดยพลการ",
      keyWord: "คำสำคัญ",
      keyEvents: "เหตุการณ์สำคัญ",
    },
    semanticIntelligence: {
      staff: "พนักงาน",
      customer: "ลูกค้า",
      employeesAndCustomers: "พนักงานและลูกค้า",
      notStarted: "ยังไม่เริ่ม",
      haveInHand: "กำลังดำเนินการอยู่",
      completed: "เสร็จเรียบร้อยแล้ว",
      callRecording: "บันทึกการโทร",
      enterpriseWeChatCall: "การโทรไมโคร",
      enterpriseWeChatText: "ไมโครข้อความ",
      discourseMiningForObjectionHandling: "การขุดคำคัดค้าน",
      productSellingPointScriptsMining: "การขุดจุดขายสินค้า",
      conversationScenario: "ฉากสนทนา",
      topicOfConversation: "หัวข้อ",
      keyEvents: "เหตุการณ์สำคัญ",
      customerLabel: "แท็กของลูกค้า",
      includeAny: "ประกอบด้วยใดๆ",
      includeAll: "รวมทั้งหมด",
      and: "และ",
      or: "หรือ",
      question: "ประโยคคำถาม",
      nonInterrogativeSentence: "ประโยคที่ไม่ใช่คำถาม",
    },
    coach: {
      notPassed: "ล้มเหลว",
      analogTelephone: "โทรศัพท์อนาล็อก",
      enterScenarioInformation: "ป้อนข้อมูลสถานการณ์",
      generateDialogueFramework: "สร้างกรอบการสนทนา",
      generatePromptLanguage: "สร้างพรอมต์",
      aIGeneratedDialogue: "AI สร้างบทสนทนา",
      system: "ระบบ",
      custom: "ที่กำหนดเอง",
      flow: "กระบวนการ",
      script: "ทักษะการพูด",
      express: "การแสดงออก",
      emotion: "อารมณ์",
      rateAccordingToCourseConfiguration: "คะแนนตามการกำหนดค่าหลักสูตร",
      intelligentModelRating: "การให้คะแนนโมเดลอัจฉริยะ",
      allData: "ข้อมูลทั้งหมด",
      dataFromThisDepartment: "ข้อมูลแผนกนี้",
      multidepartmentalData: "ข้อมูลหลายแผนก",
      underReview: "อยู่ระหว่างการตรวจสอบ",
      no: "ไม่ใช่",
      yes: "ใช่",
      automaticRating: "การให้คะแนนอัตโนมัติ",
      auditRating: "คะแนนการตรวจสอบ",
      reRating: "ให้คะแนนอีกครั้ง",
      inAppeal: "อุทธรณ์",
      reviewed: "ตรวจสอบแล้ว",
      notViewed: "ยังไม่ได้ดู",
      viewed: "ดูแล้ว",
      uncorrected: "ไม่มีการแก้ไข",
      corrected: "แก้ไขแล้ว",
      practice: "การออกกำลังกาย",
      examination: "การสอบ",
      other: "อื่นๆ",
      notMeetingStandards: "ไม่เป็นไปตามมาตรฐาน",
      meetingStandards: "มาตรฐาน",
      excellent: "ยอดเยี่ยม",
      invalid: "ล้มเหลว",
      notStarted: "ยังไม่เริ่ม",
      haveInHand: "กำลังดำเนินการอยู่",
      ended: "สิ้นสุดแล้ว",
      completed: "เสร็จเรียบร้อยแล้ว",
      hangInTheAir: "ยังไม่เสร็จ",
      passed: "ผ่านไปแล้ว",
      voiceConversation: "การสนทนาด้วยเสียง",
      textDialogue: "บทสนทนาข้อความ",
      slidePresentation: "การนำเสนอสไลด์",
      unpublished: "ไม่ได้เผยแพร่",
      published: "โพสต์แล้ว",
      narration: "ผู้บรรยาย",
      studentSpeaking: "นักเรียนพูด",
      robotTalk: "หุ่นยนต์พูด",
      knowledgeBaseQA: "ถาม-ตอบฐานความรู้",
      slide: "สไลด์โชว์",
      negativeEmotions: "อารมณ์เชิงลบ",
      emotionallyNormal: "อารมณ์ปกติ",
      incompleteScript: "คำพูดไม่สมบูรณ์",
      completeScript: "คำพูดที่สมบูรณ์",
      normalSpeechSpeed: "ความเร็วในการพูดปกติ",
      speakTooFast: "พูดเร็วเกินไป",
      speakTooSlowly: "พูดช้าเกินไป",
      whole: "ทั้งหมด",
      singlePage: "หน้าเดียว",
      qA: "คำถามและคำตอบ",
      situationalDialogue: "บทสนทนาตามสถานการณ์",
      misses: "พลาด",
      hit: "ตี",
      sequentialQA: "คำถามและคำตอบตามลำดับ",
      randomQA: "คำถามและคำตอบแบบสุ่ม",
      mastered: "เข้าใจแล้ว",
      notMastered: "ไม่เชี่ยวชาญ",
    },
    algorithm: {
      salesEmpowerment: "การเพิ่มขีดความสามารถในการขาย",
      enterpriseWeChatVoice: "เสียง WeChat ขององค์กร",
      enterpriseWeChatText: "ข้อความ WeChat ขององค์กร",
      waiting: "กำลังรอ",
      extracting: "สกัด",
      success: "ประสบความสำเร็จ",
      fail: "ล้มเหลว",
      intelligentQualityInspection: "การตรวจสอบคุณภาพอัจฉริยะ",
      textDialogue: "บทสนทนาข้อความ",
      voiceCall: "การโทรด้วยเสียง",
      smartTags: "สมาร์ทแท็ก",
      satisfactionEvaluation: "การประเมินความพึงพอใจ",
      score: "การให้คะแนน",
      grade: "การจัดอันดับ",
      customerServiceTeam: "กลุ่มบริการลูกค้า",
      conversationAnalysis: "การวิเคราะห์เซสชัน",
    },
    operatorListGather: {
      businessSummary: "สรุปธุรกิจ",
      firstSentence: "ประโยคแรก",
      lastSentence: "ประโยคสุดท้าย",
      allTime: "เวลาทั้งหมด",
      nonBusinessWorkingHours: "ชั่วโมงการทำงานที่ไม่ใช่ธุรกิจ",
      businessWorkingHours: "ชั่วโมงการทำงานทางธุรกิจ",
    },
    callDialogueManage: {
      eCommerceReview: "รีวิวอีคอมเมิร์ซ",
      socialMediaComments: "รีวิวโซเชียลมีเดีย",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "เข้าสู่ระบบ",
        email: "กล่องจดหมาย",
        password: "รหัสผ่าน",
        forgetPwd: "ลืมรหัสผ่าน",
        emailCannotBeEmpty: "กล่องจดหมายต้องไม่ว่างเปล่า",
        passwordCannotByEmpty: "รหัสผ่านต้องไม่ว่างเปล่า",
        mustChangeLogin:
          "ขออภัยต้องรีเซ็ตรหัสผ่านของคุณโปรดติดต่อผู้ดูแลระบบเพื่อรีเซ็ตรหัสผ่าน",
        dismissionLogin: "ขออภัยคุณได้ออกไปแล้ว",
        forbiddenLogin: "ขออภัยบัญชีของคุณถูกปิดใช้งาน",
        loginError: "การเข้าสู่ระบบล้มเหลวอีเมลหรือรหัสผ่านไม่ถูกต้อง",
        loginPrompt: "เข้าสู่ระบบ",
        rememberMe: "จำฉันไว้",
      },
      logout: {
        logoutError:
          "ออกจากระบบล้มเหลวโปรดตรวจสอบการเชื่อมต่อเครือข่ายหรือลองอีกครั้งในภายหลัง",
      },
      resetPassword: {
        forgetPwd: "ลืมรหัสผ่าน",
        inputEmailPrompt:
          "ป้อนที่อยู่อีเมลบัญชีของคุณด้านล่างและเราจะส่งอีเมลและคำแนะนำในการรีเซ็ตรหัสผ่านให้คุณ",
        sendEmailSuccess:
          "จดหมายยืนยันการแก้ไขรหัสผ่านถูกส่งไปยังกล่องจดหมายที่คุณกรอกเรียบร้อยแล้วโปรดตรวจสอบให้ทันเวลา",
        sendEmailFailed:
          "การส่งอีเมลล้มเหลวโปรดตรวจสอบการเชื่อมต่อเครือข่ายหรือลองอีกครั้งในภายหลัง",
        setNewPassword: "ตั้งรหัสผ่านใหม่ของคุณ",
        passwordCannotEmpty: "รหัสผ่านต้องไม่ว่างเปล่า",
        passwordFormWrong: "รูปแบบรหัสผ่านไม่ถูกต้อง",
        confirmPasswordWrong: "รหัสผ่านที่ป้อนสองครั้งไม่เท่ากัน",
        passwordResetSuccess: "รีเซ็ตรหัสผ่านเรียบร้อยแล้ว",
        passwordResetFailed:
          "การรีเซ็ตรหัสผ่านล้มเหลวโปรดตรวจสอบการเชื่อมต่อเครือข่ายหรือลองอีกครั้งในภายหลัง",
        linkTimeover: "ลิงก์รีเซ็ตรหัสผ่านหมดอายุโปรดลองอีกครั้ง",
      },
      form: { title: "แสดงแบบฟอร์ม CASE" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "กล่องจดหมาย",
          emailFormatError: "รูปแบบอีเมลไม่ถูกต้องโปรดป้อนอีกครั้ง!",
          forgotPassword: "ลืมรหัสผ่าน?",
          describe:
            "ป้อนที่อยู่อีเมลบัญชีของคุณด้านล่างและเราจะส่งอีเมลและคำแนะนำในการรีเซ็ตรหัสผ่านให้คุณ",
          send: "ส่ง",
          emailCannotBeEmpty: "กล่องจดหมายต้องไม่ว่างเปล่า",
          resetPasswordExpire: "ลิงก์รีเซ็ตรหัสผ่านหมดอายุโปรดลองอีกครั้ง",
        },
        resetPassword: {
          setPassword: "ตั้งรหัสผ่านใหม่ของคุณ",
          newPassword: "รหัสผ่านใหม่",
          repeatPassword: "รหัสผ่านซ้ำ",
          resetPassword: "รีเซ็ตรหัสผ่าน",
          passwordCannotBeEmpty: "รหัสผ่านใหม่ไม่สามารถว่างเปล่า",
          confirmPasswordCannotBeEmpty: "รหัสผ่านซ้ำไม่สามารถว่างเปล่า",
          passwordsNotMatch: "รหัสผ่านไม่สอดคล้องกันสองครั้ง!",
          passwordFormWrong: "รหัสผ่านต้องไม่เป็นภาษาจีนและจำนวนคือ6-14หลัก!",
          linkExpiration: "ลิงก์รีเซ็ตรหัสผ่านหมดอายุโปรดลองอีกครั้ง",
        },
      },
      errors: { haveNoPermissionWarningText: "คุณไม่มีสิทธิ์ดูหน้านี้" },
    },
    downloadCenter: {
      name: "ศูนย์ดาวน์โหลด",
      saveTime: "(ค่าเริ่มต้นจะถูกบันทึกไว้เป็นเวลา30วัน)",
      headerTitle: "ดาวน์โหลดไฟล์",
      download: "ดาวน์โหลด",
      downloadPromp: "ส่งงานดาวน์โหลดแล้วโปรดไปที่",
      viewProgress: "ดูความคืบหน้า",
    },
    uploadCenter: { name: "ศูนย์อัปโหลด" },
    tasks: {
      index: {
        name: "ชื่อ",
        remark: "คำอธิบาย",
        qualityCheckTasksTitle: "โต๊ะทำงาน",
        informationCollection: "รวบรวมงาน",
        qualityCheckTasks: "งานตรวจสอบคุณภาพ",
        wechatQaTasks: "งานวิเคราะห์",
        moduleName: "ใช้เทมเพลตเพื่อสร้างใหม่",
        creatNewQualityCheckTask: "งานใหม่",
        generalInspectionList: "รายงานการตรวจสอบคุณภาพทั่วไป",
        editQualityCheckTask: "แก้ไขงานตรวจสอบคุณภาพ",
        creatNewQualityCheckTaskError: "งานตรวจสอบคุณภาพใหม่ล้มเหลว",
        editQualityCheckTaskError: "งานตรวจสอบคุณภาพการแก้ไขล้มเหลว",
        type: "ประเภทฟอร์ม",
        singleScore: "คะแนนเดี่ยว",
        singleScoreNullError: "คะแนนเดี่ยวต้องไม่ว่างเปล่า",
        inspector: "ผู้ตรวจสอบคุณภาพ",
        inspectDataSource: "แหล่งข้อมูล",
        taskName: "ชื่องาน",
        inspectionType: "ประเภทการตรวจสอบคุณภาพ",
      },
      manage: {
        screeningCondition: "เงื่อนไขการคัดกรอง",
        template: {
          condition: {
            index: {
              name: "เงื่อนไข",
              existingConditions: "เงื่อนไขที่มีอยู่",
            },
            detail: {
              id: "ID",
              name: "ชื่อเงื่อนไข",
              remark: "คำอธิบาย",
              checkRange: "ช่วงการตรวจสอบ",
              operator: "โอเปอเรเตอร์",
              operatorLogic: "ตรรกะของตัวดำเนินการ",
            },
          },
          checkPointTest: {
            title: "การทดสอบกฎ",
            running: "กฎในการทดสอบโปรดรอ...",
            clearSuccess: "บทสนทนาจำลองถูกล้างเรียบร้อยแล้ว",
            mock: "จำลองการสนทนา",
            save: "บันทึกการสนทนาที่เลือก",
            saveAll: "บันทึกการสนทนาทั้งหมด",
            saveSuccess: "บันทึกการสนทนาสำเร็จ",
            close: "ออก",
            delete: "ลบการเลือก",
            clear: "ว่างเปล่า",
            customer: "ลูกค้า",
            agent: "บริการลูกค้า",
            test: "การทดสอบ",
            request:
              "หลังจากล้างบทสนทนาจำลองแล้วจะไม่สามารถกู้คืนได้คุณยืนยันการล้างหรือไม่?",
            deletePopconfirmContent:
              "คุณยืนยันที่จะลบการสนทนาที่เลือกไว้หรือไม่?",
          },
          checkPoint: {
            index: {
              name: "การกำหนดค่ากฎ",
              wechatTitle: "การวิเคราะห์การกำหนดค่าโมเดล",
              existingCheckPoints: "มีกฎการตรวจสอบคุณภาพ",
              currentCheckPointScore: "คะแนนกฎการตรวจสอบคุณภาพปัจจุบันคือ",
              currentCheckPointScoreWechat:
                "คะแนนแบบจำลองการวิเคราะห์ปัจจุบันคือ",
              currentCheckPointWeight:
                "ผลรวมของน้ำหนักของกฎการตรวจสอบคุณภาพปัจจุบันคือ",
              weightTotalScoreAndItemScore: "คะแนนรวม: 100คะแนนคะแนนเดี่ยว:",
              template: "แม่แบบกฎการตรวจสอบคุณภาพ",
              import: "นำเข้าเทมเพลตกฎการตรวจสอบคุณภาพ",
              getTemplateError: "ความล้มเหลวในการรับเทมเพลตกฎการตรวจสอบคุณภาพ",
              tabs: { template: "แม่แบบ", demo: "ตัวอย่าง" },
              customize: "แม่แบบเปล่า",
              move: "ลากและวางเพื่อปรับการจัดหมวดหมู่และการเรียงลำดับ",
              classification: "การจำแนกประเภทการตรวจสอบคุณภาพ",
              classificationWeChat: "ประเภทโมเดล",
              first: "การจำแนกระดับแรก",
              second: "การจำแนกประเภทรอง",
              new: "สร้างกฎการตรวจสอบคุณภาพใหม่",
              weChatNew: "เพิ่มแบบจำลองการวิเคราะห์",
              createWeChat: "สร้างแบบจำลองการวิเคราะห์ใหม่",
              empty: "ไม่มีกฎการตรวจสอบคุณภาพ",
              wechatEmpty: "ไม่มีรูปแบบการวิเคราะห์",
              newSecond: "การจัดประเภทรองใหม่",
              newFirst: "การจัดประเภทระดับใหม่",
              checkPoint: "กฎการตรวจสอบคุณภาพ",
              checkPointWeChat: "แบบจำลองการวิเคราะห์",
            },
            detail: {
              name: "ชื่อ",
              remark: "คำอธิบาย",
              type: "ประเภท",
              deduction: "หักเงิน",
              rule: "กฎ",
              highlighted: "ไฮไลท์",
              gradeType: "วิธีการให้คะแนน",
              interactiveCategory: "การจำแนกแบบโต้ตอบ",
              predeterminedScore: "คะแนน",
              weight: "น้ำหนัก",
              na: "N/A",
              default: "คะแนนเริ่มต้น",
              preRule: "กฎเงื่อนไขก่อน",
              analysisHighlighted: "กฎการวิเคราะห์",
              categorySelectPrompt: "โปรดเลือกหมวดหมู่",
              checkHighlightArrayError:
                "กฎไม่ตรงกับไฮไลต์โปรดคลิกปุ่มแยกวิเคราะห์กฎอีกครั้ง",
              foctorsHolder: "โปรดเลือกองค์ประกอบ",
              foctorsErrorNone:
                "กฎหลายองค์ประกอบว่างเปล่าโปรดเพิ่มประเภทหลายองค์ประกอบ",
              naError: "ไม่สามารถปิดรายการได้เมื่อคะแนนเริ่มต้นคือ N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A หมายความว่าไม่สามารถปรับได้กำหนดเงื่อนไขล่วงหน้าสำหรับกฎการตรวจสอบคุณภาพนี้และทดสอบเมื่อเป็นไปตามเงื่อนไขหากไม่เป็นไปตามเงื่อนไขกฎจะใช้ไม่ได้และจะไม่ทดสอบกฎนี้\nคำแนะนำในการกรอกเงื่อนไขเบื้องต้น: "&&" หมายถึง "และ", "||" หมายถึง "หรือ", "!" หมายถึง "ไม่ใช่" กรอกตัวอย่าง "(R1 | R2) && R3"',
                applicationMessageType: "ปพลิเคชันประเภทข้อความ",
                selectAll: "เลือกทั้งหมด",
                highlightTags: "แท็กไฮไลต์:",
                ruleType: "ประเภทกฎ",
                dataSource: "แหล่งข้อมูล",
                whole: "ทั้งหมด",
                enterpriseMicroConversation: "เซสชันไมโครขององค์กร",
                enterpriseAndMicroCommunication: "การโทรไมโคร",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "โหนดการตัดสิน",
                    dialogueNode: "โหนดการสนทนา",
                    editNodes: "แก้ไขโหนด",
                  },
                  nodeConfig: {
                    nodeName: "ชื่อโหนด",
                    nodeRules: "กฎโหนด",
                    scoringLogic: "ตรรกะการให้คะแนน",
                    score: "คะแนน:",
                    rule: "กฎ",
                    multiBranchLogic: "ตรรกะหลายสาขา",
                    highlight: "ไฮไลท์",
                    submit: "ส่ง",
                  },
                  ruleList: {
                    branch: "สาขา",
                    branchName: "ชื่อสาขา:",
                    addRules: "เพิ่มกฎ",
                  },
                  sidebar: { node: "โหนด" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "จัดระเบียบอัตโนมัติ",
                      canvasAdaptation: "ผ้าใบปรับตัวได้",
                      copy: "คัดลอก",
                      delete: "ลบ",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "การตัดสินโหนดลูกของโหนดไม่สามารถใช้เป็นเป้าหมายการเชื่อมต่อได้",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "โหนดเริ่มต้นไม่สามารถใช้เป็นเป้าหมายการเชื่อมต่อ",
                      startingNodeCannotConnectMultipleNodes:
                        "โหนดเริ่มต้นไม่สามารถเชื่อมต่อหลายโหนด",
                      nodeCannotBeConnectedToItself:
                        "โหนดไม่สามารถเชื่อมต่อกับตัวเอง",
                      startNode: "เริ่มต้นโหนด",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "ยกเลิกการเลือกทั้งหมด",
                    selectAll: "เลือกทั้งหมด",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "พบหรือไม่",
                    gradedEvaluation: "การประเมินไฟล์",
                  },
                },
              },
            },
            configuration: {
              title: "กฎการตรวจสอบคุณภาพ",
              wechatTitle: "แบบจำลองการวิเคราะห์",
              neme: "ชื่อ",
              errorMessage: "การลบล้มเหลวต้องมีเงื่อนไขอย่างน้อยหนึ่งข้อ",
              desc: "คำอธิบาย",
              status: "สถานะ",
              yes: "มีประสิทธิภาพ",
              no: "ไม่ถูกต้อง",
              namePlaceHolder: "กรุณาใส่ชื่อฉลาก",
              desPlaceHolder: "กรุณาใส่คำอธิบาย",
              nameEmpty: "ชื่อต้องไม่ว่างเปล่า",
              desEmpty: "คำอธิบายต้องไม่ว่างเปล่า",
              type: "ประเภท",
              ruler: "กฎอัจฉริยะ",
              machineLearning: "โหมดการเรียนรู้ด้วยตนเอง",
              autoScore: "คะแนนอัจฉริยะ (เก่า)",
              manualScore: "การให้คะแนนด้วยตนเอง",
              interaction: "การตรวจจับแบบโต้ตอบ (เก่า)",
              typeTips: {
                model:
                  'โมเดลอัจฉริยะ: เป็นระบบตรวจสอบคุณภาพที่ตั้งไว้ล่วงหน้าไม่จำเป็นต้องมีการกำหนดค่าและการบำรุงรักษาและสามารถตรวจสอบปัญหาในบริการได้อย่างรวดเร็วโมเดลนี้เป็นแบบทั่วไปมากกว่าหากไม่ตรงตามความต้องการทางธุรกิจโปรดใช้ "กฎอัจฉริยะ" หรือ "การให้คะแนนด้วยตนเอง',
                rule: "กฎอัจฉริยะ: การให้คะแนนโดยอัตโนมัติตามกฎ AI",
                manual:
                  "การให้คะแนนด้วยตนเอง: การตรวจสอบคุณภาพด้วยตนเองการให้คะแนนด้วยตนเอง",
              },
              model: "รุ่น:",
              ruleType: {
                words: "ทักษะการพูด",
                interaction: "การโต้ตอบ",
                feature: "ลักษณะ",
                information: "ข้อมูล",
              },
              testingMode: {
                all: "ตรวจสอบคำหลักทั้งหมด",
                any: "ตรวจจับคำหลักใดๆ",
              },
              operatorTypesOfInteraction: {
                question: "คำถามและคำตอบ",
                element: "คำตอบองค์ประกอบ",
                repet: "การทำซ้ำตามบริบท",
                snatch: "คว้าคำพูด",
                silent: "เงียบ",
              },
              role: "บทบาทการตรวจจับ:",
              preposition: "ตัวดำเนินการด้านหน้า:",
              range: "ช่วงการตรวจสอบ:",
              from: "ลำดับที่",
              to: "ประโยคถึง",
              end: "ประโยค",
              hasOldSilentTip:
                'สวัสดีเพื่อปรับปรุงความแม่นยำ "Smart Rules-Interaction-Silence" ได้รับการอัปเกรดเป็น "Response หมดเวลา" ขอแนะนำให้อัปเดตกฎการใช้งาน!',
              changeToOld: "เปลี่ยนไปใช้เวอร์ชันเก่า",
              changeToNew: "เปลี่ยนไปใช้เวอร์ชันใหม่",
              logic: {
                logic: "ตรรกะการให้คะแนน",
                naTip:
                  "N/A หมายความว่าไม่สามารถปรับได้กำหนดเงื่อนไขล่วงหน้าสำหรับกฎการตรวจสอบคุณภาพนี้และทดสอบเมื่อตรงตามเงื่อนไขหากไม่ตรงตามเงื่อนไขกฎจะใช้ไม่ได้และจะไม่ทดสอบกฎ",
                score: "คะแนน:",
                na: "N/A：",
                naDes: "รายการนี้ไม่เกี่ยวข้องกับการทดสอบที่อนุญาต",
                preRule: "ก่อนเงื่อนไขกฎ:",
                notCatchPreRule: "เมื่อไม่เป็นไปตามเงื่อนไขเบื้องต้น:",
                naChecked: "เมื่อตี N/A:",
                preRuleEmpty: "กฎเงื่อนไขเบื้องต้นต้องไม่ว่างเปล่า",
                operatorEmpty:
                  "เงื่อนไขที่จำเป็นในตัวดำเนินการต้องไม่ว่างเปล่า",
                multipleEmpty:
                  "เงื่อนไขที่จำเป็นในการให้คะแนนหลายตรรกะต้องไม่ว่างเปล่า",
                average: "คะแนนเฉลี่ยในกลุ่ม",
                addOrSubtract: "บวกและลบคะแนนโดยตรง",
                noChange: "ไม่มีคะแนนบวกหรือลบ",
                rule: "กฎ:",
                all: "พบกับตัวดำเนินการทั้งหมด",
                any: "พบกับตัวดำเนินการใดๆ",
                customize: "ตรรกะตัวดำเนินการที่กำหนดเอง",
                multiple: "การให้คะแนนแบบหลายตรรกะ",
                heightLight: "ไฮไลท์:",
                machineLearningTip:
                  "ขณะนี้โหมดการเรียนรู้ด้วยตนเองมีผลเฉพาะสำหรับการพูดประโยคเดียวโปรดใช้กฎอัจฉริยะสำหรับการพูดที่ซับซ้อน",
                strategy: "กลยุทธ์:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "ส่งการอนุมัติเรียบร้อยแล้ว",
            },
            components: {
              pageHeaderExtra: {
                test: "การทดสอบ",
                formal: "อย่างเป็นทางการ",
                submit: "ส่ง",
              },
              createModal: { index: { myRules: "กฎของฉัน" } },
            },
          },
          automaticAdd: {
            index: {
              name: "กระบวนการอัตโนมัติ",
              screeningCondition: "เงื่อนไขการคัดกรอง",
              aiScreeningCondition: "เงื่อนไขการคัดกรองอัจฉริยะ",
              rule: "กฎ",
              automaticAddition: "เพิ่มอัตโนมัติ",
              automaticAdditionTip:
                "เพิ่มข้อมูลลงในงานโดยอัตโนมัติแบบเรียลไทม์หรือเป็นประจำสำหรับการตรวจสอบคุณภาพ AI",
              autoAdd: {
                autoAddType: "วิธีการเพิ่มอัตโนมัติ",
                addFrequency: "เพิ่มความถี่",
                add: "เพิ่ม",
              },
              automaticSamplingInspection: "การสุ่มตัวอย่างอัตโนมัติ",
              automaticSamplingInspectionTip:
                "ดึงข้อมูลที่ต้องมีการตรวจสอบคุณภาพด้วยตนเองโดยอัตโนมัติตัวอย่างเช่นดึงข้อมูล10% ของข้อมูลที่สุ่มเมื่อวานนี้ทุกวันสำหรับการสุ่มตรวจด้วยตนเอง",
              everyAgentCheckLimit: "ขีดจำกัดสูงสุดต่อฝ่ายบริการลูกค้า",
              automaticAllocation: "การกระจายอัตโนมัติ",
              automaticAllocationTip:
                "การจัดสรรการสุ่มตัวอย่าง: ข้อมูลที่ดึงออกมาจะถูกจัดสรรให้กับผู้ตรวจสอบคุณภาพโดยอัตโนมัติตัวอย่างเช่น10% ของข้อมูลที่สุ่มเมื่อวานจะถูกสุ่มเลือกทุกวันสำหรับการสุ่มตรวจด้วยตนเองและผู้ตรวจสอบคุณภาพโดยเฉลี่ย2คนในงานนี้จะถูกสุ่ม",
              automaticAllocationTip2:
                "การจัดสรรการตรวจสอบ: ข้อมูลการอุทธรณ์จะถูกจัดสรรโดยอัตโนมัติให้กับผู้ตรวจสอบคุณภาพเพื่อตรวจสอบตัวอย่างเช่นข้อมูลการอุทธรณ์รายวันจะถูกสุ่มให้กับผู้ตรวจสอบคุณภาพสองคนในงานนี้เพื่อตรวจสอบ",
              automaticPush: "ผลักดันอัตโนมัติ",
              automaticPushTip:
                "ทริกเกอร์การแจ้งเตือน: การแจ้งเตือน/การแจ้งเตือนจะดำเนินการในระหว่างการตรวจสอบคุณภาพตัวอย่างเช่นเมื่อฝ่ายบริการลูกค้ามีข้อผิดพลาดร้ายแรงให้ส่งข้อความแจ้งเตือนไปยังกลุ่มไมโครขององค์กร",
              automaticPushTip2:
                "รายงานประจำ: ส่งผลการตรวจสอบคุณภาพไปยังรายงานประจำทางอีเมลตัวอย่างเช่นผลการตรวจสอบคุณภาพของการตรวจสอบการสุ่มตัวอย่างด้วยตนเองเมื่อวานนี้จะถูกส่งไปยังกล่องจดหมายของหัวหน้างานบริการลูกค้าเวลา9.00น. ของทุกวัน",
              automaticAssess: "การประเมินอัตโนมัติ",
              automaticAssessTip:
                "เปรียบเทียบผลการสุ่มตัวอย่างด้วยตนเองกับผลการตรวจสอบคุณภาพ AI เป็นประจำเพื่อประเมินความแม่นยำตัวอย่างเช่นเวลา8:00น. ของทุกวันจะมีการประเมินความถูกต้องของข้อมูลที่สุ่มตัวอย่างเมื่อวานนี้",
              reexaminationComplaint: "การตรวจสอบการอุทธรณ์อีกครั้ง",
              spoktReviewDetail: "การกำหนดค่าธุรกิจ",
              spoktReviewDetailTip:
                "กำหนดค่าฟังก์ชันต่างๆเช่นรายการตรวจสอบคุณภาพรายการตรวจสอบแบบสุ่มบริการอุทธรณ์รายการตรวจสอบและหมายเหตุ",
              qaDetailAction: "การกำหนดค่าการตรวจสอบคุณภาพ",
              spoktDetailAction: "การกำหนดค่าการสุ่มตัวอย่าง",
              reviewDetailAction: "ตรวจสอบการกำหนดค่า",
              appealAction: "การกำหนดค่าการอุทธรณ์",
              remarkTemplate: "แม่แบบหมายเหตุ",
              automaticArchiving: "เก็บถาวรอัตโนมัติ",
              conditionalRules: "กฎเงื่อนไข",
              qualityInspectionConditions: "เงื่อนไขการตรวจสอบคุณภาพ",
              unopened: "ยังไม่เปิด",
              sampleInspectionDistributionModel: "การสุ่มตรวจ",
              reviewAllocationModel: "การจัดสรรการตรวจสอบ",
              distributionModelTitle: "กลยุทธ์การกระจาย:",
              pushRules: "กฎการผลักดัน",
              push: "ดัน",
              assessRules: "กฎการประเมิน",
              on: "on",
              off: "off",
              updateStatusSuccess: "เปลี่ยนสถานะสำเร็จ!",
              updateStatusFailed:
                "การเปลี่ยนแปลงสถานะล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
              sampleNumberOrPercent: "จำนวนตัวอย่างหรือสัดส่วน",
              inspectorList: "กำหนดผู้ตรวจสอบคุณภาพ",
              numberOfSubmission: "จำนวนครั้งที่อนุญาตให้ส่งการอุทธรณ์",
              rewivewDays: "วัน",
              TimeOfSubmission: "ระยะเวลาที่ถูกต้องของการส่งการอุทธรณ์",
              rewivewSecond: "ครั้ง",
              switchOn: "เปิด",
              switchOff: "ปิด",
              notMatchAnyRule: "เมื่อไม่ตรงกับกฎโดยพลการ:",
              appealOptionsList: {
                hiddenInspector: "อุทธรณ์เพื่อซ่อนข้อมูลของผู้ตรวจสอบคุณภาพ",
                hiddenHit: "หน้าอุทธรณ์รองรับการเน้นการตีตำแหน่ง",
              },
              caseLibrary: "การซิงโครไนซ์ไลบรารีกรณี",
              caseLibrarySyncTip:
                "ซิงโครไนซ์กรณีที่เพิ่มในระบบ QA ไปยังระบบบริการลูกค้า",
              addCase: "เพิ่มการซิงค์",
              caseLibraryId: "ห้องสมุดกรณี",
              caseLibraryIdTip: "การจัดกลุ่มไลบรารีกรณีของระบบ QA",
              udeskCaseLibraryIdTip: "การจัดกลุ่มกรณีของระบบบริการลูกค้า",
              syncHis: "กรณีประวัติซิงโครไนซ์",
              otherSettings: "การตั้งค่าอื่นๆ",
              udeskCaseLibraryId: "กลุ่มกรณีผลักดัน",
              url: "ผลักดัน URL",
              urlTip:
                "โปรดป้อนที่อยู่อินเตอร์เฟซที่ขึ้นต้นด้วย http:// หรือ https://",
            },
            templateNew: {
              secondaryReview: "รีวิวรอง",
              numberOfAppealsAllowedToBeSubmitted:
                "จำนวนครั้งที่อนุญาตให้ยื่นอุทธรณ์",
              validityOfSubmissionOfAppeal:
                "ระยะเวลาที่ถูกต้องของการยื่นอุทธรณ์",
              validityOfAppealReview:
                "ระยะเวลาที่ถูกต้องของการตรวจสอบการอุทธรณ์",
              hours: "ชั่วโมง",
              businessSummary: "สรุปธุรกิจ",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "ขีด จำกัด ล่างของการจับฉลากต่อฝ่ายบริการลูกค้า",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "สร้างโดยอัตโนมัติสรุปธุรกิจโดยโมเดลขนาดใหญ่มิติการสร้างที่กำหนดเองตามสถานการณ์ทางธุรกิจ",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "กลยุทธ์การจัดสรรผู้ตรวจสอบระดับแรกเท่านั้น",
              secondaryCompounding: "รองคอมโพสิต:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "ผู้ตรวจสอบระดับสองสนับสนุนการกระจายอย่างเท่าเทียมกันเท่านั้น",
              secondLevelReviewer: "ผู้ตรวจสอบระดับสอง:",
              samplingInspectionTarget: "เป้าหมายการสุ่มตัวอย่าง",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "กำหนดเป้าหมายที่ควรทำให้เสร็จสมบูรณ์สำหรับการตรวจสอบแบบสุ่มด้วยตนเองและแจ้งเตือนเมื่อไม่ถึงหรือเกินเป้าหมายตัวอย่างเช่นฝ่ายบริการลูกค้าแต่ละคนควรทำการตรวจสอบแบบสุ่ม100ครั้งต่อเดือนเมื่อน้อยกว่า80หน้าการตรวจสอบคุณภาพและการตรวจสอบแบบสุ่มจะแจ้งให้ทราบ",
            },
            component: {
              verificationFailed: "การตรวจสอบล้มเหลว",
              pleaseAddASecondLevelQualityInspector:
                "โปรดเพิ่มผู้ตรวจสอบคุณภาพระดับสอง",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "คำใบ้",
                    fieldName: "ชื่อฟิลด์",
                    fieldDescription: "คำอธิบายฟิลด์",
                    generateContent: "สร้างเนื้อหา",
                    enabled: "เปิดใช้งานหรือไม่",
                    generationMethod: "วิธีการสร้าง",
                    automaticGeneration: "สร้างโดยอัตโนมัติ",
                    manualTrigger: "ทริกเกอร์ด้วยตนเอง",
                    filterCondition: "เงื่อนไขการคัดกรอง",
                    return: "กลับ",
                    preserve: "บันทึก",
                  },
                  components: {
                    training: { index: { generateResults: "สร้างผลลัพธ์" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "ไม่จำกัด",
                    limitedScope: "จำกัดช่วง",
                    add: "เพิ่ม",
                    thereIsCurrentlyNoDataAvailable: "ไม่มีข้อมูล",
                  },
                },
              },
              index: {
                fieldName: "ชื่อฟิลด์",
                fieldDescription: "คำอธิบายฟิลด์",
                nothing: "ไม่มี",
                generationMethod: "วิธีการสร้าง",
                generateManually: "การสร้างด้วยตนเอง",
                automaticGeneration: "สร้างโดยอัตโนมัติ",
                generateContent: "สร้างเนื้อหา",
                notQualified: "ไม่จำกัด",
                filterCondition: "เงื่อนไขการคัดกรอง",
                enabled: "เปิดใช้งานหรือไม่",
                type: "ประเภท",
                system: "ระบบ",
                custom: "ที่กำหนดเอง",
                operation: "การดำเนินงาน",
                edit: "บรรณาธิการ",
                areYouSureToDeleteIt: "แน่ใจว่าจะลบ?",
                delete: "ลบ",
                addField: "เพิ่มฟิลด์",
              },
            },
          },
          roleConfig: {
            index: {
              name: "การกำหนดค่าบทบาท",
              roleConfigTip:
                "เมื่อข้อมูลการโทรที่นำเข้าเป็นโมโนกฎการระบุการกำหนดค่าจะถูกแยกและประมวลผลสำหรับผู้โทรบทบาทของการบริการลูกค้าที่สอดคล้องกับกฎ",
              operator: "โอเปอเรเตอร์",
              operatorLogic: "ตรรกะของตัวดำเนินการ",
            },
          },
          manualAdd: {
            index: {
              name: "เพิ่มด้วยตนเอง",
              tip: "ใช้เพื่อกรองและดูตัวอย่างข้อมูลผ่านเงื่อนไขการกำหนดค่าด้วยตนเองและเพิ่มลงในงานตรวจสอบคุณภาพอย่างรวดเร็ว",
              screeningCondition: "เพิ่มตามเกณฑ์การกรอง",
              aiScreeningCondition: "เงื่อนไขการคัดกรองอัจฉริยะ",
              rule: "กฎ",
              createTime: "เพิ่มเวลา",
              filterTitle: "กรองเนื้อหา",
              filterRule: "เงื่อนไขการคัดกรอง",
              startTime: "เวลาเริ่มต้น",
              endTime: "เวลาสิ้นสุด",
              records: "นำเข้าบันทึก",
              createUserName: "ผู้ปฏิบัติงาน",
              successCount: "เพิ่มความสำเร็จ",
              conditionRule: "กฎเงื่อนไข:",
              preview: "ดูตัวอย่าง",
              previewData:
                "ขณะนี้ตรงตามเงื่อนไขการกรอง {0} มีการเพิ่มงาน {1} สามารถเพิ่มงาน {2} ได้และไม่สามารถเพิ่มงาน {3} ได้",
              failCount: "เพิ่มความล้มเหลว",
            },
          },
          basicInfo: {
            index: {
              title: "ข้อมูลพื้นฐาน",
              name: "ชื่อ",
              taskName: "ชื่องาน",
              taskNamePlaceholder: "โปรดป้อนชื่องาน",
              requiredName: "ชื่องานต้องไม่ว่างเปล่า",
              taskRemarkPlaceholder: "โปรดป้อนคำอธิบายงาน",
              requiredRemark: "คำอธิบายงานต้องไม่ว่างเปล่า",
              remark: "คำอธิบาย",
              taskRemark: "คำอธิบายงาน",
              source: "แหล่งข้อมูล",
              benchmarkScore: "จุดอ้างอิง",
              benchmarkScorePlaceholder: "กรุณาป้อนคะแนนมาตรฐาน",
              scoreLowerLimit: "คะแนนขั้นต่ำ",
              scoreUpperLimit: "คะแนนสูงสุด",
              inspectType: "ประเภทการตรวจสอบคุณภาพ",
              nlpModel: "รุ่น NLP",
              commonInspect: "การตรวจสอบคุณภาพตามปกติ",
              associateInspect: "การตรวจสอบคุณภาพที่เกี่ยวข้อง",
              associateCycle: "ระยะเวลาการเชื่อมโยง",
              associateRegulation: "กฎการเชื่อมโยง",
              judgeStrategy: "ตรรกะการดำเนินการ",
              rule: "กฎ",
              commonTip:
                "การตรวจสอบคุณภาพตามปกติ: เหมาะสำหรับการสื่อสารและการสนทนาครั้งเดียวการตรวจสอบคุณภาพของเนื้อหาของการสื่อสารครั้งเดียว",
              associateTip:
                "การตรวจสอบคุณภาพที่เกี่ยวข้อง: เหมาะสำหรับการสื่อสารและการสนทนาหลายรายการและเชื่อมโยงเนื้อหาของการสื่อสารหลายรายการเพื่อการตรวจสอบคุณภาพ",
              cycleTip:
                "หากลิงก์และบทสนทนาหลายรายการที่เกี่ยวข้องข้อมูลที่ตรงตามรอบการเชื่อมโยงจะเข้าสู่การตรวจสอบคุณภาพงาน",
              scoreLowerLimitPlaceholder: "กรุณาใส่คะแนนขั้นต่ำ",
              scoreUpperLimitPlaceholder: "โปรดป้อนขีดจำกัดคะแนนสูงสุด",
              call: "การโทรด้วยเสียง",
              dialogue: "บทสนทนาข้อความ",
              user: "ผู้ตรวจสอบคุณภาพ",
              wechatUser: "ผู้ประกอบการ",
              noDesignated: "ไม่ระบุ",
              type: "ประเภทฟอร์ม",
              singleScore: "คะแนนเดี่ยว",
              conduct:
                "โปรดกรอกข้อมูลต่อไปนี้ก่อนหลังจากเสร็จสิ้นโปรดคลิกถัดไป",
              conduct2:
                "โปรดกรอกกฎการตรวจสอบคุณภาพและการกำหนดค่าจุดตรวจสอบคุณภาพหากคุณกำหนดค่าเสร็จสมบูรณ์คุณสามารถคลิกถัดไปหรือออกจากการบูต",
              conduct3:
                "การให้คะแนนการตรวจสอบคุณภาพคือการกำหนดค่าการให้คะแนนตามจุดตรวจสอบคุณภาพและสถานะการให้คะแนนคุณสามารถกำหนดระดับผลการตรวจสอบคุณภาพที่แตกต่างกันได้หากคุณไม่ต้องการการให้คะแนนคุณสามารถข้ามขั้นตอนนี้ได้โดยตรงหากคุณกำหนดค่าเสร็จสมบูรณ์คุณสามารถคลิกขั้นตอนเดียวหรือออกจากการบูต",
              conduct4:
                "คุณสามารถกำหนดค่าลักษณะข้อมูลที่จะนำเข้าสู่งานโดยอัตโนมัติในตำแหน่งการเพิ่มอัตโนมัติตามลักษณะข้อมูลที่งานตรวจสอบคุณภาพควรตรวจสอบหากคุณไม่จำเป็นต้องเพิ่มข้อมูลโดยอัตโนมัติในขณะนี้คุณสามารถคลิกข้ามได้หากคุณกำหนดค่าเสร็จสมบูรณ์คุณสามารถคลิกถัดไป",
              conduct5:
                "หากคุณใช้การบันทึกโมโนโปรดกำหนดค่ารายการนี้และคลิกถัดไปโปรดใช้เฉพาะฝ่ายบริการลูกค้าเท่านั้นที่จะใช้เทคนิคคำเพื่อช่วยให้ระบบระบุแทร็กที่แตกต่างกันระหว่างฝ่ายบริการลูกค้าและลูกค้าหากคุณใช้การบันทึกสองช่องทางหรือการกำหนดค่าเสร็จสมบูรณ์คุณสามารถคลิกข้าม",
              conduct6:
                "คุณสามารถเพิ่มฟังก์ชันด้วยตนเองแนะนำข้อมูลการสนทนาที่มีอยู่ในระบบสำหรับการตรวจสอบคุณภาพหรือคุณได้ส่งงานเพิ่มด้วยตนเองโปรดคลิกเพื่อดำเนินการให้เสร็จสิ้น",
              helper: "ดูเอกสารความช่วยเหลือ",
              quit: "ออกจากการบูต",
              next: "ขั้นตอนต่อไป",
              complete: "เสร็จแล้ว",
              skip: "ข้าม",
              cancel: "การยกเลิก",
              delete: "ลบ",
              save: "บันทึก",
            },
            template: {
              samplingInspector: "ผู้ตรวจสอบการสุ่มตัวอย่าง",
              sessionType: "ประเภทเซสชัน",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "โต๊ะตรวจสอบคุณภาพ",
              search: "การคัดกรอง",
              export: "ส่งออก",
              condition: "เงื่อนไข",
              logic: "ตรรกะ",
              reQualityInspection: "ตรวจสอบคุณภาพใหม่",
              reQualityInspectionUnderWay: "งานตรวจสอบคุณภาพใหม่...",
              reQualityInspectionUnderWayTip:
                "การออกจากหน้าหลังจากปิดหน้าต่างจะไม่ส่งผลกระทบต่องาน",
              reQualityInspectionDone: "การตรวจสอบคุณภาพใหม่เสร็จสมบูรณ์แล้ว",
              reQualityInspectionDoneTip:
                "การตรวจสอบคุณภาพใหม่เสร็จสมบูรณ์แล้วโปรดคลิกปุ่มยืนยัน",
              endReQualityInspection: "ยุติภารกิจ",
              alreadyDoneTip: "เสร็จเรียบร้อยแล้ว",
              totalTip: "รวม",
              unit: "แถบ",
              endReInspectionSuccess: "งานตรวจสอบคุณภาพซ้ำสิ้นสุดลงแล้ว",
              closeWindow: "ปิดหน้าต่าง",
              endReInspectionFailed:
                "งานตรวจสอบคุณภาพใหม่ล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
            },
            detail: {
              name: "รายละเอียดการตรวจสอบคุณภาพ",
              reviewName: "ตรวจสอบรายละเอียด",
              spotCheckName: "รายละเอียดการสุ่มตัวอย่าง",
              audioText: "บันทึกข้อความ",
              callInfo: "ข้อมูลการโทร",
              satisfactionEvaluation: "การประเมินความพึงพอใจ:",
              queueTime: "คิวใช้เวลานาน:",
              ringingTime: "เวลาสั่น:",
              callTime: "เหตุการณ์การโทร:",
              hangUpIdentity: "วางสาย:",
              location: "แสดงที่มา:",
              source: "ที่มา:",
              agent: "บริการลูกค้า:",
              deviceType: "ประเภทอุปกรณ์:",
              callType: "ประเภทการโทร:",
              phoneNumber: "หมายเลขรีเลย์:",
              task: "ภารกิจ:",
              commentsTip: "โปรดป้อนความคิดเห็นการตรวจสอบคุณภาพ",
              lastPage: "ก่อนหน้า",
              nextPage: "หน้าถัดไป",
              score: "คะแนนการตรวจสอบคุณภาพ",
              review: "รีวิว",
              file: "เก็บถาวร",
              changeList: "บันทึกการเปลี่ยนแปลง",
              fullMarks: "คะแนนเต็ม",
              most: "มากที่สุด",
              saveReview: "การส่งรีวิว",
              saveSpotCheck: "การส่งแบบสุ่ม",
              point: "จุดตรวจสอบคุณภาพ",
              sampleAssignInspector: "มอบหมายผู้ตรวจสอบการสุ่มตัวอย่าง",
              reviewAssignInspector: "มอบหมายผู้ตรวจสอบ",
              sampleStatus: "สถานะการสุ่มตัวอย่าง",
              read: "อ่านแล้ว",
              user: "ผู้ใช้",
              confirmer: "บุคคลที่ได้รับการยืนยัน",
              voiceDownloadError:
                "ดาวน์โหลดเสียงล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
              smartTags: "สมาร์ทแท็ก",
              interactiveTip:
                "ใช้การระบุตัวตนแบบโต้ตอบเพื่อตรวจสอบการใช้คำถามที่พบบ่อยในการบริการลูกค้า",
              interactiveIsEmptyTip: "ไม่พบเนื้อหาที่ระบุตัวตนแบบโต้ตอบ",
              businessRecords: "บันทึกธุรกิจ",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "การวิเคราะห์แชทกลุ่ม WeChat ขององค์กร",
            },
          },
        },
      },
      cards: {
        health: "การตรวจสอบคุณภาพสุขภาพ",
        accuracy: "พบอัตราการอนุมัติ",
        sessionsTotal: "จำนวนเซสชันทั้งหมด",
        enable: "เปิดใช้งาน",
        disable: "ปิดการใช้งาน",
        none: "ไม่มี",
        call: "โทร",
        dialogue: "การสนทนา",
        taobao: "อีคอมเมิร์ซ",
        wecom: "เซสชัน",
        ticket: "ใบสั่งงาน",
      },
      component: {
        backup: "(สำรอง)",
        taskSaveAsSucceeded: "บันทึกงานให้สำเร็จ",
        copyContent: "คัดลอกเนื้อหา",
        essentialInformation: "ข้อมูลพื้นฐาน",
        ruleConfiguration: "การกำหนดค่ากฎ",
        basicInformationQualityInspector: "ข้อมูลพื้นฐาน-ผู้ตรวจสอบคุณภาพ",
        qualityInspectionRating: "การให้คะแนนการตรวจสอบคุณภาพ",
        roleConfiguration: "การกำหนดค่าบทบาท",
        autoAddConfiguration: "เพิ่มการกำหนดค่าโดยอัตโนมัติ",
        autoPushConfiguration: "การกำหนดค่าผลักดันอัตโนมัติ",
        automaticSamplingConfiguration: "การกำหนดค่าการสุ่มตัวอย่างอัตโนมัติ",
        automaticAllocationConfiguration: "การกำหนดค่าการกำหนดค่าอัตโนมัติ",
        businessConfiguration: "การกำหนดค่าธุรกิจ",
        caseBaseConfiguration: "ห้องสมุดกรณี",
      },
      template: {
        cancel: "การยกเลิก",
        complete: "เสร็จแล้ว",
        sort: "เรียงลำดับ",
        ruleApproval: "การอนุมัติกฎ",
        enterpriseMicroConversationAnalysisTask:
          "งานวิเคราะห์เซสชันไมโครขององค์กร",
        enterpriseAndMicroVoiceAnalysisTask: "งานวิเคราะห์ไมโครวอยซ์ขององค์กร",
        saveTaskAs: "บันทึกงานเป็น",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "รายละเอียดกฎ" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "แก้ไขสำเร็จ",
            automaticApproval: "การอนุมัติอัตโนมัติ",
          },
          pageHeaderExtra: {
            modificationSucceeded: "แก้ไขสำเร็จ",
            test: "การทดสอบ",
            formal: "อย่างเป็นทางการ",
            adopt: "ผ่าน",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "คุณแน่ใจหรือว่าคุณปฏิเสธ",
              pleaseEnterTheRejectionReason: "โปรดป้อนเหตุผลในการปฏิเสธ",
              reject: "ปฏิเสธ",
            },
          },
          table: {
            index: {
              modificationSucceeded: "แก้ไขสำเร็จ",
              taskName: "ชื่องาน",
              dataSource: "แหล่งข้อมูล",
              qualityInspectionType: "ประเภทการตรวจสอบคุณภาพ",
              state: "สถานะ",
              updateTime: "เวลาอัพเดต",
              updatedBy: "คนอัพเดต",
              approver: "ผู้อนุมัติ",
              reasonForRejection: "เหตุผลในการปฏิเสธ",
              operation: "การดำเนินงาน",
              details: "รายละเอียด",
              adopt: "ผ่าน",
              whole: "ทั้งหมด",
              approvalStatus: "สถานะการอนุมัติ",
            },
          },
        },
      },
    },
    dashboard: {
      title: "ภาพรวม",
      hi: "สวัสดี,",
      standardEdition: "รุ่นมาตรฐาน",
      call: "ความสมดุลการตรวจสอบเสียง",
      text: "ความถูกต้องของการตรวจสอบข้อความ",
      collectionTasks: "งานตรวจสอบคุณภาพที่รวบรวม",
      noCollection: "ไม่มีงานตรวจสอบคุณภาพสำหรับคอลเลกชัน",
      goToCollecte: "ไปที่คอลเลกชัน",
      yesterdayOverView: "สถิติธุรกิจเมื่อวานนี้",
      yesterdayAgent: "การจัดอันดับการบริการลูกค้าเมื่อวานนี้",
      yesterdayInspWork: "การทำงานของผู้ตรวจสอบคุณภาพเมื่อวานนี้",
      operationalStatistics: {
        title: "สถิติธุรกิจ",
        qualityTesting: "การตรวจสอบคุณภาพ",
        spotCheck: "การสุ่มตัวอย่าง",
        appeal: "การอุทธรณ์",
        chartTitles: {
          qualityTestingTrend: "แนวโน้มการตรวจสอบคุณภาพ",
          qualityTestingAnalysis: "งานตรวจสอบคุณภาพ",
          spotCheckTrend: "แนวโน้มการสุ่มตัวอย่าง",
          spotCheckAnalysis: "งานตรวจสอบตัวอย่าง",
          appealTrend: "แนวโน้มการอุทธรณ์",
          appealAnalysis: "งานอุทธรณ์",
        },
        titles: {
          qualityDataTend: "การกระจายข้อมูลการตรวจสอบคุณภาพ",
          qualityCover: "ความครอบคลุมการตรวจสอบคุณภาพ",
          qualityAverageTime: "เวลารอการตรวจสอบคุณภาพโดยเฉลี่ย",
          qualityCoverTend: "แนวโน้มการครอบคลุมการตรวจสอบคุณภาพ",
          handCover: "สถานการณ์การประมวลผลด้วยตนเอง",
          checkCover: "ความครอบคลุมการสุ่มตัวอย่าง",
          resAverageTime: "เวลาในการประมวลผลเฉลี่ย",
          handTend: "แนวโน้มการประมวลผลด้วยตนเอง",
          dataTend: "การกระจายงานข้อมูล",
        },
        qualityAverage: "คะแนนเฉลี่ยการตรวจสอบคุณภาพ",
        scoreTrend: "แนวโน้มการให้คะแนนการตรวจสอบคุณภาพ",
        qualityAverageTime: "เวลารอการตรวจสอบคุณภาพโดยเฉลี่ย",
        callCoverage: "ความครอบคลุมการตรวจสอบคุณภาพเสียง",
        textCoverage: "ความครอบคลุมการตรวจสอบคุณภาพข้อความ",
        checkCount: "ปริมาณข้อมูลการสุ่มตัวอย่าง",
        siphonCount: "ดึงข้อมูลจำนวน",
        reviewCount: "ตรวจสอบปริมาณข้อมูล",
        appealCount: "จำนวนข้อมูลการอุทธรณ์",
        checkRate: "อัตราส่วนการดำเนินการตรวจสอบการสุ่มตัวอย่าง",
        reviewRate: "อัตราส่วนการตรวจสอบการอุทธรณ์",
        PieTitle: {
          totalCount: "จำนวนการสนทนาทั้งหมด",
          totalCall: "จำนวนการโทรด้วยเสียง",
          totalIm: "จำนวนการสนทนาข้อความ",
          inspectionCall: "การตรวจสอบคุณภาพเสียง",
          inspectionIm: "การตรวจสอบคุณภาพข้อความ",
          checkCall: "ปริมาณการสุ่มตัวอย่างเสียง",
          unCheckCall: "ไม่มีการสุ่มตัวอย่างเสียง",
          checkIm: "ปริมาณการตรวจสอบข้อความ",
          unCheckIm: "ไม่มีการสุ่มตัวอย่างข้อความ",
          reviewCall: "ปริมาณการตรวจสอบด้วยเสียง",
          reviewIm: "ปริมาณการตรวจสอบข้อความ",
          unReviewCall: "จำนวนเสียงที่ไม่ได้รับการตรวจสอบ",
          unReviewIm: "จำนวนข้อความที่ไม่ได้รับการตรวจสอบ",
          unInspectionCall: "ปริมาณเสียงที่ไม่มีคุณภาพ",
          unInspectionIm: "ข้อความไม่ได้รับการตรวจสอบคุณภาพ",
        },
        check_rate: "ความครอบคลุมการสุ่มตัวอย่าง",
        voice_check_rate: "ความครอบคลุมของการสุ่มตัวอย่างเสียง",
        text_check_rate: "ความครอบคลุมของการตรวจสอบข้อความ",
        sample_execute_time: "เวลาในการสุ่มตรวจโดยเฉลี่ย",
        review_execute_time: "เวลาดำเนินการอุทธรณ์โดยเฉลี่ย",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "บอร์ดกฎการตรวจสอบคุณภาพ",
          agent: "การจัดอันดับการบริการลูกค้า",
        },
        tableTips: {
          conditions:
            "<Div> จัดอันดับจำนวนการสนทนาทั้งหมดที่ละเมิดและปฏิบัติตามกฎการตรวจสอบคุณภาพ <Br/>\nรายการตรวจสอบคุณภาพ: ชื่อของจุดตรวจสอบคุณภาพ <br/>\nจำนวนการสนทนาที่ผิดกฎหมายทั้งหมด: จำนวนการสนทนาทั้งหมดที่ละเมิดในรายการตรวจสอบคุณภาพนี้ <br/>\nสัดส่วนการละเมิด: สัดส่วนการละเมิด = จำนวนการสนทนาที่ละเมิดทั้งหมด/จำนวนการสนทนาทั้งหมด </div>",
          agent:
            "<Div> จัดอันดับคะแนนเฉลี่ยของที่นั่ง <br/>\nชื่อบริการลูกค้า: ชื่อบริการลูกค้า <br/>\nกลุ่มบริการลูกค้า: กลุ่มบริการลูกค้า (ข้อมูลองค์กรในข้อมูลการบริการลูกค้า) <br/>\nจำนวนบทสนทนาทั้งหมดที่ให้คะแนน: จำนวนบทสนทนาทั้งหมดในการตรวจสอบคุณภาพของฝ่ายบริการลูกค้า <br/>\nคะแนนเฉลี่ยการตรวจสอบคุณภาพ: คะแนนเฉลี่ยการตรวจสอบคุณภาพของฝ่ายบริการลูกค้า (คะแนนเพื่อสรุป/ผ่านการสนทนาทั้งหมด)</div>",
        },
        tips: {
          total: "จำนวนการเข้าถึง/การสนทนาทั้งหมดที่เข้าสู่งานตรวจสอบคุณภาพ",
          agentTotal:
            "จำนวนบริการลูกค้าตรวจสอบคุณภาพทั้งหมดที่เข้าสู่งานตรวจสอบคุณภาพ",
          avg: "คะแนนเฉลี่ยของคะแนนการตรวจสอบคุณภาพ",
          violation:
            "ผลรวมของจำนวนการละเมิดรายการตรวจสอบคุณภาพในการตรวจสอบคุณภาพทั้งหมด",
          compliance:
            "ผลรวมของจำนวนครั้งที่ตีรายการตรวจสอบคุณภาพในระหว่างกระบวนการตรวจสอบคุณภาพ",
          check: "จำนวนการสุ่มตัวอย่างด้วยตนเองทั้งหมด",
          appeal: "จำนวนการอุทธรณ์ทั้งหมดที่ส่งในจำนวนการสนทนาทั้งหมด",
          review: "จำนวนบทสนทนาทั้งหมดที่ส่งเพื่อตรวจสอบ",
        },
        subTips: {
          violation:
            "อัตราการละเมิด: จำนวนการละเมิดทั้งหมด/จำนวนรายการตรวจสอบคุณภาพทั้งหมด * 100%",
          compliance:
            "อัตราการปฏิบัติตาม: จำนวนการปฏิบัติตามทั้งหมด/จำนวนรายการตรวจสอบคุณภาพทั้งหมด * 100%",
          check:
            "ความครอบคลุมของการตรวจสอบการสุ่มตัวอย่าง: จำนวนการตรวจสอบการสุ่มตัวอย่างด้วยตนเองทั้งหมด/การตรวจสอบคุณภาพทั้งหมด * 100%",
          appeal:
            "อัตราการร้องเรียน: จำนวนการอุทธรณ์ทั้งหมด/การตรวจสอบคุณภาพทั้งหมด * 100%",
          review:
            "อัตราการตรวจสอบ: จำนวนการตรวจสอบทั้งหมด/การตรวจสอบคุณภาพทั้งหมด * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "การตั้งค่าระบบ",
          systemName: "ชื่อระบบ",
          speakerRecognize: "การรับรู้ของผู้พูด",
          massage: "การแจ้งเตือนข้อความ",
          evaluate: "การประเมินความรู้ความเข้าใจเทียม",
          evaluateTip:
            "หลังจากเปิดแล้วให้เลือกการตรวจสอบการสุ่มตัวอย่างอย่างรวดเร็วในรายละเอียดการตรวจสอบคุณภาพและจะแจ้งให้ทำการประเมินด้วยตนเอง",
          speakerRecognizeTip:
            "การบันทึกการโทรของบุคคลที่สามผ่านการเข้าถึง API จำเป็นต้องระบุว่าช่องทางซ้ายเป็นฝ่ายบริการลูกค้าหรือลูกค้า",
          speakerRecognizeTip2:
            "การบันทึกช่องเดียวกำหนดบทบาทของการบริการลูกค้าผ่านการจดจำข้อความการกำหนดค่าบทบาท",
          enabledImSendStatusFilter: "การกรองข้อความการตรวจสอบคุณภาพ",
          enabledImSendStatusFilterTips:
            "เนื้อหาการทดสอบของตัวดำเนินการตรวจสอบคุณภาพไม่รวมข้อความที่มีสถานะเป็น [ถอน] [ส่งไม่สำเร็จ] และ [ข้อความออฟไลน์ยังไม่ได้อ่าน]",
          lang: "การตั้งค่าภาษาระบบ",
          zh: "ภาษาจีนตัวย่อ",
          en: "English",
          tw: "ภาษาจีนดั้งเดิม",
        },
        template: {
          largeModelSettings: "การตั้งค่ารูปแบบขนาดใหญ่",
          indonesian: "ภาษาอินโดนีเซีย",
          vietnamese: "ภาษาเวียดนาม",
          portuguese: "ภาษาโปรตุเกส",
          malay: "ภาษามลายู",
          french: "ฝรั่งเศส",
          spanish: "สเปน",
          japanese: "ญี่ปุ่น",
          thai: "ภาษาไทย",
          blockSystemSendingMessages: "ระบบป้องกันการส่งข้อความ",
          textDataDesensitization: "การลดความไวของข้อมูลข้อความ",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "เมื่อสวิตช์เปิดอยู่จะแสดงรายการแบบเลื่อนลงของฟิลด์ desensitization (รองรับการเลือกหลายรายการ) ฟิลด์ desensitization: ชื่อหมายเลขโทรศัพท์มือถือที่อยู่",
          desensitizingField: "ฟิลด์ desensitization",
          name: "ชื่อบุคคล",
          cellphoneNumber: "หมายเลขโทรศัพท์มือถือ",
          address: "ที่อยู่",
          dataCleaning: "การล้างข้อมูล",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "หลังจากตั้งค่าแล้วข้อมูลการสนทนาการตรวจสอบคุณภาพและการสนทนา WeChat ขององค์กรจะถูกล้างเป็นประจำและไม่สามารถกู้คืนได้โปรดใช้ความระมัดระวัง",
          newCleaningRules: "เพิ่มกฎการทำความสะอาด",
          qualityInspectionRuleApproval: "การอนุมัติกฎการตรวจสอบคุณภาพ",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "หลังจากเปิดแล้วการแก้ไขกฎการตรวจสอบคุณภาพจะต้องได้รับการอนุมัติก่อนจึงจะมีผล",
          maskOfflineMessageUnread: "บล็อกข้อความออฟไลน์ยังไม่ได้อ่าน",
          privacyNumberProtection: "การป้องกันหมายเลขความเป็นส่วนตัว",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'หลังจากเปิดแล้วให้ลดความไวของฟิลด์ "โทรศัพท์ของลูกค้า" ในระหว่างการโทรและแสดงเป็น1881 *******',
          shieldingWithdrawn: "การบล็อกถูกถอนออกไปแล้ว",
          maskSendingFailed: "การส่งบล็อกล้มเหลว",
        },
        component: {
          conversation: "โทร",
          dialogue: "การสนทนา",
          weCom: "องค์กร WeChat",
          day: "วัน",
          week: "สัปดาห์",
          month: "เดือน",
          year: "ปี",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "ช่วงการทำความสะอาด",
            dataCleaningTimeSetting: "การตั้งค่าเวลาทำความสะอาดข้อมูล",
            afterCleanUpTheData: "หลังจากทำความสะอาดข้อมูล",
            dataAfterComplaintCompletion: "ข้อมูลหลังจากการอุทธรณ์เสร็จสิ้น",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "ดำเนินการได้สำเร็จ",
          saveSuccessful: "บันทึกความสำเร็จ",
          speechRecognition: "การรู้จำเสียง",
          reExecuteErrorCorrection: "ดำเนินการแก้ไขข้อผิดพลาดอีกครั้ง",
          confirmExecution: "ยืนยันการดำเนินการ",
          voiceDetection: "การตรวจจับเสียง",
          noiseDetectionPowerLevel: "ตรวจจับเสียงรบกวน powerLevel ≤",
          pleaseEnterTheThreshold: "กรุณาป้อนเกณฑ์",
          volumeDetectionPowerLevel: "การตรวจสอบระดับเสียง powerLevel ≥",
          reset: "รีเซ็ต",
          preserve: "บันทึก",
        },
        coachTemplate: {
          french: "ฝรั่งเศส",
          spanish: "สเปน",
          japanese: "ญี่ปุ่น",
          thai: "ภาษาไทย",
          loginPopupWindow: "หน้าต่างป๊อปอัปเข้าสู่ระบบ",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "หลังจากเปิดแล้วระบบจะแจ้งป๊อปอัปเมื่อเข้าสู่ระบบการฝึกอบรมอัจฉริยะ",
          title: "ชื่อเรื่อง",
          text: "ร่างกาย",
          checkBoxContent: "ตรวจสอบเนื้อหาของกล่อง",
          informedConsentForm: "ได้รับความยินยอม",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "เพื่อช่วยให้คุณพัฒนาทักษะการสื่อสารและการพูดและการประยุกต์ใช้ความรู้ทางวิชาชีพช่วยให้คุณฝึกฝนและรับข้อเสนอแนะแบบเรียลไทม์ได้ทุกที่ทุกเวลาเราจะรวบรวมข้อมูลต่อไปนี้จากคุณผ่านทางแพลตฟอร์มนี้:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "ฉันได้อ่านและเข้าใจแบบฟอร์มยินยอมนี้อย่างครบถ้วนและขอประกาศอย่างจริงจังว่าฉันตกลงที่จะจัดการกับข้อมูลส่วนบุคคลที่ละเอียดอ่อนของฉันตามวัตถุประสงค์และวิธีการที่อธิบายไว้ในแบบฟอร์มยินยอมนี้",
        },
      },
      systemIntegration: {
        title: "การตั้งค่าระบบ",
        ccIntegrationTips:
          "เอกสารคำอธิบายศูนย์บริการ Udesk สำหรับการเชื่อมต่อการตรวจสอบคุณภาพอัจฉริยะ",
        unauthorized: "ไม่ได้รับอนุญาต",
        authorized: "ได้รับอนุญาต",
        udeskDomain: "ชื่อโดเมนระบบบริการลูกค้า",
        udeskToken: "คีย์ระบบบริการลูกค้า",
        udeskAdminEmail: "บัญชีผู้ดูแลระบบบริการลูกค้า",
        password: "รหัสผ่านบัญชีผู้ดูแลระบบ",
        applyAuth: "การอนุญาต",
        cancelAuth: "ยกเลิกการอนุญาต",
        reApplyAuth: "การอนุญาตใหม่",
        keyTips:
          "การจัดการพื้นหลังของระบบบริการลูกค้า-การตรวจสอบความถูกต้องของ API Token ในการลงชื่อเพียงครั้งเดียว",
      },
      customerField: {
        title: "ฟิลด์ลูกค้า",
        table: {
          propName: "ชื่อฟิลด์",
          propDesc: "คำอธิบายฟิลด์",
          valueType: "ประเภทฟิลด์",
          propType: "วิธีการสร้าง",
          createTime: "สร้างเวลา",
          canNotEdit: "ฟิลด์ระบบไม่รองรับการแก้ไข",
        },
        edit: "แก้ไขฟิลด์",
        new: "ฟิลด์ใหม่",
      },
      wechatDocking: {
        title: "การเชื่อมต่อองค์กรขนาดเล็ก",
        formLabels: {
          companyId: "องค์กร ID",
          communicationKey: "กุญแจการสื่อสาร",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "เหตุการณ์ URL โทรกลับ",
          applicationName: "ชื่อแอป",
          applicationId: "ID แอปพลิเคชันเว็บ",
          applicationSecret: "แอพลิเคชัน secret",
        },
        formTitle: {
          initialForm: "การตั้งค่าเริ่มต้น",
          synchronizeForm: "การซิงโครไนซ์สมุดรายชื่อ",
          settingForm: "การตั้งค่าแอป",
        },
        qrCodeUrlTip:
          "ขอให้ผู้ดูแลระบบสแกนโค้ด QR ด้านล่างเพื่อพัฒนาแอปพลิเคชันที่มีผลผูกพัน",
      },
      taobao: {
        formLabels: {
          name: "ชื่อร้าน",
          account: "บัญชี",
          password: "รหัสผ่าน",
          confirm: "ยืนยันรหัสผ่าน",
          authorization: "การอนุญาต",
          switchOn: "เปิด",
          switchOff: "ปิด",
        },
        tableLabels: {
          name: "ชื่อร้าน",
          account: "บัญชี",
          authorization: "การอนุญาต",
          updateUser: "อัปเดตล่าสุด",
          updateTime: "เวลาอัพเดตล่าสุด",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "ชื่อโดเมนระดับที่สองของ Weifeng",
          secretKeyTip:
            "คีย์ลับของผู้เช่าได้รับจากการเชื่อมต่อระบบพื้นหลังของระบบ Weifeng",
        },
      },
      user: {
        index: {
          name: "การจัดการพนักงาน",
          existingUsers: "พนักงานที่มีอยู่แล้ว",
          deleteSuccess: "ลบสำเร็จ",
          deleteFailed: "ลบล้มเหลว",
        },
        detail: {
          accountPrompt: "กรุณากรอกอีเมล",
          resetPassword: "รีเซ็ตรหัสผ่าน",
          passwordPrompt: "รหัสผ่าน (6-14ตัวอักษรและตัวเลขรวมกัน)",
          originPasswordPrompt: "โปรดป้อนรหัสผ่านเริ่มต้น",
          resumePasswordPrompt: "โปรดป้อนรหัสผ่านอีกครั้ง",
          passwordFormWrong: "โปรดป้อนรหัสผ่าน6-14ตัวอักษรและตัวเลขรวมกัน!",
          resumePasswordWrong: "รหัสผ่านไม่สอดคล้องกันสองครั้ง!",
          suppertImageType: "รองรับ jpg, gif, png, สูงสุด500Kb",
          namePrompt: "กรุณากรอกชื่อ",
          nickNamePrompt: "กรุณาใส่ชื่อเล่น",
          telephonePrompt: "กรุณากรอกหมายเลขโทรศัพท์",
          roleSelectedPrompt: "โปรดเลือกบทบาท",
          agentIdPrompt: "กรุณากรอกหมายเลขงาน",
          disabled: "ปิดการใช้งาน",
          enabled: "เปิดใช้งาน",
          reloadBowerInfo:
            "ข้อมูลผู้ใช้มีการเปลี่ยนแปลงฉันจำเป็นต้องรีเฟรชเบราว์เซอร์หรือไม่?",
          saveUserError: "บันทึกข้อมูลผู้ใช้ล้มเหลวโปรดลองอีกครั้งในภายหลัง",
          resetPasswordSuccess: "รีเซ็ตรหัสผ่านเรียบร้อยแล้ว",
          resetPasswordFailed:
            "การรีเซ็ตรหัสผ่านล้มเหลวโปรดลองอีกครั้งในภายหลัง",
        },
      },
      userGroups: {
        index: {
          name: "การจัดการกลุ่มพนักงาน",
          existingUserGroups: "กลุ่มพนักงานที่มีอยู่",
        },
        detail: {},
      },
      role: {
        index: { name: "การจัดการบทบาท", existingRoles: "บทบาทที่มีอยู่" },
        edit: {
          editRole: "แก้ไขบทบาท",
          createRole: "สร้างตัวละคร",
          basicInfo: "ข้อมูลพื้นฐาน",
          taskPermissions: "สิทธิ์งาน",
          existingTasks: "งานที่มีอยู่",
        },
      },
      anomalousData: {
        title: "ข้อมูลที่ผิดปกติ",
        list: {
          selectHolder: "โปรดเลือกแหล่งข้อมูล",
          businessTime: "เวลาธุรกิจ",
          businessId: "ธุรกิจ Id",
          export: "ส่งออก",
        },
      },
      templateComponent: {
        list: {
          title: "ตลาดแม่แบบ",
          name: "ชื่อแม่แบบ",
          searchHolder: "โปรดป้อนสิ่งที่คุณต้องการสอบถาม",
          update: "อัพเดตสถานการณ์",
          sort: "วิธีการเรียงลำดับ",
          moduleType: "ประเภทแม่แบบ",
          moduleSourceType: "ประเภทที่ใช้งานได้",
          fetchNum: "จำนวนครั้งที่ได้รับ",
          newest: "ปรับปรุงล่าสุด",
          usedMost: "รับมากที่สุด",
          call: "โทร",
          dialogue: "การสนทนา",
          waiting: "เพื่อตรวจสอบ",
          cancel: "ยกเลิกการเผยแพร่",
          success: "ได้รับการอนุมัติและเผยแพร่แล้ว",
          failed: "ปฏิเสธการตรวจสอบ",
          no: "ไม่สามารถอัพเกรดได้",
          ok: "สามารถอัพเกรดได้",
          all: "ทั้งหมด",
          delete: "ลบสำเร็จ",
          downOk: "นำออกสำเร็จ",
          withdraw: "ถอนสำเร็จ",
          updateOk: "อัพเกรดสำเร็จ",
          get: "รับ",
          width: "ถอน",
          getOk: "ได้รับความสำเร็จ",
          orgName: "องค์กรในเครือ",
          nowVersion: "รุ่นปัจจุบัน",
          lastedVersion: "รุ่นล่าสุด",
          appStatus: "สถานะแอป",
          actions: "การดำเนินงาน",
          down: "ชั้นวาง",
          updateTop: "อัพเกรด",
          check: "ดู",
          deleteWhen: "คุณยืนยันการลบหรือไม่?",
          yes: "ใช่",
          noo: "ไม่ใช่",
          deleteOk: "ลบ",
          permissible: "อนุญาต",
          noPermissible: "ไม่อนุญาต",
          openUp: "เปิด",
          openDown: "ไม่เปิด",
          task: "งานตรวจสอบคุณภาพ",
          point: "จุดตรวจสอบคุณภาพ",
          tag: "แท็กความหมาย",
          muti: "หลายองค์ประกอบ",
          inter: "คำถามและคำตอบ",
          info: "เอนทิตีข้อมูล",
          concept: "คำแนวคิด",
          detail: "รายละเอียดโมดูล",
          infomation: "ข้อมูลพื้นฐาน",
          detailConfig: "รายละเอียดการกำหนดค่า",
          twoEdit: "การแก้ไขรอง",
          mustUpdate: "อัปเกรดทันที",
          descition: "คำอธิบายเทมเพลต",
          descitionSize: "คำอธิบายเทมเพลตต้องไม่เกิน250คำ",
          stencilsDetail: "รายละเอียดแม่แบบ",
          updatedRecords: "บันทึกการปรับปรุง",
          version: "หมายเลขรุ่น",
          updateDescription: "ปรับปรุงคำอธิบาย",
          myModule: "แม่แบบของฉัน",
          addModule: "โมดูลใหม่",
          publishVersion: "รุ่นที่วางจำหน่าย",
          earliestVersion: "รุ่นแรกสุด",
          edit: "บรรณาธิการ",
          add: "เพิ่มใหม่",
          configNo: "รายละเอียดการกำหนดค่าโมดูลต้องไม่ว่างเปล่า",
          updateNo: "คำแนะนำในการอัปเดตต้องไม่ว่างเปล่า",
          editSuccess: "แก้ไขสำเร็จ",
          saveSuccess: "บันทึกความสำเร็จ",
          newer: "อัพเดต",
          sumbit: "ส่ง",
          cancelName: "การยกเลิก",
          nameRequired: "ชื่อโมดูลเป็นฟิลด์ที่จำเป็น",
          nameSize: "ชื่อโมดูลต้องไม่เกิน16คำ",
          publishStatus: "สถานะการโพสต์",
          submitUpdate: "ส่งการปรับปรุง",
          updateVersion: "รุ่นปรับปรุง",
          updateExplain: "ปรับปรุงคำอธิบาย",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon อีคอมเมิร์ซ",
        youtubeVideo: "วิดีโอ Youtube",
        amazon: {
          component: {
            queuing: "ในสาย",
            notStarted: "ยังไม่เริ่ม",
            inProgress: "กำลังดำเนินการอยู่",
            success: "ประสบความสำเร็จ",
            failed: "ล้มเหลว",
            successfullyCreated: "สร้างสำเร็จ",
            editedSuccessfully: "แก้ไขสำเร็จ",
            operationSucceeded: "การดำเนินงานที่ประสบความสำเร็จ",
            deletedSuccessfully: "ลบสำเร็จ",
            link: "การเชื่อมโยง",
            grabState: "สถานะการรวบรวมข้อมูล",
            updateTime: "เวลาอัพเดต",
            grab: "คว้า",
            edit: "แก้ไข",
          },
          template: {
            amazonEcommerce: "Amazon อีคอมเมิร์ซ",
            thisProduct: "ผลิตภัณฑ์นี้",
            competition: "สินค้าที่แข่งขันได้",
            new: "สร้างใหม่",
            edit: "แก้ไข",
            link: "การเชื่อมโยง",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "วิดีโอ Youtube",
            thisProduct: "ผลิตภัณฑ์นี้",
            competition: "สินค้าที่แข่งขันได้",
            new: "สร้างใหม่",
            edit: "แก้ไข",
            link: "การเชื่อมโยง",
          },
          component: {
            queuing: "ในสาย",
            notStarted: "ยังไม่เริ่ม",
            inProgress: "กำลังดำเนินการอยู่",
            success: "ประสบความสำเร็จ",
            failed: "ล้มเหลว",
            successfullyCreated: "สร้างสำเร็จ",
            editedSuccessfully: "แก้ไขสำเร็จ",
            operationSucceeded: "การดำเนินงานที่ประสบความสำเร็จ",
            deletedSuccessfully: "ลบสำเร็จ",
            youTubeVideoLink: "ลิงก์วิดีโอ YouTube",
            grabState: "สถานะการรวบรวมข้อมูล",
            updateTime: "เวลาอัพเดต",
            grab: "คว้า",
            edit: "แก้ไข",
          },
        },
      },
    },
    demo: {
      title: "การตรวจสอบคุณภาพอัจฉริยะ",
      template: "เลือกแม่แบบการตรวจสอบคุณภาพ",
      radio: "เลือกรูปแบบการบันทึก",
      uploadAudio: "อัปโหลดบันทึก",
      uploadAudioTip:
        "การบันทึกการอัปโหลดรองรับรูปแบบ wav และ mp3เท่านั้นและขนาดไม่เกิน1G",
      uploadText: "อัปโหลดข้อความ",
      uploadTextTip:
        "ข้อความที่อัปโหลดรองรับเฉพาะรูปแบบ srt และ txt และขนาดไม่เกิน10M หลังจากอัปโหลดข้อความแล้วจะไม่ใช้ ASR ในการแยกวิเคราะห์ไฟล์บันทึกอีกต่อไป",
      uploadSuccess: "อัปโหลดไฟล์บันทึกเรียบร้อยแล้ว",
      uploadFailed: "ความล้มเหลวในการอัปโหลดไฟล์บันทึก",
      startQualityInspection: "เริ่มการตรวจสอบคุณภาพ",
      qualityInspectionStarted: "อยู่ระหว่างการตรวจสอบคุณภาพ",
      downloadText: "ดาวน์โหลดข้อความ",
      checking: "ความคืบหน้าการตรวจสอบคุณภาพ",
      checkingTip: "คาดว่าจะใช้เวลาไม่กี่นาทีโปรดอดใจรอ",
      noCheckContent: "ยังไม่มีผลการตรวจสอบคุณภาพ",
      noTextDownload: "ไม่มีข้อความตรวจสอบคุณภาพให้ดาวน์โหลด!",
      pleaseCheckParams:
        "โปรดตรวจสอบว่าคุณเลือกเทมเพลตประเภทเสียงและอัปโหลดไฟล์บันทึก",
      inspectionFailed: "การตรวจสอบคุณภาพล้มเหลวโปรดลองอีกครั้งในภายหลัง!",
    },
    wechatQa: {
      baseInfo: { title: "ข้อมูลพื้นฐาน" },
      conversationSetting: {
        title: "การตั้งค่าเซสชัน",
        segmentationStrategy: "กลยุทธ์การแบ่ง",
        rule: "กลยุทธ์",
        time: "แบ่งเวลา",
        daily: "ทุกวัน",
        passScore: "คะแนนคุณสมบัติเซสชัน",
        ruleLogic: "ตรรกะการดำเนินการ",
      },
      message: {
        title: "เหตุการณ์ผลักดัน",
        table: {
          index: "หมายเลขซีเรียล",
          name: "ชื่อ",
          type: "วิธีการผลักดัน",
          desc: "คำอธิบาย",
          status: "สถานะ",
        },
        subTitle: "รายการเหตุการณ์",
      },
      checkPoint: {
        title: "แบบจำลองการวิเคราะห์",
        groups: "การจัดกลุ่มโมเดล",
        newClassification: "กลุ่มใหม่",
        editClassification: "แก้ไขการจัดกลุ่ม",
        benchmarkScore: "จุดอ้างอิง",
        pointCount:
          "แบบจำลองการวิเคราะห์ที่ใช้ร่วมกันภายใต้การจำแนกประเภทปัจจุบัน",
        openPointCount: "จำนวนที่เปิดใช้งานคือ",
        closedPointCount: "จำนวนการปิดใช้งานคือ",
        scoreLowerLimit: "คะแนนขั้นต่ำ",
        scoreUpperLimit: "คะแนนสูงสุด",
        classificationName: "ชื่อกลุ่ม",
        classificationNamePlaceHolder: "โปรดป้อนชื่อกลุ่ม",
        classificationRemark: "คำอธิบายกลุ่ม",
        classificationRemarkPlaceHolder: "โปรดป้อนคำอธิบายกลุ่ม",
      },
      chartRecord: {
        title: "เซสชัน WeChat ขององค์กร",
        table: {
          id: "เซสชัน ID",
          type: "ประเภทเซสชัน",
          startTime: "เวลาเริ่มต้นของเซสชัน",
          endTime: "เวลาสิ้นสุดของเซสชันล่าสุด",
          groupId: "กลุ่ม ID",
          client: "ลูกค้า",
          customer: "บริการลูกค้า",
          averageScore: "คะแนนการตรวจสอบคุณภาพเฉลี่ย",
        },
        detail: {
          title: "รายละเอียดเซสชัน WeChat ขององค์กร",
          inspectionInfo: "วิเคราะห์ข้อมูล",
          chartInfo: "ข้อมูลเซสชัน",
          tasks: "งานวิเคราะห์",
          avgScore: "วิเคราะห์คะแนนเฉลี่ย",
          qualified: "มีคุณสมบัติเหมาะสม",
          unqualified: "ไม่มีเงื่อนไข",
          taskTitle: "เทมเพลตการวิเคราะห์ที่ใช้งานได้",
          info: "การวิเคราะห์ข้อมูลแบบเรียลไทม์",
          today: "ข่าววันนี้",
          history: "ข่าวประวัติศาสตร์",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "กรุณากรอกหมายเหตุ/ชื่อเล่น",
                pleaseEnterTheGroupChatName: "กรุณากรอกชื่อการสนทนากลุ่ม",
              },
            },
          },
          template: { whole: "ทั้งหมด" },
        },
      },
      realTimeAnalysis: {
        title: "การวิเคราะห์แบบเรียลไทม์",
        table: {
          name: "ชื่อฉาก",
          count: "วิเคราะห์จำนวนรุ่น",
          baseScore: "จุดอ้างอิง",
          passScore: "คะแนนที่ผ่านการรับรอง",
          maxScore: "คะแนนสูงสุด",
          minScore: "คะแนนขั้นต่ำ",
          status: "สถานะ",
          actions: "การดำเนินงาน",
        },
        detail: {
          title: "การวิเคราะห์ฉากแบบเรียลไทม์",
          form: {
            name: "ชื่อฉาก",
            prevCondition: "เงื่อนไขทริกเกอร์ฉาก",
            prevConditionToolTip:
              "หากเงื่อนไขว่างเปล่าสถานการณ์จะถูกวิเคราะห์โดยค่าเริ่มต้น",
            prevConditionError:
              "เงื่อนไขทริกเกอร์ฉากไม่สามารถทับซ้อนกับรูปแบบการวิเคราะห์ได้",
            scoreInputPlaceholder: "ใส่เศษส่วน",
            baseScore: "จุดอ้างอิง",
            status: "สถานะ",
            passScore: "คะแนนที่ผ่านการรับรอง",
            maxScore: "เพดานคะแนนสูงสุด",
            minScore: "ขีดจำกัดล่างของคะแนนต่ำสุด",
            analysisModel: "แบบจำลองการวิเคราะห์",
            message: "ทริกเกอร์การแจ้งเตือน",
            pushType: "วิธีการผลักดัน",
            createAnalysisModel: "เพิ่มโมเดล",
            scoreUpperLimitError:
              "ขีดจำกัดล่างของคะแนนขั้นต่ำต้องไม่เกินขีดจำกัดบนของคะแนนสูงสุด",
            table: { name: "ชื่อรุ่น", rule: "กฎ", score: "คะแนน" },
            pushConfigSwitchTip1: "เมื่อฉากนั้นปรากฏขึ้น",
            pushConfigSwitchTip2: "ครั้งขึ้นไปกดการแจ้งเตือน",
          },
        },
      },
      smartCustomerTag: {
        title: "สมาร์ทฉลากลูกค้า",
        table: {
          name: "ชื่อกฎ",
          tags: "ฉลากลูกค้าขนาดเล็กขององค์กร",
          status: "เปิดใช้งานสถานะ",
          createUser: "ผู้สร้าง",
          createTime: "สร้างเวลา",
        },
        detail: {
          name: "ชื่อกฎ",
          condition: "ตั้งกฎ",
          logic: "ตรรกะ",
          status: "เปิดใช้งานสถานะ",
          tags: "เลือกแท็บ",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "จำนวนเครื่องหมายลูกค้า" },
        },
        messageFiltering: {
          template: {
            edit: "บรรณาธิการ",
            messageContent: "เนื้อหาข้อความ",
            messageTypeID: "ประเภทข้อความ ID",
            messageTypeDescription: "คำอธิบายประเภทข้อความ",
            messageFiltering: "กรองข้อความ",
            suitableForFilteringDataWithoutQualityInspection:
              "เหมาะสำหรับการกรองข้อมูลโดยไม่มีการตรวจสอบคุณภาพ",
            create: "สร้าง",
            messageDescription: "คำอธิบายข้อความ",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "เวลาพูดคุย",
          sessionType: "ประเภทเซสชัน",
          whole: "ทั้งหมด",
          staff: "พนักงาน",
          customer: "ลูกค้า",
          callKeywords: "คำสำคัญในการโทร",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "รายละเอียดการโทรด้วยเสียงไมโครขององค์กร",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "ที่เก็บเซสชัน",
        voiceCall: "การโทรด้วยเสียง",
        noMoreVoiceCalls: "ไม่มีการโทรด้วยเสียงเพิ่มเติม",
        enterpriseWechatVoiceCall: "การโทรด้วยเสียง WeChat ขององค์กร",
        enterpriseWechatConversationDetails:
          "รายละเอียดเซสชัน WeChat ขององค์กร",
        previous: "ก่อนหน้านี้",
        next: "ถัดไป",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "งานวิเคราะห์เซสชันไมโครขององค์กร",
          enterpriseAndMicroVoiceAnalysisTask:
            "งานวิเคราะห์ไมโครวอยซ์ขององค์กร",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "การวิเคราะห์แชทกลุ่ม WeChat ขององค์กร",
      },
      wechatQaSettingEntry: { messageFiltering: "กรองข้อความ" },
    },
    businessAnalysis: {
      keyword: "ชื่อรายงาน",
      createUser: "ผู้สร้าง",
      createTime: "สร้างเวลา",
      updateTime: "เวลาอัพเดต",
    },
    semanticIntelligence: {
      applicationMessageType: "ปพลิเคชันประเภทข้อความ",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "โปรดปรับปรุงการกำหนดค่า",
              generatePromptLanguage: "สร้างพรอมต์",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "พบหรือไม่",
              gradedEvaluation: "การประเมินไฟล์",
            },
          },
          training: {
            index: {
              pleaseEnter: "กรุณาใส่",
              pleaseGeneratePrompt: "กรุณาสร้างพรอมต์",
              pleaseSelectADialogue: "โปรดเลือกการสนทนา",
              training: "การฝึกอบรม",
              dataType: "ชนิดข้อมูล:",
              voiceCall: "การโทรด้วยเสียง",
              textDialogue: "บทสนทนาข้อความ",
              generate: "สร้าง",
              qualityInspectionResults: "ผลการตรวจสอบคุณภาพ:",
              hit: "ตีคำพูด:",
            },
          },
        },
        detail: {
          index: {
            modelName: "ชื่อรุ่น",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "โปรดป้อนชื่อรุ่นเช่น: ฝ่ายบริการลูกค้าเฉยหรือไม่",
            modelDescription: "คำอธิบายแบบจำลอง",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "คุณสามารถอธิบายเนื้อหาที่คุณต้องการทดสอบโดยละเอียดเพื่ออำนวยความสะดวกในการทำความเข้าใจโมเดลเช่น: ฝ่ายบริการลูกค้าเฉยเมยหรือไม่นั่นคือฝ่ายบริการลูกค้าได้ตอบคำถามของลูกค้าแต่ปัญหาของลูกค้ายังไม่ได้รับการจัดการตลอดทั้งบทความหรือเนื้อหาของการตอบกลับไม่มีส่วนเกี่ยวข้องกับการตอบกลับของลูกค้า",
            validity: "ประสิทธิผล",
            effective: "มีประสิทธิภาพ",
            invalid: "ไม่ถูกต้อง",
            type: "ประเภท",
            hintLanguage: "คำใบ้",
            editIntelligentModel: "แก้ไขโมเดลอัจฉริยะ",
            createANewIntelligentModel: "สร้างโมเดลอัจฉริยะใหม่",
          },
        },
        index: {
          modelName: "ชื่อรุ่น",
          modelDescription: "คำอธิบายแบบจำลอง",
          type: "ประเภท",
          whetherItMeetsTheRequirements: "พบหรือไม่",
          gradedEvaluation: "การประเมินไฟล์",
          validity: "ประสิทธิผล",
          intelligentModel: "โมเดลอัจฉริยะ",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "ใช้ได้กับการตรวจสอบคุณภาพแบบอัตนัย (เช่นทัศนคติในการให้บริการตรวจสอบคุณภาพมีความกระตือรือร้นหรือไม่การให้บริการมีความอดทนฯลฯ)",
        },
      },
      clusterAnalysis: "การวิเคราะห์คลัสเตอร์",
      speechMining: "การขุดคำ",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "รองรับการนำเข้าเอนทิตีข้อมูลเป็นกลุ่มผ่านอินเทอร์เฟซ API ไม่รองรับการแก้ไขหลังจากบันทึกชื่อ API แล้ว",
        },
        component: { nothing: "ไม่มี" },
      },
      knowledgeBase: {
        index: {
          classification: "หมวดหมู่",
          import: "นำเข้า",
          importKnowledgeBase: "นำเข้าฐานความรู้",
          moveKnowledgePointsInBatch: "จุดความรู้มือถือเป็นกลุ่ม",
          moveTo: "ย้ายไปที่:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "โปรดเลือกประเด็นความรู้ที่คุณต้องการย้ายเป็นชุด",
        },
        detail: {
          index: { intrasentence: "ภายในประโยค", afterProblem: "หลังจากคำถาม" },
        },
      },
      semanticTags: { component: { nothing: "ไม่มี" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "คำที่ละเอียดอ่อน",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "คำที่ละเอียดอ่อน: คำที่ละเอียดอ่อนสามารถกำหนดค่าได้ด้วยคำที่เกี่ยวข้องกับธุรกิจซึ่งใช้สำหรับความคิดเห็นสาธารณะเพื่อค้นหาคำที่ละเอียดอ่อนเมฆ",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "คำที่ละเอียดอ่อนใหม่",
            editSensitiveWords: "แก้ไขคำที่ละเอียดอ่อน",
            sensitiveWords: "คำที่ละเอียดอ่อน",
          },
          template: {
            sensitiveWords: "คำที่ละเอียดอ่อน",
            sensitiveWordsCannotBeEmpty: "คำที่ละเอียดอ่อนต้องไม่ว่างเปล่า",
            pleaseEnterSensitiveWordsSeparatedBy:
              'โปรดป้อนคำที่ละเอียดอ่อนคั่นด้วย "," ระหว่างคำที่ละเอียดอ่อนหลายคำ',
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "โปรดเลือกฉากแอป",
            keyWord: "คำสำคัญ",
            pleaseEnterKeywords: "กรุณาใส่คำสำคัญ",
            keywordLengthWithinCharacters: "ความยาวคำหลักภายใน40ตัวอักษร",
            dataSources: "แหล่งข้อมูล",
            pleaseSelectADataSource: "โปรดเลือกแหล่งข้อมูล",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "การทำคลัสเตอร์ปัจจุบันเสร็จสมบูรณ์แล้วการแก้ไขใหม่จะสร้างข้อมูลการทำคลัสเตอร์ใหม่ซึ่งจะไม่ครอบคลุมข้อมูลการทำคลัสเตอร์ที่เสร็จสมบูรณ์โปรดยืนยัน",
            savingSucceeded: "บันทึกความสำเร็จ",
            clusteringStart: "การจัดกลุ่มเริ่มต้น",
            clustering: "การจัดกลุ่ม",
            edit: "บรรณาธิการ",
            newlyBuild: "สร้างใหม่",
            clusterName: "ชื่อคลัสเตอร์",
            pleaseEnterAName: "กรุณาใส่ชื่อ",
            withinCharactersOfTheName: "ชื่อความยาวภายใน40ตัวอักษร",
            speaker: "ลำโพง",
            pleaseSelectASpeaker: "โปรดเลือกผู้พูด",
            sessionDate: "วันที่เซสชัน",
            pleaseEnterTheSessionDate: "โปรดป้อนวันที่สนทนา",
            selectUpToMonthOfHistoricalSessionData:
              "เลือกข้อมูลเซสชันประวัติศาสตร์ได้สูงสุด1เดือน",
            automaticallyClusterDailyNewData:
              "การจัดกลุ่มอัตโนมัติเพิ่มข้อมูลทุกวัน",
            conversationChannel: "ช่องสนทนา",
            pleaseSelectAConversationChannel: "โปรดเลือกช่องทางการสนทนา",
            applicationScenario: "ฉากการใช้งาน",
            clusteringRules: "กฎการจัดกลุ่ม",
            incompleteClusteringRuleSettings:
              "การตั้งค่ากฎการจัดกลุ่มไม่สมบูรณ์",
            startClustering: "เริ่มการจัดกลุ่ม",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask: "โปรดเลือกงานตรวจสอบคุณภาพ",
              beEqualTo: "เท่ากับ",
            },
          },
        },
        detail: {
          index: {
            theme: "ธีม",
            numberOfCorpora: "จำนวนคลังข้อมูล",
            corpusCoverage: "ครอบคลุมคลังข้อมูล",
            clusterDetails: "รายละเอียดการทำคลัสเตอร์",
          },
          ranking: {
            hotWords: "คำร้อน",
            numberOfCorpora: "จำนวนคลังข้อมูล",
            speaker: "ลำโพง",
            sit: "นั่ง",
            passenger: "แขก",
            hotSentence: "ประโยคร้อน",
            corpusDetails: "รายละเอียดคลังข้อมูล",
          },
        },
        index: {
          delete: "ลบ",
          deletionSucceeded: "ลบสำเร็จ",
          clusterName: "ชื่อคลัสเตอร์",
          speaker: "ลำโพง",
          creationTime: "สร้างเวลา",
          state: "สถานะ",
          operation: "การดำเนินงาน",
          see: "ดู",
          edit: "บรรณาธิการ",
          clusterAnalysis: "การวิเคราะห์คลัสเตอร์",
          newCluster: "คลัสเตอร์ใหม่",
          clusteringStatus: "สถานะการจัดกลุ่ม",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "ป้อนคำสำคัญเพื่อค้นหาการคัดค้าน",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "ห้องสมุดคำ",
                recommendedScripts: "คำพูดที่แนะนำ",
                common: "รวม",
                strip: "แถบ",
                scriptFavorites: "รายการโปรด",
              },
              conditionPostfix: {
                detectRoles: "ตรวจจับบทบาท",
                detectionRange: "ช่วงการตรวจสอบ",
                sentence: "ประโยค",
              },
              customerFilter: {
                scriptFiltering: "การกรองคำ",
                cancel: "การยกเลิก",
              },
              index: {
                viewDetails: "ดูรายละเอียด",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "คุณยืนยันที่จะยอมแพ้หรือไม่? จะไม่สามารถกู้คืนได้หลังจากทิ้ง",
                abandonment: "ละทิ้ง",
                deletionSucceeded: "ลบสำเร็จ",
                passenger: "แขก",
                sit: "นั่ง",
                adopted: "นำมาใช้",
                determine: "กำหนด",
                cancel: "การยกเลิก",
                adopt: "รับเลี้ยงบุตรบุญธรรม",
              },
              pageRightFrom: {
                savingSucceeded: "บันทึกความสำเร็จ",
                pleaseEnterTheScriptContent: "กรุณาใส่เนื้อหาของคำ",
                pleaseSelectAFavoriteGroup: "โปรดเลือกกลุ่มรายการโปรด",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary: "ฐานข้อมูลการจัดการการคัดค้าน",
              },
              pageRightTable: {
                index: { pleaseChooseToAdoptScript: "โปรดเลือกใช้ทักษะการพูด" },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "รายการโปรดของการจัดการการคัดค้าน",
            },
            pageRightTable: {
              likeCancel: "ชอบยกเลิก",
              likeSuccessfully: "ชอบสำเร็จ",
              adoptScripts: "ใช้ทักษะการพูด",
              operation: "การดำเนินงาน",
              viewConversationDetails: "ดูรายละเอียดการสนทนา",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "คลังคำจุดขายสินค้า",
              },
              pageRightTable: { keyPointsOfScript: "ประเด็นหลักของการพูด" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "รายการโปรดของการจัดการการคัดค้าน",
              newGroup: "กลุ่มใหม่",
              editGroup: "แก้ไขการจัดกลุ่ม",
              addGroup: "เพิ่มการจัดกลุ่ม",
              savingSucceeded: "บันทึกความสำเร็จ",
              groupName: "ชื่อกลุ่ม",
              pleaseEnterTheGroupName: "โปรดป้อนชื่อกลุ่ม",
              groupNameWithinCharacters: "ชื่อกลุ่มความยาวภายใน20ตัวอักษร",
              deletionSucceeded: "ลบสำเร็จ",
            },
            pageRightTable: {
              configDrawer: {
                index: { scriptRecommendationSettings: "การตั้งค่าที่แนะนำ" },
                rightForm: {
                  savingSucceeded: "บันทึกความสำเร็จ",
                  intelligentRecommendation: "คำแนะนำที่ชาญฉลาด",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "แสดงคำที่ยอดเยี่ยมที่นำมาใช้โดยอัตโนมัติในแถบด้านข้างของการสนทนาของการคัดค้านที่คล้ายกันและพนักงานสามารถดูคำที่แนะนำได้โดยตรงเพื่อเรียนรู้ในหน้ารายละเอียดของการตรวจสอบเซสชัน",
                  objectionsIncludedByDefault: "การคัดค้านรวมอยู่ในค่าเริ่มต้น",
                  addRecommendationTriggerSemantics:
                    "เพิ่มความหมายทริกเกอร์ที่แนะนำ",
                  remindEmployeesToView: "เตือนให้พนักงานดู",
                  shareWithMoreEmployees: "แบ่งปันให้กับพนักงานมากขึ้น",
                  allHands: "พนักงานทุกคน",
                },
                smartToolbar: { selectSemanticTag: "เลือกแท็บความหมาย" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "คุณส่งออกระเบียนข้อมูลทั้งหมดภายใต้กลุ่มปัจจุบันหรือไม่",
                newScript: "ทักษะการพูดใหม่",
                editingScript: "ทักษะการแก้ไข",
                savingSucceeded: "บันทึกความสำเร็จ",
                objection: "คัดค้าน",
                pleaseEnterAScriptObjection: "กรุณาใส่คำคัดค้าน",
                speechTechnique: "ทักษะการพูด",
                pleaseEnterTheScriptContent: "กรุณาใส่เนื้อหาของคำ",
                grouping: "การจัดกลุ่ม",
                pleaseSelectAFavoriteGroup: "โปรดเลือกกลุ่มรายการโปรด",
                adoptScripts: "ใช้ทักษะการพูด",
                passenger: "แขก",
                sit: "นั่ง",
                numberOfViews: "จำนวนการดู",
                useful: "มีประโยชน์",
                operation: "การดำเนินงาน",
                edit: "บรรณาธิการ",
                delete: "ลบ",
                deletionSucceeded: "ลบสำเร็จ",
                scriptRecommendationSettings: "การตั้งค่าที่แนะนำ",
              },
            },
          },
        },
        index: {
          scriptLibrary: "ห้องสมุดคำ",
          scriptMining: "การขุดคำ",
          objectionHandlingScripts: "การจัดการการคัดค้าน",
          productSellingPointScripts: "จุดขายสินค้า",
        },
        sellingPoint: {
          index: { newCluster: "คลัสเตอร์ใหม่" },
          components: {
            callDetailModal: {
              sessionDetails: "รายละเอียดเซสชัน",
              cancel: "การยกเลิก",
              determine: "กำหนด",
              choice: "เลือก",
            },
            pageCenterTable: { scriptOverview: "ภาพรวมของการพูด" },
            pageLeftTable: {
              enterSearchKeywords: "ป้อนคำค้นหา",
              keyWord: "คำสำคัญ",
            },
            pageRightTable: {
              index: {
                viewDetails: "ดูรายละเอียด",
                scriptLibrary: "ห้องสมุดคำ",
                scriptRefinement: "การปรับแต่งคำ",
                adoptScript: "ใช้ทักษะการพูด",
              },
              tagsEdit: { keyPointsOfScript: "ประเด็นหลักของการพูด" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "คลังคำจุดขายสินค้า",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "จุดขายที่รวมอยู่ในค่าเริ่มต้น",
                  },
                },
                index: {
                  keyWord: "คำสำคัญ",
                  pleaseSelectAKeyword: "โปรดเลือกคำสำคัญ",
                  keyPointsOfScript: "ประเด็นหลักของการพูด",
                  pleaseInputTheKeyPointsOfTheScript:
                    "กรุณากรอกประเด็นหลักของการพูด",
                  viewDetails: "ดูรายละเอียด",
                  pleaseChooseToAdoptScript: "โปรดเลือกใช้ทักษะการพูด",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "ตรรกะการจับคู่",
            nLPMatching: "NLP จับคู่",
            strictlyMatchByWord: "จับคู่อย่างเคร่งครัดตามคำ",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "ชื่อประเภทข้อความ",
          messageTypeID: "ประเภทข้อความ ID",
          messageTypeDescription: "คำอธิบายประเภทข้อความ",
          applicationMessageType: "ปพลิเคชันประเภทข้อความ",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "เหมาะสำหรับการเข้าถึงข้อมูลจากช่องทางต่างๆสำหรับการตรวจสอบคุณภาพและจำเป็นต้องจัดประเภทตามการระบุช่อง",
        },
        detail: {
          index: {
            messageTypeName: "ชื่อประเภทข้อความ",
            pleaseEnterAMessageTypeName: "โปรดป้อนชื่อประเภทข้อความ",
            messageTypeDescription: "คำอธิบายประเภทข้อความ",
            describeYourMessageTypeInDetail:
              "สามารถอธิบายประเภทข้อความของคุณโดยละเอียดได้",
            editMessageType: "แก้ไขประเภทข้อความ",
            newMessageType: "ประเภทข้อความใหม่",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "โปรดเลือกสมาชิกในทีม",
        existingFilter: "ตัวกรองที่มีอยู่",
        pleaseSelect: "โปรดเลือก",
        filterCondition: "กรองเงื่อนไข",
        areYouSureToDeleteTheCurrentFilter:
          "คุณยืนยันที่จะลบตัวกรองปัจจุบันหรือไม่?",
        sessionDate: "วันที่เซสชัน",
        staff: "พนักงาน",
        customer: "ลูกค้า",
        pleaseEnterCustomerName: "โปรดป้อนชื่อลูกค้า",
        keyWord: "คำสำคัญ",
        whole: "ทั้งหมด",
        pleaseEnterSessionKeywords: "โปรดป้อนคำสำคัญของการสนทนา",
        sessionDuration: "ความยาวของเซสชัน",
        sessionLabel: "ฉากสนทนา",
        pleaseSelectASessionLabel: "โปรดเลือกฉากการสนทนา",
        commentTopic: "หัวข้อความคิดเห็น",
        pleaseSelectACommentTopic: "โปรดเลือกหัวข้อความคิดเห็น",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "คุณยืนยันว่าจะเขียนทับตัวกรองปัจจุบันหรือไม่?",
        overlayFilter: "ครอบคลุมตัวกรอง",
        NewFilter: "ตัวกรองใหม่",
        newFilter: "ตัวกรองใหม่",
        filterName: "ชื่อตัวกรอง",
        pleaseEnter: "กรุณาใส่",
        allCall: "ทุกสาย",
        percentage: "ร้อยละ",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'รองรับการค้นหาคำหลักหลายคำความสัมพันธ์ "หรือ" คั่นด้วยเครื่องหมายจุลภาค (ภาษาจีนและภาษาอังกฤษ) และความสัมพันธ์ "และ" คั่นด้วยเครื่องหมายหยุดชั่วคราวเช่น:',
          callRecordID: "ID บันทึกการโทร",
          pleaseFillInTheCallRecordID: "กรุณากรอก ID บันทึกการโทร",
          minute: "นาที",
          second: "วินาที",
          whole: "ทั้งหมด",
          callRecording: "บันทึกการโทร",
          enterpriseWechat: "องค์กร WeChat",
          conversationChannel: "ช่องสนทนา",
          pleaseSelectASessionChannel: "โปรดเลือกช่องทางการสนทนา",
          learningStatus: "สถานะการเรียนรู้",
          pleaseSelectLearningStatus: "โปรดเลือกสถานะการเรียนรู้",
          notLearned: "ไม่ได้เรียน",
          learned: "ได้เรียนรู้",
          Minutes: "นาที",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "เงื่อนไขตัวกรองว่างเปล่าและไม่รองรับตัวกรองใหม่!",
          filterAddedSuccessfully: "เพิ่มตัวกรองเรียบร้อยแล้ว!",
          failedToAddFilter: "ตัวกรองใหม่ล้มเหลว!",
          filterDeletedSuccessfully: "ลบตัวกรองสำเร็จ!",
          filterDeletionFailed: "ลบตัวกรองล้มเหลว!",
          filterOverwriteSucceeded: "ครอบคลุมตัวกรองเรียบร้อยแล้ว!",
          filterOverwriteFailed: "ครอบคลุมตัวกรองล้มเหลว!",
          theme: "ธีม",
          pleaseSelectATheme: "โปรดเลือกธีม",
          keyEvents: "เหตุการณ์สำคัญ",
          pleaseSelectAKeyEvent: "โปรดเลือกเหตุการณ์สำคัญ",
        },
        filterItem: {
          startRetesting: "เริ่มการตรวจสอบอีกครั้ง",
          time: "เวลา",
          talkTime: "ระยะเวลาการโทร",
          keyEvents: "เหตุการณ์สำคัญ",
          totalImplementationRateOfSupervisionItems:
            "อัตราการดำเนินการทั้งหมดของรายการกำกับดูแล",
          sort: "เรียงลำดับ:",
          seeMore: "ดูเพิ่มเติม",
        },
        filterStatistics: {
          conversation: "โทร",
          weChat: "WeChat",
          quantity: "จำนวน",
        },
      },
      caseBase: {
        index: {
          copyTo: "คัดลอกไปที่",
          pleaseSelectTheLocationToMove: "โปรดเลือกตำแหน่งที่จะย้าย!",
          folderCopiedSuccessfully: "คัดลอกโฟลเดอร์สำเร็จ!",
          cancelCollectionSuccessfully: "ยกเลิกการรวบรวมสำเร็จ!",
          collectionSucceeded: "คอลเลกชันสำเร็จ!",
          starCaseBase: "ไลบรารีเคสดาว",
          move: "ย้าย",
          rename: "เปลี่ยนชื่อ",
          delete: "ลบ",
          folderMovedSuccessfully: "ย้ายโฟลเดอร์ที่ประสบความสำเร็จ!",
          fileMovedSuccessfully: "ย้ายไฟล์สำเร็จ!",
          fileMoveFailed: "ย้ายไฟล์ล้มเหลว!",
          folderAddedSuccessfully: "เพิ่มโฟลเดอร์ที่ประสบความสำเร็จ!",
          folderRenameSucceeded: "เปลี่ยนชื่อโฟลเดอร์เรียบร้อยแล้ว!",
          fileRenameSucceeded: "เปลี่ยนชื่อไฟล์สำเร็จ!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "หลังจากลบโฟลเดอร์แล้วจะไม่สามารถเรียกคืนได้คุณยืนยันการลบหรือไม่?",
          deleteSucceeded: "ลบสำเร็จ!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "หลังจากลบไฟล์แล้วจะไม่สามารถเรียกคืนได้คุณยืนยันการลบหรือไม่?",
          share: "รวม",
          folders: "โฟลเดอร์",
          calls: "โทร",
          newFolder: "เพิ่มโฟลเดอร์",
          modifyFolder: "แก้ไขโฟลเดอร์",
          title: "ชื่อเรื่อง",
          pleaseEnter: "กรุณาใส่",
          selectMoveLocation: "เลือกตำแหน่งที่จะย้าย",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "การแจ้งเตือนการเรียนรู้",
          subscriptionDynamics: "สมัครรับข่าวสาร",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "เซสชันที่คุณสมัคร (ID",
            thereAreDynamicUpdates: "มีการอัปเดตแบบไดนามิก",
            newCommentsReplies: "เพิ่มความคิดเห็น/ตอบกลับ",
            strip: "แถบ",
            newHelp: "เพิ่มความช่วยเหลือ",
            goAndWatch: "ไปดู",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "ตอบกลับความคิดเห็นของฉัน",
            replySucceeded: "ตอบกลับสำเร็จ!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "ในความคิดเห็น @ ฉัน",
            replySucceeded: "ตอบกลับสำเร็จ!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "แบ่งปันให้คุณ",
            shareCasesForYou: "แชร์เคสให้คุณ",
          },
        },
        study: {
          index: {
            learned: "ได้เรียนรู้",
            ignored: "ไม่สนใจ",
            study: "การเรียนรู้",
            ignore: "ละเว้น",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "นำเข้าเรียบร้อยแล้ว",
          account: "บัญชี",
          password: "รหัสผ่าน",
          cover: "ครอบคลุม",
          skip: "ข้าม",
          import: "นำเข้า",
          importEmployees: "นำเข้าพนักงาน",
          importPrompt: "เคล็ดลับการนำเข้า",
          confirm: "กำหนด",
          theDataContainsDuplicateAccountsOrNames:
            "ข้อมูลมีหมายเลขบัญชีหรือชื่อที่ซ้ำกัน",
          allCoverage: "ครอบคลุมทั้งหมด",
          skipAll: "ข้ามทั้งหมด",
          headPortrait: "อวตาร",
          fullName: "ชื่อ",
          nickname: "ชื่อเล่น",
          excellentStaff: "พนักงานที่ยอดเยี่ยม",
          yes: "ใช่",
          no: "ไม่ใช่",
          department: "แผนก",
          accountNumberemail: "บัญชี (อีเมล)",
          telephone: "โทรศัพท์",
          jobNumber: "หมายเลขงาน",
          role: "บทบาท",
          pleaseEnterANameOrPhoneSearch:
            "กรุณากรอกอีเมลชื่อหรือค้นหาทางโทรศัพท์",
          person: "คน)",
          AddEmployee: "เพิ่มพนักงาน",
        },
        edit: {
          jobNo: "หมายเลขงาน",
          udeskCustomerServiceSystemIdentity:
            "ข้อมูลประจำตัวของระบบบริการลูกค้า Udesk",
          enterpriseWechatIdentity: "ข้อมูลประจำตัว WeChat ขององค์กร",
          inputID: "ใส่โลโก้",
          newIdentityBinding: "เพิ่มการผูกข้อมูลประจำตัว",
          identityBinding: "การผูกตัวตน",
          avatarGreaterThan500kb: "อวตารมากกว่า500Kb",
          uploadSuccessful: "อัพโหลดสำเร็จ",
          upload: "อัปโหลด",
          supportJPGGIFAndPNGUpTo500kb: "รองรับ jpg, gif, png, สูงสุด500Kb",
          yes: "ใช่",
          no: "ไม่ใช่",
          accountNumber: "บัญชี",
          pleaseEnterTheAccountNumber: "กรุณากรอกหมายเลขบัญชี",
          initialPassword: "รหัสผ่านเริ่มต้น",
          pleaseInputAPassword: "กรุณาใส่รหัสผ่าน",
          confirmPassword: "ยืนยันรหัสผ่าน",
          thePasswordsEnteredTwiceAreDifferent:
            "รหัสผ่านที่ป้อนสองครั้งไม่เหมือนกัน!",
          headPortrait: "อวตาร",
          fullName: "ชื่อ",
          pleaseEnterYourName: "กรุณากรอกชื่อ",
          nickname: "ชื่อเล่น",
          department: "แผนก",
          excellentStaff: "พนักงานที่ยอดเยี่ยม",
          sessionIdentity: "การระบุเซสชัน",
          markTheSessionRecordAsBelongingToTheEmployee:
            "ทำเครื่องหมายบันทึกเซสชันเป็นของพนักงาน",
          telephone: "โทรศัพท์",
          jobNumber: "หมายเลขงาน",
          role: "บทบาท",
          pleaseSelectARole: "โปรดเลือกบทบาท",
          preservation: "บันทึก",
          cancel: "การยกเลิก",
          addedSuccessfully: "เพิ่มความสำเร็จ!",
          modificationSucceeded: "แก้ไขสำเร็จ!",
          newEmployees: "เพิ่มพนักงานใหม่",
          editEmployee: "แก้ไขพนักงาน",
        },
        detail: {
          viewDetails: "ดูรายละเอียด",
          accountNumber: "บัญชี",
          headPortrait: "อวตาร",
          fullName: "ชื่อ",
          nickname: "ชื่อเล่น",
          department: "แผนก",
          excellentStaff: "พนักงานที่ยอดเยี่ยม",
          yes: "ใช่",
          no: "ไม่ใช่",
          sessionIdentity: "การระบุเซสชัน",
          markTheSessionRecordAsBelongingToTheEmployee:
            "ทำเครื่องหมายบันทึกเซสชันเป็นของพนักงาน",
          telephone: "โทรศัพท์",
          jobNumber: "หมายเลขงาน",
          role: "บทบาท",
        },
      },
      roleManage: {
        list: {
          menu: "เมนู",
          functionalCategory: "ประเภทฟังก์ชัน",
          functionalSubclass: "หมวดหมู่ย่อยที่ใช้งานได้",
          deletedSuccessfully: "ลบสำเร็จ",
          NewRole: "ตัวละครใหม่",
          role: "บทบาท",
          dataPermissions: "สิทธิ์ข้อมูล:",
          edit: "บรรณาธิการ",
          areYouSureYouWantToDeleteTheRole: "กำหนดลบบทบาท",
          determine: "กำหนด",
          cancel: "การยกเลิก",
          delete: "ลบ",
          applicationPermission: "สิทธิ์ของแอป",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData: "ดูเฉพาะข้อมูลเซสชันของคุณเอง",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "คุณสามารถดูข้อมูลเซสชันของแผนกนี้และแผนกรอง",
          menu: "เมนู",
          functionalCategory: "ประเภทฟังก์ชัน",
          functionalSubclass: "หมวดหมู่ย่อยที่ใช้งานได้",
          successfullyAdded: "เพิ่มความสำเร็จ",
          modifiedSuccessfully: "แก้ไขสำเร็จ",
          edit: "บรรณาธิการ",
          cancel: "การยกเลิก",
          confirm: "ยืนยัน",
          roleName: "ชื่อตัวละคร",
          pleaseEnterTheRoleName: "โปรดป้อนชื่อตัวละคร",
          dataAuthority: "สิทธิ์ข้อมูล",
          youCanViewCompanyWideSessionData: "สามารถดูข้อมูลเซสชันของทั้งบริษัท",
          applicationPermission: "สิทธิ์ของแอป",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "กระบวนการสนทนา",
          generateProcess: "สร้างกระบวนการ",
          addProcess: "เพิ่มกระบวนการ",
          cancel: "การยกเลิก",
          complete: "เสร็จแล้ว",
          edit: "บรรณาธิการ",
          theSettingWillBeSuccessfulLater:
            "การตั้งค่าจะเห็นความสำเร็จในภายหลัง!",
          previous: "ก่อนหน้านี้",
          next: "ถัดไป",
          debuggingMode: "โหมดดีบัก",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "หลังจากเปิดข้อความหากตรงกับเหตุการณ์สำคัญรายการกำกับดูแลกฎของฉลากลูกค้าหรือกฎประเด็นความรู้จะแสดงชื่อของกฎที่ตรงกับข้อความและชื่อของประเด็นความรู้ของฐานความรู้",
          seeLater: "ดูในภายหลัง",
          determine: "กำหนด",
          conversationalInsights: "ข้อมูลเชิงลึกการสนทนา",
          customerInsight: "ข้อมูลเชิงลึกของลูกค้า",
          knowledgePointRecommendation: "คำแนะนำความรู้",
          comment: "ความคิดเห็น",
          sessionQuality: "คุณภาพของเซสชัน",
          setToListenLaterSuccessfully:
            "การตั้งค่าที่จะฟังความสำเร็จในภายหลัง!",
          listenLater: "ฟังในภายหลัง",
          seekCounselingSuccess: "แสวงหาความสำเร็จในการให้คำปรึกษา!",
          altogether: "ทั้งหมด",
          appearsAt: "ปรากฏทุกที่'",
          commentsPublishedSuccessfully: "แสดงความคิดเห็นสำเร็จ",
          callRecording: "บันทึกการโทร",
          addedSuccessfully: "เพิ่มความสำเร็จ",
          shareWithColleagues: "แบ่งปันกับเพื่อนร่วมงาน",
          shareWithCustomers: "แบ่งปันให้กับลูกค้า",
          resumptionDetails: "รายละเอียดการตรวจสอบ",
          addToCaseBase: "เพิ่มลงในไลบรารีกรณี",
          shareCases: "แบ่งปันกรณี",
          seekCounseling: "ขอคำปรึกษา",
          sessionRecord: "บันทึกเซสชัน",
          businessRecord: "บันทึกธุรกิจ",
          keywordSearch: "การค้นหาคำหลัก",
          whole: "ทั้งหมด",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "โปรดป้อนความคิดเห็นป้อน @ เพื่อแจ้งให้ผู้อื่นป้อน # เพื่อเพิ่มหัวข้อ",
          subscriptionSession: "เซสชันการสมัครสมาชิก",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "หลังจากสมัครสมาชิกคุณจะได้รับการแจ้งเตือนเมื่อมีความช่วยเหลือใหม่ความคิดเห็นและการตอบกลับ",
        },
      },
      coachTask: {
        index: {
          employeeName: "ชื่อพนักงาน",
          customerName: "ชื่อลูกค้า",
          source: "แหล่งที่มา",
          viewSessionRecords: "ดูบันทึกเซสชัน",
          coachingStatus: "สถานะการให้คำปรึกษา",
          helpTime: "เวลาช่วยเหลือ",
          counselingTime: "เวลาให้คำปรึกษา",
          helpContent: "เนื้อหาความช่วยเหลือ",
          coachingOperation: "การดำเนินการให้คำปรึกษา",
          areYouSureYouWantToIgnoreIt: "คุณแน่ใจหรือว่าคุณไม่สนใจ?",
          determine: "กำหนด",
          cancel: "การยกเลิก",
          ignoreHelp: "ละเว้นการขอความช่วยเหลือ",
          coaching: "ให้คำปรึกษา",
          viewCoaching: "ดูการให้คำปรึกษา",
          areYouSureToReturnToCounseling: "คุณแน่ใจหรือว่าจะกลับไปให้คำปรึกษา?",
          returnToPendingCoaching: "กลับไปให้คำปรึกษา",
          tutor: "ที่ปรึกษา",
          helpSeekingStatus: "สถานะความช่วยเหลือ",
          recentCoachingTime: "เวลาติวล่าสุด",
          operation: "การดำเนินงาน",
          ignoreSuccess: "ละเว้นความสำเร็จ!",
          recoverySucceeded: "กู้คืนสำเร็จ!",
          successfulCoaching: "ติวสำเร็จ!",
          myMission: "ภารกิจของฉัน",
          selectCoachingStatus: "เลือกสถานะการให้คำปรึกษา",
          whole: "ทั้งหมด",
          iAskedForHelp: "ฉันขอความช่วยเหลือ",
          signOut: "ออก",
          submit: "ส่ง",
          helpPerson: "ขอความช่วยเหลือ",
          customer: "ลูกค้า",
          toBeCoached: "กำลังรอการให้คำปรึกษา",
          counselingSuggestions: "คำแนะนำการให้คำปรึกษา",
          pleaseEnterCoachingSuggestions: "กรุณากรอกคำแนะนำการให้คำปรึกษา",
        },
      },
      coachTaskCenter: {
        title: "ศูนย์ภารกิจ",
        list: {
          taskName: "ชื่องาน",
          lessonGroup: "การจัดหมวดหมู่หลักสูตร",
          lessonName: "หลักสูตร",
          taskType: "ประเภทงาน",
          taskStatus: "สถานะงาน",
          taskExecutionInfo: "สถานการณ์การดำเนินการ",
          totalCount: "จำนวนคนที่ควรดำเนินการ",
          executeCount: "จำนวนคนที่ถูกประหารชีวิต",
          executeRate: "อัตราการดำเนินการ",
          completeCount: "จำนวนคนที่เสร็จสมบูรณ์",
          completeRate: "อัตราความสำเร็จ",
          commitCount: "จำนวนคนที่ตรงตามมาตรฐาน",
          commitRate: "อัตราการปฏิบัติตาม",
          excellentRate: "อัตราที่ยอดเยี่ยม",
          taskTime: "เวลาภารกิจ",
          option: "การดำเนินงาน",
          edit: "บรรณาธิการ",
          taskDetail: "ข้อมูลงาน",
          delete: "ลบ",
          new: "วางจำหน่ายใหม่",
          taskTypeAll: "ทั้งหมด",
          taskTypePractice: "การออกกำลังกาย",
          taskTypeTest: "การสอบ",
          taskStatusNotStarted: "ยังไม่เริ่ม",
          taskStatusRunning: "กำลังดำเนินการอยู่",
          taskStatusEnd: "สิ้นสุดแล้ว",
          taskStatusInvalid: "ไม่มีผล",
          unlimitedTime: "ไม่จำกัดเวลา",
          to: "ถึง",
          deleteConfirm:
            "หลังจากลบแล้วบันทึกการเรียนรู้ทั้งหมดภายใต้งานนี้จะถูกล้างคุณยืนยันการลบหรือไม่?",
          deleteSuccess: "ลบงานได้สำเร็จ",
        },
        edit: {
          addTask: "งานปล่อยใหม่",
          editTask: "แก้ไขงานเผยแพร่",
          publishSuccess: "เปิดตัวสำเร็จ",
          publishFailed: "ล้มเหลวในการเผยแพร่",
          updateSuccess: "อัปเดตเรียบร้อยแล้ว",
          updateFailed: "การปรับปรุงล้มเหลว",
          cannotBeNull: "ไม่ว่างเปล่า",
          explain: "คำอธิบาย",
          taskDescExtra: "(เนื้อหาที่นี่จะแสดงที่ทางเข้า {0})",
          taskTargetObj: "โพสต์วัตถุ",
          isEnable: "สถานะมีผลบังคับใช้",
          dialogueMode: "โหมดการสนทนา",
          voice: "เสียง",
          text: "ข้อความ",
          userPortrait: "ภาพผู้ใช้",
          difficultyLevel: "ความยาก",
          limitDuration: "จำกัดระยะเวลา",
          tipLabel: "เคล็ดลับการพูด",
          tipTooltip: "อนุญาตให้นักเรียนดูคำอ้างอิงหรือไม่",
          limitTimes: "จำนวนการสอบ",
          time: "เวลา",
          gradingStandart: "เกณฑ์การให้คะแนน",
          gradingStandartDescAffix: "(กระบวนการสนทนาปัจจุบันจะถูกแบ่งออกเป็น",
          gradingStandartDescSuffix:
            "คะแนน, ทักษะการพูด {0} คะแนน, อารมณ์ {1} คะแนน, ความเร็วในการพูด {2} คะแนน)",
          completeStandart: "ภารกิจเสร็จสิ้นความต้องการ",
          byTimes: "ตามจำนวนครั้ง",
          byScore: "คะแนนตาม",
          completeByTimes: "จำนวนการออกกำลังกาย",
          times: "ครั้ง",
          score: "แบ่ง",
          anyTimes: "ครั้งใดก็ได้",
          getScore: "คะแนน",
          offLimitDuration: "ยาวไม่จำกัดเวลา",
          onLimitDuration: "ระยะเวลาจำกัด",
          commitScore: "มาตรฐานคะแนน",
          excellentScore: "มาตรฐานที่ยอดเยี่ยมคะแนน",
          offLimitTime: "ไม่จำกัดเวลา",
          onLimitTime: "จำกัดเวลา",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "สวัสดี," },
          callDynamic: {
            today: "วันนี้",
            yesterday: "เมื่อวานนี้",
            sessions: "เซสชัน",
          },
          todoList: {
            strip: "แถบ",
            theSessionIsAddedTolistenLater: 'การสนทนาจะถูกเพิ่ม "ฟังในภายหลัง"',
            askYouForHelp: "ขอความช่วยเหลือจากคุณ",
            iHopeToContactYouLater: "คาดว่าจะติดต่อได้ในภายหลัง",
            lookingForwardToSendingTheInformationLater:
              "คาดว่าจะส่งข้อมูลในภายหลัง",
            dialogueRisk: "เซสชันมีจุดเสี่ยง",
            tutored: "ติวแล้ว",
            goToCounseling: "ไปติว",
            contacted: "ติดต่อมาแล้ว",
            hasBeenSent: "ส่งไปแล้ว",
            areYouSureYouWantToIgnoreIt: "คุณแน่ใจหรือว่าคุณไม่สนใจ?",
            determine: "กำหนด",
            cancel: "การยกเลิก",
            ignore: "ละเว้น",
            checked: "ดูแล้ว",
          },
          callListItem: {
            strip: "แถบ",
            learned: "ได้เรียนรู้",
            keyEvents: "เหตุการณ์สำคัญ",
            totalImplementationRateOfSupervisionItems:
              "อัตราการดำเนินการทั้งหมดของรายการกำกับดูแล",
          },
        },
        index: {
          operationSucceeded: "การดำเนินการประสบความสำเร็จ!",
          sessionDynamics: "เซสชันไดนามิก",
          my: "ของฉัน",
          department: "แผนก",
          company: "บริษัท",
          toDoList: "สิ่งที่ต้องทำ",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "ปริมาณการโทรที่มีประสิทธิภาพ (ก)",
          bestPractices: "ปฏิบัติที่ดีที่สุด",
          averageDurationOfASingleCallminutes:
            "ระยะเวลาการโทรเฉลี่ยต่อครั้ง (นาที)",
          totalCallVolumePCs: "ปริมาณการโทรทั้งหมด (ก)",
          totalTalkTimehours: "ระยะเวลาการโทรทั้งหมด (ชั่วโมง)",
          speakingPercentagepercentage: "สัดส่วนการพูด (ร้อยละ)",
          maximumContinuousSpeakingTimeminutes:
            "ระยะเวลาการพูดต่อเนื่องสูงสุด (นาที)",
          numberOfIndepthDiscussionsPCs: "จำนวนการอภิปรายเชิงลึก (a)",
          maximumCustomerTalkminutes: "คำพูดของลูกค้าที่ยาวที่สุด (นาที)",
          questionFrequencyPCs: "ความถี่ในการถามคำถาม (ก)",
          averageValue: "ค่าเฉลี่ย",
          selectDate: "เลือกวันที่",
          personnel: "บุคลากร",
          pleaseSelect: "โปรดเลือก",
          callRange: "ช่วงการโทร",
          conversationActivity: "กิจกรรมการสนทนา",
          conversationAction: "การดำเนินการเซสชัน",
          keyEvents: "เหตุการณ์สำคัญ",
          callRecord: "บันทึกการโทร",
          filter: "กรอง:",
          conversation: "โทร",
          minute: "นาที",
          sale: "พนักงาน",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "เดือนมกราคม",
            numberOfCalls: "จำนวนการโทร",
            accountInformation: "ข้อมูลบัญชี",
            month: "เดือน",
            today: "วันนี้",
            customerDetails: "รายละเอียดของลูกค้า",
            searchKeywordsInCommunicationContent:
              "ค้นหาคำสำคัญในเนื้อหาการสื่อสาร",
            allMembers: "สมาชิกทั้งหมด",
            reset: "รีเซ็ต",
            query: "สอบถามข้อมูล",
            allTrip: "การแสดงการเดินทางเต็มรูปแบบ",
            contactOverview: "ภาพรวมการติดต่อ",
            lastContact: "ติดต่อล่าสุด",
            nextContact: "ติดต่อครั้งต่อไป",
            previous: "ก่อนหน้านี้",
            next: "ถัดไป",
            viewDetails: "ดูรายละเอียด",
            contactDynamics: "ติดต่อข่าวสาร",
            lastYear: "ปีล่าสุด",
            lastCall: "การโทรล่าสุด",
            hotTopicsForDiscussion: "หัวข้อสนทนายอดนิยม",
            totalInTheLastYear: "รวมในปีที่แล้ว",
            callTimes: "โทร",
          },
        },
        list: {
          index: {
            risk: "ความเสี่ยง",
            have: "มี",
            nothing: "ไม่มี",
            channel: "ช่อง",
            callRecording: "บันทึกการโทร",
            enterpriseWeChatConversation: "เซสชันไมโครขององค์กร",
            enterpriseWeChatCall: "การโทรไมโคร",
            followUpSuggestions: "ติดตามข้อเสนอแนะ",
            customer: "ศูนย์ลูกค้า",
            followUp: "ติดตามสถานการณ์",
            personInCharge: "ผู้รับผิดชอบ",
            recentFollowup: "คนติดตามล่าสุด",
            lastContactTime: "เวลาติดต่อล่าสุด",
            operation: "การดำเนินงาน",
            see: "ดู",
            exceed: "กว่า",
            noActivityForMoreThanDays: "วันไม่มีกิจกรรม",
            lately: "เมื่อเร็วๆนี้",
            risksOccurredInTheLastDays: "ความหมายของความเสี่ยงภายในหนึ่งวัน",
            savedSuccessfully: "บันทึกความสำเร็จ",
            today: "วันนี้",
            allDepartments: "ทุกภาคส่วน",
            allMembers: "สมาชิกทั้งหมด",
            customerName: "ชื่อลูกค้า",
            reset: "รีเซ็ต",
            query: "สอบถามข้อมูล",
            customers: "ลูกค้า",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "แสดงการติดต่อระหว่างพนักงานและลูกค้าฟองสีชมพูคือข้อความของลูกค้าและสีน้ำเงินคือข้อความของพนักงานยิ่งมีสัดส่วนการพูดมากเท่าไหร่ฟองก็จะยิ่งมากขึ้นเท่านั้น",
            riskAllocation: "การจัดสรรความเสี่ยง",
            preservation: "บันทึก",
            pleaseEnter: "กรุณาใส่",
            noActivityForDays: "วันไม่มีกิจกรรม",
            riskSemanticsWithinDays: "ความหมายของความเสี่ยงภายในหนึ่งวัน",
            addRiskSemantics: "เพิ่มความหมายของความเสี่ยง",
            semanticTags: "แท็กความหมาย:",
            standardSentence: "ประโยคมาตรฐาน:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "เพิ่มเงื่อนไขใหม่" } },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "มีคุณสมบัติครบถ้วนตามเงื่อนไขต่อไปนี้",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "การคัดกรอง",
                pleaseSelectFilteringCriteria: "กรุณาเลือกเงื่อนไขการคัดกรอง",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "เปลี่ยนเวทีโอกาสทางธุรกิจ",
              theCurrentStageIs: "ขั้นตอนปัจจุบันคือ【",
              ConfirmToChangeItTo: "】ตรวจสอบให้แน่ใจว่าได้เปลี่ยนเป็น【",
              IsIt: "】?",
              StageCoStay: "】การเข้าพักร่วม",
              day: "วัน",
              start: "เริ่มต้น",
              end: "สิ้นสุด",
              orderInput: "สูญเสียคำสั่ง",
              restart: "รีสตาร์ท",
              pleaseConfigureTheSalesStageInformation:
                "กรุณากำหนดค่าข้อมูลขั้นตอนการขาย",
              reasonForDocumentInput: "เหตุผลในการสูญเสีย:",
              completeOrderInput: "เสร็จสิ้นการสูญเสียคำสั่ง",
              pleaseSelectTheReason: "โปรดเลือกเหตุผล",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "ผู้จัดการแนะนำ:" },
              index: {
                sessionDetails: "รายละเอียดเซสชัน",
                customerDynamics: "พลวัตของลูกค้า",
                riskPoint: "จุดเสี่ยง",
                followUpSuggestions: "ติดตามข้อเสนอแนะ",
              },
              sessionDetails: {
                common: "รวม",
                currentlyViewingThe: "ดูปัจจุบัน",
                sessions: "เซสชัน",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "ขั้นตอนปัจจุบันอยู่",
              day: "วัน",
            },
          },
          business: {
            index: { reEnableOpportunity: "เปิดโอกาสทางธุรกิจอีกครั้ง" },
          },
          customerTag: { index: { label: "แท็บ" } },
          moreCustomer: {
            index: {
              essentialInformation: "ข้อมูลพื้นฐาน",
              cancel: "การยกเลิก",
              confirm: "ยืนยัน",
              customer: "ลูกค้า",
              currentFollowupStage: "ขั้นตอนการติดตามผลในปัจจุบัน",
              telephone: "โทรศัพท์",
              followUpDays: "ติดตามจำนวนวัน",
              day: "วัน",
              remarks: "หมายเหตุ",
              channelPreference: "การตั้งค่าช่อง",
              hello: "สวัสดีค่ะ",
              customFields: "ฟิลด์ที่กำหนดเอง",
              opportunityAmount: "จำนวนโอกาสทางธุรกิจ",
              focus: "โฟกัส",
              customerInformation: "ข้อมูลลูกค้า",
              viewMoreCustomerInformation: "ดูข้อมูลลูกค้าเพิ่มเติม",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "เหตุการณ์สำคัญ:" },
                row1: {
                  customerDynamics: "พลวัตของลูกค้า",
                  common: "รวม",
                  barDynamic: "ข่าว",
                },
                row2: {
                  riskPoint: "จุดเสี่ยง",
                  common: "รวม",
                  risks: "บทความความเสี่ยง",
                },
                row3: {
                  followUpSuggestions: "ติดตามข้อเสนอแนะ",
                  intelligentRecommendation: "คำแนะนำที่ชาญฉลาด",
                  strip: "แถบ",
                  managersSuggestATotalOf: "ผู้จัดการแนะนำทั้งหมด",
                  recommendedByManagers: "ผู้จัดการแนะนำ",
                  creationTime: "สร้างเวลา",
                  administratorSuggestions: "ผู้ดูแลระบบแนะนำ",
                  suggestionsForNewManagers: "คำแนะนำผู้จัดการใหม่",
                },
              },
            },
            index: { typeTime: "ประเภท/เวลา" },
          },
          userInfo: {
            index: {
              personInCharge: "ผู้รับผิดชอบ",
              recentFollower: "คนติดตามล่าสุด",
              startTime: "เวลาเข้าถึงครั้งแรก",
              followUpDays: "ติดตามจำนวนวัน",
              day: "วัน",
              remarks: "หมายเหตุ",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "สมาชิกในทีม",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "จำนวนการสนทนาทั้งหมดที่สมาชิกเรียนรู้จากผู้อื่น (การอ่านซ้ำ) ตรวจสอบรายละเอียดของการสนทนาและคลิกที่การบันทึกเพื่อเล่นถือเป็นการเรียนรู้ครั้งเดียว",
          totalNumberOfStudies: "จำนวนการเรียนรู้ทั้งหมด",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "จำนวนความคิดเห็นและการตอบกลับทั้งหมดที่สมาชิกเริ่มต้นในการสนทนาของผู้อื่น (ไปที่ซ้ำกัน)",
          totalNumberOfInteractions: "จำนวนการโต้ตอบทั้งหมด",
          learnTheSumOfConversationCallDuration: "ระยะเวลาการสนทนาการเรียนรู้",
          lengthOfStudy: "ระยะเวลาในการเรียนรู้",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "จำนวนสมาชิกทั้งหมดที่ขอความช่วยเหลือจากการสนทนาของผู้อื่น (ไปที่อีกครั้ง)",
          totalNumberOfRequests: "จำนวนความช่วยเหลือทั้งหมด",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "จำนวนสมาชิกทั้งหมดที่ให้คำปรึกษาเพื่อขอความช่วยเหลือจากผู้อื่น (ไปที่น้ำหนัก)",
          totalCounseling: "จำนวนการสอนทั้งหมด",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "จำนวนรวมของสมาชิกในการเพิ่มการสนทนาของผู้อื่นในไลบรารีกรณี (ไปหนัก)",
          addCase: "เพิ่มกรณี",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "จำนวนสมาชิกทั้งหมดที่สมัครสมาชิกเซสชันของผู้อื่น (ไปที่อีกครั้ง)",
          subscribe: "สมัครสมาชิก",
          totalNumberOfSessionsCommunicatedByMembers:
            "จำนวนเซสชันทั้งหมดที่สมาชิกสื่อสาร",
          totalSessions: "จำนวนเซสชันทั้งหมด",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "สมาชิกจะตรวจสอบจำนวนเซสชันทั้งหมดของตนเอง (ยกเลิกการทำซ้ำ) ตรวจสอบรายละเอียดของเซสชันและคลิกเพื่อบันทึกและเล่นจะถือเป็นการตรวจสอบหนึ่งครั้ง",
          totalNumberOfDoubleDisk: "จำนวนรีวิวทั้งหมด",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "จำนวนรีวิวทั้งหมด/จำนวนเซสชันทั้งหมด * 100%",
          proportionOfDoubleDisk: "อัตราส่วนการตรวจสอบ",
          summationOfConversationCallDurationOfDuplicateDisk:
            "สรุประยะเวลาการโทรของเซสชันซ้ำ",
          resumptionDuration: "เวลาในการตรวจสอบ",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "จำนวนสมาชิกทั้งหมดที่ขอความช่วยเหลือในการสนทนาของตนเอง (ไปที่อีกครั้ง)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "จำนวนความคิดเห็นทั้งหมดที่สมาชิกได้รับ (รวมถึงการตอบกลับ) จากผู้อื่น",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "จำนวนรวมของการสนทนาของสมาชิกที่ถูกเพิ่มเข้าไปในไลบรารีกรณีโดยผู้อื่น (ไปอีกครั้ง)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "จำนวนการสมัครสมาชิกทั้งหมดของการสนทนาของสมาชิกโดยผู้อื่น (ไปอีกครั้ง)",
          askForHelpInTheLastMonth: "ขอความช่วยเหลือในเดือนที่แล้ว",
          lastTimeForHelp: "เวลาช่วยเหลือล่าสุด",
          operation: "การดำเนินงาน",
          viewAllSessionsInTheLastMonth: "ดูเซสชันทั้งหมดในเดือนล่าสุด",
          allDepartments: "ทุกภาคส่วน",
          reset: "รีเซ็ต",
          query: "สอบถามข้อมูล",
          altogether: "รวม",
          secondSession: "เซสชันย่อย",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "รายการทดสอบปัจจุบัน:",
            supervisionItem: "รายการกำกับดูแล",
            keyEvents: "เหตุการณ์สำคัญ",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "ตรวจสอบรายการการตรวจสอบหรือเหตุการณ์สำคัญที่จะทดสอบและตรวจสอบให้แน่ใจว่ามีเนื้อหาการสนทนาในการสนทนาจำลอง",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "การวิเคราะห์การดำเนินการ" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "กระบวนการ1",
              technologicalProcess: "กระบวนการ",
              newProcess: "กระบวนการใหม่",
            },
          },
          index: {
            upload: "อัปโหลด",
            preservation: "บันทึก",
            newlyBuild: "สร้างใหม่",
            cancel: "การยกเลิก",
            name: "ชื่อ",
            describe: "คำอธิบาย",
            type: "ประเภท",
            classification: "หมวดหมู่",
            content: "เนื้อหา",
            enclosure: "อุปกรณ์เสริม",
            knowledgePointAssociation: "เชื่อมโยงประเด็นความรู้",
            open: "เปิด",
            close: "ปิด",
            operator: "โอเปอเรเตอร์",
            logic: "ตรรกะ",
            see: "ดู",
            edit: "บรรณาธิการ",
          },
        },
        index: {
          name: "ชื่อ",
          describe: "คำอธิบาย",
          createdBy: "ผู้สร้าง",
          type: "ประเภท",
          lastUpdated: "เวลาอัพเดตล่าสุด",
          numberOfReferences: "จำนวนการอ้างอิง",
          operation: "การดำเนินงาน",
          see: "ดู",
          edit: "บรรณาธิการ",
          delete: "ลบ",
          categoryDeletedSuccessfully: "ลบหมวดหมู่สำเร็จ",
          classificationOfKnowledgePoints: "การจำแนกประเด็นความรู้",
          newlyBuild: "สร้างใหม่",
          knowledgeBase: "ฐานความรู้",
          knowledgePoints: "ประเด็นความรู้",
          technologicalProcess: "กระบวนการ",
          keyword: "คำหลัก",
          batchExport: "ส่งออกเป็นกลุ่ม",
          batchDelete: "การลบแบทช์",
          importProcess: "กระบวนการนำเข้า",
          importKnowledgePoints: "นำเข้าประเด็นความรู้",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "หากคะแนนความรู้ไม่มีอยู่ในฐานความรู้ให้เพิ่มคะแนนความรู้โดยตรงหากมีอยู่ให้ข้ามไปและอย่าอัปเดต",
          editKnowledgePointClassification: "แก้ไขการจำแนกประเด็นความรู้",
          newKnowledgePointClassification: "การจำแนกประเด็นความรู้ใหม่",
          categoryEditingSucceeded: "การแก้ไขหมวดหมู่ที่ประสบความสำเร็จ",
          newClassificationSucceeded: "การจัดประเภทใหม่ประสบความสำเร็จ",
          classificationName: "ชื่อหมวดหมู่",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "การกำกับดูแลการดำเนินการ" },
      },
      topicCallout: {
        index: {
          whole: "ทั้งหมด",
          date: "วันที่",
          dimensionStatus: "สถานะการติดฉลาก",
          callRecordID: "ID บันทึกการโทร",
          callRecordIDMustBeNumeric: "ID บันทึกการโทรต้องเป็นตัวเลข",
          strip: "แถบ",
          topicAnnotation: "การทำเครื่องหมายหัวข้อ",
          annotated: "ทำเครื่องหมายแล้ว",
          stripNotMarked: "บทความไม่มีเครื่องหมาย",
          barInDimension: "แถบทำเครื่องหมาย",
          customer: "ลูกค้า",
          sale: "การขาย",
          common: "รวม",
          sessions: "เซสชันบทความ",
          currentTopic: "หัวข้อปัจจุบัน:",
          staff: "พนักงาน",
          topicOfConversation: "หัวข้อ",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "การดำเนินการสำเร็จทำเครื่องหมายใหม่",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "หลังจากรีเซ็ตแล้วบันทึกการติดฉลากทั้งหมดของเซสชันนี้จะถูกล้างโปรดยืนยัน",
          resetAll: "รีเซ็ตทั้งหมด",
          pleaseSelectTheTopicToBeMarked: "โปรดเลือกหัวข้อที่จะทำเครื่องหมาย",
          pleaseTickTheSentenceToBeMarked:
            "โปรดตรวจสอบประโยคที่จะทำเครื่องหมาย",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "การดำเนินการสำเร็จการทำเครื่องหมายมีผล",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "ช่วงนี้มีข้อความในหัวข้ออื่นๆคลิกตกลงเพื่อเขียนทับ",
          confirmDimensions: "ยืนยันการติดฉลาก",
          talkTime: "เวลาพูดคุย",
          callID: "โทร ID",
        },
      },
      topicList: {
        index: {
          callID: "โทร ID",
          latestMarkedTime: "เวลาทำเครื่องหมายล่าสุด",
          topicOfConversation: "หัวข้อ",
          numberOfSentences: "จำนวนประโยค",
          sentence: "ประโยค",
          operation: "การดำเนินงาน",
          see: "ดู",
          delete: "ลบ",
          edit: "บรรณาธิการ",
          newSubTopic: "หัวข้อย่อยใหม่",
          move: "ย้าย",
          whole: "ทั้งหมด",
          topicDeletedSuccessfully: "ลบหัวข้อที่ประสบความสำเร็จ",
          newlyBuild: "สร้างใหม่",
          topicList: "รายการหัวข้อ",
          callIDMustBeNumeric: "รหัสการโทรต้องเป็นตัวเลข",
          leadInTopic: "นำเข้าหัวข้อ",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "เมื่อชื่อหมวดหมู่ซ้ำกันหมวดหมู่หัวข้อเดิมจะไม่ถูกเขียนทับรายการจะถูกข้ามโดยตรงและจะไม่นำเข้า",
          mobileClassification: "ประเภทมือถือ",
          editTopic: "แก้ไขหัวข้อ",
          newTopic: "หัวข้อใหม่",
          topicName: "ชื่อหัวข้อ",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "แก้ไขสำเร็จ",
            createdSuccessfully: "สร้างสำเร็จ",
            modificationFailed: "การแก้ไขล้มเหลว",
            creationFailed: "การสร้างล้มเหลว",
            monitoringName: "ชื่อการตรวจสอบ",
            upToCharacters: "สูงสุด40ตัวอักษร",
            pleaseEnterAName: "กรุณาใส่ชื่อ",
            monitoringIndicators: "ตัวบ่งชี้การตรวจสอบ",
            sessionActivity: "กิจกรรมการสนทนา",
            conversationAction: "การดำเนินการเซสชัน",
            keyEvents: "เหตุการณ์สำคัญ",
            supervisionItems: "รายการกำกับดูแล",
            monitoringMembers: "สมาชิกการตรวจสอบ",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "กลุ่มที่ได้รับคำแนะนำการฝึกอบรมหรือการแทรกแซงการจัดการ",
            groupA: "กลุ่ม A",
            pleaseSelectGroupAData: "โปรดเลือกกลุ่มข้อมูล",
            thereAreDuplicateUsersInGroupB: "กับผู้ใช้ที่ซ้ำกันในกลุ่ม B",
            groupARemarks: "กลุ่ม A หมายเหตุ",
            controlGroupGroupComparedWithGroupA:
              "กลุ่มควบคุมกลุ่มที่เปรียบเทียบกับกลุ่ม A",
            groupB: "กลุ่ม B",
            pleaseSelectGroupBData: "โปรดเลือกข้อมูลกลุ่ม B",
            thereAreDuplicateUsersInGroupA: "กับผู้ใช้ที่ซ้ำกันในกลุ่ม A",
            groupBRemarks: "หมายเหตุกลุ่ม B",
            monitoringPeriod: "วงจรการตรวจสอบ",
            startNode: "เริ่มต้นโหนด",
            monitoringScope: "ช่วงการตรวจสอบ",
            pleaseSelectMonitoringConditions: "โปรดเลือกเงื่อนไขการตรวจสอบ",
            pleaseEnterANumber: "กรุณากรอกหมายเลข",
            pleaseSelectATimePeriod: "โปรดเลือกช่วงเวลา",
            preservation: "บันทึก",
            cancel: "การยกเลิก",
          },
        },
        list: {
          template: {
            deletionSucceeded: "ลบสำเร็จ",
            notStarted: "ยังไม่เริ่ม",
            haveInHand: "กำลังดำเนินการอยู่",
            closed: "สิ้นสุดแล้ว",
            newGroupTest: "การทดสอบกลุ่มใหม่",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "ขั้นตอนการขาย",
        communicationStrategy1: "กลยุทธ์การสื่อสาร",
        riskAllocation: "การจัดสรรความเสี่ยง",
        businessConfiguration: "การกำหนดค่าธุรกิจ",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "การจัดการการคัดค้าน",
                  productSellingPointScript: "จุดขายสินค้า",
                  caseGrouping: "การจัดกลุ่มกรณี",
                  pleaseSelectExcellentCases: "โปรดเลือกกรณีที่ยอดเยี่ยม",
                  pleaseSelectKnowledgePoints: "โปรดเลือกประเด็นความรู้",
                  associativeLinks: "ลิงก์ที่เกี่ยวข้อง",
                  jumpTo: "ข้ามไปที่",
                  jumpType: "ประเภทกระโดด",
                  knowledgePoints: "ประเด็นความรู้",
                  excellentCases: "กรณีที่ยอดเยี่ยม",
                  excellentScript: "ทักษะการพูดที่ยอดเยี่ยม",
                  pleaseSelectASingleKnowledgePoint:
                    "โปรดเลือกประเด็นความรู้เดียว",
                },
              },
            },
            index: {
              name: "ชื่อ",
              pushText: "ข้อความผลักดัน",
              enableStatus: "เปิดใช้งานสถานะ",
              triggerRules: "กฎทริกเกอร์",
              communicationStrategy: "กลยุทธ์การสื่อสาร",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "การจัดกลุ่มกลยุทธ์การสื่อสาร",
              name: "ชื่อ",
              pushText: "ข้อความผลักดัน",
              effectiveness: "ประสิทธิผล",
              openSuccessfully: "เปิดสำเร็จ",
              closeSuccessfully: "ปิดสำเร็จ",
              operationFailed: "การดำเนินการล้มเหลว",
              operation: "การดำเนินงาน",
              edit: "บรรณาธิการ",
              delete: "ลบ",
              newlyBuild: "สร้างใหม่",
              communicationStrategy: "กลยุทธ์การสื่อสาร",
              editGroup: "แก้ไขการจัดกลุ่ม",
              groupName: "ชื่อกลุ่ม",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "เซสชันทั้งหมด",
                  recentDays: "จำนวนวันล่าสุด",
                  recentSessions: "เซสชันล่าสุด",
                  scopeOfApplication: "ขอบเขตการใช้งาน:",
                  day: "วัน",
                  through: "ผ่าน",
                },
              },
            },
            index: {
              describe: "คำอธิบาย",
              applicableCustomers: "เหมาะสำหรับลูกค้า",
              enableStatus: "เปิดใช้งานสถานะ",
              triggerRules: "กฎทริกเกอร์",
              riskRules: "กฎความเสี่ยง",
            },
          },
          list: {
            index: {
              riskAllocation: "การจัดสรรความเสี่ยง",
              keyWord: "คำสำคัญ",
              newRiskRule: "สร้างกฎความเสี่ยงใหม่",
              riskRuleName: "ชื่อกฎความเสี่ยง",
              describe: "คำอธิบาย",
              state: "สถานะ",
              openSuccessfully: "เปิดสำเร็จ",
              closeSuccessfully: "ปิดสำเร็จ",
              operationFailed: "การดำเนินการล้มเหลว",
              operation: "การดำเนินงาน",
              edit: "บรรณาธิการ",
              delete: "ลบ",
              deletionSucceeded: "ลบสำเร็จ",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "สูญเสียคำสั่ง" },
              endReasonList: {
                addEndReason: "เพิ่มเหตุผลในการสิ้นสุด",
                enterEndReason: "ป้อนเหตุผลในการสิ้นสุด",
              },
              stageList: {
                keyEvents: "เหตุการณ์สำคัญ",
                waysideData: "ข้อมูลถนน",
                includeAny: "ประกอบด้วยใดๆ",
                includeAll: "รวมทั้งหมด",
                pleaseEnterTheStageName: "กรุณาใส่ชื่อเฟส",
                setStageRules: "ตั้งกฎขั้นตอน",
                stageName: "ชื่อเวที",
                operator: "โอเปอเรเตอร์",
                logic: "ตรรกะ",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "รูปแบบการป้อนข้อมูลฟิลด์ประเภทเวลาคือ: 2022-12-31 00:00:00น",
              requestFailedPleaseTryAgainLater:
                "คำขอล้มเหลวโปรดลองอีกครั้งในภายหลัง",
              salesStage: "ขั้นตอนการขาย",
              essentialInformation: "ข้อมูลพื้นฐาน",
              name: "ชื่อ",
              applicableDepartments: "หน่วยงานที่เกี่ยวข้อง",
              applicableToOthers: "เหมาะสำหรับอื่นๆ",
              manualChangePhase: "ขั้นตอนการเปลี่ยนแปลงด้วยตนเอง",
              enableStatus: "เปิดใช้งานสถานะ",
              describe: "คำอธิบาย",
              opportunityStageSettings: "การตั้งค่าเวทีโอกาสทางธุรกิจ",
              stageSettings: "การตั้งค่าเฟส",
              aliasSettings: "การตั้งค่านามแฝง",
              closeOpportunity: "ยุติโอกาสทางธุรกิจ",
              newSuccessfully: "สร้างใหม่สำเร็จ",
              newFailed: "ความล้มเหลวในการสร้างใหม่",
              savingSucceeded: "บันทึกความสำเร็จ",
              saveFailed: "บันทึกล้มเหลว",
            },
          },
          list: {
            index: {
              salesStage: "ขั้นตอนการขาย",
              keyWord: "คำสำคัญ",
              newSalesStage: "ขั้นตอนการขายใหม่",
              salesStageName: "ชื่อขั้นตอนการขาย",
              describe: "คำอธิบาย",
              state: "สถานะ",
              openSuccessfully: "เปิดสำเร็จ",
              closeSuccessfully: "ปิดสำเร็จ",
              operationFailed: "การดำเนินการล้มเหลว",
              operation: "การดำเนินงาน",
              edit: "บรรณาธิการ",
              delete: "ลบ",
              deletionSucceeded: "ลบสำเร็จ",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "การสร้างแบบจำลองขนาดใหญ่",
            conversationInsights: "ข้อมูลเชิงลึกการสนทนา",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "สร้างโดยอัตโนมัติ" },
              },
              hooks: {
                conversationSummary: "บทสรุปของวาระงาน",
                conversationProcess: "กระบวนการสนทนา",
                opportunityPoints: "จุดโอกาส",
              },
              prompt: {
                index: {
                  edit: "บรรณาธิการ",
                  restoreDefault: "เรียกคืนค่าปริยาย",
                  cancel: "การยกเลิก",
                  confirm: "ยืนยัน",
                },
              },
            },
            index: {
              saveSuccessful: "บันทึกความสำเร็จ",
              hintLanguage: "คำใบ้",
              preserve: "บันทึก",
              cancel: "การยกเลิก",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "การสร้างแบบจำลองขนาดใหญ่",
        salesStage: "ขั้นตอนการขาย",
        communicationStrategy: "กลยุทธ์การสื่อสาร",
        riskAllocation: "การจัดสรรความเสี่ยง",
        businessConfiguration: "การกำหนดค่าธุรกิจ",
      },
      salesLearningCenter: {
        knowledgeBase: "ฐานความรู้",
        speechCorpus: "ห้องสมุดคำ",
        learningCenter: "ศูนย์การเรียนรู้",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "ข้อมูลลูกค้า" } },
        },
        index: { otherInformation: "ข้อมูลอื่นๆ" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "นำเข้า" } } },
        hooks: {
          index: {
            all: "ทั้งหมด",
            intelligentModel: "โมเดลอัจฉริยะ",
            smartRules: "กฎอัจฉริยะ",
            manualScoring: "การให้คะแนนด้วยตนเอง",
            enable: "เปิดใช้งาน",
            notEnabled: "ไม่ได้เปิดใช้งาน",
            voiceCall: "การโทรด้วยเสียง",
            textDialogue: "บทสนทนาข้อความ",
            ecommerceTextSession: "เซสชันข้อความอีคอมเมิร์ซ",
            workOrder: "ใบสั่งงาน",
          },
        },
        index: {
          ruleName: "ชื่อกฎ",
          ruleType: "ประเภทกฎ",
          pleaseSelect: "กรุณาเลือก",
          ruleStatus: "สถานะกฎ",
          dataSourceType: "ประเภทแหล่งข้อมูล",
          voiceCall: "การโทรด้วยเสียง",
          textDialogue: "บทสนทนาข้อความ",
          ecommerceTextSession: "เซสชันข้อความอีคอมเมิร์ซ",
          workOrder: "ใบสั่งงาน",
          status: "สถานะ",
          operation: "การดำเนินงาน",
          edit: "บรรณาธิการ",
          ruleTemplate: "แม่แบบกฎ",
        },
      },
      analysisReportTitle: "รายงานการวิเคราะห์",
      ruleTest: "การทดสอบกฎ",
      intelligentTags: {
        labelDisplayQuantity: "ป้ายแสดงจำนวน",
        unlimited: "ไม่จำกัด",
        ignoringTagLibraries: "ละเว้นไลบรารีแท็ก",
        automaticallyGenerateLabels: "สร้างฉลากโดยอัตโนมัติ",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'หลังจากเปิดแล้วแท็กจะถูกสร้างขึ้นโดยอัตโนมัติสำหรับเซสชันตามโมเดลขนาดใหญ่และแท็กที่สร้างขึ้นจะถูกจัดประเภทเป็น "ไม่มีหมวดหมู่" ตามค่าเริ่มต้น',
        defaultValidity: "ความถูกต้องเริ่มต้น",
        supervisionItem: "รายการกำกับดูแล",
        customerLabel: "แท็กของลูกค้า",
        newSupervisionItemRule: "กฎการกำกับดูแลใหม่",
        editSupervisionItemRule: "แก้ไขกฎรายการกำกับดูแล",
        newCustomerLabel: "สร้างฉลากลูกค้าใหม่",
        editCustomerLabel: "แก้ไขแท็บลูกค้า",
        newSessionLabel: "สร้างแท็กเซสชันใหม่",
        editSessionLabel: "แก้ไขแท็กเซสชัน",
        newKeyEvent: "เหตุการณ์สำคัญใหม่",
        editKeyEvents: "แก้ไขเหตุการณ์สำคัญ",
        detail: {
          template: { nameIsARequiredField: "ชื่อเป็นฟิลด์ที่จำเป็น" },
          formItemComponent: {
            largeModelExtensionLabel: "ป้ายขยายรุ่นใหญ่",
            sessionRecordID: "บันทึกเซสชัน id",
            sessionTime: "เวลาเซสชัน",
            smartTags: "สมาร์ทแท็ก",
            viewDetails: "ดูรายละเอียด",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "หลังจากลบข้อมูลที่บันทึกในเซสชันนี้จะถูกล้างคุณยืนยันการลบหรือไม่?",
            tagData: "ข้อมูลแท็ก",
            batchDeletion: "การลบแบทช์",
          },
        },
        template: {
          advancedFiltering: "การคัดกรองขั้นสูง",
          dataType: "ชนิดข้อมูล",
          filterCriteria: "เงื่อนไขการคัดกรอง",
          edit: "บรรณาธิการ",
          createANewSubcategory: "หมวดหมู่ย่อยใหม่",
          delete: "ลบ",
          nameLengthCannotExceed: "ความยาวของชื่อต้องไม่เกิน40",
        },
        component: {
          modelExpansion: "การขยายโมเดล",
          enable: "เปิดใช้งาน",
          notEnabled: "ไม่ได้เปิดใช้งาน",
          standardScreening: "การคัดกรองมาตรฐาน",
          advancedFiltering: "การคัดกรองขั้นสูง",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "คำสำคัญ",
              sessionLabel: "แท็กเซสชัน",
              keyEvents: "เหตุการณ์สำคัญ",
              matchAny: "จับคู่ใดก็ได้",
              matchAll: "จับคู่ทั้งหมด",
              beEqualTo: "เท่ากับ",
              includeAll: "รวมทั้งหมด",
              includeAny: "ประกอบด้วยใดๆ",
              advancedConditions: "เงื่อนไขขั้นสูง",
              smartTag: "สมาร์ทแท็ก",
              smartTagI: "สมาร์ทแท็กหนึ่ง",
              smartTagII: "สมาร์ทแท็กสอง",
            },
            template: {
              pleaseSelectSmartTag: "โปรดเลือกสมาร์ทแท็บ",
              pleaseSelectASessionLabel: "โปรดเลือกแท็บเซสชัน",
              pleaseSelectAKeyEvent: "โปรดเลือกเหตุการณ์สำคัญ",
              pleaseSelectAdvancedCondition: "โปรดเลือกเงื่อนไขขั้นสูง",
              newAdvancedFilter: "สร้างการคัดกรองขั้นสูงใหม่",
              moveUp: "เลื่อนขึ้น",
              moveDown: "เลื่อนลง",
              moveLeft: "เลื่อนไปทางซ้าย",
              shiftRight: "เลื่อนไปทางขวา",
            },
          },
          component: {
            businessAnalysispng: "การวิเคราะห์ธุรกิจ .png",
            savedSuccessfully: "บันทึกความสำเร็จ",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "ถาวรและมีประสิทธิภาพ",
            permanentlyInvalid: "ไม่ถูกต้องถาวร",
            customValidity: "ระยะเวลาที่กำหนดเอง",
            termOfValidity: "ระยะเวลาที่ใช้ได้",
            name: "ชื่อ",
            explain: "คำอธิบาย",
            state: "สถานะ",
            classification: "หมวดหมู่",
            smartRecommendation: "คำแนะนำที่ชาญฉลาด",
            preconditions: "สภาพก่อน",
            SetPreconditionsForThisSupervisionItem:
              "1.กำหนดเงื่อนไขล่วงหน้าสำหรับรายการกำกับดูแลนี้:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'เมื่อเป็นไปตามเงื่อนไขเบื้องต้นจะเป็น "รายการกำกับดูแล" และจะมีการทดสอบรายการกำกับดูแล',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "เมื่อไม่เป็นไปตามเงื่อนไขเบื้องต้นจะไม่มีการทดสอบรายการกำกับดูแลของบทความนี้",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2.ไม่มีการกำหนดเงื่อนไขล่วงหน้านั่นคือการตรวจสอบเซสชันทั้งหมดโดยค่าเริ่มต้น",
            have: "มี",
            nothing: "ไม่มี",
            preconditionRule: "กฎเงื่อนไขก่อน",
            preconditionLogic: "ตรรกะก่อนเงื่อนไข",
          },
          recommendation: {
            caseGrouping: "การจัดกลุ่มกรณี",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "เมื่อพนักงานไม่ดำเนินการในสถานที่พวกเขาสามารถแนะนำคำพูดที่ยอดเยี่ยมและกรณีที่ยอดเยี่ยมได้อย่างชาญฉลาด",
            smartRecommendation: "คำแนะนำที่ชาญฉลาด",
            open: "เปิด",
            close: "ปิด",
            whenThisSupervisionItem: "เมื่อรายการกำกับดูแลนี้",
            implement: "ดำเนินการ",
            unexecuted: "ไม่ได้ดำเนินการ",
            when: "เวลา:",
            recommendedKnowledgePoints: "ประเด็นความรู้ที่แนะนำ",
            caseRecommendation: "คำแนะนำกรณี",
          },
        },
        index: {
          determine: "กำหนด",
          sort: "เรียงลำดับ",
          sortSucceeded: "เรียงลำดับความสำเร็จ",
          supervisionItemClassification: "หมวดหมู่รายการกำกับดูแล",
          pressEnterToCreateANew: "กด Enter เพื่อสร้างใหม่",
          nameCannotBeEmpty: "ชื่อต้องไม่ว่างเปล่า",
          newlyBuild: "สร้างใหม่",
          name: "ชื่อ",
          explain: "คำอธิบาย",
          validStatus: "สถานะที่มีประสิทธิภาพ",
          permanentlyValid: "ถาวรและมีประสิทธิภาพ",
          permanentlyInvalid: "ไม่ถูกต้องถาวร",
          operation: "การดำเนินงาน",
          edit: "บรรณาธิการ",
          batchDelete: "การลบแบทช์",
          newSupervisionItem: "รายการกำกับดูแลใหม่",
          keyWord: "คำสำคัญ",
          delete: "ลบ",
          deleteSucceeded: "ลบสำเร็จ",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "ข้อมูลจะไม่สามารถกู้คืนได้หลังจากถูกลบคุณยืนยันการลบหรือไม่?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "สมาร์ทแท็ก",
              beEqualTo: "เท่ากับ",
              includeAll: "รวมทั้งหมด",
              includeAny: "ประกอบด้วยใดๆ",
              matchAny: "จับคู่ใดก็ได้",
              matchAll: "จับคู่ทั้งหมด",
              smartTagI: "สมาร์ทแท็กหนึ่ง",
              smartTagII: "สมาร์ทแท็กสอง",
            },
            template: {
              pleaseSelectASmartTag: "โปรดเลือกสมาร์ทแท็บ",
              pleaseSelectAdvancedConditions: "โปรดเลือกเงื่อนไขขั้นสูง",
              newAdvancedFilter: "สร้างการคัดกรองขั้นสูงใหม่",
              rename: "เปลี่ยนชื่อ",
              moveUp: "เลื่อนขึ้น",
              moveDown: "เลื่อนลง",
              shiftLeft: "เลื่อนไปทางซ้าย",
              shiftRight: "เลื่อนไปทางขวา",
            },
          },
        },
        template: {
          delete: "ลบ",
          labelFiltering: "แท็บกรอง:",
          satisfyAny: "ตอบสนองใดๆ",
          meetAll: "ตอบสนองทั้งหมด",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "แท็กการจำแนกประเภทระดับที่สองจำนวนบทสนทนา ≥ การจำแนกระดับแรกเซสชันหนึ่งอาจมีแท็กหลายแท็ก",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "ตรรกะการคำนวณของการเปลี่ยนแปลงเครือข่าย: (ปริมาณการสนทนาในช่วงเวลาที่เลือก-ปริมาณการสนทนาในช่วงเวลาก่อนหน้าถัดจาก)/ปริมาณการสนทนาในช่วงเวลาก่อนหน้า",
          proportionOfPrimaryClassification: "สัดส่วนของการจำแนกประเภทระดับแรก",
          saveAs: "บันทึกเป็น",
          cover: "ครอบคลุม",
          preservation: "บันทึก",
          qualityInspectionTask: "งานวิเคราะห์:",
          query: "สอบถามข้อมูล",
          changeFromMonthToMonth: "การเปลี่ยนแปลงของห่วงโซ่",
          tagRanking: "การจัดอันดับแท็ก",
          proportion: "สถานการณ์สัดส่วน",
        },
        component: {
          numberOfConversations: "จำนวนการสนทนา",
          operation: "การดำเนินงาน",
          businessAnalysispng: "การวิเคราะห์ธุรกิจ .png",
          savingSucceeded: "บันทึกความสำเร็จ",
          deletionSucceeded: "ลบสำเร็จ",
          sequence: "ลำดับ",
          firstLevelClassification: "การจำแนกระดับแรก",
          ringRatioChange: "การเปลี่ยนแปลงโซ่",
          classClassification: "การจำแนกประเภท",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "ยืนยันการลบเอกสารและเนื้อหาที่แยกออกมา?",
            addKnowledgeBase: "เพิ่มฐานความรู้",
            uploadDocument: "อัปโหลดเอกสาร",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "รองรับ word, pdf, ppt, excel, txt",
            taskName: "ชื่องาน",
            extractConversationDate: "แยกวันที่สนทนา",
            pleaseSelectAConversationDate: "โปรดเลือกวันที่สนทนา",
            dataSource: "แหล่งข้อมูล",
            dataType: "ชนิดข้อมูล",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "ส่งออกสำเร็จ",
              problem: "คำถาม",
              answer: "คำตอบ",
              addToKnowledgeBase: "เพิ่มลงในฐานความรู้",
              pleaseConfirmWhetherToDeleteIt: "โปรดตรวจสอบว่าจะลบหรือไม่",
            },
            pageRightFrom: {
              addToKnowledgeBase: "เพิ่มลงในฐานความรู้",
              selectClassification: "เลือกหมวดหมู่",
              pleaseSelectAKnowledgeBaseClassification:
                "โปรดเลือกหมวดหมู่ฐานความรู้",
            },
          },
        },
        index: {
          intelligentExtraction: "การสกัดอัจฉริยะ",
          documentExtraction: "การแยกเอกสาร",
          dialogueExtraction: "การแยกบทสนทนา",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "การดำเนินงานที่ประสบความสำเร็จ",
          labelIgnored: "ไม่สนใจแท็ก",
          add: "เพิ่ม",
          areYouSureToDeleteIt: "แน่ใจว่าจะลบ?",
          batchFilterLabels: "แท็กกรองแบทช์",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "การตรวจสอบคุณภาพอัจฉริยะ",
        savedSuccessfully: "บันทึกความสำเร็จ",
        logoSettings: "การตั้งค่าโลโก้",
        default: "เริ่มต้น",
        custom: "ที่กำหนดเอง",
        title: "ชื่อเรื่อง",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "แนะนำให้ใช้ชื่อชื่อเรื่องที่แสดงโดยระบบภายใน6คำ",
        logoImageUpload: "อัพโหลดภาพโลโก้",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "กรุณาเลือกภาพที่มีขนาดไม่เกิน2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "เพื่อให้แน่ใจว่าเอฟเฟกต์การเรียกดูโลโก้คำแนะนำในการอัปโหลดรูปภาพ:",
        Size5050Pixels: "1.ขนาด: 50*50พิกเซล;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2.รูปแบบ: ภาพพื้นหลังโปร่งใส, png หรือ jpg, โลโก้กราฟิกที่ใช้สีขาว",
        browserLabelIcon: "ไอคอนแท็บเบราว์เซอร์",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "เพื่อให้แน่ใจว่าเอฟเฟกต์การเรียกดูแท็กคำแนะนำในการอัปโหลดรูปภาพ:",
        Size3232Pixels: "1.ขนาด: 32*32พิกเซล;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2.รูปแบบ: ภาพพื้นหลังโปร่งใส, png หรือ jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "การตรวจสอบคุณภาพอยู่ระหว่างดำเนินการโปรดรอสักครู่...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "ในปี2558อสังหาริมทรัพย์ของจีนมาถึงช่วงเวลาสำคัญของการอยู่รอดบ้านจำนวนมากไม่ได้รับการดูแลและผู้คนไม่เต็มใจที่จะซื้อหางโจวเคยลดลงต่ำกว่า8000และนักพัฒนาบางรายทุบสินค้าเซินเจิ้นยังคงรวมตัวกันที่ประมาณ2w-3w ในเวลานั้นและบ้านในเมืองชั้นหนึ่งก็ตกอยู่ในอันตรายเช่นกัน",
        xiaoAn: "เสี่ยวอัน",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "แถบกั้นหน่วยความจำเหล่านี้เรียกว่า jsr memory barrier ซึ่งอยู่ที่ระดับ jvm มีสองวิธีในการใช้งานที่ด้านล่างของระบบปฏิบัติการ: 1คือบัสล็อกและอีกวิธีหนึ่งคือองค์ประกอบของสิ่งกีดขวางหน่วยความจำเช่น sfance (อุปสรรคการเขียน) mfence (อุปสรรคเต็ม) ifence (อ่านอุปสรรค). ชั้นล่างสุดของเครื่องเสมือน hotspots ใช้คำสั่งแอสเซมบลี: ล็อก addl, ล็อกการดำเนินการที่ว่างเปล่าซึ่งก็คือบัสล็อกเนื่องจากระบบปฏิบัติการบางระบบไม่รองรับคำฐานกั้นหน่วยความจำ",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1.ฉันพยายามหมักเนื้อสัตว์หลายๆครั้งและอาหารที่ทอดหลังจากเพิ่มส่วนผสมเนื้อจะเหม็นเปรี้ยวและมีกลิ่นเหม็น! ตั้งแต่นั้นมาฉันจะไม่ใส่ไวน์ปรุงอาหาร",
        xiaodong: "เสี่ยวตง",
        xiaoxi: "โคอิชิ",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3.ฉันพยายามหมักเนื้อสัตว์สองสามครั้งและอาหารที่ทอดหลังจากเพิ่มส่วนผสมเนื้อจะเหม็นเปรี้ยวและมีกลิ่นเหม็น! ตั้งแต่นั้นมาฉันจะไม่ใส่ไวน์ปรุงอาหาร",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "การจัดการรีวิวอีคอมเมิร์ซ",
        socialMediaReviewManagement: "การจัดการความคิดเห็นของสื่อโซเชียล",
        customDataSource: "ต้นฉบับข้อมูลที่กำหนดเอง",
        qualityInspectionResults: "ผลการตรวจสอบคุณภาพ",
        accountManagement: "การจัดการบัญชี",
        employeeProfile: "ภาพพนักงาน",
        intelligentAnalysis: "การวิเคราะห์อัจฉริยะ",
        customKanban: "คัมบังที่กำหนดเอง",
        dataScreening: "ภาพรวมข้อมูล",
        learningRecord: "บันทึกการเรียนรู้",
        dataAnalysis: "การวิเคราะห์ข้อมูล",
        scoringManagement: "การจัดการคะแนน",
        businessConfiguration: "การกำหนดค่าธุรกิจ",
        manualReview: "การตรวจสอบด้วยตนเอง",
        taskList: "รายการงาน",
        videoAnalysis: "การวิเคราะห์วิดีโอ",
        overview: "ภาพรวม",
        personalSignage: "ป้ายส่วนตัว",
        learningCenter: "ศูนย์การเรียนรู้",
        wrongQuestionSet: "ชุดคำถามผิด",
        abilityImprovement: "เพิ่มความสามารถ",
        close: "ปิด",
        switchingSystemApplications: "สลับการประยุกต์ใช้ระบบ",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "เลื่อนเมาส์ไปที่จุดนี้เพื่อเรียกเมนูการสลับแอปพลิเคชันคลิกรายการเมนูเพื่อข้ามไปยังแอปพลิเคชันต่างๆ",
        teamAnalysis: "การวิเคราะห์ทีม",
        semanticIntelligence: "ความฉลาดเชิงความหมาย",
        permissionManagement: "การจัดการสิทธิ์",
        dataConnection: "เชื่อมต่อข้อมูล",
        courseManagement: "การจัดการหลักสูตร",
        labelManagement: "การจัดการฉลาก",
        curriculum: "ศูนย์หลักสูตร",
        courseConfiguration: "การกำหนดค่าหลักสูตร",
        task: "ภารกิจของฉัน",
        historicalLearningData: "ประวัติความเป็นมา",
        taskCenter: "การจัดการงาน",
        knowledgeBase: "ฐานความรู้",
        ecommerceSessionManagement: "การจัดการเซสชันอีคอมเมิร์ซ",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "สลับแอป",
          descriptionOfIntelligentQualityInspectionSystem:
            "คำอธิบายระบบตรวจสอบคุณภาพอัจฉริยะ",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "ตัวกรองเซสชันอีคอมเมิร์ซ",
        allEcommerceSessions: "เซสชันอีคอมเมิร์ซทั้งหมด",
        ecommerceSessionManagement: "การจัดการเซสชันอีคอมเมิร์ซ",
      },
    },
    analysisEnable: {
      bestPractices: "ปฏิบัติที่ดีที่สุด",
      behaviorAnalysis: "การวิเคราะห์พฤติกรรม",
      teamAnalysis: "การวิเคราะห์ทีม",
      executiveSupervision: "การกำกับดูแลการดำเนินการ",
      executiveForceAnalysis: "การวิเคราะห์การดำเนินการ",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "การจัดอันดับ:",
          startTime: "เวลาเริ่มต้น",
          workOrderNo: "หมายเลขใบสั่งงาน",
          customerServiceHasReadNotRead: "ฝ่ายบริการลูกค้าอ่าน/ยังไม่ได้อ่าน",
          canYouAppeal: "สามารถอุทธรณ์ได้หรือไม่",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "ข้อสังเกตขององค์กร",
          addFriendTime: "เพิ่มเพื่อนเวลา",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "เริ่มดำเนินการตรวจสอบคุณภาพใหม่",
          callID: "โทร ID",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'คุณสมบัติใหม่! คลิกปุ่ม "Quick Inspection" และจะข้ามไปยังหน้าถัดไปโดยอัตโนมัติหลังจากส่งสำเร็จ ~~~',
        },
      },
      template: {
        deleteRecord: "ลบระเบียน",
        areYouSureToDeleteIt: "แน่ใจว่าจะลบ?",
        confirm: "กำหนด",
        reIdentification: "การระบุตัวตนใหม่",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "การระบุซ้ำจะใช้เวลาในการถอดเสียงโปรดใช้ความระมัดระวัง",
        determine: "กำหนด",
        executionSuccessful: "ดำเนินการได้สำเร็จ",
      },
      component: {
        pleaseSelectData: "โปรดเลือกข้อมูล",
        operationSuccessful: "การดำเนินงานที่ประสบความสำเร็จ",
        startPerformingReQualityInspection: "เริ่มดำเนินการตรวจสอบคุณภาพใหม่",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "ชื่อไฟล์ที่อัปโหลด",
        analysisTask: "งานวิเคราะห์",
        analyzeProgress: "วิเคราะห์ความคืบหน้า",
        analysisSucceeded: "การวิเคราะห์ที่ประสบความสำเร็จ",
        analysisFailed: "การวิเคราะห์ล้มเหลว",
        localDataUpload: "อัปโหลดข้อมูลท้องถิ่น",
        recordingUpload: "อัปโหลดบันทึก",
        textUpload: "อัปโหลดข้อความ",
        uploadTime: "เวลาอัปโหลด",
        taskName: "ชื่องาน",
        operator: "ผู้ดำเนินการ",
        qualityInspectionProgress: "ความคืบหน้าการตรวจสอบคุณภาพ",
        whole: "ทั้งหมด",
        haveInHand: "กำลังดำเนินการอยู่",
        completed: "เสร็จเรียบร้อยแล้ว",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "คุณส่งออกบันทึกข้อมูลที่เสร็จสมบูรณ์ทั้งหมดภายใต้เกณฑ์การกรองปัจจุบันหรือไม่",
        upload: "อัปโหลด",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "กรอกชื่อ",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "กรอกชื่อเช่นชนิดของข้อมูลที่นำเข้า/คอลเลกชัน/วัตถุประสงค์",
            default: "เริ่มต้น",
            jDCOM: "จิงดี",
            dataType: "ชนิดข้อมูล",
            selectAnalysisTask: "เลือกงานวิเคราะห์",
            uploadFiles: "อัปโหลดไฟล์",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1.ดาวน์โหลดเทมเพลต excel และกรอกข้อมูลในรูปแบบ:",
            template: "แม่แบบ",
            SupportUpTo10000PiecesOfData: "2.รองรับสูงสุด10000ข้อมูล",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "รองรับการอัปโหลดข้อมูลการสนทนาที่ส่งออกโดย JD, ไฟล์ .txt, ขนาดไฟล์สูงสุด100M",
            alongTheWayDataTemplate: "แม่แบบข้อมูลถนน",
          },
        },
        result: {
          template: {
            textUnderInspection: "การตรวจสอบข้อความโปรดอดทนรอ",
            textQualityInspectionCompleted:
              "การตรวจสอบคุณภาพข้อความเสร็จสมบูรณ์",
          },
        },
        template: {
          startAnalysis: "เริ่มต้นการวิเคราะห์",
          pleaseUploadTheFile: "กรุณาอัปโหลดไฟล์",
          localDataUpload: "อัปโหลดข้อมูลท้องถิ่น",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            'คุณสมบัติใหม่! คลิกปุ่ม "ตรวจสอบการส่ง" และจะข้ามไปยังหน้าถัดไปโดยอัตโนมัติหลังจากส่งสำเร็จ ~~~',
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "อำนาจงานตรวจสอบคุณภาพ",
            edit: "บรรณาธิการ",
            iParticipated: "ฉันเข้าร่วม",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "บทบาทสามารถดูข้อมูลภายใต้แผนกที่เลือก",
            dataPermissions: "สิทธิ์ข้อมูล",
            selectDepartment: "เลือกแผนก",
            edit: "บรรณาธิการ",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "เริ่มดำเนินการตรวจสอบคุณภาพใหม่",
          callID: "โทร ID",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'คุณสมบัติใหม่! คลิกปุ่ม "การส่งการตรวจสอบการสุ่มตัวอย่าง" และ "การตรวจสอบการสุ่มตัวอย่างอย่างรวดเร็ว" หลังจากการส่งสำเร็จระบบจะข้ามไปยังหน้าถัดไปโดยอัตโนมัติ ~~~',
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "กฎ" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "การจัดการใบสั่งงาน",
          workOrderDetails: "รายละเอียดใบสั่งงาน",
        },
      },
      template: {
        workOrderFilter: "ตัวกรองใบสั่งงาน",
        allWorkOrders: "ใบสั่งงานทั้งหมด",
        workOrderManagement: "การจัดการใบสั่งงาน",
      },
    },
    trainingCenter: {
      topicAnnotation: "การทำเครื่องหมายหัวข้อ",
      topicList: "รายการหัวข้อ",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "ประเภทตัวละคร",
              keyWord: "คำสำคัญ",
              pleaseEnterKeywordSearch: "กรุณาป้อนคำสำคัญเพื่อค้นหา",
              query: "สอบถามข้อมูล",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "ยังไม่ได้เปิดฟังก์ชันนี้โปรดติดต่อผู้ดูแลระบบหากจำเป็น",
          pleaseSelectAQuestion: "กรุณาเลือกคำถาม!",
          recommendedSimilarSentencesLargeModel:
            "แนะนำประโยคที่คล้ายกัน (รุ่นใหญ่)",
          selectAll: "เลือกทั้งหมด",
          add: "เพิ่ม",
          changeBatch: "เปลี่ยนชุด",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "ชั่วโมงการทำงานทางธุรกิจ",
      localDataUpload: "อัปโหลดข้อมูลท้องถิ่น",
      assembleMenuListAccordingToCurrentUserPermissions:
        "ตามสิทธิ์ของผู้ใช้ปัจจุบันประกอบรายการเมนู",
      sessionSettings: "การตั้งค่าเซสชัน",
      logoSettings: "การตั้งค่าโลโก้",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "ยินดีต้อนรับกลับ! กรุณากรอกรายละเอียดของคุณ",
        mailbox: "กล่องจดหมาย",
        password: "รหัสผ่าน",
        signIn: "เข้าสู่ระบบ",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "ไม่อนุญาตให้เข้าสู่ระบบ IP ปัจจุบัน",
          welcomeToLogin: "ยินดีต้อนรับเข้าสู่ระบบ",
          pursuingExcellenceAndLeadingChange:
            "แสวงหาความเป็นเลิศและนำไปสู่การเปลี่ยนแปลง",
          intelligentPlatform: "แพลตฟอร์มอัจฉริยะ",
        },
        virtualLogin: { index: { pleaseSelect: "กรุณาเลือก" } },
      },
      oauth: {
        illegalCall: "โทรผิดกฎหมาย",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "ยินดีต้อนรับกลับ! คลิกปุ่มด้านล่างเพื่อข้ามไปยังแพลตฟอร์มการพิสูจน์ตัวตน",
        loggingIn: "เข้าสู่ระบบ...",
        unifiedIdentityAuthenticationLogin: "เข้าสู่ระบบการพิสูจน์ตัวตนแบบรวม",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "การทดสอบกฎ: กฎบางอย่างไม่สามารถใช้การทดสอบกฎได้เช่นระดับเสียงความเร็วในการพูดข้อมูลบนท้องถนนเป็นต้น",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "เรียนรู้เพิ่มเติม",
          enteringTheSystem: "เข้าสู่ระบบ",
        },
      },
      index: {
        cancel: "การยกเลิก",
        enteringTheSystem: "เข้าสู่ระบบ",
        close: "ปิด",
        enterSystemApplication: "เข้าสู่แอปพลิเคชันระบบ",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'คลิก "เข้าสู่ระบบ" เพื่อข้ามไปยังแอปพลิเคชันระบบที่เปิดใช้งาน',
      },
    },
    permissionManagement: {
      employeeManagement: "การจัดการพนักงาน",
      roleManagement: "การจัดการบทบาท",
      permissionManagement: "การจัดการสิทธิ์",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "เท่ากับ",
            contain: "ประกอบด้วย",
            doesNotContain: "ไม่รวม",
            applicationSystem: "ระบบการประยุกต์ใช้",
            intelligentQualityInspection:
              "การตรวจสอบคุณภาพอัจฉริยะ/การวิเคราะห์เซสชันอัจฉริยะ",
            salesEmpowerment: "การเพิ่มขีดความสามารถในการขาย",
            dataSourceType: "ประเภทแหล่งข้อมูล",
            filterCriteria: "เงื่อนไขการคัดกรอง",
          },
        },
        index: {
          dataConnection: "เชื่อมต่อข้อมูล",
          savingSucceeded: "บันทึกความสำเร็จ",
          dataIsolation: "การแยกข้อมูล",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "หลังจากเปิดแล้วคุณสามารถตั้งค่าช่วงข้อมูลของแต่ละระบบแอปพลิเคชันเพื่อเข้าถึงการโทรและเซสชัน WeChat ขององค์กรหากสวิตช์ไม่เปิดขึ้นแต่ละระบบจะเชื่อมต่อกับข้อมูลทั้งหมด",
          accessRules: "กฎการเข้าถึง",
          preservation: "บันทึก",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "ชื่อแอป",
            accessRights: "สิทธิ์การเข้าถึง",
            inAppPermissionSettings: "การตั้งค่าสิทธิ์ในแอป",
            setUp: "การตั้งค่า",
            applicationAccess: "สิทธิ์การเข้าถึงแอป",
            newRole: "เพิ่มตัวละคร",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "รายละเอียดเซสชัน" } },
      messageNotification: {
        index: {
          sessionDetails: "รายละเอียดเซสชัน",
          caseDetails: "รายละเอียดกรณี",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "พนักงานที่มองเห็นได้",
            newCourse: "หลักสูตรใหม่",
            editCourse: "แก้ไขหลักสูตร",
            courseName: "ชื่อหลักสูตร",
            courseIntroduction: "แนะนำหลักสูตร",
            courseType: "ประเภทของหลักสูตร",
            courseClassification: "การจัดหมวดหมู่หลักสูตร",
            courseCover: "ปกหลักสูตร",
            learningContent: "เนื้อหาการเรียนรู้",
            uploadLearningMaterials: "อัปโหลดสื่อการเรียนรู้",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "การทดสอบ",
              preservation: "บันทึก",
              cancel: "การยกเลิก",
              editNodeName: "แก้ไขชื่อโหนด",
              determine: "กำหนด",
              individual: "หนึ่ง",
              of: ", รวม",
              branch: "แบ่ง",
            },
            configDrawerComponents: {
              pPTParsing: "การวิเคราะห์ PPT...",
              uploadSuccessful: "อัพโหลดสำเร็จ",
              uploadAttachments: "อัปโหลดไฟล์แนบ",
              pPTParsingPleaseWait: "การวิเคราะห์ PPT โปรดรอสักครู่",
            },
            customerPortraitConfigDrawer: {
              cancel: "การยกเลิก",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "ไม่สามารถกู้คืนได้หลังจากลบแล้วคุณแน่ใจหรือว่าต้องการลบภาพนี้?",
              delete: "ลบ",
              determine: "กำหนด",
              customerPortrait: "ภาพลูกค้า",
              bindSelectedCharacterPortrait: "การผูกภาพตัวละครที่เลือก",
              customerProfileDetails: "รายละเอียดภาพลูกค้า",
              createANewCustomerProfile: "สร้างภาพลูกค้าใหม่",
            },
            flow: {
              sidebar: { node: "โหนด" },
              useControlButtons: {
                cancellingPleaseWait: "ยกเลิกโปรดรอสักครู่...",
                redoInProgressPleaseWait: "กำลังทำซ้ำโปรดรอสักครู่...",
                automaticOrganization: "จัดระเบียบอัตโนมัติ",
                canvasAdaptation: "ผ้าใบปรับตัวได้",
                delete: "ลบ",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "โหนดไม่สามารถเชื่อมต่อกับตัวเอง",
              },
            },
            globalConfigDrawer: {
              cancel: "การยกเลิก",
              determine: "กำหนด",
              globalConfiguration: "การกำหนดค่าทั่วโลก",
              sessionDetection: "การตรวจสอบเซสชัน",
              reciprocalRule: "กฎการโต้ตอบ",
              robotVoice: "เสียงหุ่นยนต์",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "ไม่มีการตั้งค่าการหมดเวลาโหนดของนักเรียน",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "เวลาเงียบสูงสุด (s) ที่นักเรียนไม่ตอบ",
              promptScriptForFailureToAnswerOverTime:
                "การหมดเวลาโดยไม่ตอบคำถาม",
              addScript: "เพิ่มคำ",
            },
            robotTimbreForm: {
              aixianatural: "Ai Xia (ธรรมชาติ)",
              zhishaChat: "Zhisha (แชท)",
              zhiqiangentle: "Zhiqian (อ่อนโยน)",
              zhimiEmotion: "Zhimi (อารมณ์)",
              aiShuoNatural: "Ai Shuo (ธรรมชาติ)",
              zhishuoChat: "Zhishuo (แชท)",
              aiHaoAffinity: "Ai Hao (ความสัมพันธ์)",
              zhixiangEmotion: "Zhixiang (อารมณ์)",
              robotVoice: "เสียงหุ่นยนต์",
              schoolboy: "เด็กผู้ชาย",
              girlStudent: "ผู้หญิง",
              volume: "ระดับเสียง",
              tone: "โทนเสียง",
              speechSpeed: "ความเร็วในการพูด",
              test: "การทดสอบ",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "สวัสดีฉันเป็นโค้ชการฝึกอบรมที่ชาญฉลาดและหวังว่าจะได้สื่อสารกับคุณ",
            },
            sessionDetectionForm: {
              expressionDetection: "การตรวจจับการแสดงออก",
              excessiveDetectionOfModalParticles:
                "การตรวจจับอนุภาคก๊าซมากเกินไป",
              theNumberOfRepetitionsIs: "จำนวนการทำซ้ำคือ",
              minus: "ครั้งที่สองลบ",
              appear: "ปรากฏ",
              subtract: "ครั้งลบ",
              branch: "แบ่ง",
              addRules: "เพิ่มกฎ",
              includingNegativeAngryComplaining: "(รวมถึงแง่ลบโกรธบ่น)",
              scorePointsDisplay: "คะแนนจุดแสดง",
              reminderOfSpeakingTooFasttooSlow: "เร็วเกินไป/ช้าเกินไปเตือน",
              scriptViolationReminder: "การแจ้งเตือนการละเมิด",
              emotionalReminder: "เตือนอารมณ์",
              speechSpeedDetection: "การตรวจจับความเร็วในการพูด",
              normalSpeechSpeedScore: "คะแนนการพูดปกติ",
              speakTooFast: "พูดเร็วเกินไป",
              everyMinuteGreaterThan: "มากกว่าต่อนาที",
              aWordAppears: "คำปรากฏขึ้น",
              speakTooSlowly: "พูดช้าเกินไป",
              lessThanPerMinute: "ต่อนาทีน้อยกว่า",
              ignoreLessThan: "ละเว้นน้อยกว่า",
              aSentenceOfWords: "ประโยคคำ",
              emotionalDetection: "การตรวจจับอารมณ์",
              emotionalNormalScore: "คะแนนอารมณ์ปกติ",
              negativeEmotions: "อารมณ์เชิงลบ",
              illegalWordDetection: "การตรวจจับการละเมิด",
              scriptViolationConfiguration: "การกำหนดค่าที่ผิดกฎหมาย",
              realTimePromptOnTheFrontEnd: "พรอมต์แบบเรียลไทม์ส่วนหน้า",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "ถาม-ตอบฐานความรู้",
                  qAMethod: "คำถามและคำตอบ",
                  numberOfQuestionsAndAnswers: "จำนวนคำถามและคำตอบ",
                  scoringRules: "กฎการให้คะแนน",
                  ifAnyQuestionAndAnswerIsCorrect: "คำถามและคำตอบใดๆถูกต้อง",
                  branch: "แบ่ง",
                  successfullySaved: "บันทึกความสำเร็จ",
                },
                pptNode: {
                  verificationPassed: "ผ่านการตรวจสอบ",
                  upload: "อัปโหลด",
                  supportPptPdfFormatFiles: "รองรับไฟล์ ppt, pdf",
                  uploadSlide: "อัปโหลดสไลด์โชว์",
                  assessmentMethod: "วิธีการประเมิน",
                  associateSlide: "สไลด์โชว์ที่เกี่ยวข้อง",
                  standardScript: "ทักษะการพูดมาตรฐาน",
                  successfullySaved: "บันทึกความสำเร็จ",
                },
                robotNode: {
                  robotScript: "หุ่นยนต์พูด",
                  randomScript: "การพูดแบบสุ่ม",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "เลือกคำความหมายที่คล้ายกันหุ่นยนต์จะสุ่มพูดประโยคใดประโยคหนึ่ง",
                  customerPortrait: "ภาพลูกค้า",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "เมื่อภาพลูกค้าเหมือนกันหุ่นยนต์จะพูดสิ่งนี้",
                  setAsDefaultScript: "ตั้งเป็นคำเริ่มต้น",
                  yes: "ใช่",
                  no: "ไม่ใช่",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "เมื่อหุ่นยนต์พลาดแท็กผู้ใช้ใดๆ",
                  successfullySaved: "บันทึกความสำเร็จ",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "โหนดสาขานี้มีเทคนิคการพูดเริ่มต้นอยู่แล้วภาษาเริ่มต้นจะถูกแทนที่ด้วยเทคนิคการพูดปัจจุบันหรือไม่",
                },
                startNode: {
                  paragon: "การบรรยาย",
                  successfullySaved: "บันทึกความสำเร็จ",
                },
                userNode: {
                  verificationPassed: "ผ่านการตรวจสอบ",
                  standardScript: "ทักษะการพูดมาตรฐาน",
                  successfullySaved: "บันทึกความสำเร็จ",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "โปรดปรับปรุงการกำหนดค่า",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "กรุณากรอกตัวอย่างการสนทนา",
                  dialogueExample: "ตัวอย่างการสนทนา",
                  generateConversationExamples: "สร้างตัวอย่างการสนทนา",
                  mattersNeedingAttention: "ข้อควรระวัง",
                  pleaseEnterThePromptLanguage: "กรุณากรอกข้อความแจ้ง",
                },
                aiGenSceneNode: {
                  dialogueScenario: "ฉากสนทนา",
                  simulateCharacter: "จำลองตัวละคร",
                  accompanyingTrainees: "วัตถุซ้อม",
                  selectFromTemplateLibrary: "เลือกจากไลบรารีเทมเพลต",
                  templateLibrary: "ไลบรารีแม่แบบ",
                  role: "บทบาท",
                  scene: "ฉาก",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "หน้าต่างทดสอบสไลด์【",
              clearContent: "ล้างเนื้อหา",
              close: "ปิด",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "กระบวนการสร้าง AI",
                scriptStyle: "สไตล์การพูด",
                theMostConversationRounds: "รอบการสนทนามากที่สุด",
                copyConversation: "คัดลอกการสนทนา",
                application: "การประยุกต์ใช้งาน",
                regenerate: "สร้างใหม่",
                startGenerating: "เริ่มสร้าง",
                pleaseGenerateAProcess: "โปรดสร้างกระบวนการ",
                copySuccessful: "คัดลอกสำเร็จ",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "ตัวแทนขาย",
                    customerServicePersonnel: "เจ้าหน้าที่บริการลูกค้า",
                    storeCounter: "เคาน์เตอร์ร้านค้า",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "สร้างคำพูดแบบเต็ม",
                speaker: "ลำโพง",
                scriptStyle: "สไตล์การพูด",
                copyConversation: "คัดลอกการสนทนา",
                application: "การประยุกต์ใช้งาน",
                regenerate: "สร้างใหม่",
                startGenerating: "เริ่มสร้าง",
                pleaseGenerateAProcess: "โปรดสร้างกระบวนการ",
                operationSuccessful: "การดำเนินงานที่ประสบความสำเร็จ",
                copySuccessful: "คัดลอกสำเร็จ",
              },
            },
            aiTextArea: { index: { scriptPolishing: "เทคนิคการพูดขัด" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "คำพูดไม่ว่างเปล่า!",
              copySuccessful: "คัดลอกสำเร็จ",
              cancel: "การยกเลิก",
              copy: "คัดลอก",
              replaceTheOriginalText: "แทนที่ข้อความต้นฉบับ",
              aiGeneration: "การสร้าง Ai",
              aiPolishing: "Ai ขัด",
              currentStyle: "สไตล์ปัจจุบัน",
              rigorous: "เข้มงวด",
              mild: "อ่อนโยน",
              lively: "มีชีวิตชีวา",
              simplicity: "เรียบง่าย",
              pleaseEnterTheSpeakerRole: "กรุณาใส่บทบาทของผู้พูด",
              startGenerating: "เริ่มสร้าง",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "คำพูดไม่ว่างเปล่า!",
              copySuccessful: "คัดลอกสำเร็จ",
              aiRewrite: "Ai เขียนใหม่",
              cancel: "การยกเลิก",
              copy: "คัดลอก",
              replaceTheOriginalText: "แทนที่ข้อความต้นฉบับ",
              currentStyle: "สไตล์ปัจจุบัน",
              rigorous: "เข้มงวด",
              mild: "อ่อนโยน",
              lively: "มีชีวิตชีวา",
              simplicity: "เรียบง่าย",
              pleaseEnterTheSpeakerRole: "กรุณาใส่บทบาทของผู้พูด",
              startGenerating: "เริ่มสร้าง",
            },
          },
          index: {
            verificationPassed: "ตรวจสอบผ่าน",
            ruleValidation: "การตรวจสอบกฎ",
            aIGeneration: "การสร้าง AI",
            generateFullTextSpeechScript: "สร้างคำพูดแบบเต็ม",
            editCourse: "แก้ไขหลักสูตร",
            importConversation: "นำเข้าการสนทนา",
            uploadExcelToCompleteDialogueImport:
              "อัปโหลด Excel เพื่อนำเข้าบทสนทนาที่สมบูรณ์",
            editBasicInformation: "แก้ไขข้อมูลพื้นฐาน",
            test: "การทดสอบ",
            release: "โพสต์",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "แท็บใหม่" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "ไม่สามารถกู้คืนได้หลังจากลบแล้วแน่ใจว่าต้องการลบแท็บนี้หรือไม่?",
              delete: "ลบ",
              cancel: "การยกเลิก",
            },
          },
        },
        index: {
          labelManagement: "การจัดการฉลาก",
          newGroup: "กลุ่มใหม่",
          edit: "บรรณาธิการ",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "หลังจากลบแล้วไม่สามารถกู้คืนได้คุณแน่ใจหรือไม่ว่าต้องการลบกลุ่มแท็บนี้",
          delete: "ลบ",
          cancel: "การยกเลิก",
          editGroup: "แก้ไขการจัดกลุ่ม",
          labelGroupName: "ชื่อกลุ่มแท็ก",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "ปิดใช้งานกล้อง",
              noCameraDevicesFound: "ไม่พบอุปกรณ์กล้อง",
            },
          },
          cue: {
            index: {
              regenerate: "สร้างใหม่",
              generate: "สร้าง",
              referenceScript: "คำอ้างอิง",
              mattersNeedingAttention: "ข้อควรระวัง",
              examFrequency: "จำนวนการสอบ",
              section: "ลำดับที่",
              timestotal: "ครั้ง/ทั้งหมด",
              second: "ครั้ง",
              requirementsForPassingTheExam: "ผ่านข้อกำหนดการสอบ",
              examScore: "คะแนนสอบ ≥",
              branch: "แบ่ง",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "ไม่มีภาพบุคคล",
              customerBackground: "พื้นหลังของลูกค้า",
              customerLabel: "แท็กของลูกค้า",
            },
          },
          flow: {
            index: {
              processReminder: "เคล็ดลับกระบวนการ (",
              score: "คะแนน",
              TotalScore: "/คะแนนรวม",
            },
          },
          index: {
            taskName: "ชื่องาน",
            taskStatus: "สถานะงาน",
            whole: "ทั้งหมด",
            completionStatus: "สถานะสมบูรณ์",
            courseClassification: "การจัดหมวดหมู่หลักสูตร",
            practiceMode: "โหมดการปฏิบัติ",
            examMode: "โหมดการสอบ",
            practiceTime: "เวลาฝึก",
          },
          ppt: {
            index: {
              nextPage: "หน้าถัดไป",
              tips: "เคล็ดลับ",
              continueWithCurrentPage: "ดำเนินการต่อหน้าปัจจุบัน",
              goToTheNextPage: "ไปที่หน้าถัดไป",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "หลังจากเข้าสู่หน้าถัดไปคุณจะไม่สามารถกลับไปที่หน้าก่อนหน้าได้โปรดยืนยันว่าหน้าปัจจุบันเสร็จสมบูรณ์แล้วจากนั้นข้ามไป",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "คลิกที่หน้าถัดไปข้อความแจ้งนี้จะไม่ปรากฏขึ้น",
            },
            list: { index: { slide: "สไลด์โชว์" } },
          },
          record: {
            index: {
              unableToRecord: "ไม่สามารถบันทึก:",
              creatingARecorderService: "สร้างบริการ Recorder",
              turnOffTheRecorderService: "ปิดบริการ Recorder",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "ไคลเอนต์ปัจจุบันเชื่อมต่อกับเซิร์ฟเวอร์ ASR Websocket แล้ว",
              afterProcessingTheLastFrameRestoreTheState:
                "หลังจากประมวลผลเฟรมสุดท้ายแล้วให้คืนสถานะ",
              aSRConnectionClosed: "การเชื่อมต่อ ASR ถูกปิด...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "การบันทึกถูกระงับและประมวลผลแล้วแต่ยังมีข้อมูลอยู่ในแคช",
              foundASRChannelClosedRecreateWebsocketLink:
                "ค้นพบช่อง ASR ปิดและสร้างการเชื่อมโยง Websocket ใหม่",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "พบว่าช่อง ASR ไม่ได้เปิดขึ้นมาสร้างการเชื่อมโยง Websocket ใหม่",
            },
            ppt: {
              hook: { index: { serverError: "ข้อผิดพลาดของเซิร์ฟเวอร์" } },
            },
            text: {
              index: {
                pleaseEnter: "กรุณาใส่",
                sendOut: "ส่ง",
                startPracticing: "เริ่มฝึก",
                startTheExam: "เริ่มการสอบ",
              },
            },
            util: {
              recorded: "บันทึกไว้แล้ว",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 pcm SendFrameSize ต้องเป็นจำนวนเต็มหลาย2",
              pCMSamplingRateShouldNotOccur: "ไม่ควรมีอัตราการสุ่มตัวอย่าง pcm",
              andTheRequiredSamplingRate: "และอัตราตัวอย่างที่จำเป็น",
              atypism: "ไม่สอดคล้องกัน",
              errorsThatShouldNotOccur: "ข้อผิดพลาดที่ไม่ควรเกิดขึ้น:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> ข้อมูลที่ไม่เป็นระเบียบสามารถทิ้งได้โดยตรงหากความต้องการไม่สูง </span>',
              flower: "ดอกไม้",
              TransferStopped: ": หยุดการส่ง",
            },
            video: { index: { camera: "กล้อง" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "ไคลเอนต์ปัจจุบันเชื่อมต่อกับเซิร์ฟเวอร์ ASR Websocket แล้ว",
                    aSRConnectionClosed: "การเชื่อมต่อ ASR ถูกปิด...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "ค้นพบช่อง ASR ปิดและสร้างการเชื่อมโยง Websocket ใหม่",
                    unableToRecord: "ไม่สามารถบันทึก:",
                    aSRChannelClosedReopened: "ช่อง ASR ถูกปิดและเปิดอีกครั้ง",
                    theASRChannelWasNotCreatedStartingNow:
                      "ช่อง ASR ไม่ได้สร้างตอนนี้เริ่มสร้าง",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect โทรกลับเพื่อล้างช่อง ASR",
                    creatingARecorderService: "สร้างบริการ Recorder",
                    turnOffTheRecorderService: "ปิดบริการ Recorder",
                  },
                  util: {
                    recorded: "บันทึกไว้แล้ว",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 pcm SendFrameSize ต้องเป็นจำนวนเต็มหลาย2",
                    pCMSamplingRateShouldNotOccur:
                      "ไม่ควรมีอัตราการสุ่มตัวอย่าง pcm",
                    andTheRequiredSamplingRate: "และอัตราตัวอย่างที่จำเป็น",
                    atypism: "ไม่สอดคล้องกัน",
                    errorsThatShouldNotOccur: "ข้อผิดพลาดที่ไม่ควรเกิดขึ้น:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> ข้อมูลที่ไม่เป็นระเบียบสามารถทิ้งได้โดยตรงหากความต้องการไม่สูง </span>',
                    flower: "ดอกไม้",
                    TransferStopped: ": หยุดการส่ง",
                  },
                },
                index: { failInSend: "ส่งล้มเหลว" },
              },
              index: {
                startRecording: "เริ่มการบันทึก",
                endRecording: "สิ้นสุดการบันทึก",
                startPracticing: "เริ่มฝึก",
                startTheExam: "เริ่มการสอบ",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(จำนวนครั้งที่เหลือในการสอบปัจจุบันคือ",
              totalScriptScore: "คะแนนรวม:",
              totalEmotionalScore: "คะแนนรวมอารมณ์:",
              totalScoreOfExpression: "คะแนนรวมการแสดงออก:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "ผลการให้คะแนนงานปัจจุบันจำเป็นต้องได้รับการตรวจสอบด้วยตนเองและคุณจะได้รับแจ้งคะแนนสุดท้ายหลังจากการตรวจสอบเสร็จสิ้นโปรดอดใจรอ",
              areYouSureToSubmit: "คุณยืนยันการส่งหรือไม่?",
              theExerciseHasEndedAreYouSureToSubmit:
                "การออกกำลังกายสิ้นสุดลงคุณยืนยันการส่งหรือไม่?",
              theExamHasEndedAreYouSureToSubmit:
                "การสอบสิ้นสุดลงคุณยืนยันการส่งหรือไม่?",
              completed: "เสร็จเรียบร้อยแล้ว",
              page: "หน้า",
              pageCount: "จำนวนหน้าทั้งหมด",
              topic: "คำถาม",
              totalNumberOfQuestions: "จำนวนคำถามทั้งหมด",
              totalScore: "คะแนนรวม",
              branch: "แบ่ง",
              accumulatedCompletionProgress: "ความคืบหน้าเสร็จสิ้นสะสม",
              accumulatedPracticeDuration: "เวลาฝึกสะสม",
              lastPauseExerciseResults: "การระงับครั้งสุดท้าย-ผลการปฏิบัติ",
              exerciseInstructions: "คำแนะนำในการปฏิบัติ",
              examInstructions: "คำอธิบายการสอบ",
              halfwayPausePracticeResults: "การระงับกลางคัน-ผลการฝึกซ้อม",
              tips: "เคล็ดลับ",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "หลังจากออกแล้วแบบฝึกหัดนี้จะไม่นับรวมในคะแนนการออกได้รับการยืนยันหรือไม่?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "ไม่อนุญาตให้ถอนระหว่างการสอบคุณยืนยันการส่งหรือไม่?",
              pleaseOperateWithCaution: "ครั้งโปรดใช้ความระมัดระวัง)",
              restart: "เริ่มต้นใหม่อีกครั้ง",
              returnToLearning: "กลับไปเรียนรู้",
              continuePracticing: "ฝึกฝนต่อไป",
              continuingTheExam: "ดำเนินการสอบต่อไป",
              return: "กลับ",
              notSubmittedTemporarily: "ไม่ส่งชั่วคราว",
              iGotIt: "ฉันรู้แล้ว",
              confirmExit: "ยืนยันการถอน",
              confirmSubmission: "ยืนยันการส่ง",
              signOut: "ออก",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "หยุดชั่วคราว",
              submit: "ส่ง",
              remainingTimeOfThisQuestion: "เวลาที่เหลือของคำถามนี้",
              SecondsLeftUntilTheEndTime: "เหลือเวลาอีก10วินาที",
              remainingTime: "เวลาที่เหลือ",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "ไม่พบเนื้อหาเสียงของผู้ใช้โปรดลองอีกครั้ง",
          },
          wave: {
            index: {
              continueRecording: "บันทึกต่อไป",
              submit: "ส่ง",
              endRecording: "สิ้นสุดการบันทึก",
              suspendRecording: "หยุดการบันทึกชั่วคราว",
              startPracticing: "เริ่มฝึก",
              startTheExam: "เริ่มการสอบ",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "โหมดการสนทนา",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "เสียงดังเกินไปโปรดเปลี่ยนสภาพแวดล้อมที่เงียบ",
              normalNoiseDetection: "การตรวจจับเสียงรบกวนเป็นเรื่องปกติ",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "ระดับเสียงน้อยเกินไปโปรดเพิ่มระดับเสียง",
              volumeDetectionIsNormal: "การตรวจจับระดับเสียงปกติ",
              noiseDetection: "การตรวจจับเสียงรบกวน",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "โปรดเงียบและบันทึกเสียงสภาพแวดล้อม3s",
              endRecording: "สิ้นสุดการบันทึก",
              startRecording: "เริ่มการบันทึก",
              volumeDetection: "การตรวจจับระดับเสียง",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'โปรดอ่านออกเสียง "ทดสอบระดับเสียงตอนนี้" ด้วยระดับเสียงปกติ',
              playLastDetectedAudio: "เล่นเสียงที่ตรวจพบล่าสุด",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "โปรดอ่านข้อกำหนดอย่างละเอียดก่อนเริ่ม",
              return: "กลับ",
              iGotIt: "ฉันรู้แล้ว",
              timeLimitForSingleQuestionAnswering:
                "จำกัดเวลาในการตอบคำถามเดียว",
              totalTimeLimit: "ระยะเวลารวม",
              unlimitedTime: "ไม่จำกัดเวลา",
              stillNeedToCompleteTheTaskInTotal: "จำเป็นต้องทำงานให้เสร็จ",
              practice: "การออกกำลังกาย",
              second: "ครั้ง",
              taskScore: "คะแนนงานครั้งเดียว ≥",
              branch: "แบ่ง",
              or: "หรือ",
              examScore: "คะแนนสอบ ≥",
              remainingExamTimes: "จำนวนการสอบที่เหลือ",
              totalScore: "คะแนนรวม",
              Script: "(คำ",
              scoreEmotion: "แบ่งอารมณ์",
              fractionExpression: "การแสดงออกย่อย",
              points: "คะแนน)",
              standardScore: "คะแนนมาตรฐาน",
              excellentScore: "คะแนนดีเยี่ยม",
              exerciseInstructions: "คำแนะนำในการปฏิบัติ",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "เพื่อให้คำตอบเป็นไปอย่างราบรื่นโปรดตรวจสอบอุปกรณ์ให้เสร็จสิ้นก่อน:",
              camera: "กล้อง",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "การได้เห็นตัวเองในวิดีโอหมายความว่ากล้องเป็นเรื่องปกติ",
              microphone: "ไมโครโฟน",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "กรุณาพูดกับไมโครโฟนดูรูปแบบคลื่นหมายความว่าไมโครโฟนปกติ",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "ว่างเปล่า",
                  copyTo: "คัดลอกไปที่",
                  classification: "หมวดหมู่",
                  rename: "เปลี่ยนชื่อ",
                  newlyBuild: "สร้างใหม่",
                  categoryParent: "การจำแนกประเภทที่เหนือกว่า",
                  moveTo: "ย้ายไปที่",
                  name: "ชื่อ",
                },
              },
              util: {
                rename: "เปลี่ยนชื่อ",
                moveTo: "ย้ายไปที่",
                copyTo: "คัดลอกไปที่",
                delete: "ลบ",
                courseClassification: "การจัดหมวดหมู่หลักสูตร",
                newlyBuild: "สร้างใหม่",
              },
            },
          },
          edit: { index: { curriculum: "หลักสูตร" } },
          index: {
            learn: "เรียน",
            practice: "ฝึก",
            examination: "ทดสอบ",
            curriculum: "หลักสูตร",
            courseIntroduction: "แนะนำหลักสูตร:",
            createdOn: "สร้างเวลา:",
            published: "โพสต์แล้ว",
            tasks: "งาน",
            unpublishedTasks: "งานที่ไม่ได้เผยแพร่",
            task: "ภารกิจ",
            operation: "การดำเนินงาน",
            learningMaterials: "สื่อการเรียนรู้",
            goPractice: "ไปฝึกซ้อม",
            publishTask: "โพสต์งาน",
            taskData: "ข้อมูลงาน",
            edit: "บรรณาธิการ",
            copy: "คัดลอก",
            successfullyCopied: "คัดลอกสำเร็จ",
            delete: "ลบ",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "หลังจากลบแล้วข้อมูลงานทั้งหมดในหลักสูตรนี้จะถูกล้างคุณยืนยันการลบหลักสูตรหรือไม่?",
            courseName: "ชื่อหลักสูตร",
            creationTime: "สร้างเวลา",
            releaseStatus: "สถานะการโพสต์",
            whole: "ทั้งหมด",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "ไม่พบเสียง",
              voice: "เสียง",
              text: "ข้อความ",
              referenceScript: "คำอ้างอิง:",
              clickOnTheRightSideToStartRecording:
                "คลิกที่ด้านขวาเพื่อเริ่มการบันทึก",
              sendOut: "ส่ง",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "ไคลเอนต์ปัจจุบันเชื่อมต่อกับเซิร์ฟเวอร์ ASR Websocket แล้ว",
                afterProcessingTheLastFrameRestoreTheState:
                  "หลังจากประมวลผลเฟรมสุดท้ายแล้วให้คืนสถานะ",
                aSRConnectionClosed: "การเชื่อมต่อ ASR ถูกปิด...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "การบันทึกถูกระงับและประมวลผลแล้วแต่ยังมีข้อมูลอยู่ในแคช",
                foundASRChannelClosedRecreateWebsocketLink:
                  "ค้นพบช่อง ASR ปิดและสร้างการเชื่อมโยง Websocket ใหม่",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "พบว่าช่อง ASR ไม่ได้เปิดขึ้นมาสร้างการเชื่อมโยง Websocket ใหม่",
                unableToRecord: "ไม่สามารถบันทึก:",
                creatingARecorderService: "สร้างบริการ Recorder",
                turnOffTheRecorderService: "ปิดบริการ Recorder",
              },
              util: {
                recorded: "บันทึกไว้แล้ว",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 pcm SendFrameSize ต้องเป็นจำนวนเต็มหลาย2",
                pCMSamplingRateShouldNotOccur:
                  "ไม่ควรมีอัตราการสุ่มตัวอย่าง pcm",
                andTheRequiredSamplingRate: "และอัตราตัวอย่างที่จำเป็น",
                atypism: "ไม่สอดคล้องกัน",
                errorsThatShouldNotOccur: "ข้อผิดพลาดที่ไม่ควรเกิดขึ้น:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> ข้อมูลที่ไม่เป็นระเบียบสามารถทิ้งได้โดยตรงหากความต้องการไม่สูง </span>',
                flower: "ดอกไม้",
                TransferStopped: ": หยุดการส่ง",
              },
            },
            finishTest: {
              index: {
                endTest: "สิ้นสุดการทดสอบ",
                testResult: "ผลการทดสอบ",
                returnToCanvas: "กลับไปที่ผ้าใบ",
                retest: "ทดสอบอีกครั้ง",
              },
            },
          },
          index: {
            testFromCurrentNode: "การทดสอบจากโหนดปัจจุบัน",
            listening: "วิทยุ...",
            canvasTesting: "การทดสอบผ้าใบ",
            endOfProcess: "สิ้นสุดกระบวนการ",
            restart: "เริ่มต้นใหม่อีกครั้ง",
            start: "เริ่มต้น",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "วิเคราะห์",
                  expandParsing: "ขยายการวิเคราะห์",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: ", โทนเสียงมากเกินไป",
                  second: "ครั้ง",
                  ratingOverview: "ภาพรวมการให้คะแนน",
                  totalScore: "คะแนนรวม",
                  section: "ลำดับที่",
                  name: "ชื่อ",
                  of: "(รวม",
                  people: "คน)",
                  scriptScore: "คะแนนการพูด",
                  common: "รวม",
                  keyPoints: "ประเด็นสำคัญ",
                  notReached: "ไม่ถึง",
                  have: "มี",
                  violationOfRegulations: "การละเมิด",
                  emotionalScore: "คะแนนอารมณ์",
                  appear: "ปรากฏ",
                  secondaryNegativeEmotions: "อารมณ์เชิงลบ",
                  expressionScore: "คะแนนการแสดงออก",
                  speakTooFast: "พูดเร็วเกินไป",
                  times: "ครั้ง,",
                  speakTooSlowly: "พูดช้าเกินไป",
                  average: "เฉลี่ย",
                  wordminute: "คำ/นาที",
                },
              },
              score: {
                index: {
                  studentNodeName: "ชื่อโหนดนักเรียน",
                  keyPointName: "ชื่อจุด",
                  slideNodeName: "ชื่อโหนดสไลด์",
                  dialogue: "การสนทนา",
                  mainPoints: "จุด",
                  slide: "สไลด์โชว์",
                  detectionResult: "ผลการทดสอบ",
                  satisfy: "พอใจ",
                  dissatisfaction: "ไม่พอใจ",
                  score: "คะแนน",
                  script: "ทักษะการพูด",
                  semantics: "ความหมาย",
                  scriptViolation: "การละเมิดคำพูด",
                  emotion: "อารมณ์",
                  expression: "การแสดงออก",
                },
              },
              taskTitle: { index: { branch: "แบ่ง" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "ในครั้งนี้",
                  lastTime: "ครั้งล่าสุด",
                  comprehensiveEvaluation: "การประเมินผลที่ครอบคลุม",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· คำมาตรฐานสะสม',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "คำจำนวนคำที่นักเรียนตีด้วยการอ่าน",
                  aWordspan: "คำ </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'ครั้ง </span><br/><span style = "margin-left: 8px">· พูดช้าเกินไป',
                  timesspan: "ครั้ง </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· มากเกินไป',
                  complete: "เนื้อหาที่สมบูรณ์",
                  keyHit: "ตีประเด็นสำคัญ",
                  languageNorm: "ข้อกำหนดภาษา",
                  fluentExpression: "การแสดงออกที่ราบรื่น",
                  emotionallyPositive: "อารมณ์บวก",
                  radarChart: "แผนที่เรดาร์",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· จำนวนกระบวนการที่สมบูรณ์',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· ตีจุดที่คุณต้องการ',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· มี',
                  dealingWithIllegalWordsspan: "การละเมิดคำ </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· พูดเร็วเกินไป',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'ครั้ง </span><br/><span style = "margin-left: 8px">· คำพูดที่มีสไตล์มากเกินไป',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· อารมณ์เชิงลบ',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "บันทึกคะแนน",
                  firstRating: "การให้คะแนนครั้งแรก",
                  totalScore: "คะแนนรวม",
                  changeTo: "เปลี่ยนเป็น",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "ขั้นตอนการสื่อสาร",
                  communicationObjectives: "เป้าหมายการสื่อสาร",
                  scoringCriteria: "เกณฑ์การให้คะแนน",
                  suggestionsForImprovement: "คำแนะนำในการอัพเกรด",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "เวลาที่เหลือ:",
                  second: "ครั้ง",
                  doItAgain: "อีกครั้ง",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "เบราว์เซอร์ไม่สนับสนุนการเล่นเสียง",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "ความคิดเห็นและข้อเสนอแนะ",
                  regenerate: "สร้างใหม่",
                  generate: "สร้าง",
                  generationFailed: "ความล้มเหลวในการสร้าง",
                },
              },
              resultModal: {
                index: { return: "กลับ", viewDetails: "ดูรายละเอียด" },
              },
            },
            index: {
              reRatingSuccessful: "ให้คะแนนความสำเร็จอีกครั้ง",
              reRatingFailed: "ล้มเหลวในการให้คะแนนอีกครั้ง",
              downloadRecording: "ดาวน์โหลดการบันทึก",
              downloadText: "ดาวน์โหลดข้อความ",
              download: "ดาวน์โหลด",
              reRating: "ให้คะแนนอีกครั้ง",
              submitARatingAppeal: "ยื่นอุทธรณ์คะแนน",
              reviewSubmission: "การส่งรีวิว",
              saveChanges: "บันทึกการแก้ไข",
              previousSentence: "ประโยคก่อนหน้า",
              nextSentence: "ประโยคถัดไป",
              score: "การให้คะแนน",
              scoringSituation: "สถานการณ์การให้คะแนน",
              dialogueAnalysis: "การวิเคราะห์บทสนทนา",
            },
          },
          index: {
            ratingAppealInProgress: "การอุทธรณ์การให้คะแนน",
            operation: "การดำเนินงาน",
            wrongQuestionSet: "ชุดคำถามผิด",
            ratingUpdate: "การปรับปรุงคะแนน",
            viewDetails: "ดูรายละเอียด",
            curriculum: "หลักสูตร",
            taskName: "ชื่องาน",
            taskType: "ประเภทงาน",
            score: "คะแนน",
            scoringResults: "ผลคะแนน",
            time: "เวลา",
            courseClassification: "การจัดหมวดหมู่หลักสูตร",
            whole: "ทั้งหมด",
            learningTime: "เวลาศึกษา",
            historicalLearningData: "ข้อมูลการเรียนรู้ประวัติศาสตร์",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "หยุดการออกกำลังกาย",
              lastProgress: "ความคืบหน้าล่าสุด",
              continuePracticing: "ฝึกฝนต่อไป",
              practice: "การออกกำลังกาย",
              startPracticing: "เริ่มฝึก",
              historicalData: "ข้อมูลทางประวัติศาสตร์",
              courseIntroduction: "แนะนำหลักสูตร:",
              exerciseDifficulty: "ความยากลำบากในการปฏิบัติ:",
              practiceCompletionRequirements: "ข้อกำหนดในการทำแบบฝึกหัด:",
              practiceFrequency: "จำนวนการออกกำลังกาย ≥",
              second: "ครั้ง",
              anyScore: "คะแนนใดๆ ≥",
              branch: "แบ่ง",
              or: "หรือ",
              taskList: "รายการงาน",
              practiceTime: "เวลาปฏิบัติ:",
              to: "ถึง",
              unlimitedTime: "ไม่จำกัดเวลา",
              completionStatus: "สถานะสมบูรณ์",
              numberOfExercises: "จำนวนการออกกำลังกาย",
              maximumScore: "คะแนนสูงสุด",
              operation: "การดำเนินงาน",
              enteringLearning: "เข้าสู่การเรียนรู้",
              historicalLearningData: "ข้อมูลการเรียนรู้ประวัติศาสตร์",
              wrongQuestionSet: "ชุดคำถามผิด",
            },
          },
          index: {
            practiceTasks: "การปฏิบัติภารกิจ",
            examTasks: "งานสอบ",
            task: "ภารกิจ",
          },
          learn: {
            index: {
              courseName: "ชื่อหลักสูตร",
              courseIntroduction: "แนะนำหลักสูตร",
              learningContent: "เนื้อหาการเรียนรู้",
              downloadLearningMaterials: "ดาวน์โหลดสื่อการเรียนรู้",
              startPracticing: "เริ่มฝึก",
            },
          },
          test: {
            index: {
              taskList: "รายการงาน",
              courseIntroduction: "แนะนำหลักสูตร:",
              examDifficulty: "ความยากในการสอบ:",
              requirementsForPassingTheExam: "ข้อกำหนดในการผ่านสอบ:",
              examScore: "คะแนนสอบ ≥",
              branch: "แบ่ง",
              examTime: "เวลาสอบ:",
              to: "ถึง",
              unlimitedTime: "ไม่จำกัดเวลา",
              completionStatus: "สถานะสมบูรณ์",
              completionTimes: "จำนวนครั้งที่เสร็จสมบูรณ์",
              maximumScore: "คะแนนสูงสุด",
              operation: "การดำเนินงาน",
              immediateExam: "สอบทันที",
              history: "ประวัติความเป็นมา",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "คำอ้างอิง" } },
              flow: {
                index: {
                  wrongQuestionItem: "รายการคำถามผิด (",
                  score: "คะแนน",
                  TotalScore: "/คะแนนรวม",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "ข้อผิดพลาดของเซิร์ฟเวอร์" } },
                  index: {
                    startRecording: "เริ่มการบันทึก",
                    endRecording: "สิ้นสุดการบันทึก",
                    startPracticing: "เริ่มฝึก",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "กรุณาใส่",
                    sendOut: "ส่ง",
                    startPracticing: "เริ่มฝึก",
                  },
                },
                voice: {
                  index: {
                    startRecording: "เริ่มการบันทึก",
                    endRecording: "สิ้นสุดการบันทึก",
                    startPracticing: "เริ่มฝึก",
                  },
                  hook: { index: { failInSend: "ส่งล้มเหลว" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "คะแนนแบบฝึกหัดที่ไม่ถูกต้องนี้",
                  tips: "เคล็ดลับ",
                  areYouSureToEnd: "คุณยืนยันการสิ้นสุดหรือไม่?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "ฝึกอีกครั้งโดยไม่เข้าใจคำถามผิด",
                  continue: "ดำเนินการต่อ",
                  returnToTheSetOfIncorrectQuestions:
                    "กลับไปที่ชุดคำถามที่ไม่ถูกต้อง",
                  end: "สิ้นสุด",
                  wrongQuestionItem: "รายการคำถามผิด",
                  numberOfExercisesThisTime: "จำนวนแบบฝึกหัดนี้",
                  masteringTheSituation: "เข้าใจสถานการณ์",
                  mastered: "เข้าใจแล้ว",
                  notMastered: "ไม่เชี่ยวชาญ",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "ฝึกคำถามนี้อีกครั้ง",
                  nextQuestion: "คำถามถัดไป",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "ฝึกคำถามผิด",
                endExercise: "สิ้นสุดการออกกำลังกาย",
              },
            },
            text: {
              index: {
                mispracticeExercises: "ฝึกคำถามผิด",
                endExercise: "สิ้นสุดการออกกำลังกาย",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "ฝึกคำถามผิด",
                endExercise: "สิ้นสุดการออกกำลังกาย",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "ดูทักษะการพูด",
                  section: "ลำดับที่",
                  secondaryDiscourseTechnique: "คำที่สอง",
                },
              },
              tableItem: {
                count: { second: "ครั้ง" },
                scoreRuleList: {
                  noRelevantInformationFound: "ไม่พบข้อมูลที่เกี่ยวข้อง",
                  recallWords: "คำเรียกคืน",
                  branch: "แบ่ง",
                  answerIncorrectly: "ตอบผิด",
                  second: "ครั้ง",
                  wrongAnswerRate: "อัตราการตอบผิด",
                },
                standardScript: {
                  standardScript: "ทักษะการพูดมาตรฐาน",
                  myScript: "คำพูดของฉัน",
                },
                status: { mastered: "เข้าใจแล้ว", notMastered: "ไม่เชี่ยวชาญ" },
                index: {
                  totalNumberOfQuestionsAnswered: "จำนวนคำตอบทั้งหมด",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "จำนวนคำถามที่ไม่ถูกต้องได้รับการฝึกฝน",
                },
              },
            },
            index: {
              wrongQuestionList: "รายการคำถามผิด",
              wrongQuestionSet: "ชุดคำถามผิด",
              mispracticeExercises: "ฝึกคำถามผิด",
              taskName: "ชื่องาน",
              staff: "พนักงาน",
              masteringTheSituation: "เข้าใจสถานการณ์",
              whole: "ทั้งหมด",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "จำนวนการออกกำลังกาย ≥",
              second: "ครั้ง",
              or: "หรือ",
              excellentScoreAtAnyOneTime: "คะแนนดีเยี่ยมทุกครั้ง (≥",
              points: "คะแนน)",
              examScore: "คะแนนสอบ ≥",
              branch: "แบ่ง",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "การกระจายเฉลี่ยแบบสุ่ม",
              assignToDesignatedPersonnel: "มอบหมายให้บุคคลที่กำหนด",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams: "เพิ่มจำนวนการสอบสำเร็จ",
              failedToIncreaseTheNumberOfExams: "เพิ่มจำนวนการสอบล้มเหลว",
              increaseTheNumberOfExams: "เพิ่มจำนวนการสอบ",
              selectObjects: "เลือกวัตถุ",
            },
          },
          executionFilter: {
            index: { participationRate: "อัตราการมีส่วนร่วม" },
          },
          intelligentReview: {
            index: {
              overallReview: "รีวิวโดยรวม",
              pleaseEnterTheReviewCriterianotMandatory:
                "กรุณากรอกเกณฑ์การตรวจสอบ (ไม่จำเป็น)",
              commentaryOnSingleSentenceRhetoric:
                "ความคิดเห็นเกี่ยวกับประโยคเดียว",
              realTimeReview: "รีวิวแบบเรียลไทม์",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "คะแนนเฉลี่ยการสอบ",
            averageExamDurationmin: "ระยะเวลาการสอบเฉลี่ย (min)",
            accumulatedPracticeDurationmin: "ระยะเวลาการฝึกสะสม (นาที)",
            accumulatedPracticeTimes: "จำนวนการออกกำลังกายสะสม",
            totalScore: "คะแนนรวม",
            average: "คะแนนเฉลี่ย",
            total: "รวม",
            keyHitCount: "ประเด็นสำคัญจำนวนการตี",
            practiceAverageScore: "คะแนนเฉลี่ยการปฏิบัติ",
            averagePracticeDurationmin: "ระยะเวลาในการออกกำลังกายเฉลี่ย (นาที)",
            topOfTheHighestScore: "คะแนนสูงสุด Top3",
            branch: "แบ่ง",
            errorProneItemTop: "รายการที่ผิดพลาด Top5",
            hitRate: "อัตราการตี",
            hitRateOfKeyPointsInScript: "คะแนนการพูด",
            averageScriptScore: "คะแนนเฉลี่ย",
            sortByProcess: "เรียงตามกระบวนการ",
            sortByScore: "เรียงตามคะแนน",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "คะแนนเฉลี่ยของหน้านี้/คะแนนรวมของหน้านี้",
            averageScoreOfThisItemtotalScoreOfThisItem: "คะแนนเฉลี่ย/คะแนนรวม",
            details: "รายละเอียด",
            viewRules: "ดูกฎ",
            standardScript: "ทักษะการพูดมาตรฐาน",
          },
          scoreDetailList: {
            section: "ลำดับที่",
            second: "ครั้ง",
            practice: "การออกกำลังกาย",
            examination: "การสอบ",
            notMeetingStandards: "ไม่เป็นไปตามมาตรฐาน",
            meetingStandards: "มาตรฐาน",
            excellent: "ยอดเยี่ยม",
            frequency: "จำนวนครั้ง",
            fraction: "คะแนน",
            result: "ผล",
            operation: "การดำเนินงาน",
            viewDetails: "ดูรายละเอียด",
          },
          index: {
            common: "รวม",
            people: "คน",
            staff: "พนักงาน",
            notMeetingStandards: "ไม่เป็นไปตามมาตรฐาน",
            meetingStandards: "มาตรฐาน",
            excellent: "ยอดเยี่ยม",
            notStarted: "ยังไม่เริ่ม",
            haveInHand: "กำลังดำเนินการอยู่",
            completed: "เสร็จเรียบร้อยแล้ว",
            passed: "ผ่านไปแล้ว",
            hangInTheAir: "ยังไม่เสร็จ",
            failed: "ล้มเหลว",
            unlimitedTime: "ไม่จำกัดเวลา",
            to: "ถึง",
            maximumScoringCriteria: "เกณฑ์คะแนนสูงสุด",
            completionStatus: "สถานะสมบูรณ์",
            employeeName: "ชื่อพนักงาน",
            frequency: "จำนวนครั้ง",
            practice: "การออกกำลังกาย",
            examination: "การสอบ",
            average: "คะแนนเฉลี่ย",
            speechCraftAvgScore: "คะแนนเฉลี่ย",
            emotionAvgScore: "คะแนนเฉลี่ยทางอารมณ์",
            speedAvgScore: "คะแนนเฉลี่ยของการแสดงออก",
            flowAvgScore: "คะแนนเฉลี่ยของกระบวนการ",
            practiceCostTime: "ระยะเวลาสะสม",
            participateCommitRate: "อัตราการปฏิบัติตาม",
            participateExcellentRate: "อัตราที่ยอดเยี่ยม",
            duration: "ระยะเวลา",
            score: "คะแนน",
            scoringResults: "ผลคะแนน",
            flowScore: "คะแนนกระบวนการ",
            craftScore: "คะแนนการพูด",
            emotionScore: "คะแนนอารมณ์",
            speedScore: "คะแนนการแสดงออก",
            contentIntegrity: "ความสมบูรณ์ของเนื้อหา",
            pointHitRate: "อัตราการตีที่สำคัญ",
            emotionNegativeRate: "สัดส่วนของอารมณ์เชิงลบ",
            paraPhaSiaRate: "สัดส่วนของอัตราการพูดที่ผิดปกติ",
            toneOvermuchRate: "สัดส่วนของอนุภาคเสียงที่มากเกินไป",
            maximumScore: "คะแนนสูงสุด",
            highestScoreResult: "ผลคะแนนสูงสุด",
            state: "สถานะ",
            averageDurationmin: "ระยะเวลาเฉลี่ย (นาที)",
            lately: "เมื่อเร็วๆนี้",
            time: "เวลา",
            operation: "การดำเนินงาน",
            viewDetails: "ดูรายละเอียด",
            wrongQuestionSet: "ชุดคำถามผิด",
            taskName: "ชื่องาน",
            completeRequirements: "เสร็จสิ้นตามคำขอ",
            publishTo: "โพสต์วัตถุ",
            taskTime: "เวลาภารกิจ",
            fractionalComposition: "องค์ประกอบเศษส่วน",
            totalScore: "คะแนนรวม",
            branch: "แบ่ง",
            Script: "(คำ",
            scoreEmotion: "แบ่งอารมณ์",
            fractionExpression: "การแสดงออกย่อย",
            points: "คะแนน)",
            implementationRate: "อัตราการมีส่วนร่วม",
            numberOfExecutors: "จำนวนผู้เข้าร่วม",
            numberOfPeopleNotExecuted: "จำนวนคนที่ไม่ได้เข้าร่วม",
            completionRate: "อัตราความสำเร็จ",
            numberOfCompletedPersonnel: "จำนวนคนที่เสร็จสมบูรณ์",
            numberOfUnfinishedPersonnel: "จำนวนคนที่ยังไม่เสร็จ",
            complianceRate: "อัตราการปฏิบัติตาม",
            numberOfQualifiedPersonnel: "จำนวนคนที่ตรงตามมาตรฐาน",
            numberOfNonCompliantIndividuals: "จำนวนคนที่ไม่เป็นไปตามมาตรฐาน",
            excellentRate: "อัตราที่ยอดเยี่ยม",
            participateCompleteRate: "อัตราการเข้าร่วมสำเร็จ",
            numberOfOutstandingIndividuals: "จำนวนที่ยอดเยี่ยม",
            numberOfNonOutstandingIndividuals: "จำนวนคนที่ไม่โดดเด่น",
            export: "ส่งออก",
            taskData: "ข้อมูลงาน",
            resultAnalysis: "การวิเคราะห์ผลลัพธ์",
            scoreDetails: "รายละเอียดคะแนน-",
            dataOverview: "ภาพรวมข้อมูล",
            taskDetail: "รายละเอียดงาน",
            scriptAnalysis: "การวิเคราะห์คำพูด",
            flowAnalysis: "การวิเคราะห์กระบวนการ",
            expressionAnalysis: "การวิเคราะห์การแสดงออก",
            emotionAnalysis: "การวิเคราะห์อารมณ์",
            byTime: "มุมมองมิติเวลา",
            byNumber: "มุมมองมิติความถี่",
            personData: "สถิติโดยบุคลากร",
            numberData: "บันทึกรายละเอียด",
            count: "ตามจำนวนครั้ง",
            person: "ตามจำนวนคน",
            reachTimes: "จำนวนการเข้าถึง",
            outOfReachTimes: "จำนวนครั้งที่ไม่สามารถเข้าถึงได้",
            seeOutOfReach: "คลิกเพื่อดูไม่ถึง",
            accumulative: "สะสม",
            script: "ทักษะการพูด",
            times: "ครั้ง",
            sentence: "ประโยค",
            expressionAverage: "ค่าเฉลี่ยการแสดงออก",
            modalAnalysis: "การวิเคราะห์อนุภาค",
            total: "รวม",
            muchModalAnalysis: "มีอนุภาคเสียงมากเกินไป",
            normal: "ปกติ",
            speedAnalysis: "การวิเคราะห์ความเร็วในการพูด",
            speedDistribution: "การกระจายความเร็วในการพูด",
            speakingSpeed: "ความเร็วในการพูด",
            normalSpeak: "อัตราการพูดปกติ",
            tooFastSpeak: "พูดเร็วเกินไป",
            tooSlowlySpeak: "พูดช้าเกินไป",
            violationWord: "การละเมิด",
            violationWordAnalysis: "การวิเคราะห์การละเมิด",
            noData: "ไม่มี",
            emotionalScore: "คะแนนอารมณ์",
            emotionalDistribution: "การกระจายอารมณ์",
            negativeEmotion: "อารมณ์เชิงลบ",
            normalEmotion: "อารมณ์ปกติ",
            getDetail: "ดูรายละเอียด",
            barPattern: "มุมมองแผนภูมิแท่ง",
            timeDimension: "มุมมองละติจูดเวลา",
            timesDimension: "มุมมองมิติความถี่",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "คะแนนเฉลี่ย",
                  averageScoreOfScript: "คะแนนเฉลี่ย",
                  averageEmotionalScore: "อารมณ์เฉลี่ย",
                  expressionAverageScore: "คะแนนเฉลี่ยของการแสดงออก",
                  processAverageScore: "กระบวนการเฉลี่ย",
                  accumulatedDuration: "ระยะเวลาสะสม",
                  accumulatedTimes: "จำนวนสะสม",
                  participationComplianceRate:
                    "อัตราการปฏิบัติตามการมีส่วนร่วม",
                  participationRateForAchievingExcellence: "อัตราการมีส่วนร่วม",
                  branch: "แบ่ง",
                  numberOfPeople: "จำนวนคน",
                  averageScoreOfExpression: "ค่าเฉลี่ยการแสดงออก",
                  people: "คน",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: { index: { negativeEmotions: "อารมณ์เชิงลบ" } },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "คะแนนเฉลี่ย",
                  branch: "แบ่ง",
                },
                useSummaryDetail: { keyHitRate: "อัตราการตีที่สำคัญ" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "รีวิวอัจฉริยะ",
            pleaseImproveTheConfiguration: "โปรดปรับปรุงการกำหนดค่า",
            examResults: "ผลการสอบ",
            showTheResultsAfterEachExam: "แสดงผลหลังการสอบทุกครั้ง",
            showTheResultsAfterTheLastExam: "แสดงผลการสอบครั้งสุดท้าย",
            doNotDisplayResults: "ไม่แสดงผล",
            resultDisplay: "แสดงผล",
            score: "คะแนน",
            scoringResults: "ผลคะแนน",
            allowViewingScoreDetails: "อนุญาตให้ดูรายละเอียดคะแนน",
            viewCanvasConfiguration: "ดูการกำหนดค่าผ้าใบ",
            synchronizationFailedProcessNodeHasChanged:
              "การซิงโครไนซ์ล้มเหลวโหนดกระบวนการเปลี่ยนแปลง",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "การซิงโครไนซ์สำเร็จโปรดคลิกเพื่อเผยแพร่เพื่ออัปเดตหลักสูตร",
            retrievedTheLatestCanvasConfiguration:
              "ดึงการกำหนดค่าผ้าใบล่าสุดแล้ว",
            synchronizeCanvasConfiguration: "การกำหนดค่าผ้าใบแบบซิงโครนัส",
            scoringMethod: "วิธีการให้คะแนน",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "คะแนน, กระบวนการ {0} คะแนน, ทักษะการพูด {1} คะแนน, อารมณ์ {2} คะแนน, ความเร็วในการพูด {3} คะแนน)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "มาตรฐานที่ยอดเยี่ยมจะต้องสูงกว่ามาตรฐาน",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "มาตรฐานการให้คะแนนต้องมากกว่า0% น้อยกว่าหรือเท่ากับ100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "มาตรฐานการให้คะแนนต้องเป็นไปตามคะแนนมากกว่า0คะแนนน้อยกว่าหรือเท่ากับ {0} คะแนน",
            doRatingsRequireManualReview:
              "การให้คะแนนต้องได้รับการตรวจสอบด้วยตนเองหรือไม่",
            allocationStrategy: "กลยุทธ์การกระจาย",
            pleaseSelectTheAssignedPersonnel:
              "โปรดเลือกบุคลากรที่ได้รับมอบหมาย",
            pleaseSelectADesignatedPerson: "โปรดเลือกบุคคลที่กำหนด",
            timeLimitForSingleQuestionAnswering: "จำกัดเวลาในการตอบคำถามเดียว",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "โพสต์เริ่มต้นสำหรับพนักงานที่เพิ่มใหม่",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "การให้คะแนนใหม่หมายความว่าจะจับคู่ตามกฎการให้คะแนนของผืนผ้าใบล่าสุดงานปัจจุบันมีข้อมูลการให้คะแนนที่ได้รับการตรวจสอบด้วยตนเองว่าจะครอบคลุมคะแนนหรือไม่",
            notCovered: "ไม่ครอบคลุม",
            cover: "ครอบคลุม",
            participationSituation: "การมีส่วนร่วม",
            numberOfParticipants: "จำนวนผู้เข้าร่วม",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "ผลรวมของจำนวนวัตถุฝึกที่เลือกสำหรับงานนี้",
            sumOfTheNumberOfParticipantsInTheTask:
              "ผลรวมของจำนวนคนที่เข้าร่วมในภารกิจ",
            numberOfCompletedPersonnel: "จำนวนคนที่เสร็จสมบูรณ์",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "จำนวนคนที่ตรงตามข้อกำหนดในการทำภารกิจให้สำเร็จ",
            numberOfQualifiedPersonnel: "จำนวนคนที่ตรงตามมาตรฐาน",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "จำนวนคนที่ตรงตามคะแนนงาน",
            excellentPersonnel: "จำนวนที่ยอดเยี่ยม",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "พบกับคะแนนภารกิจจำนวนคนที่ยอดเยี่ยม",
            participationRate: "อัตราการมีส่วนร่วม",
            numberOfParticipantsNumberOfParticipants:
              "จำนวนผู้เข้าร่วม/จำนวนผู้เข้าร่วม",
            numberOfCompletedNumberOfParticipants:
              "จำนวนคนที่ทำสำเร็จ/จำนวนคนที่ควรเข้าร่วม",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "จำนวนคนที่ตรงตามมาตรฐาน/จำนวนผู้เข้าร่วม",
            excellentNumbernumberOfParticipants:
              "จำนวนยอดเยี่ยม/จำนวนผู้เข้าร่วม",
            participationCompletionRate: "อัตราการเข้าร่วมสำเร็จ",
            numberOfCompletedParticipated:
              "จำนวนที่เสร็จสมบูรณ์/จำนวนผู้เข้าร่วม",
            participationComplianceRate: "อัตราการปฏิบัติตามการมีส่วนร่วม",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "จำนวนมาตรฐาน/จำนวนผู้เข้าร่วม",
            participationRateForAchievingExcellence: "อัตราการมีส่วนร่วม",
            accumulatedDuration: "ระยะเวลาสะสม",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "ระยะเวลาในการมีส่วนร่วมของนักเรียนในงาน",
            averageDuration: "ระยะเวลาเฉลี่ย",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "ระยะเวลาการมีส่วนร่วมของนักเรียนในงานคือผลรวม/จำนวนการมีส่วนร่วมทั้งหมด",
            accumulatedTimes: "จำนวนสะสม",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "จำนวนงานที่นักเรียนเข้าร่วม",
            averageScore: "คะแนนเฉลี่ย",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "คะแนนเฉลี่ยของบุคลากรที่เข้าร่วมในภารกิจ",
            maximumScore: "คะแนนสูงสุด",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "คะแนนสูงสุดของบุคลากรที่เกี่ยวข้องกับภารกิจ",
            averageScoreOfScript: "คะแนนเฉลี่ย",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "คะแนนทักษะการพูดของนักเรียนผลรวม/จำนวนการมีส่วนร่วมทั้งหมด",
            totalScoreOfScript: "คะแนนรวม",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "กำหนดคะแนนรวมของคำภายใต้งานนี้",
            processAverageScore: "กระบวนการเฉลี่ย",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "คะแนนกระบวนการของนักเรียนผลรวม/จำนวนการมีส่วนร่วมทั้งหมด",
            totalProcessScore: "คะแนนรวมของกระบวนการ",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "คะแนนรวมของกระบวนการที่กำหนดไว้ภายใต้งานนี้",
            averageEmotionalScore: "อารมณ์เฉลี่ย",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "คะแนนอารมณ์ของนักเรียนผลรวม/จำนวนการมีส่วนร่วมทั้งหมด",
            totalEmotionalScore: "คะแนนรวมอารมณ์",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "คะแนนรวมของการตั้งค่าอารมณ์ภายใต้ภารกิจ",
            averageScoreOfExpression: "ค่าเฉลี่ยการแสดงออก",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "นักเรียนแสดงคะแนนและผลรวม/จำนวนการมีส่วนร่วมทั้งหมด",
            totalScoreOfExpression: "คะแนนรวมการแสดงออก",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "คะแนนรวมของนิพจน์ที่ตั้งไว้ภายใต้งาน",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "เริ่มดำเนินการให้คะแนนใหม่คุณสามารถข้ามไปที่ศูนย์งานเพื่อดูความคืบหน้า",
            reRating: "ให้คะแนนอีกครั้ง",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "การให้คะแนนใหม่หมายความว่าจะจับคู่ตามกฎการให้คะแนนของผืนผ้าใบล่าสุดซึ่งจะเขียนทับผลการให้คะแนนปัจจุบัน",
            releaseTime: "เวลาเผยแพร่",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "ตรวจสอบแล้ว",
              pendingReview: "เพื่อตรวจสอบ",
              enterKeywordSearch: "ป้อนคำค้นหา",
            },
            pageRightTable: {
              index: {
                employeeName: "ชื่อพนักงาน",
                systemRating: "การให้คะแนนระบบ",
                scoringResults: "ผลคะแนน",
                duration: "ระยะเวลา",
                time: "เวลา",
                state: "สถานะ",
                whetherToModifyTheRating: "แก้ไขคะแนนหรือไม่",
                finalScore: "คะแนนสุดท้าย",
                reviewedBy: "ผู้ตรวจสอบ",
                operation: "การดำเนินงาน",
                viewScoreDetails: "ดูรายละเอียดคะแนน",
                viewStatus: "ดูสถานะ",
                whole: "ทั้งหมด",
                correctionStatus: "สถานะการแก้ไข",
                completeReview: "เสร็จสิ้นการตรวจสอบ",
                tips: "เคล็ดลับ",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "คะแนนสุดท้ายของนักเรียนขึ้นอยู่กับผลการตรวจสอบขั้นสุดท้ายในปัจจุบันโปรดยืนยัน",
              },
            },
          },
          index: { manualReview: "การตรวจสอบด้วยตนเอง" },
        },
        review: {
          index: {
            employeeName: "ชื่อพนักงาน",
            score: "คะแนน",
            scoringResults: "ผลคะแนน",
            duration: "ระยะเวลา",
            taskName: "ชื่องาน",
            numberOfAppealScripts: "จำนวนคำอุทธรณ์",
            appealTime: "เวลาอุทธรณ์",
            processStatus: "สถานะกระบวนการ",
            scoreAfterReview: "คะแนนหลังจากการตรวจสอบ",
            reviewedBy: "ผู้ตรวจสอบ",
            operation: "การดำเนินงาน",
            viewScoreStatus: "ดูคะแนน",
            whole: "ทั้งหมด",
            manualReview: "การตรวจสอบด้วยตนเอง",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "ข้อมูลการปฏิบัติงาน",
            implementationRate: "อัตราการดำเนินการ",
            numberOfExecutors: "จำนวนผู้บริหาร",
            numberOfPeopleNotExecuted: "จำนวนคนที่ไม่ได้ดำเนินการ",
            completionRate: "อัตราความสำเร็จ",
            numberOfCompletedPersonnel: "จำนวนคนที่เสร็จสมบูรณ์",
            numberOfUnfinishedPersonnel: "จำนวนคนที่ยังไม่เสร็จ",
            complianceRate: "อัตราการปฏิบัติตาม",
            numberOfQualifiedPersonnel: "จำนวนคนที่ตรงตามมาตรฐาน",
            numberOfNonCompliantIndividuals: "จำนวนคนที่ไม่เป็นไปตามมาตรฐาน",
            excellentRate: "อัตราที่ยอดเยี่ยม",
            numberOfOutstandingIndividuals: "จำนวนที่ยอดเยี่ยม",
            numberOfNonOutstandingIndividuals: "จำนวนคนที่ไม่โดดเด่น",
            examTaskData: "ข้อมูลงานสอบ",
            published: "โพสต์แล้ว",
            courses: "หลักสูตร",
            courseData: "ข้อมูลหลักสูตร",
            practiceTasks: "การปฏิบัติภารกิจ",
            examTasks: "งานสอบ",
          },
          practiceSummary: {
            practiceSummary: "สรุปแบบฝึกหัด",
            numberOfPracticeTasks: "จำนวนการปฏิบัติงาน",
            numberOfExercises: "จำนวนการออกกำลังกาย",
            accumulatedPracticeDuration: "เวลาฝึกสะสม",
            complianceRate: "อัตราการปฏิบัติตาม",
            excellentRate: "อัตราที่ยอดเยี่ยม",
            ranking: "การจัดอันดับ",
          },
          rankingList: {
            numberOfExercises: "จำนวนการออกกำลังกาย",
            exerciseDuration: "ระยะเวลาในการฝึก",
            complianceRate: "อัตราการปฏิบัติตาม",
            excellentRate: "อัตราที่ยอดเยี่ยม",
            diligenceChart: "รายการขยัน",
            excellentList: "รายการยอดเยี่ยม",
            ranking: "การจัดอันดับ",
            fullName: "ชื่อ",
            i: "ฉัน",
          },
          taskCard: {
            end: "กำหนดเวลา:",
            noDeadline: "ไม่มีวันหมดเขต",
            second: "ครั้ง",
            branch: "แบ่ง",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "ไม่มีภารกิจ",
            completed: "เสร็จเรียบร้อยแล้ว",
          },
          filter: { employeeDepartment: "แผนกพนักงาน" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "หากการส่งออกสำเร็จโปรดไปที่ศูนย์งานเพื่อดู",
              export: "ส่งออก",
            },
          },
          filterConfig: {
            dropdown: { custom: "ที่กำหนดเอง" },
            modal: {
              updateSuccessful: "อัปเดตเรียบร้อยแล้ว",
              successfullySaved: "บันทึกความสำเร็จ",
              setAsACommonReport: "ตั้งค่าเป็นรายงานทั่วไป",
              updateCurrentReport: "อัปเดตรายงานปัจจุบัน",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "คะแนนเฉลี่ย",
                  averageScoreOfScript: "คะแนนเฉลี่ย",
                  averageEmotionalScore: "คะแนนเฉลี่ยทางอารมณ์",
                  expressionAverageScore: "คะแนนเฉลี่ยของการแสดงออก",
                  processAverageScore: "คะแนนเฉลี่ยของกระบวนการ",
                  branch: "แบ่ง",
                },
              },
              comprehensiveEvaluation: { const: { data: "ข้อมูล" } },
              doneStatus: {
                index: {
                  practice: "การออกกำลังกาย",
                  examination: "การสอบ",
                  executed: "ดำเนินการแล้ว",
                  unexecuted: "ไม่ได้ดำเนินการ",
                  accumulatedExamDuration: "ระยะเวลาสอบสะสม",
                  examFrequency: "จำนวนการสอบ",
                },
              },
              filter: {
                index: {
                  practice: "การออกกำลังกาย",
                  examination: "การสอบ",
                  pleaseCompleteTheRequiredFields: "กรุณากรอกรายการที่จำเป็น",
                  staff: "พนักงาน",
                  taskType: "ประเภทงาน",
                  pleaseSelect: "โปรดเลือก",
                  taskName: "ชื่องาน",
                },
              },
            },
            index: {
              completionStatus: "เสร็จสิ้นสถานการณ์",
              comprehensiveEvaluation: "การประเมินผลที่ครอบคลุม",
              abilityGrowthTrend: "แนวโน้มการเติบโตของความสามารถ",
              errorProneAnalysis: "การวิเคราะห์จุดผิดพลาด",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "จำนวนคนที่ใช้งานอยู่",
            accumulatedPracticeDuration: "เวลาฝึกสะสม",
            averagePracticeDuration: "ระยะเวลาในการฝึกเฉลี่ย",
            people: "คน",
            numberOfPracticeTasks: "จำนวนการปฏิบัติงาน",
            numberOfExercises: "จำนวนการออกกำลังกาย",
            exerciseDuration: "ระยะเวลาในการฝึก",
            second: "ครั้ง",
            avgScore: "คะแนนเฉลี่ย",
            speechCraftAvgScore: "คะแนนเฉลี่ย",
            emotionAvgScore: "คะแนนเฉลี่ยทางอารมณ์",
            speedAvgScore: "คะแนนเฉลี่ยของการแสดงออก",
            flowAvgScore: "คะแนนเฉลี่ยของกระบวนการ",
            practiceCostTime: "ระยะเวลาสะสม",
            totalPracticeTimes: "จำนวนสะสม",
            participateCommitRate: "อัตราการปฏิบัติตามการมีส่วนร่วม",
            participateExcellentRate: "อัตราการมีส่วนร่วม",
          },
        },
        index: {
          employeeProfile: "ภาพพนักงาน",
          overview: "ภาพรวม",
          personalSignage: "ป้ายส่วนตัว",
          ongoingExercises: "การออกกำลังกายอย่างต่อเนื่อง",
          ongoingExams: "กำลังดำเนินการสอบ",
          practiceTaskProgress: "ความคืบหน้าการปฏิบัติงาน",
          examTaskProgress: "ความคืบหน้าของงานสอบ",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "โปรดเลือกคำแนวคิด",
              sessionDetectionSettings: "การตั้งค่าการตรวจสอบเซสชัน",
              successfullySaved: "บันทึกความสำเร็จ",
              abnormalSpeechSpeed: "ความเร็วในการพูดผิดปกติ",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "รองรับมาตรฐานการตรวจจับความเร็วในการพูดที่กำหนดเองและผลลัพธ์จะถูกตัดสินว่าเป็นความเร็วในการพูดปกติความเร็วในการพูดเร็วเกินไปและความเร็วในการพูดช้าเกินไปเมื่ออัตราการพูดผิดปกติ (เร็วเกินไป/ช้าเกินไป) คะแนนจะลดลงตามกฎการให้คะแนน",
              speechSpeedTooFastGreaterThanEveryMinute:
                "พูดเร็วเกินไป: มากกว่าต่อนาที",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "คำความเร็วในการพูดช้าเกินไป: น้อยกว่าทุกนาที",
              charactersIgnoringLessThan: "คำละเว้นน้อยกว่า",
              aSentenceOfWords: "ประโยคคำ",
              excessiveModalParticles: "คำพูดมากเกินไป",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "รองรับอนุภาคที่กำหนดเองและตั้งกฎสำหรับอนุภาคที่มีอนุภาคมากเกินไปเมื่อมีอนุภาคเสียงมากเกินไปคะแนนจะลดลงตามกฎการให้คะแนน",
              theNumberOfRepetitionsIs: "จำนวนการทำซ้ำคือ",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "ประการที่สองถูกตัดสินว่ามีอนุภาคเสียงมากเกินไป",
              definitionOfSensitiveViolations:
                "คำจำกัดความของการละเมิดที่ละเอียดอ่อน",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "รองรับการปรับแต่งคำศัพท์ที่ผิดกฎหมายเพื่อตรวจสอบว่ามีคำที่ผิดกฎหมายในประโยคหรือไม่เมื่อมีการละเมิดคะแนนจะลดลงตามกฎการให้คะแนน",
              violationWords: "การละเมิด:",
              emotionalDetection: "การตรวจจับอารมณ์",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "การตรวจจับอารมณ์ดำเนินการตามเทคนิคการสนทนาแบบจำลองอารมณ์ของระบบและผลลัพธ์จะถูกตัดสินว่าเป็นอารมณ์ปกติและอารมณ์เชิงลบ (รวมถึงเชิงลบโกรธและบ่น) เมื่อมีอารมณ์เชิงลบคะแนนจะลดลงตามกฎการให้คะแนน",
              preserve: "บันทึก",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "มิติการให้คะแนน",
              ratingPoints: "คะแนน",
              explain: "คำอธิบาย",
              type: "ประเภท",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "ข้อมูลแบบสุ่ม" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· คำมาตรฐานสะสม',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "คำจำนวนคำที่นักเรียนตีด้วยการอ่าน",
                  aWordspan: "คำ </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'ครั้ง </span><br/><span style = "margin-left: 8px">· พูดช้าเกินไป',
                  timesspan: "ครั้ง </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· มากเกินไป',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "คะแนนคือ" },
                  item: {
                    violationOnceReduced: "การละเมิด1ครั้ง",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "ความเร็วในการพูดผิดปกติ (รวมถึงความเร็วในการพูดเร็วเกินไปความเร็วในการพูดช้าเกินไป) ลดลง1ครั้ง",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "ลดคำก๊าซมากเกินไป1ครั้ง",
                    oneNegativeDecreaseInEmotions: "ลบอารมณ์1ครั้ง",
                    branch: "แบ่ง",
                  },
                  wrap: { name: "ชื่อ", preserve: "บันทึก" },
                },
              },
              index: {
                complete: "เนื้อหาที่สมบูรณ์",
                keyHit: "ตีประเด็นสำคัญ",
                accurateFollowupReading: "ติดตามได้อย่างถูกต้อง",
                languageNorm: "ข้อกำหนดภาษา",
                speechSpeed: "ความเร็วในการพูด",
                modalParticles: "โทนสี",
                emotionallyPositive: "อารมณ์บวก",
                scoringModel: "แบบจำลองการให้คะแนน",
                successfullyModified: "แก้ไขสำเร็จ",
                newSuccessfullyCreated: "สร้างใหม่สำเร็จ",
              },
            },
            index: {
              scoringModel: "แบบจำลองการให้คะแนน",
              newModel: "โมเดลใหม่",
              model: "โมเดล",
              type: "ประเภท",
              operation: "การดำเนินงาน",
              preview: "ดูตัวอย่าง",
              edit: "บรรณาธิการ",
            },
          },
          index: {
            scoringModel: "แบบจำลองการให้คะแนน",
            ratingDimension: "มิติการให้คะแนน",
            sessionDetectionSettings: "การตั้งค่าการตรวจสอบเซสชัน",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: { pleaseSelect: "โปรดเลือก", hit: "ตี", misses: "พลาด" },
            },
          },
          index: {
            duration: "ระยะเวลา",
            processScore: "คะแนนกระบวนการ",
            branch: "แบ่ง",
            contentCompleteness: "ความสมบูรณ์ของเนื้อหา",
            scriptScore: "คะแนนการพูด",
            keyHitRate: "อัตราการตีที่สำคัญ",
            emotionalScore: "คะแนนอารมณ์",
            negativeProportionOfEmotions: "สัดส่วนของอารมณ์เชิงลบ",
            expressionScore: "คะแนนการแสดงออก",
            theProportionOfAbnormalSpeechSpeed:
              "สัดส่วนของอัตราการพูดที่ผิดปกติ",
            excessiveProportionOfModalParticles:
              "สัดส่วนของอนุภาคเสียงที่มากเกินไป",
            learningRecordData: "เรียนรู้การบันทึกข้อมูล",
            staff: "พนักงาน",
            curriculum: "หลักสูตร",
            mainPoints: "จุด",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "ตาราง",
                        lineChart: "แผนภูมิเส้น",
                        barChart: "แผนภูมิแท่ง",
                        pieChart: "แผนภูมิวงกลม",
                      },
                      index: { switchingTypes: "ประเภทการสลับ" },
                    },
                  },
                  index: {
                    saveSuccessful: "บันทึกความสำเร็จ",
                    editChart: "แก้ไขแผนภูมิ",
                    chartName: "ชื่อแผนภูมิ",
                    deleteSuccessful: "ลบสำเร็จ",
                    refresh: "รีเฟรช",
                    export: "ส่งออก",
                    areYouSureToDeleteIt: "แน่ใจว่าจะลบ?",
                    delete: "ลบ",
                    viewFilteringCriteria: "ดูตัวกรอง",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "หากการส่งออกสำเร็จโปรดไปที่ศูนย์งานเพื่อดู",
              },
            },
            header: {
              index: {
                saveSuccessful: "บันทึกความสำเร็จ",
                deleteSuccessful: "ลบสำเร็จ",
                editReport: "แก้ไขรายงาน",
                reportName: "ชื่อรายงาน",
                refresh: "รีเฟรช",
                areYouSureToDeleteIt: "แน่ใจว่าจะลบ?",
                delete: "ลบ",
                exportToExcel: "ส่งออก Excel",
                exportSnapshot: "ส่งออกภาพรวม",
                export: "ส่งออก",
                authority: "สิทธิ์",
                permissionSettings: "การตั้งค่าสิทธิ์",
                reportVisiblePermissions: "รายงานสิทธิ์ที่มองเห็นได้",
                visibleToEveryone: "ทุกคนสามารถมองเห็นได้",
                onlyVisibleToOneself: "มองเห็นได้ด้วยตัวเองเท่านั้น",
                designatedPersonnelVisible: "บุคคลที่กำหนดสามารถมองเห็นได้",
                pleaseSelect: "โปรดเลือก",
                reportDataPermissions: "สิทธิ์ข้อมูลรายงาน",
                restrictedByUserPermissions: "จำกัดโดยสิทธิ์ของผู้ใช้",
                visiblePeopleAreTheSame: "จะเห็นได้ว่าคนเหมือนกัน",
              },
            },
            titleFooter: {
              index: { addReport: "เพิ่มรายงาน", reportName: "ชื่อรายงาน" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "หากการส่งออกสำเร็จโปรดไปที่ศูนย์งานเพื่อดู",
            dataQueryInProgress: "แบบสอบถามข้อมูล...",
            customKanban: "คัมบังที่กำหนดเอง",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "ตาราง",
                lineChart: "แผนภูมิเส้น",
                barChart: "แผนภูมิแท่ง",
                pieChart: "แผนภูมิวงกลม",
              },
              index: {
                successfullyAdded: "เพิ่มความสำเร็จ",
                viewSQL: "ดู sql",
                export: "ส่งออก",
                addToReport: "เพิ่มลงในรายงาน",
              },
            },
            refer: {
              hooks: {
                data: "ข้อมูล",
                interrogativeSentence: "คำถาม",
                historicalQuery: "สอบถามประวัติ",
              },
              index: { copySuccessful: "คัดลอกสำเร็จ", copy: "คัดลอก" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "ไม่เป็นไปตามมาตรฐาน",
                        meetingStandards: "มาตรฐาน",
                        excellent: "ยอดเยี่ยม",
                        notStartedYet: "ยังไม่เริ่ม",
                        inProgress: "กำลังดำเนินการอยู่",
                        completed: "เสร็จเรียบร้อยแล้ว",
                        incomplete: "ยังไม่เสร็จ",
                      },
                    },
                  },
                  const: {
                    taskName: "ชื่องาน",
                    department: "แผนก",
                    studentName: "ชื่อนักเรียน",
                    scoringResults: "ผลคะแนน",
                    taskCompletionStatus: "สถานะเสร็จสิ้นภารกิจ",
                  },
                  index: { pleaseSelectADimension: "โปรดเลือกมิติข้อมูล" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "ป้อน @ เพื่อเปิดกล่องป๊อปอัพบนสำหรับการเลือกมิติข้อมูล",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "โปรดป้อนคำสั่งสอบถามเพื่อสอบถามโดยตรง",
                query: "สอบถามข้อมูล",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "หากการส่งออกสำเร็จโปรดไปที่ศูนย์งานเพื่อดู",
            dataQueryInProgress: "แบบสอบถามข้อมูล...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "การค้นหาคำหลัก",
                previous: "ก่อนหน้านี้",
                next: "ต่อไป",
                theObjectThatTriggersTheScrollingEventIs:
                  "วัตถุที่เรียกเหตุการณ์เลื่อนคือ",
                userScrolling: "ผู้ใช้เลื่อน",
              },
            },
            summary: {
              index: {
                reminder: "คำเตือน:",
                edit: "บรรณาธิการ",
                name: "ชื่อ",
                hintLanguage: "คำใบ้",
              },
            },
            viewpoint: { index: { viewpointExtraction: "การสกัดมุมมอง" } },
            outline: {
              index: {
                numberOfKeywords: "จำนวนคำหลัก",
                speaker: "ลำโพง",
                speechDuration: "ระยะเวลาในการพูด",
                proportionOfSpeaking: "สัดส่วนการพูด",
                maximumSpeechDuration: "ระยะเวลาการพูดสูงสุด",
                numberOfQuestions: "จำนวนคำถาม",
                averageSpeechSpeed: "อัตราการพูดเฉลี่ย",
                conversationalActions: "การดำเนินการเซสชัน",
                contentSummary: "สรุปเนื้อหา",
                generateSummary: "สร้างบทคัดย่อ",
                keywordCloud: "คำสำคัญเมฆคำ",
              },
            },
          },
          index: {
            outline: "สรุป",
            viewpoint: "มุมมอง",
            contentSummary: "สรุปเนื้อหา",
            viewpointExtraction: "การสกัดมุมมอง",
            summaryOfKeyPoints: "สรุปประเด็นสำคัญ",
            videoAnalysis: "การวิเคราะห์วิดีโอ",
          },
        },
        list: {
          index: {
            delete: "ลบ",
            uploadSuccessful: "อัพโหลดสำเร็จ",
            videoList: "รายการวิดีโอ",
            uploadVideo: "อัปโหลดวิดีโอ",
            videoName: "ชื่อวิดีโอ",
            uploadTime: "เวลาอัปโหลด",
            endTime: "เวลาสิ้นสุด",
            analyzeProgress: "วิเคราะห์ความคืบหน้า",
            haveInHand: "กำลังดำเนินการอยู่",
            completed: "เสร็จเรียบร้อยแล้ว",
            fail: "ล้มเหลว",
            toBegin: "รอการเริ่มต้น",
            operation: "การดำเนินงาน",
            see: "ดู",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "เพิ่มชั่วโมงการทำงาน" },
        },
      },
      index: {
        businessWorkingHours: "ชั่วโมงการทำงานทางธุรกิจ",
        newlyBuild: "สร้างใหม่",
        name: "ชื่อ",
        describe: "คำอธิบาย",
        operation: "การดำเนินงาน",
        edit: "บรรณาธิการ",
        delete: "ลบ",
        editBusinessWorkingHours: "แก้ไขชั่วโมงการทำงานของธุรกิจ",
        newBusinessWorkingHours: "ชั่วโมงการทำงานของธุรกิจใหม่",
        workingHours: "เวลาทำงาน",
      },
    },
    algorithmSemanticIntelligence: { intelligentExtraction: "การสกัดอัจฉริยะ" },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "คุณยืนยันว่าต้องการยกเลิกหรือไม่? จะไม่สามารถกู้คืนได้หลังจากการยกเลิก",
        },
      },
    },
    accountManagement: {
      securitySetting: "การตั้งค่าความปลอดภัย",
      accountManagement: "การจัดการบัญชี",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "ถึง",
            switchToIPAddressMode: "สลับไปยังโหมดที่อยู่ IP",
            switchToIPSegmentMode: "สลับไปยังโหมดส่วน IP",
          },
        },
      },
      index: {
        saveSuccessful: "บันทึกความสำเร็จ",
        pleaseImproveTheConfiguration: "โปรดปรับปรุงการกำหนดค่า",
        securitySetting: "การตั้งค่าความปลอดภัย",
        employeeLoginIPRestrictions: "ข้อจำกัด IP เข้าสู่ระบบของพนักงาน",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "จำกัดช่วง IP ที่พนักงานสามารถเข้าสู่ระบบได้และไม่อนุญาตให้เข้าสู่ระบบเกินช่วงมีผลเฉพาะกับเว็บไซต์เท่านั้นแอพมือถือไม่จำกัดผู้ดูแลระบบไม่จำกัด",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "เมื่อเพิ่มหรือแก้ไขโปรดกรอกส่วน IP ภายนอก (ส่วน C เดียวกัน) หรือที่อยู่ IP ภายนอกคงที่",
        allowLoginIPRange: "อนุญาตให้เข้าสู่ระบบ IP ช่วง",
        preserve: "บันทึก",
      },
    },
    coachAdminEntry: { speechRecognition: "การรู้จำเสียง" },
    billingCenter: {
      template: {
        details: "รายละเอียด",
        languageType: "ประเภทภาษา",
        aSREngine: "เครื่องยนต์ ASR",
        voiceQualityQuota: "โควต้าการตรวจสอบคุณภาพเสียง",
        realtimeData: "ข้อมูลเรียลไทม์",
        calculatedByDay: "คำนวณตามวัน",
      },
      component: {
        aSRName: "ชื่อ ASR",
        language: "ภาษา",
        remainingQuota: "โควต้าที่เหลือ",
        hours: "ชั่วโมง",
        usedQuota: "ใช้โควต้า",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "การดำเนินการบันทึกสำเร็จ!",
        deletedSuccessfully: "ลบสำเร็จ!",
        pleaseSelectLanguage: "โปรดเลือกภาษา",
        pleaseConfigureRules: "กรุณากำหนดค่ากฎ",
        updateSuccessful: "การอัปเดตสำเร็จ!",
        operationSuccessful: "การดำเนินการประสบความสำเร็จ!",
      },
      template: {
        aSRLanguage: "ภาษา ASR",
        pleaseSelect: "โปรดเลือก",
        model: "โมเดล",
        pleaseSelectLanguage: "โปรดเลือกภาษา",
        delete: "ลบ",
        newASRModel: "โมเดล ASR ใหม่",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "การวิเคราะห์ผลิตภัณฑ์ที่แข่งขันได้" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "ตัวกรองรีวิวอีคอมเมิร์ซ",
        allComments: "ความคิดเห็นทั้งหมด",
        ecommerceReviewManagement: "การจัดการรีวิวอีคอมเมิร์ซ",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "ตัวกรองความคิดเห็นโซเชียลมีเดีย",
        allComments: "ความคิดเห็นทั้งหมด",
        socialMediaReviewManagement: "การจัดการความคิดเห็นของสื่อโซเชียล",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "การวิเคราะห์ผลิตภัณฑ์ที่แข่งขันได้",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "อวตาร",
        realname: "ชื่อ",
        nickname: "ชื่อเล่น",
        name: "ชื่อ",
        email: "บัญชี (อีเมล)",
        mobile: "โทรศัพท์",
        agentId: "หมายเลขงาน",
        position: "โพสต์",
        role: "บทบาท",
        actions: "การดำเนินงาน",
        createUser: "เพิ่มพนักงานใหม่",
        editUser: "แก้ไขพนักงาน",
        account: "บัญชี",
        originPassword: "รหัสผ่านเริ่มต้น",
        newPassword: "รหัสผ่านใหม่",
        resumePassword: "ยืนยันรหัสผ่าน",
        userGroup: "กลุ่มพนักงาน",
        authorizationGroup: "กลุ่มสิทธิ์",
        resetPassword: "รีเซ็ตรหัสผ่าน",
      },
      userGroups: {
        id: "ID",
        name: "ชื่อ",
        userCount: "จำนวนพนักงาน",
        actions: "การดำเนินงาน",
        createUserGroup: "เพิ่มกลุ่มพนักงาน",
        editUserGroup: "แก้ไขกลุ่มพนักงาน",
        member: "สมาชิก",
      },
      role: {
        id: "ID",
        name: "ชื่อ",
        actions: "การดำเนินงาน",
        all: "ทั้งหมด",
        taskName: "ภารกิจ",
        readTask: "ดูภารกิจ",
        manageTemplate: "การจัดการแม่แบบ",
        manualInspection: "การตรวจสอบซ้ำด้วยตนเอง",
        readResultAll: "ดูผลลัพธ์",
        readResultPersonal: "มุมมองผลลัพธ์ (ส่วนบุคคล)",
        readProcess: "มุมมองกระบวนการตรวจสอบคุณภาพ",
        readReport: "สถิติและรายงาน",
      },
    },
    download: {
      downloadCenter: {
        createTime: "วันที่",
        taskName: "ชื่อ",
        size: "ขนาด",
        status: "สถานะ",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "ชื่อ",
            remark: "คำอธิบาย",
            actions: "การดำเนินงาน",
          },
          checkPoint: {
            name: "ชื่อ",
            remark: "คำอธิบาย",
            type: "ประเภท",
            gradeType: "วิธีการประเมิน",
            conditionLogic: "กฎ",
            predeterminedScore: "คะแนน",
            weight: "น้ำหนัก",
            status: "สถานะ",
            actions: "การดำเนินงาน",
            switchOn: "เปิดใช้งาน",
            switchOff: "ปิดใช้งาน",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "เวลา",
            customer: "ลูกค้า",
            customerPhone: "โทรศัพท์ของลูกค้า",
            callType: "ประเภทการโทร",
            source: "แหล่งที่มา",
            voiceRecording: "บันทึกการโทร",
            graded: "การให้คะแนน",
            openingGreeting: "เปิดทักทาย",
          },
          checkPointList: {
            name: "จุดตรวจสอบคุณภาพ",
            type: "ประเภท",
            deductScore: "คะแนน",
            isHit: "สถานการณ์ตี",
            status: "รีวิว",
            finalDeductScore: "ยืนยันการหักเงิน",
            hit: "ตี",
            notHit: "พลาด",
          },
        },
      },
    },
    custom: { keywords: "คำสนทนา" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "การตั้งค่าระบบ" },
      systemIntegration: {
        text: "การรวมระบบ",
        subTitle: {
          udesk: "ระบบบริการลูกค้า Udesk",
          udeskWhite: "ระบบบริการลูกค้า",
          wechat: "องค์กร WeChat",
          taobao: "Taobao Qianniu",
          weifeng: "เว่ยเฟิง",
        },
      },
      columnsManage: { text: "การจัดการฟิลด์การตรวจสอบคุณภาพ" },
      customerField: { text: "ฟิลด์ลูกค้า" },
      speechRecognition: { text: "การรู้จำเสียง" },
      user: { text: "การจัดการพนักงาน" },
      userGroups: { text: "การจัดการกลุ่มพนักงาน" },
      role: { text: "การจัดการบทบาท" },
      dataMigration: { text: "การโยกย้ายข้อมูล" },
      taskCenter: { text: "ศูนย์ภารกิจ" },
      recordUpload: { text: "อัปโหลดการบันทึกในท้องถิ่น" },
      anomalousData: { text: "ข้อมูลที่ผิดปกติ" },
      systemLog: { text: "บันทึกข้อความ" },
      msgCenter: { text: "ศูนย์ข้อความ" },
      wechatDocking: { text: "การเชื่อมต่อองค์กรขนาดเล็ก" },
    },
    tasks: {
      homePage: { text: "หน้าแรก" },
      workbench: { text: "โต๊ะทำงาน" },
      semanticIntelligence: { text: "ความฉลาดเชิงความหมาย" },
      intelligentAnalysis: { text: "การวิเคราะห์อัจฉริยะ" },
      intelligentLabel: { text: "สมาร์ทแท็ก" },
      admin: { text: "การจัดการ" },
      callManage: { text: "การจัดการการโทร" },
      dialogueManage: { text: "การจัดการการสนทนา" },
      ticketManage: { text: "การจัดการใบสั่งงาน" },
      dataAnalysis: { text: "การวิเคราะห์ข้อมูล" },
      customerService: { text: "ศูนย์บริการลูกค้า" },
      clientCenter: { text: "ศูนย์ลูกค้า" },
      insight: { text: "Insight" },
      trainingCenter: { text: "ศูนย์ฝึกอบรม" },
      templates: { text: "การจัดการแม่แบบ" },
      wechatQaTasks: { text: "โต๊ะทำงาน WeChat ขององค์กร" },
      wechatQaSetting: { text: "การกำหนดค่าการวิเคราะห์ WeChat ขององค์กร" },
      wechatQaChartRecord: { text: "เซสชัน WeChat ขององค์กร" },
      wechatStaffCenter: { text: "ศูนย์พนักงานระดับองค์กร" },
      callAnalysis: { text: "การวิเคราะห์เซสชัน" },
      caseBaseFolder: { text: "ห้องสมุดกรณี" },
      coachTask: { text: "งานให้คำปรึกษา" },
      teamAnalysis: { text: "การวิเคราะห์ทีม" },
      customer: { text: "ศูนย์ลูกค้า" },
      analysisEnable: { text: "การเพิ่มขีดความสามารถในการวิเคราะห์" },
      analysisHelp: { text: "ช่วยวิเคราะห์" },
      analysisStudy: { text: "การวิเคราะห์การเรียนรู้" },
      analysisReview: { text: "การวิเคราะห์การตรวจสอบ" },
      analysisTags: { text: "การวิเคราะห์ฉลากอัจฉริยะ" },
      analysisSentiment: { text: "การวิเคราะห์ความคิดเห็นของประชาชน" },
      analysisBusiness: { text: "การวิเคราะห์ธุรกิจ" },
      groupTest: { text: "การทดสอบกลุ่ม" },
    },
    navBar: {
      billingCenter: "ศูนย์ช่วยเหลือ",
      uploadDownload: "ศูนย์อัปโหลดและดาวน์โหลด",
      setting: "การตั้งค่าระบบ",
    },
  },
  fix: {
    selectType: "เลือกประเภท:",
    all: "ทั้งหมด",
    call: "โทร",
    text: "การสนทนา",
    wechat: "เซสชัน WeChat ขององค์กร",
    businessTrendAnalysis: "การวิเคราะห์แนวโน้มธุรกิจการจำแนกประเภทระดับแรก",
    noEchart: "กราฟไม่มีข้อมูล",
    checkPointisRequired: "การกำหนดค่ากฎเป็นรายการที่จำเป็น",
    voiceDownLoad: "ดาวน์โหลดการบันทึก",
    exportTxt: "ส่งออกข้อความ TXT",
    exportWord: "ส่งออกข้อความ word",
    sureReRole: "ยืนยันบทบาทของผู้พูดหรือไม่?",
    sureRecover: "ยืนยันการแก้ไขอีกครั้งหรือไม่?",
    sureReAsr: "ยืนยันการระบุตัวตนใหม่หรือไม่?",
    reRole: "กลับลำโพง",
    recover: "แก้ไขข้อผิดพลาดใหม่",
    reAsr: "การระบุตัวตนใหม่",
    recordingOperation: "การดำเนินการบันทึก",
    onlySave30Day: "(เก็บไว้เพียง30วัน)",
    appealError: "กรุณาร้องเรียนที่จุดตรวจสอบคุณภาพก่อนส่ง",
    exportToTaskCenter: "ส่งออกไปยังศูนย์อัปโหลดและดาวน์โหลดเรียบร้อยแล้ว",
    caseBaseLimit: "ไลบรารีเคสต้องไม่เกิน50",
    unnamed: "ไม่มีชื่อ",
    wordSpeed: "คำ/นาที",
    hit: "ตี",
    checkPoint: "จุดตรวจสอบคุณภาพ",
    realTimeAdditionTip1:
      "เพิ่มตามเวลาจริง: เริ่มการตรวจสอบคุณภาพทันทีเมื่อข้อมูลเข้าสู่ระบบการตรวจสอบคุณภาพ",
    realTimeAdditionTip2:
      "เพิ่มเวลา: เพิ่มข้อมูลโดยอัตโนมัติสำหรับการตรวจสอบคุณภาพ",
    null: "ไม่มี",
    collcetedProps: "ฟิลด์การรวบรวม",
    editingNameNow: "มีการแก้ไขชื่อ",
    upToFourLevels: "จำแนกได้ถึงสี่ระดับ",
    default: "เริ่มต้น",
    nameCantBeNull: "ชื่อต้องไม่ว่างเปล่า",
    editingNow: "มีการดำเนินการแก้ไข",
    nameError: "ชื่อไม่ถูกต้อง",
    nameMaxLength8: "ความยาวสูงสุดของชื่อคือ8",
    callTime: "เวลาพูดคุย",
    sentimentValue: "ค่าความเชื่อมั่นของลูกค้า",
    agentSentimentValue: "ค่าอารมณ์การบริการลูกค้า",
    sentimentAnalysis: "การวิเคราะห์อารมณ์",
    associatedData: "ข้อมูลถนน",
    notifyTheOwner: "แจ้งเจ้าของกลุ่ม",
    notifyTheEmployee: "แจ้งพนักงานด้วยตนเอง",
    notifyTheOwnerTip: "เมื่อประเภทเซสชันเป็นแชทกลุ่มเจ้าของกลุ่มจะได้รับแจ้ง",
    customNotifyEmployee: "พนักงานแจ้งเตือนที่กำหนดเอง",
    customNotifyEmployeeTip:
      "ทริกเกอร์หากพนักงานเป็นพนักงานข้อความจะได้รับแจ้งด้วยตนเอง",
    clickToLearnMore: "คลิกเพื่อทราบข้อมูลเพิ่มเติม",
    repeatMarkingTip:
      "บันทึกนี้ถูกทำเครื่องหมายโดยจุดตรวจสอบคุณภาพไม่จำเป็นต้องทำเครื่องหมายซ้ำ",
    successfulEvaluation: "การประเมินผลสำเร็จ",
    evaluationFailed: "การประเมินล้มเหลว",
    qualityInspectionScore: "คะแนนการตรวจสอบคุณภาพ",
    semanticTags: "แท็กความหมาย",
    smartTags: "สมาร์ทแท็ก",
    collect: "รวบรวมข้อมูล",
    appealRecord: "บันทึกการอุทธรณ์",
    satisfy: "พอใจ",
    notSatisfy: "ไม่พอใจ",
    appealPlaceHolder: "โปรดป้อนเหตุผลในการอุทธรณ์",
    giveUp: "ยอมแพ้",
    rejectReasonPlaceHolder: "โปรดป้อนเหตุผลในการปฏิเสธ",
    reject: "ปฏิเสธ",
    changeRejectReason: "เหตุผลในการแก้ไขและปฏิเสธ",
    pass: "ผ่าน",
    revise: "แก้ไข",
    machineEvaluation: "การประเมินเครื่อง",
    poor: "แย่",
    excellent: "โอเค",
    moderate: "ทั่วไป",
    mustMarked: "ต้องทำเครื่องหมายจุดตรวจสอบคุณภาพ",
    pleaseChoose: "โปรดเลือก",
    manualEvaluation: "การประเมินด้วยตนเอง",
    qualityInspectionTemplate: "เทมเพลตการตรวจสอบคุณภาพที่ใช้งานได้",
    collectionTemplate: "เทมเพลตการรวบรวมที่ใช้งานได้",
    keywordsWordCloud: "คำสำคัญเมฆคำ",
    editCategoryLabel: "แก้ไขแท็กหมวดหมู่",
    justCall: "เพียงแค่ดูการโทร",
    justConversation: "เพียงแค่ดูการสนทนา",
    batchOperation: "การดำเนินการแบทช์",
    customerServicesGroup: "กลุ่มบริการลูกค้า",
    joinGroup: "เข้าร่วมกลุ่ม",
    removeGroup: "ย้ายออกจากกลุ่ม",
    enterInformationEntity: "โปรดป้อนเนื้อหาเอนทิตีข้อมูล",
    enterNotBeNull: "อินพุตต้องไม่ว่างเปล่า",
    operationGroup: "กลุ่มปฏิบัติการ",
    customerServiceSelected: "เลือกบริการลูกค้า",
    callAndConversation: "พูดคุย/สนทนา",
    conversation: "การสนทนา",
    system: "ระบบ",
    customize: "ที่กำหนดเอง",
    effective: "มีประสิทธิภาพ",
    invalid: "ไม่ถูกต้อง",
    successfulAndToTaskCenter: "หากการดำเนินการสำเร็จโปรดไปที่ศูนย์งานเพื่อดู",
    recalculationFailed: "การคำนวณใหม่ล้มเหลว",
    selectRecalculatedIndicator: "โปรดเลือกตัวบ่งชี้ที่จะคำนวณใหม่",
    source: "โปรดเลือกแหล่งข้อมูล",
    placeholder: "กรุณาใส่คำสำคัญ",
    cumulative: "ประเภทสะสม",
    average: "ประเภทเฉลี่ย",
    distribution: "กฎหมายการกระจาย",
    originalDataCannotEmpty: "ข้อมูลดิบต้องไม่ว่างเปล่า",
    inspection: "การตรวจสอบคุณภาพ",
    check: "การสุ่มตัวอย่าง",
    review: "รีวิว",
    draftSavedSuccessfully: "บันทึกร่างสำเร็จ",
    optimization: "การเพิ่มประสิทธิภาพ",
    agentName: "ชื่อบริการลูกค้า",
    allTasks: "งานตรวจสอบคุณภาพทั้งหมด",
    datePickerSelect: "เลือกวันที่",
    callSelect: "เลือกการสนทนา",
    taskSelect: "เลือกงาน",
    genReport: "สร้างรายงาน",
    dataSource: "แหล่งข้อมูล",
    callsTotal: "จำนวนการโทรทั้งหมด",
    intelligentQualityInspection: "การตรวจสอบคุณภาพอัจฉริยะ",
    percentage: "คิดเป็นสัดส่วน",
    artificialSampling: "การสุ่มตัวอย่างด้วยตนเอง",
    appealNumber: "จำนวนการร้องเรียน",
    totalConversation: "จำนวนบทสนทนาทั้งหมด",
    samplingReviewRate: "อัตราการตรวจสอบซ้ำ",
    data: "ข้อมูล",
    inspectionResults: "ผลการตรวจสอบคุณภาพ",
    inspected: "ตรวจสอบคุณภาพ",
    totalScore: "คะแนนรวม",
    learnMore: "ดูเพิ่มเติม",
    inspectionEffect: "ผลการตรวจสอบคุณภาพ",
    allTotal: "รวม",
    manualInspection: "การตรวจสอบซ้ำด้วยตนเอง",
    artificialReview: "การตรวจสอบด้วยตนเอง",
    approvalAmountAndRate: "จำนวนการอนุมัติและอัตราการอนุมัติ",
    customerScore: "คะแนนการบริการลูกค้า",
    inspectionRecommendations: "คำแนะนำการตรวจสอบคุณภาพ",
    saveDraft: "บันทึกร่าง",
    historicalAdvice: "คำแนะนำทางประวัติศาสตร์",
    advicePlaceHolder: "กรุณากรอกคำแนะนำ...",
    upload: "อัปโหลดไฟล์",
    customAdd: "เพิ่มการกรอง",
    advancedConditions: "เงื่อนไขขั้นสูง",
    keyWord: "คำสำคัญ",
    containsAll: "รวมทั้งหมด",
    containsAny: "ประกอบด้วยใดๆ",
    matchAny: "จับคู่ใดก็ได้",
    matchAll: "จับคู่ทั้งหมด",
    smartTagsOne: "สมาร์ทแท็กหนึ่ง",
    smartTagsTwo: "สมาร์ทแท็กสอง",
    season: "ฤดูกาล",
    month: "เดือน",
    week: "สัปดาห์",
    day: "วัน",
    hour: "เมื่อ",
    notMoreThan366: "เวลาต้องไม่เกิน366วัน",
    propsCannotBeNull: "ไม่ได้กรอกฟิลด์",
    other: "อื่นๆ",
    correlationCoefficient: "ค่าสัมประสิทธิ์สหสัมพันธ์",
    repetitionRate: "อัตราการทำซ้ำ",
    configurationItemsMoreThan20: "เพิ่มรายการกำหนดค่าได้ถึง20รายการ",
    conversionAnalysis: "การวิเคราะห์การแปลง",
    correlationAnalysis: "การวิเคราะห์สหสัมพันธ์",
    successfulExportAndToTaskCenter:
      "หากการส่งออกสำเร็จโปรดไปที่ศูนย์งานเพื่อดู",
    typeSelect: "เลือกประเภท",
    exportExcel: "ส่งออก excel",
    exportPng: "ส่งออก png",
    noDataChart: "กราฟไม่มีข้อมูล",
    placeOperator: "โปรดเลือกสมาร์ทแท็บ",
    placeAdvancedConditions: "โปรดเลือกเงื่อนไขขั้นสูง",
    createAdvancedConditions: "สร้างการคัดกรองขั้นสูงใหม่",
    moveUp: "เลื่อนขึ้น",
    moveDown: "เลื่อนลง",
    moveLeft: "เลื่อนไปทางซ้าย",
    moveRight: "เลื่อนไปทางขวา",
    statusChangeSuccess: "การปรับเปลี่ยนสถานะสำเร็จ",
    editSuccess: "แก้ไขสำเร็จ",
    saveSuccess: "บันทึกความสำเร็จ",
    firstPage: "หน้าแรก",
    lastPage: "หน้าสุดท้าย",
    customerInfo: "ข้อมูลลูกค้า",
    type: "ประเภท",
    NumberTypeErrorMessage:
      'ข้อมูลฟิลด์ประเภทตัวเลขเนื้อหาสามารถมีตัวเลขหรือ "." เท่านั้น',
    taskStartPrompt: "เคล็ดลับการเปิดใช้งาน",
    startConfirm: "โปรดคลิกปุ่มยืนยันเพื่อเริ่มงานตรวจสอบคุณภาพ",
    continueToSubmitWithoutEvaluation: "ไม่มีการประเมินและส่งต่อไป",
    rejectTip:
      "ไม่พบการดำเนินการเปลี่ยนแปลงคุณยืนยันที่จะปฏิเสธการอุทธรณ์ทั้งหมดโดยตรงหรือไม่?",
    unmarkedSelfLearningTip:
      "คุณมีจุดตรวจสอบคุณภาพการเรียนรู้ด้วยตนเองที่ไม่มีเครื่องหมาย",
    markedSuccessfully: "ติดฉลากเรียบร้อยแล้ว",
    markingFailed: "การติดฉลากล้มเหลว",
    exceedingLength: "เกินความยาว",
    uncategorized: "ไม่มีหมวดหมู่",
    addSuccess: "เพิ่มความสำเร็จ",
    deleteSuccess: "ลบสำเร็จ",
    featureName: "ชื่อองค์ประกอบ",
    informationEntity: "เอนทิตีข้อมูล",
    standardAnswer: "คำตอบมาตรฐาน",
    keyElementAnswer: "องค์ประกอบสำคัญตอบ",
    generalStatement: "ข้อความทั่วไป (ไม่ต้องสอบถาม)",
    generalQuestionsAndConfirmation: "คำถามทั่วไปและการยืนยัน",
    specialQuestionsAndAnswers: "คำถามและคำตอบพิเศษ",
    agent: "บริการลูกค้า",
    customer: "ลูกค้า",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "องค์ประกอบหลักแท็กความหมายและเอนทิตีข้อมูลไม่สามารถว่างเปล่าในเวลาเดียวกันได้",
    standardSentence: "ประโยคมาตรฐาน",
    acquaintance: "ความใกล้ชิด",
    smartUePlaceholder: "โปรดป้อนชื่อการโต้ตอบอัจฉริยะ",
    typeSelectPlaceholder: "โปรดเลือกประเภท",
    keyElement: "องค์ประกอบที่สำคัญ",
    reQuerySementicTags: "เลือกแท็บความหมายอีกครั้ง",
    querySementicTags: "เลือกแท็บความหมาย",
    QATestForm: "รูปแบบการตรวจจับคำถามและคำตอบ",
    selectNotBeNull: "ทางเลือกไม่สามารถว่างเปล่า",
    featureDetectionTarget: "เป้าหมายการตรวจสอบองค์ประกอบ",
    categorized: "หมวดหมู่",
    effectiveness: "ประสิทธิผล",
    hideAdvancedSettings: "ซ่อนการตั้งค่าขั้นสูง",
    showAdvancedSettings: "แสดงการตั้งค่าขั้นสูง",
    generalStatementOnly: "ข้อความทั่วไป",
    generalQuestions: "คำถามทั่วไป",
    affirmativeExpression: "การแสดงออกที่แน่นอน",
    negativeIntention: "เจตนาปฏิเสธ",
    searchSementicTags: "ค้นหาแท็กความหมาย",
    choiceQuestion: "ประโยคคำถามแบบเลือก",
    selectiveAnswer: "คำตอบที่เลือก",
    sementicTagsPlaceholder: "โปรดป้อนเนื้อหาของแท็กความหมาย",
    openSpecialQuestion: "เปิดคำถามพิเศษ",
    openAnswer: "เปิดคำตอบ",
    customQA: "แบบทดสอบที่กำหนดเอง",
    questions: "ถามกฎหมาย",
    dataPlaceholder: "กรุณาใส่เนื้อหา",
    reply: "ตอบ",
    notFindSuitableLabel: "ไม่พบฉลากที่เหมาะสม",
    addSemanticTag: "เพิ่มแท็บความหมายใหม่",
    intelligentInteraction: "การโต้ตอบอัจฉริยะ",
    matching: "การจับคู่",
    bout: "ครั้ง",
    keywordMatch: "การจับคู่คำหลัก",
    addFilterConditions: "เพิ่มเกณฑ์การกรอง",
    buttonStartCollectionTask: "โปรดคลิกปุ่มตกลงเพื่อเริ่มงานการรวบรวม",
    buttonStartTask: "โปรดคลิกปุ่มตกลงเพื่อเริ่มงานตรวจสอบคุณภาพ",
    ruleConfigurationIsRequired: "การกำหนดค่ากฎเป็นรายการที่จำเป็น",
    asrRunning: "การดำเนินการ ASR",
    errorCorrectionExecution: "การดำเนินการแก้ไขข้อผิดพลาด",
    emotionalVolumeRecognition: "การรับรู้ปริมาณอารมณ์",
    dataPreprocessing: "การประมวลผลข้อมูลล่วงหน้า",
    inspectionExecution: "การดำเนินการตรวจสอบคุณภาพ",
    businessId: "ธุรกิจ Id",
    businessTime: "เวลาธุรกิจ",
    createTime: "สร้างเวลา",
    abnormalReason: "สาเหตุของความผิดปกติ",
    singleChat: "แชทเดี่ยว",
    groupChat: "สนทนากลุ่ม",
    taskContent: "เนื้อหางาน",
    submissionTime: "เวลาส่ง",
    startTime: "เวลาเริ่มต้น",
    endTime: "เวลาสิ้นสุด",
    runningTime: "ใช้เวลาในการวิ่ง",
    totalAmountOfData: "ปริมาณข้อมูลทั้งหมด",
    resultsOfThe: "ผลการดำเนินการ",
    operator: "ผู้ประกอบการ",
    taskDetails: "รายละเอียดภารกิจ",
    executiveFunction: "ฟังก์ชั่นการดำเนินการ",
    qualityCheckTasks: "งานตรวจสอบคุณภาพ",
    implementationDetails: "รายละเอียดการดำเนินการ",
    conditionalRules: "กฎเงื่อนไข",
    smartCondition: "เงื่อนไขอัจฉริยะ",
    goThis: "ย้ายไปที่นี่",
    pleaseMarkClassification: "กรุณาทำเครื่องหมายหมวดหมู่",
    advancedScreening: "การคัดกรองขั้นสูง",
    noDataToManipulate: "ไม่มีข้อมูลที่สามารถดำเนินการได้",
    concept: "คำแนวคิด",
    checkPoint1: "การกำหนดค่ากฎ",
    requiredFieldsCannotEmpty: "รายการที่ต้องการต้องไม่ว่างเปล่า",
    rule: "กฎ",
    afterFixUnit: "คำ",
    unmarkedSelfLearningPointsTip:
      "คุณมีจุดตรวจสอบคุณภาพการเรียนรู้ด้วยตนเองที่ไม่มีเครื่องหมาย",
    onlyReservedFor7Days: "(เก็บไว้เพียง7วัน)",
    downloadError: "การดาวน์โหลดล้มเหลว",
    pause: "หยุดชั่วคราว",
    continue: "ดำเนินการต่อ",
    recalcScore: "คำนวณคะแนนใหม่เท่านั้น (รวมคะแนน)",
    recalculatePointsAndScores: "คำนวณจุดตรวจสอบคุณภาพและคะแนนใหม่",
    recalculatePointsAndScoresWithEmtry:
      "คำนวณจุดตรวจสอบคุณภาพและคะแนนใหม่ (จุดตรวจสอบคุณภาพว่างเปล่า)",
    inProgress: "กำลังดำเนินการอยู่",
    toBeExecuted: "ที่จะดำเนินการ",
    executeImmediately: "ดำเนินการทันที",
    specialElements: "องค์ประกอบพิเศษ",
    logicRule: "กฎตรรกะ",
    every: "การจับคู่หลายครั้ง",
    hitRecall: "ตีเรียกคืนคำ",
    notHitRecall: "จำไม่ได้",
    hitRecallRegular: "ตีเรียกคืนปกติ",
    notHitRecallRegular: "พลาดปกติ",
    hitRule: "กฎ Hit",
    notHitRule: "กฎพลาด",
    hitSimilarSentence: "ตีประโยคที่คล้ายกัน",
    notHitSimilarSentence: "ประโยคที่คล้ายกันพลาด",
    hitExcludeSimilarSentences: "ตีไม่รวมประโยคที่คล้ายกัน",
    notHitExcludeSimilarSentences: "ไม่รวมประโยคที่คล้ายกัน",
    updateSuccess: "อัปเดตเรียบร้อยแล้ว",
    qualify: "ปกติ",
    recallKeyWord: "คีย์เวิร์ดเรียกคืน",
    grammar: "กฎไวยากรณ์",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "การเปลี่ยนแปลงความถูกต้องล้มเหลวโปรดลองอีกครั้ง",
    postAutoMateTasksCaseByTaskId:
      "การบันทึกการกำหนดค่าการซิงโครไนซ์ไลบรารีกรณีล้มเหลว",
    getAutoMateTasksCaseByTaskId:
      "ล้มเหลวในการรับการกำหนดค่าการซิงโครไนซ์ไลบรารีกรณี",
    postInspectionRemarkModelConfigs: "บันทึกเทมเพลตหมายเหตุล้มเหลว",
    putInspectionRemarkModelConfigsById: "บันทึกเทมเพลตหมายเหตุล้มเหลว",
    getInspectionRemarkModelConfigsById: "ล้มเหลวในการรับข้อมูลเทมเพลตหมายเหตุ",
    postKnowledgeDatas: "การสร้างฐานความรู้ใหม่ล้มเหลวโปรดลองอีกครั้ง",
    putKnowledgeDatasById: "การแก้ไขฐานความรู้ล้มเหลวโปรดลองอีกครั้ง",
    getLibraryFiles: "ล้มเหลวในการรับรายการโฟลเดอร์ราคา",
    getLibraryCalls: "การรับไฟล์ล้มเหลว",
    getLibraryFilesSearch: "การค้นหาล้มเหลว",
    putLibraryFilesMoveById: "การย้ายโฟลเดอร์ราคาล้มเหลว",
    putLibraryCallsById: "การเปลี่ยนชื่อไฟล์ล้มเหลว!",
    postLibraryFiles: "ล้มเหลวในการเพิ่มโฟลเดอร์",
    putLibraryFilesById: "การเปลี่ยนชื่อโฟลเดอร์ล้มเหลว",
    deleteLibraryFilesById: "ลบล้มเหลว",
    deleteLibraryCallsById: "ลบล้มเหลว",
    postLibraryCalls: "เพิ่มความล้มเหลว",
    getVoiceUploadResultById: "การอัปโหลดผลการส่งออกล้มเหลว",
  },
};
