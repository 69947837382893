/* eslint-disable no-unused-vars */
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';

const FAILURE_REASON = {
    repeat: Locales['current'].components.pages.recordUpload.repeat,
    overCount: Locales['current'].components.pages.recordUpload.overCount,
    overSize: Locales['current'].components.pages.recordUpload.overSize,
    uploadFail: Locales['current'].components.pages.recordUpload.uoloadFail,
    formatError: Locales['current'].components.pages.recordUpload.formatError,
};

const MAX_FILE_SIZE = 500 * 1000 * 1024;

enum Status {
    beforeUpload = 'beforeUpload',
    uploading = 'uploading',
    success = 'success',
    failure = 'failure',
}

class RecordFile {
    static FAILURE_REASON = FAILURE_REASON;
    static MAX_FILE_SIZE = MAX_FILE_SIZE;
    private file: any = null;
    name = '';
    size = 0;
    status = Status.beforeUpload;
    key = '';
    checked = true;
    failureReason = '';
    id = '';
    private _ignore = false;

    constructor(file) {
        this.file = file;
        this.name = file.name;
        this.size = file.size;
        this.id = file.uid;
        if (isFileTypeError(file)) {
            this.changeStatusToFailure(FAILURE_REASON.formatError);
        }
        if (isFileSizeError(file)) {
            this.changeStatusToFailure(FAILURE_REASON.overSize);
        }
    }

    get content(){
        return this.name;
    }

    ignoreFailure(){
        this._ignore = true;
    }
    get ignore(){
        return this._ignore;
    }
    getFile() {
        return this.file;
    }

    startUpload(ossToken, cb) {
        if (this.failureReason || !this.file) {
            cb(this);
            return;
        }
        Udesk.app.fileStorage.upload(this.file, {
            token: ossToken,
            successCallback: (file, uploadData) => {
                //file就是文件，uploadData是包含云端返回结果的所有信息
                // ossToken = null;
                this.key = uploadData.key;
                this.status = Status.success;
                this.checked = true;
                this.minify();
                cb(this);
            },
            errorCallback: (file, uploadData) => {
                this.status = Status.failure;
                this.failureReason = FAILURE_REASON.uploadFail;
                this.checked = false;
                this.minify();
                cb(this);
            },
        });
    }

    minify() {
        if (this.file !== null) {
            this.file = null;
        }
    }

    delete() {
        this.checked = false;
    }

    changeStatusToUploading() {
        this.status = Status.uploading;
    }
    changeStatusToSuccess() {
        this.status = Status.success;
    }
    changeStatusToFailure(failureReason) {
        this.status = Status.failure;
        this.failureReason = failureReason;
    }
}

export { RecordFile, Status };

function isFileTypeError(file) {
    return !['audio/wav', 'audio/mpeg'].includes(file.type);
}

function isFileSizeError(file) {
    return file.size > MAX_FILE_SIZE;
}
