// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerNodePptConfigQuery,
    BaseResponseIntelligentPartnerDialogNodePptConfigFoundResponse,
    PostReviewIntelligentPartnerNodePptConfigBody,
} from '../../../../types';

/**
 * findByNodeId
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [GET] /review/intelligentPartner/node/pptConfig
 */
export function getReviewIntelligentPartnerNodePptConfig(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodePptConfigQuery> & {
        params: GetReviewIntelligentPartnerNodePptConfigQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodePptConfigFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerNodePptConfigQuery>(
        '/review/intelligentPartner/node/pptConfig',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodePptConfig',
        }
    );
}

/**
 * save
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [POST] /review/intelligentPartner/node/pptConfig
 */
export function postReviewIntelligentPartnerNodePptConfig(
    data: PostReviewIntelligentPartnerNodePptConfigBody
): Promise<BaseResponseIntelligentPartnerDialogNodePptConfigFoundResponse>;
export function postReviewIntelligentPartnerNodePptConfig(
    data: PostReviewIntelligentPartnerNodePptConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodePptConfigFoundResponse>;
export function postReviewIntelligentPartnerNodePptConfig(
    data: PostReviewIntelligentPartnerNodePptConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodePptConfigFoundResponse> {
    return request<PostReviewIntelligentPartnerNodePptConfigBody, never, never>(
        '/review/intelligentPartner/node/pptConfig',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodePptConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig',
        method: 'get',
    },
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig',
        method: 'post',
    },
];
