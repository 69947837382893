export default {
  labels: {
    clear: "Prázdný",
    refresh: "Aktualizace",
    nextStep: "Další krok",
    prevStep: "Předchozí krok",
    closeDriver: "Ukončit spuštění",
    complete: "Kompletní",
    viewStatistics: "Zobrazit statistiky",
    colon: "：",
    splitter: "/",
    remark: "Poznámky",
    correction: "Oprava chyb",
    save: "Uložit",
    reset: "Resetovat",
    increase: "Přidat",
    preview: "Náhled",
    unit: "Článek",
    cancel: "Storno",
    all: "All",
    searchPlaceHolder: "Zadejte dotaz na klíčové slovo",
    greaterThan: "Větší",
    lessThan: "Méně",
    second: "Sekundy",
    description: "Popis",
    column: "Sloupec",
    pleaseChoice: "Vyberte prosím",
    filter: "Filtr",
    saveAndSubmit: "Uložit a odeslat",
    submit: "Podání",
    appeal: "Stížnost",
    review: "Přezkum",
    choose: "Vybrat",
    manage: "Management",
    delete: "Smazat",
    add: "Nový",
    create: "Nový",
    edit: "Upravit",
    name: "Jméno",
    describe: "Popis",
    next: "Další krok",
    percent: "%",
    export: "Export",
    exportAll: "Exportovat vše",
    confirm: "Určit",
    back: "Return",
    versionUpdate:
      "I am glad to inform you that our website has just released a new version! Abychom zajistili vaše běžné použití, potřebujeme, abyste okamžitě uložili aktuální práci a poté aktualizovali stránku (klikněte na tlačítko aktualizace prohlížeče nebo stiskněte klávesu f5). to make our new version take effect. Děkuji vám za spolupráci!",
    more: "Více",
    selected: "Vybráno",
    deduct: "Spony",
    maxScore: "Most",
    scoreUnit: "Body",
    pointUnit: "One",
    comma: "，",
    view: "Zobrazit",
    batchingImport: "Import",
    deleteContent: "Opravdu ho chcete odstranit? Po odstranění nelze obnovit",
    import: "Import",
    uploadFile: "Nahrát soubor",
    downloadFile: "Exportovat soubor",
    upload: "Nahrát",
    click: "Kliknout",
    downloadTemplate: "Download template",
    fillInTheForm: "Vyplnit podle formátu",
    UploadExcelfile: "Nahrát aplikaci excel k dokončení importu šarže",
    uploadIdentical:
      "Pokud existuje název api, budou během importu aktualizovány stávající údaje v systému.",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Použitelné",
    leftBracket: "(",
    rightBracket: ")",
    question: "Otázka",
    answer: "Odpověď",
    content: "Obsah",
    search: "Hledat",
    query: "Dotaz",
    cancelEdit: "Zrušit úpravu",
    noValue: "Žádné údaje",
    selectAll: "Vybrat vše",
    changeAGroup: "Změnit skupinu",
    arrow: " ---> ",
    saveReview: "Předložení přezkumu",
    saveSpotCheck: "Kontrola a předložení vzorků",
    rapidSampling: "Rychlý odběr vzorků",
    file: "Archivace",
    download: "Stáhnout",
    lastPage: "Předchozí stránka",
    nextPage: "Další stránka",
    batchActions: "Provoz šarže",
    cancelDistribution: "Nepřiřadit",
    againDistribution: "Přeřadit",
    deleteRecord: "Smazat záznam",
    oneOptionOpertor: "Volitelný inspektor kvality",
    allOptionOpertor: "Všechny inspektory kvality",
    distributionInfoFirstOne: "Potvrdit",
    distributionInfoFirstTwo: "Chcete přeložit záznamy? Vyberte cíl přiřazení.",
    distributionInfoSecondOne: "Ok to cancel",
    distributionInfoSecondTwo:
      "Je zadavatel informace tohoto záznamu? Po zrušení se stane nepřiřazený záznam o hodnocení.",
    distributionInfoSecondThree:
      "Je zadavatel informace tohoto záznamu, to se stane nepřidělený vzorkovací záznam po zrušení.",
    distributionInfoThirdOne: "Vybrané záznamy mají",
    distributionInfoThirdTwo: "Přezkoumané záznamy nebudou zpracovány.",
    distributionInfoThirdThree:
      "Záznamy, které byly zařazeny do vzorku, nejsou zpracovány.",
    exportError: "Export selhal",
    exportSuccess: "Exportovat úspěšně",
    deleteRecordInfo:
      "Opravdu chcete odstranit záznamy {0}? Data nelze po odstranění obnovit",
    pleaseSetFilter: "Vyberte kritéria filtru",
    noCountAfterFilter: "Žádné exportní údaje, prosím obnovit kritéria filtru",
    redo: "Re-execution",
    batchMove: "Stěhování šarže",
    websiteName: "Inteligentní kontrola kvality",
    salesEnable: "Empowerment sales",
    systemName: "Inteligentní kontrola kvality",
    initSystemError:
      "Síťová výjimka nebo selhání inicializace stránky, prosím kontaktujte zákaznickou službu",
    day: "Den",
    hour: "Hodiny",
    minute: "Body",
    questionMark: "？",
    personalSettings: "Osobní nastavení",
    logout: "Odpis",
    ok: "Určit",
    saveAs: "Uložit",
    pleaseEnter: "Zadejte prosím",
    executeMessage: {
      executeSuccess: "{0} úspěšně spuštěn",
      executeError: "Selhalo spuštění {0}",
    },
    field: "Pole",
    operator: "Operátor",
    value: "Hodnota",
    placeholder: "Vyberte prosím",
    pleaseSelect: "Vyberte prosím",
    totalScore: "Celkové skóre",
    hyphen: "-",
    semicolon: "；",
    wfm: "Inteligentní plánování",
    user: "Uživatel",
    none: "Žádné",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Úspěšně uložit! Účinná po tom, co relevantní zaměstnanec aktualizuje stránku",
    saveAsName: "{0}(kopie)",
    systemInformation: "Zobrazit data systému",
    billingCenter: "Fakturační centrum",
    warningMessage:
      "Zbývající kvóta je nedostatečná, prosím dobíjej co nejdříve",
    fileTypeHolder:
      "Formát souboru je nesprávný. Načtěte soubor ve formátu {0}.",
    dataNo: "Žádné údaje",
    move: "Přesunout",
    study: "Máte neoznačené self-learning kontrolní body kvality",
    studySuccess: "Úspěch označování",
    studyError: "Označování selhalo",
    editType: "Upravit štítky kategorií",
    copyed: "Zkopírováno do schránky",
    clickToCopy: "Klepněte na tlačítko kopírovat",
    addFilterConditions: "Přidat podmínku filtru",
    filterConditions: "Stav filtru",
    messageTime: "Čas zprávy",
    messageType: "Typ zprávy",
    pushType: "Kanál zpráv",
    messageContent: "Obsah zprávy",
    messageName: "Název zprávy",
    messageStatus: "Stav zprávy",
    configuration: "Informace o konfiguraci",
    sendTarget: "Odeslat určení",
    guideUrl: "Url bootstrap",
    noticeDetail: "Podrobnosti zprávy",
    action: "Operace",
    seeDetail: "Zobrazit podrobnosti",
    theDay: "Den {0}",
    theLastDayOfMonth: "Poslední den každého měsíce",
    ignore: "Ignorovat",
    seeNow: "Zobrazit nyní",
    exportConfirmText: {
      export: "A total of {0} pieces of data, confirm to export?",
      exportAll: "Potvrdit exportování veškerého obsahu?",
    },
    samrTagAdd: "Přidat značky",
    deletePopconfirmContent: "Opravdu chcete odstranit?",
    TheLastThirtyDays: "Last 30 days",
    addNumber: "Plus",
    subNumber: "Mínus",
    addStrategy: "Přidat zásadu",
    minutes: "Minuty",
    status: "Povolený stav",
    pushConfig: "Nastavení stisknutí",
    category: "Klasifikace",
    and: "S",
    interval: "Interval",
    switchOn: "Otevřít",
    switchOff: "Close",
    batchDel: "Smazat dávku",
    exportTxt: "Exportovat text txt",
    playSpeed: "Dvojitá rychlost",
    messageLogBtn: "Zobrazit oznámení/rozhovory, které spouštějí oznámení",
    dataLink: "Datový odkaz",
    yes: "Ano",
    no: "Ne",
    sentenceUnit: "Věta",
    appealDetail: "Podrobnosti záznamu stížnosti",
    detail: "Podrobnosti",
    default: "Výchozí",
    customer: "Zákazník",
    agent: "Zákaznický servis",
    sort: "Řadit",
    applyNow: "Aplikace",
    index: "Sériové číslo",
    allColumns: "Sloupce, které lze přidat",
    selectedColumns: "Přidané sloupce",
    syn: "Synchronizace",
    saveAndNext: "Uložit a vytvořit další",
    viewReference: "Zobrazit odkazy",
    referenceDetail: "Referenční údaje",
    goToQaList: "Přejít na kontrolu kvality",
    goToCheck: "Přejít na kontrolu na místě",
    businessCard: "Vizitka",
    weapp: "Malý program",
    redpacket: "Červená obálka",
    externalRedpacket: "Vyměnit červené obálky",
    sphfeed: "Číslo videa",
    exportTaskCreated: "Úspěšně vytvořený úkol exportovat",
    plzGoTo: "Please jump",
    importTaskCreated: "Úspěšně vytvořená úloha importu",
    taskCreated: "Úspěšně vytvořená úloha",
    reply: "Odpověď",
    replied: "Odpověď",
    release: "Publikovat",
    expandMore: "Rozšířit další {0} odpovědi",
    comment: "Comments",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Please have at least one {0}",
      canNotBeEmptyFormat: "{0} nemůže být prázdná",
      repeatErrorTipFormat: "{0} má duplicity",
      getInformationFailedFormat:
        "Selhalo získání {0} informací. Zkontrolujte síťové připojení nebo zkuste znovu později.",
      deleteSuccessFormat: "{0} byl úspěšně odstraněn.",
      deleteFailedFormat: "Smazání {0} se nezdařilo. Zkuste to znovu později.",
      saveSuccessFormat: "{0} úspěšně uloženo.",
      saveFailedFormat: "{0} se nepodařilo uložit. Zkuste to znovu později.",
      fileSuccessFormat: "{0} úspěšně archivováno.",
      fileFailedFormat: "Archivace {0} se nezdařila. Zkuste to znovu později.",
      exportSuccessFormat: "{0} export byl úspěšný",
      exportFailedFormat:
        "Exportování {0} se nezdařilo. Zkuste to znovu později.",
      submitSuccessFormat: "{0} úspěšně odesláno.",
      submitFailedFormat: "{0} nepodařilo se odeslat, zkuste to znovu později.",
      confirmSuccessFormat: "Potvrzení {0} bylo úspěšné.",
      confirmFailedFormat:
        "Potvrzení {0} se nezdařilo. Zkuste to znovu později.",
      pleaseUploadFile: "Please upload the file first!",
      importFileSuccess: "Import souborů je úspěšný!",
      importFildFailed: "Import souboru selhal!",
      deleteSuccess: "Úspěšně smazán!",
      deleteFailed: "Odstranění selhalo, zkuste to znovu později!",
      illegalTitle: "Nedostatečné oprávnění",
      illegalMessage:
        "Nemáte některé nebo všechny oprávnění pro další krok, prosím, kontaktujte správce pro konfiguraci, klikněte na tlačítko ok bude ukončeno spouštění",
      illegalMessageCurrent:
        "Nemáte některé nebo všechny oprávnění pro aktuální krok, prosím, kontaktujte správce pro konfiguraci, klikněte na tlačítko ok bude ukončeno spuštění",
      skipMessage:
        "Pokud chcete přeskočit tento krok pro nyní, klepněte na tlačítko přeskočit",
      errorMsg500:
        "Požadavek serveru selhal, zkontrolujte operaci nebo aktualizaci a zkuste to znovu.",
      errorMsg404: "Ooops... stránka, kterou hledáte, chybí",
      errorMsg400:
        "Požadavek serveru selhal, zkontrolujte data a zkuste to znovu.",
      pageOverFlow:
        "V současné době je možné zobrazit pouze 10000 barů. Pro zobrazení přebytku nastavte kritéria filtrování.",
      isHandAddSmartTag: "Je manuálně přidaný smart label",
      defaultGradeMustAtLast:
        "Výchozí hodnocení musí být umístěno na samém konci",
      AutomaticAllocationInspectorsIsNull:
        "Prosím, zadejte základní informační stránku pro přidání inspektora kvality",
      timePickerTips:
        "S cílem neovlivnit vaše běžné použití, 23:00 ~ 2:00 je doba údržby systému, neprovádějte naplánované úkoly.",
      batchDeleteInfo:
        "Položky {0} byly odstraněny a položky {1} selhaly, protože data byla použita.",
      batchDeleteTips: "Zaškrtněte {0} kusy dat, potvrďte smazat?",
      correctionSuccess: "Úspěch korekce chyb",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError: "Přidat až 10 formulářů pro kontrolu kvality",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "Metrické položky {0} musí přidat až 100",
          banProjectMaxErrorTip: "Přidat až 2000 zakázaných položek",
          deadlyProjectMaxErrorTip: "Přidat až 500 smrtelných položek",
        },
      },
    },
    info: {
      creatSuccess: "Úspěšně vytvořeno",
      creatError: "Nepodařilo se vytvořit",
      editSuccess: "Úprava byla úspěšná",
      editError: "Úprava selhala",
      deleteSuccess: "Úspěšně smazán",
      addSuccess: "Nový úspěch",
      deleteError: "Odstranění selhalo",
      conditionError: "Chyba logického výrazu operátoru",
      customJudgeLogic: "Chyba logického výrazu vlastní kombinace stavu",
      exportError: "Export selhal",
      searchError: "Selhal filtr",
      loadError: "Načtení {0} se nezdařilo",
      saveSuccess: "Úspěšně uložit",
      saveError: "Selhalo uložení",
      fileSuccess: "Úspěch archivace",
      fileError: "Archiv selhal",
      cannotBeNull: "{0} nemůže být prázdná!",
      pleaseSearchAndSelect: "Zadejte prosím vyhledávání klíčových slov",
      clickAndToggle: "Klikněte pro výběr přepínače",
      deleteWarning:
        "{0} odstranění nebude možné obnovit, jste si jistý, že chcete pokračovat?",
      getOssAuthFailed:
        "Selhalo získání oprávnění oss. Zkontrolujte síťové připojení nebo opakujte akci později.",
      getInsightAuthFailed:
        "Selhalo získání autorizace insight. Zkontrolujte síťové připojení nebo opakujte akci později.",
      pictureUploadFailed: "Načtení obrázku se nezdařilo",
      pictureUploadFailedSize:
        "Velikost obrázku přesahuje limit, načtení obrázku selhalo",
      wecomTagsSyncInfo: "Last updated:{0}, syncing {1} messages",
      collectSuccess: "Úspěch sběru.",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "Selhalo získání dat sestavy" },
      waveAudio: {
        noWaveMessage:
          "Za účelem zachování vašich zkušeností se waveform nebude zobrazovat pro záznamy během {0} minut. Můžete si je zahrát přímo.",
      },
      customFilter: {
        del: "Smazat",
        edit: "Upravit",
        new: "Vlastní filtr",
        apply: "Aplikace",
        cancel: "Storno",
        onlyApply: "Použitelné pouze",
        ohterSave: "Uložit",
        save: "Uložit",
        customFilter: "Společný filtr",
        reset: "Resetovat",
        holderKeyWord: "Zadejte prosím klíčové slovo",
        customFilterRule: "Pravidla pro filtrování logiky:",
        customFilterName: "Název filtru:",
        holderCustomFilterName: "Zadejte název filtru",
        manualConfiguration: "Manuální konfigurace",
        holderCustomRule: "Zadejte prosím vlastní logickou podmínku",
        example: "Příklad",
        dragConfiguration: "Přesunout a pustit konfiguraci",
        condition: "Stav",
        operation: "Operace",
        infoDel: "Drag content out of area to delete",
        toConfigure: "Konfigurace",
        holderCustomFilterNameLength: "Název filtru maximální délka je 10",
        customFilterNameNone: "Název filtru nemůže být prázdný",
        and: "A",
        or: "Nebo",
        not: "Non",
        delSuccess: "Úspěšně smazán",
        saveSuccess: "Úspěšně uložit",
        updateSuccess: "Úprava byla úspěšná",
        customFilterCondition: "Stav filtru",
        conditionRule: "Pravidla stavu",
        none: "Žádné",
        filterPlaceholder: "Zadejte prosím klíčové slovo",
      },
      labelTabs: {
        addThis: "Přidat do tohoto",
        goThis: "Přesunout do tohoto",
        saveGo: "Uložit a cvičit",
      },
      datePicker: {
        today: "Dnes",
        yesterday: "Včera",
        theDayBeforeYesterday: "Den před včerejším",
        thisWeek: "Tento týden",
        lastWeek: "Minulý týden.",
        thisMonth: "Tento měsíc",
        lastMonth: "Minulý měsíc",
        lastSevenDay: "Poslední 7 dní",
        lastThirtyDay: "Last 30 days",
        thisQuarter: "Toto čtvrtletí",
        lastQuarter: "Poslední čtvrtletí",
        thisYear: "Tento rok",
        lastYear: "Minulý rok",
        lastYearFromToday: "Minulý rok",
      },
      pagination: { page: "Stránka {1} z {0}" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Načíst předchozí zprávy",
          bottom: "Načíst další zprávy",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Kontrola kvality úkol",
        informationCollectTasksManageTitle: "Úkoly sběru",
        customerServiceCenterTitle: "Centrum služeb zákazníkům",
        informationTitle: "Datové centrum",
        intelligentAnalysisTitle: "Inteligentní analýza",
        semanticIntelligence: "Sémantická inteligence",
        trainingCenterTitle: "Výcvikové centrum",
        adminTitle: "Správa systému",
      },
      qualityCheckingTask: {
        title: "Kontrola kvality volání",
        index: {
          tipOne:
            "Kontrola kvality volání se používá k vyhodnocení kvality zaměstnance v call center. Na základě vašeho podniku můžete nastavit vhodné formuláře pro kontrolu kvality.",
          tipTwo:
            "Vhodná pravidla mohou být stanovena pro úkoly kontroly kvality, včetně filtrování a odběru vzorků. Po skóre inspektora kvality může zákaznický servis zobrazit výsledky kontroly kvality a další odvolání,",
          addTaskTitle: "Přidat úlohu kontroly kvality",
          deleteTipTitle: "Odstranit úlohu kontroly kvality",
          deleteTipContent:
            "Úkoly kontroly kvality nelze po vymazání obnovit. Opravdu je chcete odstranit?",
          checkingTask: "Kontrola kvality úkol",
          checkingTaskList: "Seznam úkolů kontrol kvality",
          formList: "Seznam formulářů",
          viewHelpDocuments: "Zobrazit dokumentaci nápovědy",
        },
        manage: {
          taskSetting: {
            title: "Nastavení úkolu",
            form: "Formulář",
            basicInfo: "Základní informace",
            visibleRange: "Viditelný rozsah",
            visibleRangeTip:
              "Vybraný tým pro zákaznický servis a zákaznický servis může zobrazit úlohu kontroly kvality. Kromě toho může být velitel zákaznické služby, zákaznický servis a inspektor kvality kombinován s nastaveními oprávnění pro tuto úlohu.",
            automaticSampling: "Automatický odběr vzorků",
            automaticSamplingTip:
              "Můžete nastavit pravidla automatického odběru vzorků tak, aby systém pravidelně přidal úkoly kontrol kvality podle nastavených pravidel, jako je extrakce 5% hovorů z minulého týdne každý pondělí.",
            samplingRate: "Frekvence odběru vzorků",
            samplingTime: "Čas odběru vzorků",
            samplingRange: "Rozsah odběru vzorků",
            samplingRule: "Pravidla pro odběr vzorků",
            fillterCondition: "Stav filtru",
            workflow: "Průběh kontroly kvality",
            appealsTime: "Povolit čas odvolání",
            appealsTimeTipOne: "Po podání skóre",
            appealsTimeTipTwo: "Within days",
            appealsTimeTipThree:
              'Pokud je čas odvolání překročen, stav kontroly kvality záznamů bude automaticky změněn na potvrzení"',
            auditTime: "Doba zpracování auditu",
            auditTimeTipOne: "Po podání stížnosti",
            auditTimeTipTwo: "Within days",
            auditTimeTipThree:
              'Po překročení doby zpracování schválení záznam zachovává původní skóre a stav kontroly kvality se automaticky změní na potvrzení"',
            noAppealsMode: "Bez režimu stížnosti",
            appealsMode: "Povolit režim odvolání",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Zákaznický servis",
            customerServicesGroup: "Skupina zákaznických služeb",
            surveyList: "Seznam hodnocení",
            customFields: "Vlastní pole",
            fieldConfig: "Nastavení pole",
            from: "Od",
            owner: "Odpovědná osoba",
            inspectorAndReviewerAuthority:
              "Kontrolní orgán pro kontrolu kvality",
            inspector: "Inspektor kvality",
            reviewer: "Recenzent",
            inspectorTip: "Inspektor může vyhodnotit úkol",
            reviewerTip: "Recenzent může hodnotit úkol",
            dataStatisticsAuthority: "Oprávnění statistiky dat",
          },
          samplingRecords: {
            title: "Záznamy o odběru vzorků",
            index: {},
            manualSampling: {
              title: "Odběr vzorků",
              fillterCondition: "Stav filtru",
              samplingRule: "Pravidla pro odběr vzorků",
              agent: "Zákaznický servis",
              type: "Typ",
              result: "Výsledky",
              evaluate: "Hodnocení",
              relayNumber: "Číslo relé",
              source: "Zdroj",
              task: "Úkoly",
              queueState: "Stav fronty",
              callHanger: "Call hang-up party",
              queueTime: "Časově náročná fronta",
              talkTime: "Doba trvání volání",
              bellTime: "Doba trvání prstenu",
              recordCountTipOne: "Celkem",
              recordCountTipTwo: "Záznam",
              customerServices: "Zákaznický servis",
              customerServicesGroup: "Skupina zákaznických služeb",
              surveyList: "Seznam hodnocení",
              customFields: "Vlastní pole",
              fieldConfig: "Nastavení pole",
              defaultFields: "Výchozí pole",
            },
          },
          taskList: {
            title: "Seznam úkolů",
            index: {
              batchDelete: "Smazat dávku",
              batchSubmit: "Podání šarže",
              batchConfirm: "Potvrzení šarže",
              itemFields: "Pole položky třídění",
              categoryFields: "Pole sekundární klasifikace",
              forbidsAndDeadlies: "Zakázané a smrtelné předměty",
              scoreFieldList: "Seznam polí položek",
              customerName: "Jméno zákazníka",
              cellphone: "Telefon",
              email: "Poštovní schránka",
              organization: "Společnost",
              description: "Popis",
              tags: "Štítek",
              taskUserRole: "Role uživatele aktuální úlohy",
            },
            workBench: {
              title: "Údaje o záznamu",
              checkForm: "Formulář kontroly kvality",
              waitEvaluate: "Vyhodnotit",
              alreadyEvaluate: "Vyhodnoceno",
              waitSubmit: "K předložení",
              alreadySubmit: "Podal",
              waitConfirm: "K potvrzení",
              alreadyConfirm: "Potvrzeno",
              waitReview: "K přezkoumání",
              alreadyReview: "Přezkoumáno",
              alreadyDone: "Dokončeno",
              comment: "Poznámky k hodnocení",
              totalScore: "Celkové skóre",
              score: "Body",
              remark: "Poznámky",
              changeRecords: "Změnit záznam",
              subject: "Téma",
              secondLevelScore: "Skóre ii. stupně",
              deadlyList: "Fatální položka",
              forbidList: "Zakázané položky",
              addToCheckTask: "Přidat do úkolu kvality",
              submitCheckRemark: "Předložené hodnocení kontroly kvality",
              changeCheckRemark: "Hodnocení kontroly kvality bylo změněno",
              doneCheckRemark: "Hodnocení kontroly kvality dokončeno",
              submitCheckReview: "Předložené pro přezkum kontroly kvality",
              submitCheckAppeal: "Předložená odvolání na kontrolu kvality",
              saveFlowSuccess: "Vyhodnotit úspěch.",
              saveFlowFailed: "Hodnocení selhalo, zkuste to znovu později.",
              FormDetail: "Podrobnosti formuláře",
              callDetail: "Podrobnosti záznamu volání",
              sampleCalllogDetail: "Údaje o záznamu",
              appealReasonTip:
                "Prosím, zadejte důvod pro odvolání (požadované)",
              appeal: "Stížnost",
              appealReason: "Důvody stížnosti",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} formulář kontroly kvality",
        basicInformationTitle: "Základní informace",
        indicatorsProjectTitle: "Indikační položka",
        specialProjectTitle: "Speciální položky",
        totalScore: "Celkové skóre",
        indicatorsName: "Název ukazatele",
        score: "Frakce",
        singleScore: "Skóre jedné položky",
        weight: "Váha",
        maxDeductScore: "Odpočet maximálních bodů",
        add: "Zvýšení",
        detail: "Podrobnosti formuláře",
        list: {
          title: "Formulář kontroly kvality",
          tipOne:
            "Konfigurace formuláře pro kontrolu kvality je prvním krokem v práci na kontrolu kvality. Můžete nastavit nejvhodnější formulář pro kontrolu kvality na základě podnikání vaší společnosti.",
          tipTwo:
            "We support a variety of forms such as direct scoring, weighted scoring, and point deduction systems. Před přidáním těchto pravidel do systému se doporučuje nastavit pravidla.",
        },
        basicInformation: {
          formTemplate: "Šablona formuláře",
          directScore: "Přímé hodnocení",
          weightedScore: "Skóre váhy",
          deductScore: "Odpočty",
          directScoreSecondLevel: "Přímé hodnocení-úroveň 2",
          weightedScoreSecondLevel: "Váha skóre-úroveň 2",
          deductScoreSecondLevel: "Odpočet bodů-úroveň 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Zakázané položky",
          banProjectTip:
            "Může být použit pro kontrolu při hodnocení kontroly kvality. It can be considered as a violation or a supplement to comments without affecting the actual score.",
          deadlyProject: "Fatální položka",
          deadlyProjectTip:
            "Při hodnocení kontrol kvality lze použít smrtelné položky. Pokud je kontrolována smrtelná položka, úloha kontrol kvality je přímo zaznamenána na 0 bodů.",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Kontrola kvality volání",
          entiretyReportTitle: "Celková zpráva",
          serviceGroupReportTitle: "Zpráva skupiny pro služby zákazníkům",
          serviceReportTitle: "Zpráva o servisu zákazníků",
          entiretyReport: {
            pandect: "Přehled",
            contrast: "Kontrast",
            trend: "Trend",
            analyze: "Analýza",
            totalScore: "Celkové skóre",
            banProject: "Zakázané položky",
            deadlyProject: "Fatální položka",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Seznam výsledků kontrol kvality",
          taskSelectPlaceholder: "Vybrat kvalitní úkol",
          agentSelectPlaceholder:
            "Vybrat zákaznický servis (18 + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +",
          refresh: "Aktualizace",
          all: "All",
          unread: "Nepřečtené",
          inComplaint: "Ve stížnosti",
          reviewed: "Přezkoumáno",
          totalScore: "Obecné připomínky",
          detail: "Podrobnosti kontroly kvality",
          audioText: "Nahraný text",
          callInfo: "Informace o volání",
          ticketInfo: "Informace o pracovní objednávce",
          wechatInfo: "Informace o relaci",
          dialogText: "Text dialogu",
          dialogInfo: "Informace o dialogu",
          customer: "Zákazník",
          agent: "Zákaznický servis",
          none: "Žádné údaje o kontrole kvality",
          appealSuccess: "Odvolání úspěšné!",
          appealFailed: "Odvolání selhalo, zkuste to později znovu!",
          pointListIsNull: "Kontrola kvality stížnosti nemůže být prázdná!",
          pleaseSelectPoint: "Prosím, vyberte kontrolní bod kvality!",
          unsupportedFormat: "Tento formát není podporován.",
          shardingInformation: "Níže je shard {0}",
          smartTags: "Smart label",
          businessRecords: "Obchodní záznamy",
          selectedPointsWarning:
            "Prosím, zkontrolujte položky kontroly kvality, aby se odvolali, zadejte příslušné prohlášení pro položky odvolání, a poté klepněte na tlačítko předložit.",
          applyReason: "Důvody kasačního opravného prostředku (max. 50 slov)",
          chatMessageTypes: {
            all: "All",
            text: "Text",
            image: "Obrázek",
            agree: "Souhlas obsah sezení",
            video: "Video",
            voice: "Hlas",
            file: "Soubor",
            emotion: "Výraz",
            link: "Link",
            chatrecord: "Záznam chatu",
            mixed: "Smíšená zpráva",
            default: "Zpráva není podporována",
            deleteFriend: "Smazat přítele",
            redpacket: "Červená obálka",
            card: "Vizitka",
            disAgree: "Nesouhlasí s obsahem konverzace",
            weapp: "Malý program",
            externalRedpacket: "Vyměnit červené obálky",
            meetingVoicevCall: "Zpráva o volání",
            uRL: "Webová stránka",
            replyTimeout: "Lhůta odpověď",
            sphfeed: "Číslo videa",
            chatRecordTitle: "Záznam chatu",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Celkové skóre",
            actualScore: "Rating",
            actualGrade: "Rating",
            appeal: "Stížnost",
            remark: "Ruční poznámky",
            none: "Žádné podrobné údaje pro kontrolu kvality",
            pointEdit: "Klasifikace editačního bodu kontroly kvality",
          },
          changeList: {
            title: "Změnit záznam",
            user: "Uživatel",
            point: "Bod kontroly kvality",
          },
          relatedRecord: {
            title: "Související záznamy",
            qualityList: "Kontrola kvality úkol",
            none: "Žádné",
            check: "Zobrazit",
            loadMore: "Načíst více",
          },
        },
      },
      grade: {
        title: "Hodnocení kontroly kvality",
        subTitle:
          "Rating kontroly kvality se používá k hodnocení objektů kontroly kvality po dokončení hodnocení kontroly kvality. Například může být konfigurována jako nekvalifikovaná, pokud je nižší než 60, dobrá, pokud je vyšší než 60, a vynikající, pokud je vyšší než 80.",
        list: {
          up: "Přesunout nahoru",
          down: "Posunout dolů",
          del: "Smazat",
          case: "Automaticky se připojit k knihovně případů",
          orderCase: "Výchozí knihovna případů",
          info: "Výchozí hodnocení nemá odpovídající kritéria a nelze jej odstranit. Pokud některá z výše uvedených ratingů není splněna, bude získáno výchozí hodnocení.",
          add: "Přidat hodnocení",
          save: "Uložit",
          close: "Storno",
          max: "Rating kontroly kvality lze nastavit až do 20",
          name: "Nejmenovaný",
          success: "Úspěšně uložit",
        },
      },
      qualityInspectionList: {
        title: "Seznam kontrol kvality",
        fastFilter: "Porušení kontroly kvality",
        fastFilterTip:
          "Zásah do minusového bodového systému je porušení, a miss na plus-bodový systém je porušení.",
        titleWechat: "Seznam analýz",
        titleCollect: "Seznam akvizic",
        status: "Stav analýzy",
        reQualityInspectionConfig: "Možnosti úkolu requality",
        pleaseSelectRecheckPoints:
          "Prosím, vyberte kontrolní bod kvality, který má být přepočítán.",
        beginReQualityInspection: "Spustit provádění",
        cancelReQualityInspection: "Zrušit úlohu",
        reQualityInspection: "Opětovná kontrola",
        reQualityInspectionWechat: "Re-analýza",
        reQualityInspectionUnderWay: "Opakovaná kontrola...",
        reQualityInspectionUnderWayTip:
          "Opouštění stránky po uzavření okna nemá vliv na úkol",
        reQualityInspectionDone: "Opakovaná kontrola byla dokončena",
        reQualityInspectionDoneTip:
          "Opakovaná kontrola byla úspěšně dokončena, klikněte na tlačítko potvrzení.",
        endReQualityInspection: "Ukončit úlohu",
        alreadyDoneTip: "{0} dokončeno",
        totalTip: "Celkem {0}",
        endReInspectionSuccess: "Úloha re-quality ukončená",
        closeWindow: "Zavřít okno",
        endReInspectionFailed:
          "Úloha re-quality selhala, zkuste to později znovu!",
        detail: {
          title: "Podrobnosti kontroly kvality",
          wechatTitle: "Podrobnosti analýzy",
          titleCollect: "Detaily sbírky",
          callIndex: "Přidružené volání",
          textIndex: "Související rozhovory",
          fileConfirm: "Potvrdit podání?",
          fileConfirmDesc:
            "Po archivaci nelze upravit skóre kontroly kvality. Potvrďte, zda chcete archivovat.",
        },
        sysCloumnsContal: "Správa polí v systémovém sloupci",
        personCloumnsContal: "Osobní řízení pole sloupců",
      },
      spotCheckList: {
        title: "Seznam odběrů vzorků",
        onlyMyTip: "Zobrazit pouze můj vzorek",
        plInspector: "Prosím, vyberte odběr vzorků",
        allInspector: "Zobrazit vše",
        seInspector: "Zobrazit určený inspektor odběru vzorků",
        myInspector: "Zobrazit pouze moje",
        noInspector: "Zkontrolujte nepřiřazený inspektor odběru vzorků",
        detail: { title: "Podrobnosti o odběru vzorků" },
      },
      reviewList: {
        title: "Přehledový seznam",
        onlyMyTip: "View only my review",
        plInspector: "Prosím, vyberte hodnotitele",
        allInspector: "Zobrazit vše",
        seInspector: "Zobrazit určený hodnotitel",
        myInspector: "Zobrazit pouze moje",
        noInspector: "Zobrazit nepřiřazený hodnotitel",
        detail: { title: "Přezkoumat podrobnosti" },
      },
      caseBaseList: {
        title: "Knihovna případů",
        del: "Smazat",
        delTitle: "Po odstranění se neuloží celý obsah",
        cancel: "Storno",
        actions: "Operace",
        seeCaseBase: "Zobrazit případ",
        seeDetail: "Zobrazit podrobnosti",
        edit: "Upravit",
        save: "Uložit",
        addCaseBase: "Nová knihovna případů",
        export: "Export",
        ok: "Určit",
        tip: "Typické údaje o volání a konverzaci v seznamu kontrol kvality lze uložit do knihovny případů pro snadné učení a sdílení.",
        detail: {
          choiceCaseBase: "Prosím, vyberte případ",
          delCaseBase: "Odstranit případ",
          addCaseBase: "Přidat případ",
          successed: "Úspěšně uložit",
          info: "Prosím, uložit nebo zrušit obsah, který upravujete jako první.",
          caseType: "Vyberte knihovnu případů, kterou se chcete připojit",
        },
      },
      testSetList: {
        title: "Zkušební sada kontrol kvality",
        tip: "Údaje odebírané inspektorem jakosti budou uloženy ve zkušebním souboru kontroly jakosti, což je vhodné optimalizovat konfiguraci podmínek kontrol kvality a pravidel pro některé položky chyb kontroly strojů v porovnání s kontrolou vzorku.",
        refresh: "Aktualizace",
        reQualityInspectionUnderWay: "Přehodnotit misi v...",
        reQualityInspectionUnderWayTip:
          "Opouštění stránky po uzavření okna nemá vliv na úkol",
        reQualityInspectionDone: "Nové posouzení dokončeno",
        reQualityInspectionDoneTip:
          "Re-hodnocení bylo úspěšně dokončeno, klikněte na tlačítko potvrdit",
        endReQualityInspection: "Ukončit úlohu",
        alreadyDoneTip: "{0} dokončeno",
        totalTip: "Celkem {0}",
        endReInspectionSuccess: "Úloha přehodnocování byla ukončena",
        closeWindow: "Zavřít okno",
        endReInspectionFailed:
          "Úloha opakovaného hodnocení selhala, zkuste to později znovu!",
        recheckPoint: "Přepočítat body pro kontrolu kvality a přesnost",
        recheckPointTitle: "Možnosti re-hodnocení",
        delete: {
          reQualityInspectionUnderWay: "Úkoly odstraňování šarží...",
          reQualityInspectionUnderWayTip:
            "Opouštění stránky po uzavření okna nemá vliv na úkol",
          reQualityInspectionDone: "Dokončena úloha odstranění šarže",
          reQualityInspectionDoneTip:
            "Hromadné odstranění bylo úspěšně dokončeno, klikněte na tlačítko potvrdit",
          endReQualityInspection: "Ukončit úlohu",
          alreadyDoneTip: "{0} dokončeno",
          totalTip: "Celkem {0}",
          endReInspectionSuccess: "Úloha odstranění šarže byla ukončena",
          closeWindow: "Zavřít okno",
          endReInspectionFailed:
            "Odstranění šarže se nezdařilo, zkuste to později znovu!",
        },
        reultList: {
          assessmentTime: "Poslední čas hodnocení",
          assessmentAll: "Celkové hodnocení",
          strip: "Článek",
          spot: "Bod",
          deviation: "Odchylka",
          accuracyRate: "Přesnost",
          info: "Žádný výsledek hodnocení, vyhodnoťte prosím",
          onlyDeviationInfo: "Zobrazit pouze záznamy odchylky",
          againAssessment: "Re-hodnocení",
          screen: "Filtr",
          batchDelete: "Odebrat dávku",
          matching: "Zápas",
          ok: "Ano",
          noCancel: "Ne",
        },
      },
      smartWordsLibrary: {
        title: "Inteligentní thesaurus",
        tip1: "Synonyma: používá se ke snížení množství výcviku sémantických štítků, podobné věty synonymů nemusí být opakovaně cvičeny.",
        tip2: "Profesionální podstatné jméno: optimalizovat transkripci a pochopení přirozeného jazyka pro správné podstatné jméno, jako jsou názvy podniků, názvy výrobků a věcné slova, které nejsou přesně uznány, aby se zlepšila přesnost rozpoznávání a přesnost analýzy.",
        tip3: "Focus on words: focus on the words you want to focus on in word cloud analysis to help ai analysis hotspots meet the current business.",
        tip4: "Bloková slova: blokujte slova, na které nechcete věnovat pozornost v analýze aplikace word cloud, aby pomohla ai analyzovat horké body v souladu s aktuálním podnikem.",
        tip5: "Váha slov: používá se k korekci a optimalizaci rozpoznávání sémantických štítků. Úpravou váhy vlivu slov ve větách lze efekt sémantického rozpoznávání v konkrétních scénářích podle potřeby optimalizovat.",
        tip6: "Corpus data: enter large paragraphs of text for their respective business scenarios for transcoding error correction and asr training to improve asr transcoding accuracy.",
        upload: {
          uploadSynonymWords: "Importovat synonymy",
          uploadProfessionalWords: "Importovat profesionální podmínky",
          uploadAttentionWords: "Importovat slova zájmu",
          uploadIgnoreWords: "Import ignorovat slova",
          uploadWordsWeight: "Importovat slova o hmotnosti",
          uploadTypo: "Import typových slov sada",
          uploadCorpusData: "Importovat souborové údaje",
          uploadCorpusContent: "Importovat obsah souborů dat",
          firstUploadStep:
            "1. download the template and fill it in according to the template format",
          downloadTemplateMessage: "Šablona stahování:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. klepněte na tlačítko import pro načtení souboru excel, který odpovídá formátu šablony",
          secondUploadCorpusDataStep:
            "2. klepněte na tlačítko import pro načtení souboru txt, který odpovídá formátu šablony.",
          postUploadDataFail: "Odeslání a načtení dat se nezdařilo.",
          pleaseUploadTemplate: "Načtěte soubor excel/json/txt",
          templateNeedJSONOrXLSX:
            "Šablony souborů mohou být pouze ve formátu json nebo xlsx",
          templateNeedTxt:
            "Šablona souboru corpus data může být pouze soubor txt",
          uploadSuccess: "Úspěšný import",
          uploadFailed: "Import selhal",
          downloadError: "Stažení selhalo",
          downLoadTxtTitle: "Korpus soupeří. Txt",
        },
        navTabNames: {
          synonymWords: "Synonyma",
          professionalWords: "Profesionální podstatné jméno",
          attentionWords: "Dotčená slova",
          ignoreWords: "Ignorovat slova",
          wordsWeight: "Váha slova",
          corpusData: "Corpus data",
          typoLibrary: "Soubor slov typos",
        },
        synonymWords: {
          createSynonymWords: "Nový synonym",
          editSynonymWords: "Upravit synonyma",
          addSynonymWords: "Přidat synonymum",
          pleaseEnterStandardWords: "Zadejte standardní slovo",
          synonymWordsExists: "Synonymum již existuje a nelze jej přidat",
          synonymWordsCanNotNull: "Přidaný synonym nemůže být prázdný",
          pleaseAddSynonymWords: "Please add synonyms",
          addSuccess: "Nový úspěch",
          editSuccess: "Úprava byla úspěšná",
          deleteSuccess: "Úspěšně smazán",
          deleteError: "Odstranění selhalo",
          required: "*",
        },
        professionalWords: {
          create: "Nové profesionální termíny",
          edit: "Profesionální podstatný editor",
          addTargetWords: "Přidat náhradní slovo",
          targetWordsExists: "Náhradní slovo již existuje a nelze jej přidat",
          targetWordsCanNotNull: "Přidané náhradní slovo nemůže být prázdné",
          pleaseAddTargetWords: "Prosím, přidat náhradní slovo",
          pleaseEnter: "Zadejte prosím profesionální termín",
          wordsExists: "Termín již existuje.",
          createSuccess: "Úspěšně přidáno",
          editSuccess: "Úprava byla úspěšná",
          deleteSuccess: "Úspěšně smazán",
          deleteError: "Odstranění selhalo",
          saveError: "Selhalo uložení",
          addTargetWordsMaxNumberError: "Můžete přidat až 100 náhradních slov",
          addHomophonyWordsMaxNumberError:
            "Můžete přidat až 100 maskovaných slov.",
        },
        attentionWords: {
          create: "Nové slovo znepokojení",
          edit: "Dotčený slovní editor",
          pleaseEnter: "Prosím, zadejte slovo zájmu",
          wordsExists: "Slovo zájmu již existuje.",
          createSuccess: "Úspěšně přidáno",
          editSuccess: "Úprava byla úspěšná",
          deleteSuccess: "Úspěšně smazán",
          deleteError: "Odstranění selhalo",
        },
        ignoreWords: {
          create: "New ignore word",
          edit: "Ignorovat úpravu slov",
          pleaseEnter: "Zadejte prosím ignorovat slovo",
          wordsExists: "The ignore word already exists",
          createSuccess: "Úspěšně přidáno",
          editSuccess: "Úprava byla úspěšná",
          deleteSuccess: "Úspěšně smazán",
          deleteError: "Odstranění selhalo",
        },
        wordsWeight: {
          createWordsWeight: "Nové slovo váhy",
          editWordsWeight: "Upravit slovo váhy",
          addWordsWeight: "Přidat slovo váhy",
          pleaseEnterWeightWords: "Zadejte váhové slovo",
          pleaseEnterWeight: "Zadejte váhu",
          weightNeedInZeroToHundred: "Hmotnost musí být mezi 0 a 100",
          synonymWordsExists: "Váha již existuje",
          weightNeedNumber: "Hmotnost vyžaduje celočíselný formát",
          standardWeightInformation:
            "Referenční hmotnost je 50, a čím větší je hmotnost, tím silnější je vliv v analýze.",
          createSuccess: "Úspěšně přidáno",
          editSuccess: "Úprava byla úspěšná",
          deleteSuccess: "Úspěšně smazán",
          deleteError: "Odstranění selhalo",
        },
        corpusData: {
          createCorpusData: "Nová data z corpusu",
          editCorpusData: "Upravit souborové údaje",
          addCorpusData: "Přidat soubor dat",
          pleaseEnterCorpusDescription: "Zadejte popis korpusu",
          pleaseEnterCorpusContent: "Zadejte obsah korpusu",
          synonymWordsExists: "Údaje z tohoto korpusu již existují",
          CorpusContentWordsOverSetting:
            "Počet slov v obsahu přesahuje 20000 slov,",
          download: "Stáhnout",
          upload: "Nahrát",
          see: "Zobrazit",
          canNotSave:
            "Please use the import function, otherwise it cannot be saved",
          createSuccess: "Úspěšně přidáno",
          editSuccess: "Úprava byla úspěšná",
          deleteSuccess: "Úspěšně smazán",
          deleteError: "Odstranění selhalo",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Správné slovo",
            wrongWord: "Špatné slovo",
            wrongWordCount: "Počet špatných slov",
            wrongWordTip: "Pro přidání stiskněte klávesu enter",
            status: "Účinnost",
          },
          create: "Sada nových písmen",
          edit: "Upravit písma",
          tip: 'Pro optimalizaci účinku "detekce typos" se používá sada slovů typos. Například, pokud je "náhrada" chybně odeslána jako "náhrada šířky", lze pomocí této funkce nastavit společné písmo a správné slova a nabývá účinku příštího dne po uložení.',
          onlyChinese: "Pouze podporovat vstupní čínské slova",
          wrongWordLengthErr:
            "Délka každého špatného slova by měla být 2 ~ 10 slov",
        },
      },
      semanticTags: {
        title: "Sémantické označení",
        current: "Aktuální sémantická značka",
        reference1: "Kontrola kvality: {0} - {1}",
        reference2: "Smart label: {0}",
        reference3: "Předzpracování dat: {0}",
        reference4: "Znalostní základna: {0}",
        index: { import: "Importovat sémantické štítky" },
        detail: {
          new: "Nová sémantická značka",
          edit: "Upravit sémantický štítek",
          name: "Jméno",
          standardSentences: "Standardní věta",
          effectiveness: "Účinnost",
          similarSentences: "Podobné věty",
          tip: "Support using # # to call entity api, example: is your mobile phone number # sys_phone?",
          excludeSimilarSentences: "Vyloučit podobné věty",
          intelligentRecommend: "Inteligentní doporučení podobného obsahu",
          fastTraining: "Rychlý trénink:",
          qualify: "Pravidelné",
          recallKeyWord: "Recall keywords",
          excludeKeywords: "Vyloučit klíčová slova",
          excludeKeywordsInfo1:
            "Vyloučit klíčová slova a pravidelné výrazy mají přednost před všemi logickami. Pokud jsou vyloučeny, nezasáhne se sémantická značka.",
          keyWordInfo1:
            "V každém řádku je možné vyplnit více klíčových slov, které lze oddělit anglickými čárkami. When multiple recall keywords are filled in, the logic is that all hits are hit.",
          keyWordInfo2:
            "Zpětná slova lze vyplnit ve více řádcích. Když je vyplněno více řádků, logika může být nastavena jako jakýkoli zásah.",
          recallRegular: "Recall regular",
          excludeRegular: "Vyloučit pravidelné",
          similaSentences: "Vyloučit podobné věty",
          simim: "Podobnost",
          keyWordInfo3:
            "Vyloučení podobných vět má přednost před celou logikou. Pokud se zasáhne podobná věta, nezasáhne se sémantická značka. Výchozí podobnost je 80% a čím větší je podobnost, tím podobnější je.",
          keyWordInfo4:
            "Pravidlo je podobné normálnímu, s výchozím začátkem a koncem. Není nutně používána s konzolami, např. [not xxxx]",
          keyWordInfo5:
            "Funkce v pokročilém modulu jsou obvykle experimentální funkce pro odborníky, kteří jsou obeznámeni s operací. Pokud si nejste jisti, jak fungovat, doporučuje se, abyste tuto část nezměnili.",
          keyWordInfo6:
            "Funkce detekce křížové věty dává sémantické značky schopnost detekovat všechny věty.",
          keyWordInfo7: "Nejvíce po sobě jdoucí ve stejné roli",
          keyWordInfo8: "Ignorovat různé role délka věty není větší",
          keyWordInfo9: "Věty slov pro detekci křížových vět",
          keyWordInfo10: "Délka nejdelšího křižovatého věty není více",
          keyWordInfo11: "Odstranit podobné procento ne méně",
          keyWordInfo12: "Podobnost není nižší",
          keyWordInfo13:
            "% Podobných vět bude srovnáno podobně (výchozí podobnost 80%)",
          keyWordInfo14:
            "Podobná deduplikace se shoduje s věty na základě jejich podobnosti. Doporučuje se odstranit věty, které jsou příliš podobné, aby se zlepšila účinnost detekce.",
          keyWord: "Klíčová slova",
          entity: "Subjekt",
          ruleField: "Koncepční slova",
          customRule: "Návrh pravidla řeči",
          fanhua: "Podobné otázky",
          test: "Test",
          testOk: "Hit test",
          senior: "Rozšířené nastavení",
          seniorList: "Detekce křížové věty",
          up: "Povolit",
          down: "Close",
          ju: "Věta",
          oneWord: "Slovo",
          simimNo: "Similar to weight",
          seniorField: "Práh podobnosti",
          save: "Uložit",
          cancel: "Storno",
          specialElements: "Speciální prvky",
          logicRule: "Logické pravidla",
          every: "Vícenásobné zápasy",
          recallOk: "Hit recall word:",
          recallNo: "Zmeškané vzpomínací slovo",
          ruleOk: "Hit recall regular",
          ruleNo: "Chybělo pravidelně",
          rularOk: "Stisknout pravidla",
          rularNo: "Chybějící pravidlo",
          infoOk: "Stiskněte standardní větu nebo podobnou větu:",
          infoNo: "Chyběla standardní věta nebo podobná věta",
          fieldOk: "Hit to exclude similar sentences:",
          fieldNo: "Chybělo k vyloučení podobných vět",
          excludeRuleNo: "Chybí vyloučení regex",
          excludeRuleOk: "Hit vyloučení pravidelné",
          excludeKeywordNo: "Vylučovat klíčová slova",
          excludeKeywordOk: "Hit exclude keywords",
          update: "Úspěšná aktualizace",
          fanhuaList: "Podobný seznam vět předložený na kartu generalizace",
          wordList:
            "Seznam výcviků je prázdný a nelze ho předložit do seznamu generalizací.",
          start: "Začátek",
          end: "Konec",
          keyWordInfo15:
            "Podobné pole existují v seznamu generalizací (rozšířená konfigurace podobných omezení deduplikace)",
          saveSuccess: "Úspěšně uložit",
          errInfo: "Některé pole nejsou vyplněny nebo nesprávně vyplněny",
        },
        table: {
          deepTraining: "Kolo deep training {0}",
          fastTraining: "Rychlý trénink",
        },
      },
      intelligentCollection: {
        title: "Inteligentní nabývání",
        classification: "Seznam skupin",
        new: "Nová sbírka",
        table: {
          name: "Název pole",
          props: "Atributy zákazníka",
          type: "Typ pole",
          desc: "Popis pole",
        },
        detail: {
          isCustomerProps: "Uložit atributy zákazníka",
          customerPropsHelper:
            "Po uložení se shromážděné informace aktualizují na informace zákazníka. Neuložit, žádné aktualizace informací o zákazníkovi",
          name: "Název pole",
          type: "Typ pole",
          text: "Text",
          desc: "Popis pole",
          descPlaceHolder:
            "Popište použití tohoto pole k shromažďování informací",
          condition: "Stav",
          logic: "Pravidla",
          method: "Režim nabývání",
          message: "Informační obsah",
          range: "Rozsah extrakce",
          entity: "Výtah entit",
          base: "Základní informace",
          collect: "Shromažďovat informace",
        },
      },
      intelligentTags: {
        title: "Smart label",
        sessionLabelTitle: "Scénáře sezení",
        keyEventsTitle: "Klíčové události",
        customerLabelTitle: "Štítek zákazníka",
        addClassification: "Vytvořit novou klasifikaci štítku",
        defaultCategoryName: "Výchozí klasifikace",
        index: {
          createTag: "Nová značka",
          createEvent: "Nová událost",
          editTagClassification: "Upravit klasifikaci štítků",
          selectTagClassification: "Vybrat klasifikaci štítku",
          editTagGrounp: "Upravit seskupování",
          callTimeError:
            "Zvolený časový rozsah volání/počáteční čas konverzace nemůže přesáhnout jeden měsíc (31 dní)",
          callRecordCountError:
            "Počet záznamů je 0, prosím znovu zvolte volání/čas konverzace",
          selectItmeCountError:
            "Vyberte smart label, pro který chcete provést hromadnou operaci.",
          redoSuccess:
            "Re-execution is successful, please go to the task center to view",
          redoError: "Opakované provedení selhalo, zkuste to později.",
          timeError: "Doba dotazu nesmí překročit jeden měsíc (31 dní)",
          categoryName: "Název kategorie",
          categoryColor: "Barva klasifikace",
          newTagClassification: "Klasifikace nového štítku",
          newEventClassification: "Nová kategorie",
          tagClassification: "Klasifikace štítků",
          eventClassification: "Klasifikace událostí",
        },
        detail: {
          new: "Nový smart label",
          edit: "Upravit smart label",
          tagName: "Jméno",
          tagDesc: "Popis",
          category: "Klasifikace",
          tagStatus: "Účinnost",
          operator: "Operátor",
          rule: "Pravidla",
          labelClassAtion: "Typ štítku",
          labelClassAtionTip1: "Volací štítky: označování na základě pravidel",
          labelClassAtionTip2:
            "Klasifikace označování: označování na základě modelového výcviku",
          group: "Seskupení",
          checkLabel: "Vybrat kategorii",
          warn: "Varování",
          warnTips:
            'Po zapnutí výstrahy, pokud relace splňuje toto pravidlo klíčové události, bude určená osoba vyzvána a může obdržet připomínku v seznamu "to-do".',
          warnError: "Prosím, vyberte upozornění zadaný personál",
        },
        subTitle: {
          common: "Smart session tags",
          wecom: "Enterprise micro label",
        },
      },
      customerPortrait: {
        title: "Portrét zákazníka",
        index: { import: "Importovat portrét zákazníka" },
        searchPlaceHolder: "Id zákazníka, přezdívka nebo telefon",
        callCount: "Počet rozhovorů:",
        callDuration: "Celková doba volání:",
        dialogCount: "Celkový počet rozhovorů:",
        keyWordsStatistics:
          "Klíčová slova statistika (top 20 & words of concern)",
        keyWordsCount: "({0})",
        businessTag: "Obchodní značka",
        keyWordsCloudImg: "Klíčová slova tabulka cloud",
        customerEmotionTrend: "Trendy pro klienty",
        notFoundCustomer: "Nezískali žádné související zákazníci",
        noKeyWords: "Nenalezena žádná související klíčová slova",
        noBusinessTags: "Související obchodní značky nenalezen",
        getKeyWordsStatisticsDataError:
          "Dotaz statistiky klíčových slov zákazníka se nezdařil",
        getBusinessTagsStatisticsDataError:
          "Dotaz na zákaznické obchodní štítky selhal",
        personCustome: "Informace o zákazníkovi nejsou dotazy",
        notimeInfo: "Nebyly nalezeny žádné emocionální informace.",
        timeList: "Datový rozsah",
        operator: "Smart label",
        placeHolder: "Vyberte prosím",
        placeOperator: "Vyberte smart label",
        search: "Dotaz",
        reset: "Resetovat",
        searchHolder: "Hledat",
        call: "Volání",
        text: "Dialog",
      },
      sentimentAnalysis: { title: "Objevování veřejného mínění" },
      saleSentimentAnalysis: { title: "Analýza veřejného mínění" },
      dataPreprocess: {
        title: "Předzpracování dat",
        index: {
          createData: "Nové údaje",
          selectDataSource: "Vybrat zdroj dat",
          selectStatus: "Vybrat účinnost",
          callTimeError:
            "Zvolený časový rozsah volání/počáteční čas konverzace nemůže přesáhnout jeden měsíc (31 dní)",
          callRecordCountError:
            "Počet záznamů je 0, prosím znovu zvolte volání/čas konverzace",
          selectItmeCountError: "Please select data to perform bulk operation",
          redoSuccess:
            "Re-execution is successful, please go to the task center to view",
          redoError: "Opakované provedení selhalo, zkuste to později.",
        },
        detail: {
          new: "Nové údaje",
          edit: "Upravit data",
          name: "Jméno",
          desc: "Popis",
          dataSource: "Zdroj dat",
          status: "Účinnost",
          entity: "Výtah entit",
          operator: "Operátor",
          rule: "Pravidla",
        },
      },
      connectedByConversation: {
        title: "Sdružení komunikačních konverzací",
        list: {
          title: "Funkce přidružení dialogu komunikace",
          info: "Funkce konverzace může spojovat různé volání a rozhovory podle přednastavených pravidel.",
          rule: "Pravidla sdružení konverzace",
          source: "Vyberte zdroj dat",
          classification: "Vyberte kategorii",
          choose: "Vyberte prosím",
          input: "Zadejte prosím",
          save: "Uložit",
          cancel: "Storno",
          fieldwei: "Bit",
          fieldNot: "Jsou nevyplněné pole",
          fiedldlengthNo: "Délka pole přesahuje limit",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip: "Lze nastavit více klíčových slov, oddělených čárkami",
          applyRole: "Detekovat roli",
          preOperator: "Předoperátor",
          applyOperatorScope: "Rozsah detekce",
          addOperator: "Přidat operátor",
          noPreOperator: "Žádné",
          from: "Sekce",
          to: "Věta k č.",
          end: "Věta",
          testRegular: "Testovat regulární výrazy",
          test: "Test",
          matchSuccess: "Úspěšně zápas",
          matchError: "Zápas selhal",
          querySementicTags: "Vybrat sémantické štítky",
          reQuerySementicTags: "Znovu vybrat sémantické štítky",
          sementicTag: "Sémantické označení",
          standardSentence: "Standardní věta",
          similarity: "Podobnost",
          actions: "Operace",
          noFitTag: "Nenašel jsem tu správnou značku,",
          addSemanticTag: "Přidat novou sémantickou značku",
          pleaseEnterRegular: "Zadejte pravidelný výraz",
          pleaseEnterRegularText: "Zadejte prosím obsah, který má být ověřen.",
          newAssociateComponentTip:
            "Pokud je hodnota číselný typ, použijte funkci legacy",
        },
        filterManage: {
          noValueTip: "Žádné údaje",
          allCall: "Všechny volání",
          allDialogue: "Všechny dialogy",
          allWechat: "All sessions",
          getFiltersListFailed:
            "Selhalo získání seznamu filtrů, zkuste to znovu později!",
          getFilterDetailFailed:
            "Selhalo získání detailů filtru, zkuste to později znovu!",
          getAllCountFailed:
            "Nepodařilo se získat celkový počet hovorů/konverzací, zkuste to později znovu!",
        },
        listManage: {
          searchPlaceHolder: "Zadejte prosím vyhledávání klíčových slov",
          filterConditions: "Stav filtru",
          records: "Záznam",
          getRecordsFailed:
            "Nepodařilo se získat záznam, zkuste to znovu později!",
        },
        miniListManage: {
          getRecordsFailed:
            "Nepodařilo se získat záznam, zkuste to znovu později!",
        },
        applyTemplates: {
          title: "Použitelná šablona kontroly kvality",
          collectionTitle: "Použitelná šablona nabývání",
        },
        recordDetail: {
          showWaveAudio: "Zobrazit záznam",
          getDetailFailed:
            "Nedošlo k získání detailů, zkuste to později znovu!",
          getSmartTagsFailed:
            "Selhalo získání smart label, zkuste to znovu později!",
          getTemplatesFailed:
            "Selhalo získání použitelné šablony, zkuste to později znovu!",
        },
        changeList: {
          sampleStatus: "Stav odběru vzorků",
          sampleAssignInspector: "Přiřadit inspektora odběru vzorků",
          reviewAssignInspector: "Přiřadit hodnotitele",
          read: "Číst",
          distributivePerson: "Přidělená osoba",
          totalScore: "Celkové skóre",
        },
        qualityCheckScore: {
          totalScore: "Celkové skóre",
          actualScore: "Skóre",
          actualGrade: "Rating",
          appeal: "Stížnost",
          remark: "Poznámky",
          manualRemark: "Ruční poznámky",
          none: "Žádné podrobné údaje pro kontrolu kvality",
          interactiveTip:
            "Použít rozpoznávání interakcí k odhalení použití faq pro zákaznické služby",
          interactiveIsEmptyTip:
            "Žádný interaktivní rozpoznávací obsah nebyl zjištěn",
        },
        semanticMark: {
          semanticContent: "Označování věty",
          markedSimilarTitel: "Podobné sémantické štítky",
          markedExcludeTitle:
            "Označeno k vyloučení podobných sémantických štítků",
          taskTagTitle: "Sémantické štítky související s úlohou",
          similarTagTitle:
            "Identifikovat podobné sémantické značky (podobnost> 40%, top 20)",
          delete: "Smazat",
          markSimilar: "Označit podobné",
          markExclude: "Vyloučit podobné",
          none: "Žádné",
        },
        selectCallTime: {
          callTime: "Čas rozhovorů/počáteční čas sezení",
          callTimeRemind:
            "V posledním roce si můžete vybrat až jeden měsíc (31 dní) záznamů konverzace.",
          callRecordCount: "Vybrali jste celkem záznamy {0} hovoru/konverzace",
          getCallRecordError: "Nepodařilo se získat záznam, zkuste to později.",
        },
      },
      informationEntities: {
        title: "Subjekt informací",
        index: { import: "Importovat informační subjekty" },
        detail: {
          new: "Nová informační jednotka",
          edit: "Upravit subjekt informací",
          name: "Jméno",
          apiName: "Název api",
          activeFlag: "Účinnost",
          remark: "Popis",
          logicExp: "Entity regular",
          sentenceList: "Obsah subjektu",
          tip: "Obsah subjektu podporuje více položek, oddělených mezerami, které jsou synonymy stejné entity.",
          remarkLengthError: "Popis délka nesmí překročit 255",
          nameLengthError: "Délka názvu nesmí překročit 40",
          entityDuplicateError: "Duplikovaný obsah subjektu",
        },
      },
      interactiveRecognition: {
        title: "Interaktivní rozpoznávání",
        classify: "Klasifikace",
        addClassify: "Přidat kategorii",
        editClassify: "Upravit kategorii",
        index: { import: "Rozpoznávání interakcí importu" },
        detail: {
          new: "Nová inteligentní interakce",
          edit: "Upravit inteligentní interakci",
          type: "Typ",
          standardQuestion: "Standardní problémy",
          similarQList: "Podobný problém",
          exceptSimilarQList: "Odstranit podobné problémy",
          category: "Klasifikace",
          targetType: "Cíl detekce problémů",
          status: "Účinnost",
          standardAnswer: "Standardní odpověď",
          similarAList: "Podobné odpovědi",
          wrongAList: "Špatná odpověď",
        },
      },
      businessAnalysis: {
        title: "Obchodní analýza",
        tip1: "Business trend analysis: the vertical coordinate is the number of conversations, i. E. celkový počet rozhovorů, které splňují podmínky, a horizontální souřadnice je čas.",
        tip2: "Analýza korelace: analyzovat korelaci více ukazatelů, zobrazit údaje o korelaci, datový objem, korelace, rychlost opakování",
        tip3: "Analýza konverzí: analýza více ukazatelů může tvořit vzájemný konverzní vztah, konverzní kritéria pro stejné volání/konverzaci, pokud můžete zasáhnout dva různé ukazatele, můžete zvážit, že tyto dva ukazatele mají vztah k přeměně.",
      },
      knowledgeBase: {
        title: "Základna znalostí",
        list: {
          add: "Přidat podkategorii",
          delete: "Odstranit klasifikaci",
          categoryName: "Název kategorie",
          createSuccess: "Podkategorie vytvořená úspěšně",
          keyWord: "Klíčová slova",
        },
        detail: {
          type: "Typ",
          status: "Stav",
          classification: "Klasifikace",
          standardQuestion: "Standardní problémy",
          answerDetectionRange: "Rozsah detekce odpovědí",
          standardAnswer: "Standardní odpověď",
        },
      },
      callManage: {
        title: "Filtry pro nahrávání volání",
        listTitle: "Řízení volání",
        detailTitle: "Podrobnosti hovoru",
      },
      dialogueManage: {
        title: "Text filtru konverzace",
        listTitle: "Řízení dialogu",
        detailTitle: "Podrobnosti o dialogu",
      },
      weChatManage: {
        title: "Filtr sezení wechat enterprise",
        listTitle: "Enterprise wechat session management",
        detailTitle: "Informace o sezení wechat enterprise",
      },
      speechRecognition: {
        title: "Rozpoznávání řeči",
        asr: "Rozpoznávání řeči",
        asrRemind:
          "Vypnutí tohoto spínače způsobí, že funkce hlasu na text a funkce kontroly kvality stroje budou zakázány.",
        asrConfig: "Asr filtrace",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "Je-li povoleno, provede se transkripce asr im",
        asrConfigRemind:
          "Pokud je povoleno, spustí pouze způsobilé volání obsah asr",
        correction: "Inteligentní oprava chyb",
        correctionRemind:
          "When enabled, the content in \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words 'target =' _ blank'> smart thesaurus-professional nouns </a>\" bude použit k vynucení konverzace korekce chyb",
        correctionConfig: "Filtrování korekce chyb",
        advancedSettings: "Rozšířené nastavení",
        advancedSettingsTip: "Zvolí zadaný model asr pro zadané údaje.",
        mandarinASRModel: "Mandarin asr model",
        cantoneseASRModel: "Kantonský model asr",
        englishASRModel: "Anglický model asr",
        correctionConfigRemind:
          "Pokud je povoleno, budou opraveny pouze volání, které splňují následující podmínky.",
        customJudgeLogicIsNull: "Vlastní logika nemůže být prázdná",
        redoCorrection: "Znovu provést opravu chyb",
        callTime: "Vybrat čas hovoru",
        callTimeRemind:
          "V posledním roce si můžete vybrat až jeden měsíc (31 dní) záznamů o volání.",
        callRecordCount: "Vybrali jste celkem záznamy {0}",
        redoCorrectionSubmit: "Odeslat k provedení",
        callTimeError:
          "Zvolený časový rozsah volání nesmí překročit jeden měsíc (31 dní)",
        callRecordCountError:
          "Počet záznamů volání je 0, prosím znovu zvolte čas volání",
        redoCorrectionSuccess:
          "Oprava opakovaných chyb je úspěšná, prosím, přejděte do centra úloh pro zobrazení",
        redoCorrectionError:
          "Opakovaná chyba se nezdařila, zkuste to znovu později",
      },
      role: {
        title: "Řízení úloh",
        create: "Nová role",
        detail: {
          title: "Role",
          new: "Nová role",
          view: "Zobrazit role",
          edit: "Upravit roli",
          navTabNames: {
            basicInfo: "Oprávnění nabídky",
            taskPermissions: "Oprávnění úkolu kontroly kvality",
            dataPermissions: "Oprávnění pro data",
          },
          basicInfo: { roleName: "Jméno", permissions: "Oprávnění" },
          taskPermissions: {
            allTask: "Všechny úkoly kontroly kvality",
            myCreatedTask: "Úloha kontroly kvality, kterou jsem vytvořil",
          },
          dataPermissions: {
            dataPermissionsConfig: "Konfigurace oprávnění dat",
            tips: "Tato role může zobrazit data pod vybranou klasifikací",
            select: "Vybrat kategorii",
            selectPlaceholder:
              "Vyberte klasifikaci, která vyžaduje povolení k viditelnosti dat",
          },
        },
      },
      dataMigration: {
        title: "Migrace dat",
        dataUpload: "Migrace dat",
        dataDowload: "Migrace dat",
        error: "Prosím, vyberte kvalitní úkol pro export",
        list: {
          info: "Prosím, vyberte úlohu kontroly kvality, kterou chcete přesunout",
          listInfo:
            "Vyberte prosím, zda přesunout údaje související s úkolem kontroly kvality (např. inteligentní tezaur, sémantická značka, subjekt informací, inteligentní interakce atd.)",
          noInfo: "Please select whether to move out other non-qc data",
          ownInfo: "Přesunout pouze úkoly",
          everyInfo: "Související úloha přesunu",
          allInfo: "Přesunout ze všech",
          callFilter: "Filtr volání",
          talkFilter: "Filtr dialogu",
          smartTag: "Smart label",
        },
        upload: {
          info: "1. klepněte na tlačítko upload pro načtení souboru json, který odpovídá formátu.",
          uploadSuccess: "Nahrávat úspěšně",
          uploadError: "Načtení selhalo",
        },
      },
      dataAnalysis: {
        businessStatistics: "Podnikatelské statistiky",
        qualityEffect: "Kontrola kvality zdraví",
        qualityAnalysis: "Analýza kontroly kvality",
        generalInspecitionReport: "Obecná zpráva",
        fullAmountWork: "Pracovní zátěž inspektora kvality",
        intelligentTagsAnalysis: "Analýza štítků",
        saleIntelligentTagsAnalysis: "Analýza inteligentních štítků",
        generalInspecitionReportTip:
          "Celkové údaje: počítá celkový počet údajů o volání a konverzaci v rámci aktuálního časového limitu a rozsahu úkolů, včetně počtu a procenta údajů o inteligentní inspekci kvality, počet a procento údajů o manuálním odběru vzorků, počet odvolání, míra odběru vzorků a tabulka časového trendu.",
        groupTest: "Skupinový test",
      },
      uploadDownloadList: {
        title: "Mission center",
        tabs: {
          upload: "Nahrát import",
          download: "Stažení exportu",
          taskCenter: "Provedení úkolu",
        },
      },
      uploadDownloadModel: {
        title: "Stáhnout",
        list: {
          clear: "Prázdný",
          success: "Úspěch",
          fail: "Selhal",
          hand: "Probíhá",
          noInfo: "Žádné údaje",
        },
      },
      billingCenter: {
        title: "Fakturační centrum",
        export: "Export",
        limit: "Limit připomenutí",
        tips: "Výsledky vyúčtování budou v důsledku zaokrouhlování zpřísněné, pouze pro odkaz",
        dashboard: {
          remainingQuota: "Aktuální zbývající kvóta",
          usedQuota: "Kumulativní použitá kvóta",
          voiceQuality: "Kontrola kvality hlasu:",
          voice: "Hlas:",
          textQuality: "Kontrola kvality textu:",
          text: "Text:",
          hour: "Hodiny",
          tenThousandHour: "Milion hodin.",
          day: "Den",
          cutoff: "Cutoff",
          warning:
            "(The remaining quota is insufficient, please recharge as soon as possible)",
          isClosed: "(Uzavřený stav)",
        },
        table: {
          quotaRecord: "Záznam kvóty",
          timeFilter: "Vyberte datum:",
          search: "Dotaz",
          reset: "Resetovat",
          id: "Sériové číslo",
          type: "Typ",
          qualityType: "Typ kontroly kvality",
          quotaChange: "Změna",
          surplus: "Bilance",
          changeTime: "Čas",
          voiceQuality: "Kontrola kvality hlasu",
          textQuality: "Kontrola kvality textu",
          sizeChangeText: "Zobrazit na stránku",
          used: "Použití",
          give: "Grant",
          deduction: "Odpočet",
          callTime: "Čas mluvení",
          count: "Zpracovávání objemu dat",
          page: "Stránka {1} z {0}",
        },
        limitModal: {
          call: "Kvóta na kontrolu kvality hlasu je nižší",
          callFix: "Hodiny k připomenutí",
          dialogue: "Zbývající doba kontroly kvality textu je menší",
          dialogueFix: "Days to remind",
        },
      },
      messageCenter: {
        replyTitle: "Odpovědět na můj",
        answerTitle: "@ My",
        shareTitle: "Sdílet můj.",
      },
      columnsManage: { title: "Kontrola kvality řízení pole" },
      recordUpload: {
        list: {
          task: "Kontrola kvality úkol",
          user: "Operátor",
          uploadTime: "Čas načítání",
          endTime: "Konec času",
          total: "Total upload",
          success: "Úspěch kontroly kvality",
          failed: "Kontrola kvality selhala",
          progress: "Pokrok kontroly kvality",
          inProgress: "Probíhá",
          finished: "Dokončeno",
        },
        steps: {
          uploading: "Nahrát záznamový soubor",
          baseInfo: "Vyplňte základní informace",
          start: "Zahájit kontrolu kvality",
        },
        uploadComponent: {
          dragger: "Drag and drop to add files",
          or: "Nebo",
          selectFile: "Vybrat soubor",
          des: "Podpora wav, formát mp3, frekvence odběru vzorků 8000hz, 16 bitový soubor",
          limit1: "Nahrát nanejvýš jednou",
          limit2: "Soubory, každá velikost souboru nepřesahuje",
          uploading: "Nahrávání",
          finished: "Nahrávání dokončeno",
          success: "Úspěch",
          failure: "Selhal",
        },
        baseInfo: {
          task: "Vybrat kvalitní úkol",
          type: "Vybrat formulář pro nahrávání",
          agentSide: "Kanál, kde se nachází zákaznický servis",
          excel: "Po cestě načíst data",
          checkFileInfo: "Ověřit informace o souboru",
          excelFileError: "Please upload data along the way",
          checking: "Kalibrace v...",
          reCheck: "Rekalibrace",
          success: "Úspěch ověřování",
          excelUoloadFail: "Odesílání dat po trase se nezdařilo.",
          tips: {
            tip1: "1. název záznamového souboru v ecxel musí být stejný jako název nahraného záznamu a nemůže být stejným názvem.",
            tip2: "2. systém kontroluje duplikát s názvem záznamového souboru a id volání. Opakované údaje povede k selhání kontroly kvality.",
            tip3: "3. for the downloaded excel recording template, the font color is red, which is a required field.",
            tip4: "4. formát pole typu času je: yyyy-mm-dd hh:mm:ss, například 2021-04-20 17:36:30",
          },
        },
        title: "Místní nahrávání",
        checkTitle: "Kontrola kvality záznamu",
        start: "Zahájit kontrolu kvality",
        disabledTips: "Před kontrolou kvality ověřte záznamový soubor.",
        tips: "Záznam souboru se stejným názvem přepíše výsledky kontroly kvality a základní informace.",
        tips1: "Drag and drop the file here, or click upload",
        tips2: "Podpora",
        tips3: "Formát",
        type: "wav、mp3",
        tips4: "Nahrát nanejvýš jednou",
        tips5: "Soubory, každá velikost souboru nepřesahuje",
        tipsOverSize:
          "Aktuální fronta je plná, před nahráním odstrante soubory ve frontě.",
        size: "500M",
        count: "20",
        uploaded: "Nahraný záznam souboru",
        uploading: "({0}%)",
        uploadFinished: "(Upload complete)",
        bottomTitle: "Sběr informací o nahrávání:",
        recordType: "Vyberte formulář pro nahrávání:",
        double: "Dvoukanálový záznam hovoru",
        single: "Single channel call recording",
        recordBaseInfo: "Nahrávání záznamů základní informace:",
        addBtn: "Přidat soubor",
        downloadTemplate: "Šablona stahování. excel",
        checkRecord: "Ověřit informace o záznamu",
        checking: "Kalibrace v...",
        reCheck: "Rekalibrace",
        checkAll: "Vybrat vše",
        cancelCheckAll: "Zrušit vybrat vše",
        delete: "Smazat",
        deleteLotSize: "Smazat dávku",
        agentChannel: "Kanál zákaznické služby:",
        left: "Levý kanál",
        right: "Pravý kanál",
        donotKonw: "Já nevím.",
        mission: "Vyberte kvalitní úkol:",
        uploadFail: "Načtení selhalo",
        repeat: "Duplikovaný soubor",
        overCount: "Počet souborů přesahuje limit",
        overSize: "Velikost souboru přesahuje limit",
        uoloadFail: "Nepodařilo se nahrát do cloudu",
        formatError: "Chyba formátu",
        seccess: "Úspěch ověřování",
        fail: "Ověření selhalo",
        recordChecking: "Nahrávání při inspekci kvality, prosím později...",
        recordCheckSuccess: "Kontrola kvality záznamu dokončena",
        recordCheckFail:
          "Kontrola kvality záznamu se nezdařila, zkontrolujte, zda je záznam duplikovaný nebo neplatný.",
        backToIndex: "Vrátit se do seznamu nahrávání",
        recordCheckIsStarted:
          "V současné době se provádí kontrola kvality. Po dokončení aktuální úlohy spusťte novou úlohu.",
      },
      customerServicePortrait: {
        title: "Zákaznická služba portrét",
        tip1: "Distribuční zákon délky věty: rozdělení délky věty se počítá v segmentech podle počtu slov v délce věty a pouze zákaznické služby",
        tip2: "Zákon o distribuci rychlosti: distribuce rychlosti podle rychlosti (slova/minuta), statistiky segmentu vypočítá pouze zákaznický servis",
        tip3: "Právo distribuce doby odezvy: rozložení času odezvy se vypočte pouze situace odezvy zákaznické služby.",
        tip4: "Dodržování kontroly jakosti: míra dodržování kontrolních míst jakosti",
        tip5: "Průměrné skóre úkolů kontrol kvality: průměrné skóre úkolů kontrol kvality",
        contol: {
          date: "Vyberte datum:",
          today: "Dnes",
          thisMonth: "Tento měsíc",
          lastMonth: "Minulý měsíc",
          thisQuarter: "Toto čtvrtletí",
          lastQuarter: "Poslední čtvrtletí",
          thisYear: "Tento rok",
          lastYear: "Minulý rok",
          customer: "Zákaznický servis:",
          search: "Dotaz",
          reset: "Resetovat",
          export: "Export",
          indicator: "Řízení ukazatelů",
          selectPlaceholder:
            "Vyberte prosím zákaznický servis, který je třeba porovnat",
          warning: "Vyberte až 5 agentů pro srovnání",
          warning2: "Prosím, vyberte alespoň jeden agent",
          dataWarning:
            "Pouze data do 366 dnů po počátečním datu mohou být dotazována.---* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *",
          seccuss: "Úspěšný export záznamu",
        },
        echarts: {
          distributionLaw: "Právo distribuce",
          count: "Počet časů",
          bout: "Times",
        },
        portrait: {
          ability: "Rozměr schopnosti zákaznické služby",
          option: "Konfigurace",
          abilityOption: "Konfigurovat rozměry schopnosti",
          placeholder:
            "Vyhledávání klíčových slov pro dimenzi vstupní schopnosti",
          save: "Uložit",
          warning:
            "Please select at least 3 indicators to configure, and you can select up to 10 indicators",
        },
        label: { title: "Štítek služby zákazníkům" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Hodnocení zákaznických služeb",
        select: {
          date: "Vyberte datum:",
          today: "Dnes",
          thisMonth: "Tento měsíc",
          lastMonth: "Minulý měsíc",
          thisQuarter: "Toto čtvrtletí",
          lastQuarter: "Poslední čtvrtletí",
          thisYear: "Tento rok",
          lastYear: "Minulý rok",
          group: "Skupina zákaznických služeb:",
          groupPlaceholder: "Vyberte skupinu agentů.",
          customer: "Zákaznický servis:",
          customerPlaceholder: "Please select customer service",
          search: "Dotaz",
          reset: "Resetovat",
          compared: "Kontrast",
          export: "Export",
          column: "Sloupec",
          warning: "Vyberte až 5 agentů pro srovnání",
          warning2: "Vyberte alespoň jednoho agenta.",
          dataWarning:
            "Pouze data do 366 dnů po počátečním datu mohou být dotazována.---* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *",
          selected: "Vybraný agent:",
          seccuss: "Úspěšný export záznamu",
        },
        compared: {
          title:
            "Vybrat zákaznický servis (18 + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +",
          return: "Storno",
          compared: "Kontrast",
          tips: "Please select 2-5 agents for comparison",
          warning: "Pouze 2-5 agentů jsou podporovány pro srovnání",
        },
        transfer: {
          leftTitle: "Položka seznamu",
          rightTitle: "Zobrazit položku",
        },
        table: {
          page: "Stránka {1} z {0}",
          actionTitle: "Operace",
          action: "Zobrazit",
        },
      },
      conceptWord: {
        title: "Koncepční slova",
        new: "Nové slovo konceptu",
        control: {
          search: "Hledat:",
          searchPlaceholder: "Hledání názvu nebo klíčového slova",
          commit: "Dotaz",
          reset: "Resetovat",
          import: "Import",
          export: "Export",
          seccuss: "Úspěšný export záznamu",
        },
        modal: { title: "Importovat koncept slov" },
        table: {
          sizeChangeText: "Zobrazit na stránku",
          page: "Stránka {1} z {0}",
          edit: "Upravit",
          delete: "Smazat",
          request:
            "Po odstranění nemůže být obnovena. Opravdu ho chcete odstranit?",
          cancel: "Storno",
          title: {
            conceptWord: "Koncepční slova",
            describeWordsNum: "Počet popisů",
            updateTime: "Čas aktualizace",
            status: "Účinnost",
            effective: "Efektivní",
            invalid: "Neplatný",
            action: "Operace",
          },
        },
        detail: {
          title: {
            new: "Nové slovo konceptu",
            edit: "Upravit koncepční slova",
          },
          name: "Název pojmového slova",
          namePlaceholder: "Zadejte název koncepčního slova.",
          effectiveness: "Účinnost",
          effective: "Efektivní",
          invalid: "Neplatný",
          description: "Popisující slova:",
          descriptionPlaceholder: "Zadejte popisovač",
          add: "Přidat",
          repeat: "Duplikovaný obsah",
          empty: "Popisující slova nemohou být prázdná",
          recommendEmpty:
            "Žádné další doporučené popisy, zadejte a přidejte je ručně.",
          recommend: "Popisovač inteligentních doporučení",
          cancel: "Storno",
          change: "Změnit skupinu",
          descriptionSelected: "Přidaný popis:",
          save: "Uložit",
          success: "Koncepční slova úspěšně uložena",
          warning: "Název pojmového slova nemůže být prázdný",
          warning2: "Prosím přidat alespoň 1 deskriptor",
          submitBtnHandle: {
            title:
              "Vybraný popis nebyl přidán. Ukládání se zlikviduje nepřidaný deskriptor.",
            content:
              "Klepnutím na tlačítko ok se vrátíte na domovskou stránku koncepčního slova, potvrďte pokračování?",
          },
        },
      },
      smartTools: {
        btn: "Inteligentní nástroje",
        title: {
          similarWords: "Nalezeno souvisejících slov",
          search: "Podle slova",
          semanticTags: "Sémantické označení",
        },
        sentenceSearch: {
          placeholder:
            "Zadejte hledání klíčových slov, podporujte více klíčových slov",
          more: "Zobrazit celý text",
          pageUp: "Předchozí stránka",
          pageDown: "Další stránka",
          total: "Total {0} records",
        },
        similarDiscover: {
          placeholder: "Zadejte prosím klíčové slovo",
          copyed: "Zkopírován",
          none: "Žádné relevantní slova, prosím, zkuste jiné klíčové slova",
        },
        smartTags: {
          searchPlaceHolder: "Zadejte prosím klíčová slova nebo věty",
          createSmartTag: "Nový",
          createClusteringSmartTag: "Vytvořit nové údaje s {0}",
          addFromKeyword: "Přidat obsah hledání do podobných vět",
          hit: "Hit",
          similar: "Podobné",
          name: "Název sémantického štítku",
          namePlaceHolder: "Zadejte název sémantického štítku",
          recallWords: "Recall word",
          recallWordsPlaceHolder: "Zadejte prosím klíčové slovo",
          recallRegular: "Recall regular",
          recallRegularPlaceHolder: "Please enter recall regular",
          rules: "Pravidla",
          standardSentences: "Standardní věta",
          standardSentencesPlaceHolder: "Zadejte standardní větu",
          similaSentences: "Podobné věty",
          similaSentencesPlaceHolder: "Zadejte podobnou větu.",
          notSaveError: "Před vyhledáváním prosím uložte sémantický štítek",
          add: "Přidat",
          newPage: "Otevřít v nové stránce",
          querryError:
            "Podobné pole existují v seznamu generalizací (rozšířená konfigurace podobných omezení deduplikace)",
          detailTitle: "Sémantické detaily štítku",
        },
      },
      trainingCenter: {
        title: "Výcvikové centrum",
        edit: "Upravit",
        delete: "Smazat",
        seleteAll: "Vybrat vše",
        cancelCheckAll: "Zrušit vybrat vše",
        add: "Přidat",
        remove: "Odstranit",
        save: "Uložit",
        cancel: "Storno",
        fastTraining: {
          title: "Rychlý trénink",
          titleDes:
            "Rychlý trénink je navržen tak, aby pomohl sémantické štítky rychle generalizovat na 20 podobných vět.",
          semanticTags: "Sémantické označení",
          semanticTagsPlaceholder: "Zadejte prosím vyhledávání klíčových slov",
          standardSentence: "Standardní věta:{0}",
          similarSentencesPlaceholder:
            "Zadejte podobné věty pro přidání nebo vyhledávání",
          similarSentencesAdd: "Přidat",
          similarSentencesSearch: "Hledat",
          similarSentences: "Přidáno podobné věty",
          trainingCount: "Počet výcviků:{0}",
          similarSentencesRecommend: "Doporučené podobné věty",
          noResp:
            "Bez relevantního sémantického štítku, prosím změňte klíčové slovo",
          similarSentencesModalTitle: "Podobné vyhledávání věty",
          moreInfo: "Zobrazit kontext",
          moreInfoBtn: "Kontext",
          modalSearchType: {
            all: "All",
            customer: "Zákaznický servis",
            client: "Zákazník",
          },
          repetWarning:
            "Stejná podobná věta již existuje, prosím, nepřidávejte ji opakovaně.",
          repetRemoveTip:
            "There is duplicate content in the saved similar sentence, which has been automatically deduplicated.",
        },
        trainingHistory: {
          title: "Historie výcviku",
          select: {
            date: "Zvolte čas:",
            today: "Dnes",
            thisMonth: "Tento měsíc",
            lastMonth: "Minulý měsíc",
            thisQuarter: "Toto čtvrtletí",
            lastQuarter: "Poslední čtvrtletí",
            thisYear: "Tento rok",
            lastYear: "Minulý rok",
            search: "Dotaz",
            reset: "Resetovat",
          },
          table: {
            time: "Čas",
            user: "Operátor",
            tag: "Sémantické označení",
            similarCount: "Počet podobných vět",
            deleteCount: "Počet odstraněných vět",
            page: "Stránka {1} z {0}",
          },
        },
        classifiedTraining: {
          title: "Klasifikační výcvik",
          tagsGrounp: "Seskupování štítků",
          placeHolder: "Vyberte prosím",
          cellTime: "Vybrat čas",
          search: "Dotaz",
          reset: "Resetovat",
          classifiedFlag: "Klasifikační výcvik",
          num: "Číselné",
          gas: "Procento",
          noData: "Neutajované školení",
          noDataHeight:
            "Neutajované údaje jsou vysoké a je naléhavě zapotřebí školení.",
          noDataSmall:
            "Neutajované údaje představují poměrně vysoký podíl a doporučuje se trénink.",
          noDataDays: "{0} dny před posledním provedením",
          training: "Výcvik",
          start: "Začátek",
          addClass: "Přidat klasifikaci",
          custom: "Rozšířené filtrování",
          marked:
            "Označená data errata (* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *",
          markedHeight:
            "Errata nebyla provedena po {0} dny a doporučuje se tak udělat.",
          markedSmall:
            "Errata nebyla provedena po {0} dny. Chybu je naléhavě zapotřebí.",
          mark: "Errata",
          dataCheck: "Ověřování utajovaných dat",
          dataCheckSmall:
            "K-L divergence je vyšší než 0,5, doporučuje se trénovat.",
          dataCheckHeight:
            "K-L divergence je vyšší než 0,8 a je naléhavě zapotřebí školení.",
          check: "Kalibrace",
          history: "Historie volání",
          historyTime:
            "Daily annotation and modification will automatically perform model training at 2:00 am",
          today: "Tagged today",
          article: "Článek",
          none: "Žádné",
          historyCheck: "Historical total callout",
          look: "Zobrazit záznamy",
          historyluate: "Přehodnocování historických údajů",
          startluate: "Výpočet jeřábu",
          classData: "Klasifikované údaje",
          classStatus: "Postup výcviku",
          next: "Další článek",
          prev: "Předchozí článek",
          finish: "Kompletní výcvik",
          stop: "Konec výcviku",
          classDetail: "Podrobnosti o výcviku",
          trainingNone: "Žádný trénink pro smart label",
        },
        classifiedTrainingHistory: {
          title: "Historie výcviku",
          time: "Zvolte čas:",
          tag: "Typ štítku:",
          user: "Obsluha:------------------------------------------------------------------------------------------------------------------------",
          data: "Vyberte zdroj dat:",
          search: "Dotaz",
          searchBar: "Hledat:",
          searchBarPlaceholder: "Zadejte id volání/id konverzace",
          reset: "Resetovat",
          export: "Export",
          all: "All",
          select: {
            today: "Dnes",
            thisMonth: "Tento měsíc",
            lastMonth: "Minulý měsíc",
            thisQuarter: "Toto čtvrtletí",
            lastQuarter: "Poslední čtvrtletí",
            thisYear: "Tento rok",
            lastYear: "Minulý rok",
            search: "Dotaz",
            reset: "Resetovat",
          },
          table: {
            user: "Operátor",
            date: "Doba operace",
            data: "Zdroj dat",
            id: "Id volání/id konverzace",
            tagType: "Inteligentní seskupování štítků",
            tagName: "Název štítku",
            state: "Stav",
            sizeChangeText: "Zobrazit na stránku",
            page: "Stránka {1} z {0}",
            call: "Volání",
            dialogue: "Dialog",
          },
        },
        clusteringAnnotation: {
          title: "Annotace clusterování",
          left: { title: "Výsledky clusterování" },
          right: {
            title: "Klasterování vět",
            checkAll: "Vyberte tuto stránku",
            tagging: "Callout",
            detail: "Zobrazit",
            meaningless: "Ignorovat",
            meaninglessTips:
              "Přehlížené výsledky clusterování se v budoucnu nebudou zobrazovat. Jsi si jistá, že je chceš ignorovat?",
            cancel: "Storno",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Správa zákazníků",
          tabTitle: "Zákazník servisního centra",
        },
        wechatClientManage: {
          title: "Enterprise micro customer",
          table: {
            id: "Id zákazníka",
            name: "Přezdívka zákazníka",
            tag: "Štítek zákazníka",
          },
        },
      },
      collectionRule: {
        title: "Pravidla sběru",
        form: {
          info: "Sběr inteligentních informací",
          infoPlaceholder: "Vyberte kategorii",
        },
        table: {
          name: "Název pole",
          props: "Atributy zákazníka",
          type: "Typ pole",
          desc: "Popis pole",
        },
      },
      dataAdd: {
        title: "Přidat data",
        types: { auto: "Přidat automaticky", manual: "Přidat ručně" },
      },
      wechatStaff: {
        table: {
          name: "Jméno",
          part: "Oddělení",
          phone: "Číslo mobilního telefonu",
          email: "Poštovní schránka",
          wechartId: "Id enterprise wechat",
          status: "Stav práce",
        },
        info: "Vyberte oddělení, které musí nejprve synchronizovat data.",
        synSuccess: "Synchronizace byla úspěšná",
        init: "Inicializace",
        syn: "Synchronizace",
      },
      appealHistory: {
        appealTime: "Čas odvolání",
        cancelTime: "Čas odvolání",
        reviewTime: "Čas přezkumu",
        inspector: "Inspektor kvality",
        result: "Výsledek stížnosti",
        complainResult:
          "Celkem byly odvolány {0} položky kontroly kvality, {1} byly úspěšně odvolány a {2} byly zamítnuty.",
        reason: "Důvod stížnosti",
        rejection: "Důvod pro odmítnutí",
        cancel: "Zrušen",
        tip: "Potvrdit stažení stížnosti?",
      },
      appealSdk: {
        time: "Čas dialogu",
        externalIdCall: "Id záznamu volání",
        externalIdText: "Id záznamu konverzace",
        customerNickName: "Přezdívka zákazníka",
        readStatus: "Stav přezkumu",
        status: "Stav stížnosti",
        isManual: "Ruční kontrola kvality",
        complainButtonInfo:
          "{0} povolené odvolání, {1} již učiněné odvolání, {2} zbývající odvolání. {3} může se odvolávat před",
        submit: "Podat stížnost",
        complain: "Stížnost",
        allComplain: "Všechny stížnosti",
        pendingSubmit: "K předložení",
        taskIds: "Kontrola kvality úkol",
        graded: "Rating",
        complainSuccess: "Stížnost předložená úspěšně",
        appealSdkIndex: { title: "Inteligentní výsledky kontrol kvality" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Selhalo získání informací ze seznamu filtrů, zkontrolujte síťové připojení nebo akci znovu později!",
      columns: "Sloupec",
      filter: "Filtr",
      noSearchResult: "Nebyly nalezeny žádné záznamy",
      pleaseSearch: "Vyberte filtr a zkuste hledat",
      sift: "Filtr",
      create: "Nový",
      search: "Hledat",
      createTaskTitle: "Nová úloha",
      title: "Název",
      deadline: "Lhůta",
      principal: "Odpovědná osoba",
      enqueueTime: "Čas vstupu",
      computeError: "Výpočet selhal, prosím počkejte!",
      sum: "Součet",
      max: "Maximum",
      min: "Minimum",
      avg: "Průměr",
      count: "Hrabě",
      maxDate: "Maximum (latest)",
      minDate: "Minimum (nejstarší)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Selhalo získání adresy odkazu insight, zkuste to později znovu!",
      },
      template: {
        learningReminder: "Vzpomínka na učení",
        subscriptionDynamics: "Dynamics of subscription",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Správa úkolů", chaneg: "Přepínač" },
        qualityCheckTemplate: {
          text: "Šablona kontroly kvality",
          collectText: "Šablona nabývání",
          condition: { text: "Podmínky kontroly kvality" },
          checkPoint: { text: "Nastavení pravidla" },
          automaticAdd: { text: "Automatizace procesu" },
          manualAdd: { text: "Přidat ručně" },
          roleConfig: { text: "Konfigurace role" },
          basicInfo: { text: "Základní informace" },
          smartWordsLibrary: { text: "Inteligentní thesaurus" },
          grade: { text: "Hodnocení kontroly kvality" },
          collectionRule: { text: "Pravidla sběru" },
          addData: { text: "Přidat data" },
          collectionList: { text: "Seznam akvizic" },
        },
        qualityCheckWorkbench: {
          text: "Pracovní stůl kontroly kvality",
          wechatText: "Analytická pracovní stůl",
          informationCollectText: "Obstarávací pracovní panel",
          qualityInspectionList: {
            text: "Seznam kontrol kvality",
            wechatText: "Seznam analýz",
            informationCollectText: "Seznam akvizic",
          },
          condition: { text: "Seznam kontrol kvality (zastaralé)" },
          reviewList: { text: "Přehledový seznam" },
          spotCheckList: { text: "Seznam odběrů vzorků" },
          caseBaseList: { text: "Knihovna případů" },
          testSetList: { text: "Zkušební sada kontrol kvality" },
        },
        statisticsAndReports: {
          text: "Statistiky a zprávy",
          wholeReport: { text: "Celková zpráva" },
          groupReport: { text: "Zpráva skupiny pro služby zákazníkům" },
          agentReport: { text: "Zpráva o servisu zákazníků" },
          customerServiceExecution: { text: "Analýza zákaznických služeb" },
          analysisOverview: { text: "Přehled analýzy" },
          inspectionItemsAnalysis: { text: "Analýza položek kontroly kvality" },
        },
        wechatQa: {
          text: "Enterprise wechat kontrola kvality",
          task: { text: "Úloha analýzy" },
          baseInfo: { text: "Základní informace" },
          checkPoint: { text: "Konfigurace modelu analýzy" },
          setting: { text: "Konfigurace analýzy aplikace wechat" },
          automaticAdd: { text: "Automatizace procesu" },
          manualAdd: { text: "Přidat ručně" },
        },
        clientCenter: { text: "Centrum služeb zákazníkům" },
      },
      conditionDictionaries: {
        title: "Všechny podmínky",
        searchDefaultText: "Zadejte id nebo název",
        id: "ID",
        name: "Jméno",
        conditionDetailedShow: "Rozšířit",
        conditionDetailedHide: "Stow",
        remark: "Popis:",
        checkRole: "Role:",
        operator:
          "Obsluha:------------------------------------------------------------------------------------------------------------------------",
        operatorLogic: "Logika:",
      },
      audioTextView: {
        customer: "Zákazník",
        agent: "Zákaznický servis",
        unsupportedFormat: "Tento formát není podporován.",
      },
      checkPointClassifications: {
        add: "Přidat kategorii",
        name: "Jméno",
        enterToSave: "Stiskněte enter pro uložení",
        highestScore: "Maximální skóre cap",
        minimumScore: "Minimální skóre dolní limit",
        defaultMinScore: "Výchozí hodnota je 0 bodů",
        minimumScoreTitle: "Nižší limit skóre",
        beginScore: "Celková výchozí hodnota skóre",
        beginScoreTitle: "Počáteční hodnota skóre",
        scoreSetTip:
          "The maximum score is less than the sum of all scores. Můžete vybrat:",
        ignoreExcessSpillover: "Ignorovat přebytek",
        automaticWeightCalculation: "Automatické účtování hmotnosti",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Pole" },
    checkRange: {
      numberTipOne: "Sekce",
      numberTipTwo: "Věta",
      numberTipThree: "Věta k č.",
      conditionLimit: "Podmíněné omezení",
      roleLimit: "Omezení role",
      contextLimit: "Omezení věty",
      notZero: "Vstup 0 není podporován pro omezení věty",
      startMustLessThanEnd:
        "Pozice počáteční věty musí být menší než pozice konečné věty",
    },
    waveAudio: {
      play: "Play",
      pause: "Pauza",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Zadejte prosím",
      pleaseEnterRegular: "Zadejte pravidelný výraz",
      pleaseEnterGrammar: "Prosím, zadejte gramatické pravidlo",
      pleaseEnterTestText: "Zadejte prosím obsah, který má být ověřen.",
      pleaseEnterLoudness: "Prosím, zadejte hlasitost decibel",
      pleaseEnterRangeAbility: "Zadejte rozsah změny",
      afterHit: "Kontrola po stisku",
      pleaseEnterContext: "Prosím, zadejte referenční výkaz",
      wordNumber: "Slova mezera",
      intervalSecond: "Časový interval větší",
      minute: "Každou minutu.",
      wordUnit: "Slovo",
      test: "Test",
      testRegular: "Testovat regulární výrazy",
      testGrammar: "Testovací gramatika",
      testGrammarError: "Zkouška gramatiky selhala",
      regularError: "Chyba pravidelného výrazu, prosím znovu zadejte",
      pleaseEnterSemanticSentence:
        "Zadejte větu, která vyžaduje sémantickou detekci.",
      pleaseEnterAssociate: "Viz návod na právo k vytvoření výkazu data-along",
      querySemanticTags: "Vybrat sémantické štítky",
      reQuerySemanticTags: "Znovu vybrat sémantické štítky",
      semanticTags: "Sémantické označení",
      standardSentence: "Standardní věta",
      semanticName: "Sémantické označení",
      sentence: "Standardní věta",
      similarity: "Podobnost",
      actions: "Operace",
      tipOne: "Nenašel jsem tu správnou značku,",
      tipTwo: "Přidat novou sémantickou značku",
      notLessThan: "Ne méně",
      notGreaterThan: "Ne větší",
      ignoreLessThan: "Ignorovat méně",
      wordUnitSet: "Věta slov",
      speedWordNotNull:
        "Minimální počet slov a maximální počet slov při detekci rychlosti řeči nemůže být oba prázdné.",
      speedWordMinValueLessThanMaxValue:
        "Minimální počet slov při detekci rychlosti řeči nemůže být větší než maximální počet slov.",
      speechGrabbingIntervalTip: "Interval> =",
      speechLengthTip: "Věta je dlouhá> =",
      msUnit: "ms",
      sUnit: "Sekundy",
      grabIntervalNotNull: "Interval odtržení nemůže být prázdný.",
      grabLengthNotNull: "Věta nemůže být prázdná.",
      pleaseSelectEntity: "Vyberte informační subjekt",
      pleaseSelectOperator: "Vyberte akci",
      pleaseSelectBasicInfo: "Vyberte základní informace",
      pleaseEnterFixedValue: "Zadejte pevnou hodnotu",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Úspěšně zápas",
      matchError: "Zápas selhal",
      mindetectionStatement: "Minimální výkaz detekce",
      conceptWordholder: "Prosím, vyberte koncepční slovo",
      elementHolder: "Zadejte prosím prvek",
      associateMedol: { tabs: { data: "Data", operator: "Operátor" } },
      repet: "Opakovat",
      bout: "Times",
      ignore: "Ignorovat méně",
      ignoreAffix: "Věta slov",
      associateData: "Údaje o silnici",
      pretreatmentData: "Předzpracování dat",
      reviewTaskData: "Přehodnotit data úloh",
      taskData: "Údaje o úkolech kontroly kvality",
      businessData: "Obchodní záznamy",
      functionOperator: "Operátory funkcí",
      operation: "Operátor operace",
      notAnyMatch: "When any issues are not hit:",
      times: "Počet výskytů",
      newAssociateDataTip:
        "Pokud je hodnota číselný typ, použijte funkci legacy",
    },
    conditionGather: {
      cycle: "Cyklus",
      rlue: "Pravidla",
      delete: "Odstranit",
      sampling: "Extrakce",
      item: "Článek",
      percent: "%",
      samplingSame: "Stejný počet extrakcí na agenta",
      samplingRecheck: "Povolit opakovanou extrakci vzorků",
      dateUnit: "Ne.",
      samplingTip:
        "Různá pravidla zahrnující stejný hovor nebudou vytažena opakovaně a záznamy, které byly extrahovány, ale nebyly kontrolovány, nebudou znovu extrahovány.",
      add: "Nové pravidlo",
    },
    ruleGather: {
      title: "Pravidla",
      screeningCondition: "Stav filtru",
      addRule: "Nové pravidlo",
      inspector: "Inspektor kvality",
      ruleTip:
        "Režim first alokace pravidla, od shora dolů, neopakuje přidělení, v rámci pravidla nebo neshoduje s žádnými položkami pravidla, které budou náhodně přiděleny.",
      moveUp: "Přesunout nahoru",
      moveDown: "Posunout dolů",
      top: "Top",
    },
    pushRulesGather: {
      title: "Pravidla",
      delete: "Odstranit",
      newPush: "New push",
      newRule: "Nové pravidlo",
      sampling: "Extrakce",
      item: "Článek",
      percent: "%",
      samplingSame: "Stejný počet extrakcí na agenta",
      dateUnit: "Ne.",
      screeningCondition: "Stav filtru",
      email: "Poštovní schránka",
      emailPlaceholder: "Zadejte poštovní schránky oddělené čárkami",
      updateType: "Typ aktualizace",
      pushDetail: "Tlačit podrobnosti",
      pushUrl: "Push url",
      pushWay: "Typ tlačítka",
      pushMethod: "Režim tlačení",
      pushContentType: "Formát dat",
      pushData: "Push data",
      additionalData: "Dodatečné údaje",
      inspectionType: "Typ kontroly kvality",
      all: "All",
    },
    taskAdditionalData: {
      newData: "Nové údaje",
      delete: "Odstranit",
      placeholder: "Zadejte název",
      dataPlaceholder: "Zadejte prosím obsah",
    },
    UdeskQaReact: {
      loadSdkError: "Načtení sdk selhalo, zkuste to později znovu!",
    },
    gearOptionList: { evaluation: "Hodnocení" },
    tableComponentTransfer: {
      leftTitle: "Položka seznamu",
      rightTitle: "Zobrazit položku",
    },
    kmTinymce: {
      link: "Vložit/upravit odkaz",
      modalTitle: "Vložit odkaz",
      href: "Adresa",
      text: "Zobrazit text",
      title: "Název",
      target: "Režim otevření",
      none: "Žádné",
      newWindow: "Otevřít v novém okně",
      buttonStyle: "Styl tlačítka",
      close: "Close",
      open: "Otevřít",
      answerMessage: "Skočit/odeslat zprávu",
      answerMessageTitle: "Tlačítko odeslat zprávu",
      answerMessageText: "Zobrazit text",
      answerMessageContent: "Obsah zprávy",
      answerMessageButtonStyle: "Styl tlačítka",
      answerMessageTips:
        "Poznámka: tlačítko odesílat zprávu podporuje pouze webový kanál, prosím ho pečlivě nastavte.",
      answerSwitchStaff: "Příručka jump/turn",
      answerSwitchStaffTitle: "Přepnout manuální tlačítko",
      answerSwitchStaffText: "Zobrazit text",
      answerSwitchStaffRule: "Pravidla tlačítka",
      answerSwitchStaffButtonStyle: "Styl tlačítka",
      answerSwitchStaffTips:
        "Poznámka: ruční tlačítko vypínač podporuje pouze webový kanál.",
      textButton: "Tlačítko text",
      borderButton: "Zarámované tlačítko",
      message: "Odeslat zprávu",
      switchStaff: "Obrat manuál",
      messageError: "Obsah tlačítka nemůže překročit 10 slov",
      messageIsEmptyError: "Obsah tlačítka nemůže být prázdný",
      okText: "Určit",
      cancelText: "Storno",
      video: "Video",
      uploadVideo: "Nahrát video",
      uploadText: "Podporuje pouze formát mp4, velikost ne více než 20m",
      videoEmptyOrUploading: "Video je prázdné nebo nahrává",
      customEmoji: "Výraz",
    },
    massageConfiguration: {
      pushTarget: "Cíl oznamování",
      customizePushTarget: "Vlastní tlačítko určení",
      sysUser: "Aktuální uživatel systému",
      sysRole: "Aktuální role systému",
      emailPushTarget: "Odeslat určení",
      targetEmailsPlaceholder: "Zadejte poštovní schránky oddělené čárkami",
      subject: "Název zprávy",
      SDKID: "Id sdk",
      content: "Obsah oznámení",
      sdkPushType: "Push schéma",
      sdkPushURL: "Push url",
      pushData: "Push data",
      sdkPushWay: "Režim tlačení",
      dataType: "Formát dat",
      additionalData: "Dodatečné údaje",
      name: "Název zprávy",
      filter: "Stav filtru",
      triggerType: "Spouštěcí mechanismus",
      pushCycle: "Načasování pevné frekvence",
      pushContent: "Obsah sestavy",
      qualityList: "Seznam výsledků kontrol kvality",
      designated: "Určený personál",
      messageType: "Typ zprávy",
    },
    taskCard: {
      template: { cancelCollection: "Zrušit oblíbený", collection: "Sbírka" },
    },
    semanticTagsSelect: { index: { standardSentence: "Standardní věta:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Sbírka",
          calls: "A call",
          createdBy: "Vytvořeno:",
          createdOn: "Čas vytvoření:",
        },
        fileItem: {
          uIProvidesIcon: "Uživatelské rozhraní poskytuje ikonu",
          minute: "Minuty",
          callClip: "Fragment volání",
          start: "Začátek",
          End: "Konec",
          employees: "Zaměstnanci:",
          Customer: "Zákazník:",
          sessionTime: "Čas sezení:",
          reasonForRecommendation: "Důvod doporučení:",
          addedBy: "Přidáno:",
          addTime: "Přidat čas:",
        },
        modalAddFile: {
          caseTitle: "Název případu",
          addSomeClipsToTheCaseLibrary:
            "Přidat částečné úryvky do knihovny případů",
          addToCaseBase: "Přidat do knihovny případů",
          storedIn: "Uloženo v",
          pleaseSelect: "Vyberte prosím",
          reasonsForRecommendation: "Důvod pro doporučení",
          pleaseEnter: "Zadejte prosím",
        },
      },
      shareModal: {
        index: {
          customer: "Zákazník:",
          employees: "Zaměstnanci:",
          timeliness: "Včasnost:",
          recordingLinkValidWithinDays: "Záznam odkaz platný do dnů",
          sharingInstructions: "Popis sdílení:",
          shareSuccess: "Sdílení úspěchu!",
          cancel: "Storno",
          share: "Sdílet",
          copySucceeded: "Kopírovat úspěšně!",
          copyShareLink: "Kopírovat odkaz na sdílení",
          shareWithColleagues: "Sdílet s kolegy",
          shareWithCustomers: "Sdílet s zákazníky",
          staff: "Zaměstnanec",
          selectColleagues: "Vybrat kolegy",
          pleaseSelectColleaguesToShare: "Prosím, vyberte kolegu, který sdílí",
          pleaseEnterTheSharingDescription: "Zadejte prosím pokyny pro sdílení",
          taskName: "Úkoly:",
          recordingShareValidWithinDays: "Sdílení odkazu platí do dnů",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Soubor nelze nalézt!",
          sale: "Prodej:",
          customer: "Zákazník:",
          topicDetection: "Detekce témat:",
          keyEvents: "Klíčové události:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Vybrat kolegy",
          helpContent: "Obsah nápovědy",
          visibleRange: "Viditelný rozsah",
          seekCounseling: "Hledat poradenství",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Vyhledejte radu", concatText: "Do" },
      },
      customerInsight: {
        index: {
          customerInformation: "Informace o zákazníkovi",
          customerInsight: "Výhled zákazníků",
          customerLabel: "Štítek zákazníka",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Doporučená slova",
          objectionHandlingScripts: "Vyřizování námitek",
          productSellingPointScripts: "Prodejní body produktu",
          knowledgePointRecommendation: "Doporučení znalostního bodu",
          relatedKnowledgePoints: "Přidružené znalostní body",
          recommendedCases: "Doporučené případy",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Doporučená slova",
          caseRecommendation: "Doporučení případu",
          hit: "Hit",
          miss: "Zmizela",
          supervisionItem: "Položky dohledu",
          implementation: "Stav implementace",
          reInspection: "Opětovná kontrola",
          supervisionItemSettings: "Nastavení položky dohledu",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Telefonní hovory",
          enterpriseWeChatCall: "Enterprise micro-talk",
          enterpriseMicroConversation: "Enterprise micro-session",
          open: "Otevřít",
          close: "Close",
          conversationScenario: "Scénáře sezení",
          monitorConversationChannels: "Dohlížet na kanál konverzace",
          permanentlyValid: "Trvale účinný",
          permanentlyInvalid: "Trvale neplatný",
          customValidity: "Vlastní doba platnosti",
          termOfValidity: "Doba platnosti",
          newRating: "Nové hodnocení",
          whenConversation: "When the session",
          hit: "Hit",
          miss: "Zmizela",
          time: "Kdy",
          rated: "The rating is",
          qualified: "Kvalifikovaný",
          unqualified: "Nekvalifikovaná",
          modifiedSuccessfully: "Úprava byla úspěšná",
          classificationName: "Název kategorie",
          supervisionObject: "Předmět dohledu",
          entryintoforceTime: "Efektivní čas",
          supervisionItemRating: "Hodnocení položky dohledu",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Zkontrolujte, zda je rating dohledu správný",
          ratingRules: "Pravidla hodnocení",
          settingOfRatingRulesForSupervisionItems:
            "Nastavení pravidla hodnocení položky monitorování",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Klíčové události",
          supervisionItem: "Položky dohledu",
          conversationScenario: "Scénáře sezení",
          customerLabel: "Štítek zákazníka",
          reDetectionRules: "Redetect rules",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Stáhnout záznam",
          downloadText: "Stáhnout text",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Nahrát obrázek",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Please select a picture with a size not exceeding 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Nebo",
            and: "A",
            wrong: "Non",
            matchTo: "Zápas",
            rule: "Pravidla",
            satisfactionOperator: "Uspokojit operátor",
            customerLabel: "Štítek zákazníka",
            keyEvents: "Klíčové události",
            knowledgePoints: "Znalostní body",
            sessionLabel: "Štítek relace",
            supervisionItem: "Položky dohledu",
          },
          linkExplorer: { linkInTheText: "Odkaz v článku:" },
        },
      },
    },
    createForm: {
      field: { customComponentNotFound: "Vlastní součást nenalezena:" },
    },
    sdkLoaderBase: {
      component: { custom: "Vlastní" },
      dropdownMonit: {
        updateSucceeded: "Úspěšná aktualizace",
        areYouSureToDelete: "Opravdu chcete odstranit?",
        custom: "Vlastní",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Uložit aktuální konfiguraci sestavy === formulář",
        saveCurrentReportConfiguration: "Uložit aktuální konfiguraci sestavy",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Zadejte společný název nastavení, maximální délka je 20!",
        preservation: "Uložit",
      },
      template: {
        saveCurrentReportConfiguration: "Uložit aktuální konfiguraci sestavy",
        updateCurrentReportConfiguration:
          "Aktualizovat aktuální konfiguraci sestavy",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Informace o pracovní objednávce",
        workOrder: "Pracovní pořadí",
        triggerScenario: "Spouštěcí scénář",
        associatedCallConversation: "Přidružený hovor/rozhovor",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Test s aktuálním textem průjezdu/dialogu",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "Sekce", day: "Den" },
      businessSummary: {
        normal: {
          equalTo: "Rovná",
          notEqualTo: "Not equal",
          contain: "Obsahuje",
          excluding: "Neobsahuje",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "I am who i am, a different firework @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi, i am the notification of the robot quality inspection warning message, which was added to the group on october 17",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "I am who i am, a different firework @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi, i am the notification of the robot quality inspection warning message, which was added to the group on october 17",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Témata komunikace",
          conversationSummary: "Souhrn sezení",
          serviceSuggestions: "Doporučení pro služby",
        },
        template: { smartTags: "Smart label" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Kliknutím se dozvíte více" },
      schema: {
        textRobot: "Textový robot",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Doprovod trenéra, dokladová otázka a odpověď, dialog založený na úkolech, otázka a odpověď na tabulku, otázka a odpověď na graf.",
        capitalRetentionRobot: "Zadržovací robot",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Rich follow-up retention strategy, high personification communication, to ensure high retention rate, clues efficient automatic conversion.",
        outboundRobot: "Robot pro výchozí volání",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Dokončení pracovní objednávky návratová návštěva, průzkum spokojenosti služeb, varování před vadou provozu a údržbou, připomínka doručení objednávky, oznámení o činnosti elektronického obchodu, jmenování kandidátů, pomoc automatizace podniků a zlepšování efektivity podnikání",
        incomingRobot: "Příchozí robot",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Flexibilní integrace pracovních příkazů, objednávek a jiných obchodních systémů k dosažení automatizace procesů; hlasový sémantický model individuální rozpoznávání, obchodní výsledky okamžitě; subjekt (číslo mobilního telefonu, množství objednávky, čas objednávky, adresa,...) identifikace, rychlá podpora pro komplexní podnikání",
        intelligentQualityInspection: "Inteligentní kontrola kvality",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Úplná kontrola kvality, včasná a efektivní, s přesností 85%",
        intelligentSessionAnalysis: "Inteligentní analýza relací",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Pohled do krátké obchodní rady, naslouchat hlasu zákazníků, zlepšit transformaci marketingu",
        enterpriseWechatConversationAnalysis:
          "Enterprise wechat session analysis",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Účinná identifikace potenciálních obchodních příležitostí, rizik, konverzní míra marketingu vzrostla o 20%",
        assistant: "Asistent sedadla",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Agent asistent může zajistit službu nováčků zákaznické služby a zlepšit kvalitu volání kvalifikovaných zákazníků. Ať je každé sedadlo tvým trumfovým kartáčkem!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
        digitalPeople: "The digital man",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          '"Face-to-face" antropomorfní komunikace, podpora hlasu, textu, videa, touch multi-modální interakce, k dosažení immerzivní komunikační zkušenosti, zlepšení obrazu značky a uživatelské služby',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Ai znalosti v polovině fáze hledání podniků (angl. s. r. o. a. s. r. o.)-----------------------------------------------------------------------------------------------",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Vyhledávání znalostí, sdílení znalostí, vyhledávání dokumentů, vyhledávání v databázi",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Ai knowledge middle desk · kcs knowledge base",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Poskytuje plné řízení znalostí, jednotnou údržbu robotů a znalostních základů im, podporuje docking s znalostními základnami třetích stran, šablony znalostí, atomové znalosti a další výkonné funkce, které přinesou pohodlnější zkušenosti z řízení znalostí.",
        aIKnowledgeCenterKnowledgeMap: "Znalostní platforma ai-znalostní mapa",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "One-stop mapping construction integrates enterprise knowledge, big picture shows knowledge details, and docking capabilities to create customized experience.",
        modularDevelopmentPlatformOriginalEngineASR:
          "Modulární vývojová platforma-originální heart engine-asr",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Přijmout algoritmus end-to-end, aby bylo možné realizovat vlastní zkušenosti s efektem služby, s mírou rozpoznání přirozené scény přesahující 90% a individualizovanou rozpoznání slovní zásoby převyšující 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Modulární vývojová platforma · inteligentní marketing",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Použití algoritmů hlubokého učení v kombinaci s uživatelskými portréty k dosažení přesných doporučení produktu a zlepšení konverzace prodeje.",
        modularDevelopmentPlatformUserPortrait:
          "Modulární vývojová platforma · uživatelský portrét",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Přesně sestavovat uživatelské profily na základě uživatelských údajů a údajů dialogu, a plně popisuje charakteristiky zákazníků.",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Modulární rozvojová platforma · inteligentní doporučení",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Podle uživatelského portrétu a portrétu produktu automaticky doporučují produkty, které mohou být pro uživatele zajímavé, a realizují personalizované doporučení pro tisíce lidí.",
        modularDevelopmentPlatformNLPPAAS:
          "Modulární vývojová platforma · nlp paas",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Poskytuje základní nlp schopnosti s vysokou přesností, pokrývající nízkoúrovňové schopnosti různých granularity, jako jsou slova, věty a články.",
      },
    },
    qualityScore: {
      index: {
        show: "Show:",
        displayBySecondaryClassification:
          "Zobrazit podle sekundární klasifikace",
        showByRule: "Zobrazit podle pravidla",
        pleaseEnterClassificationruleName: "Zadejte název klasifikace/pravidla",
        clickCopy: "Klepněte na tlačítko kopírovat",
        hitDetailsOfOperatorsInRules:
          "Operátor zaškrtněte podrobnosti v pravidle:",
        hit: "Hit",
        miss: "Zmizela",
        filter: "Filtr:",
        clickToViewDetails: "Kliknutím zobrazit podrobnosti",
      },
      components: {
        flowNodeList: {
          branch: "Body",
          pleaseSelect: "Vyberte prosím",
          viewTheCompleteProcess: "Zobrazit celý proces",
          thereIsCurrentlyNoProcessAvailable: "Žádný proces",
        },
        pointItem: { xiangHit: "Hit položky" },
        flowNodeListModal: { index: { completeProcess: "Dokončit proces" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Úkol kontrol kvality procesu se změnil, prosím znovu odvolejte.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Tyto údaje byly pravidelně očištěny",
        subject: "Subject:",
        description: "Popis:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "Přidat do úkolu kvality" },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Přidružený hovor/rozhovor" },
    },
    samplingTarget: {
      index: {
        time: "Kdy",
        samplingQuantityIsLessThan: "Množství odběru vzorků je nižší",
        alertWhen: "Včasné varování",
        everyday: "Denně",
        weekly: "Týdeník",
        monthly: "Měsíční",
        samplingInspectionTargetSavedSuccessfully:
          "Cíl kontroly odběru vzorků úspěšně uložený",
        personnel: "Personál",
        eachCustomerService: "Každý zákaznický servis",
        cycle: "Cyklus",
        samplingInspectionTarget: "Cíl pro kontrolu odběru vzorků",
        strip: "Článek",
        alertSettings: "Nastavení upozornění",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Zákaznický servis",
        complete: "Kompletní",
        Objective: "/Target",
        everyday: "Denně",
        weekly: "Týdeník",
        monthly: "Měsíční",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Míra interakce ve skupinovém chatu",
        maximumResponseIntervalTime: "Maximální interval mezi odpovědí",
        numberOfPreemptiveCalls: "Počet úniků",
        numberOfMessages: "Počet zpráv",
        strip: "Článek",
        proportionOfMessages: "Procento počtu zpráv",
        speechPercentage: "Mluvčí poměr",
        longestCustomerTalk: "Nejdelší zákazník mluví",
        numberOfIndepthDiscussions: "Počet hloubkových diskusí",
        second: "Times",
        maximumDurationOfContinuousSpeech:
          "Maximální trvání nepřetržitého hovoru",
        questionFrequency: "Frekvence otázek",
        customer: "Zákazník",
        keyEvents: "Klíčové události:",
        opportunity: {
          index: {
            generationFailed: "Sestavení selhalo",
            generateOpportunityPoints: "Generovat příležitostné body",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Sestavení selhalo",
            generateSummary: "Generovat souhrn",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Opravdu chcete odstranit?",
        add: "Přidat",
        opportunityPoints: "Příležitostný bod",
        conversationSummary: "Souhrn sezení",
        cancel: "Storno",
        determine: "Určit",
        edit: "Upravit",
        establish: "Vytvořit",
        conversationalInsights: "Průzkumy sezení",
        keyEvents: "Klíčové události",
        numKeyEvents: "Klíčové události",
        questionAnalysis: "Analýza otázek",
        find: "Nalezeno",
        questions: "Druhá otázka",
        conversationAction: "Akce sezení",
        customer: "Zákazník",
        sale: "Prodej",
        sessionLabel: "Scénáře sezení",
        employeeQuestions: "Otázky zaměstnanců",
        customerQuestions: "Otázky zákazníka",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Zákazník",
        customerService: "Zákaznický servis",
        replyTo: "Odpověď",
        addressee: "Do:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Opětovná identifikace",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Re-identifikace bude spotřebovat čas převodu, prosím operovat s opatrností.",
        determine: "Určit",
        executionSuccessful: "Úspěšné provedení",
        addToQualityInspectionTask: "Přidat do úkolu kvality",
        batchOperation: "Provoz šarže",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Úspěšně přidáno",
        addToQualityInspectionTask: "Přidat do úkolu kvality",
        qualityInspectionTask: "Kontrola kvality úkol",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Detekovat roli",
        detectionRange: "Rozsah detekce",
        sentence: "Věta",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Tyto údaje byly pravidelně očištěny",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Return to business system" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Extrakce problémů",
        businessSummary: "Business summary",
        keyWord: "Klíčová slova",
      },
      components: {
        problemExtraction: {
          no: "Ne",
          customerServiceDidNotAnswer: "Zákaznický servis neodpověděl",
          customerServiceHasAnswered: "Zákaznický servis odpověděl",
          problemUnresolved: "Problém nevyřešen",
          theProblemHasBeenResolved: "Problém vyřešen",
          customerIssue: "Otázky zákazníka:",
          customerServiceAnswer: "Odpověď zákaznické služby:",
          reExtractQuestions: "Problém re-extrakce",
          clickToExtractQuestions: "Click to extract questions",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Jméno portrétu",
            backgroundDescription: "Popis pozadí",
            customerLabel: "Štítek zákazníka",
            labelManagement: "Správa štítků",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Nový" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Nastavení výslechu" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Zvýšit výslech",
              remove: "Odstranit",
              addQuestioning: "Přidat otázky",
              section: "Sekce",
              secondInquiry: "Druhý výslech",
              questioningScore: "Výslech",
            },
          },
        },
        index: {
          accordingToRules: "Podle pravidla",
          byScore: "Podle skóre",
          whenMissRule: "When the rule is missed",
          whenConductingQuestioning: "Při kládání otázek",
          pleaseSelectARule: "Vyberte pravidlo",
          ruleCannotBeEmpty: "Pravidlo nemůže být prázdné",
          currentScriptScore: "When the speech score ≤",
          pleaseEnterTheScore: "Prosím, zadejte skóre",
          scoreCannotBeEmpty: "Skóre nemůže být prázdné",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Ne." },
          pPT: { slide: "Posuv" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Comments",
              script: "Slova",
              semantics: "Sémantická",
              violatingWords: "Porušení slov",
              speechSpeed: "Rychlost řeči",
              emotion: "Emoce",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Comments",
              regenerate: "Regenerovat",
              generate: "Generovat",
              hitSituation: "Hit case",
              noSemanticTagsMatched: "Neshoduje se sémantickým štítkem",
              matchToSemanticTags: "Odpovídající sémantickému štítku",
              appealToBeSubmitted: "Podaná stížnost",
              ratingHasBeenReviewed: "Hodnocení",
              ratingAppealInProgress: "Bodování stížností",
              expressionScore: "Výraz skóre",
              moodParticlesTimes: "Tónové slovo, {0} krát",
              changeTheModalParticlesTo: "Pro modální slova",
              second: "Times",
              interrogation: "Výslech",
              questioningWithoutScoring: "Ask without scoring",
              branch: "Body",
              matchingRate: "Srovnávací rychlost",
              ratingAppeal: "Rating grievance",
              changeTo: "Změna",
              wordPerMinute: "Slova za minutu",
              completeScript: "Kompletní projev",
              incompleteScript: "Neúplný projev",
              semanticHit: "Sémantický hit",
              semanticMisses: "Sémantické chyby",
              scriptViolation: "Porušování řeči",
              modified: "Upraveno",
              notChanged: "Nezměněno",
              keyWord: "Klíčová slova",
              semanticLabels: "Sémantické označení",
              semanticMatchingRate: "Sémantická srovnávací rychlost",
              violatingWords: 'Porušení slova"',
              delete: "Smazat",
              unmatched: "Neshoduje",
              matching: "Zápas",
              matchTo: "Zápas",
              notMatchedTo: "Neshoduje",
              scriptScore: "Skóre řeči",
              standardScript: "Standardní řeči",
              emotionalScore: "Emocionální skóre",
              speechSpeedScore: "Rychlost",
              viewResolution: "Zobrazit rozlišení",
              nodeConfiguration: "Konfigurace uzlu",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Doba odpovědi na tuto otázku je přesčas a obsah odpovědi je automaticky odeslán.",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Doba odpovědi na tuto otázku je přesčas a obsah odpovědi je automaticky odeslán.",
          },
          correctBtn: {
            saveSuccessful: "Úspěšně uložit",
            errorCorrection: "Oprava chyb",
            cancel: "Storno",
            preserve: "Uložit",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Pravidla pro hodnocení",
            cancel: "Storno",
            determine: "Určit",
          },
          scoringRuleItem: {
            whole: "All",
            arbitrarily: "Svévolné",
            pleaseEnterARuleName: "Zadejte název pravidla",
            standardScript: "Standardní řeči",
            if: "If",
            and: "A",
            or: "Nebo",
            thenScore: "Pak skóre",
            pleaseEnterAScore: "Prosím, zadejte skóre",
            bonusPoints: "Bonusové body",
            minusPoints: "Mínus body",
            hit: "Hit",
            misses: "Zmizela",
            keyWord: "Klíčová slova",
            semantics: "Sémantická",
          },
          title: {
            ruleValidation: "Ověření pravidel",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Hodnotící pravidla (celkový skóre současného projevu je",
            points: "Body)",
            editRules: "Upravit pravidlo",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Bez štítku" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Cíle komunikace",
              scoringCriteria: "Hodnocení kritérií",
            },
          },
        },
        index: {
          generating: "Generování",
          generateDialogueFramework: "Vytváření dialogového rámce",
          theme: "Téma",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Výzva",
            generatePromptLanguage: "Generovaný prompt",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Fáze komunikace",
          communicationObjectives: "Cíle komunikace",
          scoringCriteria: "Hodnocení kritérií",
          suggestionsForImprovement: "Doporučení pro propagaci",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Skóre procesu",
            contentIntegrity: "Integrita obsahu:",
            NumberOfCompletedProcesses: "%, Počet dokončených procesů",
            keyHitPointsTotal: "Key hits: total",
            notReached: "Nedosáhl",
            accurateFollowupAccumulationOfStandardScripts:
              "Přesné sledování: standardní hromadění řeči",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "A word, the number of words hit by the student",
            aWord: "Slovo",
            languageNormsYes: "Specifikace jazyka: ano",
            emotionsAppearing: "Emoce:",
            speechSpeedSpeakingTooFast: "Rychlost: rychlost příliš rychlá",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Tónové slova: nadměrný výskyt tónových slov",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Přidat k ignorování tagů",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Jste si jistý, že chcete znovu spustit smart label?",
        reExecute: "Re-execution",
      },
      component: { successfullyAdded: "Úspěšně přidáno" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Vypnout všechny oznámení",
        reQualityInspectionTask: "Úloha opětovné kontroly",
        requalityInspection: "Opětovná kontrola",
        waiting: "Čekání",
        viewDetails: "Zobrazit podrobnosti",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Čas detekce:" } },
        },
        template: { responseOpeningSentence: "Počáteční věta odpovědi" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Sestavení selhalo",
            generate: "Generovat",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Žádné údaje" } },
      },
      index: { regenerate: "Regenerovat" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Opravdu chcete odstranit?",
        operationSuccessful: "Operace byla úspěšná",
      },
      template: { delete: "Smazat" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Prosím, vyberte otázku!",
        similarQuestionRecommendation: "Podobné doporučení",
        selectAllOnPage: "Při výběru všech stránek",
        cancel: "Storno",
        preserve: "Uložit",
        changeBatch: "Změna šarže",
      },
    },
    listControl: { index: { columnControl: "Ovládání sloupců" } },
    loginUserAvatar: { index: { switch: "Přepínač" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Při přidávání dat v reálném čase vyžaduje úloha kontroly kvality čas, aby byla provedena. Specifický čas závisí na velikosti údajů o kontrolách kvality, od několika minut do 2 hodin.",
      },
    },
    bEditor: {
      index: {
        delete: "Smazat",
        cancel: "Storno",
        confirm: "Určit",
        insert: "Vložit",
        width: "Šířka",
        height: "Výška",
        clearContent: "Vymazat obsah",
        revoked: "Zrušen",
        redo: "Redo",
        fontSize: "Velikost písma",
        rowHeight: "Výška řádku",
        wordSpacing: "Mezery mezi slovy",
        paragraphIndent: "Odrážka odstavce",
        increaseIndent: "Zvýšit odsazení",
        decreaseIndent: "Snížit odsazení",
        border: "Hraničí",
        color: "Barva",
        textColor: "Barva textu",
        backgroundColor: "Barva pozadí",
        temporaryColor: "Dočasná barva",
        bold: "Bold",
        italic: "Italic",
        underline: "Podtržení",
        strikethrough: "Strikethrough",
        font: "Písmo",
        textAlignment: "Zarovnání textu",
        left: "Vlevo",
        centered: "Centrované",
        right: "Vpravo",
        bothEnds: "Obě konce",
        leftFloat: "Levá float",
        rightFloat: "Pravý pluh",
        subrupt: "Subrupt",
        subscript: "Index",
        clearStyle: "Jasný styl",
        title: "Název",
        regular: "Pravidelné",
        orderedList: "Objednaný seznam",
        unorderedList: "Neobjednaný seznam",
        reference: "Reference",
        code: "Kód",
        link: "Link",
        clearLink: "Vymazat odkaz",
        horizontalLine: "Vodorovná čára",
        media: "Media",
        mediaLibrary: "Mediální knihovna",
        smallExpression: "Malý výraz",
        fullScreen: "Celá obrazovka",
        exitFullScreen: "Ukončit celou obrazovku",
        enterLinkText: "Zadejte text odkazu",
        enterLinkAddress: "Zadejte adresu odkazu",
        enterTheLinkAddressAndPressEnter:
          "Zadejte adresu odkazu a stiskněte enter",
        openInNewWindow: "Otevřít v novém okně",
        removeLink: "Odstranit odkaz",
        playingAudioFiles: "Přehrávání zvukových souborů",
        playVideoFiles: "Přehrávat video soubory",
        embeddedMedia: "Vložené média",
        image: "Obrázek",
        video: "Video",
        audio: "Audio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Jméno",
        description: "Popis",
        type: "Typ",
        status: "Stav",
        actions: "Operace",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Název úlohy",
        description: "Popis",
        form: "Formulář kontroly kvality",
        actions: "Operace",
        createUser: "Tvůrce",
      },
      samplingRecords: {
        createTime: "Čas",
        type: "Typ",
        operateUser: "Operátor",
        number: "Přidat množství",
        filterConditionList: "Stav filtru",
        sampleRuleStr: "Podmínky odběru vzorků",
        timeGreaterThan: "Čas je větší",
        timeLessThan: "Méně času",
      },
    },
    customFilters: {
      createdAt: "Čas mluvení",
      customerId: "Zákazník",
      agentId: "Zákaznický servis",
      userGroupId: "Skupina zákaznických služeb",
      state: "Stav",
      lastEvaluateUserId: "Poslední rater",
      submitTime: "Poslední doba podání",
      totalScore: "Celkové skóre",
      formItemForbidIdList: "Zakázané položky",
      formItemDeadlyIdList: "Fatální položka",
    },
    samplingInspection: {
      callTime: "Čas mluvení",
      dialogTime: "Čas dialogu",
      originator: "Přidělená osoba",
      createTime: "Čas vytvoření",
      updateTime: "Čas aktualizace",
      status: "Stav procesu",
      score: "Frakce",
      actions: "Operace",
      reviewer: "Recenzent",
      samplingOfficer: "Inspektor odběru vzorků",
      id: "Id záznamu volání",
      time: "Čas mluvení",
      phone: "Telefon zákazníka",
      staff: "Zákaznický servis",
      inspectionStatus: "Stav kontroly kvality",
      samplingStatus: "Stav odběru vzorků",
      inspectionGrade: "Rating",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Standardní slovo",
        synonymWords: "Synonyma",
        synonymWordsNumber: "Počet synonymů",
        useFrequency: "Četnost použití",
        effectiveness: "Účinnost",
        operation: "Operace",
      },
      common: {
        useFrequency: "Četnost použití",
        effectiveness: "Účinnost",
        operation: "Operace",
      },
      professionalWords: {
        professionalWords: "Profesionální podstatné jméno",
        useFrequency: "Použít statistiky",
        homophonic: "Hot words (homophone substitution)",
        target: "Podstatné nahrazení",
        targetWords: "Náhradní slova",
        homophonyWords: "Homofon maskovací slovo",
        operation: "Operace",
      },
      attentionWords: "Dotčená slova",
      ignoreWords: "Ignorovat slova",
      wordsWeight: {
        selfDefineWeightWords: "Vlastní slovo váhy",
        weightWords: "Váha slov",
        weight: "Váha",
        useFrequency: "Četnost použití",
        effectiveness: "Účinnost",
        operation: "Operace",
      },
      corpusData: {
        corpusDescription: "Popis corpus",
        updateTime: "Čas aktualizace",
        wordsCount: "Počet slov",
        effectiveness: "Účinnost",
        operation: "Operace",
        corpusContent: "Obsah",
      },
    },
    semanticTags: {
      title: "Sémantické označení",
      tags: "Standardní věta",
      orgName: "Organizace",
      tagCount: "Počet školení",
      usingCount: "Počet odkazů",
      activeFlag: "Účinnost",
      suggestion: "Doporučení pro výcvik",
      actions: "Operace",
    },
    intelligentTags: {
      tagName: "Jméno",
      tagDesc: "Popis",
      tagRefCnt: "Počet značek",
      categoryName: "Klasifikace štítků",
      eventName: "Klasifikace událostí",
      tagStatus: "Účinnost",
      actions: "Operace",
      tagType: "Typ štítku",
    },
    dataPreprocess: {
      name: "Jméno",
      desc: "Popis",
      dataSource: "Limity zdroje dat",
      type: "Typ",
      status: "Účinnost",
      actions: "Operace",
    },
    informationEntities: {
      name: "Jméno",
      apiName: "Název api",
      type: "Typ",
      remark: "Popis",
      activeFlag: "Účinnost",
      actions: "Operace",
    },
    interactiveRecognition: {
      questionAndAnswer: "Standardní otázky a odpovědi",
      type: "Typ",
      status: "Stav",
      actions: "Operace",
      question: "Otázka",
    },
    role: { id: "ID", name: "Jméno", actions: "Operace" },
    uploadDownload: {
      taskName: "Název souboru",
      createTime: "Datum",
      size: "Velikost",
      createUserName: "Operátor",
      status: "Stav",
      result: "Výsledky",
      actions: "Operace",
    },
    taskCenterList: {
      name: "Obsah úlohy",
      createTime: "Čas podání",
      startTime: "Čas zahájení",
      endTime: "Konec času",
      createUserName: "Operátor",
      status: "Stav",
      result: "Výsledky",
      actions: "Operace",
    },
    customerInformation: {
      title: "Informace o servisu zákazníků",
      data: "Zobrazit pouze data výjimek",
      list: {
        agentNo: "Číslo práce/id servisu zákazníků",
        agentName: "Zákaznický servis",
        agentTel: "Telefon",
        agentEmail: "Poštovní schránka",
        updateTime: "Čas aktualizace",
        actions: "Operace",
      },
      upload: {
        second:
          "2. klepněte na tlačítko import pro načtení souboru excel, který odpovídá formátu šablony",
      },
    },
    IndexManagement: {
      title: "Řízení ukazatelů",
      list: {
        indicatorName: "Jméno",
        indicatorDesc: "Popis",
        activity: "Účinnost",
        inspectDataSource: "Zdroj dat",
        sysType: "Typ",
        agentGroups: "Skupina zákaznických služeb",
        actions: "Operace",
      },
      detail: {
        nameEmpty: "Název nemůže být prázdný",
        descript: "Popis",
        descriptEmpty: "Popis nemůže být prázdný",
        active: "Účinnost",
        activeEmpty: "Platnost nemůže být prázdná",
        dataSource: "Zdroj dat",
        dataSourceEmpty: "Zdroj dat nemůže být prázdný",
        grounp: "Skupina zákaznických služeb",
        grounpEmpty: "Skupina agentů nemůže být prázdná",
        grounpHolder: "Vyberte skupinu agentů.",
        type: "Metoda hodnocení",
        typeNone: "Metoda hodnocení nemůže být prázdná",
        base: "Nadace body",
        baseNone: "Základní skóre nemůže být prázdné",
        positive: "Pozitivní faktory",
        positiveNone: "Pozitivní faktor nemůže být prázdný",
        customAdd: "Přidat filtr",
        negative: "Negativní faktory",
        negativeNone: "Negativní faktory nemohou být prázdné",
        customOK: "Kritéria filtru:",
        judgeStrategy: "Pravidla stavu:",
        other: "Další faktory",
        otherAdd: "Přidat faktor",
        agent: "Surové údaje",
        section: "Počet intervalů",
        sectionNone: "Počet intervalů nemůže být prázdný",
        step: "Velikost kroku intervalu",
        stepNone: "Velikost kroku intervalu",
        save: "Uložit",
        cancel: "Storno",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "All",
      staff: "Jen já.",
      department: "Oddělení a níže",
    },
    gongStatisticsDate: { week: "Týden", month: "Měsíc", season: "Sezóna" },
    gongCoachTaskStatus: {
      wait: "To be mentored",
      already: "Trenér",
      ignore: "Ignorované",
    },
    callRangeStatus: {
      all: "Všechny volání",
      sale: "Zaměstnanec iniciovaný",
      customer: "Iniciovaný zákazník",
    },
    todoListType: {
      later: "Poslouchejte později",
      help: "Poradenství pro pomoc",
      contact: "Kontakt později",
      send: "Odeslat data",
      warn: "Riziko včasné varování",
    },
    frequencyTypes: {
      everyDay: "Denně",
      everyWeek: "Týdeník",
      everyMonth: "Měsíční",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Neutajované školení údajů",
        markedDataCorrigenda:
          "Označená data errata (* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *",
        classifiedDataCheck: "Ověřování utajovaných dat",
        markedHistoryRecord: "Historie volání",
        historyDataRecalculate: "Přehodnocování historických údajů",
      },
      dataExtractMode: {
        random: "Zcela náhodné",
        intelligentRecommend: "Inteligentní doporučení",
        custom: "Vlastní",
        specifiedClass: "Určit klasifikaci",
      },
      smartRatesList: {
        markRate: "Rychlost označování",
        corrigendaRate: "Errata rate",
        kl: "K-L divergence",
        signalNoiseRate: "Poměr signálu a hluku",
      },
    },
    visibleRangeTypes: {
      all: "All",
      customerGroup: "Skupina zákaznických služeb",
      userDefined: "Vlastní",
    },
    samplingRateTypes: { everyDay: "Draw daily", everyWeek: "Týdenní čerpání" },
    taskRemindTypes: {
      confirm:
        "Zašlete připomínku, kdy je třeba potvrdit záznam úkolu kontroly kvality.",
      appeal:
        "Pokud záznam o úkolu kontroly kvality zahajuje stížnost, zašlete připomínku.",
    },
    weekDays: {
      monday: "Pondělí",
      tuesday: "Úterý",
      wednesday: "Středa",
      thursday: "Čtvrtek",
      friday: "Pátek",
      saturday: "Sobota",
      sunday: "Neděle",
    },
    reportDateTypes: {
      dailyReport: "Denní zpráva",
      weeklyReport: "Týdenní zpráva",
    },
    samplingRangeDayTypes: { today: "Dnes", yesterday: "Včera" },
    samplingRangeWeekTypes: {
      thisWeek: "Tento týden",
      lastWeek: "Minulý týden.",
    },
    samplingRuleTypes: {
      proportion: "Proporční odběr vzorků",
      random: "Náhodný odběr vzorků",
    },
    taskStatusTypes: {
      waitEvaluate: "Vyhodnotit",
      waitSubmit: "K předložení",
      waitConfirm: "K potvrzení",
      waitReview: "K přezkoumání",
      completed: "Dokončeno",
      all: "All",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Přímé hodnocení",
        weightedScore: "Skóre váhy",
        deductScore: "Odpočty",
        directScoreSecondLevel: "Přímé hodnocení-úroveň 2",
        weightedScoreSecondLevel: "Váha skóre-úroveň 2",
        deductScoreSecondLevel: "Odpočet bodů-úroveň 2",
      },
    },
    fieldOperators: {
      is: "Rovná",
      not: "Not equal",
      contains: "Obsahuje",
      notContains: "Neobsahuje",
      not_contains: "Neobsahuje",
      containsAny: "Obsahuje jakékoli",
      contains_any: "Obsahuje jakékoli",
      is_any: "Obsahuje jakékoli",
      not_any: "Neobsahuje žádné",
      notContainsAny: "Neobsahuje žádné",
      not_contains_any: "Neobsahuje žádné",
      containsAll: "Obsahuje všechny",
      contains_all: "Obsahuje všechny",
      notContainsAll: "Neobsahuje všechny",
      nont_contains_all: "Neobsahuje všechny",
      isNull: "Prázdný",
      is_null: "Prázdný",
      isNotNull: "Není prázdný",
      is_not_null: "Není prázdný",
      greaterThan: "Větší",
      greater_than: "Větší",
      greaterThanEq: "Větší nebo rovný",
      greater_than_eq: "Větší nebo rovný",
      lessThan: "Méně",
      less_than: "Méně",
      lessThanEq: "Menší nebo rovný",
      less_than_eq: "Menší nebo rovný",
      between: "Mezi",
      notBetween: "Ne",
      not_between: "Ne",
      relative: "Rovná",
      set: "Sada",
      belongs: "Patřit",
      notBelongs: "Nepatří",
      not_belongs: "Not equal",
      today: "Dnes",
      yesterday: "Včera",
      the_day_before_yesterday: "Den před včerejším",
      tomorrow: "Zítra.",
      next_seven_day: "The next seven days",
      last_seven_day: "Poslední sedm dní",
      this_week: "Tento týden",
      next_week: "Příští týden",
      last_week: "Minulý týden.",
      this_month: "Tento měsíc",
      next_month: "Příští měsíc",
      last_month: "Minulý měsíc",
      this_year: "Tento rok",
      next_year: "Příští rok",
      last_year: "Minulý rok",
      exact_match: "Přesný zápas",
      add_value: "Nová přidaná hodnota",
      remove_value: "Smazat hodnotu",
      set_null: "Prázdný",
      isAny: "Svévolně rovná se",
      notAny: "Svévolné ne rovné",
      belongTo: "Patřit",
      notBelongTo: "Nepatří",
      hasAny: "Obsahuje jakékoli",
      notHasAny: "Neobsahuje žádné",
      prefixContains: "Začíná rovná",
      prefixNotContains: "Začátek není rovný",
      suffixContains: "Konec se rovná",
      suffixNotContains: "Konec není rovný",
      nextSevenDay: "Další 7 dní",
      lastSevenDay: "Poslední 7 dní",
      thisWeek: "Tento týden",
      nextWeek: "Příští týden",
      lastWeek: "Minulý týden.",
      thisMonth: "Tento měsíc",
      nextMonth: "Příští měsíc",
      lastMonth: "Minulý měsíc",
      thisYear: "Tento rok",
      nextYear: "Příští rok",
      lastYear: "Minulý rok",
      after: "Později než (včetně aktuálního dne)",
      before: "Dříve než (včetně aktuálního dne)",
      allMatching: "Zápas vše",
      allNotMatching: "Všechny se neshodují",
      hit: "Hit",
      not_hit: "Zmizela",
    },
    fieldsOperators: {
      is: "Rovná",
      is_null: "Také prázdný",
      head_equal: "Začíná rovná",
      tail_equal: "Konec se rovná",
      is_not_null: "Also not empty",
      not: "Not equal",
      diff_equal: "Rozdíl je rovný.",
      diff_greater_than: "Rozdíl je větší.",
      diff_greater_than_eq: "Rozdíl větší nebo rovný",
      diff_less_than: "Rozdíl je menší",
      diff_less_than_eq: "Rozdíl menší nebo rovný",
      hour_equal: "Stejná hodina.",
      day_equal: "Stejný den",
      week_equal: "Stejný týden",
      month_equal: "Stejný měsíc",
      year_equal: "V témže roce",
      day: "Den",
      minute: "Minuty",
      hour: "Hodiny",
      part_equal: "Částečně rovno",
    },
    samplingColumnCaptions: {
      created_at: "Čas mluvení",
      agent_id: "Zákaznický servis",
      get_direction: "Typ volání",
      call_result: "Výsledky volání",
      survey: "Hodnocení",
      derived_from_id: "Zdroj",
      quit_queue_why: "Stav fronty",
      queuing_duration: "Časově náročná fronta",
      talking_seconds: "Doba trvání volání",
      ringing_seconds: "Čas prstenu",
      hangup_by: "Call hang-up party",
    },
    callTypes: {
      callIn: "Příchozí hovor",
      callOut: "Vydechovat",
      threeParty: "(Tripartite)",
      consultation: "(Poradenství)",
      insert: "(Silné vložení)",
      monitor: "(Naslouchat)",
      transfer: "(Transfer)",
      intercept: "(Intercept)",
      transferOutside: "(Transfer external line)",
      threePartyOutside: "(Tripartite external line)",
      consultingOutside: "(Consult outside)",
    },
    callResults: {
      staffAnswer: "Odpověď zákaznické služby",
      staffNotAnswer: "Zákaznický servis zmeškaný",
      customerAnswer: "Odpověď zákazníka",
      customerNotAnswer: "Zákazník vynechal",
      phoneBusy: "Telefon zaneprázdněn",
      phoneOffline: "Telefon offline",
      customerSpeedHangUp: "Zákazník rychlé zavěšení",
      customerHangUp: "Zákazník visí up",
      queueTimeout: "Časový limit fronty",
      queueGiveUp: "Opustit frontu",
      outlineAnswer: "Vnější odpověď",
      outlineNotAnswer: "Vnější linka není připojená",
      noChooseQueue: "Není vybrána žádná fronta",
    },
    defaultSurveys: {
      noEvaluation: "Nevyhodnoceno",
      noNeedToEvaluate: "Není třeba vyhodnotit",
    },
    queueStates: {
      queueSuccess: "Úspěch fronty",
      queueTimeout: "Časový limit fronty",
      queueGiveUp: "Opustit frontu",
      noStaffOnline: "Online zákaznické služby",
    },
    callHangers: {
      customer: "Zákazník",
      staff: "Zákaznický servis",
      outline: "Venku",
    },
    workFlowTypes: {
      noAppealsMode: "Bez režimu stížnosti",
      appealsMode: "Povolit režim odvolání",
    },
    fieldCategories: {
      standardCondition: "Standardní kritéria filtru",
      customerCondition: "Uživatelem definovaná kritéria filtru",
    },
    sampleTypes: {
      automatic: "Automatický odběr vzorků",
      manual: "Manuální odběr vzorků",
    },
    defaultFields: {
      createdAt: "Čas mluvení",
      agentId: "Zákaznický servis",
      getDirection: "Typ volání",
      callResult: "Výsledky volání",
      survey: "Hodnocení",
      derivedFrom: "Zdroj",
      quitQueueWhy: "Stav fronty",
      queuingDuration: "Časově náročná fronta",
      talkingSeconds: "Doba trvání volání",
      ringingSeconds: "Čas prstenu",
      hangupBy: "Call hang-up party",
    },
    conditionFieldCategories: {
      fixedField: "Pevné pole",
      customField: "Vlastní pole",
      secondLevelScore: "Sekundární klasifikace",
      formItemScore: "Položka skóre",
      forbids: "Zakázané položky",
      deadlies: "Fatální položka",
    },
    columnFieldCategories: {
      agent: "Zákaznický servis",
      callLog: "Záznam hovoru",
      businessFields: "Podnikatelské informace",
      customer: "Zákazník",
      customerCustomFields: "Zákaznické přizpůsobení",
      source: "Zdroj",
      queue: "Fronta",
      fixed: "Fixní",
      secondLevelScore: "Sekundární klasifikace",
      formItemScore: "Položka skóre",
      forbids: "Zakázané položky",
      deadlies: "Fatální položka",
    },
    taskListDefaultFields: {
      startTime: "Čas mluvení",
      name: "Zákaznický servis",
      state: "Stav",
      totalScore: "Celkové skóre",
      formItemForbidList: "Zakázané položky",
      formItemDeadlyList: "Fatální položka",
      comment: "Poznámky k hodnocení",
      inspector: "Hodnocení",
      review: "Recenzent",
    },
    evaluationStates: {
      initial: "Inicializace",
      waitEvaluate: "Vyhodnotit",
      evaluated: "K předložení",
      waitConfirm: "K potvrzení",
      waitCheck: "K přezkoumání",
      complete: "Dokončeno",
    },
    workBenchInfoTypes: {
      callInfo: "Informace o volání",
      businessInfo: "Podnikatelské informace",
      customerInfo: "Informace o zákazníkovi",
    },
    evaluationEventStates: {
      create: "Vytvořit",
      valuate: "Hodnocení",
      edit: "Upravit",
      submit: "Podání",
      confirm: "Potvrdit",
      appeal: "Stížnost",
      checkConfirm: "Přezkoumat potvrzení",
      check: "Přezkum",
    },
    formItemTypes: {
      direct: "Přímé hodnocení",
      weight: "Váha",
      deduct: "Odpočty",
      forbid: "Zakázané položky",
      deadly: "Fatální položka",
    },
    appealCheckCallTypes: {
      callIn: "Příchozí hovor",
      callBack: "Oboustranné zpětné volání",
      callOut: "Přímé volání",
      autoCall: "Automatický výchozí hovor",
    },
    appealCheckCallStatus: {
      waitReview: "K přezkoumání",
      reviewed: "Přezkoumáno",
      filed: "Archivované",
    },
    samplingStatus: {
      all: "All",
      unchecked: "Extrakce není kontrolována",
      checked: "Odběr vzorků",
    },
    inspectionStatus: {
      all: "All",
      unread: "Nepřečtené",
      readed: "Číst",
      appeal: "Stížnost",
      review: "Přezkum",
      filed: "Archivované",
    },
    gradeChooseHits: { yes: "Ano", no: "Ne" },
    appealCheckStatus: {
      all: "Celý stav",
      unread: "Nepřečtené",
      inComplaint: "Ve stížnosti",
      reviewed: "Přezkoumáno",
      readed: "Číst",
      filed: "Archivované",
      spotchecked: "Odběr vzorků",
    },
    flowStatus: {
      unread: "Nepřečtené",
      alreadyRead: "Číst",
      complaining: "Ve stížnosti",
      reviewed: "Přezkoumáno",
      sampling: "Extrakce není kontrolována",
      samplingCompleted: "Odběr vzorků",
      complainCancel: "Zrušen",
    },
    complainStatus: {
      success: "Úspěch",
      fail: "Selhal",
      default: "Ve stížnosti",
      canceled: "Zrušen",
    },
    reviewInspectionStatus: {
      inComplaint: "Ve stížnosti",
      reviewed: "Přezkoumáno",
    },
    gradeChangeTypes: {
      edit: "Upravit",
      check: "Přezkum",
      file: "Archivace",
      remark: "Poznámky",
      complain: "Stížnost",
      review: "Přezkum",
      reviewEvaluation: "Hodnocení hodnocení",
      sample: "Náhodná kontrola",
      sampleEvaluation: "Kontrola a hodnocení odběru vzorků",
      sampleSubmit: "Kontrola a předložení vzorků",
      sampleAssign: "Přidělení vzorků",
      reviewAssign: "Přehodnotit přidělení",
      read: "Číst",
      reviewConfirm: "Přezkoumat potvrzení",
      sampleConfirm: "Potvrzení kontroly odběru vzorků",
      caseBase: "Přidat do skupiny případů",
      sampleCancel: "Odběr vzorků zrušit přidělení",
      reviewCancel: "Zrušení přezkumu",
      sampleAgain: "Přerozdělování vzorků",
      reviewAgain: "Přepracování",
      btachDelete: "Odstranit záznamy o náhodných kontrolách",
      rapidSampling: "Rychlý odběr vzorků",
      reInspection: "Opětovná kontrola",
      smartTagsChange: "Změny inteligentního štítku",
      cancelAppeal: "Stažení stížnosti",
    },
    appealTypes: {
      transliterationError: "Chyba transkribace",
      discriminationError: "Chyba úsudku",
      otherError: "Další chyby",
    },
    inspectDataSources: {
      voiceCall: "Hlasový hovor",
      textDialogue: "Textový dialog",
      realTimeVoiceCall: "Hlasové volání v reálném čase",
      realTimeTextDialogue: "Hovory v reálném čase",
      wechatDialogue: "Enterprise micro-session",
      taobao: "E-commerce text dialogue",
      ticket: "Pracovní pořadí",
      wechatRadio: "Enterprise micro voice",
    },
    inspectTaskType: {
      all: "All",
      common: "Obecná kontrola kvality",
      relate: "Související kontrola kvality",
    },
    inspectApproveType: {
      all: "All",
      resolve: "Schválen",
      resolving: "V revizi",
      reject: "Audit nebyl proveden",
    },
    dataSourceFlags: {
      all: "Žádné omezení",
      voiceCall: "Volání",
      textDialogue: "Dialog",
    },
    smartWordsLibrary: { effective: "Efektivní", invalid: "Neplatný" },
    activeFlags: { effiective: "Efektivní", invalid: "Neplatný" },
    labelTypes: {
      dimensionLabel: "Volací štítky",
      classificationLabel: "Označení kategorie",
    },
    pointTypes: {
      automatic: "Automatické hodnocení",
      manual: "Manuální hodnocení",
      interactiveDetection: "Detekce interakce",
      smartRules: "Inteligentní pravidla",
      machineLearning: "Režim sebeučení",
      intelligentModel: "Inteligentní model",
    },
    pointGradeTypes: {
      radio: "Jednotný výběr",
      input: "Vstup",
      select: "Vybrat",
      all: "Meet all",
      any: "Meet arbitrary",
      customize: "Vlastní",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Odpovídající klíčovým slovům",
      question: "Výslech",
      regular: "Regulární výrazy",
      context: "Duplikování kontextu",
      semantics: "Sémantické přizpůsobení",
      word: "Podobné slova",
      dialogue: "Interval dialogu",
      speed: "Detekce rychlosti řeči",
      grabDetection: "Detekce chycení",
      semanticTags: "Sémantické označení",
    },
    applyRoles: {
      all: "All",
      customer: "Zákazník",
      agent: "Zákaznický servis",
      robot: "Robot",
    },
    saleApplyRoles: { all: "All", customer: "Zákazník", agent: "Zaměstnanec" },
    applyOperatorScopes: {
      all: "Celý text",
      preCondition: "Předpoklad",
      scope: "Zadaný rozsah",
    },
    keywordScopes: {
      all: "Detekce všech klíčových slov.",
      any: "Odhalit libovolné klíčové slova",
    },
    preOperatorHitTypes: {
      first: "První zápas",
      every: "Každý zápas",
      last: "Poslední zápas",
      any: "Jakýkoli zápas",
      none: "Neshoduje",
    },
    operatorRuleTypes: {
      all: "Meet all",
      any: "Meet arbitrary",
      custom: "Vlastní logika",
    },
    informationEntityTypes: {
      system: "Systém",
      custom: "Vlastní",
      moduleCompany: "Modul",
    },
    organizationTypes: { null: "Žádné", moduleCompany: "Modul" },
    customType: {
      smartTag: "Výtah entit",
      scriptTimes: "Počítání řeči",
      dataCategory: "Klasifikace dat",
    },
    interactiveQuestionTypes: {
      standard: "Standardní q & a",
      multiElement: "Víceletá otázka a odpověď",
      guideMultiRound: "Naváděná interakce s více koly",
    },
    targetTypes: {
      all: "All",
      customerService: "Zákaznický servis",
      customer: "Zákazník",
    },
    interactiveTypes: {
      similarQList: "Podobný problém",
      exceptSimilarQList: "Odstranit podobné problémy",
      similarAList: "Podobné odpovědi",
      wrongAList: "Špatná odpověď",
    },
    filterTypes: {
      call: "Záznam hovoru",
      dialogue: "Text dialogu",
      wechat: "Enterprise wechat",
      wechatAll: "Enterprise wechat všechny dialog",
      ticket: "Relace pracovní objednávky",
      taobao: "Relace elektronického obchodu",
      wechatRadio: "Enterprise micro voice",
    },
    fieldTypes: {
      keyword: "Klíčová slova",
      agent: "Zákaznický servis",
      agentGroup: "Skupina zákaznických služeb",
      sigleListbox: "Jednotný výběr",
      multiListbox: "Multiple choice",
      text: "Jeden řádek textu",
      textArea: "Více řádků textu",
      dateTime: "Čas",
      number: "Čísla",
      voice: "",
      customer: "Zákazník",
      fieldWithLink: "Pole s odkazem",
      percentage: "Procento",
      intelligentClassification: "Inteligentní klasifikace",
      testSetList: "Testovat dynamické pole",
      inspector: "Inspektor kvality",
    },
    saveTypes: { save: "Uložit" },
    knowledgeType: { oneToOne: "Jedna otázka a jedna odpověď" },
    updateTypes: { update: "Aktualizace", saveAs: "Uložit" },
    recommendItemActionTypes: {
      similar: "Podobné",
      exclude: "Vyloučit",
      ignore: "Ignorovat",
    },
    gradeStatus: {
      unread: "Nepřečtené",
      alreadyReview: "Přezkoumáno",
      alreadyFiled: "Archivované",
      waitReview: "K přezkoumání",
      alreadyRead: "Číst",
    },
    qualityCheckDetailTypes: {
      review: "Přezkum",
      spotCheck: "Náhodná kontrola",
    },
    sampleAndReviewStatus: {
      init: "Neextrahované",
      unread: "Nepřečtené",
      alreadyRead: "Číst",
      complaining: "Ve stížnosti",
      reviewed: "Přezkoumáno",
      sampling: "Extrakce není kontrolována",
      samplingCompleted: "Odběr vzorků",
    },
    interactiveAnswerTypes: {
      noAnswer: "Nebyla zjištěna žádná odpověď",
      answer: "Zjistila odpověď",
      wrongAnswer: "Zjištěna nesprávná odpověď",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Nebyly zjištěny žádné problémy",
      question: "Zjištěný problém",
      eliminateQuestion: "Zjištěn problém s odstraňováním problémů",
    },
    isManual: {
      yes: "Ruční kontrola kvality",
      no: "Žádná ruční kontrola kvality",
    },
    readStatus: { yes: "Číst", no: "Nepřečtené" },
    reInspectionType: {
      recalcScore: "Přepočítat pouze skóre (s hodnocením)",
      recheckPoint: "Přepočítat kontrolní body a skóre kvality",
    },
    common: {
      eCommerceReview: "E-commerce review",
      socialMediaComments: "Komentáře sociálních médií",
      customer: "Zákazník",
      customerService: "Zákaznický servis",
      robot: "Robot",
      customerInformation: "Informace o zákazníkovi",
      workOrderFields: "Pole pracovního pořadí",
      intelligentConversationAnalysis: "Inteligentní analýza relací",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Pohled do krátké obchodní rady, naslouchat hlasu zákazníků, zlepšit transformaci marketingu",
      intelligentAccompanyingPractice: "Smart sparring",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Vytvořte immersive ai sparring, který simuluje skutečné podnikatelské scény, aby bylo dosaženo plného zvládnutí řečnických dovedností zlaté medaile a rychlého zlepšení dialogových dovedností.",
      intelligentQualityInspection: "Inteligentní kontrola kvality",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Úplná kontrola kvality, včasná a efektivní, s přesností více než 85%",
      salesEmpowerment: "Empowerment sales",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "Ai reformuje řízení prodeje, realizuje digitalizaci a vizualizaci komunikačního procesu, transparentně spravuje každé propojení prodeje, inteligentně vnímá každý krok komunikace, jemně diagnostikuje obchodní problémy a dokonale přepracuje zkušenosti z prodeje.",
      algorithmPlatform: "Platforma algoritmu",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Based on the speech semantics of the self-developed original heart engine, asr and nlp models are customized to get through business scenario data and realize system self-learning, aby bylo dosaženo cíle inteligentnějšího a přesnějšího použití.",
      generalSettings: "Obecné nastavení",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Nastavit a spravovat oprávnění dat a úlohy zaměstnanců jednotným způsobem",
      callLog: "Záznam hovoru",
      intelligentRules: "Inteligentní pravidla",
      businessRecords: "Obchodní záznamy",
      customFilter: {
        call: "Hlasový hovor",
        text: "Textový dialog",
        smartTags: "Smart label",
        pretreatment: "Předléčba",
        smartTagGroup: "Skupina smart label",
        currency: "Obecné údaje",
        wechat: "Enterprise wechat",
        spotCheck: "Náhodná kontrola",
        personaField: "Seznam zákazníků",
        ticket: "Relace pracovní objednávky",
        taobao: "Relace elektronického obchodu",
        wechatRadio: "Enterprise micro voice",
        reviewList: "Přezkum",
      },
      talkerRecognitionType: {
        API: "Api identifikuje roli stopy",
        text: "Rozpoznávání textu",
      },
      operatorTypes: {
        keyword: "Odpovídající klíčovým slovům",
        question: "Výslech",
        regular: "Regulární výrazy",
        context: "Duplikování kontextu",
        semantics: "Sémantické přizpůsobení",
        word: "Podobné slova",
        dialogue: "Interval dialogu",
        speed: "Detekce rychlosti řeči",
        grabDetection: "Detekce chycení",
        semanticTags: "Sémantické označení",
        keywordStrict: "Soulad klíčových slov (striktní)",
        link: "Pole propojení",
        entityInfoDetection: "Detekce subjektu informací",
        basicInfoDetection: "Detekce základních informací",
        volume: "Detekce svazku",
        emotion: "Analýza sentimentu",
        grammar: "Pravidla gramatiky",
        sentenceLength: "Detekce délky věty",
        conceptWord: "Koncepční slova",
        questionsAndAnswers: "Řešení základny znalostí (staré)",
        element: "Základní otázky a odpovědi",
        silent: "Lhůta odezvy",
        associate: "Údaje o silnici",
        repeat: "Opakovat odeslání",
        timeInterval: "Délka intervalu",
        typoDetection: "Detekce typů",
        silentOld: "Ticho",
        knowledge: "Znalostní řešení",
        wecomRemark: "Zákaznický podnik micro poznámky",
        customerAddTime: "Zákazník přidat čas",
      },
      keywordOperators: {
        all: "Zápas vše",
        allNot: "Všechny se neshodují",
        any: "Jakýkoli zápas",
      },
      applyRoles: {
        all: "All",
        customer: "Zákazník",
        agent: "Zákaznický servis",
      },
      sentenceLength: {
        less: "Méně",
        greater: "Větší",
        equal: "Rovná",
        greater_or_equal: "Větší nebo rovný",
        less_or_equal: "Menší nebo rovný",
      },
      dialogueApplyScopes: {
        differentRoles: "Mezi různými roly",
        agent: "Zákaznický servis",
        customer: "Zákazník",
        agentResponse: "Odezva zákazníka",
        customerResponse: "Odezva zákazníka",
        customerNoResponse: "Zákazník nereaguje",
      },
      applyConditionHitTypes: {
        first: "První hit.",
        every: "Každý zásah.",
        last: "Poslední hit",
      },
      applyConditionScopes: {
        current: "Current",
        before: "Před",
        after: "Po",
        beforeAll: "All before",
        afterAll: "Koneckonců",
        around: "Poblíž",
      },
      voiceDemoTypes: {
        singleChannel: "Single channel call recording",
        dualChannel: "Dvoukanálový záznam hovoru",
      },
      sendStatus: {
        sending: "Odeslání",
        arrive: "Doručeno",
        fail: "Nepodařilo se odeslat",
        off_sending: "Odeslat offline nepřečtené",
        off_arrive: "Číst",
        rollback: "Staženo",
      },
      collectionMethod: {
        assignment: "Přímé přidělení",
        entity: "Extrakce entit",
      },
      systemTypes: {
        qa: "Inteligentní kontrola kvality",
        wfm: "Inteligentní plánování",
      },
      entityOperators: {
        equals: "Rovná se informacím",
        notEquals: "Není rovno s informacemi",
        contains: "Obsahuje informace",
        notContains: "Neobsahuje informace",
        equalsFix: "Rovná pevné hodnotě",
        notEqualsFix: "Není rovno pevné hodnotě",
        containsFix: "Obsahuje pevné hodnoty",
        notContainsFix: "Neobsahuje pevnou hodnotu",
      },
      basicInfoOperators: {
        equals: "Rovná",
        notEquals: "Not equal",
        contains: "Obsahuje",
        notContains: "Neobsahuje",
        isNull: "Prázdný",
        isNotNull: "Není prázdný",
      },
      volumeDetectionWays: {
        loudness: "Hlasitost decibel",
        rangeAbilitySelf:
          "Range of change (than i did in the previous sentence)",
        rangeAbilityOth: "Rozsah změny (ve srovnání s výše uvedenou větou)",
        rangeAbility3: "Rozsah změny (než jsem v této větě)",
      },
      volumeDetectionOperators: {
        is: "Rovná",
        not: "Not equal",
        greaterThan: "Větší",
        greaterThanEq: "Větší nebo rovný",
        lessThan: "Méně",
        lessThanEq: "Menší nebo rovný",
      },
      sentimentTypes: {
        positive: "Pozitivní emoce",
        negative: "Negativní emoce",
        neutral: "Neutrální emoce",
        thankful: "Thanks",
        happy: "Šťastný",
        complaining: "Stěžovat",
        angry: "Naštvaný",
        post: "Přední",
        negat: "Negativní",
        neut: "Neutrální",
      },
      emotionOperators: { is: "Soulad", not: "Není v souladu" },
      propTypes: { system: "Systém", user: "Vlastní" },
      valueTypes: { text: "Text", number: "Čísla" },
    },
    tasks: {
      allowCustomGrievance: "Povolit vlastní stížnost",
      businessSummary: "Business summary",
      theDayBeforeYesterday: "Den před včerejším",
      assessActionTypes: { reassess: "Re-hodnocení" },
      scoreType: {
        aiScore: "Inteligentní hodnocení",
        manualScore: "Manuální hodnocení",
        interactiveDetection: "Detekce interakce",
        smartRules: "Inteligentní pravidla",
        machineLearning: "Režim sebeučení",
      },
      inspectType: { inspectTrue: "Kontrola", inspectFalse: "Nezaškrtnuto" },
      operatorType: {
        keyword: "Odpovídající klíčovým slovům",
        notKeyword: "Neshoda klíčových slov",
      },
      applyRole: {
        all: "All",
        customer: "Zákazník",
        agent: "Zákaznický servis",
      },
      applyScope: {
        all: "All",
        specific: "Zadaná věta",
        scope: "Zadaný rozsah",
      },
      judgeStrategy: {
        all: "Splnit všechny podmínky",
        arbitrarily: "Splnit jakoukoli podmínku",
        custom: "Vlastní logika",
      },
      checkPointStatus: {
        waitFor: "Čeká na hodnocení",
        notDeductScore: "Bez odpočtu",
        deductScore: "Odpočty",
      },
      applyOptions: {
        hit: "Hit stavu",
        notHit: "Nejsou stanoveny žádné podmínky",
      },
      gradeTypes: {
        yesOrNo: "Soulad",
        input: "Manuální vstup",
        level: "Hodnocení stupně",
        multipleFactors: "Více prvků",
      },
      gradeChooseHits: { yes: "Ano", no: "Ne" },
      gradeChangeTypes: {
        edit: "Upravit",
        check: "Přezkum",
        file: "Archivace",
        remark: "Poznámky",
        complain: "Stížnost",
        review: "Přezkum",
        reviewEvaluation: "Hodnocení hodnocení",
        sample: "Náhodná kontrola",
        sampleEvaluation: "Kontrola a hodnocení odběru vzorků",
        sampleSubmit: "Kontrola a předložení vzorků",
        sampleAssign: "Přidělení vzorků",
        reviewAssign: "Přehodnotit přidělení",
        read: "Číst",
        reviewConfirm: "Přezkoumat potvrzení",
        sampleConfirm: "Potvrzení kontroly odběru vzorků",
      },
      gradeStatus: {
        unread: "Nepřečtené",
        alreadyReview: "Přezkoumáno",
        alreadyFild: "Archivované",
        waitReview: "K přezkoumání",
        alreadyRead: "Číst",
      },
      samplingFrequencyTypes: {
        everyDay: "Denně",
        everyWeek: "Týdeník",
        everyMonth: "Měsíční",
      },
      samplingEveryDayTimeTypes: { yesterday: "Včera", today: "Dnes" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Tento týden",
        lastWeek: "Minulý týden.",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Tento měsíc",
        lastMonth: "Minulý měsíc",
      },
      samplingTypes: {
        random: "Náhodný odběr vzorků",
        proportional: "Proporční odběr vzorků",
      },
      weekdays: {
        Monday: "Pondělí",
        Tuesday: "Úterý",
        Wednesday: "Středa",
        Thursday: "Čtvrtek",
        Friday: "Pátek",
        Saturday: "Sobota",
        Sunday: "Neděle",
      },
      distributeTypes: {
        rule: "Přidělování priorit pravidla",
        average: "Průměrné náhodné rozdělení",
      },
      notMatchAnyRuleTypes: {
        random: "Náhodné průměrné rozdělení",
        assign: "Přiřadit určeným osobám",
        ignore: "Není přiřazen",
      },
      inspectorTypes: {
        all: "Všechny inspektory kvality",
        some: "Určený inspektor kvality",
      },
      pushFrequencyTypes: {
        everyDay: "Denně",
        everyWeek: "Týdeník",
        everyMonth: "Měsíční",
        inspectionUpdate: "Aktualizace kontroly kvality",
        timelyInspection: "Kontrola kvality v reálném čase",
      },
      pushObjectTypes: {
        email: "Zadat poštovní schránku",
        staff: "Zákaznický servis",
      },
      reviewOptions: {
        reviewResult: "Zobrazit výsledky kontroly kvality během přezkumu",
        reviewRecord: "Zobrazit příslušné záznamy během přezkumu",
        reviewAgentHidden: "Skrýt informace agenta během přezkumu",
        samplingResult:
          "Zobrazit výsledky kontroly kvality během náhodné kontroly",
        samplingRecord: "Zobrazit příslušné záznamy během náhodné kontroly",
        sampleAgentHidden:
          "Skrýt informace o servisu zákazníků během náhodné kontroly",
        checkExportAddCallInfoIphone:
          "Připojit protokoly volání při exportování seznamu",
        sampleExportAddCallInfoIphone:
          "Připojit protokoly volání při exportování seznamu",
        checkExportAddCallInfoText:
          "Připojit záznamy konverzace při exportování seznamu",
        sampleExportAddCallInfoText:
          "Připojit záznamy konverzace při exportování seznamu",
        inspectionExportAddCallInfoIphone:
          "Při exportu seznamu přiložte záznamy o volání (v současnosti lze vyvážet až 10000)",
        inspectionExportAddCallInfoText:
          "Při exportu seznamu přiložte záznamy o konverzaci (v současnosti lze vyvážet až 10000)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Umožnit rychlou kontrolu na místě v podrobnostech o kvalitě",
        fastSampleInSampleDetail:
          "Povolit rychlé provedení náhodné kontroly v náhodných detailech kontroly",
        fastSampleInReviewDetail:
          "Umožňuje rychlé provedení kontrol na místě v podrobnostech o přezkoumání",
      },
      permitCustomReasonList: {
        permitCustomReason: "Povolit vlastní důvody pro tvrzení",
      },
      autoMateTasks: {
        autoLoad: "Přidat automaticky",
        sample: "Automatická kontrola odběru vzorků",
        assign: "Automatické přidělování",
        pushes: "Automatické tlačení",
        assess: "Automatické hodnocení",
        check: "Hodnocení stížnosti",
        case: "Synchronizace knihovny případů",
      },
      qualityCheckDetailTypes: {
        review: "Přezkum",
        spotCheck: "Náhodná kontrola",
      },
      appealTypes: {
        transliterationError: "Chyba transkribace",
        discriminationError: "Chyba úsudku",
        otherError: "Další chyby",
      },
      inspectDataSources: {
        voiceCall: "Hlasový hovor",
        textDialogue: "Textový dialog",
        realTimeVoiceCall: "Hlasové volání v reálném čase",
        realTimeTextDialogue: "Hovory v reálném čase",
      },
      pointTypes: {
        automatic: "Automatické hodnocení",
        manual: "Manuální hodnocení",
        interactiveDetection: "Detekce interakce",
      },
      pointGradeTypes: {
        radio: "Jednotný výběr",
        input: "Vstup",
        select: "Vybrat",
      },
      sampleAndReviewStatus: {
        init: "Neextrahované",
        unread: "Nepřečtené",
        alreadyRead: "Číst",
        complaining: "Ve stížnosti",
        reviewed: "Přezkoumáno",
        sampling: "Extrakce není kontrolována",
        samplingCompleted: "Odběr vzorků",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Ignorovat přebytek",
        automaticWeightCalculation: "Automatické účtování hmotnosti",
      },
      calculateScoreTypes: { add: "Bonusové body", sub: "Mínus body" },
      naChecked: { check: "Tato položka není zapojena do povolené detekce" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Procentní body",
        allRight: "Všechny správné body",
        anyRight: "Jakýkoli správný skóre",
      },
      noHitQuestionResults: {
        true: 'Výsledkem je "ano"',
        false: 'Výsledek je "ne"',
      },
      repetRules: {
        same: "Obsah přesně stejný",
        similar: "Sémantická podobnost",
      },
      relateConditions: {
        highlightQuestion: "Zvýraznit problém",
        highlightAnswer: "Zvýraznit odpověď",
        hightlightFoctors: "Zvýraznit prvek",
      },
      interactiveQuestionTypes: {
        question: "Zjištěný problém",
        eliminateQuestion: "Zjištěn problém s odstraňováním problémů",
      },
      interactiveAnswerTypes: {
        noAnswer: "Nebyla zjištěna žádná odpověď",
        answer: "Zjistila odpověď",
        wrongAnswer: "Zjištěna nesprávná odpověď",
      },
      inspectionUpdateTypes: {
        autoInspection: "Automatická kontrola kvality",
        autoInspectionWechat: "Automatická analýza",
        artificialSampling: "Manuální odběr vzorků",
        artificialReview: "Manuální přezkum",
        manualModification: "Rychlý odběr vzorků",
        timelyInspection: "Kontrola kvality v reálném čase",
        samplingAssign: "Přidělení vzorků",
        artificialComplain: "Podání stížnosti",
        fastSampling: "Rychlá kontrola a předložení vzorků",
        recheckInspection: "Opětovná kontrola",
        recheckInspectionWechat: "Re-analýza",
      },
      timelyInspectionTypes: {
        timelyInspection: "Kontrola kvality v reálném čase",
      },
      pushActionTypes: { email: "Pošta", interfacePush: "Rozhraní push" },
      pushDetailWays: {
        riskAlert: "Upozornění na riziko",
        subscribe: "Přihlásit se na zprávy",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Zpráva o volání/konverzaci",
        inspectionResults: "Výsledky kontroly kvality",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Zpráva o volání/konverzaci",
        textContent: "Textový obsah hovorů/rozhovorů",
        inspectionResults: "Výsledky kontroly kvality",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Průměrné skóre v rámci skupiny",
        addOrSubtract: "Přímé plus nebo mínus body",
        noChange: "Žádné plus nebo mínus body",
      },
      defaultScore: {
        empty: "Prázdný",
        add: "Bonusové body",
        notAdd: "Žádné body navíc",
        sub: "Mínus body",
        notSub: "Žádné body snížené",
        na: "N/A",
        input: "Manuální vstup",
        choose: "Vybrat",
      },
      strategyTypes: {
        hitAdd: "Hit bonus",
        hitNotAdd: "Žádné body pro zásah",
        hitSub: "Hit mínus body",
        hitNotSub: "Hit nesnižuje body",
      },
    },
    download: {
      exportStatus: {
        prepare: "Při přípravě",
        process: "Probíhá",
        faild: "Export selhal",
        success: "Úspěch",
      },
    },
    messageType: {
      all: "All",
      monitor: "Okamžité sledování",
      trigger: "Oznámení o spuštění",
      timing: "Načasovací zpráva",
    },
    messagePushType: {
      all: "All",
      system: "Systémové zprávy",
      sdk: "Message sdk",
      push: "Rozhraní push",
      email: "Pošta",
      udesk: "Oznámení udesk",
      udeskWhite: "Oznámení systému pro služby zákazníkům",
      weChat: "Enterprise micro-message",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Přiřadit inspektora odběru vzorků",
      sampleInspector: "Inspektor odběru vzorků",
      assignReviewer: "Přiřadit hodnotitele",
      reviewer: "Recenzent",
      taskInspector: "Inspektor úkolů",
    },
    sdkPushType: {
      webHook: "Standardní webhook",
      dingDing: "Natírací robot",
      weChat: "Enterprise wechat robot",
      customer: "Vlastní obsah json",
    },
    inspectionRemarksType: {
      inspection: "Kontrola kvality",
      check: "Náhodná kontrola",
      review: "Přezkum",
    },
    segmentationStrategyType: {
      count: "Počet zpráv",
      spacing: "Interval zprávy",
    },
    segmentationStrategy: {
      byTime: "Načasování segmentace",
      customize: "Kombinované krájení",
    },
    inspectType: {
      commonInspect: "Obecná kontrola kvality",
      associateInspect: "Související kontrola kvality",
    },
    typoDetectionOperators: { less: "Méně", greater: "Větší" },
    autoAddTypes: {
      realTimeAdd: "Přidat v reálném čase",
      timingAdd: "Přidat načasování",
    },
    messageTypes: { text: "Textová zpráva", markdown: "Zpráva o označování" },
    inServiceStatus: {
      activated: "Aktivováno",
      disabled: "Zakázáno",
      inactivated: "Neaktivováno",
      resigned: "Výstup z podniku",
    },
    roles: { agent: "Zaměstnanec", customer: "Zákazník" },
    commentVisibleAuthority: {
      all: "Viditelné pro všechny",
      self: "Viditelné pouze pro sebe.",
      designation: "Viditelná určená osoba",
    },
    appealCheckingTask: {
      eCommerceReview: "E-commerce review",
      socialMediaComments: "Komentáře sociálních médií",
      largeModel: "Velký model",
      customModel: "Vlastní model",
      universalModel: "Obecný model",
      enterpriseWeChatText: "Enterprise micro-text",
      generalQualityInspection: "Obecná kontrola kvality",
      processQualityInspection: "Kontrola kvality procesu",
      voiceCopy: "Voice double disk",
      beEqualTo: "Rovná",
      notEqualTo: "Not equal",
      canAppeal: "Can appeal",
      noAppeal: "Nestížnost",
    },
    wechat: {
      redEnvelopes: "Červená obálka",
      text: "Text",
      picture: "Obrázek",
      voice: "Hlas",
      voiceCall: "Hlasový hovor",
      video: "Video",
      businessCard: "Vizitka",
      position: "Location",
      expression: "Výraz",
      link: "Link",
      applet: "Malý program",
      chatRecord: "Záznam chatu",
      mixedMessage: "Smíšená zpráva",
      file: "Soubor",
    },
    gong: {
      sessionActivity: "Aktivita relace",
      conversationAction: "Akce sezení",
      riskSemantics: "Riziková sémantika",
      daysNotFollowedUp: "Days not followed",
      waysideData: "Údaje o silnici",
      notStarted: "Nezahájeno",
      completed: "Dokončeno",
      haveInHand: "Probíhá",
      unpublished: "Nezveřejněno",
      published: "Published",
      knowledgePoints: "Znalostní body",
      technologicalProcess: "Proces",
      notMarked: "Neoznačeno",
      inAnnotation: "In callout",
      annotated: "Označeno",
    },
    qualityChecingTask: {
      appealCanceled: "Odvolání zrušeno",
      complaintExpired: "Stížnost vypršela",
      incomingCall: "Příchozí hovor",
      exhale: "Vydechovat",
      incomingCallthirdParty: "Příchozí hovor (třísměrný)",
      incomingCallconsultation: "Příchozí hovor (poradenství)",
      incomingCallforcedInsertion: "Příchozí hovor (silný)",
      incomingCallmonitoring: "Příchozí volání (naslouchání)",
      incomingCalltransfer: "Příchozí hovor (převod)",
      incomingCallinterception: "Příchozí hovor (intercept)",
      incomingCalltransferToExternalLine: "Příchozí hovor (převod mimo linku)",
      incomingCallthirdPartyExternalLine:
        "Příchozí hovor (třísměrná vnější linka)",
      incomingCallexternalLine: "Příchozí hovor (poradenství mimo linku)",
      outgoingCallthirdParty: "Výstupní (třísměrný)",
      outgoingCalltransfer: "Outcoming (transfer)",
      outgoingCallforcedInsertion: "Výstupní (silná)",
      outgoingCallmonitoring: "Odchozí (naslouchání)",
      outgoingCallinterception: "Odchozí hovor (intercept)",
      outgoingCalltransferToExternalLine: "Odchozí (převod mimo linku)",
      outgoingCallthreePartyExternalLine:
        "Odchozí hovor (třísměrná vnější linka)",
      customerServiceAnswer: "Odpověď zákaznické služby",
      customerServiceMissed: "Zákaznický servis zmeškaný",
      customerAnswer: "Odpověď zákazníka",
      customerMissed: "Zákazník vynechal",
      theTelephoneIsBusy: "Telefon zaneprázdněn",
      phoneOffline: "Telefon offline",
      customerQuickHanging: "Zákazník rychlé zavěšení",
      clientHangsUp: "Zákazník visí up",
      queueTimeout: "Časový limit fronty",
      giveUpQueuing: "Opustit frontu",
      outsideLineAnswering: "Vnější odpověď",
      externalLineIsNotConnected: "Vnější linka není připojená",
      noQueueSelected: "Není vybrána žádná fronta",
      notEvaluated: "Nevyhodnoceno",
      noEvaluationRequired: "Není třeba vyhodnotit",
      queuingSucceeded: "Úspěch fronty",
      noCustomerServiceOnline: "Online zákaznické služby",
      customer: "Zákazník",
      customerService: "Zákaznický servis",
      outsideLines: "Venku",
    },
    sessionActivity: {
      single: "Průměrný čas jednorázového volání",
      all: "Total calls",
      allTime: "Celková doba volání",
    },
    sessionAction: {
      salesProportion: "Poměr prodejních hovorů",
      salesLongest: "Průměrný nejdelší výkaz prodeje",
      customerLongest: "Průměrně nejdelší zákazník mluví",
      discussNumber: "Počet hloubkových diskusí",
      frequency: "Frekvence otázek",
    },
    monitorCriteria: { all: "Před a po", before: "Před", after: "Po" },
    monitorTimeCycle: { day: "Den", week: "Týden", month: "Měsíc" },
    monitorGroupTestType: {
      ab: "Srovnání obou skupin",
      a: "Skupina a",
      b: "Skupina b",
    },
    fields: {
      department: "Oddělení",
      customerTags: "Štítek zákazníka",
      member: "Členové",
      time: "Čas",
      cascade: "Kaskáda",
      anyMatch: "Jakýkoli zápas",
      keyWord: "Klíčová slova",
      keyEvents: "Klíčové události",
    },
    semanticIntelligence: {
      staff: "Zaměstnanec",
      customer: "Zákazník",
      employeesAndCustomers: "Zaměstnanci a zákazníci",
      notStarted: "Nezahájeno",
      haveInHand: "Probíhá",
      completed: "Dokončeno",
      callRecording: "Záznam hovoru",
      enterpriseWeChatCall: "Enterprise micro-talk",
      enterpriseWeChatText: "Enterprise micro-text",
      discourseMiningForObjectionHandling: "Vyřizování námitek",
      productSellingPointScriptsMining: "Výrobek prodejní bod talk mining",
      conversationScenario: "Scénáře sezení",
      topicOfConversation: "Téma",
      keyEvents: "Klíčové události",
      customerLabel: "Štítek zákazníka",
      includeAny: "Obsahuje jakékoli",
      includeAll: "Obsahuje všechny",
      and: "A",
      or: "Nebo",
      question: "Výslech",
      nonInterrogativeSentence: "Nesporná věta",
    },
    coach: {
      notPassed: "Not passed",
      analogTelephone: "Analogový telefon",
      enterScenarioInformation: "Zadejte informace o scénáři",
      generateDialogueFramework: "Vytváření dialogového rámce",
      generatePromptLanguage: "Generovaný prompt",
      aIGeneratedDialogue: "Dialogové okno generované z ai",
      system: "Systém",
      custom: "Vlastní",
      flow: "Proces",
      script: "Slova",
      express: "Výraz",
      emotion: "Emoce",
      rateAccordingToCourseConfiguration: "Skóre podle konfigurace kurzu",
      intelligentModelRating: "Skóre inteligentního modelu",
      allData: "Všechny údaje",
      dataFromThisDepartment: "Údaje oddělení",
      multidepartmentalData: "Multi-sector data",
      underReview: "V revizi",
      no: "Ne",
      yes: "Ano",
      automaticRating: "Automatické hodnocení",
      auditRating: "Auditní skóre",
      reRating: "Re-scoring",
      inAppeal: "Ve stížnosti",
      reviewed: "Přezkoumáno",
      notViewed: "Not viewed",
      viewed: "Viewed",
      uncorrected: "Neopraveno",
      corrected: "Opraveno",
      practice: "Cvičení",
      examination: "Zkouška",
      other: "Ostatní",
      notMeetingStandards: "Ne standardní",
      meetingStandards: "Standard",
      excellent: "Výborná",
      invalid: "Selhání",
      notStarted: "Nezahájeno",
      haveInHand: "Probíhá",
      ended: "Konec",
      completed: "Dokončeno",
      hangInTheAir: "Nedokončený",
      passed: "Pass",
      voiceConversation: "Hlasový dialog",
      textDialogue: "Textový dialog",
      slidePresentation: "Prezentace snímků",
      unpublished: "Nezveřejněno",
      published: "Published",
      narration: "Narrator",
      studentSpeaking: "Cadet speaking",
      robotTalk: "Roboti mluví.",
      knowledgeBaseQA: "Znalostní základna q & a",
      slide: "Posuv",
      negativeEmotions: "Negativní emoce",
      emotionallyNormal: "Normální nálada",
      incompleteScript: "Neúplný projev",
      completeScript: "Kompletní projev",
      normalSpeechSpeed: "Rychlost normální",
      speakTooFast: "Rychle příliš rychle",
      speakTooSlowly: "Rychlost pomalá",
      whole: "All",
      singlePage: "Jedna stránka",
      qA: "Jedna otázka a jedna odpověď",
      situationalDialogue: "Situační dialog",
      misses: "Zmizela",
      hit: "Hit",
      sequentialQA: "Sekvenční q & a",
      randomQA: "Náhodné otázky",
      mastered: "Mastering",
      notMastered: "Ne zvládnout",
    },
    algorithm: {
      salesEmpowerment: "Empowerment sales",
      enterpriseWeChatVoice: "Enterprise wechat voice",
      enterpriseWeChatText: "Enterprise wechat text",
      waiting: "Čekání",
      extracting: "V extrakci",
      success: "Úspěch",
      fail: "Selhal",
      intelligentQualityInspection: "Inteligentní kontrola kvality",
      textDialogue: "Textový dialog",
      voiceCall: "Hlasový hovor",
      smartTags: "Smart label",
      satisfactionEvaluation: "Hodnocení spokojenosti",
      score: "Rating",
      grade: "Rating",
      customerServiceTeam: "Skupina zákaznických služeb",
      conversationAnalysis: "Analýza konverzace",
    },
    operatorListGather: {
      businessSummary: "Business summary",
      firstSentence: "První věta",
      lastSentence: "Poslední věta",
      allTime: "All time",
      nonBusinessWorkingHours: "Nepracovní doba",
      businessWorkingHours: "Pracovní doba",
    },
    callDialogueManage: {
      eCommerceReview: "E-commerce review",
      socialMediaComments: "Komentáře sociálních médií",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Přihlášení",
        email: "Poštovní schránka",
        password: "Heslo",
        forgetPwd: "Zapomněla jsem heslo",
        emailCannotBeEmpty: "Schránka nesmí být prázdná.",
        passwordCannotByEmpty: "Heslo nesmí být prázdné.",
        mustChangeLogin:
          "Sorry, your password must be reset, please contact your administrator to reset your password.",
        dismissionLogin: "Promiň, odešla jsi z práce.",
        forbiddenLogin: "Promiňte, váš účet je zakázán.",
        loginError: "Přihlášení selhalo, došlo k chybě schránky nebo hesla.",
        loginPrompt: "Přihlášení k systému",
        rememberMe: "Remember me",
      },
      logout: {
        logoutError:
          "Odhlášení se nezdařilo. Zkontrolujte síťové připojení nebo opakujte akci později.",
      },
      resetPassword: {
        forgetPwd: "Zapomněla jsem heslo",
        inputEmailPrompt:
          "Zadejte e-mail vašeho účtu níže a my vám pošleme e-mail a návod k obnovení hesla.",
        sendEmailSuccess:
          "Potvrzující dopis o změně hesla byl úspěšně zaslán do vyplněného e-mailu, zkontrolujte ho včas.",
        sendEmailFailed:
          "Odeslání poštovní schránky se nezdařilo. Zkontrolujte síťové připojení nebo opakujte akci později.",
        setNewPassword: "Nastavit nové heslo",
        passwordCannotEmpty: "Heslo nesmí být prázdné",
        passwordFormWrong: "Nesprávný formát hesla",
        confirmPasswordWrong: "Zadané dvě hesla nejsou stejné.",
        passwordResetSuccess: "Resetování hesla bylo úspěšné.",
        passwordResetFailed:
          "Resetování hesla se nezdařilo. Zkontrolujte síťové připojení nebo opakujte akci později.",
        linkTimeover: "Resetovat odkaz hesla vypršela, zkuste to znovu",
      },
      form: { title: "Zobrazit formulář případu" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Poštovní schránka",
          emailFormatError: "Chyba formátu schránky, prosím znovu zadejte!",
          forgotPassword: "Zapomněl jste heslo?",
          describe:
            "Zadejte e-mail vašeho účtu níže a my vám pošleme e-mail a návod k obnovení hesla.",
          send: "Odeslat",
          emailCannotBeEmpty: "Poštovní schránka nemůže být prázdná",
          resetPasswordExpire:
            "Resetovat odkaz hesla vypršela, zkuste to znovu",
        },
        resetPassword: {
          setPassword: "Nastavit nové heslo",
          newPassword: "Nové heslo",
          repeatPassword: "Duplicitní heslo",
          resetPassword: "Obnovit heslo",
          passwordCannotBeEmpty: "Nové heslo nemůže být prázdné.",
          confirmPasswordCannotBeEmpty: "Duplicitní heslo nemůže být prázdné.",
          passwordsNotMatch: "Dvě hesla jsou nekonzistentní!",
          passwordFormWrong: "Heslo nemůže být v čínštině a číslo je 6-14!",
          linkExpiration: "Resetovat odkaz hesla vypršela, zkuste to znovu",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Nemáte oprávnění k zobrazení této stránky",
      },
    },
    downloadCenter: {
      name: "Download center",
      saveTime: "(30 dní ve výchozím nastavení)",
      headerTitle: "Stáhnout soubor",
      download: "Stáhnout",
      downloadPromp: "Úloha stahování byla předložena, prosím jděte",
      viewProgress: "Zobrazit pokrok",
    },
    uploadCenter: { name: "Upload center" },
    tasks: {
      index: {
        name: "Jméno",
        remark: "Popis",
        qualityCheckTasksTitle: "Pracovní stůl",
        informationCollection: "Úkoly sběru",
        qualityCheckTasks: "Kontrola kvality úkol",
        wechatQaTasks: "Úloha analýzy",
        moduleName: "Použít šablonu nové",
        creatNewQualityCheckTask: "Nová úloha",
        generalInspectionList: "Obecná zpráva o kontrole kvality",
        editQualityCheckTask: "Úprava kontroly kvality",
        creatNewQualityCheckTaskError:
          "Vytvoření nové kontroly kvality se nezdařilo.",
        editQualityCheckTaskError: "Úprava kontroly kvality se nezdařilo.",
        type: "Typ formuláře",
        singleScore: "Single score",
        singleScoreNullError: "Single score cannot be empty",
        inspector: "Inspektor kvality",
        inspectDataSource: "Zdroj dat",
        taskName: "Název úlohy",
        inspectionType: "Typ kontroly kvality",
      },
      manage: {
        screeningCondition: "Stav filtru",
        template: {
          condition: {
            index: { name: "Stav", existingConditions: "Existující podmínky" },
            detail: {
              id: "ID",
              name: "Název stavu",
              remark: "Popis",
              checkRange: "Rozsah detekce",
              operator: "Operátor",
              operatorLogic: "Logika operátoru",
            },
          },
          checkPointTest: {
            title: "Test pravidla",
            running: "Pravidla v testu, prosím později...",
            clearSuccess: "Úspěšně vyprázdněn simulovaný dialog",
            mock: "Simulovaný dialog",
            save: "Uložit vybrané dialogové okno",
            saveAll: "Uložit všechny rozhovory",
            saveSuccess: "Konverzace úspěšně uložena",
            close: "Exit",
            delete: "Smazat vybrané",
            clear: "Prázdný",
            customer: "Zákazník",
            agent: "Zákaznický servis",
            test: "Test",
            request:
              "Po vyčtení dialogového okna simulace nelze obnovit. Potvrdíte, že to vyčistíte?",
            deletePopconfirmContent:
              "Opravdu chcete odstranit vybrané dialogové okno?",
          },
          checkPoint: {
            index: {
              name: "Nastavení pravidla",
              wechatTitle: "Konfigurace modelu analýzy",
              existingCheckPoints: "Stávající pravidla kontroly kvality",
              currentCheckPointScore:
                "Aktuální skóre pravidel kontroly kvality je",
              currentCheckPointScoreWechat: "Aktuální skóre modelu analýzy je",
              currentCheckPointWeight:
                "Celková hmotnost stávajícího pravidla kontroly kvality je",
              weightTotalScoreAndItemScore: "Celkové skóre: 100, single score:",
              template: "Šablona pravidel kontroly kvality",
              import: "Šablona pravidel kontroly kvality importu",
              getTemplateError:
                "Selhalo získání šablony pravidel kontroly kvality.",
              tabs: { template: "Šablona", demo: "Vzorek" },
              customize: "Prázdná šablona",
              move: "Drag and drop to adjust classification and sorting",
              classification: "Klasifikace kontroly kvality",
              classificationWeChat: "Klasifikace modelu",
              first: "Klasifikace úrovně 1",
              second: "Sekundární klasifikace",
              new: "Nové pravidlo kontroly kvality",
              weChatNew: "Přidat model analýzy",
              createWeChat: "Nový model analýzy",
              empty: "Prozatím nejsou žádná pravidla pro kontrolu kvality,",
              wechatEmpty: "Žádný model analýzy,",
              newSecond: "Nová sekundární klasifikace",
              newFirst: "Nová klasifikace první úrovně",
              checkPoint: "Pravidla kontroly kvality",
              checkPointWeChat: "Model analýzy",
            },
            detail: {
              name: "Jméno",
              remark: "Popis",
              type: "Typ",
              deduction: "Odpočty",
              rule: "Pravidla",
              highlighted: "Zvýraznit",
              gradeType: "Metoda hodnocení",
              interactiveCategory: "Klasifikace interakcí",
              predeterminedScore: "Skóre",
              weight: "Váha",
              na: "N/A",
              default: "Výchozí skóre",
              preRule: "Pravidlo předpokladu",
              analysisHighlighted: "Pravidla pro analýzu",
              categorySelectPrompt: "Vyberte kategorii",
              checkHighlightArrayError:
                "Pravidlo se nezhoduje se zvýrazněním, klepněte znovu na tlačítko pravidla parsování.",
              foctorsHolder: "Vyberte prvek",
              foctorsErrorNone:
                "Pravidlo pro více prvků je prázdné, prosím přidejte typ více prvků.",
              naError: "Nelze uzavřít položku, pokud je výchozí skóre n/a",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/a je nepoužitelná, která stanoví předpoklady pro pravidlo kontroly jakosti. Je-li splněna podmínka, nepoužije se toto pravidlo. Není-li podmínka splněna, není pravidlo zjištěno;\nPopis předpokladů: "&&" znamená "a", "||" znamená "nebo", "!" Označuje "ne", vyplňte příklad "(R1 || R2) && R3"',
                applicationMessageType: "Typ zprávy aplikace",
                selectAll: "Vybrat vše",
                highlightTags: "Zvýraznit označení:",
                ruleType: "Typ pravidla",
                dataSource: "Zdroj dat",
                whole: "All",
                enterpriseMicroConversation: "Enterprise micro-session",
                enterpriseAndMicroCommunication: "Enterprise micro-talk",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Úsudkový uzel",
                    dialogueNode: "Uzel dialogu",
                    editNodes: "Upravit uzel",
                  },
                  nodeConfig: {
                    nodeName: "Název uzlu",
                    nodeRules: "Pravidla uzlu",
                    scoringLogic: "Bodování logiky",
                    score: "Skóre:",
                    rule: "Pravidla",
                    multiBranchLogic: "Multi-branch logic",
                    highlight: "Zvýraznit",
                    submit: "Podání",
                  },
                  ruleList: {
                    branch: "Pobočka",
                    branchName: "Název pobočky:",
                    addRules: "Přidat pravidlo",
                  },
                  sidebar: { node: "Uzel" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Automatické dokončení",
                      canvasAdaptation: "Adaptivní plátno",
                      copy: "Kopírovat",
                      delete: "Smazat",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Jako cíl připojení nelze použít uzel pro děti úsudkového uzlu.",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Počáteční uzel nemůže být cílem připojení",
                      startingNodeCannotConnectMultipleNodes:
                        "Počáteční uzel nemůže připojit více uzlů",
                      nodeCannotBeConnectedToItself:
                        "Uzel se nemůže připojit k sobě.",
                      startNode: "Zahajovací uzel",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Zrušit vybrat vše",
                    selectAll: "Vybrat vše",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Soulad",
                    gradedEvaluation: "Hodnocení stupně",
                  },
                },
              },
            },
            configuration: {
              title: "Pravidla kontroly kvality",
              wechatTitle: "Model analýzy",
              neme: "Jméno",
              errorMessage: "Odstranění selhalo s alespoň jednou podmínkou",
              desc: "Popis",
              status: "Stav",
              yes: "Efektivní",
              no: "Neplatný",
              namePlaceHolder: "Zadejte název štítku",
              desPlaceHolder: "Zadejte prosím popis",
              nameEmpty: "Název nemůže být prázdný",
              desEmpty: "Popis nemůže být prázdný",
              type: "Typ",
              ruler: "Inteligentní pravidla",
              machineLearning: "Režim sebeučení",
              autoScore: "Smart scoring (old)",
              manualScore: "Ruční skóre",
              interaction: "Detekce interakce (starý)",
              typeTips: {
                model:
                  'Inteligentní model: jedná se o přednastavený systém kontroly kvality, který může rychle kontrolovat problémy existující ve službě bez konfigurace a údržby. Tento model je obecnější, pokud nesplníte obchodní požadavky, použijte prosím "inteligentní pravidla" nebo "manuální skóre".',
                rule: "Inteligentní pravidla: automatické hodnocení podle pravidla ai",
                manual:
                  "Manual scoring: manual quality inspection, manual scoring by manual",
              },
              model: "Model:",
              ruleType: {
                words: "Slova",
                interaction: "Interakce",
                feature: "Features",
                information: "Informace",
              },
              testingMode: {
                all: "Detekce všech klíčových slov.",
                any: "Odhalit libovolné klíčové slova",
              },
              operatorTypesOfInteraction: {
                question: "Jedna otázka a jedna odpověď",
                element: "Prvky odpovědí",
                repet: "Duplikování kontextu",
                snatch: "Sbírat slova",
                silent: "Ticho",
              },
              role: "Role detekce:",
              preposition: "Předoperátor:",
              range: "Rozsah detekce:",
              from: "Sekce",
              to: "Věta k č.",
              end: "Věta",
              hasOldSilentTip:
                'Hello, in order to improve the accuracy, "intelligent rules-interaction-silence" has been upgraded to "response timeout". Doporučuje se aktualizovat pravidla používání!',
              changeToOld: "Přepnout na odkaz",
              changeToNew: "Přepnout na novou verzi",
              logic: {
                logic: "Bodování logiky",
                naTip:
                  "N/a je nepoužitelná, která stanoví předpoklady pro pravidlo kontroly jakosti. Pokud jsou podmínky splněny, toto pravidlo se nepoužije. Pokud nejsou splněny podmínky, pravidlo není zjištěno.",
                score: "Skóre:",
                na: "N/A：",
                naDes: "Tato položka není zapojena do povolené detekce",
                preRule: "Pravidla předpokladu:",
                notCatchPreRule: "Pokud nejsou splněny podmínky:",
                naChecked: "Při nárazu n/a:",
                preRuleEmpty: "Pravidlo předpokladu nemůže být prázdné",
                operatorEmpty:
                  "Nezbytná podmínka nemůže být v operátorovi null",
                multipleEmpty:
                  "Nezbytná podmínka nemůže být prázdná ve více logických skóre",
                average: "Průměrné skóre v rámci skupiny",
                addOrSubtract: "Přímé plus nebo mínus body",
                noChange: "Žádné plus nebo mínus body",
                rule: "Pravidla:",
                all: "Uspokojit všechny operátory",
                any: "Uspokojit svévolného operátora",
                customize: "Vlastní logika operátoru",
                multiple: "Multiple logic score",
                heightLight: "Zvýraznění:",
                machineLearningTip:
                  "V současné době je režim sebeučení účinný pouze pro projev jedné věty. Pro komplexní projev použijte inteligentní pravidla.",
                strategy: "Strategie:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Úspěšné předložení schválení",
            },
            components: {
              pageHeaderExtra: {
                test: "Test",
                formal: "Formální",
                submit: "Podání",
              },
              createModal: { index: { myRules: "Moje pravidla" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Automatizace procesu",
              screeningCondition: "Stav filtru",
              aiScreeningCondition: "Stav inteligentního filtru",
              rule: "Pravidla",
              automaticAddition: "Přidat automaticky",
              automaticAdditionTip:
                "Automaticky přidávat data do úkolů v reálném čase nebo v pravidelných intervalech pro kontrolu kvality ai",
              autoAdd: {
                autoAddType: "Metoda automatického přidání",
                addFrequency: "Přidat frekvenci",
                add: "Přidat",
              },
              automaticSamplingInspection: "Automatická kontrola odběru vzorků",
              automaticSamplingInspectionTip:
                "Automaticky extrahujte údaje, které vyžadují manuální kontrolu kvality, například každý den extrahujte 10% včerejších náhodných údajů pro manuální odběr vzorků.",
              everyAgentCheckLimit: "Maximum per agent draw",
              automaticAllocation: "Automatické přidělování",
              automaticAllocationTip:
                "Přidělení kontrol odběru vzorků: získané údaje se automaticky přidělují inspektorům kvality. Například 10% včerejších náhodných údajů se extrahuje denně pro ruční kontrolu odběru vzorků a průměrně jsou k této úloze přiděleni dva inspektoři jakosti.",
              automaticAllocationTip2:
                "Přidělení přezkumu: údaje o odvolání se automaticky přidělují inspektorovi kvality k přezkumu, například denní údaje o odvolání jsou rovnocenně přiděleny 2 inspektorům kvality tohoto úkolu k přezkumu.",
              automaticPush: "Automatické tlačení",
              automaticPushTip:
                "Oznámení o spuštění: oznámení/poplach během kontroly kvality, například v případě, že v zákaznické službě dojde k smrtelné chybě, je do skupiny enterprise micro odesláno oznámení.",
              automaticPushTip2:
                "Časová zpráva: odeslat výsledky kontroly kvality ve formě pošty, například odeslat výsledky kontroly kvality včerejšího manuálního odběru vzorků do poštovní schránky inspektora služeb zákazníků o 9:00 každý den.",
              automaticAssess: "Automatické hodnocení",
              automaticAssessTip:
                "Výsledky manuálního odběru vzorků jsou pravidelně srovnávány s výsledky kontrol kvality ai pro hodnocení přesnosti. Například míra přesnosti včerejších údajů o odběru vzorků se každý den hodnotí na 8:00.",
              reexaminationComplaint: "Hodnocení stížnosti",
              spoktReviewDetail: "Konfigurace podnikání",
              spoktReviewDetailTip:
                "Nastavit funkce, jako je seznam kontrol kvality, seznam náhodných kontrol, obchod se stížnostmi, seznam kontrol a poznámky",
              qaDetailAction: "Konfigurace kontroly kvality",
              spoktDetailAction: "Konfigurace odběru vzorků",
              reviewDetailAction: "Přezkoumat konfiguraci",
              appealAction: "Nastavení stížnosti",
              remarkTemplate: "Šablona poznámky",
              automaticArchiving: "Automatické archivování",
              conditionalRules: "Pravidla stavu",
              qualityInspectionConditions: "Podmínky kontroly kvality",
              unopened: "Ještě není otevřeno.",
              sampleInspectionDistributionModel: "Přidělení vzorků",
              reviewAllocationModel: "Přehodnotit přidělení",
              distributionModelTitle: "Politika přidělování:",
              pushRules: "Tlačit pravidla",
              push: "Push",
              assessRules: "Pravidla hodnocení",
              on: "on",
              off: "off",
              updateStatusSuccess: "Změna stavu úspěšná!",
              updateStatusFailed:
                "Změna stavu selhala, zkuste to znovu později!",
              sampleNumberOrPercent: "Počet nebo poměr odběru vzorků",
              inspectorList: "Určený inspektor kvality",
              numberOfSubmission: "Počet zastoupení povolených k předložení",
              rewivewDays: "Den",
              TimeOfSubmission: "Datum platnosti předložení prohlášení",
              rewivewSecond: "Times",
              switchOn: "Otevřít",
              switchOff: "Close",
              notMatchAnyRule: "When no rules are matched:",
              appealOptionsList: {
                hiddenInspector: "Stížnost skryté informace inspektora",
                hiddenHit:
                  "The appeal page supports highlighting and positioning hits.",
              },
              caseLibrary: "Synchronizace knihovny případů",
              caseLibrarySyncTip:
                "Synchronizovat případy přidané systémem qa do systému služeb zákazníkům",
              addCase: "Zvýšit synchronizaci",
              caseLibraryId: "Knihovna případů",
              caseLibraryIdTip: "Základní seskupování qa systému",
              udeskCaseLibraryIdTip:
                "Seskupování případů systému služeb zákazníkům",
              syncHis: "Případ synchronní historie",
              otherSettings: "Další nastavení",
              udeskCaseLibraryId: "Skupina push case",
              url: "Push url",
              urlTip:
                "Zadejte prosím adresu rozhraní začínající http:// nebo https://",
            },
            templateNew: {
              secondaryReview: "Sekundární přezkum",
              numberOfAppealsAllowedToBeSubmitted:
                "Počet odvolání povolených k podání",
              validityOfSubmissionOfAppeal: "Platnost podání odvolání",
              validityOfAppealReview: "Platnost přezkumu odvolání",
              hours: "Hodiny",
              businessSummary: "Business summary",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Dolní hranice extrakce na agenta",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Souhrn podnikání je automaticky generován velkým modelem a rozměr lze upravit podle obchodní situace.",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Strategie přidělení pouze pro hodnotitele úrovně 1",
              secondaryCompounding: "Sekundární kompozit:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Pouze sekundární hodnotitelé podporují rovné rozdělení",
              secondLevelReviewer: "Recenzent druhé úrovně:",
              samplingInspectionTarget: "Cíl pro kontrolu odběru vzorků",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Nastavte cíl, který má být dokončen manuální inspekcí odběru vzorků, a uveďte výzvu, pokud cíl není dosažen nebo překročen. Například, každá zákaznická služba by měla dokončit inspekci odběru vzorků ze 100 položek za měsíc, a když je cíl méně než 80 položek, bude na stránce kontrola kvality a kontrola vzorků podávat výzvu.",
            },
            component: {
              verificationFailed: "Ověření selhalo",
              pleaseAddASecondLevelQualityInspector:
                "Prosím, přidat inspektor kvality druhé úrovně",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Výzva",
                    fieldName: "Název pole",
                    fieldDescription: "Popis pole",
                    generateContent: "Generovat obsah",
                    enabled: "Povoleno",
                    generationMethod: "Metoda generace",
                    automaticGeneration: "Automaticky generováno",
                    manualTrigger: "Ruční spouštěč",
                    filterCondition: "Stav filtru",
                    return: "Return",
                    preserve: "Uložit",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Generovat výsledky" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Nekvalifikované",
                    limitedScope: "Omezený rozsah",
                    add: "Přidat",
                    thereIsCurrentlyNoDataAvailable: "Žádné údaje",
                  },
                },
              },
              index: {
                fieldName: "Název pole",
                fieldDescription: "Popis pole",
                nothing: "Žádné",
                generationMethod: "Metoda generace",
                generateManually: "Generovat ručně",
                automaticGeneration: "Automaticky generováno",
                generateContent: "Generovat obsah",
                notQualified: "Nekvalifikované",
                filterCondition: "Stav filtru",
                enabled: "Povoleno",
                type: "Typ",
                system: "Systém",
                custom: "Vlastní",
                operation: "Operace",
                edit: "Upravit",
                areYouSureToDeleteIt: "Opravdu chcete odstranit?",
                delete: "Smazat",
                addField: "Přidat pole",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Konfigurace role",
              roleConfigTip:
                "Používá se pro konfiguraci pravidel rozpoznávání pro zpracování oddělení reproduktorů, pokud jsou importované údaje o volání mono. Dodržuje pravidla pro roli agenta",
              operator: "Operátor",
              operatorLogic: "Logika operátoru",
            },
          },
          manualAdd: {
            index: {
              name: "Přidat ručně",
              tip: "Používá se k filtrování náhledových dat manuální konfigurací kritérií a rychle je přidá do úkolu kontrola kvality.",
              screeningCondition: "Přidat podle kritérií filtru",
              aiScreeningCondition: "Stav inteligentního filtru",
              rule: "Pravidla",
              createTime: "Přidat čas",
              filterTitle: "Filtrovat obsah",
              filterRule: "Stav filtru",
              startTime: "Čas zahájení",
              endTime: "Konec času",
              records: "Importovat záznamy",
              createUserName: "Operátor",
              successCount: "Úspěšně přidáno",
              conditionRule: "Pravidla stavu:",
              preview: "Náhled",
              previewData:
                "V současné době existují {0} odpovídající kritéria filtru, byly přidány úkoly {1}, lze přidat úkoly {2} a úkoly {3} nelze přidat.",
              failCount: "Přidání selhalo",
            },
          },
          basicInfo: {
            index: {
              title: "Základní informace",
              name: "Jméno",
              taskName: "Název úlohy",
              taskNamePlaceholder: "Zadejte název úlohy",
              requiredName: "Název úlohy nemůže být prázdný",
              taskRemarkPlaceholder: "Zadejte prosím popis úkolu",
              requiredRemark: "Popis úlohy nemůže být prázdný",
              remark: "Popis",
              taskRemark: "Popis úlohy",
              source: "Zdroj dat",
              benchmarkScore: "Skóre benchmarku",
              benchmarkScorePlaceholder: "Zadejte referenční skóre",
              scoreLowerLimit: "Minimální skóre dolní limit",
              scoreUpperLimit: "Maximální skóre cap",
              inspectType: "Typ kontroly kvality",
              nlpModel: "Model nlp",
              commonInspect: "Obecná kontrola kvality",
              associateInspect: "Související kontrola kvality",
              associateCycle: "Korelační období",
              associateRegulation: "Pravidlo přidružení",
              judgeStrategy: "Logika provádění",
              rule: "Pravidla",
              commonTip:
                "Obecná kontrola kvality: použitelná na jednotnou komunikaci a dialog a kontrola kvality obsahu jednotné komunikace.",
              associateTip:
                "Související kontrola kvality: použitelné pro více komunikací a rozhovorů, spojující obsah více komunikací pro kontrolu kvality.",
              cycleTip:
                "Pokud existuje více souvisejících komunikací a dialogů, zadají se údaje v přidruženém období do úkolu pro kontrolu kvality.",
              scoreLowerLimitPlaceholder: "Zadejte minimální limit skóre",
              scoreUpperLimitPlaceholder: "Prosím, zadejte maximální skóre cap",
              call: "Hlasový hovor",
              dialogue: "Textový dialog",
              user: "Inspektor kvality",
              wechatUser: "Operátor",
              noDesignated: "Neurčit",
              type: "Typ formuláře",
              singleScore: "Single score",
              conduct:
                "Nejprve vyplňte následující informace a klepněte na tlačítko další",
              conduct2:
                "Prosím, vyplňte konfiguraci pravidel kontroly kvality a kontrolních bodů kvality. Pokud jste dokončili konfiguraci, můžete klepnout na příručku další nebo výstup",
              conduct3:
                "Hodnocení kontroly kvality je konfigurováno podle stanoviště kontroly kvality a jeho hodnocení. Můžete definovat různé úrovně výsledků kontrol kvality. Pokud nepotřebujete rating, můžete tento krok přeskočit. Pokud jste dokončili konfiguraci, můžete klepnout na tlačítko další nebo ukončit příručku.",
              conduct4:
                "Parametry dat, které budou automaticky přidány do úkolu, můžete nastavit v závislosti na charakteristikách dat, které by úloha měla kontrolovat v poloze automatického přidání. Pokud zatím nepotřebujete automaticky přidat data, klepněte na tlačítko přeskočit. Pokud jste dokončili konfiguraci, klepněte na tlačítko další.",
              conduct5:
                "If you are using mono recording, please be sure to configure this item and click next. Please use only the voice of the customer service to help the system identify the different tracks between the customer service and the customer. Pokud používáte dvoukanálový záznam nebo jste dokončili konfiguraci, klepněte na tlačítko přeskočit",
              conduct6:
                "Můžete použít funkci manuální přidání k zavedení stávajících komunikačních dat v systému pro kontrolu kvality, nebo jste podal ruční přidávací úlohu, klepněte na tlačítko dokončit přímo",
              helper: "Zobrazit dokumentaci nápovědy",
              quit: "Ukončit spuštění",
              next: "Další krok",
              complete: "Kompletní",
              skip: "Přeskočit",
              cancel: "Storno",
              delete: "Smazat",
              save: "Uložit",
            },
            template: {
              samplingInspector: "Inspektor odběru vzorků",
              sessionType: "Typ relace",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Pracovní stůl kontroly kvality",
              search: "Filtr",
              export: "Export",
              condition: "Stav",
              logic: "Logika",
              reQualityInspection: "Opětovná kontrola",
              reQualityInspectionUnderWay: "Opakovaná kontrola...",
              reQualityInspectionUnderWayTip:
                "Opouštění stránky po uzavření okna nemá vliv na úkol",
              reQualityInspectionDone: "Opakovaná kontrola byla dokončena",
              reQualityInspectionDoneTip:
                "Opakovaná kontrola byla úspěšně dokončena, klikněte na tlačítko potvrzení.",
              endReQualityInspection: "Ukončit úlohu",
              alreadyDoneTip: "Dokončeno",
              totalTip: "Celkem",
              unit: "Článek",
              endReInspectionSuccess: "Úloha re-quality ukončená",
              closeWindow: "Zavřít okno",
              endReInspectionFailed:
                "Úloha re-quality selhala, zkuste to později znovu!",
            },
            detail: {
              name: "Podrobnosti kontroly kvality",
              reviewName: "Přezkoumat podrobnosti",
              spotCheckName: "Podrobnosti o odběru vzorků",
              audioText: "Nahraný text",
              callInfo: "Informace o volání",
              satisfactionEvaluation: "Hodnocení spokojenosti:",
              queueTime: "Čas strávený ve frontě:",
              ringingTime: "Doba zvonění:",
              callTime: "Volání událostí:",
              hangUpIdentity: "Call hung-up party:",
              location: "Úvod:",
              source: "Source:",
              agent: "Zákaznický servis:",
              deviceType: "Typ zařízení:",
              callType: "Typ volání:",
              phoneNumber: "Číslo relé:",
              task: "Úkoly:",
              commentsTip: "Please enter quality inspection comments",
              lastPage: "Předchozí stránka",
              nextPage: "Další stránka",
              score: "Hodnocení kontroly kvality",
              review: "Přezkum",
              file: "Archivace",
              changeList: "Změnit záznam",
              fullMarks:
                "Úplná značka (+ + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +",
              most: "Most",
              saveReview: "Předložení přezkumu",
              saveSpotCheck: "Kontrola a předložení vzorků",
              point: "Bod kontroly kvality",
              sampleAssignInspector: "Přiřadit inspektora odběru vzorků",
              reviewAssignInspector: "Přiřadit hodnotitele",
              sampleStatus: "Stav odběru vzorků",
              read: "Číst",
              user: "Uživatel",
              confirmer: "Potvrzená osoba",
              voiceDownloadError:
                "Hlasové stahování selhalo, zkuste to znovu později!",
              smartTags: "Smart label",
              interactiveTip:
                "Použít rozpoznávání interakcí k odhalení použití faq pro zákaznické služby",
              interactiveIsEmptyTip:
                "Žádný interaktivní rozpoznávací obsah nebyl zjištěn",
              businessRecords: "Obchodní záznamy",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat: "Analýza skupiny wechat",
            },
          },
        },
      },
      cards: {
        health: "Kontrola kvality zdraví",
        accuracy: "Míra schválení objevu",
        sessionsTotal: "Celkem sezení",
        enable: "Povolit",
        disable: "Zakázat",
        none: "Ne",
        call: "Volání",
        dialogue: "Dialog",
        taobao: "Elektronický obchod",
        wecom: "Relace",
        ticket: "Pracovní pořadí",
      },
      component: {
        backup: "(Backup)",
        taskSaveAsSucceeded: "Úloha uložená jako úspěšná",
        copyContent: "Kopírovat obsah",
        essentialInformation: "Základní informace",
        ruleConfiguration: "Nastavení pravidla",
        basicInformationQualityInspector:
          "Základní informace-inspektor kvality",
        qualityInspectionRating: "Hodnocení kontroly kvality",
        roleConfiguration: "Konfigurace role",
        autoAddConfiguration: "Automaticky přidat konfiguraci",
        autoPushConfiguration: "Automatická nastavení stisknutí",
        automaticSamplingConfiguration: "Automatická konfigurace odběru vzorků",
        automaticAllocationConfiguration: "Automatická konfigurace přidělování",
        businessConfiguration: "Konfigurace podnikání",
        caseBaseConfiguration: "Knihovna případů",
      },
      template: {
        cancel: "Storno",
        complete: "Kompletní",
        sort: "Řadit",
        ruleApproval: "Schválení pravidla",
        enterpriseMicroConversationAnalysisTask:
          "Enterprise micro-session analysis task",
        enterpriseAndMicroVoiceAnalysisTask:
          "Enterprise micro voice analysis task",
        saveTaskAs: "Uložit úlohu",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Podrobnosti o pravidlech" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Úprava byla úspěšná",
            automaticApproval: "Automatické schválení",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Úprava byla úspěšná",
            test: "Test",
            formal: "Formální",
            adopt: "By",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Určitě odmítnete",
              pleaseEnterTheRejectionReason:
                "Prosím, zadejte důvod pro odmítnutí",
              reject: "Odmítnuto",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Úprava byla úspěšná",
              taskName: "Název úlohy",
              dataSource: "Zdroj dat",
              qualityInspectionType: "Typ kontroly kvality",
              state: "Stav",
              updateTime: "Čas aktualizace",
              updatedBy: "Aktualizovat osobu",
              approver: "Approver",
              reasonForRejection: "Důvod pro odmítnutí",
              operation: "Operace",
              details: "Podrobnosti",
              adopt: "By",
              whole: "All",
              approvalStatus: "Stav schválení",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Přehled",
      hi: "Hi,",
      standardEdition: "Standard edition",
      call: "Bilance kontroly kvality hlasu",
      text: "Doba platnosti kontroly kvality textu",
      collectionTasks: "Shromážděné úkoly kontroly kvality",
      noCollection: "Žádná sbírka úkolů kontrol kvality,",
      goToCollecte: "Přejít na sběr",
      yesterdayOverView: "Včerejší obchodní statistiky",
      yesterdayAgent: "Včerejší seznam služeb zákazníků",
      yesterdayInspWork: "Včerejší pracovní situace inspektora kvality",
      operationalStatistics: {
        title: "Podnikatelské statistiky",
        qualityTesting: "Kontrola kvality",
        spotCheck: "Náhodná kontrola",
        appeal: "Stížnost",
        chartTitles: {
          qualityTestingTrend: "Vývoj objemu kontroly kvality",
          qualityTestingAnalysis: "Kontrola kvality úkol",
          spotCheckTrend: "Trend odběru vzorků",
          spotCheckAnalysis: "Situace úlohy odběru vzorků",
          appealTrend: "Grievance trends",
          appealAnalysis: "Status odvolání mandát",
        },
        titles: {
          qualityDataTend: "Distribuce dat kontrola kvality",
          qualityCover: "Krytí kontroly kvality",
          qualityAverageTime: "Průměrná doba čekání kontroly kvality",
          qualityCoverTend: "Trend pokrytí kontroly kvality",
          handCover: "Ruční manipulace",
          checkCover: "Pokrytí odběru vzorků",
          resAverageTime: "Průměrný čas zpracování",
          handTend: "Trend ručního zpracování",
          dataTend: "Distribuce úloh dat",
        },
        qualityAverage: "Průměrné hodnocení kontroly kvality",
        scoreTrend: "Kontrola kvality skóre trend",
        qualityAverageTime: "Průměrná doba čekání kontroly kvality",
        callCoverage: "Prohlídka kvality hlasu",
        textCoverage: "Kontrola kvality textu",
        checkCount: "Objem údajů o odběru vzorků",
        siphonCount: "Extrakt datový svazek",
        reviewCount: "Prohlédnout svazek dat",
        appealCount: "Objem údajů o stížnosti",
        checkRate: "Procento náhodné kontroly",
        reviewRate: "Procento hodnocení stížnosti",
        PieTitle: {
          totalCount: "Celkový počet rozhovorů",
          totalCall: "Počet hlasových volání",
          totalIm: "Počet textových konverzací",
          inspectionCall: "Množství kontroly kvality hlasu",
          inspectionIm: "Množství kontroly kvality textu",
          checkCall: "Množství odběru hlasových vzorků",
          unCheckCall: "Hlas není vzorkem",
          checkIm: "Množství odběru vzorků textu",
          unCheckIm: "Množství textu bez vzorku",
          reviewCall: "Hlasitost prohlížeče hlasu",
          reviewIm: "Množství přezkumu textu",
          unReviewCall: "Hlasové nepřezkoumané množství",
          unReviewIm: "Počet nepřezkoumaných textů",
          unInspectionCall: "Hlasové množství není zkontrolováno",
          unInspectionIm: "Nekontrolované množství textu",
        },
        check_rate: "Pokrytí odběru vzorků",
        voice_check_rate: "Pokrytí hlasových vzorků",
        text_check_rate: "Pokrytí vzorků textu",
        sample_execute_time: "Průměrný čas zpracování vzorků",
        review_execute_time: "Průměrný čas zpracování stížností",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Pravidla kontroly kvality kanban",
          agent: "Seznam hodnocení služeb zákazníků",
        },
        tableTips: {
          conditions:
            "<Div> řadit celkový počet konverzací, které porušují a dodržují pravidla kontroly kvality. <Br/>\nKontrola kvality: název kontrolního místa kvality <br />\nCelkový počet nelegálních rozhovorů: celkový počet nezákonných rozhovorů o této položce kontroly kvality <br />\nProcento porušení: procento porušení = celkový počet porušení/celkový počet porušení </div>",
          agent:
            "<Div> sort the average rating of the agents <br />\nNázev zákaznické služby: název zákaznické služby <br />\nCustomer service group: the customer service group where the customer service is located (the organization information in the customer service information)<br />\nCelkový počet vyhodnocených rozhovorů: celkový počet diskusních diskusí o kvalitě zákaznických služeb <br />\nPrůměrné hodnocení kontroly kvality: průměrné hodnocení kontroly kvality agenta (celkové skóre/celkový počet rozhovorů)</div>",
        },
        tips: {
          total:
            "Celkový počet průjezdů/rozhovorů zadaných do úkolu kontrola kvality",
          agentTotal:
            "Celkový počet agentů pro kontrolu kvality, které vstoupily do úkolu kontroly kvality",
          avg: "Průměrné skóre kontrol kvality",
          violation:
            "Součet počet krát všech průjezdu dialogu kontrola kvality položky jsou zasažena a porušena",
          compliance:
            "Součet počet případů, kdy jsou položky kontrol kvality zasáhnuty v procesu kontroly kvality prostřednictvím dialogu",
          check: "Celkový počet manuálního odběru vzorků",
          appeal: "Celkový počet podaných stížností v celkovém počtu rozhovorů",
          review:
            "Celkový počet předložených hodnocení v celkovém počtu komunikačních rozhovorů",
        },
        subTips: {
          violation:
            "Míra porušení: celkový počet porušení/celkový počet kontrol kvality * 100%",
          compliance:
            "Míra souladu: celkový počet dobů souladu/celkový počet položek kontroly kvality * 100%",
          check:
            "Pokrytí odběru vzorků: celkový manuální odběr vzorků/celková kontrola kvality * 100%",
          appeal:
            "Odvolací sazba: celkový počet odvolání/celkový počet kontrol kvality * 100%",
          review:
            "Hodnotící sazba: celkový počet hodnocení/celkový počet kontrol kvality * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Nastavení systému",
          systemName: "Název systému",
          speakerRecognize: "Identifikace mluvčího",
          massage: "Oznámení zprávy",
          evaluate: "Umělé kognitivní hodnocení",
          evaluateTip:
            "Po otevření vyberte rychlou kontrolu kontroly kvality a rychlé ruční hodnocení",
          speakerRecognizeTip:
            "Pro přístup k nahrávání hovorů třetí strany prostřednictvím api je nutné určit, zda je levý kanál zákaznickou službou nebo zákazníkem.",
          speakerRecognizeTip2:
            "Jednokanálový záznam používá rozpoznávání textu a konfiguraci role pro definování úlohy agenta.",
          enabledImSendStatusFilter: "Filtrování zpráv kontrola kvality textu",
          enabledImSendStatusFilterTips:
            "Obsah detekce řeči operátorů při inspekci kvality nezahrnuje zprávy ve stavu [odejmuto], [nepodařilo se odeslat] a [nepřečtená zpráva offline]",
          lang: "Nastavení jazyka systému",
          zh: "Zjednodušená čínština",
          en: "English",
          tw: "Tradiční čínština",
        },
        template: {
          largeModelSettings: "Nastavení velkého modelu",
          indonesian: "Indonéština",
          vietnamese: "Vietnamština",
          portuguese: "Portugalština",
          malay: "Malajština",
          french: "Francouzština",
          spanish: "Španělština",
          japanese: "Japonština",
          thai: "Thajština",
          blockSystemSendingMessages: "Systém masky odeslat zprávu",
          textDataDesensitization: "Desensibilizace textových dat",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Když je přepínač zapnut, zobrazí se rozbalovací seznam pole desensibilizace (je podporován výběr více). Desenzibilizační pole zahrnuje jméno, číslo mobilního telefonu a adresu osoby.",
          desensitizingField: "Desensitizované pole",
          name: "Jméno osoby",
          cellphoneNumber: "Číslo mobilního telefonu",
          address: "Adresa",
          dataCleaning: "Čištění dat",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Po nastavení budou pravidelně vyčištěny údaje o konverzaci kontroly kvality a o relaci wechat a nelze je obnovit. Prosím operujte opatrně.",
          newCleaningRules: "Nové pravidlo pro uklízení",
          qualityInspectionRuleApproval: "Schválení pravidel kontroly kvality",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Po otevření musí být úprava pravidel kontroly jakosti schválena, než nabývá účinnosti.",
          maskOfflineMessageUnread: "Nepřečtená zpráva maska offline",
          privacyNumberProtection: "Ochrana čísel soukromí",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Po otevření, desensitizovat pole "zákaznický telefon" během volání a zobrazit ho jako 1881 * * * * * * *',
          shieldingWithdrawn: "Maska byla stažena",
          maskSendingFailed: "Odeslání masky se nezdařilo",
        },
        component: {
          conversation: "Volání",
          dialogue: "Dialog",
          weCom: "Enterprise wechat",
          day: "Den",
          week: "Týden",
          month: "Měsíc",
          year: "Rok",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Rozsah čištění",
            dataCleaningTimeSetting: "Nastavení času čištění dat",
            afterCleanUpTheData: "Po vyčištění dat",
            dataAfterComplaintCompletion: "Údaje po stížnosti",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Úspěšné provedení",
          saveSuccessful: "Úspěšně uložit",
          speechRecognition: "Rozpoznávání řeči",
          reExecuteErrorCorrection: "Znovu provést opravu chyb",
          confirmExecution: "Potvrdit provedení",
          voiceDetection: "Detekce hlasu",
          noiseDetectionPowerLevel:
            "Hladina výkonu detekce hluku <<<<= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =>",
          pleaseEnterTheThreshold: "Zadejte prahovou hodnotu",
          volumeDetectionPowerLevel: "Hlasitost detekce výkonu ≥",
          reset: "Resetovat",
          preserve: "Uložit",
        },
        coachTemplate: {
          french: "Francouzština",
          spanish: "Španělština",
          japanese: "Japonština",
          thai: "Thajština",
          loginPopupWindow: "Přihlášení pop-up okno",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Po otevření se při vstupu do inteligentního sparringového systému zobrazí pop-up okno.",
          title: "Název",
          text: "Text",
          checkBoxContent: "Zaškrtnutí pole obsah",
          informedConsentForm: "Formulář informovaného souhlasu",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Aby vám pomohlo zlepšit vaše komunikační a řečnické dovednosti a uplatnění odborných znalostí, které vám umožní praktikovat kdykoli a kdekoli a obdržet zpětnou vazbu v reálném čase, prostřednictvím této platformy od vás shromažďujeme následující informace:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Přečetl jsem si a plně pochopil tento formulář informovaného souhlasu a slavnostně jsem souhlasil s vyřizováním svých citlivých osobních údajů v souladu s cíli a způsobem uvedeným v tomto formuláři informovaného souhlasu.",
        },
      },
      systemIntegration: {
        title: "Nastavení systému",
        ccIntegrationTips:
          "Inteligentní kontrola kvality docking udesk call center popis dokument",
        unauthorized: "Neoprávněný",
        authorized: "Autorizovaný",
        udeskDomain: "Název domény systému pro služby zákazníkům",
        udeskToken: "Systémový klíč agenta",
        udeskAdminEmail: "Účet správce systému služby zákazníkům",
        password: "Heslo účtu správce",
        applyAuth: "Autorizace",
        cancelAuth: "Zrušit autorizaci",
        reApplyAuth: "Reautorizace",
        keyTips:
          "Správa pozadí systému pro služby zákazníkům-token pro ověřování api v jediném přihlášení",
      },
      customerField: {
        title: "Pole zákazníka",
        table: {
          propName: "Název pole",
          propDesc: "Popis pole",
          valueType: "Typ pole",
          propType: "Metoda vytvoření",
          createTime: "Čas vytvoření",
          canNotEdit: "Systémové pole nepodporují úpravu",
        },
        edit: "Upravit pole",
        new: "Nové pole",
      },
      wechatDocking: {
        title: "Enterprise micro docking",
        formLabels: {
          companyId: "Id enterprise",
          communicationKey: "Komunikační klíč",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "Url zpětné volání událostí",
          applicationName: "Název aplikace",
          applicationId: "Id aplikace webu",
          applicationSecret: "Aplikace tajné",
        },
        formTitle: {
          initialForm: "Počáteční nastavení",
          synchronizeForm: "Synchronizace adresáře",
          settingForm: "Nastavení aplikace",
        },
        qrCodeUrlTip:
          "Prosím, požádejte správce, aby skenoval dvourozměrný kód níže, aby navázal vývojovou aplikaci.",
      },
      taobao: {
        formLabels: {
          name: "Název obchodu",
          account: "Číslo účtu",
          password: "Heslo",
          confirm: "Potvrdit heslo",
          authorization: "Autorizace",
          switchOn: "Otevřít",
          switchOff: "Close",
        },
        tableLabels: {
          name: "Název obchodu",
          account: "Číslo účtu",
          authorization: "Autorizace",
          updateUser: "Nedávno aktualizovaná osoba",
          updateTime: "Poslední aktualizace",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Weifeng sekundární název domény",
          secretKeyTip:
            "Tajný klíč nájemce je získán v dokovacím místě systému weifeng pozadí systému.",
        },
      },
      user: {
        index: {
          name: "Řízení zaměstnanců",
          existingUsers: "Už jsou tu zaměstnanci.",
          deleteSuccess: "Úspěšně smazán",
          deleteFailed: "Odstranění selhalo",
        },
        detail: {
          accountPrompt: "Zadejte prosím poštovní schránku",
          resetPassword: "Obnovit heslo",
          passwordPrompt: "Heslo (6-14 kombinace písmen a čísel)",
          originPasswordPrompt: "Zadejte prosím počáteční heslo",
          resumePasswordPrompt: "Zadejte prosím znovu své heslo",
          passwordFormWrong: "Prosím, zadejte kombinaci 6-14 písmen a čísel!",
          resumePasswordWrong: "Dvě hesla jsou nekonzistentní!",
          suppertImageType: "Podpora jpg, gif, png, maximum 500kb",
          namePrompt: "Zadejte název",
          nickNamePrompt: "Zadejte prosím přezdívku",
          telephonePrompt: "Zadejte prosím telefonní číslo",
          roleSelectedPrompt: "Vyberte roli",
          agentIdPrompt: "Zadejte prosím číslo práce",
          disabled: "Zakázat",
          enabled: "Povolit",
          reloadBowerInfo:
            "Uživatelské informace změny, potřebujete aktualizovat prohlížeč?",
          saveUserError:
            "Nelze uložit informace o uživateli, zkuste to později.",
          resetPasswordSuccess: "Úspěšné resetování hesla",
          resetPasswordFailed: "Selhalo resetování hesla, zkuste to později.",
        },
      },
      userGroups: {
        index: {
          name: "Řízení skupiny zaměstnanců",
          existingUserGroups: "Stávající zaměstnanecká skupina",
        },
        detail: {},
      },
      role: {
        index: { name: "Řízení úloh", existingRoles: "Stávající role" },
        edit: {
          editRole: "Upravit roli",
          createRole: "Vytvořit roli",
          basicInfo: "Základní informace",
          taskPermissions: "Oprávnění úkolu",
          existingTasks: "Již úkoly",
        },
      },
      anomalousData: {
        title: "Abnormální data",
        list: {
          selectHolder: "Vyberte zdroj dat",
          businessTime: "Pracovní čas",
          businessId: "Id společnosti",
          export: "Export",
        },
      },
      templateComponent: {
        list: {
          title: "Trh s šablonami",
          name: "Název šablony",
          searchHolder: "Zadejte prosím obsah k dotazu",
          update: "Aktualizace",
          sort: "Řadit",
          moduleType: "Typ šablony",
          moduleSourceType: "Použitelný typ",
          fetchNum: "Počet nabytých krát",
          newest: "Nedávno aktualizováno",
          usedMost: "Sežeňte nejvíce",
          call: "Volání",
          dialogue: "Dialog",
          waiting: "K přezkoumání",
          cancel: "Unpublish",
          success: "Schváleno a zveřejněno",
          failed: "Odmítnutí auditu",
          no: "Nelze aktualizovat",
          ok: "Můžete aktualizovat",
          all: "All",
          delete: "Úspěšně smazán",
          downOk: "Úspěšně odstraněno",
          withdraw: "Odejmutí bylo úspěšné",
          updateOk: "Úspěšná aktualizace",
          get: "Get",
          width: "Stažení",
          getOk: "Úspěch nabytí",
          orgName: "Organizace",
          nowVersion: "Aktuální verze",
          lastedVersion: "Poslední verze",
          appStatus: "Stav aplikace",
          actions: "Operace",
          down: "Z police",
          updateTop: "Aktualizace",
          check: "Zobrazit",
          deleteWhen: "Potvrdit odstranění?",
          yes: "Ano",
          noo: "Ne",
          deleteOk: "Smazat",
          permissible: "Povoleno",
          noPermissible: "Není povoleno",
          openUp: "Otevřít",
          openDown: "Neotevřeno",
          task: "Kontrola kvality úkol",
          point: "Bod kontroly kvality",
          tag: "Sémantické označení",
          muti: "Více prvků",
          inter: "Jedna otázka a jedna odpověď",
          info: "Subjekt informací",
          concept: "Koncepční slova",
          detail: "Podrobnosti modulu",
          infomation: "Základní informace",
          detailConfig: "Podrobná konfigurace",
          twoEdit: "Sekundární úprava",
          mustUpdate: "Aktualizovat nyní",
          descition: "Popis šablony",
          descitionSize: "Popis šablony nemůže překročit 250 slov",
          stencilsDetail: "Podrobnosti šablony",
          updatedRecords: "Aktualizovat záznam",
          version: "Číslo verze",
          updateDescription: "Popis aktualizace",
          myModule: "Moje šablona",
          addModule: "Nový modul",
          publishVersion: "Release version",
          earliestVersion: "The earliest version",
          edit: "Upravit",
          add: "Nový",
          configNo: "Detaily konfigurace modulu nemohou být prázdné",
          updateNo: "Popis aktualizace nemůže být prázdný",
          editSuccess: "Úprava byla úspěšná",
          saveSuccess: "Úspěšně uložit",
          newer: "Aktualizace",
          sumbit: "Podání",
          cancelName: "Storno",
          nameRequired: "Název modulu je požadované pole",
          nameSize: "Název modulu nemůže překročit 16 znaků",
          publishStatus: "Stav publikování",
          submitUpdate: "Odeslat aktualizaci",
          updateVersion: "Aktualizovat verzi",
          updateExplain: "Aktualizovat instrukce",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon e-commerce",
        youtubeVideo: "Youtube video",
        amazon: {
          component: {
            queuing: "Fronta",
            notStarted: "Nezahájeno",
            inProgress: "Probíhá",
            success: "Úspěch",
            failed: "Selhal",
            successfullyCreated: "Úspěšně vytvořeno",
            editedSuccessfully: "Úspěšně upraven",
            operationSucceeded: "Operace byla úspěšná",
            deletedSuccessfully: "Úspěšně smazán",
            link: "Link",
            grabState: "Stav chycení",
            updateTime: "Čas aktualizace",
            grab: "Chytit",
            edit: "Upravit",
          },
          template: {
            amazonEcommerce: "Amazon e-commerce",
            thisProduct: "Tento produkt",
            competition: "Konkurence",
            new: "Nový",
            edit: "Upravit",
            link: "Link",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube video",
            thisProduct: "Tento produkt",
            competition: "Konkurence",
            new: "Nový",
            edit: "Upravit",
            link: "Link",
          },
          component: {
            queuing: "Fronta",
            notStarted: "Nezahájeno",
            inProgress: "Probíhá",
            success: "Úspěch",
            failed: "Selhal",
            successfullyCreated: "Úspěšně vytvořeno",
            editedSuccessfully: "Úspěšně upraven",
            operationSucceeded: "Operace byla úspěšná",
            deletedSuccessfully: "Úspěšně smazán",
            youTubeVideoLink: "Video odkaz na youtube",
            grabState: "Stav chycení",
            updateTime: "Čas aktualizace",
            grab: "Chytit",
            edit: "Upravit",
          },
        },
      },
    },
    demo: {
      title: "Inteligentní kontrola kvality",
      template: "Vyberte šablonu kontroly kvality",
      radio: "Vybrat formulář pro nahrávání",
      uploadAudio: "Nahrát nahrávku",
      uploadAudioTip:
        "Nahraná nahrávka podporuje pouze formáty wav a mp3 a velikost nepřesahuje 1g",
      uploadText: "Nahrát text",
      uploadTextTip:
        "Nahraný text podporuje pouze srt a txt formáty a velikost nepřesahuje 10m. Po načtení textu nebude asr již použit pro analýzu nahrávacího souboru.",
      uploadSuccess: "Nahrávání souboru úspěšně nahrané",
      uploadFailed: "Nepodařilo se nahrát záznamový soubor",
      startQualityInspection: "Zahájit kontrolu kvality",
      qualityInspectionStarted: "Kontrola kvality probíhá",
      downloadText: "Stažení textu",
      checking: "Pokrok kontroly kvality",
      checkingTip:
        "Očekává se, že bude trvat pár minut, prosím buďte trpěliví.",
      noCheckContent: "Dosud žádné výsledky kontroly kvality",
      noTextDownload: "Žádný text kontroly kvality nelze stáhnout!",
      pleaseCheckParams:
        "Zkontrolujte, zda chcete vybrat šablonu, typ hlasu a nahrát nahrávací soubor",
      inspectionFailed: "Kontrola kvality selhala, zkuste to později znovu!",
    },
    wechatQa: {
      baseInfo: { title: "Základní informace" },
      conversationSetting: {
        title: "Nastavení relace",
        segmentationStrategy: "Strategie krájení",
        rule: "Strategie",
        time: "Čas řezání",
        daily: "Každý den",
        passScore: "Způsobilé skóre sezení",
        ruleLogic: "Logika provádění",
      },
      message: {
        title: "Push events",
        table: {
          index: "Sériové číslo",
          name: "Jméno",
          type: "Režim tlačení",
          desc: "Popis",
          status: "Stav",
        },
        subTitle: "Seznam událostí",
      },
      checkPoint: {
        title: "Model analýzy",
        groups: "Seskupování modelů",
        newClassification: "Nová skupina",
        editClassification: "Upravit seskupování",
        benchmarkScore: "Skóre benchmarku",
        pointCount: "Společný model analýzy v současné klasifikaci",
        openPointCount: "Počet povolených je",
        closedPointCount: "Deaktivované množství je",
        scoreLowerLimit: "Minimální skóre dolní limit",
        scoreUpperLimit: "Maximální skóre cap",
        classificationName: "Název skupiny",
        classificationNamePlaceHolder: "Zadejte název skupiny",
        classificationRemark: "Popis skupiny",
        classificationRemarkPlaceHolder: "Zadejte prosím popis skupiny",
      },
      chartRecord: {
        title: "Enterprise wechat session",
        table: {
          id: "Id relace",
          type: "Typ relace",
          startTime: "Počáteční čas sezení",
          endTime: "Poslední konec sezení",
          groupId: "Id skupiny",
          client: "Zákazník",
          customer: "Zákaznický servis",
          averageScore: "Průměrné hodnocení kontroly kvality",
        },
        detail: {
          title: "Informace o sezení wechat enterprise",
          inspectionInfo: "Informace o analýze",
          chartInfo: "Informace o relaci",
          tasks: "Úloha analýzy",
          avgScore: "Průměrné skóre analýzy",
          qualified: "Kvalifikovaný",
          unqualified: "Nekvalifikovaná",
          taskTitle: "Použitelná šablona analýzy",
          info: "Analýza informací v reálném čase",
          today: "Dnešní novinky",
          history: "Historické zprávy",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname:
                  "Prosím, zadejte komentář/přezdívku",
                pleaseEnterTheGroupChatName: "Zadejte název skupiny chatu",
              },
            },
          },
          template: { whole: "All" },
        },
      },
      realTimeAnalysis: {
        title: "Analýza v reálném čase",
        table: {
          name: "Název scény",
          count: "Počet modelů analýzy",
          baseScore: "Skóre benchmarku",
          passScore: "Kvalifikované skóre",
          maxScore: "Maximální skóre cap",
          minScore: "Minimální skóre dolní limit",
          status: "Stav",
          actions: "Operace",
        },
        detail: {
          title: "Analýza scénářů v reálném čase",
          form: {
            name: "Název scény",
            prevCondition: "Podmínky spuštění scénáře",
            prevConditionToolTip:
              "Pokud je podmínka prázdná, bude scénář ve výchozím nastavení analyzován",
            prevConditionError:
              "Podmínky spuštění scénáře se nemohou shodnout s modelem analýzy",
            scoreInputPlaceholder: "Zadejte skóre",
            baseScore: "Skóre benchmarku",
            status: "Stav",
            passScore: "Kvalifikované skóre",
            maxScore: "Horní limit maximálního skóre",
            minScore: "Dolní mez minimálního skóre",
            analysisModel: "Model analýzy",
            message: "Oznámení o spuštění",
            pushType: "Režim tlačení",
            createAnalysisModel: "Přidat model",
            scoreUpperLimitError:
              "Dolní mez minimální skóre nemůže být větší než horní mez maximálního skóre.",
            table: { name: "Název modelu", rule: "Pravidla", score: "Skóre" },
            pushConfigSwitchTip1: "Když se objeví scéna",
            pushConfigSwitchTip2: "Times and above, push notifications",
          },
        },
      },
      smartCustomerTag: {
        title: "Inteligentní zákaznické označování",
        table: {
          name: "Název pravidla",
          tags: "Enterprise micro customer label",
          status: "Povolený stav",
          createUser: "Tvůrce",
          createTime: "Čas vytvoření",
        },
        detail: {
          name: "Název pravidla",
          condition: "Nastavit pravidla",
          logic: "Logika",
          status: "Povolený stav",
          tags: "Vybrat štítek",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Množství označené zákazníkem" },
        },
        messageFiltering: {
          template: {
            edit: "Upravit",
            messageContent: "Obsah zprávy",
            messageTypeID: "Id typu zprávy",
            messageTypeDescription: "Popis typu zprávy",
            messageFiltering: "Filtrování zpráv",
            suitableForFilteringDataWithoutQualityInspection:
              "Vhodné pro filtrování dat bez kontroly kvality",
            create: "Vytvořit",
            messageDescription: "Popis zprávy",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Čas mluvení",
          sessionType: "Typ relace",
          whole: "All",
          staff: "Zaměstnanec",
          customer: "Zákazník",
          callKeywords: "Volání klíčových slov",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Informace o mikrohlasu pro enterprise",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Archiv sezení",
        voiceCall: "Hlasový hovor",
        noMoreVoiceCalls: "Už žádné hlasové volání",
        enterpriseWechatVoiceCall: "Enterprise wechat voice call",
        enterpriseWechatConversationDetails:
          "Informace o sezení wechat enterprise",
        previous: "Předchozí článek",
        next: "Další článek",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Enterprise micro-session analysis task",
          enterpriseAndMicroVoiceAnalysisTask:
            "Enterprise micro voice analysis task",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat: "Analýza skupiny wechat",
      },
      wechatQaSettingEntry: { messageFiltering: "Filtrování zpráv" },
    },
    businessAnalysis: {
      keyword: "Název sestavy",
      createUser: "Tvůrce",
      createTime: "Čas vytvoření",
      updateTime: "Čas aktualizace",
    },
    semanticIntelligence: {
      applicationMessageType: "Typ zprávy aplikace",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "Prosím, zdokonalte konfiguraci",
              generatePromptLanguage: "Generovaný prompt",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Soulad",
              gradedEvaluation: "Hodnocení stupně",
            },
          },
          training: {
            index: {
              pleaseEnter: "Zadejte prosím",
              pleaseGeneratePrompt: "Generujte prosím výzvu",
              pleaseSelectADialogue: "Prosím, vyberte dialog",
              training: "Výcvik",
              dataType: "Typ dat:",
              voiceCall: "Hlasový hovor",
              textDialogue: "Textový dialog",
              generate: "Generovat",
              qualityInspectionResults: "Výsledky kontroly kvality:",
              hit: "Hit:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Název modelu",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Zadejte například název modelu, zda agent nemá žádnou činnost.",
            modelDescription: "Popis modelu",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Můžete podrobně popsat, co musíte testovat, což je pro model snadné pochopit. Například, zda zákaznická služba nemá žádnou činnost, to znamená, že zákaznická služba odpověděla na otázku zákazníka, otázka zákazníka však nebyla vyřešena nebo obsah odpovědi nemá nic společného s odpovědí zákazníka.",
            validity: "Účinnost",
            effective: "Efektivní",
            invalid: "Neplatný",
            type: "Typ",
            hintLanguage: "Výzva",
            editIntelligentModel: "Upravit inteligentní model",
            createANewIntelligentModel: "Nový model smart",
          },
        },
        index: {
          modelName: "Název modelu",
          modelDescription: "Popis modelu",
          type: "Typ",
          whetherItMeetsTheRequirements: "Soulad",
          gradedEvaluation: "Hodnocení stupně",
          validity: "Účinnost",
          intelligentModel: "Inteligentní model",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Použije se na subjektivní kontrolu kvality (například na to, zda je přístup ke kontrole kvality nadšený a zda je služba trpělivá)",
        },
      },
      clusterAnalysis: "Analýza clusteru",
      speechMining: "Slova těžba",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Informační subjekty můžete importovat v dávkách prostřednictvím rozhraní api. Po uložení názvu api nelze jej upravit.",
        },
        component: { nothing: "Žádné" },
      },
      knowledgeBase: {
        index: {
          classification: "Klasifikace",
          import: "Import",
          importKnowledgeBase: "Import znalostní základna",
          moveKnowledgePointsInBatch: "Body poznatků pro přesun šarže",
          moveTo: "Přesunout:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Prosím, vyberte znalostní body, které se mají přesunout v šaržích",
        },
        detail: {
          index: { intrasentence: "V rámci věty", afterProblem: "Po problému" },
        },
      },
      semanticTags: { component: { nothing: "Žádné" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Citlivé slova",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Citlivé slova: citlivé slova mohou být konfigurovány s obchodními slovy, které se používají pro objevování citlivého slovního cloudu veřejného mínění",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Nové citlivé slovo",
            editSensitiveWords: "Upravit citlivé slova",
            sensitiveWords: "Citlivé slova",
          },
          template: {
            sensitiveWords: "Citlivé slova",
            sensitiveWordsCannotBeEmpty: "Citlivé slovo nemůže být prázdné",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Prosím, zadejte citlivé slovo, více citlivých slov uprostřed ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "Vyberte scénář aplikace",
            keyWord: "Klíčová slova",
            pleaseEnterKeywords: "Zadejte prosím klíčové slovo",
            keywordLengthWithinCharacters: "Délka klíčového slova do 40 znaků",
            dataSources: "Zdroj dat",
            pleaseSelectADataSource: "Vyberte zdroj dat",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Současná clustering byla dokončena. Opětovná úprava vygeneruje nový kus dat z clusterování a nepřepíše dokončené data z clusterování. Potvrďte prosím",
            savingSucceeded: "Úspěšně uložit",
            clusteringStart: "Začíná klasterování",
            clustering: "Seskupení",
            edit: "Upravit",
            newlyBuild: "Nový",
            clusterName: "Název clusteru",
            pleaseEnterAName: "Zadejte název",
            withinCharactersOfTheName: "Délka jména do 40 znaků",
            speaker: "Mluvčí",
            pleaseSelectASpeaker: "Vyberte mluvčí",
            sessionDate: "Datum sezení",
            pleaseEnterTheSessionDate: "Zadejte datum sezení",
            selectUpToMonthOfHistoricalSessionData:
              "Vyberte maximálně 1 měsíc historických údajů o relaci",
            automaticallyClusterDailyNewData:
              "Automatické seskupování denních nových dat",
            conversationChannel: "Kanál konverzace",
            pleaseSelectAConversationChannel: "Vyberte relační kanál",
            applicationScenario: "Scénáře aplikací",
            clusteringRules: "Pravidla clusterování",
            incompleteClusteringRuleSettings:
              "Nastavení pravidla clusterování je neúplné",
            startClustering: "Spustit clusterování",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Prosím, vyberte kvalitní úkol",
              beEqualTo: "Rovná",
            },
          },
        },
        detail: {
          index: {
            theme: "Téma",
            numberOfCorpora: "Počet korpusu",
            corpusCoverage: "Krytí korpusu",
            clusterDetails: "Podrobnosti o clusterování",
          },
          ranking: {
            hotWords: "Hot words",
            numberOfCorpora: "Počet korpusu",
            speaker: "Mluvčí",
            sit: "Sedni si.",
            passenger: "Host",
            hotSentence: "Horká věta",
            corpusDetails: "Corpus details",
          },
        },
        index: {
          delete: "Smazat",
          deletionSucceeded: "Úspěšně smazán",
          clusterName: "Název clusteru",
          speaker: "Mluvčí",
          creationTime: "Čas vytvoření",
          state: "Stav",
          operation: "Operace",
          see: "Zobrazit",
          edit: "Upravit",
          clusterAnalysis: "Analýza clusteru",
          newCluster: "Nový klaster",
          clusteringStatus: "Stav clusterování",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Zadejte klíčová slova pro vyhledávání námitek",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Diskusní knihovna",
                recommendedScripts: "Doporučená slova",
                common: "Celkem",
                strip: "Článek",
                scriptFavorites: "Oblíbené slova",
              },
              conditionPostfix: {
                detectRoles: "Detekovat roli",
                detectionRange: "Rozsah detekce",
                sentence: "Věta",
              },
              customerFilter: {
                scriptFiltering: "Filtrování řeči",
                cancel: "Storno",
              },
              index: {
                viewDetails: "Zobrazit podrobnosti",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Jsi si jistá, že to chceš opustit? Nemůžete ho obnovit po jeho opuštění.",
                abandonment: "Zahodit",
                deletionSucceeded: "Úspěšně smazán",
                passenger: "Host",
                sit: "Sedni si.",
                adopted: "Přijato",
                determine: "Určit",
                cancel: "Storno",
                adopt: "Adopce",
              },
              pageRightFrom: {
                savingSucceeded: "Úspěšně uložit",
                pleaseEnterTheScriptContent: "Zadejte obsah řeči",
                pleaseSelectAFavoriteGroup: "Vyberte oblíbené seskupení",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Diskusní knihovna pro vyřizování námitek",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "Zvolte, prosím, přijmout projev",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Oblíbené slovy pro nakládání s námitkami",
            },
            pageRightTable: {
              likeCancel: "Jako zrušeno",
              likeSuccessfully: "Mám rád úspěch.",
              adoptScripts: "Přijmout projev",
              operation: "Operace",
              viewConversationDetails: "Zobrazit podrobnosti konverzace",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Product selling point talk library",
              },
              pageRightTable: { keyPointsOfScript: "Klíčové body řeči" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Oblíbené slovy pro nakládání s námitkami",
              newGroup: "Nová skupina",
              editGroup: "Upravit seskupování",
              addGroup: "Přidat seskupení",
              savingSucceeded: "Úspěšně uložit",
              groupName: "Název skupiny",
              pleaseEnterTheGroupName: "Zadejte název skupiny",
              groupNameWithinCharacters: "Délka názvu skupiny do 20 znaků",
              deletionSucceeded: "Úspěšně smazán",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings:
                    "Doporučené nastavení pro projev",
                },
                rightForm: {
                  savingSucceeded: "Úspěšně uložit",
                  intelligentRecommendation: "Inteligentní doporučení",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Přijaté výborné řečnické dovednosti budou automaticky zobrazeny v bočním panelu sezení s podobnými námitkami a zaměstnanci mohou přímo zobrazit doporučené řečnické dovednosti pro učení na stránce podrobnosti o pokračování sezení.",
                  objectionsIncludedByDefault:
                    "Výpověď zahrnuta ve výchozím nastavení",
                  addRecommendationTriggerSemantics:
                    "Přidat doporučení trigger semantika",
                  remindEmployeesToView: "Připomenout zaměstnance k zobrazení",
                  shareWithMoreEmployees: "Sdílet s více zaměstnanci.",
                  allHands: "Celý personál",
                },
                smartToolbar: { selectSemanticTag: "Vybrat sémantické štítky" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Exportovat všechny data záznamy pod aktuální seskupení?",
                newScript: "Nová reč",
                editingScript: "Upravit projev",
                savingSucceeded: "Úspěšně uložit",
                objection: "Námitka",
                pleaseEnterAScriptObjection: "Please enter a speech objection",
                speechTechnique: "Slova",
                pleaseEnterTheScriptContent: "Zadejte obsah řeči",
                grouping: "Seskupení",
                pleaseSelectAFavoriteGroup: "Vyberte oblíbené seskupení",
                adoptScripts: "Přijmout projev",
                passenger: "Host",
                sit: "Sedni si.",
                numberOfViews: "Počet pohledů",
                useful: "Užitečné",
                operation: "Operace",
                edit: "Upravit",
                delete: "Smazat",
                deletionSucceeded: "Úspěšně smazán",
                scriptRecommendationSettings: "Doporučené nastavení pro projev",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Diskusní knihovna",
          scriptMining: "Slova těžba",
          objectionHandlingScripts: "Vyřizování námitek",
          productSellingPointScripts: "Prodejní body produktu",
        },
        sellingPoint: {
          index: { newCluster: "Nový klaster" },
          components: {
            callDetailModal: {
              sessionDetails: "Podrobnosti o relaci",
              cancel: "Storno",
              determine: "Určit",
              choice: "Vybrat",
            },
            pageCenterTable: { scriptOverview: "Přehled řeči" },
            pageLeftTable: {
              enterSearchKeywords: "Zadejte klíčová slova pro vyhledávání",
              keyWord: "Klíčová slova",
            },
            pageRightTable: {
              index: {
                viewDetails: "Zobrazit podrobnosti",
                scriptLibrary: "Diskusní knihovna",
                scriptRefinement: "Rafinace řeči",
                adoptScript: "Přijmout projev",
              },
              tagsEdit: { keyPointsOfScript: "Klíčové body řeči" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Product selling point talk library",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Prodejní body zahrnuté ve výchozím nastavení",
                  },
                },
                index: {
                  keyWord: "Klíčová slova",
                  pleaseSelectAKeyword: "Prosím, vyberte klíčové slovo",
                  keyPointsOfScript: "Klíčové body řeči",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Zadejte klíčové body řeči",
                  viewDetails: "Zobrazit podrobnosti",
                  pleaseChooseToAdoptScript: "Zvolte, prosím, přijmout projev",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Odpovídající logika",
            nLPMatching: "Nlp match",
            strictlyMatchByWord: "Přísný zápas podle slova",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Název typu zprávy",
          messageTypeID: "Id typu zprávy",
          messageTypeDescription: "Popis typu zprávy",
          applicationMessageType: "Typ zprávy aplikace",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Použije se pro přístupové údaje z různých kanálů pro kontrolu kvality, které by měly být klasifikovány podle identifikace kanálu.",
        },
        detail: {
          index: {
            messageTypeName: "Název typu zprávy",
            pleaseEnterAMessageTypeName: "Zadejte název typu zprávy",
            messageTypeDescription: "Popis typu zprávy",
            describeYourMessageTypeInDetail: "Podrobně popište typ zprávy",
            editMessageType: "Upravit typ zprávy",
            newMessageType: "Nový typ zprávy",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Vyberte člena týmu",
        existingFilter: "Už jsou filtry.",
        pleaseSelect: "Vyberte prosím",
        filterCondition: "Podmínky filtrace",
        areYouSureToDeleteTheCurrentFilter:
          "Opravdu chcete smazat aktuální filtr?",
        sessionDate: "Datum sezení",
        staff: "Zaměstnanec",
        customer: "Zákazník",
        pleaseEnterCustomerName: "Zadejte prosím jméno zákazníka",
        keyWord: "Klíčová slova",
        whole: "All",
        pleaseEnterSessionKeywords: "Zadejte prosím klíčová slova sezení",
        sessionDuration: "Délka relace",
        sessionLabel: "Scénáře sezení",
        pleaseSelectASessionLabel: "Vyberte scénář relace",
        commentTopic: "Komentovat téma",
        pleaseSelectACommentTopic: "Please select a comment topic",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Opravdu si přejete přepsat aktuální filtr?",
        overlayFilter: "Krycí filtr",
        NewFilter: "Nový filtr",
        newFilter: "Nový filtr",
        filterName: "Název filtru",
        pleaseEnter: "Zadejte prosím",
        allCall: "Všechny volání",
        percentage: "Procento",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "Můžete hledat více klíčových slov. Použijte čárky (v angličtině a čínštině) k oddělení nebo a pauze k oddělení a nebo. Například:",
          callRecordID: "Id záznamu volání",
          pleaseFillInTheCallRecordID: "Prosím, vyplňte id záznamu volání",
          minute: "Minuty",
          second: "Sekundy",
          whole: "All",
          callRecording: "Záznam hovoru",
          enterpriseWechat: "Enterprise wechat",
          conversationChannel: "Kanál konverzace",
          pleaseSelectASessionChannel: "Vyberte relační kanál",
          learningStatus: "Stav učení",
          pleaseSelectLearningStatus: "Vyberte, prosím, stav učení",
          notLearned: "Nenaučil se",
          learned: "Leared",
          Minutes: "Minuty",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Stav filtru je prázdný, nový filtr není podporován!",
          filterAddedSuccessfully: "Filtr přidán úspěšně!",
          failedToAddFilter: "Přidání filtru se nezdařilo!",
          filterDeletedSuccessfully: "Filtrovat smazán úspěšně!",
          filterDeletionFailed: "Odstranění filtru se nezdařilo!",
          filterOverwriteSucceeded: "Filtrovat přepracovat úspěšně!",
          filterOverwriteFailed: "Přepracování filtru se nezdařilo!",
          theme: "Téma",
          pleaseSelectATheme: "Prosím, vyberte téma",
          keyEvents: "Klíčové události",
          pleaseSelectAKeyEvent: "Vyberte klíčovou událost",
        },
        filterItem: {
          startRetesting: "Spustit opětovnou detekci",
          time: "Čas",
          talkTime: "Doba trvání volání",
          keyEvents: "Klíčové události",
          totalImplementationRateOfSupervisionItems:
            "Celková míra provádění položek dohledu",
          sort: "Sort:",
          seeMore: "Více",
        },
        filterStatistics: {
          conversation: "Volání",
          weChat: "Wechat",
          quantity: "Množství",
        },
      },
      caseBase: {
        index: {
          copyTo: "Kopírovat",
          pleaseSelectTheLocationToMove:
            "Vyberte umístění, které chcete přesunout!",
          folderCopiedSuccessfully: "Kopírování složky úspěšné!",
          cancelCollectionSuccessfully: "Zrušit kolekci úspěšně!",
          collectionSucceeded: "Úspěch sbírky!",
          starCaseBase: "Knihovna hvězdného případu",
          move: "Přesunout",
          rename: "Přejmenovat",
          delete: "Smazat",
          folderMovedSuccessfully: "Složka byla úspěšně přesunuta!",
          fileMovedSuccessfully: "Soubor byl úspěšně přesunut!",
          fileMoveFailed: "Přesun souboru selhal!",
          folderAddedSuccessfully: "Přidat složku úspěšně!",
          folderRenameSucceeded: "Složka úspěšně přejmenovaná!",
          fileRenameSucceeded: "Soubor úspěšně přejmenován!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Po odstranění složky jej nebudete moci načíst. Určitě ji odstraníte?",
          deleteSucceeded: "Úspěšně smazán!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Po odstranění souboru ho nebudete moci načíst. Určitě ji odstraníte?",
          share: "Celkem",
          folders: "Složka,",
          calls: "A call",
          newFolder: "Nová složka",
          modifyFolder: "Upravit složku",
          title: "Název",
          pleaseEnter: "Zadejte prosím",
          selectMoveLocation: "Vybrat umístění přesunu",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Vzpomínka na učení",
          subscriptionDynamics: "Dynamics of subscription",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "The session you subscribed to (id",
            thereAreDynamicUpdates: "Je tu dynamická aktualizace",
            newCommentsReplies: "Přidat komentáře/odpovědi",
            strip: "Článek",
            newHelp: "Přidat nápovědu",
            goAndWatch: "Go onlookers",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Odpovědět na moje komentáře",
            replySucceeded: "Odpovědět úspěšně!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Me in the comments.",
            replySucceeded: "Odpovědět úspěšně!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Volá vás k sdílení",
            shareCasesForYou: "Sdílejte ten případ pro vás.",
          },
        },
        study: {
          index: {
            learned: "Leared",
            ignored: "Ignorované",
            study: "Učení",
            ignore: "Ignorovat",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Úspěšný import",
          account: "Číslo účtu",
          password: "Heslo",
          cover: "Obal",
          skip: "Přeskočit",
          import: "Import",
          importEmployees: "Importovat zaměstnance",
          importPrompt: "Importovat tipy",
          confirm: "Určit",
          theDataContainsDuplicateAccountsOrNames:
            "V tomto zápisu dat existuje duplicitní účet nebo název",
          allCoverage: "Pokrýt vše",
          skipAll: "Přeskočit vše",
          headPortrait: "Avatar",
          fullName: "Jméno",
          nickname: "Přezdívka",
          excellentStaff: "Výborný personál",
          yes: "Ano",
          no: "Ne",
          department: "Oddělení",
          accountNumberemail: "Číslo účtu (e-mail)",
          telephone: "Telefon",
          jobNumber: "Práce č.",
          role: "Role",
          pleaseEnterANameOrPhoneSearch: "Zadejte e-mail, název nebo telefon",
          person: "Lidé)",
          AddEmployee: "Přidat zaměstnance",
        },
        edit: {
          jobNo: "Práce č.",
          udeskCustomerServiceSystemIdentity:
            "Identita systému služby zákazníkům udesk",
          enterpriseWechatIdentity: "Enterprise wechat identity",
          inputID: "Vstupní id",
          newIdentityBinding: "Vazba na novou identitu",
          identityBinding: "Vazba identity",
          avatarGreaterThan500kb: "Avatar větší než 500kb",
          uploadSuccessful: "Nahrávat úspěšně",
          upload: "Nahrát",
          supportJPGGIFAndPNGUpTo500kb: "Podpora jpg, gif, png, maximum 500kb",
          yes: "Ano",
          no: "Ne",
          accountNumber: "Číslo účtu",
          pleaseEnterTheAccountNumber: "Zadejte prosím číslo účtu",
          initialPassword: "Počáteční heslo",
          pleaseInputAPassword: "Zadejte prosím heslo",
          confirmPassword: "Potvrdit heslo",
          thePasswordsEnteredTwiceAreDifferent:
            "Heslo zadané dvakrát není stejné!",
          headPortrait: "Avatar",
          fullName: "Jméno",
          pleaseEnterYourName: "Zadejte název",
          nickname: "Přezdívka",
          department: "Oddělení",
          excellentStaff: "Výborný personál",
          sessionIdentity: "Identita relace",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Záznam sezení značky patří zaměstnanci",
          telephone: "Telefon",
          jobNumber: "Práce č.",
          role: "Role",
          pleaseSelectARole: "Vyberte roli",
          preservation: "Uložit",
          cancel: "Storno",
          addedSuccessfully: "Úspěšně přidáno!",
          modificationSucceeded: "Úspěšně upraven!",
          newEmployees: "Nový zaměstnanec",
          editEmployee: "Upravit zaměstnance",
        },
        detail: {
          viewDetails: "Zobrazit podrobnosti",
          accountNumber: "Číslo účtu",
          headPortrait: "Avatar",
          fullName: "Jméno",
          nickname: "Přezdívka",
          department: "Oddělení",
          excellentStaff: "Výborný personál",
          yes: "Ano",
          no: "Ne",
          sessionIdentity: "Identita relace",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Záznam sezení značky patří zaměstnanci",
          telephone: "Telefon",
          jobNumber: "Práce č.",
          role: "Role",
        },
      },
      roleManage: {
        list: {
          menu: "Menu",
          functionalCategory: "Funkční kategorie",
          functionalSubclass: "Podtřída funkce",
          deletedSuccessfully: "Úspěšně smazán",
          NewRole: "Nová role",
          role: "Role",
          dataPermissions: "Oprávnění dat:",
          edit: "Upravit",
          areYouSureYouWantToDeleteTheRole: "Ok pro odstranění role",
          determine: "Určit",
          cancel: "Storno",
          delete: "Smazat",
          applicationPermission: "Použít oprávnění",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Můžete vidět pouze vaše vlastní data sezení",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Data sezení oddělení a podřízených oddělení můžete zobrazit",
          menu: "Menu",
          functionalCategory: "Funkční kategorie",
          functionalSubclass: "Podtřída funkce",
          successfullyAdded: "Nový úspěch",
          modifiedSuccessfully: "Úprava byla úspěšná",
          edit: "Upravit",
          cancel: "Storno",
          confirm: "Potvrdit",
          roleName: "Název role",
          pleaseEnterTheRoleName: "Zadejte název role",
          dataAuthority: "Oprávnění pro data",
          youCanViewCompanyWideSessionData:
            "Můžete zobrazit data sezení v celé společnosti",
          applicationPermission: "Použít oprávnění",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Proces sezení",
          generateProcess: "Generační proces",
          addProcess: "Přidat proces",
          cancel: "Storno",
          complete: "Kompletní",
          edit: "Upravit",
          theSettingWillBeSuccessfulLater:
            "Nastavit, aby viděl úspěch později!",
          previous: "Předchozí článek",
          next: "Další článek",
          debuggingMode: "Ladicí režim",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Po aktivaci zprávy, pokud odpovídá pravidlům klíčových událostí, monitorování položek, zákaznických štítků nebo znalostních bodů, zobrazí se název pravidla, na které se zpráva shoduje, a název poznatkového bodu znalostní základny.",
          seeLater: "Podívejte se později",
          determine: "Určit",
          conversationalInsights: "Průzkumy sezení",
          customerInsight: "Výhled zákazníků",
          knowledgePointRecommendation: "Doporučení znalostního bodu",
          comment: "Comments",
          sessionQuality: "Kvalita relace",
          setToListenLaterSuccessfully: "Nastavit naslouchat úspěchu později!",
          listenLater: "Poslouchejte později",
          seekCounselingSuccess: "Hledej úspěch koučování!",
          altogether: "Celkem",
          appearsAt: '"',
          commentsPublishedSuccessfully: "Comments posted successfully",
          callRecording: "Záznam hovoru",
          addedSuccessfully: "Úspěšně přidáno",
          shareWithColleagues: "Sdílet s kolegy",
          shareWithCustomers: "Sdílet s zákazníky",
          resumptionDetails: "Podrobnosti o opětovné nabídce",
          addToCaseBase: "Přidat do knihovny případů",
          shareCases: "Případy sdílení",
          seekCounseling: "Hledat poradenství",
          sessionRecord: "Záznam sezení",
          businessRecord: "Obchodní záznamy",
          keywordSearch: "Hledání klíčových slov",
          whole: "All",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Please enter a comment, enter @ to notify others, enter # to add a thread",
          subscriptionSession: "Přihlásit se na relaci",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Po předplatné, pokud jsou nové nápovědy, komentáře a odpovědi, obdržíte upozornění a připomenutí.",
        },
      },
      coachTask: {
        index: {
          employeeName: "Jméno zaměstnance",
          customerName: "Jméno zákazníka",
          source: "Zdroj",
          viewSessionRecords: "Zobrazit záznamy sezení",
          coachingStatus: "Stav koučování",
          helpTime: "Čas na pomoc",
          counselingTime: "Čas poradenství",
          helpContent: "Obsah nápovědy",
          coachingOperation: "Koučování operace",
          areYouSureYouWantToIgnoreIt: "Jsi si jistá, že to chceš ignorovat?",
          determine: "Určit",
          cancel: "Storno",
          ignoreHelp: "Ignorovat nápovědu",
          coaching: "Provádět koučování",
          viewCoaching: "Zobrazit koučování",
          areYouSureToReturnToCounseling: "Určitě se vrátíš k poradenství?",
          returnToPendingCoaching: "Pokračovat v poradenství",
          tutor: "Poradce",
          helpSeekingStatus: "Stav nápovědy",
          recentCoachingTime: "Nedávný čas poradenství",
          operation: "Operace",
          ignoreSuccess: "Ignorujte úspěch!",
          recoverySucceeded: "Obnovení úspěšné!",
          successfulCoaching: "Vyučování úspěch!",
          myMission: "Moje mise",
          selectCoachingStatus: "Vybrat stav koučování",
          whole: "All",
          iAskedForHelp: "Požádal jsem o pomoc.",
          signOut: "Exit",
          submit: "Podání",
          helpPerson: "Požádat o pomoc",
          customer: "Zákazník",
          toBeCoached: "To be mentored",
          counselingSuggestions: "Poradenství",
          pleaseEnterCoachingSuggestions: "Prosím, zadejte koučování návrhy",
        },
      },
      coachTaskCenter: {
        title: "Mission center",
        list: {
          taskName: "Název úlohy",
          lessonGroup: "Klasifikace kurzu",
          lessonName: "Course",
          taskType: "Typ úkolu",
          taskStatus: "Stav úkolu",
          taskExecutionInfo: "Stav implementace",
          totalCount: "Počet osob, které mají být popraveny",
          executeCount: "Počet provedených",
          executeRate: "Míra realizace",
          completeCount: "Počet dokončených",
          completeRate: "Míra dokončení",
          commitCount: "Počet výsledků",
          commitRate: "Míra souladu",
          excellentRate: "Vynikající sazba",
          taskTime: "Čas úlohy",
          option: "Operace",
          edit: "Upravit",
          taskDetail: "Data úloh",
          delete: "Smazat",
          new: "Nová publikace",
          taskTypeAll: "All",
          taskTypePractice: "Cvičení",
          taskTypeTest: "Zkouška",
          taskStatusNotStarted: "Nezahájeno",
          taskStatusRunning: "Probíhá",
          taskStatusEnd: "Konec",
          taskStatusInvalid: "Není v platnosti",
          unlimitedTime: "Neomezený čas",
          to: "Do",
          deleteConfirm:
            "Po odstranění budou všechny záznamy o učení v rámci této úlohy vymazány. Určitě je odstraníte?",
          deleteSuccess: "Úloha úspěšně odstraněna",
        },
        edit: {
          addTask: "Nová úloha publikovat",
          editTask: "Upravit úlohu publikovat",
          publishSuccess: "Úspěšně zveřejněno",
          publishFailed: "Publikování selhalo",
          updateSuccess: "Úspěšná aktualizace",
          updateFailed: "Aktualizace selhala",
          cannotBeNull: "Nemůže být prázdný",
          explain: "Popis",
          taskDescExtra: "(This will be displayed at the {0} entrance)",
          taskTargetObj: "Publikační objekty",
          isEnable: "Účinný stav",
          dialogueMode: "Režim dialogu",
          voice: "Hlas",
          text: "Text",
          userPortrait: "Uživatelský portrét",
          difficultyLevel: "Obtížnost",
          limitDuration: "Limit trvání",
          tipLabel: "Tipy",
          tipTooltip: "Povolit delegátům zobrazit referenční projev",
          limitTimes: "Počet zkoušek",
          time: "Čas",
          gradingStandart: "Hodnocení kritérií",
          gradingStandartDescAffix:
            "(The current conversation process is divided",
          gradingStandartDescSuffix:
            "Body, speech skill {0} points, mood {1} points, speed {2} points)",
          completeStandart: "Požadavky na dokončení úkolu",
          byTimes: "Počet krát",
          byScore: "Podle skóre",
          completeByTimes: "Počet cvičení",
          times: "Times",
          score: "Body",
          anyTimes: "Any once",
          getScore: "Skóre",
          offLimitDuration: "Neomezená doba trvání",
          onLimitDuration: "Omezená délka času",
          commitScore: "Standard, score",
          excellentScore: "Vynikající standard, score",
          offLimitTime: "Neomezený čas",
          onLimitTime: "Omezený čas",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Hi," },
          callDynamic: {
            today: "Dnes",
            yesterday: "Včera.",
            sessions: "Relace",
          },
          todoList: {
            strip: "Článek",
            theSessionIsAddedTolistenLater: 'Session is joined "listen later"',
            askYouForHelp: "Požádejte vás o pomoc.",
            iHopeToContactYouLater: "Očekávám, že se kontaktuje později",
            lookingForwardToSendingTheInformationLater:
              "Očekávám, že pošlete později materiál.",
            dialogueRisk: "Konverzace má rizikové body",
            tutored: "Trenér",
            goToCounseling: "Přejít na poradenství",
            contacted: "Kontakty",
            hasBeenSent: "Odesláno",
            areYouSureYouWantToIgnoreIt: "Jsi si jistá, že to chceš ignorovat?",
            determine: "Určit",
            cancel: "Storno",
            ignore: "Ignorovat",
            checked: "Viewed",
          },
          callListItem: {
            strip: "Článek",
            learned: "Leared",
            keyEvents: "Klíčové události",
            totalImplementationRateOfSupervisionItems:
              "Celková míra provádění položek dohledu",
          },
        },
        index: {
          operationSucceeded: "Operace úspěšná!",
          sessionDynamics: "Dynamika relace",
          my: "My",
          department: "Oddělení",
          company: "Společnost",
          toDoList: "To-do",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Účinný objem volání (jednotka)",
          bestPractices: "Osvědčené postupy",
          averageDurationOfASingleCallminutes:
            "Průměrná doba jednorázového volání (minuty)",
          totalCallVolumePCs: "Total calls (unit)",
          totalTalkTimehours: "Celková doba volání (hodiny)",
          speakingPercentagepercentage: "Procentní podíl (procentní podíl)",
          maximumContinuousSpeakingTimeminutes:
            "Maximální nepřetržitá doba mluvení (minuty)",
          numberOfIndepthDiscussionsPCs: "Počet hloubkových diskusí (jednotka)",
          maximumCustomerTalkminutes: "Maximum customer speaking (minutes)",
          questionFrequencyPCs: "Frekvence otázky (jednotka)",
          averageValue: "Průměr",
          selectDate: "Vybrat datum",
          personnel: "Personál",
          pleaseSelect: "Vyberte prosím",
          callRange: "Rozsah volání",
          conversationActivity: "Aktivita relace",
          conversationAction: "Akce sezení",
          keyEvents: "Klíčové události",
          callRecord: "Záznam hovoru",
          filter: "Filtr:",
          conversation: "Volání",
          minute: "Minuty",
          sale: "Zaměstnanec",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Leden",
            numberOfCalls: "Počet hovorů",
            accountInformation: "Informace o účtu",
            month: "Měsíc",
            today: "Dnes",
            customerDetails: "Údaje o zákazníkovi",
            searchKeywordsInCommunicationContent:
              "Search keywords in communication content",
            allMembers: "Všichni členové",
            reset: "Resetovat",
            query: "Dotaz",
            allTrip: "Zobrazit celou cestu",
            contactOverview: "Přehled kontaktů",
            lastContact: "Poslední kontakt",
            nextContact: "Další kontakt",
            previous: "Předchozí článek",
            next: "Další článek",
            viewDetails: "Zobrazit podrobnosti",
            contactDynamics: "Kontaktní dynamika",
            lastYear: "Minulý rok",
            lastCall: "Poslední volání",
            hotTopicsForDiscussion: "Horká téma diskuse",
            totalInTheLastYear: "V posledním roce.",
            callTimes: "Sekundární volání",
          },
        },
        list: {
          index: {
            risk: "Risk",
            have: "There are",
            nothing: "Žádné",
            channel: "Kanál",
            callRecording: "Záznam hovoru",
            enterpriseWeChatConversation: "Enterprise micro-session",
            enterpriseWeChatCall: "Enterprise micro-talk",
            followUpSuggestions: "Následné doporučení",
            customer: "Centrum zákazníků",
            followUp: "Sledování",
            personInCharge: "Odpovědná osoba",
            recentFollowup: "Nedávno následoval.",
            lastContactTime: "Poslední čas kontaktu",
            operation: "Operace",
            see: "Zobrazit",
            exceed: "Více",
            noActivityForMoreThanDays: "Dny bez aktivity",
            lately: "Nedávno",
            risksOccurredInTheLastDays:
              "Rizikové sémantiky se vyskytují během dnů",
            savedSuccessfully: "Úspěšně uložit",
            today: "Dnes",
            allDepartments: "Všechny oddělení",
            allMembers: "Všichni členové",
            customerName: "Jméno zákazníka",
            reset: "Resetovat",
            query: "Dotaz",
            customers: "Zákazník",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Zobrazit kontakt mezi zaměstnanci a zákazníky, v nichž růžové bubliny jsou zprávy zákazníků a modré bubliny jsou zprávy zaměstnanců. Čím větší je podíl projevů, tím větší bubliny.",
            riskAllocation: "Konfigurace rizika",
            preservation: "Uložit",
            pleaseEnter: "Zadejte prosím",
            noActivityForDays: "Dny bez aktivity",
            riskSemanticsWithinDays:
              "Rizikové sémantiky se vyskytují během dnů",
            addRiskSemantics: "Přidat rizikovou sémantiku",
            semanticTags: "Sémantické štítky:",
            standardSentence: "Standardní věta:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "Přidat novou podmínku" } },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Splnit všechny následující podmínky",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Filtr",
                pleaseSelectFilteringCriteria: "Vyberte kritéria filtru",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Fáze změny příležitostí",
              theCurrentStageIs:
                "Aktuální etapa je-------------------------------------------------------------------------------------------------------------------",
              ConfirmToChangeItTo: "], Potvrďte změnu",
              IsIt: "]?",
              StageCoStay: "] Celková fáze pobytu",
              day: "Den",
              start: "Začátek",
              end: "Konec",
              orderInput: "Ztráta pořadí",
              restart: "Restartovat",
              pleaseConfigureTheSalesStageInformation:
                "Prosím, nastavit informace o fázi prodeje",
              reasonForDocumentInput: "Důvod pro ztrátu objednávky:",
              completeOrderInput: "Kompletní ztráta doc",
              pleaseSelectTheReason: "Prosím, vyberte důvod",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "Vedení doporučuje:" },
              index: {
                sessionDetails: "Podrobnosti o relaci",
                customerDynamics: "Dynamika zákazníků",
                riskPoint: "Bod rizika",
                followUpSuggestions: "Následné doporučení",
              },
              sessionDetails: {
                common: "Celkem",
                currentlyViewingThe: "Currently view no.",
                sessions: "Relace",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Současná fáze se zastavila.",
              day: "Den",
            },
          },
          business: {
            index: { reEnableOpportunity: "Znovu povolit příležitost" },
          },
          customerTag: { index: { label: "Štítek" } },
          moreCustomer: {
            index: {
              essentialInformation: "Základní informace",
              cancel: "Storno",
              confirm: "Potvrdit",
              customer: "Zákazník",
              currentFollowupStage: "Aktuální fáze sledování",
              telephone: "Telefon",
              followUpDays: "Následné dny",
              day: "Den",
              remarks: "Poznámky",
              channelPreference: "Přednost kanálu",
              hello: "Ahoj.",
              customFields: "Vlastní pole",
              opportunityAmount: "Částka příležitostí",
              focus: "Obavy",
              customerInformation: "Informace o zákazníkovi",
              viewMoreCustomerInformation: "Více informací o zákazníkovi",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Klíčové události:" },
                row1: {
                  customerDynamics: "Dynamika zákazníků",
                  common: "Celkem",
                  barDynamic: "Dynamické",
                },
                row2: {
                  riskPoint: "Bod rizika",
                  common: "Celkem",
                  risks: "Riziko článku",
                },
                row3: {
                  followUpSuggestions: "Následné doporučení",
                  intelligentRecommendation: "Inteligentní doporučení",
                  strip: "Článek",
                  managersSuggestATotalOf: "Manažer doporučuje celkem",
                  recommendedByManagers: "Doporučeno",
                  creationTime: "Čas vytvoření",
                  administratorSuggestions: "Poradenství správce",
                  suggestionsForNewManagers: "Poradenství nového manažera",
                },
              },
            },
            index: { typeTime: "Typ/čas" },
          },
          userInfo: {
            index: {
              personInCharge: "Odpovědná osoba",
              recentFollower: "Nedávno následoval.",
              startTime: "První čas dosahu",
              followUpDays: "Následné dny",
              day: "Den",
              remarks: "Poznámky",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Členové týmu",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Celkový počet sezení, které se členové naučili od jiných (deduplicace). Hledání detailů sezení a kliknutí na záznam pro přehrávání se považuje za učení jednou.",
          totalNumberOfStudies: "Celkový počet učení",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Celkový počet komentářů a odpovědí, které členové zahájili na sezení jiných lidí (deduplikace)",
          totalNumberOfInteractions: "Celkový počet interakcí",
          learnTheSumOfConversationCallDuration:
            "Součet délky volání učených relací",
          lengthOfStudy: "Délka učení",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Celkový počet žádostí o pomoc od členů do sezení jiných lidí (deduplicace)",
          totalNumberOfRequests: "Celkový počet žádostí o pomoc",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Celkový počet členů poradenství pro pomoc od jiných (deduplicace)",
          totalCounseling: "Celkový počet návodů",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "The total number of sessions of members to others added to the case library (de-duplication)",
          addCase: "Přidat případ",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Celkový počet předplacených členů na sezení jiných lidí (deduplikace)",
          subscribe: "Předplatné",
          totalNumberOfSessionsCommunicatedByMembers:
            "Celkový počet sezení sdělených členy",
          totalSessions: "Celkem sezení",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Celkový počet členů přehrávajících jejich sezení (deduplicace), hledání detailů sezení a kliknutí na nahrávku pro přehrávání se považuje za opakování jednou.",
          totalNumberOfDoubleDisk: "Celkový počet redisků",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Celkový počet relací/celkový počet relací * 100%",
          proportionOfDoubleDisk: "Obnovit sdílení",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Součet délky konverzace dvojitého disku.",
          resumptionDuration: "Doba trvání obnovy",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Celkový počet členů, kteří iniciovali pomoc pro vlastní sezení (deduplicace)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Celkový počet připomínek (včetně odpovědí) obdržených ostatními za zasedání člena",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Celkový počet relací člena přidaných do knihovny případů dalšími (deduplicace)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Celkový počet členských relací upsaných druhými (deduplication)",
          askForHelpInTheLastMonth: "Minulý měsíc pro pomoc.",
          lastTimeForHelp: "Minule jsme požádali o pomoc.",
          operation: "Operace",
          viewAllSessionsInTheLastMonth:
            "Zobrazit všechny relace posledního měsíce",
          allDepartments: "Všechny oddělení",
          reset: "Resetovat",
          query: "Dotaz",
          altogether: "Celkem",
          secondSession: "Sekundární relace",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Current test project:",
            supervisionItem: "Položky dohledu",
            keyEvents: "Klíčové události",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Zkontrolujte položky monitorování nebo klíčové události, které chcete vyzkoušet, a ujistěte se, že v simulovaném rozhovoru je obsah mluvení.",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Analýza provádění" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Proces 1",
              technologicalProcess: "Proces",
              newProcess: "Nový proces",
            },
          },
          index: {
            upload: "Nahrát",
            preservation: "Uložit",
            newlyBuild: "Nový",
            cancel: "Storno",
            name: "Jméno",
            describe: "Popis",
            type: "Typ",
            classification: "Klasifikace",
            content: "Obsah",
            enclosure: "Příloha",
            knowledgePointAssociation: "Asociace znalostních bodů",
            open: "Otevřít",
            close: "Close",
            operator: "Operátor",
            logic: "Logika",
            see: "Zobrazit",
            edit: "Upravit",
          },
        },
        index: {
          name: "Jméno",
          describe: "Popis",
          createdBy: "Tvůrce",
          type: "Typ",
          lastUpdated: "Poslední aktualizace",
          numberOfReferences: "Počet odkazů",
          operation: "Operace",
          see: "Zobrazit",
          edit: "Upravit",
          delete: "Smazat",
          categoryDeletedSuccessfully: "Kategorie úspěšně odstraněná",
          classificationOfKnowledgePoints: "Klasifikace znalostních bodů",
          newlyBuild: "Nový",
          knowledgeBase: "Základna znalostí",
          knowledgePoints: "Znalostní body",
          technologicalProcess: "Proces",
          keyword: "Klíčové slovo",
          batchExport: "Export šarže",
          batchDelete: "Smazat dávku",
          importProcess: "Proces importu",
          importKnowledgePoints: "Importovat znalostní body",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Pokud v poznatkové základně neexistuje poznatkový bod, přidejte přímo znalostní bod; pokud existuje, přeskočte a neaktualizujte ho.",
          editKnowledgePointClassification:
            "Upravit klasifikaci znalostních bodů",
          newKnowledgePointClassification:
            "Klasifikace nového znalostního bodu",
          categoryEditingSucceeded: "Úspěch editace kategorie",
          newClassificationSucceeded: "Nová kategorie úspěšně",
          classificationName: "Název kategorie",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Dohled nad výkonnými silami" },
      },
      topicCallout: {
        index: {
          whole: "All",
          date: "Datum",
          dimensionStatus: "Stav volací",
          callRecordID: "Id záznamu volání",
          callRecordIDMustBeNumeric: "Id záznamu volání musí být číslo",
          strip: "Článek",
          topicAnnotation: "Topic callout",
          annotated: "Označeno",
          stripNotMarked: "Bar, bez označení",
          barInDimension: "Bar, in callout",
          customer: "Zákazník",
          sale: "Prodej",
          common: "Celkem",
          sessions: "Sezení",
          currentTopic: "Aktuální téma:",
          staff: "Zaměstnanec",
          topicOfConversation: "Téma",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Operace byla úspěšná, resetování callout",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Po resetování budou všechny záznamy o označování této relace vymazány. Potvrďte prosím",
          resetAll: "Obnovit vše",
          pleaseSelectTheTopicToBeMarked: "Prosím, vyberte téma pro označování",
          pleaseTickTheSentenceToBeMarked:
            "Prosím, zkontrolujte větu, která má být označena",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Operace byla úspěšná, label nabyl účinku",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Rozsah této anotace zahrnuje zprávy z jiných témat. Klepnutím na tlačítko ok je přepsat.",
          confirmDimensions: "Potvrdit volání",
          talkTime: "Čas mluvení",
          callID: "Id volání",
        },
      },
      topicList: {
        index: {
          callID: "Id volání",
          latestMarkedTime: "Poslední čas označování",
          topicOfConversation: "Téma",
          numberOfSentences: "Počet vět",
          sentence: "Věta",
          operation: "Operace",
          see: "Zobrazit",
          delete: "Smazat",
          edit: "Upravit",
          newSubTopic: "Nová podtéma",
          move: "Přesunout",
          whole: "All",
          topicDeletedSuccessfully: "Téma úspěšně odstraněno",
          newlyBuild: "Nový",
          topicList: "Seznam témat",
          callIDMustBeNumeric: "Id volání musí být číslo",
          leadInTopic: "Importovat téma",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Pokud je název kategorie duplikovaný, nebude původní kategorie tématu přepsána a položka bude přeskočena a nebude importována.",
          mobileClassification: "Mobilní klasifikace",
          editTopic: "Upravit téma",
          newTopic: "Nová téma",
          topicName: "Název tématu",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Úprava byla úspěšná",
            createdSuccessfully: "Úspěšně vytvořeno",
            modificationFailed: "Úprava selhala",
            creationFailed: "Nepodařilo se vytvořit",
            monitoringName: "Název monitorování",
            upToCharacters: "Až 40 znaků",
            pleaseEnterAName: "Zadejte název",
            monitoringIndicators: "Index monitorování",
            sessionActivity: "Aktivita relace",
            conversationAction: "Akce sezení",
            keyEvents: "Klíčové události",
            supervisionItems: "Položky dohledu",
            monitoringMembers: "Monitorování členů",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Skupiny přijímající poradenství, školení nebo vedoucí intervence",
            groupA: "Skupina a",
            pleaseSelectGroupAData: "Please select group a data",
            thereAreDuplicateUsersInGroupB: "Duplikovat uživatele ve skupině b",
            groupARemarks: "Poznámky skupiny a",
            controlGroupGroupComparedWithGroupA:
              "Kontrolní skupina ve srovnání s skupinou a",
            groupB: "Skupina b",
            pleaseSelectGroupBData: "Vyberte data skupiny b.",
            thereAreDuplicateUsersInGroupA:
              "Ve skupině a jsou duplicitní uživatelé",
            groupBRemarks: "Poznámky skupiny b",
            monitoringPeriod: "Cyklus monitorování",
            startNode: "Zahajovací uzel",
            monitoringScope: "Rozsah sledování",
            pleaseSelectMonitoringConditions: "Vyberte podmínky monitorování",
            pleaseEnterANumber: "Zadejte číslo",
            pleaseSelectATimePeriod: "Vyberte časové období",
            preservation: "Uložit",
            cancel: "Storno",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Úspěšně smazán",
            notStarted: "Nezahájeno",
            haveInHand: "Probíhá",
            closed: "Konec",
            newGroupTest: "Nový skupinový test",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Fáze prodeje",
        communicationStrategy1: "Komunikační strategie",
        riskAllocation: "Konfigurace rizika",
        businessConfiguration: "Konfigurace podnikání",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Vyřizování námitek",
                  productSellingPointScript: "Prodejní body produktu",
                  caseGrouping: "Seskupování případů",
                  pleaseSelectExcellentCases: "Prosím, vyberte dobrý případ.",
                  pleaseSelectKnowledgePoints: "Prosím, vyberte znalostní body",
                  associativeLinks: "Přidružené odkazy",
                  jumpTo: "Skok",
                  jumpType: "Typ skoku",
                  knowledgePoints: "Znalostní body",
                  excellentCases: "Výborný případ",
                  excellentScript: "Vynikající projev",
                  pleaseSelectASingleKnowledgePoint:
                    "Prosím, vyberte jeden znalostní bod",
                },
              },
            },
            index: {
              name: "Jméno",
              pushText: "Tlačit text",
              enableStatus: "Povolený stav",
              triggerRules: "Pravidlo spouštění",
              communicationStrategy: "Komunikační strategie",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "Skupina komunikační strategie",
              name: "Jméno",
              pushText: "Tlačit text",
              effectiveness: "Účinnost",
              openSuccessfully: "Úspěšně otevřít",
              closeSuccessfully: "Úspěšně uzavřeno",
              operationFailed: "Operace selhala",
              operation: "Operace",
              edit: "Upravit",
              delete: "Smazat",
              newlyBuild: "Nový",
              communicationStrategy: "Komunikační strategie",
              editGroup: "Upravit seskupování",
              groupName: "Název skupiny",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "All sessions",
                  recentDays: "Poslední dny",
                  recentSessions: "Nedávné relace",
                  scopeOfApplication: "Oblast působnosti:",
                  day: "Den",
                  through: "Průchod",
                },
              },
            },
            index: {
              describe: "Popis",
              applicableCustomers: "Použitelné pro zákazníky",
              enableStatus: "Povolený stav",
              triggerRules: "Pravidlo spouštění",
              riskRules: "Pravidla pro riziko",
            },
          },
          list: {
            index: {
              riskAllocation: "Konfigurace rizika",
              keyWord: "Klíčová slova",
              newRiskRule: "Nové pravidlo pro riziko",
              riskRuleName: "Název pravidla rizika",
              describe: "Popis",
              state: "Stav",
              openSuccessfully: "Úspěšně otevřít",
              closeSuccessfully: "Úspěšně uzavřeno",
              operationFailed: "Operace selhala",
              operation: "Operace",
              edit: "Upravit",
              delete: "Smazat",
              deletionSucceeded: "Úspěšně smazán",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Ztráta pořadí" },
              endReasonList: {
                addEndReason: "Přidat koncový důvod",
                enterEndReason: "Enter end reason",
              },
              stageList: {
                keyEvents: "Klíčové události",
                waysideData: "Údaje o silnici",
                includeAny: "Obsahuje jakékoli",
                includeAll: "Obsahuje všechny",
                pleaseEnterTheStageName: "Zadejte název fáze",
                setStageRules: "Nastavit pravidla fáze",
                stageName: "Název fáze",
                operator: "Operátor",
                logic: "Logika",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Časový typ pole vstupní formát: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Žádost selhala, zkuste to znovu později",
              salesStage: "Fáze prodeje",
              essentialInformation: "Základní informace",
              name: "Jméno",
              applicableDepartments: "Použitelné oddělení",
              applicableToOthers: "Použitelné pro ostatní",
              manualChangePhase: "Fáze ruční změny",
              enableStatus: "Povolený stav",
              describe: "Popis",
              opportunityStageSettings: "Nastavení fáze možnosti",
              stageSettings: "Nastavení fáze",
              aliasSettings: "Nastavení alias",
              closeOpportunity: "Možnost ukončení",
              newSuccessfully: "Nový úspěch",
              newFailed: "Nový selhal",
              savingSucceeded: "Úspěšně uložit",
              saveFailed: "Selhalo uložení",
            },
          },
          list: {
            index: {
              salesStage: "Fáze prodeje",
              keyWord: "Klíčová slova",
              newSalesStage: "Nová fáze prodeje",
              salesStageName: "Název prodejní etapy",
              describe: "Popis",
              state: "Stav",
              openSuccessfully: "Úspěšně otevřít",
              closeSuccessfully: "Úspěšně uzavřeno",
              operationFailed: "Operace selhala",
              operation: "Operace",
              edit: "Upravit",
              delete: "Smazat",
              deletionSucceeded: "Úspěšně smazán",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Generace velkých modelů",
            conversationInsights: "Průzkumy sezení",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Automatické generování" },
              },
              hooks: {
                conversationSummary: "Souhrn sezení",
                conversationProcess: "Proces sezení",
                opportunityPoints: "Příležitostný bod",
              },
              prompt: {
                index: {
                  edit: "Upravit",
                  restoreDefault: "Obnovit výchozí",
                  cancel: "Storno",
                  confirm: "Potvrdit",
                },
              },
            },
            index: {
              saveSuccessful: "Úspěšně uložit",
              hintLanguage: "Výzva",
              preserve: "Uložit",
              cancel: "Storno",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Generace velkých modelů",
        salesStage: "Fáze prodeje",
        communicationStrategy: "Komunikační strategie",
        riskAllocation: "Konfigurace rizika",
        businessConfiguration: "Konfigurace podnikání",
      },
      salesLearningCenter: {
        knowledgeBase: "Základna znalostí",
        speechCorpus: "Diskusní knihovna",
        learningCenter: "Centrum učení",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Informace o zákazníkovi" },
          },
        },
        index: { otherInformation: "Další informace" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Import" } } },
        hooks: {
          index: {
            all: "All",
            intelligentModel: "Inteligentní model",
            smartRules: "Inteligentní pravidla",
            manualScoring: "Ruční skóre",
            enable: "Povolit",
            notEnabled: "Není povoleno",
            voiceCall: "Hlasový hovor",
            textDialogue: "Textový dialog",
            ecommerceTextSession: "E-commerce text session",
            workOrder: "Pracovní pořadí",
          },
        },
        index: {
          ruleName: "Název pravidla",
          ruleType: "Typ pravidla",
          pleaseSelect: "Vyberte prosím",
          ruleStatus: "Stav pravidla",
          dataSourceType: "Typ zdroje dat",
          voiceCall: "Hlasový hovor",
          textDialogue: "Textový dialog",
          ecommerceTextSession: "E-commerce text session",
          workOrder: "Pracovní pořadí",
          status: "Stav",
          operation: "Operace",
          edit: "Upravit",
          ruleTemplate: "Šablona pravidla",
        },
      },
      analysisReportTitle: "Zpráva o analýze",
      ruleTest: "Test pravidla",
      intelligentTags: {
        labelDisplayQuantity: "Množství zobrazení štítku",
        unlimited: "Neomezený",
        ignoringTagLibraries: "Ignorovat knihovnu značek",
        automaticallyGenerateLabels: "Automaticky generovat štítky",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "Po zapnutí relace jsou značky automaticky generovány na základě velkého modelu a generované tagy jsou ve výchozím nastavení klasifikovány pod neklasifikované.",
        defaultValidity: "Výchozí účinnost",
        supervisionItem: "Položky dohledu",
        customerLabel: "Štítek zákazníka",
        newSupervisionItemRule: "Nové pravidlo položky dohledu",
        editSupervisionItemRule: "Upravit pravidla sledovaných položek",
        newCustomerLabel: "Nové zákaznické označení",
        editCustomerLabel: "Upravit značky zákazníků",
        newSessionLabel: "Nový štítek relace",
        editSessionLabel: "Upravit štítek sezení",
        newKeyEvent: "Nová událost klíče",
        editKeyEvents: "Upravit událost klíče",
        detail: {
          template: { nameIsARequiredField: "Název je požadované pole" },
          formItemComponent: {
            largeModelExtensionLabel: "Velké značky rozšíření modelu",
            sessionRecordID: "Id záznamu relace",
            sessionTime: "Čas sezení",
            smartTags: "Smart label",
            viewDetails: "Zobrazit podrobnosti",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Po odstranění budou údaje z tohoto záznamu sezení vymazány. Určitě ji odstraníte?",
            tagData: "Data značky",
            batchDeletion: "Smazat dávku",
          },
        },
        template: {
          advancedFiltering: "Rozšířené filtrování",
          dataType: "Typ dat",
          filterCriteria: "Stav filtru",
          edit: "Upravit",
          createANewSubcategory: "Nová podkategorie",
          delete: "Smazat",
          nameLengthCannotExceed: "Délka názvu nesmí překročit 40",
        },
        component: {
          modelExpansion: "Rozšíření modelu",
          enable: "Povolit",
          notEnabled: "Není povoleno",
          standardScreening: "Standardní filtr",
          advancedFiltering: "Rozšířené filtrování",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Klíčová slova",
              sessionLabel: "Štítek relace",
              keyEvents: "Klíčové události",
              matchAny: "Match any",
              matchAll: "Zápas vše",
              beEqualTo: "Rovná",
              includeAll: "Obsahuje všechny",
              includeAny: "Obsahuje jakékoli",
              advancedConditions: "Pokročilé podmínky",
              smartTag: "Smart label",
              smartTagI: "Smart label one",
              smartTagII: "Smart label ii",
            },
            template: {
              pleaseSelectSmartTag: "Vyberte smart label",
              pleaseSelectASessionLabel: "Prosím, vyberte štítek relace",
              pleaseSelectAKeyEvent: "Vyberte klíčovou událost",
              pleaseSelectAdvancedCondition: "Please select advanced criteria",
              newAdvancedFilter: "Nový pokročilý filtr",
              moveUp: "Přesunout nahoru",
              moveDown: "Posunout dolů",
              moveLeft: "Přesunout vlevo",
              shiftRight: "Přesunout doprava",
            },
          },
          component: {
            businessAnalysispng: "Business analytics.png",
            savedSuccessfully: "Úspěšně uložit",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Trvale účinný",
            permanentlyInvalid: "Trvale neplatný",
            customValidity: "Vlastní doba platnosti",
            termOfValidity: "Doba platnosti",
            name: "Jméno",
            explain: "Popis",
            state: "Stav",
            classification: "Klasifikace",
            smartRecommendation: "Inteligentní doporučení",
            preconditions: "Předpoklad",
            SetPreconditionsForThisSupervisionItem:
              "1. nastavit předpoklady pro tuto položku dohledu:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Je-li splněny předběžné podmínky, považuje se za "položku dohledu, která má být provedena", a položka dohledu musí být zkoušena;',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Pokud nejsou splněny předběžné podmínky, nedojde k odhalení položky dohledu;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. nenastavujte podmínky, to znamená, že všechny relace jsou detekovány ve výchozím nastavení.",
            have: "There are",
            nothing: "Žádné",
            preconditionRule: "Pravidlo předpokladu",
            preconditionLogic: "Logika předpokladu",
          },
          recommendation: {
            caseGrouping: "Seskupování případů",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Když zaměstnanci nevykonávají správně, mohou inteligentně doporučovat vynikající řeči a vynikající případy.",
            smartRecommendation: "Inteligentní doporučení",
            open: "Otevřít",
            close: "Close",
            whenThisSupervisionItem: "Když tato položka dohledu",
            implement: "Poprava",
            unexecuted: "Neprovedeno",
            when: "Kdy:",
            recommendedKnowledgePoints: "Doporučené znalostní body",
            caseRecommendation: "Doporučení případu",
          },
        },
        index: {
          determine: "Určit",
          sort: "Řadit",
          sortSucceeded: "Seřadit úspěšně",
          supervisionItemClassification: "Klasifikace položek dohledu",
          pressEnterToCreateANew: "Stisknutím tlačítka enter new",
          nameCannotBeEmpty: "Název nemůže být prázdný",
          newlyBuild: "Nový",
          name: "Jméno",
          explain: "Popis",
          validStatus: "Platný stav",
          permanentlyValid: "Trvale účinný",
          permanentlyInvalid: "Trvale neplatný",
          operation: "Operace",
          edit: "Upravit",
          batchDelete: "Smazat dávku",
          newSupervisionItem: "Nová položka dohledu",
          keyWord: "Klíčová slova",
          delete: "Smazat",
          deleteSucceeded: "Úspěšně smazán",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Data nelze po odstranění obnovit. Opravdu ho chcete odstranit?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Smart label",
              beEqualTo: "Rovná",
              includeAll: "Obsahuje všechny",
              includeAny: "Obsahuje jakékoli",
              matchAny: "Match any",
              matchAll: "Zápas vše",
              smartTagI: "Smart label one",
              smartTagII: "Smart label ii",
            },
            template: {
              pleaseSelectASmartTag: "Vyberte smart label",
              pleaseSelectAdvancedConditions: "Please select advanced criteria",
              newAdvancedFilter: "Nový pokročilý filtr",
              rename: "Přejmenovat",
              moveUp: "Přesunout nahoru",
              moveDown: "Posunout dolů",
              shiftLeft: "Přesunout vlevo",
              shiftRight: "Přesunout doprava",
            },
          },
        },
        template: {
          delete: "Smazat",
          labelFiltering: "Filtrování štítků:",
          satisfyAny: "Meet arbitrary",
          meetAll: "Meet all",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Počet sekundárních rozhovorů s označením klasifikace ≥ primární klasifikace, sezení může obsahovat více štítků",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logika pro výpočet změny prstenu: (objem dialogu ve vybraném časovém období-objem dialogu v bezprostředně předcházejícím časovém období)/objem dialogu v předcházejícím časovém období",
          proportionOfPrimaryClassification:
            "Procento klasifikace první úrovně",
          saveAs: "Uložit",
          cover: "Obal",
          preservation: "Uložit",
          qualityInspectionTask: "Úkoly analýzy:",
          query: "Dotaz",
          changeFromMonthToMonth: "Změna prstenu",
          tagRanking: "Pořadí štítků",
          proportion: "Procentuální situace",
        },
        component: {
          numberOfConversations: "Počet rozhovorů",
          operation: "Operace",
          businessAnalysispng: "Business analytics.png",
          savingSucceeded: "Úspěšně uložit",
          deletionSucceeded: "Úspěšně smazán",
          sequence: "Sekvence",
          firstLevelClassification: "Klasifikace úrovně 1",
          ringRatioChange: "Změna prstenu",
          classClassification: "Třída",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Potvrdit odstranění dokumentu a jeho extrahovaného obsahu?",
            addKnowledgeBase: "Přidat základnu znalostí",
            uploadDocument: "Nahrát dokument",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Podporovat soubory formátu word, pdf, ppt, excel, txt",
            taskName: "Název úlohy",
            extractConversationDate: "Datum vyúčtování konverzace",
            pleaseSelectAConversationDate: "Vyberte datum konverzace",
            dataSource: "Zdroj dat",
            dataType: "Typ dat",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Exportovat úspěšně",
              problem: "Otázka",
              answer: "Odpověď",
              addToKnowledgeBase: "Přidat do znalostní základny",
              pleaseConfirmWhetherToDeleteIt: "Určete, zda chcete odstranit",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Přidat do znalostní základny",
              selectClassification: "Vybrat kategorii",
              pleaseSelectAKnowledgeBaseClassification:
                "Vyberte klasifikaci základny znalostí",
            },
          },
        },
        index: {
          intelligentExtraction: "Inteligentní extrakce",
          documentExtraction: "Extrakce dokumentů",
          dialogueExtraction: "Extrakce dialogu",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operace byla úspěšná",
          labelIgnored: "Značka ignorovaná",
          add: "Přidat",
          areYouSureToDeleteIt: "Opravdu chcete odstranit?",
          batchFilterLabels: "Štítk filtra šarže",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Inteligentní kontrola kvality",
        savedSuccessfully: "Úspěšně uložit",
        logoSettings: "Nastavení loga",
        default: "Výchozí",
        custom: "Vlastní",
        title: "Název",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Název názvu zobrazený systémem se doporučuje být v rozmezí 6 znaků.",
        logoImageUpload: "Nahrát obrázek s logem",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Please select a picture with a size not exceeding 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Za účelem zajištění efektu prohlížení loga se navrhuje nahrát obrázky:",
        Size5050Pixels: "1. size: 50*50 pixels;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. format: picture with transparent background, png or jpg,logo graphic using white",
        browserLabelIcon: "Ikona záložky prohlížeče",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Pro zajištění účinku prohlížení značek se doporučuje nahrávat obrázky:",
        Size3232Pixels: "1. size: 32*32 pixels;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. formát: obrázek s průhledným pozadím, png nebo jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Kontrola kvality probíhá, prosím počkejte...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "V roce 2015 dosáhla nemovitost mé země kritického okamžiku přežití. Velký počet domů byl bez dozoru a lidé je nechtěli koupit. Hangzhou jednou klesl pod 8000 a někteří vývojáři rozbili trh, aby dodali. Shenzhen se v té době stále konsolidoval kolem 2w-3w a domy v nejvyšších městech byly také v ohrožení.",
        xiaoAn: "Ann.",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload tyto paměťové bariéry se nazývají jsr paměťové bariéry, které jsou na úrovni jvm. V dolní části operačního systému jsou dvě implementace: jedna je zámek zbernice a druhá je primitivní paměťová bariéra, jako je sfance (zápis bariéry) mfence (plná bariéra) ifence (čtení bariéry). Spodní vrstva virtuálního stroje hotspots používá sestavovací příkaz: zamknout addl, zamknout prázdnou operaci, což je zámek. This is done because some operating systems do not support memory barrier primitives.",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. try to marinate the meat several times and stir-fry the vegetables after adding wine. Maso je mrzuté a voní kyselou! Od nynějška už žádné víno pro slaninu",
        xiaodong: "Xiaodong",
        xiaoxi: "Xiao xi",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. try to marinate the meat several times, and the vegetables fried after adding wine are like spoiled meat, with a sour smell! Od nynějška už žádné víno pro slaninu",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "E-commerce review management",
        socialMediaReviewManagement: "Správa hodnocení sociálních médií",
        customDataSource: "Vlastní zdroj dat",
        qualityInspectionResults: "Výsledky kontroly kvality",
        accountManagement: "Správa účtů",
        employeeProfile: "Zaměstnanecký portrét",
        intelligentAnalysis: "Inteligentní analýza",
        customKanban: "Vlastní kanban",
        dataScreening: "Přehled dat",
        learningRecord: "Záznam učení",
        dataAnalysis: "Analýza dat",
        scoringManagement: "Řízení skóre",
        businessConfiguration: "Konfigurace podnikání",
        manualReview: "Manuální přezkum",
        taskList: "Seznam úkolů",
        videoAnalysis: "Analýza videa",
        overview: "Přehled",
        personalSignage: "Osobní kanban",
        learningCenter: "Centrum učení",
        wrongQuestionSet: "Chybný soubor problémů",
        abilityImprovement: "Schopnost aktualizovat",
        close: "Close",
        switchingSystemApplications: "Aplikace pro přepínání systému",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Přesunout myši sem, aby se volalo do nabídky pro přepínání aplikací a klepnutím na položku nabídky přeskočíte do různých aplikací.-----------------------------------------------------------------------------------------",
        teamAnalysis: "Analýza týmu",
        semanticIntelligence: "Sémantická inteligence",
        permissionManagement: "Správa oprávnění",
        dataConnection: "Data docking",
        courseManagement: "Řízení kurzů",
        labelManagement: "Správa štítků",
        curriculum: "Kurzové centrum",
        courseConfiguration: "Konfigurace kurzu",
        task: "Moje mise",
        historicalLearningData: "Historie",
        taskCenter: "Správa úkolů",
        knowledgeBase: "Základna znalostí",
        ecommerceSessionManagement: "Správa relací elektronického obchodu",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Přepínání aplikací",
          descriptionOfIntelligentQualityInspectionSystem:
            "Popis inteligentního systému kontroly kvality",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Filtr relace elektronického obchodu",
        allEcommerceSessions: "Všechny relace elektronického obchodu",
        ecommerceSessionManagement: "Správa relací elektronického obchodu",
      },
    },
    analysisEnable: {
      bestPractices: "Osvědčené postupy",
      behaviorAnalysis: "Analýza chování",
      teamAnalysis: "Analýza týmu",
      executiveSupervision: "Dohled nad výkonnými silami",
      executiveForceAnalysis: "Analýza provádění",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Rating:",
          startTime: "Čas zahájení",
          workOrderNo: "Číslo pracovní objednávky",
          customerServiceHasReadNotRead: "Čtení/nepřečteno agenta",
          canYouAppeal: "Zda se odvolávat",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Podnikové mikro poznámky",
          addFriendTime: "Přidat čas kamaráda",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Spustit opětovnou inspekci",
          callID: "Id volání",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Nové funkce! Kliknutím na tlačítko "quick sampling check" automaticky přeskočí na další stránku po úspěšném podání ~ ~ ~',
        },
      },
      template: {
        deleteRecord: "Smazat záznam",
        areYouSureToDeleteIt: "Opravdu chcete odstranit?",
        confirm: "Určit",
        reIdentification: "Opětovná identifikace",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Re-identifikace bude spotřebovat čas převodu, prosím operovat s opatrností.",
        determine: "Určit",
        executionSuccessful: "Úspěšné provedení",
      },
      component: {
        pleaseSelectData: "Vyberte data",
        operationSuccessful: "Operace byla úspěšná",
        startPerformingReQualityInspection: "Spustit opětovnou inspekci",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Načíst název souboru",
        analysisTask: "Úloha analýzy",
        analyzeProgress: "Analyzovat pokrok",
        analysisSucceeded: "Analýza úspěchu",
        analysisFailed: "Analýza selhala",
        localDataUpload: "Nahrávání místních dat",
        recordingUpload: "Nahrávání zvukového záznamu",
        textUpload: "Nahrát text",
        uploadTime: "Čas načítání",
        taskName: "Název úlohy",
        operator: "Operátor",
        qualityInspectionProgress: "Pokrok kontroly kvality",
        whole: "All",
        haveInHand: "Probíhá",
        completed: "Dokončeno",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Exportovat všechny dokončené záznamy dat podle aktuálních kritérií filtru?",
        upload: "Nahrát",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Vyplnit název",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Zadejte název, například typ/sběr/účel dovážených dat.",
            default: "Výchozí",
            jDCOM: "Jingdong",
            dataType: "Typ dat",
            selectAnalysisTask: "Vybrat úlohu analýzy",
            uploadFiles: "Nahrát soubor",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. download the excel template and fill it in according to the format:",
            template: "Šablona",
            SupportUpTo10000PiecesOfData: "2. maximální podpora 10000 kusů dat",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Podpora jingdong exportované data dialogu upload,.txt soubor, maximální podpora velikosti souboru 100m",
            alongTheWayDataTemplate: "Šablona dat sledovat silnici",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Prosím, počkejte trpělivě během kontroly kvality textu.",
            textQualityInspectionCompleted: "Kontrola kvality textu dokončena",
          },
        },
        template: {
          startAnalysis: "Spustit analýzu",
          pleaseUploadTheFile: "Please upload the file",
          localDataUpload: "Nahrávání místních dat",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "Nové funkce! Klikněte na tlačítko \"review submit', poté, co je podání úspěšné, bude automaticky přeskočit na další stránku ~ ~ ~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "Oprávnění úkolu kontroly kvality",
            edit: "Upravit",
            iParticipated: "Jsem zapojen do",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Tato role může zobrazit data pod vybraným oddělením.",
            dataPermissions: "Oprávnění pro data",
            selectDepartment: "Vybrat oddělení",
            edit: "Upravit",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Spustit opětovnou inspekci",
          callID: "Id volání",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Nové funkce! Kliknutím na tlačítka "podat vzorky" a "podat rychlý vzorek" se automaticky po úspěšném podání přeskočí na další stránku ~ ~ ~',
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Pravidla" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Řízení pracovního pořadí",
          workOrderDetails: "Podrobnosti pracovní objednávky",
        },
      },
      template: {
        workOrderFilter: "Filtr pracovní objednávky",
        allWorkOrders: "Všechny pracovní objednávky",
        workOrderManagement: "Řízení pracovního pořadí",
      },
    },
    trainingCenter: {
      topicAnnotation: "Topic callout",
      topicList: "Seznam témat",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Typ role",
              keyWord: "Klíčová slova",
              pleaseEnterKeywordSearch: "Zadejte prosím hledání klíčových slov",
              query: "Dotaz",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Tato funkce dosud nebyla otevřena. V případě potřeby se obraťte na správce systému.",
          pleaseSelectAQuestion: "Prosím, vyberte otázku!",
          recommendedSimilarSentencesLargeModel:
            "Doporučené podobné věty (velký model)",
          selectAll: "Vybrat vše",
          add: "Přidat",
          changeBatch: "Změna šarže",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Pracovní doba",
      localDataUpload: "Nahrávání místních dat",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Sestavit seznam menu podle aktuálních uživatelských práv",
      sessionSettings: "Nastavení relace",
      logoSettings: "Nastavení loga",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Vítejte zpátky! Zadejte prosím vaše údaje",
        mailbox: "Poštovní schránka",
        password: "Heslo",
        signIn: "Přihlášení",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "Aktuální ip neumožňuje přihlášení",
          welcomeToLogin: "Vítejte na přihlášení",
          pursuingExcellenceAndLeadingChange:
            "The pursuit of excellence, leading the change",
          intelligentPlatform: "Inteligentní platforma",
        },
        virtualLogin: { index: { pleaseSelect: "Vyberte prosím" } },
      },
      oauth: {
        illegalCall: "Nezákonné volání",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Vítejte zpátky! Klepněte na tlačítko níže",
        loggingIn: "Přihlášení...",
        unifiedIdentityAuthenticationLogin:
          "Přihlášení k jednotné ověření totožnosti",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Testování pravidel: některé pravidla nelze zkoušet pomocí pravidel, jako je rychlost řeči a hlasitost, data na cestě atd.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Více informací",
          enteringTheSystem: "Zadejte systém",
        },
      },
      index: {
        cancel: "Storno",
        enteringTheSystem: "Zadejte systém",
        close: "Close",
        enterSystemApplication: "Zadejte systémovou aplikaci",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Klepnutím na tlačítko "zadejte systém" přejdete na povolenou aplikaci systému',
      },
    },
    permissionManagement: {
      employeeManagement: "Řízení zaměstnanců",
      roleManagement: "Řízení úloh",
      permissionManagement: "Správa oprávnění",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Rovná",
            contain: "Obsahuje",
            doesNotContain: "Neobsahuje",
            applicationSystem: "Aplikační systém",
            intelligentQualityInspection:
              "Inteligentní kontrola kvality/inteligentní analýza sezení",
            salesEmpowerment: "Empowerment sales",
            dataSourceType: "Typ zdroje dat",
            filterCriteria: "Stav filtru",
          },
        },
        index: {
          dataConnection: "Data docking",
          savingSucceeded: "Úspěšně uložit",
          dataIsolation: "Izolace dat",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Po otevření můžete nastavit datový rozsah každého přístupového volání aplikacího systému a relace enterprise wechat. Pokud není přepínač zapnut, všechny systémy jsou připojeny k úplným datům.",
          accessRules: "Pravidla přístupu",
          preservation: "Uložit",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Název aplikace",
            accessRights: "Přístup",
            inAppPermissionSettings: "Nastavení oprávnění v aplikaci",
            setUp: "Instalace",
            applicationAccess: "Přístup k aplikacím",
            newRole: "Nová role",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Podrobnosti o relaci" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Podrobnosti o relaci",
          caseDetails: "Podrobnosti případu",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Viditelní zaměstnanci",
            newCourse: "Nový kurz",
            editCourse: "Upravit kurz",
            courseName: "Název kurzu",
            courseIntroduction: "Úvod kurzu",
            courseType: "Typ kurzu",
            courseClassification: "Klasifikace kurzu",
            courseCover: "Obal kurzu",
            learningContent: "Učení se obsahu",
            uploadLearningMaterials: "Nahrát učebné materiály",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Test",
              preservation: "Uložit",
              cancel: "Storno",
              editNodeName: "Upravit název uzlu",
              determine: "Určit",
              individual: "One",
              of: ", Celkem",
              branch: "Body",
            },
            configDrawerComponents: {
              pPTParsing: "Analýza ppt v...",
              uploadSuccessful: "Nahrávat úspěšně",
              uploadAttachments: "Nahrát přílohu",
              pPTParsingPleaseWait: "Ppt parsing, prosím počkejte",
            },
            customerPortraitConfigDrawer: {
              cancel: "Storno",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Po odstranění nelze obnovit. Opravdu chcete tento portrét odstranit?",
              delete: "Smazat",
              determine: "Určit",
              customerPortrait: "Portrét zákazníka",
              bindSelectedCharacterPortrait: "Svázat vybraný portrét znaků",
              customerProfileDetails: "Podrobnosti profilu zákazníka",
              createANewCustomerProfile: "Nový profil zákazníka",
            },
            flow: {
              sidebar: { node: "Uzel" },
              useControlButtons: {
                cancellingPleaseWait: "Zrušit, prosím později...",
                redoInProgressPleaseWait: "Redo, prosím později...",
                automaticOrganization: "Automatické dokončení",
                canvasAdaptation: "Adaptivní plátno",
                delete: "Smazat",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Uzel se nemůže připojit k sobě.",
              },
            },
            globalConfigDrawer: {
              cancel: "Storno",
              determine: "Určit",
              globalConfiguration: "Globální konfigurace",
              sessionDetection: "Detekce relace",
              reciprocalRule: "Pravidla interakce",
              robotVoice: "Robot sound",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Nastavení časového limitu studentského uzlu bez odpovědi",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Maximální povolený tichý čas (y)",
              promptScriptForFailureToAnswerOverTime: "Výzva pro časový limit",
              addScript: "Přidat slova",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (příroda)",
              zhishaChat: "Zhisha (chat)",
              zhiqiangentle: "Zhiqian (jemný)",
              zhimiEmotion: "Poznat rýži (emoce)",
              aiShuoNatural: "Aishuo (příroda)",
              zhishuoChat: "Zhishuo (chat)",
              aiHaoAffinity: "Ai hao (afinita)",
              zhixiangEmotion: "Zhixiang (emoce)",
              robotVoice: "Robot sound",
              schoolboy: "Chlapci",
              girlStudent: "Dívky",
              volume: "Svazek",
              tone: "Tón",
              speechSpeed: "Rychlost řeči",
              test: "Test",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Zdravím, jsem inteligentní tréninkový trenér a těším se na komunikaci s vámi.",
            },
            sessionDetectionForm: {
              expressionDetection: "Detekce výrazu",
              excessiveDetectionOfModalParticles:
                "Nadměrná detekce modálních slov",
              theNumberOfRepetitionsIs: "Počet opakování je",
              minus: "Čas, mínus",
              appear: "Zobrazit",
              subtract: "Časy, mínus",
              branch: "Body",
              addRules: "Přidat pravidlo",
              includingNegativeAngryComplaining:
                "(Including negative, angry, complaining)",
              scorePointsDisplay: "Zobrazit body skóre",
              reminderOfSpeakingTooFasttooSlow:
                "Rychlost příliš rychlá/příliš pomalá připomínka",
              scriptViolationReminder: "Připomínka porušování řeči",
              emotionalReminder: "Emocionální připomínka",
              speechSpeedDetection: "Detekce rychlosti řeči",
              normalSpeechSpeedScore: "Rychlost normální skóre",
              speakTooFast: "Rychle příliš rychle",
              everyMinuteGreaterThan: "Za minutu větší",
              aWordAppears: "Slovo, objevte se.",
              speakTooSlowly: "Rychlost pomalá",
              lessThanPerMinute: "Méně než za minutu",
              ignoreLessThan: "Ignorovat méně",
              aSentenceOfWords: "Věta slov",
              emotionalDetection: "Detekce emocí",
              emotionalNormalScore: "Normální skóre nálady",
              negativeEmotions: "Negativní emoce",
              illegalWordDetection: "Nezákonná detekce slov",
              scriptViolationConfiguration: "Konfigurace porušování řeči",
              realTimePromptOnTheFrontEnd: "Front-end real-time prompt",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Znalostní základna q & a",
                  qAMethod: "Způsob otázky a odpovědi",
                  numberOfQuestionsAndAnswers: "Počet otázek a odpovědí",
                  scoringRules: "Pravidla pro hodnocení",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Jakákoliv otázka a odpověď je správná, máte",
                  branch: "Body",
                  successfullySaved: "Úspěšně uložit",
                },
                pptNode: {
                  verificationPassed: "Ověřování provedeno",
                  upload: "Nahrát",
                  supportPptPdfFormatFiles:
                    "Podporovat soubory formátu ppt a pdf",
                  uploadSlide: "Nahrávat snímky",
                  assessmentMethod: "Metoda hodnocení",
                  associateSlide: "Přidružené snímky",
                  standardScript: "Standardní řeči",
                  successfullySaved: "Úspěšně uložit",
                },
                robotNode: {
                  robotScript: "Robot speech",
                  randomScript: "Náhodná reč",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Zvolte slova s podobnou sémantikou a robot náhodně řekne jednu z vět.",
                  customerPortrait: "Portrét zákazníka",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "When the customer portrait is consistent, the robot speaks this",
                  setAsDefaultScript: "Nastavit jako výchozí",
                  yes: "Ano",
                  no: "Ne",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "To se říká, když robot chybí jakoukoli uživatelskou značku.",
                  successfullySaved: "Úspěšně uložit",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "This branch node has already a default speech. Chcete výchozí projev nahradit aktuální řečí?",
                },
                startNode: {
                  paragon: "Voiceover",
                  successfullySaved: "Úspěšně uložit",
                },
                userNode: {
                  verificationPassed: "Ověřování provedeno",
                  standardScript: "Standardní řeči",
                  successfullySaved: "Úspěšně uložit",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Prosím, zdokonalte konfiguraci",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "Zadejte vzorek konverzace",
                  dialogueExample: "Příklad konverzace",
                  generateConversationExamples: "Příklad generování dialogu",
                  mattersNeedingAttention: "Opatření",
                  pleaseEnterThePromptLanguage: "Zadejte prosím výzvu",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Scéna dialogu",
                  simulateCharacter: "Simulovaný znak",
                  accompanyingTrainees: "Doprovodný objekt",
                  selectFromTemplateLibrary: "Vybrat z knihovny šablony",
                  templateLibrary: "Knihovna šablony",
                  role: "Role",
                  scene: "Scéna",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow:
                "Okno testování snímků-------------------------------------------------------------------------------------------------------------------",
              clearContent: "Vymazat obsah",
              close: "Close",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "Proces generování ai",
                scriptStyle: "Styl řeči",
                theMostConversationRounds: "Většina kol dialogu",
                copyConversation: "Kopírovat dialog",
                application: "Aplikace",
                regenerate: "Regenerovat",
                startGenerating: "Spustit generování",
                pleaseGenerateAProcess: "Generujte prosím proces",
                copySuccessful: "Kopírovat úspěch",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Prodejní zástupce",
                    customerServicePersonnel: "Zákaznický personál",
                    storeCounter: "Počítadlo skladu",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Generovat plnotextové jazykové dovednosti",
                speaker: "Mluvčí",
                scriptStyle: "Styl řeči",
                copyConversation: "Kopírovat dialog",
                application: "Aplikace",
                regenerate: "Regenerovat",
                startGenerating: "Spustit generování",
                pleaseGenerateAProcess: "Generujte prosím proces",
                operationSuccessful: "Operace byla úspěšná",
                copySuccessful: "Kopírovat úspěch",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Polská řeči" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Slova nemohou být prázdná!",
              copySuccessful: "Kopírovat úspěch",
              cancel: "Storno",
              copy: "Kopírovat",
              replaceTheOriginalText: "Nahradit originál",
              aiGeneration: "Generace ai",
              aiPolishing: "Ai polish",
              currentStyle: "Aktuální styl",
              rigorous: "Rigorous",
              mild: "Jemný",
              lively: "Lively",
              simplicity: "Prosté",
              pleaseEnterTheSpeakerRole: "Zadejte roli mluvčího.",
              startGenerating: "Spustit generování",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Slova nemohou být prázdná!",
              copySuccessful: "Kopírovat úspěch",
              aiRewrite: "Přepisovat ai",
              cancel: "Storno",
              copy: "Kopírovat",
              replaceTheOriginalText: "Nahradit originál",
              currentStyle: "Aktuální styl",
              rigorous: "Rigorous",
              mild: "Jemný",
              lively: "Lively",
              simplicity: "Prosté",
              pleaseEnterTheSpeakerRole: "Zadejte roli mluvčího.",
              startGenerating: "Spustit generování",
            },
          },
          index: {
            verificationPassed: "Ověřování provedeno",
            ruleValidation: "Ověření pravidel",
            aIGeneration: "Generace ai",
            generateFullTextSpeechScript:
              "Generovat plnotextové jazykové dovednosti",
            editCourse: "Upravit kurz",
            importConversation: "Importovat konverzaci",
            uploadExcelToCompleteDialogueImport:
              "Načíst kompletní importování dialogového okna aplikace excel",
            editBasicInformation: "Upravit základní informace",
            test: "Test",
            release: "Publikovat",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Nová značka" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Po odstranění nelze obnovit. Opravdu chcete tuto značku odstranit?",
              delete: "Smazat",
              cancel: "Storno",
            },
          },
        },
        index: {
          labelManagement: "Správa štítků",
          newGroup: "Nová skupina",
          edit: "Upravit",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Po odstranění nelze obnovit. Opravdu si přejete smazat tuto skupinu štítků?",
          delete: "Smazat",
          cancel: "Storno",
          editGroup: "Upravit seskupování",
          labelGroupName: "Název skupiny štítků",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Kamera zakázána",
              noCameraDevicesFound: "Zařízení fotoaparátu nenalezeno",
            },
          },
          cue: {
            index: {
              regenerate: "Regenerovat",
              generate: "Generovat",
              referenceScript: "Referenční projev",
              mattersNeedingAttention: "Opatření",
              examFrequency: "Počet zkoušek",
              section: "Sekce",
              timestotal: "Časy/celkem",
              second: "Times",
              requirementsForPassingTheExam: "Požadavky na absolvování zkoušky",
              examScore: "Testovací skóre ≥",
              branch: "Body",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Žádný portrét",
              customerBackground: "Pozadí zákazníka",
              customerLabel: "Štítek zákazníka",
            },
          },
          flow: {
            index: {
              processReminder: "Procesní výzva (",
              score: "Skóre",
              TotalScore: "/Celkové skóre",
            },
          },
          index: {
            taskName: "Název úlohy",
            taskStatus: "Stav úkolu",
            whole: "All",
            completionStatus: "Stav dokončení",
            courseClassification: "Klasifikace kurzu",
            practiceMode: "Režim praxe",
            examMode: "Režim zkoušky",
            practiceTime: "Čas cvičení",
          },
          ppt: {
            index: {
              nextPage: "Další stránka",
              tips: "Výzva",
              continueWithCurrentPage: "Pokračovat v aktuální stránce",
              goToTheNextPage: "Přejít na další stránku",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Po zadání další stránky se nebudete moci vrátit na předchozí stránku. Please confirm that the explanation of the current page is complete before jumping.",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Kliknutím na další stránku neobjeví tento výzva",
            },
            list: { index: { slide: "Posuv" } },
          },
          record: {
            index: {
              unableToRecord: "Nelze zaznamenat:",
              creatingARecorderService: "Vytvořit službu recorder",
              turnOffTheRecorderService: "Vypnout službu zapisovače",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Aktuální klient je již připojen k serveru asr websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "Po zpracování posledního rámu obnovit stav",
              aSRConnectionClosed: "Připojení asr uzavřeno...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Záznam je pozastaven a byl zpracován, ale v mezipaměti jsou stále údaje.",
              foundASRChannelClosedRecreateWebsocketLink:
                "Nalezeno asr channel uzavřeno, re-create websocket link",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Zjistili, že asr kanál není otevřen, znovu vytvořit websocket odkaz",
            },
            ppt: { hook: { index: { serverError: "Chyba serveru" } } },
            text: {
              index: {
                pleaseEnter: "Zadejte prosím",
                sendOut: "Odeslat",
                startPracticing: "Začít cvičit",
                startTheExam: "Zahájit zkoušku",
              },
            },
            util: {
              recorded: "Recorded",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 bitový pcm sendframesize musí být celočíselný násobek 2",
              pCMSamplingRateShouldNotOccur:
                "Rychlost odběru vzorků pcm by se neměla zobrazovat",
              andTheRequiredSamplingRate: "A požadovaná rychlost odběru vzorků",
              atypism: "Nekonzistentní",
              errorsThatShouldNotOccur: "Chyby, které by se neměly vyskytnout:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> data, which is out of order can be discarded if the requirements are not high. </Span>',
              flower: "Květiny",
              TransferStopped: ": Přenos zastavil",
            },
            video: { index: { camera: "Kamera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Aktuální klient je již připojen k serveru asr websocket",
                    aSRConnectionClosed: "Připojení asr uzavřeno...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Nalezeno asr channel uzavřeno, re-create websocket link",
                    unableToRecord: "Nelze zaznamenat:",
                    aSRChannelClosedReopened:
                      "Asr channel uzavřen, znovu otevřen",
                    theASRChannelWasNotCreatedStartingNow:
                      "Asr kanál není vytvořen, začít vytvářet nyní",
                    useEffectCallbackToClearTheASRChannel:
                      "Použit zpětné volání k vymazání asr kanálu.----------------------------------------------------------------------------------------------------------------",
                    creatingARecorderService: "Vytvořit službu recorder",
                    turnOffTheRecorderService: "Vypnout službu zapisovače",
                  },
                  util: {
                    recorded: "Recorded",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 bitový pcm sendframesize musí být celočíselný násobek 2",
                    pCMSamplingRateShouldNotOccur:
                      "Rychlost odběru vzorků pcm by se neměla zobrazovat",
                    andTheRequiredSamplingRate:
                      "A požadovaná rychlost odběru vzorků",
                    atypism: "Nekonzistentní",
                    errorsThatShouldNotOccur:
                      "Chyby, které by se neměly vyskytnout:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> data, which is out of order can be discarded if the requirements are not high. </Span>',
                    flower: "Květiny",
                    TransferStopped: ": Přenos zastavil",
                  },
                },
                index: { failInSend: "Nepodařilo se odeslat" },
              },
              index: {
                startRecording: "Spustit nahrávání",
                endRecording: "Ukončit nahrávání",
                startPracticing: "Začít cvičit",
                startTheExam: "Zahájit zkoušku",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(The current number of exams remaining is",
              totalScriptScore:
                "Celkový skóre řeči:---* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *",
              totalEmotionalScore: "Celkové emocionální skóre:",
              totalScoreOfExpression:
                "Celkový výraz skóre:---* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Aktuální výsledky hodnocení úkolů je třeba ručně přezkoumat. Po dokončení přezkumu budete informováni o konečných výsledcích. Prosím, počkejte trpělivě.",
              areYouSureToSubmit: "Potvrdit podání?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Cvičení skončilo, jste si jistý, že chcete předložit?",
              theExamHasEndedAreYouSureToSubmit:
                "Zkouška skončila, jste si jistý, že ji chcete předložit?",
              completed: "Dokončeno",
              page: "Page",
              pageCount: "Celkem stránek",
              topic: "Téma",
              totalNumberOfQuestions: "Celkový počet otázek",
              totalScore: "Celkové skóre",
              branch: "Body",
              accumulatedCompletionProgress: "Kumulativní pokrok dokončení",
              accumulatedPracticeDuration: "Doba trvání kumulativní praxe",
              lastPauseExerciseResults: "Last time-out-practice results",
              exerciseInstructions: "Pokyny pro cvičení",
              examInstructions: "Popis zkoušky",
              halfwayPausePracticeResults: "Halfway pauza-výsledky praxe",
              tips: "Výzva",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Po skončení tohoto cvičení nebudou zahrnuty do skóre. Jsi si jistá, že chceš přestat?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Odchod není povolen v polovině zkoušky. Potvrdit podání?",
              pleaseOperateWithCaution: "Times, please operate carefully)",
              restart: "Restartovat",
              returnToLearning: "Návrat k učení",
              continuePracticing: "Pokračovat v praxi",
              continuingTheExam: "Pokračovat ve zkoušce",
              return: "Return",
              notSubmittedTemporarily: "Neodesláno",
              iGotIt: "Já vím.",
              confirmExit: "Potvrdit výstup",
              confirmSubmission: "Potvrdit podání",
              signOut: "Exit",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Pauza",
              submit: "Podání",
              remainingTimeOfThisQuestion: "Zbývající čas",
              SecondsLeftUntilTheEndTime: "10 sekund do konce času",
              remainingTime: "Zbývající čas",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Obsah hlasu uživatele nebyl detekován, zkuste to znovu.",
          },
          wave: {
            index: {
              continueRecording: "Pokračovat v nahrávání",
              submit: "Podání",
              endRecording: "Ukončit nahrávání",
              suspendRecording: "Pozastavit nahrávání",
              startPracticing: "Začít cvičit",
              startTheExam: "Zahájit zkoušku",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Režim dialogu",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Příliš mnoho hluku, přepněte prosím na klidné prostředí.",
              normalNoiseDetection: "Detekce hluku je normální",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "The volume is too low, please increase the volume",
              volumeDetectionIsNormal: "Detekce hlasitosti normální",
              noiseDetection: "Detekce hluku",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Prosím, mlčte a zaznamenejte 3s ambient sound",
              endRecording: "Ukončit nahrávání",
              startRecording: "Spustit nahrávání",
              volumeDetection: "Detekce svazku",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Please read "volume test now" in normal volume"',
              playLastDetectedAudio: "Play last detection audio",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Před začátkem si prosím pečlivě přečtěte požadavky.",
              return: "Return",
              iGotIt: "Já vím.",
              timeLimitForSingleQuestionAnswering:
                "Lhůta pro odpověď na jednu otázku",
              totalTimeLimit: "Celkový časový limit",
              unlimitedTime: "Neomezený čas",
              stillNeedToCompleteTheTaskInTotal:
                "Je stále nutné dokončit úkol.",
              practice: "Cvičení",
              second: "Times",
              taskScore: "Jeden skóre úlohy ≥",
              branch: "Body",
              or: "Nebo",
              examScore: "Testovací skóre ≥",
              remainingExamTimes: "Počet zbývajících zkoušek",
              totalScore: "Celkové skóre",
              Script: "(Talker",
              scoreEmotion: "Emoce",
              fractionExpression: "Dílčí výraz",
              points: "Body)",
              standardScore: "Standardní skóre",
              excellentScore: "Vynikající skóre",
              exerciseInstructions: "Pokyny pro cvičení",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Za účelem bezproblémové odpovědi na otázku, dokončíte nejprve zkoušku zařízení:",
              camera: "Kamera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Být schopný vidět sami sebe ve videu znamená, že kamera je normální.",
              microphone: "Mikrofon",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Prosím, mluvte do mikrofonu, viz vzor vlny znamená, že mikrofon je normální",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Prázdný",
                  copyTo: "Kopírovat",
                  classification: "Klasifikace",
                  rename: "Přejmenovat",
                  newlyBuild: "Nový",
                  categoryParent: "Vyšší klasifikace",
                  moveTo: "Přesunout",
                  name: "Jméno",
                },
              },
              util: {
                rename: "Přejmenovat",
                moveTo: "Přesunout",
                copyTo: "Kopírovat",
                delete: "Smazat",
                courseClassification: "Klasifikace kurzu",
                newlyBuild: "Nový",
              },
            },
          },
          edit: { index: { curriculum: "Curriculum" } },
          index: {
            learn: "Učení",
            practice: "Praxe",
            examination: "Test",
            curriculum: "Curriculum",
            courseIntroduction: "Úvod kurzu:",
            createdOn: "Čas vytvoření:",
            published: "Published",
            tasks: "Úkoly",
            unpublishedTasks: "Nezveřejněná úloha",
            task: "Úkoly",
            operation: "Operace",
            learningMaterials: "Učebné materiály",
            goPractice: "Přejít na praxi",
            publishTask: "Publikovat úkoly",
            taskData: "Data úloh",
            edit: "Upravit",
            copy: "Kopírovat",
            successfullyCopied: "Kopírovat úspěch",
            delete: "Smazat",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Po odstranění budou všechny údaje úkolů v rámci tohoto kurzu vymazány. Jste si jistý, že odstraníte kurz?",
            courseName: "Název kurzu",
            creationTime: "Čas vytvoření",
            releaseStatus: "Stav publikování",
            whole: "All",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Nebyl zjištěn žádný zvuk",
              voice: "Hlas",
              text: "Text",
              referenceScript: "Referenční projev:",
              clickOnTheRightSideToStartRecording:
                "Kliknutím vpravo začnete nahrávat",
              sendOut: "Odeslat",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Aktuální klient je již připojen k serveru asr websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "Po zpracování posledního rámu obnovit stav",
                aSRConnectionClosed: "Připojení asr uzavřeno...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Záznam je pozastaven a byl zpracován, ale v mezipaměti jsou stále údaje.",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Nalezeno asr channel uzavřeno, re-create websocket link",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Zjistili, že asr kanál není otevřen, znovu vytvořit websocket odkaz",
                unableToRecord: "Nelze zaznamenat:",
                creatingARecorderService: "Vytvořit službu recorder",
                turnOffTheRecorderService: "Vypnout službu zapisovače",
              },
              util: {
                recorded: "Recorded",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 bitový pcm sendframesize musí být celočíselný násobek 2",
                pCMSamplingRateShouldNotOccur:
                  "Rychlost odběru vzorků pcm by se neměla zobrazovat",
                andTheRequiredSamplingRate:
                  "A požadovaná rychlost odběru vzorků",
                atypism: "Nekonzistentní",
                errorsThatShouldNotOccur:
                  "Chyby, které by se neměly vyskytnout:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> data, which is out of order can be discarded if the requirements are not high. </Span>',
                flower: "Květiny",
                TransferStopped: ": Přenos zastavil",
              },
            },
            finishTest: {
              index: {
                endTest: "Koncový test",
                testResult: "Výsledky testů",
                returnToCanvas: "Návrat k plátnu",
                retest: "Re-test",
              },
            },
          },
          index: {
            testFromCurrentNode: "Test z aktuálního uzlu",
            listening: "Rádio v...",
            canvasTesting: "Test z plátna",
            endOfProcess: "Konec procesu",
            restart: "Restartovat",
            start: "Začátek",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Analýza kolapsu",
                  expandParsing: "Rozbalit analýzu",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Příliš mnoho modálních slov",
                  second: "Times",
                  ratingOverview: "Přehled skóre",
                  totalScore: "Celkové skóre",
                  section: "Sekce",
                  name: "Jméno",
                  of: "(Celkem",
                  people: "Lidé)",
                  scriptScore: "Skóre řeči",
                  common: "Celkem",
                  keyPoints: "Klíčový bod,",
                  notReached: "Nedosáhl,",
                  have: "There are",
                  violationOfRegulations: "Porušování",
                  emotionalScore: "Emocionální skóre",
                  appear: "Zobrazit",
                  secondaryNegativeEmotions: "Sekundární negativní emoce",
                  expressionScore: "Výraz skóre",
                  speakTooFast: "Rychle příliš rychle",
                  times: "Times,",
                  speakTooSlowly: "Rychlost pomalá",
                  average: "Průměr",
                  wordminute: "Slovo/minuta",
                },
              },
              score: {
                index: {
                  studentNodeName: "Název uzlu studentů",
                  keyPointName: "Název klíčového bodu",
                  slideNodeName: "Název uzlu",
                  dialogue: "Dialog",
                  mainPoints: "Klíčové body",
                  slide: "Posuv",
                  detectionResult: "Výsledky testů",
                  satisfy: "Meet",
                  dissatisfaction: "Neuspokojení",
                  score: "Skóre",
                  script: "Slova",
                  semantics: "Sémantická",
                  scriptViolation: "Porušování řeči",
                  emotion: "Emoce",
                  expression: "Výraz",
                },
              },
              taskTitle: { index: { branch: "Body" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "This time",
                  lastTime: "Naposledy",
                  comprehensiveEvaluation: "Komplexní hodnocení",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· standard speech cumulative',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "A word, the number of words hit by the student",
                  aWordspan: "Word </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Times </span><br /><span style = "margin-left: 8px">· slow speed',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· too many modal words',
                  complete: "Kompletní obsah",
                  keyHit: "Hit klíčových bodů",
                  languageNorm: "Specifikace jazyka",
                  fluentExpression: "Hladký výraz",
                  emotionallyPositive: "Emocionálně pozitivní",
                  radarChart: "Radarový graf",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· number of complete flows',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· hit key points',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· yes',
                  dealingWithIllegalWordsspan:
                    "In violation of the word </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· speak too fast',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '</Span><br /><span style = "margin-left: 8px"> too many modal words',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· negative emotions',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Záznam skóre",
                  firstRating: "První hodnocení",
                  totalScore: "Celkové skóre",
                  changeTo: "Změna",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Fáze komunikace",
                  communicationObjectives: "Cíle komunikace",
                  scoringCriteria: "Hodnocení kritérií",
                  suggestionsForImprovement: "Doporučení pro propagaci",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Zbývající:",
                  second: "Times",
                  doItAgain: "Ještě jednou.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Prohlížeč nepodporuje přehrávání zvuku.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Připomínky a návrhy na projev",
                  regenerate: "Regenerovat",
                  generate: "Generovat",
                  generationFailed: "Sestavení selhalo",
                },
              },
              resultModal: {
                index: {
                  return: "Return",
                  viewDetails: "Zobrazit podrobnosti",
                },
              },
            },
            index: {
              reRatingSuccessful: "Úspěch opětovného skórování",
              reRatingFailed: "Opakované skórování selhalo",
              downloadRecording: "Stáhnout záznam",
              downloadText: "Stáhnout text",
              download: "Stáhnout",
              reRating: "Re-scoring",
              submitARatingAppeal: "Podat stížnost třídění",
              reviewSubmission: "Předložení přezkumu",
              saveChanges: "Uložit změny",
              previousSentence: "Předchozí věta",
              nextSentence: "Další věta",
              score: "Rating",
              scoringSituation: "Skóre",
              dialogueAnalysis: "Analýza dialogu",
            },
          },
          index: {
            ratingAppealInProgress: "Bodování stížností",
            operation: "Operace",
            wrongQuestionSet: "Chybný soubor problémů",
            ratingUpdate: "Aktualizace hodnocení",
            viewDetails: "Zobrazit podrobnosti",
            curriculum: "Curriculum",
            taskName: "Název úlohy",
            taskType: "Typ úkolu",
            score: "Skóre",
            scoringResults: "Výsledky skóre",
            time: "Čas",
            courseClassification: "Klasifikace kurzu",
            whole: "All",
            learningTime: "Čas učení",
            historicalLearningData: "Historické údaje o učení",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Praxe pauza",
              lastProgress: "Poslední pokrok",
              continuePracticing: "Pokračovat v praxi",
              practice: "Cvičení",
              startPracticing: "Začít cvičit",
              historicalData: "Historické údaje",
              courseIntroduction: "Úvod kurzu:",
              exerciseDifficulty: "Obtížnost praxe:",
              practiceCompletionRequirements: "Požadavky na dokončení cvičení:",
              practiceFrequency: "Počet cvičení ≥",
              second: "Times",
              anyScore: "Jakékoli skóre ≥",
              branch: "Body",
              or: "Nebo",
              taskList: "Seznam úkolů",
              practiceTime: "Čas cvičení:",
              to: "Do",
              unlimitedTime: "Neomezený čas",
              completionStatus: "Stav dokončení",
              numberOfExercises: "Počet cvičení",
              maximumScore: "Nejvyšší skóre",
              operation: "Operace",
              enteringLearning: "Enter learning",
              historicalLearningData: "Historické údaje o učení",
              wrongQuestionSet: "Chybný soubor problémů",
            },
          },
          index: {
            practiceTasks: "Praxe úloha",
            examTasks: "Zkušební úkoly",
            task: "Úkoly",
          },
          learn: {
            index: {
              courseName: "Název kurzu",
              courseIntroduction: "Úvod kurzu",
              learningContent: "Učení se obsahu",
              downloadLearningMaterials: "Stažení učebných materiálů",
              startPracticing: "Začít cvičit",
            },
          },
          test: {
            index: {
              taskList: "Seznam úkolů",
              courseIntroduction: "Úvod kurzu:",
              examDifficulty: "Obtížnost zkoušky:",
              requirementsForPassingTheExam:
                "Požadavky na absolvování zkoušky:",
              examScore: "Testovací skóre ≥",
              branch: "Body",
              examTime: "Doba zkoušky:",
              to: "Do",
              unlimitedTime: "Neomezený čas",
              completionStatus: "Stav dokončení",
              completionTimes: "Počet dokončení",
              maximumScore: "Nejvyšší skóre",
              operation: "Operace",
              immediateExam: "Okamžité vyšetření",
              history: "Historie",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Referenční projev" } },
              flow: {
                index: {
                  wrongQuestionItem: "Špatná položka (",
                  score: "Skóre",
                  TotalScore: "/Celkové skóre",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Chyba serveru" } },
                  index: {
                    startRecording: "Spustit nahrávání",
                    endRecording: "Ukončit nahrávání",
                    startPracticing: "Začít cvičit",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Zadejte prosím",
                    sendOut: "Odeslat",
                    startPracticing: "Začít cvičit",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Spustit nahrávání",
                    endRecording: "Ukončit nahrávání",
                    startPracticing: "Začít cvičit",
                  },
                  hook: { index: { failInSend: "Nepodařilo se odeslat" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Skóre tohoto špatného cvičení",
                  tips: "Výzva",
                  areYouSureToEnd: "Potvrdit konec?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Opakujte praxi a nezvládněte špatné otázky.",
                  continue: "Pokračovat",
                  returnToTheSetOfIncorrectQuestions:
                    "Vrátit se na chybnou sadu otázek",
                  end: "Konec",
                  wrongQuestionItem: "Špatná položka",
                  numberOfExercisesThisTime: "Číslo tohoto cvičení",
                  masteringTheSituation: "Pochopit situaci",
                  mastered: "Mastering",
                  notMastered: "Ne zvládnout",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Zopakujte tuto otázku.",
                  nextQuestion: "Další otázka",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Špatné cvičení",
                endExercise: "Konec cvičení",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Špatné cvičení",
                endExercise: "Konec cvičení",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Špatné cvičení",
                endExercise: "Konec cvičení",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Zobrazit projev",
                  section: "Sekce",
                  secondaryDiscourseTechnique: "Sekundární projev",
                },
              },
              tableItem: {
                count: { second: "Times" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Nebyly nalezeny žádné související informace",
                  recallWords: "Recall word",
                  branch: "Body",
                  answerIncorrectly: "Špatná odpověď",
                  second: "Times",
                  wrongAnswerRate: "Chybná míra odpovědi",
                },
                standardScript: {
                  standardScript: "Standardní řeči",
                  myScript: "Moje slova",
                },
                status: { mastered: "Mastering", notMastered: "Ne zvládnout" },
                index: {
                  totalNumberOfQuestionsAnswered: "Celkový počet odpovědí",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Počet krát špatné otázky byly praktizovány",
                },
              },
            },
            index: {
              wrongQuestionList: "Seznam špatných otázek",
              wrongQuestionSet: "Chybný soubor problémů",
              mispracticeExercises: "Špatné cvičení",
              taskName: "Název úlohy",
              staff: "Zaměstnanec",
              masteringTheSituation: "Pochopit situaci",
              whole: "All",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Počet cvičení ≥",
              second: "Times",
              or: "Nebo",
              excellentScoreAtAnyOneTime: "Any one score excellent (≥",
              points: "Body)",
              examScore: "Testovací skóre ≥",
              branch: "Body",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Náhodné průměrné rozdělení",
              assignToDesignatedPersonnel: "Přiřadit určeným osobám",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Úspěch při zvyšování počtu zkoušek",
              failedToIncreaseTheNumberOfExams: "Zvýšit počet selhání testů",
              increaseTheNumberOfExams: "Zvýšit počet zkoušek",
              selectObjects: "Vybrat objekt",
            },
          },
          executionFilter: { index: { participationRate: "Míra účasti" } },
          intelligentReview: {
            index: {
              overallReview: "Celkové hodnocení",
              pleaseEnterTheReviewCriterianotMandatory:
                "Please enter comment criteria (not required)",
              commentaryOnSingleSentenceRhetoric: "Comments on single sentence",
              realTimeReview: "Recenze v reálném čase",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Průměrné skóre testu",
            averageExamDurationmin: "Průměrná doba trvání zkoušky (min)",
            accumulatedPracticeDurationmin:
              "Doba trvání kumulativní praxe (min)",
            accumulatedPracticeTimes: "Kumulativní počet cvičení",
            totalScore: "Celkové skóre",
            average: "Průměr",
            total: "Celkem",
            keyHitCount: "Počet klíčových bodů",
            practiceAverageScore: "Praxe průměrné skóre",
            averagePracticeDurationmin: "Průměrná délka cvičení (min)",
            topOfTheHighestScore: "Nejvyšší skóre top3",
            branch: "Body",
            errorProneItemTop: "Chyba-náchylné položky top5",
            hitRate: "Hitová rychlost",
            hitRateOfKeyPointsInScript:
              "Rychlost zásahů v klíčových bodech řeči",
            averageScriptScore: "Průměrné skóre řeči",
            sortByProcess: "Seřadit podle procesu",
            sortByScore: "Seřadit podle skóre",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Průměrné skóre této stránky/celkové skóre této stránky",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Průměrné skóre této položky/celkové skóre této položky",
            details: "Podrobnosti",
            viewRules: "Zobrazit pravidla",
            standardScript: "Standardní řeči",
          },
          scoreDetailList: {
            section: "Sekce",
            second: "Times",
            practice: "Cvičení",
            examination: "Zkouška",
            notMeetingStandards: "Ne standardní",
            meetingStandards: "Standard",
            excellent: "Výborná",
            frequency: "Počet časů",
            fraction: "Frakce",
            result: "Výsledky",
            operation: "Operace",
            viewDetails: "Zobrazit podrobnosti",
          },
          index: {
            common: "Celkem",
            people: "Lidé",
            staff: "Zaměstnanec",
            notMeetingStandards: "Ne standardní",
            meetingStandards: "Standard",
            excellent: "Výborná",
            notStarted: "Nezahájeno",
            haveInHand: "Probíhá",
            completed: "Dokončeno",
            passed: "Pass",
            hangInTheAir: "Nedokončený",
            failed: "Not passed",
            unlimitedTime: "Neomezený čas",
            to: "Do",
            maximumScoringCriteria: "Nejvyšší skóre",
            completionStatus: "Stav dokončení",
            employeeName: "Jméno zaměstnance",
            frequency: "Počet časů",
            practice: "Cvičení",
            examination: "Zkouška",
            average: "Průměr",
            speechCraftAvgScore: "Průměrné skóre řeči",
            emotionAvgScore: "Průměrné emocionální skóre",
            speedAvgScore: "Expresní průměrné skóre",
            flowAvgScore: "Průměrné skóre procesu",
            practiceCostTime: "Kumulativní délka trvání",
            participateCommitRate: "Míra souladu",
            participateExcellentRate: "Vynikající sazba",
            duration: "Doba trvání",
            score: "Skóre",
            scoringResults: "Výsledky skóre",
            flowScore: "Skóre procesu",
            craftScore: "Skóre řeči",
            emotionScore: "Emocionální skóre",
            speedScore: "Výraz skóre",
            contentIntegrity: "Integrita obsahu",
            pointHitRate: "Rychlost zásahu klíčových bodů",
            emotionNegativeRate: "Negativní podíl emocí",
            paraPhaSiaRate: "Podíl abnormální rychlosti řeči",
            toneOvermuchRate: "Nadměrný podíl modálních slov",
            maximumScore: "Nejvyšší skóre",
            highestScoreResult: "Nejvyšší výsledky",
            state: "Stav",
            averageDurationmin: "Průměrná délka trvání (min)",
            lately: "Nedávno",
            time: "Čas",
            operation: "Operace",
            viewDetails: "Zobrazit podrobnosti",
            wrongQuestionSet: "Chybný soubor problémů",
            taskName: "Název úlohy",
            completeRequirements: "Kompletní požadavky",
            publishTo: "Publikační objekty",
            taskTime: "Čas úlohy",
            fractionalComposition: "Složení frakce",
            totalScore: "Celkové skóre",
            branch: "Body",
            Script: "(Talker",
            scoreEmotion: "Emoce",
            fractionExpression: "Dílčí výraz",
            points: "Body)",
            implementationRate: "Míra účasti",
            numberOfExecutors: "Počet účastníků",
            numberOfPeopleNotExecuted: "Počet neúčastníků",
            completionRate: "Míra dokončení",
            numberOfCompletedPersonnel: "Počet dokončených",
            numberOfUnfinishedPersonnel: "Počet nedokončených",
            complianceRate: "Míra souladu",
            numberOfQualifiedPersonnel: "Počet výsledků",
            numberOfNonCompliantIndividuals:
              "Počet osob, které nesplňují normu",
            excellentRate: "Vynikající sazba",
            participateCompleteRate: "Míra dokončení účasti",
            numberOfOutstandingIndividuals: "Nevyřízené čísla",
            numberOfNonOutstandingIndividuals: "Nevynikající číslo",
            export: "Export",
            taskData: "Data úloh",
            resultAnalysis: "Analýza výsledků",
            scoreDetails: "Podrobnosti o skóre-",
            dataOverview: "Přehled dat",
            taskDetail: "Podrobnosti úlohy",
            scriptAnalysis: "Analýza řeči",
            flowAnalysis: "Analýza procesu",
            expressionAnalysis: "Analýza výrazů",
            emotionAnalysis: "Analýza sentimentu",
            byTime: "Zobrazení časové dimenze",
            byNumber: "Zobrazení rozměru stupně",
            personData: "Statistiky podle personálu",
            numberData: "Detail podle záznamu",
            count: "Počet krát",
            person: "Počet lidí",
            reachTimes: "Čtení",
            outOfReachTimes: "Nedosáhnuté časy",
            seeOutOfReach: "Klikněte pro zobrazení nedosáhl",
            accumulative: "Kumulativní",
            script: "Slova",
            times: "Times",
            sentence: "Věta",
            expressionAverage: "Průměrné skóre výrazu",
            modalAnalysis: "Analýza modální částice",
            total: "Celkem",
            muchModalAnalysis: "Příliš mnoho modálních slov",
            normal: "Normální",
            speedAnalysis: "Analýza rychlosti řeči",
            speedDistribution: "Rozdělení rychlosti řeči",
            speakingSpeed: "Rychlost řeči",
            normalSpeak: "Normální rychlost řeči",
            tooFastSpeak: "Speedy speedy",
            tooSlowlySpeak: "Slow speed",
            violationWord: "Porušení slov",
            violationWordAnalysis: "Analýza slov porušování",
            noData: "Ne",
            emotionalScore: "Emocionální skóre",
            emotionalDistribution: "Distribuce emocí",
            negativeEmotion: "Negativní emoce",
            normalEmotion: "Normální emoce",
            getDetail: "Zobrazit podrobnosti",
            barPattern: "Pohled na bar",
            timeDimension: "Zobrazení zeměpisné šířky času",
            timesDimension: "Zobrazení rozměru stupně",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Průměr",
                  averageScoreOfScript: "Průměrné skóre řeči",
                  averageEmotionalScore: "Průměrné emocionální skóre",
                  expressionAverageScore: "Expresní průměrné skóre",
                  processAverageScore: "Průměrné skóre procesu",
                  accumulatedDuration: "Kumulativní délka trvání",
                  accumulatedTimes: "Kumulativní časy",
                  participationComplianceRate: "Míra účasti",
                  participationRateForAchievingExcellence: "Míra účasti",
                  branch: "Body",
                  numberOfPeople: "Počet lidí",
                  averageScoreOfExpression: "Průměrné skóre výrazu",
                  people: "Lidé",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Negativní emoce" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Průměrné skóre řeči",
                  branch: "Body",
                },
                useSummaryDetail: {
                  keyHitRate: "Rychlost zásahu klíčových bodů",
                },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Chytré recenze",
            pleaseImproveTheConfiguration: "Prosím, zdokonalte konfiguraci",
            examResults: "Výsledky zkoušky",
            showTheResultsAfterEachExam: "Zobrazit výsledky po každé zkoušce.",
            showTheResultsAfterTheLastExam:
              "Zobrazit výsledky po poslední zkoušce.",
            doNotDisplayResults: "Nezobrazovat výsledky",
            resultDisplay: "Zobrazit výsledky",
            score: "Skóre",
            scoringResults: "Výsledky skóre",
            allowViewingScoreDetails: "Povolit zobrazit podrobnosti o skóre",
            viewCanvasConfiguration: "Zobrazit konfiguraci plátna",
            synchronizationFailedProcessNodeHasChanged:
              "Synchronizace selhala, procesní uzel vygeneroval změny",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Synchronizace je úspěšná, klepněte na tlačítko publikovat pro aktualizaci kurzu",
            retrievedTheLatestCanvasConfiguration:
              "Poslední konfigurace plátna tažená",
            synchronizeCanvasConfiguration: "Synchronizovat konfiguraci plátna",
            scoringMethod: "Metoda hodnocení",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Body, flow {0} points, speech skill {1} points, emotion {2} points, speed {3} points)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Úroveň excelence musí být vyšší než úroveň souladu.",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Skóre musí být vyšší než 0% a nižší než nebo rovná se 100",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Kritéria pro hodnocení musí být větší než 0 bodů a menší nebo rovná {0} bodů.",
            doRatingsRequireManualReview:
              "Zda hodnocení potřebuje manuální přezkum",
            allocationStrategy: "Politika přidělování",
            pleaseSelectTheAssignedPersonnel: "Vyberte přiřazenou osobu.",
            pleaseSelectADesignatedPerson: "Vyberte určenou osobu.",
            timeLimitForSingleQuestionAnswering:
              "Lhůta pro odpověď na jednu otázku",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Ve výchozím nastavení zveřejňovat nově přidaným zaměstnancům",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Opakované skóre naznačuje, že skóre bude vyrovnáno podle pravidel pro hodnocení nejnovějšího plátna. Aktuální úloha ručně přezkoumala údaje o skóre. Chcete přepsat skóre?",
            notCovered: "Not covered",
            cover: "Obal",
            participationSituation: "Účast",
            numberOfParticipants: "Počet účastníků",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Součet počet objektů cvičení vybraných pro tuto úlohu",
            sumOfTheNumberOfParticipantsInTheTask:
              "Součet počet lidí, kteří se zúčastnili úkolu",
            numberOfCompletedPersonnel: "Počet dokončených",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Souhrn počtu lidí, kteří splňují požadavky na dokončení úkolu",
            numberOfQualifiedPersonnel: "Počet výsledků",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Souhrn počtu lidí, kteří splňují skóre úkolů",
            excellentPersonnel: "Nevyřízené čísla",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Souhrn počtu lidí, kteří splňují úkol s vynikajícím skóre",
            participationRate: "Míra účasti",
            numberOfParticipantsNumberOfParticipants:
              "Počet účastníků/počet účastníků",
            numberOfCompletedNumberOfParticipants:
              "Počet dokončených/počet by se měl účastnit",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Počet výsledků/počet účastníků",
            excellentNumbernumberOfParticipants:
              "Nevyřízený počet účastníků/počet účastníků",
            participationCompletionRate: "Míra dokončení účasti",
            numberOfCompletedParticipated: "Počet dokončených/účastníků",
            participationComplianceRate: "Míra účasti",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Počet výsledků/účasti",
            participationRateForAchievingExcellence: "Míra účasti",
            accumulatedDuration: "Kumulativní délka trvání",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Účastnit se trvání úkolu a součtu",
            averageDuration: "Průměrná délka trvání",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Celková doba trvání účastníků/celkový počet účastníků",
            accumulatedTimes: "Kumulativní časy",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Součet počet účastníků úlohy",
            averageScore: "Průměr",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Průměrné skóre personálu zapojeného do úkolu",
            maximumScore: "Nejvyšší skóre",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Nejvyšší skóre personálu zapojeného do úkolu",
            averageScoreOfScript: "Průměrné skóre řeči",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Součet skóre studentské řeči/celkové časy účasti",
            totalScoreOfScript: "Celkový skóre řeči",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Celkový skóre řeči nastavený v rámci této úlohy",
            processAverageScore: "Průměrné skóre procesu",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Součet výsledků studentského procesu/celkový počet závazků",
            totalProcessScore: "Celkový výsledek procesu",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Celkový skóre procesu nastaveného v rámci této úlohy",
            averageEmotionalScore: "Průměrné emocionální skóre",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Součet skóre emoce stážisty/celkový počet aktivit",
            totalEmotionalScore: "Celkové emocionální skóre",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Celková emocionální skóre nastavená v rámci této úlohy",
            averageScoreOfExpression: "Průměrné skóre výrazu",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Součet skóre výrazu učitelů/celkový počet závazků",
            totalScoreOfExpression: "Výraz celkový skóre",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Celkový skóre výrazu v rámci této úlohy",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Začněte provádět re-scoring, můžete skočit do úlohového centra pro zobrazení pokroku.",
            reRating: "Re-scoring",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Re-scoring naznačuje, že skóre bude vyrovnáno podle pravidel skóre nejnovějšího plátna a aktuální skóre bude přepsáno.",
            releaseTime: "Čas uvolnění",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Přezkoumáno",
              pendingReview: "K přezkoumání",
              enterKeywordSearch: "Zadejte klíčová slova k vyhledávání",
            },
            pageRightTable: {
              index: {
                employeeName: "Jméno zaměstnance",
                systemRating: "Systémové skóre",
                scoringResults: "Výsledky skóre",
                duration: "Doba trvání",
                time: "Čas",
                state: "Stav",
                whetherToModifyTheRating: "Správné skóre",
                finalScore: "Konečné skóre",
                reviewedBy: "Recenzent",
                operation: "Operace",
                viewScoreDetails: "Zobrazit podrobnosti skóre",
                viewStatus: "Zobrazit stav",
                whole: "All",
                correctionStatus: "Stav korekce",
                completeReview: "Kompletní přezkum",
                tips: "Výzva",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Konečné výsledky stážistů podléhají aktuálním výsledkům konečného přezkumu. Potvrďte prosím",
              },
            },
          },
          index: { manualReview: "Manuální přezkum" },
        },
        review: {
          index: {
            employeeName: "Jméno zaměstnance",
            score: "Skóre",
            scoringResults: "Výsledky skóre",
            duration: "Doba trvání",
            taskName: "Název úlohy",
            numberOfAppealScripts: "Počet stížností",
            appealTime: "Čas odvolání",
            processStatus: "Stav procesu",
            scoreAfterReview: "Skóre po přezkoumání",
            reviewedBy: "Recenzent",
            operation: "Operace",
            viewScoreStatus: "Zobrazit skóre",
            whole: "All",
            manualReview: "Manuální přezkum",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Údaje o úloze cvičení",
            implementationRate: "Míra realizace",
            numberOfExecutors: "Počet spuštění",
            numberOfPeopleNotExecuted: "Počet neprovedených",
            completionRate: "Míra dokončení",
            numberOfCompletedPersonnel: "Počet dokončených",
            numberOfUnfinishedPersonnel: "Počet nedokončených",
            complianceRate: "Míra souladu",
            numberOfQualifiedPersonnel: "Počet výsledků",
            numberOfNonCompliantIndividuals:
              "Počet osob, které nesplňují normu",
            excellentRate: "Vynikající sazba",
            numberOfOutstandingIndividuals: "Nevyřízené čísla",
            numberOfNonOutstandingIndividuals: "Nevynikající číslo",
            examTaskData: "Údaje o úkolu zkoušky",
            published: "Published",
            courses: "Kurz",
            courseData: "Data kurzu",
            practiceTasks: "Praxe úloha",
            examTasks: "Zkušební úkoly",
          },
          practiceSummary: {
            practiceSummary: "Shrnutí praxe",
            numberOfPracticeTasks: "Počet praxe úkolů",
            numberOfExercises: "Počet cvičení",
            accumulatedPracticeDuration: "Doba trvání kumulativní praxe",
            complianceRate: "Míra souladu",
            excellentRate: "Vynikající sazba",
            ranking: "Ranking",
          },
          rankingList: {
            numberOfExercises: "Počet cvičení",
            exerciseDuration: "Doba trvání cvičení",
            complianceRate: "Míra souladu",
            excellentRate: "Vynikající sazba",
            diligenceChart: "Seznam pečlivosti",
            excellentList: "Výborný seznam",
            ranking: "Hodnocení",
            fullName: "Jméno",
            i: "Já...",
          },
          taskCard: {
            end: "Termín:",
            noDeadline: "Žádná lhůta",
            second: "Times",
            branch: "Body",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Žádné úkoly",
            completed: "Dokončeno",
          },
          filter: { employeeDepartment: "Oddělení zaměstnanců" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Export is successful, please go to the task center to view",
              export: "Export",
            },
          },
          filterConfig: {
            dropdown: { custom: "Vlastní" },
            modal: {
              updateSuccessful: "Úspěšná aktualizace",
              successfullySaved: "Úspěšně uložit",
              setAsACommonReport: "Nastavit na společné sestavy",
              updateCurrentReport: "Aktualizovat aktuální sestavu",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Průměr",
                  averageScoreOfScript: "Průměrné skóre řeči",
                  averageEmotionalScore: "Průměrné emocionální skóre",
                  expressionAverageScore: "Expresní průměrné skóre",
                  processAverageScore: "Průměrné skóre procesu",
                  branch: "Body",
                },
              },
              comprehensiveEvaluation: { const: { data: "Data" } },
              doneStatus: {
                index: {
                  practice: "Cvičení",
                  examination: "Zkouška",
                  executed: "Spustit",
                  unexecuted: "Neprovedeno",
                  accumulatedExamDuration: "Doba kumulativního testu",
                  examFrequency: "Počet zkoušek",
                },
              },
              filter: {
                index: {
                  practice: "Cvičení",
                  examination: "Zkouška",
                  pleaseCompleteTheRequiredFields:
                    "Prosím, vyplňte požadované položky",
                  staff: "Zaměstnanec",
                  taskType: "Typ úkolu",
                  pleaseSelect: "Vyberte prosím",
                  taskName: "Název úlohy",
                },
              },
            },
            index: {
              completionStatus: "Stav dokončení",
              comprehensiveEvaluation: "Komplexní hodnocení",
              abilityGrowthTrend: "Trend růstu kapacity",
              errorProneAnalysis: "Analýza bodu náchylného k chybě",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Aktivní číslo",
            accumulatedPracticeDuration: "Doba trvání kumulativní praxe",
            averagePracticeDuration: "Průměrná doba cvičení",
            people: "Lidé",
            numberOfPracticeTasks: "Počet praxe úkolů",
            numberOfExercises: "Počet cvičení",
            exerciseDuration: "Doba trvání cvičení",
            second: "Times",
            avgScore: "Průměr",
            speechCraftAvgScore: "Průměrné skóre řeči",
            emotionAvgScore: "Průměrné emocionální skóre",
            speedAvgScore: "Expresní průměrné skóre",
            flowAvgScore: "Průměrné skóre procesu",
            practiceCostTime: "Kumulativní délka trvání",
            totalPracticeTimes: "Kumulativní časy",
            participateCommitRate: "Míra účasti",
            participateExcellentRate: "Míra účasti",
          },
        },
        index: {
          employeeProfile: "Zaměstnanecký portrét",
          overview: "Přehled",
          personalSignage: "Osobní kanban",
          ongoingExercises: "Cvičení probíhá",
          ongoingExams: "Zkouška probíhá",
          practiceTaskProgress: "Pokrok úlohy cvičení",
          examTaskProgress: "Postup testování úkolu",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Prosím, vyberte koncepční slovo",
              sessionDetectionSettings: "Nastavení detekce relace",
              successfullySaved: "Úspěšně uložit",
              abnormalSpeechSpeed: "Abnormální rychlost řeči",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Je podporován uživatelem definovaný standard detekce rychlosti řeči. Výsledkem bude normální rychlost řeči, příliš rychlá rychlost řeči a příliš pomalá rychlost řeči. Když je rychlost řeči abnormální (příliš rychlá/příliš pomalá), bude skóre snížena podle pravidel pro hodnocení.",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Rychlost příliš rychlá: více než jedna minuta",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Slovo, příliš pomalé: méně než za minutu",
              charactersIgnoringLessThan: "Slova, ignorovat méně",
              aSentenceOfWords: "Věta slov",
              excessiveModalParticles: "Příliš mnoho modálních slov",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Podporovat vlastní tónové slova a nastavit pravidla příliš mnoho tónových slov. Když je příliš mnoho modálních slov, skóre se sníží podle pravidel pro hodnocení.",
              theNumberOfRepetitionsIs: "Počet opakování je",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "Podruhé je stanoveno, že existuje příliš mnoho modálních slov.",
              definitionOfSensitiveViolations: "Definice citlivého porušování",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Můžete upravit lexikon nelegálních slov, aby zjistili, zda se v větě objeví nezákonná slova. Pokud existuje nezákonné slovo, skóre se sníží podle pravidel skóre.",
              violationWords: "Slova porušování:",
              emotionalDetection: "Detekce emocí",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Podle dialogu systémového emočního modelu bude zkouška emocí posuzována jako normální emoce a negativní emoce (včetně negativní, hněvivé a stěžující se). Pokud existuje negativní emoce, skóre se sníží podle pravidel pro skóre.",
              preserve: "Uložit",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Rozměr hodnocení",
              ratingPoints: "Body",
              explain: "Popis",
              type: "Typ",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Náhodné údaje" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· standard speech cumulative',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "A word, the number of words hit by the student",
                  aWordspan: "Word </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Times </span><br /><span style = "margin-left: 8px">· slow speed',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· too many modal words',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Výsledek je" },
                  item: {
                    violationOnceReduced: "1 porušení mínus",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Abnormální rychlost (včetně příliš rychlé a příliš pomalé rychlosti)",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Příliš mnoho modálních slov 1 mínus",
                    oneNegativeDecreaseInEmotions: "Negativní emoce mínus 1",
                    branch: "Body",
                  },
                  wrap: { name: "Jméno", preserve: "Uložit" },
                },
              },
              index: {
                complete: "Kompletní obsah",
                keyHit: "Hit klíčových bodů",
                accurateFollowupReading: "Přesné čtení",
                languageNorm: "Specifikace jazyka",
                speechSpeed: "Rychlost řeči",
                modalParticles: "Modální slovo",
                emotionallyPositive: "Emocionálně pozitivní",
                scoringModel: "Bodovací model",
                successfullyModified: "Úprava byla úspěšná",
                newSuccessfullyCreated: "Nový úspěch",
              },
            },
            index: {
              scoringModel: "Bodovací model",
              newModel: "Nový model",
              model: "Model",
              type: "Typ",
              operation: "Operace",
              preview: "Náhled",
              edit: "Upravit",
            },
          },
          index: {
            scoringModel: "Bodovací model",
            ratingDimension: "Rozměr hodnocení",
            sessionDetectionSettings: "Nastavení detekce relace",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Vyberte prosím",
                hit: "Hit",
                misses: "Zmizela",
              },
            },
          },
          index: {
            duration: "Doba trvání",
            processScore: "Skóre procesu",
            branch: "Body",
            contentCompleteness: "Integrita obsahu",
            scriptScore: "Skóre řeči",
            keyHitRate: "Rychlost zásahu klíčových bodů",
            emotionalScore: "Emocionální skóre",
            negativeProportionOfEmotions: "Negativní podíl emocí",
            expressionScore: "Výraz skóre",
            theProportionOfAbnormalSpeechSpeed:
              "Podíl abnormální rychlosti řeči",
            excessiveProportionOfModalParticles:
              "Nadměrný podíl modálních slov",
            learningRecordData: "Data záznamu učení",
            staff: "Zaměstnanec",
            curriculum: "Curriculum",
            mainPoints: "Klíčové body",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Tabulka",
                        lineChart: "Řádkový graf",
                        barChart: "Histogram",
                        pieChart: "Pie chart",
                      },
                      index: { switchingTypes: "Typ spínače" },
                    },
                  },
                  index: {
                    saveSuccessful: "Úspěšně uložit",
                    editChart: "Upravit graf",
                    chartName: "Název grafu",
                    deleteSuccessful: "Úspěšně smazán",
                    refresh: "Aktualizace",
                    export: "Export",
                    areYouSureToDeleteIt: "Opravdu chcete odstranit?",
                    delete: "Smazat",
                    viewFilteringCriteria: "Zobrazit kritéria filtru",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Export is successful, please go to the task center to view",
              },
            },
            header: {
              index: {
                saveSuccessful: "Úspěšně uložit",
                deleteSuccessful: "Úspěšně smazán",
                editReport: "Upravit sestavu",
                reportName: "Název sestavy",
                refresh: "Aktualizace",
                areYouSureToDeleteIt: "Opravdu chcete odstranit?",
                delete: "Smazat",
                exportToExcel: "Exportovat aplikaci excel",
                exportSnapshot: "Exportovat snímek",
                export: "Export",
                authority: "Oprávnění",
                permissionSettings: "Nastavení oprávnění",
                reportVisiblePermissions: "Hlásit viditelné oprávnění",
                visibleToEveryone: "Viditelné pro všechny",
                onlyVisibleToOneself: "Viditelné pouze pro sebe.",
                designatedPersonnelVisible: "Viditelná určená osoba",
                pleaseSelect: "Vyberte prosím",
                reportDataPermissions: "Oprávnění k vykazování dat",
                restrictedByUserPermissions:
                  "Omezené uživatelskými oprávněními",
                visiblePeopleAreTheSame: "Viditelní lidé jsou stejní.",
              },
            },
            titleFooter: {
              index: { addReport: "Nová zpráva", reportName: "Název sestavy" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Export is successful, please go to the task center to view",
            dataQueryInProgress: "Dotaz na data v...",
            customKanban: "Vlastní kanban",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Tabulka",
                lineChart: "Řádkový graf",
                barChart: "Histogram",
                pieChart: "Pie chart",
              },
              index: {
                successfullyAdded: "Úspěšně přidáno",
                viewSQL: "Zobrazit sql",
                export: "Export",
                addToReport: "Přidat do sestavy",
              },
            },
            refer: {
              hooks: {
                data: "Data",
                interrogativeSentence: "Otázky",
                historicalQuery: "Historický dotaz",
              },
              index: { copySuccessful: "Kopírovat úspěch", copy: "Kopírovat" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Ne standardní",
                        meetingStandards: "Standard",
                        excellent: "Výborná",
                        notStartedYet: "Nezahájeno",
                        inProgress: "Probíhá",
                        completed: "Dokončeno",
                        incomplete: "Nedokončený",
                      },
                    },
                  },
                  const: {
                    taskName: "Název úlohy",
                    department: "Oddělení",
                    studentName: "Jméno studentů",
                    scoringResults: "Výsledky skóre",
                    taskCompletionStatus: "Stav dokončení úlohy",
                  },
                  index: { pleaseSelectADimension: "Prosím, vyberte rozměr" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Enter @ to open the bullet box for dimension selection",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Prosím, zadejte příkaz dotazu k dotazu přímo",
                query: "Dotaz",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Export is successful, please go to the task center to view",
            dataQueryInProgress: "Dotaz na data v...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Hledání klíčových slov",
                previous: "Předchozí",
                next: "Další",
                theObjectThatTriggersTheScrollingEventIs:
                  "Objekt, který spustí událost scroll, je",
                userScrolling: "Uživatelský svitek",
              },
            },
            summary: {
              index: {
                reminder: "Připomínka:",
                edit: "Upravit",
                name: "Jméno",
                hintLanguage: "Výzva",
              },
            },
            viewpoint: { index: { viewpointExtraction: "Výtah z pohledu" } },
            outline: {
              index: {
                numberOfKeywords: "Počet klíčových slov",
                speaker: "Mluvčí",
                speechDuration: "Doba trvání řeči",
                proportionOfSpeaking: "Mluvčí poměr",
                maximumSpeechDuration: "Maximální délka řeči",
                numberOfQuestions: "Počet otázek",
                averageSpeechSpeed: "Průměrná rychlost",
                conversationalActions: "Akce sezení",
                contentSummary: "Souhrn obsahu",
                generateSummary: "Generovat souhrn",
                keywordCloud: "Klíčová slova cloud",
              },
            },
          },
          index: {
            outline: "Shrnutí",
            viewpoint: "Stanovisko",
            contentSummary: "Souhrn obsahu",
            viewpointExtraction: "Výtah z pohledu",
            summaryOfKeyPoints: "Shrnutí klíčových bodů",
            videoAnalysis: "Analýza videa",
          },
        },
        list: {
          index: {
            delete: "Smazat",
            uploadSuccessful: "Nahrávat úspěšně",
            videoList: "Seznam videí",
            uploadVideo: "Nahrát video",
            videoName: "Název videa",
            uploadTime: "Čas načítání",
            endTime: "Konec času",
            analyzeProgress: "Analyzovat pokrok",
            haveInHand: "Probíhá",
            completed: "Dokončeno",
            fail: "Selhal",
            toBegin: "Začít",
            operation: "Operace",
            see: "Zobrazit",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Přidat pracovní čas" },
        },
      },
      index: {
        businessWorkingHours: "Pracovní doba",
        newlyBuild: "Nový",
        name: "Jméno",
        describe: "Popis",
        operation: "Operace",
        edit: "Upravit",
        delete: "Smazat",
        editBusinessWorkingHours: "Upravit pracovní hodiny",
        newBusinessWorkingHours: "Nová pracovní doba",
        workingHours: "Pracovní čas",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Inteligentní extrakce",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Jste si jistý, že chcete zrušit? Zrušení nebude možné",
        },
      },
    },
    accountManagement: {
      securitySetting: "Nastavení zabezpečení",
      accountManagement: "Správa účtů",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Do",
            switchToIPAddressMode: "Přepnout do režimu ip adresy",
            switchToIPSegmentMode: "Přepnout do režimu segmentu ip",
          },
        },
      },
      index: {
        saveSuccessful: "Úspěšně uložit",
        pleaseImproveTheConfiguration: "Prosím, zdokonalte konfiguraci",
        securitySetting: "Nastavení zabezpečení",
        employeeLoginIPRestrictions: "Omezení přihlašování ip zaměstnance",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Omezit rozsah ip, ke kterému se zaměstnanci mohou přihlásit. Přihlášení není povoleno mimo rozsah. Platí pouze pro webové stránky, mobilní aplikace nejsou omezeny a správci nejsou omezeni.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Při přidávání nebo změně zadejte externí síťový ip segment (stejný c segment) nebo pevnou externí síťovou ip adresu.",
        allowLoginIPRange: "Rozsah ip povolen pro přihlášení",
        preserve: "Uložit",
      },
    },
    coachAdminEntry: { speechRecognition: "Rozpoznávání řeči" },
    billingCenter: {
      template: {
        details: "Podrobnosti",
        languageType: "Typ jazyka",
        aSREngine: "Motor asr",
        voiceQualityQuota: "Kvóta kvality hlasu",
        realtimeData: "Data v reálném čase",
        calculatedByDay: "Vypočítáno podle dne",
      },
      component: {
        aSRName: "Název asr",
        language: "Jazyk",
        remainingQuota: "Zbývající kvóta",
        hours: "Hodiny",
        usedQuota: "Použitá kvóta",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Operace úspěšně uložena!",
        deletedSuccessfully: "Úspěšně smazán!",
        pleaseSelectLanguage: "Vyberte jazyk",
        pleaseConfigureRules: "Prosím, nastavit pravidla",
        updateSuccessful: "Aktualizovat úspěšně!",
        operationSuccessful: "Operace úspěšná!",
      },
      template: {
        aSRLanguage: "Asr jazyk",
        pleaseSelect: "Vyberte prosím",
        model: "Model",
        pleaseSelectLanguage: "Vyberte jazyk",
        delete: "Smazat",
        newASRModel: "Nový model asr",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Analýza hospodářské soutěže" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Filtr pro přezkum elektronického obchodu",
        allComments: "All comments",
        ecommerceReviewManagement: "E-commerce review management",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Filtr pro recenzi sociálních médií",
        allComments: "All comments",
        socialMediaReviewManagement: "Správa hodnocení sociálních médií",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Analýza hospodářské soutěže",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Jméno",
        nickname: "Přezdívka",
        name: "Jméno",
        email: "Číslo účtu (e-mail)",
        mobile: "Telefon",
        agentId: "Práce č.",
        position: "Post",
        role: "Role",
        actions: "Operace",
        createUser: "Nový zaměstnanec",
        editUser: "Upravit zaměstnance",
        account: "Číslo účtu",
        originPassword: "Počáteční heslo",
        newPassword: "Nové heslo",
        resumePassword: "Potvrdit heslo",
        userGroup: "Skupina zaměstnanců",
        authorizationGroup: "Seskupení povolení",
        resetPassword: "Obnovit heslo",
      },
      userGroups: {
        id: "ID",
        name: "Jméno",
        userCount: "Počet zaměstnanců",
        actions: "Operace",
        createUserGroup: "Přidat skupinu zaměstnanců",
        editUserGroup: "Upravit skupinu zaměstnanců",
        member: "Členové",
      },
      role: {
        id: "ID",
        name: "Jméno",
        actions: "Operace",
        all: "All",
        taskName: "Úkoly",
        readTask: "Zobrazení úkolu",
        manageTemplate: "Správa šablony",
        manualInspection: "Manuální přezkum",
        readResultAll: "Zobrazení výsledků",
        readResultPersonal: "Zobrazení výsledků (individuální)",
        readProcess: "Zobrazit proces kontroly kvality",
        readReport: "Statistiky a zprávy",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Datum",
        taskName: "Jméno",
        size: "Velikost",
        status: "Stav",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Jméno",
            remark: "Popis",
            actions: "Operace",
          },
          checkPoint: {
            name: "Jméno",
            remark: "Popis",
            type: "Typ",
            gradeType: "Metoda hodnocení",
            conditionLogic: "Pravidla",
            predeterminedScore: "Skóre",
            weight: "Váha",
            status: "Stav",
            actions: "Operace",
            switchOn: "Povolit",
            switchOff: "Deaktivovat",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Čas",
            customer: "Zákazník",
            customerPhone: "Telefon zákazníka",
            callType: "Typ volání",
            source: "Zdroj",
            voiceRecording: "Záznam hovoru",
            graded: "Rating",
            openingGreeting: "Úvodní pozdravy",
          },
          checkPointList: {
            name: "Bod kontroly kvality",
            type: "Typ",
            deductScore: "Frakce",
            isHit: "Hit case",
            status: "Přezkum",
            finalDeductScore: "Potvrdit odpočet bodů",
            hit: "Hit",
            notHit: "Zmizela",
          },
        },
      },
    },
    custom: { keywords: "Klíčová slova dialogu" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Nastavení systému" },
      systemIntegration: {
        text: "Systémová integrace",
        subTitle: {
          udesk: "Systém služby zákazníkům udesk",
          udeskWhite: "Systém služeb zákazníkům",
          wechat: "Enterprise wechat",
          taobao: "Taobao qianniu",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Kontrola kvality řízení pole" },
      customerField: { text: "Pole zákazníka" },
      speechRecognition: { text: "Rozpoznávání řeči" },
      user: { text: "Řízení zaměstnanců" },
      userGroups: { text: "Řízení skupiny zaměstnanců" },
      role: { text: "Řízení úloh" },
      dataMigration: { text: "Migrace dat" },
      taskCenter: { text: "Mission center" },
      recordUpload: { text: "Místní nahrávání" },
      anomalousData: { text: "Abnormální data" },
      systemLog: { text: "Deník zpráv" },
      msgCenter: { text: "Centrum zpráv" },
      wechatDocking: { text: "Enterprise micro docking" },
    },
    tasks: {
      homePage: { text: "Úvodní stránka" },
      workbench: { text: "Pracovní stůl" },
      semanticIntelligence: { text: "Sémantická inteligence" },
      intelligentAnalysis: { text: "Inteligentní analýza" },
      intelligentLabel: { text: "Smart label" },
      admin: { text: "Management" },
      callManage: { text: "Řízení volání" },
      dialogueManage: { text: "Řízení dialogu" },
      ticketManage: { text: "Řízení pracovního pořadí" },
      dataAnalysis: { text: "Analýza dat" },
      customerService: { text: "Centrum služeb zákazníkům" },
      clientCenter: { text: "Centrum zákazníků" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Výcvikové centrum" },
      templates: { text: "Správa šablony" },
      wechatQaTasks: { text: "Enterprise wechat workbench" },
      wechatQaSetting: { text: "Konfigurace analýzy aplikace wechat" },
      wechatQaChartRecord: { text: "Enterprise wechat session" },
      wechatStaffCenter: { text: "Enterprise micro employee center" },
      callAnalysis: { text: "Analýza konverzace" },
      caseBaseFolder: { text: "Knihovna případů" },
      coachTask: { text: "Mentorské úkoly" },
      teamAnalysis: { text: "Analýza týmu" },
      customer: { text: "Centrum zákazníků" },
      analysisEnable: { text: "Zmocnění analýzy" },
      analysisHelp: { text: "Analýza nápovědy" },
      analysisStudy: { text: "Analýza učení" },
      analysisReview: { text: "Analýza složeného disku" },
      analysisTags: { text: "Analýza inteligentních štítků" },
      analysisSentiment: { text: "Analýza veřejného mínění" },
      analysisBusiness: { text: "Obchodní analýza" },
      groupTest: { text: "Skupinový test" },
    },
    navBar: {
      billingCenter: "Centrum pomoci",
      uploadDownload: "Upload a download center",
      setting: "Nastavení systému",
    },
  },
  fix: {
    selectType: "Zvolte typ:",
    all: "All",
    call: "Volání",
    text: "Dialog",
    wechat: "Enterprise wechat session",
    businessTrendAnalysis: "Úroveň 1 klasifikace analýza obchodních trendů",
    noEchart: "Žádné údaje pro graf",
    checkPointisRequired: "Je požadována konfigurace pravidla",
    voiceDownLoad: "Stáhnout záznam",
    exportTxt: "Exportovat text txt",
    exportWord: "Exportovat text slova",
    sureReRole: "Vrátí potvrzení roli mluvčího?",
    sureRecover: "Potvrdit rekorekci?",
    sureReAsr: "Potvrdit opětovnou identifikaci?",
    reRole: "Reverzní mluvčí",
    recover: "Oprava opakovaných chyb",
    reAsr: "Opětovná identifikace",
    recordingOperation: "Operace nahrávání",
    onlySave30Day: "(Jen 30 dní)",
    appealError:
      "Před tím, než jej předloží, obraťte se na kontrolní místo kvality.",
    exportToTaskCenter:
      "Úspěšně exportováno do centra pro nahrávání a stahování",
    caseBaseLimit: "Knihovna případů nemůže přesáhnout 50",
    unnamed: "Nejmenovaný",
    wordSpeed: "Slovo/min",
    hit: "Hit",
    checkPoint: "Bod kontroly kvality",
    realTimeAdditionTip1:
      "Přidání v reálném čase: kontrola kvality začíná okamžitě, když data vstoupí do systému kontroly kvality",
    realTimeAdditionTip2:
      "Načasování přidat: načasování automaticky přidává údaje pro kontrolu kvality",
    null: "Žádné",
    collcetedProps: "Sběrné pole",
    editingNameNow: "Název se upravuje",
    upToFourLevels: "Klasifikace do čtyř úrovní",
    default: "Výchozí",
    nameCantBeNull: "Název nemůže být prázdný",
    editingNow: "Akce se upravuje",
    nameError: "Špatný název",
    nameMaxLength8: "Maximální délka názvu je 8",
    callTime: "Čas mluvení",
    sentimentValue: "Hodnota sentimentu zákazníka",
    agentSentimentValue: "Hodnota sentimentu pro služby zákazníkům",
    sentimentAnalysis: "Analýza sentimentu",
    associatedData: "Údaje o silnici",
    notifyTheOwner: "Informovat vlastníka skupiny",
    notifyTheEmployee: "Informovat zaměstnance sám",
    notifyTheOwnerTip:
      "Pokud je typ rozhovoru skupinový chat, bude majitel skupiny upozorněn.",
    customNotifyEmployee: "Vlastní zaměstnanec oznámení",
    customNotifyEmployeeTip:
      "Je-li aktivující osoba zaměstnancem, bude informován zaměstnanec, jemuž zpráva patří.",
    clickToLearnMore: "Kliknutím se dozvíte více",
    repeatMarkingTip:
      "Tento záznam byl označen tímto kontrolním bodem kvality a není nutné označování opakovat.",
    successfulEvaluation: "Hodnocení úspěchu",
    evaluationFailed: "Hodnocení selhalo",
    qualityInspectionScore: "Hodnocení kontroly kvality",
    semanticTags: "Sémantické označení",
    smartTags: "Smart label",
    collect: "Shromažďovat informace",
    appealRecord: "Záznam o stížnosti",
    satisfy: "Spokojený",
    notSatisfy: "Neuspokojení",
    appealPlaceHolder: "Prosím, zadejte důvod odvolání",
    giveUp: "Abandon",
    rejectReasonPlaceHolder: "Prosím, zadejte důvod pro odmítnutí",
    reject: "Odmítnuto",
    changeRejectReason: "Revidovat důvod odmítnutí",
    pass: "By",
    revise: "Změna",
    machineEvaluation: "Vyhodnocení strojů",
    poor: "Chudák",
    excellent: "Dobře.",
    moderate: "General",
    mustMarked: "Tento bod kontroly kvality musí být označen",
    pleaseChoose: "Vyberte prosím",
    manualEvaluation: "Manuální hodnocení",
    qualityInspectionTemplate: "Použitelná šablona kontroly kvality",
    collectionTemplate: "Použitelná šablona nabývání",
    keywordsWordCloud: "Klíčová slova cloud",
    editCategoryLabel: "Upravit štítky kategorií",
    justCall: "Jen se podívejme na hovor.",
    justConversation: "Jen se podívej na dialog.",
    batchOperation: "Provoz šarže",
    customerServicesGroup: "Skupina zákaznických služeb",
    joinGroup: "Připojit se ke skupině",
    removeGroup: "Přesunout ze skupiny",
    enterInformationEntity: "Zadejte obsah subjektu informací",
    enterNotBeNull: "Vstup nemůže být prázdný",
    operationGroup: "Skupina operací",
    customerServiceSelected: "Vybraná zákaznická služba",
    callAndConversation: "Volání/rozhovor",
    conversation: "Dialog",
    system: "Systém",
    customize: "Vlastní",
    effective: "Efektivní",
    invalid: "Neplatný",
    successfulAndToTaskCenter:
      "Provádění je úspěšné, prosím, přejděte do střediska úkolů a zkontrolujte",
    recalculationFailed: "Opakovaný výpočet selhal",
    selectRecalculatedIndicator: "Please select the indicator to recalrate",
    source: "Vyberte zdroj dat",
    placeholder: "Zadejte prosím klíčové slovo",
    cumulative: "Kumulativní",
    average: "Průměrný typ",
    distribution: "Právo distribuce",
    originalDataCannotEmpty: "Surové údaje nemohou být prázdné",
    inspection: "Kontrola kvality",
    check: "Náhodná kontrola",
    review: "Přezkum",
    draftSavedSuccessfully: "Návrh úspěšně uložený",
    optimization: "Optimalizace",
    agentName: "Název zákaznické služby",
    allTasks: "Všechny úkoly kontroly kvality",
    datePickerSelect: "Vybrat datum",
    callSelect: "Vybrat dialog",
    taskSelect: "Vybrat úlohu",
    genReport: "Generovat sestavu",
    dataSource: "Zdroj dat",
    callsTotal: "Total calls",
    intelligentQualityInspection: "Inteligentní kontrola kvality",
    percentage: "Poměr",
    artificialSampling: "Manuální odběr vzorků",
    appealNumber: "Počet stížností",
    totalConversation: "Celkový počet dialogu",
    samplingReviewRate: "Rychlost přezkumu čerpání",
    data: "Data",
    inspectionResults: "Výsledky kontroly kvality",
    inspected: "Kontrola kvality",
    totalScore: "Celkové skóre",
    learnMore: "Více",
    inspectionEffect: "Efekt kontroly kvality",
    allTotal: "Celkem",
    manualInspection: "Manuální přezkum",
    artificialReview: "Manuální přezkum",
    approvalAmountAndRate: "Schválené množství a míra schválení",
    customerScore: "Skóre zákaznické služby",
    inspectionRecommendations: "Návrhy na kontrolu kvality",
    saveDraft: "Uložit návrh",
    historicalAdvice: "Historické rady",
    advicePlaceHolder: "Zadejte prosím návrh...",
    upload: "Nahrát soubor",
    customAdd: "Přidat filtr",
    advancedConditions: "Pokročilé podmínky",
    keyWord: "Klíčová slova",
    containsAll: "Obsahuje všechny",
    containsAny: "Obsahuje jakékoli",
    matchAny: "Match any",
    matchAll: "Zápas vše",
    smartTagsOne: "Smart label one",
    smartTagsTwo: "Smart label ii",
    season: "Sezóna",
    month: "Měsíc",
    week: "Týden",
    day: "Den",
    hour: "Kdy",
    notMoreThan366: "Ne víc než 366 dní.",
    propsCannotBeNull: "Některé pole nejsou vyplněny",
    other: "Ostatní",
    correlationCoefficient: "Korelační koeficient",
    repetitionRate: "Míra opakování",
    configurationItemsMoreThan20: "Přidat až 20 konfiguračních položek",
    conversionAnalysis: "Transformační analýza",
    correlationAnalysis: "Korelační analýza",
    successfulExportAndToTaskCenter:
      "Export is successful, please go to the task center to view",
    typeSelect: "Vybrat typ",
    exportExcel: "Exportovat aplikaci excel",
    exportPng: "Exportovat png",
    noDataChart: "Žádné údaje pro graf",
    placeOperator: "Vyberte smart label",
    placeAdvancedConditions: "Please select advanced criteria",
    createAdvancedConditions: "Nový pokročilý filtr",
    moveUp: "Přesunout nahoru",
    moveDown: "Posunout dolů",
    moveLeft: "Přesunout vlevo",
    moveRight: "Přesunout doprava",
    statusChangeSuccess: "Úspěšně upravený stav",
    editSuccess: "Úprava byla úspěšná",
    saveSuccess: "Úspěšně uložit",
    firstPage: "Úvodní stránka",
    lastPage: "Strana chvostů",
    customerInfo: "Informace o zákazníkovi",
    type: "Typ",
    NumberTypeErrorMessage:
      'Obsah pole informací typu čísel může obsahovat pouze čísla nebo "."',
    taskStartPrompt: "Řádek pro otevírání úlohy",
    startConfirm:
      "Klikněte na tlačítko potvrzení pro otevření úkolu kontrola kvality",
    continueToSubmitWithoutEvaluation: "Pokračovat v podání bez hodnocení",
    rejectTip:
      "Nebyly zjištěny žádné změny, určitě všechny odvolání odmítnete přímo?",
    unmarkedSelfLearningTip:
      "Máte neoznačené self-learning kontrolní body kvality",
    markedSuccessfully: "Úspěch označování",
    markingFailed: "Označování selhalo",
    exceedingLength: "Překročení délky",
    uncategorized: "Neutajované",
    addSuccess: "Nový úspěch",
    deleteSuccess: "Úspěšně smazán",
    featureName: "Název prvku",
    informationEntity: "Subjekt informací",
    standardAnswer: "Standardní odpověď",
    keyElementAnswer: "Klíčové prvky k odpovědi",
    generalStatement: "Obecné prohlášení (není třeba se ptát)",
    generalQuestionsAndConfirmation: "Obecné otázky a potvrzení",
    specialQuestionsAndAnswers: "Zvláštní otázky a odpovědi",
    agent: "Zákaznický servis",
    customer: "Zákazník",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Významový prvek sémantické štítky a subjekt informací nemůže být zároveň prázdný.",
    standardSentence: "Standardní věta",
    acquaintance: "Známost",
    smartUePlaceholder: "Zadejte název inteligentní interakce",
    typeSelectPlaceholder: "Vyberte typ",
    keyElement: "Klíčové prvky",
    reQuerySementicTags: "Znovu vybrat sémantické štítky",
    querySementicTags: "Vybrat sémantické štítky",
    QATestForm: "Formulář detekce otázky a odpovědi",
    selectNotBeNull: "Výběr nemůže být prázdný",
    featureDetectionTarget: "Cíl detekce prvků",
    categorized: "Klasifikace",
    effectiveness: "Účinnost",
    hideAdvancedSettings: "Skrýt pokročilé nastavení",
    showAdvancedSettings: "Zobrazit pokročilé nastavení",
    generalStatementOnly: "Obecné prohlášení",
    generalQuestions: "Obecná pochybnost",
    affirmativeExpression: "Kladný výraz",
    negativeIntention: "Negativní záměr",
    searchSementicTags: "Dotaz na sémantické štítky",
    choiceQuestion: "Selektivní dotazující věta",
    selectiveAnswer: "Odpověď typu volby",
    sementicTagsPlaceholder: "Zadejte obsah sémantického štítku",
    openSpecialQuestion: "Otevřít zvláštní otázky",
    openAnswer: "Otevřete odpověď.",
    customQA: "Vlastní q & a",
    questions: "Metoda dotazu",
    dataPlaceholder: "Zadejte prosím obsah",
    reply: "Odpověď",
    notFindSuitableLabel: "Nebyla nalezena žádná vhodná značka.",
    addSemanticTag: "Přidat novou sémantickou značku",
    intelligentInteraction: "Inteligentní interakce",
    matching: "Zápas",
    bout: "Times",
    keywordMatch: "Odpovídající klíčovým slovům",
    addFilterConditions: "Přidat podmínku filtru",
    buttonStartCollectionTask:
      "Klepněte na tlačítko ok pro spuštění úlohy sběru",
    buttonStartTask: "Klepněte na tlačítko ok pro otevření kontroly kvality",
    ruleConfigurationIsRequired: "Je požadována konfigurace pravidla",
    asrRunning: "Provádění asr",
    errorCorrectionExecution: "Provedení opravy chyb",
    emotionalVolumeRecognition: "Rozpoznávání hlasitosti emocí",
    dataPreprocessing: "Předzpracování dat",
    inspectionExecution: "Provádění kontroly kvality",
    businessId: "Id společnosti",
    businessTime: "Pracovní čas",
    createTime: "Čas vytvoření",
    abnormalReason: "Abnormální důvod",
    singleChat: "Single chat",
    groupChat: "Skupinový chat",
    taskContent: "Obsah úlohy",
    submissionTime: "Čas podání",
    startTime: "Čas zahájení",
    endTime: "Konec času",
    runningTime: "Časově náročná operace",
    totalAmountOfData: "Celkový objem dat",
    resultsOfThe: "Výsledky provádění",
    operator: "Operátor",
    taskDetails: "Podrobnosti úlohy",
    executiveFunction: "Výkonná funkce",
    qualityCheckTasks: "Kontrola kvality úkol",
    implementationDetails: "Podrobnosti o provádění",
    conditionalRules: "Pravidla stavu",
    smartCondition: "Inteligentní podmínky",
    goThis: "Přesunout do tohoto",
    pleaseMarkClassification: "Prosím, označte klasifikaci",
    advancedScreening: "Rozšířené filtrování",
    noDataToManipulate: "Žádné údaje k manipulaci",
    concept: "Koncepční slova",
    checkPoint1: "Nastavení pravidla",
    requiredFieldsCannotEmpty: "Požadovaná položka nemůže být prázdná",
    rule: "Pravidla",
    afterFixUnit: "Slovo",
    unmarkedSelfLearningPointsTip:
      "Máte neoznačené self-learning kontrolní body kvality",
    onlyReservedFor7Days: "(Jen 7 dní)",
    downloadError: "Stažení selhalo",
    pause: "Pauza",
    continue: "Pokračovat",
    recalcScore: "Přepočítat pouze skóre (s hodnocením)",
    recalculatePointsAndScores: "Přepočítat kontrolní body a skóre kvality",
    recalculatePointsAndScoresWithEmtry:
      "Přepočítat kontrolní body a skóre kvality (kontrolní body kvality jsou prázdné)",
    inProgress: "Probíhá",
    toBeExecuted: "Bude realizován",
    executeImmediately: "Okamžité provedení",
    specialElements: "Speciální prvky",
    logicRule: "Logické pravidla",
    every: "Vícenásobné zápasy",
    hitRecall: "Hit recall word",
    notHitRecall: "Zmeškané vzpomínací slovo",
    hitRecallRegular: "Hit recall regular",
    notHitRecallRegular: "Chybělo pravidelně",
    hitRule: "Stisknout pravidla",
    notHitRule: "Chybějící pravidlo",
    hitSimilarSentence: "Zaškrtněte podobné věty",
    notHitSimilarSentence: "Vynechat podobné věty",
    hitExcludeSimilarSentences: "Hit to exclude similar sentences",
    notHitExcludeSimilarSentences: "Chybělo k vyloučení podobných vět",
    updateSuccess: "Úspěšná aktualizace",
    qualify: "Pravidelné",
    recallKeyWord: "Recall keywords",
    grammar: "Pravidla gramatiky",
  },
  api: {
    putTypoDatasByIdStatusByStatus: "Změna platnosti selhala, zkuste to znovu",
    postAutoMateTasksCaseByTaskId:
      "Selhalo uložení konfigurace synchronizace knihovny případů",
    getAutoMateTasksCaseByTaskId:
      "Selhalo získání konfigurace synchronizace knihovny případů",
    postInspectionRemarkModelConfigs: "Selhalo uložení šablony komentáře",
    putInspectionRemarkModelConfigsById: "Selhalo uložení šablony komentáře",
    getInspectionRemarkModelConfigsById:
      "Selhalo získání dat šablony komentáře",
    postKnowledgeDatas: "Vytvoření znalostní základny selhalo, zkuste to znovu",
    putKnowledgeDatasById: "Úprava úložiště selhala, zkuste to znovu.",
    getLibraryFiles: "Nepodařilo se získat seznam cenových složek.",
    getLibraryCalls: "Selhalo získání souboru",
    getLibraryFilesSearch: "Hledání selhalo",
    putLibraryFilesMoveById: "Nepodařilo se přesunout složku cena.",
    putLibraryCallsById: "Přejmenování souboru se nezdařilo!",
    postLibraryFiles: "Přidání složky selhalo",
    putLibraryFilesById: "Přejmenování složky selhalo",
    deleteLibraryFilesById: "Odstranění selhalo",
    deleteLibraryCallsById: "Odstranění selhalo",
    postLibraryCalls: "Přidání selhalo",
    getVoiceUploadResultById: "Exportování výsledků odeslání se nezdařilo",
  },
};
