import React from 'react';
import SdkLoaderBaseComponent from './component';
import SdkLoader from 'udesk-react/src/components/sdk-loader';
import { PageHeader, Button } from 'udesk-ui';
class SdkLoaderBaseTemplate extends SdkLoaderBaseComponent {
    render() {
        let { 
            privates, actions, locales, 
        } = this;

        const {
            renderTitle = () => {}, 
            renderExtra = () => {
                if (privates.showExport) {
                    return [
                        <Button type='primary' onClick={actions.export}>
                            {locales.labels.export}
                        </Button>,
                    ];
                }
            }
        } = this; 

        return (
            <div className='udesk-qa-web-page' style={{ height: '100%' }}>
                <PageHeader
                    onBack={privates.showBack ? () => window.history.back() : null}
                    className='udesk-qa-web-page-header'
                    title={privates.pageTitle}
                    subTitle={renderTitle()}
                    extra={renderExtra()}
                />

                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root help-center-data-main' style={{ padding: '16px' }}>
                        <div className='data-report-page' style={{ height: '100%' }} id={privates.renderContainerId}>
                            <SdkLoader
                                releaseVersionUrl={privates.releaseVersionUrl}
                                ref={privates.qaRef}
                                jsUrl={privates.externalAppUrl}
                                cssUrl={privates.externalAppCssUrl}
                                loadErrorMessage={privates.fileLoadFailedMessage}
                                onLoaded={actions.onLoaded}
                                onDestroying={actions.onDestroying}
                                onCreated={actions.onCreatedInstance}
                                onDestroyed={actions.onDestroyInstance}
                                whenDetail={actions.showBack}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SdkLoaderBaseTemplate;
