// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { GetInspectionTestDatasTestByTaskIdParam, BaseResponseVoid } from '../../../types';

/**
 * 开始测试
 *
 * @export
 * @tags 质检规则测试
 * @link [GET] /inspectionTestDatas/test/{taskId}
 * @param taskId
 */
export function getInspectionTestDatasTestByTaskId(
    options: ApiOptions<GetInspectionTestDatasTestByTaskIdParam, never> & {
        segments: GetInspectionTestDatasTestByTaskIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, GetInspectionTestDatasTestByTaskIdParam, never>(
        '/inspectionTestDatas/test/{taskId}',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionTestDatasTestByTaskId' }
    );
}

export const meta = [
    { tags: ['质检规则测试'], path: '/inspectionTestDatas/test/{taskId}', method: 'get' },
];
