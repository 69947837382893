import React from "react";
import { Button, Icon } from 'udesk-ui';
import Locales from 'UdeskLocales';
import './style.scss';


const ColLabel = (props) => {
    const locales = Locales['current'];

    const { hasHighlight, isCusProps, hitCheckPointSelected, point } = props;
    return <div className='collection-component-col-label'>
        {
            hasHighlight ? <Button
                type='text'
                size='small'
                icon={<Icon type='daohang'></Icon>}
                onClick={hitCheckPointSelected.bind(
                    null,
                    point
                )}
            ></Button> : <div style={{width: 24, height: 1, display: 'block'}}></div>
        }
        {
            isCusProps ? <Button
                tooltip={locales.components.pages.collectionRule.table.props}
                type='text'
                size='small'
                icon={<Icon type='TeamOutlined' antdIcon={true} ></Icon>}
                style={{color: '#0FBA26'}}
            ></Button> : <div style={{width: 24, height: 1, display: 'block'}}></div>
        }
        <div className='collection-component-col-label-content' title={point.name}>{point.name}</div>
    </div>;
};
const ColValue = (props) => {
    const { value } = props;
    return <div className='collection-component-col-value' title={value}>
        {value}
    </div>;
};

const Collection = props => {
    const locales = Locales['current'];

    // const [initialValues, setInitialValues] = useState({a: 'aaaaaaaaaabbbbbbbbbb'});
    return (
        <React.Fragment>
            <div className='collection-component-title'>
                {locales.fix.collcetedProps}
            </div>
            <div className='collection-component-list'>
                {
                    (props.pointCategoryList?.[0]?.children?.[0]?.pointDataList??[])?.map?.((item, k) => {
                        return <div>
                            <div className='collection-component-col'>
                                <div className='collection-component-col-item'>
                                    <ColLabel point={item} hasHighlight={item.highlightGroupList?.length > 0} isCusProps={Boolean(item.customField)} hitCheckPointSelected={props.hitCheckPointSelected}/>
                                    <ColValue value={item.showValue}/>
                                </div>
                                {/* <div className='collection-component-col-item-remark'>
                                    remark：aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
                                </div> */}
                            </div>
                        </div>;
                    })
                }
            </div>
        </React.Fragment>
    );
};

export default React.memo(Collection);