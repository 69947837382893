import React from 'react';
import Udesk from 'Udesk';

const UNITS_LIST = ['K', 'M', 'G'];

export default class CorpusDataComponent extends React.Component {
    // #region privates
    privates = {
        isCorpusDataModalShow: false,
        corpusDataModalTitle: '',
        corpusDescription: '',
        corpusContent: '',
        effectiveness: '1',
        corpusDataId: '',
        isShowWarningNoneCorpusContent: false,
        isShowWarningCorpusContentOverSetting: false,
        isShowTextArea: true,
        downloadCorpusDataPath: '',
        smartWordsLibraryListInstance: null,
        isEnterEditCorpusContentOverSetting: false //刚进入编辑,语料内容超过20000字
    };
    //#endregion

    // #region cycles
    init() {

        let columns = this.getColumns();

        this.privates.columns = columns;
        // this.actions.update();
    }
    componentWillUnmount() {
        this.privates.smartWordsLibraryListInstance = null;
    }
    //#endregion

    // #region actions
    actions = {
        createCorpusData() {

            let {
                locales
            } = this;

            this.privates.isCorpusDataModalShow = true;
            this.privates.corpusDataModalTitle = locales.components.pages.smartWordsLibrary.corpusData.createCorpusData;
            this.actions.update();
        },
        editCorpusData(id) {

            let {
                locales
            } = this;

            let {
                sdkOptions
            } = this.props;

            let url = Udesk.business.apiPath.concatApiPath(`corpora/corporaData/${id}`, sdkOptions);

            Udesk.ajax.get(url).then(
                (resp, textStatus, jqXHR) => {
                    let responseItem = $.extend(true, {}, resp.data);

                    this.privates.corpusDescription = responseItem.corpusDescription;
                    this.privates.corpusContent = responseItem.corpusContent;
                    this.privates.corpusDataModalTitle = locales.components.pages.smartWordsLibrary.corpusData.editCorpusData;
                    this.privates.effectiveness = responseItem.effectiveness;
                    this.privates.corpusDataId = responseItem.id;
                    this.privates.isCorpusDataModalShow = true;

                    let {
                        dataType
                    } = responseItem;

                    if (dataType === Udesk.enums.corpusContentWordsLengthStatus.over.id) {
                        this.privates.isShowTextArea = false;
                        this.privates.downloadCorpusDataPath = responseItem.corpusContent;
                        this.privates.isEnterEditCorpusContentOverSetting = true;

                    }
                    if (dataType === Udesk.enums.corpusContentWordsLengthStatus.suitable.id) {
                        this.privates.isShowTextArea = true;
                    }
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.smartWordsLibrary.professionalWords.deleteErr);
                });
        },
        deleteCorpusData(id) {

            let that = this;

            let {
                sdkOptions
            } = this.props;

            Udesk.ui.alerts.warning({
                content: this.locales.labels.deleteContent,
                onOk: () => {
                    Udesk.ajax.del(
                        `${Udesk.business.apiPath.concatApiPath(`corpora/${id}`, sdkOptions)}`
                    ).then(
                        (resp, textStatus, jqXHR) => {
                            Udesk.ui.notify.success(this.locales.components.pages.smartWordsLibrary.corpusData.deleteSuccess);
                            that.privates.smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.smartWordsLibrary.corpusData.deleteError);
                        }
                    );
                }
            });
        },
        changeCorpusContent(e) {
            this.privates.corpusContent = e.target.value;
            let {
                corpusContent
            } = this.privates;
            if (corpusContent) {
                this.privates.isShowWarningNoneCorpusContent = false;
            } else {
                this.privates.isShowWarningNoneCorpusContent = true;
                this.privates.isShowWarningCorpusContentOverSetting = false;
            }
            if (corpusContent && corpusContent.length > 20000) {
                this.privates.isShowWarningCorpusContentOverSetting = true;
            }
            if (corpusContent && corpusContent.length <= 20000) {
                this.privates.isShowWarningCorpusContentOverSetting = false;
            }
            this.actions.update();
        },
        changeCorpusDataEffectiveness(value) {
            this.privates.effectiveness = value;
            this.actions.update();
        },
        saveCorpusData(e) {
            e.preventDefault();
            let isEdit = false;
            if (this.privates.corpusDataId) {
                isEdit = true;
            }

            if (!this.privates.corpusContent) {
                this.privates.isShowWarningNoneCorpusContent = true;
                this.actions.update();
            }

            this.props.form.validateFields((err, values) => {

                let { locales } = this;

                let {
                    corpusContent,
                    isShowTextArea,
                    isShowWarningCorpusContentOverSetting,
                    smartWordsLibraryListInstance,
                    isEnterEditCorpusContentOverSetting
                } = this.privates;

                let {
                    sdkOptions
                } = this.props;

                let requestType = isEdit ? 'put' : 'post';

                let title = isEdit ? locales.components.pages.smartWordsLibrary.corpusData.editSuccess : locales.components.pages.smartWordsLibrary.corpusData.createSuccess;

                if ((!err && corpusContent && !isShowWarningCorpusContentOverSetting) || (!err && corpusContent && !isShowTextArea)) {
                    let params = Object.assign({}, values, { corpusContent, wordsCount: corpusContent.length });
                    if (isEnterEditCorpusContentOverSetting) {
                        params = Object.assign({}, values);
                    }


                    let url = '';
                    if (isEdit) {
                        let id = this.privates.corpusDataId;
                        url = Udesk.business.apiPath.concatApiPath(`corpora/corporaData/${id}`, sdkOptions);

                    } else {
                        url = Udesk.business.apiPath.concatApiPath(`corpora/corporaData`, sdkOptions);
                    }

                    let that = this;

                    Udesk.ajax[requestType](url, params).then(
                        (resp, textStatus, jqXHR) => {
                            that.actions.closeCorpusDataModal();
                            Udesk.ui.notify.success(title);
                            smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || locales.components.pages.smartWordsLibrary.corpusData.deleteErr);
                        });

                } else {
                    return;
                }
            });

        },
        closeCorpusDataModal() {
            this.privates.corpusContent = '';
            this.privates.corpusDescription = '';
            this.privates.effectiveness = '1';
            this.privates.isCorpusDataModalShow = false;
            this.privates.corpusDataId = '';
            this.privates.isShowWarningNoneCorpusContent = false;
            this.privates.isShowWarningCorpusContentOverSetting = false;
            this.privates.downloadCorpusDataPath = '';
            this.privates.isShowTextArea = true;
            this.privates.isEnterEditCorpusContentOverSetting = false;
            this.actions.update();
        },
        download() {
            let {
                locales
            } = this;
            let {
                downloadCorpusDataPath
            } = this.privates;
            let request = new XMLHttpRequest();
            if (request) {
                request.open("GET", downloadCorpusDataPath, true);
                request.overrideMimeType("text/plain;charset=utf8");
                request.onreadystatechange = function () {
                    if (request.readyState === 4) {
                        if (request.status === 200 || request.status === 0) {
                            let content = request.responseText;
                            let blob = new Blob([content]);
                            let a = $('<a></a>');
                            a.attr('href', URL.createObjectURL(blob));
                            a.prop('download', locales.components.pages.smartWordsLibrary.upload.downLoadTxtTitle);
                            a.get(0).click();
                            a = null;
                        }
                    }
                };
                request.send(null);
            } else {
                Udesk.ui.notify.error(locales.components.pages.smartWordsLibrary.upload.downloadError);
            }
        }
    };
    //#endregion

    // #region public methods used "this":
    getColumns() {
        let { locales } = this;
        let columns = [
            {
                name: "corpusDescription",
                width: "20%",
                caption: locales.fields.smartWordsLibrary.corpusData.corpusDescription
            },
            {
                name: "updateTime",
                width: "10%",
                caption: locales.fields.smartWordsLibrary.corpusData.updateTime
            },
            {
                name: "wordsCount",
                width: "10%",
                caption: locales.fields.smartWordsLibrary.corpusData.wordsCount,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return (
                        <span>
                            {Udesk.business.smartWordsMethods.getFormatFrequency(item.wordsCount, UNITS_LIST)}
                        </span>
                    );
                }
            },
            {
                name: "effectiveness",
                width: "10%",
                caption: locales.fields.smartWordsLibrary.corpusData.effectiveness,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    let targetItem = Udesk.enums.wordsEffectiveness.get(item.effectiveness);
                    return (
                        <span>
                            {targetItem.name}
                        </span>
                    );
                }
            },
            {
                name: "operation",
                width: "15%",
                caption: locales.fields.smartWordsLibrary.corpusData.operation,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return (
                        <div className="smart-words-library-corpus-data-operation">
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:edit")}>
                                <span className="smart-words-library-operation-edit" title={locales.labels.edit} onClick={this.actions.editCorpusData.params(item.id)}>
                                    {locales.labels.edit}
                                </span>
                            </If>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:delete")}>
                                <span className="smart-words-library-operation-delete" title={locales.labels.delete} onClick={this.actions.deleteCorpusData.params(item.id)}>
                                    {locales.labels.delete}
                                </span>
                            </If>
                        </div>

                    );
                }
            }
        ];
        return columns;
    }
}