import React from 'react';
import Udesk from 'Udesk';
import UdeskLocales from "UdeskLocales";
import { getSystemModule } from 'Udesk/system/subApp';

const CUSTOMER_COUNT = 30;

class CustomerServicePortraitComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
        customerData: [],
        customerDataShow: [],
        customerSelectedValue: [],
        fetching: false,
        lastFetchId: 0,
        echartsWidth: 0,
        optinVisible: false,
        customerLabels: [],//业务标签
        startTime: moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00',
        endTime: moment().format('YYYY-MM-DD') + ' 23:59:59',
        radarData: {},//雷达图数据
        lineDatas: [],//柱形图数据
        // customerSelectedValueDefault: [],
    };
    privates = {
        customerDataShow: [],
        areaStyle: {},
        optionsSelectedKeys: [],
        testOptions: {},
        options: [],
        optionsAfterSearch: [],
        optionsSelected: [],
        defaultOptions: [],
        echartsRefs: [],
        radarRef: React.createRef(),
        defaultStartTime: moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00',
        defaultEndTime: moment().format('YYYY-MM-DD') + ' 23:59:59',
        customerSelectedKeys: [],
        customerSelectedNos: [],
        radarSeriesColumns: [
            {
                name: "",
                type: "radar",
                // areaStyle: {normal: {}},
                data: []
            }
        ],
        nameForSearch: '',
    };
    static computes = {

    };
    actions = {
        //#region RangePicker 时间选择
        selectTime([startTime, endTime]) {
            this.setState({
                startTime,
                endTime
            });
        },
        getFormatDate(date) {
            return getFormatDate(date);
        },
        //#endregion
        reset() {
            this.setState({
                startTime: this.privates.defaultStartTime,
                endTime: this.privates.defaultEndTime,
                customerSelectedValue: []
            });
            this.privates.customerSelectedKeys = [];
            this.privates.customerSelectedNos = [];
        },
        toIndicator() {
            let routeOptions = {
                history: this.props.history,
                routeName: "customerServiceIndexManagementIndex",
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        export() {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`agentPictureDistribution/exp`, sdkOptions);
            let pamars = {
                "agentNos": this.privates.customerSelectedNos,
                "agentIds": this.privates.customerSelectedKeys,
                "agentName": this.state.customerSelectedValue.length === 1 ? this.state.customerSelectedValue[0].label : '',
                "startTime": this.state.startTime,
                "endTime": this.state.endTime,
                "indicatorIds": this.privates.optionsSelectedKeys,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, pamars).then(
                resp => {
                    Udesk.ui.notify.success(this.locales.components.pages.customerServicePortrait.contol.seccuss);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        //#region Button search
        searchCustomerInfoDebounce(){
            return debounce(this.actions.searchCustomerInfo(), 1000);
        },
        searchCustomerInfo() {
            let max = moment(this.state.startTime).add('days', 366).format('YYYY-MM-DD')+ ' 23:59:59';
            if(max >= this.state.endTime){
                this.privates.echartsRefs = [];
                let pamars = {
                    "agentNos": this.privates.customerSelectedNos,
                    "agentIds": this.privates.customerSelectedKeys,
                    "agentName": this.state.customerSelectedValue.length === 1 ? this.privates.nameForSearch : '',
                    "startTime": this.state.startTime,
                    "endTime": this.state.endTime,
                    "indicatorIds": this.privates.optionsSelectedKeys
                };
    
                this.privates.customerSelectedNos.length
                    && debounce(getAllEcharts(this, pamars), 1000);
                !this.privates.customerSelectedNos.length && Udesk.ui.notify.error(
                    this.locales.components.pages.customerServicePortrait.contol.warning2
                );    
            }else {
                Udesk.ui.notify.error(
                    this.locales.components.pages.customerServicePortrait.contol.dataWarning
                );
            }
        },
        //#endregion
        //#region Select 选择客服
        selectCustomerChange(value) {
            this.setState({
                customerSelectedValue: value.length <= CUSTOMER_COUNT ? value : value.splice(0, CUSTOMER_COUNT),
            }, () => {
                this.privates.customerSelectedKeys = [];
                this.privates.customerSelectedNos = [];
                this.state.customerSelectedValue.map(item => {
                    this.privates.customerSelectedKeys.push(item.key);
                    this.state.customerData.map(data => {
                        data.id === item.key * 1 && this.privates.customerSelectedNos.push(data.agentNos);
                        return data;
                    });
                    return item;
                });
                if (this.state.customerSelectedValue.length === 1) {
                    this.state.customerData.forEach(data => {
                        if (data.id === this.state.customerSelectedValue.key) {
                            this.privates.nameForSearch = data.nameForSearch;
                        }
                    });
                }
            });
        },
        filterOption(inputValue, option){
            return option.props.children.indexOf(inputValue) >= 0;
        },
        //#endregion
        //#region Modal 选择配置
        //Button 配置
        showOption() {
            this.setState({
                optinVisible: true
            });
        },
        //Modal 选择配置
        optinHandleOk(e) {
            //此处应该发送ajax，获取this.privates.optionsSelected对应的数据
            if(this.privates.optionsSelected.length >= 3 && this.privates.optionsSelected.length <= 10){
                this.setState({
                    optinVisible: false,
                });
                this.privates.optionsSelectedKeys = [];
                this.privates.options.map(option => {
                    this.privates.optionsSelected.map(optionSelected => {
                        optionSelected === option.title
                            && this.privates.optionsSelectedKeys.push(option.key);
                        return optionSelected;
                    });
                    return option;
                });
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(`agentPictureDistribution/findRadar`, sdkOptions);
                let pamars = {
                    "agentNos": this.privates.customerSelectedNos,
                    "agentIds": this.privates.customerSelectedKeys,
                    "agentName": this.state.customerSelectedValue.length === 1 ? this.state.customerSelectedValue[0].label : '',
                    "startTime": this.state.startTime,
                    "endTime": this.state.endTime,
                    "indicatorIds": this.privates.optionsSelectedKeys
                };
                Udesk.ajax.post(url, pamars).then(
                    resp => {
                        let radarLegend = [];
                        let indicator = [];
                        let series = [];
                        // let optionsAfterSearch = [];
                        resp.data.map((item, index) => {
                            let seriesValue = [];
                            radarLegend.push(item.name);
                            item.radars.map(radar => {
                                index === 0 && indicator.push({
                                    text: radar.radarTagsName,
                                    max: 100
                                });
                                // index === 0 && optionsAfterSearch.push({
                                //     title: radar.radarTagsName,
                                //     key: radar.radarTagsName,
                                //     indicatorId: radar.indicatorId
                                // });
                                seriesValue.push(radar.count || 0);
                                return radar;
                            });
                            series.push({
                                value: seriesValue,
                                name: item.name
                            });
                            return item;
                        });
                        // this.privates.optionsAfterSearch = optionsAfterSearch;
                        // this.privates.options = optionsAfterSearch;
                        this.setState({
                            radarData: {
                                radarLegend,
                                indicator,
                                series
                            },
                        }, () => {
                            this.privates.radarSeriesColumns = [{
                                name: "",
                                type: "radar",
                                // areaStyle: {normal: {}},
                                data: this.state.radarData.series
                            }];
                            this.actions.update();
                        });
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );    
            }else {
                Udesk.ui.notify.error(this.locales.components.pages.customerServicePortrait.portrait.warning);
            }
        },
        optinHandleCancel(e) {
            this.setState({
                optinVisible: false,
            });
        },
        onOptionChange(checkedValues) {
            //checkedValues是对应的value组成的数组
            this.privates.optionsSelected = checkedValues;
            this.actions.update();
        },
        onSearch(value) {
            this.privates.optionsAfterSearch = value ? this.privates.options.filter(option => {
                return option.title.indexOf(value) !== -1;
            }) : this.privates.options;
            this.actions.update();
        },
        onSearchChange(e) {
            this.privates.optionsAfterSearch = e.target.value ? this.privates.options.filter(option => {
                return option.title.indexOf(e.target.value) !== -1;
            }) : this.privates.options;
            this.actions.update();
        },
        //#endregion
        //#region Echarts 自动变换大小
        setEchartsRefs(ref) {
            this.privates.echartsRefs.push(ref);
            this.actions.update();
        },
        onWindowResize() {
            this.privates.echartsRefs.map(echartsRef => {
                echartsRef && echartsRef.resize();
                return echartsRef;
            });
        },
        //#endregion
        //#region Echarts 柱状图参数获取
        getEchartsLineOptions(lineData, index, option) {
            option.title = { text: lineData.title };
            option.tooltip = {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                    crossStyle: { color: "#999" }
                },
                lineStyle: { color: "#999" }
            };
            option.dataZoom = [
                { type: "slider" }
            ];
            option.legend = {
                data: lineData.legend,
                bottom: 40
            };
            option.grid.bottom = 100;
            option.xAxis = {
                type: "category",
                // boundaryGap: true,
                data: lineData.xAxisData[index],
                axisPointer: { type: "shadow" }
            };
            option.yAxis = [
                {
                    type: "value",
                    name: this.locales.components.pages.customerServicePortrait.echarts.distributionLaw,
                    min: 0,
                    axisLabel: { formatter: "{value} %" }
                },
                {
                    type: "value",
                    name: this.locales.components.pages.customerServicePortrait.echarts.count,
                    min: 0,
                    axisLabel: { formatter: "{value} " + this.locales.components.pages.customerServicePortrait.echarts.bout }
                }
            ];
            option.series = lineData.series;
            // this.privates.echartsRefs[index] && this.privates.echartsRefs[index].current.forceUpdate()
            return option;
        },
        //#endregion
        //#region Echarts 雷达图参数获取
        getEchartsRadarOptions(option) {
            option.tooltip = {};
            option.legend = {
                data: this.state.radarData.radarLegend,
                y: 'bottom',
                type: 'scroll',
                bottom: 10
            };
            // option.grid = {coordinateSystem:'polar'};
            option.radar = {
                // shape: 'polygon',
                // splitNumber: 3,
                // center:['50%','50%'],
                // radius:'65%',
                //指示器名称和指示器轴的距离。[ default: 15 ]
                // nameGap:5,
                // triggerEvent:true,
                name: {
                    textStyle: {
                        color: "rgba(0,0,0,0.65)",
                        fontSize: '8px',
                        width: '48px',
                        height: '40px',
                        textAlign: 'center'
                    },
                    formatter: function (value, indicator) {
                        return value;
                    }
                },
                indicator: this.state.radarData.indicator
            };
            // option.series = lineData.data.map(item => {
            //     item.type = 'line';
            //     item.smooth = true;
            // })
            option.series = [
                {
                    name: "",
                    symbol: "none",
                    type: "radar",
                    areaStyle: { normal: { opacity: 0.3 } },
                    data: this.state.radarData.series
                }
            ];
            return option;
        }
        //#endregion
    };
    //#region Life Cycle
    async componentDidMount() {
        let customerRankListToCustomerPortraitSelected = JSON.parse(sessionStorage.getItem('customerRankListToCustomerPortrait_selected'));
        let customerRankListToCustomerPortraitPamars = JSON.parse(sessionStorage.getItem('customerRankListToCustomerPortrait_pamars'));
        sessionStorage.removeItem('customerRankListToCustomerPortrait_pamars');
        sessionStorage.removeItem('customerRankListToCustomerPortrait_selected');
        // customerRankListToCustomerPortraitSelected && this.setState({
        //     customerSelectedValueDefault: customerRankListToCustomerPortraitSelected
        // });
        //echarts重画
        window.addEventListener('resize', this.actions.onWindowResize);
        //#region 初始化数据
        //#region 获取客服列表
        let { sdkOptions } = this.props;
        let url3 = Udesk.business.apiPath.concatApiPath(`agentGroups`, sdkOptions);
        await Udesk.ajax.get(url3).then(
            resp => {
                getCustomerGroupMember(resp.data[0].id, sdkOptions, this);
                customerRankListToCustomerPortraitSelected && this.actions.selectCustomerChange(customerRankListToCustomerPortraitSelected);
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
        //#endregion 
        if (customerRankListToCustomerPortraitPamars) {
            this.setState({
                startTime: customerRankListToCustomerPortraitPamars.startTime,
                endTime: customerRankListToCustomerPortraitPamars.endTime
            });
            let pamars = customerRankListToCustomerPortraitPamars ?
                customerRankListToCustomerPortraitPamars
                : {
                    "agentNos": this.privates.customerSelectedNos,
                    "agentIds": this.privates.customerSelectedKeys,
                    "agentName": this.state.customerSelectedValue.length === 1 ? this.state.customerSelectedValue[0].label : '',
                    "startTime": this.state.startTime,
                    "endTime": this.state.endTime,
                    "indicatorIds": this.privates.optionsSelectedKeys
                };
            getAllEcharts(this, pamars);
            this.privates.customerSelectedKeys = [...customerRankListToCustomerPortraitPamars.agentIds];
            this.privates.customerSelectedNos = [...customerRankListToCustomerPortraitPamars.agentNos];
        }
        //#endregion
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.actions.onWindowResize);
        this.privates.echartsRefs = [];
        sessionStorage.setItem(
            'customerPortraitToCustomerRankList_pamars',
            JSON.stringify(
                {
                    'startTime': this.state.startTime,
                    'endTime': this.state.endTime,
                }
            )
        );
    }
    getSnapshotBeforeUpdate(prevProps, prevState) {

    }
    //#endregion

}

function getLineOption(option) {
    option.title = {
        text: this.title
    };
    option.legend = {
        data: this.legend,
        y: 'bottom',
        marginTop: '50px',
        type: 'scroll',
        // orient: 'vertical',
    };
    option.grid.bottom = 50;
    option.xAxis = [
        {
            type: 'category',
            data: this.xAxisData,
            axisPointer: {
                type: 'shadow'
            }
        }
    ];
    option.yAxis = [
        {
            type: 'value',
            name: UdeskLocales.current.components.pages.customerServicePortrait.echarts.count,
            axisLabel: {
                formatter: '{value}' + UdeskLocales.current.components.pages.customerServicePortrait.echarts.bout
            }
        },
        {
            type: 'value',
            name: UdeskLocales.current.components.pages.customerServicePortrait.echarts.distributionLaw,
            interval: 10,
            axisLabel: {
                formatter: '{value} %'
            }
        }
    ];
    option.tooltip = {
        trigger: 'axis',
        formatter:function(a){
            let res = [];

            a.forEach((item, index) => {
                index === 0 && res.push(item.name+':<br/>');
                if(item.seriesType === 'bar'){
                    res.push(item.marker+item.seriesName+':<br/>'+item.value+UdeskLocales.current.components.pages.customerServicePortrait.echarts.bout);
                }
                if(item.seriesType === 'line'){
                    res.push(','+item.value+'%<br/>');
                }
            });
            return res.join('');
        },
        axisPointer: {
            type: 'cross',
            crossStyle: {
                color: '#999'
            }
        }
    };
    option.series = this.series;
    return option;
}

function getFormatDate(date) {
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}

function getCustomerGroupMember(parmars, sdkOptions, context) {
    let url2 = Udesk.business.apiPath.concatApiPath(`agentGroups/${parmars}`, sdkOptions);
    Udesk.ajax.post(url2, { checked: 0, keyWord: "" }).then(
        resp => {
            let customerDataCopy = [];
            resp.data.map(item => {
                customerDataCopy.push({
                    name: `${item.agentName}(${item.agentNo})`,
                    nameForSearch: item.agentName,
                    id: item.id,
                    agentNos: item.agentNo
                });
                return item;
            });
            context.privates.customerDataShow = customerDataCopy;
            context.setState({
                customerData: customerDataCopy,
                // customerDataShow: customerDataCopy
            }, () => {
            });
        },
        reason => {
            Udesk.ui.notify.error(reason.errorMsg);
        }
    );
}

function debounce(fn,wait){
    var timer = null;
    return function(){
        if(timer !== null){
            clearTimeout(timer);
        }
        timer = setTimeout(fn,wait);
    };
}

async function getAllEcharts(that, pamars) {
    //雷达图指标配置
    try {
        let { sdkOptions } = that.props;
        let url = Udesk.business.apiPath.concatApiPath(`agentPictureDistribution/findTags`, sdkOptions);
        let url2 = Udesk.business.apiPath.concatApiPath(`agentPictureDistribution/findRadar`, sdkOptions);
        let url3 = Udesk.business.apiPath.concatApiPath(`agentPictureDistribution/findDistribution`, sdkOptions);
        let url4 = Udesk.business.apiPath.concatApiPath(`agentPictureDistribution/findIndicators`, sdkOptions);
        that.privates.options = [];
        let findIndicators = await Udesk.ajax.post(url4, pamars);
        that.privates.optionsSelected = [];
        let options = [];
        let idsList = [];
        findIndicators.data.map(item => {
            let option = {};
            option.title = item.indicatorName;
            option.key = item.id;
            idsList.push(item.id);
            options.push(option);
            return item;
        });
        that.privates.options = options;
        that.privates.optionsAfterSearch = options;
        that.privates.defaultOptions = idsList.length > CUSTOMER_COUNT ? idsList.splice(CUSTOMER_COUNT) : idsList;
        let optionsSelectedKeys = idsList.length > CUSTOMER_COUNT ? idsList.splice(CUSTOMER_COUNT) : idsList;
        optionsSelectedKeys.map(optionsSelectedKey => {
            options.map(option => {
                option.key === optionsSelectedKey && that.privates.optionsSelected.push(option.title);
                return option;
            });
            return optionsSelectedKey;
        });
        that.actions.update();
        pamars.indicatorIds = optionsSelectedKeys;
        that.privates.optionsSelectedKeys = optionsSelectedKeys;
        //雷达图
        let findRadar = await Udesk.ajax.post(url2, pamars);
        let radarLegend = [];
        let indicator = [];
        let series = [];
        // let optionsAfterSearch = [];
        findRadar.data.map((item, index) => {
            let seriesValue = [];
            radarLegend.push(item.name);
            item.radars.map(radar => {
                index === 0 && indicator.push({
                    text: radar.radarTagsName,
                    max: 100
                });
                // index === 0 && optionsAfterSearch.push({
                //     title: radar.radarTagsName,
                //     key: radar.radarTagsName,
                //     indicatorId: radar.indicatorId
                // });
                seriesValue.push(radar.count || 0);
                return radar;
            });
            series.push({
                value: seriesValue,
                name: item.name
            });
            return item;
        });
        // this.privates.optionsAfterSearch = optionsAfterSearch;
        // this.privates.options = optionsAfterSearch;
        that.setState({
            radarData: {
                radarLegend,
                indicator,
                series
            },
        }, () => {
            that.privates.radarSeriesColumns = [{
                name: "",
                type: "radar",
                // areaStyle: {normal: {}},
                data: that.state.radarData.series
            }];
            that.actions.update();
        });
        //智能标签
        let findTags = await Udesk.ajax.post(url, pamars);
        that.setState({
            customerLabels: findTags.data
        });
        //柱状图
        let findDistribution = await Udesk.ajax.post(url3, pamars);
        let lineDatas = [];
        let legend = [];
        let xAxisData = [];
        findDistribution.data.map((item, index) => {
            let series = [];
            let lineData = {};
            lineData.title = item.title;
            xAxisData[index] = [];
            legend[index] = [];
            item.datas.map((data, datasIndex) => {
                // index === 0 && legend.push(data.name);
                let countList = [];
                let rateList = [];
                legend[index].push(data.name);
                data.nodes.map((node, nodeIndex) => {
                    // datasIndex === 0 && data.nodes.length > xAxisData.length && xAxisData[index].push(node.axis);
                    datasIndex === 0 && xAxisData[index].push(node.axis);
                    countList.push(node.count);
                    rateList.push((node.rate*100).toFixed(2));
                    return node;
                });
                series.push({
                    name: data.name,
                    type: 'bar',
                    data: countList
                });
                series.push({
                    name: data.name,
                    type: 'line',
                    yAxisIndex: 1,
                    smooth: true,
                    data: rateList
                });
                return data;
            });
            lineData.legend = legend[index];
            lineData.xAxisData = xAxisData[index];
            lineData.series = series;
            lineDatas.push(lineData);
            return item;
        });
        lineDatas.map(item => {
            item.getOption = getLineOption.bind(item);
            return item;
        });
        that.setState({
            lineDatas: lineDatas
        });    
    }
    catch(err){
        Udesk.ui.notify.success(err.errorMsg);
        that.setState({
            lineDatas: [],
        });
    }
}

export default CustomerServicePortraitComponent;
