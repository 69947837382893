import Udesk from 'Udesk';
import CustomerServiceRankListComponent from './component';

class CustomerServiceRankListRoute extends CustomerServiceRankListComponent {
    asyncModel() {
        let { sdkOptions } = this.props;

        let url = Udesk.business.apiPath.concatApiPath(`agentGroups/tree`, sdkOptions);
        let agentGroupsTreePromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                (resp) => {
                    let treeData1 = fromAgentsToChildren(
                        (resp.data || []).map((d) => ({ ...d, id: d.id + '/n' }))
                    );
                    let dataClone = JSON.parse(JSON.stringify(treeData1));
                    let treeData = convertKey(dataClone, {
                        agentGroupName: 'title',
                        id: 'value',
                        agents: 'children',
                        agentName: 'title',
                        agentNo: 'value',
                    });
                    this.setState(
                        {
                            customerGroupData: treeData,
                            selectedCustomerGroup: [treeData[0].value],
                        },
                        () => {
                            this.privates.defaultSelectedCustomerGroup = [treeData[0].value];
                        }
                    );
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let url3 = Udesk.business.apiPath.concatApiPath(`agentGroups`, sdkOptions);
        new Promise((resolve, reject) => {
            Udesk.ajax.get(url3).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        })
            .then((data) => {
                let allCustomerGroupKeys = [];
                getCustomerGroupKeys(data.data, allCustomerGroupKeys);
                let allGroupId = data.data[0].id;
                this.privates.allCustomerGroupKeys = allCustomerGroupKeys;
                let url2 = Udesk.business.apiPath.concatApiPath(
                    `agentGroups/${allGroupId}`,
                    sdkOptions
                );
                return new Promise((resolve, reject) => {
                    Udesk.ajax.post(url2, { checked: 0, keyWord: '' }).then(
                        (resp) => {
                            this.privates.allCustomerInfo = resp.data;
                            resp.data.map((item) => {
                                this.privates.allagentNos.push(item.agentNo);
                                this.privates.customerNosSelected.push(item.agentNo);
                                this.privates.customerNosSelectedCopy.push(item.agentNo);
                                return item;
                            });
                            resolve(resp);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            })
            .then((data) => {
                this.actions.getTableData();
            });
        return {
            agentGroupsTree: agentGroupsTreePromise,
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}
function evil(fn) {
    let Fn = Function;
    return new Fn('return ' + fn)();
}

function convertKey(arr, keyMap) {
    let tempString = JSON.stringify(arr);
    for (var key in keyMap) {
        var reg = `/"${key}":/g`;
        tempString = tempString.replace(evil(reg), '"' + keyMap[key] + '":');
        // tempString = tempString.replace(eval(reg),'"'+keyMap[key]+'":');
    }
    return JSON.parse(tempString);
}

function fromAgentsToChildren(data) {
    data.map((item) => {
        if (item.agents && item.agents.length === 0) {
            delete item['agents'];
        }
        // 这里，为避免id重复导致渲染失败，将组别的id做下处理，在有用到的地方转成数字
        if (item.children) {
            item.children = item.children.map((child) => {
                const _agent = { ...child, id: child.id + '/u' };
                return _agent;
            });
        }
        // if (item.agents) {
        //     item.agents = item.agents.map((agent) => {
        //         const _agent = { ...agent, agentNo: agent.agentNo + '/u', id: agent.id + '/u' };
        //         return _agent;
        //     });
        // }
        if (item.agents && item.children) {
            item.agents.map((agent) => {
                item.children.push(agent);
                return agent;
            });
            delete item['agents'];
        }
        if (item.children) {
            fromAgentsToChildren(item.children);
        }
        return item;
    });
    return data;
}

function getCustomerGroupKeys(allCheckedNodes, allCustomerGroupKeys) {
    allCheckedNodes.map((allCheckedNode) => {
        if (allCheckedNode.children) {
            getCustomerGroupKeys(allCheckedNode.children, allCustomerGroupKeys);
        } else {
            allCustomerGroupKeys.push(allCheckedNode.id);
        }
        return allCheckedNode;
    });
}

export default CustomerServiceRankListRoute;
