// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetIntelligentPartnerAiAnalysisQueryFormFindAllQuery,
    BaseResponseListIntelligentPartnerAiQueryReportFormFoundResponse,
} from '../../../../../../types';

/**
 * 查询自定义报表-分页
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [GET] /intelligentPartner/ai/analysis/query/form/findAll
 */
export function getIntelligentPartnerAiAnalysisQueryFormFindAll(): Promise<BaseResponseListIntelligentPartnerAiQueryReportFormFoundResponse>;
export function getIntelligentPartnerAiAnalysisQueryFormFindAll(
    options: ApiOptions<never, GetIntelligentPartnerAiAnalysisQueryFormFindAllQuery>
): Promise<BaseResponseListIntelligentPartnerAiQueryReportFormFoundResponse>;
export function getIntelligentPartnerAiAnalysisQueryFormFindAll(
    options?: ApiOptions<never, GetIntelligentPartnerAiAnalysisQueryFormFindAllQuery>
): Promise<BaseResponseListIntelligentPartnerAiQueryReportFormFoundResponse> {
    return request<never, never, GetIntelligentPartnerAiAnalysisQueryFormFindAllQuery>(
        '/intelligentPartner/ai/analysis/query/form/findAll',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerAiAnalysisQueryFormFindAll',
        }
    );
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/query/form/findAll',
        method: 'get',
    },
];
