export default {
  lang: {
    num: 'Sekce',
    total: 'Článek/celkem',
    strip: 'Článek',
    more: 'Více',
    chosen: 'Vybráno',
    cancel: 'Zrušit výběr',
    bulkOperations: 'Provoz šarže',
    import: 'Import',
  },
};
