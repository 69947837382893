const INNER_STORAGE_KEY = Symbol('$private$_innerStorage');
const POSTFIX_REG_EXP = /^([^$]+?)\$(\d+?)\$(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(\.\d+?)?Z$/;
const DEFAULT_PRIORITY = 0;
const MAX_PRIORITY = 5;

export class WebStorageClass {
    constructor(storageName) {
        if (storageAvailable(storageName)) {
            this[INNER_STORAGE_KEY] = window[storageName];
        } else {
            this[INNER_STORAGE_KEY] = null;
        }
    }

    get available() {
        return (this[INNER_STORAGE_KEY] != null);
    }

    get length() {
        if (this[INNER_STORAGE_KEY]) {
            return this[INNER_STORAGE_KEY].length;
        } else {
            return 0;
        }
    }

    key(index) {
        if (this[INNER_STORAGE_KEY]) {
            return this[INNER_STORAGE_KEY].key(index);
        } else {
            return null;
        }
    }

    getItem(key) {
        if (this[INNER_STORAGE_KEY]) {
            let fullKey = findMatchedKey(this[INNER_STORAGE_KEY], key);
            if (fullKey) {
                return this[INNER_STORAGE_KEY].getItem(fullKey.storageKey);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    setItem(key, value, priority) {
        if (this[INNER_STORAGE_KEY]) {
            try {
                let fullKey = findMatchedKey(this[INNER_STORAGE_KEY], key);
                let storageKey = null;
                if (fullKey != null) {
                    storageKey = fullKey.storageKey;
                } else {
                    storageKey = generateStorageKey(key, priority);
                }
                this[INNER_STORAGE_KEY].setItem(storageKey, value);
                return true;
            } catch (err) {
                if (isQuotaExceededError(err, this[INNER_STORAGE_KEY])) {
                    if (removeOldestItem(this[INNER_STORAGE_KEY])) {
                        return this.setItem(key, value, priority);
                    } else {
                        throw err;
                    }
                } else {
                    throw err;
                }
            }
        }
        return false;
    }

    removeItem(key) {
        if (this[INNER_STORAGE_KEY]) {
            let fullKey = findMatchedKey(this[INNER_STORAGE_KEY], key);
            if (fullKey) {
                return this[INNER_STORAGE_KEY].removeItem(fullKey.storageKey);
            }
        }
    }

    clear() {
        if (this[INNER_STORAGE_KEY]) {
            this[INNER_STORAGE_KEY].clear();
        }
    }
}

export default {
    localStorage: new WebStorageClass("localStorage"),
    sessionStorage: new WebStorageClass("sessionStorage"),
};

function storageAvailable(storageName) {
    try {
        let storage = window[storageName];
        let testKey = '__storage_test__';
        storage.setItem(testKey, testKey);
        storage.removeItem(testKey);
        return true;
    } catch (err) {
        return isQuotaExceededError(err, window[storageName]);
    }
}

function isQuotaExceededError(error, webStorage) {
    return error instanceof DOMException && (
            // everything except Firefox
            error.code === 22 ||
            // Firefox
            error.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            error.name === 'QuotaExceededError' ||
            // Firefox
            error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        webStorage && webStorage.length > 0;
}

function findMatchedKey(webStorage, key) {
    if (Object.prototype.hasOwnProperty.call(webStorage,key)) {
        return {
            key,
            storageKey: key,
            priority: null,
            timestamp: null,
            milliseconds: null
        };
    } else {
        for (let i = 0; i < webStorage.length; i++) {
            let storageKey = webStorage.key(i);
            if (storageKey.substr(0, key.length) === key) {
                let fullKey = parseFullKey(storageKey);
                if (fullKey) {
                    return fullKey;
                }
            }
        }
        return null;
    }
}

function parseFullKey(storageKey) {
    let postfixMatch = storageKey.match(POSTFIX_REG_EXP);
    if (postfixMatch != null) {
        let priority = Number.parseInt(postfixMatch[2], 10);
        let createTimeMilliseconds = Number.parseInt(postfixMatch[4], 10);
        return {
            key: postfixMatch[1],
            storageKey,
            priority: (Number.isNaN(priority) ? DEFAULT_PRIORITY : priority),
            timestamp: postfixMatch[3],
            milliseconds: (Number.isNaN(createTimeMilliseconds) ? 0 : createTimeMilliseconds)
        };
    }
    return null;
}

function generateStorageKey(key, priority) {
    priority = Number.parseInt(priority, 10);
    if (Number.isNaN(priority) || priority < DEFAULT_PRIORITY) {
        priority = DEFAULT_PRIORITY;
    } else if (priority > MAX_PRIORITY) {
        priority = MAX_PRIORITY;
    }
    return `${key}$${priority}$${new Date().toISOString()}`;
}

function removeOldestItem(webStorage) {
    let smallest = null;
    for (let i = 0; i < webStorage.length; i++) {
        let storageKey = webStorage.key(i);
        let fullKey = parseFullKey(storageKey);
        if (fullKey) {
            if (smallest != null) {
                if (fullKey.priority < smallest.priority ||
                    fullKey.timestamp < smallest.timestamp ||
                    fullKey.milliseconds < smallest.milliseconds) {
                    smallest = fullKey;
                    continue;
                }
            } else {
                smallest = fullKey;
            }
        }
    }

    if (smallest != null) {
        webStorage.removeItem(smallest.storageKey);
        return true;
    } else {
        let firstKey = webStorage.key(0);
        if (firstKey != null) {
            webStorage.removeItem(firstKey);
            return true;
        }
    }

    return false;
}