import Embed from '@editorjs/embed';
import List from '@editorjs/list';
//import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Paragraph from '@editorjs/paragraph';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
// import Link from '@editorjs/link';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import VideoTool from '@weekwood/editorjs-video';
// import RawTool from '@editorjs/raw';
export var getEditorJsTools = function getEditorJsTools(methodObj) {
  var customImageUpload = methodObj.customImageUpload,
    customVideoUpload = methodObj.customVideoUpload;
  return {
    header: {
      class: Header,
      config: {
        placeholder: 'Enter a header',
        levels: [1, 2, 3],
        defaultLevel: 1,
      },
      inlineToolbar: ['link'],
    },
    embed: {
      class: Embed,
      inlineToolbar: true,
    },
    list: {
      class: List,
      inlineToolbar: true,
      selected: true,
    },
    checklist: {
      class: CheckList,
      inlineToolbar: true,
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
    },
    quote: {
      class: Quote,
      inlineToolbar: true,
      config: {
        quotePlaceholder: '请输入引用',
        captionPlaceholder: '引用的作者',
      },
    },
    linkTool: {
      class: LinkTool,
      inlineToolbar: true,
      config: {
        endpoint: 'http://www.baidu.com',
        headers: 'get', // Your backend endpoint for url data fetching,
      },
    },

    // image: {
    //   class: Image,
    //   config: {
    //     endpoints: {
    //       // byFile: 'http://localhost:8008/uploadFile', // Your backend file uploader endpoint
    //       byFile: 'http://www.baidu.com', // Your backend file uploader endpoint
    //       byUrl: 'http://www.baidu.com', // Your endpoint that provides uploading by Url
    //     },
    //     uploader: {
    //       /**
    //        * Upload file to the server and return an uploaded image data
    //        * @param {File} file - file selected from the device or pasted by drag-n-drop
    //        * @return {Promise.<{success, file: {url}}>}
    //        */
    //       uploadByFile(file: any) {
    //         // your own uploading logic here
    //         // return ajax.upload(file).then(() => {
    //         //     customImageUpload(file).then(() => {
    //         return (
    //           {
    //             success: 1,
    //             file: {
    //               url: '',
    //               // any other image data you want to store, such as width, height, color, extension, etc
    //             },
    //           } || customImageUpload()
    //         );
    //         // });
    //         // });
    //       },
    //       /**
    //        * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
    //        * @param {string} url - pasted image URL
    //        * @return {Promise.<{success, file: {url}}>}
    //        */
    //       uploadByUrl(url: string) {
    //         // your ajax request for uploading
    //         // return MyAjax.upload(file).then(() => {
    //         return {
    //           success: 1,
    //           file: {
    //             url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Ffile02.16sucai.com%2Fd%2Ffile%2F2014%2F0814%2F17c8e8c3c106b879aa9f4e9189601c3b.jpg&refer=http%3A%2F%2Ffile02.16sucai.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652665006&t=f4f727ac25cc109a7b21251fdbfe098c',
    //             // any other image data you want to store, such as width, height, color, extension, etc
    //           },
    //         };
    //         // });
    //       },
    //     },
    //     actions: [
    //       {
    //         name: 'new_button',
    //         icon: '<svg>...</svg>',
    //         title: 'New Button',
    //         action: (names: any) => {
    //           alert(`${names} button clicked`);
    //           return true;
    //         },
    //       },
    //     ],
    //   },
    // },
    // video: {
    //   class: VideoTool,
    //   config: {
    //     /**
    //      * Custom uploader
    //      *
    //      */
    //     endpoints: {
    //       byFile: 'http://localhost:8008/uploadFile', // Your backend file uploader endpoint
    //       byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
    //     },
    //     player: {
    //       pip: true,
    //       controls: true,
    //       light: true,
    //       playing: true,
    //     },
    //     uploader: {
    //       /**
    //        * Upload file to the server and return an uploaded video data
    //        * @param {File} file - file selected from the device or pasted by drag-n-drop
    //        * @return {Promise.<{success, file: {url}}>}
    //        */
    //       uploadByFile(file: any) {
    //         // your own uploading logic here
    //         //   return customVideoUpload().then(() => {
    //         return {
    //           success: 1,
    //           file:
    //             {
    //               url: 'https://codex.so/upload/redactor_videos/o_80beea670e49f04931ce9e3b2122ac70.jpg',
    //               // any other video data you want to store, such as width, height, color, extension, etc
    //             } || customVideoUpload(),
    //         };
    //         //   });
    //       },
    //       /**
    //        * Send URL-string to the server. Backend should load video by this URL and return an uploaded video data
    //        * @param {string} url - pasted video URL
    //        * @return {Promise.<{success, file: {url}}>}
    //        */
    //       uploadByUrl(url: any) {
    //         // your ajax request for uploading
    //         //    return MyAjax.upload(file).then(() => {
    //         return {
    //           success: 1,
    //           file: {
    //             url: 'https://codex.so/upload/redactor_videos/o_e48549d1855c7fc1807308dd14990126.jpg',
    //             // any other video data you want to store, such as width, height, color, extension, etc
    //           },
    //         };
    //         //});
    //       },
    //     },
    //   },
    // },
    delimiter: Delimiter,
  };
};
export var EDITOR_JS_TOOLS = {
  header: {
    class: Header,
    config: {
      placeholder: 'Enter a header',
      levels: [1, 2, 3],
      defaultLevel: 1,
    },
    inlineToolbar: ['link'],
  },
  embed: {
    class: Embed,
    inlineToolbar: true,
  },
  list: {
    class: List,
    inlineToolbar: true,
    selected: true,
  },
  checklist: {
    class: CheckList,
    inlineToolbar: true,
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
    config: {
      quotePlaceholder: '请输入引用',
      captionPlaceholder: '引用的作者',
    },
  },
  linkTool: {
    class: LinkTool,
    inlineToolbar: true,
    config: {
      endpoint: 'http://www.baidu.com',
      headers: 'get', // Your backend endpoint for url data fetching,
    },
  },

  image: {
    class: Image,
    config: {
      endpoints: {
        byFile: 'http://localhost:8008/uploadFile',
        byUrl: 'http://www.baidu.com', // Your endpoint that provides uploading by Url
      },

      uploader: {
        /**
         * Upload file to the server and return an uploaded image data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        uploadByFile: function uploadByFile(file) {
          // your own uploading logic here
          // return ajax.upload(file).then(() => {
          //     customImageUpload(file).then(() => {
          return {
            success: 1,
            file: {
              url: 'https://codex.so/upload/redactor_images/o_80beea670e49f04931ce9e3b2122ac70.jpg',
              // any other image data you want to store, such as width, height, color, extension, etc
            },
          };
          // });
          // });
        },
        /**
         * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
         * @param {string} url - pasted image URL
         * @return {Promise.<{success, file: {url}}>}
         */
        uploadByUrl: function uploadByUrl(url) {
          // your ajax request for uploading
          //return MyAjax.upload(file).then(() => {
          return {
            success: 1,
            file: {
              url: 'https://codex.so/upload/redactor_images/o_e48549d1855c7fc1807308dd14990126.jpg',
              // any other image data you want to store, such as width, height, color, extension, etc
            },
          };
          // });
        },
      },

      actions: [
        {
          name: 'new_button',
          icon: '<svg>...</svg>',
          title: 'New Button',
          action: function action(names) {
            alert(''.concat(names, ' button clicked'));
            return true;
          },
        },
      ],
    },
  },
  video: {
    class: VideoTool,
    config: {
      /**
       * Custom uploader
       *
       */
      endpoints: {
        byFile: 'http://localhost:8008/uploadFile',
        byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
      },

      player: {
        pip: true,
        controls: true,
        light: true,
        playing: true,
      },
      uploader: {
        /**
         * Upload file to the server and return an uploaded video data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        uploadByFile: function uploadByFile(file) {
          // your own uploading logic here
          //   return customVideoUpload().then(() => {
          return {
            success: 1,
            file: {
              url: 'https://codex.so/upload/redactor_videos/o_80beea670e49f04931ce9e3b2122ac70.jpg',
              // any other video data you want to store, such as width, height, color, extension, etc
            },
          };
          //   });
        },
        /**
         * Send URL-string to the server. Backend should load video by this URL and return an uploaded video data
         * @param {string} url - pasted video URL
         * @return {Promise.<{success, file: {url}}>}
         */
        uploadByUrl: function uploadByUrl(url) {
          // your ajax request for uploading
          //  return MyAjax.upload(file).then(() => {
          return {
            success: 1,
            file: {
              url: 'https://codex.so/upload/redactor_videos/o_e48549d1855c7fc1807308dd14990126.jpg',
              // any other video data you want to store, such as width, height, color, extension, etc
            },
          };
          //  });
        },
      },
    },
  },

  delimiter: Delimiter,
};
