// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerTaskInfosTargetByLessonIdParam,
    BaseResponseIntelligentPartnerTaskTarget,
} from '../../../types';

/**
 * 获取发布对象
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/target/{lessonId}
 * @param lessonId
 */
export function getIntelligentPartnerTaskInfosTargetByLessonId(
    options: ApiOptions<GetIntelligentPartnerTaskInfosTargetByLessonIdParam, never> & {
        segments: GetIntelligentPartnerTaskInfosTargetByLessonIdParam;
    }
): Promise<BaseResponseIntelligentPartnerTaskTarget> {
    return request<never, GetIntelligentPartnerTaskInfosTargetByLessonIdParam, never>(
        '/intelligentPartnerTaskInfos/target/{lessonId}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerTaskInfosTargetByLessonId',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/target/{lessonId}',
        method: 'get',
    },
];
