import React, { FC, memo } from 'react';
import { Cascader, CascaderProps } from 'antd';
import 'antd/dist/antd.css';

type TemplateProps = {
    value?: any;
    onChanged?: (v: any) => void;
    attributes?: Partial<CascaderProps>;
    dataSource?: any[];
    disabled?: boolean;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChanged, attributes, dataSource, disabled } = props;

    return (
        <Cascader
            {...attributes}
            value={value}
            onChange={onChanged}
            disabled={disabled}
            options={dataSource || []}
            changeOnSelect
            fieldNames={{
                label: 'name',
                value: 'id',
                children: 'subs',
            }}
        />
    );
};

export default memo(Template);
