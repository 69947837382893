import React, { useState } from 'react';
import { Radio, Tabs, Input } from 'udesk-ui';

import Udesk from 'Udesk';
import Locales from 'UdeskLocales';

import Logic from './component/drag-component';
import ConditionLogic from './component/condition-drag-component';

/**
 *
 * @param isCondition 质检规则true， 不是质检规则false
 */
export default function LogicComponent(props) {
    const locales = Locales['current'];
    const { value = {}, onChange, isCondition = false, allowOperatorRuleTypes = Udesk.enums.operatorRuleTypes } = props;
    const {
        judgeStrategy: propsJudgeStrategy = Udesk.enums.operatorRuleTypes.all.id,
        customJudgeLogic: propsCustomJudgeLogic = '',
    } = value;
    const [operatorRuleTypes] = useState(allowOperatorRuleTypes.map((t) => ({ label: t.name, value: t.id })));
    // const [judgeStrategy, setJudgeStrategy] = useState(propsJudgeStrategy || Udesk.enums.operatorRuleTypes.all.id);
    // const [customJudgeLogic, setCustomJudgeLogic] = useState(propsCustomJudgeLogic??'');
    const changeJudgeStrategy = (e) => {
        let newValue = {
            judgeStrategy: e.target.value,
            customJudgeLogic: propsCustomJudgeLogic,
        };
        onChange?.(newValue);
    };
    const changeCustomJudgeLogicByInput = (e) => {
        let newValue = {
            judgeStrategy: propsJudgeStrategy,
            customJudgeLogic: e.target.value,
        };
        onChange?.(newValue);
    };
    const changeCustomJudgeLogicByDnd = (newCustomJudgeLogic) => {
        let newValue = {
            judgeStrategy: propsJudgeStrategy,
            customJudgeLogic: newCustomJudgeLogic,
        };
        onChange?.(newValue);
    };
    const isCustom = propsJudgeStrategy === Udesk.enums.operatorRuleTypes.custom.id;
    return (
        <div className={`udesk-qa-logic-component ${isCustom ? 'with-top-margin' : ''}`}>
            <Radio.Group onChange={changeJudgeStrategy} value={propsJudgeStrategy} options={operatorRuleTypes} />
            {isCustom && (
                <Tabs defaultActiveKey='2'>
                    <Tabs.TabPane tab={locales.components.common.customFilter.manualConfiguration} key='1'>
                        <Input
                            placeholder={locales.components.common.customFilter.holderCustomRule}
                            value={propsCustomJudgeLogic}
                            onChange={changeCustomJudgeLogicByInput}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={locales.components.common.customFilter.dragConfiguration} key='2'>
                        {isCondition ? (
                            <ConditionLogic
                                conditions={props.conditions}
                                customJudgeLogic={propsCustomJudgeLogic}
                                onChange={changeCustomJudgeLogicByDnd}
                            />
                        ) : (
                            <Logic
                                conditions={props.conditions}
                                customJudgeLogic={propsCustomJudgeLogic}
                                onChange={changeCustomJudgeLogicByDnd}
                            />
                        )}
                    </Tabs.TabPane>
                </Tabs>
            )}
        </div>
    );
}
