// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetLibraryFilesByIdParam,
    BaseResponseLibraryFileFoundResponse,
    PutLibraryFilesByIdParam,
    PutLibraryFilesByIdBody,
    BaseResponseVoid,
    DeleteLibraryFilesByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 案例库文件夹
 * @link [GET] /libraryFiles/{id}
 * @param id
 */
export function getLibraryFilesById(
    options: ApiOptions<GetLibraryFilesByIdParam, never> & { segments: GetLibraryFilesByIdParam }
): Promise<BaseResponseLibraryFileFoundResponse> {
    return request<never, GetLibraryFilesByIdParam, never>('/libraryFiles/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getLibraryFilesById',
    });
}

/**
 * update
 *
 * @export
 * @tags 案例库文件夹
 * @link [PUT] /libraryFiles/{id}
 * @param id
 */
export function putLibraryFilesById(
    data: PutLibraryFilesByIdBody,
    options: ApiOptions<PutLibraryFilesByIdParam, never> & { segments: PutLibraryFilesByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutLibraryFilesByIdBody, PutLibraryFilesByIdParam, never>('/libraryFiles/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putLibraryFilesById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 案例库文件夹
 * @link [DELETE] /libraryFiles/{id}
 * @param id
 */
export function deleteLibraryFilesById(
    options: ApiOptions<DeleteLibraryFilesByIdParam, never> & {
        segments: DeleteLibraryFilesByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteLibraryFilesByIdParam, never>('/libraryFiles/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteLibraryFilesById',
    });
}

export const meta = [
    { tags: ['案例库文件夹'], path: '/libraryFiles/{id}', method: 'get' },
    { tags: ['案例库文件夹'], path: '/libraryFiles/{id}', method: 'put' },
    { tags: ['案例库文件夹'], path: '/libraryFiles/{id}', method: 'delete' },
];
