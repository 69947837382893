import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';
import { CommonPage } from 'udesk_gm_ui';
import styled from 'styled-components';

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .role-detail-task-permissions-page,
    .role-detail-data-permissions-page {
        padding: 10px 16px 16px;
    }
    .role-detail-data-permissions-page-title {
        padding-bottom: 10px;
    }
`;
const AdminGongUser = React.memo((props: any) => {
    let { routes, route, location, sdkOptions, match } = props;
    const isRedirect = (currentPath, toPath) => {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: props.match.params,
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    };
    return (
        <CommonPage.PageInfoMemo>
            {isRedirect(route, location.pathname) ? (
                <Redirect
                    to={Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                        routeName: route.name,
                        pathParams: match.params,
                    })}
                    sdkOptions={sdkOptions}
                />
            ) : (
                <Wrap
                    className="udesk-qa-web-permission-management-center-pages"
                    style={{ height: '100%' }}
                >
                    {routes.map((routeItem, i) => (
                        <SubRoutes key={i} route={routeItem} />
                    ))}
                </Wrap>
            )}
        </CommonPage.PageInfoMemo>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongUser {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
