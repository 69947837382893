// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewIntelligentPartnerTestByRecordIdClearCacheQuery,
    GetReviewIntelligentPartnerTestByRecordIdClearCacheParam,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 清理节点配置缓存
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [GET] /review/intelligentPartner/test/{recordId}/clearCache
 * @param recordId
 */
export function getReviewIntelligentPartnerTestByRecordIdClearCache(
    options: ApiOptions<
        GetReviewIntelligentPartnerTestByRecordIdClearCacheParam,
        GetReviewIntelligentPartnerTestByRecordIdClearCacheQuery
    > & { segments: GetReviewIntelligentPartnerTestByRecordIdClearCacheParam } & {
        params: GetReviewIntelligentPartnerTestByRecordIdClearCacheQuery;
    }
): Promise<BaseResponseVoid> {
    return request<
        never,
        GetReviewIntelligentPartnerTestByRecordIdClearCacheParam,
        GetReviewIntelligentPartnerTestByRecordIdClearCacheQuery
    >('/review/intelligentPartner/test/{recordId}/clearCache', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewIntelligentPartnerTestByRecordIdClearCache',
    });
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/test/{recordId}/clearCache',
        method: 'get',
    },
];
