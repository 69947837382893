import Udesk from 'Udesk';
import React from 'react';
import { Dropdown, Menu, Select } from 'udesk-ui';
import { useWorkTimeContext } from '../../../Contxt';
import UdeskLocales from 'UdeskLocales';

export const ApplyTimeType = ({ conditionItem, selectMenuItem, applyTimeIdChange }) => {
    const { workTimes } = useWorkTimeContext();

    return (
        <>
            <div style={{ marginRight: 16, display: 'inline-block' }}>{/* 检测时间： */}{UdeskLocales['current'].components.checkPointConditionListGather.checkPointCondition.components.applyTimeType.index.detectionTime}<Dropdown
                overlay={
                    <Menu onClick={selectMenuItem}>
                        {Udesk.enums.applyTimeTypes.map((item) => {
                            return <Menu.Item key={item.id}>{item.name}</Menu.Item>;
                        })}
                    </Menu>
                }
                trigger={['click']}
            >
                <a
                    className="udesk-ui-dropdown-link"
                    style={{ color: '#1B6DFF' }}
                    onClick={(e) => e.preventDefault()}
                >
                    {Udesk.enums.applyTimeTypes.getName(conditionItem.applyTimeType)}
                    <span className="udesk-qa-react-web-iconfont">&#xe686;</span>
                </a>
            </Dropdown>
            </div>
            {conditionItem.applyTimeType === Udesk.enums.applyTimeTypes.workTime.id && (
                <Select onChange={applyTimeIdChange} options={workTimes} value={conditionItem.applyTimeId} style={{ width: 150 }} />
            )}
        </>
    );
};
