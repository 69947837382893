import React from 'react';
import {Button, Switch} from 'udesk-ui';

export const ApproveButton = (props) => {
    const {
        isApprove,
        isEdit,
        ...compProps
    } = props;

    return (
        <>
            {
                isApprove && !isEdit ? null : <Button {...compProps}/>
            }
        </>
    );
};

export const ApproveSwitch = (props) => {
    const {
        isApprove,
        isEdit,
        ...compProps
    } = props;

    return (
        <Switch disabled={isApprove && !isEdit} {...compProps}/>
    );
};