import { CustomerPortraitConfigProvider, useCustomerPortraitConfigContext } from './Context';
import { CustomerPortraitConfigDetail } from './Detail';
import { CustomerPortraitList } from './List';
import { CustomerPortraitSelect } from './Select';
import { useCustomerPortraitConfig } from './useCustomerPortraitConfig';

const InternalCustomerPortraitConfig = CustomerPortraitList;

type InternalCustomerPortraitConfigType = typeof InternalCustomerPortraitConfig & {
    CustomerPortraitConfigProvider: typeof CustomerPortraitConfigProvider;
    useCustomerPortraitConfigContext: typeof useCustomerPortraitConfigContext;
    useCustomerPortraitConfig: typeof useCustomerPortraitConfig;
    Detail: typeof CustomerPortraitConfigDetail;
    Create: typeof CustomerPortraitConfigDetail;
    Select: typeof CustomerPortraitSelect;
};

const CustomerPortraitConfig: InternalCustomerPortraitConfigType =
    InternalCustomerPortraitConfig as InternalCustomerPortraitConfigType;

CustomerPortraitConfig.CustomerPortraitConfigProvider = CustomerPortraitConfigProvider;
CustomerPortraitConfig.useCustomerPortraitConfigContext = useCustomerPortraitConfigContext;
CustomerPortraitConfig.useCustomerPortraitConfig = useCustomerPortraitConfig;
CustomerPortraitConfig.Detail = CustomerPortraitConfigDetail;
CustomerPortraitConfig.Create = CustomerPortraitConfigDetail;
CustomerPortraitConfig.Select = CustomerPortraitSelect;

export { CustomerPortraitConfig };
