import React, { useState, useCallback } from 'react';
import { Button, Table, Popconfirm, message, Space } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { inspect } from 'src/util/core';
import ColumnHeader from './columnHeader';
import ItemForm from './pageRightFrom';
import CallDetailModal from '../../../selling-point/components/callDetailModal';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const DelBtn = (props: { locales: any; onDelete: () => void }) => {
    const { onDelete, locales } = props;
    return (
        <Popconfirm
            title={
                /* 您确认要舍弃吗？舍弃后将不能再恢复 */ locales.pages.semanticIntelligence
                    .wordsMining.dissent.components.pageRightTable.index
                    .areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding
            }
            onConfirm={onDelete}
            onCancel={(e) => e?.stopPropagation()}
            okText={locales.labels.confirm}
            placement="bottom"
            cancelText={locales.labels.cancel}
        >
            <Button size="small">
                {/* 舍弃 */}
                {
                    locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable
                        .index.abandonment
                }
            </Button>
        </Popconfirm>
    );
};

export default React.memo((props: any) => {
    const {
        scenario,
        selectedRowKey,
        selectClusterId,
        subjectName,
        locales,
        history,
        judgeStrategy,
        conditionList,
        customJudgeLogic,
        setJudgeStrategy,
        setConditionList,
        setCustomJudgeLogic,
        rightPagePagination: pagination,
        setRightPagePagination: setPagination,
    } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [groupDataSource, setGroupDataSource] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState();
    const [visible, setVisible] = useState(false);
    const request = useRequest();

    const delHandle = useCallback(
        (id) => {
            let { current, pageSize, total } = pagination;
            request(`/review/clusterResult/${id}`, {}, 'del').then((r) => {
                message.success(
                    /* 删除成功 */ locales.pages.semanticIntelligence.wordsMining.dissent.components
                        .pageRightTable.index.deletionSucceeded
                );
                if (current > 1) {
                    if ((current - 1) * pageSize >= total - 1) {
                        current--;
                    } else {
                        setData((list) => {
                            return list.filter((item) => item.key !== id);
                        });
                    }
                    setPagination({
                        current,
                        pageSize,
                        total: total - 1,
                    });
                } else {
                    setData((list) => {
                        return list.filter((item) => item.key !== id);
                    });
                }
            });
        },
        [locales, pagination, request, setPagination]
    );

    const changeEditStatus = useCallback((item, index, flag) => {
        setData((data) => {
            const list = [...data];
            if (list[index] === item) {
                item.isEdit = flag;
            }
            return list;
        });
    }, []);

    const renderCell = useCallback(
        (text, record, index) => {
            return (
                <div className="page-right-table-item">
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <div>
                            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                                {inspect(
                                    record.isEdit,
                                    () => (
                                        <div className="message">
                                            <ItemForm
                                                locales={locales}
                                                groupDataSource={groupDataSource}
                                                scenario={scenario}
                                                request={request}
                                                data={record}
                                                successCallback={() => {
                                                    changeEditStatus(record, index, false);
                                                }}
                                            />
                                        </div>
                                    ),
                                    () => (
                                        <>
                                            <div className="message">
                                                <span className="user-type customer">
                                                    {/* 客 */}
                                                    {
                                                        locales.pages.semanticIntelligence
                                                            .wordsMining.dissent.components
                                                            .pageRightTable.index.passenger
                                                    }
                                                </span>
                                                <p>{record.question}</p>
                                            </div>
                                            <div className="message">
                                                <span className="user-type">
                                                    {/* 坐 */}
                                                    {
                                                        locales.pages.semanticIntelligence
                                                            .wordsMining.dissent.components
                                                            .pageRightTable.index.sit
                                                    }
                                                </span>
                                                {record.answer.length > 200 ? (
                                                    <p title={record.answer}>
                                                        {record.answer.slice(0, 200)}...
                                                    </p>
                                                ) : (
                                                    <p>{record.answer}</p>
                                                )}
                                            </div>
                                        </>
                                    )
                                )}
                            </Space>
                        </div>
                        <Space className="toolkit-button">
                            {inspect(
                                record.accept,
                                () => (
                                    <Button size="small" type="primary">
                                        {/* 已采纳 */}
                                        {
                                            locales.pages.semanticIntelligence.wordsMining.dissent
                                                .components.pageRightTable.index.adopted
                                        }
                                    </Button>
                                ),
                                () =>
                                    inspect(
                                        record.isEdit,
                                        () => (
                                            <>
                                                <Button
                                                    onClick={() => record.form?.submit()}
                                                    size="small"
                                                    type="primary"
                                                >
                                                    {/* 确定 */}
                                                    {
                                                        locales.pages.semanticIntelligence
                                                            .wordsMining.dissent.components
                                                            .pageRightTable.index.determine
                                                    }
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        changeEditStatus(record, index, false)
                                                    }
                                                    size="small"
                                                >
                                                    {/* 取消 */}
                                                    {
                                                        locales.pages.semanticIntelligence
                                                            .wordsMining.dissent.components
                                                            .pageRightTable.index.cancel
                                                    }
                                                </Button>
                                            </>
                                        ),
                                        () => (
                                            <>
                                                <Button
                                                    size="small"
                                                    type="link"
                                                    onClick={() => {
                                                        setVisible(true);
                                                        setSelectedRow(record);
                                                    }}
                                                >
                                                    {/* 查看详情 */}
                                                    {
                                                        UdeskLocales['current'].pages
                                                            .semanticIntelligence.wordsMining
                                                            .dissent.components.pageRightTable.index
                                                            .viewDetails
                                                    }
                                                </Button>
                                                <DelBtn
                                                    locales={locales}
                                                    onDelete={() => delHandle(record.id)}
                                                />
                                                <Button
                                                    onClick={() =>
                                                        changeEditStatus(record, index, true)
                                                    }
                                                    size="small"
                                                    type="primary"
                                                >
                                                    {/* 采纳 */}
                                                    {
                                                        locales.pages.semanticIntelligence
                                                            .wordsMining.dissent.components
                                                            .pageRightTable.index.adopt
                                                    }
                                                </Button>
                                            </>
                                        )
                                    )
                            )}
                        </Space>
                    </Space>
                </div>
            );
        },
        [groupDataSource, scenario, request, locales, changeEditStatus, delHandle]
    );

    const requestTableSource = useCallback(
        (success) => {
            if (selectedRowKey) {
                let url = `review/clusterResult/${selectedRowKey}`;
                let params = {
                    scenario,
                    pageNum: pagination.current,
                    pageSize: pagination.pageSize,
                };
                setLoading(true);
                request(url, params)
                    .then((r) => {
                        success(() => {
                            setData(
                                r.data.map((item) => ({
                                    ...item,
                                    key: item.id,
                                    isEdit: false,
                                    clusterId: selectClusterId,
                                }))
                            );
                            setPagination({
                                ...pagination,
                                total: r.paging.total,
                            });
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                setData([]);
                setPagination({
                    ...pagination,
                    total: 0,
                });
            }
        },
        [scenario, pagination.current, pagination.pageSize, selectedRowKey, selectClusterId]
    );

    useLiveEffect(
        (success) => {
            const url = `/review/words/favorite/${scenario}`;
            request(url, {
                pageNum: 1,
                pageSize: 99999,
            }).then(({ data }) => {
                success(() => {
                    data.forEach((item) => (item.key = item.id));
                    setGroupDataSource(data);
                });
            });
        },
        [scenario]
    );

    useLiveEffect(requestTableSource, [requestTableSource]);

    return (
        <div className="words-mining-dissent-page-right">
            <CallDetailModal
                selectedRow={selectedRow}
                request={request}
                visible={visible}
                setVisible={setVisible}
            />
            <Table
                scroll={{
                    y: 'calc(100% - 50px)',
                }}
                loading={loading}
                rowSelection={{
                    columnTitle: (
                        <ColumnHeader
                            {...{
                                history,
                                locales,
                                subjectName,
                                total: pagination.total,
                                selectedRowKey,
                                judgeStrategy,
                                conditionList,
                                customJudgeLogic,
                                setJudgeStrategy,
                                setConditionList,
                                setCustomJudgeLogic,
                                onFilterComplete: requestTableSource,
                            }}
                        />
                    ),
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    ...pagination,
                    hideOnSinglePage: false,
                    onChange: (current) => {
                        setPagination({ ...pagination, current });
                    },
                }}
                columns={[]}
                dataSource={data}
            />
        </div>
    );
});
