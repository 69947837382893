import React from 'react';
import Udesk from 'Udesk';
import _throttle from 'lodash-es/throttle';
// import Driver from 'driver.js';
// import 'driver.js/dist/driver.min.css';

class QualityCheckScoreTalkComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        type: null,
        saving: false,
        task: {},
        hasFeature: null,
        pointCategoryList: [],
        isDemo: false,
        pointWidth: '100%',
    };
    //#endregion

    state = {};
    privates = {
        task: {},
        pointCategoryList: [],
        selectedCategory: {},
        visible: false,
        firstCategoryNode: {},
        dropValue: null,
        prointSet: {},
        pointFlag: null,
        pointChildrenPerson: {},
        scoreTalkRef: React.createRef(),
        smartRulesFlag: false,
        qualtiyBodyRef: React.createRef(),
        menuSelectId: null,
        activeFlag: null,
        macValue: null,
        xhr: {},
        showKeys: [],
        isInformationCollect: false,
        complainPointList: [],
        complainPointIdMap: {},
        isPopoverVisible: false,
    };
    static computes = {};
    actions = {
        selectedCategory(category, id) {
            let audoView = document.getElementById(category);
            if (audoView) {
                audoView.scrollIntoViewIfNeeded();
            } else {
                return;
            }
            this.privates.menuSelectId = id;
            this.actions.update();
        },
        changeTaskRemark(event) {
            let { task } = this.privates;
            task.remark = event.target.value;

            task.remarkChanged = true;
            this.privates.remark = event.target.value;
            let { type } = this.props;
            this.trigger('onSave', this.privates.pointCategoryList, this.privates.task, type);
            this.actions.update();
        },
        changePointNaChecked(point) {
            if (this.props.isTest) return;
            if (this.props.showAppealButton) return;
            let { task, pointCategoryList } = this.privates;
            if (point.naChecked) {
                point.naChecked = 0;
                point.gradeChooseHit = 0;
            } else {
                point.naChecked = 1;
                point.gradeChooseHit = null;
                point.gradeInput = null;
            }
            point.isChanged = true;
            Udesk.business.scoreCompute.computeScore(task, pointCategoryList);
            let { type } = this.props;
            this.trigger('onSave', this.privates.pointCategoryList, this.privates.task, type);
            this.actions.update();
        },
        changePointGradeChooseHit(point, value) {
            value.stopPropagation();
            if (this.props.isTest) return;
            if (this.props.showAppealButton) return;
            let { task, pointCategoryList } = this.privates;
            if (point.type === Udesk.enums.pointTypes.machineLearning.id) {
                if (
                    (point.gradeType === Udesk.enums.strategyTypes.hitAdd.id ||
                        point.gradeType === Udesk.enums.strategyTypes.hitSub.id) &&
                    value.target.value === 0
                ) {
                    this.privates.pointFlag = null;
                    this.props.heightHit && this.props.heightHit(false, []);
                } else if (
                    (point.gradeType === Udesk.enums.strategyTypes.hitNotAdd.id ||
                        point.gradeType === Udesk.enums.strategyTypes.hitNotSub.id) &&
                    value.target.value === 1
                ) {
                    this.privates.pointFlag = null;
                    this.props.heightHit && this.props.heightHit(false, []);
                }
            }
            point.gradeChooseHit = value.target.value;
            point.isChanged = true;
            Udesk.business.scoreCompute.computeScore(task, pointCategoryList);
            let { type } = this.props;
            this.trigger('onSave', this.privates.pointCategoryList, this.privates.task, type);
            this.actions.update();
        },
        changeFlowNodeGradeChooseHit(point, newCanvasNodeMatchResultList, e) {
            e?.stopPropagation?.();
            if (this.props.isTest) return;
            if (this.props.showAppealButton) return;
            let { task, pointCategoryList } = this.privates;
            // node.isHit = value?.target?.value ?? value;
            point.isChanged = true;
            const newScore = newCanvasNodeMatchResultList.reduce((prev, c) => {
                if (typeof c.score !== 'number') return prev;
                if (!c.isHit) return prev;
                return c.scoreType === 2 ? prev - c.score : prev + c.score;
            }, 0);
            point.predeterminedScore = Math.abs(newScore);
            point.scoreType = newScore < 0 ? 2 : 1;
            console.log('point.predeterminedScore', point.predeterminedScore);
            console.log('pointCategoryList', pointCategoryList);
            point.canvasNodeMatchResultList = newCanvasNodeMatchResultList;
            point.gradeChooseHit = point.canvasNodeMatchResultList.some((c) => c.isHit) ? 1 : 0;

            Udesk.business.scoreCompute.computeScore(task, pointCategoryList);

            console.log('changeFlowNodeGradeChooseHit point', point);
            let { type } = this.props;
            this.trigger('onSave', this.privates.pointCategoryList, this.privates.task, type);
            this.actions.update();
        },
        changeGradeChooseOption(point, value) {
            if (this.props.isTest) return;
            if (this.props.showAppealButton) return;
            let { task, pointCategoryList } = this.privates;
            const target = point.gearOptionList.find((item) => item.id === value);
            point.gradeChooseOption = target.gradeChooseOption;
            point.pointHitValue = target.id;
            // point.pointHitName = target.name;
            point.isChanged = true;
            Udesk.business.scoreCompute.computeScore(task, pointCategoryList);
            let { type } = this.props;
            this.trigger('onSave', this.privates.pointCategoryList, this.privates.task, type);
            this.actions.update();
        },
        changePointGradeInput(point, value) {
            if (this.props.isTest) return;
            if (this.props.showAppealButton) return;
            let { task, pointCategoryList } = this.privates;
            point.gradeInput = value;
            point.isChanged = true;
            Udesk.business.scoreCompute.computeScore(task, pointCategoryList);
            let { type } = this.props;
            this.trigger('onSave', this.privates.pointCategoryList, this.privates.task, type);
            this.actions.update();
        },
        passComplain(point) {
            if (this.props.showAppealButton) return;
            if (point.isAllApprove) {
                point.allPoint.forEach((point) => {
                    point.pointComplaintInfo.complainStatus = Udesk.enums.complainStatus.success.id;
                    point.pointComplaintInfo.rejection = '';
                });
            } else {
                point.pointComplaintInfo.complainStatus = Udesk.enums.complainStatus.success.id;
                point.pointComplaintInfo.rejection = '';
            }
            let { type } = this.props;
            this.trigger('onSave', this.privates.pointCategoryList, this.privates.task, type);
            this.actions.update();
        },
        changePointRejectReason(point, value) {
            if (this.props.showAppealButton) return;
            if (point.isAllApprove) {
                point.allPoint.forEach((point) => {
                    point.pointComplaintInfo.complainStatus = Udesk.enums.complainStatus.fail.id;
                    point.pointComplaintInfo.rejection = value;
                });
            } else {
                point.pointComplaintInfo.complainStatus = Udesk.enums.complainStatus.fail.id;
                point.pointComplaintInfo.rejection = value;
            }
            let { type } = this.props;
            this.trigger('onSave', this.privates.pointCategoryList, this.privates.task, type);
            this.actions.update();
        },
        interactivePoint(point, visible) {
            // e.stopPropagation();
            if (!point.id) {
                return;
            }
            point.isInteractivePointVisible = visible;
            this.actions.update();
            // if (point.isInteractivePointVisible) {
            //     point.isInteractivePointVisible = false;
            //     this.actions.update();
            //     return;
            // } else {
            //     point.isInteractivePointVisible = true;
            // }
            let { sdkOptions, task, isDemo, isTest } = this.props;
            let url = '';
            if (isDemo) {
                url = `/api/demo/inspectionData/${task.id}/interactivePointInspectMatch?dataPointId=${point.id}`;
            } else if (isTest) {
                url = Udesk.business.apiPath.concatApiPath(
                    `inspectionData/${task.id}/interactivePointInspectMatchForTest?dataPointId=${point.id}`,
                    sdkOptions
                );
            } else {
                url = Udesk.business.apiPath.concatApiPath(
                    `inspectionData/${task.id}/interactivePointInspectMatch?dataPointId=${point.id}`,
                    sdkOptions
                );
            }
            if (point.interactiveResult || this.privates.xhr[point.id]) return;
            let ajax = Udesk.ajax.get(url);
            this.privates.xhr[point.id] = ajax;
            ajax.then(
                (resp) => {
                    point.interactiveResult = resp.data;
                    this.privates.xhr[point.id] = null;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.xhr[point.id] = null;
                    this.actions.update();
                }
            );
        },
        // mouseLeaveInteractiveContainer(point) {
        //     point.isInteractivePointVisible = false;
        //     this.actions.update();
        // },
        hitCheckPointSelected(checkPoint, e) {
            this.trigger('audioStop', true);
            e.stopPropagation();
            this.trigger('triggerHighLight', checkPoint);
            // this.trigger('onHitCheckPointSelected', checkPoint);
        },
        cancel() {
            this.trigger('onCancel');
        },
        save() {
            let { task, pointCategoryList } = this.privates;
            let { type } = this.props;
            this.trigger('onSave', pointCategoryList, task, type);
        },
        submit() {
            let { task, pointCategoryList } = this.privates;
            this.trigger('onSubmit', pointCategoryList, task);
        },
        rapidSampling() {
            let { task, pointCategoryList } = this.privates;
            let { type } = this.props;
            this.trigger('onRapidSampling', pointCategoryList, task, type);
        },
        formatTotalScore(data) {
            let scoreSting = '';
            if (data._minScore !== 0) scoreSting += data._minScore + '~';
            if (data._maxScore !== 0) scoreSting += data._maxScore;
            if (scoreSting === '') scoreSting = '0';

            return scoreSting.replace(/~$/, '');
        },
        drawerVisibleTrue(falg, id, e) {
            e?.stopPropagation?.();
            this.privates.visible = falg;
            if (falg) {
                if (typeof id === 'number' || typeof id === 'string') {
                    this.privates.menuSelectId = id;
                }
            }
            this.actions.update();
        },
        dropChanged: _throttle((value) => {
            this.privates.dropValue = value;
            this.actions.update();
        }, 300),
        collection(item, value) {
            let { prointSet } = this.privates;
            if (prointSet[item.ids]) {
                let obj = prointSet[item.ids].find((current) => current.id === value.id);
                if (obj) {
                    return Udesk.ui.notify.error(this.locales.fix.repeatMarkingTip);
                } else {
                    prointSet[item.ids].push({ name: item.name, id: value.id });
                }
            } else {
                prointSet[item.ids] = [{ name: item.name, id: value.id }];
            }
            let data = {
                id: item.ids,
                name: item.name,
            };
            if (this.privates.pointChildrenPerson[value.id]) {
                this.privates.pointChildrenPerson[value.id].push(data);
            } else {
                this.privates.pointChildrenPerson = {
                    ...this.privates.pointChildrenPerson,
                    [value.id]: [data],
                };
            }
            this.props.handMarkMap &&
                this.props.handMarkMap(prointSet, this.privates.pointChildrenPerson);
            this.actions.update();
        },
        pointEdit(point, field) {
            if (
                point.type === Udesk.enums.pointTypes.machineLearning.id &&
                (point.gradeChooseHit === null ||
                    ((point.gradeType === Udesk.enums.strategyTypes.hitAdd.id ||
                        point.gradeType === Udesk.enums.strategyTypes.hitSub.id) &&
                        point.gradeChooseHit === 0) ||
                    ((point.gradeType === Udesk.enums.strategyTypes.hitNotAdd.id ||
                        point.gradeType === Udesk.enums.strategyTypes.hitNotSub.id) &&
                        point.gradeChooseHit === 1))
            ) {
                return;
            }
            let { prointSet } = this.privates;
            if (field === point.pointId) {
                this.trigger('audioPlay');
                this.privates.pointFlag = null;
                this.props.heightHit && this.props.heightHit(false, []);
            } else {
                this.trigger('audioStop');
                if (prointSet[point.pointId]) {
                    this.props.heightHit &&
                        this.props.heightHit(
                            { id: point.pointId, name: point.name },
                            prointSet[point.pointId]
                        );
                } else {
                    this.props.heightHit &&
                        this.props.heightHit({ id: point.pointId, name: point.name }, []);
                }
                this.privates.pointFlag = point.pointId;
            }
            this.actions.update();
        },
        openMap(data) {
            let list = [];
            if (data && data.length > 0) {
                data.forEach((item, index) => {
                    if (item.children && item.children.length > 0) {
                        list.push(`${item.id}`);
                    }
                });
            }
            return list;
        },
        activeChange(flag) {
            let { sdkOptions } = this.props;
            let activeValue = null;
            let { task } = this.privates;
            if (flag === 'true') {
                this.privates.activeFlag = 'true';
                activeValue = '1';
            } else if (flag === 'false') {
                this.privates.activeFlag = 'false';
                activeValue = '2';
            }

            let url1 = Udesk.business.apiPath.concatApiPath(
                `call/goodOrBad/${task.callId}?goodBadFlag=${activeValue}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url1).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(this.locales.fix.successfulEvaluation);
                        this.trigger('evaluateChange', true);
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(this.locales.fix.evaluationFailed);
                        reject(reason);
                    }
                );
            });
        },
        onSubmitClick({ complainType, complainReason, pointId }) {
            let complainPointList = this.privates.complainPointList.filter(
                (i) => i.pointId !== pointId
            );
            complainPointList.push({ complainType, complainReason, pointId });
            this.privates.complainPointList = complainPointList;
            this.privates.complainPointIdMap = complainPointList.reduce((ret, current) => {
                ret[current.pointId] = current;
                return ret;
            }, {});
            this.props.onComplainPointListChange?.(complainPointList);
            this.actions.update();
        },
        onCancelClick(pointId) {
            let complainPointList = this.privates.complainPointList.filter(
                (i) => i.pointId !== pointId
            );
            this.privates.complainPointList = complainPointList;
            this.privates.complainPointIdMap = complainPointList.reduce((ret, current) => {
                ret[current.pointId] = current;
                return ret;
            }, {});
            this.props.onComplainPointListChange?.(complainPointList);
            this.actions.update();
        },
        onVisibleChange(isPopoverVisible) {
            this.privates.isPopoverVisible = isPopoverVisible;
            this.actions.update();
        },
        isAllApprove(points, success, fail = () => {}) {
            const isArray = Array.isArray(points);
            const point = isArray
                ? points.find((point) => !!point.pointComplaintInfo?.firstComplaintInfo) ||
                  points.find(
                      (point) =>
                          point.pointComplaintInfo?.complainStatus ===
                          Udesk.enums.complainStatus.success.id
                  ) ||
                  points[0]
                : points;

            if (point) {
                if (point.pointComplaintInfo) {
                    point.isAllApprove = point.pointComplaintInfo.complainSubmitType === 'CALL';
                    if (point.isAllApprove) {
                        if (isArray) {
                            point.allPoint = points;
                        }
                        return success(point);
                    }
                }
                return fail(point);
            }
        },
        isApprove(point, success) {
            return this.actions.isAllApprove(point, () => {}, success);
        },
        isAllComplain(allPointId, success, fail) {
            // 只有存在质检规则， 并且是语音通话，才能进行申诉全部的操作
            if (
                allPointId &&
                this.privates.task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id
            ) {
                const list = this.privates.complainPointList;
                if (list) {
                    if (
                        list.length === 0 ||
                        list.some((item) => typeof item.pointId === 'string')
                    ) {
                        return success(allPointId);
                    }
                }
            }
            return fail;
        },
        isComplain(success, fail) {
            const list = this.privates.complainPointList;
            if (list) {
                if (list.length === 0 || !list.some((item) => typeof item.pointId === 'string')) {
                    return success;
                }
            }
            return fail;
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps, isInitial }) {
        let data = {
            task: props.task,
            remark: props.caseBaseRemark,
            pointCategoryList: props.pointCategoryList,
            pointChildrenPerson: props.pointChildrenPerson,
        };
        if (data.task.remark == null) data.task.remark = '';
        if (data.task.evaluateDto) {
            if (data.task.evaluateDto.handValue) {
                if (data.task.evaluateDto.handValue === '1') {
                    data.activeFlag = 'true';
                } else if (data.task.evaluateDto.handValue === '2') {
                    data.activeFlag = 'false';
                }
            }
            if (data.task.evaluateDto.machineValue) {
                data.macValue = data.task.evaluateDto.machineValue;
            }
        }
        data.task._minScore = 0;
        data.task._maxScore = 0;
        if (props.pointCategoryList && props.pointCategoryList.length > 0) {
            // 计算各级分值
            for (let i = 0; i < data.pointCategoryList.length; i++) {
                let firstCategory = data.pointCategoryList[i];
                firstCategory._minScore = 0;
                firstCategory._maxScore = 0;

                if (firstCategory.children && firstCategory.children.length > 0) {
                    for (let j = 0; j < firstCategory.children.length; j++) {
                        let secondaryCategory = firstCategory.children[j];
                        // 计算各个二级分值
                        Udesk.business.scoreCompute.computeSecondaryCategoryLimitScore(
                            secondaryCategory
                        );

                        // 累计最大值与最小值
                        firstCategory._minScore += secondaryCategory._minScore;
                        firstCategory._maxScore += secondaryCategory._maxScore;
                    }
                }

                // 计算一级最终分值
                Udesk.business.scoreCompute.computeFinalLimitScore(firstCategory);

                // 累计质检任务的最大值与最小值
                data.task._minScore += firstCategory._minScore;
                data.task._maxScore += firstCategory._maxScore;
            }
        }
        if (isInitial) {
            this.privates.isInformationCollect =
                this.props.sdkOptions.props?.task?.taskType ===
                Udesk.enums.taskType.informationCollection.id;
            // data.showKeys = ['score', 'semanticTags', 'intelligentTags'];
            data.showKeys = ['score', 'semanticTags', 'keywords', 'extraction', 'businessSummary'];
            if (this.props.isReview || this.props.isTest) {
                data.showKeys = ['score', 'keywords', 'extraction', 'businessSummary'];
            }
            if (this.props.showAppealButton) {
                data.showKeys = ['score', 'appealHistory'];
            }
            if (this.privates.isInformationCollect) {
                data.showKeys = ['collection'];
            }
        }
        if (props.resetComplainPointIdMap !== prevProps.resetComplainPointIdMap) {
            data.complainPointIdMap = {};
        }

        return data;
    }
    componentDidMount() {
        // const isHit = (point) => point.gradeChooseHit === 1 || (Boolean(point.gradeChooseOption) && parseFloat(point.gradeChooseOption) !== 0);
        if (this.props.pointCategoryList && this.privates.pointCategoryList.length > 0) {
            this.privates.pointFlag = null;
            this.privates.prointSet = {};
            if (this.props.pointCategoryList && this.props.pointCategoryList.length > 0) {
                this.props.pointCategoryList.forEach((item) => {
                    if (item.children) {
                        item.children.forEach((items) => {
                            if (items.pointDataList) {
                                items.pointDataList.forEach((itemss) => {
                                    // itemss._isHit = isHit(itemss);
                                    if (itemss.canvasNodeMatchResultList) {
                                        itemss.canvasNodeMatchResultList.forEach((result) => {
                                            if (result.nodeType === 'BRANCH' && result.isHit) {
                                                const parentNode =
                                                    itemss.canvasNodeMatchResultList.find(
                                                        (i) =>
                                                            i.nodeIndex === result.preIndexList[0]
                                                    );
                                                if (parentNode) {
                                                    parentNode.isHit = true;
                                                }
                                            }
                                        });
                                    }
                                    if (itemss.handMarkHighlightSeqMap) {
                                        if (itemss.handMarkHighlightSeqMap[1]) {
                                            this.privates.prointSet = {
                                                ...this.privates.prointSet,
                                                [itemss.pointId]:
                                                    itemss.handMarkHighlightSeqMap[1].map(
                                                        (itemsss) => {
                                                            return {
                                                                name: itemss.name,
                                                                id: itemsss,
                                                            };
                                                        }
                                                    ),
                                            };
                                        }
                                    }
                                });
                                // items._isHit = items.pointDataList.some(i => i._isHit);
                            }
                        });
                        // item._isHit = item.children.some(i => i._isHit);
                    }
                });
            }
            if (
                this.props.pointCategoryList[0].children &&
                this.props.pointCategoryList[0].children.length > 0
            ) {
                this.privates.selectedCategory = this.props.pointCategoryList[0].children[0];
                this.privates.firstCategoryNode = this.props.pointCategoryList[0];
            }
            this.actions.update();
        }

        // const status = localStorage.getItem('has_finished_quality_inspection_detail_driver');
        // if(status === 'true' || this.props.isInformationCollect) return;
        // const driver = new Driver({
        //     allowClose: false,
        //     nextBtnText: this.locales.labels.nextStep,
        //     prevBtnText: this.locales.labels.prevStep,
        //     closeBtnText: this.locales.labels.closeDriver,
        //     doneBtnText: this.locales.labels.complete,
        //     onReset : ( Element ) => {
        //         localStorage.setItem(
        //             'has_finished_quality_inspection_detail_driver',
        //             'true'
        //         );
        //     },
        // });
        // driver.defineSteps([
        //     // {
        //     //     element: '#udesk-qa-first-element-introduction',
        //     //     popover: {
        //     //         // className: 'first-step-popover-class',
        //     //         title: '功能变更引导',
        //     //         description: '您可以点击此处对本次沟通进行评价批注。',
        //     //         position: 'left'
        //     //     }
        //     // },
        //     {
        //         element: '#udesk-qa-second-element-introduction-zhinengbiaoqian',
        //         popover: {
        //             title: '功能变更引导',
        //             description: '点击此处，查看本次沟通标记的标签内容。',
        //             position: 'top'
        //         }
        //     },
        //     {
        //         element: '#udesk-qa-second-element-introduction-yuyibiaoqian',
        //         popover: {
        //             title: '功能变更引导',
        //             description: '点击此处，再点击沟通的单句话术，可以对语音标签进行随时训练。',
        //             position: 'top'
        //         }
        //     },
        //     // {
        //     //   element: '#third-element-introduction',
        //     //   popover: {
        //     //     title: '功能变更引导',
        //     //     description: 'Body of the popover',
        //     //     position: 'right'
        //     //   }
        //     // },
        // ]);
        // driver.start();
        // const refresh = () => {
        //     setTimeout(() => {
        //         if(!document.getElementById('udesk-qa-second-element-introduction-zhinengbiaoqian')){
        //             refresh();
        //         }
        //     }, 200);
        // };
        // refresh();
    }
    componentWillUnmount() {}
    asyncModel(changedParams) {}
    componentDidUpdate(prevProps) {
        if (this.props.dropChed !== prevProps.dropChed) {
            this.privates.dropValue = null;
            this.actions.update();
        }
        if (
            this.props.pointCategoryList !== prevProps.pointCategoryList ||
            this.props.resetStatus !== prevProps.resetStatus
        ) {
            this.privates.pointFlag = null;
            this.privates.prointSet = {};
            if (this.props.pointCategoryList && this.props.pointCategoryList.length > 0) {
                this.props.pointCategoryList.forEach((item) => {
                    if (item.children) {
                        item.children.forEach((items) => {
                            if (items.pointDataList) {
                                items.pointDataList.forEach((itemss) => {
                                    if (itemss.handMarkHighlightSeqMap) {
                                        if (itemss.handMarkHighlightSeqMap[1]) {
                                            this.privates.prointSet = {
                                                ...this.privates.prointSet,
                                                [itemss.pointId]:
                                                    itemss.handMarkHighlightSeqMap[1].map(
                                                        (itemsss) => {
                                                            return {
                                                                name: itemss.name,
                                                                id: itemsss,
                                                            };
                                                        }
                                                    ),
                                            };
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
            this.actions.update();
        }
        if (this.props.checkedMap !== prevProps.checkedMap) {
            if (this.props.checkedMap) {
                if (this.props.checkedMap.flag) {
                    if (this.privates.prointSet[this.props.checkedMap.pointId]) {
                        this.privates.prointSet[this.props.checkedMap.pointId].push({
                            id: this.props.checkedMap.id,
                            name: this.props.checkedMap.name,
                        });
                    } else {
                        this.privates.prointSet = {
                            ...this.privates.prointSet,
                            [this.props.checkedMap.pointId]: [
                                {
                                    id: this.props.checkedMap.id,
                                    name: this.props.checkedMap.name,
                                },
                            ],
                        };
                    }
                    if (this.privates.pointChildrenPerson[this.props.checkedMap.id]) {
                        this.privates.pointChildrenPerson[this.props.checkedMap.id].push({
                            id: this.props.checkedMap.pointId,
                            name: this.props.checkedMap.name,
                        });
                    } else {
                        this.privates.pointChildrenPerson = {
                            ...this.privates.pointChildrenPerson,
                            [this.props.checkedMap.id]: [
                                {
                                    id: this.props.checkedMap.pointId,
                                    name: this.props.checkedMap.name,
                                },
                            ],
                        };
                    }
                    this.props.handMarkMap &&
                        this.props.handMarkMap(
                            this.privates.prointSet,
                            this.privates.pointChildrenPerson
                        );
                } else {
                    if (this.privates.prointSet[this.props.checkedMap.pointId]) {
                        this.privates.prointSet[this.props.checkedMap.pointId] =
                            this.privates.prointSet[this.props.checkedMap.pointId].filter(
                                (item) =>
                                    parseInt(item.id, 10) !== parseInt(this.props.checkedMap.id, 10)
                            );
                    }
                    if (this.privates.pointChildrenPerson[this.props.checkedMap.id]) {
                        this.privates.pointChildrenPerson[this.props.checkedMap.id] =
                            this.privates.pointChildrenPerson[this.props.checkedMap.id].filter(
                                (item) =>
                                    parseInt(item.id, 10) !==
                                    parseInt(this.props.checkedMap.pointId, 10)
                            );
                    }
                    this.props.handMarkMap &&
                        this.props.handMarkMap(
                            this.privates.prointSet,
                            this.privates.pointChildrenPerson
                        );
                }
                this.props.heightHit &&
                    this.props.heightHit(
                        {
                            id: this.props.checkedMap.pointId,
                            name: this.props.checkedMap.name,
                        },
                        this.privates.prointSet[this.props.checkedMap.pointId]
                    );
            }
            this.actions.update();
        }
    }
    //#endregion
}

export default QualityCheckScoreTalkComponent;
