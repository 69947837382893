export default {
  lang: {
    confirm: {
      title:
        'Adakah anda mengesahkan bahawa anda mahu memadam? Tidak dapat dipulihkan selepas penghapusan',
      okText: 'Padamkan',
      cancelText: 'Batalkan',
    },
  },
};
