import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import UdeskLocales from 'UdeskLocales';

class trainingCenter extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { route, location, sdkOptions } = this.props;

        // let { locales } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        //&& Udesk.data.init.user.hasFeature("smart:tag:root")
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('sys:module:list:root')) {
            navLists.push({
                text: /* 亚马逊电商 */ UdeskLocales['current'].pages.admin.customDataSource
                    .amazonEcommerce,
                pathName: 'amazonListIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/amazon');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('sys:module:list:root')) {
            navLists.push({
                text: /* Youtube视频 */ UdeskLocales['current'].pages.admin.customDataSource
                    .youtubeVideo,
                pathName: 'youtubeListIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/youtube');
                },
            });
        }
        if (
            sdkOptions.props.landingPath != null &&
            this.isRedirect(route.path, location.pathname)
        ) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = null;
            if (navLists[0].pathName) {
                toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                    routeName: navLists[0].pathName,
                });
            } else {
                toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                    routeName: navLists[0].children[0].pathName,
                });
            }

            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="semantic-intelligence">
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={UdeskLocales['current'].pages.home.index.customDataSource}
                    />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(trainingCenter);
