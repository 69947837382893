import React from 'react';
// import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs } from 'udesk-ui';
import TemplateComponentsListRoute from './route';
// import UdeskTable from 'Component/common/udesk-table';
import { PageHeader, Modal, Input, Button, Form, Table } from 'udesk-ui';
import './style.scss';
import UdeskLocales from 'UdeskLocales';
// const { TabPane } = Tabs;
// const { Option } = Select;
export default class TemplateComponentsListTemplate extends TemplateComponentsListRoute {
    render() {
        let { targetItems, tableData, paging, sourceUrl, disabled, modalVisiable, activeItem } =
            this.privates;
        let { actions, locales } = this;

        return (
            <Page
                pageBodyClassName="client-center-root-page-index"
                title={
                    /* Youtube视频 */ UdeskLocales['current'].pages.admin.customDataSource.youtube
                        .template.youtubeVideo
                }
                padding={true}
                footer={
                    <Tabs onChange={actions.sourceTypeChange}>
                        <Tabs.TabPane
                            tab={
                                /* 本品 */ UdeskLocales['current'].pages.admin.customDataSource
                                    .youtube.template.thisProduct
                            }
                            key="1"
                        ></Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                /* 竞品 */ UdeskLocales['current'].pages.admin.customDataSource
                                    .youtube.template.competition
                            }
                            key="2"
                        ></Tabs.TabPane>
                    </Tabs>
                }
            >
                <div className="udesk-qa-web-page">
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={
                            /* Youtube视频 */ UdeskLocales['current'].pages.admin.customDataSource
                                .youtube.template.youtubeVideo
                        }
                    />
                    <div className="udesk-qa-web-page-body">
                        <div className="udesk-qa-web-page-body-root template-components-list-page">
                            <div className="template-components-list-page-search"></div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    {/* 添加后自动抓取内容：
                                    <Switch checked={autoPull} onChange={actions.switchChange} /> */}
                                </div>
                                <Button onClick={actions.createBtn}>
                                    {/* 新建 */}
                                    {
                                        UdeskLocales['current'].pages.admin.customDataSource.youtube
                                            .template.new
                                    }
                                </Button>
                            </div>

                            {/* <UdeskTable
                                columns={targetItems}
                                dataSource={tableData}
                                loading={disabled}
                                scroll={{ y: 'calc(100vh - 64px - 58px - 32px - 194px)' }}
                                pagination={{
                                    onChange: actions.pageNumChange,
                                    total: paging.total,
                                    pageSize: paging.pageSize,
                                    onShowSizeChange: actions.pageSizeChange,
                                }}
                            ></UdeskTable> */}

                            <Table
                                columns={targetItems}
                                dataSource={tableData}
                                loading={disabled}
                                scroll={{ y: 'calc(100vh - 64px - 58px - 32px - 194px)' }}
                                pagination={{
                                    onChange: actions.pageNumChange,
                                    total: paging.total,
                                    pageSize: paging.pageSize,
                                    showSizeChanger: true,
                                    onShowSizeChange: actions.pageSizeChange,
                                }}
                            />
                            <Modal
                                visible={modalVisiable}
                                title={
                                    activeItem
                                        ? /* 编辑 */ UdeskLocales['current'].pages.admin
                                              .customDataSource.youtube.template.edit
                                        : /* 新建 */ UdeskLocales['current'].pages.admin
                                              .customDataSource.youtube.template.new
                                }
                                headerClassName="udesk-qa-smart-words-library-modal-header"
                                footerClassName="udesk-qa-smart-words-library-modal-footer"
                                okText={locales.labels.save}
                                cancelText={locales.labels.cancel}
                                onOk={activeItem ? actions.editLink : actions.createLink}
                                onCancel={actions.closeBtn}
                            >
                                <Form
                                    onSubmit={actions.createLink}
                                    layout="vertical"
                                    className="synonym-words-modal-form smart-words-library-nav-item-form"
                                >
                                    <Form.Item
                                        label={
                                            /* 链接 */ UdeskLocales['current'].pages.admin
                                                .customDataSource.youtube.template.link
                                        }
                                    >
                                        <Input.TextArea
                                            value={sourceUrl}
                                            onChange={actions.inputChange}
                                            className="synonym-words-login-form-input"
                                            placeholder="请输入链接"
                                        />
                                    </Form.Item>
                                    {/* <div>{JSON.stringify(activeItem)}</div> */}
                                </Form>
                            </Modal>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}
