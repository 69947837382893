// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostIntelligentPartnerSmartRemarkQueryBody,
    BaseResponseListIntelligentPartnerRecordRemark,
} from '../../../../types';

/**
 * 智能点评查询接口
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [POST] /intelligentPartner/smart/remark/query
 */
export function postIntelligentPartnerSmartRemarkQuery(
    data: PostIntelligentPartnerSmartRemarkQueryBody
): Promise<BaseResponseListIntelligentPartnerRecordRemark>;
export function postIntelligentPartnerSmartRemarkQuery(
    data: PostIntelligentPartnerSmartRemarkQueryBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerRecordRemark>;
export function postIntelligentPartnerSmartRemarkQuery(
    data: PostIntelligentPartnerSmartRemarkQueryBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerRecordRemark> {
    return request<PostIntelligentPartnerSmartRemarkQueryBody, never, never>(
        '/intelligentPartner/smart/remark/query',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerSmartRemarkQuery',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/smart/remark/query',
        method: 'post',
    },
];
