import React, { useState } from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
import Locales from 'UdeskLocales';
import styled from 'styled-components';

const Div = styled.div`
    /* margin-top: 10px; */
    .icon-udesk-qa-react-web-xiazaishibai {
        font-size: 14px;
        color: rgb(214, 90, 87);
        margin-right: 5px;
    }
    .icon-udesk-qa-react-web-yixuanzhong {
        color: rgb(134, 191, 82);
        margin-right: 5px;
    }
`;

function _SemanticTagTest(props) {
    const { testListFieldsBlacklist, hitTest, style } = props;
    const [testListFields] = useState([
        {
            value: 3,
            text: Locales['current'].components.pages.semanticTags.detail.recallOk,
            name: Locales['current'].components.pages.semanticTags.detail.recallNo,
        },
        {
            value: 4,
            text: Locales['current'].components.pages.semanticTags.detail.ruleOk,
            name: Locales['current'].components.pages.semanticTags.detail.ruleNo,
        },
        {
            value: 5,
            text: Locales['current'].components.pages.semanticTags.detail.rularOk,
            name: Locales['current'].components.pages.semanticTags.detail.rularNo,
        },
        {
            value: 2,
            text: Locales['current'].components.pages.semanticTags.detail.infoOk,
            name: Locales['current'].components.pages.semanticTags.detail.infoNo,
        },
        {
            value: 1,
            text: Locales['current'].components.pages.semanticTags.detail.fieldOk,
            name: Locales['current'].components.pages.semanticTags.detail.fieldNo,
        },
        {
            value: 6,
            text: Locales['current'].components.pages.semanticTags.detail.excludeKeywordOk,
            name: Locales['current'].components.pages.semanticTags.detail.excludeKeywordNo,
        },
        {
            value: 7,
            text: Locales['current'].components.pages.semanticTags.detail.excludeRuleOk,
            name: Locales['current'].components.pages.semanticTags.detail.excludeRuleNo,
        },
    ]);

    return (
        <Div style={style}>
            {!_isEmpty(testListFields) &&
                !_isEmpty(hitTest) &&
                testListFields
                    .filter(
                        (f) =>
                            !testListFieldsBlacklist || !testListFieldsBlacklist.includes(f.value)
                    )
                    .map((item) => {
                        return (
                            <div className="semantic-tags-detail-page-body-tabs-test-hit-limit-button-info">
                                <div>
                                    {isHit(hitTest, item) ? (
                                        <i
                                            className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yixuanzhong"
                                            style={{ marginRight: '3px' }}
                                        ></i>
                                    ) : (
                                        <i
                                            className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"
                                            style={{ marginRight: '3px' }}
                                        ></i>
                                    )}
                                    {isHit(hitTest, item) ? <span>{item.text}</span> : item.name}
                                </div>
                                <div>
                                    {isHit(hitTest, item) &&
                                        ((value) =>
                                            ({
                                                3: getCurrent(hitTest, value).recallWords,
                                                2: getCurrent(hitTest, value).similarSentences?.map(
                                                    (item, index) => {
                                                        return (
                                                            <div style={{ marginLeft: '10px' }}>
                                                                {index + 1}.{item}
                                                            </div>
                                                        );
                                                    }
                                                ),
                                                1: getCurrent(hitTest, value).similarSentences?.map(
                                                    (item, index) => {
                                                        return (
                                                            <div style={{ marginLeft: '10px' }}>
                                                                {index + 1}.{item}
                                                            </div>
                                                        );
                                                    }
                                                ),
                                                6: getCurrent(hitTest, value).excludeKeyword,
                                            }[value] || null))(item.value)}
                                </div>
                            </div>
                        );
                    })}
        </Div>
    );
}

export const SemanticTagTest = React.memo(_SemanticTagTest);

function getCurrent(hitTest: any, value: number) {
    return _find(hitTest, (current) => current.matchType === value);
}

function isHit(hitTest: any, item: { value: number; text: any; name: any }) {
    return (
        !_isEmpty(hitTest) &&
        _find(hitTest, (current) => current.matchType === item.value)?.isHit === 1
    );
}
