// 统一合并为完整的 Locale
var locale = {
  lang: {
    confirm: {
      title: 'Are you sure you want to delete? Once deleted, it cannot be restored',
      okText: 'Delete',
      cancelText: 'Cancel'
    }
  }
};
export default locale;