import { useState } from 'react';

export const useFlowPoint = (point) => {
    const [visible, setVisible] = useState(true);

    return {
        detailVisible: visible,
        changeDetailVisible: () => setVisible((prev) => !prev),
    };
};
