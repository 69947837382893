import Udesk from 'Udesk';
import React from 'react';
import CheckPointConditionComponent from './component';
import { Select, Menu, Dropdown, InputNumber, Button } from 'udesk-ui';
import './style.scss';
import OperatorListGather from 'Component/pages/components/check-point-operator-list-gather';
import UdeskLocales from 'UdeskLocales';
import { ApplyTimeType } from './components/ApplyTimeType';
const { Option } = Select;
export default class CheckPointConditionTemplate extends CheckPointConditionComponent {
    render() {
        let { props, actions, privates } = this;
        let { conditionItem, computes } = privates;
        const { roleDisabled } = computes;

        return (
            <div className="check-point-condition-item">
                <div
                    className="check-point-condition-item-color"
                    style={{
                        backgroundColor:
                            conditionItem.conditionType === 1
                                ? 'rgba(0,145,255,0.2)'
                                : conditionItem.conditionType === 2
                                ? 'rgba(68,215,182,0.2)'
                                : conditionItem.conditionType === 3
                                ? 'rgba(247,181,0,0.2)'
                                : conditionItem.conditionType === 4
                                ? 'rgba(109,212,0,0.2)'
                                : '',
                    }}
                ></div>
                <div className="check-point-condition-item-content">
                    <div className="check-point-condition-item-content-top">
                        <div className="check-point-condition-item-content-rule-code">
                            {conditionItem.idCode}
                        </div>
                        <div className="check-point-condition-item-content-rule-type">
                            <Select
                                value={conditionItem.conditionType}
                                style={{ minWidth: 84 }}
                                onChange={actions.ruleTypeChange.params(conditionItem)}
                            >
                                {props.conditionTypeList.map((item) => {
                                    return (
                                        <Option key={item.key} value={item.key}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <OperatorListGather
                            funcType={props.funcType}
                            conditionItem={conditionItem}
                            operatorTypes={actions.getOperatorTypes()}
                            onChange={actions.getGearOptionList}
                            entityFields={this.props.fieldDataList}
                            type={props.type}
                            isAutoAssociateDateSource={props.isAutoAssociateDateSource}
                            isNewAssociate={
                                typeof this.privates.conditionItem?.operatorList?.[0]?.param
                                    ?.version === 'undefined'
                                    ? props.isNewAssociate || 0
                                    : this.privates.conditionItem?.operatorList?.[0]?.param?.version
                            }
                            taskId={props.taskId}
                            hasSummaryRule={props.hasSummaryRule}
                        ></OperatorListGather>
                    </div>
                    <div className="check-point-condition-item-content-bottom">
                        <If
                            condition={
                                ![
                                    Udesk.enums.operatorTypes.associate.id,
                                    Udesk.enums.operatorTypes.businessSummary.id,
                                ].includes(conditionItem.operatorType)
                            }
                        >
                            <div style={{ marginLeft: 43, display: 'inline-block' }}>
                                {
                                    UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                        .configuration.role
                                }
                                <Dropdown
                                    overlay={
                                        <Menu onClick={actions.selectMenuItem.params('applyRole')}>
                                            {props.isSales
                                                ? Udesk.enums.saleApplyRoles.map((item) => {
                                                      return (
                                                          <Menu.Item key={item.id}>
                                                              {item.name}
                                                          </Menu.Item>
                                                      );
                                                  })
                                                : Udesk.enums.applyRoles.map((item) => {
                                                      return (
                                                          <Menu.Item key={item.id}>
                                                              {item.name}
                                                          </Menu.Item>
                                                      );
                                                  })}
                                        </Menu>
                                    }
                                    disabled={roleDisabled}
                                    trigger={['click']}
                                >
                                    <a
                                        className="udesk-ui-dropdown-link"
                                        style={{
                                            color: roleDisabled ? 'rgba(0, 0, 0, 0.15)' : '#1B6DFF',
                                        }}
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {' '}
                                        {props.isSales
                                            ? UdeskLocales.current.enums.saleApplyRoles[
                                                  conditionItem.applyRole
                                              ]
                                            : UdeskLocales.current.enums.applyRoles[
                                                  conditionItem.applyRole
                                              ]}
                                        <span className="udesk-qa-react-web-iconfont">
                                            &#xe686;
                                        </span>
                                    </a>
                                </Dropdown>
                            </div>
                            {/* 前置算子 */}
                            <div
                                style={{ marginRight: 16, marginLeft: 48, display: 'inline-block' }}
                            >
                                {
                                    UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                        .configuration.preposition
                                }
                                <Dropdown
                                    overlay={
                                        <Menu
                                            onClick={actions.selectMenuItem.params(
                                                'applyConditionIdCode'
                                            )}
                                        >
                                            <Menu.Item key="">
                                                {UdeskLocales.current.fix.null}
                                            </Menu.Item>
                                            {props.conditionList &&
                                                props.conditionList.length > 1 &&
                                                props.conditionList
                                                    .filter(
                                                        (item) =>
                                                            item.idCode !== conditionItem.idCode &&
                                                            item.conditionType !== 4
                                                    )
                                                    .map((item) => {
                                                        return (
                                                            <Menu.Item key={item.idCode}>
                                                                {item.idCode}
                                                            </Menu.Item>
                                                        );
                                                    })}
                                        </Menu>
                                    }
                                    trigger={['click']}
                                >
                                    <a
                                        className="udesk-ui-dropdown-link"
                                        style={{ color: '#1B6DFF' }}
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {conditionItem.applyConditionIdCode
                                            ? conditionItem.applyConditionIdCode
                                            : UdeskLocales.current.fix.null}
                                        <span className="udesk-qa-react-web-iconfont">
                                            &#xe686;
                                        </span>
                                    </a>
                                </Dropdown>
                            </div>

                            <If condition={actions.getShowResponseStart()}>
                                <div style={{ marginLeft: 43, display: 'inline-block' }}>
                                    {
                                        /* 应答起始句 */ UdeskLocales['current'].components
                                            .checkPointConditionListGather.checkPointCondition
                                            .template.responseOpeningSentence
                                    }
                                    <Dropdown
                                        overlay={
                                            <Menu
                                                onClick={actions.selectMenuItem.params(
                                                    'responseStart'
                                                )}
                                            >
                                                {Udesk.enums.responseStart.map((item) => {
                                                    return (
                                                        <Menu.Item key={item.id}>
                                                            {item.name}
                                                        </Menu.Item>
                                                    );
                                                })}
                                            </Menu>
                                        }
                                        trigger={['click']}
                                    >
                                        <a
                                            className="udesk-ui-dropdown-link"
                                            style={{
                                                color: '#1B6DFF',
                                            }}
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            {Udesk.enums.responseStart.getName(
                                                conditionItem.responseStart
                                            )}
                                            <span className="udesk-qa-react-web-iconfont">
                                                &#xe686;
                                            </span>
                                        </a>
                                    </Dropdown>
                                </div>
                            </If>

                            <If condition={conditionItem.applyConditionIdCode}>
                                <div
                                    style={{ marginRight: 8, display: 'inline-block', width: 100 }}
                                >
                                    <Dropdown
                                        overlay={
                                            <Menu
                                                onClick={actions.selectMenuItem.params(
                                                    'applyConditionHitType'
                                                )}
                                            >
                                                {Udesk.enums.preOperatorHitTypes.map((item) => {
                                                    return (
                                                        <Menu.Item key={item.id}>
                                                            {item.name}
                                                        </Menu.Item>
                                                    );
                                                })}
                                            </Menu>
                                        }
                                        trigger={['click']}
                                    >
                                        <a
                                            className="udesk-ui-dropdown-link"
                                            style={{ color: '#1B6DFF' }}
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            {Udesk.enums.preOperatorHitTypes.getName(
                                                conditionItem.applyConditionHitType
                                            )}
                                            <span className="udesk-qa-react-web-iconfont">
                                                &#xe686;
                                            </span>
                                        </a>
                                    </Dropdown>
                                </div>
                            </If>
                            {/* 检测范围 */}
                            <div style={{ marginRight: 16, display: 'inline-block' }}>
                                {
                                    UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                        .configuration.range
                                }
                                <Dropdown
                                    overlay={
                                        <Menu
                                            onClick={actions.selectMenuItem.params(
                                                'applyOperatorScope'
                                            )}
                                        >
                                            {Udesk.enums.applyOperatorScopes
                                                .filter((item) => {
                                                    if (
                                                        !conditionItem.applyConditionIdCode ||
                                                        conditionItem.applyConditionHitType ===
                                                            Udesk.enums.preOperatorHitTypes.none.id
                                                    ) {
                                                        return item.id !== 2;
                                                    } else {
                                                        return true;
                                                    }
                                                })
                                                .map((item) => {
                                                    return (
                                                        <Menu.Item key={item.id}>
                                                            {item.name}
                                                        </Menu.Item>
                                                    );
                                                })}
                                        </Menu>
                                    }
                                    trigger={['click']}
                                >
                                    <a
                                        className="udesk-ui-dropdown-link"
                                        style={{ color: '#1B6DFF' }}
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {
                                            UdeskLocales.current.enums.applyOperatorScopes[
                                                conditionItem.operatorList[0].applyOperatorScope &&
                                                    conditionItem.operatorList[0].applyOperatorScope
                                                        .key
                                            ]
                                        }
                                        <span className="udesk-qa-react-web-iconfont">
                                            &#xe686;
                                        </span>
                                    </a>
                                </Dropdown>
                            </div>
                            <If
                                condition={
                                    conditionItem.operatorList[0].applyOperatorScope &&
                                    conditionItem.operatorList[0].applyOperatorScope.id ===
                                        Udesk.enums.applyOperatorScopes.preCondition.id
                                }
                            >
                                <Select
                                    style={{ width: 100, marginRight: 8 }}
                                    value={conditionItem.applyConditionScope}
                                    onChange={actions.selectMenuItem.params('applyConditionScope')}
                                    className="check-range-apply-condition-scope"
                                >
                                    <For
                                        each="item"
                                        index="index"
                                        of={Udesk.enums.applyConditionScopes}
                                    >
                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                    </For>
                                </Select>
                                <If
                                    condition={[
                                        Udesk.enums.applyConditionScopes.before.id,
                                        Udesk.enums.applyConditionScopes.after.id,
                                        Udesk.enums.applyConditionScopes.around.id,
                                    ].includes(conditionItem.applyConditionScope)}
                                >
                                    <InputNumber
                                        // type="number"
                                        min={0}
                                        className="check-range-apply-condition-limit"
                                        value={conditionItem.applyConditionLimit}
                                        onChange={actions.changeInputValue.params(
                                            conditionItem,
                                            'applyConditionLimit'
                                        )}
                                        onBlur={actions.validateRangeValue.params(
                                            conditionItem,
                                            'applyConditionLimit',
                                            conditionItem.applyConditionLimit
                                        )}
                                    />
                                    {UdeskLocales.current.components.checkRange.numberTipTwo}
                                </If>
                            </If>
                            <If
                                condition={
                                    conditionItem.operatorList[0].applyOperatorScope.id ===
                                    Udesk.enums.applyOperatorScopes.scope.id
                                }
                            >
                                {
                                    UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                        .configuration.from
                                }
                                <InputNumber
                                    value={conditionItem.applyScopeStart}
                                    onChange={actions.changeInputValue.params(
                                        conditionItem,
                                        'applyScopeStart'
                                    )}
                                    placeholder={
                                        UdeskLocales.current.components.operatorListGather
                                            .pleaseEnter
                                    }
                                    onBlur={actions.validateGrabInterval.params(
                                        conditionItem,
                                        'applyScopeStart'
                                    )}
                                    style={{ width: 150 }}
                                />
                                {
                                    UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                        .configuration.to
                                }
                                <InputNumber
                                    value={conditionItem.applyScopeEnd}
                                    onChange={actions.changeInputValue.params(
                                        conditionItem,
                                        'applyScopeEnd'
                                    )}
                                    placeholder={
                                        UdeskLocales.current.components.operatorListGather
                                            .pleaseEnter
                                    }
                                    onBlur={actions.validateGrabInterval.params(
                                        conditionItem,
                                        'applyScopeEnd'
                                    )}
                                    style={{ width: 150 }}
                                />
                                {
                                    UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                        .configuration.end
                                }
                            </If>
                            {/* 检测时间 */}
                            <If condition={actions.getShowApplyTimeType()}>
                                <ApplyTimeType
                                    conditionItem={conditionItem}
                                    selectMenuItem={actions.selectMenuItem.params('applyTimeType')}
                                    applyTimeIdChange={actions.selectMenuItem.params('applyTimeId')}
                                />
                            </If>
                        </If>
                        <If
                            condition={[Udesk.enums.operatorTypes.associate.id].includes(
                                conditionItem.operatorType
                            )}
                        >
                            <Button
                                type="link"
                                size="small"
                                onClick={actions.onAssociateVersionChange}
                            >
                                {
                                    UdeskLocales.current.pages.tasks.manage.template.checkPoint
                                        .configuration[
                                        (
                                            typeof conditionItem?.operatorList?.[0]?.param
                                                ?.version === 'undefined'
                                                ? props.isNewAssociate || 0
                                                : conditionItem?.operatorList?.[0]?.param?.version
                                        )
                                            ? 'changeToOld'
                                            : 'changeToNew'
                                    ]
                                }
                            </Button>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}
