import React, { useMemo } from 'react';
import UdeskLocales from 'UdeskLocales';
import { Empty, Switch } from "udesk-ui";
import { isNotEmpty, isArray, inspect } from 'src/util/core';
import { trim } from 'lodash/string';
import './index.less';

export default React.memo((props: any) => {
    const {referenceWords, isShowCue, setIsShowCue,} = props;
    
    const data = useMemo<string[]>(() => {
        if (isArray(referenceWords)) {
            return referenceWords;
        }
        if (typeof referenceWords === 'string') {
            return [referenceWords];
        }
        return [];
    }, [
        referenceWords
    ]);

    return (
        <div className='cue-panel'>
            <div className='cue-title'>
                <div><span>{/* 参考话术 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.cue.index.referenceScript}</span></div>
                <Switch checked={isShowCue} onChange={() => setIsShowCue(isShow => !isShow)} />
            </div>
            {
                inspect(
                    isShowCue && data?.length && data.every(item => isNotEmpty(trim(item))), 
                    () => data?.map(words => {
                        return <div>{words}</div>;
                    }), 
                    () => (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                )
            }
        </div>
    );
});

