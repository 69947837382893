import React, { useState, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Upload, Space, Button, Icon, Progress, Modal } from 'udesk-ui';
import './style.scss';
import VirtualList from 'Component/common/udesk-virtual-list';
import { RecordFile, Status } from './file';
import { deleteVoiceUploadUploadRecord, postVoiceUploadUploadRecord } from 'src/api/voice-upload/upload-record';
import { getSystemModule } from 'Udesk/system/subApp';

const { Dragger } = Upload;

// let ossToken: any = null;
let OSS_TOKEN_REFRESH_TIME = 5 * 60 * 1000;

let publicCountLimit = 25;
let timer: NodeJS.Timeout | null = null;
let timer2: NodeJS.Timeout | null = null;

export default function ConversationSetting(props) {
    const locales = Locales['current'];

    const [countLimit, setCountLimit] = useState<number>(publicCountLimit);
    const [showFailureList, setShowFailureList] = useState<boolean>(false);
    const [prevFailureCount, setPrevFailureCount] = useState<number>(
        props.propsUserUploadList?.filter((f) => f.status === 'failure').length
    );
    const [isUserUpload, setIsUserUpload] = useState<boolean>(false);
    // const [uploadedCount, setUploadedCount] = useState<number>(0);
    const [disabledUpload, setDisabledUpload] = useState<boolean>(false);
    const [successCount, setSuccessCount] = useState<number>(0);
    const [successCountRight, setSuccessCountRight] = useState<number>(0);
    const [failureCount, setFailureCount] = useState<number>(0);
    const [userUploadRounds, setUserUploadRounds] = useState<number>(1);
    /**
     * showedRounds 已展示过错误原因的轮次
     */
    // const [showedRounds, setShowedRounds] = useState<number>(0);
    const [uploadPercent, setUploadPercent] = useState<number>(0);
    /**
     * userFileList 用户选择上传的文件的list
     */
    const [userFileList, setUserFileList]: [RecordFile[], any] = useState(props.propsUserUploadList);
    const firstFileRef = useRef<any>(null);
    const recordBatchIdRef = useRef<any>(null);
    const lastFileRef = useRef<any>(null);
    const firstFile = firstFileRef.current;
    const lastFile = lastFileRef.current;
    const currentUploadingFile = useRef<any>(null);
    const ossTokenTime = useRef<any>(Date.now());
    const ossToken = useRef<any>(null);
    const fileCount = useRef<any>(props.fileCount ?? 0);
    const domRef = useRef<any>(null);

    //上传云端的方法
    const uploadRecord = (file) => {
        return false;
    };
    const handleCancel = () => {
        setShowFailureList(false);
        // setShowedRounds(userUploadRounds);
        setUserFileList(
            userFileList.map((file) => {
                file.failureReason && file.ignoreFailure();
                return file;
            })
        );
    };
    const draggerProps = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        accept: '.mp3, .wav',
        disabled: disabledUpload || userFileList.length !== fileCount.current,
        beforeUpload: uploadRecord,
        onChange(info) {
            // userFileList.current = [...(userFileList.current), info.file];
            if (!info) {
                return;
            }
            let { file: originFile } = info;
            if (!originFile) {
                return;
            }
            const file = new RecordFile(originFile);

            setIsUserUpload(true);
            // setUserFileList(originFile);

            if (!firstFileRef.current) {
                firstFileRef.current = file;
                lastFileRef.current = file;
            } else {
                lastFileRef.current.next = file;
                // file.prev = lastFileRef.current;
                lastFileRef.current = file;
            }
            fileCount.current++;
            props.setFileCount(fileCount.current);
        },
    };
    useEffect(() => {
        if (timer2) {
            clearTimeout(timer2);
        }
        timer2 = setTimeout(async () => {
            if (!lastFile) return;
            if (!currentUploadingFile.current) {
                currentUploadingFile.current = { next: firstFile };
            }
            let current = currentUploadingFile.current.next;

            while (current) {
                // eslint-disable-next-line no-inner-declarations
                // eslint-disable-next-line no-loop-func
                const upload = (current) => {
                    return new Promise((res, rej) => {
                        const now = Date.now();
                        if (now - ossTokenTime.current > OSS_TOKEN_REFRESH_TIME || !ossToken.current) {
                            ossToken.current = new Promise((resolve, reject) => {
                                Udesk.business
                                    .getServerAuthorization({
                                        type: 2,
                                    })
                                    .then(
                                        function (resp) {
                                            ossTokenTime.current = now;
                                            resolve(resp);
                                        },
                                        function (reason) {
                                            reject(reason);
                                        }
                                    );
                            });
                        }
                        current.startUpload(ossToken.current, (file) => {
                            setUserFileList((list) => {
                                if (list.find((i) => i.id === file.id)) return list;
                                if (isFileRepet(file, list)) {
                                    let sameFile = list.find((f) => f.name === file.name);
                                    if (sameFile.checked) {
                                        file.status = Status.failure;
                                        file.failureReason = RecordFile.FAILURE_REASON.repeat;
                                    }
                                }
                                //超出限制上传数量
                                if (list.length >= publicCountLimit) {
                                    file.status = Status.failure;
                                    file.failureReason = RecordFile.FAILURE_REASON.overCount;
                                }
                                return [file, ...list];
                            });
                            res(file);
                        });
                    });
                };
                let requests: any[] = [];
                let innerCurrent = current;
                const ConcurrencyCount = 8;
                for (let i = 0; i < ConcurrencyCount; i++) {
                    requests.push(upload(innerCurrent));
                    if (innerCurrent.next && i !== ConcurrencyCount - 1) {
                        innerCurrent = innerCurrent.next;
                    } else {
                        break;
                    }
                }
                let requestList = await Promise.all(requests);

                requestList = requestList.filter((i) => i.status === Status.success);
                if (requestList.length) {
                    const { data } = await postVoiceUploadUploadRecord({
                        recordBatchId: recordBatchIdRef.current as any,
                        ossKeys: requestList.map((i) => i.key),
                        systemModule: getSystemModule(),
                    });
                    if (data?.id) {
                        recordBatchIdRef.current = data.id;
                        props.saveRecordBatchId(recordBatchIdRef.current);
                    }
                }
                current = innerCurrent.next ?? null;
                //保存最后一个文件的指针，防止重复发送文件的key
                currentUploadingFile.current = innerCurrent;
            }
        }, 500);
    }, [lastFile, publicCountLimit]);
    const deleteUploadItem = (file) => {
        let newUserFileList = [...userFileList];
        // newUserFileList.some((f) => {
        //     let flag = f.uid === file.uid;
        //     if (flag) {
        //         f.checked = false;
        //     }
        //     return flag;
        // });
        deleteVoiceUploadUploadRecord({ ossKeys: [file.key], recordBatchId: recordBatchIdRef.current as any, systemModule: getSystemModule() }).then(
            () => {
                file.checked = false;
                setUserFileList(newUserFileList);
            }
        );
    };
    useEffect(() => {
        let limitUrl = Udesk.business.apiPath.concatApiPath(`voice-upload/limit`, props.sdkOptions);
        Udesk.ajax.get(limitUrl).then(
            (resp) => {
                setCountLimit(resp.data);
                publicCountLimit = resp.data;
                // setCountLimit(2);
                // publicCountLimit = 2;
            },
            (reason) => {
                Udesk.ui.notify.error(reason.message);
            }
        );

        // workerRef.current = new Worker('/work.js');
    }, []);
    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        // let shouldUpdate = false;
        timer = setTimeout(() => {
            let successList = userFileList.filter((f) => f.status === 'success');
            let successCount = successList.filter((f) => f.checked).length;
            let failureCount = userFileList.filter((f) => f.status === 'failure').length;
            setSuccessCount(successCount);
            setFailureCount(failureCount);
            setDisabledUpload(successCount >= countLimit);
            setSuccessCountRight(successList.length);

            if (userFileList.length && userFileList.length === fileCount.current) {
                if (
                    userFileList.some((f) => f.status === 'failure') &&
                    isUserUpload &&
                    prevFailureCount !== failureCount
                ) {
                    setShowFailureList(true);
                    setPrevFailureCount(failureCount);
                }
                setIsUserUpload(false);
                setUserUploadRounds(userUploadRounds + 1);
            }

            props.saveUserUploadList?.(userFileList);
        }, 500);
    }, [userFileList]);
    useEffect(() => {
        //当前轮次上传的文件个数
        // let uploadLength = userFileList.filter((f) => userUploadRounds === f.userUploadRounds).length;
        let uploadLength = props.fileCount;
        let percent = uploadLength ? (userFileList.length / uploadLength) * 100 : 0;
        setUploadPercent(percent);
    }, [userFileList, userUploadRounds, props.fileCount]);
    return (
        <div className='record-upload-component'>
            <div className='record-upload-component-upload-wrapper'>
                <Dragger {...draggerProps}>
                    <div className='record-upload-component-upload-wrapper-inner'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Space>
                                <div style={{ color: '#000000' }}>
                                    {locales.components.pages.recordUpload.uploadComponent.dragger}
                                </div>
                                <div>{`${successCount}/${countLimit}`}</div>
                            </Space>
                        </div>
                        <div style={{ margin: '16px auto' }}>
                            {locales.components.pages.recordUpload.uploadComponent.or}
                        </div>
                        <Button
                            style={{ width: 106 }}
                            disabled={disabledUpload || userFileList.length !== fileCount.current}
                            icon={<Icon type='UploadOutlined' antdIcon={true} />}
                        >
                            {locales.components.pages.recordUpload.uploadComponent.selectFile}
                        </Button>
                        <div style={{ margin: '40px auto 0px' }}>
                            <div>{locales.components.pages.recordUpload.uploadComponent.des}</div>
                            <div>
                                {locales.components.pages.recordUpload.uploadComponent.limit1}
                                <span className='biue-color'>{countLimit}</span>
                                {locales.components.pages.recordUpload.uploadComponent.limit2}
                                <span className='biue-color'>{`${RecordFile.MAX_FILE_SIZE / 1000 / 1024}M`}</span>
                            </div>
                        </div>
                    </div>
                </Dragger>
            </div>
            <div className='record-upload-component-uploaded-display-list'>
                <div className='record-upload-component-uploaded-display-list-progress'>
                    {userFileList.length !== fileCount.current ? (
                        <div className='biue-color' style={{ marginBottom: '-7px', display: 'flex' }}>
                            <Icon type='FileTextOutlined' classname='upload-icon' antdIcon={true} />
                            <div>{locales.components.pages.recordUpload.uploadComponent.uploading}</div>
                            {props.fileCount > 0 && <div style={{marginLeft: 8, color: 'rgba(0, 0, 0, .45)'}}>{`(${successCount}/${props.fileCount})`}</div>}
                        </div>
                    ) : (
                        <div
                            style={{
                                fontWeight: 500,
                                marginBottom: '-7px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>{locales.components.pages.recordUpload.uploadComponent.finished}</div>
                            <div>
                                <span className='biue-color'>
                                    {locales.components.pages.recordUpload.uploadComponent.success}
                                    {successCountRight}
                                </span>
                                <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>/</span>
                                <span style={{ color: 'rgb(251, 81, 39)' }}>
                                    {locales.components.pages.recordUpload.uploadComponent.failure}
                                    {failureCount}
                                </span>
                            </div>
                        </div>
                    )}
                    <Progress
                        strokeLinecap='square'
                        strokeColor={uploadPercent === 100 || uploadPercent === 0 ? '#f5f5f5' : '#1A6EFF'}
                        strokeWidth={2}
                        percent={uploadPercent}
                        showInfo={false}
                    />
                </div>
                <div className='record-upload-component-uploaded-display-list-checkbox-list' ref={domRef}>
                    <VirtualList
                        data={userFileList.filter((f) => f.status === 'success' && f.checked) as any[]}
                        height={domRef.current ? parseInt(window.getComputedStyle(domRef.current).height, 10) - 9 : 0}
                        itemRender={({ item: file, index }) => {
                            return (
                                <div className='record-upload-component-uploaded-display-list-checkbox-list-item'>
                                    <Space>
                                        <div>{index + 1}.</div>
                                        <Icon type='yinpinwenjian' style={{ alignSelf: 'center' }} />
                                        <div>{file.name}</div>
                                    </Space>
                                    {userFileList.length === fileCount.current && (
                                        <Button
                                            size='small'
                                            onClick={deleteUploadItem.bind(null, file)}
                                            type='text'
                                            style={{ color: 'rgba(26, 110, 255, 1)' }}
                                        >
                                            {locales.labels.delete}
                                        </Button>
                                    )}
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
            <Modal
                title={locales.components.pages.recordUpload.uploadFail}
                visible={showFailureList}
                onCancel={handleCancel}
                width={650}
                footer={<Button onClick={handleCancel}>{locales.labels.cancel}</Button>}
            >
                <div className='record-upload-component-uploaded-failed-list'>
                    {userFileList
                        .filter((f) => f.status === 'failure' && !f.ignore)
                        .map((failedFile, index) => {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 4 }}>
                                    <div className='uploaded-failed-file-name'>
                                        {index + 1}.{failedFile.name}
                                    </div>
                                    <span className='uploaded-failed-file-reason'>{failedFile.failureReason}</span>
                                </div>
                            );
                        })}
                </div>
            </Modal>
        </div>
    );
}

function isFileRepet(newFile, fileList) {
    return fileList.some((file) => file.name === newFile.name);
}
// function generateFlieList(firstFile) {}
