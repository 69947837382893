export default {
  labels: {
    clear: "Vuoto",
    refresh: "Aggiorna",
    nextStep: "Prossimo passo",
    prevStep: "Passaggio precedente",
    closeDriver: "Avvio di uscita",
    complete: "Completa",
    viewStatistics: "Visualizza statistiche",
    colon: "：",
    splitter: "/",
    remark: "Osservazioni",
    correction: "Correzione dell'errore",
    save: "Salva",
    reset: "Reset",
    increase: "Aggiungi",
    preview: "Anteprima",
    unit: "Articolo",
    cancel: "Annulla",
    all: "Tutto",
    searchPlaceHolder: "Inserisci la query delle parole chiave",
    greaterThan: "Maggiore",
    lessThan: "Meno",
    second: "Secondi",
    description: "Descrizione",
    column: "Colonna",
    pleaseChoice: "Seleziona",
    filter: "Filtro",
    saveAndSubmit: "Salva e invia",
    submit: "Presentazione",
    appeal: "Denuncia",
    review: "Revisione",
    choose: "Seleziona",
    manage: "Gestione",
    delete: "Elimina",
    add: "Nuovo",
    create: "Nuovo",
    edit: "Modifica",
    name: "Nome",
    describe: "Descrizione",
    next: "Prossimo passo",
    percent: "%",
    export: "Esportazione",
    exportAll: "Esporta tutto",
    confirm: "Determinare",
    back: "Reso",
    versionUpdate:
      "Sono lieto di informarvi che il nostro sito web ha appena rilasciato una nuova versione! Per garantire il tuo normale utilizzo, abbiamo bisogno che tu salvi immediatamente il tuo lavoro corrente, quindi aggiorni la pagina (fai clic sul pulsante di aggiornamento del browser o premi il tasto F5) per rendere effettiva la nostra nuova versione. Grazie mille per la collaborazione!",
    more: "Altro",
    selected: "Selezionato",
    deduct: "Fibbia",
    maxScore: "La maggior parte",
    scoreUnit: "Punti",
    pointUnit: "Uno",
    comma: "，",
    view: "Visualizza",
    batchingImport: "Importazione",
    deleteContent:
      "Sei sicuro di volerlo eliminare? Non è possibile ripristinarlo dopo l'eliminazione",
    import: "Importazione",
    uploadFile: "Carica file",
    downloadFile: "Esporta file",
    upload: "Carica",
    click: "Clicca",
    downloadTemplate: "Scarica il modello",
    fillInTheForm: "Compila per formato",
    UploadExcelfile: "Carica Excel per completare l'importazione in batch",
    uploadIdentical:
      "Se il nome API esiste, i dati esistenti nel sistema verranno aggiornati durante l'importazione.",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Applicabile",
    leftBracket: "(",
    rightBracket: ")",
    question: "Domanda",
    answer: "Risposta",
    content: "Contenuto",
    search: "Ricerca",
    query: "Interrogazione",
    cancelEdit: "Annulla Modifica",
    noValue: "Nessun dato",
    selectAll: "Seleziona tutto",
    changeAGroup: "Cambiare un gruppo",
    arrow: " ---> ",
    saveReview: "Rivedi la presentazione",
    saveSpotCheck: "Ispezione e presentazione del campionamento",
    rapidSampling: "Campionamento rapido",
    file: "Archiviazione",
    download: "Scaricare",
    lastPage: "Pagina precedente",
    nextPage: "Pagina successiva",
    batchActions: "Operazione batch",
    cancelDistribution: "Annulla assegnazione",
    againDistribution: "Riassegnare",
    deleteRecord: "Elimina record",
    oneOptionOpertor: "Ispettore di qualità facoltativo",
    allOptionOpertor: "Tutti gli ispettori di qualità",
    distributionInfoFirstOne: "Conferma",
    distributionInfoFirstTwo:
      "Vuoi riassegnare i record? Seleziona un obiettivo di assegnazione.",
    distributionInfoSecondOne: "OK per annullare",
    distributionInfoSecondTwo:
      "Sono le informazioni del cedente di questo record? Dopo la cancellazione, diventerà un record di revisione non assegnato.",
    distributionInfoSecondThree:
      "Sono le informazioni dell'assegnatore di questo record, diventerà un record di campionamento non assegnato dopo l'annullamento.",
    distributionInfoThirdOne: "I record selezionati hanno",
    distributionInfoThirdTwo: "I record esaminati non verranno elaborati.",
    distributionInfoThirdThree:
      "I record che sono stati campionati non verranno elaborati.",
    exportError: "Esportazione non riuscita",
    exportSuccess: "Esportazione riuscita",
    deleteRecordInfo:
      "Eliminare {0} record? I dati non possono essere recuperati dopo l'eliminazione",
    pleaseSetFilter: "Seleziona i criteri di filtro",
    noCountAfterFilter:
      "Nessun dato di esportazione, si prega di reimpostare i criteri di filtro",
    redo: "Riesecuzione",
    batchMove: "Spostamento in batch",
    websiteName: "Ispezione di qualità intelligente",
    salesEnable: "Responsabilizzazione delle vendite",
    systemName: "Ispezione di qualità intelligente",
    initSystemError:
      "Eccezione di rete o errore di inizializzazione della pagina, contatta il servizio clienti",
    day: "Giorno",
    hour: "Ore",
    minute: "Punti",
    questionMark: "？",
    personalSettings: "Impostazioni personali",
    logout: "Cancellazione",
    ok: "Determinare",
    saveAs: "Salva",
    pleaseEnter: "Inserisci",
    executeMessage: {
      executeSuccess: "{0} eseguito correttamente",
      executeError: "{0} non riuscita a eseguire",
    },
    field: "Campo",
    operator: "Operatore",
    value: "Valore",
    placeholder: "Seleziona",
    pleaseSelect: "Seleziona",
    totalScore: "Punteggio totale",
    hyphen: "-",
    semicolon: "；",
    wfm: "Programmazione intelligente",
    user: "Utente",
    none: "Nessuno",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Salvataggio riuscito! Efficace dopo l'aggiornamento della pagina dei dipendenti pertinenti",
    saveAsName: "{0}(Copia)",
    systemInformation: "Visualizza i dati di sistema",
    billingCenter: "Centro di fatturazione",
    warningMessage:
      "La quota rimanente è insufficiente, si prega di ricaricare il prima possibile",
    fileTypeHolder:
      "Il formato del file non è corretto. Carica un file in formato {0}.",
    dataNo: "Nessun dato",
    move: "Sposta",
    study:
      "Non hai contrassegnato punti di controllo della qualità dell'autoapprendimento",
    studySuccess: "Etichettatura riuscita",
    studyError: "Etichettatura non riuscita",
    editType: "Modifica etichette di categoria",
    copyed: "Copiato negli Appunti",
    clickToCopy: "Fai clic su Copia",
    addFilterConditions: "Aggiungi la condizione del filtro",
    filterConditions: "Condizione del filtro",
    messageTime: "Ora del messaggio",
    messageType: "Tipo di messaggio",
    pushType: "Canale dei messaggi",
    messageContent: "Contenuto del messaggio",
    messageName: "Nome messaggio",
    messageStatus: "Stato del messaggio",
    configuration: "Informazioni di configurazione",
    sendTarget: "Invia destinazione",
    guideUrl: "URL Bootstrap",
    noticeDetail: "Dettagli del messaggio",
    action: "Operazione",
    seeDetail: "Visualizza dettagli",
    theDay: "Giorno {0}",
    theLastDayOfMonth: "L'ultimo giorno di ogni mese",
    ignore: "Ignora",
    seeNow: "Visualizza ora",
    exportConfirmText: {
      export: "Un totale di {0} dati, conferma di esportare?",
      exportAll: "Confermare l'esportazione di tutti i contenuti?",
    },
    samrTagAdd: "Aggiungi tag",
    deletePopconfirmContent: "Sei sicuro di voler eliminare?",
    TheLastThirtyDays: "Ultimi 30 giorni",
    addNumber: "Più",
    subNumber: "Meno",
    addStrategy: "Aggiungi politica",
    minutes: "Minuti",
    status: "Stato abilitato",
    pushConfig: "Configurazione Push",
    category: "Classificazione",
    and: "Con",
    interval: "Intervallo",
    switchOn: "Apri",
    switchOff: "Chiudi",
    batchDel: "Elimina lotto",
    exportTxt: "Esporta il testo TXT",
    playSpeed: "Doppia velocità",
    messageLogBtn:
      "Visualizza notifiche/conversazioni che attivano le notifiche",
    dataLink: "Collegamento dati",
    yes: "Sì",
    no: "No",
    sentenceUnit: "Sentenza",
    appealDetail: "Dettagli del registro dei reclami",
    detail: "Dettagli",
    default: "Default",
    customer: "Cliente",
    agent: "Servizio clienti",
    sort: "Ordina",
    applyNow: "Applicazione",
    index: "Numero di serie",
    allColumns: "Colonne che possono essere aggiunte",
    selectedColumns: "Colonne aggiunte",
    syn: "Sincronizzazione",
    saveAndNext: "Salva e crea dopo",
    viewReference: "Visualizza riferimenti",
    referenceDetail: "Dettagli di riferimento",
    goToQaList: "Vai all'ispezione di qualità",
    goToCheck: "Vai al controllo a campione",
    businessCard: "Biglietto da visita",
    weapp: "Piccolo programma",
    redpacket: "Busta rossa",
    externalRedpacket: "Scambia buste rosse",
    sphfeed: "Numero del video",
    exportTaskCreated: "Esporta attività creata con successo",
    plzGoTo: "Si prega di saltare",
    importTaskCreated: "Importa attività creata con successo",
    taskCreated: "Attività creata con successo",
    reply: "Rispondi",
    replied: "Rispondi",
    release: "Pubblica",
    expandMore: "Espandi altre {0} risposte",
    comment: "Commenti",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Si prega di avere almeno un {0}",
      canNotBeEmptyFormat: "{0} non può essere vuoto",
      repeatErrorTipFormat: "{0} ha dei duplicati",
      getInformationFailedFormat:
        "Impossibile ottenere {0} informazioni. Controlla la tua connessione di rete o riprova più tardi",
      deleteSuccessFormat: "{0} è stato eliminato con successo.",
      deleteFailedFormat: "{0} non è riuscita a eliminare. Riprova più tardi.",
      saveSuccessFormat: "{0} salvati con successo.",
      saveFailedFormat: "{0} non è riuscito a salvare. Riprova più tardi.",
      fileSuccessFormat: "{0} archiviato correttamente.",
      fileFailedFormat: "{0} archiviazione non riuscita. Riprova più tardi.",
      exportSuccessFormat: "{0} esportazione riuscita",
      exportFailedFormat: "{0} esportazione non riuscita. Riprova più tardi.",
      submitSuccessFormat: "{0} inviato correttamente.",
      submitFailedFormat: "{0} non è stato inviato, riprova più tardi.",
      confirmSuccessFormat: "La conferma di {0} è andata a buon fine.",
      confirmFailedFormat: "La conferma {0} non è riuscita. Riprova più tardi.",
      pleaseUploadFile: "Carica prima il file!",
      importFileSuccess: "Importazione file riuscita!",
      importFildFailed: "Importa file non riuscita!",
      deleteSuccess: "Eliminazione riuscita!",
      deleteFailed: "Eliminazione non riuscita, riprova più tardi!",
      illegalTitle: "Autorizzazioni insufficienti",
      illegalMessage:
        "Non hai alcune o tutte le autorizzazioni per il passaggio successivo, contatta l'amministratore per configurare, fai clic sul pulsante OK uscirà dall'avvio",
      illegalMessageCurrent:
        "Non hai alcune o tutte le autorizzazioni per il passaggio corrente, contatta l'amministratore per configurare, fai clic sul pulsante OK per uscire dall'avvio",
      skipMessage:
        "Se per ora vuoi saltare questo passaggio, fai clic su Salta",
      errorMsg500:
        "Richiesta server non riuscita, controlla l'operazione o aggiorna e riprova",
      errorMsg404: "Ooops... manca la pagina che stai cercando",
      errorMsg400:
        "Richiesta server non riuscita, controlla i tuoi dati e riprova",
      pageOverFlow:
        "Attualmente, è possibile visualizzare solo 10000 barre. Si prega di impostare i criteri di filtraggio per visualizzare l'eccesso.",
      isHandAddSmartTag: "È un'etichetta intelligente aggiunta manualmente",
      defaultGradeMustAtLast:
        "La valutazione predefinita deve essere posizionata alla fine",
      AutomaticAllocationInspectorsIsNull:
        "Inserisci la pagina delle informazioni di base per aggiungere l'ispettore di qualità",
      timePickerTips:
        "Per non influire sul tuo normale utilizzo, 23:00 ~ 2:00 è il tempo di manutenzione del sistema, non eseguire attività programmate.",
      batchDeleteInfo:
        "{0} voci sono state eliminate e {1} voci non sono riuscite perché i dati sono stati utilizzati.",
      batchDeleteTips: "Controlla {0} dati, conferma l'eliminazione?",
      correctionSuccess: "Correzione errori riuscita",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError:
            "Aggiungi fino a 10 moduli di ispezione di qualità",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "Gli articoli metrici {0} devono aggiungere fino a 100",
          banProjectMaxErrorTip: "Aggiungi fino a 2000 articoli proibiti",
          deadlyProjectMaxErrorTip: "Aggiungi fino a 500 oggetti fatali",
        },
      },
    },
    info: {
      creatSuccess: "Creato con successo",
      creatError: "Impossibile creare",
      editSuccess: "Modifica riuscita",
      editError: "Modifica non riuscita",
      deleteSuccess: "Eliminazione riuscita",
      addSuccess: "Nuovo successo",
      deleteError: "Eliminazione non riuscita",
      conditionError: "Errore di espressione logica dell'operatore",
      customJudgeLogic:
        "Errore di espressione logica combinazione condizione personalizzata",
      exportError: "Esportazione non riuscita",
      searchError: "Filtro non riuscito",
      loadError: "Caricamento di {0} non riuscito",
      saveSuccess: "Salvataggio riuscito",
      saveError: "Salvataggi non riusciti",
      fileSuccess: "Archiviazione riuscita",
      fileError: "Archivio non riuscito",
      cannotBeNull: "{0} non può essere vuoto!",
      pleaseSearchAndSelect: "Inserisci una ricerca per parole chiave",
      clickAndToggle: "Fai clic per selezionare l'interruttore",
      deleteWarning:
        "L'eliminazione di {0} non sarà recuperabile, sei sicuro di voler continuare?",
      getOssAuthFailed:
        "Impossibile ottenere l'autorizzazione OSS. Controlla la connessione di rete o riprova più tardi.",
      getInsightAuthFailed:
        "Impossibile ottenere l'autorizzazione Insight. Controlla la tua connessione di rete o riprova più tardi.",
      pictureUploadFailed: "Caricamento immagine non riuscito",
      pictureUploadFailedSize:
        "La dimensione dell'immagine supera il limite, caricamento dell'immagine non riuscito",
      wecomTagsSyncInfo:
        "Ultimo aggiornamento:{0}, sincronizzazione di {1} messaggi",
      collectSuccess: "Successo della collezione.",
    },
  },
  components: {
    common: {
      qcReportEcharts: {
        errorMessage: "Impossibile ottenere i dati del report",
      },
      waveAudio: {
        noWaveMessage:
          "Per mantenere la tua esperienza, la forma d'onda non verrà visualizzata per le registrazioni superiori a {0} minuti. Puoi giocarli direttamente.",
      },
      customFilter: {
        del: "Elimina",
        edit: "Modifica",
        new: "Filtro personalizzato",
        apply: "Applicazione",
        cancel: "Annulla",
        onlyApply: "Solo applicabile",
        ohterSave: "Salva",
        save: "Salva",
        customFilter: "Filtro comune",
        reset: "Reset",
        holderKeyWord: "Inserisci una parola chiave",
        customFilterRule: "Regole logiche filtro:",
        customFilterName: "Nome filtro:",
        holderCustomFilterName: "Inserisci un nome di filtro",
        manualConfiguration: "Configurazione manuale",
        holderCustomRule: "Inserisci una condizione logica personalizzata",
        example: "Esempio",
        dragConfiguration: "Configurazione drag and drop",
        condition: "Condizione",
        operation: "Operazione",
        infoDel: "Trascina il contenuto fuori dall'area per eliminarlo",
        toConfigure: "Configurazione",
        holderCustomFilterNameLength:
          "La lunghezza massima del nome del filtro è 10",
        customFilterNameNone: "Il nome del filtro non può essere vuoto",
        and: "E",
        or: "O",
        not: "Non",
        delSuccess: "Eliminazione riuscita",
        saveSuccess: "Salvataggio riuscito",
        updateSuccess: "Modifica riuscita",
        customFilterCondition: "Condizione del filtro",
        conditionRule: "Regole di condizione",
        none: "Nessuno",
        filterPlaceholder: "Inserisci una parola chiave",
      },
      labelTabs: {
        addThis: "Aggiungi a questo",
        goThis: "Spostati in questo",
        saveGo: "Salva e allena",
      },
      datePicker: {
        today: "Oggi",
        yesterday: "Ieri",
        theDayBeforeYesterday: "L'altro ieri",
        thisWeek: "Questa settimana",
        lastWeek: "La settimana scorsa.",
        thisMonth: "Questo mese",
        lastMonth: "Il mese scorso",
        lastSevenDay: "Ultimi 7 giorni",
        lastThirtyDay: "Ultimi 30 giorni",
        thisQuarter: "Questo trimestre",
        lastQuarter: "Ultimo quarto",
        thisYear: "Quest'anno",
        lastYear: "L'anno scorso",
        lastYearFromToday: "L'anno scorso",
      },
      pagination: { page: "Pagina {1} di {0}" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Caricare i messaggi precedenti",
          bottom: "Carica più messaggi",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Compito di ispezione di qualità",
        informationCollectTasksManageTitle: "Compiti di raccolta",
        customerServiceCenterTitle: "Centro assistenza clienti",
        informationTitle: "Centro dati",
        intelligentAnalysisTitle: "Analisi intelligente",
        semanticIntelligence: "Intelligenza semantica",
        trainingCenterTitle: "Centro di formazione",
        adminTitle: "Gestione del sistema",
      },
      qualityCheckingTask: {
        title: "Chiama l'ispezione di qualità",
        index: {
          tipOne:
            "L'ispezione della qualità delle chiamate viene utilizzata per valutare la qualità del lavoro del call center dei dipendenti. Puoi impostare moduli di ispezione di qualità appropriati in base alla tua attività.",
          tipTwo:
            "È possibile impostare regole appropriate per le attività di ispezione della qualità, inclusi il filtraggio e il campionamento. Dopo i punteggi dell'ispettore di qualità, il servizio clienti può visualizzare i risultati dell'ispezione di qualità e l'ulteriore appello,",
          addTaskTitle: "Aggiungi attività di ispezione di qualità",
          deleteTipTitle: "Elimina attività di ispezione di qualità",
          deleteTipContent:
            "Le attività di ispezione di qualità non possono essere ripristinate dopo essere state eliminate. Sei sicuro di volerli eliminare?",
          checkingTask: "Compito di ispezione di qualità",
          checkingTaskList: "Elenco attività di ispezione di qualità",
          formList: "Elenco moduli",
          viewHelpDocuments: "Visualizza la documentazione della guida",
        },
        manage: {
          taskSetting: {
            title: "Impostazioni attività",
            form: "Modulo",
            basicInfo: "Informazioni di base",
            visibleRange: "Gamma visibile",
            visibleRangeTip:
              "Il servizio clienti selezionato e il team del servizio clienti possono visualizzare l'attività di ispezione della qualità. Inoltre, il leader del team del servizio clienti, il servizio clienti e l'ispettore della qualità possono essere combinati con le impostazioni di autorizzazione per questa attività.",
            automaticSampling: "Campionamento automatico",
            automaticSamplingTip:
              "È possibile impostare regole di campionamento automatico, in modo che il sistema aggiunga periodicamente attività di ispezione della qualità in base alle regole impostate, come estrarre il 5% delle chiamate della scorsa settimana ogni lunedì.",
            samplingRate: "Frequenza di campionamento",
            samplingTime: "Tempo di campionamento",
            samplingRange: "Intervallo di campionamento",
            samplingRule: "Regole di campionamento",
            fillterCondition: "Condizione del filtro",
            workflow: "Flusso di lavoro per l'ispezione della qualità",
            appealsTime: "Consentire il tempo di ricorso",
            appealsTimeTipOne: "Dopo l'invio del punteggio",
            appealsTimeTipTwo: "Entro giorni",
            appealsTimeTipThree:
              "Se il tempo di ricorso viene superato, lo stato dell'ispezione della qualità del record verrà automaticamente modificato in Confermato\"",
            auditTime: "Tempo di elaborazione dell'audit",
            auditTimeTipOne: "Dopo aver presentato un reclamo",
            auditTimeTipTwo: "Entro giorni",
            auditTimeTipThree:
              "Dopo aver superato il tempo di elaborazione dell'approvazione, il record mantiene il punteggio originale e lo stato dell'ispezione di qualità viene automaticamente modificato in Confermato\"",
            noAppealsMode: "Nessuna modalità reclamo",
            appealsMode: "Consenti modalità appello",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Servizio clienti",
            customerServicesGroup: "Gruppo di servizio clienti",
            surveyList: "Elenco di valutazione",
            customFields: "Campi personalizzati",
            fieldConfig: "Configurazione del campo",
            from: "Da",
            owner: "Persona in carica",
            inspectorAndReviewerAuthority:
              "Autorità di revisione delle ispezioni di qualità",
            inspector: "Ispettore di qualità",
            reviewer: "Revisore",
            inspectorTip: "L'ispettore può segnare il compito",
            reviewerTip: "Il revisore può valutare l'attività",
            dataStatisticsAuthority: "Permesso per statistiche sui dati",
          },
          samplingRecords: {
            title: "Record di campionamento",
            index: {},
            manualSampling: {
              title: "Campionamento",
              fillterCondition: "Condizione del filtro",
              samplingRule: "Regole di campionamento",
              agent: "Servizio clienti",
              type: "Tipo",
              result: "Risultati",
              evaluate: "Valutazione",
              relayNumber: "Numero relè",
              source: "Fonte",
              task: "Compiti",
              queueState: "Stato di accodamento",
              callHanger: "Chiama la festa di riaggancia",
              queueTime: "Fare la fila che richiede tempo",
              talkTime: "Durata della chiamata",
              bellTime: "Durata dell'anello",
              recordCountTipOne: "Totale",
              recordCountTipTwo: "Registra",
              customerServices: "Servizio clienti",
              customerServicesGroup: "Gruppo di servizio clienti",
              surveyList: "Elenco di valutazione",
              customFields: "Campi personalizzati",
              fieldConfig: "Configurazione del campo",
              defaultFields: "Campi predefiniti",
            },
          },
          taskList: {
            title: "Elenco attività",
            index: {
              batchDelete: "Elimina lotto",
              batchSubmit: "Invio in batch",
              batchConfirm: "Conferma in batch",
              itemFields: "Campo di classificazione degli articoli",
              categoryFields: "Campo di classificazione secondaria",
              forbidsAndDeadlies: "Articoli proibiti e fatali",
              scoreFieldList: "Elenco dei campi degli elementi di punteggio",
              customerName: "Nome del cliente",
              cellphone: "Telefono",
              email: "Cassetta postale",
              organization: "Azienda",
              description: "Descrizione",
              tags: "Etichetta",
              taskUserRole: "Ruolo utente attività corrente",
            },
            workBench: {
              title: "Dettagli record",
              checkForm: "Modulo di ispezione di qualità",
              waitEvaluate: "Da valutare",
              alreadyEvaluate: "Valutato",
              waitSubmit: "Da inviare",
              alreadySubmit: "Inviato",
              waitConfirm: "Da confermare",
              alreadyConfirm: "Confermato",
              waitReview: "Da rivedere",
              alreadyReview: "Recensito",
              alreadyDone: "Completato",
              comment: "Osservazioni di valutazione",
              totalScore: "Punteggio totale",
              score: "Punti",
              remark: "Osservazioni",
              changeRecords: "Cambia record",
              subject: "Tema",
              secondLevelScore: "Punteggio di II grado",
              deadlyList: "Articolo fatale",
              forbidList: "Articoli vietati",
              addToCheckTask: "Aggiungi al compito di qualità",
              submitCheckRemark:
                "Valutazione dell'ispezione di qualità presentata",
              changeCheckRemark:
                "La valutazione dell'ispezione di qualità è stata modificata",
              doneCheckRemark:
                "Valutazione dell'ispezione di qualità completata",
              submitCheckReview:
                "Presentato per la revisione dell'ispezione di qualità",
              submitCheckAppeal:
                "Appello per l'ispezione della qualità presentato",
              saveFlowSuccess: "Valuta il successo.",
              saveFlowFailed: "Valutazione non riuscita, riprova più tardi.",
              FormDetail: "Dettagli modulo",
              callDetail: "Dettagli del registro chiamate",
              sampleCalllogDetail: "Dettagli record",
              appealReasonTip:
                "Inserisci un motivo per presentare ricorso (obbligatorio)",
              appeal: "Denuncia",
              appealReason: "Motivi della denuncia",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} modulo di ispezione della qualità",
        basicInformationTitle: "Informazioni di base",
        indicatorsProjectTitle: "Indicatore",
        specialProjectTitle: "Articoli speciali",
        totalScore: "Punteggio totale",
        indicatorsName: "Nome indicatore",
        score: "Frazione",
        singleScore: "Punteggio singolo articolo",
        weight: "Peso",
        maxDeductScore: "Detrazione massima punti",
        add: "Aumento",
        detail: "Dettagli modulo",
        list: {
          title: "Modulo di ispezione di qualità",
          tipOne:
            "La configurazione di un modulo di ispezione di qualità è il primo passo nel lavoro di ispezione di qualità. Puoi configurare il modulo di ispezione di qualità più adatto in base all'attività della tua azienda.",
          tipTwo:
            "Supportiamo una varietà di forme come il punteggio diretto, il punteggio ponderato e i sistemi di detrazione dei punti. Si consiglia di impostare le regole prima di aggiungerle al sistema.",
        },
        basicInformation: {
          formTemplate: "Modello modulo",
          directScore: "Valutazione diretta",
          weightedScore: "Punteggio del peso",
          deductScore: "Detrazioni",
          directScoreSecondLevel: "Valutazione diretta-Livello 2",
          weightedScoreSecondLevel: "Punteggio peso-Livello 2",
          deductScoreSecondLevel: "Deduzione dei punti-Livello 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Articoli vietati",
          banProjectTip:
            "Può essere utilizzato per controllare durante il punteggio di ispezione di qualità. Può essere considerato come una violazione o un supplemento ai commenti senza influire sul punteggio effettivo.",
          deadlyProject: "Articolo fatale",
          deadlyProjectTip:
            "Gli oggetti fatali possono essere utilizzati per controllare durante il punteggio dell'ispezione di qualità. Se viene controllato un oggetto fatale, l'attività di controllo della qualità viene valutata direttamente con 0 punti.",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Chiama l'ispezione di qualità",
          entiretyReportTitle: "Relazione complessiva",
          serviceGroupReportTitle: "Rapporto del gruppo del servizio clienti",
          serviceReportTitle: "Rapporto sul servizio clienti",
          entiretyReport: {
            pandect: "Panoramica",
            contrast: "Contrasto",
            trend: "Tendenza",
            analyze: "Analisi",
            totalScore: "Punteggio totale",
            banProject: "Articoli vietati",
            deadlyProject: "Articolo fatale",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Elenco dei risultati dell'ispezione di qualità",
          taskSelectPlaceholder: "Seleziona attività di qualità",
          agentSelectPlaceholder: "Seleziona il servizio clienti",
          refresh: "Aggiorna",
          all: "Tutto",
          unread: "Non letto",
          inComplaint: "In Denuncia",
          reviewed: "Recensito",
          totalScore: "Osservazioni generali",
          detail: "Dettagli di ispezione di qualità",
          audioText: "Testo registrato",
          callInfo: "Informazioni sulla chiamata",
          ticketInfo: "Informazioni sull'ordine di lavoro",
          wechatInfo: "Informazioni sulla sessione",
          dialogText: "Testo del dialogo",
          dialogInfo: "Informazioni sul dialogo",
          customer: "Cliente",
          agent: "Servizio clienti",
          none: "Nessun dato di ispezione di qualità",
          appealSuccess: "Appello riuscito!",
          appealFailed: "Appello non riuscito, riprova più tardi!",
          pointListIsNull:
            "Il punto di controllo della qualità del reclamo non può essere vuoto!",
          pleaseSelectPoint:
            "Si prega di selezionare un punto di controllo di qualità!",
          unsupportedFormat: "Questo formato non è supportato.",
          shardingInformation: "Il seguente è shard {0}",
          smartTags: "Etichetta intelligente",
          businessRecords: "Documenti aziendali",
          selectedPointsWarning:
            "Controlla gli articoli di ispezione di qualità per presentare ricorso, specifica le dichiarazioni pertinenti per gli articoli di ricorso, quindi fai clic su Invia.",
          applyReason: "Motivi di ricorso (max. 50 parole)",
          chatMessageTypes: {
            all: "Tutto",
            text: "Testo",
            image: "Immagine",
            agree: "Contenuto della sessione di consenso",
            video: "Video",
            voice: "Voce",
            file: "File",
            emotion: "Espressione",
            link: "Collegamento",
            chatrecord: "Record di chat",
            mixed: "Messaggio misto",
            default: "Messaggio non supportato",
            deleteFriend: "Elimina amico",
            redpacket: "Busta rossa",
            card: "Biglietto da visita",
            disAgree: "Non sono d'accordo con il contenuto della conversazione",
            weapp: "Piccolo programma",
            externalRedpacket: "Scambia buste rosse",
            meetingVoicevCall: "Messaggio di chiamata",
            uRL: "Pagina Web",
            replyTimeout: "Timeout Risposta",
            sphfeed: "Numero del video",
            chatRecordTitle: "Record di chat",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Punteggio totale",
            actualScore: "Valutazione",
            actualGrade: "Valutazione",
            appeal: "Denuncia",
            remark: "Osservazioni manuali",
            none: "Nessun dato dettagliato per l'ispezione di qualità",
            pointEdit:
              "Classificazione di modifica del punto di ispezione della qualità",
          },
          changeList: {
            title: "Cambia record",
            user: "Utente",
            point: "Punto di ispezione della qualità",
          },
          relatedRecord: {
            title: "Record correlati",
            qualityList: "Compito di ispezione di qualità",
            none: "Nessuno",
            check: "Visualizza",
            loadMore: "Carica di più",
          },
        },
      },
      grade: {
        title: "Valutazione dell'ispezione di qualità",
        subTitle:
          "La valutazione dell'ispezione di qualità viene utilizzata per classificare gli oggetti di ispezione di qualità dopo il completamento del punteggio di ispezione di qualità. Ad esempio, può essere configurato come non qualificato se è inferiore a 60, buono se è superiore a 60 ed eccellente se è superiore a 80.",
        list: {
          up: "Spostati",
          down: "Spostati verso il basso",
          del: "Elimina",
          case: "Unisciti automaticamente alla libreria dei casi",
          orderCase: "Libreria caso predefinita",
          info: "La valutazione predefinita non ha criteri corrispondenti e non può essere eliminata. Se una delle valutazioni di cui sopra non viene soddisfatta, verrà ottenuta la valutazione predefinita.",
          add: "Aggiungi valutazione",
          save: "Salva",
          close: "Annulla",
          max: "La valutazione di ispezione di qualità può essere configurata fino a 20",
          name: "Senza nome",
          success: "Salvataggio riuscito",
        },
      },
      qualityInspectionList: {
        title: "Elenco di ispezione di qualità",
        fastFilter: "Violazione dell'ispezione di qualità",
        fastFilterTip:
          "Un colpo sul sistema del punto meno è una violazione e un errore nel sistema del punto più è una violazione.",
        titleWechat: "Elenco analisi",
        titleCollect: "Elenco di acquisizione",
        status: "Stato dell'analisi",
        reQualityInspectionConfig: "Opzioni attività di riqualità",
        pleaseSelectRecheckPoints:
          "Seleziona il punto di ispezione della qualità da ricalcolare",
        beginReQualityInspection: "Avvia l'esecuzione",
        cancelReQualityInspection: "Annulla attività",
        reQualityInspection: "Riispezione",
        reQualityInspectionWechat: "Rianalisi",
        reQualityInspectionUnderWay: "Compito di ri-ispezione...",
        reQualityInspectionUnderWayTip:
          "Lasciare la pagina dopo aver chiuso la finestra non influisce sul compito",
        reQualityInspectionDone: "Riispezione completata",
        reQualityInspectionDoneTip:
          "La nuova ispezione è stata completata con successo, fare clic sul pulsante di conferma.",
        endReQualityInspection: "Terminare l'attività",
        alreadyDoneTip: "{0} completato",
        totalTip: "Totale {0}",
        endReInspectionSuccess: "Attività di qualità terminata",
        closeWindow: "Chiudere la finestra",
        endReInspectionFailed:
          "Attività di nuova qualità non riuscita, riprova più tardi!",
        detail: {
          title: "Dettagli di ispezione di qualità",
          wechatTitle: "Dettagli analisi",
          titleCollect: "Dettagli della raccolta",
          callIndex: "Chiamata associata",
          textIndex: "Conversazioni associate",
          fileConfirm: "Confermare l'archiviazione?",
          fileConfirmDesc:
            "Dopo l'archiviazione, il punteggio dell'ispezione di qualità non può essere modificato. Conferma se archiviare.",
        },
        sysCloumnsContal: "Gestione del campo della colonna di sistema",
        personCloumnsContal: "Gestione del campo della colonna personale",
      },
      spotCheckList: {
        title: "Elenco di campionamento",
        onlyMyTip: "Visualizza solo il mio campionamento",
        plInspector: "Seleziona il personale di campionamento",
        allInspector: "Visualizza tutto",
        seInspector: "Visualizza l'ispettore di campionamento designato",
        myInspector: "Visualizza solo il mio",
        noInspector: "Controllare l'ispettore di campionamento non assegnato",
        detail: { title: "Dettagli del campionamento" },
      },
      reviewList: {
        title: "Elenco di revisione",
        onlyMyTip: "Visualizza solo la mia recensione",
        plInspector: "Seleziona un revisore",
        allInspector: "Visualizza tutto",
        seInspector: "Visualizza revisore designato",
        myInspector: "Visualizza solo il mio",
        noInspector: "Visualizza revisore non assegnato",
        detail: { title: "Rivedi i dettagli" },
      },
      caseBaseList: {
        title: "Libreria del caso",
        del: "Elimina",
        delTitle: "Tutto il contenuto non verrà salvato dopo l'eliminazione",
        cancel: "Annulla",
        actions: "Operazione",
        seeCaseBase: "Visualizza caso",
        seeDetail: "Visualizza dettagli",
        edit: "Modifica",
        save: "Salva",
        addCaseBase: "Nuova libreria di casi",
        export: "Esportazione",
        ok: "Determinare",
        tip: "I dati tipici di chiamata e conversazione nell'elenco di ispezione di qualità possono essere salvati nella libreria dei casi per un facile apprendimento e condivisione.",
        detail: {
          choiceCaseBase: "Seleziona un caso",
          delCaseBase: "Elimina caso",
          addCaseBase: "Aggiungi custodia",
          successed: "Salvataggio riuscito",
          info: "Prima salva o annulla il contenuto che stai modificando",
          caseType: "Seleziona una libreria di casi per partecipare",
        },
      },
      testSetList: {
        title: "Set di test di ispezione di qualità",
        tip: "I dati campionati dall'ispettore di qualità saranno salvati nel set di test di ispezione di qualità, che è conveniente per ottimizzare la configurazione delle condizioni di ispezione di qualità e le regole per alcuni elementi di errore di ispezione della macchina rispetto dopo l'ispezione di campionamento.",
        refresh: "Aggiorna",
        reQualityInspectionUnderWay: "Rivalutare la missione in...",
        reQualityInspectionUnderWayTip:
          "Lasciare la pagina dopo aver chiuso la finestra non influisce sul compito",
        reQualityInspectionDone: "Rivalutazione completata",
        reQualityInspectionDoneTip:
          "La nuova valutazione è stata completata con successo, fai clic sul pulsante Conferma",
        endReQualityInspection: "Terminare l'attività",
        alreadyDoneTip: "{0} completato",
        totalTip: "Totale {0}",
        endReInspectionSuccess: "Compito di rivalutazione terminato",
        closeWindow: "Chiudere la finestra",
        endReInspectionFailed:
          "Attività di rivalutazione non riuscita, riprova più tardi!",
        recheckPoint:
          "Ricalcolare i punteggi e l'accuratezza dei punti di ispezione della qualità",
        recheckPointTitle: "Opzioni di rivalutazione",
        delete: {
          reQualityInspectionUnderWay: "Elimina attività in batch...",
          reQualityInspectionUnderWayTip:
            "Lasciare la pagina dopo aver chiuso la finestra non influisce sul compito",
          reQualityInspectionDone: "Eliminazione lotto attività completata",
          reQualityInspectionDoneTip:
            "L'eliminazione in blocco è stata completata con successo, fai clic sul pulsante Conferma",
          endReQualityInspection: "Terminare l'attività",
          alreadyDoneTip: "{0} completato",
          totalTip: "Totale {0}",
          endReInspectionSuccess: "Eliminazione del lotto attività terminata",
          closeWindow: "Chiudere la finestra",
          endReInspectionFailed:
            "Eliminazione batch non riuscita, riprova più tardi!",
        },
        reultList: {
          assessmentTime: "Ultimo tempo di valutazione",
          assessmentAll: "Valutazione totale",
          strip: "Articolo",
          spot: "Punto",
          deviation: "Deviazione",
          accuracyRate: "Precisione",
          info: "Nessun risultato della valutazione, si prega di valutare",
          onlyDeviationInfo: "Visualizza solo record di deviazioni",
          againAssessment: "Rivalutazione",
          screen: "Filtro",
          batchDelete: "Rimuovi lotto",
          matching: "Partita",
          ok: "Sì",
          noCancel: "No",
        },
      },
      smartWordsLibrary: {
        title: "Thesaurus intelligente",
        tip1: "Sinonimi: usati per ridurre la quantità di addestramento delle etichette semantiche, frasi simili di sinonimi non devono essere ripetutamente addestrate.",
        tip2: "Nomi professionali: ottimizza la trascrizione e la comprensione del linguaggio naturale per nomi appropriati come nomi aziendali, nomi di prodotti e parole del settore che non sono accuratamente riconosciuti per migliorare l'accuratezza del riconoscimento e l'accuratezza dell'analisi.",
        tip3: "Concentrati sulle parole: concentrati sulle parole su cui vuoi concentrarti nell'analisi del cloud di parole per aiutare gli hotspot dell'analisi dell'intelligenza artificiale a soddisfare il business attuale.",
        tip4: "Parole di blocco: blocca le parole a cui non vuoi prestare attenzione nell'analisi del cloud di parole per aiutare l'intelligenza artificiale ad analizzare i punti caldi in linea con l'attività corrente.",
        tip5: "Peso della parola: utilizzato per correggere e ottimizzare il riconoscimento delle etichette semantiche. Modificando il peso di influenza delle parole nelle frasi, l'effetto di riconoscimento semantico in scenari specifici può essere ottimizzato secondo necessità.",
        tip6: "Dati di corpo: inserisci grandi paragrafi di testo per i rispettivi scenari aziendali per la correzione degli errori di transcodifica e l'addestramento ASR per migliorare l'accuratezza della transcodifica ASR.",
        upload: {
          uploadSynonymWords: "Sinonimi di importazione",
          uploadProfessionalWords: "Importa termini professionali",
          uploadAttentionWords: "Importa parole di interesse",
          uploadIgnoreWords: "Importa ignora le parole",
          uploadWordsWeight: "Importa parole peso",
          uploadTypo: "Importa set di parole refusi",
          uploadCorpusData: "Importa i dati del corpus",
          uploadCorpusContent: "Importa il contenuto dei dati del corpus",
          firstUploadStep:
            "1. Scarica il modello e compilalo in base al formato del modello",
          downloadTemplateMessage: "Scarica modello:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Fare clic sul pulsante Importa per caricare il file EXCEl conforme al formato del modello",
          secondUploadCorpusDataStep:
            "2. Fare clic sul pulsante Importa per caricare il file TXT conforme al formato del modello.",
          postUploadDataFail: "Impossibile inviare e caricare i dati.",
          pleaseUploadTemplate: "Carica il file EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "I modelli di file possono essere solo in formato JSON o xlsx",
          templateNeedTxt:
            "Il modello di file di dati corpus può essere solo un file TXT",
          uploadSuccess: "Importazione riuscita",
          uploadFailed: "Importa non riuscita",
          downloadError: "Download non riuscito",
          downLoadTxtTitle: "Corpus contendono. Txt",
        },
        navTabNames: {
          synonymWords: "Sinonimi",
          professionalWords: "Sostantivo professionale",
          attentionWords: "Parole preoccupate",
          ignoreWords: "Ignora le parole",
          wordsWeight: "Parola peso",
          corpusData: "Dati del corpus",
          typoLibrary: "Refusi set di parole",
        },
        synonymWords: {
          createSynonymWords: "Nuovo Sinonimo",
          editSynonymWords: "Modifica sinonimi",
          addSynonymWords: "Sinonimo di Aggiungere",
          pleaseEnterStandardWords: "Inserisci una parola standard",
          synonymWordsExists:
            "Il sinonimo esiste già e non può essere aggiunto",
          synonymWordsCanNotNull: "Il sinonimo aggiunto non può essere vuoto",
          pleaseAddSynonymWords: "Aggiungi sinonimi",
          addSuccess: "Nuovo successo",
          editSuccess: "Modifica riuscita",
          deleteSuccess: "Eliminazione riuscita",
          deleteError: "Eliminazione non riuscita",
          required: "*",
        },
        professionalWords: {
          create: "Nuovi termini professionali",
          edit: "Editor di nomi professionali",
          addTargetWords: "Aggiungi parola sostitutiva",
          targetWordsExists:
            "La parola sostitutiva esiste già e non può essere aggiunta",
          targetWordsCanNotNull:
            "La parola di sostituzione aggiunta non può essere vuota",
          pleaseAddTargetWords: "Aggiungi una parola sostitutiva",
          pleaseEnter: "Inserisci un termine professionale",
          wordsExists: "Il termine esiste già.",
          createSuccess: "Aggiunto correttamente",
          editSuccess: "Modifica riuscita",
          deleteSuccess: "Eliminazione riuscita",
          deleteError: "Eliminazione non riuscita",
          saveError: "Salvataggi non riusciti",
          addTargetWordsMaxNumberError:
            "Puoi aggiungere fino a 100 parole sostitutive",
          addHomophonyWordsMaxNumberError:
            "Puoi aggiungere fino a 100 parole mascherate",
        },
        attentionWords: {
          create: "Nuova parola di preoccupazione",
          edit: "Editor di parole interessato",
          pleaseEnter: "Inserisci una parola di interesse",
          wordsExists: "La parola di interesse esiste già.",
          createSuccess: "Aggiunto correttamente",
          editSuccess: "Modifica riuscita",
          deleteSuccess: "Eliminazione riuscita",
          deleteError: "Eliminazione non riuscita",
        },
        ignoreWords: {
          create: "Nuova parola ignora",
          edit: "Ignora la modifica delle parole",
          pleaseEnter: "Inserisci la parola ignorata",
          wordsExists: "La parola ignora esiste già",
          createSuccess: "Aggiunto correttamente",
          editSuccess: "Modifica riuscita",
          deleteSuccess: "Eliminazione riuscita",
          deleteError: "Eliminazione non riuscita",
        },
        wordsWeight: {
          createWordsWeight: "Nuova parola peso",
          editWordsWeight: "Modifica parola peso",
          addWordsWeight: "Aggiungi parola peso",
          pleaseEnterWeightWords: "Inserisci una parola di peso",
          pleaseEnterWeight: "Inserisci un peso",
          weightNeedInZeroToHundred: "Il peso deve essere compreso tra 0 e 100",
          synonymWordsExists: "La parola peso esiste già",
          weightNeedNumber: "Il peso richiede il formato intero",
          standardWeightInformation:
            "Il peso di riferimento è 50, e maggiore è il peso, più forte è l'influenza nell'analisi.",
          createSuccess: "Aggiunto correttamente",
          editSuccess: "Modifica riuscita",
          deleteSuccess: "Eliminazione riuscita",
          deleteError: "Eliminazione non riuscita",
        },
        corpusData: {
          createCorpusData: "Nuovi dati di Corpus",
          editCorpusData: "Modifica i dati del corpo",
          addCorpusData: "Aggiungi i dati del corpo",
          pleaseEnterCorpusDescription: "Inserisci una descrizione del corpus",
          pleaseEnterCorpusContent: "Inserisci il contenuto del corpus",
          synonymWordsExists: "Questi dati del corpus esistono già",
          CorpusContentWordsOverSetting:
            "Il numero di parole nel contenuto supera le parole 20000,",
          download: "Scaricare",
          upload: "Carica",
          see: "Visualizza",
          canNotSave:
            "Si prega di utilizzare la funzione di importazione, altrimenti non può essere salvata",
          createSuccess: "Aggiunto correttamente",
          editSuccess: "Modifica riuscita",
          deleteSuccess: "Eliminazione riuscita",
          deleteError: "Eliminazione non riuscita",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Parola corretta",
            wrongWord: "Parola sbagliata",
            wrongWordCount: "Numero di parole sbagliate",
            wrongWordTip: "Premere Invio per aggiungere",
            status: "Efficacia",
          },
          create: "Nuovo set di parole refusi",
          edit: "Modifica errori di battitura",
          tip: 'Il set di parole di battitura viene utilizzato per ottimizzare l\'effetto del "rilevamento degli errori di battitura". Ad esempio, se "rimborso" viene erroneamente inviato come "rimborso della larghezza", è possibile impostare errori di battitura comuni e parole corrette tramite questa funzione e avrà effetto il giorno successivo al salvataggio.',
          onlyChinese: "Supporti solo le parole cinesi di input",
          wrongWordLengthErr:
            "La lunghezza di ogni parola sbagliata dovrebbe essere di 2 ~ 10 parole",
        },
      },
      semanticTags: {
        title: "Etichetta semantica",
        current: "Attuale etichetta semantica",
        reference1: "Attività di ispezione della qualità: {0} - {1}",
        reference2: "Etichetta intelligente: {0}",
        reference3: "Preelaborazione dei dati: {0}",
        reference4: "Base di conoscenza: {0}",
        index: { import: "Importa etichette semantiche" },
        detail: {
          new: "Nuova etichetta semantica",
          edit: "Modifica etichetta semantica",
          name: "Nome",
          standardSentences: "Frase standard",
          effectiveness: "Efficacia",
          similarSentences: "Frasi simili",
          tip: "Supporta l'utilizzo di # # per chiamare l'API dell'entità, ad esempio: il tuo numero di cellulare è # sys_phone?",
          excludeSimilarSentences: "Escludere frasi simili",
          intelligentRecommend:
            "Raccomandazione intelligente di contenuti simili",
          fastTraining: "Formazione rapida:",
          qualify: "Regolare",
          recallKeyWord: "Richiedi parole chiave",
          excludeKeywords: "Escludere le parole chiave",
          excludeKeywordsInfo1:
            "Escludere le parole chiave e le espressioni regolari hanno la precedenza su tutte le logiche. Se vengono esclusi, l'etichetta semantica non verrà colpita.",
          keyWordInfo1:
            "È possibile inserire più parole chiave di richiamo in ogni riga, separate da virgole inglesi. Quando vengono compilate più parole chiave di richiamo, la logica è che tutti gli hit vengono colpiti.",
          keyWordInfo2:
            "Le parole chiave di richiamo possono essere compilate in più righe. Quando vengono riempite più righe, la logica può essere impostata come qualsiasi hit.",
          recallRegular: "Richiamiamo regolarmente",
          excludeRegular: "Escludere regolare",
          similaSentences: "Escludere frasi simili",
          simim: "Somiglianza",
          keyWordInfo3:
            "Escludere frasi simili ha la precedenza su ogni logica. Se viene colpita una frase simile, l'etichetta semantica non verrà colpita. La somiglianza predefinita è dell'80 % e maggiore è la somiglianza, più è simile.",
          keyWordInfo4:
            "La regola è simile a quella regolare, con inizio e fine di default. Non necessariamente utilizzato con parentesi, ad esempio [non XXXX]",
          keyWordInfo5:
            "Le funzioni nel modulo avanzato sono solitamente funzioni sperimentali per esperti che hanno familiarità con l'operazione. Se non si è sicuri di come operare, si consiglia di non modificare questa sezione.",
          keyWordInfo6:
            "La funzione di rilevamento delle frasi incrociate offre ai tag semantici la capacità di rilevare tra le frasi.",
          keyWordInfo7: "Al massimo consecutivi attraverso lo stesso ruolo",
          keyWordInfo8:
            "Ignora ruoli diversi La lunghezza della frase non è maggiore",
          keyWordInfo9:
            "Frasi di parole per il rilevamento di frasi incrociate",
          keyWordInfo10:
            "La lunghezza della frase limite di frase incrociata più lunga non è più",
          keyWordInfo11: "Rimuovere percentuale simile non inferiore",
          keyWordInfo12: "La somiglianza non è inferiore",
          keyWordInfo13:
            "% Di frasi simili saranno abbinate in modo simile (somiglianza predefinita 80%)",
          keyWordInfo14:
            "La deduplicazione simile corrisponde a frasi in base alla loro somiglianza. Si raccomanda di rimuovere frasi troppo simili per migliorare l'efficienza di rilevamento.",
          keyWord: "Parole chiave",
          entity: "Entità",
          ruleField: "Concetto parole",
          customRule: "Progettazione della regola del discorso",
          fanhua: "Domande simili",
          test: "Test",
          testOk: "Test di successo",
          senior: "Impostazioni avanzate",
          seniorList: "Rilevamento della frase incrociata",
          up: "Abilita",
          down: "Chiudi",
          ju: "Sentenza",
          oneWord: "Parola",
          simimNo: "Simile al peso",
          seniorField: "Soglia di somiglianza",
          save: "Salva",
          cancel: "Annulla",
          specialElements: "Elementi speciali",
          logicRule: "Regole logiche",
          every: "Partite multiple",
          recallOk: "Premi la parola di richiamo:",
          recallNo: "Parola di richiamo mancata",
          ruleOk: "Richiamo regolare",
          ruleNo: "Mancato regolare",
          rularOk: "Regole di successo",
          rularNo: "Regola mancata",
          infoOk: "Colpisci una frase standard o una frase simile:",
          infoNo: "Frase standard mancata o frase simile",
          fieldOk: "Colpisci per escludere frasi simili:",
          fieldNo: "Mancato escludere frasi simili",
          excludeRuleNo: "Esclusione Miss Regex",
          excludeRuleOk: "Colpire l'esclusione regolare",
          excludeKeywordNo: "Le mancate escludono le parole chiave",
          excludeKeywordOk: "Premi Escludi parole chiave",
          update: "Aggiornamento riuscito",
          fanhuaList:
            "Inviato alla scheda di generalizzazione Elenco frasi simili",
          wordList:
            "L'elenco di formazione è vuoto e non può essere inviato all'elenco di generalizzazione",
          start: "Inizia",
          end: "Fine",
          keyWordInfo15:
            "Campi simili esistono nell'elenco di generalizzazione (configurazione avanzata di vincoli di deduplicazione simili)",
          saveSuccess: "Salvataggio riuscito",
          errInfo:
            "Alcuni campi non vengono compilati o compilati in modo errato",
        },
        table: {
          deepTraining: "Deep Training {0} round",
          fastTraining: "Formazione rapida",
        },
      },
      intelligentCollection: {
        title: "Acquisizione intelligente",
        classification: "Elenco dei gruppi",
        new: "Nuova collezione",
        table: {
          name: "Nome del campo",
          props: "Attributi del cliente",
          type: "Tipo di campo",
          desc: "Descrizione del campo",
        },
        detail: {
          isCustomerProps: "Salva negli attributi del cliente",
          customerPropsHelper:
            "Dopo il salvataggio, le informazioni raccolte verranno aggiornate alle informazioni del cliente. Non salvare, nessun aggiornamento alle informazioni del cliente",
          name: "Nome del campo",
          type: "Tipo di campo",
          text: "Testo",
          desc: "Descrizione del campo",
          descPlaceHolder:
            "Descrivere l'uso di questo campo per raccogliere informazioni",
          condition: "Condizione",
          logic: "Regole",
          method: "Modalità di acquisizione",
          message: "Contenuto delle informazioni",
          range: "Gamma di estrazione",
          entity: "Entità estratto",
          base: "Informazioni di base",
          collect: "Raccogliere informazioni",
        },
      },
      intelligentTags: {
        title: "Etichetta intelligente",
        sessionLabelTitle: "Scenari di sessione",
        keyEventsTitle: "Eventi chiave",
        customerLabelTitle: "Etichetta del cliente",
        addClassification: "Crea una nuova classificazione dell'etichetta",
        defaultCategoryName: "Classificazione predefinita",
        index: {
          createTag: "Nuova etichetta",
          createEvent: "Nuovo evento",
          editTagClassification: "Modifica classificazione dell'etichetta",
          selectTagClassification:
            "Seleziona la classificazione delle etichette",
          editTagGrounp: "Modifica raggruppamento",
          callTimeError:
            "L'intervallo di tempo di inizio della chiamata/conversazione selezionato non può superare un mese (31 giorni)",
          callRecordCountError:
            "Il numero di record è 0, seleziona nuovamente il tempo di chiamata/conversazione",
          selectItmeCountError:
            "Selezionare l'etichetta intelligente per la quale si desidera eseguire l'operazione di massa",
          redoSuccess:
            "La riesecuzione ha esito positivo, vai al centro attività per visualizzarlo",
          redoError: "Riesecuzione non riuscita, riprova più tardi",
          timeError: "Il tempo di query non può superare un mese (31 giorni)",
          categoryName: "Nome categoria",
          categoryColor: "Colore della classificazione",
          newTagClassification: "Nuova classificazione dell'etichetta",
          newEventClassification: "Nuova categoria",
          tagClassification: "Classificazione dell'etichetta",
          eventClassification: "Classificazione degli eventi",
        },
        detail: {
          new: "Nuova etichetta intelligente",
          edit: "Modifica etichetta intelligente",
          tagName: "Nome",
          tagDesc: "Descrizione",
          category: "Classificazione",
          tagStatus: "Efficacia",
          operator: "Operatore",
          rule: "Regole",
          labelClassAtion: "Tipo di etichetta",
          labelClassAtionTip1:
            "Etichette callout: etichettatura basata su regole",
          labelClassAtionTip2:
            "Etichettatura di classificazione: Etichettatura basata sulla formazione del modello",
          group: "Raggruppamento",
          checkLabel: "Seleziona categoria",
          warn: "Avviso",
          warnTips:
            "Dopo aver attivato l'avviso, quando la sessione soddisfa questa regola dell'evento chiave, verrà richiesto alla persona designata e potrà ricevere il promemoria nell'elenco delle cose da fare.",
          warnError: "Seleziona Personale specificato per avviso",
        },
        subTitle: {
          common: "Tag della sessione intelligente",
          wecom: "Micro etichetta aziendale",
        },
      },
      customerPortrait: {
        title: "Ritratto del cliente",
        index: { import: "Ritratto del cliente di importazione" },
        searchPlaceHolder: "ID cliente, soprannome o telefono",
        callCount: "Numero di conversazioni:",
        callDuration: "Durata totale delle chiamate:",
        dialogCount: "Numero totale di conversazioni:",
        keyWordsStatistics:
          "Statistiche delle parole chiave (Top 20 e parole di preoccupazione)",
        keyWordsCount: "({0})",
        businessTag: "Etichetta aziendale",
        keyWordsCloudImg: "Grafico cloud delle parole chiave",
        customerEmotionTrend: "Tendenze del sentimento dei clienti",
        notFoundCustomer: "Nessun cliente correlato recuperato",
        noKeyWords: "Non sono state trovate parole chiave correlate",
        noBusinessTags: "Tag aziendali correlati non trovati",
        getKeyWordsStatisticsDataError:
          "La query sulle statistiche delle parole chiave del cliente non è riuscita",
        getBusinessTagsStatisticsDataError:
          "La query sull'etichetta aziendale del cliente non è riuscita",
        personCustome: "Informazioni del cliente non interrogate",
        notimeInfo: "Nessuna informazione emotiva è stata trovata.",
        timeList: "Intervallo di data",
        operator: "Etichetta intelligente",
        placeHolder: "Seleziona",
        placeOperator: "Seleziona un'etichetta intelligente",
        search: "Interrogazione",
        reset: "Reset",
        searchHolder: "Ricerca",
        call: "Chiama",
        text: "Dialogo",
      },
      sentimentAnalysis: { title: "Scoperta dell'opinione pubblica" },
      saleSentimentAnalysis: { title: "Analisi dell'opinione pubblica" },
      dataPreprocess: {
        title: "Preelaborazione dei dati",
        index: {
          createData: "Nuovi dati",
          selectDataSource: "Seleziona origine dati",
          selectStatus: "Seleziona effettività",
          callTimeError:
            "L'intervallo di tempo di inizio della chiamata/conversazione selezionato non può superare un mese (31 giorni)",
          callRecordCountError:
            "Il numero di record è 0, seleziona nuovamente il tempo di chiamata/conversazione",
          selectItmeCountError:
            "Seleziona i dati per eseguire operazioni in blocco",
          redoSuccess:
            "La riesecuzione ha esito positivo, vai al centro attività per visualizzarlo",
          redoError: "Riesecuzione non riuscita, riprova più tardi",
        },
        detail: {
          new: "Nuovi dati",
          edit: "Modifica dati",
          name: "Nome",
          desc: "Descrizione",
          dataSource: "Fonte dati",
          status: "Efficacia",
          entity: "Entità estratto",
          operator: "Operatore",
          rule: "Regole",
        },
      },
      connectedByConversation: {
        title: "Associazione di conversazione di comunicazione",
        list: {
          title: "Funzione di associazione di dialogo di comunicazione",
          info: "La funzione di associazione di conversazione può associare chiamate e conversazioni diverse in base alle regole preimpostate.",
          rule: "Regole di associazione di conversazione",
          source: "Seleziona un'origine dati",
          classification: "Seleziona una categoria",
          choose: "Seleziona",
          input: "Inserisci",
          save: "Salva",
          cancel: "Annulla",
          fieldwei: "Bit",
          fieldNot: "Ci sono campi non riempiti",
          fiedldlengthNo: "La lunghezza del campo supera il limite",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "È possibile impostare più parole chiave, separate da virgole",
          applyRole: "Rileva il ruolo",
          preOperator: "Pre-operatore",
          applyOperatorScope: "Intervallo di rilevazione",
          addOperator: "Aggiungi operatore",
          noPreOperator: "Nessuno",
          from: "Sezione",
          to: "Sentenza al n.",
          end: "Sentenza",
          testRegular: "Prova espressioni regolari",
          test: "Test",
          matchSuccess: "Corrispondenza riuscita",
          matchError: "Partita non riuscita",
          querySementicTags: "Seleziona etichetta semantica",
          reQuerySementicTags: "Riseleziona le etichette semantiche",
          sementicTag: "Etichetta semantica",
          standardSentence: "Frase standard",
          similarity: "Somiglianza",
          actions: "Operazione",
          noFitTag: "Non ho trovato l'etichetta giusta,",
          addSemanticTag: "Aggiungere un nuovo tag semantico",
          pleaseEnterRegular: "Inserisci un'espressione regolare",
          pleaseEnterRegularText: "Inserisci il contenuto da verificare",
          newAssociateComponentTip:
            "Se il valore è di tipo numerico, utilizzare la funzionalità legacy",
        },
        filterManage: {
          noValueTip: "Nessun dato",
          allCall: "Tutte le chiamate",
          allDialogue: "Tutti i dialoghi",
          allWechat: "Tutte le sessioni",
          getFiltersListFailed:
            "Impossibile ottenere l'elenco dei filtri, riprova più tardi!",
          getFilterDetailFailed:
            "Impossibile ottenere i dettagli del filtro, riprova più tardi!",
          getAllCountFailed:
            "Impossibile ottenere il numero totale di chiamate/conversazioni, riprova più tardi!",
        },
        listManage: {
          searchPlaceHolder: "Inserisci una ricerca per parole chiave",
          filterConditions: "Condizione del filtro",
          records: "Registra",
          getRecordsFailed:
            "Impossibile ottenere il record, riprova più tardi!",
        },
        miniListManage: {
          getRecordsFailed:
            "Impossibile ottenere il record, riprova più tardi!",
        },
        applyTemplates: {
          title: "Modello di ispezione di qualità applicabile",
          collectionTitle: "Modello di acquisizione applicabile",
        },
        recordDetail: {
          showWaveAudio: "Mostra la registrazione",
          getDetailFailed:
            "Impossibile ottenere i dettagli, riprova più tardi!",
          getSmartTagsFailed:
            "Impossibile ottenere l'etichetta smart, riprova più tardi!",
          getTemplatesFailed:
            "Impossibile ottenere il modello applicabile, riprova più tardi!",
        },
        changeList: {
          sampleStatus: "Stato di campionamento",
          sampleAssignInspector: "Assegna ispettore di campionamento",
          reviewAssignInspector: "Assegna revisore",
          read: "Leggi",
          distributivePerson: "Persona allocata",
          totalScore: "Punteggio totale",
        },
        qualityCheckScore: {
          totalScore: "Punteggio totale",
          actualScore: "Punteggio",
          actualGrade: "Valutazione",
          appeal: "Denuncia",
          remark: "Osservazioni",
          manualRemark: "Osservazioni manuali",
          none: "Nessun dato dettagliato per l'ispezione di qualità",
          interactiveTip:
            "Utilizzare il riconoscimento dell'interazione per rilevare l'utilizzo del servizio clienti FAQ",
          interactiveIsEmptyTip:
            "Nessun contenuto di riconoscimento interattivo rilevato",
        },
        semanticMark: {
          semanticContent: "Marcatura della frase",
          markedSimilarTitel: "Etichette semantiche simili",
          markedExcludeTitle:
            "Contrassegnato per escludere tag semantici simili",
          taskTagTitle: "Etichette semantiche relative al compito",
          similarTagTitle:
            "Identifica tag semantici simili (somiglianza> 40%,Top 20)",
          delete: "Elimina",
          markSimilar: "Segna simili",
          markExclude: "Escludere simili",
          none: "Nessuno",
        },
        selectCallTime: {
          callTime: "Ora di inizio della conversazione/ora della sessione",
          callTimeRemind:
            "È possibile selezionare fino a un mese (31 giorni) di record di conversazione nell'ultimo anno.",
          callRecordCount:
            "Hai selezionato un totale di {0} record di chiamate/conversazioni",
          getCallRecordError:
            "Impossibile ottenere il record, riprova più tardi",
        },
      },
      informationEntities: {
        title: "Entità dell'informazione",
        index: { import: "Importa entità informative" },
        detail: {
          new: "Nuova entità di informazione",
          edit: "Modifica entità informazioni",
          name: "Nome",
          apiName: "Nome API",
          activeFlag: "Efficacia",
          remark: "Descrizione",
          logicExp: "Entità regolare",
          sentenceList: "Contenuto dell'entità",
          tip: "Il contenuto dell'entità supporta più voci, separate da spazi, che sono sinonimi della stessa entità.",
          remarkLengthError:
            "La lunghezza della descrizione non può superare 255",
          nameLengthError: "La lunghezza del nome non può superare i 40",
          entityDuplicateError: "Contenuto dell'entità duplicata",
        },
      },
      interactiveRecognition: {
        title: "Riconoscimento interattivo",
        classify: "Classificazione",
        addClassify: "Aggiungi categoria",
        editClassify: "Modifica categoria",
        index: { import: "Riconoscimento dell'interazione di importazione" },
        detail: {
          new: "Nuova interazione intelligente",
          edit: "Modifica interazione intelligente",
          type: "Tipo",
          standardQuestion: "Problemi standard",
          similarQList: "Problema simile",
          exceptSimilarQList: "Eliminare problemi simili",
          category: "Classificazione",
          targetType: "Obiettivo di rilevamento del problema",
          status: "Efficacia",
          standardAnswer: "Risposta standard",
          similarAList: "Risposte simili",
          wrongAList: "Risposta sbagliata",
        },
      },
      businessAnalysis: {
        title: "Analisi aziendale",
        tip1: "Analisi delle tendenze aziendali: la coordinata verticale è il numero di conversazioni, I. E. il numero totale di conversazioni che soddisfano le condizioni e la coordinata orizzontale è il tempo.",
        tip2: "Analisi della correlazione: analizzare la correlazione di più indicatori, dati di correlazione della visualizzazione della sospensione del mouse, volume di dati, correlazione, tasso di ripetizione",
        tip3: "Analisi di conversione: l'analisi di più indicatori può formare una relazione di conversione reciproca, i criteri di conversione per la stessa chiamata/conversazione, se è possibile colpire due diversi indicatori, è possibile considerare che i due indicatori hanno una relazione di conversione.",
      },
      knowledgeBase: {
        title: "Base di conoscenza",
        list: {
          add: "Aggiungi sottocategoria",
          delete: "Elimina classificazione",
          categoryName: "Nome categoria",
          createSuccess: "Sottocategoria creata con successo",
          keyWord: "Parole chiave",
        },
        detail: {
          type: "Tipo",
          status: "Stato",
          classification: "Classificazione",
          standardQuestion: "Problemi standard",
          answerDetectionRange: "Intervallo di rilevamento delle risposte",
          standardAnswer: "Risposta standard",
        },
      },
      callManage: {
        title: "Filtri di registrazione delle chiamate",
        listTitle: "Gestione chiamate",
        detailTitle: "Dettagli chiamata",
      },
      dialogueManage: {
        title: "Filtro del testo di conversazione",
        listTitle: "Gestione del dialogo",
        detailTitle: "Dettagli del dialogo",
      },
      weChatManage: {
        title: "Filtro sessione WeChat Enterprise",
        listTitle: "Gestione della sessione WeChat Enterprise",
        detailTitle: "Dettagli della sessione di Enterprise WeChat",
      },
      speechRecognition: {
        title: "Riconoscimento vocale",
        asr: "Riconoscimento vocale",
        asrRemind:
          "Lo spegnimento di questo interruttore causerà l'disabilitazione della funzione voce-testo e della funzione di ispezione della qualità della macchina.",
        asrConfig: "Filtrazione ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Quando abilitata, viene eseguita la trascrizione ASR di IM",
        asrConfigRemind:
          "Quando abilitate, solo le chiamate idonee eseguono contenuti ASR",
        correction: "Correzione degli errori intelligenti",
        correctionRemind:
          "Quando abilitato, il contenuto in \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words 'target =' _ blank'> Smart Thesaurus-sostantivi professionali </a>\" verrà utilizzato per forzare la conversione della correzione degli errori",
        correctionConfig: "Filtraggio correzione errore",
        advancedSettings: "Impostazioni avanzate",
        advancedSettingsTip:
          "Seleziona il modello ASR specificato per i dati specificati.",
        mandarinASRModel: "Mandarino modello ASR",
        cantoneseASRModel: "Modello cantonese ASR",
        englishASRModel: "Modello inglese ASR",
        correctionConfigRemind:
          "Quando abilitate, verranno corrette solo le chiamate che soddisfano le seguenti condizioni",
        customJudgeLogicIsNull: "La logica personalizzata non può essere vuota",
        redoCorrection: "Rieseguire la correzione degli errori",
        callTime: "Seleziona il tempo di conversazione",
        callTimeRemind:
          "È possibile selezionare fino a un mese (31 giorni) di record delle chiamate nell'ultimo anno.",
        callRecordCount: "Hai selezionato un totale di {0} record di chiamate",
        redoCorrectionSubmit: "Invia per l'esecuzione",
        callTimeError:
          "L'intervallo di tempo di chiamata selezionato non può superare un mese (31 giorni)",
        callRecordCountError:
          "Il numero di registrazioni delle chiamate è 0. Si prega di selezionare nuovamente l'orario di chiamata",
        redoCorrectionSuccess:
          "La correzione del nuovo errore è riuscita, vai al centro attività per visualizzarla",
        redoCorrectionError: "Rierrore non riuscito, riprova più tardi",
      },
      role: {
        title: "Gestione del ruolo",
        create: "Nuovo ruolo",
        detail: {
          title: "Ruolo",
          new: "Nuovo ruolo",
          view: "Visualizza ruoli",
          edit: "Modifica ruolo",
          navTabNames: {
            basicInfo: "Menu autorizzazioni",
            taskPermissions: "Permesso per l'attività di ispezione di qualità",
            dataPermissions: "Autorizzazioni dati",
          },
          basicInfo: { roleName: "Nome", permissions: "Autorizzazioni" },
          taskPermissions: {
            allTask: "Tutte le attività di ispezione di qualità",
            myCreatedTask:
              "Il compito di ispezione della qualità che ho creato",
          },
          dataPermissions: {
            dataPermissionsConfig:
              "Configurazione dell'autorizzazione dei dati",
            tips: "Questo ruolo può vedere i dati sotto la classificazione selezionata",
            select: "Seleziona categoria",
            selectPlaceholder:
              "Seleziona una classificazione che richiede l'autorizzazione alla visibilità dei dati",
          },
        },
      },
      dataMigration: {
        title: "Migrazione dei dati",
        dataUpload: "Migrazione dei dati",
        dataDowload: "Migrazione dei dati",
        error: "Seleziona un'attività di qualità da esportare",
        list: {
          info: "Seleziona l'attività di ispezione della qualità per uscire",
          listInfo:
            "Selezionare se eseguire la migrazione dei dati relativi all'attività di ispezione della qualità (ad esempio thesaurus intelligente, tag semantico, entità di informazione, interazione intelligente, ecc.)",
          noInfo: "Seleziona se spostare altri dati non QC",
          ownInfo: "Sposta solo le attività",
          everyInfo: "Attività di spostamento-out correlata",
          allInfo: "Spostati fuori da tutti",
          callFilter: "Filtro chiamate",
          talkFilter: "Filtro di dialogo",
          smartTag: "Etichetta intelligente",
        },
        upload: {
          info: "1. Fai clic sul pulsante Carica per caricare un file JSON conforme al formato.",
          uploadSuccess: "Caricamento riuscito",
          uploadError: "Caricamento non riuscito",
        },
      },
      dataAnalysis: {
        businessStatistics: "Statistiche aziendali",
        qualityEffect: "Salute dell'ispezione di qualità",
        qualityAnalysis: "Analisi dell'ispezione di qualità",
        generalInspecitionReport: "Relazione generale",
        fullAmountWork: "Carico di lavoro dell'ispettore di qualità",
        intelligentTagsAnalysis: "Analisi dell'etichetta",
        saleIntelligentTagsAnalysis: "Analisi intelligente dell'etichetta",
        generalInspecitionReportTip:
          "Dati totali: conta la quantità totale di dati di chiamata e conversazione entro il limite di tempo corrente e l'intervallo di attività, inclusi il numero e la percentuale di dati di ispezione intelligente della qualità, il numero e la percentuale di dati di campionamento manuale, il numero di ricorsi, la frequenza di campionamento e il grafico delle tendenze temporali.",
        groupTest: "Test di gruppo",
      },
      uploadDownloadList: {
        title: "Centro di missione",
        tabs: {
          upload: "Carica importazione",
          download: "Scarica Esportazione",
          taskCenter: "Esecuzione attività",
        },
      },
      uploadDownloadModel: {
        title: "Carica download",
        list: {
          clear: "Vuoto",
          success: "Successo",
          fail: "Impossibile",
          hand: "In corso",
          noInfo: "Nessun dato",
        },
      },
      billingCenter: {
        title: "Centro di fatturazione",
        export: "Esportazione",
        limit: "Limite promemoria",
        tips: "I risultati della fatturazione saranno distorti a causa dell'arrotondamento, solo per riferimento",
        dashboard: {
          remainingQuota: "Contingente rimanente attuale",
          usedQuota: "Quota utilizzata cumulativa",
          voiceQuality: "Ispezione di qualità vocale:",
          voice: "Voce:",
          textQuality: "Ispezione della qualità del testo:",
          text: "Testo:",
          hour: "Ore",
          tenThousandHour: "Milioni di ore.",
          day: "Giorno",
          cutoff: "Taglio",
          warning:
            "(La quota rimanente è insufficiente, si prega di ricaricare il prima possibile)",
          isClosed: "(Stato chiuso)",
        },
        table: {
          quotaRecord: "Registro delle quote",
          timeFilter: "Selezionare una data:",
          search: "Interrogazione",
          reset: "Reset",
          id: "Numero di serie",
          type: "Tipo",
          qualityType: "Tipo di ispezione di qualità",
          quotaChange: "Cambia",
          surplus: "Equilibrio",
          changeTime: "Tempo",
          voiceQuality: "Ispezione della qualità vocale",
          textQuality: "Ispezione della qualità del testo",
          sizeChangeText: "Visualizzazione per pagina",
          used: "Uso",
          give: "Concessione",
          deduction: "Detrazione",
          callTime: "Tempo di conversazione",
          count: "Elaborazione del volume di dati",
          page: "Pagina {1} di {0}",
        },
        limitModal: {
          call: "La quota di ispezione della qualità vocale è inferiore",
          callFix: "Ore per ricordare",
          dialogue:
            "Il tempo rimanente dell'ispezione della qualità del testo è inferiore",
          dialogueFix: "Giorni da ricordare",
        },
      },
      messageCenter: {
        replyTitle: "Rispondi al mio",
        answerTitle: "@ My",
        shareTitle: "Condividi il mio.",
      },
      columnsManage: { title: "Gestione del campo di ispezione della qualità" },
      recordUpload: {
        list: {
          task: "Compito di ispezione di qualità",
          user: "Operatore",
          uploadTime: "Tempo di caricamento",
          endTime: "Ora di fine",
          total: "Caricamento totale",
          success: "Successo dell'ispezione di qualità",
          failed: "Ispezione di qualità non riuscita",
          progress: "Progresso dell'ispezione di qualità",
          inProgress: "In corso",
          finished: "Completato",
        },
        steps: {
          uploading: "Carica file di registrazione",
          baseInfo: "Inserisci le informazioni di base",
          start: "Avvia l'ispezione di qualità",
        },
        uploadComponent: {
          dragger: "Trascina e rilascia per aggiungere file",
          or: "O",
          selectFile: "Seleziona file",
          des: "Supporto wav, formato mp3, frequenza di campionamento di 8000Hz, file a 16 bit",
          limit1: "Carica al massimo una volta",
          limit2: "File, ogni dimensione del file non supera",
          uploading: "Caricamento",
          finished: "Caricamento completo",
          success: "Successo",
          failure: "Impossibile",
        },
        baseInfo: {
          task: "Seleziona attività di qualità",
          type: "Seleziona modulo di registrazione",
          agentSide: "Il canale in cui si trova il servizio clienti",
          excel: "Carica i dati lungo la strada",
          checkFileInfo: "Verifica le informazioni del file",
          excelFileError: "Carica i dati lungo il percorso",
          checking: "Calibrazione in...",
          reCheck: "Ri-calibrazione",
          success: "Verifica riuscita",
          excelUoloadFail: "Impossibile caricare i dati lungo il percorso.",
          tips: {
            tip1: "1. Il nome del file di registrazione in ecxel deve essere uguale al nome della registrazione caricata e non può avere lo stesso nome.",
            tip2: "2. Il sistema controlla il duplicato con il nome del file di registrazione e l'ID chiamata. I dati ripetuti porteranno a un errore di ispezione di qualità.",
            tip3: "3. Per il modello di registrazione Excel scaricato, il colore del carattere è rosso, che è un campo obbligatorio.",
            tip4: "4. Il formato del campo del tipo di tempo è: aaaa-mm-gg hh:mm:ss, per esempio, 2021-04-20 17:36:30",
          },
        },
        title: "Caricamento della registrazione locale",
        checkTitle: "Ispezione della qualità della registrazione",
        start: "Avvia l'ispezione di qualità",
        disabledTips:
          "Verificare il file di registrazione prima dell'ispezione di qualità",
        tips: "Il file di registrazione con lo stesso nome sovrascrive i risultati dell'ispezione di qualità e le informazioni di base.",
        tips1: "Trascina il file qui o fai clic su Carica",
        tips2: "Supporto",
        tips3: "Formato",
        type: "wav、mp3",
        tips4: "Carica al massimo una volta",
        tips5: "File, ogni dimensione del file non supera",
        tipsOverSize:
          "La coda corrente è piena, rimuovi i file nella coda prima di caricarli.",
        size: "500M",
        count: "20",
        uploaded: "File di registrazione caricato",
        uploading: "({0}%)",
        uploadFinished: "(Caricamento completo)",
        bottomTitle: "Raccolta delle informazioni di registrazione:",
        recordType: "Selezionare il modulo di registrazione:",
        double: "Registrazione delle chiamate a doppio canale",
        single: "Registrazione delle chiamate a canale singolo",
        recordBaseInfo: "Carica le informazioni di base della registrazione:",
        addBtn: "Aggiungi file",
        downloadTemplate: "Download del modello. excel",
        checkRecord: "Verifica le informazioni di registrazione",
        checking: "Calibrazione in...",
        reCheck: "Ri-calibrazione",
        checkAll: "Seleziona tutto",
        cancelCheckAll: "Annulla Seleziona tutto",
        delete: "Elimina",
        deleteLotSize: "Elimina lotto",
        agentChannel: "Canale del servizio clienti:",
        left: "Canale di sinistra",
        right: "Canale destro",
        donotKonw: "Non so",
        mission: "Selezionare un'attività di qualità:",
        uploadFail: "Caricamento non riuscito",
        repeat: "File duplicato",
        overCount: "Il numero di file supera il limite",
        overSize: "La dimensione del file supera il limite",
        uoloadFail: "Impossibile caricare su cloud",
        formatError: "Errore di formato",
        seccess: "Verifica riuscita",
        fail: "Verifica non riuscita",
        recordChecking:
          "Registrazione nell'ispezione di qualità, per favore più tardi...",
        recordCheckSuccess:
          "Completata l'ispezione della qualità della registrazione",
        recordCheckFail:
          "Controllo della qualità della registrazione non riuscito. Verifica se la registrazione è duplicata o non valida",
        backToIndex: "Torna alla lista di caricamento",
        recordCheckIsStarted:
          "È attualmente in corso l'esecuzione di un'attività di ispezione della qualità. Si prega di avviare una nuova attività al termine dell'attività corrente.",
      },
      customerServicePortrait: {
        title: "Ritratto del servizio clienti",
        tip1: "La legge di distribuzione della lunghezza della frase: la distribuzione della lunghezza della frase viene conteggiata in segmenti in base al numero di parole nella lunghezza della frase e solo al servizio clienti",
        tip2: "Legge sulla distribuzione della velocità: la distribuzione della velocità, in base alla velocità (parole/minuto), le statistiche del segmento, calcolare solo il servizio clienti",
        tip3: "Legge sulla distribuzione del tempo di risposta: la distribuzione del tempo di risposta, viene calcolata solo la situazione di risposta del servizio clienti.",
        tip4: "Conformità alle ispezioni di qualità: tasso di conformità dei punti di ispezione di qualità",
        tip5: "Punteggio delle attività di ispezione di qualità media: punteggio medio delle attività di ispezione di qualità",
        contol: {
          date: "Selezionare una data:",
          today: "Oggi",
          thisMonth: "Questo mese",
          lastMonth: "Il mese scorso",
          thisQuarter: "Questo trimestre",
          lastQuarter: "Ultimo quarto",
          thisYear: "Quest'anno",
          lastYear: "L'anno scorso",
          customer: "Servizio clienti:",
          search: "Interrogazione",
          reset: "Reset",
          export: "Esportazione",
          indicator: "Gestione degli indicatori",
          selectPlaceholder:
            "Seleziona il servizio clienti che deve essere confrontato",
          warning: "Selezionare fino a 5 agenti per il confronto",
          warning2: "Seleziona almeno un agente per chiedere informazioni",
          dataWarning:
            "È possibile interrogare solo i dati fino a 366 giorni dopo la data di inizio.",
          seccuss: "Esportazione record riuscita",
        },
        echarts: {
          distributionLaw: "Legge di distribuzione",
          count: "Numero di volte",
          bout: "Tempi",
        },
        portrait: {
          ability: "Dimensione della capacità del servizio clienti",
          option: "Configurazione",
          abilityOption: "Configura le dimensioni della capacità",
          placeholder: "Ricerca per parole chiave dimensione capacità di input",
          save: "Salva",
          warning:
            "Seleziona almeno 3 indicatori da configurare e puoi selezionare fino a 10 indicatori",
        },
        label: { title: "Etichetta del servizio clienti" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Classifica del servizio clienti",
        select: {
          date: "Selezionare una data:",
          today: "Oggi",
          thisMonth: "Questo mese",
          lastMonth: "Il mese scorso",
          thisQuarter: "Questo trimestre",
          lastQuarter: "Ultimo quarto",
          thisYear: "Quest'anno",
          lastYear: "L'anno scorso",
          group: "Gruppo di servizio clienti:",
          groupPlaceholder: "Seleziona un gruppo di agenti",
          customer: "Servizio clienti:",
          customerPlaceholder: "Seleziona il servizio clienti",
          search: "Interrogazione",
          reset: "Reset",
          compared: "Contrasto",
          export: "Esportazione",
          column: "Colonna",
          warning: "Selezionare fino a 5 agenti per il confronto",
          warning2: "Seleziona almeno un agente",
          dataWarning:
            "È possibile interrogare solo i dati fino a 366 giorni dopo la data di inizio.",
          selected: "Agente selezionato:",
          seccuss: "Esportazione record riuscita",
        },
        compared: {
          title: "Seleziona il servizio clienti",
          return: "Annulla",
          compared: "Contrasto",
          tips: "Seleziona 2-5 agenti per il confronto",
          warning: "Solo 2-5 agenti sono supportati per il confronto",
        },
        transfer: {
          leftTitle: "Elenca l'articolo",
          rightTitle: "Mostra articolo",
        },
        table: {
          page: "Pagina {1} di {0}",
          actionTitle: "Operazione",
          action: "Visualizza",
        },
      },
      conceptWord: {
        title: "Concetto parole",
        new: "Nuova parola concetto",
        control: {
          search: "Ricerca:",
          searchPlaceholder: "Ricerca nome o parola chiave",
          commit: "Interrogazione",
          reset: "Reset",
          import: "Importazione",
          export: "Esportazione",
          seccuss: "Esportazione record riuscita",
        },
        modal: { title: "Importa parole concettuali" },
        table: {
          sizeChangeText: "Visualizzazione per pagina",
          page: "Pagina {1} di {0}",
          edit: "Modifica",
          delete: "Elimina",
          request:
            "Dopo l'eliminazione, non può essere recuperato. Sei sicuro di volerlo eliminare?",
          cancel: "Annulla",
          title: {
            conceptWord: "Concetto parole",
            describeWordsNum: "Numero di descrittivi",
            updateTime: "Tempo di aggiornamento",
            status: "Efficacia",
            effective: "Efficace",
            invalid: "Non valido",
            action: "Operazione",
          },
        },
        detail: {
          title: {
            new: "Nuova parola concetto",
            edit: "Modifica parole concettuali",
          },
          name: "Nome della parola di concetto",
          namePlaceholder: "Inserisci il nome di una parola concettuale",
          effectiveness: "Efficacia",
          effective: "Efficace",
          invalid: "Non valido",
          description: "Parole descrittive:",
          descriptionPlaceholder: "Inserisci un descrittore",
          add: "Aggiungi",
          repeat: "Contenuto duplicato",
          empty: "Le parole descrittive non possono essere vuote",
          recommendEmpty:
            "Non ci sono più descrittivi consigliati, inseriscili e aggiungili manualmente.",
          recommend: "Descrittore intelligente delle raccomandazioni",
          cancel: "Annulla",
          change: "Cambiare un gruppo",
          descriptionSelected: "Descrittore aggiunto:",
          save: "Salva",
          success: "Concetto di parole salvate con successo",
          warning: "Il nome della parola concettuale non può essere vuoto",
          warning2: "Aggiungi almeno 1 descrittore",
          submitBtnHandle: {
            title:
              "Il descrittore selezionato non è stato aggiunto. Il salvataggio eliminerà il descrittore non aggiunto.",
            content:
              "Fare clic su OK per tornare alla home page della parola concettuale, confermare di continuare?",
          },
        },
      },
      smartTools: {
        btn: "Strumenti intelligenti",
        title: {
          similarWords: "Parole correlate trovate",
          search: "Per parola",
          semanticTags: "Etichetta semantica",
        },
        sentenceSearch: {
          placeholder:
            "Inserisci la ricerca per parole chiave, supporta più parole chiave",
          more: "Visualizza testo completo",
          pageUp: "Pagina precedente",
          pageDown: "Pagina successiva",
          total: "Totale {0} record",
        },
        similarDiscover: {
          placeholder: "Inserisci una parola chiave",
          copyed: "Copiato",
          none: "Nessuna parola pertinente, prova altre parole chiave",
        },
        smartTags: {
          searchPlaceHolder: "Inserisci parole chiave o frasi",
          createSmartTag: "Nuovo",
          createClusteringSmartTag: "Crea nuovo con {0} dati",
          addFromKeyword: "Aggiungi contenuto di ricerca a frasi simili",
          hit: "Colpisci",
          similar: "Simile",
          name: "Nome dell'etichetta semantica",
          namePlaceHolder: "Inserisci il nome di un'etichetta semantica",
          recallWords: "Richiama la parola",
          recallWordsPlaceHolder: "Inserisci una parola chiave di richiamo",
          recallRegular: "Richiamiamo regolarmente",
          recallRegularPlaceHolder: "Inserisci Recall Regular",
          rules: "Regole",
          standardSentences: "Frase standard",
          standardSentencesPlaceHolder: "Inserisci una frase standard",
          similaSentences: "Frasi simili",
          similaSentencesPlaceHolder: "Inserisci una frase simile",
          notSaveError: "Salva l'etichetta semantica prima di cercare",
          add: "Aggiungi",
          newPage: "Apri nella nuova pagina",
          querryError:
            "Campi simili esistono nell'elenco di generalizzazione (configurazione avanzata di vincoli di deduplicazione simili)",
          detailTitle: "Dettagli dell'etichetta semantica",
        },
      },
      trainingCenter: {
        title: "Centro di formazione",
        edit: "Modifica",
        delete: "Elimina",
        seleteAll: "Seleziona tutto",
        cancelCheckAll: "Annulla Seleziona tutto",
        add: "Aggiungi",
        remove: "Rimuovi",
        save: "Salva",
        cancel: "Annulla",
        fastTraining: {
          title: "Formazione rapida",
          titleDes:
            "L'allenamento rapido è progettato per aiutare le etichette semantiche a generalizzare rapidamente fino a 20 frasi simili",
          semanticTags: "Etichetta semantica",
          semanticTagsPlaceholder:
            "Inserisci la ricerca per parole chiave di processo",
          standardSentence: "Frase standard:{0}",
          similarSentencesPlaceholder:
            "Inserisci frasi simili da aggiungere o cercare",
          similarSentencesAdd: "Aggiungi",
          similarSentencesSearch: "Ricerca",
          similarSentences: "Frase simile aggiunta",
          trainingCount: "Conteggio della formazione:{0}",
          similarSentencesRecommend: "Frasi simili consigliate",
          noResp:
            "Nessuna etichetta semantica pertinente, modifica la parola chiave",
          similarSentencesModalTitle: "Ricerca frasi simili",
          moreInfo: "Visualizza contesto",
          moreInfoBtn: "Contesto",
          modalSearchType: {
            all: "Tutto",
            customer: "Servizio clienti",
            client: "Cliente",
          },
          repetWarning:
            "La stessa frase simile esiste già, per favore non aggiungerla ripetutamente.",
          repetRemoveTip:
            "C' è un contenuto duplicato nella frase simile salvata, che è stata automaticamente deduplicata.",
        },
        trainingHistory: {
          title: "Storia della formazione",
          select: {
            date: "Seleziona ora:",
            today: "Oggi",
            thisMonth: "Questo mese",
            lastMonth: "Il mese scorso",
            thisQuarter: "Questo trimestre",
            lastQuarter: "Ultimo quarto",
            thisYear: "Quest'anno",
            lastYear: "L'anno scorso",
            search: "Interrogazione",
            reset: "Reset",
          },
          table: {
            time: "Tempo",
            user: "Operatore",
            tag: "Etichetta semantica",
            similarCount: "Numero di frasi simili",
            deleteCount: "Numero di frasi rimosse",
            page: "Pagina {1} di {0}",
          },
        },
        classifiedTraining: {
          title: "Formazione per la classificazione",
          tagsGrounp: "Raggruppamento etichette",
          placeHolder: "Seleziona",
          cellTime: "Seleziona ora",
          search: "Interrogazione",
          reset: "Reset",
          classifiedFlag: "Formazione per la classificazione",
          num: "Numerico",
          gas: "Percentuale",
          noData: "Formazione non classificata",
          noDataHeight:
            "I dati non classificati sono alti e la formazione è urgente.",
          noDataSmall:
            "I dati non classificati rappresentano una percentuale relativamente alta e si consiglia la formazione.",
          noDataDays: "{0} giorni prima dell'ultima esecuzione",
          training: "Formazione",
          start: "Inizia",
          addClass: "Aggiungi classificazione",
          custom: "Filtraggio avanzato",
          marked: "Dati etichettati Errata",
          markedHeight:
            "L'errore non è stato eseguito per {0} giorni e si consiglia di farlo.",
          markedSmall: "Errata non viene eseguita per {0} giorni. Urge Errata.",
          mark: "Errata",
          dataCheck: "Verifica dei dati classificati",
          dataCheckSmall:
            "K-L divergenza è superiore a 0,5, si consiglia di allenarsi.",
          dataCheckHeight:
            "K-L divergenza è superiore allo 0,8 ed è urgente la formazione.",
          check: "Calibrazione",
          history: "Storia callout",
          historyTime:
            "L'annotazione e la modifica giornaliere eseguiranno automaticamente l'allenamento del modello alle 2:00.",
          today: "Taggato Oggi",
          article: "Articolo",
          none: "Nessuno",
          historyCheck: "Callout totale storico",
          look: "Visualizza record",
          historyluate: "Recaltazione dei dati storici",
          startluate: "Calcolo della gru",
          classData: "Dati classificati",
          classStatus: "Progressi della formazione",
          next: "Prossimo articolo",
          prev: "Articolo precedente",
          finish: "Formazione completa",
          stop: "Fine della formazione",
          classDetail: "Dettagli della formazione",
          trainingNone: "Nessuna formazione per l'etichetta intelligente",
        },
        classifiedTrainingHistory: {
          title: "Storia della formazione",
          time: "Seleziona ora:",
          tag: "Tipo di etichetta:",
          user: "Operatore:",
          data: "Selezionare un'origine dati:",
          search: "Interrogazione",
          searchBar: "Ricerca:",
          searchBarPlaceholder:
            "Inserisci ID chiamata/ricerca ID conversazione",
          reset: "Reset",
          export: "Esportazione",
          all: "Tutto",
          select: {
            today: "Oggi",
            thisMonth: "Questo mese",
            lastMonth: "Il mese scorso",
            thisQuarter: "Questo trimestre",
            lastQuarter: "Ultimo quarto",
            thisYear: "Quest'anno",
            lastYear: "L'anno scorso",
            search: "Interrogazione",
            reset: "Reset",
          },
          table: {
            user: "Operatore",
            date: "Tempo di funzionamento",
            data: "Fonte dati",
            id: "ID chiamata/ID conversazione",
            tagType: "Raggruppamento etichette intelligenti",
            tagName: "Nome dell'etichetta",
            state: "Stato",
            sizeChangeText: "Visualizzazione per pagina",
            page: "Pagina {1} di {0}",
            call: "Chiama",
            dialogue: "Dialogo",
          },
        },
        clusteringAnnotation: {
          title: "Annotazione di clustering",
          left: { title: "Risultati di raggruppamento" },
          right: {
            title: "Frasi di clustering",
            checkAll: "Seleziona tutte queste pagine",
            tagging: "Callout",
            detail: "Visualizza",
            meaningless: "Ignora",
            meaninglessTips:
              "I risultati del clustering ignorato non verranno visualizzati in futuro. Sei sicuro di volerli ignorare?",
            cancel: "Annulla",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Gestione dei clienti",
          tabTitle: "Customer Service Center",
        },
        wechatClientManage: {
          title: "Micro cliente aziendale",
          table: {
            id: "ID cliente",
            name: "Soprannominazione del cliente",
            tag: "Etichetta del cliente",
          },
        },
      },
      collectionRule: {
        title: "Regole di raccolta",
        form: {
          info: "Raccolta di informazioni intelligenti",
          infoPlaceholder: "Seleziona una categoria",
        },
        table: {
          name: "Nome del campo",
          props: "Attributi del cliente",
          type: "Tipo di campo",
          desc: "Descrizione del campo",
        },
      },
      dataAdd: {
        title: "Aggiungere dati",
        types: {
          auto: "Aggiungi automaticamente",
          manual: "Aggiungi manualmente",
        },
      },
      wechatStaff: {
        table: {
          name: "Nome",
          part: "Dipartimento",
          phone: "Numero di cellulare",
          email: "Cassetta postale",
          wechartId: "ID WeChat aziendale",
          status: "Stato sul posto di lavoro",
        },
        info: "Seleziona il dipartimento che deve prima sincronizzare i dati",
        synSuccess: "Sincronizzazione riuscita",
        init: "Inizializzazione",
        syn: "Sincronizzazione",
      },
      appealHistory: {
        appealTime: "Tempo di appello",
        cancelTime: "Tempo di revoca",
        reviewTime: "Tempo di revisione",
        inspector: "Ispettore di qualità",
        result: "Esito della denuncia",
        complainResult:
          "Sono stati appellati {0} articoli per l'ispezione della qualità, {1} sono stati appellati con successo e {2} sono stati respinti",
        reason: "Motivo della denuncia",
        rejection: "Motivo del rifiuto",
        cancel: "Revocato",
        tip: "Confermi di ritirare il reclamo?",
      },
      appealSdk: {
        time: "Tempo di dialogo",
        externalIdCall: "ID registro chiamate",
        externalIdText: "ID record di conversazione",
        customerNickName: "Soprannominazione del cliente",
        readStatus: "Stato della revisione",
        status: "Stato di reclamo",
        isManual: "Ispezione manuale della qualità",
        complainButtonInfo:
          "{0} ricorsi ammessi, {1} ricorsi già presentati, {2} ricorsi rimanenti. {3} può presentare ricorso prima",
        submit: "Invia un reclamo",
        complain: "Denuncia",
        allComplain: "Reclami tutti",
        pendingSubmit: "Da inviare",
        taskIds: "Compito di ispezione di qualità",
        graded: "Valutazione",
        complainSuccess: "Reclamo inviato correttamente",
        appealSdkIndex: {
          title: "Risultati intelligenti dell'ispezione di qualità",
        },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Impossibile ottenere le informazioni dell'elenco dei filtri, controlla la connessione di rete o riprova più tardi!",
      columns: "Colonna",
      filter: "Filtro",
      noSearchResult: "Nessun record trovato",
      pleaseSearch: "Seleziona un filtro e prova a cercare",
      sift: "Filtro",
      create: "Nuovo",
      search: "Ricerca",
      createTaskTitle: "Nuova attività",
      title: "Titolo",
      deadline: "Scadenza",
      principal: "Persona in carica",
      enqueueTime: "Tempo di ingresso",
      computeError: "Calcolo non riuscito, attendi!",
      sum: "Somma",
      max: "Massimo",
      min: "Minimo",
      avg: "Media",
      count: "Conteggio",
      maxDate: "Massimo (ultimo)",
      minDate: "Minimo (più vecchio)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Impossibile ottenere l'indirizzo del link Insight, riprova più tardi!",
      },
      template: {
        learningReminder: "Promemoria di apprendimento",
        subscriptionDynamics: "Dinamica dell'abbonamento",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Gestione attività", chaneg: "Interruttore" },
        qualityCheckTemplate: {
          text: "Modello di ispezione di qualità",
          collectText: "Modello di acquisizione",
          condition: { text: "Condizioni di ispezione di qualità" },
          checkPoint: { text: "Configurazione delle regole" },
          automaticAdd: { text: "Automazione dei processi" },
          manualAdd: { text: "Aggiungi manualmente" },
          roleConfig: { text: "Configurazione ruolo" },
          basicInfo: { text: "Informazioni di base" },
          smartWordsLibrary: { text: "Thesaurus intelligente" },
          grade: { text: "Valutazione dell'ispezione di qualità" },
          collectionRule: { text: "Regole di raccolta" },
          addData: { text: "Aggiungere dati" },
          collectionList: { text: "Elenco di acquisizione" },
        },
        qualityCheckWorkbench: {
          text: "Banco da lavoro per l'ispezione della qualità",
          wechatText: "Banco da lavoro per analisi",
          informationCollectText: "Acquisizione Workbench",
          qualityInspectionList: {
            text: "Elenco di ispezione di qualità",
            wechatText: "Elenco analisi",
            informationCollectText: "Elenco di acquisizione",
          },
          condition: { text: "Elenco di ispezione di qualità (obsoleto)" },
          reviewList: { text: "Elenco di revisione" },
          spotCheckList: { text: "Elenco di campionamento" },
          caseBaseList: { text: "Libreria del caso" },
          testSetList: { text: "Set di test di ispezione di qualità" },
        },
        statisticsAndReports: {
          text: "Statistiche e relazioni",
          wholeReport: { text: "Relazione complessiva" },
          groupReport: { text: "Rapporto del gruppo del servizio clienti" },
          agentReport: { text: "Rapporto sul servizio clienti" },
          customerServiceExecution: { text: "Analisi del servizio clienti" },
          analysisOverview: { text: "Panoramica dell'analisi" },
          inspectionItemsAnalysis: {
            text: "Analisi degli elementi di ispezione della qualità",
          },
        },
        wechatQa: {
          text: "Ispezione di qualità Enterprise WeChat",
          task: { text: "Attività di analisi" },
          baseInfo: { text: "Informazioni di base" },
          checkPoint: { text: "Configurazione del modello di analisi" },
          setting: { text: "Configurazione dell'analisi di Enterprise WeChat" },
          automaticAdd: { text: "Automazione dei processi" },
          manualAdd: { text: "Aggiungi manualmente" },
        },
        clientCenter: { text: "Centro assistenza clienti" },
      },
      conditionDictionaries: {
        title: "Tutte le condizioni",
        searchDefaultText: "Inserisci un ID o un nome",
        id: "ID",
        name: "Nome",
        conditionDetailedShow: "Espandere",
        conditionDetailedHide: "Stow",
        remark: "Descrizione:",
        checkRole: "Ruolo:",
        operator: "Operatore:",
        operatorLogic: "Logica:",
      },
      audioTextView: {
        customer: "Cliente",
        agent: "Servizio clienti",
        unsupportedFormat: "Questo formato non è supportato.",
      },
      checkPointClassifications: {
        add: "Aggiungi categoria",
        name: "Nome",
        enterToSave: "Premi Invio per salvare",
        highestScore: "Cap punteggio massimo",
        minimumScore: "Punteggio minimo Limite inferiore",
        defaultMinScore: "L'impostazione predefinita è 0 punti",
        minimumScoreTitle: "Limite di punteggio inferiore",
        beginScore: "Valore complessivo del punteggio iniziale",
        beginScoreTitle: "Valore del punteggio iniziale",
        scoreSetTip:
          "Il punteggio massimo è inferiore alla somma di tutti i punteggi. È possibile selezionare:",
        ignoreExcessSpillover: "Ignora l'eccesso di trabocco",
        automaticWeightCalculation: "Contabilità automatica del peso",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Campo" },
    checkRange: {
      numberTipOne: "Sezione",
      numberTipTwo: "Sentenza",
      numberTipThree: "Sentenza al n.",
      conditionLimit: "Restrizioni condizionali",
      roleLimit: "Restrizioni di ruolo",
      contextLimit: "Restrizioni delle frasi",
      notZero: "L'input 0 non è supportato per la restrizione della frase",
      startMustLessThanEnd:
        "La posizione della frase iniziale deve essere inferiore alla posizione della frase finale",
    },
    waveAudio: {
      play: "Gioca",
      pause: "Pausa",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Inserisci",
      pleaseEnterRegular: "Inserisci un'espressione regolare",
      pleaseEnterGrammar: "Inserisci una regola di grammatica",
      pleaseEnterTestText: "Inserisci il contenuto da verificare",
      pleaseEnterLoudness: "Inserisci il volume di decibel",
      pleaseEnterRangeAbility: "Inserisci l'intervallo di modifica",
      afterHit: "Controlla dopo il colpo",
      pleaseEnterContext: "Inserisci una dichiarazione di riferimento",
      wordNumber: "Gap di parole",
      intervalSecond: "Intervallo di tempo maggiore",
      minute: "Ogni minuto.",
      wordUnit: "Parola",
      test: "Test",
      testRegular: "Prova espressioni regolari",
      testGrammar: "Test grammaticale",
      testGrammarError: "Test grammaticale fallito",
      regularError: "Errore di espressione regolare, inserisci nuovamente",
      pleaseEnterSemanticSentence:
        "Inserisci una frase che richiede il rilevamento semantico",
      pleaseEnterAssociate:
        "Fare riferimento alle istruzioni a destra per costruire un'espressione data-along",
      querySemanticTags: "Seleziona etichetta semantica",
      reQuerySemanticTags: "Riseleziona le etichette semantiche",
      semanticTags: "Etichetta semantica",
      standardSentence: "Frase standard",
      semanticName: "Etichetta semantica",
      sentence: "Frase standard",
      similarity: "Somiglianza",
      actions: "Operazione",
      tipOne: "Non ho trovato l'etichetta giusta,",
      tipTwo: "Aggiungere un nuovo tag semantico",
      notLessThan: "Non meno",
      notGreaterThan: "Non maggiore",
      ignoreLessThan: "Ignora di meno",
      wordUnitSet: "Frase delle parole",
      speedWordNotNull:
        "Il numero minimo di parole e il numero massimo di parole nel rilevamento della velocità vocale non possono essere entrambi vuoti.",
      speedWordMinValueLessThanMaxValue:
        "Il numero minimo di parole nel rilevamento della velocità vocale non può essere maggiore del numero massimo di parole.",
      speechGrabbingIntervalTip: "Intervallo> =",
      speechLengthTip: "La frase è lunga> =",
      msUnit: "ms",
      sUnit: "Secondi",
      grabIntervalNotNull: "L'intervallo snatch non può essere vuoto",
      grabLengthNotNull: "La frase non può essere vuota.",
      pleaseSelectEntity: "Seleziona un'entità di informazione",
      pleaseSelectOperator: "Seleziona un'azione",
      pleaseSelectBasicInfo: "Seleziona le informazioni di base",
      pleaseEnterFixedValue: "Inserisci un valore fisso",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Corrispondenza riuscita",
      matchError: "Partita non riuscita",
      mindetectionStatement: "Dichiarazione minima di rilevazione",
      conceptWordholder: "Seleziona una parola concettuale",
      elementHolder: "Inserisci un elemento",
      associateMedol: { tabs: { data: "Dati", operator: "Operatore" } },
      repet: "Ripeti",
      bout: "Tempi",
      ignore: "Ignora di meno",
      ignoreAffix: "Frase delle parole",
      associateData: "Dati di follow-the-road",
      pretreatmentData: "Preelaborazione dei dati",
      reviewTaskData: "Rivedi i dati delle attività",
      taskData: "Dati delle attività di ispezione della qualità",
      businessData: "Documenti aziendali",
      functionOperator: "Funzione Operatori",
      operation: "Operatore di funzionamento",
      notAnyMatch: "Quando eventuali problemi non vengono colpiti:",
      times: "Numero di occorrenze",
      newAssociateDataTip:
        "Se il valore è di tipo numerico, utilizzare la funzionalità legacy",
    },
    conditionGather: {
      cycle: "Ciclo",
      rlue: "Regole",
      delete: "Rimuovi",
      sampling: "Estrazione",
      item: "Articolo",
      percent: "%",
      samplingSame: "Stesso numero di estrazione per agente",
      samplingRecheck:
        "Consentire l'estrazione ripetuta di articoli campionati",
      dateUnit: "No.",
      samplingTip:
        "Regole diverse riguardano la stessa chiamata che non verrà estratta ripetutamente e i record che sono stati estratti ma non controllati non verranno estratti di nuovo.",
      add: "Nuova regola",
    },
    ruleGather: {
      title: "Regole",
      screeningCondition: "Condizione del filtro",
      addRule: "Nuova regola",
      inspector: "Ispettore di qualità",
      ruleTip:
        "La modalità di allocazione della regola prima, dall'alto verso il basso, non ripete l'allocazione, all'interno della regola o non corrisponde a nessun elemento della regola verrà allocato in modo casuale.",
      moveUp: "Spostati",
      moveDown: "Spostati verso il basso",
      top: "Top",
    },
    pushRulesGather: {
      title: "Regole",
      delete: "Rimuovi",
      newPush: "Nuovo Push",
      newRule: "Nuova regola",
      sampling: "Estrazione",
      item: "Articolo",
      percent: "%",
      samplingSame: "Stesso numero di estrazione per agente",
      dateUnit: "No.",
      screeningCondition: "Condizione del filtro",
      email: "Cassetta postale",
      emailPlaceholder: "Inserisci le cassette postali, separate da virgole",
      updateType: "Tipo di aggiornamento",
      pushDetail: "Dettagli push",
      pushUrl: "URL push",
      pushWay: "Tipo di spinta",
      pushMethod: "Modalità Push",
      pushContentType: "Formato dati",
      pushData: "Spingere i dati",
      additionalData: "Dati aggiuntivi",
      inspectionType: "Tipo di ispezione di qualità",
      all: "Tutto",
    },
    taskAdditionalData: {
      newData: "Nuovi dati",
      delete: "Rimuovi",
      placeholder: "Inserisci un nome",
      dataPlaceholder: "Inserisci il contenuto",
    },
    UdeskQaReact: {
      loadSdkError: "Caricamento dell'SDK non riuscito, riprova più tardi!",
    },
    gearOptionList: { evaluation: "Valutazione" },
    tableComponentTransfer: {
      leftTitle: "Elenca l'articolo",
      rightTitle: "Mostra articolo",
    },
    kmTinymce: {
      link: "Inserisci/Modifica link",
      modalTitle: "Inserire Link",
      href: "Indirizzo",
      text: "Mostra testo",
      title: "Titolo",
      target: "Modalità aperta",
      none: "Nessuno",
      newWindow: "Apri in una nuova finestra",
      buttonStyle: "Stile pulsante",
      close: "Chiudi",
      open: "Apri",
      answerMessage: "Salta/Invia messaggio",
      answerMessageTitle: "Invia pulsante messaggio",
      answerMessageText: "Mostra testo",
      answerMessageContent: "Contenuto del messaggio",
      answerMessageButtonStyle: "Stile pulsante",
      answerMessageTips:
        "Nota: Il pulsante Invia messaggio supporta solo il canale web, si prega di configurarlo con attenzione.",
      answerSwitchStaff: "Manuale di salto/svolta",
      answerSwitchStaffTitle: "Chiudere il pulsante manuale",
      answerSwitchStaffText: "Mostra testo",
      answerSwitchStaffRule: "Regole del pulsante",
      answerSwitchStaffButtonStyle: "Stile pulsante",
      answerSwitchStaffTips:
        "Nota: Il pulsante interruttore manuale supporta solo il canale web, si prega di configurarlo con attenzione.",
      textButton: "Pulsante di testo",
      borderButton: "Bottone Incorniciato",
      message: "Invia messaggio",
      switchStaff: "Girare manuale",
      messageError: "Il contenuto del pulsante non può superare le 10 parole",
      messageIsEmptyError: "Il contenuto del pulsante non può essere vuoto",
      okText: "Determinare",
      cancelText: "Annulla",
      video: "Video",
      uploadVideo: "Carica video",
      uploadText:
        "Supporta solo il formato MP4, dimensioni non superiori a 20M",
      videoEmptyOrUploading: "Il video è vuoto o in caricamento",
      customEmoji: "Espressione",
    },
    massageConfiguration: {
      pushTarget: "Obiettivo di notifica",
      customizePushTarget: "Destinazione push personalizzata",
      sysUser: "Utente del sistema corrente",
      sysRole: "Ruolo attuale del sistema",
      emailPushTarget: "Invia destinazione",
      targetEmailsPlaceholder:
        "Inserisci le cassette postali separate da virgole",
      subject: "Nome messaggio",
      SDKID: "ID SDK",
      content: "Contenuto della notifica",
      sdkPushType: "Schema push",
      sdkPushURL: "URL push",
      pushData: "Spingere i dati",
      sdkPushWay: "Modalità Push",
      dataType: "Formato dati",
      additionalData: "Dati aggiuntivi",
      name: "Nome messaggio",
      filter: "Condizione del filtro",
      triggerType: "Meccanismo di innesco",
      pushCycle: "Temporizzazione a frequenza fissa",
      pushContent: "Contenuto del rapporto",
      qualityList: "Elenco dei risultati dell'ispezione di qualità",
      designated: "Personale designato",
      messageType: "Tipo di messaggio",
    },
    taskCard: {
      template: {
        cancelCollection: "Annulla preferito",
        collection: "Collezione",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Frase standard:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Collezione",
          calls: "Una chiamata",
          createdBy: "Creato:",
          createdOn: "Tempo di creazione:",
        },
        fileItem: {
          uIProvidesIcon: "Ui fornisce l'icona",
          minute: "Minuti",
          callClip: "Chiama il frammento",
          start: "Inizia",
          End: "Fine",
          employees: "Dipendenti:",
          Customer: "Cliente:",
          sessionTime: "Tempo di sessione:",
          reasonForRecommendation: "Motivo della raccomandazione:",
          addedBy: "Aggiunto:",
          addTime: "Aggiungi tempo:",
        },
        modalAddFile: {
          caseTitle: "Titolo del caso",
          addSomeClipsToTheCaseLibrary:
            "Aggiungere frammenti parziali alla libreria dei casi",
          addToCaseBase: "Aggiungi alla libreria dei casi",
          storedIn: "Immagazzinato in",
          pleaseSelect: "Seleziona",
          reasonsForRecommendation: "Motivo della raccomandazione",
          pleaseEnter: "Inserisci",
        },
      },
      shareModal: {
        index: {
          customer: "Cliente:",
          employees: "Dipendenti:",
          timeliness: "Tempestività:",
          recordingLinkValidWithinDays:
            "Link di registrazione valido entro pochi giorni",
          sharingInstructions: "DESCRIZIONE DELLA CONDIVISIONE:",
          shareSuccess: "Condivisione del successo!",
          cancel: "Annulla",
          share: "Condividi",
          copySucceeded: "Copia riuscita!",
          copyShareLink: "Copia link di condivisione",
          shareWithColleagues: "Condividi con i colleghi",
          shareWithCustomers: "Condividi con i clienti",
          staff: "Impiegato",
          selectColleagues: "Seleziona colleghi",
          pleaseSelectColleaguesToShare: "Seleziona un collega da condividere",
          pleaseEnterTheSharingDescription:
            "Inserisci le istruzioni per la condivisione",
          taskName: "Compiti:",
          recordingShareValidWithinDays:
            "Link di condivisione valido entro pochi giorni",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Il file non può essere trovato!",
          sale: "Vendite:",
          customer: "Cliente:",
          topicDetection: "Rilevazione di argomenti:",
          keyEvents: "Eventi chiave:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Seleziona colleghi",
          helpContent: "Aiutare i contenuti",
          visibleRange: "Gamma visibile",
          seekCounseling: "Cerca consulenza",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Cerca un consiglio", concatText: "A" },
      },
      customerInsight: {
        index: {
          customerInformation: "Informazioni sul cliente",
          customerInsight: "Approfondimenti sui clienti",
          customerLabel: "Etichetta del cliente",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Parole consigliate",
          objectionHandlingScripts: "Manipolazione dell'obiezione",
          productSellingPointScripts: "Punti di vendita del prodotto",
          knowledgePointRecommendation:
            "Raccomandazione del punto di conoscenza",
          relatedKnowledgePoints: "Punti di conoscenza associati",
          recommendedCases: "Casi consigliati",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Parole consigliate",
          caseRecommendation: "Raccomandazione del caso",
          hit: "Colpisci",
          miss: "Mancato",
          supervisionItem: "Articoli per la supervisione",
          implementation: "Stato di attuazione",
          reInspection: "Riispezione",
          supervisionItemSettings: "Impostazione elemento di supervisione",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Telefonate",
          enterpriseWeChatCall: "Micro-Talk aziendale",
          enterpriseMicroConversation: "Micro-sessione aziendale",
          open: "Apri",
          close: "Chiudi",
          conversationScenario: "Scenari di sessione",
          monitorConversationChannels:
            "Supervisionare il canale di conversazione",
          permanentlyValid: "Efficace in modo permanente",
          permanentlyInvalid: "Permanentemente non valido",
          customValidity: "Periodo di validità personalizzato",
          termOfValidity: "Periodo di validità",
          newRating: "Nuova valutazione",
          whenConversation: "Quando la sessione",
          hit: "Colpisci",
          miss: "Mancato",
          time: "Quando",
          rated: "La valutazione è",
          qualified: "Qualificato",
          unqualified: "Non qualificato",
          modifiedSuccessfully: "Modifica riuscita",
          classificationName: "Nome categoria",
          supervisionObject: "Oggetto di supervisione",
          entryintoforceTime: "Tempo effettivo",
          supervisionItemRating: "Valutazione della voce di supervisione",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Si prega di verificare se la valutazione della supervisione è corretta",
          ratingRules: "Regole di valutazione",
          settingOfRatingRulesForSupervisionItems:
            "Monitoraggio dell'impostazione delle regole di valutazione degli elementi",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Eventi chiave",
          supervisionItem: "Articoli per la supervisione",
          conversationScenario: "Scenari di sessione",
          customerLabel: "Etichetta del cliente",
          reDetectionRules: "Rilevare le regole",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Scarica la registrazione",
          downloadText: "Scarica testo",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Carica immagine",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Selezionare un'immagine di dimensioni non superiori a 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "O",
            and: "E",
            wrong: "Non",
            matchTo: "Partita",
            rule: "Regole",
            satisfactionOperator: "Soddisfare operatore",
            customerLabel: "Etichetta del cliente",
            keyEvents: "Eventi chiave",
            knowledgePoints: "Punti di conoscenza",
            sessionLabel: "Etichetta di sessione",
            supervisionItem: "Articoli per la supervisione",
          },
          linkExplorer: { linkInTheText: "Link nell'articolo:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound: "Componente personalizzato non trovato:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "Personalizzato" },
      dropdownMonit: {
        updateSucceeded: "Aggiornamento riuscito",
        areYouSureToDelete: "Sei sicuro di voler eliminare?",
        custom: "Personalizzato",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Salva la configurazione attuale del report === Form",
        saveCurrentReportConfiguration:
          "Salva la configurazione del report corrente",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Inserisci un nome di impostazione comune, la lunghezza massima è 20!",
        preservation: "Salva",
      },
      template: {
        saveCurrentReportConfiguration:
          "Salva la configurazione del report corrente",
        updateCurrentReportConfiguration:
          "Aggiornare la configurazione del report corrente",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Informazioni sull'ordine di lavoro",
        workOrder: "Ordine di lavoro",
        triggerScenario: "Scenario trigger",
        associatedCallConversation: "Chiamata/conversazione associata",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Test con testo di passaggio/dialogo corrente",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "Sezione", day: "Giorno" },
      businessSummary: {
        normal: {
          equalTo: "Uguale",
          notEqualTo: "Non uguale",
          contain: "Contiene",
          excluding: "Non contiene",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Sono quello che sono, un fuoco d'artificio diverso @ 156 xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Ciao, sono la notifica del messaggio di avviso di ispezione qualità del robot, che è stato aggiunto al gruppo il 17 ottobre",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Sono quello che sono, un fuoco d'artificio diverso @ 156 xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Ciao, sono la notifica del messaggio di avviso di ispezione qualità del robot, che è stato aggiunto al gruppo il 17 ottobre",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Temi di comunicazione",
          conversationSummary: "Riepilogo della sessione",
          serviceSuggestions: "Raccomandazioni di servizio",
        },
        template: { smartTags: "Etichetta intelligente" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Clicca per saperne di più" },
      schema: {
        textRobot: "Robot di testo",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Accort trainer, domande e risposte sui documenti, dialogo basato sulle attività, domande e risposte sulla tabella, domande e risposte sul grafico.",
        capitalRetentionRobot: "Robot di ritenzione",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Ricca strategia di conservazione di follow-up, alta comunicazione di personificazione, per garantire un alto tasso di ritenzione, indizi efficiente conversione automatica.",
        outboundRobot: "Robot di chiamata in uscita",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Visita di reso per il completamento degli ordini di lavoro, indagine sulla soddisfazione del servizio, avviso di guasto del funzionamento e della manutenzione, promemoria per la consegna degli ordini, notifica di attività di e-commerce, nomina del candidato, assistenza all'automazione aziendale e miglioramento dell'efficienza aziendale",
        incomingRobot: "Robot in arrivo",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Integrazione flessibile di ordini di lavoro, ordini e altri sistemi aziendali per ottenere l'automazione dei processi; riconoscimento personalizzato del modello semantico vocale, risultati aziendali immediatamente; entità (numero di telefono cellulare, importo dell'ordine, tempo dell'ordine, indirizzo,...) identificazione, supporto rapido per attività complesse",
        intelligentQualityInspection: "Ispezione di qualità intelligente",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Ispezione completa di qualità, tempestiva ed efficiente, con un tasso di precisione dell'85 %",
        intelligentSessionAnalysis: "Analisi intelligente della sessione",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Insight nel breve consiglio di amministrazione, ascoltare la voce dei clienti, migliorare la trasformazione del marketing",
        enterpriseWechatConversationAnalysis:
          "Analisi della sessione di Enterprise WeChat",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Identificazione efficiente delle potenziali opportunità di business, dei rischi, del tasso di conversione del marketing è aumentato del 20%",
        assistant: "Assistente Sedile",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "L'assistente agente può garantire il servizio del servizio clienti alle prime armi e migliorare la qualità della chiamata del servizio clienti qualificato. Lascia che ogni posto sia la tua carta vincente",
        digitalPeople: "L'uomo digitale",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          "Comunicazione antropomorfa \"faccia a faccia\", supporto di voce, testo, video, interazione multimodale touch, per ottenere un'esperienza di comunicazione immersiva, migliorare l'immagine del marchio e l'esperienza del servizio dell'utente",
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Ricerca aziendale a metà fase Conoscenza dell'intelligenza artificiale",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Ricerca della conoscenza, Condivisione della conoscenza, Ricerca di documenti, Ricerca di docking del database",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Scrivania centrale della conoscenza AI · Knowledge Base KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Fornisce la gestione completa del ciclo di vita delle conoscenze, la manutenzione unificata dei robot e delle basi di conoscenza IM, supporta l'attracco con basi di conoscenza di terze parti, modelli di conoscenza, conoscenza atomica e altre potenti funzioni per offrire un'esperienza di gestione della conoscenza più conveniente.",
        aIKnowledgeCenterKnowledgeMap:
          "Piattaforma della conoscenza AI-Mappa della conoscenza",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "La costruzione della mappatura one-stop integra la conoscenza aziendale, il quadro generale mostra i dettagli della conoscenza e le capacità di docking per creare un'esperienza personalizzata.",
        modularDevelopmentPlatformOriginalEngineASR:
          "Piattaforma di sviluppo modulare-Motore cuore originale-ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Adottare un algoritmo end-to-end per realizzare un'esperienza di effetto di servizio personalizzata, con un tasso di riconoscimento della scena naturale superiore al 90% e un riconoscimento del vocabolario personalizzato superiore al 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Piattaforma di sviluppo modulare · Marketing intelligente",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Utilizzando algoritmi di deep learning, combinati con i ritratti degli utenti, per ottenere raccomandazioni accurate sui prodotti e migliorare la conversione delle vendite.",
        modularDevelopmentPlatformUserPortrait:
          "Piattaforma di sviluppo modulare · Ritratto dell'utente",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Costruisci accuratamente i profili utente in base ai dati dell'utente e ai dati di dialogo, descrivendo completamente le caratteristiche del cliente",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Piattaforma di sviluppo modulare · Raccomandazione intelligente",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Secondo il ritratto dell'utente e il ritratto del prodotto, consiglia automaticamente prodotti che potrebbero essere di interesse per gli utenti, realizzando raccomandazioni personalizzate per migliaia di persone.",
        modularDevelopmentPlatformNLPPAAS:
          "Piattaforma di sviluppo modulare · PNL PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Fornisce funzionalità di base della PNL con elevata precisione, coprendo capacità di basso livello di diversa granularità come parole, frasi e articoli.",
      },
    },
    qualityScore: {
      index: {
        show: "Mostra:",
        displayBySecondaryClassification:
          "Visualizzazione per classificazione secondaria",
        showByRule: "Mostra per regola",
        pleaseEnterClassificationruleName:
          "Inserisci il nome della regola/classificazione",
        clickCopy: "Fai clic su Copia",
        hitDetailsOfOperatorsInRules:
          "L'operatore ha colpito i dettagli nella regola:",
        hit: "Colpisci",
        miss: "Mancato",
        filter: "Filtro:",
        clickToViewDetails: "Fai clic per visualizzare i dettagli",
      },
      components: {
        flowNodeList: {
          branch: "Punti",
          pleaseSelect: "Seleziona",
          viewTheCompleteProcess: "Visualizza processo completo",
          thereIsCurrentlyNoProcessAvailable: "Nessun processo",
        },
        pointItem: { xiangHit: "Articolo colpito" },
        flowNodeListModal: { index: { completeProcess: "Processo completo" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "L'attività di ispezione della qualità del processo è cambiata, si prega di presentare nuovamente ricorso.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Questi dati sono stati periodicamente eliminati",
        subject: "Oggetto:",
        description: "Descrizione:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask: "Aggiungi al compito di qualità",
          },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Chiamata/conversazione associata" },
    },
    samplingTarget: {
      index: {
        time: "Quando",
        samplingQuantityIsLessThan: "La quantità di campionamento è inferiore",
        alertWhen: "Preallarme",
        everyday: "Ogni giorno",
        weekly: "Settimanale",
        monthly: "Mensile",
        samplingInspectionTargetSavedSuccessfully:
          "Obiettivo di ispezione di campionamento salvato con successo",
        personnel: "Personale",
        eachCustomerService: "Ogni servizio clienti",
        cycle: "Ciclo",
        samplingInspectionTarget: "Obiettivo di ispezione di campionamento",
        strip: "Articolo",
        alertSettings: "Impostazioni avviso",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Servizio clienti",
        complete: "Completa",
        Objective: "/Obiettivo",
        everyday: "Ogni giorno",
        weekly: "Settimanale",
        monthly: "Mensile",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Tasso di interazione chat di gruppo",
        maximumResponseIntervalTime: "Intervallo massimo tra le risposte",
        numberOfPreemptiveCalls: "Numero di strappi",
        numberOfMessages: "Numero di messaggi",
        strip: "Articolo",
        proportionOfMessages: "Percentuale del numero di messaggi",
        speechPercentage: "Rapporto di conversazione",
        longestCustomerTalk: "Il cliente più lungo che parla",
        numberOfIndepthDiscussions: "Numero di discussioni approfondite",
        second: "Tempi",
        maximumDurationOfContinuousSpeech:
          "Durata massima del parlare continuo",
        questionFrequency: "Frequenza delle domande",
        customer: "Cliente",
        keyEvents: "Eventi chiave:",
        opportunity: {
          index: {
            generationFailed: "Costruire non riuscito",
            generateOpportunityPoints: "Genera punti opportunità",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Costruire non riuscito",
            generateSummary: "Genera riepilogo",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Sei sicuro di voler eliminare?",
        add: "Aggiungi",
        opportunityPoints: "Punto opportunità",
        conversationSummary: "Riepilogo della sessione",
        cancel: "Annulla",
        determine: "Determinare",
        edit: "Modifica",
        establish: "Crea",
        conversationalInsights: "Approfondimenti sulla sessione",
        keyEvents: "Eventi chiave",
        numKeyEvents: "Eventi chiave",
        questionAnalysis: "Analisi delle domande",
        find: "Trovato",
        questions: "Seconda questione",
        conversationAction: "Sessione di azione",
        customer: "Cliente",
        sale: "Vendite",
        sessionLabel: "Scenari di sessione",
        employeeQuestions: "Domande del personale",
        customerQuestions: "Domande dei clienti",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Cliente",
        customerService: "Servizio clienti",
        replyTo: "Rispondi",
        addressee: "A:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Riidentificazione",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "La riidentificazione consumerà il tempo di trasferimento, si prega di operare con cautela.",
        determine: "Determinare",
        executionSuccessful: "Esecuzione riuscita",
        addToQualityInspectionTask: "Aggiungi al compito di qualità",
        batchOperation: "Operazione batch",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Aggiunto correttamente",
        addToQualityInspectionTask: "Aggiungi al compito di qualità",
        qualityInspectionTask: "Compito di ispezione di qualità",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Rileva il ruolo",
        detectionRange: "Intervallo di rilevazione",
        sentence: "Sentenza",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Questi dati sono stati periodicamente eliminati",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Torna al sistema aziendale" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Estrazione del problema",
        businessSummary: "Riepilogo aziendale",
        keyWord: "Parole chiave",
      },
      components: {
        problemExtraction: {
          no: "No",
          customerServiceDidNotAnswer:
            "Il servizio clienti non ha ricevuto risposta",
          customerServiceHasAnswered: "Il servizio clienti ha risposto",
          problemUnresolved: "Problema non risolto",
          theProblemHasBeenResolved: "Problema risolto",
          customerIssue: "Problemi con i clienti:",
          customerServiceAnswer: "Risposta al servizio clienti:",
          reExtractQuestions: "Problema di riestrazione",
          clickToExtractQuestions: "Clicca per estrarre le domande",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Nome ritratto",
            backgroundDescription: "Descrizione dello sfondo",
            customerLabel: "Etichetta del cliente",
            labelManagement: "Gestione dell'etichetta",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Nuovo" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Impostazioni di interrogatorio" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Aumenta le domande",
              remove: "Rimuovi",
              addQuestioning: "Aggiungi domande",
              section: "Sezione",
              secondInquiry: "Secondo interrogatorio",
              questioningScore: "Punteggio interrogatorio",
            },
          },
        },
        index: {
          accordingToRules: "Per regola",
          byScore: "Per punteggio",
          whenMissRule: "Quando la regola è mancata",
          whenConductingQuestioning: "Quando si fanno domande",
          pleaseSelectARule: "Seleziona una regola",
          ruleCannotBeEmpty: "La regola non può essere vuota",
          currentScriptScore: "Quando il punteggio del discorso ≤",
          pleaseEnterTheScore: "Inserisci un punteggio",
          scoreCannotBeEmpty: "Il punteggio non può essere vuoto",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Narratore:" },
          pPT: { slide: "Slide" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Commenti",
              script: "Parole",
              semantics: "Semantica",
              violatingWords: "Violazione delle parole",
              speechSpeed: "Velocità del discorso",
              emotion: "Commozione",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Commenti",
              regenerate: "Rigenerare",
              generate: "Genera",
              hitSituation: "Caso di successo",
              noSemanticTagsMatched: "Non abbinato all'etichetta semantica",
              matchToSemanticTags: "Abbina all'etichetta semantica",
              appealToBeSubmitted: "Denuncia da presentare",
              ratingHasBeenReviewed: "Valutazione recensione",
              ratingAppealInProgress: "Reclenze di punteggio",
              expressionScore: "Punteggio di espressione",
              moodParticlesTimes: "Parola tonale, {0} volte",
              changeTheModalParticlesTo: "Per parole modali",
              second: "Tempi",
              interrogation: "Interrogatorio",
              questioningWithoutScoring: "Chiedi senza segnare",
              branch: "Punti",
              matchingRate: "Tasso di corrispondenza",
              ratingAppeal: "Grievance di valutazione",
              changeTo: "Cambia",
              wordPerMinute: "Parole al minuto",
              completeScript: "Discorso completo",
              incompleteScript: "Discorso incompleto",
              semanticHit: "Colpo semantico",
              semanticMisses: "Mische semantiche",
              scriptViolation: "Violazione del discorso",
              modified: "Modificato",
              notChanged: "Non modificato",
              keyWord: "Parole chiave",
              semanticLabels: "Etichetta semantica",
              semanticMatchingRate: "Tasso di corrispondenza semantica",
              violatingWords: 'Violazione della parola"',
              delete: "Elimina",
              unmatched: "Non abbinato",
              matching: "Partita",
              matchTo: "Partita",
              notMatchedTo: "Non abbinato",
              scriptScore: "Punteggio vocale",
              standardScript: "Discorso standard",
              emotionalScore: "Punteggio emotivo",
              speechSpeedScore: "Punteggio velocità",
              viewResolution: "Visualizza risoluzione",
              nodeConfiguration: "Configurazione del nodo",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Il tempo di risposta a questa domanda è straordinario e il contenuto della risposta viene inviato automaticamente.",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Il tempo di risposta a questa domanda è straordinario e il contenuto della risposta viene inviato automaticamente.",
          },
          correctBtn: {
            saveSuccessful: "Salvataggio riuscito",
            errorCorrection: "Correzione dell'errore",
            cancel: "Annulla",
            preserve: "Salva",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Regole di punteggio",
            cancel: "Annulla",
            determine: "Determinare",
          },
          scoringRuleItem: {
            whole: "Tutto",
            arbitrarily: "Arbitrario",
            pleaseEnterARuleName: "Inserisci il nome di una regola",
            standardScript: "Discorso standard",
            if: "Se",
            and: "E",
            or: "O",
            thenScore: "Poi segna",
            pleaseEnterAScore: "Inserisci un punteggio",
            bonusPoints: "Punti bonus",
            minusPoints: "Meno punti",
            hit: "Colpisci",
            misses: "Mancato",
            keyWord: "Parole chiave",
            semantics: "Semantica",
          },
          title: {
            ruleValidation: "Verifica della regola",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Regole di punteggio (il punteggio totale del discorso corrente è",
            points: "Punti)",
            editRules: "Modifica regola",
          },
        },
      },
      userTagsSelect: {
        index: { noLabelsCurrentlyAvailable: "Nessuna etichetta" },
      },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Obiettivi della comunicazione",
              scoringCriteria: "Criteri di punteggio",
            },
          },
        },
        index: {
          generating: "Generazione",
          generateDialogueFramework: "Generazione di un quadro di dialogo",
          theme: "Tema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Prompt",
            generatePromptLanguage: "Prompt generato",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Fase di comunicazione",
          communicationObjectives: "Obiettivi della comunicazione",
          scoringCriteria: "Criteri di punteggio",
          suggestionsForImprovement: "Raccomandazioni per la promozione",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Punteggio del processo",
            contentIntegrity: "Integrità del contenuto:",
            NumberOfCompletedProcesses: "%, Numero di processi completati",
            keyHitPointsTotal: "Colpi chiave: totale",
            notReached: "Non raggiunto",
            accurateFollowupAccumulationOfStandardScripts:
              "Follow-up accurato: accumulo di discorsi standard",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Una parola, il numero di parole colpite dallo studente",
            aWord: "Parola",
            languageNormsYes: "Specificazione della lingua: Sì",
            emotionsAppearing: "Emozioni: apparire",
            speechSpeedSpeakingTooFast: "VELOCITÀ: VELOCITÀ troppo veloce",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Parole di tono: eccessiva occorrenza di parole di tono",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Aggiungi tag ignora",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Sei sicuro di voler rieseguire l'etichetta smart?",
        reExecute: "Riesecuzione",
      },
      component: { successfullyAdded: "Aggiunto correttamente" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Disattiva tutte le notifiche",
        reQualityInspectionTask: "Attività di ri-ispezione",
        requalityInspection: "Riispezione",
        waiting: "In attesa",
        viewDetails: "Visualizza dettagli",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Tempo di rilevazione:" } },
        },
        template: { responseOpeningSentence: "Frase iniziale di risposta" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Costruire non riuscito",
            generate: "Genera",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Nessun dato" } },
      },
      index: { regenerate: "Rigenerare" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Sei sicuro di voler eliminare?",
        operationSuccessful: "Operazione riuscita",
      },
      template: { delete: "Elimina" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Seleziona una domanda!",
        similarQuestionRecommendation: "Raccomandazioni simili",
        selectAllOnPage: "Quando si selezionano tutte le pagine",
        cancel: "Annulla",
        preserve: "Salva",
        changeBatch: "Cambia un lotto",
      },
    },
    listControl: { index: { columnControl: "Controllo della colonna" } },
    loginUserAvatar: { index: { switch: "Interruttore" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Quando si aggiungono dati in tempo reale, l'esecuzione dell'attività di ispezione della qualità richiede ancora tempo. Il tempo specifico dipende dalla dimensione dei dati di ispezione di qualità, che vanno da pochi minuti a 2 ore.",
      },
    },
    bEditor: {
      index: {
        delete: "Elimina",
        cancel: "Annulla",
        confirm: "OK",
        insert: "Inserto",
        width: "Larghezza",
        height: "Altezza",
        clearContent: "Contenuto chiaro",
        revoked: "Revocato",
        redo: "Rifazione",
        fontSize: "Dimensione del carattere",
        rowHeight: "Altezza della riga",
        wordSpacing: "Spaziatura delle parole",
        paragraphIndent: "Paragrafo trattino",
        increaseIndent: "Aumenta il trattino",
        decreaseIndent: "Diminuisci il trattino",
        border: "Confine",
        color: "Colore",
        textColor: "Colore del testo",
        backgroundColor: "Colore dello sfondo",
        temporaryColor: "Colore temporaneo",
        bold: "Bold",
        italic: "Italico",
        underline: "Sottolinea",
        strikethrough: "Strikethrough",
        font: "Font",
        textAlignment: "Allineamento del testo",
        left: "Sinistra",
        centered: "Centrato",
        right: "Giusto",
        bothEnds: "Entrambe le estremità",
        leftFloat: "Galleggiante sinistro",
        rightFloat: "Galleggiante destro",
        subrupt: "Subrupt",
        subscript: "Sottoscritto",
        clearStyle: "Stile chiaro",
        title: "Titolo",
        regular: "Regolare",
        orderedList: "Elenco ordinato",
        unorderedList: "Elenco non ordinato",
        reference: "Riferimento",
        code: "Codice",
        link: "Collegamento",
        clearLink: "Collegamento chiaro",
        horizontalLine: "Linea orizzontale",
        media: "Media",
        mediaLibrary: "Biblioteca multimediale",
        smallExpression: "Piccola espressione",
        fullScreen: "Schermo intero",
        exitFullScreen: "Esci a schermo intero",
        enterLinkText: "Inserisci il testo del link",
        enterLinkAddress: "Inserisci l'indirizzo del link",
        enterTheLinkAddressAndPressEnter:
          "Inserisci l'indirizzo del link e premi Invio",
        openInNewWindow: "Apri in una nuova finestra",
        removeLink: "Rimuovi link",
        playingAudioFiles: "Riproduzione di file audio",
        playVideoFiles: "Riproduci file video",
        embeddedMedia: "Media embedded",
        image: "Immagine",
        video: "Video",
        audio: "Audio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Nome",
        description: "Descrizione",
        type: "Tipo",
        status: "Stato",
        actions: "Operazione",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Nome attività",
        description: "Descrizione",
        form: "Modulo di ispezione di qualità",
        actions: "Operazione",
        createUser: "Creatore",
      },
      samplingRecords: {
        createTime: "Tempo",
        type: "Tipo",
        operateUser: "Operatore",
        number: "Aggiungi quantità",
        filterConditionList: "Condizione del filtro",
        sampleRuleStr: "Condizioni di campionamento",
        timeGreaterThan: "Il tempo è più grande",
        timeLessThan: "Tempo in meno",
      },
    },
    customFilters: {
      createdAt: "Tempo di conversazione",
      customerId: "Cliente",
      agentId: "Servizio clienti",
      userGroupId: "Gruppo di servizio clienti",
      state: "Stato",
      lastEvaluateUserId: "Ultimo Rater",
      submitTime: "Ultima ora di invio",
      totalScore: "Punteggio totale",
      formItemForbidIdList: "Articoli vietati",
      formItemDeadlyIdList: "Articolo fatale",
    },
    samplingInspection: {
      callTime: "Tempo di conversazione",
      dialogTime: "Tempo di dialogo",
      originator: "Persona allocata",
      createTime: "Tempo di creazione",
      updateTime: "Tempo di aggiornamento",
      status: "Stato del processo",
      score: "Frazione",
      actions: "Operazione",
      reviewer: "Revisore",
      samplingOfficer: "Ispettore di campionamento",
      id: "ID registro chiamate",
      time: "Tempo di conversazione",
      phone: "Telefono cliente",
      staff: "Servizio clienti",
      inspectionStatus: "Stato di ispezione di qualità",
      samplingStatus: "Stato di campionamento",
      inspectionGrade: "Valutazione",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Parola standard",
        synonymWords: "Sinonimi",
        synonymWordsNumber: "Numero di sinonimi",
        useFrequency: "Frequenza di utilizzo",
        effectiveness: "Efficacia",
        operation: "Operazione",
      },
      common: {
        useFrequency: "Frequenza di utilizzo",
        effectiveness: "Efficacia",
        operation: "Operazione",
      },
      professionalWords: {
        professionalWords: "Sostantivo professionale",
        useFrequency: "Utilizzare le statistiche",
        homophonic: "Parole calde (sostituzione omofono)",
        target: "Sostituzione del nome",
        targetWords: "Parole sostitutive",
        homophonyWords: "Mascheramento per omofono",
        operation: "Operazione",
      },
      attentionWords: "Parole preoccupate",
      ignoreWords: "Ignora le parole",
      wordsWeight: {
        selfDefineWeightWords: "Parola di peso personalizzata",
        weightWords: "Parole di peso",
        weight: "Peso",
        useFrequency: "Frequenza di utilizzo",
        effectiveness: "Efficacia",
        operation: "Operazione",
      },
      corpusData: {
        corpusDescription: "Corpus descrizione",
        updateTime: "Tempo di aggiornamento",
        wordsCount: "Numero di parole",
        effectiveness: "Efficacia",
        operation: "Operazione",
        corpusContent: "Contenuto",
      },
    },
    semanticTags: {
      title: "Etichetta semantica",
      tags: "Frase standard",
      orgName: "Organizzazione",
      tagCount: "Numero di formazione",
      usingCount: "Numero di riferimenti",
      activeFlag: "Efficacia",
      suggestion: "Raccomandazioni per la formazione",
      actions: "Operazione",
    },
    intelligentTags: {
      tagName: "Nome",
      tagDesc: "Descrizione",
      tagRefCnt: "Numero di marchi",
      categoryName: "Classificazione dell'etichetta",
      eventName: "Classificazione degli eventi",
      tagStatus: "Efficacia",
      actions: "Operazione",
      tagType: "Tipo di etichetta",
    },
    dataPreprocess: {
      name: "Nome",
      desc: "Descrizione",
      dataSource: "Limiti sorgente dati",
      type: "Tipo",
      status: "Efficacia",
      actions: "Operazione",
    },
    informationEntities: {
      name: "Nome",
      apiName: "Nome API",
      type: "Tipo",
      remark: "Descrizione",
      activeFlag: "Efficacia",
      actions: "Operazione",
    },
    interactiveRecognition: {
      questionAndAnswer: "Domande e risposte standard",
      type: "Tipo",
      status: "Stato",
      actions: "Operazione",
      question: "Domanda",
    },
    role: { id: "ID", name: "Nome", actions: "Operazione" },
    uploadDownload: {
      taskName: "Nome file",
      createTime: "Data",
      size: "Dimensioni",
      createUserName: "Operatore",
      status: "Stato",
      result: "Risultati",
      actions: "Operazione",
    },
    taskCenterList: {
      name: "Contenuto attività",
      createTime: "Ora di invio",
      startTime: "Ora di inizio",
      endTime: "Ora di fine",
      createUserName: "Operatore",
      status: "Stato",
      result: "Risultati",
      actions: "Operazione",
    },
    customerInformation: {
      title: "Informazioni sul servizio clienti",
      data: "Visualizza solo dati di eccezione",
      list: {
        agentNo: "Numero di lavoro/ID del servizio clienti",
        agentName: "Servizio clienti",
        agentTel: "Telefono",
        agentEmail: "Cassetta postale",
        updateTime: "Tempo di aggiornamento",
        actions: "Operazione",
      },
      upload: {
        second:
          "2. Fare clic sul pulsante Importa per caricare il file EXCEl conforme al formato del modello",
      },
    },
    IndexManagement: {
      title: "Gestione degli indicatori",
      list: {
        indicatorName: "Nome",
        indicatorDesc: "Descrizione",
        activity: "Efficacia",
        inspectDataSource: "Fonte dati",
        sysType: "Tipo",
        agentGroups: "Gruppo di servizio clienti",
        actions: "Operazione",
      },
      detail: {
        nameEmpty: "Il nome non può essere vuoto",
        descript: "Descrizione",
        descriptEmpty: "La descrizione non può essere vuota",
        active: "Efficacia",
        activeEmpty: "La validità non può essere vuota",
        dataSource: "Fonte dati",
        dataSourceEmpty: "L'origine dati non può essere vuota",
        grounp: "Gruppo di servizio clienti",
        grounpEmpty: "Il gruppo di agenti non può essere vuoto",
        grounpHolder: "Seleziona un gruppo di agenti",
        type: "Metodo di punteggio",
        typeNone: "Il metodo di punteggio non può essere vuoto",
        base: "Punti di fondazione",
        baseNone: "Il punteggio di base non può essere vuoto",
        positive: "Fattori positivi",
        positiveNone: "Il fattore positivo non può essere vuoto",
        customAdd: "Aggiungi filtro",
        negative: "Fattori negativi",
        negativeNone: "I fattori negativi non possono essere vuoti",
        customOK: "Criteri di filtro:",
        judgeStrategy: "Regole di condizione:",
        other: "Altri fattori",
        otherAdd: "Aggiungi fattore",
        agent: "Dati grezzi",
        section: "Numero di intervalli",
        sectionNone: "Il numero di intervalli non può essere vuoto",
        step: "Dimensione del passo dell'intervallo",
        stepNone: "Dimensione del passo dell'intervallo",
        save: "Salva",
        cancel: "Annulla",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Tutto",
      staff: "Solo me stesso",
      department: "Dipartimento e sotto",
    },
    gongStatisticsDate: {
      week: "Settimana",
      month: "Mese",
      season: "Stagione",
    },
    gongCoachTaskStatus: {
      wait: "Da mentore",
      already: "Allenato",
      ignore: "Ignorato",
    },
    callRangeStatus: {
      all: "Tutte le chiamate",
      sale: "Impiegato Avviato",
      customer: "Cliente avviato",
    },
    todoListType: {
      later: "Ascolta più tardi",
      help: "Consulenza per chiedere aiuto",
      contact: "Contattare più tardi",
      send: "Invia dati",
      warn: "Rischio allarme rapido",
    },
    frequencyTypes: {
      everyDay: "Ogni giorno",
      everyWeek: "Settimanale",
      everyMonth: "Mensile",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Formazione dei dati non classificati",
        markedDataCorrigenda: "Dati etichettati Errata",
        classifiedDataCheck: "Verifica dei dati classificati",
        markedHistoryRecord: "Storia callout",
        historyDataRecalculate: "Recaltazione dei dati storici",
      },
      dataExtractMode: {
        random: "Completamente casuale",
        intelligentRecommend: "Raccomandazione intelligente",
        custom: "Personalizzato",
        specifiedClass: "Specificare la classificazione",
      },
      smartRatesList: {
        markRate: "Tasso di marcatura",
        corrigendaRate: "Tasso errata",
        kl: "Divergenza K-L",
        signalNoiseRate: "Rapporto segnale-rumore",
      },
    },
    visibleRangeTypes: {
      all: "Tutto",
      customerGroup: "Gruppo di servizio clienti",
      userDefined: "Personalizzato",
    },
    samplingRateTypes: {
      everyDay: "Disegna ogni giorno",
      everyWeek: "Sorteggio settimanale",
    },
    taskRemindTypes: {
      confirm:
        "Invia un promemoria quando è necessario confermare il record dell'attività di ispezione della qualità.",
      appeal:
        "Quando il record dell'attività di ispezione di qualità avvia un reclamo, invia un promemoria.",
    },
    weekDays: {
      monday: "Lunedì",
      tuesday: "Martedì",
      wednesday: "Mercoledì",
      thursday: "Giovedì",
      friday: "Venerdì",
      saturday: "Sabato",
      sunday: "Domenica",
    },
    reportDateTypes: {
      dailyReport: "Rapporto giornaliero",
      weeklyReport: "Rapporto settimanale",
    },
    samplingRangeDayTypes: { today: "Oggi", yesterday: "Ieri" },
    samplingRangeWeekTypes: {
      thisWeek: "Questa settimana",
      lastWeek: "La settimana scorsa.",
    },
    samplingRuleTypes: {
      proportion: "Campionamento proporzionale",
      random: "Campionamento casuale",
    },
    taskStatusTypes: {
      waitEvaluate: "Da valutare",
      waitSubmit: "Da inviare",
      waitConfirm: "Da confermare",
      waitReview: "Da rivedere",
      completed: "Completato",
      all: "Tutto",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Valutazione diretta",
        weightedScore: "Punteggio del peso",
        deductScore: "Detrazioni",
        directScoreSecondLevel: "Valutazione diretta-Livello 2",
        weightedScoreSecondLevel: "Punteggio peso-Livello 2",
        deductScoreSecondLevel: "Deduzione dei punti-Livello 2",
      },
    },
    fieldOperators: {
      is: "Uguale",
      not: "Non uguale",
      contains: "Contiene",
      notContains: "Non contiene",
      not_contains: "Non contiene",
      containsAny: "Contiene qualsiasi",
      contains_any: "Contiene qualsiasi",
      is_any: "Contiene qualsiasi",
      not_any: "Non contiene alcun",
      notContainsAny: "Non contiene alcun",
      not_contains_any: "Non contiene alcun",
      containsAll: "Contiene tutti",
      contains_all: "Contiene tutti",
      notContainsAll: "Non contiene tutti",
      nont_contains_all: "Non contiene tutti",
      isNull: "Vuoto",
      is_null: "Vuoto",
      isNotNull: "Non vuoto",
      is_not_null: "Non vuoto",
      greaterThan: "Maggiore",
      greater_than: "Maggiore",
      greaterThanEq: "Maggiore o uguale",
      greater_than_eq: "Maggiore o uguale",
      lessThan: "Meno",
      less_than: "Meno",
      lessThanEq: "Meno o uguale",
      less_than_eq: "Meno o uguale",
      between: "Tra",
      notBetween: "Non",
      not_between: "Non",
      relative: "Uguale",
      set: "Imposta",
      belongs: "Appartengono",
      notBelongs: "Non appartengono",
      not_belongs: "Non uguale",
      today: "Oggi",
      yesterday: "Ieri",
      the_day_before_yesterday: "L'altro ieri",
      tomorrow: "Domani.",
      next_seven_day: "I prossimi sette giorni",
      last_seven_day: "Ultimi sette giorni",
      this_week: "Questa settimana",
      next_week: "La prossima settimana",
      last_week: "La settimana scorsa.",
      this_month: "Questo mese",
      next_month: "Il prossimo mese",
      last_month: "Il mese scorso",
      this_year: "Quest'anno",
      next_year: "L'anno prossimo",
      last_year: "L'anno scorso",
      exact_match: "Corrispondenza esatta",
      add_value: "Nuovo valore aggiunto",
      remove_value: "Elimina valore",
      set_null: "Vuoto",
      isAny: "Arbitrariamente uguale",
      notAny: "Arbitrario non uguale",
      belongTo: "Appartengono",
      notBelongTo: "Non appartengono",
      hasAny: "Contiene qualsiasi",
      notHasAny: "Non contiene alcun",
      prefixContains: "Inizia uguale",
      prefixNotContains: "L'inizio non è uguale",
      suffixContains: "Fine uguale",
      suffixNotContains: "La fine non è uguale",
      nextSevenDay: "I prossimi 7 giorni",
      lastSevenDay: "Ultimi 7 giorni",
      thisWeek: "Questa settimana",
      nextWeek: "La prossima settimana",
      lastWeek: "La settimana scorsa.",
      thisMonth: "Questo mese",
      nextMonth: "Il prossimo mese",
      lastMonth: "Il mese scorso",
      thisYear: "Quest'anno",
      nextYear: "L'anno prossimo",
      lastYear: "L'anno scorso",
      after: "Più tardi di (compreso il giorno corrente)",
      before: "Prima di (compreso il giorno corrente)",
      allMatching: "Abbina tutto",
      allNotMatching: "Tutti non corrispondono",
      hit: "Colpisci",
      not_hit: "Mancato",
    },
    fieldsOperators: {
      is: "Uguale",
      is_null: "Anche vuoto",
      head_equal: "Inizia uguale",
      tail_equal: "Fine uguale",
      is_not_null: "Inoltre non vuoto",
      not: "Non uguale",
      diff_equal: "La differenza è uguale",
      diff_greater_than: "La differenza è maggiore",
      diff_greater_than_eq: "Differenza maggiore o uguale",
      diff_less_than: "La differenza è meno",
      diff_less_than_eq: "Differenza inferiore o uguale",
      hour_equal: "Stessa ora.",
      day_equal: "Lo stesso giorno",
      week_equal: "Stessa settimana",
      month_equal: "Lo stesso mese",
      year_equal: "Nello stesso anno",
      day: "Giorno",
      minute: "Minuti",
      hour: "Ore",
      part_equal: "Parzialmente uguale",
    },
    samplingColumnCaptions: {
      created_at: "Tempo di conversazione",
      agent_id: "Servizio clienti",
      get_direction: "Tipo di chiamata",
      call_result: "Chiama i risultati",
      survey: "Valutazione",
      derived_from_id: "Fonte",
      quit_queue_why: "Stato di accodamento",
      queuing_duration: "Fare la fila che richiede tempo",
      talking_seconds: "Durata della chiamata",
      ringing_seconds: "Tempo dell'anello",
      hangup_by: "Chiama la festa di riaggancia",
    },
    callTypes: {
      callIn: "Chiamata in arrivo",
      callOut: "Espirare",
      threeParty: "(Tripartito)",
      consultation: "(Consulenza)",
      insert: "(Forte inserimento)",
      monitor: "(Ascolto)",
      transfer: "(Trasferimento)",
      intercept: "(Intercetta)",
      transferOutside: "(Trasferimento linea esterna)",
      threePartyOutside: "(Linea esterna tripartita)",
      consultingOutside: "(Consultare all'esterno)",
    },
    callResults: {
      staffAnswer: "Risposta al servizio clienti",
      staffNotAnswer: "Servizio clienti mancato",
      customerAnswer: "Risposta del cliente",
      customerNotAnswer: "Cliente mancato",
      phoneBusy: "Telefono occupato",
      phoneOffline: "Telefono offline",
      customerSpeedHangUp: "Attacco rapido del cliente",
      customerHangUp: "Il cliente riaggancia",
      queueTimeout: "Timeout della coda",
      queueGiveUp: "Abbandonare le code",
      outlineAnswer: "Risposta esterna",
      outlineNotAnswer: "Linea esterna non collegata",
      noChooseQueue: "Nessuna coda selezionata",
    },
    defaultSurveys: {
      noEvaluation: "Non valutato",
      noNeedToEvaluate: "Non c' è bisogno di valutare",
    },
    queueStates: {
      queueSuccess: "Coda riuscita",
      queueTimeout: "Timeout della coda",
      queueGiveUp: "Abbandonare le code",
      noStaffOnline: "Nessun servizio clienti online",
    },
    callHangers: {
      customer: "Cliente",
      staff: "Servizio clienti",
      outline: "Fuori",
    },
    workFlowTypes: {
      noAppealsMode: "Nessuna modalità reclamo",
      appealsMode: "Consenti modalità appello",
    },
    fieldCategories: {
      standardCondition: "Criteri di filtro standard",
      customerCondition: "Criteri di filtro definiti dall'utente",
    },
    sampleTypes: {
      automatic: "Campionamento automatico",
      manual: "Campionamento manuale",
    },
    defaultFields: {
      createdAt: "Tempo di conversazione",
      agentId: "Servizio clienti",
      getDirection: "Tipo di chiamata",
      callResult: "Chiama i risultati",
      survey: "Valutazione",
      derivedFrom: "Fonte",
      quitQueueWhy: "Stato di accodamento",
      queuingDuration: "Fare la fila che richiede tempo",
      talkingSeconds: "Durata della chiamata",
      ringingSeconds: "Tempo dell'anello",
      hangupBy: "Chiama la festa di riaggancia",
    },
    conditionFieldCategories: {
      fixedField: "Campo fisso",
      customField: "Campi personalizzati",
      secondLevelScore: "Classificazione secondaria",
      formItemScore: "Punteggio elemento",
      forbids: "Articoli vietati",
      deadlies: "Articolo fatale",
    },
    columnFieldCategories: {
      agent: "Servizio clienti",
      callLog: "Registrazione delle chiamate",
      businessFields: "Informazioni aziendali",
      customer: "Cliente",
      customerCustomFields: "Personalizzazione del cliente",
      source: "Fonte",
      queue: "Coda",
      fixed: "Fisso",
      secondLevelScore: "Classificazione secondaria",
      formItemScore: "Punteggio elemento",
      forbids: "Articoli vietati",
      deadlies: "Articolo fatale",
    },
    taskListDefaultFields: {
      startTime: "Tempo di conversazione",
      name: "Servizio clienti",
      state: "Stato",
      totalScore: "Punteggio totale",
      formItemForbidList: "Articoli vietati",
      formItemDeadlyList: "Articolo fatale",
      comment: "Osservazioni di valutazione",
      inspector: "Valutazioni",
      review: "Revisore",
    },
    evaluationStates: {
      initial: "Inizializzazione",
      waitEvaluate: "Da valutare",
      evaluated: "Da inviare",
      waitConfirm: "Da confermare",
      waitCheck: "Da rivedere",
      complete: "Completato",
    },
    workBenchInfoTypes: {
      callInfo: "Informazioni sulla chiamata",
      businessInfo: "Informazioni aziendali",
      customerInfo: "Informazioni sul cliente",
    },
    evaluationEventStates: {
      create: "Crea",
      valuate: "Valutazione",
      edit: "Modifica",
      submit: "Presentazione",
      confirm: "Conferma",
      appeal: "Denuncia",
      checkConfirm: "Rivedi la conferma",
      check: "Revisione",
    },
    formItemTypes: {
      direct: "Valutazione diretta",
      weight: "Peso",
      deduct: "Detrazioni",
      forbid: "Articoli vietati",
      deadly: "Articolo fatale",
    },
    appealCheckCallTypes: {
      callIn: "Chiamata in arrivo",
      callBack: "Callback bidirezionale",
      callOut: "Chiamata diretta",
      autoCall: "Chiamata automatica in uscita",
    },
    appealCheckCallStatus: {
      waitReview: "Da rivedere",
      reviewed: "Recensito",
      filed: "Archiviato",
    },
    samplingStatus: {
      all: "Tutto",
      unchecked: "Estrazione non controllata",
      checked: "Campionamento",
    },
    inspectionStatus: {
      all: "Tutto",
      unread: "Non letto",
      readed: "Leggi",
      appeal: "Denuncia",
      review: "Revisione",
      filed: "Archiviato",
    },
    gradeChooseHits: { yes: "Sì", no: "No" },
    appealCheckStatus: {
      all: "Tutti gli stati",
      unread: "Non letto",
      inComplaint: "In Denuncia",
      reviewed: "Recensito",
      readed: "Leggi",
      filed: "Archiviato",
      spotchecked: "Campionamento",
    },
    flowStatus: {
      unread: "Non letto",
      alreadyRead: "Leggi",
      complaining: "In Denuncia",
      reviewed: "Recensito",
      sampling: "Estrazione non controllata",
      samplingCompleted: "Campionamento",
      complainCancel: "Revocato",
    },
    complainStatus: {
      success: "Successo",
      fail: "Impossibile",
      default: "In Denuncia",
      canceled: "Revocato",
    },
    reviewInspectionStatus: {
      inComplaint: "In Denuncia",
      reviewed: "Recensito",
    },
    gradeChangeTypes: {
      edit: "Modifica",
      check: "Revisione",
      file: "Archiviazione",
      remark: "Osservazioni",
      complain: "Denuncia",
      review: "Revisione",
      reviewEvaluation: "Rivedi la valutazione",
      sample: "Ispezione casuale",
      sampleEvaluation: "Ispezione e valutazione del campionamento",
      sampleSubmit: "Ispezione e presentazione del campionamento",
      sampleAssign: "Assegnazione del campionamento",
      reviewAssign: "Riesaminare l'assegnazione",
      read: "Leggi",
      reviewConfirm: "Rivedi la conferma",
      sampleConfirm: "Conferma dell'ispezione di campionamento",
      caseBase: "Aggiungi al gruppo di casi",
      sampleCancel: "Assegnazione dell'annullamento del campionamento",
      reviewCancel: "Revisione Cancellazione",
      sampleAgain: "Riassegnazione del campionamento",
      reviewAgain: "Riassegnazione di revisione",
      btachDelete: "Elimina i record di ispezione casuale",
      rapidSampling: "Campionamento rapido",
      reInspection: "Riispezione",
      smartTagsChange: "Modifiche etichette intelligenti",
      cancelAppeal: "Ritiro della denuncia",
    },
    appealTypes: {
      transliterationError: "Errore di trascrizione",
      discriminationError: "Errore di giudizio",
      otherError: "Altri errori",
    },
    inspectDataSources: {
      voiceCall: "Chiamata vocale",
      textDialogue: "Dialogo di testo",
      realTimeVoiceCall: "Chiamate vocali in tempo reale",
      realTimeTextDialogue: "Conversazioni di testo in tempo reale",
      wechatDialogue: "Micro-sessione aziendale",
      taobao: "Dialogo del testo dell' e-commerce",
      ticket: "Ordine di lavoro",
      wechatRadio: "Micro voce aziendale",
    },
    inspectTaskType: {
      all: "Tutto",
      common: "Ispezione generale di qualità",
      relate: "Ispezione della qualità associata",
    },
    inspectApproveType: {
      all: "Tutto",
      resolve: "Approvato",
      resolving: "In revisione",
      reject: "Audit non superato",
    },
    dataSourceFlags: {
      all: "Nessuna restrizione",
      voiceCall: "Chiama",
      textDialogue: "Dialogo",
    },
    smartWordsLibrary: { effective: "Efficace", invalid: "Non valido" },
    activeFlags: { effiective: "Efficace", invalid: "Non valido" },
    labelTypes: {
      dimensionLabel: "Etichette callout",
      classificationLabel: "Etichetta di categoria",
    },
    pointTypes: {
      automatic: "Valutazione automatica",
      manual: "Valutazione manuale",
      interactiveDetection: "Rilevamento dell'interazione",
      smartRules: "Regole intelligenti",
      machineLearning: "Modalità di autoapprendimento",
      intelligentModel: "Modello intelligente",
    },
    pointGradeTypes: {
      radio: "Selezione singola",
      input: "Input",
      select: "Seleziona",
      all: "Incontra tutti",
      any: "Incontra arbitrario",
      customize: "Personalizzato",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Corrispondenza delle parole chiave",
      question: "Frase interrogativa",
      regular: "Espressioni regolari",
      context: "Duplicazione del contesto",
      semantics: "Corrispondenza semantica",
      word: "Parole simili",
      dialogue: "Intervallo di dialogo",
      speed: "Rilevamento della velocità del discorso",
      grabDetection: "Snatch rilevamento",
      semanticTags: "Etichetta semantica",
    },
    applyRoles: {
      all: "Tutto",
      customer: "Cliente",
      agent: "Servizio clienti",
      robot: "Robot",
    },
    saleApplyRoles: { all: "Tutto", customer: "Cliente", agent: "Impiegato" },
    applyOperatorScopes: {
      all: "Testo completo",
      preCondition: "Condizione preliminare",
      scope: "Gamma specificata",
    },
    keywordScopes: {
      all: "Rilevamento di tutte le parole chiave.",
      any: "Rileva parole chiave arbitrarie",
    },
    preOperatorHitTypes: {
      first: "Primo incontro",
      every: "Ogni partita",
      last: "Ultima partita",
      any: "Qualsiasi corrispondenza",
      none: "Non abbinato",
    },
    operatorRuleTypes: {
      all: "Incontra tutti",
      any: "Incontra arbitrario",
      custom: "Logica personalizzata",
    },
    informationEntityTypes: {
      system: "Sistema",
      custom: "Personalizzato",
      moduleCompany: "Modulo",
    },
    organizationTypes: { null: "Nessuno", moduleCompany: "Modulo" },
    customType: {
      smartTag: "Entità estratto",
      scriptTimes: "Conteggio dei discorsi",
      dataCategory: "Classificazione dei dati",
    },
    interactiveQuestionTypes: {
      standard: "Domande e risposte standard",
      multiElement: "Domanda e risposta multi-elemento",
      guideMultiRound: "Interazione guidata a più ruote",
    },
    targetTypes: {
      all: "Tutto",
      customerService: "Servizio clienti",
      customer: "Cliente",
    },
    interactiveTypes: {
      similarQList: "Problema simile",
      exceptSimilarQList: "Eliminare problemi simili",
      similarAList: "Risposte simili",
      wrongAList: "Risposta sbagliata",
    },
    filterTypes: {
      call: "Registrazione delle chiamate",
      dialogue: "Testo del dialogo",
      wechat: "WeChat aziendale",
      wechatAll: "Tutto il dialogo Enterprise WeChat",
      ticket: "Sessione dell'ordine di lavoro",
      taobao: "Sessione di e-commerce",
      wechatRadio: "Micro voce aziendale",
    },
    fieldTypes: {
      keyword: "Parole chiave",
      agent: "Servizio clienti",
      agentGroup: "Gruppo di servizio clienti",
      sigleListbox: "Selezione singola",
      multiListbox: "A scelta multipla",
      text: "Singola linea di testo",
      textArea: "Più righe di testo",
      dateTime: "Tempo",
      number: "Numeri",
      voice: "",
      customer: "Cliente",
      fieldWithLink: "Campo con link",
      percentage: "Percentuale",
      intelligentClassification: "Classificazione intelligente",
      testSetList: "Campi dinamici del set di test",
      inspector: "Ispettore di qualità",
    },
    saveTypes: { save: "Salva" },
    knowledgeType: { oneToOne: "Una domanda e una risposta" },
    updateTypes: { update: "Aggiornamento", saveAs: "Salva" },
    recommendItemActionTypes: {
      similar: "Simile",
      exclude: "Escludi",
      ignore: "Ignora",
    },
    gradeStatus: {
      unread: "Non letto",
      alreadyReview: "Recensito",
      alreadyFiled: "Archiviato",
      waitReview: "Da rivedere",
      alreadyRead: "Leggi",
    },
    qualityCheckDetailTypes: {
      review: "Revisione",
      spotCheck: "Ispezione casuale",
    },
    sampleAndReviewStatus: {
      init: "Non estratto",
      unread: "Non letto",
      alreadyRead: "Leggi",
      complaining: "In Denuncia",
      reviewed: "Recensito",
      sampling: "Estrazione non controllata",
      samplingCompleted: "Campionamento",
    },
    interactiveAnswerTypes: {
      noAnswer: "Nessuna risposta rilevata",
      answer: "Risposta rilevata",
      wrongAnswer: "Rilevata risposta errata",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Nessun problema rilevato",
      question: "Problema rilevato",
      eliminateQuestion: "Risoluzione dei problemi rilevati",
    },
    isManual: {
      yes: "Ispezione manuale della qualità",
      no: "Nessuna ispezione di qualità manuale",
    },
    readStatus: { yes: "Leggi", no: "Non letto" },
    reInspectionType: {
      recalcScore: "Ricalcola solo i punteggi (con valutazioni)",
      recheckPoint:
        "Ricalcolare i punti e i punteggi di ispezione della qualità",
    },
    common: {
      eCommerceReview: "Revisione dell' e-commerce",
      socialMediaComments: "Commenti sui social media",
      customer: "Cliente",
      customerService: "Servizio clienti",
      robot: "Robot",
      customerInformation: "Informazioni sul cliente",
      workOrderFields: "Campi degli ordini di lavoro",
      intelligentConversationAnalysis: "Analisi intelligente della sessione",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Insight nel breve consiglio di amministrazione, ascoltare la voce dei clienti, migliorare la trasformazione del marketing",
      intelligentAccompanyingPractice: "Sparring intelligente",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Crea un coinvolgente sparring AI che simula scene di business reali per ottenere la piena padronanza delle capacità vocali della medaglia d'oro e un rapido miglioramento delle capacità di dialogo.",
      intelligentQualityInspection: "Ispezione di qualità intelligente",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Ispezione completa di qualità, tempestiva ed efficiente, con un tasso di precisione superiore all'85 %",
      salesEmpowerment: "Responsabilizzazione delle vendite",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "L'intelligenza artificiale rimodella la gestione delle vendite, realizza la digitalizzazione e la visualizzazione del processo di comunicazione, gestisce in modo trasparente ogni link delle vendite, percepisce in modo intelligente ogni fase della comunicazione, diagnostica delicatamente i problemi aziendali e rifà perfettamente l'esperienza di vendita.",
      algorithmPlatform: "Piattaforma algoritmo",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Sulla base della semantica del parlato del motore cardiaco originale auto-sviluppato, i modelli ASR e NLP sono personalizzati per ottenere attraverso i dati dello scenario aziendale e realizzare l'autoapprendimento del sistema, in modo da raggiungere lo scopo di un uso più intelligente e più accurato.",
      generalSettings: "Impostazioni generali",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Impostare e gestire le autorizzazioni dei dati e i ruoli dei dipendenti in modo unificato",
      callLog: "Registrazione delle chiamate",
      intelligentRules: "Regole intelligenti",
      businessRecords: "Documenti aziendali",
      customFilter: {
        call: "Chiamata vocale",
        text: "Dialogo di testo",
        smartTags: "Etichetta intelligente",
        pretreatment: "Pretrattamento",
        smartTagGroup: "Gruppo di etichette intelligenti",
        currency: "Dati generali",
        wechat: "WeChat aziendale",
        spotCheck: "Ispezione casuale",
        personaField: "Elenco clienti",
        ticket: "Sessione dell'ordine di lavoro",
        taobao: "Sessione di e-commerce",
        wechatRadio: "Micro voce aziendale",
        reviewList: "Revisione",
      },
      talkerRecognitionType: {
        API: "L'API identifica il ruolo della traccia",
        text: "Riconoscimento del testo",
      },
      operatorTypes: {
        keyword: "Corrispondenza delle parole chiave",
        question: "Frase interrogativa",
        regular: "Espressioni regolari",
        context: "Duplicazione del contesto",
        semantics: "Corrispondenza semantica",
        word: "Parole simili",
        dialogue: "Intervallo di dialogo",
        speed: "Rilevamento della velocità del discorso",
        grabDetection: "Snatch rilevamento",
        semanticTags: "Etichetta semantica",
        keywordStrict: "Corrispondenza delle parole chiave (rigorosa)",
        link: "Campo di collegamento",
        entityInfoDetection: "Rilevamento entità informazioni",
        basicInfoDetection: "Rilevamento delle informazioni di base",
        volume: "Rilevamento del volume",
        emotion: "Analisi del sentimento",
        grammar: "Regole grammaticali",
        sentenceLength: "Rilevamento della lunghezza della frase",
        conceptWord: "Concetto parole",
        questionsAndAnswers: "Soluzione della base di conoscenza (vecchia)",
        element: "Domande e risposte essenziali",
        silent: "Timeout di risposta",
        associate: "Dati di follow-the-road",
        repeat: "Ripeti invio",
        timeInterval: "Durata dell'intervallo",
        typoDetection: "Rilevamento errori di battitura",
        silentOld: "Silenzio",
        knowledge: "Soluzione di conoscenza",
        wecomRemark: "Osservazioni micro aziendali del cliente",
        customerAddTime: "Tempo di aggiunta del cliente",
      },
      keywordOperators: {
        all: "Abbina tutto",
        allNot: "Tutti non corrispondono",
        any: "Qualsiasi corrispondenza",
      },
      applyRoles: {
        all: "Tutto",
        customer: "Cliente",
        agent: "Servizio clienti",
      },
      sentenceLength: {
        less: "Meno",
        greater: "Maggiore",
        equal: "Uguale",
        greater_or_equal: "Maggiore o uguale",
        less_or_equal: "Meno o uguale",
      },
      dialogueApplyScopes: {
        differentRoles: "Tra ruoli diversi",
        agent: "Servizio clienti",
        customer: "Cliente",
        agentResponse: "Risposta dei clienti",
        customerResponse: "Risposta dei clienti",
        customerNoResponse: "Cliente che non risponde",
      },
      applyConditionHitTypes: {
        first: "Il primo colpo.",
        every: "Ogni colpo.",
        last: "Ultimo colpo",
      },
      applyConditionScopes: {
        current: "Attuale",
        before: "Prima",
        after: "Dopo",
        beforeAll: "Tutto prima",
        afterAll: "Dopo tutto",
        around: "Nelle vicinanze",
      },
      voiceDemoTypes: {
        singleChannel: "Registrazione delle chiamate a canale singolo",
        dualChannel: "Registrazione delle chiamate a doppio canale",
      },
      sendStatus: {
        sending: "Invio",
        arrive: "Consegnato",
        fail: "Impossibile inviare",
        off_sending: "Invio offline non letto",
        off_arrive: "Leggi",
        rollback: "Ritirato",
      },
      collectionMethod: {
        assignment: "Incarico diretto",
        entity: "Estrazione dell'entità",
      },
      systemTypes: {
        qa: "Ispezione di qualità intelligente",
        wfm: "Programmazione intelligente",
      },
      entityOperators: {
        equals: "Uguali informazioni",
        notEquals: "Non uguale all'informazione",
        contains: "Contiene informazioni",
        notContains: "Non contiene informazioni",
        equalsFix: "Uguale al valore fisso",
        notEqualsFix: "Non uguale al valore fisso",
        containsFix: "Contiene valori fissi",
        notContainsFix: "Non contiene un valore fisso",
      },
      basicInfoOperators: {
        equals: "Uguale",
        notEquals: "Non uguale",
        contains: "Contiene",
        notContains: "Non contiene",
        isNull: "Vuoto",
        isNotNull: "Non vuoto",
      },
      volumeDetectionWays: {
        loudness: "Volume decibel",
        rangeAbilitySelf:
          "Gamma di cambiamento (rispetto a quello che ho fatto nella frase precedente)",
        rangeAbilityOth: "Gamma di cambiamento (rispetto alla frase sopra)",
        rangeAbility3: "Gamma di cambiamento (che io sono in questa frase)",
      },
      volumeDetectionOperators: {
        is: "Uguale",
        not: "Non uguale",
        greaterThan: "Maggiore",
        greaterThanEq: "Maggiore o uguale",
        lessThan: "Meno",
        lessThanEq: "Meno o uguale",
      },
      sentimentTypes: {
        positive: "Emozioni positive",
        negative: "Emozione negativa",
        neutral: "Emozione neutra",
        thankful: "Grazie",
        happy: "Felice",
        complaining: "Lamentati",
        angry: "Arrabbiato",
        post: "Anteriore",
        negat: "Negativo",
        neut: "Neutro",
      },
      emotionOperators: { is: "Conformità", not: "Non conforme" },
      propTypes: { system: "Sistema", user: "Personalizzato" },
      valueTypes: { text: "Testo", number: "Numeri" },
    },
    tasks: {
      allowCustomGrievance: "Consenti reclamo personalizzato",
      businessSummary: "Riepilogo aziendale",
      theDayBeforeYesterday: "L'altro ieri",
      assessActionTypes: { reassess: "Rivalutazione" },
      scoreType: {
        aiScore: "Valutazione intelligente",
        manualScore: "Valutazione manuale",
        interactiveDetection: "Rilevamento dell'interazione",
        smartRules: "Regole intelligenti",
        machineLearning: "Modalità di autoapprendimento",
      },
      inspectType: {
        inspectTrue: "Controlla",
        inspectFalse: "Non controllato",
      },
      operatorType: {
        keyword: "Corrispondenza delle parole chiave",
        notKeyword: "Assenza di corrispondenza con le parole chiave",
      },
      applyRole: {
        all: "Tutto",
        customer: "Cliente",
        agent: "Servizio clienti",
      },
      applyScope: {
        all: "Tutto",
        specific: "Frase specificata",
        scope: "Gamma specificata",
      },
      judgeStrategy: {
        all: "Soddisfa tutte le condizioni",
        arbitrarily: "Soddisfare qualsiasi condizione",
        custom: "Logica personalizzata",
      },
      checkPointStatus: {
        waitFor: "In attesa di revisione",
        notDeductScore: "Nessuna deduzione",
        deductScore: "Detrazioni",
      },
      applyOptions: {
        hit: "Condizione colpita",
        notHit: "Nessuna condizione specificata",
      },
      gradeTypes: {
        yesOrNo: "Conformità",
        input: "Input manuale",
        level: "Valutazione del grado",
        multipleFactors: "Elementi multipli",
      },
      gradeChooseHits: { yes: "Sì", no: "No" },
      gradeChangeTypes: {
        edit: "Modifica",
        check: "Revisione",
        file: "Archiviazione",
        remark: "Osservazioni",
        complain: "Denuncia",
        review: "Revisione",
        reviewEvaluation: "Rivedi la valutazione",
        sample: "Ispezione casuale",
        sampleEvaluation: "Ispezione e valutazione del campionamento",
        sampleSubmit: "Ispezione e presentazione del campionamento",
        sampleAssign: "Assegnazione del campionamento",
        reviewAssign: "Riesaminare l'assegnazione",
        read: "Leggi",
        reviewConfirm: "Rivedi la conferma",
        sampleConfirm: "Conferma dell'ispezione di campionamento",
      },
      gradeStatus: {
        unread: "Non letto",
        alreadyReview: "Recensito",
        alreadyFild: "Archiviato",
        waitReview: "Da rivedere",
        alreadyRead: "Leggi",
      },
      samplingFrequencyTypes: {
        everyDay: "Ogni giorno",
        everyWeek: "Settimanale",
        everyMonth: "Mensile",
      },
      samplingEveryDayTimeTypes: { yesterday: "Ieri", today: "Oggi" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Questa settimana",
        lastWeek: "La settimana scorsa.",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Questo mese",
        lastMonth: "Il mese scorso",
      },
      samplingTypes: {
        random: "Campionamento casuale",
        proportional: "Campionamento proporzionale",
      },
      weekdays: {
        Monday: "Lunedì",
        Tuesday: "Martedì",
        Wednesday: "Mercoledì",
        Thursday: "Giovedì",
        Friday: "Venerdì",
        Saturday: "Sabato",
        Sunday: "Domenica",
      },
      distributeTypes: {
        rule: "Assegnazione della priorità della regola",
        average: "Distribuzione casuale media",
      },
      notMatchAnyRuleTypes: {
        random: "Distribuzione media casuale",
        assign: "Assegna a persone designate",
        ignore: "Non assegnato",
      },
      inspectorTypes: {
        all: "Tutti gli ispettori di qualità",
        some: "Ispettore di qualità designato",
      },
      pushFrequencyTypes: {
        everyDay: "Ogni giorno",
        everyWeek: "Settimanale",
        everyMonth: "Mensile",
        inspectionUpdate: "Aggiornamento dell'ispezione di qualità",
        timelyInspection: "Ispezione di qualità in tempo reale",
      },
      pushObjectTypes: {
        email: "Specificare la cassetta postale",
        staff: "Servizio clienti",
      },
      reviewOptions: {
        reviewResult:
          "Visualizza i risultati delle ispezioni di qualità durante la revisione",
        reviewRecord: "Visualizza i record pertinenti durante la revisione",
        reviewAgentHidden:
          "Nascondi le informazioni dell'agente durante la revisione",
        samplingResult:
          "Visualizza i risultati delle ispezioni di qualità durante l'ispezione casuale",
        samplingRecord:
          "Visualizza i record pertinenti durante l'ispezione casuale",
        sampleAgentHidden:
          "Nascondi le informazioni del servizio clienti durante l'ispezione casuale",
        checkExportAddCallInfoIphone:
          "Allegare i registri delle chiamate quando si esporta un elenco",
        sampleExportAddCallInfoIphone:
          "Allegare i registri delle chiamate quando si esporta un elenco",
        checkExportAddCallInfoText:
          "Allegare record di conversazione quando si esporta un elenco",
        sampleExportAddCallInfoText:
          "Allegare record di conversazione quando si esporta un elenco",
        inspectionExportAddCallInfoIphone:
          "Allegare i record delle chiamate quando si esporta un elenco (è possibile esportare fino a 10000 alla volta)",
        inspectionExportAddCallInfoText:
          "Allegare record di conversazione quando si esporta un elenco (è possibile esportare fino a 10000 alla volta)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Consenti un rapido controllo a campione dei dettagli di qualità",
        fastSampleInSampleDetail:
          "Consentire l'esecuzione rapida di un'ispezione casuale nei dettagli di ispezione casuale",
        fastSampleInReviewDetail:
          "Consente una rapida esecuzione dei controlli a campione nei dettagli della revisione",
      },
      permitCustomReasonList: {
        permitCustomReason: "Consentire motivi personalizzati per i reclami",
      },
      autoMateTasks: {
        autoLoad: "Aggiungi automaticamente",
        sample: "Ispezione automatica del campionamento",
        assign: "Assegnazione automatica",
        pushes: "Spinta automatica",
        assess: "Valutazione automatica",
        check: "Revisione del reclamo",
        case: "Sincronizzazione della libreria dei casi",
      },
      qualityCheckDetailTypes: {
        review: "Revisione",
        spotCheck: "Ispezione casuale",
      },
      appealTypes: {
        transliterationError: "Errore di trascrizione",
        discriminationError: "Errore di giudizio",
        otherError: "Altri errori",
      },
      inspectDataSources: {
        voiceCall: "Chiamata vocale",
        textDialogue: "Dialogo di testo",
        realTimeVoiceCall: "Chiamate vocali in tempo reale",
        realTimeTextDialogue: "Conversazioni di testo in tempo reale",
      },
      pointTypes: {
        automatic: "Valutazione automatica",
        manual: "Valutazione manuale",
        interactiveDetection: "Rilevamento dell'interazione",
      },
      pointGradeTypes: {
        radio: "Selezione singola",
        input: "Input",
        select: "Seleziona",
      },
      sampleAndReviewStatus: {
        init: "Non estratto",
        unread: "Non letto",
        alreadyRead: "Leggi",
        complaining: "In Denuncia",
        reviewed: "Recensito",
        sampling: "Estrazione non controllata",
        samplingCompleted: "Campionamento",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Ignora l'eccesso di trabocco",
        automaticWeightCalculation: "Contabilità automatica del peso",
      },
      calculateScoreTypes: { add: "Punti bonus", sub: "Meno punti" },
      naChecked: {
        check: "Questo articolo non è coinvolto nel rilevamento consentito",
      },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Punti percentuali",
        allRight: "Tutti i punti corretti",
        anyRight: "Qualsiasi punteggio corretto",
      },
      noHitQuestionResults: {
        true: 'Il risultato è "Sì"',
        false: 'Il risultato è "No"',
      },
      repetRules: {
        same: "Contenuto esattamente lo stesso",
        similar: "Somiglianza semantica",
      },
      relateConditions: {
        highlightQuestion: "Evidenzia il problema",
        highlightAnswer: "Evidenzia risposta",
        hightlightFoctors: "Elemento Evidenzia",
      },
      interactiveQuestionTypes: {
        question: "Problema rilevato",
        eliminateQuestion: "Risoluzione dei problemi rilevati",
      },
      interactiveAnswerTypes: {
        noAnswer: "Nessuna risposta rilevata",
        answer: "Risposta rilevata",
        wrongAnswer: "Rilevata risposta errata",
      },
      inspectionUpdateTypes: {
        autoInspection: "Ispezione automatica di qualità",
        autoInspectionWechat: "Analisi automatica",
        artificialSampling: "Campionamento manuale",
        artificialReview: "Revisione manuale",
        manualModification: "Campionamento rapido",
        timelyInspection: "Ispezione di qualità in tempo reale",
        samplingAssign: "Assegnazione del campionamento",
        artificialComplain: "Presentazione del reclamo",
        fastSampling: "Ispezione e invio rapido del campionamento",
        recheckInspection: "Riispezione",
        recheckInspectionWechat: "Rianalisi",
      },
      timelyInspectionTypes: {
        timelyInspection: "Ispezione di qualità in tempo reale",
      },
      pushActionTypes: {
        email: "Mail",
        interfacePush: "Push dell'interfaccia",
      },
      pushDetailWays: {
        riskAlert: "Allarme rischio",
        subscribe: "Iscriviti ai messaggi",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Messaggio di chiamata/conversazione",
        inspectionResults: "Risultati delle ispezioni di qualità",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Messaggio di chiamata/conversazione",
        textContent: "Contenuto di testo di chiamate/conversazioni",
        inspectionResults: "Risultati delle ispezioni di qualità",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Punteggio medio all'interno del gruppo",
        addOrSubtract: "Punti più o meno diretti",
        noChange: "Nessun punto più o meno",
      },
      defaultScore: {
        empty: "Vuoto",
        add: "Punti bonus",
        notAdd: "Nessun punto extra",
        sub: "Meno punti",
        notSub: "Nessun punto ridotto",
        na: "N/A",
        input: "Input manuale",
        choose: "Seleziona",
      },
      strategyTypes: {
        hitAdd: "Premi bonus",
        hitNotAdd: "Nessun punto per colpire",
        hitSub: "Colpisci meno punti",
        hitNotSub: "Il colpo non riduce i punti",
      },
    },
    download: {
      exportStatus: {
        prepare: "In preparazione",
        process: "In corso",
        faild: "Esportazione non riuscita",
        success: "Successo",
      },
    },
    messageType: {
      all: "Tutto",
      monitor: "Monitoraggio immediato",
      trigger: "Notifica trigger",
      timing: "Rapporto tempismo",
    },
    messagePushType: {
      all: "Tutto",
      system: "Messaggi di sistema",
      sdk: "Messaggio SDK",
      push: "Push dell'interfaccia",
      email: "Mail",
      udesk: "Notifiche udesk",
      udeskWhite: "Notifica del sistema del servizio clienti",
      weChat: "Micro-messaggio aziendale",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Assegna ispettore di campionamento",
      sampleInspector: "Ispettore di campionamento",
      assignReviewer: "Assegna revisore",
      reviewer: "Revisore",
      taskInspector: "Compito ispettore",
    },
    sdkPushType: {
      webHook: "Webhook standard",
      dingDing: "Robot per inchiodare",
      weChat: "Robot WeChat aziendale",
      customer: "Contenuto Json personalizzato",
    },
    inspectionRemarksType: {
      inspection: "Ispezione di qualità",
      check: "Ispezione casuale",
      review: "Revisione",
    },
    segmentationStrategyType: {
      count: "Numero di messaggi",
      spacing: "Intervallo di messaggio",
    },
    segmentationStrategy: {
      byTime: "Segmentazione dei tempi",
      customize: "Affettatura combinata",
    },
    inspectType: {
      commonInspect: "Ispezione generale di qualità",
      associateInspect: "Ispezione della qualità associata",
    },
    typoDetectionOperators: { less: "Meno", greater: "Maggiore" },
    autoAddTypes: {
      realTimeAdd: "Aggiungi in tempo reale",
      timingAdd: "Aggiungi tempistica",
    },
    messageTypes: {
      text: "Messaggio di testo",
      markdown: "Messaggio di markdown",
    },
    inServiceStatus: {
      activated: "Attivato",
      disabled: "Disabili",
      inactivated: "Non attivato",
      resigned: "Uscita dall'impresa",
    },
    roles: { agent: "Impiegato", customer: "Cliente" },
    commentVisibleAuthority: {
      all: "Visibile a tutti",
      self: "Solo visibile a se stessi",
      designation: "Persona designata visibile",
    },
    appealCheckingTask: {
      eCommerceReview: "Revisione dell' e-commerce",
      socialMediaComments: "Commenti sui social media",
      largeModel: "Modello grande",
      customModel: "Modello personalizzato",
      universalModel: "Modello generico",
      enterpriseWeChatText: "Microtesto aziendale",
      generalQualityInspection: "Ispezione generale della qualità",
      processQualityInspection: "Ispezione della qualità del processo",
      voiceCopy: "Doppio disco vocale",
      beEqualTo: "Uguale",
      notEqualTo: "Non uguale",
      canAppeal: "Può presentare ricorso",
      noAppeal: "Non-Denuncia",
    },
    wechat: {
      redEnvelopes: "Busta rossa",
      text: "Testo",
      picture: "Immagine",
      voice: "Voce",
      voiceCall: "Chiamata vocale",
      video: "Video",
      businessCard: "Biglietto da visita",
      position: "Posizione",
      expression: "Espressione",
      link: "Collegamento",
      applet: "Piccolo programma",
      chatRecord: "Record di chat",
      mixedMessage: "Messaggio misto",
      file: "File",
    },
    gong: {
      sessionActivity: "Attività di sessione",
      conversationAction: "Sessione di azione",
      riskSemantics: "Semantica del rischio",
      daysNotFollowedUp: "Giorni non seguiti",
      waysideData: "Dati di follow-the-road",
      notStarted: "Non avviato",
      completed: "Completato",
      haveInHand: "In corso",
      unpublished: "Non pubblicato",
      published: "Pubblicato",
      knowledgePoints: "Punti di conoscenza",
      technologicalProcess: "Processo",
      notMarked: "Non etichettato",
      inAnnotation: "In callout",
      annotated: "Etichettato",
    },
    qualityChecingTask: {
      appealCanceled: "Annullato ricorso",
      complaintExpired: "Denuncia scaduta",
      incomingCall: "Chiamata in arrivo",
      exhale: "Espirare",
      incomingCallthirdParty: "Chiamata in arrivo (a tre vie)",
      incomingCallconsultation: "Chiamata in arrivo (consulenza)",
      incomingCallforcedInsertion: "Chiamata in arrivo (forte)",
      incomingCallmonitoring: "Chiamata in arrivo (ascolto)",
      incomingCalltransfer: "Chiamata in arrivo (trasferimento)",
      incomingCallinterception: "Chiamata in arrivo (intercettazione)",
      incomingCalltransferToExternalLine:
        "Chiamata in arrivo (trasferimento fuori linea)",
      incomingCallthirdPartyExternalLine:
        "Chiamata in arrivo (linea esterna a tre vie)",
      incomingCallexternalLine:
        "Chiamata in arrivo (consulenza al di fuori della linea)",
      outgoingCallthirdParty: "In uscita (a tre vie)",
      outgoingCalltransfer: "Risultato (trasferimento)",
      outgoingCallforcedInsertion: "In uscita (forte)",
      outgoingCallmonitoring: "In uscita (ascolto)",
      outgoingCallinterception: "Chiamata in uscita (intercettazione)",
      outgoingCalltransferToExternalLine:
        "In uscita (trasferimento alla linea esterna)",
      outgoingCallthreePartyExternalLine:
        "Chiamata in uscita (linea esterna a tre vie)",
      customerServiceAnswer: "Risposta al servizio clienti",
      customerServiceMissed: "Servizio clienti mancato",
      customerAnswer: "Risposta del cliente",
      customerMissed: "Cliente mancato",
      theTelephoneIsBusy: "Telefono occupato",
      phoneOffline: "Telefono offline",
      customerQuickHanging: "Attacco rapido del cliente",
      clientHangsUp: "Il cliente riaggancia",
      queueTimeout: "Timeout della coda",
      giveUpQueuing: "Abbandonare le code",
      outsideLineAnswering: "Risposta esterna",
      externalLineIsNotConnected: "Linea esterna non collegata",
      noQueueSelected: "Nessuna coda selezionata",
      notEvaluated: "Non valutato",
      noEvaluationRequired: "Non c' è bisogno di valutare",
      queuingSucceeded: "Coda riuscita",
      noCustomerServiceOnline: "Nessun servizio clienti online",
      customer: "Cliente",
      customerService: "Servizio clienti",
      outsideLines: "Fuori",
    },
    sessionActivity: {
      single: "Tempo medio di chiamata singola",
      all: "Totale chiamate",
      allTime: "Durata totale delle chiamate",
    },
    sessionAction: {
      salesProportion: "Rapporto di conversazione sulle vendite",
      salesLongest: "Dichiarazione di vendita media più lunga",
      customerLongest: "Parlare il cliente medio più lungo",
      discussNumber: "Numero di discussioni approfondite",
      frequency: "Frequenza delle domande",
    },
    monitorCriteria: { all: "Prima e dopo", before: "Prima", after: "Dopo" },
    monitorTimeCycle: { day: "Giorno", week: "Settimana", month: "Mese" },
    monitorGroupTestType: {
      ab: "Confronto dei due gruppi",
      a: "Gruppo A",
      b: "Gruppo B",
    },
    fields: {
      department: "Dipartimento",
      customerTags: "Etichetta del cliente",
      member: "Membri",
      time: "Tempo",
      cascade: "Cascata",
      anyMatch: "Qualsiasi corrispondenza",
      keyWord: "Parole chiave",
      keyEvents: "Eventi chiave",
    },
    semanticIntelligence: {
      staff: "Impiegato",
      customer: "Cliente",
      employeesAndCustomers: "Dipendenti e clienti",
      notStarted: "Non avviato",
      haveInHand: "In corso",
      completed: "Completato",
      callRecording: "Registrazione delle chiamate",
      enterpriseWeChatCall: "Micro-Talk aziendale",
      enterpriseWeChatText: "Microtesto aziendale",
      discourseMiningForObjectionHandling:
        "Gestione dell'obiezione Discorso Mining",
      productSellingPointScriptsMining:
        "Punto di vendita del prodotto talk mining",
      conversationScenario: "Scenari di sessione",
      topicOfConversation: "Argomento",
      keyEvents: "Eventi chiave",
      customerLabel: "Etichetta del cliente",
      includeAny: "Contiene qualsiasi",
      includeAll: "Contiene tutti",
      and: "E",
      or: "O",
      question: "Frase interrogativa",
      nonInterrogativeSentence: "Frase non interrogativa",
    },
    coach: {
      notPassed: "Non superato",
      analogTelephone: "Telefono analogico",
      enterScenarioInformation: "Inserisci le informazioni sullo scenario",
      generateDialogueFramework: "Generazione di un quadro di dialogo",
      generatePromptLanguage: "Prompt generato",
      aIGeneratedDialogue: "Dialog generato dall'intelligenza artificiale",
      system: "Sistema",
      custom: "Personalizzato",
      flow: "Processo",
      script: "Parole",
      express: "Espressione",
      emotion: "Commozione",
      rateAccordingToCourseConfiguration: "Configurazione punteggio per corso",
      intelligentModelRating: "Punteggio modello intelligente",
      allData: "Tutti i dati",
      dataFromThisDepartment: "Dati del Dipartimento",
      multidepartmentalData: "Dati multisettore",
      underReview: "In revisione",
      no: "No",
      yes: "Sì",
      automaticRating: "Punteggio automatico",
      auditRating: "Punteggio di audit",
      reRating: "Ri-punteggio",
      inAppeal: "In Denuncia",
      reviewed: "Recensito",
      notViewed: "Non visto",
      viewed: "Visto",
      uncorrected: "Non corretto",
      corrected: "Corretto",
      practice: "Esercizio",
      examination: "Esame",
      other: "Altro",
      notMeetingStandards: "Non all'altezza degli standard",
      meetingStandards: "Standard",
      excellent: "Eccellente",
      invalid: "Fallimento",
      notStarted: "Non avviato",
      haveInHand: "In corso",
      ended: "Chiuso",
      completed: "Completato",
      hangInTheAir: "Incompiuto",
      passed: "Superato",
      voiceConversation: "Dialogo vocale",
      textDialogue: "Dialogo di testo",
      slidePresentation: "Presentazione slide",
      unpublished: "Non pubblicato",
      published: "Pubblicato",
      narration: "Narratore",
      studentSpeaking: "Cadet che parla",
      robotTalk: "I robot parlano.",
      knowledgeBaseQA: "Domande e risposte della Knowledge Base",
      slide: "Slide",
      negativeEmotions: "Emozione negativa",
      emotionallyNormal: "Umore normale",
      incompleteScript: "Discorso incompleto",
      completeScript: "Discorso completo",
      normalSpeechSpeed: "VELOCITÀ NORMALE",
      speakTooFast: "Speedy troppo veloce",
      speakTooSlowly: "VELOCITÀ LENTA",
      whole: "Tutto",
      singlePage: "Pagina singola",
      qA: "Una domanda e una risposta",
      situationalDialogue: "Dialogo situazionale",
      misses: "Mancato",
      hit: "Colpisci",
      sequentialQA: "Domande e risposte sequenziali",
      randomQA: "Domande casuali",
      mastered: "Mastering",
      notMastered: "Non masterizzato",
    },
    algorithm: {
      salesEmpowerment: "Responsabilizzazione delle vendite",
      enterpriseWeChatVoice: "Voce WeChat aziendale",
      enterpriseWeChatText: "Testo WeChat aziendale",
      waiting: "In attesa",
      extracting: "In estrazione",
      success: "Successo",
      fail: "Impossibile",
      intelligentQualityInspection: "Ispezione di qualità intelligente",
      textDialogue: "Dialogo di testo",
      voiceCall: "Chiamata vocale",
      smartTags: "Etichetta intelligente",
      satisfactionEvaluation: "Valutazione della soddisfazione",
      score: "Valutazione",
      grade: "Valutazione",
      customerServiceTeam: "Gruppo di servizio clienti",
      conversationAnalysis: "Analisi della conversazione",
    },
    operatorListGather: {
      businessSummary: "Riepilogo aziendale",
      firstSentence: "Prima frase",
      lastSentence: "Ultima frase",
      allTime: "Tutti i tempi",
      nonBusinessWorkingHours: "Orario non lavorativo",
      businessWorkingHours: "Orario di lavoro aziendale",
    },
    callDialogueManage: {
      eCommerceReview: "Revisione dell' e-commerce",
      socialMediaComments: "Commenti sui social media",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Accesso",
        email: "Cassetta postale",
        password: "Password",
        forgetPwd: "Password dimenticata",
        emailCannotBeEmpty: "La casella di posta non deve essere vuota.",
        passwordCannotByEmpty: "La password non deve essere vuota.",
        mustChangeLogin:
          "Spiacenti, la tua password deve essere reimpostata, contatta l'amministratore per reimpostare la password.",
        dismissionLogin: "Scusa, hai lasciato il tuo lavoro.",
        forbiddenLogin: "Spiacenti, il tuo account è disabilitato.",
        loginError:
          "Accesso non riuscito, casella di posta o errore della password.",
        loginPrompt: "Accedi al sistema",
        rememberMe: "Ricordati di me",
      },
      logout: {
        logoutError:
          "Disconnessione non riuscita. Controlla la tua connessione di rete o riprova più tardi.",
      },
      resetPassword: {
        forgetPwd: "Password dimenticata",
        inputEmailPrompt:
          "Inserisci l' e-mail del tuo account qui sotto e ti invieremo un' e-mail e le istruzioni per reimpostare la tua password.",
        sendEmailSuccess:
          "La lettera di conferma della modifica della password è stata inviata con successo all' e-mail che hai inserito, controlla in tempo.",
        sendEmailFailed:
          "L'invio della casella di posta non è riuscito. Controlla la tua connessione di rete o riprova più tardi.",
        setNewPassword: "Imposta la tua nuova password",
        passwordCannotEmpty: "La password non deve essere vuota",
        passwordFormWrong: "Formato della password errato",
        confirmPasswordWrong: "Le due password inserite non sono uguali.",
        passwordResetSuccess: "Ripristino della password riuscito.",
        passwordResetFailed:
          "Reimpostazione della password non riuscita. Controlla la tua connessione di rete o riprova più tardi.",
        linkTimeover:
          "Ripristina il collegamento della password scaduto, riprova",
      },
      form: { title: "Visualizzazione del modulo CASO" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Cassetta postale",
          emailFormatError:
            "Errore nel formato della cassetta postale, inserisci di nuovo!",
          forgotPassword: "Hai dimenticato la tua password?",
          describe:
            "Inserisci l' e-mail del tuo account qui sotto e ti invieremo un' e-mail e le istruzioni per reimpostare la tua password.",
          send: "Invia",
          emailCannotBeEmpty: "La cassetta postale non può essere vuota",
          resetPasswordExpire:
            "Ripristina il collegamento della password scaduto, riprova",
        },
        resetPassword: {
          setPassword: "Imposta la tua nuova password",
          newPassword: "Nuova password",
          repeatPassword: "Password duplicata",
          resetPassword: "Reimposta la password",
          passwordCannotBeEmpty: "La nuova password non può essere vuota.",
          confirmPasswordCannotBeEmpty:
            "La password duplicata non può essere vuota.",
          passwordsNotMatch: "Due password sono incoerenti!",
          passwordFormWrong:
            "La password non può essere in cinese e il numero è 6-14!",
          linkExpiration:
            "Ripristina il collegamento della password scaduto, riprova",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Non sei autorizzato a visualizzare questa pagina",
      },
    },
    downloadCenter: {
      name: "Centro download",
      saveTime: "(30 giorni per impostazione predefinita)",
      headerTitle: "Scarica file",
      download: "Scaricare",
      downloadPromp: "L'attività di download è stata inviata, vai",
      viewProgress: "Visualizza progresso",
    },
    uploadCenter: { name: "Centro Carica" },
    tasks: {
      index: {
        name: "Nome",
        remark: "Descrizione",
        qualityCheckTasksTitle: "Banco da lavoro",
        informationCollection: "Compiti di raccolta",
        qualityCheckTasks: "Compito di ispezione di qualità",
        wechatQaTasks: "Attività di analisi",
        moduleName: "Usa nuovo modello",
        creatNewQualityCheckTask: "Nuova attività",
        generalInspectionList: "Rapporto generale di ispezione della qualità",
        editQualityCheckTask: "Modifica attività di ispezione della qualità",
        creatNewQualityCheckTaskError:
          "Impossibile creare una nuova attività di ispezione della qualità.",
        editQualityCheckTaskError:
          "Impossibile modificare l'attività di ispezione di qualità.",
        type: "Tipo di modulo",
        singleScore: "Punteggio singolo",
        singleScoreNullError: "Il punteggio singolo non può essere vuoto",
        inspector: "Ispettore di qualità",
        inspectDataSource: "Fonte dati",
        taskName: "Nome attività",
        inspectionType: "Tipo di ispezione di qualità",
      },
      manage: {
        screeningCondition: "Condizione del filtro",
        template: {
          condition: {
            index: {
              name: "Condizione",
              existingConditions: "Condizioni esistenti",
            },
            detail: {
              id: "ID",
              name: "Nome condizione",
              remark: "Descrizione",
              checkRange: "Intervallo di rilevazione",
              operator: "Operatore",
              operatorLogic: "Logica dell'operatore",
            },
          },
          checkPointTest: {
            title: "Prova della regola",
            running: "Regole nel test, per favore più tardi...",
            clearSuccess: "Dialogo simulato svuotato con successo",
            mock: "Dialogo simulato",
            save: "Salva la finestra di dialogo selezionata",
            saveAll: "Salva tutte le conversazioni",
            saveSuccess: "Conversazione salvata con successo",
            close: "Uscita",
            delete: "Elimina selezionati",
            clear: "Vuoto",
            customer: "Cliente",
            agent: "Servizio clienti",
            test: "Test",
            request:
              "Dopo che la finestra di dialogo di simulazione è stata cancellata, non può essere recuperata. Confermi di cancellarlo?",
            deletePopconfirmContent:
              "Sei sicuro di voler eliminare la finestra di dialogo selezionata?",
          },
          checkPoint: {
            index: {
              name: "Configurazione delle regole",
              wechatTitle: "Configurazione del modello di analisi",
              existingCheckPoints: "Regole di ispezione di qualità esistenti",
              currentCheckPointScore:
                "Il punteggio corrente della regola di ispezione della qualità è",
              currentCheckPointScoreWechat:
                "Il punteggio attuale del modello di analisi è",
              currentCheckPointWeight:
                "Il peso totale dell'attuale regola di ispezione della qualità è",
              weightTotalScoreAndItemScore:
                "Punteggio totale: 100, punteggio singolo:",
              template: "Modello di regola di ispezione di qualità",
              import: "Importa il modello di regola di ispezione della qualità",
              getTemplateError:
                "Impossibile ottenere il modello di regola di ispezione della qualità.",
              tabs: { template: "Modello", demo: "Campione" },
              customize: "Modello bianco",
              move: "Trascina e rilascia per regolare la classificazione e l'ordinamento",
              classification: "Classificazione dell'ispezione di qualità",
              classificationWeChat: "Classificazione del modello",
              first: "Classificazione di livello 1",
              second: "Classificazione secondaria",
              new: "Nuova regola di ispezione di qualità",
              weChatNew: "Aggiungi modello di analisi",
              createWeChat: "Nuovo modello di analisi",
              empty:
                "Non ci sono regole di ispezione di qualità per il momento,",
              wechatEmpty: "Nessun modello di analisi,",
              newSecond: "Nuova classificazione secondaria",
              newFirst: "Nuova classificazione di primo livello",
              checkPoint: "Regole di ispezione di qualità",
              checkPointWeChat: "Modello di analisi",
            },
            detail: {
              name: "Nome",
              remark: "Descrizione",
              type: "Tipo",
              deduction: "Detrazioni",
              rule: "Regole",
              highlighted: "Evidenzia",
              gradeType: "Metodo di punteggio",
              interactiveCategory: "Classificazione di interazione",
              predeterminedScore: "Punteggio",
              weight: "Peso",
              na: "N/A",
              default: "Punteggio predefinito",
              preRule: "Regola precondizione",
              analysisHighlighted: "Regole di analisi",
              categorySelectPrompt: "Seleziona una categoria",
              checkHighlightArrayError:
                "La regola non corrisponde all'evidenziazione, fai nuovamente clic sul pulsante della regola di analisi.",
              foctorsHolder: "Seleziona un elemento",
              foctorsErrorNone:
                "La regola multi-elemento è vuota, aggiungi un tipo multi-elemento.",
              naError:
                "Non è possibile chiudere l'articolo quando il punteggio predefinito è N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A è il non applicabile, che stabilisce le condizioni preliminari per la regola di ispezione di qualità. Se la condizione è soddisfatta, la regola non è applicabile. Se la condizione non è soddisfatta, la regola non viene rilevata;\nDescrizione delle precondizioni: "&&" significa "e", "||" significa "o", "!" Indica "NON", compila l\'esempio "(R1 || R2) && R3"',
                applicationMessageType: "Tipo di messaggio dell'applicazione",
                selectAll: "Seleziona tutto",
                highlightTags: "Evidenzia etichetta:",
                ruleType: "Tipo di regola",
                dataSource: "Fonte dati",
                whole: "Tutto",
                enterpriseMicroConversation: "Micro-sessione aziendale",
                enterpriseAndMicroCommunication: "Micro-Talk aziendale",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Nodo giudizio",
                    dialogueNode: "Nodo di dialogo",
                    editNodes: "Modifica nodo",
                  },
                  nodeConfig: {
                    nodeName: "Nome del nodo",
                    nodeRules: "Regole del nodo",
                    scoringLogic: "Logica di punteggio",
                    score: "Punteggio:",
                    rule: "Regole",
                    multiBranchLogic: "Logica multi-ramo",
                    highlight: "Evidenzia",
                    submit: "Presentazione",
                  },
                  ruleList: {
                    branch: "Ramo",
                    branchName: "Nome del ramo:",
                    addRules: "Aggiungi regola",
                  },
                  sidebar: { node: "Nodo" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Rifinitura automatica",
                      canvasAdaptation: "Tela adattiva",
                      copy: "Copia",
                      delete: "Elimina",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Il nodo figlio del nodo giudizio non può essere utilizzato come target di connessione.",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Il nodo start non può essere un target di connessione",
                      startingNodeCannotConnectMultipleNodes:
                        "Il nodo Start non può connettere più nodi",
                      nodeCannotBeConnectedToItself:
                        "Il nodo non può connettersi a se stesso",
                      startNode: "Nodo di avvio",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Annulla Seleziona tutto",
                    selectAll: "Seleziona tutto",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Conformità",
                    gradedEvaluation: "Valutazione del grado",
                  },
                },
              },
            },
            configuration: {
              title: "Regole di ispezione di qualità",
              wechatTitle: "Modello di analisi",
              neme: "Nome",
              errorMessage:
                "Eliminazione non riuscita con almeno una condizione",
              desc: "Descrizione",
              status: "Stato",
              yes: "Efficace",
              no: "Non valido",
              namePlaceHolder: "Inserisci il nome di un'etichetta",
              desPlaceHolder: "Inserisci una descrizione",
              nameEmpty: "Il nome non può essere vuoto",
              desEmpty: "La descrizione non può essere vuota",
              type: "Tipo",
              ruler: "Regole intelligenti",
              machineLearning: "Modalità di autoapprendimento",
              autoScore: "Punteggio intelligente (vecchio)",
              manualScore: "Punteggio manuale",
              interaction: "Rilevamento dell'interazione (vecchio)",
              typeTips: {
                model:
                  'Modello intelligente: è un sistema di ispezione di qualità preimpostato, che può controllare rapidamente i problemi esistenti nel servizio senza configurazione e manutenzione. Questo modello è più generale, se non si soddisfano i requisiti aziendali, si prega di utilizzare "regole intelligenti" o "punteggio manuale".',
                rule: "Regole intelligenti: punteggio automatico basato sulla regola AI",
                manual:
                  "Punteggio manuale: ispezione manuale della qualità, punteggio manuale per manuale",
              },
              model: "Modello:",
              ruleType: {
                words: "Parole",
                interaction: "Interazione",
                feature: "Caratteristiche",
                information: "Informazioni",
              },
              testingMode: {
                all: "Rilevamento di tutte le parole chiave.",
                any: "Rileva parole chiave arbitrarie",
              },
              operatorTypesOfInteraction: {
                question: "Una domanda e una risposta",
                element: "Gli elementi rispondono",
                repet: "Duplicazione del contesto",
                snatch: "Strappa parole",
                silent: "Silenzio",
              },
              role: "Ruolo di rilevazione:",
              preposition: "Pre-operatore:",
              range: "Gamma di rilevazione:",
              from: "Sezione",
              to: "Sentenza al n.",
              end: "Sentenza",
              hasOldSilentTip:
                'Ciao, al fine di migliorare la precisione, "Intelligent Rules-Interaction-Silence" è stato aggiornato a "Response Timeout". Si consiglia di aggiornare le regole di utilizzo!',
              changeToOld: "Passa all'eredità",
              changeToNew: "Passa alla nuova versione",
              logic: {
                logic: "Logica di punteggio",
                naTip:
                  "N/A è il non applicabile, che stabilisce le condizioni preliminari per la regola di ispezione di qualità. Se le condizioni sono soddisfatte, la regola non è applicabile. Se le condizioni non sono soddisfatte, la regola non viene rilevata.",
                score: "Punteggio:",
                na: "N/A：",
                naDes:
                  "Questo articolo non è coinvolto nel rilevamento consentito",
                preRule: "Regole precondizione:",
                notCatchPreRule: "Quando i presupposti non sono soddisfatti:",
                naChecked: "Quando si colpisce N/A:",
                preRuleEmpty:
                  "La regola della precondizione non può essere vuota",
                operatorEmpty:
                  "La condizione necessaria non può essere nulla nell'operatore",
                multipleEmpty:
                  "La condizione necessaria non può essere vuota in un punteggio logico multiplo",
                average: "Punteggio medio all'interno del gruppo",
                addOrSubtract: "Punti più o meno diretti",
                noChange: "Nessun punto più o meno",
                rule: "Regole:",
                all: "Soddisfare tutti gli operatori",
                any: "Soddisfare operatore arbitrario",
                customize: "Logica dell'operatore personalizzata",
                multiple: "Punteggio di logica multipla",
                heightLight: "Evidenzia:",
                machineLearningTip:
                  "Attualmente, la modalità di autoapprendimento è efficace solo per il discorso a frase singola. Per un discorso complesso, si prega di utilizzare regole intelligenti.",
                strategy: "Strategia:",
              },
            },
            component: {
              approvalSubmittedSuccessfully:
                "Approvazione inviata correttamente",
            },
            components: {
              pageHeaderExtra: {
                test: "Test",
                formal: "Formale",
                submit: "Presentazione",
              },
              createModal: { index: { myRules: "Le mie regole" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Automazione dei processi",
              screeningCondition: "Condizione del filtro",
              aiScreeningCondition: "Condizione intelligente del filtro",
              rule: "Regole",
              automaticAddition: "Aggiungi automaticamente",
              automaticAdditionTip:
                "Aggiungere automaticamente i dati alle attività in tempo reale o a intervalli regolari per l'ispezione della qualità AI",
              autoAdd: {
                autoAddType: "Metodo di aggiunta automatica",
                addFrequency: "Aggiungi frequenza",
                add: "Aggiungi",
              },
              automaticSamplingInspection:
                "Ispezione automatica del campionamento",
              automaticSamplingInspectionTip:
                "Estrarre automaticamente i dati che richiedono l'ispezione manuale della qualità, ad esempio, estrarre il 10% dei dati casuali di ieri ogni giorno per il campionamento manuale.",
              everyAgentCheckLimit: "Massimo per estrazione agente",
              automaticAllocation: "Assegnazione automatica",
              automaticAllocationTip:
                "Assegnazione delle ispezioni di campionamento: i dati estratti vengono assegnati automaticamente agli ispettori di qualità. Ad esempio, il 10% dei dati casuali di ieri viene estratto quotidianamente per l'ispezione manuale del campionamento e gli ispettori di qualità 2 vengono assegnati in modo casuale a questa attività in media.",
              automaticAllocationTip2:
                "Assegnazione della revisione: i dati dell'appello vengono automaticamente assegnati all'ispettore della qualità per la revisione, ad esempio, i dati di ricorso giornalieri vengono assegnati in modo ugualmente casuale ai 2 ispettori di qualità di questa attività per la revisione.",
              automaticPush: "Spinta automatica",
              automaticPushTip:
                "Notifica trigger: notifica/allarme durante l'ispezione di qualità, ad esempio, quando si verifica un errore fatale nel servizio clienti, viene inviato un messaggio di notifica al micro gruppo aziendale.",
              automaticPushTip2:
                "Rapporto a tempo: inviare i risultati dell'ispezione di qualità sotto forma di posta, ad esempio, inviare i risultati dell'ispezione di qualità del campionamento manuale di ieri alla casella di posta del supervisore del servizio clienti alle 9:00 ogni giorno.",
              automaticAssess: "Valutazione automatica",
              automaticAssessTip:
                "I risultati del campionamento manuale vengono regolarmente confrontati con i risultati dell'ispezione della qualità AI per la valutazione dell'accuratezza. Ad esempio, il tasso di accuratezza dei dati di campionamento di ieri viene valutato alle 8:00 ogni giorno.",
              reexaminationComplaint: "Revisione del reclamo",
              spoktReviewDetail: "Configurazione aziendale",
              spoktReviewDetailTip:
                "Configura funzioni come l'elenco delle ispezioni di qualità, l'elenco delle ispezioni casuali, l'attività dei reclami, l'elenco delle revisioni e le osservazioni",
              qaDetailAction: "Configurazione dell'ispezione di qualità",
              spoktDetailAction: "Configurazione del campionamento",
              reviewDetailAction: "Rivedi la configurazione",
              appealAction: "Configurazione del reclamo",
              remarkTemplate: "Modello di nota",
              automaticArchiving: "Archiviazione automatica",
              conditionalRules: "Regole di condizione",
              qualityInspectionConditions: "Condizioni di ispezione di qualità",
              unopened: "Non ancora aperto",
              sampleInspectionDistributionModel:
                "Assegnazione del campionamento",
              reviewAllocationModel: "Riesaminare l'assegnazione",
              distributionModelTitle: "Politica di assegnazione:",
              pushRules: "Regole push",
              push: "Spingi",
              assessRules: "Regole di valutazione",
              on: "on",
              off: "off",
              updateStatusSuccess: "Cambiamento di stato riuscito!",
              updateStatusFailed:
                "Modifica dello stato non riuscita, riprova più tardi!",
              sampleNumberOrPercent: "Numero o proporzione di campionamento",
              inspectorList: "Ispettore di qualità designato",
              numberOfSubmission:
                "Numero di dichiarazioni autorizzate da presentare",
              rewivewDays: "Giorno",
              TimeOfSubmission:
                "Data di validità della presentazione delle dichiarazioni",
              rewivewSecond: "Tempi",
              switchOn: "Apri",
              switchOff: "Chiudi",
              notMatchAnyRule: "Quando non vengono abbinate regole:",
              appealOptionsList: {
                hiddenInspector:
                  "Denuncia informazioni dell'ispettore nascoste",
                hiddenHit:
                  "La pagina di appello supporta hit di evidenziazione e posizionamento.",
              },
              caseLibrary: "Sincronizzazione della libreria dei casi",
              caseLibrarySyncTip:
                "Sincronizzare i casi aggiunti dal sistema QA al sistema del servizio clienti",
              addCase: "Aumentare la sincronizzazione",
              caseLibraryId: "Libreria del caso",
              caseLibraryIdTip:
                "Raggruppamento della base del caso del sistema QA",
              udeskCaseLibraryIdTip:
                "Raggruppamento dei casi del sistema di assistenza clienti",
              syncHis: "Caso di storia sincrona",
              otherSettings: "Altre impostazioni",
              udeskCaseLibraryId: "Push Case Group",
              url: "URL push",
              urlTip:
                "Inserisci un indirizzo di interfaccia che inizia con http:// o https://",
            },
            templateNew: {
              secondaryReview: "Revisione secondaria",
              numberOfAppealsAllowedToBeSubmitted: "Numero di ricorsi ammessi",
              validityOfSubmissionOfAppeal:
                "Validità della presentazione del ricorso",
              validityOfAppealReview: "Validità del riesame del ricorso",
              hours: "Ore",
              businessSummary: "Riepilogo aziendale",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Limite inferiore di estrazione per agente",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Il riepilogo aziendale viene generato automaticamente dal modello di grandi dimensioni e la dimensione può essere personalizzata in base alla situazione aziendale.",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Strategia di assegnazione solo per il revisore di livello 1",
              secondaryCompounding: "Composito secondario:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "I revisori secondari supportano solo la distribuzione uguale",
              secondLevelReviewer: "Revisore di secondo livello:",
              samplingInspectionTarget:
                "Obiettivo di ispezione di campionamento",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Impostare l'obiettivo da completare mediante ispezione di campionamento manuale e fornire una richiesta quando l'obiettivo non viene raggiunto o superato. Ad esempio, ogni servizio clienti dovrebbe completare l'ispezione di campionamento di 100 articoli al mese e quando l'obiettivo è inferiore a 80 articoli, la pagina di ispezione di qualità e di ispezione di campionamento fornirà un suggerimento.",
            },
            component: {
              verificationFailed: "Verifica non riuscita",
              pleaseAddASecondLevelQualityInspector:
                "Si prega di aggiungere un ispettore di qualità di secondo livello",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Prompt",
                    fieldName: "Nome del campo",
                    fieldDescription: "Descrizione del campo",
                    generateContent: "Genera contenuto",
                    enabled: "Abilitato",
                    generationMethod: "Metodo di generazione",
                    automaticGeneration: "Generato automaticamente",
                    manualTrigger: "Trigger manuale",
                    filterCondition: "Condizione del filtro",
                    return: "Reso",
                    preserve: "Salva",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Genera risultati" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Non qualificato",
                    limitedScope: "Portata limitata",
                    add: "Aggiungi",
                    thereIsCurrentlyNoDataAvailable: "Nessun dato",
                  },
                },
              },
              index: {
                fieldName: "Nome del campo",
                fieldDescription: "Descrizione del campo",
                nothing: "Nessuno",
                generationMethod: "Metodo di generazione",
                generateManually: "Generare manualmente",
                automaticGeneration: "Generato automaticamente",
                generateContent: "Genera contenuto",
                notQualified: "Non qualificato",
                filterCondition: "Condizione del filtro",
                enabled: "Abilitato",
                type: "Tipo",
                system: "Sistema",
                custom: "Personalizzato",
                operation: "Operazione",
                edit: "Modifica",
                areYouSureToDeleteIt: "Sei sicuro di voler eliminare?",
                delete: "Elimina",
                addField: "Aggiungi campo",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Configurazione ruolo",
              roleConfigTip:
                "Utilizzato per configurare le regole di riconoscimento per l'elaborazione della separazione degli altoparlanti quando i dati delle chiamate importati sono mono. Conforme alle regole per il ruolo dell'agente",
              operator: "Operatore",
              operatorLogic: "Logica dell'operatore",
            },
          },
          manualAdd: {
            index: {
              name: "Aggiungi manualmente",
              tip: "Utilizzato per filtrare i dati di anteprima configurando manualmente i criteri e aggiungerli rapidamente all'attività di ispezione della qualità",
              screeningCondition: "Criteri di aggiunta per filtro",
              aiScreeningCondition: "Condizione intelligente del filtro",
              rule: "Regole",
              createTime: "Ora aggiunta",
              filterTitle: "Filtrare il contenuto",
              filterRule: "Condizione del filtro",
              startTime: "Ora di inizio",
              endTime: "Ora di fine",
              records: "Registri di importazione",
              createUserName: "Operatore",
              successCount: "Aggiunto correttamente",
              conditionRule: "Regole di condizione:",
              preview: "Anteprima",
              previewData:
                "Attualmente sono disponibili {0} criteri di filtro corrispondente, sono state aggiunte {1} attività, è possibile aggiungere {2} attività e non è possibile aggiungere {3} attività.",
              failCount: "Aggiunte non riuscite",
            },
          },
          basicInfo: {
            index: {
              title: "Informazioni di base",
              name: "Nome",
              taskName: "Nome attività",
              taskNamePlaceholder: "Inserisci il nome di un'attività",
              requiredName: "Il nome dell'attività non può essere vuoto",
              taskRemarkPlaceholder: "Inserisci una descrizione dell'attività",
              requiredRemark:
                "La descrizione dell'attività non può essere vuota",
              remark: "Descrizione",
              taskRemark: "Descrizione attività",
              source: "Fonte dati",
              benchmarkScore: "Punteggio di riferimento",
              benchmarkScorePlaceholder:
                "Inserisci un punteggio di riferimento",
              scoreLowerLimit: "Punteggio minimo Limite inferiore",
              scoreUpperLimit: "Cap punteggio massimo",
              inspectType: "Tipo di ispezione di qualità",
              nlpModel: "Il modello NLP",
              commonInspect: "Ispezione generale di qualità",
              associateInspect: "Ispezione della qualità associata",
              associateCycle: "Periodo di correlazione",
              associateRegulation: "Regola di associazione",
              judgeStrategy: "Logica di esecuzione",
              rule: "Regole",
              commonTip:
                "Ispezione generale di qualità: applicabile alla singola comunicazione e al dialogo e all'ispezione di qualità del contenuto della singola comunicazione.",
              associateTip:
                "Ispezione di qualità associata: applicabile a più comunicazioni e conversazioni, che collegano il contenuto di più comunicazioni per l'ispezione di qualità.",
              cycleTip:
                "Se sono presenti più comunicazioni e dialoghi associati, i dati nel periodo associato entreranno nell'attività per l'ispezione di qualità.",
              scoreLowerLimitPlaceholder:
                "Inserisci il limite minimo di punteggio",
              scoreUpperLimitPlaceholder:
                "Inserisci il limite massimo del punteggio",
              call: "Chiamata vocale",
              dialogue: "Dialogo di testo",
              user: "Ispettore di qualità",
              wechatUser: "Operatore",
              noDesignated: "Non specificare",
              type: "Tipo di modulo",
              singleScore: "Punteggio singolo",
              conduct:
                "Si prega di completare prima le seguenti informazioni, quindi fare clic su Avanti",
              conduct2:
                "Si prega di completare la configurazione delle regole di ispezione di qualità e dei punti di ispezione di qualità. Se hai completato la configurazione, puoi fare clic su Avanti o Guida all'uscita",
              conduct3:
                "La valutazione di ispezione di qualità è configurata secondo il punto di ispezione di qualità e la sua valutazione. È possibile definire diversi livelli di risultato dell'ispezione di qualità. Se non hai bisogno di una valutazione, puoi saltare questo passaggio. Se è stata completata la configurazione, è possibile fare clic su Avanti o uscire dalla guida.",
              conduct4:
                "È possibile configurare le caratteristiche dei dati che verranno automaticamente aggiunte all'attività in base alle caratteristiche dei dati che l'attività dovrebbe controllare nella posizione di aggiunta automatica. Se per il momento non è necessario aggiungere automaticamente i dati, è possibile fare clic su Skip. Se hai completato la configurazione, puoi fare clic su Avanti.",
              conduct5:
                "Se si utilizza la registrazione mono, assicurarsi di configurare questo elemento e fare clic su Avanti. Si prega di utilizzare solo la voce del servizio clienti per aiutare il sistema a identificare le diverse tracce tra il servizio clienti e il cliente. Se si utilizza la registrazione a due canali o si è completata la configurazione, è possibile fare clic su Salta",
              conduct6:
                "È possibile utilizzare la funzione di aggiunta manuale per introdurre i dati di comunicazione esistenti nel sistema per l'ispezione della qualità, oppure è stata inviata un'attività di aggiunta manuale, fare clic su Fine direttamente",
              helper: "Visualizza la documentazione della guida",
              quit: "Avvio di uscita",
              next: "Prossimo passo",
              complete: "Completa",
              skip: "Salta",
              cancel: "Annulla",
              delete: "Elimina",
              save: "Salva",
            },
            template: {
              samplingInspector: "Ispettore di campionamento",
              sessionType: "Tipo di sessione",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Banco da lavoro per l'ispezione della qualità",
              search: "Filtro",
              export: "Esportazione",
              condition: "Condizione",
              logic: "Logica",
              reQualityInspection: "Riispezione",
              reQualityInspectionUnderWay: "Compito di ri-ispezione...",
              reQualityInspectionUnderWayTip:
                "Lasciare la pagina dopo aver chiuso la finestra non influisce sul compito",
              reQualityInspectionDone: "Riispezione completata",
              reQualityInspectionDoneTip:
                "La nuova ispezione è stata completata con successo, fare clic sul pulsante di conferma.",
              endReQualityInspection: "Terminare l'attività",
              alreadyDoneTip: "Completato",
              totalTip: "Totale",
              unit: "Articolo",
              endReInspectionSuccess: "Attività di qualità terminata",
              closeWindow: "Chiudere la finestra",
              endReInspectionFailed:
                "Attività di nuova qualità non riuscita, riprova più tardi!",
            },
            detail: {
              name: "Dettagli di ispezione di qualità",
              reviewName: "Rivedi i dettagli",
              spotCheckName: "Dettagli del campionamento",
              audioText: "Testo registrato",
              callInfo: "Informazioni sulla chiamata",
              satisfactionEvaluation: "Valutazione della soddisfazione:",
              queueTime: "Tempo trascorso in coda:",
              ringingTime: "Tempo di squillo:",
              callTime: "Eventi di chiamata:",
              hangUpIdentity: "Chiama la festa di riagganciato:",
              location: "Casa:",
              source: "Fonte:",
              agent: "Servizio clienti:",
              deviceType: "Tipo di dispositivo:",
              callType: "Tipo di chiamata:",
              phoneNumber: "Numero relè:",
              task: "Compiti:",
              commentsTip: "Inserisci commenti sull'ispezione di qualità",
              lastPage: "Pagina precedente",
              nextPage: "Pagina successiva",
              score: "Punteggio di ispezione della qualità",
              review: "Revisione",
              file: "Archiviazione",
              changeList: "Cambia record",
              fullMarks: "Pieni di voti",
              most: "La maggior parte",
              saveReview: "Rivedi la presentazione",
              saveSpotCheck: "Ispezione e presentazione del campionamento",
              point: "Punto di ispezione della qualità",
              sampleAssignInspector: "Assegna ispettore di campionamento",
              reviewAssignInspector: "Assegna revisore",
              sampleStatus: "Stato di campionamento",
              read: "Leggi",
              user: "Utente",
              confirmer: "Persona confermata",
              voiceDownloadError:
                "Download vocale non riuscito, riprova più tardi!",
              smartTags: "Etichetta intelligente",
              interactiveTip:
                "Utilizzare il riconoscimento dell'interazione per rilevare l'utilizzo del servizio clienti FAQ",
              interactiveIsEmptyTip:
                "Nessun contenuto di riconoscimento interattivo rilevato",
              businessRecords: "Documenti aziendali",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Analisi della chat di gruppo di Enterprise WeChat",
            },
          },
        },
      },
      cards: {
        health: "Salute dell'ispezione di qualità",
        accuracy: "Tasso di approvazione della scoperta",
        sessionsTotal: "Sessioni totali",
        enable: "Abilita",
        disable: "Disattiva",
        none: "No",
        call: "Chiama",
        dialogue: "Dialogo",
        taobao: "E-commerce",
        wecom: "Sessione",
        ticket: "Ordine di lavoro",
      },
      component: {
        backup: "(Backup)",
        taskSaveAsSucceeded: "Attività salvata con successo",
        copyContent: "Copia contenuto",
        essentialInformation: "Informazioni di base",
        ruleConfiguration: "Configurazione delle regole",
        basicInformationQualityInspector:
          "Informazioni di base-Ispettore di qualità",
        qualityInspectionRating: "Valutazione dell'ispezione di qualità",
        roleConfiguration: "Configurazione ruolo",
        autoAddConfiguration: "Aggiungi automaticamente la configurazione",
        autoPushConfiguration: "Configurazione a spinta automatica",
        automaticSamplingConfiguration:
          "Configurazione automatica del campionamento",
        automaticAllocationConfiguration:
          "Configurazione automatica dell'allocazione",
        businessConfiguration: "Configurazione aziendale",
        caseBaseConfiguration: "Libreria del caso",
      },
      template: {
        cancel: "Annulla",
        complete: "Completa",
        sort: "Ordina",
        ruleApproval: "Approvazione della regola",
        enterpriseMicroConversationAnalysisTask:
          "Attività di analisi della micro-sessione aziendale",
        enterpriseAndMicroVoiceAnalysisTask:
          "Attività di analisi vocale micro aziendale",
        saveTaskAs: "Salva attività",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Dettagli della regola" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Modifica riuscita",
            automaticApproval: "Approvazione automatica",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Modifica riuscita",
            test: "Test",
            formal: "Formale",
            adopt: "Di",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Sei sicuro di rifiutare",
              pleaseEnterTheRejectionReason:
                "Inserisci un motivo per il rifiuto",
              reject: "Rifiutato",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Modifica riuscita",
              taskName: "Nome attività",
              dataSource: "Fonte dati",
              qualityInspectionType: "Tipo di ispezione di qualità",
              state: "Stato",
              updateTime: "Tempo di aggiornamento",
              updatedBy: "Aggiorna persona",
              approver: "Approvazione",
              reasonForRejection: "Motivo del rifiuto",
              operation: "Operazione",
              details: "Dettagli",
              adopt: "Di",
              whole: "Tutto",
              approvalStatus: "Stato di approvazione",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Panoramica",
      hi: "Ciao,",
      standardEdition: "Edizione Standard",
      call: "Saldo di ispezione della qualità vocale",
      text: "Periodo di validità dell'ispezione della qualità del testo",
      collectionTasks: "Compiti di ispezione di qualità raccolti",
      noCollection: "Nessuna raccolta di compiti di ispezione di qualità,",
      goToCollecte: "Vai alla raccolta",
      yesterdayOverView: "Statistiche aziendali di ieri",
      yesterdayAgent: "Elenco del servizio clienti di ieri",
      yesterdayInspWork:
        "Situazione di lavoro di ieri dell'ispettore di qualità",
      operationalStatistics: {
        title: "Statistiche aziendali",
        qualityTesting: "Ispezione di qualità",
        spotCheck: "Ispezione casuale",
        appeal: "Denuncia",
        chartTitles: {
          qualityTestingTrend:
            "Andamento del volume delle ispezioni di qualità",
          qualityTestingAnalysis: "Attività di ispezione della qualità",
          spotCheckTrend: "Tendenza del campionamento",
          spotCheckAnalysis: "Situazione delle attività di campionamento",
          appealTrend: "Tendenze di reclamo",
          appealAnalysis: "Lo status del mandato di appello",
        },
        titles: {
          qualityDataTend: "Distribuzione dei dati di ispezione di qualità",
          qualityCover: "Copertura dell'ispezione di qualità",
          qualityAverageTime:
            "Tempo di attesa per l'ispezione di qualità media",
          qualityCoverTend:
            "Tendenza della copertura dell'ispezione di qualità",
          handCover: "Maneggevolezza manuale",
          checkCover: "Copertura del campionamento",
          resAverageTime: "Tempo medio di elaborazione",
          handTend: "Andamento dell'elaborazione manuale",
          dataTend: "Distribuzione attività dati",
        },
        qualityAverage: "Punteggio medio di ispezione della qualità",
        scoreTrend: "Tendenza del punteggio di ispezione della qualità",
        qualityAverageTime: "Tempo di attesa per l'ispezione di qualità media",
        callCoverage: "Copertura dell'ispezione della qualità vocale",
        textCoverage: "Copertura dell'ispezione della qualità del testo",
        checkCount: "Volume dei dati di campionamento",
        siphonCount: "Estrarre il volume dei dati",
        reviewCount: "Volume dei dati di revisione",
        appealCount: "Volume dei dati di reclamo",
        checkRate: "Percentuale di ispezione casuale",
        reviewRate: "Percentuale di revisione del reclamo",
        PieTitle: {
          totalCount: "Numero totale di conversazioni",
          totalCall: "Numero di chiamate vocali",
          totalIm: "Numero di conversazioni di testo",
          inspectionCall: "Quantità di ispezione della qualità vocale",
          inspectionIm: "Quantità di ispezione della qualità del testo",
          checkCall: "Quantità di campionamento vocale",
          unCheckCall: "Voce non campionata",
          checkIm: "Quantità di campionamento del testo",
          unCheckIm: "Quantità di testo non campionata",
          reviewCall: "Volume di revisione vocale",
          reviewIm: "Quantità di revisione del testo",
          unReviewCall: "Quantità vocale non recensita",
          unReviewIm: "Importo del testo non riesaminato",
          unInspectionCall: "Quantità vocale non controllata",
          unInspectionIm: "Quantità di testo non controllata",
        },
        check_rate: "Copertura del campionamento",
        voice_check_rate: "Copertura di campionamento vocale",
        text_check_rate: "Copertura di campionamento del testo",
        sample_execute_time: "Tempo medio di elaborazione del campionamento",
        review_execute_time: "Tempo medio di elaborazione del reclamo",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Regole di ispezione di qualità Kanban",
          agent: "Elenco di posizionamento del servizio clienti",
        },
        tableTips: {
          conditions:
            "<Div> ordina il numero totale di conversazioni che violano e rispettano le regole di ispezione di qualità. <Br/>\nIspezione di qualità Articolo: nome del punto di ispezione di qualità <br />\nNumero totale di conversazioni illegali: numero totale di conversazioni illegali su questo articolo di controllo di qualità <br />\nPercentuale di violazioni: Percentuale di violazioni = numero totale di violazioni/numero totale di violazioni </div>",
          agent:
            "<Div> Ordina la valutazione media degli agenti <br />\nNome del servizio clienti: il nome del servizio clienti <br />\nGruppo del servizio clienti: il gruppo del servizio clienti in cui si trova il servizio clienti (le informazioni sull'organizzazione nelle informazioni del servizio clienti)<br />\nNumero totale di conversazioni con punteggio: numero totale di conversazioni di ispezione di qualità del servizio clienti <br />\nPunteggio medio di ispezione della qualità: il punteggio medio dell'ispezione di qualità dell'agente (punteggio totale/numero totale di conversazioni)</div>",
        },
        tips: {
          total:
            "Numero totale di passaggi/conversazioni inseriti nell'attività di ispezione della qualità",
          agentTotal:
            "Il numero totale di agenti di ispezione della qualità che sono entrati nel compito di ispezione della qualità",
          avg: "Punteggio medio del punteggio di ispezione di qualità",
          violation:
            "Somma il numero di volte in cui tutti gli elementi di ispezione della qualità del dialogo superato vengono colpiti e violati",
          compliance:
            "Somma il numero di volte in cui gli articoli di ispezione della qualità vengono colpiti nel processo di ispezione della qualità attraverso il dialogo",
          check: "Numero totale di campionamenti manuali",
          appeal:
            "Numero totale di reclami presentati nel numero totale di conversazioni",
          review:
            "Numero totale di revisioni inviate nel numero totale di conversazioni di comunicazione",
        },
        subTips: {
          violation:
            "Tasso di violazione: numero totale di violazioni/numero totale di articoli per l'ispezione di qualità * 100%",
          compliance:
            "Tasso di conformità: numero totale di tempi di conformità/numero totale di articoli per l'ispezione di qualità * 100%",
          check:
            "Copertura del campionamento: campionamento manuale totale/ispezione della qualità totale * 100%",
          appeal:
            "Tasso di ricorso: numero totale di ricorsi/numero totale di ispezioni di qualità * 100%",
          review:
            "Tasso di revisione: numero totale di recensioni/numero totale di ispezioni di qualità * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Impostazioni di sistema",
          systemName: "Nome del sistema",
          speakerRecognize: "Identificazione dell'altoparlante",
          massage: "Notifica messaggi",
          evaluate: "Valutazione cognitiva artificiale",
          evaluateTip:
            "Dopo l'apertura, selezionare un rapido controllo a campione nei dettagli dell'ispezione di qualità e richiedere la valutazione manuale",
          speakerRecognizeTip:
            "Per accedere a una registrazione delle chiamate di terze parti tramite API, è necessario identificare se il canale sinistro è un servizio clienti o un cliente.",
          speakerRecognizeTip2:
            "La registrazione a canale singolo utilizza il riconoscimento del testo e la configurazione del ruolo per definire il ruolo dell'agente.",
          enabledImSendStatusFilter:
            "Filtraggio dei messaggi di ispezione della qualità del testo",
          enabledImSendStatusFilterTips:
            "Il contenuto di rilevamento del discorso dell'operatore nell'ispezione di qualità non include i messaggi nello stato di [Ritirato], [Impossibile inviare] e [Messaggio offline non letto]",
          lang: "Impostazioni della lingua di sistema",
          zh: "Cinese semplificato",
          en: "English",
          tw: "Cinese tradizionale",
        },
        template: {
          largeModelSettings: "Impostazioni del modello di grandi dimensioni",
          indonesian: "Indonesiano",
          vietnamese: "Vietnamita",
          portuguese: "Portoghese",
          malay: "Malese",
          french: "Francese",
          spanish: "Spagnolo",
          japanese: "Giapponese",
          thai: "Tailandese",
          blockSystemSendingMessages: "Invia messaggio di sistema maschera",
          textDataDesensitization: "Desensibilizzazione dei dati di testo",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Quando l'interruttore è acceso, viene visualizzato l'elenco a discesa del campo di desensibilizzazione (è supportata la selezione multipla). I campi di desensibilizzazione includono il nome della persona, il numero di cellulare e l'indirizzo.",
          desensitizingField: "Campo desensibilizzato",
          name: "Nome della persona",
          cellphoneNumber: "Numero di cellulare",
          address: "Indirizzo",
          dataCleaning: "Pulizia dei dati",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Dopo l'impostazione, la conversazione sull'ispezione di qualità e i dati della sessione WeChat aziendale verranno ripuliti regolarmente e non potranno essere recuperati. Si prega di operare con attenzione.",
          newCleaningRules: "Nuova regola di pulizia",
          qualityInspectionRuleApproval:
            "Approvazione delle norme relative alle ispezioni di qualità",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Dopo l'apertura, la modifica della regola di ispezione di qualità deve essere approvata prima che abbia effetto.",
          maskOfflineMessageUnread: "Maschera messaggio offline non letto",
          privacyNumberProtection: "Protezione del numero di privacy",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Dopo l\'apertura, desensibilizzare il campo "telefono cliente" durante la chiamata e visualizzarlo come 1881 * * * * * * * * *',
          shieldingWithdrawn: "La maschera è stata ritirata",
          maskSendingFailed: "Mittente maschera non riuscita",
        },
        component: {
          conversation: "Chiama",
          dialogue: "Dialogo",
          weCom: "WeChat aziendale",
          day: "Giorno",
          week: "Settimana",
          month: "Mese",
          year: "Anno",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Ambito di applicazione della pulizia",
            dataCleaningTimeSetting:
              "Impostazioni del tempo di pulizia dei dati",
            afterCleanUpTheData: "Dopo aver pulito i dati",
            dataAfterComplaintCompletion: "Dati post-reclamo",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Esecuzione riuscita",
          saveSuccessful: "Salvataggio riuscito",
          speechRecognition: "Riconoscimento vocale",
          reExecuteErrorCorrection: "Rieseguire la correzione degli errori",
          confirmExecution: "Conferma esecuzione",
          voiceDetection: "Rilevamento vocale",
          noiseDetectionPowerLevel: "Rilevamento del rumore powerLevel ≤",
          pleaseEnterTheThreshold: "Inserisci una soglia",
          volumeDetectionPowerLevel: "Rilevamento del volume powerLevel ≥",
          reset: "Reset",
          preserve: "Salva",
        },
        coachTemplate: {
          french: "Francese",
          spanish: "Spagnolo",
          japanese: "Giapponese",
          thai: "Tailandese",
          loginPopupWindow: "Finestra pop-up di accesso",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Dopo l'apertura, verrà visualizzata una finestra pop-up quando si entra nel sistema di sparring intelligente.",
          title: "Titolo",
          text: "Testo",
          checkBoxContent: "Seleziona il contenuto della casella",
          informedConsentForm: "Modulo di consenso informato",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Per aiutarti a migliorare le tue capacità comunicative e vocali e l'applicazione delle conoscenze professionali, permettendoti di esercitarti sempre e ovunque e ricevere feedback in tempo reale, raccoglieremo le seguenti informazioni da te attraverso questa piattaforma:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Ho letto e compreso appieno questo Modulo di consenso informato e accetto solennemente di gestire le mie informazioni personali sensibili in conformità con gli scopi e le modalità indicati nel presente Modulo di consenso informato",
        },
      },
      systemIntegration: {
        title: "Impostazioni di sistema",
        ccIntegrationTips:
          "Documento di descrizione del call center Udesk di controllo di qualità intelligente",
        unauthorized: "Non autorizzato",
        authorized: "Autorizzato",
        udeskDomain: "Nome del dominio del sistema del servizio clienti",
        udeskToken: "Chiave di sistema agente",
        udeskAdminEmail:
          "Account amministratore di sistema del servizio clienti",
        password: "Password dell'account amministratore",
        applyAuth: "Autorizzazione",
        cancelAuth: "Annulla autorizzazione",
        reApplyAuth: "Riautorizzazione",
        keyTips:
          "Gestione in background del sistema del servizio clienti-token di autenticazione API in single sign-on",
      },
      customerField: {
        title: "Campo cliente",
        table: {
          propName: "Nome del campo",
          propDesc: "Descrizione del campo",
          valueType: "Tipo di campo",
          propType: "Metodo di creazione",
          createTime: "Tempo di creazione",
          canNotEdit: "I campi di sistema non supportano la modifica",
        },
        edit: "Modifica campo",
        new: "Nuovo campo",
      },
      wechatDocking: {
        title: "Micro attracco aziendale",
        formLabels: {
          companyId: "ID azienda",
          communicationKey: "Chiave di comunicazione",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL di richiamata evento",
          applicationName: "Nome della domanda",
          applicationId: "ID applicazione Web",
          applicationSecret: "Applicazione del segreto",
        },
        formTitle: {
          initialForm: "Impostazione iniziale",
          synchronizeForm: "Sincronizzazione della rubrica",
          settingForm: "Impostazioni dell'applicazione",
        },
        qrCodeUrlTip:
          "Si prega di chiedere all'amministratore di scansionare il codice bidimensionale sottostante per associare l'applicazione di sviluppo.",
      },
      taobao: {
        formLabels: {
          name: "Nome negozio",
          account: "Numero di conto",
          password: "Password",
          confirm: "Conferma password",
          authorization: "Autorizzazione",
          switchOn: "Apri",
          switchOff: "Chiudi",
        },
        tableLabels: {
          name: "Nome negozio",
          account: "Numero di conto",
          authorization: "Autorizzazione",
          updateUser: "Persona aggiornata di recente",
          updateTime: "Tempo ultimo aggiornamento",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Weifeng nome di dominio secondario",
          secretKeyTip:
            "La chiave segreta dell'inquilino viene ottenuta nel luogo di attracco del sistema di background del sistema Weifeng.",
        },
      },
      user: {
        index: {
          name: "Gestione dei dipendenti",
          existingUsers: "Ci sono già dipendenti.",
          deleteSuccess: "Eliminazione riuscita",
          deleteFailed: "Eliminazione non riuscita",
        },
        detail: {
          accountPrompt: "Inserisci una casella di posta",
          resetPassword: "Reimposta la password",
          passwordPrompt: "Password (6-14 combinazione di lettere e numeri)",
          originPasswordPrompt: "Inserisci la password iniziale",
          resumePasswordPrompt: "Inserisci nuovamente la password",
          passwordFormWrong:
            "Inserisci una combinazione di 6-14 lettere e numeri!",
          resumePasswordWrong: "Due password sono incoerenti!",
          suppertImageType: "Supporto jpg, gif, png, massimo 500Kb",
          namePrompt: "Inserisci un nome",
          nickNamePrompt: "Inserisci un nickname",
          telephonePrompt: "Inserisci il numero di telefono",
          roleSelectedPrompt: "Seleziona un ruolo",
          agentIdPrompt: "Inserisci il numero del lavoro",
          disabled: "Disattiva",
          enabled: "Abilita",
          reloadBowerInfo:
            "Le informazioni dell'utente cambiano, è necessario aggiornare il browser?",
          saveUserError:
            "Impossibile salvare le informazioni dell'utente, riprova più tardi",
          resetPasswordSuccess: "Ripristino della password riuscito",
          resetPasswordFailed:
            "Reimpostazione della password non riuscita, riprova più tardi",
        },
      },
      userGroups: {
        index: {
          name: "Gestione del gruppo dei dipendenti",
          existingUserGroups: "Gruppo di dipendenti esistenti",
        },
        detail: {},
      },
      role: {
        index: { name: "Gestione del ruolo", existingRoles: "Ruoli esistenti" },
        edit: {
          editRole: "Modifica ruolo",
          createRole: "Crea ruolo",
          basicInfo: "Informazioni di base",
          taskPermissions: "Autorizzazioni attività",
          existingTasks: "Già compiti",
        },
      },
      anomalousData: {
        title: "Dati anomali",
        list: {
          selectHolder: "Seleziona un'origine dati",
          businessTime: "Orario aziendale",
          businessId: "ID azienda",
          export: "Esportazione",
        },
      },
      templateComponent: {
        list: {
          title: "Mercato dei modelli",
          name: "Nome modello",
          searchHolder: "Inserisci il contenuto da interrogare",
          update: "Aggiornamento",
          sort: "Ordina",
          moduleType: "Tipo di modello",
          moduleSourceType: "Tipo applicabile",
          fetchNum: "Numero di volte acquisite",
          newest: "Aggiornato di recente",
          usedMost: "Ottieni il massimo",
          call: "Chiama",
          dialogue: "Dialogo",
          waiting: "Da rivedere",
          cancel: "Annulla pubblicazione",
          success: "Approvato e pubblicato",
          failed: "Rifiuto dell'audit",
          no: "Non aggiornabile",
          ok: "Può aggiornare",
          all: "Tutto",
          delete: "Eliminazione riuscita",
          downOk: "Rimosso con successo",
          withdraw: "Prelievo riuscito",
          updateOk: "Aggiornamento riuscito",
          get: "Ottieni",
          width: "Ritiro",
          getOk: "Successo dell'acquisizione",
          orgName: "Organizzazione",
          nowVersion: "Versione attuale",
          lastedVersion: "Ultima versione",
          appStatus: "Stato della domanda",
          actions: "Operazione",
          down: "Fuori dallo scaffale",
          updateTop: "Aggiornamento",
          check: "Visualizza",
          deleteWhen: "Confermare l'eliminazione?",
          yes: "Sì",
          noo: "No",
          deleteOk: "Elimina",
          permissible: "Permesso",
          noPermissible: "Non consentito",
          openUp: "Apri",
          openDown: "Non aperto",
          task: "Compito di ispezione di qualità",
          point: "Punto di ispezione della qualità",
          tag: "Etichetta semantica",
          muti: "Elementi multipli",
          inter: "Una domanda e una risposta",
          info: "Entità dell'informazione",
          concept: "Concetto parole",
          detail: "Dettagli del modulo",
          infomation: "Informazioni di base",
          detailConfig: "Configurazione dettagliata",
          twoEdit: "Modifica secondaria",
          mustUpdate: "Aggiorna ora",
          descition: "Descrizione del modello",
          descitionSize:
            "La descrizione del modello non può superare le 250 parole",
          stencilsDetail: "Dettagli del modello",
          updatedRecords: "Aggiorna record",
          version: "Numero di versione",
          updateDescription: "Aggiorna descrizione",
          myModule: "Il mio modello",
          addModule: "Nuovo modulo",
          publishVersion: "Versione di rilascio",
          earliestVersion: "La prima versione",
          edit: "Modifica",
          add: "Nuovo",
          configNo:
            "I dettagli di configurazione del modulo non possono essere vuoti",
          updateNo: "La descrizione dell'aggiornamento non può essere vuota",
          editSuccess: "Modifica riuscita",
          saveSuccess: "Salvataggio riuscito",
          newer: "Aggiornamento",
          sumbit: "Presentazione",
          cancelName: "Annulla",
          nameRequired: "Il nome del modulo è un campo richiesto",
          nameSize: "Il nome del modulo non può superare i 16 caratteri",
          publishStatus: "Stato di pubblicazione",
          submitUpdate: "Invia aggiornamento",
          updateVersion: "Aggiorna versione",
          updateExplain: "Istruzioni di aggiornamento",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon e-commerce",
        youtubeVideo: "Video di Youtube",
        amazon: {
          component: {
            queuing: "In coda",
            notStarted: "Non iniziato",
            inProgress: "In corso",
            success: "Successo",
            failed: "Impossibile",
            successfullyCreated: "Creato con successo",
            editedSuccessfully: "Modificato correttamente",
            operationSucceeded: "Operazione riuscita",
            deletedSuccessfully: "Eliminato correttamente",
            link: "Collegamento",
            grabState: "Afferra lo stato",
            updateTime: "Tempo di aggiornamento",
            grab: "Afferra",
            edit: "Modifica",
          },
          template: {
            amazonEcommerce: "Amazon e-commerce",
            thisProduct: "Questo prodotto",
            competition: "Concorrenza",
            new: "Nuovo",
            edit: "Modifica",
            link: "Collegamento",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Video di Youtube",
            thisProduct: "Questo prodotto",
            competition: "Concorrenza",
            new: "Nuovo",
            edit: "Modifica",
            link: "Collegamento",
          },
          component: {
            queuing: "In coda",
            notStarted: "Non iniziato",
            inProgress: "In corso",
            success: "Successo",
            failed: "Impossibile",
            successfullyCreated: "Creato con successo",
            editedSuccessfully: "Modificato correttamente",
            operationSucceeded: "Operazione riuscita",
            deletedSuccessfully: "Eliminato correttamente",
            youTubeVideoLink: "Link al video di YouTube",
            grabState: "Afferra lo stato",
            updateTime: "Tempo di aggiornamento",
            grab: "Afferra",
            edit: "Modifica",
          },
        },
      },
    },
    demo: {
      title: "Ispezione di qualità intelligente",
      template: "Seleziona modello di ispezione qualità",
      radio: "Seleziona modulo di registrazione",
      uploadAudio: "Carica registrazione",
      uploadAudioTip:
        "La registrazione caricata supporta solo i formati wav e mp3 e le dimensioni non superano 1G",
      uploadText: "Carica testo",
      uploadTextTip:
        "Il testo caricato supporta solo i formati srt e txt e la dimensione non supera i 10M. Dopo aver caricato il testo, ASR non verrà più utilizzato per analizzare il file di registrazione.",
      uploadSuccess: "File di registrazione caricato correttamente",
      uploadFailed: "Impossibile caricare il file di registrazione",
      startQualityInspection: "Avvia l'ispezione di qualità",
      qualityInspectionStarted: "Ispezione di qualità in corso",
      downloadText: "Download del testo",
      checking: "Progresso dell'ispezione di qualità",
      checkingTip:
        "Si prevede che ci vorranno alcuni minuti, si prega di essere pazienti",
      noCheckContent: "Ancora nessun risultato di ispezione di qualità",
      noTextDownload:
        "Nessun testo di ispezione di qualità può essere scaricato!",
      pleaseCheckParams:
        "Controlla se selezionare il modello, il tipo di voce e caricare il file di registrazione",
      inspectionFailed: "Ispezione di qualità non riuscita, riprova più tardi!",
    },
    wechatQa: {
      baseInfo: { title: "Informazioni di base" },
      conversationSetting: {
        title: "Impostazioni della sessione",
        segmentationStrategy: "Strategia di affettatura",
        rule: "Strategia",
        time: "Tempo di taglio",
        daily: "Ogni giorno",
        passScore: "Punteggio idoneo della sessione",
        ruleLogic: "Logica di esecuzione",
      },
      message: {
        title: "Eventi push",
        table: {
          index: "Numero di serie",
          name: "Nome",
          type: "Modalità Push",
          desc: "Descrizione",
          status: "Stato",
        },
        subTitle: "Elenco eventi",
      },
      checkPoint: {
        title: "Modello di analisi",
        groups: "Raggruppamento del modello",
        newClassification: "Nuovo gruppo",
        editClassification: "Modifica raggruppamento",
        benchmarkScore: "Punteggio di riferimento",
        pointCount: "Modello di analisi comune con classificazione attuale",
        openPointCount: "Il numero di abilitate è",
        closedPointCount: "La quantità disattivata è",
        scoreLowerLimit: "Punteggio minimo Limite inferiore",
        scoreUpperLimit: "Cap punteggio massimo",
        classificationName: "Nome gruppo",
        classificationNamePlaceHolder: "Inserisci il nome di un gruppo",
        classificationRemark: "Descrizione del gruppo",
        classificationRemarkPlaceHolder: "Inserisci una descrizione del gruppo",
      },
      chartRecord: {
        title: "Sessione WeChat Enterprise",
        table: {
          id: "ID sessione",
          type: "Tipo di sessione",
          startTime: "Ora di inizio della sessione",
          endTime: "Sessione Ultima Fine",
          groupId: "ID gruppo",
          client: "Cliente",
          customer: "Servizio clienti",
          averageScore: "Punteggio medio di ispezione della qualità",
        },
        detail: {
          title: "Dettagli della sessione di Enterprise WeChat",
          inspectionInfo: "Informazioni sull'analisi",
          chartInfo: "Informazioni sulla sessione",
          tasks: "Attività di analisi",
          avgScore: "Punteggio medio dell'analisi",
          qualified: "Qualificato",
          unqualified: "Non qualificato",
          taskTitle: "Modello di analisi applicabile",
          info: "Analisi delle informazioni in tempo reale",
          today: "Le notizie di oggi",
          history: "Messaggi storici",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Inserisci un commento/nickname",
                pleaseEnterTheGroupChatName:
                  "Inserisci il nome di una chat di gruppo",
              },
            },
          },
          template: { whole: "Tutto" },
        },
      },
      realTimeAnalysis: {
        title: "Analisi in tempo reale",
        table: {
          name: "Nome della scena",
          count: "Numero di modelli di analisi",
          baseScore: "Punteggio di riferimento",
          passScore: "Punteggio qualificato",
          maxScore: "Cap punteggio massimo",
          minScore: "Punteggio minimo Limite inferiore",
          status: "Stato",
          actions: "Operazione",
        },
        detail: {
          title: "Analisi degli scenari in tempo reale",
          form: {
            name: "Nome della scena",
            prevCondition: "Condizioni di attivazione dello scenario",
            prevConditionToolTip:
              "Se la condizione è vuota, lo scenario viene analizzato per impostazione predefinita",
            prevConditionError:
              "Le condizioni di attivazione dello scenario non possono coincidere con il modello di analisi",
            scoreInputPlaceholder: "Inserisci il punteggio",
            baseScore: "Punteggio di riferimento",
            status: "Stato",
            passScore: "Punteggio qualificato",
            maxScore: "Punteggio massimo limite superiore",
            minScore: "Limite inferiore del punteggio minimo",
            analysisModel: "Modello di analisi",
            message: "Notifica trigger",
            pushType: "Modalità Push",
            createAnalysisModel: "Aggiungi modello",
            scoreUpperLimitError:
              "Il limite inferiore del punteggio minimo non può essere maggiore del limite superiore del punteggio massimo.",
            table: {
              name: "Nome del modello",
              rule: "Regole",
              score: "Punteggio",
            },
            pushConfigSwitchTip1: "Quando appare la scena",
            pushConfigSwitchTip2: "In tempi e oltre, le notifiche push",
          },
        },
      },
      smartCustomerTag: {
        title: "Etichettatura intelligente del cliente",
        table: {
          name: "Nome della regola",
          tags: "Etichetta micro cliente aziendale",
          status: "Stato abilitato",
          createUser: "Creatore",
          createTime: "Tempo di creazione",
        },
        detail: {
          name: "Nome della regola",
          condition: "Imposta regole",
          logic: "Logica",
          status: "Stato abilitato",
          tags: "Seleziona etichetta",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: {
            numberOfCustomerTags: "Quantità contrassegnato dal cliente",
          },
        },
        messageFiltering: {
          template: {
            edit: "Modifica",
            messageContent: "Contenuto del messaggio",
            messageTypeID: "ID del tipo di messaggio",
            messageTypeDescription: "Descrizione del tipo di messaggio",
            messageFiltering: "Filtraggio messaggi",
            suitableForFilteringDataWithoutQualityInspection:
              "Adatto per il filtraggio dei dati senza ispezione di qualità",
            create: "Crea",
            messageDescription: "Descrizione del messaggio",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Tempo di conversazione",
          sessionType: "Tipo di sessione",
          whole: "Tutto",
          staff: "Impiegato",
          customer: "Cliente",
          callKeywords: "Chiama le parole chiave",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Dettagli della chiamata vocale micro aziendale",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Archivio della sessione",
        voiceCall: "Chiamata vocale",
        noMoreVoiceCalls: "Niente più chiamate vocali",
        enterpriseWechatVoiceCall: "Chiamata vocale Enterprise WeChat",
        enterpriseWechatConversationDetails:
          "Dettagli della sessione di Enterprise WeChat",
        previous: "Articolo precedente",
        next: "Prossimo articolo",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Attività di analisi della micro-sessione aziendale",
          enterpriseAndMicroVoiceAnalysisTask:
            "Attività di analisi vocale micro aziendale",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Analisi della chat di gruppo di Enterprise WeChat",
      },
      wechatQaSettingEntry: { messageFiltering: "Filtraggio messaggi" },
    },
    businessAnalysis: {
      keyword: "Nome rapporto",
      createUser: "Creatore",
      createTime: "Tempo di creazione",
      updateTime: "Tempo di aggiornamento",
    },
    semanticIntelligence: {
      applicationMessageType: "Tipo di messaggio dell'applicazione",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Si prega di perfezionare la configurazione",
              generatePromptLanguage: "Prompt generato",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Conformità",
              gradedEvaluation: "Valutazione del grado",
            },
          },
          training: {
            index: {
              pleaseEnter: "Inserisci",
              pleaseGeneratePrompt: "Si prega di generare prompt",
              pleaseSelectADialogue: "Seleziona un dialogo",
              training: "Formazione",
              dataType: "Tipo di dati:",
              voiceCall: "Chiamata vocale",
              textDialogue: "Dialogo di testo",
              generate: "Genera",
              qualityInspectionResults: "Risultati dell'ispezione di qualità:",
              hit: "Hit:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Nome del modello",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Inserisci il nome del modello, ad esempio, se l'agente non ha alcuna azione.",
            modelDescription: "Descrizione del modello",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Puoi descrivere in dettaglio ciò che devi testare, che è facile da capire per il modello. Ad esempio, se il servizio clienti non ha alcuna azione, ovvero il servizio clienti ha risposto alla domanda del cliente, ma la domanda del cliente non è stata trattata o il contenuto della risposta non ha nulla a che fare con la risposta del cliente.",
            validity: "Efficacia",
            effective: "Efficace",
            invalid: "Non valido",
            type: "Tipo",
            hintLanguage: "Prompt",
            editIntelligentModel: "Modifica modello intelligente",
            createANewIntelligentModel: "Nuovo modello intelligente",
          },
        },
        index: {
          modelName: "Nome del modello",
          modelDescription: "Descrizione del modello",
          type: "Tipo",
          whetherItMeetsTheRequirements: "Conformità",
          gradedEvaluation: "Valutazione del grado",
          validity: "Efficacia",
          intelligentModel: "Modello intelligente",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Applicabile all'ispezione soggettiva della qualità (ad esempio se l'atteggiamento del servizio di ispezione della qualità è entusiasta e se il servizio è paziente)",
        },
      },
      clusterAnalysis: "Analisi del cluster",
      speechMining: "Estrazione di parole",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "È possibile importare entità di informazioni in batch tramite l'interfaccia API. Dopo aver salvato il nome API, non può essere modificato.",
        },
        component: { nothing: "Nessuno" },
      },
      knowledgeBase: {
        index: {
          classification: "Classificazione",
          import: "Importazione",
          importKnowledgeBase: "Importa la Knowledge Base",
          moveKnowledgePointsInBatch: "Spostare batch punti conoscenza",
          moveTo: "Mossa:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Seleziona i punti di conoscenza da spostare in lotti",
        },
        detail: {
          index: {
            intrasentence: "All'interno della frase",
            afterProblem: "Dopo il problema",
          },
        },
      },
      semanticTags: { component: { nothing: "Nessuno" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Parole sensibili",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Parole sensibili: le parole sensibili possono essere configurate con parole di preoccupazione aziendale, utilizzate per la scoperta dell'opinione pubblica sensibile alle parole cloud",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Nuova parola sensibile",
            editSensitiveWords: "Modifica parole sensibili",
            sensitiveWords: "Parole sensibili",
          },
          template: {
            sensitiveWords: "Parole sensibili",
            sensitiveWordsCannotBeEmpty:
              "La parola sensibile non può essere vuota",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Inserisci una parola sensibile, più parole sensibili nel mezzo ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Seleziona uno scenario di applicazione",
            keyWord: "Parole chiave",
            pleaseEnterKeywords: "Inserisci una parola chiave",
            keywordLengthWithinCharacters:
              "Lunghezza delle parole chiave entro 40 caratteri",
            dataSources: "Fonte dati",
            pleaseSelectADataSource: "Seleziona la fonte dati",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "L'attuale clustering è stato completato. La nuova modifica genererà un nuovo pezzo di dati di clustering e non sovrascriverà i dati di clustering completati. Conferma",
            savingSucceeded: "Salvataggio riuscito",
            clusteringStart: "Inizia il clustering",
            clustering: "Clustering",
            edit: "Modifica",
            newlyBuild: "Nuovo",
            clusterName: "Nome cluster",
            pleaseEnterAName: "Inserisci un nome",
            withinCharactersOfTheName: "Lunghezza del nome entro 40 caratteri",
            speaker: "Altoparlante",
            pleaseSelectASpeaker: "Seleziona un altoparlante",
            sessionDate: "Data della sessione",
            pleaseEnterTheSessionDate: "Inserisci una data di sessione",
            selectUpToMonthOfHistoricalSessionData:
              "Seleziona un massimo di 1 mese di dati storici della sessione",
            automaticallyClusterDailyNewData:
              "Clustering automatico di nuovi dati giornalieri",
            conversationChannel: "Canale di conversazione",
            pleaseSelectAConversationChannel: "Seleziona un canale di sessione",
            applicationScenario: "Scenari di applicazione",
            clusteringRules: "Regole di clustering",
            incompleteClusteringRuleSettings:
              "L'impostazione della regola di clustering è incompleta",
            startClustering: "Avvia il clustering",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Seleziona un'attività di qualità",
              beEqualTo: "Uguale",
            },
          },
        },
        detail: {
          index: {
            theme: "Tema",
            numberOfCorpora: "Numero di corpus",
            corpusCoverage: "Copertura del corpus",
            clusterDetails: "Dettagli di clustering",
          },
          ranking: {
            hotWords: "Parole hot",
            numberOfCorpora: "Numero di corpus",
            speaker: "Altoparlante",
            sit: "Siediti.",
            passenger: "Ospite",
            hotSentence: "Frase calda",
            corpusDetails: "Dettagli del cadavere",
          },
        },
        index: {
          delete: "Elimina",
          deletionSucceeded: "Eliminazione riuscita",
          clusterName: "Nome cluster",
          speaker: "Altoparlante",
          creationTime: "Tempo di creazione",
          state: "Stato",
          operation: "Operazione",
          see: "Visualizza",
          edit: "Modifica",
          clusterAnalysis: "Analisi del cluster",
          newCluster: "Nuovo cluster",
          clusteringStatus: "Stato di clustering",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Inserisci parole chiave per cercare obiezioni",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Libreria vocale",
                recommendedScripts: "Parole consigliate",
                common: "Totale",
                strip: "Articolo",
                scriptFavorites: "Parole preferite",
              },
              conditionPostfix: {
                detectRoles: "Rileva il ruolo",
                detectionRange: "Intervallo di rilevazione",
                sentence: "Sentenza",
              },
              customerFilter: {
                scriptFiltering: "Filtraggio vocale",
                cancel: "Annulla",
              },
              index: {
                viewDetails: "Visualizza dettagli",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Sei sicuro di volerlo abbandonare? Non è possibile ripristinarlo dopo averlo abbandonato.",
                abandonment: "Scartare",
                deletionSucceeded: "Eliminazione riuscita",
                passenger: "Ospite",
                sit: "Siediti.",
                adopted: "Adottato",
                determine: "Determinare",
                cancel: "Annulla",
                adopt: "Adozione",
              },
              pageRightFrom: {
                savingSucceeded: "Salvataggio riuscito",
                pleaseEnterTheScriptContent:
                  "Inserisci il contenuto del discorso",
                pleaseSelectAFavoriteGroup:
                  "Seleziona un raggruppamento preferito",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Libreria vocale per la gestione delle obiezioni",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript:
                    "Si prega di scegliere di adottare il discorso",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Opposizione che tratta le parole preferite",
            },
            pageRightTable: {
              likeCancel: "Come annullato",
              likeSuccessfully: "Mi piace il successo.",
              adoptScripts: "Adotta un discorso",
              operation: "Operazione",
              viewConversationDetails:
                "Visualizza i dettagli della conversazione",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Libreria di conversazione sul punto di vendita del prodotto",
              },
              pageRightTable: {
                keyPointsOfScript: "Punti chiave del discorso",
              },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Opposizione che tratta le parole preferite",
              newGroup: "Nuovo gruppo",
              editGroup: "Modifica raggruppamento",
              addGroup: "Aggiungi raggruppamento",
              savingSucceeded: "Salvataggio riuscito",
              groupName: "Nome gruppo",
              pleaseEnterTheGroupName: "Inserisci il nome di un gruppo",
              groupNameWithinCharacters:
                "Lunghezza del nome del gruppo entro 20 caratteri",
              deletionSucceeded: "Eliminazione riuscita",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings:
                    "Impostazioni consigliate per il discorso",
                },
                rightForm: {
                  savingSucceeded: "Salvataggio riuscito",
                  intelligentRecommendation: "Raccomandazione intelligente",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Le eccellenti capacità vocali adottate verranno automaticamente visualizzate nella barra laterale delle sessioni con obiezioni simili e i dipendenti possono visualizzare direttamente le capacità vocali consigliate per l'apprendimento nella pagina dei dettagli del curriculum della sessione.",
                  objectionsIncludedByDefault:
                    "Obiezione inclusa per impostazione predefinita",
                  addRecommendationTriggerSemantics:
                    "Aggiungi semantica del trigger di raccomandazione",
                  remindEmployeesToView:
                    "Ricorda ai dipendenti di visualizzare",
                  shareWithMoreEmployees: "Condividi con più dipendenti.",
                  allHands: "Tutto il personale",
                },
                smartToolbar: {
                  selectSemanticTag: "Seleziona etichetta semantica",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Esportare tutti i record di dati nell'ambito del raggruppamento corrente?",
                newScript: "Nuovo discorso",
                editingScript: "Modifica discorso",
                savingSucceeded: "Salvataggio riuscito",
                objection: "Obiezione",
                pleaseEnterAScriptObjection:
                  "Inserisci un'obiezione del discorso",
                speechTechnique: "Parole",
                pleaseEnterTheScriptContent:
                  "Inserisci il contenuto del discorso",
                grouping: "Raggruppamento",
                pleaseSelectAFavoriteGroup:
                  "Seleziona un raggruppamento preferito",
                adoptScripts: "Adotta un discorso",
                passenger: "Ospite",
                sit: "Siediti.",
                numberOfViews: "Numero di visualizzazioni",
                useful: "Utile",
                operation: "Operazione",
                edit: "Modifica",
                delete: "Elimina",
                deletionSucceeded: "Eliminazione riuscita",
                scriptRecommendationSettings:
                  "Impostazioni consigliate per il discorso",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Libreria vocale",
          scriptMining: "Estrazione di parole",
          objectionHandlingScripts: "Manipolazione dell'obiezione",
          productSellingPointScripts: "Punti di vendita del prodotto",
        },
        sellingPoint: {
          index: { newCluster: "Nuovo cluster" },
          components: {
            callDetailModal: {
              sessionDetails: "Dettagli della sessione",
              cancel: "Annulla",
              determine: "Determinare",
              choice: "Seleziona",
            },
            pageCenterTable: { scriptOverview: "Panoramica del discorso" },
            pageLeftTable: {
              enterSearchKeywords: "Inserisci parole chiave di ricerca",
              keyWord: "Parole chiave",
            },
            pageRightTable: {
              index: {
                viewDetails: "Visualizza dettagli",
                scriptLibrary: "Libreria vocale",
                scriptRefinement: "Raffinazione del discorso",
                adoptScript: "Adotta un discorso",
              },
              tagsEdit: { keyPointsOfScript: "Punti chiave del discorso" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Libreria di conversazione sul punto di vendita del prodotto",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Punti di vendita inclusi per impostazione predefinita",
                  },
                },
                index: {
                  keyWord: "Parole chiave",
                  pleaseSelectAKeyword: "Seleziona una parola chiave",
                  keyPointsOfScript: "Punti chiave del discorso",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Inserisci i punti chiave del discorso",
                  viewDetails: "Visualizza dettagli",
                  pleaseChooseToAdoptScript:
                    "Si prega di scegliere di adottare il discorso",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Logica di corrispondenza",
            nLPMatching: "Partita PNL",
            strictlyMatchByWord: "Rigorosa corrispondenza con la parola",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Nome tipo messaggio",
          messageTypeID: "ID del tipo di messaggio",
          messageTypeDescription: "Descrizione del tipo di messaggio",
          applicationMessageType: "Tipo di messaggio dell'applicazione",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "È applicabile ad accedere ai dati da diversi canali per l'ispezione di qualità, che dovrebbero essere classificati in base all'identificazione del canale.",
        },
        detail: {
          index: {
            messageTypeName: "Nome tipo messaggio",
            pleaseEnterAMessageTypeName:
              "Inserisci un nome del tipo di messaggio",
            messageTypeDescription: "Descrizione del tipo di messaggio",
            describeYourMessageTypeInDetail:
              "Descrivi il tipo di messaggio in dettaglio",
            editMessageType: "Modifica tipo di messaggio",
            newMessageType: "Nuovo tipo di messaggio",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Seleziona un membro del team",
        existingFilter: "Ci sono già filtri.",
        pleaseSelect: "Seleziona",
        filterCondition: "Condizioni di filtrazione",
        areYouSureToDeleteTheCurrentFilter: "Eliminare il filtro corrente?",
        sessionDate: "Data della sessione",
        staff: "Impiegato",
        customer: "Cliente",
        pleaseEnterCustomerName: "Inserisci il nome del cliente",
        keyWord: "Parole chiave",
        whole: "Tutto",
        pleaseEnterSessionKeywords: "Inserisci le parole chiave della sessione",
        sessionDuration: "Durata della sessione",
        sessionLabel: "Scenari di sessione",
        pleaseSelectASessionLabel: "Seleziona uno scenario di sessione",
        commentTopic: "Argomento del commento",
        pleaseSelectACommentTopic: "Seleziona un argomento per i commenti",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Sei sicuro di voler sovrascrivere il filtro corrente?",
        overlayFilter: "Filtro di copertura",
        NewFilter: "Nuovo filtro",
        newFilter: "Nuovo filtro",
        filterName: "Nome filtro",
        pleaseEnter: "Inserisci",
        allCall: "Tutte le chiamate",
        percentage: "Percentuale",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "Puoi cercare più parole chiave. Usare le virgole (in inglese e cinese) per separare la sala operatoria e mettere in pausa per la sala operatoria. Ad esempio:",
          callRecordID: "ID registro chiamate",
          pleaseFillInTheCallRecordID: "Compila l'ID del record di chiamata",
          minute: "Minuti",
          second: "Secondi",
          whole: "Tutto",
          callRecording: "Registrazione delle chiamate",
          enterpriseWechat: "WeChat aziendale",
          conversationChannel: "Canale di conversazione",
          pleaseSelectASessionChannel: "Seleziona un canale di sessione",
          learningStatus: "Stato di apprendimento",
          pleaseSelectLearningStatus: "Seleziona lo stato di apprendimento",
          notLearned: "Non imparato",
          learned: "Stimato",
          Minutes: "Minuti",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "La condizione del filtro è vuota, il nuovo filtro non è supportato!",
          filterAddedSuccessfully: "Filtro aggiunto correttamente!",
          failedToAddFilter: "Impossibile aggiungere il filtro!",
          filterDeletedSuccessfully: "Filtro eliminato correttamente!",
          filterDeletionFailed: "Eliminazione filtro non riuscita!",
          filterOverwriteSucceeded: "Override filtro riuscito!",
          filterOverwriteFailed: "Sovralpo filtro non riuscito!",
          theme: "Tema",
          pleaseSelectATheme: "Seleziona un tema",
          keyEvents: "Eventi chiave",
          pleaseSelectAKeyEvent: "Seleziona un evento chiave",
        },
        filterItem: {
          startRetesting: "Avvia la nuova rilevazione",
          time: "Tempo",
          talkTime: "Durata della chiamata",
          keyEvents: "Eventi chiave",
          totalImplementationRateOfSupervisionItems:
            "Tasso di attuazione globale degli elementi di sorveglianza",
          sort: "Ordina:",
          seeMore: "Visualizza altro",
        },
        filterStatistics: {
          conversation: "Chiama",
          weChat: "WeChat",
          quantity: "Quantità",
        },
      },
      caseBase: {
        index: {
          copyTo: "Copia",
          pleaseSelectTheLocationToMove:
            "Si prega di selezionare una posizione per spostarsi!",
          folderCopiedSuccessfully: "Copia cartella riuscita!",
          cancelCollectionSuccessfully: "Annulla la raccolta riuscita!",
          collectionSucceeded: "Successo della collezione!",
          starCaseBase: "Libreria custodia a stella",
          move: "Sposta",
          rename: "Rinomina",
          delete: "Elimina",
          folderMovedSuccessfully: "Cartella spostata con successo!",
          fileMovedSuccessfully: "File spostato correttamente!",
          fileMoveFailed: "Spostare file non riuscito!",
          folderAddedSuccessfully: "Aggiungi cartella correttamente!",
          folderRenameSucceeded: "Cartella rinominata con successo!",
          fileRenameSucceeded: "File rinominato correttamente!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Dopo aver eliminato la cartella, non sarai in grado di recuperarla. Sei sicuro di voler eliminarlo?",
          deleteSucceeded: "Eliminazione riuscita!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Dopo aver eliminato il file, non sarai in grado di recuperarlo. Sei sicuro di voler eliminarlo?",
          share: "Totale",
          folders: "Cartella,",
          calls: "Una chiamata",
          newFolder: "Nuova cartella",
          modifyFolder: "Modifica cartella",
          title: "Titolo",
          pleaseEnter: "Inserisci",
          selectMoveLocation: "Seleziona Sposta posizione",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Promemoria di apprendimento",
          subscriptionDynamics: "Dinamica dell'abbonamento",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID:
              "La sessione a cui ti sei iscritto (ID",
            thereAreDynamicUpdates: "C' è un aggiornamento dinamico",
            newCommentsReplies: "Aggiungi commenti/risposte",
            strip: "Articolo",
            newHelp: "Aggiungi aiuto",
            goAndWatch: "Vai ai curiosi",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Rispondi ai miei commenti",
            replySucceeded: "Risposta riuscita!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Me nei commenti.",
            replySucceeded: "Risposta riuscita!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Chiama per te di condividere",
            shareCasesForYou: "Condividi il caso per te.",
          },
        },
        study: {
          index: {
            learned: "Stimato",
            ignored: "Ignorato",
            study: "Apprendimento",
            ignore: "Ignora",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Importazione riuscita",
          account: "Numero di conto",
          password: "Password",
          cover: "Copertina",
          skip: "Salta",
          import: "Importazione",
          importEmployees: "Import dipendente",
          importPrompt: "Suggerimenti per l'importazione",
          confirm: "Determinare",
          theDataContainsDuplicateAccountsOrNames:
            "In questa voce di dati esiste un account o un nome duplicato",
          allCoverage: "Copri tutto",
          skipAll: "Salta tutto",
          headPortrait: "Avatar",
          fullName: "Nome",
          nickname: "Soprannomino",
          excellentStaff: "Personale eccellente",
          yes: "Sì",
          no: "No",
          department: "Dipartimento",
          accountNumberemail: "Numero di conto (e-mail)",
          telephone: "Telefono",
          jobNumber: "Lavoro n.",
          role: "Ruolo",
          pleaseEnterANameOrPhoneSearch:
            "Inserisci un' e-mail, un nome o una ricerca telefonica",
          person: "Persone)",
          AddEmployee: "Aggiungi dipendente",
        },
        edit: {
          jobNo: "Lavoro n.",
          udeskCustomerServiceSystemIdentity:
            "Identità del sistema di assistenza clienti Udesk",
          enterpriseWechatIdentity: "Identità WeChat aziendale",
          inputID: "ID di input",
          newIdentityBinding: "Nuova associazione di identità",
          identityBinding: "Associazione di identità",
          avatarGreaterThan500kb: "Avatar più grande di 500Kb",
          uploadSuccessful: "Caricamento riuscito",
          upload: "Carica",
          supportJPGGIFAndPNGUpTo500kb: "Supporto jpg, gif, png, massimo 500Kb",
          yes: "Sì",
          no: "No",
          accountNumber: "Numero di conto",
          pleaseEnterTheAccountNumber: "Inserisci il numero di conto",
          initialPassword: "Password iniziale",
          pleaseInputAPassword: "Inserisci la password",
          confirmPassword: "Conferma password",
          thePasswordsEnteredTwiceAreDifferent:
            "La password inserita due volte non è la stessa!",
          headPortrait: "Avatar",
          fullName: "Nome",
          pleaseEnterYourName: "Inserisci un nome",
          nickname: "Soprannomino",
          department: "Dipartimento",
          excellentStaff: "Personale eccellente",
          sessionIdentity: "Identità della sessione",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Il record della sessione di tag appartiene al dipendente",
          telephone: "Telefono",
          jobNumber: "Lavoro n.",
          role: "Ruolo",
          pleaseSelectARole: "Seleziona un ruolo",
          preservation: "Salva",
          cancel: "Annulla",
          addedSuccessfully: "Aggiunto con successo!",
          modificationSucceeded: "Modificato con successo!",
          newEmployees: "Nuovo dipendente",
          editEmployee: "Modifica dipendente",
        },
        detail: {
          viewDetails: "Visualizza dettagli",
          accountNumber: "Numero di conto",
          headPortrait: "Avatar",
          fullName: "Nome",
          nickname: "Soprannomino",
          department: "Dipartimento",
          excellentStaff: "Personale eccellente",
          yes: "Sì",
          no: "No",
          sessionIdentity: "Identità della sessione",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Il record della sessione di tag appartiene al dipendente",
          telephone: "Telefono",
          jobNumber: "Lavoro n.",
          role: "Ruolo",
        },
      },
      roleManage: {
        list: {
          menu: "Menu",
          functionalCategory: "Categorie funzionali",
          functionalSubclass: "Funzione sottoclasse",
          deletedSuccessfully: "Eliminazione riuscita",
          NewRole: "Nuovo ruolo",
          role: "Ruolo",
          dataPermissions: "Permessi dati:",
          edit: "Modifica",
          areYouSureYouWantToDeleteTheRole: "OK per eliminare il ruolo",
          determine: "Determinare",
          cancel: "Annulla",
          delete: "Elimina",
          applicationPermission: "Applica autorizzazioni",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Puoi vedere solo i dati della tua sessione",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "È possibile visualizzare i dati della sessione del dipartimento e dei reparti subordinati",
          menu: "Menu",
          functionalCategory: "Categorie funzionali",
          functionalSubclass: "Funzione sottoclasse",
          successfullyAdded: "Nuovo successo",
          modifiedSuccessfully: "Modifica riuscita",
          edit: "Modifica",
          cancel: "Annulla",
          confirm: "Conferma",
          roleName: "Nome del ruolo",
          pleaseEnterTheRoleName: "Inserisci il nome di un ruolo",
          dataAuthority: "Autorizzazioni dati",
          youCanViewCompanyWideSessionData:
            "Può visualizzare i dati della sessione a livello aziendale",
          applicationPermission: "Applica autorizzazioni",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Processo di sessione",
          generateProcess: "Processo di generazione",
          addProcess: "Aggiungi processo",
          cancel: "Annulla",
          complete: "Completa",
          edit: "Modifica",
          theSettingWillBeSuccessfulLater:
            "Imposta per vedere il successo più tardi!",
          previous: "Articolo precedente",
          next: "Prossimo articolo",
          debuggingMode: "Modalità di debug",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Dopo l'attivazione del messaggio, se corrisponde alle regole degli eventi chiave, agli elementi di monitoraggio, ai tag cliente o ai punti conoscenza, vengono visualizzati il nome della regola a cui corrisponde il messaggio e il nome del punto conoscenza della knowledge base.",
          seeLater: "Guarda più tardi",
          determine: "Determinare",
          conversationalInsights: "Approfondimenti sulla sessione",
          customerInsight: "Approfondimenti sui clienti",
          knowledgePointRecommendation:
            "Raccomandazione del punto di conoscenza",
          comment: "Commenti",
          sessionQuality: "Qualità della sessione",
          setToListenLaterSuccessfully:
            "Impostato per ascoltare il successo più tardi!",
          listenLater: "Ascolta più tardi",
          seekCounselingSuccess: "Cerca il successo del coaching!",
          altogether: "Un totale",
          appearsAt: "Aspetto",
          commentsPublishedSuccessfully: "Commenti pubblicati con successo",
          callRecording: "Registrazione delle chiamate",
          addedSuccessfully: "Aggiunto correttamente",
          shareWithColleagues: "Condividi con i colleghi",
          shareWithCustomers: "Condividi con i clienti",
          resumptionDetails: "Dettagli della nuova offerta",
          addToCaseBase: "Aggiungi alla libreria dei casi",
          shareCases: "Condivisione dei casi",
          seekCounseling: "Cerca consulenza",
          sessionRecord: "Record della sessione",
          businessRecord: "Documenti aziendali",
          keywordSearch: "Ricerca parole chiave",
          whole: "Tutto",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Inserisci un commento, inserisci @ per avvisare gli altri, inserisci # per aggiungere un thread",
          subscriptionSession: "Iscriviti alla sessione",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Dopo l'abbonamento, quando ci sono nuovi aiuti, commenti e risposte, riceverai notifiche e promemoria.",
        },
      },
      coachTask: {
        index: {
          employeeName: "Nome dipendente",
          customerName: "Nome del cliente",
          source: "Fonte",
          viewSessionRecords: "Visualizza i record della sessione",
          coachingStatus: "Stato di coaching",
          helpTime: "Tempo di aiuto",
          counselingTime: "Tempo di consulenza",
          helpContent: "Aiutare i contenuti",
          coachingOperation: "Operazione di coaching",
          areYouSureYouWantToIgnoreIt: "Sei sicuro di volerlo ignorare?",
          determine: "Determinare",
          cancel: "Annulla",
          ignoreHelp: "Ignora l'aiuto",
          coaching: "Coaching di condotta",
          viewCoaching: "Visualizza Coaching",
          areYouSureToReturnToCounseling:
            "Sei sicuro di tornare alla consulenza?",
          returnToPendingCoaching: "Riprendi la consulenza",
          tutor: "Consigliere",
          helpSeekingStatus: "Stato di aiuto",
          recentCoachingTime: "Tempo di consulenza recente",
          operation: "Operazione",
          ignoreSuccess: "Ignora il successo!",
          recoverySucceeded: "Recupero riuscito!",
          successfulCoaching: "Successo del tutoraggio!",
          myMission: "La mia missione",
          selectCoachingStatus: "Seleziona stato coaching",
          whole: "Tutto",
          iAskedForHelp: "Ho chiesto aiuto",
          signOut: "Uscita",
          submit: "Presentazione",
          helpPerson: "Chiedi aiuto",
          customer: "Cliente",
          toBeCoached: "Da mentore",
          counselingSuggestions: "Consigli di consulenza",
          pleaseEnterCoachingSuggestions:
            "Inserisci suggerimenti per il coaching",
        },
      },
      coachTaskCenter: {
        title: "Centro di missione",
        list: {
          taskName: "Nome attività",
          lessonGroup: "Classificazione del corso",
          lessonName: "Corso",
          taskType: "Tipo di attività",
          taskStatus: "Stato attività",
          taskExecutionInfo: "Stato di attuazione",
          totalCount: "Numero di persone da eseguire",
          executeCount: "Numero di giustiziati",
          executeRate: "Tasso di attuazione",
          completeCount: "Numero di completate",
          completeRate: "Tasso di completamento",
          commitCount: "Numero di conseguimento",
          commitRate: "Tasso di conformità",
          excellentRate: "Tasso eccellente",
          taskTime: "Tempo di attività",
          option: "Operazione",
          edit: "Modifica",
          taskDetail: "Dati attività",
          delete: "Elimina",
          new: "Nuova pubblicazione",
          taskTypeAll: "Tutto",
          taskTypePractice: "Esercizio",
          taskTypeTest: "Esame",
          taskStatusNotStarted: "Non avviato",
          taskStatusRunning: "In corso",
          taskStatusEnd: "Chiuso",
          taskStatusInvalid: "Non in vigore",
          unlimitedTime: "Tempo illimitato",
          to: "A",
          deleteConfirm:
            "Dopo l'eliminazione, tutti i record di apprendimento nell'ambito di questa attività verranno cancellati. Sei sicuro di voler eliminarli?",
          deleteSuccess: "Attività eliminata con successo",
        },
        edit: {
          addTask: "Nuova attività di pubblicazione",
          editTask: "Modifica attività di pubblicazione",
          publishSuccess: "Pubblicato correttamente",
          publishFailed: "Pubblicazione non riuscita",
          updateSuccess: "Aggiornamento riuscito",
          updateFailed: "Aggiornamento non riuscito",
          cannotBeNull: "Non può essere vuoto",
          explain: "Descrizione",
          taskDescExtra: "(Questo verrà visualizzato all'ingresso {0})",
          taskTargetObj: "Pubblicazione di oggetti",
          isEnable: "Stato effettivo",
          dialogueMode: "Modalità di dialogo",
          voice: "Voce",
          text: "Testo",
          userPortrait: "Ritratto utente",
          difficultyLevel: "Difficoltà",
          limitDuration: "Limite di durata",
          tipLabel: "Suggerimenti",
          tipTooltip:
            "Consentire ai delegati di visualizzare il discorso di riferimento",
          limitTimes: "Numero di esami",
          time: "Tempo",
          gradingStandart: "Criteri di punteggio",
          gradingStandartDescAffix:
            "(Il punteggio totale per il processo di conversazione corrente è",
          gradingStandartDescSuffix:
            "Punti, abilità vocale {0} punti, umore {1} punti, velocità {2} punti)",
          completeStandart: "Requisiti per il completamento dell'attività",
          byTimes: "Per numero di volte",
          byScore: "Per punteggio",
          completeByTimes: "Numero di esercizi",
          times: "Tempi",
          score: "Punti",
          anyTimes: "Una volta qualsiasi",
          getScore: "Punteggio",
          offLimitDuration: "Durata illimitata",
          onLimitDuration: "Durata limitata",
          commitScore: "Standard, punteggio",
          excellentScore: "Standard eccellente, punteggio",
          offLimitTime: "Tempo illimitato",
          onLimitTime: "Tempo limitato",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Ciao," },
          callDynamic: {
            today: "Oggi",
            yesterday: "Ieri.",
            sessions: "Una sessione",
          },
          todoList: {
            strip: "Articolo",
            theSessionIsAddedTolistenLater:
              'La sessione è unita a "Ascolta dopo"',
            askYouForHelp: "Ti chiedo aiuto",
            iHopeToContactYouLater: "Aspettatevi di contattare più tardi",
            lookingForwardToSendingTheInformationLater:
              "Aspettatevi di inviare il materiale più tardi",
            dialogueRisk: "La conversazione ha punti di rischio",
            tutored: "Allenato",
            goToCounseling: "Vai alla consulenza",
            contacted: "Contatti",
            hasBeenSent: "Inviato",
            areYouSureYouWantToIgnoreIt: "Sei sicuro di volerlo ignorare?",
            determine: "Determinare",
            cancel: "Annulla",
            ignore: "Ignora",
            checked: "Visto",
          },
          callListItem: {
            strip: "Articolo",
            learned: "Stimato",
            keyEvents: "Eventi chiave",
            totalImplementationRateOfSupervisionItems:
              "Tasso di attuazione globale degli elementi di sorveglianza",
          },
        },
        index: {
          operationSucceeded: "Operazione riuscita!",
          sessionDynamics: "Dinamica della sessione",
          my: "Il mio",
          department: "Dipartimento",
          company: "Azienda",
          toDoList: "Fare da fare",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Volume effettivo della chiamata (unità)",
          bestPractices: "Migliori pratiche",
          averageDurationOfASingleCallminutes:
            "Durata media della singola chiamata (minuti)",
          totalCallVolumePCs: "Chiamate totali (unità)",
          totalTalkTimehours: "Durata totale della chiamata (ore)",
          speakingPercentagepercentage:
            "Percentuale di conversazione (percentuale)",
          maximumContinuousSpeakingTimeminutes:
            "Tempo massimo di parola continuo (minuti)",
          numberOfIndepthDiscussionsPCs:
            "Numero di discussioni approfondite (unità)",
          maximumCustomerTalkminutes: "Parlare al massimo del cliente (minuti)",
          questionFrequencyPCs: "Frequenza delle domande (unità)",
          averageValue: "Media",
          selectDate: "Seleziona data",
          personnel: "Personale",
          pleaseSelect: "Seleziona",
          callRange: "Intervallo di chiamata",
          conversationActivity: "Attività di sessione",
          conversationAction: "Sessione di azione",
          keyEvents: "Eventi chiave",
          callRecord: "Registrazione delle chiamate",
          filter: "Filtro:",
          conversation: "Chiama",
          minute: "Minuti",
          sale: "Impiegato",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Gennaio",
            numberOfCalls: "Numero di chiamate",
            accountInformation: "Informazioni sull'account",
            month: "Mese",
            today: "Oggi",
            customerDetails: "Dettagli cliente",
            searchKeywordsInCommunicationContent:
              "Cerca parole chiave nel contenuto della comunicazione",
            allMembers: "Tutti i membri",
            reset: "Reset",
            query: "Interrogazione",
            allTrip: "Spettacolo di viaggi completi",
            contactOverview: "Panoramica dei contatti",
            lastContact: "Ultimo contatto",
            nextContact: "Prossimo contatto",
            previous: "Articolo precedente",
            next: "Prossimo articolo",
            viewDetails: "Visualizza dettagli",
            contactDynamics: "Dinamica del contatto",
            lastYear: "L'anno scorso",
            lastCall: "Ultima chiamata",
            hotTopicsForDiscussion: "Argomento di discussione caldo",
            totalInTheLastYear: "Nell'ultimo anno in tutto.",
            callTimes: "Chiamata secondaria",
          },
        },
        list: {
          index: {
            risk: "Rischio",
            have: "Ci sono",
            nothing: "Nessuno",
            channel: "Canale",
            callRecording: "Registrazione delle chiamate",
            enterpriseWeChatConversation: "Micro-sessione aziendale",
            enterpriseWeChatCall: "Micro-Talk aziendale",
            followUpSuggestions: "Raccomandazioni di follow-up",
            customer: "Centro clienti",
            followUp: "Follow-up",
            personInCharge: "Persona in carica",
            recentFollowup: "Seguita di recente.",
            lastContactTime: "Tempo ultimo contatto",
            operation: "Operazione",
            see: "Visualizza",
            exceed: "Altro",
            noActivityForMoreThanDays: "Giorni senza attività",
            lately: "Di recente",
            risksOccurredInTheLastDays:
              "La semantica del rischio si verifica in pochi giorni",
            savedSuccessfully: "Salvataggio riuscito",
            today: "Oggi",
            allDepartments: "Tutti i dipartimenti",
            allMembers: "Tutti i membri",
            customerName: "Nome del cliente",
            reset: "Reset",
            query: "Interrogazione",
            customers: "Cliente",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Mostra il contatto tra dipendenti e clienti, in cui le bolle rosa sono messaggi dei clienti e le bolle blu sono messaggi dei dipendenti. Maggiore è la proporzione di discorsi, maggiori sono le bolle.",
            riskAllocation: "Configurazione del rischio",
            preservation: "Salva",
            pleaseEnter: "Inserisci",
            noActivityForDays: "Giorni senza attività",
            riskSemanticsWithinDays:
              "La semantica del rischio si verifica in pochi giorni",
            addRiskSemantics: "Aggiungi semantica del rischio",
            semanticTags: "Etichette semantiche:",
            standardSentence: "Frase standard:",
          },
          components: {
            advancedFilter: {
              components: {
                list: {
                  index: { addNewConditions: "Aggiungi nuova condizione" },
                },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Soddisfare tutte le seguenti condizioni",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Filtro",
                pleaseSelectFilteringCriteria: "Seleziona i criteri di filtro",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Cambia la fase delle opportunità",
              theCurrentStageIs: "Lo stadio attuale è 【",
              ConfirmToChangeItTo: "], Conferma per cambiarlo 【",
              IsIt: "]?",
              StageCoStay: "] Fase totale soggiorno",
              day: "Giorno",
              start: "Inizia",
              end: "Fine",
              orderInput: "Ordine perdente",
              restart: "Riavvia",
              pleaseConfigureTheSalesStageInformation:
                "Configurare le informazioni sulla fase di vendita",
              reasonForDocumentInput: "Motivo per perdere l'ordine:",
              completeOrderInput: "Doc perdente completo",
              pleaseSelectTheReason: "Seleziona un motivo",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "La direzione raccomanda:",
              },
              index: {
                sessionDetails: "Dettagli della sessione",
                customerDynamics: "Dinamica del cliente",
                riskPoint: "Punto di rischio",
                followUpSuggestions: "Raccomandazioni di follow-up",
              },
              sessionDetails: {
                common: "Totale",
                currentlyViewingThe: "Attualmente visualizza n.",
                sessions: "Una sessione",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "La fase attuale si è fermata",
              day: "Giorno",
            },
          },
          business: {
            index: { reEnableOpportunity: "Riabilitare l'opportunità" },
          },
          customerTag: { index: { label: "Etichetta" } },
          moreCustomer: {
            index: {
              essentialInformation: "Informazioni di base",
              cancel: "Annulla",
              confirm: "Conferma",
              customer: "Cliente",
              currentFollowupStage: "Attuale fase di follow-up",
              telephone: "Telefono",
              followUpDays: "Giorni di follow-up",
              day: "Giorno",
              remarks: "Osservazioni",
              channelPreference: "Preferenza del canale",
              hello: "Ciao.",
              customFields: "Campi personalizzati",
              opportunityAmount: "Importo opportunità",
              focus: "Preoccupazioni",
              customerInformation: "Informazioni sul cliente",
              viewMoreCustomerInformation:
                "Visualizza ulteriori informazioni sui clienti",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Eventi chiave:" },
                row1: {
                  customerDynamics: "Dinamica del cliente",
                  common: "Totale",
                  barDynamic: "Dinamico",
                },
                row2: {
                  riskPoint: "Punto di rischio",
                  common: "Totale",
                  risks: "Articolo Rischio",
                },
                row3: {
                  followUpSuggestions: "Raccomandazioni di follow-up",
                  intelligentRecommendation: "Raccomandazione intelligente",
                  strip: "Articolo",
                  managersSuggestATotalOf: "Il manager consiglia un totale",
                  recommendedByManagers: "Consigliato il responsabile",
                  creationTime: "Tempo di creazione",
                  administratorSuggestions: "Consulenza dell'amministratore",
                  suggestionsForNewManagers: "Consigli del nuovo manager",
                },
              },
            },
            index: { typeTime: "Tipo/Tempo" },
          },
          userInfo: {
            index: {
              personInCharge: "Persona in carica",
              recentFollower: "Seguita di recente.",
              startTime: "Prima volta di portata",
              followUpDays: "Giorni di follow-up",
              day: "Giorno",
              remarks: "Osservazioni",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Membri del team",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Il numero totale di sessioni che i membri hanno imparato da altri (de-duplicazione). Cercare i dettagli della sessione e fare clic sulla registrazione da riprodurre è considerato come imparare una volta.",
          totalNumberOfStudies: "Numero totale di apprendimento",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Numero totale di commenti e risposte avviati dai membri alle sessioni di altre persone (deduplicazione)",
          totalNumberOfInteractions: "Numero totale di interazioni",
          learnTheSumOfConversationCallDuration:
            "Somma della durata della chiamata della sessione appresa",
          lengthOfStudy: "Durata dell'apprendimento",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Il numero totale di richieste di aiuto da parte dei membri alle sessioni di altre persone (de-duplicazione)",
          totalNumberOfRequests: "Numero totale di richieste di aiuto",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Numero totale di membri che consigliano aiuto da altri (de-duplicazione)",
          totalCounseling: "Numero totale di tutorial",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Numero totale di sessioni di membri ad altri aggiunti alla libreria dei casi (de-duplicazione)",
          addCase: "Aggiungi custodia",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Numero totale di abbonamenti ai membri alle sessioni di altre persone (deduplicazione)",
          subscribe: "Abbonamento",
          totalNumberOfSessionsCommunicatedByMembers:
            "Numero totale di sessioni comunicate dai membri",
          totalSessions: "Sessioni totali",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Il numero totale di membri che rigiocano le loro sessioni (deduplicazione), cercano i dettagli della sessione e fanno clic sulla registrazione da riprodurre è considerato riprodotto una volta.",
          totalNumberOfDoubleDisk: "Numero totale di redischi",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Numero totale di redischi/numero totale di sessioni * 100%",
          proportionOfDoubleDisk: "Ripresa quota",
          summationOfConversationCallDurationOfDuplicateDisk:
            "La somma della durata della conversazione del doppio disco.",
          resumptionDuration: "Durata della ristrutturazione",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Numero totale di membri che hanno avviato l'assistenza per le proprie sessioni (de-duplicazione)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Numero totale di commenti (comprese le risposte) ricevuti da altri per la sessione del membro",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Numero totale di sessioni dei membri aggiunte da altri alla libreria dei casi (de-duplicazione)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Numero totale di sessioni membri sottoscritte da altri (deduplicazione)",
          askForHelpInTheLastMonth: "Il mese scorso per chiedere aiuto.",
          lastTimeForHelp: "L'ultima volta per chiedere aiuto",
          operation: "Operazione",
          viewAllSessionsInTheLastMonth:
            "Visualizza tutte le sessioni dell'ultimo mese",
          allDepartments: "Tutti i dipartimenti",
          reset: "Reset",
          query: "Interrogazione",
          altogether: "Un totale",
          secondSession: "Sessione secondaria",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Progetto di prova attuale:",
            supervisionItem: "Articoli per la supervisione",
            keyEvents: "Eventi chiave",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Controlla gli elementi di monitoraggio o gli eventi chiave che vuoi testare e assicurati che ci siano contenuti di conversazione nella conversazione simulata",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Analisi dell'esecuzione" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Processo 1",
              technologicalProcess: "Processo",
              newProcess: "Nuovo processo",
            },
          },
          index: {
            upload: "Carica",
            preservation: "Salva",
            newlyBuild: "Nuovo",
            cancel: "Annulla",
            name: "Nome",
            describe: "Descrizione",
            type: "Tipo",
            classification: "Classificazione",
            content: "Contenuto",
            enclosure: "Allegato",
            knowledgePointAssociation: "Associazione del punto di conoscenza",
            open: "Apri",
            close: "Chiudi",
            operator: "Operatore",
            logic: "Logica",
            see: "Visualizza",
            edit: "Modifica",
          },
        },
        index: {
          name: "Nome",
          describe: "Descrizione",
          createdBy: "Creatore",
          type: "Tipo",
          lastUpdated: "Tempo ultimo aggiornamento",
          numberOfReferences: "Numero di riferimenti",
          operation: "Operazione",
          see: "Visualizza",
          edit: "Modifica",
          delete: "Elimina",
          categoryDeletedSuccessfully: "Categoria eliminata con successo",
          classificationOfKnowledgePoints:
            "Classificazione dei punti di conoscenza",
          newlyBuild: "Nuovo",
          knowledgeBase: "Base di conoscenza",
          knowledgePoints: "Punti di conoscenza",
          technologicalProcess: "Processo",
          keyword: "Parola chiave",
          batchExport: "Esportazione in batch",
          batchDelete: "Elimina lotto",
          importProcess: "Processo di importazione",
          importKnowledgePoints: "Importa punti conoscenza",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Se il punto di conoscenza non esiste nella knowledge base, aggiungi direttamente il punto di conoscenza; se esiste, saltalo e non aggiornarlo.",
          editKnowledgePointClassification:
            "Modifica classificazione del punto di conoscenza",
          newKnowledgePointClassification:
            "Nuova classificazione dei punti di conoscenza",
          categoryEditingSucceeded: "Modifica categoria riuscita",
          newClassificationSucceeded: "Nuova categoria con successo",
          classificationName: "Nome categoria",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Supervisione della forza esecutiva" },
      },
      topicCallout: {
        index: {
          whole: "Tutto",
          date: "Data",
          dimensionStatus: "Stato callout",
          callRecordID: "ID registro chiamate",
          callRecordIDMustBeNumeric:
            "L'ID del registro chiamate deve essere un numero",
          strip: "Articolo",
          topicAnnotation: "Temi callout",
          annotated: "Etichettato",
          stripNotMarked: "Bar, senza etichetta",
          barInDimension: "Bar, in callout",
          customer: "Cliente",
          sale: "Vendite",
          common: "Totale",
          sessions: "Sessioni",
          currentTopic: "Argomento attuale:",
          staff: "Impiegato",
          topicOfConversation: "Argomento",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Operazione riuscita, callout resettato",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Dopo il ripristino, tutti i record di etichettatura di questa sessione verranno cancellati. Conferma",
          resetAll: "Ripristina tutto",
          pleaseSelectTheTopicToBeMarked:
            "Seleziona un argomento da etichettare",
          pleaseTickTheSentenceToBeMarked:
            "Si prega di controllare la frase da contrassegnare",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Operazione riuscita, etichetta ha fatto effetto",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "L'ambito di questa annotazione include messaggi da altri argomenti. Fai clic su OK per sovrascriverli.",
          confirmDimensions: "Conferma callout",
          talkTime: "Tempo di conversazione",
          callID: "ID chiamata",
        },
      },
      topicList: {
        index: {
          callID: "ID chiamata",
          latestMarkedTime: "Ultima ora della marcatura",
          topicOfConversation: "Argomento",
          numberOfSentences: "Numero di frasi",
          sentence: "Sentenza",
          operation: "Operazione",
          see: "Visualizza",
          delete: "Elimina",
          edit: "Modifica",
          newSubTopic: "Nuovo argomento secondario",
          move: "Sposta",
          whole: "Tutto",
          topicDeletedSuccessfully: "Argomento eliminato con successo",
          newlyBuild: "Nuovo",
          topicList: "Elenco degli argomenti",
          callIDMustBeNumeric: "L'ID chiamata deve essere un numero",
          leadInTopic: "Argomento di importazione",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Se il nome della categoria è duplicato, la categoria dell'argomento originale non verrà sovrascritta e l'elemento verrà saltato e non verrà importato.",
          mobileClassification: "Classificazione mobile",
          editTopic: "Modifica argomento",
          newTopic: "Nuovo argomento",
          topicName: "Nome argomento",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Modifica riuscita",
            createdSuccessfully: "Creato con successo",
            modificationFailed: "Modifica non riuscita",
            creationFailed: "Impossibile creare",
            monitoringName: "Nome del monitoraggio",
            upToCharacters: "Fino a 40 caratteri",
            pleaseEnterAName: "Inserisci un nome",
            monitoringIndicators: "Indice di monitoraggio",
            sessionActivity: "Attività di sessione",
            conversationAction: "Sessione di azione",
            keyEvents: "Eventi chiave",
            supervisionItems: "Articoli per la supervisione",
            monitoringMembers: "Membri di monitoraggio",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Gruppi che ricevono orientamento, formazione o interventi di gestione",
            groupA: "Gruppo A",
            pleaseSelectGroupAData: "Seleziona i dati del Gruppo A",
            thereAreDuplicateUsersInGroupB: "Utenti duplicati nel gruppo B",
            groupARemarks: "Osservazioni del gruppo A",
            controlGroupGroupComparedWithGroupA:
              "Gruppo di controllo, rispetto al gruppo A",
            groupB: "Gruppo B",
            pleaseSelectGroupBData: "Seleziona i dati del gruppo B",
            thereAreDuplicateUsersInGroupA:
              "Ci sono utenti duplicati nel gruppo A",
            groupBRemarks: "Osservazioni del gruppo B",
            monitoringPeriod: "Ciclo di monitoraggio",
            startNode: "Nodo di avvio",
            monitoringScope: "Intervallo di monitoraggio",
            pleaseSelectMonitoringConditions:
              "Seleziona le condizioni di monitoraggio",
            pleaseEnterANumber: "Inserisci un numero",
            pleaseSelectATimePeriod: "Seleziona un periodo di tempo",
            preservation: "Salva",
            cancel: "Annulla",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Eliminazione riuscita",
            notStarted: "Non avviato",
            haveInHand: "In corso",
            closed: "Chiuso",
            newGroupTest: "Nuovo test di gruppo",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Fase di vendita",
        communicationStrategy1: "Strategia di comunicazione",
        riskAllocation: "Configurazione del rischio",
        businessConfiguration: "Configurazione aziendale",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Manipolazione dell'obiezione",
                  productSellingPointScript: "Punti di vendita del prodotto",
                  caseGrouping: "Raggruppamento dei casi",
                  pleaseSelectExcellentCases:
                    "Si prega di selezionare un buon caso.",
                  pleaseSelectKnowledgePoints: "Seleziona i punti conoscenza",
                  associativeLinks: "Collegamenti associati",
                  jumpTo: "Salta",
                  jumpType: "Tipo di salto",
                  knowledgePoints: "Punti di conoscenza",
                  excellentCases: "Custodia eccellente",
                  excellentScript: "Discorso eccellente",
                  pleaseSelectASingleKnowledgePoint:
                    "Seleziona un singolo punto di conoscenza",
                },
              },
            },
            index: {
              name: "Nome",
              pushText: "Spingi testo",
              enableStatus: "Stato abilitato",
              triggerRules: "Regola di trigger",
              communicationStrategy: "Strategia di comunicazione",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping:
                "Gruppo di strategia di comunicazione",
              name: "Nome",
              pushText: "Spingi testo",
              effectiveness: "Efficacia",
              openSuccessfully: "Apri con successo",
              closeSuccessfully: "Chiuso con successo",
              operationFailed: "Operazione non riuscita",
              operation: "Operazione",
              edit: "Modifica",
              delete: "Elimina",
              newlyBuild: "Nuovo",
              communicationStrategy: "Strategia di comunicazione",
              editGroup: "Modifica raggruppamento",
              groupName: "Nome gruppo",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Tutte le sessioni",
                  recentDays: "Giorni recenti",
                  recentSessions: "Sessioni recenti",
                  scopeOfApplication: "Ambito di applicazione:",
                  day: "Giorno",
                  through: "Passa",
                },
              },
            },
            index: {
              describe: "Descrizione",
              applicableCustomers: "Applicabile ai clienti",
              enableStatus: "Stato abilitato",
              triggerRules: "Regola di trigger",
              riskRules: "Regole di rischio",
            },
          },
          list: {
            index: {
              riskAllocation: "Configurazione del rischio",
              keyWord: "Parole chiave",
              newRiskRule: "Nuova regola di rischio",
              riskRuleName: "Nome della regola di rischio",
              describe: "Descrizione",
              state: "Stato",
              openSuccessfully: "Apri con successo",
              closeSuccessfully: "Chiuso con successo",
              operationFailed: "Operazione non riuscita",
              operation: "Operazione",
              edit: "Modifica",
              delete: "Elimina",
              deletionSucceeded: "Eliminazione riuscita",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Ordine perdente" },
              endReasonList: {
                addEndReason: "Aggiungi motivo finale",
                enterEndReason: "Inserisci il motivo finale",
              },
              stageList: {
                keyEvents: "Eventi chiave",
                waysideData: "Dati di follow-the-road",
                includeAny: "Contiene qualsiasi",
                includeAll: "Contiene tutti",
                pleaseEnterTheStageName: "Inserisci il nome della fase",
                setStageRules: "Imposta le regole della fase",
                stageName: "Nome fase",
                operator: "Operatore",
                logic: "Logica",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Formato di input del campo di tipo orario: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Richiesta non riuscita, riprova più tardi",
              salesStage: "Fase di vendita",
              essentialInformation: "Informazioni di base",
              name: "Nome",
              applicableDepartments: "Dipartimento applicabile",
              applicableToOthers: "Applicabile ad altri",
              manualChangePhase: "Fase di cambio manuale",
              enableStatus: "Stato abilitato",
              describe: "Descrizione",
              opportunityStageSettings:
                "Impostazioni delle fasi di opportunità",
              stageSettings: "Impostazioni fase",
              aliasSettings: "Impostazioni Alias",
              closeOpportunity: "Fine opportunità",
              newSuccessfully: "Nuovo successo",
              newFailed: "Nuovo non riuscito",
              savingSucceeded: "Salvataggio riuscito",
              saveFailed: "Salvataggi non riusciti",
            },
          },
          list: {
            index: {
              salesStage: "Fase di vendita",
              keyWord: "Parole chiave",
              newSalesStage: "Nuova fase di vendita",
              salesStageName: "Nome della fase di vendita",
              describe: "Descrizione",
              state: "Stato",
              openSuccessfully: "Apri con successo",
              closeSuccessfully: "Chiuso con successo",
              operationFailed: "Operazione non riuscita",
              operation: "Operazione",
              edit: "Modifica",
              delete: "Elimina",
              deletionSucceeded: "Eliminazione riuscita",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Generazione di modelli di grandi dimensioni",
            conversationInsights: "Approfondimenti sulla sessione",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Generazione automatica" },
              },
              hooks: {
                conversationSummary: "Riepilogo della sessione",
                conversationProcess: "Processo di sessione",
                opportunityPoints: "Punto opportunità",
              },
              prompt: {
                index: {
                  edit: "Modifica",
                  restoreDefault: "Ripristino predefinito",
                  cancel: "Annulla",
                  confirm: "Conferma",
                },
              },
            },
            index: {
              saveSuccessful: "Salvataggio riuscito",
              hintLanguage: "Prompt",
              preserve: "Salva",
              cancel: "Annulla",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Generazione di modelli di grandi dimensioni",
        salesStage: "Fase di vendita",
        communicationStrategy: "Strategia di comunicazione",
        riskAllocation: "Configurazione del rischio",
        businessConfiguration: "Configurazione aziendale",
      },
      salesLearningCenter: {
        knowledgeBase: "Base di conoscenza",
        speechCorpus: "Libreria vocale",
        learningCenter: "Centro di apprendimento",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Informazioni sul cliente" },
          },
        },
        index: { otherInformation: "Altre informazioni" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Importazione" } } },
        hooks: {
          index: {
            all: "Tutto",
            intelligentModel: "Modello intelligente",
            smartRules: "Regole intelligenti",
            manualScoring: "Punteggio manuale",
            enable: "Abilita",
            notEnabled: "Non abilitato",
            voiceCall: "Chiamata vocale",
            textDialogue: "Dialogo di testo",
            ecommerceTextSession: "Sessione di testo e-commerce",
            workOrder: "Ordine di lavoro",
          },
        },
        index: {
          ruleName: "Nome della regola",
          ruleType: "Tipo di regola",
          pleaseSelect: "Seleziona",
          ruleStatus: "Stato della regola",
          dataSourceType: "Tipo di origine dati",
          voiceCall: "Chiamata vocale",
          textDialogue: "Dialogo di testo",
          ecommerceTextSession: "Sessione di testo e-commerce",
          workOrder: "Ordine di lavoro",
          status: "Stato",
          operation: "Operazione",
          edit: "Modifica",
          ruleTemplate: "Modello di regola",
        },
      },
      analysisReportTitle: "Rapporto di analisi",
      ruleTest: "Prova della regola",
      intelligentTags: {
        labelDisplayQuantity: "Quantità di visualizzazione dell'etichetta",
        unlimited: "Illimitato",
        ignoringTagLibraries: "Ignora Tag Library",
        automaticallyGenerateLabels: "Genera automaticamente le etichette",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "Dopo aver abilitato la sessione, i tag vengono generati automaticamente in base al modello di grandi dimensioni e i tag generati vengono classificati in Non classificati per impostazione predefinita.",
        defaultValidity: "Effettività predefinita",
        supervisionItem: "Articoli per la supervisione",
        customerLabel: "Etichetta del cliente",
        newSupervisionItemRule: "Nuova regola dell'articolo di supervisione",
        editSupervisionItemRule: "Modifica regole articolo supervisionato",
        newCustomerLabel: "Nuova etichetta del cliente",
        editCustomerLabel: "Modifica tag cliente",
        newSessionLabel: "Nuova etichetta di sessione",
        editSessionLabel: "Modifica etichetta di sessione",
        newKeyEvent: "Nuovo evento chiave",
        editKeyEvents: "Modifica evento chiave",
        detail: {
          template: { nameIsARequiredField: "Il nome è un campo obbligatorio" },
          formItemComponent: {
            largeModelExtensionLabel:
              "Grandi etichette di estensione del modello",
            sessionRecordID: "ID record di sessione",
            sessionTime: "Tempo di sessione",
            smartTags: "Etichetta intelligente",
            viewDetails: "Visualizza dettagli",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Dopo l'eliminazione, i dati di questo record di sessione verranno cancellati. Sei sicuro di voler eliminarlo?",
            tagData: "Dati tag",
            batchDeletion: "Elimina lotto",
          },
        },
        template: {
          advancedFiltering: "Filtraggio avanzato",
          dataType: "Tipo di dati",
          filterCriteria: "Condizione del filtro",
          edit: "Modifica",
          createANewSubcategory: "Nuova sottocategoria",
          delete: "Elimina",
          nameLengthCannotExceed: "La lunghezza del nome non può superare i 40",
        },
        component: {
          modelExpansion: "Espansione del modello",
          enable: "Abilita",
          notEnabled: "Non abilitato",
          standardScreening: "Filtro standard",
          advancedFiltering: "Filtraggio avanzato",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Parole chiave",
              sessionLabel: "Etichetta di sessione",
              keyEvents: "Eventi chiave",
              matchAny: "Abbina qualsiasi",
              matchAll: "Abbina tutto",
              beEqualTo: "Uguale",
              includeAll: "Contiene tutti",
              includeAny: "Contiene qualsiasi",
              advancedConditions: "Condizioni avanzate",
              smartTag: "Etichetta intelligente",
              smartTagI: "Etichetta intelligente",
              smartTagII: "Etichetta intelligente II",
            },
            template: {
              pleaseSelectSmartTag: "Seleziona un'etichetta intelligente",
              pleaseSelectASessionLabel: "Seleziona un'etichetta di sessione",
              pleaseSelectAKeyEvent: "Seleziona un evento chiave",
              pleaseSelectAdvancedCondition: "Seleziona criteri avanzati",
              newAdvancedFilter: "Nuovo filtro avanzato",
              moveUp: "Spostati",
              moveDown: "Spostati verso il basso",
              moveLeft: "Spostati a sinistra",
              shiftRight: "Muoviti a destra",
            },
          },
          component: {
            businessAnalysispng: "Business Analytics.png",
            savedSuccessfully: "Salvataggio riuscito",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Efficace in modo permanente",
            permanentlyInvalid: "Permanentemente non valido",
            customValidity: "Periodo di validità personalizzato",
            termOfValidity: "Periodo di validità",
            name: "Nome",
            explain: "Descrizione",
            state: "Stato",
            classification: "Classificazione",
            smartRecommendation: "Raccomandazione intelligente",
            preconditions: "Condizione preliminare",
            SetPreconditionsForThisSupervisionItem:
              "1. Imposta le condizioni preliminari per questo articolo di supervisione:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              "Quando le condizioni preliminari sono soddisfatte, viene considerato come \"l'elemento di supervisione da attuare\" e l'elemento di supervisione deve essere testato;",
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Se le pre-condizioni non sono soddisfatte, l'elemento di supervisione non verrà rilevato;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Non impostare le precondizioni, ovvero tutte le sessioni vengono rilevate per impostazione predefinita.",
            have: "Ci sono",
            nothing: "Nessuno",
            preconditionRule: "Regola precondizione",
            preconditionLogic: "Logica precondizione",
          },
          recommendation: {
            caseGrouping: "Raggruppamento dei casi",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Quando i dipendenti non si comportano correttamente, possono raccomandare in modo intelligente eccellenti capacità vocali e casi eccellenti.",
            smartRecommendation: "Raccomandazione intelligente",
            open: "Apri",
            close: "Chiudi",
            whenThisSupervisionItem: "Quando questo articolo di supervisione",
            implement: "Esecuzione",
            unexecuted: "Non giustiziato",
            when: "Quando:",
            recommendedKnowledgePoints: "Punti di conoscenza consigliati",
            caseRecommendation: "Raccomandazione del caso",
          },
        },
        index: {
          determine: "Determinare",
          sort: "Ordina",
          sortSucceeded: "Ordina correttamente",
          supervisionItemClassification:
            "Classificazione degli elementi di supervisione",
          pressEnterToCreateANew: "Premi Invio Nuovo",
          nameCannotBeEmpty: "Il nome non può essere vuoto",
          newlyBuild: "Nuovo",
          name: "Nome",
          explain: "Descrizione",
          validStatus: "Stato valido",
          permanentlyValid: "Efficace in modo permanente",
          permanentlyInvalid: "Permanentemente non valido",
          operation: "Operazione",
          edit: "Modifica",
          batchDelete: "Elimina lotto",
          newSupervisionItem: "Nuovo elemento di supervisione",
          keyWord: "Parole chiave",
          delete: "Elimina",
          deleteSucceeded: "Eliminazione riuscita",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "I dati non possono essere ripristinati dopo essere stati cancellati. Sei sicuro di volerlo eliminare?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Etichetta intelligente",
              beEqualTo: "Uguale",
              includeAll: "Contiene tutti",
              includeAny: "Contiene qualsiasi",
              matchAny: "Abbina qualsiasi",
              matchAll: "Abbina tutto",
              smartTagI: "Etichetta intelligente",
              smartTagII: "Etichetta intelligente II",
            },
            template: {
              pleaseSelectASmartTag: "Seleziona un'etichetta intelligente",
              pleaseSelectAdvancedConditions: "Seleziona criteri avanzati",
              newAdvancedFilter: "Nuovo filtro avanzato",
              rename: "Rinomina",
              moveUp: "Spostati",
              moveDown: "Spostati verso il basso",
              shiftLeft: "Spostati a sinistra",
              shiftRight: "Muoviti a destra",
            },
          },
        },
        template: {
          delete: "Elimina",
          labelFiltering: "Filtraggio dell'etichetta:",
          satisfyAny: "Incontra arbitrario",
          meetAll: "Incontra tutti",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Numero di conversazioni con etichette di classificazione secondaria ≥ classificazione primaria, una sessione può contenere più etichette",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logica per il calcolo del cambio di anello: (volume di dialogo nel periodo di tempo selezionato-volume di dialogo nel periodo di tempo immediatamente precedente)/volume di dialogo nel periodo di tempo precedente",
          proportionOfPrimaryClassification:
            "Percentuale di classificazione di primo livello",
          saveAs: "Salva",
          cover: "Copertina",
          preservation: "Salva",
          qualityInspectionTask: "Compiti di analisi:",
          query: "Interrogazione",
          changeFromMonthToMonth: "Cambio anello",
          tagRanking: "Classifica etichetta",
          proportion: "Situazione possibile",
        },
        component: {
          numberOfConversations: "Numero di conversazioni",
          operation: "Operazione",
          businessAnalysispng: "Business Analytics.png",
          savingSucceeded: "Salvataggio riuscito",
          deletionSucceeded: "Eliminazione riuscita",
          sequence: "Sequenza",
          firstLevelClassification: "Classificazione di livello 1",
          ringRatioChange: "Cambio anello",
          classClassification: "Classificazione delle classi",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Confermare l'eliminazione del documento e del suo contenuto estratto?",
            addKnowledgeBase: "Aggiungi Knowledge Base",
            uploadDocument: "Carica documento",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Supporto parola, pdf, ppt, excel, file in formato txt",
            taskName: "Nome attività",
            extractConversationDate: "Estratto Data di conversazione",
            pleaseSelectAConversationDate:
              "Seleziona una data di conversazione",
            dataSource: "Fonte dati",
            dataType: "Tipo di dati",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Esportazione riuscita",
              problem: "Domanda",
              answer: "Risposta",
              addToKnowledgeBase: "Aggiungi alla Knowledge Base",
              pleaseConfirmWhetherToDeleteIt:
                "Si prega di determinare se eliminare",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Aggiungi alla Knowledge Base",
              selectClassification: "Seleziona categoria",
              pleaseSelectAKnowledgeBaseClassification:
                "Seleziona la classificazione della base di conoscenza",
            },
          },
        },
        index: {
          intelligentExtraction: "Estrazione intelligente",
          documentExtraction: "Estrazione del documento",
          dialogueExtraction: "Estrazione del dialogo",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operazione riuscita",
          labelIgnored: "Tag ignorato",
          add: "Aggiungi",
          areYouSureToDeleteIt: "Sei sicuro di voler eliminare?",
          batchFilterLabels: "Etichetta filtro lotto",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Ispezione di qualità intelligente",
        savedSuccessfully: "Salvataggio riuscito",
        logoSettings: "Impostazioni logo",
        default: "Default",
        custom: "Personalizzato",
        title: "Titolo",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Si raccomanda che il nome del titolo visualizzato dal sistema sia entro 6 caratteri.",
        logoImageUpload: "Caricamento dell'immagine del logo",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Selezionare un'immagine di dimensioni non superiori a 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Per garantire l'effetto di navigazione del logo, si consiglia di caricare le immagini:",
        Size5050Pixels: "1. Dimensioni: 50*50 pixel;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Formato: immagine con sfondo trasparente, png o jpg,logo grafico utilizzando il bianco",
        browserLabelIcon: "Icona della scheda del browser",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Per garantire l'effetto della navigazione dei tag, si consiglia di caricare le immagini:",
        Size3232Pixels: "1. Dimensioni: 32*32 pixel;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Formato: immagine con sfondo trasparente, png o jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Ispezione di qualità in corso, si prega di attendere...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "Nel 2015, la proprietà immobiliare del mio paese ha raggiunto un momento critico di sopravvivenza. Un gran numero di case era incustodito e le persone non erano disposte ad acquistarle. Hangzhou una volta è scesa sotto gli 8000 e alcuni sviluppatori hanno distrutto il mercato per spedire. A quel tempo Shenzhen si stava ancora consolidando intorno a 2w-3w e anche le case nelle città di primo livello erano in pericolo.",
        xiaoAn: "Ann.",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storeload Storestore queste barriere di memoria sono chiamate barriere di memoria jsr, che sono a livello jvm. Ci sono due implementazioni nella parte inferiore del sistema operativo: una è quella di bloccare il bus e l'altra è la barriera di memoria primitiva, come sfance (write barrier) mfence (full barrier) ifence (leggi barriera). Il livello inferiore della macchina virtuale hotspot utilizza un'istruzione assembly: blocco addl, blocco di un'operazione vuota, che è il lock bus. Questo viene fatto perché alcuni sistemi operativi non supportano le primitive della barriera di memoria.",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Prova a marinare la carne più volte e soffriggi le verdure dopo aver aggiunto il vino. La carne è rancida e ha un odore aspro! D'ora in poi, niente più vino da cucina per la pancetta",
        xiaodong: "Xiaodong",
        xiaoxi: "Xiao Xi",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Prova a marinare la carne più volte e le verdure fritte dopo aver aggiunto il vino sono come carne viziata, con un odore aspro! D'ora in poi, niente più vino da cucina per la pancetta",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Gestione della revisione dell' e-commerce",
        socialMediaReviewManagement:
          "Gestione delle revisioni dei social media",
        customDataSource: "Origine dati personalizzata",
        qualityInspectionResults: "Risultati delle ispezioni di qualità",
        accountManagement: "Gestione account",
        employeeProfile: "Ritratto dell'impiegato",
        intelligentAnalysis: "Analisi intelligente",
        customKanban: "Kanban personalizzato",
        dataScreening: "Panoramica dei dati",
        learningRecord: "Record di apprendimento",
        dataAnalysis: "Analisi dei dati",
        scoringManagement: "Gestione del punteggio",
        businessConfiguration: "Configurazione aziendale",
        manualReview: "Revisione manuale",
        taskList: "Elenco attività",
        videoAnalysis: "Analisi video",
        overview: "Panoramica",
        personalSignage: "Kanban personale",
        learningCenter: "Centro di apprendimento",
        wrongQuestionSet: "Set di problemi errati",
        abilityImprovement: "Capacità di aggiornamento",
        close: "Chiudi",
        switchingSystemApplications: "Applicazioni del sistema di commutazione",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Sposta il mouse qui per richiamare il menu di commutazione dell'applicazione e fai clic sulla voce di menu per passare a diverse applicazioni.",
        teamAnalysis: "Analisi della squadra",
        semanticIntelligence: "Intelligenza semantica",
        permissionManagement: "Gestione delle autorizzazioni",
        dataConnection: "Docking dei dati",
        courseManagement: "Gestione del corso",
        labelManagement: "Gestione dell'etichetta",
        curriculum: "Centro del corso",
        courseConfiguration: "Configurazione del corso",
        task: "La mia missione",
        historicalLearningData: "Storia",
        taskCenter: "Gestione attività",
        knowledgeBase: "Base di conoscenza",
        ecommerceSessionManagement: "Gestione delle sessioni di e-commerce",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Commutazione dell'applicazione",
          descriptionOfIntelligentQualityInspectionSystem:
            "Descrizione del sistema di ispezione intelligente di qualità",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Filtro sessione e-commerce",
        allEcommerceSessions: "Tutte le sessioni di e-commerce",
        ecommerceSessionManagement: "Gestione delle sessioni di e-commerce",
      },
    },
    analysisEnable: {
      bestPractices: "Migliori pratiche",
      behaviorAnalysis: "Analisi del comportamento",
      teamAnalysis: "Analisi della squadra",
      executiveSupervision: "Supervisione della forza esecutiva",
      executiveForceAnalysis: "Analisi dell'esecuzione",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Valutazione:",
          startTime: "Ora di inizio",
          workOrderNo: "Numero dell'ordine di lavoro",
          customerServiceHasReadNotRead: "Agente letto/non letto",
          canYouAppeal: "Se fare appello",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Osservazioni micro aziendali",
          addFriendTime: "Aggiungi tempo amico",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Inizia la nuova ispezione",
          callID: "ID chiamata",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Nuove funzionalità! Click sul pulsante "Quick Sampling Check" passerà automaticamente alla pagina successiva dopo l\'invio con successo ~ ~ ~',
        },
      },
      template: {
        deleteRecord: "Elimina record",
        areYouSureToDeleteIt: "Sei sicuro di voler eliminare?",
        confirm: "Determinare",
        reIdentification: "Riidentificazione",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "La riidentificazione consumerà il tempo di trasferimento, si prega di operare con cautela.",
        determine: "Determinare",
        executionSuccessful: "Esecuzione riuscita",
      },
      component: {
        pleaseSelectData: "Seleziona dati",
        operationSuccessful: "Operazione riuscita",
        startPerformingReQualityInspection: "Inizia la nuova ispezione",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Carica nome file",
        analysisTask: "Attività di analisi",
        analyzeProgress: "Analizza i progressi",
        analysisSucceeded: "Analisi del successo",
        analysisFailed: "Analisi non riuscita",
        localDataUpload: "Caricamento dati locali",
        recordingUpload: "Caricamento della registrazione audio",
        textUpload: "Caricamento del testo",
        uploadTime: "Tempo di caricamento",
        taskName: "Nome attività",
        operator: "Operatore",
        qualityInspectionProgress: "Progresso dell'ispezione di qualità",
        whole: "Tutto",
        haveInHand: "In corso",
        completed: "Completato",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Esportare tutti i record di dati completati in base ai criteri di filtro correnti?",
        upload: "Carica",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Inserisci il nome",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Inserisci il nome, ad esempio il tipo/la raccolta/lo scopo dei dati importati.",
            default: "Default",
            jDCOM: "Jingdong",
            dataType: "Tipo di dati",
            selectAnalysisTask: "Seleziona attività di analisi",
            uploadFiles: "Carica file",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Scarica il modello Excel e compilalo in base al formato:",
            template: "Modello",
            SupportUpTo10000PiecesOfData:
              "2. supporto massimo 10000 pezzi di dati",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Supporta il caricamento dei dati di dialogo esportato Jingdong, file txt, dimensione massima del file 100M",
            alongTheWayDataTemplate: "Modello di dati Follow-the-road",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Si prega di attendere pazientemente durante l'ispezione della qualità del testo.",
            textQualityInspectionCompleted:
              "Ispezione della qualità del testo completata",
          },
        },
        template: {
          startAnalysis: "Inizia l'analisi",
          pleaseUploadTheFile: "Carica il file",
          localDataUpload: "Caricamento dati locali",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            'Nuove funzionalità! Click sul pulsante "Recensione", dopo che l\'invio è riuscito, passerà automaticamente alla pagina successiva ~ ~ ~',
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Permesso per l'attività di ispezione di qualità",
            edit: "Modifica",
            iParticipated: "Sono coinvolto in",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Questo ruolo può vedere i dati sotto il dipartimento selezionato",
            dataPermissions: "Autorizzazioni dati",
            selectDepartment: "Seleziona reparto",
            edit: "Modifica",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Inizia la nuova ispezione",
          callID: "ID chiamata",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Nuove funzionalità! I pulsanti "sampling submit" e "sampling rapido" passeranno automaticamente alla pagina successiva dopo l\'invio con successo ~ ~ ~',
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Regole" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Gestione degli ordini di lavoro",
          workOrderDetails: "Dettagli ordine di lavoro",
        },
      },
      template: {
        workOrderFilter: "Filtro ordine di lavoro",
        allWorkOrders: "Tutti gli ordini di lavoro",
        workOrderManagement: "Gestione degli ordini di lavoro",
      },
    },
    trainingCenter: {
      topicAnnotation: "Temi callout",
      topicList: "Elenco degli argomenti",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Tipo di ruolo",
              keyWord: "Parole chiave",
              pleaseEnterKeywordSearch:
                "Inserisci la ricerca per parole chiave",
              query: "Interrogazione",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Questa funzione non è stata ancora aperta. Si prega di contattare l'amministratore di sistema, se necessario.",
          pleaseSelectAQuestion: "Seleziona una domanda!",
          recommendedSimilarSentencesLargeModel:
            "Frasi simili consigliate (modello di grandi dimensioni)",
          selectAll: "Seleziona tutto",
          add: "Aggiungi",
          changeBatch: "Cambia un lotto",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Orario di lavoro aziendale",
      localDataUpload: "Caricamento dati locali",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Assemblare l'elenco dei menu in base ai privilegi utente correnti",
      sessionSettings: "Impostazioni della sessione",
      logoSettings: "Impostazioni logo",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails: "Bentornato! Inserisci i tuoi dati",
        mailbox: "Cassetta postale",
        password: "Password",
        signIn: "Accesso",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "L'IP corrente non consente l'accesso",
          welcomeToLogin: "Benvenuto in Login",
          pursuingExcellenceAndLeadingChange:
            "La ricerca dell'eccellenza, guidando il cambiamento",
          intelligentPlatform: "Piattaforma intelligente",
        },
        virtualLogin: { index: { pleaseSelect: "Seleziona" } },
      },
      oauth: {
        illegalCall: "Chiamata illegale",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Bentornato! Fai clic sul pulsante qui sotto per passare alla piattaforma di autenticazione",
        loggingIn: "Login...",
        unifiedIdentityAuthenticationLogin:
          "Accesso all'autenticazione dell'identità unificata",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Test delle regole: alcune regole non possono essere testate utilizzando regole, come la velocità e il volume del parlato, i dati lungo il percorso, ecc.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Per saperne di più",
          enteringTheSystem: "Inserisci il sistema",
        },
      },
      index: {
        cancel: "Annulla",
        enteringTheSystem: "Inserisci il sistema",
        close: "Chiudi",
        enterSystemApplication: "Inserisci l'applicazione di sistema",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Fai clic su "Inserisci sistema" per passare a un\'app di sistema abilitata',
      },
    },
    permissionManagement: {
      employeeManagement: "Gestione dei dipendenti",
      roleManagement: "Gestione del ruolo",
      permissionManagement: "Gestione delle autorizzazioni",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Uguale",
            contain: "Contiene",
            doesNotContain: "Non contiene",
            applicationSystem: "Sistema di applicazione",
            intelligentQualityInspection:
              "Ispezione di qualità intelligente/analisi intelligente della sessione",
            salesEmpowerment: "Responsabilizzazione delle vendite",
            dataSourceType: "Tipo di origine dati",
            filterCriteria: "Condizione del filtro",
          },
        },
        index: {
          dataConnection: "Docking dei dati",
          savingSucceeded: "Salvataggio riuscito",
          dataIsolation: "Isolamento dei dati",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Dopo l'apertura, è possibile impostare l'intervallo di dati di ogni chiamata di accesso al sistema dell'applicazione e della sessione WeChat enterprise. Se l'interruttore non è acceso, tutti i sistemi sono collegati ai dati completi.",
          accessRules: "Regole di accesso",
          preservation: "Salva",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Nome della domanda",
            accessRights: "Accesso",
            inAppPermissionSettings: "Impostazioni di autorizzazione in-app",
            setUp: "Setup",
            applicationAccess: "Accesso all'applicazione",
            newRole: "Nuovo ruolo",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Dettagli della sessione" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Dettagli della sessione",
          caseDetails: "Dettagli del caso",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Dipendenti visibili",
            newCourse: "Nuovo corso",
            editCourse: "Modifica corso",
            courseName: "Nome del corso",
            courseIntroduction: "Introduzione del corso",
            courseType: "Tipo di corso",
            courseClassification: "Classificazione del corso",
            courseCover: "Copertina del corso",
            learningContent: "Contenuto di apprendimento",
            uploadLearningMaterials: "Carica materiali didattici",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Test",
              preservation: "Salva",
              cancel: "Annulla",
              editNodeName: "Modifica nome del nodo",
              determine: "Determinare",
              individual: "Uno",
              of: ", Totale",
              branch: "Punti",
            },
            configDrawerComponents: {
              pPTParsing: "Analisi PPT in...",
              uploadSuccessful: "Caricamento riuscito",
              uploadAttachments: "Carica allegato",
              pPTParsingPleaseWait: "Analisi PPT, attendi",
            },
            customerPortraitConfigDrawer: {
              cancel: "Annulla",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Non può essere ripristinato dopo l'eliminazione. Sei sicuro di voler eliminare questo ritratto?",
              delete: "Elimina",
              determine: "Determinare",
              customerPortrait: "Ritratto del cliente",
              bindSelectedCharacterPortrait:
                "Associare il ritratto del personaggio selezionato",
              customerProfileDetails: "Dettagli del profilo cliente",
              createANewCustomerProfile: "Nuovo profilo cliente",
            },
            flow: {
              sidebar: { node: "Nodo" },
              useControlButtons: {
                cancellingPleaseWait: "Annulla, per favore più tardi...",
                redoInProgressPleaseWait: "Rifai, per favore più tardi...",
                automaticOrganization: "Rifinitura automatica",
                canvasAdaptation: "Tela adattiva",
                delete: "Elimina",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Il nodo non può connettersi a se stesso",
              },
            },
            globalConfigDrawer: {
              cancel: "Annulla",
              determine: "Determinare",
              globalConfiguration: "Configurazione globale",
              sessionDetection: "Rilevamento della sessione",
              reciprocalRule: "Regole di interazione",
              robotVoice: "Suono robot",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Impostazioni di timeout del nodo dello studente senza risposta",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Tempo massimo di silenzio consentito",
              promptScriptForFailureToAnswerOverTime: "Prompt per il timeout",
              addScript: "Aggiungi parole",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (Natura)",
              zhishaChat: "Zhisha (chat)",
              zhiqiangentle: "Zhiqian (Gentile)",
              zhimiEmotion: "Conosci il riso (emozione)",
              aiShuoNatural: "Aishuo (Natura)",
              zhishuoChat: "Zhishuo (chat)",
              aiHaoAffinity: "Ai Hao (affinità)",
              zhixiangEmotion: "Zhixiang (Emozione)",
              robotVoice: "Suono robot",
              schoolboy: "Ragazzi",
              girlStudent: "Ragazze",
              volume: "Volume",
              tone: "Tono",
              speechSpeed: "Velocità del discorso",
              test: "Test",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Ciao, sono un allenatore di allenamento intelligente e non vedo l'ora di comunicare con te.",
            },
            sessionDetectionForm: {
              expressionDetection: "Rilevamento dell'espressione",
              excessiveDetectionOfModalParticles:
                "Rilevamento di parole modali eccessivo",
              theNumberOfRepetitionsIs: "Il numero di ripetizioni è",
              minus: "Tempo, meno",
              appear: "Appaiono",
              subtract: "Tempi, meno",
              branch: "Punti",
              addRules: "Aggiungi regola",
              includingNegativeAngryComplaining:
                "(Tra cui negativo, arrabbiato, lamentarsi)",
              scorePointsDisplay: "Visualizzazione punti punteggio",
              reminderOfSpeakingTooFasttooSlow:
                "Speedtoo veloce/promemoria troppo lento",
              scriptViolationReminder:
                "Promemoria della violazione del discorso",
              emotionalReminder: "Promemoria Emozionale",
              speechSpeedDetection: "Rilevamento della velocità del discorso",
              normalSpeechSpeedScore: "PUNTEGGIO NORMALE DI VELOCITÀ",
              speakTooFast: "Speedy troppo veloce",
              everyMinuteGreaterThan: "Al minuto maggiore",
              aWordAppears: "Parola, appaiono.",
              speakTooSlowly: "VELOCITÀ LENTA",
              lessThanPerMinute: "Meno di al minuto",
              ignoreLessThan: "Ignora di meno",
              aSentenceOfWords: "Frase delle parole",
              emotionalDetection: "Rilevamento delle emozioni",
              emotionalNormalScore: "Punteggio dell'umore normale",
              negativeEmotions: "Emozione negativa",
              illegalWordDetection: "Rilevamento illegale di parole",
              scriptViolationConfiguration:
                "Configurazione della violazione del discorso",
              realTimePromptOnTheFrontEnd: "Prompt front-end in tempo reale",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Domande e risposte della Knowledge Base",
                  qAMethod: "Metodo di domanda e risposta",
                  numberOfQuestionsAndAnswers: "Numero di domande e risposte",
                  scoringRules: "Regole di punteggio",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Qualsiasi domanda e risposta è corretta, hai",
                  branch: "Punti",
                  successfullySaved: "Salvataggio riuscito",
                },
                pptNode: {
                  verificationPassed: "Verifica passata",
                  upload: "Carica",
                  supportPptPdfFormatFiles:
                    "Supporta file in formato ppt e pdf",
                  uploadSlide: "Carica diapositive",
                  assessmentMethod: "Metodo di valutazione",
                  associateSlide: "Diapositive associate",
                  standardScript: "Discorso standard",
                  successfullySaved: "Salvataggio riuscito",
                },
                robotNode: {
                  robotScript: "Discorso robot",
                  randomScript: "Discorso casuale",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Scegli parole con semantica simile e il robot dirà casualmente una delle frasi",
                  customerPortrait: "Ritratto del cliente",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Quando il ritratto del cliente è coerente, il robot parla questo",
                  setAsDefaultScript: "Imposta come impostazione predefinita",
                  yes: "Sì",
                  no: "No",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Questo viene detto quando il robot manca qualsiasi tag utente.",
                  successfullySaved: "Salvataggio riuscito",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Questo nodo di ramo ha già un discorso predefinito. Vuoi sostituire il discorso predefinito con il discorso corrente?",
                },
                startNode: {
                  paragon: "Voce fuori campo",
                  successfullySaved: "Salvataggio riuscito",
                },
                userNode: {
                  verificationPassed: "Verifica passata",
                  standardScript: "Discorso standard",
                  successfullySaved: "Salvataggio riuscito",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Si prega di perfezionare la configurazione",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Inserisci una conversazione di esempio",
                  dialogueExample: "Esempio di conversazione",
                  generateConversationExamples:
                    "Generare un esempio di dialogo",
                  mattersNeedingAttention: "Precauzioni",
                  pleaseEnterThePromptLanguage: "Inserisci un prompt",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Scena di dialogo",
                  simulateCharacter: "Carattere simulato",
                  accompanyingTrainees: "Oggetto di accompagnamento",
                  selectFromTemplateLibrary: "Seleziona dalla libreria modelli",
                  templateLibrary: "Libreria modelli",
                  role: "Ruolo",
                  scene: "Scena",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Finestra di prova slide 【",
              clearContent: "Contenuto chiaro",
              close: "Chiudi",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "Processo di generazione AI",
                scriptStyle: "Stile di discorso",
                theMostConversationRounds:
                  "La maggior parte dei round di dialogo",
                copyConversation: "Copia dialogo",
                application: "Applicazione",
                regenerate: "Rigenerare",
                startGenerating: "Inizio generazione",
                pleaseGenerateAProcess: "Genera il processo",
                copySuccessful: "Copia riuscita",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Rappresentante di vendita",
                    customerServicePersonnel: "Personale del servizio clienti",
                    storeCounter: "Contatore negozio",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Generare competenze vocali full-text",
                speaker: "Altoparlante",
                scriptStyle: "Stile di discorso",
                copyConversation: "Copia dialogo",
                application: "Applicazione",
                regenerate: "Rigenerare",
                startGenerating: "Inizio generazione",
                pleaseGenerateAProcess: "Genera il processo",
                operationSuccessful: "Operazione riuscita",
                copySuccessful: "Copia riuscita",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Lucidare il discorso" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Le parole non possono essere vuote!",
              copySuccessful: "Copia riuscita",
              cancel: "Annulla",
              copy: "Copia",
              replaceTheOriginalText: "Sostituisci originale",
              aiGeneration: "Generazione Ai",
              aiPolishing: "Ai polacco",
              currentStyle: "Stile attuale",
              rigorous: "Rigoroso",
              mild: "Dolce",
              lively: "Vivace",
              simplicity: "Semplice",
              pleaseEnterTheSpeakerRole: "Inserisci un ruolo di oratore",
              startGenerating: "Inizio generazione",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Le parole non possono essere vuote!",
              copySuccessful: "Copia riuscita",
              aiRewrite: "Ai riscrivere",
              cancel: "Annulla",
              copy: "Copia",
              replaceTheOriginalText: "Sostituisci originale",
              currentStyle: "Stile attuale",
              rigorous: "Rigoroso",
              mild: "Dolce",
              lively: "Vivace",
              simplicity: "Semplice",
              pleaseEnterTheSpeakerRole: "Inserisci un ruolo di oratore",
              startGenerating: "Inizio generazione",
            },
          },
          index: {
            verificationPassed: "Verifica passata",
            ruleValidation: "Verifica della regola",
            aIGeneration: "Generazione AI",
            generateFullTextSpeechScript:
              "Generare competenze vocali full-text",
            editCourse: "Modifica corso",
            importConversation: "Conversazione di importazione",
            uploadExcelToCompleteDialogueImport:
              "Carica importazione completa della finestra di dialogo Excel",
            editBasicInformation: "Modifica le informazioni di base",
            test: "Test",
            release: "Pubblica",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Nuova etichetta" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Non è possibile recuperare dopo la cancellazione. Sei sicuro di voler eliminare questa etichetta?",
              delete: "Elimina",
              cancel: "Annulla",
            },
          },
        },
        index: {
          labelManagement: "Gestione dell'etichetta",
          newGroup: "Nuovo gruppo",
          edit: "Modifica",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Non è possibile recuperare dopo la cancellazione. Sei sicuro di voler eliminare questo gruppo di etichette?",
          delete: "Elimina",
          cancel: "Annulla",
          editGroup: "Modifica raggruppamento",
          labelGroupName: "Nome gruppo etichetta",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Fotocamera disabilitata",
              noCameraDevicesFound: "Dispositivo fotocamera non trovato",
            },
          },
          cue: {
            index: {
              regenerate: "Rigenerare",
              generate: "Genera",
              referenceScript: "Discorso di riferimento",
              mattersNeedingAttention: "Precauzioni",
              examFrequency: "Numero di esami",
              section: "Sezione",
              timestotal: "Tempi/Totale",
              second: "Tempi",
              requirementsForPassingTheExam:
                "Requisiti per il superamento dell'esame",
              examScore: "Punteggio del test ≥",
              branch: "Punti",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Nessun ritratto",
              customerBackground: "Sfondo del cliente",
              customerLabel: "Etichetta del cliente",
            },
          },
          flow: {
            index: {
              processReminder: "Prompt del processo (",
              score: "Punteggio",
              TotalScore: "/Punteggio totale",
            },
          },
          index: {
            taskName: "Nome attività",
            taskStatus: "Stato attività",
            whole: "Tutto",
            completionStatus: "Stato di completamento",
            courseClassification: "Classificazione del corso",
            practiceMode: "Modalità pratica",
            examMode: "Modalità di esame",
            practiceTime: "Tempo di esercizio",
          },
          ppt: {
            index: {
              nextPage: "Pagina successiva",
              tips: "Prompt",
              continueWithCurrentPage: "Continua Pagina attuale",
              goToTheNextPage: "Vai alla pagina successiva",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Dopo aver inserito la pagina successiva, non sarà possibile tornare alla pagina precedente. Si prega di confermare che la spiegazione della pagina corrente è completa prima di saltare.",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Clicca sulla pagina successiva non apparirà questo prompt",
            },
            list: { index: { slide: "Slide" } },
          },
          record: {
            index: {
              unableToRecord: "Impossibile registrare:",
              creatingARecorderService: "Crea servizio di registrazione",
              turnOffTheRecorderService: "Disattiva il servizio Registratore",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Il client corrente è già connesso al server ASR Websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "Dopo aver elaborato l'ultimo fotogramma, ripristinare lo stato",
              aSRConnectionClosed: "Connessione ASR chiusa...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "La registrazione è in pausa ed è stata elaborata, ma ci sono ancora dati nella cache",
              foundASRChannelClosedRecreateWebsocketLink:
                "Trovato canale ASR chiuso, ricreare il link Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Trovato che il canale ASR non è aperto, ricreare il collegamento Websocket",
            },
            ppt: { hook: { index: { serverError: "Errore del server" } } },
            text: {
              index: {
                pleaseEnter: "Inserisci",
                sendOut: "Invia",
                startPracticing: "Inizia a praticare",
                startTheExam: "Inizia l'esame",
              },
            },
            util: {
              recorded: "Registrato",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "Il 16 bit pcm SendFrameSize deve essere un multiplo intero di 2",
              pCMSamplingRateShouldNotOccur:
                "La frequenza di campionamento del pcm non deve apparire",
              andTheRequiredSamplingRate:
                "E la frequenza di campionamento richiesta",
              atypism: "Incoerente",
              errorsThatShouldNotOccur:
                "Errori che non dovrebbero verificarsi:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> I dati fuori uso possono essere scartati se i requisiti non sono elevati. </Span>',
              flower: "Fiori",
              TransferStopped: ": Trasferimento interrotto",
            },
            video: { index: { camera: "Fotocamera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Il client corrente è già connesso al server ASR Websocket",
                    aSRConnectionClosed: "Connessione ASR chiusa...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Trovato canale ASR chiuso, ricreare il link Websocket",
                    unableToRecord: "Impossibile registrare:",
                    aSRChannelClosedReopened: "Chiuso canale ASR, riaperto",
                    theASRChannelWasNotCreatedStartingNow:
                      "Canale ASR non creato, inizia a creare ora",
                    useEffectCallbackToClearTheASRChannel:
                      "Richiamata di useEffect per cancellare il canale ASR",
                    creatingARecorderService: "Crea servizio di registrazione",
                    turnOffTheRecorderService:
                      "Disattiva il servizio Registratore",
                  },
                  util: {
                    recorded: "Registrato",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "Il 16 bit pcm SendFrameSize deve essere un multiplo intero di 2",
                    pCMSamplingRateShouldNotOccur:
                      "La frequenza di campionamento del pcm non deve apparire",
                    andTheRequiredSamplingRate:
                      "E la frequenza di campionamento richiesta",
                    atypism: "Incoerente",
                    errorsThatShouldNotOccur:
                      "Errori che non dovrebbero verificarsi:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> I dati fuori uso possono essere scartati se i requisiti non sono elevati. </Span>',
                    flower: "Fiori",
                    TransferStopped: ": Trasferimento interrotto",
                  },
                },
                index: { failInSend: "Impossibile inviare" },
              },
              index: {
                startRecording: "Avvia la registrazione",
                endRecording: "Termina la registrazione",
                startPracticing: "Inizia a praticare",
                startTheExam: "Inizia l'esame",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Il numero attuale di esami rimanenti è",
              totalScriptScore: "Punteggio totale del discorso:",
              totalEmotionalScore: "Punteggio emotivo totale:",
              totalScoreOfExpression: "Punteggio totale di espressione:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "I risultati attuali del punteggio delle attività devono essere rivisti manualmente. Dopo aver completato la revisione, riceverai una notifica dei risultati finali. Per favore aspetta pazientemente.",
              areYouSureToSubmit: "Confermare la sottomissione?",
              theExerciseHasEndedAreYouSureToSubmit:
                "L'esercizio è terminato, sei sicuro di volerlo inviare?",
              theExamHasEndedAreYouSureToSubmit:
                "L'esame è terminato, sei sicuro di volerlo presentare?",
              completed: "Completato",
              page: "Pagina",
              pageCount: "Pagine totali",
              topic: "Argomento",
              totalNumberOfQuestions: "Numero totale di domande",
              totalScore: "Punteggio totale",
              branch: "Punti",
              accumulatedCompletionProgress:
                "Progresso cumulativo del completamento",
              accumulatedPracticeDuration: "Durata della pratica cumulativa",
              lastPauseExerciseResults: "Last Time-out-Practice Risultati",
              exerciseInstructions: "Istruzioni per l'esercizio",
              examInstructions: "Descrizione dell'esame",
              halfwayPausePracticeResults:
                "Pausa a metà strada-Risultati della pratica",
              tips: "Prompt",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Dopo aver smesso, questo esercizio non sarà incluso nel punteggio. Sei sicuro di voler smettere?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Smettere di fumare non è permesso nel mezzo dell'esame. Confermare la sottomissione?",
              pleaseOperateWithCaution:
                "Tempi, si prega di operare con attenzione)",
              restart: "Riavvia",
              returnToLearning: "Ritorno all'apprendimento",
              continuePracticing: "Continua a praticare",
              continuingTheExam: "Continua esame",
              return: "Reso",
              notSubmittedTemporarily: "Non inviare",
              iGotIt: "Lo so. Lo so.",
              confirmExit: "Conferma uscita",
              confirmSubmission: "Conferma invio",
              signOut: "Uscita",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Pausa",
              submit: "Presentazione",
              remainingTimeOfThisQuestion: "Tempo rimanente",
              SecondsLeftUntilTheEndTime: "10 secondi alla fine",
              remainingTime: "Tempo rimanente",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Contenuto vocale dell'utente non rilevato, riprova",
          },
          wave: {
            index: {
              continueRecording: "Continua la registrazione",
              submit: "Presentazione",
              endRecording: "Termina la registrazione",
              suspendRecording: "Metti in pausa la registrazione",
              startPracticing: "Inizia a praticare",
              startTheExam: "Inizia l'esame",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Modalità di dialogo",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Troppo rumore, si prega di passare a un ambiente tranquillo",
              normalNoiseDetection: "Il rilevamento del rumore è normale",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Il volume è troppo basso, si prega di aumentare il volume",
              volumeDetectionIsNormal: "Rilevamento del volume normale",
              noiseDetection: "Rilevamento del rumore",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Si prega di mantenere la calma e registrare 3s suono ambientale",
              endRecording: "Termina la registrazione",
              startRecording: "Avvia la registrazione",
              volumeDetection: "Rilevamento del volume",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Si prega di leggere "test del volume ora" in volume normale"',
              playLastDetectedAudio: "Riproduci l'ultimo audio di rilevamento",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Si prega di leggere attentamente i requisiti prima di iniziare.",
              return: "Reso",
              iGotIt: "Lo so. Lo so.",
              timeLimitForSingleQuestionAnswering:
                "Limite di tempo per la risposta a una singola domanda",
              totalTimeLimit: "Limite di tempo totale",
              unlimitedTime: "Tempo illimitato",
              stillNeedToCompleteTheTaskInTotal:
                "È ancora necessario completare l'attività.",
              practice: "Esercizio",
              second: "Tempi",
              taskScore: "Punteggio di una attività ≥",
              branch: "Punti",
              or: "O",
              examScore: "Punteggio del test ≥",
              remainingExamTimes: "Numero di esami rimanenti",
              totalScore: "Punteggio totale",
              Script: "(Talker",
              scoreEmotion: "Commozione",
              fractionExpression: "Sottoespressione",
              points: "Punti)",
              standardScore: "Punteggio standard",
              excellentScore: "Punteggio eccellente",
              exerciseInstructions: "Istruzioni per l'esercizio",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Per rispondere senza problemi alla domanda, si prega di completare prima il test dell'apparecchiatura:",
              camera: "Fotocamera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Essere in grado di vedere te stesso nel video significa che la fotocamera è normale.",
              microphone: "Microfono",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Si prega di parlare nel microfono, vedere il modello di forma d'onda significa che il microfono è normale",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Vuoto",
                  copyTo: "Copia",
                  classification: "Classificazione",
                  rename: "Rinomina",
                  newlyBuild: "Nuovo",
                  categoryParent: "Classificazione superiore",
                  moveTo: "Sposta",
                  name: "Nome",
                },
              },
              util: {
                rename: "Rinomina",
                moveTo: "Sposta",
                copyTo: "Copia",
                delete: "Elimina",
                courseClassification: "Classificazione del corso",
                newlyBuild: "Nuovo",
              },
            },
          },
          edit: { index: { curriculum: "Curriculum" } },
          index: {
            learn: "Apprendimento",
            practice: "Pratica",
            examination: "Test",
            curriculum: "Curriculum",
            courseIntroduction: "Introduzione del corso:",
            createdOn: "Tempo di creazione:",
            published: "Pubblicato",
            tasks: "Compiti",
            unpublishedTasks: "Attività non pubblicata",
            task: "Compiti",
            operation: "Operazione",
            learningMaterials: "Materiali di apprendimento",
            goPractice: "Vai alla pratica",
            publishTask: "Pubblica attività",
            taskData: "Dati attività",
            edit: "Modifica",
            copy: "Copia",
            successfullyCopied: "Copia riuscita",
            delete: "Elimina",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Dopo l'eliminazione, tutti i dati delle attività in questo corso verranno cancellati. Sei sicuro di voler eliminare il corso?",
            courseName: "Nome del corso",
            creationTime: "Tempo di creazione",
            releaseStatus: "Stato di pubblicazione",
            whole: "Tutto",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Nessun suono rilevato",
              voice: "Voce",
              text: "Testo",
              referenceScript: "Discorso di riferimento:",
              clickOnTheRightSideToStartRecording:
                "Clicca sulla destra per iniziare la registrazione",
              sendOut: "Invia",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Il client corrente è già connesso al server ASR Websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "Dopo aver elaborato l'ultimo fotogramma, ripristinare lo stato",
                aSRConnectionClosed: "Connessione ASR chiusa...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "La registrazione è in pausa ed è stata elaborata, ma ci sono ancora dati nella cache",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Trovato canale ASR chiuso, ricreare il link Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Trovato che il canale ASR non è aperto, ricreare il collegamento Websocket",
                unableToRecord: "Impossibile registrare:",
                creatingARecorderService: "Crea servizio di registrazione",
                turnOffTheRecorderService: "Disattiva il servizio Registratore",
              },
              util: {
                recorded: "Registrato",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "Il 16 bit pcm SendFrameSize deve essere un multiplo intero di 2",
                pCMSamplingRateShouldNotOccur:
                  "La frequenza di campionamento del pcm non deve apparire",
                andTheRequiredSamplingRate:
                  "E la frequenza di campionamento richiesta",
                atypism: "Incoerente",
                errorsThatShouldNotOccur:
                  "Errori che non dovrebbero verificarsi:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> I dati fuori uso possono essere scartati se i requisiti non sono elevati. </Span>',
                flower: "Fiori",
                TransferStopped: ": Trasferimento interrotto",
              },
            },
            finishTest: {
              index: {
                endTest: "Fine test",
                testResult: "Risultati del test",
                returnToCanvas: "Torna a Tela",
                retest: "Ri-test",
              },
            },
          },
          index: {
            testFromCurrentNode: "Test dal nodo corrente",
            listening: "Radio in...",
            canvasTesting: "Test su tela",
            endOfProcess: "Fine del processo",
            restart: "Riavvia",
            start: "Inizia",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Analisi del crollo",
                  expandParsing: "Espandi Parse",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Troppe parole modali",
                  second: "Tempi",
                  ratingOverview: "Panoramica del punteggio",
                  totalScore: "Punteggio totale",
                  section: "Sezione",
                  name: "Nome",
                  of: "(Totale",
                  people: "Persone)",
                  scriptScore: "Punteggio vocale",
                  common: "Totale",
                  keyPoints: "Un punto chiave,",
                  notReached: "Non raggiunto,",
                  have: "Ci sono",
                  violationOfRegulations: "Violazioni",
                  emotionalScore: "Punteggio delle emozioni",
                  appear: "Appaiono",
                  secondaryNegativeEmotions: "Emozioni negative secondarie",
                  expressionScore: "Punteggio di espressione",
                  speakTooFast: "Speedy troppo veloce",
                  times: "Volte,",
                  speakTooSlowly: "VELOCITÀ LENTA",
                  average: "Media",
                  wordminute: "Parola/minuto",
                },
              },
              score: {
                index: {
                  studentNodeName: "Nome nodo studente",
                  keyPointName: "Nome del punto chiave",
                  slideNodeName: "Nome nodo diapositiva",
                  dialogue: "Dialogo",
                  mainPoints: "Punti chiave",
                  slide: "Slide",
                  detectionResult: "Risultati del test",
                  satisfy: "Si incontrano",
                  dissatisfaction: "Non soddisfatto",
                  score: "Punteggio",
                  script: "Parole",
                  semantics: "Semantica",
                  scriptViolation: "Violazione del discorso",
                  emotion: "Commozione",
                  expression: "Espressione",
                },
              },
              taskTitle: { index: { branch: "Punti" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Questa volta",
                  lastTime: "L'ultima volta",
                  comprehensiveEvaluation: "Valutazione completa",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· discorso standard cumulativo',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Una parola, il numero di parole colpite dallo studente",
                  aWordspan: "Parola </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Tempi </span><br /><span style = "margin-left: 8px">· Velocità lenta',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Troppe parole modali',
                  complete: "Contenuto completo",
                  keyHit: "Punti chiave colpiti",
                  languageNorm: "Specificazione della lingua",
                  fluentExpression: "Espressione liscia",
                  emotionallyPositive: "Emotivamente positivo",
                  radarChart: "Grafico radar",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Numero di flussi completi',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· Premi punti chiave',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· Sì',
                  dealingWithIllegalWordsspan:
                    "In violazione della parola </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· Parla troppo velocemente',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '</Span><br /><span style = "margin-left: 8px"> troppe parole modali',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Emozioni negative',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Punteggio record",
                  firstRating: "Prima valutazione",
                  totalScore: "Punteggio totale",
                  changeTo: "Cambia",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Fase di comunicazione",
                  communicationObjectives: "Obiettivi della comunicazione",
                  scoringCriteria: "Criteri di punteggio",
                  suggestionsForImprovement:
                    "Raccomandazioni per la promozione",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Rimanente:",
                  second: "Tempi",
                  doItAgain: "Ancora una volta.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Il browser non supporta la riproduzione audio.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions:
                    "Commenti e suggerimenti sul discorso",
                  regenerate: "Rigenerare",
                  generate: "Genera",
                  generationFailed: "Costruire non riuscito",
                },
              },
              resultModal: {
                index: { return: "Reso", viewDetails: "Visualizza dettagli" },
              },
            },
            index: {
              reRatingSuccessful: "Riottenere il successo",
              reRatingFailed: "Ri-punteggio non riuscito",
              downloadRecording: "Scarica la registrazione",
              downloadText: "Scarica testo",
              download: "Scaricare",
              reRating: "Ri-punteggio",
              submitARatingAppeal: "Invia reclamo per la classificazione",
              reviewSubmission: "Rivedi la presentazione",
              saveChanges: "Salva modifiche",
              previousSentence: "Frase precedente",
              nextSentence: "Frase successiva",
              score: "Valutazione",
              scoringSituation: "Punteggio",
              dialogueAnalysis: "Analisi del dialogo",
            },
          },
          index: {
            ratingAppealInProgress: "Reclenze di punteggio",
            operation: "Operazione",
            wrongQuestionSet: "Set di problemi errati",
            ratingUpdate: "Aggiornamento della valutazione",
            viewDetails: "Visualizza dettagli",
            curriculum: "Curriculum",
            taskName: "Nome attività",
            taskType: "Tipo di attività",
            score: "Punteggio",
            scoringResults: "Punteggio Risultati",
            time: "Tempo",
            courseClassification: "Classificazione del corso",
            whole: "Tutto",
            learningTime: "Tempo di apprendimento",
            historicalLearningData: "Dati storici di apprendimento",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Pausa pratica",
              lastProgress: "Ultimo progresso",
              continuePracticing: "Continua a praticare",
              practice: "Esercizio",
              startPracticing: "Inizia a praticare",
              historicalData: "Dati storici",
              courseIntroduction: "Introduzione del corso:",
              exerciseDifficulty: "Difficoltà di pratica:",
              practiceCompletionRequirements:
                "Requisiti di completamento dell'esercizio:",
              practiceFrequency: "Numero di esercizi ≥",
              second: "Tempi",
              anyScore: "Qualsiasi punteggio ≥",
              branch: "Punti",
              or: "O",
              taskList: "Elenco attività",
              practiceTime: "Tempo di esercizio:",
              to: "A",
              unlimitedTime: "Tempo illimitato",
              completionStatus: "Stato di completamento",
              numberOfExercises: "Numero di esercizi",
              maximumScore: "Punteggio più alto",
              operation: "Operazione",
              enteringLearning: "Inserisci l'apprendimento",
              historicalLearningData: "Dati storici di apprendimento",
              wrongQuestionSet: "Set di problemi errati",
            },
          },
          index: {
            practiceTasks: "Attività di pratica",
            examTasks: "Compiti di esame",
            task: "Compiti",
          },
          learn: {
            index: {
              courseName: "Nome del corso",
              courseIntroduction: "Introduzione del corso",
              learningContent: "Contenuto di apprendimento",
              downloadLearningMaterials: "Materiali di apprendimento Scaricare",
              startPracticing: "Inizia a praticare",
            },
          },
          test: {
            index: {
              taskList: "Elenco attività",
              courseIntroduction: "Introduzione del corso:",
              examDifficulty: "Difficoltà dell'esame:",
              requirementsForPassingTheExam:
                "Requisiti per il superamento dell'esame:",
              examScore: "Punteggio del test ≥",
              branch: "Punti",
              examTime: "Tempo di esame:",
              to: "A",
              unlimitedTime: "Tempo illimitato",
              completionStatus: "Stato di completamento",
              completionTimes: "Numero di completamenti",
              maximumScore: "Punteggio più alto",
              operation: "Operazione",
              immediateExam: "Esame immediato",
              history: "Storia",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Discorso di riferimento" } },
              flow: {
                index: {
                  wrongQuestionItem: "Articolo sbagliato (",
                  score: "Punteggio",
                  TotalScore: "/Punteggio totale",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Errore del server" } },
                  index: {
                    startRecording: "Avvia la registrazione",
                    endRecording: "Termina la registrazione",
                    startPracticing: "Inizia a praticare",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Inserisci",
                    sendOut: "Invia",
                    startPracticing: "Inizia a praticare",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Avvia la registrazione",
                    endRecording: "Termina la registrazione",
                    startPracticing: "Inizia a praticare",
                  },
                  hook: { index: { failInSend: "Impossibile inviare" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Punteggio di questo esercizio sbagliato",
                  tips: "Prompt",
                  areYouSureToEnd: "Confermare la fine?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Esercitati di nuovo e non padroneggiare le domande sbagliate.",
                  continue: "Continua",
                  returnToTheSetOfIncorrectQuestions:
                    "Torna al set di domande sbagliate",
                  end: "Fine",
                  wrongQuestionItem: "Articolo sbagliato",
                  numberOfExercisesThisTime: "Il numero di questo esercizio",
                  masteringTheSituation: "Afferrare la situazione",
                  mastered: "Mastering",
                  notMastered: "Non masterizzato",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Esercita di nuovo questa domanda",
                  nextQuestion: "Prossima domanda",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Esercizio sbagliato",
                endExercise: "Fine esercizio",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Esercizio sbagliato",
                endExercise: "Fine esercizio",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Esercizio sbagliato",
                endExercise: "Fine esercizio",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Visualizza discorso",
                  section: "Sezione",
                  secondaryDiscourseTechnique: "Discorso secondario",
                },
              },
              tableItem: {
                count: { second: "Tempi" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Non sono state trovate informazioni correlate",
                  recallWords: "Richiama la parola",
                  branch: "Punti",
                  answerIncorrectly: "Risposta sbagliata",
                  second: "Tempi",
                  wrongAnswerRate: "Tasso di risposta sbagliato",
                },
                standardScript: {
                  standardScript: "Discorso standard",
                  myScript: "Le mie parole",
                },
                status: {
                  mastered: "Mastering",
                  notMastered: "Non masterizzato",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "Numero totale di risposte",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Numero di volte in cui sono state praticate domande sbagliate",
                },
              },
            },
            index: {
              wrongQuestionList: "Elenco delle domande sbagliate",
              wrongQuestionSet: "Set di problemi errati",
              mispracticeExercises: "Esercizio sbagliato",
              taskName: "Nome attività",
              staff: "Impiegato",
              masteringTheSituation: "Afferrare la situazione",
              whole: "Tutto",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Numero di esercizi ≥",
              second: "Tempi",
              or: "O",
              excellentScoreAtAnyOneTime: "Qualsiasi punteggio eccellente (≥",
              points: "Punti)",
              examScore: "Punteggio del test ≥",
              branch: "Punti",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Distribuzione media casuale",
              assignToDesignatedPersonnel: "Assegna a persone designate",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Successo nell'aumentare il numero di esami",
              failedToIncreaseTheNumberOfExams:
                "Aumentare il numero di fallimenti del test",
              increaseTheNumberOfExams: "Aumenta il numero di esami",
              selectObjects: "Seleziona oggetto",
            },
          },
          executionFilter: {
            index: { participationRate: "Tasso di partecipazione" },
          },
          intelligentReview: {
            index: {
              overallReview: "Revisione globale",
              pleaseEnterTheReviewCriterianotMandatory:
                "Inserisci i criteri per i commenti (non richiesti)",
              commentaryOnSingleSentenceRhetoric:
                "Commenti sulla singola frase",
              realTimeReview: "Recensioni in tempo reale",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Punteggio medio del test",
            averageExamDurationmin: "Durata media dell'esame (min)",
            accumulatedPracticeDurationmin:
              "Durata della pratica cumulativa (min)",
            accumulatedPracticeTimes: "Numero cumulativo di esercizi",
            totalScore: "Punteggio totale",
            average: "Media",
            total: "Totale",
            keyHitCount: "Conteggio dei punti chiave",
            practiceAverageScore: "Punteggio medio di pratica",
            averagePracticeDurationmin: "Durata media dell'esercizio (min)",
            topOfTheHighestScore: "Il punteggio più alto Top3",
            branch: "Punti",
            errorProneItemTop: "Articoli soggetti a errori Top5",
            hitRate: "Tasso di successo",
            hitRateOfKeyPointsInScript:
              "Tasso di successo dei punti chiave del discorso",
            averageScriptScore: "Punteggio medio del discorso",
            sortByProcess: "Ordina per processo",
            sortByScore: "Ordina per punteggio",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Punteggio medio di questa pagina/punteggio totale di questa pagina",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Il punteggio medio di questo articolo/il punteggio totale di questo articolo",
            details: "Dettagli",
            viewRules: "Visualizza regole",
            standardScript: "Discorso standard",
          },
          scoreDetailList: {
            section: "Sezione",
            second: "Tempi",
            practice: "Esercizio",
            examination: "Esame",
            notMeetingStandards: "Non all'altezza degli standard",
            meetingStandards: "Standard",
            excellent: "Eccellente",
            frequency: "Numero di volte",
            fraction: "Frazione",
            result: "Risultati",
            operation: "Operazione",
            viewDetails: "Visualizza dettagli",
          },
          index: {
            common: "Totale",
            people: "Persone",
            staff: "Impiegato",
            notMeetingStandards: "Non all'altezza degli standard",
            meetingStandards: "Standard",
            excellent: "Eccellente",
            notStarted: "Non avviato",
            haveInHand: "In corso",
            completed: "Completato",
            passed: "Superato",
            hangInTheAir: "Incompiuto",
            failed: "Non superato",
            unlimitedTime: "Tempo illimitato",
            to: "A",
            maximumScoringCriteria: "Criteri di punteggio più alti",
            completionStatus: "Stato di completamento",
            employeeName: "Nome dipendente",
            frequency: "Numero di volte",
            practice: "Esercizio",
            examination: "Esame",
            average: "Media",
            speechCraftAvgScore: "Punteggio medio del discorso",
            emotionAvgScore: "Punteggio emotivo medio",
            speedAvgScore: "Punteggio medio espresso",
            flowAvgScore: "Punteggio medio del processo",
            practiceCostTime: "Durata cumulativa",
            participateCommitRate: "Tasso di conformità",
            participateExcellentRate: "Tasso eccellente",
            duration: "Durata",
            score: "Punteggio",
            scoringResults: "Punteggio Risultati",
            flowScore: "Punteggio del processo",
            craftScore: "Punteggio vocale",
            emotionScore: "Punteggio delle emozioni",
            speedScore: "Punteggio di espressione",
            contentIntegrity: "Integrità del contenuto",
            pointHitRate: "Tasso di successo dei punti chiave",
            emotionNegativeRate: "Percentuale negativa di emozione",
            paraPhaSiaRate: "Percentuale di velocità anomala del discorso",
            toneOvermuchRate: "Eccessiva proporzione di parole modali",
            maximumScore: "Punteggio più alto",
            highestScoreResult: "Risultati con il punteggio più alto",
            state: "Stato",
            averageDurationmin: "Durata media (min)",
            lately: "Di recente",
            time: "Tempo",
            operation: "Operazione",
            viewDetails: "Visualizza dettagli",
            wrongQuestionSet: "Set di problemi errati",
            taskName: "Nome attività",
            completeRequirements: "Requisiti completi",
            publishTo: "Pubblicazione di oggetti",
            taskTime: "Tempo di attività",
            fractionalComposition: "Composizione della frazione",
            totalScore: "Punteggio totale",
            branch: "Punti",
            Script: "(Talker",
            scoreEmotion: "Commozione",
            fractionExpression: "Sottoespressione",
            points: "Punti)",
            implementationRate: "Tasso di partecipazione",
            numberOfExecutors: "Numero di partecipanti",
            numberOfPeopleNotExecuted: "Numero di non partecipanti",
            completionRate: "Tasso di completamento",
            numberOfCompletedPersonnel: "Numero di completate",
            numberOfUnfinishedPersonnel: "Numero di non finiti",
            complianceRate: "Tasso di conformità",
            numberOfQualifiedPersonnel: "Numero di conseguimento",
            numberOfNonCompliantIndividuals:
              "Numero di persone che non soddisfano lo standard",
            excellentRate: "Tasso eccellente",
            participateCompleteRate:
              "Tasso di completamento della partecipazione",
            numberOfOutstandingIndividuals: "Numeri eccezionali",
            numberOfNonOutstandingIndividuals: "Numero non eccellente",
            export: "Esportazione",
            taskData: "Dati attività",
            resultAnalysis: "Analisi dei risultati",
            scoreDetails: "Dettagli del punteggio-",
            dataOverview: "Panoramica dei dati",
            taskDetail: "Dettagli attività",
            scriptAnalysis: "Analisi del discorso",
            flowAnalysis: "Analisi del processo",
            expressionAnalysis: "Analisi dell'espressione",
            emotionAnalysis: "Analisi del sentimento",
            byTime: "Visualizzazione della dimensione del tempo",
            byNumber: "Vista della dimensione del grado",
            personData: "Statistiche da parte del personale",
            numberData: "Dettaglio per record",
            count: "Per numero di volte",
            person: "Per numero di persone",
            reachTimes: "Letture",
            outOfReachTimes: "Orari non raggiunti",
            seeOutOfReach: "Fai clic per visualizzare non raggiunto",
            accumulative: "Cumulativo",
            script: "Parole",
            times: "Tempi",
            sentence: "Sentenza",
            expressionAverage: "Punteggio medio di espressione",
            modalAnalysis: "Analisi della particella modale",
            total: "Totale",
            muchModalAnalysis: "Troppe parole modali",
            normal: "Normale",
            speedAnalysis: "Analisi della velocità del discorso",
            speedDistribution: "Distribuzione del tasso vocale",
            speakingSpeed: "Velocità di conversazione",
            normalSpeak: "Normale velocità del discorso",
            tooFastSpeak: "Speedy Speedy",
            tooSlowlySpeak: "Velocità lenta",
            violationWord: "Violazione delle parole",
            violationWordAnalysis: "Analisi delle parole di violazione",
            noData: "No",
            emotionalScore: "Punteggio delle emozioni",
            emotionalDistribution: "Distribuzione delle emozioni",
            negativeEmotion: "Emozione negativa",
            normalEmotion: "Emozione normale",
            getDetail: "Visualizza dettagli",
            barPattern: "Vista da bar",
            timeDimension: "Visualizzazione della latitudine temporale",
            timesDimension: "Vista della dimensione del grado",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Media",
                  averageScoreOfScript: "Punteggio medio del discorso",
                  averageEmotionalScore: "Punteggio emotivo medio",
                  expressionAverageScore: "Punteggio medio espresso",
                  processAverageScore: "Punteggio medio del processo",
                  accumulatedDuration: "Durata cumulativa",
                  accumulatedTimes: "Tempi cumulativi",
                  participationComplianceRate: "Tasso di partecipazione",
                  participationRateForAchievingExcellence:
                    "Tasso di partecipazione",
                  branch: "Punti",
                  numberOfPeople: "Numero di persone",
                  averageScoreOfExpression: "Punteggio medio di espressione",
                  people: "Persone",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Emozione negativa" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Punteggio medio del discorso",
                  branch: "Punti",
                },
                useSummaryDetail: {
                  keyHitRate: "Tasso di successo dei punti chiave",
                },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Recensioni intelligenti",
            pleaseImproveTheConfiguration:
              "Si prega di perfezionare la configurazione",
            examResults: "Risultati dell'esame",
            showTheResultsAfterEachExam: "Mostra i risultati dopo ogni esame.",
            showTheResultsAfterTheLastExam:
              "Mostra i risultati dopo l'ultimo esame.",
            doNotDisplayResults: "Non mostrare risultati",
            resultDisplay: "I risultati mostrano",
            score: "Punteggio",
            scoringResults: "Punteggio Risultati",
            allowViewingScoreDetails:
              "Consenti di visualizzare i dettagli del punteggio",
            viewCanvasConfiguration: "Visualizza la configurazione della tela",
            synchronizationFailedProcessNodeHasChanged:
              "Sincronizzazione non riuscita, il nodo di processo ha generato modifiche",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "La sincronizzazione è riuscita, fai clic su Pubblica per aggiornare il corso",
            retrievedTheLatestCanvasConfiguration:
              "Ultima configurazione su tela tirata",
            synchronizeCanvasConfiguration:
              "Sincronizzare la configurazione della tela",
            scoringMethod: "Metodo di punteggio",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Punti, flusso {0} punti, abilità vocale {1} punti, emozione {2} punti, velocità {3} punti)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Lo standard di eccellenza deve essere superiore allo standard di conformità.",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "I criteri di punteggio devono essere maggiori dello 0% e inferiori o uguali a 100",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "I criteri di punteggio devono essere superiori a 0 punti e inferiori o uguali a {0} punti.",
            doRatingsRequireManualReview:
              "Se il punteggio necessita di revisione manuale",
            allocationStrategy: "Politica di assegnazione",
            pleaseSelectTheAssignedPersonnel: "Seleziona una persona assegnata",
            pleaseSelectADesignatedPerson: "Seleziona una persona designata",
            timeLimitForSingleQuestionAnswering:
              "Limite di tempo per la risposta a una singola domanda",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Pubblicare ai dipendenti aggiunti di default",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Il nuovo punteggio indica che il punteggio verrà abbinato in base alle regole di punteggio dell'ultima tela. L'attività corrente ha esaminato manualmente i dati di punteggio. Vuoi sovrascrivere il punteggio?",
            notCovered: "Non coperto",
            cover: "Copertina",
            participationSituation: "Partecipazione",
            numberOfParticipants: "Numero di partecipanti",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Somma il numero di oggetti di esercizio selezionati per questo compito",
            sumOfTheNumberOfParticipantsInTheTask:
              "Somma il numero di persone che hanno partecipato al compito",
            numberOfCompletedPersonnel: "Numero di completate",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Somma il numero di persone che soddisfano i requisiti di completamento dell'attività",
            numberOfQualifiedPersonnel: "Numero di conseguimento",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Somma il numero di persone che soddisfano il punteggio dell'attività",
            excellentPersonnel: "Numeri eccezionali",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Somma il numero di persone che soddisfano il compito con un punteggio eccellente",
            participationRate: "Tasso di partecipazione",
            numberOfParticipantsNumberOfParticipants:
              "Numero di partecipanti/numero di partecipanti",
            numberOfCompletedNumberOfParticipants:
              "Numero di completate/Numero di partecipanti",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Numero di risultati/numero di partecipanti",
            excellentNumbernumberOfParticipants:
              "Numero eccezionale/Numero di partecipanti",
            participationCompletionRate:
              "Tasso di completamento della partecipazione",
            numberOfCompletedParticipated: "Numero di completate/partecipanti",
            participationComplianceRate: "Tasso di partecipazione",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Numero di conseguimento/partecipazione",
            participationRateForAchievingExcellence: "Tasso di partecipazione",
            accumulatedDuration: "Durata cumulativa",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Partecipa alla durata e alla somma del compito",
            averageDuration: "Durata media",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Durata totale dei partecipanti/numero totale dei partecipanti",
            accumulatedTimes: "Tempi cumulativi",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Somma il numero di partecipanti al compito",
            averageScore: "Media",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Punteggio medio del personale coinvolto nell'attività",
            maximumScore: "Punteggio più alto",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Il punteggio più alto del personale coinvolto nel compito",
            averageScoreOfScript: "Punteggio medio del discorso",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Somma del punteggio del discorso degli studenti/volte di partecipazione totale",
            totalScoreOfScript: "Punteggio totale del discorso",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Punteggio vocale totale impostato in questa attività",
            processAverageScore: "Punteggio medio del processo",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Somma del punteggio del processo studentesco/numero totale di impegni",
            totalProcessScore: "Punteggio totale del processo",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Punteggio totale del processo impostato in questa attività",
            averageEmotionalScore: "Punteggio emotivo medio",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Somma del punteggio di emozione del tirocinante/numero totale di impegni",
            totalEmotionalScore: "Punteggio emotivo totale",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Punteggio emotivo totale impostato in questo compito",
            averageScoreOfExpression: "Punteggio medio di espressione",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Somma del punteggio di espressione dello studente/numero totale di impegni",
            totalScoreOfExpression: "Punteggio totale delle espressioni",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Punteggio totale dell'espressione impostato in questa attività",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Inizia a eseguire il re-punteggio, puoi saltare al centro attività per visualizzare i progressi.",
            reRating: "Ri-punteggio",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Il nuovo punteggio indica che il punteggio verrà abbinato in base alle regole di punteggio dell'ultima tela e il punteggio attuale verrà sovrascritto.",
            releaseTime: "Tempo di rilascio",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Recensito",
              pendingReview: "Da rivedere",
              enterKeywordSearch: "Inserisci le parole chiave per cercare",
            },
            pageRightTable: {
              index: {
                employeeName: "Nome dipendente",
                systemRating: "Punteggio di sistema",
                scoringResults: "Punteggio Risultati",
                duration: "Durata",
                time: "Tempo",
                state: "Stato",
                whetherToModifyTheRating: "Punteggio corretto",
                finalScore: "Punteggio finale",
                reviewedBy: "Revisore",
                operation: "Operazione",
                viewScoreDetails: "Visualizza i dettagli del punteggio",
                viewStatus: "Visualizza stato",
                whole: "Tutto",
                correctionStatus: "Stato di correzione",
                completeReview: "Revisione completa",
                tips: "Prompt",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "I risultati finali dei tirocinanti sono soggetti ai risultati finali attuali della revisione. Conferma",
              },
            },
          },
          index: { manualReview: "Revisione manuale" },
        },
        review: {
          index: {
            employeeName: "Nome dipendente",
            score: "Punteggio",
            scoringResults: "Punteggio Risultati",
            duration: "Durata",
            taskName: "Nome attività",
            numberOfAppealScripts: "Numero di reclami",
            appealTime: "Tempo di appello",
            processStatus: "Stato del processo",
            scoreAfterReview: "Punteggio dopo la revisione",
            reviewedBy: "Revisore",
            operation: "Operazione",
            viewScoreStatus: "Visualizza punteggio",
            whole: "Tutto",
            manualReview: "Revisione manuale",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Dati attività esercizio",
            implementationRate: "Tasso di attuazione",
            numberOfExecutors: "Numero di esecuzione",
            numberOfPeopleNotExecuted: "Numero di non eseguiti",
            completionRate: "Tasso di completamento",
            numberOfCompletedPersonnel: "Numero di completate",
            numberOfUnfinishedPersonnel: "Numero di non finiti",
            complianceRate: "Tasso di conformità",
            numberOfQualifiedPersonnel: "Numero di conseguimento",
            numberOfNonCompliantIndividuals:
              "Numero di persone che non soddisfano lo standard",
            excellentRate: "Tasso eccellente",
            numberOfOutstandingIndividuals: "Numeri eccezionali",
            numberOfNonOutstandingIndividuals: "Numero non eccellente",
            examTaskData: "Dati dell'attività dell'esame",
            published: "Pubblicato",
            courses: "Un corso",
            courseData: "Dati del corso",
            practiceTasks: "Attività di pratica",
            examTasks: "Compiti di esame",
          },
          practiceSummary: {
            practiceSummary: "Riepilogo pratica",
            numberOfPracticeTasks: "Numero di compiti pratici",
            numberOfExercises: "Numero di esercizi",
            accumulatedPracticeDuration: "Durata della pratica cumulativa",
            complianceRate: "Tasso di conformità",
            excellentRate: "Tasso eccellente",
            ranking: "Classifica",
          },
          rankingList: {
            numberOfExercises: "Numero di esercizi",
            exerciseDuration: "Durata dell'esercizio",
            complianceRate: "Tasso di conformità",
            excellentRate: "Tasso eccellente",
            diligenceChart: "Lista di diligenza",
            excellentList: "Elenco eccellente",
            ranking: "Classifiche",
            fullName: "Nome",
            i: "Io...",
          },
          taskCard: {
            end: "Scadenza:",
            noDeadline: "Nessuna scadenza",
            second: "Tempi",
            branch: "Punti",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Nessun lavoro",
            completed: "Completato",
          },
          filter: { employeeDepartment: "Dipartimento dipendenti" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Esportazione riuscita, vai al centro attività per visualizzarlo",
              export: "Esportazione",
            },
          },
          filterConfig: {
            dropdown: { custom: "Personalizzato" },
            modal: {
              updateSuccessful: "Aggiornamento riuscito",
              successfullySaved: "Salvataggio riuscito",
              setAsACommonReport: "Impostare sui rapporti comuni",
              updateCurrentReport: "Aggiorna il rapporto corrente",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Media",
                  averageScoreOfScript: "Punteggio medio del discorso",
                  averageEmotionalScore: "Punteggio emotivo medio",
                  expressionAverageScore: "Punteggio medio espresso",
                  processAverageScore: "Punteggio medio del processo",
                  branch: "Punti",
                },
              },
              comprehensiveEvaluation: { const: { data: "Dati" } },
              doneStatus: {
                index: {
                  practice: "Esercizio",
                  examination: "Esame",
                  executed: "Esegui",
                  unexecuted: "Non giustiziato",
                  accumulatedExamDuration: "Durata cumulativa della prova",
                  examFrequency: "Numero di esami",
                },
              },
              filter: {
                index: {
                  practice: "Esercizio",
                  examination: "Esame",
                  pleaseCompleteTheRequiredFields:
                    "Completa gli articoli richiesti",
                  staff: "Impiegato",
                  taskType: "Tipo di attività",
                  pleaseSelect: "Seleziona",
                  taskName: "Nome attività",
                },
              },
            },
            index: {
              completionStatus: "Stato di completamento",
              comprehensiveEvaluation: "Valutazione completa",
              abilityGrowthTrend: "Tendenza di crescita della capacità",
              errorProneAnalysis: "Analisi del punto soggetto a errori",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Numero Attivo",
            accumulatedPracticeDuration: "Durata della pratica cumulativa",
            averagePracticeDuration: "Tempo medio di esercizio",
            people: "Persone",
            numberOfPracticeTasks: "Numero di compiti pratici",
            numberOfExercises: "Numero di esercizi",
            exerciseDuration: "Durata dell'esercizio",
            second: "Tempi",
            avgScore: "Media",
            speechCraftAvgScore: "Punteggio medio del discorso",
            emotionAvgScore: "Punteggio emotivo medio",
            speedAvgScore: "Punteggio medio espresso",
            flowAvgScore: "Punteggio medio del processo",
            practiceCostTime: "Durata cumulativa",
            totalPracticeTimes: "Tempi cumulativi",
            participateCommitRate: "Tasso di partecipazione",
            participateExcellentRate: "Tasso di partecipazione",
          },
        },
        index: {
          employeeProfile: "Ritratto dell'impiegato",
          overview: "Panoramica",
          personalSignage: "Kanban personale",
          ongoingExercises: "Esercizio in corso",
          ongoingExams: "Esame in corso",
          practiceTaskProgress: "Progresso dell'attività di esercizio",
          examTaskProgress: "Avanzamento dell'attività di test",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Seleziona una parola concettuale",
              sessionDetectionSettings:
                "Impostazioni di rilevamento della sessione",
              successfullySaved: "Salvataggio riuscito",
              abnormalSpeechSpeed: "Velocità anomala del discorso",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "È supportato lo standard di rilevamento della velocità vocale definito dall'utente. Il risultato sarà la normale velocità del parlato, la velocità del discorso troppo veloce e la velocità del discorso troppo lenta. Quando la velocità del discorso è anormale (troppo veloce/troppo lento), il punteggio verrà ridotto in base alle regole di punteggio.",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Speedtoo veloce: più di un minuto",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Parola, troppo lenta: meno di al minuto",
              charactersIgnoringLessThan: "Parole, ignorare meno",
              aSentenceOfWords: "Frase delle parole",
              excessiveModalParticles: "Troppe parole modali",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Supporta parole di tono personalizzate e imposta le regole di troppe parole di tono. Quando ci sono troppe parole modali, il punteggio sarà ridotto in base alle regole di punteggio.",
              theNumberOfRepetitionsIs: "Il numero di ripetizioni è",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "La seconda volta, è determinato che ci sono troppe parole modali.",
              definitionOfSensitiveViolations:
                "Definizione di violazione sensibile",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Puoi personalizzare il lessico delle parole illegali per rilevare se alcune parole illegali appaiono in una frase. Quando c' è una parola illegale, il punteggio sarà ridotto secondo le regole di punteggio.",
              violationWords: "Parole di violazione:",
              emotionalDetection: "Rilevamento delle emozioni",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Secondo il dialogo del modello di emozione del sistema, il test delle emozioni sarà giudicato come normale emozione ed emozione negativa (inclusi negativo, arrabbiato e lamentoso). Quando c' è un'emozione negativa, il punteggio sarà ridotto secondo le regole di punteggio.",
              preserve: "Salva",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Dimensione di valutazione",
              ratingPoints: "Punteggio punti",
              explain: "Descrizione",
              type: "Tipo",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Dati casuali" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· discorso standard cumulativo',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Una parola, il numero di parole colpite dallo studente",
                  aWordspan: "Parola </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Tempi </span><br /><span style = "margin-left: 8px">· Velocità lenta',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Troppe parole modali',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Il punteggio è" },
                  item: {
                    violationOnceReduced: "1 violazione meno",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Velocità anomala (compresa la velocità troppo veloce e la velocità troppo bassa) 1 diminuzione",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Troppe parole modali 1 meno",
                    oneNegativeDecreaseInEmotions: "Emozione negativa meno 1",
                    branch: "Punti",
                  },
                  wrap: { name: "Nome", preserve: "Salva" },
                },
              },
              index: {
                complete: "Contenuto completo",
                keyHit: "Punti chiave colpiti",
                accurateFollowupReading: "Lettura accurata",
                languageNorm: "Specificazione della lingua",
                speechSpeed: "Velocità del discorso",
                modalParticles: "Parola modale",
                emotionallyPositive: "Emotivamente positivo",
                scoringModel: "Modello di punteggio",
                successfullyModified: "Modifica riuscita",
                newSuccessfullyCreated: "Nuovo successo",
              },
            },
            index: {
              scoringModel: "Modello di punteggio",
              newModel: "Nuovo modello",
              model: "Modello",
              type: "Tipo",
              operation: "Operazione",
              preview: "Anteprima",
              edit: "Modifica",
            },
          },
          index: {
            scoringModel: "Modello di punteggio",
            ratingDimension: "Dimensione di valutazione",
            sessionDetectionSettings:
              "Impostazioni di rilevamento della sessione",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Seleziona",
                hit: "Colpisci",
                misses: "Mancato",
              },
            },
          },
          index: {
            duration: "Durata",
            processScore: "Punteggio del processo",
            branch: "Punti",
            contentCompleteness: "Integrità del contenuto",
            scriptScore: "Punteggio vocale",
            keyHitRate: "Tasso di successo dei punti chiave",
            emotionalScore: "Punteggio delle emozioni",
            negativeProportionOfEmotions: "Percentuale negativa di emozione",
            expressionScore: "Punteggio di espressione",
            theProportionOfAbnormalSpeechSpeed:
              "Percentuale di velocità anomala del discorso",
            excessiveProportionOfModalParticles:
              "Eccessiva proporzione di parole modali",
            learningRecordData: "Dati dei record di apprendimento",
            staff: "Impiegato",
            curriculum: "Curriculum",
            mainPoints: "Punti chiave",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Tavolo",
                        lineChart: "Grafico a linee",
                        barChart: "Istogramma",
                        pieChart: "Grafico a torta",
                      },
                      index: { switchingTypes: "Tipo di interruttore" },
                    },
                  },
                  index: {
                    saveSuccessful: "Salvataggio riuscito",
                    editChart: "Modifica grafico",
                    chartName: "Nome grafico",
                    deleteSuccessful: "Eliminazione riuscita",
                    refresh: "Aggiorna",
                    export: "Esportazione",
                    areYouSureToDeleteIt: "Sei sicuro di voler eliminare?",
                    delete: "Elimina",
                    viewFilteringCriteria: "Visualizza criteri di filtro",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Esportazione riuscita, vai al centro attività per visualizzarlo",
              },
            },
            header: {
              index: {
                saveSuccessful: "Salvataggio riuscito",
                deleteSuccessful: "Eliminazione riuscita",
                editReport: "Modifica rapporto",
                reportName: "Nome rapporto",
                refresh: "Aggiorna",
                areYouSureToDeleteIt: "Sei sicuro di voler eliminare?",
                delete: "Elimina",
                exportToExcel: "Esporta Excel",
                exportSnapshot: "Istantanea di esportazione",
                export: "Esportazione",
                authority: "Autorizzazioni",
                permissionSettings: "Impostazioni di autorizzazione",
                reportVisiblePermissions: "Segnala autorizzazioni visibili",
                visibleToEveryone: "Visibile a tutti",
                onlyVisibleToOneself: "Solo visibile a se stessi",
                designatedPersonnelVisible: "Persona specificata visibile",
                pleaseSelect: "Seleziona",
                reportDataPermissions: "Segnala l'autorizzazione ai dati",
                restrictedByUserPermissions:
                  "Limitato dalle autorizzazioni utente",
                visiblePeopleAreTheSame: "Le persone visibili sono uguali.",
              },
            },
            titleFooter: {
              index: {
                addReport: "Nuovo rapporto",
                reportName: "Nome rapporto",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Esportazione riuscita, vai al centro attività per visualizzarlo",
            dataQueryInProgress: "Query di dati in...",
            customKanban: "Kanban personalizzato",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Tavolo",
                lineChart: "Grafico a linee",
                barChart: "Istogramma",
                pieChart: "Grafico a torta",
              },
              index: {
                successfullyAdded: "Aggiunto correttamente",
                viewSQL: "Visualizza SQL",
                export: "Esportazione",
                addToReport: "Aggiungi al rapporto",
              },
            },
            refer: {
              hooks: {
                data: "Dati",
                interrogativeSentence: "Domande",
                historicalQuery: "Interrogazione storica",
              },
              index: { copySuccessful: "Copia riuscita", copy: "Copia" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Non all'altezza degli standard",
                        meetingStandards: "Standard",
                        excellent: "Eccellente",
                        notStartedYet: "Non avviato",
                        inProgress: "In corso",
                        completed: "Completato",
                        incomplete: "Incompiuto",
                      },
                    },
                  },
                  const: {
                    taskName: "Nome attività",
                    department: "Dipartimento",
                    studentName: "Nome dello studente",
                    scoringResults: "Punteggio Risultati",
                    taskCompletionStatus: "Stato di completamento attività",
                  },
                  index: { pleaseSelectADimension: "Seleziona una dimensione" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Inserisci @ per aprire la casella bullet per la selezione delle dimensioni",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Inserisci la dichiarazione di query per eseguire la query direttamente",
                query: "Interrogazione",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Esportazione riuscita, vai al centro attività per visualizzarlo",
            dataQueryInProgress: "Query di dati in...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Ricerca parole chiave",
                previous: "Precedente",
                next: "Successivo",
                theObjectThatTriggersTheScrollingEventIs:
                  "L'oggetto che attiva l'evento di scorrimento è",
                userScrolling: "Scorri utente",
              },
            },
            summary: {
              index: {
                reminder: "Promemoria:",
                edit: "Modifica",
                name: "Nome",
                hintLanguage: "Prompt",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Estrazione del punto di vista" },
            },
            outline: {
              index: {
                numberOfKeywords: "Numero di parole chiave",
                speaker: "Altoparlante",
                speechDuration: "Durata del discorso",
                proportionOfSpeaking: "Rapporto di conversazione",
                maximumSpeechDuration: "Lunghezza massima del discorso",
                numberOfQuestions: "Numero di domande",
                averageSpeechSpeed: "Velocità media",
                conversationalActions: "Sessione di azione",
                contentSummary: "Riepilogo del contenuto",
                generateSummary: "Genera riepilogo",
                keywordCloud: "Parole chiave cloud",
              },
            },
          },
          index: {
            outline: "Riassunto",
            viewpoint: "Opinione",
            contentSummary: "Riepilogo del contenuto",
            viewpointExtraction: "Estrazione del punto di vista",
            summaryOfKeyPoints: "Sintesi dei punti chiave",
            videoAnalysis: "Analisi video",
          },
        },
        list: {
          index: {
            delete: "Elimina",
            uploadSuccessful: "Caricamento riuscito",
            videoList: "Elenco video",
            uploadVideo: "Carica video",
            videoName: "Nome video",
            uploadTime: "Tempo di caricamento",
            endTime: "Ora di fine",
            analyzeProgress: "Analizza i progressi",
            haveInHand: "In corso",
            completed: "Completato",
            fail: "Impossibile",
            toBegin: "Per iniziare",
            operation: "Operazione",
            see: "Visualizza",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Aggiungi orario di lavoro" },
        },
      },
      index: {
        businessWorkingHours: "Orario di lavoro aziendale",
        newlyBuild: "Nuovo",
        name: "Nome",
        describe: "Descrizione",
        operation: "Operazione",
        edit: "Modifica",
        delete: "Elimina",
        editBusinessWorkingHours: "Modifica ore di lavoro aziendale",
        newBusinessWorkingHours: "Nuove ore lavorative aziendali",
        workingHours: "Orario di lavoro",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Estrazione intelligente",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Sei sicuro di voler annullare? La cancellazione non sarà possibile",
        },
      },
    },
    accountManagement: {
      securitySetting: "Impostazioni di sicurezza",
      accountManagement: "Gestione account",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "A",
            switchToIPAddressMode: "Passa alla modalità indirizzo IP",
            switchToIPSegmentMode: "Passa alla modalità segmento IP",
          },
        },
      },
      index: {
        saveSuccessful: "Salvataggio riuscito",
        pleaseImproveTheConfiguration:
          "Si prega di perfezionare la configurazione",
        securitySetting: "Impostazioni di sicurezza",
        employeeLoginIPRestrictions:
          "Restrizioni dell'IP di accesso ai dipendenti",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Limitare l'intervallo IP a cui i dipendenti possono accedere. Il login non è consentito oltre la gamma. Valido solo per siti Web, le app mobili non sono limitate e gli amministratori non sono soggetti a restrizioni.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Quando si aggiunge o si modifica, inserire il segmento IP di rete esterna (lo stesso segmento C) o un indirizzo IP di rete esterno fisso.",
        allowLoginIPRange: "Intervallo IP autorizzato ad accedere",
        preserve: "Salva",
      },
    },
    coachAdminEntry: { speechRecognition: "Riconoscimento vocale" },
    billingCenter: {
      template: {
        details: "Dettagli",
        languageType: "Tipo di lingua",
        aSREngine: "Motore ASR",
        voiceQualityQuota: "Quota di qualità vocale",
        realtimeData: "Dati in tempo reale",
        calculatedByDay: "Calcolato per giorno",
      },
      component: {
        aSRName: "Nome ASR",
        language: "Lingua",
        remainingQuota: "Quota rimanente",
        hours: "Ore",
        usedQuota: "Quota usata",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Operazione salvata con successo!",
        deletedSuccessfully: "Eliminazione riuscita!",
        pleaseSelectLanguage: "Seleziona la lingua",
        pleaseConfigureRules: "Configurare le regole",
        updateSuccessful: "Aggiornamento riuscito!",
        operationSuccessful: "Operazione riuscita!",
      },
      template: {
        aSRLanguage: "Linguaggio ASR",
        pleaseSelect: "Seleziona",
        model: "Modello",
        pleaseSelectLanguage: "Seleziona la lingua",
        delete: "Elimina",
        newASRModel: "Nuovo modello ASR",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Analisi della concorrenza" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Filtro delle recensioni dell' e-commerce",
        allComments: "Tutti i commenti",
        ecommerceReviewManagement: "Gestione della revisione dell' e-commerce",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Filtro per la revisione dei social media",
        allComments: "Tutti i commenti",
        socialMediaReviewManagement:
          "Gestione delle revisioni dei social media",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Analisi della concorrenza",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Nome",
        nickname: "Soprannomino",
        name: "Nome",
        email: "Numero di conto (e-mail)",
        mobile: "Telefono",
        agentId: "Lavoro n.",
        position: "Posta",
        role: "Ruolo",
        actions: "Operazione",
        createUser: "Nuovo dipendente",
        editUser: "Modifica dipendente",
        account: "Numero di conto",
        originPassword: "Password iniziale",
        newPassword: "Nuova password",
        resumePassword: "Conferma password",
        userGroup: "Gruppo di dipendenti",
        authorizationGroup: "Gruppo di autorizzazione",
        resetPassword: "Reimposta la password",
      },
      userGroups: {
        id: "ID",
        name: "Nome",
        userCount: "Numero di dipendenti",
        actions: "Operazione",
        createUserGroup: "Aggiungi gruppo di dipendenti",
        editUserGroup: "Modifica gruppo di dipendenti",
        member: "Membri",
      },
      role: {
        id: "ID",
        name: "Nome",
        actions: "Operazione",
        all: "Tutto",
        taskName: "Compiti",
        readTask: "Visualizzazione attività",
        manageTemplate: "Gestione dei modelli",
        manualInspection: "Revisione manuale",
        readResultAll: "Risultati Visualizza",
        readResultPersonal: "Risultati Visualizza (individuale)",
        readProcess: "Visualizza il processo di ispezione della qualità",
        readReport: "Statistiche e relazioni",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Data",
        taskName: "Nome",
        size: "Dimensioni",
        status: "Stato",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Nome",
            remark: "Descrizione",
            actions: "Operazione",
          },
          checkPoint: {
            name: "Nome",
            remark: "Descrizione",
            type: "Tipo",
            gradeType: "Metodo di valutazione",
            conditionLogic: "Regole",
            predeterminedScore: "Punteggio",
            weight: "Peso",
            status: "Stato",
            actions: "Operazione",
            switchOn: "Abilita",
            switchOff: "Disattiva",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Tempo",
            customer: "Cliente",
            customerPhone: "Telefono cliente",
            callType: "Tipo di chiamata",
            source: "Fonte",
            voiceRecording: "Registrazione delle chiamate",
            graded: "Valutazione",
            openingGreeting: "Saluti di apertura",
          },
          checkPointList: {
            name: "Punto di ispezione della qualità",
            type: "Tipo",
            deductScore: "Frazione",
            isHit: "Caso di successo",
            status: "Revisione",
            finalDeductScore: "Conferma la detrazione dei punti",
            hit: "Colpisci",
            notHit: "Mancato",
          },
        },
      },
    },
    custom: { keywords: "Parole chiave del dialogo" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Impostazioni di sistema" },
      systemIntegration: {
        text: "Integrazione del sistema",
        subTitle: {
          udesk: "Sistema di assistenza clienti Udesk",
          udeskWhite: "Sistema di servizio clienti",
          wechat: "WeChat aziendale",
          taobao: "Taobao Qianniu",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Gestione del campo di ispezione della qualità" },
      customerField: { text: "Campo cliente" },
      speechRecognition: { text: "Riconoscimento vocale" },
      user: { text: "Gestione dei dipendenti" },
      userGroups: { text: "Gestione del gruppo dei dipendenti" },
      role: { text: "Gestione del ruolo" },
      dataMigration: { text: "Migrazione dei dati" },
      taskCenter: { text: "Centro di missione" },
      recordUpload: { text: "Caricamento della registrazione locale" },
      anomalousData: { text: "Dati anomali" },
      systemLog: { text: "Registro messaggi" },
      msgCenter: { text: "Centro messaggi" },
      wechatDocking: { text: "Micro attracco aziendale" },
    },
    tasks: {
      homePage: { text: "Home Page" },
      workbench: { text: "Banco da lavoro" },
      semanticIntelligence: { text: "Intelligenza semantica" },
      intelligentAnalysis: { text: "Analisi intelligente" },
      intelligentLabel: { text: "Etichetta intelligente" },
      admin: { text: "Gestione" },
      callManage: { text: "Gestione chiamate" },
      dialogueManage: { text: "Gestione del dialogo" },
      ticketManage: { text: "Gestione degli ordini di lavoro" },
      dataAnalysis: { text: "Analisi dei dati" },
      customerService: { text: "Centro assistenza clienti" },
      clientCenter: { text: "Centro clienti" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Centro di formazione" },
      templates: { text: "Gestione dei modelli" },
      wechatQaTasks: { text: "Banco di lavoro WeChat aziendale" },
      wechatQaSetting: {
        text: "Configurazione dell'analisi di Enterprise WeChat",
      },
      wechatQaChartRecord: { text: "Sessione WeChat Enterprise" },
      wechatStaffCenter: { text: "Micro centro dipendenti aziendale" },
      callAnalysis: { text: "Analisi della conversazione" },
      caseBaseFolder: { text: "Libreria del caso" },
      coachTask: { text: "Compiti di mentoring" },
      teamAnalysis: { text: "Analisi della squadra" },
      customer: { text: "Centro clienti" },
      analysisEnable: { text: "Empowerment dell'analisi" },
      analysisHelp: { text: "Analisi di aiuto" },
      analysisStudy: { text: "Analisi dell'apprendimento" },
      analysisReview: { text: "Analisi del disco composto" },
      analysisTags: { text: "Analisi intelligente dell'etichetta" },
      analysisSentiment: { text: "Analisi dell'opinione pubblica" },
      analysisBusiness: { text: "Analisi aziendale" },
      groupTest: { text: "Test di gruppo" },
    },
    navBar: {
      billingCenter: "Centro assistenza",
      uploadDownload: "Centro di caricamento e download",
      setting: "Impostazioni di sistema",
    },
  },
  fix: {
    selectType: "Seleziona tipo:",
    all: "Tutto",
    call: "Chiama",
    text: "Dialogo",
    wechat: "Sessione WeChat Enterprise",
    businessTrendAnalysis:
      "Analisi delle tendenze aziendali di classificazione di livello 1",
    noEchart: "Nessun dato per il grafico",
    checkPointisRequired: "È richiesta la configurazione delle regole",
    voiceDownLoad: "Scarica la registrazione",
    exportTxt: "Esporta il testo TXT",
    exportWord: "Esporta testo di parole",
    sureReRole: "La conferma inverte il ruolo dell'oratore?",
    sureRecover: "Confermare la ri-correzione?",
    sureReAsr: "Confermare la riidentificazione?",
    reRole: "Altoparlante inversa",
    recover: "Correzione del nuovo errore",
    reAsr: "Riidentificazione",
    recordingOperation: "Operazione di registrazione",
    onlySave30Day: "(Solo 30 giorni)",
    appealError:
      "Si prega di fare appello al punto di controllo della qualità prima di inviarlo.",
    exportToTaskCenter:
      "Esportato con successo nel Centro Caricamento e Download",
    caseBaseLimit: "La libreria dei casi non può superare i 50",
    unnamed: "Senza nome",
    wordSpeed: "Parola/min",
    hit: "Colpisci",
    checkPoint: "Punto di ispezione della qualità",
    realTimeAdditionTip1:
      "Aggiunta in tempo reale: l'ispezione di qualità inizia immediatamente quando i dati entrano nel sistema di ispezione di qualità",
    realTimeAdditionTip2:
      "Timing Aggiungi: il tempismo aggiunge automaticamente i dati per l'ispezione di qualità",
    null: "Nessuno",
    collcetedProps: "Campi di raccolta",
    editingNameNow: "Il nome viene modificato",
    upToFourLevels: "Classificazione fino a quattro livelli",
    default: "Default",
    nameCantBeNull: "Il nome non può essere vuoto",
    editingNow: "L'azione viene modificata",
    nameError: "Nome sbagliato",
    nameMaxLength8: "La lunghezza massima del nome è 8",
    callTime: "Tempo di conversazione",
    sentimentValue: "Valore del sentimento del cliente",
    agentSentimentValue: "Valore del sentiment del servizio clienti",
    sentimentAnalysis: "Analisi del sentimento",
    associatedData: "Dati di follow-the-road",
    notifyTheOwner: "Avvisare il proprietario del gruppo",
    notifyTheEmployee: "Avvisare il dipendente stesso",
    notifyTheOwnerTip:
      "Quando il tipo di conversazione è chat di gruppo, il proprietario del gruppo verrà avvisato.",
    customNotifyEmployee: "Impiegato di notifica personalizzato",
    customNotifyEmployeeTip:
      "Se la persona scatenante è un dipendente, il dipendente a cui appartiene il messaggio verrà informato.",
    clickToLearnMore: "Clicca per saperne di più",
    repeatMarkingTip:
      "Questo record è stato contrassegnato da questo punto di ispezione di qualità e non è necessario ripetere la marcatura.",
    successfulEvaluation: "Valutazione del successo",
    evaluationFailed: "Valutazione non riuscita",
    qualityInspectionScore: "Punteggio di ispezione della qualità",
    semanticTags: "Etichetta semantica",
    smartTags: "Etichetta intelligente",
    collect: "Raccogliere informazioni",
    appealRecord: "Record di Grievance",
    satisfy: "Soddisfatto",
    notSatisfy: "Non soddisfatto",
    appealPlaceHolder: "Inserisci il motivo del ricorso",
    giveUp: "Abbandono",
    rejectReasonPlaceHolder: "Inserisci un motivo per il rifiuto",
    reject: "Rifiutato",
    changeRejectReason: "Rivedere il motivo del rifiuto",
    pass: "Di",
    revise: "Modifica",
    machineEvaluation: "Valutazione della macchina",
    poor: "Povero",
    excellent: "Bene.",
    moderate: "Generale",
    mustMarked:
      "Questo punto di ispezione della qualità deve essere contrassegnato",
    pleaseChoose: "Seleziona",
    manualEvaluation: "Valutazione manuale",
    qualityInspectionTemplate: "Modello di ispezione di qualità applicabile",
    collectionTemplate: "Modello di acquisizione applicabile",
    keywordsWordCloud: "Parole chiave cloud",
    editCategoryLabel: "Modifica etichette di categoria",
    justCall: "Basta guardare la chiamata",
    justConversation: "Basta guardare il dialogo.",
    batchOperation: "Operazione batch",
    customerServicesGroup: "Gruppo di servizio clienti",
    joinGroup: "Unisciti al gruppo",
    removeGroup: "Spostati fuori dal gruppo",
    enterInformationEntity:
      "Inserisci il contenuto dell'entità delle informazioni",
    enterNotBeNull: "L'input non può essere vuoto",
    operationGroup: "Gruppo di operazione",
    customerServiceSelected: "Servizio clienti selezionato",
    callAndConversation: "Chiamata/Conversazione",
    conversation: "Dialogo",
    system: "Sistema",
    customize: "Personalizzato",
    effective: "Efficace",
    invalid: "Non valido",
    successfulAndToTaskCenter:
      "L'esecuzione ha esito positivo, vai al centro attività per controllare",
    recalculationFailed: "Ri-calcolo non riuscito",
    selectRecalculatedIndicator: "Seleziona l'indicatore per ricalrare",
    source: "Seleziona un'origine dati",
    placeholder: "Inserisci una parola chiave",
    cumulative: "Cumulativo",
    average: "Tipo medio",
    distribution: "Legge di distribuzione",
    originalDataCannotEmpty: "I dati grezzi non possono essere vuoti",
    inspection: "Ispezione di qualità",
    check: "Ispezione casuale",
    review: "Revisione",
    draftSavedSuccessfully: "Bozza salvata correttamente",
    optimization: "Ottimizzazione",
    agentName: "Nome del servizio clienti",
    allTasks: "Tutte le attività di ispezione di qualità",
    datePickerSelect: "Seleziona data",
    callSelect: "Seleziona dialogo",
    taskSelect: "Seleziona attività",
    genReport: "Genera rapporto",
    dataSource: "Fonte dati",
    callsTotal: "Totale chiamate",
    intelligentQualityInspection: "Ispezione di qualità intelligente",
    percentage: "Proporzione",
    artificialSampling: "Campionamento manuale",
    appealNumber: "Importo delle denunce",
    totalConversation: "Importo totale del dialogo",
    samplingReviewRate: "Tasso di revisione del pompaggio",
    data: "Dati",
    inspectionResults: "Risultati delle ispezioni di qualità",
    inspected: "Ispezione di qualità",
    totalScore: "Punteggio totale",
    learnMore: "Visualizza altro",
    inspectionEffect: "Effetto di ispezione di qualità",
    allTotal: "Totale",
    manualInspection: "Revisione manuale",
    artificialReview: "Revisione manuale",
    approvalAmountAndRate: "Quantità e tasso di approvazione approvati",
    customerScore: "Punteggio del servizio clienti",
    inspectionRecommendations: "Suggerimenti per l'ispezione della qualità",
    saveDraft: "Salva bozza",
    historicalAdvice: "Consulenza storica",
    advicePlaceHolder: "Inserisci un suggerimento...",
    upload: "Caricamento file",
    customAdd: "Aggiungi filtro",
    advancedConditions: "Condizioni avanzate",
    keyWord: "Parole chiave",
    containsAll: "Contiene tutti",
    containsAny: "Contiene qualsiasi",
    matchAny: "Abbina qualsiasi",
    matchAll: "Abbina tutto",
    smartTagsOne: "Etichetta intelligente",
    smartTagsTwo: "Etichetta intelligente II",
    season: "Stagione",
    month: "Mese",
    week: "Settimana",
    day: "Giorno",
    hour: "Quando",
    notMoreThan366: "Non più di 366 giorni.",
    propsCannotBeNull: "Alcuni campi non sono compilati",
    other: "Altro",
    correlationCoefficient: "Coefficiente di correlazione",
    repetitionRate: "Tasso di ripetizione",
    configurationItemsMoreThan20:
      "Aggiungi fino a 20 articoli di configurazione",
    conversionAnalysis: "Analisi della trasformazione",
    correlationAnalysis: "Analisi della correlazione",
    successfulExportAndToTaskCenter:
      "Esportazione riuscita, vai al centro attività per visualizzarlo",
    typeSelect: "Seleziona tipo",
    exportExcel: "Esporta Excel",
    exportPng: "Esportazione png",
    noDataChart: "Nessun dato per il grafico",
    placeOperator: "Seleziona un'etichetta intelligente",
    placeAdvancedConditions: "Seleziona criteri avanzati",
    createAdvancedConditions: "Nuovo filtro avanzato",
    moveUp: "Spostati",
    moveDown: "Spostati verso il basso",
    moveLeft: "Spostati a sinistra",
    moveRight: "Muoviti a destra",
    statusChangeSuccess: "Stato modificato correttamente",
    editSuccess: "Modifica riuscita",
    saveSuccess: "Salvataggio riuscito",
    firstPage: "Home Page",
    lastPage: "Pagina della coda",
    customerInfo: "Informazioni sul cliente",
    type: "Tipo",
    NumberTypeErrorMessage:
      'Il contenuto delle informazioni sul campo del tipo di numero può contenere solo numeri o "."',
    taskStartPrompt: "Prompt di apertura delle attività",
    startConfirm:
      "Si prega di fare clic sul pulsante di conferma per aprire l'attività di ispezione di qualità",
    continueToSubmitWithoutEvaluation:
      "Continua la presentazione senza valutazione",
    rejectTip:
      "Non sono state rilevate azioni di modifica, sei sicuro di voler respingere direttamente tutti i ricorsi?",
    unmarkedSelfLearningTip:
      "Non hai contrassegnato punti di controllo della qualità dell'autoapprendimento",
    markedSuccessfully: "Etichettatura riuscita",
    markingFailed: "Etichettatura non riuscita",
    exceedingLength: "Lunghezza del superamento",
    uncategorized: "Non classificato",
    addSuccess: "Nuovo successo",
    deleteSuccess: "Eliminazione riuscita",
    featureName: "Nome dell'elemento",
    informationEntity: "Entità dell'informazione",
    standardAnswer: "Risposta standard",
    keyElementAnswer: "Elementi chiave per rispondere",
    generalStatement: "Dichiarazione generale (non c' è bisogno di chiedere)",
    generalQuestionsAndConfirmation: "Domande generali e conferma",
    specialQuestionsAndAnswers: "Domande e risposte speciali",
    agent: "Servizio clienti",
    customer: "Cliente",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "L'etichetta semantica dell'elemento chiave e l'entità delle informazioni non possono essere vuote contemporaneamente",
    standardSentence: "Frase standard",
    acquaintance: "Conoscenza",
    smartUePlaceholder: "Inserisci un nome di interazione intelligente",
    typeSelectPlaceholder: "Seleziona il tipo",
    keyElement: "Elementi chiave",
    reQuerySementicTags: "Riseleziona le etichette semantiche",
    querySementicTags: "Seleziona etichetta semantica",
    QATestForm: "Modulo di rilevamento di domande e risposte",
    selectNotBeNull: "La selezione non può essere vuota",
    featureDetectionTarget: "Obiettivo di rilevamento dell'elemento",
    categorized: "Classificazione",
    effectiveness: "Efficacia",
    hideAdvancedSettings: "Nascondi impostazioni avanzate",
    showAdvancedSettings: "Mostra impostazioni avanzate",
    generalStatementOnly: "DICHIARAZIONE GENERALE",
    generalQuestions: "Dubbio Generale",
    affirmativeExpression: "Espressione affermativa",
    negativeIntention: "Intenzione negativa",
    searchSementicTags: "Etichette semantiche di query",
    choiceQuestion: "Frase interrogativa selettiva",
    selectiveAnswer: "Risposta tipo scelta",
    sementicTagsPlaceholder: "Inserisci il contenuto dell'etichetta semantica",
    openSpecialQuestion: "Domande speciali aperte",
    openAnswer: "Risposta aperta.",
    customQA: "Domande e risposte personalizzate",
    questions: "Metodo di domanda",
    dataPlaceholder: "Inserisci il contenuto",
    reply: "Risposta",
    notFindSuitableLabel: "Non è stata trovata alcuna etichetta adatta.",
    addSemanticTag: "Aggiungere un nuovo tag semantico",
    intelligentInteraction: "Interazione intelligente",
    matching: "Partita",
    bout: "Tempi",
    keywordMatch: "Corrispondenza delle parole chiave",
    addFilterConditions: "Aggiungi la condizione del filtro",
    buttonStartCollectionTask:
      "Fare clic sul pulsante OK per avviare l'attività di raccolta",
    buttonStartTask:
      "Fare clic sul pulsante OK per aprire l'attività di ispezione della qualità",
    ruleConfigurationIsRequired: "È richiesta la configurazione delle regole",
    asrRunning: "Esecuzione ASR",
    errorCorrectionExecution: "Esecuzione della correzione degli errori",
    emotionalVolumeRecognition: "Riconoscimento del volume delle emozioni",
    dataPreprocessing: "Preelaborazione dei dati",
    inspectionExecution: "Esecuzione dell'ispezione di qualità",
    businessId: "ID azienda",
    businessTime: "Orario aziendale",
    createTime: "Tempo di creazione",
    abnormalReason: "Ragione anormale",
    singleChat: "Chat singola",
    groupChat: "Chat di gruppo",
    taskContent: "Contenuto attività",
    submissionTime: "Ora di invio",
    startTime: "Ora di inizio",
    endTime: "Ora di fine",
    runningTime: "Operazione che richiede tempo",
    totalAmountOfData: "Volume totale dei dati",
    resultsOfThe: "Risultati dell'esecuzione",
    operator: "Operatore",
    taskDetails: "Dettagli attività",
    executiveFunction: "Funzione esecutiva",
    qualityCheckTasks: "Compito di ispezione di qualità",
    implementationDetails: "Dettagli esecuzione",
    conditionalRules: "Regole di condizione",
    smartCondition: "Condizioni intelligenti",
    goThis: "Spostati in questo",
    pleaseMarkClassification: "Si prega di contrassegnare la classificazione",
    advancedScreening: "Filtraggio avanzato",
    noDataToManipulate: "Nessun dato da manipolare",
    concept: "Concetto parole",
    checkPoint1: "Configurazione delle regole",
    requiredFieldsCannotEmpty: "L'articolo richiesto non può essere vuoto",
    rule: "Regole",
    afterFixUnit: "Parola",
    unmarkedSelfLearningPointsTip:
      "Non hai contrassegnato punti di controllo della qualità dell'autoapprendimento",
    onlyReservedFor7Days: "(Solo 7 giorni)",
    downloadError: "Download non riuscito",
    pause: "Pausa",
    continue: "Continua",
    recalcScore: "Ricalcola solo i punteggi (con valutazioni)",
    recalculatePointsAndScores:
      "Ricalcolare i punti e i punteggi di ispezione della qualità",
    recalculatePointsAndScoresWithEmtry:
      "Ricalcolare i punti e i punteggi di ispezione di qualità (i punti di ispezione di qualità sono vuoti)",
    inProgress: "In corso",
    toBeExecuted: "Da implementare",
    executeImmediately: "Esecuzione immediata",
    specialElements: "Elementi speciali",
    logicRule: "Regole logiche",
    every: "Partite multiple",
    hitRecall: "Premi la parola di richiamo",
    notHitRecall: "Parola di richiamo mancata",
    hitRecallRegular: "Richiamo regolare",
    notHitRecallRegular: "Mancato regolare",
    hitRule: "Regole di successo",
    notHitRule: "Regola mancata",
    hitSimilarSentence: "Colpisci frasi simili",
    notHitSimilarSentence: "Frasi simili mancate",
    hitExcludeSimilarSentences: "Colpito per escludere frasi simili",
    notHitExcludeSimilarSentences: "Mancato escludere frasi simili",
    updateSuccess: "Aggiornamento riuscito",
    qualify: "Regolare",
    recallKeyWord: "Richiedi parole chiave",
    grammar: "Regole grammaticali",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Modifica della validità non riuscita, riprova",
    postAutoMateTasksCaseByTaskId:
      "Impossibile salvare la configurazione di sincronizzazione della libreria dei casi",
    getAutoMateTasksCaseByTaskId:
      "Impossibile ottenere la configurazione della sincronizzazione della libreria dei casi",
    postInspectionRemarkModelConfigs:
      "Impossibile salvare il modello di commento",
    putInspectionRemarkModelConfigsById:
      "Impossibile salvare il modello di commento",
    getInspectionRemarkModelConfigsById:
      "Impossibile ottenere i dati del modello di commento",
    postKnowledgeDatas: "Creazione della Knowledge Base non riuscita, riprova",
    putKnowledgeDatasById: "Modifica del repository non riuscita, riprova",
    getLibraryFiles: "Impossibile ottenere l'elenco delle cartelle dei prezzi.",
    getLibraryCalls: "Impossibile ottenere il file",
    getLibraryFilesSearch: "Ricerca non riuscita",
    putLibraryFilesMoveById: "Impossibile spostare la cartella dei prezzi.",
    putLibraryCallsById: "Rinomina file non riuscita!",
    postLibraryFiles: "Impossibile aggiungere la cartella",
    putLibraryFilesById: "Rinomina cartella non riuscita",
    deleteLibraryFilesById: "Eliminazione non riuscita",
    deleteLibraryCallsById: "Eliminazione non riuscita",
    postLibraryCalls: "Aggiunte non riuscite",
    getVoiceUploadResultById:
      "Esportazione del risultato del caricamento non riuscita",
  },
};
