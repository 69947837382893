import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { Modal } from 'udesk-ui';
export default class TaskBasicInfoComponent extends React.Component {
    privates = {
        fetching: false,
        searchValue: '',
        searchUserList: [],
        qualityUserSeleteList: [],
        isEdit: false,
        inspectDataSource: null,
        remark: '',
        name: '',
        qualityUser: null,
        inspectDataSources: [],
        loading: false,
        isWechatQa: false,
        related: Udesk.enums.inspectType.commonInspect.id,
        sessionType: Udesk.enums.chatTypes.singleChat.id * 1,
        formRef: React.createRef(),
        conditions: [],
        period: 7,
        associateRegulation: {},
        isInfoCollect: false,
    };
    state = {
        inspectorList: [],
    };
    static computes = {
        // tasks: [
        //     "model.tasks.data",
        //     function ({ props, state, privates, locales }) {
        //         return (
        //             //FIXME: [手动迁移] this.state中不存在属性audioCallCetailsNavSelected，请手动检查。
        //             this.get("states.audioCallCetailsNavSelected") === "audioText"
        //         );
        //     }
        // ],
        _autoCompleteUrl: function ({ props, state, privates, locales }) {
            return Udesk.business.apiPath.concatApiPath('/users?pageSize=1000', props.sdkOptions);
        },
    };
    //#region actions
    actions = {
        userListChanged(values) {
            this.setState({
                inspectorList: values,
            });
        },
        save(params) {
            let id = this.privates.model.task.id;
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + id, this.props.sdkOptions);
            this.privates.loading = true;
            this.actions.update();
            Udesk.ajax.put(url, params).then(
                (resp) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.saveSuccess);
                    this.actions.reloadModel();
                },
                (reason) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.info.saveError);
                }
            );
        },

        deleteTask() {
            let id = this.privates.model.task.id;
            const taskType = this.privates.model.task.taskType;
            let onConfirm = () => {
                Udesk.ajax
                    .del(Udesk.business.apiPath.concatApiPath(`/inspectionTasks/${id}${taskType === Udesk.enums.taskType.informationCollection.id ? '/informationAcquisitionTask' : ''}`, this.props.sdkOptions))
                    .then(
                        (resp) => {
                            Udesk.ui.notify.success(UdeskLocales.current.business.info.deleteSuccess);
                            let routeOptions = {
                                history: this.props.history,
                                routeName: 'tasksIndex',
                            };
                            if (this.privates.isWechatQa) {
                                routeOptions.routeName = 'wechatQaTasksIndex';
                            }
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.info.deleteFailed);
                        }
                    );
            };

            Modal.confirm({
                content: Udesk.utils.string.format(
                    UdeskLocales.current.business.info.deleteWarning,
                    UdeskLocales.current.pages.tasks.index.qualityCheckTasks
                ),
                okText: UdeskLocales.current.labels.delete,
                cancelText: UdeskLocales.current.labels.cancel,
                onOk: onConfirm,
            });
        },
        gotoTask() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'tasksIndex',
                state: {
                    taskType: this.props.sdkOptions.props?.task?.taskType === Udesk.enums.taskType.informationCollection.id ? Udesk.enums.taskType.informationCollection.id : Udesk.enums.taskType.qualityCheckTasks.id,
                }

            };
            if (this.privates.isWechatQa) {
                routeOptions = {
                    history: this.props.history,
                    routeName: 'wechatQaTasksIndex',
                };
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        goToCheckPoint(id) {
            // let url = Udesk.business.apiPath.concatApiPath(
            //     "/inspectionTasks/" + id,
            //     this.props.sdkOptions
            // );
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${id}`,
                this.props.sdkOptions
            );
            if (!this.privates.isWechatQa) {
                Udesk.ajax.put(url, { processStatus: 2 }).then(
                    (resp) => {},
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                        );
                    }
                );
            }
            new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + id, this.props.sdkOptions);
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                        this.props.sdkOptions.props.task = resp.data;
                        let routeOptions = {
                            history: this.props.history,
                            routeName: 'tasksCheckPointCreateEntry',
                            pathParams: {
                                taskId: id,
                            },
                        };
                        if (this.privates.isWechatQa) {
                            routeOptions.routeName = 'wechatQaCheckPointEntry';
                        }
                        Udesk.ui.routing.transitionTo(routeOptions);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                        );
                        reject(reason);
                    }
                );
            });
        },
        goToTicketPoint(id) {
            // let url = Udesk.business.apiPath.concatApiPath(
            //     "/inspectionTasks/" + id,
            //     this.props.sdkOptions
            // );
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${id}`,
                this.props.sdkOptions
            );
            if (!this.privates.isWechatQa) {
                Udesk.ajax.put(url, { processStatus: -1 }).then(
                    (resp) => {},
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                        );
                    }
                );
            }
            new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + id, this.props.sdkOptions);
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                        this.props.sdkOptions.props.task = resp.data;
                        let routeOptions = {
                            history: this.props.history,
                            routeName: 'tasksCheckPointIndex',
                            pathParams: {
                                taskId: id,
                            },
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                        );
                        reject(reason);
                    }
                );
            });
        },
        goToCollectionPoint(id) {
            // let url = Udesk.business.apiPath.concatApiPath(
            //     "/inspectionTasks/" + id,
            //     this.props.sdkOptions
            // );
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${id}`,
                this.props.sdkOptions
            );
            if (!this.privates.isWechatQa) {
                Udesk.ajax.put(url, { processStatus: 2 }).then(
                    (resp) => {},
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                        );
                    }
                );
            }
            new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + id, this.props.sdkOptions);
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                        this.props.sdkOptions.props.task = resp.data;
                        let routeOptions = {
                            history: this.props.history,
                            routeName: 'informationCollectPointIndex',
                            pathParams: {
                                taskId: id,
                            },
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                        );
                        reject(reason);
                    }
                );
            });
        },
        fetchUser(value) {
            this.privates.searchValue = value;
            let url = Udesk.business.apiPath.concatApiPath(`/users?keyword=${value}`, this.props.sdkOptions);
            let params = {
                pageSize: 1000,
            };
            Udesk.ajax.get(url, params).then(
                (resp) => {
                    this.privates.searchUserList = resp.data;
                    this.privates.fetching = false;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                            )
                    );
                }
            );
        },
        handleSubmit(params) {
            this.privates.loading = true;
            this.actions.update();
            Udesk.ajax
                .post(Udesk.business.apiPath.concatApiPath(`/inspectionTasks`, this.props.sdkOptions), params)
                .then(
                    (resp) => {
                        Udesk.ui.notify.success(UdeskLocales.current.business.info.creatSuccess);
                        this.privates.loading = false;
                        this.actions.update();
                        if([Udesk.enums.inspectDataSources.ticket.id, Udesk.enums.inspectDataSources.taobao.id].includes(params.inspectDataSource)){
                            this.actions.goToTicketPoint(resp.data.id);
                        }else if(!this.privates.isInfoCollect){
                            this.actions.goToCheckPoint(resp.data.id);
                        }else{
                            this.actions.goToCollectionPoint(resp.data.id);
                        }
                    },
                    (reason) => {
                        this.privates.loading = false;
                        this.actions.update();
                        Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.info.creatError);
                    }
                );
        },
        close() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'tasksIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        onFormValuesChange(changedValues, allValues) {
            this.privates.inspectDataSource = allValues.inspectDataSource;
            this.privates.conditions = (allValues.associateRegulation?.conditionList ?? []).map((item, i) => ({
                ...item,
                order: i + 1,
            }));
            this.actions.update();
        },
        onFinish(values) {
            const { logic, inspectorList, associateRegulation, ...args } = values;
            let params = {
                ...args,
            };
            params.taskType = Udesk.enums.taskType[this.privates.isInfoCollect ? 'informationCollection' : 'qualityCheckTasks']?.id;

            if (associateRegulation && logic?.judgeStrategy) {
                params.associateRegulation = {
                    conditionList: (associateRegulation?.conditionList ?? [])
                        .filter((i) => i.id || i.fieldName)
                        .map((i) => ({
                            field: i,
                        })),
                    ...logic,
                };
            }
            params.inspectorIds = Array.isArray(inspectorList) ? inspectorList.map((i) => i.id) : inspectorList;
            if (this.privates.isEdit) {
                this.actions.save(params);
            } else {
                this.actions.handleSubmit(params);
            }
        },
        submit() {
            this.privates.formRef?.current?.submit?.();
        },
    };
    //#endregion
    init() {
        this.privates.isWechatQa = ['wechatQaCreateBaseInfo', 'wechatQaBaseInfo'].includes(this.props.route.name);
        this.privates.isInfoCollect = ['informationCollectionBasicInfoCreateIndex', 'informationCollectionIndex'].includes(this.props.route.name);
    }
    onModelResolved(model) {
        let company = Udesk.data.init.company;
        this.privates.inspectDataSources = Udesk.business.task.getInspectDataSources(this.privates.isWechatQa, company, this.privates.isInfoCollect);

        if (!this.privates.inspectDataSource) {
            this.privates.inspectDataSource = this.privates.inspectDataSources[0].id;
        }
        this.privates.formRef?.current?.resetFields?.();
    }
    componentDidMount() {
        this.privates.formRef?.current?.resetFields?.();
    }
}
