function tableDataFormat(dataSourceProps, keyName) {
    let correctness = true;
    dataSourceProps.forEach(item => {
        if(Object.keys(item).some(key => key === keyName) === false){
            correctness = false;
        }
    });
    if(!correctness){
        throw new Error(
            `keyName is not in dataSource.`
        );
    }

    let dataSource = [];
    dataSourceProps.forEach(item => {
        let fieldValue = item.fieldValueMap;
        fieldValue.key = item[keyName];
        dataSource.push(fieldValue);
    });
    return dataSource;
}

export default {
    tableDataFormat
};
