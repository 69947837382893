import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, message, Popover, Space } from 'udesk-ui';
import styled from 'styled-components';
import { postIntelligentPartnerWordsManualCorrect } from 'src/api/intelligentPartner/words/manual/correct/index';
import { hasFeature } from 'src/util/permit';
import UdeskLocales from 'UdeskLocales';

type UseCorrectBtnProps = {
    content?: string;
    recordId?: number;
    sentenceId?: number;
};

export const useCorrectBtn = (props: UseCorrectBtnProps) => {
    const { content, recordId, sentenceId } = props;

    const [contentText, setContentText] = useState<string>(content || '');
    useEffect(() => {
        setContentText(content || '');
    }, [content]);

    const [correctVisible, setCorrectVisible] = useState(false);
    const [form] = Form.useForm();

    const onCorrect = () => {
        setCorrectVisible(true);
        form.setFieldsValue({ content: contentText });
    };

    const onCancel = () => {
        setCorrectVisible(false);
    };

    const [loading, setLoading] = useState(false);

    const onCorrectSave = (values) => {
        const { content } = values;
        setLoading(true);

        postIntelligentPartnerWordsManualCorrect({ recordId, sentenceId, correctWords: content })
            .then(() => {
                setContentText(content);
                message.success(/* 保存成功 */UdeskLocales['current'].components.coach.dialogue.components.correctBtn.saveSuccessful);
            })
            .finally(() => {
                setLoading(false);
                setCorrectVisible(false);
            });
    };

    return {
        contentText,
        correctVisible,
        form,
        onCorrectSave,
        onCancel,
        loading,
        onCorrect,
    };
};

const OperationWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

type CorrectBtnProps = Omit<ReturnType<typeof useCorrectBtn>, 'contentText'>;

export const CorrectBtn: FC<CorrectBtnProps> = (props) => {
    const { correctVisible, form, onCorrectSave, onCancel, loading, onCorrect } = props;

    return (
        <>
            {hasFeature('sys:setting:correctWord') ? (
                <Popover
                    title={/* 纠错 */UdeskLocales['current'].components.coach.dialogue.components.correctBtn.errorCorrection}
                    visible={correctVisible}
                    trigger="click"
                    content={
                        <Form form={form} onFinish={onCorrectSave}>
                            <Form.Item name="content">
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item noStyle>
                                <OperationWrapper>
                                    <Space>
                                        <Button size="small" onClick={onCancel}>{/* 取消 */}{UdeskLocales['current'].components.coach.dialogue.components.correctBtn.cancel}</Button>
                                        <Button
                                            type="primary"
                                            size="small"
                                            htmlType="submit"
                                            loading={loading}
                                            disabled={loading}
                                        >{/* 保存 */}{UdeskLocales['current'].components.coach.dialogue.components.correctBtn.preserve}</Button>
                                    </Space>
                                </OperationWrapper>
                            </Form.Item>
                        </Form>
                    }
                >
                    <Button type="link" onClick={onCorrect}>{/* 纠错 */}{UdeskLocales['current'].components.coach.dialogue.components.correctBtn.errorCorrection}</Button>
                </Popover>
            ) : null}
        </>
    );
};
