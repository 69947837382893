// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostStorageCreateResourceBody, BaseResponsestring } from '../../types';

/**
 * createResourceByKeyName
 *
 * @export
 * @tags 云存储
 * @link [POST] /storage/createResource
 */
export function postStorageCreateResource(
    data: PostStorageCreateResourceBody
): Promise<BaseResponsestring>;
export function postStorageCreateResource(
    data: PostStorageCreateResourceBody,
    options: ApiOptions<never, never>
): Promise<BaseResponsestring>;
export function postStorageCreateResource(
    data: PostStorageCreateResourceBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponsestring> {
    return request<PostStorageCreateResourceBody, never, never>('/storage/createResource', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postStorageCreateResource',
    });
}

export const meta = [{ tags: ['云存储'], path: '/storage/createResource', method: 'post' }];
