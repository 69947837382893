export const createNode = (data, position) => {
    return {
        id: `${Math.random()}`,
        data,
        type: 'cusNode',
        position,
    };
};

export const cloneNode = (node, parentNodeId?) => {
    return {
        ...node,
        id: `${Math.random()}`,
        data: {
            ...node.data,
            preId: parentNodeId,
        },
        selected: false,
        parentNode: parentNodeId,
        position: parentNodeId
            ? node.position
            : {
                x: node.position.x + 20,
                y: node.position.y + 20,
            },
    };
};

export function findAllSelectedBranch(nodes: any, currentSelectedNode: any) {
    return nodes.filter((n) => n.data.preId === currentSelectedNode.id && n.data.type === 'BRANCH');
}