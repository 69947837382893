import React, { useState, useMemo, useCallback, useImperativeHandle } from 'react';
import Udesk from 'Udesk';
import UdeskList from 'Component/common/udesk-list';
import ConfigDrawer from './configDrawer';
import ExportButton from 'Component/common/export-button';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { Select, Input, Modal, Form, message, Icon, Space } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { fire } from 'src/util/core';
import '../index.scss';
import UdeskLocales from 'UdeskLocales';

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};

const Words = React.memo((props: { 
    value?: string[]; 
    onChange?: (data: string[]) => void;
    locales: any;
}) => {
    const { onChange, value: data = [], locales } = props;

    const addItem = useCallback(() => {
        const temp = [
            ...data,
            '',
        ];
        onChange?.(temp);
    }, [
        data, 
        onChange
    ]);

    const delItem = useCallback((index: number) => {
        const temp = [...data];
        temp.splice(index, 1);
        onChange?.(temp);
    }, [
        data, 
        onChange
    ]);

    const updateItem = useCallback((value, index) => {
        onChange?.(data.map((item, dataIndex) => {
            if (dataIndex === index) {
                return value;
            }
            return item;
        }));
    }, [
        data,
        onChange
    ]);

    return (
        <div className={data && data.length === 1 ? 'hidden-delete-button': ''}>
            <UdeskList addBtnText={/* 新增话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.newScript} addItem={addItem} deleteItem={delItem} addBtnDisabled={data.length > 9999}>
                {data.map((value, index) => (
                    <Input.TextArea
                        className={value && value.length > 0 ? 'ok' : ''}
                        rows={6}
                        maxLength={2000} 
                        maxCount={2000}
                        showCount={true}                        
                        value={value}
                        onChange={(e) => updateItem(e.target.value, index)}/>
                ))}
            </UdeskList>
        </div>
    );
});


const EditModal = React.forwardRef((props: any, ref) => {
    const {locales, scenario, request, groupId, groupDataSource, onSuccess} = props;
    const [form] = Form.useForm();
    const [title, setTitle] = useState('');
    const [visible, setVisible] = useState(false);
    const [callback, setCallback] = useState();
    const [isDisable, setIsDisable] = useState(false);

    const open = useCallback((item, callback) => {
        if (item) {
            item.key = item.id;
            item.customerWord = Array.isArray(item.customerWord) ? item.customerWord.join() : item.customerWord;
            form.setFieldsValue(item);
        } else {
            form.setFieldsValue({
                key: undefined,
                subjectId: undefined,
                groupId,
                customerWord: '',
                seatsWord: ['']
            });
        }
        setTitle(item ? /* 编辑话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.editingScript : /* 新建话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.newScript);
        setVisible(true);
        setIsDisable(!!item);
        setCallback(() => callback);
    }, [
        locales,
        form, 
        groupId
    ]);

    const channelHandle = useCallback(() => setVisible(false), []);

    const submitHandle = useCallback(({key: id, customerWord, ...values}) => {
        const url = `/review/words/accept/${id ? id : 'save'}`;
        const method = id ? 'put' : 'post';
        const params = {
            id,
            customerWord: [customerWord],
            scenario,
            ...values
        };
        request(url, params, method).then((r) => {
            onSuccess(method === 'post' ? r.data : params);
            channelHandle();
            fire(callback, params);
            message.success(/* 保存成功 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.savingSucceeded);
        });
    }, [
        locales,
        scenario,
        channelHandle, 
        onSuccess,
        request,
        callback
    ]);

    useImperativeHandle(ref, () => ({
        open
    }));

    return (
        <Modal
            wrapClassName="words-mining-favorite-edit-dialog beautiful-scrollbar"
            title={title}
            visible={visible}
            onOk={form.submit}
            onCancel={channelHandle}
        >
            <Form 
                form={form} 
                onFinish={submitHandle}    
                labelCol={{span: 3}}
                wrapperCol={{span: 20}}
            >
                <Form.Item name='key' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='subjectId' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name='customerWord'
                    label={/* 异议 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.objection}
                    rules={[
                        { required: true, message: /* 请输入话术异议 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.pleaseEnterAScriptObjection }
                    ]}
                >
                    <Input disabled={isDisable}/>
                </Form.Item>
                <Form.Item
                    name='seatsWord'
                    label={/* 话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.speechTechnique}
                    rules={[
                        { required: true, message: /* 请输入话术内容 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.pleaseEnterTheScriptContent },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value) {
                                    for(let item of value) {
                                        if (!item) {
                                            return Promise.reject(/* 请输入话术内容 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.pleaseEnterTheScriptContent);
                                        }
                                    }
                                }
                                return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Words locales={locales} />
                </Form.Item>
                <Form.Item 
                    name='groupId'
                    label={/* 分组 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.grouping}
                    rules={[
                        { required: true, message: /* 请选择收藏夹分组 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.pleaseSelectAFavoriteGroup },
                    ]}>
                    <Select 
                        showSearch={true}
                        optionFilterProp="label"
                        options={
                            groupDataSource.map(item => ({
                                label: item.name,
                                value: item.id,
                            }))
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default React.memo((props: any) => {
    const { selectedRowKey: groupId, scenario, groupDataSource, locales, SmartToolBarRef } = props;
    const [pagination, setPagination] = useState(defaultPagination);
    const [tableData, setTableData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [exportData, setExportData] = useState<any[]>([]);
    const request = useRequest();
    const modalRef = React.useRef<any>();
    const drawerRef = React.useRef<any>();

    const ajaxTableData=  useCallback((map) => {
        setLoading(true);
        return request(`review/words/accept`, map).then(
            (resp) => {
                const { pageNum, pageSize } = map;
                const pagination = {
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum || pageNum,
                    pageSize: resp.paging?.pageSize || pageSize,
                };
                setTableData(resp.data ?? []);
                setPagination(pagination);
            }
        ).finally(() => {
            setLoading(false);
        });
    }, [
        request
    ]);

    const onRequest = useCallback((params) => {
        const {pagination}  = params;
        ajaxTableData({
            groupId,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
        });
    }, [
        ajaxTableData, 
        groupId
    ]);

    const openModel = useCallback(item => modalRef.current.open(item, () => {
        ajaxTableData({
            groupId,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
        });
    }), [
        ajaxTableData, 
        groupId, 
        pagination
    ]);

    const openDrawer = useCallback(item => drawerRef.current.open(item), []);

    useLiveEffect(() => {
        if (groupId) {
            const params = {
                pageSize: pagination.pageSize,
                current: 1,
                total: 0,
            };
            setPagination(params);
            ajaxTableData({
                groupId,
                pageNum: params.current,
                pageSize: params.pageSize,
            });
        }
    }, [
        groupId
    ]);
    
    const saveSuccessHandle = () => {};

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 采纳话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.adoptScripts,
                key: 'name',
                dataIndex: 'name',
                render(name, record) {
                    return (
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <div className='message'>
                                <span className="user-type customer">{/* 客 */}{locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.passenger}</span>
                                <p>{record.customerWord}</p>
                            </div>
                            {
                                record.seatsWord.map(item => (
                                    <div className='message'>
                                        <span className="user-type">{/* 坐 */}{locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.sit}</span>
                                        {
                                            item.length > 200 ? (
                                                <p title={item}>{item.slice(0, 200)}...</p>
                                            ) : (
                                                <p>{item}</p>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </Space>
                    );
                }
            },
            {
                title: /* 查看次数 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.numberOfViews,
                key: 'checkNum',
                dataIndex: 'checkNum'
            },
            {
                title: /* 有用 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.useful,
                key: 'likeNum',
                dataIndex: 'likeNum',
            },
            {
                title: /* 操作 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.operation,
                key: 'actions',
                renderType: 'actions',
                buttons: [
                    {
                        label: /* 编辑 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.edit,
                        onClick: openModel,
                    },
                    {
                        label: /* 删除 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.delete,
                        danger: true,
                        onClick: (item) => {
                            request(`/review/words/accept/${item.id}`, {}, 'del').then(() => {
                                message.success(/* 删除成功 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.deletionSucceeded);
                                let {pageSize, current, total} = pagination;
                                if (current > 1) {
                                    if ((total - 1) / pageSize < current) {
                                        current = current - 1;
                                    }
                                }
                                ajaxTableData({
                                    groupId,
                                    pageNum: current,
                                    pageSize,
                                });
                            });
                        },
                    },
                ].filter(Boolean),
            },
        ];
        return {
            loading,
            columns,
            dataSource: tableData,
            pagination,
            rowSelectionEnhance: {
                onSelectedRowKeysChange: (keys: any[], items: any[]) => {
                    setExportData(items);
                },
            },
        };
    }, [
        locales,
        loading,
        groupId,
        openModel,
        tableData, 
        pagination, 
        request,
        ajaxTableData
    ]);

    const exportResult = () => {
        return new Promise((resolve, reject) => {
            if (exportData.length === 0) {
                resolve([]);
                Udesk.ui.notify.error(/* 请选择采纳话术 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.dissent.favorite.components.pageRightTable.index.pleaseChooseToAdoptScript);
            } else {
                Modal.confirm({
                    title: Udesk.utils.string.format(
                        locales.labels.exportConfirmText.export,
                        exportData?.length || 0
                    ),
                    icon: <Icon type='ExclamationCircleOutlined' antdIcon={true} />,
                    maskClosable: true,
                    okText: locales.labels.export,
                    onOk() {
                        request(`review/words/accept/export/${groupId}?systemModule=${Udesk.enums.sysModuleList.sales.id}`, exportData.map((i) => i.id), 'post').then(
                            resp => {
                                resolve(exportData);
                                ExportButton.exportSuccessNotification(() => {
                                    window.open('/sales/admin/task-center/download-list');
                                });
                            }
                        );
                    },
                    onCancel() {
                        reject();
                    },
                });
            }
            
        });
    };

    return (
        <div className='words-mining-favorite-page-right'>
            <StandardizeTable.RequestTable
                autoRequestOnMount={false}
                onRequest={onRequest}
                table={tableProps}
                toolBarRightButtons={[
                    {
                        label: /* 话术推荐设置 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.scriptRecommendationSettings,
                        icon: <Icon type='SettingOutlined' antdIcon={true} />,
                        onClick: openDrawer,
                        mainBtn: true,
                    },
                    {
                        label: /* 新建话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.newScript,
                        icon: <Icon type='PlusOutlined' antdIcon={true} />,
                        onClick: () => openModel(undefined),
                    },
                ].filter(Boolean)}
                exportBtn={{
                    noSecondConfirmation: true,
                    onClick: exportResult,
                    confirm: {
                        title: /* 是否导出当前分组下所有的数据记录？ */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.doYouWantToExportAllDataRecordsUnderTheCurrentGroup,
                    },
                }}
            />
            <ConfigDrawer 
                ref={drawerRef}
                locales={locales} 
                request={request} 
                scenario={scenario}
                selectedRowKey={groupId}
                groupDataSource={groupDataSource} 
                SmartToolBarRef={SmartToolBarRef}/>
            <EditModal 
                scenario={scenario}
                ref={modalRef}
                locales={locales} 
                request={request} 
                groupId={groupId} 
                groupDataSource={groupDataSource}
                onSuccess={saveSuccessHandle}/>
        </div>
    );
});
