// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListKnowledgeCategoryFoundResponse } from '../../types';

/**
 * initTree
 *
 * @export
 * @tags knowledge-category-controller
 * @link [GET] /knowledgeCategorys/initTree
 */
export function getKnowledgeCategorysInitTree(): Promise<BaseResponseListKnowledgeCategoryFoundResponse>;
export function getKnowledgeCategorysInitTree(
    options: ApiOptions<never, never>
): Promise<BaseResponseListKnowledgeCategoryFoundResponse>;
export function getKnowledgeCategorysInitTree(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListKnowledgeCategoryFoundResponse> {
    return request<never, never, never>('/knowledgeCategorys/initTree', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getKnowledgeCategorysInitTree',
    });
}

export const meta = [
    {
        tags: ['knowledge-category-controller'],
        path: '/knowledgeCategorys/initTree',
        method: 'get',
    },
];
