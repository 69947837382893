const ACTION_BEHAVIORS = [
    {
        name: "preventDefault",
        handler: function (...args) {
            let e = args[args.length - 1];
            if (e && typeof e.preventDefault === "function") {
                e.preventDefault();
            }
        }
    },
    {
        name: "stopPropagation",
        handler: function (...args) {
            let e = args[args.length - 1];
            if (e && typeof e.stopPropagation === "function") {
                e.stopPropagation();
            }
        }
    }
];

export default ACTION_BEHAVIORS;
export let actionBehaviors = ACTION_BEHAVIORS;