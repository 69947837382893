import React from 'react';
import Udesk from 'Udesk';
import CustomerPortraitRoute from './route';
import './style.scss';
import UdeskPagination from 'Component/common/udesk-pagination';
import LoadingAnimationComponent from 'udesk-react/src/components/loading-animation';
import classNames from 'classnames';
import {
    Timeline,
    Tooltip,
    DatePicker,
    Space,
    Select,
    Empty,
    Button,
    Row,
    Col,
    Icon,
    Input,
    Descriptions,
    PageHeader,
} from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import Locales from 'UdeskLocales';
import { formatDuration } from 'src/util/date';

const { RangePicker } = DatePicker;
const { Option } = Select;
const emtiorData = [
    {
        type: 3,
        color: '#11A848',
        name: 'gaoxing',
        text: Locales.current.enums.common.sentimentTypes.happy,
    },
    {
        type: 1,
        color: '#11A848',
        name: 'gaoxing',
        text: Locales.current.enums.common.sentimentTypes.post,
    },
    {
        type: 2,
        color: '#1B6DFF',
        name: 'ganji',
        text: Locales.current.enums.common.sentimentTypes.thankful,
    },
    {
        type: 0,
        color: 'rgba(0,0,0,0.65)',
        name: 'zhongxing',
        text: Locales.current.enums.common.sentimentTypes.neut,
    },
    {
        type: -2,
        color: '#F09A00',
        name: 'baoyuan',
        text: Locales.current.enums.common.sentimentTypes.complaining,
    },
    {
        type: -3,
        color: '#FB5127',
        name: 'fennu',
        text: Locales.current.enums.common.sentimentTypes.angry,
    },
    {
        type: -1,
        color: '#F09A00',
        name: 'baoyuan',
        text: Locales.current.enums.common.sentimentTypes.negat,
    },
];
let operatorList = [
    {
        id: 'contains_any',
        key: 'contains_any',
        name: Locales.current.enums.fieldOperators.contains_any,
    },
    {
        id: 'contains_all',
        key: 'contains_all',
        name: Locales.current.enums.fieldOperators.contains_all,
    },
];
export default class CustomerPortraitTemplate extends CustomerPortraitRoute {
    render() {
        let { privates } = this;
        // let {
        //     sdkOptions
        // } = this.props;
        let {
            computes,
            asyncModel,
            keyWordsData,
            businessTagsData,
            // keywordWordCloudUrl,
            currentCostomer,
            statAt,
            keyWordsEacharts,
            findAllSamrtTagsItems,
            tagList,
            timeLineData,
            personaData,
            keyword,
            operator,
        } = this.privates;
        let { actions, CUSTOMER_LIST_PAGE_SIZE, FIRST_PAGER_NUMBER } = this;
        const { Search } = Input;
        let list = Udesk.business.report.getChartDataFix(this, keyWordsEacharts);
        return (
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={this.locales.components.pages.customerPortrait.title}
                />
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root customer-portrait-index-page">
                        <div className="customer-portrait-index-page-list-header">
                            <div className="customer-portrait-index-page-search">
                                <Space>
                                    <div style={{ display: 'inline-block' }}>
                                        <span>
                                            {
                                                this.locales.components.pages.customerPortrait
                                                    .timeList
                                            }
                                            ：
                                        </span>
                                        <RangePicker
                                            picker="month"
                                            format={'YYYY-MM'}
                                            onChange={actions.timeChange}
                                            value={
                                                (statAt && [
                                                    moment(statAt[0]),
                                                    moment(statAt[1]),
                                                ]) ||
                                                undefined
                                            }
                                        />
                                    </div>
                                    <div style={{ display: 'inline-block' }}>
                                        <span>
                                            {
                                                this.locales.components.pages.customerPortrait
                                                    .operator
                                            }
                                            ：
                                        </span>
                                        <Space>
                                            <Select
                                                value={operator}
                                                onChange={actions.operatorChanged}
                                            >
                                                <Option value={'please'}>
                                                    {
                                                        this.locales.components.pages
                                                            .customerPortrait.placeHolder
                                                    }
                                                </Option>
                                                <For each="items" index="index" of={operatorList}>
                                                    <Option value={`${items.id}`}>
                                                        {items.name}
                                                    </Option>
                                                </For>
                                            </Select>
                                            <Select
                                                mode="multiple"
                                                placeholder={
                                                    this.locales.components.pages.customerPortrait
                                                        .placeHolder
                                                }
                                                maxTagCount={2}
                                                style={{ width: '220px' }}
                                                value={tagList || undefined}
                                                labelInValue={true}
                                                filterOption={false}
                                                onSearch={actions.onSearchSelect}
                                                onChange={actions.onChangeSelect}
                                                disabled={operator === 'please'}
                                                onFocus={actions.onFocusSelect}
                                            >
                                                <For
                                                    each="items"
                                                    index="index"
                                                    of={findAllSamrtTagsItems}
                                                >
                                                    <Option value={`${items.id}`}>
                                                        {items.tagName}
                                                    </Option>
                                                </For>
                                            </Select>
                                        </Space>
                                    </div>
                                    <div style={{ display: 'inline-block' }}>
                                        <span>
                                            {
                                                this.locales.components.pages.customerPortrait
                                                    .searchHolder
                                            }
                                            ：
                                        </span>
                                        <Search
                                            placeholder={
                                                this.locales.components.pages.customerPortrait
                                                    .searchPlaceHolder
                                            }
                                            onSearch={(value) => actions.onSearchCustomer(value)}
                                            allowClear={true}
                                            onChange={actions.searchChange}
                                            value={keyword}
                                            style={{ width: '220px' }}
                                        ></Search>
                                    </div>
                                    <Button type="primary" onClick={actions.onSearchSubmit}>
                                        {this.locales.components.pages.customerPortrait.search}
                                    </Button>
                                    <Button onClick={actions.reset}>
                                        {this.locales.components.pages.customerPortrait.reset}
                                    </Button>
                                </Space>
                            </div>
                        </div>
                        <div className="customer-portrait-index-page-body-list">
                            {/* 客户列表 */}
                            <div className="customer-portrait-index-page-list-wrapper">
                                {/* <div className="customer-portrait-index-page-back"></div> */}

                                <ul className="customer-portrait-index-page-list">
                                    <If condition={privates.asyncModelFulfilled}>
                                        <If condition={!privates.asyncModelResolved}>
                                            <div className="async-model-error-msg-tip">
                                                {privates.asyncModelErrorMsg}
                                            </div>
                                        </If>
                                    </If>
                                    <If condition={!privates.asyncModelFulfilled}>
                                        <LoadingAnimationComponent />
                                    </If>
                                    <If
                                        condition={
                                            privates.asyncModelFulfilled &&
                                            privates.asyncModelResolved
                                        }
                                    >
                                        <With
                                            hasCustomer={
                                                asyncModel &&
                                                asyncModel.customerPortrait != null &&
                                                asyncModel.customerPortrait.length > 0
                                            }
                                        >
                                            <If condition={hasCustomer}>
                                                <For
                                                    of={asyncModel.customerPortrait}
                                                    each="customer"
                                                    index="customerIndex"
                                                >
                                                    <li
                                                        className={classNames('customer-item', {
                                                            [`active`]:
                                                                currentCostomer === customer.id,
                                                        })}
                                                        key={`customer-${customerIndex}`}
                                                        onClick={this.actions.getStatisticsData.params(
                                                            customer.id,
                                                            customer.customerId
                                                        )}
                                                    >
                                                        <div className="customer-item-top customer-item-row">
                                                            <With
                                                                userIdoverflow={
                                                                    customer.nickName != null &&
                                                                    customer.nickName.length >= 30
                                                                }
                                                            >
                                                                <If condition={userIdoverflow}>
                                                                    <Tooltip
                                                                        placement="topLeft"
                                                                        title={customer.nickName}
                                                                    >
                                                                        <div
                                                                            className="customer-item-user-id customer-item-row-item udesk-ellipsis"
                                                                            style={{
                                                                                maxWidth: '242px',
                                                                            }}
                                                                        >
                                                                            {customer.nickName}
                                                                        </div>
                                                                    </Tooltip>
                                                                </If>
                                                                <If condition={!userIdoverflow}>
                                                                    <div
                                                                        className="customer-item-user-id customer-item-row-item udesk-ellipsis"
                                                                        style={{
                                                                            maxWidth: '242px',
                                                                        }}
                                                                    >
                                                                        {customer.nickName}
                                                                    </div>
                                                                </If>
                                                            </With>
                                                        </div>
                                                        <div className="customer-item-top customer-item-row">
                                                            <With
                                                                userIdoverflow={
                                                                    customer.custId != null &&
                                                                    customer.custId.length >= 18
                                                                }
                                                            >
                                                                <If condition={userIdoverflow}>
                                                                    <Tooltip
                                                                        placement="topLeft"
                                                                        title={customer.custId}
                                                                    >
                                                                        <div className="customer-item-user-id customer-item-row-item udesk-ellipsis">
                                                                            {customer.custId}
                                                                        </div>
                                                                    </Tooltip>
                                                                </If>
                                                                <If condition={!userIdoverflow}>
                                                                    <div className="customer-item-user-id customer-item-row-item udesk-ellipsis">
                                                                        {customer.custId}
                                                                    </div>
                                                                </If>
                                                            </With>
                                                            <div className="customer-item-call-count customer-item-row-item text-right udesk-ellipsis">
                                                                <span className="customer-item-title">
                                                                    {
                                                                        this.locales.components
                                                                            .pages.customerPortrait
                                                                            .callCount
                                                                    }
                                                                </span>
                                                                <With
                                                                    callCountOverflow={
                                                                        customer.callCount !=
                                                                            null &&
                                                                        customer.callCount.toString()
                                                                            .length >= 13
                                                                    }
                                                                >
                                                                    <If
                                                                        condition={
                                                                            callCountOverflow
                                                                        }
                                                                    >
                                                                        <Tooltip
                                                                            title={
                                                                                customer.callCount
                                                                            }
                                                                        >
                                                                            {customer.callCount}
                                                                        </Tooltip>
                                                                    </If>
                                                                    <If
                                                                        condition={
                                                                            !callCountOverflow
                                                                        }
                                                                    >
                                                                        {customer.callCount}
                                                                    </If>
                                                                </With>
                                                            </div>
                                                        </div>
                                                        <div className="customer-item-bottom customer-item-row">
                                                            <If
                                                                condition={
                                                                    customer.callDuration != null
                                                                }
                                                            >
                                                                <div className="customer-item-call-duration customer-item-row-item udesk-ellipsis">
                                                                    <span className="customer-item-title">
                                                                        {
                                                                            this.locales.components
                                                                                .pages
                                                                                .customerPortrait
                                                                                .callDuration
                                                                        }
                                                                    </span>
                                                                    <With
                                                                        callDurationOverflow={
                                                                            customer.callDuration.toString()
                                                                                .length >= 10
                                                                        }
                                                                    >
                                                                        <If
                                                                            condition={
                                                                                callDurationOverflow
                                                                            }
                                                                        >
                                                                            <Tooltip
                                                                                title={formatDuration(
                                                                                    customer.callDuration
                                                                                )}
                                                                            >
                                                                                {formatDuration(
                                                                                    customer.callDuration
                                                                                )}
                                                                            </Tooltip>
                                                                        </If>
                                                                        <If
                                                                            condition={
                                                                                !callDurationOverflow
                                                                            }
                                                                        >
                                                                            {formatDuration(
                                                                                customer.callDuration
                                                                            )}
                                                                        </If>
                                                                    </With>
                                                                </div>
                                                            </If>
                                                            <div
                                                                className={classNames(
                                                                    'customer-item-dialog-count customer-item-row-item udesk-ellipsis',
                                                                    {
                                                                        [` text-right `]:
                                                                            customer.callDuration !=
                                                                            null,
                                                                    }
                                                                )}
                                                            >
                                                                <span className="customer-item-title">
                                                                    {
                                                                        this.locales.components
                                                                            .pages.customerPortrait
                                                                            .dialogCount
                                                                    }
                                                                </span>
                                                                <With
                                                                    dialogCountOverflow={
                                                                        customer.dialogCount !=
                                                                            null &&
                                                                        customer.dialogCount.toString()
                                                                            .length >= 9
                                                                    }
                                                                >
                                                                    <If
                                                                        condition={
                                                                            dialogCountOverflow
                                                                        }
                                                                    >
                                                                        <Tooltip
                                                                            title={
                                                                                customer.dialogCount
                                                                            }
                                                                        >
                                                                            {customer.dialogCount}
                                                                        </Tooltip>
                                                                    </If>
                                                                    <If
                                                                        condition={
                                                                            !dialogCountOverflow
                                                                        }
                                                                    >
                                                                        {customer.dialogCount}
                                                                    </If>
                                                                </With>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </For>
                                            </If>
                                            <If condition={!hasCustomer}>
                                                <div className="customer-not-found">
                                                    {
                                                        this.locales.components.pages
                                                            .customerPortrait.notFoundCustomer
                                                    }
                                                </div>
                                            </If>
                                        </With>
                                    </If>
                                </ul>
                                <If
                                    condition={
                                        asyncModel &&
                                        asyncModel.customerPortraitPaging != null &&
                                        asyncModel.customerPortrait.length > 0
                                    }
                                >
                                    <div className={'customer-portrait-index-pager'}>
                                        <div className="customer-portrait-index-pager-first-and-final">
                                            <span
                                                className="first-pager-btn jump-pager-btn"
                                                onClick={actions.pageChanged.params(
                                                    FIRST_PAGER_NUMBER,
                                                    CUSTOMER_LIST_PAGE_SIZE
                                                )}
                                            >
                                                {Locales['current'].fix.firstPage}
                                            </span>
                                            <span
                                                className="last-pager-btn jump-pager-btn"
                                                onClick={actions.pageChanged.params(
                                                    computes.lastPagerNumbers,
                                                    CUSTOMER_LIST_PAGE_SIZE
                                                )}
                                            >
                                                {Locales['current'].fix.lastPage}
                                            </span>
                                        </div>
                                        {/* <ReactPager
                                            pageNum={asyncModel.customerPortraitPaging.pageNum}
                                            pageSize={asyncModel.customerPortraitPaging.pageSize}
                                            total={asyncModel.customerPortraitPaging.total}
                                            languageCode={sdkOptions.props.language}
                                            pageChanged={actions.pageChanged}
                                            enablePageSize={false}
                                            showTotal={true}
                                            showTotalPages={true}
                                            marginPagesDisplayed={0}
                                            pageRangeDisplayed={1}
                                        // enableGoto={true}
                                        // showTotalPagesInGodo={true}
                                        /> */}
                                        <UdeskPagination
                                            current={asyncModel.customerPortraitPaging.pageNum}
                                            pageSize={asyncModel.customerPortraitPaging.pageSize}
                                            total={asyncModel.customerPortraitPaging.total}
                                            simple={true}
                                            showSizeChanger={false}
                                            onChange={actions.pageChanged}
                                        />
                                    </div>
                                </If>
                            </div>
                            {/* 客户画像 */}
                            <div className="customer-portrait-index-page-content">
                                {/* <div className="customer-portrait-index-page-header qa-react-page-header">
                                    <div className="customer-portrait-index-page-header-title qa-react-page-header-title">
                                        {this.locales.components.pages.customerPortrait.title}
                                    </div>
                                </div> */}
                                <div className="customer-portrait-index-page-body qa-react-page-body">
                                    {/* 统计内容 */}
                                    <div className="page-body-top-wrapper">
                                        <Row gutter={16} style={{ height: '100%', width: '100%' }}>
                                            <Col span={16} style={{ height: '100%' }}>
                                                <Row style={{ height: '100%' }}>
                                                    <Col span={12} style={{ height: '50%' }}>
                                                        <div className="customer-portrait-index-page-body-list-info">
                                                            <div className="customer-portrait-index-page-body-list-info-header">
                                                                {
                                                                    Locales['current'].fix
                                                                        .customerInfo
                                                                }
                                                            </div>
                                                            <div className="customer-portrait-index-page-body-list-info-content">
                                                                <With
                                                                    person={personaData.length > 0}
                                                                >
                                                                    <If condition={!personaData}>
                                                                        <span className="no-content-tip">
                                                                            {
                                                                                this.locales
                                                                                    .components
                                                                                    .pages
                                                                                    .customerPortrait
                                                                                    .personCustome
                                                                            }
                                                                        </span>
                                                                    </If>
                                                                    <If condition={person}>
                                                                        {/* <For of={personaData} each="item" index="index">
                                                                            <div key={index}>{item.key}</div>
                                                                        </For> */}
                                                                        <Descriptions
                                                                            bordered
                                                                            size="small"
                                                                        >
                                                                            {personaData.map(
                                                                                (item) => {
                                                                                    let node =
                                                                                        item.key.split(
                                                                                            ':'
                                                                                        );
                                                                                    return (
                                                                                        <Descriptions.Item
                                                                                            span={3}
                                                                                            label={
                                                                                                <div
                                                                                                    style={{
                                                                                                        textAlign:
                                                                                                            'end',
                                                                                                    }}
                                                                                                >{`${node[0]}`}</div>
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                node[1]
                                                                                            }
                                                                                        </Descriptions.Item>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Descriptions>
                                                                    </If>
                                                                    <If condition={!person}>
                                                                        <Empty
                                                                            image={
                                                                                Empty.PRESENTED_IMAGE_SIMPLE
                                                                            }
                                                                        />
                                                                    </If>
                                                                </With>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={12} style={{ height: '50%' }}>
                                                        <div className="customer-portrait-index-page-body-list-info">
                                                            <div className="customer-portrait-index-page-body-list-info-header">
                                                                {' '}
                                                                {
                                                                    this.locales.components.pages
                                                                        .customerPortrait
                                                                        .businessTag
                                                                }
                                                            </div>
                                                            {/* 业务标签 */}
                                                            <div className="customer-portrait-index-page-body-list-info-content">
                                                                <With
                                                                    hasBusinessTags={
                                                                        businessTagsData.length > 0
                                                                    }
                                                                >
                                                                    <If condition={hasBusinessTags}>
                                                                        <For
                                                                            of={businessTagsData}
                                                                            each="businessTag"
                                                                            index="businessTagIndex"
                                                                        >
                                                                            {/* <span className="tag-left-arrow" style={{ borderRight: `12px solid ${businessTag.categoryColor}` }}></span>
                                                                                    <Badge count={businessTag.tagCnt} style={{ backgroundColor: '#2878ff', color: '#fff', boxShadow: '0 0 0 1px #fff inset', userSelect: `none` }}>
                                                                                        <Tag color={businessTag.categoryColor} style={{ textShadow: `0px 1px 1px rgba(0, 0, 0, .1)`, userSelect: `none`, fontWeight: `bold` }}>{businessTag.tagName}</Tag>
                                                                                    </Badge> */}
                                                                            <span
                                                                                key={`business-tag-${businessTagIndex}`}
                                                                                className="customer-portrait-business-tag"
                                                                                style={{
                                                                                    color: businessTag.categoryColor,
                                                                                    background:
                                                                                        colorToRGB(
                                                                                            businessTag.categoryColor
                                                                                        ),
                                                                                    border: `1px solid ${businessTag.categoryColor}`,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    businessTag.tagName
                                                                                }
                                                                                （
                                                                                {businessTag.tagCnt}
                                                                                ）
                                                                            </span>
                                                                        </For>
                                                                    </If>
                                                                    <If
                                                                        condition={!hasBusinessTags}
                                                                    >
                                                                        <span className="no-content-tip">
                                                                            {
                                                                                this.locales
                                                                                    .components
                                                                                    .pages
                                                                                    .customerPortrait
                                                                                    .noBusinessTags
                                                                            }
                                                                        </span>
                                                                    </If>
                                                                </With>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={12} style={{ height: '50%' }}>
                                                        <div className="customer-portrait-index-page-body-list-info">
                                                            <div className="customer-portrait-index-page-body-list-info-header">
                                                                {' '}
                                                                {
                                                                    this.locales.components.pages
                                                                        .customerPortrait
                                                                        .keyWordsCloudImg
                                                                }
                                                            </div>
                                                            <div className="customer-portrait-index-page-body-list-info-content">
                                                                <With
                                                                    hasKeywordWordCloudUrl={
                                                                        !_isEmpty(list) &&
                                                                        !_isEmpty(list.items)
                                                                    }
                                                                >
                                                                    <If
                                                                        condition={
                                                                            hasKeywordWordCloudUrl
                                                                        }
                                                                    >
                                                                        <div
                                                                            id="customerPortraitKeyWordsCloud"
                                                                            className="key-words-cloud-content"
                                                                        >
                                                                            <ReactEcharts
                                                                                ref={
                                                                                    privates.wordCloudRef
                                                                                }
                                                                                classNames="key-words-cloud"
                                                                                type="wordCloud"
                                                                                xLabelField="xLabel"
                                                                                showTooltips={true}
                                                                                rerenderOptimization={
                                                                                    true
                                                                                }
                                                                                onSeriesClicked={
                                                                                    actions.onSeriesClicked
                                                                                }
                                                                                records={list.items}
                                                                                seriesColumns={
                                                                                    list.columns
                                                                                }
                                                                                finalizeChartOptions={(
                                                                                    option
                                                                                ) => {
                                                                                    option.tooltip.trigger =
                                                                                        'item';
                                                                                    option.tooltip.backgroundColor =
                                                                                        '#fff';
                                                                                    option.tooltip.borderColor =
                                                                                        '#333';
                                                                                    option.tooltip.textStyle.color =
                                                                                        '#333';
                                                                                    option.tooltip.padding = 15;
                                                                                    option.tooltip.textStyle.fontSize = 14;
                                                                                    return option;
                                                                                }}
                                                                                height="100%"
                                                                            />
                                                                        </div>
                                                                    </If>
                                                                    <If
                                                                        condition={
                                                                            !hasKeywordWordCloudUrl
                                                                        }
                                                                    >
                                                                        <div className="key-words-cloud-content">
                                                                            <Icon
                                                                                type="cloud"
                                                                                className="not-word-cloud-init"
                                                                            />
                                                                        </div>
                                                                    </If>
                                                                </With>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={12} style={{ height: '50%' }}>
                                                        <div className="customer-portrait-index-page-body-list-info">
                                                            {/* 关键词统计 */}
                                                            <div className="customer-portrait-index-page-body-list-info-header">
                                                                {' '}
                                                                {
                                                                    this.locales.components.pages
                                                                        .customerPortrait
                                                                        .keyWordsStatistics
                                                                }
                                                            </div>
                                                            <div className="customer-portrait-index-page-body-list-info-content">
                                                                <With
                                                                    hasKeyWords={
                                                                        keyWordsData.length > 0
                                                                    }
                                                                >
                                                                    <If condition={hasKeyWords}>
                                                                        <For
                                                                            of={keyWordsData}
                                                                            each="keyWord"
                                                                            index="keyWordIndex"
                                                                        >
                                                                            <span
                                                                                key={`business-tag-${keyWordIndex}`}
                                                                                className="customer-portrait-business-tag"
                                                                                style={{
                                                                                    color: 'rgba(0, 0, 0, 0.65)',
                                                                                    background:
                                                                                        'rgba(0,0,0,0.05)',
                                                                                    border: `1px solid rgba(0,0,0,0.15)`,
                                                                                }}
                                                                            >
                                                                                {keyWord.keyword}（
                                                                                {
                                                                                    keyWord.keyword_freq
                                                                                }
                                                                                ）
                                                                            </span>
                                                                        </For>
                                                                    </If>
                                                                    <If condition={!hasKeyWords}>
                                                                        <span className="no-content-tip">
                                                                            {
                                                                                this.locales
                                                                                    .components
                                                                                    .pages
                                                                                    .customerPortrait
                                                                                    .noKeyWords
                                                                            }
                                                                        </span>
                                                                    </If>
                                                                </With>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={8} style={{ height: '100%' }}>
                                                <With timeflag={timeLineData.length > 0}>
                                                    <If condition={timeflag}>
                                                        <div className="customer-portrait-timeline-list">
                                                            <Timeline>
                                                                <For
                                                                    each="item"
                                                                    index="index"
                                                                    of={timeLineData}
                                                                >
                                                                    <Timeline.Item
                                                                        key={item.callId}
                                                                    >
                                                                        <div className="customer-portrait-timeline-item">
                                                                            <div
                                                                                className="customer-portrait-timeline-item-header"
                                                                                onClick={actions.transToDetail.params(
                                                                                    item.callId,
                                                                                    item.inspectDataSource
                                                                                )}
                                                                            >
                                                                                {`${
                                                                                    item.inspectDataSource ===
                                                                                    1
                                                                                        ? this
                                                                                              .locales
                                                                                              .components
                                                                                              .pages
                                                                                              .customerPortrait
                                                                                              .call
                                                                                        : this
                                                                                              .locales
                                                                                              .components
                                                                                              .pages
                                                                                              .customerPortrait
                                                                                              .text
                                                                                } `}
                                                                                {item.callTime}
                                                                            </div>
                                                                            <If
                                                                                condition={
                                                                                    (item.emotionNumDTOList &&
                                                                                        item
                                                                                            .emotionNumDTOList
                                                                                            .length >
                                                                                            0) ||
                                                                                    (item.tagList &&
                                                                                        item.tagList
                                                                                            .length >
                                                                                            0)
                                                                                }
                                                                            >
                                                                                <div className="customer-portrait-timeline-item-info">
                                                                                    <If
                                                                                        condition={
                                                                                            item.emotionNumDTOList &&
                                                                                            item
                                                                                                .emotionNumDTOList
                                                                                                .length >
                                                                                                0
                                                                                        }
                                                                                    >
                                                                                        <div className="customer-portrait-timeline-item-info-banner">
                                                                                            <For
                                                                                                each="emotion"
                                                                                                index="index"
                                                                                                of={
                                                                                                    item.emotionNumDTOList
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    key={
                                                                                                        emotion.emotionValue
                                                                                                    }
                                                                                                >
                                                                                                    <div className="customer-portrait-timeline-item-info-banner-emoties">
                                                                                                        <Icon
                                                                                                            type={
                                                                                                                emtiorData.find(
                                                                                                                    (
                                                                                                                        item
                                                                                                                    ) =>
                                                                                                                        item.type ===
                                                                                                                        emotion.emotionValue
                                                                                                                )
                                                                                                                    ?.name
                                                                                                            }
                                                                                                            style={{
                                                                                                                fontSize:
                                                                                                                    '20px',
                                                                                                                border: `1px solid ${
                                                                                                                    emtiorData.find(
                                                                                                                        (
                                                                                                                            item
                                                                                                                        ) =>
                                                                                                                            item.type ===
                                                                                                                            emotion.emotionValue
                                                                                                                    )
                                                                                                                        ?.color
                                                                                                                }`,
                                                                                                            }}
                                                                                                        />
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize:
                                                                                                                    '12px',
                                                                                                                marginLeft:
                                                                                                                    '3px',
                                                                                                                color: emtiorData.find(
                                                                                                                    (
                                                                                                                        item
                                                                                                                    ) =>
                                                                                                                        item.type ===
                                                                                                                        emotion.emotionValue
                                                                                                                )
                                                                                                                    ?.color,
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                emtiorData.find(
                                                                                                                    (
                                                                                                                        item
                                                                                                                    ) =>
                                                                                                                        item.type ===
                                                                                                                        emotion.emotionValue
                                                                                                                )
                                                                                                                    ?.text
                                                                                                            }

                                                                                                            x
                                                                                                            {
                                                                                                                emotion.num
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </For>
                                                                                        </div>
                                                                                    </If>
                                                                                    <If
                                                                                        condition={
                                                                                            item.tagList &&
                                                                                            item
                                                                                                .tagList
                                                                                                .length >
                                                                                                0
                                                                                        }
                                                                                    >
                                                                                        <div className="customer-portrait-timeline-item-info-content">
                                                                                            <For
                                                                                                each="tag"
                                                                                                index="index"
                                                                                                of={
                                                                                                    item.tagList
                                                                                                }
                                                                                            >
                                                                                                <span
                                                                                                    key={
                                                                                                        tag.tagId
                                                                                                    }
                                                                                                    className="customer-portrait-business-tag"
                                                                                                    style={{
                                                                                                        color: tag.categoryColor,
                                                                                                        background:
                                                                                                            colorToRGB(
                                                                                                                tag.categoryColor
                                                                                                            ),
                                                                                                        border: tag.categoryColor,
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        tag.tagName
                                                                                                    }
                                                                                                </span>
                                                                                            </For>
                                                                                        </div>
                                                                                    </If>
                                                                                </div>
                                                                            </If>
                                                                        </div>
                                                                    </Timeline.Item>
                                                                </For>
                                                            </Timeline>
                                                        </div>
                                                    </If>
                                                    <If condition={!timeflag}>
                                                        <span className="no-content-tip">
                                                            {
                                                                this.locales.components.pages
                                                                    .customerPortrait.notimeInfo
                                                            }
                                                        </span>
                                                    </If>
                                                </With>
                                            </Col>
                                        </Row>

                                        {/* 关键词云图 */}
                                    </div>
                                    {/* 情绪变化趋势 */}
                                    {/* <div className="page-body-bottom-wrapper">
                            <div className="page-body-customer-emotion">
                                <div className="page-body-customer-emotion-title"></div>
                                <div className="page-body-customer-emotion-polygonal-chart"></div>
                            </div>
                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function colorToRGB(color) {
    var color1, color2, color3;
    color = '' + color;
    if (typeof color !== 'string') return;
    if (color.charAt(0) === '#') {
        color = color.substring(1);
    }
    if (color.length === 3) {
        color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
    }
    if (/^[0-9a-fA-F]{6}$/.test(color)) {
        color1 = parseInt(color.substr(0, 2), 16);
        color2 = parseInt(color.substr(2, 2), 16);
        color3 = parseInt(color.substr(4, 2), 16);
        return 'rgb(' + color1 + ',' + color2 + ',' + color3 + ',0.1)';
    }
}
