import TemplateComponentsListComponent from './component';
import Udesk from 'Udesk';

class TemplateComponentsListRoute extends TemplateComponentsListComponent {
    asyncModel() {
        let { sdkOptions } = this.props;
        let { keywords, releaseStatus, paging, needUpdate, moduleSourceType, moduleType, order } = this.privates;
        let data = {
            pageNum: paging.pageNum,
            pageSize: paging.pageSize,
            moduleSourceType,
            moduleType,
            order,
        };
        if (!keywords) {
            keywords = '';
        }
        if (needUpdate === 1 || needUpdate === 0) {
            data.needUpdate = needUpdate;
        }
        data.keywords = keywords;
        if (releaseStatus) {
            data.releaseStatus = releaseStatus;
        }
        this.privates.disabled = true;
        this.actions.update();
        let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionModules/list`, sdkOptions);
        let taskPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(taskUrl, data).then(
                (resp) => {
                    resolve(resp);
                    this.privates.disabled = false;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.disabled = false;
                    this.actions.update();
                    reject(reason);
                }
            );
        });
        return {
            taskData: taskPromise,
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}

export default TemplateComponentsListRoute;
