import Udesk from 'udesk-react/src/udesk';
import Locales from 'udesk-react/src/udesk/locales';
import validators from '../validators';
import converters from '../converters';
import CFITextBox from 'udesk-react/src/components/custom-filter-inputs/text-box';
// import CFIDropdown from "udesk-react/src/components/custom-filter-inputs/drop-down";
import CFIReactMultiSelect from 'udesk-react/src/components/custom-filter-inputs/react-multi-select';
import CFIAutoComplete from 'udesk-react/src/components/custom-filter-inputs/auto-complete';
import CFIDateRangePicker from 'udesk-react/src/components/custom-filter-inputs/date-range-picker';
import CFIDateTimePicker from 'udesk-react/src/components/custom-filter-inputs/date-time-picker';
import CFIDatePicker from 'udesk-react/src/components/custom-filter-inputs/date-picker';
import CFIIntegerNumber from 'udesk-react/src/components/custom-filter-inputs/integer-number';
import AntdSelect from '../filter-cus-component/select/index';
import DepartmentSelect from '../filter-cus-component/department-select';
import CustomerTagSelect from '../filter-cus-component/customer-tag-select';
import AgentSelect from '../filter-cus-component/agent-select';
import Cascader from '../filter-cus-component/cascader';

export default getFilterComponentSettings;

function getFilterComponentSettings(options = {}) {
    return [
        {
            inputType: validators.validateInputTypes,
            operators: validators.validateOperators,
            component: validators.validateReactElementNullable,
            attributes: validators.validateObject,
        },
        {
            inputType: converters.convertInputType,
            operators: converters.convertOperators,
            component: null,
            attributes: null,
        },
        {
            inputType: 'keyword',
            operators: [
                'is',
                'not',
                'greater_than',
                'greater_than_eq',
                'less_than',
                'less_than_eq',
                'is_null',
                'is_not_null',
            ],
            component: CFITextBox,
            attributes: {
                classNames: 'form-control input-sm',
            },
        },
        {
            inputType: 'keyword',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'text',
            operators: [
                'is',
                'not',
                'contains',
                'not_contains',
                'set',
                'add_value',
                'remove_value',
                'less_than',
                'greater_than',
            ],
            component: CFITextBox,
            attributes: {
                classNames: 'form-control input-sm',
            },
        },
        {
            inputType: 'text',
            operators: ['is_null', 'is_not_null', 'exact_match', 'set_null'],
            component: null,
            attributes: {},
        },
        {
            inputType: 'textArea',
            operators: ['is', 'not', 'contains', 'not_contains', 'set'],
            component: CFITextBox,
            attributes: {
                classNames: 'form-control input-sm',
            },
        },
        {
            inputType: 'textArea',
            operators: ['is_null', 'is_not_null'],
            component: null,
            attributes: {},
        },
        {
            inputType: 'number',
            operators: [
                'is',
                'not',
                'greater_than',
                'greater_than_eq',
                'less_than',
                'less_than_eq',
                'set',
            ],
            component: CFIIntegerNumber,
            attributes: {
                classNames: 'form-control input-sm',
            },
        },
        {
            inputType: 'number',
            operators: ['between', 'not_between'],
            component: CFIIntegerNumber,
            attributes: {
                isRange: true,
                classNames: 'form-control input-sm input-sm-wide',
            },
        },
        {
            inputType: 'number',
            operators: ['is_null', 'is_not_null'],
            component: null,
            attributes: {},
        },
        {
            inputType: 'dateTime',
            operators: [
                'is',
                'not',
                'greater_than',
                'greater_than_eq',
                'less_than',
                'less_than_eq',
                'set',
            ],
            component: CFIDateTimePicker,
            attributes: {
                classNames: 'date-picker form-control input-sm',
                format: Udesk.config.date.momentDateTimeFormat,
                serverFormat: Udesk.config.date.momentDateTimeFormat,
                timePickerSeconds: true,
            },
        },
        {
            inputType: 'dateTime',
            operators: ['between', 'not_between'],
            component: CFIDateRangePicker,
            attributes: {
                classNames: 'date-range-picker form-control input-sm',
                format: Udesk.config.date.momentDateFormat,
                serverFormat: Udesk.config.date.momentDateFormat,
            },
        },
        {
            inputType: 'dateTime',
            operators: [
                'today',
                'yesterday',
                'tomorrow',
                'the_day_before_yesterday',
                'next_seven_day',
                'last_seven_day',
                'this_week',
                'next_week',
                'last_week',
                'this_month',
                'next_month',
                'last_month',
                'this_year',
                'next_year',
                'last_year',
            ],
            component: null,
            attributes: {},
        },
        {
            inputType: 'date',
            operators: [
                'is',
                'not',
                'greater_than',
                'greater_than_eq',
                'less_than',
                'less_than_eq',
                'set',
            ],
            component: CFIDatePicker,
            attributes: {
                classNames: 'date-picker form-control input-sm',
                format: Udesk.config.date.momentDateFormat,
                serverFormat: Udesk.config.date.momentDateFormat,
            },
        },
        {
            inputType: 'date',
            operators: ['between', 'not_between'],
            component: CFIDateRangePicker,
            attributes: {
                classNames: 'date-range-picker form-control input-sm',
                format: Udesk.config.date.momentDateFormat,
                serverFormat: Udesk.config.date.momentDateFormat,
            },
        },
        {
            inputType: 'date',
            operators: [
                'today',
                'yesterday',
                'tomorrow',
                'the_day_before_yesterday',
                'next_seven_day',
                'last_seven_day',
                'this_week',
                'next_week',
                'last_week',
                'this_month',
                'next_month',
                'last_month',
                'this_year',
                'next_year',
                'last_year',
            ],
            component: null,
            attributes: {},
        },
        {
            inputType: 'keywords',
            operators: ['all', 'all_not', 'any', 'contains'],
            component: CFITextBox,
            attributes: {
                classNames: 'form-control input-sm',
            },
        },
        {
            inputType: 'supervision',
            operators: ['hit', 'not_hit'],
            component: AntdSelect,
            attributes: {
                classNames: 'form-control',
                nameField: 'name',
                nameFieldPostfix: 'content',
                valueField: 'id',
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'keyEvents',
            operators: ['hit', 'not_hit'],
            component: AntdSelect,
            attributes: {
                classNames: 'form-control',
                nameField: 'name',
                nameFieldPostfix: 'content',
                valueField: 'id',
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'keyEvents',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'keyEvents',
            operators: ['is', 'not'],
            component: AntdSelect,
            attributes: {
                classNames: 'form-control',
                nameField: 'name',
                nameFieldPostfix: 'content',
                valueField: 'id',
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'keyEvents',
            operators: ['is_any', 'not_any'],
            component: AntdSelect,
            attributes: {
                mode: 'multiple',
                classNames: 'form-control',
                nameField: 'name',
                nameFieldPostfix: 'content',
                valueField: 'id',
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'multiListbox',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'multiListbox',
            operators: ['is', 'not'],
            component: AntdSelect,
            attributes: {
                classNames: 'form-control',
                nameField: 'name',
                nameFieldPostfix: 'content',
                valueField: 'id',
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'multiListbox',
            operators: ['is_any', 'not_any'],
            component: AntdSelect,
            attributes: {
                mode: 'multiple',
                classNames: 'form-control',
                nameField: 'name',
                nameFieldPostfix: 'content',
                valueField: 'id',
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'multiListbox',
            operators: ['contains', 'not_contains'],
            component: AntdSelect,
            attributes: {
                mode: 'multiple',
                classNames: 'form-control',
                nameField: 'name',
                nameFieldPostfix: 'content',
                valueField: 'id',
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'sigleListbox',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'sigleListbox',
            operators: ['is', 'not', 'contains', 'not_contains'],
            component: AntdSelect,
            attributes: {
                classNames: 'form-control',
                nameField: 'name',
                valueField: 'id',
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'sigleListbox',
            operators: ['is_any', 'not_any'],
            component: CFIReactMultiSelect,
            attributes: {
                classNames: 'ember-power-select-multiple',
                nameField: 'name',
                searchField: 'name',
                valueField: 'id',
            },
        },
        {
            inputType: 'fieldWithLink',
            operators: ['is_null', 'is_not_null'],
            component: '',
        },
        {
            inputType: 'fieldWithLink',
            operators: [
                'is',
                'not',
                'contains',
                'not_contains',
                // 'prefix_contains',
                // 'prefix_not_contains',
                // 'suffix_contains',
                // 'suffix_not_contains'
            ],
            component: CFITextBox,
            attributes: {
                classNames: 'form-control input-sm',
            },
        },
        {
            inputType: 'customer',
            operators: [
                'is',
                'not',
                'contains',
                'not_contains',
                'set',
                'add_value',
                'remove_value',
            ],
            component: CFITextBox,
            attributes: {
                classNames: 'form-control input-sm',
            },
        },
        {
            inputType: 'customer',
            operators: ['is_null', 'is_not_null', 'exact_match', 'set_null'],
            component: null,
            attributes: {},
        },
        {
            inputType: 'agent',
            operators: ['is', 'not'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'agentName',
                valueField: 'agentNo',
                searchField: 'agentName',
                placeholder: '',
                method: 'post',
                extraQueryParams: { pageSize: 200 },
                url: '/backend/agent/search',
                optionMode: 'object',
                queryParamName: 'keyWord',
                enableEmptySearch: true,
            },
        },
        {
            inputType: 'agent',
            operators: ['belongTo', 'notBelongTo'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'agentGroupName',
                valueField: 'id',
                searchField: 'agentGroupName',
                placeholder: '',
                method: 'get',
                url: '/backend/agentGroups/list',
                optionMode: 'object',
                queryParamName: 'keyword',
                enableEmptySearch: true,
            },
        },
        {
            inputType: 'agent',
            operators: ['is_any', 'not_any'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'agentName',
                valueField: 'agentNo',
                searchField: 'agentName',
                placeholder: '',
                optionMode: 'object',
                queryParamName: 'keyWord',
                method: 'post',
                extraQueryParams: { pageSize: 200 },
                url: '/backend/agent/search',
                isMultiSelect: true,
                enableEmptySearch: true,
            },
        },
        {
            inputType: 'intelligentClassification',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'intelligentClassification',
            operators: ['is', 'not'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'tagName',
                valueField: 'id',
                searchField: 'tagName',
                placeholder: '',
                method: 'get',
                optionMode: 'object',
                queryParamName: 'keyword',
                url: '/backend/smartTags/list',
            },
        },
        {
            inputType: 'intelligentClassification',
            operators: ['is_any', 'not_any'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'tagName',
                valueField: 'id',
                searchField: 'tagName',
                placeholder: '',
                method: 'get',
                optionMode: 'object',
                queryParamName: 'keyword',
                url: '/backend/smartTags/list',
                isMultiSelect: true,
            },
        },
        {
            inputType: 'agent',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'percentage',
            operators: [
                'is',
                'not',
                'greater_than',
                'greater_than_eq',
                'less_than',
                'less_than_eq',
            ],
            component: CFIIntegerNumber,
            attributes: {
                classNames: 'form-control input-sm',
            },
        },
        {
            inputType: 'percentage',
            operators: ['is_null', 'is_not_null'],
            component: null,
            attributes: {},
        },
        {
            inputType: 'testSetList',
            operators: ['contains_all', 'is_any'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'name',
                valueField: 'id',
                searchField: 'name',
                placeholder: '',
                method: 'get',
                optionMode: 'object',
                queryParamName: 'name',
                url: `/backend/inspectionPoints/list?taskId=${options.taskId || undefined}`,
                isMultiSelect: true,
            },
        },
        {
            inputType: 'inspector',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'inspector',
            operators: ['is', 'not'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'realname',
                valueField: 'id',
                searchField: 'realname',
                placeholder: '',
                method: 'get',
                optionMode: 'object',
                queryParamName: 'keyword',
                url: '/backend/users?pageSize=1000',
                enableEmptySearch: true,
            },
        },
        {
            inputType: 'inspector',
            operators: ['is_any', 'not_any'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'realname',
                valueField: 'id',
                searchField: 'realname',
                placeholder: '',
                method: 'get',
                optionMode: 'object',
                queryParamName: 'keyword',
                url: '/backend/users?pageSize=1000',
                isMultiSelect: true,
                enableEmptySearch: true,
            },
        },
        {
            inputType: 'executeUser',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'executeUser',
            operators: ['is', 'not'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'realname',
                valueField: 'id',
                searchField: 'realname',
                placeholder: '',
                method: 'get',
                optionMode: 'object',
                queryParamName: 'keyword',
                url: '/backend/users?pageSize=1000',
                enableEmptySearch: true,
            },
        },
        {
            inputType: 'executeUser',
            operators: ['is_any', 'not_any'],
            component: CFIAutoComplete,
            attributes: {
                classNames: 'udesk-auto-complete',
                nameField: 'realname',
                valueField: 'id',
                searchField: 'realname',
                placeholder: '',
                method: 'get',
                optionMode: 'object',
                queryParamName: 'keyword',
                url: '/backend/users?pageSize=1000',
                isMultiSelect: true,
                enableEmptySearch: true,
            },
        },

        // {
        //     inputType: "dropDown",
        //     operators: ["is", "not", "set"],
        //     component: CFIDropdown,
        //     attributes: {
        //         classNames: "form-control input-sm",
        //         nameField: "value",
        //         valueField: "key",
        //         placeholder: Locales.get("labels.pleaseSelect")
        //     }
        // },
        // {
        //     inputType: "dropDown",
        //     operators: ["is_any", "not_any", "contains_all", "not_contains_all"],
        //     component: CFIReactMultiSelect,
        //     attributes: {
        //         classNames: "ember-power-select-multiple",
        //         nameField: "value",
        //         searchField: "value",
        //         valueField: "key"
        //     }
        // },
        // {
        //     inputType: "dropDown",
        //     operators: ["is_null", "is_not_null", "set_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "powerSelect",
        //     operators: ["is", "not", "set"],
        //     component: CFIReactSelect,
        //     attributes: {
        //         classNames: "form-control input-sm"
        //     }
        // },
        // {
        //     inputType: "powerSelect",
        //     operators: ["is_any", "not_any", "contains_all", "not_contains_all"],
        //     component: CFIReactMultiSelect,
        //     attributes: {
        //         classNames: "ember-power-select-multiple"
        //     }
        // },
        // {
        //     inputType: "powerSelect",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "autoComplete",
        //     operators: ["is", "not", "set"],
        //     component: CFIAutoComplete,
        //     attributes: {
        //         classNames: ""
        //     }
        // },

        // {
        //     inputType: "autoComplete",
        //     operators: ["is_any", "not_any", "contains_all", "not_contains_all"],
        //     component: CFIAutoComplete,
        //     // 配置多选字段
        //     attributes: {
        //         classNames: "udesk-auto-complete",
        //         // isMulti: true,
        //         isMultiSelect: true
        //     }
        // },
        // {
        //     inputType: "autoComplete",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "checkBox",
        //     operators: ["is_any", "not_any", "contains_all", "not_contains_all", "set"],
        //     component: CFIReactMultiSelect,
        //     attributes: {
        //         classNames: "ember-power-select-multiple",
        //         nameField: "value",
        //         valueField: "key",
        //         searchField: "value"
        //     }
        // },
        // {
        //     inputType: "checkBox",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "datePicker",
        //     operators: ["greater_than", "greater_than_eq", "less_than", "less_than_eq", "set"],
        //     component: CFIDatePicker,
        //     attributes: {
        //         classNames: "date-picker form-control input-sm",
        //         format: Udesk.config.date.momentDateTimeFormat,
        //         serverFormat: Udesk.config.date.momentDateTimeFormat
        //     }
        // },
        // {
        //     inputType: "datePicker",
        //     operators: ["is", "not"],
        //     component: CFIDatePicker,
        //     attributes: {
        //         classNames: "date-picker form-control input-sm",
        //         format: Udesk.config.date.momentDateFormat,
        //         serverFormat: Udesk.config.date.momentDateFormat
        //     }
        // },
        // {
        //     inputType: "datePicker",
        //     operators: ["between", "not_between"],
        //     component: CFIDateRangePicker,
        //     attributes: {
        //         classNames: "date-range-picker",
        //         format: Udesk.config.date.momentDateFormat,
        //         serverFormat: Udesk.config.date.momentDateFormat
        //     }
        // },
        // {
        //     inputType: "datePicker",
        //     operators: ["today", "yesterday", "tomorrow", "next_seven_day", "last_seven_day", "this_week", "next_week", "last_week", "this_month", "next_month", "last_month", "this_year", "next_year", "last_year"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "datePicker",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "dateTimePicker",
        //     operators: ["between", "not_between"],
        //     component: CFIDateRangePicker,
        //     attributes: {
        //         classNames: "date-range-picker",
        //         format: Udesk.config.date.momentDateTimeFormat,
        //         serverFormat: Udesk.config.date.momentDateTimeFormat
        //     }
        // },
        // {
        //     inputType: "dateTimePicker",
        //     operators: ["greater_than", "greater_than_eq", "less_than", "less_than_eq", "set"],
        //     component: CFIIntegerNumber,
        //     attributes: {
        //         classNames: "date-range-picker",
        //         format: Udesk.config.date.momentDateTimeFormat,
        //         serverFormat: Udesk.config.date.momentDateTimeFormat
        //     }
        // },
        // {
        //     inputType: "dateTimePicker",
        //     operators: ["is", "not"],
        //     component: CFIDatePicker,
        //     attributes: {
        //         classNames: "date-time-picker form-control input-sm",
        //         format: Udesk.config.date.momentDateTimeFormat,
        //         serverFormat: Udesk.config.date.momentDateTimeFormat
        //     }
        // },
        // {
        //     inputType: "dateTimePicker",
        //     operators: ["today", "yesterday", "tomorrow", "next_seven_day", "last_seven_day", "this_week", "next_week", "last_week", "this_month", "next_month", "last_month", "this_year", "next_year", "last_year"],
        //     component: null,
        //     attributes: {
        //         classNames: "date-time-picker form-control input-sm"
        //     }
        // },
        // {
        //     inputType: "dateTimePicker",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "dateRange",
        //     operators: ["between", "not_between", "set"],
        //     component: CFIDateRangePicker,
        //     attributes: {
        //         classNames: "date-range-picker",
        //         format: Udesk.config.date.momentDateTimeFormat,
        //         serverFormat: Udesk.config.date.momentDateTimeFormat
        //     }
        // },
        // {
        //     inputType: "dateRange",
        //     operators: ["is", "not", "greater_than", "greater_than_eq", "less_than", "less_than_eq"],
        //     component: CFIDatePicker,
        //     attributes: {
        //         classNames: "date-range-picker",
        //         format: Udesk.config.date.momentDateTimeFormat,
        //         serverFormat: Udesk.config.date.momentDateTimeFormat
        //     }
        // },
        // {
        //     inputType: "dateRange",
        //     operators: ["today", "yesterday", "tomorrow", "next_seven_day", "last_seven_day", "this_week", "next_week", "last_week", "this_month", "next_month", "last_month", "this_year", "next_year", "last_year"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "dateRange",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "integerNumber",
        //     operators: ["is", "not", "greater_than", "greater_than_eq", "less_than", "less_than_eq", "set"],
        //     component: CFIIntegerNumber,
        //     attributes: {
        //         classNames: "form-control input-sm"
        //     }
        // },
        // {
        //     inputType: "integerNumber",
        //     operators: ["between", "not_between"],
        //     component: CFIIntegerNumber,
        //     attributes: {
        //         isRange: true,
        //         classNames: "form-control input-sm input-sm-wide"
        //     }
        // },
        // {
        //     inputType: "integerNumber",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "floatNumber",
        //     operators: ["is", "not", "greater_than", "greater_than_eq", "less_than", "less_than_eq", "set"],
        //     component: CFIFloatNumber,
        //     attributes: {
        //         classNames: "form-control input-sm"
        //     }
        // },
        // {
        //     inputType: "floatNumber",
        //     operators: ["between", "not_between"],
        //     component: CFIFloatNumber,
        //     attributes: {
        //         isRange: true,
        //         classNames: "form-control input-sm input-sm-wide"
        //     }
        // },
        // {
        //     inputType: "floatNumber",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "powerSelectMultiple",
        //     operators: ["contains_all", "not_contains_all", "not_any", "set"],
        //     component: CFIReactMultiSelect,
        //     attributes: {
        //         classNames: "ember-power-select-multiple"
        //     }
        // },
        // {
        //     inputType: "powerSelectMultiple",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "cascadeDropDown",
        //     operators: ["is", "not", "belongs", "not_belongs", "set"],
        //     component: "udesk/custom-filter-inputs/cascade-drop-down",
        //     attributes: {
        //         classNames: "form-control",
        //         nameField: "value",
        //         valuePath: "key",
        //         childNodesField: "subOptionList",
        //         allowEmpty: true
        //     }
        // },
        // {
        //     inputType: "simplePhone",
        //     operators: ["is", "not", "contains", "not_contains", "set"],
        //     component: CFISimplePhone,
        //     attributes: {
        //         classNames: "form-control input-sm"
        //     }
        // },
        // {
        //     inputType: "simplePhone",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "currentUser",
        //     operators: ["is", "not"],
        //     component: CFIAutoComplete,
        //     attributes: {
        //         classNames: "udesk-auto-complete",
        //         nameField: "realname",
        //         valueField: "id",
        //         searchField: "realname",
        //         placeholder: "",
        //         method: "get",
        //         optionMode: "object",
        //         queryParamName: "realnameOrEmailOrMobile",
        //         url: "/backend/users/realnames"
        //     }
        // },
        // {
        //     inputType: "currentUser",
        //     operators: ["is_any", "not_any"],
        //     component: CFIAutoComplete,
        //     attributes: {
        //         classNames: "udesk-auto-complete",
        //         isMultiSelect: true,
        //         nameField: "realname",
        //         valueField: "id",
        //         searchField: "realname",
        //         placeholder: "",
        //         method: "get",
        //         optionMode: "object",
        //         queryParamName: "realnameOrEmailOrMobile",
        //         url: "/backend/users/realnames"
        //     }
        // },
        // {
        //     inputType: "currentUser",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // },
        // {
        //     inputType: "currentOffice",
        //     operators: ["is", "not"],
        //     component: CFIReactSelect,
        //     attributes: {
        //         classNames: "form-control input-sm",
        //         placeholder: Locales.get("labels.pleaseSelect"),
        //         nameField: "intentName",
        //         searchField: "name",
        //         valueField: "id"
        //     }
        // },
        // {
        //     inputType: "currentOffice",
        //     operators: ["is_any", "not_any"],
        //     component: CFIReactMultiSelect,
        //     attributes: {
        //         classNames: "ember-power-select-multiple",
        //         nameField: "intentName",
        //         searchField: "name",
        //         valueField: "id",
        //         placeholder: ""
        //     }
        // },
        // {
        //     inputType: "currentOffice",
        //     operators: ["is_null", "is_not_null"],
        //     component: null,
        //     attributes: {}
        // }
        {
            inputType: 'department',
            operators: [
                'is',
                'not',
                'contains',
                'not_contains',
                'prefix_contains',
                'prefix_not_contains',
                'suffix_contains',
                'suffix_not_contains',
            ],
            component: DepartmentSelect,
            attributes: {},
        },
        {
            inputType: 'customerTag',
            operators: [
                'is',
                'not',
                'contains',
                'not_contains',
                'prefix_contains',
                'prefix_not_contains',
                'suffix_contains',
                'suffix_not_contains',
            ],
            component: CustomerTagSelect,
            attributes: {},
        },
        {
            inputType: 'wfAgent',
            operators: [
                'is',
                'not',
                'contains',
                'not_contains',
                'prefix_contains',
                'prefix_not_contains',
                'suffix_contains',
                'suffix_not_contains',
            ],
            component: AgentSelect,
            attributes: {},
        },
        {
            inputType: 'cascader',
            operators: ['is_null', 'is_not_null'],
            component: '',
            attributes: null,
        },
        {
            inputType: 'cascader',
            operators: ['is', 'not'],
            component: Cascader,
            attributes: {
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
        {
            inputType: 'cascader',
            operators: ['is_any', 'not_any', 'contains', 'not_contains'],
            component: Cascader,
            attributes: {
                placeholder: Locales.get('labels.pleaseSelect'),
            },
        },
    ];
}
