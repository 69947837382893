// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetSubjectMarkCallsDataCallDetailByIdParam,
    BaseResponseDataCallDto,
} from '../../../../types';

/**
 * 查询标注通话详情
 *
 * @export
 * @tags 话题标注通话
 * @link [GET] /subjectMarkCalls/dataCall/detail/{id}
 * @param id
 */
export function getSubjectMarkCallsDataCallDetailById(
    options: ApiOptions<GetSubjectMarkCallsDataCallDetailByIdParam, never> & {
        segments: GetSubjectMarkCallsDataCallDetailByIdParam;
    }
): Promise<BaseResponseDataCallDto> {
    return request<never, GetSubjectMarkCallsDataCallDetailByIdParam, never>(
        '/subjectMarkCalls/dataCall/detail/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getSubjectMarkCallsDataCallDetailById' }
    );
}

export const meta = [
    { tags: ['话题标注通话'], path: '/subjectMarkCalls/dataCall/detail/{id}', method: 'get' },
];
