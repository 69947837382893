import React from "react";
import TaskBasicInfoRoute from "./route";
import { PageHeader, Form, Input, Select, Row, Col } from 'udesk-ui';
import './style.scss';
import AutoComplete from 'udesk-react/src/components/auto-complete';


export default class TaskBasicInfoTemplate extends TaskBasicInfoRoute {
    render() {
        let { actions, locales, privates } = this;
        return (
            <React.Fragment>
                <div className='udesk-qa-web-page'>
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        // onBack={actions.gotoTask}
                        title={locales.pages.tasks.manage.template.basicInfo.index.title}
                    />
                    <div className='udesk-qa-web-page-body'>
                        {/* <If condition={!privates.isEdit}>
                            <div className="tasks-manage-template-basic-info-index-conduct">
                                <div className="tasks-manage-template-basic-info-index-conduct-left">
                                    {locales.pages.tasks.manage.template.basicInfo.index.conduct}
                                    <span style={{marginLeft: 16, color: 'rgba(27,109,255,1)', textDecoration: 'underline', cursor: 'pointer'}}>
                                        {locales.pages.tasks.manage.template.basicInfo.index.helper}
                                    </span>
                                </div>
                                <div className="tasks-manage-template-basic-info-index-conduct-right">
                                    <span className="udesk-qa-react-web-iconfont" style={{marginRight: 4}}>
                                        &#xe6ae;
                                    </span>
                                    {locales.pages.tasks.manage.template.basicInfo.index.quit}
                                </div>
                            </div>
                        </If> */}
                        <div className='udesk-qa-web-page-body-root tasks-manage-test-template-basic-info-page'>
                            <div className="tasks-manage-template-basic-info-index-form" style={{ backgroundColor: '#fff', height: '100%', padding: 40 }}>
                                <div>
                                    <Form
                                        // form={privates.basicForm}
                                        labelCol={{ span: 2 }}
                                        name="basicForm"
                                        // onSubmit={actions.handleSubmit}
                                        initialValues={{
                                            taskName: privates.taskName,
                                            taskRemark: privates.taskRemark,
                                            taskSource: privates.taskSource,
                                            // qualityUser: privates.qualityUser,
                                        }}
                                        onValuesChange={actions.onFormValuesChange}
                                    >
                                        <Form.Item
                                            label={locales.pages.tasks.manage.template.basicInfo.index.taskName}
                                            wrapperCol={{ span: 4 }}
                                            name="taskName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: locales.pages.tasks.manage.template.basicInfo.index.requiredName
                                                },
                                            ]}
                                        >
                                            <Input disabled={true} placeholder={locales.pages.tasks.manage.template.basicInfo.index.taskNamePlaceholder} />
                                        </Form.Item>
                                        <Form.Item
                                            label={locales.pages.tasks.manage.template.basicInfo.index.taskRemark}
                                            wrapperCol={{ span: 6 }}
                                            name="taskRemark"
                                        >
                                            <Input.TextArea disabled={true} autoSize={{ minRows: 2, maxRows: 2 }} placeholder={locales.pages.tasks.manage.template.basicInfo.index.taskRemarkPlaceholder} />
                                        </Form.Item>
                                        <Form.Item
                                            label={locales.pages.tasks.manage.template.basicInfo.index.source}
                                            wrapperCol={{ span: 7 }}
                                            name="taskSource"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select style={{ width: 112 }} disabled={true}>
                                                <For each="item" index="index" of={privates.inspectDataSources}>
                                                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                    <Row gutter={16}>
                                        <Col span={2} style={{ paddingRight: 0 }}>
                                            <div style={{ textAlign: 'end', lineHeight: '30px' }}>
                                                {locales.pages.tasks.manage.template.basicInfo.index.user}:
                                            </div>
                                        </Col>
                                        <Col span={4} style={{ paddingLeft: 0 }}>
                                            <div style={{ width: 200, display: 'inline-block', marginLeft: 8 }}>
                                                <AutoComplete
                                                    url={privates.computes._autoCompleteUrl}
                                                    isMulti={true}
                                                    value={this.state.inspectorList}
                                                    method={"GET"}
                                                    disabled={true}
                                                    queryParamName={"keyword"}
                                                    nameField={"realname"}
                                                    onChanged={actions.userListChanged}
                                                    enableEmptySearch={true}
                                                    placeholder={locales.business.info.pleaseSearchAndSelect}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                                <div className="col-lg-8 col-md-8 col-xs-12 col-12">
                                    <If
                                        condition={hasFeature && hasFeature(
                                            "task:template:info:view"
                                        )}
                                    >
                                        <form
                                            className="form-horizontal form-label-left"
                                        >
                                            <div className="form-group">
                                                <label
                                                    className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                    style={{padding:"0 15px"}}
                                                    htmlFor="task_name"
                                                >
                                                    {
                                                        locales.pages.tasks.manage.template.basicInfo.index
                                                            .name
                                                    }
                                                    <span className="required">*</span>
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <input
                                                        type={"text"}
                                                        id={"task_name"}
                                                        className={"form-control"}
                                                        maxLength={"255"}
                                                        value={privates.model.task.name}
                                                        onChange={actions.mutator("privates.model.task.name", e => e.target.value, true)}
                                                        disabled={!hasFeature ||
                                                            !hasFeature(
                                                                "task:template:info:edit"
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                    style={{padding:"0 15px"}}
                                                    htmlFor="task_remark"
                                                >
                                                    {
                                                        locales.pages.tasks.manage.template.basicInfo.index
                                                            .remark
                                                    }
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <textarea
                                                        id={"task_remark"}
                                                        className={"form-control"}
                                                        value={privates.model.task.remark}
                                                        onChange={actions.mutator("privates.model.task.remark", e => e.target.value, true)}
                                                        rows={"5"}
                                                        disabled={!hasFeature ||
                                                            !hasFeature(
                                                                "task:template:info:edit"
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* <div class="form-group">
                                                      <label class="control-label col-lg-2 col-md-3 col-xs-12 col-12" for="task_type">
                                                          {{UDL.pages.tasks.manage.template.basicInfo.index.type}}
                                                      </label>
                                                      <div class="form-control-static col-lg-7 col-md-7 col-xs-12 col-12">
                                                          {{udesk/enum-text model.task.type UDQ.enums.taskTypes}}
                                                      </div>
                                                  </div>
                                                  {{#if (eq model.task.type UDQ.enums.taskTypes.weightScore.id)}}
                                                      <div class="form-group">
                                                          <label class="control-label col-lg-2 col-md-3 col-xs-12 col-12" for="task_single">
                                                              {{UDL.pages.tasks.manage.template.basicInfo.index.singleScore}}
                                                          </label>
                                                          <div class="form-control-static col-lg-7 col-md-7 col-xs-12 col-12">
                                                              {{model.task.singleItemScore}}
                                                          </div>
                                                      </div>
                                                  {{/if}} 
                                            <div className="form-group">
                                                <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                                    <If
                                                        condition={hasFeature && hasFeature(
                                                            "task:template:info:delete"
                                                        )}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            style={{marginRight:"5px"}}
                                                            onClick={actions.deleteTask.params(
                                                                privates.model.task.id
                                                            )}
                                                        >
                                                            {locales.labels.delete}
                                                        </button>
                                                    </If>
                                                    <If
                                                        condition={hasFeature && hasFeature(
                                                            "task:template:info:edit"
                                                        )}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="btn btn-default"
                                                            onClick={actions.cancelChange}
                                                        >
                                                            {locales.labels.cancel}
                                                        </button>
                                                        <button type="button" className="btn btn-success" style={{marginLeft:"5px"}} onClick={actions.save}>
                                                            {locales.labels.save}
                                                        </button>
                                                    </If>
                                                </div>
                                            </div>
                                        </form>
                                    </If>
                                </div>
                            </div> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
