import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import { PageHeader, Form, Button, message, Space, InputNumber } from 'udesk-ui';
import { TaskSelect } from 'Component/pages/components/coach/task-select';
import styled from 'styled-components';
import { postIntelligentPartnerTaskInfosWordsCorrectBatch } from 'src/api/intelligentPartnerTaskInfos/words/correct/batch/index';
import {
    getCompanysIntelligentPartnerSpeechDetection,
    putCompanysIntelligentPartnerSpeechDetection,
} from 'src/api/companys/intelligent/partner/speech/detection';
import { getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

export const NOISE_Threshold = 6; // 噪音阈值，表示能接受的最大噪音音量
export const VOLUMN_Threshold = 20; // 音量阈值，表示能接受的最小说话音量

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

const tailLayout = {
    wrapperCol: { offset: 4 },
};

const PageContentWrapper = styled.div`
    padding-left: 120px;
`;

const CoachSpeechRecognition = () => {
    const [loading, setLoading] = useState(false);

    const onExcute = (values) => {
        setLoading(true);
        postIntelligentPartnerTaskInfosWordsCorrectBatch({
            taskIds: values.tasks || [],
            systemModule: getSystemModule(),
        })
            .then(() => {
                message.success(/* 执行成功 */UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.executionSuccessful);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const [form] = Form.useForm();

    const [initialValues] = useState<any>({
        maxNoiseVolume: NOISE_Threshold,
        minVoiceVolume: VOLUMN_Threshold,
    });

    const { run: getSpeechDetection } = useRequest(
        () => getCompanysIntelligentPartnerSpeechDetection(),
        {
            onSuccess: (res) => {
                form.setFieldsValue({
                    maxNoiseVolume: res.data?.maxNoiseVolume || NOISE_Threshold,
                    minVoiceVolume: res.data?.minVoiceVolume || VOLUMN_Threshold,
                });
            },
        }
    );

    const onFinish = () => {
        const values = form.getFieldsValue();
        putCompanysIntelligentPartnerSpeechDetection({
            maxNoiseVolume: values.maxNoiseVolume,
            minVoiceVolume: values.minVoiceVolume,
        }).then(() => {
            message.success(/* 保存成功 */UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.saveSuccessful);
            getSpeechDetection();
        });
    };

    return (
        <div className="udesk-qa-web-page">
            <PageHeader className="udesk-qa-web-page-header" title={/* 语音识别 */UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.speechRecognition} />

            <div className="udesk-qa-web-page-body">
                <div
                    className="udesk-qa-web-page-body-root qa-page-admin-systom-index"
                    style={{ padding: 16 }}
                >
                    <PageContentWrapper>
                        <Form onFinish={onExcute} style={{ marginBottom: 32 }} {...layout}>
                            <Form.Item label={/* 重新执行纠错 */UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.reExecuteErrorCorrection} name="tasks">
                                <TaskSelect
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    optionFilterProp="label"
                                    showSearch
                                    style={{ width: 500 }}
                                    extraPrams={{ dialogueModes: [1, 3] }}
                                />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}
                                    loading={loading}
                                >{/* 确认执行 */}{UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.confirmExecution}</Button>
                            </Form.Item>
                        </Form>

                        <Form
                            form={form}
                            onFinish={onFinish}
                            {...layout}
                            initialValues={initialValues}
                        >
                            <Form.Item label={/* 语音检测 */UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.voiceDetection} required>
                                <div style={{ marginBottom: 16 }}>
                                    <Space>
                                        <span>{/* 噪音检测 powerLevel ≤ */}{UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.noiseDetectionPowerLevel}</span>
                                        <Form.Item
                                            noStyle
                                            name="maxNoiseVolume"
                                            rules={[{ required: true, message: /* 请输入阈值 */UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.pleaseEnterTheThreshold }]}
                                        >
                                            <InputNumber min={0} />
                                        </Form.Item>
                                    </Space>
                                </div>

                                <div>
                                    <Space>
                                        <span>{/* 音量检测 powerLevel ≥ */}{UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.volumeDetectionPowerLevel}</span>
                                        <Form.Item
                                            noStyle
                                            name="minVoiceVolume"
                                            rules={[{ required: true, message: /* 请输入阈值 */UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.pleaseEnterTheThreshold }]}
                                        >
                                            <InputNumber min={0} />
                                        </Form.Item>
                                    </Space>
                                </div>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Space>
                                    <Button htmlType="reset">{/* 重置 */}{UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.reset}</Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={loading}
                                        loading={loading}
                                    >{/* 保存 */}{UdeskLocales['current'].pages.admin.system.coachSpeechRecognition.preserve}</Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </PageContentWrapper>
                </div>
            </div>
        </div>
    );
};

export default CoachSpeechRecognition;
