import React from 'react';
import Udesk from 'Udesk';
import TrainingHistoryIndexRoute from './route';
import { Button, PageHeader } from 'udesk-ui';
import './style.scss';
import AutoSizeTable from 'Component/common/auto-size-table';
import UdeskDatePicker from 'Component/common/udesk-date-picker';

export default class TrainingHistoryIndexTemplate extends TrainingHistoryIndexRoute {
    render() {
        let {
            startTime,
            endTime,
            columns,
            data,
            paging
        } = this.privates;
        let {
            actions,
            locales
        } = this;

        return (
            // <div className="training-history-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.trainingCenter.trainingHistory.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root training-history-index-page'>

                        <div className="training-history-index-page-body">
                            <div className="training-history-index-page-body-top">
                                <div className="training-history-index-page-body-top-date">
                                    <div className="training-history-index-page-body-top-date-des">
                                        {
                                            locales.components.pages.customerServiceRankList.select
                                                .date
                                        }
                                    </div>
                                    <UdeskDatePicker style={{ width: 246 }} allowClear={false} onChange={actions.selectTime} value={[startTime, endTime]} />
                                    <Button type="primary" onClick={actions.search} style={{ marginLeft: "16px" }}>
                                        {
                                            locales.components.pages.trainingCenter.trainingHistory
                                                .select.search
                                        }
                                    </Button>
                                    <Button
                                        onClick={actions.reset}
                                        style={{ marginLeft: "8px" }}
                                    >
                                        {
                                            locales.components.pages.trainingCenter.trainingHistory
                                                .select.reset
                                        }
                                    </Button>
                                </div>
                            </div>
                            <div className="training-history-index-page-body-table">
                                <AutoSizeTable
                                    columns={columns}
                                    dataSource={data}
                                    subtractedHeight={document.getElementsByClassName('training-history-index-page-body-top')[0] && window.getComputedStyle(document.getElementsByClassName('training-history-index-page-body-top')[0]).height}
                                    pagination={{
                                        onChange: actions.pageNumChange,
                                        total: paging.total,
                                        current: paging.pageNum,
                                        pageSize: paging.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['5', '10', '20', '50', '100'],
                                        onShowSizeChange: actions.pageSizeChange,
                                        showTotal: (total, range) => {
                                            return Udesk.utils.string.format(locales.components.pages.trainingCenter.trainingHistory.table.page, total, Math.ceil(paging.total / paging.pageSize));
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
