import RectangularCoordinateSystemBuilderClass from './rectangular-coordinate-system';
import common from '../../../../udesk/index';

class DoubleAxisesOptionsBuilderClass extends RectangularCoordinateSystemBuilderClass {
    getYAxisOptions(optionsOwner) {
        let {
            seriesColumns,
            yAxisOptions
        } = optionsOwner.props;

        let yAxis = [];
        seriesColumns.forEach((column, index) => {
            let yAxisItem = {};
            yAxisItem.type = "value";
            yAxisItem.min = 0;
            yAxisItem.name = column.name;
            yAxisItem.axisLabel = {
                formatter: '{value}'
            };

            if (yAxisOptions && Array.isArray(yAxisOptions)) {
                let yAxisOption = yAxisOptions[index] ? yAxisOptions[index] : null;
                if (index > 0 && yAxisOption == null) {
                    yAxisOption = yAxisOptions[0] || {};
                }

                if (yAxisOption == null) {
                    yAxisOption = {};
                }

                yAxisItem = common.utils.object.deepCopy(yAxisItem, yAxisOption);
            }
            yAxis.push(yAxisItem);
        });
        return yAxis;
    }

    _decorateSeries(series, optionsOwner) {
        series = super._decorateSeries(...arguments);
        for (var i = 0; i < series.length; i++) {
            let seriesItem = series[i];
            if (i === 0) {
                seriesItem.yAxisIndex = 0;
            } else {
                seriesItem.yAxisIndex = 1;
            }
        }
        return series;
    }

    _getChartType(seriesIndex) {
        return (seriesIndex === 0 ? "bar" : "line");
    }
}

export default DoubleAxisesOptionsBuilderClass;
