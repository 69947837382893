import React from 'react';
import { Form, InputNumber, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

export const Group = ({ children, group, field }) => {
    return (
        <>
            <Form.Item label={group.groupName}>
                <Space>
                    <div>
                        {/* 分值为 */}
                        {
                            UdeskLocales['current'].pages.coach.businessConfiguration
                                .scoreManagement.scoreModel.detail.components.model.group.theScoreIs
                        }
                    </div>
                    <Form.Item noStyle shouldUpdate={() => group.dimension === 2}>
                        {({ getFieldValue }) => {
                            const pointId = getFieldValue('groups')?.[1]?.children?.[0]?.pointId;
                            const t_pointId = group.children?.[0]?.options?.find(
                                (o) => o.pointType === 2
                            )?.value;

                            return (
                                <Form.Item name={[field.name, 'score']} style={{ marginBottom: 0 }}>
                                    <InputNumber
                                        style={{ width: 200 }}
                                        disabled={group.dimension === 2 && pointId === t_pointId}
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Space>
            </Form.Item>
            <Form.List name={[field.name, 'children']}>
                {(fields, { remove, add, move }) =>
                    fields.map((field, index) => children(field, group.children[index]))
                }
            </Form.List>
            {children}
        </>
    );
};
