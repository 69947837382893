// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PutReviewIntelligentPartnerNodeTraineeConfigByIdParam,
    PutReviewIntelligentPartnerNodeTraineeConfigByIdBody,
    BaseResponseIntelligentPartnerDialogNodeTraineeConfigFoundResponse,
} from '../../../../../types';

/**
 * 修改学员节点配置
 *
 * @export
 * @tags 智能陪练-学员对话节点配置
 * @link [PUT] /review/intelligentPartner/node/traineeConfig/{id}
 * @param id
 */
export function putReviewIntelligentPartnerNodeTraineeConfigById(
    data: PutReviewIntelligentPartnerNodeTraineeConfigByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerNodeTraineeConfigByIdParam, never> & {
        segments: PutReviewIntelligentPartnerNodeTraineeConfigByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeTraineeConfigFoundResponse> {
    return request<
        PutReviewIntelligentPartnerNodeTraineeConfigByIdBody,
        PutReviewIntelligentPartnerNodeTraineeConfigByIdParam,
        never
    >('/review/intelligentPartner/node/traineeConfig/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerNodeTraineeConfigById',
    });
}

export const meta = [
    {
        tags: ['智能陪练-学员对话节点配置'],
        path: '/review/intelligentPartner/node/traineeConfig/{id}',
        method: 'put',
    },
];
