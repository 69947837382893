export default {
  labels: {
    clear: "Vider",
    refresh: "Rafraîchir",
    nextStep: "Prochaines étapes",
    prevStep: "Étape précédente",
    closeDriver: "Quitter le guidage",
    complete: "Terminé",
    viewStatistics: "Voir les statistiques",
    colon: "：",
    splitter: "/",
    remark: "Remarques",
    correction: "Correction d'erreurs",
    save: "Sauvegarder",
    reset: "Réinitialisation",
    increase: "Ajouter",
    preview: "Preview",
    unit: "Article",
    cancel: "Annulation",
    all: "Tous",
    searchPlaceHolder: "Entrez une requête par mot clé",
    greaterThan: "Supérieure à",
    lessThan: "Moins que",
    second: "Secondes",
    description: "Description",
    column: "Colonnes",
    pleaseChoice: "Veuillez sélectionner",
    filter: "Filtrer",
    saveAndSubmit: "Sauvegarder et soumettre",
    submit: "Soumettre",
    appeal: "Plaintes",
    review: "Révision",
    choose: "Sélection",
    manage: "Gestion",
    delete: "Supprimer",
    add: "Nouveau",
    create: "Nouvelle construction",
    edit: "Modifier",
    name: "Le nom",
    describe: "Description",
    next: "Prochaines étapes",
    percent: "%",
    export: "Exporter",
    exportAll: "Tout exporter",
    confirm: "Déterminer",
    back: "Retour",
    versionUpdate:
      "C'est avec plaisir que nous vous informons que notre site vient de sortir une nouvelle version! Pour vous garantir une utilisation normale, nous avons besoin que vous sauvegardiez immédiatement votre travail actuel, puis que vous rafraîchississiez la page (en cliquant sur le bouton Actualiser de votre navigateur ou en appuyant sur la touche F5) pour que notre nouvelle version prenne effet. Merci beaucoup pour votre coopération!",
    more: "Plus",
    selected: "Sélectionné",
    deduct: "Boucle",
    maxScore: "Le plus",
    scoreUnit: "Points",
    pointUnit: "Une",
    comma: "，",
    view: "Voir",
    batchingImport: "Importation",
    deleteContent:
      "Confirmez - vous que vous souhaitez supprimer? Ne peut plus être restauré après la suppression",
    import: "Importation",
    uploadFile: "Télécharger des fichiers",
    downloadFile: "Exporter un fichier",
    upload: "Télécharger",
    click: "Cliquez sur",
    downloadTemplate: "Télécharger le template",
    fillInTheForm: "Remplir par Format",
    UploadExcelfile: "Télécharger Excel terminer l'importation par lots",
    uploadIdentical:
      "Si le nom de l'API existe, lors de l'importation, les données déjà présentes dans le système sont mises à jour",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Applicable",
    leftBracket: "(",
    rightBracket: ")",
    question: "Le problème",
    answer: "La réponse",
    content: "Contenu",
    search: "Rechercher",
    query: "Enquête",
    cancelEdit: "Annuler Modifier",
    noValue: "Pas de données pour le moment",
    selectAll: "Tout sélectionner",
    changeAGroup: "Changer un groupe",
    arrow: " ---> ",
    saveReview: "Révision de la soumission",
    saveSpotCheck: "Soumission d'inspection par tirage au sort",
    rapidSampling: "Inspection rapide",
    file: "Archive",
    download: "Télécharger",
    lastPage: "Page précédente",
    nextPage: "Page suivante",
    batchActions: "Opérations par lots",
    cancelDistribution: "Annuler la distribution",
    againDistribution: "Redistribution",
    deleteRecord: "Supprimer un enregistrement",
    oneOptionOpertor: "Inspecteur qualité auto - sélectionné",
    allOptionOpertor: "Tous les inspecteurs qualité",
    distributionInfoFirstOne: "Confirmer pour",
    distributionInfoFirstTwo:
      "Les enregistrements sont - ils redistribués? Veuillez sélectionner un objectif d'allocation",
    distributionInfoSecondOne: "Déterminer pour annuler",
    distributionInfoSecondTwo:
      "Est - ce que l'information de l'expéditeur d'un enregistrement d'entrée, après annulation, deviendra un enregistrement de révision non attribué.",
    distributionInfoSecondThree:
      "Est - ce que l'information de la personne assignée enregistrée, après l'annulation, deviendra un enregistrement aléatoire non attribué.",
    distributionInfoThirdOne: "Parmi les enregistrements sélectionnés, il y a",
    distributionInfoThirdTwo:
      "Les enregistrements révisés, les enregistrements révisés ne seront pas traités.",
    distributionInfoThirdThree:
      "Les enregistrements pris au sort, les enregistrements pris au sort ne seront pas traités.",
    exportError: "L'exportation a échoué",
    exportSuccess: "Exporter avec succès",
    deleteRecordInfo:
      "Confirmer voulez - vous supprimer un enregistrement {0}? Les données ne seront pas récupérées après la suppression",
    pleaseSetFilter: "Veuillez sélectionner un critère de filtre",
    noCountAfterFilter:
      "Aucune donnée exportée pour le moment, veuillez réinitialiser vos critères de filtre",
    redo: "Ré - exécution",
    batchMove: "Mouvement par lots",
    websiteName: "Contrôle de qualité intelligent",
    salesEnable: "Facilitation des ventes",
    systemName: "Contrôle de qualité intelligent",
    initSystemError:
      "Exception réseau ou échec de l'initialisation de la page, contacter le service client",
    day: "Jours",
    hour: "Heures",
    minute: "Points",
    questionMark: "？",
    personalSettings: "Paramètres personnels",
    logout: "Déconnexion",
    ok: "Déterminer",
    saveAs: "Enregistrer en tant que",
    pleaseEnter: "Veuillez entrer",
    executeMessage: {
      executeSuccess: "Exécution réussie de {0} Bar",
      executeError: "{0} l'exécution de la barre a échoué",
    },
    field: "Les champs",
    operator: "Opérateurs",
    value: "Valeur",
    placeholder: "Veuillez sélectionner",
    pleaseSelect: "Veuillez sélectionner",
    totalScore: "Score total",
    hyphen: "-",
    semicolon: "；",
    wfm: "Classe intelligente",
    user: "Utilisateurs",
    none: "Aucun",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Sauver le succès! Prend effet lorsque l'employé concerné actualise la page",
    saveAsName: "{0} (copie)",
    systemInformation: "Voir les données système",
    billingCenter: "Centre de facturation",
    warningMessage:
      "Quota restant insuffisant, veuillez le recharger dès que possible",
    fileTypeHolder:
      "Le format du fichier est incorrect, veuillez télécharger le fichier de format {0}.",
    dataNo: "Pas de données pour le moment",
    move: "Mobile",
    study: "Vous avez des points d'auto - apprentissage non marqués",
    studySuccess: "Marquer le succès",
    studyError: "Échec du marquage",
    editType: "Modifier Catégorie Tags",
    copyed: "Copié dans le presse - papiers",
    clickToCopy: "Cliquez sur copier",
    addFilterConditions: "Ajouter des critères de filtre",
    filterConditions: "Filtrer les conditions",
    messageTime: "Heure du message",
    messageType: "Type de message",
    pushType: "Canaux de message",
    messageContent: "Contenu du message",
    messageName: "Nom du message",
    messageStatus: "État du message",
    configuration: "Informations de configuration",
    sendTarget: "Envoyer une cible",
    guideUrl: "URL de démarrage",
    noticeDetail: "Détails du message",
    action: "Opérations",
    seeDetail: "Voir les détails",
    theDay: "{0} jour",
    theLastDayOfMonth: "Dernier jour du mois",
    ignore: "Ignorer",
    seeNow: "Voir maintenant",
    exportConfirmText: {
      export: "Total {0} données, confirmer l'exportation?",
      exportAll: "Confirmer l'exportation de tout?",
    },
    samrTagAdd: "Ajouter une étiquette",
    deletePopconfirmContent: "Confirmez - vous que vous souhaitez supprimer?",
    TheLastThirtyDays: "30 derniers jours",
    addNumber: "Plus",
    subNumber: "Moins",
    addStrategy: "Ajouter une politique",
    minutes: "Minutes",
    status: "Activer le statut",
    pushConfig: "Configuration push",
    category: "Classification",
    and: "Avec",
    interval: "Intervalle",
    switchOn: "Ouvrir",
    switchOff: "Fermé",
    batchDel: "Suppression par lots",
    exportTxt: "Exporter du texte TXT",
    playSpeed: "Double vitesse",
    messageLogBtn:
      "Voir les pass / conversations qui déclenchent les notifications",
    dataLink: "Liens de données",
    yes: "Oui",
    no: "Non",
    sentenceUnit: "Phrases",
    appealDetail: "Détails du dossier de plainte",
    detail: "Détails",
    default: "Par défaut",
    customer: "Le client",
    agent: "Service à la clientèle",
    sort: "Trier",
    applyNow: "Application",
    index: "Numéro de série",
    allColumns: "Colonnes pouvant être ajoutées",
    selectedColumns: "Colonnes ajoutées",
    syn: "Synchronisation",
    saveAndNext: "Enregistrer et créer l'article suivant",
    viewReference: "Voir la citation",
    referenceDetail: "Détails de la citation",
    goToQaList: "Inspection de la qualité",
    goToCheck: "Aller à l'inspection",
    businessCard: "Carte de visite",
    weapp: "Petit programme",
    redpacket: "Le paquet rouge",
    externalRedpacket: "Paquet rouge interopérable",
    sphfeed: "Numéro de vidéo",
    exportTaskCreated: "Exporter la tâche créée avec succès",
    plzGoTo: "Veuillez sauter à",
    importTaskCreated: "Importer la tâche créer avec succès",
    taskCreated: "Succès de la création de la tâche",
    reply: "Répondre",
    replied: "Répondu",
    release: "Publier",
    expandMore: "Développer les autres {0} réponses",
    comment: "Commentaires",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Veuillez avoir au moins un {0}",
      canNotBeEmptyFormat: "{0} ne peut pas être vide",
      repeatErrorTipFormat: "{0} avec répétition",
      getInformationFailedFormat:
        "Obtenir les informations {0} a échoué, vérifiez la connexion réseau ou réessayez plus tard",
      deleteSuccessFormat: "{0} suppression réussie.",
      deleteFailedFormat:
        "{0} la suppression a échoué, Veuillez réessayer plus tard.",
      saveSuccessFormat: "{0} sauvegarde réussie.",
      saveFailedFormat:
        "{0} la sauvegarde a échoué, Veuillez réessayer plus tard.",
      fileSuccessFormat: "{0} archive réussie.",
      fileFailedFormat: "{0} l'archive a échoué, Veuillez réessayer plus tard.",
      exportSuccessFormat: "{0} exportation réussie",
      exportFailedFormat: "{0} l'exportation a échoué, réessayez plus tard.",
      submitSuccessFormat: "{0} soumission réussie.",
      submitFailedFormat:
        "{0} la soumission a échoué, Veuillez réessayer plus tard.",
      confirmSuccessFormat: "{0} confirmation du succès.",
      confirmFailedFormat: "{0} confirmer l'échec, réessayez plus tard.",
      pleaseUploadFile: "Veuillez d'abord télécharger le fichier!",
      importFileSuccess: "Fichier importé avec succès!",
      importFildFailed: "L'importation du fichier a échoué!",
      deleteSuccess: "Supprimer avec succès!",
      deleteFailed: "La suppression a échoué, Veuillez réessayer plus tard!",
      illegalTitle: "Autorité insuffisante",
      illegalMessage:
        "Vous n'avez pas l'autorisation partielle ou totale pour l'étape suivante, contactez l'Administrateur configuration, appuyez sur le bouton OK pour quitter le démarrage",
      illegalMessageCurrent:
        "Vous n'avez pas les autorisations partielles ou complètes pour l'étape en cours, contactez l'Administrateur configuration, appuyez sur le bouton OK pour quitter le démarrage",
      skipMessage:
        "Si vous souhaitez sauter cette étape temporairement, appuyez sur sauter",
      errorMsg500:
        "La demande de serveur a échoué, vérifiez votre action ou Rafraîchissez réessayez",
      errorMsg404: "Ooops... La page que vous cherchez a disparu",
      errorMsg400:
        "La demande de serveur a échoué, Veuillez réessayer après avoir vérifié vos données",
      pageOverFlow:
        "Actuellement, seul l'affichage de 10 000 barres est pris en charge, au - delà Veuillez définir vos critères de filtre afficher",
      isHandAddSmartTag: "Est une étiquette intelligente ajoutée manuellement",
      defaultGradeMustAtLast: "La note par défaut doit être placée à la fin",
      AutomaticAllocationInspectorsIsNull:
        "Veuillez aller à la page informations de base ajouter un inspecteur qualité",
      timePickerTips:
        "Afin de ne pas affecter votre utilisation normale, 23: 00 ~ 2: 00 est le temps de maintenance du système et n'effectue pas de tâches chronométrées.",
      batchDeleteInfo:
        "Suppression réussie de la barre {0}, échec de la barre {1}, la raison de l'échec étant que les données ont déjà été utilisées.",
      batchDeleteTips: "Cochez {0} données au total, Confirmez la suppression?",
      correctionSuccess: "Correction d'erreur réussie",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError:
            "Ajouter jusqu'à 10 formulaires de contrôle de qualité",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "La somme des éléments de l'indicateur {0} doit être égale à 100",
          banProjectMaxErrorTip: "Interdiction ajouter jusqu'à 2000",
          deadlyProjectMaxErrorTip: "Éléments mortels ajouter jusqu'à 500",
        },
      },
    },
    info: {
      creatSuccess: "Créer un succès",
      creatError: "La création a échoué",
      editSuccess: "Modification réussie",
      editError: "La modification a échoué",
      deleteSuccess: "Supprimer avec succès",
      addSuccess: "Nouveau succès",
      deleteError: "La suppression a échoué",
      conditionError: "Erreur d'expression logique de l'opérateur",
      customJudgeLogic:
        "Erreur d'expression logique combinatoire conditionnelle personnalisée",
      exportError: "L'exportation a échoué",
      searchError: "Le filtrage a échoué",
      loadError: "{0} le chargement a échoué",
      saveSuccess: "Sauvegarder le succès",
      saveError: "Échec de la sauvegarde",
      fileSuccess: "Archiver avec succès",
      fileError: "L'archivage a échoué",
      cannotBeNull: "{0} ne peut pas être vide!",
      pleaseSearchAndSelect: "Veuillez saisir un mot - clé recherche",
      clickAndToggle: "Cliquez sur Sélectionner pour changer",
      deleteWarning:
        "{0} la suppression ne sera pas restaurée, êtes - vous sûr de vouloir continuer?",
      getOssAuthFailed:
        "Obtenir l'autorisation OSS a échoué, vérifiez la connexion réseau ou réessayez plus tard.",
      getInsightAuthFailed:
        "Obtenir l'autorisation Insight a échoué, vérifiez votre connexion réseau ou réessayez plus tard.",
      pictureUploadFailed: "Le téléchargement de l'image a échoué",
      pictureUploadFailedSize:
        "La taille de l'image dépasse la limite, le téléchargement de l'image échoue",
      wecomTagsSyncInfo:
        "Dernière mise à jour: {0}, synchroniser {1} informations",
      collectSuccess: "Succès de la collection",
    },
  },
  components: {
    common: {
      qcReportEcharts: {
        errorMessage: "L'obtention des données du rapport a échoué",
      },
      waveAudio: {
        noWaveMessage:
          "Pour préserver votre expérience, les enregistrements de plus de {0} minutes n'affichent pas de forme d'onde, vous pouvez jouer directement",
      },
      customFilter: {
        del: "Supprimer",
        edit: "Modifier",
        new: "Filtre personnalisé",
        apply: "Application",
        cancel: "Annulation",
        onlyApply: "Applicable uniquement",
        ohterSave: "Enregistrer en tant que",
        save: "Sauvegarder",
        customFilter: "Filtre couramment utilisé",
        reset: "Réinitialisation",
        holderKeyWord: "Veuillez entrer un mot clé",
        customFilterRule: "Filtrer les règles logiques:",
        customFilterName: "Nom du filtre:",
        holderCustomFilterName: "Veuillez entrer le nom du filtre",
        manualConfiguration: "Configuration manuelle",
        holderCustomRule: "Veuillez entrer une condition logique personnalisée",
        example: "Exemples",
        dragConfiguration: "Configuration du drag",
        condition: "Conditions",
        operation: "Opérations",
        infoDel: "Contenu glisser hors de la zone Supprimer",
        toConfigure: "Configuration",
        holderCustomFilterNameLength: "Nom du filtre longueur maximale 10",
        customFilterNameNone: "Le nom du filtre ne peut pas être vide",
        and: "Et",
        or: "Ou",
        not: "Non",
        delSuccess: "Supprimer avec succès",
        saveSuccess: "Sauvegarder le succès",
        updateSuccess: "Modification réussie",
        customFilterCondition: "Filtrer les conditions",
        conditionRule: "Règles des conditions",
        none: "Aucun",
        filterPlaceholder: "Veuillez entrer un mot clé",
      },
      labelTabs: {
        addThis: "Ajouter à ceci",
        goThis: "Déplacer ici",
        saveGo: "Préserver et former",
      },
      datePicker: {
        today: "Aujourd'hui",
        yesterday: "Hier",
        theDayBeforeYesterday: "Avant - hier",
        thisWeek: "Cette semaine",
        lastWeek: "La semaine dernière",
        thisMonth: "Ce mois - ci",
        lastMonth: "Le mois dernier",
        lastSevenDay: "7 derniers jours",
        lastThirtyDay: "30 derniers jours",
        thisQuarter: "Ce trimestre",
        lastQuarter: "Dernier trimestre",
        thisYear: "Cette année",
        lastYear: "L'année dernière",
        lastYearFromToday: "Dernière année",
      },
      pagination: { page: "Total {0}, {1} pages" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Charger un message plus tôt",
          bottom: "Charger plus de messages",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Tâches d'inspection de la qualité",
        informationCollectTasksManageTitle: "Tâches d'acquisition",
        customerServiceCenterTitle: "Centre de service à la clientèle",
        informationTitle: "Centre de données",
        intelligentAnalysisTitle: "Analyse intelligente",
        semanticIntelligence: "Intelligence sémantique",
        trainingCenterTitle: "Centre de formation",
        adminTitle: "Gestion du système",
      },
      qualityCheckingTask: {
        title: "Contrôle qualité des appels",
        index: {
          tipOne:
            "Contrôle de qualité d'appel pour évaluer la qualité du travail du Centre d'appels de vos employés, vous pouvez combiner votre propre entreprise et configurer un formulaire de contrôle de qualité approprié",
          tipTwo:
            "Des règles appropriées peuvent être définies pour les tâches d'inspection de la qualité, y compris le dépistage, l'échantillonnage, etc. Après que l'inspecteur de qualité ait évalué, le service à la clientèle peut voir les résultats de l'inspection de qualité et se plaindre davantage,",
          addTaskTitle: "Ajouter une tâche de contrôle qualité",
          deleteTipTitle: "Supprimer une tâche de contrôle de qualité",
          deleteTipContent:
            "Non récupérable après la suppression de la tâche de contrôle de qualité, confirmez - vous la suppression?",
          checkingTask: "Tâches d'inspection de la qualité",
          checkingTaskList: "Liste des tâches d'inspection de qualité",
          formList: "Liste des formulaires",
          viewHelpDocuments: "Voir les documents d'aide",
        },
        manage: {
          taskSetting: {
            title: "Configuration des tâches",
            form: "Formulaire",
            basicInfo: "Informations de base",
            visibleRange: "Gamme visible",
            visibleRangeTip:
              "Le service à la clientèle sélectionné, le Groupe de service à la clientèle peut afficher cette tâche d'inspection de la qualité, en plus de combiner les paramètres d'autorisation pour cette tâche, le Groupe de service à la clientèle, le service à la clientèle, l'inspecteur de la qualité, etc.",
            automaticSampling: "échantillonnage automatique",
            automaticSamplingTip:
              "Vous pouvez définir des règles pour l'échantillonnage automatique afin que le système ajoute régulièrement des tâches de contrôle de la qualité selon les règles que vous avez définies, telles que le tirage hebdomadaire de 5% des appels de la semaine dernière.",
            samplingRate: "Fréquence d'échantillonnage",
            samplingTime: "Temps d'échantillonnage",
            samplingRange: "Gamme d'échantillonnage",
            samplingRule: "Règles d'échantillonnage",
            fillterCondition: "Filtrer les conditions",
            workflow: "Processus de travail d'inspection de qualité",
            appealsTime: "Délai de réclamation autorisé",
            appealsTimeTipOne: "Après soumission du score",
            appealsTimeTipTwo: "Dans les jours",
            appealsTimeTipThree:
              "Au - delà du délai de réclamation autorisé, le statut du contrôle de qualité des dossiers est automatiquement modifié en « confirmé ».",
            auditTime: "Temps de traitement de l'audit",
            auditTimeTipOne: "Après avoir déposé une plainte",
            auditTimeTipTwo: "Dans les jours",
            auditTimeTipThree:
              "Le temps de traitement de l'audit est dépassé, les enregistrements maintiennent le score original et le statut de l'inspection de qualité est automatiquement modifié à « confirmé».",
            noAppealsMode: "Modèle sans plainte",
            appealsMode: "Autoriser le mode de plainte",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Service à la clientèle",
            customerServicesGroup: "Groupe de service à la clientèle",
            surveyList: "Liste des évaluations",
            customFields: "Champs personnalisés",
            fieldConfig: "Configuration des champs",
            from: "À partir de",
            owner: "Responsable",
            inspectorAndReviewerAuthority: "Autorité d'examen de la qualité",
            inspector: "Inspecteur qualité",
            reviewer: "Examinateur",
            inspectorTip: "L'Inspecteur qualité peut évaluer la tâche",
            reviewerTip: "Le réviseur peut évaluer la tâche",
            dataStatisticsAuthority:
              "Autorisations statistiques sur les données",
          },
          samplingRecords: {
            title: "Enregistrement de l'échantillonnage",
            index: {},
            manualSampling: {
              title: "Échantillonnage",
              fillterCondition: "Filtrer les conditions",
              samplingRule: "Règles d'échantillonnage",
              agent: "Service à la clientèle",
              type: "Type",
              result: "Résultats",
              evaluate: "Évaluation",
              relayNumber: "Numéro de relais",
              source: "Sources",
              task: "La Mission",
              queueState: "État de la file d'attente",
              callHanger: "Partie appelant",
              queueTime: "La queue prend du temps",
              talkTime: "Durée de l'appel",
              bellTime: "Durée de sonnerie",
              recordCountTipOne: "Total",
              recordCountTipTwo: "Enregistrement d'article",
              customerServices: "Service à la clientèle",
              customerServicesGroup: "Groupe de service à la clientèle",
              surveyList: "Liste des évaluations",
              customFields: "Champs personnalisés",
              fieldConfig: "Configuration des champs",
              defaultFields: "Champs par défaut",
            },
          },
          taskList: {
            title: "Liste des tâches",
            index: {
              batchDelete: "Suppression par lots",
              batchSubmit: "Soumission par lots",
              batchConfirm: "Confirmation de lot",
              itemFields: "Score champ projet",
              categoryFields: "Champs de classification secondaire",
              forbidsAndDeadlies: "Interdits et mortels",
              scoreFieldList: "Liste des champs d'éléments de score",
              customerName: "Nom du client",
              cellphone: "Téléphone",
              email: "Boîte aux lettres",
              organization: "La société",
              description: "Description",
              tags: "Étiquettes",
              taskUserRole: "Rôle utilisateur de la tâche actuelle",
            },
            workBench: {
              title: "Détails de l'enregistrement",
              checkForm: "Formulaire de contrôle qualité",
              waitEvaluate: "À évaluer",
              alreadyEvaluate: "évalué",
              waitSubmit: "À soumettre",
              alreadySubmit: "Soumis",
              waitConfirm: "À confirmer",
              alreadyConfirm: "Confirmé",
              waitReview: "À réviser",
              alreadyReview: "Examiné",
              alreadyDone: "Terminé",
              comment: "Remarques sur l'évaluation",
              totalScore: "Score total",
              score: "Points",
              remark: "Remarques",
              changeRecords: "Enregistrement des changements",
              subject: "Thèmes",
              secondLevelScore: "Score de deuxième niveau",
              deadlyList: "Article fatal",
              forbidList: "Articles interdits",
              addToCheckTask: "Ajouter à la tâche contrôle qualité",
              submitCheckRemark: "Évaluation de la qualité soumise",
              changeCheckRemark: "Révision de l'évaluation de la qualité",
              doneCheckRemark: "Évaluation de contrôle de la qualité terminée",
              submitCheckReview: "Examen de la qualité soumis",
              submitCheckAppeal: "Plainte de contrôle de qualité déposée",
              saveFlowSuccess: "Évaluer le succès.",
              saveFlowFailed:
                "L'évaluation a échoué, Veuillez réessayer plus tard.",
              FormDetail: "Détails du formulaire",
              callDetail: "Détails de l'enregistrement des appels",
              sampleCalllogDetail: "Détails de l'enregistrement",
              appealReasonTip:
                "Veuillez entrer un motif de plainte (obligatoire)",
              appeal: "Plaintes",
              appealReason: "Motifs de plainte",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} formulaire de contrôle de qualité",
        basicInformationTitle: "Informations de base",
        indicatorsProjectTitle: "Projet d'indicateurs",
        specialProjectTitle: "Articles spéciaux",
        totalScore: "Score total",
        indicatorsName: "Nom de l'indicateur",
        score: "Score",
        singleScore: "Points individuels",
        weight: "Poids",
        maxDeductScore: "Maximum de points déductibles",
        add: "Augmentation",
        detail: "Détails du formulaire",
        list: {
          title: "Formulaire de contrôle qualité",
          tipOne:
            "La configuration du formulaire d'inspection de la qualité est la première étape du travail d'inspection de la qualité et vous pouvez combiner les activités de votre entreprise pour configurer le formulaire d'inspection de la qualité le plus approprié.",
          tipTwo:
            "Nous soutenons de nombreux formulaires tels que le score direct, le score de poids, le système de déduction et bien plus encore. Il est recommandé d'ajouter les règles au système une fois que vous les avez définies.",
        },
        basicInformation: {
          formTemplate: "Modèle de formulaire",
          directScore: "Score direct",
          weightedScore: "Score de poids",
          deductScore: "Déduction de points",
          directScoreSecondLevel: "Score direct - niveau 2",
          weightedScoreSecondLevel: "Score de poids - niveau 2",
          deductScoreSecondLevel: "Points déduits - niveau II",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Articles interdits",
          banProjectTip:
            "Peut être utilisé pour cocher au moment de l'évaluation de la qualité, il peut être considéré comme une violation ou comme un complément aux remarques, sans affecter le score réel.",
          deadlyProject: "Article fatal",
          deadlyProjectTip:
            "L'élément fatal peut être utilisé pour cocher au moment de l'évaluation de la qualité, si l'élément fatal est coché, cette tâche d'évaluation de la qualité est directement évaluée à 0 point",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Contrôle qualité des appels",
          entiretyReportTitle: "Déclaration globale",
          serviceGroupReportTitle:
            "Rapports de groupe de service à la clientèle",
          serviceReportTitle: "Déclaration de service à la clientèle",
          entiretyReport: {
            pandect: "Aperçu général",
            contrast: "Le contraste",
            trend: "Les tendances",
            analyze: "Analyse",
            totalScore: "Score total",
            banProject: "Articles interdits",
            deadlyProject: "Article fatal",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Liste des résultats du contrôle qualité",
          taskSelectPlaceholder: "Choisir une tâche de contrôle qualité",
          agentSelectPlaceholder: "Choisir un service client",
          refresh: "Rafraîchir",
          all: "Tous",
          unread: "Non lu",
          inComplaint: "Dans la plainte",
          reviewed: "Examiné",
          totalScore: "Évaluation générale",
          detail: "Détails de l'inspection de qualité",
          audioText: "Texte enregistré",
          callInfo: "Informations sur les appels",
          ticketInfo: "Informations sur les ordres de travail",
          wechatInfo: "Informations sur la session",
          dialogText: "Texte du dialogue",
          dialogInfo: "Informations sur la conversation",
          customer: "Le client",
          agent: "Service à la clientèle",
          none: "Pas encore de données de contrôle qualité",
          appealSuccess: "Plainte réussie!",
          appealFailed: "La plainte a échoué, Veuillez réessayer plus tard!",
          pointListIsNull:
            "Le point de contrôle qualitatif ne peut pas être vide!",
          pleaseSelectPoint: "Veuillez choisir un point de contrôle qualité!",
          unsupportedFormat: "Le format n'est pas supporté",
          shardingInformation: "Ci - dessous les fractions {0}",
          smartTags: "Étiquettes intelligentes",
          businessRecords: "Dossiers d'affaires",
          selectedPointsWarning:
            "Veuillez cocher l'élément de contrôle de qualité pour lequel vous souhaitez vous plaindre, spécifier les énoncés pertinents pour l'élément de plainte séparément, puis appuyez sur soumettre.",
          applyReason: "Motifs de la plainte (50 mots maximum)",
          chatMessageTypes: {
            all: "Tous",
            text: "Texte",
            image: "Images",
            agree: "Consentement au contenu de la session",
            video: "Vidéo",
            voice: "Voix",
            file: "Documents",
            emotion: "Expressions",
            link: "Liens",
            chatrecord: "Historique de chat",
            mixed: "Messages mixtes",
            default: "Message non supporté",
            deleteFriend: "Supprimer un ami",
            redpacket: "Le paquet rouge",
            card: "Carte de visite",
            disAgree: "Pas d'accord avec le contenu de la session",
            weapp: "Petit programme",
            externalRedpacket: "Paquet rouge interopérable",
            meetingVoicevCall: "Message d'appel",
            uRL: "Page Web",
            replyTimeout: "Délai de réponse",
            sphfeed: "Numéro de vidéo",
            chatRecordTitle: "Historique de chat",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Score total",
            actualScore: "Score",
            actualGrade: "Évaluation",
            appeal: "Plaintes",
            remark: "Remarques manuelles",
            none: "Pas encore de données de contrôle de qualité",
            pointEdit: "Points de contrôle qualité modifier la classification",
          },
          changeList: {
            title: "Enregistrement des changements",
            user: "Utilisateurs",
            point: "Point de contrôle de qualité",
          },
          relatedRecord: {
            title: "Enregistrements associés",
            qualityList: "Tâches d'inspection de la qualité",
            none: "Aucun",
            check: "Voir",
            loadMore: "Charger plus",
          },
        },
      },
      grade: {
        title: "Évaluation de l'inspection de qualité",
        subTitle:
          "L'évaluation de l'inspection de qualité est utilisée pour classer les objets d'inspection de qualité après l'achèvement de l'évaluation de l'inspection de qualité, par exemple Configurable en dessous de 60 pour être non qualifié, 60 - 85 pour être bon et supérieur à 80 pour être excellent.",
        list: {
          up: "Déplacement vers le Haut",
          down: "Descendre",
          del: "Supprimer",
          case: "Rejoindre automatiquement la Bibliothèque de cas",
          orderCase: "Bibliothèque de cas par défaut",
          info: "Évaluations par défaut aucune condition de correspondance, ne peut pas être supprimé, obtenez une évaluation par défaut si vous ne répondez à aucune des évaluations ci - dessus",
          add: "Ajouter une évaluation",
          save: "Sauvegarder",
          close: "Annulation",
          max: "Évaluation de contrôle de qualité jusqu'à 20 configurables",
          name: "Sans nom",
          success: "Sauvegarder le succès",
        },
      },
      qualityInspectionList: {
        title: "Liste des contrôles de qualité",
        fastFilter: "Violation du contrôle qualité",
        fastFilterTip:
          "Un score réduit est une violation, un score plus manqué est une violation",
        titleWechat: "Liste des analyses",
        titleCollect: "Liste d'acquisition",
        status: "État de l'analyse",
        reQualityInspectionConfig:
          "Options pour les tâches de contrôle de la qualité",
        pleaseSelectRecheckPoints:
          "Veuillez sélectionner le point de contrôle de qualité qui doit être recalculé",
        beginReQualityInspection: "Commencer l'exécution",
        cancelReQualityInspection: "Annuler une tâche",
        reQualityInspection: "Vérification de la qualité",
        reQualityInspectionWechat: "Réanalyse",
        reQualityInspectionUnderWay: "La Mission de requalification...",
        reQualityInspectionUnderWayTip:
          "Quitter la page après avoir fermé la fenêtre n'affecte pas le déroulement de la tâche",
        reQualityInspectionDone: "Le contrôle de requalification est terminé",
        reQualityInspectionDoneTip:
          "Maintenant terminé avec succès l'inspection de requalification, veuillez cliquer sur le bouton de confirmation",
        endReQualityInspection: "Fin de la Mission",
        alreadyDoneTip: "Les {0} barres sont terminées",
        totalTip: "Total {0} articles",
        endReInspectionSuccess:
          "La Mission de contrôle de la requalification est terminée",
        closeWindow: "Fermer la fenêtre",
        endReInspectionFailed:
          "La tâche de contrôle de qualité a échoué, Veuillez réessayer plus tard!",
        detail: {
          title: "Détails de l'inspection de qualité",
          wechatTitle: "Détails de l'analyse",
          titleCollect: "Détails de l'acquisition",
          callIndex: "Appels associés",
          textIndex: "Conversations associées",
          fileConfirm: "Confirmer le dépôt?",
          fileConfirmDesc:
            "Le score de contrôle de qualité ne sera pas modifiable après l'archivage, veuillez confirmer si vous Archivez",
        },
        sysCloumnsContal: "Gestion des champs de colonnes système",
        personCloumnsContal: "Gestion des champs de colonnes personnelles",
      },
      spotCheckList: {
        title: "Liste des inspections",
        onlyMyTip: "Voir seulement mes sweepstakes",
        plInspector: "Veuillez sélectionner un examinateur",
        allInspector: "Voir tous",
        seInspector: "Voir l'inspecteur désigné",
        myInspector: "Voir seulement mon",
        noInspector: "Voir les inspecteurs non assignés",
        detail: { title: "Détails de l'inspection" },
      },
      reviewList: {
        title: "Liste de révision",
        onlyMyTip: "Voir seulement mes révisions",
        plInspector: "Veuillez sélectionner un réviseur",
        allInspector: "Voir tous",
        seInspector: "Voir le réviseur désigné",
        myInspector: "Voir seulement mon",
        noInspector: "Voir les réviseurs non assignés",
        detail: { title: "Détails de la révision" },
      },
      caseBaseList: {
        title: "Bibliothèque de cas",
        del: "Supprimer",
        delTitle: "Tout n'est pas sauvegardé après la suppression",
        cancel: "Annulation",
        actions: "Opérations",
        seeCaseBase: "Voir le cas",
        seeDetail: "Voir les détails",
        edit: "Modifier",
        save: "Sauvegarder",
        addCaseBase: "Ajout d'une bibliothèque de cas",
        export: "Exporter",
        ok: "Déterminer",
        tip: "Données d'appel et de conversation typiques de la liste de contrôle de qualité, qui peuvent être enregistrées dans la Bibliothèque de cas pour faciliter l'apprentissage et le partage",
        detail: {
          choiceCaseBase: "Veuillez sélectionner un cas",
          delCaseBase: "Supprimer un cas",
          addCaseBase: "Ajouter un cas",
          successed: "Sauvegarder le succès",
          info: "Veuillez d'abord enregistrer ou annuler ce que vous modifiez",
          caseType: "Veuillez sélectionner une bibliothèque de cas à rejoindre",
        },
      },
      testSetList: {
        title: "Ensemble de tests de contrôle de qualité",
        tip: "Les données de l'inspection de la qualité sont sauvegardées dans le jeu d'essais de contrôle de la qualité, ce qui facilite l'optimisation des conditions d'inspection de la qualité et la configuration des règles d'inspection de la qualité pour certains éléments d'erreur d'inspection machine après l'inspection.",
        refresh: "Rafraîchir",
        reQualityInspectionUnderWay: "Réévaluation de la Mission...",
        reQualityInspectionUnderWayTip:
          "Quitter la page après avoir fermé la fenêtre n'affecte pas le déroulement de la tâche",
        reQualityInspectionDone: "Réévaluation terminée",
        reQualityInspectionDoneTip:
          "La réévaluation est maintenant terminée avec succès, veuillez cliquer sur le bouton de confirmation",
        endReQualityInspection: "Fin de la Mission",
        alreadyDoneTip: "Les {0} barres sont terminées",
        totalTip: "Total {0} articles",
        endReInspectionSuccess: "Mission de réévaluation terminée",
        closeWindow: "Fermer la fenêtre",
        endReInspectionFailed:
          "La tâche de réévaluation a échoué, Veuillez réessayer plus tard!",
        recheckPoint:
          "Recalculer les scores de points de contrôle de qualité et la précision",
        recheckPointTitle: "Options de réévaluation",
        delete: {
          reQualityInspectionUnderWay: "Supprimer des tâches en masse...",
          reQualityInspectionUnderWayTip:
            "Quitter la page après avoir fermé la fenêtre n'affecte pas le déroulement de la tâche",
          reQualityInspectionDone:
            "La tâche de suppression par lots est terminée",
          reQualityInspectionDoneTip:
            "La suppression par lots est maintenant terminée avec succès, veuillez cliquer sur le bouton confirmer",
          endReQualityInspection: "Fin de la Mission",
          alreadyDoneTip: "Les {0} barres sont terminées",
          totalTip: "Total {0} articles",
          endReInspectionSuccess:
            "La tâche de suppression en masse est terminée",
          closeWindow: "Fermer la fenêtre",
          endReInspectionFailed:
            "La tâche de suppression par lots a échoué, Veuillez réessayer plus tard!",
        },
        reultList: {
          assessmentTime: "Heure de la dernière évaluation",
          assessmentAll: "Évaluation totale",
          strip: "Article",
          spot: "Le point",
          deviation: "Déviations",
          accuracyRate: "Précision",
          info: "Aucun résultat d'évaluation, veuillez évaluer",
          onlyDeviationInfo: "Voir uniquement les enregistrements de déviation",
          againAssessment: "Réévaluation",
          screen: "Filtrer",
          batchDelete: "Suppression par lots",
          matching: "Match",
          ok: "Oui",
          noCancel: "Non",
        },
      },
      smartWordsLibrary: {
        title: "Thésaurus intelligent",
        tip1: "Synonymes: utilisé pour réduire la quantité d'entraînement pour les étiquettes sémantiques, phrases similaires pour les synonymes sans entraînement répété",
        tip2: "Noms professionnels: optimisation de la transcrite, optimisation de la compréhension du langage naturel, amélioration de la précision de la reconnaissance, précision de l'analyse pour identifier les noms propres tels que les noms d'entreprise inexacts, les noms de produits, le vocabulaire de l'industrie, etc.",
        tip3: "Mots clés: Concentrez - vous sur les mots que vous souhaitez cibler dans word cloud Analytics pour aider l'IA Analytics Hotspot à s'adapter à votre entreprise actuelle",
        tip4: "Mots de blocage: bloque les mots sur lesquels vous ne voulez pas vous concentrer dans word cloud Analytics pour aider les points chauds d'analyse de l'IA à s'adapter à l'entreprise actuelle",
        tip5: "Poids des mots: pour l'optimisation de la correction d'erreur de la reconnaissance des étiquettes sémantiques, en optimisant l'effet de reconnaissance sémantique dans un scénario spécifique en modifiant le poids d'influence du mot dans la phrase, selon les besoins",
        tip6: "Données de matière: saisie de texte de paragraphe volumineux pour leurs scénarios d'affaires respectifs pour la correction d'erreur de transcription et la formation ASR, amélioration de la précision de la transcription ASR",
        upload: {
          uploadSynonymWords: "Importer des synonymes",
          uploadProfessionalWords: "Importer des noms professionnels",
          uploadAttentionWords: "Importer un mot de préoccupation",
          uploadIgnoreWords: "Importer des mots ignorés",
          uploadWordsWeight: "Importer des mots de poids",
          uploadTypo: "Importer un ensemble de mots d'erreur",
          uploadCorpusData: "Importer des données de matière",
          uploadCorpusContent: "Importer le contenu des données de matière",
          firstUploadStep:
            "1. Téléchargez le modèle, remplissez - le par format de modèle",
          downloadTemplateMessage: "Télécharger le modèle:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Téléchargez un fichier Excel conforme au format du modèle en cliquant sur le bouton importer",
          secondUploadCorpusDataStep:
            "2. Téléchargez un fichier txt conforme au format du modèle en cliquant sur le bouton importer",
          postUploadDataFail: "La soumission des données téléchargées a échoué",
          pleaseUploadTemplate:
            "Veuillez télécharger le fichier Excel / json / txt",
          templateNeedJSONOrXLSX:
            "Les modèles de fichiers ne peuvent être qu'au format json ou xlsx",
          templateNeedTxt:
            "Les modèles de fichiers de données de matière ne peuvent être que des fichiers txt",
          uploadSuccess: "Importation réussie",
          uploadFailed: "L'importation a échoué",
          downloadError: "Le téléchargement a échoué",
          downLoadTxtTitle: "Contenu du matériel.Txt",
        },
        navTabNames: {
          synonymWords: "Synonymes",
          professionalWords: "Noms professionnels",
          attentionWords: "Mots de préoccupation",
          ignoreWords: "Ignorer les mots",
          wordsWeight: "Poids des mots",
          corpusData: "Données de matière",
          typoLibrary: "Ensemble de mots d'erreur",
        },
        synonymWords: {
          createSynonymWords: "Nouveaux synonymes",
          editSynonymWords: "Edit synonymes",
          addSynonymWords: "Ajouter des synonymes",
          pleaseEnterStandardWords: "Veuillez entrer le mot standard",
          synonymWordsExists: "Ce synonyme existe déjà et ne peut être ajouté",
          synonymWordsCanNotNull:
            "Les synonymes ajoutés ne peuvent pas être vides",
          pleaseAddSynonymWords: "Veuillez ajouter des synonymes",
          addSuccess: "Nouveau succès",
          editSuccess: "Modification réussie",
          deleteSuccess: "Supprimer avec succès",
          deleteError: "La suppression a échoué",
          required: "*",
        },
        professionalWords: {
          create: "Nouveau nom professionnel",
          edit: "Nom professionnel Modifier",
          addTargetWords: "Ajouter un mot de remplacement",
          targetWordsExists:
            "Ce mot de remplacement existe déjà et ne peut pas être ajouté",
          targetWordsCanNotNull:
            "Le mot de remplacement ajouté ne peut pas être vide",
          pleaseAddTargetWords: "Veuillez ajouter un mot de remplacement",
          pleaseEnter: "Veuillez entrer un nom professionnel",
          wordsExists: "Ce nom professionnel existe déjà",
          createSuccess: "Ajouté avec succès",
          editSuccess: "Modification réussie",
          deleteSuccess: "Supprimer avec succès",
          deleteError: "La suppression a échoué",
          saveError: "Échec de la sauvegarde",
          addTargetWordsMaxNumberError:
            "Vous pouvez ajouter jusqu'à 100 mots de remplacement",
          addHomophonyWordsMaxNumberError:
            "Les mots de blocage peuvent être ajoutés jusqu'à 100",
        },
        attentionWords: {
          create: "Nouveau mot de préoccupation",
          edit: "Suivre les mots Modifier",
          pleaseEnter: "Veuillez entrer un mot de préoccupation",
          wordsExists: "Ce focus existe déjà",
          createSuccess: "Ajouté avec succès",
          editSuccess: "Modification réussie",
          deleteSuccess: "Supprimer avec succès",
          deleteError: "La suppression a échoué",
        },
        ignoreWords: {
          create: "Nouveau mot ignoré",
          edit: "Ignorer les mots Modifier",
          pleaseEnter: "Veuillez entrer le mot ignorer",
          wordsExists: "Le mot ignorer existe déjà",
          createSuccess: "Ajouté avec succès",
          editSuccess: "Modification réussie",
          deleteSuccess: "Supprimer avec succès",
          deleteError: "La suppression a échoué",
        },
        wordsWeight: {
          createWordsWeight: "Nouveau mot de poids",
          editWordsWeight: "Modifier les mots de poids",
          addWordsWeight: "Ajouter un mot de poids",
          pleaseEnterWeightWords: "Veuillez entrer un mot de poids",
          pleaseEnterWeight: "Veuillez entrer un poids",
          weightNeedInZeroToHundred: "Poids doit être entre 0 ~ 100",
          synonymWordsExists: "Ce mot de poids existe déjà",
          weightNeedNumber: "Poids nécessite un format entier",
          standardWeightInformation:
            "Le poids du Benchmark est de 50, plus le poids est important, plus il a d'impact dans l'analyse",
          createSuccess: "Ajouté avec succès",
          editSuccess: "Modification réussie",
          deleteSuccess: "Supprimer avec succès",
          deleteError: "La suppression a échoué",
        },
        corpusData: {
          createCorpusData: "Nouvelle donnée de matière",
          editCorpusData: "Modifier les données de matière",
          addCorpusData: "Ajouter des données de matière",
          pleaseEnterCorpusDescription:
            "Veuillez entrer une description du matériau",
          pleaseEnterCorpusContent: "Veuillez entrer le contenu du matériel",
          synonymWordsExists: "Ces données de matière existent déjà",
          CorpusContentWordsOverSetting:
            "Le nombre de mots de contenu compte plus de 20 000 mots,",
          download: "Télécharger",
          upload: "Télécharger",
          see: "Voir",
          canNotSave:
            "Veuillez utiliser la fonction d'importation, sinon vous ne pourrez pas enregistrer",
          createSuccess: "Ajouté avec succès",
          editSuccess: "Modification réussie",
          deleteSuccess: "Supprimer avec succès",
          deleteError: "La suppression a échoué",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Le mot correct",
            wrongWord: "Le mauvais mot",
            wrongWordCount: "Nombre de mauvais mots",
            wrongWordTip: "Appuyez sur Entrée pour ajouter",
            status: "Efficacité",
          },
          create: "Nouvelle collection de mots d'erreur",
          edit: "Modifier le mauvais ensemble de mots",
          tip: "L'ensemble de mots d'erreur est utilisé pour optimiser l'effet de la \"détection de mots d'erreur\", par exemple \"remboursement\" envoyé par erreur comme \"retrait\", vous pouvez définir les mots d'erreur communs ainsi que les mots corrects via cette fonction, en prenant note de l'enregistrement le lendemain de l'entrée en vigueur.",
          onlyChinese: "Seuls les mots chinois sont supportés",
          wrongWordLengthErr: "Chaque mot faux doit être de 2 à 10 mots",
        },
      },
      semanticTags: {
        title: "Étiquettes sémantiques",
        current: "Étiquettes sémantiques actuelles",
        reference1: "Tâche de contrôle de qualité: {0} - {1}",
        reference2: "Étiquette intelligente: {0}",
        reference3: "Pré - traitement des données: {0}",
        reference4: "Base de connaissances: {0}",
        index: { import: "Importer des étiquettes sémantiques" },
        detail: {
          new: "Nouvelle étiquette sémantique",
          edit: "Modifier les étiquettes sémantiques",
          name: "Le nom",
          standardSentences: "Phrase standard",
          effectiveness: "Efficacité",
          similarSentences: "Phrases similaires",
          tip: "Prise en charge de l'appel de l'API entity à l'aide de ; , exemple: votre numéro de téléphone est \\ \\ sys \\ \\ Le téléphone \\?",
          excludeSimilarSentences: "Exclusion des phrases similaires",
          intelligentRecommend: "Smart recommander contenu similaire",
          fastTraining: "Entraînement rapide:",
          qualify: "Régulière",
          recallKeyWord: "Mots clés de rappel",
          excludeKeywords: "Exclure les mots clés",
          excludeKeywordsInfo1:
            "Exclure les mots - clés, la règle prend effet de préférence à toute logique, si l'exclusion est atteinte, cette étiquette sémantique n'est pas atteinte",
          keyWordInfo1:
            "Les mots - clés de rappel prennent en charge le remplissage multiple par ligne, séparés par des virgules en anglais, lorsque le remplissage multiple est logique pour tous les coups.",
          keyWordInfo2:
            "Le mot clé de rappel prend en charge le remplissage de plusieurs lignes, lorsque vous remplissez plusieurs lignes, la logique est de frapper tout ce qui peut être valide.",
          recallRegular: "Règles de rappel",
          excludeRegular: "Exclusion des règles",
          similaSentences: "Exclusion des phrases similaires",
          simim: "Similarité",
          keyWordInfo3:
            "L'exclusion d'une phrase de similarité prend effet de manière préférentielle sur toute logique, si une phrase d'exclusion de similarité est atteinte, cette étiquette sémantique n'est pas atteinte, le degré de similitude par défaut est de 80%, plus le degré de similitude est élevé, plus il est similaire.",
          keyWordInfo4:
            "Les règles sont comme des règles, avec un début et une fin présents par défaut. Ne doit pas être utilisé avec des crochets, par exemple [non xxxx]",
          keyWordInfo5:
            "Les fonctionnalités des modules avancés sont généralement des fonctionnalités expérimentales préparées pour les experts familiers avec l'opération, et si vous n'êtes pas sûr de ce que vous devez faire, il est conseillé de ne pas modifier cette section.",
          keyWordInfo6:
            "La fonction de détection de phrases croisées donne aux étiquettes sémantiques la capacité de détecter à travers les phrases.",
          keyWordInfo7: "Au plus sur le même rôle continu",
          keyWordInfo8:
            "Ignorer les différentes phrases de rôle qui ne sont pas plus longues que",
          keyWordInfo9: "Phrases d'un mot pour la détection croisée",
          keyWordInfo10:
            "La plus longue phrase limite la longueur de la phrase n'est pas supérieure à",
          keyWordInfo11: "Enlever le pourcentage de similitude pas moins de",
          keyWordInfo12: "La similitude n'est pas inférieure à",
          keyWordInfo13:
            "% des phrases similaires sont similaires (80% de similitude par défaut)",
          keyWordInfo14:
            "La déduplication des similitudes correspondra en fonction de la similitude des phrases, et il est recommandé de supprimer les phrases trop similaires pour améliorer l'efficacité de la détection.",
          keyWord: "Mots clés",
          entity: "Entités",
          ruleField: "Mots conceptuels",
          customRule: "Conception des règles phonétiques",
          fanhua: "Demande similaire",
          test: "Test",
          testOk: "Test de Hit",
          senior: "Paramètres avancés",
          seniorList: "Détection de phrases croisées",
          up: "Activer",
          down: "Fermé",
          ju: "Phrases",
          oneWord: "Un mot",
          simimNo: "Similaire à weight",
          seniorField: "Seuil de similarité",
          save: "Sauvegarder",
          cancel: "Annulation",
          specialElements: "Éléments spéciaux",
          logicRule: "Règles logiques",
          every: "Correspondances multiples",
          recallOk: "Mots de rappel:",
          recallNo: "Mot de rappel manqué",
          ruleOk: "Règle de rappel",
          ruleNo: "Règle de l'échec",
          rularOk: "Règles de Hit",
          rularNo: "Règle de l'échec",
          infoOk: "Frapper une phrase standard ou similaire:",
          infoNo: "Échec d'une phrase standard ou similaire",
          fieldOk: "Exclure les phrases similaires:",
          fieldNo: "Échec exclure les phrases similaires",
          excludeRuleNo: "Règle d'exclusion d'échec",
          excludeRuleOk: "Règle d'exclusion de frappe",
          excludeKeywordNo: "Mots - clés exclus sans succès",
          excludeKeywordOk: "Exclure les mots clés",
          update: "Mise à jour réussie",
          fanhuaList:
            "Soumis à la page de généralisation signez la liste des phrases similaires",
          wordList:
            "La liste de formation est vide et ne peut pas être soumise à la liste généralisée",
          start: "Commencer",
          end: "La fin",
          keyWordInfo15:
            "Présence de champs similaires dans la liste généralisée (Configuration avancée des contraintes de déréférencement similaires)",
          saveSuccess: "Sauvegarder le succès",
          errInfo: "Il y a des champs non remplis ou mal remplis",
        },
        table: {
          deepTraining: "Entraînement en profondeur {0} roue",
          fastTraining: "Entraînement rapide",
        },
      },
      intelligentCollection: {
        title: "Acquisition intelligente",
        classification: "Liste des groupes",
        new: "Nouvelle acquisition",
        table: {
          name: "Nom du champ",
          props: "Propriétés du client",
          type: "Type de champ",
          desc: "Description du champ",
        },
        detail: {
          isCustomerProps: "Enregistrer dans les propriétés du client",
          customerPropsHelper:
            "Une fois enregistrées, les informations collectées sont mises à jour aux informations client. Non sauvegardé, il ne sera pas mis à jour aux informations du client",
          name: "Nom du champ",
          type: "Type de champ",
          text: "Texte",
          desc: "Description du champ",
          descPlaceHolder:
            "Décrire l'utilité de ce champ pour l'acquisition d'informations",
          condition: "Conditions",
          logic: "Règles",
          method: "Mode d'acquisition",
          message: "Contenu de l'information",
          range: "Gamme de tirage",
          entity: "Extraction d'entités",
          base: "Informations de base",
          collect: "Collecte d'informations",
        },
      },
      intelligentTags: {
        title: "Étiquettes intelligentes",
        sessionLabelTitle: "Scène de session",
        keyEventsTitle: "Événements clés",
        customerLabelTitle: "Étiquette client",
        addClassification: "Nouvelle classification d'étiquette",
        defaultCategoryName: "Classification par défaut",
        index: {
          createTag: "Nouvelle étiquette",
          createEvent: "Nouvel événement",
          editTagClassification: "Modifier la classification des tags",
          selectTagClassification: "Choisir une classification d'étiquette",
          editTagGrounp: "Modifier le Groupe",
          callTimeError:
            "La plage de temps d'appel / de début de conversation sélectionnée ne peut pas dépasser un mois (31 jours)",
          callRecordCountError:
            "Le nombre de barres d'enregistrement est 0, veuillez sélectionner à nouveau l'heure de l'appel / de la conversation",
          selectItmeCountError:
            "Veuillez sélectionner les étiquettes intelligentes qui doivent effectuer des opérations par lots",
          redoSuccess:
            "Ré - exécuter avec succès, voir dans le Centre des tâches",
          redoError: "La ré - exécution a échoué, Veuillez réessayer plus tard",
          timeError:
            "Les demandes ne peuvent pas durer plus d'un mois (31 jours)",
          categoryName: "Nom de la classification",
          categoryColor: "Catégories couleur",
          newTagClassification: "Nouvelle classification des étiquettes",
          newEventClassification: "Nouvelle classification",
          tagClassification: "Classification des étiquettes",
          eventClassification: "Classification des événements",
        },
        detail: {
          new: "Nouvelle étiquette intelligente",
          edit: "Modifier les Smart Tags",
          tagName: "Le nom",
          tagDesc: "Description",
          category: "Classification",
          tagStatus: "Efficacité",
          operator: "Opérateur",
          rule: "Règles",
          labelClassAtion: "Type d'étiquette",
          labelClassAtionTip1: "Étiquetage: Étiquetage basé sur des règles",
          labelClassAtionTip2:
            "Étiquettes de catégorie: Étiquetage basé sur la formation de modèle",
          group: "Groupe",
          checkLabel: "Choisir une classification",
          warn: "Alerte précoce",
          warnTips:
            "Lorsque l'alerte précoce est activée, la personne désignée est invitée à recevoir un rappel à backlog lorsque la session respecte cette règle d'événement critique.",
          warnError:
            "Veuillez sélectionner une personne désignée pour l'alerte précoce",
        },
        subTitle: {
          common: "Étiquettes de session intelligentes",
          wecom: "Micro - étiquettes",
        },
      },
      customerPortrait: {
        title: "Portrait du client",
        index: { import: "Importer le portrait du client" },
        searchPlaceHolder: "ID client, pseudonyme ou téléphone",
        callCount: "Nombre de conversations:",
        callDuration: "Durée totale de l'appel:",
        dialogCount: "Nombre total de barres de dialogue:",
        keyWordsStatistics: "Statistiques de mots clés (Top 20 & followers)",
        keyWordsCount: "({0})",
        businessTag: "Étiquettes d'entreprise",
        keyWordsCloudImg: "Mots clés Cloud Map",
        customerEmotionTrend: "Tendances du changement d'humeur des clients",
        notFoundCustomer: "Aucun client pertinent retrouvé",
        noKeyWords: "Pas de requête pour les mots clés pertinents",
        noBusinessTags: "Pas d'enquête sur l'étiquette d'entreprise pertinente",
        getKeyWordsStatisticsDataError:
          "Échec de la requête statistique pour les mots clés du client",
        getBusinessTagsStatisticsDataError:
          "La requête d'étiquette de l'entreprise du client a échoué",
        personCustome: "Aucune information du client interrogée",
        notimeInfo: "Pas de requête pour les informations émotionnelles",
        timeList: "Plage de dates",
        operator: "Étiquettes intelligentes",
        placeHolder: "Veuillez sélectionner",
        placeOperator: "Veuillez sélectionner Smart label",
        search: "Enquête",
        reset: "Réinitialisation",
        searchHolder: "Rechercher",
        call: "Appel",
        text: "Dialogue",
      },
      sentimentAnalysis: { title: "Découverte de l'opinion publique" },
      saleSentimentAnalysis: { title: "Analyse de l'opinion publique" },
      dataPreprocess: {
        title: "Pré - traitement des données",
        index: {
          createData: "Nouvelles données",
          selectDataSource: "Choisir une source de données",
          selectStatus: "Choisir l'efficacité",
          callTimeError:
            "La plage de temps d'appel / de début de conversation sélectionnée ne peut pas dépasser un mois (31 jours)",
          callRecordCountError:
            "Le nombre de barres d'enregistrement est 0, veuillez sélectionner à nouveau l'heure de l'appel / de la conversation",
          selectItmeCountError:
            "Veuillez sélectionner les données dont vous avez besoin pour effectuer des opérations par lots",
          redoSuccess:
            "Ré - exécuter avec succès, voir dans le Centre des tâches",
          redoError: "La ré - exécution a échoué, Veuillez réessayer plus tard",
        },
        detail: {
          new: "Nouvelles données",
          edit: "Modifier les données",
          name: "Le nom",
          desc: "Description",
          dataSource: "Sources de données",
          status: "Efficacité",
          entity: "Extraction d'entités",
          operator: "Opérateur",
          rule: "Règles",
        },
      },
      connectedByConversation: {
        title: "Association par dialogue",
        list: {
          title: "Fonction d'association via la conversation",
          info: "La fonction d'association de conversation via permet d'associer différents appels à la conversation selon des règles prédéfinies",
          rule: "Règles d'association par dialogue",
          source: "Veuillez sélectionner une source de données",
          classification: "Veuillez sélectionner une classification",
          choose: "Veuillez sélectionner",
          input: "Veuillez entrer",
          save: "Sauvegarder",
          cancel: "Annulation",
          fieldwei: "Position",
          fieldNot: "Il y a des champs non remplis",
          fiedldlengthNo: "La longueur du champ dépasse la limite",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Mots - clés peuvent être définis plusieurs, séparés par des virgules",
          applyRole: "Détecter le rôle",
          preOperator: "Opérateur frontal",
          applyOperatorScope: "Gamme de détection",
          addOperator: "Ajouter un opérateur",
          noPreOperator: "Aucun",
          from: "Le numéro",
          to: "À la fin",
          end: "Phrases",
          testRegular: "Tester les expressions régulières",
          test: "Test",
          matchSuccess: "Match réussi",
          matchError: "Match échoué",
          querySementicTags: "Choisir une étiquette sémantique",
          reQuerySementicTags:
            "Sélectionner à nouveau les étiquettes sémantiques",
          sementicTag: "Étiquettes sémantiques",
          standardSentence: "Phrase standard",
          similarity: "Similarité",
          actions: "Opérations",
          noFitTag: "Aucune étiquette appropriée n'a été trouvée,",
          addSemanticTag: "Ajouter une nouvelle étiquette sémantique",
          pleaseEnterRegular: "Veuillez entrer une expression régulière",
          pleaseEnterRegularText: "Veuillez entrer ce qui doit être vérifié",
          newAssociateComponentTip:
            "Si la valeur est de type numérique, utilisez la fonctionnalité ancienne version",
        },
        filterManage: {
          noValueTip: "Pas de données pour le moment",
          allCall: "Tous les appels",
          allDialogue: "Toutes les conversations",
          allWechat: "Toutes les sessions",
          getFiltersListFailed:
            "Obtenir la liste des filtres a échoué, Veuillez réessayer plus tard!",
          getFilterDetailFailed:
            "Obtenir les détails du filtre a échoué, Veuillez réessayer plus tard!",
          getAllCountFailed:
            "Obtenir le nombre total d'appels / conversations a échoué, réessayez plus tard!",
        },
        listManage: {
          searchPlaceHolder: "Veuillez saisir un mot - clé recherche",
          filterConditions: "Filtrer les conditions",
          records: "Enregistrement",
          getRecordsFailed:
            "Obtenir l'enregistrement a échoué, Veuillez réessayer plus tard!",
        },
        miniListManage: {
          getRecordsFailed:
            "Obtenir l'enregistrement a échoué, Veuillez réessayer plus tard!",
        },
        applyTemplates: {
          title: "Modèle d'inspection de qualité applicable",
          collectionTitle: "Modèles d'acquisition applicables",
        },
        recordDetail: {
          showWaveAudio: "Afficher les enregistrements",
          getDetailFailed:
            "Obtenir les détails a échoué, Veuillez réessayer plus tard!",
          getSmartTagsFailed:
            "L'obtention du SMART label a échoué, réessayez plus tard!",
          getTemplatesFailed:
            "L'obtention du modèle applicable a échoué, Veuillez réessayer plus tard!",
        },
        changeList: {
          sampleStatus: "État de l'inspection éclair",
          sampleAssignInspector: "Répartition des inspecteurs",
          reviewAssignInspector: "Répartition des réviseurs",
          read: "Lu",
          distributivePerson: "Distributeur",
          totalScore: "Score total",
        },
        qualityCheckScore: {
          totalScore: "Score total",
          actualScore: "Score",
          actualGrade: "Évaluation",
          appeal: "Plaintes",
          remark: "Remarques",
          manualRemark: "Remarques manuelles",
          none: "Pas encore de données de contrôle de qualité",
          interactiveTip:
            "Utilisation de la reconnaissance interactive pour détecter l'utilisation du service client FAQ",
          interactiveIsEmptyTip:
            "Aucun contenu de reconnaissance interactive détecté",
        },
        semanticMark: {
          semanticContent: "Phrase annotée",
          markedSimilarTitel: "Étiquettes sémantiques similaires étiquetées",
          markedExcludeTitle: "Étiquettes sémantiques similaires exclues",
          taskTagTitle: "Étiquettes sémantiques liées aux tâches",
          similarTagTitle:
            "Identifier les étiquettes sémantiques similaires (similarité > 40%, Top 20)",
          delete: "Supprimer",
          markSimilar: "Marques similaires",
          markExclude: "Exclure les similitudes",
          none: "Aucun",
        },
        selectCallTime: {
          callTime: "Heure d'appel / heure de début de session",
          callTimeRemind:
            "Vous pouvez sélectionner jusqu'à un seul enregistrement de conversation d'au plus un mois (31 jours) au cours de la dernière année",
          callRecordCount:
            "Vous avez sélectionné un total de {0} enregistrements d'appels / Conversations",
          getCallRecordError:
            "Obtenir l'enregistrement a échoué, Veuillez réessayer plus tard",
        },
      },
      informationEntities: {
        title: "Entité d'information",
        index: { import: "Importer des entités d'information" },
        detail: {
          new: "Nouvelle entité d'information",
          edit: "Modifier l'entité d'information",
          name: "Le nom",
          apiName: "Nom de l'API",
          activeFlag: "Efficacité",
          remark: "Description",
          logicExp: "Entité regular",
          sentenceList: "Contenu de l'entité",
          tip: "Entity content prend en charge l'entrée de plusieurs, séparés par des espaces, pour les entrées synonymes de la même entité",
          remarkLengthError: "Description la longueur ne doit pas dépasser 255",
          nameLengthError: "La longueur du nom ne doit pas dépasser 40",
          entityDuplicateError: "Répétition de contenu d'entité",
        },
      },
      interactiveRecognition: {
        title: "Identification interactive",
        classify: "Classification",
        addClassify: "Ajouter une classification",
        editClassify: "Modifier la catégorie",
        index: { import: "Importer la reconnaissance interactive" },
        detail: {
          new: "Nouvelle interaction intelligente",
          edit: "Modifier Smart interactive",
          type: "Type",
          standardQuestion: "Questions standard",
          similarQList: "Problèmes similaires",
          exceptSimilarQList: "Exclure les problèmes similaires",
          category: "Classification",
          targetType: "Objectifs de détection de problèmes",
          status: "Efficacité",
          standardAnswer: "Réponse standard",
          similarAList: "Réponses similaires",
          wrongAList: "Mauvaise réponse",
        },
      },
      businessAnalysis: {
        title: "Analyse des affaires",
        tip1: "Analyse des tendances commerciales: L'ordonnée est le nombre de conversations passantes, c'est - à - dire le nombre total de conversations passantes qui remplissent les conditions, l'abscisse est le temps",
        tip2: "Analyse de corrélation: analyse de la situation d'association de plusieurs indicateurs, suspension de la souris pour afficher la situation des données de corrélation, volume de données, corrélation, taux de répétition",
        tip3: "Analyse de conversion: l'analyse de plusieurs indicateurs peut former une relation de conversion mutuelle, le critère de décision de conversion est le même appel / conversation, si vous pouvez atteindre deux indicateurs différents, vous pouvez considérer que ces deux indicateurs ont une relation de conversion.",
      },
      knowledgeBase: {
        title: "Base de connaissances",
        list: {
          add: "Ajouter une sous - Classification",
          delete: "Supprimer une classification",
          categoryName: "Nom de la classification",
          createSuccess: "Sous - catégories créées avec succès",
          keyWord: "Mots clés",
        },
        detail: {
          type: "Type",
          status: "Statut",
          classification: "Classification",
          standardQuestion: "Questions standard",
          answerDetectionRange: "Gamme de détection de réponse",
          standardAnswer: "Réponse standard",
        },
      },
      callManage: {
        title: "Filtre d'enregistrement d'appel",
        listTitle: "Gestion des appels",
        detailTitle: "Détails de l'appel",
      },
      dialogueManage: {
        title: "Filtre de texte conversationnel",
        listTitle: "Gestion des conversations",
        detailTitle: "Détails de la conversation",
      },
      weChatManage: {
        title: "Filtre de session Wechat d'entreprise",
        listTitle: "Gestion de session Wechat entreprise",
        detailTitle: "Détails de la session Wechat entreprise",
      },
      speechRecognition: {
        title: "Reconnaissance vocale",
        asr: "Reconnaissance vocale",
        asrRemind:
          "La désactivation de ce commutateur entraîne l'échec de la fonction voix vers texte ainsi que de la fonction de contrôle de qualité de la machine",
        asrConfig: "ASR filtration",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Une fois activé, la transcrite ASR de la messagerie instantanée est effectuée",
        asrConfigRemind:
          "Lorsque activé, seuls les appels éligibles exécutent le contenu ASR",
        correction: "Correction intelligente des erreurs",
        correctionRemind:
          "Lorsqu'elle est activée, la conversion de correction d'erreur forcée est effectuée en utilisant le contenu de \"< a href = '/ site / Semantic - intelligence / SMART - Words - library / Professional - Words' target =' @ Blank '> Smart Thesaurus - Professional names < / a >\"",
        correctionConfig: "Filtrage de correction d'erreurs",
        advancedSettings: "Paramètres avancés",
        advancedSettingsTip:
          "Sélectionnez le modèle ASR spécifié pour les données spécifiées.",
        mandarinASRModel: "Modèle ASR en mandarin",
        cantoneseASRModel: "Modèle ASR en cantonais",
        englishASRModel: "Anglais ASR Model",
        correctionConfigRemind:
          "Lorsqu'il est activé, seuls les appels répondant aux conditions décrites ci - dessous sont corrigés",
        customJudgeLogicIsNull:
          "La logique personnalisée ne peut pas être vide",
        redoCorrection: "Ré - exécuter la correction d'erreur",
        callTime: "Choisissez l'heure de l'appel",
        callTimeRemind:
          "Vous pouvez sélectionner jusqu'à un seul appel d'au plus un mois (31 jours) au cours de la dernière année",
        callRecordCount:
          "Vous avez sélectionné un total de {0} journaux d'appels",
        redoCorrectionSubmit: "Soumettre pour exécution",
        callTimeError:
          "La période d'appel sélectionnée ne peut pas dépasser un mois (31 jours)",
        callRecordCountError:
          "Le nombre de barres d'enregistrement d'appel est 0, veuillez sélectionner à nouveau l'heure de l'appel",
        redoCorrectionSuccess:
          "Correction d'erreur réussie, consultez le Centre de tâches",
        redoCorrectionError:
          "La correction d'erreur a échoué, Veuillez réessayer plus tard",
      },
      role: {
        title: "Gestion des rôles",
        create: "Nouveau rôle",
        detail: {
          title: "Rôle",
          new: "Nouveau rôle",
          view: "Voir les rôles",
          edit: "Modifier le rôle",
          navTabNames: {
            basicInfo: "Autorisations de menu",
            taskPermissions:
              "Autorisation des tâches d'inspection de la qualité",
            dataPermissions: "Droits sur les données",
          },
          basicInfo: { roleName: "Le nom", permissions: "Permissions" },
          taskPermissions: {
            allTask: "Toutes les tâches de contrôle qualité",
            myCreatedTask: "Tâches de contrôle qualité que j'ai créées",
          },
          dataPermissions: {
            dataPermissionsConfig: "Configuration des droits de données",
            tips: "Le rôle peut voir les données sous la classification sélectionnée",
            select: "Choisir une classification",
            selectPlaceholder:
              "Veuillez sélectionner une classification qui nécessite des autorisations de visibilité des données",
          },
        },
      },
      dataMigration: {
        title: "Migration des données",
        dataUpload: "Déménagement des données",
        dataDowload: "Transfert de données",
        error: "Veuillez sélectionner la tâche de contrôle qualité à exporter",
        list: {
          info: "Veuillez sélectionner la tâche de contrôle de qualité à transférer",
          listInfo:
            "Veuillez choisir si vous souhaitez déplacer les données pertinentes de la tâche de contrôle de qualité (par exemple, thésaurus intelligent, étiquettes sémantiques, entités d'information, interactions intelligentes, etc.)",
          noInfo:
            "Veuillez choisir si vous souhaitez transférer d'autres données d'inspection non - qualité",
          ownInfo: "Déplacement des tâches uniquement",
          everyInfo: "Liés aux missions de déménagement",
          allInfo: "Hors de tout",
          callFilter: "Filtre d'appel",
          talkFilter: "Filtre de conversation",
          smartTag: "Étiquettes intelligentes",
        },
        upload: {
          info: "1. Téléchargez un fichier json conforme au format en cliquant sur le bouton upload",
          uploadSuccess: "Téléchargement réussi",
          uploadError: "Le téléchargement a échoué",
        },
      },
      dataAnalysis: {
        businessStatistics: "Statistiques opérationnelles",
        qualityEffect: "Santé de contrôle de qualité",
        qualityAnalysis: "Analyse de contrôle de qualité",
        generalInspecitionReport: "Rapport général",
        fullAmountWork: "Charge de travail de l'Inspecteur qualité",
        intelligentTagsAnalysis: "Analyse des étiquettes",
        saleIntelligentTagsAnalysis: "Analyse intelligente des étiquettes",
        generalInspecitionReportTip:
          "Total des données: comptez le temps et les tâches actuellement limités, le nombre total de données d'appel et de conversation, dont le nombre et le pourcentage de données de contrôle de la qualité intelligent, le nombre et le pourcentage de données de contrôle manuel, le volume de plaintes, le taux de rappel des appels et le graphique des tendances temporelles",
        groupTest: "Test de groupe",
      },
      uploadDownloadList: {
        title: "Centre de mission",
        tabs: {
          upload: "Importation de téléchargement",
          download: "Télécharger export",
          taskCenter: "Exécution des tâches",
        },
      },
      uploadDownloadModel: {
        title: "Télécharger télécharger",
        list: {
          clear: "Vider",
          success: "Succès",
          fail: "L'échec",
          hand: "En cours",
          noInfo: "Pas de données",
        },
      },
      billingCenter: {
        title: "Centre de facturation",
        export: "Exporter",
        limit: "Limite de rappel",
        tips: "Les résultats de facturation sont faussés par l'arrondi, à titre indicatif seulement",
        dashboard: {
          remainingQuota: "Quota restant actuel",
          usedQuota: "Quota cumulé utilisé",
          voiceQuality: "Test de qualité vocale:",
          voice: "Voix:",
          textQuality: "Contrôle qualité du texte:",
          text: "Texte:",
          hour: "Heures",
          tenThousandHour: "Dix mille heures",
          day: "Jours",
          cutoff: "Coupure",
          warning:
            "(quota restant insuffisant, veuillez le recharger dès que possible)",
          isClosed: "(Statut fermé)",
        },
        table: {
          quotaRecord: "Enregistrement des quotas",
          timeFilter: "Choisissez une date:",
          search: "Enquête",
          reset: "Réinitialisation",
          id: "Numéro d'eau courante",
          type: "Type",
          qualityType: "Type d'inspection de qualité",
          quotaChange: "Changements",
          surplus: "Solde",
          changeTime: "Le temps",
          voiceQuality: "Contrôle de qualité vocale",
          textQuality: "Contrôle qualité du texte",
          sizeChangeText: "Affichage par page",
          used: "Utilisation",
          give: "Accordée",
          deduction: "Déduction",
          callTime: "Temps d'appel",
          count: "Volume de données traitées",
          page: "Total {0}, {1} pages",
        },
        limitModal: {
          call: "Quota de contrôle de qualité vocal inférieur à",
          callFix: "Heures pour un rappel",
          dialogue:
            "Le temps restant pour le contrôle de qualité du texte est inférieur à",
          dialogueFix: "Jours pour un rappel",
        },
      },
      messageCenter: {
        replyTitle: "Répondre à mon",
        answerTitle: "@ le mien",
        shareTitle: "Partagez le mien",
      },
      columnsManage: { title: "Gestion des champs de contrôle qualité" },
      recordUpload: {
        list: {
          task: "Tâches d'inspection de la qualité",
          user: "Opérateur",
          uploadTime: "Temps de téléchargement",
          endTime: "Heure de fin",
          total: "Total des uploads",
          success: "Test de qualité réussi",
          failed: "échec du contrôle qualité",
          progress: "Progrès de l'inspection de qualité",
          inProgress: "En cours",
          finished: "Terminé",
        },
        steps: {
          uploading: "Télécharger un fichier d'enregistrement",
          baseInfo: "Remplissez les informations de base",
          start: "Commencer le contrôle qualité",
        },
        uploadComponent: {
          dragger: "Glisser pour ajouter des fichiers",
          or: "Ou",
          selectFile: "Sélectionner un fichier",
          des: "Prend en charge les formats WAV, MP3, taux d'échantillonnage de 8000hz, fichiers de 16 bits",
          limit1: "Un maximum de uploads",
          limit2: "Un fichier dont la taille ne dépasse pas",
          uploading: "Dans upload",
          finished: "Upload terminé",
          success: "Succès",
          failure: "L'échec",
        },
        baseInfo: {
          task: "Choisir une tâche de contrôle qualité",
          type: "Choisir un format d'enregistrement",
          agentSide: "Passage où se trouve le service client",
          excel: "Télécharger des données routières",
          checkFileInfo: "Informations sur les fichiers de contrôle",
          excelFileError: "Veuillez télécharger les données routières",
          checking: "À la vérification...",
          reCheck: "Re - check",
          success: "Check succès",
          excelUoloadFail:
            "Le téléchargement des données sur la route a échoué",
          tips: {
            tip1: "1. Le nom du fichier d'enregistrement dans ecxel doit correspondre au nom de l'enregistrement téléchargé et ne peut pas être renommé",
            tip2: "2. Le système est chargé avec le nom du fichier d'enregistrement, l'ID d'appel, la répétition entraînera l'échec du contrôle de qualité",
            tip3: "3. Télécharger le modèle d'enregistrement Excel, la couleur de la police est rouge est un champ obligatoire",
            tip4: "4. Le format du champ pour le type de temps est: AAAA - MM - JJ HH: MM: SS, par exemple 2021 - 04 - 20 17: 36: 30",
          },
        },
        title: "Téléchargement d'enregistrements locaux",
        checkTitle: "Contrôle qualité des enregistrements",
        start: "Commencer le contrôle qualité",
        disabledTips:
          "Veuillez vérifier le fichier d'enregistrement avant de procéder au contrôle de qualité",
        tips: "Le fichier d'enregistrement du même nom couvre les résultats du contrôle de qualité et les informations de base",
        tips1: "Faites glisser le fichier ici ou appuyez sur télécharger",
        tips2: "Soutien",
        tips3: "Format",
        type: "wav、mp3",
        tips4: "Un maximum de uploads",
        tips5: "Un fichier dont la taille ne dépasse pas",
        tipsOverSize:
          "La file d'attente actuelle est pleine, supprimez les fichiers de la file d'attente avant de les télécharger",
        size: "500M",
        count: "20",
        uploaded: "Fichier d'enregistrement téléchargé",
        uploading: "({0}%)",
        uploadFinished: "(téléchargement terminé)",
        bottomTitle: "Collecte d'informations enregistrées:",
        recordType: "Choisissez le format d'enregistrement:",
        double: "Enregistrement d'appels à deux canaux",
        single: "Enregistrement d'appels à canal unique",
        recordBaseInfo: "Télécharger un enregistrement informations de base:",
        addBtn: "Ajouter un fichier",
        downloadTemplate: "Modèle télécharger.Excel",
        checkRecord: "Vérifier les informations d'enregistrement",
        checking: "À la vérification...",
        reCheck: "Re - check",
        checkAll: "Tout sélectionner",
        cancelCheckAll: "Annuler toute la sélection",
        delete: "Supprimer",
        deleteLotSize: "Suppression par lots",
        agentChannel: "Accès où se trouve le service client:",
        left: "Canal gauche",
        right: "Canal de droite",
        donotKonw: "Ne sait pas",
        mission: "Choisissez une tâche de contrôle de qualité:",
        uploadFail: "Le téléchargement a échoué",
        repeat: "Répéter le fichier",
        overCount: "Le nombre de fichiers dépasse la limite",
        overSize: "La taille du fichier dépasse la limite",
        uoloadFail: "Le téléchargement vers le cloud a échoué",
        formatError: "Erreur de format",
        seccess: "Check succès",
        fail: "échec du contrôle",
        recordChecking:
          "Vérification de la qualité de l'enregistrement, s'il vous plaît plus tard...",
        recordCheckSuccess: "Test de qualité d'enregistrement terminé",
        recordCheckFail:
          "Échec du contrôle de qualité de l'enregistrement, vérifiez si l'enregistrement est dupliqué ou invalide",
        backToIndex: "Retour à la liste des uploads",
        recordCheckIsStarted:
          "Il y a actuellement une tâche de contrôle de qualité en cours d'exécution, veuillez commencer une nouvelle tâche après la fin de la tâche en cours",
      },
      customerServicePortrait: {
        title: "Portrait du service à la clientèle",
        tip1: "Loi de distribution de la longueur de la phrase: distribution de la longueur de la phrase, selon le nombre de mots longs de la phrase, les segments sont comptés, seul le service client est compté",
        tip2: "Loi de distribution de la vitesse de la parole: distribution de la vitesse de la parole, en fonction de la vitesse de la parole (mots / minute), les segments sont comptés, seul le service client est compté",
        tip3: "Loi de distribution du temps de réponse: distribution du temps de réponse, seuls les cas où le service client répond au client sont comptés",
        tip4: "Conformité au contrôle qualité: taux de conformité aux points de contrôle qualité",
        tip5: "Moyenne des tâches d'inspection de la qualité: moyenne des tâches d'inspection de la qualité",
        contol: {
          date: "Choisissez une date:",
          today: "Aujourd'hui",
          thisMonth: "Ce mois - ci",
          lastMonth: "Le mois dernier",
          thisQuarter: "Ce trimestre",
          lastQuarter: "Dernier trimestre",
          thisYear: "Cette année",
          lastYear: "L'année dernière",
          customer: "Service à la clientèle:",
          search: "Enquête",
          reset: "Réinitialisation",
          export: "Exporter",
          indicator: "Gestion des indicateurs",
          selectPlaceholder:
            "Veuillez sélectionner un service à la clientèle qui nécessite un contraste",
          warning: "Choisissez jusqu'à 5 services à la clientèle pour comparer",
          warning2:
            "Veuillez sélectionner au moins 1 Service client pour votre demande",
          dataWarning:
            "Jusqu'à 366 jours après la date de début de la requête sont pris en charge",
          seccuss: "Enregistrer le succès de l'exportation",
        },
        echarts: {
          distributionLaw: "Loi de distribution",
          count: "Nombre de fois",
          bout: "Une fois",
        },
        portrait: {
          ability: "Dimension de compétence du service à la clientèle",
          option: "Configuration",
          abilityOption: "Dimension de capacité de configuration",
          placeholder:
            "Entrez la dimension de compétence recherche par mots clés",
          save: "Sauvegarder",
          warning:
            "Veuillez sélectionner au moins 3 indicateurs à configurer et jusqu'à 10 indicateurs",
        },
        label: { title: "Service à la clientèle Business label" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Classement du service à la clientèle",
        select: {
          date: "Choisissez une date:",
          today: "Aujourd'hui",
          thisMonth: "Ce mois - ci",
          lastMonth: "Le mois dernier",
          thisQuarter: "Ce trimestre",
          lastQuarter: "Dernier trimestre",
          thisYear: "Cette année",
          lastYear: "L'année dernière",
          group: "Groupe de service à la clientèle:",
          groupPlaceholder:
            "Veuillez sélectionner un groupe de service à la clientèle",
          customer: "Service à la clientèle:",
          customerPlaceholder: "Veuillez sélectionner le service client",
          search: "Enquête",
          reset: "Réinitialisation",
          compared: "Le contraste",
          export: "Exporter",
          column: "Colonnes",
          warning: "Choisissez jusqu'à 5 services à la clientèle pour comparer",
          warning2: "Veuillez sélectionner au moins un service client",
          dataWarning:
            "Jusqu'à 366 jours après la date de début de la requête sont pris en charge",
          selected: "Service client sélectionné:",
          seccuss: "Enregistrer le succès de l'exportation",
        },
        compared: {
          title: "Choisir un service client",
          return: "Annulation",
          compared: "Le contraste",
          tips: "Veuillez sélectionner 2 - 5 personnes service à la clientèle pour comparer",
          warning: "Support uniquement pour 2 - 5 clients comparer",
        },
        transfer: {
          leftTitle: "Éléments de liste",
          rightTitle: "Éléments d'affichage",
        },
        table: {
          page: "Total {0}, {1} pages",
          actionTitle: "Opérations",
          action: "Voir",
        },
      },
      conceptWord: {
        title: "Mots conceptuels",
        new: "Nouveau mot concept",
        control: {
          search: "Rechercher:",
          searchPlaceholder: "Recherche par nom ou mot clé",
          commit: "Enquête",
          reset: "Réinitialisation",
          import: "Importation",
          export: "Exporter",
          seccuss: "Enregistrer le succès de l'exportation",
        },
        modal: { title: "Importer des mots conceptuels" },
        table: {
          sizeChangeText: "Affichage par page",
          page: "Total {0}, {1} pages",
          edit: "Modifier",
          delete: "Supprimer",
          request:
            "Il ne sera pas récupérable après la suppression, confirmez - vous la suppression?",
          cancel: "Annulation",
          title: {
            conceptWord: "Mots conceptuels",
            describeWordsNum: "Description nombre de mots",
            updateTime: "Temps de mise à jour",
            status: "Efficacité",
            effective: "Efficace",
            invalid: "Invalide",
            action: "Opérations",
          },
        },
        detail: {
          title: {
            new: "Nouveau mot concept",
            edit: "Modifier le mot concept",
          },
          name: "Nom du mot concept",
          namePlaceholder: "Veuillez entrer le nom du mot concept",
          effectiveness: "Efficacité",
          effective: "Efficace",
          invalid: "Invalide",
          description: "Mots descriptifs:",
          descriptionPlaceholder: "Veuillez entrer un mot de description",
          add: "Ajouter",
          repeat: "Contenu répété",
          empty: "Le mot de description ne peut pas être vide",
          recommendEmpty:
            "Il n'y a pas encore plus de descripteurs recommandés, veuillez saisir manuellement ajouter",
          recommend: "Mots descriptifs recommandés intelligents",
          cancel: "Annulation",
          change: "Changer un groupe",
          descriptionSelected: "Un mot descriptif a été ajouté:",
          save: "Sauvegarder",
          success: "Concept word préserver le succès",
          warning: "Le nom du mot concept ne peut pas être vide",
          warning2: "Veuillez ajouter au moins 1 mot de description",
          submitBtnHandle: {
            title:
              "Il y a des descripteurs cochés qui n'ont pas été ajoutés, Save abandonnera les descripteurs non ajoutés",
            content:
              "Cliquez sur OK pour revenir à la page d'accueil de concept words, confirmer continuer?",
          },
        },
      },
      smartTools: {
        btn: "Outils intelligents",
        title: {
          similarWords: "Découverte de mots connexes",
          search: "Phrases par mots",
          semanticTags: "Étiquettes sémantiques",
        },
        sentenceSearch: {
          placeholder:
            "S'il vous plaît entrer recherche de mots clés, soutenir plusieurs mots clés",
          more: "Voir l'article complet",
          pageUp: "Page précédente",
          pageDown: "Page suivante",
          total: "Un total de {0} enregistrements",
        },
        similarDiscover: {
          placeholder: "Veuillez entrer un mot clé",
          copyed: "A été copié",
          none: "Pas encore de mots - clés, essayez d'autres mots - clés",
        },
        smartTags: {
          searchPlaceHolder: "Veuillez entrer un mot clé ou une phrase",
          createSmartTag: "Nouvelle construction",
          createClusteringSmartTag: "Nouveau avec {0} données",
          addFromKeyword:
            "Ajouter du contenu de recherche à des phrases similaires",
          hit: "Frappé",
          similar: "Similaires",
          name: "Nom de l'étiquette sémantique",
          namePlaceHolder: "Veuillez entrer un nom d'étiquette sémantique",
          recallWords: "Mots de rappel",
          recallWordsPlaceHolder: "Veuillez entrer un mot clé de rappel",
          recallRegular: "Règles de rappel",
          recallRegularPlaceHolder: "Veuillez entrer la règle de rappel",
          rules: "Règles",
          standardSentences: "Phrase standard",
          standardSentencesPlaceHolder: "Veuillez entrer la phrase standard",
          similaSentences: "Phrases similaires",
          similaSentencesPlaceHolder: "Veuillez entrer une phrase similaire",
          notSaveError:
            "Veuillez sauvegarder les étiquettes sémantiques avant de chercher",
          add: "Ajouter",
          newPage: "Ouvrir sur une nouvelle page",
          querryError:
            "Présence de champs similaires dans la liste généralisée (Configuration avancée des contraintes de déréférencement similaires)",
          detailTitle: "Détails des étiquettes sémantiques",
        },
      },
      trainingCenter: {
        title: "Centre de formation",
        edit: "Modifier",
        delete: "Supprimer",
        seleteAll: "Tout sélectionner",
        cancelCheckAll: "Annuler toute la sélection",
        add: "Ajouter",
        remove: "Supprimer",
        save: "Sauvegarder",
        cancel: "Annulation",
        fastTraining: {
          title: "Entraînement rapide",
          titleDes:
            "La formation rapide est conçue pour aider les étiquettes sémantiques à généraliser rapidement jusqu'à 20 phrases similaires",
          semanticTags: "Étiquettes sémantiques",
          semanticTagsPlaceholder:
            "Veuillez entrer le processus recherche par mot - clé",
          standardSentence: "Phrase standard: {0}",
          similarSentencesPlaceholder:
            "Entrez une phrase similaire ajouter ou rechercher",
          similarSentencesAdd: "Ajouter",
          similarSentencesSearch: "Rechercher",
          similarSentences: "Des phrases similaires ont été ajoutées",
          trainingCount: "Nombre d'entraînements: {0}",
          similarSentencesRecommend: "Phrases similaires recommandées",
          noResp:
            "Pas encore d'étiquettes sémantiques associées, veuillez remplacer les mots clés",
          similarSentencesModalTitle: "Recherche de phrases similaires",
          moreInfo: "Voir le contexte",
          moreInfoBtn: "Contexte",
          modalSearchType: {
            all: "Tous",
            customer: "Service à la clientèle",
            client: "Le client",
          },
          repetWarning:
            "La même phrase similaire existe déjà, ne répétez pas l'ajout",
          repetRemoveTip:
            "Enregistrer un contenu dupliqué dans une phrase similaire, qui a été automatiquement redirigé",
        },
        trainingHistory: {
          title: "Histoire de la formation",
          select: {
            date: "Choisissez l'heure:",
            today: "Aujourd'hui",
            thisMonth: "Ce mois - ci",
            lastMonth: "Le mois dernier",
            thisQuarter: "Ce trimestre",
            lastQuarter: "Dernier trimestre",
            thisYear: "Cette année",
            lastYear: "L'année dernière",
            search: "Enquête",
            reset: "Réinitialisation",
          },
          table: {
            time: "Le temps",
            user: "Opérateur",
            tag: "Étiquettes sémantiques",
            similarCount: "Nombre de phrases similaires Identification",
            deleteCount: "Supprimer le nombre de phrases",
            page: "Total {0}, {1} pages",
          },
        },
        classifiedTraining: {
          title: "Formation par catégorie",
          tagsGrounp: "Groupe d'étiquettes",
          placeHolder: "Veuillez sélectionner",
          cellTime: "Choisissez l'heure",
          search: "Enquête",
          reset: "Réinitialisation",
          classifiedFlag: "Catégorie Formation",
          num: "Valeur numérique",
          gas: "Pourcentage",
          noData: "Formation non classée",
          noDataHeight:
            "Proportion relativement élevée de données non catégorisées, formation urgente",
          noDataSmall:
            "Proportion relativement élevée de données non catégorisées, formation recommandée",
          noDataDays: "Dernière exécution {0} jours ago",
          training: "Formation",
          start: "Commencer",
          addClass: "Augmenter la classification",
          custom: "Filtrage avancé",
          marked: "Erratum de données étiqueté",
          markedHeight: "Pas d'Erratum depuis {0} jours Erratum recommandé",
          markedSmall: "Pas d'Erratum depuis {0} jours, erratum urgent",
          mark: "Errata",
          dataCheck: "Contrôle des données classées",
          dataCheckSmall:
            "K - l divergence supérieure à 0,5, entraînement recommandé",
          dataCheckHeight:
            "K - l divergence supérieure à 0,8, besoin urgent de formation",
          check: "Contrôle",
          history: "Étiquetage historique",
          historyTime:
            "Les annotations et modifications quotidiennes automatisent la formation du modèle à 2 h",
          today: "Tagué aujourd'hui",
          article: "Article",
          none: "Aucun",
          historyCheck: "Annotation totale historique",
          look: "Voir les enregistrements",
          historyluate: "Données historiques recalculées",
          startluate: "Lancer une réévaluation",
          classData: "Données de classification",
          classStatus: "Progression de la formation",
          next: "Article suivant",
          prev: "Article précédent",
          finish: "Terminer la formation",
          stop: "Fin de la formation",
          classDetail: "Détails de la formation",
          trainingNone:
            "Étiquettes intelligentes pour le moment sans entraînement",
        },
        classifiedTrainingHistory: {
          title: "Histoire de la formation",
          time: "Choisissez l'heure:",
          tag: "Type d'étiquette:",
          user: "Opérateur:",
          data: "Sélectionnez la source de données:",
          search: "Enquête",
          searchBar: "Rechercher:",
          searchBarPlaceholder:
            "Veuillez saisir l'identifiant de l'appel / identifiant de la conversation rechercher",
          reset: "Réinitialisation",
          export: "Exporter",
          all: "Tous",
          select: {
            today: "Aujourd'hui",
            thisMonth: "Ce mois - ci",
            lastMonth: "Le mois dernier",
            thisQuarter: "Ce trimestre",
            lastQuarter: "Dernier trimestre",
            thisYear: "Cette année",
            lastYear: "L'année dernière",
            search: "Enquête",
            reset: "Réinitialisation",
          },
          table: {
            user: "Opérateur",
            date: "Temps de fonctionnement",
            data: "Sources de données",
            id: "ID d'appel / id de conversation",
            tagType: "Regroupement d'étiquettes intelligentes",
            tagName: "Nom de l'étiquette",
            state: "Statut",
            sizeChangeText: "Affichage par page",
            page: "Total {0}, {1} pages",
            call: "Appel",
            dialogue: "Dialogue",
          },
        },
        clusteringAnnotation: {
          title: "Marquage de Clusters",
          left: { title: "Résultats de Clustering" },
          right: {
            title: "Phrases de groupe",
            checkAll: "Sélectionner toute cette page",
            tagging: "Annotation",
            detail: "Voir",
            meaningless: "Ignorer",
            meaninglessTips:
              "Les résultats de Clustering ignorés ne seront plus affichés par la suite, confirmez - vous que vous souhaitez les ignorer?",
            cancel: "Annulation",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Gestion des clients",
          tabTitle: "Client du Centre de service à la clientèle",
        },
        wechatClientManage: {
          title: "Micro - entreprises clients",
          table: {
            id: "ID client",
            name: "Surnom du client",
            tag: "Étiquette client",
          },
        },
      },
      collectionRule: {
        title: "Règles d'acquisition",
        form: {
          info: "Informations d'acquisition intelligentes",
          infoPlaceholder: "Veuillez sélectionner une classification",
        },
        table: {
          name: "Nom du champ",
          props: "Propriétés du client",
          type: "Type de champ",
          desc: "Description du champ",
        },
      },
      dataAdd: {
        title: "Ajout de données",
        types: {
          auto: "Ajouter automatiquement",
          manual: "Ajouter manuellement",
        },
      },
      wechatStaff: {
        table: {
          name: "Nom et prénom",
          part: "Département appartenant",
          phone: "Numéro de téléphone portable",
          email: "Boîte aux lettres",
          wechartId: "Identifiant Wechat d'entreprise",
          status: "Statut au travail",
        },
        info: "Veuillez d'abord sélectionner le Département qui doit synchroniser les données",
        synSuccess: "Synchronisation réussie",
        init: "Initialisation",
        syn: "Synchronisation",
      },
      appealHistory: {
        appealTime: "Temps de réclamation",
        cancelTime: "Temps de révocation",
        reviewTime: "Temps de révision",
        inspector: "Inspecteur qualité",
        result: "Résultat de la plainte",
        complainResult:
          "Total des plaintes {0} contrôle de qualité, plaintes reçues {1} et rejetées {2}.",
        reason: "Motifs de la plainte",
        rejection: "Motifs du rejet",
        cancel: "Révocation",
        tip: "Confirmer voulez - vous retirer votre plainte?",
      },
      appealSdk: {
        time: "Temps de conversation",
        externalIdCall: "ID d'enregistrement d'appels",
        externalIdText: "ID d'enregistrement de conversation",
        customerNickName: "Surnom du client",
        readStatus: "Consulter le statut",
        status: "État des plaintes",
        isManual: "Contrôle de qualité manuel ou non",
        complainButtonInfo:
          "Un grief est admis {0} fois, un grief a été déposé {1} fois et un grief peut être déposé {2} fois. {3} ancien plaignant",
        submit: "Déposer une plainte",
        complain: "Plaintes",
        allComplain: "Plaintes toutes",
        pendingSubmit: "À soumettre",
        taskIds: "Tâches d'inspection de la qualité",
        graded: "Score",
        complainSuccess: "Plainte déposée avec succès",
        appealSdkIndex: { title: "Résultats du contrôle qualité intelligent" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Obtenir les informations de liste de filtres a échoué, vérifiez votre connexion réseau ou réessayez plus tard!",
      columns: "Colonnes",
      filter: "Filtrer",
      noSearchResult: "Aucun enregistrement trouvé",
      pleaseSearch: "Veuillez sélectionner un filtre et essayer de rechercher",
      sift: "Filtrer",
      create: "Nouvelle construction",
      search: "Rechercher",
      createTaskTitle: "Nouvelle tâche",
      title: "Titre",
      deadline: "Temps de coupure",
      principal: "Responsable",
      enqueueTime: "Temps d'entrée à l'équipe",
      computeError: "Le calcul a échoué, plus tard s'il vous plaît!",
      sum: "Demander et",
      max: "Valeur maximale",
      min: "Valeur minimale",
      avg: "Moyenne",
      count: "Comptage",
      maxDate: "Max (le plus récent)",
      minDate: "Minimum (le plus ancien)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Obtenir l'adresse du lien Insight a échoué, réessayez plus tard!",
      },
      template: {
        learningReminder: "Rappel d'apprentissage",
        subscriptionDynamics: "S'abonner à Dynamics",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Gestion des tâches", chaneg: "Commuter" },
        qualityCheckTemplate: {
          text: "Modèle d'inspection de qualité",
          collectText: "Modèle d'acquisition",
          condition: { text: "Conditions d'inspection de qualité" },
          checkPoint: { text: "Configuration des règles" },
          automaticAdd: { text: "Automatisation des processus" },
          manualAdd: { text: "Ajouter manuellement" },
          roleConfig: { text: "Configuration des rôles" },
          basicInfo: { text: "Informations de base" },
          smartWordsLibrary: { text: "Thésaurus intelligent" },
          grade: { text: "Évaluation de l'inspection de qualité" },
          collectionRule: { text: "Règles d'acquisition" },
          addData: { text: "Ajouter des données" },
          collectionList: { text: "Liste d'acquisition" },
        },
        qualityCheckWorkbench: {
          text: "Banc d'inspection de qualité",
          wechatText: "Table de travail analytique",
          informationCollectText: "Banc d'acquisition",
          qualityInspectionList: {
            text: "Liste des contrôles de qualité",
            wechatText: "Liste des analyses",
            informationCollectText: "Liste d'acquisition",
          },
          condition: { text: "Liste des contrôles de qualité (abandonnée)" },
          reviewList: { text: "Liste de révision" },
          spotCheckList: { text: "Liste des inspections" },
          caseBaseList: { text: "Bibliothèque de cas" },
          testSetList: { text: "Ensemble de tests de contrôle de qualité" },
        },
        statisticsAndReports: {
          text: "Statistiques et déclarations",
          wholeReport: { text: "Déclaration globale" },
          groupReport: { text: "Rapports de groupe de service à la clientèle" },
          agentReport: { text: "Déclaration de service à la clientèle" },
          customerServiceExecution: { text: "Analyse du service client" },
          analysisOverview: { text: "Aperçu des analyses" },
          inspectionItemsAnalysis: {
            text: "Analyse des éléments de contrôle de qualité",
          },
        },
        wechatQa: {
          text: "Contrôle qualité Wechat entreprise",
          task: { text: "Tâches analytiques" },
          baseInfo: { text: "Informations de base" },
          checkPoint: { text: "Configuration du modèle analytique" },
          setting: { text: "Configuration d'analyse Wechat d'entreprise" },
          automaticAdd: { text: "Automatisation des processus" },
          manualAdd: { text: "Ajouter manuellement" },
        },
        clientCenter: { text: "Centre de service à la clientèle" },
      },
      conditionDictionaries: {
        title: "Toutes les conditions",
        searchDefaultText: "Veuillez entrer un identifiant ou un nom",
        id: "ID",
        name: "Le nom",
        conditionDetailedShow: "Développer",
        conditionDetailedHide: "Fermer",
        remark: "Description:",
        checkRole: "Rôle:",
        operator: "Opérateur:",
        operatorLogic: "Logique:",
      },
      audioTextView: {
        customer: "Le client",
        agent: "Service à la clientèle",
        unsupportedFormat: "Le format n'est pas supporté",
      },
      checkPointClassifications: {
        add: "Nouvelle classification",
        name: "Le nom",
        enterToSave: "Appuyez sur Entrée pour sauvegarder",
        highestScore: "Maximum de score",
        minimumScore: "Score minimum inférieur",
        defaultMinScore: "0 point par défaut",
        minimumScoreTitle: "Limite inférieure de score",
        beginScore: "Valeur globale du score",
        beginScoreTitle: "Évaluer la valeur du score",
        scoreSetTip:
          "La limite supérieure du score le plus élevé est inférieure à la somme de chaque valeur de score, vous pouvez choisir entre:",
        ignoreExcessSpillover: "Ignorer les débordements excédentaires",
        automaticWeightCalculation: "Comptabilité automatique des poids",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Les champs" },
    checkRange: {
      numberTipOne: "Le numéro",
      numberTipTwo: "Phrases",
      numberTipThree: "Phrase à paragraphe",
      conditionLimit: "Restrictions des conditions",
      roleLimit: "Limitations de rôle",
      contextLimit: "Limitation de la phrase",
      notZero: "Limite de phrase ne prend pas en charge input 0",
      startMustLessThanEnd:
        "La position de la phrase de début doit être inférieure à la position de la phrase de fin",
    },
    waveAudio: {
      play: "Jouer",
      pause: "Suspension",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Veuillez entrer",
      pleaseEnterRegular: "Veuillez entrer une expression régulière",
      pleaseEnterGrammar: "Veuillez entrer les règles de droit",
      pleaseEnterTestText: "Veuillez entrer ce qui doit être vérifié",
      pleaseEnterLoudness: "Veuillez entrer le décibel de volume",
      pleaseEnterRangeAbility: "Veuillez entrer l'amplitude du changement",
      afterHit: "Vérification après coup",
      pleaseEnterContext: "Veuillez entrer une déclaration de référence",
      wordNumber: "L'écart du nombre de mots",
      intervalSecond: "Intervalle de temps supérieur à",
      minute: "Chaque minute",
      wordUnit: "Un mot",
      test: "Test",
      testRegular: "Tester les expressions régulières",
      testGrammar: "Test de grammaire",
      testGrammarError: "échec du test de grammaire",
      regularError: "Erreur d'expression régulière, veuillez saisir à nouveau",
      pleaseEnterSemanticSentence:
        "Veuillez entrer une phrase nécessitant une détection sémantique",
      pleaseEnterAssociate:
        "Reportez - vous aux instructions à droite pour construire une expression de données en cours de route",
      querySemanticTags: "Choisir une étiquette sémantique",
      reQuerySemanticTags: "Sélectionner à nouveau les étiquettes sémantiques",
      semanticTags: "Étiquettes sémantiques",
      standardSentence: "Phrase standard",
      semanticName: "Étiquettes sémantiques",
      sentence: "Phrase standard",
      similarity: "Similarité",
      actions: "Opérations",
      tipOne: "Pas trouvé le bon label,",
      tipTwo: "Ajouter une nouvelle étiquette sémantique",
      notLessThan: "Pas moins de",
      notGreaterThan: "Pas plus grand que",
      ignoreLessThan: "Ignorer moins de",
      wordUnitSet: "Phrases de mots",
      speedWordNotNull:
        "Détection de la vitesse de la parole le nombre de mots minimum et le nombre de mots maximum ne peuvent pas tous être vides",
      speedWordMinValueLessThanMaxValue:
        "Détection de la vitesse de la parole le nombre de mots minimum ne peut pas être supérieur au nombre de mots maximum",
      speechGrabbingIntervalTip: "Intervalle de saisie > =",
      speechLengthTip: "Longueur de phrase > =",
      msUnit: "ms",
      sUnit: "Secondes",
      grabIntervalNotNull: "L'intervalle de préemption ne peut pas être vide",
      grabLengthNotNull:
        "La longueur de la phrase préemptive ne peut pas être vide",
      pleaseSelectEntity: "Veuillez sélectionner une entité d'information",
      pleaseSelectOperator: "Veuillez sélectionner une action",
      pleaseSelectBasicInfo: "Veuillez sélectionner les informations de base",
      pleaseEnterFixedValue: "Veuillez entrer une valeur fixe",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Match réussi",
      matchError: "Match échoué",
      mindetectionStatement: "Instruction de détection minimale",
      conceptWordholder: "Veuillez sélectionner le mot concept",
      elementHolder: "Veuillez entrer un élément",
      associateMedol: { tabs: { data: "Données", operator: "Opérateur" } },
      repet: "Répéter",
      bout: "Une fois",
      ignore: "Ignorer moins de",
      ignoreAffix: "Phrases de mots",
      associateData: "Données sur la route",
      pretreatmentData: "Données prétraitées",
      reviewTaskData: "Révision des données de mission",
      taskData: "Données de mission d'inspection de qualité",
      businessData: "Dossiers d'affaires",
      functionOperator: "Opérateur de fonction",
      operation: "Opérateurs opérationnels",
      notAnyMatch: "Quand aucun problème n'est touché:",
      times: "Nombre d'apparitions",
      newAssociateDataTip:
        "Si la valeur est de type numérique, utilisez la fonctionnalité ancienne version",
    },
    conditionGather: {
      cycle: "Le cycle",
      rlue: "Règles",
      delete: "Supprimer",
      sampling: "Extraction",
      item: "Article",
      percent: "%",
      samplingSame: "Même nombre de tirages par service client",
      samplingRecheck: "Autoriser le tirage répété des entrées tirées au sort",
      dateUnit: "Numéro",
      samplingTip:
        "Différentes règles couvrant le même appel ne sont pas tirées à plusieurs reprises, et les enregistrements qui ont été tirés non vérifiés ne sont pas tirés à nouveau.",
      add: "Ajouter des règles",
    },
    ruleGather: {
      title: "Règles",
      screeningCondition: "Filtrer les conditions",
      addRule: "Ajouter des règles",
      inspector: "Inspecteur qualité",
      ruleTip:
        "Mode d'allocation de priorité de règle, attribué de haut en bas, sans attribution répétée, dans la règle ou ne correspondant pas à un élément de règle arbitraire sera attribué aléatoirement",
      moveUp: "Déplacement vers le Haut",
      moveDown: "Descendre",
      top: "Mettre en haut",
    },
    pushRulesGather: {
      title: "Règles",
      delete: "Supprimer",
      newPush: "Nouveau push",
      newRule: "Ajouter des règles",
      sampling: "Extraction",
      item: "Article",
      percent: "%",
      samplingSame: "Même nombre de tirages par service client",
      dateUnit: "Numéro",
      screeningCondition: "Filtrer les conditions",
      email: "Boîte aux lettres",
      emailPlaceholder: "Entrez la boîte aux lettres, divisée par des virgules",
      updateType: "Type de mise à jour",
      pushDetail: "Détails push",
      pushUrl: "Push URL",
      pushWay: "Type de push",
      pushMethod: "Méthode push",
      pushContentType: "Format des données",
      pushData: "Données push",
      additionalData: "Données supplémentaires",
      inspectionType: "Type d'inspection de qualité",
      all: "Tous",
    },
    taskAdditionalData: {
      newData: "Ajout de données",
      delete: "Supprimer",
      placeholder: "Veuillez entrer un nom",
      dataPlaceholder: "Veuillez entrer le contenu",
    },
    UdeskQaReact: {
      loadSdkError:
        "Le chargement du SDK a échoué, Veuillez réessayer plus tard!",
    },
    gearOptionList: { evaluation: "Évaluation" },
    tableComponentTransfer: {
      leftTitle: "Éléments de liste",
      rightTitle: "Éléments d'affichage",
    },
    kmTinymce: {
      link: "Insérer / modifier un lien",
      modalTitle: "Insérer un lien",
      href: "Adresse",
      text: "Afficher le texte",
      title: "Titre",
      target: "Méthode d'ouverture",
      none: "Aucun",
      newWindow: "Ouvrir dans une nouvelle fenêtre",
      buttonStyle: "Style de bouton",
      close: "Fermé",
      open: "Ouvrir",
      answerMessage: "Sauter / envoyer un message",
      answerMessageTitle: "Bouton envoyer un message",
      answerMessageText: "Afficher le texte",
      answerMessageContent: "Contenu du message",
      answerMessageButtonStyle: "Style de bouton",
      answerMessageTips:
        "Remarque: le bouton envoyer un message ne prend en charge que le canal Web, configurez - le avec prudence",
      answerSwitchStaff: "Jump / turn artificiel",
      answerSwitchStaffTitle: "Tourner le bouton artificiel",
      answerSwitchStaffText: "Afficher le texte",
      answerSwitchStaffRule: "Règle du bouton",
      answerSwitchStaffButtonStyle: "Style de bouton",
      answerSwitchStaffTips:
        "Remarque: le bouton tourner humain ne prend en charge que le canal Web, veuillez le configurer avec prudence",
      textButton: "Bouton texte",
      borderButton: "Bouton avec cadre",
      message: "Envoyer un message",
      switchStaff: "Transfert artificiel",
      messageError: "Le contenu du bouton ne peut pas dépasser 10 mots",
      messageIsEmptyError: "Le contenu du bouton ne peut pas être vide",
      okText: "Déterminer",
      cancelText: "Annulation",
      video: "Vidéo",
      uploadVideo: "Télécharger une vidéo",
      uploadText:
        "Supporte uniquement le format MP4, la taille ne dépasse pas 20m",
      videoEmptyOrUploading: "La vidéo est vide ou téléchargée",
      customEmoji: "Expressions",
    },
    massageConfiguration: {
      pushTarget: "Objectifs de notification",
      customizePushTarget: "Objectifs Push personnalisés",
      sysUser: "Utilisateurs actuels du système",
      sysRole: "Rôle actuel du système",
      emailPushTarget: "Envoyer une cible",
      targetEmailsPlaceholder:
        "Veuillez entrer votre boîte aux lettres et les séparer par des virgules en anglais",
      subject: "Nom du message",
      SDKID: "Identification du SDK",
      content: "Contenu de la notification",
      sdkPushType: "Programme push",
      sdkPushURL: "Push URL",
      pushData: "Données push",
      sdkPushWay: "Méthode push",
      dataType: "Format des données",
      additionalData: "Données supplémentaires",
      name: "Nom du message",
      filter: "Filtrer les conditions",
      triggerType: "Mécanisme de déclenchement",
      pushCycle: "Timing fréquence",
      pushContent: "Contenu du rapport",
      qualityList: "Liste des résultats du contrôle qualité",
      designated: "Personnes désignées",
      messageType: "Type de message",
    },
    taskCard: {
      template: {
        cancelCollection: "Annuler la collection",
        collection: "Collections",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Phrase standard:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Collections",
          calls: "Un appel",
          createdBy: "Créé par:",
          createdOn: "Temps de création:",
        },
        fileItem: {
          uIProvidesIcon: "UI offre Icon",
          minute: "Minutes",
          callClip: "Fragments d'appel",
          start: "Commencer",
          End: "La fin",
          employees: "Employés:",
          Customer: "Le client:",
          sessionTime: "Durée de la session:",
          reasonForRecommendation: "Raisons de recommander:",
          addedBy: "Ajouté par:",
          addTime: "Ajouter le temps:",
        },
        modalAddFile: {
          caseTitle: "Titre du cas",
          addSomeClipsToTheCaseLibrary:
            "Ajouter des fragments partiels à la Bibliothèque de cas",
          addToCaseBase: "Ajouter à la Bibliothèque de cas",
          storedIn: "Stocké dans",
          pleaseSelect: "Veuillez sélectionner",
          reasonsForRecommendation: "Raisons de recommander",
          pleaseEnter: "Veuillez entrer",
        },
      },
      shareModal: {
        index: {
          customer: "Le client:",
          employees: "Employés:",
          timeliness: "La prescription:",
          recordingLinkValidWithinDays:
            "Le lien enregistrement en jours est valide",
          sharingInstructions: "Instructions de partage:",
          shareSuccess: "Partagez le succès!",
          cancel: "Annulation",
          share: "Partager",
          copySucceeded: "Copie réussie!",
          copyShareLink: "Copier le lien de partage",
          shareWithColleagues: "Partager avec des collègues",
          shareWithCustomers: "Partager avec les clients",
          staff: "Employés",
          selectColleagues: "Choisir un collègue",
          pleaseSelectColleaguesToShare:
            "Veuillez sélectionner un collègue à partager",
          pleaseEnterTheSharingDescription:
            "Veuillez entrer les instructions de partage",
          taskName: "Mission:",
          recordingShareValidWithinDays:
            "Le lien de partage en jours fonctionne",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Impossible de trouver le fichier!",
          sale: "Vente:",
          customer: "Le client:",
          topicDetection: "Détection de sujet:",
          keyEvents: "Événements clés:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Choisir un collègue",
          helpContent: "Contenu de l'aide",
          visibleRange: "Gamme visible",
          seekCounseling: "Rechercher un coaching",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Chercher des conseils", concatText: "Vers" },
      },
      customerInsight: {
        index: {
          customerInformation: "Informations pour les clients",
          customerInsight: "Aperçu client",
          customerLabel: "Étiquette client",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Recommandation vocale",
          objectionHandlingScripts: "Traitement des objections",
          productSellingPointScripts: "Discours de vente de produits",
          knowledgePointRecommendation: "Points de connaissance recommandés",
          relatedKnowledgePoints: "Points de connaissance associés",
          recommendedCases: "Cas recommandés",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Recommandation vocale",
          caseRecommendation: "Recommandation de cas",
          hit: "Frappé",
          miss: "Pas touché",
          supervisionItem: "Articles de supervision",
          implementation: "Mise en œuvre",
          reInspection: "Vérification de la qualité",
          supervisionItemSettings: "Paramètres des éléments de supervision",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Appels téléphoniques",
          enterpriseWeChatCall: "Micro - appel",
          enterpriseMicroConversation: "Micro - session d'entreprise",
          open: "Ouvrir",
          close: "Fermé",
          conversationScenario: "Scène de session",
          monitorConversationChannels: "Supervision des canaux de session",
          permanentlyValid: "Permanent et efficace",
          permanentlyInvalid: "Invalidité permanente",
          customValidity: "Période de validité personnalisée",
          termOfValidity: "Période de validité",
          newRating: "Nouvelle évaluation",
          whenConversation: "Lorsque la session",
          hit: "Frappé",
          miss: "Pas touché",
          time: "Lorsque",
          rated: "L'évaluation est",
          qualified: "Qualifié",
          unqualified: "Non qualifié",
          modifiedSuccessfully: "Modification réussie",
          classificationName: "Nom de la classification",
          supervisionObject: "Objet de supervision",
          entryintoforceTime: "Heure d'entrée en vigueur",
          supervisionItemRating: "Évaluation du poste de supervision",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Veuillez vérifier que l'évaluation de l'élément de supervision est correcte",
          ratingRules: "Règles de classement",
          settingOfRatingRulesForSupervisionItems:
            "Paramètres des règles de notation des éléments de supervision",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Événements clés",
          supervisionItem: "Articles de supervision",
          conversationScenario: "Scène de session",
          customerLabel: "Étiquette client",
          reDetectionRules: "Règles de re - détection",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Télécharger l'enregistrement",
          downloadText: "Télécharger le texte",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Télécharger une image",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Veuillez sélectionner une image dont la taille ne dépasse pas 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Ou",
            and: "Et",
            wrong: "Non",
            matchTo: "Correspondre à",
            rule: "Règles",
            satisfactionOperator: "Satisfaire l'opérateur",
            customerLabel: "Étiquette client",
            keyEvents: "Événements clés",
            knowledgePoints: "Points de connaissance",
            sessionLabel: "Étiquettes de session",
            supervisionItem: "Articles de supervision",
          },
          linkExplorer: { linkInTheText: "Liens dans le texte:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound: "Aucun composant personnalisé trouvé:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "Personnalisation" },
      dropdownMonit: {
        updateSucceeded: "Mise à jour réussie",
        areYouSureToDelete: "Sûr de supprimer?",
        custom: "Personnalisation",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Enregistrer la configuration actuelle du rapport = = = formulaire",
        saveCurrentReportConfiguration:
          "Enregistrer la configuration actuelle du rapport",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "S'il vous plaît entrer un nom de réglage commun avec une longueur maximale de 20!",
        preservation: "Sauvegarder",
      },
      template: {
        saveCurrentReportConfiguration:
          "Enregistrer la configuration actuelle du rapport",
        updateCurrentReportConfiguration:
          "Mettre à jour la configuration actuelle du rapport",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Informations sur les ordres de travail",
        workOrder: "Ordre de travail",
        triggerScenario: "Scène de déclenchement",
        associatedCallConversation: "Appels / conversations associés",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Testé avec le texte courant pass / talk",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "Le numéro", day: "Jours" },
      businessSummary: {
        normal: {
          equalTo: "égale à",
          notEqualTo: "Pas égale à",
          contain: "Contient",
          excluding: "Ne contient pas",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Je suis ce que je suis, ce sont des feux d'artifice différents @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi, I am a Robot Quality check Warning message notice depuis le 17 octobre ajouté au groupe",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Je suis ce que je suis, ce sont des feux d'artifice différents @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi, I am a Robot Quality check Warning message notice depuis le 17 octobre ajouté au groupe",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Thèmes de communication",
          conversationSummary: "Résumé de la session",
          serviceSuggestions: "Suggestions de services",
        },
        template: { smartTags: "Étiquettes intelligentes" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Cliquez pour en savoir plus" },
      schema: {
        textRobot: "Robot de texte",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Escorte d'entraîneur, questions et réponses sur les documents, conversations de mission, questions et réponses sur les formulaires, questions et réponses sur les atlas",
        capitalRetentionRobot: "Robot de rétention",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Stratégie de rétention riche, communication anthropomorphique élevée, taux de rétention élevé garanti, conversion automatique efficace des indices",
        outboundRobot: "Robot d'appel extérieur",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Visites de retour de fin d'ordre de travail, études de satisfaction de service, conseils d'avertissement d'échec d'exploitation et d'entretien, rappels d'expédition de commande, notifications d'activité de commerce électronique, rendez - vous de candidat, automatisation d'affaires d'assistance, efficacité d'affaires améliorée",
        incomingRobot: "Le robot entrant",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Intégration flexible des ordres de travail, des commandes et d'autres systèmes d'affaires pour automatiser les processus; Reconnaissance personnalisée du modèle sémantique de la parole, effet commercial immédiat; Identification de l’entité (numéro de téléphone portable, montant de la commande, heure de la commande, adresse, …) pour un accompagnement rapide des entreprises complexes",
        intelligentQualityInspection: "Contrôle de qualité intelligent",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Inspection de qualité complète, rapide et efficace, précision de 85%",
        intelligentSessionAnalysis: "Analyse de session intelligente",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Insight Business shortboard, écouter les cœurs des clients et stimuler la conversion Marketing",
        enterpriseWechatConversationAnalysis:
          "Analyse des sessions Wechat d'entreprise",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Identifier efficacement les opportunités potentielles, les risques et augmenter les conversions marketing de 20%",
        assistant: "Assistant assis",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "L'assistant assis peut assurer le service à la clientèle novice pour compléter le service et améliorer la qualité des appels du service à la clientèle. Faites de chaque siège votre as",
        digitalPeople: "Personnes numériques",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          "« face - à - face » communication personnifiée, soutenir le son, le texte, la vidéo, l'interaction multimodale tactile, réaliser une expérience de communication immersive, améliorer l'image de marque et l'expérience de service utilisateur",
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Ai Knowledge Middle Desk · recherche d'entreprise",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Recherche de connaissances, partage de connaissances, recherche de documents, recherche de base de données",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Ai Knowledge Middle Desk · base de connaissances kcs",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Fournit une gestion complète du cycle de vie des connaissances, une maintenance unifiée des bases de connaissances robotiques et de messagerie instantanée, prend en charge l'amarrage des bases de connaissances tierces, des modèles de connaissances, des connaissances atomiques et d'autres fonctionnalités puissantes apportent une expérience de gestion des connaissances plus pratique",
        aIKnowledgeCenterKnowledgeMap:
          "Ai Knowledge Middle Desk · Atlas des connaissances",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "Construction d'Atlas à guichet unique fusionne les connaissances de l'entreprise, l'image agrandie montre les détails des connaissances, la capacité d'amarrage crée une expérience personnalisée",
        modularDevelopmentPlatformOriginalEngineASR:
          "Plateforme de développement modulaire · moteur original · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Adoptez des algorithmes de bout en bout pour obtenir une expérience d'effet personnalisée pour l'entreprise avec un taux de reconnaissance de scène naturelle de plus de 90% et une reconnaissance de vocabulaire personnalisée de plus de 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Plateforme de développement modulaire · Smart Marketing",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Utilisez des algorithmes d'apprentissage profond combinés avec des portraits d'utilisateurs pour obtenir des recommandations précises sur les produits et améliorer la conversion des ventes",
        modularDevelopmentPlatformUserPortrait:
          "Plateforme de développement modulaire · portrait d'utilisateur",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Créez un portrait d'utilisateur précis basé sur les données utilisateur et les données de conversation, décrivant pleinement les caractéristiques du client",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Plateforme de développement modulaire · Smart recommendations",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Selon le portrait de l'utilisateur et le portrait du produit, recommander automatiquement aux utilisateurs des produits susceptibles de les intéresser, réaliser des milliers de recommandations personnalisées",
        modularDevelopmentPlatformNLPPAAS:
          "Plateforme de développement modulaire · NLP paas",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Fournit des compétences de base de la PNL avec une grande précision, couvrant les capacités sous - jacentes de différentes granularités telles que les mots, les phrases, les articles, etc.",
      },
    },
    qualityScore: {
      index: {
        show: "Affichez:",
        displayBySecondaryClassification: "Affichez par niveau deux",
        showByRule: "Affichez selon les règles",
        pleaseEnterClassificationruleName:
          "Veuillez saisir le nom de la classification/règle",
        clickCopy: "Cliquez sur copier",
        hitDetailsOfOperatorsInRules:
          "Détails de l'opérateur hit dans la règle:",
        hit: "Frappé",
        miss: "Pas touché",
        filter: "Filtrer:",
        clickToViewDetails: "Cliquez pour les détails",
      },
      components: {
        flowNodeList: {
          branch: "Points",
          pleaseSelect: "Veuillez sélectionner",
          viewTheCompleteProcess: "Voir le processus complet",
          thereIsCurrentlyNoProcessAvailable: "Pas de processus pour le moment",
        },
        pointItem: { xiangHit: "Le hit" },
        flowNodeListModal: { index: { completeProcess: "Processus complet" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Si la tâche d'inspection de la qualité du processus est modifiée, veuillez faire appel à nouveau.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Ces données ont été régulièrement effacées",
        subject: "Thème:",
        description: "Description:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask: "Ajouter à la tâche contrôle qualité",
          },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Appels / conversations associés" },
    },
    samplingTarget: {
      index: {
        time: "Lorsque",
        samplingQuantityIsLessThan:
          "Le nombre d'inspections par tirage est inférieur à",
        alertWhen: "Alerte précoce quand",
        everyday: "Quotidien",
        weekly: "Hebdomadaire",
        monthly: "Par mois",
        samplingInspectionTargetSavedSuccessfully:
          "Objectif d'inspection aléatoire Save success",
        personnel: "Personnel",
        eachCustomerService: "Chaque service à la clientèle",
        cycle: "Le cycle",
        samplingInspectionTarget: "Objectif de l'inspection éclair",
        strip: "Article",
        alertSettings: "Paramètres d'alerte précoce",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Service à la clientèle",
        complete: "Terminé",
        Objective: "/ Objectifs",
        everyday: "Quotidien",
        weekly: "Hebdomadaire",
        monthly: "Par mois",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Taux d'interaction du chat de groupe",
        maximumResponseIntervalTime: "Temps maximum entre les réponses",
        numberOfPreemptiveCalls: "Nombre de conversations",
        numberOfMessages: "Nombre de barres de message",
        strip: "Article",
        proportionOfMessages: "Pourcentage de messages",
        speechPercentage: "Parler en pourcentage",
        longestCustomerTalk: "Le client le plus long parle",
        numberOfIndepthDiscussions: "Nombre de discussions approfondies",
        second: "Une fois",
        maximumDurationOfContinuousSpeech:
          "La plus longue durée de conversation continue",
        questionFrequency: "Fréquence des questions",
        customer: "Le client",
        keyEvents: "Événements clés:",
        opportunity: {
          index: {
            generationFailed: "La génération a échoué",
            generateOpportunityPoints: "Générer des points d'opportunité",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "La génération a échoué",
            generateSummary: "Générer un résumé",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Sûr de supprimer?",
        add: "Ajouter",
        opportunityPoints: "Points d'opportunité",
        conversationSummary: "Résumé de la session",
        cancel: "Annulation",
        determine: "Déterminer",
        edit: "Modifier",
        establish: "Créer",
        conversationalInsights: "Insights conversationnels",
        keyEvents: "Événements clés",
        numKeyEvents: "Un événement clé",
        questionAnalysis: "Analyse des questions",
        find: "Découvrir",
        questions: "Sous - Question",
        conversationAction: "Action conversationnelle",
        customer: "Le client",
        sale: "Vendre",
        sessionLabel: "Scène de session",
        employeeQuestions: "Questions des employés",
        customerQuestions: "Questions des clients",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Le client",
        customerService: "Service à la clientèle",
        replyTo: "Répondre à",
        addressee: "Destinataire:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Ré - identification",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "La ré - identification consomme du temps de transcrite, faites preuve de prudence",
        determine: "Déterminer",
        executionSuccessful: "Exécution réussie",
        addToQualityInspectionTask: "Ajouter à la tâche contrôle qualité",
        batchOperation: "Opérations par lots",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Ajouté avec succès",
        addToQualityInspectionTask: "Ajouter à la tâche contrôle qualité",
        qualityInspectionTask: "Tâches d'inspection de la qualité",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Détecter le rôle",
        detectionRange: "Gamme de détection",
        sentence: "Phrases",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Ces données ont été régulièrement effacées",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Retour aux systèmes d'affaires" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Extraction de problèmes",
        businessSummary: "Résumé des opérations",
        keyWord: "Mots clés",
      },
      components: {
        problemExtraction: {
          no: "Non",
          customerServiceDidNotAnswer: "Service client sans réponse",
          customerServiceHasAnswered: "Le service client a répondu",
          problemUnresolved: "Problème non résolu",
          theProblemHasBeenResolved: "Problème résolu",
          customerIssue: "Questions des clients:",
          customerServiceAnswer: "Réponse du service client:",
          reExtractQuestions: "Problème de ré - extraction",
          clickToExtractQuestions: "Cliquez sur tirer la question",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Nom du portrait",
            backgroundDescription: "Description du contexte",
            customerLabel: "Étiquette client",
            labelManagement: "Gestion des étiquettes",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: {
              index: { newlyBuild: "Nouvelle construction" },
            },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Demander les paramètres" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Augmenter le questionnement",
              remove: "Supprimer",
              addQuestioning: "Ajouter une question",
              section: "Le numéro",
              secondInquiry: "Demande secondaire",
              questioningScore: "Demander le score",
            },
          },
        },
        index: {
          accordingToRules: "Par règles",
          byScore: "Par score",
          whenMissRule: "Quand les règles ne sont pas atteintes",
          whenConductingQuestioning: "Lors de la poursuite",
          pleaseSelectARule: "Veuillez sélectionner une règle",
          ruleCannotBeEmpty: "Les règles ne peuvent pas être vides",
          currentScriptScore: "Score orthographique ≤",
          pleaseEnterTheScore: "Veuillez entrer un score",
          scoreCannotBeEmpty: "Le score ne peut pas être vide",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Narrateur:" },
          pPT: { slide: "Diapositives" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Commentaires sur Speech",
              script: "La parole",
              semantics: "Sémantique",
              violatingWords: "Mots de violation",
              speechSpeed: "Vitesse de la parole",
              emotion: "Les émotions",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Commentaires sur Speech",
              regenerate: "Régénérer",
              generate: "Générer",
              hitSituation: "Situation de Hit",
              noSemanticTagsMatched:
                "Ne correspond pas aux étiquettes sémantiques",
              matchToSemanticTags: "Correspondance aux étiquettes sémantiques",
              appealToBeSubmitted: "Plainte à soumettre",
              ratingHasBeenReviewed: "Le score a été revu",
              ratingAppealInProgress: "Dans les plaintes notées",
              expressionScore: "Score d'expression",
              moodParticlesTimes: "Mots de ton, {0} fois",
              changeTheModalParticlesTo: "Le mot de ton est changé en",
              second: "Une fois",
              interrogation: "Demander après",
              questioningWithoutScoring: "Demander pas de score",
              branch: "Points",
              matchingRate: "Taux de correspondance",
              ratingAppeal: "Score des plaintes",
              changeTo: "Remplacer par",
              wordPerMinute: "Mots par minute",
              completeScript: "Parole complète",
              incompleteScript: "La parole incomplète",
              semanticHit: "Sémantique hit",
              semanticMisses: "Sémantique manquée",
              scriptViolation: "Violations de la parole",
              modified: "Modifié",
              notChanged: "Non modifié",
              keyWord: "Mots clés",
              semanticLabels: "Étiquettes sémantiques",
              semanticMatchingRate: "Taux de correspondance sémantique",
              violatingWords: "Mots offensants »",
              delete: "Supprimer",
              unmatched: "Ne correspond pas",
              matching: "Match",
              matchTo: "Correspondre à",
              notMatchedTo: "Ne correspond pas à",
              scriptScore: "Score phonétique",
              standardScript: "Langage standard",
              emotionalScore: "Score émotionnel",
              speechSpeedScore: "Score de vitesse vocale",
              viewResolution: "Voir la résolution",
              nodeConfiguration: "Configuration des nœuds",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Délai de réponse à cette question, soumission automatique du contenu de la réponse",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Délai de réponse à cette question, soumission automatique du contenu de la réponse",
          },
          correctBtn: {
            saveSuccessful: "Sauvegarder le succès",
            errorCorrection: "Correction d'erreurs",
            cancel: "Annulation",
            preserve: "Sauvegarder",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Règles de score",
            cancel: "Annulation",
            determine: "Déterminer",
          },
          scoringRuleItem: {
            whole: "Tous",
            arbitrarily: "Arbitraire",
            pleaseEnterARuleName: "Veuillez entrer le nom de la règle",
            standardScript: "Langage standard",
            if: "Si",
            and: "Et",
            or: "Ou",
            thenScore: "Alors score",
            pleaseEnterAScore: "Veuillez entrer une valeur de point",
            bonusPoints: "Points supplémentaires",
            minusPoints: "Moins de points",
            hit: "Frappé",
            misses: "Pas touché",
            keyWord: "Mots clés",
            semantics: "Sémantique",
          },
          title: {
            ruleValidation: "Vérification des règles",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Règles de score (le score total actuel est de",
            points: "Points)",
            editRules: "Modifier les règles",
          },
        },
      },
      userTagsSelect: {
        index: { noLabelsCurrentlyAvailable: "Pas encore d'étiquette" },
      },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Objectifs de communication",
              scoringCriteria: "Critères de notation",
            },
          },
        },
        index: {
          generating: "Dans la génération",
          generateDialogueFramework: "Générer un cadre de dialogue",
          theme: "Thèmes",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Langue de conseils",
            generatePromptLanguage: "Générer des invites",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Phase de communication",
          communicationObjectives: "Objectifs de communication",
          scoringCriteria: "Critères de notation",
          suggestionsForImprovement: "Suggestions d'élévation",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Score du processus",
            contentIntegrity: "Intégrité du contenu:",
            NumberOfCompletedProcesses: "% de processus terminés",
            keyHitPointsTotal: "Points clés touchés: Total",
            notReached: "Non atteint.",
            accurateFollowupAccumulationOfStandardScripts:
              "Précision de lecture: accumulation de la parole standard",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Un mot, le nombre total de mots suivis par l'apprenant",
            aWord: "Un mot",
            languageNormsYes: "Spécifications linguistiques: Oui",
            emotionsAppearing: "Emotions: apparition",
            speechSpeedSpeakingTooFast: "Vitesse de la parole: trop rapide",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Mots de ton: présence excessive de mots de ton",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Ajouter à ignorer les étiquettes",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Êtes - vous sûr de vouloir ré - exécuter les Smart Tags?",
        reExecute: "Ré - exécution",
      },
      component: { successfullyAdded: "Ajouté avec succès" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Fermer toutes les notifications",
        reQualityInspectionTask: "Mission de contrôle de la qualité",
        requalityInspection: "Vérification de la qualité",
        waiting: "En attente",
        viewDetails: "Voir les détails",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Temps de détection:" } },
        },
        template: { responseOpeningSentence: "Phrase de début de réponse" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "La génération a échoué",
            generate: "Générer",
          },
        },
        empty: {
          index: {
            thereIsCurrentlyNoDataAvailable: "Pas de données pour le moment",
          },
        },
      },
      index: { regenerate: "Régénérer" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Sûr de supprimer?",
        operationSuccessful: "Opération réussie",
      },
      template: { delete: "Supprimer" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Veuillez sélectionner une question!",
        similarQuestionRecommendation: "Similaire demander recommander",
        selectAllOnPage: "Lorsque la page est sélectionnée",
        cancel: "Annulation",
        preserve: "Sauvegarder",
        changeBatch: "Changer un lot",
      },
    },
    listControl: { index: { columnControl: "Contrôle des colonnes" } },
    loginUserAvatar: { index: { switch: "Basculer" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Lors de l'ajout de données en temps réel, l'exécution des tâches d'inspection de la qualité prend encore beaucoup de temps. Le temps spécifique dépend de la quantité de données d'inspection de la qualité, allant de quelques minutes à 2 heures.",
      },
    },
    bEditor: {
      index: {
        delete: "Supprimer",
        cancel: "Annulation",
        confirm: "Déterminer",
        insert: "Insérer",
        width: "Largeur",
        height: "Hauteur",
        clearContent: "Effacer le contenu",
        revoked: "Annulation",
        redo: "Refaire",
        fontSize: "Numéro de police",
        rowHeight: "Ligne haute",
        wordSpacing: "Espacement des mots",
        paragraphIndent: "Retrait de paragraphe",
        increaseIndent: "Augmenter le retrait",
        decreaseIndent: "Réduire le retrait",
        border: "Bordure",
        color: "Couleur",
        textColor: "Couleur du texte",
        backgroundColor: "Couleur de fond",
        temporaryColor: "Couleur temporaire",
        bold: "Épaississement",
        italic: "Italique",
        underline: "Soulignement",
        strikethrough: "Supprimer la ligne",
        font: "Polices de police",
        textAlignment: "Alignement du texte",
        left: "À gauche",
        centered: "Au milieu",
        right: "À droite",
        bothEnds: "Les deux extrémités",
        leftFloat: "Flotteur gauche",
        rightFloat: "Flottant à droite",
        subrupt: "L'offre supérieure",
        subscript: "Indiquer",
        clearStyle: "Effacer les styles",
        title: "Titre",
        regular: "Général",
        orderedList: "Liste ordonnée",
        unorderedList: "Liste désordonnée",
        reference: "Référence",
        code: "Code",
        link: "Liens",
        clearLink: "Effacer le lien",
        horizontalLine: "Ligne horizontale",
        media: "Médias",
        mediaLibrary: "Médiathèque",
        smallExpression: "Petites expressions",
        fullScreen: "Plein écran",
        exitFullScreen: "Quitter le plein écran",
        enterLinkText: "Saisir le texte du lien",
        enterLinkAddress: "Saisissez l'adresse du lien",
        enterTheLinkAddressAndPressEnter: "Entrez l'adresse du lien et retour",
        openInNewWindow: "Dans une nouvelle fenêtre s'ouvre",
        removeLink: "Supprimer le lien",
        playingAudioFiles: "Lecture de fichiers audio",
        playVideoFiles: "Lecture de fichiers vidéo",
        embeddedMedia: "Médias embarqués",
        image: "Image",
        video: "Vidéo",
        audio: "Audio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Le nom",
        description: "Description",
        type: "Type",
        status: "Statut",
        actions: "Opérations",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Nom de la tâche",
        description: "Description",
        form: "Formulaire de contrôle qualité",
        actions: "Opérations",
        createUser: "Créé par",
      },
      samplingRecords: {
        createTime: "Le temps",
        type: "Type",
        operateUser: "Opérateur",
        number: "Ajouter la quantité",
        filterConditionList: "Filtrer les conditions",
        sampleRuleStr: "Conditions d'échantillonnage",
        timeGreaterThan: "Le temps est supérieur à",
        timeLessThan: "Temps inférieur à",
      },
    },
    customFilters: {
      createdAt: "Temps d'appel",
      customerId: "Le client",
      agentId: "Service à la clientèle",
      userGroupId: "Groupe de service à la clientèle",
      state: "Statut",
      lastEvaluateUserId: "Dernière personne notée",
      submitTime: "Dernière heure de soumission",
      totalScore: "Score total",
      formItemForbidIdList: "Articles interdits",
      formItemDeadlyIdList: "Article fatal",
    },
    samplingInspection: {
      callTime: "Temps d'appel",
      dialogTime: "Temps de conversation",
      originator: "Distributeur",
      createTime: "Temps de création",
      updateTime: "Temps de mise à jour",
      status: "État du processus",
      score: "Score",
      actions: "Opérations",
      reviewer: "Examinateur",
      samplingOfficer: "Inspecteur",
      id: "ID d'enregistrement d'appels",
      time: "Temps d'appel",
      phone: "Téléphone du client",
      staff: "Service à la clientèle",
      inspectionStatus: "État de l'inspection de qualité",
      samplingStatus: "État de l'inspection éclair",
      inspectionGrade: "Évaluation",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Mots standard",
        synonymWords: "Synonymes",
        synonymWordsNumber: "Nombre de synonymes",
        useFrequency: "Fréquence d'utilisation",
        effectiveness: "Efficacité",
        operation: "Opérations",
      },
      common: {
        useFrequency: "Fréquence d'utilisation",
        effectiveness: "Efficacité",
        operation: "Opérations",
      },
      professionalWords: {
        professionalWords: "Noms professionnels",
        useFrequency: "Statistiques d'utilisation",
        homophonic: "Mots chauds (remplacement homophonique)",
        target: "Substitution de noms",
        targetWords: "Mots de remplacement",
        homophonyWords: "Mots de blocage homophoniques",
        operation: "Opérations",
      },
      attentionWords: "Mots de préoccupation",
      ignoreWords: "Ignorer les mots",
      wordsWeight: {
        selfDefineWeightWords: "Mots de poids personnalisés",
        weightWords: "Mots de poids",
        weight: "Poids",
        useFrequency: "Fréquence d'utilisation",
        effectiveness: "Efficacité",
        operation: "Opérations",
      },
      corpusData: {
        corpusDescription: "Description du matériau",
        updateTime: "Temps de mise à jour",
        wordsCount: "Nombre de mots",
        effectiveness: "Efficacité",
        operation: "Opérations",
        corpusContent: "Contenu",
      },
    },
    semanticTags: {
      title: "Étiquettes sémantiques",
      tags: "Phrase standard",
      orgName: "Organisations affiliées",
      tagCount: "Nombre de formations",
      usingCount: "Nombre de citations",
      activeFlag: "Efficacité",
      suggestion: "Recommandations de formation",
      actions: "Opérations",
    },
    intelligentTags: {
      tagName: "Le nom",
      tagDesc: "Description",
      tagRefCnt: "Nombre de marqueurs",
      categoryName: "Classification des étiquettes",
      eventName: "Classification des événements",
      tagStatus: "Efficacité",
      actions: "Opérations",
      tagType: "Type d'étiquette",
    },
    dataPreprocess: {
      name: "Le nom",
      desc: "Description",
      dataSource: "Limitation des sources de données",
      type: "Type",
      status: "Efficacité",
      actions: "Opérations",
    },
    informationEntities: {
      name: "Le nom",
      apiName: "Nom de l'API",
      type: "Type",
      remark: "Description",
      activeFlag: "Efficacité",
      actions: "Opérations",
    },
    interactiveRecognition: {
      questionAndAnswer: "Questions et réponses standard",
      type: "Type",
      status: "Statut",
      actions: "Opérations",
      question: "Le problème",
    },
    role: { id: "ID", name: "Le nom", actions: "Opérations" },
    uploadDownload: {
      taskName: "Nom du fichier",
      createTime: "Date",
      size: "Taille",
      createUserName: "Opérateur",
      status: "Statut",
      result: "Résultats",
      actions: "Opérations",
    },
    taskCenterList: {
      name: "Contenu de la tâche",
      createTime: "Heure de soumission",
      startTime: "Heure de début",
      endTime: "Heure de fin",
      createUserName: "Opérateur",
      status: "Statut",
      result: "Résultats",
      actions: "Opérations",
    },
    customerInformation: {
      title: "Informations sur le service à la clientèle",
      data: "Voir uniquement les données anormales",
      list: {
        agentNo: "Numéro de travail / ID du service client",
        agentName: "Service à la clientèle",
        agentTel: "Téléphone",
        agentEmail: "Boîte aux lettres",
        updateTime: "Temps de mise à jour",
        actions: "Opérations",
      },
      upload: {
        second:
          "2. Téléchargez un fichier Excel conforme au format du modèle en cliquant sur le bouton importer",
      },
    },
    IndexManagement: {
      title: "Gestion des indicateurs",
      list: {
        indicatorName: "Le nom",
        indicatorDesc: "Description",
        activity: "Efficacité",
        inspectDataSource: "Sources de données",
        sysType: "Type",
        agentGroups: "Groupe de service à la clientèle",
        actions: "Opérations",
      },
      detail: {
        nameEmpty: "Le nom ne peut pas être vide",
        descript: "Description",
        descriptEmpty: "La description ne peut pas être vide",
        active: "Efficacité",
        activeEmpty: "La validité ne peut pas être vide",
        dataSource: "Sources de données",
        dataSourceEmpty: "La source de données ne peut pas être vide",
        grounp: "Groupe de service à la clientèle",
        grounpEmpty:
          "Le Groupe de service à la clientèle ne peut pas être vide",
        grounpHolder:
          "Veuillez sélectionner un groupe de service à la clientèle",
        type: "Méthode de notation",
        typeNone: "La méthode de notation ne peut pas être vide",
        base: "Points de base",
        baseNone: "La base ne peut pas être vide",
        positive: "Facteurs positifs",
        positiveNone: "Le facteur positif ne peut pas être vide",
        customAdd: "Ajouter un filtre",
        negative: "Les facteurs négatifs",
        negativeNone: "Les facteurs négatifs ne peuvent pas être vides",
        customOK: "Filtrer les critères:",
        judgeStrategy: "Règles de condition:",
        other: "Autres facteurs",
        otherAdd: "Ajouter des facteurs",
        agent: "Données brutes",
        section: "Nombre d'intervalles",
        sectionNone: "Le nombre d'intervalles ne peut pas être vide",
        step: "Intervalle de pas",
        stepNone: "Intervalle de pas",
        save: "Sauvegarder",
        cancel: "Annulation",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Tous",
      staff: "En personne seulement",
      department: "Cette division et au - dessous",
    },
    gongStatisticsDate: { week: "Semaine", month: "Mois", season: "Saison" },
    gongCoachTaskStatus: {
      wait: "À coacher",
      already: "Déjà coaché",
      ignore: "Ignoré",
    },
    callRangeStatus: {
      all: "Tous les appels",
      sale: "Les employés initient",
      customer: "Le client initie",
    },
    todoListType: {
      later: "Écoutez plus tard",
      help: "Aide au Coaching",
      contact: "Contactez plus tard",
      send: "Envoyer des informations",
      warn: "Alerte précoce aux risques",
    },
    frequencyTypes: {
      everyDay: "Quotidien",
      everyWeek: "Hebdomadaire",
      everyMonth: "Par mois",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Formation sur les données non catégorisées",
        markedDataCorrigenda: "Erratum de données étiqueté",
        classifiedDataCheck: "Contrôle des données classées",
        markedHistoryRecord: "Étiquetage historique",
        historyDataRecalculate: "Données historiques recalculées",
      },
      dataExtractMode: {
        random: "Complètement aléatoire",
        intelligentRecommend: "Recommandations intelligentes",
        custom: "Personnalisation",
        specifiedClass: "Classification spécifiée",
      },
      smartRatesList: {
        markRate: "Taux de notation",
        corrigendaRate: "Taux d'erratum",
        kl: "Dispersion K - l",
        signalNoiseRate: "Rapport signal sur bruit",
      },
    },
    visibleRangeTypes: {
      all: "Tous",
      customerGroup: "Groupe de service à la clientèle",
      userDefined: "Personnalisation",
    },
    samplingRateTypes: {
      everyDay: "Tirage quotidien",
      everyWeek: "Tirage hebdomadaire",
    },
    taskRemindTypes: {
      confirm:
        "Envoyer un rappel lorsque l'enregistrement d'une tâche d'inspection de la qualité nécessite une confirmation",
      appeal:
        "Enregistrement des tâches d'inspection de la qualité envoyer un rappel lorsqu'un grief est lancé",
    },
    weekDays: {
      monday: "Lundi",
      tuesday: "Mardi",
      wednesday: "Mercredi",
      thursday: "Jeudi",
      friday: "Vendredi",
      saturday: "Samedi",
      sunday: "Dimanche",
    },
    reportDateTypes: {
      dailyReport: "Tableau quotidien",
      weeklyReport: "Formulaire hebdomadaire",
    },
    samplingRangeDayTypes: { today: "Aujourd'hui", yesterday: "Hier" },
    samplingRangeWeekTypes: {
      thisWeek: "Cette semaine",
      lastWeek: "La semaine dernière",
    },
    samplingRuleTypes: {
      proportion: "Échantillonnage proportionnel",
      random: "échantillonnage aléatoire",
    },
    taskStatusTypes: {
      waitEvaluate: "À évaluer",
      waitSubmit: "À soumettre",
      waitConfirm: "À confirmer",
      waitReview: "À réviser",
      completed: "Terminé",
      all: "Tous",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Score direct",
        weightedScore: "Score de poids",
        deductScore: "Déduction de points",
        directScoreSecondLevel: "Score direct - niveau 2",
        weightedScoreSecondLevel: "Score de poids - niveau 2",
        deductScoreSecondLevel: "Points déduits - niveau II",
      },
    },
    fieldOperators: {
      is: "égale à",
      not: "Pas égale à",
      contains: "Contient",
      notContains: "Ne contient pas",
      not_contains: "Ne contient pas",
      containsAny: "Contient n'importe quel",
      contains_any: "Contient n'importe quel",
      is_any: "Contient n'importe quel",
      not_any: "Ne contient aucun arbitraire",
      notContainsAny: "Ne contient aucun arbitraire",
      not_contains_any: "Ne contient aucun arbitraire",
      containsAll: "Contient tout",
      contains_all: "Contient tout",
      notContainsAll: "Ne contient pas tout",
      nont_contains_all: "Ne contient pas tout",
      isNull: "Pour vide",
      is_null: "Pour vide",
      isNotNull: "Pas vide",
      is_not_null: "Pas vide",
      greaterThan: "Supérieure à",
      greater_than: "Supérieure à",
      greaterThanEq: "Supérieur ou égal à",
      greater_than_eq: "Supérieur ou égal à",
      lessThan: "Moins que",
      less_than: "Moins que",
      lessThanEq: "Inférieur ou égal à",
      less_than_eq: "Inférieur ou égal à",
      between: "Entre",
      notBetween: "Pas entre",
      not_between: "Pas entre",
      relative: "égale à",
      set: "Configuré pour",
      belongs: "Appartenant à",
      notBelongs: "N'appartient pas à",
      not_belongs: "Pas égale à",
      today: "Aujourd'hui",
      yesterday: "Hier",
      the_day_before_yesterday: "Avant - hier",
      tomorrow: "Demain",
      next_seven_day: "Les sept prochains jours",
      last_seven_day: "Les sept derniers jours",
      this_week: "Cette semaine",
      next_week: "La semaine prochaine",
      last_week: "La semaine dernière",
      this_month: "Ce mois - ci",
      next_month: "Le mois prochain",
      last_month: "Le mois dernier",
      this_year: "Cette année",
      next_year: "L'année prochaine",
      last_year: "L'année dernière",
      exact_match: "Match précis",
      add_value: "Nouvelle valeur ajoutée",
      remove_value: "Supprimer une valeur",
      set_null: "Vide",
      isAny: "Arbitrairement égale à",
      notAny: "Arbitrairement pas égal à",
      belongTo: "Appartenant à",
      notBelongTo: "N'appartient pas à",
      hasAny: "Contient n'importe quel",
      notHasAny: "Ne contient aucun arbitraire",
      prefixContains: "Le début est égal à",
      prefixNotContains: "Le début n'est pas égal à",
      suffixContains: "La fin est égale à",
      suffixNotContains: "La fin n'est pas égale à",
      nextSevenDay: "Les 7 prochains jours",
      lastSevenDay: "7 derniers jours",
      thisWeek: "Cette semaine",
      nextWeek: "La semaine prochaine",
      lastWeek: "La semaine dernière",
      thisMonth: "Ce mois - ci",
      nextMonth: "Le mois prochain",
      lastMonth: "Le mois dernier",
      thisYear: "Cette année",
      nextYear: "L'année prochaine",
      lastYear: "L'année dernière",
      after: "Plus tard (jour inclus)",
      before: "Avant (jour inclus)",
      allMatching: "Toutes les correspondances",
      allNotMatching: "Tout ne correspond pas",
      hit: "Frappé",
      not_hit: "Pas touché",
    },
    fieldsOperators: {
      is: "égale à",
      is_null: "Vide en même temps",
      head_equal: "Le début est égal à",
      tail_equal: "La fin est égale à",
      is_not_null: "Pas vide en même temps",
      not: "Pas égale à",
      diff_equal: "Différence égale à",
      diff_greater_than: "Différence supérieure à",
      diff_greater_than_eq: "Différence supérieure ou égale à",
      diff_less_than: "Différence inférieure à",
      diff_less_than_eq: "Différence inférieure ou égale à",
      hour_equal: "La même heure",
      day_equal: "Le même jour",
      week_equal: "La même semaine",
      month_equal: "Le même mois",
      year_equal: "La même année",
      day: "Jours",
      minute: "Minutes",
      hour: "Heures",
      part_equal: "Partiellement égale à",
    },
    samplingColumnCaptions: {
      created_at: "Temps d'appel",
      agent_id: "Service à la clientèle",
      get_direction: "Type d'appel",
      call_result: "Résultats des appels",
      survey: "Évaluation",
      derived_from_id: "Sources",
      quit_queue_why: "État de la file d'attente",
      queuing_duration: "La queue prend du temps",
      talking_seconds: "Durée de l'appel",
      ringing_seconds: "Temps de sonnerie",
      hangup_by: "Partie appelant",
    },
    callTypes: {
      callIn: "Appels entrants",
      callOut: "Exhale",
      threeParty: "(les trois parties)",
      consultation: "(consultation)",
      insert: "(insertion forte)",
      monitor: "(écoute)",
      transfer: "(transfert)",
      intercept: "(interception)",
      transferOutside: "(transfert vers l'extérieur)",
      threePartyOutside: "(ligne extérieure tripartite)",
      consultingOutside: "(consulter la ligne extérieure)",
    },
    callResults: {
      staffAnswer: "Réponse du service client",
      staffNotAnswer: "Service à la clientèle manqué",
      customerAnswer: "Les clients répondent",
      customerNotAnswer: "Client manqué",
      phoneBusy: "Ligne occupée par le téléphone",
      phoneOffline: "Téléphone hors ligne",
      customerSpeedHangUp: "Suspension rapide du client",
      customerHangUp: "Machine suspendue client",
      queueTimeout: "Timeout de la file d'attente",
      queueGiveUp: "Abandonner la file d'attente",
      outlineAnswer: "Ligne extérieure répondre",
      outlineNotAnswer: "Ligne extérieure non connectée",
      noChooseQueue: "Aucune file d'attente sélectionnée",
    },
    defaultSurveys: {
      noEvaluation: "Non évalué",
      noNeedToEvaluate: "Aucune évaluation requise",
    },
    queueStates: {
      queueSuccess: "Queue réussie",
      queueTimeout: "Timeout de la file d'attente",
      queueGiveUp: "Abandonner la file d'attente",
      noStaffOnline: "Aucun service client en ligne",
    },
    callHangers: {
      customer: "Le client",
      staff: "Service à la clientèle",
      outline: "Ligne extérieure",
    },
    workFlowTypes: {
      noAppealsMode: "Modèle sans plainte",
      appealsMode: "Autoriser le mode de plainte",
    },
    fieldCategories: {
      standardCondition: "Critères de filtre standard",
      customerCondition: "Critères de filtre personnalisés par l'utilisateur",
    },
    sampleTypes: {
      automatic: "échantillonnage automatique",
      manual: "Échantillonnage manuel",
    },
    defaultFields: {
      createdAt: "Temps d'appel",
      agentId: "Service à la clientèle",
      getDirection: "Type d'appel",
      callResult: "Résultats des appels",
      survey: "Évaluation",
      derivedFrom: "Sources",
      quitQueueWhy: "État de la file d'attente",
      queuingDuration: "La queue prend du temps",
      talkingSeconds: "Durée de l'appel",
      ringingSeconds: "Temps de sonnerie",
      hangupBy: "Partie appelant",
    },
    conditionFieldCategories: {
      fixedField: "Champs fixes",
      customField: "Champs personnalisés",
      secondLevelScore: "Classification secondaire",
      formItemScore: "Élément de valeur fractionnaire",
      forbids: "Articles interdits",
      deadlies: "Article fatal",
    },
    columnFieldCategories: {
      agent: "Service à la clientèle",
      callLog: "Enregistrement des appels",
      businessFields: "Informations opérationnelles",
      customer: "Le client",
      customerCustomFields: "Personnalisation du client",
      source: "Sources",
      queue: "La queue",
      fixed: "Fixe",
      secondLevelScore: "Classification secondaire",
      formItemScore: "Élément de valeur fractionnaire",
      forbids: "Articles interdits",
      deadlies: "Article fatal",
    },
    taskListDefaultFields: {
      startTime: "Temps d'appel",
      name: "Service à la clientèle",
      state: "Statut",
      totalScore: "Score total",
      formItemForbidList: "Articles interdits",
      formItemDeadlyList: "Article fatal",
      comment: "Remarques sur l'évaluation",
      inspector: "Personnes notées",
      review: "Examinateur",
    },
    evaluationStates: {
      initial: "Initialisation",
      waitEvaluate: "À évaluer",
      evaluated: "À soumettre",
      waitConfirm: "À confirmer",
      waitCheck: "À réviser",
      complete: "Terminé",
    },
    workBenchInfoTypes: {
      callInfo: "Informations sur les appels",
      businessInfo: "Informations opérationnelles",
      customerInfo: "Informations pour les clients",
    },
    evaluationEventStates: {
      create: "Créer",
      valuate: "Évaluation",
      edit: "Modifier",
      submit: "Soumettre",
      confirm: "Confirmation",
      appeal: "Plaintes",
      checkConfirm: "Confirmation de la révision",
      check: "Révision",
    },
    formItemTypes: {
      direct: "Score direct",
      weight: "Poids",
      deduct: "Déduction de points",
      forbid: "Articles interdits",
      deadly: "Article fatal",
    },
    appealCheckCallTypes: {
      callIn: "Appels entrants",
      callBack: "Rappel dans les deux sens",
      callOut: "Appel direct extérieur",
      autoCall: "Appel externe automatique",
    },
    appealCheckCallStatus: {
      waitReview: "À réviser",
      reviewed: "Examiné",
      filed: "Archivé",
    },
    samplingStatus: {
      all: "Tous",
      unchecked: "Tirage non vérifié",
      checked: "Vérifié",
    },
    inspectionStatus: {
      all: "Tous",
      unread: "Non lu",
      readed: "Lu",
      appeal: "Plaintes",
      review: "Révision",
      filed: "Archivé",
    },
    gradeChooseHits: { yes: "Oui", no: "Non" },
    appealCheckStatus: {
      all: "Tous les états",
      unread: "Non lu",
      inComplaint: "Dans la plainte",
      reviewed: "Examiné",
      readed: "Lu",
      filed: "Archivé",
      spotchecked: "Vérifié",
    },
    flowStatus: {
      unread: "Non lu",
      alreadyRead: "Lu",
      complaining: "Dans la plainte",
      reviewed: "Examiné",
      sampling: "Tirage non vérifié",
      samplingCompleted: "Vérifié",
      complainCancel: "A été révoqué",
    },
    complainStatus: {
      success: "Succès",
      fail: "L'échec",
      default: "Dans la plainte",
      canceled: "A été révoqué",
    },
    reviewInspectionStatus: {
      inComplaint: "Dans la plainte",
      reviewed: "Examiné",
    },
    gradeChangeTypes: {
      edit: "Modifier",
      check: "Révision",
      file: "Archive",
      remark: "Remarques",
      complain: "Plaintes",
      review: "Révision",
      reviewEvaluation: "Révision de l'évaluation",
      sample: "Inspection par tirage",
      sampleEvaluation: "Évaluation des inspections",
      sampleSubmit: "Soumission d'inspection par tirage au sort",
      sampleAssign: "Distribution par tirage au sort",
      reviewAssign: "Révision de la distribution",
      read: "Lu",
      reviewConfirm: "Confirmation de la révision",
      sampleConfirm: "Confirmation de l'inspection",
      caseBase: "Ajouter au Groupe de cas",
      sampleCancel: "Tirage au sort annuler la distribution",
      reviewCancel: "Révision de l'annulation de la distribution",
      sampleAgain: "Redistribution par tirage au sort",
      reviewAgain: "Révision de la redistribution",
      btachDelete: "Supprimer l'enregistrement de l'inspection éclair",
      rapidSampling: "Inspection rapide",
      reInspection: "Vérification de la qualité",
      smartTagsChange: "Changement d'étiquette intelligente",
      cancelAppeal: "Retrait de la plainte",
    },
    appealTypes: {
      transliterationError: "Erreur de transcrite",
      discriminationError: "Erreur de jugement",
      otherError: "Autres erreurs",
    },
    inspectDataSources: {
      voiceCall: "Appels vocaux",
      textDialogue: "Dialogue textuel",
      realTimeVoiceCall: "Appels vocaux en temps réel",
      realTimeTextDialogue: "Dialogue texte en temps réel",
      wechatDialogue: "Micro - session d'entreprise",
      taobao: "Dialogue Texte e - commerce",
      ticket: "Ordre de travail",
      wechatRadio: "Micro - voix",
    },
    inspectTaskType: {
      all: "Tous",
      common: "Contrôle qualité conventionnel",
      relate: "Contrôle de qualité associé",
    },
    inspectApproveType: {
      all: "Tous",
      resolve: "Audit passé",
      resolving: "En audit",
      reject: "Audit non passé",
    },
    dataSourceFlags: {
      all: "Aucune restriction",
      voiceCall: "Appel",
      textDialogue: "Dialogue",
    },
    smartWordsLibrary: { effective: "Efficace", invalid: "Invalide" },
    activeFlags: { effiective: "Efficace", invalid: "Invalide" },
    labelTypes: {
      dimensionLabel: "ÉTIQUETAGE",
      classificationLabel: "Étiquettes de catégorie",
    },
    pointTypes: {
      automatic: "Évaluation automatique",
      manual: "Évaluation manuelle",
      interactiveDetection: "Détection interactive",
      smartRules: "Règles intelligentes",
      machineLearning: "Mode d'auto - apprentissage",
      intelligentModel: "Le modèle intelligent",
    },
    pointGradeTypes: {
      radio: "Sélection unique",
      input: "Entrée",
      select: "Sélection",
      all: "Satisfaire tout",
      any: "Satisfaire tout arbitraire",
      customize: "Personnalisation",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Correspondance de mots clés",
      question: "Phrases de doute",
      regular: "Expressions régulières",
      context: "Répétition du contexte",
      semantics: "Correspondance sémantique",
      word: "Nombre de mots phrases similaires",
      dialogue: "Intervalle de temps de conversation",
      speed: "Détection de la vitesse de la parole",
      grabDetection: "Détection de discours",
      semanticTags: "Étiquettes sémantiques",
    },
    applyRoles: {
      all: "Tous",
      customer: "Le client",
      agent: "Service à la clientèle",
      robot: "Robots",
    },
    saleApplyRoles: { all: "Tous", customer: "Le client", agent: "Employés" },
    applyOperatorScopes: {
      all: "Texte intégral",
      preCondition: "Conditions préalables",
      scope: "Gamme spécifiée",
    },
    keywordScopes: {
      all: "Détecter tous les mots clés",
      any: "Détection de mots clés arbitraires",
    },
    preOperatorHitTypes: {
      first: "Première correspondance",
      every: "Chaque match",
      last: "Dernière correspondance",
      any: "Toute correspondance",
      none: "Ne correspond pas",
    },
    operatorRuleTypes: {
      all: "Satisfaire tous",
      any: "Satisfaire tout arbitraire",
      custom: "Logique personnalisée",
    },
    informationEntityTypes: {
      system: "Système",
      custom: "Personnalisation",
      moduleCompany: "Groupe de modules",
    },
    organizationTypes: { null: "Aucun", moduleCompany: "Groupe de modules" },
    customType: {
      smartTag: "Extraction d'entités",
      scriptTimes: "Orthographe fois",
      dataCategory: "Classification des données",
    },
    interactiveQuestionTypes: {
      standard: "Questions et réponses standard",
      multiElement: "Questions et réponses Multi - éléments",
      guideMultiRound: "Interaction guidée Multi - roues",
    },
    targetTypes: {
      all: "Tous",
      customerService: "Service à la clientèle",
      customer: "Le client",
    },
    interactiveTypes: {
      similarQList: "Problèmes similaires",
      exceptSimilarQList: "Exclure les problèmes similaires",
      similarAList: "Réponses similaires",
      wrongAList: "Mauvaise réponse",
    },
    filterTypes: {
      call: "Enregistrement des appels",
      dialogue: "Texte du dialogue",
      wechat: "Wechat d'entreprise",
      wechatAll: "Entreprise Wechat toutes les conversations",
      ticket: "Séance d'ordre de travail",
      taobao: "Séance de commerce électronique",
      wechatRadio: "Micro - voix",
    },
    fieldTypes: {
      keyword: "Mots clés",
      agent: "Service à la clientèle",
      agentGroup: "Groupe de service à la clientèle",
      sigleListbox: "Sélection unique",
      multiListbox: "Sélection multiple",
      text: "Une seule ligne de texte",
      textArea: "Plusieurs lignes de texte",
      dateTime: "Le temps",
      number: "Numérique",
      voice: "",
      customer: "Le client",
      fieldWithLink: "Avec champ de lien",
      percentage: "Pourcentage",
      intelligentClassification: "Classification intelligente",
      testSetList: "Test Set champs dynamiques",
      inspector: "Inspecteur qualité",
    },
    saveTypes: { save: "Sauvegarder" },
    knowledgeType: { oneToOne: "Une question une réponse" },
    updateTypes: { update: "Mise à jour", saveAs: "Enregistrer en tant que" },
    recommendItemActionTypes: {
      similar: "Similaires",
      exclude: "Exclusion",
      ignore: "Ignorer",
    },
    gradeStatus: {
      unread: "Non lu",
      alreadyReview: "Examiné",
      alreadyFiled: "Archivé",
      waitReview: "À réviser",
      alreadyRead: "Lu",
    },
    qualityCheckDetailTypes: {
      review: "Révision",
      spotCheck: "Inspection par tirage",
    },
    sampleAndReviewStatus: {
      init: "Non tiré",
      unread: "Non lu",
      alreadyRead: "Lu",
      complaining: "Dans la plainte",
      reviewed: "Examiné",
      sampling: "Tirage non vérifié",
      samplingCompleted: "Vérifié",
    },
    interactiveAnswerTypes: {
      noAnswer: "Aucune réponse détectée",
      answer: "Réponse détectée",
      wrongAnswer: "Mauvaise réponse détectée",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Aucun problème détecté",
      question: "Problème détecté",
      eliminateQuestion: "Problèmes d'exclusion détectés",
    },
    isManual: {
      yes: "Inspection manuelle de la qualité",
      no: "Pas de contrôle de qualité manuel",
    },
    readStatus: { yes: "Lu", no: "Non lu" },
    reInspectionType: {
      recalcScore: "Recalculer les scores uniquement (avec les notations)",
      recheckPoint:
        "Re - comptabilisation des points de contrôle de qualité et des scores",
    },
    common: {
      eCommerceReview: "Avis de commerce électronique",
      socialMediaComments: "Revue des médias sociaux",
      customer: "Le client",
      customerService: "Service à la clientèle",
      robot: "Robots",
      customerInformation: "Informations pour les clients",
      workOrderFields: "Champs d'ordre de travail",
      intelligentConversationAnalysis: "Analyse de session intelligente",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Insight Business shortboard, écouter les cœurs des clients et stimuler la conversion Marketing",
      intelligentAccompanyingPractice: "Entraînement intelligent",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Créez un entraînement immersif ai simulant des scénarios réels d'entreprise, réalisez une maîtrise complète de la parole d'or, une amélioration rapide des compétences de conversation",
      intelligentQualityInspection: "Contrôle de qualité intelligent",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Inspection de qualité complète, rapide et efficace, précision supérieure à 85%",
      salesEmpowerment: "Facilitation des ventes",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "L'Ia réinvente la gestion des ventes, permet la numérisation du processus de communication, la visualisation, la gestion transparente de chaque boucle des ventes, la communication intelligente à chaque étape, le diagnostic raffiné des problèmes d'affaires, la réplication parfaite de l'expérience de la Couronne",
      algorithmPlatform: "Plateforme algorithmique",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Basé sur la sémantique vocale du moteur de coeur d'auto - étude, ASR, personnalisation de modèle de PNL, traversant les données de scénario d'affaires, réalisant l'auto - apprentissage du système, atteignant l'objectif de plus d'utilisation intelligente, plus d'utilisation précise",
      generalSettings: "Paramètres universels",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Gestion unifiée des paramètres pour les autorisations de données, les rôles des employés",
      callLog: "Enregistrement des appels",
      intelligentRules: "Règles intelligentes",
      businessRecords: "Dossiers d'affaires",
      customFilter: {
        call: "Appels vocaux",
        text: "Dialogue textuel",
        smartTags: "Étiquettes intelligentes",
        pretreatment: "Pré - traitement",
        smartTagGroup: "Groupe d'étiquettes intelligentes",
        currency: "Données génériques",
        wechat: "Wechat d'entreprise",
        spotCheck: "Inspection par tirage",
        personaField: "Liste des clients",
        ticket: "Séance d'ordre de travail",
        taobao: "Séance de commerce électronique",
        wechatRadio: "Micro - voix",
        reviewList: "Révision",
      },
      talkerRecognitionType: {
        API: "Rôle de piste d'identification API",
        text: "Reconnaissance de texte",
      },
      operatorTypes: {
        keyword: "Correspondance de mots clés",
        question: "Phrases de doute",
        regular: "Expressions régulières",
        context: "Répétition du contexte",
        semantics: "Correspondance sémantique",
        word: "Nombre de mots phrases similaires",
        dialogue: "Intervalle de temps de conversation",
        speed: "Détection de la vitesse de la parole",
        grabDetection: "Détection de discours",
        semanticTags: "Étiquettes sémantiques",
        keywordStrict: "Correspondance de mots clés (strict)",
        link: "Champ de lien",
        entityInfoDetection: "Détection d'entités d'information",
        basicInfoDetection: "Détection des informations de base",
        volume: "Détection de volume",
        emotion: "Analyse des émotions",
        grammar: "Règles de droit",
        sentenceLength: "Détection de longueur de phrase",
        conceptWord: "Mots conceptuels",
        questionsAndAnswers: "Base de connaissances réponses (ancien)",
        element: "Questions et réponses sur les éléments",
        silent: "Délai de réponse",
        associate: "Données sur la route",
        repeat: "Envoi répété",
        timeInterval: "Durée de l'intervalle",
        typoDetection: "Détection de faux mots",
        silentOld: "Silence",
        knowledge: "Réponses de connaissances",
        wecomRemark: "Remarque du client",
        customerAddTime: "Temps ajouté par le client",
      },
      keywordOperators: {
        all: "Toutes les correspondances",
        allNot: "Tout ne correspond pas",
        any: "Toute correspondance",
      },
      applyRoles: {
        all: "Tous",
        customer: "Le client",
        agent: "Service à la clientèle",
      },
      sentenceLength: {
        less: "Moins que",
        greater: "Supérieure à",
        equal: "égale à",
        greater_or_equal: "Supérieur ou égal à",
        less_or_equal: "Inférieur ou égal à",
      },
      dialogueApplyScopes: {
        differentRoles: "Entre les différents rôles",
        agent: "Service à la clientèle",
        customer: "Le client",
        agentResponse: "Réponse du service client",
        customerResponse: "Réponse du client",
        customerNoResponse: "Aucune réponse du client",
      },
      applyConditionHitTypes: {
        first: "Le premier hit",
        every: "Chaque coup",
        last: "Le dernier coup",
      },
      applyConditionScopes: {
        current: "Actuellement",
        before: "Avant",
        after: "Après",
        beforeAll: "Avant tout",
        afterAll: "Après tout",
        around: "A proximité",
      },
      voiceDemoTypes: {
        singleChannel: "Enregistrement d'appels à canal unique",
        dualChannel: "Enregistrement d'appels à deux canaux",
      },
      sendStatus: {
        sending: "En envoi",
        arrive: "A été livré",
        fail: "L'envoi a échoué",
        off_sending: "Envoi hors ligne non lu",
        off_arrive: "Lu",
        rollback: "A été retiré",
      },
      collectionMethod: {
        assignment: "Affectation directe des valeurs",
        entity: "Extraction d'entités",
      },
      systemTypes: {
        qa: "Contrôle de qualité intelligent",
        wfm: "Classe intelligente",
      },
      entityOperators: {
        equals: "égale à information",
        notEquals: "Pas égal à information",
        contains: "Contient des informations",
        notContains: "Ne contient pas d'informations",
        equalsFix: "égale à une valeur fixe",
        notEqualsFix: "Pas égal à une valeur fixe",
        containsFix: "Contient une valeur fixe",
        notContainsFix: "Ne contient pas de valeur fixe",
      },
      basicInfoOperators: {
        equals: "égale à",
        notEquals: "Pas égale à",
        contains: "Contient",
        notContains: "Ne contient pas",
        isNull: "Pour vide",
        isNotNull: "Pas vide",
      },
      volumeDetectionWays: {
        loudness: "Décibels de volume",
        rangeAbilitySelf:
          "Amplitude du changement (par rapport à la phrase précédente)",
        rangeAbilityOth:
          "Amplitude du changement (par rapport à la phrase précédente)",
        rangeAbility3: "Amplitude du changement (par rapport à cette phrase)",
      },
      volumeDetectionOperators: {
        is: "égale à",
        not: "Pas égale à",
        greaterThan: "Supérieure à",
        greaterThanEq: "Supérieur ou égal à",
        lessThan: "Moins que",
        lessThanEq: "Inférieur ou égal à",
      },
      sentimentTypes: {
        positive: "émotions positives",
        negative: "émotions négatives",
        neutral: "Emotions neutres",
        thankful: "Merci",
        happy: "Heureux",
        complaining: "Se plaindre",
        angry: "En colère",
        post: "Le Front",
        negat: "Négatif",
        neut: "Neutre",
      },
      emotionOperators: { is: "Conforme", not: "Non conforme" },
      propTypes: { system: "Système", user: "Personnalisation" },
      valueTypes: { text: "Texte", number: "Numérique" },
    },
    tasks: {
      allowCustomGrievance: "Autoriser la personnalisation des motifs d'appel",
      businessSummary: "Résumé des opérations",
      theDayBeforeYesterday: "Avant - hier",
      assessActionTypes: { reassess: "Réévaluation" },
      scoreType: {
        aiScore: "Évaluation intelligente",
        manualScore: "Évaluation manuelle",
        interactiveDetection: "Détection interactive",
        smartRules: "Règles intelligentes",
        machineLearning: "Mode d'auto - apprentissage",
      },
      inspectType: { inspectTrue: "Vérifié à", inspectFalse: "Non vérifié" },
      operatorType: {
        keyword: "Correspondance de mots clés",
        notKeyword: "Les mots clés ne correspondent pas",
      },
      applyRole: {
        all: "Tous",
        customer: "Le client",
        agent: "Service à la clientèle",
      },
      applyScope: {
        all: "Tous",
        specific: "Phrase spécifiée",
        scope: "Gamme spécifiée",
      },
      judgeStrategy: {
        all: "Toutes les conditions sont remplies",
        arbitrarily: "Satisfaire à des conditions arbitraires",
        custom: "Logique personnalisée",
      },
      checkPointStatus: {
        waitFor: "En attente de révision",
        notDeductScore: "Pas de déduction de points",
        deductScore: "Déduction de points",
      },
      applyOptions: {
        hit: "Condition hit",
        notHit: "Aucune condition spécifiée",
      },
      gradeTypes: {
        yesOrNo: "Conformité avec",
        input: "Entrée manuelle",
        level: "Évaluation par répartition",
        multipleFactors: "Multi - éléments",
      },
      gradeChooseHits: { yes: "Oui", no: "Non" },
      gradeChangeTypes: {
        edit: "Modifier",
        check: "Révision",
        file: "Archive",
        remark: "Remarques",
        complain: "Plaintes",
        review: "Révision",
        reviewEvaluation: "Révision de l'évaluation",
        sample: "Inspection par tirage",
        sampleEvaluation: "Évaluation des inspections",
        sampleSubmit: "Soumission d'inspection par tirage au sort",
        sampleAssign: "Distribution par tirage au sort",
        reviewAssign: "Révision de la distribution",
        read: "Lu",
        reviewConfirm: "Confirmation de la révision",
        sampleConfirm: "Confirmation de l'inspection",
      },
      gradeStatus: {
        unread: "Non lu",
        alreadyReview: "Examiné",
        alreadyFild: "Archivé",
        waitReview: "À réviser",
        alreadyRead: "Lu",
      },
      samplingFrequencyTypes: {
        everyDay: "Quotidien",
        everyWeek: "Hebdomadaire",
        everyMonth: "Par mois",
      },
      samplingEveryDayTimeTypes: { yesterday: "Hier", today: "Aujourd'hui" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Cette semaine",
        lastWeek: "La semaine dernière",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Ce mois - ci",
        lastMonth: "Le mois dernier",
      },
      samplingTypes: {
        random: "échantillonnage aléatoire",
        proportional: "Échantillonnage proportionnel",
      },
      weekdays: {
        Monday: "Lundi",
        Tuesday: "Mardi",
        Wednesday: "Mercredi",
        Thursday: "Jeudi",
        Friday: "Vendredi",
        Saturday: "Samedi",
        Sunday: "Dimanche",
      },
      distributeTypes: {
        rule: "Distribution prioritaire des règles",
        average: "Distribution aléatoire moyenne",
      },
      notMatchAnyRuleTypes: {
        random: "Distribution moyenne aléatoire",
        assign: "Distribution aux personnes désignées",
        ignore: "Pas de distribution",
      },
      inspectorTypes: {
        all: "Tous les inspecteurs qualité",
        some: "Désigner un inspecteur qualité",
      },
      pushFrequencyTypes: {
        everyDay: "Quotidien",
        everyWeek: "Hebdomadaire",
        everyMonth: "Par mois",
        inspectionUpdate: "Mise à jour du contrôle qualité",
        timelyInspection: "Contrôle qualité en temps réel",
      },
      pushObjectTypes: {
        email: "Spécifier une boîte aux lettres",
        staff: "Service à la clientèle affilié",
      },
      reviewOptions: {
        reviewResult:
          "Affichage des résultats du contrôle qualité lors de la révision",
        reviewRecord:
          "Affichage des enregistrements pertinents lors de la révision",
        reviewAgentHidden:
          "Masquer les informations du service client lors de la révision",
        samplingResult:
          "Affichage des résultats de l'inspection de qualité lors de l'inspection",
        samplingRecord:
          "Affichage des enregistrements pertinents lors du tirage",
        sampleAgentHidden:
          "Masquer les informations du service client lors d'une inspection",
        checkExportAddCallInfoIphone:
          "Joindre un journal d'appels lors de l'exportation d'une liste",
        sampleExportAddCallInfoIphone:
          "Joindre un journal d'appels lors de l'exportation d'une liste",
        checkExportAddCallInfoText:
          "Joindre un enregistrement de conversation lors de l'exportation d'une liste",
        sampleExportAddCallInfoText:
          "Joindre un enregistrement de conversation lors de l'exportation d'une liste",
        inspectionExportAddCallInfoIphone:
          "Joindre des journaux d'appels lors de l'exportation de listes (jusqu'à 10 000 à la fois)",
        inspectionExportAddCallInfoText:
          "Joindre un enregistrement de conversation lors de l'exportation de la liste (vous pouvez exporter jusqu'à 10 000 à la fois)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Permet une exécution rapide de l'inspection par tirage au sort dans les détails de l'inspection de qualité",
        fastSampleInSampleDetail:
          "Permet une exécution rapide des contrôles au tirage détails",
        fastSampleInReviewDetail:
          "Permet d'effectuer rapidement des contrôles sur les détails de la révision",
      },
      permitCustomReasonList: {
        permitCustomReason:
          "Autoriser les raisons de représentation personnalisées",
      },
      autoMateTasks: {
        autoLoad: "Ajouter automatiquement",
        sample: "Inspection automatique",
        assign: "Distribution automatique",
        pushes: "Push automatique",
        assess: "Évaluation automatique",
        check: "Réexamen des plaintes",
        case: "Synchronisation de la Bibliothèque de cas",
      },
      qualityCheckDetailTypes: {
        review: "Révision",
        spotCheck: "Inspection par tirage",
      },
      appealTypes: {
        transliterationError: "Erreur de transcrite",
        discriminationError: "Erreur de jugement",
        otherError: "Autres erreurs",
      },
      inspectDataSources: {
        voiceCall: "Appels vocaux",
        textDialogue: "Dialogue textuel",
        realTimeVoiceCall: "Appels vocaux en temps réel",
        realTimeTextDialogue: "Dialogue texte en temps réel",
      },
      pointTypes: {
        automatic: "Évaluation automatique",
        manual: "Évaluation manuelle",
        interactiveDetection: "Détection interactive",
      },
      pointGradeTypes: {
        radio: "Sélection unique",
        input: "Entrée",
        select: "Sélection",
      },
      sampleAndReviewStatus: {
        init: "Non tiré",
        unread: "Non lu",
        alreadyRead: "Lu",
        complaining: "Dans la plainte",
        reviewed: "Examiné",
        sampling: "Tirage non vérifié",
        samplingCompleted: "Vérifié",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Ignorer les débordements excédentaires",
        automaticWeightCalculation: "Comptabilité automatique des poids",
      },
      calculateScoreTypes: {
        add: "Points supplémentaires",
        sub: "Moins de points",
      },
      naChecked: {
        check: "Permet que cet élément ne soit pas impliqué dans la détection",
      },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Donner des points proportionnellement",
        allRight: "Tout donner des points correctement",
        anyRight: "Donner des points arbitrairement correctement",
      },
      noHitQuestionResults: {
        true: "Le résultat est « oui ».",
        false: 'Le résultat est "non".',
      },
      repetRules: {
        same: "Le contenu est exactement le même",
        similar: "Sémantiquement similaire",
      },
      relateConditions: {
        highlightQuestion: "Problème de highlight",
        highlightAnswer: "Highlight Réponse",
        hightlightFoctors: "Éléments de surbrillance",
      },
      interactiveQuestionTypes: {
        question: "Problème détecté",
        eliminateQuestion: "Problèmes d'exclusion détectés",
      },
      interactiveAnswerTypes: {
        noAnswer: "Aucune réponse détectée",
        answer: "Réponse détectée",
        wrongAnswer: "Mauvaise réponse détectée",
      },
      inspectionUpdateTypes: {
        autoInspection: "Contrôle qualité automatique",
        autoInspectionWechat: "Analyse automatique",
        artificialSampling: "Inspection manuelle",
        artificialReview: "Révision manuelle",
        manualModification: "Inspection rapide",
        timelyInspection: "Contrôle qualité en temps réel",
        samplingAssign: "Distribution par tirage au sort",
        artificialComplain: "Soumission de plaintes",
        fastSampling: "Soumission rapide d'inspection",
        recheckInspection: "Vérification de la qualité",
        recheckInspectionWechat: "Réanalyse",
      },
      timelyInspectionTypes: {
        timelyInspection: "Contrôle qualité en temps réel",
      },
      pushActionTypes: {
        email: "Le courrier",
        interfacePush: "Interface push",
      },
      pushDetailWays: {
        riskAlert: "Rappel des risques",
        subscribe: "S'abonner aux messages",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Appels / conversations messages",
        inspectionResults: "Résultats du contrôle qualité",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Appels / conversations messages",
        textContent: "Contenu textuel des appels / Conversations",
        inspectionResults: "Résultats du contrôle qualité",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Moyenne des scores au sein du Groupe",
        addOrSubtract: "Ajouter et soustraire des points directement",
        noChange: "Sans addition ni soustraction de points",
      },
      defaultScore: {
        empty: "Pour vide",
        add: "Points supplémentaires",
        notAdd: "Pas de points supplémentaires",
        sub: "Moins de points",
        notSub: "Pas moins de points",
        na: "N/A",
        input: "Entrée manuelle",
        choose: "Sélection",
      },
      strategyTypes: {
        hitAdd: "Hit points plus",
        hitNotAdd: "Frapper sans points supplémentaires",
        hitSub: "Frapper moins de points",
        hitNotSub: "Frapper sans perdre de points",
      },
    },
    download: {
      exportStatus: {
        prepare: "En préparation",
        process: "En cours",
        faild: "L'exportation a échoué",
        success: "Succès",
      },
    },
    messageType: {
      all: "Tous",
      monitor: "Surveillance immédiate",
      trigger: "Notification de déclenchement",
      timing: "Rapports temporels",
    },
    messagePushType: {
      all: "Tous",
      system: "Messages système",
      sdk: "Message SDK",
      push: "Interface push",
      email: "Le courrier",
      udesk: "Udesk notification",
      udeskWhite: "Notification du système de service à la clientèle",
      weChat: "Micro - message",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Répartition des inspecteurs",
      sampleInspector: "Inspecteur",
      assignReviewer: "Répartition des réviseurs",
      reviewer: "Examinateur",
      taskInspector: "Inspecteur qualité des tâches",
    },
    sdkPushType: {
      webHook: "Webhook standard",
      dingDing: "Robot clou",
      weChat: "Robots Wechat d'entreprise",
      customer: "Personnaliser le contenu json",
    },
    inspectionRemarksType: {
      inspection: "Contrôle qualité",
      check: "Inspection par tirage",
      review: "Révision",
    },
    segmentationStrategyType: {
      count: "Nombre de messages",
      spacing: "Intervalle de message",
    },
    segmentationStrategy: {
      byTime: "Découpage temporel",
      customize: "Découpe combinée",
    },
    inspectType: {
      commonInspect: "Contrôle qualité conventionnel",
      associateInspect: "Contrôle de qualité associé",
    },
    typoDetectionOperators: { less: "Moins que", greater: "Supérieure à" },
    autoAddTypes: {
      realTimeAdd: "Ajout en temps réel",
      timingAdd: "Ajout temporel",
    },
    messageTypes: { text: "Message texte", markdown: "Markdown messages" },
    inServiceStatus: {
      activated: "Activé",
      disabled: "Est désactivé",
      inactivated: "Non activé",
      resigned: "Quitter une entreprise",
    },
    roles: { agent: "Employés", customer: "Le client" },
    commentVisibleAuthority: {
      all: "Visible pour tous",
      self: "Visible uniquement par soi - même",
      designation: "Visible par la personne désignée",
    },
    appealCheckingTask: {
      eCommerceReview: "Avis de commerce électronique",
      socialMediaComments: "Revue des médias sociaux",
      largeModel: "Grand modèle",
      customModel: "Modèle personnalisé",
      universalModel: "Modèle universel",
      enterpriseWeChatText: "Micro - texte",
      generalQualityInspection: "Inspection de qualité ordinaire",
      processQualityInspection: "Contrôle qualité du processus",
      voiceCopy: "Réplication vocale",
      beEqualTo: "égale à",
      notEqualTo: "Pas égale à",
      canAppeal: "Peut porter plainte",
      noAppeal: "Non susceptible de plainte",
    },
    wechat: {
      redEnvelopes: "Le paquet rouge",
      text: "Texte",
      picture: "Images",
      voice: "Voix",
      voiceCall: "Appels vocaux",
      video: "Vidéo",
      businessCard: "Carte de visite",
      position: "Localisation",
      expression: "Expressions",
      link: "Liens",
      applet: "Petit programme",
      chatRecord: "Historique de chat",
      mixedMessage: "Messages mixtes",
      file: "Documents",
    },
    gong: {
      sessionActivity: "Activités de session",
      conversationAction: "Action conversationnelle",
      riskSemantics: "Sémantique du risque",
      daysNotFollowedUp: "Jours non suivis",
      waysideData: "Données sur la route",
      notStarted: "Pas commencé",
      completed: "Terminé",
      haveInHand: "En cours",
      unpublished: "Non publié",
      published: "Publié",
      knowledgePoints: "Points de connaissance",
      technologicalProcess: "Processus",
      notMarked: "Non étiqueté",
      inAnnotation: "Dans la note",
      annotated: "Déjà étiqueté",
    },
    qualityChecingTask: {
      appealCanceled: "La plainte est annulée",
      complaintExpired: "La plainte a expiré",
      incomingCall: "Appels entrants",
      exhale: "Exhale",
      incomingCallthirdParty: "Appels entrants (à trois)",
      incomingCallconsultation: "Appels entrants (consultation)",
      incomingCallforcedInsertion: "Appels entrants (insertion forte)",
      incomingCallmonitoring: "Appels entrants (écoute)",
      incomingCalltransfer: "Appels entrants (transfert)",
      incomingCallinterception: "Appels entrants (interception)",
      incomingCalltransferToExternalLine:
        "Appels entrants (transfert vers l'extérieur)",
      incomingCallthirdPartyExternalLine:
        "Appels entrants (ligne extérieure tripartite)",
      incomingCallexternalLine:
        "Appels entrants (consulter la ligne extérieure)",
      outgoingCallthirdParty: "Expirer (à trois)",
      outgoingCalltransfer: "Exhale (transfert)",
      outgoingCallforcedInsertion: "Exhale (insertion forte)",
      outgoingCallmonitoring: "Expirer (écouter)",
      outgoingCallinterception: "Exhalation (interception)",
      outgoingCalltransferToExternalLine:
        "Expirer (transférer vers l'extérieur)",
      outgoingCallthreePartyExternalLine:
        "Expirer (ligne extérieure tripartite)",
      customerServiceAnswer: "Réponse du service client",
      customerServiceMissed: "Service à la clientèle manqué",
      customerAnswer: "Les clients répondent",
      customerMissed: "Client manqué",
      theTelephoneIsBusy: "Ligne occupée par le téléphone",
      phoneOffline: "Téléphone hors ligne",
      customerQuickHanging: "Suspension rapide du client",
      clientHangsUp: "Machine suspendue client",
      queueTimeout: "Timeout de la file d'attente",
      giveUpQueuing: "Abandonner la file d'attente",
      outsideLineAnswering: "Ligne extérieure répondre",
      externalLineIsNotConnected: "Ligne extérieure non connectée",
      noQueueSelected: "Aucune file d'attente sélectionnée",
      notEvaluated: "Non évalué",
      noEvaluationRequired: "Aucune évaluation requise",
      queuingSucceeded: "Queue réussie",
      noCustomerServiceOnline: "Aucun service client en ligne",
      customer: "Le client",
      customerService: "Service à la clientèle",
      outsideLines: "Ligne extérieure",
    },
    sessionActivity: {
      single: "Durée moyenne d'un seul appel",
      all: "Volume total des appels",
      allTime: "Durée totale des appels",
    },
    sessionAction: {
      salesProportion: "Les ventes parlent en pourcentage",
      salesLongest: "Le plus long discours de vente moyen",
      customerLongest: "Le client moyen le plus long parle",
      discussNumber: "Nombre de discussions approfondies",
      frequency: "Fréquence des questions",
    },
    monitorCriteria: { all: "Avant et après", before: "Avant", after: "Après" },
    monitorTimeCycle: { day: "Jours", week: "Semaine", month: "Mois" },
    monitorGroupTestType: {
      ab: "Comparaison des deux groupes",
      a: "Groupe a",
      b: "Groupe B",
    },
    fields: {
      department: "Département",
      customerTags: "Étiquette client",
      member: "Membres",
      time: "Le temps",
      cascade: "Cascade",
      anyMatch: "Toute correspondance",
      keyWord: "Mots clés",
      keyEvents: "Événements clés",
    },
    semanticIntelligence: {
      staff: "Employés",
      customer: "Le client",
      employeesAndCustomers: "Employés et clients",
      notStarted: "Pas commencé",
      haveInHand: "En cours",
      completed: "Terminé",
      callRecording: "Enregistrement des appels",
      enterpriseWeChatCall: "Micro - appel",
      enterpriseWeChatText: "Micro - texte",
      discourseMiningForObjectionHandling:
        "Excavation du traitement des objections",
      productSellingPointScriptsMining: "Produits excavation vocale",
      conversationScenario: "Scène de session",
      topicOfConversation: "Sujets",
      keyEvents: "Événements clés",
      customerLabel: "Étiquette client",
      includeAny: "Contient n'importe quel",
      includeAll: "Contient tout",
      and: "Et",
      or: "Ou",
      question: "Phrases de doute",
      nonInterrogativeSentence: "Phrases sans doute",
    },
    coach: {
      notPassed: "Non passé",
      analogTelephone: "Téléphone analogique",
      enterScenarioInformation: "Entrez les informations de la situation",
      generateDialogueFramework: "Générer un cadre de dialogue",
      generatePromptLanguage: "Générer des invites",
      aIGeneratedDialogue: "Ai génère des conversations",
      system: "Système",
      custom: "Personnalisation",
      flow: "Processus",
      script: "La parole",
      express: "Expression",
      emotion: "Les émotions",
      rateAccordingToCourseConfiguration: "Configurer le score par cours",
      intelligentModelRating: "Smart Model score",
      allData: "Toutes les données",
      dataFromThisDepartment: "Données de ce département",
      multidepartmentalData: "Données multisectorielles",
      underReview: "En audit",
      no: "Non",
      yes: "Oui",
      automaticRating: "Score automatique",
      auditRating: "Score d'audit",
      reRating: "Re - score",
      inAppeal: "Dans la plainte",
      reviewed: "Examiné",
      notViewed: "Pas vu",
      viewed: "Vu",
      uncorrected: "Non modifié",
      corrected: "Modifié",
      practice: "Exercices",
      examination: "examen",
      other: "Autres",
      notMeetingStandards: "Non conforme",
      meetingStandards: "Conforme aux normes",
      excellent: "Excellent",
      invalid: "Invalidation",
      notStarted: "Pas commencé",
      haveInHand: "En cours",
      ended: "C'est terminé",
      completed: "Terminé",
      hangInTheAir: "Pas terminé",
      passed: "Adopté",
      voiceConversation: "Conversations vocales",
      textDialogue: "Dialogue textuel",
      slidePresentation: "Discours sur diapositives",
      unpublished: "Non publié",
      published: "Publié",
      narration: "Narration",
      studentSpeaking: "Les élèves parlent",
      robotTalk: "Le robot parle",
      knowledgeBaseQA: "Base de connaissances questions et réponses",
      slide: "Diapositives",
      negativeEmotions: "Humeur négative",
      emotionallyNormal: "Emotions normales",
      incompleteScript: "La parole incomplète",
      completeScript: "Parole complète",
      normalSpeechSpeed: "Vitesse de parole normale",
      speakTooFast: "Vitesse de parole trop rapide",
      speakTooSlowly: "Vitesse de parole trop lente",
      whole: "Tous",
      singlePage: "Une seule page",
      qA: "Une question une réponse",
      situationalDialogue: "Dialogue situationnel",
      misses: "Pas touché",
      hit: "Frappé",
      sequentialQA: "Questions et réponses séquentielles",
      randomQA: "Questions et réponses aléatoires",
      mastered: "Maîtrisé",
      notMastered: "Non maîtrisé",
    },
    algorithm: {
      salesEmpowerment: "Facilitation des ventes",
      enterpriseWeChatVoice: "Voix WeChat d'entreprise",
      enterpriseWeChatText: "Texte Wechat d'entreprise",
      waiting: "En attente",
      extracting: "Dans le tirage",
      success: "Succès",
      fail: "L'échec",
      intelligentQualityInspection: "Contrôle de qualité intelligent",
      textDialogue: "Dialogue textuel",
      voiceCall: "Appels vocaux",
      smartTags: "Étiquettes intelligentes",
      satisfactionEvaluation: "Évaluation de la satisfaction",
      score: "Score",
      grade: "Évaluation",
      customerServiceTeam: "Groupe de service à la clientèle",
      conversationAnalysis: "Analyse de session",
    },
    operatorListGather: {
      businessSummary: "Résumé des opérations",
      firstSentence: "Première phrase",
      lastSentence: "Dernière phrase",
      allTime: "Tout le temps",
      nonBusinessWorkingHours: "Heures de travail non commerciales",
      businessWorkingHours: "Heures de travail des entreprises",
    },
    callDialogueManage: {
      eCommerceReview: "Avis de commerce électronique",
      socialMediaComments: "Revue des médias sociaux",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Se connecter",
        email: "Boîte aux lettres",
        password: "Mot de passe",
        forgetPwd: "Mot de passe oublié",
        emailCannotBeEmpty: "La boîte aux lettres ne doit pas être vide.",
        passwordCannotByEmpty: "Le mot de passe ne doit pas être vide.",
        mustChangeLogin:
          "Désolé, votre mot de passe doit être réinitialisé, veuillez contacter votre administrateur pour réinitialiser votre mot de passe.",
        dismissionLogin: "Désolé, vous avez quitté votre emploi.",
        forbiddenLogin: "Désolé, votre compte est désactivé.",
        loginError:
          "La connexion a échoué, la boîte aux lettres ou le mot de passe était incorrect.",
        loginPrompt: "Se connecter au système",
        rememberMe: "Se souvenir de moi",
      },
      logout: {
        logoutError:
          "La déconnexion a échoué, vérifiez votre connexion réseau ou réessayez plus tard.",
      },
      resetPassword: {
        forgetPwd: "Mot de passe oublié",
        inputEmailPrompt:
          "Entrez votre adresse e - mail de compte ci - dessous et nous vous enverrons un e - mail et des instructions pour réinitialiser votre mot de passe.",
        sendEmailSuccess:
          "La lettre de confirmation de modification du mot de passe a été envoyée avec succès dans la boîte aux lettres que vous avez remplie, veuillez la récupérer à temps.",
        sendEmailFailed:
          "L'envoi de la boîte aux lettres a échoué, vérifiez la connexion réseau ou réessayez plus tard.",
        setNewPassword: "Configurez votre nouveau mot de passe",
        passwordCannotEmpty: "Le mot de passe ne doit pas être vide",
        passwordFormWrong: "Le format du mot de passe est incorrect",
        confirmPasswordWrong:
          "Les mots de passe saisis deux fois ne sont pas égaux.",
        passwordResetSuccess: "Réinitialisation du mot de passe réussie.",
        passwordResetFailed:
          "La Réinitialisation du mot de passe a échoué, vérifiez votre connexion réseau ou réessayez plus tard.",
        linkTimeover:
          "Réinitialiser le lien de mot de passe expire, Veuillez réessayer",
      },
      form: { title: "Case formulaire présentation" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Boîte aux lettres",
          emailFormatError:
            "La boîte aux lettres est au mauvais format, veuillez la saisir à nouveau!",
          forgotPassword: "Mot de passe oublié?",
          describe:
            "Entrez votre adresse e - mail de compte ci - dessous et nous vous enverrons un e - mail et des instructions pour réinitialiser votre mot de passe.",
          send: "Envoyer",
          emailCannotBeEmpty: "La boîte aux lettres ne peut pas être vide",
          resetPasswordExpire:
            "Réinitialiser le lien de mot de passe expire, Veuillez réessayer",
        },
        resetPassword: {
          setPassword: "Configurez votre nouveau mot de passe",
          newPassword: "Nouveau mot de passe",
          repeatPassword: "Répéter le mot de passe",
          resetPassword: "Réinitialiser le mot de passe",
          passwordCannotBeEmpty:
            "Le nouveau mot de passe ne peut pas être vide.",
          confirmPasswordCannotBeEmpty:
            "Le mot de passe répété ne peut pas être vide.",
          passwordsNotMatch: "Deux fois le mot de passe est incohérent!",
          passwordFormWrong:
            "Les mots de passe ne peuvent pas être en chinois et les chiffres sont de 6 à 14 Chiffres!",
          linkExpiration:
            "Réinitialiser le lien de mot de passe expire, Veuillez réessayer",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Vous n'avez pas la permission de voir cette page",
      },
    },
    downloadCenter: {
      name: "Centre de téléchargement",
      saveTime: "(30 jours par défaut)",
      headerTitle: "Télécharger le fichier",
      download: "Télécharger",
      downloadPromp: "Télécharger la tâche a été soumise, allez à",
      viewProgress: "Voir la progression",
    },
    uploadCenter: { name: "Centre de téléchargement" },
    tasks: {
      index: {
        name: "Le nom",
        remark: "Description",
        qualityCheckTasksTitle: "Table de travail",
        informationCollection: "Tâches d'acquisition",
        qualityCheckTasks: "Tâches d'inspection de la qualité",
        wechatQaTasks: "Tâches analytiques",
        moduleName: "Utiliser un modèle nouveau",
        creatNewQualityCheckTask: "Nouvelle tâche",
        generalInspectionList: "Rapport général d'inspection de la qualité",
        editQualityCheckTask: "Modifier les tâches de contrôle de qualité",
        creatNewQualityCheckTaskError:
          "La nouvelle tâche de contrôle qualité échoue",
        editQualityCheckTaskError:
          "Échec de la tâche de contrôle de qualité d'édition",
        type: "Type de formulaire",
        singleScore: "Score individuel",
        singleScoreNullError: "Le score individuel ne peut pas être vide",
        inspector: "Inspecteur qualité",
        inspectDataSource: "Sources de données",
        taskName: "Nom de la tâche",
        inspectionType: "Type d'inspection de qualité",
      },
      manage: {
        screeningCondition: "Filtrer les conditions",
        template: {
          condition: {
            index: {
              name: "Conditions",
              existingConditions: "Les conditions sont déjà",
            },
            detail: {
              id: "ID",
              name: "Nom de la condition",
              remark: "Description",
              checkRange: "Gamme de détection",
              operator: "Opérateur",
              operatorLogic: "Logique des opérateurs",
            },
          },
          checkPointTest: {
            title: "Test des règles",
            running: "Règles test, plus tard...",
            clearSuccess: "Simulation de conversation vide avec succès",
            mock: "Dialogue simulé",
            save: "Sauvegarder la conversation sélectionnée",
            saveAll: "Sauvegarder toutes les conversations",
            saveSuccess: "Conversation sauver le succès",
            close: "Quitter",
            delete: "Supprimer la sélection",
            clear: "Vider",
            customer: "Le client",
            agent: "Service à la clientèle",
            test: "Test",
            request:
              "La conversation simulée sera irrécupérable après avoir été vidée, confirmez - vous la vidange?",
            deletePopconfirmContent:
              "Confirmez - vous la suppression de la conversation cochée?",
          },
          checkPoint: {
            index: {
              name: "Configuration des règles",
              wechatTitle: "Configuration du modèle analytique",
              existingCheckPoints: "Règles de contrôle de qualité existantes",
              currentCheckPointScore:
                "La valeur actuelle des règles de contrôle de la qualité est",
              currentCheckPointScoreWechat:
                "La valeur du score du modèle analytique actuel est",
              currentCheckPointWeight:
                "La somme des poids actuels des règles de contrôle de la qualité est",
              weightTotalScoreAndItemScore:
                "Score total: 100 points individuels:",
              template: "Modèle de règles d'inspection de la qualité",
              import: "Importer un modèle de règle de contrôle qualité",
              getTemplateError:
                "Échec de l'acquisition du modèle de règle d'inspection de qualité",
              tabs: { template: "Modèle", demo: "Exemple d'exemple" },
              customize: "Modèle vide",
              move: "Faites glisser pour ajuster la classification et le tri",
              classification: "Classification des contrôles de qualité",
              classificationWeChat: "Classification des modèles",
              first: "Classification de premier niveau",
              second: "Classification secondaire",
              new: "Nouvelle règle de contrôle qualité",
              weChatNew: "Ajouter un modèle analytique",
              createWeChat: "Nouveau modèle analytique",
              empty: "Il n'y a pas encore de règles de contrôle de qualité,",
              wechatEmpty: "Pas encore de modèle analytique,",
              newSecond: "Nouvelle classification secondaire",
              newFirst: "Nouvelle classification de niveau 1",
              checkPoint: "Règles de contrôle de qualité",
              checkPointWeChat: "Modèle analytique",
            },
            detail: {
              name: "Le nom",
              remark: "Description",
              type: "Type",
              deduction: "Déduction de points",
              rule: "Règles",
              highlighted: "Highlight",
              gradeType: "Méthode de notation",
              interactiveCategory: "Classification des interactions",
              predeterminedScore: "Valeur du point",
              weight: "Poids",
              na: "N/A",
              default: "Score par défaut",
              preRule: "Règles de pré - condition",
              analysisHighlighted: "Règles de résolution",
              categorySelectPrompt: "Veuillez sélectionner une classification",
              checkHighlightArrayError:
                "La règle ne correspond pas à la surbrillance, appuyez à nouveau sur le bouton résoudre la règle",
              foctorsHolder: "Veuillez sélectionner les éléments",
              foctorsErrorNone:
                "La règle multifactorielle est vide, veuillez ajouter un nouveau type d'élément",
              naError:
                "Impossible de fermer l'élément lorsque le score par défaut est n / A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A est Not Applicable, qui définit les conditions préalables à la règle d\'inspection de la qualité, qui sont testées lorsque les conditions sont remplies, et si les conditions ne sont pas remplies, la règle ne s\'applique pas, la règle ne sera pas testée;\nInstructions de pré-remplissage: "&&" signifie "et", "||" signifie "ou", "!" Indique "non", remplissez l\'exemple "(R1 || R2) && R3"',
                applicationMessageType: "Type de message d'application",
                selectAll: "Tout sélectionner",
                highlightTags: "Étiquettes en surbrillance:",
                ruleType: "Types de règles",
                dataSource: "Sources de données",
                whole: "Tous",
                enterpriseMicroConversation: "Micro - session d'entreprise",
                enterpriseAndMicroCommunication: "Micro - appel",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Juger les nœuds",
                    dialogueNode: "Nœuds de dialogue",
                    editNodes: "Modifier le nœud",
                  },
                  nodeConfig: {
                    nodeName: "Nom du nœud",
                    nodeRules: "Règles des nœuds",
                    scoringLogic: "Logique de score",
                    score: "Valeur du point:",
                    rule: "Règles",
                    multiBranchLogic: "Logique Multi - branches",
                    highlight: "Highlight",
                    submit: "Soumettre",
                  },
                  ruleList: {
                    branch: "Branches",
                    branchName: "Nom de la branche:",
                    addRules: "Ajouter des règles",
                  },
                  sidebar: { node: "Noeuds" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Organiser automatiquement",
                      canvasAdaptation: "Adaptation de la toile",
                      copy: "Copier",
                      delete: "Supprimer",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Juger que les sous - noeuds d'un noeud ne peuvent pas servir de cibles de connexion",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Le nœud de démarrage ne peut pas être une cible de connexion",
                      startingNodeCannotConnectMultipleNodes:
                        "Le nœud de départ ne peut pas connecter plusieurs nœuds",
                      nodeCannotBeConnectedToItself:
                        "Les nœuds ne peuvent pas se connecter à eux - mêmes",
                      startNode: "Démarrer le nœud",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Annuler la sélection complète",
                    selectAll: "Sélection complète",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Si elle est conforme",
                    gradedEvaluation: "Évaluation des fichiers",
                  },
                },
              },
            },
            configuration: {
              title: "Règles de contrôle de qualité",
              wechatTitle: "Modèle analytique",
              neme: "Le nom",
              errorMessage:
                "La suppression a échoué, avec au moins une condition",
              desc: "Description",
              status: "Statut",
              yes: "Efficace",
              no: "Invalide",
              namePlaceHolder: "Veuillez entrer le nom de l'étiquette",
              desPlaceHolder: "Veuillez entrer une description",
              nameEmpty: "Le nom ne peut pas être vide",
              desEmpty: "La description ne peut pas être vide",
              type: "Type",
              ruler: "Règles intelligentes",
              machineLearning: "Mode d'auto - apprentissage",
              autoScore: "Smart score (ancien)",
              manualScore: "Score manuel",
              interaction: "Détection interactive (ancienne)",
              typeTips: {
                model:
                  "Modèle intelligent: est un ensemble prédéfini de système d'inspection de la qualité, sans configuration ni maintenance, capable de détecter rapidement les problèmes présents dans le service. Le modèle est plus générique, si vous ne répondez pas aux exigences de l'entreprise, utilisez des « règles intelligentes» ou des évaluations manuelles.",
                rule: "Règles intelligentes: C'est - à - dire score automatique basé sur la règle ai",
                manual:
                  "Évaluation manuelle: C'est - à - dire inspection de qualité manuelle, évaluée manuellement par un humain",
              },
              model: "Modèle:",
              ruleType: {
                words: "La parole",
                interaction: "Interaction",
                feature: "Caractéristiques",
                information: "Informations",
              },
              testingMode: {
                all: "Détecter tous les mots clés",
                any: "Détection de mots clés arbitraires",
              },
              operatorTypesOfInteraction: {
                question: "Une question une réponse",
                element: "Réponse aux éléments",
                repet: "Répétition du contexte",
                snatch: "Saisir des mots",
                silent: "Silence",
              },
              role: "Détecter le rôle:",
              preposition: "Opérateur frontal:",
              range: "Gamme de détection:",
              from: "Le numéro",
              to: "À la fin",
              end: "Phrases",
              hasOldSilentTip:
                'Bonjour, pour améliorer la précision, "règles intelligentes - interaction - Silence" a été mis à niveau vers "délai de réponse", il est recommandé de mettre à jour les règles d\'utilisation!',
              changeToOld: "Passer à l'ancienne version",
              changeToNew: "Passer à la nouvelle version",
              logic: {
                logic: "Logique de score",
                naTip:
                  "N / a c'est - à - dire not applicable, définit une condition préalable pour cette règle de contrôle de la qualité, détecte lorsqu'elle est éligible, ne détecte pas lorsqu'elle n'est pas éligible, c'est - à - dire que la règle ne s'applique pas",
                score: "Valeur du point:",
                na: "N/A：",
                naDes:
                  "Permet que cet élément ne soit pas impliqué dans la détection",
                preRule: "Règles de pré - condition:",
                notCatchPreRule:
                  "Lorsque la condition préalable n'est pas remplie:",
                naChecked: "Lorsque N / A est touché:",
                preRuleEmpty:
                  "La règle de condition préalable ne peut pas être vide",
                operatorEmpty:
                  "La condition nécessaire dans l'opérateur ne peut pas être vide",
                multipleEmpty:
                  "La condition nécessaire dans le score logique multiple ne peut pas être vide",
                average: "Moyenne des scores au sein du Groupe",
                addOrSubtract: "Ajouter et soustraire des points directement",
                noChange: "Sans addition ni soustraction de points",
                rule: "Règles:",
                all: "Satisfaire tous les opérateurs",
                any: "Satisfaire tout opérateur",
                customize: "Logique d'opérateur personnalisée",
                multiple: "Score logique multiple",
                heightLight: "Highlight:",
                machineLearningTip:
                  "Le mode d'auto - apprentissage n'est actuellement actif que pour les mots simples, utilisez des règles intelligentes pour les mots complexes",
                strategy: "Stratégie:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Approbation soumission réussie",
            },
            components: {
              pageHeaderExtra: {
                test: "Test",
                formal: "Officiellement",
                submit: "Soumettre",
              },
              createModal: { index: { myRules: "Mes règles" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Automatisation des processus",
              screeningCondition: "Filtrer les conditions",
              aiScreeningCondition: "Critères de filtrage intelligents",
              rule: "Règles",
              automaticAddition: "Ajouter automatiquement",
              automaticAdditionTip:
                "Ajout automatique en temps réel ou programmé de données à une tâche pour le contrôle de qualité ai",
              autoAdd: {
                autoAddType: "Méthode d'ajout automatique",
                addFrequency: "Fréquence d'ajout",
                add: "Ajouter",
              },
              automaticSamplingInspection: "Inspection automatique",
              automaticSamplingInspectionTip:
                "Extraction automatique des données nécessitant un contrôle de qualité manuel, par exemple, extraction quotidienne aléatoire de 10% des données d'hier pour un contrôle de qualité manuel",
              everyAgentCheckLimit: "Plafond de tirage par service client",
              automaticAllocation: "Distribution automatique",
              automaticAllocationTip:
                "Affectation au tirage au sort: les données extraites sont automatiquement attribuées à l'inspecteur de la qualité, par exemple, 10% des données tirées au hasard hier sont tirées quotidiennement pour un tirage au sort manuel, et 2 inspecteurs de la qualité sont affectés au hasard en moyenne à cette tâche.",
              automaticAllocationTip2:
                "Attribution de la révision: les données relatives aux plaintes sont automatiquement affectées à la révision par l'inspecteur de la qualité, par exemple, les données relatives aux plaintes quotidiennes sont affectées de manière aléatoire, en moyenne, à 2 inspecteurs de la qualité pour cette tâche.",
              automaticPush: "Push automatique",
              automaticPushTip:
                "Notifications déclenchées: notification / alerte lors d'un contrôle de qualité, par exemple, envoi d'un message de notification au sein du Groupe Enterprise micro en cas d'erreur fatale du service client",
              automaticPushTip2:
                "Rapports chronométrés: Envoyez des rapports chronométrés sur les résultats de l'inspection de qualité par courrier, par exemple, tous les jours à 9H00 Envoyez les résultats de l'inspection de qualité de l'inspection manuelle d'hier à la boîte aux lettres du responsable du service clientèle",
              automaticAssess: "Évaluation automatique",
              automaticAssessTip:
                "Les résultats de l'inspection manuelle sont évalués avec précision par rapport aux résultats de l'inspection de qualité ai. Par exemple, chaque jour à 8h00, les données du tirage d'hier sont évaluées avec précision",
              reexaminationComplaint: "Réexamen des plaintes",
              spoktReviewDetail: "Configuration d'entreprise",
              spoktReviewDetailTip:
                "Configuration fonctionnelle de la liste d'inspection de la qualité, de la liste d'inspection par tirage au sort, de l'opération de règlement des griefs, de la liste de révision, des notes, etc.",
              qaDetailAction: "Configuration du contrôle qualité",
              spoktDetailAction: "Configuration de l'inspection éclair",
              reviewDetailAction: "Révision de la configuration",
              appealAction: "Configuration des plaintes",
              remarkTemplate: "Modèle de notes",
              automaticArchiving: "Archivage automatique",
              conditionalRules: "Règles des conditions",
              qualityInspectionConditions: "Conditions d'inspection de qualité",
              unopened: "Pas encore ouvert",
              sampleInspectionDistributionModel:
                "Distribution par tirage au sort",
              reviewAllocationModel: "Révision de la distribution",
              distributionModelTitle: "Stratégie de distribution:",
              pushRules: "Règles push",
              push: "Push",
              assessRules: "Règles d'évaluation",
              on: "on",
              off: "off",
              updateStatusSuccess: "Changement de statut réussi!",
              updateStatusFailed:
                "Le changement de statut a échoué, Veuillez réessayer plus tard!",
              sampleNumberOrPercent:
                "Nombre ou proportion de barres d'échantillonnage",
              inspectorList: "Désigner un inspecteur qualité",
              numberOfSubmission: "Nombre de soumissions autorisé",
              rewivewDays: "Jours",
              TimeOfSubmission:
                "Période de validité de la soumission des observations",
              rewivewSecond: "Une fois",
              switchOn: "Ouvrir",
              switchOff: "Fermé",
              notMatchAnyRule:
                "Lorsque vous ne correspondez pas à une règle arbitraire:",
              appealOptionsList: {
                hiddenInspector:
                  "Plainte cacher l'information de l'Inspecteur qualité",
                hiddenHit:
                  "Page de réclamation support highlight Positioning hit",
              },
              caseLibrary: "Synchronisation de la Bibliothèque de cas",
              caseLibrarySyncTip:
                "Synchroniser les cas ajoutés par le système QA au système de service à la clientèle",
              addCase: "Augmenter la synchronisation",
              caseLibraryId: "Bibliothèque de cas",
              caseLibraryIdTip:
                "Regroupement de bibliothèques de cas pour le système QA",
              udeskCaseLibraryIdTip:
                "Regroupement de cas pour le système de service à la clientèle",
              syncHis: "Cas historiques synchronisés",
              otherSettings: "Autres paramètres",
              udeskCaseLibraryId: "Push Groupe de cas",
              url: "Push URL",
              urlTip:
                "Veuillez entrer l'adresse de l'interface commençant par http: / / ou https: / /",
            },
            templateNew: {
              secondaryReview: "Réexamen secondaire",
              numberOfAppealsAllowedToBeSubmitted:
                "Nombre de recours autorisés",
              validityOfSubmissionOfAppeal:
                "La période de validité de la soumission des plaintes",
              validityOfAppealReview:
                "Période de validité de l'examen des appels",
              hours: "Heures",
              businessSummary: "Résumé des opérations",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Limite inférieure de tirage par service client",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Générer automatiquement un résumé de l'entreprise par le grand modèle, dimensions de génération personnalisables en fonction de la situation de l'entreprise",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Stratégie d'allocation des réviseurs de niveau 1 uniquement",
              secondaryCompounding: "Composé secondaire:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Les réviseurs de niveau 2 ne soutiennent que la distribution égale",
              secondLevelReviewer: "Réviseur secondaire:",
              samplingInspectionTarget: "Objectif de l'inspection éclair",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Définissez l'objectif que l'inspection manuelle doit atteindre, l'objectif n'est pas atteint ou dépassé lorsque vous donnez des conseils, par exemple, chaque mois de service client doit compléter l'inspection aléatoire 100, en dessous de 80, l'inspection de la qualité, la page d'inspection aléatoire invite.",
            },
            component: {
              verificationFailed: "échec du contrôle",
              pleaseAddASecondLevelQualityInspector:
                "Veuillez ajouter un inspecteur qualité de niveau 2",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "L'invite",
                    fieldName: "Nom du champ",
                    fieldDescription: "Description du champ",
                    generateContent: "Générer du contenu",
                    enabled: "Activer ou non",
                    generationMethod: "Méthode de génération",
                    automaticGeneration: "Génération automatique",
                    manualTrigger: "Déclenchement manuel",
                    filterCondition: "Conditions de filtrage",
                    return: "Retour",
                    preserve: "Enregistrer",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Générer des résultats" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Pas limité",
                    limitedScope: "Limiter la portée",
                    add: "Ajouter",
                    thereIsCurrentlyNoDataAvailable:
                      "Pas de données pour le moment",
                  },
                },
              },
              index: {
                fieldName: "Nom du champ",
                fieldDescription: "Description du champ",
                nothing: "Aucun",
                generationMethod: "Méthode de génération",
                generateManually: "Génération manuelle",
                automaticGeneration: "Génération automatique",
                generateContent: "Générer du contenu",
                notQualified: "Pas limité",
                filterCondition: "Conditions de filtrage",
                enabled: "Activer ou non",
                type: "Type",
                system: "Système",
                custom: "Personnalisation",
                operation: "Opération",
                edit: "Éditeur",
                areYouSureToDeleteIt: "Est-il sûr de supprimer?",
                delete: "Supprimer",
                addField: "Augmentation des champs",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Configuration des rôles",
              roleConfigTip:
                "Utilisé pour configurer les règles d'identification pour le traitement de séparation des locuteurs lorsque les données d'appel importées sont mono. Rôle de service à la clientèle conforme aux règles",
              operator: "Opérateur",
              operatorLogic: "Logique des opérateurs",
            },
          },
          manualAdd: {
            index: {
              name: "Ajouter manuellement",
              tip: "Pour filtrer les données de prévisualisation par configuration manuelle des critères et les ajouter rapidement aux tâches de contrôle de qualité où elles se trouvent",
              screeningCondition: "Ajouter selon les critères de filtre",
              aiScreeningCondition: "Critères de filtrage intelligents",
              rule: "Règles",
              createTime: "Ajouter le temps",
              filterTitle: "Filtrer le contenu",
              filterRule: "Filtrer les conditions",
              startTime: "Heure de début",
              endTime: "Heure de fin",
              records: "Importer des enregistrements",
              createUserName: "Personnel opérationnel",
              successCount: "Ajouté avec succès",
              conditionRule: "Règles de condition:",
              preview: "Preview",
              previewData:
                "Répond actuellement aux critères de filtrage {0} barre, a rejoint la tâche {1} barre, peut rejoindre la tâche {2} barre, ne peut pas rejoindre la tâche {3} barre",
              failCount: "L'ajout a échoué",
            },
          },
          basicInfo: {
            index: {
              title: "Informations de base",
              name: "Le nom",
              taskName: "Nom de la tâche",
              taskNamePlaceholder: "Veuillez entrer le nom de la tâche",
              requiredName: "Le nom de la tâche ne peut pas être vide",
              taskRemarkPlaceholder:
                "Veuillez entrer une description de la tâche",
              requiredRemark:
                "La description de la tâche ne peut pas être vide",
              remark: "Description",
              taskRemark: "Description de la tâche",
              source: "Sources de données",
              benchmarkScore: "Score de base",
              benchmarkScorePlaceholder: "Veuillez entrer un score de base",
              scoreLowerLimit: "Score minimum inférieur",
              scoreUpperLimit: "Maximum de score",
              inspectType: "Type d'inspection de qualité",
              nlpModel: "Modèle NLP",
              commonInspect: "Contrôle qualité conventionnel",
              associateInspect: "Contrôle de qualité associé",
              associateCycle: "Période d'association",
              associateRegulation: "Règles d'association",
              judgeStrategy: "Logique d'exécution",
              rule: "Règles",
              commonTip:
                "Contrôle de qualité conventionnel: applicable à un seul passage, dialogue, contrôle de qualité du contenu d'une seule communication",
              associateTip:
                "Contrôle de qualité associé: s'applique à plusieurs passages, conversations, associer le contenu de plusieurs communications pour effectuer le contrôle de qualité",
              cycleTip:
                "Si plusieurs passes, conversations associées, se conformer aux données dans le cycle d'association pour entrer dans cette tâche contrôle de qualité",
              scoreLowerLimitPlaceholder:
                "Veuillez entrer la limite inférieure de score la plus basse",
              scoreUpperLimitPlaceholder:
                "Veuillez entrer le score maximum maximum",
              call: "Appels vocaux",
              dialogue: "Dialogue textuel",
              user: "Inspecteur qualité",
              wechatUser: "Opérateur",
              noDesignated: "Non spécifié",
              type: "Type de formulaire",
              singleScore: "Score individuel",
              conduct:
                "Veuillez d'abord affiner les informations ci - dessous, puis cliquez sur suivant lorsque vous avez terminé",
              conduct2:
                "Veuillez compléter la configuration des règles de contrôle de qualité et des points de contrôle de qualité, si vous avez terminé la configuration, vous pouvez cliquer sur suivant ou quitter le démarrage",
              conduct3:
                "L'évaluation de l'inspection de qualité est une configuration qui évalue les points d'inspection de qualité en fonction de leur situation d'évaluation, vous pouvez définir différents niveaux de résultats d'inspection de qualité, si vous n'avez pas besoin de l'évaluation, vous pouvez sauter cette étape directement, si vous avez terminé la configuration, vous pouvez cliquer sur suivant ou quitter le Guide",
              conduct4:
                "Vous pouvez configurer les propriétés des données qui seront automatiquement introduites dans la tâche en suivant les propriétés des données que cette tâche de contrôle de la qualité doit vérifier à l'emplacement d'ajout automatique, en cliquant sur ignorer si vous n'avez pas besoin d'ajouter automatiquement des données temporairement, ou sur suivant si vous avez terminé la configuration.",
              conduct5:
                "Si vous utilisez un enregistrement Mono, assurez - vous de le configurer et appuyez sur suivant, utilisez le système d'aide vocale que seul le service client utilisera pour identifier les différentes pistes du service client par rapport au client. Si vous utilisez un enregistrement double ou avez terminé la configuration, vous pouvez appuyer sur skip",
              conduct6:
                "Vous pouvez effectuer un contrôle de qualité en ajoutant manuellement une fonctionnalité, en introduisant des données de dialogue traversant déjà présentes dans votre système ou en soumettant une tâche d'ajout manuel, en cliquant directement sur terminer.",
              helper: "Voir les documents d'aide",
              quit: "Quitter le guidage",
              next: "Prochaines étapes",
              complete: "Terminé",
              skip: "Sauter",
              cancel: "Annulation",
              delete: "Supprimer",
              save: "Sauvegarder",
            },
            template: {
              samplingInspector: "Inspection aléatoire",
              sessionType: "Type de session",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Banc d'inspection de qualité",
              search: "Filtrer",
              export: "Exporter",
              condition: "Conditions",
              logic: "Logique",
              reQualityInspection: "Vérification de la qualité",
              reQualityInspectionUnderWay: "La Mission de requalification...",
              reQualityInspectionUnderWayTip:
                "Quitter la page après avoir fermé la fenêtre n'affecte pas le déroulement de la tâche",
              reQualityInspectionDone:
                "Le contrôle de requalification est terminé",
              reQualityInspectionDoneTip:
                "Maintenant terminé avec succès l'inspection de requalification, veuillez cliquer sur le bouton de confirmation",
              endReQualityInspection: "Fin de la Mission",
              alreadyDoneTip: "Terminé",
              totalTip: "Total",
              unit: "Article",
              endReInspectionSuccess:
                "La Mission de contrôle de la requalification est terminée",
              closeWindow: "Fermer la fenêtre",
              endReInspectionFailed:
                "La tâche de contrôle de qualité a échoué, Veuillez réessayer plus tard!",
            },
            detail: {
              name: "Détails de l'inspection de qualité",
              reviewName: "Détails de la révision",
              spotCheckName: "Détails de l'inspection",
              audioText: "Texte enregistré",
              callInfo: "Informations sur les appels",
              satisfactionEvaluation: "Évaluation de la satisfaction:",
              queueTime: "Les files d'attente prennent du temps:",
              ringingTime: "Temps de sonnerie:",
              callTime: "Événements d'appel:",
              hangUpIdentity: "Partie appelant:",
              location: "Lieu d'attribution:",
              source: "Source:",
              agent: "Service à la clientèle:",
              deviceType: "Type d'équipement:",
              callType: "Type d'appel:",
              phoneNumber: "Numéro de relais:",
              task: "Mission:",
              commentsTip:
                "Veuillez entrer un commentaire de contrôle de qualité",
              lastPage: "Page précédente",
              nextPage: "Page suivante",
              score: "Score d'inspection de qualité",
              review: "Révision",
              file: "Archive",
              changeList: "Enregistrement des changements",
              fullMarks: "Score complet",
              most: "Le plus",
              saveReview: "Révision de la soumission",
              saveSpotCheck: "Soumission d'inspection par tirage au sort",
              point: "Point de contrôle de qualité",
              sampleAssignInspector: "Répartition des inspecteurs",
              reviewAssignInspector: "Répartition des réviseurs",
              sampleStatus: "État de l'inspection éclair",
              read: "Lu",
              user: "Utilisateurs",
              confirmer: "La personne confirmée",
              voiceDownloadError:
                "Le téléchargement de la voix a échoué, Veuillez réessayer plus tard!",
              smartTags: "Étiquettes intelligentes",
              interactiveTip:
                "Utilisation de la reconnaissance interactive pour détecter l'utilisation du service client FAQ",
              interactiveIsEmptyTip:
                "Aucun contenu de reconnaissance interactive détecté",
              businessRecords: "Dossiers d'affaires",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Analyse du chat Wechat d'entreprise",
            },
          },
        },
      },
      cards: {
        health: "Santé de contrôle de qualité",
        accuracy: "Découvrez le taux d'approbation",
        sessionsTotal: "Nombre total de sessions",
        enable: "Activer",
        disable: "Désactivé",
        none: "Pas encore",
        call: "Appel",
        dialogue: "Dialogue",
        taobao: "Le commerce électronique",
        wecom: "Session",
        ticket: "Ordre de travail",
      },
      component: {
        backup: "(Sauvegarde)",
        taskSaveAsSucceeded: "Enregistrer la tâche comme succès",
        copyContent: "Copier le contenu",
        essentialInformation: "Informations de base",
        ruleConfiguration: "Configuration des règles",
        basicInformationQualityInspector:
          "Informations de base - inspecteur qualité",
        qualityInspectionRating: "Évaluation de l'inspection de qualité",
        roleConfiguration: "Configuration des rôles",
        autoAddConfiguration: "Ajout automatique de configurations",
        autoPushConfiguration: "Configuration Push automatique",
        automaticSamplingConfiguration:
          "Configuration d'inspection automatique",
        automaticAllocationConfiguration:
          "Configuration d'allocation automatique",
        businessConfiguration: "Configuration d'entreprise",
        caseBaseConfiguration: "Bibliothèque de cas",
      },
      template: {
        cancel: "Annulation",
        complete: "Terminé",
        sort: "Tri",
        ruleApproval: "Approbation des règles",
        enterpriseMicroConversationAnalysisTask:
          "Tâches d'analyse de micro - session",
        enterpriseAndMicroVoiceAnalysisTask:
          "Tâches d'analyse vocale micro - entreprise",
        saveTaskAs: "Enregistrer la tâche sous",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Détails des règles" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Modification réussie",
            automaticApproval: "Approbation automatique",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Modification réussie",
            test: "Test",
            formal: "Officiellement",
            adopt: "Par",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Est - il certain de rejeter",
              pleaseEnterTheRejectionReason:
                "Veuillez entrer la raison du rejet",
              reject: "Rejeté",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Modification réussie",
              taskName: "Nom de la tâche",
              dataSource: "Sources de données",
              qualityInspectionType: "Type d'inspection de qualité",
              state: "Statut",
              updateTime: "Temps de mise à jour",
              updatedBy: "Mise à jour par",
              approver: "Approbateur",
              reasonForRejection: "Motifs du rejet",
              operation: "Opérations",
              details: "Détails",
              adopt: "Par",
              whole: "Tous",
              approvalStatus: "Statut d'approbation",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Aperçu",
      hi: "Salut,",
      standardEdition: "Édition standard",
      call: "Balance de contrôle de qualité vocale",
      text: "Validité du contrôle qualité du texte",
      collectionTasks: "Mission de contrôle qualité des collections",
      noCollection: "Aucune tâche de contrôle de la qualité des collections,",
      goToCollecte: "Aller à la collection",
      yesterdayOverView: "Statistiques d'affaires d'hier",
      yesterdayAgent: "Hier classement du service client",
      yesterdayInspWork: "Travail d'inspecteur qualité hier",
      operationalStatistics: {
        title: "Statistiques opérationnelles",
        qualityTesting: "Contrôle qualité",
        spotCheck: "Inspection par tirage",
        appeal: "Plaintes",
        chartTitles: {
          qualityTestingTrend: "Tendances en matière de contrôle de qualité",
          qualityTestingAnalysis:
            "Situation des tâches d'inspection de la qualité",
          spotCheckTrend: "Tendances de l'inspection par tirage au sort",
          spotCheckAnalysis: "Situation des tâches d'inspection",
          appealTrend: "Tendances des plaintes",
          appealAnalysis: "Mandat de réclamation",
        },
        titles: {
          qualityDataTend: "Distribution des données de contrôle qualité",
          qualityCover: "Couverture du contrôle qualité",
          qualityAverageTime:
            "Temps d'attente moyen pour le contrôle de qualité",
          qualityCoverTend:
            "Tendances en matière de couverture de contrôle de qualité",
          handCover: "Situations de traitement manuel",
          checkCover: "Couverture d'inspection éclair",
          resAverageTime: "Durée moyenne de traitement",
          handTend: "Tendances du traitement manuel",
          dataTend: "Distribution des tâches de données",
        },
        qualityAverage: "Moyenne des tests de qualité",
        scoreTrend: "Tendances du score de contrôle de qualité",
        qualityAverageTime: "Temps d'attente moyen pour le contrôle de qualité",
        callCoverage: "Couverture du contrôle qualité vocal",
        textCoverage: "Couverture du contrôle qualité du texte",
        checkCount: "Volume des données d'inspection",
        siphonCount: "Quantité de données extraites",
        reviewCount: "Révision du volume des données",
        appealCount: "Volume des données relatives aux plaintes",
        checkRate: "Proportion d'exécution de l'inspection par tirage",
        reviewRate: "Proportion de recours examinés",
        PieTitle: {
          totalCount: "Nombre total de conversations",
          totalCall: "Nombre d'appels vocaux",
          totalIm: "Nombre de conversations textuelles",
          inspectionCall: "Volume de contrôle de qualité vocale",
          inspectionIm: "Contrôle de qualité du texte",
          checkCall: "Volume d'inspection vocale",
          unCheckCall: "Volume non extrait de la voix",
          checkIm: "Tirage de texte",
          unCheckIm: "Texte non extrait",
          reviewCall: "Volume de révision vocale",
          reviewIm: "Volume de révision du texte",
          unReviewCall: "Volume de voix non censurée",
          unReviewIm: "Quantité de texte non révisé",
          unInspectionCall: "Volume non vérifié de la voix",
          unInspectionIm: "Texte non vérifié",
        },
        check_rate: "Couverture d'inspection éclair",
        voice_check_rate: "Couverture du Speech Sweep",
        text_check_rate: "Couverture de contrôle de texte",
        sample_execute_time: "Durée moyenne du traitement par prélèvement",
        review_execute_time: "Durée moyenne de traitement des plaintes",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Règles de contrôle qualité Kanban",
          agent: "Classement du service à la clientèle",
        },
        tableTips: {
          conditions:
            "< div > trie le nombre total de conversations Pass - through pour violation des règles de contrôle de la qualité, conformité. < Br / >",
          agent: "< div > trier la note moyenne des sièges < br / >",
        },
        tips: {
          total:
            "Nombre total de pass / dialogues entrés dans la tâche de contrôle de qualité",
          agentTotal:
            "Nombre total de services d'inspection de la qualité entrés dans une tâche d'inspection de la qualité",
          avg: "Score moyen du test de qualité",
          violation:
            "Somme du nombre de violations d'éléments de contrôle de qualité de toutes les conversations",
          compliance:
            "Somme du nombre de fois où la conformité des éléments de contrôle de qualité est atteinte pendant le processus de contrôle de qualité par dialogue",
          check: "Nombre total de contrôles manuels",
          appeal:
            "Nombre total de plaintes déposées dans le nombre total de conversations",
          review:
            "Nombre total de demandes de révision dans le nombre total de dialogues",
        },
        subTips: {
          violation:
            "Taux de violation: nombre total de violations / nombre total de contrôles de qualité * 100%",
          compliance:
            "Taux de conformité: nombre total de conformité / nombre total de contrôles de qualité * 100%",
          check:
            "Couverture des inspections par tirage: total des inspections par tirage manuel / total des inspections de qualité * 100%",
          appeal:
            "Taux de plaintes: total des plaintes / total des contrôles de qualité * 100%",
          review:
            "Taux de révision: total des révisions / total des contrôles de qualité * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Paramètres du système",
          systemName: "Nom du système",
          speakerRecognize: "Identification des locuteurs",
          massage: "Notification des messages",
          evaluate: "Évaluation Cognitive artificielle",
          evaluateTip:
            "Sélection d'une inspection rapide dans les détails de l'inspection de qualité après ouverture invite à une évaluation humaine",
          speakerRecognizeTip:
            "L'accès aux enregistrements d'appels de tiers via l'API nécessite d'identifier si le canal gauche est un service client ou un client",
          speakerRecognizeTip2:
            "Enregistrement à canal unique reconnu par texte, la configuration des rôles définit les rôles du service client",
          enabledImSendStatusFilter:
            "Filtrage des messages textuels Quality check",
          enabledImSendStatusFilterTips:
            "Le contenu détecté de la parole d'opérateur pour le contrôle de qualité ne contient pas de message avec les états [retiré], [échec d'envoi], [message hors ligne non lu]",
          lang: "Paramètres de langue du système",
          zh: "Chinois simplifié",
          en: "English",
          tw: "Chinois traditionnel",
        },
        template: {
          largeModelSettings: "Paramètres du grand modèle",
          indonesian: "Indonésien",
          vietnamese: "Vietnamien",
          portuguese: "Portugais",
          malay: "Malais",
          french: "Français",
          spanish: "Espagnol",
          japanese: "Japonais",
          thai: "Thaï",
          blockSystemSendingMessages: "Système de masquage envoyer un message",
          textDataDesensitization: "Désensibilisation des données textuelles",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "L'interrupteur on affiche la liste déroulante des champs désensibilisés (prise en charge de la sélection multiple), les champs désensibilisés: nom de la personne, numéro de téléphone portable, adresse",
          desensitizingField: "Champs désensibilisés",
          name: "Nom de la personne",
          cellphoneNumber: "Numéro de téléphone portable",
          address: "Adresse",
          dataCleaning: "Nettoyage des données",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Après la configuration, les conversations QC, les données de session Wechat d'entreprise seront nettoyées périodiquement et ne seront pas récupérables, veuillez agir avec prudence",
          newCleaningRules: "Ajouter une règle de nettoyage",
          qualityInspectionRuleApproval:
            "Approbation des règles d'inspection de la qualité",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Une fois activée, la modification des règles de contrôle de la qualité doit être approuvée avant d'entrer en vigueur",
          maskOfflineMessageUnread: "Masquer les messages hors ligne non lus",
          privacyNumberProtection: "Protection du numéro de confidentialité",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Désensibilisation du champ "téléphone client" en cours d\'appel après ouverture, affiché comme 1881 * * * * *',
          shieldingWithdrawn: "Le bouclier a été retiré",
          maskSendingFailed: "L'envoi bloqué a échoué",
        },
        component: {
          conversation: "Appel",
          dialogue: "Dialogue",
          weCom: "Wechat d'entreprise",
          day: "Jour",
          week: "Semaine",
          month: "Mois",
          year: "Année",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Nettoyer la portée",
            dataCleaningTimeSetting:
              "Paramètres de temps de nettoyage des données",
            afterCleanUpTheData: "Après cela, nettoyez les données",
            dataAfterComplaintCompletion: "Données après l'achèvement du grief",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Exécution réussie",
          saveSuccessful: "Sauvegarder le succès",
          speechRecognition: "Reconnaissance vocale",
          reExecuteErrorCorrection: "Ré - exécuter la correction d'erreur",
          confirmExecution: "Confirmer l'exécution",
          voiceDetection: "Détection vocale",
          noiseDetectionPowerLevel: "Détection de bruit powerlevel ≤",
          pleaseEnterTheThreshold: "Veuillez entrer le seuil",
          volumeDetectionPowerLevel: "Détection du volume powerlevel ≥",
          reset: "Réinitialisation",
          preserve: "Sauvegarder",
        },
        coachTemplate: {
          french: "Français",
          spanish: "Espagnol",
          japanese: "Japonais",
          thai: "Thaï",
          loginPopupWindow: "Login fenêtre",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Une fois allumé, une invite de pop - up est donnée lors de l'entrée dans le système SMART sparring",
          title: "Titre",
          text: "Le corps du texte",
          checkBoxContent: "Cochez la case contenu",
          informedConsentForm: "Consentement éclairé",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Pour vous aider à améliorer vos compétences en communication et en parole, ainsi que l'application de votre expertise, vous permettant de pratiquer et de recevoir des commentaires en temps réel, à tout moment et en tout lieu, nous recueillerons les informations suivantes sur vous via cette plate - forme:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "J'ai lu et compris pleinement le présent formulaire de consentement éclairé dans son intégralité et déclare solennellement consentir au traitement de mes renseignements personnels sensibles conformément aux fins et de la manière décrites dans le présent formulaire de consentement éclairé.",
        },
      },
      systemIntegration: {
        title: "Paramètres du système",
        ccIntegrationTips:
          "Dock intelligent de contrôle de qualité udesk call center description Documentation",
        unauthorized: "Non autorisé",
        authorized: "Autorisé",
        udeskDomain: "Nom de domaine du système de service à la clientèle",
        udeskToken: "Clé du système de service à la clientèle",
        udeskAdminEmail:
          "Numéro de compte administrateur système du service client",
        password: "Compte Administrateur mot de passe",
        applyAuth: "Autorisation",
        cancelAuth: "Annulation de l'autorisation",
        reApplyAuth: "Réautorisation",
        keyTips:
          "Gestion du back office du système de service à la clientèle - token d'authentification API dans Single Sign - on",
      },
      customerField: {
        title: "Champ client",
        table: {
          propName: "Nom du champ",
          propDesc: "Description du champ",
          valueType: "Type de champ",
          propType: "Mode de création",
          createTime: "Temps de création",
          canNotEdit: "Les champs système ne supportent pas l'édition",
        },
        edit: "Modifier les champs",
        new: "Nouveau champ",
      },
      wechatDocking: {
        title: "Micro - amarrage",
        formLabels: {
          companyId: "Enterprise id",
          communicationKey: "Clé de communication",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL de rappel d'événement",
          applicationName: "Nom de l'application",
          applicationId: "Id de l'application Web",
          applicationSecret: "Appliquer secret",
        },
        formTitle: {
          initialForm: "Configuration initiale",
          synchronizeForm: "Synchronisation des contacts",
          settingForm: "Appliquer les paramètres",
        },
        qrCodeUrlTip:
          "Demandez à l'Administrateur de scanner le Code QR ci - dessous pour le développement générationnel application Binding",
      },
      taobao: {
        formLabels: {
          name: "Nom du magasin",
          account: "Numéro de compte",
          password: "Mot de passe",
          confirm: "Confirmer le mot de passe",
          authorization: "Autorisation",
          switchOn: "Ouvrir",
          switchOff: "Fermé",
        },
        tableLabels: {
          name: "Nom du magasin",
          account: "Numéro de compte",
          authorization: "Autorisation",
          updateUser: "Récemment mis à jour par",
          updateTime: "Heure de la dernière mise à jour",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Domaine de deuxième niveau microfong",
          secretKeyTip:
            "Clé secrète du locataire, obtenue à la station d'accueil du système en arrière - plan du système microfon",
        },
      },
      user: {
        index: {
          name: "Gestion du personnel",
          existingUsers: "Déjà employé",
          deleteSuccess: "Supprimer avec succès",
          deleteFailed: "La suppression a échoué",
        },
        detail: {
          accountPrompt: "Veuillez entrer une boîte aux lettres",
          resetPassword: "Réinitialiser le mot de passe",
          passwordPrompt:
            "Mot de passe (6 - 14 Chiffres combinés avec des chiffres)",
          originPasswordPrompt: "Veuillez entrer le mot de passe initial",
          resumePasswordPrompt: "Veuillez saisir à nouveau votre mot de passe",
          passwordFormWrong:
            "S'il vous plaît entrer un mot de passe avec 6 - 14 Chiffres combinés avec des chiffres!",
          resumePasswordWrong: "Deux fois le mot de passe est incohérent!",
          suppertImageType: "Soutien JPG, GIF, PNG, jusqu'à 500kb",
          namePrompt: "Veuillez entrer un nom",
          nickNamePrompt: "Veuillez entrer un pseudonyme",
          telephonePrompt: "Veuillez entrer un numéro de téléphone",
          roleSelectedPrompt: "Veuillez sélectionner un rôle",
          agentIdPrompt: "Veuillez entrer le numéro de travail",
          disabled: "Désactivé",
          enabled: "Activer",
          reloadBowerInfo:
            "Les informations de l'utilisateur changent et vous devez rafraîchir votre navigateur?",
          saveUserError:
            "L'enregistrement des informations utilisateur a échoué, Veuillez réessayer plus tard",
          resetPasswordSuccess: "Réinitialisation du mot de passe réussie",
          resetPasswordFailed:
            "La Réinitialisation du mot de passe a échoué, Veuillez réessayer plus tard",
        },
      },
      userGroups: {
        index: {
          name: "Gestion des groupes d'employés",
          existingUserGroups: "Groupes d'employés existants",
        },
        detail: {},
      },
      role: {
        index: { name: "Gestion des rôles", existingRoles: "Déjà des rôles" },
        edit: {
          editRole: "Modifier le rôle",
          createRole: "Créer un rôle",
          basicInfo: "Informations de base",
          taskPermissions: "Autorisations de tâches",
          existingTasks: "Déjà des missions",
        },
      },
      anomalousData: {
        title: "Données anormales",
        list: {
          selectHolder: "Veuillez sélectionner une source de données",
          businessTime: "Temps d'affaires",
          businessId: "Business id",
          export: "Exporter",
        },
      },
      templateComponent: {
        list: {
          title: "Le marché des Templates",
          name: "Nom du modèle",
          searchHolder: "Veuillez entrer ce que vous souhaitez demander",
          update: "Situation des mises à jour",
          sort: "Trier par",
          moduleType: "Type de modèle",
          moduleSourceType: "Type applicable",
          fetchNum: "Nombre d'acquisitions",
          newest: "Récemment mis à jour",
          usedMost: "Obtenir le plus",
          call: "Appel",
          dialogue: "Dialogue",
          waiting: "À auditer",
          cancel: "Annuler la publication",
          success: "Audit passé, publié",
          failed: "Audit rejeté",
          no: "Non extensible",
          ok: "Peut être mis à niveau",
          all: "Tous",
          delete: "Supprimer avec succès",
          downOk: "Succès sur l'étagère inférieure",
          withdraw: "Retrait réussi",
          updateOk: "Mise à niveau réussie",
          get: "Obtenir",
          width: "Retrait",
          getOk: "Obtenir le succès",
          orgName: "Organisations affiliées",
          nowVersion: "Version actuelle",
          lastedVersion: "Dernière version",
          appStatus: "État de l'application",
          actions: "Opérations",
          down: "L'étagère inférieure",
          updateTop: "Mise à niveau",
          check: "Voir",
          deleteWhen: "Confirmer la suppression?",
          yes: "Oui",
          noo: "Non",
          deleteOk: "Supprimer",
          permissible: "Autorisé",
          noPermissible: "Non autorisé",
          openUp: "Ouvert",
          openDown: "Pas ouvert",
          task: "Tâches d'inspection de la qualité",
          point: "Point de contrôle de qualité",
          tag: "Étiquettes sémantiques",
          muti: "Multi - éléments",
          inter: "Une question une réponse",
          info: "Entité d'information",
          concept: "Mots conceptuels",
          detail: "Détails du module",
          infomation: "Informations de base",
          detailConfig: "Configuration détaillée",
          twoEdit: "Deuxième édition",
          mustUpdate: "Mettre à niveau maintenant",
          descition: "Description du modèle",
          descitionSize:
            "La description du modèle ne peut pas dépasser 250 mots",
          stencilsDetail: "Détails du modèle",
          updatedRecords: "Mettre à jour les enregistrements",
          version: "Numéro de version",
          updateDescription: "Mettre à jour la description",
          myModule: "Mes Templates",
          addModule: "Nouveau module",
          publishVersion: "Version publiée",
          earliestVersion: "La plus ancienne version",
          edit: "Modifier",
          add: "Nouveau",
          configNo: "Détails de configuration du module ne peut pas être vide",
          updateNo: "Les instructions de mise à jour ne peuvent pas être vides",
          editSuccess: "Modification réussie",
          saveSuccess: "Sauvegarder le succès",
          newer: "Mise à jour",
          sumbit: "Soumettre",
          cancelName: "Annulation",
          nameRequired: "Nom du module est un champ obligatoire",
          nameSize: "Le nom du module ne peut pas dépasser 16 mots",
          publishStatus: "Statut de publication",
          submitUpdate: "Soumettre une mise à jour",
          updateVersion: "Version mise à jour",
          updateExplain: "Instructions de mise à jour",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon Électro",
        youtubeVideo: "Vidéo Youtube",
        amazon: {
          component: {
            queuing: "Dans la file d'attente",
            notStarted: "Pas commencé",
            inProgress: "En cours",
            success: "Succès",
            failed: "Échec",
            successfullyCreated: "Créer un succès",
            editedSuccessfully: "Rédaction réussie",
            operationSucceeded: "Opération réussie",
            deletedSuccessfully: "Supprimer le succès",
            link: "Liens",
            grabState: "Prenez l'état",
            updateTime: "Temps de mise à jour",
            grab: "Saisir",
            edit: "Éditeur",
          },
          template: {
            amazonEcommerce: "Amazon Électro",
            thisProduct: "Ce produit",
            competition: "Produits compétitifs",
            new: "Nouvelle construction",
            edit: "Éditeur",
            link: "Liens",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Vidéo Youtube",
            thisProduct: "Ce produit",
            competition: "Produits compétitifs",
            new: "Nouvelle construction",
            edit: "Éditeur",
            link: "Liens",
          },
          component: {
            queuing: "Dans la file d'attente",
            notStarted: "Pas commencé",
            inProgress: "En cours",
            success: "Succès",
            failed: "Échec",
            successfullyCreated: "Créer un succès",
            editedSuccessfully: "Rédaction réussie",
            operationSucceeded: "Opération réussie",
            deletedSuccessfully: "Supprimer le succès",
            youTubeVideoLink: "Lien vidéo YouTube",
            grabState: "Prenez l'état",
            updateTime: "Temps de mise à jour",
            grab: "Saisir",
            edit: "Éditeur",
          },
        },
      },
    },
    demo: {
      title: "Contrôle de qualité intelligent",
      template: "Choisir un modèle de contrôle qualité",
      radio: "Choisir un format d'enregistrement",
      uploadAudio: "Télécharger un enregistrement audio",
      uploadAudioTip:
        "Les enregistrements téléchargés ne prennent en charge que les formats WAV et MP3 et ne dépassent pas 1G",
      uploadText: "Télécharger du texte",
      uploadTextTip:
        "Le texte téléchargé ne prend en charge que les formats SRT et txt, la taille ne dépasse pas 10m, le fichier d'enregistrement ne sera plus analysé avec ASR après le téléchargement du texte",
      uploadSuccess: "Fichier d'enregistrement téléchargé avec succès",
      uploadFailed: "Le téléchargement du fichier d'enregistrement a échoué",
      startQualityInspection: "Commencer le contrôle qualité",
      qualityInspectionStarted: "En cours de contrôle qualité",
      downloadText: "Texte à télécharger",
      checking: "Progrès de l'inspection de qualité",
      checkingTip: "Attendez quelques minutes, soyez patient",
      noCheckContent: "Aucun résultat de contrôle qualité",
      noTextDownload:
        "Il n'y a pas encore de texte de contrôle de qualité à télécharger!",
      pleaseCheckParams:
        "Veuillez vérifier si vous sélectionnez un modèle, un type de voix et téléchargez un fichier d'enregistrement",
      inspectionFailed:
        "Le test de qualité a échoué, Veuillez réessayer plus tard!",
    },
    wechatQa: {
      baseInfo: { title: "Informations de base" },
      conversationSetting: {
        title: "Paramètres de session",
        segmentationStrategy: "Stratégie de segmentation",
        rule: "Stratégie",
        time: "Temps de découpage",
        daily: "Tous les jours",
        passScore: "Score de qualification de session",
        ruleLogic: "Logique d'exécution",
      },
      message: {
        title: "Événements push",
        table: {
          index: "Numéro de série",
          name: "Le nom",
          type: "Méthode push",
          desc: "Description",
          status: "Statut",
        },
        subTitle: "Liste des événements",
      },
      checkPoint: {
        title: "Modèle analytique",
        groups: "Groupement de modèles",
        newClassification: "Nouveau groupe",
        editClassification: "Modifier le Groupe",
        benchmarkScore: "Score de base",
        pointCount:
          "Modèles analytiques communs sous la classification actuelle",
        openPointCount: "Le nombre d'activations est",
        closedPointCount: "Le nombre de désactivations est",
        scoreLowerLimit: "Score minimum inférieur",
        scoreUpperLimit: "Maximum de score",
        classificationName: "Nom du Groupe",
        classificationNamePlaceHolder: "Veuillez entrer le nom du Groupe",
        classificationRemark: "Description du Groupe",
        classificationRemarkPlaceHolder:
          "Veuillez entrer une description de groupe",
      },
      chartRecord: {
        title: "Session Wechat d'entreprise",
        table: {
          id: "Id de session",
          type: "Type de session",
          startTime: "Heure de début de la session",
          endTime: "Dernière heure de fin de session",
          groupId: "ID du Groupe",
          client: "Le client",
          customer: "Service à la clientèle",
          averageScore: "Score moyen de contrôle de qualité",
        },
        detail: {
          title: "Détails de la session Wechat entreprise",
          inspectionInfo: "Analyser les informations",
          chartInfo: "Informations sur la session",
          tasks: "Tâches analytiques",
          avgScore: "Analyse du score moyen",
          qualified: "Qualifié",
          unqualified: "Non qualifié",
          taskTitle: "Modèles d'analyse applicables",
          info: "Analyse des informations en temps réel",
          today: "Message aujourd'hui",
          history: "Message historique",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname:
                  "Veuillez entrer une note / un pseudonyme",
                pleaseEnterTheGroupChatName:
                  "Veuillez entrer un nom de discussion de groupe",
              },
            },
          },
          template: { whole: "Tous" },
        },
      },
      realTimeAnalysis: {
        title: "Analyse en temps réel",
        table: {
          name: "Nom de la scène",
          count: "Nombre de modèles analytiques",
          baseScore: "Score de base",
          passScore: "Points de qualification",
          maxScore: "Maximum de score",
          minScore: "Score minimum inférieur",
          status: "Statut",
          actions: "Opérations",
        },
        detail: {
          title: "Analyse des scènes en temps réel",
          form: {
            name: "Nom de la scène",
            prevCondition: "Conditions de déclenchement de scène",
            prevConditionToolTip:
              "Si la condition est vide, ce scénario est analysé par défaut",
            prevConditionError:
              "La condition de déclenchement de scène ne peut pas coïncider avec le modèle analytique",
            scoreInputPlaceholder: "Entrez le score",
            baseScore: "Score de base",
            status: "Statut",
            passScore: "Points de qualification",
            maxScore: "Maximum de points",
            minScore: "La limite inférieure de score la plus basse",
            analysisModel: "Modèle analytique",
            message: "Notification de déclenchement",
            pushType: "Méthode push",
            createAnalysisModel: "Ajouter un modèle",
            scoreUpperLimitError:
              "La limite inférieure de score ne peut pas être supérieure à la limite supérieure de score",
            table: {
              name: "Nom du modèle",
              rule: "Règles",
              score: "Valeur du point",
            },
            pushConfigSwitchTip1: "Lorsque cette scène apparaît",
            pushConfigSwitchTip2: "Quand et au - dessus, notifications Push",
          },
        },
      },
      smartCustomerTag: {
        title: "Étiquettes clients intelligentes",
        table: {
          name: "Nom de la règle",
          tags: "Étiquette micro - entreprise client",
          status: "Activer le statut",
          createUser: "Créé par",
          createTime: "Temps de création",
        },
        detail: {
          name: "Nom de la règle",
          condition: "Définir les règles",
          logic: "Logique",
          status: "Activer le statut",
          tags: "Choisir une étiquette",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Nombre de marques clients" },
        },
        messageFiltering: {
          template: {
            edit: "Éditeur",
            messageContent: "Contenu du message",
            messageTypeID: "ID de type de message",
            messageTypeDescription: "Description du type de message",
            messageFiltering: "Filtrage des messages",
            suitableForFilteringDataWithoutQualityInspection:
              "Convient pour le filtrage des données qui ne nécessitent pas d'inspection de qualité",
            create: "Créer",
            messageDescription: "Description du message",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Temps d'appel",
          sessionType: "Type de session",
          whole: "Tous",
          staff: "Employés",
          customer: "Le client",
          callKeywords: "Mots - clés d'appel",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Détails des appels vocaux Enterprise micro",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Archive des sessions",
        voiceCall: "Appels vocaux",
        noMoreVoiceCalls: "Pas encore d'appels vocaux",
        enterpriseWechatVoiceCall: "Appels vocaux Wechat d'entreprise",
        enterpriseWechatConversationDetails:
          "Détails de la session Wechat entreprise",
        previous: "Article précédent",
        next: "Article suivant",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Tâches d'analyse de micro - session",
          enterpriseAndMicroVoiceAnalysisTask:
            "Tâches d'analyse vocale micro - entreprise",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Analyse du chat Wechat d'entreprise",
      },
      wechatQaSettingEntry: { messageFiltering: "Filtrage des messages" },
    },
    businessAnalysis: {
      keyword: "Nom du rapport",
      createUser: "Créé par",
      createTime: "Temps de création",
      updateTime: "Temps de mise à jour",
    },
    semanticIntelligence: {
      applicationMessageType: "Type de message d'application",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Veuillez affiner la configuration",
              generatePromptLanguage: "Générer des invites",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Conformité avec",
              gradedEvaluation: "Évaluation par répartition",
            },
          },
          training: {
            index: {
              pleaseEnter: "Veuillez saisir",
              pleaseGeneratePrompt: "Veuillez générer un rappel",
              pleaseSelectADialogue: "Veuillez choisir une conversation",
              training: "Entraînement",
              dataType: "Type de données:",
              voiceCall: "Appels vocaux",
              textDialogue: "Dialogue de texte",
              generate: "Générer",
              qualityInspectionResults: "Résultats d'inspection de la qualité:",
              hit: "Hit parler:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Nom du modèle",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Veuillez entrer un nom de modèle, par exemple: Existe - t - il un cas d'inaction au service à la clientèle",
            modelDescription: "Description du modèle",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Peut décrire en détail ce que vous devez détecter pour faciliter la compréhension du modèle, tels que: s'il y a une absence d'action du service à la clientèle, c'est - à - dire une question adressée au client le service à la clientèle a une réponse, mais la question du client n'est pas traitée ou le contenu de la réponse n'est pas lié à la réponse du client",
            validity: "Efficacité",
            effective: "Efficace",
            invalid: "Invalide",
            type: "Type",
            hintLanguage: "Langue de conseils",
            editIntelligentModel: "Modifier le modèle intelligent",
            createANewIntelligentModel: "Nouveau modèle intelligent",
          },
        },
        index: {
          modelName: "Nom du modèle",
          modelDescription: "Description du modèle",
          type: "Type",
          whetherItMeetsTheRequirements: "Conformité avec",
          gradedEvaluation: "Évaluation par répartition",
          validity: "Efficacité",
          intelligentModel: "Le modèle intelligent",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "S'applique à l'inspection de la qualité des éléments subjectifs (par exemple, si l'attitude du Service d'inspection de la qualité est enthousiaste, si le service est patient, etc.)",
        },
      },
      clusterAnalysis: "Analyse de Clustering",
      speechMining: "Excavation vocale",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Prend en charge l'importation par lots d'entités d'information via l'interface API, le nom de l'API ne prend pas en charge l'édition après l'enregistrement",
        },
        component: { nothing: "Aucun" },
      },
      knowledgeBase: {
        index: {
          classification: "Classification",
          import: "Importation",
          importKnowledgeBase: "Importer la base de connaissances",
          moveKnowledgePointsInBatch:
            "Points de connaissances mobiles par lots",
          moveTo: "Déplacer vers:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Veuillez sélectionner les points de connaissance qui nécessitent un déplacement par lots",
        },
        detail: {
          index: {
            intrasentence: "Dans la phrase",
            afterProblem: "Après le problème",
          },
        },
      },
      semanticTags: { component: { nothing: "Aucun" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Mots sensibles",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Mots sensibles: mots sensibles mots d'intérêt métier configurables pour la découverte par l'opinion publique mots sensibles nuage",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Nouveau mot sensible",
            editSensitiveWords: "Modifier les mots sensibles",
            sensitiveWords: "Mots sensibles",
          },
          template: {
            sensitiveWords: "Mots sensibles",
            sensitiveWordsCannotBeEmpty:
              "Les mots sensibles ne peuvent pas être vides",
            pleaseEnterSensitiveWordsSeparatedBy:
              "S'il vous plaît entrer des mots sensibles, plusieurs mots sensibles sont séparés par un, '",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Veuillez sélectionner un scénario d'application",
            keyWord: "Mots clés",
            pleaseEnterKeywords: "Veuillez entrer un mot clé",
            keywordLengthWithinCharacters:
              "Mots - clés longueur dans les 40 caractères",
            dataSources: "Source des données",
            pleaseSelectADataSource:
              "Veuillez sélectionner une source de données",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Le Cluster actuel est terminé, la réédition produira un nouvel élément de données de Cluster qui ne remplacera pas les données de Cluster terminées, veuillez confirmer",
            savingSucceeded: "Sauvegarder le succès",
            clusteringStart: "Début du cluster",
            clustering: "Clustering",
            edit: "Modifier",
            newlyBuild: "Nouvelle construction",
            clusterName: "Nom du cluster",
            pleaseEnterAName: "Veuillez entrer un nom",
            withinCharactersOfTheName: "Longueur du nom dans les 40 caractères",
            speaker: "Orateur",
            pleaseSelectASpeaker: "Veuillez sélectionner un orateur",
            sessionDate: "Date de la session",
            pleaseEnterTheSessionDate: "Veuillez entrer la date de la session",
            selectUpToMonthOfHistoricalSessionData:
              "Sélectionnez jusqu'à 1 mois de données de session historiques",
            automaticallyClusterDailyNewData:
              "Clustering automatique ajout quotidien de données",
            conversationChannel: "Canaux de session",
            pleaseSelectAConversationChannel:
              "Veuillez sélectionner un canal de session",
            applicationScenario: "Scène d'application",
            clusteringRules: "Règles de Clustering",
            incompleteClusteringRuleSettings:
              "Configuration incomplète des règles de Clustering",
            startClustering: "Commencer le Clustering",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Veuillez sélectionner une tâche de contrôle qualité",
              beEqualTo: "égale à",
            },
          },
        },
        detail: {
          index: {
            theme: "Thèmes",
            numberOfCorpora: "Nombre de matières",
            corpusCoverage: "Couverture de matière",
            clusterDetails: "Détails du cluster",
          },
          ranking: {
            hotWords: "Mots chauds",
            numberOfCorpora: "Nombre de matières",
            speaker: "Orateur",
            sit: "Assis",
            passenger: "Invités",
            hotSentence: "Phrases chaudes",
            corpusDetails: "Détails du matériau",
          },
        },
        index: {
          delete: "Supprimer",
          deletionSucceeded: "Supprimer avec succès",
          clusterName: "Nom du cluster",
          speaker: "Orateur",
          creationTime: "Temps de création",
          state: "Statut",
          operation: "Opérations",
          see: "Voir",
          edit: "Modifier",
          clusterAnalysis: "Analyse de Clustering",
          newCluster: "Nouveau Cluster",
          clusteringStatus: "État du cluster",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Entrez les mots - clés Search dissent",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Bibliothèque de mots",
                recommendedScripts: "Procédure de recommandation",
                common: "Au total",
                strip: "Article",
                scriptFavorites: "Mots favoris",
              },
              conditionPostfix: {
                detectRoles: "Détecter le rôle",
                detectionRange: "Gamme de détection",
                sentence: "Phrases",
              },
              customerFilter: {
                scriptFiltering: "Filtrage phonétique",
                cancel: "Annulation",
              },
              index: {
                viewDetails: "Voir les détails",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Vous confirmez vouloir abandonner? Après abandon, il ne sera plus possible de récupérer",
                abandonment: "Abandonner",
                deletionSucceeded: "Supprimer avec succès",
                passenger: "Invités",
                sit: "Assis",
                adopted: "Adopté",
                determine: "Déterminer",
                cancel: "Annulation",
                adopt: "Adoption",
              },
              pageRightFrom: {
                savingSucceeded: "Sauvegarder le succès",
                pleaseEnterTheScriptContent:
                  "Veuillez entrer le contenu verbal",
                pleaseSelectAFavoriteGroup:
                  "Veuillez sélectionner un groupe de favoris",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Bibliothèque de traitement des objections",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "Veuillez choisir la parole",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Traitement des objections favoris",
            },
            pageRightTable: {
              likeCancel: "J'aime annuler",
              likeSuccessfully: "Aimer le succès",
              adoptScripts: "Adoption du discours",
              operation: "Opérations",
              viewConversationDetails: "Voir les détails de la conversation",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Bibliothèque de mots de vente de produits",
              },
              pageRightTable: { keyPointsOfScript: "Points clés de la parole" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Traitement des objections favoris",
              newGroup: "Nouveau groupe",
              editGroup: "Modifier le Groupe",
              addGroup: "Ajouter un groupe",
              savingSucceeded: "Sauvegarder le succès",
              groupName: "Nom du Groupe",
              pleaseEnterTheGroupName: "Veuillez entrer le nom du Groupe",
              groupNameWithinCharacters:
                "Nom du Groupe longueur dans les 20 caractères",
              deletionSucceeded: "Supprimer avec succès",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings:
                    "Paramètres recommandés pour la parole",
                },
                rightForm: {
                  savingSucceeded: "Sauvegarder le succès",
                  intelligentRecommendation: "Recommandations intelligentes",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Affiche automatiquement les excellentes procédures de conversation adoptées dans la barre latérale de la session avec des objections similaires, et les employés peuvent voir directement les procédures de recommandation pour apprendre sur la page de détails de la répétition de la session",
                  objectionsIncludedByDefault: "Objections incluses par défaut",
                  addRecommendationTriggerSemantics:
                    "Augmenter la sémantique de déclenchement recommandée",
                  remindEmployeesToView: "Rappeler aux employés de voir",
                  shareWithMoreEmployees: "Partager avec plus d'employés",
                  allHands: "Tout le personnel",
                },
                smartToolbar: {
                  selectSemanticTag: "Choisir une étiquette sémantique",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Exportez - vous tous les enregistrements de données sous le paquet actuel?",
                newScript: "Nouvelle expression",
                editingScript: "Editer le mot",
                savingSucceeded: "Sauvegarder le succès",
                objection: "Objection",
                pleaseEnterAScriptObjection:
                  "Veuillez entrer une objection verbale",
                speechTechnique: "La parole",
                pleaseEnterTheScriptContent:
                  "Veuillez entrer le contenu verbal",
                grouping: "Groupe",
                pleaseSelectAFavoriteGroup:
                  "Veuillez sélectionner un groupe de favoris",
                adoptScripts: "Adoption du discours",
                passenger: "Invités",
                sit: "Assis",
                numberOfViews: "Nombre de vues",
                useful: "Utile",
                operation: "Opérations",
                edit: "Modifier",
                delete: "Supprimer",
                deletionSucceeded: "Supprimer avec succès",
                scriptRecommendationSettings:
                  "Paramètres recommandés pour la parole",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Bibliothèque de mots",
          scriptMining: "Excavation vocale",
          objectionHandlingScripts: "Traitement des objections",
          productSellingPointScripts: "Discours de vente de produits",
        },
        sellingPoint: {
          index: { newCluster: "Nouveau Cluster" },
          components: {
            callDetailModal: {
              sessionDetails: "Détails de la session",
              cancel: "Annulation",
              determine: "Déterminer",
              choice: "Sélection",
            },
            pageCenterTable: { scriptOverview: "Profil de la parole" },
            pageLeftTable: {
              enterSearchKeywords: "Entrez les mots clés de recherche",
              keyWord: "Mots clés",
            },
            pageRightTable: {
              index: {
                viewDetails: "Voir les détails",
                scriptLibrary: "Bibliothèque de mots",
                scriptRefinement: "Distillation de la parole",
                adoptScript: "Adoption du discours",
              },
              tagsEdit: { keyPointsOfScript: "Points clés de la parole" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Bibliothèque de mots de vente de produits",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Points de vente inclus par défaut",
                  },
                },
                index: {
                  keyWord: "Mots clés",
                  pleaseSelectAKeyword: "Veuillez sélectionner un mot clé",
                  keyPointsOfScript: "Points clés de la parole",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Veuillez entrer les points de parole",
                  viewDetails: "Voir les détails",
                  pleaseChooseToAdoptScript: "Veuillez choisir la parole",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Logique de match",
            nLPMatching: "Correspondance NLP",
            strictlyMatchByWord: "Correspondance stricte par mot",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Nom du type de message",
          messageTypeID: "ID de type de message",
          messageTypeDescription: "Description du type de message",
          applicationMessageType: "Type de message d'application",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Convient à l'accès à différentes données de canal pour l'inspection de la qualité, et doit être classé en fonction de l'identification du canal",
        },
        detail: {
          index: {
            messageTypeName: "Nom du type de message",
            pleaseEnterAMessageTypeName:
              "Veuillez saisir le nom du type de message",
            messageTypeDescription: "Description du type de message",
            describeYourMessageTypeInDetail:
              "Peut décrire en détail votre type de message",
            editMessageType: "Modifier le type de message",
            newMessageType: "Nouveau type de message",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Veuillez sélectionner un membre de l'équipe",
        existingFilter: "Déjà filtres",
        pleaseSelect: "Veuillez sélectionner",
        filterCondition: "Conditions de filtrage",
        areYouSureToDeleteTheCurrentFilter:
          "Confirmez - vous la suppression du filtre actuel?",
        sessionDate: "Date de la session",
        staff: "Employés",
        customer: "Le client",
        pleaseEnterCustomerName: "Veuillez entrer le nom du client",
        keyWord: "Mots clés",
        whole: "Tous",
        pleaseEnterSessionKeywords:
          "Veuillez saisir les mots clés de la session",
        sessionDuration: "Durée de la session",
        sessionLabel: "Scène de session",
        pleaseSelectASessionLabel:
          "Veuillez sélectionner un scénario de session",
        commentTopic: "Sujet de commentaire",
        pleaseSelectACommentTopic: "Veuillez choisir un sujet de commentaire",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Confirmez - vous la couverture du filtre actuel?",
        overlayFilter: "Couvrir le filtre",
        NewFilter: "+ nouveau filtre",
        newFilter: "Nouveau filtre",
        filterName: "Nom du filtre",
        pleaseEnter: "Veuillez entrer",
        allCall: "Tous les appels",
        percentage: "Pourcentage",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'Supporte la recherche de plusieurs mots clés, la relation "ou" est séparée par une virgule (chinois - anglais), "et" la relation est séparée par un don, comme:',
          callRecordID: "ID d'enregistrement d'appels",
          pleaseFillInTheCallRecordID:
            "Veuillez remplir l'id de journal d'appels",
          minute: "Minutes",
          second: "Secondes",
          whole: "Tous",
          callRecording: "Enregistrement des appels",
          enterpriseWechat: "Wechat d'entreprise",
          conversationChannel: "Canaux de session",
          pleaseSelectASessionChannel:
            "Veuillez sélectionner un canal de session",
          learningStatus: "État d'apprentissage",
          pleaseSelectLearningStatus:
            "Veuillez sélectionner un statut d'apprentissage",
          notLearned: "Pas étudié",
          learned: "étudié",
          Minutes: "Minutes",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "La condition de filtrage est vide, les nouveaux filtres ne sont pas pris en charge!",
          filterAddedSuccessfully: "Filtres nouveau succès!",
          failedToAddFilter: "L'ajout de filtres a échoué!",
          filterDeletedSuccessfully: "Filtre Supprimer avec succès!",
          filterDeletionFailed: "La suppression du filtre a échoué!",
          filterOverwriteSucceeded: "Couverture de filtre réussie!",
          filterOverwriteFailed: "La couverture du filtre a échoué!",
          theme: "Thèmes",
          pleaseSelectATheme: "Veuillez choisir un thème",
          keyEvents: "Événements clés",
          pleaseSelectAKeyEvent: "Veuillez sélectionner un événement clé",
        },
        filterItem: {
          startRetesting: "Commencer la re - détection",
          time: "Le temps",
          talkTime: "Durée de l'appel",
          keyEvents: "Événements clés",
          totalImplementationRateOfSupervisionItems:
            "Taux d'exécution total de la supervision",
          sort: "Trier:",
          seeMore: "Voir plus",
        },
        filterStatistics: {
          conversation: "Appel",
          weChat: "Wechat",
          quantity: "Quantité",
        },
      },
      caseBase: {
        index: {
          copyTo: "Copier vers",
          pleaseSelectTheLocationToMove:
            "Veuillez choisir l'emplacement de votre déménagement!",
          folderCopiedSuccessfully: "Dossier copié avec succès!",
          cancelCollectionSuccessfully: "Annuler Collection réussie!",
          collectionSucceeded: "Collection réussie!",
          starCaseBase: "Galerie de cas starmark",
          move: "Mobile",
          rename: "Renommer",
          delete: "Supprimer",
          folderMovedSuccessfully: "Dossier déplacé avec succès!",
          fileMovedSuccessfully: "Fichier déplacé avec succès!",
          fileMoveFailed: "Le déplacement du fichier a échoué!",
          folderAddedSuccessfully: "Ajout du dossier réussi!",
          folderRenameSucceeded: "Dossier renommé avec succès!",
          fileRenameSucceeded: "Fichier renommé avec succès!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Le dossier ne sera pas récupéré après la suppression, confirmez - vous la suppression?",
          deleteSucceeded: "Supprimer avec succès!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Les fichiers ne seront pas récupérés après la suppression, confirmez - vous la suppression?",
          share: "Au total",
          folders: "Un dossier,",
          calls: "Un appel",
          newFolder: "Ajouter un dossier",
          modifyFolder: "Modifier un dossier",
          title: "Titre",
          pleaseEnter: "Veuillez entrer",
          selectMoveLocation: "Choisir l'emplacement de déplacement",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Rappel d'apprentissage",
          subscriptionDynamics: "S'abonner à Dynamics",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID:
              "La session à laquelle vous êtes abonné (id",
            thereAreDynamicUpdates: "Il y a une mise à jour dynamique.",
            newCommentsReplies: "Ajouter un commentaire / Réponse",
            strip: "Article",
            newHelp: "Ajout d'aide",
            goAndWatch: "Aller voir",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Répondu à mon commentaire",
            replySucceeded: "Réponse réussie!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "Dans les commentaires @ me",
            replySucceeded: "Réponse réussie!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Appel pour vous partager",
            shareCasesForYou: "Partager le cas pour vous",
          },
        },
        study: {
          index: {
            learned: "étudié",
            ignored: "Ignoré",
            study: "Apprendre",
            ignore: "Ignorer",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Importation réussie",
          account: "Numéro de compte",
          password: "Mot de passe",
          cover: "Couverture",
          skip: "Sauter",
          import: "Importation",
          importEmployees: "Importer des employés",
          importPrompt: "Conseils d'importation",
          confirm: "Déterminer",
          theDataContainsDuplicateAccountsOrNames:
            "Données d'entrée présence d'un numéro de compte ou d'une duplication de nom",
          allCoverage: "Tout couvert",
          skipAll: "Tout sauter",
          headPortrait: "Image de tête",
          fullName: "Nom et prénom",
          nickname: "Surnom",
          excellentStaff: "Excellent personnel",
          yes: "Oui",
          no: "Non",
          department: "Département",
          accountNumberemail: "Numéro de compte (boîte aux lettres)",
          telephone: "Téléphone",
          jobNumber: "Numéro de travail",
          role: "Rôle",
          pleaseEnterANameOrPhoneSearch:
            "Veuillez entrer une boîte aux lettres, un nom ou un téléphone rechercher",
          person: "Personnes)",
          AddEmployee: "+ ajouter un employé",
        },
        edit: {
          jobNo: "Numéro de travail",
          udeskCustomerServiceSystemIdentity:
            "Identité du système de service à la clientèle udesk",
          enterpriseWechatIdentity: "Identité Wechat d'entreprise",
          inputID: "Entrez l'identification",
          newIdentityBinding: "Ajout d'une liaison d'identité",
          identityBinding: "Liaison d'identité",
          avatarGreaterThan500kb: "Avatar plus grand que 500kb",
          uploadSuccessful: "Téléchargement réussi",
          upload: "Télécharger",
          supportJPGGIFAndPNGUpTo500kb: "Soutien JPG, GIF, PNG, jusqu'à 500kb",
          yes: "Oui",
          no: "Non",
          accountNumber: "Numéro de compte",
          pleaseEnterTheAccountNumber: "Veuillez entrer le numéro de compte",
          initialPassword: "Mot de passe initial",
          pleaseInputAPassword: "Veuillez entrer votre mot de passe",
          confirmPassword: "Confirmer le mot de passe",
          thePasswordsEnteredTwiceAreDifferent:
            "Le mot de passe saisi deux fois n'est pas le même!",
          headPortrait: "Image de tête",
          fullName: "Nom et prénom",
          pleaseEnterYourName: "Veuillez entrer un nom",
          nickname: "Surnom",
          department: "Département",
          excellentStaff: "Excellent personnel",
          sessionIdentity: "Identité de session",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Marquer un enregistrement de session attribué à cet employé",
          telephone: "Téléphone",
          jobNumber: "Numéro de travail",
          role: "Rôle",
          pleaseSelectARole: "Veuillez sélectionner un rôle",
          preservation: "Sauvegarder",
          cancel: "Annulation",
          addedSuccessfully: "Ajouté avec succès!",
          modificationSucceeded: "Modification réussie!",
          newEmployees: "Ajout de personnel",
          editEmployee: "Modifier le personnel",
        },
        detail: {
          viewDetails: "Voir les détails",
          accountNumber: "Numéro de compte",
          headPortrait: "Image de tête",
          fullName: "Nom et prénom",
          nickname: "Surnom",
          department: "Département",
          excellentStaff: "Excellent personnel",
          yes: "Oui",
          no: "Non",
          sessionIdentity: "Identité de session",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Marquer un enregistrement de session attribué à cet employé",
          telephone: "Téléphone",
          jobNumber: "Numéro de travail",
          role: "Rôle",
        },
      },
      roleManage: {
        list: {
          menu: "Le menu",
          functionalCategory: "Grande classe de fonctions",
          functionalSubclass: "Petites classes fonctionnelles",
          deletedSuccessfully: "Supprimer avec succès",
          NewRole: "+ nouveau rôle",
          role: "Rôle",
          dataPermissions: "Droits sur les données:",
          edit: "Modifier",
          areYouSureYouWantToDeleteTheRole: "Déterminer supprimer un rôle",
          determine: "Déterminer",
          cancel: "Annulation",
          delete: "Supprimer",
          applicationPermission: "Droits d'application",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Ne voir que ses propres données de session",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Peut voir les données de session de ce département et de ses sous - départements",
          menu: "Le menu",
          functionalCategory: "Grande classe de fonctions",
          functionalSubclass: "Petites classes fonctionnelles",
          successfullyAdded: "Nouveau succès",
          modifiedSuccessfully: "Modification réussie",
          edit: "Modifier",
          cancel: "Annulation",
          confirm: "Confirmation",
          roleName: "Nom du rôle",
          pleaseEnterTheRoleName: "Veuillez entrer un nom de rôle",
          dataAuthority: "Droits sur les données",
          youCanViewCompanyWideSessionData:
            "Possibilité de visualiser les données de session à l'échelle de l'entreprise",
          applicationPermission: "Droits d'application",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Processus de session",
          generateProcess: "Générer un processus",
          addProcess: "Ajouter un processus",
          cancel: "Annulation",
          complete: "Terminé",
          edit: "Modifier",
          theSettingWillBeSuccessfulLater:
            "Configuration voir le succès plus tard!",
          previous: "Article précédent",
          next: "Article suivant",
          debuggingMode: "Mode de débogage",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Les messages post - ouverture affichent le nom de la règle à laquelle le message correspond, ainsi que le nom du point de connaissance de la base de connaissances, s'ils correspondent à un événement clé, à un élément de supervision, à une règle de l'étiquette du client ou à une règle du point de connaissance.",
          seeLater: "Voir plus tard",
          determine: "Déterminer",
          conversationalInsights: "Insights conversationnels",
          customerInsight: "Aperçu client",
          knowledgePointRecommendation: "Points de connaissance recommandés",
          comment: "Commentaires",
          sessionQuality: "Qualité de la séance",
          setToListenLaterSuccessfully: "Set up Écoutez le succès plus tard!",
          listenLater: "Écoutez plus tard",
          seekCounselingSuccess: "En quête de réussite en Coaching!",
          altogether: "Au total, il y a",
          appearsAt: "Apparaissent »",
          commentsPublishedSuccessfully: "Commentaires publié avec succès",
          callRecording: "Enregistrement des appels",
          addedSuccessfully: "Ajouté avec succès",
          shareWithColleagues: "Partager avec des collègues",
          shareWithCustomers: "Partager avec les clients",
          resumptionDetails: "Détails du disque double",
          addToCaseBase: "Ajouter à la Bibliothèque de cas",
          shareCases: "Partager le cas",
          seekCounseling: "Rechercher un coaching",
          sessionRecord: "Enregistrement de la session",
          businessRecord: "Dossiers d'affaires",
          keywordSearch: "Recherche de mots clés",
          whole: "Tous",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "S'il vous plaît entrer un commentaire, entrez @ pour informer les autres, entrez \\ \\ pour ajouter un sujet",
          subscriptionSession: "S'abonner à une session",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Après s'être abonné, recevez des alertes de notification lorsqu'il y a de nouvelles demandes d'aide, commentaires, réponses",
        },
      },
      coachTask: {
        index: {
          employeeName: "Nom de l'employé",
          customerName: "Nom du client",
          source: "Sources",
          viewSessionRecords: "Voir les enregistrements de session",
          coachingStatus: "État du mentorat",
          helpTime: "Temps d'aide",
          counselingTime: "Temps de coaching",
          helpContent: "Contenu de l'aide",
          coachingOperation: "Opérations de coaching",
          areYouSureYouWantToIgnoreIt: "Sûr d'ignorer?",
          determine: "Déterminer",
          cancel: "Annulation",
          ignoreHelp: "Ignorer l'aide",
          coaching: "Faire du coaching",
          viewCoaching: "Voir le coaching",
          areYouSureToReturnToCounseling:
            "Êtes - vous sûr de revenir à Coaching?",
          returnToPendingCoaching: "Retour à Coaching",
          tutor: "Mentors",
          helpSeekingStatus: "Statut de l'aide",
          recentCoachingTime: "Temps de coaching récent",
          operation: "Opérations",
          ignoreSuccess: "Ignorer le succès!",
          recoverySucceeded: "Restauration réussie!",
          successfulCoaching: "Coaching réussi!",
          myMission: "Mes missions",
          selectCoachingStatus: "Choisir un statut de coaching",
          whole: "Tous",
          iAskedForHelp: "J'ai demandé de l'aide",
          signOut: "Quitter",
          submit: "Soumettre",
          helpPerson: "Personnes à l'aide",
          customer: "Le client",
          toBeCoached: "À coacher",
          counselingSuggestions: "Recommandations pour le coaching",
          pleaseEnterCoachingSuggestions:
            "Veuillez entrer une proposition de coaching",
        },
      },
      coachTaskCenter: {
        title: "Centre de mission",
        list: {
          taskName: "Nom de la tâche",
          lessonGroup: "Classification des cours",
          lessonName: "Cours appartenant",
          taskType: "Types de tâches",
          taskStatus: "État de la tâche",
          taskExecutionInfo: "Mise en œuvre",
          totalCount: "Nombre de personnes à exécuter",
          executeCount: "Nombre de personnes exécutées",
          executeRate: "Taux d'exécution",
          completeCount: "Nombre de personnes terminées",
          completeRate: "Taux d'achèvement",
          commitCount: "Nombre de personnes conformes",
          commitRate: "Taux de conformité",
          excellentRate: "Taux de dao",
          taskTime: "Temps de mission",
          option: "Opérations",
          edit: "Modifier",
          taskDetail: "Données de mission",
          delete: "Supprimer",
          new: "Nouvelle publication",
          taskTypeAll: "Tous",
          taskTypePractice: "Exercices",
          taskTypeTest: "examen",
          taskStatusNotStarted: "Pas commencé",
          taskStatusRunning: "En cours",
          taskStatusEnd: "C'est terminé",
          taskStatusInvalid: "Non en vigueur",
          unlimitedTime: "Temps illimité",
          to: "à",
          deleteConfirm:
            "Tous les enregistrements d'apprentissage sous cette tâche seront vidés après la suppression, confirmez - vous la suppression?",
          deleteSuccess: "Suppression réussie de la tâche",
        },
        edit: {
          addTask: "Nouvelle tâche de publication",
          editTask: "Modifier la tâche de publication",
          publishSuccess: "Publier avec succès",
          publishFailed: "La publication a échoué",
          updateSuccess: "Mise à jour réussie",
          updateFailed: "La mise à jour a échoué",
          cannotBeNull: "Ne peut pas être vide",
          explain: "Description",
          taskDescExtra: "(le contenu sera affiché ici à l'entrée {0})",
          taskTargetObj: "Publier un objet",
          isEnable: "État d'entrée en vigueur",
          dialogueMode: "Mode conversation",
          voice: "Voix",
          text: "Texte",
          userPortrait: "Portrait de l'utilisateur",
          difficultyLevel: "Difficulté",
          limitDuration: "Limitation de la durée",
          tipLabel: "Conseils pour la parole",
          tipTooltip: "Permettre aux apprenants de voir les mots de référence",
          limitTimes: "Nombre d'examens",
          time: "Le temps",
          gradingStandart: "Critères de notation",
          gradingStandartDescAffix:
            "(le processus de dialogue actuel est divisé en",
          gradingStandartDescSuffix:
            "Score, phraséologie {0}, émotions {1}, vitesse de la parole {2})",
          completeStandart: "Exigences d'achèvement des tâches",
          byTimes: "Par nombre de fois",
          byScore: "Par score",
          completeByTimes: "Nombre d'exercices",
          times: "Une fois",
          score: "Points",
          anyTimes: "N'importe quelle fois",
          getScore: "Score",
          offLimitDuration: "Pas limité dans le temps",
          onLimitDuration: "Durée limitée",
          commitScore: "Atteindre les normes, score",
          excellentScore: "Excellent standard, score",
          offLimitTime: "Temps illimité",
          onLimitTime: "Temps limité",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Salut," },
          callDynamic: {
            today: "Aujourd'hui",
            yesterday: "Hier",
            sessions: "Une séance",
          },
          todoList: {
            strip: "Article",
            theSessionIsAddedTolistenLater:
              "La session est ajoutée à « Écoutez plus tard ».",
            askYouForHelp: "Demande de l'aide à toi",
            iHopeToContactYouLater: "Attendez - vous à être contacté plus tard",
            lookingForwardToSendingTheInformationLater:
              "Attendez - vous à envoyer des informations plus tard",
            dialogueRisk: "Il y a des points de risque dans la session",
            tutored: "Déjà coaché",
            goToCounseling: "Aller au Coaching",
            contacted: "A été contacté",
            hasBeenSent: "Envoyé",
            areYouSureYouWantToIgnoreIt: "Sûr d'ignorer?",
            determine: "Déterminer",
            cancel: "Annulation",
            ignore: "Ignorer",
            checked: "Vu",
          },
          callListItem: {
            strip: "Article",
            learned: "étudié",
            keyEvents: "Événements clés",
            totalImplementationRateOfSupervisionItems:
              "Taux d'exécution total de la supervision",
          },
        },
        index: {
          operationSucceeded: "Opération réussie!",
          sessionDynamics: "Dynamique de session",
          my: "Le mien",
          department: "Département",
          company: "La société",
          toDoList: "À faire",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Volume d'appels effectif (PCS)",
          bestPractices: "Meilleures pratiques",
          averageDurationOfASingleCallminutes:
            "Durée moyenne d'un appel (minutes)",
          totalCallVolumePCs: "Volume total des appels (PC)",
          totalTalkTimehours: "Durée totale des appels (heures)",
          speakingPercentagepercentage: "Parler en pourcentage (pourcentage)",
          maximumContinuousSpeakingTimeminutes:
            "Durée maximale de la conversation continue (minutes)",
          numberOfIndepthDiscussionsPCs:
            "Nombre de discussions approfondies (p)",
          maximumCustomerTalkminutes: "Le client le plus long parle (minutes)",
          questionFrequencyPCs: "Fréquence des questions (PC)",
          averageValue: "Moyenne",
          selectDate: "Choisissez une date",
          personnel: "Personnel",
          pleaseSelect: "Veuillez sélectionner",
          callRange: "Gamme d'appels",
          conversationActivity: "Activités de session",
          conversationAction: "Action conversationnelle",
          keyEvents: "Événements clés",
          callRecord: "Enregistrement des appels",
          filter: "Filtrer:",
          conversation: "Appel",
          minute: "Minutes",
          sale: "Employés",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Janvier",
            numberOfCalls: "Nombre d'appels",
            accountInformation: "Informations sur le compte",
            month: "Mois",
            today: "Aujourd'hui",
            customerDetails: "Détails du client",
            searchKeywordsInCommunicationContent:
              "Mots clés récupérés dans le contenu de la communication",
            allMembers: "Tous les membres",
            reset: "Réinitialisation",
            query: "Enquête",
            allTrip: "Présentation du voyage complet",
            contactOverview: "Aperçu des contacts",
            lastContact: "Dernier contact",
            nextContact: "Prochain contact",
            previous: "Article précédent",
            next: "Article suivant",
            viewDetails: "Voir les détails",
            contactDynamics: "Dynamique de contact",
            lastYear: "Dernière année",
            lastCall: "Dernier appel",
            hotTopicsForDiscussion: "Sujets de discussion hotspots",
            totalInTheLastYear: "Total de la dernière année",
            callTimes: "Un appel",
          },
        },
        list: {
          index: {
            risk: "Les risques",
            have: "Il y a",
            nothing: "Aucun",
            channel: "Canaux",
            callRecording: "Enregistrement des appels",
            enterpriseWeChatConversation: "Micro - session d'entreprise",
            enterpriseWeChatCall: "Micro - appel",
            followUpSuggestions: "Recommandations de suivi",
            customer: "Centre client",
            followUp: "Situation du suivi",
            personInCharge: "Responsable",
            recentFollowup: "Suivi récent",
            lastContactTime: "Dernière heure de contact",
            operation: "Opérations",
            see: "Voir",
            exceed: "Plus de",
            noActivityForMoreThanDays: "Jours sans activité",
            lately: "Récemment",
            risksOccurredInTheLastDays: "Sémantique du risque en jours",
            savedSuccessfully: "Sauvegarder le succès",
            today: "Aujourd'hui",
            allDepartments: "Tous les départements",
            allMembers: "Tous les membres",
            customerName: "Nom du client",
            reset: "Réinitialisation",
            query: "Enquête",
            customers: "Un client",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Montrer la situation de contact de l'employé avec le client, où la bulle rose est le message du client, le bleu est le message de l'employé, plus le pourcentage de discours est grand, plus la bulle est grande",
            riskAllocation: "Configuration des risques",
            preservation: "Sauvegarder",
            pleaseEnter: "Veuillez entrer",
            noActivityForDays: "Jours sans activité",
            riskSemanticsWithinDays: "Sémantique du risque en jours",
            addRiskSemantics: "Ajouter une sémantique de risque",
            semanticTags: "Étiquettes sémantiques:",
            standardSentence: "Phrase standard:",
          },
          components: {
            advancedFilter: {
              components: {
                list: {
                  index: { addNewConditions: "Ajouter une nouvelle condition" },
                },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Remplir toutes les conditions suivantes",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Filtrer",
                pleaseSelectFilteringCriteria:
                  "Veuillez sélectionner un critère de filtre",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Phase de changement d'opportunité",
              theCurrentStageIs: "La phase actuelle est […]",
              ConfirmToChangeItTo: "], il est déterminé à le changer en […]",
              IsIt: "]?",
              StageCoStay: "] phase séjour total",
              day: "Jours",
              start: "Commencer",
              end: "La fin",
              orderInput: "Ordre de perte",
              restart: "Redémarrer",
              pleaseConfigureTheSalesStageInformation:
                "Veuillez configurer les informations de phase de vente",
              reasonForDocumentInput: "Raison de la perte:",
              completeOrderInput: "Terminer un ordre de perte",
              pleaseSelectTheReason: "Veuillez choisir une raison",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "Les gestionnaires recommandent:",
              },
              index: {
                sessionDetails: "Détails de la session",
                customerDynamics: "Dynamique client",
                riskPoint: "Points de risque",
                followUpSuggestions: "Recommandations de suivi",
              },
              sessionDetails: {
                common: "Au total",
                currentlyViewingThe: "Voir actuellement le numéro",
                sessions: "Une séance",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "La phase actuelle est restée",
              day: "Jours",
            },
          },
          business: {
            index: {
              reEnableOpportunity: "Réactiver les opportunités commerciales",
            },
          },
          customerTag: { index: { label: "Étiquettes" } },
          moreCustomer: {
            index: {
              essentialInformation: "Informations de base",
              cancel: "Annulation",
              confirm: "Confirmation",
              customer: "Le client",
              currentFollowupStage: "Phase de suivi actuelle",
              telephone: "Téléphone",
              followUpDays: "Jours de suivi",
              day: "Jours",
              remarks: "Remarques",
              channelPreference: "Préférences de canal",
              hello: "Bonjour",
              customFields: "Champs personnalisés",
              opportunityAmount: "Montant des opportunités commerciales",
              focus: "Points d'attention",
              customerInformation: "Informations pour les clients",
              viewMoreCustomerInformation: "Voir plus d'informations clients",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Événements clés:" },
                row1: {
                  customerDynamics: "Dynamique client",
                  common: "Au total",
                  barDynamic: "Dynamique des barres",
                },
                row2: {
                  riskPoint: "Points de risque",
                  common: "Au total",
                  risks: "Risque de clause",
                },
                row3: {
                  followUpSuggestions: "Recommandations de suivi",
                  intelligentRecommendation: "Recommandations intelligentes",
                  strip: "Article",
                  managersSuggestATotalOf:
                    "Recommandations du gestionnaire total",
                  recommendedByManagers: "Recommandé par les gestionnaires",
                  creationTime: "Temps de création",
                  administratorSuggestions:
                    "Recommandations de l'Administrateur",
                  suggestionsForNewManagers:
                    "Suggestions de nouveaux gestionnaires",
                },
              },
            },
            index: { typeTime: "Type / heure" },
          },
          userInfo: {
            index: {
              personInCharge: "Responsable",
              recentFollower: "Suivi récent",
              startTime: "Le premier temps touché",
              followUpDays: "Jours de suivi",
              day: "Jours",
              remarks: "Remarques",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Membres de l'équipe",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Nombre total de membres apprenant les sessions d'autres personnes (déduplication), en consultant les détails de la session et en cliquant sur la lecture de l'enregistrement est considéré comme apprenant une fois",
          totalNumberOfStudies: "Total des études",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Nombre total de commentaires lancés par les membres, de réponses aux sessions d'autres personnes (déduplication)",
          totalNumberOfInteractions: "Total des interactions",
          learnTheSumOfConversationCallDuration:
            "Longue somme lors d'un appel conversationnel appris",
          lengthOfStudy: "Durée des études",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Nombre total d'appels à l'aide lancés par les membres pour les sessions d'autres personnes (déduplication)",
          totalNumberOfRequests: "Total des recours",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Nombre total de membres coachés à l'aide d'autres personnes (déduplication)",
          totalCounseling: "Total du coaching",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Nombre total de sessions de membres à d'autres ajoutées à la Bibliothèque de cas (dédupliquées)",
          addCase: "Ajouter un cas",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Nombre total d'abonnements de membres aux sessions d'autres personnes (déduplication)",
          subscribe: "S'abonner",
          totalNumberOfSessionsCommunicatedByMembers:
            "Nombre total de sessions communiquées par les membres",
          totalSessions: "Nombre total de sessions",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Le nombre total de sessions que les membres ont répliquées (déduplication), la consultation des détails de la session et le fait de cliquer sur la lecture de l'enregistrement sont considérés comme des réplications une fois",
          totalNumberOfDoubleDisk: "Nombre total de disques",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Nombre total de reprises / nombre total de sessions * 100%",
          proportionOfDoubleDisk: "Ratio de disque composé",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Sommation longue lors d'un appel de session à répétition",
          resumptionDuration: "Durée du disque double",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Nombre total d'appels à l'aide lancés par les membres pour leurs propres sessions (déduplication)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Nombre total de sessions de membres ayant reçu des commentaires (y compris des réponses) d'autres personnes",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Nombre total de sessions d'un membre ajoutées à la Bibliothèque de cas par d'autres (dédupliquées)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Nombre total de sessions d'un membre abonnées par d'autres (dédupliquées)",
          askForHelpInTheLastMonth: "Dernier mois d'aide",
          lastTimeForHelp: "Heure du dernier recours",
          operation: "Opérations",
          viewAllSessionsInTheLastMonth:
            "Voir toutes les sessions du dernier mois",
          allDepartments: "Tous les départements",
          reset: "Réinitialisation",
          query: "Enquête",
          altogether: "Au total",
          secondSession: "Sous - session",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Projets actuellement testés:",
            supervisionItem: "Articles de supervision",
            keyEvents: "Événements clés",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Veuillez cocher les éléments de supervision ou les événements clés à tester et assurez - vous qu'il y a du contenu d'appel dans la conversation simulée",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Analyse de la force d'exécution" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Processus 1",
              technologicalProcess: "Processus",
              newProcess: "Nouveau processus",
            },
          },
          index: {
            upload: "Télécharger",
            preservation: "Sauvegarder",
            newlyBuild: "Nouvelle construction",
            cancel: "Annulation",
            name: "Le nom",
            describe: "Description",
            type: "Type",
            classification: "Classification",
            content: "Contenu",
            enclosure: "Annexes",
            knowledgePointAssociation: "Association de points de connaissance",
            open: "Ouvrir",
            close: "Fermé",
            operator: "Opérateur",
            logic: "Logique",
            see: "Voir",
            edit: "Modifier",
          },
        },
        index: {
          name: "Le nom",
          describe: "Description",
          createdBy: "Créé par",
          type: "Type",
          lastUpdated: "Heure de la dernière mise à jour",
          numberOfReferences: "Nombre de citations",
          operation: "Opérations",
          see: "Voir",
          edit: "Modifier",
          delete: "Supprimer",
          categoryDeletedSuccessfully: "Catégorie supprimer avec succès",
          classificationOfKnowledgePoints:
            "Classification des points de connaissance",
          newlyBuild: "Nouvelle construction",
          knowledgeBase: "Base de connaissances",
          knowledgePoints: "Points de connaissance",
          technologicalProcess: "Processus",
          keyword: "Mots clés",
          batchExport: "Exportation par lots",
          batchDelete: "Suppression par lots",
          importProcess: "Processus d'importation",
          importKnowledgePoints: "Importer des points de connaissance",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Ajouter directement un point de connaissance s'il n'existe pas dans la base de connaissances, sauter s'il existe et ne pas mettre à jour",
          editKnowledgePointClassification:
            "Modifier la classification des points de connaissance",
          newKnowledgePointClassification:
            "Nouvelle classification des points de connaissance",
          categoryEditingSucceeded: "Catégories Edit success",
          newClassificationSucceeded: "Nouvelle classification réussie",
          classificationName: "Nom de la classification",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Supervision de l'exécution" },
      },
      topicCallout: {
        index: {
          whole: "Tous",
          date: "Date",
          dimensionStatus: "État d'étiquetage",
          callRecordID: "ID d'enregistrement d'appels",
          callRecordIDMustBeNumeric:
            "L'id de journal d'appels doit être numérique",
          strip: "Article",
          topicAnnotation: "Tags de sujet",
          annotated: "Déjà étiqueté",
          stripNotMarked: "Barre, non marquée",
          barInDimension: "Barre, dans la note",
          customer: "Le client",
          sale: "Vendre",
          common: "Au total",
          sessions: "Séance",
          currentTopic: "Sujets actuels:",
          staff: "Employés",
          topicOfConversation: "Sujets",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Opération réussie, la note a été réinitialisée",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Tous les enregistrements étiquetés de cette session de conversation seront vidés après la Réinitialisation, veuillez confirmer",
          resetAll: "Réinitialiser tout",
          pleaseSelectTheTopicToBeMarked:
            "Veuillez sélectionner un sujet à étiqueter",
          pleaseTickTheSentenceToBeMarked:
            "Veuillez cocher la phrase à marquer",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Opération réussie, la note est entrée en vigueur",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "La portée de cette note contient des messages sur d'autres sujets, appuyez sur OK pour couvrir",
          confirmDimensions: "Confirmer la note",
          talkTime: "Temps d'appel",
          callID: "Appel id",
        },
      },
      topicList: {
        index: {
          callID: "Id de l'appel",
          latestMarkedTime: "Dernier temps marqué",
          topicOfConversation: "Sujets",
          numberOfSentences: "Nombre de phrases",
          sentence: "Phrases",
          operation: "Opérations",
          see: "Voir",
          delete: "Supprimer",
          edit: "Modifier",
          newSubTopic: "Nouveau sous - thème",
          move: "Mobile",
          whole: "Tous",
          topicDeletedSuccessfully: "Supprimer le sujet avec succès",
          newlyBuild: "Nouvelle construction",
          topicList: "Liste des sujets",
          callIDMustBeNumeric: "L'id d'appel doit être numérique",
          leadInTopic: "Importer un sujet",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Lorsque le nom de la catégorie est répété, la catégorie de sujet d'origine n'est pas remplacée, l'élément est sauté directement et n'est pas importé",
          mobileClassification: "Classification mobile",
          editTopic: "Modifier le sujet",
          newTopic: "Nouveau sujet",
          topicName: "Nom du sujet",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Modification réussie",
            createdSuccessfully: "Créer un succès",
            modificationFailed: "La modification a échoué",
            creationFailed: "La création a échoué",
            monitoringName: "Nom de la surveillance",
            upToCharacters: "Jusqu'à 40 caractères",
            pleaseEnterAName: "Veuillez entrer un nom",
            monitoringIndicators: "Indicateurs de surveillance",
            sessionActivity: "Activités de session",
            conversationAction: "Action conversationnelle",
            keyEvents: "Événements clés",
            supervisionItems: "Articles de supervision",
            monitoringMembers: "Surveiller les membres",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Groupes recevant des interventions de mentorat, de formation ou de gestion",
            groupA: "Groupe a",
            pleaseSelectGroupAData:
              "Veuillez sélectionner les données du Groupe a",
            thereAreDuplicateUsersInGroupB:
              "Utilisateurs en double avec le Groupe B",
            groupARemarks: "Remarques du Groupe a",
            controlGroupGroupComparedWithGroupA:
              "Groupe témoin, Groupe comparé au Groupe a",
            groupB: "Groupe B",
            pleaseSelectGroupBData:
              "Veuillez sélectionner le Groupe de données B",
            thereAreDuplicateUsersInGroupA:
              "Utilisateurs en double avec le Groupe a",
            groupBRemarks: "Groupe B Remarques",
            monitoringPeriod: "Cycle de surveillance",
            startNode: "Démarrer le nœud",
            monitoringScope: "Portée de la surveillance",
            pleaseSelectMonitoringConditions:
              "Veuillez sélectionner les conditions de surveillance",
            pleaseEnterANumber: "Veuillez entrer un numéro",
            pleaseSelectATimePeriod: "Veuillez sélectionner une période",
            preservation: "Sauvegarder",
            cancel: "Annulation",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Supprimer avec succès",
            notStarted: "Pas commencé",
            haveInHand: "En cours",
            closed: "C'est terminé",
            newGroupTest: "Nouveau test de groupe",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Phase de vente",
        communicationStrategy1: "Stratégie de communication",
        riskAllocation: "Configuration des risques",
        businessConfiguration: "Configuration d'entreprise",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Traitement des objections",
                  productSellingPointScript: "Discours de vente de produits",
                  caseGrouping: "Groupe de cas",
                  pleaseSelectExcellentCases:
                    "Veuillez choisir un excellent cas",
                  pleaseSelectKnowledgePoints:
                    "Veuillez sélectionner un point de connaissance",
                  associativeLinks: "Liens associés",
                  jumpTo: "Sauter à",
                  jumpType: "Type de saut",
                  knowledgePoints: "Points de connaissance",
                  excellentCases: "Cas d'excellence",
                  excellentScript: "Excellent discours",
                  pleaseSelectASingleKnowledgePoint:
                    "Veuillez sélectionner un seul point de connaissance",
                },
              },
            },
            index: {
              name: "Le nom",
              pushText: "Push texte",
              enableStatus: "Activer le statut",
              triggerRules: "Règles de déclenchement",
              communicationStrategy: "Stratégie de communication",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping:
                "Regroupement des stratégies de communication",
              name: "Le nom",
              pushText: "Push texte",
              effectiveness: "Efficacité",
              openSuccessfully: "Ouvrir avec succès",
              closeSuccessfully: "Fermer avec succès",
              operationFailed: "L'opération a échoué",
              operation: "Opérations",
              edit: "Modifier",
              delete: "Supprimer",
              newlyBuild: "Nouvelle construction",
              communicationStrategy: "Stratégie de communication",
              editGroup: "Modifier le Groupe",
              groupName: "Nom du Groupe",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Toutes les sessions",
                  recentDays: "Jours récents",
                  recentSessions: "Sessions récentes",
                  scopeOfApplication: "Champ d'application:",
                  day: "Jours",
                  through: "Via",
                },
              },
            },
            index: {
              describe: "Description",
              applicableCustomers: "Clients applicables",
              enableStatus: "Activer le statut",
              triggerRules: "Règles de déclenchement",
              riskRules: "Les règles du risque",
            },
          },
          list: {
            index: {
              riskAllocation: "Configuration des risques",
              keyWord: "Mots clés",
              newRiskRule: "Nouvelle règle de risque",
              riskRuleName: "Nom de la règle de risque",
              describe: "Description",
              state: "Statut",
              openSuccessfully: "Ouvrir avec succès",
              closeSuccessfully: "Fermer avec succès",
              operationFailed: "L'opération a échoué",
              operation: "Opérations",
              edit: "Modifier",
              delete: "Supprimer",
              deletionSucceeded: "Supprimer avec succès",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Ordre de perte" },
              endReasonList: {
                addEndReason: "Ajouter une raison de fin",
                enterEndReason: "Entrez la raison de la fin",
              },
              stageList: {
                keyEvents: "Événements clés",
                waysideData: "Données sur la route",
                includeAny: "Contient n'importe quel",
                includeAll: "Contient tout",
                pleaseEnterTheStageName: "Veuillez entrer le nom de la phase",
                setStageRules: "Définir les règles de phase",
                stageName: "Nom de la phase",
                operator: "Opérateur",
                logic: "Logique",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Le format d'entrée du champ timetype est: 2022 - 12 - 31 00: 00: 00",
              requestFailedPleaseTryAgainLater:
                "La demande a échoué, Veuillez réessayer plus tard",
              salesStage: "Phase de vente",
              essentialInformation: "Informations de base",
              name: "Le nom",
              applicableDepartments: "Département applicable",
              applicableToOthers: "Appliquer les autres",
              manualChangePhase: "Phase de changement manuel",
              enableStatus: "Activer le statut",
              describe: "Description",
              opportunityStageSettings:
                "Configuration de la phase d'opportunité",
              stageSettings: "Configuration des phases",
              aliasSettings: "Paramètres d'alias",
              closeOpportunity: "Fin des opportunités commerciales",
              newSuccessfully: "Nouvelle construction réussie",
              newFailed: "La nouvelle construction a échoué",
              savingSucceeded: "Sauvegarder le succès",
              saveFailed: "Échec de la sauvegarde",
            },
          },
          list: {
            index: {
              salesStage: "Phase de vente",
              keyWord: "Mots clés",
              newSalesStage: "Nouvelle phase de vente",
              salesStageName: "Nom de la phase de vente",
              describe: "Description",
              state: "Statut",
              openSuccessfully: "Ouvrir avec succès",
              closeSuccessfully: "Fermer avec succès",
              operationFailed: "L'opération a échoué",
              operation: "Opérations",
              edit: "Modifier",
              delete: "Supprimer",
              deletionSucceeded: "Supprimer avec succès",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Génération de grands modèles",
            conversationInsights: "Insights conversationnels",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Généré automatiquement" },
              },
              hooks: {
                conversationSummary: "Résumé de la session",
                conversationProcess: "Processus de session",
                opportunityPoints: "Points d'opportunité",
              },
              prompt: {
                index: {
                  edit: "Modifier",
                  restoreDefault: "Restaurer par défaut",
                  cancel: "Annulation",
                  confirm: "Confirmation",
                },
              },
            },
            index: {
              saveSuccessful: "Sauvegarder le succès",
              hintLanguage: "Langue de conseils",
              preserve: "Sauvegarder",
              cancel: "Annulation",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Génération de grands modèles",
        salesStage: "Phase de vente",
        communicationStrategy: "Stratégie de communication",
        riskAllocation: "Configuration des risques",
        businessConfiguration: "Configuration d'entreprise",
      },
      salesLearningCenter: {
        knowledgeBase: "Base de connaissances",
        speechCorpus: "Bibliothèque de mots",
        learningCenter: "Centre d'apprentissage",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Informations pour les clients" },
          },
        },
        index: { otherInformation: "Autres informations" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Importer" } } },
        hooks: {
          index: {
            all: "Tous",
            intelligentModel: "Modèle intelligent",
            smartRules: "Règles intelligentes",
            manualScoring: "Score manuel",
            enable: "Activer",
            notEnabled: "Non activé",
            voiceCall: "Appels vocaux",
            textDialogue: "Dialogue de texte",
            ecommerceTextSession: "Session de texte de commerce électronique",
            workOrder: "Feuille de travail",
          },
        },
        index: {
          ruleName: "Nom de la règle",
          ruleType: "Type de règle",
          pleaseSelect: "Veuillez sélectionner",
          ruleStatus: "État des règles",
          dataSourceType: "Type de source de données",
          voiceCall: "Appels vocaux",
          textDialogue: "Dialogue de texte",
          ecommerceTextSession: "Session de texte de commerce électronique",
          workOrder: "Feuille de travail",
          status: "État",
          operation: "Opération",
          edit: "Éditeur",
          ruleTemplate: "Modèle de règles",
        },
      },
      analysisReportTitle: "Analyse des rapports",
      ruleTest: "Test des règles",
      intelligentTags: {
        labelDisplayQuantity: "Nombre d'affichage des étiquettes",
        unlimited: "Pas limité",
        ignoringTagLibraries: "Ignorer la Bibliothèque d'étiquettes",
        automaticallyGenerateLabels: "Génération automatique d'étiquettes",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'Une fois activée, une étiquette est générée automatiquement sur la base d\'une session de paire de modèles volumineux, et les étiquettes générées sont classées par défaut sous "Non classé".',
        defaultValidity: "Validité par défaut",
        supervisionItem: "Articles de supervision",
        customerLabel: "Étiquette client",
        newSupervisionItemRule: "Nouvelle règle de supervision",
        editSupervisionItemRule: "Modifier les règles de supervision",
        newCustomerLabel: "Nouvelle étiquette client",
        editCustomerLabel: "Modifier les tags clients",
        newSessionLabel: "Nouvelle étiquette de session",
        editSessionLabel: "Modifier les étiquettes de session",
        newKeyEvent: "Nouvel événement clé",
        editKeyEvents: "Modifier les événements clés",
        detail: {
          template: { nameIsARequiredField: "Nom est un champ obligatoire" },
          formItemComponent: {
            largeModelExtensionLabel: "Étiquette d'extension grand modèle",
            sessionRecordID: "ID d'enregistrement de session",
            sessionTime: "Temps de la session",
            smartTags: "Étiquettes intelligentes",
            viewDetails: "Voir les détails",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Les données enregistrées par cette session seront vidées après la suppression, confirmez - vous la suppression?",
            tagData: "Données de marquage",
            batchDeletion: "Suppression par lots",
          },
        },
        template: {
          advancedFiltering: "Filtrage avancé",
          dataType: "Type de données",
          filterCriteria: "Filtrer les conditions",
          edit: "Modifier",
          createANewSubcategory: "Nouvelle sous - Classification",
          delete: "Supprimer",
          nameLengthCannotExceed: "La longueur du nom ne doit pas dépasser 40",
        },
        component: {
          modelExpansion: "Extension du modèle",
          enable: "Activer",
          notEnabled: "Non activé",
          standardScreening: "Filtre standard",
          advancedFiltering: "Filtrage avancé",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Mots clés",
              sessionLabel: "Étiquettes de session",
              keyEvents: "Événements clés",
              matchAny: "Match any",
              matchAll: "Match tous",
              beEqualTo: "égale à",
              includeAll: "Contient tout",
              includeAny: "Contient n'importe quel",
              advancedConditions: "Conditions avancées",
              smartTag: "Étiquettes intelligentes",
              smartTagI: "Smart label un",
              smartTagII: "Étiquette intelligente II",
            },
            template: {
              pleaseSelectSmartTag: "Veuillez sélectionner Smart label",
              pleaseSelectASessionLabel:
                "Veuillez sélectionner une étiquette de session",
              pleaseSelectAKeyEvent: "Veuillez sélectionner un événement clé",
              pleaseSelectAdvancedCondition:
                "Veuillez sélectionner une condition avancée",
              newAdvancedFilter: "Nouveau filtre avancé",
              moveUp: "Déplacement vers le Haut",
              moveDown: "Descendre",
              moveLeft: "Déplacement à gauche",
              shiftRight: "Déplacement à droite",
            },
          },
          component: {
            businessAnalysispng: "Analyse d'entreprise.png",
            savedSuccessfully: "Sauvegarder le succès",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Permanent et efficace",
            permanentlyInvalid: "Invalidité permanente",
            customValidity: "Période de validité personnalisée",
            termOfValidity: "Période de validité",
            name: "Le nom",
            explain: "Description",
            state: "Statut",
            classification: "Classification",
            smartRecommendation: "Recommandations intelligentes",
            preconditions: "Conditions préalables",
            SetPreconditionsForThisSupervisionItem:
              "1. Définissez les conditions préalables pour la surveillance de cette section:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              "Lorsque les conditions préalables sont remplies, c'est - à - dire « la surveillance doit être effectuée», la surveillance de cet article est détectée;",
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Lorsque les conditions préalables ne sont pas remplies, la surveillance de l'article n'est pas détectée;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Aucune condition préalable n'est définie, c'est - à - dire que toutes les sessions sont détectées par défaut",
            have: "Il y a",
            nothing: "Aucun",
            preconditionRule: "Règles de pré - condition",
            preconditionLogic: "Logique des conditions préalables",
          },
          recommendation: {
            caseGrouping: "Groupe de cas",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Recommander intelligemment d'excellents discours et d'excellents cas lorsque l'exécution du personnel n'est pas en place",
            smartRecommendation: "Recommandations intelligentes",
            open: "Ouvrir",
            close: "Fermé",
            whenThisSupervisionItem: "Lorsque cette surveillance",
            implement: "Exécution",
            unexecuted: "Non exécuté",
            when: "Lorsque:",
            recommendedKnowledgePoints: "Points de connaissance recommandés",
            caseRecommendation: "Recommandation de cas",
          },
        },
        index: {
          determine: "Déterminer",
          sort: "Trier",
          sortSucceeded: "Trier avec succès",
          supervisionItemClassification:
            "Classification des éléments de supervision",
          pressEnterToCreateANew: "Appuyez sur retour nouveau",
          nameCannotBeEmpty: "Le nom ne peut pas être vide",
          newlyBuild: "Nouvelle construction",
          name: "Le nom",
          explain: "Description",
          validStatus: "État de validité",
          permanentlyValid: "Permanent et efficace",
          permanentlyInvalid: "Invalidité permanente",
          operation: "Opérations",
          edit: "Modifier",
          batchDelete: "Suppression par lots",
          newSupervisionItem: "Nouvelle Supervision",
          keyWord: "Mots clés",
          delete: "Supprimer",
          deleteSucceeded: "Supprimer avec succès",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Les données ne seront pas récupérées après la suppression, confirmez - vous la suppression?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Étiquettes intelligentes",
              beEqualTo: "égale à",
              includeAll: "Contient tout",
              includeAny: "Contient n'importe quel",
              matchAny: "Match any",
              matchAll: "Match tous",
              smartTagI: "Smart label un",
              smartTagII: "Étiquette intelligente II",
            },
            template: {
              pleaseSelectASmartTag: "Veuillez sélectionner Smart label",
              pleaseSelectAdvancedConditions:
                "Veuillez sélectionner une condition avancée",
              newAdvancedFilter: "Nouveau filtre avancé",
              rename: "Renommer",
              moveUp: "Déplacement vers le Haut",
              moveDown: "Descendre",
              shiftLeft: "Déplacement à gauche",
              shiftRight: "Déplacement à droite",
            },
          },
        },
        template: {
          delete: "Supprimer",
          labelFiltering: "Filtrer par Tags:",
          satisfyAny: "Satisfaire tout arbitraire",
          meetAll: "Satisfaire tout",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Classification de niveau 2 Étiquettes nombre de conversations ≥ classification de niveau 1, une session peut contenir plusieurs étiquettes",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logique de calcul de la variation d'un mois à l'autre: (volume de conversation de la période sélectionnée - volume de conversation de la période précédente immédiatement à côté) / volume de conversation de la période précédente",
          proportionOfPrimaryClassification:
            "Classement de niveau 1 en pourcentage",
          saveAs: "Enregistrer en tant que",
          cover: "Couverture",
          preservation: "Sauvegarder",
          qualityInspectionTask: "Tâches analytiques:",
          query: "Enquête",
          changeFromMonthToMonth: "Changement de situation d'un mois à l'autre",
          tagRanking: "Rangée d'étiquettes",
          proportion: "Proportion de cas",
        },
        component: {
          numberOfConversations: "Nombre de conversations",
          operation: "Opérations",
          businessAnalysispng: "Analyse d'entreprise.png",
          savingSucceeded: "Sauvegarder le succès",
          deletionSucceeded: "Supprimer avec succès",
          sequence: "Séquence",
          firstLevelClassification: "Classification de premier niveau",
          ringRatioChange: "Variation du rapport cyclique",
          classClassification: "Classification des niveaux",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Confirmer la suppression du document et de son contenu extrait?",
            addKnowledgeBase: "Ajouter une base de connaissances",
            uploadDocument: "Télécharger un document",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Supporte les fichiers au format Word, PDF, ppt, Excel, txt",
            taskName: "Nom de la tâche",
            extractConversationDate: "Tirer la date de la conversation",
            pleaseSelectAConversationDate:
              "Veuillez sélectionner une date de conversation",
            dataSource: "Sources de données",
            dataType: "Type de données",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Exporter avec succès",
              problem: "Le problème",
              answer: "La réponse",
              addToKnowledgeBase: "Ajouter à la base de connaissances",
              pleaseConfirmWhetherToDeleteIt:
                "Veuillez déterminer si Supprimer",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Ajouter à la base de connaissances",
              selectClassification: "Choisir une classification",
              pleaseSelectAKnowledgeBaseClassification:
                "Veuillez sélectionner une classification de base de connaissances",
            },
          },
        },
        index: {
          intelligentExtraction: "Extraction intelligente",
          documentExtraction: "Extraction de documents",
          dialogueExtraction: "Tirage du dialogue",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Opération réussie",
          labelIgnored: "Étiquettes ignorées",
          add: "Ajouter",
          areYouSureToDeleteIt: "Sûr de supprimer?",
          batchFilterLabels: "Étiquettes de filtrage par lots",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Contrôle de qualité intelligent",
        savedSuccessfully: "Sauvegarder le succès",
        logoSettings: "Paramètres du logo",
        default: "Par défaut",
        custom: "Personnalisation",
        title: "Titre",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Nom du titre affiché par le système, recommandé en 6 mots.",
        logoImageUpload: "LOGO images télécharger",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Veuillez sélectionner une image dont la taille ne dépasse pas 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Pour garantir l'effet de navigation du logo, télécharger des suggestions d'images:",
        Size5050Pixels: "1. Taille: 50 * 50 pixels;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Format: image avec fond transparent, PNG ou JPG, logo graphique utilise blanc",
        browserLabelIcon: "Icône d'étiquette de navigateur",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Pour garantir l'effet de navigation de l'étiquette, télécharger des suggestions d'images:",
        Size3232Pixels: "1. Taille: 32 * 32 pixels;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Format: image avec fond transparent, PNG ou JPG",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Contrôle de qualité en cours, plus tard...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "En 2015, l'immobilier dans notre pays est arrivé à un moment crucial de la survie, un grand nombre de maisons ont été laissées sans enquête et les gens du peuple ne voulaient pas acheter. Hangzhou est tombé en dessous de 8000 à un moment donné, il y a des développeurs qui cassent la plaque pour l'expédition, Shenzhen était encore à l'époque autour de 2W - 3W pour la consolidation, la maison de la ville de première ligne est également précaire",
        xiaoAn: "Petite Ann",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload ces barrières de mémoire, appelées barrières de mémoire JSR, sont au niveau JVM et sont implémentées de deux manières sous le système d'exploitation: 1 est le bus de verrouillage et deux métalangages de barrière de mémoire tels que sfance (barrière d'écriture) mfence (barrière totale) ifence (barrière de lecture). La couche inférieure de la machine virtuelle hotspots utilise une instruction assembleur: lock addl, lock une opération vide, c'est - à - dire Lock bus, ce qui est fait parce que certains systèmes d'exploitation ne prennent pas en charge le métalangage de barrière de mémoire.",
        xiaobei: "Le petit nord",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Essayez de mariner la viande plusieurs fois, ajouter de l'alcool après avoir sauté les plats, la viande et le semblable, une odeur nauséabonde acide! Plus de vin à partir de viande marinée",
        xiaodong: "Le petit est",
        xiaoxi: "Petit Ouest",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Essayez de mariner la viande plusieurs fois, ajouter de l'alcool après avoir sauté les plats, la viande et le semblable, une odeur nauséabonde acide! Plus de vin à partir de viande marinée",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Gestion des avis de commerce électronique",
        socialMediaReviewManagement:
          "Gestion des commentaires sur les médias sociaux",
        customDataSource: "Personnaliser les données d'origine",
        qualityInspectionResults: "Résultats d'inspection de la qualité",
        accountManagement: "Gestion des comptes",
        employeeProfile: "Portrait des employés",
        intelligentAnalysis: "Analyse intelligente",
        customKanban: "Kanban personnalisé",
        dataScreening: "Aperçu général des données",
        learningRecord: "Record d'apprentissage",
        dataAnalysis: "Analyse des données",
        scoringManagement: "Gestion des scores",
        businessConfiguration: "Configuration d'entreprise",
        manualReview: "Révision manuelle",
        taskList: "Liste des tâches",
        videoAnalysis: "Analyse vidéo",
        overview: "Aperçu général",
        personalSignage: "Kanban personnel",
        learningCenter: "Centre d'apprentissage",
        wrongQuestionSet: "Ensemble de questions erronées",
        abilityImprovement: "Augmentation des capacités",
        close: "Fermé",
        switchingSystemApplications: "Application du système de commutation",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "La souris se déplace ici pour rétablir le menu de commutation d'application, cliquez sur l'élément de menu pour sauter à différentes applications",
        teamAnalysis: "Analyse des équipes",
        semanticIntelligence: "Intelligence sémantique",
        permissionManagement: "Gestion des droits",
        dataConnection: "Amarrage des données",
        courseManagement: "Gestion des cours",
        labelManagement: "Gestion des étiquettes",
        curriculum: "Centre de cours",
        courseConfiguration: "Configuration du cours",
        task: "Mes missions",
        historicalLearningData: "Historique",
        taskCenter: "Gestion des tâches",
        knowledgeBase: "Base de connaissances",
        ecommerceSessionManagement: "Gestion des sessions e - commerce",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Appliquer la commutation",
          descriptionOfIntelligentQualityInspectionSystem:
            "Description du système intelligent de contrôle qualité",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Filtre de session de commerce électronique",
        allEcommerceSessions: "Toutes les sessions e - commerce",
        ecommerceSessionManagement: "Gestion des sessions e - commerce",
      },
    },
    analysisEnable: {
      bestPractices: "Meilleures pratiques",
      behaviorAnalysis: "Analyse du comportement",
      teamAnalysis: "Analyse des équipes",
      executiveSupervision: "Supervision de l'exécution",
      executiveForceAnalysis: "Analyse de la force d'exécution",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Évaluation:",
          startTime: "Heure de début",
          workOrderNo: "Numéro de l'ordre de travail",
          customerServiceHasReadNotRead: "Service client Lu / non lu",
          canYouAppeal: "Peut - on porter plainte",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Remarques",
          addFriendTime: "Ajouter un ami Time",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Commencer à effectuer le contrôle de requalification",
          callID: "Id de l'appel",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Nouvelle fonctionnalité! Cliquez sur le bouton "Quick Check" et vous passerez automatiquement à la page suivante après avoir soumis avec succès.',
        },
      },
      template: {
        deleteRecord: "Supprimer un enregistrement",
        areYouSureToDeleteIt: "Sûr de supprimer?",
        confirm: "Déterminer",
        reIdentification: "Ré - identification",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "La ré - identification consomme du temps de transcrite, faites preuve de prudence",
        determine: "Déterminer",
        executionSuccessful: "Exécution réussie",
      },
      component: {
        pleaseSelectData: "Veuillez sélectionner les données",
        operationSuccessful: "Opération réussie",
        startPerformingReQualityInspection:
          "Commencer à effectuer le contrôle de requalification",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Télécharger le nom du fichier",
        analysisTask: "Tâches analytiques",
        analyzeProgress: "Analyser les progrès",
        analysisSucceeded: "Analyser le succès",
        analysisFailed: "L'analyse échoue",
        localDataUpload: "Téléchargement de données locales",
        recordingUpload: "Téléchargement d'enregistrements",
        textUpload: "Téléchargement de texte",
        uploadTime: "Temps de téléchargement",
        taskName: "Nom de la tâche",
        operator: "Opérateur",
        qualityInspectionProgress: "Progrès de l'inspection de qualité",
        whole: "Tous",
        haveInHand: "En cours",
        completed: "Terminé",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Exportez - vous tous les enregistrements de données terminés selon les critères de filtrage actuels?",
        upload: "Télécharger",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Remplissez le nom",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Remplissez un nom tel que type / collection / but de cette importation de données",
            default: "Par défaut",
            jDCOM: "Jingdong",
            dataType: "Type de données",
            selectAnalysisTask: "Choisir une tâche d'analyse",
            uploadFiles: "Télécharger des fichiers",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Téléchargez le modèle Excel, remplissez - le par Format:",
            template: "Modèle",
            SupportUpTo10000PiecesOfData:
              "2. Support maximum de 10 000 données",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Prise en charge du téléchargement des données de conversation exportées par jd.com, fichier.Txt, taille de fichier Max 100m",
            alongTheWayDataTemplate: "Modèle de données sur la route",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Contrôle de qualité du texte, veuillez patienter",
            textQualityInspectionCompleted: "Contrôle qualité du texte terminé",
          },
        },
        template: {
          startAnalysis: "Commencer l'analyse",
          pleaseUploadTheFile: "Veuillez télécharger le fichier",
          localDataUpload: "Téléchargement de données locales",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            'Nouvelle fonctionnalité! Cliquez sur le bouton "réviser la soumission" et une fois la soumission réussie, vous passerez automatiquement à la page suivante ~ ~ ~',
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Autorisation des tâches d'inspection de la qualité",
            edit: "Modifier",
            iParticipated: "Je participe à",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Le rôle peut voir les données sous le Département sélectionné",
            dataPermissions: "Droits sur les données",
            selectDepartment: "Choisir un département",
            edit: "Modifier",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Commencer à effectuer le contrôle de requalification",
          callID: "Id de l'appel",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Nouvelle fonctionnalité! Cliquez sur les boutons "soumission par tirage au sort" et "vérification rapide", et une fois la soumission réussie, vous passerez automatiquement à la page suivante.',
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Règles" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Gestion des ordres de travail",
          workOrderDetails: "Détails des ordres de travail",
        },
      },
      template: {
        workOrderFilter: "Filtre des ordres de travail",
        allWorkOrders: "Tous les ordres de travail",
        workOrderManagement: "Gestion des ordres de travail",
      },
    },
    trainingCenter: {
      topicAnnotation: "Tags de sujet",
      topicList: "Liste des sujets",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Types de rôles",
              keyWord: "Mots clés",
              pleaseEnterKeywordSearch:
                "Veuillez entrer un mot - clé recherche",
              query: "Enquête",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Cette fonctionnalité n'est pas encore ouverte, contactez l'administrateur système si nécessaire",
          pleaseSelectAQuestion: "Veuillez sélectionner une question!",
          recommendedSimilarSentencesLargeModel:
            "Phrases similaires recommandées (grand modèle)",
          selectAll: "Tout sélectionner",
          add: "Ajouter",
          changeBatch: "Changer un lot",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Heures de travail des entreprises",
      localDataUpload: "Téléchargement de données locales",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Selon les droits actuels de l'utilisateur, assembler la liste des menus",
      sessionSettings: "Paramètres de session",
      logoSettings: "Paramètres du logo",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Bienvenue à revenir! Veuillez entrer vos coordonnées",
        mailbox: "Boîte aux lettres",
        password: "Mot de passe",
        signIn: "Se connecter",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin:
            "L'IP actuelle n'autorise pas les atterrissages",
          welcomeToLogin: "Bienvenue login",
          pursuingExcellenceAndLeadingChange:
            "Viser l'excellence, mener le changement",
          intelligentPlatform: "Plateforme intelligente",
        },
        virtualLogin: { index: { pleaseSelect: "Veuillez sélectionner" } },
      },
      oauth: {
        illegalCall: "Appel illégal",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Bienvenue à revenir! Cliquez sur le bouton ci - dessous pour passer à la plate - forme d'authentification d'identité",
        loggingIn: "Dans le login...",
        unifiedIdentityAuthenticationLogin: "Authentification unifiée login",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Tests de règles: certaines règles ne peuvent pas utiliser les tests de règles, tels que le volume de la vitesse de la parole, les données en cours de route, etc.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "En savoir plus",
          enteringTheSystem: "Accès au système",
        },
      },
      index: {
        cancel: "Annulation",
        enteringTheSystem: "Accès au système",
        close: "Fermé",
        enterSystemApplication: "Entrer dans l'application système",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Cliquez sur "entrer dans le système" pour accéder aux applications système activées',
      },
    },
    permissionManagement: {
      employeeManagement: "Gestion du personnel",
      roleManagement: "Gestion des rôles",
      permissionManagement: "Gestion des droits",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "égale à",
            contain: "Contient",
            doesNotContain: "Ne contient pas",
            applicationSystem: "Système d'application",
            intelligentQualityInspection:
              "Contrôle de qualité intelligent / analyse de session intelligente",
            salesEmpowerment: "Facilitation des ventes",
            dataSourceType: "Type de source de données",
            filterCriteria: "Filtrer les conditions",
          },
        },
        index: {
          dataConnection: "Amarrage des données",
          savingSucceeded: "Sauvegarder le succès",
          dataIsolation: "Isolation des données",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Une fois activé, vous pouvez définir la plage de données pour chaque appel d'accès au système d'application, session Wechat d'entreprise. L'interrupteur n'est pas activé, chaque système accède à la totalité des données",
          accessRules: "Règles d'accès",
          preservation: "Sauvegarder",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Nom de l'application",
            accessRights: "Droits d'accès",
            inAppPermissionSettings:
              "Paramètres d'autorisations dans l'application",
            setUp: "Paramètres",
            applicationAccess: "Appliquer les droits d'accès",
            newRole: "Ajouter un rôle",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Détails de la session" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Détails de la session",
          caseDetails: "Détails du cas",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Employés visibles",
            newCourse: "Nouveau cours",
            editCourse: "Modifier le cours",
            courseName: "Nom du cours",
            courseIntroduction: "Présentation du cours",
            courseType: "Types de cours",
            courseClassification: "Classification des cours",
            courseCover: "Couverture du cours",
            learningContent: "Contenu de l'apprentissage",
            uploadLearningMaterials: "Télécharger du matériel d'apprentissage",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Test",
              preservation: "Sauvegarder",
              cancel: "Annulation",
              editNodeName: "Modifier le nom du nœud",
              determine: "Déterminer",
              individual: "Une",
              of: ", au total",
              branch: "Points",
            },
            configDrawerComponents: {
              pPTParsing: "Résolution ppt...",
              uploadSuccessful: "Téléchargement réussi",
              uploadAttachments: "Télécharger des pièces jointes",
              pPTParsingPleaseWait: "Résolution ppt, plus tard",
            },
            customerPortraitConfigDrawer: {
              cancel: "Annulation",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Impossible de récupérer après la suppression, êtes - vous sûr de vouloir supprimer ce portrait?",
              delete: "Supprimer",
              determine: "Déterminer",
              customerPortrait: "Portrait du client",
              bindSelectedCharacterPortrait:
                "Binding sélectionner un personnage portrait",
              customerProfileDetails: "Détails du portrait du client",
              createANewCustomerProfile: "Nouveau portrait client",
            },
            flow: {
              sidebar: { node: "Noeuds" },
              useControlButtons: {
                cancellingPleaseWait: "Révocation, plus tard...",
                redoInProgressPleaseWait:
                  "Refaire plus tard s'il vous plaît...",
                automaticOrganization: "Organiser automatiquement",
                canvasAdaptation: "Adaptation de la toile",
                delete: "Supprimer",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Les nœuds ne peuvent pas se connecter à eux - mêmes",
              },
            },
            globalConfigDrawer: {
              cancel: "Annulation",
              determine: "Déterminer",
              globalConfiguration: "Configuration globale",
              sessionDetection: "Détection de session",
              reciprocalRule: "Règles d'interaction",
              robotVoice: "Tonalité Robot",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Paramètres de délai d'expiration du nœud cadet sans réponse",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Temps de silence maximal autorisé sans réponse de l'apprenant (s)",
              promptScriptForFailureToAnswerOverTime:
                "Délai d'attente non répondu",
              addScript: "Ajouter des mots",
            },
            robotTimbreForm: {
              aixianatural: "Aixia (nature)",
              zhishaChat: "Sacha (chat)",
              zhiqiangentle: "Qian (modéré)",
              zhimiEmotion: "Tshimi (émotion)",
              aiShuoNatural: "Aisus (naturel)",
              zhishuoChat: "Know (chat)",
              aiHaoAffinity: "AiHao (affinité)",
              zhixiangEmotion: "Knoxiang (émotion)",
              robotVoice: "Tonalité Robot",
              schoolboy: "Garçons",
              girlStudent: "Filles",
              volume: "Volume",
              tone: "Tonalité",
              speechSpeed: "Vitesse de la parole",
              test: "Test",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Bonjour, je suis Smart Training coach et j'ai hâte de communiquer avec vous",
            },
            sessionDetectionForm: {
              expressionDetection: "Détection d'expression",
              excessiveDetectionOfModalParticles:
                "Détection excessive de mots de ton",
              theNumberOfRepetitionsIs: "Le nombre de répétitions est",
              minus: "La fois suivante, moins",
              appear: "Apparaître",
              subtract: "Une fois, moins",
              branch: "Points",
              addRules: "Ajouter des règles",
              includingNegativeAngryComplaining:
                "(y compris la négativité, la colère, les plaintes)",
              scorePointsDisplay: "Affichage des points de score",
              reminderOfSpeakingTooFasttooSlow:
                "Rappel trop rapide / trop lent",
              scriptViolationReminder: "Rappel des violations de la parole",
              emotionalReminder: "Rappels émotionnels",
              speechSpeedDetection: "Détection de la vitesse de la parole",
              normalSpeechSpeedScore: "Score normal de vitesse vocale",
              speakTooFast: "Vitesse de parole trop rapide",
              everyMinuteGreaterThan: "Chaque minute est supérieure à",
              aWordAppears: "Un mot, apparaît",
              speakTooSlowly: "Vitesse de parole trop lente",
              lessThanPerMinute: "Moins par minute",
              ignoreLessThan: "Ignorer moins de",
              aSentenceOfWords: "Phrases de mots",
              emotionalDetection: "Détection des émotions",
              emotionalNormalScore: "Score normal émotionnel",
              negativeEmotions: "émotions négatives",
              illegalWordDetection: "Détection de mots offensants",
              scriptViolationConfiguration:
                "Configuration des violations vocales",
              realTimePromptOnTheFrontEnd: "Conseils en temps réel Front End",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA:
                    "Base de connaissances questions et réponses",
                  qAMethod: "Méthode de questions et réponses",
                  numberOfQuestionsAndAnswers:
                    "Nombre de questions et réponses",
                  scoringRules: "Règles de score",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Toute question est correcte, il faut",
                  branch: "Points",
                  successfullySaved: "Sauvegarder le succès",
                },
                pptNode: {
                  verificationPassed: "Contrôle passé",
                  upload: "Télécharger",
                  supportPptPdfFormatFiles:
                    "Prise en charge des fichiers au format ppt, PDF",
                  uploadSlide: "Télécharger des diapositives",
                  assessmentMethod: "Mode d'évaluation",
                  associateSlide: "Diapositives associées",
                  standardScript: "Langage standard",
                  successfullySaved: "Sauvegarder le succès",
                },
                robotNode: {
                  robotScript: "Langage robotique",
                  randomScript: "Parole aléatoire",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Choisissez une phraséologie de sémantique similaire, le robot dira l'une de ces phrases au hasard",
                  customerPortrait: "Portrait du client",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Lorsque le portrait du client est cohérent, le robot parle",
                  setAsDefaultScript: "Faire la parole par défaut",
                  yes: "Oui",
                  no: "Non",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Cette procédure se produit lorsque le robot ne frappe aucune étiquette utilisateur",
                  successfullySaved: "Sauvegarder le succès",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Ce nœud de Branche a déjà un mot par défaut, Remplacez - vous le mot par défaut par le mot courant?",
                },
                startNode: {
                  paragon: "Parler blanc à côté",
                  successfullySaved: "Sauvegarder le succès",
                },
                userNode: {
                  verificationPassed: "Contrôle passé",
                  standardScript: "Langage standard",
                  successfullySaved: "Sauvegarder le succès",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Veuillez affiner la configuration",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Veuillez entrer un exemple de conversation",
                  dialogueExample: "Exemples de conversations",
                  generateConversationExamples:
                    "Générer des exemples de conversations",
                  mattersNeedingAttention: "Précautions",
                  pleaseEnterThePromptLanguage: "Veuillez entrer une invite",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Scène de dialogue",
                  simulateCharacter: "Rôle simulé",
                  accompanyingTrainees: "Objet d'entraînement",
                  selectFromTemplateLibrary:
                    "Choisissez à partir de la Bibliothèque de modèles",
                  templateLibrary: "Bibliothèque de modèles",
                  role: "Rôle",
                  scene: "Scènes",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Fenêtre de test de diapositives []",
              clearContent: "Effacer le contenu",
              close: "Fermé",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "Process de génération ai",
                scriptStyle: "Style de parole",
                theMostConversationRounds:
                  "Le plus grand nombre de tours de conversation",
                copyConversation: "Copier la conversation",
                application: "Application",
                regenerate: "Régénérer",
                startGenerating: "Commencer à générer",
                pleaseGenerateAProcess: "Veuillez générer le processus",
                copySuccessful: "Copie réussie",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Représentant des ventes",
                    customerServicePersonnel:
                      "Personnel du service à la clientèle",
                    storeCounter: "Armoire de magasin",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Génération de discours en texte intégral",
                speaker: "Orateur",
                scriptStyle: "Style de parole",
                copyConversation: "Copier la conversation",
                application: "Application",
                regenerate: "Régénérer",
                startGenerating: "Commencer à générer",
                pleaseGenerateAProcess: "Veuillez générer le processus",
                operationSuccessful: "Opération réussie",
                copySuccessful: "Copie réussie",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Retouche vocale" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "La parole ne peut pas être vide!",
              copySuccessful: "Copie réussie",
              cancel: "Annulation",
              copy: "Copier",
              replaceTheOriginalText: "Remplacer l'original",
              aiGeneration: "Ai généré",
              aiPolishing: "Ai retouche",
              currentStyle: "Le style actuel",
              rigorous: "Rigueur",
              mild: "Doux",
              lively: "Animé",
              simplicity: "Simplicité",
              pleaseEnterTheSpeakerRole: "Veuillez entrer le rôle de speaker",
              startGenerating: "Commencer à générer",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "La parole ne peut pas être vide!",
              copySuccessful: "Copie réussie",
              aiRewrite: "Ai réécrit",
              cancel: "Annulation",
              copy: "Copier",
              replaceTheOriginalText: "Remplacer l'original",
              currentStyle: "Le style actuel",
              rigorous: "Rigueur",
              mild: "Doux",
              lively: "Animé",
              simplicity: "Simplicité",
              pleaseEnterTheSpeakerRole: "Veuillez entrer le rôle de speaker",
              startGenerating: "Commencer à générer",
            },
          },
          index: {
            verificationPassed: "Contrôle passé",
            ruleValidation: "Vérification des règles",
            aIGeneration: "Génération ai",
            generateFullTextSpeechScript:
              "Génération de discours en texte intégral",
            editCourse: "Modifier le cours",
            importConversation: "Importer une conversation",
            uploadExcelToCompleteDialogueImport:
              "Télécharger Excel terminer l'importation de la conversation",
            editBasicInformation: "Modifier les informations de base",
            test: "Test",
            release: "Publier",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Nouvelle étiquette" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Impossible de récupérer après la suppression, êtes - vous sûr de vouloir supprimer cette étiquette?",
              delete: "Supprimer",
              cancel: "Annulation",
            },
          },
        },
        index: {
          labelManagement: "Gestion des étiquettes",
          newGroup: "Nouveau groupe",
          edit: "Modifier",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Impossible de récupérer après la suppression, Êtes - vous sûr de vouloir supprimer ce groupe d'étiquettes?",
          delete: "Supprimer",
          cancel: "Annulation",
          editGroup: "Modifier le Groupe",
          labelGroupName: "Étiquette nom du Groupe",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Caméra désactivée",
              noCameraDevicesFound: "Aucun appareil photo trouvé",
            },
          },
          cue: {
            index: {
              regenerate: "Régénérer",
              generate: "Générer",
              referenceScript: "Discours de référence",
              mattersNeedingAttention: "Précautions",
              examFrequency: "Nombre d'examens",
              section: "Le numéro",
              timestotal: "Fois / total",
              second: "Une fois",
              requirementsForPassingTheExam: "Exigences pour passer l'examen",
              examScore: "Score d'examen ≥",
              branch: "Points",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable:
                "Pas de portrait pour le moment",
              customerBackground: "Contexte du client",
              customerLabel: "Étiquette client",
            },
          },
          flow: {
            index: {
              processReminder: "Conseils de processus (",
              score: "Score",
              TotalScore: "/ score total",
            },
          },
          index: {
            taskName: "Nom de la tâche",
            taskStatus: "État de la tâche",
            whole: "Tous",
            completionStatus: "État d'achèvement",
            courseClassification: "Classification des cours",
            practiceMode: "Mode de pratique",
            examMode: "Mode d'examen",
            practiceTime: "Temps de pratique",
          },
          ppt: {
            index: {
              nextPage: "Page suivante",
              tips: "Conseils",
              continueWithCurrentPage: "Continuer sur la page actuelle",
              goToTheNextPage: "Aller à la page suivante",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Vous ne pourrez plus revenir à la page précédente après avoir accédé à la page suivante, veuillez confirmer que l'explication de la page en cours est complète avant de sauter",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Cliquer sur la page suivante ne fera pas apparaître cette astuce",
            },
            list: { index: { slide: "Diapositives" } },
          },
          record: {
            index: {
              unableToRecord: "Impossible d'enregistrer:",
              creatingARecorderService: "Créer un service recorder",
              turnOffTheRecorderService: "Fermer le service recorder",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Le client actuel est déjà connecté au serveur ASR websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "Une fois la dernière image traitée, Restaurez l'état",
              aSRConnectionClosed: "La connexion ASR est fermée...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "L'enregistrement est suspendu et le traitement est terminé, mais les données sont toujours présentes dans le cache",
              foundASRChannelClosedRecreateWebsocketLink:
                "Découvrez la fermeture du canal ASR, Recréez le lien websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Découvrez que le canal ASR n'est pas ouvert, Recréez le lien websocket",
            },
            ppt: { hook: { index: { serverError: "Erreur du serveur" } } },
            text: {
              index: {
                pleaseEnter: "Veuillez entrer",
                sendOut: "Envoyer",
                startPracticing: "Commencer à pratiquer",
                startTheExam: "Commencer l'examen",
              },
            },
            util: {
              recorded: "Enregistré",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 bits PCM sendframesize doit être un multiple entier de 2",
              pCMSamplingRateShouldNotOccur:
                "Le taux d'échantillonnage PCM ne doit pas apparaître",
              andTheRequiredSamplingRate: "Et taux d'échantillonnage requis",
              atypism: "Incohérence",
              errorsThatShouldNotOccur:
                "Erreurs qui ne devraient pas se produire:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '< span style = "color: red" > Les données désordonnées peuvent être supprimées directement si elles ne sont pas exigeantes. < / span >',
              flower: "Fleurs",
              TransferStopped: ": transmission arrêtée",
            },
            video: { index: { camera: "Caméra" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Le client actuel est déjà connecté au serveur ASR websocket",
                    aSRConnectionClosed: "La connexion ASR est fermée...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Découvrez la fermeture du canal ASR, Recréez le lien websocket",
                    unableToRecord: "Impossible d'enregistrer:",
                    aSRChannelClosedReopened: "Canal ASR fermé, ré - ouvert",
                    theASRChannelWasNotCreatedStartingNow:
                      "Le canal ASR n'est pas créé, commencez à le créer maintenant",
                    useEffectCallbackToClearTheASRChannel:
                      "Useeffect Callback pour effacer le canal ASR",
                    creatingARecorderService: "Créer un service recorder",
                    turnOffTheRecorderService: "Fermer le service recorder",
                  },
                  util: {
                    recorded: "Enregistré",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 bits PCM sendframesize doit être un multiple entier de 2",
                    pCMSamplingRateShouldNotOccur:
                      "Le taux d'échantillonnage PCM ne doit pas apparaître",
                    andTheRequiredSamplingRate:
                      "Et taux d'échantillonnage requis",
                    atypism: "Incohérence",
                    errorsThatShouldNotOccur:
                      "Erreurs qui ne devraient pas se produire:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '< span style = "color: red" > Les données désordonnées peuvent être supprimées directement si elles ne sont pas exigeantes. < / span >',
                    flower: "Fleurs",
                    TransferStopped: ": transmission arrêtée",
                  },
                },
                index: { failInSend: "L'envoi a échoué" },
              },
              index: {
                startRecording: "Commencer l'enregistrement",
                endRecording: "Fin de l'enregistrement",
                startPracticing: "Commencer à pratiquer",
                startTheExam: "Commencer l'examen",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(le nombre restant d'examens est",
              totalScriptScore: "Score total en phonétique:",
              totalEmotionalScore: "Score total émotionnel:",
              totalScoreOfExpression: "Exprimer le score total:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Les résultats du score de la tâche en cours sont soumis à un audit humain, l'achèvement de l'audit vous informera du score final, veuillez patienter",
              areYouSureToSubmit: "La soumission est - elle confirmée?",
              theExerciseHasEndedAreYouSureToSubmit:
                "L'exercice est terminé, la soumission est - elle confirmée?",
              theExamHasEndedAreYouSureToSubmit:
                "L'examen est terminé, la soumission est - elle confirmée?",
              completed: "Terminé",
              page: "Pages",
              pageCount: "Nombre total de pages",
              topic: "Sujet",
              totalNumberOfQuestions: "Nombre total de questions",
              totalScore: "Score total",
              branch: "Points",
              accumulatedCompletionProgress:
                "Progression cumulative de l'achèvement",
              accumulatedPracticeDuration: "Durée cumulée des exercices",
              lastPauseExerciseResults:
                "Dernière pause - résultats de l'exercice",
              exerciseInstructions: "Instructions pour les exercices",
              examInstructions: "Description de l'examen",
              halfwayPausePracticeResults:
                "Pause à mi - chemin - résultats de l'exercice",
              tips: "Conseils",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Cet exercice ne compte pas pour les notes après le retrait, le retrait est - il confirmé?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Les retraits ne sont pas autorisés à mi - parcours de l'examen, la soumission est - elle confirmée?",
              pleaseOperateWithCaution:
                "Une fois, veuillez opérer avec prudence)",
              restart: "Recommencer",
              returnToLearning: "Retour aux études",
              continuePracticing: "Continuez à pratiquer",
              continuingTheExam: "Continuer l'examen",
              return: "Retour",
              notSubmittedTemporarily: "Ne pas soumettre en attendant",
              iGotIt: "Je le vois.",
              confirmExit: "Confirmer le retrait",
              confirmSubmission: "Confirmer la soumission",
              signOut: "Quitter",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Suspension",
              submit: "Soumettre",
              remainingTimeOfThisQuestion: "Temps restant sur ce sujet",
              SecondsLeftUntilTheEndTime: "10 secondes restantes de la fin",
              remainingTime: "Temps restant",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Aucun contenu vocal utilisateur détecté, réessayez",
          },
          wave: {
            index: {
              continueRecording: "Continuer l'enregistrement",
              submit: "Soumettre",
              endRecording: "Fin de l'enregistrement",
              suspendRecording: "Suspendre l'enregistrement",
              startPracticing: "Commencer à pratiquer",
              startTheExam: "Commencer l'examen",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Mode conversation",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Bruit excessif, veuillez changer d'environnement calme",
              normalNoiseDetection: "Détection de bruit normale",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Volume trop faible, veuillez augmenter le volume",
              volumeDetectionIsNormal: "Détection du volume normal",
              noiseDetection: "Détection de bruit",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "S'il vous plaît rester calme et enregistrer des sons d'ambiance 3S",
              endRecording: "Fin de l'enregistrement",
              startRecording: "Commencer l'enregistrement",
              volumeDetection: "Détection de volume",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'S\'il vous plaît lire à haute voix "faites le test de volume maintenant" avec un volume normal',
              playLastDetectedAudio: "Jouer la dernière détection audio",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Veuillez lire attentivement les exigences avant de commencer",
              return: "Retour",
              iGotIt: "Je le vois.",
              timeLimitForSingleQuestionAnswering:
                "Limite de temps de réponse aux questions individuelles",
              totalTimeLimit: "Durée totale limitée",
              unlimitedTime: "Pas limité dans le temps",
              stillNeedToCompleteTheTaskInTotal: "Total des tâches à accomplir",
              practice: "Exercices",
              second: "Une fois",
              taskScore: "Score d'une mission ≥",
              branch: "Points",
              or: "Ou",
              examScore: "Score d'examen ≥",
              remainingExamTimes: "Nombre d'examens restants",
              totalScore: "Score total",
              Script: "(la parole",
              scoreEmotion: "Score + émotions",
              fractionExpression: "Score + expression",
              points: "Points)",
              standardScore: "Score de conformité",
              excellentScore: "Excellent score",
              exerciseInstructions: "Instructions pour les exercices",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Pour une bonne réponse, terminez d'abord la détection de l'appareil:",
              camera: "Caméra",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Vous pouvez vous voir dans la vidéo, cela signifie que la caméra fonctionne",
              microphone: "Microphone",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "S'il vous plaît parler dans le microphone, voir un diagramme d'onde signifie que le microphone est OK",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Vide",
                  copyTo: "Copier vers",
                  classification: "Classification",
                  rename: "Renommer",
                  newlyBuild: "Nouvelle construction",
                  categoryParent: "Classification supérieure",
                  moveTo: "Déplacer vers",
                  name: "Le nom",
                },
              },
              util: {
                rename: "Renommer",
                moveTo: "Déplacer vers",
                copyTo: "Copier vers",
                delete: "Supprimer",
                courseClassification: "Classification des cours",
                newlyBuild: "Nouvelle construction",
              },
            },
          },
          edit: { index: { curriculum: "Cours" } },
          index: {
            learn: "Apprendre",
            practice: "Entraînement",
            examination: "Le Kau",
            curriculum: "Cours",
            courseIntroduction: "Présentation du cours:",
            createdOn: "Temps de création:",
            published: "Publié",
            tasks: "Une mission",
            unpublishedTasks: "Aucune tâche publiée",
            task: "La Mission",
            operation: "Opérations",
            learningMaterials: "Matériel d'apprentissage",
            goPractice: "Aller à la pratique",
            publishTask: "Publier une tâche",
            taskData: "Données de mission",
            edit: "Modifier",
            copy: "Copier",
            successfullyCopied: "Copie réussie",
            delete: "Supprimer",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Toutes les données de tâche sous ce cours seront vidées après la suppression, confirmez - vous la suppression du cours?",
            courseName: "Nom du cours",
            creationTime: "Temps de création",
            releaseStatus: "Statut de publication",
            whole: "Tous",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Aucun son détecté",
              voice: "Voix",
              text: "Texte",
              referenceScript: "Mots de référence:",
              clickOnTheRightSideToStartRecording:
                "Cliquez sur le côté droit pour commencer l'enregistrement",
              sendOut: "Envoyer",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Le client actuel est déjà connecté au serveur ASR websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "Une fois la dernière image traitée, Restaurez l'état",
                aSRConnectionClosed: "La connexion ASR est fermée...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "L'enregistrement est suspendu et le traitement est terminé, mais les données sont toujours présentes dans le cache",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Découvrez la fermeture du canal ASR, Recréez le lien websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Découvrez que le canal ASR n'est pas ouvert, Recréez le lien websocket",
                unableToRecord: "Impossible d'enregistrer:",
                creatingARecorderService: "Créer un service recorder",
                turnOffTheRecorderService: "Fermer le service recorder",
              },
              util: {
                recorded: "Enregistré",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 bits PCM sendframesize doit être un multiple entier de 2",
                pCMSamplingRateShouldNotOccur:
                  "Le taux d'échantillonnage PCM ne doit pas apparaître",
                andTheRequiredSamplingRate: "Et taux d'échantillonnage requis",
                atypism: "Incohérence",
                errorsThatShouldNotOccur:
                  "Erreurs qui ne devraient pas se produire:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '< span style = "color: red" > Les données désordonnées peuvent être supprimées directement si elles ne sont pas exigeantes. < / span >',
                flower: "Fleurs",
                TransferStopped: ": transmission arrêtée",
              },
            },
            finishTest: {
              index: {
                endTest: "Fin du test",
                testResult: "Résultats des tests",
                returnToCanvas: "Retour à Canvas",
                retest: "Re - test",
              },
            },
          },
          index: {
            testFromCurrentNode: "Test à partir du nœud actuel",
            listening: "Dans le son...",
            canvasTesting: "Test sur toile",
            endOfProcess: "Fin du processus",
            restart: "Recommencer",
            start: "Commencer",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Résolution de retrait",
                  expandParsing: "Développer la résolution",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Trop de mots de ton",
                  second: "Une fois",
                  ratingOverview: "Aperçu général du score",
                  totalScore: "Score total",
                  section: "Le numéro",
                  name: "Le nom",
                  of: "(au total",
                  people: "Personnes)",
                  scriptScore: "Score phonétique",
                  common: "Au total",
                  keyPoints: "Un point important,",
                  notReached: "Non atteint,",
                  have: "Il y a",
                  violationOfRegulations: "Violation",
                  emotionalScore: "Score émotionnel",
                  appear: "Apparaître",
                  secondaryNegativeEmotions: "émotions sous - négatives",
                  expressionScore: "Score d'expression",
                  speakTooFast: "Vitesse de parole trop rapide",
                  times: "Une fois,",
                  speakTooSlowly: "Vitesse de parole trop lente",
                  average: "Moyenne",
                  wordminute: "Mots / minutes",
                },
              },
              score: {
                index: {
                  studentNodeName: "Nom du nœud cadet",
                  keyPointName: "Nom du point",
                  slideNodeName: "Nom du nœud slide",
                  dialogue: "Dialogue",
                  mainPoints: "Points clés",
                  slide: "Diapositives",
                  detectionResult: "Résultats de détection",
                  satisfy: "Satisfait",
                  dissatisfaction: "Non satisfait",
                  score: "Score",
                  script: "La parole",
                  semantics: "Sémantique",
                  scriptViolation: "Violations de la parole",
                  emotion: "Les émotions",
                  expression: "Expression",
                },
              },
              taskTitle: { index: { branch: "Points" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Cette fois",
                  lastTime: "La dernière fois",
                  comprehensiveEvaluation: "Évaluation intégrée",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '< span style = "Margin - left: 8px" > · accumulation de mots standard',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Un mot, le nombre total de mots suivis par l'apprenant",
                  aWordspan: "Un mot < / span >",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Sub < / span > < br / > < span style = "Margin - left: 8px" > · trop lent',
                  timesspan: "Secondaire < / span >",
                  tooManyModalParticles:
                    '< span style = "Margin - left: 8px" > · trop de mots dans le ton',
                  complete: "Contenu complet",
                  keyHit: "Points clés hit",
                  languageNorm: "Spécifications linguistiques",
                  fluentExpression: "Expression fluide",
                  emotionallyPositive: "Emotionnellement positif",
                  radarChart: "Redatu",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '< span style = "Margin - left: 8px" > · nombre de processus complets',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '< span style = "Margin - left: 8px" > · frapper pour obtenir des points',
                  spanStylemarginLeftpxYes:
                    '< span style = "Margin - left: 8px" > · oui',
                  dealingWithIllegalWordsspan: "Mot d'infraction < / span >",
                  spanStylemarginLeftpxSpeakTooFast:
                    '< span style = "Margin - left: 8px" > · trop rapide',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Sub < / span > < br / > < span style = "Margin - left: 8px" > · trop de mots sur le ton',
                  spanStylemarginLeftpxNegativeEmotions:
                    '< span style = "Margin - left: 8px" > · émotions négatives',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Score record",
                  firstRating: "Premier score",
                  totalScore: "Score total",
                  changeTo: "Changer pour",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Phase de communication",
                  communicationObjectives: "Objectifs de communication",
                  scoringCriteria: "Critères de notation",
                  suggestionsForImprovement: "Suggestions d'élévation",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Nombre restant:",
                  second: "Une fois",
                  doItAgain: "Encore une fois",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Le navigateur ne prend pas en charge la lecture audio.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions:
                    "Commentaires et conseils sur la parole",
                  regenerate: "Régénérer",
                  generate: "Générer",
                  generationFailed: "La génération a échoué",
                },
              },
              resultModal: {
                index: { return: "Retour", viewDetails: "Voir les détails" },
              },
            },
            index: {
              reRatingSuccessful: "Re - score réussi",
              reRatingFailed: "échec du re - score",
              downloadRecording: "Télécharger l'enregistrement",
              downloadText: "Télécharger le texte",
              download: "Télécharger",
              reRating: "Re - score",
              submitARatingAppeal: "Soumettre une plainte notée",
              reviewSubmission: "Révision de la soumission",
              saveChanges: "Sauvegarder les modifications",
              previousSentence: "Phrase précédente",
              nextSentence: "Phrase suivante",
              score: "Score",
              scoringSituation: "Situation des scores",
              dialogueAnalysis: "Résolution de dialogue",
            },
          },
          index: {
            ratingAppealInProgress: "Dans les plaintes notées",
            operation: "Opérations",
            wrongQuestionSet: "Ensemble de questions erronées",
            ratingUpdate: "Mise à jour du score",
            viewDetails: "Voir les détails",
            curriculum: "Cours",
            taskName: "Nom de la tâche",
            taskType: "Types de tâches",
            score: "Score",
            scoringResults: "Score résultats",
            time: "Le temps",
            courseClassification: "Classification des cours",
            whole: "Tous",
            learningTime: "Temps d'étude",
            historicalLearningData: "Données d'apprentissage historiques",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Suspension des exercices",
              lastProgress: "Dernière progression",
              continuePracticing: "Continuez à pratiquer",
              practice: "Exercices",
              startPracticing: "Commencer à pratiquer",
              historicalData: "Données historiques",
              courseIntroduction: "Présentation du cours:",
              exerciseDifficulty: "Difficulté de l'exercice:",
              practiceCompletionRequirements:
                "Exigences d'achèvement des exercices:",
              practiceFrequency: "Nombre d'exercices ≥",
              second: "Une fois",
              anyScore: "Score à n'importe quel moment ≥",
              branch: "Points",
              or: "Ou",
              taskList: "Liste des tâches",
              practiceTime: "Temps de pratique:",
              to: "à",
              unlimitedTime: "Temps illimité",
              completionStatus: "État d'achèvement",
              numberOfExercises: "Nombre d'exercices",
              maximumScore: "Le score le plus élevé",
              operation: "Opérations",
              enteringLearning: "Entrer dans l'apprentissage",
              historicalLearningData: "Données d'apprentissage historiques",
              wrongQuestionSet: "Ensemble de questions erronées",
            },
          },
          index: {
            practiceTasks: "Exercice des tâches",
            examTasks: "Tâches d'examen",
            task: "La Mission",
          },
          learn: {
            index: {
              courseName: "Nom du cours",
              courseIntroduction: "Présentation du cours",
              learningContent: "Contenu de l'apprentissage",
              downloadLearningMaterials:
                "Matériel d'apprentissage à télécharger",
              startPracticing: "Commencer à pratiquer",
            },
          },
          test: {
            index: {
              taskList: "Liste des tâches",
              courseIntroduction: "Présentation du cours:",
              examDifficulty: "Difficulté de l'examen:",
              requirementsForPassingTheExam: "Exigences pour passer l'examen:",
              examScore: "Score d'examen ≥",
              branch: "Points",
              examTime: "Heures d'examen:",
              to: "à",
              unlimitedTime: "Temps illimité",
              completionStatus: "État d'achèvement",
              completionTimes: "Nombre de finitions",
              maximumScore: "Le score le plus élevé",
              operation: "Opérations",
              immediateExam: "Examen maintenant",
              history: "Historique",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Discours de référence" } },
              flow: {
                index: {
                  wrongQuestionItem: "Questions erronées (",
                  score: "Score",
                  TotalScore: "/ score total",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Erreur du serveur" } },
                  index: {
                    startRecording: "Commencer l'enregistrement",
                    endRecording: "Fin de l'enregistrement",
                    startPracticing: "Commencer à pratiquer",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Veuillez entrer",
                    sendOut: "Envoyer",
                    startPracticing: "Commencer à pratiquer",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Commencer l'enregistrement",
                    endRecording: "Fin de l'enregistrement",
                    startPracticing: "Commencer à pratiquer",
                  },
                  hook: { index: { failInSend: "L'envoi a échoué" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Score de cet exercice à problèmes",
                  tips: "Conseils",
                  areYouSureToEnd: "La fin est - elle confirmée?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Pratiquer à nouveau les problèmes non maîtrisés",
                  continue: "Continuer",
                  returnToTheSetOfIncorrectQuestions:
                    "Retour à la collection de questions erronées",
                  end: "La fin",
                  wrongQuestionItem: "Éléments erronés",
                  numberOfExercisesThisTime: "Nombre de cet exercice",
                  masteringTheSituation: "Maîtriser la situation",
                  mastered: "Maîtrisé",
                  notMastered: "Non maîtrisé",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Pratiquez à nouveau le sujet",
                  nextQuestion: "Sujet suivant",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Exercices de faux problèmes",
                endExercise: "Fin des exercices",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Exercices de faux problèmes",
                endExercise: "Fin des exercices",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Exercices de faux problèmes",
                endExercise: "Fin des exercices",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Voir la parole",
                  section: "Le numéro",
                  secondaryDiscourseTechnique: "Sous - Discours",
                },
              },
              tableItem: {
                count: { second: "Une fois" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Aucune demande d'information pertinente",
                  recallWords: "Mots de rappel",
                  branch: "Points",
                  answerIncorrectly: "Mauvaise réponse",
                  second: "Une fois",
                  wrongAnswerRate: "Taux d'erreur de réponse",
                },
                standardScript: {
                  standardScript: "Langage standard",
                  myScript: "Ma parole",
                },
                status: { mastered: "Maîtrisé", notMastered: "Non maîtrisé" },
                index: {
                  totalNumberOfQuestionsAnswered: "Nombre total de réponses",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Nombre de questions erronées pratiquées",
                },
              },
            },
            index: {
              wrongQuestionList: "Liste des questions erronées",
              wrongQuestionSet: "Ensemble de questions erronées",
              mispracticeExercises: "Exercices de faux problèmes",
              taskName: "Nom de la tâche",
              staff: "Employés",
              masteringTheSituation: "Maîtriser la situation",
              whole: "Tous",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Nombre d'exercices ≥",
              second: "Une fois",
              or: "Ou",
              excellentScoreAtAnyOneTime: "Excellent score à tout moment (≥",
              points: "Points)",
              examScore: "Score d'examen ≥",
              branch: "Points",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Distribution moyenne aléatoire",
              assignToDesignatedPersonnel:
                "Distribution aux personnes désignées",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Augmenter le nombre de tests réussis",
              failedToIncreaseTheNumberOfExams:
                "Augmenter le nombre d'échecs aux examens",
              increaseTheNumberOfExams: "Augmenter le nombre d’examens",
              selectObjects: "Sélectionner un objet",
            },
          },
          executionFilter: {
            index: { participationRate: "Taux de participation" },
          },
          intelligentReview: {
            index: {
              overallReview: "Commentaire général",
              pleaseEnterTheReviewCriterianotMandatory:
                "Veuillez entrer un critère de commentaire (non obligatoire)",
              commentaryOnSingleSentenceRhetoric: "Commentaire sur single word",
              realTimeReview: "Commentaires en temps réel",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Score moyen aux examens",
            averageExamDurationmin: "Durée moyenne de l'examen (min)",
            accumulatedPracticeDurationmin: "Durée cumulée des exercices (min)",
            accumulatedPracticeTimes: "Nombre cumulé d'exercices",
            totalScore: "Score total",
            average: "Score moyen",
            total: "Total",
            keyHitCount: "Nombre de points touchés",
            practiceAverageScore: "Moyenne des exercices",
            averagePracticeDurationmin: "Durée moyenne des exercices (min)",
            topOfTheHighestScore: "Meilleur score Top3",
            branch: "Points",
            errorProneItemTop: "Erreurs Top5",
            hitRate: "Taux de réussite",
            hitRateOfKeyPointsInScript: "Taux de réussite des points de parole",
            averageScriptScore: "Score moyen en phonétique",
            sortByProcess: "Trier par processus",
            sortByScore: "Trier par score",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Note moyenne sur cette page / note totale sur cette page",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Note moyenne pour cet article / note totale pour cet article",
            details: "Détails",
            viewRules: "Voir les règles",
            standardScript: "Langage standard",
          },
          scoreDetailList: {
            section: "Le numéro",
            second: "Une fois",
            practice: "Exercices",
            examination: "examen",
            notMeetingStandards: "Non conforme",
            meetingStandards: "Conforme aux normes",
            excellent: "Excellent",
            frequency: "Nombre de fois",
            fraction: "Score",
            result: "Résultats",
            operation: "Opérations",
            viewDetails: "Voir les détails",
          },
          index: {
            common: "Au total",
            people: "Personnes",
            staff: "Employés",
            notMeetingStandards: "Non conforme",
            meetingStandards: "Conforme aux normes",
            excellent: "Excellent",
            notStarted: "Pas commencé",
            haveInHand: "En cours",
            completed: "Terminé",
            passed: "Adopté",
            hangInTheAir: "Pas terminé",
            failed: "Non passé",
            unlimitedTime: "Temps illimité",
            to: "à",
            maximumScoringCriteria: "Critères de score les plus élevés",
            completionStatus: "État d'achèvement",
            employeeName: "Nom de l'employé",
            frequency: "Nombre de fois",
            practice: "Exercices",
            examination: "examen",
            average: "Score moyen",
            speechCraftAvgScore: "Score moyen en phonétique",
            emotionAvgScore: "Score moyen émotionnel",
            speedAvgScore: "Expression score moyen",
            flowAvgScore: "Score moyen du processus",
            practiceCostTime: "Durée cumulée",
            participateCommitRate: "Taux de conformité",
            participateExcellentRate: "Taux de dao",
            duration: "Durée",
            score: "Score",
            scoringResults: "Score résultats",
            flowScore: "Score du processus",
            craftScore: "Score phonétique",
            emotionScore: "Score émotionnel",
            speedScore: "Score d'expression",
            contentIntegrity: "Intégrité du contenu",
            pointHitRate: "Taux de réussite des points",
            emotionNegativeRate: "Ratio négatif émotionnel",
            paraPhaSiaRate: "Proportion d'anomalies de vitesse vocale",
            toneOvermuchRate: "Trop de mots de ton",
            maximumScore: "Le score le plus élevé",
            highestScoreResult: "Résultat du score le plus élevé",
            state: "Statut",
            averageDurationmin: "Durée moyenne (min)",
            lately: "Récemment",
            time: "Le temps",
            operation: "Opérations",
            viewDetails: "Voir les détails",
            wrongQuestionSet: "Ensemble de questions erronées",
            taskName: "Nom de la tâche",
            completeRequirements: "Exigences d'achèvement",
            publishTo: "Publier un objet",
            taskTime: "Temps de mission",
            fractionalComposition: "Composition du score",
            totalScore: "Score total",
            branch: "Points",
            Script: "(la parole",
            scoreEmotion: "Score + émotions",
            fractionExpression: "Score + expression",
            points: "Points)",
            implementationRate: "Taux de participation",
            numberOfExecutors: "Nombre de participants",
            numberOfPeopleNotExecuted: "Nombre de non - participants",
            completionRate: "Taux d'achèvement",
            numberOfCompletedPersonnel: "Nombre de personnes terminées",
            numberOfUnfinishedPersonnel: "Nombre de personnes inachevées",
            complianceRate: "Taux de conformité",
            numberOfQualifiedPersonnel: "Nombre de personnes conformes",
            numberOfNonCompliantIndividuals:
              "Nombre de personnes non conformes",
            excellentRate: "Taux de dao",
            participateCompleteRate: "Taux d'achèvement de la participation",
            numberOfOutstandingIndividuals: "Excellent nombre de personnes",
            numberOfNonOutstandingIndividuals:
              "Nombre de personnes non excellentes",
            export: "Exporter",
            taskData: "Données de mission",
            resultAnalysis: "Analyse des résultats",
            scoreDetails: "Détails du score -",
            dataOverview: "Aperçu des données",
            taskDetail: "Détails des tâches",
            scriptAnalysis: "Analyse phonétique",
            flowAnalysis: "Analyse des processus",
            expressionAnalysis: "Analyse de l'expression",
            emotionAnalysis: "Analyse des émotions",
            byTime: "Vue de dimension temporelle",
            byNumber: "Nombre de vues dimensionnelles",
            personData: "Statistiques par personne",
            numberData: "Détails par enregistrement",
            count: "Par nombre de fois",
            person: "Par nombre de personnes",
            reachTimes: "Nombre de toucher",
            outOfReachTimes: "Nombre de fois non touché",
            seeOutOfReach: "Cliquez pour voir untouched",
            accumulative: "Cumulatif",
            script: "La parole",
            times: "Une fois",
            sentence: "Phrases",
            expressionAverage: "Moyenne d'expression",
            modalAnalysis: "Analyse des mots de ton",
            total: "Au total",
            muchModalAnalysis: "Présence de trop de mots de ton",
            normal: "Normale",
            speedAnalysis: "Analyse de la vitesse de la parole",
            speedDistribution: "Distribution de la vitesse vocale",
            speakingSpeed: "Vitesse de parole",
            normalSpeak: "Vitesse normale de la parole",
            tooFastSpeak: "Vitesse de parole trop rapide",
            tooSlowlySpeak: "Vitesse de parole trop lente",
            violationWord: "Mots de violation",
            violationWordAnalysis: "Analyse des mots offensants",
            noData: "Pas encore",
            emotionalScore: "Score émotionnel",
            emotionalDistribution: "Distribution des émotions",
            negativeEmotion: "émotions négatives",
            normalEmotion: "Emotions normales",
            getDetail: "Voir les détails",
            barPattern: "Vue des barres",
            timeDimension: "Vue latitude temporelle",
            timesDimension: "Nombre de vues dimensionnelles",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Score moyen",
                  averageScoreOfScript: "Moyenne vocale",
                  averageEmotionalScore: "Moyenne des émotions",
                  expressionAverageScore: "Expression score moyen",
                  processAverageScore: "Score moyen du processus",
                  accumulatedDuration: "Durée d'accumulation",
                  accumulatedTimes: "Nombre cumulé",
                  participationComplianceRate:
                    "Taux de participation à la conformité",
                  participationRateForAchievingExcellence:
                    "Taux de participation Dao",
                  branch: "Points",
                  numberOfPeople: "Nombre de personnes",
                  averageScoreOfExpression: "Moyenne d'expression",
                  people: "Personnes",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "émotions négatives" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Score moyen en phonétique",
                  branch: "Points",
                },
                useSummaryDetail: { keyHitRate: "Taux de réussite des points" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Smart commentaires",
            pleaseImproveTheConfiguration: "Veuillez affiner la configuration",
            examResults: "Résultats des examens",
            showTheResultsAfterEachExam:
              "Affichage des résultats à la fin de chaque examen",
            showTheResultsAfterTheLastExam:
              "Dernier examen terminé afficher les résultats",
            doNotDisplayResults: "Ne pas afficher les résultats",
            resultDisplay: "Présentation des résultats",
            score: "Score",
            scoringResults: "Score résultats",
            allowViewingScoreDetails: "Permet de voir les détails du score",
            viewCanvasConfiguration: "Voir la configuration du canevas",
            synchronizationFailedProcessNodeHasChanged:
              "La synchronisation a échoué, le nœud de processus a produit des modifications",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Synchronisation réussie, appuyez sur publier un cours de mise à jour",
            retrievedTheLatestCanvasConfiguration:
              "Dernière configuration de Canvas tirée",
            synchronizeCanvasConfiguration:
              "Configuration du canevas synchronisé",
            scoringMethod: "Méthode de notation",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Score, Flow {0} score, word {1} score, emotion {2} score, Speech {3} score)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Les normes d'excellence doivent être supérieures aux normes",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Les critères de notation doivent répondre à plus de 0% et à moins de 100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Les critères de notation doivent répondre à un score supérieur à 0, inférieur ou égal à {0} points",
            doRatingsRequireManualReview:
              "Le score nécessite - t - il un audit humain",
            allocationStrategy: "Stratégie de distribution",
            pleaseSelectTheAssignedPersonnel:
              "Veuillez sélectionner une personne assignée",
            pleaseSelectADesignatedPerson:
              "Veuillez sélectionner une personne désignée",
            timeLimitForSingleQuestionAnswering:
              "Limite de temps de réponse aux questions individuelles",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Publication par défaut aux nouveaux employés ajoutés",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Le re - score indique qu'il sera apparié selon les règles de score du dernier canevas, la tâche en cours contient des données de score qui ont été révisées artificiellement, si vous souhaitez remplacer le score",
            notCovered: "Non couvert",
            cover: "Couverture",
            participationSituation: "Participation",
            numberOfParticipants: "Nombre de participants",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Nombre d'objets d'exercice sélectionnés pour cette tâche somme",
            sumOfTheNumberOfParticipantsInTheTask:
              "Somme du nombre de personnes ayant participé à la Mission",
            numberOfCompletedPersonnel: "Nombre de personnes terminées",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Somme du nombre de personnes répondant aux exigences d'achèvement des tâches",
            numberOfQualifiedPersonnel: "Nombre de personnes conformes",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Somme du nombre de personnes répondant aux critères de la Mission",
            excellentPersonnel: "Excellent nombre de personnes",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Satisfaire le nombre de personnes ayant un excellent score de mission somme",
            participationRate: "Taux de participation",
            numberOfParticipantsNumberOfParticipants:
              "Nombre de participants déjà attendus / nombre de participants attendus",
            numberOfCompletedNumberOfParticipants:
              "Nombre de personnes complétées / nombre de participants",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Nombre de personnes à atteindre / nombre de participants à participer",
            excellentNumbernumberOfParticipants:
              "Excellent nombre / nombre de participants",
            participationCompletionRate:
              "Taux d'achèvement de la participation",
            numberOfCompletedParticipated:
              "Nombre de personnes complétées / nombre de participants",
            participationComplianceRate:
              "Taux de participation à la conformité",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Nombre de personnes conformes / nombre de participants",
            participationRateForAchievingExcellence:
              "Taux de participation Dao",
            accumulatedDuration: "Durée d'accumulation",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Longue sommation des participants aux missions",
            averageDuration: "Durée moyenne",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Nombre total de participants / nombre total de participants",
            accumulatedTimes: "Nombre d'accumulations",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Somme du nombre de missions auxquelles participent les stagiaires",
            averageScore: "Score moyen",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Score moyen des personnes impliquées dans cette mission",
            maximumScore: "Le score le plus élevé",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Meilleurs scores pour les personnes impliquées dans cette mission",
            averageScoreOfScript: "Moyenne vocale",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Score de conversation / nombre total de participants",
            totalScoreOfScript: "Score total en phonétique",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Score total de la parole définie sous cette tâche",
            processAverageScore: "Score moyen du processus",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Cumul des points du processus des apprenants / nombre total de participations",
            totalProcessScore: "Score total du processus",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Score total du processus défini sous cette tâche",
            averageEmotionalScore: "Moyenne des émotions",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Score émotionnel des apprenants somme / participation totale",
            totalEmotionalScore: "Score total émotionnel",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Score total émotionnel défini sous cette tâche",
            averageScoreOfExpression: "Moyenne d'expression",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Nombre total de participants exprimant un score / participation totale",
            totalScoreOfExpression: "Exprimer le score total",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Score total d'expression défini sous cette tâche",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Commencez à effectuer un re - score, vous pouvez passer au centre de mission pour voir les progrès",
            reRating: "Re - score",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Le re - score indique qu'il correspondra aux règles de score de la dernière toile, ce qui écrasera les résultats de score actuels",
            releaseTime: "Heure de publication",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Audité",
              pendingReview: "À auditer",
              enterKeywordSearch: "Entrez le mot clé rechercher",
            },
            pageRightTable: {
              index: {
                employeeName: "Nom de l'employé",
                systemRating: "Score du système",
                scoringResults: "Score résultats",
                duration: "Durée",
                time: "Le temps",
                state: "Statut",
                whetherToModifyTheRating: "Modifier le score ou non",
                finalScore: "Score final",
                reviewedBy: "Auditeur",
                operation: "Opérations",
                viewScoreDetails: "Voir les détails du score",
                viewStatus: "Voir le statut",
                whole: "Tous",
                correctionStatus: "Statut de la correction",
                completeReview: "Terminer l'audit",
                tips: "Conseils",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Les résultats finaux des stagiaires sont soumis aux résultats de l'audit final actuel, veuillez confirmer",
              },
            },
          },
          index: { manualReview: "Audit manuel" },
        },
        review: {
          index: {
            employeeName: "Nom de l'employé",
            score: "Score",
            scoringResults: "Score résultats",
            duration: "Durée",
            taskName: "Nom de la tâche",
            numberOfAppealScripts: "Nombre de recours",
            appealTime: "Temps de réclamation",
            processStatus: "État du processus",
            scoreAfterReview: "Score après révision",
            reviewedBy: "Réviseur",
            operation: "Opérations",
            viewScoreStatus: "Voir le score",
            whole: "Tous",
            manualReview: "Révision manuelle",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Pratiquer les données de tâche",
            implementationRate: "Taux d'exécution",
            numberOfExecutors: "Nombre de personnes exécutées",
            numberOfPeopleNotExecuted: "Nombre de personnes non exécutées",
            completionRate: "Taux d'achèvement",
            numberOfCompletedPersonnel: "Nombre de personnes terminées",
            numberOfUnfinishedPersonnel: "Nombre de personnes inachevées",
            complianceRate: "Taux de conformité",
            numberOfQualifiedPersonnel: "Nombre de personnes conformes",
            numberOfNonCompliantIndividuals:
              "Nombre de personnes non conformes",
            excellentRate: "Taux de dao",
            numberOfOutstandingIndividuals: "Excellent nombre de personnes",
            numberOfNonOutstandingIndividuals:
              "Nombre de personnes non excellentes",
            examTaskData: "Données sur les tâches d'examen",
            published: "Publié",
            courses: "Un cours",
            courseData: "Données de cours",
            practiceTasks: "Exercice des tâches",
            examTasks: "Tâches d'examen",
          },
          practiceSummary: {
            practiceSummary: "Résumé des exercices",
            numberOfPracticeTasks: "Nombre de tâches de pratique",
            numberOfExercises: "Nombre d'exercices",
            accumulatedPracticeDuration: "Durée cumulée des exercices",
            complianceRate: "Taux de conformité",
            excellentRate: "Taux de dao",
            ranking: "Rangée",
          },
          rankingList: {
            numberOfExercises: "Nombre d'exercices",
            exerciseDuration: "Durée de la pratique",
            complianceRate: "Taux de conformité",
            excellentRate: "Taux de dao",
            diligenceChart: "Liste de diligence",
            excellentList: "Liste d'excellence",
            ranking: "Classement",
            fullName: "Nom et prénom",
            i: "Moi",
          },
          taskCard: {
            end: "Coupure:",
            noDeadline: "Aucune date limite",
            second: "Une fois",
            branch: "Points",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Pas de mission pour le moment",
            completed: "Terminé",
          },
          filter: { employeeDepartment: "Département du personnel" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Exportation réussie, rendez - vous dans le Centre de mission pour voir",
              export: "Exporter",
            },
          },
          filterConfig: {
            dropdown: { custom: "Personnalisation" },
            modal: {
              updateSuccessful: "Mise à jour réussie",
              successfullySaved: "Sauvegarder le succès",
              setAsACommonReport: "Définir comme rapport commun",
              updateCurrentReport: "Mettre à jour le rapport actuel",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Score moyen",
                  averageScoreOfScript: "Score moyen en phonétique",
                  averageEmotionalScore: "Score moyen émotionnel",
                  expressionAverageScore: "Expression score moyen",
                  processAverageScore: "Score moyen du processus",
                  branch: "Points",
                },
              },
              comprehensiveEvaluation: { const: { data: "Données" } },
              doneStatus: {
                index: {
                  practice: "Exercices",
                  examination: "examen",
                  executed: "Exécuté",
                  unexecuted: "Non exécuté",
                  accumulatedExamDuration: "Durée cumulée des examens",
                  examFrequency: "Nombre d'examens",
                },
              },
              filter: {
                index: {
                  practice: "Exercices",
                  examination: "examen",
                  pleaseCompleteTheRequiredFields:
                    "Veuillez compléter les champs obligatoires",
                  staff: "Employés",
                  taskType: "Types de tâches",
                  pleaseSelect: "Veuillez sélectionner",
                  taskName: "Nom de la tâche",
                },
              },
            },
            index: {
              completionStatus: "Situation d'achèvement",
              comprehensiveEvaluation: "Évaluation intégrée",
              abilityGrowthTrend: "Tendances de croissance des capacités",
              errorProneAnalysis: "Analyse des points faillibles",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Nombre de personnes actives",
            accumulatedPracticeDuration: "Durée cumulée des exercices",
            averagePracticeDuration: "Durée moyenne de pratique",
            people: "Personnes",
            numberOfPracticeTasks: "Nombre de tâches de pratique",
            numberOfExercises: "Nombre d'exercices",
            exerciseDuration: "Durée de la pratique",
            second: "Une fois",
            avgScore: "Score moyen",
            speechCraftAvgScore: "Score moyen en phonétique",
            emotionAvgScore: "Score moyen émotionnel",
            speedAvgScore: "Expression score moyen",
            flowAvgScore: "Score moyen du processus",
            practiceCostTime: "Durée cumulée",
            totalPracticeTimes: "Nombre cumulé",
            participateCommitRate: "Taux de participation à la conformité",
            participateExcellentRate: "Taux de participation Dao",
          },
        },
        index: {
          employeeProfile: "Portrait des employés",
          overview: "Aperçu général",
          personalSignage: "Kanban personnel",
          ongoingExercises: "Exercices en cours",
          ongoingExams: "Examens en cours",
          practiceTaskProgress: "Pratiquer la progression des tâches",
          examTaskProgress: "Progression des tâches d'examen",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Veuillez sélectionner le mot concept",
              sessionDetectionSettings: "Paramètres de détection de session",
              successfullySaved: "Sauvegarder le succès",
              abnormalSpeechSpeed: "Anomalies de la vitesse de la parole",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Prend en charge les normes de détection de vitesse de parole personnalisées, le résultat sera jugé comme normal, trop rapide, trop lent. En cas d'anomalie de la vitesse de la parole (trop rapide / trop lent), la soustraction est effectuée selon les règles de notation",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Vitesse de parole trop rapide: supérieure à chaque minute",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Un mot, trop lent: moins de chaque minute",
              charactersIgnoringLessThan: "Un mot, ignorer moins que",
              aSentenceOfWords: "Phrases de mots",
              excessiveModalParticles: "Trop de mots de ton",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Soutenez les mots de ton personnalisés et définissez des règles pour trop de mots de ton. Lorsqu'il y a trop de mots de ton, le score est réduit selon les règles de notation",
              theNumberOfRepetitionsIs: "Le nombre de répétitions est",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "La seconde fois, la décision est trop de mots de ton",
              definitionOfSensitiveViolations:
                "Définition des violations sensibles",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Prend en charge le thésaurus personnalisé des mots offensants, détecte si un mot offensant apparaît dans une phrase. Lorsque des mots offensants apparaissent, les points sont diminués selon les règles de notation",
              violationWords: "Mots offensants:",
              emotionalDetection: "Détection des émotions",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "La détection des émotions est effectuée par la méthode de dialogue du modèle émotionnel systémique et les résultats sont jugés comme étant normaux sur le plan émotionnel et négatifs sur le plan émotionnel (y compris négatif, en colère, se plaindre). Lorsque l'humeur est négative, la soustraction est faite selon les règles de notation",
              preserve: "Sauvegarder",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Dimension du score",
              ratingPoints: "Points de score",
              explain: "Description",
              type: "Type",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Données aléatoires" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '< span style = "Margin - left: 8px" > · accumulation de mots standard',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Un mot, le nombre total de mots suivis par l'apprenant",
                  aWordspan: "Un mot < / span >",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Sub < / span > < br / > < span style = "Margin - left: 8px" > · trop lent',
                  timesspan: "Secondaire < / span >",
                  tooManyModalParticles:
                    '< span style = "Margin - left: 8px" > · trop de mots dans le ton',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "La valeur du score est" },
                  item: {
                    violationOnceReduced: "Violation 1 Réduction",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Anomalies de la vitesse de la parole (y compris la vitesse de la parole excessive, la vitesse de la parole trop lente) 1 moins",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Trop de mots de ton 1 fois moins",
                    oneNegativeDecreaseInEmotions: "Humeur négative à 1 fois",
                    branch: "Points",
                  },
                  wrap: { name: "Le nom", preserve: "Sauvegarder" },
                },
              },
              index: {
                complete: "Contenu complet",
                keyHit: "Points clés hit",
                accurateFollowupReading: "Lire avec précision",
                languageNorm: "Spécifications linguistiques",
                speechSpeed: "Vitesse de la parole",
                modalParticles: "Mots de ton",
                emotionallyPositive: "Emotionnellement positif",
                scoringModel: "Modèle de score",
                successfullyModified: "Modification réussie",
                newSuccessfullyCreated: "Nouvelle construction réussie",
              },
            },
            index: {
              scoringModel: "Modèle de score",
              newModel: "Nouveau modèle",
              model: "Modèle",
              type: "Type",
              operation: "Opérations",
              preview: "Preview",
              edit: "Modifier",
            },
          },
          index: {
            scoringModel: "Modèle de score",
            ratingDimension: "Dimension du score",
            sessionDetectionSettings: "Paramètres de détection de session",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Veuillez sélectionner",
                hit: "Frappé",
                misses: "Pas touché",
              },
            },
          },
          index: {
            duration: "Durée",
            processScore: "Score du processus",
            branch: "Points",
            contentCompleteness: "Intégrité du contenu",
            scriptScore: "Score phonétique",
            keyHitRate: "Taux de réussite des points",
            emotionalScore: "Score émotionnel",
            negativeProportionOfEmotions: "Ratio négatif émotionnel",
            expressionScore: "Score d'expression",
            theProportionOfAbnormalSpeechSpeed:
              "Proportion d'anomalies de vitesse vocale",
            excessiveProportionOfModalParticles: "Trop de mots de ton",
            learningRecordData: "Données d'enregistrement d'apprentissage",
            staff: "Employés",
            curriculum: "Cours",
            mainPoints: "Points clés",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Formulaire",
                        lineChart: "Graphique en lignes",
                        barChart: "Diagramme des colonnes",
                        pieChart: "Diagramme de gâteau",
                      },
                      index: { switchingTypes: "Type de commutation" },
                    },
                  },
                  index: {
                    saveSuccessful: "Sauvegarder le succès",
                    editChart: "Modifier le diagramme",
                    chartName: "Nom du graphique",
                    deleteSuccessful: "Supprimer avec succès",
                    refresh: "Rafraîchir",
                    export: "Exporter",
                    areYouSureToDeleteIt: "Sûr de supprimer?",
                    delete: "Supprimer",
                    viewFilteringCriteria: "Voir les critères de filtre",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Exportation réussie, rendez - vous dans le Centre de mission pour voir",
              },
            },
            header: {
              index: {
                saveSuccessful: "Sauvegarder le succès",
                deleteSuccessful: "Supprimer avec succès",
                editReport: "Modifier un rapport",
                reportName: "Nom du rapport",
                refresh: "Rafraîchir",
                areYouSureToDeleteIt: "Sûr de supprimer?",
                delete: "Supprimer",
                exportToExcel: "Exporter Excel",
                exportSnapshot: "Exporter un Snapshot",
                export: "Exporter",
                authority: "Permissions",
                permissionSettings: "Paramètres des permissions",
                reportVisiblePermissions:
                  "Autorisations visibles pour les rapports",
                visibleToEveryone: "Visible pour tous",
                onlyVisibleToOneself: "Visible uniquement par soi - même",
                designatedPersonnelVisible: "Visible par la personne désignée",
                pleaseSelect: "Veuillez sélectionner",
                reportDataPermissions: "Autorisations de données de rapport",
                restrictedByUserPermissions:
                  "Limité aux droits des utilisateurs",
                visiblePeopleAreTheSame: "Personnes visibles identiques",
              },
            },
            titleFooter: {
              index: {
                addReport: "Ajouter un rapport",
                reportName: "Nom du rapport",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Exportation réussie, rendez - vous dans le Centre de mission pour voir",
            dataQueryInProgress: "Dans les requêtes de données...",
            customKanban: "Kanban personnalisé",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Formulaire",
                lineChart: "Graphique en lignes",
                barChart: "Diagramme des colonnes",
                pieChart: "Diagramme de gâteau",
              },
              index: {
                successfullyAdded: "Ajouté avec succès",
                viewSQL: "Voir SQL",
                export: "Exporter",
                addToReport: "Ajouter au rapport",
              },
            },
            refer: {
              hooks: {
                data: "Données",
                interrogativeSentence: "Phrase d'interrogation",
                historicalQuery: "Requêtes historiques",
              },
              index: { copySuccessful: "Copie réussie", copy: "Copier" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Non conforme",
                        meetingStandards: "Conforme aux normes",
                        excellent: "Excellent",
                        notStartedYet: "Pas commencé",
                        inProgress: "En cours",
                        completed: "Terminé",
                        incomplete: "Pas terminé",
                      },
                    },
                  },
                  const: {
                    taskName: "Nom de la tâche",
                    department: "Département",
                    studentName: "Nom du stagiaire",
                    scoringResults: "Score résultats",
                    taskCompletionStatus: "État d'achèvement de la tâche",
                  },
                  index: {
                    pleaseSelectADimension:
                      "Veuillez sélectionner une dimension",
                  },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Input @ ouvre la boîte à balles pour la sélection des dimensions",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "S'il vous plaît entrer l'instruction de demande directement pour la demande",
                query: "Enquête",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Exportation réussie, rendez - vous dans le Centre de mission pour voir",
            dataQueryInProgress: "Dans les requêtes de données...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Recherche de mots clés",
                previous: "Précédent",
                next: "Suivant",
                theObjectThatTriggersTheScrollingEventIs:
                  "L'objet qui déclenche un événement de défilement est",
                userScrolling: "Défilement utilisateur",
              },
            },
            summary: {
              index: {
                reminder: "Mots de conseils:",
                edit: "Modifier",
                name: "Le nom",
                hintLanguage: "Langue de conseils",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Extraction du point de vue" },
            },
            outline: {
              index: {
                numberOfKeywords: "Nombre de mots clés",
                speaker: "Orateur",
                speechDuration: "Temps de parole long",
                proportionOfSpeaking: "Parler en pourcentage",
                maximumSpeechDuration: "Durée maximale de parole",
                numberOfQuestions: "Nombre de questions",
                averageSpeechSpeed: "Vitesse moyenne de la parole",
                conversationalActions: "Action conversationnelle",
                contentSummary: "Résumé du contenu",
                generateSummary: "Générer un résumé",
                keywordCloud: "Mots clés Cloud",
              },
            },
          },
          index: {
            outline: "Résumé",
            viewpoint: "Point de vue",
            contentSummary: "Résumé du contenu",
            viewpointExtraction: "Extraction du point de vue",
            summaryOfKeyPoints: "Résumé des points clés",
            videoAnalysis: "Analyse vidéo",
          },
        },
        list: {
          index: {
            delete: "Supprimer",
            uploadSuccessful: "Téléchargement réussi",
            videoList: "Liste des vidéos",
            uploadVideo: "Télécharger une vidéo",
            videoName: "Nom de la vidéo",
            uploadTime: "Temps de téléchargement",
            endTime: "Heure de fin",
            analyzeProgress: "Analyser les progrès",
            haveInHand: "En cours",
            completed: "Terminé",
            fail: "L'échec",
            toBegin: "À commencer",
            operation: "Opérations",
            see: "Voir",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Ajouter des heures de travail" },
        },
      },
      index: {
        businessWorkingHours: "Heures de travail des entreprises",
        newlyBuild: "Nouvelle construction",
        name: "Le nom",
        describe: "Description",
        operation: "Opérations",
        edit: "Modifier",
        delete: "Supprimer",
        editBusinessWorkingHours:
          "Modifier les heures de travail de l'entreprise",
        newBusinessWorkingHours: "New Business heures de travail",
        workingHours: "Heures de travail",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Extraction intelligente",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Avez - vous confirmé que vous souhaitez annuler? Ne pourra plus être restauré après annulation",
        },
      },
    },
    accountManagement: {
      securitySetting: "Paramètres de sécurité",
      accountManagement: "Gestion des comptes",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "à",
            switchToIPAddressMode: "Passer en mode adresse IP",
            switchToIPSegmentMode: "Passer en mode segment IP",
          },
        },
      },
      index: {
        saveSuccessful: "Sauvegarder le succès",
        pleaseImproveTheConfiguration: "Veuillez affiner la configuration",
        securitySetting: "Paramètres de sécurité",
        employeeLoginIPRestrictions:
          "Restrictions d'IP d'atterrissage des employés",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Limitez la portée des adresses IP auxquelles les employés peuvent se connecter, au - delà de laquelle l'atterrissage n'est pas autorisé. Valable uniquement pour le site Web, l'application mobile n'est pas limitée et l'Administrateur n'est pas limité.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Lorsque vous ajoutez ou modifiez, remplissez un segment IP externe (le même segment c) ou une adresse IP externe fixe.",
        allowLoginIPRange: "Plage d'adresses IP autorisées à atterrir",
        preserve: "Sauvegarder",
      },
    },
    coachAdminEntry: { speechRecognition: "Reconnaissance vocale" },
    billingCenter: {
      template: {
        details: "Détails",
        languageType: "Types de langues",
        aSREngine: "Moteur ASR",
        voiceQualityQuota: "Quota d'inspection de la qualité vocale",
        realtimeData: "Données en temps réel",
        calculatedByDay: "Calculé par jour",
      },
      component: {
        aSRName: "Nom ASR",
        language: "Langue",
        remainingQuota: "Quota restant",
        hours: "Heures",
        usedQuota: "Quotas utilisés",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "L'opération est sauvegardée avec succès!",
        deletedSuccessfully: "Supprimer avec succès!",
        pleaseSelectLanguage: "Veuillez sélectionner la langue",
        pleaseConfigureRules: "Veuillez configurer les règles",
        updateSuccessful: "Mise à jour réussie!",
        operationSuccessful: "Opération réussie!",
      },
      template: {
        aSRLanguage: "Langue ASR",
        pleaseSelect: "Veuillez sélectionner",
        model: "Modèle",
        pleaseSelectLanguage: "Veuillez sélectionner la langue",
        delete: "Supprimer",
        newASRModel: "Nouveau modèle ASR",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Analyse des produits compétitifs" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter:
          "Filtres de commentaires de commerce électronique",
        allComments: "Tous les commentaires",
        ecommerceReviewManagement: "Gestion des avis de commerce électronique",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Filtre de commentaires des médias sociaux",
        allComments: "Tous les commentaires",
        socialMediaReviewManagement:
          "Gestion des commentaires sur les médias sociaux",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Analyse des produits compétitifs",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Image de tête",
        realname: "Nom et prénom",
        nickname: "Surnom",
        name: "Nom et prénom",
        email: "Numéro de compte (boîte aux lettres)",
        mobile: "Téléphone",
        agentId: "Numéro de travail",
        position: "Postes",
        role: "Rôle",
        actions: "Opérations",
        createUser: "Ajout de personnel",
        editUser: "Modifier le personnel",
        account: "Numéro de compte",
        originPassword: "Mot de passe initial",
        newPassword: "Nouveau mot de passe",
        resumePassword: "Confirmer le mot de passe",
        userGroup: "Groupe d'employés",
        authorizationGroup: "Groupes de permissions",
        resetPassword: "Réinitialiser le mot de passe",
      },
      userGroups: {
        id: "ID",
        name: "Le nom",
        userCount: "Nombre d'employés",
        actions: "Opérations",
        createUserGroup: "Ajout d'un groupe d'employés",
        editUserGroup: "Modifier un groupe d'employés",
        member: "Membres",
      },
      role: {
        id: "ID",
        name: "Le nom",
        actions: "Opérations",
        all: "Tous",
        taskName: "La Mission",
        readTask: "Mission voir",
        manageTemplate: "Gestion des modèles",
        manualInspection: "Ré - inspection manuelle",
        readResultAll: "Résultats voir",
        readResultPersonal: "Affichage des résultats (individuel)",
        readProcess: "Processus d'inspection de la qualité View",
        readReport: "Statistiques et déclarations",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Date",
        taskName: "Le nom",
        size: "Taille",
        status: "Statut",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Le nom",
            remark: "Description",
            actions: "Opérations",
          },
          checkPoint: {
            name: "Le nom",
            remark: "Description",
            type: "Type",
            gradeType: "Modalités d'évaluation",
            conditionLogic: "Règles",
            predeterminedScore: "Valeur du point",
            weight: "Poids",
            status: "Statut",
            actions: "Opérations",
            switchOn: "Activer",
            switchOff: "Désactiver",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Le temps",
            customer: "Le client",
            customerPhone: "Téléphone du client",
            callType: "Type d'appel",
            source: "Sources",
            voiceRecording: "Enregistrement des appels",
            graded: "Score",
            openingGreeting: "Salutations d'ouverture",
          },
          checkPointList: {
            name: "Point de contrôle de qualité",
            type: "Type",
            deductScore: "Score",
            isHit: "Situation de Hit",
            status: "Révision",
            finalDeductScore: "Confirmer la déduction de points",
            hit: "Frappé",
            notHit: "Pas touché",
          },
        },
      },
    },
    custom: { keywords: "Mots clés de conversation" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Paramètres du système" },
      systemIntegration: {
        text: "Intégration de systèmes",
        subTitle: {
          udesk: "Système de service à la clientèle udesk",
          udeskWhite: "Système de service à la clientèle",
          wechat: "Wechat d'entreprise",
          taobao: "Taobao mille vaches",
          weifeng: "Micro - Fong",
        },
      },
      columnsManage: { text: "Gestion des champs de contrôle qualité" },
      customerField: { text: "Champ client" },
      speechRecognition: { text: "Reconnaissance vocale" },
      user: { text: "Gestion du personnel" },
      userGroups: { text: "Gestion des groupes d'employés" },
      role: { text: "Gestion des rôles" },
      dataMigration: { text: "Migration des données" },
      taskCenter: { text: "Centre de mission" },
      recordUpload: { text: "Téléchargement d'enregistrements locaux" },
      anomalousData: { text: "Données anormales" },
      systemLog: { text: "Journal des messages" },
      msgCenter: { text: "Centre de message" },
      wechatDocking: { text: "Micro - amarrage" },
    },
    tasks: {
      homePage: { text: "Accueil" },
      workbench: { text: "Table de travail" },
      semanticIntelligence: { text: "Intelligence sémantique" },
      intelligentAnalysis: { text: "Analyse intelligente" },
      intelligentLabel: { text: "Étiquettes intelligentes" },
      admin: { text: "Gestion" },
      callManage: { text: "Gestion des appels" },
      dialogueManage: { text: "Gestion des conversations" },
      ticketManage: { text: "Gestion des ordres de travail" },
      dataAnalysis: { text: "Analyse des données" },
      customerService: { text: "Centre de service à la clientèle" },
      clientCenter: { text: "Centre client" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Centre de formation" },
      templates: { text: "Gestion des modèles" },
      wechatQaTasks: { text: "Wechat Workbench pour les entreprises" },
      wechatQaSetting: { text: "Configuration d'analyse Wechat d'entreprise" },
      wechatQaChartRecord: { text: "Session Wechat d'entreprise" },
      wechatStaffCenter: { text: "Centre des employés Enterprise micro" },
      callAnalysis: { text: "Analyse de session" },
      caseBaseFolder: { text: "Bibliothèque de cas" },
      coachTask: { text: "Missions de coaching" },
      teamAnalysis: { text: "Analyse des équipes" },
      customer: { text: "Centre client" },
      analysisEnable: { text: "Habilitation analytique" },
      analysisHelp: { text: "Analyse des recours" },
      analysisStudy: { text: "Analyse de l'apprentissage" },
      analysisReview: { text: "Analyse de disque complexe" },
      analysisTags: { text: "Analyse intelligente des étiquettes" },
      analysisSentiment: { text: "Analyse de l'opinion publique" },
      analysisBusiness: { text: "Analyse des affaires" },
      groupTest: { text: "Test de groupe" },
    },
    navBar: {
      billingCenter: "Centre d'aide",
      uploadDownload: "Upload Centre de téléchargement",
      setting: "Paramètres du système",
    },
  },
  fix: {
    selectType: "Choisissez le type:",
    all: "Tous",
    call: "Appel",
    text: "Dialogue",
    wechat: "Session Wechat d'entreprise",
    businessTrendAnalysis:
      "Analyse des tendances commerciales de la classification de niveau 1",
    noEchart: "Graphique pas de données pour le moment",
    checkPointisRequired: "Règle configurée comme obligatoire",
    voiceDownLoad: "Télécharger l'enregistrement",
    exportTxt: "Exporter du texte TXT",
    exportWord: "Exporter du texte word",
    sureReRole: "Confirmer inverser le rôle du locuteur?",
    sureRecover: "Confirmer la correction d'erreur?",
    sureReAsr: "Confirmer la ré - identification?",
    reRole: "Inverser le locuteur",
    recover: "Correction d'erreur à nouveau",
    reAsr: "Ré - identification",
    recordingOperation: "Opérations d'enregistrement",
    onlySave30Day: "(réservé seulement 30 jours)",
    appealError:
      "Veuillez d'abord déposer une plainte au point de contrôle de la qualité avant de la soumettre",
    exportToTaskCenter:
      "Exporté avec succès vers le Centre de téléchargement upload",
    caseBaseLimit: "La Bibliothèque de cas ne peut pas dépasser 50",
    unnamed: "Sans nom",
    wordSpeed: "Mots / points",
    hit: "Frappé",
    checkPoint: "Point de contrôle de qualité",
    realTimeAdditionTip1:
      "Ajout en temps réel: le contrôle de qualité commence immédiatement lorsque les données entrent dans le système de contrôle de qualité",
    realTimeAdditionTip2:
      "Ajout temporisé: ajout automatique temporisé de données pour le contrôle de qualité",
    null: "Aucun",
    collcetedProps: "Champs d'acquisition",
    editingNameNow: "Un nom est en cours d'édition",
    upToFourLevels: "Classification jusqu'à quatre niveaux",
    default: "Par défaut",
    nameCantBeNull: "Le nom ne peut pas être vide",
    editingNow: "Il y a une action en cours d'édition",
    nameError: "Le nom est erroné",
    nameMaxLength8: "Nom longueur maximale 8",
    callTime: "Temps d'appel",
    sentimentValue: "Valeur du sentiment client",
    agentSentimentValue: "Valeur émotionnelle du service client",
    sentimentAnalysis: "Analyse des émotions",
    associatedData: "Données sur la route",
    notifyTheOwner: "Informer le maître du Groupe",
    notifyTheEmployee: "Informer les employés eux - mêmes",
    notifyTheOwnerTip:
      "Les propriétaires de groupe sont avertis lorsque le type de session est une conversation de groupe",
    customNotifyEmployee: "Notification personnalisée des employés",
    customNotifyEmployeeTip:
      "La personne déclenchant est l'employé, alors l'employé qui est informé de l'attribution du message lui - même",
    clickToLearnMore: "Cliquez pour en savoir plus",
    repeatMarkingTip:
      "Cet enregistrement a été marqué par ce point de contrôle de qualité, pas besoin de répéter l'étiquetage",
    successfulEvaluation: "Évaluer le succès",
    evaluationFailed: "L'évaluation échoue",
    qualityInspectionScore: "Score d'inspection de qualité",
    semanticTags: "Étiquettes sémantiques",
    smartTags: "Étiquettes intelligentes",
    collect: "Collecte d'informations",
    appealRecord: "Dossier de plainte",
    satisfy: "Satisfait",
    notSatisfy: "Pas satisfait",
    appealPlaceHolder: "Veuillez entrer le motif de la plainte",
    giveUp: "Abandonner",
    rejectReasonPlaceHolder: "Veuillez entrer un motif de rejet",
    reject: "Rejeté",
    changeRejectReason: "Modification des motifs de rejet",
    pass: "Par",
    revise: "Modifier",
    machineEvaluation: "Évaluation des machines",
    poor: "La différence",
    excellent: "Bon",
    moderate: "Général",
    mustMarked: "Ce point de contrôle de qualité doit être étiqueté",
    pleaseChoose: "Veuillez sélectionner",
    manualEvaluation: "Évaluation manuelle",
    qualityInspectionTemplate: "Modèle d'inspection de qualité applicable",
    collectionTemplate: "Modèles d'acquisition applicables",
    keywordsWordCloud: "Mots clés Cloud",
    editCategoryLabel: "Modifier Catégorie Tags",
    justCall: "Voir uniquement les appels",
    justConversation: "Voir seulement la conversation",
    batchOperation: "Opérations par lots",
    customerServicesGroup: "Groupe de service à la clientèle",
    joinGroup: "Rejoindre le Groupe",
    removeGroup: "Sortir du Groupe",
    enterInformationEntity: "Veuillez entrer l'information entité contenu",
    enterNotBeNull: "L'entrée ne peut pas être vide",
    operationGroup: "Groupe d'opérations",
    customerServiceSelected: "Service client sélectionné",
    callAndConversation: "Appels / Conversations",
    conversation: "Dialogue",
    system: "Système",
    customize: "Personnalisation",
    effective: "Efficace",
    invalid: "Invalide",
    successfulAndToTaskCenter:
      "Exécution réussie, consultez le Centre de mission",
    recalculationFailed: "La réévaluation échoue",
    selectRecalculatedIndicator:
      "Veuillez sélectionner les indicateurs à recalculer",
    source: "Veuillez sélectionner une source de données",
    placeholder: "Veuillez entrer un mot clé",
    cumulative: "Type cumulatif",
    average: "Type moyen",
    distribution: "Loi de distribution",
    originalDataCannotEmpty: "Les données brutes ne peuvent pas être vides",
    inspection: "Contrôle qualité",
    check: "Inspection par tirage",
    review: "Révision",
    draftSavedSuccessfully: "Brouillon sauvé avec succès",
    optimization: "Optimisation",
    agentName: "Nom du service à la clientèle",
    allTasks: "Toutes les tâches de contrôle qualité",
    datePickerSelect: "Choisissez une date",
    callSelect: "Choisir une conversation",
    taskSelect: "Choisir une tâche",
    genReport: "Générer un rapport",
    dataSource: "Source des données",
    callsTotal: "Total des appels",
    intelligentQualityInspection: "Contrôle de qualité intelligent",
    percentage: "Pourcentage",
    artificialSampling: "Inspection manuelle",
    appealNumber: "Volume des plaintes",
    totalConversation: "Total des conversations",
    samplingReviewRate: "Taux de reprise des tests",
    data: "Données",
    inspectionResults: "Résultats du contrôle qualité",
    inspected: "Inspection de qualité",
    totalScore: "Score total",
    learnMore: "Voir plus",
    inspectionEffect: "Effet du contrôle qualité",
    allTotal: "Total",
    manualInspection: "Ré - inspection manuelle",
    artificialReview: "Révision manuelle",
    approvalAmountAndRate: "Volume et taux d'approbation",
    customerScore: "Score du service à la clientèle",
    inspectionRecommendations: "Recommandations d'inspection de qualité",
    saveDraft: "Sauvegarder le brouillon",
    historicalAdvice: "Recommandations historiques",
    advicePlaceHolder: "Veuillez entrer une suggestion...",
    upload: "Téléchargement de fichiers",
    customAdd: "Ajouter un filtre",
    advancedConditions: "Conditions avancées",
    keyWord: "Mots clés",
    containsAll: "Contient tout",
    containsAny: "Contient n'importe quel",
    matchAny: "Match any",
    matchAll: "Match tous",
    smartTagsOne: "Smart label un",
    smartTagsTwo: "Étiquette intelligente II",
    season: "Saison",
    month: "Mois",
    week: "Semaine",
    day: "Jours",
    hour: "Lorsque",
    notMoreThan366: "Ne peut excéder 366 jours",
    propsCannotBeNull: "Il y a des champs non remplis",
    other: "Autres",
    correlationCoefficient: "Coefficient de corrélation",
    repetitionRate: "Taux de répétition",
    configurationItemsMoreThan20:
      "Ajouter jusqu'à 20 éléments de configuration",
    conversionAnalysis: "Analyse de transformation",
    correlationAnalysis: "Analyse de pertinence",
    successfulExportAndToTaskCenter:
      "Exportation réussie, rendez - vous dans le Centre de mission pour voir",
    typeSelect: "Choisissez le type",
    exportExcel: "Exporter Excel",
    exportPng: "Exporter le PNG",
    noDataChart: "Graphique pas de données pour le moment",
    placeOperator: "Veuillez sélectionner Smart label",
    placeAdvancedConditions: "Veuillez sélectionner une condition avancée",
    createAdvancedConditions: "Nouveau filtre avancé",
    moveUp: "Déplacement vers le Haut",
    moveDown: "Descendre",
    moveLeft: "Déplacement à gauche",
    moveRight: "Déplacement à droite",
    statusChangeSuccess: "Modification de statut réussie",
    editSuccess: "Modification réussie",
    saveSuccess: "Sauvegarder le succès",
    firstPage: "Accueil",
    lastPage: "Page de queue",
    customerInfo: "Informations pour les clients",
    type: "Type",
    NumberTypeErrorMessage:
      "Le contenu des informations de champ de type numérique ne peut contenir que des chiffres ou «. ».",
    taskStartPrompt: "Conseils d'ouverture de tâche",
    startConfirm:
      "Veuillez cliquer sur le bouton de confirmation pour ouvrir la tâche de contrôle de qualité",
    continueToSubmitWithoutEvaluation:
      "Aucune évaluation continuer à soumettre",
    rejectTip:
      "Aucune opération de changement n'a été détectée, confirmez - vous le rejet direct de tous les griefs?",
    unmarkedSelfLearningTip:
      "Vous avez des points d'auto - apprentissage non marqués",
    markedSuccessfully: "Marquer le succès",
    markingFailed: "Échec du marquage",
    exceedingLength: "Au - delà de la longueur",
    uncategorized: "Non classé",
    addSuccess: "Nouveau succès",
    deleteSuccess: "Supprimer avec succès",
    featureName: "Nom de l'élément",
    informationEntity: "Entité d'information",
    standardAnswer: "Réponse standard",
    keyElementAnswer: "Éléments clés réponses",
    generalStatement: "Présentation générale (sans demande)",
    generalQuestionsAndConfirmation: "Doutes généraux et confirmation",
    specialQuestionsAndAnswers: "Questions spéciales et réponses",
    agent: "Service à la clientèle",
    customer: "Le client",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Éléments clés Étiquettes sémantiques et entités d'information ne peuvent pas être vides en même temps",
    standardSentence: "Phrase standard",
    acquaintance: "Niveau de connaissance",
    smartUePlaceholder: "Veuillez entrer un nom pour Smart interactive",
    typeSelectPlaceholder: "Veuillez sélectionner le type",
    keyElement: "Éléments clés",
    reQuerySementicTags: "Sélectionner à nouveau les étiquettes sémantiques",
    querySementicTags: "Choisir une étiquette sémantique",
    QATestForm: "Formulaire de détection de questions et réponses",
    selectNotBeNull: "La sélection ne peut pas être vide",
    featureDetectionTarget: "Objectif de détection des éléments",
    categorized: "Classification",
    effectiveness: "Efficacité",
    hideAdvancedSettings: "Masquer les paramètres avancés",
    showAdvancedSettings: "Afficher les paramètres avancés",
    generalStatementOnly: "Présentation générale",
    generalQuestions: "Doutes généraux",
    affirmativeExpression: "Expression affirmative",
    negativeIntention: "Intention négative",
    searchSementicTags: "Étiquettes sémantiques de requête",
    choiceQuestion: "Phrases de doute de type choix",
    selectiveAnswer: "Type de réponse choisie",
    sementicTagsPlaceholder:
      "Veuillez entrer le contenu de l'étiquette sémantique",
    openSpecialQuestion: "Questions spéciales ouvertes",
    openAnswer: "Réponse ouverte",
    customQA: "Questions et réponses personnalisées",
    questions: "Méthode de demande",
    dataPlaceholder: "Veuillez entrer le contenu",
    reply: "Répondre",
    notFindSuitableLabel: "Pas trouvé le bon label",
    addSemanticTag: "Ajouter une nouvelle étiquette sémantique",
    intelligentInteraction: "Interaction intelligente",
    matching: "Match",
    bout: "Une fois",
    keywordMatch: "Correspondance de mots clés",
    addFilterConditions: "Ajouter des critères de filtre",
    buttonStartCollectionTask:
      "Veuillez cliquer sur le bouton OK pour ouvrir la tâche d'acquisition",
    buttonStartTask:
      "Veuillez cliquer sur le bouton OK pour ouvrir la tâche de contrôle de qualité",
    ruleConfigurationIsRequired: "Règle configurée comme obligatoire",
    asrRunning: "ASR exécution",
    errorCorrectionExecution: "Correction d'erreurs exécution",
    emotionalVolumeRecognition: "Reconnaissance du volume émotionnel",
    dataPreprocessing: "Pré - traitement des données",
    inspectionExecution: "Exécution des contrôles de qualité",
    businessId: "Business id",
    businessTime: "Temps d'affaires",
    createTime: "Temps de création",
    abnormalReason: "Causes anormales",
    singleChat: "Une seule conversation",
    groupChat: "Discussion de groupe",
    taskContent: "Contenu de la tâche",
    submissionTime: "Heure de soumission",
    startTime: "Heure de début",
    endTime: "Heure de fin",
    runningTime: "Temps de fonctionnement",
    totalAmountOfData: "Volume total de données",
    resultsOfThe: "Résultats de la mise en œuvre",
    operator: "Opérateur",
    taskDetails: "Détails de la Mission",
    executiveFunction: "Fonctions exécutives",
    qualityCheckTasks: "Tâches d'inspection de la qualité",
    implementationDetails: "Détails de l'exécution",
    conditionalRules: "Règles des conditions",
    smartCondition: "Conditions intelligentes",
    goThis: "Déplacer ici",
    pleaseMarkClassification: "Veuillez noter la classification",
    advancedScreening: "Filtrage avancé",
    noDataToManipulate: "Pas de données à manipuler",
    concept: "Mots conceptuels",
    checkPoint1: "Configuration des règles",
    requiredFieldsCannotEmpty:
      "Les champs obligatoires ne peuvent pas être vides",
    rule: "Règles",
    afterFixUnit: "Un mot",
    unmarkedSelfLearningPointsTip:
      "Vous avez des points d'auto - apprentissage non marqués",
    onlyReservedFor7Days: "(réservé 7 jours seulement)",
    downloadError: "Le téléchargement a échoué",
    pause: "Suspension",
    continue: "Continuer",
    recalcScore: "Recalculer les scores uniquement (avec les notations)",
    recalculatePointsAndScores:
      "Re - comptabilisation des points de contrôle de qualité et des scores",
    recalculatePointsAndScoresWithEmtry:
      "Recalculer les points de contrôle de qualité et les scores (les points de contrôle de qualité sont vides)",
    inProgress: "En cours",
    toBeExecuted: "À exécuter",
    executeImmediately: "Exécuter immédiatement",
    specialElements: "Éléments spéciaux",
    logicRule: "Règles logiques",
    every: "Correspondances multiples",
    hitRecall: "Mots de rappel",
    notHitRecall: "Mot de rappel manqué",
    hitRecallRegular: "Règle de rappel",
    notHitRecallRegular: "Règle de l'échec",
    hitRule: "Règles de Hit",
    notHitRule: "Règle de l'échec",
    hitSimilarSentence: "Frapper des phrases similaires",
    notHitSimilarSentence: "Phrases similaires manquées",
    hitExcludeSimilarSentences: "Exclure les phrases similaires",
    notHitExcludeSimilarSentences: "Échec exclure les phrases similaires",
    updateSuccess: "Mise à jour réussie",
    qualify: "Régulière",
    recallKeyWord: "Mots clés de rappel",
    grammar: "Règles de droit",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Le changement de validité a échoué, Veuillez réessayer",
    postAutoMateTasksCaseByTaskId:
      "La sauvegarde de la configuration de synchronisation de la Bibliothèque de cas a échoué",
    getAutoMateTasksCaseByTaskId:
      "L'obtention de la configuration synchrone de la Bibliothèque de cas a échoué",
    postInspectionRemarkModelConfigs:
      "L'enregistrement du modèle de note a échoué",
    putInspectionRemarkModelConfigsById:
      "L'enregistrement du modèle de note a échoué",
    getInspectionRemarkModelConfigsById:
      "L'obtention des données du modèle de note a échoué",
    postKnowledgeDatas: "Base de connaissances New failed, réessayez",
    putKnowledgeDatasById:
      "La modification de la base de connaissances a échoué, Veuillez réessayer",
    getLibraryFiles: "L'obtention de la liste des prix a échoué",
    getLibraryCalls: "L'obtention du fichier a échoué",
    getLibraryFilesSearch: "La recherche a échoué",
    putLibraryFilesMoveById: "Le Mouvement du clip de demande de prix a échoué",
    putLibraryCallsById: "Le renommage du fichier a échoué!",
    postLibraryFiles: "L'ajout d'un dossier a échoué",
    putLibraryFilesById: "Le renommage du dossier a échoué",
    deleteLibraryFilesById: "La suppression a échoué",
    deleteLibraryCallsById: "La suppression a échoué",
    postLibraryCalls: "L'ajout a échoué",
    getVoiceUploadResultById:
      "L'exportation des résultats de téléchargement a échoué",
  },
};
