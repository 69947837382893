import React, { FC, memo, useMemo, useState } from 'react';
import styled from 'styled-components';
import AutoSizeTable, { TableProps } from 'Component/common/auto-size-table';
import { Button, Popconfirm, Space, Switch } from 'udesk-ui';
import CustomFilterText from 'Component/pages/components/custom-filter-text';
import Filed from './components/Field';
import { FiledType, Mode, Enable } from './components/Field/const';
import { TablePaginationConfig } from 'udesk-ui/lib/table';
import { getCallServiceSummaryFields } from 'src/api/callServiceSummaryFields';
import { putCallServiceSummaryFieldsEnableStatusById } from 'src/api/callServiceSummaryFields/enableStatus/{id}';
import { deleteCallServiceSummaryFieldsById } from 'src/api/callServiceSummaryFields/{id}';
import { CallServiceSummaryFieldFoundResponse } from 'src/api/types';
import { useRequest } from 'ahooks';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div``;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

const Title = styled.div``;

const Extra = styled.div``;

type TemplateProps = {
    type?: number;
    taskId?: number;
};

const Template: FC<TemplateProps> = (props) => {
    const { type, taskId } = props;

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const [dataSource, setDataSource] = useState<CallServiceSummaryFieldFoundResponse[]>([]);

    const { loading: getLoading } = useRequest(
        () =>
            getCallServiceSummaryFields({
                params: {
                    taskId: taskId!,
                    pageNum: pagination.current,
                    pageSize: pagination.pageSize,
                },
            }),
        {
            ready: !!taskId,
            refreshDeps: [pagination],
            onSuccess: (res) => {
                setDataSource(res.data || []);
            },
        }
    );

    const onChange: TableProps<CallServiceSummaryFieldFoundResponse>['onChange'] = (
        paginate,
        filters,
        sorter,
        { action }
    ) => {
        if (action === 'paginate') {
            setPagination(paginate);
        }
    };

    const [putLoading, sePputLoading] = useState(false);

    const onEnableChange = (id?: number, isEnable?: boolean) => {
        if (id) {
            sePputLoading(true);
            putCallServiceSummaryFieldsEnableStatusById(
                { isEnable: isEnable ? Enable.OPEN : Enable.CLOSE },
                { segments: { id } }
            )
                .then(() => {
                    setPagination((p) => ({ ...p }));
                })
                .finally(() => {
                    sePputLoading(false);
                });
        }
    };

    const [delLoading, setDelLoading] = useState(false);

    const onDel = (id?: number) => {
        if (id) {
            setDelLoading(true);
            deleteCallServiceSummaryFieldsById({ segments: { id } })
                .then(() => {
                    setPagination((p) => ({ ...p }));
                })
                .finally(() => {
                    setDelLoading(false);
                });
        }
    };

    const [fieldId, setFieldId] = useState<number>();
    const [isEdit, setIsEdit] = useState(false);

    const onAdd = () => {
        setFieldId(undefined);
        setIsEdit(true);
    };

    const onEdit = (id?: number) => {
        setFieldId(id);
        setIsEdit(true);
    };

    const onBack = () => {
        setIsEdit(false);
        setPagination((p) => ({ ...p }));
    };

    const columns = useMemo<TableProps<CallServiceSummaryFieldFoundResponse>['columns']>(() => {
        return [
            {
                title: /* 字段名称 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.fieldName,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: /* 字段描述 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.fieldDescription,
                dataIndex: 'description',
                key: 'description',
                render: (v, r) => {
                    return r.description ?? /* 无 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.nothing;
                },
            },
            {
                title: /* 生成方式 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.generationMethod,
                dataIndex: 'generatedMethod',
                key: 'generatedMethod',
                render: (v, r) => {
                    return r.generatedMethod === Mode.MANUAL ? /* 手动生成 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.generateManually : /* 自动生成 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.automaticGeneration;
                },
            },
            {
                title: /* 生成内容 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.generateContent,
                dataIndex: 'generatedContent',
                key: 'generatedContent',
                render: (v, r) => {
                    const isLimit = r.generatedContent?.length;
                    return isLimit ? r.generatedContent?.join(',') : /* 不限定 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.notQualified;
                },
            },
            {
                title: /* 筛选条件 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.filterCondition,
                dataIndex: 'filterCondition',
                key: 'filterCondition',
                render: (v, r) => {
                    return r.filterCondition ? <CustomFilterText {...r.filterCondition} /> : /* 无 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.nothing;
                },
            },
            {
                title: /* 是否启用 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.enabled,
                dataIndex: 'isEnable',
                key: 'isEnable',
                render: (v, r) => {
                    return (
                        <Switch
                            checked={r.isEnable === Enable.OPEN}
                            onChange={(c) => onEnableChange(r.id, c)}
                        />
                    );
                },
            },
            {
                title: /* 类型 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.type,
                dataIndex: 'type',
                key: 'type',
                render: (v, r) => {
                    return r.type === FiledType.SYSTEM
                        ? /* 系统 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.system
                        : r.type === FiledType.CUSTOM
                        ? /* 自定义 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.custom
                        : '-';
                },
            },
            {
                title: /* 操作 */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.operation,
                key: 'action',
                render: (v, r) => {
                    return (
                        <Space>
                            <Button type="link" style={{ padding: 0 }} onClick={() => onEdit(r.id)}>{/* 编辑 */}{UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.edit}</Button>
                            <Popconfirm title={/* 确定删除吗？ */UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.areYouSureToDeleteIt} onConfirm={() => onDel(r.id)}>
                                <Button
                                    type="link"
                                    danger
                                    disabled={r.type === FiledType.SYSTEM}
                                    style={{ padding: 0 }}
                                >{/* 删除 */}{UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.delete}</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            },
        ];
    }, []);

    return (
        <Wrapper>
            {!isEdit ? (
                <>
                    <Header>
                        <Title></Title>
                        <Extra>
                            <Space>
                                <Button type="link" onClick={onAdd}>{/* 增加字段 */}{UdeskLocales['current'].pages.tasks.manage.template.automaticAdd.businessSummary.index.addField}</Button>
                            </Space>
                        </Extra>
                    </Header>

                    <AutoSizeTable
                        columns={columns}
                        dataSource={dataSource}
                        loading={getLoading || putLoading || delLoading}
                        pagination={pagination}
                        onChange={onChange}
                    />
                </>
            ) : (
                <Filed type={type} onBack={onBack} id={fieldId} taskId={taskId} />
            )}
        </Wrapper>
    );
};

export default memo(Template);
