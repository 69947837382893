// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetWecomMessageFiltersByIdParam,
    BaseResponseWecomMessageFilterFoundResponse,
    PutWecomMessageFiltersByIdParam,
    PutWecomMessageFiltersByIdBody,
    BaseResponseVoid,
    DeleteWecomMessageFiltersByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags wecom-message-filter-controller
 * @link [GET] /wecomMessageFilters/{id}
 * @param id
 */
export function getWecomMessageFiltersById(
    options: ApiOptions<GetWecomMessageFiltersByIdParam, never> & {
        segments: GetWecomMessageFiltersByIdParam;
    }
): Promise<BaseResponseWecomMessageFilterFoundResponse> {
    return request<never, GetWecomMessageFiltersByIdParam, never>('/wecomMessageFilters/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getWecomMessageFiltersById',
    });
}

/**
 * update
 *
 * @export
 * @tags wecom-message-filter-controller
 * @link [PUT] /wecomMessageFilters/{id}
 * @param id
 */
export function putWecomMessageFiltersById(
    data: PutWecomMessageFiltersByIdBody,
    options: ApiOptions<PutWecomMessageFiltersByIdParam, never> & {
        segments: PutWecomMessageFiltersByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutWecomMessageFiltersByIdBody, PutWecomMessageFiltersByIdParam, never>(
        '/wecomMessageFilters/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putWecomMessageFiltersById' }
    );
}

/**
 * deleteById
 *
 * @export
 * @tags wecom-message-filter-controller
 * @link [DELETE] /wecomMessageFilters/{id}
 * @param id
 */
export function deleteWecomMessageFiltersById(
    options: ApiOptions<DeleteWecomMessageFiltersByIdParam, never> & {
        segments: DeleteWecomMessageFiltersByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteWecomMessageFiltersByIdParam, never>('/wecomMessageFilters/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteWecomMessageFiltersById',
    });
}

export const meta = [
    { tags: ['wecom-message-filter-controller'], path: '/wecomMessageFilters/{id}', method: 'get' },
    { tags: ['wecom-message-filter-controller'], path: '/wecomMessageFilters/{id}', method: 'put' },
    {
        tags: ['wecom-message-filter-controller'],
        path: '/wecomMessageFilters/{id}',
        method: 'delete',
    },
];
