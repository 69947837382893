import Udesk from 'Udesk';
import LoginComponent from './component';

export default class LoginRoute extends LoginComponent {
    model() {
        let params = Object.assign(
            {},
            this.props.match.params,
            Object.fromEntries(new URLSearchParams(this.props.location.search))
        );
        return new Promise((resolve, reject) => {
            Udesk.ajax.get(Udesk.business.apiPath.concatApiPath('/isLogin', this.props.sdkOptions)).then(
                () => {
                    if (params.refer) {
                        window.location.href = params.refer;
                    } else {
                        window.location.href = '/entry-page';
                    }
                    reject();
                },
                reason => {
                    resolve({
                        refer: params.refer ? params.refer : ''
                    });
                }
            );
        });
    }
}
