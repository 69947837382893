// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerTaskInfosSyncConfigQuery,
    BaseResponseVoid,
} from '../../../types';

/**
 * 同步配置校验
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/sync/config
 */
export function getIntelligentPartnerTaskInfosSyncConfig(
    options: ApiOptions<never, GetIntelligentPartnerTaskInfosSyncConfigQuery> & {
        params: GetIntelligentPartnerTaskInfosSyncConfigQuery;
    }
): Promise<BaseResponseVoid> {
    return request<never, never, GetIntelligentPartnerTaskInfosSyncConfigQuery>(
        '/intelligentPartnerTaskInfos/sync/config',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerTaskInfosSyncConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/sync/config',
        method: 'get',
    },
];
