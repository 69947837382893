import React from 'react';
import { Space, Icon, Button, Tooltip, Progress, Tag } from 'udesk-ui';
import './common.scss';
import { CheckCircleTwoTone } from '@ant-design/icons';
import Locales from 'UdeskLocales';
import { isDefined } from 'src/util/core';
import DialogIcon from 'Component/common/gong/dialog-icon';

const TimeText = React.memo<{ time: number }>((props) => {
    const temp = parseInt((props.time / 1000).toString()) ?? 0;
    return (
        <span className="time-durtion">
            {temp / 60 <= 1 ? temp.toFixed(0) + 's' : (temp / 60).toFixed(0) + 'm'}
        </span>
    );
});

const CallListItem = (props) => {
    const {
        isHome,
        tag,
        inspectDataSource,
        channelType,
        agentName,
        customerName,
        date,
        asrVoiceDuration = 0,
        callId,
        call_duration,
        total_time,
        start_time,
        agent,
        customer_nick_name,
        // smartTags,
        call_time,
        commentCount,
        keyEvents,
        superversion = 0,
        imCount = 0,
        studied,
    } = props;
    const locales = Locales['current'];

    const renderIcon = (channelType, inspectDataSource) => {
        switch (channelType) {
            case 2: // 企微通话
            case 0: // 电话
                return (
                    <>
                        <DialogIcon
                            inspectDataSource={inspectDataSource}
                            channelType={channelType}
                        />
                        <TimeText
                            time={
                                isHome
                                    ? asrVoiceDuration
                                    : (parseInt(
                                          isDefined(call_duration, call_duration, total_time)
                                      ) ?? 0) * 1000
                            }
                        />
                    </>
                );
            case 1: // 企微文本
                return (
                    <>
                        <DialogIcon
                            inspectDataSource={inspectDataSource}
                            channelType={channelType}
                        />
                        <span className="time-durtion">
                            {imCount}
                            {/* 条 */}
                            {locales.pages.gong.homePage.common.callListItem.strip}
                        </span>
                    </>
                );

            default:
                return null;
        }
    };
    const smartTagsMoreItem = (result, limit) => {
        return (
            <React.Fragment>
                {result?.length && result?.length > limit ? (
                    <Tooltip
                        placement="bottom"
                        color="#fff"
                        title={
                            <div
                                className="more-list-btn"
                                style={{ marginRight: '-8px', marginBottom: '-4px' }}
                            >
                                {!!result?.length &&
                                    result.slice(limit).map((record) => {
                                        return (
                                            <Tag
                                                color={record.categoryColor + '1a'}
                                                key={record.id}
                                                style={{
                                                    background: record.categoryColor + '1a',
                                                    color: record.categoryColor,
                                                    marginBottom: 4,
                                                }}
                                            >
                                                {record.tagName}{' '}
                                                <span style={{ margin: '5px' }}>|</span>{' '}
                                                {record.sentencesIndices?.length ?? 0}
                                            </Tag>
                                        );
                                    })}
                            </div>
                        }
                    >
                        <Tag>{`...   +${result?.length - limit}`}</Tag>
                    </Tooltip>
                ) : null}
            </React.Fragment>
        );
    };
    const handlerCallDetail = () => {
        props.handlerCallDetail && props.handlerCallDetail(callId);
    };

    const displayAgentName = (isHome ? agentName : agent) || '--';
    const displayCustomerName = isHome
        ? customerName
            ? ' | ' + customerName
            : ''
        : customer_nick_name
        ? ' | ' + customer_nick_name
        : '';
    return (
        <div onClick={handlerCallDetail} className="call-list-item-common-wanan">
            <div className="item item-flex item-base-info">
                <div className="all-cal-item-images">
                    {renderIcon(channelType, inspectDataSource)}
                    {Boolean(studied) && (
                        <Button
                            size="small"
                            type="text"
                            style={{ position: 'absolute', top: 0, right: 0 }}
                            icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                            tooltip={
                                /* 已学习 */ locales.pages.gong.homePage.common.callListItem.learned
                            }
                        />
                    )}
                </div>
                <div className="call-title" style={{ flex: 1, overflow: 'hidden' }}>
                    <div style={{ display: 'flex', overflow: 'hidden' }}>
                        <div
                            title={displayAgentName}
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: displayCustomerName ? '50%' : '100%',
                            }}
                        >
                            {displayAgentName}
                        </div>
                        <div
                            title={displayCustomerName}
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: displayAgentName ? '50%' : '100%',
                            }}
                        >
                            {displayCustomerName}
                        </div>
                    </div>
                    <div className="time-wanna">
                        <span className="call-time">{isHome ? date : call_time || start_time}</span>
                        <span className="call-commont">
                            {/* <img
                                src={require('../../../../../static/img/recommend-blue.svg').default}
                                width={16}
                                alt=''
                            /> */}
                            <Icon
                                type="ic-ask-pinglun"
                                style={{ color: '#1A6EFF', fontSize: '16px' }}
                            />
                            <span>{commentCount}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="item" style={{ flex: 1, overflowX: 'hidden' }}>
                <div className="title-wanna">
                    <span>
                        {/* 关键事件 */}
                        {locales.pages.gong.homePage.common.callListItem.keyEvents}
                    </span>
                    <span>|</span>
                    <span>{keyEvents?.length ?? 0}</span>
                </div>
                <div className="content-desc">
                    {isHome ? (
                        <Space wrap>
                            {!!tag?.length &&
                                tag.map((record) => {
                                    return (
                                        <Button
                                            key={record.id}
                                            style={{
                                                background: record.categoryColor + '1a',
                                                color: record.categoryColor,
                                                border: `1px solid ${record.categoryColor}`,
                                            }}
                                            className="call-icon"
                                            size="small"
                                        >
                                            {record.tagName}
                                        </Button>
                                    );
                                })}
                        </Space>
                    ) : (
                        <div>
                            {!!keyEvents?.length &&
                                keyEvents.slice(0, 3).map((record) => {
                                    return (
                                        <Tag
                                            color={record.categoryColor + '1a'}
                                            key={record.id}
                                            style={{
                                                color: record.categoryColor,
                                                marginBottom: 4,
                                                display: 'inline-flex',
                                                overflow: 'hidden',
                                                maxWidth: '100%',
                                            }}
                                        >
                                            <div
                                                title={record.tagName}
                                                style={{
                                                    flex: 1,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {record.tagName}
                                            </div>
                                            <span style={{ margin: '0 5px' }}>|</span>
                                            <div>{record.sentencesIndices?.length ?? 0}</div>
                                        </Tag>
                                    );
                                })}
                            {smartTagsMoreItem(keyEvents, 3)}
                        </div>
                    )}
                </div>
            </div>
            <div className="item">
                <div className="title-wanna" style={{ textAlign: 'center' }}>
                    <span>
                        {/* 监督项总执行率 */}
                        {
                            locales.pages.gong.homePage.common.callListItem
                                .totalImplementationRateOfSupervisionItems
                        }
                    </span>
                </div>
                <div className="content-desc content-desc-flex">
                    <Progress width={52} type="circle" percent={superversion} />
                </div>
            </div>
        </div>
    );
};

export default CallListItem;
