import React from 'react';
import Udesk from 'Udesk';

class InformationEntitiesDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        sentenceList: []
    };

    actions = {
        saveEntity() {
            let {
                locales
            } = this;
            let {
                sdkOptions
            } = this.props;
            let {
                // sentenceList,
                asyncModel
            } = this.privates;
            if (asyncModel.name == null || asyncModel.name.trim() === "") {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.informationEntities.detail.name)
                );
            }
            if (asyncModel.apiName == null || asyncModel.apiName.trim() === "") {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.informationEntities.detail.apiName)
                );
            }

            let sentenceList = this.privates.asyncModel.sentenceList;
            if (sentenceList && sentenceList.length > 0) {
                sentenceList = sentenceList.filter(item => {
                    return item.content.trim() !== "";
                });
            }
            asyncModel.sentenceList = sentenceList;
            let params = asyncModel;
            // let params = {
            //     id: asyncModel.id,
            //     name: asyncModel.name,
            //     apiName: asyncModel.apiName,
            //     activeFlag: asyncModel.activeFlag,
            //     remark: asyncModel.remark,
            //     logicExp: asyncModel.logicExp,
            //     sentenceList,
            // };
            // params.systemType = Udesk.data.init.company.systemType;
            
            let url = Udesk.business.apiPath.concatApiPath(`information-entity/save`, sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            if (asyncModel.id) {
                url = Udesk.business.apiPath.concatApiPath(`information-entity/update`, sdkOptions);
                method = Udesk.enums.requestMethods.update.name;
            }

            Udesk.ajax(url, params, method).then(
                (resp) => {
                    Udesk.ui.notify.success(Udesk.utils.string.format(locales.business.notifyMessage.saveSuccessFormat, locales.components.pages.informationEntities.title));
                    this.actions.backToIndex();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(locales.business.notifyMessage.saveFailedFormat, locales.components.pages.informationEntities.title));
                }
            );
        },
        backToIndex() {
            let routeOptions = {
                history: this.props.history,
                routeName: "informationEntitiesIndex",
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sentenceValueChange(index, value) {
            let {
                sentenceList
            } = this.privates.asyncModel;
            sentenceList[index].content = value;
            this.actions.update();
        },
        addSentence() {
            let {
                sentenceList
            } = this.privates.asyncModel;
            if (Array.isArray(sentenceList) && sentenceList.length >= 2000) {
                return;
            }
            sentenceList.unshift({
                content: ''
            });
            this.actions.update();
        },
        sentenceEmptyErrorTip() {
            Udesk.ui.notify.error(
                Udesk.utils.string.format(this.locales.business.notifyMessage.canNotBeEmptyFormat, this.locales.components.pages.informationEntities.detail.sentenceList)
            );
        },
        deleteSentence(index) {
            let {
                sentenceList
            } = this.privates.asyncModel;
            sentenceList.splice(index, 1);
            this.actions.update();
        },
        changeActiveFlag(value) {
            this.privates.asyncModel.activeFlag = value;
            this.actions.update();
        }
    };

    //#region Life Cycle
    componentDidMount() {
        let activeFlags = Udesk.enums.activeFlags;
        activeFlags = activeFlags.map(item => {
            return {
                id: item.id,
                name: item.name
            };
        });
        this.privates.activeFlags = activeFlags;
        this.actions.update();
    }
    componentWillUnmount() { }
    //#endregion
}

export default InformationEntitiesDetailComponent;