// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetIntelligentPartnerCustomerPersonasQuery,
    BaseResponseListIntelligentPartnerCustomerPersonaFoundResponse,
    PostIntelligentPartnerCustomerPersonasBody,
    BaseResponseIntelligentPartnerCustomerPersonaFoundResponse,
} from '../types';

/**
 * 查询客户画像列表
 *
 * @export
 * @tags 智能陪练-客户画像配置
 * @link [GET] /intelligentPartnerCustomerPersonas
 */
export function getIntelligentPartnerCustomerPersonas(): Promise<BaseResponseListIntelligentPartnerCustomerPersonaFoundResponse>;
export function getIntelligentPartnerCustomerPersonas(
    options: ApiOptions<never, GetIntelligentPartnerCustomerPersonasQuery>
): Promise<BaseResponseListIntelligentPartnerCustomerPersonaFoundResponse>;
export function getIntelligentPartnerCustomerPersonas(
    options?: ApiOptions<never, GetIntelligentPartnerCustomerPersonasQuery>
): Promise<BaseResponseListIntelligentPartnerCustomerPersonaFoundResponse> {
    return request<never, never, GetIntelligentPartnerCustomerPersonasQuery>(
        '/intelligentPartnerCustomerPersonas',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentPartnerCustomerPersonas' }
    );
}

/**
 * 新建客户画像
 *
 * @export
 * @tags 智能陪练-客户画像配置
 * @link [POST] /intelligentPartnerCustomerPersonas
 */
export function postIntelligentPartnerCustomerPersonas(
    data: PostIntelligentPartnerCustomerPersonasBody
): Promise<BaseResponseIntelligentPartnerCustomerPersonaFoundResponse>;
export function postIntelligentPartnerCustomerPersonas(
    data: PostIntelligentPartnerCustomerPersonasBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerCustomerPersonaFoundResponse>;
export function postIntelligentPartnerCustomerPersonas(
    data: PostIntelligentPartnerCustomerPersonasBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerCustomerPersonaFoundResponse> {
    return request<PostIntelligentPartnerCustomerPersonasBody, never, never>(
        '/intelligentPartnerCustomerPersonas',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerCustomerPersonas',
        }
    );
}

export const meta = [
    { tags: ['智能陪练-客户画像配置'], path: '/intelligentPartnerCustomerPersonas', method: 'get' },
    {
        tags: ['智能陪练-客户画像配置'],
        path: '/intelligentPartnerCustomerPersonas',
        method: 'post',
    },
];
