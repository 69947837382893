import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import XssComponent from 'udesk-react/src/components/xss';
import UdeskLocales from 'UdeskLocales';
import isEqual from 'lodash-es/isEqual';
import { Button, Icon, InputNumber, Popover, Space, Spin, Tag, Tooltip } from 'udesk-ui';
import { createClassName, inspect, isDefined, isNotEmpty, isEmpty } from 'src/util/core';
import { ComplainButton } from 'Component/pages/components/quality-score/components/ComplainButton';
import './index.less';
import { SemanticTagTest } from 'Component/pages/components/SemanticTagTest';
import { hasFeature } from 'src/util/permit';
import {
    useSpeachRemark,
    RemarkType,
} from 'src/pages/coach/learning-center/record/detail/components/EvaluationSuggestion';

const noop = () => ({});

const HIT = Udesk.enums.scoreRuleHit.hit.id;
const NO_HIT = Udesk.enums.scoreRuleHit.noHit.id;

const ADD = Udesk.enums.scoreRuleOperator.add.id;
const SUB = Udesk.enums.scoreRuleOperator.sub.id;

const ADD_TEXT = Udesk.enums.scoreRuleOperator.add.name;
const SUB_TEXT = Udesk.enums.scoreRuleOperator.sub.name;

export const ScoreDetail = (props) => {
    const {
        isChangeMatchResult,
        isAllOpen = true,
        isTest = false,
        isEdit = false,
        isAudit,
        isReview,
        align = 'right',
        width = '70%',
        onNodeConfigClick,
        setPropValue = noop,
        getPropValue = noop,
        getPropValueWithDataSource = noop,
        data,
        isNodeConfigShow = true,
        recordId,
        id,
        enableSingle,
        enableRealTimeReview,
        realTimeReview,
        isShare = false
    } = props;

    const { knowLedgeScoreResult } = data || {};

    const cardPaddingWidth = useMemo(() => {
        let num = width;
        if (typeof num === 'number') {
            num = `${num}px`;
        }
        return `calc(100% - ${num})`;
    }, [width]);

    const getWordsScore = useCallback(() => {
        return isDefined(
            data?.wordsScore,
            data?.wordsScore, // 非计算得分，由后端获取
            // 计算得分，备用方案， 以下代码不会执行，只提供计算思路
            () => {
                return data?.scoreRuleResultList?.reduce((total, item) => {
                    if (item.hit && data?.isQuestionCloselyScore) {
                        return total + item.score;
                    }
                    return total;
                }, 0);
            }
        );
    }, [data]);

    const getMatchRate = useCallback(() => {
        if (!data?.wordsTotalPoints) {
            return 0;
        }
        return Math.round((getWordsScore() / data?.wordsTotalPoints) * 10000) / 100;
    }, [data?.wordsTotalPoints, getWordsScore]);

    const isKnowLedge = useMemo(() => !!knowLedgeScoreResult, [knowLedgeScoreResult]);

    const appealContent = getPropValue('.appealContent').value; // 申诉
    const appealContentWithDataSource = getPropValueWithDataSource('.appealContent').value;

    const isChanged = !isEqual(appealContent, appealContentWithDataSource);

    const wordsScoreRender = useCallback(
        (isOpen, click) => {
            return (
                <Space className="score-detail-item" direction="vertical">
                    <Button
                        onClick={click}
                        type="link"
                        icon={<Icon type={'MessageFilled'} antdIcon={true} />}
                    >
                        <div className="score-detail-item-title">
                            <span>
                                {/* 话术得分 */}
                                {
                                    UdeskLocales['current'].components.coach.dialogue.components
                                        .scoreDetail.index.scriptScore
                                }
                                {inspect(data?.isEnableQuestionClosely, () => {
                                    return inspect(
                                        data?.isQuestionCloselyScore,
                                        () => {
                                            return (
                                                <Tag
                                                    style={{
                                                        fontWeight: 400,
                                                        marginLeft: 8,
                                                        borderColor: 'transparent',
                                                    }}
                                                    color={'blue'}
                                                >
                                                    {/* 追问 */}
                                                    {
                                                        UdeskLocales['current'].components.coach
                                                            .dialogue.components.scoreDetail.index
                                                            .interrogation
                                                    }
                                                </Tag>
                                            );
                                        },
                                        () => {
                                            return (
                                                <Tag
                                                    style={{
                                                        fontWeight: 400,
                                                        marginLeft: 8,
                                                        borderColor: 'transparent',
                                                    }}
                                                    color={'red'}
                                                >
                                                    {/* 追问不计分 */}
                                                    {
                                                        UdeskLocales['current'].components.coach
                                                            .dialogue.components.scoreDetail.index
                                                            .questioningWithoutScoring
                                                    }
                                                </Tag>
                                            );
                                        }
                                    );
                                })}
                            </span>
                            <Space>
                                {isKnowLedge ? (
                                    <span>
                                        <span className="font-blue">
                                            {knowLedgeScoreResult?.score}
                                        </span>
                                        /{knowLedgeScoreResult?.totalScore || 0}
                                        {/* 分 */}
                                        {
                                            UdeskLocales['current'].components.coach.dialogue
                                                .components.scoreDetail.index.branch
                                        }
                                    </span>
                                ) : (
                                    <>
                                        <span>
                                            <span className="font-blue">{getWordsScore()}</span>/
                                            {data?.wordsTotalPoints}
                                            {/* 分 */}
                                            {
                                                UdeskLocales['current'].components.coach.dialogue
                                                    .components.scoreDetail.index.branch
                                            }
                                        </span>
                                        <span>
                                            <span className="font-blue">{getMatchRate()}%</span>
                                            {/* 匹配率 */}
                                            {
                                                UdeskLocales['current'].components.coach.dialogue
                                                    .components.scoreDetail.index.matchingRate
                                            }
                                        </span>
                                    </>
                                )}
                            </Space>
                        </div>
                    </Button>
                    {isOpen && (
                        <>
                            <Space className="standard-words">
                                <span className="standard-words-title">
                                    {/* 标准话术 */}
                                    {
                                        UdeskLocales['current'].components.coach.dialogue.components
                                            .scoreDetail.index.standardScript
                                    }
                                </span>
                                <XssComponent value={data?.standardWords} />
                            </Space>
                            <ScoreRuleResultFormat
                                list={data?.scoreRuleResultList}
                                isEdit={isEdit}
                                setPropValue={setPropValue}
                                isTest={isTest}
                            />
                            <KnowLedgeScoreResultFormat
                                data={data?.knowLedgeScoreResult}
                                isEdit={isEdit}
                                setPropValue={setPropValue}
                                isTest={isTest}
                            />
                            <WordsViolationScoreResultFormat
                                list={data?.wordsViolationScoreResultList}
                                isEdit={isEdit}
                                setPropValue={setPropValue}
                            />
                        </>
                    )}
                </Space>
            );
        },
        [
            isEdit,
            isTest,
            setPropValue,
            getWordsScore,
            getMatchRate,
            data?.knowLedgeScoreResult,
            data?.scoreRuleResultList,
            data?.standardWords,
            data?.wordsTotalPoints,
            data?.wordsViolationScoreResultList,
            data?.isEnableQuestionClosely,
            data?.isQuestionCloselyScore,
            isKnowLedge,
            knowLedgeScoreResult?.score,
            knowLedgeScoreResult?.totalScore,
        ]
    );

    const emotionScoreRender = useCallback(
        (isOpen, click) => {
            const nextValue =
                data?.sentimentValue === Udesk.enums.sentimentValues.positive.id
                    ? Udesk.enums.sentimentValues.negative.id
                    : Udesk.enums.sentimentValues.positive.id;

            return (
                <Space className="score-detail-item" direction="vertical">
                    <Button
                        onClick={click}
                        type="link"
                        icon={
                            <Icon
                                type={
                                    data?.sentimentValue === Udesk.enums.sentimentValues.negative.id
                                        ? 'FrownFilled'
                                        : 'SmileFilled'
                                }
                                antdIcon={true}
                            />
                        }
                    >
                        <div className="score-detail-item-title">
                            <span>
                                {/* 情感得分 */}
                                {
                                    UdeskLocales['current'].components.coach.dialogue.components
                                        .scoreDetail.index.emotionalScore
                                }
                            </span>
                            <Space>
                                <span>
                                    <span
                                        className={
                                            data?.sentimentDeduction === 0
                                                ? 'font-blue'
                                                : 'font-red'
                                        }
                                    >
                                        -{data?.sentimentDeduction}
                                    </span>
                                    {/* 分 */}
                                    {
                                        UdeskLocales['current'].components.coach.dialogue.components
                                            .scoreDetail.index.branch
                                    }
                                </span>
                            </Space>
                        </div>
                    </Button>
                    {isOpen && (
                        <>
                            <Space className="standard-words">
                                <span
                                    className={
                                        data?.sentimentDeduction === 0 ? 'font-blue' : 'font-red'
                                    }
                                >
                                    <EditTooltip
                                        isEdit={isEdit}
                                        text={
                                            /* 改为 */ UdeskLocales['current'].components.coach
                                                .dialogue.components.scoreDetail.index.changeTo
                                        }
                                        btnText={Udesk.enums.sentimentValues.getName(nextValue)}
                                        onClick={() => setPropValue('sentimentValue', nextValue)}
                                    >
                                        {Udesk.enums.sentimentValues.getName(data?.sentimentValue)}
                                    </EditTooltip>
                                </span>
                            </Space>
                        </>
                    )}
                </Space>
            );
        },
        [data?.sentimentDeduction, data?.sentimentValue, isEdit, setPropValue]
    );

    const speedScoreRender = useCallback(
        (isOpen, click) => {
            const nextValue =
                data?.speakSpeed + 1 > 3
                    ? Udesk.enums.speakSpeeds.neutral.id
                    : data?.speakSpeed + 1;

            return (
                <Space className="score-detail-item" direction="vertical">
                    <Button
                        onClick={click}
                        type="link"
                        icon={<Icon type={'RocketFilled'} antdIcon={true} />}
                    >
                        <div className="score-detail-item-title">
                            <span>
                                {
                                    /* 表达得分 */ UdeskLocales['current'].components.coach.dialogue
                                        .components.scoreDetail.index.expressionScore
                                }
                            </span>
                            <Space>
                                <span>
                                    <span
                                        className={
                                            (data?.speakSpeedDeduction || 0) +
                                                (data?.modalParticleTooMuchDeduction || 0) ===
                                            0
                                                ? 'font-blue'
                                                : 'font-red'
                                        }
                                    >
                                        {SUB_TEXT}
                                        {(data?.speakSpeedDeduction || 0) +
                                            (data?.modalParticleTooMuchDeduction || 0)}
                                    </span>
                                    {/* 分 */}
                                    {
                                        UdeskLocales['current'].components.coach.dialogue.components
                                            .scoreDetail.index.branch
                                    }
                                </span>
                            </Space>
                        </div>
                    </Button>
                    {isOpen && (
                        <>
                            <Space className="standard-words" direction="vertical">
                                {inspect(!isEmpty(data?.speakSpeed), () => (
                                    <span
                                        className={
                                            data?.speakSpeed === 1 ? 'font-green' : 'font-red'
                                        }
                                    >
                                        <EditTooltip
                                            isEdit={isEdit}
                                            text={
                                                /* 改为 */ UdeskLocales['current'].components.coach
                                                    .dialogue.components.scoreDetail.index.changeTo
                                            }
                                            btnText={Udesk.enums.speakSpeeds.getName(nextValue)}
                                            onClick={() => setPropValue('speakSpeed', nextValue)}
                                        >
                                            {Udesk.enums.speakSpeeds.getName(data?.speakSpeed)}
                                        </EditTooltip>
                                        , {data?.everyMinuteSpeakWordsNum}
                                        {/* 字每分钟 */}
                                        {
                                            UdeskLocales['current'].components.coach.dialogue
                                                .components.scoreDetail.index.wordPerMinute
                                        }
                                    </span>
                                ))}
                                {inspect(data?.enableModalParticle, () => (
                                    <span
                                        className={inspect(
                                            data?.modalParticleTooMuch,
                                            'font-red',
                                            'font-green'
                                        )}
                                    >
                                        <EditTooltipForModalParticleTooMuch
                                            isEdit={isEdit}
                                            value={data?.modalParticleTooMuchAppearNum}
                                            onChange={(value) =>
                                                setPropValue('modalParticleTooMuchAppearNum', value)
                                            }
                                        >
                                            {Udesk.utils.string.format(
                                                `${
                                                    /* 语气词, {0}次 */ UdeskLocales['current']
                                                        .components.coach.dialogue.components
                                                        .scoreDetail.index.moodParticlesTimes
                                                }`,
                                                data?.modalParticleTooMuchAppearNum
                                            )}
                                        </EditTooltipForModalParticleTooMuch>
                                    </span>
                                ))}
                            </Space>
                        </>
                    )}
                </Space>
            );
        },
        [
            data?.speakSpeed,
            data?.speakSpeedDeduction,
            data?.modalParticleTooMuchDeduction,
            data?.enableModalParticle,
            data?.everyMinuteSpeakWordsNum,
            data?.modalParticleTooMuch,
            data?.modalParticleTooMuchAppearNum,
            isEdit,
            setPropValue,
        ]
    );

    const speechReviewRender = useCallback(
        (isOpen, click) => {
            return (
                <SpeechReviewRender
                    isOpen={isOpen}
                    click={click}
                    recordId={recordId}
                    sentenceId={id}
                    enableSingle={enableSingle}
                    enableRealTimeReview={enableRealTimeReview}
                    realTimeReview={realTimeReview}
                />
            );
        },
        [enableRealTimeReview, enableSingle, id, realTimeReview, recordId]
    );

    const render = useCallback(
        (isOpen, click) => {
            const {
                scoreRuleResultList,
                wordsViolationScoreResultList,
                knowLedgeScoreResult,
                sentimentValue,
                sentimentDeduction,
                speakSpeed,
                speakSpeedDeduction,
                modalParticleTooMuch,
                wordsScore,
            } = data;

            const result = new Array<any>();
            // 话术
            if (scoreRuleResultList) {
                const scoreRuleResult = scoreRuleResultList.reduce(
                    (cache, item) => {
                        if (item.hit === HIT) {
                            if (item.operator === ADD) {
                                cache.score += item.score;
                            }
                            if (item.operator === SUB) {
                                cache.score -= item.score;
                                cache.isAllHit = false;
                            }
                        } else {
                            if (item.operator === ADD) {
                                cache.isAllHit = false;
                            }
                        }
                        return cache;
                    },
                    {
                        isAllHit: true,
                        score: 0,
                    }
                );

                result.push(
                    <ScoreTag
                        style={{
                            borderColor: 'transparent',
                        }}
                        color={scoreRuleResult.isAllHit ? 'blue' : 'gold'}
                        text={
                            scoreRuleResult.isAllHit
                                ? /* 话术完整 */ UdeskLocales['current'].components.coach.dialogue
                                      .components.scoreDetail.index.completeScript
                                : /* 话术不完整 */ UdeskLocales['current'].components.coach.dialogue
                                      .components.scoreDetail.index.incompleteScript
                        }
                        score={`${ADD_TEXT}${scoreRuleResult.score}`}
                    />
                );
            }
            // 知识库
            if (knowLedgeScoreResult) {
                result.push(
                    <ScoreTag
                        style={{
                            borderColor: 'transparent',
                        }}
                        color={knowLedgeScoreResult.isHit ? 'blue' : 'red'}
                        text={
                            knowLedgeScoreResult.isHit
                                ? /* 语义命中 */ UdeskLocales['current'].components.coach.dialogue
                                      .components.scoreDetail.index.semanticHit
                                : /* 语义未命中 */ UdeskLocales['current'].components.coach.dialogue
                                      .components.scoreDetail.index.semanticMisses
                        }
                        score={`${ADD_TEXT}${knowLedgeScoreResult.score}`}
                    />
                );
            }

            const wordsViolationScoreResult = wordsViolationScoreResultList?.reduce(
                (score, item) => {
                    return score + item.wordsViolationDeduction;
                },
                0
            );
            // 话术违规
            if (wordsViolationScoreResult) {
                result.push(
                    <ScoreTag
                        style={{
                            borderColor: 'transparent',
                        }}
                        color={'red'}
                        text={
                            /* 话术违规 */ UdeskLocales['current'].components.coach.dialogue
                                .components.scoreDetail.index.scriptViolation
                        }
                        score={`-${wordsViolationScoreResult}`}
                    />
                );
            }
            // 情绪
            if (!isEmpty(sentimentValue)) {
                result.push(
                    <ScoreTag
                        style={{
                            borderColor: 'transparent',
                        }}
                        color={
                            sentimentValue === Udesk.enums.sentimentValues.negative.id
                                ? 'red'
                                : sentimentValue === Udesk.enums.sentimentValues.positive.id
                                ? 'blue'
                                : 'gold'
                        }
                        text={Udesk.enums.sentimentValues.getName(sentimentValue)}
                        score={sentimentDeduction ? ` ${SUB_TEXT}${sentimentDeduction}` : undefined}
                    />
                );
            }
            // 语速
            if (!isEmpty(speakSpeed)) {
                result.push(
                    <ScoreTag
                        style={{
                            borderColor: 'transparent',
                        }}
                        color={speakSpeed === Udesk.enums.speakSpeeds.neutral.id ? 'blue' : 'gold'}
                        text={Udesk.enums.speakSpeeds.getName(speakSpeed)}
                        score={
                            speakSpeedDeduction ? ` ${SUB_TEXT}${speakSpeedDeduction}` : undefined
                        }
                    />
                );
            }

            const originValue = isDefined(
                appealContent,
                () => ({
                    complainType: appealContent.type,
                    complainReason: appealContent.content,
                    isChangeMatchResult: appealContent.isChangeMatchResult,
                    scoringStatus: appealContent.scoringStatus,
                }),
                () => undefined
            );

            const isFinishChecked = appealContentWithDataSource?.scoringStatus === 2;
            const isInChecked = appealContentWithDataSource?.scoringStatus === 1;

            return (
                <div className="score-detail-card-wrapper">
                    <div
                        className={`score-detail-card ${align}`}
                        style={{
                            [align === 'right' ? 'paddingLeft' : 'paddingRight']: cardPaddingWidth,
                        }}
                    >
                        <div className="score-detail-card-tags">
                            {align === 'left' && !isShare && result}
                            <div className="score-detail-card-open-btn">
                                {!isShare && (
                                    <Button type="link" onClick={click} className={'open'}>
                                        <span>
                                            {/* 查看解析 */}
                                            {
                                                UdeskLocales['current'].components.coach.dialogue
                                                    .components.scoreDetail.index.viewResolution
                                            }
                                        </span>
                                        <Icon
                                            antdIcon={true}
                                            type={isOpen ? 'UpOutlined' : 'DownOutlined'}
                                        ></Icon>
                                    </Button>
                                )}
                                {inspect(!isTest && !isAudit && !isReview && isOpen, () => (
                                    <ComplainButton
                                        {...{
                                            btnText: inspect(
                                                isChanged,
                                                () =>
                                                    /* 申诉待提交 */ UdeskLocales['current']
                                                        .components.coach.dialogue.components
                                                        .scoreDetail.index.appealToBeSubmitted,
                                                () => {
                                                    if (isFinishChecked) {
                                                        return /* 评分已复核 */ UdeskLocales[
                                                            'current'
                                                        ].components.coach.dialogue.components
                                                            .scoreDetail.index
                                                            .ratingHasBeenReviewed;
                                                    } else if (isInChecked) {
                                                        return /* 评分申诉中 */ UdeskLocales[
                                                            'current'
                                                        ].components.coach.dialogue.components
                                                            .scoreDetail.index
                                                            .ratingAppealInProgress;
                                                    } else {
                                                        return /* 评分申诉 */ UdeskLocales[
                                                            'current'
                                                        ].components.coach.dialogue.components
                                                            .scoreDetail.index.ratingAppeal;
                                                    }
                                                }
                                            ),
                                            isEdit: !isInChecked,
                                            visible: inspect(isChanged, true, () => {
                                                if (isFinishChecked || isInChecked) {
                                                    return isDefined(appealContent);
                                                }
                                                return true;
                                            }),
                                            hasOtherPopoverOpen: false,
                                            originValue,
                                            btnProps: {
                                                type: 'link',
                                                icon: (
                                                    <Icon
                                                        style={{ zoom: 0.9 }}
                                                        type="InfoCircleOutlined"
                                                        antdIcon={true}
                                                    />
                                                ),
                                                style: {
                                                    color: inspect(isChanged, null, () => {
                                                        if (isFinishChecked) {
                                                            return '#52c41a';
                                                        } else if (isInChecked) {
                                                            return null;
                                                        } else {
                                                            return '#fb5127';
                                                        }
                                                    }),
                                                },
                                            },
                                            onSubmitClick(values) {
                                                setPropValue('.appealContent', {
                                                    type: values.complainType, // 类型 1-转写错误 2-判别错误 3-其他错题
                                                    content: values.complainReason, // 申诉内容描述
                                                    isChangeMatchResult: 0, // 是否修改匹配结果 0-未修改 1-已修改 ，学员申诉时候不能修改话术匹配结果，因此该字段为0
                                                    scoringStatus: 1, // 评分状态字段，1-申诉中 2-已经复核，当改话术需要提交申诉时，前端要将改字段要修改成1
                                                });
                                            },
                                            onCancelClick() {
                                                setPropValue(
                                                    '.appealContent',
                                                    appealContentWithDataSource
                                                );
                                            },
                                        }}
                                    />
                                ))}
                                {isTest && isNodeConfigShow && (
                                    <Button onClick={onNodeConfigClick}>
                                        {/* 节点配置 */}
                                        {
                                            UdeskLocales['current'].components.coach.dialogue
                                                .components.scoreDetail.index.nodeConfiguration
                                        }
                                    </Button>
                                )}
                            </div>

                            {align === 'right' && !isShare && result}
                        </div>
                        {isOpen && (
                            <>
                                {inspect(isReview && appealContent, () => (
                                    <div className={'review-card-content'}>
                                        <div>
                                            <span>
                                                <Icon type="InfoCircleOutlined" antdIcon={true} />
                                            </span>
                                            <p>
                                                <span>
                                                    {Udesk.enums.appealTypes.getName(
                                                        appealContent?.type
                                                    )}
                                                </span>
                                                <span>{appealContent?.content}</span>
                                            </p>
                                        </div>
                                        <span
                                            className={createClassName({
                                                changed: isChangeMatchResult,
                                            })}
                                        >
                                            {isChangeMatchResult
                                                ? /* 已修改 */ UdeskLocales['current'].components
                                                      .coach.dialogue.components.scoreDetail.index
                                                      .modified
                                                : /* 未修改 */ UdeskLocales['current'].components
                                                      .coach.dialogue.components.scoreDetail.index
                                                      .notChanged}
                                        </span>
                                    </div>
                                ))}
                                <div className="score-detail-card-content">
                                    {inspect(
                                        !isEmpty(knowLedgeScoreResult) || !isEmpty(wordsScore),
                                        () => (
                                            <Card isAllOpen={isOpen || isAllOpen}>
                                                {wordsScoreRender}
                                            </Card>
                                        )
                                    )}

                                    {inspect(!isEmpty(sentimentValue), () => (
                                        <Card isAllOpen={isOpen || isAllOpen}>
                                            {emotionScoreRender}
                                        </Card>
                                    ))}

                                    {inspect(!isEmpty(speakSpeed) || modalParticleTooMuch, () => (
                                        <Card isAllOpen={isOpen || isAllOpen}>
                                            {speedScoreRender}
                                        </Card>
                                    ))}

                                    {inspect(
                                        (enableSingle || enableRealTimeReview) &&
                                            hasFeature('sys:largeAi:is:smartRemark'),
                                        () => (
                                            <Card isAllOpen={isOpen || isAllOpen}>
                                                {speechReviewRender}
                                            </Card>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            );
        },
        [
            data,
            appealContent,
            appealContentWithDataSource,
            align,
            cardPaddingWidth,
            isTest,
            isAudit,
            isReview,
            isNodeConfigShow,
            onNodeConfigClick,
            enableSingle,
            enableRealTimeReview,
            isChanged,
            setPropValue,
            isChangeMatchResult,
            isAllOpen,
            wordsScoreRender,
            emotionScoreRender,
            speedScoreRender,
            speechReviewRender,
        ]
    );

    if (data) {
        return <Card isAllOpen={isAllOpen}>{render}</Card>;
    }

    return null;
};

export const Card = (props) => {
    const { isAllOpen = true, children } = props;
    const [isOpen, setIsOpen] = useState(isAllOpen);

    const clickHandle = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, []);

    useEffect(() => {
        setIsOpen(isAllOpen);
    }, [isAllOpen]);

    return children(isOpen, clickHandle);
};

const SemanticTagTestToolTip = (props) => {
    if (!props.show || !props.semanticTagHitTestResults) return props.children;
    return (
        <Popover
            title={
                <>
                    {props.children}
                    {/* 命中情况 */}
                    {
                        UdeskLocales['current'].components.coach.dialogue.components.scoreDetail
                            .index.hitSituation
                    }
                </>
            }
            content={<SemanticTagTest hitTest={props.semanticTagHitTestResults} />}
        >
            {props.children}
        </Popover>
    );
};

const createText4List = (title, list, isEdit, setPropValue, isTest) => {
    const map = list.reduce(
        (cache, item, index) => {
            if (item.conditionType === 1) {
                cache.keywords.push({
                    ...item,
                    index,
                });
            } else {
                cache.smartTags.push({
                    ...item,
                    index,
                });
            }

            return cache;
        },
        {
            keywords: [],
            smartTags: [],
        }
    );

    const result = new Array<string>();

    if (map.keywords.length > 0) {
        result.push(
            `${
                /* 关键词 */ UdeskLocales['current'].components.coach.dialogue.components
                    .scoreDetail.index.keyWord
            }${map.keywords.map((item) => item.keyword).join(' ')}`
        );
    }
    if (map.smartTags.length > 0) {
        result.push(
            `${
                /* 语义标签 */ UdeskLocales['current'].components.coach.dialogue.components
                    .scoreDetail.index.semanticLabels
            }${map.smartTags.map((item) => item.keyword).join(' ')}`
        );
    }
    console.log('map:: ', map);
    const callback = (item) => {
        return (
            <>
                <ScoreRuleItemWord
                    {...{
                        isEdit,
                        isHit: item.hit === HIT,
                        onClick() {
                            setPropValue(
                                `hitConditionResultList.${item.index}.hit`,
                                item.hit === HIT ? NO_HIT : HIT
                            );
                        },
                        word: (
                            <SemanticTagTestToolTip
                                show={isTest}
                                semanticTagHitTestResults={item.semanticTagHitTestResults}
                            >
                                <span className="font-blue">{item.keyword}</span>
                            </SemanticTagTestToolTip>
                        ),
                    }}
                />
            </>
        );
    };

    return [
        inspect(map.keywords.length > 0, () => [
            <span>
                {title}
                {/* 关键词 */}
                {
                    UdeskLocales['current'].components.coach.dialogue.components.scoreDetail.index
                        .keyWord
                }
            </span>,
            map.keywords.map(callback),
        ]),
        inspect(map.smartTags.length > 0, () => [
            <span>
                {title}
                {/* 语义标签 */}
                {
                    UdeskLocales['current'].components.coach.dialogue.components.scoreDetail.index
                        .semanticLabels
                }
            </span>,
            map.smartTags.map(callback),
        ]),
    ];
};

const createText = (title, list, isHit, isEdit, setPropValue, isTest) => {
    list = list.map((item, index) => ({ ...item, index })).filter(isHit);
    if (isNotEmpty(list)) {
        return (
            <>
                <span>
                    {title}
                    {list[0]?.conditionType === 1
                        ? /* 关键词 */ UdeskLocales['current'].components.coach.dialogue.components
                              .scoreDetail.index.keyWord
                        : /* 语义标签 */ UdeskLocales['current'].components.coach.dialogue
                              .components.scoreDetail.index.semanticLabels}
                </span>
                {list.map((item) => {
                    return (
                        <ScoreRuleItemWord
                            {...{
                                isEdit,
                                isHit: item.hit === HIT,
                                word: (
                                    <SemanticTagTestToolTip
                                        show={isTest}
                                        semanticTagHitTestResults={item.semanticTagHitTestResults}
                                    >
                                        <span className="font-blue">{item.keyword}</span>
                                    </SemanticTagTestToolTip>
                                ),
                                onClick() {
                                    setPropValue(
                                        `hitConditionResultList.${item.index}.hit`,
                                        item.hit === HIT ? NO_HIT : HIT
                                    );
                                },
                            }}
                        />
                    );
                })}
            </>
        );
    } else {
        return null;
    }
};

// 知识库详情
const KnowLedgeScoreResultFormat = React.memo<any>((props) => {
    const { data, isEdit, setPropValue, isTest } = props;
    console.log('data:: ', data);
    const isHitTxt = (isHit) =>
        !isHit
            ? /* 未匹配到语义标签 */ UdeskLocales['current'].components.coach.dialogue.components
                  .scoreDetail.index.noSemanticTagsMatched
            : /* 匹配到语义标签 */ UdeskLocales['current'].components.coach.dialogue.components
                  .scoreDetail.index.matchToSemanticTags;
    return isDefined(data, () => {
        const { isHit, score } = data;
        return (
            <Space className="standard-words">
                <ScoreTag
                    color={isHit ? 'blue' : 'red'}
                    text={
                        isHit
                            ? /* 语义命中 */ UdeskLocales['current'].components.coach.dialogue
                                  .components.scoreDetail.index.semanticHit
                            : /* 语义未命中 */ UdeskLocales['current'].components.coach.dialogue
                                  .components.scoreDetail.index.semanticMisses
                    }
                    score={`${ADD_TEXT}${score}${
                        /* 分 */ UdeskLocales['current'].components.coach.dialogue.components
                            .scoreDetail.index.branch
                    }`}
                />
                <span className={isHit ? 'font-green' : 'font-red'}>
                    <Icon
                        antdIcon={true}
                        type={isHit ? 'CheckCircleFilled' : 'CloseCircleFilled'}
                    />
                </span>
                {inspect(hasFeature('intelligentpartner:scoringresult:root'), () => (
                    <span>
                        {/* <span style={{marginRight: 6}}>{UdeskLocales['current'].components.coach.dialogue.components.scoreDetail.index.semanticMatchingRate}</span> */}
                        <EditTooltip
                            isEdit={isEdit}
                            text={
                                /* 改为 */ UdeskLocales['current'].components.coach.dialogue
                                    .components.scoreDetail.index.changeTo
                            }
                            btnText={isHitTxt(!isHit)}
                            onClick={() => {
                                setPropValue('knowLedgeScoreResult.isHit', !isHit);
                            }}
                        >
                            {isEdit ? (
                                <span>
                                    <Button
                                        type="link"
                                        size="small"
                                        style={{ width: 'auto', height: 19 }}
                                    >
                                        {isHitTxt(isHit)}
                                    </Button>
                                </span>
                            ) : (
                                <span>
                                    {isHitTxt(isHit)}
                                    <SemanticTagTestToolTip
                                        show={isTest}
                                        semanticTagHitTestResults={data?.semanticTagHitTestResults}
                                    >
                                        <span className="font-blue" style={{ marginLeft: 8 }}>
                                            {data?.title}
                                        </span>
                                    </SemanticTagTestToolTip>
                                </span>
                            )}
                        </EditTooltip>
                    </span>
                ))}
            </Space>
        );
    });
});

// 话术违规详情
const WordsViolationScoreResultFormat = React.memo<any>((props) => {
    const { isEdit, setPropValue, list } = props;

    const score = useMemo(() => {
        return list?.reduce((score, item) => score + item.wordsViolationDeduction, 0);
    }, [list]);

    const clickHandle = useCallback(
        (delIndex) => {
            const result = list.filter((item, index) => delIndex !== index);
            setPropValue('wordsViolationScoreResultList', result.length === 0 ? undefined : result);
        },
        [list, setPropValue]
    );

    return isNotEmpty(list, () => (
        <Space className="standard-words">
            <ScoreTag
                color="red"
                text={
                    /* 话术违规 */ UdeskLocales['current'].components.coach.dialogue.components
                        .scoreDetail.index.scriptViolation
                }
                score={`${SUB_TEXT}${score}${
                    /* 分 */ UdeskLocales['current'].components.coach.dialogue.components
                        .scoreDetail.index.branch
                }`}
            />
            <span className={score === 0 ? 'font-green' : 'font-red'}>
                <Icon
                    antdIcon={true}
                    type={score === 0 ? 'CheckCircleFilled' : 'CloseCircleFilled'}
                />
            </span>
            {inspect(hasFeature('intelligentpartner:scoringresult:root'), () => (
                <Space wrap>
                    {list.map((item, delIndex) => {
                        return (
                            <EditTooltip
                                isEdit={isEdit}
                                algin="right"
                                text={`${
                                    /* 违规词“ */ UdeskLocales['current'].components.coach.dialogue
                                        .components.scoreDetail.index.violatingWords
                                }${item.violationWords}”`}
                                btnText={
                                    /* 删除 */ UdeskLocales['current'].components.coach.dialogue
                                        .components.scoreDetail.index.delete
                                }
                                onClick={() => clickHandle(delIndex)}
                            >
                                {inspect(
                                    isEdit,
                                    <span>
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ width: 'auto', height: 19 }}
                                        >
                                            <span className="font-blue">{item.violationWords}</span>
                                        </Button>
                                    </span>,
                                    <span className="font-blue">{item.violationWords}</span>
                                )}
                            </EditTooltip>
                        );
                    })}
                </Space>
            ))}
        </Space>
    ));
});

// 话术得分详情
const ScoreRuleResultFormat = React.memo<any>((props) => {
    const { list, isEdit, setPropValue, isTest } = props;

    return isNotEmpty(list, () => {
        return list.map((item, index) => {
            return (
                <ScoreRuleItem
                    data={item}
                    isEdit={isEdit}
                    setPropValue={(path, value) => {
                        setPropValue(`scoreRuleResultList.${index}.${path}`, value);
                    }}
                    isTest={isTest}
                />
            );
        });
    });
});

const ScoreRuleItemWord = React.memo((props: any) => {
    const { isEdit, isHit, onClick, word } = props;
    return (
        <>
            <EditTooltip
                isEdit={isEdit}
                text={
                    /* 改为 */ UdeskLocales['current'].components.coach.dialogue.components
                        .scoreDetail.index.changeTo
                }
                btnText={
                    isHit
                        ? /* 未匹配 */ UdeskLocales['current'].components.coach.dialogue.components
                              .scoreDetail.index.unmatched
                        : /* 匹配 */ UdeskLocales['current'].components.coach.dialogue.components
                              .scoreDetail.index.matching
                }
                onClick={onClick}
            >
                {isEdit ? (
                    <span>
                        <Button type="link" size="small" style={{ width: 'auto', height: 19 }}>
                            {word}
                        </Button>
                    </span>
                ) : (
                    word
                )}
            </EditTooltip>
        </>
    );
});

const ScoreRuleItem = React.memo((props: any) => {
    const { data, isEdit, setPropValue, isTest } = props;
    const isAnd = data?.logicalOperator === '&&';
    const isHit = data?.hit === HIT;

    const operator = useMemo(() => {
        return Udesk.enums.scoreRuleOperator.getName(data?.operator);
    }, [data?.operator]);

    const title = useMemo(() => {
        return isHit
            ? /* 匹配到 */ UdeskLocales['current'].components.coach.dialogue.components.scoreDetail
                  .index.matchTo
            : /* 未匹配到 */ UdeskLocales['current'].components.coach.dialogue.components
                  .scoreDetail.index.notMatchedTo;
    }, [isHit]);

    return (
        <Space className="standard-words">
            <ScoreTag
                color="blue"
                text={data?.scoreRuleName}
                score={`${operator}${data?.score}${
                    /* 分 */ UdeskLocales['current'].components.coach.dialogue.components
                        .scoreDetail.index.branch
                }`}
            />
            <span className={data?.hit === HIT ? 'font-green' : 'font-red'}>
                <Icon
                    antdIcon={true}
                    type={data?.hit === HIT ? 'CheckCircleFilled' : 'CloseCircleFilled'}
                />
            </span>
            {inspect(hasFeature('intelligentpartner:scoringresult:root'), () => (
                <Space wrap align="baseline">
                    {inspect(
                        (isHit && isAnd) || (!isHit && !isAnd),
                        () =>
                            createText4List(
                                title,
                                data?.hitConditionResultList,
                                isEdit,
                                setPropValue,
                                isTest
                            ),
                        () =>
                            createText(
                                title,
                                data?.hitConditionResultList,
                                (item) => item.hit === (isHit && !isAnd ? HIT : NO_HIT),
                                isEdit,
                                setPropValue,
                                isTest
                            )
                    )}
                </Space>
            ))}
        </Space>
    );
});

const ScoreTag = React.memo((props: any) => {
    const { color, style, text, score } = props;
    return isDefined(
        text,
        () => (
            <Tag title={text} color={color} style={style} className={'udesk-qa-ui-score-tag'}>
                <span>{text}</span>
                {isDefined(
                    score,
                    () => (
                        <span>{score}</span>
                    ),
                    null
                )}
            </Tag>
        ),
        null
    );
});

const EditTooltip = React.memo((props: any) => {
    const { isEdit, text, btnText, onClick, children, algin = 'left' } = props;
    return isEdit ? (
        <Tooltip
            color={'#fff'}
            overlayInnerStyle={{ color: '#555', padding: '6px 16px' }}
            title={() => {
                return (
                    <Space size={0}>
                        {inspect(algin === 'left', <span>{text}</span>)}
                        <Button style={{ padding: 0 }} type="link" size={'small'} onClick={onClick}>
                            {btnText}
                        </Button>
                        {inspect(algin === 'right', <span>{text}</span>)}
                    </Space>
                );
            }}
        >
            {children}
        </Tooltip>
    ) : (
        children
    );
});

const EditTooltipForModalParticleTooMuch = React.memo<any>((props) => {
    const { isEdit, children, value = 0, onChange } = props;

    return inspect(
        isEdit,
        () => (
            <Tooltip
                color={'#fff'}
                overlayInnerStyle={{ color: '#555', padding: '6px 16px' }}
                title={() => {
                    return (
                        <Space>
                            <span>
                                {
                                    /* 语气词改为 */ UdeskLocales['current'].components.coach
                                        .dialogue.components.scoreDetail.index
                                        .changeTheModalParticlesTo
                                }
                            </span>
                            <InputNumber size="small" min={0} value={value} onChange={onChange} />
                            <span>
                                {
                                    /* 次 */ UdeskLocales['current'].components.coach.dialogue
                                        .components.scoreDetail.index.second
                                }
                            </span>
                        </Space>
                    );
                }}
            >
                {children}
            </Tooltip>
        ),
        () => children
    );
});

const SpeechReviewRender = (props) => {
    const {
        isOpen,
        click,
        recordId,
        sentenceId,
        enableSingle,
        enableRealTimeReview,
        realTimeReview,
    } = props;

    const { content, onGen, genRemarkLoading, queryLoading } = useSpeachRemark({
        recordId,
        remarkType: RemarkType.Single,
        sentenceId,
        enableSingle,
        enableRealTimeReview,
        realTimeReview,
    });

    return (
        <Space className="score-detail-item" direction="vertical">
            <Button onClick={click} type="link" icon={<Icon type={'BulbFilled'} antdIcon={true} />}>
                <div className="score-detail-item-title">
                    <span>{/* 话术点评 */}{UdeskLocales['current'].components.coach.dialogue.components.scoreDetail.index.scriptReview}</span>
                    <Space>
                        {isOpen && enableSingle ? (
                            <Button
                                type="link"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onGen();
                                }}
                            >
                                {content ? /* 重新生成 */UdeskLocales['current'].components.coach.dialogue.components.scoreDetail.index.regenerate : /* 生成 */UdeskLocales['current'].components.coach.dialogue.components.scoreDetail.index.generate}
                            </Button>
                        ) : null}
                    </Space>
                </div>
            </Button>

            {isOpen && (
                <div className="standard-words">
                    <Spin spinning={genRemarkLoading || queryLoading}>
                        {content ? <div>{content}</div> : null}
                    </Spin>
                </div>
            )}
        </Space>
    );
};
