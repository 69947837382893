import React from 'react';
import Udesk from 'Udesk';
import { getSmoothStepPath, useReactFlow } from 'reactflow';
import { Button, Icon } from 'udesk-ui';

const foreignObjectSize = 25;

export default (props) => {
    const reactFlowInstance = useReactFlow();
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        markerEnd,
        target,
    } = props;
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    // 判断当前是不是ppt类型的flow，如果是，用户不能删除节点和连线
    // const isPpt = reactFlowInstance.getNode(target)?.type === Udesk.enums.nodeTypes.pptNode.key;

    const isAiGen =
        reactFlowInstance.getNode(target)?.type === Udesk.enums.nodeTypes.aiGenSceneNode.key ||
        reactFlowInstance.getNode(target)?.type === Udesk.enums.nodeTypes.aiGenDialogueNode.key ||
        reactFlowInstance.getNode(target)?.type === Udesk.enums.nodeTypes.aiGenPromptNode.key;

    return (
        <>
            <path
                id={id}
                style={style}
                fill="none"
                // className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                stroke={style.color}
                strokeWidth={style.strokeWidth}
            />
            {props.selected && !isAiGen && (
                <foreignObject
                    width={foreignObjectSize}
                    height={foreignObjectSize}
                    x={labelX - foreignObjectSize / 2}
                    y={labelY - foreignObjectSize / 2}
                    className="edgebutton-foreignobject"
                    requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                    <Button
                        onClick={() => {
                            reactFlowInstance?.deleteElements?.({
                                edges: [props],
                            });
                        }}
                        shape="circle"
                        type="primary"
                        size="small"
                        danger
                        icon={
                            <Icon type="CloseOutlined" antdIcon={true} style={{ marginLeft: 0 }} />
                        }
                    />
                </foreignObject>
            )}
        </>
    );
};
