import RectangularCoordinateSystemBuilderClass from '../rectangular-coordinate-system';
import common from '../../../../../udesk/index';

export default class ScatterOptionsBuilderClass extends RectangularCoordinateSystemBuilderClass {
    // getXAxisOptions(optionsOwner) {
    // }
    // getYAxisOptions(optionsOwner) {
    // }
    getDataZoom(optionsOwner) {
        return [{
            type: 'slider',
            start: 0,
            end: 100
        }, {
            type: 'inside',
            start: 0,
            end: 100
        }];
    }

    getSeriesOptions(optionsOwner) {
        let series = super.getSeriesOptions(optionsOwner);

        let {
            records,
            seriesColumns
        } = optionsOwner.props;

        if (series && series.length > 0) {
            let firstSeriesItem = series[0];
            firstSeriesItem.type = "custom";
            firstSeriesItem.name = 'trend';

            firstSeriesItem.renderItem = renderItem.bind(optionsOwner);
            firstSeriesItem.itemStyle = {
                normal: {
                    borderWidth: 2
                }
            };
            firstSeriesItem.z = "10";
            firstSeriesItem.encode = {
                x: 0,
                y: [1, 2, 3, 4, 5,]
            };
            // data: customData,
            firstSeriesItem.data = getCustomSeriesData(optionsOwner, series);
        }


        return series;
    }
    _getChartType() {
        return common.enums.echartTypes.bar.key;
    }
}

function getCustomSeriesData(optionsOwner, series) {
    let {
        records,
        seriesColumns
    } = optionsOwner.props;

    let seriesLength = series && series.length;
    let seriesDataLength = series[1].data && series[1].data.length;

    let customData = [];
    for (let i = 0; i < seriesDataLength; i++) {
        let customVal = [i];

        for (let j = 0; j < seriesLength; j++) {
            let val = Math.random() * 20;
            customVal.push(val);
        }
        customData.push(customVal);
    }
    return customData;
}


function renderItem(params, api) {
    let {
        barMaxWidth
    } = this.props;
    let xValue = api.value(0);
    let currentSeriesIndices = api.currentSeriesIndices();
    let barLayout = api.barLayout({
        barGap: '30%',
        barCategoryGap: '20%',
        barMaxWidth,
        count: currentSeriesIndices.length - 1
    });

    let points = [];
    for (let i = 0; i < currentSeriesIndices.length; i++) {
        let seriesIndex = currentSeriesIndices[i];
        if (seriesIndex !== params.seriesIndex) {
            let point = api.coord([xValue, api.value(seriesIndex)]);
            point[0] += barLayout[i - 1].offsetCenter;
            point[1] -= 20;
            points.push(point);
        }
    }
    let style = api.style({
        stroke: "red" || api.visual('color'),
        fill: null
    });

    return {
        type: 'polyline',
        shape: {
            points: points
        },
        style: style
    };
}

function getTooltipValue(params, optionsOwner, isKeyWord = false) {
    let paramsData = params;
    if (!Array.isArray(params)) {
        paramsData = [params];
    }

    return "<div class='echart-tooltip-content'>" + paramsData.map(item => {
        let {
            records,
            seriesColumns
        } = optionsOwner.props;

        let seriesName = item.seriesName;

        if (seriesName === '\u0000-') {
            // Not show '-'
            seriesName = '';
        }

        let color = item.color || '#E3E3E3';
        if (color === "auto") {
            color = '#E3E3E3';
        }

        if (seriesColumns == null) {
            seriesColumns = [];
        }
        if (!Array.isArray(seriesColumns)) {
            seriesColumns = [seriesColumns];
        }

        let normalTooltipItems = seriesColumns.reduce((arr, seriesColumn) => {
            let formattedValue = 0;
            if (records && records.length > 0) {
                let fieldKey = seriesColumn.field;
                let record = null;
                if (isKeyWord) {
                    record = records[item.dataIndex];
                } else {
                    record = records[item.dataIndex + 1];
                }

                if (fieldKey && record) {
                    formattedValue = record[fieldKey].value || record[fieldKey].originalValue || 0;
                }
                if (formattedValue > 0) {
                    let name = seriesColumn.name;
                    arr.push(`<div class="echart-tooltip-content-item"><div class="echart-tooltip-title">${common.utils.encodeHTML(name)}</div><div class="echart-tooltip-value" style="background-color:${color};">${formattedValue}</div></div>`);
                }
            }
            return arr;
        }, []);
        return normalTooltipItems.join('');
    }).join('') + "</div>";

}