import RectangularCoordinateSystemBuilderClass from './rectangular-coordinate-system';

class LineOptionsBuilderClass extends RectangularCoordinateSystemBuilderClass {
    _getChartType(seriesItem) {
        return "line";
    }
    getTooltipOptions(optionsOwner) {
        let tooltip = super.getTooltipOptions(optionsOwner);
        //十字交叉显示
        tooltip.axisPointer = tooltip.axisPointer || {};
        tooltip.axisPointer.show = true;
        tooltip.axisPointer.label = tooltip.axisPointer.label || {};
        tooltip.axisPointer.label.show = true;

        return tooltip;
    }
}

export default LineOptionsBuilderClass;
