// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetLibraryFilesPublicSearchQuery,
    BaseResponseListLibraryFileFoundResponse,
} from '../../../types';

/**
 * findPublicLibraryFiles
 *
 * @export
 * @tags 案例库文件夹
 * @link [GET] /libraryFiles/public/search
 */
export function getLibraryFilesPublicSearch(): Promise<BaseResponseListLibraryFileFoundResponse>;
export function getLibraryFilesPublicSearch(
    options: ApiOptions<never, GetLibraryFilesPublicSearchQuery>
): Promise<BaseResponseListLibraryFileFoundResponse>;
export function getLibraryFilesPublicSearch(
    options?: ApiOptions<never, GetLibraryFilesPublicSearchQuery>
): Promise<BaseResponseListLibraryFileFoundResponse> {
    return request<never, never, GetLibraryFilesPublicSearchQuery>('/libraryFiles/public/search', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getLibraryFilesPublicSearch',
    });
}

export const meta = [
    { tags: ['案例库文件夹'], path: '/libraryFiles/public/search', method: 'get' },
];
