// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostReviewIntelligentPartnerNodeWordsPolishBody,
    BaseResponsestring,
} from '../../../../types';

/**
 * 节点话术润色
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/node/wordsPolish
 */
export function postReviewIntelligentPartnerNodeWordsPolish(
    data: PostReviewIntelligentPartnerNodeWordsPolishBody
): Promise<BaseResponsestring>;
export function postReviewIntelligentPartnerNodeWordsPolish(
    data: PostReviewIntelligentPartnerNodeWordsPolishBody,
    options: ApiOptions<never, never>
): Promise<BaseResponsestring>;
export function postReviewIntelligentPartnerNodeWordsPolish(
    data: PostReviewIntelligentPartnerNodeWordsPolishBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponsestring> {
    return request<PostReviewIntelligentPartnerNodeWordsPolishBody, never, never>(
        '/review/intelligentPartner/node/wordsPolish',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeWordsPolish',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/wordsPolish',
        method: 'post',
    },
];
