export default {
  lang: {
    export: 'Vienti',
    successfully: 'Vie tehtävä onnistuneesti',
    pleaseJumpTo: 'Hyppää.',
    uploadAndDownloadCenter: 'Lataa ja lataa keskusta',
    check: 'Näytä',
    exportAll: 'Vahvistetaanko viedä koko sisältö?',
    total: 'Yhteensä',
    confirm: 'Data, vahvistaa viennin?',
    error: 'Vietävien tietojen määrä on 0, muuta suodatusperusteita ja yritä uudelleen',
  },
};
