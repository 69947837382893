// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    BaseResponseListSuperVisionRuleResponse,
    PostSupervisionRuleConfigsBody,
    BaseResponseSupervisionRuleConfigFoundResponse,
} from '../types';

/**
 * 监督项列表
 *
 * @export
 * @tags supervision-rule-config-controller
 * @link [GET] /supervisionRuleConfigs
 */
export function getSupervisionRuleConfigs(): Promise<BaseResponseListSuperVisionRuleResponse>;
export function getSupervisionRuleConfigs(
    options: ApiOptions<never, never>
): Promise<BaseResponseListSuperVisionRuleResponse>;
export function getSupervisionRuleConfigs(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListSuperVisionRuleResponse> {
    return request<never, never, never>('/supervisionRuleConfigs', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSupervisionRuleConfigs',
    });
}

/**
 * 监督项保存/更新
 *
 * @export
 * @tags supervision-rule-config-controller
 * @link [POST] /supervisionRuleConfigs
 */
export function postSupervisionRuleConfigs(
    data: PostSupervisionRuleConfigsBody
): Promise<BaseResponseSupervisionRuleConfigFoundResponse>;
export function postSupervisionRuleConfigs(
    data: PostSupervisionRuleConfigsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseSupervisionRuleConfigFoundResponse>;
export function postSupervisionRuleConfigs(
    data: PostSupervisionRuleConfigsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseSupervisionRuleConfigFoundResponse> {
    return request<PostSupervisionRuleConfigsBody, never, never>('/supervisionRuleConfigs', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSupervisionRuleConfigs',
    });
}

export const meta = [
    {
        tags: ['supervision-rule-config-controller'],
        path: '/supervisionRuleConfigs',
        method: 'get',
    },
    {
        tags: ['supervision-rule-config-controller'],
        path: '/supervisionRuleConfigs',
        method: 'post',
    },
];
