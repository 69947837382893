import React, { FC, memo, useMemo, useState } from 'react';
import Fields, { TemplateProps as CustomerFieldsProps } from '../fields';
import { getReviewSpectacularsSaleStageByCustomerId } from 'src/api/review/spectaculars/saleStage/{customerId}';
import isNil from 'lodash-es/isNil';
import UdeskLocales from 'UdeskLocales';
import { useRequest } from 'ahooks';

export type TemplateProps = {
    customerId?: string;
    customerName?: string;
    stageStatus?: string;
    phoneNumber?: string;
    followUpDays?: string;
    remark?: string;
    canEdit?: boolean;
    onSave?: (v: any) => Promise<any>;
    descriptionsConfig?: CustomerFieldsProps['descriptionsConfig'];
};

const Template: FC<TemplateProps> = (props) => {
    const {
        customerId,
        customerName,
        stageStatus,
        phoneNumber,
        followUpDays,
        remark,
        canEdit,
        onSave,
        descriptionsConfig,
    } = props;

    const [stageOptions, setStageOptions] = useState<any[]>();

    useRequest(() => getReviewSpectacularsSaleStageByCustomerId({ segments: { customerId } }), {
        ready: !isNil(customerId),
        onSuccess: (res) => {
            setStageOptions(
                res.data?.customerSaleSettingStageList?.map((s) => {
                    return {
                        value: s.stageId,
                        label: s.stageName,
                    };
                })
            );
        },
    });

    const fieldList = useMemo(() => {
        return [
            {
                id: 'customerName',
                name: /* 客户 */ UdeskLocales['current'].pages.gong.saleClientCenter.components
                    .moreCustomer.index.customer,
                value: customerName,
            },
            {
                id: 'stageStatus',
                name: /* 当前跟进阶段 */ UdeskLocales['current'].pages.gong.saleClientCenter
                    .components.moreCustomer.index.currentFollowupStage,
                value: stageStatus,
                textProps: { options: stageOptions },
                fieldProps: { type: 'Select', options: stageOptions },
            },
            {
                id: 'phoneNumber',
                name: /* 电话 */ UdeskLocales['current'].pages.gong.saleClientCenter.components
                    .moreCustomer.index.telephone,
                value: phoneNumber,
            },
            {
                id: 'followUpDays',
                name: /* 跟进天数 */ UdeskLocales['current'].pages.gong.saleClientCenter.components
                    .moreCustomer.index.followUpDays,
                value: followUpDays,
                textProps: {
                    suffix: /* 天 */ UdeskLocales['current'].pages.gong.saleClientCenter.components
                        .moreCustomer.index.day,
                },

                fieldProps: {
                    type: 'InputNumber',
                    style: {
                        width: '100%',
                    },
                },
            },
            {
                id: 'remark',
                name: /* 备注 */ UdeskLocales['current'].pages.gong.saleClientCenter.components
                    .moreCustomer.index.remarks,

                value: remark,
                fieldProps: {
                    type: 'TextArea',
                    rows: 4,
                },
            },
        ];
    }, [customerName, followUpDays, phoneNumber, remark, stageOptions, stageStatus]);

    return (
        <Fields
            descriptionsConfig={{
                title: /* 客户信息 */UdeskLocales['current'].pages.gong.customerFields.components.baseFields.index.customerInformation,
                column: 2,
                bordered: true,
                ...descriptionsConfig,
            }}
            fieldList={fieldList}
            canEdit={canEdit}
            onSave={onSave}
        />
    );
};

export default memo(Template);
