export default {
  lang: {
    num: 'Sezione',
    total: 'Articolo/totale',
    strip: 'Articolo',
    more: 'Altro',
    chosen: 'Selezionato',
    cancel: 'Deseleziona',
    bulkOperations: 'Operazione batch',
    import: 'Importazione',
  },
};
