import React from 'react';
import Udesk from 'Udesk';
import ClassNames from 'classnames';
import CustomFilters from 'udesk-react/src/components/custom-filters/template';
import Header from 'udesk-react/src/components/custom-filters/template/header';
import DynamicComponent from 'udesk-react/src/components/dynamic-component';
import ConditionModuleEnums from "udesk-react/src/components/custom-filters/component/condition-module-enums";
import { Async } from './async';

import "udesk-react/src/components/custom-filters/styles/index.css";
import 'udesk-react/src/fonts/iconfont.css';
import { fire } from 'src/util/core';

const getOperators = (filter, list, fieldId) => {
    if (filter) {
        return filter(list, fieldId);
    }
    
    return list;
};

const getValueTdColSpan = (isAutoHideOperator, operators, filedId) => {
    const flag = fire(isAutoHideOperator, operators, filedId);
    if (flag) {
        if (operators && operators.length === 1) {
            return 2;
        }
    }

    return 1;
};

class CustomFiltersComponent extends CustomFilters {
    render() {
        let {
            props,
            // state,
            privates,
            privates: { computes },
            actions
        } = this;
        let {
            isAutoHideOperator = false, // 是否自动隐藏只有‘等于’操作的列
            operatorsFilter, // 操作符过滤函数,可以针对某个字段进行过滤
            postfixComponentRenderInLine, // 后缀扩展字段隐藏位置函数
            conditionPrefixComponent: ConditionPrefixComponent,
            conditionFieldComponent: ConditionFieldComponent,
            operatorComponent: OperatorComponent,
            valueLoadingComponent: ValueLoadingComponent,
            conditionPostfixComponent: ConditionPostfixComponent,
            removalConditionComponent: RemovalConditionComponent,
            addConditionComponent: AddConditionComponent,
            actionsComponent: ActionsComponent,
            footerComponent: FooterComponent,
            antdSelectComponent: AntdSelect,
            valueSettingsAttributesField
        } = props;
        let {
            get,
        } = Udesk.utils.object;

        return (
            <div className={computes.className} ref={actions._receiveContainerDom}>
                <div className="filter-wrapper">
                    <Header {...props} />
                    <table className="filter-conditions">
                        <colgroup>
                            {/* 前缀列 */}
                            <If condition={props.conditionPrefixComponent || props.showConditionNumber}>
                                <col className="condition-prefix-column" />
                            </If>
                            {/* 字段列 */}
                            <col className="condition-field-column" />
                            {/* 操作符列 */}
                            <col className="condition-operator-column" />
                            {/* 值列 */}
                            <col className="condition-value-column" />
                            {/* 后缀列 */}
                            <If condition={props.conditionPostfixComponent}>
                                <col className="condition-postfix-column" />
                            </If>
                            {/* 删除按钮列 */}
                            <If condition={props.showRemovalButton && !props.disabled}>
                                <col className="condition-removal-column" />
                            </If>
                        </colgroup>
                        {/* 过滤器表头 */}
                        <If condition={props.showConditionHeaders}>
                            <With showConditionHeadersWhenEmpty={computes._conditions.length === 0 && !props.showConditionHeadersWhenEmpty}>
                                <Choose>
                                    <When condition={showConditionHeadersWhenEmpty}>
                                        { /* 没有数据时隐藏表头 */}
                                    </When>
                                    <Otherwise>
                                        <thead className="filter-head">
                                            <tr className="filter-head-row">
                                                {/* 前缀 */}
                                                <If condition={props.conditionPrefixComponent || props.showConditionNumber}>
                                                    <th className="filter-head-item condition-prefix-column-header">{props.conditionPrefixColumnHeader}</th>
                                                </If>
                                                {/* 字段 */}
                                                <th className={ClassNames("filter-head-item", "condition-field-column-header", { "invisible-column": !props.showConditionFieldColumn })}>{props.conditionFieldColumnHeader}</th>
                                                {/* 操作符 */}
                                                <th className={ClassNames("filter-head-item", "condition-operator-column-header", { "invisible-column": !props.showConditionOperatorColumn })}>{props.conditionOperatorColumnHeader}</th>
                                                {/* 值 */}
                                                <th className={ClassNames("filter-head-item", "condition-value-column-header", { "invisible-column": !props.showConditionValueColumn })}>{props.conditionValueColumnHeader}</th>
                                                {/* 后缀 */}
                                                <If condition={props.conditionPostfixComponent}>
                                                    <th className="filter-head-item condition-postfix-column-header">{props.conditionPostfixColumnHeader}</th>
                                                </If>
                                                {/* 删除操作 */}
                                                <If condition={props.showRemovalButton && !props.disabled}>
                                                    <th className="filter-head-item condition-removal-column-header">{props.conditionRemovalColumnHeader}</th>
                                                </If>
                                            </tr>
                                        </thead>
                                    </Otherwise>
                                </Choose>
                            </With>
                        </If>
                        {/* 过滤器主体 */}
                        <tbody className="filter-body">
                            {/* 字段列表是支持异步加载的 */}
                            <Async await={privates.fields} waiting={actions.promiseWaiting} then={actions._fieldsPromiseResolved} catch={actions.promiseCatch}>
                                {fieldsPromiseToken =>
                                    <Choose>
                                        <When condition={fieldsPromiseToken.status === "resolved"}>
                                            {/* 字段加载完成 */}
                                            <With fields={fieldsPromiseToken.result}>
                                                {/* 展示过滤器数据行 */}
                                                <For of={computes._conditions} each="fullCondition" index="index">
                                                    <With conditionField={actions._getFieldFromCondition(fullCondition.data) || {}}>
                                                        <tr key={fullCondition.id.toString()} className={
                                                            ClassNames(
                                                                "condition-row", 
                                                                { 
                                                                    'is-tow-line' : fire(postfixComponentRenderInLine, 1, fullCondition.data) && ConditionPostfixComponent,
                                                                    "'condition-error'": fullCondition.states.hasError, 
                                                                    'no-condition': fullCondition.data == null || (fullCondition.data && !Object.keys(fullCondition.data).length) 
                                                                }
                                                            )
                                                        }>
                                                            {/* 前缀列，默认支持显示行号 */}
                                                            <If condition={props.conditionPrefixComponent || props.showConditionNumber}>
                                                                <td className="condition-item condition-prefix">
                                                                    {/* 【非最佳实践】渲染时标记内部状态，以知道哪些单元格渲染了 */}
                                                                    {
                                                                        fullCondition.states.renderedModules[ConditionModuleEnums.prefix.key] = true
                                                                    }
                                                                    {/* 行号 */}
                                                                    <If condition={props.showConditionNumber}>
                                                                        <span className="condition-number">{index + 1}</span>
                                                                    </If>
                                                                    {/* 渲染自定义前缀组件 */}
                                                                    <If condition={fullCondition.states.moduleStates.prefix.visible}>
                                                                        <If condition={props.conditionPrefixComponent}>
                                                                            <ConditionPrefixComponent fields={fields} value={get(fullCondition.data, props.conditionPrefixField)} fieldKey={fullCondition.key} conditionIndex={index} disabled={fullCondition.states.moduleStates.prefix.disabled} className={props.fieldPrefixClass} onChanged={actions.onConditionPrefixChanged.params(fullCondition)} runtimeAttributes={props.conditionPrefixComponentAttrs} />
                                                                        </If>
                                                                    </If>
                                                                </td>
                                                            </If>
                                                            {/* 字段列 */}
                                                            <td className={ClassNames(
                                                                "condition-item",
                                                                "condition-field",
                                                                {
                                                                    'field-disabled': fullCondition.states.moduleStates.field.disabled,
                                                                    'text-mode': props.displayFieldSectionAsText,
                                                                    'field-error': fullCondition.states.fieldErrors.length > 0,
                                                                    'invisible-column': !props.showConditionFieldColumn
                                                                })}>
                                                                <With filteredLocalFields={actions._filterFieldsByPrefix(fields, fullCondition.data[props.conditionPrefixField])}>
                                                                    <If condition={filteredLocalFields}>
                                                                        {/* 【非最佳实践】渲染时标记内部状态，以知道哪些单元格渲染了 */}
                                                                        {
                                                                            fullCondition.states.renderedModules[ConditionModuleEnums.field.key] = true
                                                                        }
                                                                        <If condition={fullCondition.states.moduleStates.field.visible}>
                                                                            <Choose>
                                                                                {/* 字段列是否只读，直接显示成文本 */}
                                                                                <When condition={props.displayFieldSectionAsText}>
                                                                                    <div className="field-display-as-text">
                                                                                        <With matchedField={filteredLocalFields.find(f => f[props.fieldKeyField] === fullCondition.key)}>
                                                                                            <If condition={matchedField}>
                                                                                                {props.fieldTextPrefix}{get(matchedField, props.fieldTextField)}
                                                                                            </If>
                                                                                        </With>
                                                                                    </div>
                                                                                </When>
                                                                                <Otherwise>
                                                                                    {/* 字段可操作 */}
                                                                                    <Choose>
                                                                                        <When condition={props.conditionFieldComponent}>
                                                                                            {/* 自定义字段组件，需要按照下面传参的方式，作为组件开发规范 */}
                                                                                            <ConditionFieldComponent
                                                                                                fields={filteredLocalFields} /* 字段集合 */
                                                                                                value={get(fullCondition.data, props.conditionFieldField)} /* 当前字段值 */
                                                                                                fieldKey={fullCondition.key} /* 当前字段的key */
                                                                                                disabled={fullCondition.states.moduleStates.field.disabled} /* 是否禁用 */
                                                                                                onChanged={actions.onExternalFieldChanged.params(fullCondition)} /* 传入的主回调函数，在组件内当值变化时调用 */
                                                                                                className={props.fieldSelectorClass} /* 自定义className */
                                                                                                prefix={get(fullCondition.data, props.conditionPrefixField)} /* 前缀值 */
                                                                                                fieldTextPrefix={props.fieldTextPrefix} /* 每个字段文本的统一前缀文本 */
                                                                                                fieldNameField={props.fieldNameField} /* 字段值的字段名 */
                                                                                                fieldKeyField={props.fieldKeyField} /* 字段key的字段名 */
                                                                                                fieldTextField={props.fieldTextField} /* 字段文本的字段名 */
                                                                                                runtimeAttributes={props.conditionFieldComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                                                                            />
                                                                                        </When>
                                                                                        <Otherwise>
                                                                                            {/* 默认字段组件 */}
                                                                                            <Choose>
                                                                                                <When condition={props.antdStyle}>
                                                                                                    {/* AntDesign风格 */}
                                                                                                    <AntdSelect
                                                                                                        className={ClassNames("field-selector", props.fieldSelectorClass)}
                                                                                                        value={fullCondition.key}
                                                                                                        showSearch={props.showFieldsSearch}
                                                                                                        optionFilterProp="children"
                                                                                                        disabled={fullCondition.states.moduleStates.field.disabled}
                                                                                                        onChange={actions.onFieldValueChanged.params(fullCondition)}
                                                                                                    >
                                                                                                        <For of={filteredLocalFields} each="field">
                                                                                                            <With fieldKey={get(field, props.fieldKeyField)}>
                                                                                                                <AntdSelect.Option
                                                                                                                    key={fieldKey}
                                                                                                                    value={fieldKey}
                                                                                                                >
                                                                                                                    {props.fieldTextPrefix}{get(field, props.fieldTextField)}
                                                                                                                </AntdSelect.Option>
                                                                                                            </With>
                                                                                                        </For>
                                                                                                    </AntdSelect>
                                                                                                </When>
                                                                                                <Otherwise>
                                                                                                    {/* 默认使用原生的select */}
                                                                                                    <select
                                                                                                        className={ClassNames("field-selector", props.fieldSelectorClass)}
                                                                                                        value={fullCondition.key}
                                                                                                        disabled={fullCondition.states.moduleStates.field.disabled}
                                                                                                        onChange={actions.onFieldSelectChanged.params(fullCondition)}
                                                                                                    >
                                                                                                        <For of={filteredLocalFields} each="field">
                                                                                                            <With fieldKey={get(field, props.fieldKeyField)}>
                                                                                                                <option
                                                                                                                    key={fieldKey}
                                                                                                                    value={fieldKey}
                                                                                                                >
                                                                                                                    {props.fieldTextPrefix}{get(field, props.fieldTextField)}
                                                                                                                </option>
                                                                                                            </With>
                                                                                                        </For>
                                                                                                    </select>
                                                                                                </Otherwise>
                                                                                            </Choose>
                                                                                        </Otherwise>
                                                                                    </Choose>
                                                                                </Otherwise>
                                                                            </Choose>
                                                                        </If>
                                                                    </If>
                                                                </With>
                                                            </td>
                                                            {/* 操作符列 */}
                                                            <With operators={getOperators(
                                                                operatorsFilter, 
                                                                get(conditionField, props.fieldOperatorsField), 
                                                                get(fullCondition.data, props.conditionFieldField)
                                                            )}>
                                                                <If condition={operators && operators.length > fire(isAutoHideOperator, operators, get(fullCondition.data, props.conditionFieldField)) ? 1 : 0}>
                                                                    <td className={ClassNames(
                                                                        "condition-item",
                                                                        "condition-operator",
                                                                        {
                                                                            'operator-disabled': fullCondition.states.moduleStates.operator.disabled,
                                                                            'operator-error': fullCondition.states.operatorErrors.length > 0,
                                                                            'invisible-column': !props.showConditionOperatorColumn
                                                                        })}>
                                                                        {/* 【非最佳实践】渲染时标记内部状态，以知道哪些单元格渲染了 */}
                                                                        {
                                                                            fullCondition.states.renderedModules[ConditionModuleEnums.operator.key] = true
                                                                        }
                                                                        <If condition={fullCondition.states.moduleStates.operator.visible}>
                                                                            <Choose>
                                                                                <When condition={props.operatorComponent}>
                                                                                    {/* 自定义操作符组件，需要按照下面传参的方式，作为组件开发规范 */}
                                                                                    <OperatorComponent
                                                                                        operators={operators} /* 操作符集合 */
                                                                                        value={get(fullCondition.data, props.conditionOperatorField)} /* 当前操作符值 */
                                                                                        disabled={fullCondition.states.moduleStates.operator.disabled} /* 是否禁用 */
                                                                                        onChanged={actions.onExternalOperatorChanged.params(fullCondition)} /* 传入的主回调函数，在组件内当值变化时调用 */
                                                                                        className={props.operatorSelectorClass} /* 自定义className */
                                                                                        runtimeAttributes={props.operatorComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                                                                    />
                                                                                </When>
                                                                                <Otherwise>
                                                                                    <Choose>
                                                                                        <When condition={props.antdStyle}>
                                                                                            {/* AntDesign风格 */}
                                                                                            <AntdSelect
                                                                                                className={ClassNames("operator-selector", props.operatorSelectorClass)}
                                                                                                value={get(fullCondition.data, props.conditionOperatorField)}
                                                                                                disabled={fullCondition.states.moduleStates.operator.disabled}
                                                                                                onChange={actions.onOperatorValueChanged.params(fullCondition)}
                                                                                            > 
                                                                                                <For of={operators} each="operator">
                                                                                                    <With operatorId={get(operator, props.operatorIdField)}>
                                                                                                        <AntdSelect.Option
                                                                                                            key={operatorId}
                                                                                                            value={operatorId}>
                                                                                                            {get(operator, props.operatorNameField)}
                                                                                                        </AntdSelect.Option>
                                                                                                    </With>
                                                                                                </For>
                                                                                            </AntdSelect>
                                                                                        </When>
                                                                                        <Otherwise>
                                                                                            {/* 默认使用原生select */}
                                                                                            <select
                                                                                                className={ClassNames("operator-selector", props.operatorSelectorClass)}
                                                                                                value={get(fullCondition.data, props.conditionOperatorField)}
                                                                                                disabled={fullCondition.states.moduleStates.operator.disabled}
                                                                                                onChange={actions.onOperatorSelectChanged.params(fullCondition)}
                                                                                            >
                                                                                                <For of={operators} each="operator">
                                                                                                    <With operatorId={get(operator, props.operatorIdField)}>
                                                                                                        <option
                                                                                                            key={operatorId}
                                                                                                            value={operatorId}>
                                                                                                            {get(operator, props.operatorNameField)}
                                                                                                        </option>
                                                                                                    </With>
                                                                                                </For>
                                                                                            </select>
                                                                                        </Otherwise>
                                                                                    </Choose>
                                                                                </Otherwise>
                                                                            </Choose>
                                                                        </If>
                                                                    </td>
                                                                </If>
                                                            
                                                                {/* 值列 */}
                                                                <With tdColSpan={getValueTdColSpan(isAutoHideOperator, operators, get(fullCondition.data, props.conditionFieldField))}>
                                                                    <If condition={operators}>
                                                                        {/* 获取匹配的操作符对象 */}
                                                                        <With conditionOperator={operators.find(o => get(o, props.operatorIdField) === get(fullCondition.data, props.conditionOperatorField))}>
                                                                            <If condition={conditionOperator}>
                                                                                {/* 把当前字段的配置合并到components配置中，如果有同名的话，字段的配置优先级更高 */}
                                                                                <With settings={actions._mergeValueSettings(get(get(computes._valueSettingsMappings, get(get(conditionField, props.fieldInputTypeField), props.inputTypeIdField)), get(conditionOperator, props.operatorIdField)), conditionField)}>
                                                                                    <If condition={settings}>
                                                                                        {/* 先尝试获取字段的dataSource。对于集合类型的组件，需要现有数据源，再渲染组件 */}
                                                                                        <With originalDataSource={actions._getFieldDataSource(get(conditionField, props.fieldDataSourceField), conditionField, settings) || privates.EmptyDataSource}>
                                                                                            {/* 由于前面的变量都是在渲染阶段获取的，所以如果获取到同步数据源的话也只能是在渲染阶段可以使用到，也只能在这个时机把数据源回写到condition中 */}
                                                                                            {/* 【非最佳实践】 */}
                                                                                            {
                                                                                                (function () {
                                                                                                    fullCondition.fieldDataSourceResolving(conditionField, (originalDataSource === privates.EmptyDataSource ? null : originalDataSource));
                                                                                                })()
                                                                                            }
                                                                                            <If condition={originalDataSource}>
                                                                                                {/* 从配置中获取到要渲染的组件 */}
                                                                                                <With valueSettingsComponent={get(settings, props.valueSettingsComponentField)}>
                                                                                                    <If condition={valueSettingsComponent}>
                                                                                                        {/* 开始尝试异步加载数据源，当然也允许同步的数据，Async会立即返回 */}
                                                                                                        <Async await={originalDataSource} waiting={actions.promiseWaiting} then={actions.promiseResolved} catch={actions.promiseCatch}>
                                                                                                            {dataSourcePromiseToken =>
                                                                                                                <Choose>
                                                                                                                    <When condition={dataSourcePromiseToken.status === "resolved"}>
                                                                                                                        {/* 不管是同步还是异步数据源，到这里都是本地数据了 */}
                                                                                                                        {/* 【非最佳事件】目的同上 */}
                                                                                                                        {
                                                                                                                            (function () {
                                                                                                                                fullCondition.fieldDataSourceResolved(conditionField, (dataSourcePromiseToken.result === privates.EmptyDataSource ? null : dataSourcePromiseToken.result));
                                                                                                                            })()
                                                                                                                        }
                                                                                                                        {/* 根据当前选择的字段和操作符，查找到component配置对象，从而决定渲染什么组件 */}
                                                                                                                        <With valueSettings={actions._getFieldValueSettings(fullCondition.data)}>
                                                                                                                            {/* 从valueSettings中用当前操作符值去匹配，获取attributes配置对象 */}
                                                                                                                            <With attributes={get(valueSettings, get(fullCondition.data, props.conditionOperatorField)) || {}}>
                                                                                                                                <td colSpan={tdColSpan} className={ClassNames(
                                                                                                                                    "condition-item",
                                                                                                                                    "condition-value",
                                                                                                                                    {
                                                                                                                                        'value-disabled': fullCondition.states.moduleStates.value.disabled,
                                                                                                                                        'value-error': fullCondition.states.hasValueError,
                                                                                                                                        'invisible-column': !props.showConditionValueColumn
                                                                                                                                    })}>
                                                                                                                                    {/* 【非最佳实践】渲染时标记内部状态，以知道哪些单元格渲染了 */}
                                                                                                                                    {
                                                                                                                                        fullCondition.states.renderedModules[ConditionModuleEnums.value.key] = true
                                                                                                                                    }
                                                                                                                                    <If condition={fullCondition.states.moduleStates.value.visible}>
                                                                                                                                        {/* 获取到当前过滤器的值，传递给值组件 */}
                                                                                                                                        <With conditionValue={get(fullCondition.data, props.conditionValueField)}>
                                                                                                                                            <Choose>
                                                                                                                                                <When condition={attributes.isRange}>
                                                                                                                                                    {/* 区间范围类型，渲染左右两个组件 */}
                                                                                                                                                    {/* 
                                                                                                                                                    在配置中可以在attributes中配置isRange开关，来实现类似“介于”操作符的要求。
                                                                                                                                                    “介于”操作符需要输入两个值，来形成一个区间范围。 
                                                                                                                                                    我们可以渲染两个值组件来普适性的支持类似需求，理论上所有类型的组件都可以支持。
                                                                                                                                                    但如果有些组件有专用的Range组件的话，比如date-range-picker，可以优先使用专用组件，
                                                                                                                                                    此选项适合作为一种“托底”实现
                                                                                                                                                */}
                                                                                                                                                    <div className={props.rangeWrapperClass}>
                                                                                                                                                        {/* 支持在左侧组件前面前面显示一个文本，比如：从。实现 从___到___ 的效果，看起来更直观一些 */}
                                                                                                                                                        <If condition={computes.rangeFromText}>
                                                                                                                                                            <span className={ClassNames("range-from-text", computes.rangeFromTextClass)}>{computes.rangeFromText}</span>
                                                                                                                                                        </If>
                                                                                                                                                        {/* 渲染左侧值组件，规范注释参考下方 */}
                                                                                                                                                        <div className={ClassNames("range-from-value", props.rangeFromClass)}>
                                                                                                                                                            <DynamicComponent
                                                                                                                                                            // 设置一个由 字段值:操作符值 组成的key, 目的是一旦切换字段或者操作符时,确保值组件会销毁重建.
                                                                                                                                                            // 因为值组件可能无法完全做到受控,一旦两个操作符对应同一种组件类型时,会复用实例,进而可能会造成状态错乱
                                                                                                                                                                key={`${fullCondition.key}:${get(conditionOperator, props.operatorIdField)}`}
                                                                                                                                                                component={valueSettingsComponent}
                                                                                                                                                                value={conditionValue && conditionValue[0]}
                                                                                                                                                                field={conditionField}
                                                                                                                                                                operator={conditionOperator}
                                                                                                                                                                dataSource={dataSourcePromiseToken.result === privates.EmptyDataSource ? null : dataSourcePromiseToken.result}
                                                                                                                                                                disabled={fullCondition.states.moduleStates.value.disabled}
                                                                                                                                                                attributes={attributes}
                                                                                                                                                                runtimeAttributes={props.conditionValueComponentAttrs}
                                                                                                                                                                onChanged={actions.onRangeValueChanged.params(0, fullCondition, conditionField)}
                                                                                                                                                                isValid={fullCondition.states.isRangeFromValid}
                                                                                                                                                                errors={fullCondition.states.rangeFromErrors}
                                                                                                                                                            />
                                                                                                                                                        </div>
                                                                                                                                                        {/* 在右侧组件前面显示的文本 */}
                                                                                                                                                        <If condition={computes.rangeToText}>
                                                                                                                                                            <span className={ClassNames("range-to-text", computes.rangeToTextClass)}>{computes.rangeToText}</span>
                                                                                                                                                        </If>
                                                                                                                                                        {/* 渲染右侧值组件，规范注释参考下方 */}
                                                                                                                                                        <div className={ClassNames("range-to-value", props.rangeToClass)}>
                                                                                                                                                            <DynamicComponent
                                                                                                                                                            // 设置一个由 字段值:操作符值 组成的key, 目的是一旦切换字段或者操作符时,确保值组件会销毁重建.
                                                                                                                                                            // 因为值组件可能无法完全做到受控,一旦两个操作符对应同一种组件类型时,会复用实例,进而可能会造成状态错乱
                                                                                                                                                                key={`${fullCondition.key}:${get(conditionOperator, props.operatorIdField)}`}
                                                                                                                                                                component={valueSettingsComponent}
                                                                                                                                                                value={conditionValue && conditionValue[1]}
                                                                                                                                                                field={conditionField}
                                                                                                                                                                operator={conditionOperator}
                                                                                                                                                                dataSource={dataSourcePromiseToken.result === privates.EmptyDataSource ? null : dataSourcePromiseToken.result}
                                                                                                                                                                disabled={fullCondition.states.moduleStates.value.disabled}
                                                                                                                                                                attributes={attributes}
                                                                                                                                                                runtimeAttributes={props.conditionValueComponentAttrs}
                                                                                                                                                                onChanged={actions.onRangeValueChanged.params(1, fullCondition, conditionField)}
                                                                                                                                                                isValid={fullCondition.states.isRangeToValid}
                                                                                                                                                                errors={fullCondition.states.rangeToErrors}
                                                                                                                                                            />
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </When>
                                                                                                                                                <Otherwise>
                                                                                                                                                    {/* 单值组件，只用渲染一个 */}
                                                                                                                                                    {/* 自定义值组件，需要按照下面传参的方式，作为组件开发规范 */}
                                                                                                                                                    <div className={ClassNames("single-value-wrapper", props.valueWrapperClass)}>
                                                                                                                                                        <DynamicComponent
                                                                                                                                                        // 设置一个由 字段值:操作符值 组成的key, 目的是一旦切换字段或者操作符时,确保值组件会销毁重建.
                                                                                                                                                        // 因为值组件可能无法完全做到受控,一旦两个操作符对应同一种组件类型时,会复用实例,进而可能会造成状态错乱
                                                                                                                                                            key={`${fullCondition.key}:${get(conditionOperator, props.operatorIdField)}`}
                                                                                                                                                            component={valueSettingsComponent} /* 自定义组件 */
                                                                                                                                                            value={conditionValue} /* 组件的值 */
                                                                                                                                                            field={conditionField} /* 对应的字段对象 */
                                                                                                                                                            operator={conditionOperator} /* 对应的操作符枚举 */
                                                                                                                                                            dataSource={dataSourcePromiseToken.result === privates.EmptyDataSource ? null : dataSourcePromiseToken.result} /* 组件的数据源，如果需要的话 */
                                                                                                                                                            attributes={attributes} /* 在valueSettings中静态配置的attribute对象 */
                                                                                                                                                            runtimeAttributes={props.conditionValueComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                                                                                                                                            disabled={fullCondition.states.moduleStates.value.disabled} /* 是否禁用 */
                                                                                                                                                            onChanged={actions.onValueChanged.params(fullCondition, conditionField)} /* 传入的主回调函数，在组件内当值变化时调用 */
                                                                                                                                                            isValid={fullCondition.states.isValid} /* 输入值的校验结果，很少使用，可能校验机制已失效 */
                                                                                                                                                            errors={fullCondition.states.errors} /* 输入值的校验错误消息，很少使用，可能校验机制已失效 */
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </Otherwise>
                                                                                                                                            </Choose>
                                                                                                                                        </With>
                                                                                                                                    </If>
                                                                                                                                </td>
                                                                                                                            </With>
                                                                                                                        </With>
                                                                                                                    </When>
                                                                                                                    <When condition={dataSourcePromiseToken.status === "waiting"}>
                                                                                                                        {/* 数据源加载中 */}
                                                                                                                        <td colSpan={tdColSpan} className="condition-item condition-value">
                                                                                                                            <Choose>
                                                                                                                                <When condition={props.valueLoadingComponent}>
                                                                                                                                    {/* 自定义loading组件 */}
                                                                                                                                    <ValueLoadingComponent
                                                                                                                                        attributes={get(fullCondition.data, valueSettingsAttributesField)}
                                                                                                                                        field={conditionField}
                                                                                                                                        promise={originalDataSource}
                                                                                                                                        runtimeAttributes={props.conditionValueComponentAttrs}
                                                                                                                                    />
                                                                                                                                </When>
                                                                                                                                <Otherwise>
                                                                                                                                    {/* 默认显示一段文本，默认为空，可配置 */}
                                                                                                                                    {computes.valueLoadingText}
                                                                                                                                </Otherwise>
                                                                                                                            </Choose>
                                                                                                                        </td>
                                                                                                                    </When>
                                                                                                                    <When condition={dataSourcePromiseToken.status === "error"}>
                                                                                                                        {/* 数据源加载失败 */}
                                                                                                                        <td colSpan={tdColSpan} className="condition-item condition-value">
                                                                                                                            {/* 【非最佳实践】渲染时标记内部状态，以知道哪些单元格渲染了 */}
                                                                                                                            {
                                                                                                                                fullCondition.states.renderedModules[ConditionModuleEnums.value.key] = true
                                                                                                                            }
                                                                                                                            <div className="value-loading-error">
                                                                                                                                {/* 显示默认文本，可配置 */}
                                                                                                                                {get(dataSourcePromiseToken.reason, props.rejectReasonMessageField) || Udesk.utils.string.format(computes.dataSourceLoadingErrorMsgFormat, get(conditionField, props.fieldTextField))}
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </When>
                                                                                                                </Choose>
                                                                                                            }
                                                                                                        </Async>
                                                                                                    </If>
                                                                                                </With>
                                                                                            </If>
                                                                                        </With>
                                                                                    </If>
                                                                                </With>
                                                                            </If>
                                                                        </With>
                                                                    </If>
                                                                </With>
                                                            </With>
                                                            {/* 后缀，平时很少使用到 */}
                                                            {
                                                                [true, undefined].includes(fire(postfixComponentRenderInLine, 0, fullCondition.data)) ? (
                                                                    <If condition={ConditionPostfixComponent}>
                                                                        <td className="condition-item condition-postfix">
                                                                            <If condition={fullCondition.states.moduleStates.postfix.visible}>
                                                                                {/* 【非最佳实践】渲染时标记内部状态，以知道哪些单元格渲染了 */}
                                                                                {
                                                                                    fullCondition.states.renderedModules[ConditionModuleEnums.postfix.key] = true
                                                                                }
                                                                                {/* 自定义后缀组件，需要按照下面传参的方式，作为组件开发规范 */}
                                                                                <ConditionPostfixComponent
                                                                                    position={0} /* 位置信息 0：同行末尾，1：下一行*/
                                                                                    value={get(fullCondition.data, props.conditionPostfixField)}
                                                                                    fields={fields} /* 字段列表 */
                                                                                    fieldKey={fullCondition.key} /* 当前选择字段的key */
                                                                                    conditionIndex={index} /* 当前行的索引值，从0开始 */
                                                                                    className={props.fieldPostfixClass} /* 自定义className */
                                                                                    disabled={fullCondition.states.moduleStates.postfix.disabled} /* 是否禁用 */
                                                                                    onChanged={actions.onConditionPostfixChanged.params(fullCondition)} /* 传入的主回调函数，在组件内当值变化时调用 */
                                                                                    runtimeAttributes={props.conditionPostfixComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                                                                />
                                                                            </If>
                                                                        </td>
                                                                    </If>
                                                                ) : null
                                                            }
                                                            {/* 删除按钮 */}
                                                            <If condition={props.showRemovalButton && !props.disabled}>
                                                                <td className="condition-item condition-removal">
                                                                    <If condition={fullCondition.states.removalIconVisible && !fullCondition.states.moduleStates.removalIcon.disabled}>
                                                                        {/* 【非最佳实践】渲染时标记内部状态，以知道哪些单元格渲染了 */}
                                                                        {
                                                                            fullCondition.states.renderedModules[ConditionModuleEnums.removalIcon.key] = true
                                                                        }
                                                                        <If condition={fullCondition.states.moduleStates.removalIcon.visible}>
                                                                            <Choose>
                                                                                <When condition={props.removalConditionComponent}>
                                                                                    {/* 自定义删除按钮组件，需要按照下面传参的方式，作为组件开发规范 */}
                                                                                    <RemovalConditionComponent
                                                                                        condition={fullCondition.data} /* 当前condition对象 */
                                                                                        conditionIndex={index} /* 当前行的索引值，从0开始 */
                                                                                        onRemoved={actions.onExternalRemoved.params(fullCondition)} /* 传入的主回调函数，在组件内需要删除整行时调用，可以把当前行移除 */
                                                                                        removalIconText={props.removalIconText} /* 把外部传入的删除按钮的文本透传过去，可自行选择是否使用 */
                                                                                        removalIconTitle={props.removalIconTitle} /* 把外部传入的删除按钮的图标tooltip透传过去，可自行选择是否使用 */
                                                                                    />
                                                                                    <If condition={props.showAddButton && props.inlineAddButton && !props.disabled && (computes._conditions && index === computes._conditions.length - 1 )}>
                                                                                        {/* 自定义新增按钮组件，行内形式展示，需要按照下面传参的方式，作为组件开发规范 */}
                                                                                        <If condition={!props.addDisabled}>
                                                                                            <AddConditionComponent
                                                                                                onAdd={actions.onExternalAdd} /* 传入的主回调函数，在组件内部调用以新增一行条件 */
                                                                                                addIconText={props.addIconText} /* 把外部传入的新增按钮的文本透传过去，可自行选择是否使用 */
                                                                                                addIconTitle={props.addIconTitle} /* 把外部传入的新增按钮的tooltip透传过去，可自行选择是否使用 */
                                                                                                runtimeAttributes={props.onAddComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                                                                            />
                                                                                        </If>
                                                                                    </If>
                                                                                </When>
                                                                                <Otherwise>
                                                                                    {/* 默认的删除按钮 */}
                                                                                    <a href="#blank" onClick={actions.onRemoved.params(fullCondition).preventDefault()} className="filter-icon-wrapper filter-icon-removal">
                                                                                        {/* 基于WebFont的图标 */}
                                                                                        <If condition={props.removalIconClass}>
                                                                                            <i className={ClassNames("filter-icon", props.removalIconClass)} title={props.removalIconTitle}></i>
                                                                                        </If>
                                                                                        {/* 按钮文本 */}
                                                                                        <If condition={props.removalIconText}>
                                                                                            <span className="action-icon-text">{props.removalIconText}</span>
                                                                                        </If>
                                                                                    </a>
                                                                                </Otherwise>
                                                                            </Choose>
                                                                        </If>
                                                                    </If>
                                                                </td>
                                                            </If>
                                                            {/* 如果某一行的操作符被隐藏掉了，则tr就会缺少一个td，表格就错乱了。所以在这里递补一个空单元格，以让tr结构是对齐的 */}
                                                            <If condition={!fullCondition.states.renderedModules.operator}>
                                                                <td></td>
                                                            </If>
                                                            {/* 如果某一行的值被隐藏掉了，则tr就会缺少一个td，表格就错乱了。所以在这里递补一个空单元格，以让tr结构是对齐的 */}
                                                            <If condition={!fullCondition.states.renderedModules.value}>
                                                                <td></td>
                                                            </If>
                                                        </tr>
                                                        {
                                                            fire(postfixComponentRenderInLine, 1, fullCondition.data) ? (
                                                                <If condition={fullCondition.states.moduleStates.postfix.visible}>
                                                                    <tr>
                                                                        {
                                                                            ConditionPrefixComponent || props.showConditionNumber ? <td></td> : null
                                                                        }
                                                                        <td colSpan={
                                                                            (
                                                                                5 
                                                                                - (ConditionPostfixComponent ? 0 : 1)
                                                                                - ((props.showRemovalButton && !props.disabled) ? 0 : 1)
                                                                            ).toString()
                                                                        }>
                                                                            <div className={ClassNames("single-value-wrapper", props.valueWrapperClass)}>
                                                                                <ConditionPostfixComponent
                                                                                    position={1}
                                                                                    value={get(fullCondition.data, props.conditionPostfixField)}
                                                                                    fields={fields} /* 字段列表 */
                                                                                    fieldKey={fullCondition.key} /* 当前选择字段的key */
                                                                                    conditionIndex={index} /* 当前行的索引值，从0开始 */
                                                                                    className={props.fieldPostfixClass} /* 自定义className */
                                                                                    onChanged={actions.onConditionPostfixChanged.params(fullCondition)} /* 传入的主回调函数，在组件内当值变化时调用 */
                                                                                    runtimeAttributes={props.conditionPostfixComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </If>
                                                            ) : null
                                                        }
                                                    </With>
                                                </For>
                                            </With>
                                        </When>
                                        <When condition={fieldsPromiseToken.status === "waiting"}>
                                            {/* 字段列表加载中 */}
                                            {/* 默认显示一段文本，可配置。暂无自定义loading组件 */}
                                            <tr>
                                                <td className="field-loading-text" colSpan={ConditionModuleEnums.length}>
                                                    {computes.fieldsLoadingText}
                                                </td>
                                            </tr>
                                        </When>
                                        <When condition={fieldsPromiseToken.status === "error"}>
                                            {/* 字段列表加载失败 */}
                                            {/* 默认显示一段文本，可配置 */}
                                            <tr>
                                                <td className="field-loading-error" colSpan={ConditionModuleEnums.length}>
                                                    {get(fieldsPromiseToken.reason, props.rejectReasonMessageField) || computes.fieldsLoadingErrorMsg}
                                                </td>
                                            </tr>
                                        </When>
                                    </Choose>
                                }
                            </Async>
                        </tbody>
                    </table>
                    {/* 底部添加按钮 */}
                    <div className="filter-actions">
                        <If condition={props.showAddButton && !props.inlineAddButton && !props.disabled}>
                            {/* 新增按钮以另起一行的形式展示 */}
                            <div className="add-condition">
                                <Choose>
                                    <When condition={props.addConditionComponent}>
                                        {/* 自定义新增按钮组件，需要按照下面传参的方式，作为组件开发规范 */}
                                        <If condition={!props.addDisabled}>
                                            <AddConditionComponent
                                                onAdd={actions.onExternalAdd} /* 传入的主回调函数，在组件内部调用以新增一行条件 */
                                                addIconText={props.addIconText} /* 把外部传入的新增按钮的文本透传过去，可自行选择是否使用 */
                                                addIconTitle={props.addIconTitle} /* 把外部传入的新增按钮的tooltip透传过去，可自行选择是否使用 */
                                                runtimeAttributes={props.onAddComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                            />
                                        </If>
                                    </When>
                                    <Otherwise>
                                        {/* 默认的新增按钮 */}
                                        <a href="#blank" onClick={actions.onAdd.preventDefault()} className={ClassNames("filter-icon-wrapper", "filter-icon-add", { 'filter-icon-add-with-condition-number': props.showConditionNumber })}>
                                            {/* 基于WebFont的图标 */}
                                            <If condition={props.addIconClass}>
                                                <i className={ClassNames("filter-icon", props.addIconClass)} title={props.addIconTitle}></i>
                                            </If>
                                            {/* 按钮文本 */}
                                            <If condition={props.addIconText}>
                                                <span className="action-icon-text">{props.addIconText}</span>
                                            </If>
                                        </a>
                                    </Otherwise>
                                </Choose>
                            </div>
                        </If>
                        {/* 还可以添加更多自定义的Action按钮 */}
                        <If condition={props.actionsComponent}>
                            {/* 自定义底部动作组件，需要按照下面传参的方式，作为组件开发规范 */}
                            <div className="external-actions">
                                <ActionsComponent
                                    disabled={props.disabled} /* 是否禁用 */
                                    conditions={props.conditions} /* 当前所有的conditions */
                                    runtimeAttributes={props.actionsComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                />
                            </div>
                        </If>
                    </div>
                    {/* 如果过滤器有校验错误的话，在底部集中展示。验证机制很少使用，有可能已失效 */}
                    <If condition={computes._hasError}>
                        <div className="filter-validation-errors">
                            {/* 错误的汇总信息 */}
                            <div className="validation-summary">
                                {computes.validationSummary}
                            </div>
                            {/* 展示详细错误明细 */}
                            <For of={computes._conditions} each="fullCondition" index="index">
                                <With conditionField={actions._getFieldFromCondition(fullCondition.data)}>
                                    <If condition={fullCondition.states.hasError}>
                                        <div key={fullCondition.id} className="field-error">
                                            {/* 字段名 */}
                                            <div className="field-name">{get(conditionField, props.fieldTextField)}</div>
                                            {/* 字段错误，如果有的话 */}
                                            <If condition={fullCondition.states.fieldErrors.length > 0}>
                                                <For of={fullCondition.states.fieldErrors} each="error">
                                                    <div key={error.key} className="error-message">{error.content}</div>
                                                </For>
                                            </If>
                                            {/* 操作符错误，如果有的话 */}
                                            <If condition={fullCondition.states.operatorErrors.length > 0}>
                                                <For of={fullCondition.states.operatorErrors} each="error">
                                                    <div key={error.key} className="error-message">{error.content}</div>
                                                </For>
                                            </If>
                                            {/* 值错误，如果有的话 */}
                                            <With valueSettings={actions._getFieldValueSettings(fullCondition.data)}>
                                                <With isRangeComponent={get(get(valueSettings, get(fullCondition.data, props.conditionOperatorField)), "isRange")}>
                                                    <Choose>
                                                        <When condition={isRangeComponent}>
                                                            {/* 区间类型的错误 */}
                                                            {/* 左侧值的错误，如果有的话 */}
                                                            <If condition={!fullCondition.states.isRangeFromValid}>
                                                                <For of={fullCondition.states.rangeFromErrors} each="error">
                                                                    <div key={error.key} className="error-message">{error}</div>
                                                                </For>
                                                            </If>
                                                            {/* 右侧值的错误，如果有的话 */}
                                                            <If condition={!fullCondition.states.isRangeToValid}>
                                                                <For of={fullCondition.states.rangeToErrors} each="error">
                                                                    <div key={error.key} className="error-message">{error}</div>
                                                                </For>
                                                            </If>
                                                        </When>
                                                        <Otherwise>
                                                            {/* 单值类型 */}
                                                            {/* 值错误，如果有的话 */}
                                                            <If condition={!fullCondition.states.isValid}>
                                                                <For of={fullCondition.states.errors} each="error">
                                                                    <div key={error.key} className="error-message">{error}</div>
                                                                </For>
                                                            </If>
                                                        </Otherwise>
                                                    </Choose>
                                                </With>
                                            </With>
                                        </div>
                                    </If>
                                </With>
                            </For>
                        </div>
                    </If>
                    {/* 自定义foot */}
                    <Choose>
                        <When condition={props.footerComponent}>
                            {/* 自定义底部动作组件，需要按照下面传参的方式，作为组件开发规范 */}
                            <div className="filter-footer">
                                <FooterComponent
                                    conditions={props.conditions} /* 当前所有的conditions */
                                    runtimeAttributes={props.footerComponentAttrs} /* 从外部直接透传给自定义组件的props */
                                />
                            </div>
                        </When>
                        <Otherwise>
                            {/* 渲染props.footer，默认值不渲染 */}
                            <If condition={props.footer}>
                                <div className="filter-footer">
                                    {props.footer}
                                </div>
                            </If>
                        </Otherwise>
                    </Choose>
                </div>
            </div>
        );
    }
} 

export default Udesk.react.udeskify(CustomFiltersComponent);