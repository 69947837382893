import React from 'react';
import { Select as SelectAntd, Popconfirm, PageHeader } from "udesk-ui";
import Udesk from 'Udesk';
import { Input, Select } from '@alifd/next';
// import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import ReactModal from 'udesk-react/src/components/react-modal';
import SelectCallTimer from '../../components/select-call-time';
import AutoSizeTable from 'Component/common/auto-size-table';
import DataPreprocessRoute from './route';
import './style.scss';

export default class DataPreprocessTemplate extends DataPreprocessRoute {
    render() {
        // let {
        //     sdkOptions
        // } = this.props;
        let {
            columns,
            pageNum,
            pageSize,
            total
        } = this.state;
        let {
            keyWords,
            dataPreprocess,
            dataSourceFlags,
            dataSource,
            statusFlags,
            status,
            selectedItems,
            selectCallTimeVisible,
            redoCallIds,
        } = this.privates;
        let {
            actions,
            locales
        } = this;
        // const company = Udesk.data.init.company;
        return (
            // <div className="data-preprocess-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className='udesk-qa-web-page-header'
                    title={locales.components.pages.dataPreprocess.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root data-preprocess-index-page-body'>
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature('datapre:rule:view')}>
                            <div className='data-preprocess-index-page-body-search-container qa-react-page-body-search-container'>
                                <div className='qa-react-page-body-search-container-left-part pull-left'>
                                    <Select
                                        // disabled={!(company.enabledCallInspect !== 0 && company.enabledImInspect !== 0)}
                                        value={dataSource}
                                        dataSource={dataSourceFlags}
                                        onChange={actions.dataSourceChanged}
                                        hasClear
                                        placeholder={locales.components.pages.dataPreprocess.index.selectDataSource}
                                    />
                                    <Select
                                        value={status}
                                        dataSource={statusFlags}
                                        onChange={actions.statusChanged}
                                        hasClear
                                        placeholder={locales.components.pages.dataPreprocess.index.selectStatus}
                                    />
                                    <Input
                                        defaultValue={keyWords}
                                        onKeyDown={actions.searchItems}
                                        onChange={actions.mutator('privates.keyWords', (value) => value)}
                                        placeholder={locales.labels.searchPlaceHolder}
                                    />
                                </div>
                                <If
                                    condition={
                                        Udesk.data.init.user && Udesk.data.init.user.hasFeature('datapre:rule:redo')
                                    }
                                >
                                    <div className='qa-react-page-body-search-container-right-part pull-right'>
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-success'
                                            onClick={actions.createItem}
                                        >
                                            {locales.components.pages.dataPreprocess.index.createData}
                                        </button>
                                    </div>
                                </If>
                                <If
                                    condition={
                                        Udesk.data.init.user && Udesk.data.init.user.hasFeature('datapre:rule:view')
                                    }
                                >
                                    <div className='qa-react-page-body-search-container-right-part pull-right'>
                                        <SelectAntd
                                            defaultValue={locales.labels.batchActions}
                                            value={locales.labels.batchActions}
                                            style={{ width: 120, marginRight: '10px' }}
                                            onChange={actions.selecthandleChange}
                                        >
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature('datapre:rule:delete')
                                                }
                                            >
                                                <SelectAntd.Option value='delete' style={{ padding: '0px' }}>
                                                    <Popconfirm
                                                        title={Udesk.utils.string.format(
                                                            locales.labels.deleteRecordInfo,
                                                            selectedItems.length
                                                        )}
                                                        okText={locales.labels.confirm}
                                                        cancelText={locales.labels.cancel}
                                                        onCancel={actions.deleteRecordCancel}
                                                        onConfirm={actions.deleteRecordOk}
                                                    >
                                                        <a
                                                            href=''
                                                            onClick={actions.deletReordClick}
                                                            style={{
                                                                display: 'inline-block',
                                                                width: '100%',
                                                                padding: '5px 12px',
                                                            }}
                                                        >
                                                            {locales.labels.deleteRecord}
                                                        </a>
                                                    </Popconfirm>
                                                </SelectAntd.Option>
                                            </If>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature('datapre:rule:redo')
                                                }
                                            >
                                                <SelectAntd.Option value='redo'>
                                                    {locales.labels.redo}
                                                </SelectAntd.Option>
                                            </If>
                                        </SelectAntd>
                                    </div>
                                </If>
                            </div>
                            <div className='data-preprocess-index-page-body-content-container qa-react-page-body-content-container'>
                                <AutoSizeTable
                                    columns={columns}
                                    dataSource={dataPreprocess}
                                    // checkable={true}
                                    // onSelectionChanged={actions.onCheckChange}
                                    // selectedItems={[].concat(selectedItems)}
                                    subtractedHeight={144}
                                    pagination={false}
                                    rowSelection={{
                                        onSelectAll: actions.onSelectAll,
                                        selectedRowKeys: selectedItems.map(i => i.id),
                                        onSelect: actions.onCheckChange
                                    }}
                                />
                                {/* <ReactPager
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    total={total}
                                    languageCode={sdkOptions.props.language}
                                    pageChanged={actions.pageChanged}
                                /> */}
                                <UdeskPagination
                                    current={pageNum}
                                    pageSize={pageSize}
                                    total={total}
                                    onChange={actions.pageChanged}
                                />
                            </div>
                        </If>
                        <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save}
                            visible={selectCallTimeVisible}
                            title={locales.labels.redo}
                            closeIconClass='udesk-qc-iconfont icon-qc-quxiao pull-right'
                            onCancel={actions.redo.params(false)}
                            onOk={actions.redo.params(true)}
                            headerClassName='udesk-qa-modal-default-header'
                            footerClassName='udesk-qa-modal-default-footer'
                        >
                            <SelectCallTimer
                                onDateChanged={actions.onDateChanged}
                                recordCount={redoCallIds.length}
                            ></SelectCallTimer>
                        </ReactModal>
                    </div>
                </div>
            </div>
        );
    }
}

