// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseRiskConfigFoundResponse } from '../../types';

/**
 * findByCompany
 *
 * @export
 * @tags risk-config-controller
 * @link [GET] /riskConfigs/findByCompany
 */
export function getRiskConfigsFindByCompany(): Promise<BaseResponseRiskConfigFoundResponse>;
export function getRiskConfigsFindByCompany(
    options: ApiOptions<never, never>
): Promise<BaseResponseRiskConfigFoundResponse>;
export function getRiskConfigsFindByCompany(
    options?: ApiOptions<never, never>
): Promise<BaseResponseRiskConfigFoundResponse> {
    return request<never, never, never>('/riskConfigs/findByCompany', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getRiskConfigsFindByCompany',
    });
}

export const meta = [
    { tags: ['risk-config-controller'], path: '/riskConfigs/findByCompany', method: 'get' },
];
