import React from 'react';
import Udesk from 'Udesk';
import ClassifiedTrainingHistoryIndexRoute from './route';
import { Button, Select, TreeSelect, Input, PageHeader } from 'udesk-ui';
import './style.scss';
// import _debounce from 'lodash-es/debounce';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import AutoSizeTable from 'Component/common/auto-size-table';
import UdeskDatePicker from 'Component/common/udesk-date-picker';

const { Option } = Select;
const { Search } = Input;

export default class ClassifiedTrainingHistoryIndexTemplate extends ClassifiedTrainingHistoryIndexRoute {
    render() {
        let {
            startTime,
            endTime,
            columns,
            data,
            paging,
            smartTagCategorys,
            // userList,
            dataSource,
            seletedCategoryId,
            id,
            seletedDataSource
        } = this.privates;
        let {
            actions,
            locales
        } = this;
        return (
            // <div className="classified-training-history-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.trainingCenter.classifiedTrainingHistory.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root classified-training-history-index-page'>
                        <div className="classified-training-history-index-page-body">
                            <div className="classified-training-history-index-page-body-top">
                                <div style={{ display: 'flex', flexFlow: 'wrap' }}>
                                    <div className="classified-training-history-index-page-body-top-date">
                                        {
                                            locales.components.pages.trainingCenter.classifiedTrainingHistory.time
                                        }
                                        <UdeskDatePicker style={{ width: 246 }} allowClear={false} onChange={actions.selectTime} value={[startTime, endTime]} />
                                    </div>
                                    <div className="classified-training-history-index-page-body-top-tag">
                                        {locales.components.pages.trainingCenter.classifiedTrainingHistory.tag}
                                        {/* <Select mode='multiple' style={{ minWidth: 112 }} defaultValue={[]} onChange={actions.selectTag} value={seletedCategoryId}>
                                            {
                                                smartTagCategorys.map(tag => {
                                                    return (
                                                        <Option value={tag.id} title={tag.categoryName}>{tag.categoryName}</Option>
                                                    );
                                                })
                                            }
                                        </Select> */}
                                        <TreeSelect
                                            style={{ width: 250 }}
                                            treeData={smartTagCategorys}
                                            onChange={actions.selectTag}
                                            value={seletedCategoryId}
                                            showSearch
                                            // allowClear
                                            multiple
                                            treeNodeFilterProp='title'
                                            showArrow
                                            placeholder={locales.components.pages.trainingCenter.classifiedTraining.placeHolder}
                                            treeDefaultExpandAll={false}
                                            treeDataSimpleMode={true}
                                        />
                                    </div>
                                    <div className="classified-training-history-index-page-body-top-user">
                                        {locales.components.pages.trainingCenter.classifiedTrainingHistory.user}
                                        {/* <Select
                                    mode='multiple'
                                    showSearch
                                    style={{ minWidth: 112 }}
                                    value={this.privates.seletedUserId}
                                    defaultValue={[]}
                                    optionFilterProp="children"
                                    onFocus={actions.seleteUserFocus}
                                    onChange={actions.seleteUser}
                                    onSearch={_debounce(actions.inputUserKeyWords, 500)}
                                // filterOption={(input, option) =>
                                //     option.props.children.indexOf(input) >= 0
                                // }
                                >
                                    {
                                        userList.map(user => {
                                            return (
                                                <Option value={user.id} title={user.realname}>{user.realname}</Option>
                                            );
                                        })
                                    }
                                </Select> */}
                                        <div style={{ display: 'inline-block', minWidth: '100px' }}>
                                            <AutoComplete isMulti={true} value={this.privates.seletedUserId} loadOptions={actions.loadUserOptions} onChanged={actions.seleteUser} enableEmptySearch={true} />
                                        </div>
                                    </div>
                                    <div className="classified-training-history-index-page-body-top-data">
                                        {locales.components.pages.trainingCenter.classifiedTrainingHistory.data}
                                        <Select style={{ minWidth: 112 }} onChange={actions.selectDataSource} value={seletedDataSource}>
                                            {
                                                dataSource.map(item => {
                                                    return (
                                                        <Option value={item.id}>{item.type}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </div>
                                    <div className="classified-training-history-index-page-body-top-data">
                                        {locales.components.pages.trainingCenter.classifiedTrainingHistory.searchBar}
                                        <Search
                                            placeholder={locales.components.pages.trainingCenter.classifiedTrainingHistory.searchBarPlaceholder}
                                            onSearch={actions.searchBarHandle}
                                            value={id}
                                            onChange={actions.searchBarInputChange}
                                            onPressEnter={actions.searchBarHandleByEnter}
                                            style={{ minWidth: 240 }}
                                        />
                                    </div>
                                </div>
                                <div className="classified-training-history-index-page-body-top-btn">
                                    <Button type="primary" onClick={actions.search}>
                                        {
                                            locales.components.pages.trainingCenter.classifiedTrainingHistory
                                                .search
                                        }
                                    </Button>
                                    <Button
                                        onClick={actions.reset}
                                        style={{ marginLeft: "8px" }}
                                    >
                                        {
                                            locales.components.pages.trainingCenter.classifiedTrainingHistory
                                                .reset
                                        }
                                    </Button>
                                </div>
                            </div>
                            <div className="classified-training-history-index-page-body-table">
                                <AutoSizeTable
                                    columns={columns}
                                    dataSource={data}
                                    subtractedHeight={document.getElementsByClassName('classified-training-history-index-page-body-top')[0] && window.getComputedStyle(document.getElementsByClassName('classified-training-history-index-page-body-top')[0]).height}
                                    pagination={{
                                        onChange: actions.pageNumChange,
                                        total: paging.total,
                                        current: paging.pageNum,
                                        pageSize: paging.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['5', '10', '20', '50', '100'],
                                        onShowSizeChange: actions.pageSizeChange,
                                        showTotal: (total, range) => {
                                            return Udesk.utils.string.format(locales.components.pages.trainingCenter.classifiedTrainingHistory.table.page, total, Math.ceil(paging.total / paging.pageSize));
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
