import { useContext, useEffect, useState,createContext } from 'react';
// import { UserContext } from '..';
import UdeskLocales from 'UdeskLocales';
import { useRequest } from 'src/util/hook';

const UserContext = createContext<[any, React.Dispatch<React.SetStateAction<any>>]>(
    [] as any
);

export const useSummary = (taskId,startTime, endTime, userSelectObj, echartsType) => {
    console.log('echartsType',echartsType);
    const request = useRequest();
    const [taskSummary] = useState<any>(null);
    const [taskSummaryEcharts, setTaskSummaryEcharts] = useState<any>(null);
    const [_params] = useContext(UserContext);

    useEffect(() => {
        const params: any = {
            organizationIds: userSelectObj.organizationIds,
            userIds: userSelectObj.userIds,
            endTime: endTime.split(' ')[0],
            startTime: startTime.split(' ')[0],
        };
        let requestUrlTime = '/intelligentPartnerTaskInfos/result/analysis/trickTime/' + taskId;
        let requestUrlCount = '/intelligentPartnerTaskInfos/result/analysis/trickCount/' + taskId;
        let requestUrl: string = '';
        if (echartsType === 'byTime') {
            requestUrl = requestUrlTime;
        } else if (echartsType === 'byNumber') {
            requestUrl = requestUrlCount;
        }
        console.log('paramsss',params);
        if (!params.startTime || !params.endTime || !requestUrl) return;
        request(requestUrl, params, 'post').then((resp) => {
            const taskTrend = resp.data ?? [];
            const xAxis: string[] = []; // X轴数据
            
            const avgScore: any[]  = []; // 平均分
            const nodeName: string[] = []; // 节点名称
            const nodeId: number[]  = []; // nodeId

            let markObj = taskTrend[0]['resultAnalysisList'];
            markObj.forEach(item => {
                nodeName.push(item.nodeName ?? '');
                nodeId.push(item.nodeId ?? '');
            });
            taskTrend.forEach((t) => {
                if (echartsType === 'byTime') {
                    xAxis.push(t.datePoint!);
                } else if (echartsType === 'byNumber') {
                    xAxis.push(t.stringCount!);
                }
                let tmpSccore: any[] = [];
                if (t.resultAnalysisList) {
                    t.resultAnalysisList.forEach(item => {
                        // console.log(item);
                        tmpSccore.push(item.avgScore ?? 0);
                    });
                    avgScore.push(tmpSccore);
                }
            });
            setTaskSummaryEcharts(
                getNumberEchartOptions(
                    nodeName, // ['a1','a2']
                    nodeId, // [3101, 3301]
                    avgScore, // [[100, 101], [200, 201], [300, 301]]
                    xAxis // [10,11,12]
                )
            );
        });
    }, [
        startTime,
        endTime,
        userSelectObj,
        echartsType,
        _params?.organizationIds,
        _params?.startTime,
        _params?.endTime,
    ]);

    return {
        taskSummary,
        taskSummaryEcharts,
    };
};

function getNumberEchartOptions(
    nodeName, // ['a1','a2'] 
    nodeId, // 
    avgScoreArr, // [[100, 101], [200, 201], [300, 301]] => arr1：[100, 200, 300] arr2:[101, 201, 301]
    xAxis // [10,11,12]
) {
    // console.log('res', nodeName, avgScoreArr,xAxis);
    // 拆分 avgScoreArr avgScoreNodeId 为nodeName length 的个数 用对象保存
    let valueObjArr = {};
    nodeName.forEach((item, index) => {
        console.log(item);
        valueObjArr[index] = [];
    });
    avgScoreArr.forEach(itemArr => {
        itemArr.forEach((vo, index) => {
            valueObjArr[index].push(vo);
        });
    });
    // 获取所有结果
    let allValueArr: any[] = [];
    for (let key in valueObjArr) {
        allValueArr = allValueArr.concat(valueObjArr[key]);
    }

    const leftMaxValue = calMax(Math.max(...allValueArr));
    const rightMaxValue = calMax(Math.max(...allValueArr));

    const interval_left = leftMaxValue / 5; //左轴间隔
    const interval_right = rightMaxValue / 5; //右轴间隔

    // console.log(tmpSeries);

    let variableSeries: any[]= [];
    nodeName.map((item, index) => {
        let tmpObj = {
            name: item,
            type: 'line',
            data: valueObjArr[index],
            id: nodeId[index],
            smooth: true,
        };
        // tmpObj.name = item;
        // return tmpObj
        variableSeries.push(tmpObj);
    });
    let series = variableSeries;
    return getEchartOptions(
        series,
        leftMaxValue,
        interval_left,
        rightMaxValue,
        interval_right,
        xAxis
    );
}

export const getFirstEchartOptions = (
    nodeName, // ['a1','a2'] 
    nodeId, // 
    avgScoreArr, // [[100, 101], [200, 201], [300, 301]] => arr1：[100, 200, 300] arr2:[101, 201, 301]
    xAxis // [10,11,12]
) => {
    console.log(UdeskLocales);
    // console.log('res', nodeName, avgScoreArr,xAxis);
    // 拆分 avgScoreArr avgScoreNodeId 为nodeName length 的个数 用对象保存
    let valueObjArr = {};
    nodeName.forEach((item, index) => {
        console.log(item);
        valueObjArr[index] = [];
    });
    avgScoreArr.forEach(itemArr => {
        itemArr.forEach((vo, index) => {
            valueObjArr[index].push(vo);
        });
    });
    // 获取所有结果
    let allValueArr: any[] = [];
    for (let key in valueObjArr) {
        allValueArr = allValueArr.concat(valueObjArr[key]);
    }
    // console.log('allValueArr',...allValueArr);

    const leftMaxValue = calMax(allValueArr);
    const rightMaxValue = calMax(allValueArr);

    const interval_left = leftMaxValue / 5; //左轴间隔
    const interval_right = rightMaxValue / 5; //右轴间隔


    let variableSeries: any[]= [];
    nodeName.map((item, index) => {
        let tmpObj = {
            name: item,
            type: 'line',
            data: valueObjArr[index],
            id: nodeId[index],
            smooth: true,
        };
        // tmpObj.name = item;
        // return tmpObj
        variableSeries.push(tmpObj);
    });
    let series = variableSeries;
    return getEchartOptions(
        series,
        leftMaxValue,
        interval_left,
        rightMaxValue,
        interval_right,
        xAxis
    );
};

function getEchartOptions(series, maxLeft, interval_left, maxRight, interval_right, xAxis) {
    const option = {
        backgroundColor: '#F5F8FE',
        color: [
            "#5B8FF9",
            "#5AD8A6",
            "#F6BD16",
            "#E86452",
            "#6DC8EC",
            "#945FB9",
            "#FF9845",
            "#1E9493",
            "#FF99C3",
            "#CDDDFD",
            "#CDF3E4",
            "#FCEBB9",
            "#F8D0CB",
            "#D3EEF9",
            "#DECFEA",
            "#FFE0C7",
            "#BBDEDE",
            "#FFE0ED",
        ],
        title: {
            text: /* 话术平均得分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.scriptAnalysis.hook.useSummary.averageScoreOfScript
        },
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            top: 24,
            data: series.map((s) => s.name),
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
        },
        yAxis: [
            {
                type: 'value',
                min: 0,
                max: maxLeft,
                splitNumber: 5,
                interval: interval_left,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return (
                            value + /* 分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.scriptAnalysis.hook.useSummary.branch
                        );
                    },
                },
            },
        ],
        series,
    };

    return option;
}

function calMax(arr) {
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
        // 求出一组数组中的最大值
        if (max < arr[i]) {
            max = arr[i];
        }
    }
    const maxint = Math.ceil(max / 10); // 向上取整
    const maxval = maxint * 10; // 最终设置的最大值
    return maxval; // 输出最大值
}
