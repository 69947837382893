// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostMessageLogsExportBody, BaseResponseVoid } from '../../types';

/**
 * export
 *
 * @export
 * @tags 消息日志
 * @link [POST] /message-logs/export
 */
export function postMessageLogsExport(data: PostMessageLogsExportBody): Promise<BaseResponseVoid>;
export function postMessageLogsExport(
    data: PostMessageLogsExportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postMessageLogsExport(
    data: PostMessageLogsExportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostMessageLogsExportBody, never, never>('/message-logs/export', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postMessageLogsExport',
    });
}

export const meta = [{ tags: ['消息日志'], path: '/message-logs/export', method: 'post' }];
