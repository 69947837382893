// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostReviewIntelligentPartnerTestBody,
    BaseResponseIntelligentPartnerTrainingWords,
} from '../../../types';

/**
 * 测试人机练习
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/test
 */
export function postReviewIntelligentPartnerTest(
    data: PostReviewIntelligentPartnerTestBody
): Promise<BaseResponseIntelligentPartnerTrainingWords>;
export function postReviewIntelligentPartnerTest(
    data: PostReviewIntelligentPartnerTestBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerTrainingWords>;
export function postReviewIntelligentPartnerTest(
    data: PostReviewIntelligentPartnerTestBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerTrainingWords> {
    return request<PostReviewIntelligentPartnerTestBody, never, never>(
        '/review/intelligentPartner/test',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerTest',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/test',
        method: 'post',
    },
];
