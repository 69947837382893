import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import ReactTinymceLoaderService from '../../services/react-tinymce-loader';
import { Editor } from '@tinymce/tinymce-react';
import Udesk from '../../udesk/index';
import Locales from '../../udesk/locales';
class ReactTinymce extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRender: false,
            inint: null,
            defaultId: 'Editor' + Udesk.utils.string.random(10)
        };
        this.editorInstance = null;
    }

    insertEditorContent(content) {
        let editor = this.editorInstance;
        if (editor != null) {
            editor.insertContent(content);
        }
    }

    setEditorContent(content) {
        let editor = this.editorInstance;
        if (editor != null) {
            editor.setContent(content);
            editor.undoManager.clear();
        }
    }

    focusEditor() {
        let editor = this.editorInstance;
        if (editor != null) {
            editor.focus();
        }
    }

    getEditorBody() {
        let editor = this.editorInstance;
        if (editor != null) {
            return editor.getBody();
        }
        return null;
    }

    getEditorContent() {
        let editor = this.editorInstance;
        if (editor != null) {
            return editor.getContent();
        }
        return null;
    }

    actions = {
        inintTinymceCallback() {
            let {
                selector,
                menubar,
                menu,
                toolbar,
                statusbar,
                options,
                theme,
                placeholder,
                initInstanceCallback,
                plugins,
                setup,
                branding,
                width,
                height,
                minWidth,
                minHeight,
                maxHeight,
                imageAdvtab,
                bodyClass,
                airModeContentStyle,
                skin,
                fontFormats,
                fontsizeFormats,
                showLink,
                showTable,
                showTextColor,
                showBackColor,
                showCharmap,
                showEmoticons,
                showImage,
                showMedia,
                showImagetools,
                showCode,
                showBullist,
                showNumList,
                showOutdent,
                showIndent,
                showFont,
                showFontSize,
                languageCode,
                getToolbar,
                getToolbarContext,
                uploadImage,
                singleLine,
                skinUrl,
                baseUrl,
                externalPlugins,
                extendedValidElements,
                autoFocus,
                formats,
            } = this.props;

            plugins = plugins || `advlist autolink lists ${showLink ? "link" : ""}  charmap print preview anchor searchreplace
                    visualblocks ${showCode ? "code" : ""} fullscreen insertdatetime ${showMedia ? "media" : ""} ${showTable ? "table" : ""} contextmenu paste
                    wordcount colorpicker ${(showTextColor || showBackColor) ? "textcolor" : ""} ${showEmoticons ? "emoticons" : ""} ${showImage ? "image imagetools" : ""}  visualchars
                    template codesample hr spellchecker textpattern`;
            toolbar = toolbar || `bold italic underline | alignleft aligncenter alignright ${(showBullist || showNumList) ? "|" : ""} ${showBullist ? "bullist" : ""} ${showNumList ? "numlist" : ''} ${(showOutdent || showIndent) ? "|" : ""} ${showOutdent ? "outdent" : ""} ${showIndent ? "indent" : ""} ${(showLink || showImage || showMedia) ? "|" : ""}} ${showLink ? "link" : ""} ${showImage ? "image" : ""} ${showMedia ? "media" : ""} ${showTable ? "| table" : ""} ${(showFont || showFontSize) ? "|" : ""} ${showFont ? "fontselect" : ""} ${showFontSize ? "fontsizeselect" : ""} ${(showTextColor || showBackColor) ? "|" : ""} ${showTextColor ? "forecolor" : ""} ${showBackColor ? "backcolor" : ""} ${(showCharmap || showEmoticons) ? "|" : ""} ${showCharmap ? "charmap" : ""} ${showEmoticons ? "emoticons" : ""} ${showCode ? "code" : ""} ${(showImage && showImagetools) ? "rotateleft rotateright flipv fliph imageoptions editimage" : ""}`;
            if (getToolbar) {
                toolbar = getToolbar.call(getToolbarContext, toolbar);
            }
            if (singleLine) {
                toolbar = [];
            } else {
                let customButtonToolbarNames = this.actions.getCustomButtonsNames();
                if (customButtonToolbarNames) {
                    toolbar = toolbar += " | " + customButtonToolbarNames;
                }
            }

            let defaultOptions = {
                selector: selector || `#${this.state.defaultId}`,
                plugins: plugins,
                toolbar: toolbar,
                inline: false,
                image_advtab: imageAdvtab,
                theme: theme,
                placeholder: placeholder,
                skin,
                init_instance_callback:
                    initInstanceCallback && typeof initInstanceCallback === 'function'
                        ? initInstanceCallback
                        : undefined,
                menubar: menubar || false,
                menu,
                statusbar: statusbar,
                branding: branding,
                formats: formats,
                link_title: false,
                target_list: false,
                display: 'inline-block',
                removed_menuitems: 'newdocument',
                relative_urls: false,
                anchor_top: false,
                anchor_bottom: false,
                allow_unsafe_link_target: true,
                font_formats:
                    fontFormats ||
                    '雅黑=微软雅黑;宋体=宋体;黑体=黑体;Verdana=verdana;Georgia=georgia;Courier New=courier new,courier,monospace;Sans-serif=sans-serif;Arial=arial;Times New Roman=times new roman;Impact=impact;Calibri=calibri;',
                fontsize_formats: fontsizeFormats || "8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt",
                setup: (editor) => {
                    if (setup && typeof setup === "function") {
                        setup(editor);
                        this.actions.addCustomButton(editor);
                    }
                    if (singleLine) {
                        editor.on("keydown", function (e) {
                            if (e.keyCode === 13) {
                                return false;
                            }
                        });
                    }
                },
                width: width,
                height: height,
                min_height: minHeight,
                max_height: maxHeight,
                min_width: minWidth,
                body_class: bodyClass,
                content_style: airModeContentStyle,
                language: languageCode
            };
            if (skinUrl) {
                defaultOptions["skin_url"] = skinUrl;
            }
            if (baseUrl) {
                defaultOptions["base_url"] = baseUrl;
            }
            if (extendedValidElements) {
                defaultOptions["extended_valid_elements"] = extendedValidElements;
            }

            if (externalPlugins) {
                defaultOptions["external_plugins"] = externalPlugins;
            }

            if (showImage) {
                defaultOptions["images_upload_handler"] = (blobInfo, success, failure) => {
                    if (typeof uploadImage !== "function") {
                        throw new Error("`uploadImage` must be assigned and must be function in react-tinymce.js.");
                    }
                    let file = blobInfo.blob();
                    if (file == null) {
                        return;
                    }
                    let uploadImageUrl = null;
                    let uploadPromise = uploadImage.call(this, file);
                    if (uploadPromise.then) {
                        uploadPromise.then((resp) => {
                            success(resp);
                        }, (reason) => {
                            failure();
                        });
                    } else {
                        if (typeof uploadPromise === "string") {
                            uploadImageUrl = uploadPromise;
                            success(uploadImageUrl);
                        } else {
                            throw new Error("`uploadImage` method must return a promise or string.");
                        }
                    }
                };
            }

            if (autoFocus) {
                defaultOptions["auto_focus"] = this.state.defaultId;
            }

            let inint = defaultOptions;

            if (options) {
                Object.assign(inint, options);
            }

            this.setState({
                isRender: true,
                inint
            });
        },
        onInit(initEvent, editor) {
            this.editorInstance = editor;
            this.trigger("onInit", initEvent, editor);
        },
        getCustomButtonsNames() {
            let customButtons = this.props.customButtons;
            let result = "";
            if (customButtons && customButtons.length > 0) {
                customButtons.forEach((button) => {
                    if (result) {
                        result += (" | " + button.name);
                    } else {
                        result += button.name;
                    }
                });
            }
            return result;
        },
        addCustomButton(editor) {
            let customButtons = this.props.customButtons;
            if (customButtons && customButtons.length > 0) {
                customButtons.forEach((button) => {
                    if (button.name && (button.text || button.icon || button.image)) {
                        editor.addButton(button.name, button);
                    }
                });
            }
        }
    };

    componentDidMount() {
        if (window && window.tinymce) {
            this.actions.inintTinymceCallback();
        } else {
            let { tinymcePath } = this.props;
            if (typeof tinymcePath === "function") {
                tinymcePath = tinymcePath.call(this);
            }
            ReactTinymceLoaderService.setLoadUrl(tinymcePath);
            ReactTinymceLoaderService.loadAsync(this.actions.inintTinymceCallback);
        }
    }
    componentWillUnmount() {
        this.editorInstance = null;
        ReactTinymceLoaderService.removeCallback(this.actions.inintTinymceCallback);
    }
    render() {
        let {
            classNames,
            languageCode,
            value,
            readonly,
            initialValue,
            onEditorChange,
            onClick,
            onAddUndo,
            onBeforeAddUndo,
            onBeforeExecCommand,
            onBeforeGetContent,
            onBeforeRenderUI,
            onBeforeSetContent,
            onBeforePaste,
            onBlur,
            onChange,
            onClearUndos,
            onContextMenu,
            onCopy,
            onCut,
            onDblclick,
            onDeactivate,
            onDirty,
            onDrag,
            onDragDrop,
            onDragEnd,
            onDragGesture,
            onDragOver,
            onDrop,
            onExecCommand,
            onFocus,
            onFocusIn,
            onFocusOut,
            onGetContent,
            onHide,
            onKeyDown,
            onKeyPress,
            onKeyUp,
            onLoadContent,
            onMouseDown,
            onMouseEnter,
            onMouseLeave,
            onMouseMove,
            onMouseOut,
            onMouseOver,
            onMouseUp,
            onNodeChange,
            onObjectResizeStart,
            onObjectResized,
            onObjectSelected,
            onPaste,
            onPostProcess,
            onPostRender,
            onPreProcess,
            onProgressState,
            onRedo,
            onRemove,
            onReset,
            onSaveContent,
            onSelectionChange,
            onSetAttrib,
            onSetContent,
            onShow,
            onSubmit,
            onActivate,
            onUndo,
            onVisual,
        } = this.props;
        let { isRender, inint } = this.state;
        let { actions } = this;

        return (
            <div className={ClassNames('react-tinymce', { [`${classNames}`]: classNames })}>
                <Choose>
                    <When condition={isRender && (window && window.tinymce)}>
                        <Editor
                            id={this.state.defaultId}
                            init={inint}
                            disabled={readonly}
                            value={value}
                            initialValue={initialValue}
                            onEditorChange={onEditorChange}
                            onClick={onClick}
                            onAddUndo={onAddUndo}
                            onBeforeAddUndo={onBeforeAddUndo}
                            onBeforeExecCommand={onBeforeExecCommand}
                            onBeforeGetContent={onBeforeGetContent}
                            onBeforeRenderUI={onBeforeRenderUI}
                            onBeforeSetContent={onBeforeSetContent}
                            onBeforePaste={onBeforePaste}
                            onBlur={onBlur}
                            onChange={onChange}
                            onClearUndos={onClearUndos}
                            onContextMenu={onContextMenu}
                            onCopy={onCopy}
                            onCut={onCut}
                            onDblclick={onDblclick}
                            onDeactivate={onDeactivate}
                            onDirty={onDirty}
                            onDrag={onDrag}
                            onDragDrop={onDragDrop}
                            onDragEnd={onDragEnd}
                            onDragGesture={onDragGesture}
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                            onExecCommand={onExecCommand}
                            onFocus={onFocus}
                            onFocusIn={onFocusIn}
                            onFocusOut={onFocusOut}
                            onGetContent={onGetContent}
                            onHide={onHide}
                            onInit={actions.onInit}
                            onKeyDown={onKeyDown}
                            onKeyPress={onKeyPress}
                            onKeyUp={onKeyUp}
                            onLoadContent={onLoadContent}
                            onMouseDown={onMouseDown}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            onMouseMove={onMouseMove}
                            onMouseOut={onMouseOut}
                            onMouseOver={onMouseOver}
                            onMouseUp={onMouseUp}
                            onNodeChange={onNodeChange}
                            onObjectResizeStart={onObjectResizeStart}
                            onObjectResized={onObjectResized}
                            onObjectSelected={onObjectSelected}
                            onPaste={onPaste}
                            onPostProcess={onPostProcess}
                            onPostRender={onPostRender}
                            onPreProcess={onPreProcess}
                            onProgressState={onProgressState}
                            onRedo={onRedo}
                            onRemove={onRemove}
                            onReset={onReset}
                            onSaveContent={onSaveContent}
                            onSelectionChange={onSelectionChange}
                            onSetAttrib={onSetAttrib}
                            onSetContent={onSetContent}
                            onShow={onShow}
                            onSubmit={onSubmit}
                            onActivate={onActivate}
                            onUndo={onUndo}
                            onVisual={onVisual}
                        />
                    </When>
                    <Otherwise>{Locales.get('components.reactTinymce.isLoading', languageCode)}</Otherwise>
                </Choose>
            </div>
        );
    }
}

ReactTinymce.propTypes = {
    className: PropTypes.string,
    extendedValidElements: PropTypes.string,
    tinymcePath: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    initInstanceCallback: PropTypes.func,
    uploadImage: PropTypes.func,
    branding: PropTypes.bool,
    getToolbar: PropTypes.func,
    getToolbarContext: PropTypes.object
};

ReactTinymce.defaultProps = {
    classNames: '',
    languageCode: '',
    tinymcePath: '',
    tagName: 'div',
    apiKey: '',
    cloudChannel: '',
    id: 'div',

    options: null,
    value: '',
    initialValue: '',
    placeholder: undefined,

    toolbar: null,
    plugins: undefined,
    // selector: 'textarea',
    width: undefined,
    height: undefined,
    minWidth: undefined,
    minHeight: undefined,
    maxHeight: undefined,
    menu: null,
    menubar: false,
    imageAdvtab: false,
    bodyClass: '',
    airModeContentStyle: '',
    singleLine: false,
    customButtons: null,
    autoFocus: false,

    inline: true,
    theme: 'modern',
    theme_url: '',
    skinUrl: '',
    baseUrl: '',
    externalPlugins: null,
    statusbar: true,
    skin: 'lightgray',

    cacheSuffix: '',
    contentSecurityPolicy: '',
    hiddenInput: true,
    readonly: false,

    target: null,
    branding: false,
    formats: {},
    elementpath: true,
    extendedValidElements: "",

    fontFormats: null,
    fontsizeFormats: null,
    showLink: true,
    showTable: false,
    showTextColor: true,
    showBackColor: true,
    showCharmap: true,
    showEmoticons: true,
    showImage: false,
    showMedia: true,
    showImagetools: true,
    showCode: true,
    showBullist: true,
    showNumList: true,
    showOutdent: true,
    showIndent: true,
    showFont: true,
    showFontSize: true,
    initInstanceCallback: (editor) => { },
    uploadImage: (file) => { },
    setup: (editor) => { },
    onEditorChange: undefined,
    onClick: undefined,
    onAddUndo: undefined,
    onBeforeAddUndo: undefined,
    onBeforeExecCommand: undefined,
    onBeforeGetContent: undefined,
    onBeforeRenderUI: undefined,
    onBeforeSetContent: undefined,
    onBeforePaste: undefined,
    onBlur: undefined,
    onChange: undefined,
    onClearUndos: undefined,
    onContextMenu: undefined,
    onCopy: undefined,
    onCut: undefined,
    onDblclick: undefined,
    onDeactivate: undefined,
    onDirty: undefined,
    onDrag: undefined,
    onDragDrop: undefined,
    onDragEnd: undefined,
    onDragGesture: undefined,
    onDragOver: undefined,
    onDrop: undefined,
    onExecCommand: undefined,
    onFocus: undefined,
    onFocusIn: undefined,
    onFocusOut: undefined,
    onGetContent: undefined,
    onHide: undefined,
    onInit: undefined,
    onKeyDown: undefined,
    onKeyPress: undefined,
    onKeyUp: undefined,
    onLoadContent: undefined,
    onMouseDown: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    onMouseMove: undefined,
    onMouseOut: undefined,
    onMouseOver: undefined,
    onMouseUp: undefined,
    onNodeChange: undefined,
    onObjectResizeStart: undefined,
    onObjectResized: undefined,
    onObjectSelected: undefined,
    onPaste: undefined,
    onPostProcess: undefined,
    onPostRender: undefined,
    onPreProcess: undefined,
    onProgressState: undefined,
    onRedo: undefined,
    onRemove: undefined,
    onReset: undefined,
    onSaveContent: undefined,
    onSelectionChange: undefined,
    onSetAttrib: undefined,
    onSetContent: undefined,
    onShow: undefined,
    onSubmit: undefined,
    onActivate: undefined,
    onUndo: undefined,
    onVisual: undefined,
    getToolbar: null,
    getToolbarContext: null,
    uploadExtraParams: null,
};

export default ReactTinymce;
