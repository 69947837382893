import React, { FC, useCallback, useState } from 'react';
import { useRequest, useMount } from 'ahooks';
import { Empty, Spin, message } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
import Header from './components/header';
import { Container, Item } from './components/Container';
import Chart from './components/Chart';
import TitleFooter from './components/title-footer';
import styled from 'styled-components';
import { CUSTOM_KANBAN_WRAPPER_ID } from './const';
import { getIntelligentPartnerAiAnalysisQueryFormFindAll } from 'src/api/intelligentPartner/ai/analysis/query/form/findAll';
import { getIntelligentPartnerAiAnalysisByIdQueryForm } from 'src/api/intelligentPartner/ai/analysis/{id}/query/form';
import { getIntelligentPartnerAiAnalysisByIdQueryFormExport } from 'src/api/intelligentPartner/ai/analysis/{id}/query/form/export';
import { getSystemModule } from 'Udesk/system/subApp';
import { IntelligentPartnerAiQueryReportFormFoundResponse, AiTextToSqlResult } from 'src/api/types';
import './index.less';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    padding: 16px;
`;

const EmptyWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

type TemplateProps = {};

const Template: FC<TemplateProps> = React.memo((props) => {
    const [statements, setStatements] = useState<
        IntelligentPartnerAiQueryReportFormFoundResponse[]
    >([]);

    const [activeKey, setActiveKey] = useState<any>();

    const getStatements = useCallback(
        ({ isReset }: { isReset: boolean } = { isReset: false }) => {
            getIntelligentPartnerAiAnalysisQueryFormFindAll().then((res) => {
                setStatements(res.data || []);
                if (isReset || !activeKey) {
                    setActiveKey(res.data?.[0]?.id ? res.data?.[0]?.id + '' : undefined);
                }
            });
        },
        [activeKey]
    );

    useMount(() => {
        getStatements({ isReset: true });
    });

    const onTabChange = (k) => {
        setActiveKey(k);
        setChartList([]);
    };

    const [chartList, setChartList] = useState<AiTextToSqlResult[]>([]);
    const [chartInfo, setChartInfo] = useState<IntelligentPartnerAiQueryReportFormFoundResponse>();
    const [canModify, setCanModify] = useState<boolean>(false);

    const { run: getChart, loading: getChartLoading } = useRequest(
        () => getIntelligentPartnerAiAnalysisByIdQueryForm({ segments: { id: activeKey } }),
        {
            ready: !!activeKey,
            refreshDeps: [activeKey],
            onSuccess: (res) => {
                setChartList(res.data?.queryHistoryList || []);
                setChartInfo(res.data);
                setCanModify(res.data?.isSelf === 1);
            },
        }
    );

    const [exportLoading, setExportLoading] = useState(false);

    const onExport = () => {
        setExportLoading(true);
        getIntelligentPartnerAiAnalysisByIdQueryFormExport({
            segments: { id: activeKey as any },
            params: {
                systemModule: getSystemModule(),
            },
        })
            .then(() => {
                message.success(/* 导出成功，请前往任务中心查看 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.index.exportSuccessfulPleaseGoToTheTaskCenterToCheck);
            })
            .finally(() => {
                setExportLoading(false);
            });
    };

    return (
        <Page
            pageClassName="custom-kanban-page"
            pageBodyClassName="custom-kanban-page-body"
            backGroundGhost={true}
            loading={false}
            loadingTip={/* 数据查询中... */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.index.dataQueryInProgress}
            title={/* 自定义看板 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.index.customKanban}
            footer={
                <TitleFooter
                    statements={statements}
                    activeKey={activeKey}
                    onChange={onTabChange}
                    getStatements={getStatements}
                />
            }
        >
            <Wrapper id={CUSTOM_KANBAN_WRAPPER_ID}>
                <Spin spinning={getChartLoading || exportLoading}>
                    {statements.length ? (
                        <Header
                            statements={statements}
                            activeKey={activeKey}
                            getStatements={getStatements}
                            getChart={getChart}
                            chartInfo={chartInfo}
                            onExport={onExport}
                            canModify={canModify}
                        />
                    ) : null}

                    <Container>
                        {chartList.length ? (
                            chartList.map((c) => {
                                return (
                                    <Item widthRatio={1 / 2}>
                                        <Chart
                                            {...c}
                                            activeKey={activeKey}
                                            getChart={getChart}
                                            canModify={canModify}
                                        />
                                    </Item>
                                );
                            })
                        ) : (
                            <EmptyWrapper>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </EmptyWrapper>
                        )}
                    </Container>
                </Spin>
            </Wrapper>
        </Page>
    );
});

export default Template;
