import Udesk from 'Udesk';
import SdkLoaderBase from '../../../common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';
class QualityAnalysisComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: 'data-analysis-full-amount-work',
        pageTitle: UdeskLocales.current.components.pages.dataAnalysis.fullAmountWork,
    };
}

export default Udesk.react.udeskify(QualityAnalysisComponent);
