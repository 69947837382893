// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostWeComDataCallSearchBody,
    BaseResponseFilterListDataWrapperDto,
} from '../../types';

/**
 * findByPage
 *
 * @export
 * @tags 企微语音
 * @link [POST] /weComDataCall/search
 */
export function postWeComDataCallSearch(
    data: PostWeComDataCallSearchBody
): Promise<BaseResponseFilterListDataWrapperDto>;
export function postWeComDataCallSearch(
    data: PostWeComDataCallSearchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseFilterListDataWrapperDto>;
export function postWeComDataCallSearch(
    data: PostWeComDataCallSearchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseFilterListDataWrapperDto> {
    return request<PostWeComDataCallSearchBody, never, never>('/weComDataCall/search', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postWeComDataCallSearch',
    });
}

export const meta = [{ tags: ['企微语音'], path: '/weComDataCall/search', method: 'post' }];
