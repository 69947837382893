import React, { useCallback, useMemo } from 'react';
import UdeskList from 'Component/common/udesk-list';
import {
    InputNumber,
    Form,
    Input,
} from 'udesk-ui';
import { isDefined } from 'src/util/core';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const { form, submit, change } = props;
      
    return (
        <Form 
            form={form}
            onFinish={submit}
            onValuesChange={change}
            initialValues={{ 'maxSilencePeriod': 3, 'timeoutHintWords': null }}
        >
            <Form.Item label={/* 学员节点超时未作答设置 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.interactionRuleForm.studentNodeTimeoutWithoutAnsweringSetting} labelCol={{span: 24}} wrapperCol={{span: 23, offset: 1}}>
                <Form.Item name="maxSilencePeriod" label={/* 学员未作答允许的最大静默时间（s） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.interactionRuleForm.maximumAllowedSilenceTimeForStudentsNotAnswerings}>
                    <InputNumber min={1} max={1000} />
                </Form.Item>
                <Form.Item name="timeoutHintWords" label={/* 超时未作答提示话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.interactionRuleForm.promptScriptForFailureToAnswerOverTime}>
                    <TimeoutHintWords />
                </Form.Item>
            </Form.Item>
            {/* <Form.Item label="容错机制设置" labelCol={{span: 24}} wrapperCol={{span: 23, offset: 1}}>
                <Form.Item name="faultTolerantNumber" label="容错次数">
                    <InputNumber min={1} max={15} />
                </Form.Item>
                <Form.Item label="作答容错话术">
                    <span>结合评分要点命中情况进行提示，无需进行设置</span>
                </Form.Item>
            </Form.Item> */}
        </Form>
    );
});

export const TimeoutHintWords = React.memo((props: any) => {
    const { 
        value: timeoutHintWords, 
        onChange 
    } = props;

    const value = useMemo(() => timeoutHintWords || [], [timeoutHintWords]);

    const deleteItem = useCallback((index) => {
        let newValue = [...value];
        if (index > -1) {
            newValue.splice(index, 1);
        }
        onChange?.(newValue);
    }, [onChange, value]);

    const addItem = useCallback(() => {
        onChange?.([
            ...value,
            '',
        ]);
    }, [onChange, value]);

    const onChangeHandle = useCallback((event, index) => {
        const item = value[index];
        if (isDefined(item)) {
            value[index] = event.target.value;
        }
        onChange?.([...value]);
    }, [onChange, value]);

    return (
        <UdeskList
            deleteItem={deleteItem}
            addItem={addItem}
            addBtnText={/* 添加话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.interactionRuleForm.addScript}
        >
            {value.map((item, index) => {
                return (
                    <Input 
                        value={item}                        
                        onChange={(newItem) => {
                            onChangeHandle(newItem, index);
                        }}/>
                );
            })}
        </UdeskList>
    );
});
