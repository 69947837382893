import React from 'react';
import { Space, Avatar } from 'udesk-ui';
import Locales from 'UdeskLocales';

const locales = Locales['current'];

const UseMessage = (props) => {
    const { realname, headPortraitUrl, company } = props.biUser;
    return (
        <Space size={10}>
            {
                headPortraitUrl ? <Avatar size={48} src={headPortraitUrl} /> : 
                    <Avatar size={48} src={'/static/images/avatar.png'} />
            }
            <Space size={2} direction="vertical">
                <span style={{fontSize: 18}}>{locales.pages.gong.homePage.common.useMessage.hi}{realname}</span>
                <span style={{fontSize: 12}}>{company.name}</span>
            </Space>
        </Space>
    );
};

export default UseMessage;