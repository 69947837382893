// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetInspectionRemarkModelConfigsQuery,
    BaseResponseListInspectionRemarkModelConfigFoundResponse,
    PostInspectionRemarkModelConfigsBody,
    BaseResponseInspectionRemarkModelConfigFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags inspection-remark-model-config-controller
 * @link [GET] /inspectionRemarkModelConfigs
 */
export function getInspectionRemarkModelConfigs(): Promise<BaseResponseListInspectionRemarkModelConfigFoundResponse>;
export function getInspectionRemarkModelConfigs(
    options: ApiOptions<never, GetInspectionRemarkModelConfigsQuery>
): Promise<BaseResponseListInspectionRemarkModelConfigFoundResponse>;
export function getInspectionRemarkModelConfigs(
    options?: ApiOptions<never, GetInspectionRemarkModelConfigsQuery>
): Promise<BaseResponseListInspectionRemarkModelConfigFoundResponse> {
    return request<never, never, GetInspectionRemarkModelConfigsQuery>(
        '/inspectionRemarkModelConfigs',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionRemarkModelConfigs' }
    );
}

/**
 * save
 *
 * @export
 * @tags inspection-remark-model-config-controller
 * @link [POST] /inspectionRemarkModelConfigs
 */
export function postInspectionRemarkModelConfigs(
    data: PostInspectionRemarkModelConfigsBody
): Promise<BaseResponseInspectionRemarkModelConfigFoundResponse>;
export function postInspectionRemarkModelConfigs(
    data: PostInspectionRemarkModelConfigsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseInspectionRemarkModelConfigFoundResponse>;
export function postInspectionRemarkModelConfigs(
    data: PostInspectionRemarkModelConfigsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseInspectionRemarkModelConfigFoundResponse> {
    return request<PostInspectionRemarkModelConfigsBody, never, never>(
        '/inspectionRemarkModelConfigs',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postInspectionRemarkModelConfigs',
        }
    );
}

export const meta = [
    {
        tags: ['inspection-remark-model-config-controller'],
        path: '/inspectionRemarkModelConfigs',
        method: 'get',
    },
    {
        tags: ['inspection-remark-model-config-controller'],
        path: '/inspectionRemarkModelConfigs',
        method: 'post',
    },
];
