import React, { FC, memo, useMemo } from 'react';
import { Icon } from 'udesk-ui';

const InspectDataSourceMap = {
    /* 语音通话 */
    Call: 1,
    /* 语音通话 */
    Call_2: 9,
    /* 企微文本 */
    Wecom_chat: 14,
    /* 企微通话 */
    Wecom_call: 13,
};

const SessionTypeMap = {
    /* 企微文本 - 单聊 */
    Single: 1,
    /* 企微文本 - 群聊 */
    Group: 2,
};

type TemplateProps = {
    inspectDataSource?: number;
    sessionType?: number;
    [key: string]: unknown;
};

const Template: FC<TemplateProps> = (props) => {
    const { inspectDataSource, sessionType, ...restPorps } = props;

    const iconType = useMemo(() => {
        switch (inspectDataSource) {
            case InspectDataSourceMap.Call:
                return 'tonghuaguanli';

            case InspectDataSourceMap.Call_2:
                return 'tonghuaguanli';

            case InspectDataSourceMap.Wecom_chat:
                switch (sessionType) {
                    case SessionTypeMap.Single:
                        return 'xingzhuang';

                    case SessionTypeMap.Group:
                        return 'ic-customer';

                    default:
                        return 'xingzhuang';
                }

            case InspectDataSourceMap.Wecom_call:
                return 'a-maikefengshengyinluyinluzhishengyin1';

            default:
                return '';
        }
    }, [inspectDataSource, sessionType]);

    if (!iconType) {
        return null;
    }

    return (
        <Icon
            type={iconType}
            style={{ fontSize: '30px', color: 'rgba(0, 0, 0, 0.1)' }}
            {...restPorps}
        />
    );
};

export default memo(Template);
