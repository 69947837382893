import routing from './routing';
import widgets, { initWidgets, destroy as destroyWidgets }  from './widgets';
import alerts from './alerts';

export default {
    widgets,
    routing,
    alerts,
    setLocale
};


export function initUI() {
    initWidgets.call(this.widgets);
}

function setLocale(locale) {
    this.widgets.setLocale(locale);
}

export function destroy() {
    destroyWidgets.call(this.widgets);
}