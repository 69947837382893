import React from 'react';
import Udesk from 'Udesk';
import './style.scss';
import InputList from 'udesk-react/src/components/input-list';
import InformationEntitiesDetailRoute from './route';
import ReactSelect from 'udesk-react/src/components/react-select';
import { PageHeader } from 'udesk-ui';


export default class InformationEntitiesDetailTemplate extends InformationEntitiesDetailRoute {
    render() {
        let {
            activeFlags,
        } = this.privates;
        let {
            actions,
            locales,
            privates
        } = this;

        return (
            // <div className="information-entities-detail-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.fix.informationEntity}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root information-entities-detail-page'>
                        <If condition={privates.asyncModelFulfilled}>
                            <If condition={!privates.asyncModelResolved}>
                                {privates.asyncModelErrorMsg}
                            </If>
                        </If>
                        <If condition={!privates.asyncModelFulfilled}>
                            Loading...
                        </If>
                        <If condition={privates.asyncModelFulfilled && privates.asyncModelResolved}>
                            <div className="information-entities-detail-page-body">
                                <div className="form-group row">
                                    <label htmlFor="entity_name" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.labels.name}
                                        <span className="required"></span>
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Choose>
                                            <When condition={privates.asyncModel.type === Udesk.enums.informationEntityTypes.system.id}>
                                                <input disabled type="text" id="entity_name" className="form-control" defaultValue={privates.asyncModel.name} onChange={actions.mutator('privates.asyncModel.name', e => e.target.value)}  />
                                            </When>
                                            <Otherwise>
                                                <input disabled type="text" id="entity_name" className="form-control" defaultValue={privates.asyncModel.name} onChange={actions.mutator('privates.asyncModel.name', e => e.target.value)} />
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="api_name" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.apiName}
                                        <span className="required"></span>
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Choose>
                                            <When condition={privates.asyncModel.type === Udesk.enums.informationEntityTypes.system.id}>
                                                <input disabled type="text" id="api_name" className="form-control" defaultValue={privates.asyncModel.apiName} onChange={actions.mutator('privates.asyncModel.apiName', e => e.target.value)}  />
                                            </When>
                                            <Otherwise>
                                                <input disabled type="text" id="api_name" className="form-control" defaultValue={privates.asyncModel.apiName} onChange={actions.mutator('privates.asyncModel.apiName', e => e.target.value)} />
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="active_flag" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.activeFlag}
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <ReactSelect disabled classNames="udesk-qc-react-select" value={privates.asyncModel.activeFlag} dataScource={activeFlags} hasNullSelect={false} onChanged={actions.changeActiveFlag} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="remark" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.remark}
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Choose>
                                            <When condition={privates.asyncModel.type === Udesk.enums.informationEntityTypes.system.id}>
                                                <textarea disabled id="remark" className="form-control form-control-textarea" cols="30" rows="5" defaultValue={privates.asyncModel.remark} onChange={actions.mutator('privates.asyncModel.remark', e => e.target.value)} ></textarea>
                                            </When>
                                            <Otherwise>
                                                <textarea disabled id="remark" className="form-control form-control-textarea" cols="30" rows="5" defaultValue={privates.asyncModel.remark} onChange={actions.mutator('privates.asyncModel.remark', e => e.target.value)}></textarea>
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="logicExp" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.logicExp}
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <Choose>
                                            <When condition={privates.asyncModel.type === Udesk.enums.informationEntityTypes.system.id}>
                                                <input disabled type="text" id="logicExp" className="form-control" defaultValue={privates.asyncModel.logicExp} onChange={actions.mutator('privates.asyncModel.logicExp', e => e.target.value)}  />
                                            </When>
                                            <Otherwise>
                                                <input disabled type="text" id="logicExp" className="form-control" defaultValue={privates.asyncModel.logicExp} onChange={actions.mutator('privates.asyncModel.logicExp', e => e.target.value)} />
                                            </Otherwise>
                                        </Choose>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="sentenceList" className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1">
                                        {locales.components.pages.informationEntities.detail.sentenceList}
                                    </label>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                        <InputList list={privates.asyncModel.sentenceList} listItemTextFieldName="content" inputListClassNames="information-entities-detail-input-list" addIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707" deleteIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao" inputTextChanged={actions.sentenceValueChange} addListValue={actions.addSentence} deleteListValue={actions.deleteSentence} emptyErrorTip={actions.sentenceEmptyErrorTip} maxListValueLength={255} />
                                        <span className="information-entities-detail-tip">
                                            {locales.components.pages.informationEntities.detail.tip}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}

