// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetIntelligentPartnerMistakeCollectionsQuery,
    BaseResponseListIntelligentPartnerMistakeCollectionFoundResponse,
    PostIntelligentPartnerMistakeCollectionsBody,
    BaseResponseIntelligentPartnerMistakeCollectionFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags intelligent-partner-mistake-collection-controller
 * @link [GET] /intelligentPartnerMistakeCollections
 */
export function getIntelligentPartnerMistakeCollections(): Promise<BaseResponseListIntelligentPartnerMistakeCollectionFoundResponse>;
export function getIntelligentPartnerMistakeCollections(
    options: ApiOptions<never, GetIntelligentPartnerMistakeCollectionsQuery>
): Promise<BaseResponseListIntelligentPartnerMistakeCollectionFoundResponse>;
export function getIntelligentPartnerMistakeCollections(
    options?: ApiOptions<never, GetIntelligentPartnerMistakeCollectionsQuery>
): Promise<BaseResponseListIntelligentPartnerMistakeCollectionFoundResponse> {
    return request<never, never, GetIntelligentPartnerMistakeCollectionsQuery>(
        '/intelligentPartnerMistakeCollections',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerMistakeCollections',
        }
    );
}

/**
 * save
 *
 * @export
 * @tags intelligent-partner-mistake-collection-controller
 * @link [POST] /intelligentPartnerMistakeCollections
 */
export function postIntelligentPartnerMistakeCollections(
    data: PostIntelligentPartnerMistakeCollectionsBody
): Promise<BaseResponseIntelligentPartnerMistakeCollectionFoundResponse>;
export function postIntelligentPartnerMistakeCollections(
    data: PostIntelligentPartnerMistakeCollectionsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerMistakeCollectionFoundResponse>;
export function postIntelligentPartnerMistakeCollections(
    data: PostIntelligentPartnerMistakeCollectionsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerMistakeCollectionFoundResponse> {
    return request<PostIntelligentPartnerMistakeCollectionsBody, never, never>(
        '/intelligentPartnerMistakeCollections',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerMistakeCollections',
        }
    );
}

export const meta = [
    {
        tags: ['intelligent-partner-mistake-collection-controller'],
        path: '/intelligentPartnerMistakeCollections',
        method: 'get',
    },
    {
        tags: ['intelligent-partner-mistake-collection-controller'],
        path: '/intelligentPartnerMistakeCollections',
        method: 'post',
    },
];
