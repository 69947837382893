import Udesk from 'udesk-react/src/udesk';
import common from '../../common/index';
import React from 'react';
// import { inputTypes, fieldOperators } from '../enums/fields';

export default {
    validateString,
    validateInputTypes,
    validateOperators,
    validateObject,
    validateFunction,
    validateFunctionNullable,
    validateReactElement,
    validateReactElementNullable
};

function validateString(value) {
    if (typeof value !== "string") {
        return "value must be string";
    }
    return true;
}

function validateInputTypes(value) {
    return validateEnum(value, common.enums.inputTypes, "inputTypes");
}

function validateOperators(value) {
    return validateEnumArray(value, common.enums.fieldOperators, "fieldOperators");
}

function validateObject(value) {
    if (value != null && typeof value !== "object") {
        return "value must be POJO";
    }
    return true;
}

function validateFunction(value) {
    if (typeof value !== "function") {
        return "value must be function";
    }
    return true;
}

function validateFunctionNullable(value) {
    if (value != null && typeof value !== "function") {
        return "value must be function";
    }
    return true;
}

function validateEnum(value, enumType, enumTypeName) {
    if (!(value instanceof Udesk.enums.EnumItemClass) &&
        enumType[value] == null && enumType.get(value) == null) {
        return `value (${value}) must be enum type (${enumTypeName})`;
    }
    return true;
}

function validateEnumArray(value, enumType, enumTypeName) {
    if (!(value instanceof Array)) {
        return "value must be array";
    }
    for (var item of value) {
        var result = validateEnum(item, enumType, enumTypeName);
        if (result !== true) {
            return result;
        }
    }
    return true;
}

function validateReactElement(value) {
    if (React.isValidElement(value)) {
        return "value must be React element";
    }
    return true;
}

function validateReactElementNullable(value) {
    if (value != null && React.isValidElement(value)) {
        return "value must be React element";
    }
    return true;
}