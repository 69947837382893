import React from "react";
import BusinessAnalysisFilterComponent from "./component";
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Select, Input, Button, Tooltip, Form } from "udesk-ui";
import _isEmpty from "lodash-es/isEmpty";
import _compact from 'lodash-es/compact';
import _size from 'lodash-es/size';
import _find from 'lodash-es/find';
import BusinessAnalysisFilter from '../business-analysis-fiter';
import ClassNames from 'classnames';
export default class BusinessAnalysisFilterTemplate extends BusinessAnalysisFilterComponent {
    render() {
        let { actions, locales } = this;
        let { searchTemplate, driverFlag } = this.props;
        let {
            saleBusinessList,
            saleFieldsList,
            businessList,
            fieldsList,
            data,
            findAllSamrtTagsItems
        } = this.privates;
        // let { getFieldDecorator } = this.props.form;
        let { Option } = Select;
        return (
            <div>
                {!_isEmpty(data) &&
                    _compact(data).map((itemss, index) => {
                        return (
                            <div key={itemss.num} className={ClassNames('custome-filter-friver-next-box', { 'custome-filter-friver-next-box-flag': (_size(data) === 1 && !driverFlag) || (index === _size(data) - 1) })}>

                                <div className={ClassNames('custome-filter-dirver-first-box', { 'custome-filter-friver-flag-box': driverFlag })} >
                                    <div
                                        className={"custome-filter-driver-one-box"}
                                    >

                                        <div className="custome-filter-driver-one">
                                            <div className="custome-filter-driver-circle-add-one">
                                                <div
                                                    className="customer-filter-driver-circle-add"
                                                    onClick={actions.addTwoCustome.params(itemss)}
                                                >
                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i>
                                                </div>
                                            </div>
                                            <Form.Item>
                                                {/* {getFieldDecorator(`${itemss.num}-first`, {
                                                    initialValue: itemss.type || undefined,
                                                })( */}
                                                <Select
                                                    onChange={actions.dataSourceChanged.params(
                                                        itemss
                                                    )}
                                                    value={itemss.type || undefined}
                                                    placeholder={
                                                        locales.labels.pleaseChoice
                                                    }
                                                >
                                                    {
                                                        this.props.route?.name === 'analysisBusinessAnalysisDetail' || this.props.route?.name === 'analysisBusinessAnalysisCreate' ? (!_isEmpty(saleBusinessList) &&
                                                            saleBusinessList.map(items => {
                                                                return (
                                                                    <Option key={items.type} value={items.type} disabled={items.type === '0' && this.props.dataSourceType === 0}>
                                                                        {items.text}
                                                                    </Option>
                                                                );
                                                            })) : (!_isEmpty(businessList) &&
                                                                businessList.map(items => {
                                                                    return (
                                                                        <Option key={items.type} value={items.type} disabled={items.type === '0' && this.props.dataSourceType === 0}>
                                                                            {items.text}
                                                                        </Option>
                                                                    );
                                                                }))
                                                    }
                                                </Select>
                                                {/* )} */}
                                            </Form.Item>
                                            {
                                                this.props.route?.name === 'analysisBusinessAnalysisDetail' || this.props.route?.name === 'analysisBusinessAnalysisCreate' ? (_find(
                                                    saleFieldsList,
                                                    current => current.id === itemss.type
                                                ) &&
                                                    !_isEmpty(
                                                        _find(
                                                            saleFieldsList,
                                                            current => current.id === itemss.type
                                                        ).list
                                                    ) && (
                                                    <Form.Item>
                                                        {/* {getFieldDecorator(`${itemss.num}-second`, {
                                                            initialValue: itemss.operator || undefined,
                                                        })( */}
                                                        <Select
                                                            onChange={actions.dataSourceFromChanged.params(
                                                                itemss,
                                                            )}
                                                            value={itemss.operator || undefined}
                                                            placeholder={
                                                                locales.labels.pleaseChoice
                                                            }
                                                        >
                                                            {_find(
                                                                saleFieldsList,
                                                                current => current.id === itemss.type
                                                            ).list.map(items => {
                                                                return (
                                                                    <Option value={items.type}>
                                                                        {items.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {/* )} */}
                                                    </Form.Item>
                                                )) : (_find(
                                                    fieldsList,
                                                    current => current.id === itemss.type
                                                ) &&
                                                    !_isEmpty(
                                                        _find(
                                                            fieldsList,
                                                            current => current.id === itemss.type
                                                        ).list
                                                    ) && (
                                                    <Form.Item>
                                                        {/* {getFieldDecorator(`${itemss.num}-second`, {
                                                            initialValue: itemss.operator || undefined,
                                                        })( */}
                                                        <Select
                                                            onChange={actions.dataSourceFromChanged.params(
                                                                itemss,
                                                            )}
                                                            value={itemss.operator || undefined}
                                                            placeholder={
                                                                locales.labels.pleaseChoice
                                                            }
                                                        >
                                                            {_find(
                                                                fieldsList,
                                                                current => current.id === itemss.type
                                                            ).list.map(items => {
                                                                return (
                                                                    <Option value={items.type}>
                                                                        {items.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {/* )} */}
                                                    </Form.Item>
                                                ))
                                            }
                                            {itemss.type === "1" && (
                                                <Form.Item>
                                                    {/* {getFieldDecorator(`${itemss.num}-three`, {
                                                        initialValue: itemss.value || undefined, */}
                                                    {/* })( */}
                                                    <Input style={{ width: "320px" }} value={itemss.value} onChange={actions.dataSourceFromThreeChanged.params(itemss, 'input')} />
                                                    {/* )} */}
                                                </Form.Item>
                                            )}
                                            {itemss.type === "2" && (
                                                <Form.Item>
                                                    {/* {getFieldDecorator(`${itemss.num}-four`, {
                                                        initialValue: itemss.value || undefined,
                                                    })( */}
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={/* 请选择智能标签 */locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.pleaseSelectSmartTag}
                                                        maxTagCount={2}
                                                        style={{ width: "320px" }}
                                                        value={itemss.value || undefined}
                                                        // labelInValue={true}
                                                        filterOption={false}
                                                        onSearch={actions.onSearchSelect}
                                                        onChange={actions.dataSourceFromThreeChanged.params(itemss, 'select')}
                                                    >
                                                        <For each="items" index="index" of={findAllSamrtTagsItems}>
                                                            <Option key={items.id} value={`${items.id}`}>
                                                                {items.tagName}
                                                            </Option>
                                                        </For>
                                                    </Select>
                                                    {/* )} */}
                                                </Form.Item>
                                            )}
                                            {itemss.type === "3" && (
                                                <Form.Item>
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={/* 请选择会话标签 */locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.pleaseSelectASessionLabel}
                                                        maxTagCount={2}
                                                        style={{ width: "320px" }}
                                                        value={itemss.value || undefined}
                                                        filterOption={false}
                                                        onSearch={actions.onSearchSelect}
                                                        onChange={actions.dataSourceFromThreeChanged.params(itemss, 'select')}
                                                    >
                                                        <For each="items" index="index" of={findAllSamrtTagsItems}>
                                                            <Option key={items.id} value={`${items.id}`}>
                                                                {items.tagName}
                                                            </Option>
                                                        </For>
                                                    </Select>
                                                </Form.Item>
                                            )}
                                            {itemss.type === "4" && (
                                                <Form.Item>
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={/* 请选择关键事件 */locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.pleaseSelectAKeyEvent}
                                                        maxTagCount={2}
                                                        style={{ width: "320px" }}
                                                        value={itemss.value || undefined}
                                                        filterOption={false}
                                                        onSearch={actions.onSearchSelect}
                                                        onChange={actions.dataSourceFromThreeChanged.params(itemss, 'select')}
                                                    >
                                                        <For each="items" index="index" of={findAllSamrtTagsItems}>
                                                            <Option key={items.id} value={`${items.id}`}>
                                                                {items.tagName}
                                                            </Option>
                                                        </For>
                                                    </Select>
                                                </Form.Item>
                                            )}
                                            {itemss.type === "0" && (
                                                <Form.Item>
                                                    {/* {getFieldDecorator(`${itemss.num}-five`, {
                                                        initialValue: itemss.value || undefined,
                                                    })( */}
                                                    <Select
                                                        placeholder={/* 请选择高级条件 */locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.pleaseSelectAdvancedCondition}
                                                        style={{ width: "120px" }}
                                                        value={itemss.value || undefined}
                                                        onChange={actions.dataSourceFromfiveChanged.params(itemss)}
                                                    >
                                                        {!_isEmpty(searchTemplate) &&
                                                            searchTemplate.map(items => {
                                                                return (
                                                                    <Option value={items.id} >
                                                                        {items.name}
                                                                    </Option>
                                                                );
                                                            })}

                                                    </Select>
                                                    {/* )} */}
                                                </Form.Item>

                                            )}
                                            {
                                                itemss.type === "0" && (
                                                    <Button onClick={actions.newAddFilter} style={{ marginRight: '10px' }}>{/* 新建高级筛选 */}{locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.newAdvancedFilter}</Button>
                                                )
                                            }
                                            <Form.Item>
                                                {/* {getFieldDecorator(`${itemss.num}-six`, {
                                                    initialValue: itemss.color || undefined
                                                })( */}
                                                <Input
                                                    type="color"
                                                    defaultValue={itemss.color || undefined}
                                                    style={{ width: "100px" }}
                                                    onChange={actions.InputColorItem.params(itemss.num)}
                                                />
                                                {/* )} */}
                                            </Form.Item>
                                            <Tooltip placement="bottom" title={(<div className="custome-filter-tootip-button">
                                                <p onClick={actions.displacement.params(itemss.num, 'top')}>{/* 上移 */}{locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.moveUp}</p>
                                                <p onClick={actions.displacement.params(itemss.num, 'bottom')}>{/* 下移 */}{locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.moveDown}</p>
                                                <p onClick={actions.displacement.params(itemss.num, 'left')}>{/* 左移 */}{locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.moveLeft}</p>
                                                <p onClick={actions.displacement.params(itemss.num, 'right')}>{/* 右移 */}{locales.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.template.shiftRight}</p>
                                            </div>)} overlayClassName='custome-filter-tootip'>
                                                <div
                                                    className="customer-filter-driver-circle-del"
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tuozhuai1"></i>
                                                </div>
                                            </Tooltip>
                                            <div
                                                className="customer-filter-driver-circle-del"
                                                onClick={actions.delTwoCustome.params(itemss.num)}
                                            >
                                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                {
                                    !_isEmpty(itemss.children) && (
                                        <BusinessAnalysisFilter {...this.props} data={itemss.children} findAllSamrtTagsItems={findAllSamrtTagsItems} searchTemplate={searchTemplate} key={itemss.num} num={this.props.num}
                                            fieldDelList={this.props.fieldDelList}
                                            fieldAddList={this.props.fieldAddList}
                                            dataSourceChanged={this.props.dataSourceChanged}
                                            dataSourceFromChanged={this.props.dataSourceFromChanged}
                                            dataSourceFromThreeChanged={this.props.dataSourceFromThreeChanged}
                                            dataSourceFromfiveChanged={this.props.dataSourceFromfiveChanged}
                                            newAddFilterFileds={this.props.newAddFilterFileds}
                                            InputColorItemChange={this.props.InputColorItemChange}
                                            displacementChange={this.props.displacementChange}
                                            dataSourceType={this.props.dataSourceType}
                                        />
                                    )
                                }
                            </div>
                        );
                    })
                }

            </div>
        );
    }

}
