// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetRolesByIdParam,
    BaseResponseRoleFoundResponse,
    PutRolesByIdParam,
    PutRolesByIdBody,
    BaseResponseVoid,
    DeleteRolesByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 角色
 * @link [GET] /roles/{id}
 * @param id
 */
export function getRolesById(
    options: ApiOptions<GetRolesByIdParam, never> & { segments: GetRolesByIdParam }
): Promise<BaseResponseRoleFoundResponse> {
    return request<never, GetRolesByIdParam, never>('/roles/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getRolesById',
    });
}

/**
 * update
 *
 * @export
 * @tags 角色
 * @link [PUT] /roles/{id}
 * @param id
 */
export function putRolesById(
    data: PutRolesByIdBody,
    options: ApiOptions<PutRolesByIdParam, never> & { segments: PutRolesByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutRolesByIdBody, PutRolesByIdParam, never>('/roles/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putRolesById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 角色
 * @link [DELETE] /roles/{id}
 * @param id
 */
export function deleteRolesById(
    options: ApiOptions<DeleteRolesByIdParam, never> & { segments: DeleteRolesByIdParam }
): Promise<BaseResponseVoid> {
    return request<never, DeleteRolesByIdParam, never>('/roles/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteRolesById',
    });
}

export const meta = [
    { tags: ['角色'], path: '/roles/{id}', method: 'get' },
    { tags: ['角色'], path: '/roles/{id}', method: 'put' },
    { tags: ['角色'], path: '/roles/{id}', method: 'delete' },
];
