import Udesk from 'Udesk';
import InformationEntitiesDetailComponent from './component';

class InformationEntitiesDetailRoute extends InformationEntitiesDetailComponent {
    model() {
        let {
            match,
            sdkOptions
        } = this.props;

        if (match.params.id) {
            let url = Udesk.business.apiPath.concatApiPath(`information-entity/${match.params.id}`, sdkOptions);
            return new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        if (!resp.data.sentenceList) resp.data.sentenceList = [];
                        this.privates.initialValues = resp.data;
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        } else {
            return new Promise((resolve, reject) => {
                this.privates.initialValues = {
                    sentenceList: [],
                    activeFlag: Udesk.enums.activeFlags.effiective.id
                };
                resolve(this.privates.initialValues);
            });
        }
    }
}

export default InformationEntitiesDetailRoute;
