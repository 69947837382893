import React, { useState, useEffect } from 'react';
import { Button, FormBuilder, Row, Col, Space } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import Page from 'Component/common/udesk-qa-page-layout';
import SemanticTagsSelect from 'Component/pages/components/semantic-tags-select';
import TypeSelect, { TemplatePropsValue as TypeSelectValue } from '../components/TypeSelect';
import PromptArea from '../components/PromptArea';
import styled from 'styled-components';
import { StatusEnum } from '../const';
import { postAiModels } from 'src/api/aiModels';
import { getAiModelsById, putAiModelsById } from 'src/api/aiModels/{id}';
import { AiModelCreateRequest, AiModelUpdateRequest } from 'src/api/types';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Training from '../components/Training';

const Wrapper = styled.div`
    height: 100%;
    overflow: auto;
`;

interface FormValues extends Omit<AiModelCreateRequest, 'id' | 'type' | 'divideRuleList'> {
    typeSelect?: TypeSelectValue;
}

const Template = React.memo((props: any) => {
    const id = props.match.params.id;

    const [btnLoading, setBtnLoading] = useState(false);

    const [initialValues, setInitialValues] = useState<FormValues>({
        typeSelect: {
            type: 1,
        },
        status: 1,
    });

    const [baseForm] = FormBuilder.useForm();

    useEffect(() => {
        if (id) {
            setBtnLoading(true);
            getAiModelsById({
                segments: {
                    id,
                },
            })
                .then((resp) => {
                    const data = {
                        ...(resp.data || {}),
                        typeSelect: {
                            type: resp.data?.type,
                            divideRuleList: resp.data?.divideRuleList,
                        },
                    };

                    setInitialValues((initialValues) => ({
                        ...initialValues,
                        ...data,
                    }));
                })
                .finally(() => {
                    setBtnLoading(false);
                });
        }
    }, [id]);

    useEffect(() => {
        baseForm.resetFields();
    }, [baseForm, initialValues]);

    const onCancel = () => {
        let routeOptions: any = {
            history: props.history,
            routeName: 'intelligentModel',
            state: {},
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const onFinish = async (values: FormValues) => {
        const params: AiModelUpdateRequest = {
            name: values.name,
            modelDesc: values.modelDesc || values.name,
            status: values.status,
            type: values.typeSelect?.type,
            divideRuleList: values.typeSelect?.divideRuleList,
            prompt: values.prompt,
        };

        setBtnLoading(true);

        try {
            if (id) {
                await putAiModelsById(params, {
                    segments: {
                        id,
                    },
                    successMsg: UdeskLocales['current'].business.info.saveSuccess,
                });
            } else {
                await postAiModels(params, {
                    successMsg: UdeskLocales['current'].business.info.creatSuccess,
                });
            }
            onCancel();
        } catch (e) {
            console.log(e);
        } finally {
            setBtnLoading(false);
        }
    };

    const config: FormProps = {
        customComponents: { SemanticTagsSelect, TypeSelect, PromptArea },
        initialValues,
        onFinish: onFinish,
        fields: [
            {
                name: 'name',
                label: /* 模型名称 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.detail.index.modelName,
                type: 'Input',
                rules: [
                    {
                        required: true,
                    },
                ],
                props: {
                    placeholder:
                        /* 请输入模型名称，例如：客服是否存在无作为的情况 */ UdeskLocales['current']
                            .pages.semanticIntelligence.intelligentModel.detail.index
                            .pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive,
                },
            },
            {
                name: 'modelDesc',
                label: /* 模型描述 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.detail.index.modelDescription,
                type: 'TextArea',
                props: {
                    autoSize: true,
                    placeholder:
                        /* 可详细描述您需要检测的内容，便于模型理解，如：客服是否存在无作为的情况，即针对客户的提问客服有回复，但客户问题通篇没有得到处理或回复内容与客人回复无关 */ UdeskLocales[
                            'current'
                        ].pages.semanticIntelligence.intelligentModel.detail.index
                            .canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse,
                },
                rules: [],
            },
            {
                name: 'status',
                label: /* 有效性 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.detail.index.validity,
                type: 'Select',
                props: {
                    options: [
                        {
                            label: /* 有效 */ UdeskLocales['current'].pages.semanticIntelligence
                                .intelligentModel.detail.index.effective,
                            value: StatusEnum.efficient,
                        },
                        {
                            label: /* 无效 */ UdeskLocales['current'].pages.semanticIntelligence
                                .intelligentModel.detail.index.invalid,
                            value: StatusEnum.invalid,
                        },
                    ],
                    style: {
                        width: 200,
                    },
                    placeholder: UdeskLocales['current'].labels.pleaseSelect,
                },
            },
            {
                name: 'typeSelect',
                label: /* 类型 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.detail.index.type,
                type: 'TypeSelect',
                props: {},
            },
            {
                name: 'prompt',
                label: /* 提示语 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.detail.index.hintLanguage,
                type: 'PromptArea',
                props: {
                    getParams: () => {
                        const { name, modelDesc, typeSelect } = baseForm.getFieldsValue() || {};
                        return { modelDesc: modelDesc || name, typeSelect };
                    },
                    setParams: (params) => {
                        baseForm.setFieldsValue(params);
                    },
                },
                rules: [],
            },
        ],
        footerRender: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 13 },
    };

    return (
        <Page
            pageBodyClassName="wechat-staff-center-page-index"
            title={
                props.match.params.id
                    ? /* 编辑智能模型 */ UdeskLocales['current'].pages.semanticIntelligence
                          .intelligentModel.detail.index.editIntelligentModel
                    : /* 新建智能模型 */ UdeskLocales['current'].pages.semanticIntelligence
                          .intelligentModel.detail.index.createANewIntelligentModel
            }
            padding={true}
            onBack={onCancel}
        >
            <Wrapper>
                <FormBuilder {...config} form={baseForm} />

                <Row>
                    <Col offset={4}>
                        <Training
                            getValues={() => {
                                return baseForm.getFieldsValue() || {};
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col offset={4}>
                        <Space>
                            <Button loading={btnLoading && { delay: 200 }} onClick={onCancel}>
                                {UdeskLocales['current'].labels.cancel}
                            </Button>
                            <Button
                                loading={btnLoading && { delay: 200 }}
                                type="primary"
                                onClick={() => baseForm.submit()}
                            >
                                {UdeskLocales['current'].labels.save}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Wrapper>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
