// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostIntelligentPartnerWordsManualCorrectBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 语音转写手动标注纠错
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [POST] /intelligentPartner/words/manual/correct
 */
export function postIntelligentPartnerWordsManualCorrect(
    data: PostIntelligentPartnerWordsManualCorrectBody
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerWordsManualCorrect(
    data: PostIntelligentPartnerWordsManualCorrectBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerWordsManualCorrect(
    data: PostIntelligentPartnerWordsManualCorrectBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentPartnerWordsManualCorrectBody, never, never>(
        '/intelligentPartner/words/manual/correct',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerWordsManualCorrect',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/words/manual/correct',
        method: 'post',
    },
];
