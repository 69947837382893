import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import ChatRecord from 'Component/common/chat-record';
import useResizeObserver from 'use-resize-observer';
import { Button, Drawer, Space } from 'udesk-ui';
import { useLiveEffect } from 'src/util/hook';
import { beautifulTime, getDateTimeString } from 'src/util/moment';
import { getId, inspect, isDefined } from 'src/util/core';
import UdeskLocales from 'UdeskLocales';

export default React.memo<any>(props => {
    const {visible, setVisible, selectedRow, request, onCheckFormSubmit} = props;
    const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();
    const [dataSource, setDateSource] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [checkedList, setCheckoutList] = useState<any[]>([]);
    const chatRecordRef = useRef<any>(null);
    const audioRef = useRef<HTMLAudioElement>(null);

    const onSubmit = useCallback(() => {
        onCheckFormSubmit(checkedList.map(id => dataSource.find(item => item.id === id)));
    }, [
        onCheckFormSubmit, 
        checkedList, 
        dataSource
    ]);
    
    useLiveEffect((success) => {
        if (selectedRow?.callId) {
            setLoading(true);
            request(`/review/clusterResult/call/${selectedRow.callId}`, {dataSource: selectedRow.dataSource}).then(({data}) => {
                success(() => {
                    setDateSource(data.map(item => {
                        return createMessage({
                            type: item.voiceUrl ? 'call' : 'message',
                            avatar: item.avatar,
                            sender: item.channel_id === 1 ? 'customer' : 'agent',
                            message: item.text,
                            voice_url: item.voiceUrl,
                            created_at: beautifulTime(item.begin_time / 1000),
                            audio: audioRef?.current
                        });
                    }));
                });
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [selectedRow?.callId]);

    const current = useMemo(() => {
        if (selectedRow?.answer && dataSource.length) {
            return dataSource?.findIndex(item => {
                const content = selectedRow?.answer.replace(/^'(.*)'$/, '$1');
                const message = JSON.parse(item.content).data.content;
                return message.indexOf(content) > -1;
            });
        }
        return 0;
    }, [dataSource, selectedRow?.answer]);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                if (current > 0) {
                    const div:any = jQuery('.rc-virtual-list-holder-inner>.item');
                    const top = div?.eq(current - 1).offset().top;
                    chatRecordRef.current?.scrollTo(top);
                }
            }, 500);
        } else {
            setShowCheckbox(false);
            chatRecordRef.current?.scrollTo(0);
        }
    }, [current, visible]);

    useEffect(() => { setCheckoutList([]); }, [showCheckbox]);

    return (
        <Drawer
            title={/* 会话详情 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.callDetailModal.sessionDetails}
            width={725}
            onClose={() => setVisible(false)}
            visible={visible}
            footerStyle={{textAlign: 'end'}}
            footer={
                inspect(
                    visible && isDefined(onCheckFormSubmit) && !selectedRow.accept, 
                    () => inspect(
                        showCheckbox , 
                        () => (
                            <Space>
                                <Button onClick={() => setShowCheckbox(false)}>{/* 取消 */}{UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.callDetailModal.cancel}</Button>
                                <Button type="primary" onClick={onSubmit}>{/* 确定 */}{UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.callDetailModal.determine}</Button>
                            </Space>
                        ), 
                        () => (
                            <Button type="primary" onClick={() => setShowCheckbox(true)}>{/* 选择 */}{UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.callDetailModal.choice}</Button>
                        )
                    )
                )
            }
        >
            <div ref={ref} className='chat-panel' style={{height: dataSource.length > 0 ? '100%' : 'auto'}} onMouseEnter={() => chatRecordRef.current?.scrollTo?.(null)}>
                <audio className='hidden' ref={audioRef} controls autoPlay={true} />
                <ChatRecord {...{
                    ref: chatRecordRef,
                    loading,
                    showTopMore: false,
                    height,
                    showCheckbox,
                    defaultScrollToTop: true,
                    onMessageItemCheckboxClick: setCheckoutList
                }} data={dataSource}/>
            </div>
        </Drawer>
    );
});

const createMessage = (props = {} as any) => {
    const {
        id = getId().toString(), 
        created_at = getDateTimeString(),
        type = 'call', 
        sender = 'agent', 
        message = '', 
        voice_url = null,
        audio = undefined as any,
        avatar,
    } = props;

    const chatData: any = {
        id,
        created_at,
        sender,
        describes: null,
        send_status: "",
        isPlaying: -1,
        canRemarkEdit: false,
        fieldFlag: false,
        avatar,
        playRudio () {},
    };

    const content: any = {
        data: {
            content: message,
        },
        type: 'rich',
    };

    if (type === 'call') {
        content.data.voice_url = voice_url;
        chatData.playRudio = url => {
            audio?.setAttribute('src', url);
            audio?.play();
        };
    }

    chatData.content = JSON.stringify(content);

    return chatData;
};
