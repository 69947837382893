import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';
import { CommonPage } from 'udesk_gm_ui';

class intelligentTags extends React.Component<any, any> {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { routes, route, location, sdkOptions } = this.props;
        return (
            <CommonPage.PageInfoMemo>
                {(() => {
                    if (this.isRedirect(route.path, location.pathname)) {
                        let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                            routeName: route.name,
                        });
                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else {
                        return (
                            <div className='udesk-qa-supervision' style={{ height: '100%' }}>
                                <div className='case-base-pages' style={{ height: '100%' }}>
                                    {routes.map((routeItem, i) => (
                                        <SubRoutes key={i} route={routeItem} />
                                    ))}
                                </div>
                            </div>
                        );
                    }
                })()}
            </CommonPage.PageInfoMemo>
        );
    }
}

export default Udesk.react.udeskify(intelligentTags);
