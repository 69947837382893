import React from 'react';
// import Udesk from 'Udesk';
import _includes from 'lodash-es/includes';

class RelatedRecordsListComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        list: [],
        pointCategoryList: []
    };
    //#endregion

    state = {};
    privates = {
        pointList: [],
        loading: false
    };
    static computes = {};
    actions = {
        loadMore(paging) {
            let { parentLoadMore, relatedDatas } = this.props;
            let data = {
                pageNum: paging.pageNum + 1,
                dateTime: relatedDatas[relatedDatas.length - 1].callTime,
                lastCallId: relatedDatas[relatedDatas.length - 1].callId
            };
            this.privates.loading = true;
            this.actions.update();
            parentLoadMore && parentLoadMore(data);
        },
        relatedRecordsFieldSee(value, status) {
            if (_includes([1, 3], status)) {
                window.open(`/site/call-manage/view/${value}?newTab=true`);
            } else if (_includes([2, 4], status)) {
                window.open(`/site/dialogue-manage/view/${value}?newTab=true`);
            }
        },
        inspectionTaskOpen(value, id) {
            window.open(`/site/tasks/manage/${value}/workbench/quality-inspection-list/detail/${id}`);
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {
            loading: props.loading
        };
    }
    componentDidMount() { }
    componentWillUnmount() { }
    asyncModel(changedParams) { }
    //#endregion
}

export default RelatedRecordsListComponent;
