// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostIntelligentPartnerTaskInfosByTaskIdStatisticsParam,
    PostIntelligentPartnerTaskInfosByTaskIdStatisticsBody,
    BaseResponseIntelligentPartnerTaskStatisticsFoundResponse,
} from '../../../types';

/**
 * 获取单个发布统计数据
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/{taskId}/statistics
 * @param taskId
 */
export function postIntelligentPartnerTaskInfosByTaskIdStatistics(
    data: PostIntelligentPartnerTaskInfosByTaskIdStatisticsBody,
    options: ApiOptions<PostIntelligentPartnerTaskInfosByTaskIdStatisticsParam, never> & {
        segments: PostIntelligentPartnerTaskInfosByTaskIdStatisticsParam;
    }
): Promise<BaseResponseIntelligentPartnerTaskStatisticsFoundResponse> {
    return request<
        PostIntelligentPartnerTaskInfosByTaskIdStatisticsBody,
        PostIntelligentPartnerTaskInfosByTaskIdStatisticsParam,
        never
    >('/intelligentPartnerTaskInfos/{taskId}/statistics', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTaskInfosByTaskIdStatistics',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/{taskId}/statistics',
        method: 'post',
    },
];
