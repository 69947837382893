import UdeskLocales from 'UdeskLocales';

export const useConfig = () => {
    const seriesItemStyle = {
        // 折线拐点标志的样式。
        normal: {
            // 普通状态时的样式
            lineStyle: {
                width: 1,
            },
            opacity: 0.2,
        },
        emphasis: {
            // 高亮时的样式
            lineStyle: {
                width: 5,
            },
            opacity: 1,
        },
    };

    const legend = [
        {
            name: /* 本次 */ UdeskLocales['current'].pages.coach.learningCenter.record.detail
                .components.comprehensiveEvaluation.const.thisTime,
            areaStyleColor: 'rgba(0, 119, 255, 0.6)',
            color: 'rgba(0, 119, 255, 1)',
        },
        {
            name: /* 上次 */ UdeskLocales['current'].pages.coach.learningCenter.record.detail
                .components.comprehensiveEvaluation.const.lastTime,
            areaStyleColor: 'rgba(1, 154, 98, 0.6)',
            color: 'rgba(1, 154, 98, 1)',
        },
    ];

    const radarOptionLegend = {
        // 图例组件
        show: true,
        icon: 'rect',
        // 图例项的 icon。ECharts 提供的标记类型包括 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'也可以通过 'image://url' 设置为图片，其中 url 为图片的链接，或者 dataURI。可以通过 'path://' 将图标设置为任意的矢量路径。
        top: '0',
        // 图例距离顶部边距
        right: '8px',
        // 图例距离左侧边距
        orient: 'horizontal',
        // 图例列表的布局朝向,'horizontal'为横向,''为纵向.
        data: legend,
    };

    const radarOptionTitle = {
        text: /* 综合评价 */ UdeskLocales['current'].pages.coach.learningCenter.record.detail
            .components.comprehensiveEvaluation.const.comprehensiveEvaluation,
        target: 'blank',
        textStyle: {
            color: 'rgba(0, 0, 0, .85)',
            fontSize: 14,
        },
    };

    return {
        seriesItemStyle,
        legend,
        radarOptionLegend,
        radarOptionTitle,
    };
};
