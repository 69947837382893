// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetOperatorLogsFindStudyAnalysisQuery,
    BaseResponseListOperatorLog,
} from '../../types';

/**
 * findByCondition
 *
 * @export
 * @tags operator-log-controller
 * @link [GET] /operatorLogs/findStudyAnalysis
 */
export function getOperatorLogsFindStudyAnalysis(): Promise<BaseResponseListOperatorLog>;
export function getOperatorLogsFindStudyAnalysis(
    options: ApiOptions<never, GetOperatorLogsFindStudyAnalysisQuery>
): Promise<BaseResponseListOperatorLog>;
export function getOperatorLogsFindStudyAnalysis(
    options?: ApiOptions<never, GetOperatorLogsFindStudyAnalysisQuery>
): Promise<BaseResponseListOperatorLog> {
    return request<never, never, GetOperatorLogsFindStudyAnalysisQuery>(
        '/operatorLogs/findStudyAnalysis',
        { ...options, method: 'get', __$requestCalleeName: 'getOperatorLogsFindStudyAnalysis' }
    );
}

export const meta = [
    { tags: ['operator-log-controller'], path: '/operatorLogs/findStudyAnalysis', method: 'get' },
];
