// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerScoringModelsByIdParam,
    BaseResponseIntelligentPartnerScoringModelFoundResponse,
    PutIntelligentPartnerScoringModelsByIdParam,
    PutIntelligentPartnerScoringModelsByIdBody,
    BaseResponseVoid,
    DeleteIntelligentPartnerScoringModelsByIdParam,
} from '../../../../types';

/**
 * 评分模型详情
 *
 * @export
 * @tags 智能陪练-评分模型
 * @link [GET] /intelligentPartner/scoring/models/{id}
 * @param id
 */
export function getIntelligentPartnerScoringModelsById(
    options: ApiOptions<GetIntelligentPartnerScoringModelsByIdParam, never> & {
        segments: GetIntelligentPartnerScoringModelsByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerScoringModelFoundResponse> {
    return request<never, GetIntelligentPartnerScoringModelsByIdParam, never>(
        '/intelligentPartner/scoring/models/{id}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerScoringModelsById',
        }
    );
}

/**
 * 更新评分模型
 *
 * @export
 * @tags 智能陪练-评分模型
 * @link [PUT] /intelligentPartner/scoring/models/{id}
 * @param id
 */
export function putIntelligentPartnerScoringModelsById(
    data: PutIntelligentPartnerScoringModelsByIdBody,
    options: ApiOptions<PutIntelligentPartnerScoringModelsByIdParam, never> & {
        segments: PutIntelligentPartnerScoringModelsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentPartnerScoringModelsByIdBody,
        PutIntelligentPartnerScoringModelsByIdParam,
        never
    >('/intelligentPartner/scoring/models/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerScoringModelsById',
    });
}

/**
 * 删除评分模型
 *
 * @export
 * @tags 智能陪练-评分模型
 * @link [DELETE] /intelligentPartner/scoring/models/{id}
 * @param id
 */
export function deleteIntelligentPartnerScoringModelsById(
    options: ApiOptions<DeleteIntelligentPartnerScoringModelsByIdParam, never> & {
        segments: DeleteIntelligentPartnerScoringModelsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentPartnerScoringModelsByIdParam, never>(
        '/intelligentPartner/scoring/models/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteIntelligentPartnerScoringModelsById',
        }
    );
}

export const meta = [
    { tags: ['智能陪练-评分模型'], path: '/intelligentPartner/scoring/models/{id}', method: 'get' },
    { tags: ['智能陪练-评分模型'], path: '/intelligentPartner/scoring/models/{id}', method: 'put' },
    {
        tags: ['智能陪练-评分模型'],
        path: '/intelligentPartner/scoring/models/{id}',
        method: 'delete',
    },
];
