import importSuccessNotification from 'Component/common/import-notification';
import React from 'react';
import Udesk from 'Udesk';
import { enableSale } from 'Udesk/business';
import { isAlgorithm, isSalesSubApp } from 'Udesk/system/subApp';
import { getSystemModule } from 'Udesk/system/subApp';

class InformationEntitiesComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {

    };
    //#endregion

    state = {
        columns: [],
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: Udesk.config.paging.defaultPageSize,
        total: null,
    };
    privates = {
        keyword: null,
        isUploadVisible: false,
        file: null,
        uploadData: null,
        loading: false,
        selectedItems: [],
        dataSource: [],
        moduleType: null,
    };

    actions = {
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        searchItems(e) {
            this.actions.reloadAsyncModel({
                pageNum: Udesk.config.paging.defaultPageNumber,
                pageSize: Udesk.config.paging.defaultPageSize,
            });
        },
        moduleTypeChange(value) {
            this.privates.moduleType = value;
            this.actions.update();
        },
        createItem() {
            let routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm() ? 'algorithmInformationEntitiesNew' : isSalesSubApp() ? 'salesInformationEntitiesNew' : "informationEntitiesNew",
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        editItem(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm() ? 'algorithmInformationEntitiesEdit' : isSalesSubApp() ? 'salesInformationEntitiesEdit' : "informationEntitiesEdit",
                pathParams: {
                    id: item.id
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        deleteItem(item) {
            let {
                locales
            } = this;
            let onConfirm = () => {
                let {
                    sdkOptions
                } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(`information-entity/${item.id}`, sdkOptions);
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg || locales.business.notifyMessage.deleteFailed);
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: locales.labels.deleteContent,
                onOk: onConfirm
            });
        },
        changeUploadVisible() {
            let {
                isUploadVisible
            } = this.privates;
            this.privates.isUploadVisible = !isUploadVisible;
            this.actions.update();
        },
        uploadFile(e) {
            let {
                locales
            } = this;
            let files = e.target.files;

            if (files.length === 0) {
                return;
            }

            // 取消文件大小限制
            // if (files[0] && (files[0].size > 2 * 1024 * 1024)) {
            //     return Udesk.ui.notify.error(UDL.business.notifyMessage.overFileSize);
            // }
            let fileName = fileType(files[0].name);
            if (!fileName) {
                return;
            } else if (fileName !== 'xlsx') {
                return Udesk.ui.notify.error(Udesk.utils.string.format(locales.labels.fileTypeHolder, 'xlsx'));
            }
            //type 1语音,2导入,3导出,4语料数据
            Udesk.app.fileStorage.upload(files[0], {
                token: new Promise((resolve, reject) => {
                    Udesk.business.getServerAuthorization({
                        type: 2,
                    }).then(function (resp) {
                        resolve(resp);
                    }, function (reason) {
                        reject(reason);
                    });
                }),
                //progressCallback: progressCallback.bind(this),
                successCallback: this.actions.uploadFileSuccess.bind(this),
                errorCallback: this.actions.uploadFileError.bind(this),
            });
        },
        downloadTemplate() {
            let {
                sdkOptions
            } = this.props;

            let templateLocalUrl = `/import/information_entities_template.xlsx`;
            let templateUrl = Udesk.business.apiPath.getStaticBasePath(sdkOptions.props.domain, sdkOptions.props.pathPrefix) + templateLocalUrl;

            window.location = templateUrl;
        },
        uploadFileSuccess(file, uploadData) {
            this.privates.file = file;
            this.privates.uploadData = uploadData;
            this.actions.update();
        },
        uploadFileError(file, uploadData) {

        },
        importInformationEntities() {
            let {
                sdkOptions
            } = this.props;
            let {
                file,
                uploadData
            } = this.privates;
            if (file == null || uploadData == null) {
                return Udesk.ui.notify.error(this.locales.business.notifyMessage.pleaseUploadFile);
            }
            let params = {
                keyName: uploadData.key,
                fileName: file.name,
                systemModule: getSystemModule(),
                // systemType: Udesk.data.init.company.systemType,
            };
            let url = Udesk.business.apiPath.concatApiPath(`information-entity/import`, sdkOptions);
            Udesk.ajax.post(url, params)
                .then(
                    (resp) => {
                        Udesk.ui.notify.success(this.locales.business.notifyMessage.importFileSuccess);
                        this.actions.changeUploadVisible();
                        importSuccessNotification();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg || this.locales.business.notifyMessage.importFildFailed);
                        this.actions.changeUploadVisible();
                    }
                );
        },
        export() {
            let {
                sdkOptions,
            } = this.props;
            let getListUrl = Udesk.business.apiPath.concatApiPath(`/information-entity/export`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(getListUrl, { type: 1, systemModule: getSystemModule(), ids: this.privates.selectedItems.map(i => i.id) }).then(
                    resp => {
                        resolve(resp);
                        Udesk.ui.notify.success(resp.message);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onCheckChange(record, selected, selectedRows, nativeEvent) {
            let newSelectedItems = [...this.privates.selectedItems];
            if(selected){
                newSelectedItems.push(record);
            }else{
                newSelectedItems = newSelectedItems.filter(item => item.id !== record.id);
            }
            this.privates.selectedItems = newSelectedItems;
            this.actions.update();
        },
        onSelectAll(selected, selectedRows, changeRows) {
            let newSelectedItems = [...this.privates.selectedItems];
            if(selected){
                newSelectedItems = [...newSelectedItems, ...changeRows];
            }else{
                newSelectedItems = newSelectedItems.filter(item => !changeRows.find(r => r.id === item.id));
            }
            this.privates.selectedItems = newSelectedItems;
            this.actions.update();
        },
        batchDelete() {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `information-entity/delete`,
                sdkOptions
            );
            let params = {
                ids: this.privates.selectedItems.map(i => i.id),
            };
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    resp => {
                        this.actions.reloadAsyncModel();
                        let failedCount = resp.data.failed;
                        let successCount = resp.data.success;
                        this.privates.selectedItems = [];
                        resolve({
                            failedCount,
                            successCount
                        });
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );    
            });
        },
    };

    //#region Life Cycle
    componentDidMount() {
        getColumns(this);
    }
    componentWillUnmount() {

    }
    //#endregion
}
function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
function getColumns(that) {
    let {
        actions,
        locales
    } = that;
    let columns = [
        {
            dataIndex: "name",
            width: "15%",
            title: locales.fields.informationEntities.name
        },
        {
            dataIndex: "apiName",
            width: "15%",
            title: locales.fields.informationEntities.apiName,
        },
        {
            dataIndex: "type",
            width: "15%",
            title: locales.fields.informationEntities.type,
            render: (name, item, index) => {
                return Udesk.enums.informationEntityTypes.getName(item.type);
            }
        },
        {
            dataIndex: "remark",
            width: "15%",
            title: locales.fields.informationEntities.remark,
            ellipsis: true,
        },
        {
            title: locales.fields.semanticTags.orgName,
            dataIndex: "type1",
            width: "10%",
            render: (name, record) => {
                return record.type === 3 ? Udesk.enums.organizationTypes.getName(record.type) : /* 无 */locales.pages.semanticIntelligence.informationEntities.component.nothing;
            }
        },
        {
            dataIndex: "activeFlag",
            width: "15%",
            title: locales.fields.informationEntities.activeFlag,
            render: (name, item, index) => {
                return Udesk.enums.activeFlags.getName(item.activeFlag);
            }
        },
        {
            dataIndex: "actions",
            width: "15%",
            title: locales.fields.informationEntities.actions,
            render: (name, item, index) => {
                return (
                    <div className="qa-react-page-body-content-table-actions">
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:imformation:edit")}>
                            <If condition={(item.moduleId && item.editEnable && item.type === 3) || !item.moduleId || item.type !== 3}>
                                <span className="action-btn" style={{ cursor: 'pointer' }} title={locales.labels.edit} onClick={actions.editItem.params(item)}>
                                    {locales.labels.edit}
                                </span>
                            </If>
                            <If condition={item.moduleId && item.showEnable && !item.editEnable && item.type === 3}>
                                <span className="action-btn" title={locales.labels.view} onClick={actions.editItem.params(item)}>
                                    {locales.labels.view}
                                </span>
                            </If>
                        </If>
                        <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:imformation:delete")}>
                            <If condition={item.type !== Udesk.enums.informationEntityTypes.system.id}>
                                <span className="action-btn" style={{ color: "red", marginLeft: 5, cursor: 'pointer' }} title={locales.labels.delete} onClick={actions.deleteItem.params(item)}>
                                    {locales.labels.delete}
                                </span>
                            </If>
                        </If>
                    </div>
                );
            }
        }
    ];
    if (enableSale()) {
        let hideIndex = columns.findIndex(item => item.dataIndex === "type1");
        columns[hideIndex - 1].width = "25%";
        columns.splice(hideIndex, 1);
    }
    that.setState({
        columns
    });
}

export default InformationEntitiesComponent;