import React from 'react';
import Udesk from 'Udesk';
import SdkLoader from 'udesk-react/src/components/sdk-loader';
import SdkLoaderBase from '../../../../common/sdk-loader-base';

function extend(object, attrs) {
    Reflect.ownKeys(attrs).forEach((key) => {
        Reflect.set(object, key, attrs[key]);
    });
    return object;
}

class AnalysisComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: 'analysis-enable-group-test',
    };

    constructor(props) {
        super(props);

        const {selectedRowKey} = this.props;
        const getRenderToOptions = this.actions.getRenderToOptions;

        extend(this.actions, {
            getRenderToOptions: () => {
                const options = getRenderToOptions.call(this);
                // 这里重新定义sdk传入的方法
                extend(options.methods, {
                    initQaRequest: () => {
                        const {sdkOptions} = this.props;
                        return (url, params, method= 'get') => {
                            const httpUrl = Udesk.business.apiPath.concatApiPath(
                                url,
                                sdkOptions,
                                sdkOptions.props.domain
                            );
                            return Udesk.ajax[method](httpUrl, params);
                        };
                    },
                    setInit: (initHandle) => {
                        extend(this.actions, {
                            initHandle
                        });
                        if (selectedRowKey) {
                            initHandle(selectedRowKey);
                        }
                    },
                });
                return options;
            }
        });
    }
    componentDidUpdate(prevProps) {
        const {selectedRowKey} = this.props;
        if (selectedRowKey !== prevProps.selectedRowKey) {
            if (this.actions.initHandle) {
                this.actions.initHandle(selectedRowKey);
            }
        }
    }
    render() {
        let { privates, actions} = this;

        return (
            <div className='data-report-page' style={{height:"100%"}} id={privates.renderContainerId}>
                <SdkLoader
                    releaseVersionUrl={privates.releaseVersionUrl}
                    ref={privates.qaRef}
                    jsUrl={privates.externalAppUrl}
                    cssUrl={privates.externalAppCssUrl}
                    loadErrorMessage={privates.fileLoadFailedMessage}
                    onLoaded={actions.onLoaded}
                    onDestroying={actions.onDestroying}
                    onCreated={actions.onCreatedInstance}
                    onDestroyed={actions.onDestroyInstance}
                    whenDetail={actions.showBack}
                />
            </div>
        );
    }
}

export default Udesk.react.udeskify(AnalysisComponent);
