import React from 'react';
import Udesk from 'Udesk';
import { Button, Popconfirm, Tooltip } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
const PAGING = {
    pageSize: 20,
    total: 0,
    pageNum: 1,
};
const CommentStatus = {
    0: {
        label: /* 未开始 */ UdeskLocales['current'].pages.admin.customDataSource.youtube.component
            .notStarted,
        color: '',
    },
    1: {
        label: /* 进行中 */ UdeskLocales['current'].pages.admin.customDataSource.youtube.component
            .inProgress,
        color: 'origin',
    },
    2: {
        label: /* 成功 */ UdeskLocales['current'].pages.admin.customDataSource.youtube.component
            .success,
        color: 'green',
    },
    '-1': {
        label: /* 失败 */ UdeskLocales['current'].pages.admin.customDataSource.youtube.component
            .failed,
        color: 'red',
    },
    10: {
        label: /* 排队中 */UdeskLocales['current'].pages.admin.customDataSource.youtube.component.queuing,
        color: '',
    },
};
class TemplateComponentsListComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        paging: {
            ...PAGING,
        },
        targetItems: [],
        tableData: [],
        releaseStatus: Udesk.enums.templateShowable.false.id,
        needUpdate: Udesk.enums.templateNeedUpdate.all.id,
        disabled: false,
        order: 'updateTime',
        moduleSourceType: null,
        moduleType: null,
        moduleTypesList: [],
        // new
        sourceUrl: '',
        modalVisiable: false,
        dataType: '1', // 本品：1 竞品：2
        activeItem: null,
        autoPull: false, // 自动拉去
    };

    actions = {
        // 分页
        pageNumChange(pageNum) {
            this.privates.paging.pageNum = pageNum;
            this.actions.reloadAsyncModel();
        },
        pageSizeChange(pageNum, pageSize) {
            this.privates.paging.pageNum = pageNum;
            this.privates.paging.pageSize = pageSize;
        },
        // 本品/竞品 切换
        sourceTypeChange(e) {
            console.log(e);
            this.privates.dataType = e;
            // this.privates.rloadFlag = false;
            // this.actions.reloadList(e);
            this.actions.reloadAsyncModel();
        },
        // 创建/编辑 按钮
        createBtn(item) {
            console.log(item.id);
            // 这种格式的 URL 才可以
            // https://www.amazon.com/-/zh/dp/B0CQYQWYTL/ref=cm_cr_arp_d_bdcrb_top?ie=UTF8&th=1
            // https://www.youtube.com/watch?v=rSuFLIId7z8
            if (item.id) {
                this.privates.activeItem = item;
                this.privates.sourceUrl = item.sourceUrl;
            } else {
                this.privates.sourceUrl = '';
            }
            this.privates.modalVisiable = true;
            this.actions.update();
        },
        // 链接输入框
        inputChange(e) {
            this.privates.sourceUrl = e.target.value;
            this.actions.update();
        },
        // 保存按钮
        createLink() {
            console.log('createLink', this.privates.sourceUrl);
            let { sdkOptions } = this.props;
            // let { locales } = this;
            let taskUrl = Udesk.business.apiPath.concatApiPath(`/voc/comment`, sdkOptions);
            let params = {
                autoPull: 1,
                dataType: this.privates.dataType,
                sourceType: 2, // 1： 亚马逊  2： youtube
                sourceUrl: this.privates.sourceUrl,
            };

            new Promise((resolve, reject) => {
                Udesk.ajax.post(taskUrl, params).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(
                            /* 创建成功 */ UdeskLocales['current'].pages.admin.customDataSource
                                .youtube.component.successfullyCreated
                        );
                        this.actions.reloadAsyncModel();
                        this.actions.closeBtn();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        // 更新
        editLink() {
            console.log('editLink', this.privates.sourceUrl);
            let { sdkOptions } = this.props;
            // let { locales } = this;
            let itemId = this.privates.activeItem.id;
            let taskUrl = Udesk.business.apiPath.concatApiPath(
                `/voc/comment/${itemId}`,
                sdkOptions
            );
            let params = {
                autoPull: 1,
                id: itemId,
                sourceUrl: this.privates.sourceUrl,
            };

            new Promise((resolve, reject) => {
                Udesk.ajax.put(taskUrl, params).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(
                            /* 编辑成功 */ UdeskLocales['current'].pages.admin.customDataSource
                                .youtube.component.editedSuccessfully
                        );
                        this.actions.reloadAsyncModel();
                        this.actions.closeBtn();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        // 取消按钮
        closeBtn() {
            console.log('cancel');
            this.privates.modalVisiable = false;
            this.privates.sourceUrl = '';
            this.privates.activeItem = null;
            this.actions.update();
        },
        // 抓取功能
        updateLink(record) {
            console.log('updateLink', record);
            let { sdkOptions } = this.props;
            // let { locales } = this;
            let itemId = record.id;
            let taskUrl = Udesk.business.apiPath.concatApiPath(
                `/voc/comment/pull/${itemId}`,
                sdkOptions
            );

            new Promise((resolve, reject) => {
                Udesk.ajax.post(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(
                            /* 操作成功 */ UdeskLocales['current'].pages.admin.customDataSource
                                .youtube.component.operationSucceeded
                        );
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },

        // 修改状态--编辑/删除
        deleteItem(record) {
            console.log('deleteItem', record);
            let { sdkOptions } = this.props;
            // let { locales } = this;
            let itemId = record.id;
            let taskUrl = Udesk.business.apiPath.concatApiPath(
                `/voc/comment/${itemId}`,
                sdkOptions
            );

            new Promise((resolve, reject) => {
                Udesk.ajax.del(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(
                            /* 删除成功 */ UdeskLocales['current'].pages.admin.customDataSource
                                .youtube.component.deletedSuccessfully
                        );
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        // 抓取状态开关
        switchChange(record, checked) {
            this.actions.getStatus(
                record,
                checked
                    ? Udesk.enums.templateModuleStatus.open.id
                    : Udesk.enums.templateModuleStatus.disabled.id
            );
        },
    };

    //#region Life Cycle
    componentDidMount() {
        getColumn(this);
        let moduleTypesList = Udesk.enums.templateTypeList.map((i) => i);
        moduleTypesList.unshift({ name: this.locales.labels.all, id: null });
        this.privates.moduleTypesList = moduleTypesList;
        this.actions.update();
    }
    componentWillUnmount() {}
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'taskData') {
            this.privates.tableData = asyncResult.data;
            this.privates.paging = asyncResult.paging;
            this.actions.update();
        }
    }

    //#endregion
}
function getColumn(that) {
    let hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    let { locales } = that;
    let column = [
        {
            title: /* YouTube视频链接 */ UdeskLocales['current'].pages.admin.customDataSource
                .youtube.component.youTubeVideoLink,
            dataIndex: 'sourceUrl',
            key: 'sourceUrl',
            width: '400px',
            render: (text, record, index) => {
                return (
                    <Tooltip title={text}>
                        <div className="column-ellipsis">{text}</div>
                    </Tooltip>
                );
            },
        },
        {
            title: /* 抓取状态 */ UdeskLocales['current'].pages.admin.customDataSource.youtube
                .component.grabState,
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => {
                return (
                    <span style={{ color: CommentStatus[text]['color'] }}>
                        {CommentStatus[text]['label'] || '-'}
                    </span>
                );
            },
        },
        {
            title: /* 更新时间 */ UdeskLocales['current'].pages.admin.customDataSource.youtube
                .component.updateTime,
            dataIndex: 'updateTime',
            key: 'updateTime',
        },
        {
            title: locales.pages.admin.templateComponent.list.actions,
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record, index) => {
                return (
                    <div>
                        <If condition={hasFeature('sys:module:list:edit')}>
                            <Button
                                style={{ paddingLeft: 0 }}
                                type="link"
                                onClick={that.actions.updateLink.bind(null, record)}
                            >
                                {
                                    /* 抓取 */ UdeskLocales['current'].pages.admin.customDataSource
                                        .youtube.component.grab
                                }
                            </Button>
                            <Button
                                style={{ paddingLeft: 0 }}
                                type="link"
                                onClick={that.actions.createBtn.bind(null, record)}
                            >
                                {
                                    /* 编辑 */ UdeskLocales['current'].pages.admin.customDataSource
                                        .youtube.component.edit
                                }
                            </Button>
                            <Popconfirm
                                title={locales.pages.admin.templateComponent.list.deleteWhen}
                                onConfirm={that.actions.deleteItem.bind(null, record)}
                                onText={locales.pages.admin.templateComponent.list.yes}
                                cancelText={locales.pages.admin.templateComponent.list.noo}
                            >
                                <Button type="link" style={{ color: 'red' }}>
                                    {locales.pages.admin.templateComponent.list.deleteOk}
                                </Button>
                            </Popconfirm>
                        </If>
                    </div>
                );
            },
        },
    ];
    that.privates.targetItems = column;
    that.actions.update();
}

export default TemplateComponentsListComponent;
