/*
 * @Author: Armito
 * @Description: 课程选择组件
 */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { TreeSelect } from 'udesk-ui';
import { getIntelligentPartnerTaskInfosCourseCategory } from 'src/api/intelligentPartnerTaskInfos/courseCategory';
import { getReviewIntelligentPartnerCategoryCenter } from 'src/api/review/intelligentPartner/category/center';
import {
    IntelligentPartnerCourseCategoryFoundResponse,
    IntelligentPartnerCourse,
} from 'src/api/types';
import { isNumber } from 'src/util/core';

type useTreeSelectProps = {
    categoryDisabled?: boolean;
    isAdmin?: boolean;
};

const useTreeSelect = (props?: useTreeSelectProps) => {
    const {
        categoryDisabled = false,
        /* 是否为管理员权限，查询数据有差异 */
        isAdmin = false,
    } = props || {};

    const [categories, setCategories] = useState<IntelligentPartnerCourseCategoryFoundResponse[]>(
        []
    );
    const [courses, setCourses] = useState<IntelligentPartnerCourse[]>([]);
    useRequest(
        isAdmin
            ? getIntelligentPartnerTaskInfosCourseCategory
            : getReviewIntelligentPartnerCategoryCenter,
        {
            onSuccess: (res) => {
                setCategories(res.data || []);
                setCourses(
                    (res.data || [])?.reduce((pre, cur) => {
                        return [...pre, ...(cur.courseList || [])];
                    }, [] as IntelligentPartnerCourse[])
                );
            },
        }
    );

    const { treeData, treeDefaultExpandedKeys } = useMemo(() => {
        const temp1 = categories.map((c) => {
            return {
                id: c.id,
                pId: c.parentId,
                value: c.id,
                title: c.name,
                key: c.id,
                data: c,
                disabled: categoryDisabled,
            };
        });

        const treeDefaultExpandedKeys = [temp1?.[0]?.id];

        const temp2 = courses.map((c) => {
            const mId = c.id + '/n';

            return {
                id: mId,
                pId: c.categoryId,
                value: mId,
                title: c.name,
                key: mId,
                data: c,
            };
        });

        return {
            treeData: [...temp1, ...temp2],
            treeDefaultExpandedKeys,
        };
    }, [categories, categoryDisabled, courses]);

    return {
        treeDataSimpleMode: true,
        treeData,
        treeDefaultExpandedKeys,
        allowClear: true,
        showArrow: true,
        filterTreeNode: true,
        showSearch: true,
        treeNodeFilterProp: 'title',
    };
};

type CourseTreeSelectProps = {
    value?: {
        categories?: number[];
        courses?: number[];
    };
    onChange?: (v?: CourseTreeSelectProps['value']) => void;
    [key: string]: unknown;
};

const CategoryCourseTreeSelect: FC<CourseTreeSelectProps> & {
    useTreeSelect: typeof useTreeSelect;
} = (props) => {
    const { value, onChange, ...restProps } = props;

    const [innerValue, setInnerValue] = useState<(number | string)[]>();

    useEffect(() => {
        if (value) {
            setInnerValue([
                ...(value?.categories || []),
                ...(value?.courses || []).map((c) => c + '/n'),
            ]);
        } else {
            setInnerValue(value);
        }
    }, [value, value?.categories, value?.courses]);

    const onInnerChange = (v: (number | string)[] | number | string | undefined) => {
        let arr: any = v;
        if (!Array.isArray(v) && v !== undefined) {
            arr = [v];
        }
        setInnerValue(arr);

        const temp = arr?.reduce(
            (pre, cur) => {
                if (isNumber(cur)) {
                    return {
                        ...pre,
                        categories: [...pre.categories, cur],
                    };
                } else {
                    return {
                        ...pre,
                        courses: [...pre.courses, parseInt(cur as string)],
                    };
                }
            },
            { categories: [], courses: [] } as any
        );
        onChange?.(temp);
    };

    return <TreeSelect value={innerValue} onChange={onInnerChange} {...restProps} />;
};

CategoryCourseTreeSelect.useTreeSelect = useTreeSelect;

export default CategoryCourseTreeSelect;
