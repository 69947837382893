import Udesk from 'Udesk';
import React from 'react';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';

class customerService extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    init() {
        // 被其他页面污染，手动置空
        this.props.sdkOptions.props.task = null;
    }

    render() {
        let { route, location, sdkOptions } = this.props;

        let { locales } = this;

        let navLists = [];
        // let grounp = {};
        // 根据当前用户权限，组装菜单列表
        //&& Udesk.data.init.user.hasFeature("smart:tag:root")
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('agent:info:root')) {
            navLists.push({
                text: locales.fields.customerInformation.title,
                pathName: 'customerServiceInformation',
                isActive: (match, location) => {
                    return location.pathname.includes('/customer-information');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('agent:ranklist:root')) {
            navLists.push({
                text: locales.components.pages.customerServiceRankList.title,
                pathName: 'customerServiceRankListIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/customer-ranklist');
                },
            });
        }

        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('agent:picture:root')) {
            navLists.push({
                text: locales.components.pages.customerServicePortrait.title,
                pathName: 'customerServicePortraitIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/customer-service-portrait');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('agent:indicator:root')) {
            navLists.push({
                text: locales.fields.IndexManagement.title,
                pathName: 'customerServiceIndexManagementIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/index-management');
                },
            });
        }

        if (
            sdkOptions.props.landingPath != null &&
            this.isRedirect(route.path, location.pathname)
        ) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="semantic-intelligence">
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={
                            locales.components.pages.menuTitleList.customerServiceCenterTitle
                        }
                    />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(customerService);
