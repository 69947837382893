import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import TasksAutomaticAddComponent from './component';
import { getAutoMateTasksCaseByTaskId } from 'src/api/autoMateTasks/case/{taskId}';
import { getInspectionRemarkModelConfigsById } from 'src/api/inspectionRemarkModelConfigs/{id}';

export default class TasksAutomaticAddRoute extends TasksAutomaticAddComponent {
    model() {
        const { props } = this;
        let { sdkOptions } = this.props;
        let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        let type = sdkOptions.props.task.inspectDataSource || Udesk.enums.inspectDataSources.voiceCall.id;
        
        if (sdkOptions.props.task && (sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id)) {
            type = Udesk.enums.inspectDataSources.voiceCall.id;
        } else if (sdkOptions.props.task && (sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id || sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id)) {
            type = Udesk.enums.inspectDataSources.textDialogue.id;
        } else if (sdkOptions.props.task && sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.wechatDialogue.id) {
            type = Udesk.enums.inspectDataSources.wechatDialogue.id;
        } else if (sdkOptions.props.task && sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.wechatRadio.id) {
            type = Udesk.enums.inspectDataSources.wechatRadio.id;
        }
        this.privates.type = type;
        //查询筛选条件  为了在质检评级修改之后在筛选条件中生效，在这单独查询一次
        let customFields = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/field/task/' + params.taskId, props.sdkOptions);
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(
                        resp.data.filter(item => {
                            return item.id !== 'r_1' && item.id !== 'r_2';
                        })
                    );
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.labels.field
                            )
                    );
                    reject(reason);
                }
            );
        });
        
        // 流程自动化自动添加使用新的字段查询接口，新接口中增加了预处理字段
        // let automaticCustomFields = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         '/field/task/' + params.taskId + '?type=call',
        //         props.sdkOptions
        //     );
        //     Udesk.ajax.get(url).then(
        //         resp => {
        //             resolve(resp.data);
        //         },
        //         reason => {
        //             Udesk.ui.notify.error(
        //                 reason.errorMsg ||
        //                     Udesk.utils.string.format(
        //                         UdeskLocales.current.business.info.loadError,
        //                         UdeskLocales.current.labels.field
        //                     )
        //             );
        //             reject(reason);
        //         }
        //     );
        // });
        let searchParams = {
            sourceList: [this.privates.type]
        };
        let automaticCustomFields = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/field/data/search-template',
                props.sdkOptions
            );
            Udesk.ajax.post(url, searchParams).then(
                resp => {
                    let res = [];
                    resp.data.forEach(item => {
                        item.typeList.forEach(typeItem => {
                            res = [...res, ...typeItem.fieldList];
                        });
                    });
                    resolve(res);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.labels.field
                            )
                    );
                    reject(reason);
                }
            );
        });


        // 1、请求所有开启关闭的状态
        let statusUrl = Udesk.business.apiPath.concatApiPath(`/autoMateTasks/${params.taskId}`, props.sdkOptions);
        let statusPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(statusUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        // 2、自动添加
        let automaticAddUrl = Udesk.business.apiPath.concatApiPath(
            `/autoMateTasks/autoLoad-plus/${params.taskId}`,
            props.sdkOptions
        );
        let automaticAddPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(automaticAddUrl).then(
                resp => {
                    let data = resp.data;
                    if(Array.isArray(resp.data)) {
                        data = resp.data[0]??{};
                    }
                    if (!data.baseFilter) {
                        data.baseFilter = {
                            conditionList: [],
                            judgeStrategy: Udesk.enums.judgeStrategy.all.id,
                            customJudgeLogic: ''
                        };
                    }
                    if (!data.autoMateLoadConf) {
                        data.autoMateLoadConf = {};
                    }
                    resolve(data);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.automaticAdd.index.name
                            )
                    );
                    reject(reason);
                }
            );
        });

        // 3、自动抽检
        let sampleUrl = Udesk.business.apiPath.concatApiPath(
            `/autoMateTasks/samples/${params.taskId}`,
            props.sdkOptions
        );
        let samplePromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(sampleUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        // 4、自动分配
        let assignUrl = Udesk.business.apiPath.concatApiPath(
            `/autoMateTasks/assigns/${params.taskId}`,
            props.sdkOptions
        );
        let assignPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(assignUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        // 5、自动推送
        let pushUrl = Udesk.business.apiPath.concatApiPath(
            `/autoMateTasks/monitor/${params.taskId}`,
            props.sdkOptions
        );
        let pushPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(pushUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        // 6、自动评估
        let assessUrl = Udesk.business.apiPath.concatApiPath(
            `/autoMateTasks/assessment/${params.taskId}`,
            props.sdkOptions
        );
        let assessPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(assessUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        // 7、申诉复检
        let checkUrl = Udesk.business.apiPath.concatApiPath(
            `/autoMateTasks/checks/${params.taskId}`,
            props.sdkOptions
        );
        let checkPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(checkUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        getInspectionRemarkModelConfigsById({
            segments: {
                id: params.taskId
            }
        }).then(resp => {
            if(resp.data){
                this.privates.remarkModel = resp.data.remarkModel??'';
                this.privates.remarkModelId = resp.data.id??null;
            }
        });

        //8、获取所有员工
        let usersUrl = Udesk.business.apiPath.concatApiPath(`/users`, props.sdkOptions);
        let usersParams = {
            pageSize: 1000
        };
        let usersPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(usersUrl, usersParams).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        //9 案例库同步
        let casePromise = new Promise((resolve, reject) => {
            getAutoMateTasksCaseByTaskId({
                segments: {
                    taskId: params.taskId,
                }
            }).then(
                resp => {
                    this.privates.caseConfigList = resp.data.map(item => ({...item.caseConf, _id: item.id}));
                    this.actions.update();
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        
        // let inspectionConditionsPromise = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         "/inspectionConditions",
        //         props.sdkOptions
        //     );
        //     Udesk.ajax.get(url, params)
        //         .then(
        //             resp => {
        //                 resolve(resp.data);
        //             },
        //             reason => {
        //                 Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.condition.index.title));
        //                 reject(reason);
        //             }
        //         );
        // });
        let processStatusPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionTasks/" + params.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });

        let roles = new Promise((resolve, reject) => {
            Udesk.ajax.get(Udesk.business.apiPath.concatApiPath(`/roles`, this.props.sdkOptions), params).then(
                (resp, textStatus, jqXHR) => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.role.loadRoleListError);
                    reject(reason);
                }
            );
        });

        return {
            processStatus: statusPromise, //流程状态
            automaticAdd: automaticAddPromise, //自动添加
            autoSample: samplePromise, //自动抽检
            autoAssign: assignPromise, //自动分配
            autoPush: pushPromise, //自动推送
            autoAssess: assessPromise, //自动评估
            autoCheck: checkPromise, //申诉复检
            // inspectionConditions: inspectionConditionsPromise,
            fields: props.sdkOptions.props.basicInfoFields,
            customFields: customFields,
            task: props.sdkOptions.props.task,
            taskId: params.taskId,
            users: usersPromise,
            case: casePromise,
            roles,
            automaticCustomFields: automaticCustomFields, //自动添加新的查询接口
            processCreateStatus: processStatusPromise
        };
    }
}
