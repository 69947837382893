import React from 'react';
import AdminUserViewRoute from './route';
import './style.scss';
import { PageHeader } from 'udesk-ui';

export default class AdminUserViewTemplate extends AdminUserViewRoute {
    render() {
        let { actions, locales, privates } = this;
        return (
            // <div className="sub-page admin-user-view">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={privates.model.user.realname}
                    onBack={actions.userHistoryBack}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root admin-user-view' style={{ padding: 16 }}>
                        <div className="page-content">
                            <div className="x_panel clearfix custom-object-list">
                                <div className="x_content clearfix">
                                    <div className="col-lg-8 col-md-8 col-xs-12 col-12">
                                        <form className="form-horizontal form-label-left" onSubmit="return false;">
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.id}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">{privates.model.user.id}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.account}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">{privates.model.user.email}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.headPortraitUrl}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <If condition={privates.model.user.headPortraitUrl}>
                                                        <img src={privates.model.user.headPortraitUrl} alt="" width="60" />
                                                    </If>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.name}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">{privates.model.user.realname}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.nickname}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">{privates.model.user.nickname}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.mobile}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">{privates.model.user.mobile}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.agentId}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">{privates.model.user.agentId}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.role}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">
                                                        <For each="role" index="index" of={privates.model.user.roleList}>
                                                            {role.name}
                                                        </For>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.user.userGroup}
                                                </label>
                                                <div className="col-lg-10 col-md-9 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">
                                                        <For each="userGroup" index="index" of={privates.model.user.userGroupList}>
                                                            {userGroup.name}
                                                        </For>
                                                    </p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
