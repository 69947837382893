// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostReviewIntelligentPartnerNodeAiWordsBody,
    BaseResponseListAiNodeWordsFoundResponse,
} from '../../../../types';

/**
 * ai生成对话话术
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/node/aiWords
 */
export function postReviewIntelligentPartnerNodeAiWords(
    data: PostReviewIntelligentPartnerNodeAiWordsBody
): Promise<BaseResponseListAiNodeWordsFoundResponse>;
export function postReviewIntelligentPartnerNodeAiWords(
    data: PostReviewIntelligentPartnerNodeAiWordsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListAiNodeWordsFoundResponse>;
export function postReviewIntelligentPartnerNodeAiWords(
    data: PostReviewIntelligentPartnerNodeAiWordsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListAiNodeWordsFoundResponse> {
    return request<PostReviewIntelligentPartnerNodeAiWordsBody, never, never>(
        '/review/intelligentPartner/node/aiWords',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeAiWords',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/aiWords',
        method: 'post',
    },
];
