import React, { FC, Ref, memo, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'udesk-ui';
import AdvancedFilter, { TemplateProps as AdvancedFilterProps } from '../advanced-filter';
import { request } from 'src/api/context';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div``;

export type TemplateProps = {
    forwardRef?: Ref<{ onClear: () => void }>;
    onSearch?: (params: { reviewSearchConditionList?: any[] }) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { forwardRef, onSearch: _onSearch } = props;

    const [fields, setFields] = useState<AdvancedFilterProps['fields']>([]);

    useEffect(() => {
        request('/review/call/customer/message/field/search', {
            method: 'get',
            __$requestCalleeName: 'getFiled',
        }).then((res) => {
            setFields(
                (res.data || []).map((d) => {
                    return {
                        ...d,
                        fieldName: d.name,
                        id: d.name,
                        // 这个字段用来区分
                        isCustomer: true,
                    };
                })
            );
        });
    }, []);

    const [visible, setVisible] = useState(false);

    const onOpen = () => {
        setVisible(true);
    };

    const [params, setParams] = useState<AdvancedFilterProps['value']>();

    const onParamsChange: AdvancedFilterProps['onChange'] = (v) => {
        setParams(v);
    };

    const onSearch = () => {
        const _params = {
            reviewSearchConditionList:
                params?.map((c) => {
                    return {
                        name: c?.field,
                        operator: c?.operator,
                        value: c?.value,
                        fieldType: fields?.find((f) => f.id === c?.field)?.fieldType,
                    };
                }) || [],
        };

        _onSearch?.(_params);

        onCancel();
    };

    const onCancel = () => {
        setVisible(false);
    };

    useImperativeHandle(forwardRef, () => {
        return {
            onClear: () => {
                setParams(undefined);
            },
        };
    });

    return (
        <Wrapper>
            <Button onClick={() => onOpen()}>{/* 筛选 */}{UdeskLocales['current'].pages.gong.saleClientCenter.list.components.advancedFilterButton.index.screen}</Button>

            <Modal
                title={/* 请选择筛选条件 */UdeskLocales['current'].pages.gong.saleClientCenter.list.components.advancedFilterButton.index.pleaseSelectFilteringCriteria}
                visible={visible}
                mask={false}
                onOk={onSearch}
                onCancel={onCancel}
                bodyStyle={{ maxHeight: 400, overflowY: 'auto' }}
            >
                <AdvancedFilter value={params} onChange={onParamsChange} fields={fields} />
            </Modal>
        </Wrapper>
    );
};

export default memo(Template);
