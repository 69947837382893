import React from 'react';
import InputUseEntityComponent from './component';
import './style.scss';

export default class InputUseEntityTemplate extends InputUseEntityComponent {
    render() {
        let { locales, actions } = this;
        let { maxLength, width, hideList } = this.props;
        let { value, isEntitiesVisible, informationEntities } = this.privates;

        return (
            <div className="input-use-entity-component" style={{ width }}>
                <input
                    type="text"
                    className="form-control input-use-entity"
                    value={value}
                    maxLength={maxLength}
                    onChange={actions.inputChanged}
                    onFocus={actions.onFocusInput}
                    onBlur={actions.onBlurInput}
                />
                <If
                    condition={
                        !hideList &&
                        isEntitiesVisible &&
                        informationEntities &&
                        informationEntities.length > 0
                    }
                >
                    <ul className="information-entities-container">
                        <For each="entity" index="index" of={informationEntities}>
                            <li
                                key={index}
                                className="information-entity-item"
                                onClick={actions.selectEntity.params(entity)}
                            >
                                {entity.apiName}
                                {locales.labels.leftBracket}
                                {entity.name}
                                {locales.labels.rightBracket}
                            </li>
                        </For>
                    </ul>
                </If>
            </div>
        );
    }
}
