import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import styled from 'styled-components';
import { Button, Card, Form, Input, message, Popconfirm, Spin, Tag } from 'udesk-ui';
import { CloseOutlined } from '@udesk/icons';
import { getSmartTagIgnoresAll } from 'src/api/smartTagIgnores/all/index';
import { postSmartTagIgnores } from 'src/api/smartTagIgnores';
import { deleteSmartTagIgnoresById } from 'src/api/smartTagIgnores/{id}/index';
import { postSmartTagIgnoresSync } from 'src/api/smartTagIgnores/sync/index';
import { SmartTagIgnoreFoundResponse } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

export const PostType = {
    /* 直接新增 */
    Direct: 0,
    /* 间接新增 */
    Backhand: 1,
};

const LabelLibWrapper = styled.div`
    width: 600px;
`;

const LabelWrapper = styled.div``;

const LabelContentWrapper = styled.div`
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
`;

const IgnoreLabel = () => {
    const [tags, setTags] = useState<SmartTagIgnoreFoundResponse[]>([]);

    const { run: getTags, loading: getLoading } = useRequest(() => getSmartTagIgnoresAll({}), {
        onSuccess: (res) => {
            setTags(res.data || []);
        },
    });

    const [curTag, setCurTag] = useState('');

    const onCurTagChange = (v) => {
        setCurTag(v);
    };

    const [saveLoading, setSaveLoading] = useState(false);

    const onAdd = () => {
        setSaveLoading(true);
        postSmartTagIgnores({ name: curTag, type: PostType.Direct })
            .then(() => {
                setCurTag('');
                getTags();
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    const [delLoading, setDelLoading] = useState(false);

    const onDelete = (id?: number) => {
        if (id) {
            setDelLoading(true);
            deleteSmartTagIgnoresById({ segments: { id } })
                .then(() => {
                    getTags();
                })
                .finally(() => {
                    setDelLoading(false);
                });
        }
    };

    const onBatch = () => {
        postSmartTagIgnoresSync().then(() => {
            message.success(/* 操作成功 */UdeskLocales['current'].pages.intelligentAnalysis.ignoreLabel.index.operationSuccessful);
        });
    };

    return (
        <Form labelCol={{ span: 3 }}>
            <Form.Item label={/* 已忽略标签 */UdeskLocales['current'].pages.intelligentAnalysis.ignoreLabel.index.labelIgnored}>
                <LabelLibWrapper>
                    <Input
                        value={curTag}
                        onChange={(e) => onCurTagChange(e.target.value)}
                        suffix={
                            <Button type="primary" onClick={onAdd} disabled={!curTag}>{/* 添加 */}{UdeskLocales['current'].pages.intelligentAnalysis.ignoreLabel.index.add}</Button>
                        }
                        style={{ marginBottom: 16 }}
                    ></Input>

                    <Card>
                        <Spin spinning={getLoading || saveLoading || delLoading}>
                            <LabelWrapper>
                                {tags.map((t) => (
                                    <Tag
                                        key={t.id}
                                        color="blue"
                                        closable
                                        onClose={(e) => {
                                            e.preventDefault();
                                        }}
                                        style={{ margin: '0 8px 8px 0' }}
                                        closeIcon={
                                            <Popconfirm
                                                title={/* 确定删除吗？ */UdeskLocales['current'].pages.intelligentAnalysis.ignoreLabel.index.areYouSureToDeleteIt}
                                                onConfirm={() => onDelete(t.id)}
                                            >
                                                <CloseOutlined />
                                            </Popconfirm>
                                        }
                                        title={t.name}
                                    >
                                        <LabelContentWrapper>{t.name}</LabelContentWrapper>
                                    </Tag>
                                ))}
                            </LabelWrapper>
                        </Spin>
                    </Card>
                </LabelLibWrapper>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 3 }}>
                <Button type="primary" onClick={onBatch}>{/* 批量过滤标签 */}{UdeskLocales['current'].pages.intelligentAnalysis.ignoreLabel.index.batchFilterLabels}</Button>
            </Form.Item>
        </Form>
    );
};

export default IgnoreLabel;
