import React, { useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { FormBuilder, Space, Button, Col, Row } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import './style.scss';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import { getWfConfigs, postWfConfigs } from 'src/api/wfConfigs';
import { WfConfigFoundResponse } from 'src/api/types';
import { putWfConfigsById } from 'src/api/wfConfigs/{id}';

const Display = (props) => <div>{props.value ?? ''}</div>;

const WechatDocking = (props) => {
    const locales = Locales['current'];
    const formLabels = locales.pages.admin.weifengDocking.formLabels;

    const [initialValues, setInitialValues] = useState<WfConfigFoundResponse>({});
    const [initialForm] = FormBuilder.useForm();
    // const [copyText, setCopyText] = useState('');
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const initialFormFields: FormProps['fields'] = [
        {
            name: 'subDomain',
            type: 'Input',
            label: formLabels.secondaryDomain,
            rules: [
                {
                    required: true,
                },
            ],
            props: {
                placeholder: locales.labels.pleaseEnter,
            },
        },
        {
            name: 'secretKey',
            type: 'Input',
            label: 'secretKey',
            tooltip: formLabels.secretKeyTip,
            rules: [
                {
                    required: true,
                },
            ],
            props: {
                placeholder: locales.labels.pleaseEnter,
            },
        },
    ];
    // const formList = [initialForm, synchronizeForm, settingForm];
    const formConfigs = useMemo(() => getConfig(initialFormFields, initialValues), [initialValues]);
    // const formConfigs = formSchema.map((i) => getConfig(i.field, initialValues));

    const cancel = () => {
        initialForm.resetFields();
    };

    const save = async (values) => {
        setBtnLoading(true);
        if (initialValues?.id) {
            putWfConfigsById(values, {
                segments: {
                    id: initialValues.id,
                },
                successMsg: locales.business.info.saveSuccess,
            });
        } else {
            postWfConfigs(values, {
                successMsg: locales.business.info.saveSuccess,
            });
        }
        setInitialValues({ ...initialValues, ...values });
        setBtnLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        getWfConfigs().then(
            (resp) => {
                setInitialValues(resp?.data ?? {});
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(
                    reason.errorMsg ||
                        locales.components.pages.components.recordDetail.getDetailFailed
                );
                setLoading(false);
            }
        );
    }, []);
    useEffect(() => {
        cancel();
    }, [initialValues]);
    return (
        <Page
            pageBodyClassName="wechat-docking-page-index"
            title={locales.pages.admin.wechatDocking.title}
            // padding={true}
            loading={loading}
            // backGroundGhost={true}
        >
            <FormBuilder onFinish={save} form={initialForm} {...formConfigs}></FormBuilder>
            <Row>
                <Col offset={7}>
                    <Space>
                        <Button loading={btnLoading && { delay: 200 }} onClick={cancel}>
                            {locales.labels.cancel}
                        </Button>
                        <Button
                            loading={btnLoading && { delay: 200 }}
                            type="primary"
                            onClick={() => initialForm.submit()}
                        >
                            {locales.labels.save}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Page>
    );
};

class Component extends React.Component {
    render() {
        return <WechatDocking {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);

const configTemplate: Omit<FormProps, 'fields'> = {
    footerRender: false,
    customComponents: { Display },
};

function getConfig(fields, initialValues) {
    return {
        ...configTemplate,
        fields,
        initialValues,
    };
}
