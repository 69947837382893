import React, { useState } from 'react';
import { Button, Icon } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
// import Locales from 'UdeskLocales';
// import { Provider, useBackToSubSystemContext } from './context';

const BackToSubSystem = (props: any) => {
    // const locales = Locales['current'];
    const { history } = props;
    const [backUrl] = useState(() => {
        return sessionStorage.getItem('Udesk_qa_web_backUrl');
    });
    if (!backUrl) return null;

    return (
        <Button
            type="primary"
            style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 16,
            }}
            icon={<Icon type="ArrowLeftOutlined" antdIcon={true} />}
            onClick={() => {
                history.push(backUrl);
                sessionStorage.removeItem('Udesk_qa_web_backUrl');
            }}
        >{/* 返回业务系统 */}{UdeskLocales['current'].components.backToSubSystem.index.returnToBusinessSystem}</Button>
    );
};

// type InternalBackToSubSystemType = typeof InternalBackToSubSystem;
// type BackToSubSystemType = InternalBackToSubSystemType & {
//     Provider: typeof Provider;
// };

// const BackToSubSystem: BackToSubSystemType = InternalBackToSubSystem as BackToSubSystemType;
// BackToSubSystem.Provider = Provider;

export { BackToSubSystem };
