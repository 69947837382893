// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetRolesQuery,
    BaseResponseListRoleFoundResponse,
    PostRolesBody,
    BaseResponseRoleFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 角色
 * @link [GET] /roles
 */
export function getRoles(): Promise<BaseResponseListRoleFoundResponse>;
export function getRoles(
    options: ApiOptions<never, GetRolesQuery>
): Promise<BaseResponseListRoleFoundResponse>;
export function getRoles(
    options?: ApiOptions<never, GetRolesQuery>
): Promise<BaseResponseListRoleFoundResponse> {
    return request<never, never, GetRolesQuery>('/roles', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getRoles',
    });
}

/**
 * save
 *
 * @export
 * @tags 角色
 * @link [POST] /roles
 */
export function postRoles(data: PostRolesBody): Promise<BaseResponseRoleFoundResponse>;
export function postRoles(
    data: PostRolesBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseRoleFoundResponse>;
export function postRoles(
    data: PostRolesBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseRoleFoundResponse> {
    return request<PostRolesBody, never, never>('/roles', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postRoles',
    });
}

export const meta = [
    { tags: ['角色'], path: '/roles', method: 'get' },
    { tags: ['角色'], path: '/roles', method: 'post' },
];
