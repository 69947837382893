import React from 'react';
import Udesk from 'Udesk';
import { getSystemModule } from 'Udesk/system/subApp';
// import _concat from "lodash-es/concat";
import cloneDeep from 'lodash-es/cloneDeep';

class CustomerServiceRankListComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
        total: null,
        selectedagentNames: [],
        customerGroupData: [], //客服组的数据
        selectedCustomerGroup: [],
        checked: [],
        customerData: [], //客服数据
        selectedCustomer: [],
        filteredInfo: {},
        sortedInfo: null,
        selectedRowKeys: [], //表格中 选中的key
        columns: [], //表格列标题
        tableData: [],
        sourceKeys: [],
        targetKeys: [], //穿梭框右侧的key的集合
        targetKeysAfterSearch: [],
        targetItemsAfterSearch: [],
        selectedKeys: [],
        targetItems: [], //穿梭框右侧的obj集合
        comparedCustomer: [], //选出要进行对比的客服的key
        startTime: moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00',
        endTime: moment().format('YYYY-MM-DD') + ' 23:59:59',
        isHidden: false,
        loading: false,
    };
    privates = {
        udeskTableRef: React.createRef(),
        selectedagentIds: [],
        allagentNos: [], //初始化时获得的所有客服ID，用于reset
        allCustomerInfo: [], //所有客服的所有数据
        allCustomerGroupKeys: [],
        sortKey: null,
        sortType: 1,
        paging: {
            pageNum: 1,
            pageSize: 10,
            total: null,
        },
        selectedItems: [],
        smartTags: [],
        defaultStartTime: moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00',
        defaultEndTime: moment().format('YYYY-MM-DD') + ' 23:59:59',
        allCustomerGroupId: [],
        checkboxSelectedKeys: [],
        customerNosSelected: [],
        customerNosSelectedCopy: [],
        filteredList: [], //表格列标题
        targetKeys: [], //穿梭框右侧的keys
        transferSearchValue: '',
        sources: [
            { title: '1', key: '1' },
            { title: '2', key: '2' },
            { title: '3', key: '3' },
            { title: '4', key: '4' },
            { title: '5', key: '5' },
        ],
        toPortrait: [],
    };
    static computes = {};
    actions = {
        filterTreeNode(inputValue, treeNode) {
            return treeNode.title?.indexOf?.(inputValue) >= 0;
        },
        pageNumChange(pageNumber) {
            if (pageNumber !== this.privates.paging.pageNum) {
                this.privates.paging.pageNum = pageNumber;
                this.actions.getTableData();
                this.actions.update();
            }
        },
        pageSizeChange(current, pageSize) {
            this.privates.paging.pageNum = current;
            this.privates.paging.pageSize = pageSize;
            this.actions.getTableData();
            this.actions.update();
        },
        onCheckChange(value) {
            this.privates.selectedItems = value;
            this.actions.update();
        },
        selectTime([startTime, endTime]) {
            this.setState({
                startTime,
                endTime,
            });
        },
        selectCustomerGroup(value, label, extra) {
            console.log(value, label, extra);
            let customerKeysSelected = [];
            getCustomerKeys(extra.allCheckedNodes, customerKeysSelected);
            this.privates.allCustomerGroupKeys.map((customerGroupKey) => {
                let index = customerKeysSelected.findIndex((item) => {
                    return item * 1 === customerGroupKey;
                });
                index !== -1 && customerKeysSelected.splice(index, 1);
                return customerGroupKey;
            });
            let customerNosSelected = [];
            customerKeysSelected.map((key) => {
                this.privates.allCustomerInfo.map((info) => {
                    parseInt(key) * 1 === info.id && customerNosSelected.push(info.agentNo);
                    return info;
                });
                return key;
            });
            this.privates.customerNosSelected = customerNosSelected;
            let { sdkOptions } = this.props;
            this.setState({ selectedCustomerGroup: value }, () => {
                if (this.state.selectedCustomerGroup.length) {
                    this.state.selectedCustomerGroup.map((item, idx) => {
                        if (extra.allCheckedNodes?.[idx]?.node?.props?.nodeType) {
                            getCustomerGroupMember(parseInt(item), sdkOptions, this);
                        }
                        return item;
                    });
                } else {
                    this.setState({
                        customerData: [],
                    });
                }
            });
        },
        deselectCustomerGroup(value) {},
        onSelectedCustomerChange(value) {
            this.setState({ selectedCustomer: value });
        },
        search() {
            //发送ajax
            this.privates.customerNosSelectedCopy = cloneDeep(this.privates.customerNosSelected);
            this.actions.getTableData();
        },
        reset() {
            this.privates.customerNosSelected = cloneDeep(this.privates.allagentNos);
            this.privates.customerNosSelectedCopy = cloneDeep(this.privates.customerNosSelected);
            this.setState(
                {
                    selectedCustomerGroup: this.privates.allCustomerGroupId,
                    startTime: this.privates.defaultStartTime,
                    endTime: this.privates.defaultEndTime,
                },
                () => {
                    this.actions.getTableData();
                }
            );
        },
        handleClose(index) {
            let selectedRowKeys = [...this.state.selectedRowKeys];
            let selectedagentNames = [...this.state.selectedagentNames];
            selectedRowKeys.splice(index, 1);
            selectedagentNames.splice(index, 1);
            this.setState({
                selectedRowKeys,
                selectedagentNames,
            });
        },
        compared() {
            sessionStorage.setItem(
                'customerRankListToCustomerPortrait_pamars',
                JSON.stringify({
                    agentNos: this.state.selectedRowKeys,
                    agentIds: this.privates.selectedagentIds,
                    agentName: '',
                    startTime: this.state.startTime,
                    endTime: this.state.endTime,
                    indicatorIds: [],
                })
            );
            sessionStorage.setItem(
                'customerRankListToCustomerPortrait_selected',
                JSON.stringify(this.privates.toPortrait)
            );
            let routeOptions = {
                history: this.props.history,
                routeName: 'customerServicePortraitIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        export() {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `agentAbilityRecordDays/export`,
                sdkOptions
            );
            let pamars = {
                start: this.state.startTime,
                end: this.state.endTime,
                agentNos: this.privates.customerNosSelected,
                // pageNum: this.privates.paging.pageNum,
                // pageSize: this.privates.paging.pageSize,
                sortKey: this.privates.sortKey,
                sortType: this.privates.sortType,
                systemModule: getSystemModule(),
            };
            if (!this.privates.sortKey) {
                delete pamars.sortKey;
            }
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, pamars).then(
                    (resp) => {
                        Udesk.ui.notify.success(
                            this.locales.components.pages.customerServiceRankList.select.seccuss
                        );
                        resolve(resp);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        handleTableChange(pagination, filters, sorter) {
            this.privates.sortType = sorter.order && sorter.order === 'descend' ? 2 : 1;
            this.privates.sortKey = sorter.columnKey;
            this.setState(
                {
                    sortedInfo: sorter,
                },
                () => {
                    this.actions.getTableData();
                }
            );
        },
        onTableSelectChange(selectedRowKeys, selectedRows) {
            let selectedagentIds = [];
            let selectedagentNames = [];
            let toPortrait = [];
            if (selectedRowKeys.length > 5) {
                Udesk.ui.notify.error(
                    this.locales.components.pages.customerServiceRankList.select.warning
                );
            }
            this.setState(
                {
                    selectedRowKeys:
                        selectedRowKeys.length <= 5 ? selectedRowKeys : this.state.selectedRowKeys,
                },
                () => {
                    this.state.selectedRowKeys.map((selectedRowKey) => {
                        this.privates.allCustomerInfo.map((info) => {
                            selectedRowKey === info.agentNo && selectedagentIds.push(info.id);
                            selectedRowKey === info.agentNo &&
                                selectedagentNames.push({
                                    agentName: info.agentName,
                                    agentNo: info.agentNo,
                                });
                            selectedRowKey === info.agentNo &&
                                toPortrait.push({
                                    key: `${info.id}`,
                                    label: `${info.agentName}(${info.agentNo})`,
                                });
                            return info;
                        });
                        return selectedRowKey;
                    });
                    this.setState({
                        selectedagentNames,
                    });
                    this.privates.selectedagentIds = selectedagentIds;
                    this.privates.toPortrait = toPortrait;
                }
            );
        },
        onPopoverVisible(e) {
            this.privates.udeskTableRef.current.triggerColumnsControl();
        },
        getTableData() {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `agentAbilityRecordDays/rankList`,
                sdkOptions
            );
            let pamars = {
                start: this.state.startTime,
                end: this.state.endTime,
                agentNos: this.privates.customerNosSelectedCopy,
                pageNum: this.privates.paging.pageNum,
                pageSize: this.privates.paging.pageSize,
                sortKey: this.privates.sortKey,
                sortType: this.privates.sortType,
            };
            !pamars.sortKey && delete pamars.sortKey;
            let max =
                moment(this.state.startTime).add('days', 366).format('YYYY-MM-DD') + ' 23:59:59';
            if (max >= this.state.endTime) {
                this.privates.paging.total = this.privates.customerNosSelectedCopy.length;
                !this.privates.customerNosSelectedCopy.length &&
                    Udesk.ui.notify.error(
                        this.locales.components.pages.customerServiceRankList.select.warning2
                    );
                this.privates.customerNosSelectedCopy.length &&
                    new Promise((resolve, reject) => {
                        this.setState({
                            loading: true,
                        });
                        Udesk.ajax.post(url, pamars).then(
                            (resp) => {
                                let columns = [];
                                let fieldValues = [];
                                resp.data.fieldData.map((item) => {
                                    let fieldValue = item.fieldValueMap;
                                    fieldValue.key = item.agentNo;
                                    fieldValues.push(fieldValue);
                                    return item;
                                });
                                resp.data.showFields.map((item) => {
                                    let column = {
                                        title: item.label,
                                        dataIndex: item.id,
                                        key: item.id,
                                        sorter: (a, b) => {},
                                        // ellipsis: true
                                        onCell: (record) => this.actions.onCell(item, record),
                                    };
                                    columns.push(column);
                                    return item;
                                });
                                let targetKeysDefault = [] || this.state.targetKeys;
                                let targetItemsDefault = [] || this.state.targetItems;
                                columns.map((column, index) => {
                                    this.state.targetItems.length !== 0 &&
                                        this.state.targetItems.forEach((item) => {
                                            if (item.key === column.key) {
                                                targetKeysDefault.push(column.key);
                                                targetItemsDefault.push(column);
                                            }
                                        });
                                    this.state.targetItems.length === 0 &&
                                        targetKeysDefault.push(column.key);
                                    this.state.targetItems.length === 0 &&
                                        targetItemsDefault.push(column);
                                    return column;
                                });
                                targetKeysDefault.push('action');
                                targetItemsDefault.push({
                                    title: this.locales.components.pages.customerServiceRankList
                                        .table.actionTitle,
                                    key: 'action',
                                    render: (text, record) => (
                                        <span
                                            onClick={this.actions.action.params(text, record)}
                                            style={{
                                                color: 'rgba(27,109,255,1)',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {
                                                this.locales.components.pages
                                                    .customerServiceRankList.table.action
                                            }
                                        </span>
                                    ),
                                });
                                columns.push({
                                    title: this.locales.components.pages.customerServiceRankList
                                        .table.actionTitle,
                                    key: 'action',
                                    render: (text, record) => (
                                        <span
                                            onClick={this.actions.action}
                                            style={{
                                                color: 'rgba(27,109,255,1)',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {
                                                this.locales.components.pages
                                                    .customerServiceRankList.table.action
                                            }
                                        </span>
                                    ),
                                });
                                fieldValues.push();
                                this.setState({
                                    columns,
                                    tableData: fieldValues,
                                    loading: false,
                                    sourceKeys: columns,
                                    targetKeys: targetKeysDefault,
                                    targetKeysAfterSearch: targetKeysDefault,
                                    targetItems: targetItemsDefault,
                                    targetItemsAfterSearch: targetItemsDefault,
                                });
                                resolve(resp);
                            },
                            (reason) => {
                                Udesk.ui.notify.error(reason.errorMsg);
                                reject(reason);
                            }
                        );
                    });
            } else {
                Udesk.ui.notify.error(
                    this.locales.components.pages.customerServiceRankList.select.dataWarning
                );
            }
        },
        action(text, record) {
            let toPortrait = {};
            let toPortraitSlecet = {};
            this.privates.allCustomerInfo.forEach((info) => {
                if (info.agentNo === record.key) {
                    toPortrait.agentNos = record.key;
                    toPortrait.agentIds = info.id;
                    toPortrait.agentName = info.agentName;
                    toPortraitSlecet = [
                        { key: `${info.id}`, label: `${info.agentName}(${info.agentNo})` },
                    ];
                }
            });
            sessionStorage.setItem(
                'customerRankListToCustomerPortrait_pamars',
                JSON.stringify({
                    agentNos: [toPortrait.agentNos],
                    agentIds: [toPortrait.agentIds],
                    agentName: toPortrait.agentName,
                    startTime: this.state.startTime,
                    endTime: this.state.endTime,
                    indicatorIds: [],
                })
            );
            sessionStorage.setItem(
                'customerRankListToCustomerPortrait_selected',
                JSON.stringify(toPortraitSlecet)
            );
            let routeOptions = {
                history: this.props.history,
                routeName: 'customerServicePortraitIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        showTotal(total, range) {
            let { locales } = this;
            // return `共${total}条，${total/range[1]}页`;
            return Udesk.utils.string.format(
                locales.components.pages.customerServiceRankList.table.page,
                total,
                Math.ceil(total / this.privates.paging.pageSize)
            );
        },
        onCell(item, record) {
            const hasFeature = Udesk.data.adminTaskMenuList.hasFeature;

            return {
                onClick: (event) => {
                    const isTask = item.id?.split?.('_')?.[0] === 't';
                    const taskId = item.id?.split?.('_')?.[1] || '';

                    if (
                        isTask &&
                        taskId &&
                        hasFeature &&
                        hasFeature('task:report:root') &&
                        hasFeature('task:report:agent:root')
                    ) {
                        Udesk.ui.routing.transitionTo({
                            history: this.props.history,
                            pathParams: { taskId },
                            routeName: 'tasksAgentReport',
                            queryParams: {
                                startDate: this.state?.startTime?.split?.(' ')?.[0],
                                endDate: this.state?.endTime?.split?.(' ')?.[0],
                                agentNo: record.key,
                            },
                        });
                    }
                },
            };
        },
    };

    //#region Life Cycle
    componentDidMount() {
        let customerPortraitToCustomerRankListPamars = JSON.parse(
            sessionStorage.getItem('customerPortraitToCustomerRankList_pamars')
        );
        sessionStorage.removeItem('customerPortraitToCustomerRankList_pamars');
        customerPortraitToCustomerRankListPamars &&
            this.setState({
                startTime: customerPortraitToCustomerRankListPamars.startTime,
                endTime: customerPortraitToCustomerRankListPamars.endTime,
            });

        let { sdkOptions } = this.props;
        let url = Udesk.business.apiPath.concatApiPath(`agentGroups`, sdkOptions);
        new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.privates.allCustomerGroupId.push(resp.data[0].id + '/n');
                    getCustomerGroupMember(
                        parseInt(this.privates.allCustomerGroupId[0]),
                        sdkOptions,
                        this
                    );
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    }
    componentWillUnmount() {}
    //#endregion
}

function getCustomerGroupMember(parmars, sdkOptions, context) {
    new Promise((resolve, reject) => {
        let url2 = Udesk.business.apiPath.concatApiPath(`agentGroups/${parmars}`, sdkOptions);
        Udesk.ajax.post(url2, { checked: 0, keyWord: '' }).then(
            (resp) => {
                let customerDataCopy = [];
                resp.data.map((item) => {
                    customerDataCopy.push({
                        name: `${item.agentName}(${item.agentNo})`,
                        id: item.id,
                    });
                    return item;
                });
                context.setState({
                    customerData: customerDataCopy,
                });
                resolve(resp);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                reject(reason);
            }
        );
    });
}

function getCustomerKeys(allCheckedNodes, customerKeysSelected) {
    allCheckedNodes.map((allCheckedNode) => {
        if (allCheckedNode.props) {
            if (allCheckedNode.props.children && allCheckedNode.props.children.length > 0) {
                getCustomerKeys(allCheckedNode.props.children, customerKeysSelected);
            } else {
                allCheckedNode.node && customerKeysSelected.push(allCheckedNode.node.key);
                !allCheckedNode.node && customerKeysSelected.push(allCheckedNode.key);
            }
        } else {
            if (allCheckedNode.children && allCheckedNode.children.length > 0) {
                getCustomerKeys(allCheckedNode.children, customerKeysSelected);
            } else {
                allCheckedNode.node && customerKeysSelected.push(allCheckedNode.node.key);
                !allCheckedNode.node && customerKeysSelected.push(allCheckedNode.key);
            }
        }
        return allCheckedNode;
    });
}

export default CustomerServiceRankListComponent;
