import Udesk from 'Udesk';
import TestSetListComponent from './component';
class TestSetListRoute extends TestSetListComponent {
    asyncModel(changedParams) {
        let { sdkOptions, match } = this.props;
        let { pageSize, pageNum, conditionList, judgeStrategy, customJudgeLogic } = this.privates.storages;
        let taskId = match.params.taskId;
        let params = {
            pageNum,
            pageSize,
            taskId,
            conditionList: this.actions.getQueryConditionList(conditionList).map(item => {
                if (item.field.inspectionTaskId) {
                    return {
                        ...item,
                        field: {
                            ...item.field,
                            id: item.field.statusKey
                        }
                    };
                } else {
                    return item;
                }
            }),
            judgeStrategy,
            customJudgeLogic
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }
        let url = Udesk.business.apiPath.concatApiPath(`assessmentResults/refresh`, sdkOptions);
        let testSetPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        this.privates.loading = true;
        this.actions.update();
        let url2 = Udesk.business.apiPath.concatApiPath(`inspection-test-set/search`, sdkOptions);
        let inspectionDataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url2, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let url3 = Udesk.business.apiPath.concatApiPath(
            `inspection-test-set/reassessment/${taskId}/process?manualTaskId=`,
            sdkOptions
        );
        let inspectionProcess = new Promise((resolve, reject) => {
            Udesk.ajax.get(url3).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        // let url4 = Udesk.business.apiPath.concatApiPath(`assessmentResults/pointOffset/${taskId}`, sdkOptions);
        // let pointOffset = new Promise((resolve, reject) => {
        //     Udesk.ajax.get(url4).then(
        //         resp => {
        //             resolve(resp);
        //         },
        //         reason => {
        //             Udesk.ui.notify.error(reason.errorMsg);
        //             reject(reason);
        //         }
        //     );
        // });
        let url5 = Udesk.business.apiPath.concatApiPath(
            `inspection-test-set/batchRemove/${taskId}/process?manualTaskId=`,
            sdkOptions
        );
        let batchDelProcess = new Promise((resolve, reject) => {
            Udesk.ajax.get(url5).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let checkPointsUrl = Udesk.business.apiPath.concatApiPath(
            `inspectionData/${match.params.taskId}/pointList`,
            sdkOptions
        );
        let checkPointsPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(checkPointsUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            inspectionData: inspectionDataPromise,
            testSetPromise: testSetPromise,
            // pointOffset: pointOffset,
            inspectionProcess: inspectionProcess,
            batchDelProcess: batchDelProcess,
            checkPoints: checkPointsPromise,
        };
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}

export default TestSetListRoute;
