// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostSearchTemplateAdvancedBody,
    BaseResponseListSearchTemplateDTO,
} from '../../types';

/**
 * findAll
 *
 * @export
 * @tags 筛选器
 * @link [POST] /search-template/advanced
 */
export function postSearchTemplateAdvanced(
    data: PostSearchTemplateAdvancedBody
): Promise<BaseResponseListSearchTemplateDTO>;
export function postSearchTemplateAdvanced(
    data: PostSearchTemplateAdvancedBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListSearchTemplateDTO>;
export function postSearchTemplateAdvanced(
    data: PostSearchTemplateAdvancedBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListSearchTemplateDTO> {
    return request<PostSearchTemplateAdvancedBody, never, never>('/search-template/advanced', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSearchTemplateAdvanced',
    });
}

export const meta = [{ tags: ['筛选器'], path: '/search-template/advanced', method: 'post' }];
