import React from 'react';
import Udesk from 'Udesk';
// import ReactSelect from 'udesk-react/src/components/react-select';
import SpotCheckListComponent from './component';
import { Select, Popconfirm, PageHeader, Button, Space } from 'udesk-ui';
import _size from 'lodash-es/size';
import _filter from 'lodash-es/filter';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import ReactModal from 'udesk-react/src/components/react-modal';
// import ClassNames from 'classnames';
import './style.scss';
import UdeskTable from 'Component/common/udesk-table';
import ExportButton from 'Component/common/export-button';
// import UdeskDatePicker from 'Component/common/udesk-date-picker';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import CustomFilterBtn from 'Component/pages/components/custom-filter-button';

export default class SpotCheckListTemplate extends SpotCheckListComponent {
    render() {
        let { sdkOptions, match } = this.props;
        let { pageSize, total, pageNum } = this.state;
        let {
            selectedItems,
            checkVisible,
            // autoComponentBlurFlag,
            tableRef,
            loading,
            selectedRowKeys,
            filterVisible,
            type,
            customId,
            customName,
        } = this.privates;
        let {
            // startTime,
            // endTime,
            // sampleStatus,
            // seeUserList,
            // selectSpotCheckValue,
            // userTwoValue,
            conditionList,
            judgeStrategy,
            customJudgeLogic,
        } = this.privates.storages;

        let { actions, locales } = this;
        let { _columns, _dataList } = this.privates.computes;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        return (
            // <div className="spot-check-list-page row">
            <div className='udesk-qa-web-page'>
                <PageHeader className='udesk-qa-web-page-header' title={locales.components.pages.spotCheckList.title} />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root spot-check-list-page'>
                        <If
                            condition={
                                hasFeature &&
                                (hasFeature('task:work:sample:view:self') ||
                                    hasFeature('task:work:sample:view:none') ||
                                    hasFeature('task:work:sample:view:all') ||
                                    hasFeature('task:work:sample:view'))
                            }
                        >
                            <div className='spot-check-list-body-search' style={{ position: 'relative' }}>
                                {/* <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: 10 }}>
                                        <UdeskDatePicker disabled={loading} style={{ width: 246 }} allowClear={false} onChange={actions.dateChanged} value={[startTime, endTime]} />
                                    </div>
                                    <ReactSelect classNames="udesk-qc-react-select" value={sampleStatus} dataScource={Udesk.enums.samplingStatus} hasNullSelect={true} onChanged={actions.getSamplingStatus} /> */}
                                {/* <label className="spot-check-list-body-search-checkbox-label">
                                        <input type="checkbox" className="spot-check-list-body-search-checkbox-label-input" checked={showMyOwn} onChange={actions.changeOnlyStatus} />
                                        <span className="spot-check-list-body-search-checkbox-label-span">
                                            {locales.components.pages.spotCheckList.onlyMyTip}
                                        </span>
                                    </label> */}
                                {/* <Select value={selectSpotCheckValue} style={{ width: 140, marginRight: "10px" }} onChange={actions.selectTwoChange}>
                                        <If condition={hasFeature && hasFeature("task:work:sample:view:all")}>
                                            <Select.Option value={1}>{locales.components.pages.spotCheckList.allInspector}</Select.Option>
                                        </If>
                                        <If condition={hasFeature && hasFeature("task:work:sample:view:all")}>
                                            <Select.Option value={5}>{locales.components.pages.spotCheckList.seInspector}</Select.Option>
                                        </If>
                                        <If condition={hasFeature && (hasFeature("task:work:sample:view:self"))}>
                                            <Select.Option value={2}>{locales.components.pages.spotCheckList.myInspector}</Select.Option>
                                        </If>
                                        <If condition={hasFeature && hasFeature("task:work:sample:view:none")}>
                                            <Select.Option value={3}>{locales.components.pages.spotCheckList.noInspector}</Select.Option>
                                        </If>
                                    </Select>
                                    {
                                        seeUserList && (
                                            <div style={{width: 200}} className={ClassNames("spot-check-list-body-auto-component", { 'spot-check-list-body-auto-component-blur': autoComponentBlurFlag })}>
                                                <AutoComplete isMulti={true} value={userTwoValue} loadOptions={actions.loadUserOptions} onChanged={actions.onUserListTwoChanged} onFocused={actions.autoComponentFocus} onBlurred={actions.autoComponentBlur} enableEmptySearch={true} />
                                            </div>
                                        )
                                    }
                                </div> */}
                                <div className='qa-react-page-body-search-container-right-part pull-right'>
                                    <Space>
                                        <CustomFilterBtn
                                            onClick={actions.changeCusFilterVisible}
                                            judgeStrategy={judgeStrategy}
                                            conditionList={conditionList}
                                            customJudgeLogic={customJudgeLogic}
                                        />
                                        <If
                                            condition={
                                                hasFeature &&
                                                (hasFeature('task:work:sample:cancel') ||
                                                    hasFeature('task:work:sample:reassigned'))
                                            }
                                        >
                                            <Select
                                                defaultValue={locales.labels.batchActions}
                                                value={this.privates.selectValue || locales.labels.batchActions}
                                                style={{ minWidth: 120 }}
                                                onChange={actions.selecthandleChange}
                                            >
                                                <If
                                                    condition={
                                                        hasFeature && hasFeature('task:work:sample:batch:delete')
                                                    }
                                                >
                                                    <Select.Option value='delete' style={{ padding: '0px' }}>
                                                        <Popconfirm
                                                            okButtonProps={{ disabled: selectedItems.length === 0 }}
                                                            title={Udesk.utils.string.format(
                                                                locales.labels.deleteRecordInfo,
                                                                _size(selectedItems)
                                                            )}
                                                            okText={locales.labels.confirm}
                                                            cancelText={locales.labels.cancel}
                                                            onCancel={actions.deleteRecordCancel}
                                                            onConfirm={actions.deleteRecordOk}
                                                        >
                                                            <a
                                                                href=''
                                                                onClick={actions.deletReordClick}
                                                                style={{
                                                                    display: 'inline-block',
                                                                    width: '100%',
                                                                    padding: '5px 12px',
                                                                }}
                                                            >
                                                                {locales.labels.deleteRecord}
                                                            </a>
                                                        </Popconfirm>
                                                    </Select.Option>
                                                </If>
                                                <If condition={hasFeature && hasFeature('task:work:sample:cancel')}>
                                                    <Select.Option value='cancel'>
                                                        {locales.labels.cancelDistribution}
                                                    </Select.Option>
                                                </If>
                                                <If condition={hasFeature && hasFeature('task:work:sample:reassigned')}>
                                                    <Select.Option value='again'>
                                                        {locales.labels.againDistribution}
                                                    </Select.Option>
                                                </If>
                                            </Select>
                                            <If condition={hasFeature && hasFeature('task:work:sample:export')}>
                                                <ExportButton
                                                    disabled={loading}
                                                    exportDataCount={total}
                                                    hasFilterCondition={true}
                                                    onClick={actions.export}
                                                />
                                            </If>
                                            <Button onClick={actions.onPopoverVisible}>
                                                {locales.components.pages.customerServiceRankList.select.column}
                                            </Button>
                                        </If>
                                    </Space>
                                </div>
                            </div>
                            <div className='spot-check-list-body-list'>
                                {/* <If condition={privates.asyncModelFulfilled}>
                                    <If condition={!privates.asyncModelResolved}>
                                        {privates.asyncModelErrorMsg}
                                    </If>
                                </If>
                                <If condition={!privates.asyncModelFulfilled}>
                                    Loading...
                                </If>
                                <If condition={privates.asyncModelFulfilled && privates.asyncModelResolved}> */}
                                {/* <ReactTable ref={tableRef} columns={_columns} items={privates.asyncModel.data.fieldDataList} checkable={hasFeature && (hasFeature("task:work:sample:cancel") || hasFeature("task:work:sample:reassigned")) ? true : false} onSelectionChanged={actions.onCheckChange} selectedItems={_concat([], selectedItems)} enableChooseColumns={true} onColumnsChanged={actions.onColumnsChange} cacheKey="tasks.manage.workbench.spot-check-list.index" itemIdentityField="dataId" rowClick={actions.viewItemDetail} />
                                    <ReactPager pageSize={pageSize} total={total} pageNum={pageNum} languageCode={sdkOptions.props.language} pageChanged={actions.pageChanged} /> */}
                                <UdeskTable
                                    cacheKey={`tasks.manage.workbench.spot-check-list.index.taskId-${match.params.taskId}`}
                                    // scroll={{ x: _size(_columns) * 180, y: 'calc(100vh - 64px - 58px - 64px - 165px)' }}
                                    ref={tableRef}
                                    loading={loading}
                                    subtractedHeight={176}
                                    columns={_columns}
                                    dataSource={_dataList}
                                    rowKey={(item) => item.id}
                                    rowSelection={{ selectedRowKeys, onChange: actions.onCheckChange }}
                                    onRow={(record) => {
                                        return { onClick: actions.viewItemDetail.params(record) };
                                    }}
                                    pagination={{
                                        onChange: actions.pageChanged,
                                        total: total,
                                        pageSize: pageSize,
                                        current: pageNum,
                                        onShowSizeChange: actions.pageSizeChange,
                                    }}
                                ></UdeskTable>
                                {/* </If> */}
                            </div>
                        </If>
                        <CustomFilterModal
                            visible={filterVisible}
                            cancel={actions.cancel}
                            applyAction={actions.handleConditionApply}
                            onConditionFilterSaved={actions.onConditionFilterSaved}
                            judgeStrategy={judgeStrategy}
                            customJudgeLogic={customJudgeLogic}
                            customName={customName}
                            customId={customId}
                            conditionList={conditionList}
                            type={type}
                            taskId={this.props.match.params.taskId}
                            taskName={this.props.sdkOptions.props.task.name}
                            filterList={['f_8', 'r_1', 'r_2', 'r_3']}
                            querryType='spotCheckList'
                            filterTaskData={true}
                        />

                        <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save}
                            visible={checkVisible}
                            title={
                                this.privates.selectStatus
                                    ? locales.labels.againDistribution
                                    : locales.labels.cancelDistribution
                            }
                            closeIconClass='udesk-qc-iconfont icon-qc-quxiao pull-right'
                            onCancel={actions.changeFilterVisible}
                            onOk={actions.handleConditionFilter}
                            headerClassName='udesk-qa-modal-default-header'
                            footerClassName='udesk-qa-modal-default-footer'
                        >
                            {this.privates.selectStatus ? (
                                <div>
                                    <div style={{ marginBottom: '10px' }}>{`${locales.labels.distributionInfoFirstOne}${
                                        selectedItems && _size(selectedItems)
                                    }${locales.labels.distributionInfoFirstTwo}`}</div>
                                    <Select
                                        defaultValue={2}
                                        style={{ width: 120, marginRight: '10px' }}
                                        onChange={actions.selectResAssignendChange}
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    >
                                        <Select.Option value={1}>{locales.labels.allOptionOpertor}</Select.Option>
                                        <Select.Option value={2}>{locales.labels.oneOptionOpertor}</Select.Option>
                                    </Select>
                                    {this.privates.userStatusAgain && (
                                        <div style={{ width: '200px', display: 'inline-block' }}>
                                            <AutoComplete
                                                isMulti={true}
                                                value={this.privates.userValue}
                                                loadOptions={actions.loadUserOptions}
                                                onChanged={actions.onUserChanged}
                                                enableEmptySearch={true}
                                            />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {locales.labels.distributionInfoSecondOne}
                                    {selectedItems && _size(selectedItems)}
                                    {locales.labels.distributionInfoSecondThree}
                                </div>
                            )}
                            <div style={{ marginTop: '10px' }}>
                                {locales.labels.distributionInfoThirdOne}
                                {selectedItems &&
                                    _size(
                                        _filter(
                                            selectedItems,
                                            (item) => item.af_7 === locales.enums.samplingStatus.checked
                                        )
                                    )}
                                {locales.labels.distributionInfoThirdThree}
                            </div>
                        </ReactModal>
                    </div>
                </div>
            </div>
        );
    }
}
