// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { PutWecomSmartTagsByIdStatusByStatusParam, BaseResponseVoid } from '../../../../types';

/**
 * status
 *
 * @export
 * @tags wecom-smart-tag-controller
 * @link [PUT] /wecomSmartTags/{id}/status/{status}
 * @param id
 * @param status
 */
export function putWecomSmartTagsByIdStatusByStatus(
    options: ApiOptions<PutWecomSmartTagsByIdStatusByStatusParam, never> & {
        segments: PutWecomSmartTagsByIdStatusByStatusParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PutWecomSmartTagsByIdStatusByStatusParam, never>(
        '/wecomSmartTags/{id}/status/{status}',
        { ...options, method: 'put', __$requestCalleeName: 'putWecomSmartTagsByIdStatusByStatus' }
    );
}

export const meta = [
    {
        tags: ['wecom-smart-tag-controller'],
        path: '/wecomSmartTags/{id}/status/{status}',
        method: 'put',
    },
];
