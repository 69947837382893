import Udesk from 'Udesk';
import IndexManagementDetailComponent from './component';

class IndexManangementDetailRoute extends IndexManagementDetailComponent {
    asyncModel() {
        let { sdkOptions, location } = this.props;
        let urlTree = Udesk.business.apiPath.concatApiPath(`agentGroups`, sdkOptions);
        let initTree = new Promise((resolve, reject) => {
            Udesk.ajax.get(urlTree).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let editDetailPromise = '';
        if (location.pathname && location.pathname.indexOf('edit/') !== -1) {
            let list = location.pathname.split('edit/');
            let chartFieldsUrl = Udesk.business.apiPath.concatApiPath(`agentPictureIndicators/${list[1]}`, sdkOptions);
            editDetailPromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(chartFieldsUrl).then(
                    resp => {
                        resolve(resp);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        }

        return {
            initTree,
            editDetailPromise
        };
    }
    parseAsyncModel(asyncModel) {
        return asyncModel;
    }
}
export default IndexManangementDetailRoute;
