/*
 * @Author: 唐毓斌
 * @Description: 任务详情
 */
import React, {useCallback, useEffect, useState,useRef } from 'react';
import Page from 'Component/common/udesk-qa-page-layout'; 
import * as echarts from 'echarts';
import useResizeObserver from 'use-resize-observer';
import { getFirstEchartOptions } from './hook/useSummary';
import { getNumberEchartOptions } from './hook/useSummaryDetail';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { useUdeskDatePicker } from 'Component/common/udesk-date-picker/template';
import OrganizationMemberSelect from '../../../components/OrganizationMemberSelect';
import Udesk from 'Udesk';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import ResultAnalysis from './components/ResultAnalysis';
import { Button,Radio,Tag } from 'udesk-ui';
import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';

import { isExercise } from '../../../../learning-center/components/util';
import { useCoachUserTreeDataWithPermit } from 'src/util/hook/user';
import { useRequest } from 'src/util/hook';



const TaskCenterDetail: React.FC<any> = (props) => {
    const request = useRequest();
    const locales = UdeskLocales['current'];
    const { history, match, routes } = props;
    const taskId = match?.params?.id || '0';
    console.log('task-center:routes', routes);
    const [taskInfo, setTaskInfo] = useState<any>();
    const preStartTime = moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00';
    const preEndTime = moment().format('YYYY-MM-DD') + ' 23:59:59';
    // const [loading, setLoading] = useState(false);
    const [userSelectObj, setUserSelectObj] = useState<any>({
        userIds: [],
        organizationIds: []
    });
    const { treeData } = useCoachUserTreeDataWithPermit();
    
    const [echartsType, setEchartsType] = useState('barChart');
    const [echartInstanceFirst, setEchartInstanceFirst] = useState<any>(null);
    const [ setEchartInstanceSecond] = useState<any>(null);
    const { startTime, endTime, ...datePickerProps } = useUdeskDatePicker();
    // const [detailChartOptions, setDetailChartOptions] = useState<any>();
    const [isClickPoint, setIsClickPoint] = useState(false);
    const [detailNodeId, setDetailNodeId] = useState(null);
    const [ confirmFilterParams, setConfirmFilterParams] = useState<any>({
        startTime: preStartTime,
        endTime: preEndTime,
        userSelectObj: {
            userIds: [],
            organizationIds: []
        }
    });
    const {  width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const domInstance = useRef(null);
    const domInstanceDetail = useRef(null);
    const [mainPointsArr,setMainPointsArr] = useState<any>([]);

    const chartPointClick = (res) => {
        const c = echarts.init(domInstanceDetail.current);
        c.setOption(res);
        setEchartInstanceSecond(c);
        console.log('chartPointClick', detailNodeId);
        setTimeout(()=>{
            console.log('params, 500');
            c?.resize?.();
        }, 500);
    }; 

    useEffect(() => {
        if (echartInstanceFirst) {
            echartInstanceFirst.on('click', (params) => {
                console.log(params, setDetailNodeId);
            });
        }
    }, [echartInstanceFirst]);
    useEffect(() => {
        requestIdleCallback(() => {
            echartInstanceFirst?.resize?.();
        });
    }, [width, height, echartInstanceFirst]);

    const initFirstChart = (res) => {
        const c = echarts.init(domInstance.current);
        c.setOption(res);
        setEchartInstanceFirst(c);
    };
    const summary =  (taskId,startTime, endTime, userSelectObj, echartsType) => {
        const params: any = {
            organizationIds: userSelectObj.organizationIds,
            userIds: userSelectObj.userIds,
            endTime: endTime.split(' ')[0],
            startTime: startTime.split(' ')[0],
        };
        let requestUrlTime = '/intelligentPartnerTaskInfos/result/analysis/trickTime/' + taskId;
        let requestUrlCount = '/intelligentPartnerTaskInfos/result/analysis/trickCount/' + taskId;
        let requestUrl: string = '';
        if (echartsType === 'byTime') {
            requestUrl = requestUrlTime;
        } else if (echartsType === 'byNumber') {
            requestUrl = requestUrlCount;
        }
        console.log('paramsss',params);
        if (!params.startTime || !params.endTime || !requestUrl) return;
        request(requestUrl, params, 'post').then((resp) => {
            const taskTrend = resp.data ?? [];
            const xAxis: string[] = []; // X轴数据
            
            const avgScore: any[]  = []; // 平均分
            const nodeName: string[] = []; // 节点名称
            const nodeId: number[]  = []; // nodeId
            // let pointArr: any[] =  [];

            let markObj = taskTrend[0]['resultAnalysisList'];
            markObj.forEach(item => {

                nodeName.push(item.nodeName ?? '');
                nodeId.push(item.nodeId ?? '');
            });
            setMainPointsArr(markObj);
            taskTrend.forEach((t) => {
                if (echartsType === 'byTime') {
                    xAxis.push(t.datePoint!);
                } else if (echartsType === 'byNumber') {
                    xAxis.push(t.stringCount!);
                }
                let tmpSccore: any[] = [];
                if (t.resultAnalysisList) {
                    t.resultAnalysisList.forEach(item => {
                        // console.log(item);
                        tmpSccore.push(item.avgScore ?? 0);
                    });
                    avgScore.push(tmpSccore);
                }
            });
            initFirstChart(
                getFirstEchartOptions(
                    nodeName, // ['a1','a2']
                    nodeId, // [3101, 3301]
                    avgScore, // [[100, 101], [200, 201], [300, 301]]
                    xAxis // [10,11,12]
                )
            );
        });

    };

    const clickPoint = (nodeId) => {
        if (!nodeId) {
            return;
        }
        setDetailNodeId(nodeId);
        setIsClickPoint(true);
        summaryDetail(taskId,startTime, endTime, userSelectObj, echartsType, nodeId);
    };

    const summaryDetail = (taskId,startTime, endTime, userSelectObj, echartsType, nodeId) => {
        console.log('echartsType',echartsType, nodeId);
    
        const params: any = {
            organizationIds: userSelectObj.organizationIds,
            userIds: userSelectObj.userIds,
            endTime: endTime.split(' ')[0],
            startTime: startTime.split(' ')[0],
            nodeId: nodeId || '',
        };
        let requestUrlTimeDetail = '/intelligentPartnerTaskInfos/result/analysis/trickTimeDetail/' + taskId;
        let requestUrlCountDetail = '/intelligentPartnerTaskInfos/result/analysis/trickCountDetail/' + taskId;
        let requestUrl: string = '';
        if (echartsType === 'byTime') {
            requestUrl = requestUrlTimeDetail;
        } else {
            requestUrl = requestUrlCountDetail;
        }
        console.log('paramsss',params);
        if (!params.startTime || !params.endTime) return;
        request(requestUrl, params, 'post').then((resp) => {
            if (echartsType !== 'barChart') {
                const taskTrend = resp.data ?? [];
                const xAxis: string[] = []; // X轴数据
                
                const avgScore: any[]  = []; // 平均分
                const nodeName: string[] = []; // 节点名称
    
    
                if (resp.data.length > 0) {
                    let markObj = taskTrend[0]['detail'];
                    markObj.forEach(item => {
                        nodeName.push(item.scoreRuleName ?? '');
                    });
                } else {
                    return;
                }
                taskTrend.forEach((t) => {
                    if (echartsType === 'byTime') {
                        xAxis.push(t.datePoint!);
                    } else if (echartsType === 'byNumber') {
                        xAxis.push(t.stringCount!);
                    }
                    let tmpSccore: any[] = [];
                    if (t.detail) {
                        t.detail.forEach(item => {
                            // console.log(item);
                            tmpSccore.push(item.hitRatio ? (item.hitRatio * 100) : 0);
                        });
                        avgScore.push(tmpSccore);
                    }
                });
                chartPointClick(
                    getNumberEchartOptions(
                        nodeName, // ['a1','a2']
                        avgScore, // [[100, 101], [200, 201], [300, 301]]
                        xAxis // [第1次,第2次,第3次]
                    )
                );
            }
        });
    };

    const onBack = () => {
        const routeName = 'coachTaskCenterList';
        Udesk.ui.routing.transitionTo({
            history,
            routeName,
        });
    };

    const reRequest = () => {
        if (!isClickPoint) {
            summary(taskId,startTime, endTime, userSelectObj, echartsType);
        } else {
            summary(taskId,startTime, endTime, userSelectObj, echartsType);
            summaryDetail(taskId,startTime, endTime, userSelectObj, echartsType, detailNodeId);
        }
    };

    const filterConfirm = () => {
        setConfirmFilterParams({
            startTime,
            endTime,
            userSelectObj
        });
        reRequest();
    };
    const filterReset = () => {
        setConfirmFilterParams({
            startTime: preStartTime,
            endTime: preEndTime,
            userSelectObj: {
                userIds: [],
                organizationIds: []
            }
        });
        setUserSelectObj({
            userIds: [],
            organizationIds: []
        });
        datePickerProps.onChange([preStartTime, preEndTime]);
        setTimeout(()=> {
            reRequest();
        }, 800);
    };

    const isPPT = useCallback(
        (wholeCallback, singleCallback, errorCallback?) => {
            // taskInfo?.evaluationMode 等于null:非ppt， 1：整体， 2：单页
            if (taskInfo?.evaluationMode === 1) {
                return wholeCallback.call(taskInfo);
            }
            if (taskInfo?.evaluationMode === 2) {
                return singleCallback.call(taskInfo);
            }
            return (errorCallback || singleCallback).call(taskInfo);
        },
        [taskInfo]
    );

    const onRadioChange = (e) => {
        setEchartsType(e.target.value);
        setIsClickPoint(false);
        if (e.target.value !== 'barChart') {
            // onSortWayChange(activeKey);
            summary(taskId,startTime, endTime, userSelectObj, e.target.value);
            setEchartInstanceSecond(null);
            // setTimeout(()=>{
            //     initGraphChart(); 
            // },800);
        } else {
            setEchartInstanceFirst(null);
            setEchartInstanceSecond(null);
        }
    };
    const treeDataChange = (data) => {
        console.log(data);
        setUserSelectObj(data);
    };

    const init = () => {
        const id = match?.params?.id || '0';
        if (id !== '0') {
            // setLoading(true);
            getIntelligentPartnerTaskInfosById({
                segments: { id },
            })
                .then((resp) => {
                    let values: any = {
                        ...resp?.data,
                    };
                    //画像
                    values.imageIdInfo = {
                        status: 2,
                    };
                    if (values.imageId != null) {
                        values.imageIdInfo.status = 1;
                        values.imageIdInfo.selectedItemId = values.imageId;
                    }
                    // 时长限制
                    values.limitDurationInfo = {
                        status: 1,
                    };
                    if (values.limitDuration != null) {
                        values.limitDurationInfo.status = 2;
                        values.limitDurationInfo.inputValue = values.limitDuration;
                    }
                    // 时间
                    values.taskTimeInfo = {
                        status: 1,
                        viewText:
                            /* 不限时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .index.unlimitedTime,
                    };
                    if (values.taskStartTime && values.taskStartTime) {
                        values.taskTimeInfo.status = 2;
                        values.taskTimeInfo.viewText =
                            moment(values.taskStartTime).format('YYYY-MM-DD HH:mm') +
                            /* 至 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .to +
                            moment(values.taskEndTime).format('YYYY-MM-DD HH:mm');
                    }
                    // 评分标准
                    values.gradingStandartInfo = {
                        commitScore: values.commitScore,
                        excellentScore: values.excellentScore,
                    };
                    // 完成要求
                    values.completeStandartInfo = {};
                    if (values.completeByTimes != null) {
                        values.completeStandartInfo.timesStatus = true;
                        values.completeStandartInfo.completeByTimes = values.completeByTimes;
                    }
                    if (values.completeByScore != null) {
                        values.completeStandartInfo.scoreStatus = true;
                        values.completeStandartInfo.completeByScore = values.completeByScore;
                    }
                    setTaskInfo(values);
                    // getTaskScore(values);
                    // setLoading(false);
                })
                .catch(() => {
                    // setLoading(false);
                });
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Page
            pageBodyClassName="task-center-detail-container"
            backGroundGhost={true}
            onBack={onBack}
        >
            <div className="tcd-header-wrapper">
                    <div className="tcd-header-filter-box" style={{background: '#fff'}}>
                        <div className="tcd-header-filter-box-left">
                            {UdeskLocales['current'].pages.coach.taskCenter.detail.index.staff}：
                            <OrganizationMemberSelect 
                                placeholder={locales.labels.pleaseSelect}
                                width={200}
                                value={userSelectObj}
                                onChange={treeDataChange}
                                treeData={treeData}
                            />
                            &nbsp;
                            <UdeskDatePicker {...datePickerProps} allowClear={false} />
                        </div>
                        <div className="tcd-header-filter-box-right">
                            <Button onClick={filterReset}>{locales.labels.reset}</Button>
                            <Button type="primary" onClick={filterConfirm}>{locales.labels.confirm}</Button>
                        </div>
                    </div>
                {/* <div style={{background: '#fff', marginTop: '10px'}}> */}
                    <div className="udsk-qa-web-practice-summary-title" style={{width: '100%'}}>
                        <Radio.Group
                            defaultValue="barChart"
                            onChange={onRadioChange}
                        >
                            <Radio.Button value="barChart">
                                {
                                    /* 条形图视图 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                    .barPattern
                                }
                            </Radio.Button>
                            <Radio.Button value="byTime">
                                {
                                    /* 时间纬度视图 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                    .timeDimension
                                }
                            </Radio.Button>
                            <Radio.Button value="byNumber">
                                {
                                    /* 次数维度视图 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                    .timesDimension
                                }
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <div style={{background: '#fff', marginTop: '10px', width: '100%'}}>
                    {echartsType === 'barChart' ? (
                        <ResultAnalysis
                            taskId={match?.params?.id}
                            lessonId={taskInfo?.lessonId}
                            isExercise={isExercise(taskInfo?.taskType)}
                            startTime={confirmFilterParams.startTime}
                            endTime={confirmFilterParams.endTime}
                            userSelectObj={confirmFilterParams.userSelectObj}
                            isPPT={isPPT}
                            showBottom={true}
                            history={props.history}
                        />
                    ) : (
                        <div>
                            <div 
                                className="echarts-wrap"
                                ref={domInstance}>
                            </div>
                            <div className="echarts-point-tags" style={{margin: '12px'}}>
                                {mainPointsArr.length > 0 ? (
                                        mainPointsArr.map(item => {
                                        return (
                                            <Tag 
                                                style={{cursor: 'pointer'}}
                                                onClick={()=>{clickPoint(item?.nodeId);}} 
                                                color={detailNodeId === item?.nodeId ? 'success': 'geekblue'}>
                                                {item?.nodeName}
                                            </Tag>
                                        );
                                    })
                                ) : ''}
                            </div>
                            {isClickPoint ? (
                                <div 
                                    className="echarts-wrap"
                                    ref={domInstanceDetail}>
                                </div>
                            ): (' ')}
                        </div>

                    )}
                    </div>
            </div>

        </Page>
    );
};

export default hookProps(TaskCenterDetail);
