import React from 'react';
import Udesk from 'Udesk';
import PropTypes from 'prop-types';
import { getSystemModule } from 'Udesk/system/subApp';

export default class SmartWordsLibraryCommonListComponent extends React.Component {
    static defaultProps = {
        columns: [], //表格字段
        getlistDataPath: '',//访问列表数据,
        getlistDataMethod: 'get',//访问列表数据请求类型,
        openNewModal: null,//打开父组件新增modal,
        uploadModalTitle: '',//组件上传模板title,
        boardName: '',//上传时,所需下载模板的名字
        navItemType: null,// 组件类别:1同义词 2专业名词 3关注词 4忽略词 5词权重 6语料数据
        buttons: ['add', 'import', 'export'],
    };
    static propTypes = {
        columns: PropTypes.array,
        getlistDataPath: PropTypes.string,
        getlistDataMethod: PropTypes.string,
        openNewModal: PropTypes.func,
        uploadModalTitle: PropTypes.string,
        boardName: PropTypes.string,
        navItemType: PropTypes.number,
        buttons: PropTypes.array,
    };
    privates = {
        // model: {
        //     pageNum: Udesk.config.paging.defaultPageNumber,
        //     pageSize: Udesk.config.paging.defaultPageSize
        // },
        isSynonymWordsModalShow: false,
        synonymWordsModalTitle: '',
        uploadModalTitle: '',
        synonymWordsId: '',
        isShowUploadModal: false,
        pageNum: Udesk.config.paging.defaultPageNumber,
        pageSize: Udesk.config.paging.defaultPageSize,
        cacheKey: "",
        keywords: "",
        loading: false,
        columns: [],
    };
    actions = {
        refresh() {

            let {
                total,
            } = this.privates;

            if ((this.privates.pageNum !== 1) && ((this.privates.pageNum - 1) * this.privates.pageSize === (total - 1))) {
                this.privates.pageNum--;
                this.actions.update();
            }

            let {
                pageNum,
                pageSize
            } = this.privates;

            this.actions.reloadModel({
                pageNum,
                pageSize
            });
        },

        openAddModal() {
            this.props.openAddModal();
        },
        showUploadModal() {
            this.privates.isShowUploadModal = true;
            this.privates.uploadModalTitle = this.locales.components.pages.smartWordsLibrary.upload.uploadSynonymWords;
            this.actions.update();
        },

        closeUploadModal() {
            this.privates.isShowUploadModal = false;
            this.actions.update();
        },
        onPageChanged(pageNum, pageSize) {
            this.actions.reloadModel({
                pageNum,
                pageSize
            });
        },
        searchItems(e) {
            if (e.keyCode === 13) {
                this.actions.reloadModel({
                    pageNum: Udesk.config.paging.defaultPageNumber,
                    pageSize: Udesk.config.paging.defaultPageSize,
                });
            }
            return;
        },
        export(){
            let {
                sdkOptions,
            } = this.props;
            let getListUrl = Udesk.business.apiPath.concatApiPath(`/corpora/export/${this.props.navItemType}`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(getListUrl,{systemModule: getSystemModule(), type:1}).then(
                    resp => {
                        resolve(resp);
                        Udesk.ui.notify.success(resp.message);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        this.privates.cacheKey = Udesk.enums.smartWordsLibraryMenus.get(props.navItemType).key;
        let ret = {};
        if(prevProps.columns !== props.columns || !this.privates.columns.length) {            
            ret.columns = props.columns.map(item => ({
                ...item,
                title: item.caption,
                dataIndex: item.name,
                key: item.name,
                render: item.getYieldContent,
            }));
        }
        return ret;
    }
    componentDidMount() {}
    componentWillUnmount() {}
    //#endregion
}