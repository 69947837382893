import Locales from '../locales/index';
import UdeskLocales from 'UdeskLocales';

// let operatorTypes = [
//     { id: 1, key: 'keyword', name: () => Locales.current.enums.operatorTypes.keyword },
//     { id: 2, key: 'question', name: () => Locales.current.enums.operatorTypes.question },
//     { id: 3, key: 'regular', name: () => Locales.current.enums.operatorTypes.regular },
//     { id: 4, key: 'context', name: () => Locales.current.enums.operatorTypes.context },
//     { id: 5, key: 'semantics', name: () => Locales.current.enums.operatorTypes.semantics },
//     { id: 6, key: 'word', name: () => Locales.current.enums.operatorTypes.word },
//     { id: 7, key: 'dialogue', name: () => Locales.current.enums.operatorTypes.dialogue },
//     { id: 8, key: 'speed', name: () => Locales.current.enums.operatorTypes.speed },
//     { id: 9, key: 'grabDetection', name: () => Locales.current.enums.operatorTypes.grabDetection },
//     { id: 10, key: 'semanticTags', name: () => Locales.current.enums.operatorTypes.semanticTags },
// ];

let keywordOperators = [
    { id: 'all', key: 'all', name: () => Locales.current.enums.common.keywordOperators.all },
    {
        id: 'all_not',
        key: 'allNot',
        name: () => Locales.current.enums.common.keywordOperators.allNot,
    },
    { id: 'any', key: 'any', name: () => Locales.current.enums.common.keywordOperators.any },
];

let operatorTypes = [
    { id: 1, key: 'keyword', name: () => Locales.current.enums.common.operatorTypes.keyword },
    {
        id: 11,
        key: 'keywordStrict',
        name: () => Locales.current.enums.common.operatorTypes.keywordStrict,
    },
    { id: 2, key: 'question', name: () => Locales.current.enums.common.operatorTypes.question },
    { id: 3, key: 'regular', name: () => Locales.current.enums.common.operatorTypes.regular },
    { id: 4, key: 'context', name: () => Locales.current.enums.common.operatorTypes.context },
    { id: 5, key: 'semantics', name: () => Locales.current.enums.common.operatorTypes.semantics },
    { id: 6, key: 'word', name: () => Locales.current.enums.common.operatorTypes.word },
    { id: 25, key: 'agentAnswer', name: () => Locales.current.enums.common.operatorTypes.silent },
    { id: 7, key: 'dialogue', name: () => Locales.current.enums.common.operatorTypes.silentOld },
    { id: 8, key: 'speed', name: () => Locales.current.enums.common.operatorTypes.speed },
    {
        id: 18,
        key: 'sentenceLength',
        name: () => Locales.current.enums.common.operatorTypes.sentenceLength,
    },
    {
        id: 9,
        key: 'grabDetection',
        name: () => Locales.current.enums.common.operatorTypes.grabDetection,
    },
    {
        id: 10,
        key: 'semanticTags',
        name: () => Locales.current.enums.common.operatorTypes.semanticTags,
    },
    {
        id: 13,
        key: 'entityInfoDetection',
        name: () => Locales.current.enums.common.operatorTypes.entityInfoDetection,
    },
    // { id: 14, key: 'basicInfoDetection', name: () => Locales.current.enums.common.operatorTypes.basicInfoDetection }
    { id: 15, key: 'volume', name: () => Locales.current.enums.common.operatorTypes.volume },
    { id: 16, key: 'emotion', name: () => Locales.current.enums.common.operatorTypes.emotion },
    { id: 17, key: 'grammar', name: () => Locales.current.enums.common.operatorTypes.grammar },
    {
        id: 20,
        key: 'conceptWord',
        name: () => Locales.current.enums.common.operatorTypes.conceptWord,
    },
    {
        id: 12,
        key: 'questionsAndAnswers',
        name: () => Locales.current.enums.common.operatorTypes.questionsAndAnswers,
    },
    { id: 19, key: 'element', name: () => Locales.current.enums.common.operatorTypes.element },
    // { id: 7, key: 'silent', name: () => Locales.current.enums.common.operatorTypes.silent },
    { id: 21, key: 'associate', name: () => Locales.current.enums.common.operatorTypes.associate },
    { id: 22, key: 'repeat', name: () => Locales.current.enums.common.operatorTypes.repeat },
    {
        id: 23,
        key: 'timeInterval',
        name: () => Locales.current.enums.common.operatorTypes.timeInterval,
    },
    {
        id: 24,
        key: 'typoDetection',
        name: () => Locales.current.enums.common.operatorTypes.typoDetection,
    },
    { id: 26, key: 'knowledge', name: () => Locales.current.enums.common.operatorTypes.knowledge },
    {
        id: 27,
        key: 'wecomRemark',
        name: () => Locales.current.enums.common.operatorTypes.wecomRemark,
    },
    {
        id: 28,
        key: 'customerAddTime',
        name: () => Locales.current.enums.common.operatorTypes.customerAddTime,
    },
    {
        id: 34,
        key: 'businessSummary',
        name: () => /* 业务小结 */ UdeskLocales['current'].enums.operatorListGather.businessSummary,
    },
];

let conceptWordOperators = [
    { id: 'any', key: 'any', name: () => Locales.current.enums.common.keywordOperators.any },
    {
        id: 'all_not',
        key: 'allNot',
        name: () => Locales.current.enums.common.keywordOperators.allNot,
    },
];

let applyRoles = [
    { id: 'all', key: 'all', name: () => Locales.current.enums.applyRoles.all, keywordType: null },
    {
        id: 'customer',
        key: 'customer',
        name: () => Locales.current.enums.applyRoles.customer,
        keywordType: 1,
    },
    {
        id: 'agent',
        key: 'agent',
        name: () => Locales.current.enums.applyRoles.agent,
        keywordType: 0,
    },
];

let applyTimeTypes = [
    {
        id: 1,
        key: 'all',
        name: () => /* 全部时间 */ Locales.current.enums.operatorListGather.allTime,
    },
    {
        id: 2,
        key: 'notWorkTime',
        name: () =>
            /* 非业务工作时间 */ Locales.current.enums.operatorListGather.nonBusinessWorkingHours,
    },
    {
        id: 3,
        key: 'workTime',
        name: () =>
            /* 业务工作时间 */ Locales.current.enums.operatorListGather.businessWorkingHours,
    },
];

let saleApplyRoles = [
    { id: 'all', key: 'all', name: () => Locales.current.enums.saleApplyRoles.all },
    { id: 'customer', key: 'customer', name: () => Locales.current.enums.saleApplyRoles.customer },
    { id: 'agent', key: 'agent', name: () => Locales.current.enums.saleApplyRoles.agent },
];

let applyOperatorScopes = [
    { id: 1, key: 'all', name: () => Locales.current.enums.applyOperatorScopes.all },
    {
        id: 2,
        key: 'preCondition',
        name: () => Locales.current.enums.applyOperatorScopes.preCondition,
    },
    { id: 3, key: 'scope', name: () => Locales.current.enums.applyOperatorScopes.scope },
];

let keywordScopes = [
    { id: 'all', key: 'all', name: () => Locales.current.enums.keywordScopes.all },
    { id: 'any', key: 'any', name: () => Locales.current.enums.keywordScopes.any },
];

let preOperatorHitTypes = [
    { id: 1, key: 'first', name: () => Locales.current.enums.preOperatorHitTypes.first },
    { id: 3, key: 'every', name: () => Locales.current.enums.preOperatorHitTypes.every },
    { id: 2, key: 'last', name: () => Locales.current.enums.preOperatorHitTypes.last },
    { id: 4, key: 'any', name: () => Locales.current.enums.preOperatorHitTypes.any },
    { id: 5, key: 'none', name: () => Locales.current.enums.preOperatorHitTypes.none },
];

const typoDetectionOperators = [
    { id: 1, key: 'greater', name: () => Locales.current.enums.typoDetectionOperators.greater },
    { id: 2, key: 'less', name: () => Locales.current.enums.typoDetectionOperators.less },
];

let applyRolesWithRobot = [
    ...applyRoles,
    {
        id: 'agent',
        key: 'robot',
        name: () => Locales.current.enums.applyRoles.robot,
        keywordType: 2,
    },
];

const responseStart = [
    {
        id: 1,
        key: 'first',
        name: () => /* 首句 */ UdeskLocales['current'].enums.operatorListGather.firstSentence,
    },
    {
        id: 2,
        key: 'last',
        name: () => /* 末句 */ UdeskLocales['current'].enums.operatorListGather.lastSentence,
    },
];

export default {
    keywordOperators,
    operatorTypes,
    conceptWordOperators,
    applyRoles,
    saleApplyRoles,
    applyOperatorScopes,
    keywordScopes,
    preOperatorHitTypes,
    typoDetectionOperators,
    applyRolesWithRobot,
    applyTimeTypes,
    responseStart,
};
