import React, { useEffect, useState } from 'react';
import { DatePicker, Radio } from 'udesk-ui';
import Locales from 'UdeskLocales';
interface PracticeTimeValue {
    status?: number;
    inputValue?: any;
}

interface PracticeTimeProps {
    value?: PracticeTimeValue;
    onChange?: (value?: PracticeTimeValue) => void;
}

const PracticeTimePicker: React.FC<PracticeTimeProps> = (props) => {
    const locales = Locales['current'];
    const { value, onChange } = props;
    const [status, setStatus] = useState(1);
    const [inputValue, setInputValue] = useState(undefined);

    const triggerChange = (changedValue) => {
        const newValue = {
            status,
            inputValue,
            ...changedValue,
        };
        if (newValue.status === 2 && !newValue.inputValue) {
            onChange?.(undefined);
        } else {
            onChange?.(newValue);
        }
    };

    const onStatusChange = (e) => {
        setStatus(e.target.value);
        triggerChange({ status: e.target.value });
    };

    const onInputValueChange = (val) => {
        setInputValue(val);
        triggerChange({ inputValue: val });
    };

    useEffect(() => {
        if (value) {
            setStatus(value?.status || 1);
            setInputValue(value?.inputValue);
        }
    }, [value]);

    return (
        <div>
            <Radio.Group value={value?.status || status} onChange={onStatusChange}>
                <Radio value={1}>{locales.pages.gong.coachTaskCenter.edit.offLimitTime}</Radio>
                <Radio value={2}>{locales.pages.gong.coachTaskCenter.edit.onLimitTime}</Radio>
            </Radio.Group>
            {(value?.status || status) === 2 && (
                <DatePicker.RangePicker
                    style={{ marginTop: 10 }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    allowClear
                    value={value?.inputValue}
                    onChange={onInputValueChange}
                />
            )}
        </div>
    );
};

export default PracticeTimePicker;
