// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostBusinessAnalysisTrendBody,
    BaseResponseBusinessAnalysisTrendFoundResponseDto,
} from '../../types';

/**
 * trend
 *
 * @export
 * @tags business-analysis-controller
 * @link [POST] /businessAnalysis/trend
 */
export function postBusinessAnalysisTrend(
    data: PostBusinessAnalysisTrendBody
): Promise<BaseResponseBusinessAnalysisTrendFoundResponseDto>;
export function postBusinessAnalysisTrend(
    data: PostBusinessAnalysisTrendBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisTrendFoundResponseDto>;
export function postBusinessAnalysisTrend(
    data: PostBusinessAnalysisTrendBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisTrendFoundResponseDto> {
    return request<PostBusinessAnalysisTrendBody, never, never>('/businessAnalysis/trend', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postBusinessAnalysisTrend',
    });
}

export const meta = [
    { tags: ['business-analysis-controller'], path: '/businessAnalysis/trend', method: 'post' },
];
