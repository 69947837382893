import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

class SubRoute extends React.Component {
    render() {
        let {
            route,
            childProps
        } = this.props;

        if (route == null) {
            return (null);
        } else {
            return (
                <Route
                    path={route.path}
                    render={props => (<route.component {...props} route={route} routes={route.routes} {...childProps} />)}
                />
            );
        }
    }
}

SubRoute.propTypes = {
    childProps: PropTypes.object
};

SubRoute.defaultProps = {
    route: null,
    childProps: {}
};

export default SubRoute;