import React, { FC } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';
import styled from 'styled-components';
// import { useScoreDetailContext } from '../Context';
import UdeskLocales from 'UdeskLocales';
import { Tag } from 'udesk-ui';
import { IntelligentPartnerScorePageDetails } from 'src/api/types';

const Title = styled.div`
    position: relative;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center;
    min-height: 60px;
    white-space: nowrap;

    &::after {
        content: '';
        position: absolute;

        top: 68px;
        left: -16px;
        right: -16px;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .task-title {
        margin-right: 12px;
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 16px;
    }
    .task-title-time {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
    }
    .task-title-grade {
        padding-right: 60px;
        font-size: 12px;
        > span {
            position: relative;
            z-index: 1;
        }
        &.standard {
            color: #f09a00;
        }
        &.substandard {
            color: #fb5127;
        }
        &.excellence {
            color: #1a6eff;
        }

        .task-title-score-number {
            font-size: 30px;
            margin: 0 6px;
        }

        img {
            position: absolute;
            top: -16px;
            right: -16px;
        }
    }
`;

const getUrl = (degree) => {
    const type = Udesk.enums.learningTaskScoreType.get(degree);
    if (type) {
        return `/static/images/${type.icon}.svg`;
    }
    return '';
};

type TaskTitleProps = IntelligentPartnerScorePageDetails & {
    showScore?: boolean;
    showResult?: boolean;
};

export const TaskTitle: FC<TaskTitleProps> = React.memo((props) => {
    const {
        taskName,
        time,
        scoreOverView,
        costTime,
        showScore = true,
        showResult = true,
        userName = '',
    } = props || {};

    return (
        <Title>
            <div>
                <div className="task-title">{taskName}</div>
                <div className="task-title-time">
                    <span
                        title={userName}
                        style={{
                            marginRight: 8,
                            display: 'inline-block',
                            maxWidth: 60,
                            verticalAlign: 'bottom',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {userName}
                    </span>
                    {time}
                    {costTime && costTime > 0 && (
                        <Tag color="blue" style={{ marginLeft: 8 }}>
                            {getTotalTime(costTime)}
                        </Tag>
                    )}
                </div>
            </div>

            {scoreOverView?.score !== null &&
                scoreOverView?.score !== undefined &&
                Udesk.data.init.company.id !== 66 && (
                    <div
                        className={`task-title-grade ${
                            showResult
                                ? Udesk.enums.learningTaskScoreType.get(scoreOverView?.degree)?.icon
                                : ''
                        }`}
                    >
                        {showResult ? (
                            <span className="task-title-result">
                                {Udesk.enums.learningTaskScoreType.getName(scoreOverView?.degree)}
                            </span>
                        ) : null}

                        {showScore ? (
                            <span className="task-title-score">
                                <span className="task-title-score-number">
                                    {scoreOverView?.score}
                                </span>
                                {/* 分 */}
                                {
                                    UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                        .components.taskTitle.index.branch
                                }
                            </span>
                        ) : null}

                        {showResult ? (
                            <img
                                src={getUrl(scoreOverView?.degree)}
                                alt={`${scoreOverView?.score}${
                                    /* 分 */ UdeskLocales['current'].pages.coach.learningCenter
                                        .record.detail.components.taskTitle.index.branch
                                }`}
                            />
                        ) : null}
                    </div>
                )}
        </Title>
    );
});

function getTotalTime(practiceCostTime) {
    if (!practiceCostTime) return '-';
    const min = practiceCostTime / 60;
    const hour = min / 60;
    if (hour > 1) return `${hour.toFixed(2)}h`;
    if (min > 1) return `${min.toFixed(2)}min`;
    return `${practiceCostTime}s`;
}
