/*
 * @Author: 唐毓斌
 * @Description: 结果分析
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Empty, Space } from 'udesk-ui';
import Echarts from 'echarts';
import CustomCard from '../../../../components/CutomCard';
import useResizeObserver from 'use-resize-observer';
import './ResultAnalysis.less';
import UdeskLocales from 'UdeskLocales';
import { useRequest } from 'src/util/hook';

const Charts = React.memo((props: any) => {
    const { length, options, onClick, content } = props;
    const chartsRef = useRef<any>(null);
    const devRef = useRef<HTMLDivElement>(null);

    useResizeObserver<HTMLDivElement>({
        ref: content,
        onResize() {
            if (chartsRef.current) {
                chartsRef.current.resize({});
            }
        },
    });

    useEffect(() => {
        if (devRef?.current) {
            chartsRef.current = Echarts.init(devRef.current);
        }
        return () => {
            if (chartsRef.current) {
                chartsRef.current.dispose();
            }
        };
    }, []);

    useEffect(() => {
        if (chartsRef.current) {
            chartsRef.current.clear();
            chartsRef.current.setOption(options);
            chartsRef.current.resize({
                height: ((length || 1) * 2 - 1) * 22 + 56,
            });
        }
    }, [length, options]);

    useEffect(() => {
        if (chartsRef.current) {
            chartsRef.current.off('click');
            chartsRef.current.on('click', onClick);
        }
    }, [onClick]);

    return <div ref={devRef} style={{ width: '100%', flex: 0 }} />;
});

const ResultAnalysis: React.FC<any> = (props) => {
    const { taskId, isPPT, chartType, userSelectObj, showBottom = false } = props;
    const request = useRequest();
    const [avgScoreData, setAvgScoreData] = useState<any[]>();
    const contentRef = useRef<HTMLDivElement>(null);
    const fetchDetailData = useCallback(
        () => {
            let requestApi = '/intelligentPartnerTaskInfos/flow/analysis/reachCount/';
            if (chartType === 'person') {
                requestApi = '/intelligentPartnerTaskInfos/flow/analysis/reachUserCount/';
            }
            let params = {
                ...userSelectObj
            };
            // console.log('api', params);
            request(requestApi + taskId, params, 'post').then((resp) => {
                const avgScoreData = resp.data || [];
                setAvgScoreData(avgScoreData.reverse());
            });
        },
        [taskId, userSelectObj]
    );

    const avgScoreOptions = useMemo(() => {
        return {
            color: ['#dbdcdf', '#0376FB'],
            grid: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0,
                containLabel: true,
            },
            xAxis: {
                show: false,
                type: 'value',
                boundaryGap: [0, 0.01],
                max: function (value) {
                    return avgScoreData?.reduce((p, item) => Math.max(item.recordCount ?? 0, p), 0);
                },
            },
            yAxis: [
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    triggerEvent: true,
                    data: avgScoreData?.map((item) => item.nodeName),
                },
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    position: 'right',
                    data: avgScoreData?.map((item) => {
                        return item.reachCountRate ? (item.reachCountRate * 100).toFixed(2)+'%' : '-';
                    })
                },
            ],
            series: [
                {
                    name: /* 总分 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                        .resultAnalysis.totalScore,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    barGap: '-100%',
                    data: avgScoreData?.map((item) => {
                        return item.recordCount ? item.recordCount: 0;
                    }),
                },
                {
                    name: /* 平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                        .resultAnalysis.average,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    data: avgScoreData?.map((item) => (item.reachCount ? item.reachCount: 0)), //[5, 6, 7, 8, 9, 10],
                    label: {
                        show: true,
                        position: 'insideRight',
                        formatter: ({ dataIndex, data, data2 }) => {
                            let notReachCount = (avgScoreData?.[dataIndex]?.recordCount - avgScoreData?.[dataIndex]?.reachCount);
                            if (chartType === 'person') {
                                notReachCount = (avgScoreData?.[dataIndex]?.recordUserCount - avgScoreData?.[dataIndex]?.reachCount);
                            }
                            let res = (avgScoreData?.[dataIndex]?.reachCount)+'/'+(notReachCount);
                            if ((avgScoreData?.[dataIndex]?.recordCount === 0 || avgScoreData?.[dataIndex]?.recordUserCount === 0 ) && notReachCount === 0 ) {
                                return  ' ';
                            } else {
                                return res;
                            }
                        },
                    },
                },
            ],
        };
    }, [avgScoreData]);

    const onClickPointDetail = useCallback(
        (params) => {
            // console.log('123',params);
            if (params.componentType === 'series') {
                const dataIndex = params.event.target.dataIndex;
                window.open(
                    `/coach/data-analysis/learning-record?taskId=${taskId}&recordIds=${avgScoreData?.[dataIndex]['notReachRecordIds']}`
                );
                
            }
        },
        [avgScoreData,  taskId]
    );

    useEffect(() => {
        fetchDetailData();
    }, [fetchDetailData,userSelectObj]);

    return (
        <Space className="result-analysis-container" direction="vertical" size={'middle'}>
            {showBottom?(
                <div className="result-analysis-content-wrapper">
                    <CustomCard
                        containerStyle={{ flex: 1, marginRight: 10 }}
                        title={
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ fontWeight: 'bold', lineHeight: '30px' }}>
                                    {isPPT(
                                        () =>
                                            /* 话术要点命中率 */ UdeskLocales['current'].pages.coach
                                                .taskCenter.detail.resultAnalysis
                                                .hitRateOfKeyPointsInScript,
                                        () =>
                                            /* 话术平均得分 */ UdeskLocales['current'].pages.coach
                                                .taskCenter.detail.resultAnalysis.averageScriptScore
                                    )}
                                </span>

                                {isPPT(
                                    () => null,
                                    () => (
                                        <>
                                            <div style={{ color: '#00000073', lineHeight: '30px' }}>
                                                {/* {'触达次数/未触达数'} - {'点击查看未触达'} */}
                                                {/* 触达次数 */ UdeskLocales['current'].pages.coach
                                                .taskCenter.detail.index.reachTimes}
                                                /
                                                {/* 未触达数 */ UdeskLocales['current'].pages.coach
                                                .taskCenter.detail.index.outOfReachTimes}
                                                 - 
                                                {/* 点击查看未触达 */ UdeskLocales['current'].pages.coach
                                                .taskCenter.detail.index.seeOutOfReach}
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                        }
                        content={
                            isPPT(
                                () => null,
                                () => avgScoreData?.length
                            ) > 0 ? (
                                <Charts
                                    content={contentRef}
                                    options={isPPT(
                                        () => null,
                                        () => avgScoreOptions
                                    )}
                                    onClick={onClickPointDetail}
                                    length={isPPT(
                                        () => null,
                                        () => avgScoreData?.length,
                                    )}
                                />
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }
                    />
                </div>

            ):''}
            
        </Space>
    );
};

export default ResultAnalysis;
