import React, { FC, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { Button, message, Empty, Spin } from 'udesk-ui';
import styled from 'styled-components';
import { postIntelligentPartnerSmartRemarkQuery } from 'src/api/intelligentPartner/smart/remark/query';
import { postIntelligentPartnerSmartRemarkSubmit } from 'src/api/intelligentPartner/smart/remark/submit';
import UdeskLocales from 'UdeskLocales';

export const RemarkType = {
    Whole: 1,
    Single: 2,
};

const Wrapper = styled.div`
    padding: 16px 0;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
`;

const Title = styled.div`
    font-weight: 600;
`;

const Operation = styled.div``;

const Content = styled.div``;

const Text = styled.div`
    background-color: #f5f6f8;
    padding: 12px;
`;

type TemplateProps = {
    recordId: number;
};

const Template: FC<TemplateProps> = (props) => {
    const { recordId } = props;

    const { content, onGen, queryLoading, genRemarkLoading } = useSpeachRemark({
        recordId,
        remarkType: RemarkType.Whole,
    });

    return (
        <Wrapper>
            <Header>
                <Title>{/* 话术点评与建议 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.evaluationSuggestion.index.scriptCommentsAndSuggestions}</Title>

                <Operation>
                    {content ? (
                        <Button onClick={onGen} size="small" type="link">{/* 重新生成 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.evaluationSuggestion.index.regenerate}</Button>
                    ) : null}
                </Operation>
            </Header>

            <Content>
                <Spin spinning={queryLoading || genRemarkLoading}>
                    {content ? (
                        <Text>{content}</Text>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ flex: 1 }}>
                            <Button type="primary" onClick={onGen} size="small">{/* 生成 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.evaluationSuggestion.index.generate}</Button>
                        </Empty>
                    )}
                </Spin>
            </Content>
        </Wrapper>
    );
};

type UseSpeachRemarkProps = {
    recordId: number;
    remarkType: number;
    sentenceId?: number;
    enableSingle?: boolean;
    enableRealTimeReview?: boolean;
    realTimeReview?: string;
};

export const useSpeachRemark = (props: UseSpeachRemarkProps) => {
    const { recordId, remarkType, sentenceId, enableSingle, enableRealTimeReview, realTimeReview } =
        props;

    const [content, setContent] = useState<string>('');

    const [queryLoading, setQueryLoading] = useState(false);

    const { run: getRemark, cancel: cancelGetRemark } = useRequest(
        (recordId) => postIntelligentPartnerSmartRemarkQuery({ recordId, remarkType, sentenceId }),
        {
            manual: true,
            pollingInterval: 3000,
            onSuccess: (res) => {
                // 1-待执行 2-进行中 3-成功 4-失败
                const data = res.data?.[0];
                if (data?.status === 3 || !data?.status) {
                    setContent(data?.remark || '');
                    cancelGetRemark();
                    setQueryLoading(false);
                } else if (data?.status === 4) {
                    setContent('');
                    cancelGetRemark();
                    setQueryLoading(false);
                    // message.error('生成失败');
                }
            },
            onError: (e) => {
                message.error(e.message || /* 生成失败 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.evaluationSuggestion.index.generationFailed);
                cancelGetRemark();
                setQueryLoading(false);
            },
        }
    );

    useEffect(() => {
        if (remarkType === RemarkType.Whole) {
            getRemark(recordId);
        } else if (remarkType === RemarkType.Single) {
            if (!enableSingle && enableRealTimeReview) {
                setContent(realTimeReview || '');
            } else {
                getRemark(recordId);
            }
        }
    }, [recordId, getRemark, remarkType, enableSingle, enableRealTimeReview, realTimeReview]);

    const { run: genRemark, loading: genRemarkLoading } = useRequest(
        (recordId) => postIntelligentPartnerSmartRemarkSubmit({ recordId, remarkType, sentenceId }),
        {
            manual: true,
            onSuccess: () => {
                setQueryLoading(true);
                getRemark(recordId);
            },
            onError: (e) => {
                message.error(e.message || /* 生成失败 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.evaluationSuggestion.index.generationFailed);
            },
        }
    );

    const onGen = () => {
        genRemark(recordId);
    };

    return {
        content,
        queryLoading,
        genRemarkLoading,
        onGen,
    };
};

export default Template;
