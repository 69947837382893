import React, { useEffect, useRef, useState } from 'react';
// import Locales from 'UdeskLocales';
import styled from 'styled-components';
import { CustomerPortraitItem } from './components/CustomerPortraitItem';
import { useCustomerPortraitConfigContext } from '../Context';
import { AddCustomerPortraitItemBtn } from './components/AddCustomerPortraitItemBtn';
import { IntelligentPartnerCustomerPersonaFoundResponse } from 'src/api/types';

const Div = styled.div`
    > div {
        margin-right: 8px;
        margin-bottom: 8px;
    }
`;

/* cover?: string;

customerTags?: IntelligentPartnerTagGroup[];

desc?: string;

flowId?: number;

id?: number;

name?: string; */
interface CustomerPortraitListProps {
    onChange?: (id: number, selected: boolean) => void;
    value?: Array<number>;
    onView?: ({ id }: { id?: number }) => void;
    allCustomerPortrait: IntelligentPartnerCustomerPersonaFoundResponse[];
    addable?: boolean;
}

const InternalCustomerPortraitList = React.memo<CustomerPortraitListProps>((props) => {
    // const locales = Locales['current'];
    const { onChange, value, onView, allCustomerPortrait, addable = true } = props;
    const wrapDom = useRef(null);

    return (
        <React.Fragment>
            <Div ref={wrapDom}>
                {allCustomerPortrait.map((item) => {
                    return (
                        <CustomerPortraitItem
                            {...item}
                            onClick={onView?.bind(null, item)}
                            onChange={(checked) => {
                                onChange?.(item.id!, checked!);
                            }}
                            selected={value?.includes(item.id!)}
                        />
                    );
                })}
                {addable && <AddCustomerPortraitItemBtn onClick={onView?.bind(null, {})} />}
            </Div>
        </React.Fragment>
    );
});

const useCustomerPortraitList = (needRefresh?) => {
    const { allCustomerPortrait, batchBindFlow, flowId } = useCustomerPortraitConfigContext();
    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    useEffect(() => {
        setSelectedIds(
            allCustomerPortrait.filter((p) => p.bindFlowIds?.includes(flowId)).map((i) => i.id!)
        );
    }, [allCustomerPortrait, flowId, needRefresh]);
    const onSelect = (id, selected) => {
        if (selected) {
            setSelectedIds((selectedIds) => selectedIds.concat([id]));
            // propsOnChange?.(selectedIds.concat([id]));
        } else {
            setSelectedIds((selectedIds) => selectedIds.filter((i) => i !== id));
            // propsOnChange?.(selectedIds.filter((i) => i !== id));
        }
    };
    return {
        onChange: onSelect,
        value: selectedIds,
        allCustomerPortrait,
        batchBindFlow,
    };
};

type CustomerPortraitListType = typeof InternalCustomerPortraitList & {
    useCustomerPortraitList: typeof useCustomerPortraitList;
};

const CustomerPortraitList: CustomerPortraitListType =
    InternalCustomerPortraitList as CustomerPortraitListType;

CustomerPortraitList.useCustomerPortraitList = useCustomerPortraitList;

export { CustomerPortraitList };
