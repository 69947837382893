import React from 'react';
// import Locales from 'UdeskLocales';
// import Udesk from 'Udesk';
import { Space } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const CountWrap = styled.div`
    display: flex;
    .count {
        color: ${(props) => props.color};
    }
`;

export const Count = (props) => {
    // const locales = Locales['current'];

    const { label, count, color } = props;

    return (
        <CountWrap color={color}>
            <div>{label}：</div>
            <Space>
                <div className="count">{count}</div>
                <div>{/* 次 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list.components.tableItem.count.second}</div>
            </Space>
        </CountWrap>
    );
};
