import Udesk from 'Udesk';
import InformationEntitiesComponent from './component';

class InformationEntitiesRoute extends InformationEntitiesComponent {
    asyncModel(changedParams) {
        let that = this;
        let {
            sdkOptions,
            // match
        } = this.props;
        let {
            pageSize,
            pageNum
        } = this.state;
        let {
            moduleType,
            keyword
        } = this.privates;

        let params = {
            pageNum,
            pageSize,
            keyword,
            type: moduleType,
            // systemType: Udesk.data.init.company.systemType
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }

        let url = Udesk.business.apiPath.concatApiPath(`information-entity/list`, sdkOptions);
        this.privates.loading = true;
        this.actions.update();
        return new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                resp => {
                    this.privates.dataSource = resp?.data?.map(i => ({...i, key: i.id}))??[];
                    this.privates.loading = false;
                    this.actions.update();
                    if (resp.paging) {
                        that.setState({
                            pageSize: resp.paging.pageSize,
                            pageNum: resp.paging.pageNum,
                            total: resp.paging.total
                        });
                    }
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );

            //#region Default Props
            // let resp = {
            //     "code": 200,
            //     "message": "OK",
            //     "visible": false,
            //     "paging": {
            //         "pageNum": 1,
            //         "pageSize": 10,
            //         "total": 1
            //     },
            //     "data": [
            //         {
            //             "id": 4,
            //             "remark": "描述update",
            //             "deleteFlag": 0,
            //             "company": {
            //                 "id": 2,
            //                 "deleteFlag": 0
            //             },
            //             "name": "名称update",
            //             "apiName": "user_1_update",
            //             "type": 1,
            //             "logicExp": "1&2",
            //             "activeFlag": 0,
            //             "sentenceList": [
            //                 {
            //                     "content": "第一句update",
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "content": "第二句update",
            //                     "standardFlag": 0
            //                 }
            //             ]
            //         }
            //     ]
            // };
            // if (resp.paging) {
            //     that.setState({
            //         pageSize: resp.paging.pageSize,
            //         pageNum: resp.paging.pageNum,
            //         total: resp.paging.total
            //     });
            // }
            // resolve(resp);
            //#endregion
        });
    }
}

export default InformationEntitiesRoute;
