import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicInput } from '../DynamicInput';
import UdeskLocales from 'UdeskLocales';

const DescriptionWrapper = styled.div`
    padding: 8px 0;
`;
const DescriptionItem = styled.div`
    margin-bottom: 10px;
`;
const DescriptionLabel = styled.div``;
const DescriptionValue = styled.div``;

export type DescValue = {
    communicationTargetList?: string[];
    evaluationStandardList?: string[];
};

type DescriptionProps = {
    value?: DescValue;
    onChange?: (v: DescValue) => void;
};

export const Description: FC<DescriptionProps> = (props) => {
    const { value, onChange } = props;

    const onTargetChange = (v: string[]) => {
        onChange?.({
            ...(value || {}),
            communicationTargetList: v,
        });
    };

    const onStandardsChange = (v: string[]) => {
        onChange?.({
            ...(value || {}),
            evaluationStandardList: v,
        });
    };

    return (
        <DescriptionWrapper>
            <DescriptionItem>
                <DescriptionLabel>
                    {/* 沟通目标 */}
                    {
                        UdeskLocales['current'].components.coach.aiGenDialogFrame.components
                            .description.index.communicationObjectives
                    }
                </DescriptionLabel>

                <DescriptionValue>
                    <DynamicInput
                        value={value?.communicationTargetList}
                        onChange={onTargetChange}
                    />
                </DescriptionValue>
            </DescriptionItem>

            <DescriptionItem>
                <DescriptionLabel>
                    {/* 评分标准 */}
                    {
                        UdeskLocales['current'].components.coach.aiGenDialogFrame.components
                            .description.index.scoringCriteria
                    }
                </DescriptionLabel>

                <DescriptionValue>
                    <DynamicInput
                        value={value?.evaluationStandardList}
                        onChange={onStandardsChange}
                    />
                </DescriptionValue>
            </DescriptionItem>
        </DescriptionWrapper>
    );
};
