import React from 'react';
import Udesk from 'Udesk';
import './style.scss';
// import InputList from 'udesk-react/src/components/input-list';
import InteractiveRecognitionDetailRoute from './route';
// import RadioBtn from 'udesk-react/src/components/radio-btn';
// import ReactSelect from 'udesk-react/src/components/react-select';
// import ReactTree from 'udesk-react/src/components/react-tree';
import InputUseEntity from '../../../../common/input-use-entity';
import InputUseEntityList from '../../../../common/input-use-entity-list';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Input,
    Radio,
    Button,
    Checkbox,
    Select,
    Modal,
    TreeSelect,
    Table,
    PageHeader,
} from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
export default class InteractiveRecognitionDetailTemplate extends InteractiveRecognitionDetailRoute {
    render() {
        let { match } = this.props;
        let { actions, locales, privates } = this;
        let {
            // categoriesVisible,
            category,
            // searchList,
            semanticTypeList,
            talkCheckList,
            detectionTargetList,
            effectivenessList,
            questionTypeList,
            detailType,
            categoriesData,
            foctorsData,
            visibleFoctors,
            confirmLoading,
            columns,
            dataFoctors,
            customFoctorsData,
            modalData,
            actitonsFlag,
            informationEntityshowlist,
            loading,
            disabled,
            editEnable,
            sourceType,
        } = this.privates;
        let { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 3,
                },
            },
        };
        let KeyElementNode = ({ item }) => {
            return (
                <div>
                    <p>
                        {locales.fix.semanticTags}：{item.semanticName || item.title}
                    </p>
                    <p>
                        {locales.fix.standardSentence}：{item.sentence || item.standardContent}
                    </p>
                </div>
            );
        };

        return (
            // <div className="interactive-recognition-detail-page">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    onBack={actions.backToIndex}
                    title={
                        match.params.id
                            ? locales.components.pages.interactiveRecognition.detail.edit
                            : locales.components.pages.interactiveRecognition.detail.new
                    }
                />
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root interactive-recognition-detail-page">
                        <div className="interactive-recognition-detail-page-body qa-react-page-body">
                            {detailType && detailType === 2 && (
                                <div className="interactive-multipple-factors">
                                    <Form {...formItemLayout}>
                                        <Form.Item label={locales.labels.name}>
                                            {getFieldDecorator('name', {
                                                initialValue: foctorsData.name,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: locales.fix.smartUePlaceholder,
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder={locales.fix.smartUePlaceholder}
                                                    style={{ width: '170px' }}
                                                    onChange={actions.inputNameChange}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label={locales.fix.type}>
                                            {getFieldDecorator('type', {
                                                initialValue:
                                                    detailType || foctorsData.type || undefined,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: locales.fix.typeSelectPlaceholder,
                                                    },
                                                ],
                                            })(
                                                <Radio.Group
                                                    onChange={actions.factorsChangeType}
                                                    disabled={match.params.id}
                                                >
                                                    {!_isEmpty(semanticTypeList) &&
                                                        semanticTypeList.map((item) => {
                                                            return (
                                                                <Radio
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.name}
                                                                </Radio>
                                                            );
                                                        })}
                                                </Radio.Group>
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label={locales.fix.keyElement}
                                            extra={
                                                _find(
                                                    foctorsData.details,
                                                    (current) =>
                                                        current.elementParamType === 2 &&
                                                        current.additionalId === 0
                                                ) &&
                                                !_isEmpty(
                                                    _find(
                                                        foctorsData.details,
                                                        (current) =>
                                                            current.elementParamType === 2 &&
                                                            current.additionalId === 0
                                                    ).semanticTagGroup
                                                ) && (
                                                    <KeyElementNode
                                                        item={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 0
                                                            ).semanticTagGroup
                                                        }
                                                    />
                                                )
                                            }
                                        >
                                            <div>
                                                <span>{locales.fix.semanticTags}：</span>
                                                <Button
                                                    style={{ marginLeft: '10px' }}
                                                    onClick={actions.openSmartTools.params(0)}
                                                >
                                                    {_find(
                                                        foctorsData.details,
                                                        (current) =>
                                                            current.elementParamType === 2 &&
                                                            current.additionalId === 0
                                                    ) &&
                                                    _find(
                                                        foctorsData.details,
                                                        (current) =>
                                                            current.elementParamType === 2 &&
                                                            current.additionalId === 0
                                                    ).content
                                                        ? locales.fix.reQuerySementicTags
                                                        : locales.fix.querySementicTags}
                                                </Button>
                                            </div>
                                        </Form.Item>
                                        <Form.Item {...tailFormItemLayout}>
                                            <div>
                                                <span>{locales.fix.informationEntity}：</span>
                                                {getFieldDecorator('key_elements', {
                                                    initialValue:
                                                        (_find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 1 &&
                                                                current.additionalId === 0
                                                        ) && {
                                                            key: _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        1 &&
                                                                    current.additionalId === 0
                                                            ).elementParamId,
                                                            label: _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        1 &&
                                                                    current.additionalId === 0
                                                            ).content,
                                                        }) ||
                                                        undefined,
                                                })(
                                                    // <Input style={{ width: '440px' }} placeholder={locales.fix.enterInformationEntity} onChange={actions.foctorsChange.params(0, 1)} />
                                                    <Select
                                                        showSearch
                                                        allowClear={true}
                                                        placeholder={
                                                            locales.fix.enterInformationEntity
                                                        }
                                                        defaultActiveFirstOption={false}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        onSearch={actions.foctorsChangeSearch}
                                                        onFocus={actions.fctorsFocus.params(
                                                            'key_elements'
                                                        )}
                                                        onChange={actions.foctorsChangSelect.params(
                                                            0,
                                                            1
                                                        )}
                                                        labelInValue={true}
                                                        notFoundContent={null}
                                                        style={{ width: '200px' }}
                                                    >
                                                        {!_isEmpty(informationEntityshowlist) &&
                                                            informationEntityshowlist.map(
                                                                (item) => {
                                                                    return (
                                                                        <Select.Option
                                                                            key={item.id}
                                                                            value={item.id}
                                                                        >
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    );
                                                                }
                                                            )}
                                                    </Select>
                                                )}
                                            </div>
                                        </Form.Item>
                                        <Form.Item label={locales.fix.QATestForm}>
                                            {getFieldDecorator('talk_check', {
                                                initialValue:
                                                    (_find(
                                                        foctorsData.details,
                                                        (current) => current.additionalId === 0
                                                    ) &&
                                                        _find(
                                                            foctorsData.details,
                                                            (current) => current.additionalId === 0
                                                        ).detectType.length > 0 &&
                                                        _find(
                                                            foctorsData.details,
                                                            (current) => current.additionalId === 0
                                                        ).detectType.split(',')) ||
                                                    [],
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: locales.fix.selectNotBeNull,
                                                    },
                                                ],
                                            })(
                                                <Checkbox.Group
                                                    options={talkCheckList}
                                                    onChange={actions.checkboxGroupChange.params(0)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label={locales.fix.featureDetectionTarget}>
                                            {getFieldDecorator('detection_target', {
                                                initialValue: foctorsData.targetType
                                                    ? foctorsData.targetType
                                                    : foctorsData.targetType === 0
                                                    ? 0
                                                    : undefined,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: locales.fix.selectNotBeNull,
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    style={{ width: '120px' }}
                                                    placeholder={locales.labels.pleaseChoice}
                                                    onChange={actions.detectionTarget}
                                                >
                                                    {!_isEmpty(detectionTargetList) &&
                                                        detectionTargetList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    key={item.value}
                                                                    value={item.value}
                                                                >
                                                                    {item.text}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={locales.fix.categorized}>
                                            {getFieldDecorator('classification', {
                                                initialValue: foctorsData.categoryId
                                                    ? foctorsData.categoryId
                                                    : foctorsData.categoryId === 0
                                                    ? 0
                                                    : undefined,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: locales.fix.selectNotBeNull,
                                                    },
                                                ],
                                            })(
                                                <TreeSelect
                                                    style={{ width: '200px' }}
                                                    dropdownStyle={{
                                                        maxHeight: 400,
                                                        overflow: 'auto',
                                                    }}
                                                    placeholder={locales.labels.pleaseChoice}
                                                    treeData={categoriesData}
                                                    treeDefaultExpandAll
                                                    onChange={actions.classTreeChange}
                                                ></TreeSelect>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={locales.fix.effectiveness}>
                                            {getFieldDecorator('effectiveness', {
                                                initialValue: foctorsData.status
                                                    ? foctorsData.status
                                                    : foctorsData.status === 0
                                                    ? 0
                                                    : undefined,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: locales.fix.selectNotBeNull,
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    style={{ width: '120px' }}
                                                    onChange={actions.statusChange}
                                                >
                                                    {!_isEmpty(effectivenessList) &&
                                                        effectivenessList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    key={item.value}
                                                                    value={item.value}
                                                                >
                                                                    {item.text}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <div className="invication-actions-node">
                                            <span onClick={actions.actionsChange}>
                                                {actitonsFlag
                                                    ? locales.fix.hideAdvancedSettings
                                                    : locales.fix.showAdvancedSettings}
                                            </span>
                                        </div>
                                        {actitonsFlag && (
                                            <div>
                                                <Form.Item
                                                    label={locales.fix.generalStatementOnly}
                                                    extra={
                                                        _find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 2 &&
                                                                current.additionalId === 1
                                                        ) &&
                                                        !_isEmpty(
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 1
                                                            ).semanticTagGroup
                                                        ) && (
                                                            <KeyElementNode
                                                                item={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.elementParamType ===
                                                                                2 &&
                                                                            current.additionalId ===
                                                                                1
                                                                    ).semanticTagGroup
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    <Select
                                                        placeholder={locales.labels.pleaseChoice}
                                                        onChange={actions.foctorsSelectChange.params(
                                                            1,
                                                            'general_say'
                                                        )}
                                                        style={{
                                                            width: '120px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 1
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 1
                                                            ).elementParamType
                                                        }
                                                    >
                                                        {!_isEmpty(questionTypeList) &&
                                                            questionTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                    >
                                                                        {item.text}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>

                                                    {getFieldDecorator('general_say', {
                                                        initialValue:
                                                            (_find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 1
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 1
                                                            ).elementParamType === 2
                                                                ? _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 1
                                                                  ).content
                                                                : _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 1
                                                                  ).elementParamType === 1
                                                                ? {
                                                                      key: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              1
                                                                      ).elementParamId,
                                                                      label: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              1
                                                                      ).content,
                                                                  }
                                                                : undefined) || '',
                                                    })(
                                                        <Choose>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            1
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            1
                                                                    ).elementParamType === 2
                                                                }
                                                            >
                                                                <Button
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={actions.openSmartTools.params(
                                                                        1
                                                                    )}
                                                                >
                                                                    {_find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            1
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            1
                                                                    ).elementParamType === 2
                                                                        ? _find(
                                                                              foctorsData.details,
                                                                              (current) =>
                                                                                  current.additionalId ===
                                                                                  1
                                                                          ).content
                                                                            ? locales.fix
                                                                                  .reQuerySementicTags
                                                                            : locales.fix
                                                                                  .querySementicTags
                                                                        : ''}
                                                                </Button>
                                                            </When>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            1
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            1
                                                                    ).elementParamType === 1
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    allowClear={true}
                                                                    placeholder={
                                                                        locales.fix
                                                                            .enterInformationEntity
                                                                    }
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={false}
                                                                    filterOption={false}
                                                                    onSearch={
                                                                        actions.foctorsChangeSearch
                                                                    }
                                                                    onFocus={actions.fctorsFocus.params(
                                                                        'general_say'
                                                                    )}
                                                                    onChange={actions.foctorsChangSelect.params(
                                                                        1,
                                                                        1
                                                                    )}
                                                                    labelInValue={true}
                                                                    notFoundContent={null}
                                                                    style={{ width: '200px' }}
                                                                >
                                                                    {!_isEmpty(
                                                                        informationEntityshowlist
                                                                    ) &&
                                                                        informationEntityshowlist.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {item.name}
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </When>
                                                        </Choose>
                                                    )}
                                                </Form.Item>
                                                <Form.Item
                                                    label={locales.fix.generalQuestions}
                                                    extra={
                                                        _find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 2 &&
                                                                current.additionalId === 2
                                                        ) &&
                                                        !_isEmpty(
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 2
                                                            ).semanticTagGroup
                                                        ) && (
                                                            <KeyElementNode
                                                                item={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.elementParamType ===
                                                                                2 &&
                                                                            current.additionalId ===
                                                                                2
                                                                    ).semanticTagGroup
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    <Select
                                                        placeholder={locales.labels.pleaseChoice}
                                                        onChange={actions.foctorsSelectChange.params(
                                                            2,
                                                            'general_doubt'
                                                        )}
                                                        style={{
                                                            width: '120px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 2
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 2
                                                            ).elementParamType
                                                        }
                                                    >
                                                        {!_isEmpty(questionTypeList) &&
                                                            questionTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                    >
                                                                        {item.text}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                    {getFieldDecorator('general_doubt', {
                                                        initialValue:
                                                            (_find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 2
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 2
                                                            ).elementParamType === 2
                                                                ? _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 2
                                                                  ).content
                                                                : _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 2
                                                                  ).elementParamType === 1
                                                                ? {
                                                                      key: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              2
                                                                      ).elementParamId,
                                                                      label: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              2
                                                                      ).content,
                                                                  }
                                                                : undefined) || '',
                                                    })(
                                                        <Choose>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            2
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            2
                                                                    ).elementParamType === 2
                                                                }
                                                            >
                                                                {/* <Input style={{ width: '440px' }} placeholder={locales.fix.sementicTagsPlaceholder} onChange={actions.foctorsInputChange.params(2)} /> */}
                                                                <Button
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={actions.openSmartTools.params(
                                                                        2
                                                                    )}
                                                                >
                                                                    {_find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            2
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            2
                                                                    ).elementParamType === 2
                                                                        ? _find(
                                                                              foctorsData.details,
                                                                              (current) =>
                                                                                  current.additionalId ===
                                                                                  2
                                                                          ).content
                                                                            ? locales.fix
                                                                                  .reQuerySementicTags
                                                                            : locales.fix
                                                                                  .querySementicTags
                                                                        : ''}
                                                                </Button>
                                                            </When>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            2
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            2
                                                                    ).elementParamType === 1
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    allowClear={true}
                                                                    placeholder={
                                                                        locales.fix
                                                                            .enterInformationEntity
                                                                    }
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={false}
                                                                    filterOption={false}
                                                                    onSearch={
                                                                        actions.foctorsChangeSearch
                                                                    }
                                                                    onFocus={actions.fctorsFocus.params(
                                                                        'general_doubt'
                                                                    )}
                                                                    onChange={actions.foctorsChangSelect.params(
                                                                        2,
                                                                        1
                                                                    )}
                                                                    labelInValue={true}
                                                                    notFoundContent={null}
                                                                    style={{ width: '200px' }}
                                                                >
                                                                    {!_isEmpty(
                                                                        informationEntityshowlist
                                                                    ) &&
                                                                        informationEntityshowlist.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {item.name}
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </When>
                                                        </Choose>
                                                    )}
                                                </Form.Item>
                                                <Form.Item
                                                    label={locales.fix.affirmativeExpression}
                                                    extra={
                                                        _find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 2 &&
                                                                current.additionalId === 3
                                                        ) &&
                                                        !_isEmpty(
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 3
                                                            ).semanticTagGroup
                                                        ) && (
                                                            <KeyElementNode
                                                                item={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.elementParamType ===
                                                                                2 &&
                                                                            current.additionalId ===
                                                                                3
                                                                    ).semanticTagGroup
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    <Select
                                                        placeholder={locales.labels.pleaseChoice}
                                                        onChange={actions.foctorsSelectChange.params(
                                                            3,
                                                            'define_deliver_ok'
                                                        )}
                                                        style={{
                                                            width: '120px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 2
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 2
                                                            ).elementParamType
                                                        }
                                                    >
                                                        {!_isEmpty(questionTypeList) &&
                                                            questionTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                    >
                                                                        {item.text}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                    {getFieldDecorator('define_deliver_ok', {
                                                        initialValue:
                                                            (_find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 3
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 3
                                                            ).elementParamType === 2
                                                                ? _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 3
                                                                  ).content
                                                                : _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 3
                                                                  ).elementParamType === 1
                                                                ? {
                                                                      key: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              3
                                                                      ).elementParamId,
                                                                      label: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              3
                                                                      ).content,
                                                                  }
                                                                : undefined) || '',
                                                    })(
                                                        <Choose>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            3
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            3
                                                                    ).elementParamType === 2
                                                                }
                                                            >
                                                                <Button
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={actions.openSmartTools.params(
                                                                        3
                                                                    )}
                                                                >
                                                                    {_find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            3
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            3
                                                                    ).elementParamType === 2
                                                                        ? _find(
                                                                              foctorsData.details,
                                                                              (current) =>
                                                                                  current.additionalId ===
                                                                                  3
                                                                          ).content
                                                                            ? locales.fix
                                                                                  .reQuerySementicTags
                                                                            : locales.fix
                                                                                  .querySementicTags
                                                                        : ''}
                                                                </Button>
                                                            </When>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            3
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            3
                                                                    ).elementParamType === 1
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    allowClear={true}
                                                                    placeholder={
                                                                        locales.fix
                                                                            .enterInformationEntity
                                                                    }
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={false}
                                                                    filterOption={false}
                                                                    onSearch={
                                                                        actions.foctorsChangeSearch
                                                                    }
                                                                    onFocus={actions.fctorsFocus.params(
                                                                        'define_deliver_ok'
                                                                    )}
                                                                    onChange={actions.foctorsChangSelect.params(
                                                                        3,
                                                                        1
                                                                    )}
                                                                    labelInValue={true}
                                                                    notFoundContent={null}
                                                                    style={{ width: '200px' }}
                                                                >
                                                                    {!_isEmpty(
                                                                        informationEntityshowlist
                                                                    ) &&
                                                                        informationEntityshowlist.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {item.name}
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </When>
                                                        </Choose>
                                                    )}
                                                </Form.Item>
                                                <Form.Item
                                                    label={locales.fix.negativeIntention}
                                                    extra={
                                                        _find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 2 &&
                                                                current.additionalId === 4
                                                        ) &&
                                                        !_isEmpty(
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 4
                                                            ).semanticTagGroup
                                                        ) && (
                                                            <KeyElementNode
                                                                item={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.elementParamType ===
                                                                                2 &&
                                                                            current.additionalId ===
                                                                                4
                                                                    ).semanticTagGroup
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    <Select
                                                        placeholder={locales.labels.pleaseChoice}
                                                        onChange={actions.foctorsSelectChange.params(
                                                            4,
                                                            'define_deliver_no'
                                                        )}
                                                        style={{
                                                            width: '120px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 4
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 4
                                                            ).elementParamType
                                                        }
                                                    >
                                                        {!_isEmpty(questionTypeList) &&
                                                            questionTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                    >
                                                                        {item.text}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                    {getFieldDecorator('define_deliver_no', {
                                                        initialValue:
                                                            (_find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 4
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 4
                                                            ).elementParamType === 2
                                                                ? _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 4
                                                                  ).content
                                                                : _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 4
                                                                  ).elementParamType === 1
                                                                ? {
                                                                      key: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              4
                                                                      ).elementParamId,
                                                                      label: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              4
                                                                      ).content,
                                                                  }
                                                                : undefined) || '',
                                                    })(
                                                        <Choose>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            4
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            4
                                                                    ).elementParamType === 2
                                                                }
                                                            >
                                                                <Button
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={actions.openSmartTools.params(
                                                                        4
                                                                    )}
                                                                >
                                                                    {_find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            4
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            4
                                                                    ).elementParamType === 2
                                                                        ? _find(
                                                                              foctorsData.details,
                                                                              (current) =>
                                                                                  current.additionalId ===
                                                                                  4
                                                                          ).content
                                                                            ? locales.fix
                                                                                  .reQuerySementicTags
                                                                            : locales.fix
                                                                                  .querySementicTags
                                                                        : ''}
                                                                </Button>
                                                            </When>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            4
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            4
                                                                    ).elementParamType === 1
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    allowClear={true}
                                                                    placeholder={
                                                                        locales.fix
                                                                            .enterInformationEntity
                                                                    }
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={false}
                                                                    filterOption={false}
                                                                    onSearch={
                                                                        actions.foctorsChangeSearch
                                                                    }
                                                                    onFocus={actions.fctorsFocus.params(
                                                                        'define_deliver_no'
                                                                    )}
                                                                    onChange={actions.foctorsChangSelect.params(
                                                                        4,
                                                                        1
                                                                    )}
                                                                    labelInValue={true}
                                                                    notFoundContent={null}
                                                                    style={{ width: '200px' }}
                                                                >
                                                                    {!_isEmpty(
                                                                        informationEntityshowlist
                                                                    ) &&
                                                                        informationEntityshowlist.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {item.name}
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </When>
                                                        </Choose>
                                                    )}
                                                    {/* {
                                                                _find(foctorsData.details, current => current.additionalId === 4) && _find(foctorsData.details, current => current.additionalId === 4).elementParamType === 2 && (
                                                                    <Button style={{ marginLeft: '10px' }} onClick={actions.openSmartTools.params(4)}>{locales.fix.searchSementicTags}</Button>
                                                                )
                                                            } */}
                                                </Form.Item>
                                                <Form.Item
                                                    label={locales.fix.choiceQuestion}
                                                    extra={
                                                        _find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 2 &&
                                                                current.additionalId === 5
                                                        ) &&
                                                        !_isEmpty(
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 5
                                                            ).semanticTagGroup
                                                        ) && (
                                                            <KeyElementNode
                                                                item={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.elementParamType ===
                                                                                2 &&
                                                                            current.additionalId ===
                                                                                5
                                                                    ).semanticTagGroup
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    <Select
                                                        placeholder={locales.labels.pleaseChoice}
                                                        onChange={actions.foctorsSelectChange.params(
                                                            5,
                                                            'choice_doubt'
                                                        )}
                                                        style={{
                                                            width: '120px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 5
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 5
                                                            ).elementParamType
                                                        }
                                                    >
                                                        {!_isEmpty(questionTypeList) &&
                                                            questionTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                    >
                                                                        {item.text}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                    {getFieldDecorator('choice_doubt', {
                                                        initialValue:
                                                            (_find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 5
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 5
                                                            ).elementParamType === 2
                                                                ? _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 5
                                                                  ).content
                                                                : _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 5
                                                                  ).elementParamType === 1
                                                                ? {
                                                                      key: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              5
                                                                      ).elementParamId,
                                                                      label: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              5
                                                                      ).content,
                                                                  }
                                                                : undefined) || '',
                                                    })(
                                                        <Choose>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            5
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            5
                                                                    ).elementParamType === 2
                                                                }
                                                            >
                                                                {/* <Input style={{ width: '440px' }} placeholder={locales.fix.sementicTagsPlaceholder} onChange={actions.foctorsInputChange.params(5)} /> */}
                                                                <Button
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={actions.openSmartTools.params(
                                                                        5
                                                                    )}
                                                                >
                                                                    {_find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            5
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            5
                                                                    ).elementParamType === 2
                                                                        ? _find(
                                                                              foctorsData.details,
                                                                              (current) =>
                                                                                  current.additionalId ===
                                                                                  5
                                                                          ).content
                                                                            ? locales.fix
                                                                                  .reQuerySementicTags
                                                                            : locales.fix
                                                                                  .querySementicTags
                                                                        : ''}
                                                                </Button>
                                                            </When>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            5
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            5
                                                                    ).elementParamType === 1
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    allowClear={true}
                                                                    placeholder={
                                                                        locales.fix
                                                                            .enterInformationEntity
                                                                    }
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={false}
                                                                    filterOption={false}
                                                                    onSearch={
                                                                        actions.foctorsChangeSearch
                                                                    }
                                                                    onFocus={actions.fctorsFocus.params(
                                                                        'choice_doubt'
                                                                    )}
                                                                    onChange={actions.foctorsChangSelect.params(
                                                                        5,
                                                                        1
                                                                    )}
                                                                    labelInValue={true}
                                                                    notFoundContent={null}
                                                                    style={{ width: '200px' }}
                                                                >
                                                                    {!_isEmpty(
                                                                        informationEntityshowlist
                                                                    ) &&
                                                                        informationEntityshowlist.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        key={
                                                                                            item.id
                                                                                        }
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {item.name}
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </When>
                                                        </Choose>
                                                    )}
                                                    {/* {
                                                                _find(foctorsData.details, current => current.additionalId === 5) && _find(foctorsData.details, current => current.additionalId === 5).elementParamType === 2 && (
                                                                    <Button style={{ marginLeft: '10px' }} onClick={actions.openSmartTools.params(5)}>{locales.fix.searchSementicTags}</Button>
                                                                )
                                                            } */}
                                                </Form.Item>
                                                <Form.Item
                                                    label={locales.fix.selectiveAnswer}
                                                    extra={
                                                        _find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 2 &&
                                                                current.additionalId === 6
                                                        ) &&
                                                        !_isEmpty(
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 6
                                                            ).semanticTagGroup
                                                        ) && (
                                                            <KeyElementNode
                                                                item={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.elementParamType ===
                                                                                2 &&
                                                                            current.additionalId ===
                                                                                6
                                                                    ).semanticTagGroup
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    <Select
                                                        placeholder={locales.labels.pleaseChoice}
                                                        onChange={actions.foctorsSelectChange.params(
                                                            6,
                                                            'choice_answer'
                                                        )}
                                                        style={{
                                                            width: '120px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 6
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 6
                                                            ).elementParamType
                                                        }
                                                    >
                                                        {!_isEmpty(questionTypeList) &&
                                                            questionTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                    >
                                                                        {item.text}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                    {getFieldDecorator('choice_answer', {
                                                        initialValue:
                                                            (_find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 6
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 6
                                                            ).elementParamType === 2
                                                                ? _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 6
                                                                  ).content
                                                                : _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 6
                                                                  ).elementParamType === 1
                                                                ? {
                                                                      key: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              6
                                                                      ).elementParamId,
                                                                      label: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              6
                                                                      ).content,
                                                                  }
                                                                : undefined) || '',
                                                    })(
                                                        <Choose>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            6
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            6
                                                                    ).elementParamType === 2
                                                                }
                                                            >
                                                                {/* <Input style={{ width: '440px' }} placeholder={locales.fix.sementicTagsPlaceholder} onChange={actions.foctorsInputChange.params(6)} /> */}
                                                                <Button
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={actions.openSmartTools.params(
                                                                        6
                                                                    )}
                                                                >
                                                                    {_find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            6
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            6
                                                                    ).elementParamType === 2
                                                                        ? _find(
                                                                              foctorsData.details,
                                                                              (current) =>
                                                                                  current.additionalId ===
                                                                                  6
                                                                          ).content
                                                                            ? locales.fix
                                                                                  .reQuerySementicTags
                                                                            : locales.fix
                                                                                  .querySementicTags
                                                                        : ''}
                                                                </Button>
                                                            </When>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            6
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            6
                                                                    ).elementParamType === 1
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    allowClear={true}
                                                                    placeholder={
                                                                        locales.fix
                                                                            .enterInformationEntity
                                                                    }
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={false}
                                                                    filterOption={false}
                                                                    onSearch={
                                                                        actions.foctorsChangeSearch
                                                                    }
                                                                    onFocus={actions.fctorsFocus.params(
                                                                        'choice_answer'
                                                                    )}
                                                                    onChange={actions.foctorsChangSelect.params(
                                                                        6,
                                                                        1
                                                                    )}
                                                                    labelInValue={true}
                                                                    notFoundContent={null}
                                                                    style={{ width: '200px' }}
                                                                >
                                                                    {!_isEmpty(
                                                                        informationEntityshowlist
                                                                    ) &&
                                                                        informationEntityshowlist.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        key={
                                                                                            item.id
                                                                                        }
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {item.name}
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </When>
                                                        </Choose>
                                                    )}
                                                    {/* {
                                                                _find(foctorsData.details, current => current.additionalId === 6) && _find(foctorsData.details, current => current.additionalId === 6).elementParamType === 2 && (
                                                                    <Button style={{ marginLeft: '10px' }} onClick={actions.openSmartTools.params(6)}>{locales.fix.searchSementicTags}</Button>
                                                                )
                                                            } */}
                                                </Form.Item>
                                                <Form.Item
                                                    label={locales.fix.openSpecialQuestion}
                                                    extra={
                                                        _find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 2 &&
                                                                current.additionalId === 7
                                                        ) &&
                                                        !_isEmpty(
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 7
                                                            ).semanticTagGroup
                                                        ) && (
                                                            <KeyElementNode
                                                                item={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.elementParamType ===
                                                                                2 &&
                                                                            current.additionalId ===
                                                                                7
                                                                    ).semanticTagGroup
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    <Select
                                                        placeholder={locales.labels.pleaseChoice}
                                                        onChange={actions.foctorsSelectChange.params(
                                                            7,
                                                            'to_choice_doubt'
                                                        )}
                                                        style={{
                                                            width: '120px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 7
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 7
                                                            ).elementParamType
                                                        }
                                                    >
                                                        {!_isEmpty(questionTypeList) &&
                                                            questionTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                    >
                                                                        {item.text}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                    {getFieldDecorator('to_choice_doubt', {
                                                        initialValue:
                                                            (_find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 7
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 7
                                                            ).elementParamType === 2
                                                                ? _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 7
                                                                  ).content
                                                                : _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 7
                                                                  ).elementParamType === 1
                                                                ? {
                                                                      key: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              7
                                                                      ).elementParamId,
                                                                      label: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              7
                                                                      ).content,
                                                                  }
                                                                : undefined) || '',
                                                    })(
                                                        <Choose>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            7
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            7
                                                                    ).elementParamType === 2
                                                                }
                                                            >
                                                                {/* <Input style={{ width: '440px' }} placeholder={locales.fix.sementicTagsPlaceholder} onChange={actions.foctorsInputChange.params(7)} /> */}
                                                                <Button
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={actions.openSmartTools.params(
                                                                        7
                                                                    )}
                                                                >
                                                                    {_find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            7
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            7
                                                                    ).elementParamType === 2
                                                                        ? _find(
                                                                              foctorsData.details,
                                                                              (current) =>
                                                                                  current.additionalId ===
                                                                                  7
                                                                          ).content
                                                                            ? locales.fix
                                                                                  .reQuerySementicTags
                                                                            : locales.fix
                                                                                  .querySementicTags
                                                                        : ''}
                                                                </Button>
                                                            </When>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            7
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            7
                                                                    ).elementParamType === 1
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    allowClear={true}
                                                                    placeholder={
                                                                        locales.fix
                                                                            .enterInformationEntity
                                                                    }
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={false}
                                                                    filterOption={false}
                                                                    onSearch={
                                                                        actions.foctorsChangeSearch
                                                                    }
                                                                    onFocus={actions.fctorsFocus.params(
                                                                        'to_choice_doubt'
                                                                    )}
                                                                    onChange={actions.foctorsChangSelect.params(
                                                                        7,
                                                                        1
                                                                    )}
                                                                    labelInValue={true}
                                                                    notFoundContent={null}
                                                                    style={{ width: '200px' }}
                                                                >
                                                                    {!_isEmpty(
                                                                        informationEntityshowlist
                                                                    ) &&
                                                                        informationEntityshowlist.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        key={
                                                                                            item.id
                                                                                        }
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {item.name}
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </When>
                                                        </Choose>
                                                    )}
                                                    {/* {
                                                                _find(foctorsData.details, current => current.additionalId === 7) && _find(foctorsData.details, current => current.additionalId === 7).elementParamType === 2 && (
                                                                    <Button style={{ marginLeft: '10px' }} onClick={actions.openSmartTools.params(7)}>{locales.fix.searchSementicTags}</Button>
                                                                )
                                                            } */}
                                                </Form.Item>
                                                <Form.Item
                                                    label={locales.fix.openAnswer}
                                                    extra={
                                                        _find(
                                                            foctorsData.details,
                                                            (current) =>
                                                                current.elementParamType === 2 &&
                                                                current.additionalId === 8
                                                        ) &&
                                                        !_isEmpty(
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.elementParamType ===
                                                                        2 &&
                                                                    current.additionalId === 8
                                                            ).semanticTagGroup
                                                        ) && (
                                                            <KeyElementNode
                                                                item={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.elementParamType ===
                                                                                2 &&
                                                                            current.additionalId ===
                                                                                8
                                                                    ).semanticTagGroup
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    <Select
                                                        placeholder={locales.labels.pleaseChoice}
                                                        onChange={actions.foctorsSelectChange.params(
                                                            8,
                                                            'to_choice_answer'
                                                        )}
                                                        style={{
                                                            width: '120px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 8
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 8
                                                            ).elementParamType
                                                        }
                                                    >
                                                        {!_isEmpty(questionTypeList) &&
                                                            questionTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                    >
                                                                        {item.text}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                    {getFieldDecorator('to_choice_answer', {
                                                        initialValue:
                                                            (_find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 8
                                                            ) &&
                                                            _find(
                                                                foctorsData.details,
                                                                (current) =>
                                                                    current.additionalId === 8
                                                            ).elementParamType === 2
                                                                ? _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 8
                                                                  ).content
                                                                : _find(
                                                                      foctorsData.details,
                                                                      (current) =>
                                                                          current.additionalId === 8
                                                                  ).elementParamType === 1
                                                                ? {
                                                                      key: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              8
                                                                      ).elementParamId,
                                                                      label: _find(
                                                                          foctorsData.details,
                                                                          (current) =>
                                                                              current.additionalId ===
                                                                              8
                                                                      ).content,
                                                                  }
                                                                : undefined) || '',
                                                    })(
                                                        <Choose>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            8
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            8
                                                                    ).elementParamType === 2
                                                                }
                                                            >
                                                                {/* <Input style={{ width: '440px' }} placeholder={locales.fix.sementicTagsPlaceholder} onChange={actions.foctorsInputChange.params(8)} /> */}
                                                                <Button
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={actions.openSmartTools.params(
                                                                        8
                                                                    )}
                                                                >
                                                                    {_find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            8
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            8
                                                                    ).elementParamType === 2
                                                                        ? _find(
                                                                              foctorsData.details,
                                                                              (current) =>
                                                                                  current.additionalId ===
                                                                                  8
                                                                          ).content
                                                                            ? locales.fix
                                                                                  .reQuerySementicTags
                                                                            : locales.fix
                                                                                  .querySementicTags
                                                                        : ''}
                                                                </Button>
                                                            </When>
                                                            <When
                                                                condition={
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            8
                                                                    ) &&
                                                                    _find(
                                                                        foctorsData.details,
                                                                        (current) =>
                                                                            current.additionalId ===
                                                                            8
                                                                    ).elementParamType === 1
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    allowClear={true}
                                                                    placeholder={
                                                                        locales.fix
                                                                            .enterInformationEntity
                                                                    }
                                                                    defaultActiveFirstOption={false}
                                                                    showArrow={false}
                                                                    filterOption={false}
                                                                    onSearch={
                                                                        actions.foctorsChangeSearch
                                                                    }
                                                                    onFocus={actions.fctorsFocus.params(
                                                                        'to_choice_answer'
                                                                    )}
                                                                    onChange={actions.foctorsChangSelect.params(
                                                                        8,
                                                                        1
                                                                    )}
                                                                    labelInValue={true}
                                                                    notFoundContent={null}
                                                                    style={{ width: '200px' }}
                                                                >
                                                                    {!_isEmpty(
                                                                        informationEntityshowlist
                                                                    ) &&
                                                                        informationEntityshowlist.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        key={
                                                                                            item.id
                                                                                        }
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                    >
                                                                                        {item.name}
                                                                                    </Select.Option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </When>
                                                        </Choose>
                                                    )}
                                                    {/* {
                                                                _find(foctorsData.details, current => current.additionalId === 8) && _find(foctorsData.details, current => current.additionalId === 8).elementParamType === 2 && (
                                                                    <Button style={{ marginLeft: '10px' }} onClick={actions.openSmartTools.params(8)}>{locales.fix.searchSementicTags}</Button>
                                                                )
                                                            } */}
                                                </Form.Item>
                                                <Form.Item label={locales.fix.customQA}>
                                                    <Button onClick={actions.customAdd}>
                                                        {locales.labels.increase}
                                                    </Button>
                                                </Form.Item>
                                                {!_isEmpty(customFoctorsData) &&
                                                    customFoctorsData.map((item) => {
                                                        return (
                                                            <div
                                                                key={item.key}
                                                                className="interactive-custom-doute-answer"
                                                            >
                                                                <Form.Item
                                                                    {...tailFormItemLayout}
                                                                    extra={
                                                                        item.question
                                                                            .elementParamType ===
                                                                            2 &&
                                                                        !_isEmpty(
                                                                            item.question
                                                                                .semanticTagGroup
                                                                        ) && (
                                                                            <KeyElementNode
                                                                                item={
                                                                                    item.question
                                                                                        .semanticTagGroup
                                                                                }
                                                                            />
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="interactive-custom-doute-answer-field">
                                                                        <span>
                                                                            {locales.fix.questions}
                                                                            ：
                                                                        </span>
                                                                        <Select
                                                                            placeholder={
                                                                                locales.labels
                                                                                    .pleaseChoice
                                                                            }
                                                                            onChange={actions.customFoctorsSelectChange.params(
                                                                                item.key,
                                                                                'question',
                                                                                `key_elements-${item.key}-1`
                                                                            )}
                                                                            style={{
                                                                                width: '120px',
                                                                                marginRight: '10px',
                                                                            }}
                                                                            defaultValue={
                                                                                item.question &&
                                                                                item.question
                                                                                    .elementParamType
                                                                            }
                                                                        >
                                                                            {!_isEmpty(
                                                                                questionTypeList
                                                                            ) &&
                                                                                questionTypeList.map(
                                                                                    (item) => {
                                                                                        return (
                                                                                            <Select.Option
                                                                                                key={
                                                                                                    item.value
                                                                                                }
                                                                                                value={
                                                                                                    item.value
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    item.text
                                                                                                }
                                                                                            </Select.Option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </Select>
                                                                        {getFieldDecorator(
                                                                            `key_elements-${item.key}-1`,
                                                                            {
                                                                                initialValue:
                                                                                    item.question
                                                                                        .elementParamType ===
                                                                                    2
                                                                                        ? item
                                                                                              .question
                                                                                              .content ||
                                                                                          ''
                                                                                        : item
                                                                                              .question
                                                                                              .elementParamType ===
                                                                                          1
                                                                                        ? {
                                                                                              key: item
                                                                                                  .question
                                                                                                  .elementParamId,
                                                                                              label: item
                                                                                                  .question
                                                                                                  .content,
                                                                                          }
                                                                                        : undefined,
                                                                                rules:
                                                                                    item.answer
                                                                                        .elementParamType ===
                                                                                    1
                                                                                        ? [
                                                                                              {
                                                                                                  required: true,
                                                                                                  message:
                                                                                                      locales
                                                                                                          .fix
                                                                                                          .enterNotBeNull,
                                                                                              },
                                                                                          ]
                                                                                        : [],
                                                                            }
                                                                        )(
                                                                            <Choose>
                                                                                <When
                                                                                    condition={
                                                                                        item
                                                                                            .question
                                                                                            .elementParamType ===
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    <Button
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                '10px',
                                                                                        }}
                                                                                        onClick={actions.openSmartTools.params(
                                                                                            item.key,
                                                                                            'question'
                                                                                        )}
                                                                                    >
                                                                                        {item
                                                                                            .question
                                                                                            .elementParamType ===
                                                                                            2 &&
                                                                                        item
                                                                                            .question
                                                                                            .content
                                                                                            ? locales
                                                                                                  .fix
                                                                                                  .reQuerySementicTags
                                                                                            : locales
                                                                                                  .fix
                                                                                                  .querySementicTags}
                                                                                    </Button>
                                                                                    {/* <Input style={{ width: '440px' }} placeholder={locales.fix.dataPlaceholder} onChange={actions.customInputChange.params(item.key, 'question')} /> */}
                                                                                </When>
                                                                                <When
                                                                                    condition={
                                                                                        item
                                                                                            .question
                                                                                            .elementParamType ===
                                                                                        1
                                                                                    }
                                                                                >
                                                                                    <Select
                                                                                        showSearch
                                                                                        allowClear={
                                                                                            true
                                                                                        }
                                                                                        placeholder={
                                                                                            locales
                                                                                                .fix
                                                                                                .enterInformationEntity
                                                                                        }
                                                                                        defaultActiveFirstOption={
                                                                                            false
                                                                                        }
                                                                                        showArrow={
                                                                                            false
                                                                                        }
                                                                                        filterOption={
                                                                                            false
                                                                                        }
                                                                                        onSearch={
                                                                                            actions.foctorsChangeSearch
                                                                                        }
                                                                                        onFocus={actions.fctorsFocus.params(
                                                                                            `key_elements-${item.key}-1`
                                                                                        )}
                                                                                        onChange={actions.foctorsChangSelectCustome.params(
                                                                                            item.key,
                                                                                            'question'
                                                                                        )}
                                                                                        labelInValue={
                                                                                            true
                                                                                        }
                                                                                        notFoundContent={
                                                                                            null
                                                                                        }
                                                                                        style={{
                                                                                            width: '200px',
                                                                                        }}
                                                                                    >
                                                                                        {!_isEmpty(
                                                                                            informationEntityshowlist
                                                                                        ) &&
                                                                                            informationEntityshowlist.map(
                                                                                                (
                                                                                                    item
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <Select.Option
                                                                                                            key={
                                                                                                                item.id
                                                                                                            }
                                                                                                            value={
                                                                                                                item.id
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                item.name
                                                                                                            }
                                                                                                        </Select.Option>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </Select>
                                                                                </When>
                                                                            </Choose>
                                                                        )}
                                                                        {/* {
                                                                                    item.question && item.question.elementParamType === 2 && (
                                                                                        <Button style={{ marginLeft: '10px' }} onClick={actions.openSmartTools.params(item.key, 'question')}>{locales.fix.searchSementicTags}</Button>
                                                                                    )
                                                                                } */}
                                                                        {item.question &&
                                                                            item.answer &&
                                                                            item.question
                                                                                .elementParamType ===
                                                                                1 &&
                                                                            item.answer
                                                                                .elementParamType ===
                                                                                2 && (
                                                                                <span
                                                                                    style={{
                                                                                        width: '126px',
                                                                                        display:
                                                                                            'inline-block',
                                                                                        height: '10px',
                                                                                    }}
                                                                                ></span>
                                                                            )}
                                                                        {!(
                                                                            item.question
                                                                                .elementParamType ===
                                                                                1 &&
                                                                            item.answer
                                                                                .elementParamType ===
                                                                                2
                                                                        ) && (
                                                                            <div
                                                                                className="interactive-custom-doute-answer-field-del"
                                                                                onClick={actions.customDel.params(
                                                                                    item
                                                                                )}
                                                                            >
                                                                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...tailFormItemLayout}
                                                                    extra={
                                                                        item.answer
                                                                            .elementParamType ===
                                                                            2 &&
                                                                        !_isEmpty(
                                                                            item.answer
                                                                                .semanticTagGroup
                                                                        ) && (
                                                                            <KeyElementNode
                                                                                item={
                                                                                    item.answer
                                                                                        .semanticTagGroup
                                                                                }
                                                                            />
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="interactive-custom-doute-answer-field">
                                                                        <span>
                                                                            {locales.fix.reply}：
                                                                        </span>
                                                                        <Select
                                                                            placeholder={
                                                                                locales.labels
                                                                                    .pleaseChoice
                                                                            }
                                                                            onChange={actions.customFoctorsSelectChange.params(
                                                                                item.key,
                                                                                'answer',
                                                                                `key_elements-${item.key}-2`
                                                                            )}
                                                                            style={{
                                                                                width: '120px',
                                                                                marginRight: '10px',
                                                                            }}
                                                                            defaultValue={
                                                                                item.answer &&
                                                                                item.answer
                                                                                    .elementParamType
                                                                            }
                                                                        >
                                                                            {!_isEmpty(
                                                                                questionTypeList
                                                                            ) &&
                                                                                questionTypeList.map(
                                                                                    (item) => {
                                                                                        return (
                                                                                            <Select.Option
                                                                                                key={
                                                                                                    item.value
                                                                                                }
                                                                                                value={
                                                                                                    item.value
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    item.text
                                                                                                }
                                                                                            </Select.Option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </Select>
                                                                        {getFieldDecorator(
                                                                            `key_elements-${item.key}-2`,
                                                                            {
                                                                                initialValue:
                                                                                    item.answer
                                                                                        .elementParamType ===
                                                                                    2
                                                                                        ? item
                                                                                              .answer
                                                                                              .content ||
                                                                                          ''
                                                                                        : item
                                                                                              .answer
                                                                                              .elementParamType ===
                                                                                          1
                                                                                        ? {
                                                                                              key: item
                                                                                                  .answer
                                                                                                  .elementParamId,
                                                                                              label: item
                                                                                                  .answer
                                                                                                  .content,
                                                                                          }
                                                                                        : undefined,
                                                                                rules:
                                                                                    item.answer
                                                                                        .elementParamType ===
                                                                                    1
                                                                                        ? [
                                                                                              {
                                                                                                  required: true,
                                                                                                  message:
                                                                                                      locales
                                                                                                          .fix
                                                                                                          .enterNotBeNull,
                                                                                              },
                                                                                          ]
                                                                                        : [],
                                                                            }
                                                                        )(
                                                                            <Choose>
                                                                                <When
                                                                                    condition={
                                                                                        item.answer
                                                                                            .elementParamType ===
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    <Button
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                '10px',
                                                                                        }}
                                                                                        onClick={actions.openSmartTools.params(
                                                                                            item.key,
                                                                                            'answer'
                                                                                        )}
                                                                                    >
                                                                                        {item.answer
                                                                                            .elementParamType ===
                                                                                            2 &&
                                                                                        item.answer
                                                                                            .content
                                                                                            ? locales
                                                                                                  .fix
                                                                                                  .reQuerySementicTags
                                                                                            : locales
                                                                                                  .fix
                                                                                                  .querySementicTags}
                                                                                    </Button>
                                                                                    {/* <Input style={{ width: '440px' }} placeholder={locales.fix.dataPlaceholder} onChange={actions.customInputChange.params(item.key, 'answer')} /> */}
                                                                                </When>
                                                                                <When
                                                                                    condition={
                                                                                        item.answer
                                                                                            .elementParamType ===
                                                                                        1
                                                                                    }
                                                                                >
                                                                                    <Select
                                                                                        showSearch
                                                                                        allowClear={
                                                                                            true
                                                                                        }
                                                                                        placeholder={
                                                                                            locales
                                                                                                .fix
                                                                                                .enterInformationEntity
                                                                                        }
                                                                                        defaultActiveFirstOption={
                                                                                            false
                                                                                        }
                                                                                        showArrow={
                                                                                            false
                                                                                        }
                                                                                        filterOption={
                                                                                            false
                                                                                        }
                                                                                        onSearch={
                                                                                            actions.foctorsChangeSearch
                                                                                        }
                                                                                        onFocus={actions.fctorsFocus.params(
                                                                                            `key_elements-${item.key}-2`
                                                                                        )}
                                                                                        onChange={actions.foctorsChangSelectCustome.params(
                                                                                            item.key,
                                                                                            'answer'
                                                                                        )}
                                                                                        labelInValue={
                                                                                            true
                                                                                        }
                                                                                        notFoundContent={
                                                                                            null
                                                                                        }
                                                                                        style={{
                                                                                            width: '200px',
                                                                                        }}
                                                                                    >
                                                                                        {!_isEmpty(
                                                                                            informationEntityshowlist
                                                                                        ) &&
                                                                                            informationEntityshowlist.map(
                                                                                                (
                                                                                                    item
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <Select.Option
                                                                                                            key={
                                                                                                                item.id
                                                                                                            }
                                                                                                            value={
                                                                                                                item.id
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                item.name
                                                                                                            }
                                                                                                        </Select.Option>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </Select>
                                                                                </When>
                                                                            </Choose>
                                                                        )}
                                                                        {/* {
                                                                                    item.answer && item.answer.elementParamType === 2 && (
                                                                                        <Button style={{ marginLeft: '10px' }} onClick={actions.openSmartTools.params(item.key, 'answer')}>{locales.fix.searchSementicTags}</Button>
                                                                                    )
                                                                                } */}
                                                                        {item.question
                                                                            .elementParamType ===
                                                                            1 &&
                                                                            item.answer
                                                                                .elementParamType ===
                                                                                2 && (
                                                                                <div
                                                                                    className="interactive-custom-doute-answer-field-del"
                                                                                    style={{
                                                                                        top: '-15px',
                                                                                    }}
                                                                                    onClick={actions.customDel.params(
                                                                                        item
                                                                                    )}
                                                                                >
                                                                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </Form.Item>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}

                                        <div style={{ width: '100%', height: '48px' }}></div>
                                    </Form>
                                    <If
                                        condition={
                                            (editEnable && sourceType === 3) || sourceType !== 3
                                        }
                                    >
                                        <div className="interactive-custom-action">
                                            <Button
                                                type="primary"
                                                loading={loading}
                                                onClick={actions.foctorsSave}
                                            >
                                                {locales.labels.save}
                                            </Button>
                                            <Button
                                                style={{ marginLeft: '10px' }}
                                                onClick={actions.foctorCancel}
                                            >
                                                {locales.labels.cancel}
                                            </Button>
                                        </div>
                                    </If>
                                </div>
                            )}
                            {detailType && detailType === 1 && (
                                <div className="interactive-standard-answer">
                                    {/* 类型 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="interactive_recognition_type"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.type
                                            }
                                            <span className="required"></span>
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <Radio.Group
                                                classNames="interactive-recognition-types-radios"
                                                itemClassNames="interactive-recognition-type-radio-item"
                                                options={Udesk.enums.interactiveQuestionTypes
                                                    .filter(
                                                        (i) =>
                                                            i.id ===
                                                            Udesk.enums.interactiveQuestionTypes
                                                                .standard.id
                                                    )
                                                    .map((e) => ({ value: e.id, label: e.name }))}
                                                value={privates.model.detail.type}
                                                onChanged={actions.changeType}
                                            />
                                        </div>
                                    </div>
                                    {/* 标准问题 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="interactive_recognition_standard_question"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.standardQuestion
                                            }
                                            <span className="required"></span>
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            {/* <input type="text" id="interactive_recognition_standard_question" className="form-control" defaultValue={privates.model.detail.standardQuestion} onChange={actions.mutator('privates.model.detail.standardQuestion', e => e.target.value)} /> */}
                                            <InputUseEntity
                                                value={privates.model.detail.standardQuestion}
                                                onChanged={actions.changeStandardQuestion}
                                            />
                                        </div>
                                    </div>
                                    {/* 相似问题 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="interactive_recognition_similar_question"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.similarQList
                                            }
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <InputUseEntityList
                                                value={privates.model.detail.similarQList.map(
                                                    (i) => i.content
                                                )}
                                                inputTextChanged={actions.sentenceValueChange.params(
                                                    'similarQList'
                                                )}
                                                addListValue={actions.addSentence.params(
                                                    'similarQList'
                                                )}
                                                deleteListValue={actions.deleteSentence.params(
                                                    'similarQList'
                                                )}
                                                emptyErrorTip={actions.sentenceEmptyErrorTip.params(
                                                    'similarQList'
                                                )}
                                                width={230}
                                                maxListValueLength={255}
                                            />

                                            {/* <InputList
                                                list={privates.model.detail.similarQList}
                                                listItemTextFieldName="content"
                                                inputListClassNames="interactive-recognition-detail-input-list"
                                                addIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"
                                                deleteIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"
                                                inputTextChanged={actions.sentenceValueChange.params(
                                                    'similarQList'
                                                )}
                                                addListValue={actions.addSentence.params(
                                                    'similarQList'
                                                )}
                                                deleteListValue={actions.deleteSentence.params(
                                                    'similarQList'
                                                )}
                                                emptyErrorTip={actions.sentenceEmptyErrorTip.params(
                                                    'similarQList'
                                                )}
                                                maxListValueLength={255}
                                                enableKeywordSearch={true}
                                                displayValueFieldName="apiName"
                                                searchList={searchList}
                                                onSelectSearchValue={actions.onSelectEntity.params(
                                                    'similarQList'
                                                )}
                                                onFocusInput={actions.onFocusInput.params(
                                                    'similarQList'
                                                )}
                                            /> */}
                                        </div>
                                    </div>
                                    {/* 排除相似问题 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="interactive_recognition_except_similar_question"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.exceptSimilarQList
                                            }
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <InputUseEntityList
                                                value={privates.model.detail.exceptSimilarQList.map(
                                                    (i) => i.content
                                                )}
                                                inputTextChanged={actions.sentenceValueChange.params(
                                                    'exceptSimilarQList'
                                                )}
                                                addListValue={actions.addSentence.params(
                                                    'exceptSimilarQList'
                                                )}
                                                deleteListValue={actions.deleteSentence.params(
                                                    'exceptSimilarQList'
                                                )}
                                                emptyErrorTip={actions.sentenceEmptyErrorTip.params(
                                                    'exceptSimilarQList'
                                                )}
                                                maxListValueLength={255}
                                                width={230}
                                            />

                                            {/* <InputList
                                                list={privates.model.detail.exceptSimilarQList}
                                                listItemTextFieldName="content"
                                                inputListClassNames="interactive-recognition-detail-input-list"
                                                addIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"
                                                deleteIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"
                                                inputTextChanged={actions.sentenceValueChange.params(
                                                    'exceptSimilarQList'
                                                )}
                                                addListValue={actions.addSentence.params(
                                                    'exceptSimilarQList'
                                                )}
                                                deleteListValue={actions.deleteSentence.params(
                                                    'exceptSimilarQList'
                                                )}
                                                emptyErrorTip={actions.sentenceEmptyErrorTip.params(
                                                    'exceptSimilarQList'
                                                )}
                                                maxListValueLength={255}
                                            /> */}
                                        </div>
                                    </div>
                                    {/* 分类 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="interactive_recognition_category"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.category
                                            }
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <TreeSelect
                                                treeData={privates.model.categories}
                                                labelInValue
                                                value={{ value: category.id, label: category.name }}
                                                onChange={(v) => {
                                                    actions.switchNode({
                                                        id: v.value,
                                                        name: v.label,
                                                    });
                                                }}
                                                treeDefaultExpandAll
                                                style={{ width: 230 }}
                                            />

                                            {/* <div
                                                className="category-title"
                                                onClick={actions.changeCategoriesVisible}
                                            >
                                                <If condition={category.name}>{category.name}</If>
                                                <If condition={categoriesVisible}>
                                                    <div className="category-content">
                                                        <ReactTree
                                                            nodes={privates.model.categories}
                                                            childNodesField="children"
                                                            clickable={true}
                                                            enableNodeActions={false}
                                                            onActiveNodeChanged={actions.switchNode}
                                                            getNodeBehaviors={
                                                                actions.getNodeBehaviors
                                                            }
                                                            expandedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-down-s-xiasanjiao"
                                                            collapsedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-yousanjiao"
                                                        />
                                                    </div>
                                                </If>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* 问题检测目标 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="active_flag"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.targetType
                                            }
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            {/* <ReactSelect
                                                classNames="udesk-qc-react-select"
                                                value={privates.model.detail.targetType}
                                                dataScource={Udesk.enums.targetTypes}
                                                hasNullSelect={false}
                                                onChanged={actions.changeTargetType}
                                            /> */}
                                            <Select
                                                value={privates.model.detail.targetType}
                                                onChange={actions.changeTargetType}
                                                style={{ width: 230 }}
                                            >
                                                {Udesk.enums.targetTypes.map((e) => {
                                                    return (
                                                        <Select.Option key={e.id} value={e.id}>
                                                            {e.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                    {/* 有效性 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="active_flag"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.status
                                            }
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            {/* <ReactSelect
                                                classNames="udesk-qc-react-select"
                                                value={privates.model.detail.status}
                                                dataScource={Udesk.enums.activeFlags}
                                                hasNullSelect={false}
                                                onChanged={actions.changeStatus}
                                            /> */}

                                            <Select
                                                value={privates.model.detail.status}
                                                onChange={actions.changeStatus}
                                                style={{ width: 230 }}
                                            >
                                                {Udesk.enums.activeFlags.map((e) => {
                                                    return (
                                                        <Select.Option key={e.id} value={e.id}>
                                                            {e.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                    {/* 标准答案 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="interactive_recognition_standard_answer"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.standardAnswer
                                            }
                                            <span className="required"></span>
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            {/* <input type="text" id="interactive_recognition_standard_answer" className="form-control" defaultValue={privates.model.detail.standardAnswer} onChange={actions.mutator('privates.model.detail.standardAnswer', e => e.target.value)} /> */}
                                            <InputUseEntity
                                                value={privates.model.detail.standardAnswer}
                                                onChanged={actions.changeStandardAnswer}
                                            />
                                        </div>
                                    </div>
                                    {/* 相似答案 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="interactive_recognition_similar_answer"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.similarAList
                                            }
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <InputUseEntityList
                                                value={privates.model.detail.similarAList.map(
                                                    (i) => i.content
                                                )}
                                                inputTextChanged={actions.sentenceValueChange.params(
                                                    'similarAList'
                                                )}
                                                addListValue={actions.addSentence.params(
                                                    'similarAList'
                                                )}
                                                deleteListValue={actions.deleteSentence.params(
                                                    'similarAList'
                                                )}
                                                emptyErrorTip={actions.sentenceEmptyErrorTip.params(
                                                    'similarAList'
                                                )}
                                                maxListValueLength={255}
                                                width={230}
                                            />

                                            {/* <InputList
                                                list={privates.model.detail.similarAList}
                                                listItemTextFieldName="content"
                                                inputListClassNames="interactive-recognition-detail-input-list"
                                                addIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"
                                                deleteIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"
                                                inputTextChanged={actions.sentenceValueChange.params(
                                                    'similarAList'
                                                )}
                                                addListValue={actions.addSentence.params(
                                                    'similarAList'
                                                )}
                                                deleteListValue={actions.deleteSentence.params(
                                                    'similarAList'
                                                )}
                                                emptyErrorTip={actions.sentenceEmptyErrorTip.params(
                                                    'similarAList'
                                                )}
                                                maxListValueLength={255}
                                                enableKeywordSearch={true}
                                                displayValueFieldName="apiName"
                                                searchList={searchList}
                                                onSelectSearchValue={actions.onSelectEntity.params(
                                                    'similarAList'
                                                )}
                                                onFocusInput={actions.onFocusInput.params(
                                                    'similarAList'
                                                )}
                                            /> */}
                                        </div>
                                    </div>
                                    {/* 错误答案 */}
                                    <div className="form-group row">
                                        <label
                                            htmlFor="interactive_recognition_wrong_answer"
                                            className="control-label form-control-label-left col-xs-12 col-sm-2 col-md-2 col-lg-1"
                                        >
                                            {
                                                locales.components.pages.interactiveRecognition
                                                    .detail.wrongAList
                                            }
                                        </label>
                                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <InputUseEntityList
                                                value={privates.model.detail.wrongAList.map(
                                                    (i) => i.content
                                                )}
                                                inputTextChanged={actions.sentenceValueChange.params(
                                                    'wrongAList'
                                                )}
                                                addListValue={actions.addSentence.params(
                                                    'wrongAList'
                                                )}
                                                deleteListValue={actions.deleteSentence.params(
                                                    'wrongAList'
                                                )}
                                                emptyErrorTip={actions.sentenceEmptyErrorTip.params(
                                                    'wrongAList'
                                                )}
                                                maxListValueLength={255}
                                                width={230}
                                                hideList
                                            />

                                            {/* <InputList
                                                list={privates.model.detail.wrongAList}
                                                listItemTextFieldName="content"
                                                inputListClassNames="interactive-recognition-detail-input-list"
                                                addIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"
                                                deleteIconClassNames="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"
                                                inputTextChanged={actions.sentenceValueChange.params(
                                                    'wrongAList'
                                                )}
                                                addListValue={actions.addSentence.params(
                                                    'wrongAList'
                                                )}
                                                deleteListValue={actions.deleteSentence.params(
                                                    'wrongAList'
                                                )}
                                                emptyErrorTip={actions.sentenceEmptyErrorTip.params(
                                                    'wrongAList'
                                                )}
                                                maxListValueLength={255}
                                            /> */}
                                        </div>
                                    </div>
                                    {/* 保存&取消 */}
                                    <If
                                        condition={
                                            (editEnable && sourceType === 3) || sourceType !== 3
                                        }
                                    >
                                        <div className="form-group row" style={{ marginBottom: 0 }}>
                                            <div className="col-sm-offset-2 col-md-offset-2 col-lg-offset-1 col-xs-12 col-sm-4 col-md-4 col-lg-4 interactive-recognition-detail-page-buttons">
                                                <Button
                                                    className="btn btn-default"
                                                    onClick={actions.backToIndex}
                                                >
                                                    {locales.labels.cancel}
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    disabled={disabled}
                                                    className="btn btn-primary"
                                                    onClick={actions.saveRecognition}
                                                >
                                                    {locales.labels.save}
                                                </Button>
                                            </div>
                                        </div>
                                    </If>
                                </div>
                            )}
                        </div>
                        <Modal
                            className="customer-information-modal interactive-recognition-detail-page-modal"
                            title={locales.fix.searchSementicTags}
                            visible={visibleFoctors}
                            width={620}
                            destroyOnClose="true"
                            okText={locales.labels.confirm}
                            cancelText={locales.labels.cancel}
                            onOk={actions.grounpSave}
                            confirmLoading={confirmLoading}
                            onCancel={actions.grounpCancel}
                        >
                            <div>
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                    <Input
                                        style={{ width: '400px', marginRight: '10px' }}
                                        defaultValue={modalData.text}
                                        onChange={actions.modalChangeInput}
                                    />
                                    <Button onClick={actions.searchFoctors.params(false)}>
                                        {locales.fix.searchSementicTags}
                                    </Button>
                                </div>
                                <div>
                                    <Table
                                        columns={columns}
                                        dataSource={dataFoctors}
                                        pagination={false}
                                        scroll={{ y: 240 }}
                                    />
                                    <div style={{ marginTop: '10px' }}>
                                        {locales.fix.notFindSuitableLabel}{' '}
                                        <a
                                            href="/site/semantic-intelligence/semantic-tags/new"
                                            target="_blank"
                                        >
                                            {locales.fix.addSemanticTag}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}
