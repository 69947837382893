import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import _filter from 'lodash-es/filter';
import _find from 'lodash-es/find';
import _includes from 'lodash-es/includes';
import _extendWith from 'lodash-es/extendWith';
import Locales from 'UdeskLocales';
import { isAlgorithm, isSalesSubApp } from 'Udesk/system/subApp';
class SemanticTagsDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        isEdit: false,
        id: null,
        activeFlags: [],
        activeFlag: null,
        name: '',
        standardTags: [],
        standardSentences: '',
        title: '',
        effectiveness: Udesk.enums.activeFlags.effiective.id,
        similarTags: [],
        informationEntities: [],
        isEntitiesVisible: false,
        searchList: [],
        excludeSimilarTags: [],
        ignoreList: [],
        DegreeData: [],
        recommendVisible: false,
        rulelistValue: [],
        ruleList: [
            {
                type: 'se',
                name: Locales.current.components.pages.semanticTags.detail.specialElements,
                color: 'rgb(235, 192, 73)',
            },
            {
                type: 'lr',
                name: Locales.current.components.pages.semanticTags.detail.logicRule,
                color: 'rgb(215, 88, 87)',
            },
            {
                type: 'mm',
                name: Locales.current.components.pages.semanticTags.detail.every,
                color: 'rgb(215, 88, 87)',
            },
        ],
        testListFields: [
            {
                value: 3,
                text: Locales.current.components.pages.semanticTags.detail.recallOk,
                name: Locales.current.components.pages.semanticTags.detail.recallNo,
            },
            {
                value: 4,
                text: Locales.current.components.pages.semanticTags.detail.ruleOk,
                name: Locales.current.components.pages.semanticTags.detail.ruleNo,
            },
            {
                value: 5,
                text: Locales.current.components.pages.semanticTags.detail.rularOk,
                name: Locales.current.components.pages.semanticTags.detail.rularNo,
            },
            {
                value: 2,
                text: Locales.current.components.pages.semanticTags.detail.infoOk,
                name: Locales.current.components.pages.semanticTags.detail.infoNo,
            },
            {
                value: 1,
                text: Locales.current.components.pages.semanticTags.detail.fieldOk,
                name: Locales.current.components.pages.semanticTags.detail.fieldNo,
            },
            {
                value: 6,
                text: Locales.current.components.pages.semanticTags.detail.excludeKeywordOk,
                name: Locales.current.components.pages.semanticTags.detail.excludeKeywordNo,
            },
            {
                value: 7,
                text: Locales.current.components.pages.semanticTags.detail.excludeRuleOk,
                name: Locales.current.components.pages.semanticTags.detail.excludeRuleNo,
            },
        ],
        ruleFields: [],
        transList: [],
        ruleFieldList: [],
        recallKeyWord: [],
        recallKeyWordCount: 0,
        excludeKeyword: [],
        excludeKeywordCount: 0,
        similaSentences: [],
        similaSentencesCount: 0,
        customRules: [{ id: 'none' }],
        highLevelConfig: {
            crossSentence: {
                on: 0,
                crossLength: 1,
                differentRoleLength: 0,
                maxCrossLength: 100,
            },
            duplicate: {
                on: 0,
                threshold: 80,
            },
            similarityDegreeThreshold: {
                threshold: 80,
            },
        },
        paging: {
            pageNum: 1,
            pageSize: 10,
        },
        tagId: null,
        searchMinScore: 60,
        searchMaxScore: 80,
        hitTest: [],
        ruleFieldsStartEnd: [],
        checkIds: [],
        conceptWordList: [],
        isChecked: false,
        type: null,
        shouldFixed: false,
        io: null,
        paddingTop: 0,
        tabsRef: React.createRef(),
    };
    static computes = {
        _errFlag: [
            'privates.rulelistValue',
            'privates.similaSentences',
            'privates.recallKeyWord',
            'privates.excludeKeyword',
            'privates.recallRegular',
            'privates.excludeRegular',
            'privates.isChecked',
            function ({ props, privates, locales }) {
                let {
                    recallKeyWord,
                    similaSentences,
                    rulelistValue,
                    recallRegular,
                    isChecked,
                    excludeKeyword,
                    excludeRegular,
                } = privates;
                let hasKeyWord =
                    recallKeyWord.some((item) => item.text) ||
                    excludeKeyword.some((item) => item.text);
                let hasRegular = recallRegular || excludeRegular ? true : false;
                let hasStandard = similaSentences.some(
                    (item) => item.standardFlag === 1 && item.content
                );
                let hasRulelist = rulelistValue.length ? true : false;
                if (!isChecked) {
                    return true;
                }
                return (
                    privates.title.trim() &&
                    (hasKeyWord || hasStandard || hasRulelist || hasRegular)
                );
            },
        ],
    };

    actions = {
        backToIndex() {
            let routeOptions = {
                history: this.props.history,
                routeName: isAlgorithm()
                    ? 'algorithmSemanticTagsIndex'
                    : isSalesSubApp()
                    ? 'salesSemanticTagsIndex'
                    : 'semanticTagsIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        //训练刷新
        trainNewChange() {
            let { paging, tagId, searchMaxScore, searchMinScore } = this.privates;
            let { sdkOptions } = this.props;
            let data = {
                tagId,
                searchMinScore,
                searchMaxScore,
                pageNum: paging.pageNum,
                pageSize: paging.pageSize,
            };
            //训练查询
            let url4 = Udesk.business.apiPath.concatApiPath(
                `semantic-tag/similar-sentences`,
                sdkOptions
            );
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url4, data).then(
                    (resp) => {
                        resolve(resp.data);
                        if (resp.paging && resp.data) {
                            this.privates.paging.pageNum = resp.paging.pageNum + 1;
                        }
                        if (resp.data) {
                            this.privates.transList = resp.data;
                        }
                        Udesk.ui.notify.success(
                            Locales.current.components.pages.semanticTags.detail.update
                        );
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        //训练范围值
        trainValueChange(value, e) {
            this.privates[value] = e;
        },
        //改变标准句
        changeStandardSentences(value, e) {
            let { similaSentences, similaSentencesCount } = this.privates;
            if (_find(similaSentences, (current) => current.standardFlag === 1)) {
                similaSentences.map((item) => {
                    if (item.standardFlag === 1) {
                        item.content = e.target.value.trim();
                    }
                    return item;
                });
            } else {
                this.privates.similaSentences = [
                    ...similaSentences,
                    {
                        order: similaSentencesCount + 1,
                        content: e.target.value.trim(),
                        standardFlag: 1,
                        similarityDegree: null,
                    },
                ];
            }
            this.privates.similaSentencesCount = similaSentencesCount + 1;
        },
        //改变标题
        changeTitles(e) {
            // let { getFieldValue } = this.props.form;
            this.privates.title = e.target.value.trim();
        },
        //改变有效性
        effectivenessChange(value) {
            this.privates.effectiveness = value;
        },
        recallKeyWordAdd(key) {
            let recallKeyWord = this.privates[key];
            let recallKeyWordCount = this.privates[key + 'Count'];
            let count = recallKeyWordCount + 1;
            recallKeyWord = [
                ...recallKeyWord,
                {
                    id: count,
                    text: '',
                },
            ];
            this.privates[key + 'Count'] = count;
            this.privates[key] = recallKeyWord;
            this.actions.update();
        },
        recallKeyWordDel(key, value) {
            let recallKeyWord = this.privates[key];

            let list = _filter(recallKeyWord, (current) => current.id !== value);
            this.privates[key] = list;
            this.actions.update();
        },
        recallKeyWordChangeField(key, value, e) {
            let recallKeyWord = this.privates[key];
            let list = recallKeyWord.map((item) => {
                if (item.id === value) {
                    item.text = e.target.value;
                }
                return item;
            });
            this.privates[key] = list;
        },
        similaSentencesChangeField(value, flag, e) {
            let { similaSentences } = this.privates;
            let list = similaSentences.map((item) => {
                if (item.order === value) {
                    if (flag === 0) {
                        item.content = e.target.value;
                    } else if (flag === 1) {
                        item.similarityDegree = e;
                    }
                }
                return item;
            });
            this.privates.similaSentences = list;
        },
        similaSentencesAdd(value) {
            let { similaSentences, similaSentencesCount } = this.privates;
            let count = similaSentencesCount + 1;
            let list = [
                ...similaSentences,
                {
                    order: count,
                    content: '',
                    standardFlag: value,
                    similarityDegree: value === 2 ? 80 : '',
                },
            ];
            this.privates.similaSentences = list;
            this.privates.similaSentencesCount = count;
            this.actions.update();
        },
        similaSentencesDel(value) {
            let { similaSentences } = this.privates;
            let list = _filter(similaSentences, (current) => current.order !== value);
            this.privates.similaSentences = list;
            this.actions.update();
        },
        similaSentencesRecommendAdd(recommends) {
            let { similaSentences, similaSentencesCount } = this.privates;

            let count = similaSentencesCount;

            const sentences = (recommends || []).map((r) => {
                count = count + 1;
                return {
                    order: count,
                    content: r.value,
                    standardFlag: 0,
                    similarityDegree: '',
                };
            });

            this.privates.similaSentences = [...similaSentences, ...sentences];
            this.privates.similaSentencesCount = count;
            this.actions.update();
        },
        similaSentencesChangeFieldTwo(value, e) {
            let { similaSentences } = this.privates;
            let list = similaSentences.map((item) => {
                if (item.order === value) {
                    item.content = e.target.value;
                }
                return item;
            });
            this.privates.similaSentences = list;
        },
        recallRegularChange(key, e) {
            this.privates[key] = e.target.value;
        },
        fieldTestingChange(value) {
            this.privates.highLevelConfig.crossSentence.on = value;
            this.actions.update();
        },
        crossSentenceChange(value, e) {
            this.privates.highLevelConfig.crossSentence[value] = e;
            this.actions.update();
        },
        similarityRemovalChange(value) {
            this.privates.highLevelConfig.duplicate.on = value;
            this.actions.update();
        },
        duplicateChange(value, e) {
            this.privates.highLevelConfig.duplicate[value] = e;
            this.actions.update();
        },
        similarityDegreeThresholdChange(value, e) {
            this.privates.highLevelConfig.similarityDegreeThreshold[value] = e;
            this.actions.update();
        },
        ruleFieldChange(e) {
            if (e.target.value) {
                this.privates.ruleFieldList = [
                    {
                        fieldType: 'keyWord',
                        subtype: 'word',
                        label: null,
                        defaultValue: [],
                        fieldName: null,
                        keyWords: e.target.value,
                        apiName: '',
                        id: `${this.privates.ruleFields.length + 1}_0`,
                        color: '#1890ff',
                    },
                ];
                this.actions.update();
            } else {
                this.privates.ruleFieldList = [];
                this.actions.update();
            }
        },
        trainCheckChange(value, e) {
            let { transList, checkIds } = this.privates;
            let list = transList.map((item) => {
                if (item.id === value) {
                    if (e.target.checked) {
                        item.value = 1;
                    } else {
                        item.value = 0;
                    }
                }
                return item;
            });
            if (e.target.checked && !_includes(checkIds, value)) {
                this.privates.checkIds = [...checkIds, value];
            } else if (!e.target.checked && _includes(checkIds, value)) {
                this.privates.checkIds = _filter(checkIds, (current) => current !== value);
            }

            this.privates.transList = list;
        },
        trainChange() {
            let { transList, similaSentencesCount, similaSentences } = this.privates;
            let list = _filter(transList, (current) => current.value === 1);
            if (!_isEmpty(list)) {
                let count = similaSentencesCount;
                list = list.map((item) => {
                    count = count + 1;
                    return {
                        content: item.sentence,
                        standardFlag: 0,
                        order: count,
                        similarityDegree: null,
                        id: item.id ? item.id : null,
                    };
                });
                this.privates.similaSentences = [...similaSentences, ...list];
                this.privates.similaSentencesCount = count;
                Udesk.ui.notify.success(
                    Locales.current.components.pages.semanticTags.detail.fanhuaList
                );
                this.actions.update();
            } else {
                Udesk.ui.notify.error(
                    Locales.current.components.pages.semanticTags.detail.wordList
                );
            }
        },
        ruleListObj(value) {
            this.privates.rulelistValue = value;
        },
        saveAndNext() {
            this.actions.save(true);
        },
        save(flag) {
            let {
                title,
                standardSentences,
                effectiveness,
                recallKeyWord,
                excludeKeyword,
                recallRegular,
                excludeRegular,
                similaSentences,
                highLevelConfig,
                rulelistValue,
                ruleFieldsStartEnd,
                checkIds,
                type,
            } = this.privates;
            this.privates.isChecked = true;
            // if (_isEmpty(rulelistValue)) {
            //     rulelistValue = customRules;
            // }
            let list = _isEmpty(rulelistValue)
                ? []
                : rulelistValue
                      .filter((item) => item.id !== 'none')
                      .map((item) => {
                          return {
                              fieldType: item.fieldType ? item.fieldType : '',
                              subtype: item.subtype ? item.subtype : '',
                              label: item.label ? item.label : '',
                              defaultValue: item.defaultValue ? item.defaultValue : [],
                              fieldName: item.fieldName ? item.fieldName : '',
                              apiName: item.apiName ? item.apiName : '',
                              keyWords: item.keyWords ? item.keyWords : '',
                              color: item.color,
                          };
                      });
            if (list.length !== 0) {
                list = [
                    _find(
                        ruleFieldsStartEnd,
                        (current) =>
                            current.label ===
                            Locales.current.components.pages.semanticTags.detail.start
                    ),
                    ...list,
                    _find(
                        ruleFieldsStartEnd,
                        (current) =>
                            current.label ===
                            Locales.current.components.pages.semanticTags.detail.end
                    ),
                ];
            } else {
                list = [];
            }
            const filterKeyWordList = (list) =>
                list.filter((item) => item.text !== '').map((item) => item.text);
            let simList = _filter(
                similaSentences,
                (current) =>
                    !(
                        (current.standardFlag === 1 && current.content.trim() === '') ||
                        current.content === ''
                    )
            );
            let data = {
                title,
                activeFlag: effectiveness,
                standardContent: standardSentences,
                checkIds,
                tags: simList,
                recallWords: filterKeyWordList(recallKeyWord),
                recallRegular,
                excludeRegular,
                customRules: list,
                highLevelConfig,
                type: type,
                excludeKeyword: filterKeyWordList(excludeKeyword),
            };
            let { sdkOptions, match } = this.props;
            let url = '';
            if (match.params.id) {
                url = Udesk.business.apiPath.concatApiPath(
                    `semantic-tag/${match.params.id}`,
                    sdkOptions
                );
                data = _extendWith(data, { id: match.params.id });
            } else {
                url = Udesk.business.apiPath.concatApiPath(`semantic-tag/save`, sdkOptions);
            }

            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.privates.loading = true;
                    this.actions.update();
                    return new Promise((resolve, reject) => {
                        if (match.params.id) {
                            Udesk.ajax.put(url, data).then(
                                (resp) => {
                                    resolve(resp.data);
                                    this.privates.loading = false;
                                    this.actions.update();
                                    if (resp.data && resp.data.length > 0) {
                                        this.privates.DegreeData = resp.data;
                                        this.actions.update();

                                        Udesk.ui.notify.error(
                                            Locales.current.components.pages.semanticTags.detail
                                                .keyWordInfo15
                                        );
                                    } else {
                                        this.privates.loading = false;
                                        this.actions.update();
                                        Udesk.ui.notify.success(
                                            Locales.current.components.pages.semanticTags.detail
                                                .saveSuccess
                                        );
                                        if (!flag) {
                                            this.actions.backToIndex();
                                        }
                                    }
                                },
                                (reason) => {
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.error(reason.errorMsg);
                                    reject(reason);
                                }
                            );
                        } else {
                            Udesk.ajax.post(url, data).then(
                                (resp) => {
                                    resolve(resp.data);
                                    this.privates.loading = false;
                                    this.actions.update();
                                    if (resp.data && resp.data.length > 0) {
                                        this.privates.DegreeData = resp.data;
                                        this.actions.update();
                                        Udesk.ui.notify.error(
                                            Locales.current.components.pages.semanticTags.detail
                                                .keyWordInfo15
                                        );
                                    } else {
                                        this.privates.loading = false;
                                        this.actions.update();
                                        Udesk.ui.notify.success(
                                            Locales.current.components.pages.semanticTags.detail
                                                .saveSuccess
                                        );
                                        if (!flag) {
                                            this.actions.backToIndex();
                                        } else {
                                            this.actions.reloadAsyncModel();
                                            // this.props.form.resetFields();
                                        }
                                    }
                                },
                                (reason) => {
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.error(reason.errorMsg);
                                    reject(reason);
                                }
                            );
                        }
                    });
                } else {
                    Udesk.ui.notify.error(
                        Locales.current.components.pages.semanticTags.detail.errorInfo
                    );
                }
            });
        },
        cancel() {
            this.actions.backToIndex();
        },
        testFieldsChange(e) {
            this.privates.testField = e.target.value;
        },
        testSaveUpdate() {
            let {
                title,
                standardSentences,
                effectiveness,
                recallKeyWord,
                excludeKeyword,
                recallRegular,
                excludeRegular,
                similaSentences,
                highLevelConfig,
                rulelistValue,
                ruleFieldsStartEnd,
                checkIds,
                type,
            } = this.privates;
            let list = rulelistValue
                .filter((item) => item.id !== 'none')
                .map((item) => {
                    return {
                        fieldType: item.fieldType ? item.fieldType : '',
                        subtype: item.subtype ? item.subtype : '',
                        label: item.label ? item.label : '',
                        defaultValue: item.defaultValue ? item.defaultValue : [],
                        fieldName: item.fieldName ? item.fieldName : '',
                        apiName: item.apiName ? item.apiName : '',
                        keyWords: item.keyWords ? item.keyWords : '',
                        color: item.color,
                    };
                });
            if (list.length !== 0) {
                list = [
                    _find(
                        ruleFieldsStartEnd,
                        (current) =>
                            current.label ===
                            Locales.current.components.pages.semanticTags.detail.start
                    ),
                    ...list,
                    _find(
                        ruleFieldsStartEnd,
                        (current) =>
                            current.label ===
                            Locales.current.components.pages.semanticTags.detail.end
                    ),
                ];
            } else {
                list = [];
            }

            let lists = [];
            recallKeyWord.forEach((item) => {
                lists = [...lists, item.text];
            });
            let simList = _filter(
                similaSentences,
                (current) => !(current.standardFlag === 1 && current.content === '')
            );
            // simList = simList.map(item=>{
            //     return {
            //         content:item.content,
            //         standardFlag:item.standardFlag,
            //         similarityDegree:item.similarityDegree
            //     };
            // });
            let data = {
                title,
                activeFlag: effectiveness,
                standardContent: standardSentences,
                checkIds,
                tags: simList,
                recallWords: lists,
                excludeKeyword: excludeKeyword.map((item) => item.text),
                recallRegular,
                excludeRegular,
                customRules: list,
                highLevelConfig,
                type: type,
            };
            let { sdkOptions, match } = this.props;
            let url = '';
            if (match.params.id) {
                url = Udesk.business.apiPath.concatApiPath(
                    `semantic-tag/${match.params.id}`,
                    sdkOptions
                );
                data = _extendWith(data, { id: match.params.id });
            } else {
                url = Udesk.business.apiPath.concatApiPath(`semantic-tag/save`, sdkOptions);
            }
            this.props.form
                .validateFields((err, values) => {
                    if (!err) {
                        return new Promise((resolve, reject) => {
                            resolve(values);
                        });
                    } else {
                        Udesk.ui.notify.error(
                            Locales.current.components.pages.semanticTags.detail.errorInfo
                        );
                        return new Promise((resolve, reject) => {
                            reject();
                        });
                    }
                })
                .then(() => {
                    return new Promise((resolve, reject) => {
                        if (match.params.id) {
                            Udesk.ajax.put(url, data).then(
                                (resp) => {
                                    if (resp.data && resp.data.length > 0) {
                                        this.privates.DegreeData = resp.data;
                                        this.actions.update();
                                        Udesk.ui.notify.error(
                                            Locales.current.components.pages.semanticTags.detail
                                                .keyWordInfo15
                                        );
                                    } else {
                                        Udesk.ui.notify.success(
                                            Locales.current.components.pages.semanticTags.detail
                                                .saveSuccess
                                        );
                                        this.actions.update();
                                    }
                                    resolve(resp.data);
                                },
                                (reason) => {
                                    Udesk.ui.notify.error(reason.errorMsg);
                                    reject(reason);
                                }
                            );
                        } else {
                            if (this.privates.id) {
                                resolve({ id: this.privates.id });
                            } else {
                                Udesk.ajax.post(url, data).then(
                                    (resp) => {
                                        if (resp.data && resp.data.length > 0) {
                                            this.privates.DegreeData = resp.data;
                                            this.actions.update();
                                            Udesk.ui.notify.error(
                                                Locales.current.components.pages.semanticTags.detail
                                                    .keyWordInfo15
                                            );
                                        } else {
                                            Udesk.ui.notify.success(
                                                Locales.current.components.pages.semanticTags.detail
                                                    .saveSuccess
                                            );
                                            this.actions.update();
                                        }
                                        resolve(resp.data);
                                    },
                                    (reason) => {
                                        Udesk.ui.notify.error(reason.errorMsg);
                                        reject(reason);
                                    }
                                );
                            }
                        }
                    });
                })
                .then((data) => {
                    let urls = '';
                    if (match.params.id || data.id) {
                        urls = Udesk.business.apiPath.concatApiPath(
                            `semantic-tag/hit-test`,
                            sdkOptions
                        );
                        this.privates.id = match.params.id || data.id;
                    }
                    let datas = {
                        groupId: match.params.id || data.id,
                        text: this.privates.testField,
                    };
                    return new Promise((resolve, reject) => {
                        Udesk.ajax.post(urls, datas).then(
                            (resp) => {
                                resolve(resp.data);
                                this.privates.hitTest = resp.data;
                                this.actions.update();
                            },
                            (reason) => {
                                Udesk.ui.notify.error(reason.errorMsg);
                                reject(reason);
                            }
                        );
                    });
                });
        },
        onTabsChange(activeKey) {
            this.privates.shouldFixed = activeKey === '2';
            // if(activeKey !== '2') {
            // this.privates.io && this.privates.io.disconnect();
            // return;
            // }
            // setTimeout(() => {
            //     let dom = document.getElementById('positonTransLine');
            //     if(dom){
            //         let callback = (entries) => {
            //             entries.forEach(i => {
            //                 this.privates.shouldFixed = !i.isIntersecting;
            //                 this.actions.update();
            //             });
            //         };
            //         this.privates.io = new IntersectionObserver(callback);
            //         this.privates.io.observe(dom);
            //     }
            // }, 100);
        },
        scrollListenerHandler(e) {
            if (!this.privates.shouldFixed) return;
            this.privates.paddingTop = e.target.scrollTop - 320;
            this.actions.update();
        },
    };

    //#region Life Cycle
    componentDidMount() {
        this.privates.isEdit = !!this.props.match.params.id;
        let activeFlags = Udesk.enums.activeFlags;
        activeFlags = activeFlags.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
        this.privates.activeFlags = activeFlags;
        this.actions.update();
    }
    componentWillUnmount() {}
    //#endregion
}

// function addArrayToTags(isExclude, array, tags) {
//     array.forEach(item => {
//         tags.push({
//             recommendId: item.recommendId,
//             content: item.content,
//             standardFlag: isExclude ? 2 : 0
//         });
//     });
// }

// function addArrayToIgnoreList(array, list) {
//     array.forEach(item => {
//         if (list.indexOf(item.recommendId) === -1) {
//             list.push(item.recommendId);
//         }
//     });
// }

// function submitIgnoreList(that, array) {
//     if (!array || array.length === 0) {
//         return Promise.resolve();
//     }
//     let { sdkOptions } = that.props;
//     let url = Udesk.business.apiPath.concatApiPath(
//         `semantic-tag/similar-sentences-submit`,
//         sdkOptions
//     );
//     let params = {
//         exclude: array.map(item => {
//             return item.recommendId;
//         })
//     };
//     return new Promise((resolve, reject) => {
//         Udesk.ajax.post(url, params).then(
//             resp => {
//                 resolve(resp);
//             },
//             reason => {
//                 reject(reason);
//             }
//         );
//     });
// }

export default SemanticTagsDetailComponent;
