import { rebuild as rebuildEnums } from 'udesk-web-toolkits/src/udesk/enums';
let sortDirection = [
    { id: "none", name: "none", key: "none" },
    { id: "asc", name: "ascending", key: "ascending" },
    { id: "desc", name: "descending", key: "descending" }
];
let modelErrorDisplayMode = [
    { id: 0, name: "none", key: "none" },
    { id: 1, name: "alert", key: "alert" },
    { id: 2, name: "inline", key: "inline" },
    { id: 3, name: "both", key: "both" }
];

let requestMethods = [
    { id: 1, name: "GET", key: "get" },
    { id: 2, name: "POST", key: "create" },
    { id: 3, name: "DELETE", key: "del" },
    { id: 4, name: "PUT", key: "update" }
];

let exportableEnums = {
    sortDirection,
    modelErrorDisplayMode,
    requestMethods
};
rebuildEnums.call(exportableEnums);
export default exportableEnums;