// import Udesk from "Udesk";
import React from "react";
import CheckPointConditionListGatherComponent from "./component";
import "./style.scss";
import CheckPointCondition from 'Component/pages/template-test/check-point-condition-list-gather/check-point-condition';
import { Button, Space } from 'udesk-ui';
export default class CheckPointConditionListGatherTemplate extends CheckPointConditionListGatherComponent {
    render() {
        let { props, actions,privates} = this;
        let { conditionList, conditionTypeList } = privates;
        return (
            <div className='check-point-condition-list-gather'>
                {
                    conditionList && conditionList.length !== 0 && conditionList.map((condition, index) => {
                        return (
                            <div className='check-point-condition-list-item' key={condition.idCode}>
                                <CheckPointCondition
                                    key={condition.idCode}
                                    conditionList={conditionList}
                                    condition={condition}
                                    conditionTypeList={conditionTypeList}
                                    type={props.type}
                                    onChange={actions.handleConditionListChange.params(index)}
                                >
                                </CheckPointCondition>
                            </div>
                        );
                    })
                }
                <div className='check-point-condition-list-add'>
                    <Space>
                        {
                            privates.conditionTypeList.map(item => {
                                return (
                                    <Button
                                        key={item.key}
                                        // className='check-point-condition-list-add-btn'
                                    >
                                        <span className="udesk-qa-react-web-iconfont" style={{ fontSize: '16px', marginRight: '8px', cursor: 'pointer', lineHeight: '16px' }}>&#xe610;</span>
                                        {item.name}
                                    </Button>
                                );
                            })
                        }
                    </Space>
                </div>
            </div>
        );
    }
}
