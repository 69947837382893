import Udesk from 'Udesk';
import React from 'react';
import IndexManangementRoute from './route';
import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import { TreeSelect, Select, Input, Button, PageHeader } from 'udesk-ui';
import ReactModal from 'udesk-react/src/components/react-modal';
import _isEmpty from 'lodash-es/isEmpty';
import './style.scss';
import SelectCallTimer from '../../components/select-call-time';
export default class IndexManagementTemplate extends IndexManangementRoute {
    render() {
        let { /* sdkOptions, */ sourceList } = this.privates;
        let { actions, locales } = this;
        let {
            columns,
            data,
            paging,
            dataSource,
            initData,
            initTreeValue,
            selectCallTimeVisible,
            selectedItems
        } = this.privates;
        let { pageNum, pageSize, total } = paging;
        const company = Udesk.data.init.company;

        return (
            // <div className='index-management-tags-index-page'>
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.fields.IndexManagement.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root index-management-tags-index-page'>
                        <div className='customer-information-tags-index-page-body qa-react-page-body'>
                            <div className='index-management-tags-index-page-fitler'>
                                <div>
                                    <Select
                                        disabled={!(company.enabledCallInspect !== 0 && company.enabledImInspect !== 0)}
                                        onChange={actions.sourceChange}
                                        placeholder={locales.fix.source}
                                        style={{ width: '120px', marginRight: '10px' }}
                                        defaultValue={dataSource}>
                                        {
                                            !_isEmpty(sourceList) && sourceList.map(item => {
                                                return (
                                                    <Select.Option value={item.value}>{item.text}</Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                    <TreeSelect
                                        style={{ width: '200px', marginRight: '10px' }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder={locales.labels.pleaseChoice}
                                        treeData={initData}
                                        allowClear
                                        treeDefaultExpandAll
                                        value={initTreeValue}
                                        onChange={actions.onChangeGrounp}
                                    >
                                    </TreeSelect>
                                    <Input style={{ width: '200px' }} placeholder={locales.fix.placeholder} onPressEnter={actions.onPressEnterInput} />
                                </div>
                                <div>
                                    <Button onClick={actions.fieldsChange}>{locales.labels.add}</Button>
                                </div>

                            </div>
                            <div className='customer-information-index-page-body-content-container qa-react-page-body-content-container'>
                                <ReactTable columns={columns} items={data} checkable={true} onSelectionChanged={actions.onCheckChange} selectedItems={[].concat(selectedItems)} />
                                {/* <ReactPager
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    total={total}
                                    languageCode={sdkOptions.props.language}
                                    pageChanged={actions.pageChanged}
                                /> */}
                                <UdeskPagination
                                    current={pageNum}
                                    pageSize={pageSize}
                                    total={total}
                                    onChange={actions.pageChanged}
                                />
                            </div>
                            <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save} visible={selectCallTimeVisible} title={locales.labels.redo} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.redo.params(false)} onOk={actions.redo.params(true)} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                                <SelectCallTimer onDateChanged={actions.onDateChanged} fieldFlag={true}></SelectCallTimer>
                            </ReactModal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}