import React, { useState } from 'react';
import { Space } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
import { Container } from './components/Container';
import { AdminDataCards } from './components/AdminDataCards';
import FilterConfigDropdown from './components/filter-config/dropdown';
import FilterConfigModal from './components/filter-config/modal';
import { ExportButton } from './components/export-button';
import StudentDataCards from './components/StudentDataCards';
import EmployeeDataCards from './components/EmployeeDataCards';
import { FilterContext, InitialValuesContext, ExportContext } from './context';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import './style.scss';

const Template = React.memo((props: any) => {
    const { title = null, subTitle = null, extra = null } = props;

    return (
        <FilterContext.Provider value={useState()}>
            <InitialValuesContext.Provider value={useState()}>
                <ExportContext.Provider value={useState()}>
                    <Page
                        pageBodyClassName="udesk-qa-web-coach-dashboard-body"
                        pageClassName="udesk-qa-web-coach-dashboard"
                        title={title}
                        padding={false}
                        backGroundGhost={true}
                        subTitle={subTitle}
                        extra={extra}
                    >
                        <Container>{props.children}</Container>
                    </Page>
                </ExportContext.Provider>
            </InitialValuesContext.Provider>
        </FilterContext.Provider>
    );
});

const AdminPage = React.memo((props) => {
    const [currentCndition, setCurrentCndition] = useState({});

    const dropClick = (v) => {
        setCurrentCndition(v);
    };

    return (
        <Template
            {...props}
            title={/* 总览 */ UdeskLocales['current'].pages.coach.dashboard.index.overview}
            subTitle={<FilterConfigDropdown dropClick={dropClick} />}
            extra={
                <Space>
                    <FilterConfigModal current={currentCndition} />
                    <ExportButton />
                </Space>
            }
        >
            <AdminDataCards {...props} />
        </Template>
    );
});

const StudentPage = React.memo((props: any) => {
    return (
        <Template
            {...props}
            title={
                /* 个人看板 */ UdeskLocales['current'].pages.coach.dashboard.index.personalSignage
            }
        >
            <StudentDataCards {...props} />
        </Template>
    );
});

const EmployeePage = React.memo((props) => {
    return (
        <Template {...props} title={/* 员工画像 */UdeskLocales['current'].pages.coach.dashboard.index.employeeProfile}>
            <EmployeeDataCards {...props} />
        </Template>
    );
});

function udeskify(Template) {
    class Component extends React.Component {
        render() {
            return <Template {...this.props} />;
        }
    }
    return Udesk.react.udeskify(Component);
}

export const AdminDashboard = udeskify(AdminPage);
export const StudentDashboard = udeskify(StudentPage);
export const EmployeeDashboard = udeskify(EmployeePage);
