// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetCustomerTagGroupsSyncQuery, BaseResponseVoid } from '../../types';

/**
 * synData
 *
 * @export
 * @tags customer-tag-group-controller
 * @link [GET] /customerTagGroups/sync
 */
export function getCustomerTagGroupsSync(): Promise<BaseResponseVoid>;
export function getCustomerTagGroupsSync(
    options: ApiOptions<never, GetCustomerTagGroupsSyncQuery>
): Promise<BaseResponseVoid>;
export function getCustomerTagGroupsSync(
    options?: ApiOptions<never, GetCustomerTagGroupsSyncQuery>
): Promise<BaseResponseVoid> {
    return request<never, never, GetCustomerTagGroupsSyncQuery>('/customerTagGroups/sync', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCustomerTagGroupsSync',
    });
}

export const meta = [
    { tags: ['customer-tag-group-controller'], path: '/customerTagGroups/sync', method: 'get' },
];
