import interfaceClasses from './interface-classes';
import helpers from './helpers';

class OptionsBuilderClass {

    isSingleValue(optionsOwner) {
        return false;
    }
    notMergeOption() {
        return false;
    }
    lazyUpdate() {
        return false;
    }
    _getChartType(seriesIndex) {
        throw new Error("_getChartType not implemented!");
    }

    getTitleOptions(optionsOwner) {
        let {
            title: text,
            titleOptions
        } = optionsOwner.props;
        let title = {};
        if (text) {
            title.text = text;
        }
        if (titleOptions) {
            Object.assign(title, titleOptions);
        }
        return title;
    }

    convertSeries(records, seriesColumns, optionsOwner) {
        let {
            isStacked,
            showSeriesLabels,
            showExtraPercentInLabels,
            seriesLabelEmphasisOptions
        } = optionsOwner.props;

        let series = [];
        if (seriesColumns != null && records != null) {
            for (let column of seriesColumns) {
                let seriesItem = {};
                seriesItem.name = column.name;
                if (isStacked) {
                    seriesItem.stack = "one";
                }
                seriesItem.data = [];
                seriesItem.label = {};
                seriesItem.label.normal = {};
                //todo: includeSeriesLabelItems
                if (showSeriesLabels || showExtraPercentInLabels) {
                    seriesItem.label.normal.show = true;
                    seriesItem.label.normal.formatter = (params) => {
                        let {
                            dataIndex,
                            seriesIndex
                        } = params;
                        let record = records[dataIndex];
                        let currentColumn = seriesColumns[seriesIndex];
                        let newParams = new interfaceClasses.FormatSeriesLabelParamsClass({
                            value: params.value,
                            record: record,
                            column: currentColumn,
                            seriesColumns: seriesColumns,
                            originParams: params
                        });
                        return this._formatSeriesLabelCore(newParams, optionsOwner);
                    };
                } else {
                    seriesItem.label.normal.show = false;
                }
                if (seriesLabelEmphasisOptions) {
                    if (seriesItem.label.emphasis == null) {
                        seriesItem.label.emphasis = {};
                    }
                    Object.assign(seriesItem.label.emphasis, seriesLabelEmphasisOptions);
                }

                for (let recordItem of records) {
                    let realValue = Number(this._getFieldOriginalValue(recordItem, column.field));
                    if (isNaN(realValue)) {
                        realValue = 0;
                    }
                    var dataItem = {
                        value: realValue,
                        name: recordItem.xLabel
                    };
                    seriesItem.data.push(dataItem);
                }

                //series.length 当前index

                let markPoint = this.getMarkPoint(optionsOwner, series.length);
                if (markPoint) {
                    seriesItem.markPoint = markPoint;
                }
                let markLine = this.getMarkLine(optionsOwner, series.length);
                if (markLine) {
                    seriesItem.markLine = markLine;
                }

                series.push(seriesItem);
            }
        }
        return this._decorateSeries(series, optionsOwner);
    }

    getMarkPoint(optionsOwner, seriesIndex) {
        let {
            enableMarkPoint,
            markPointOptions
        } = optionsOwner.props;

        if (enableMarkPoint && markPointOptions) {
            let defaultOptions = defaultMarkPointOption();
            if (Array.isArray(markPointOptions) && markPointOptions.length > 0) {
                let targetMarkPointOption = markPointOptions[seriesIndex];
                let markPointData = targetMarkPointOption ? targetMarkPointOption.data : null;
                if (markPointData && Array.isArray(markPointData)) {
                    markPointData = markPointData.map(dataItem => {
                        if (dataItem && Array.isArray(dataItem) && dataItem.length > 0) {
                            if (Array.isArray(dataItem) && dataItem.length > 0) {
                                if ((typeof dataItem[0] === "string" || typeof dataItem[0] === "number") &&
                                    (typeof dataItem[1] === "string" || typeof dataItem[1] === "number")) {
                                    dataItem = {
                                        x: dataItem[0],
                                        y: dataItem[1]
                                    };
                                }
                            }
                        }
                        return dataItem;
                    });
                }
                return Object.assign(defaultOptions, targetMarkPointOption);
            } else {
                return Object.assign(defaultOptions, markPointOptions);
            }
        } else {
            return null;
        }
    }
    getMarkLine(optionsOwner, seriesIndex) {
        let {
            enableMarkLine,
            markLineOptions
        } = optionsOwner.props;

        if (enableMarkLine && markLineOptions) {

            let defaultOptions = defaultMarkLineOption();

            if (Array.isArray(markLineOptions) && markLineOptions.length > 0) {

                let targetMarLineOption = markLineOptions[seriesIndex];
                let marLineData = targetMarLineOption ? targetMarLineOption.data : null;

                if (marLineData && Array.isArray(marLineData)) {
                    marLineData = marLineData.map(dataItem => {

                        if (dataItem && Array.isArray(dataItem) && dataItem.length > 0) {
                            let firstDataItem = dataItem[0];
                            let secondDataItem = dataItem[1];

                            if (Array.isArray(firstDataItem) && firstDataItem.length > 0) {
                                if ((typeof firstDataItem[0] === "string" || typeof firstDataItem[0] === "number") &&
                                    (typeof firstDataItem[1] === "string" || typeof firstDataItem[1] === "number")) {
                                    dataItem[0] = {
                                        x: firstDataItem[0],
                                        y: firstDataItem[1]
                                    };
                                }
                            }

                            if (Array.isArray(secondDataItem) && secondDataItem.length > 0) {
                                if ((typeof secondDataItem[0] === "string" || typeof secondDataItem[0] === "number") &&
                                    (typeof secondDataItem[1] === "string" || typeof secondDataItem[1] === "number")) {
                                    dataItem[1] = {
                                        x: secondDataItem[0],
                                        y: secondDataItem[1]
                                    };
                                }
                            }
                        }
                        return dataItem;
                    });
                }
                return Object.assign(defaultOptions, targetMarLineOption);
            } else {
                return Object.assign(defaultOptions, markLineOptions);
            }
        } else {
            return null;
        }
    }

    getSeriesOptions(optionsOwner) {
        let {
            records,
            seriesColumns,
        } = optionsOwner.props;

        let series = this.convertSeries(records, seriesColumns, optionsOwner);
        return series;
    }

    _getLabelPosition() {
        return "inside";
    }
    getGridOptions(optionsOwner) {
        return null;
    }
    getXAxisOptions(optionsOwner) {
        return null;
    }

    getYAxisOptions(optionsOwner) {
        return null;
    }

    getLegendOptions(optionsOwner) {
        let {
            legendType,
            showLegend,
            legendTop,
            legendLeft,
            legendRight,
            legendBottom,
            legendOrient
        } = optionsOwner.props;

        let legend = {};

        legend.type = legendType;
        legend.show = showLegend;
        legend.top = legendTop;
        legend.left = legendLeft;
        legend.right = legendRight;
        legend.bottom = legendBottom;

        legend.orient = legendOrient;

        return legend;
    }
    getColorOptions(optionsOwner) {
        let {
            customColor
        } = optionsOwner.props;
        if (customColor instanceof Array && customColor.length > 0) {
            return customColor;
        } else {
            return [];
        }
    }
    getTextStyleOptions(optionsOwner) {
        let {
            textStyle
        } = optionsOwner.props;

        return textStyle;
    }
    getDataZoom(optionsOwner) {
        return null;
    }

    getTooltipOptions(optionsOwner) {
        let {
            showTooltips,
            formatTooltip,
            seriesColumns,
            records,
            tooltipBackgroundColor,
            tooltipPadding,
            tooltipBorderColor,
            tooltipBorderWidth,
            tooltipTextFontSize,
            tooltipTextColor,
            tooltipTextLineHeight,
            tooltipEnterable,
            tooltipConfine,
            extraCssText
        } = optionsOwner.props;

        let tooltip = {};

        if (tooltipBackgroundColor) {
            tooltip.backgroundColor = tooltipBackgroundColor;
        }
        tooltip.padding = tooltipPadding;
        if (tooltipBorderColor) {
            tooltip.borderColor = tooltipBorderColor;
        }
        tooltip.borderWidth = tooltipBorderWidth;

        tooltip.textStyle = {};
        tooltip.textStyle.fontSize = tooltipTextFontSize;
        tooltip.textStyle.color = tooltipTextColor;
        tooltip.textStyle.lineHeight = tooltipTextLineHeight;

        tooltip.show = showTooltips;
        tooltip.confine = tooltipConfine;
        tooltip.enterable = tooltipEnterable;
        tooltip.trigger = "item";

        let multipleSeries = false;
        if (tooltip) {
            if (tooltip.trigger === "axis") {
                multipleSeries = true;
            } else if (tooltip.trigger === "item") {
                multipleSeries = false;
            } else {
                multipleSeries = null;
            }
        }

        if (extraCssText) {
            tooltip.extraCssText = extraCssText;
        }

        tooltip.formatter = (params) => {
            if (!params || multipleSeries === null) {
                return;
            }
            // let {
            //     dataIndex,
            //     seriesIndex
            // } = params;

            // let record = records[dataIndex];
            // let currentColumn = seriesColumns[seriesIndex];
            if (formatTooltip) {
                return formatTooltip.apply(optionsOwner, [params, multipleSeries, records, seriesColumns]);
            } else {
                return defaultFormatToolTip(this, optionsOwner, params, multipleSeries);
            }
        };
        return tooltip;
    }

    _formatTooltip(optionsOwner, params, multipleSeries) {
        let {
            showSeriesLabels,
            showExtraPercentInLabels
        } = optionsOwner.props;

        let paramsData = params;
        if (!Array.isArray(params)) {
            paramsData = [params];
        }

        let firstRecordItem = this._getRecordItemValue(optionsOwner, paramsData[0].dataIndex);

        let toolTipName = (firstRecordItem && firstRecordItem.xLabel) ? this._encodeHTML(firstRecordItem.xLabel) : "";

        if (!toolTipName || firstRecordItem.xLabel === '\u0000-' || firstRecordItem.xLabel === '-') {
            toolTipName = "";
        } else {
            toolTipName += '<br />';
        }

        return (!multipleSeries ? "" : toolTipName) + paramsData.map(item => {

            let recordItem = this._getRecordItemValue(optionsOwner, item.dataIndex);
            let seriesColumnItem = this._getSeriesColumnItem(optionsOwner, item.seriesIndex);

            let seriesName = item.seriesName;
            let name = item.name;

            let formattedValue = 0;
            let currentValue = 0;
            if (recordItem) {
                currentValue = recordItem[seriesColumnItem.field].value || recordItem[seriesColumnItem.field].originalValue;
            }


            let percentValue = item.percent;
            if (isNaN(item.percent)) {
                percentValue = (0).toFixed(2);
            }

            if (showSeriesLabels && showExtraPercentInLabels) {
                formattedValue = ` ${currentValue} (${percentValue}%)`;
            } else if (showSeriesLabels) {
                formattedValue = `${currentValue}`;
            } else if (showExtraPercentInLabels) {
                formattedValue = `${percentValue}%`;
            } else {
                formattedValue = currentValue;
            }

            if (seriesName === '\u0000-') {
                // Not show '-'
                seriesName = '';
            }

            let color = item.color || 'transparent';
            let colorEl = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:'${this._encodeHTML(color)}'"></span>`;

            return !multipleSeries ? (seriesName && this._encodeHTML(seriesName) + '<br />') + colorEl + (name ? this._encodeHTML(name) + ' : ' + formattedValue : formattedValue) : colorEl + (seriesColumnItem.name ? this._encodeHTML((seriesColumnItem.name) + ' : ' + formattedValue) : formattedValue);

        }).join('<br />');
    }

    getToolboxOptions(optionsOwner) {
        let {
            toolboxConfig
        } = optionsOwner.props;
        let toolboxOptions = {};

        toolboxOptions.feature = {
            // dataView: {
            //     show: true
            // },
            // magicType: {
            //     type: ['line', 'bar', 'stack', 'tiled']
            // },
            // saveAsImage: {
            //     type: 'png',
            //     show: true,
            // }
        };
        let position = helpers.toolboxPosition(toolboxConfig);
        toolboxOptions = $.extend(toolboxOptions, position);

        return toolboxOptions;
    }
    getRadarOptions(optionsOwner) {
        return null;
    }
    getVisualMapOptions(optionsOwner) {
        return null;
    }
    _getSeriesColumnItem(optionsOwner, seriesIndex) {
        let {
            seriesColumns,
        } = optionsOwner.props;
        if (seriesColumns) {
            return seriesColumns[seriesIndex];
        }
    }

    _getRecordItemValue(optionsOwner, dataIndex) {
        let {
            records,
        } = optionsOwner.props;
        if (records) {
            return records[dataIndex];
        } else {
            return "";
        }
    }

    _encodeHTML(source) {
        return String(source).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
    }

    _getFieldValue(record, fieldName) {
        if (record == null) {
            return 0;
        }
        let fieldValue = record[fieldName];
        if (typeof fieldValue === "object") {
            return fieldValue.value;
        } else {
            return fieldValue;
        }
    }

    _getFieldOriginalValue(record, fieldName) {
        if (record == null) {
            return 0;
        }
        let fieldValue = record[fieldName];
        if (typeof fieldValue === "object") {
            if (fieldValue.originalValue != null) {
                return fieldValue.originalValue;
            } else {
                return fieldValue.value;
            }
        } else {
            let possibleFieldName = fieldName + "Original";
            if (fieldValue && fieldValue[possibleFieldName] != null) {
                return fieldValue[possibleFieldName];
            } else {
                return fieldValue;
            }
        }
    }

    _getSeriesLabelValue(newParams, optionsOwner) {
        if (newParams) {
            if (newParams.column && newParams.column.field) {
                let field = newParams.column.field;
                if (newParams.record) {
                    let recordValue = newParams.record[field];
                    if (recordValue) {
                        return recordValue.value || recordValue.originalValue || newParams.value;
                    }
                }
            }
            return newParams.value;
        } else {
            throw new Error(`value is undefined`);
        }
    }

    _formatSeriesLabelCore(newParams, optionsOwner) {
        let {
            formatSeriesLabel,
        } = optionsOwner.props;
        let that = this;
        if (typeof formatSeriesLabel !== "function") {
            formatSeriesLabel = (params) => {
                return defaultFormatSeriesLabel(params, that, optionsOwner);
            };
        }
        return formatSeriesLabel.apply(this, [newParams]);
    }

    _formatSeriesLabelValue(newParams, optionsOwner) {
        return newParams.value;
    }

    _decorateSeries(series, optionsOwner) {
        let {
            barMaxWidth,
            seriesLabelPosition,
        } = optionsOwner.props;
        if (series != null) {
            for (var i = 0; i < series.length; i++) {
                let seriesItem = series[i];
                seriesItem.type = this._getChartType(i);
                seriesItem.barMaxWidth = barMaxWidth;
                seriesItem.label.normal.position = seriesLabelPosition || this._getLabelPosition();
            }
        }
        return series;
    }
}

export default OptionsBuilderClass;

function defaultMarkLineOption() {
    return {
        silent: false,
    };
}
function defaultMarkPointOption() {
    return {};
}

function defaultFormatSeriesLabel(newParams, context, optionsOwner) {
    let getSeriesLabelValue = optionsOwner.props.getSeriesLabelValue;
    if (typeof getSeriesLabelValue !== "function") {
        getSeriesLabelValue = (params) => {
            return context._getSeriesLabelValue(params, optionsOwner);
        };
    }
    newParams.value = getSeriesLabelValue(newParams, optionsOwner);

    let formatSeriesLabelValue = optionsOwner.props.formatSeriesLabelValue;
    if (typeof formatSeriesLabelValue !== "function") {
        formatSeriesLabelValue = (params) => {
            return context._formatSeriesLabelValue(params, optionsOwner);
        };
    }
    return formatSeriesLabelValue.apply(context, [newParams]);
}

function defaultFormatToolTip(context, optionsOwner, params) {
    return context._formatTooltip(optionsOwner, params);
}
