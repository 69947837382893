import React from 'react';
import { Space } from 'udesk-ui';

export const ScoreTitle = (props) => {
    return (
        <Space style={{ display: 'flex' }} className={'over-view-content-detail-title'}>
            <div>{props.title}</div>
            <Score {...props} />
        </Space>
    );
};

function get(value) {
    return value ?? '-';
}
const Score = (props) => {
    const { score, totalScore } = props;
    return (
        <span className="score">
            <span>{get(score)}</span>/<span>{get(totalScore)}</span>
        </span>
    );
};
