// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PutCompanyGeneralSettingssByTypeByValueParam,
    BaseResponseCompanyFoundResponse,
} from '../../../types';

/**
 * updateStatus
 *
 * @export
 * @tags company-general-settings-controller
 * @link [PUT] /companyGeneralSettingss/{type}/{value}
 * @param type
 * @param value
 */
export function putCompanyGeneralSettingssByTypeByValue(
    options: ApiOptions<PutCompanyGeneralSettingssByTypeByValueParam, never> & {
        segments: PutCompanyGeneralSettingssByTypeByValueParam;
    }
): Promise<BaseResponseCompanyFoundResponse> {
    return request<never, PutCompanyGeneralSettingssByTypeByValueParam, never>(
        '/companyGeneralSettingss/{type}/{value}',
        {
            ...options,
            method: 'put',
            __$requestCalleeName: 'putCompanyGeneralSettingssByTypeByValue',
        }
    );
}

export const meta = [
    {
        tags: ['company-general-settings-controller'],
        path: '/companyGeneralSettingss/{type}/{value}',
        method: 'put',
    },
];
