import React from 'react';
import PropTypes from 'prop-types';

export default class FilterModalComponent extends React.Component {
    static defaultProps = {
        disabled: false
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        disabled: PropTypes.bool,
    };

    actions = {
        editFilterOhter(){
            let { props } = this;
            props.editFilterOhter(
                props.conditionList,
                props.judgeStrategy,
                props.customJudgeLogic,
            );
        }
    };
}
