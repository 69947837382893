// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetInspectionRemarkModelConfigsByIdParam,
    BaseResponseInspectionRemarkModelConfigFoundResponse,
    PutInspectionRemarkModelConfigsByIdParam,
    PutInspectionRemarkModelConfigsByIdBody,
    BaseResponseVoid,
    DeleteInspectionRemarkModelConfigsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags inspection-remark-model-config-controller
 * @link [GET] /inspectionRemarkModelConfigs/{id}
 * @param id
 */
export function getInspectionRemarkModelConfigsById(
    options: ApiOptions<GetInspectionRemarkModelConfigsByIdParam, never> & {
        segments: GetInspectionRemarkModelConfigsByIdParam;
    }
): Promise<BaseResponseInspectionRemarkModelConfigFoundResponse> {
    return request<never, GetInspectionRemarkModelConfigsByIdParam, never>(
        '/inspectionRemarkModelConfigs/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionRemarkModelConfigsById' }
    );
}

/**
 * update
 *
 * @export
 * @tags inspection-remark-model-config-controller
 * @link [PUT] /inspectionRemarkModelConfigs/{id}
 * @param id
 */
export function putInspectionRemarkModelConfigsById(
    data: PutInspectionRemarkModelConfigsByIdBody,
    options: ApiOptions<PutInspectionRemarkModelConfigsByIdParam, never> & {
        segments: PutInspectionRemarkModelConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutInspectionRemarkModelConfigsByIdBody,
        PutInspectionRemarkModelConfigsByIdParam,
        never
    >('/inspectionRemarkModelConfigs/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putInspectionRemarkModelConfigsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags inspection-remark-model-config-controller
 * @link [DELETE] /inspectionRemarkModelConfigs/{id}
 * @param id
 */
export function deleteInspectionRemarkModelConfigsById(
    options: ApiOptions<DeleteInspectionRemarkModelConfigsByIdParam, never> & {
        segments: DeleteInspectionRemarkModelConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteInspectionRemarkModelConfigsByIdParam, never>(
        '/inspectionRemarkModelConfigs/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteInspectionRemarkModelConfigsById',
        }
    );
}

export const meta = [
    {
        tags: ['inspection-remark-model-config-controller'],
        path: '/inspectionRemarkModelConfigs/{id}',
        method: 'get',
    },
    {
        tags: ['inspection-remark-model-config-controller'],
        path: '/inspectionRemarkModelConfigs/{id}',
        method: 'put',
    },
    {
        tags: ['inspection-remark-model-config-controller'],
        path: '/inspectionRemarkModelConfigs/{id}',
        method: 'delete',
    },
];
