import React from 'react';
import Udesk from 'Udesk';

export default class InputUseEntityComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {

    };
    static propTypes = {

    };
    //#endregion

    state = {

    };
    privates = {
        value: "",
        informationEntities: []
    };

    actions = {
        inputChanged(e) {
            let value = e.target.value;
            this.privates.value = value;
            this.trigger("onChanged", value);
            this.actions.update();
            if (value == null || value.trim() === "") {
                return;
            }
            let signIndex = value.lastIndexOf("#");
            if (signIndex !== -1 && (value.length - 1 !== signIndex)) {
                let keyword = value.substring(signIndex + 1);
                if (keyword == null || keyword.trim() === "") {
                    return;
                }
                this.actions.searchEntities(keyword);
            } else if (signIndex !== -1 && (value.length - 1 === signIndex)) {
                this.privates.informationEntities = [];
                this.actions.update();
            }
        },
        onFocusInput(e) {
            this.actions.inputChanged(e);
        },
        onBlurInput() {
            let {
                value
            } = this.privates;
            if (value == null || value.trim() === "") {
                this.privates.informationEntities = [];
                this.actions.update();
                return;
            }
            let signIndex = value.lastIndexOf("#");
            if (signIndex !== -1 && (value.length - 1 === signIndex)) {
                this.privates.informationEntities = [];
                this.actions.update();
            }
        },
        searchEntities(keyword) {
            let {
                sdkOptions
            } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`information-entity/keyword`, sdkOptions);
            let params = {
                keyword,
                pageNum: Udesk.config.paging.defaultPageNumber,
                pageSize: Udesk.config.paging.defaultPageSize,
                // systemType: Udesk.data.init.company.systemType,
            };
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    this.privates.informationEntities = resp.data;
                    this.privates.isEntitiesVisible = true;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        selectEntity(entity) {
            let {
                value
            } = this.privates;
            let signIndex = value.lastIndexOf("#");
            if (signIndex !== -1) {
                let sentence = value.substring(0, signIndex + 1);
                sentence = sentence + entity.apiName + "#";
                this.privates.value = sentence;
                this.trigger("onChanged", this.privates.value);
                this.actions.update();
                this.actions.changeEntitiesVisibleFalse();
            }
        },
        changeEntitiesVisibleFalse() {
            this.privates.isEntitiesVisible = false;
            this.actions.update();
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {
            value: props.value
        };
    }
    componentWillUnmount() {

    }
    //#endregion
}
