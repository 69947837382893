// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetSampleSampleTargetByIdQuery,
    GetSampleSampleTargetByIdParam,
    BaseResponseSampleTargetDTO,
} from '../../../types';

/**
 * sampleTargetStat
 *
 * @export
 * @tags 抽检
 * @link [GET] /sample/sampleTarget/{id}
 * @param id
 */
export function getSampleSampleTargetById(
    options: ApiOptions<GetSampleSampleTargetByIdParam, GetSampleSampleTargetByIdQuery> & {
        segments: GetSampleSampleTargetByIdParam;
    } & { params: GetSampleSampleTargetByIdQuery }
): Promise<BaseResponseSampleTargetDTO> {
    return request<never, GetSampleSampleTargetByIdParam, GetSampleSampleTargetByIdQuery>(
        '/sample/sampleTarget/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getSampleSampleTargetById' }
    );
}

export const meta = [{ tags: ['抽检'], path: '/sample/sampleTarget/{id}', method: 'get' }];
