import { useEffect, useRef, useState } from "react";

export const useScroll = ({ dialogue }: { dialogue: any[] | null }) => {
    const ref = useRef<any>(null);

    const [highlightIndex, setHighlightIndex] = useState<number>(-1);

    // const secMap = useMemo(() => {
    //     const map = new Map();
    //     if (Array.isArray(dialogue)) {
    //         dialogue.forEach((d, i) => {
    //             map.set(d.endSec, i);
    //         });
    //     }
    //     return map;
    // }, [dialogue]);

    useEffect(() => {
        const getCurrentIndex = (currentTime, dialogue) => dialogue.findIndex(i => i.fromSec <= currentTime && currentTime <= i.endSec);
        const cb = function (...args) {
            console.log('ref.current', ref.current.currentTime);
            const currentTime = ref.current.currentTime;
            if (dialogue) {
                setHighlightIndex(getCurrentIndex(currentTime, dialogue));
            }
        };
        ref.current.addEventListener("timeupdate", cb);
        return () => {
            ref.current.removeEventListener("timeupdate", cb);
        };
    }, [dialogue]);

    const onItemClick = (item) => {
        ref.current.currentTime = item.fromSec;
    };

    return { ref, highlightIndex, onItemClick };
};