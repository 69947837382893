import React from 'react';
import Udesk from 'Udesk';
// import ReactTinymce from 'Component/common/udesk-qa-tinymce';
import MassagePushConfigurationComponent from './component';
import './style.scss';
import { Input, Space, Radio, Popover } from 'udesk-ui';
import UdeskList from 'Component/common/udesk-list';
import AssociateSelect from '../associate-select';
import MarkdownIt from 'markdown-it';
import MdEditor, { Plugins } from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { PluginComponent } from 'react-markdown-editor-lite';
import Locales from 'UdeskLocales';


const CreateMdEditorCustomPlugin = (props) => {
    return class PropsSelect extends PluginComponent {
        // 这里定义插件名称，注意不能重复
        static pluginName = 'my-propsSelect';
        // 定义按钮被防止在哪个位置，默认为左侧，还可以放置在右侧（right）
        static align = 'left';
    
        constructor(props) {
            super(props);
    
            this.handleClick = this.handleClick.bind(this);
        }
    
        handleClick(pushConf, editorState, content) {
            // 调用API，往编辑器中插入内容
            this.editor.insertText(content);
        }
    
        render() {
            const locales = Locales['current'];
    
            return (
                <Popover
                    content={
                        <AssociateSelect
                            onClick={this.handleClick}
                            // pushConfContentChange={actions.pushConfContentChange}
                            pushConf={{}}
                            // associateDivRef={privates.associateDivRef}
                            // textAreaRef={privates.textAreaRef}
                            taskType={props.taskType}
                            taskTypeList={props.taskTypeList}
                            // taskId={props.taskId}
                        ></AssociateSelect>
                    }
                >
                    <span className='button button-type-counter' onClick={this.handleClick}>
                        {locales.fix.associatedData}
                    </span>
                </Popover>
            );
        }
    };
};

// 初始化Markdown解析器
const mdParser = new MarkdownIt(/* Markdown-it options */);
MdEditor.unuse(Plugins.Image);
MdEditor.unuse(Plugins.Table);
MdEditor.unuse(Plugins.BlockCodeInline);
MdEditor.unuse(Plugins.BlockCodeBlock);

let MdEditorCustomPlugin = null;

export default class MassagePushConfigurationTemplate extends MassagePushConfigurationComponent {
    componentWillUnmount () {
        if (MdEditorCustomPlugin) {
            // 销毁 MdEditorCustomPlugin
            MdEditor.unuse(MdEditorCustomPlugin);
            MdEditorCustomPlugin = null;
        }
    }
    render() {
        let { privates, props, actions, locales } = this;
        let { pushConf, taskType, taskTypeList} = props;
        
        if (taskType && !MdEditorCustomPlugin) {
            // 创建 MdEditorCustomPlugin
            MdEditorCustomPlugin = Udesk.react.udeskify(CreateMdEditorCustomPlugin({
                taskType,
                taskTypeList,
            }));
            MdEditor.use(MdEditorCustomPlugin);
        }

        return (
            <React.Fragment>
                <If
                    condition={[Udesk.enums.messagePushType.interfacePush.id].includes(
                        parseInt(pushConf.actionType, 10)
                    )}
                >
                    <div className='massage-push-configuration-row not-baseline'>
                        <div className='massage-push-configuration-row-label'>
                            {locales.components.massageConfiguration.sdkPushType}
                        </div>
                        <div className='massage-push-configuration-row-content'>
                            <Radio.Group
                                size='small'
                                options={privates.sdkPushType}
                                value={pushConf.actionDetails.type}
                                onChange={actions.onSdkPushTypeChange.params(pushConf.actionType)}
                            />
                        </div>
                    </div>
                    <div className='massage-push-configuration-row'>
                        <div className='massage-push-configuration-row-label'>
                            {locales.components.massageConfiguration.sdkPushURL}
                        </div>
                        <div className='massage-push-configuration-row-content'>
                            <Input
                                value={pushConf.actionDetails.url}
                                onChange={actions.onInputChange.params(pushConf.actionType, 'url')}
                            />
                        </div>
                    </div>
                </If>
                <If condition={[Udesk.enums.messagePushType.weChat.id].includes(parseInt(pushConf.actionType, 10))}>
                    <div className='massage-push-configuration-row not-baseline'>
                        <div className='massage-push-configuration-row-label'>
                            {locales.components.massageConfiguration.messageType}
                        </div>
                        <div className='massage-push-configuration-row-content'>
                            <Radio.Group
                                options={Udesk.enums.messageTypes.map((i) => ({ label: i.name, value: i.id }))}
                                value={pushConf.actionDetails.messageType}
                                onChange={actions.onMessageTypeChange.params(pushConf.actionType)}
                            />
                        </div>
                    </div>
                </If>
                {/* 标准WEBHOOK */}
                <If condition={pushConf.actionDetails.type === Udesk.enums.sdkPushType.webHook.id}>
                    <div className='massage-push-configuration-row'>
                        <div className='massage-push-configuration-row-label'>
                            {locales.components.massageConfiguration.sdkPushWay}
                        </div>
                        <div className='massage-push-configuration-row-content'>
                            <Input defaultValue={pushConf.actionDetails.requestMethod} disabled />
                        </div>
                    </div>
                    <div className='massage-push-configuration-row'>
                        <div className='massage-push-configuration-row-label'>
                            {locales.components.massageConfiguration.dataType}
                        </div>
                        <div className='massage-push-configuration-row-content'>
                            <Input defaultValue={pushConf.actionDetails.contentType} disabled />
                        </div>
                    </div>
                    <div className='massage-push-configuration-row massage-push-configuration-last-row'>
                        <div className='massage-push-configuration-row-label'>
                            {locales.components.massageConfiguration.additionalData}
                        </div>
                        <div className='massage-push-configuration-row-content'>
                            <UdeskList deleteItem={actions.deleteAdditionalData} addItem={actions.addAdditionalData}>
                                {pushConf.actionDetails.additionalDataList.map((data) => {
                                    return (
                                        <Space childId={data._id}>
                                            <Input
                                                key={`${data._id}-key`}
                                                defaultValue={data.key}
                                                onChange={actions.onAdditionalDataChange.params(
                                                    data._id,
                                                    'key',
                                                    pushConf.actionType
                                                )}
                                            />
                                            <Input
                                                key={`${data._id}-value`}
                                                defaultValue={data.value}
                                                onChange={actions.onAdditionalDataChange.params(
                                                    data._id,
                                                    'value',
                                                    pushConf.actionType
                                                )}
                                            />
                                        </Space>
                                    );
                                })}
                            </UdeskList>
                        </div>
                    </div>
                </If>
                <If
                    condition={
                        pushConf.actionDetails.type !== Udesk.enums.sdkPushType.webHook.id &&
                        pushConf.actionDetails.messageType !== Udesk.enums.messageTypes.markdown.id
                    }
                >
                    <div className='massage-push-configuration-row not-baseline'>
                        <div className='massage-push-configuration-row-label'>
                            {locales.components.massageConfiguration.pushData}
                        </div>
                        <div
                            className='massage-push-configuration-row-content text-area-content'
                            ref={privates.associateDivRef}
                        >
                            <Input.TextArea
                                ref={privates.textAreaRef}
                                value={pushConf.actionDetails.content}
                                onChange={actions.onInputChange.params(pushConf.actionType, 'content')}
                                autoSize={{ minRows: 16, maxRows: 20 }}
                            />
                            <div
                                style={{
                                    width: 280,
                                    padding: '0 16px',
                                    height: 360,
                                    marginLeft: 16,
                                    border: '1px solid rgba(0, 0, 0, 0.1)',
                                }}
                                // onClick={(e) => actions.associateInputFocus()}
                            >
                                <AssociateSelect
                                    pushConfContentChange={actions.pushConfContentChange}
                                    pushConf={pushConf}
                                    associateDivRef={privates.associateDivRef}
                                    textAreaRef={privates.textAreaRef}
                                    taskType={props.taskType}
                                    taskTypeList={props.taskTypeList}
                                    taskId={props.taskId}
                                ></AssociateSelect>
                            </div>
                        </div>
                    </div>
                </If>
                <If condition={pushConf.actionDetails.messageType === Udesk.enums.messageTypes.markdown.id}>
                    <div className='massage-push-configuration-row not-baseline'>
                        <div className='massage-push-configuration-row-label'>
                            {locales.components.massageConfiguration.pushData}
                        </div>
                        <div className='massage-push-configuration-row-content text-area-content'>
                            <MdEditor
                                style={{ height: '500px' }}
                                renderHTML={(text) => mdParser.render(text)}
                                onChange={actions.handleMarkdownEditorChange.params(pushConf.actionType)}
                                value={pushConf.actionDetails.content}
                            />
                        </div>
                    </div>
                </If>
            </React.Fragment>
        );
    }
}
