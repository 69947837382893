import Udesk from 'Udesk';
import SystemLogComponent from './component';

class SystemLogRoute extends SystemLogComponent {
    asyncModel(changedParams) {
        let { sdkOptions } = this.props;
        let params = this.privates.queryParams;

        if (changedParams != null) {
            Object.assign(params, changedParams);
        }
        this.setState({
            modelLoading: true,
        });
        let url = Udesk.business.apiPath.concatApiPath(`message-logs`, sdkOptions);
        let messageLogs = new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    this.setState({
                        modelLoading: false,
                    });
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            messageLogs
        };
    }
}

export default SystemLogRoute;
