// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { PostCallManageBatchReAsrBody, BaseResponseVoid } from '../../../../types';

/**
 * 通话列表批量重新识别
 *
 * @export
 * @tags data-call-batch-re-asr-controller
 * @link [POST] /call/manage/batch/reAsr
 */
export function postCallManageBatchReAsr(
    data: PostCallManageBatchReAsrBody
): Promise<BaseResponseVoid>;
export function postCallManageBatchReAsr(
    data: PostCallManageBatchReAsrBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postCallManageBatchReAsr(
    data: PostCallManageBatchReAsrBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostCallManageBatchReAsrBody, never, never>('/call/manage/batch/reAsr', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCallManageBatchReAsr',
    });
}

export const meta = [
    {
        tags: ['data-call-batch-re-asr-controller'],
        path: '/call/manage/batch/reAsr',
        method: 'post',
    },
];
