import React, { FC, memo } from 'react';
import List, { TemplateProps as ListProps } from './components/list';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    min-width: 340px;
`;

const Content = styled.div`
    margin-bottom: 8px;
    padding: 8px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
`;

const Title = styled.div``;

export type TemplateProps = {
    value?: ListProps['value'];
    onChange?: (v: TemplateProps['value']) => void;
    fields?: ListProps['fields'];
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange, fields } = props;

    const onSatisfyAllConditionsChange = (v: ListProps['value']) => {
        onChange?.(v);
    };

    return (
        <Wrapper>
            <Content>
                <Title>{/* 满足以下全部条件 */}{UdeskLocales['current'].pages.gong.saleClientCenter.list.components.advancedFilter.index.meetAllOfTheFollowingConditions}</Title>
                <List fields={fields} value={value} onChange={onSatisfyAllConditionsChange} />
            </Content>
        </Wrapper>
    );
};

export default memo(Template);
