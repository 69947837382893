import Udesk from 'Udesk';
import React from 'react';
import { TreeSelect, PageHeader, Select, Form, Input, Radio,Button, InputNumber, Checkbox, Tabs, Row, Col } from 'udesk-ui';
import './style-new.scss';
import UdeskLocales from 'UdeskLocales';
import TasksCheckPointEditRoute from './route';
import TaskConditionDictionaries from 'Component/pages/components/task-condition-dictionaries';
import TaskGearOptionList from 'Component/pages/components/task-gear-option-list';
import ConditionListGather from 'Component/pages/template-test/check-point-condition-list-gather';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { TabPane } = Tabs;
const { Option } = Select;
const grid = 8;
const getListStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});
const getListDropStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    width: "100%",
    border: '1px solid #e8e8e8',
    minHeight: '40px'
});
const getListDropStyles = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    minWidth: "400px",
    border: '1px solid #e8e8e8',
    minHeight: '40px'
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    width: '32px',
    height: '24px',
    color: 'rgba(0,0,0,0.65)',
    borderRadius: '3px',
    margin: `0 ${grid}px 0 0 `,
    textAlign: 'center',
    lineHeight: '24px',
    // 拖拽的时候背景变化
    background: isDragging ? "lightgreen" : "rgba(232,244,255,1)",
    marginBottom: '3px',
    ...draggableStyle
});

export default class TasksCheckPointDetailTemplate extends TasksCheckPointEditRoute {
    render() {
        let { actions, privates, state } = this;
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={UdeskLocales.current.fix.checkPoint1}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root tasks-manage-template-configuration-detail-body-content'>
                        <div>
                            <div className='tasks-manage-template-configuration-detail-form'>
                                <Form
                                    wrapperCol={{ span: 23, offset: 1 }}
                                    initialValues={{
                                        type: privates.type,
                                        tagName: privates.name,
                                        desc: privates.remark,
                                    }}
                                    onValuesChange={actions.onFormValuesChange}
                                >
                                    <Form.Item
                                        name="tagName"
                                        label={UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.neme}
                                        rules={[
                                            {
                                                whitespace: true,
                                                required: true,
                                                message: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.nameEmpty,
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled
                                            style={{ width: 304 }}
                                            placeholder={UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.desPlaceHolder}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="desc"
                                        style={{ marginLeft: 10 }}
                                        label={UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.desc}
                                    >
                                        <Input
                                            disabled
                                            style={{ width: 304 }}
                                            placeholder={UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.desPlaceHolder}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='type'
                                        label={UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.type}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Radio.Group disabled>
                                            <Radio value={Udesk.enums.pointTypes.smartRules.id}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruler}</Radio>
                                            <If condition={this.privates.model.taskVersion && !this.privates.model.taskVersion.pointVersion}>
                                                <Radio value={Udesk.enums.pointTypes.automatic.id}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.autoScore}</Radio>
                                                <Radio value={Udesk.enums.pointTypes.interactiveDetection.id}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.interaction}</Radio>
                                            </If>
                                            <Radio value={Udesk.enums.pointTypes.manual.id}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.manualScore}</Radio>
                                            <Radio value={Udesk.enums.pointTypes.machineLearning.id}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.machineLearning}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Form>

                            </div>
                            <If condition={privates.type === Udesk.enums.pointTypes.smartRules.id}>
                                <ConditionListGather
                                    conditionList={privates.ruleList}
                                    type={[privates.model.task && privates.model.task.inspectDataSource]}
                                    onChange={actions.getConditionList}
                                ></ConditionListGather>
                                <div className='tasks-manage-template-configuration-detail-logic'>
                                    <div className='tasks-manage-template-configuration-detail-logic-title'>
                                        {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.logic}
                                    </div>
                                    <div className='tasks-manage-template-configuration-detail-logic-score'>
                                        {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.score}
                                        <Select disabled value={privates.scoreType} style={{ width: 86 }} onChange={actions.handleScoreChange}>
                                            {Udesk.enums.calculateScoreTypes.map(item => {
                                                return (
                                                    <Option value={item.id}>{item.name}</Option>
                                                );
                                            })}
                                            {/* {UdeskLocales.current.enums.tasks.calculateScoreTypes}
                                        Udesk.enums.calculateScoreTypes.add.id */}
                                        </Select>
                                        <InputNumber
                                            disabled
                                            min={1}
                                            style={{ width: 200 }}
                                            value={privates.predeterminedScore}
                                            onChange={actions.handleScoreInput}
                                            addonAfter={UdeskLocales.current.labels.scoreUnit}
                                        >
                                        </InputNumber>
                                    </div>
                                    <div className='tasks-manage-template-configuration-detail-logic-na'>
                                        <div style={{ paddingTop: 6, paddingLeft: 4 }}>
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.na}
                                        </div>
                                        <div className='tasks-manage-template-configuration-detail-logic-na-content'>
                                            <Checkbox disabled onChange={actions.onNAChange} checked={privates.naChecked}>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.naDes}
                                            </Checkbox>
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.preRule}
                                            <Input
                                                value={privates.naPreviousFormula}
                                                onChange={actions.naPreviousFormulaChange}
                                                style={{ width: 200 }}
                                                disabled
                                            >
                                            </Input>
                                            <div style={{ marginTop: 16, marginBottom: 29 }}>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.notCatchPreRule}
                                                <Radio.Group disabled onChange={actions.onNotCatchPreRuleChange} value={privates.naScoreType}>
                                                    {Udesk.enums.naScoreType.map((item, index) => {
                                                        return (
                                                            <Radio value={item.id}>{item.name}</Radio>
                                                        );
                                                    })}
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='tasks-manage-template-configuration-detail-logic-rule'>
                                        {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.rule}
                                        <Radio.Group disabled onChange={actions.onGradeTypeChange} value={privates.gradeType}>
                                            <Radio value={1}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.all}</Radio>
                                            <Radio value={2}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.any}</Radio>
                                            <Radio value={3}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.customize}</Radio>
                                            <Radio value={31}>{UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.multiple}</Radio>
                                        </Radio.Group>
                                        <If condition={privates.gradeType === 3}>
                                            {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                            <Tabs defaultActiveKey="2" disabled >
                                                <TabPane tab={UdeskLocales.current.components.common.customFilter.manualConfiguration} key="1">
                                                    <Input placeholder={UdeskLocales.current.components.common.customFilter.holderCustomRule} value={privates.operatorLogic} onChange={actions.customJudgeLogicChange} />
                                                    {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                                </TabPane>
                                                <TabPane tab={UdeskLocales.current.components.common.customFilter.dragConfiguration} key="2">
                                                    <DragDropContext onDragEnd={actions.onDragEnd} onDragStart={actions.onDragStart}>
                                                        <div className='data-preprocess-custom'>
                                                            <div className='data-preprocess-custom-drap-title'>{UdeskLocales.current.components.common.customFilter.condition}：</div>
                                                            <div className='data-preprocess-cutom-drap-info'>
                                                                <Droppable droppableId="droppables" direction="horizontal" isDropDisabled={true}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                                        >
                                                                            {privates.conditionListFilter.map((item, index) => (
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            {item.content}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </div>
                                                        <div className='data-preprocess-custom'>
                                                            <div className='data-preprocess-custom-drap-title'>{UdeskLocales.current.components.common.customFilter.condition}：</div>
                                                            <div className='data-preprocess-cutom-drap-info'>
                                                                <Droppable droppableId="droppabless" direction="horizontal" isDropDisabled={true}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={getListStyle(snapshot.isDragging)}
                                                                        >
                                                                            {privates.logicList.filter(item => item.flag).map((item, index) => (
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            {item.content}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </div>
                                                        <div className='data-preprocess-custom'>
                                                            {
                                                                privates.tarskDel && <div className='index-manmagement-custom-drag-tarsk'>{UdeskLocales.current.components.common.customFilter.infoDel}</div>
                                                            }
                                                            <div className='data-preprocess-custom-drap-title'>{UdeskLocales.current.components.common.customFilter.toConfigure}：</div>
                                                            <div className='data-preprocess-cutom-drap-info'>
                                                                <Droppable droppableId="droppable" direction="horizontal">
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={getListDropStyle(snapshot.isDraggingOver)}
                                                                        >
                                                                            {this.privates.items.map((item, index) => (
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            {item.content}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </div>
                                                    </DragDropContext>
                                                </TabPane>
                                            </Tabs>
                                        </If>
                                        <If condition={privates.gradeType === 31}>
                                            <Tabs disabled defaultActiveKey="2" >
                                                <TabPane tab={UdeskLocales.current.components.common.customFilter.manualConfiguration} key="1">
                                                    {
                                                        privates.operatorMultipleLogicList.map((item, index) => {
                                                            return (
                                                                <div style={{ marginBottom: 8 }}>
                                                                    <Input
                                                                        placeholder={UdeskLocales.current.components.common.customFilter.holderCustomRule}
                                                                        value={item.formula}
                                                                        style={{ marginRight: 16, width: 200 }}
                                                                        onChange={actions.operatorMultipleLogicChange.params(index)}
                                                                    />
                                                                    {privates.scoreType === Udesk.enums.calculateScoreTypes.add.id ? UdeskLocales.current.enums.tasks.calculateScoreTypes.add : UdeskLocales.current.enums.tasks.calculateScoreTypes.sub}
                                                                    <InputNumber
                                                                        min={0}
                                                                        style={{ marginRight: 16, width: 100 }}
                                                                        value={item.score}
                                                                        onChange={actions.operatorMultipleFractionChange.params(index)}
                                                                    // addonAfter={UdeskLocales.current.labels.scoreUnit}
                                                                    />
                                                                    {UdeskLocales.current.components.gearOptionList.evaluation}
                                                                    <Input
                                                                        // placeholder={UdeskLocales.current.components.common.customFilter.holderCustomRule}
                                                                        value={item.evaluation}
                                                                        style={{ marginRight: 16, width: 200 }}
                                                                        onChange={actions.operatorMultipleAssessChange.params(index)}
                                                                    />
                                                                    <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={actions.operatorMultipleDelete.params(index)}>
                                                                        <span class="udesk-qa-react-web-iconfont">&#xe653;</span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div onClick={actions.operatorMultipleAdd} style={{ display: 'inline-block' }}>
                                                        <i class="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-add-circle-jia" style={{ fontSize: '25px', cursor: 'pointer' }} />
                                                    </div>
                                                    {/* <input type="text" className="form-control" defaultValue={operatorLogic} onChange={actions.mutator("privates.operatorLogic", e => e.target.value)} /> */}
                                                </TabPane>
                                                <TabPane tab={UdeskLocales.current.components.common.customFilter.dragConfiguration} key="2">
                                                    <DragDropContext onDragEnd={actions.onDragEndMultiple} onDragStart={actions.onDragStart}>
                                                        <div className='data-preprocess-custom'>
                                                            <div className='data-preprocess-custom-drap-title'>{UdeskLocales.current.components.common.customFilter.condition}：</div>
                                                            <div className='data-preprocess-cutom-drap-info'>
                                                                <Droppable droppableId="droppables" direction="horizontal" isDropDisabled={true}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                                        >
                                                                            {privates.conditionListFilter.map((item, index) => (
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            {item.content}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </div>
                                                        <div className='data-preprocess-custom'>
                                                            <div className='data-preprocess-custom-drap-title'>{UdeskLocales.current.components.common.customFilter.condition}：</div>
                                                            <div className='data-preprocess-cutom-drap-info'>
                                                                <Droppable droppableId="droppabless" direction="horizontal" isDropDisabled={true}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={getListStyle(snapshot.isDragging)}
                                                                        >
                                                                            {privates.logicList.filter(item => item.flag).map((item, index) => (
                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            {item.content}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </div>
                                                        <div className='data-preprocess-custom'>
                                                            {
                                                                privates.tarskDel && <div className='index-manmagement-custom-drag-tarsk'>{UdeskLocales.current.components.common.customFilter.infoDel}</div>
                                                            }
                                                            <div className='data-preprocess-custom-drap-title'>{UdeskLocales.current.components.common.customFilter.toConfigure}：</div>
                                                            <div className='data-preprocess-cutom-drap-info' style={{ flexDirection: 'column' }}>
                                                                {
                                                                    privates.operatorMultipleLogicitems.map((items, index) => {
                                                                        return (
                                                                            <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                                                <div style={{ height: 44, lineHeight: '44px', marginRight: 8 }}>
                                                                                    {index + 1}
                                                                                </div>
                                                                                <Droppable droppableId={`configuration${index}`} direction="horizontal">
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            {...provided.droppableProps}
                                                                                            ref={provided.innerRef}
                                                                                            style={getListDropStyles(snapshot.isDraggingOver)}
                                                                                        >
                                                                                            {items.filter(item => !!item).map((item, index) => (
                                                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                                    {(provided, snapshot) => (
                                                                                                        <div
                                                                                                            ref={provided.innerRef}
                                                                                                            {...provided.draggableProps}
                                                                                                            {...provided.dragHandleProps}
                                                                                                            style={getItemStyle(
                                                                                                                snapshot.isDragging,
                                                                                                                provided.draggableProps.style
                                                                                                            )}
                                                                                                        >
                                                                                                            {item.content}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Draggable>
                                                                                            ))}
                                                                                            {provided.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                                <span style={{ width: 30, marginLeft: 8, lineHeight: '44px' }}>
                                                                                    {privates.scoreType === Udesk.enums.calculateScoreTypes.add.id ? UdeskLocales.current.enums.tasks.calculateScoreTypes.add : UdeskLocales.current.enums.tasks.calculateScoreTypes.sub}
                                                                                </span>
                                                                                <InputNumber
                                                                                    min={0}
                                                                                    style={{ marginRight: 16, width: 100, lineHeight: '44px' }}
                                                                                    value={privates.operatorMultipleLogicList[index].score}
                                                                                    onChange={actions.operatorMultipleFractionChange.params(index)}
                                                                                // addonAfter={UdeskLocales.current.labels.scoreUnit}
                                                                                />
                                                                                <span style={{ width: 30, marginLeft: 8, lineHeight: '44px' }}>
                                                                                    {UdeskLocales.current.components.gearOptionList.evaluation}
                                                                                </span>
                                                                                <Input
                                                                                    // placeholder={UdeskLocales.current.components.common.customFilter.holderCustomRule}
                                                                                    value={privates.operatorMultipleLogicList[index].evaluation}
                                                                                    style={{ marginRight: 16, width: 200 }}
                                                                                    onChange={actions.operatorMultipleAssessChange.params(index)}
                                                                                />
                                                                                <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={actions.operatorMultipleDelete.params(index)}>
                                                                                    <span class="udesk-qa-react-web-iconfont" style={{ lineHeight: '46px' }}>&#xe653;</span>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </DragDropContext>
                                                    <div onClick={actions.operatorMultipleAdd} style={{ marginLeft: '32px', display: 'inline-block' }}>
                                                        <i class="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-add-circle-jia" style={{ fontSize: '25px', cursor: 'pointer' }} />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </If>
                                        {/* <Input placeholder={UdeskLocales.current.components.common.customFilter.holderCustomRule} value={privates.customJudgeLogic} onChange={actions.customJudgeLogicChange} /> */}
                                        {/* <div style={{ marginTop: '10px' }}>
                                            {UdeskLocales.current.components.common.customFilter.example}：1 or 2 and (3 and 4)
                                        </div> */}
                                    </div>
                                    <div className='tasks-manage-template-configuration-detail-logic-heightLight'>
                                        {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.heightLight}
                                        <Checkbox.Group
                                            disabled
                                            options={privates.ruleList && privates.ruleList.length > 0 && privates.ruleList.map(item => { return { label: item.idCode, value: item.idCode }; })}
                                            onChange={actions.heightLightChange}
                                            value={privates.heightLightList}
                                        />
                                    </div>
                                </div>
                            </If>
                            <If condition={privates.type === Udesk.enums.pointTypes.automatic.id}>
                                <form className="form-horizontal form-label-left">
                                    <div className="form-group">
                                        <label
                                            htmlFor="checkPoint_gradeType"
                                            style={{ padding: "0 15px" }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                            <span className="required">*</span>
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.gradeType}
                                        </label>
                                        <div className="select-container col-lg-7 col-md-7 col-xs-12 col-12">
                                            <Select
                                                disabled
                                                value={privates.model.inspectionPoint.gradeType}
                                                className="form-control form-control-score-type"
                                                onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'gradeType')}>
                                                <For each="item" index="index" of={privates.computes.gradeTypes}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            style={{ padding: "0 15px" }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                            htmlFor="checkPoint_deduction">
                                            <span className="required">*</span>
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.predeterminedScore}
                                        </label>
                                        <div className="col-lg-2 col-md-3 col-xs-12 col-12">
                                            <Select
                                                disabled
                                                value={privates.model.inspectionPoint.scoreType}
                                                className="form-control form-control-score-type"
                                                onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'scoreType')}>
                                                <For each="item" index="index" of={Udesk.enums.calculateScoreTypes}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-xs-12 col-12">
                                            <input
                                                disabled
                                                type="number"
                                                id="checkPoint_deduction"
                                                className="form-control input-sm-wide deduction-input"
                                                value={privates.model.inspectionPoint.predeterminedScore}
                                                onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'predeterminedScore')}
                                            />
                                            <span>{UdeskLocales.current.labels.minute}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            style={{ padding: "0 15px" }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                            htmlFor="checkPoint_na">
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.na}
                                        </label>
                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                            <Checkbox disabled checked={privates.model.inspectionPoint.naChecked} onChange={actions.changePointNaChecked}>
                                                {UdeskLocales.current.enums.tasks.naChecked.check}
                                            </Checkbox>
                                            {/* 智能评分 */}
                                            <If condition={privates.type === Udesk.enums.scoreType.aiScore.id ||
                                                privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.multipleFactors.id}>
                                                <div>
                                                    <span className="check-point-na-previous-formula-title">
                                                        {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.preRule}
                                                    </span>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        className="form-control check-point-na-previous-formula"
                                                        value={privates.model.inspectionPoint.naPreviousFormula}
                                                        onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'naPreviousFormula')}
                                                    />
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                    <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.level.id}>
                                        <div className="form-group">
                                            <label
                                                style={{ padding: "0 15px" }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_na">
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.rule}
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <TaskGearOptionList
                                                    gearOptions={privates.model.inspectionPoint.ruleList}
                                                    onChange={actions.getGearOptionListOld}
                                                    scoreType={privates.model.inspectionPoint.scoreType}
                                                    valueField="score"
                                                    evaluationField="evaluation"
                                                    formulaField="formula"
                                                    type={privates.type}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    {/* 智能评分 */}
                                    <If condition={privates.type === Udesk.enums.scoreType.aiScore.id &&
                                        privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.yesOrNo.id}>
                                        <div className="form-group">
                                            <label
                                                style={{ padding: "0 15px" }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_rule">
                                                <span className="required">*</span>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.rule}
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <textarea
                                                    disabled
                                                    id="checkPoint_rule"
                                                    className="form-control rule-input"
                                                    maxLength="255"
                                                    rows="5"
                                                    value={state.conditionLogic}
                                                    onChange={actions.onValueChanged.params(state, 'conditionLogic')}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    {/* 智能评分 */}
                                    <If condition={privates.type === Udesk.enums.scoreType.aiScore.id}>
                                        <div className="form-group">
                                            <label
                                                style={{ padding: "0 15px" }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_highlighted">
                                                <span className="required">*</span>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.highlighted}
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <button
                                                    disabled
                                                    type="button"
                                                    className="btn btn-xs btn-default"
                                                    onClick={actions.generateHighlight}>
                                                    {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.analysisHighlighted}
                                                </button>
                                                <For each="item" index="index" of={state.relateConditionList}>
                                                    <label key={item.idCode} htmlFor={index}>
                                                        <input
                                                            disabled
                                                            id={index}
                                                            type="checkbox"
                                                            checked={item.highlight}
                                                            className="high-light-check-box"
                                                            onChange={actions.onValueChanged.params(item, 'highlight')}
                                                        />
                                                        {item.idCode}
                                                    </label>
                                                </For>
                                            </div>
                                        </div>
                                    </If>

                                </form>
                                <TaskConditionDictionaries items={privates.model.inspectionConditions} />
                            </If>
                            <If condition={privates.type === Udesk.enums.pointTypes.interactiveDetection.id}>
                                <form className="form-horizontal form-label-left">
                                    {/* <div className="form-group">
                                        <label
                                            style={{ padding: "0 15px" }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.type}
                                            <span className="required">*</span>
                                        </label>
                                        <div className="select-container col-lg-7 col-md-7 col-xs-12 col-12">
                                            <Select
                                                value={privates.model.inspectionPoint.type}
                                                className="form-control form-control-score-type"
                                                onChange={actions.changeScoreType}>
                                                {
                                                    state.scoreType.map(item => {
                                                        if(item.id !== Udesk.enums.scoreType.manualScore.id){
                                                            return (
                                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                            );
                                                        }
                                                    })
                                                } */}
                                    {/* <For each="item" index="index" of={state.scoreType}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For> */}
                                    {/* </Select>
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <label
                                            htmlFor="checkPoint_gradeType"
                                            style={{ padding: "0 15px" }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                            <span className="required">*</span>
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.gradeType}
                                        </label>
                                        <div className="select-container col-lg-7 col-md-7 col-xs-12 col-12">
                                            <Select
                                                disabled
                                                value={privates.model.inspectionPoint.gradeType}
                                                className="form-control form-control-score-type"
                                                onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'gradeType')}>
                                                <For each="item" index="index" of={privates.computes.gradeTypes}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </div>
                                    </div>
                                    {/* 交互检测 */}
                                    <If condition={privates.type === Udesk.enums.scoreType.interactiveDetection.id
                                        && privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.yesOrNo.id}>
                                        <div className="form-group">
                                            <label
                                                htmlFor="checkPoint_interactive_category"
                                                style={{ padding: "0 15px" }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                <span className="required">*</span>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.interactiveCategory}
                                            </label>
                                            <div className="interactive-category-container col-lg-7 col-md-7 col-xs-12 col-12">
                                                <TreeSelect
                                                    disabled
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    value={state.selectedCategory.id}
                                                    treeData={privates.model.categories}
                                                    treeDefaultExpandAll
                                                    onChange={actions.switchNode}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    <div className="form-group">
                                        <label
                                            style={{ padding: "0 15px" }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                            htmlFor="checkPoint_deduction">
                                            <span className="required">*</span>
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.predeterminedScore}
                                        </label>
                                        <div className="col-lg-2 col-md-3 col-xs-12 col-12">
                                            <Select
                                                disabled
                                                value={privates.model.inspectionPoint.scoreType}
                                                className="form-control form-control-score-type"
                                                onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'scoreType')}>
                                                <For each="item" index="index" of={Udesk.enums.calculateScoreTypes}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-xs-12 col-12">
                                            <input
                                                disabled
                                                type="number"
                                                id="checkPoint_deduction"
                                                className="form-control input-sm-wide deduction-input"
                                                value={privates.model.inspectionPoint.predeterminedScore}
                                                onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'predeterminedScore')}
                                            />
                                            <span>{UdeskLocales.current.labels.minute}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            style={{ padding: "0 15px" }}
                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                            htmlFor="checkPoint_na">
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.na}
                                        </label>
                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                            <Checkbox disabled checked={privates.model.inspectionPoint.naChecked} onChange={actions.changePointNaChecked}>
                                                {UdeskLocales.current.enums.tasks.naChecked.check}
                                            </Checkbox>
                                        </div>
                                    </div>
                                    <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.multipleFactors.id}>
                                        <div className="form-group">
                                            <label className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.preRule}
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <Input
                                                    disabled
                                                    className="check-point-na-previous-formula"
                                                    value={privates.model.inspectionPoint.naPreviousFormula}
                                                    onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'naPreviousFormula')}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    {/* 交互检测 */}
                                    <If condition={privates.type === Udesk.enums.scoreType.interactiveDetection.id}>
                                        <div className="form-group">
                                            <label
                                                style={{ padding: "0 15px" }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_na">
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.rule}
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.multipleFactors.id &&
                                                    state.foctorsData.length > 0}>
                                                    <Select
                                                        disabled
                                                        value={state.foctorsValue}
                                                        style={{ width: '40%' }}
                                                        className="form-control form-control-score-type foctors-select-style"
                                                        onChange={actions.onValueChanged.params(state, 'foctorsValue')}>
                                                        <If condition={state.foctorsFlag === true}>
                                                            <Select.Option value={state.foctorsNoneValue.id}>{state.foctorsNoneValue.name}</Select.Option>
                                                        </If>
                                                        <For each="item" index="index" of={state.foctorsData}>
                                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                        </For>
                                                    </Select>
                                                    <Select
                                                        disabled
                                                        value={state.foctorsHit}
                                                        style={{ width: '40%', marginLeft: '5px' }}
                                                        className="form-control form-control-score-type foctors-select-style"
                                                        onChange={actions.onValueChanged.params(state, 'foctorsHit')}>
                                                        <For each="item" index="index" of={Udesk.enums.inspectFortorsType}>
                                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                        </For>
                                                    </Select>
                                                </If>
                                                <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.yesOrNo.id}>
                                                    <Radio.Group
                                                        disabled
                                                        value={privates.model.inspectionPoint.interactiveRule}
                                                        onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'interactiveRule')}>
                                                        <For each="item" index="index" of={Udesk.enums.interactiveRules}>
                                                            <Radio key={item.id} value={item.id}>{item.name}</Radio>
                                                        </For>
                                                    </Radio.Group>
                                                </If>
                                            </div>
                                        </div>
                                    </If>
                                    <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.level.id}>
                                        <div className="form-group">
                                            <label
                                                style={{ padding: "0 15px" }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_na">
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.rule}
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <TaskGearOptionList
                                                    gearOptions={privates.model.inspectionPoint.ruleList}
                                                    onChange={actions.getGearOptionListOld}
                                                    scoreType={privates.model.inspectionPoint.scoreType}
                                                    valueField="score"
                                                    evaluationField="evaluation"
                                                    formulaField="formula"
                                                    type={privates.type}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    {/* 交互检测 */}
                                    <If condition={privates.type === Udesk.enums.scoreType.interactiveDetection.id}>
                                        <div className="form-group">
                                            <label
                                                style={{ padding: "0 15px" }}
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                htmlFor="checkPoint_highlighted">
                                                <span className="required">*</span>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.highlighted}
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.yesOrNo.id}>
                                                    <Checkbox.Group
                                                        disabled
                                                        value={state.interactiveRelateConditionList}
                                                        onChange={actions.onValueChanged.params(state, 'interactiveRelateConditionList')}>
                                                        <For each="item" index="index" of={Udesk.enums.relateConditions}>
                                                            <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>
                                                        </For>
                                                    </Checkbox.Group>
                                                </If>
                                                <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.multipleFactors.id}>
                                                    <Checkbox.Group
                                                        disabled
                                                        value={state.interactiveRelateConditionList}
                                                        onChange={actions.onValueChanged.params(state, 'interactiveRelateConditionList')}>
                                                        <For each="item" index="index" of={Udesk.enums.relateConditionsHit}>
                                                            <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>
                                                        </For>
                                                    </Checkbox.Group>
                                                </If>
                                            </div>
                                        </div>
                                    </If>
                                    <div className="form-group">
                                        <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                            <button type="button" className="btn btn-success" onClick={actions.save}>
                                                {UdeskLocales.current.labels.save}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <TaskConditionDictionaries items={privates.model.inspectionConditions} />
                            </If>
                            <If condition={privates.type === Udesk.enums.pointTypes.manual.id}>
                                <div style={{ width: 700, marginLeft: 76 }}>
                                    <Row>
                                        <Col span={3}>
                                            <label
                                                htmlFor="checkPoint_gradeType">
                                                <span style={{ color: '#ff4d4f', marginRight: 4 }}>*</span>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.gradeType}
                                            </label>

                                        </Col>
                                        <Col span={8}>
                                            <div>
                                                <Select
                                                    disabled
                                                    style={{ width: 112 }}
                                                    value={privates.model.inspectionPoint.gradeType}
                                                    onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'gradeType')}>
                                                    <For each="item" index="index" of={privates.computes.gradeTypes}>
                                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                    </For>
                                                </Select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 24 }}>
                                        <Col span={3}>
                                            <label
                                                htmlFor="checkPoint_deduction">
                                                <span style={{ color: '#ff4d4f', marginRight: 4 }}>*</span>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.predeterminedScore}
                                            </label>
                                        </Col>
                                        <Col span={4}>
                                            <div>
                                                <Select
                                                    disabled
                                                    style={{ width: 86 }}
                                                    value={privates.model.inspectionPoint.scoreType}
                                                    onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'scoreType')}>
                                                    <For each="item" index="index" of={Udesk.enums.calculateScoreTypes}>
                                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                    </For>
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div>
                                                <InputNumber
                                                    disabled
                                                    style={{ width: 198 }}
                                                    id="checkPoint_deduction"
                                                    value={privates.model.inspectionPoint.predeterminedScore}
                                                    onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'predeterminedScore')}
                                                />
                                                <span>{UdeskLocales.current.labels.minute}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 24 }}>
                                        <Col span={3}>
                                            <label
                                                htmlFor="checkPoint_na">
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.na}
                                            </label>
                                        </Col>
                                        <Col span={16}>
                                            <div>
                                                <Checkbox disabled checked={privates.model.inspectionPoint.naChecked} onChange={actions.changePointNaChecked}>
                                                    {UdeskLocales.current.enums.tasks.naChecked.check}
                                                </Checkbox>
                                            </div>
                                            <div style={{ marginTop: 16 }}>
                                                {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.naChecked}
                                                <Radio.Group disabled onChange={actions.onNotCatchPreRuleChange} value={privates.naScoreType}>
                                                    {Udesk.enums.naScoreType.map((item, index) => {
                                                        return (
                                                            <Radio value={item.id}>{item.name}</Radio>
                                                        );
                                                    })}
                                                </Radio.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                    <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.level.id}>
                                        <Row style={{ marginTop: 24 }}>
                                            <Col span={3}>
                                                <label
                                                    htmlFor="checkPoint_na">
                                                    {UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.rule}
                                                </label>
                                            </Col>
                                            <Col span={8}>
                                                <div style={{ width: 500 }}>
                                                    <TaskGearOptionList
                                                        gearOptions={privates.model.inspectionPoint.ruleList}
                                                        onChange={actions.getGearOptionListOld}
                                                        scoreType={privates.model.inspectionPoint.scoreType}
                                                        valueField="score"
                                                        evaluationField="evaluation"
                                                        formulaField="formula"
                                                        type={Udesk.enums.scoreType.manualScore.id}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </If>
                                    <Row style={{ marginTop: 24 }}>
                                        <Col span={8}>
                                            <div>
                                                <Button disabled type="primary" onClick={actions.save}>
                                                    {UdeskLocales.current.labels.save}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <TaskConditionDictionaries items={privates.model.inspectionConditions} />
                            </If>
                            <If condition={privates.type === Udesk.enums.pointTypes.machineLearning.id}>
                                <div className='tasks-manage-template-configuration-detail-machine-learning'>
                                    <div className='tasks-manage-template-configuration-detail-machine-learning-logic'>
                                        {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.logic}
                                        <span style={{ color: 'rgba(0,0,0,0.45)' }}>
                                            {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.machineLearningTip}
                                        </span>
                                    </div>
                                    <div className='tasks-manage-template-configuration-detail-machine-learning-score'>
                                        {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.score}
                                        <Select disabled value={privates.scoreType} style={{ width: 86 }} onChange={actions.handleMachineLearningScoreChange}>
                                            {Udesk.enums.calculateScoreTypes.map(item => {
                                                return (
                                                    <Option value={item.id}>{item.name}</Option>
                                                );
                                            })}
                                            {/* {UdeskLocales.current.enums.tasks.calculateScoreTypes}
                                            Udesk.enums.calculateScoreTypes.add.id */}
                                        </Select>
                                        <InputNumber
                                            disabled
                                            min={1}
                                            style={{ width: 200 }}
                                            value={privates.predeterminedScore}
                                            onChange={actions.handleScoreInput}
                                            addonAfter={UdeskLocales.current.labels.scoreUnit}
                                        >
                                        </InputNumber>
                                    </div>
                                    <div className='tasks-manage-template-configuration-detail-machine-learning-strategy'>
                                        {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.strategy}
                                        <Radio.Group disabled onChange={actions.onStrategyTypeChange} value={privates.gradeType}>
                                            <If condition={privates.scoreType === Udesk.enums.calculateScoreTypes.add.id}>
                                                <Radio value={Udesk.enums.strategyTypes.hitAdd.id}>{Udesk.enums.strategyTypes.hitAdd.name}</Radio>
                                                <Radio value={Udesk.enums.strategyTypes.hitNotAdd.id}>{Udesk.enums.strategyTypes.hitNotAdd.name}</Radio>
                                            </If>
                                            <If condition={privates.scoreType === Udesk.enums.calculateScoreTypes.sub.id}>
                                                <Radio value={Udesk.enums.strategyTypes.hitSub.id}>{Udesk.enums.strategyTypes.hitSub.name}</Radio>
                                                <Radio value={Udesk.enums.strategyTypes.hitNotSub.id}>{Udesk.enums.strategyTypes.hitNotSub.name}</Radio>
                                            </If>
                                        </Radio.Group>
                                    </div>

                                </div>
                            </If>
                        </div>
                        <If condition={privates.type === Udesk.enums.pointTypes.smartRules.id}>
                            <div className='udesk-qa-web-page-footer tasks-manage-template-configuration-detail-body-footer'>

                            </div>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}