import React, { createContext } from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';

export const Context = createContext({});

class TasksCheckPointEntry extends React.Component {
    state = {
        pageNum: 1,
        pageSize: 20,
    };

    savePageInfo = ({pageNum, pageSize}) => {
        this.setState({
            pageNum,
            pageSize,
        });
    };

    isRedirect(currentPath, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: this.props.match.params,
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let { routes, route, location, sdkOptions, match } = this.props;

        if (this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name,
                pathParams: match.params,
            });
            return (
                <Context.Provider value={{ ...this.state, savePageInfo: this.savePageInfo }}>
                    <Redirect to={toPath} sdkOptions={sdkOptions} />
                </Context.Provider>
            );
        } else {
            return (
                <Context.Provider value={{ ...this.state, savePageInfo: this.savePageInfo }}>
                    <div style={{ height: '100%' }}>
                        {routes.map((routeItem, i) => (
                            <SubRoutes key={i} route={routeItem} showModelError={true} />
                        ))}
                    </div>
                </Context.Provider>
            );
        }
    }
}
export default Udesk.react.udeskify(TasksCheckPointEntry);
