import ReportEcharts from './base';
import Udesk from '../../../udesk';
import "./index.css";

const ReactReportEcharts = Udesk.react.udeskify(ReportEcharts);

export default ReportEcharts;
export {
    ReactReportEcharts as ReportEcharts
};
