export default {
  lang: {
    num: 'Nie.',
    total: 'Artykuł/Ogółem',
    strip: 'Artykuł',
    more: 'Więcej',
    chosen: 'Wybrane',
    cancel: 'Odzyskany',
    bulkOperations: 'Operacja partii',
    import: 'Import',
  },
};
