
import React, { useCallback, useImperativeHandle, useState } from 'react';
import UdeskLocales from 'UdeskLocales';
import { useRequest } from 'src/util/hook';
import { Form, Input, Modal } from 'udesk-ui';
import { putIntelligentExtractTasksResultById } from 'src/api/intelligentExtractTasks/result/{id}';

export type EditRefType = {
    open: (record: any) => void;
}

export default React.forwardRef<EditRefType, any>((props: any, ref) => {
    const [form] = Form.useForm();
    const {
        successCallback,
    }= props;

    const {run: saveRun, loading} = useRequest({
        onSuccess: useCallback(() => {
            form.resetFields();
            setVisible(false);
            successCallback();
        }, [
            form, 
            successCallback
        ])
    });

    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        open(record) {
            form.setFieldsValue({
                key: record.id,
                question: record.question,
                answer: record.answer,
            });
            setVisible(true);
        }
    }));

    const submitHandle = useCallback((values) => {
        saveRun(putIntelligentExtractTasksResultById, {
            question: values.question,
            answer: values.answer,
        }, {
            segments: {
                id: values.key
            }
        });
    }, [saveRun]);

    return (
        <Modal
            title={/* 编辑 */UdeskLocales['current'].labels.edit}
            centered
            visible={visible}
            onOk={() => form.submit()}
            onCancel={() => setVisible(false)}
            okButtonProps={{
                loading
            }}
        >
            <div className='page-right-form'>
                <Form 
                    form={form} 
                    onFinish={submitHandle}    
                    labelCol={{span: 4}}
                    wrapperCol={{span: 17}}
                >
                    <Form.Item name='key' hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='question' label={/* 问题 */UdeskLocales['current'].labels.question}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='answer' label={/* 答案 */UdeskLocales['current'].labels.answer}>
                        <Input.TextArea autoSize={{
                            minRows: 6
                        }}/>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});