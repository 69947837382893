import React from 'react';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const DataCardWrap = styled.div`
    height: 118px;
    background: #f5f8fe;
    border-radius: 4px;
    padding: 12px 16px;
    position: relative;

    .data-card-title-wrap {
        display: flex;
        justify-content: space-between;
        .data-card-title {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            position: relative;
            margin-left: 10px;
            flex: 1;
            width: 0;

            > div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &::before {
                content: ' ';
                width: 4px;
                height: 12px;
                background: #1a6eff;
                border-radius: 4px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -10px;
            }
        }
    }
    .data-card-rank {
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
        span {
            color: #1a6eff;
            margin-left: 4px;
        }
    }
    .data-card-content {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #1a6eff;
        text-align: center;
        /* margin-top: 16px; */
        position: absolute;
        left: 50%;
        transform: translate(-50%, -30%);
        top: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
`;

const DataCard = (props) => {
    return (
        <DataCardWrap className="data-card-wrap">
            <div className="data-card-title-wrap">
                <div className="data-card-title" title={props.title}>
                    <div>{props.title}</div>
                </div>
                {props.rank && (
                    <div className="data-card-rank">
                        {/* 排行 */}
                        {
                            UdeskLocales['current'].pages.coach.dashboard.components.practiceSummary
                                .ranking
                        }
                        <span>{props.rank}</span>
                    </div>
                )}
            </div>
            <div className="data-card-content">{props.value ?? '-'}</div>
        </DataCardWrap>
    );
};

export default DataCard;
