import React from 'react';
import Udesk from 'Udesk';

class MiniListManageComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {
        type: Udesk.enums.filterTypes.call.id,
        manageTitle: "",
        pageConfig: {},
        onBackToIndex: null
    };
    //#endregion

    state = {

    };
    privates = {
        data: [],
        paging: {},
        selectedDatum: null,
        selectedFilter: {}
    };
    static computes = {

    };
    actions = {
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        changeSelectedDatum(datum) {
            this.privates.selectedDatum = datum;
            this.trigger("onSelectedDatumChanged", datum);
            // this.actions.update();
        },
        backToIndex() {
            this.trigger("onBackToIndex");
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        let pageConfig = props.pageConfig || this.trigger("getPageConfig");
        return {
            paging: pageConfig,
            selectedDatum: props.selectedDatum,
            selectedFilter: props.selectedFilter
        };
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    componentWillUnmount() {

    }
    asyncModel(changedParams) {
        let {
            sdkOptions,
        } = this.props;
        let params = Object.assign({}, {
            type: this.props.type,
            pageNum: this.privates.paging.pageNum || Udesk.config.paging.defaultPageNumber,
            pageSize: this.privates.paging.pageSize || Udesk.config.paging.defaultPageSize,
            conditionList: this.privates.selectedFilter.conditionList,
            customJudgeLogic: this.privates.selectedFilter.customJudgeLogic || "",
            judgeStrategy: this.privates.selectedFilter.judgeStrategy || 1,
            keyword: this.privates.selectedFilter.keyword || "",
            keywordType: this.privates.selectedFilter.keywordType,
            dataId:this.privates.selectedFilter.callId || null
        }, changedParams);
        let url = Udesk.business.apiPath.concatApiPath(`data/mini-search`, sdkOptions);
        let dataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.miniListManage.getRecordsFailed);
                    reject(reason);
                }
            );
        });

        return ({
            data: dataPromise
        });
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let {
            asyncResult
        } = parseOptions;
        if (asyncResult) {
            if (asyncResult.data) {
                this.privates.data = asyncResult.data;
            }
            if (asyncResult.paging) {
                this.privates.paging = asyncResult.paging;
                let {
                    pageNum,
                    pageSize,
                    total
                } = this.privates.paging;
                this.trigger("onPageChanged", pageNum, pageSize, total);
            }
            this.actions.update();
        }
    }
    //#endregion
}

export default MiniListManageComponent;