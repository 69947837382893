import React from 'react';
import Udesk from 'Udesk';
import _find from 'lodash-es/find';
import _isEmpty from 'lodash-es/isEmpty';
import isEmpty from 'lodash-es/isEmpty';
import tranToTaskCenterNotification from 'Component/common/task-notification';
import { getSystemModule } from 'Udesk/system/subApp';

class classifiedTrainingComponent extends React.Component {
    static defaultProps = {};
    privates = {
        smartData: [],
        smartValue: undefined,
        statAt: [
            moment()
                .add(-30, 'day')
                .format('YYYY-MM-DD 00:00:00'),
            moment().format('YYYY-MM-DD 23:59:59')
        ],
        noneDataTrain: null,
        markedDataCorrigenda: null,
        classifiedDataCheck: null,
        markedHistoryRecord: null,
        historyDataRecalculate: null,
        markedDataCorrigendaValue: null,
        smartReatsData: null,
        valueType: 2,
        treeDataList: {},
        labelActive: null,
        labelLevel: null,
        noneDataValue: 1,
        RadiomarkedDataCorrigendaValue: 1,
        RadioclassifiedDataCheckValue: 1,
        classifiedDataCheckValue: null,
        redoCallIds: [],
        callTimeRange: [],
        userValue: [],
        conditionList: [],
        fieldList: [],
        judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        customJudgeLogic: '',
        dataSourceType: 0,
        customFilterList: [],
        customeFilterField: null,
        customeFilterFieldKey: [],
        customDataSourceList: [],
        customId: null,
        customName: '',
        filterVisible: false,
        hitData: {},
        labelParent: {},
        nonecondtionJson: [],
        nonejudgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        nonecustomJudgeLogic: '',
        fitlerName: null,
        classcondtionJson: [],
        classjudgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        classcustomJudgeLogic: '',
        markcondtionJson: [],
        markjudgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        markcustomJudgeLogic: '',
        treeDataRef: {},
        labelRef: React.createRef()
    };
    actions = {
        smartChangeValue(value) {
            this.privates.smartValue = value;
            this.privates.RadiomarkedDataCorrigendaValue = 1;
            this.actions.getCateaoryId();
            this.actions.update();
        },
        timeChange(dataString) {
            let startTime = '',
                endTime = '';
            if (!dataString[0] || !dataString[1]) {
                startTime = moment()
                    .add(-30, 'day')
                    .format('YYYY-MM-DD 00:00:00');
                endTime = moment().format('YYYY-MM-DD 23:59:59');
            } else {
                startTime = dataString[0];
                endTime = dataString[1];
            }

            this.privates.statAt = [startTime, endTime];
            this.actions.update();
        },
        valueTypeChange(value) {
            this.privates.valueType = value;
            this.actions.update();
        },
        historyTo() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'classifiedTrainingHistoryIndex'
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        typeTo() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'intelligentTagsNew'
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sumbitGet() {
            let { smartValue, statAt } = this.privates;
            let { locales } = this;
            if (!smartValue) {
                return Udesk.ui.notify.error(locales.components.pages.trainingCenter.classifiedTraining.trainingNone);
            }
            let { sdkOptions } = this.props;
            if (moment(statAt[0]).add(31, 'days') < moment(statAt[1])) {
                return Udesk.ui.notify.error(locales.components.pages.intelligentTags.index.timeError);
            }
            let url1 = Udesk.business.apiPath.concatApiPath(`smartTagTrainRecords/rates`, sdkOptions);
            let url2 = Udesk.business.apiPath.concatApiPath(`smartTagTrainRecords/sign`, sdkOptions);
            let data = {
                startTime: statAt[0],
                endTime: statAt[1],
                categoryId: smartValue
            };
            this.actions.getTagTreeInit();
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url2, data).then(
                    resp => {
                        resolve(resp);
                        if (resp.data) {
                            resp.data.signResponseList.forEach(item => {
                                if (Udesk.enums.trainTypeList.noneDataTrain.id === item.trainType) {
                                    this.privates.noneDataTrain = item;
                                } else if (Udesk.enums.trainTypeList.markedDataCorrigenda.id === item.trainType) {
                                    this.privates.markedDataCorrigenda = item;
                                } else if (Udesk.enums.trainTypeList.classifiedDataCheck.id === item.trainType) {
                                    this.privates.classifiedDataCheck = item;
                                } else if (Udesk.enums.trainTypeList.historyDataRecalculate.id === item.trainType) {
                                    this.privates.historyDataRecalculate = item;
                                }
                            });
                            this.privates.markedHistoryRecord = resp.data;
                            this.actions.update();
                        }
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url1, data).then(
                    resp => {
                        resolve(resp);
                        if (resp.data) {
                            this.privates.smartReatsData = resp.data;
                        }
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        markedDataCorrigendaChange(value) {
            this.privates.markedDataCorrigendaValue = value;
            this.actions.update();
        },
        classifiedDataCheckChange(value) {
            this.privates.classifiedDataCheckValue = value;
            this.actions.update();
        },
        activesLabel(value, level, leveflag) {
            let { treeDataList } = this.privates;
            this.privates.labelActive = value;
            this.privates.tagId = value;
            this.privates.labelLevel = level;
            this.privates.labelParent[level] = value;
            for (let key in this.privates.labelParent) {
                if (key > level) {
                    this.privates.labelParent[key] = null;
                }
            }
            for (let key in this.privates.hitData) {
                if (key >= level) {
                    this.privates.hitData[key] = null;
                }
            }
            if (leveflag !== 1) {
                this.actions.getTagTreeInit(value);
            } else {
                for (let key in treeDataList) {
                    if (key > level) {
                        this.privates.treeDataList[key] = null;
                    }
                }
            }

            this.actions.update();
        },
        getTagTreeInit(tagId) {
            let { sdkOptions } = this.props;
            let { smartValue, statAt, valueType } = this.privates;
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/tree`, sdkOptions);
            let data = {
                categoryId: smartValue,
                treeType: 3,
                startTime: statAt[0],
                endTime: statAt[1],
                valueType
            };
            if (tagId) {
                data.tagId = tagId;
            }
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    resp => {
                        resolve(resp.data);
                        if (tagId) {
                            if (resp.data && resp.data.length > 0) {
                                let level = resp.data[0].tagLevel;
                                if (!_isEmpty(this.privates.treeDataList)) {
                                    let obj = _find(this.privates.treeDataList, (item, key) => key === level);
                                    if (obj) {
                                        this.privates.treeDataList[level] = resp.data;
                                    } else {
                                        this.privates.treeDataList = {
                                            ...this.privates.treeDataList,
                                            [level]: resp.data
                                        };
                                    }
                                    let data = {};
                                    for (let key in this.privates.treeDataList) {
                                        if (key <= level) {
                                            data[key] = this.privates.treeDataList[key];
                                        }
                                    }
                                    this.privates.treeDataList = data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                    }
                                } else {
                                    this.privates.treeDataList[level] = resp.data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                    }
                                }
                                this.actions.update();
                            } else {
                                if (this.privates.labelLevel) {
                                    this.privates.treeDataList = {
                                        ...this.privates.treeDataList,
                                        [this.privates.labelLevel + 1]: []
                                    };
                                }
                                let data = {};
                                for (let key in this.privates.treeDataList) {
                                    if (key <= this.privates.labelLevel + 1) {
                                        data[key] = this.privates.treeDataList[key];
                                    }
                                }
                                this.privates.treeDataList = data;
                                for (let key in this.privates.treeDataList) {
                                    this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                }
                                this.actions.update();
                            }
                        } else {
                            if (!isEmpty(resp.data)) {
                                this.privates.treeDataList = resp.data;
                            } else {
                                this.privates.treeDataList = {};
                            }
                            for (let key in this.privates.treeDataList) {
                                this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                            }
                            this.actions.treeMap();
                            this.actions.hitMap();
                            this.actions.update();
                        }
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        treeMap() {
            let { treeDataList } = this.privates;
            for (let key in treeDataList) {
                if (treeDataList[key] && treeDataList[key].length > 0) {
                    let id = treeDataList[key][0].id;
                    for (let item in treeDataList) {
                        if (treeDataList[item] && treeDataList[item].length > 0) {
                            if (item - key >= 1) {
                                if (!_isEmpty(treeDataList[item].filter(items => items.parentId === id))) {
                                    treeDataList[item] = treeDataList[item].filter(items => items.parentId === id);
                                } else {
                                    treeDataList[item] = null;
                                }
                            }
                        }
                    }
                }
            }
        },
        treeDrag(value, e) {
            var x1 = e.pageX || e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
            if (value.current) {
                let width = value.current.style.width;
                let wid = parseInt(width.split('px')[0], 10);
                this.privates.labelRef.current.onmousemove = e => {
                    var x2 = e.pageX || e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
                    if (x2 >= x1 && x2 - x1 <= 500) {
                        value.current.style.width = x2 - x1 + wid + 'px';
                    } else {
                        if (x2 - x1 < 0) {
                            value.current.style.width = wid - (x1 - x2) + 'px';
                        }
                    }
                };
            }
        },
        fieldUp() {
            this.privates.labelRef.current.onmousemove = e => {
                return;
            };
        },
        hitMap() {
            this.privates.hitData = {};
            let { treeDataList } = this.privates;
            for (let key in treeDataList) {
                if (treeDataList[key] && treeDataList[key].length > 0) {
                    this.privates.hitData[key] = treeDataList[key][0].id;
                }
            }
        },
        reseat() {
            this.privates.smartValue = this.privates.smartData[0].id;
            this.privates.treeDataList = {};
            this.privates.smartReatsData = {};
            this.privates.statAt = [
                moment()
                    .add(-30, 'day')
                    .format('YYYY-MM-DD 00:00:00'),
                moment().format('YYYY-MM-DD 23:59:59')
            ];
            this.privates.noneDataTrain = null;
            this.privates.markedDataCorrigenda = null;
            this.privates.classifiedDataCheck = null;
            this.privates.markedHistoryRecord = null;
            this.privates.historyDataRecalculate = null;
            this.privates.markedDataCorrigendaValue = null;
            this.privates.smartReatsData = null;
            this.privates.valueType = 2;
            this.privates.labelActive = null;
            this.privates.labelLevel = null;
            this.privates.noneDataValue = 1;
            this.privates.RadiomarkedDataCorrigendaValue = 1;
            this.privates.RadioclassifiedDataCheckValue = 1;
            this.privates.classifiedDataCheckValue = null;
            this.actions.update();
        },
        noneDataTrainChange(value) {
            this.privates.noneDataValue = value.target.value;
            this.actions.update();
        },
        radiomarkedDataCorrigenda(value) {
            // e.stopPropagation();
            this.privates.RadiomarkedDataCorrigendaValue = value.target.value;
            this.actions.update();
        },
        RadioclassifiedDataCheckChange(value) {
            this.privates.RadioclassifiedDataCheckValue = value.target.value;
            this.actions.update();
        },
        startTrain(type, value) {
            let { sdkOptions } = this.props;
            let {
                smartValue,
                statAt,
                markcondtionJson,
                nonecondtionJson,
                classcondtionJson,
                classifiedDataCheckValue,
                markedDataCorrigendaValue,
                classjudgeStrategy,
                nonecustomJudgeLogic,
                nonejudgeStrategy,
                classcustomJudgeLogic,
                markcustomJudgeLogic,
                markjudgeStrategy
            } = this.privates;
            let url = Udesk.business.apiPath.concatApiPath(`smartTagTrainRecords/trainData`, sdkOptions);
            let data = {
                recordType: type,
                dataExtractMode: value,
                startTime: statAt[0],
                endTime: statAt[1],
                categoryId: smartValue
            };
            if (Udesk.enums.dataExtractModeTypeOne.intelligentRecommend.id === type && value === 4) {
                data.id = markedDataCorrigendaValue.id;
            }
            if (Udesk.enums.dataExtractModeTypeOne.custom.id === type && value === 4) {
                data.id = classifiedDataCheckValue.id;
            }
            if (Udesk.enums.dataExtractModeTypeOne.custom.id === type && value === 3) {
                data.filter = {
                    conditionList: classcondtionJson,
                    customJudgeLogic: classcustomJudgeLogic,
                    judgeStrategy: classjudgeStrategy
                };
            }
            if (Udesk.enums.dataExtractModeTypeOne.intelligentRecommend.id === type && value === 3) {
                data.filter = {
                    conditionList: markcondtionJson,
                    customJudgeLogic: markcustomJudgeLogic,
                    judgeStrategy: markjudgeStrategy
                };
            }
            if (Udesk.enums.dataExtractModeTypeOne.random.id === type && value === 3) {
                data.filter = {
                    conditionList: nonecondtionJson,
                    customJudgeLogic: nonecustomJudgeLogic,
                    judgeStrategy: nonejudgeStrategy
                };
            }
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    resp => {
                        resolve(resp);
                        if (resp.data && resp.data.length > 0) {
                            let routeOptions = {
                                history: this.props.history,
                                routeName: 'classifiedTrainingDetail',
                                pathParams: {
                                    id: resp.data[0].id
                                },
                                queryParams: {
                                    categoryId: smartValue,
                                    trainType: type
                                },
                                state: {
                                    callList: resp.data,
                                    time: statAt
                                }
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        } else {
                            Udesk.ui.notify.error(this.locales.fix.noDataToManipulate);
                        }
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onDateChanged(value) {
            this.privates.callTimeRange = value;
            if (moment(value[0]).add(31, 'days') <= value[1]) {
                return Udesk.ui.notify.error(this.locales.components.pages.intelligentTags.index.callTimeError);
            }
            let url = Udesk.business.apiPath.concatApiPath('data/call-num', this.props.sdkOptions);
            let data = {
                startTime: moment(value[0])
                    .second(0)
                    .millisecond(0)
                    .format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(value[1])
                    .second(0)
                    .millisecond(0)
                    .format('YYYY-MM-DD HH:mm:ss')
            };
            Udesk.ajax.post(url, data).then(
                resp => {
                    this.privates.redoCallIds = resp.data.ids;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.components.pages.components.selectCallTime.getCallRecordError
                    );
                }
            );
        },
        redo(value) {
            this.privates.selectCallTimeVisible = false;
            this.actions.update();
            if (!value) {
                this.privates.callTimeRange = [];
                this.privates.redoCallIds = [];
                this.privates.userValue = [];
                return;
            }

            let { locales } = this;
            let { callTimeRange, redoCallIds, userValue } = this.privates;
            if (moment(callTimeRange[0]).add(31, 'days') <= callTimeRange[1]) {
                this.privates.callTimeRange = [];
                this.privates.redoCallIds = [];
                this.privates.userValue = [];
                return Udesk.ui.notify.error(locales.components.pages.intelligentTags.index.callTimeError);
            }
            if (redoCallIds.length === 0) {
                this.privates.callTimeRange = [];
                this.privates.redoCallIds = [];
                this.privates.userValue = [];
                return Udesk.ui.notify.error(locales.components.pages.intelligentTags.index.callRecordCountError);
            }
            let url = Udesk.business.apiPath.concatApiPath('smartTags/redo', this.props.sdkOptions);
            let data = {
                startTime: moment(callTimeRange[0])
                    .second(0)
                    .millisecond(0)
                    .format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(callTimeRange[1])
                    .second(0)
                    .millisecond(0)
                    .format('YYYY-MM-DD HH:mm:ss'),
                ids: [],
                processCount: redoCallIds.length,
                callIds: redoCallIds,
                relations: userValue.map(item => {
                    return { tagId: item.id, tagType: item.tagType, categoryId: item.categoryId };
                }),
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, data).then(
                resp => {
                    Udesk.ui.notify.success(locales.components.pages.intelligentTags.index.redoSuccess);
                    tranToTaskCenterNotification();
                    this.privates.callTimeRange = [];
                    this.privates.redoCallIds = [];
                    this.privates.userValue = [];
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg || locales.components.pages.intelligentTags.redoError);
                    this.privates.callTimeRange = [];
                    this.privates.redoCallIds = [];
                    this.privates.userValue = [];
                }
            );
        },
        callModalChange() {
            this.privates.selectCallTimeVisible = true;
            this.actions.update();
        },
        loadUserOptions(value) {
            let { sdkOptions } = this.props;
            let { smartValue } = this.privates;
            let params = {
                tagType: 2,
                categoryId: smartValue
            };
            if (value) {
                Object.assign(params, { tagName: value });
            }
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/findAll`, sdkOptions);
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    resp => {
                        resolve(
                            resp.data.map(item => {
                                return {
                                    id: item.id,
                                    name: item.tagName,
                                    categoryId: item.categoryId,
                                    tagType: item.tagType
                                };
                            })
                        );
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onUserChanged(value) {
            this.privates.userValue = value;
            this.actions.update();
        },
        queckListMang(conditionList, judgeStrategy, customJudgeLogic) {
            let { locales } = this;
            return (
                <div className='index-management-custom-filter'>
                    <div className='index-management-custom-filter-Info'>
                        <div>
                            {locales.components.common.customFilter.customFilterCondition}：
                            {_isEmpty(conditionList) && locales.components.common.customFilter.none}
                        </div>
                        <div>
                            {!_isEmpty(conditionList) &&
                                this.actions.fieldsFilterChange({
                                    conditionList: conditionList
                                })}
                        </div>
                    </div>
                    <div>
                        {locales.components.common.customFilter.conditionRule}：
                        <span>
                            {judgeStrategy !== 3 &&
                                Udesk.enums.operatorRuleTypes.find(current => current.id === (judgeStrategy || Udesk.enums.operatorRuleTypes.all.id)).name}
                            {judgeStrategy === 3 && customJudgeLogic}
                        </span>
                    </div>
                </div>
            );
        },

        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.conditionList.forEach(item => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach(items => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label + ' ' + obj[item.operator] + ' ' + str.substring(0, str.length - 1)
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <p style={{ marginBottom: '3px' }}>
                        {index + 1}. {item}
                    </p>
                );
            });
        },
        handleConditionCancel() {
            this.privates.filterVisible = false;
            this.actions.update();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy) {
            if (this.privates.fitlerName === 'noneDataValue') {
                this.privates.nonecondtionJson = conditionList;
                this.privates.nonecustomJudgeLogic = customJudgeLogic;
                this.privates.nonejudgeStrategy = judgeStrategy;
            } else if (this.privates.fitlerName === 'classifiedDataCheck') {
                this.privates.classcondtionJson = conditionList;
                this.privates.classcustomJudgeLogic = customJudgeLogic;
                this.privates.classjudgeStrategy = judgeStrategy;
            } else if (this.privates.fitlerName === 'markedDataCorrigendaValue') {
                this.privates.markcondtionJson = conditionList;

                this.privates.markcustomJudgeLogic = customJudgeLogic;
                this.privates.markjudgeStrategy = judgeStrategy;
            }
            this.privates.customName = null;
            this.privates.customId = null;
            this.privates.filterVisible = false;
            this.actions.update();
        },
        changeFilterVisible(value) {
            let {
                nonecondtionJson,
                nonejudgeStrategy,
                nonecustomJudgeLogic,
                classcondtionJson,
                classjudgeStrategy,
                classcustomJudgeLogic,
                markcondtionJson,
                markjudgeStrategy,
                markcustomJudgeLogic
            } = this.privates;
            this.privates.fitlerName = value;
            if (value === 'noneDataValue') {
                this.privates.conditionList = nonecondtionJson;
                this.privates.judgeStrategy = nonejudgeStrategy;
                this.privates.customJudgeLogic = nonecustomJudgeLogic;
            } else if (value === 'classifiedDataCheck') {
                this.privates.conditionList = classcondtionJson;
                this.privates.judgeStrategy = classjudgeStrategy;
                this.privates.customJudgeLogic = classcustomJudgeLogic;
            } else if (value === 'markedDataCorrigendaValue') {
                this.privates.conditionList = markcondtionJson;
                this.privates.judgeStrategy = markjudgeStrategy;
                this.privates.customJudgeLogic = markcustomJudgeLogic;
            }
            this.privates.filterVisible = true;
            this.actions.update();
        },
        getCateaoryId(value) {
            let { sdkOptions } = this.props;
            let keyWord = '';
            if (value) {
                keyWord = value;
            }
            let { smartValue } = this.privates;
            let url2 = Udesk.business.apiPath.concatApiPath(
                `smartTags/categoryTag?categoryId=${smartValue}&keyword=${keyWord}`,
                sdkOptions
            );
            return new Promise((resolve, reject) => {
                Udesk.ajax.get(url2).then(
                    resp => {
                        this.privates.markedDataCorrigendaValue = resp.data[0]
                            ? { id: resp.data[0].id, name: resp.data[0].tagName }
                            : null;
                        this.privates.classifiedDataCheckValue = resp.data[0]
                            ? { id: resp.data[0].id, name: resp.data[0].tagName }
                            : null;
                        this.actions.update();
                        resolve(
                            resp.data.map(item => {
                                return { id: item.id, name: item.tagName };
                            })
                        );
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        }
    };
    componentDidMount() {
        if (window.sessionStorage.getItem('categoryId')) {
            this.privates.smartValue = parseInt(window.sessionStorage.getItem('categoryId'), 10);
            window.sessionStorage.removeItem('categoryId');
            this.actions.update();
            this.actions.sumbitGet();
        } else {
            if (this.props.location.state && this.props.location.state.categoryId) {
                this.privates.smartValue = parseInt(this.props.location.state.categoryId, 10);
                if (this.props.location.state.time) {
                    this.privates.statAt = this.props.location.state.time;
                }
                this.actions.update();
                this.actions.sumbitGet();
            }
        }
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult, asyncKey } = parseOptions;
        if (asyncKey === 'smartTagCate') {
            if (asyncResult.data) {
                this.privates.smartData = asyncResult.data?.map(i => ({
                    ...i,
                    pId: i.parentId,
                    value: i.id,
                    title: i.categoryName,
                })) ?? [];
                if (asyncResult.data.length > 0) {
                    if (!this.privates.smartValue) {
                        this.privates.smartValue = asyncResult.data[0].id;
                    }
                    this.actions.getCateaoryId();
                }
            }
        }
        this.actions.update();
    }
}
// function getAllFieldListFilter(props, privates) {
//     let { fieldList } = privates;
//     let { sdkOptions } = props;

//     let allFieldList = [];

//     if (fieldList && fieldList.length > 0) {
//         allFieldList = fieldList;
//     } else if (sdkOptions.props.fields && sdkOptions.props.fields.length > 0) {
//         allFieldList = sdkOptions.props.fields;
//     }

//     return allFieldList;
// }
function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter(item => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map(item => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}
export default classifiedTrainingComponent;
