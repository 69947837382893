import Udesk from 'Udesk';
import CustomerServiceInformationComponent from "./component";

class CustomerSericeInforomationRoute extends CustomerServiceInformationComponent {
    asyncModel(flag) {
        let { sdkOptions } = this.props;

        let params = {
            pageNum: this.privates.paging.pageNum,
            pageSize: this.privates.paging.pageSize,
            keyWord: this.privates.keyWord,
            checked: this.privates.checkExceptionData,
            ordersList: this.privates.ordersList
        };
        // let listData;
        // if (!flag) {
        //     let url = Udesk.business.apiPath.concatApiPath(`agent/list`, sdkOptions);
        //     listData = new Promise((resolve, reject) => {
        //         Udesk.ajax.post(url, params).then(
        //             resp => {
        //                 resolve(resp);
        //             },
        //             reason => {
        //                 Udesk.ui.notify.error(reason.errorMsg);
        //                 reject(reason);
        //             }
        //         );
        //     });
        // }
        this.privates.loading = true;
        this.actions.update();
        let grounpUrl = Udesk.business.apiPath.concatApiPath(
            `agentGroups`,
            sdkOptions
        );
        let grounpDataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(grounpUrl).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        }).then(grounpData => {
            let id = "";
            if (this.privates.grounpId) {
                id = this.privates.grounpId;
            } else {
                if (grounpData && grounpData.length > 0) {
                    id = grounpData[0].id;
                }
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `agentGroups/${id}`,
                sdkOptions
            );
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    resp => {
                        resolve({ grounpData, listData: resp });
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        });
        return {
            grounpData: grounpDataPromise
        };
    }
    parseAsyncModel(asyncModel) {
        return asyncModel;
    }
}

export default CustomerSericeInforomationRoute;
