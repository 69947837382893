import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Divider, Modal, Space } from 'udesk-ui';
import CallListItem from 'Component/pages/gong/home-page/common/call-list-item';
import { getReviewCallCustomerMessageFindDetailByCustomerId } from 'src/api/review/call/customer/message/findDetailByCustomerId';
import * as echarts from 'echarts';
import moment from 'moment';
import './index.scss';

export default forwardRef((props:any, ref) => {
    const {
        customerId,
        locales, 
        userName, 
        listData, 
        handlerCallDetail,
        callDataList,
    } = props;    
    const [allCount, setAllCount] = useState(0);
    const [isModalShow, setIsModalShow] = useState(false);
    const [mainEchartsOptions, setMainEchartsOptions] = useState({
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: [locales.pages.gong.saleClientCenter.detail.index.january],
            axisTick: {
                alignWithLabel: true,
            },
        },
        yAxis: {
            name: '',
            type: 'value',
            splitNumber: 1,
        },
        grid: {
            left: '0',
            right: '0',
            bottom: '3%',
            top: '3%',
            containLabel: true,
        },
        series: [
            {
                name: locales.pages.gong.saleClientCenter.detail.index.numberOfCalls,
                data: [20],
                type: 'bar',
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#1A65FF' },
                            { offset: 1, color: '#1A6EFF' },
                        ]),
                    },
                },
                barMaxWidth: 20,
            },
        ],
    });
    const [conversationOptions, setConversationOptions] = useState({
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLine: {
                show: false,
            },
        },
        yAxis: {
            type: 'category',
            data: [locales.pages.gong.saleClientCenter.detail.index.accountInformation],
        },
        grid: {
            top: '3%',
            left: '0',
            right: '3%',
            bottom: '0',
            containLabel: true,
        },
        series: [
            {
                name: '',
                type: 'bar',
                data: [15],
                barMaxWidth: 20,
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#60D7A7' },
                            { offset: 1, color: '#60D7A7' },
                        ]),
                    },
                },
            },
        ],
    });

    const getContactStautsData = useCallback(() => {
        const params = {
            customerId,
        };
        getReviewCallCustomerMessageFindDetailByCustomerId({ params }).then((res) => {
            setAllCount(res.data?.allCount ?? 0);
            let newYear = moment().format('YYYY');
            let oldYear = moment().subtract(1, 'years').add(1, 'months').format('YYYY');
            let newMonth = moment().format('M');
            let oldMonth = moment().subtract(1, 'years').add(1, 'months').format('M');
            let monthList: any = [];
            if (newYear === oldYear) {
                for (let i = 1; i <= parseInt(newMonth, 10); i++) {
                    monthList.push(i + locales.pages.gong.saleClientCenter.detail.index.month);
                }
            } else {
                for (let i = parseInt(oldMonth, 10); i <= 12; i++) {
                    monthList.push(i + locales.pages.gong.saleClientCenter.detail.index.month);
                }
                for (let i = 1; i <= parseInt(newMonth, 10); i++) {
                    monthList.push(i + locales.pages.gong.saleClientCenter.detail.index.month);
                }
            }
            setMainEchartsOptions({
                ...mainEchartsOptions,
                xAxis: {
                    ...mainEchartsOptions.xAxis,
                    data: monthList,
                },
                series: [
                    {
                        ...mainEchartsOptions.series[0],
                        data: res.data?.countList ?? [],
                    },
                ],
            });

            let converData: any = [];
            res.data?.timeList?.forEach((item) => {
                converData.push(Math.floor((item / 1000 / 60) * 100) / 100);
            });
            setConversationOptions({
                ...conversationOptions,
                yAxis: {
                    ...conversationOptions.yAxis,
                    data: res.data?.topicNameList ?? [],
                },
                series: [
                    {
                        ...conversationOptions.series[0],
                        data: converData,
                    },
                ],
            });
        });
    }, [
        conversationOptions, 
        customerId, 
        locales.pages.gong.saleClientCenter.detail.index.month, 
        mainEchartsOptions
    ]);
    
    const timeFormatter = (time?) => {
        let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
        let today = moment().format('YYYY-MM-DD');
        let defaultTime = moment(time).format('YYYY-MM-DD');
        switch (defaultTime) {
            case today:
                return locales.pages.gong.homePage.common.callDynamic.today;
            case yesterday:
                return locales.pages.gong.homePage.common.callDynamic.yesterday;
            default: {
                return time;
            }
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            open() {
                setIsModalShow(true);
                getContactStautsData();
            },
        }),
        [getContactStautsData],
    );

    useEffect(() => {
        const pageBodyOnresize = document.querySelector('.page-call-analysis-all-call-list');
        let timer: any = null;
        const mainCanvas = document.getElementById('mainCanvas');
        const mainTopsCanvas = document.getElementById('mainTopsCanvas');
        if (!mainCanvas && !mainTopsCanvas) return;
        const myChart = echarts.init(mainCanvas);
        const myTopsChart = echarts.init(mainTopsCanvas);
        myChart.setOption(mainEchartsOptions);
        myTopsChart.setOption(conversationOptions);
        myChart.getDom().style.width = '100%';

        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                timer = setTimeout(() => {
                    myChart.resize();
                    myTopsChart.resize();
                }, 200);
            });
        });
        if (pageBodyOnresize) {
            resizeObserver.observe(pageBodyOnresize);
        }
        return () => {
            timer = null;
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalShow, mainEchartsOptions, conversationOptions]);

    return (
        <Modal
            wrapClassName='pages-modal-sale-client-center-container'
            width={920}
            title={userName}
            visible={isModalShow}
            footer={null}
            onCancel={() => setIsModalShow(false)}
        >
            <div className='pages-modal-sale-client-center-call-staus-num'>
                <div className='title'>
                    <Space style={{ flex: 1 }}>
                        <h3>{locales.pages.gong.saleClientCenter.detail.index.contactDynamics}</h3>
                        <Divider type='vertical'></Divider>
                        <span>{locales.pages.gong.saleClientCenter.detail.index.lastYear}</span>
                    </Space>
                    <Space>
                        <div className='rect-marks'></div>
                        <span>{locales.pages.gong.saleClientCenter.detail.index.numberOfCalls}</span>
                    </Space>
                </div>
                <div id='mainCanvas' style={{ width: '100%', height: '200px' }}></div>
            </div>
            <div className='modal-bottom-content'>
                <div style={{ flex: 2, marginRight: 16 }} className='pages-modal-sale-client-center-call-staus-num'>
                    <div className='title'>
                        <Space>
                            <h3>{locales.pages.gong.saleClientCenter.detail.index.lastCall}</h3>
                            <Divider type='vertical'></Divider>
                            <span>
                                {!!listData.length && timeFormatter(listData[listData.length - 1].callTime)}
                            </span>
                        </Space>
                    </div>
                    <div className='call-detail-item'>
                        {!!listData.length &&
                                listData[listData.length - 1]?.children?.map((record, idx) => {
                                    return (
                                        <>
                                            {
                                                idx  === (listData[listData.length - 1]?.children.length - 1) && <CallListItem
                                                    isHome={true}
                                                    {...props}
                                                    {...record}
                                                    tag={record?.smartTagList?.filter((s, i) => i < 1) ?? []}
                                                    asrVoiceDuration={record.callDuration}
                                                    agentName={record.userName}
                                                    handlerCallDetail={(callId) => {
                                                        handlerCallDetail(callId, listData.length - 1, callDataList.findIndex(call => call === record), isModalShow);
                                                    }}
                                                    callId={record.id}
                                                ></CallListItem>
                                            }
                                        </>
                                    );
                                })}
                    </div>
                </div>
                <div style={{ flex: 3 }} className='pages-modal-sale-client-center-call-staus-num'>
                    <div className='title'>
                        <Space>
                            <h3>{locales.pages.gong.saleClientCenter.detail.index.hotTopicsForDiscussion}</h3>
                            <Divider type='vertical'></Divider>
                            <span>
                                {locales.pages.gong.saleClientCenter.detail.index.totalInTheLastYear}
                                {allCount}
                                {locales.pages.gong.saleClientCenter.detail.index.callTimes}
                            </span>
                        </Space>
                    </div>
                    <p className='top-hots-text'>TOP5</p>
                    <div id='mainTopsCanvas' style={{ width: '100%', height: '200px' }}></div>
                </div>
            </div>
        </Modal>
    );
});
