import React, { useMemo } from 'react';
import { Button, Icon, Modal, Dropdown, Menu } from 'udesk-ui';
import Locales from 'UdeskLocales';

const TreeNode = (props) => {
    const locales = Locales['current'];
    const { menu: menuProps = [], ...node } = props;
    const { title } = node;

    const menu = useMemo(() => {
        return (
            <Menu>
                {menuProps.map((item) => {
                    const disabled = typeof item.disabled === 'function' ? item.disabled(node) : item.disabled;
                    return (
                        <Menu.Item
                            danger={!disabled && item.danger}
                            disabled={disabled}
                            key={item.label}
                            onClick={
                                item.danger
                                    ? () => {
                                        Modal.confirm({
                                            title: locales.labels.deleteContent,
                                            icon: <Icon type='ExclamationCircleOutlined' antdIcon={true} />,
                                            okText: locales.labels.delete,
                                            cancelText: locales.labels.cancel,
                                            onOk: item.onClick.bind(null, node),
                                        });
                                    }
                                    : item.onClick.bind(null, node)
                            }
                        >
                            {item.label}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
    }, [menuProps, node]);
    const hiddenExtra = useMemo(
        () => menuProps.every((item) => (typeof item.disabled === 'function' ? item.disabled(node) : item.disabled)),
        [menuProps, node]
    );
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='tree-node-title' title={title}>{title}</div>
            <div onClick={(e) => e.stopPropagation()}>
                {hiddenExtra || (
                    <Dropdown overlay={menu}>
                        <Button size='small' type='text' icon={<Icon type='EllipsisOutlined' antdIcon={true} />} />
                    </Dropdown>
                )}
            </div>
        </div>
    );
};

export { TreeNode };
