import Udesk from 'Udesk';
import Tempalte from './tempalte.tsx';
import React from 'react';

class Component extends React.Component {
    render(){
        return <Tempalte {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);