import moment from 'moment';
import { isNumber } from './core';

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const getDateTimeString = (date = undefined, format = DEFAULT_DATE_TIME_FORMAT) => {
    return moment(date).format(format);
};

export const createDefaultMoment = (date) => {
    if (date) {
        return moment(date, DEFAULT_DATE_TIME_FORMAT);
    }
    return moment();
};

export const beautifulTime = (second, autoIndent = false) => {
    return isNumber(second, () => {
        return moment('000000', 'Hmmss').second(second).format(autoIndent && second < 3600 ? 'mm:ss' : 'HH:mm:ss');
    });
};