import notifications from './notifications';
import webStorage from './web-storage';
import mobile from './mobile';
import deviceInfo from "./device-info";

export default {
    notifications,
    storage: webStorage,
    mobile,
    get isMobile() {
        return mobile.isMobile;
    },
    deviceInfo
};