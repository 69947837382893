import React from 'react';
import Udesk from 'Udesk';
import AudioCallContentInfoComponent from './component';
// import _isEmpty from 'lodash-es/isEmpty';
import { Input, Select, Button, Space, /* Checkbox */ Icon, Dropdown } from 'udesk-ui';
import ClassNames from 'classnames';
import AudioCallTextInfo from '../audio-call-text-info';
import TicketTextInfo from '../ticket-text-info';
import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
// import { useDrag } from 'react-dnd';
import Locales from 'UdeskLocales';

const { Option } = Select;
const noop = {};

const SYS_MESSAGE_TYPES = [
    'transfer_send',
    'survey',
    'form',
    'form_received',
    'info_transfer',
    'customer_close',
    'invitation',
    'lock',
    'start_session',
];
// const DraggableComponent = (props) => {
//     // const [, drag] = useDrag({
//     //     item: { type: 'cards', id: props.id },
//     //     end: (item, monitor) => {
//     //         props.viewCheckd && props.viewCheckd(false);
//     //         !props.markFlag && props.onEnd && props.onEnd();
//     //         if (!monitor.didDrop()) {
//     //             delete item.index;
//     //         }
//     //     },
//     //     begin: () => {
//     //         props.onClickd && props.onClickd();
//     //         props.viewCheckd && props.viewCheckd(props.id);
//     //     },
//     // });
//     return (
//         <div /* ref={drag} */ className='react-qa-custom-filter-body-left-info-field' style={{ height: '30px' }}>
//             <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tuozhuai1'></i>
//         </div>
//     );
// };
const DownloadMenu = ({ exportTxt /* exportWord */ }) => {
    const locales = Locales['current'];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
            {exportTxt && (
                <Button type='text' onClick={exportTxt}>
                    {locales.fix.exportTxt}
                </Button>
            )}
            {/* {exportWord && (
                <Button type='text' onClick={exportWord}>
                    {locales.fix.exportWord}
                </Button>
            )} */}
        </div>
    );
};

const DownloadAstText = ({ downloadAudio, exportTxt, exportWord }) => {
    return (
        <Dropdown
            overlay={<DownloadMenu downloadAudio={downloadAudio} exportTxt={exportTxt} exportWord={exportWord} />}
            placement='bottomCenter'
        >
            <i
                className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazai'
                style={{ alignSelf: 'center' }}
            ></i>
        </Dropdown>
    );
};
const MessageStatus = React.memo((props) => {
    const { status } = props;
    const { color, name } = Udesk.enums.sendStatus[status] || {};
    return (
        Boolean(status) && <div style={{ alignSelf: 'flex-end', margin: '0 8px', color, fontSize: '12px' }}>{name}</div>
    );
});
export default class AudioCallContentInfo extends AudioCallContentInfoComponent {
    render() {
        let {
            detail,
            markSemanticVisible,
            inspectDataSource,
            caseBase,
            dialogRemarkList,
            canRemarkEdit,
            id,
            changeDialogRemarkList,
            isWechatQa,
        } = this.props;
        let {
            computes,
            audioTextListRef,
            highlightSeqIndex,
            highlightSeqList,
            searchContentList,
            keywords,
            viewCheck,
            heightList,
            markFlag,
            pointChildrenPerson,
            pointChildrenComputer,
            testSetListFlag,
            pointContentList,
            itemId,
            waveAudio,
            type,
        } = this.privates;
        let { actions, locales } = this;
        const { related } = detail;
        const isTicket = inspectDataSource === Udesk.enums.inspectDataSources.ticket.id;
        return (
            <div className='audio-call-content-info' onMouseEnter={actions.audioStop} onMouseLeave={actions.audioPlay}>
                {!isTicket && (
                    <React.Fragment>
                        <div className='audio-call-content-search'>
                            <div style={{ display: 'flex' }}>
                                <Select
                                    value={type}
                                    style={{ width: 70 }}
                                    onSelect={actions.selectType}
                                    options={Udesk.enums.applyRolesWithRobot.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    }))}
                                />
                                <Input.Search
                                    value={keywords}
                                    className='audio-call-content-search-input'
                                    onChange={actions.keywordsChanged}
                                    placeholder={locales.labels.pleaseEnter}
                                    onSearch={actions.searchContet}
                                />
                                {!isWechatQa && !testSetListFlag && !related && (
                                    <Select
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                        value={this.privates.fieldsId || 'unnamed'}
                                        onChange={actions.selectChange}
                                        style={{ width: '130px', marginRight: '8px' }}
                                        disabled={testSetListFlag}
                                    >
                                        <Option value={'unnamed'}>
                                            {locales.components.pages.caseBaseList.detail.choiceCaseBase}
                                        </Option>
                                        {caseBase &&
                                            !_isEmpty(caseBase) &&
                                            caseBase.map((item) => {
                                                return (
                                                    <Select.Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                )}
                                <If condition={this.privates.fieldsId !== 'unnamed'}>
                                    <Button style={{ width: '64px' }} onClick={actions.save}>
                                        {locales.labels.save}
                                    </Button>
                                </If>
                            </div>
                            {[2, 4].includes(detail?.inspectDataSource) && (
                                <DownloadAstText exportTxt={actions.exportTxt} />
                            )}
                        </div>
                        <If condition={highlightSeqList.length > 0}>
                            <div className='audio-text-highlight-navigation'>
                                <div
                                    className='audio-text-highlight-navigation-button button-up'
                                    onClick={actions.nextHit.params('up')}
                                >
                                    <Icon type='ic-arrow-up-s-line' style={{ height: '12px' }} />
                                </div>
                                <div
                                    className='audio-text-highlight-navigation-button button-down'
                                    onClick={actions.nextHit.params('down')}
                                >
                                    <Icon type='ic-arrow-down-s-xiajiantou' style={{ height: '12px' }} />
                                </div>
                                <div className='audio-text-highlight-navigation-text'>
                                    {highlightSeqIndex}/{highlightSeqList.length}
                                </div>
                                <div className='audio-text-highlight-navigation-close' onClick={actions.closeHit}>
                                    <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-quxiao'></i>
                                </div>
                            </div>
                        </If>
                        <If condition={pointContentList > 0}>
                            <div className='audio-text-highlight-navigation'>
                                <div
                                    className='audio-text-highlight-navigation-button button-up'
                                    onClick={actions.nextPointed.params('up')}
                                >
                                    <Icon type='ic-arrow-up-s-line' style={{ height: '12px' }} />
                                </div>
                                <div
                                    className='audio-text-highlight-navigation-button button-down'
                                    onClick={actions.nextPointed.params('down')}
                                >
                                    <Icon type='ic-arrow-down-s-xiajiantou' style={{ height: '12px' }} />
                                </div>
                                <div className='audio-text-highlight-navigation-text'>
                                    {highlightSeqIndex}/{pointContentList}
                                </div>
                                <div className='audio-text-highlight-navigation-close' onClick={actions.closeHit}>
                                    <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-quxiao'></i>
                                </div>
                            </div>
                        </If>
                        <If condition={searchContentList.length > 0}>
                            <div className='audio-text-highlight-navigation'>
                                <div
                                    className='audio-text-highlight-navigation-button button-up'
                                    onClick={actions.nextSearched.params('up')}
                                >
                                    <Icon type='ic-arrow-up-s-line' style={{ height: '12px' }} />
                                </div>
                                <div
                                    className='audio-text-highlight-navigation-button button-down'
                                    onClick={actions.nextSearched.params('down')}
                                >
                                    <Icon type='ic-arrow-down-s-xiajiantou' style={{ height: '12px' }} />
                                </div>
                                <div className='audio-text-highlight-navigation-text'>
                                    {highlightSeqIndex}/{searchContentList.length}
                                </div>
                                <div className='audio-text-highlight-navigation-close' onClick={actions.closeSearched}>
                                    <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-quxiao'></i>
                                </div>
                            </div>
                        </If>
                    </React.Fragment>
                )}
                <div
                    className='audio-call-content-view-info-content'
                    style={isTicket ? { padding: 0, backgroundColor: '#fff' } : undefined}
                    ref={audioTextListRef}
                >
                    <If
                        condition={
                            detail &&
                            detail.dialogRecords &&
                            detail.dialogRecords.dialogSentenceList &&
                            detail.dialogRecords.dialogSentenceList.length > 0
                        }
                    ></If>
                    <If condition={computes && computes.audioTextItems && computes.audioTextItems.length > 0}>
                        <For each='item' index='index' of={computes.audioTextItems}>
                            <With
                                type={item.states.content.type}
                                role={item.data.role}
                                content={item.states.content.data.content}
                                isSysMsg={SYS_MESSAGE_TYPES.includes(item.states.content.type)}
                            >
                                {isTicket ? (
                                    <TicketTextInfo
                                        item={item}
                                        content={item?.allData?.data?.content}
                                        itemKey={item?.data?.id}
                                        isLastItem={index === computes.audioTextItems.length - 1}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <If condition={role === 'customer'}>
                                            <div
                                                className={ClassNames('audio-call-content-body-list', {
                                                    'audio-call-content-view-custom-checked':
                                                        viewCheck === item.data.id ||
                                                        actions.checkedChanged(item.data.id, heightList) ||
                                                        itemId === item.data.id,
                                                })}
                                            >
                                                <div
                                                    key={detail.id + item.data.id}
                                                    className='audio-call-content-view-custom'
                                                >
                                                    <Space>
                                                        <If condition={!isSysMsg}>
                                                            <div
                                                                className='audio-call-content-view-custom-avatar'
                                                                title={item.data.nickName ? item.data.nickName : ''}
                                                            >
                                                                {item.data.avatar ? (
                                                                    <div
                                                                        style={{
                                                                            backgroundImage: `url('${item.data.avatar}')`,
                                                                            backgroundSize: '100% 100%',
                                                                            height: '100%',
                                                                            width: '100%',
                                                                        }}
                                                                    ></div>
                                                                ) : (
                                                                    <Icon type='ic-user-ren' />
                                                                )}
                                                            </div>
                                                        </If>
                                                        <AudioCallTextInfo
                                                            rerenderOptimization={true}
                                                            pointChildrenComputerInfo={
                                                                pointChildrenComputer &&
                                                                pointChildrenComputer[item.data.id]
                                                            }
                                                            markFlag={markFlag}
                                                            pointChildrenPersonInfo={
                                                                pointChildrenPerson && pointChildrenPerson[item.data.id]
                                                            }
                                                            heightList={actions.heightListHit(item.data.id, heightList)}
                                                            role={role}
                                                            fieldsList={this.privates.fieldsList}
                                                            inputFlag={actions.inputFlag}
                                                            fieldsId={this.props.fieldsId}
                                                            markSemanticVisible={markSemanticVisible}
                                                            caseBaselist={actions.caseBaselist}
                                                            inspectDataSource={inspectDataSource}
                                                            item={item}
                                                            type={type}
                                                            content={content}
                                                            onTimeRangeChanged={actions.sendCurrentTimeRange}
                                                            onMarkSemanticClick={actions.markSemantic}
                                                            caseBaseFlag={this.privates.caseBaseFlag}
                                                            fieldFlag={this.privates.fieldFlag}
                                                            waveAudio={waveAudio}
                                                            onAudioPause={actions.audioPause}
                                                            allData={item.allData}
                                                            playRudio={actions.playRudio}
                                                            isSysMsg={isSysMsg}
                                                            dialogRemarkList={dialogRemarkList}
                                                            changeDialogRemarkList={changeDialogRemarkList}
                                                            canRemarkEdit={canRemarkEdit}
                                                            id={id}
                                                            callId={detail.callId}
                                                        />
                                                    </Space>
                                                    {/* {
                                                !isSysMsg && <MessageStatus status={item.data.sendStatus} />
                                            } */}
                                                    {/* <div className='audio-call-content-drag'>
                                                {markFlag && (
                                                    <Checkbox
                                                        checked={actions.checkedChanged(item.data.id, heightList)}
                                                        onChange={actions.RadioChange.params(
                                                            item.data.id,
                                                            heightList,
                                                            markFlag
                                                        )}
                                                    />
                                                )} */}
                                                    {/* {!testSetListFlag && !isSysMsg && (
                                                    <DraggableComponent
                                                        onClickd={actions.audioStop}
                                                        markFlag={markFlag}
                                                        onEnd={actions.audioPlay}
                                                        key={detail.id + item.data.id}
                                                        id={item.data.id}
                                                        viewCheckd={actions.viewCheckd}
                                                    />
                                                )} */}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </If>
                                        <If condition={role === 'agent'}>
                                            <div
                                                className={ClassNames('audio-call-content-body-list', {
                                                    'audio-call-content-view-custom-checked':
                                                        viewCheck === item.data.id ||
                                                        actions.checkedChanged(item.data.id, heightList) ||
                                                        itemId === item.data.id,
                                                })}
                                            >
                                                <div
                                                    key={detail.id + item.data.id}
                                                    className='audio-call-content-view-costom'
                                                >
                                                    {!isSysMsg && <MessageStatus status={item.data.sendStatus} />}
                                                    <Space>
                                                        <AudioCallTextInfo
                                                            pointChildrenComputerInfo={
                                                                pointChildrenComputer &&
                                                                pointChildrenComputer[item.data.id]
                                                            }
                                                            markFlag={markFlag}
                                                            pointChildrenPersonInfo={
                                                                pointChildrenPerson && pointChildrenPerson[item.data.id]
                                                            }
                                                            heightList={actions.heightListHit(item.data.id, heightList)}
                                                            role={role}
                                                            inputFlag={actions.inputFlag}
                                                            fieldsId={this.privates.fieldsId}
                                                            markSemanticVisible={markSemanticVisible}
                                                            caseBaselist={actions.caseBaselist}
                                                            fieldsList={this.privates.fieldsList}
                                                            inspectDataSource={inspectDataSource}
                                                            item={item}
                                                            type={type}
                                                            content={content}
                                                            onTimeRangeChanged={actions.sendCurrentTimeRange}
                                                            onMarkSemanticClick={actions.markSemantic}
                                                            caseBaseFlag={this.privates.caseBaseFlag}
                                                            fieldFlag={this.privates.fieldFlag}
                                                            audioStop={actions.audioStop}
                                                            waveAudio={waveAudio}
                                                            onAudioPause={actions.audioPause}
                                                            allData={
                                                                item.data.content ? JSON.parse(item.data.content) : noop
                                                            }
                                                            playRudio={actions.playRudio}
                                                            isSysMsg={isSysMsg}
                                                            dialogRemarkList={dialogRemarkList}
                                                            changeDialogRemarkList={changeDialogRemarkList}
                                                            canRemarkEdit={canRemarkEdit}
                                                            id={id}
                                                        />
                                                        <If condition={!isSysMsg}>
                                                            <div
                                                                className='audio-call-content-view-costom-avatar'
                                                                title={item.data.nickName ? item.data.nickName : ''}
                                                            >
                                                                {item.data.avatar ? (
                                                                    <div
                                                                        style={{
                                                                            backgroundImage: `url('${item.data.avatar}')`,
                                                                            backgroundSize: '100% 100%',
                                                                            height: '100%',
                                                                            width: '100%',
                                                                        }}
                                                                    ></div>
                                                                ) : (
                                                                    <i class='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-customer-service-kefu'></i>
                                                                )}
                                                            </div>
                                                        </If>
                                                    </Space>

                                                    {/* <div className='audio-call-content-drag'>
                                                {markFlag && (
                                                    <Checkbox
                                                        checked={actions.checkedChanged(item.data.id, heightList)}
                                                        onChange={actions.RadioChange.params(
                                                            item.data.id,
                                                            heightList,
                                                            markFlag
                                                        )}
                                                    />
                                                )} */}
                                                    {/* {!testSetListFlag && !isSysMsg && (
                                                    <DraggableComponent
                                                        onClickd={actions.audioStop}
                                                        markFlag={markFlag}
                                                        onEnd={actions.audioPlay}
                                                        key={detail.id + item.data.id}
                                                        id={item.data.id}
                                                        viewCheckd={actions.viewCheckd}
                                                    />
                                                )} */}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </If>
                                    </React.Fragment>
                                )}
                            </With>
                        </For>
                    </If>
                </div>
                {this.privates.downUrl && (
                    <iframe src={this.privates.downUrl} className='demo-inspection-iframe' title='url'></iframe>
                )}
            </div>
        );
    }
}
