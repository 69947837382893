// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostCallInspectByTaskIdBatchReAsrParam,
    PostCallInspectByTaskIdBatchReAsrBody,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 质检列表批量重新识别
 *
 * @export
 * @tags data-call-batch-re-asr-controller
 * @link [POST] /call/inspect/{taskId}/batch/reAsr
 * @param taskId
 */
export function postCallInspectByTaskIdBatchReAsr(
    data: PostCallInspectByTaskIdBatchReAsrBody,
    options: ApiOptions<PostCallInspectByTaskIdBatchReAsrParam, never> & {
        segments: PostCallInspectByTaskIdBatchReAsrParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PostCallInspectByTaskIdBatchReAsrBody,
        PostCallInspectByTaskIdBatchReAsrParam,
        never
    >('/call/inspect/{taskId}/batch/reAsr', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCallInspectByTaskIdBatchReAsr',
    });
}

export const meta = [
    {
        tags: ['data-call-batch-re-asr-controller'],
        path: '/call/inspect/{taskId}/batch/reAsr',
        method: 'post',
    },
];
