// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostIntelligentExtractTasksResultAddKnowledgeBatchBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 添加到知识库-批量
 *
 * @export
 * @tags 智能抽取接口
 * @link [POST] /intelligentExtractTasks/result/addKnowledge/batch
 */
export function postIntelligentExtractTasksResultAddKnowledgeBatch(
    data: PostIntelligentExtractTasksResultAddKnowledgeBatchBody
): Promise<BaseResponseVoid>;
export function postIntelligentExtractTasksResultAddKnowledgeBatch(
    data: PostIntelligentExtractTasksResultAddKnowledgeBatchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentExtractTasksResultAddKnowledgeBatch(
    data: PostIntelligentExtractTasksResultAddKnowledgeBatchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentExtractTasksResultAddKnowledgeBatchBody, never, never>(
        '/intelligentExtractTasks/result/addKnowledge/batch',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentExtractTasksResultAddKnowledgeBatch',
        }
    );
}

export const meta = [
    {
        tags: ['智能抽取接口'],
        path: '/intelligentExtractTasks/result/addKnowledge/batch',
        method: 'post',
    },
];
