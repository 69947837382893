import React from 'react';
import Udesk from 'Udesk';
import SmartWordsLibraryCommonComponent from '../smart-words-library-common';

export default class AttentionWordsTemplate extends React.Component {
    render() {

        let {
            locales
        } = this;

        let corporaType = Udesk.enums.smartWordsLibraryMenus.ignoreWords.id;

        return (
            <div>
                <SmartWordsLibraryCommonComponent
                    getCommonListPath={`corpora/commonList/${corporaType}`}
                    navItemType={corporaType}
                    navItemName={Udesk.enums.smartWordsLibraryMenus.ignoreWords.key}
                    boardName={Udesk.enums.smartWordsLibraryBoard.ignoreWords.id}
                    uploadModalTitle={locales.components.pages.smartWordsLibrary.upload.uploadIgnoreWords}
                />
            </div>
        );
    }
}