import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { Upload, Button, Icon, Space, Row, Col } from 'udesk-ui';
import CreateForm, { CreateFromProps } from 'Component/common/create-form';
import { getInspectionTasksSourceBySource } from 'src/api/inspectionTasks/source/{source}';
import { getSystemModule, isSalesSubApp } from 'Udesk/system/subApp';
import './style.scss';

const FormUpload = ({ value, onChange, tips, noAccept }) => {
    const locales = UdeskLocales['current'];

    const uploadExcelProps = {
        beforeUpload: (flie) => {
            uploadExcel(flie);
            return false;
        },
        // onRemove: (file) => {
        //     onChange && onChange(null);
        // },
        fileList: [value],
        showUploadList: false,
        maxCount: 1,
        accept: noAccept ? undefined : '.xlsx, .xls',
    };
    const uploadExcelSuccess = (file, uploadData) => {
        onChange && onChange(uploadData);
    };
    const uploadExcelError = (file, uploadData) => {
        Udesk.ui.notify.error(locales.components.pages.recordUpload.baseInfo.excelUoloadFail);
    };
    const uploadExcel = (info) => {
        Udesk.app.fileStorage.upload(info, {
            token: new Promise((resolve, reject) => {
                Udesk.business
                    .getServerAuthorization({
                        type: 2,
                    })
                    .then(
                        function (resp) {
                            resolve(resp);
                        },
                        function (reason) {
                            reject(reason);
                        }
                    );
            }),
            //progressCallback: progressCallback.bind(this),
            successCallback: uploadExcelSuccess,
            errorCallback: uploadExcelError,
        });
    };
    return (
        <React.Fragment>
            {value.key ? (
                <div className="uploaded-file-btn">
                    <Space>
                        <Icon type="excel" />
                        <div className="uploaded-file-btn-inner">{value.name}</div>
                    </Space>
                    <div
                        className="uploaded-file-btn-close"
                        onClick={() => {
                            onChange && onChange({});
                        }}
                    >
                        <Icon type="ic-close-circle-guanbi1" />
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <Upload {...uploadExcelProps}>
                        <Button icon={<Icon type="tianjia1" />}>{locales.labels.uploadFile}</Button>
                    </Upload>
                    {tips && (
                        <div style={{ marginTop: 8, color: 'rgba(0, 0, 0, 0.45)' }}>{tips}</div>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const TemplateBtn = (props) => {
    return (
        <Button
            type="link"
            size="small"
            onClick={() => {
                let { sdkOptions, templateLocalUrl } = props;
                let templateUrl =
                    Udesk.business.apiPath.getStaticBasePath(
                        sdkOptions.props.domain,
                        sdkOptions.props.pathPrefix
                    ) + templateLocalUrl;
                (window as any).location = templateUrl;
            }}
        >
            {props.children}
        </Button>
    );
};
const tips = [1, 2, 3, 4];
const cusConfigMap = { Upload: FormUpload };
export default function ConversationSetting(props) {
    const locales = UdeskLocales['current'];
    const { type } = props;

    const [tasks, setTasks] = useState<any[]>([]);
    const [initialValues, setInitialValues] = useState({
        voiceType: 1,
        agentChannel: 1,
        excel: {},
        uploadDataSource: 2,
        ossKeys: {},
    });
    const [wrapForm] = CreateForm.useForm();
    //excel的校验状态
    const [checkStatus, setCheckStatus] = useState<'unCheck' | 'checking' | 'success' | 'failure'>(
        'unCheck'
    );
    const [uploadDataSource, setUploadDataSource] = useState<number>(
        initialValues.uploadDataSource
    );
    const onFinish = (values) => {
        let url = Udesk.business.apiPath.concatApiPath(`voice-upload/check`, props.sdkOptions);
        let params = {
            size: props.userUploadListLength,
            ossKey: values?.excel?.key,
            systemModule: getSystemModule(),
        };
        setCheckStatus('checking');
        Udesk.ajax.post(url, params).then(
            (resp) => {
                setCheckStatus('success');
                props.saveUserUploadInfo &&
                    props.saveUserUploadInfo({ ...values, checkSuccess: true });
            },
            (reason) => {
                setCheckStatus('failure');
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const downloadTemplate = () => {
        let { sdkOptions } = props;
        let url = Udesk.business.apiPath.concatApiPath(`voice-upload/template`, sdkOptions);

        Udesk.ajax
            .get(url, {
                systemModule: getSystemModule(),
            })
            .then(
                (resp) => {
                    window.location.href = `${resp.data}`;
                },
                (reason) => {}
            );
    };
    const uploadDataSourceOptions = [
        { label: /* 默认 */ locales.pages.recordUpload.detail.info.template.default, value: 1 },
        { label: /* 京东 */ locales.pages.recordUpload.detail.info.template.jDCOM, value: 2 },
    ];
    const config: CreateFromProps = {
        initialValues: initialValues,
        col: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 },
        },
        btnsCol: {
            offset: 10,
            span: 14,
        },
        fields: [
            {
                name: 'uploadDataSource',
                label: /* 数据类型 */ locales.pages.recordUpload.detail.info.template.dataType,
                field: {
                    type: 'RadioGroup',
                    props: {
                        options: uploadDataSourceOptions,
                        optionType: 'button',
                        buttonStyle: 'solid',
                    },
                },
                // forceDisabled: true,
                forceHidden: !isIm(type),
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'taskIds',
                label: isIm(type)
                    ? /* 选择分析任务 */ locales.pages.recordUpload.detail.info.template
                          .selectAnalysisTask
                    : locales.components.pages.recordUpload.baseInfo.task,
                field: {
                    type: 'Select',
                    props: {
                        options: tasks,
                        style: {
                            width: 386,
                        },
                        showSearch: true,
                        filterOption: (input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                        placeholder: locales.labels.pleaseSelect,
                    },
                },
                forceHidden: isSalesSubApp(),
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'taskName',
                label: /* 填写名称 */ UdeskLocales['current'].pages.recordUpload.detail.info
                    .template.fillInTheName,
                field: {
                    type: 'Input',
                    props: {
                        style: {
                            width: 386,
                        },
                        placeholder:
                            /* 填写名称，如本次导入数据的类型/集合/目的 */ UdeskLocales['current']
                                .pages.recordUpload.detail.info.template
                                .fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData,
                    },
                },
                forceHidden: !isSalesSubApp(),
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'ossKeys',
                label: /* 上传文件 */ locales.pages.recordUpload.detail.info.template.uploadFiles,
                field: {
                    type: 'Upload',
                    props: {
                        tips:
                            uploadDataSource === uploadDataSourceOptions[0].value ? (
                                <div>
                                    <div>
                                        {/* 1.下载excel模板，按格式填写： */}
                                        {
                                            locales.pages.recordUpload.detail.info.template
                                                .DownloadExcelTemplateAndFillInAccordingToTheFormat
                                        }
                                        <TemplateBtn
                                            templateLocalUrl={`/import/data-upload/template.xlsx`}
                                            sdkOptions={props.sdkOptions}
                                        >
                                            {/* 模板 */}
                                            {
                                                locales.pages.recordUpload.detail.info.template
                                                    .template
                                            }
                                        </TemplateBtn>
                                    </div>
                                    <div>
                                        {/* 2.最大支持10000条数据 */}
                                        {
                                            locales.pages.recordUpload.detail.info.template
                                                .SupportUpTo10000PiecesOfData
                                        }
                                    </div>
                                </div>
                            ) : (
                                /* 支持京东导出的对话数据上传，.log文件，文件大小最大支持100M */ locales
                                    .pages.recordUpload.detail.info.template
                                    .supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM
                            ),
                        noAccept: true,
                    },
                },
                forceHidden: !isIm(type),
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'voiceType',
                label: locales.components.pages.recordUpload.baseInfo.type,
                field: {
                    type: 'RadioGroup',
                    props: {
                        optionType: 'button',
                        options: [
                            { label: locales.components.pages.recordUpload.double, value: 1 },
                            { label: locales.components.pages.recordUpload.single, value: 2 },
                        ],
                    },
                },
                forceHidden: isIm(type),
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'agentChannel',
                label: locales.components.pages.recordUpload.baseInfo.agentSide,
                field: {
                    type: 'RadioGroup',
                    props: {
                        optionType: 'button',
                        options: [
                            { label: locales.components.pages.recordUpload.left, value: 1 },
                            { label: locales.components.pages.recordUpload.right, value: 2 },
                            { label: locales.components.pages.recordUpload.donotKonw, value: 3 },
                        ],
                    },
                },
                forceHidden: isIm(type),
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'excel',
                label: locales.components.pages.recordUpload.baseInfo.excel,
                field: {
                    type: 'Upload',
                    props: {
                        tips:
                            isIm(type) && uploadDataSource === uploadDataSourceOptions[0].value ? (
                                <div>
                                    <div>
                                        {/* 1.下载excel模板，按格式填写： */}
                                        {
                                            locales.pages.recordUpload.detail.info.template
                                                .DownloadExcelTemplateAndFillInAccordingToTheFormat
                                        }
                                        <TemplateBtn
                                            templateLocalUrl={`/import/data-upload/associate-template.xlsx`}
                                            sdkOptions={props.sdkOptions}
                                        >
                                            {/* 随路数据模板 */}
                                            {
                                                locales.pages.recordUpload.detail.info.template
                                                    .alongTheWayDataTemplate
                                            }
                                        </TemplateBtn>
                                    </div>
                                    <div>
                                        {/* 2.最大支持10000条数据 */}
                                        {
                                            locales.pages.recordUpload.detail.info.template
                                                .SupportUpTo10000PiecesOfData
                                        }
                                    </div>
                                </div>
                            ) : undefined,
                    },
                },
                addonAfter:
                    uploadDataSource === uploadDataSourceOptions[0].value ? undefined : (
                        <Button type="link" size="small" onClick={downloadTemplate}>
                            {locales.components.pages.recordUpload.downloadTemplate}
                        </Button>
                    ),
                forceHidden: isIm(type) && uploadDataSource === uploadDataSourceOptions[1].value,
                // rules: [
                //     {
                //         required: true,
                //     },
                //     ({ getFieldValue }) => ({
                //         validator(_, value) {
                //             if (!value || value.key) {
                //                 return Promise.resolve();
                //             }
                //             return Promise.reject(
                //                 new Error(locales.components.pages.recordUpload.baseInfo.excelFileError)
                //             );
                //         },
                //     }),
                // ],
            },
        ],
        footerRender: isIm(type)
            ? false
            : [
                  {
                      type: 'Button',
                      props: {
                          children: `${
                              locales.components.pages.recordUpload.baseInfo[
                                  checkStatus === 'unCheck'
                                      ? 'checkFileInfo'
                                      : checkStatus === 'checking'
                                      ? 'checking'
                                      : checkStatus === 'failure'
                                      ? 'reCheck'
                                      : 'success'
                              ]
                          }`,
                          type: checkStatus === 'success' ? 'text' : 'primary',
                          style:
                              checkStatus === 'success'
                                  ? { color: 'rgba(15, 186, 38, 1)', padding: 0 }
                                  : undefined,
                          icon:
                              checkStatus === 'success' ? (
                                  <Icon type="ic-right-circle-duigou" />
                              ) : undefined,
                          loading: checkStatus === 'checking',
                          onClick: () => {
                              if (checkStatus === 'success') {
                                  return;
                              }
                              wrapForm.submit();
                          },
                      },
                  },
              ],
        onFinish: onFinish,
        configMap: cusConfigMap,
        onValuesChange: (_, values) => {
            if (_.uploadDataSource && _.uploadDataSource !== uploadDataSource) {
                setUploadDataSource(_.uploadDataSource);
            }

            if (isIm(type)) {
                props.saveUserUploadInfo &&
                    props.saveUserUploadInfo({ ...values, checkSuccess: true });
            } else {
                if (!values?.excel?.key) {
                    props.saveUserUploadInfo &&
                        props.saveUserUploadInfo({ ...values, checkSuccess: false });
                    values?.excel?.key || setCheckStatus('unCheck');
                }
            }
        },
    };
    useEffect(() => {
        let { sdkOptions } = props;
        if (!isIm(type)) {
            let url = Udesk.business.apiPath.concatApiPath(`voice-upload/tasks`, sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    setTasks(resp.data.map((i) => ({ label: i.name, value: i.id })));
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.message);
                }
            );
        } else {
            getInspectionTasksSourceBySource({
                segments: {
                    source: 0,
                },
            }).then(
                (resp) => {
                    setTasks(
                        resp.data
                            ?.filter(
                                (i) => i.inspectDataSource && [2, 8].includes(i.inspectDataSource)
                            )
                            .map((i) => ({ label: i.name, value: i.id })) ?? []
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.message);
                }
            );
        }
        if (props.userUploadInfo) {
            setInitialValues(props.userUploadInfo);
        }
    }, []);
    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues]);
    return (
        <div className="record-upload-base-info-component">
            <CreateForm {...config} form={wrapForm} />
            {!isIm(type) && (
                <Row>
                    {tips.map((tipNum) => (
                        <Col span={14} offset={10}>
                            <div className="upload-excel-tips-item">
                                {
                                    locales.components.pages.recordUpload.baseInfo.tips[
                                        `tip${tipNum}`
                                    ]
                                }
                            </div>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
}

function isIm(type) {
    return type === 'im';
}
