import { EnumSelect } from 'Component/common/enum-component';
import React, { useCallback, useEffect, useState } from 'react';
import Udesk from 'Udesk';
import { Checkbox, InputNumber, Space } from 'udesk-ui';
import { CheckboxChangeEvent } from 'udesk-ui/lib/checkbox';
import UdeskLocales from 'UdeskLocales';
interface CompleteStandartValue {
    timesStatus?: boolean;
    scoreStatus?: boolean;
    completeByTimes?: number;
    completeByScore?: number;
    completeByScoreUnit?: number;
}

interface CompleteStandartProps {
    lessonScore: any;
    disabled?: boolean;
    taskType: number;
    value?: CompleteStandartValue;
    onChange?: (value?: CompleteStandartValue) => void;
}

const CompleteStandart: React.FC<CompleteStandartProps> = (props) => {
    const locales = UdeskLocales['current'];
    const { taskType, value, onChange, lessonScore, disabled } = props;
    const [timesStatus, setTimesStatus] = useState<boolean>(false);
    const [scoreStatus, setScoreStatus] = useState<boolean>(false);
    const [completeByTimes, setCompleteByTimes] = useState<number>();
    const [completeByScore, setCompleteByScore] = useState<number>();
    const [completeByScoreUnit, setCompleteByScoreUnit] = useState<number>(Udesk.enums.scoreStandardUnitType.score.id);

    const triggerChange = (changedValue: CompleteStandartValue) => {
        if (taskType === 2) {
            const newValue = {
                completeByScore: changedValue.completeByScore || completeByScore,
                completeByScoreUnit: changedValue.completeByScoreUnit || completeByScoreUnit,
                scoreStatus: true,
                timesStatus: false,
                completeByTimes: undefined,
            };
            if (newValue.completeByScore == null) {
                return onChange?.(undefined);
            } else {
                return onChange?.(newValue);
            }
        }
        const newValue = {
            timesStatus,
            scoreStatus,
            completeByTimes,
            ...changedValue,
        };
        if (scoreStatus) {
            newValue.completeByScore = changedValue.completeByScore || completeByScore;
            newValue.completeByScoreUnit = changedValue.completeByScoreUnit || completeByScoreUnit;
        }
        if (
            (!newValue.timesStatus && !newValue.scoreStatus) ||
            (newValue.timesStatus && newValue.completeByTimes == null) ||
            (newValue.scoreStatus && newValue.completeByScore == null)
        ) {
            return onChange?.(undefined);
        } else {
            return onChange?.(newValue);
        }
    };

    const onTimesStatusChange = (e: CheckboxChangeEvent) => {
        setTimesStatus(e.target.checked);
        triggerChange({ timesStatus: e.target.checked });
    };

    const onScoreStatusChange = (e: CheckboxChangeEvent) => {
        setScoreStatus(e.target.checked);
        triggerChange({ scoreStatus: e.target.checked });
    };

    const onCompleteByTimesChange = (val: number) => {
        setCompleteByTimes(val);
        triggerChange({ completeByTimes: val });
    };

    const onCompleteByScoreChange = (val: number) => {
        setCompleteByScore(val);
        triggerChange({ completeByScore: val });
    };

    const onCompleteByScoreUnitChange = (val: number) => {
        setCompleteByScoreUnit(val);
        triggerChange({ completeByScoreUnit: val });
    };

    useEffect(() => {
        if (value) {
            setTimesStatus(value?.timesStatus || false);
            setScoreStatus(value?.scoreStatus || false);
            setCompleteByTimes(value?.completeByTimes);
            setCompleteByScore(value?.completeByScore);
            setCompleteByScoreUnit(value?.completeByScoreUnit || Udesk.enums.scoreStandardUnitType.score.id);
        }
    }, [value]);

    const getMax = useCallback((scoreStandardUnit) => {
        if (scoreStandardUnit === Udesk.enums.scoreStandardUnitType.score.id) {
            return lessonScore.total;
        }
        return 100;
    }, [lessonScore.total]);

    return (
        <Space direction="vertical">
            {taskType === 1 && (
                <Space>
                    <Checkbox
                        disabled={disabled}
                        checked={value?.timesStatus || timesStatus}
                        onChange={onTimesStatusChange}
                    >
                        {locales.pages.gong.coachTaskCenter.edit.byTimes}
                    </Checkbox>
                    <Checkbox
                        disabled={disabled}
                        checked={value?.scoreStatus || scoreStatus}
                        onChange={onScoreStatusChange}
                    >
                        {locales.pages.gong.coachTaskCenter.edit.byScore}
                    </Checkbox>
                </Space>
            )}
            {taskType === 1 && (value?.timesStatus || timesStatus) && (
                <Space>
                    <div
                        style={{
                            width: value?.scoreStatus || scoreStatus ? 100 : 70,
                            textAlign: 'right',
                        }}
                    >
                        {`${locales.pages.gong.coachTaskCenter.edit.completeByTimes} ≥`}
                    </div>
                    <InputNumber
                        disabled={disabled}
                        style={{ width: 80 }}
                        min={0}
                        value={value?.completeByTimes || completeByTimes}
                        onChange={onCompleteByTimesChange}
                    />
                    <span>{locales.pages.gong.coachTaskCenter.edit.times}</span>
                </Space>
            )}
            {(taskType === 2 || value?.scoreStatus || scoreStatus) && (
                <Space>
                    <div style={{ width: taskType === 1 ? 100 : 'auto', textAlign: 'right' }}>{`${
                        taskType === 1
                            ? locales.pages.gong.coachTaskCenter.edit.anyTimes
                            : locales.pages.gong.coachTaskCenter.list.taskTypeTest
                    }${locales.pages.gong.coachTaskCenter.edit.getScore} ≥`}</div>
                    <InputNumber
                        disabled={disabled}
                        style={{ width: 80 }}
                        min={0}
                        max={getMax(value?.completeByScoreUnit || completeByScoreUnit)}
                        value={value?.completeByScore || completeByScore}
                        onChange={onCompleteByScoreChange}
                    />
                    <EnumSelect 
                        disabled={disabled}
                        enumKey='scoreStandardUnitType' 
                        value={value?.completeByScoreUnit || completeByScoreUnit} 
                        onChange={onCompleteByScoreUnitChange}
                    />
                </Space>
            )}
        </Space>
    );
};

export const CompleteStandartView = (props) => {
    const { taskType, completeStandartInfo } = props;

    if (taskType === 1) {
        return (
            <span>
                {completeStandartInfo?.timesStatus && (
                    <span
                        style={{ color: '#0376FB' }}
                    >{`${/* 练习次数≥ */UdeskLocales['current'].pages.coach.taskCenter.components.completeStandart.index.practiceFrequency}${completeStandartInfo.completeByTimes}${/* 次 */UdeskLocales['current'].pages.coach.taskCenter.components.completeStandart.index.second}`}</span>
                )}
                {completeStandartInfo?.timesStatus && completeStandartInfo?.scoreStatus && (
                    <span style={{ margin: '0 10px' }}>{`${/* 或 */UdeskLocales['current'].pages.coach.taskCenter.components.completeStandart.index.or}`}</span>
                )}
                {completeStandartInfo?.scoreStatus && (
                    <span
                        style={{ color: '#0376FB' }}
                    >{`${/* 任意一次得分优秀(≥ */UdeskLocales['current'].pages.coach.taskCenter.components.completeStandart.index.excellentScoreAtAnyOneTime}${completeStandartInfo.completeByScore}${/* 分) */UdeskLocales['current'].pages.coach.taskCenter.components.completeStandart.index.points}`}</span>
                )}
            </span>
        );
    }
    if (taskType === 2) {
        return (
            <span
                style={{ color: '#0376FB' }}
            >{`${/* 考试得分≥ */UdeskLocales['current'].pages.coach.taskCenter.components.completeStandart.index.examScore}${completeStandartInfo?.completeByScore}${/* 分 */UdeskLocales['current'].pages.coach.taskCenter.components.completeStandart.index.branch}`}</span>
        );
    }
    return null;
};

export default CompleteStandart;
