import Udesk from 'Udesk';
import RoleIndexComponent from './component';

class RoleIndexRoute extends RoleIndexComponent {
    asyncModel(changedParams) {
        let {
            sdkOptions,
        } = this.props;
        let {
            pageSize,
            pageNum,
        } = this.state;

        let params = {
            pageNum,
            pageSize,
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }

        this.privates.loading = true;
        this.actions.update();
        let url = Udesk.business.apiPath.concatApiPath(`roles`, sdkOptions);

        let rolesPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        return ({
            roles: rolesPromise
        });
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        if (asyncKey === "roles") {
            if (asyncModel.roles) {
                if (asyncModel.roles.data) {
                    this.privates.roles = asyncModel.roles.data;
                    this.privates.loading = false;
                    this.actions.update();
                }
                if (asyncModel.roles.paging) {
                    this.setState({
                        pageNum: asyncModel.roles.paging.pageNum,
                        pageSize: asyncModel.roles.paging.pageSize,
                        total: asyncModel.roles.paging.total
                    });
                }
            }
        }
    }
}

export default RoleIndexRoute;
