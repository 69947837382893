import React from 'react';
import Udesk from 'Udesk';
import LayoutMenu from 'Component/common/layout-menu';
import { Redirect } from 'react-router-dom';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

class AdminEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    actions = {
        transitionTo(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: item.key,
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
    };
    render() {

        let {
            route,
            location,
            sdkOptions,
        } = this.props;
        let {
            locales
        } = this;
        let navLists = [];

        const isInspect = Udesk?.data?.init?.company?.enabledCallInspect !== 0 || Udesk?.data?.init?.company?.enabledImInspect !== 0;

        // 根据当前用户权限，组装菜单列表
        const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;

        if (hasFeature && hasFeature("sys:info:root")) {
            navLists.push({
                text: locales.menu.admin.system.text,
                pathName: getCurrentRouteName("adminSystemIndex"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/system/");
                }
            });
        }
        // 会话设置
        // if(hasFeature && hasFeature("sys:review:wecom:root")){
        //     navLists.push({
        //         text: /* 会话设置 */locales.pages.adminEntry.sessionSettings,
        //         pathName: "sessionSetings",
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/admin/session/");
        //         }
        //     });
        // }
        // logo设置
        if (hasFeature && hasFeature("sys:custom:logo")) {
            navLists.push({
                text: /* logo设置 */locales.pages.adminEntry.logoSettings,
                pathName: getCurrentRouteName("logoSite"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/logo-site");
                }
            });
        }
        //客户字段
        if (hasFeature && hasFeature('sys:customer:field')) {
            navLists.push({
                text: locales.menu.admin.customerField.text,
                pathName: getCurrentRouteName("customerField"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/customer-field");
                }
            });
        }
        if(Udesk.data.init.middleware === 'udesk' && hasFeature && hasFeature('sys:register:root')){
            navLists.push({
                text: locales.menu.admin.systemIntegration.text,
                pathName: getCurrentRouteName("systemIntegrationCCpass"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/integration");
                }
            });    
        }
        if ((Udesk.data.init.company.enabledCallInspect === 1 || Udesk.data.init.company.enabledCallInspect === 3) && hasFeature && hasFeature("sys:voice:root")) {
            navLists.push({
                text: locales.menu.admin.speechRecognition.text,
                pathName: getCurrentRouteName("speechRecognitionIndex"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/speech-recognition");
                }
            });
        }
        if (hasFeature && hasFeature("sys:user:root")) {
            navLists.push({
                text: locales.menu.admin.user.text,
                pathName: getCurrentRouteName("adminUserEntry"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/user/");
                }
            });
        }
        // gong 员工管理
        // if (hasFeature && hasFeature("gong:user:root")) {
        //     navLists.push({
        //         text: locales.menu.admin.user.text,
        //         pathName: "adminGongUserList",
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/admin/medicine-user/");
        //         }
        //     });
        // }
        // gong 角色管理
        // if (hasFeature && hasFeature("gong:role:root")) {
        //     navLists.push({
        //         text: locales.menu.admin.role.text,
        //         pathName: "adminGongRoleList",
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/admin/medicine-role/");
        //         }
        //     });
        // }        
        if (hasFeature && hasFeature("sys:group:root")) {
            navLists.push({
                text: locales.menu.admin.userGroups.text,
                pathName: getCurrentRouteName("adminUserGroupsEntry"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/user-groups/");
                }
            });
        }
        if (hasFeature && hasFeature("sys:role:root")) {
            navLists.push({
                text: locales.menu.admin.role.text,
                pathName: getCurrentRouteName("role"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/role");
                }
            });
        }
        if (hasFeature && hasFeature("sys:dataTransfer:root")) {
            navLists.push({
                text: locales.menu.admin.dataMigration.text,
                pathName: getCurrentRouteName("dataMigration"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/data-migration");
                }
            });
        }
        if (hasFeature && hasFeature("sys:impExp:root")) {
            navLists.push({
                text: locales.menu.admin.taskCenter.text,
                pathName: getCurrentRouteName("taskCenter"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/task-center");
                }
            });
        }
        if (hasFeature && hasFeature("hc:charge:root")) {
            navLists.push({
                text: locales.labels.billingCenter,
                pathName: getCurrentRouteName("billingCenterIndex"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/billing-center");
                }
            });
        }
        if (hasFeature && hasFeature("sys:voiceUpload:root") && isInspect) {
            navLists.push({
                text: /* 本地数据上传 */locales.pages.adminEntry.localDataUpload,
                pathName: getCurrentRouteName("recordUploadIndex"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/record-upload");
                }
            });
        }
        if (hasFeature && hasFeature("sys:work:time")) {
            navLists.push({
                text: /* 业务工作时间 */UdeskLocales['current'].pages.adminEntry.businessWorkingHours,
                pathName: getCurrentRouteName("businessWorkingHours"),
                isActive: (match, location) => {
                    return location.pathname.includes("/business-working-hours");
                }
            });
        }
        if (hasFeature && hasFeature("sys:dataAbnormal:root")) {
            navLists.push({
                text: locales.menu.admin.anomalousData.text,
                pathName: getCurrentRouteName("anomalousDataIndex"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/anomalous-data");
                }
            });
        }
        // let grounp = [];
        // if (hasFeature && hasFeature("sys:module:list:root")) {
        //     let grounpChildren = {
        //         text: '模组列表',
        //         pathName: "templateComponentListIndex",
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/template-component-list");
        //         }
        //     };
        //     grounp.push(grounpChildren);
        // }
        // if (hasFeature && hasFeature("sys:module:my:root")) {
        //     let grounpChildren = {
        //         text: '我的模组',
        //         pathName: 'templateComponentMyIndex',
        //         isActive: (match, location) => {
        //             return location.pathname.includes('/template-component-my');
        //         }
        //     };
        //     grounp.push(grounpChildren);
        // }
        // if (hasFeature && hasFeature("sys:module:root")) {
        //     navLists.push({
        //         text: '模板组件',
        //         children: grounp,
        //     });
        // }
        // grounp = [];
        if (hasFeature && hasFeature("sys:message:root")) {
            navLists.push({
                text: locales.menu.admin.systemLog.text,
                pathName: getCurrentRouteName("systemLogIndex"),
                isActive: (match, location) => {
                    return location.pathname.includes("/admin/system-log");
                }
            });
        }
        // 消息中心
        // if (hasFeature && hasFeature("sys:review:msgbox:root")) {
        //     navLists.push({
        //         text: locales.menu.admin.msgCenter.text,
        //         pathName: "adminMsgCenterReply",
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/admin/message-center");
        //         }
        //     });
        // }

        // navLists.push({
        //     text: locales.menu.admin.wechatDocking.text,
        //     pathName: "wechatDocking",
        //     isActive: (match, location) => {
        //         return location.pathname.includes("/admin/wechat-docking");
        //     }
        // });

        // const defaultSelectedKeys = [];
        // const defaultSelected = navLists.find(item => item.isActive(null, location));
        // if (defaultSelected) {
        //     defaultSelectedKeys.push(defaultSelected.pathName);
        // }

        if (sdkOptions.props.landingPath != null && this.isRedirect(route.path, location.pathname)) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = null;
            if (navLists[0].pathName) {
                toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                    routeName: navLists[0].pathName
                });
            } else {
                toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                    routeName: navLists[0].children[0].pathName
                });
            }

            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="admin-entry">
                    <LayoutMenu {...this.props} navLists={navLists} menuTitle={locales.components.pages.menuTitleList.adminTitle} />
                </div>
            );
        }
    }
}
export default Udesk.react.udeskify(AdminEntry);