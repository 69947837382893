import { timeago as ExternalTimeago } from '../ref-npm-modules';
import { timeagoLocale_ZH_CN } from '../ref-npm-modules';
import { timeagoLocale_ZH_TW } from '../ref-npm-modules';
import { timeagoLocale_EN } from '../ref-npm-modules';
import { fecha as ExternalFecha } from '../ref-npm-modules';
import UdeskLocaleManager from '../locales';
import UdeskConfig from '../config';

ExternalTimeago.register("EN", timeagoLocale_EN);
ExternalTimeago.register("EN-US", timeagoLocale_EN);
ExternalTimeago.register("ZH", timeagoLocale_ZH_CN);
ExternalTimeago.register("ZH-CN", timeagoLocale_ZH_CN);
ExternalTimeago.register("ZH-TW", timeagoLocale_ZH_TW);
ExternalTimeago.register("ZH-HK", timeagoLocale_ZH_TW);

export default {
    format,
    parse,
    formatDuration,
    formatDurationWithMillisecond,
    timeago
};

function format(date, format) {
    if (format == null && typeof date === "string") {
        format = date;
        date = new Date();
    }
    return ExternalFecha.format(date, format);
}

function parse(dateString, format) {
    if (format == null) {
        format = UdeskConfig.date.momentDateFormat;
    }
    return ExternalFecha.parse(dateString, format);
}

function formatDuration(durationSeconds) {
    let hours = Math.floor(durationSeconds / (60 * 60));
    hours = hours >= 10 ? hours : `0${hours}`;
    let second = durationSeconds % (60 * 60);
    let minutes = Math.floor(second / 60);
    minutes = minutes >= 10 ? minutes : `0${minutes}`;
    second= Math.round(second) % 60
    second = second >= 10 ? second : `0${second}`;
    return `${hours}:${minutes}:${second}`;
}

function formatDurationWithMillisecond(durationMilliseconds) {
    let durationSeconds = Math.floor(durationMilliseconds);
    let formatString = formatDuration(durationSeconds);
    let milliseconds = Math.round(durationMilliseconds % 1 * 1000) + "";
    milliseconds = milliseconds.padStart(3, "0");
    return `${formatString}.${milliseconds}`;
}

function timeago(date, languageCode = "") {
    if (!languageCode) {
        languageCode = UdeskLocaleManager.currentLanguage;
    }
    if (typeof languageCode !== "string") {
        languageCode = languageCode.toString();
    }
    languageCode = languageCode.toUpperCase();
    return ExternalTimeago().format(date, languageCode);
}