// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { GetOauthByDomainCallbackUrlParam, BaseResponseOauthConfigDto } from '../../../types';

/**
 * getCallbackUrl
 *
 * @export
 * @tags oauth-controller
 * @link [GET] /oauth/{domain}/callbackUrl
 * @param domain
 */
export function getOauthByDomainCallbackUrl(
    options: ApiOptions<GetOauthByDomainCallbackUrlParam, never> & {
        segments: GetOauthByDomainCallbackUrlParam;
    }
): Promise<BaseResponseOauthConfigDto> {
    return request<never, GetOauthByDomainCallbackUrlParam, never>('/oauth/{domain}/callbackUrl', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getOauthByDomainCallbackUrl',
    });
}

export const meta = [
    { tags: ['oauth-controller'], path: '/oauth/{domain}/callbackUrl', method: 'get' },
];
