import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Space, Select } from 'udesk-ui';
import { getIntelligentPartnerTaskInfosScorePoints } from 'src/api/intelligentPartnerTaskInfos/score/points/index';
import { IntelligentPartnerScoreRule } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

type KeyPointHitProps = {
    value?: {
        point?: {
            taskId?: number;
            nodeId?: number;
            ruleId?: number;
        };
        isHit?: 0 | 1;
    };
    onChange?: (v: KeyPointHitProps['value']) => void;
    taskId?: number;
};

export const KeyPointHit: FC<KeyPointHitProps> = (props) => {
    const { value, onChange, taskId } = props;

    const { point, isHit } = value || {};

    // 这里，uid 是由该要点的 taskId nodeId ruleId 拼接而成
    const uid = useMemo(
        () =>
            point?.taskId && point?.nodeId && point?.ruleId
                ? `${point?.taskId}-${point?.nodeId}-${point?.ruleId}`
                : undefined,
        [point?.nodeId, point?.ruleId, point?.taskId]
    );

    const onKeyPointChange = (v, o) => {
        onChange?.({
            ...value,
            point: {
                ...o,
            },
        });
    };

    const onHitChange = (v) => {
        onChange?.({
            ...value,
            isHit: v,
        });
    };

    const [points, setPoints] = useState<IntelligentPartnerScoreRule[]>([]);
    const controllerRef = useRef<any>();

    const getPoints = useCallback((taskId) => {
        const controller = new AbortController();
        controllerRef.current = controller;
        const { signal } = controller;

        return getIntelligentPartnerTaskInfosScorePoints({
            params: { taskId },
            signal,
        })
            .then((res) => {
                setPoints(res.data || []);
            })
            .catch(() => {
                setPoints([]);
            });
    }, []);

    useEffect(() => {
        controllerRef.current?.abort?.();
        if (taskId) {
            getPoints(taskId);
        } else {
            setPoints([]);
        }
    }, [getPoints, taskId]);

    const _points = useMemo(
        () =>
            points.map((p) => ({
                ...p,
                label: p.ruleName,
                value: `${p.taskId}-${p.nodeId}-${p.ruleId}`,
            })),
        [points]
    );

    return (
        <Space>
            <Select
                value={uid}
                onChange={onKeyPointChange}
                options={_points}
                style={{ width: 120 }}
                allowClear
                placeholder={/* 请选择 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.components.keyPointHit.index.pleaseSelect}
            />

            <Select
                value={isHit}
                onChange={onHitChange}
                options={[
                    {
                        label: /* 命中 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.components.keyPointHit.index.hit,
                        value: 1,
                    },
                    {
                        label: /* 未命中 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.components.keyPointHit.index.misses,
                        value: 0,
                    },
                ]}
                style={{ width: 120 }}
                allowClear
                placeholder={/* 请选择 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.components.keyPointHit.index.pleaseSelect}
            />
        </Space>
    );
};
