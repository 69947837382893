import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import TaskBasicInfoComponent from './component';

export default class TaskBasicInfoRoute extends TaskBasicInfoComponent {
    model() {
        let params = Object.assign(
            {},
            this.props.match.params,
            Object.fromEntries(new URLSearchParams(this.props.location.search))
        );
        if (params.taskId !== '0') {
            this.privates.isEdit = true;
        }
        let modelPromise =
            params.taskId !== '0'
                ? new Promise((resolve, reject) => {
                    let url = Udesk.business.apiPath.concatApiPath(
                        '/inspectionTasks/' + params.taskId,
                        this.props.sdkOptions
                    );
                    Udesk.ajax.get(url).then(
                        (resp) => {
                            this.privates.name = resp.data.name;
                            this.privates.remark = resp.data.remark;
                            this.privates.inspectDataSource = resp.data.inspectDataSource;
                            this.privates.related = resp.data.related;
                            this.privates.sessionType = resp.data.sessionType;
                            this.privates.period = resp.data.period;
                            let associateRegulation = resp.data.associateRegulation;
                            if(associateRegulation){
                                associateRegulation.conditionList = associateRegulation.conditionList.map(i => i.field);
                                this.privates.associateRegulation = associateRegulation;
                                

                            }
                            this.privates.conditions = (
                                associateRegulation?.conditionList ?? []
                            ).map((item, i) => ({ ...item, order: i + 1 }));
                            
                            this.setState({
                                inspectorList: resp.data.inspectorList,
                            });
                            resolve(resp.data);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                            );
                            reject(reason);
                        }
                    );
                })
                : null;
        let getInspector = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/users?pageSize=1000', this.props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        Udesk.utils.string.format(
                            UdeskLocales.current.business.info.loadError,
                            UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                        )
                    );
                    reject(reason);
                }
            );
        });
        if (params.taskId !== '0') {
            return {
                task: modelPromise,
                inspector: getInspector,
            };
        } else {
            return {
                inspector: getInspector,
            };
        }
    }
}
