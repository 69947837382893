import React, { useState, useMemo, useCallback } from 'react';
import { useWordStyle } from 'src/util/hook/useWordStyle';
import { Button, Drawer, Input, Space, Spin, Form, Select, message } from 'udesk-ui';
import styled from 'styled-components';
import TipInput from './components/TipInput';
import PptPreview from './components/PptPreview';
import { postReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowId } from 'src/api/review/intelligentPartner/node/pptConfig/generateAiWords/{flowId}';
import { putReviewIntelligentPartnerNodePptConfigBatch } from 'src/api/review/intelligentPartner/node/pptConfig/batch';
import { onCopyText } from 'src/util/copy';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

const FormWrapper = styled.div`
    padding: 16px;
`;

const NodeListFormWrapper = styled.div`
    padding: 16px;
    background-color: #eee;
    margin: 16px 16px 32px;

    .udesk-qa-ui-form-item {
        margin-bottom: 12px;
    }
`;

const Operations = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ListWrapper = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const InitializedConfigDrawer = (props) => {
    const {
        visible,
        onClose,
        form,
        loading,
        onFinish,
        nodeForm,
        onApply,
        hasGen,
        onCopy,
        wordStyleOptions,
    } = props;

    return (
        <Drawer
            width={500}
            style={{ position: 'absolute' }}
            getContainer={document.getElementsByClassName('udesk-qa-web-page')[0] as any}
            className="udesk-coach-web-config-drawer"
            visible={visible}
            title={/* 生成全文演讲话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.generateFullTextSpeechScript}
            mask={false}
            footer={null}
            onClose={onClose}
        >
            <Spin spinning={loading}>
                <FormWrapper>
                    <Form
                        form={form}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        initialValues={{
                            wordsStyle: 1,
                            sessionRound: 20,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label={/* 演讲者 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.speaker} name="speaker" rules={[{ required: true }]}>
                            <TipInput />
                        </Form.Item>

                        <Form.Item
                            label={/* 话术风格 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.scriptStyle}
                            name="wordsStyle"
                            rules={[{ required: true }]}
                        >
                            <Select options={wordStyleOptions} />
                        </Form.Item>

                        <Form.Item noStyle>
                            <Operations>
                                <Space>
                                    {hasGen ? (
                                        <>
                                            <Button onClick={onCopy}>{/* 复制对话 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.copyConversation}</Button>
                                            <Button onClick={onApply}>{/* 应用 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.application}</Button>
                                            <Button type="primary" htmlType="submit">{/* 重新生成 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.regenerate}</Button>
                                        </>
                                    ) : (
                                        <Button type="primary" htmlType="submit">{/* 开始生成 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.startGenerating}</Button>
                                    )}
                                </Space>
                            </Operations>
                        </Form.Item>
                    </Form>
                </FormWrapper>

                {hasGen ? (
                    <NodeListFormWrapper>
                        <Form form={nodeForm} size="small">
                            <Form.List name="aiNodeWordsList">
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            {fields.map((f, idx) => {
                                                return (
                                                    <ListWrapper key={f.key}>
                                                        <div>{idx + 1}</div>
                                                        <Form.Item
                                                            name={[f.name, 'pptImageList', 0]}
                                                            noStyle
                                                        >
                                                            <PptPreview />
                                                        </Form.Item>
                                                        <Form.Item name={[f.name, 'words']} noStyle>
                                                            <Input.TextArea
                                                                style={{
                                                                    width: 270,
                                                                    resize: 'none',
                                                                }}
                                                                rows={4}
                                                            />
                                                        </Form.Item>
                                                    </ListWrapper>
                                                );
                                            })}
                                        </>
                                    );
                                }}
                            </Form.List>
                        </Form>
                    </NodeListFormWrapper>
                ) : null}
            </Spin>
        </Drawer>
    );
};

const useConfigDrawer = (props) => {
    const { requestFlowData, flowId, reactFlowInstance } = props;

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [hasGen, setHasGen] = useState(false);

    const { options: wordStyleOptions } = useWordStyle();

    const onOpen = useCallback(() => {
        setVisible(true);
        form.resetFields();
    }, [form]);

    const onClose = () => {
        setVisible(false);
        setHasGen(false);
    };

    const [nodeForm] = Form.useForm();

    const onFinish = useMemo(() => {
        return (values) => {
            setLoading(true);
            return postReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowId(
                {
                    ...values,
                },
                { segments: { flowId } }
            )
                .then((res) => {
                    nodeForm.setFieldsValue({ aiNodeWordsList: res.data || [] });
                    setHasGen(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
    }, [flowId, nodeForm]);

    const onApply = () => {
        nodeForm.validateFields().then((values) => {
            const list = values.aiNodeWordsList || [];
            if (!list.length) {
                message.warning(/* 请生成流程 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.pleaseGenerateAProcess);
                return;
            }

            setLoading(true);
            putReviewIntelligentPartnerNodePptConfigBatch(list)
                .then(() => {
                    message.success(/* 操作成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.operationSuccessful);
                    requestFlowData(() => {
                        reactFlowInstance?.setViewport?.({ x: 0, y: 0 }, { duration: 300 });
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const onCopy = () => {
        let text = '';
        const aiNodeWordsList = nodeForm.getFieldsValue()?.aiNodeWordsList || [];
        if (!aiNodeWordsList.length) {
            message.warning(/* 请生成流程 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.pleaseGenerateAProcess);
            return;
        }
        text = aiNodeWordsList.reduce((pre, cur, idx) => {
            return `${pre}\n${idx + 1}：${cur.words}`;
        }, text);
        onCopyText(text, () => {
            message.success(/* 复制成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenPPTDrawer.index.copySuccessful);
        });
    };

    return {
        visible,
        onOpen,
        onClose,
        form,
        loading,
        onFinish,
        nodeForm,
        onApply,
        hasGen,
        onCopy,
        wordStyleOptions,
    };
};

type ConfigDrawerType = typeof InitializedConfigDrawer & {
    useConfigDrawer: typeof useConfigDrawer;
};

const AiGenPPTDrawer: ConfigDrawerType = InitializedConfigDrawer as ConfigDrawerType;

AiGenPPTDrawer.useConfigDrawer = useConfigDrawer;

export { AiGenPPTDrawer };
