import React from 'react';
import LoadingAnimationComponent from './component';

class LoadingAnimationTemplate extends LoadingAnimationComponent {
    render() {
        let {
            props,
            state
        } = this;
        return (
            <div className={["udesk-loading-animation", "animation-" + (props.type || this.DefaultAnimationName), props.classNames, props.align, props.verticalAlign, state.isTimeout ? "" : "animation-invisible"].filter(Boolean).join(" ")}>
                <If condition={state.isTimeout}>
                    <If condition={props.type === "indicator"}>
                        <div className="indicator">
                            <svg width="16px" height="12px">
                                <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
                                <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
                            </svg>
                        </div>
                    </If>
                    <If condition={props.type === "moving-balls"}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </If>
                    <If condition={props.type === "pulse"}>
                        <div className="pulse"></div>
                    </If>
                    <If condition={props.type === "line-scale-pulse-out"}>
                        <div className="line-scale-pulse-out">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </If>
                    <If condition={props.type === "line-scale-pulse-out-rapid"}>
                        <div className="line-scale-pulse-out-rapid">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </If>
                    <If condition={props.type === "ball-scale-multiple"}>
                        <div className="ball-scale-multiple">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </If>
                    <If condition={props.type === "ball-scale-ripple-multiple"}>
                        <div className="ball-scale-ripple-multiple">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </If>
                    <If condition={props.type === "line-spin-fade-loader"}>
                        <div className="line-spin-fade-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </If>
                </If>
            </div>
        );
    }
}

export default LoadingAnimationTemplate;