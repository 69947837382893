import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { Button, Modal, Form, Input, message } from 'udesk-ui';
import { FilterContext, InitialValuesContext } from '../../context';
import { postIntelligentPartnerHomePageFilter } from 'src/api/intelligentPartner/homePage/filter/index';
import { putIntelligentPartnerHomePageByIdFilter } from 'src/api/intelligentPartner/homePage/{id}/filter/index';
import UdeskLocales from 'UdeskLocales';

type PropsType = {
    current: any;
};

export default React.memo((props: PropsType) => {
    const { current } = props;

    const locales = UdeskLocales['current'];

    const [visible, setVisible] = useState(false);
    const [names, setNames] = useState('');
    const [showFlag, setShowFlag] = useState(true);
    const [conditions] = useContext(FilterContext);
    const [, setInitialValues] = useContext(InitialValuesContext);

    const isCustom = useMemo(() => {
        return current.id === 'default';
    }, [current.id]);

    const onSaveConfig = () => {
        if (isCustom) {
            setVisible(true);
        } else {
            const params = {
                name: current.name,
                condition: conditions,
            };
            const id = current.id;

            putIntelligentPartnerHomePageByIdFilter(params, { segments: { id } })
                .then(() => {
                    setInitialValues({
                        data: {
                            organizationIds: conditions?.organizationIds
                                ?.split(',')
                                .map((id) => parseInt(id)),
                        },
                        date: [conditions.startDate, conditions.endDate],
                    });
                    setNames('');
                    message.success(
                        /* 更新成功 */ UdeskLocales['current'].pages.coach.dashboard.components
                            .filterConfig.modal.updateSuccessful
                    );
                })
                .finally(() => {
                    setVisible(false);
                });
        }
    };

    const onCancel = useCallback(() => {
        setVisible(false);
        setNames(isCustom ? '' : current.name);
    }, [current.name, isCustom]);

    const onFinish = useCallback(() => {
        const params = {
            name: names,
            condition: conditions,
        };

        if (isCustom) {
            postIntelligentPartnerHomePageFilter(params)
                .then(() => {
                    setInitialValues({
                        data: {
                            organizationIds: conditions?.organizationIds
                                ?.split(',')
                                .map((id) => parseInt(id)),
                        },
                        date: [conditions.startDate, conditions.endDate],
                    });
                    setNames('');
                    message.success(
                        /* 保存成功 */ UdeskLocales['current'].pages.coach.dashboard.components
                            .filterConfig.modal.successfullySaved
                    );
                })
                .finally(() => {
                    setVisible(false);
                });
        }
    }, [isCustom, names, conditions, setInitialValues]);

    const nameChange = useCallback((e) => {
        setNames(e.target.value);
        setShowFlag(e.target.value === '');
    }, []);

    useEffect(() => {
        if (current) {
            setNames(isCustom ? '' : current.name);
        }
    }, [current, isCustom]);

    return (
        <div>
            <Button type="primary" onClick={onSaveConfig}>
                {isCustom
                    ? /* 设置为常用报表 */ UdeskLocales['current'].pages.coach.dashboard.components
                          .filterConfig.modal.setAsACommonReport
                    : /* 更新当前报表 */ UdeskLocales['current'].pages.coach.dashboard.components
                          .filterConfig.modal.updateCurrentReport}
            </Button>

            <Modal
                title={locales.components.sdkLoaderBase.modalMonit.saveCurrentReportConfiguration}
                visible={visible}
                footer={null}
                onOk={onFinish}
                onCancel={onCancel}
            >
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                max: 20,
                                message:
                                    locales.components.sdkLoaderBase.modalMonit
                                        .pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs,
                            },
                        ]}
                    >
                        <div style={{ display: 'flex' }}>
                            <Input
                                value={names}
                                maxLength={20}
                                maxCount={20}
                                showCount={true}
                                onChange={nameChange}
                                placeholder={
                                    locales.components.sdkLoaderBase.modalMonit
                                        .pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs
                                }
                            />
                            <Button
                                style={{ marginLeft: '3px' }}
                                type="primary"
                                disabled={showFlag}
                                htmlType="submit"
                            >
                                {locales.components.sdkLoaderBase.modalMonit.preservation}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
});
