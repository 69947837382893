import React from 'react';
import Udesk from 'Udesk';
import CustomizeModelError from '../components/pages/model-error-page';

export default class pageNotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.backToHome = this.backToHome.bind(this);
    }
    backToHome(){
        const routeOptions = {
            history: this.props.history,
            routeName: 'tasksIndex',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    }
    render() {
        return (
            <CustomizeModelError backToTasks={this.backToHome}/>
        );
    }
}
