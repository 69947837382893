import Udesk from 'Udesk';
import FastTrainingIndexComponent from './component';

class FastTrainingIndexRoute extends FastTrainingIndexComponent {
    model(changedParams) {
        let {
            sdkOptions,
            // match
        } = this.props;

        let semanticTagsToFastTraining_pamars = JSON.parse(sessionStorage.getItem('semanticTagsToFastTraining_pamars')) || null;
        if(semanticTagsToFastTraining_pamars){
            let itemNum = semanticTagsToFastTraining_pamars.itemNum;

            this.privates.semanticTagsPageNum = Math.ceil(itemNum/this.privates.semanticTagsPageSize);
            this.privates.semanticTagsInputVlaueForSearch = semanticTagsToFastTraining_pamars.keywords || semanticTagsToFastTraining_pamars.title;
            this.privates.semanticTagsInputVlaue = semanticTagsToFastTraining_pamars.keywords || semanticTagsToFastTraining_pamars.title;

        }
        let params = {
            pageNum: this.privates.semanticTagsPageNum,
            pageSize: this.privates.semanticTagsPageSize,
            keywords: this.privates.semanticTagsInputVlaueForSearch
        };
        let url = Udesk.business.apiPath.concatApiPath(`semantic-tag/train/list`, sdkOptions);

        return new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                resp => {
                    resolve(resp);
                    this.privates.semanticTagsList = resp.data;
                    if(resp.paging){
                        this.privates.semanticTagsTotal = resp.paging.total;
                        this.privates.semanticTagsPageNum = resp.paging.pageNum;
                    }
                    !semanticTagsToFastTraining_pamars
                        && this.privates.firstSearchFlag
                        && this.privates.semanticTagsPageNum === 1
                        && this.privates.activeSemanticTag === null
                        && (this.privates.activeSemanticTag = this.privates.semanticTagsList[0] || {});
                    semanticTagsToFastTraining_pamars
                        && (this.privates.activeSemanticTag = this.privates.semanticTagsList.find(item => {
                            return item.id === semanticTagsToFastTraining_pamars.id;
                        }));
                    this.actions.update();
                    sessionStorage.removeItem('semanticTagsToFastTraining_pamars');
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    }
}

export default FastTrainingIndexRoute;
