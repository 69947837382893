import { useState } from 'react';
import {
    connectNodes,
    deleteNodeById,
    deleteNodesConnection,
    recoverNodeDeletedStatusById,
    updateNodePositionById,
} from './utils/nodeOperates';

type RecordType = EdgeRecordType | NodeRecordType;

type EdgeRecordType = typeof CreateEdge | typeof MoveEdge | typeof DeleteEdge;
type NodeRecordType = typeof CreateNode | typeof MoveNode | typeof DeleteNode;

// 1. 创建节点
const CreateNode = 'CreateNode';
// 2. 移动节点
const MoveNode = 'MoveNode';
// 3. 删除节点
const DeleteNode = 'DeleteNode';
// 4. 创建连线
const CreateEdge = 'CreateEdge';
// 5. 修改连线
const MoveEdge = 'MoveEdge';
// 6. 删除连线
const DeleteEdge = 'DeleteEdge';

type Record = {
    redo: Function;
    undo: Function;
};
class Store {
    queue: Record[];
    pointer;
    constructor() {
        this.queue = [];
        this.pointer = -1;
    }

    record(data: Record) {
        while (this.pointer < this.queue.length - 1) {
            this.queue.pop();
        }
        this.queue.push(data);
        this.pointer++;
    }

    undo = (step = 1) => {
        const action = this.queue[this.pointer || 0];
        this.pointer -= step;
        return action.undo();
    };

    redo = (step = 1) => {
        this.pointer += step;
        const action = this.queue[this.pointer || 0];
        return action.redo();
    };
}

export function useFlowStepStore(refreshFlow) {
    const [store] = useState(new Store());
    const [undoAble, setUndoAble] = useState<boolean>(false);
    const [redoAble, setRedoAble] = useState<boolean>(false);

    const changeUndoRedoStatus = () => {
        setUndoAble(store.queue.length > 0 && store.pointer >= 0);
        setRedoAble(store.queue.length > 0 && store.pointer < store.queue.length - 1);
    };

    const createRecord = createRecordFactory(refreshFlow, changeUndoRedoStatus);

    const recordNodeCreate = ({ nodeId }: { nodeId: string }) => {
        store.record(
            createRecord<NodeRecordType>(CreateNode, {
                nodeId,
            })
        );
        changeUndoRedoStatus();
    };

    const recordNodeDeletion = ({ nodeId }: { nodeId: string }) => {
        store.record(
            createRecord<NodeRecordType>(DeleteNode, {
                nodeId,
            })
        );
        changeUndoRedoStatus();
    };
    const recordNodeMove = ({
        nodeId,
        originPosition,
        newPosition,
    }: {
        nodeId: string;
        originPosition: string;
        newPosition: string;
    }) => {
        store.record(
            createRecord<NodeRecordType>(MoveNode, {
                nodeId,
                originPosition,
                newPosition,
            })
        );
        changeUndoRedoStatus();
    };

    const recordEdgeCreate = ({
        sourceNodeId,
        targetNodeId,
    }: {
        sourceNodeId: string;
        targetNodeId: string;
    }) => {
        store.record(
            createRecord<EdgeRecordType>(CreateEdge, {
                sourceNodeId,
                targetNodeId,
            })
        );
        changeUndoRedoStatus();
    };

    const recordEdgeDeletion = ({
        sourceNodeId,
        targetNodeId,
    }: {
        sourceNodeId: string;
        targetNodeId: string;
    }) => {
        store.record(
            createRecord<EdgeRecordType>(DeleteEdge, {
                sourceNodeId,
                targetNodeId,
            })
        );
        changeUndoRedoStatus();
    };

    return {
        recordNodeCreate,
        recordNodeMove,
        recordNodeDeletion,
        recordEdgeCreate,
        recordEdgeDeletion,
        store,
        undoAble,
        redoAble,
    };
}

type NodeChangeOptions = {
    nodeId: string;
    originPosition?: string;
    newPosition?: string;
};

type EdgeChangeOptions = {
    sourceNodeId: string;
    targetNodeId: string;
};
type CreateRecordOptions<RecordType> = RecordType extends NodeRecordType
    ? NodeChangeOptions
    : EdgeChangeOptions;

function createRecordFactory(refreshFlow: Function, changeUndoRedoStatus: Function) {
    return function createRecord<T>(type: RecordType, options: CreateRecordOptions<T>) {
        const { nodeId, originPosition, sourceNodeId, targetNodeId, newPosition } =
            options as NodeChangeOptions & EdgeChangeOptions;
        const _nodeId = parseInt(nodeId, 10);
        const _sourceNodeId = parseInt(sourceNodeId, 10);
        const _targetNodeId = parseInt(targetNodeId, 10);

        const _recoverNodeDeletedStatus = (nodeId) => () => recoverNodeDeletedStatusById(nodeId);

        function triggerActionAndRefresh(action) {
            return () =>
                action().then(() => {
                    refreshFlow();
                    changeUndoRedoStatus();
                });
        }
        function createNodeRecord(record: Record, nodeId: number) {
            record.redo = triggerActionAndRefresh(_recoverNodeDeletedStatus(_nodeId));
            record.undo = triggerActionAndRefresh(() => deleteNodeById(nodeId));

            return record;
        }
        function moveNodeRecord(
            record: Record,
            nodeId: number,
            originPosition: any,
            newPosition: any
        ) {
            record.redo = triggerActionAndRefresh(() =>
                updateNodePositionById(nodeId, newPosition)
            );
            record.undo = triggerActionAndRefresh(() =>
                updateNodePositionById(nodeId, originPosition)
            );

            return record;
        }
        function deleteNodeRecord(record: Record, nodeId: number) {
            record.redo = triggerActionAndRefresh(() => deleteNodeById(nodeId));
            record.undo = triggerActionAndRefresh(_recoverNodeDeletedStatus(nodeId));
            return record;
        }
        function createEdgeRecord(record: Record, sourceNodeId: number, targetNodeId: number) {
            record.redo = triggerActionAndRefresh(() => connectNodes(sourceNodeId, targetNodeId));
            record.undo = triggerActionAndRefresh(() =>
                deleteNodesConnection(sourceNodeId, targetNodeId)
            );
            return record;
        }
        function deleteEdgeRecord(record: Record, sourceNodeId: number, targetNodeId: number) {
            record.redo = triggerActionAndRefresh(() =>
                deleteNodesConnection(sourceNodeId, targetNodeId)
            );
            record.undo = triggerActionAndRefresh(() => connectNodes(sourceNodeId, targetNodeId));
            return record;
        }
        const record: Record = {
            redo: () => {},
            undo: () => {},
        };

        switch (type) {
            case CreateNode:
                return createNodeRecord(record, _nodeId);
            case MoveNode:
                return moveNodeRecord(record, _nodeId, originPosition, newPosition);
            case DeleteNode:
                return deleteNodeRecord(record, _nodeId);
            case CreateEdge:
                return createEdgeRecord(record, _sourceNodeId, _targetNodeId);
            case DeleteEdge:
                return deleteEdgeRecord(record, _sourceNodeId, _targetNodeId);

            default:
                // no-locale-translate
                console.warn('createRecord 没控制住');
                return record;
        }
    };
}
