export default {
  lang: {
    export: 'Esportazione',
    successfully: 'Esporta attività creata con successo',
    pleaseJumpTo: 'Si prega di saltare',
    uploadAndDownloadCenter: 'Centro di caricamento e download',
    check: 'Visualizza',
    exportAll: "Confermare l'esportazione di tutti i contenuti?",
    total: 'Totale',
    confirm: "Dati, confermare l'esportazione?",
    error: 'La quantità di dati esportabili è 0, modifica i criteri di filtro e riprova',
  },
};
