import React, { useState } from 'react';
import Locales from 'UdeskLocales';
import { Icon, Dropdown, Space } from 'udesk-ui';
import './style.scss';

const locales = Locales['current'];

function FolderItem(props) {
    let { isCollection, name, collectFlag, callCount, createUserName, createTime } = props;
    const [mouseOver, setMouseOver] = useState(false);
    const handlerCollection = (e) => {
        props.handlerCollection && props.handlerCollection(props);
        e.stopPropagation();
    };
    const handlerOpen = (e) => {
        props.handlerOpen && props.handlerOpen(props);
        e.stopPropagation();
    };
    const handlerMouseEnter = () => {
        setMouseOver(true);
    };
    const handlerMouseLeave = () => {
        setMouseOver(false);
    };
    const handlerActionsMenu = (e) => {
        e.stopPropagation();
    };
    return (
        <div
            className={
                mouseOver
                    ? `component-common-folder-item-container folder-item-active`
                    : 'component-common-folder-item-container'
            }
            onClick={handlerOpen}
            onMouseEnter={handlerMouseEnter}
            onMouseLeave={handlerMouseLeave}
        >
            <div className='folder-content-detail'>
                <div className='folder-icon'>
                    <Icon style={{ fontSize: 20, color: '#1A6EFF' }} type='ic-files-wenjianjia'></Icon>
                </div>
                <div className='folder-content-call-num'>
                    <h3>{name}</h3>
                    <span>
                        {callCount || 0}
                        {locales.components.gong.filesOpen.folderItem.calls}
                    </span>
                    <p>
                        {locales.components.gong.filesOpen.folderItem.createdBy}
                        {createUserName}
                    </p>
                    <p>
                        {locales.components.gong.filesOpen.folderItem.createdOn}
                        {createTime || '--'}
                    </p>
                </div>
                {mouseOver && (
                    <div className='folder-actions'>
                        <Space>
                            <div onClick={handlerCollection}>
                                {collectFlag ? (
                                    <Icon
                                        style={{ fontSize: 20, color: '#FF7A52' }}
                                        type='StarFilled'
                                        antdIcon={true}
                                    ></Icon>
                                ) : (
                                    <Icon
                                        style={{ fontSize: 20, color: '#1A6EFF' }}
                                        type='StarOutlined'
                                        antdIcon={true}
                                    ></Icon>
                                )}
                            </div>
                            {!isCollection ? (
                                <Dropdown overlay={props.menu}>
                                    <div onClick={handlerActionsMenu}>
                                        <Icon
                                            style={{ fontSize: 20, color: '#1A6EFF' }}
                                            type='EllipsisOutlined'
                                            antdIcon={true}
                                        />
                                    </div>
                                </Dropdown>
                            ) : null}
                        </Space>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FolderItem;
