import React, { FC, useState } from 'react';
import { Modal, Select, Space } from 'udesk-ui';
import Selector from './components/Selector';
import { useLatitudeOptions } from './hooks';
import UdeskLocales from 'UdeskLocales';

type LatitudeModalProps = {
    visible: boolean;
    onOk: (v: string) => void;
    onCancel: () => void;
};

const LatitudeModal: FC<LatitudeModalProps> = (props) => {
    const { visible, onOk, onCancel } = props;

    const { LatitudeOptions } = useLatitudeOptions();

    const [curLati, setCurLati] = useState(LatitudeOptions[0].value);
    const [curLatiLabel, setCurLatiLabel] = useState(LatitudeOptions[0].label);
    const [curDataLabel, setCurDataLabel] = useState('');

    const onLatiChange = (v, o) => {
        setCurLati(v);
        setCurLatiLabel(o.label);
        setCurDataLabel('');
    };

    const onInnerCancel = () => {
        setCurLati(LatitudeOptions[0].value);
        setCurLatiLabel(LatitudeOptions[0].label);
        setCurDataLabel('');
        onCancel();
    };

    const onInnerOk = () => {
        let v = curLatiLabel;
        if (curDataLabel) {
            v += curDataLabel;
        }
        onOk(v);
        onInnerCancel();
    };

    return (
        <Modal
            visible={visible}
            onCancel={onInnerCancel}
            onOk={onInnerOk}
            title={
                /* 请选择维度 */ UdeskLocales['current'].pages.coach.dataAnalysis
                    .intelligentAnalysis.components.search.components.latitudeModal.index
                    .pleaseSelectADimension
            }
            destroyOnClose
        >
            <Space size="large">
                <Select
                    options={LatitudeOptions}
                    style={{ width: 200 }}
                    value={curLati}
                    onChange={onLatiChange}
                />

                <Selector curLati={curLati} onChange={(l) => setCurDataLabel(l)} />
            </Space>
        </Modal>
    );
};

export default LatitudeModal;
