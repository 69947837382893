// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetOauthByDomainMultiCompanyLoginQuery,
    GetOauthByDomainMultiCompanyLoginParam,
    BaseResponse登录返回参数,
} from '../../../../types';

/**
 * multiCompanyLogin
 *
 * @export
 * @tags oauth-controller
 * @link [GET] /oauth/{domain}/multiCompany/login
 * @param domain
 */
export function getOauthByDomainMultiCompanyLogin(
    options: ApiOptions<
        GetOauthByDomainMultiCompanyLoginParam,
        GetOauthByDomainMultiCompanyLoginQuery
    > & { segments: GetOauthByDomainMultiCompanyLoginParam } & {
        params: GetOauthByDomainMultiCompanyLoginQuery;
    }
): Promise<BaseResponse登录返回参数> {
    return request<
        never,
        GetOauthByDomainMultiCompanyLoginParam,
        GetOauthByDomainMultiCompanyLoginQuery
    >('/oauth/{domain}/multiCompany/login', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getOauthByDomainMultiCompanyLogin',
    });
}

export const meta = [
    { tags: ['oauth-controller'], path: '/oauth/{domain}/multiCompany/login', method: 'get' },
];
