import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Avatar, Modal, Table, Radio, Space, message } from 'udesk-ui';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import { getUsers } from 'src/api/users';
import './style.scss';
import { GetUsersQuery, ImportUser } from 'src/api/types';
// import { BaseResponseHeadCountResponse, GetUsersQuery } from 'src/api/types';
import { putUsersByIdStatusByStatus } from 'src/api/users/{id}/status/{status}';
import { deleteUsersByIds } from 'src/api/users/{ids}';
// import { getUsersHeadcount } from 'src/api/users/headcount';
import { isGeneralSetting, isSalesSubApp } from 'Udesk/system/subApp';
import { useSdkOptions } from 'src/util/hook';
import ImportModal from 'Component/common/import-modal';
import { postUsersExport } from 'src/api/users/export/index';
import { getSystemModule } from 'Udesk/system/subApp';
import { postUsersImport } from 'src/api/users/import/index';

interface DataNode {
    title: string;
    key: string;
    isLeaf?: boolean;
    children?: DataNode[];
    icon?: any;
    userId?: string;
}

interface FindAllOrigange extends GetUsersQuery {
    organizationId: number;
}

const AdminGongUser = React.memo((props: any) => {
    const ref = useRef<any>({});
    const { table: tableRef } = ref.current;
    const [selectedKey, setSelectedKey] = useState<string>(props.location?.state?.categoryId ?? '');
    const [detailModalInitialValues, setDetailModalInitialValues] = useState<any>({});
    const [detailModalVisible, setDetailModalVisible] = useState(false);
    const [keyWord, setKeyWord] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const expandedKeys = useRef<string[]>([]);
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [detailModalLoading, setDetailModalLoading] = useState(false);
    // const [headcount, setHeadcount] = useState(0);
    // const [companyName, setCompanyName] = useState('');
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });
    const treeProps = useMemo(() => {
        const onSelect = (selectedKeys, { selected, selectedNodes, node, event }) => {
            if (selected) {
                tableRef.clearSelectedRowKeys();
                setSelectedKey(node.key);
                const newPaging = {
                    ...paging,
                    pageNum: 1,
                };
                setPaging(newPaging);
                queryList({ categoryId: parseInt(node.key, 10), ...newPaging });
            }
        };
        const onAdd = (item) => {
            setDetailModalInitialValues({ pid: item.id });
            setDetailModalVisible(true);
        };
        const onEdit = (item) => {
            setDetailModalInitialValues({ ...item });
            setDetailModalVisible(true);
        };
        const onDelete = (item) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `organizations/${item.id}`,
                props.sdkOptions
            );
            Udesk.ajax.del(url, {}).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        UdeskLocales['current'].business.notifyMessage.deleteSuccess
                    );
                    queryTree();
                    // companyMember();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        };
        return {
            treeNodeExtra: [
                {
                    label: UdeskLocales['current'].components.pages.knowledgeBase.list.add,
                    onClick: onAdd,
                    disabled: (item) => item.level > 4 || (item.pid && item.defaultFlag),
                },
                {
                    label: UdeskLocales['current'].labels.edit,
                    disabled: (item) => item.defaultFlag,
                    onClick: onEdit,
                },
                {
                    label: UdeskLocales['current'].components.pages.knowledgeBase.list.delete,
                    danger: true,
                    disabled: (item) => item.defaultFlag,
                    onClick: onDelete,
                },
            ],
            treeData,
            blockNode: true,
            onSelect,
            selectedKeys: [selectedKey],
            showIcon: true,
        };
    }, [treeData, selectedKey, paging]);
    const queryList = ({ pageSize, pageNum, categoryId, keyword = keyWord }) => {
        setLoading(true);
        const params: FindAllOrigange = {
            pageNum,
            pageSize,
            keyword,
            organizationId: parseInt(categoryId, 10),
        };
        // setExportParams(params);
        getUsers({
            params,
        }).then((resp) => {
            setDataSource(resp.data || []);
            setLoading(false);
            setPaging(resp.paging as any);
        });
    };
    const modalProps = useMemo(() => {
        const formConfig = {
            initialValues: detailModalInitialValues,
            fields: [
                {
                    name: 'name',
                    label: UdeskLocales['current'].components.pages.knowledgeBase.list.categoryName,
                    type: 'Input',
                    props: {
                        style: {
                            width: 300,
                        },
                        placeholder: UdeskLocales['current'].labels.pleaseEnter,
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            ],
            footerRender: false as false,
        };
        const onOk = async (values) => {
            setDetailModalLoading(true);
            if (detailModalInitialValues.id) {
                const params = {
                    id: detailModalInitialValues.id,
                    ...values,
                    parentId: detailModalInitialValues.parentId,
                };
                await editOrganizations(params);
            } else {
                if (!detailModalInitialValues.pid) return;
                const params = {
                    ...values,
                    parentId: detailModalInitialValues.pid,
                };
                await addOrganizations(params);
            }
            setDetailModalVisible(false);
            setDetailModalLoading(false);
            queryTree();
        };
        return {
            visible: detailModalVisible,
            loading: detailModalLoading,
            formConfig,
            onCancel: () => setDetailModalVisible(false),
            onOk,
        };
    }, [detailModalVisible, detailModalInitialValues, detailModalLoading]);
    const addOrganizations = (params) => {
        return new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(`organizations`, props.sdkOptions);
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        UdeskLocales['current'].components.pages.knowledgeBase.list.createSuccess
                    );
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    };
    const editOrganizations = (params) => {
        return new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `organizations/${params.id}`,
                props.sdkOptions
            );
            Udesk.ajax.put(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(UdeskLocales['current'].business.info.editSuccess);
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    };
    const queryTree = () => {
        let url = Udesk.business.apiPath.concatApiPath(`organizations`, props.sdkOptions);
        Udesk.ajax.get(url, {}).then(
            (resp) => {
                const newTreeData = getNode(resp.data);
                const getExpandedKeys = (source: any[] = [], level = 0) => {
                    if (level > 2) return [];
                    let ret = source.map((s) => s.key);

                    return [
                        ...ret,
                        ...source.map((s) => getExpandedKeys(s.children, level + 1)).flat(Infinity),
                    ];
                };
                const expKeys = getExpandedKeys(newTreeData);
                expandedKeys.current = expKeys;
                setTreeData(newTreeData);
                if (Array.isArray(resp.data) && resp.data.length) {
                    queryList({
                        categoryId: selectedKey ? parseInt(selectedKey, 10) : newTreeData[0].id,
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                    if (newTreeData[0].id && !selectedKey) {
                        setSelectedKey(`${newTreeData[0].id}`);
                    }
                }
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    // const companyMember = () => {
    //     getUsersHeadcount().then((res:BaseResponseHeadCountResponse) => {
    //         // setHeadcount(res.data?.headcount??0);
    //         // setCompanyName(res.data?.companyName??'');
    //     });
    // };
    useEffect(() => {
        queryTree();
        // companyMember();
    }, []);
    useEffect(() => {
        setColumns([
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.headPortrait,
                dataIndex: 'headPortraitUrl',
                key: 'headPortraitUrl',
                render: (name, item) => (
                    <Avatar src={item.headPortraitUrl || '/static/images/avatar.png'} />
                ),
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.fullName,
                dataIndex: 'realname',
                key: 'realname',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.nickname,
                dataIndex: 'nickname',
                key: 'nickname',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.excellentStaff,
                dataIndex: 'isExample',
                key: 'isExample',
                render: (name, item) => {
                    return item.isExample
                        ? UdeskLocales['current'].pages.gong.userManage.list.yes
                        : UdeskLocales['current'].pages.gong.userManage.list.no;
                },
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.department,
                dataIndex: 'organizationName',
                key: 'organizationName',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.accountNumberemail,
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.telephone,
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.jobNumber,
                dataIndex: 'agentId',
                key: 'agentId',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.role,
                dataIndex: 'role',
                key: 'role',
                render: (text, record) => {
                    if (!record.roleList || record.roleList.length === 0) {
                        return '';
                    }
                    return record.roleList[0].name;
                },
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: UdeskLocales['current'].fields.interactiveRecognition.status,
                renderType: 'switch',
                onSwitchChange: (item, index) => {
                    const { id, status } = item;
                    putUsersByIdStatusByStatus({ segments: { id, status: status ? 0 : 1 } }).then(
                        () => {
                            let newData = [...dataSource];
                            newData[index].status = !newData[index].status;
                            setDataSource(newData);
                        }
                    );
                },
            },
            {
                key: 'actions',
                dataIndex: 'actions',
                title: UdeskLocales['current'].labels.action,
                renderType: 'actions',
                width: 180,
                onView: Udesk.data.init.user
                    ? (item) => {
                          let routeOptions = {
                              history: props.history,
                              routeName: isGeneralSetting()
                                  ? 'generalSettingUserDetail'
                                  : isSalesSubApp()
                                  ? 'adminGongUserDetail'
                                  : 'adminUserView',
                              pathParams: { id: item.uuid },
                          };
                          Udesk.ui.routing.transitionTo(routeOptions);
                      }
                    : undefined,
                onEdit: Udesk.data.init.user
                    ? (item) => {
                          let routeOptions = {
                              history: props.history,
                              routeName: isGeneralSetting()
                                  ? 'generalSettingUserEdit'
                                  : isSalesSubApp()
                                  ? 'adminGongUserEdit'
                                  : 'adminUserEdit',
                              pathParams: { id: item.uuid },
                          };
                          Udesk.ui.routing.transitionTo(routeOptions);
                      }
                    : undefined,
                onDelete: Udesk.data.init.user
                    ? (item) => {
                          deleteUsersByIds({
                              segments: {
                                  ids: item.id,
                              },
                              successMsg:
                                  UdeskLocales['current'].business.notifyMessage.deleteSuccess,
                          }).then((res) => {
                              queryList({
                                  categoryId: parseInt(selectedKey, 10),
                                  keyword: keyWord,
                                  pageNum: 1,
                                  pageSize: paging.pageSize,
                              });
                              // companyMember();
                          });
                      }
                    : undefined,
            },
            {
                name: 'keyword',
                type: 'Input',
                props: {
                    placeholder:
                        UdeskLocales['current'].pages.gong.userManage.list
                            .pleaseEnterANameOrPhoneSearch,
                },
            },
        ]);
    }, [selectedKey, keyWord, dataSource]);
    // const tableHeaderLeftRender = (
    //     <p>{companyName}（{headcount}{UdeskLocales['current'].pages.gong.userManage.list.person}</p>
    // );
    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
            checkable: false,
        };
    }, [columns, dataSource]);
    const paginationProps = useMemo(() => {
        const { pageSize, total } = paging;
        return {
            current: paging.pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                const newPages = {
                    ...paging,
                    pageNum: page,
                    pageSize,
                };
                queryList({ ...newPages, categoryId: parseInt(selectedKey, 10) });
                setPaging(newPages);
            },
        };
    }, [paging.pageNum, paging.pageSize, paging.total, selectedKey]);
    const handlerEditUser = () => {
        let routeOptions = {
            history: props.history,
            routeName: isGeneralSetting()
                ? 'generalSettingUserEdit'
                : isSalesSubApp()
                ? 'adminGongUserEdit'
                : 'adminUserNew',
            pathParams: { id: -1 },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    // const [exportParams, setExportParams] = useState<any>();
    const onExport = (v) => {
        postUsersExport({ systemModule: getSystemModule() });
    };

    return (
        <Page
            pageBodyClassName="page-user-manage-center"
            title={UdeskLocales['current'].menu.admin.user.text}
            padding={true}
        >
            <CommonTablePage
                loading={loading}
                // tableHeaderLeftRender={companyName ? tableHeaderLeftRender : null}
                ref={ref}
                mainButton={
                    <Button onClick={handlerEditUser}>
                        {UdeskLocales['current'].pages.gong.userManage.list.AddEmployee}
                    </Button>
                }
                onReset={(params) => {
                    setKeyWord('');
                    queryList({
                        ...params,
                        keyword: '',
                        categoryId: parseInt(selectedKey, 10),
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                }}
                onSearch={(params) => {
                    setKeyWord(params.keyword ?? '');
                    queryList({
                        ...params,
                        categoryId: parseInt(selectedKey, 10),
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                }}
                showExport
                exportBtn={{ onExport }}
                tableHeaderRightRender={
                    <ImportButton
                        successCallback={() => {
                            setKeyWord('');
                            queryList({
                                keyword: '',
                                categoryId: parseInt(selectedKey, 10),
                                pageNum: 1,
                                pageSize: paging.pageSize,
                            });
                        }}
                    />
                }
            >
                <CommonTablePage.Tree
                    {...treeProps}
                    defaultExpandedKeys={expandedKeys.current}
                    treeKey="knowledgeGroupTree"
                ></CommonTablePage.Tree>
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
                <CommonTablePage.Modal {...modalProps}></CommonTablePage.Modal>
            </CommonTablePage>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongUser {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

const getNode = (arr = []) => {
    if (!arr || !arr.length) return [];
    const sortedArr = arr
        .map((i: any) => ({
            ...i,
            title: i.name,
            key: i.id,
            parentId: i.parentId * 1,
        }))
        .sort((a, b) => a.parentId - b.parentId);
    let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
    let nodeMap = {};
    sortedArr.forEach((item) => {
        nodeMap[item.id] = item;
        if (nodeMap[item.parentId]) {
            if (Array.isArray(nodeMap[item.parentId].children)) {
                nodeMap[item.parentId].children.push(item);
            } else {
                nodeMap[item.parentId].children = [item];
            }
        }
    });
    return [...root];
};

const RepeatActionType = {
    Override: 1,
    Skip: 2,
};

const ImportButton = React.memo<{
    disabled?: boolean;
    successCallback: Function;
}>((props) => {
    const sdkOptions = useSdkOptions();

    const downloadTemplate = () => {
        let templateLocalUrl = `/import/coach/employee-import-template.xlsx`;
        let templateUrl =
            Udesk.business.apiPath.getStaticBasePath(
                sdkOptions.props.domain,
                sdkOptions.props.pathPrefix
            ) + templateLocalUrl;

        (window as any).location = templateUrl;
    };

    const [uploadData, setUploadData] = useState<any>();

    const {
        handleOnCancel,
        handleOnOk,
        isUploadVisible,
        uploadFile,
        fileRef,
        file,
        clearFile,
        showImportModal,
        uploadLoading,
    } = ImportModal.useImportModal({
        isShowImportSuccessNotification: false,
        isShowImportSuccessMessage: false,
        accept: ['xls', 'xlsx'],
        request({ uploadData }) {
            setUploadData(uploadData);
            return postUsersImport({
                keyName: uploadData.key,
                fileName: uploadData.name,
                systemModule: getSystemModule(),
            }).then((res) => {
                if (res.data?.importStatus === 3) {
                    setRepeatVisible(true);
                    setDataSource(
                        (res.data.validImportUserList || []).map((u) => ({
                            ...u,
                            repeatAction: RepeatActionType.Skip,
                        }))
                    );
                } else {
                    message.success(
                        /* 导入成功 */ UdeskLocales['current'].pages.gong.userManage.list
                            .importSuccessful
                    );
                    props.successCallback();
                }
            });
        },
        formatParams: useCallback((file, uploadData) => {
            return {
                file,
                uploadData,
            };
        }, []),
    });

    const [repeatVisible, setRepeatVisible] = useState(false);

    const [dataSource, setDataSource] = useState<ImportUser[]>([]);

    const onActionChange = (rowIndex, v) => {
        setDataSource((data) =>
            data.map((d, idx) => {
                if (idx === rowIndex) {
                    return {
                        ...d,
                        repeatAction: v,
                    };
                } else {
                    return d;
                }
            })
        );
    };

    const columns = useMemo(() => {
        return [
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.fullName,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: /* 账号 */ UdeskLocales['current'].pages.gong.userManage.list.account,
                dataIndex: 'emailAccount',
                key: 'emailAccount',
            },
            {
                title: /* 密码 */ UdeskLocales['current'].pages.gong.userManage.list.password,
                dataIndex: 'password',
                key: 'password',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.role,
                dataIndex: 'role',
                key: 'role',
            },
            {
                title: UdeskLocales['current'].pages.gong.userManage.list.department,
                dataIndex: 'organization',
                key: 'organization',
            },
            {
                key: 'repeatAction',
                dataIndex: 'repeatAction',
                title: UdeskLocales['current'].labels.action,
                width: 180,
                render: (text, record, index) => {
                    return (
                        <Radio.Group
                            value={text}
                            onChange={(v) => onActionChange(index, v.target.value)}
                            options={[
                                {
                                    label: /* 覆盖 */ UdeskLocales['current'].pages.gong.userManage
                                        .list.cover,
                                    value: RepeatActionType.Override,
                                },
                                {
                                    label: /* 跳过 */ UdeskLocales['current'].pages.gong.userManage
                                        .list.skip,
                                    value: RepeatActionType.Skip,
                                },
                            ]}
                        />
                    );
                },
            },
        ];
    }, []);

    const onAllOverride = () => {
        setDataSource((data) =>
            data.map((d) => ({ ...d, repeatAction: RepeatActionType.Override }))
        );
    };

    const onAllJump = () => {
        setDataSource((data) => data.map((d) => ({ ...d, repeatAction: RepeatActionType.Skip })));
    };

    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        setLoading(true);
        postUsersImport({
            keyName: uploadData.key,
            fileName: uploadData.name,
            systemModule: getSystemModule(),
            importUserList: dataSource,
        })
            .then(() => {
                message.success(
                    /* 导入成功 */ UdeskLocales['current'].pages.gong.userManage.list
                        .importSuccessful
                );
                props.successCallback();
            })
            .finally(() => {
                setLoading(false);
                setRepeatVisible(false);
            });
    };

    return (
        <>
            <Button disabled={props.disabled} onClick={showImportModal}>
                {/* 导入 */}
                {UdeskLocales['current'].pages.gong.userManage.list.import}
            </Button>

            <ImportModal
                onCancel={() => {
                    clearFile();
                    handleOnCancel();
                }}
                onOk={() => handleOnOk().then(clearFile)}
                visible={isUploadVisible}
                fileRef={fileRef}
                uploadFile={uploadFile}
                title={
                    /* 导入员工 */ UdeskLocales['current'].pages.gong.userManage.list
                        .importEmployees
                }
                file={file}
                okButtonProps={{
                    loading: uploadLoading,
                    disabled: uploadLoading,
                }}
                cancelButtonProps={{ disabled: uploadLoading }}
            >
                <div className="form-group">
                    <div className="form-group">
                        {UdeskLocales['current'].labels.numOne}
                        {UdeskLocales['current'].labels.colon}&nbsp;&nbsp;
                        {UdeskLocales['current'].labels.click}&nbsp;&nbsp;
                        <span className="upload-file-template" onClick={downloadTemplate}>
                            {UdeskLocales['current'].labels.downloadTemplate}
                        </span>
                        &nbsp;&nbsp;
                        {UdeskLocales['current'].labels.fillInTheForm}
                    </div>
                    <div className="form-group">
                        {UdeskLocales['current'].labels.numTwo}
                        {UdeskLocales['current'].labels.colon}&nbsp;&nbsp;
                        {
                            /* 上传Excel完成对话导入 */ UdeskLocales['current'].pages.coach
                                .courseManagement.flow.index.uploadExcelToCompleteDialogueImport
                        }
                    </div>
                </div>
            </ImportModal>

            <Modal
                title={
                    /* 导入提示 */ UdeskLocales['current'].pages.gong.userManage.list.importPrompt
                }
                visible={repeatVisible}
                width={800}
                closable={false}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        disabled={loading}
                        onClick={handleOk}
                    >
                        {/* 确定 */}
                        {UdeskLocales['current'].pages.gong.userManage.list.confirm}
                    </Button>,
                ]}
            >
                <div
                    style={{
                        marginBottom: 12,
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div>{`${dataSource.length || 0}${
                        /* 条数据存在账号或姓名重复 */ UdeskLocales['current'].pages.gong.userManage
                            .list.theDataContainsDuplicateAccountsOrNames
                    }`}</div>
                    <div>
                        <Space>
                            <Button type="primary" onClick={onAllOverride}>
                                {/* 全部覆盖 */}
                                {UdeskLocales['current'].pages.gong.userManage.list.allCoverage}
                            </Button>
                            <Button type="primary" onClick={onAllJump}>
                                {/* 全部跳过 */}
                                {UdeskLocales['current'].pages.gong.userManage.list.skipAll}
                            </Button>
                        </Space>
                    </div>
                </div>
                <Table
                    columns={columns}
                    scroll={{ y: 300 }}
                    dataSource={dataSource}
                    pagination={false}
                />
            </Modal>
        </>
    );
});
