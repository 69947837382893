import HasLegendBuilderClass from './has-legend';
import helpers from '../helpers';
import common from '../../../../udesk/index';

class RectangularCoordinateSystemBuilderClass extends HasLegendBuilderClass {
    getTooltipOptions(optionsOwner) {
        let {
            tooltipAxisPointerType
        } = optionsOwner.props;
        let tooltip = super.getTooltipOptions(optionsOwner);
        tooltip.trigger = "axis";
        tooltip.axisPointer = {};

        if (tooltipAxisPointerType) {
            tooltip.axisPointer.type = tooltipAxisPointerType;
        }

        return tooltip;
    }

    getYAxisOptions(optionsOwner) {
        let {
            formatYLabel,
            showYAxis,
            yTitle,
            yNameLocation
        } = optionsOwner.props;
        let yAxis = {};
        yAxis.show = showYAxis;
        yAxis.nameLocation = yNameLocation;
        if (yTitle) {
            yAxis.name = yTitle;
        }
        yAxis.axisLabel = {};
        let axisLabel = {};
        if (typeof formatYLabel !== "function") {
            formatYLabel = defaultFormatYLabel;
        }
        axisLabel.formatter = (value, index) => formatYLabel(value, index, this);
        yAxis.axisLabel = Object.assign(yAxis.axisLabel, axisLabel);

        return yAxis;
    }

    _formatYLabelValue(value, index) {
        return value;
    }
    //针对实时报表
    convertXAxis(records, optionsOwner) {
        let {
            formatXLabel,
            autoTruncateXLabelConfig: config,
            xTitle,
            showXAxis,
            xNameLocation
        } = optionsOwner.props;

        let xAxis = {};

        xAxis.show = showXAxis;
        xAxis.nameLocation = xNameLocation;
        if (xTitle) {
            xAxis.name = xTitle;
        }
        if (records && records.length > 0) {
            var data = records.map(item => (item.xLabel ? item.xLabel : ''));
            xAxis.data = data;
        }
        xAxis.axisLabel = {
            rotate: 0,
            interval: "auto", // interval为0,全部显示;auto时自动隐藏显示不下的
        };

        let axisLabel = {};
        if (typeof formatXLabel !== "function") {
            formatXLabel = defaultFormatXLabel;
        }
        axisLabel.formatter = (value, index) => formatXLabel(value, index, config, this);
        xAxis.axisLabel = $.extend(xAxis.axisLabel, axisLabel);
        return xAxis;
    }
    getXAxisOptions(optionsOwner) {
        let {
            records,
        } = optionsOwner.props;
        let xAxis = this.convertXAxis(records, optionsOwner);

        return xAxis;
    }

    _formatXLabelValue(value, index, config) {
        return helpers.truncate(value, config);
    }

    getDataZoom(optionsOwner) {
        let {
            records,
            dataZoomMinLimit,
            type
        } = optionsOwner.props;
        if (records.length >= dataZoomMinLimit) {
            let dataZoom = [{
                type: 'inside',
                show: true,
                end: 100,
                start: 0
            }
                // , {
                //     type: 'slider',
                //     show: true,
                //     handleSize: "20%",
                //     end: 100,
                //     start: 0
                // }
            ];

            if (type === common.enums.echartTypes.bar.key || type === common.enums.echartTypes.stackBar.key) {
                dataZoom.forEach((item) => {
                    item.orient = "vertical";
                });
            } else {
                dataZoom.forEach((item) => {
                    item.orient = "horizontal";
                });
            }
            return dataZoom;
        }
    }
    getGridOptions(optionsOwner) {

        let {
            gridTop,
            gridLeft,
            gridRight,
            gridBottom,
            gridContainLabel
        } = optionsOwner.props;

        let grid = {};

        grid.top = gridTop;
        grid.left = gridLeft;
        grid.right = gridRight;
        grid.bottom = gridBottom;
        grid.containLabel = gridContainLabel;

        return grid;

        // return {
        //     bottom: 20,
        //     left: 100,
        //     right: 100
        // }
    }
    _getChartType() {
        return "bar";
    }

    _getLabelPosition() {
        return "top";
    }

    _formatSeriesLabelValue(params, optionsOwner) {
        let {
            showSeriesLabels,
            seriesLabelContentMode,
            enableSeriesLabelFallback
        } = optionsOwner.props;

        if (showSeriesLabels) {
            if (seriesLabelContentMode === "recordValue") {
                if (params.value === 0) {
                    if (enableSeriesLabelFallback) {
                        return params.record.xLabel;
                    } else {
                        return "";
                    }
                }
                return params.value;
            } else if (seriesLabelContentMode === "recordXLabel") {
                return params.record.xLabel;
            }
        }
    }
}
export default RectangularCoordinateSystemBuilderClass;



function defaultFormatXLabel(value, index, config, context) {
    let formatXLabelValue = context.formatXLabelValue;
    if (typeof formatXLabelValue !== "function") {
        formatXLabelValue = context._formatXLabelValue;
    }
    return formatXLabelValue(value, index, config);
}

function defaultFormatYLabel(value, index, context) {
    let formatYLabelValue = context.formatYLabelValue;
    if (typeof formatYLabelValue !== "function") {
        formatYLabelValue = context._formatYLabelValue;
    }
    return formatYLabelValue(value, index);
}
