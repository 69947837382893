import React from 'react';
import DataSourceBaseClass from './data-source-base-class';
import ReactSelectComponent from 'react-select';
import Udesk from '../../udesk/index';

class ReactMultiSelect extends DataSourceBaseClass {
    actions = {
        _validate() {
            return true;
        },

        _onInputValueChanged(options) {
            if (options instanceof Array) {
                let { mergedAttributes } = this.privates.computes;
                let { dataSourceValueFeild, DefaultValueField } = this.props;
                let valueField = DefaultValueField;
                if (mergedAttributes[dataSourceValueFeild] != null) {
                    valueField = mergedAttributes[dataSourceValueFeild];
                }
                let internalValue = [];
                if (options.length > 0) {
                    internalValue = options.map(option => option[valueField]);
                }
                this.actions._onChanged(internalValue);
            }
        }
    };

    static get emberName() {
        return '';
    }

    render() {
        let {
            privates: { computes }
        } = this;

        let { internalValue, mergedAttributes } = computes;
        let {
            disabled,
            dataSource,
            dataSourceValueFeild,
            dataSourceNameField,
            DefaultValueField,
            DefaultNameField
        } = this.props;
        let valueField = DefaultValueField;
        if (mergedAttributes[dataSourceValueFeild] != null) {
            valueField = mergedAttributes[dataSourceValueFeild];
        }
        let nameField = DefaultNameField;
        if (mergedAttributes[dataSourceNameField] != null) {
            nameField = mergedAttributes[dataSourceNameField];
        }

        let getOptionLabel = option => {
            return option[nameField];
        };

        let getOptionValue = option => {
            return option[valueField];
        };
        if (!(dataSource instanceof Array)) {
            dataSource = [];
        }

        if (internalValue == null || internalValue === '') {
            internalValue = [];
        }

        let targetOptions = [];
        internalValue.forEach(item => {
            let targetOption = dataSource.find(option => {
                if (typeof option !== 'object') {
                    return false;
                }
                return option[valueField] === item;
            });

            if (targetOption != null) {
                targetOptions.push(targetOption);
            }
        });

        return (
            <ReactSelectComponent
                styles={mergedAttributes.styles}
                value={targetOptions}
                containerClassNames={mergedAttributes.classNames}
                isSearchable={mergedAttributes.isSearchable}
                isClearable={mergedAttributes.isClearable}
                disabled={disabled}
                options={dataSource}
                placeholder={mergedAttributes.placeholder}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={this.actions._onInputValueChanged}
                isMulti
            />
        );
    }
}

export default Udesk.react.udeskify(ReactMultiSelect);
