// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetReviewIntelligentPartnerNodePromptConfigExampleDialoguesByFlowIdParam,
    BaseResponsestring,
} from '../../../../../../types';

/**
 * 提示语节点生成对话示例
 *
 * @export
 * @tags 智能陪练-AI对话提示语节点配置
 * @link [GET] /review/intelligentPartner/node/promptConfig/exampleDialogues/{flowId}
 * @param flowId
 */
export function getReviewIntelligentPartnerNodePromptConfigExampleDialoguesByFlowId(
    options: ApiOptions<
        GetReviewIntelligentPartnerNodePromptConfigExampleDialoguesByFlowIdParam,
        never
    > & { segments: GetReviewIntelligentPartnerNodePromptConfigExampleDialoguesByFlowIdParam }
): Promise<BaseResponsestring> {
    return request<
        never,
        GetReviewIntelligentPartnerNodePromptConfigExampleDialoguesByFlowIdParam,
        never
    >('/review/intelligentPartner/node/promptConfig/exampleDialogues/{flowId}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewIntelligentPartnerNodePromptConfigExampleDialoguesByFlowId',
    });
}

export const meta = [
    {
        tags: ['智能陪练-AI对话提示语节点配置'],
        path: '/review/intelligentPartner/node/promptConfig/exampleDialogues/{flowId}',
        method: 'get',
    },
];
