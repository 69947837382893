import { useEffect, useState } from 'react';
import { getReviewIntelligentPartnerCategory } from 'src/api/review/intelligentPartner/category';

export const useCategorySelect = () => {
    const [categories, setCategory] = useState<any[]>([]);

    useEffect(() => {
        getReviewIntelligentPartnerCategory().then((resp) => {
            setCategory(
                resp.data?.map((item) => ({
                    id: item.id,
                    pId: item.parentId,
                    value: item.id,
                    title: item.name,
                })) ?? []
            );
        });
    }, []);

    return {
        treeData: categories,
        treeDataSimpleMode: { rootPId: 0 },
        treeDefaultExpandAll: true,
    };
};
