import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
class RecordDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {
        selectedDatum: {}
    };
    //#endregion

    state = {

    };
    privates = {
        detail: null,
        smartTags: [],
        templates: [],
        relatedDatas: [],
        showEmotionAnalysis: false,
        semanticMarkShow: false,
        semanticContent: "",
        waveAudioInstance: null,
        storages: {
            categoryIdList: [],
            pressId: null,
            callTime: null
        },
        relatedLoading: false,
        treeDataList: {},
        labelActive: null,
        progressState: 0,
        progressCount: 0,
        tagId: null,
        treeValue: null,
        idState: null,
        labelLevel: null,
        labelLevelModal: null,
        labelParent: {},
        labelParentModal: {},
        hitData: {},
        hitDataObj: {},
        treeDataRef: {},
        labelRef: React.createRef(),
        treeDataRefObj: {},
        labelRefObj: React.createRef()
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: "sessionStorage",
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [/^\/site\/training-center\/classified-training\/detail\/\d+/i],
        },
    };
    static computes = {

    };
    actions = {
        sendCurrentTimeRange(item) {
            let {
                waveAudioInstance
            } = this.privates;
            if (waveAudioInstance) {
                waveAudioInstance.actions.playSpecificTimePeriod(item.data.fromSec, item.data.endSec);
            }
        },
        changeShowEmotionAnalysis() {
            let {
                showEmotionAnalysis
            } = this.privates;
            this.privates.showEmotionAnalysis = !showEmotionAnalysis;
            this.actions.update();
        },
        transitionToTemplate(template) {
            this.trigger("onTransitionToTemplate", template);
        },
        onSeriesClicked() {
            this.trigger("onSeriesClicked");
        },
        markSemantic(content) {
            this.privates.semanticMarkShow = true;
            this.privates.semanticContent = content;
            this.actions.update();
        },
        markSemanticClose() {
            this.privates.semanticMarkShow = false;
            this.actions.update();
        },
        parentLoadMore(value) {
            let { relatedDatas, selectedDatum } = this.privates;
            let {
                sdkOptions
            } = this.props;
            let relatedData = Object.assign({ id: selectedDatum.dataId, pageSize: 10 }, value);
            let relatedDataUrl = Udesk.business.apiPath.concatApiPath(`data/related-data`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(relatedDataUrl, relatedData).then(
                    (resp) => {
                        resolve(resp);
                        let list = [
                            ...relatedDatas,
                            ...resp.data
                        ];
                        this.privates.relatedDatas = list;
                        this.privates.storages.relatedDatasPaging = resp.paging;
                        this.privates.relatedLoading = false;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        this.privates.relatedLoading = false;
                        reject(reason);
                    }
                );
            });
        },
        backToIndexPage() {
            let routeOptions = {
                history: this.props.history,
                routeName: "classifiedTrainingIndex",
                state: {
                    categoryId: parseInt(this.privates.categoryId, 10),
                    time: this.privates.storages.callTime
                }

            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        getTagTreeInit(categoryId, type, tagId, flag, typeFlag) {
            let {
                sdkOptions
            } = this.props;
            let data = {
                categoryId,
                treeType: type
            };
            if (tagId) {
                data.tagId = tagId;
            }
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/tree`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        resolve(resp.data);
                        if (!flag) {
                            if (type === 1) {
                                if (resp.data && resp.data.length > 0) {
                                    let level = resp.data[0].tagLevel;
                                    if (!_isEmpty(this.privates.treeDataList)) {
                                        let obj = _find(this.privates.treeDataList, (item, key) => key === level);
                                        if (obj) {
                                            this.privates.treeDataList[level] = resp.data;
                                        } else {
                                            this.privates.treeDataList = {
                                                ...this.privates.treeDataList,
                                                [level]: resp.data
                                            };
                                        }
                                        let data = {};
                                        for (let key in this.privates.treeDataList) {
                                            if (key <= level) {
                                                data[key] = this.privates.treeDataList[key];
                                            }
                                        }
                                        this.privates.treeDataList = data;
                                        for (let key in this.privates.treeDataList) {
                                            this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                        }

                                    } else {
                                        this.privates.treeDataList[level] = resp.data.filter(item => !item.nonCategory);
                                        for (let key in this.privates.treeDataList) {
                                            this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                        }
                                        this.privates.treeNonCategory = resp.data.find(item => item.nonCategory === 1);
                                    }
                                    this.actions.update();
                                } else {
                                    if (this.privates.labelLevel) {
                                        this.privates.treeDataList = {
                                            ...this.privates.treeDataList,
                                            [this.privates.labelLevel + 1]: []
                                        };
                                        let data = {};
                                        for (let key in this.privates.treeDataList) {
                                            if (key <= (this.privates.labelLevel + 1)) {
                                                data[key] = this.privates.treeDataList[key];
                                            }
                                        }
                                        this.privates.treeDataList = data;
                                        for (let key in this.privates.treeDataList) {
                                            this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                        }
                                    }
                                    this.actions.update();
                                }
                            } else if (type === 2) {
                                if (typeFlag) {
                                    this.privates.treeDataList = resp.data;
                                    for (let key in this.privates.treeDataList) {
                                        if (parseInt(key, 10) === 1) {
                                            this.privates.treeNonCategory = this.privates.treeDataList[key].find(item => item.nonCategory === 1);
                                            this.privates.treeDataList[key] = this.privates.treeDataList[key].filter(item => !item.nonCategory);

                                            break;
                                        }
                                    }
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                    }
                                    this.actions.treeMap(tagId, this.privates.treeDataList, -1);
                                    this.actions.hitMap(tagId, this.privates.treeDataList, this.privates.hitData, -1);
                                    this.privates.tagId = tagId;
                                    this.privates.labelActive = tagId;
                                    this.actions.update();
                                } else {
                                    this.privates.treeDataListObj = resp.data;
                                    for (let key in this.privates.treeDataListObj) {
                                        this.privates.treeDataRefObj[`treeObj-${key}`] = React.createRef();
                                    }
                                    this.actions.treeMap(tagId, this.privates.treeDataListObj, -1);
                                    this.actions.hitMap(tagId, this.privates.treeDataListObj, this.privates.hitDataObj, -1);
                                    this.actions.update();
                                }

                            }
                        } else {
                            if (resp.data && resp.data.length > 0) {
                                let level = resp.data[0].tagLevel;
                                if (!_isEmpty(this.privates.treeDataListObj)) {
                                    let obj = _find(this.privates.treeDataListObj, (item, key) => key === level);
                                    if (obj) {
                                        this.privates.treeDataListObj[level] = resp.data;
                                    } else {
                                        this.privates.treeDataListObj = {
                                            ...this.privates.treeDataListObj,
                                            [level]: resp.data
                                        };
                                    }
                                    let data = {};
                                    for (let key in this.privates.treeDataListObj) {
                                        if (key <= level) {
                                            data[key] = this.privates.treeDataListObj[key];
                                        }
                                    }
                                    this.privates.treeDataListObj = data;
                                    for (let key in this.privates.treeDataListObj) {
                                        this.privates.treeDataRefObj[`treeObj-${key}`] = React.createRef();
                                    }
                                } else {
                                    this.privates.treeDataListObj[level] = resp.data;

                                }
                                this.actions.update();
                            } else {
                                if (this.privates.labelLevelModal) {
                                    this.privates.treeDataListObj = {
                                        ...this.privates.treeDataListObj,
                                        [this.privates.labelLevelModal + 1]: []
                                    };
                                    let data = {};
                                    for (let key in this.privates.treeDataListObj) {
                                        if (key <= (this.privates.labelLevelModal + 1)) {
                                            data[key] = this.privates.treeDataListObj[key];
                                        }
                                    }
                                    this.privates.treeDataListObj = data;
                                    for (let key in this.privates.treeDataListObj) {
                                        this.privates.treeDataRefObj[`treeObj-${key}`] = React.createRef();
                                    }
                                }
                                this.actions.update();
                            }
                        }

                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        treeMap(id, treeDataListObj, level) {
            for (let key in treeDataListObj) {
                if ((parseInt(key, 10) === level) || (level === -1)) {
                    if (treeDataListObj[key] && treeDataListObj[key].length > 0) {
                        let obj = treeDataListObj[key].find(item => item.id === id);
                        if (obj && obj.parentId) {
                            treeDataListObj[key] = treeDataListObj[key].filter(item => item.parentId === obj.parentId);
                            this.actions.treeMap(obj.parentId, treeDataListObj, obj.tagLevel - 1);
                            break;
                        }
                    }
                }

            }
        },
        treeDrag(value, obj, e) {
            var x1 = e.pageX || e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
            if (value.current) {
                let width = value.current.style.width;
                let wid = parseInt(width.split('px')[0], 10);
                obj.current.onmousemove = e => {
                    var x2 = e.pageX || e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
                    if ((x2 >= x1) && (x2 - x1 <= 500)) {
                        value.current.style.width = (x2 - x1) + wid + 'px';
                    } else {
                        if (x2 - x1 < 0) {
                            value.current.style.width = wid - (x1 - x2) + 'px';
                        }
                    }

                };

            }
        },
        fieldUp(obj) {
            obj.current.onmousemove = e => { return; };
        },
        hitMap(id, treeDataListObj, hitList, level) {
            for (let key in treeDataListObj) {
                if ((parseInt(key, 10) === level) || (level === -1)) {
                    if (treeDataListObj[key] && treeDataListObj[key].length > 0) {
                        treeDataListObj[key].forEach(item => {
                            if (item.id === id) {
                                hitList[item.tagLevel] = id;
                                if (item.parentId) {
                                    this.actions.hitMap(item.parentId, treeDataListObj, hitList, item.tagLevel - 1);
                                }
                            }
                        });
                    }
                }
            }
        },
        activesLabel(value, level, leveflag) {
            let { treeDataList } = this.privates;
            this.privates.labelActive = value;
            this.privates.tagId = value;
            this.privates.labelLevel = level;
            this.privates.labelParent[level] = value;
            for (let key in this.privates.labelParent) {
                if (key > level) {
                    this.privates.labelParent[key] = null;
                }
            }
            for (let key in this.privates.hitData) {
                if (key >= level) {
                    this.privates.hitData[key] = null;
                }
            }
            if (leveflag !== 1) {
                this.actions.getTagTreeInit(this.privates.categoryId, 1, value);
            } else {
                for (let key in treeDataList) {
                    if (key > level) {
                        this.privates.treeDataList[key] = null;
                    }
                }
            }
            this.actions.update();
        },
        activesLabelNone(value) {
            this.privates.labelActive = value;
            this.privates.tagId = value;
            this.privates.hitData = {};
            this.privates.hitDataObj = {};
            this.actions.update();
        },
        labelFieldAdd(key, id) {
            this.privates.addLevel = key;
            this.privates.idState = id;
            this.actions.update();
        },
        activesLabelModal(value, level, leveflag) {
            let { treeDataListObj } = this.privates;
            this.privates.labelActiveModal = value;
            this.privates.tagIdModal = value;
            this.privates.labelLevelModal = level;
            this.privates.labelParentModal[level] = value;
            for (let key in this.privates.labelParentModal) {
                if (key > level) {
                    this.privates.labelParentModal[key] = null;
                }
            }
            for (let key in this.privates.hitDataObj) {
                if (key >= level) {
                    this.privates.hitDataObj[key] = null;
                }
            }
            if (leveflag !== 1) {
                this.actions.getTagTreeInit(this.privates.treeValue.categoryId, 1, value, true);
            } else {
                for (let key in treeDataListObj) {
                    if (key > level) {
                        this.privates.treeDataListObj[key] = null;
                    }
                }
            }
            this.actions.update();
        },
        progressChange(percent, successPercent) {
            let { progressState } = this.privates;
            let { categoryIdList } = this.privates.storages;
            return progressState + "/" + categoryIdList.length;
        },
        prevProgress(value) {
            let { categoryId, trainType } = this.privates;
            let { categoryIdList } = this.privates.storages;
            let id = categoryIdList[value].id;
            let routeOptions = {
                history: this.props.history,
                routeName: "classifiedTrainingDetail",
                pathParams: {
                    id: id
                },
                queryParams: {
                    categoryId,
                    trainType
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        nextProgress(value) {
            let { tagId, detail, categoryId, trainType, progressState, progressCount } = this.privates;
            let { categoryIdList, pressId } = this.privates.storages;
            let id = null;
            if (value) {
                id = categoryIdList[value].id;
            }
            let {
                sdkOptions
            } = this.props;
            let data = {
                tagId,
                callId: detail.id,
                categoryId,
                trainType,
                dataSource: detail.inspectDataSource
            };
            if (pressId) {
                if (progressState === progressCount) {
                    if (tagId) {
                        let url = Udesk.business.apiPath.concatApiPath(`smartTagTrainRecordDetails`, sdkOptions);
                        new Promise((resolve, reject) => {
                            Udesk.ajax.post(url, data).then(
                                (resp) => {
                                    resolve(resp);
                                    if (value) {
                                        let detaIndex = null;
                                        let pressIndex = null;
                                        if (pressId) {
                                            categoryIdList.forEach((item, index) => {
                                                if (parseInt(id, 10) === item.id) {
                                                    detaIndex = index;
                                                }
                                                if (parseInt(pressId, 10) === item.id) {
                                                    pressIndex = index;
                                                }
                                            });
                                            if (detaIndex >= pressIndex) {
                                                this.privates.storages.pressId = id;
                                            } else {
                                                this.privates.storages.pressId = pressId;
                                            }
                                        } else {
                                            this.privates.storages.pressId = id;
                                        }
                                        let routeOptions = {
                                            history: this.props.history,
                                            routeName: "classifiedTrainingDetail",
                                            pathParams: {
                                                id: id
                                            },
                                            queryParams: {
                                                categoryId,
                                                trainType
                                            },

                                        };
                                        Udesk.ui.routing.transitionTo(routeOptions);
                                    }

                                },
                                (reason) => {
                                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.recordDetail.getDetailFailed);
                                    reject(reason);
                                }
                            );
                        });
                    } else {
                        Udesk.ui.notify.error(this.locales.fix.pleaseMarkClassification);
                    }
                } else {
                    if (tagId) {
                        let url = Udesk.business.apiPath.concatApiPath(`smartTagTrainRecordDetails`, sdkOptions);
                        new Promise((resolve, reject) => {
                            Udesk.ajax.post(url, data).then(
                                (resp) => {
                                    resolve(resp);
                                    if (value) {
                                        let detaIndex = null;
                                        let pressIndex = null;
                                        if (pressId) {
                                            categoryIdList.forEach((item, index) => {
                                                if (parseInt(id, 10) === item.id) {
                                                    detaIndex = index;
                                                }
                                                if (parseInt(pressId, 10) === item.id) {
                                                    pressIndex = index;
                                                }
                                            });
                                            if (detaIndex >= pressIndex) {
                                                this.privates.storages.pressId = id;
                                            } else {
                                                this.privates.storages.pressId = pressId;
                                            }
                                        } else {
                                            this.privates.storages.pressId = id;
                                        }
                                        let routeOptions = {
                                            history: this.props.history,
                                            routeName: "classifiedTrainingDetail",
                                            pathParams: {
                                                id: id
                                            },
                                            queryParams: {
                                                categoryId,
                                                trainType
                                            },

                                        };
                                        Udesk.ui.routing.transitionTo(routeOptions);
                                    }

                                },
                                (reason) => {
                                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.recordDetail.getDetailFailed);
                                    reject(reason);
                                }
                            );
                        });
                    } else {
                        let routeOptions = {
                            history: this.props.history,
                            routeName: "classifiedTrainingDetail",
                            pathParams: {
                                id: id
                            },
                            queryParams: {
                                categoryId,
                                trainType
                            },

                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    }
                }
            }

        },
        classifiedIndex() {
            let { tagId } = this.privates;
            this.actions.nextProgress();
            if (tagId) {
                let routeOptions = {
                    history: this.props.history,
                    routeName: "classifiedTrainingIndex",
                    state: {
                        categoryId: parseInt(this.privates.categoryId, 10),
                        time: this.privates.storages.callTime
                    }

                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }


        },
        treeListVisible(value) {
            if (value) {
                if (value.tagType === 2) {
                    this.privates.treeValue = value;
                    this.actions.getTagTreeInit(value.categoryId, 2, value.id);
                    this.privates.visible = true;
                    this.actions.update();
                }

            }
        },
        modalCancel() {
            this.privates.visible = false;
            this.actions.update();
        },
        modalOk() {
            let { treeValue, addLevel, treeDataListObj, labelParentModal } = this.privates;
            let {
                sdkOptions
            } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/${treeValue.id}`, sdkOptions);
            let parentIds = null;
            let data = null;
            if (treeDataListObj[addLevel] && treeDataListObj[addLevel].length > 0) {
                if (treeDataListObj[addLevel][0].parentId) {
                    parentIds = treeDataListObj[addLevel][0].parentId;
                }
            } else {
                if (addLevel > 1) {
                    parentIds = labelParentModal[addLevel - 1][0];
                }

            }
            for (let key in treeDataListObj) {
                if (treeDataListObj[key]) {
                    for (let i = 0; i <= treeDataListObj[key].length - 1; i++) {
                        if (treeValue.id === treeDataListObj[key][i].id) {
                            data = treeDataListObj[key][i];
                            break;
                        }
                    }
                }

            }
            if (parentIds) {
                data = { ...data, parentId: parentIds };
            }

            new Promise((resolve, reject) => {
                Udesk.ajax.put(url, data).then(
                    resp => {
                        resolve(resp);
                        this.privates.visible = false;
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onSmartTagsChange(smartTags){
            this.privates.smartTags = smartTags;
            this.actions.update();
        }
    };

    //#region Life Cycle
    // parseProps({ props, prevProps }) {
    //     if (props.match.params.id !== prevProps.match.params.id) {
    //         return {
    //             selectedDatum: {
    //                 dataId: props.match.params.id
    //             }
    //         };
    //     }

    // }
    componentDidMount() {
        let params = Object.assign({}, this.props.match.params, Object.fromEntries(new URLSearchParams(this.props.location.search)));
        this.privates.categoryId = params.categoryId;
        this.privates.trainType = params.trainType;
        this.actions.getTagTreeInit(params.categoryId, 1);
        if (this.props.location.state) {
            this.privates.storages.categoryIdList = this.props.location.state.callList;
            this.privates.storages.callTime = this.props.location.state.time;
        } else {
            if (_isEmpty(this.privates.storages.categoryIdList) && this.props.match.params.id) {
                this.privates.storages.categoryIdList = [{ id: parseInt(this.props.match.params.id, 10) }];
            }
        }
        this.privates.storages.categoryIdList.forEach((item, index) => {
            if (this.privates.storages.pressId) {
                if (parseInt(this.privates.storages.pressId, 10) === item.id) {
                    this.privates.progressState = index;
                }
            } else {
                if (parseInt(this.props.match.params.id, 10) === item.id) {
                    this.privates.progressState = index;
                    this.privates.storages.pressId = this.props.match.params.id;
                }
            }
            if (parseInt(this.props.match.params.id, 10) === item.id) {
                this.privates.progressCount = index;
            }
        });

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.privates.selectedDatum = {
                dataId: this.props.match.params.id
            };

            let params = Object.assign({}, this.props.match.params, Object.fromEntries(new URLSearchParams(this.props.location.search)));
            this.privates.categoryId = params.categoryId;
            this.privates.trainType = params.trainType;
            this.actions.getTagTreeInit(params.categoryId, 1);
            this.privates.treeDataList = {};
            this.privates.hitData = {};
            this.privates.hitDataObj = {};
            this.privates.tagId = null;
            this.privates.labelParent = {};
            this.privates.labelActive = null;
            if (this.props.location.state) {
                this.privates.storages.categoryIdList = this.props.location.state.callList;
                this.privates.storages.callTime = this.props.location.state.time;
            } else {
                if (_isEmpty(this.privates.storages.categoryIdList) && this.props.match.params.id) {
                    this.privates.storages.categoryIdList = [{ id: parseInt(this.props.match.params.id, 10) }];
                }
            }
            this.privates.storages.categoryIdList.forEach((item, index) => {
                if (this.privates.storages.pressId) {
                    if (parseInt(this.privates.storages.pressId, 10) === item.id) {
                        this.privates.progressState = index;
                    }
                } else {
                    if (parseInt(this.props.match.params.id, 10) === item.id) {
                        this.privates.progressState = index;
                    }
                }
                if (parseInt(this.props.match.params.id, 10) === item.id) {
                    this.privates.progressCount = index;
                }
            });
            this.actions.reloadAsyncModel();
        }
    }
    componentWillUnmount() {
        let {
            waveAudioInstance
        } = this.privates;
        if (waveAudioInstance) {
            waveAudioInstance.actions.pause();
            this.privates.waveAudioInstance = null;
        }
    }
    asyncModel(changedParams) {
        let {
            sdkOptions
        } = this.props;
        this.privates.selectedDatum = {
            dataId: this.props.match.params.id
        };
        let {
            selectedDatum
        } = this.privates;
        if (selectedDatum.dataId == null) {
            return;
        }
        let url = Udesk.business.apiPath.concatApiPath(`data/${selectedDatum.dataId}/dataCallDetail`, sdkOptions);
        let detailPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.recordDetail.getDetailFailed);
                    reject(reason);
                }
            );
        });

        let smartTagsUrl = Udesk.business.apiPath.concatApiPath(`callTags/${selectedDatum.dataId}/tagsOfCall`, sdkOptions);
        let smartTagsPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(smartTagsUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.recordDetail.getSmartTagsFailed);
                    reject(reason);
                }
            );
        });
        let templatesUrl = Udesk.business.apiPath.concatApiPath(`inspectionData/${selectedDatum.dataId}/appliedTemplates`, sdkOptions);
        let templatesPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(templatesUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.recordDetail.getTemplatesFailed);
                    reject(reason);
                }
            );
        });
        return ({
            detail: detailPromise,
            smartTags: smartTagsPromise,
            templates: templatesPromise,

        });
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let {
            asyncKey,
            asyncResult
        } = parseOptions;
        if (asyncKey === "detail") {
            if (asyncResult) {
                if (asyncResult.data) {
                    this.privates.detail = asyncResult.data;
                }
                this.actions.update();
            }
        } else if (asyncKey === "smartTags") {
            if (asyncResult) {
                if (asyncResult.data) {
                    this.privates.smartTags = asyncResult.data;
                    let obj = this.privates.smartTags.find(current => (current.tagType === 2) && (current.categoryId === parseInt(this.privates.categoryId, 10)));
                    if (obj) {
                        this.actions.getTagTreeInit(this.privates.categoryId, 2, obj.id, false, true);
                    }

                }
                this.actions.update();
            }
        } else if (asyncKey === "templates") {
            if (asyncResult) {
                if (asyncResult.data) {
                    this.privates.templates = asyncResult.data;
                }
                this.actions.update();
            }
        }
    }
    //#endregion
}


export default RecordDetailComponent;