// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetOperatorLogsFindSeekHelpAnalysisQuery,
    BaseResponseListOperatorLogFoundResponse,
} from '../../types';

/**
 * findSeekHelpAnalysis
 *
 * @export
 * @tags operator-log-controller
 * @link [GET] /operatorLogs/findSeekHelpAnalysis
 */
export function getOperatorLogsFindSeekHelpAnalysis(): Promise<BaseResponseListOperatorLogFoundResponse>;
export function getOperatorLogsFindSeekHelpAnalysis(
    options: ApiOptions<never, GetOperatorLogsFindSeekHelpAnalysisQuery>
): Promise<BaseResponseListOperatorLogFoundResponse>;
export function getOperatorLogsFindSeekHelpAnalysis(
    options?: ApiOptions<never, GetOperatorLogsFindSeekHelpAnalysisQuery>
): Promise<BaseResponseListOperatorLogFoundResponse> {
    return request<never, never, GetOperatorLogsFindSeekHelpAnalysisQuery>(
        '/operatorLogs/findSeekHelpAnalysis',
        { ...options, method: 'get', __$requestCalleeName: 'getOperatorLogsFindSeekHelpAnalysis' }
    );
}

export const meta = [
    {
        tags: ['operator-log-controller'],
        path: '/operatorLogs/findSeekHelpAnalysis',
        method: 'get',
    },
];
