import ColumnBuilderClass from "./column";

class BarOptionsBuilderClass extends ColumnBuilderClass {
    getXAxisOptions(optionsOwner) {
        let {
            records,
            seriesColumns,
            showExtraPercentInLabels
        } = optionsOwner.props;

        let yAxis = super.getYAxisOptions(optionsOwner);
        if (showExtraPercentInLabels) {
            let fieldName = seriesColumns[0]['field'];

            let valueSum = records.map(item => {
                let value = Number(this._getFieldOriginalValue(item, fieldName));
                if (isNaN(value)) {
                    value = 0;
                }
                return value;
            }).reduce((a, b) => a + b);
            yAxis.max = valueSum;
            yAxis.show = false;
        }
        return yAxis;
    }

    getYAxisOptions(optionsOwner) {
        let xAxis = super.getXAxisOptions(optionsOwner);
        let {
            reverseBarSeries
        } = optionsOwner.props;

        if (reverseBarSeries) {
            xAxis.inverse = true;
        }
        return xAxis;
    }

    _getLabelPosition() {
        return "right";
    }

    _formatSeriesLabelValue(newParams, optionsOwner) {

        let {
            seriesColumns,
            records,
            showSeriesLabels,
            showExtraPercentInLabels,
            seriesLabelContentMode,
            enableSeriesLabelFallback
        } = optionsOwner.props;

        if (showSeriesLabels) {
            if (seriesLabelContentMode === "recordValue") {
                if (newParams.value === 0) {
                    if (enableSeriesLabelFallback) {
                        return newParams.record.xLabel;
                    } else {
                        return "";
                    }
                }
                return newParams.value;
            } else if (seriesLabelContentMode === "recordXLabel") {
                return newParams.record.xLabel;
            }
        } else if (showExtraPercentInLabels) {
            if (seriesColumns == null || seriesColumns.length === 0 ||
                records == null || records.length === 0) {
                return newParams.value;
            }

            let fieldName = seriesColumns[0]['field'];
            let value = this._getFieldOriginalValue(newParams.record, fieldName);
            let valueSum = records.map(item => {
                let value = Number(this._getFieldOriginalValue(item, fieldName));
                if (isNaN(value)) {
                    value = 0;
                }
                return value;
            }).reduce((a, b) => a + b);

            let percentage = (0).toFixed(2);

            if (!isNaN(value) && !isNaN(valueSum) && valueSum !== 0 && value > 0) {
                percentage = (value * 100 / valueSum).toFixed(2);
            }
            return `${percentage}%`;
        }
    }
}

export default BarOptionsBuilderClass;


