// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { PutKnowledgeDatasBatchDeleteBody, BaseResponseVoid } from '../../../types';

/**
 * batchDelete
 *
 * @export
 * @tags knowledge-data-controller
 * @link [PUT] /knowledgeDatas/batch/delete
 */
export function putKnowledgeDatasBatchDelete(
    data: PutKnowledgeDatasBatchDeleteBody
): Promise<BaseResponseVoid>;
export function putKnowledgeDatasBatchDelete(
    data: PutKnowledgeDatasBatchDeleteBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putKnowledgeDatasBatchDelete(
    data: PutKnowledgeDatasBatchDeleteBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutKnowledgeDatasBatchDeleteBody, never, never>('/knowledgeDatas/batch/delete', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putKnowledgeDatasBatchDelete',
    });
}

export const meta = [
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas/batch/delete', method: 'put' },
];
