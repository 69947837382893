import xssWhiteList from '../ui/widgets/xss';

function safeHtml(content, whiteList) {
    if (window.filterXSS == null) {
        return content;
    }
    let xssAwayHtml = window.filterXSS(content, {
        whiteList: whiteList || xssWhiteList.whiteLists.default
    });
    return xssAwayHtml;
}

export default {
    safeHtml
};