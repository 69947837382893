import React, { FC, useMemo } from 'react';
import { useOrgTreeData, useCoachUserTreeDataWithPermit } from 'src/util/hook/user';
import { Select, TreeSelect } from 'udesk-ui';
import { TaskSelect } from 'Component/pages/components/coach/task-select';
import { isNumber } from 'src/util/core';
import { LatitudeMap } from '../../hooks';
import { useOptions } from './hooks';

type SelectorProps = {
    curLati: number;
    onChange: (l: string) => void;
};

const Selector: FC<SelectorProps> = (props) => {
    const { curLati, onChange } = props;

    const { ScoreOptions, TaskStatusOptions } = useOptions();

    const { treeData } = useOrgTreeData();

    const { treeData: userTreeData } = useCoachUserTreeDataWithPermit();

    const pureUserTreeData = useMemo(() => {
        return userTreeData.map((d) => {
            if (isNumber(d.id)) {
                return {
                    ...d,
                    selectable: false,
                };
            } else {
                return d;
            }
        });
    }, [userTreeData]);

    return (
        <>
            {curLati === LatitudeMap.Task ? (
                <TaskSelect
                    key="Task"
                    optionFilterProp="label"
                    showSearch={true}
                    style={{
                        width: 200,
                    }}
                    onChange={(v, o) => {
                        onChange(`[${(o as any).label}]`);
                    }}
                />
            ) : curLati === LatitudeMap.Department ? (
                <TreeSelect
                    key="Department"
                    style={{ width: 200 }}
                    treeData={treeData}
                    onChange={(v, l) => {
                        onChange(`[${l}]`);
                    }}
                    treeNodeFilterProp="title"
                    treeDataSimpleMode={true}
                    showSearch={true}
                    treeDefaultExpandAll={true}
                />
            ) : curLati === LatitudeMap.Student ? (
                <TreeSelect
                    key="Student"
                    style={{ width: 200 }}
                    treeData={pureUserTreeData}
                    onChange={(v, l) => {
                        onChange(`[${l}]`);
                    }}
                    treeNodeFilterProp="title"
                    treeDataSimpleMode={true}
                    showSearch={true}
                    treeDefaultExpandAll={true}
                />
            ) : curLati === LatitudeMap.Score ? (
                <Select
                    key="Score"
                    options={ScoreOptions}
                    optionFilterProp="label"
                    showSearch={true}
                    style={{
                        width: 200,
                    }}
                    onChange={(v, o) => {
                        onChange(`[${(o as any).label}]`);
                    }}
                />
            ) : curLati === LatitudeMap.TaskStatus ? (
                <Select
                    key="TaskStatus"
                    options={TaskStatusOptions}
                    optionFilterProp="label"
                    showSearch={true}
                    style={{
                        width: 200,
                    }}
                    onChange={(v, o) => {
                        onChange(`[${(o as any).label}]`);
                    }}
                />
            ) : null}
        </>
    );
};

export default Selector;
