// @ts-nocheck

import { request } from '../../../../../../../../context';
import type { ApiOptions } from '../../../../../../../../context';
import type {
    PutIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormIdParam,
    BaseResponseVoid,
    DeleteIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormIdParam,
} from '../../../../../../../../types';

/**
 * 添加图表到报表
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [PUT] /intelligentPartner/ai/analysis/{historyId}/query/history/form/{formId}
 * @param historyId
 * @param formId
 */
export function putIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormId(
    options: ApiOptions<
        PutIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormIdParam,
        never
    > & { segments: PutIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormIdParam }
): Promise<BaseResponseVoid> {
    return request<
        never,
        PutIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormIdParam,
        never
    >('/intelligentPartner/ai/analysis/{historyId}/query/history/form/{formId}', {
        ...options,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormId',
    });
}

/**
 * 从报表删除图表
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [DELETE] /intelligentPartner/ai/analysis/{historyId}/query/history/form/{formId}
 * @param historyId
 * @param formId
 */
export function deleteIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormId(
    options: ApiOptions<
        DeleteIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormIdParam,
        never
    > & { segments: DeleteIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormIdParam }
): Promise<BaseResponseVoid> {
    return request<
        never,
        DeleteIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormIdParam,
        never
    >('/intelligentPartner/ai/analysis/{historyId}/query/history/form/{formId}', {
        ...options,
        method: 'delete',
        __$requestCalleeName:
            'deleteIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormId',
    });
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/{historyId}/query/history/form/{formId}',
        method: 'put',
    },
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/{historyId}/query/history/form/{formId}',
        method: 'delete',
    },
];
