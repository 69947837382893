import React, { useEffect, useState /*{ , useMemo, useEffect, useRef } */ } from 'react';
// import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
// import { Button, Space, Popconfirm } from 'udesk-ui';
// import CommentReply from 'Component/common/gong/comment-reply';
import CommentItem from '../comment-dispaly-item';
import type { CommentItemProps } from '../comment-dispaly-item';

import './style.scss';
import { getReplayCommentsFindAllBySourceCommentIdById } from 'src/api/replayComments/findAllBySourceCommentId/{id}';
import { CommentReplyProps } from '../comment-reply';

export interface CommentComponentProps {
    comments?: CommentItemProps[];
    userList?: any[]; //可以被圈的人的列表
    topicsList?: any[]; //话题列表
    hiddenReplayAvatar?: boolean; //是否隐藏点击回复时弹出的回复组件里的头像
    onReply?: CommentReplyProps['onPublish']; //回复评论
    onReplySuccess?: CommentReplyProps['onPublishSuccess']; //回复评论成功时的回调
    onDelete?: (item: any) => Promise<any>; //回复/评论删除时的回调
    onReplyDeleteSuccess?: CommentReplyProps['onPublishSuccess']; //回复删除成功时的回调
    createTimePlacement?: CommentItemProps['createTimePlacement'];
    onCommentTimeClick?: CommentItemProps['onCommentTimeClick'];
}

const CommentComponent = React.memo((props: CommentComponentProps) => {
    // const locales = Locales['current'];
    const {
        comments: commentsSafe = [],
        userList,
        topicsList,
        hiddenReplayAvatar = false,
        onReply,
        onReplySuccess,
        onDelete: onDeleteProps,
        onReplyDeleteSuccess,
        createTimePlacement,
        onCommentTimeClick,
    } = props;
    
    const [comments, setComments] = useState(commentsSafe);
    // const comments = useMemo(() => {
    // const commentsMap = new Map();
    // for (let c of commentsSafe) {
    //     if (!c.sourceCommentId) {
    //         commentsMap.set(c.id, {...c});
    //     }
    // }
    // const childComments = commentsSafe.filter((c) => c.sourceCommentId);

    // for (let c of childComments) {
    //     if (commentsMap.has(c.sourceCommentId)) {
    //         let pComment = commentsMap.get(c.sourceCommentId);
    //         if (Array.isArray(pComment.childComments)) {
    //             pComment.childComments.push(c);
    //         } else {
    //             pComment.childComments = [c];
    //         }
    //     }
    // }

    // return Array.from(commentsMap.values());
    // }, [commentsSafe]);
    useEffect(() => {
        setComments(commentsSafe);
    }, [commentsSafe]);
    const onShowMoreClick = (id) => {
        getReplayCommentsFindAllBySourceCommentIdById({
            segments: {
                id,
            },
        }).then((resp) => {
            const currentSourceComment = comments.find((i) => i.id === id);
            if (!currentSourceComment) return;
            currentSourceComment.subReplayCommentList = resp.data ?? [];
            currentSourceComment.totalReplyCount = (resp.data ?? []).length;
            setComments([...comments]);
        });
    };
    const onDelete = (item) => {
        onDeleteProps?.(item).then((resp) => {
            (onReplyDeleteSuccess || onShowMoreClick)?.(item.sourceCommentId);
        });
    };

    return (
        <div className='comment-component' style={{ height: '100%', width: '100%' }}>
            <div style={{ height: '100%', overflowY: 'auto' }}>
                {commentsSafe.map((comment) => {
                    return (
                        <CommentItem
                            {...comment}
                            onReply={onReply}
                            onShowMoreClick={onShowMoreClick}
                            userList={userList}
                            topicsList={topicsList}
                            hiddenReplayAvatar={hiddenReplayAvatar}
                            replyClassName='reply-wrap-box'
                            sourceCommentId={comment.id}
                            replyCommentId={comment.id}
                            onReplySuccess={onReplySuccess || onShowMoreClick}
                            onDelete={onDelete}
                            createTimePlacement={createTimePlacement}
                            onCommentTimeClick={onCommentTimeClick}
                            editorWrapperClassName='udesk-qa-ui-input'
                        />
                    );
                })}
            </div>
            {/* <CommentReply userList={userList} /> */}
        </div>
    );
});
export default React.memo(CommentComponent);
