import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash-es/isEmpty';

export default class CheckPointConditionComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        onChange: null,
        condition: {},
        conditionTypeList: [],
        type: [],
        index: null,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        isNewAssociate: PropTypes.bool,
        isAutoAssociateDateSource: PropTypes.bool,
        onChange: PropTypes.func,
        condition: PropTypes.object,
        conditionTypeList: PropTypes.array,
        type: PropTypes.array,
        index: PropTypes.number,
    };

    privates = {
        fieldDataList: [],
        // isNewAssociate: true,
    };

    static computes = {
        roleDisabled: [
            'privates.conditionItem',
            ({ privates }) => {
                const { conditionItem } = privates;
                return judgeRoleDisabled(conditionItem);
            },
        ],
    };

    actions = {
        getOperatorTypes() {
            const { replenishOperatorTypes } = this.props;
            const {
                conditionItem: { conditionType } = {},
                operatorTypesOfWords,
                operatorTypesOfInteraction,
                operatorTypesOfFeature,
                operatorTypesOfInformation,
            } = this.privates;
            const operatorTypes = Udesk.enums.operatorTypes.filter((item) => item !== null);
            const result = [
                [],
                [...operatorTypesOfWords],
                [...operatorTypesOfInteraction],
                [...operatorTypesOfFeature],
                [...operatorTypesOfInformation],
            ][[1, 2, 3, 4].includes(conditionType) ? conditionType : 0];
            // 外层可以通过此方法修改操作类型
            if (replenishOperatorTypes) {
                replenishOperatorTypes(result, operatorTypes);
            }
            return result;
        },
        getShowResponseStart() {
            const { privates } = this;
            return (
                [Udesk.enums.operatorTypes.agentAnswer.id].includes(
                    privates.conditionItem.operatorType
                ) &&
                [
                    Udesk.enums.dialogueApplyScopes.agentResponse.id,
                    Udesk.enums.dialogueApplyScopes.customerResponse.id,
                ].includes(privates.conditionItem.operatorList?.[0]?.param?.intervalType)
            );
        },
        getShowApplyTimeType() {
            const { props, privates } = this;
            return (
                props.showApplyTimeType &&
                [
                    Udesk.enums.operatorTypes.agentAnswer.id,
                    Udesk.enums.operatorTypes.dialogue.id,
                    Udesk.enums.operatorTypes.timeInterval.id,
                ].includes(privates.conditionItem.operatorType)
            );
        },
        ruleTypeChange(conditionItem, value) {
            Object.keys(conditionItem).forEach((key) => {
                if (
                    key !== '_id' &&
                    key !== 'operatorType' &&
                    key !== 'taskId' &&
                    key !== 'id' &&
                    key !== 'idCode' &&
                    key !== 'operator' &&
                    key !== 'applyRole' &&
                    key !== 'operatorList' &&
                    key !== 'param' &&
                    key !== 'operatorLogic'
                ) {
                    delete conditionItem[key];
                }
            });
            conditionItem.conditionType = value;

            conditionItem.applyTimeType = Udesk.enums.applyTimeTypes.all.id;
            conditionItem.applyTimeId = null;

            conditionItem.operatorType = this.actions.setDefaultType(value);
            conditionItem.operatorList[0].type = this.actions.setDefaultType(value);
            conditionItem.operatorList[0].applyOperatorScope = {
                id: Udesk.enums.applyOperatorScopes.all.id,
                key: Udesk.enums.applyOperatorScopes.all.key,
            };
            conditionItem.applyConditionHitType = Udesk.enums.preOperatorHitTypes.first.id;
            conditionItem.operatorList[0].operator = 'all';
            conditionItem.operatorList[0].applyRole = 'all';
            if (!conditionItem.operatorList[0].param) {
                conditionItem.operatorList[0].param = {};
            }
            if (conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.agentAnswer.id) {
                // 响应超时
                conditionItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.agentAnswer.id,
                    intervalType: Udesk.enums.dialogueApplyScopes.agentResponse.id,
                    intervalTime: 20,
                };
            }
            if (
                conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.sentenceLength.id
            ) {
                conditionItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.sentenceLength.id,
                    compareType: Udesk.enums.sentenceLength.less.id,
                    wordsNumber: 2,
                };
            }
            if (conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.associate.id) {
                conditionItem.operatorList[0].param = {
                    version: 1,
                };
            }
            conditionItem.operatorList[0].param.type = this.actions.setDefaultType(value);
            this.actions.handleConditionItemChange();
        },
        setDefaultType(key) {
            const operatorTypes = this.actions.getOperatorTypes();
            let type = 0;

            if (operatorTypes && operatorTypes.length > 0) {
                type = operatorTypes[0].id;
            }
            if (key === 1) {
                type = Udesk.enums.operatorTypes.semanticTags.id;
            }
            return type;
        },
        handleConditionItemChange() {
            this.actions.update();
            this.trigger('onChange', this.privates.conditionItem);
        },
        getGearOptionList(conditionItem) {
            this.privates.conditionItem = conditionItem;
            this.actions.handleConditionItemChange();
        },
        selectMenuItem(type, item, key) {
            let { conditionItem } = this.privates;
            if (type === 'applyRole') {
                conditionItem[type] = item.key;
            }
            if (type === 'applyConditionIdCode') {
                conditionItem[type] = item.key === 'item_0' ? null : item.key;
                // conditionItem.applyConditionIdCode = item.key === 'item_0' ? null : item.key;
                conditionItem.applyConditionHitType = Udesk.enums.preOperatorHitTypes.first.id;
            }
            if (type === 'applyConditionHitType') {
                let opera = Udesk.enums.preOperatorHitTypes.find((opera) => {
                    return opera.id === parseInt(item.key, 10);
                });
                conditionItem[type] = opera.id;
                //未批配情况下 重置后续选项，检测范围隐藏前置条件选项
                if (
                    conditionItem.applyConditionHitType === Udesk.enums.preOperatorHitTypes.none.id
                ) {
                    conditionItem.applyScopeStart = null;
                    conditionItem.applyScopeEnd = null;
                    conditionItem.applyConditionLimit = null;
                    conditionItem.applyConditionScope = null;
                    conditionItem.operatorList[0].applyOperatorScope = {
                        key: Udesk.enums.applyOperatorScopes.all.key,
                        id: Udesk.enums.applyOperatorScopes.all.id,
                    };
                }
            }
            if (type === 'applyOperatorScope') {
                //前端自用属性，后端不保存，使用applyScopeStart，applyScopeEnd的有无判断
                conditionItem.applyScopeStart = null;
                conditionItem.applyScopeEnd = null;
                conditionItem.applyConditionLimit = null;
                conditionItem.applyConditionScope = null;

                let opera = Udesk.enums.applyOperatorScopes.find((opera) => {
                    return opera.id === parseInt(item.key, 10);
                });
                conditionItem.operatorList[0][type] = {
                    key: opera.key,
                    id: opera.id,
                };
                if (
                    conditionItem.operatorList[0][type].id ===
                    Udesk.enums.applyOperatorScopes.preCondition.id
                ) {
                    conditionItem.applyConditionScope = Udesk.enums.applyConditionScopes.current.id;
                }
                if (
                    conditionItem.operatorList[0][type].id ===
                    Udesk.enums.applyOperatorScopes.all.id
                ) {
                    conditionItem.applyScopeStart = null;
                    conditionItem.applyScopeEnd = null;
                    conditionItem.applyConditionLimit = null;
                    conditionItem.applyConditionScope = null;
                }
            }
            if (type === 'applyConditionScope') {
                let opera = Udesk.enums.applyConditionScopes.find((opera) => {
                    return opera.id === item;
                });
                conditionItem[type] = opera.id;
            }
            if (type === 'applyTimeType') {
                let opera = Udesk.enums.applyTimeTypes.find((opera) => {
                    return opera.id === parseInt(item.key, 10);
                });
                conditionItem[type] = opera.id;
                conditionItem.applyTimeId = null;
            }
            if (type === 'applyTimeId') {
                conditionItem[type] = item;
            }
            if (type === 'responseStart') {
                conditionItem.responseStart = parseInt(item.key, 10);
            }
            this.actions.handleConditionItemChange();
        },
        changeInputValue(conditionItem, type, value) {
            conditionItem[type] = value;
            this.actions.handleConditionItemChange();
        },
        validateRangeValue(condition, attribute, value) {
            if (value === '0' || value === 0) {
                condition[attribute] = '';
                this.actions.handleConditionItemChange();
                return Udesk.ui.notify.error(UdeskLocales.current.components.checkRange.notZero);
            }

            if (condition.applyScopeStart && condition.applyScopeEnd) {
                if (
                    parseInt(condition.applyScopeStart, 10) > parseInt(condition.applyScopeEnd, 10)
                ) {
                    condition[attribute] = '';
                    this.actions.handleConditionItemChange();
                    return Udesk.ui.notify.error(
                        UdeskLocales.current.components.checkRange.startMustLessThanEnd
                    );
                }
            }
        },
        validateGrabInterval(operatorItem, attribute, value) {
            if (
                operatorItem[attribute] == null ||
                operatorItem[attribute] === '' ||
                operatorItem[attribute] === 0
            ) {
                return Udesk.ui.notify.error(UdeskLocales.current.components.checkRange.notZero);
            }
            // if (operatorItem.applyScopeStart && operatorItem.applyScopeEnd) {
            //     if (parseInt(operatorItem.applyScopeStart, 10) > parseInt(operatorItem.applyScopeEnd, 10)) {
            //         operatorItem[attribute] = '';
            //         this.actions.update();
            //         return Udesk.ui.notify.error(UdeskLocales.current.components.checkRange.startMustLessThanEnd);
            //     }
            // }
        },
        initField() {
            const { props } = this;
            //区别语音、文本、实时语音、实时文本，过滤算子类型
            let operatorTypes = Udesk.enums.operatorTypes.filter((item) => item !== null);

            if (!props.isWechatQa) {
                operatorTypes = operatorTypes.filter((type) => {
                    return (
                        type.id !== Udesk.enums.operatorTypes.wecomRemark.id &&
                        type.id !== Udesk.enums.operatorTypes.customerAddTime.id
                    );
                });
            }

            if (
                props.type.includes(Udesk.enums.inspectDataSources.textDialogue.id) ||
                props.type.includes(Udesk.enums.inspectDataSources.realTimeTextDialogue.id) ||
                props.type.includes(Udesk.enums.inspectDataSources.wechatDialogue.id)
            ) {
                operatorTypes = operatorTypes.filter((type) => {
                    return (
                        type.id !== Udesk.enums.operatorTypes.question.id &&
                        type.id !== Udesk.enums.operatorTypes.speed.id &&
                        type.id !== Udesk.enums.operatorTypes.volume.id &&
                        // 文本支持情绪
                        // type.id !== Udesk.enums.operatorTypes.emotion.id &&
                        type.id !== Udesk.enums.operatorTypes.grabDetection.id
                    );
                });
            }
            if (props.type.includes(Udesk.enums.inspectDataSources.wechatDialogue.id)) {
                operatorTypes = operatorTypes.filter((type) => {
                    return type.id !== Udesk.enums.operatorTypes.typoDetection.id;
                });
            }
            if (props.type.includes(Udesk.enums.inspectDataSources.ticket.id)) {
                const ignoreKeys = [
                    'keyword',
                    'question',
                    'regular',
                    'context',
                    'semantics',
                    'word',
                    'dialogue',
                    'speed',
                    'grabDetection',
                    'entityInfoDetection',
                    'volume',
                    'grammar',
                    'questionsAndAnswers',
                    'element',
                    'timeInterval',
                    'typoDetection',
                    // 'knowledge',
                    'wecomRemark',
                    'customerAddTime',
                ];
                operatorTypes = operatorTypes.filter((type) => !ignoreKeys.includes(type.key));
            }

            if (
                props.type.includes(Udesk.enums.inspectDataSources.realTimeVoiceCall.id) ||
                props.type.includes(Udesk.enums.inspectDataSources.voiceCall.id)
            ) {
                operatorTypes = operatorTypes.filter((type) => {
                    return type.id !== Udesk.enums.operatorTypes.typoDetection.id;
                });
            }
            if (
                props.type.includes(Udesk.enums.inspectDataSources.realTimeVoiceCall.id) ||
                props.type.includes(Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
            ) {
                operatorTypes = operatorTypes.filter((type) => {
                    return (
                        type.id !== Udesk.enums.operatorTypes.keyword.id &&
                        type.id !== Udesk.enums.operatorTypes.semantics.id &&
                        type.id !== Udesk.enums.operatorTypes.semanticTags.id &&
                        type.id !== Udesk.enums.operatorTypes.entityInfoDetection.id &&
                        type.id !== Udesk.enums.operatorTypes.grammar.id
                    );
                });
            }

            if (
                props.type.includes(Udesk.enums.inspectDataSources.realTimeVoiceCall.id) ||
                props.type.includes(Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
            ) {
                operatorTypes = operatorTypes.filter((type) => {
                    return (
                        type.id !== Udesk.enums.operatorTypes.keyword.id &&
                        type.id !== Udesk.enums.operatorTypes.semantics.id &&
                        type.id !== Udesk.enums.operatorTypes.semanticTags.id &&
                        type.id !== Udesk.enums.operatorTypes.entityInfoDetection.id &&
                        type.id !== Udesk.enums.operatorTypes.grammar.id
                    );
                });
            }

            if (props.type.includes(0)) {
                operatorTypes = operatorTypes.filter((type) => {
                    return (
                        type.id !== Udesk.enums.operatorTypes.question.id &&
                        type.id !== Udesk.enums.operatorTypes.speed.id &&
                        type.id !== Udesk.enums.operatorTypes.volume.id &&
                        type.id !== Udesk.enums.operatorTypes.emotion.id &&
                        type.id !== Udesk.enums.operatorTypes.grabDetection.id &&
                        type.id !== Udesk.enums.operatorTypes.typoDetection.id
                    );
                });
            }
            let operatorTypesOfWords = operatorTypes.filter((type) => {
                return (
                    type.id === Udesk.enums.operatorTypes.semanticTags.id ||
                    type.id === Udesk.enums.operatorTypes.conceptWord.id ||
                    type.id === Udesk.enums.operatorTypes.keywordStrict.id ||
                    (type.id === Udesk.enums.operatorTypes.regular.id && props.ruglar)
                );
            });
            let operatorTypesOfInteraction = operatorTypes.filter((type) => {
                // console.log('operatorTypesOfInteraction:::::', operatorTypes);
                return (
                    type.id === Udesk.enums.operatorTypes.questionsAndAnswers.id ||
                    type.id === Udesk.enums.operatorTypes.knowledge.id ||
                    type.id === Udesk.enums.operatorTypes.grabDetection.id ||
                    type.id === Udesk.enums.operatorTypes.repeat.id ||
                    type.id === Udesk.enums.operatorTypes.dialogue.id ||
                    type.id === Udesk.enums.operatorTypes.agentAnswer.id ||
                    type.id === Udesk.enums.operatorTypes.timeInterval.id
                );
            });
            let operatorTypesOfFeature = operatorTypes.filter((type) => {
                return (
                    type.id === Udesk.enums.operatorTypes.question.id ||
                    type.id === Udesk.enums.operatorTypes.sentenceLength.id ||
                    type.id === Udesk.enums.operatorTypes.speed.id ||
                    type.id === Udesk.enums.operatorTypes.volume.id ||
                    type.id === Udesk.enums.operatorTypes.emotion.id ||
                    type.id === Udesk.enums.operatorTypes.typoDetection.id
                );
            });
            let operatorTypesOfInformation = operatorTypes.filter((type) => {
                return (
                    type.id === Udesk.enums.operatorTypes.entityInfoDetection.id ||
                    (type.id === Udesk.enums.operatorTypes.associate.id &&
                        !props.type.includes(0)) ||
                    (type.id === Udesk.enums.operatorTypes.wecomRemark.id && props.isWechatQa) ||
                    (type.id === Udesk.enums.operatorTypes.customerAddTime.id &&
                        props.isWechatQa) ||
                    (props.funcType === 'QUALITY_INSPECT' &&
                        props.hasSummaryRule &&
                        type.id === Udesk.enums.operatorTypes.businessSummary.id)
                );
            });

            this.privates.operatorTypesOfWords = operatorTypesOfWords;
            this.privates.operatorTypesOfInteraction = operatorTypesOfInteraction;
            // console.log(
            //     'conditionItem33::::operatorTypesOfInteraction',
            //     operatorTypesOfInteraction
            // );
            this.privates.operatorTypesOfFeature = operatorTypesOfFeature;
            this.privates.operatorTypesOfInformation = operatorTypesOfInformation;
        },
        onAssociateVersionChange() {
            // this.privates.isNewAssociate = !this.privates.isNewAssociate;
            this.privates.conditionItem.operatorList[0].param.inputContent = '';
            this.privates.conditionItem.operatorList[0].param.version =
                (typeof this.privates.conditionItem.operatorList[0].param.version === 'undefined'
                    ? this.props.isNewAssociate || 0
                    : this.privates.conditionItem.operatorList[0].param.version) === 1
                    ? 0
                    : 1;
            this.actions.handleConditionItemChange();
            this.actions.update();
        },
    };

    parseProps({ props, prevProps, state, privates, isInitial }) {
        let needTrigger = false;
        let conditionItem = JSON.parse(JSON.stringify(props.condition));

        console.log('conditionItem22', conditionItem);

        if (!conditionItem.operatorType) {
            let key = conditionItem.conditionType;
            conditionItem.operatorType = this.actions.setDefaultType(key);
            conditionItem.operatorList[0].type = this.actions.setDefaultType(key);
            conditionItem.operatorList[0].param = { type: this.actions.setDefaultType(key) };
            needTrigger = true;
            if (key === 3) {
                conditionItem.operatorList[0].param = {
                    ...conditionItem.operatorList[0].param,
                    compareType: Udesk.enums.sentenceLength.less.id,
                    wordsNumber: 2,
                };
            }
            if (key === 2) {
                conditionItem.operatorList[0].applyScope =
                    Udesk.enums.dialogueApplyScopes.agentResponse.id;
                conditionItem.operatorList[0].intervalSecond = 20;
            }
        }
        //applyOperatorScope 是前端根据applyScopeStart，applyScopeEnd，applyConditionLimit判断出的属性，用于回显范围
        if (!conditionItem.operatorList[0].applyOperatorScope) {
            let applyOperatorScope = {
                key: Udesk.enums.applyOperatorScopes.all.key,
                id: Udesk.enums.applyOperatorScopes.all.id,
                name: Udesk.enums.applyOperatorScopes.all.name,
            };
            if (conditionItem.applyConditionLimit || conditionItem.applyConditionScope) {
                applyOperatorScope = {
                    key: Udesk.enums.applyOperatorScopes.preCondition.key,
                    id: Udesk.enums.applyOperatorScopes.preCondition.id,
                    name: Udesk.enums.applyOperatorScopes.preCondition.name,
                };
            }
            if (conditionItem.applyScopeStart || conditionItem.applyScopeEnd) {
                applyOperatorScope = {
                    key: Udesk.enums.applyOperatorScopes.scope.key,
                    id: Udesk.enums.applyOperatorScopes.scope.id,
                    name: Udesk.enums.applyOperatorScopes.scope.name,
                };
            }

            conditionItem.operatorList[0].applyOperatorScope = applyOperatorScope;
            needTrigger = true;
        }

        if (!conditionItem.responseStart) {
            let responseStart = Udesk.enums.responseStart.first.id;

            conditionItem.responseStart = responseStart;
            needTrigger = true;
        }

        const shouldHasParam =
            conditionItem.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.knowledge.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.element.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.conceptWord.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.sentenceLength.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.emotion.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.volume.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.associate.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.businessSummary.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.repeat.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.timeInterval.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.customerAddTime.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.agentAnswer.id ||
            conditionItem.operatorType === Udesk.enums.operatorTypes.typoDetection.id;

        if (shouldHasParam && !conditionItem.operatorList[0].param) {
            conditionItem.operatorList[0].param = {};
        }
        if (!shouldHasParam && conditionItem.operatorList[0].param) {
            conditionItem.operatorList[0].param = null;
            needTrigger = true;
        }

        // 这里，由于针对响应超时和知识解答的检测角色只能是全部，做一下特殊处理
        if (
            judgeRoleDisabled(conditionItem) &&
            conditionItem.applyRole !==
                (this.props.isSales
                    ? Udesk.enums.saleApplyRoles[0].id
                    : Udesk.enums.applyRoles[0].id)
        ) {
            conditionItem.applyRole = this.props.isSales
                ? Udesk.enums.saleApplyRoles[0].id
                : Udesk.enums.applyRoles[0].id;
            needTrigger = true;
        }

        needTrigger && this.trigger('onChange', conditionItem);

        return {
            conditionItem: conditionItem,
        };
    }
    componentDidUpdate(prevProps) {
        if (
            !_isEmpty(this.props.type) &&
            !_isEmpty(prevProps.type) &&
            this.props.type[0] !== prevProps.type[0]
        ) {
            this.actions.initField();
            this.actions.update();
        }
    }
    componentDidMount() {
        if (!this.props.sdkOptions.props.basicInfoFields) {
            this.actions.initField();
            this.actions.update();
        }
    }
    init() {
        this.actions.initField();
    }
}

const judgeRoleDisabled = (conditionItem) => {
    const disabledOperatorType = [
        Udesk.enums.operatorTypes.agentAnswer.id,
        Udesk.enums.operatorTypes.knowledge.id,
    ].includes(conditionItem?.operatorType);

    const disabledOperatorList =
        conditionItem?.operatorType === Udesk.enums.operatorTypes.dialogue.id &&
        [
            Udesk.enums.dialogueApplyScopes.agent.id,
            Udesk.enums.dialogueApplyScopes.customer.id,
        ].includes(conditionItem?.operatorList?.[0].applyScope);

    return disabledOperatorType || disabledOperatorList;
};
