import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Locales from 'UdeskLocales';

const style = {
    height: '32px',
    fontSize: "14px",
    marginBottom: '.5rem',
    cursor: 'move',
    padding: '0px 15px',
    borderRadius: '4px',
    display: 'inline-block',
    lineHeight: '32px',
    marginRight: '3px',
    textAlign: 'center',
    background: '#fff',
    position: 'relative',
    verticalAlign: 'top'
};
const iconStyle = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    height: '14px',
    width: '14px',
    textAlign: 'center',
    lineHeight: '13px',
    cursor: 'pointer',
    display: 'none'
};
const Card = ({ id, text, index, moveCard, color, fieldType, subType, valueChange, defaultValue, delValue }) => {
    const locales = Locales['current'];

    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'cards',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves

            if (dragIndex === hoverIndex) {
                return;
            }


            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleX =
                (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            // if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            //     return;
            // }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX && !item.name) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: 'cards', id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging()

        }),
        canDrag: id === 'none' ? false : true
    });
    const opacity = id === 'none' ? 0 : isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div ref={ref} style={{ ...style, opacity, border: `1px solid ${color}`, color: `${color}` }} className='semantic-tags-dnd-card'>
            {
                id !== 'none' && (
                    <span className='semantic-tags-dnd-card-icon' style={{ ...iconStyle }} onClick={() => delValue(id)}><i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-guanbi1" style={{ fontSize: '12px' }}></i></span>
                )
            }
            {
                fieldType === 'mm' ? subType === 'mm_1' ? (
                    <span>
                        {locales.fix.matching} <input type='number' defaultValue={defaultValue && defaultValue.length > 0 ? defaultValue[0] : null} className='react-dnd-input-button-style' onChange={(value) => valueChange(id, 0, value.target.value)} /> {locales.fix.bout}
                    </span>
                ) : subType === "mm_2" ? (
                    <div>

                        {locales.fix.matching}<input type='number' defaultValue={defaultValue && defaultValue.length > 0 ? defaultValue[0] : null} className='react-dnd-input-button-style' onChange={(value) => valueChange(id, 0, value.target.value)} /> ~ <input type="number" className='react-dnd-input-button-style' defaultValue={defaultValue && defaultValue.length > 0 ? defaultValue[1] : null} onChange={(value) => valueChange(id, 1, value.target.value)} /> {locales.fix.bout}

                    </div>
                ) : text : text
            }
        </div>
    );
};
export default Card;
