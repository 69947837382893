// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetInspectionTasksCollectTypeByCollectParam,
    BaseResponseListInspectionTaskFoundResponse,
} from '../../../types';

/**
 * findByCollectType
 *
 * @export
 * @tags 质检任务
 * @link [GET] /inspectionTasks/CollectType/{collect}
 * @param collect
 */
export function getInspectionTasksCollectTypeByCollect(
    options: ApiOptions<GetInspectionTasksCollectTypeByCollectParam, never> & {
        segments: GetInspectionTasksCollectTypeByCollectParam;
    }
): Promise<BaseResponseListInspectionTaskFoundResponse> {
    return request<never, GetInspectionTasksCollectTypeByCollectParam, never>(
        '/inspectionTasks/CollectType/{collect}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getInspectionTasksCollectTypeByCollect',
        }
    );
}

export const meta = [
    { tags: ['质检任务'], path: '/inspectionTasks/CollectType/{collect}', method: 'get' },
];
