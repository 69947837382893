// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetReviewCallAnalysisOrganizationByIdBestPractiseQuery,
    GetReviewCallAnalysisOrganizationByIdBestPractiseParam,
    BaseResponseDataCallAnalysisOrgResultResponse,
} from '../../../../../../types';

/**
 * bestPractise
 *
 * @export
 * @tags 团队分析
 * @link [GET] /review/call/analysis/organization/{id}/bestPractise
 * @param id
 */
export function getReviewCallAnalysisOrganizationByIdBestPractise(
    options: ApiOptions<
        GetReviewCallAnalysisOrganizationByIdBestPractiseParam,
        GetReviewCallAnalysisOrganizationByIdBestPractiseQuery
    > & { segments: GetReviewCallAnalysisOrganizationByIdBestPractiseParam } & {
        params: GetReviewCallAnalysisOrganizationByIdBestPractiseQuery;
    }
): Promise<BaseResponseDataCallAnalysisOrgResultResponse> {
    return request<
        never,
        GetReviewCallAnalysisOrganizationByIdBestPractiseParam,
        GetReviewCallAnalysisOrganizationByIdBestPractiseQuery
    >('/review/call/analysis/organization/{id}/bestPractise', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallAnalysisOrganizationByIdBestPractise',
    });
}

export const meta = [
    {
        tags: ['团队分析'],
        path: '/review/call/analysis/organization/{id}/bestPractise',
        method: 'get',
    },
];
