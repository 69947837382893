// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetReviewCallStatisticsQuery,
    BaseResponseListDataCallPeriodStatisticsResponse,
    PutReviewCallStatisticsBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * findAll
 *
 * @export
 * @tags 通话统计
 * @link [GET] /review/call/statistics
 */
export function getReviewCallStatistics(): Promise<BaseResponseListDataCallPeriodStatisticsResponse>;
export function getReviewCallStatistics(
    options: ApiOptions<never, GetReviewCallStatisticsQuery>
): Promise<BaseResponseListDataCallPeriodStatisticsResponse>;
export function getReviewCallStatistics(
    options?: ApiOptions<never, GetReviewCallStatisticsQuery>
): Promise<BaseResponseListDataCallPeriodStatisticsResponse> {
    return request<never, never, GetReviewCallStatisticsQuery>('/review/call/statistics', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallStatistics',
    });
}

/**
 * update
 *
 * @export
 * @tags 通话统计
 * @link [PUT] /review/call/statistics
 */
export function putReviewCallStatistics(
    data: PutReviewCallStatisticsBody
): Promise<BaseResponseVoid>;
export function putReviewCallStatistics(
    data: PutReviewCallStatisticsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putReviewCallStatistics(
    data: PutReviewCallStatisticsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutReviewCallStatisticsBody, never, never>('/review/call/statistics', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewCallStatistics',
    });
}

export const meta = [
    { tags: ['通话统计'], path: '/review/call/statistics', method: 'get' },
    { tags: ['通话统计'], path: '/review/call/statistics', method: 'put' },
];
