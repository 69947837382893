import React, { createContext } from 'react';

// 搜素
export const FilterContext = createContext<[any, React.Dispatch<React.SetStateAction<any>>]>(
    [] as any
);

// 表单
export const InitialValuesContext = createContext<[any, React.Dispatch<React.SetStateAction<any>>]>(
    [] as any
);

// 导出
export const ExportContext = createContext<[any, React.Dispatch<React.SetStateAction<any>>]>(
    [] as any
);
