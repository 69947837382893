import React, { useRef, useEffect, useState, FC } from 'react';
import { useTemplate } from './hooks';
import useResizeObserver from 'use-resize-observer';
import { Empty } from 'udesk-ui';
import styled from 'styled-components';
import * as echarts from 'echarts';

const Wrapper = styled.div``;

const Chart = styled.div`
    height: 400px;
`;

type TemplateProps = {
    params?: any;
};

const Template: FC<TemplateProps> = (props) => {
    const { params } = props;

    const chartRef = useRef(null);

    const [echartInstance, setEchartInstance] = useState<any>(null);

    const { echartOptions } = useTemplate({ params });

    useEffect(() => {
        if (chartRef.current && echartOptions) {
            const c = echarts.init(chartRef.current);
            setEchartInstance(c);
        }
    }, [echartOptions]);

    useEffect(() => {
        if (echartInstance && echartOptions) {
            echartInstance.setOption(echartOptions);
        }
    }, [echartInstance, echartOptions]);

    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        requestIdleCallback(() => {
            echartInstance?.resize?.();
        });
    }, [width, height, echartInstance]);

    return (
        <Wrapper ref={ref}>
            {echartOptions ? (
                <Chart ref={chartRef}></Chart>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Wrapper>
    );
};

export default Template;
