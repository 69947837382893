// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PutLibraryCallsCopyBody, BaseResponseVoid } from '../../types';

/**
 * copy
 *
 * @export
 * @tags 案例库通话记录
 * @link [PUT] /libraryCalls/copy
 */
export function putLibraryCallsCopy(data: PutLibraryCallsCopyBody): Promise<BaseResponseVoid>;
export function putLibraryCallsCopy(
    data: PutLibraryCallsCopyBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putLibraryCallsCopy(
    data: PutLibraryCallsCopyBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutLibraryCallsCopyBody, never, never>('/libraryCalls/copy', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putLibraryCallsCopy',
    });
}

export const meta = [{ tags: ['案例库通话记录'], path: '/libraryCalls/copy', method: 'put' }];
