import React from 'react';
import Udesk from 'Udesk';
import QualityInspectionListIndexRoute from './route';
import {
    Popover,
    Modal,
    PageHeader,
    Button,
    Space,
    Input,
    Tag,
    Icon,
    Menu,
    Dropdown,
} from 'udesk-ui';
import ExportButton from 'Component/common/export-button';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import './style.scss';
import ReInspectionModel from 'Component/pages/components/reinspection-model';
import ReInspectionResultModel from 'Component/pages/components/re-inspection-result-modal';
import UdeskTable from 'Component/common/origin-columns-control-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import { BatchedActions } from 'udesk_gm_ui';
import { ArrowDownSOutlined } from '@udesk/icons';
import { postCallInspectByTaskIdBatchReAsr } from 'src/api/call/inspect/{taskId}/batch/reAsr';
import { getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';
// import ColumnsManageModal from 'Component/pages/columns-manage';

export default class QualityInspectionListIndexTemplate extends QualityInspectionListIndexRoute {
    render() {
        let { sdkOptions } = this.props;
        let {
            total,
            filterVisible,
            reInspectionConfigVisible,
            reInspectionModalVisible,
            // progressStatus,
            // progressValue,
            // alreadyDoneCount,
            // recheckProcessTotal,
            tableRef,
            tableDOMRef,
            reInspectionType,
            allPoints,
            reInspectionPoints,
            customName,
            customId,
            loading,
            isWechatQa,
            isInformationCollect,
            inspectSearchDefaultShowFields,
            reCheckLoading,
            fastFilterConditions,
            currentFastFilterConditionIndex,
            columnsManageModalVisible,
        } = this.privates;
        let { pageNum, pageSize, conditionList, judgeStrategy, customJudgeLogic, keywords } =
            this.privates.storages;
        let { _cacheKey, _columns, _dataList } = this.privates.computes;
        let { actions, locales } = this;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        let isRelated = sdkOptions.props?.task?.related ?? false;
        const menu = (
            <Menu>
                <Menu.Item onClick={actions.onColumnsManageModalVisible}>
                    {locales.components.pages.qualityInspectionList.sysCloumnsContal}
                </Menu.Item>
                <Menu.Item onClick={actions.onPopoverVisible}>
                    {locales.components.pages.qualityInspectionList.personCloumnsContal}
                </Menu.Item>
            </Menu>
        );

        const isCallInspect =
            sdkOptions.props.task.inspectDataSource ===
                Udesk.enums.inspectDataSources.voiceCall.id ||
            sdkOptions.props.task.inspectDataSource ===
                Udesk.enums.inspectDataSources.realTimeVoiceCall.id;

        return (
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={
                        locales.components.pages.qualityInspectionList[
                            isWechatQa
                                ? 'titleWechat'
                                : isInformationCollect
                                ? 'titleCollect'
                                : 'title'
                        ]
                    }
                />

                {/* <div className="quality-inspection-list-index-page"> */}
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root quality-inspection-list-index-page-body">
                        <If condition={hasFeature && hasFeature('task:work:inpectionList:view')}>
                            <div
                                className="quality-inspection-list-index-page-body-search-container qa-react-page-body-search-container"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 10,
                                }}
                            >
                                <div className="qa-react-page-body-search-container-left-part">
                                    <Space>
                                        {/* <Select
                                            value={type}
                                            style={{width: 100}}
                                            onSelect={actions.selectType}
                                            options={Udesk.enums.applyRoles.map(item => ({label: item.name, value: item.id}))}
                                        /> */}

                                        <Input
                                            maxLength={20}
                                            maxCount={20}
                                            showCount={true}
                                            value={keywords}
                                            placeholder={locales.labels.searchPlaceHolder}
                                            onChange={actions.keywordsChanged}
                                        />
                                        <Button onClick={actions.searchItems} disabled={loading}>
                                            {locales.labels.query}
                                        </Button>
                                    </Space>
                                </div>
                                <div className="qa-react-page-body-search-container-right-part">
                                    <Space>
                                        {isCallInspect ? (
                                            <BatchedActions
                                                menu={[
                                                    {
                                                        label: locales.components.pages
                                                            .qualityInspectionList[
                                                            isWechatQa
                                                                ? 'reQualityInspectionWechat'
                                                                : 'reQualityInspection'
                                                        ],
                                                        onClick:
                                                            actions.changeReInspectionConfigVisible,
                                                    },
                                                    {
                                                        label: /* 重新识别 */ UdeskLocales[
                                                            'current'
                                                        ].pages.qualityInspectionList.template
                                                            .reIdentification,
                                                        confirm: {
                                                            title: /* 重新识别会消耗转写时长，请谨慎操作 */ UdeskLocales[
                                                                'current'
                                                            ].pages.qualityInspectionList.template
                                                                .rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution,
                                                            okText: /* 确定 */ UdeskLocales[
                                                                'current'
                                                            ].pages.qualityInspectionList.template
                                                                .determine,
                                                        },
                                                        onClick: (item) => {
                                                            const {
                                                                conditionList,
                                                                judgeStrategy,
                                                                customJudgeLogic,
                                                                keywords,
                                                            } = this.privates.storages;
                                                            let taskId =
                                                                this.props.match.params.taskId;
                                                            postCallInspectByTaskIdBatchReAsr(
                                                                {
                                                                    searchRequest: {
                                                                        taskId,
                                                                        conditionList:
                                                                            conditionList,
                                                                        judgeStrategy,
                                                                        customJudgeLogic,
                                                                        keyword: keywords,
                                                                    },
                                                                    systemModule: getSystemModule(),
                                                                },
                                                                {
                                                                    segments: {
                                                                        taskId,
                                                                    },
                                                                    successMsg:
                                                                        /* 执行成功 */ UdeskLocales[
                                                                            'current'
                                                                        ].pages
                                                                            .qualityInspectionList
                                                                            .template
                                                                            .executionSuccessful,
                                                                }
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: /* 删除记录 */UdeskLocales['current'].pages.qualityInspectionList.template.deleteRecord,
                                                        confirm: {
                                                            title: /* 确定删除吗？ */UdeskLocales['current'].pages.qualityInspectionList.template.areYouSureToDeleteIt,
                                                            okText: /* 确定 */UdeskLocales['current'].pages.qualityInspectionList.template.confirm,
                                                        },
                                                        onClick: actions.batchDelete,
                                                    },
                                                ]}
                                            >
                                                <Button>
                                                    {/* 批量操作 */}
                                                    {
                                                        locales.components.listManage.template
                                                            .batchOperation
                                                    }
                                                    <ArrowDownSOutlined style={{ marginLeft: 4 }} />
                                                </Button>
                                            </BatchedActions>
                                        ) : (
                                            <BatchedActions
                                                menu={[
                                                    !isRelated &&
                                                    !isInformationCollect &&
                                                    hasFeature &&
                                                    hasFeature('task:work:inpectionList:recheck')
                                                        ? {
                                                              label: locales.components.pages
                                                                  .qualityInspectionList[
                                                                  isWechatQa
                                                                      ? 'reQualityInspectionWechat'
                                                                      : 'reQualityInspection'
                                                              ],
                                                              onClick:
                                                                  actions.changeReInspectionConfigVisible,
                                                          }
                                                        : null,
                                                    {
                                                        label: /* 删除记录 */UdeskLocales['current'].pages.qualityInspectionList.template.deleteRecord,
                                                        confirm: {
                                                            title: /* 确定删除吗？ */UdeskLocales['current'].pages.qualityInspectionList.template.areYouSureToDeleteIt,
                                                            okText: /* 确定 */UdeskLocales['current'].pages.qualityInspectionList.template.confirm,
                                                        },
                                                        onClick: actions.batchDelete,
                                                    },
                                                ].filter(Boolean)}
                                            >
                                                <Button>
                                                    {/* 批量操作 */}
                                                    {
                                                        locales.components.listManage.template
                                                            .batchOperation
                                                    }
                                                    <ArrowDownSOutlined style={{ marginLeft: 4 }} />
                                                </Button>
                                            </BatchedActions>
                                        )}

                                        <If
                                            condition={
                                                hasFeature &&
                                                hasFeature('task:work:inpectionList:export')
                                            }
                                        >
                                            <ExportButton
                                                disabled={loading}
                                                exportDataCount={total}
                                                hasFilterCondition={!!conditionList.length}
                                                onClick={actions.exportDataList}
                                            />
                                        </If>
                                        <Popover
                                            content={this.actions.queckListMang()}
                                            placement="bottomRight"
                                        >
                                            <Button
                                                disabled={loading}
                                                onClick={actions.changeFilterVisible}
                                            >
                                                {locales.labels.filter}
                                            </Button>
                                        </Popover>
                                        {hasFeature &&
                                        hasFeature('task:template:control') &&
                                        !isWechatQa &&
                                        !isInformationCollect &&
                                        sdkOptions.props.task.inspectDataSource !==
                                            Udesk.enums.inspectDataSources.taobao.id ? (
                                            <Dropdown overlay={menu} trigger={['click']}>
                                                <Button>
                                                    {
                                                        locales.components.pages
                                                            .customerServiceRankList.select.column
                                                    }
                                                </Button>
                                            </Dropdown>
                                        ) : (
                                            <Button onClick={actions.onPopoverVisible}>
                                                {
                                                    locales.components.pages.customerServiceRankList
                                                        .select.column
                                                }
                                            </Button>
                                        )}
                                    </Space>
                                </div>
                            </div>
                            {Array.isArray(fastFilterConditions) &&
                                Boolean(fastFilterConditions.length) && (
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            {
                                                locales.components.pages.qualityInspectionList
                                                    .fastFilter
                                            }
                                            <Button
                                                icon={
                                                    <Icon
                                                        antdIcon={true}
                                                        style={{ margin: '0 4px' }}
                                                        type="QuestionCircleOutlined"
                                                    />
                                                }
                                                tooltip={
                                                    locales.components.pages.qualityInspectionList
                                                        .fastFilterTip
                                                }
                                                type="text"
                                                size="small"
                                            />
                                            :
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                flexWrap: 'nowrap',
                                                display: 'flex',
                                                overflowX: 'auto',
                                            }}
                                        >
                                            {fastFilterConditions.map((item, index) => {
                                                return (
                                                    <Tag
                                                        color={
                                                            currentFastFilterConditionIndex ===
                                                            index
                                                                ? 'blue'
                                                                : undefined
                                                        }
                                                        onClick={actions.onSelectFastFilterCondition.params(
                                                            item,
                                                            index
                                                        )}
                                                        style={{
                                                            margin: '0 4px 8px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >{`${item.name}(${item.count})`}</Tag>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            {/* <ColumnsManageModal
                                visible={columnsManageModalVisible}
                                sourceListValue={this.privates.type}
                                taskId={this.props.match.params.taskId}
                                onOriginColumnsBack={actions.onOriginColumnsBack}
                                onCancel={actions.onColumnsManageModalCancel}
                                onApply={actions.onApply}
                                cacheKey={_cacheKey}
                            /> */}
                            <div
                                ref={tableDOMRef}
                                className="quality-inspection-list-index-page-body-content-container qa-react-page-body-content-container"
                            >
                                {/* <ReactTable ref={tableRef} horizontalScrollbar={true} showHorizontalScrollbar={true} columns={_columns} items={_dataList} enableChooseColumns={true} cacheKey={_cacheKey} onColumnsChanged={actions.columnsChange} rowClick={actions.gotoDetail} />
                                <ReactPager showTotalPages={false} pageRangeDisplayed={1} marginPagesDisplayed={0} pageNum={pageNum} pageSize={pageSize} total={total} languageCode={sdkOptions.props.language} pageChanged={actions.pageChanged} /> */}
                                <UdeskTable
                                    cacheKey={_cacheKey}
                                    subtractedHeight={
                                        Array.isArray(fastFilterConditions) &&
                                        Boolean(fastFilterConditions.length)
                                            ? 192
                                            : 160
                                    }
                                    // scroll={{ x: _size(_columns) * 200, y: 'calc(100vh - 64px - 58px - 64px - 165px)' }}
                                    tableRef={tableRef}
                                    cloumnsContalPopClassName={
                                        Array.isArray(fastFilterConditions) &&
                                        Boolean(fastFilterConditions.length)
                                            ? 'quality-inspection-list-cloumns-contal'
                                            : ''
                                    }
                                    loading={loading}
                                    columns={_columns}
                                    dataSource={_dataList}
                                    rowKey={(item) => item.id}
                                    inspectSearchDefaultShowFields={inspectSearchDefaultShowFields}
                                    onRow={(record) => {
                                        return { onClick: actions.gotoDetail.params(record) };
                                    }}
                                    pagination={false}
                                    originColumnsProps={{
                                        visible: columnsManageModalVisible,
                                        sourceListValue: this.privates.type,
                                        taskId: this.props.match.params.taskId,
                                        onOriginColumnsBack: actions.onOriginColumnsBack,
                                        onCancel: actions.onColumnsManageModalCancel,
                                        onApply: actions.onApply,
                                    }}
                                    rowSelection={{
                                        selectedRowKeys: this.privates.selectedRowKeys,
                                        onChange: actions.onSelectChange,
                                    }}
                                ></UdeskTable>
                                {!!total && (
                                    <UdeskPagination
                                        current={pageNum}
                                        pageSize={pageSize}
                                        onShowSizeChange={actions.pageSizeChange}
                                        total={total}
                                        overSizeHidden={true}
                                        onChange={actions.pageChanged}
                                    />
                                )}
                            </div>
                        </If>
                    </div>
                </div>
                {/* <ReactModal visible={filterVisible} title={locales.components.pages.components.listManage.filterConditions} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.changeFilterVisible} onOk={actions.handleConditionFilter} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                    <div className="udesk-qa-modal-body-content">
                        <CustomFilters allowEmptyConditions={true} conditions={conditionList} fields={_fields} fieldValueSettings={_fieldValueSettings} theme="bootstrap" onChanged={actions.filterContidionChanged} fieldSelectorClass="form-control input-sm" operatorSelectorClass="form-control input-sm" languageCode={sdkOptions.props.language} fieldNameField="key" fieldKeyField="key" fieldTextField="label" />
                        <RadioBtn classNames="customer-filters-judgeStrategy-radios" itemClassNames="customer-filters-judgeStrategy-radio-item" options={Udesk.enums.operatorRuleTypes} value={judgeStrategy} onChanged={actions.changeJudgeStrategy} />
                        <If condition={judgeStrategy === Udesk.enums.operatorRuleTypes.custom.id}>
                            <input type="text" className="form-control" defaultValue={customJudgeLogic} onChange={actions.mutator("privates.storages.customJudgeLogic", e => e.target.value)} />
                        </If>
                    </div>
                </ReactModal> */}

                <CustomFilterModal
                    visible={filterVisible}
                    cancel={actions.cancel}
                    applyAction={actions.handleConditionApply}
                    onConditionFilterSaved={actions.onConditionFilterSaved}
                    judgeStrategy={judgeStrategy}
                    customJudgeLogic={customJudgeLogic}
                    customName={customName}
                    customId={customId}
                    conditionList={conditionList}
                    type={this.privates.type}
                    taskId={this.props.match.params.taskId}
                    taskName={this.props.sdkOptions.props.task.name}
                    filterList={['f_8', 'r_1', 'r_2', 'r_3']}
                />

                <Modal
                    title={locales.fix.taskStartPrompt}
                    visible={this.state.showModel}
                    onOk={actions.handleOk}
                    onCancel={actions.handleCancel}
                >
                    <p>{locales.fix.startConfirm}</p>
                </Modal>
                <ReInspectionModel
                    title={locales.components.pages.qualityInspectionList.reQualityInspectionConfig}
                    allPoints={allPoints}
                    reInspectionConfigVisible={reInspectionConfigVisible}
                    reInspectionPoints={reInspectionPoints}
                    reInspectionType={reInspectionType}
                    onOk={actions.reInspection}
                    onPointsCheckedChange={actions.onPointsCheckedChange}
                    reInspectionTypeChanged={actions.reInspectionTypeChanged}
                    onCancel={actions.changeReInspectionConfigVisible}
                    okButtonLoading={reCheckLoading}
                />
                <ReInspectionResultModel
                    history={this.props.history}
                    visible={reInspectionModalVisible}
                    onVisibleChange={(visible) => {
                        this.privates.reInspectionModalVisible = visible;
                        this.actions.update();
                    }}
                    taskId={parseInt(this.props.match.params.taskId, 10)}
                />
            </div>
        );
    }
}
