import React from 'react';
// import Locales from 'UdeskLocales';
import { Avatar, Icon } from 'udesk-ui';
import styled from 'styled-components';
import { IntelligentPartnerCustomerPersonaFoundResponse } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

// const Div = styled.div``;
interface CustomerPortraitItemProps extends IntelligentPartnerCustomerPersonaFoundResponse {
    onClick?: () => void;
}
const Div = styled.div`
    display: inline-block;
    cursor: pointer;
`;
export const AddCustomerPortraitItemBtn: React.FC<CustomerPortraitItemProps> = (props) => {
    // const locales = Locales['current'];
    const { onClick } = props;

    return (
        <Div onClick={onClick}>
            <Avatar
                style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                alt={/* 新建 */UdeskLocales['current'].components.coach.customerPortrait.list.components.addCustomerPortraitItemBtn.index.newlyBuild}
                size="large"
                icon={<Icon type="PlusOutlined" antdIcon={true} />}
            ></Avatar>
        </Div>
    );
};
