// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetKnowledgeBaseByIdQuery,
    GetKnowledgeBaseByIdParam,
    BaseResponseListKnowledgePointDetailFoundResponse,
    DeleteKnowledgeBaseByIdParam,
    BaseResponseVoid,
} from '../../types';

/**
 * 知识库知识点列表
 *
 * @export
 * @tags 知识库分类接口
 * @link [GET] /knowledgeBase/{id}
 * @param id
 */
export function getKnowledgeBaseById(
    options: ApiOptions<GetKnowledgeBaseByIdParam, GetKnowledgeBaseByIdQuery> & {
        segments: GetKnowledgeBaseByIdParam;
    }
): Promise<BaseResponseListKnowledgePointDetailFoundResponse> {
    return request<never, GetKnowledgeBaseByIdParam, GetKnowledgeBaseByIdQuery>(
        '/knowledgeBase/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getKnowledgeBaseById' }
    );
}

/**
 * 删除知识库分类
 *
 * @export
 * @tags 知识库分类接口
 * @link [DELETE] /knowledgeBase/{id}
 * @param id
 */
export function deleteKnowledgeBaseById(
    options: ApiOptions<DeleteKnowledgeBaseByIdParam, never> & {
        segments: DeleteKnowledgeBaseByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteKnowledgeBaseByIdParam, never>('/knowledgeBase/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteKnowledgeBaseById',
    });
}

export const meta = [
    { tags: ['知识库分类接口'], path: '/knowledgeBase/{id}', method: 'get' },
    { tags: ['知识库分类接口'], path: '/knowledgeBase/{id}', method: 'delete' },
];
