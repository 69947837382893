import Udesk from "Udesk";
import React from "react";
import TaskOperatorListGatherComponent from "./component";
import AutoComplete from 'udesk-react/src/components/auto-complete';
import ReactModal from 'udesk-react/src/components/react-modal';
import ReactTable from 'udesk-react/src/components/react-table';
import { Select } from 'udesk-ui';
import "./style.scss";

export default class TaskOperatorListGatherTemplate extends TaskOperatorListGatherComponent {
    render() {
        let { actions, locales, props, state, privates } = this;
        return (
            <div className="task-operator-list-gather-component">
                <For each="operatorItem" index="index" of={privates.operatorList}>
                    <div key={operatorItem._id} className="operator-list-item-box">
                        <div className="operator-list-item-index">
                            {index + 1}
                        </div>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.type}
                            className="form-control operator-list-item-type"
                            onChange={actions.changeOperatorItemType.params(operatorItem)}
                        >
                            <For each="item" index="index" of={props.operatorTypes}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                        {/* 1、关键词匹配 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.keyword.id}>
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.operator}
                                className="form-control operator-list-item-operator"
                                onChange={actions.changeOperatorItem.params(operatorItem, "operator")}
                            >
                                <For each="item" index="index" of={Udesk.enums.keywordOperators}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                            <input
                                type="text"
                                disabled={props.disabled}
                                className="form-control operator-list-item-keyword"
                                value={operatorItem.keyword}
                                onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                            />
                        </If>
                        {/* 2、疑问句 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.question.id}></If>
                        {/* 3、正则表达式 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.regular.id}>
                            <input
                                type="text"
                                disabled={props.disabled}
                                className="form-control operator-list-item-regular"
                                value={operatorItem.keyword}
                                onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                                placeholder={locales.components.operatorListGather.pleaseEnter}
                            />
                            <div className="operator-list-item-test-container-box">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-default operator-list-item-button"
                                >
                                    {locales.components.operatorListGather.testRegular}
                                </button>
                                <div className="operator-list-item-test-container">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={operatorItem._testText}
                                        onChange={actions.changeInputValue.params(operatorItem, "_testText")}
                                        onFocus={actions.cleanTestResult.params(operatorItem)}
                                    />
                                    <span
                                        className="operator-list-item-test-container-action pull-left"
                                        onClick={actions.regularTest.params(operatorItem)}
                                    >
                                        {locales.components.operatorListGather.test}
                                    </span>
                                    <span className="pull-right">{operatorItem._testResult}</span>
                                </div>
                            </div>
                        </If>
                        {/* 4、上下文重复 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.context.id}>
                            <input
                                type="text"
                                disabled={props.disabled}
                                className="form-control operator-list-item-context"
                                value={operatorItem.keyword}
                                onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                                placeholder={locales.components.operatorListGather.pleaseEnterRegular}
                            />
                            <div className="operator-list-item-apply-role-box">
                                <span className="operator-list-item-apply-role-tip">
                                    {locales.components.operatorListGather.afterHit}
                                </span>
                                <Select
                                    disabled={props.disabled}
                                    value={operatorItem.applyRole}
                                    className="form-control operator-list-item-apply-role"
                                    onChange={actions.changeOperatorItem.params(operatorItem, "applyRole")}
                                >
                                    <For each="item" index="index" of={Udesk.enums.applyRoles}>
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    </For>
                                </Select>
                            </div>
                        </If>
                        {/* 5、语义匹配 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.semantics.id}>
                            <textarea
                                disabled={props.disabled}
                                className="form-control operator-list-item-semantics"
                                rows={3}
                                value={operatorItem.keyword}
                                onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                                placeholder={locales.components.operatorListGather.pleaseEnterContext}
                            />
                            <div className="operator-list-item-similarity-score-box">
                                <span className="operator-list-item-similarity-score-tip">
                                    {locales.components.operatorListGather.similarity}
                                </span>
                                <input
                                    type="number"
                                    disabled={props.disabled}
                                    min="0"
                                    className="form-control operator-list-item-similarity-score"
                                    value={operatorItem.similarityScore}
                                    onChange={actions.changeInputValue.params(operatorItem, "similarityScore")}
                                    onBlur={actions.validateMinValue.params(
                                        operatorItem,
                                        "similarityScore",
                                        operatorItem.similarityScore
                                    )}
                                />
                                %
                            </div>
                        </If>
                        {/* 6、字数相似句 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.word.id}>
                            <input
                                type={"text"}
                                disabled={props.disabled}
                                className={"form-control operator-list-item-word-number-input"}
                                value={operatorItem.keyword}
                                onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                                placeholder={locales.components.operatorListGather.pleaseEnter}
                            />
                            <div className="operator-list-item-word-number-box">
                                <span className="operator-list-item-word-number-tip">
                                    {locales.components.operatorListGather.wordNumber}
                                </span>
                                <input
                                    type={"number"}
                                    disabled={props.disabled}
                                    min={"0"}
                                    className={"form-control operator-list-item-differ-word-number"}
                                    value={operatorItem.differWordNumber}
                                    onChange={actions.changeInputValue.params(operatorItem, "differWordNumber")}
                                    onBlur={actions.validateMinValue.params(
                                        operatorItem,
                                        "differWordNumber",
                                        operatorItem.differWordNumber
                                    )}
                                />
                            </div>
                        </If>
                        {/* 7、对话时间间隔 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.dialogue.id}>
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.applyScope}
                                className="form-control operator-list-item-apply-scopes"
                                onChange={actions.changeOperatorItem.params(operatorItem, "applyScope")}
                            >
                                <For each="item" index="index" of={Udesk.enums.dialogueApplyScopes}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                            <div className="operator-list-item-interval-second-box">
                                <span className="operator-list-item-interval-second-tip">
                                    {locales.components.operatorListGather.intervalSecond}
                                </span>
                                <input
                                    type="number"
                                    disabled={props.disabled}
                                    min="0"
                                    className="form-control operator-list-item-interval-second"
                                    value={operatorItem.intervalSecond}
                                    onChange={actions.changeInputValue.params(operatorItem, "intervalSecond")}
                                    onBlur={actions.validateMinValue.params(
                                        operatorItem,
                                        "intervalSecond",
                                        operatorItem.intervalSecond
                                    )}
                                />
                                {locales.labels.second}
                            </div>
                        </If>
                        {/* 8、语速检测 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.speed.id}>
                            <div className="operator-list-item-word-per-minute-box">
                                {locales.components.operatorListGather.minute}
                                {locales.components.operatorListGather.notLessThan}
                                <input
                                    type="number"
                                    disabled={props.disabled}
                                    min="0"
                                    className="form-control speed-input-su operator-list-item-word-per-minute"
                                    value={operatorItem.wordPerMinite}
                                    onChange={actions.changeInputValue.params(operatorItem, "wordPerMinite")}
                                    placeholder={locales.components.operatorListGather.pleaseEnter}
                                    onBlur={actions.validateSpeedValue.params(
                                        operatorItem,
                                        "wordPerMinite",
                                        operatorItem.wordPerMinite
                                    )}
                                />
                                {locales.components.operatorListGather.wordUnit}
                                {locales.components.operatorListGather.notGreaterThan}
                                <input
                                    type="number"
                                    disabled={props.disabled}
                                    min="0"
                                    className="form-control speed-input-su  operator-list-item-word-per-minute"
                                    value={operatorItem.maxWordPerMinite}
                                    onChange={actions.changeInputValue.params(operatorItem, "maxWordPerMinite")}
                                    placeholder={locales.components.operatorListGather.pleaseEnter}
                                    onBlur={actions.validateSpeedValue.params(
                                        operatorItem,
                                        "maxWordPerMinite",
                                        operatorItem.maxWordPerMinite
                                    )}
                                />
                                {locales.components.operatorListGather.wordUnit}
                            </div>
                        </If>
                        {/* 9、抢话检测 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.grabDetection.id}>
                            <div className="operator-list-item-grab-detection-box">
                                {locales.components.operatorListGather.speechGrabbingIntervalTip}
                                <input
                                    type="number"
                                    disabled={props.disabled}
                                    className="form-control operator-list-item-grab-input"
                                    value={operatorItem.grabIntervalTime}
                                    onChange={actions.changeInputValue.params(operatorItem, "grabIntervalTime")}
                                    placeholder={locales.components.operatorListGather.pleaseEnter}
                                    onBlur={actions.validateGrabInterval.params(
                                        operatorItem,
                                        "grabIntervalTime",
                                        operatorItem.grabIntervalTime
                                    )}
                                />
                                {locales.components.operatorListGather.msUnit}
                                {locales.components.operatorListGather.speechLengthTip}
                                <input
                                    type="number"
                                    disabled={props.disabled}
                                    min="0"
                                    className="form-control operator-list-item-grab-input"
                                    value={operatorItem.grabMaxSpeakTime}
                                    onChange={actions.changeInputValue.params(operatorItem, "grabMaxSpeakTime")}
                                    placeholder={locales.components.operatorListGather.pleaseEnter}
                                    onBlur={actions.validateGrabLength.params(
                                        operatorItem,
                                        "grabMaxSpeakTime",
                                        operatorItem.grabMaxSpeakTime
                                    )}
                                />
                                {locales.components.operatorListGather.msUnit}
                            </div>
                        </If>
                        {/* 10、语义标签 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.semanticTags.id}>
                            <div className="operator-list-item-semantic-tags-container">
                                <div className="operator-list-item-semantic-tags-search-container">
                                    <input
                                        type="text"
                                        disabled={props.disabled}
                                        className="form-control operator-list-item-semantic-tags-sentence"
                                        value={operatorItem.semanticSentence}
                                        onChange={actions.changeInputValue.params(operatorItem, "semanticSentence")}
                                        placeholder={locales.components.operatorListGather.pleaseEnterSemanticSentence}
                                    />
                                    <button
                                        type="button"
                                        disabled={props.disabled}
                                        className="btn btn-sm btn-default operator-list-item-button"
                                        onClick={actions.changeSemanticTagsModalVisible.params(operatorItem)}
                                    >
                                        {locales.components.operatorListGather.querySemanticTags}
                                    </button>
                                </div>
                                <div className="operator-list-item-semantic-tags-result-container">
                                    <span className="operator-list-item-semantic-tags-result-tag">
                                        {locales.components.operatorListGather.semanticTags}
                                        {locales.labels.colon}
                                        {operatorItem.semanticTag.semanticName}
                                    </span>
                                    <span className="operator-list-item-semantic-tags-result-sentence">
                                        {locales.components.operatorListGather.standardSentence}
                                        {locales.labels.colon}
                                        {operatorItem.semanticTag.sentence}
                                    </span>
                                </div>
                            </div>
                        </If>
                        {/* 11、关键词匹配（严格） */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.keywordStrict.id}>
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.operator}
                                className="form-control operator-list-item-operator"
                                onChange={actions.changeOperatorItem.params(operatorItem, "operator")}
                            >
                                <For each="item" index="index" of={Udesk.enums.keywordOperators}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                            <input
                                type="text"
                                disabled={props.disabled}
                                className="form-control operator-list-item-keyword"
                                value={operatorItem.keyword}
                                onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                            />
                        </If>
                        {/* 13、信息实体检测 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.entityInfoDetection.id}>
                            <div className="operator-list-item-entity-info-container">
                                <div className="operator-list-item-entity-field-container">
                                    <AutoComplete
                                        disabled={props.disabled}
                                        url={privates.entityUrl}
                                        value={operatorItem.param.entity}
                                        method={"POST"}
                                        // extraQueryParams={{ activeFlag:1, systemType: Udesk.data.init.company.systemType}}
                                        extraQueryParams={{ activeFlag:1 }}
                                        queryParamName={"keyword"}
                                        onChanged={actions.changeOperatorItem.params(operatorItem.param, "entity")}
                                        enableEmptySearch={true}
                                        placeholder={locales.components.operatorListGather.pleaseSelectEntity}
                                    />
                                </div>
                                <div className="operator-list-item-entity-operator-container">
                                    <Select
                                        disabled={props.disabled}
                                        value={operatorItem.param.operator}
                                        className="form-control operator-list-item-entity-operator"
                                        onChange={actions.changeOperatorItem.params(operatorItem.param, "operator")}
                                    >
                                        <Select.Option value={null}>
                                            {locales.components.operatorListGather.pleaseSelectOperator}
                                        </Select.Option>
                                        <For each="item" index="index" of={Udesk.enums.entityOperators}>
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        </For>
                                    </Select>
                                </div>
                                <div className="operator-list-item-entity-value-container">
                                    <Choose>
                                        <When condition={
                                            (operatorItem.param.operator === null) ||
                                            (operatorItem.param.operator === Udesk.enums.entityOperators.equals.id) ||
                                            (operatorItem.param.operator === Udesk.enums.entityOperators.notEquals.id) ||
                                            (operatorItem.param.operator === Udesk.enums.entityOperators.contains.id) ||
                                            (operatorItem.param.operator === Udesk.enums.entityOperators.notContains.id)
                                        }>
                                            {/* <PowerSelect
                                                disabled={props.disabled}
                                                dataSource={props.entityFields}
                                                nameField="label"
                                                value={operatorItem.param.expectedValueObject}
                                                placeholder={locales.components.operatorListGather.pleaseSelectBasicInfo}
                                                onChanged={actions.changeOperatorItem.params(operatorItem.param, "expectedValueObject")}
                                            /> */}
                                            <AutoComplete
                                                disabled={props.disabled}
                                                defaultOptions={props.entityFields}
                                                value={operatorItem.param.expectedValueObject}
                                                nameField="label"
                                                placeholder={locales.components.operatorListGather.pleaseSelectBasicInfo}
                                                onChanged={actions.changeOperatorItem.params(operatorItem.param, "expectedValueObject")}
                                                isFilterSelectedOption={true}
                                            />
                                        </When>
                                        <Otherwise>
                                            <input
                                                type="text"
                                                disabled={props.disabled}
                                                className="form-control"
                                                value={operatorItem.param.expectedValue}
                                                onChange={actions.changeInputValue.params(operatorItem.param, "expectedValue")}
                                                placeholder={locales.components.operatorListGather.pleaseEnterFixedValue}
                                            />
                                        </Otherwise>
                                    </Choose>
                                </div>
                            </div>
                        </If>
                        {/* 14、基础信息检测 */}
                        {/* {{#if (eq operatorItem.type Udesk.enums.operatorTypes.basicInfoDetection.id)}}
                            <div className="operator-list-item-basic-info-container">
                                <div className="operator-list-item-basic-field-container">
                                    {{#power-select options=entityFields selected=operatorItem.param.field searchField="label" placeholder=UDL.components.operatorListGather.pleaseSelectBasicInfo onValueChanged=(action "triggerListChanged") as |item| }}
                                        {{item.label}}
                                    {{/power-select}}
                                </div>
                                <div className="operator-list-item-basic-operator-container">
                                    {{#x-select value=operatorItem.param.operator className="form-control operator-list-item-basic-operator" onChange=(action 'triggerListChanged') as |xs|}}
                                        {{#xs.option value=null}}
                                            {{UDL.components.operatorListGather.pleaseSelectOperator}}
                                        {{/xs.option}}
                                        {{#each Udesk.enums.basicInfoOperators as |item|}}
                                            {{#xs.option value=item.id}}
                                                {{item.name}}
                                            {{/xs.option}}
                                        {{/each}}
                                    {{/x-select}}
                                </div>
                                <div className="operator-list-item-basic-value-container">
                                    {{input type="text" className="form-control" value=operatorItem.param.expectedValue placeholder=UDL.components.operatorListGather.pleaseEnterFixedValue focus-out=(action "triggerListChanged")}}
                                </div>
                            </div>
                        {{/if}} */}
                        {/* 15、音量检测 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.volume.id}>
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.param.detectionWay}
                                className="form-control operator-list-item-volume-detection-way"
                                onChange={actions.changeOperatorItem.params(operatorItem.param, "detectionWay")}
                            >
                                <For each="item" index="index" of={Udesk.enums.volumeDetectionWays}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.param.operator}
                                className="form-control operator-list-item-volume-detection-operator"
                                onChange={actions.changeOperatorItem.params(operatorItem.param, "operator")}
                            >
                                <For each="item" index="index" of={Udesk.enums.volumeDetectionOperators}>
                                    <Select.Option value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                            <div className="operator-list-item-volume-detection-value-box">
                                <Choose>
                                    <When condition={operatorItem.param.detectionWay === Udesk.enums.volumeDetectionWays.loudness.id}>
                                        <input
                                            type="number"
                                            disabled={props.disabled}
                                            min="0"
                                            max="200"
                                            step="0.01"
                                            className="form-control operator-list-item-volume-detection-value"
                                            value={operatorItem.param.detectionValue}
                                            onChange={actions.changeInputValue.params(operatorItem.param, "detectionValue")}
                                            placeholder={locales.components.operatorListGather.pleaseEnterLoudness}
                                            onBlur={actions.validateVolumDetectionValue.params(
                                                operatorItem,
                                                operatorItem.param.detectionValue
                                            )}
                                        />
                                        {locales.components.operatorListGather.loudnessUnit}
                                    </When>
                                    <Otherwise>
                                        <input
                                            type="number"
                                            disabled={props.disabled}
                                            min="-1000"
                                            max="1000"
                                            step="0.01"
                                            className="form-control operator-list-item-volume-detection-value"
                                            value={operatorItem.param.detectionValue}
                                            onChange={actions.changeInputValue.params(operatorItem.param, "detectionValue")}
                                            placeholder={locales.components.operatorListGather.pleaseEnterRangeAbility}
                                            onBlur={actions.validateVolumDetectionValue.params(
                                                operatorItem,
                                                operatorItem.param.detectionValue
                                            )}
                                        />
                                        {locales.components.operatorListGather.percent}
                                    </Otherwise>
                                </Choose>
                            </div>
                        </If>
                        {/* 16、情绪分析 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.emotion.id}>
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.param.operator}
                                className="form-control operator-list-item-type"
                                onChange={actions.changeOperatorItem.params(operatorItem.param, "operator")}
                            >
                                <For each="item" index="index" of={Udesk.enums.emotionOperators}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.param.sentiment}
                                className="form-control operator-list-item-operator"
                                onChange={actions.changeOperatorItem.params(operatorItem.param, "sentiment")}
                            >
                                <For each="item" index="index" of={Udesk.enums.sentimentTypes}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select>
                        </If>
                        {/* 17、文法规则 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.grammar.id}>
                            <textarea
                                disabled={props.disabled}
                                className="form-control operator-list-item-semantics"
                                rows={3}
                                value={operatorItem.keyword}
                                onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                                placeholder={locales.components.operatorListGather.pleaseEnter}
                            />
                            <div className="operator-list-item-test-container-box">
                                <button type="button" className="btn btn-sm btn-default operator-list-item-button">
                                    {locales.components.operatorListGather.testGrammar}
                                </button>
                                <div className="operator-list-item-test-container">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={operatorItem._testText}
                                        onChange={actions.changeInputValue.params(operatorItem, "_testText")}
                                        onFocus={actions.cleanTestResult.params(operatorItem)}
                                    />
                                    <span
                                        className="operator-list-item-test-container-action pull-left"
                                        onClick={actions.grammarTest.params(operatorItem)}
                                    >
                                        {locales.components.operatorListGather.test}
                                    </span>
                                    <span className="pull-right">{operatorItem._testResult}</span>
                                </div>
                            </div>
                        </If>
                        {/* 18、句长检测 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.sentenceLength.id}>
                            <div className="operator-list-item-word-per-minute-box">
                                <Select
                                    disabled={props.disabled}
                                    value={operatorItem.param.compareType}
                                    className="form-control operator-list-item-operator"
                                    onChange={actions.changeOperatorItem.params(operatorItem.param, "compareType")}
                                >
                                    <For each="item" index="index" of={Udesk.enums.sentenceLength}>
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    </For>
                                </Select>
                                <input
                                    type="number"
                                    disabled={props.disabled}
                                    min="0"
                                    className="form-control operator-list-item-word-per-minute"
                                    placeholder={locales.components.operatorListGather.pleaseEnter}
                                    value={operatorItem.param.wordsNumber}
                                    onChange={actions.changeInputValue.params(operatorItem.param, "wordsNumber")}
                                />
                                {locales.components.operatorListGather.wordUnit}
                            </div>
                        </If>
                        {/* 20、概念词 */}
                        <If condition={operatorItem.type === Udesk.enums.operatorTypes.conceptWord.id}>
                            <div class="operator-list-item-entity-info-container">
                                <div class="operator-list-item-entity-operator-container">
                                    <Select
                                        disabled={props.disabled}
                                        value={operatorItem.param.compareType}
                                        className="form-control operator-list-item-entity-operator"
                                        onChange={actions.changeOperatorItem.params(operatorItem.param, "compareType")}
                                    >
                                        <For each="item" index="index" of={Udesk.enums.conceptWordOperators}>
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        </For>
                                    </Select>
                                </div>
                                <div class="operator-list-item-entity-field-container">
                                    <AutoComplete
                                        disabled={props.disabled}
                                        url={privates.conceptWordUrl}
                                        value={operatorItem.param.conceptWord}
                                        method={"POST"}
                                        queryParamName={"keyword"}
                                        onChanged={actions.conceptWordChanged.params(operatorItem)}
                                        enableEmptySearch={true}
                                        placeholder={locales.components.operatorListGather.conceptWordholder}
                                    />
                                </div>
                            </div>
                        </If>
                        <If condition={!props.disabled}>
                            <i
                                className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-delete_minus operator-list-item-icon"
                                onClick={actions.deleteOperatorItem.params(operatorItem)}
                            ></i>
                        </If>
                    </div>
                </For>
                <If condition={!props.disabled}>
                    <i
                        className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1 operator-list-item-icon"
                        onClick={actions.addNewOperatorItem}
                    ></i>
                </If>
                <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save}
                    headerClassName="udesk-qa-modal-default-header"
                    title={locales.components.operatorListGather.querySemanticTags}
                    visible={state.isSemanticTagsModalVisible}
                    onCancel={actions.changeSemanticTagsModalVisible.params(
                        state.operatorItem
                    )}
                    footer={false}
                >
                    <div className="bs-modal-base">
                        <div className="content-box semantic-tags-modal-container">
                            <div className="semantic-tags-search-container">
                                <input
                                    type="text"
                                    className="form-control semantic-tags-search-input"
                                    value={state.semanticSentence}
                                    placeholder={locales.components.operatorListGather.pleaseEnterSemanticSentence}
                                    onChange={actions.changeSemanticSentence}
                                />
                                <button
                                    type="button"
                                    style={{ marginLeft: "5px" }}
                                    className="btn btn-sm btn-default semantic-tags-search-button"
                                    onClick={actions.querySemanticTags.params(state.semanticSentence)}
                                >
                                    {locales.components.operatorListGather.querySemanticTags}
                                </button>
                            </div>
                            <div className="semantic-tags-result-container">
                                <ReactTable columns={state.columns} items={state.semanticTags} />
                            </div>
                            <div className="semantic-tags-tip-container">
                                <span>{locales.components.operatorListGather.tipOne}</span>
                                <span
                                    className="semantic-tags-tip-action"
                                    onClick={actions.openCreateTagWindow}
                                >
                                    {locales.components.operatorListGather.tipTwo}
                                </span>
                            </div>
                            <div className="semantic-tags-buttons-container form-group action">
                                <button
                                    className="btn white pull-right"
                                    onClick={actions.changeSemanticTagsModalVisible.params(
                                        state.operatorItem
                                    )}
                                >
                                    {locales.labels.cancel}
                                </button>
                                <button
                                    className="btn primary pull-right save-position-btn"
                                    onClick={actions.changeSemanticTagsModalVisible.params(
                                        state.operatorItem
                                    )}
                                >
                                    {locales.labels.ok}
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}
