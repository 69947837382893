import { useState} from 'react';
import {useLiveEffect} from './util';

const finalValue = {
    folderData: [],
    leafData: []
};

export const useSupervisionTreeData = (request) => {
    const [pointTreeData, setPointTreeData] = useState(finalValue);
    const [pointTreeCache, setPointTreeCache] = useState();

    useLiveEffect(() => {
        const url = 'supervisionRuleConfigs/findWithDetail';

        return request(url, {}).then((r) => {
            const folderCache = {};
            const leafCache = {};

            const next = (list, parentId) => {
                list.forEach((item) => {
                    if (item.id) {
                        const folder = {
                            id: item.id,
                            name: item.categoryName
                        };
                        if (parentId !==undefined) {
                            folder.parentId = parentId;
                        }
                        if (item.pointList && item.pointList.length > 0) {
                            folder.points = item.pointList.map((leaf) => {
                                leafCache[leaf.id] = {
                                    id: leaf.id,
                                    name: leaf.name
                                };
                                return leaf.id;
                            });
                        }
    
                        folderCache[folder.id] = folder;
                    }

                    if (item.categoryList && item.categoryList.length > 0) {
                        next(item.categoryList, item.id !== undefined ? item.id : undefined);
                    }
                });
            };
            if (r.data) {
                next(r.data);
            }
            setPointTreeCache(leafCache);
            setPointTreeData({
                folderData: Reflect.ownKeys(folderCache).map(key => folderCache[key]),
                leafData: Reflect.ownKeys(leafCache).map(key => leafCache[key])
            });
        });
    }, []);

    return [pointTreeData, pointTreeCache];
};


export const useUserTreeData = (request) => {
    const [userTreeData, setUserTreeData] = useState(finalValue);

    useLiveEffect(() => {
        const url = 'organizations/users';
        return request(url, {}).then((r) => {
            const folderCache = {};
            const leafCache = {};

            const next = (list) => {
                list.forEach((item) => {
                    const folder = {
                        id: item.id,
                        name: item.name,
                        parentId: item.parentId,
                    };
                    if (item.users && item.users.length > 0) {
                        folder.users = item.users.filter(user => !!user.agentId).map((user) => {
                            leafCache[user.agentId] = {
                                id: user.agentId,
                                name: user.realname
                            };
                            return user.agentId;
                        });
                    }

                    folderCache[folder.id] = folder;
                });
            };
            if (r.data) {
                next(r.data);
            }

            setUserTreeData({
                folderData: Reflect.ownKeys(folderCache).map(key => folderCache[key]),
                leafData: Reflect.ownKeys(leafCache).map(key => leafCache[key])
            });
        });
    }, []);

    return [userTreeData];
};

export const useKeyEventTreeData = (request) => {
    const [keyEventTreeData, setKeyEventTreeData] = useState(finalValue);
    const [keyEventTreeCache, setKeyEventTreeCache] = useState();

    useLiveEffect(() => {
        let url = `smartTagCategorys/keyEvents/search`;
        request(url).then((r) => {
            const folderCache = {};
            const leafCache = {};

            const next = (list) => {
                list.forEach((item, index) => {
                    const folder = {
                        id: index,
                        name: item.categoryName
                    };
                    if (item.smartTagList && item.smartTagList.length > 0) {
                        folder.smartTags = item.smartTagList.map((leaf) => {
                            leafCache[leaf.id] = {
                                id: leaf.id,
                                name: leaf.tagName
                            };
                            return leaf.id;
                        });
                    }

                    folderCache[folder.id] = folder;
                });
            };
            if (r.data) {
                next(r.data);
            }
            setKeyEventTreeCache(leafCache);
            setKeyEventTreeData({
                folderData: Reflect.ownKeys(folderCache).map(key => folderCache[key]),
                leafData: Reflect.ownKeys(leafCache).map(key => leafCache[key])
            });
        });
    }, []);

    return [keyEventTreeData, keyEventTreeCache];
};


export const useDetailSelectDataSource = (request) => {
    const [pointTreeData] = useSupervisionTreeData(request);
    const [keyEventTreeData] = useKeyEventTreeData(request);
    const [userTreeData] = useUserTreeData(request);

    return [userTreeData, pointTreeData, keyEventTreeData];
};

export const useAnalysisSelectDataSource = (request) => {
    const [, pointTreeCache] = useSupervisionTreeData(request);
    const [, keyEventTreeCache] = useKeyEventTreeData(request);

    return [pointTreeCache, keyEventTreeCache];
};