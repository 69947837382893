import { useCallback, useMemo, useState } from 'react';
import cloneDeep from 'lodash-es/cloneDeep';

type Record = {
    redo: Function;
    undo: Function;
};

export const useStep = () => {
    const [pointer, setPointer] = useState(-1);

    const [queue, setQueue] = useState<Record[]>([]);

    const undoAble = useMemo(() => {
        return queue.length > 0 && pointer >= 0;
    }, [queue, pointer]);

    const redoAble = useMemo(() => {
        return queue.length > 0 && pointer < queue.length - 1;
    }, [queue, pointer]);

    const record = useCallback(
        (data: Record) => {
            const q = cloneDeep(queue);
            while (pointer < q.length - 1) {
                q.pop();
            }
            setQueue([...q, data]);
            setPointer(pointer + 1);
        },
        [queue, pointer]
    );

    const undo = useCallback(
        (step = 1) => {
            const action = queue[pointer || 0];
            setPointer(pointer - step);
            return action.undo();
        },
        [queue, pointer]
    );

    const redo = useCallback(
        (step = 1) => {
            const p = pointer + step;
            const action = queue[p || 0];
            setPointer(p);
            return action.redo();
        },
        [queue, pointer]
    );

    return {
        undoAble,
        redoAble,
        record,
        undo,
        redo,
    };
};
