import Udesk from 'Udesk';
import SdkLoaderBase from 'Component/common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';
// import UdeskLocales from 'UdeskLocales';

function extend(object, attrs) {
    Reflect.ownKeys(attrs).forEach((key) => {
        Reflect.set(object, key, attrs[key]);
    });
    return object;
}

class AgentReportComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: "group-chat-analysis-report",
        pageTitle: /* 企业微信群聊分析 */UdeskLocales['current'].pages.tasks.manage.reports.groupChatAnalysis.index.analysisOfEnterpriseWeChatGroupChat
    };
    constructor(props) {
        super(props);

        const getRenderToOptions = this.actions.getRenderToOptions;

        extend(this.actions, {
            getRenderToOptions: () => {
                const options = getRenderToOptions.call(this);
                // 这里重新定义sdk传入的方法
                extend(options.methods, {
                    initQaRequest: () => {
                        const {sdkOptions} = this.props;
                        return (url, params, method= 'get') => {
                            const httpUrl = Udesk.business.apiPath.concatApiPath(
                                url,
                                sdkOptions,
                                sdkOptions.props.domain
                            );
                            return Udesk.ajax[method](httpUrl, params);
                        };
                    },
                });
                return options;
            }
        });
    }
}

export default Udesk.react.udeskify(AgentReportComponent);