import adminMenuList from "./admin-menu-list";
import adminTaskMenuList from "./admin-task-menu-list";
import buildUser from "./build-user";
import buildCompany from "./build-company";

function buildInitData(initData) {
    this.init = initData;
    this.adminMenuList.init(initData.allMenuList);
    this.adminTaskMenuList.init(initData.allTaskMenuList);
}

function clearData() {
    this.init = undefined;
    this.adminMenuList.clear();
    this.adminTaskMenuList.clear();
}

export default {
    init: undefined,
    buildInitData,
    adminMenuList,
    adminTaskMenuList,
    buildUser,
    buildCompany,
    clearData,
};
