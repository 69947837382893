import React from "react";
import Udesk from 'Udesk';
class CustomerServiceGrounpComponent extends React.Component {
    privates = {
        data: [],
        grounpFlag: {},
        expandedKeys: [],
        selectedKeys: [],
        group: [],
        inputFocus: false,
    };
    actions = {
        onDrop(info) {
            const dropKey = info.node.props.eventKey;
            const dragKey = info.dragNode.props.eventKey;
            const dropPos = info.node.props.pos.split("-");
            const dropPosition =
                info.dropPosition - Number(dropPos[dropPos.length - 1]);

            const loop = (data, key, callback) => {
                data.forEach((item, index, arr) => {
                    if (item.key === key) {
                        return callback(item, index, arr);
                    }
                    if (item.children) {
                        return loop(item.children, key, callback);
                    }
                });
            };
            const data = [...this.privates.data];

            // Find dragObject
            let dragObj;
            loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item;
            });

            if (!info.dropToGap) {
                // Drop on the content
                loop(data, dropKey, (item) => {
                    item.children = item.children || [];
                    // where to insert 示例添加到尾部，可以是随意位置
                    item.children.push(dragObj);
                });
            } else if (
                (info.node.props.children || []).length > 0 && // Has children
                info.node.props.expanded && // Is expanded
                dropPosition === 1 // On the bottom gap
            ) {
                loop(data, dropKey, (item) => {
                    item.children = item.children || [];
                    // where to insert 示例添加到头部，可以是随意位置
                    item.children.unshift(dragObj);
                });
            } else {
                let ar;
                let i;
                loop(data, dropKey, (item, index, arr) => {
                    ar = arr;
                    i = index;
                });
                if (dropPosition === -1) {
                    ar.splice(i, 0, dragObj);
                } else {
                    ar.splice(i + 1, 0, dragObj);
                }
            }
            this.privates.data = data;
            this.actions.update();
        },
        onDragEnter(info) {
            // expandedKeys 需要受控时设置
            // this.setState({
            //   expandedKeys: info.expandedKeys,
            // });
        },
        onDragStart(info, node) {
            // let {}
            // let key = info.node.props.eventKey;
        },
        groupNodeEdit(item, e) {
            e.stopPropagation();
            let { grounpFlag } = this.privates;
            let fields = true;
            for (let key in grounpFlag) {
                if (grounpFlag[key]) {
                    fields = false;
                    Udesk.ui.notify.error(this.locales.fix.editingNameNow);
                    break;
                }
            }
            if (fields) {
                this.privates.grounpFlag[item.key] = true;
            }
            this.actions.update();
        },
        groupNodeAdd(item, e) {
            e.stopPropagation();
            let { grounpFlag } = this.privates;
            let fields = true;
            this.privates.selectedKeys = [item.key];
            for (let key in grounpFlag) {
                if (grounpFlag[key]) {
                    fields = false;
                    Udesk.ui.notify.error(this.locales.fix.editingNameNow);
                    break;
                }
            }
            if (fields) {
                let { data } = this.privates;
                if (item.level === 4) {
                    Udesk.ui.notify.error(this.locales.fix.upToFourLevels);
                } else {
                    this.actions.grounpDataMap(data, 3, item.id);
                }
            }
            this.actions.update();
        },
        groupNodeDel(item, e) {
            e.stopPropagation();
            let { grounpFlag } = this.privates;
            let fields = true;
            for (let key in grounpFlag) {
                if (grounpFlag[key]) {
                    fields = false;
                    Udesk.ui.notify.error(this.locales.fix.editingNameNow);
                    break;
                }
            }
            if (fields) {
                this.props.groupNodeDelChange(item.id);
            }
        },
        grounpDataMap(data, type, text, id) {
            let { grounpFlag, expandedKeys } = this.privates;
            if (type === 1) {
                if (data && data.length > 0) {
                    data.forEach((item) => {
                        grounpFlag[item.key] = false;
                        if (item.children) {
                            this.actions.grounpDataMap(item.children, 1);
                        }
                    });
                }
            } else if (type === 2) {
                if (data && data.length > 0) {
                    data.forEach((item) => {
                        if (item.id === id) {
                            item.title = text;
                        }
                        if (item.children) {
                            this.actions.grounpDataMap(item.children, 2, text, id);
                        }
                    });
                }
            } else if (type === 3) {
                if (data && data.length > 0) {
                    data.forEach((item) => {
                        if (item.id === text) {
                            if (item.children) {
                                item.children = [
                                    ...item.children,
                                    {
                                        title: this.locales.fix.default,
                                        key: "grounp-add",
                                        parentId: item.id,
                                        level: item.level + 1,
                                        nodeType: 3,
                                    },
                                ];
                            } else {
                                item.children = [
                                    {
                                        title: this.locales.fix.default,
                                        key: "grounp-add",
                                        nodeType: 3,
                                        level: item.level + 1,
                                        parentId: item.id,
                                    },
                                ];
                            }
                            this.privates.grounpFlag["grounp-add"] = true;
                            this.privates.expandedKeys = [...expandedKeys, item.key];
                            this.privates.selectedKeys = ["grounp-add"];
                        }
                        if (item.children) {
                            this.actions.grounpDataMap(item.children, 3, text);
                        }
                    });
                }
            } else if (type === 4) {
                if (data && data.length > 0) {
                    for (let i = data.length - 1; i >= 0; i--) {
                        if (data[i].key === "grounp-add") {
                            data.splice(i, 1);
                            break;
                        }
                        data[i].children && this.actions.grounpDataMap(data[i].children, 4);
                    }
                }
            }
        },
        onSelect(value, e) {
            let { selectedKeys } = this.privates;
            if (!this.privates.inputFocus) {
                let { grounpFlag } = this.privates;
                let fields = true;
                for (let key in grounpFlag) {
                    if (grounpFlag[key]) {
                        fields = false;
                        Udesk.ui.notify.error(this.locales.fix.editingNameNow);
                        break;
                    }
                }
                if (fields) {
                    if (value && value.length > 0) {
                        this.privates.selectedKeys = value;
                        this.props.onSelectSearch(value);
                        this.actions.update();
                    } else {
                        this.props.onSelectSearch(selectedKeys);
                        this.actions.update();
                    }
                }
            }
        },
        grounpChange(item, value) {
            value.stopPropagation();
            let { data } = this.privates;
            if (value.target.value.length === 0) {
                this.actions.grounpDataMap(data, 2, value.target.value, item.id);
                Udesk.ui.notify.error(this.locales.fix.nameCantBeNull);
            } else {
                this.actions.grounpDataMap(data, 2, value.target.value, item.id);
            }
            this.actions.update();
        },
        onFocusInpuit(e) {
            e.stopPropagation();
            this.privates.inputFocus = true;
        },
        grounpSave(item, e) {
            e.stopPropagation();
            if (item.title && item.title.length > 0 && item.title.length < 9) {
                let data = {
                    id: item.id,
                    agentGroupName: item.title,
                    parentId: item.parentId,
                    level: item.level,
                };
                this.privates.grounpFlag[item.key] = false;
                this.privates.inputFocus = false;
                delete this.privates.grounpFlag["grounp-add"];
                this.actions.grounpDataMap(this.privates.data, 4);
                this.props.grounpSaveChange(data, item.id);
                this.actions.update();
            } else if (item.title.length > 8) {
                Udesk.ui.notify.error(this.locales.fix.nameMaxLength8);
            } else {
                Udesk.ui.notify.error(this.locales.fix.nameError);
            }
        },
        onExpand(value) {
            let { grounpFlag } = this.privates;
            let fields = true;
            for (let key in grounpFlag) {
                if (grounpFlag[key]) {
                    fields = false;
                    Udesk.ui.notify.error(this.locales.fix.editingNow);
                    break;
                }
            }
            if (fields) {
                this.privates.expandedKeys = value;
            }

            this.actions.update();
        },
    };
    init(){
        let keys = [];
        getAllKeys(this.props.data, keys);
        this.privates.expandedKeys = keys;

    }
    parseProps({ props, prevProps, actions }) {
        if (props.data !== prevProps.data) {
            this.actions.grounpDataMap(props.data, 1);
        }
        return {
            data: props.data,
        };
    }
}
function getAllKeys(data, keys){
    data.forEach(item => {
        keys.push(item.key);
        if(item.children && item.children.length){
            getAllKeys(item.children, keys);
        }
    });
}
export default CustomerServiceGrounpComponent;
