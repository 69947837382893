// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostUsersImportBody, BaseResponseImportUserResponse } from '../../types';

/**
 * 导入员工
 *
 * @export
 * @tags 用户
 * @link [POST] /users/import
 */
export function postUsersImport(data: PostUsersImportBody): Promise<BaseResponseImportUserResponse>;
export function postUsersImport(
    data: PostUsersImportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseImportUserResponse>;
export function postUsersImport(
    data: PostUsersImportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseImportUserResponse> {
    return request<PostUsersImportBody, never, never>('/users/import', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postUsersImport',
    });
}

export const meta = [{ tags: ['用户'], path: '/users/import', method: 'post' }];
