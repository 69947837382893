import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Space, Button, Progress } from 'udesk-ui';
import { isExercise } from '../../util';
import { useDisabledForStartButton } from '../hook';
import { Dialogue } from 'Component/pages/components/coach/Dialogue';
import {
    getRtaProps,
    isRta,
} from 'Component/pages/components/coach/Dialogue/components/RealTimeAnalyzer';
import { inspect } from 'src/util/core';
import { TIMEOUT_PERIOD } from '..';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import './index.less';

export default React.memo((props: any) => {
    const {
        task,
        globalConfig,
        isStartTask,
        isLastNode,
        isOver,
        pause,
        list,
        audioRef,
        startTaskHandle,
        startAudioRecord,
        loadingForTaskStart,
        loadingForSendMessage,
        pointOfFirstEntry,
    } = props;

    const chatRecordRef = useRef<any>(null);

    const [isDisabled] = useDisabledForStartButton(task);

    const scoreDetailProps = useMemo(() => getRtaProps(task, globalConfig), [task, globalConfig]);

    const extra = useCallback(
        (props) => {
            const { isSync } = props;
            if (task?.dialogueMode !== Udesk.enums.learningTaskDialogueModeType.phone.id) {
                return;
            }
            return inspect(isSync || isOver, null, () => (
                <Progress
                    style={{
                        margin: 8,
                    }}
                    percent={pointOfFirstEntry * (100 / TIMEOUT_PERIOD)}
                    steps={TIMEOUT_PERIOD}
                    size="small"
                    showInfo={false}
                />
            ));
        },
        [isOver, pointOfFirstEntry, task]
    );

    useEffect(() => {
        chatRecordRef.current?.scrollTo(list?.length);
    }, [list?.length]);

    return (
        <div className={'chat-record-panel'}>
            <audio className="hidden" ref={audioRef} controls autoPlay={false} />
            <div className="chat-panel">
                <Dialogue
                    ref={chatRecordRef}
                    dialogueSentenceList={list}
                    itemRender={(props, index) => {
                        return (
                            <React.Fragment>
                                <Dialogue.Item
                                    {...props}
                                    {...scoreDetailProps}
                                    index={index}
                                    isRta={isRta(task, props)}
                                    extra={extra}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
            {!isLastNode && (
                <div className="record-toolbar">
                    <Space>
                        <div className={'wave'}></div>

                        {isOver ? (
                            <Button
                                loading={loadingForSendMessage}
                                type="primary"
                                size="large"
                                onClick={startAudioRecord}
                            >
                                {
                                    /* 开始录音 */ UdeskLocales['current'].pages.coach
                                        .learningCenter.components.record.voice.index.startRecording
                                }
                            </Button>
                        ) : (
                            <Button
                                loading={loadingForSendMessage}
                                type="primary"
                                size="large"
                                onClick={pause}
                            >
                                {
                                    UdeskLocales['current'].pages.coach.learningCenter.components
                                        .record.voice.index.endRecording
                                }
                            </Button>
                        )}
                    </Space>

                    {!isStartTask && (
                        <div className="start-button">
                            <Button
                                disabled={isDisabled}
                                loading={loadingForTaskStart}
                                type="primary"
                                size="large"
                                onClick={startTaskHandle}
                            >
                                {isExercise(
                                    task?.taskType,
                                    /* 开始练习 */ UdeskLocales['current'].pages.coach
                                        .learningCenter.components.record.voice.index
                                        .startPracticing,
                                    /* 开始考试 */ UdeskLocales['current'].pages.coach
                                        .learningCenter.components.record.voice.index.startTheExam
                                )}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});
