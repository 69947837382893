import React, { useEffect } from 'react';
import { Spin } from 'udesk-ui';
import styled from 'styled-components';
import Udesk from 'Udesk';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Template = (props: any) => {
    const { history } = props;

    const queryParams = new URLSearchParams(props.location.search);
    const from = queryParams.get('from');

    useEffect(() => {
        from && history.replace?.(from);
    }, [from, history]);

    return (
        <Wrapper>
            <Spin spinning={true}></Spin>
        </Wrapper>
    );
};

class Page extends React.Component {
    render(): React.ReactNode {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Page);
