import React from 'react';
import { Button, Space } from "udesk-ui";
import './index.less';
import UdeskLocales from 'UdeskLocales';

export default React.memo<any>((props) => {
    const {isLastNode, isLastQuestion, refreshWrongQuestion, nextWrongQuestion} = props;

    return isLastNode ? (
        <Space className='other-toolbar'>
            <Button onClick={refreshWrongQuestion} type='primary' size='large'>{/* 再次练习本题 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskSubmitButton.index.practiceThisQuestionAgain}</Button>
            {
                !isLastQuestion && <Button onClick={nextWrongQuestion} type='primary' size='large'>{/* 下一题 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskSubmitButton.index.nextQuestion}</Button>
            }
        </Space>
    ) : null;
});
