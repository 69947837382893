import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form, Input } from 'udesk-ui';

type PropsType = {
    current: any;
    visible: boolean;
    locales: any;
    handleOk: (name: String) => void;
    handleCancel: () => void;
}

export default React.memo((props: PropsType) => {
    const { handleOk, handleCancel, visible, current, locales } = props;
    const [names, setNames] = useState('');
    const [showFlag, setShowFlag] = useState(true);
    
    const onFinish = useCallback(() => {
        handleOk(names);
        setNames('');
    }, [
        handleOk, 
        names
    ]);

    const nameChange = useCallback((e) => {
        setNames(e.target.value);
        setShowFlag(e.target.value === '');
    }, []);

    const onCancel = useCallback(() => {
        handleCancel();
        setNames(current.id === 'default' ? '' : current.name);
    }, [
        current,
        handleCancel
    ]);

    useEffect(() => {
        if (current) {
            setNames(current.id === 'default' ? '' : current.name);
        }
    }, [
        current
    ]);

    return (
        <div>
            <Modal title={locales.components.sdkLoaderBase.modalMonit.saveCurrentReportConfiguration} visible={visible} footer={null} onOk={onFinish} onCancel={onCancel}>
                <Form
                    name='normal_login'
                    className='login-form'
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name='name'
                        rules={[
                            {
                                max: 20,
                                message: locales.components.sdkLoaderBase.modalMonit.pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs,
                            },
                        ]}
                    >
                        <div style={{ display: 'flex' }}>
                            <Input value={names} maxLength={20} maxCount={20} showCount={true} onChange={nameChange} placeholder={locales.components.sdkLoaderBase.modalMonit.pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs} />
                            <Button style={{ marginLeft: '3px' }} type='primary' disabled={showFlag} htmlType='submit'>
                                {locales.components.sdkLoaderBase.modalMonit.preservation}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
});
