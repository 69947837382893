import React from 'react';
import InputBaseClass from './input-base-class';
import Udesk from '../../udesk/index';
import Locales from "../../udesk/locales/index";

class IntegerNumber extends InputBaseClass {
    actions = {
        _getInternalValueFromValue(value) {
            let internalValue = this.actions._getBaseClassGlobalInternalValueFromValue(value);
            if (internalValue == null) {
                return "";
            }
            return internalValue;
        },
        _validate() {
            let {
                privates: { computes },
            } = this;

            let {
                internalValue
            } = computes;

            if (internalValue != null) {
                if (!Udesk.config.regex.numberNullable.test(internalValue)) {
                    return [Locales.get("components.customFieldInputs.integerNumber.invalidInputError", this.props.language)];
                }
            }
            return true;
        },
        _onInputValueChanged(e) {
            let internalValue = e.target.value;
            if (internalValue === "-" || /(^-?[1-9]\d*$)|(^0{1}$)/.test(internalValue) || internalValue === "") {
                this.actions._onChanged(internalValue);
            }
        }
    }

    static get emberName() {
        return '';
    }

    render() {
        let {
            privates: { computes },
        } = this;

        let {
            internalValue,
            mergedAttributes
        } = computes;
        let {
            disabled
        } = this.props;

        return (
            <input type="text" className={mergedAttributes.classNames} value={internalValue} disabled={disabled} autoFocus={mergedAttributes.autofocus} placeholder={mergedAttributes.placeholder} onInput={this.actions._onInputValueChanged} onChange={this.actions._onInputValueChanged} />
        );
    }
}

export default Udesk.react.udeskify(IntegerNumber);