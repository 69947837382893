import React from 'react';
import { Select } from 'udesk-ui';
import './style.scss';

const { Option } = Select;
// interface propsValue {
//     onChange: () => void;
// }
const UdeskSelect = (props) => {
    //扩展onChange方法
    const onChangeExtra = (value) => {
        props.onChange(value);
    };
    return (
        <Select maxTagCount={2} getPopupContainer={triggerNode => triggerNode.parentElement} mode={props.mode} value={props.value} style={{ minWidth: 100 }} onChange={onChangeExtra}>
            {
                props.optionList.map(option => {
                    let optionProps = {...option};
                    delete optionProps.id;
                    return (
                        <Option value={option.id} {...optionProps}>{option.name}</Option>
                    );
                })
            }
        </Select>
    );
};

export default UdeskSelect;
