import Udesk from "Udesk";
import React from "react";
import TaskConditionDictionariesComponent from "./component";
import "./style.scss";

export default class TaskConditionDictionariesTemplate extends TaskConditionDictionariesComponent {
    render() {
        let { actions, locales, state, privates } = this;
        return (
            <div className="components-task-condition-dictionaries">
                <div className="condition-dictionaries-entry" onClick={actions.onConditionDicChange}>
                    <span className="condition-dictionaries-entry-icon">
                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-zidian"></i>
                    </span>
                    <span className="condition-dictionaries-entry-icon">
                        <Choose>
                            <When condition={state.conditionDictionariesDisplay}>
                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-arrow-right-copy-copy-copy"></i>
                            </When>
                            <Otherwise>
                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jiantou2"></i>
                            </Otherwise>
                        </Choose>
                    </span>
                </div>
                <If condition={state.conditionDictionariesDisplay}>
                    <div className="condition-dictionaries-content">
                        <h3 className="condition-dictionaries-content-title">
                            {locales.components.tasks.conditionDictionaries.title}
                        </h3>
                        <div className="condition-dictionaries-content-search">
                            <Choose>
                                <When condition={state.searchText.length > 0}>
                                    <span
                                        className="condition-dictionaries-content-search-icon"
                                        onClick={actions.delSearchTex}
                                    >
                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-cuo"></i>
                                    </span>
                                </When>
                                <Otherwise>
                                    <span
                                        className="condition-dictionaries-content-search-icon"
                                        onClick={actions.searchCondition}
                                    >
                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-icon-"></i>
                                    </span>
                                </Otherwise>
                            </Choose>
                            <input
                                class={"condition-dictionaries-content-search-input"}
                                value={state.searchText}
                                onChange={actions.mutator("state.searchText", e => e.target.value, true)}
                                placeholder={locales.components.tasks.conditionDictionaries.searchDefaultText}
                                onKeyDown={actions.onKeyDown}
                            />
                        </div>
                        <ul className="condition-dictionaries-content-list">
                            <li className="condition-dictionaries-content-list-li">
                                <span className="condition-dictionaries-content-list-li-title">
                                    {locales.components.tasks.conditionDictionaries.id}
                                </span>
                                <span className="condition-dictionaries-content-list-li-title">
                                    {locales.components.tasks.conditionDictionaries.name}
                                </span>
                            </li>
                            <For each="item" index="index" of={privates.conditionList}>
                                <li className="condition-dictionaries-content-list-li">
                                    <span
                                        className="condition-dictionaries-content-list-li-title"
                                        title={item.data.idCode}
                                    >
                                        {item.data.idCode}
                                    </span>
                                    <span
                                        className="condition-dictionaries-content-list-li-title"
                                        title={item.data.name}
                                    >
                                        {item.data.name}
                                    </span>
                                    <span
                                        className="condition-dictionaries-content-list-li-switch"
                                        onClick={actions.switchDetailsDisplay.params(item)}
                                    >
                                        <Choose>
                                            <When condition={item.states.detailsDisplay}>
                                                {locales.components.tasks.conditionDictionaries.conditionDetailedHide}
                                            </When>
                                            <Otherwise>
                                                {locales.components.tasks.conditionDictionaries.conditionDetailedShow}
                                            </Otherwise>
                                        </Choose>
                                    </span>
                                    <If condition={item.states.detailsDisplay}>
                                        <ul className="condition-dictionaries-content-list-li-details">
                                            <li className="condition-dictionaries-content-list-li-details-li">
                                                <span className="condition-dictionaries-content-list-li-details-li-title">
                                                    {locales.components.tasks.conditionDictionaries.remark}
                                                </span>
                                                <span className="condition-dictionaries-content-list-li-details-li-text text-wrap">
                                                    {item.data.remark}
                                                </span>
                                            </li>
                                            <li className="condition-dictionaries-content-list-li-details-li">
                                                <span className="condition-dictionaries-content-list-li-details-li-title">
                                                    {locales.components.tasks.conditionDictionaries.checkRole}
                                                </span>
                                                <span className="condition-dictionaries-content-list-li-details-li-text text-wrap">
                                                    {Udesk.enums.applyRoles.getName(item.data.applyRole)}
                                                </span>
                                            </li>
                                            <li className="condition-dictionaries-content-list-li-details-li">
                                                <span className="condition-dictionaries-content-list-li-details-li-title">
                                                    {locales.components.tasks.conditionDictionaries.operator}
                                                </span>
                                                <div className="condition-dictionaries-content-list-li-details-li-operator text-wrap">
                                                    <For each="operator" index="index" of={item.data.operatorList}>
                                                        <Choose>
                                                            <When condition={operator.type === Udesk.enums.operatorTypes.semanticTags.id}>
                                                                <span className="condition-dictionaries-content-list-li-details-li-operator-text">
                                                                    {index + 1}
                                                                    :
                                                                    {Udesk.enums.operatorTypes.getName(operator.type)}
                                                                    -
                                                                    <Choose>
                                                                        <When condition={operator.semanticTag && operator.semanticTag.semanticName}>
                                                                            {operator.semanticTag.semanticName}
                                                                        </When>
                                                                        <Otherwise>
                                                                            {locales.labels.none}
                                                                        </Otherwise>
                                                                    </Choose>
                                                                </span>
                                                            </When>
                                                            <Otherwise>
                                                                <span className="condition-dictionaries-content-list-li-details-li-operator-text">
                                                                    {index + 1}
                                                                    :
                                                                    {Udesk.enums.operatorTypes.getName(operator.type)}
                                                                    -
                                                                    {Udesk.enums.fieldOperators.getName(operator.operator)}
                                                                    ,
                                                                    {operator.keyword}
                                                                </span>
                                                            </Otherwise>
                                                        </Choose>
                                                    </For>
                                                </div>
                                            </li>
                                            <li className="condition-dictionaries-content-list-li-details-li">
                                                <span className="condition-dictionaries-content-list-li-details-li-title">
                                                    {locales.components.tasks.conditionDictionaries.operatorLogic}
                                                </span>
                                                <span className="condition-dictionaries-content-list-li-details-li-text text-wrap">
                                                    {item.data.operatorLogic}
                                                </span>
                                            </li>
                                        </ul>
                                    </If>
                                </li>
                            </For>
                        </ul>
                    </div>
                </If>
            </div>
        );
    }
}
