import Udesk from 'Udesk';
import startNodeConfig from './nodeConfigs/startNode';
import userNodeConfig from './nodeConfigs/userNode';
import robotNodeConfig from './nodeConfigs/robotNode';
import knowledgeNodeConfig from './nodeConfigs/knowledgeNode';
import pptNodeConfig from './nodeConfigs/pptNode';
import aiGenSceneNodeConfig from './nodeConfigs/aiGenSceneNode';
import aiGenDialogueNodeConfig from './nodeConfigs/aiGenDialogueNode';
import aiGenPromptNodeConfig from './nodeConfigs/aiGenPromptNode';

export const nodeTypeFormMap = {
    [Udesk.enums.nodeTypes.startNode.key]: startNodeConfig.fields,
    [Udesk.enums.nodeTypes.userNode.key]: userNodeConfig.fields,
    [Udesk.enums.nodeTypes.robotNode.key]: robotNodeConfig.fields,
    [Udesk.enums.nodeTypes.knowledgeNode.key]: knowledgeNodeConfig.fields,
    [Udesk.enums.nodeTypes.pptNode.key]: pptNodeConfig.fields,
    [Udesk.enums.nodeTypes.aiGenSceneNode.key]: aiGenSceneNodeConfig.fields,
    [Udesk.enums.nodeTypes.aiGenDialogueNode.key]: aiGenDialogueNodeConfig.fields,
    [Udesk.enums.nodeTypes.aiGenPromptNode.key]: aiGenPromptNodeConfig.fields,
};
export const nodeDetailRequestMap = {
    [Udesk.enums.nodeTypes.startNode.key]: startNodeConfig.request,
    [Udesk.enums.nodeTypes.userNode.key]: userNodeConfig.request,
    [Udesk.enums.nodeTypes.robotNode.key]: robotNodeConfig.request,
    [Udesk.enums.nodeTypes.knowledgeNode.key]: knowledgeNodeConfig.request,
    [Udesk.enums.nodeTypes.pptNode.key]: pptNodeConfig.request,
    [Udesk.enums.nodeTypes.aiGenSceneNode.key]: aiGenSceneNodeConfig.request,
    [Udesk.enums.nodeTypes.aiGenDialogueNode.key]: aiGenDialogueNodeConfig.request,
    [Udesk.enums.nodeTypes.aiGenPromptNode.key]: aiGenPromptNodeConfig.request,
};

export const nodeDetailOnFinishMap = {
    [Udesk.enums.nodeTypes.startNode.key]: startNodeConfig.finish,
    [Udesk.enums.nodeTypes.userNode.key]: userNodeConfig.finish,
    [Udesk.enums.nodeTypes.robotNode.key]: robotNodeConfig.finish,
    [Udesk.enums.nodeTypes.knowledgeNode.key]: knowledgeNodeConfig.finish,
    [Udesk.enums.nodeTypes.pptNode.key]: pptNodeConfig.finish,
    [Udesk.enums.nodeTypes.aiGenSceneNode.key]: aiGenSceneNodeConfig.finish,
    [Udesk.enums.nodeTypes.aiGenDialogueNode.key]: aiGenDialogueNodeConfig.finish,
    [Udesk.enums.nodeTypes.aiGenPromptNode.key]: aiGenPromptNodeConfig.finish,
};

export const nodeDetailFormHeaderMap = {
    [Udesk.enums.nodeTypes.startNode.key]: () => null,
    [Udesk.enums.nodeTypes.userNode.key]: () => null,
    [Udesk.enums.nodeTypes.robotNode.key]: () => null,
    [Udesk.enums.nodeTypes.knowledgeNode.key]: () => null,
    [Udesk.enums.nodeTypes.pptNode.key]: () => null,
    [Udesk.enums.nodeTypes.aiGenSceneNode.key]: aiGenSceneNodeConfig.FormHeader,
    [Udesk.enums.nodeTypes.aiGenDialogueNode.key]: () => null,
    [Udesk.enums.nodeTypes.aiGenPromptNode.key]: () => null,
};
