import React, { useState, useCallback, useEffect } from 'react';
import Udesk from 'Udesk';
import { Badge, Table, Input, Typography, Space, Dropdown, Menu, Icon } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { isDefined, isEmpty } from 'src/util/core';
import './index.scss';

const ColumnHeader = React.memo((props: any) => {
    const {
        locales,
        keyword,
        onPressEnter,
        setKeyword,
        selectClusterKey,
        setSelectedClusterKey,
    }= props;
    const request = useRequest();
    const [dataSource, setDataSource] = useState<any[]>([]);

    useLiveEffect((success) => {
        request(`/review/wordsOperation/${Udesk.enums.applicationScenarios.objection.id}`, {}).then((r) => {
            success(() => {
                const {data} = r;
                setDataSource(
                    data.map(
                        item => ({
                            key: item.id.toString(),
                            label: item.name,
                        })
                    )
                );
                if (!selectClusterKey) {
                    if (data.length > 0) {
                        setSelectedClusterKey(data[0].id);
                    }
                }
            });
        });
    }, []);

    const getCurrentLabel = useCallback(() => {
        if (selectClusterKey) {
            const current = dataSource.find(item => item.key === selectClusterKey.toString());
            if (current) {
                return current.label;
            }
        }
    }, [dataSource, selectClusterKey]);

    const clickHandle = (node) => {
        setSelectedClusterKey(+node.key);
    };

    return ( 
        <div className='column-header'>
            <Dropdown
                trigger={['click']}
                overlayClassName='dissent-menu-dropdown beautiful-scrollbar'
                overlay={
                    <Menu
                        onClick={clickHandle}
                        selectable
                        style={{
                            maxHeight: 300,
                            width: 350,
                            overflow: 'auto'
                        }}
                        selectedKeys={selectClusterKey ? [selectClusterKey.toString()] : undefined}
                    >
                        {
                            dataSource.map(item => <Menu.Item title={item.label} key={item.key}>{item.label}</Menu.Item>)
                        }
                    </Menu>
                }>
                <Typography.Link>
                    <Space>
                        {getCurrentLabel()}
                        <Icon type='DownOutlined' antdIcon={true} />
                    </Space>
                </Typography.Link>
            </Dropdown>
            <Input
                allowClear
                value={keyword}
                onChange={e => {
                    setKeyword(e.target.value);
                }}
                onPressEnter={onPressEnter}
                placeholder={/* 输入关键词搜索异议 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageLeftTable.enterKeywordsToSearchForObjections}
                suffix={<Icon style={{marginLeft: 10}} antdIcon={true} type='SearchOutlined' /> }
            />
        </div>
    );
});

const pageSize = Udesk.config.paging.defaultPageSize;

export default React.memo((props: any) => {
    const {
        leftPageKeyword: keyword,
        setLeftPageKeyword: setKeyword,
        leftPagePageNum: pageNum,
        setLeftPagePageNum: setPageNum,
        selectedRowKey, 
        setSubjectName,
        setSelectedRowKey, 
        selectClusterKey,
        setSelectedClusterKey,
        setSelectedClusterId, 
        request,
        locales
    }= props;
    const [loading, setLoading] = useState(false);
    const [tableDataSource, setTableDataSource] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    const renderCell = useCallback((text, record) => {
        return (
            <div 
                className='table-row-item' 
                onClick={() => {
                    setSubjectName(record.question);
                    setSelectedRowKey(record.id);
                    setSelectedClusterId(record.clustId);
                }}
            >
                <span>{record.question}</span>
                <div className="toolkit-button">
                    <Badge style={{ backgroundColor: '#f5f5f5' }} count={record.questionCount} overflowCount={999} />
                </div>
            </div>
        );
    }, [
        setSelectedRowKey, 
        setSubjectName
    ]);

    const requestTableSource = useCallback((selectClusterKey, pageNum = 1, keyword?) => {
        if (selectClusterKey) {
            let url = `/review/cluster/clusterResult/${selectClusterKey}`;
            let params: any = {
                pageNum,
                pageSize,
            };
            isDefined(keyword, () => {
                params.name = keyword;
            });
            setLoading(true);
            request(url, params).then(
                resp => {
                    setTableDataSource(resp.data.map(item => ({
                        ...item,
                        key: item.id
                    })));
                    setTotal(resp.paging.total);
                    setPageNum(pageNum);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                setLoading(false);
            });
        }
    }, [
        request, 
        setPageNum,
    ]);

    const onPageNumChange = useCallback((pageNum) => {
        setPageNum(pageNum);
        requestTableSource(selectClusterKey, pageNum, keyword);
    }, [
        keyword,
        setPageNum,
        selectClusterKey,
        requestTableSource
    ]);

    const onPressEnter = useCallback(e => {
        setPageNum(1);
        requestTableSource(selectClusterKey, 1, e.target.value);
    }, [
        selectClusterKey,
        requestTableSource, 
        setPageNum
    ]);

    const onSelectedClusterKeyChange = useCallback((value) => {
        setKeyword(null);
        setSubjectName(null);
        setSelectedRowKey(null);
        setSelectedClusterId(null);
        setTableDataSource([]);
        setPageNum(1);
        setSelectedClusterKey(value);
        requestTableSource(value, 1, null);
    }, [
        requestTableSource, 
        setKeyword, 
        setPageNum, 
        setSelectedClusterKey, 
        setSelectedRowKey, 
        setSelectedClusterId,
        setSubjectName
    ]);

    useEffect(() => {
        if (!isDefined(selectedRowKey) && !isEmpty(tableDataSource)) {
            setSelectedRowKey(tableDataSource[0].id);
            setSelectedClusterId(tableDataSource[0].clustId);
            setSubjectName(tableDataSource[0].question);
        }
    }, [
        selectedRowKey, 
        setSelectedRowKey, 
        setSelectedClusterId,
        setSubjectName,
        tableDataSource
    ]);

    useLiveEffect(() => requestTableSource(selectClusterKey, pageNum, keyword), [requestTableSource]);

    return (
        <div className='words-mining-dissent-page-left'>
            <Table
                loading={loading}
                scroll={{
                    y: 'calc(100% - 100px)'
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    columnTitle: (
                        <ColumnHeader {...{
                            locales,
                            keyword,
                            setKeyword,
                            onPressEnter,
                            selectClusterKey,
                            setSelectedClusterKey: onSelectedClusterKeyChange,
                        }} />
                    ),
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    current: pageNum,
                    pageSize,
                    total,
                    simple : true,
                    onChange: onPageNumChange,
                    hideOnSinglePage: true,
                }} 
                columns={[]}
                dataSource={tableDataSource}
            />
        </div>
    );
});
