const colorList = [
    '#5B8FF9',
    '#5AD8A6',
    '#F6BD16',
    '#E86452',
    '#6DC8EC',
    '#945FB9',
    '#FF9845',
    '#1E9493',
    '#FF99C3',
    '#CDDDFD',
    '#CDF3E4',
    '#FCEBB9',
    '#F8D0CB',
    '#D3EEF9',
    '#DECFEA',
    '#FFE0C7',
    '#BBDEDE',
    '#FFE0ED',
];

export const getLineAndBarEchartOptions = (xAxisData, series) => {
    const option = {
        backgroundColor: '#F5F8FE',
        color: colorList,
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            top: 20,
            data: series.map((s) => s.name),
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            // boundaryGap: false,
            data: xAxisData,
        },
        yAxis: {
            type: 'value',
            min: 0,
            // max: maxLeft,
            splitNumber: 5,
            // interval: interval_left,
            minInterval: 1,
            axisLine: {
                //y轴
                show: false,
            },
            axisTick: {
                //y轴刻度线
                show: false,
            },
            axisLabel: {
                color: '#444343',
            },
        },
        series,
    };

    return option;
};

export const getPieEchartOptions = (series) => {
    const option = {
        backgroundColor: '#F5F8FE',
        color: colorList,
        tooltip: {
            trigger: 'item',
        },
        legend: {
            type: 'scroll',
            top: 20,
        },
        series,
    };

    return option;
};
