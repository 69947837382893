import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';

const AdminGongUser = React.memo((props: any) => {
    let { routes, route, location, sdkOptions, match } = props;
    const isRedirect = (currentPath, toPath) => {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: props.match.params,
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    };
    return (
        <React.Fragment>
            {isRedirect(route, location.pathname) ? (
                <Redirect
                    to={Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                        routeName: route.name,
                        pathParams: match.params,
                    })}
                    sdkOptions={sdkOptions}
                />
            ) : (
                <div className='case-base-pages' style={{ height: '100%' }}>
                    {routes.map((routeItem, i) => (
                        <SubRoutes key={i} route={routeItem} />
                    ))}
                </div>
            )}
        </React.Fragment>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongUser {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
