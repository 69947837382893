import Udesk from 'Udesk';
import CustomerPortraitComponent from './component';

class CustomerPortraitRoute extends CustomerPortraitComponent {
    asyncModel(changedParams) {
        let { sdkOptions } = this.props;
        let { statAt, keyword } = this.privates;
        let { pageNum, pageSize } = this.state;
        let params = {
            pageNum,
            pageSize,
        };
        if(keyword){
            params.keyword = keyword;
        }
        if (statAt) {
            Object.assign(params, {
                startTime: statAt[0],
                endTime: statAt[1],
            });
        }
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }
        let customerList = Udesk.business.apiPath.concatApiPath(`personaDatas/list`, sdkOptions);
        let customerPortraitPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(customerList, params).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            customerPortrait: customerPortraitPromise,
        };
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        let customerData = {
            customerPortrait: [],
            customerPortraitPaging: {},
        };
        if (asyncKey === 'customerPortrait') {
            if (asyncModel.customerPortrait) {
                if (asyncModel.customerPortrait.data) {
                    customerData.customerPortrait = asyncModel.customerPortrait.data;
                }
                if (asyncModel.customerPortrait.paging) {
                    customerData.customerPortraitPaging = asyncModel.customerPortrait.paging;
                }
            }
        }
        return customerData;
    }
    onAsyncModelResolved(asyncModel) {
        this.setState({
            pageNum: asyncModel.customerPortraitPaging.pageNum,
            pageSize: asyncModel.customerPortraitPaging.pageSize,
            total: asyncModel.customerPortraitPaging.total,
        });
        if (asyncModel && asyncModel.customerPortrait.length > 0 && this.privates.defaultShowUserId === '') {
            this.privates.defaultShowUserId = asyncModel.customerPortrait[0].id;
            this.actions.getStatisticsData(this.privates.defaultShowUserId, asyncModel.customerPortrait[0].customerId);
            this.actions.update();
        } else if (
            (!asyncModel || asyncModel.customerPortrait.length === 0) &&
            this.privates.defaultShowUserId === ''
        ) {
            this.privates.personaData = [];
            this.privates.businessTagsData = [];
            this.privates.keyWordsData = [];
            this.privates.timeLineData = [];
            this.privates.keyWordsEacharts = {};
            this.actions.update();
        }
    }
}

export default CustomerPortraitRoute;
