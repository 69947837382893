import React, { useMemo } from 'react';
import ConditionListGather from 'Component/pages/components/check-point-condition-list-gather';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import SegmentationStrategy from 'Component/pages/components/segmentation-strategy';
import InterfacePush from 'Component/pages/components/massage-push-configuration/interface-push';
import OtherPush from 'Component/pages/components/massage-push-configuration/other-push';
import AssociateRegulation from 'Component/pages/components/associate-regulation';
import Logic from 'Component/pages/components/logic-dnd-component/index';
import PushTargetComponent from 'Component/pages/components/massage-push-configuration/push-target-component';
import ImgUpload from 'Component/common/img-upload/index';

const FormAutoComplete: React.FC<any> = ({ onChange: propsOnChange, style = {}, ...args }) => {
    return (
        <div style={style}>
            <AutoComplete {...args} onChanged={propsOnChange} />
        </div>
    );
};
const FormInterfacePush: React.FC<any> = ({ onChange: propsOnChange, value, ...args }) => {
    return <InterfacePush {...args} pushConf={value} onPushConfItemChange={propsOnChange} />;
};
const FormOtherPush: React.FC<any> = ({ onChange: propsOnChange, value, ...args }) => {
    return <OtherPush {...args} pushConf={value} onPushConfItemChange={propsOnChange} />;
};
export const ConditionListGatherForm: React.FC<any> = ({ value, ...args }) => {
    const conditions = useMemo(() => {
        return value.map(item => {
            if(item.operatorList) return item;
            return {
                ...item,
                operatorList: item.taskOperatorList,
            };
        });
    }, [value]);
    return <ConditionListGather {...args} conditionList={conditions} />;
};
const configMap = {
    UdAutoComplete: FormAutoComplete,
    SegmentationStrategy: SegmentationStrategy,
    InterfacePush: FormInterfacePush,
    OtherPush: FormOtherPush,
    AssociateRegulation: AssociateRegulation,
    Logic: Logic,
    ConditionListGather: ConditionListGatherForm,
    PushTarget: PushTargetComponent,
    ImgUpload,
};

export default configMap;
