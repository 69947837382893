function _typeof(o) {
  '@babel/helpers - typeof';
  return (
    (_typeof =
      'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
        ? function (o) {
            return typeof o;
          }
        : function (o) {
            return o &&
              'function' == typeof Symbol &&
              o.constructor === Symbol &&
              o !== Symbol.prototype
              ? 'symbol'
              : typeof o;
          }),
    _typeof(o)
  );
}
function _toConsumableArray(arr) {
  return (
    _arrayWithoutHoles(arr) ||
    _iterableToArray(arr) ||
    _unsupportedIterableToArray(arr) ||
    _nonIterableSpread()
  );
}
function _nonIterableSpread() {
  throw new TypeError(
    'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
  );
}
function _iterableToArray(iter) {
  if (
    (typeof Symbol !== 'undefined' && iter[Symbol.iterator] != null) ||
    iter['@@iterator'] != null
  )
    return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r &&
      (o = o.filter(function (r) {
        return Object.getOwnPropertyDescriptor(e, r).enumerable;
      })),
      t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2
      ? ownKeys(Object(t), !0).forEach(function (r) {
          _defineProperty(e, r, t[r]);
        })
      : Object.getOwnPropertyDescriptors
      ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t))
      : ownKeys(Object(t)).forEach(function (r) {
          Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
        });
  }
  return e;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, 'string');
  return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== 'object' || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || 'default');
    if (_typeof(res) !== 'object') return res;
    throw new TypeError('@@toPrimitive must return a primitive value.');
  }
  return (hint === 'string' ? String : Number)(input);
}
function _slicedToArray(arr, i) {
  return (
    _arrayWithHoles(arr) ||
    _iterableToArrayLimit(arr, i) ||
    _unsupportedIterableToArray(arr, i) ||
    _nonIterableRest()
  );
}
function _nonIterableRest() {
  throw new TypeError(
    'Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
  );
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === 'string') return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === 'Object' && o.constructor) n = o.constructor.name;
  if (n === 'Map' || n === 'Set') return Array.from(o);
  if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }
  return arr2;
}
function _iterableToArrayLimit(r, l) {
  var t =
    null == r ? null : ('undefined' != typeof Symbol && r[Symbol.iterator]) || r['@@iterator'];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (((i = (t = t.call(r)).next), 0 === l)) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) {}
    } catch (r) {
      (o = !0), (n = r);
    } finally {
      try {
        if (!f && null != t.return && ((u = t.return()), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
import { useLayoutEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
var useScroll = function useScroll(_ref, deps) {
  var wrapRef = _ref.wrapRef,
    propsScroll = _ref.propsScroll,
    prefixCls = _ref.prefixCls,
    dataSource = _ref.dataSource;
  var _useResizeObserver = useResizeObserver({
      ref: wrapRef,
    }),
    _useResizeObserver$wi = _useResizeObserver.width,
    width = _useResizeObserver$wi === void 0 ? 1 : _useResizeObserver$wi,
    _useResizeObserver$he = _useResizeObserver.height,
    h = _useResizeObserver$he === void 0 ? 1 : _useResizeObserver$he;
  var _useState = useState(_objectSpread({}, propsScroll)),
    _useState2 = _slicedToArray(_useState, 2),
    scroll = _useState2[0],
    setScroll = _useState2[1];
  useLayoutEffect(function () {
    var _wrapRef$current, _wrapRef$current2;
    var paginationDomInstance =
      (_wrapRef$current = wrapRef.current) === null || _wrapRef$current === void 0
        ? void 0
        : _wrapRef$current.querySelector('.'.concat(prefixCls, '-pagination'));
    var tableHeadDomInstance =
      (_wrapRef$current2 = wrapRef.current) === null || _wrapRef$current2 === void 0
        ? void 0
        : _wrapRef$current2.querySelector('.'.concat(prefixCls, '-table-thead'));
    var tableHeight = getTableScrollY(paginationDomInstance, tableHeadDomInstance, h);
    setScroll(
      Object.assign(
        {
          scrollToFirstRowOnChange: true,
          x: 'max-content',
          y: (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length)
            ? tableHeight
            : undefined,
        },
        propsScroll,
      ),
    );
  }, [].concat(_toConsumableArray(deps), [width, h, dataSource]));
  return scroll;
};
export default useScroll;
var heightProps = [
  'height',
  'marginTop',
  'marginBottom',
  'paddingTop',
  'paddingBottom',
  'borderBottomWidth',
  'borderTopWidth',
];
export function getTableScrollY(paginationDomInstance, tableHeadDomInstance, wrapHeight) {
  var tableHeadDomHeight = getComputedStyleTotalHeight(tableHeadDomInstance),
    paginationDomHeight = getComputedStyleTotalHeight(paginationDomInstance);
  return Math.max(wrapHeight - tableHeadDomHeight - paginationDomHeight, 0);
}
function getComputedStyleTotalHeight(domInstance) {
  if (!domInstance) return 0;
  var computedStyle = window.getComputedStyle(domInstance);
  return heightProps.reduce(function (prev, cur) {
    return prev + parseInt(computedStyle[cur], 10);
  }, 0);
}
