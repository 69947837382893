import { useCallback, useEffect, useMemo, useState } from 'react';

interface UseCollapseProps {
    pointCategoryList?: any[];
    show?: (i: any) => void;
}

export const useDisplay = (props: UseCollapseProps) => {
    const { pointCategoryList, show } = props || {};

    // 设置一个展开状态机
    const [pointCategoryListSM, setPointCategoryListSM] = useState<any[]>([]);

    const transform = useCallback(() => {
        return (
            pointCategoryList
                ?.filter((c) => !c._hidden && show?.(c))
                ?.map((c) => {
                    return {
                        ...c,
                        isDisplayed: true,
                        children: c.children
                            ?.filter(
                                (ch) =>
                                    !ch._hidden &&
                                    show?.(ch) &&
                                    ch.pointDataList &&
                                    ch.pointDataList.length > 0
                            )
                            ?.map((ch) => {
                                return {
                                    ...ch,
                                    isDisplayed: true,
                                };
                            }),
                    };
                }) || []
        );
    }, [pointCategoryList, show]);

    // 初始化状态机。
    // 1.过滤不展示的一/二级分类
    // 2.将一/二级分类的状态设置为展开
    const init = useCallback(() => {
        setPointCategoryListSM(transform());
    }, [transform]);

    const [searchWords, setSearchWords] = useState<string>();

    const onSearch = useCallback(
        (value?: string) => {
            const target = value?.trim();
            setSearchWords(target);

            if (target) {
                setPointCategoryListSM(() =>
                    // 这里，每次搜索确保是全量搜索
                    transform()
                        .filter(
                            // 0.只留有命中的一级分类
                            (c) =>
                                c.name.includes(target) ||
                                c.children?.some(
                                    (ch) =>
                                        ch.name?.includes(target) ||
                                        ch.pointDataList?.some((p) => p.name?.includes(target))
                                )
                        )
                        .map((c) => {
                            // 1.一级分类命中：只展示该一级分类，展开一/二级分类
                            // 2.二级分类命中：只展示该二级分类，展开一/二级分类
                            // 3.规则命中：只展示该规则，展开的一/二级分类
                            if (c.name.includes(target)) {
                                return {
                                    ...c,
                                    isDisplayed: true,
                                    children: c.children?.map((ch) => {
                                        return {
                                            ...ch,
                                            isDisplayed: true,
                                        };
                                    }),
                                };
                            } else if (c.children?.some((ch) => ch.name.includes(target))) {
                                return {
                                    ...c,
                                    isDisplayed: true,
                                    children: c.children
                                        .filter((ch) => ch.name.includes(target))
                                        .map((ch) => {
                                            return {
                                                ...ch,
                                                isDisplayed: true,
                                            };
                                        }),
                                };
                            } else {
                                return {
                                    ...c,
                                    isDisplayed: true,
                                    children: c.children?.map((ch) => {
                                        return {
                                            ...ch,
                                            isDisplayed: true,
                                            pointDataList: ch.pointDataList.filter((p) =>
                                                p.name.includes(target)
                                            ),
                                        };
                                    }),
                                };
                            }
                        })
                );
            } else {
                init();
            }
        },
        [init, transform]
    );

    useEffect(() => {
        onSearch(searchWords);
    }, [onSearch, searchWords]);

    const onSearchChange = (value) => {
        // 这里，自动触发一下search
        if (!value) {
            setSearchWords(value);
        }
    };

    const { activeKeyC1, isC1DisplayedAll, activeKeyC2, isC2DisplayedAll } = useMemo(() => {
        const activeKeyC1 = pointCategoryListSM.filter((p) => p.isDisplayed).map((p) => p.id);

        const isC1DisplayedAll =
            activeKeyC1.length === pointCategoryListSM.length && pointCategoryListSM.length;

        const activeKeyC2 = pointCategoryListSM.reduce((pre, cur, idx) => {
            return [
                ...pre,
                ...(cur.children?.filter((item) => item.isDisplayed).map((item) => item.id) || []),
            ];
        }, []);

        const isC2DisplayedAll =
            activeKeyC2.length ===
                pointCategoryListSM.reduce((pre, cur, idx) => {
                    return pre + cur.children?.length || 0;
                }, 0) && pointCategoryListSM.length;

        return {
            activeKeyC1,
            isC1DisplayedAll,
            activeKeyC2,
            isC2DisplayedAll,
        };
    }, [pointCategoryListSM]);

    const onDisplayAllByC1 = (checked) => {
        setPointCategoryListSM((p) =>
            p.map((p) => {
                return {
                    ...p,
                    isDisplayed: checked,
                };
            })
        );
        // 一级分类收起时，二级分类同时收起
        if (!checked) {
            onDisplayAllByC2(checked);
        }
    };

    const onDisplayAllByC2 = (checked) => {
        setPointCategoryListSM((p) =>
            p.map((p) => {
                return {
                    ...p,
                    // 二级分类展开时，一级分类同时展开
                    isDisplayed: checked ? checked : p.isDisplayed,
                    children: p.children?.map((c) => {
                        return {
                            ...c,
                            isDisplayed: checked,
                        };
                    }),
                };
            })
        );
    };

    const onC1Change = (keys) => {
        setPointCategoryListSM((p) =>
            p.map((c) => {
                const isDisplayed = keys.includes(c.id + '');

                return {
                    ...c,
                    isDisplayed,
                    children: c.children?.map((ch) => {
                        return {
                            ...ch,
                            isDisplayed: isDisplayed ? ch.isDisplayed : isDisplayed,
                        };
                    }),
                };
            })
        );
    };

    const onC2Change = (keys, id) => {
        setPointCategoryListSM((p) =>
            p.map((p) => {
                if (p.id === id) {
                    return {
                        ...p,
                        children: p.children?.map((c) => {
                            return {
                                ...c,
                                isDisplayed: keys.includes(c.id + ''),
                            };
                        }),
                    };
                } else {
                    return p;
                }
            })
        );
    };

    console.log('pointCategoryListSM', pointCategoryListSM);

    return {
        activeKeyC1,
        isC1DisplayedAll,
        onDisplayAllByC1,
        onC1Change,
        activeKeyC2,
        isC2DisplayedAll,
        onDisplayAllByC2,
        onC2Change,
        onSearch,
        onSearchChange,
        pointCategoryListSM,
        highlightWords: [searchWords],
    };
};
