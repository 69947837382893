import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, InputNumber, Menu, Select, Space } from 'udesk-ui';
import UdeskList from 'Component/common/udesk-list';
import { postRiskSettingsSearchTemplate } from 'src/api/riskSettings/search-template';
import CustomFilters from 'udesk-react/src/components/custom-filters';
import Udesk from 'Udesk';
import config from 'src/common/config/index';
import styled from 'styled-components';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

const colorMap = {
    1: '#efdbff',
    2: '#d6e4ff',
    3: '#b5f5ec',
    4: '#fff1b8',
    5: '#ffd8bf',
};

const RuleItemWrap = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0 3px 3px 0;
    flex: 1;
    position: relative;
    margin-left: ${(props) => props.width}px;
    &::before {
        content: '';
        background-color: ${(props) => props.color};
        width: ${(props) => props.width}px;
        position: absolute;
        top: -1px;
        left: -${(props) => props.width}px;
        bottom: -1px;
        border-radius: 3px 0 0 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-right: none;
    }
`;

const scope = [
    { key: 1, label: /* 所有会话 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.components.riskSettingRule.index.allSessions },
    { key: 2, label: /* 最近天数 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.components.riskSettingRule.index.recentDays },
    { key: 3, label: /* 最近会话 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.components.riskSettingRule.index.recentSessions },
];

const RuleItem = (props) => {
    const { value, typeListOptions, typeListCache, onChange } = props;
    const { type } = value;
    const menu = useMemo(() => {
        return (
            <Menu
                onClick={({ item, key }) => {
                    onChange({
                        ...value,
                        scope: Number(key),
                        scopeValue: Number(key) === 1 ? null : 1,
                    });
                }}
            >
                {scope.map((item) => {
                    return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
                })}
            </Menu>
        );
    }, [value]);
    const customFiltersRef = useRef<any>(null);
    const fields = useMemo(() => {        
        return Udesk.business.fields.buildConditionFields({
            fields: typeListCache[type].map(i => ({...i, fieldType: i.fieldType ?? Udesk.enums.fieldTypes.text.id})),
        });
    }, [type, typeListCache]);
    const _fieldValueSettings = useMemo(() => {
        return config.getFilterComponentSettings({});
    }, []);

    const conditions = useMemo(() => {
        return {
            ...value,
            field: value.fieldName,
        };
    }, [value]);

    return (
        <RuleItemWrap className="udesk-qa-risk-setting-rule-item" width={8} color={colorMap[type]}>
            <Space className="udesk-qa-risk-setting-rule-item-top">
                <Select
                    options={typeListOptions}
                    value={type}
                    onChange={(v: string) => {
                        onChange(new RiskSettingRuleItem(v));
                    }}
                />
                {fields && (
                    <CustomFilters
                        key={type}
                        showConditionNumber={false}
                        showRemovalButton={false}
                        showAddButton={false}
                        ref={customFiltersRef}
                        allowEmptyConditions={true}
                        conditions={conditions}
                        fields={fields}
                        fieldValueSettings={_fieldValueSettings}
                        theme="bootstrap"
                        onChanged={(condition) => {
                            const newValue = condition[0] ?? {};
                            newValue.fieldName = newValue.field;
                            onChange({...newValue});
                        }}
                        antdStyle={true}
                        antdSelectComponent={Select}
                        // fieldSelectorClass="form-control input-sm"
                        // operatorSelectorClass="form-control input-sm"
                        // languageCode={props.sdkOptions.props.language}
                        fieldNameField="key"
                        fieldKeyField="key"
                        fieldTextField="label"
                    />
                )}
            </Space>
            <div className="udesk-qa-risk-setting-rule-item-bottom">
                <Space>
                    <div>{/* 适用范围： */}{UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.components.riskSettingRule.index.scopeOfApplication}<Dropdown overlay={menu}>
                        <Button size="small" type="link">
                            {scope.find((s) => s.key === (value.scope ?? 1))?.label}
                            <span className="udesk-qa-react-web-iconfont">&#xe686;</span>
                        </Button>
                    </Dropdown>
                    </div>
                    {value.scope !== 1 && (
                        <InputNumber
                            value={value.scopeValue}
                            onChange={(scopeValue) => {
                                onChange({
                                    ...value,
                                    scopeValue,
                                });
                            }}
                        />
                    )}
                    {value.scope === 2 && /* 天 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.components.riskSettingRule.index.day}
                    {value.scope === 3 && /* 通 */UdeskLocales['current'].pages.gong.businessConfiguration.riskStatement.detail.components.riskSettingRule.index.through}
                </Space>
            </div>
        </RuleItemWrap>
    );
};

const RiskSettingRule = (props) => {
    const { value = [], onChange } = props;

    const [loading, setLoading] = useState(true);
    
    const { typeListOptions } = RiskSettingRuleItem;

    const [typeListCache, setTypeListCache] = useState<any>({});

    useEffect(() => {
        RiskSettingRuleItem.init().then(setTypeListCache).finally(() => {
            setLoading(false);
        });
    }, []);
    const onRuleItemChange = (index, newItem) => {
        const newValue = [...value];
        newValue[index] = newItem;
        onChange?.(newValue);
    };
    return (
        <div className="udesk-qa-risk-setting-rule-list">
            {loading || (
                <UdeskList
                    deleteItem={(index) => {
                        let newValue = [...value];
                        newValue[index] = null;
                        onChange?.(newValue.filter(Boolean));
                    }}
                    addBtnDisabled={RiskSettingRuleItem.loading}
                    addItem={() => {
                        onChange?.([...value, new RiskSettingRuleItem()]);
                    }}
                >
                    {value.map((item, index) => {
                        return (
                            <RuleItem
                                value={item}
                                index={index}
                                typeListOptions={typeListOptions}
                                typeListCache={typeListCache}
                                onChange={onRuleItemChange.bind(null, index)}
                            ></RuleItem>
                        );
                    })}
                </UdeskList>
            )}
        </div>
    );
};

export { RiskSettingRule };

class RiskSettingRuleItem {
    static typeListOptions = Udesk.enums.riskSettingsTypeList.map((i) => ({
        label: i.name,
        value: i.id,
    }));
    static DEFAULT_TYPE = RiskSettingRuleItem.typeListOptions[0].value;

    static typeListCache = {};
    static loading = true;

    static init = () => {
        const source = 9;
        return postRiskSettingsSearchTemplate({
            sourceList: [source], // 数据源，此处固定为9
            typeList: Udesk.enums.riskSettingsTypeList.map((i) => i.id), // 筛选器类型 1-会话活动, 2-会话动作 3-风险语义 4-未跟进天数 5-随录数据，目前固定此五种类型，后续可增减
        }).then((resp) => {
            const typeList = resp.data?.find((item) => item.source === source);
            if (typeList) {
                RiskSettingRuleItem.typeListCache =
                    typeList.typeList?.reduce((prev, cur) => {
                        prev[cur.type!] = cur.fieldList;
                        return prev;
                    }, {}) ?? {};
            }
            RiskSettingRuleItem.loading = false;
            return RiskSettingRuleItem.typeListCache;
        });
    };

    // type;
    // fieldName;
    // operator;
    // value: any = null;
    // scope: any = 1;
    // scopeValue: any = null;

    constructor(type = RiskSettingRuleItem.DEFAULT_TYPE) {
        // this.type = type;
        // this.fieldName = RiskSettingRuleItem.typeListCache[type][0].id;
        // this.operator =
        //     RiskSettingRuleItem.typeListCache[type][0].fieldType !== 16 ? 'is' : 'contains_all';
        return {
            type: type,
            fieldName: RiskSettingRuleItem.typeListCache[type][0].id,
            operator:
                RiskSettingRuleItem.typeListCache[type][0].fieldType !== 16 ? 'is' : 'contains_all',
            value: null,
            scope: 1,
            scopeValue: null,
        };
    }

    // setScope = (scope) => {
    //     this.scope = scope;
    //     this.scopeValue = scope === 1 ? null : 1;
    //     return { ...this };
    // };
}
