// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostWecomSmartTagsSearchQuery,
    PostWecomSmartTagsSearchBody,
    BaseResponseListWecomSmartTagFoundResponse,
} from '../../types';

/**
 * findAll
 *
 * @export
 * @tags wecom-smart-tag-controller
 * @link [POST] /wecomSmartTags/search
 */
export function postWecomSmartTagsSearch(
    data: PostWecomSmartTagsSearchBody
): Promise<BaseResponseListWecomSmartTagFoundResponse>;
export function postWecomSmartTagsSearch(
    data: PostWecomSmartTagsSearchBody,
    options: ApiOptions<never, PostWecomSmartTagsSearchQuery>
): Promise<BaseResponseListWecomSmartTagFoundResponse>;
export function postWecomSmartTagsSearch(
    data: PostWecomSmartTagsSearchBody,
    options?: ApiOptions<never, PostWecomSmartTagsSearchQuery>
): Promise<BaseResponseListWecomSmartTagFoundResponse> {
    return request<PostWecomSmartTagsSearchBody, never, PostWecomSmartTagsSearchQuery>(
        '/wecomSmartTags/search',
        { ...options, data, method: 'post', __$requestCalleeName: 'postWecomSmartTagsSearch' }
    );
}

export const meta = [
    { tags: ['wecom-smart-tag-controller'], path: '/wecomSmartTags/search', method: 'post' },
];
