import Udesk from 'Udesk';
import RoleDetailTaskPermissionsComponent from './component';

class RoleDetailTaskPermissionsRoute extends RoleDetailTaskPermissionsComponent {
    asyncModel() {
        let {
            match,
            sdkOptions
        } = this.props;

        let modelPromise;
        if (match.params.id) {
            let url = Udesk.business.apiPath.concatApiPath(`roleInspectionPermissions/${match.params.id}`, sdkOptions);
            modelPromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        } else {
            modelPromise = new Promise((resolve, reject) => {
                resolve(null);
            });
        }

        return ({
            data: modelPromise
        });
    }

    parseAsyncModel(model) {
        if (model.data && model.data.defaultMenuList) {
            this.privates.defaultMenuList = model.data.defaultMenuList.map((item) => {
                return {id: item};
            });
        }
        if (model.data && model.data.inspectorMenuList) {
            this.privates.inspectorMenuList = model.data.inspectorMenuList.map((item) => {
                return {id: item};
            });
        }
        if (model.data && model.data.taskMenuList) {
            this.privates.taskList = model.data.taskMenuList.map((task) => {
                if (!task.taskMenuList) {
                    task.taskMenuList = [];
                } else {
                    task.taskMenuList = task.taskMenuList.map((item) => {
                        return {id: item};
                    });
                }
                return {
                    taskId: task.taskId,
                    taskName: task.taskName,
                    _tag: this.actions.isAllChecked(task.taskMenuList),
                    taskMenuList: task.taskMenuList,
                };
            });
        }
        this.actions.update();
    }
}

export default RoleDetailTaskPermissionsRoute;
