
import React, { useCallback, useEffect, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { InputNumber, Space } from 'udesk-ui';
import { EnumSelect } from 'Component/common/enum-component';
interface GradingStandartValue {
    commitScore?: number;
    excellentScore?: number;
    scoreStandardUnit?: number;
}
interface GradingStandartProps {
    disabled?: boolean;
    value?: GradingStandartValue;
    onChange?: (value?: GradingStandartValue) => void;
    lessonScore: any;
}

const GradingStandart: React.FC<GradingStandartProps> = (props) => {
    const locales = Locales['current'];
    const { value, onChange, lessonScore, disabled } = props;
    const [commitScore, setCommitScore] = useState<number>();
    const [excellentScore, setExcellentScore] = useState<number>();
    const [scoreStandardUnit, setScoreStandardUnit] = useState<number>(Udesk.enums.scoreStandardUnitType.score.id);

    const triggerChange = (changedValue: GradingStandartValue) => {
        const newValue = {
            commitScore,
            excellentScore,
            scoreStandardUnit,
            ...changedValue,
        };
        if (newValue.commitScore == null || newValue.excellentScore == null || newValue.scoreStandardUnit == null) {
            return onChange?.(undefined);
        } else {
            return onChange?.(newValue);
        }
    };

    const onCommitScoreChange = (val: number) => {
        setCommitScore(val);
        triggerChange({ commitScore: val });
    };

    const onExcellentScoreChange = (val: number) => {
        setExcellentScore(val);
        triggerChange({ excellentScore: val });
    };

    const onScoreStandardUnitChange = (val: number) => {
        setScoreStandardUnit(val);
        triggerChange({ scoreStandardUnit: val });
    };

    useEffect(() => {
        if (value) {
            setCommitScore(value?.commitScore);
            setExcellentScore(value?.excellentScore);
            setScoreStandardUnit(value?.scoreStandardUnit || Udesk.enums.scoreStandardUnitType.score.id);
        }
    }, [value]);

    const getMax = useCallback((scoreStandardUnit) => {
        if (scoreStandardUnit === Udesk.enums.scoreStandardUnitType.score.id) {
            return lessonScore.total;
        }
        return 100;
    }, [lessonScore.total]);

    return (
        <Space direction="vertical">
            <Space align="center">
                <span>{`${locales.pages.gong.coachTaskCenter.edit.commitScore}≥`}</span>
                <InputNumber
                    disabled={disabled}
                    style={{ width: 80 }}
                    min={0}
                    max={getMax(value?.scoreStandardUnit || scoreStandardUnit)}
                    value={value?.commitScore || commitScore}
                    onChange={onCommitScoreChange}
                />
                <EnumSelect 
                    disabled={disabled}
                    enumKey='scoreStandardUnitType' 
                    value={value?.scoreStandardUnit || scoreStandardUnit} 
                    onChange={onScoreStandardUnitChange}
                />
            </Space>
            <Space align="center">
                <span>{`${locales.pages.gong.coachTaskCenter.edit.excellentScore}≥`}</span>
                <InputNumber
                    disabled={disabled}
                    style={{ width: 80 }}
                    min={0}
                    max={getMax(value?.scoreStandardUnit || scoreStandardUnit)}
                    value={value?.excellentScore || excellentScore}
                    onChange={onExcellentScoreChange}
                />
                <EnumSelect 
                    disabled={disabled}
                    enumKey='scoreStandardUnitType' 
                    value={value?.scoreStandardUnit || scoreStandardUnit} 
                    onChange={onScoreStandardUnitChange}
                />
            </Space>
        </Space>
    );
};

export default GradingStandart;
