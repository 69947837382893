import { NotifyPluginClass } from 'udesk-web-toolkits/src/udesk/ui/notify.js';

class CustomNotifyPluginClass extends NotifyPluginClass {
    show(type, options) {
        let message = null;
        if (options && options.message) {
            message = options.message;
        }
        if (!message) {
            return;
        }
        switch (type) {
            case "error":
                this.defaults.log("danger", message);
                break;
            case "warn":
                this.defaults.log("warning", message);
                break;
            case "success":
                this.defaults.log("success", message);
                break;
            case "info":
                this.defaults.log("info", message);
                break;
            default:
                this.defaults.log("success", message);
        }
    }
    destroy() {
        this.defaults = null;
    }
    getDefaults() {
        return {};
    }
}

export default CustomNotifyPluginClass;
