import React from 'react';
import Udesk from 'Udesk';
class MassagePushConfigurationComponent extends React.Component {
    privates = {
        messagePushType: [],
        messagePushList: {},
    };
    actions = {
        onMessagePushTypeChange(pushConf, actionType){
            let { pushConf: propsPushConf } = this.props;
            // for(let i = 0; i < propsPushConf.length; i++){
            //     if(propsPushConf[i].actionType === pushConf.actionType){
            //         let newPushConf = {...pushConf};
            //         newPushConf.enabled = !newPushConf.enabled ? 1 : 0;
            //         propsPushConf[i] = newPushConf;
            //     }
            // }
            let newPushConf = [...propsPushConf];
            let currentPushConf = newPushConf.find(item => item.actionType === pushConf.actionType);
            if(currentPushConf){
                currentPushConf.enabled = !currentPushConf.enabled ? 1 : 0;
            }else{
                newPushConf.push({
                    actionType,
                    enabled: 1,
                });
            }
            this.trigger('onPushConfChange', newPushConf);
        },
        handleChange(pushConf, value){
            let { pushConf: propsPushConf } = this.props;

            for(let i = 0; i < propsPushConf.length; i++){
                if(propsPushConf[i].actionType === pushConf.actionType){
                    let newPushConf = {...pushConf};
                    newPushConf.actionDetailId = parseInt(value, 10);
                    propsPushConf[i] = newPushConf;
                }
            }
            this.trigger('onPushConfChange', [...propsPushConf]);
        }
    };

    //#region Life Cycle
    init(){
        this.privates.messagePushType = Udesk.enums.messagePushType.filter(this.props.messagePushTypeFilter || (i => i.id <= 2 || i.id === 6));
        if(this.props.type === Udesk.enums.messageType.timing.id){
            this.privates.messagePushType = this.privates.messagePushType.filter(i => i.id === Udesk.enums.messagePushType.email.id);
        }
    }
    componentDidMount(changedParams) {
        this.privates.messagePushType.forEach(type => {
            let url = Udesk.business.apiPath.concatApiPath(
                `eventLists/enable/${type.id}?pageNum=1&pageSize=1000`,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    if (resp.data) {
                        this.privates.messagePushList[type.id] = resp.data;
                        this.actions.update();
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
    
        });
    }
    //#endregion
}
export default MassagePushConfigurationComponent;