import React from 'react';
import Udesk from 'Udesk';
import TestSetListRoute from './route';
// import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import UdeskTable from 'Component/common/udesk-table';
import ReactModal from 'udesk-react/src/components/react-modal';
import './style.scss';
import _concat from 'lodash-es/concat';
import _isEmpty from 'lodash-es/isEmpty';
import _toNumber from 'lodash-es/toNumber';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import { Popover, PageHeader, Button, Space } from 'udesk-ui';
import ReInspectionModel from 'Component/pages/components/reinspection-model';
import ExportButton from 'Component/common/export-button';

export default class TestSetListTemplate extends TestSetListRoute {
    render() {
        let { sdkOptions } = this.props;
        let { locales, actions } = this;
        let {
            filterVisible,
            reInspectionModalVisible,
            progressStatus,
            alreadyDoneCount,
            recheckProcessTotal,
            progressValue,
            total,
            testSetData,
            taskType,
            reInspectionConfigVisible,
            reInspectionType,
            allPoints,
            reInspectionPoints,

            customName,
            customId,
            tableRef,
            loading,
        } = this.privates;
        let {
            conditionList,
            judgeStrategy,
            customJudgeLogic,
            pageNum,
            pageSize,
        } = this.privates.storages;
        let { _columns, _dataList, _cacheKey } = this.privates.computes;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        let columns = _columns.length ? _concat(_columns, [
            {
                key: 'actions',
                title: locales.fields.customerInformation.list.actions,
                render: (name, item, index) => {
                    return (
                        <div className='qa-react-page-body-content-table-actions'>
                            <If condition={hasFeature && hasFeature("task:work:testset:view")}>
                                <a
                                    href=""
                                    className='action-btn'
                                    title={locales.labels.view}
                                    onClick={actions.detailRoute.params(item)}>
                                    {locales.labels.view}
                                </a>
                            </If>
                            <If condition={hasFeature && hasFeature("task:work:testset:remove")}>
                                <a
                                    href=""
                                    className='action-btn delete-btn'
                                    title={locales.labels.delete}
                                    style={{ marginLeft: '10px' }}
                                    onClick={actions.testSetDel.params(item)}
                                >
                                    {locales.labels.delete}
                                </a>
                            </If>
                        </div>
                    );
                }
            }
        ]) : [];
        let customCard = (
            <div className='custom-filter-info-type'>
                <div className='index-management-custom-filter-Info'>
                    <div>{locales.components.common.customFilter.customFilterCondition}：
                        {
                            _isEmpty(conditionList) && (locales.components.common.customFilter.none)
                        }
                    </div>
                    <div>
                        {!_isEmpty(conditionList) && this.actions.fieldsFilterChange({ conditionList: this.actions.getQueryConditionList(conditionList) })}
                    </div>
                </div>
                <div>
                    {locales.components.common.customFilter.conditionRule}：
                    <span>
                        {
                            judgeStrategy !== 3 && Udesk.enums.operatorRuleTypes.find(current => current.id === (judgeStrategy || Udesk.enums.operatorRuleTypes.all.id)).name
                        }
                        {
                            judgeStrategy === 3 && customJudgeLogic
                        }
                    </span>
                </div>
            </div>
        );
        let isRelated = sdkOptions.props?.task?.related??false;
        return (
            // <div className='test-set-list-page' >
            <div className='udesk-qa-web-page'>
                <PageHeader className='udesk-qa-web-page-header' title={locales.components.pages.testSetList.title} subTitle={locales.components.pages.testSetList.tip} />
                <If condition={hasFeature && hasFeature('task:work:testset:view')}>
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root test-set-list-page'>
                            <div className='row qa-react-list-page'>
                                <div className='udesk-qa-modal-body-content' style={{ padding: '20px 30px' }}>
                                    <div className='test-set-list-evaluation-information'>
                                        <div className='evaluation-information-list'>
                                            {testSetData && !_isEmpty(testSetData) ? (
                                                <div className='evaluation-result'>
                                                    <div className='test-set-list-result-time'>
                                                        <span className='test-set-list-price-title'>
                                                            {
                                                                locales.components.pages.testSetList.reultList
                                                                    .assessmentTime
                                                            }
                                                        </span>
                                                        <span>{testSetData && testSetData.assessmentTime}</span>
                                                    </div>
                                                    <div className='test-set-list-result-info'>
                                                        <div className='test-set-list-price'>
                                                            <div className='test-set-list-price-title'>
                                                                {
                                                                    locales.components.pages.testSetList.reultList
                                                                        .assessmentAll
                                                                }
                                                            </div>
                                                            <div className='test-set-list-price-number'>
                                                                <div>
                                                                    {testSetData && testSetData.assessmentCount}
                                                                    {
                                                                        locales.components.pages.testSetList.reultList
                                                                            .strip
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {testSetData && testSetData.pointCount}
                                                                    {
                                                                        locales.components.pages.testSetList.reultList
                                                                            .spot
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='test-set-list-price'>
                                                            <div className='test-set-list-price-title'>
                                                                {
                                                                    locales.components.pages.testSetList.reultList
                                                                        .deviation
                                                                }
                                                            </div>
                                                            <div className='test-set-list-price-number'>
                                                                <div>
                                                                    {testSetData && testSetData.offsetDataCount}
                                                                    {
                                                                        locales.components.pages.testSetList.reultList
                                                                            .strip
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {testSetData && testSetData.offsetPointCount}
                                                                    {
                                                                        locales.components.pages.testSetList.reultList
                                                                            .spot
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='test-set-list-price'>
                                                            <div className='test-set-list-price-title'>
                                                                {
                                                                    locales.components.pages.testSetList.reultList
                                                                        .accuracyRate
                                                                }
                                                            </div>
                                                            <div className='test-set-list-price-number'>
                                                                <div>
                                                                    {testSetData &&
                                                                        `${(
                                                                            _toNumber(
                                                                                testSetData.dataAccuracyRate || 0
                                                                            ) * 100
                                                                        ).toFixed(2)}%`}
                                                                </div>
                                                                <div>
                                                                    {testSetData &&
                                                                        `${(
                                                                            _toNumber(
                                                                                testSetData.pointAccuracyRate || 0
                                                                            ) * 100
                                                                        ).toFixed(2)}%`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='evaluation-result'>
                                                    <span>{locales.components.pages.testSetList.reultList.info}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className='evaluation-information-button'>
                                            {/*<div className="button-checkbox-evaluation" >
                                                <input type="checkbox" onClick={actions.checkBoxButton} />{locales.components.pages.testSetList.reultList.onlyDeviationInfo}
                                            </div>*/}
                                            <Space>
                                                <If condition={hasFeature && hasFeature('task:work:testset:view')}>
                                                    <Popover content={customCard} placement='bottomRight'>
                                                        <Button onClick={actions.filterShow}>
                                                            {locales.components.pages.testSetList.reultList.screen}
                                                        </Button>
                                                    </Popover>
                                                </If>
                                                <If condition={!isRelated && hasFeature && hasFeature('task:work:testset:reassessment')}>
                                                    <Button
                                                        onClick={actions.changeReInspectionConfigVisible}
                                                    >
                                                        {locales.components.pages.testSetList.reultList.againAssessment}
                                                    </Button>
                                                    <ReInspectionModel
                                                        type='test-set-list'
                                                        title={locales.components.pages.testSetList.recheckPointTitle}
                                                        allPoints={allPoints}
                                                        reInspectionConfigVisible={reInspectionConfigVisible}
                                                        reInspectionPoints={reInspectionPoints}
                                                        reInspectionType={reInspectionType}
                                                        onOk={actions.reInspection}
                                                        onPointsCheckedChange={actions.onPointsCheckedChange}
                                                        onCancel={actions.changeReInspectionConfigVisible}
                                                    />
                                                    {/* <ReactModal visible={reInspectionConfigVisible} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.changeReInspectionConfigVisible} footer={null} maskClosable={false}>
                                                        <div className="udesk-qa-modal-body-content re-inspection-modal">
                                                            <div className="re-inspection-modal-progress-header">
                                                                {locales.components.pages.qualityInspectionList.reQualityInspectionConfig}
                                                            </div>
                                                            <div className="re-inspection-modal-config-content">
                                                                <Radio.Group onChange={actions.reInspectionTypeChanged} value={reInspectionType}>
                                                                    {
                                                                        Udesk.enums.reInspectionTypes.filter(type => type.id === Udesk.enums.reInspectionTypes.recheckPoint.id).map(type => {
                                                                            return (
                                                                                <React.Fragment>
                                                                                    <Radio value={type.id} style={{ display: type.key === "recheckPoint" ? "inline-block" : "" }}>{locales.components.pages.testSetList.recheckPoint}</Radio>
                                                                                    <If condition={type.key === "recheckPoint"}>
                                                                                        <Checkbox disabled={reInspectionType !== Udesk.enums.reInspectionTypes.recheckPoint.id} checked={allPoints} onChange={actions.allPointsChecked}>
                                                                                            {locales.labels.selectAll}
                                                                                        </Checkbox>
                                                                                    </If>
                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                    }
                                                                </Radio.Group>
                                                                <div className="re-inspection-modal-config-content-points">
                                                                    <For each="firstCategory" index="index" of={reInspectionPoints}>
                                                                        <div className="re-inspection-modal-config-content-points-category" title={firstCategory.name}>
                                                                            {firstCategory.name}
                                                                        </div>
                                                                        <For each="secondCategory" index="index" of={firstCategory.children}>
                                                                            <div className="re-inspection-modal-config-content-points-category" title={secondCategory.name}>
                                                                                {secondCategory.name}
                                                                            </div>
                                                                            <For each="point" index="index" of={secondCategory.points}>
                                                                                <Checkbox className="re-inspection-modal-config-content-points-point" disabled={reInspectionType !== Udesk.enums.reInspectionTypes.recheckPoint.id} checked={point._checked} onChange={actions.pointChecked.params(point)} title={point.name} style={{ marginLeft: "0px" }}>
                                                                                    {point.name}
                                                                                </Checkbox>
                                                                            </For>
                                                                        </For>
                                                                    </For>
                                                                </div>
                                                            </div>
                                                            <div className="re-inspection-modal-progress-footer">
                                                                <span className="btn btn-default pull-right" onClick={actions.changeReInspectionConfigVisible}>
                                                                    {locales.components.pages.qualityInspectionList.cancelReQualityInspection}
                                                                </span>
                                                                <span className="btn btn-default pull-right end-reinspection-button" onClick={actions.reInspection}>
                                                                    {locales.components.pages.qualityInspectionList.beginReQualityInspection}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ReactModal> */}
                                                </If>
                                                <If condition={hasFeature && hasFeature('task:work:testset:batchremove')}>
                                                    <Button onClick={actions.batchDeletion}>
                                                        {locales.components.pages.testSetList.reultList.batchDelete}
                                                    </Button>
                                                </If>
                                                <ExportButton disabled={loading} exportDataCount={total} hasFilterCondition={!!conditionList.length} onClick={actions.exportDataList} />
                                                <Button onClick={actions.onPopoverVisible}>
                                                    {locales.components.pages.customerServiceRankList.select.column}
                                                </Button>
                                            </Space>
                                        </div>
                                    </div>
                                    <div className='test-set-list-table'>
                                        <UdeskTable
                                            columns={columns}
                                            dataSource={_dataList}
                                            subtractedHeight={194}
                                            ref={tableRef}
                                            loading={loading}
                                            rowKey={(item) => item.id}
                                            // horizontalScrollbar={true}
                                            // showHorizontalScrollbar={true}
                                            // enableChooseColumns={true}
                                            cacheKey={_cacheKey}
                                            needFixedActionColumn={true}
                                            pagination={false}
                                        />
                                        {/* <div style={{marginTop: 16}}>
                                            <ReactPager
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                                languageCode={sdkOptions.props.language}
                                                pageChanged={actions.pageChanged}
                                            />
                                        </div> */}
                                        <div style={{marginTop: 8}}>
                                            <UdeskPagination
                                                current={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                                onChange={actions.pageChanged}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CustomFilterModal
                                visible={filterVisible}
                                cancel={actions.handleConditionCancel}
                                applyAction={actions.handleConditionApply}
                                judgeStrategy={judgeStrategy}
                                customJudgeLogic={customJudgeLogic}
                                conditionList={conditionList}
                                type={this.privates.type}
                                customName={customName}
                                customId={customId}
                                filterList={['f_8']}
                                taskId={this.props.match.params.taskId}
                                taskName={this.props.sdkOptions.props.task.name}
                                testTaskId={this.props.match.params.taskId}
                                onConditionFilterSaved={actions.onConditionFilterSaved}
                            />

                            <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save}
                                visible={reInspectionModalVisible}
                                closeIconClass='udesk-qc-iconfont icon-qc-quxiao pull-right'
                                onCancel={actions.closeReInspection}
                                footer={null}
                            >
                                <div className='udesk-qa-modal-body-content re-inspection-modal'>
                                    <div class='re-inspection-modal-progress-header'>
                                        <If
                                            condition={
                                                progressStatus === Udesk.enums.recheckStatus.waitForExecution.id ||
                                                progressStatus === Udesk.enums.recheckStatus.inExecution.id
                                            }
                                        >
                                            {taskType === 5 ? (
                                                <span>
                                                    {locales.components.pages.testSetList.reQualityInspectionUnderWay}
                                                </span>
                                            ) : (
                                                taskType === 6 && (
                                                    <span>
                                                        {
                                                            locales.components.pages.testSetList.delete
                                                                .reQualityInspectionUnderWay
                                                        }
                                                    </span>
                                                )
                                            )}
                                        </If>
                                        <If condition={progressStatus === Udesk.enums.recheckStatus.success.id}>
                                            {taskType === 5 ? (
                                                <span>
                                                    {locales.components.pages.testSetList.reQualityInspectionDone}
                                                </span>
                                            ) : (
                                                taskType === 6 && (
                                                    <span>
                                                        {
                                                            locales.components.pages.testSetList.delete
                                                                .reQualityInspectionDone
                                                        }
                                                    </span>
                                                )
                                            )}
                                        </If>
                                    </div>
                                    <div class='re-inspection-modal-progress-content'>
                                        <div class='re-inspection-modal-progress-bar-tip'>
                                            <span class='pull-left'>
                                                {taskType === 5 ? (
                                                    <span>
                                                        {Udesk.utils.string.format(
                                                            locales.components.pages.testSetList.alreadyDoneTip,
                                                            alreadyDoneCount
                                                        )}
                                                    </span>
                                                ) : (
                                                    taskType === 6 && (
                                                        <span>
                                                            {Udesk.utils.string.format(
                                                                locales.components.pages.testSetList.delete
                                                                    .alreadyDoneTip,
                                                                alreadyDoneCount
                                                            )}
                                                        </span>
                                                    )
                                                )}
                                            </span>
                                            <If condition={recheckProcessTotal}>
                                                <span class='pull-right'>
                                                    {taskType === 5 ? (
                                                        <span>
                                                            {Udesk.utils.string.format(
                                                                locales.components.pages.testSetList.totalTip,
                                                                recheckProcessTotal
                                                            )}
                                                        </span>
                                                    ) : (
                                                        taskType === 6 && (
                                                            <span>
                                                                {Udesk.utils.string.format(
                                                                    locales.components.pages.testSetList.delete
                                                                        .totalTip,
                                                                    recheckProcessTotal
                                                                )}
                                                            </span>
                                                        )
                                                    )}
                                                </span>
                                            </If>
                                        </div>
                                        <div class='re-inspection-modal-progress-bar'>
                                            <span class='re-inspection-modal-progress-bar-completed-text'>
                                                {progressValue + '%'}
                                            </span>
                                            <span
                                                class='re-inspection-modal-progress-bar-completed'
                                                style={{ width: progressValue + '%' }}
                                            ></span>
                                        </div>
                                    </div>
                                    <div class='re-inspection-modal-progress-footer'>
                                        <If
                                            condition={
                                                progressStatus === Udesk.enums.recheckStatus.waitForExecution.id ||
                                                progressStatus === Udesk.enums.recheckStatus.inExecution.id
                                            }
                                        >
                                            <span class='pull-left re-inspection-modal-progress-footer-tip'>
                                                {taskType === 5 ? (
                                                    <span>
                                                        {
                                                            locales.components.pages.testSetList
                                                                .reQualityInspectionUnderWayTip
                                                        }
                                                    </span>
                                                ) : (
                                                    taskType === 6 && (
                                                        <span>
                                                            {
                                                                locales.components.pages.testSetList.delete
                                                                    .reQualityInspectionUnderWayTip
                                                            }
                                                        </span>
                                                    )
                                                )}
                                            </span>
                                            <span
                                                class='btn btn-default pull-right'
                                                onClick={actions.closeReInspection}
                                            >
                                                {taskType === 5 ? (
                                                    <span>{locales.components.pages.testSetList.closeWindow}</span>
                                                ) : (
                                                    taskType === 6 && (
                                                        <span>
                                                            {locales.components.pages.testSetList.delete.closeWindow}
                                                        </span>
                                                    )
                                                )}
                                            </span>
                                            <span
                                                class='btn red pull-right end-reinspection-button'
                                                onClick={actions.endReQualityInspection}
                                            >
                                                {taskType === 5 ? (
                                                    <span>
                                                        {locales.components.pages.testSetList.endReQualityInspection}
                                                    </span>
                                                ) : (
                                                    taskType === 6 && (
                                                        <span>
                                                            {
                                                                locales.components.pages.testSetList.delete
                                                                    .endReQualityInspection
                                                            }
                                                        </span>
                                                    )
                                                )}
                                            </span>
                                        </If>
                                        <If condition={progressStatus === Udesk.enums.recheckStatus.success.id}>
                                            <span class='pull-left re-inspection-modal-progress-footer-tip'>
                                                {taskType === 5 ? (
                                                    <span>
                                                        {
                                                            locales.components.pages.testSetList
                                                                .reQualityInspectionDoneTip
                                                        }
                                                    </span>
                                                ) : (
                                                    taskType === 6 && (
                                                        <span>
                                                            {
                                                                locales.components.pages.testSetList.delete
                                                                    .reQualityInspectionDoneTip
                                                            }
                                                        </span>
                                                    )
                                                )}
                                            </span>
                                            <span
                                                class='btn primary pull-right'
                                                onClick={actions.closeReInspection.params('success')}
                                            >
                                                {locales.labels.confirm}
                                            </span>
                                        </If>
                                    </div>
                                </div>
                            </ReactModal>
                        </div>
                    </div>
                </If>
            </div>
        );

    }
}
