// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerHomePageAdminTaskDetailQuery,
    BaseResponseIntelligentPartnerHomePageAdminTaskDetailResponse,
} from '../../../../types';

/**
 * 管理员端任务详情接口
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [GET] /intelligentPartner/homePage/admin/taskDetail
 */
export function getIntelligentPartnerHomePageAdminTaskDetail(): Promise<BaseResponseIntelligentPartnerHomePageAdminTaskDetailResponse>;
export function getIntelligentPartnerHomePageAdminTaskDetail(
    options: ApiOptions<never, GetIntelligentPartnerHomePageAdminTaskDetailQuery>
): Promise<BaseResponseIntelligentPartnerHomePageAdminTaskDetailResponse>;
export function getIntelligentPartnerHomePageAdminTaskDetail(
    options?: ApiOptions<never, GetIntelligentPartnerHomePageAdminTaskDetailQuery>
): Promise<BaseResponseIntelligentPartnerHomePageAdminTaskDetailResponse> {
    return request<never, never, GetIntelligentPartnerHomePageAdminTaskDetailQuery>(
        '/intelligentPartner/homePage/admin/taskDetail',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerHomePageAdminTaskDetail',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/admin/taskDetail',
        method: 'get',
    },
];
