export default {
  lang: {
    export: 'ส่งออก',
    successfully: 'สร้างงานส่งออกสำเร็จ',
    pleaseJumpTo: 'กรุณาข้ามไปที่',
    uploadAndDownloadCenter: 'ศูนย์อัปโหลดและดาวน์โหลด',
    check: 'ดู',
    exportAll: 'ยืนยันส่งออกเนื้อหาทั้งหมด?',
    total: 'รวม',
    confirm: 'ข้อมูลยืนยันการส่งออก?',
    error: 'จำนวนข้อมูลที่สามารถส่งออกเป็น0โปรดเปลี่ยนตัวกรองและลองอีกครั้ง',
  },
};
