export default {
  labels: {
    clear: "Leeg",
    refresh: "Vernieuwen",
    nextStep: "Volgende stap",
    prevStep: "Vorige stap",
    closeDriver: "Boot afsluiten",
    complete: "Compleet",
    viewStatistics: "Statistieken bekijken",
    colon: "：",
    splitter: "/",
    remark: "Opmerkingen",
    correction: "Foutcorrectie",
    save: "Bespaar",
    reset: "Reset",
    increase: "Toevoegen",
    preview: "Voorbeeld",
    unit: "Artikel",
    cancel: "Annuleer",
    all: "Allemaal",
    searchPlaceHolder: "Zoekwoordquery invoeren",
    greaterThan: "Groter",
    lessThan: "Minder",
    second: "Seconden",
    description: "Beschrijving",
    column: "Kolom",
    pleaseChoice: "Selecteer alstublieft",
    filter: "Filter",
    saveAndSubmit: "Opslaan en verzenden",
    submit: "Indiening",
    appeal: "Klacht",
    review: "Beoordeling",
    choose: "Selecteer",
    manage: "Beheer",
    delete: "Verwijderen",
    add: "Nieuw",
    create: "Nieuw",
    edit: "Bewerken",
    name: "Naam",
    describe: "Beschrijving",
    next: "Volgende stap",
    percent: "%",
    export: "Uitvoer",
    exportAll: "Alle exporteren",
    confirm: "Bepaal",
    back: "Terugkeer",
    versionUpdate:
      "Ik ben blij u te kunnen meedelen dat onze website zojuist een nieuwe versie heeft uitgebracht! Om uw normale gebruik te garanderen, moeten we uw huidige werk onmiddellijk opslaan en vervolgens de pagina vernieuwen (klik op de knop Vernieuwen van de browser of druk op de F5-toets) om onze nieuwe versie van kracht te maken. Hartelijk dank voor uw medewerking!",
    more: "Meer",
    selected: "Geselecteerd",
    deduct: "Gesp",
    maxScore: "De meeste",
    scoreUnit: "Punten",
    pointUnit: "Een",
    comma: "，",
    view: "Weergave",
    batchingImport: "Importeren",
    deleteContent:
      "Weet u zeker dat u het wilt verwijderen? Je kunt het niet herstellen na verwijdering",
    import: "Importeren",
    uploadFile: "Bestand uploaden",
    downloadFile: "Bestand exporteren",
    upload: "Uploaden",
    click: "Klik",
    downloadTemplate: "Sjabloon downloaden",
    fillInTheForm: "Vul in op formaat",
    UploadExcelfile: "Excel uploaden om de batchimport te voltooien",
    uploadIdentical:
      "Als de API-naam bestaat, worden de bestaande gegevens in het systeem bijgewerkt tijdens het importeren.",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Toepasselijk",
    leftBracket: "(",
    rightBracket: ")",
    question: "Vraag",
    answer: "Antwoord",
    content: "Inhoud",
    search: "Zoeken",
    query: "Vraag",
    cancelEdit: "Bewerken annuleren",
    noValue: "Geen gegevens",
    selectAll: "Alles selecteren",
    changeAGroup: "Een groep veranderen",
    arrow: " ---> ",
    saveReview: "Inzending beoordelen",
    saveSpotCheck: "Bemonstering inspectie en indiening",
    rapidSampling: "Snelle bemonstering",
    file: "Archivering",
    download: "Download",
    lastPage: "Vorige pagina",
    nextPage: "Volgende pagina",
    batchActions: "Batch operatie",
    cancelDistribution: "Toewijzen ongedaan maken",
    againDistribution: "Opnieuw toewijzen",
    deleteRecord: "Record verwijderen",
    oneOptionOpertor: "Facultatieve kwaliteitsinspecteur",
    allOptionOpertor: "Alle kwaliteitsinspecteurs",
    distributionInfoFirstOne: "Bevestigen",
    distributionInfoFirstTwo:
      "Wilt u records opnieuw toewijzen? Selecteer een toewijzingsdoel.",
    distributionInfoSecondOne: "OK om te annuleren",
    distributionInfoSecondTwo:
      "Is de toewijzingsinformatie van dit record? Na annulering wordt het een niet-toegewezen beoordelingsrecord.",
    distributionInfoSecondThree:
      "Is de toewijzingsinformatie van dit record, dan wordt het na annulering een niet-toegewezen steekproefrecord.",
    distributionInfoThirdOne: "De geselecteerde records hebben",
    distributionInfoThirdTwo: "De beoordeelde records worden niet verwerkt.",
    distributionInfoThirdThree:
      "Records die zijn gesampled, worden niet verwerkt.",
    exportError: "Export mislukt",
    exportSuccess: "Export geslaagd",
    deleteRecordInfo:
      "Weet u zeker dat u {0} records wilt verwijderen? Gegevens kunnen niet worden hersteld na verwijdering",
    pleaseSetFilter: "Selecteer filtercriteria",
    noCountAfterFilter: "Geen exportgegevens, reset de filtercriteria",
    redo: "Heruitvoering",
    batchMove: "Batch Move",
    websiteName: "Intelligente kwaliteitsinspectie",
    salesEnable: "Verkoopempowerment",
    systemName: "Intelligente kwaliteitsinspectie",
    initSystemError:
      "Netwerkuitzondering of fout bij het initialiseren van pagina's, neem contact op met de klantenservice",
    day: "Dag",
    hour: "Uren",
    minute: "Punten",
    questionMark: "？",
    personalSettings: "Persoonlijke instellingen",
    logout: "Afschrijven",
    ok: "Bepaal",
    saveAs: "Bespaar",
    pleaseEnter: "Voer alstublieft in",
    executeMessage: {
      executeSuccess: "{0} succesvol uitgevoerd",
      executeError: "{0} is niet uitgevoerd",
    },
    field: "Veld",
    operator: "Exploitant",
    value: "Waarde",
    placeholder: "Selecteer alstublieft",
    pleaseSelect: "Selecteer alstublieft",
    totalScore: "Totaal score",
    hyphen: "-",
    semicolon: "；",
    wfm: "Intelligente planning",
    user: "Gebruiker",
    none: "Geen",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Bespaar met succes! Effectief nadat relevante werknemer pagina vernieuwt",
    saveAsName: "{0}(kopie)",
    systemInformation: "Systeemgegevens bekijken",
    billingCenter: "Factureringscentrum",
    warningMessage:
      "Het resterende quotum is onvoldoende, laad alstublieft zo snel mogelijk op",
    fileTypeHolder:
      "Het bestandsformaat is onjuist. Upload een bestand in {0} formaat.",
    dataNo: "Geen gegevens",
    move: "Verplaatsen",
    study: "U hebt ongemarkeerde zelflerende kwaliteitsinspectiepunten",
    studySuccess: "Etikettering succes",
    studyError: "Etikettering mislukt",
    editType: "Categorie-etiketten bewerken",
    copyed: "Gekopieerd naar Klembord",
    clickToCopy: "Klik op Kopiëren",
    addFilterConditions: "Voeg filtervoorwaarde toe",
    filterConditions: "Filterconditie",
    messageTime: "Berichttijd",
    messageType: "Berichttype",
    pushType: "Berichtkanaal",
    messageContent: "Berichtinhoud",
    messageName: "Naam bericht",
    messageStatus: "Berichtstatus",
    configuration: "Configuratie-informatie",
    sendTarget: "Bestemming verzenden",
    guideUrl: "Bootstrap URL",
    noticeDetail: "Details van het bericht",
    action: "Operatie",
    seeDetail: "Details bekijken",
    theDay: "Dag {0}",
    theLastDayOfMonth: "De laatste dag van elke maand",
    ignore: "Negeren",
    seeNow: "Nu bekijken",
    exportConfirmText: {
      export: "Een totaal van {0} gegevens, bevestigen te exporteren?",
      exportAll: "Bevestig om alle inhoud te exporteren?",
    },
    samrTagAdd: "Tags toevoegen",
    deletePopconfirmContent: "Weet u zeker dat u wilt verwijderen?",
    TheLastThirtyDays: "Laatste 30 dagen",
    addNumber: "Plus",
    subNumber: "Min",
    addStrategy: "Beleid toevoegen",
    minutes: "Minuten",
    status: "Ingeschakelde status",
    pushConfig: "Push-configuratie",
    category: "Indeling",
    and: "Met",
    interval: "Interval",
    switchOn: "Open",
    switchOff: "Sluiten",
    batchDel: "Batch verwijderen",
    exportTxt: "TXT-tekst exporteren",
    playSpeed: "Dubbele snelheid",
    messageLogBtn: "Bekijk meldingen/gesprekken die meldingen activeren",
    dataLink: "Datalink",
    yes: "Ja",
    no: "Nee",
    sentenceUnit: "Zin",
    appealDetail: "Details van het Klachtenrecord",
    detail: "Details",
    default: "Standaard",
    customer: "Klant",
    agent: "Klantenservice",
    sort: "Sorteren",
    applyNow: "Toepassing",
    index: "Serienummer",
    allColumns: "Kolommen die kunnen worden toegevoegd",
    selectedColumns: "Kolommen toegevoegd",
    syn: "Synchronisatie",
    saveAndNext: "Opslaan en maken volgende",
    viewReference: "Referenties bekijken",
    referenceDetail: "Referentiegegevens",
    goToQaList: "Ga naar kwaliteitsinspectie",
    goToCheck: "Ga naar spotcontrole",
    businessCard: "Visitekaartje",
    weapp: "Klein programma",
    redpacket: "Rode envelop",
    externalRedpacket: "Wissel rode enveloppen in",
    sphfeed: "Videonummer",
    exportTaskCreated: "Taak exporteren met succes gemaakt",
    plzGoTo: "Spring alsjeblieft",
    importTaskCreated: "Importeer taak die met succes is gemaakt",
    taskCreated: "Taak met succes gemaakt",
    reply: "Antwoord",
    replied: "Antwoord",
    release: "Publiceren",
    expandMore: "Breid andere {0} antwoorden uit",
    comment: "Opmerkingen",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Gelieve ten minste één {0} te hebben",
      canNotBeEmptyFormat: "{0} kan niet leeg zijn",
      repeatErrorTipFormat: "{0} heeft duplicaten",
      getInformationFailedFormat:
        "Het is mislukt om {0} informatie te krijgen. Controleer uw netwerkverbinding of probeer het later opnieuw",
      deleteSuccessFormat: "{0} is met succes verwijderd.",
      deleteFailedFormat:
        "{0} kan niet worden verwijderd. Probeer het later opnieuw.",
      saveSuccessFormat: "{0} met succes opgeslagen.",
      saveFailedFormat:
        "{0} kon niet worden opgeslagen. Probeer het later opnieuw.",
      fileSuccessFormat: "{0} succesvol gearchiveerd.",
      fileFailedFormat:
        "{0} archivering is mislukt. Probeer het later opnieuw.",
      exportSuccessFormat: "{0} export geslaagd",
      exportFailedFormat: "{0} export is mislukt. Probeer het later opnieuw.",
      submitSuccessFormat: "{0} met succes ingediend.",
      submitFailedFormat: "{0} is niet verzonden. Probeer het later opnieuw.",
      confirmSuccessFormat: "De {0} bevestiging was succesvol.",
      confirmFailedFormat:
        "De {0} bevestiging is mislukt. Probeer het later opnieuw.",
      pleaseUploadFile: "Upload het bestand eerst!",
      importFileSuccess: "Bestand importeren succesvol!",
      importFildFailed: "Het importeren van bestanden is mislukt!",
      deleteSuccess: "Succesvol verwijderd!",
      deleteFailed: "Verwijderen mislukt, probeer het later opnieuw!",
      illegalTitle: "Onvoldoende machtigingen",
      illegalMessage:
        "U hebt niet enkele of alle machtigingen voor de volgende stap, neem contact op met de beheerder om te configureren, klik op de OK knop zal de boot",
      illegalMessageCurrent:
        "U hebt niet enkele of alle machtigingen voor de huidige stap, neem contact op met de beheerder om te configureren, klik op de OK knop zal de boot",
      skipMessage: "Als u deze stap nu wilt overslaan, klikt u op Overslaan",
      errorMsg500:
        "Serververzoek is mislukt, controleer uw werking of vernieuw en probeer het opnieuw",
      errorMsg404: "Ooops... de pagina die je zoekt ontbreekt",
      errorMsg400:
        "Serververzoek is mislukt, controleer uw gegevens en probeer het opnieuw",
      pageOverFlow:
        "Momenteel kunnen slechts 10000 bars worden weergegeven. Stel filtercriteria in om het overschot te bekijken.",
      isHandAddSmartTag: "Is een handmatig toegevoegd slim label",
      defaultGradeMustAtLast:
        "De standaardclassificatie moet helemaal aan het einde worden geplaatst",
      AutomaticAllocationInspectorsIsNull:
        "Voer de basisinformatiepagina in om kwaliteitsinspecteur toe te voegen",
      timePickerTips:
        "Om uw normale gebruik niet te beïnvloeden, is 23:00 ~ 2:00 de onderhoudstijd van het systeem, voer geen geplande taken uit.",
      batchDeleteInfo:
        "{0} vermeldingen zijn verwijderd en {1} vermeldingen zijn mislukt omdat de gegevens zijn gebruikt.",
      batchDeleteTips: "Controleer {0} gegevens, bevestig om te verwijderen?",
      correctionSuccess: "Foutcorrectie succes",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError:
            "Voeg maximaal 10 kwaliteitsinspectieformulieren toe",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "Metrische artikelen {0} moeten oplopen tot 100",
          banProjectMaxErrorTip: "Voeg tot 2000 verboden artikelen toe",
          deadlyProjectMaxErrorTip: "Voeg tot 500 dodelijke items toe",
        },
      },
    },
    info: {
      creatSuccess: "Succesvol gemaakt",
      creatError: "Kan niet worden gemaakt",
      editSuccess: "Wijziging geslaagd",
      editError: "Wijziging mislukt",
      deleteSuccess: "Met succes verwijderd",
      addSuccess: "Nieuw succes",
      deleteError: "Verwijderen mislukt",
      conditionError: "Fout voor logische expressie van de operator",
      customJudgeLogic: "Custom conditie combinatie logische expressie fout",
      exportError: "Export mislukt",
      searchError: "Filter mislukt",
      loadError: "{0} kan niet worden geladen",
      saveSuccess: "Bespaar met succes",
      saveError: "Opslaan mislukt",
      fileSuccess: "Succes archiveren",
      fileError: "Archief mislukt",
      cannotBeNull: "{0} kan niet leeg zijn!",
      pleaseSearchAndSelect: "Voer een zoekwoord in",
      clickAndToggle: "Klik om schakelaar te selecteren",
      deleteWarning:
        "{0} verwijdering kan niet worden hersteld. Weet u zeker dat u wilt doorgaan?",
      getOssAuthFailed:
        "Het verkrijgen van OSS-autorisatie is mislukt. Controleer de netwerkverbinding of probeer het later opnieuw.",
      getInsightAuthFailed:
        "Het verkrijgen van autorisatie voor inzicht is mislukt. Controleer uw netwerkverbinding of probeer het later opnieuw.",
      pictureUploadFailed: "Afbeelding uploaden mislukt",
      pictureUploadFailedSize:
        "Beeldgrootte overschrijdt de limiet, het uploaden van afbeeldingen is mislukt",
      wecomTagsSyncInfo:
        "Laatst bijgewerkt:{0}, synchronisatie van {1} berichten",
      collectSuccess: "Collectie succes.",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "Rapportgegevens niet ontvangen" },
      waveAudio: {
        noWaveMessage:
          "Om uw ervaring te behouden, wordt de golfvorm niet weergegeven voor opnamen van meer dan {0} minuten. Je kunt ze direct spelen.",
      },
      customFilter: {
        del: "Verwijderen",
        edit: "Bewerken",
        new: "Aangepaste filter",
        apply: "Toepassing",
        cancel: "Annuleer",
        onlyApply: "Alleen van toepassing",
        ohterSave: "Bespaar",
        save: "Bespaar",
        customFilter: "Gemeenschappelijke Filter",
        reset: "Reset",
        holderKeyWord: "Voer een trefwoord in",
        customFilterRule: "Filter logica regels:",
        customFilterName: "Filter naam:",
        holderCustomFilterName: "Voer een filternaam in",
        manualConfiguration: "Handmatige configuratie",
        holderCustomRule: "Voer een aangepaste logische voorwaarde in",
        example: "Voorbeeld",
        dragConfiguration: "Configuratie slepen en neerzetten",
        condition: "Voorwaarde",
        operation: "Operatie",
        infoDel: "Sleep inhoud uit het gebied dat u wilt verwijderen",
        toConfigure: "Configuratie",
        holderCustomFilterNameLength: "Filternaam maximale lengte is 10",
        customFilterNameNone: "Filternaam kan niet leeg zijn",
        and: "En",
        or: "Of",
        not: "Niet",
        delSuccess: "Met succes verwijderd",
        saveSuccess: "Bespaar met succes",
        updateSuccess: "Wijziging geslaagd",
        customFilterCondition: "Filterconditie",
        conditionRule: "Voorwaardigingsregels",
        none: "Geen",
        filterPlaceholder: "Voer een trefwoord in",
      },
      labelTabs: {
        addThis: "Voeg hier aan toe",
        goThis: "Ga naar dit",
        saveGo: "Opslaan en trainen",
      },
      datePicker: {
        today: "Vandaag",
        yesterday: "Gisteren",
        theDayBeforeYesterday: "Eergisteren",
        thisWeek: "Deze week",
        lastWeek: "Vorige week.",
        thisMonth: "Deze maand",
        lastMonth: "Vorige maand",
        lastSevenDay: "Laatste 7 dagen",
        lastThirtyDay: "Laatste 30 dagen",
        thisQuarter: "Dit kwartaal",
        lastQuarter: "Laatste kwartaal",
        thisYear: "Dit jaar",
        lastYear: "Vorig jaar",
        lastYearFromToday: "Vorig jaar",
      },
      pagination: { page: "Pagina {1} van {0}" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Laad eerdere berichten",
          bottom: "Laad meer berichten",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Kwaliteitsinspectietaak",
        informationCollectTasksManageTitle: "Verzameltaken",
        customerServiceCenterTitle: "Klantenservicecentrum",
        informationTitle: "Datacenter",
        intelligentAnalysisTitle: "Intelligente analyze",
        semanticIntelligence: "Semantische intelligentie",
        trainingCenterTitle: "Opleidingscentrum",
        adminTitle: "Systeembeheer",
      },
      qualityCheckingTask: {
        title: "Kwaliteitsinspectie bellen",
        index: {
          tipOne:
            "De kwaliteitscontrole van de oproep wordt gebruikt om de kwaliteit van het callcenterwerk van medewerkers te evalueren. U kunt geschikte kwaliteitsinspectieformulieren instellen op basis van uw bedrijf.",
          tipTwo:
            "Voor kwaliteitsinspectietaken, waaronder filteren en bemonsteren, kunnen passende regels worden vastgesteld. Na de scores van de kwaliteitsinspecteur, kan de klantenservice de resultaten van de kwaliteitsinspectie en verder beroep bekijken,",
          addTaskTitle: "Kwaliteitsinspectietaak toevoegen",
          deleteTipTitle: "Kwaliteitsinspectietaak verwijderen",
          deleteTipContent:
            "Kwaliteitsinspectietaken kunnen niet worden hersteld nadat ze zijn verwijderd. Weet u zeker dat u ze wilt verwijderen?",
          checkingTask: "Kwaliteitsinspectietaak",
          checkingTaskList: "Taaklijst voor kwaliteitsinspectie",
          formList: "Formulierlijst",
          viewHelpDocuments: "Helpdocumentatie bekijken",
        },
        manage: {
          taskSetting: {
            title: "Taak-instellingen",
            form: "Vorm",
            basicInfo: "Basisinformatie",
            visibleRange: "Zichtbaar bereik",
            visibleRangeTip:
              "De geselecteerde klantenservice en het klantenserviceteam kunnen de kwaliteitsinspectietaak bekijken. Bovendien kunnen de leider van het klantenserviceteam, de klantenservice en de kwaliteitsinspecteur worden gecombineerd met de toestemming-instellingen voor deze taak.",
            automaticSampling: "Automatische bemonstering",
            automaticSamplingTip:
              "U kunt automatische bemonsteringsregels instellen, zodat het systeem periodiek kwaliteitsinspectietaken toevoegt volgens de regels die u hebt ingesteld, zoals het elke maandag extraheren van 5% van de oproepen van vorige week.",
            samplingRate: "Bemonsteringsfrequentie",
            samplingTime: "Bemonsteringstijd",
            samplingRange: "Bemonsteringsbereik",
            samplingRule: "Bemonsteringsregels",
            fillterCondition: "Filterconditie",
            workflow: "Kwaliteitsinspectie workflow",
            appealsTime: "Beroep tijd toestaan",
            appealsTimeTipOne: "Na indiening van de score",
            appealsTimeTipTwo: "Binnen dagen",
            appealsTimeTipThree:
              'Als de beroepstijd wordt overschreden, wordt de status van de kwaliteitscontrole automatisch gewijzigd in Bevestigd"',
            auditTime: "Audit verwerkingstijd",
            auditTimeTipOne: "Na het indienen van een klacht",
            auditTimeTipTwo: "Binnen dagen",
            auditTimeTipThree:
              'Nadat de verwerkingstijd van de goedkeuring is overschreden, behoudt het record de oorspronkelijke score en wordt de status van de kwaliteitsinspectie automatisch gewijzigd in Bevestigd"',
            noAppealsMode: "Geen klachtenmodus",
            appealsMode: "Beroepsmodus toestaan",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Klantenservice",
            customerServicesGroup: "Klantenservicegroep",
            surveyList: "Evaluatielijst",
            customFields: "Aangepaste velden",
            fieldConfig: "Veldconfiguratie",
            from: "Van",
            owner: "De verantwoordelijke persoon",
            inspectorAndReviewerAuthority:
              "Kwaliteitsinspectie beoordelingsautoriteit",
            inspector: "Kwaliteitsinspecteur",
            reviewer: "Recensent",
            inspectorTip: "De inspecteur kan de taak scoren",
            reviewerTip: "De recensent kan de taak beoordelen",
            dataStatisticsAuthority: "Toestemming voor gegevensstatistieken",
          },
          samplingRecords: {
            title: "Bemonsteringsrecords",
            index: {},
            manualSampling: {
              title: "Bemonstering",
              fillterCondition: "Filterconditie",
              samplingRule: "Bemonsteringsregels",
              agent: "Klantenservice",
              type: "Soort",
              result: "Resultaten",
              evaluate: "Evaluatie",
              relayNumber: "Relaisnummer",
              source: "Bron",
              task: "Taken",
              queueState: "Status wachtrijen",
              callHanger: "Oproep ophangen feestje",
              queueTime: "Tijdrovende wachtrijen",
              talkTime: "Duur van de oproep",
              bellTime: "Ringduur",
              recordCountTipOne: "Totaal",
              recordCountTipTwo: "Record",
              customerServices: "Klantenservice",
              customerServicesGroup: "Klantenservicegroep",
              surveyList: "Evaluatielijst",
              customFields: "Aangepaste velden",
              fieldConfig: "Veldconfiguratie",
              defaultFields: "Standaardvelden",
            },
          },
          taskList: {
            title: "Taaklijst",
            index: {
              batchDelete: "Batch verwijderen",
              batchSubmit: "Batch-indiening",
              batchConfirm: "Batchbevestiging",
              itemFields: "Indelingsveld",
              categoryFields: "Secundair classificatieveld",
              forbidsAndDeadlies: "Verboden en fatale artikelen",
              scoreFieldList: "Fieldlijst voor artikelen scoren",
              customerName: "Naam van de klant",
              cellphone: "Telefoon",
              email: "Mailbox",
              organization: "Bedrijf",
              description: "Beschrijving",
              tags: "Label",
              taskUserRole: "Huidige rol van taakgebruiker",
            },
            workBench: {
              title: "Details opnemen",
              checkForm: "Kwaliteitsinspectieformulier",
              waitEvaluate: "Te evalueren",
              alreadyEvaluate: "Geëvalueerd",
              waitSubmit: "In te dienen",
              alreadySubmit: "Ingediend",
              waitConfirm: "Om te worden bevestigd",
              alreadyConfirm: "Bevestigd",
              waitReview: "Te beoordelen",
              alreadyReview: "Beoordeeld",
              alreadyDone: "Voltooide",
              comment: "Opmerkingen voor evaluatie",
              totalScore: "Totaal score",
              score: "Punten",
              remark: "Opmerkingen",
              changeRecords: "Record wijzigen",
              subject: "Thema",
              secondLevelScore: "Score van graad II",
              deadlyList: "Fataal artikel",
              forbidList: "Verboden artikelen",
              addToCheckTask: "Toevoegen aan kwaliteitstaak",
              submitCheckRemark: "Evaluatie kwaliteitsinspectie ingediend",
              changeCheckRemark:
                "De evaluatie van de kwaliteitsinspectie is gewijzigd",
              doneCheckRemark: "Evaluatie kwaliteitsinspectie voltooid",
              submitCheckReview:
                "Ingediend voor beoordeling van kwaliteitsinspectie",
              submitCheckAppeal: "Kwaliteitsinspectie beroep ingediend",
              saveFlowSuccess: "Evalueer succes.",
              saveFlowFailed: "Evaluatie mislukt, probeer het later opnieuw.",
              FormDetail: "Formulierdetails",
              callDetail: "Details van het oproeplogboek",
              sampleCalllogDetail: "Details opnemen",
              appealReasonTip: "Voer een reden in voor beroep (vereist)",
              appeal: "Klacht",
              appealReason: "Gronden van klacht",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Kwaliteitsinspectieformulier",
        basicInformationTitle: "Basisinformatie",
        indicatorsProjectTitle: "Indicatorartikel",
        specialProjectTitle: "Speciale artikelen",
        totalScore: "Totaal score",
        indicatorsName: "Naam van de indicator",
        score: "Fractie",
        singleScore: "Score voor één item",
        weight: "Gewicht",
        maxDeductScore: "Maximale puntenaftrek",
        add: "Verhogen",
        detail: "Formulierdetails",
        list: {
          title: "Kwaliteitsinspectieformulier",
          tipOne:
            "Het configureren van een kwaliteitsinspectieformulier is de eerste stap in de kwaliteitsinspectiewerkzaamheden. U kunt het meest geschikte kwaliteitsinspectieformulier configureren op basis van de activiteiten van uw bedrijf.",
          tipTwo:
            "We ondersteunen een verscheidenheid aan vormen, zoals directe scores, gewogen scores en puntenaftreksystemen. Het wordt aanbevolen dat u de regels instelt voordat u ze aan het systeem toevoegt.",
        },
        basicInformation: {
          formTemplate: "Vorm sjabloon",
          directScore: "Directe beoordeling",
          weightedScore: "Gewichtsscore",
          deductScore: "Inhoudingen",
          directScoreSecondLevel: "Directe beoordeling-niveau 2",
          weightedScoreSecondLevel: "Gewichtsscore-Niveau 2",
          deductScoreSecondLevel: "Punten aftrek-Niveau 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Verboden artikelen",
          banProjectTip:
            "Het kan worden gebruikt om te controleren tijdens kwaliteitsinspectie scoren. Het kan worden beschouwd als een overtreding of een aanvulling op opmerkingen zonder de werkelijke score te beïnvloeden.",
          deadlyProject: "Fataal artikel",
          deadlyProjectTip:
            "Fatale items kunnen worden gebruikt om te controleren tijdens kwaliteitsinspectie. Als een dodelijk item wordt gecontroleerd, wordt de kwaliteitsinspectietaak direct 0 punten gescoord.",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Kwaliteitsinspectie bellen",
          entiretyReportTitle: "Algemeen rapport",
          serviceGroupReportTitle: "Rapport van de klantenservice",
          serviceReportTitle: "Klantenservicerapport",
          entiretyReport: {
            pandect: "Overzicht",
            contrast: "Contrast",
            trend: "Trend",
            analyze: "Analyse",
            totalScore: "Totaal score",
            banProject: "Verboden artikelen",
            deadlyProject: "Fataal artikel",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Resultaatlijst voor kwaliteitsinspectie",
          taskSelectPlaceholder: "Selecteer Kwaliteitstaak",
          agentSelectPlaceholder: "Selecteer klantenservice",
          refresh: "Vernieuwen",
          all: "Allemaal",
          unread: "Ongelezen",
          inComplaint: "In klacht",
          reviewed: "Beoordeeld",
          totalScore: "Algemene opmerkingen",
          detail: "Details over kwaliteitsinspectie",
          audioText: "Opgenomen tekst",
          callInfo: "Bel informatie",
          ticketInfo: "Informatie over werkvolgorde",
          wechatInfo: "Sessie-informatie",
          dialogText: "Dialoogtekst",
          dialogInfo: "Dialooginformatie",
          customer: "Klant",
          agent: "Klantenservice",
          none: "Geen kwaliteitsinspectiegegevens",
          appealSuccess: "Beroep succesvol!",
          appealFailed: "Beroep is mislukt, probeer het later opnieuw!",
          pointListIsNull:
            "Het kwaliteitsinspectiepunt van de klacht kan niet leeg zijn!",
          pleaseSelectPoint: "Selecteer een kwaliteitsinspectiepunt!",
          unsupportedFormat: "Dit formaat wordt niet ondersteund.",
          shardingInformation: "Het volgende is shard {0}",
          smartTags: "Slim label",
          businessRecords: "Bedrijfsrecords",
          selectedPointsWarning:
            "Controleer de kwaliteitsinspectie-items om bezwaar te maken, specificeer relevante verklaringen voor de bezwaarschriften en klik vervolgens op Verzenden.",
          applyReason: "Gronden van beroep (max. 50 woorden)",
          chatMessageTypes: {
            all: "Allemaal",
            text: "Tekst",
            image: "Afbeelding",
            agree: "Inhoud van toestemmingssessie",
            video: "Video",
            voice: "Stem",
            file: "Bestand",
            emotion: "Uitdrukking",
            link: "Link",
            chatrecord: "Chat record",
            mixed: "Gemengd bericht",
            default: "Bericht niet ondersteund",
            deleteFriend: "Vriend verwijderen",
            redpacket: "Rode envelop",
            card: "Visitekaartje",
            disAgree: "Oneens met de inhoud van het gesprek",
            weapp: "Klein programma",
            externalRedpacket: "Wissel rode enveloppen in",
            meetingVoicevCall: "Bel bericht",
            uRL: "Webpagina",
            replyTimeout: "Time-out antwoord",
            sphfeed: "Videonummer",
            chatRecordTitle: "Chat record",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Totaal score",
            actualScore: "Beoordeling",
            actualGrade: "Beoordeling",
            appeal: "Klacht",
            remark: "Handmatige opmerkingen",
            none: "Geen gedetailleerde gegevens voor kwaliteitsinspectie",
            pointEdit: "Kwaliteitsinspectiepunt bewerkingsclassificatie",
          },
          changeList: {
            title: "Record wijzigen",
            user: "Gebruiker",
            point: "Kwaliteitsinspectiepunt",
          },
          relatedRecord: {
            title: "Gerelateerde records",
            qualityList: "Kwaliteitsinspectietaak",
            none: "Geen",
            check: "Weergave",
            loadMore: "Laad meer",
          },
        },
      },
      grade: {
        title: "Kwaliteitsinspectie beoordeling",
        subTitle:
          "De kwaliteitsinspectie-classificatie wordt gebruikt om kwaliteitsinspectieobjecten te beoordelen nadat de kwaliteitsinspectie is voltooid. Het kan bijvoorbeeld worden geconfigureerd als ongeschikt als het lager is dan 60, goed als het hoger is dan 60 en uitstekend als het hoger is dan 80.",
        list: {
          up: "Omhoog gaan",
          down: "Naar beneden gaan",
          del: "Verwijderen",
          case: "Sluit automatisch aan bij de casebibliotheek",
          orderCase: "Standaard case-bibliotheek",
          info: "De standaardbeoordeling heeft geen overeenkomende criteria en kan niet worden verwijderd. Als een van de bovenstaande beoordelingen niet wordt gehaald, wordt de standaardbeoordeling verkregen.",
          add: "Rating toevoegen",
          save: "Bespaar",
          close: "Annuleer",
          max: "Kwaliteitsinspectie kan worden geconfigureerd tot 20",
          name: "Naamloos",
          success: "Bespaar met succes",
        },
      },
      qualityInspectionList: {
        title: "Kwaliteitsinspectielijst",
        fastFilter: "Overtreding van kwaliteitsinspectie",
        fastFilterTip:
          "Een treffer op het minpuntensysteem is een overtreding, en een misser op het pluspuntensysteem is een overtreding.",
        titleWechat: "Analyselijst",
        titleCollect: "Overnamelijst",
        status: "Analyse Status",
        reQualityInspectionConfig: "Taakopties opnieuw kwaliteit",
        pleaseSelectRecheckPoints:
          "Selecteer het kwaliteitsinspectiepunt dat opnieuw moet worden berekend",
        beginReQualityInspection: "Start de uitvoering",
        cancelReQualityInspection: "Taak annuleren",
        reQualityInspection: "Herinspectie",
        reQualityInspectionWechat: "Heranalyse",
        reQualityInspectionUnderWay: "Herinspectie taak...",
        reQualityInspectionUnderWayTip:
          "Het verlaten van de pagina na het sluiten van het venster heeft geen invloed op de taak",
        reQualityInspectionDone: "Herinspectie voltooid",
        reQualityInspectionDoneTip:
          "De herinspectie is met succes voltooid. Klik op de bevestigingsknop.",
        endReQualityInspection: "Taak beëindigen",
        alreadyDoneTip: "{0} voltooid",
        totalTip: "Totaal {0}",
        endReInspectionSuccess: "Taak van herkwaliteit beëindigd",
        closeWindow: "Raam sluiten",
        endReInspectionFailed:
          "De taak van herkwaliteit is mislukt, probeer het later opnieuw!",
        detail: {
          title: "Details over kwaliteitsinspectie",
          wechatTitle: "Analyse details",
          titleCollect: "Verzamelgegevens",
          callIndex: "Bijbehorende oproep",
          textIndex: "Geassocieerde gesprekken",
          fileConfirm: "Indiening bevestigen?",
          fileConfirmDesc:
            "Na archivering kan de kwaliteitsinspectiescore niet worden bewerkt. Bevestig of u wilt archiveren.",
        },
        sysCloumnsContal: "Systeemkolomveldbeheer",
        personCloumnsContal: "Persoonlijk kolomveldbeheer",
      },
      spotCheckList: {
        title: "Bemonsteringslijst",
        onlyMyTip: "Bekijk alleen mijn bemonstering",
        plInspector: "Selecteer het bemonsteringspersoneel",
        allInspector: "Alles bekijken",
        seInspector: "Bekijk de aangewezen steekproefinspecteur",
        myInspector: "Bekijk alleen de mijne",
        noInspector: "Controleer de niet-toegewezen bemonsteringsinspecteur",
        detail: { title: "Details van de bemonstering" },
      },
      reviewList: {
        title: "Beoordelingslijst",
        onlyMyTip: "Bekijk alleen mijn recensie",
        plInspector: "Selecteer een recensent",
        allInspector: "Alles bekijken",
        seInspector: "Bekijk aangewezen recensent",
        myInspector: "Bekijk alleen de mijne",
        noInspector: "Bekijk niet-toegewezen beoordelaar",
        detail: { title: "Details bekijken" },
      },
      caseBaseList: {
        title: "Case Bibliotheek",
        del: "Verwijderen",
        delTitle: "Alle inhoud wordt niet opgeslagen na verwijdering",
        cancel: "Annuleer",
        actions: "Operatie",
        seeCaseBase: "Case bekijken",
        seeDetail: "Details bekijken",
        edit: "Bewerken",
        save: "Bespaar",
        addCaseBase: "Nieuwe casebibliotheek",
        export: "Uitvoer",
        ok: "Bepaal",
        tip: "Typische bel-en gespreksgegevens in de kwaliteitsinspectielijst kunnen worden opgeslagen in de casebibliotheek voor eenvoudig leren en delen.",
        detail: {
          choiceCaseBase: "Selecteer een zaak",
          delCaseBase: "Verwijder hoesje",
          addCaseBase: "Geval toevoegen",
          successed: "Bespaar met succes",
          info: "Bewaar of annuleer eerst de inhoud die u aan het bewerken bent",
          caseType: "Selecteer een casebibliotheek om mee te doen",
        },
      },
      testSetList: {
        title: "Testset voor kwaliteitsinspectie",
        tip: "De gegevens die door de kwaliteitsinspecteur worden bemonsterd, worden opgeslagen in de kwaliteitsinspectietestset, wat handig is om de configuratie van de kwaliteitsinspectieomstandigheden en-regels voor sommige foutitems van de inspectie van de machine te optimaliseren in vergelijking met de inspectie van de bemonstering.",
        refresh: "Vernieuwen",
        reQualityInspectionUnderWay: "Evalueer de missie opnieuw in...",
        reQualityInspectionUnderWayTip:
          "Het verlaten van de pagina na het sluiten van het venster heeft geen invloed op de taak",
        reQualityInspectionDone: "Herbeoordeling voltooid",
        reQualityInspectionDoneTip:
          "De herevaluatie is met succes voltooid. Klik op de knop Bevestigen",
        endReQualityInspection: "Taak beëindigen",
        alreadyDoneTip: "{0} voltooid",
        totalTip: "Totaal {0}",
        endReInspectionSuccess: "Herbeoordeling taak beëindigd",
        closeWindow: "Raam sluiten",
        endReInspectionFailed:
          "Herevaluatie taak mislukt, probeer het later opnieuw!",
        recheckPoint:
          "Herbereken kwaliteitsinspectiepuntscores en nauwkeurigheid",
        recheckPointTitle: "Opties voor herevaluatie",
        delete: {
          reQualityInspectionUnderWay: "Batch Verwijder taken...",
          reQualityInspectionUnderWayTip:
            "Het verlaten van de pagina na het sluiten van het venster heeft geen invloed op de taak",
          reQualityInspectionDone: "Taak voor batchverwijdering voltooid",
          reQualityInspectionDoneTip:
            "De bulkverwijdering is met succes voltooid, klik op de knop Bevestigen",
          endReQualityInspection: "Taak beëindigen",
          alreadyDoneTip: "{0} voltooid",
          totalTip: "Totaal {0}",
          endReInspectionSuccess: "Batch verwijderen taak beëindigd",
          closeWindow: "Raam sluiten",
          endReInspectionFailed:
            "Batch-verwijdering mislukt, probeer het later opnieuw!",
        },
        reultList: {
          assessmentTime: "Laatste evaluatietijd",
          assessmentAll: "Totale beoordeling",
          strip: "Artikel",
          spot: "Punt",
          deviation: "Afwijking",
          accuracyRate: "Nauwkeurigheid",
          info: "Geen evaluatieresultaat, evalueer alstublieft",
          onlyDeviationInfo: "Alleen afwijkingsrecords bekijken",
          againAssessment: "Herevaluatie",
          screen: "Filter",
          batchDelete: "Batch verwijderen",
          matching: "Match",
          ok: "Ja",
          noCancel: "Nee",
        },
      },
      smartWordsLibrary: {
        title: "Intelligente thesaurus",
        tip1: "Synoniemen: gebruikt om de hoeveelheid training van semantische labels te verminderen, hoeven vergelijkbare zinnen met synoniemen niet herhaaldelijk te worden getraind.",
        tip2: "Professionele zelfstandige naamwoorden: optimaliseer transcriptie en natuurlijk taalbegrip voor eigennamen zoals bedrijfsnamen, productnamen en branchewoorden die niet nauwkeurig worden herkend om de nauwkeurigheid van herkenning en de nauwkeurigheid van analyses te verbeteren.",
        tip3: "Focus op woorden: focus op de woorden waarop u zich wilt concentreren in woordwolkanalyse om AI-analysehotspots te helpen bij het ontmoeten van de huidige business.",
        tip4: "Blokkeer woorden: blokkeer woorden waar u niet op wilt letten in woordwolkenanalyse om AI te helpen hotspots te analyseren in overeenstemming met de huidige zaken.",
        tip5: "Woordgewicht: gebruikt om de herkenning van semantische labels te corrigeren en te optimaliseren. Door het invloedsgewicht van woorden in zinnen aan te passen, kan het semantische herkenningseffect in specifieke scenario's naar behoefte worden geoptimaliseerd.",
        tip6: "Corpus-gegevens: voer grote alinea's tekst in voor hun respectieve bedrijfsscenario's voor het transcoderen van foutcorrectie en ASR-training om de nauwkeurigheid van de ASR-transcodering te verbeteren.",
        upload: {
          uploadSynonymWords: "Synoniemen importeren",
          uploadProfessionalWords: "Professionele voorwaarden importeren",
          uploadAttentionWords: "Importeer woorden van belang",
          uploadIgnoreWords: "Importeer woorden",
          uploadWordsWeight: "Woorden van importgewicht",
          uploadTypo: "Importeer typefouten woordset",
          uploadCorpusData: "Corpusgegevens importeren",
          uploadCorpusContent: "Inhoud van corpusgegevens importeren",
          firstUploadStep:
            "1. Download de sjabloon en vul deze in volgens het sjabloonformaat",
          downloadTemplateMessage: "Sjabloon downloaden:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Klik op de knop Importeren om het EXCEl-bestand te uploaden dat voldoet aan de sjabloonindeling",
          secondUploadCorpusDataStep:
            "2. Klik op de knop Importeren om het TXT-bestand te uploaden dat voldoet aan de sjabloonindeling.",
          postUploadDataFail:
            "Het verzenden en uploaden van gegevens is mislukt.",
          pleaseUploadTemplate: "Upload het EXCEl/JSON/TXT-bestand",
          templateNeedJSONOrXLSX:
            "Bestandssjablonen kunnen alleen in JSON-of xlsx-formaat zijn",
          templateNeedTxt:
            "De sjabloon van het corpusgegevensbestand kan alleen een TXT-bestand zijn",
          uploadSuccess: "Import geslaagd",
          uploadFailed: "Importeren mislukt",
          downloadError: "Downloaden is mislukt",
          downLoadTxtTitle: "Corpus strijden. Txt",
        },
        navTabNames: {
          synonymWords: "Synoniemen",
          professionalWords: "Professionele Noun",
          attentionWords: "Bezorgde woorden",
          ignoreWords: "Negeer woorden",
          wordsWeight: "Woordgewicht",
          corpusData: "Corpus gegevens",
          typoLibrary: "Typefouten woordset",
        },
        synonymWords: {
          createSynonymWords: "Nieuw synoniem",
          editSynonymWords: "Synoniemen bewerken",
          addSynonymWords: "Synoniem toevoegen",
          pleaseEnterStandardWords: "Voer een standaard woord in",
          synonymWordsExists:
            "Het synoniem bestaat al en kan niet worden toegevoegd",
          synonymWordsCanNotNull: "Toegevoegd synoniem kan niet leeg zijn",
          pleaseAddSynonymWords: "Voeg synoniemen toe",
          addSuccess: "Nieuw succes",
          editSuccess: "Wijziging geslaagd",
          deleteSuccess: "Met succes verwijderd",
          deleteError: "Verwijderen mislukt",
          required: "*",
        },
        professionalWords: {
          create: "Nieuwe professionele voorwaarden",
          edit: "Professionele zelfstandig naamwoord-editor",
          addTargetWords: "Voeg vervangend woord toe",
          targetWordsExists:
            "Het vervangende woord bestaat al en kan niet worden toegevoegd",
          targetWordsCanNotNull:
            "Het toegevoegde vervangende woord kan niet leeg zijn",
          pleaseAddTargetWords: "Voeg een vervangend woord toe",
          pleaseEnter: "Voer een professionele term in",
          wordsExists: "De term bestaat al.",
          createSuccess: "Succesvol toegevoegd",
          editSuccess: "Wijziging geslaagd",
          deleteSuccess: "Met succes verwijderd",
          deleteError: "Verwijderen mislukt",
          saveError: "Opslaan mislukt",
          addTargetWordsMaxNumberError:
            "U kunt maximaal 100 vervangende woorden toevoegen",
          addHomophonyWordsMaxNumberError:
            "U kunt maximaal 100 gemaskerde woorden toevoegen",
        },
        attentionWords: {
          create: "Nieuw woord van bezorgdheid",
          edit: "Bezorgde woordredacteur",
          pleaseEnter: "Voer een interessant woord in",
          wordsExists: "Het woord van interesse bestaat al.",
          createSuccess: "Succesvol toegevoegd",
          editSuccess: "Wijziging geslaagd",
          deleteSuccess: "Met succes verwijderd",
          deleteError: "Verwijderen mislukt",
        },
        ignoreWords: {
          create: "Nieuw woord negeren",
          edit: "Negeer woordbewerking",
          pleaseEnter: "Voer alstublieft woord negeren",
          wordsExists: "Het negeerwoord bestaat al",
          createSuccess: "Succesvol toegevoegd",
          editSuccess: "Wijziging geslaagd",
          deleteSuccess: "Met succes verwijderd",
          deleteError: "Verwijderen mislukt",
        },
        wordsWeight: {
          createWordsWeight: "Nieuw Gewichtswoord",
          editWordsWeight: "Weight Word bewerken",
          addWordsWeight: "Gewichtswoord toevoegen",
          pleaseEnterWeightWords: "Voer een gewichtswoord in",
          pleaseEnterWeight: "Voer een gewicht in",
          weightNeedInZeroToHundred: "Gewicht moet tussen 0 en 100 zijn",
          synonymWordsExists: "Het gewichtswoord bestaat al",
          weightNeedNumber: "Gewicht vereist integer-formaat",
          standardWeightInformation:
            "Het benchmarkgewicht is 50, en hoe groter het gewicht, hoe sterker de invloed in de analyze.",
          createSuccess: "Succesvol toegevoegd",
          editSuccess: "Wijziging geslaagd",
          deleteSuccess: "Met succes verwijderd",
          deleteError: "Verwijderen mislukt",
        },
        corpusData: {
          createCorpusData: "Nieuwe Corpus Data",
          editCorpusData: "Corpus-gegevens bewerken",
          addCorpusData: "Corpus-gegevens toevoegen",
          pleaseEnterCorpusDescription: "Voer een corpusbeschrijving in",
          pleaseEnterCorpusContent: "Voer de inhoud van het corpus in",
          synonymWordsExists: "Deze corpusgegevens bestaan al",
          CorpusContentWordsOverSetting:
            "Het aantal woorden in de inhoud is groter dan 20000 woorden,",
          download: "Download",
          upload: "Uploaden",
          see: "Weergave",
          canNotSave:
            "Gebruik de importfunctie, anders kan deze niet worden opgeslagen",
          createSuccess: "Succesvol toegevoegd",
          editSuccess: "Wijziging geslaagd",
          deleteSuccess: "Met succes verwijderd",
          deleteError: "Verwijderen mislukt",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Correct woord",
            wrongWord: "Verkeerd woord",
            wrongWordCount: "Aantal verkeerde woorden",
            wrongWordTip: "Druk op Enter om toe te voegen",
            status: "Effectiviteit",
          },
          create: "Nieuwe typefouten woordset",
          edit: "Typefouten bewerken",
          tip: 'De typos-woordverzameling wordt gebruikt om het effect van "typefoutendetectie" te optimaliseren. Als "terugbetaling" bijvoorbeeld ten onrechte wordt verzonden als "breedteterugbetaling", kunnen gewone typefouten en correcte woorden worden ingesteld via deze functie en wordt deze de volgende dag na het opslaan van kracht.',
          onlyChinese: "Ondersteuning alleen input Chinese woorden",
          wrongWordLengthErr:
            "De lengte van elk verkeerd woord moet 2 ~ 10 woorden zijn",
        },
      },
      semanticTags: {
        title: "Semantisch label",
        current: "Huidig semantisch label",
        reference1: "Kwaliteitsinspectie-taak: {0} - {1}",
        reference2: "Slim label: {0}",
        reference3: "Gegevensvoorbewerking: {0}",
        reference4: "Kennisbasis: {0}",
        index: { import: "Semantische labels importeren" },
        detail: {
          new: "Nieuw semantisch label",
          edit: "Semantisch label bewerken",
          name: "Naam",
          standardSentences: "Standaardzin",
          effectiveness: "Effectiviteit",
          similarSentences: "Soortgelijke zinnen",
          tip: "Ondersteuning met behulp van # # to call entity API, bijvoorbeeld: Is uw mobiele telefoonnummer # sys_phone?",
          excludeSimilarSentences: "Soortgelijke zinnen uitsluiten",
          intelligentRecommend:
            "Intelligente aanbeveling van soortgelijke inhoud",
          fastTraining: "Snelle Opleiding:",
          qualify: "Regelmatig",
          recallKeyWord: "Recall Trefwoorden",
          excludeKeywords: "Trefwoorden uitsluiten",
          excludeKeywordsInfo1:
            "Skeywords uitsluiten en reguliere expressies hebben voorrang op alle logica's. Als ze worden uitgesloten, wordt het semantische label niet geraakt.",
          keyWordInfo1:
            "In elke regel kunnen meerdere terugroepingszoekwoorden worden ingevuld, gescheiden door Engelse komma's. Wanneer meerdere recall-keywords worden ingevuld, is de logica dat alle hits worden geraakt.",
          keyWordInfo2:
            "Recall-trefwoorden kunnen in meerdere regels worden ingevuld. Wanneer meerdere regels worden ingevuld, kan de logica worden ingesteld als elke hit.",
          recallRegular: "Regelmatig terugroepen",
          excludeRegular: "Regulier uitsluiten",
          similaSentences: "Soortgelijke zinnen uitsluiten",
          simim: "Gelijkenis",
          keyWordInfo3:
            "Exclusief soortgelijke zinnen heeft voorrang op alle logica. Als een soortgelijke zin wordt geraakt, wordt het semantische label niet geraakt. De standaard gelijkenis is 80%, en hoe groter de gelijkenis, hoe meer deze is.",
          keyWordInfo4:
            "De regel is vergelijkbaar met normaal, met een standaard begin en einde. Niet noodzakelijkerwijs gebruikt met haakjes, bijv. [niet XXXX]",
          keyWordInfo5:
            "De functies in de geavanceerde module zijn meestal experimentele functies voor experts die bekend zijn met de operatie. Als u niet zeker weet hoe u moet werken, is het raadzaam deze sectie niet te wijzigen.",
          keyWordInfo6:
            "De detectiefunctie tussen zinnen geeft semantische tags de mogelijkheid om over zinnen heen te detecteren.",
          keyWordInfo7: "Hooguit opeenvolgend in dezelfde rol",
          keyWordInfo8: "Negeer verschillende rollen Zin lengte is niet groter",
          keyWordInfo9: "Zinnen van woorden voor detectie van kruiszinnen",
          keyWordInfo10: "De lengte van de langste kruisvonlimiet is niet meer",
          keyWordInfo11: "Verwijder vergelijkbaar percentage niet minder",
          keyWordInfo12: "De gelijkenis is niet lager",
          keyWordInfo13:
            "% Van vergelijkbare zinnen zal op dezelfde manier worden gematcht (standaard overeenkomst 80%)",
          keyWordInfo14:
            "Soortgelijke deduplicatie komt overeen met zinnen op basis van hun gelijkenis. Het wordt aanbevolen om zinnen te verwijderen die te vergelijkbaar zijn om de detectie-efficiëntie te verbeteren.",
          keyWord: "Trefwoorden",
          entity: "Entiteit",
          ruleField: "Conceptwoorden",
          customRule: "Spraakregelontwerp",
          fanhua: "Soortgelijke vragen",
          test: "Test",
          testOk: "Hit test",
          senior: "Geavanceerde instellingen",
          seniorList: "Detectie tussen zinnen",
          up: "Inschakelen",
          down: "Sluiten",
          ju: "Zin",
          oneWord: "Woord",
          simimNo: "Vergelijkbaar met Weight",
          seniorField: "Gelijkenis drempel",
          save: "Bespaar",
          cancel: "Annuleer",
          specialElements: "Speciale elementen",
          logicRule: "Logische regels",
          every: "Meerdere wedstrijden",
          recallOk: "Hit Recall Word:",
          recallNo: "Gemiste Recall-woord",
          ruleOk: "Hit recall regelmatig",
          ruleNo: "Regular gemist",
          rularOk: "Hit Regels",
          rularNo: "Gemiste regel",
          infoOk: "Hit standaardzin of soortgelijke zin:",
          infoNo: "Gemiste standaardstraf of soortgelijke zin",
          fieldOk: "Hit om soortgelijke zinnen uit te sluiten:",
          fieldNo: "Gemiste om soortgelijke zinnen uit te sluiten",
          excludeRuleNo: "Mist uitsluiting Regex",
          excludeRuleOk: "Hit uitsluiting regelmatig",
          excludeKeywordNo: "Missers sluiten trefwoorden uit",
          excludeKeywordOk: "Hit Uitsluiten Trefwoorden",
          update: "Update geslaagd",
          fanhuaList:
            "Ingezonden op het tabblad Generalisatie soortgelijke zin lijst",
          wordList:
            "Trainingslijst is leeg en kan niet worden ingediend bij de generalisatielijst",
          start: "Begin",
          end: "Einde",
          keyWordInfo15:
            "Vergelijkbare velden bestaan in de generalisatielijst (geavanceerde configuratie van vergelijkbare deduplicatiebeperkingen)",
          saveSuccess: "Bespaar met succes",
          errInfo: "Sommige velden zijn niet onjuist ingevuld of ingevuld",
        },
        table: {
          deepTraining: "Deep Training {0} ronde",
          fastTraining: "Snelle training",
        },
      },
      intelligentCollection: {
        title: "Intelligente acquisitie",
        classification: "Groepslijst",
        new: "Nieuwe collectie",
        table: {
          name: "Veldnaam",
          props: "Klantkenmerken",
          type: "Veldtype",
          desc: "Veldbeschrijving",
        },
        detail: {
          isCustomerProps: "Bespaar in klantkenmerken",
          customerPropsHelper:
            "Na het opslaan wordt de verzamelde informatie bijgewerkt naar de klantinformatie. Niet opslaan, geen update naar klantinformatie",
          name: "Veldnaam",
          type: "Veldtype",
          text: "Tekst",
          desc: "Veldbeschrijving",
          descPlaceHolder:
            "Beschrijf het gebruik van dit veld om informatie te verzamelen",
          condition: "Voorwaarde",
          logic: "Regels",
          method: "Overnamemodus",
          message: "Inhoud van informatie",
          range: "Extractiebereik",
          entity: "Entiteiten uitpakken",
          base: "Basisinformatie",
          collect: "Verzamel informatie",
        },
      },
      intelligentTags: {
        title: "Slim label",
        sessionLabelTitle: "Sessiescenario's",
        keyEventsTitle: "Belangrijkste evenementen",
        customerLabelTitle: "Klantenlabel",
        addClassification: "Maak een nieuwe labelclassificatie",
        defaultCategoryName: "Standaardclassificatie",
        index: {
          createTag: "Nieuw label",
          createEvent: "Nieuw evenement",
          editTagClassification: "Labelclassificatie bewerken",
          selectTagClassification: "Labelclassificatie selecteren",
          editTagGrounp: "Groepering bewerken",
          callTimeError:
            "De geselecteerde oproeptijd/starttijdbereik van het gesprek mag niet langer zijn dan één maand (31 dagen)",
          callRecordCountError:
            "Het aantal records is 0, selecteer de oproep/gesprekstijd opnieuw",
          selectItmeCountError:
            "Selecteer het slimme label waarvoor u de bulkbewerking wilt uitvoeren",
          redoSuccess:
            "Heruitvoering is succesvol, ga naar het taakcentrum om te bekijken",
          redoError: "Heruitvoering mislukt, probeer het later opnieuw",
          timeError:
            "De tijd van de vraag kan niet één maand (31 dagen) overschrijden",
          categoryName: "Categorienaam",
          categoryColor: "Classificatie kleur",
          newTagClassification: "Nieuwe labelclassificatie",
          newEventClassification: "Nieuwe categorie",
          tagClassification: "Labelclassificatie",
          eventClassification: "Evenementclassificatie",
        },
        detail: {
          new: "Nieuw slim label",
          edit: "Slim label bewerken",
          tagName: "Naam",
          tagDesc: "Beschrijving",
          category: "Indeling",
          tagStatus: "Effectiviteit",
          operator: "Exploitant",
          rule: "Regels",
          labelClassAtion: "Label Type",
          labelClassAtionTip1:
            "Callout-etiketten: etikettering op basis van regels",
          labelClassAtionTip2:
            "Classificatie-etikettering: etikettering op basis van modeltraining",
          group: "Groeperen",
          checkLabel: "Categorie selecteren",
          warn: "Waarschuwing",
          warnTips:
            "Nadat de waarschuwing is ingeschakeld en de sessie voldoet aan deze belangrijke gebeurtenisregel, wordt de aangewezen persoon gevraagd en kan hij de herinnering ontvangen op de takenlijst.",
          warnError: "Selecteer Alert gespecificeerd personeel",
        },
        subTitle: {
          common: "Slimme sessie tags",
          wecom: "Enterprise micro-label",
        },
      },
      customerPortrait: {
        title: "Klantenportret",
        index: { import: "Portret van klanten importeren" },
        searchPlaceHolder: "Klant-ID, bijnaam of telefoon",
        callCount: "Aantal gesprekken:",
        callDuration: "Totale duur van de oproep:",
        dialogCount: "Totaal aantal gesprekken:",
        keyWordsStatistics:
          "Sleutelwoorden statistieken (Top 20 & bezorgde woorden)",
        keyWordsCount: "({0})",
        businessTag: "Zakelijk label",
        keyWordsCloudImg: "Trefwoorden Cloud chart",
        customerEmotionTrend: "Trends klantsentiment",
        notFoundCustomer: "Geen gerelateerde klanten opgehaald",
        noKeyWords: "Geen gerelateerde trefwoorden gevonden",
        noBusinessTags: "Gerelateerde bedrijfstags niet gevonden",
        getKeyWordsStatisticsDataError:
          "Query op het sleutelwoord van klanten is mislukt",
        getBusinessTagsStatisticsDataError:
          "De vraag naar bedrijfslabels van klanten is mislukt",
        personCustome: "Klantinformatie niet opgevraagd",
        notimeInfo: "Er werd geen emotie-informatie gevonden.",
        timeList: "Datumbereik",
        operator: "Slim label",
        placeHolder: "Selecteer alstublieft",
        placeOperator: "Selecteer een slim label",
        search: "Vraag",
        reset: "Reset",
        searchHolder: "Zoeken",
        call: "Bel",
        text: "Dialoog",
      },
      sentimentAnalysis: { title: "Ontdekking van de publieke opinie" },
      saleSentimentAnalysis: { title: "Analyze van de publieke opinie" },
      dataPreprocess: {
        title: "Voorverwerking van gegevens",
        index: {
          createData: "Nieuwe gegevens",
          selectDataSource: "Gegevensbron selecteren",
          selectStatus: "Selecteer effectiviteit",
          callTimeError:
            "De geselecteerde oproeptijd/starttijdbereik van het gesprek mag niet langer zijn dan één maand (31 dagen)",
          callRecordCountError:
            "Het aantal records is 0, selecteer de oproep/gesprekstijd opnieuw",
          selectItmeCountError:
            "Selecteer gegevens om bulkbewerking uit te voeren",
          redoSuccess:
            "Heruitvoering is succesvol, ga naar het taakcentrum om te bekijken",
          redoError: "Heruitvoering mislukt, probeer het later opnieuw",
        },
        detail: {
          new: "Nieuwe gegevens",
          edit: "Gegevens bewerken",
          name: "Naam",
          desc: "Beschrijving",
          dataSource: "Gegevensbron",
          status: "Effectiviteit",
          entity: "Entiteiten uitpakken",
          operator: "Exploitant",
          rule: "Regels",
        },
      },
      connectedByConversation: {
        title: "Communicatiegespreksvereniging",
        list: {
          title: "Communicatie-dialoogassociatiefunctie",
          info: "De conversatieassociatiefunctie kan verschillende oproepen en gesprekken associëren volgens vooraf ingestelde regels.",
          rule: "Regels van de conversatievereniging",
          source: "Selecteer een gegevensbron",
          classification: "Selecteer een categorie",
          choose: "Selecteer alstublieft",
          input: "Voer alstublieft in",
          save: "Bespaar",
          cancel: "Annuleer",
          fieldwei: "Beetje",
          fieldNot: "Er zijn niet-ingevulde velden",
          fiedldlengthNo: "Veldlengte overschrijdt limiet",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Meerdere trefwoorden kunnen worden ingesteld, gescheiden door komma's",
          applyRole: "Rol detecteren",
          preOperator: "Pre-operator",
          applyOperatorScope: "Detectiebereik",
          addOperator: "Operator toevoegen",
          noPreOperator: "Geen",
          from: "Nee.",
          to: "Zin tot nr.",
          end: "Zin",
          testRegular: "Regelmatige expressies testen",
          test: "Test",
          matchSuccess: "Wedstrijd met succes",
          matchError: "Match mislukt",
          querySementicTags: "Semantisch label selecteren",
          reQuerySementicTags: "Reselecteer semantische labels",
          sementicTag: "Semantisch label",
          standardSentence: "Standaardzin",
          similarity: "Gelijkenis",
          actions: "Operatie",
          noFitTag: "Ik heb het juiste label niet gevonden,",
          addSemanticTag: "Een nieuwe semantische tag toevoegen",
          pleaseEnterRegular: "Voer een reguliere expressie in",
          pleaseEnterRegularText: "Voer de te verifiëren inhoud in",
          newAssociateComponentTip:
            "Als de waarde een numeriek type is, gebruikt u de verouderde functie",
        },
        filterManage: {
          noValueTip: "Geen gegevens",
          allCall: "Alle oproepen",
          allDialogue: "Alle dialogen",
          allWechat: "Alle sessies",
          getFiltersListFailed:
            "Filterlijst is mislukt, probeer het later opnieuw!",
          getFilterDetailFailed:
            "Filtergegevens zijn mislukt. Probeer het later opnieuw!",
          getAllCountFailed:
            "Het totale aantal oproepen/gesprekken is mislukt. Probeer het later opnieuw!",
        },
        listManage: {
          searchPlaceHolder: "Voer een zoekwoord in",
          filterConditions: "Filterconditie",
          records: "Record",
          getRecordsFailed: "Het record is mislukt, probeer het later opnieuw!",
        },
        miniListManage: {
          getRecordsFailed: "Het record is mislukt, probeer het later opnieuw!",
        },
        applyTemplates: {
          title: "Toepasselijke kwaliteitsinspectie sjabloon",
          collectionTitle: "Toepasselijke acquisitie sjabloon",
        },
        recordDetail: {
          showWaveAudio: "Toon opname",
          getDetailFailed:
            "Gegevens niet ontvangen, probeer het later opnieuw!",
          getSmartTagsFailed:
            "Smartlabel is mislukt. Probeer het later opnieuw!",
          getTemplatesFailed:
            "Het is mislukt om een toepasselijke sjabloon te krijgen, probeer het later opnieuw!",
        },
        changeList: {
          sampleStatus: "Bemonsteringsstatus",
          sampleAssignInspector: "Bemonsteringsinspecteur toewijzen",
          reviewAssignInspector: "Reviewer toewijzen",
          read: "Lees",
          distributivePerson: "Toegekend persoon",
          totalScore: "Totaal score",
        },
        qualityCheckScore: {
          totalScore: "Totaal score",
          actualScore: "Score",
          actualGrade: "Beoordeling",
          appeal: "Klacht",
          remark: "Opmerkingen",
          manualRemark: "Handmatige opmerkingen",
          none: "Geen gedetailleerde gegevens voor kwaliteitsinspectie",
          interactiveTip:
            "Gebruik interactieherkenning om het gebruik van klantenservice te detecteren FAQ",
          interactiveIsEmptyTip:
            "Geen interactieve herkenningsinhoud gedetecteerd",
        },
        semanticMark: {
          semanticContent: "Markering zin",
          markedSimilarTitel: "Soortgelijke semantische labels",
          markedExcludeTitle:
            "Gemarkeerd om soortgelijke semantische tags uit te sluiten",
          taskTagTitle: "Taakgerelateerde semantische labels",
          similarTagTitle:
            "Identificeer vergelijkbare semantische tags (gelijkenis> 40%,Top 20)",
          delete: "Verwijderen",
          markSimilar: "Mark vergelijkbaar",
          markExclude: "Soortgelijke uitsluiten",
          none: "Geen",
        },
        selectCallTime: {
          callTime: "Begintijd/sessie",
          callTimeRemind:
            "U kunt maximaal één maand (31 dagen) gespreksrecords selecteren in het afgelopen jaar.",
          callRecordCount:
            "U hebt in totaal {0} bel-/gespreksrecords geselecteerd",
          getCallRecordError:
            "Het record is mislukt, probeer het later opnieuw",
        },
      },
      informationEntities: {
        title: "Informatie entiteit",
        index: { import: "Informatie importeren Entiteiten" },
        detail: {
          new: "Nieuwe informatie-entiteit",
          edit: "Informatie Entiteit bewerken",
          name: "Naam",
          apiName: "API-naam",
          activeFlag: "Effectiviteit",
          remark: "Beschrijving",
          logicExp: "Regelmatige entiteit",
          sentenceList: "Inhoud van entiteiten",
          tip: "Entity-inhoud ondersteunt meerdere vermeldingen, gescheiden door spaties, die synoniemen zijn van dezelfde entiteit.",
          remarkLengthError:
            "De lengte van de beschrijving mag niet groter zijn dan 255",
          nameLengthError: "Naamlengte mag niet groter zijn dan 40",
          entityDuplicateError: "Inhoud van dubbele entiteit",
        },
      },
      interactiveRecognition: {
        title: "Interactieve herkenning",
        classify: "Indeling",
        addClassify: "Categorie toevoegen",
        editClassify: "Categorie bewerken",
        index: { import: "Interactieherkenning importeren" },
        detail: {
          new: "Nieuwe slimme interactie",
          edit: "Slimme interactie bewerken",
          type: "Soort",
          standardQuestion: "Standaard problemen",
          similarQList: "Soortgelijk probleem",
          exceptSimilarQList: "Elimineer soortgelijke problemen",
          category: "Indeling",
          targetType: "Probleemdetectiedoelwit",
          status: "Effectiviteit",
          standardAnswer: "Standaard antwoord",
          similarAList: "Soortgelijke antwoorden",
          wrongAList: "Verkeerd antwoord",
        },
      },
      businessAnalysis: {
        title: "Bedrijfsanalyse",
        tip1: "Zakelijke trendanalyse: de verticale coördinaat is het aantal gesprekken, I. E. het totale aantal gesprekken dat aan de voorwaarden voldoet, en de horizontale coördinaat is de tijd.",
        tip2: "Correlatieanalyse: analyseer de correlatie van meerdere indicatoren, de correlatiegegevens van de muisophanging, gegevensvolume, correlatie, herhalingssnelheid",
        tip3: "Conversieanalyse: analyze van meerdere indicatoren kan een wederzijdse conversierelatie vormen, de conversiecriteria voor dezelfde oproep/conversatie, als u twee verschillende indicatoren kunt raken, kunt u bedenken dat de twee indicatoren een conversierelatie hebben.",
      },
      knowledgeBase: {
        title: "Kennisbasis",
        list: {
          add: "Subcategorie toevoegen",
          delete: "Classificatie verwijderen",
          categoryName: "Categorienaam",
          createSuccess: "Subcategorie met succes aangemaakt",
          keyWord: "Trefwoorden",
        },
        detail: {
          type: "Soort",
          status: "Status",
          classification: "Indeling",
          standardQuestion: "Standaard problemen",
          answerDetectionRange: "Antwoorddetectiebereik",
          standardAnswer: "Standaard antwoord",
        },
      },
      callManage: {
        title: "Oproepopnamefilters",
        listTitle: "Oproepbeheer",
        detailTitle: "Gespreksgegevens",
      },
      dialogueManage: {
        title: "Conversatietekstfilter",
        listTitle: "Dialoogbeheer",
        detailTitle: "Details van de dialoog",
      },
      weChatManage: {
        title: "Ondernemingsfilter WeChat",
        listTitle: "Enterprise WeChat Sessiebeheer",
        detailTitle: "Details van de Enterprise WeChat-sessie",
      },
      speechRecognition: {
        title: "Spraakherkenning",
        asr: "Spraakherkenning",
        asrRemind:
          "Als u deze schakelaar uitschakelt, worden de spraak-naar-tekstfunctie en de inspectiefunctie van de machine uitgeschakeld.",
        asrConfig: "ASR-filtratie",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Indien ingeschakeld, wordt ASR-transcriptie van IM uitgevoerd",
        asrConfigRemind:
          "Indien ingeschakeld, voeren alleen in aanmerking komende oproepen ASR-inhoud uit",
        correction: "Intelligente foutcorrectie",
        correctionRemind:
          "Indien ingeschakeld, wordt de inhoud in \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words' target =' _ blank'> Slimme thesaurus-professionele zelfstandige naamwoorden </a>\" gebruikt om foutcorrectie-conversie te forceren",
        correctionConfig: "Foutcorrectie filtering",
        advancedSettings: "Geavanceerde instellingen",
        advancedSettingsTip:
          "Selecteert het opgegeven ASR-model voor de opgegeven gegevens.",
        mandarinASRModel: "Mandarijn ASR model",
        cantoneseASRModel: "Kantonese ASR-model",
        englishASRModel: "Engels ASR-model",
        correctionConfigRemind:
          "Indien ingeschakeld, worden alleen oproepen gecorrigeerd die aan de volgende voorwaarden voldoen",
        customJudgeLogicIsNull: "Aangepaste logica kan niet leeg zijn",
        redoCorrection: "Voer foutcorrectie opnieuw uit",
        callTime: "Selecteer spreektijd",
        callTimeRemind:
          "U kunt maximaal één maand (31 dagen) aan oproepgegevens selecteren in het afgelopen jaar.",
        callRecordCount: "U hebt in totaal {0} oproeprecords geselecteerd",
        redoCorrectionSubmit: "Dien in voor executie",
        callTimeError:
          "Het geselecteerde oproeptijdbereik mag niet langer zijn dan één maand (31 dagen)",
        callRecordCountError:
          "Het aantal oproeprecords is 0, selecteer de oproeptijd opnieuw",
        redoCorrectionSuccess:
          "Herfoutcorrectie is succesvol, ga naar het taakcentrum om te bekijken",
        redoCorrectionError: "Opnieuw mislukt, probeer het later opnieuw",
      },
      role: {
        title: "Rolbeheer",
        create: "Nieuwe rol",
        detail: {
          title: "Rol",
          new: "Nieuwe rol",
          view: "Rollen weergeven",
          edit: "Rol bewerken",
          navTabNames: {
            basicInfo: "Menumachtigingen",
            taskPermissions: "Taaktoestemming voor kwaliteitsinspectie",
            dataPermissions: "Gegevensmachtigingen",
          },
          basicInfo: { roleName: "Naam", permissions: "Toestemmingen" },
          taskPermissions: {
            allTask: "Alle kwaliteitsinspectietaken",
            myCreatedTask: "De kwaliteitsinspectietaak die ik heb gemaakt",
          },
          dataPermissions: {
            dataPermissionsConfig: "Configuratie van gegevensmachtiging",
            tips: "Deze rol kan de gegevens onder de geselecteerde classificatie zien",
            select: "Categorie selecteren",
            selectPlaceholder:
              "Selecteer een classificatie waarvoor toestemming voor gegevenszichtbaarheid vereist is",
          },
        },
      },
      dataMigration: {
        title: "Gegevensmigratie",
        dataUpload: "Gegevensmigratie",
        dataDowload: "Gegevensmigratie",
        error: "Selecteer een kwaliteitstaak om te exporteren",
        list: {
          info: "Selecteer de kwaliteitsinspectietaak om te verhuizen",
          listInfo:
            "Selecteer of u gegevens wilt migreren met betrekking tot de kwaliteitsinspectietaak (zoals intelligente thesaurus, semantische tag, informatie-entiteit, intelligente interactie, enz.)",
          noInfo: "Selecteer of u andere niet-QC-gegevens wilt verplaatsen",
          ownInfo: "Alleen taken verplaatsen",
          everyInfo: "Taakgerelateerd aan verhuizing",
          allInfo: "Ga helemaal weg",
          callFilter: "Oproepfilter",
          talkFilter: "Dialoogfilter",
          smartTag: "Slim label",
        },
        upload: {
          info: "1. Klik op de knop Uploaden om een JSON-bestand te uploaden dat voldoet aan de indeling.",
          uploadSuccess: "Succesvol uploaden",
          uploadError: "Uploaden mislukt",
        },
      },
      dataAnalysis: {
        businessStatistics: "Bedrijfsstatistieken",
        qualityEffect: "Kwaliteitsinspectie gezondheid",
        qualityAnalysis: "Kwaliteitsinspectie analyze",
        generalInspecitionReport: "Algemeen Verslag",
        fullAmountWork: "Werklast van kwaliteitsinspecteur",
        intelligentTagsAnalysis: "Label Analyse",
        saleIntelligentTagsAnalysis: "Slimme etiketanalyse",
        generalInspecitionReportTip:
          "Totale gegevens: telt het totale aantal bel-en gespreksgegevens binnen de huidige tijdslimiet en taakbereik, inclusief het aantal en percentage intelligente kwaliteitsinspectiegegevens, het aantal en het percentage handmatige steekproefgegevens, het aantal beroepen, de bemonsteringsfrequentie en de tijdtrendgrafiek.",
        groupTest: "Groepstest",
      },
      uploadDownloadList: {
        title: "Missiecentrum",
        tabs: {
          upload: "Import uploaden",
          download: "Exporteren downloaden",
          taskCenter: "Taakuitvoering",
        },
      },
      uploadDownloadModel: {
        title: "Download uploaden",
        list: {
          clear: "Leeg",
          success: "Succes",
          fail: "Mislukt",
          hand: "In uitvoering",
          noInfo: "Geen gegevens",
        },
      },
      billingCenter: {
        title: "Factureringscentrum",
        export: "Uitvoer",
        limit: "Herinneringslimiet",
        tips: "De factureringsresultaten zijn bevooroordeeld vanwege afronding, alleen ter referentie",
        dashboard: {
          remainingQuota: "Lopende resterende quota",
          usedQuota: "Cumulatief gebruikt quotum",
          voiceQuality: "Inspectie van spraakkwaliteit:",
          voice: "Stem:",
          textQuality: "Tekstkwaliteitsinspectie:",
          text: "Tekst:",
          hour: "Uren",
          tenThousandHour: "Miljoen uur.",
          day: "Dag",
          cutoff: "Afsnijder",
          warning:
            "(Het resterende quotum is onvoldoende, laad zo snel mogelijk op)",
          isClosed: "(Gesloten staat)",
        },
        table: {
          quotaRecord: "Quota Record",
          timeFilter: "Selecteer een datum:",
          search: "Vraag",
          reset: "Reset",
          id: "Serienummer",
          type: "Soort",
          qualityType: "Type kwaliteitsinspectie",
          quotaChange: "Verandering",
          surplus: "Evenwicht",
          changeTime: "Tijd",
          voiceQuality: "Inspectie van spraakkwaliteit",
          textQuality: "Tekstkwaliteitsinspectie",
          sizeChangeText: "Weergave per pagina",
          used: "Gebruik",
          give: "Grant",
          deduction: "Aftrek",
          callTime: "Spreektijd",
          count: "Verwerkingsvolume van gegevens",
          page: "Pagina {1} van {0}",
        },
        limitModal: {
          call: "Het quotum voor inspectie van spraakkwaliteit is lager",
          callFix: "Uren om eraan te herinneren",
          dialogue:
            "De resterende tijd van de kwaliteitsinspectie van de tekst is minder",
          dialogueFix: "Dagen om eraan te herinneren",
        },
      },
      messageCenter: {
        replyTitle: "Antwoord aan mijn",
        answerTitle: "@ My",
        shareTitle: "Deel de mijne.",
      },
      columnsManage: { title: "Veldbeheer voor kwaliteitsinspectie" },
      recordUpload: {
        list: {
          task: "Kwaliteitsinspectietaak",
          user: "Exploitant",
          uploadTime: "Uploadtijd",
          endTime: "Eindtijd",
          total: "Totaal uploaden",
          success: "Kwaliteitsinspectie succes",
          failed: "Kwaliteitsinspectie mislukt",
          progress: "Vooruitgang van de kwaliteitsinspectie",
          inProgress: "In uitvoering",
          finished: "Voltooide",
        },
        steps: {
          uploading: "Opnamebestand uploaden",
          baseInfo: "Vul de basisinformatie in",
          start: "Start kwaliteitsinspectie",
        },
        uploadComponent: {
          dragger: "Sleep om bestanden toe te voegen",
          or: "Of",
          selectFile: "Bestand selecteren",
          des: "Ondersteuning wav, mp3-formaat, samplingsnelheid van 8000Hz,16-bit bestand",
          limit1: "Upload maximaal één keer",
          limit2: "Bestanden, is elke bestandsgrootte niet groter dan",
          uploading: "Uploaden",
          finished: "Uploaden voltooid",
          success: "Succes",
          failure: "Mislukt",
        },
        baseInfo: {
          task: "Selecteer Kwaliteitstaak",
          type: "Selecteer opnameformulier",
          agentSide: "Het kanaal waar de klantenservice zich bevindt",
          excel: "Upload onderweg gegevens",
          checkFileInfo: "Bestandsinformatie verifiëren",
          excelFileError: "Upload onderweg gegevens",
          checking: "Kalibratie in...",
          reCheck: "Herkalibratie",
          success: "Verificatie succes",
          excelUoloadFail:
            "Het uploaden van gegevens is mislukt langs de route.",
          tips: {
            tip1: "1. De naam van het opnamebestand in ecxel moet dezelfde zijn als de naam van de geüploade opname en kan niet dezelfde naam hebben.",
            tip2: "2. Het systeem controleert het duplicaat met de naam van het opnamebestand en de oproep-ID. Herhaalde gegevens zullen leiden tot een storing in de kwaliteitsinspectie.",
            tip3: "3. Voor de gedownloade Excel-opnamesjabloon is de lettertypekleur rood, wat een vereist veld is.",
            tip4: "4. Het veldformaat van het tijdtype is: jjjj-mm-dd hh:mm:ss, bijvoorbeeld 2021-04-20 17:36:30",
          },
        },
        title: "Lokale opname uploaden",
        checkTitle: "Inspectie van de kwaliteit van de opname",
        start: "Start kwaliteitsinspectie",
        disabledTips: "Controleer het opnamebestand vóór kwaliteitsinspectie",
        tips: "Het opnamebestand met dezelfde naam overschrijft de resultaten van de kwaliteitsinspectie en basisinformatie.",
        tips1: "Sleep het bestand hierheen of klik op Uploaden",
        tips2: "Ondersteuning",
        tips3: "Formaat",
        type: "wav、mp3",
        tips4: "Upload maximaal één keer",
        tips5: "Bestanden, is elke bestandsgrootte niet groter dan",
        tipsOverSize:
          "De huidige wachtrij is vol, verwijder de bestanden in de wachtrij voordat u ze uploadt.",
        size: "500M",
        count: "20",
        uploaded: "Opnamebestand geüpload",
        uploading: "({0}%)",
        uploadFinished: "(Upload compleet)",
        bottomTitle: "Het verzamelen van opname-informatie:",
        recordType: "Selecteer het opnameformulier:",
        double: "Dubbele oproepopname",
        single: "Eenkanaals oproepopname",
        recordBaseInfo: "Basisinformatie opnemen uploaden:",
        addBtn: "Bestand toevoegen",
        downloadTemplate: "Sjabloon Download. excel",
        checkRecord: "Verifieer de opname-informatie",
        checking: "Kalibratie in...",
        reCheck: "Herkalibratie",
        checkAll: "Alles selecteren",
        cancelCheckAll: "Alles selecteren annuleren",
        delete: "Verwijderen",
        deleteLotSize: "Batch verwijderen",
        agentChannel: "Klantenservice kanaal:",
        left: "Linker kanaal",
        right: "Rechter kanaal",
        donotKonw: "Ik weet niet",
        mission: "Selecteer een kwaliteitstaak:",
        uploadFail: "Uploaden mislukt",
        repeat: "Dubbel bestand",
        overCount: "Aantal bestanden overschrijdt de limiet",
        overSize: "Bestandsgrootte overschrijdt limiet",
        uoloadFail: "Uploaden naar cloud mislukt",
        formatError: "Formaatfout",
        seccess: "Verificatie succes",
        fail: "Verificatie mislukt",
        recordChecking: "Opname in kwaliteitsinspectie, alstublieft later...",
        recordCheckSuccess: "Inspectie van de opnamekwaliteit voltooid",
        recordCheckFail:
          "Inspectie van de opnamekwaliteit is mislukt, controleer of de opname duplicaat of ongeldig is",
        backToIndex: "Keer terug naar de uploadlijst",
        recordCheckIsStarted:
          "Momenteel wordt een kwaliteitsinspectietaak uitgevoerd. Start een nieuwe taak nadat de huidige taak is voltooid.",
      },
      customerServicePortrait: {
        title: "Klantenservice Portret",
        tip1: "De verdelingswet van de lengte van de zin: de verdeling van de lengte van de zin wordt in segmenten geteld op basis van het aantal woorden in de lengte van de zin en alleen de klantenservice",
        tip2: "Snelheidsverdelingswet: de verdeling van snelheid, volgens de snelheid (woorden/minuut), segmentstatistieken, bereken alleen de klantenservice",
        tip3: "Responstijdverdelingswet: de verdeling van de responstijd, alleen de responssituatie van de klantenservice wordt berekend.",
        tip4: "Naleving van kwaliteitsinspectie: nalevingspercentage van kwaliteitscontrolepunten",
        tip5: "Taakscore gemiddelde kwaliteitsinspectie: taakscore van gemiddelde kwaliteit",
        contol: {
          date: "Selecteer een datum:",
          today: "Vandaag",
          thisMonth: "Deze maand",
          lastMonth: "Vorige maand",
          thisQuarter: "Dit kwartaal",
          lastQuarter: "Laatste kwartaal",
          thisYear: "Dit jaar",
          lastYear: "Vorig jaar",
          customer: "Klantenservice:",
          search: "Vraag",
          reset: "Reset",
          export: "Uitvoer",
          indicator: "Beheer van indicatoren",
          selectPlaceholder:
            "Selecteer de klantenservice die moet worden vergeleken",
          warning: "Selecteer maximaal 5 agenten voor vergelijking",
          warning2: "Selecteer ten minste één agent om te informeren",
          dataWarning:
            "Alleen gegevens tot 366 dagen na de startdatum kunnen worden opgevraagd.",
          seccuss: "Record export succesvol",
        },
        echarts: {
          distributionLaw: "Wet van distributie",
          count: "Aantal keren",
          bout: "Tijden",
        },
        portrait: {
          ability: "Dimensie klantenservice",
          option: "Configuratie",
          abilityOption: "Afmetingen van capaciteiten configureren",
          placeholder: "Input vermogen dimensie trefwoord zoeken",
          save: "Bespaar",
          warning:
            "Selecteer ten minste 3 indicatoren te configureren, en u kunt maximaal 10 indicatoren",
        },
        label: { title: "Label klantenservice" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Klantenservice ranking",
        select: {
          date: "Selecteer een datum:",
          today: "Vandaag",
          thisMonth: "Deze maand",
          lastMonth: "Vorige maand",
          thisQuarter: "Dit kwartaal",
          lastQuarter: "Laatste kwartaal",
          thisYear: "Dit jaar",
          lastYear: "Vorig jaar",
          group: "Klantenservice Groep:",
          groupPlaceholder: "Selecteer een agentgroep",
          customer: "Klantenservice:",
          customerPlaceholder: "Selecteer klantenservice",
          search: "Vraag",
          reset: "Reset",
          compared: "Contrast",
          export: "Uitvoer",
          column: "Kolom",
          warning: "Selecteer maximaal 5 agenten voor vergelijking",
          warning2: "Selecteer ten minste één agent",
          dataWarning:
            "Alleen gegevens tot 366 dagen na de startdatum kunnen worden opgevraagd.",
          selected: "Geselecteerde agent:",
          seccuss: "Record export succesvol",
        },
        compared: {
          title: "Selecteer klantenservice",
          return: "Annuleer",
          compared: "Contrast",
          tips: "Selecteer 2-5 agenten voor vergelijking",
          warning: "Ter vergelijking worden slechts 2-5 agenten ondersteund",
        },
        transfer: { leftTitle: "Lijst artikel", rightTitle: "Item tonen" },
        table: {
          page: "Pagina {1} van {0}",
          actionTitle: "Operatie",
          action: "Weergave",
        },
      },
      conceptWord: {
        title: "Conceptwoorden",
        new: "Nieuw Conceptwoord",
        control: {
          search: "Zoeken:",
          searchPlaceholder: "Zoeken naar naam of trefwoord",
          commit: "Vraag",
          reset: "Reset",
          import: "Importeren",
          export: "Uitvoer",
          seccuss: "Record export succesvol",
        },
        modal: { title: "Importeer conceptwoorden" },
        table: {
          sizeChangeText: "Weergave per pagina",
          page: "Pagina {1} van {0}",
          edit: "Bewerken",
          delete: "Verwijderen",
          request:
            "Na verwijdering kan het niet worden hersteld. Weet u zeker dat u het wilt verwijderen?",
          cancel: "Annuleer",
          title: {
            conceptWord: "Conceptwoorden",
            describeWordsNum: "Aantal beschrijvingen",
            updateTime: "Update tijd",
            status: "Effectiviteit",
            effective: "Effectief",
            invalid: "Ongeldig",
            action: "Operatie",
          },
        },
        detail: {
          title: { new: "Nieuw Conceptwoord", edit: "Conceptwoorden bewerken" },
          name: "Concept woordnaam",
          namePlaceholder: "Voer een conceptwoordnaam in",
          effectiveness: "Effectiviteit",
          effective: "Effectief",
          invalid: "Ongeldig",
          description: "Beschrijvende woorden:",
          descriptionPlaceholder: "Voer een descriptor in",
          add: "Toevoegen",
          repeat: "Dubbele inhoud",
          empty: "Beschrijvende woorden kunnen niet leeg zijn",
          recommendEmpty:
            "Geen aanbevolen beschrijvingen meer, voer ze in en voeg ze handmatig toe.",
          recommend: "Intelligente aanbevelingsdescriptor",
          cancel: "Annuleer",
          change: "Een groep veranderen",
          descriptionSelected: "Toegevoegd descriptor:",
          save: "Bespaar",
          success: "Conceptwoorden met succes opgeslagen",
          warning: "Conceptwoordnaam kan niet leeg zijn",
          warning2: "Voeg ten minste 1 descriptor toe",
          submitBtnHandle: {
            title:
              "De geselecteerde descriptor is niet toegevoegd. Opslaan zal de niet-toegevoegde descriptor weggooien.",
            content:
              "Klik op OK om terug te keren naar de startpagina van het conceptwoord, bevestigen om door te gaan?",
          },
        },
      },
      smartTools: {
        btn: "Slim gereedschap",
        title: {
          similarWords: "Gerelateerde woorden gevonden",
          search: "Door woord",
          semanticTags: "Semantisch label",
        },
        sentenceSearch: {
          placeholder:
            "Voer een zoekwoord in, ondersteunt meerdere trefwoorden",
          more: "Volledige tekst bekijken",
          pageUp: "Vorige pagina",
          pageDown: "Volgende pagina",
          total: "Totaal {0} records",
        },
        similarDiscover: {
          placeholder: "Voer een trefwoord in",
          copyed: "Gekopieerd",
          none: "Geen relevante woorden, probeer andere trefwoorden",
        },
        smartTags: {
          searchPlaceHolder: "Voer trefwoorden of zinnen in",
          createSmartTag: "Nieuw",
          createClusteringSmartTag: "Nieuw aanmaken met {0} gegevens",
          addFromKeyword: "Voeg zoekinhoud toe aan vergelijkbare zinnen",
          hit: "Hit",
          similar: "Gelijkaardig",
          name: "Semantische etiketnaam",
          namePlaceHolder: "Voer een semantische labelnaam in",
          recallWords: "Word terugroepen",
          recallWordsPlaceHolder: "Voer een terugroeptrefwoord in",
          recallRegular: "Regelmatig terugroepen",
          recallRegularPlaceHolder: "Voer Regular Recall in",
          rules: "Regels",
          standardSentences: "Standaardzin",
          standardSentencesPlaceHolder: "Voer een standaardzin in",
          similaSentences: "Soortgelijke zinnen",
          similaSentencesPlaceHolder: "Voer een soortgelijke zin in",
          notSaveError: "Bewaar het semantische label voordat u gaat zoeken",
          add: "Toevoegen",
          newPage: "Open in nieuwe pagina",
          querryError:
            "Vergelijkbare velden bestaan in de generalisatielijst (geavanceerde configuratie van vergelijkbare deduplicatiebeperkingen)",
          detailTitle: "Details van semantische etiketten",
        },
      },
      trainingCenter: {
        title: "Opleidingscentrum",
        edit: "Bewerken",
        delete: "Verwijderen",
        seleteAll: "Alles selecteren",
        cancelCheckAll: "Alles selecteren annuleren",
        add: "Toevoegen",
        remove: "Verwijderen",
        save: "Bespaar",
        cancel: "Annuleer",
        fastTraining: {
          title: "Snelle training",
          titleDes:
            "Snelle training is ontworpen om semantische labels te helpen snel te generaliseren tot 20 vergelijkbare zinnen",
          semanticTags: "Semantisch label",
          semanticTagsPlaceholder:
            "Voer het zoeken op het trefwoord van het proces in",
          standardSentence: "Standaardzin:{0}",
          similarSentencesPlaceholder:
            "Voer vergelijkbare zinnen in om toe te voegen of te zoeken",
          similarSentencesAdd: "Toevoegen",
          similarSentencesSearch: "Zoeken",
          similarSentences: "Soortgelijke zin toegevoegd",
          trainingCount: "Aantal trainingen:{0}",
          similarSentencesRecommend: "Aanbevolen Soortgelijke Zinnen",
          noResp: "Geen relevant semantisch label, wijzig het trefwoord",
          similarSentencesModalTitle: "Soortgelijke zin zoeken",
          moreInfo: "Context bekijken",
          moreInfoBtn: "Context",
          modalSearchType: {
            all: "Allemaal",
            customer: "Klantenservice",
            client: "Klant",
          },
          repetWarning:
            "Dezelfde soortgelijke zin bestaat al, voeg deze niet herhaaldelijk toe.",
          repetRemoveTip:
            "Er is dubbele inhoud in de opgeslagen soortgelijke zin, die automatisch is gededupliceerd.",
        },
        trainingHistory: {
          title: "Trainingsgeschiedenis",
          select: {
            date: "Selecteer tijd:",
            today: "Vandaag",
            thisMonth: "Deze maand",
            lastMonth: "Vorige maand",
            thisQuarter: "Dit kwartaal",
            lastQuarter: "Laatste kwartaal",
            thisYear: "Dit jaar",
            lastYear: "Vorig jaar",
            search: "Vraag",
            reset: "Reset",
          },
          table: {
            time: "Tijd",
            user: "Exploitant",
            tag: "Semantisch label",
            similarCount: "Aantal soortgelijke zinnen",
            deleteCount: "Aantal verwijderde zinnen",
            page: "Pagina {1} van {0}",
          },
        },
        classifiedTraining: {
          title: "Classificatie training",
          tagsGrounp: "Labelgroepering",
          placeHolder: "Selecteer alstublieft",
          cellTime: "Selecteer tijd",
          search: "Vraag",
          reset: "Reset",
          classifiedFlag: "Classificatietraining",
          num: "Numeriek",
          gas: "Percentage",
          noData: "Niet-geclassificeerde opleiding",
          noDataHeight:
            "Niet-geclassificeerde gegevens zijn hoog en training is dringend nodig.",
          noDataSmall:
            "Niet-geclassificeerde gegevens zijn goed voor een relatief hoog aandeel en training wordt aanbevolen.",
          noDataDays: "{0} dagen voor de laatste executie",
          training: "Opleiding",
          start: "Begin",
          addClass: "Classificatie toevoegen",
          custom: "Geavanceerd filteren",
          marked: "Gelabelde gegevens Errata",
          markedHeight:
            "Errata is gedurende {0} dagen niet uitgevoerd en het wordt aanbevolen dit te doen.",
          markedSmall:
            "Errata is {0} dagen niet uitgevoerd. Errata is dringend nodig.",
          mark: "Errata",
          dataCheck: "Geclassificeerde gegevensverificatie",
          dataCheckSmall:
            "K-L divergentie hoger is dan 0,5, wordt aanbevolen om te trainen.",
          dataCheckHeight:
            "K-L divergentie is hoger dan 0,8 en training is dringend nodig.",
          check: "Kalibratie",
          history: "Callout Geschiedenis",
          historyTime:
            "Dagelijkse annotatie en modificatie zullen automatisch om 02.00 uur modeltraining uitvoeren.",
          today: "Vandaag getagd",
          article: "Artikel",
          none: "Geen",
          historyCheck: "Historische totale oproep",
          look: "Records bekijken",
          historyluate: "Herhaling van historische gegevens",
          startluate: "Kraanberekening",
          classData: "Gerubriceerde gegevens",
          classStatus: "Vooruitgang van de opleiding",
          next: "Volgende artikel",
          prev: "Vorige artikel",
          finish: "Volledige opleiding",
          stop: "Einde van de opleiding",
          classDetail: "Trainingsdetails",
          trainingNone: "Geen training voor het slimme label",
        },
        classifiedTrainingHistory: {
          title: "Trainingsgeschiedenis",
          time: "Selecteer tijd:",
          tag: "Etikettype:",
          user: "Operator:",
          data: "Selecteer een gegevensbron:",
          search: "Vraag",
          searchBar: "Zoeken:",
          searchBarPlaceholder: "Voer de oproep-ID/gesprek-ID-zoekopdracht in",
          reset: "Reset",
          export: "Uitvoer",
          all: "Allemaal",
          select: {
            today: "Vandaag",
            thisMonth: "Deze maand",
            lastMonth: "Vorige maand",
            thisQuarter: "Dit kwartaal",
            lastQuarter: "Laatste kwartaal",
            thisYear: "Dit jaar",
            lastYear: "Vorig jaar",
            search: "Vraag",
            reset: "Reset",
          },
          table: {
            user: "Exploitant",
            date: "Operatie tijd",
            data: "Gegevensbron",
            id: "Oproep-ID/Gesprek-ID",
            tagType: "Slimme labelgroepering",
            tagName: "Naam van het etiket",
            state: "Status",
            sizeChangeText: "Weergave per pagina",
            page: "Pagina {1} van {0}",
            call: "Bel",
            dialogue: "Dialoog",
          },
        },
        clusteringAnnotation: {
          title: "Clustering annotatie",
          left: { title: "Clusterresultaten" },
          right: {
            title: "Clusterende zinnen",
            checkAll: "Selecteer alle deze pagina",
            tagging: "Roep",
            detail: "Weergave",
            meaningless: "Negeren",
            meaninglessTips:
              "De genegeerde clusterresultaten worden in de toekomst niet weergegeven. Weet je zeker dat je ze wilt negeren?",
            cancel: "Annuleer",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Klantbeheer",
          tabTitle: "Klantenservicecentrum klant",
        },
        wechatClientManage: {
          title: "Enterprise micro-klant",
          table: {
            id: "Klant-ID",
            name: "Bijnaam van de klant",
            tag: "Klantenlabel",
          },
        },
      },
      collectionRule: {
        title: "Collectieregels",
        form: {
          info: "Intelligente informatieverzameling",
          infoPlaceholder: "Selecteer een categorie",
        },
        table: {
          name: "Veldnaam",
          props: "Klantkenmerken",
          type: "Veldtype",
          desc: "Veldbeschrijving",
        },
      },
      dataAdd: {
        title: "Gegevens toevoegen",
        types: { auto: "Automatisch toevoegen", manual: "Handmatig toevoegen" },
      },
      wechatStaff: {
        table: {
          name: "Naam",
          part: "Afdeling",
          phone: "Mobiel telefoonnummer",
          email: "Mailbox",
          wechartId: "Enterprise WeChat ID",
          status: "Status op het werk",
        },
        info: "Selecteer eerst de afdeling die gegevens moet synchroniseren",
        synSuccess: "Synchronisatie geslaagd",
        init: "Initialisatie",
        syn: "Synchronisatie",
      },
      appealHistory: {
        appealTime: "Beroepstijd",
        cancelTime: "Intrekkingstijd",
        reviewTime: "Tijd bekijken",
        inspector: "Kwaliteitsinspecteur",
        result: "Uitkomst van de klacht",
        complainResult:
          "In totaal is tegen {0} kwaliteitsinspectie-items beroep aangetekend, tegen {1} is met succes beroep aangetekend en {2} zijn afgewezen",
        reason: "Reden voor klacht",
        rejection: "Reden voor afwijzing",
        cancel: "Ingetrokken",
        tip: "Bevestig om de klacht in te trekken?",
      },
      appealSdk: {
        time: "Dialoogtijd",
        externalIdCall: "Oproeplogboek-ID",
        externalIdText: "Gespreksrecord-ID",
        customerNickName: "Bijnaam van de klant",
        readStatus: "Status beoordelen",
        status: "Klachtenstatus",
        isManual: "Handmatige kwaliteitsinspectie",
        complainButtonInfo:
          "{0} beroep toegestaan, {1} beroep al gedaan, {2} beroep resterend. {3} kan eerder in beroep gaan",
        submit: "Een klacht indienen",
        complain: "Klacht",
        allComplain: "Klachten allemaal",
        pendingSubmit: "In te dienen",
        taskIds: "Kwaliteitsinspectietaak",
        graded: "Beoordeling",
        complainSuccess: "Klacht met succes ingediend",
        appealSdkIndex: { title: "Intelligente kwaliteitsinspectieresultaten" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "U kunt geen informatie over de filterlijst krijgen. Controleer de netwerkverbinding of probeer het later opnieuw!",
      columns: "Kolom",
      filter: "Filter",
      noSearchResult: "Geen gegevens gevonden",
      pleaseSearch: "Selecteer een filter en probeer te zoeken",
      sift: "Filter",
      create: "Nieuw",
      search: "Zoeken",
      createTaskTitle: "Nieuwe taak",
      title: "Titel",
      deadline: "Deadline",
      principal: "De verantwoordelijke persoon",
      enqueueTime: "Toegangstijd",
      computeError: "Berekening is mislukt, wacht alstublieft!",
      sum: "Som",
      max: "Maximaal",
      min: "Minimum",
      avg: "Gemiddeld",
      count: "Graaf",
      maxDate: "Maximaal (nieuwste)",
      minDate: "Minimum (oudste)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Het adres van de link Insight is mislukt. Probeer het later opnieuw!",
      },
      template: {
        learningReminder: "Herinnering aan het leren",
        subscriptionDynamics: "Abonnement Dynamiek",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Taakbeheer", chaneg: "Schakelaar" },
        qualityCheckTemplate: {
          text: "Kwaliteitsinspectie sjabloon",
          collectText: "Aankoop sjabloon",
          condition: { text: "Kwaliteitsinspectievoorwaarden" },
          checkPoint: { text: "Regelconfiguratie" },
          automaticAdd: { text: "Procesautomatisering" },
          manualAdd: { text: "Handmatig toevoegen" },
          roleConfig: { text: "Rolconfiguratie" },
          basicInfo: { text: "Basisinformatie" },
          smartWordsLibrary: { text: "Intelligente thesaurus" },
          grade: { text: "Kwaliteitsinspectie beoordeling" },
          collectionRule: { text: "Collectieregels" },
          addData: { text: "Gegevens toevoegen" },
          collectionList: { text: "Overnamelijst" },
        },
        qualityCheckWorkbench: {
          text: "Kwaliteitsinspectie werkbank",
          wechatText: "Analyse Werkbank",
          informationCollectText: "Aankoop Werkbank",
          qualityInspectionList: {
            text: "Kwaliteitsinspectielijst",
            wechatText: "Analyselijst",
            informationCollectText: "Overnamelijst",
          },
          condition: { text: "Lijst van kwaliteitsinspectie (verouderd)" },
          reviewList: { text: "Beoordelingslijst" },
          spotCheckList: { text: "Bemonsteringslijst" },
          caseBaseList: { text: "Case Bibliotheek" },
          testSetList: { text: "Testset voor kwaliteitsinspectie" },
        },
        statisticsAndReports: {
          text: "Statistieken en rapporten",
          wholeReport: { text: "Algemeen rapport" },
          groupReport: { text: "Rapport van de klantenservice" },
          agentReport: { text: "Klantenservicerapport" },
          customerServiceExecution: { text: "Analyse van de klantenservice" },
          analysisOverview: { text: "Analyse Overzicht" },
          inspectionItemsAnalysis: {
            text: "Analyse van het kwaliteitsinspectie",
          },
        },
        wechatQa: {
          text: "Enterprise WeChat Kwaliteitsinspectie",
          task: { text: "Analyse taak" },
          baseInfo: { text: "Basisinformatie" },
          checkPoint: { text: "Analyse Modelconfiguratie" },
          setting: { text: "Configuratie van Enterprise WeChat Analyse" },
          automaticAdd: { text: "Procesautomatisering" },
          manualAdd: { text: "Handmatig toevoegen" },
        },
        clientCenter: { text: "Klantenservicecentrum" },
      },
      conditionDictionaries: {
        title: "Alle voorwaarden",
        searchDefaultText: "Voer een ID of naam in",
        id: "ID",
        name: "Naam",
        conditionDetailedShow: "Uitbreiden",
        conditionDetailedHide: "Stow",
        remark: "Beschrijving:",
        checkRole: "Rol:",
        operator: "Operator:",
        operatorLogic: "Logica:",
      },
      audioTextView: {
        customer: "Klant",
        agent: "Klantenservice",
        unsupportedFormat: "Dit formaat wordt niet ondersteund.",
      },
      checkPointClassifications: {
        add: "Categorie toevoegen",
        name: "Naam",
        enterToSave: "Druk op Enter om op te slaan",
        highestScore: "Maximale score cap",
        minimumScore: "Minimale score lagere limiet",
        defaultMinScore: "De standaardinstelling is 0 punten",
        minimumScoreTitle: "Lagere score limiet",
        beginScore: "Algemene startscore waarde",
        beginScoreTitle: "Startscore waarde",
        scoreSetTip:
          "De maximale score is minder dan de som van alle scores. U kunt selecteren:",
        ignoreExcessSpillover: "Negeer overtollige overloop",
        automaticWeightCalculation: "Automatische gewichtsboekhouding",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Veld" },
    checkRange: {
      numberTipOne: "Nee.",
      numberTipTwo: "Zin",
      numberTipThree: "Zin tot nr.",
      conditionLimit: "Voorwaardelijke beperkingen",
      roleLimit: "Rolbeperkingen",
      contextLimit: "Zin beperkingen",
      notZero: "Input 0 wordt niet ondersteund voor strafbeperking",
      startMustLessThanEnd:
        "De positie van de startzin moet minder zijn dan de positie van de eindzin",
    },
    waveAudio: {
      play: "Spelen",
      pause: "Pauzeer",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Voer alstublieft in",
      pleaseEnterRegular: "Voer een reguliere expressie in",
      pleaseEnterGrammar: "Voer een grammaticaregel in",
      pleaseEnterTestText: "Voer de te verifiëren inhoud in",
      pleaseEnterLoudness: "Voer de luidheidsdecibel in",
      pleaseEnterRangeAbility: "Voer het wijzigingsbereik in",
      afterHit: "Controleer na hit",
      pleaseEnterContext: "Voer een referentieverklaring in",
      wordNumber: "Woordkloof",
      intervalSecond: "Tijdsinterval groter",
      minute: "Elke minuut.",
      wordUnit: "Woord",
      test: "Test",
      testRegular: "Regelmatige expressies testen",
      testGrammar: "Test grammatica",
      testGrammarError: "Grammatica-test mislukt",
      regularError: "Reguliere expressiefout, voer opnieuw in",
      pleaseEnterSemanticSentence:
        "Voer een zin in die semantische detectie vereist",
      pleaseEnterAssociate:
        "Raadpleeg de instructies aan het recht om een data-along-uitdrukking op te bouwen",
      querySemanticTags: "Semantisch label selecteren",
      reQuerySemanticTags: "Reselecteer semantische labels",
      semanticTags: "Semantisch label",
      standardSentence: "Standaardzin",
      semanticName: "Semantisch label",
      sentence: "Standaardzin",
      similarity: "Gelijkenis",
      actions: "Operatie",
      tipOne: "Ik heb het juiste label niet gevonden,",
      tipTwo: "Een nieuwe semantische tag toevoegen",
      notLessThan: "Niet minder",
      notGreaterThan: "Niet groter",
      ignoreLessThan: "Negeer minder",
      wordUnitSet: "Zin van woorden",
      speedWordNotNull:
        "Het minimum aantal woorden en het maximale aantal woorden in spraaksnelheidsdetectie kunnen niet beide leeg zijn.",
      speedWordMinValueLessThanMaxValue:
        "Het minimum aantal woorden in spraaksnelheidsdetectie kan niet groter zijn dan het maximale aantal woorden.",
      speechGrabbingIntervalTip: "Interval> =",
      speechLengthTip: "De zin is lang> =",
      msUnit: "ms",
      sUnit: "Seconden",
      grabIntervalNotNull: "Het snatch-interval kan niet leeg zijn",
      grabLengthNotNull: "De zin kan niet leeg zijn.",
      pleaseSelectEntity: "Selecteer een informatie-entiteit",
      pleaseSelectOperator: "Selecteer een actie",
      pleaseSelectBasicInfo: "Selecteer de basisinformatie",
      pleaseEnterFixedValue: "Voer een vaste waarde in",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Wedstrijd met succes",
      matchError: "Match mislukt",
      mindetectionStatement: "Minimale detectieverklaring",
      conceptWordholder: "Selecteer een conceptwoord",
      elementHolder: "Voer een element in",
      associateMedol: { tabs: { data: "Gegevens", operator: "Exploitant" } },
      repet: "Herhaal",
      bout: "Tijden",
      ignore: "Negeer minder",
      ignoreAffix: "Zin van woorden",
      associateData: "Gegevens over de weg volgen",
      pretreatmentData: "Voorverwerkingsgegevens",
      reviewTaskData: "Taakgegevens bekijken",
      taskData: "Taakgegevens voor kwaliteitsinspectie",
      businessData: "Bedrijfsrecords",
      functionOperator: "Functiebeheerders",
      operation: "Operator",
      notAnyMatch: "Wanneer problemen niet worden geraakt:",
      times: "Aantal voorvallen",
      newAssociateDataTip:
        "Als de waarde een numeriek type is, gebruikt u de verouderde functie",
    },
    conditionGather: {
      cycle: "Cyclus",
      rlue: "Regels",
      delete: "Verwijderen",
      sampling: "Extractie",
      item: "Artikel",
      percent: "%",
      samplingSame: "Hetzelfde aantal extractie per agent",
      samplingRecheck: "Herhaalde extractie van bemonsterde artikelen toestaan",
      dateUnit: "Nee.",
      samplingTip:
        "Verschillende regels hebben betrekking op dezelfde oproep worden niet herhaaldelijk geëxtraheerd en records die zijn geëxtraheerd maar niet zijn gecontroleerd, worden niet opnieuw geëxtraheerd.",
      add: "Nieuwe regel",
    },
    ruleGather: {
      title: "Regels",
      screeningCondition: "Filterconditie",
      addRule: "Nieuwe regel",
      inspector: "Kwaliteitsinspecteur",
      ruleTip:
        "De regel-eerste toewijzingsmodus, van boven naar beneden, herhaalt de toewijzing niet, binnen de regel of komt niet overeen met regelitems worden willekeurig toegewezen.",
      moveUp: "Omhoog gaan",
      moveDown: "Naar beneden gaan",
      top: "Top",
    },
    pushRulesGather: {
      title: "Regels",
      delete: "Verwijderen",
      newPush: "Nieuwe push",
      newRule: "Nieuwe regel",
      sampling: "Extractie",
      item: "Artikel",
      percent: "%",
      samplingSame: "Hetzelfde aantal extractie per agent",
      dateUnit: "Nee.",
      screeningCondition: "Filterconditie",
      email: "Mailbox",
      emailPlaceholder: "Voer mailboxen in, gescheiden door komma's",
      updateType: "Type bijwerken",
      pushDetail: "Push Details",
      pushUrl: "Push-URL",
      pushWay: "Duwtype",
      pushMethod: "Push-modus",
      pushContentType: "Gegevensformaat",
      pushData: "Push-gegevens",
      additionalData: "Aanvullende gegevens",
      inspectionType: "Type kwaliteitsinspectie",
      all: "Allemaal",
    },
    taskAdditionalData: {
      newData: "Nieuwe gegevens",
      delete: "Verwijderen",
      placeholder: "Voer een naam in",
      dataPlaceholder: "Voer content in",
    },
    UdeskQaReact: {
      loadSdkError: "SDK laden is mislukt, probeer het later opnieuw!",
    },
    gearOptionList: { evaluation: "Evaluatie" },
    tableComponentTransfer: {
      leftTitle: "Lijst artikel",
      rightTitle: "Item tonen",
    },
    kmTinymce: {
      link: "Link invoegen/bewerken",
      modalTitle: "Link invoegen",
      href: "Adres",
      text: "Tekst weergeven",
      title: "Titel",
      target: "Open modus",
      none: "Geen",
      newWindow: "Open in nieuw venster",
      buttonStyle: "Knoopstijl",
      close: "Sluiten",
      open: "Open",
      answerMessage: "Jump/Stuur bericht",
      answerMessageTitle: "Berichtknop verzenden",
      answerMessageText: "Tekst weergeven",
      answerMessageContent: "Berichtinhoud",
      answerMessageButtonStyle: "Knoopstijl",
      answerMessageTips:
        "Opmerking: De verzendknop ondersteunt alleen het webkanaal, configureer het zorgvuldig.",
      answerSwitchStaff: "Handleiding springen/draaien",
      answerSwitchStaffTitle: "Handmatige knop inschakelen",
      answerSwitchStaffText: "Tekst weergeven",
      answerSwitchStaffRule: "Knopregels",
      answerSwitchStaffButtonStyle: "Knoopstijl",
      answerSwitchStaffTips:
        "Opmerking: De handmatige knop van de schakelaar ondersteunt alleen het webkanaal, configureer het zorgvuldig.",
      textButton: "Tekstknop",
      borderButton: "Ingelijste knop",
      message: "Bericht verzenden",
      switchStaff: "Handmatig draaien",
      messageError: "De inhoud van de knop mag niet groter zijn dan 10 woorden",
      messageIsEmptyError: "De inhoud van de knop kan niet leeg zijn",
      okText: "Bepaal",
      cancelText: "Annuleer",
      video: "Video",
      uploadVideo: "Video uploaden",
      uploadText: "Ondersteunt alleen MP4-formaat, grootte niet meer dan 20M",
      videoEmptyOrUploading: "Video is leeg of uploadt",
      customEmoji: "Uitdrukking",
    },
    massageConfiguration: {
      pushTarget: "Kennisgevingstreefcijfer",
      customizePushTarget: "Aangepaste push-bestemming",
      sysUser: "Huidige systeemgebruiker",
      sysRole: "Huidige systeemrol",
      emailPushTarget: "Bestemming verzenden",
      targetEmailsPlaceholder: "Voer mailboxen in, gescheiden door komma's",
      subject: "Naam bericht",
      SDKID: "SDK-ID",
      content: "Inhoud van kennisgeving",
      sdkPushType: "Push-schema",
      sdkPushURL: "Push-URL",
      pushData: "Push-gegevens",
      sdkPushWay: "Push-modus",
      dataType: "Gegevensformaat",
      additionalData: "Aanvullende gegevens",
      name: "Naam bericht",
      filter: "Filterconditie",
      triggerType: "Trigger mechanisme",
      pushCycle: "Timing vaste frequentie",
      pushContent: "Inhoud rapporteren",
      qualityList: "Resultaatlijst voor kwaliteitsinspectie",
      designated: "Aangewezen personeel",
      messageType: "Berichttype",
    },
    taskCard: {
      template: {
        cancelCollection: "Annuleer favoriet",
        collection: "Verzameling",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Standaardzin:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Verzameling",
          calls: "Een oproep",
          createdBy: "Gemaakt:",
          createdOn: "Creatie tijd:",
        },
        fileItem: {
          uIProvidesIcon: "Ui biedt pictogram",
          minute: "Minuten",
          callClip: "Oproepfragment",
          start: "Begin",
          End: "Einde",
          employees: "Werknemers:",
          Customer: "Klant:",
          sessionTime: "Sessie tijd:",
          reasonForRecommendation: "Reden voor aanbeveling:",
          addedBy: "Toegevoegd:",
          addTime: "Voeg tijd toe:",
        },
        modalAddFile: {
          caseTitle: "Titel van het geval",
          addSomeClipsToTheCaseLibrary:
            "Voeg gedeeltelijke fragmenten toe aan de case-bibliotheek",
          addToCaseBase: "Bibliotheek Toevoegen aan Case",
          storedIn: "Opgeslagen in",
          pleaseSelect: "Selecteer alstublieft",
          reasonsForRecommendation: "Reden voor aanbeveling",
          pleaseEnter: "Voer alstublieft in",
        },
      },
      shareModal: {
        index: {
          customer: "Klant:",
          employees: "Werknemers:",
          timeliness: "Tijdigheid:",
          recordingLinkValidWithinDays: "Opnamelink geldig binnen enkele dagen",
          sharingInstructions: "DEELBESCHRIJVING:",
          shareSuccess: "Succes delen!",
          cancel: "Annuleer",
          share: "Delen",
          copySucceeded: "Kopieer succesvol!",
          copyShareLink: "Kopieer Share Link",
          shareWithColleagues: "Delen met collega's",
          shareWithCustomers: "Deel met klanten",
          staff: "Werknemer",
          selectColleagues: "Collega's selecteren",
          pleaseSelectColleaguesToShare: "Selecteer een collega om te delen",
          pleaseEnterTheSharingDescription:
            "Voer instructies voor het delen in",
          taskName: "Taken:",
          recordingShareValidWithinDays:
            "Sharing link geldig binnen enkele dagen",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Het bestand kan niet worden gevonden!",
          sale: "Verkoop:",
          customer: "Klant:",
          topicDetection: "Topic detectie:",
          keyEvents: "Belangrijkste evenementen:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Collega's selecteren",
          helpContent: "Help-inhoud",
          visibleRange: "Zichtbaar bereik",
          seekCounseling: "Zoek advies",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Vraag advies", concatText: "Naar" },
      },
      customerInsight: {
        index: {
          customerInformation: "Klantinformatie",
          customerInsight: "Klantinzichten",
          customerLabel: "Klantenlabel",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Woorden aanbevolen",
          objectionHandlingScripts: "Bezwaar hanteren",
          productSellingPointScripts: "Productverkooppunten",
          knowledgePointRecommendation: "Kennispuntaanbeveling",
          relatedKnowledgePoints: "Bijbehorende kennispunten",
          recommendedCases: "Aanbevolen gevallen",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Woorden aanbevolen",
          caseRecommendation: "Aanbeveling zaak",
          hit: "Hit",
          miss: "Gemiste",
          supervisionItem: "Toezichtartikelen",
          implementation: "Implementatiestatus",
          reInspection: "Herinspectie",
          supervisionItemSettings: "Instelling van het supervisieartikel",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Telefoongesprekken",
          enterpriseWeChatCall: "Enterprise Micro-Talk",
          enterpriseMicroConversation: "Enterprise microsessie",
          open: "Open",
          close: "Sluiten",
          conversationScenario: "Sessiescenario's",
          monitorConversationChannels: "Houd toezicht op het gesprekskanaal",
          permanentlyValid: "Permanent effectief",
          permanentlyInvalid: "Permanent ongeldig",
          customValidity: "Aangepaste geldigheidsperiode",
          termOfValidity: "Geldigheidsperiode",
          newRating: "Nieuwe beoordeling",
          whenConversation: "Wanneer de sessie",
          hit: "Hit",
          miss: "Gemiste",
          time: "Wanneer",
          rated: "De rating is",
          qualified: "Gekwalificeerd",
          unqualified: "Ongekwalificeerd",
          modifiedSuccessfully: "Wijziging geslaagd",
          classificationName: "Categorienaam",
          supervisionObject: "Object van toezicht",
          entryintoforceTime: "Effectieve tijd",
          supervisionItemRating: "Beoordeling van toezichtartikelen",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Controleer of de beoordeling van het toezicht correct is",
          ratingRules: "Beoordelingsregels",
          settingOfRatingRulesForSupervisionItems:
            "Instelling van de regel voor artikelbeoordeling",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Belangrijkste evenementen",
          supervisionItem: "Toezichtartikelen",
          conversationScenario: "Sessiescenario's",
          customerLabel: "Klantenlabel",
          reDetectionRules: "Regels opnieuw detecteren",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Download opname",
          downloadText: "Tekst downloaden",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Afbeelding uploaden",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Selecteer een foto met een grootte van maximaal 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Of",
            and: "En",
            wrong: "Niet",
            matchTo: "Match",
            rule: "Regels",
            satisfactionOperator: "Stel de operator tevreden",
            customerLabel: "Klantenlabel",
            keyEvents: "Belangrijkste evenementen",
            knowledgePoints: "Kennispunten",
            sessionLabel: "Sessielabel",
            supervisionItem: "Toezichtartikelen",
          },
          linkExplorer: { linkInTheText: "Link in het artikel:" },
        },
      },
    },
    createForm: {
      field: { customComponentNotFound: "Aangepaste component niet gevonden:" },
    },
    sdkLoaderBase: {
      component: { custom: "Aangepast" },
      dropdownMonit: {
        updateSucceeded: "Update geslaagd",
        areYouSureToDelete: "Weet u zeker dat u wilt verwijderen?",
        custom: "Aangepast",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Bewaar huidige rapportconfiguratie = = Formulier",
        saveCurrentReportConfiguration:
          "De huidige rapportconfiguratie opslaan",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Voer een gemeenschappelijke instellingsnaam in, de maximale lengte is 20!",
        preservation: "Bespaar",
      },
      template: {
        saveCurrentReportConfiguration:
          "De huidige rapportconfiguratie opslaan",
        updateCurrentReportConfiguration:
          "Update de huidige rapportconfiguratie",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Informatie over werkvolgorde",
        workOrder: "Werkorder",
        triggerScenario: "Trigger Scenario",
        associatedCallConversation: "Associate call/conversatie",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Test met huidige pass/dialoogtekst",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "Nee.", day: "Dag" },
      businessSummary: {
        normal: {
          equalTo: "Gelijk",
          notEqualTo: "Niet gelijk",
          contain: "Bevat",
          excluding: "Bevat niet",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Ik ben wie ik ben, een ander vuurwerk @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hallo, ik ben de melding van het waarschuwingsbericht voor de kwaliteitsinspectie van de robot, dat op 17 oktober aan de groep is toegevoegd",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Ik ben wie ik ben, een ander vuurwerk @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hallo, ik ben de melding van het waarschuwingsbericht voor de kwaliteitsinspectie van de robot, dat op 17 oktober aan de groep is toegevoegd",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Communicatiethema's",
          conversationSummary: "Samenvatting van de sessie",
          serviceSuggestions: "Aanbevelingen voor service",
        },
        template: { smartTags: "Slim label" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Klik om meer te leren" },
      schema: {
        textRobot: "Tekst robot",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Trainer escort, document vraag en antwoord, taak-gebaseerde dialoog, tafel vraag en antwoord, grafiek vraag en antwoord.",
        capitalRetentionRobot: "Retentie robot",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Rijke vervolgretentiestrategie, communicatie met hoge personificatie, om een hoge retentiegraad te garanderen, aanwijzingen voor efficiënte automatische conversie.",
        outboundRobot: "Uitgaande oproep robot",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Retourbezoek aan de voltooiing van de werkorder, servicetevredenheidsonderzoek, waarschuwing voor bedienings-en onderhoudsstoringen, herinnering voor bezorging, melding van e-commerceactiviteiten, benoeming van kandidaten, hulp bij bedrijfsautomatisering en verbetering van de bedrijfsefficiëntie",
        incomingRobot: "Inkomende robot",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Flexibele integratie van werkorders, bestellingen en andere bedrijfssystemen om procesautomatisering te bereiken; stem semantische model aangepaste herkenning, bedrijfsresultaten onmiddellijk; entiteit (mobiel telefoonnummer, bestelbedrag, besteltijd, adres,...) identificatie, snelle ondersteuning voor complexe bedrijven",
        intelligentQualityInspection: "Intelligente kwaliteitsinspectie",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Volledige kwaliteitsinspectie, tijdig en efficiënt, met een nauwkeurigheidspercentage van 85%",
        intelligentSessionAnalysis: "Intelligente sessieanalyse",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Inzicht in de korte raad van zaken, luister naar de stem van klanten, verbeter marketing transformatie",
        enterpriseWechatConversationAnalysis:
          "Analyse van de Enterprise WeChat-sessie",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Efficiënte identificatie van potentiële zakelijke kansen, risico's, marketingconversieratio steeg met 20%",
        assistant: "Stoelassistent",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "De agent-assistent kan de service van beginnende klantenservice garanderen en de gesprekskwaliteit van bekwame klantenservice verbeteren. Laat elke stoel je troef zijn",
        digitalPeople: "De digitale man",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          '"Face-to-face" antropomorfe communicatie, ondersteuning van spraak, tekst, video, touch multi-modale interactie, om meeslepende communicatie-ervaring te bereiken, merkimago en gebruikersservice te verbeteren',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "AI Kennis Mid-stage Enterprise Search",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Zoeken naar kennis, kennisdeling, zoeken naar documenten, zoeken naar databasesocking",
        aIKnowledgeCenterKCsKnowledgeBase:
          "KI Kennis Middle Desk · KCS-kennisbasis",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Biedt volledig levenscyclusbeheer van kennis, uniform onderhoud van robots en IM-kennisbanken, ondersteunt docking met kennisbanken van derden, kennissjablonen, atomaire kennis en andere krachtige functies om een gemakkelijkere ervaring op het gebied van kennisbeheer te bieden.",
        aIKnowledgeCenterKnowledgeMap: "AI Kennisplatform-Kenniskaart",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "One-stop mapping-constructie integreert bedrijfskennis, big picture toont kennisdetails en docking-mogelijkheden om aangepaste ervaring te creëren.",
        modularDevelopmentPlatformOriginalEngineASR:
          "Modulair ontwikkelingsplatform-Originele hartmotor-ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Gebruik een end-to-end-algoritme om op maat gemaakte service-effectervaring te realiseren, met een natuurlijke scèneherkenningspercentage van meer dan 90% en gepersonaliseerde woordenschatherkenning van meer dan 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Modulair ontwikkelingsplatform · Intelligente marketing",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Met behulp van deep learning-algoritmen, gecombineerd met gebruikersportretten, om nauwkeurige productaanbevelingen te bereiken en verkoopconversie te verbeteren.",
        modularDevelopmentPlatformUserPortrait:
          "Modulair ontwikkelingsplatform · Gebruikersportret",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Stel gebruikersprofielen nauwkeurig op op basis van gebruikersgegevens en dialooggegevens, waarbij de kenmerken van de klant volledig worden beschreven",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Modulair ontwikkelingsplatform · Intelligente aanbeveling",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Volgens het gebruikersportret en productportret, automatisch producten aanbevelen die van belang kunnen zijn voor gebruikers, waarbij gepersonaliseerde aanbevelingen voor duizenden mensen worden gerealiseerd.",
        modularDevelopmentPlatformNLPPAAS:
          "Modulair ontwikkelingsplatform · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Biedt basis NLP-mogelijkheden met een hoge nauwkeurigheid, met betrekking tot low-level mogelijkheden van verschillende granulariteit, zoals woorden, zinnen en artikelen.",
      },
    },
    qualityScore: {
      index: {
        show: "Toon:",
        displayBySecondaryClassification:
          "Weergave op secundaire classificatie",
        showByRule: "Toon op regel",
        pleaseEnterClassificationruleName: "Voer de classificatie/regelnaam in",
        clickCopy: "Klik op Kopiëren",
        hitDetailsOfOperatorsInRules: "Operator hit details in de regel:",
        hit: "Hit",
        miss: "Gemiste",
        filter: "Filter:",
        clickToViewDetails: "Klik om details te bekijken",
      },
      components: {
        flowNodeList: {
          branch: "Punten",
          pleaseSelect: "Selecteer alstublieft",
          viewTheCompleteProcess: "Volledig proces bekijken",
          thereIsCurrentlyNoProcessAvailable: "Geen proces",
        },
        pointItem: { xiangHit: "Item hit" },
        flowNodeListModal: { index: { completeProcess: "Volledig proces" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "De taak van de proceskwaliteitsinspectie is veranderd, kunt u opnieuw in beroep gaan.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Deze gegevens zijn periodiek verwijderd",
        subject: "Betreft:",
        description: "Beschrijving:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "Toevoegen aan kwaliteitstaak" },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Associate call/conversatie" },
    },
    samplingTarget: {
      index: {
        time: "Wanneer",
        samplingQuantityIsLessThan: "De bemonsteringshoeveelheid is lager",
        alertWhen: "Vroege waarschuwing",
        everyday: "Dagelijks",
        weekly: "Wekelijks",
        monthly: "Maandelijks",
        samplingInspectionTargetSavedSuccessfully:
          "Bemonsteringsinspectiedoel met succes gered",
        personnel: "Personeel",
        eachCustomerService: "Elke klantenservice",
        cycle: "Cyclus",
        samplingInspectionTarget: "Bemonsteringsinspectiedoelstelling",
        strip: "Artikel",
        alertSettings: "Instellingen voor waarschuwingen",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Klantenservice",
        complete: "Compleet",
        Objective: "/Doel",
        everyday: "Dagelijks",
        weekly: "Wekelijks",
        monthly: "Maandelijks",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Interactiepercentage groepchat",
        maximumResponseIntervalTime: "Maximaal interval tussen reacties",
        numberOfPreemptiveCalls: "Aantal flarden",
        numberOfMessages: "Aantal berichten",
        strip: "Artikel",
        proportionOfMessages: "Percentage van het aantal berichten",
        speechPercentage: "Spreekverhouding",
        longestCustomerTalk: "Langste klant spreken",
        numberOfIndepthDiscussions: "Aantal diepgaande discussies",
        second: "Tijden",
        maximumDurationOfContinuousSpeech: "Maximale continue spreekduur",
        questionFrequency: "Vraagfrequentie",
        customer: "Klant",
        keyEvents: "Belangrijkste evenementen:",
        opportunity: {
          index: {
            generationFailed: "Bouwen mislukt",
            generateOpportunityPoints: "Opportuniteitspunten genereren",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Bouwen mislukt",
            generateSummary: "Samenvatting genereren",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Weet u zeker dat u wilt verwijderen?",
        add: "Toevoegen",
        opportunityPoints: "Kansenpunt",
        conversationSummary: "Samenvatting van de sessie",
        cancel: "Annuleer",
        determine: "Bepaal",
        edit: "Bewerken",
        establish: "Creëer",
        conversationalInsights: "Sessie Insights",
        keyEvents: "Belangrijkste evenementen",
        numKeyEvents: "Belangrijkste evenementen",
        questionAnalysis: "Analyze van vragen",
        find: "Gevonden",
        questions: "Tweede vraag",
        conversationAction: "Sessie Actie",
        customer: "Klant",
        sale: "Verkoop",
        sessionLabel: "Sessiescenario's",
        employeeQuestions: "Vragen van het personeel",
        customerQuestions: "Vragen van klanten",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Klant",
        customerService: "Klantenservice",
        replyTo: "Antwoord",
        addressee: "Aan:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Heridentificatie",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Heridentificatie zal de overdrachtstijd in beslag nemen, wees voorzichtig.",
        determine: "Bepaal",
        executionSuccessful: "Succesvolle uitvoering",
        addToQualityInspectionTask: "Toevoegen aan kwaliteitstaak",
        batchOperation: "Batch operatie",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Succesvol toegevoegd",
        addToQualityInspectionTask: "Toevoegen aan kwaliteitstaak",
        qualityInspectionTask: "Kwaliteitsinspectietaak",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Rol detecteren",
        detectionRange: "Detectiebereik",
        sentence: "Zin",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Deze gegevens zijn periodiek verwijderd",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Keer terug naar Business System" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Probleem extractie",
        businessSummary: "Samenvatting van het bedrijf",
        keyWord: "Trefwoorden",
      },
      components: {
        problemExtraction: {
          no: "Nee",
          customerServiceDidNotAnswer: "Klantenservice niet beantwoord",
          customerServiceHasAnswered: "Klantenservice heeft beantwoord",
          problemUnresolved: "Probleem niet opgelost",
          theProblemHasBeenResolved: "Probleem opgelost",
          customerIssue: "Klantproblemen:",
          customerServiceAnswer: "Antwoord van de klantenservice:",
          reExtractQuestions: "Opnieuw extractieprobleem",
          clickToExtractQuestions: "Klik om vragen te extraheren",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Portretnaam",
            backgroundDescription: "Beschrijving op de achtergrond",
            customerLabel: "Klantenlabel",
            labelManagement: "Labelbeheer",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Nieuw" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Instellingen voor ondervraging" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Verhoog vragen",
              remove: "Verwijderen",
              addQuestioning: "Vragen toevoegen",
              section: "Nee.",
              secondInquiry: "Tweede ondervraging",
              questioningScore: "Score in vraag stellen",
            },
          },
        },
        index: {
          accordingToRules: "Volgens regel",
          byScore: "Door score",
          whenMissRule: "Wanneer de regel wordt gemist",
          whenConductingQuestioning: "Bij het stellen van vragen",
          pleaseSelectARule: "Selecteer een regel",
          ruleCannotBeEmpty: "Regel kan niet leeg zijn",
          currentScriptScore: "Wanneer de spraakscore ≤",
          pleaseEnterTheScore: "Voer een score in",
          scoreCannotBeEmpty: "Score kan niet leeg zijn",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Verteller:" },
          pPT: { slide: "Glijbaan" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Opmerkingen",
              script: "Woorden",
              semantics: "Semantisch",
              violatingWords: "Overtreding van woorden",
              speechSpeed: "Snelheid van spraak",
              emotion: "Emotie",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Opmerkingen",
              regenerate: "Regenereren",
              generate: "Genereren",
              hitSituation: "Hoesje",
              noSemanticTagsMatched: "Niet afgestemd op semantisch label",
              matchToSemanticTags: "Overeenkomen met semantisch label",
              appealToBeSubmitted: "Klacht moet worden ingediend",
              ratingHasBeenReviewed: "Beoordeling",
              ratingAppealInProgress: "Grieven scoren",
              expressionScore: "Uitdrukkingsscore",
              moodParticlesTimes: "Toon woord, {0} keer",
              changeTheModalParticlesTo: "Voor modale woorden",
              second: "Tijden",
              interrogation: "Vragen stellen",
              questioningWithoutScoring: "Vraag zonder te scoren",
              branch: "Punten",
              matchingRate: "Bijpassende snelheid",
              ratingAppeal: "Beoordeling Grievance",
              changeTo: "Verandering",
              wordPerMinute: "Woorden per minuut",
              completeScript: "Volledige spraak",
              incompleteScript: "Onvolledige spraak",
              semanticHit: "Semantische hit",
              semanticMisses: "Semantische missers",
              scriptViolation: "Overtreding van de toespraak",
              modified: "Gewijzigd",
              notChanged: "Niet gewijzigd",
              keyWord: "Trefwoorden",
              semanticLabels: "Semantisch label",
              semanticMatchingRate: "Semantische matching rate",
              violatingWords: 'Overtreding van het woord"',
              delete: "Verwijderen",
              unmatched: "Niet gematcht",
              matching: "Match",
              matchTo: "Match",
              notMatchedTo: "Niet gematcht",
              scriptScore: "Spraakscore",
              standardScript: "Standaard toespraak",
              emotionalScore: "Emotionele score",
              speechSpeedScore: "Snelheidsscore",
              viewResolution: "Resolutie bekijken",
              nodeConfiguration: "Knooppuntconfiguratie",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "De antwoordtijd van deze vraag is overuren en de antwoordinhoud wordt automatisch ingediend.",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "De antwoordtijd van deze vraag is overuren en de antwoordinhoud wordt automatisch ingediend.",
          },
          correctBtn: {
            saveSuccessful: "Bespaar met succes",
            errorCorrection: "Foutcorrectie",
            cancel: "Annuleer",
            preserve: "Bespaar",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Scoreregels",
            cancel: "Annuleer",
            determine: "Bepaal",
          },
          scoringRuleItem: {
            whole: "Allemaal",
            arbitrarily: "Willekeurig",
            pleaseEnterARuleName: "Voer een regelnaam in",
            standardScript: "Standaard toespraak",
            if: "Als",
            and: "En",
            or: "Of",
            thenScore: "Dan scoren",
            pleaseEnterAScore: "Voer een score in",
            bonusPoints: "Bonuspunten",
            minusPoints: "Min punten",
            hit: "Hit",
            misses: "Gemiste",
            keyWord: "Trefwoorden",
            semantics: "Semantisch",
          },
          title: {
            ruleValidation: "Regelverificatie",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Scoregels (de totale score van de huidige toespraak is",
            points: "Punten)",
            editRules: "Regel bewerken",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Geen label" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Communicatiedoelstellingen",
              scoringCriteria: "Scorecriteria",
            },
          },
        },
        index: {
          generating: "Genereren",
          generateDialogueFramework: "Een dialoogkader genereren",
          theme: "Thema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Prompt",
            generatePromptLanguage: "Prompt gegenereerd",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Communicatiefase",
          communicationObjectives: "Communicatiedoelstellingen",
          scoringCriteria: "Scorecriteria",
          suggestionsForImprovement: "Aanbevelingen voor promotie",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Procescore",
            contentIntegrity: "Inhoud integriteit:",
            NumberOfCompletedProcesses: "%, Aantal voltooide processen",
            keyHitPointsTotal: "Belangrijkste hits: Totaal",
            notReached: "Niet bereikt",
            accurateFollowupAccumulationOfStandardScripts:
              "Nauwkeurige follow-up: standaard spraakaccumulatie",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Een woord, het aantal woorden geraakt door de student",
            aWord: "Woord",
            languageNormsYes: "Taalspecificatie: Ja",
            emotionsAppearing: "Emoties: verschijnen",
            speechSpeedSpeakingTooFast: "SNELHEID: SNELHEID te snel",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Toon woorden: overmatig voorkomen van toonwoorden",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Tags Toevoegen aan negeren",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Weet u zeker dat u het slimme label opnieuw wilt uitvoeren?",
        reExecute: "Heruitvoering",
      },
      component: { successfullyAdded: "Succesvol toegevoegd" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Schakel alle meldingen uit",
        reQualityInspectionTask: "Herinspectietaak",
        requalityInspection: "Herinspectie",
        waiting: "Wachten",
        viewDetails: "Details bekijken",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Detectietijd:" } },
        },
        template: { responseOpeningSentence: "Reactie start zin" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "Bouwen mislukt", generate: "Genereren" },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Geen gegevens" } },
      },
      index: { regenerate: "Regenereren" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Weet u zeker dat u wilt verwijderen?",
        operationSuccessful: "Operatie is geslaagd",
      },
      template: { delete: "Verwijderen" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Selecteer een vraag!",
        similarQuestionRecommendation: "Soortgelijke aanbevelingen",
        selectAllOnPage: "Bij het selecteren van alle pagina's",
        cancel: "Annuleer",
        preserve: "Bespaar",
        changeBatch: "Een batch wijzigen",
      },
    },
    listControl: { index: { columnControl: "Kolombesturing" } },
    loginUserAvatar: { index: { switch: "Schakelaar" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Bij het toevoegen van gegevens in realtime kost de kwaliteitsinspectietaak nog steeds tijd om uit te voeren. De specifieke tijd is afhankelijk van de grootte van de kwaliteitsinspectiegegevens, variërend van enkele minuten tot 2 uur.",
      },
    },
    bEditor: {
      index: {
        delete: "Verwijderen",
        cancel: "Annuleer",
        confirm: "Bepaal",
        insert: "Invoegen",
        width: "Breedte",
        height: "Hoogte",
        clearContent: "Duidelijke inhoud",
        revoked: "Ingetrokken",
        redo: "Opnieuw",
        fontSize: "Lettergrootte",
        rowHeight: "Rijhoogte",
        wordSpacing: "Woordafstand",
        paragraphIndent: "Alinea Inspringen",
        increaseIndent: "Verhoog de inspringing",
        decreaseIndent: "Afname inspringen",
        border: "Grens",
        color: "Kleur",
        textColor: "Tekstkleur",
        backgroundColor: "Achtergrondkleur",
        temporaryColor: "Tijdelijke kleur",
        bold: "Gevet",
        italic: "Cursief",
        underline: "Onderstrepen",
        strikethrough: "Doorhalen",
        font: "Lettertype",
        textAlignment: "Tekstuitlijning",
        left: "Links",
        centered: "Gecentreerd",
        right: "Rechtsaf",
        bothEnds: "Beide uiteinden",
        leftFloat: "Linker zweven",
        rightFloat: "Rechts drijven",
        subrupt: "Subrupt",
        subscript: "Subscript",
        clearStyle: "Duidelijke stijl",
        title: "Titel",
        regular: "Regelmatig",
        orderedList: "Bestelde lijst",
        unorderedList: "Ongeordende lijst",
        reference: "Referentie",
        code: "Code",
        link: "Link",
        clearLink: "Duidelijke link",
        horizontalLine: "Horizontale lijn",
        media: "Media",
        mediaLibrary: "Mediabibliotheek",
        smallExpression: "Kleine uitdrukking",
        fullScreen: "Volledig scherm",
        exitFullScreen: "Verlaat het volledige scherm",
        enterLinkText: "Linktekst invoeren",
        enterLinkAddress: "Linkadres invoeren",
        enterTheLinkAddressAndPressEnter:
          "Voer het linkadres in en druk op Enter",
        openInNewWindow: "Open in nieuw venster",
        removeLink: "Link verwijderen",
        playingAudioFiles: "Audio-bestanden afspelen",
        playVideoFiles: "Videobestanden afspelen",
        embeddedMedia: "Ingebedde media",
        image: "Afbeelding",
        video: "Video",
        audio: "Audio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Naam",
        description: "Beschrijving",
        type: "Soort",
        status: "Status",
        actions: "Operatie",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Naam van de taak",
        description: "Beschrijving",
        form: "Kwaliteitsinspectieformulier",
        actions: "Operatie",
        createUser: "Schepper",
      },
      samplingRecords: {
        createTime: "Tijd",
        type: "Soort",
        operateUser: "Exploitant",
        number: "Hoeveelheid toevoegen",
        filterConditionList: "Filterconditie",
        sampleRuleStr: "Bemonsteringsvoorwaarden",
        timeGreaterThan: "De tijd is groter",
        timeLessThan: "Tijd minder",
      },
    },
    customFilters: {
      createdAt: "Spreektijd",
      customerId: "Klant",
      agentId: "Klantenservice",
      userGroupId: "Klantenservicegroep",
      state: "Status",
      lastEvaluateUserId: "Laatste rater",
      submitTime: "Laatste inzendingstijd",
      totalScore: "Totaal score",
      formItemForbidIdList: "Verboden artikelen",
      formItemDeadlyIdList: "Fataal artikel",
    },
    samplingInspection: {
      callTime: "Spreektijd",
      dialogTime: "Dialoogtijd",
      originator: "Toegekend persoon",
      createTime: "Creatietijd",
      updateTime: "Update tijd",
      status: "Processtatus",
      score: "Fractie",
      actions: "Operatie",
      reviewer: "Recensent",
      samplingOfficer: "Bemonsteringsinspecteur",
      id: "Oproeplogboek-ID",
      time: "Spreektijd",
      phone: "Klanttelefoon",
      staff: "Klantenservice",
      inspectionStatus: "Kwaliteitsinspectiestatus",
      samplingStatus: "Bemonsteringsstatus",
      inspectionGrade: "Beoordeling",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Standaard woord",
        synonymWords: "Synoniemen",
        synonymWordsNumber: "Aantal synoniemen",
        useFrequency: "Frequentie van gebruik",
        effectiveness: "Effectiviteit",
        operation: "Operatie",
      },
      common: {
        useFrequency: "Frequentie van gebruik",
        effectiveness: "Effectiviteit",
        operation: "Operatie",
      },
      professionalWords: {
        professionalWords: "Professionele Noun",
        useFrequency: "Statistieken gebruiken",
        homophonic: "Hete woorden (homofoonvervanging)",
        target: "Substitutie zelfstandig naamwoord",
        targetWords: "Vervang woorden",
        homophonyWords: "Homofoon maskeerwoord",
        operation: "Operatie",
      },
      attentionWords: "Bezorgde woorden",
      ignoreWords: "Negeer woorden",
      wordsWeight: {
        selfDefineWeightWords: "Aangepast gewicht woord",
        weightWords: "Gewicht woorden",
        weight: "Gewicht",
        useFrequency: "Frequentie van gebruik",
        effectiveness: "Effectiviteit",
        operation: "Operatie",
      },
      corpusData: {
        corpusDescription: "Corpus beschrijving",
        updateTime: "Update tijd",
        wordsCount: "Aantal woorden",
        effectiveness: "Effectiviteit",
        operation: "Operatie",
        corpusContent: "Inhoud",
      },
    },
    semanticTags: {
      title: "Semantisch label",
      tags: "Standaardzin",
      orgName: "Organisatie",
      tagCount: "Aantal opleidingen",
      usingCount: "Aantal referenties",
      activeFlag: "Effectiviteit",
      suggestion: "Trainingsaanbevelingen",
      actions: "Operatie",
    },
    intelligentTags: {
      tagName: "Naam",
      tagDesc: "Beschrijving",
      tagRefCnt: "Aantal merken",
      categoryName: "Labelclassificatie",
      eventName: "Evenementclassificatie",
      tagStatus: "Effectiviteit",
      actions: "Operatie",
      tagType: "Label Type",
    },
    dataPreprocess: {
      name: "Naam",
      desc: "Beschrijving",
      dataSource: "Gegevensbronlimieten",
      type: "Soort",
      status: "Effectiviteit",
      actions: "Operatie",
    },
    informationEntities: {
      name: "Naam",
      apiName: "API-naam",
      type: "Soort",
      remark: "Beschrijving",
      activeFlag: "Effectiviteit",
      actions: "Operatie",
    },
    interactiveRecognition: {
      questionAndAnswer: "Standaard Vragen & Antwoorden",
      type: "Soort",
      status: "Status",
      actions: "Operatie",
      question: "Vraag",
    },
    role: { id: "ID", name: "Naam", actions: "Operatie" },
    uploadDownload: {
      taskName: "Bestandsnaam",
      createTime: "Datum",
      size: "Grootte",
      createUserName: "Exploitant",
      status: "Status",
      result: "Resultaten",
      actions: "Operatie",
    },
    taskCenterList: {
      name: "Inhoud van de taak",
      createTime: "Inzendingstijd",
      startTime: "Starttijd",
      endTime: "Eindtijd",
      createUserName: "Exploitant",
      status: "Status",
      result: "Resultaten",
      actions: "Operatie",
    },
    customerInformation: {
      title: "Informatie over klantenservice",
      data: "Alleen uitzonderingsgegevens bekijken",
      list: {
        agentNo: "Werknummer/klantenservice-ID",
        agentName: "Klantenservice",
        agentTel: "Telefoon",
        agentEmail: "Mailbox",
        updateTime: "Update tijd",
        actions: "Operatie",
      },
      upload: {
        second:
          "2. Klik op de knop Importeren om het EXCEl-bestand te uploaden dat voldoet aan de sjabloonindeling",
      },
    },
    IndexManagement: {
      title: "Beheer van indicatoren",
      list: {
        indicatorName: "Naam",
        indicatorDesc: "Beschrijving",
        activity: "Effectiviteit",
        inspectDataSource: "Gegevensbron",
        sysType: "Soort",
        agentGroups: "Klantenservicegroep",
        actions: "Operatie",
      },
      detail: {
        nameEmpty: "Naam kan niet leeg zijn",
        descript: "Beschrijving",
        descriptEmpty: "Beschrijving kan niet leeg zijn",
        active: "Effectiviteit",
        activeEmpty: "Geldigheid kan niet leeg zijn",
        dataSource: "Gegevensbron",
        dataSourceEmpty: "De gegevensbron kan niet leeg zijn",
        grounp: "Klantenservicegroep",
        grounpEmpty: "De agentengroep kan niet leeg zijn",
        grounpHolder: "Selecteer een agentgroep",
        type: "Scoremethode",
        typeNone: "De scoremethode kan niet leeg zijn",
        base: "Stichtingspunten",
        baseNone: "Basisscore kan niet leeg zijn",
        positive: "Positieve factoren",
        positiveNone: "Positieve factor kan niet leeg zijn",
        customAdd: "Filter toevoegen",
        negative: "Negatieve factoren",
        negativeNone: "Negatieve factoren kunnen niet leeg zijn",
        customOK: "Filtercriteria:",
        judgeStrategy: "Voorwaarde regels:",
        other: "Andere factoren",
        otherAdd: "Voeg factor toe",
        agent: "Onbewerkte gegevens",
        section: "Aantal intervallen",
        sectionNone: "Aantal intervallen kan niet leeg zijn",
        step: "Interval stapgrootte",
        stepNone: "Interval stapgrootte",
        save: "Bespaar",
        cancel: "Annuleer",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Allemaal",
      staff: "Alleen mezelf",
      department: "Afdeling en hieronder",
    },
    gongStatisticsDate: { week: "Week", month: "Maand", season: "Seizoen" },
    gongCoachTaskStatus: {
      wait: "Om begeleid te worden",
      already: "Gecoacht",
      ignore: "Genegeerd",
    },
    callRangeStatus: {
      all: "Alle oproepen",
      sale: "Werknemer geïnitieerd",
      customer: "Klant geïnitieerd",
    },
    todoListType: {
      later: "Luister later",
      help: "Begeleiding voor hulp",
      contact: "Later contact opnemen",
      send: "Gegevens verzenden",
      warn: "Risico vroegtijdige waarschuwing",
    },
    frequencyTypes: {
      everyDay: "Dagelijks",
      everyWeek: "Wekelijks",
      everyMonth: "Maandelijks",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Niet-geclassificeerde gegevenstraining",
        markedDataCorrigenda: "Gelabelde gegevens Errata",
        classifiedDataCheck: "Geclassificeerde gegevensverificatie",
        markedHistoryRecord: "Callout Geschiedenis",
        historyDataRecalculate: "Herhaling van historische gegevens",
      },
      dataExtractMode: {
        random: "Volledig willekeurig",
        intelligentRecommend: "Intelligente aanbeveling",
        custom: "Aangepast",
        specifiedClass: "Classificatie specificeren",
      },
      smartRatesList: {
        markRate: "Markeringspercentage",
        corrigendaRate: "Errata tarief",
        kl: "K-L divergentie",
        signalNoiseRate: "Signaal-ruisverhouding",
      },
    },
    visibleRangeTypes: {
      all: "Allemaal",
      customerGroup: "Klantenservicegroep",
      userDefined: "Aangepast",
    },
    samplingRateTypes: {
      everyDay: "Teken dagelijks",
      everyWeek: "Wekelijkse trekking",
    },
    taskRemindTypes: {
      confirm:
        "Stuur een herinnering wanneer het taakrecord van de kwaliteitsinspectie moet worden bevestigd.",
      appeal:
        "Wanneer de taakregistratie voor kwaliteitsinspectie een klacht initieert, stuurt u een herinnering.",
    },
    weekDays: {
      monday: "Maandag",
      tuesday: "Dinsdag",
      wednesday: "Woensdag",
      thursday: "Donderdag",
      friday: "Vrijdag",
      saturday: "Zaterdag",
      sunday: "Zondag",
    },
    reportDateTypes: {
      dailyReport: "Dagelijks rapport",
      weeklyReport: "Wekelijks rapport",
    },
    samplingRangeDayTypes: { today: "Vandaag", yesterday: "Gisteren" },
    samplingRangeWeekTypes: { thisWeek: "Deze week", lastWeek: "Vorige week." },
    samplingRuleTypes: {
      proportion: "Proportionele bemonstering",
      random: "Willekeurige bemonstering",
    },
    taskStatusTypes: {
      waitEvaluate: "Te evalueren",
      waitSubmit: "In te dienen",
      waitConfirm: "Om te worden bevestigd",
      waitReview: "Te beoordelen",
      completed: "Voltooide",
      all: "Allemaal",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Directe beoordeling",
        weightedScore: "Gewichtsscore",
        deductScore: "Inhoudingen",
        directScoreSecondLevel: "Directe beoordeling-niveau 2",
        weightedScoreSecondLevel: "Gewichtsscore-Niveau 2",
        deductScoreSecondLevel: "Punten aftrek-Niveau 2",
      },
    },
    fieldOperators: {
      is: "Gelijk",
      not: "Niet gelijk",
      contains: "Bevat",
      notContains: "Bevat niet",
      not_contains: "Bevat niet",
      containsAny: "Bevat elke",
      contains_any: "Bevat elke",
      is_any: "Bevat elke",
      not_any: "Bevat geen",
      notContainsAny: "Bevat geen",
      not_contains_any: "Bevat geen",
      containsAll: "Bevat alle",
      contains_all: "Bevat alle",
      notContainsAll: "Bevat niet alle",
      nont_contains_all: "Bevat niet alle",
      isNull: "Leeg",
      is_null: "Leeg",
      isNotNull: "Niet leeg",
      is_not_null: "Niet leeg",
      greaterThan: "Groter",
      greater_than: "Groter",
      greaterThanEq: "Groter dan of gelijk",
      greater_than_eq: "Groter dan of gelijk",
      lessThan: "Minder",
      less_than: "Minder",
      lessThanEq: "Minder dan of gelijk",
      less_than_eq: "Minder dan of gelijk",
      between: "Tussen",
      notBetween: "Niet",
      not_between: "Niet",
      relative: "Gelijk",
      set: "Set",
      belongs: "Belong",
      notBelongs: "Hoor niet thuis",
      not_belongs: "Niet gelijk",
      today: "Vandaag",
      yesterday: "Gisteren",
      the_day_before_yesterday: "Eergisteren",
      tomorrow: "Morgen.",
      next_seven_day: "De komende zeven dagen",
      last_seven_day: "Laatste zeven dagen",
      this_week: "Deze week",
      next_week: "Volgende week",
      last_week: "Vorige week.",
      this_month: "Deze maand",
      next_month: "Volgende maand",
      last_month: "Vorige maand",
      this_year: "Dit jaar",
      next_year: "Volgend jaar",
      last_year: "Vorig jaar",
      exact_match: "Exacte match",
      add_value: "Nieuwe toegevoegde waarde",
      remove_value: "Waarde verwijderen",
      set_null: "Leeg",
      isAny: "Willekeurig is gelijk aan",
      notAny: "Willekeurig niet gelijk",
      belongTo: "Belong",
      notBelongTo: "Hoor niet thuis",
      hasAny: "Bevat elke",
      notHasAny: "Bevat geen",
      prefixContains: "Begint gelijk",
      prefixNotContains: "Begin is niet gelijk",
      suffixContains: "Einde is gelijk aan",
      suffixNotContains: "Einde is niet gelijk",
      nextSevenDay: "Volgende 7 dagen",
      lastSevenDay: "Laatste 7 dagen",
      thisWeek: "Deze week",
      nextWeek: "Volgende week",
      lastWeek: "Vorige week.",
      thisMonth: "Deze maand",
      nextMonth: "Volgende maand",
      lastMonth: "Vorige maand",
      thisYear: "Dit jaar",
      nextYear: "Volgend jaar",
      lastYear: "Vorig jaar",
      after: "Later dan (inclusief de huidige dag)",
      before: "Eerder dan (inclusief de huidige dag)",
      allMatching: "Alles overeenkomen",
      allNotMatching: "Ze komen allemaal niet overeen",
      hit: "Hit",
      not_hit: "Gemiste",
    },
    fieldsOperators: {
      is: "Gelijk",
      is_null: "Ook leeg",
      head_equal: "Begint gelijk",
      tail_equal: "Einde is gelijk aan",
      is_not_null: "Ook niet leeg",
      not: "Niet gelijk",
      diff_equal: "Het verschil is gelijk",
      diff_greater_than: "Het verschil is groter",
      diff_greater_than_eq: "Verschil groter dan of gelijk",
      diff_less_than: "Verschil is minder",
      diff_less_than_eq: "Verschil minder dan of gelijk",
      hour_equal: "Hetzelfde uur.",
      day_equal: "Dezelfde dag",
      week_equal: "Dezelfde Week",
      month_equal: "Zelfde maand",
      year_equal: "In hetzelfde jaar",
      day: "Dag",
      minute: "Minuten",
      hour: "Uren",
      part_equal: "Gedeeltelijk gelijk",
    },
    samplingColumnCaptions: {
      created_at: "Spreektijd",
      agent_id: "Klantenservice",
      get_direction: "Oproeptype",
      call_result: "Oproepresultaten",
      survey: "Evaluatie",
      derived_from_id: "Bron",
      quit_queue_why: "Status wachtrijen",
      queuing_duration: "Tijdrovende wachtrijen",
      talking_seconds: "Duur van de oproep",
      ringing_seconds: "Ring tijd",
      hangup_by: "Oproep ophangen feestje",
    },
    callTypes: {
      callIn: "Inkomende oproep",
      callOut: "Uitademen",
      threeParty: "(Tripartite)",
      consultation: "(Advies)",
      insert: "(Sterke invoeging)",
      monitor: "(Luisterend)",
      transfer: "(Overdracht)",
      intercept: "(Onderschep)",
      transferOutside: "(Overdracht externe lijn)",
      threePartyOutside: "(Tripartiete externe lijn)",
      consultingOutside: "(Raadpleeg buiten)",
    },
    callResults: {
      staffAnswer: "Antwoord van de klantenservice",
      staffNotAnswer: "Klantenservice gemist",
      customerAnswer: "Antwoord van de klant",
      customerNotAnswer: "Klant gemist",
      phoneBusy: "Telefoon bezig",
      phoneOffline: "Telefoon offline",
      customerSpeedHangUp: "Klant snel opknoping",
      customerHangUp: "Klant hangt op",
      queueTimeout: "Wachtrij Time-out",
      queueGiveUp: "Stop in de rij",
      outlineAnswer: "Buiten antwoord",
      outlineNotAnswer: "Buiten lijn niet aangesloten",
      noChooseQueue: "Geen wachtrij geselecteerd",
    },
    defaultSurveys: {
      noEvaluation: "Niet geëvalueerd",
      noNeedToEvaluate: "Niet nodig om te evalueren",
    },
    queueStates: {
      queueSuccess: "Wachtrij succes",
      queueTimeout: "Wachtrij Time-out",
      queueGiveUp: "Stop in de rij",
      noStaffOnline: "Geen klantenservice online",
    },
    callHangers: {
      customer: "Klant",
      staff: "Klantenservice",
      outline: "Buiten",
    },
    workFlowTypes: {
      noAppealsMode: "Geen klachtenmodus",
      appealsMode: "Beroepsmodus toestaan",
    },
    fieldCategories: {
      standardCondition: "Standaard filtercriteria",
      customerCondition: "Door de gebruiker gedefinieerde filtercriteria",
    },
    sampleTypes: {
      automatic: "Automatische bemonstering",
      manual: "Handmatige bemonstering",
    },
    defaultFields: {
      createdAt: "Spreektijd",
      agentId: "Klantenservice",
      getDirection: "Oproeptype",
      callResult: "Oproepresultaten",
      survey: "Evaluatie",
      derivedFrom: "Bron",
      quitQueueWhy: "Status wachtrijen",
      queuingDuration: "Tijdrovende wachtrijen",
      talkingSeconds: "Duur van de oproep",
      ringingSeconds: "Ring tijd",
      hangupBy: "Oproep ophangen feestje",
    },
    conditionFieldCategories: {
      fixedField: "Vast veld",
      customField: "Aangepaste velden",
      secondLevelScore: "Secundaire classificatie",
      formItemScore: "Score-item",
      forbids: "Verboden artikelen",
      deadlies: "Fataal artikel",
    },
    columnFieldCategories: {
      agent: "Klantenservice",
      callLog: "Oproepopname",
      businessFields: "Bedrijfsinformatie",
      customer: "Klant",
      customerCustomFields: "Klantaanpassing",
      source: "Bron",
      queue: "Wachtrij",
      fixed: "Vast",
      secondLevelScore: "Secundaire classificatie",
      formItemScore: "Score-item",
      forbids: "Verboden artikelen",
      deadlies: "Fataal artikel",
    },
    taskListDefaultFields: {
      startTime: "Spreektijd",
      name: "Klantenservice",
      state: "Status",
      totalScore: "Totaal score",
      formItemForbidList: "Verboden artikelen",
      formItemDeadlyList: "Fataal artikel",
      comment: "Opmerkingen voor evaluatie",
      inspector: "Beoordelingen",
      review: "Recensent",
    },
    evaluationStates: {
      initial: "Initialisatie",
      waitEvaluate: "Te evalueren",
      evaluated: "In te dienen",
      waitConfirm: "Om te worden bevestigd",
      waitCheck: "Te beoordelen",
      complete: "Voltooide",
    },
    workBenchInfoTypes: {
      callInfo: "Bel informatie",
      businessInfo: "Bedrijfsinformatie",
      customerInfo: "Klantinformatie",
    },
    evaluationEventStates: {
      create: "Creëer",
      valuate: "Evaluatie",
      edit: "Bewerken",
      submit: "Indiening",
      confirm: "Bevestigen",
      appeal: "Klacht",
      checkConfirm: "Herzieningsbevestiging",
      check: "Beoordeling",
    },
    formItemTypes: {
      direct: "Directe beoordeling",
      weight: "Gewicht",
      deduct: "Inhoudingen",
      forbid: "Verboden artikelen",
      deadly: "Fataal artikel",
    },
    appealCheckCallTypes: {
      callIn: "Inkomende oproep",
      callBack: "Twee-weg callback",
      callOut: "Outcall directe wijzerplaat",
      autoCall: "Automatisch uitgaand gesprek",
    },
    appealCheckCallStatus: {
      waitReview: "Te beoordelen",
      reviewed: "Beoordeeld",
      filed: "Gearchiveerd",
    },
    samplingStatus: {
      all: "Allemaal",
      unchecked: "Extractie niet gecontroleerd",
      checked: "Bemonstering",
    },
    inspectionStatus: {
      all: "Allemaal",
      unread: "Ongelezen",
      readed: "Lees",
      appeal: "Klacht",
      review: "Beoordeling",
      filed: "Gearchiveerd",
    },
    gradeChooseHits: { yes: "Ja", no: "Nee" },
    appealCheckStatus: {
      all: "Alle status",
      unread: "Ongelezen",
      inComplaint: "In klacht",
      reviewed: "Beoordeeld",
      readed: "Lees",
      filed: "Gearchiveerd",
      spotchecked: "Bemonstering",
    },
    flowStatus: {
      unread: "Ongelezen",
      alreadyRead: "Lees",
      complaining: "In klacht",
      reviewed: "Beoordeeld",
      sampling: "Extractie niet gecontroleerd",
      samplingCompleted: "Bemonstering",
      complainCancel: "Ingetrokken",
    },
    complainStatus: {
      success: "Succes",
      fail: "Mislukt",
      default: "In klacht",
      canceled: "Ingetrokken",
    },
    reviewInspectionStatus: {
      inComplaint: "In klacht",
      reviewed: "Beoordeeld",
    },
    gradeChangeTypes: {
      edit: "Bewerken",
      check: "Beoordeling",
      file: "Archivering",
      remark: "Opmerkingen",
      complain: "Klacht",
      review: "Beoordeling",
      reviewEvaluation: "Evaluatie beoordelen",
      sample: "Willekeurige inspectie",
      sampleEvaluation: "Bemonstering inspectie en evaluatie",
      sampleSubmit: "Bemonstering inspectie en indiening",
      sampleAssign: "Steekproeftoewijzing",
      reviewAssign: "Toewijzing beoordelen",
      read: "Lees",
      reviewConfirm: "Herzieningsbevestiging",
      sampleConfirm: "Bemonstering inspectie bevestiging",
      caseBase: "Toevoegen aan Case Group",
      sampleCancel: "Bemonstering Annuleringsopdracht",
      reviewCancel: "Annulering beoordeling",
      sampleAgain: "Bemonstering Herschikking",
      reviewAgain: "Herplaatsing beoordelen",
      btachDelete: "Verwijder willekeurige inspectierecords",
      rapidSampling: "Snelle bemonstering",
      reInspection: "Herinspectie",
      smartTagsChange: "Veranderingen in slimme labels",
      cancelAppeal: "Intrekking van de klacht",
    },
    appealTypes: {
      transliterationError: "Error transcriberen",
      discriminationError: "Oordeelfout",
      otherError: "Andere fouten",
    },
    inspectDataSources: {
      voiceCall: "Spraakoproep",
      textDialogue: "Tekst dialoog",
      realTimeVoiceCall: "Realtime spraakoproepen",
      realTimeTextDialogue: "Real-time tekstgesprekken",
      wechatDialogue: "Enterprise microsessie",
      taobao: "E-commerce tekstdialoog",
      ticket: "Werkorder",
      wechatRadio: "Enterprise Micro-stem",
    },
    inspectTaskType: {
      all: "Allemaal",
      common: "Algemene kwaliteitsinspectie",
      relate: "Geassocieerde kwaliteitsinspectie",
    },
    inspectApproveType: {
      all: "Allemaal",
      resolve: "Goedgekeurd",
      resolving: "In recensie",
      reject: "Audit niet geslaagd",
    },
    dataSourceFlags: {
      all: "Geen beperkingen",
      voiceCall: "Bel",
      textDialogue: "Dialoog",
    },
    smartWordsLibrary: { effective: "Effectief", invalid: "Ongeldig" },
    activeFlags: { effiective: "Effectief", invalid: "Ongeldig" },
    labelTypes: {
      dimensionLabel: "Uitroeklabels",
      classificationLabel: "Categorie label",
    },
    pointTypes: {
      automatic: "Automatische evaluatie",
      manual: "Handmatige evaluatie",
      interactiveDetection: "Interactie detectie",
      smartRules: "Slimme regels",
      machineLearning: "Zelflerende modus",
      intelligentModel: "Intelligent model",
    },
    pointGradeTypes: {
      radio: "Enkele selectie",
      input: "Invoer",
      select: "Selecteer",
      all: "Ontmoet alle",
      any: "Voldoen aan willekeurige",
      customize: "Aangepast",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Trefwoord matching",
      question: "Vragende zin",
      regular: "Regelmatige uitdrukkingen",
      context: "Context duplicatie",
      semantics: "Semantische matching",
      word: "Soortgelijke woorden",
      dialogue: "Dialooginterval",
      speed: "Spraaksnelheid detectie",
      grabDetection: "Snatch detectie",
      semanticTags: "Semantisch label",
    },
    applyRoles: {
      all: "Allemaal",
      customer: "Klant",
      agent: "Klantenservice",
      robot: "Robot",
    },
    saleApplyRoles: { all: "Allemaal", customer: "Klant", agent: "Werknemer" },
    applyOperatorScopes: {
      all: "Volledige tekst",
      preCondition: "Voorwaarde",
      scope: "Gespecificeerd bereik",
    },
    keywordScopes: {
      all: "Het detecteren van alle trefwoorden.",
      any: "Detecteer willekeurige trefwoorden",
    },
    preOperatorHitTypes: {
      first: "Eerste wedstrijd",
      every: "Elke wedstrijd",
      last: "Laatste wedstrijd",
      any: "Elke wedstrijd",
      none: "Niet gematcht",
    },
    operatorRuleTypes: {
      all: "Ontmoet alle",
      any: "Voldoen aan willekeurige",
      custom: "Aangepaste logica",
    },
    informationEntityTypes: {
      system: "Systeem",
      custom: "Aangepast",
      moduleCompany: "Module",
    },
    organizationTypes: { null: "Geen", moduleCompany: "Module" },
    customType: {
      smartTag: "Entiteiten uitpakken",
      scriptTimes: "Spraak tellen",
      dataCategory: "Gegevensclassificatie",
    },
    interactiveQuestionTypes: {
      standard: "Standaard Q & A",
      multiElement: "Multi-element vraag en antwoord",
      guideMultiRound: "Geleide multi-wiel interactie",
    },
    targetTypes: {
      all: "Allemaal",
      customerService: "Klantenservice",
      customer: "Klant",
    },
    interactiveTypes: {
      similarQList: "Soortgelijk probleem",
      exceptSimilarQList: "Elimineer soortgelijke problemen",
      similarAList: "Soortgelijke antwoorden",
      wrongAList: "Verkeerd antwoord",
    },
    filterTypes: {
      call: "Oproepopname",
      dialogue: "Dialoogtekst",
      wechat: "Enterprise WeChat",
      wechatAll: "Enterprise WeChat Alle dialogen",
      ticket: "Werkordersessie",
      taobao: "E-commerce sessie",
      wechatRadio: "Enterprise Micro-stem",
    },
    fieldTypes: {
      keyword: "Trefwoorden",
      agent: "Klantenservice",
      agentGroup: "Klantenservicegroep",
      sigleListbox: "Enkele selectie",
      multiListbox: "Multiple choice",
      text: "Enkele regel tekst",
      textArea: "Meerdere regels tekst",
      dateTime: "Tijd",
      number: "Nummers",
      voice: "",
      customer: "Klant",
      fieldWithLink: "Veld met link",
      percentage: "Percentage",
      intelligentClassification: "Intelligente classificatie",
      testSetList: "Dynamische velden testset",
      inspector: "Kwaliteitsinspecteur",
    },
    saveTypes: { save: "Bespaar" },
    knowledgeType: { oneToOne: "Eén vraag en één antwoord" },
    updateTypes: { update: "Update", saveAs: "Bespaar" },
    recommendItemActionTypes: {
      similar: "Gelijkaardig",
      exclude: "Uitsluiten",
      ignore: "Negeren",
    },
    gradeStatus: {
      unread: "Ongelezen",
      alreadyReview: "Beoordeeld",
      alreadyFiled: "Gearchiveerd",
      waitReview: "Te beoordelen",
      alreadyRead: "Lees",
    },
    qualityCheckDetailTypes: {
      review: "Beoordeling",
      spotCheck: "Willekeurige inspectie",
    },
    sampleAndReviewStatus: {
      init: "Niet geëxtraheerd",
      unread: "Ongelezen",
      alreadyRead: "Lees",
      complaining: "In klacht",
      reviewed: "Beoordeeld",
      sampling: "Extractie niet gecontroleerd",
      samplingCompleted: "Bemonstering",
    },
    interactiveAnswerTypes: {
      noAnswer: "Geen antwoord gedetecteerd",
      answer: "Antwoord gedetecteerd",
      wrongAnswer: "Onjuist antwoord gedetecteerd",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Geen problemen gedetecteerd",
      question: "Probleem gedetecteerd",
      eliminateQuestion: "Problemen oplossen gedetecteerd",
    },
    isManual: {
      yes: "Handmatige kwaliteitsinspectie",
      no: "Geen handmatige kwaliteitsinspectie",
    },
    readStatus: { yes: "Lees", no: "Ongelezen" },
    reInspectionType: {
      recalcScore: "Alleen scores opnieuw berekenen (met beoordelingen)",
      recheckPoint: "Kwaliteitsinspectiepunten en-scores opnieuw berekenen",
    },
    common: {
      eCommerceReview: "E-commerce recensie",
      socialMediaComments: "Social Media Opmerkingen",
      customer: "Klant",
      customerService: "Klantenservice",
      robot: "Robot",
      customerInformation: "Klantinformatie",
      workOrderFields: "Werkordervelden",
      intelligentConversationAnalysis: "Intelligente sessieanalyse",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Inzicht in de korte raad van zaken, luister naar de stem van klanten, verbeter marketing transformatie",
      intelligentAccompanyingPractice: "Slimme sparring",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Creëer een meeslepende AI-sparring die echte zakelijke scènes simuleert om volledige beheersing van spraakvaardigheden met gouden medailles en snelle verbetering van dialoogvaardigheden te bereiken.",
      intelligentQualityInspection: "Intelligente kwaliteitsinspectie",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Volledige kwaliteitsinspectie, tijdig en efficiënt, met een nauwkeurigheidspercentage van meer dan 85%",
      salesEmpowerment: "Verkoopempowerment",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI hervormt het verkoopbeheer, realiseert digitalisering en visualisatie van het communicatieproces, beheert transparant elke verkooplink, neemt elke stap van communicatie intelligent waar, diagnosticeert subtiel zakelijke problemen en maakt de verkoopervaring perfect opnieuw.",
      algorithmPlatform: "Algoritme platform",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Op basis van de spraaksemantiek van de zelfontwikkelde originele hartmotor, worden ASR-en NLP-modellen aangepast om bedrijfsscenariogegevens te doorlopen en zelflerend systeem te realiseren, om het doel van intelligenter en nauwkeuriger gebruik te bereiken.",
      generalSettings: "Algemene instellingen",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Gegevensmachtigingen en werknemersrollen op een uniforme manier instellen en beheren",
      callLog: "Oproepopname",
      intelligentRules: "Slimme regels",
      businessRecords: "Bedrijfsrecords",
      customFilter: {
        call: "Spraakoproep",
        text: "Tekst dialoog",
        smartTags: "Slim label",
        pretreatment: "Voorbehandeling",
        smartTagGroup: "Slimme Label Groep",
        currency: "Algemene gegevens",
        wechat: "Enterprise WeChat",
        spotCheck: "Willekeurige inspectie",
        personaField: "Klantenlijst",
        ticket: "Werkordersessie",
        taobao: "E-commerce sessie",
        wechatRadio: "Enterprise Micro-stem",
        reviewList: "Beoordeling",
      },
      talkerRecognitionType: {
        API: "API identificeert trackrol",
        text: "Tekstherkenning",
      },
      operatorTypes: {
        keyword: "Trefwoord matching",
        question: "Vragende zin",
        regular: "Regelmatige uitdrukkingen",
        context: "Context duplicatie",
        semantics: "Semantische matching",
        word: "Soortgelijke woorden",
        dialogue: "Dialooginterval",
        speed: "Spraaksnelheid detectie",
        grabDetection: "Snatch detectie",
        semanticTags: "Semantisch label",
        keywordStrict: "Trefwoord matching (strikt)",
        link: "Link veld",
        entityInfoDetection: "Informatie entiteit detectie",
        basicInfoDetection: "Basisinformatie detectie",
        volume: "Volumedetectie",
        emotion: "Sentiment analyze",
        grammar: "Grammatica regels",
        sentenceLength: "Detectie van de lengte van de zin",
        conceptWord: "Conceptwoorden",
        questionsAndAnswers: "Knowledge Base Oplossing (oud)",
        element: "Essentiële vragen en antwoorden",
        silent: "Reactie Time-out",
        associate: "Gegevens over de weg volgen",
        repeat: "Verzenden herhalen",
        timeInterval: "Intervalduur",
        typoDetection: "Typefouten detectie",
        silentOld: "Stilte",
        knowledge: "Kennisoplossing",
        wecomRemark: "Micro-opmerkingen van de klant",
        customerAddTime: "Klant Tijd toevoegen",
      },
      keywordOperators: {
        all: "Alles overeenkomen",
        allNot: "Ze komen allemaal niet overeen",
        any: "Elke wedstrijd",
      },
      applyRoles: {
        all: "Allemaal",
        customer: "Klant",
        agent: "Klantenservice",
      },
      sentenceLength: {
        less: "Minder",
        greater: "Groter",
        equal: "Gelijk",
        greater_or_equal: "Groter dan of gelijk",
        less_or_equal: "Minder dan of gelijk",
      },
      dialogueApplyScopes: {
        differentRoles: "Tussen verschillende rollen",
        agent: "Klantenservice",
        customer: "Klant",
        agentResponse: "Reactie van de klant",
        customerResponse: "Reactie van de klant",
        customerNoResponse: "Klant reageert niet",
      },
      applyConditionHitTypes: {
        first: "De eerste hit.",
        every: "Elke hit.",
        last: "Laatste hit",
      },
      applyConditionScopes: {
        current: "Huidige",
        before: "Voordat",
        after: "Na",
        beforeAll: "Allemaal eerder",
        afterAll: "Immers",
        around: "In de buurt",
      },
      voiceDemoTypes: {
        singleChannel: "Eenkanaals oproepopname",
        dualChannel: "Dubbele oproepopname",
      },
      sendStatus: {
        sending: "Verzenden",
        arrive: "Geleverd",
        fail: "Verzenden mislukt",
        off_sending: "Offline verzenden ongelezen",
        off_arrive: "Lees",
        rollback: "Ingetrokken",
      },
      collectionMethod: {
        assignment: "Directe opdracht",
        entity: "Entiteit Extractie",
      },
      systemTypes: {
        qa: "Intelligente kwaliteitsinspectie",
        wfm: "Intelligente planning",
      },
      entityOperators: {
        equals: "Gelijk aan informatie",
        notEquals: "Niet gelijk aan informatie",
        contains: "Bevat informatie",
        notContains: "Bevat geen informatie",
        equalsFix: "Gelijk aan vaste waarde",
        notEqualsFix: "Niet gelijk aan vaste waarde",
        containsFix: "Bevat vaste waarden",
        notContainsFix: "Bevat geen vaste waarde",
      },
      basicInfoOperators: {
        equals: "Gelijk",
        notEquals: "Niet gelijk",
        contains: "Bevat",
        notContains: "Bevat niet",
        isNull: "Leeg",
        isNotNull: "Niet leeg",
      },
      volumeDetectionWays: {
        loudness: "Luidheid decibel",
        rangeAbilitySelf:
          "Bereik van verandering (dan ik deed in de vorige zin)",
        rangeAbilityOth:
          "Bereik van verandering (in vergelijking met de bovenstaande zin)",
        rangeAbility3: "Bereik van verandering (dan ben ik in deze zin)",
      },
      volumeDetectionOperators: {
        is: "Gelijk",
        not: "Niet gelijk",
        greaterThan: "Groter",
        greaterThanEq: "Groter dan of gelijk",
        lessThan: "Minder",
        lessThanEq: "Minder dan of gelijk",
      },
      sentimentTypes: {
        positive: "Positieve emoties",
        negative: "Negatieve emotie",
        neutral: "Neutrale emotie",
        thankful: "Bedankt",
        happy: "Gelukkig",
        complaining: "Klacht",
        angry: "Boos",
        post: "Voorkant",
        negat: "Negatief",
        neut: "Neutraal",
      },
      emotionOperators: { is: "Naleving", not: "Niet in overeenstemming" },
      propTypes: { system: "Systeem", user: "Aangepast" },
      valueTypes: { text: "Tekst", number: "Nummers" },
    },
    tasks: {
      allowCustomGrievance: "Sta aangepaste klachten toe",
      businessSummary: "Samenvatting van het bedrijf",
      theDayBeforeYesterday: "Eergisteren",
      assessActionTypes: { reassess: "Herevaluatie" },
      scoreType: {
        aiScore: "Intelligente evaluatie",
        manualScore: "Handmatige evaluatie",
        interactiveDetection: "Interactie detectie",
        smartRules: "Slimme regels",
        machineLearning: "Zelflerende modus",
      },
      inspectType: {
        inspectTrue: "Controleren",
        inspectFalse: "Niet gecontroleerd",
      },
      operatorType: {
        keyword: "Trefwoord matching",
        notKeyword: "Trefwoord mismatch",
      },
      applyRole: {
        all: "Allemaal",
        customer: "Klant",
        agent: "Klantenservice",
      },
      applyScope: {
        all: "Allemaal",
        specific: "Gespecificeerde zin",
        scope: "Gespecificeerd bereik",
      },
      judgeStrategy: {
        all: "Voldoen aan alle voorwaarden",
        arbitrarily: "Voldoen aan elke voorwaarde",
        custom: "Aangepaste logica",
      },
      checkPointStatus: {
        waitFor: "In afwachting van beoordeling",
        notDeductScore: "Geen aftrek",
        deductScore: "Inhoudingen",
      },
      applyOptions: {
        hit: "Conditie hit",
        notHit: "Geen voorwaarden gespecificeerd",
      },
      gradeTypes: {
        yesOrNo: "Naleving",
        input: "Handmatige invoer",
        level: "Beoordeling van de kwaliteit",
        multipleFactors: "Meerdere elementen",
      },
      gradeChooseHits: { yes: "Ja", no: "Nee" },
      gradeChangeTypes: {
        edit: "Bewerken",
        check: "Beoordeling",
        file: "Archivering",
        remark: "Opmerkingen",
        complain: "Klacht",
        review: "Beoordeling",
        reviewEvaluation: "Evaluatie beoordelen",
        sample: "Willekeurige inspectie",
        sampleEvaluation: "Bemonstering inspectie en evaluatie",
        sampleSubmit: "Bemonstering inspectie en indiening",
        sampleAssign: "Steekproeftoewijzing",
        reviewAssign: "Toewijzing beoordelen",
        read: "Lees",
        reviewConfirm: "Herzieningsbevestiging",
        sampleConfirm: "Bemonstering inspectie bevestiging",
      },
      gradeStatus: {
        unread: "Ongelezen",
        alreadyReview: "Beoordeeld",
        alreadyFild: "Gearchiveerd",
        waitReview: "Te beoordelen",
        alreadyRead: "Lees",
      },
      samplingFrequencyTypes: {
        everyDay: "Dagelijks",
        everyWeek: "Wekelijks",
        everyMonth: "Maandelijks",
      },
      samplingEveryDayTimeTypes: { yesterday: "Gisteren", today: "Vandaag" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Deze week",
        lastWeek: "Vorige week.",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Deze maand",
        lastMonth: "Vorige maand",
      },
      samplingTypes: {
        random: "Willekeurige bemonstering",
        proportional: "Proportionele bemonstering",
      },
      weekdays: {
        Monday: "Maandag",
        Tuesday: "Dinsdag",
        Wednesday: "Woensdag",
        Thursday: "Donderdag",
        Friday: "Vrijdag",
        Saturday: "Zaterdag",
        Sunday: "Zondag",
      },
      distributeTypes: {
        rule: "Regel prioriteitstoewijzing",
        average: "Gemiddelde willekeurige verdeling",
      },
      notMatchAnyRuleTypes: {
        random: "Willekeurige gemiddelde verdeling",
        assign: "Toewijzen aan aangewezen personen",
        ignore: "Niet toegewezen",
      },
      inspectorTypes: {
        all: "Alle kwaliteitsinspecteurs",
        some: "Aangewezen kwaliteitsinspecteur",
      },
      pushFrequencyTypes: {
        everyDay: "Dagelijks",
        everyWeek: "Wekelijks",
        everyMonth: "Maandelijks",
        inspectionUpdate: "Update kwaliteitsinspectie",
        timelyInspection: "Real-time kwaliteitsinspectie",
      },
      pushObjectTypes: { email: "Mailbox opgeven", staff: "Klantenservice" },
      reviewOptions: {
        reviewResult:
          "Weergave kwaliteitsinspectieresultaten tijdens beoordeling",
        reviewRecord: "Relevante records weergeven tijdens de beoordeling",
        reviewAgentHidden: "Verberg agentinformatie tijdens beoordeling",
        samplingResult:
          "Weergave kwaliteitsinspectieresultaten tijdens willekeurige inspectie",
        samplingRecord:
          "Toon relevante gegevens tijdens willekeurige inspectie",
        sampleAgentHidden:
          "Verberg informatie over de klantenservice tijdens willekeurige inspectie",
        checkExportAddCallInfoIphone:
          "Voeg oproeplogboeken toe bij het exporteren van een lijst",
        sampleExportAddCallInfoIphone:
          "Voeg oproeplogboeken toe bij het exporteren van een lijst",
        checkExportAddCallInfoText:
          "Besprekingrecords bijvoegen bij het exporteren van een lijst",
        sampleExportAddCallInfoText:
          "Besprekingrecords bijvoegen bij het exporteren van een lijst",
        inspectionExportAddCallInfoIphone:
          "Voeg oproeprecords toe bij het exporteren van een lijst (tot 10000 kunnen tegelijkertijd worden geëxporteerd)",
        inspectionExportAddCallInfoText:
          "Voeg conversatierecords bij bij het exporteren van een lijst (tot 10000 kunnen tegelijkertijd worden geëxporteerd)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Sta snelle controle ter plaatse toe in kwaliteitsdetails",
        fastSampleInSampleDetail:
          "Sta snelle uitvoering van willekeurige inspectie toe in willekeurige inspectiedetails",
        fastSampleInReviewDetail:
          "Maakt een snelle uitvoering van steekproeven in beoordelingsdetails mogelijk",
      },
      permitCustomReasonList: {
        permitCustomReason: "Sta aangepaste redenen toe voor claims",
      },
      autoMateTasks: {
        autoLoad: "Automatisch toevoegen",
        sample: "Automatische bemonsteringsinspectie",
        assign: "Automatische toewijzing",
        pushes: "Automatische duw",
        assess: "Automatische beoordeling",
        check: "Klachtenoverzicht",
        case: "Case bibliotheek synchronisatie",
      },
      qualityCheckDetailTypes: {
        review: "Beoordeling",
        spotCheck: "Willekeurige inspectie",
      },
      appealTypes: {
        transliterationError: "Error transcriberen",
        discriminationError: "Oordeelfout",
        otherError: "Andere fouten",
      },
      inspectDataSources: {
        voiceCall: "Spraakoproep",
        textDialogue: "Tekst dialoog",
        realTimeVoiceCall: "Realtime spraakoproepen",
        realTimeTextDialogue: "Real-time tekstgesprekken",
      },
      pointTypes: {
        automatic: "Automatische evaluatie",
        manual: "Handmatige evaluatie",
        interactiveDetection: "Interactie detectie",
      },
      pointGradeTypes: {
        radio: "Enkele selectie",
        input: "Invoer",
        select: "Selecteer",
      },
      sampleAndReviewStatus: {
        init: "Niet geëxtraheerd",
        unread: "Ongelezen",
        alreadyRead: "Lees",
        complaining: "In klacht",
        reviewed: "Beoordeeld",
        sampling: "Extractie niet gecontroleerd",
        samplingCompleted: "Bemonstering",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Negeer overtollige overloop",
        automaticWeightCalculation: "Automatische gewichtsboekhouding",
      },
      calculateScoreTypes: { add: "Bonuspunten", sub: "Min punten" },
      naChecked: {
        check: "Dit item is niet betrokken bij de toegestane detectie",
      },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Percentage punten",
        allRight: "Alle juiste punten",
        anyRight: "Elke juiste score",
      },
      noHitQuestionResults: {
        true: 'Het resultaat is "Ja"',
        false: 'Resultaat is "Nee"',
      },
      repetRules: {
        same: "Inhoud precies hetzelfde",
        similar: "Semantische gelijkenis",
      },
      relateConditions: {
        highlightQuestion: "Hoogtepunt probleem",
        highlightAnswer: "Markeer antwoord",
        hightlightFoctors: "Hoogtepunt Element",
      },
      interactiveQuestionTypes: {
        question: "Probleem gedetecteerd",
        eliminateQuestion: "Problemen oplossen gedetecteerd",
      },
      interactiveAnswerTypes: {
        noAnswer: "Geen antwoord gedetecteerd",
        answer: "Antwoord gedetecteerd",
        wrongAnswer: "Onjuist antwoord gedetecteerd",
      },
      inspectionUpdateTypes: {
        autoInspection: "Automatische kwaliteitsinspectie",
        autoInspectionWechat: "Automatische analyze",
        artificialSampling: "Handmatige bemonstering",
        artificialReview: "Handmatige beoordeling",
        manualModification: "Snelle bemonstering",
        timelyInspection: "Real-time kwaliteitsinspectie",
        samplingAssign: "Steekproeftoewijzing",
        artificialComplain: "Indiening van Grievance",
        fastSampling: "Snelle bemonstering inspectie en indiening",
        recheckInspection: "Herinspectie",
        recheckInspectionWechat: "Heranalyse",
      },
      timelyInspectionTypes: {
        timelyInspection: "Real-time kwaliteitsinspectie",
      },
      pushActionTypes: { email: "Mail", interfacePush: "Interface Push" },
      pushDetailWays: {
        riskAlert: "Risicowaarschuwing",
        subscribe: "Abonneren op berichten",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Bel-/gespreksbericht",
        inspectionResults: "Resultaten kwaliteitsinspectie",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Bel-/gespreksbericht",
        textContent: "Tekstinhoud van oproepen/gesprekken",
        inspectionResults: "Resultaten kwaliteitsinspectie",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Gemiddelde score binnen groep",
        addOrSubtract: "Directe plus-of minpunten",
        noChange: "Geen plus-of minpunten",
      },
      defaultScore: {
        empty: "Leeg",
        add: "Bonuspunten",
        notAdd: "Geen extra punten",
        sub: "Min punten",
        notSub: "Geen punten verminderd",
        na: "N/A",
        input: "Handmatige invoer",
        choose: "Selecteer",
      },
      strategyTypes: {
        hitAdd: "Hit Bonus",
        hitNotAdd: "Geen punten om te slaan",
        hitSub: "Raak min punten",
        hitNotSub: "Hit vermindert geen punten",
      },
    },
    download: {
      exportStatus: {
        prepare: "In voorbereiding",
        process: "In uitvoering",
        faild: "Export mislukt",
        success: "Succes",
      },
    },
    messageType: {
      all: "Allemaal",
      monitor: "Onmiddellijke monitoring",
      trigger: "Aanmelding trigger",
      timing: "Timingsrapport",
    },
    messagePushType: {
      all: "Allemaal",
      system: "Systeemberichten",
      sdk: "Bericht SDK",
      push: "Interface Push",
      email: "Mail",
      udesk: "Udesk meldingen",
      udeskWhite: "Melding van het klantenservicesysteem",
      weChat: "Enterprise microbericht",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Bemonsteringsinspecteur toewijzen",
      sampleInspector: "Bemonsteringsinspecteur",
      assignReviewer: "Reviewer toewijzen",
      reviewer: "Recensent",
      taskInspector: "Taakinspecteur",
    },
    sdkPushType: {
      webHook: "Standaard webhook",
      dingDing: "Spijkerrobot",
      weChat: "Enterprise WeChat Robot",
      customer: "Aangepaste Json-inhoud",
    },
    inspectionRemarksType: {
      inspection: "Kwaliteitsinspectie",
      check: "Willekeurige inspectie",
      review: "Beoordeling",
    },
    segmentationStrategyType: {
      count: "Aantal berichten",
      spacing: "Berichtinterval",
    },
    segmentationStrategy: {
      byTime: "Timing segmentatie",
      customize: "Combinatie snijden",
    },
    inspectType: {
      commonInspect: "Algemene kwaliteitsinspectie",
      associateInspect: "Geassocieerde kwaliteitsinspectie",
    },
    typoDetectionOperators: { less: "Minder", greater: "Groter" },
    autoAddTypes: {
      realTimeAdd: "Voeg in real time toe",
      timingAdd: "Timing Toevoegen",
    },
    messageTypes: { text: "Tekstbericht", markdown: "Markdown-bericht" },
    inServiceStatus: {
      activated: "Geactiveerd",
      disabled: "Uitgeschakeld",
      inactivated: "Niet geactiveerd",
      resigned: "Uitgang van de onderneming",
    },
    roles: { agent: "Werknemer", customer: "Klant" },
    commentVisibleAuthority: {
      all: "Zichtbaar voor iedereen",
      self: "Alleen zichtbaar voor zichzelf",
      designation: "Aangewezen persoon zichtbaar",
    },
    appealCheckingTask: {
      eCommerceReview: "E-commerce recensie",
      socialMediaComments: "Social Media Opmerkingen",
      largeModel: "Groot model",
      customModel: "Aangepast model",
      universalModel: "Generiek model",
      enterpriseWeChatText: "Enterprise micro-tekst",
      generalQualityInspection: "Algemene kwaliteitsinspectie",
      processQualityInspection: "Inspectie van proceskwaliteit",
      voiceCopy: "Stem dubbele schijf",
      beEqualTo: "Gelijk",
      notEqualTo: "Niet gelijk",
      canAppeal: "Kan in beroep gaan",
      noAppeal: "Niet-klacht",
    },
    wechat: {
      redEnvelopes: "Rode envelop",
      text: "Tekst",
      picture: "Afbeelding",
      voice: "Stem",
      voiceCall: "Spraakoproep",
      video: "Video",
      businessCard: "Visitekaartje",
      position: "Locatie",
      expression: "Uitdrukking",
      link: "Link",
      applet: "Klein programma",
      chatRecord: "Chat record",
      mixedMessage: "Gemengd bericht",
      file: "Bestand",
    },
    gong: {
      sessionActivity: "Sessie Activiteit",
      conversationAction: "Sessie Actie",
      riskSemantics: "Risico semantiek",
      daysNotFollowedUp: "Dagen niet gevolgd",
      waysideData: "Gegevens over de weg volgen",
      notStarted: "Niet begonnen",
      completed: "Voltooide",
      haveInHand: "In uitvoering",
      unpublished: "Niet gepubliceerd",
      published: "Gepubliceerd",
      knowledgePoints: "Kennispunten",
      technologicalProcess: "Proces",
      notMarked: "Niet gelabeld",
      inAnnotation: "In callout",
      annotated: "Gelabeld",
    },
    qualityChecingTask: {
      appealCanceled: "Beroep geannuleerd",
      complaintExpired: "Klacht verlopen",
      incomingCall: "Inkomende oproep",
      exhale: "Uitademen",
      incomingCallthirdParty: "Inkomende oproep (drieweg)",
      incomingCallconsultation: "Inkomende oproep (advies)",
      incomingCallforcedInsertion: "Inkomende oproep (sterk)",
      incomingCallmonitoring: "Inkomende oproep (luisteren)",
      incomingCalltransfer: "Inkomende oproep (overschrijving)",
      incomingCallinterception: "Inkomende oproep (onderscheppen)",
      incomingCalltransferToExternalLine:
        "Inkomende oproep (overdracht buiten lijn)",
      incomingCallthirdPartyExternalLine:
        "Inkomende oproep (drieweg buitenlijn)",
      incomingCallexternalLine: "Inkomende oproep (advies buiten de lijn)",
      outgoingCallthirdParty: "Uitgaand (drieweg)",
      outgoingCalltransfer: "Uitkomst (overdracht)",
      outgoingCallforcedInsertion: "Uitgaand (sterk)",
      outgoingCallmonitoring: "Uitgaand (luisteren)",
      outgoingCallinterception: "Uitgaande oproep (onderscheppen)",
      outgoingCalltransferToExternalLine:
        "Uitgaand (overdracht buiten de lijn)",
      outgoingCallthreePartyExternalLine:
        "Uitgaande oproep (drieweg buitenlijn)",
      customerServiceAnswer: "Antwoord van de klantenservice",
      customerServiceMissed: "Klantenservice gemist",
      customerAnswer: "Antwoord van de klant",
      customerMissed: "Klant gemist",
      theTelephoneIsBusy: "Telefoon bezig",
      phoneOffline: "Telefoon offline",
      customerQuickHanging: "Klant snel opknoping",
      clientHangsUp: "Klant hangt op",
      queueTimeout: "Wachtrij Time-out",
      giveUpQueuing: "Stop in de rij",
      outsideLineAnswering: "Buiten antwoord",
      externalLineIsNotConnected: "Buiten lijn niet aangesloten",
      noQueueSelected: "Geen wachtrij geselecteerd",
      notEvaluated: "Niet geëvalueerd",
      noEvaluationRequired: "Niet nodig om te evalueren",
      queuingSucceeded: "Wachtrij succes",
      noCustomerServiceOnline: "Geen klantenservice online",
      customer: "Klant",
      customerService: "Klantenservice",
      outsideLines: "Buiten",
    },
    sessionActivity: {
      single: "Gemiddelde tijd voor één gesprek",
      all: "Totaal aantal oproepen",
      allTime: "Totale duur van de oproep",
    },
    sessionAction: {
      salesProportion: "Verkoopspreekverhouding",
      salesLongest: "Gemiddeld langste verkoopverklaring",
      customerLongest: "Gemiddelde langste klant spreken",
      discussNumber: "Aantal diepgaande discussies",
      frequency: "Vraagfrequentie",
    },
    monitorCriteria: { all: "Voor en na", before: "Voordat", after: "Na" },
    monitorTimeCycle: { day: "Dag", week: "Week", month: "Maand" },
    monitorGroupTestType: {
      ab: "Vergelijking van de twee groepen",
      a: "Groep A",
      b: "Groep B",
    },
    fields: {
      department: "Afdeling",
      customerTags: "Klantenlabel",
      member: "Leden",
      time: "Tijd",
      cascade: "Cascade",
      anyMatch: "Elke wedstrijd",
      keyWord: "Trefwoorden",
      keyEvents: "Belangrijkste evenementen",
    },
    semanticIntelligence: {
      staff: "Werknemer",
      customer: "Klant",
      employeesAndCustomers: "Werknemers en Klanten",
      notStarted: "Niet begonnen",
      haveInHand: "In uitvoering",
      completed: "Voltooide",
      callRecording: "Oproepopname",
      enterpriseWeChatCall: "Enterprise Micro-Talk",
      enterpriseWeChatText: "Enterprise micro-tekst",
      discourseMiningForObjectionHandling: "Bezwaar Omgaan met spraakmijnbouw",
      productSellingPointScriptsMining:
        "Productverkoopargumenten praten over mijnbouw",
      conversationScenario: "Sessiescenario's",
      topicOfConversation: "Onderwerp",
      keyEvents: "Belangrijkste evenementen",
      customerLabel: "Klantenlabel",
      includeAny: "Bevat elke",
      includeAll: "Bevat alle",
      and: "En",
      or: "Of",
      question: "Vragende zin",
      nonInterrogativeSentence: "Niet-vragende zin",
    },
    coach: {
      notPassed: "Niet geslaagd",
      analogTelephone: "Analoge telefoon",
      enterScenarioInformation: "Scenariogegevens invoeren",
      generateDialogueFramework: "Een dialoogkader genereren",
      generatePromptLanguage: "Prompt gegenereerd",
      aIGeneratedDialogue: "Door AI gegenereerd dialoogvenster",
      system: "Systeem",
      custom: "Aangepast",
      flow: "Proces",
      script: "Woorden",
      express: "Uitdrukking",
      emotion: "Emotie",
      rateAccordingToCourseConfiguration: "Scoor op cursusconfiguratie",
      intelligentModelRating: "Slimme modelscores",
      allData: "Alle gegevens",
      dataFromThisDepartment: "Gegevens van de afdeling",
      multidepartmentalData: "Gegevens uit meerdere sectoren",
      underReview: "In recensie",
      no: "Nee",
      yes: "Ja",
      automaticRating: "Automatische score",
      auditRating: "Auditscore",
      reRating: "Opnieuw scoren",
      inAppeal: "In klacht",
      reviewed: "Beoordeeld",
      notViewed: "Niet bekeken",
      viewed: "Bekeken",
      uncorrected: "Niet gecorrigeerd",
      corrected: "Gecorrigeerd",
      practice: "Oefening",
      examination: "Onderzoek",
      other: "Overige",
      notMeetingStandards: "Niet aan de norm",
      meetingStandards: "Standaard",
      excellent: "Uitstekend",
      invalid: "Falen",
      notStarted: "Niet begonnen",
      haveInHand: "In uitvoering",
      ended: "Eindigde",
      completed: "Voltooide",
      hangInTheAir: "Onvoltooid",
      passed: "Overgegaan",
      voiceConversation: "Spraakdialoog",
      textDialogue: "Tekst dialoog",
      slidePresentation: "Dia presentatie",
      unpublished: "Niet gepubliceerd",
      published: "Gepubliceerd",
      narration: "Verteller",
      studentSpeaking: "Cadet spreken",
      robotTalk: "Robots praten.",
      knowledgeBaseQA: "Kennisbasis Q & A",
      slide: "Glijbaan",
      negativeEmotions: "Negatieve emotie",
      emotionallyNormal: "Normale stemming",
      incompleteScript: "Onvolledige spraak",
      completeScript: "Volledige spraak",
      normalSpeechSpeed: "SNELHEID NORMAAL",
      speakTooFast: "Te snel snel snel snel snel",
      speakTooSlowly: "SNELHEID LANGZAAM",
      whole: "Allemaal",
      singlePage: "Enkele pagina",
      qA: "Eén vraag en één antwoord",
      situationalDialogue: "Situationele dialoog",
      misses: "Gemiste",
      hit: "Hit",
      sequentialQA: "Sequentiële Q & A",
      randomQA: "Willekeurige vragen",
      mastered: "Mastering",
      notMastered: "Niet onder de knie",
    },
    algorithm: {
      salesEmpowerment: "Verkoopempowerment",
      enterpriseWeChatVoice: "Enterprise WeChat Voice",
      enterpriseWeChatText: "Enterprise WeChat-tekst",
      waiting: "Wachten",
      extracting: "In extractie",
      success: "Succes",
      fail: "Mislukt",
      intelligentQualityInspection: "Intelligente kwaliteitsinspectie",
      textDialogue: "Tekst dialoog",
      voiceCall: "Spraakoproep",
      smartTags: "Slim label",
      satisfactionEvaluation: "Tevredenheid evaluatie",
      score: "Beoordeling",
      grade: "Beoordeling",
      customerServiceTeam: "Klantenservicegroep",
      conversationAnalysis: "Gespreksanalyse",
    },
    operatorListGather: {
      businessSummary: "Samenvatting van het bedrijf",
      firstSentence: "Eerste zin",
      lastSentence: "Laatste zin",
      allTime: "Altijd",
      nonBusinessWorkingHours: "Niet-kantooruren",
      businessWorkingHours: "Zakelijke werktijden",
    },
    callDialogueManage: {
      eCommerceReview: "E-commerce recensie",
      socialMediaComments: "Social Media Opmerkingen",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Inloggen",
        email: "Mailbox",
        password: "Wachtwoord",
        forgetPwd: "Wachtwoord vergeten",
        emailCannotBeEmpty: "De brievenbus mag niet leeg zijn.",
        passwordCannotByEmpty: "Het wachtwoord mag niet leeg zijn.",
        mustChangeLogin:
          "Sorry, uw wachtwoord moet worden gereset. Neem contact op met uw beheerder om uw wachtwoord opnieuw in te stellen.",
        dismissionLogin: "Sorry, je hebt je baan verlaten.",
        forbiddenLogin: "Sorry, uw account is uitgeschakeld.",
        loginError: "Inloggen is mislukt, mailbox-of wachtwoordfout.",
        loginPrompt: "Login op het systeem",
        rememberMe: "Onthoud mij",
      },
      logout: {
        logoutError:
          "Afmelden is mislukt. Controleer uw netwerkverbinding of probeer het later opnieuw.",
      },
      resetPassword: {
        forgetPwd: "Wachtwoord vergeten",
        inputEmailPrompt:
          "Voer hieronder het e-mailadres van uw account in en we sturen u een e-mail en instructies om uw wachtwoord opnieuw in te stellen.",
        sendEmailSuccess:
          "De bevestigingsbrief voor wachtwoordwijziging is met succes verzonden naar de e-mail die u hebt ingevuld, controleer deze op tijd.",
        sendEmailFailed:
          "De brievenbus is niet verzonden. Controleer uw netwerkverbinding of probeer het later opnieuw.",
        setNewPassword: "Stel je nieuwe wachtwoord in",
        passwordCannotEmpty: "Wachtwoord mag niet leeg zijn",
        passwordFormWrong: "Onjuiste wachtwoordindeling",
        confirmPasswordWrong:
          "De twee ingevoerde wachtwoorden zijn niet gelijk.",
        passwordResetSuccess: "Wachtwoord reset geslaagd.",
        passwordResetFailed:
          "Wachtwoord resetten mislukt. Controleer uw netwerkverbinding of probeer het later opnieuw.",
        linkTimeover: "Reset wachtwoord link verlopen, probeer het opnieuw",
      },
      form: { title: "CASE-vormweergave" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Mailbox",
          emailFormatError:
            "Mailbox formaat fout, gelieve opnieuw in te voeren!",
          forgotPassword: "Ben je je wachtwoord vergeten?",
          describe:
            "Voer hieronder het e-mailadres van uw account in en we sturen u een e-mail en instructies om uw wachtwoord opnieuw in te stellen.",
          send: "Verzenden",
          emailCannotBeEmpty: "Mailbox kan niet leeg zijn",
          resetPasswordExpire:
            "Reset wachtwoord link verlopen, probeer het opnieuw",
        },
        resetPassword: {
          setPassword: "Stel je nieuwe wachtwoord in",
          newPassword: "Nieuw wachtwoord",
          repeatPassword: "Duplicaat wachtwoord",
          resetPassword: "Wachtwoord resetten",
          passwordCannotBeEmpty: "Het nieuwe wachtwoord kan niet leeg zijn.",
          confirmPasswordCannotBeEmpty:
            "Duplicaat wachtwoord kan niet leeg zijn.",
          passwordsNotMatch: "Twee wachtwoorden zijn inconsistent!",
          passwordFormWrong:
            "Het wachtwoord kan niet in het Chinees zijn en het nummer is 6-14!",
          linkExpiration: "Reset wachtwoord link verlopen, probeer het opnieuw",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "U hebt geen toestemming om deze pagina te bekijken",
      },
    },
    downloadCenter: {
      name: "Downloadcentrum",
      saveTime: "(30 dagen standaard)",
      headerTitle: "Bestand downloaden",
      download: "Download",
      downloadPromp: "De downloadtaak is ingediend, ga alstublieft",
      viewProgress: "Voortgang bekijken",
    },
    uploadCenter: { name: "Centrum uploaden" },
    tasks: {
      index: {
        name: "Naam",
        remark: "Beschrijving",
        qualityCheckTasksTitle: "Werkbank",
        informationCollection: "Verzameltaken",
        qualityCheckTasks: "Kwaliteitsinspectietaak",
        wechatQaTasks: "Analyse taak",
        moduleName: "Gebruik sjabloon nieuw",
        creatNewQualityCheckTask: "Nieuwe taak",
        generalInspectionList: "Algemeen kwaliteitsinspectierapport",
        editQualityCheckTask: "Kwaliteitsinspectietaak bewerken",
        creatNewQualityCheckTaskError:
          "Het maken van een nieuwe kwaliteitsinspectietaak is mislukt.",
        editQualityCheckTaskError:
          "Het bewerken van de kwaliteitsinspectietaak is mislukt.",
        type: "Formuliertype",
        singleScore: "Enkele Score",
        singleScoreNullError: "Enkele score kan niet leeg zijn",
        inspector: "Kwaliteitsinspecteur",
        inspectDataSource: "Gegevensbron",
        taskName: "Naam van de taak",
        inspectionType: "Type kwaliteitsinspectie",
      },
      manage: {
        screeningCondition: "Filterconditie",
        template: {
          condition: {
            index: {
              name: "Voorwaarde",
              existingConditions: "Bestaande voorwaarden",
            },
            detail: {
              id: "ID",
              name: "Voorwaarde naam",
              remark: "Beschrijving",
              checkRange: "Detectiebereik",
              operator: "Exploitant",
              operatorLogic: "Operator logica",
            },
          },
          checkPointTest: {
            title: "Regeltest",
            running: "Regels in de test, alstublieft later...",
            clearSuccess: "Gesimuleerde dialoog is met succes geleegd",
            mock: "Gesimuleerde dialoog",
            save: "Het geselecteerde dialoogvenster opslaan",
            saveAll: "Bespaar alle gesprekken",
            saveSuccess: "Gesprek succesvol opgeslagen",
            close: "Uitgang",
            delete: "Geselecteerd verwijderen",
            clear: "Leeg",
            customer: "Klant",
            agent: "Klantenservice",
            test: "Test",
            request:
              "Nadat het simulatiedialoogvenster is gewist, kan het niet worden hersteld. Bevestig om het te wissen?",
            deletePopconfirmContent:
              "Weet u zeker dat u het geselecteerde dialoogvenster wilt verwijderen?",
          },
          checkPoint: {
            index: {
              name: "Regelconfiguratie",
              wechatTitle: "Analyse Modelconfiguratie",
              existingCheckPoints: "Bestaande kwaliteitsinspectieregels",
              currentCheckPointScore:
                "De huidige score van de kwaliteitsinspectieregel is",
              currentCheckPointScoreWechat:
                "De huidige score van het analysemodel is",
              currentCheckPointWeight:
                "Het totale gewicht van de huidige kwaliteitsinspectieregel is",
              weightTotalScoreAndItemScore: "Totale score: 100, enkele score:",
              template: "Kwaliteitsinspectie regel sjabloon",
              import: "Importeer kwaliteitsinspectie regel sjabloon",
              getTemplateError:
                "Het verkrijgen van de sjabloon voor de kwaliteitskeuringsregel is mislukt.",
              tabs: { template: "Sjabloon", demo: "Sample" },
              customize: "Lege sjabloon",
              move: "Sleep en zet neer om classificatie en sortering aan te passen",
              classification: "Kwaliteitsinspectie classificatie",
              classificationWeChat: "Modelclassificatie",
              first: "Niveau 1 classificatie",
              second: "Secundaire classificatie",
              new: "Nieuwe Kwaliteitsinspectieregel",
              weChatNew: "Analysemodel toevoegen",
              createWeChat: "Nieuw analysemodel",
              empty: "Er zijn voorlopig geen kwaliteitsinspectieregels,",
              wechatEmpty: "Geen analysemodel,",
              newSecond: "Nieuwe secundaire classificatie",
              newFirst: "Nieuwe classificatie op het eerste niveau",
              checkPoint: "Regels voor kwaliteitsinspectie",
              checkPointWeChat: "Analyze model",
            },
            detail: {
              name: "Naam",
              remark: "Beschrijving",
              type: "Soort",
              deduction: "Inhoudingen",
              rule: "Regels",
              highlighted: "Hoogtepunt",
              gradeType: "Scoremethode",
              interactiveCategory: "Interactieclassificatie",
              predeterminedScore: "Score",
              weight: "Gewicht",
              na: "N/A",
              default: "Standaardscore",
              preRule: "Voorwaarde regel",
              analysisHighlighted: "Ontleedregels",
              categorySelectPrompt: "Selecteer een categorie",
              checkHighlightArrayError:
                "De regel komt niet overeen met de highlight, klik nogmaals op de knop Regel ontleden.",
              foctorsHolder: "Selecteer een element",
              foctorsErrorNone:
                "De regel met meerdere elementen is leeg. Voeg een type met meerdere elementen toe.",
              naError: "Kan item niet sluiten wanneer de standaardscore N/A is",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A is de niet van toepassing, die voorwaarden stelt voor de kwaliteitsinspectieregel. Als aan de voorwaarde is voldaan, is de regel niet van toepassing. Als niet aan de voorwaarde wordt voldaan, wordt de regel niet gedetecteerd;\nBeschrijving van randvoorwaarden: "&&" betekent "en", "||" betekent "of", "!" Geeft "NIET" aan, vul het voorbeeld in "(R1 || R2) && R3"',
                applicationMessageType: "Type toepassingsbericht",
                selectAll: "Alles selecteren",
                highlightTags: "Hoogtepunt:",
                ruleType: "Regeltype",
                dataSource: "Gegevensbron",
                whole: "Allemaal",
                enterpriseMicroConversation: "Enterprise microsessie",
                enterpriseAndMicroCommunication: "Enterprise Micro-Talk",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Oordeelknooppunt",
                    dialogueNode: "Dialoogknooppunt",
                    editNodes: "Knooppunt bewerken",
                  },
                  nodeConfig: {
                    nodeName: "Knooppuntnaam",
                    nodeRules: "Knooppuntregels",
                    scoringLogic: "Logica scoren",
                    score: "Score:",
                    rule: "Regels",
                    multiBranchLogic: "Multi-branch logica",
                    highlight: "Hoogtepunt",
                    submit: "Indiening",
                  },
                  ruleList: {
                    branch: "Tak",
                    branchName: "Naam van de tak:",
                    addRules: "Regel toevoegen",
                  },
                  sidebar: { node: "Knooppunt" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Automatische afwerking",
                      canvasAdaptation: "Canvas adaptief",
                      copy: "Kopiëren",
                      delete: "Verwijderen",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Het kinderknooppunt van het oordeelknooppunt kan niet als verbindingsdoel worden gebruikt.",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Het startknooppunt kan geen verbindingsdoel zijn",
                      startingNodeCannotConnectMultipleNodes:
                        "Startknooppunt kan geen verbinding maken met meerdere knooppunten",
                      nodeCannotBeConnectedToItself:
                        "Knooppunt kan geen verbinding maken met zichzelf",
                      startNode: "Start knooppunt",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Alles selecteren annuleren",
                    selectAll: "Alles selecteren",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Naleving",
                    gradedEvaluation: "Beoordeling van de kwaliteit",
                  },
                },
              },
            },
            configuration: {
              title: "Regels voor kwaliteitsinspectie",
              wechatTitle: "Analyze model",
              neme: "Naam",
              errorMessage:
                "Verwijderen is mislukt met ten minste één voorwaarde",
              desc: "Beschrijving",
              status: "Status",
              yes: "Effectief",
              no: "Ongeldig",
              namePlaceHolder: "Voer een labelnaam in",
              desPlaceHolder: "Voer een beschrijving in",
              nameEmpty: "Naam kan niet leeg zijn",
              desEmpty: "Beschrijving kan niet leeg zijn",
              type: "Soort",
              ruler: "Slimme regels",
              machineLearning: "Zelflerende modus",
              autoScore: "Slimme score (oud)",
              manualScore: "Handmatige Scoring",
              interaction: "Interactiedetectie (oud)",
              typeTips: {
                model:
                  'Intelligent model: het is een vooraf ingesteld kwaliteitsinspectiesysteem, dat de bestaande problemen in de dienst zonder configuratie en onderhoud snel kan controleren. Dit model is algemener, als u niet voldoet aan de zakelijke vereisten, gebruik dan "intelligente regels" of "handmatige score".',
                rule: "Intelligente regels: automatisch scoren op basis van regel AI",
                manual:
                  "Handmatige score: handmatige kwaliteitsinspectie, handmatige scoring met de hand",
              },
              model: "Model:",
              ruleType: {
                words: "Woorden",
                interaction: "Interactie",
                feature: "Kenmerken",
                information: "Informatie",
              },
              testingMode: {
                all: "Het detecteren van alle trefwoorden.",
                any: "Detecteer willekeurige trefwoorden",
              },
              operatorTypesOfInteraction: {
                question: "Eén vraag en één antwoord",
                element: "Elementen beantwoorden",
                repet: "Context duplicatie",
                snatch: "Pak woorden",
                silent: "Stilte",
              },
              role: "Detectie rol:",
              preposition: "Pre-operator:",
              range: "Detectiebereik:",
              from: "Nee.",
              to: "Zin tot nr.",
              end: "Zin",
              hasOldSilentTip:
                'Hallo, om de nauwkeurigheid te verbeteren, is "Intelligente regels-Interactie-Stilte" geüpgraded naar "Response Timeout". Het wordt aanbevolen om de gebruiksregels bij te werken!',
              changeToOld: "Schakel over naar Legacy",
              changeToNew: "Schakel over naar nieuwe versie",
              logic: {
                logic: "Logica scoren",
                naTip:
                  "N/A is de niet van toepassing, die voorwaarden stelt voor de kwaliteitsinspectieregel. Als aan de voorwaarden is voldaan, is de regel niet van toepassing. Als niet aan de voorwaarden wordt voldaan, wordt de regel niet gedetecteerd.",
                score: "Score:",
                na: "N/A：",
                naDes: "Dit item is niet betrokken bij de toegestane detectie",
                preRule: "Voorwaarde regels:",
                notCatchPreRule:
                  "Wanneer niet aan de randvoorwaarden is voldaan:",
                naChecked: "Bij het raken van N/A:",
                preRuleEmpty: "Preconditieregel kan niet leeg zijn",
                operatorEmpty:
                  "Noodzakelijke voorwaarde kan niet null zijn in operator",
                multipleEmpty:
                  "Noodzakelijke voorwaarde kan niet leeg zijn in meerdere logische score",
                average: "Gemiddelde score binnen groep",
                addOrSubtract: "Directe plus-of minpunten",
                noChange: "Geen plus-of minpunten",
                rule: "Regels:",
                all: "Voldoen aan alle operators",
                any: "Voldoen aan willekeurige operator",
                customize: "Aangepaste operatorlogica",
                multiple: "Meerdere logische score",
                heightLight: "Hoogtepunt:",
                machineLearningTip:
                  "Momenteel is de zelflerende modus alleen effectief voor spraak met één zin. Gebruik voor complexe spraak intelligente regels.",
                strategy: "Strategie:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Goedkeuring met succes ingediend",
            },
            components: {
              pageHeaderExtra: {
                test: "Test",
                formal: "Formeel",
                submit: "Indiening",
              },
              createModal: { index: { myRules: "Mijn regels" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Procesautomatisering",
              screeningCondition: "Filterconditie",
              aiScreeningCondition: "Intelligente filtertoestand",
              rule: "Regels",
              automaticAddition: "Automatisch toevoegen",
              automaticAdditionTip:
                "Voeg automatisch gegevens toe aan taken in realtime of met regelmatige tussenpozen voor KI-kwaliteitsinspectie",
              autoAdd: {
                autoAddType: "Methode voor automatisch toevoegen",
                addFrequency: "Frequentie toevoegen",
                add: "Toevoegen",
              },
              automaticSamplingInspection:
                "Automatische bemonsteringsinspectie",
              automaticSamplingInspectionTip:
                "Extraheer automatisch gegevens die handmatige kwaliteitsinspectie vereisen, extraheren bijvoorbeeld elke dag 10% van de willekeurige gegevens van gisteren voor handmatige bemonstering.",
              everyAgentCheckLimit: "Maximaal per draw-agent",
              automaticAllocation: "Automatische toewijzing",
              automaticAllocationTip:
                "Toewijzing van bemonsteringsinspecties: de geëxtraheerde gegevens worden automatisch toegewezen aan kwaliteitsinspecteurs. Zo wordt 10% van de willekeurige gegevens van gisteren dagelijks geëxtraheerd voor handmatige bemonsteringsinspectie en worden gemiddeld 2 kwaliteitsinspecteurs willekeurig aan deze taak toegewezen.",
              automaticAllocationTip2:
                "Toewijzing beoordelen: de beroepsgegevens worden automatisch toegewezen aan de kwaliteitsinspecteur ter beoordeling, de dagelijkse bezwaargegevens worden bijvoorbeeld even willekeurig toegewezen aan de 2 kwaliteitsinspecteurs van deze taak ter beoordeling.",
              automaticPush: "Automatische duw",
              automaticPushTip:
                "Triggermelding: melding/alarm tijdens kwaliteitsinspectie, bijvoorbeeld wanneer een fatale fout optreedt in de klantenservice, wordt een meldingsbericht verzonden naar de enterprise-microgroep.",
              automaticPushTip2:
                "Getimed rapport: Stuur de kwaliteitsinspectieresultaten in de vorm van e-mail, stuur bijvoorbeeld de kwaliteitsinspectieresultaten van de handmatige bemonstering van gisteren elke dag om 9.00 uur naar de mailbox van de klantenservice.",
              automaticAssess: "Automatische beoordeling",
              automaticAssessTip:
                "De handmatige bemonsteringsresultaten worden regelmatig vergeleken met de AI-kwaliteitsinspectieresultaten voor nauwkeurigheidsevaluatie. De nauwkeurigheid van de steekproefgegevens van gisteren wordt bijvoorbeeld elke dag om 8:00 uur geëvalueerd.",
              reexaminationComplaint: "Klachtenoverzicht",
              spoktReviewDetail: "Zakelijke configuratie",
              spoktReviewDetailTip:
                "Functies configureren zoals kwaliteitsinspectielijst, willekeurige inspectielijst, klachtenactiviteiten, beoordelingslijst en opmerkingen",
              qaDetailAction: "Configuratie van kwaliteitsinspectie",
              spoktDetailAction: "Bemonsteringsconfiguratie",
              reviewDetailAction: "Configuratie beoordelen",
              appealAction: "Klachtenconfiguratie",
              remarkTemplate: "Notitiesjabloon",
              automaticArchiving: "Automatische archivering",
              conditionalRules: "Voorwaardigingsregels",
              qualityInspectionConditions: "Kwaliteitsinspectievoorwaarden",
              unopened: "Nog niet open",
              sampleInspectionDistributionModel: "Steekproeftoewijzing",
              reviewAllocationModel: "Toewijzing beoordelen",
              distributionModelTitle: "Toewijzingsbeleid:",
              pushRules: "Push-regels",
              push: "Duw",
              assessRules: "Evaluatieregels",
              on: "on",
              off: "off",
              updateStatusSuccess: "Statusverandering succesvol!",
              updateStatusFailed:
                "Statuswijziging mislukt, probeer het later opnieuw!",
              sampleNumberOrPercent: "Bemonsteringsaantal of proportie",
              inspectorList: "Aangewezen kwaliteitsinspecteur",
              numberOfSubmission: "Aantal toegestane verklaringen",
              rewivewDays: "Dag",
              TimeOfSubmission:
                "Geldigheidsdatum van de indiening van de verklaringen",
              rewivewSecond: "Tijden",
              switchOn: "Open",
              switchOff: "Sluiten",
              notMatchAnyRule: "Wanneer geen regels worden gematcht:",
              appealOptionsList: {
                hiddenInspector: "Klacht verborgen inspecteur-informatie",
                hiddenHit:
                  "De beroepspagina ondersteunt highlighting en positionering van hits.",
              },
              caseLibrary: "Case bibliotheek synchronisatie",
              caseLibrarySyncTip:
                "Synchroniseer de cases die door het QA-systeem zijn toegevoegd aan het klantenservicesysteem",
              addCase: "Verhoog de synchronisatie",
              caseLibraryId: "Case Bibliotheek",
              caseLibraryIdTip: "Case base-groepering van QA-systeem",
              udeskCaseLibraryIdTip:
                "Geval groepering van klantenservicesysteem",
              syncHis: "Synchrone Geschiedenis Case",
              otherSettings: "Andere instellingen",
              udeskCaseLibraryId: "Push Case Group",
              url: "Push-URL",
              urlTip:
                "Voer een interfaceadres in dat begint met http:// of https://",
            },
            templateNew: {
              secondaryReview: "Secundaire herziening",
              numberOfAppealsAllowedToBeSubmitted: "Aantal toegestane beroepen",
              validityOfSubmissionOfAppeal:
                "Geldigheid van de indiening van het beroep",
              validityOfAppealReview:
                "Geldigheid van de toetsing in hoger beroep",
              hours: "Uren",
              businessSummary: "Samenvatting van het bedrijf",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Ondergrens van extractie per middel",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Het bedrijfsoverzicht wordt automatisch gegenereerd door het grote model en de dimensie kan worden aangepast aan de bedrijfssituatie.",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Niveau 1 Alleen toewijzingsstrategie voor beoordelaars",
              secondaryCompounding: "Secundair samengesteld:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Secundaire recensenten ondersteunen alleen gelijke verdeling",
              secondLevelReviewer: "Tweede niveau recensent:",
              samplingInspectionTarget: "Bemonsteringsinspectiedoelstelling",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Stel het doel in dat moet worden voltooid door middel van handmatige bemonsteringsinspectie en geef een prompt wanneer het doel niet wordt bereikt of overschreden. Bijvoorbeeld, zou elke klantenservice de steekproefinspectie van 100 punten per maand moeten voltooien, en wanneer het doel minder dan 80 punten is, zullen de pagina van de kwaliteitsinspectie en de steekproefinspectie een prompt geven.",
            },
            component: {
              verificationFailed: "Verificatie mislukt",
              pleaseAddASecondLevelQualityInspector:
                "Voeg kwaliteitsinspecteur op het tweede niveau toe",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Prompt",
                    fieldName: "Veldnaam",
                    fieldDescription: "Veldbeschrijving",
                    generateContent: "Inhoud genereren",
                    enabled: "Ingeschakeld",
                    generationMethod: "Generatiemethode",
                    automaticGeneration: "Automatisch gegenereerd",
                    manualTrigger: "Handmatige trigger",
                    filterCondition: "Filterconditie",
                    return: "Terugkeer",
                    preserve: "Bespaar",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Resultaten genereren" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Niet gekwalificeerd",
                    limitedScope: "Beperkt toepassingsgebied",
                    add: "Toevoegen",
                    thereIsCurrentlyNoDataAvailable: "Geen gegevens",
                  },
                },
              },
              index: {
                fieldName: "Veldnaam",
                fieldDescription: "Veldbeschrijving",
                nothing: "Geen",
                generationMethod: "Generatiemethode",
                generateManually: "Handmatig genereren",
                automaticGeneration: "Automatisch gegenereerd",
                generateContent: "Inhoud genereren",
                notQualified: "Niet gekwalificeerd",
                filterCondition: "Filterconditie",
                enabled: "Ingeschakeld",
                type: "Soort",
                system: "Systeem",
                custom: "Aangepast",
                operation: "Operatie",
                edit: "Bewerken",
                areYouSureToDeleteIt: "Weet u zeker dat u wilt verwijderen?",
                delete: "Verwijderen",
                addField: "Veld toevoegen",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Rolconfiguratie",
              roleConfigTip:
                "Wordt gebruikt om herkenningsregels te configureren voor verwerking van luidsprekerscheiding wanneer de geïmporteerde oproepgegevens mono zijn. Voldoet aan de regels voor de rol van de agent",
              operator: "Exploitant",
              operatorLogic: "Operator logica",
            },
          },
          manualAdd: {
            index: {
              name: "Handmatig toevoegen",
              tip: "Gebruikt om voorbeeldgegevens te filteren door criteria handmatig te configureren en deze snel toe te voegen aan de kwaliteitsinspectietaak",
              screeningCondition: "Toevoegen op filtercriteria",
              aiScreeningCondition: "Intelligente filtertoestand",
              rule: "Regels",
              createTime: "Tijd toevoegen",
              filterTitle: "Inhoud filteren",
              filterRule: "Filterconditie",
              startTime: "Starttijd",
              endTime: "Eindtijd",
              records: "Records importeren",
              createUserName: "Exploitant",
              successCount: "Succesvol toegevoegd",
              conditionRule: "Voorwaarde regels:",
              preview: "Voorbeeld",
              previewData:
                "Momenteel zijn er {0} overeenkomende filtercriteria, {1} taken zijn toegevoegd, {2} taken kunnen worden toegevoegd en {3} taken kunnen niet worden toegevoegd.",
              failCount: "Toevoegen mislukt",
            },
          },
          basicInfo: {
            index: {
              title: "Basisinformatie",
              name: "Naam",
              taskName: "Naam van de taak",
              taskNamePlaceholder: "Voer een taaknaam in",
              requiredName: "Taaknaam kan niet leeg zijn",
              taskRemarkPlaceholder: "Voer een taakbeschrijving in",
              requiredRemark: "Taakbeschrijving kan niet leeg zijn",
              remark: "Beschrijving",
              taskRemark: "Taakbeschrijving",
              source: "Gegevensbron",
              benchmarkScore: "Benchmark score",
              benchmarkScorePlaceholder: "Voer een benchmarkscore in",
              scoreLowerLimit: "Minimale score lagere limiet",
              scoreUpperLimit: "Maximale score cap",
              inspectType: "Type kwaliteitsinspectie",
              nlpModel: "Het NLP-model",
              commonInspect: "Algemene kwaliteitsinspectie",
              associateInspect: "Geassocieerde kwaliteitsinspectie",
              associateCycle: "Correlatieperiode",
              associateRegulation: "Verenigingsregel",
              judgeStrategy: "Uitvoeringslogica",
              rule: "Regels",
              commonTip:
                "Algemene kwaliteitsinspectie: van toepassing op enkele communicatie en dialoog, en kwaliteitsinspectie van de inhoud van enkele communicatie.",
              associateTip:
                "Bijbehorende kwaliteitsinspectie: van toepassing op meerdere communicatie en gesprekken, waarbij de inhoud van meerdere communicatie wordt gekoppeld voor kwaliteitsinspectie.",
              cycleTip:
                "Als er meerdere bijbehorende communicatie en dialogen zijn, zullen de gegevens in de bijbehorende periode de taak voor kwaliteitsinspectie invoeren.",
              scoreLowerLimitPlaceholder: "Voer een minimum score limiet in",
              scoreUpperLimitPlaceholder: "Voer de maximale score-limiet in",
              call: "Spraakoproep",
              dialogue: "Tekst dialoog",
              user: "Kwaliteitsinspecteur",
              wechatUser: "Exploitant",
              noDesignated: "Niet specificeren",
              type: "Formuliertype",
              singleScore: "Enkele Score",
              conduct:
                "Vul eerst de volgende informatie in en klik vervolgens op Volgende",
              conduct2:
                "Gelieve te voltooien de configuratie van de regels van de kwaliteitsinspectie en punten van de kwaliteitsinspectie. Als u de configuratie hebt voltooid, kunt u klikken op Volgende of Gids afsluiten",
              conduct3:
                "De kwaliteitsinspectie-classificatie is geconfigureerd volgens het kwaliteitsinspectiepunt en de beoordeling ervan. U kunt verschillende resultaatniveaus voor kwaliteitsinspectie definiëren. Als u geen beoordeling nodig hebt, kunt u deze stap overslaan. Als u de configuratie hebt voltooid, kunt u op Volgende klikken of de gids verlaten.",
              conduct4:
                "U kunt de gegevenskenmerken configureren die automatisch aan de taak worden toegevoegd op basis van de gegevenskenmerken die de taak moet controleren in de automatische optelpositie. Als u voorlopig niet automatisch gegevens hoeft toe te voegen, kunt u op Overslaan klikken. Als u de configuratie hebt voltooid, kunt u op Volgende klikken.",
              conduct5:
                "Als u gebruik maakt van mono-opname, zorg er dan voor dat u dit item configureert en klik op Volgende. Gebruik alleen de stem van de klantenservice om het systeem te helpen de verschillende sporen tussen de klantenservice en de klant te identificeren. Als u een opname met twee kanalen gebruikt of de configuratie hebt voltooid, kunt u op Overslaan klikken",
              conduct6:
                "U kunt de handmatige optelfunctie gebruiken om de bestaande communicatiegegevens in het systeem te introduceren voor kwaliteitsinspectie, of u hebt een handmatige opteltaak ingediend. Klik op Rechtstreeks voltooien",
              helper: "Helpdocumentatie bekijken",
              quit: "Boot afsluiten",
              next: "Volgende stap",
              complete: "Compleet",
              skip: "Sla over",
              cancel: "Annuleer",
              delete: "Verwijderen",
              save: "Bespaar",
            },
            template: {
              samplingInspector: "Bemonsteringsinspecteur",
              sessionType: "Sessietype",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Kwaliteitsinspectie werkbank",
              search: "Filter",
              export: "Uitvoer",
              condition: "Voorwaarde",
              logic: "Logica",
              reQualityInspection: "Herinspectie",
              reQualityInspectionUnderWay: "Herinspectie taak...",
              reQualityInspectionUnderWayTip:
                "Het verlaten van de pagina na het sluiten van het venster heeft geen invloed op de taak",
              reQualityInspectionDone: "Herinspectie voltooid",
              reQualityInspectionDoneTip:
                "De herinspectie is met succes voltooid. Klik op de bevestigingsknop.",
              endReQualityInspection: "Taak beëindigen",
              alreadyDoneTip: "Voltooide",
              totalTip: "Totaal",
              unit: "Artikel",
              endReInspectionSuccess: "Taak van herkwaliteit beëindigd",
              closeWindow: "Raam sluiten",
              endReInspectionFailed:
                "De taak van herkwaliteit is mislukt, probeer het later opnieuw!",
            },
            detail: {
              name: "Details over kwaliteitsinspectie",
              reviewName: "Details bekijken",
              spotCheckName: "Details van de bemonstering",
              audioText: "Opgenomen tekst",
              callInfo: "Bel informatie",
              satisfactionEvaluation: "Tevredenheidsevaluatie:",
              queueTime: "Tijd besteed aan in de rij:",
              ringingTime: "Rinkelende tijd:",
              callTime: "Bel evenementen:",
              hangUpIdentity: "Oproep opgehangen feest:",
              location: "Thuis:",
              source: "Bron:",
              agent: "Klantenservice:",
              deviceType: "Type apparaat:",
              callType: "Oproeptype:",
              phoneNumber: "Relaisnummer:",
              task: "Taken:",
              commentsTip: "Voer commentaar op kwaliteitsinspectie in",
              lastPage: "Vorige pagina",
              nextPage: "Volgende pagina",
              score: "Kwaliteitsinspectiescore",
              review: "Beoordeling",
              file: "Archivering",
              changeList: "Record wijzigen",
              fullMarks: "Volle markeringen",
              most: "De meeste",
              saveReview: "Inzending beoordelen",
              saveSpotCheck: "Bemonstering inspectie en indiening",
              point: "Kwaliteitsinspectiepunt",
              sampleAssignInspector: "Bemonsteringsinspecteur toewijzen",
              reviewAssignInspector: "Reviewer toewijzen",
              sampleStatus: "Bemonsteringsstatus",
              read: "Lees",
              user: "Gebruiker",
              confirmer: "Bevestigde Persoon",
              voiceDownloadError:
                "Voice-download is mislukt, probeer het later opnieuw!",
              smartTags: "Slim label",
              interactiveTip:
                "Gebruik interactieherkenning om het gebruik van klantenservice te detecteren FAQ",
              interactiveIsEmptyTip:
                "Geen interactieve herkenningsinhoud gedetecteerd",
              businessRecords: "Bedrijfsrecords",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Analyse van Enterprise WeChat Group Chat",
            },
          },
        },
      },
      cards: {
        health: "Kwaliteitsinspectie gezondheid",
        accuracy: "Goedkeuringspercentage voor ontdekkingen",
        sessionsTotal: "Totaal aantal sessies",
        enable: "Inschakelen",
        disable: "Uitschakelen",
        none: "Nee",
        call: "Bel",
        dialogue: "Dialoog",
        taobao: "E-commerce",
        wecom: "Sessie",
        ticket: "Werkorder",
      },
      component: {
        backup: "(Backup)",
        taskSaveAsSucceeded: "Taak opgeslagen als succesvol",
        copyContent: "Inhoud kopiëren",
        essentialInformation: "Basisinformatie",
        ruleConfiguration: "Regelconfiguratie",
        basicInformationQualityInspector:
          "Basisinformatie-Kwaliteitsinspecteur",
        qualityInspectionRating: "Kwaliteitsinspectie beoordeling",
        roleConfiguration: "Rolconfiguratie",
        autoAddConfiguration: "Configuratie automatisch toevoegen",
        autoPushConfiguration: "Automatische push-configuratie",
        automaticSamplingConfiguration:
          "Automatische bemonsteringsconfiguratie",
        automaticAllocationConfiguration:
          "Automatische toewijzingsconfiguratie",
        businessConfiguration: "Zakelijke configuratie",
        caseBaseConfiguration: "Case Bibliotheek",
      },
      template: {
        cancel: "Annuleer",
        complete: "Compleet",
        sort: "Sorteren",
        ruleApproval: "Goedkeuring van de regel",
        enterpriseMicroConversationAnalysisTask:
          "Ondernemingstaak voor microsessieanalyse",
        enterpriseAndMicroVoiceAnalysisTask:
          "Enterprise Micro Voice Analysis Taak",
        saveTaskAs: "Taak opslaan",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Regeldetails" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Wijziging geslaagd",
            automaticApproval: "Automatische goedkeuring",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Wijziging geslaagd",
            test: "Test",
            formal: "Formeel",
            adopt: "Door",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Weet je zeker dat je",
              pleaseEnterTheRejectionReason: "Voer een reden in voor afwijzing",
              reject: "Afgewezen",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Wijziging geslaagd",
              taskName: "Naam van de taak",
              dataSource: "Gegevensbron",
              qualityInspectionType: "Type kwaliteitsinspectie",
              state: "Status",
              updateTime: "Update tijd",
              updatedBy: "Persoon bijwerken",
              approver: "Goedkeuring",
              reasonForRejection: "Reden voor afwijzing",
              operation: "Operatie",
              details: "Details",
              adopt: "Door",
              whole: "Allemaal",
              approvalStatus: "Goedkeuringsstatus",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Overzicht",
      hi: "Hoi,",
      standardEdition: "Standaardeditie",
      call: "Balans voor inspectie van spraakkwaliteit",
      text: "Geldigheidsperiode van de kwaliteitscontrole van de tekst",
      collectionTasks: "Verzamelde kwaliteitsinspectietaken",
      noCollection: "Geen verzameling kwaliteitsinspectietaken,",
      goToCollecte: "Ga naar Collectie",
      yesterdayOverView: "De bedrijfsstatistieken van gisteren",
      yesterdayAgent: "De klantenservicelijst van gisteren",
      yesterdayInspWork:
        "De werksituatie van gisteren van kwaliteitsinspecteur",
      operationalStatistics: {
        title: "Bedrijfsstatistieken",
        qualityTesting: "Kwaliteitsinspectie",
        spotCheck: "Willekeurige inspectie",
        appeal: "Klacht",
        chartTitles: {
          qualityTestingTrend: "Kwaliteitsinspectie volumetrend",
          qualityTestingAnalysis: "Kwaliteitsinspectie-taak",
          spotCheckTrend: "Bemonsteringstrend",
          spotCheckAnalysis: "Sampling taaksituatie",
          appealTrend: "Klachtentrends",
          appealAnalysis: "De status van het mandaat van beroep",
        },
        titles: {
          qualityDataTend: "Gegevensdistributie kwaliteitsinspectie",
          qualityCover: "Kwaliteitsinspectie dekking",
          qualityAverageTime: "Gemiddelde kwaliteitsinspectie wachttijd",
          qualityCoverTend: "Kwaliteitsinspectie dekkingstrend",
          handCover: "Handmatige behandeling",
          checkCover: "Bemonsteringsdekking",
          resAverageTime: "Gemiddelde verwerkingstijd",
          handTend: "Handmatige verwerkingstrend",
          dataTend: "Distributie van gegevenstaak",
        },
        qualityAverage: "Gemiddelde kwaliteitsinspectiescore",
        scoreTrend: "Tendens voor kwaliteitsinspectie",
        qualityAverageTime: "Gemiddelde kwaliteitsinspectie wachttijd",
        callCoverage: "Dekking van spraakkwaliteitsinspectie",
        textCoverage: "Dekking van de kwaliteitsinspectie van de tekst",
        checkCount: "Bemonsteringsgegevensvolume",
        siphonCount: "Extraheer gegevensvolume",
        reviewCount: "Gegevensvolume beoordelen",
        appealCount: "Volume van klachtengegevens",
        checkRate: "Percentage willekeurige inspectie",
        reviewRate: "Percentage van Grievance Review",
        PieTitle: {
          totalCount: "Totaal aantal gesprekken",
          totalCall: "Aantal spraakoproepen",
          totalIm: "Aantal tekstgesprekken",
          inspectionCall: "Inspectie van spraakkwaliteit kwantiteit",
          inspectionIm: "Kwantiteit kwaliteitsinspectie tekst",
          checkCall: "Hoeveelheid spraakbemonstering",
          unCheckCall: "Stem niet gesampled",
          checkIm: "Aantal tekstbemonstering",
          unCheckIm: "Ongesamplede hoeveelheid tekst",
          reviewCall: "Spraakherzieningsvolume",
          reviewIm: "Aantal tekstherziening",
          unReviewCall: "Stem ongecontroleerde hoeveelheid",
          unReviewIm: "Bedrag van de tekst niet beoordeeld",
          unInspectionCall: "Spraakhoeveelheid niet gecontroleerd",
          unInspectionIm: "Niet-gecontroleerde hoeveelheid tekst",
        },
        check_rate: "Bemonsteringsdekking",
        voice_check_rate: "Spraakbemonstering",
        text_check_rate: "Dekking voor tekstbemonstering",
        sample_execute_time: "Gemiddelde bemonsteringstijd",
        review_execute_time: "Gemiddelde verwerkingstijd van de klacht",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Regels voor kwaliteitsinspectie Kanban",
          agent: "Ranking lijst van klantenservice",
        },
        tableTips: {
          conditions:
            "<Div> sorteert het totale aantal gesprekken dat in strijd is met en voldoet aan de regels voor kwaliteitsinspectie. <Br/>\nKwaliteitsinspectie Item: naam van het kwaliteitsinspectiepunt <br />\nTotaal aantal illegale gesprekken: totaal aantal illegale gesprekken over dit kwaliteitsinspectieartikel <br />\nPercentage overtredingen: Percentage overtredingen = totaal aantal overtredingen/totaal aantal overtredingen </div>",
          agent:
            "<Div> Sorteer de gemiddelde beoordeling van de agenten <br />\nNaam van de klantenservice: de naam van de klantenservice <br />\nKlantenservicegroep: de klantenservicegroep waar de klantenservice zich bevindt (de organisatiegegevens in de klantenservice)<br />\nTotaal aantal gescoorde gesprekken: totaal aantal kwaliteitsinspectiegesprekken van de klantenservice <br />\nGemiddelde kwaliteitsinspectiescore: de gemiddelde kwaliteitsinspectiescore van de agent (totale score/totaal aantal gesprekken)</div>",
        },
        tips: {
          total:
            "Totaal aantal passen/gesprekken ingevoerd in de kwaliteitsinspectietaak",
          agentTotal:
            "Het totale aantal kwaliteitsinspectieagenten dat de kwaliteitsinspectietaak heeft ingevoerd",
          avg: "Gemiddelde score van kwaliteitsinspectiescore",
          violation:
            "Som het aantal keren dat alle dialoogkwaliteitsinspectie-items zijn geraakt en geschonden",
          compliance:
            "Som het aantal keren dat de kwaliteitsinspectie-items worden geraakt in het kwaliteitsinspectieproces door middel van dialoog",
          check: "Totaal aantal handmatige bemonstering",
          appeal:
            "Totaal aantal ingediende klachten in totaal aantal gesprekken",
          review:
            "Totaal aantal ingezonden beoordelingen in totaal aantal communicatiegesprekken",
        },
        subTips: {
          violation:
            "Overtredingspercentage: totaal aantal overtredingen/totaal aantal kwaliteitsinspectie-items * 100%",
          compliance:
            "Compliance rate: totaal aantal conformiteitstijden/totaal aantal kwaliteitsinspectie-items * 100%",
          check:
            "Bemonsteringsdekking: totale handmatige bemonstering/totale kwaliteitsinspectie * 100%",
          appeal:
            "Beroepspercentage: totaal aantal beroepen/totaal aantal kwaliteitsinspecties * 100%",
          review:
            "Beoordelingspercentage: totaal aantal beoordelingen/totaal aantal kwaliteitsinspecties * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Systeeminstellingen",
          systemName: "Systeemnaam",
          speakerRecognize: "Luidspreker identificatie",
          massage: "Berichtmelding",
          evaluate: "Kunstmatige cognitieve evaluatie",
          evaluateTip:
            "Selecteer na het openen een snelle controle ter plaatse in de details van de kwaliteitsinspectie en een snelle handmatige evaluatie",
          speakerRecognizeTip:
            "Om toegang te krijgen tot een gespreksopname van derden via API, moet worden vastgesteld of het linkerkanaal een klantenservice of een klant is.",
          speakerRecognizeTip2:
            "Single-channel opname maakt gebruik van tekstherkenning en rolconfiguratie om de rol van de agent te definiëren.",
          enabledImSendStatusFilter: "Filtering van tekstkwaliteitsinspectie",
          enabledImSendStatusFilterTips:
            "De detectie-inhoud van de spraak van de operator in kwaliteitsinspectie omvat geen berichten in de status van [Opgenomen], [Verzenden mislukt] en [Offline bericht ongelezen]",
          lang: "Instellingen voor systeemtaal",
          zh: "Vereenvoudigd Chinees",
          en: "English",
          tw: "Traditioneel Chinees",
        },
        template: {
          largeModelSettings: "Instellingen voor grote modellen",
          indonesian: "Indonesisch",
          vietnamese: "Vietnamees",
          portuguese: "Portugees",
          malay: "Maleis",
          french: "Frans",
          spanish: "Spaans",
          japanese: "Japans",
          thai: "Thai",
          blockSystemSendingMessages: "Maskersysteem Bericht verzenden",
          textDataDesensitization: "Desensibilisatie van tekstgegevens",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Wanneer de schakelaar is ingeschakeld, wordt de vervolgkeuzelijst voor het desensibilisatieveld weergegeven (multi-selectie wordt ondersteund). De desensibilisatievelden omvatten de naam van de persoon, het mobiele telefoonnummer en het adres.",
          desensitizingField: "Ongevoelig veld",
          name: "Naam van de persoon",
          cellphoneNumber: "Mobiel telefoonnummer",
          address: "Adres",
          dataCleaning: "Gegevensreiniging",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Na het instellen worden de conversatie van de kwaliteitsinspectie en de bedrijfsgegevens van de WeChat-sessie regelmatig opgeschoond en kunnen ze niet worden hersteld. Gelieve zorgvuldig te werken.",
          newCleaningRules: "Nieuwe opruimregel",
          qualityInspectionRuleApproval:
            "Goedkeuring van de regels voor kwaliteitsinspectie",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Na opening moet de wijziging van de kwaliteitsinspectieregel worden goedgekeurd voordat deze van kracht wordt.",
          maskOfflineMessageUnread: "Masker offline bericht ongelezen",
          privacyNumberProtection: "Bescherming van privacynummers",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Maak na het openen het veld "klanttelefoon" ongevoelig tijdens het gesprek en geef het weer als 1881 * * * * * * * * * * * *',
          shieldingWithdrawn: "Masker is ingetrokken",
          maskSendingFailed: "Verzenden van maskers is mislukt",
        },
        component: {
          conversation: "Bel",
          dialogue: "Dialoog",
          weCom: "Enterprise WeChat",
          day: "Dag",
          week: "Week",
          month: "Maand",
          year: "Jaar",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Toepassingsgebied van het schoonmaken",
            dataCleaningTimeSetting: "Instellingen voor gegevensreinigingstijd",
            afterCleanUpTheData: "Na het schoonmaken van de gegevens",
            dataAfterComplaintCompletion: "Gegevens over post-klachten",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Succesvolle uitvoering",
          saveSuccessful: "Bespaar met succes",
          speechRecognition: "Spraakherkenning",
          reExecuteErrorCorrection: "Voer foutcorrectie opnieuw uit",
          confirmExecution: "Executie bevestigen",
          voiceDetection: "Stemdetectie",
          noiseDetectionPowerLevel: "PowerLevel ≤ Geluidsdetectie",
          pleaseEnterTheThreshold: "Voer een drempel in",
          volumeDetectionPowerLevel: "Volumedetectie powerLevel ≥",
          reset: "Reset",
          preserve: "Bespaar",
        },
        coachTemplate: {
          french: "Frans",
          spanish: "Spaans",
          japanese: "Japans",
          thai: "Thai",
          loginPopupWindow: "Login pop-up venster",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Na het openen wordt een pop-upvenster weergegeven bij het betreden van het intelligente sparringsysteem.",
          title: "Titel",
          text: "Tekst",
          checkBoxContent: "Inhoud selectievakje",
          informedConsentForm: "Informeerde toestemmingsformulier",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Om u te helpen uw communicatie-en spraakvaardigheden en de toepassing van professionele kennis te verbeteren, zodat u altijd en overal kunt oefenen en realtime feedback kunt ontvangen, verzamelen we de volgende informatie van u via dit platform:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Ik heb dit Informed Consent-formulier gelezen en volledig begrepen en ga er plechtig mee akkoord om met mijn gevoelige persoonlijke informatie om te gaan in overeenstemming met de doeleinden en de manier vermeld in dit Informed Consent Form",
        },
      },
      systemIntegration: {
        title: "Systeeminstellingen",
        ccIntegrationTips:
          "Intelligente kwaliteitsinspectie Docking Udesk Call Center Beschrijving Document",
        unauthorized: "Ongeautoriseerd",
        authorized: "Geautoriseerd",
        udeskDomain: "Domeinnaam van het klantenservicesysteem",
        udeskToken: "Agent systeemsleutel",
        udeskAdminEmail: "Klantenservice systeembeheerdersaccount",
        password: "Beheerdersaccount wachtwoord",
        applyAuth: "Autorisatie",
        cancelAuth: "Autorisatie annuleren",
        reApplyAuth: "Herautorisatie",
        keyTips:
          "Achtergrondbeheer van klantenservicesysteem-API-authenticatietoken in eenmalige aanmelding",
      },
      customerField: {
        title: "Klantenveld",
        table: {
          propName: "Veldnaam",
          propDesc: "Veldbeschrijving",
          valueType: "Veldtype",
          propType: "Creatiemethode",
          createTime: "Creatietijd",
          canNotEdit: "Systeemvelden ondersteunen geen bewerking",
        },
        edit: "Veld bewerken",
        new: "Nieuw veld",
      },
      wechatDocking: {
        title: "Enterprise micro-docking",
        formLabels: {
          companyId: "Enterprise-ID",
          communicationKey: "Communicatiesleutel",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL voor het terugbellen van evenementen",
          applicationName: "Naam van de aanvraag",
          applicationId: "Webtoepassing-ID",
          applicationSecret: "Toepassing van geheim",
        },
        formTitle: {
          initialForm: "Eerste installatie",
          synchronizeForm: "Synchronisatie van het adresboek",
          settingForm: "Applicatie-instellingen",
        },
        qrCodeUrlTip:
          "Vraag de beheerder om de onderstaande tweedimensionale code te scannen om de ontwikkelingstoepassing te binden.",
      },
      taobao: {
        formLabels: {
          name: "Winkelnaam",
          account: "Rekeningnummer",
          password: "Wachtwoord",
          confirm: "Wachtwoord bevestigen",
          authorization: "Autorisatie",
          switchOn: "Open",
          switchOff: "Sluiten",
        },
        tableLabels: {
          name: "Winkelnaam",
          account: "Rekeningnummer",
          authorization: "Autorisatie",
          updateUser: "Onlangs bijgewerkte persoon",
          updateTime: "Laatste Update Tijd",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Secundaire domeinnaam Weifeng",
          secretKeyTip:
            "De geheime sleutel van de huurder wordt verkregen op de dokplaats van het achtergrondsysteem van het Weifeng-systeem.",
        },
      },
      user: {
        index: {
          name: "Werknemersmanagement",
          existingUsers: "Er zijn al werknemers.",
          deleteSuccess: "Met succes verwijderd",
          deleteFailed: "Verwijderen mislukt",
        },
        detail: {
          accountPrompt: "Voer een mailbox in",
          resetPassword: "Wachtwoord resetten",
          passwordPrompt: "Wachtwoord (6-14 combinatie van letters en cijfers)",
          originPasswordPrompt: "Voer het eerste wachtwoord in",
          resumePasswordPrompt: "Voer uw wachtwoord opnieuw in",
          passwordFormWrong:
            "Voer een combinatie van 6-14 letters en cijfers in!",
          resumePasswordWrong: "Twee wachtwoorden zijn inconsistent!",
          suppertImageType: "Ondersteuning jpg, gif, png, maximaal 500Kb",
          namePrompt: "Voer een naam in",
          nickNamePrompt: "Voer een bijnaam in",
          telephonePrompt: "Voer het telefoonnummer in",
          roleSelectedPrompt: "Selecteer een rol",
          agentIdPrompt: "Voer het taaknummer in",
          disabled: "Uitschakelen",
          enabled: "Inschakelen",
          reloadBowerInfo:
            "Gebruikersinformatie verandert, moet u de browser vernieuwen?",
          saveUserError:
            "Gebruikersinformatie niet opslaan, probeer het later opnieuw",
          resetPasswordSuccess: "Wachtwoord reset succesvol",
          resetPasswordFailed:
            "Wachtwoord reset mislukt, probeer het later opnieuw",
        },
      },
      userGroups: {
        index: {
          name: "Werknemersgroepsbeheer",
          existingUserGroups: "Bestaande werknemersgroep",
        },
        detail: {},
      },
      role: {
        index: { name: "Rolbeheer", existingRoles: "Bestaande rollen" },
        edit: {
          editRole: "Rol bewerken",
          createRole: "Creëer een rol",
          basicInfo: "Basisinformatie",
          taskPermissions: "Taakmachtigingen",
          existingTasks: "Al taken",
        },
      },
      anomalousData: {
        title: "Abnormale gegevens",
        list: {
          selectHolder: "Selecteer een gegevensbron",
          businessTime: "Zakelijke tijd",
          businessId: "Zakelijk ID",
          export: "Uitvoer",
        },
      },
      templateComponent: {
        list: {
          title: "Sjabloon markt",
          name: "Naam sjabloon",
          searchHolder: "Voer de inhoud in om te zoeken",
          update: "Update",
          sort: "Sorteren",
          moduleType: "Sjabloontype",
          moduleSourceType: "Toepasselijk type",
          fetchNum: "Aantal verworven tijden",
          newest: "Onlangs bijgewerkt",
          usedMost: "Krijg het meeste",
          call: "Bel",
          dialogue: "Dialoog",
          waiting: "Te beoordelen",
          cancel: "Publiceren ongedaan",
          success: "Goedgekeurd en gepubliceerd",
          failed: "Afwijzing van de audit",
          no: "Niet te upgraden",
          ok: "Kan upgraden",
          all: "Allemaal",
          delete: "Met succes verwijderd",
          downOk: "Succesvol verwijderd",
          withdraw: "Terugtrekking geslaagd",
          updateOk: "Upgrade succesvol",
          get: "Krijg",
          width: "Intrekking",
          getOk: "Succes verwerving",
          orgName: "Organisatie",
          nowVersion: "Huidige versie",
          lastedVersion: "Laatste Versie",
          appStatus: "Aanvraagstatus",
          actions: "Operatie",
          down: "Van de plank",
          updateTop: "Upgrade",
          check: "Weergave",
          deleteWhen: "Verwijdering bevestigen?",
          yes: "Ja",
          noo: "Nee",
          deleteOk: "Verwijderen",
          permissible: "Toegestaan",
          noPermissible: "Niet toegestaan",
          openUp: "Open",
          openDown: "Niet open",
          task: "Kwaliteitsinspectietaak",
          point: "Kwaliteitsinspectiepunt",
          tag: "Semantisch label",
          muti: "Meerdere elementen",
          inter: "Eén vraag en één antwoord",
          info: "Informatie entiteit",
          concept: "Conceptwoorden",
          detail: "Details van de module",
          infomation: "Basisinformatie",
          detailConfig: "Gedetailleerde configuratie",
          twoEdit: "Secundaire bewerking",
          mustUpdate: "Nu upgraden",
          descition: "Sjabloon Beschrijving",
          descitionSize:
            "De beschrijving van het sjabloon mag niet groter zijn dan 250 woorden",
          stencilsDetail: "Sjabloon Details",
          updatedRecords: "Record bijwerken",
          version: "Versienummer",
          updateDescription: "Beschrijving bijwerken",
          myModule: "Mijn sjabloon",
          addModule: "Nieuwe Module",
          publishVersion: "Release-versie",
          earliestVersion: "De vroegste versie",
          edit: "Bewerken",
          add: "Nieuw",
          configNo: "Module-configuratiedetails kunnen niet leeg zijn",
          updateNo: "Update Beschrijving kan niet leeg zijn",
          editSuccess: "Wijziging geslaagd",
          saveSuccess: "Bespaar met succes",
          newer: "Update",
          sumbit: "Indiening",
          cancelName: "Annuleer",
          nameRequired: "De naam van de module is een vereist veld",
          nameSize: "De naam van de module mag niet groter zijn dan 16 tekens",
          publishStatus: "Publicatiestatus",
          submitUpdate: "Update indienen",
          updateVersion: "Update Versie",
          updateExplain: "Instructies bijwerken",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon e-commerce",
        youtubeVideo: "Youtube video",
        amazon: {
          component: {
            queuing: "In de rij staan",
            notStarted: "Niet begonnen",
            inProgress: "In uitvoering",
            success: "Succes",
            failed: "Mislukt",
            successfullyCreated: "Succesvol gemaakt",
            editedSuccessfully: "Met succes bewerkt",
            operationSucceeded: "Operatie is geslaagd",
            deletedSuccessfully: "Met succes verwijderd",
            link: "Link",
            grabState: "Grijp staat",
            updateTime: "Update tijd",
            grab: "Grijp",
            edit: "Bewerken",
          },
          template: {
            amazonEcommerce: "Amazon e-commerce",
            thisProduct: "Dit product",
            competition: "Concurrentie",
            new: "Nieuw",
            edit: "Bewerken",
            link: "Link",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube video",
            thisProduct: "Dit product",
            competition: "Concurrentie",
            new: "Nieuw",
            edit: "Bewerken",
            link: "Link",
          },
          component: {
            queuing: "In de rij staan",
            notStarted: "Niet begonnen",
            inProgress: "In uitvoering",
            success: "Succes",
            failed: "Mislukt",
            successfullyCreated: "Succesvol gemaakt",
            editedSuccessfully: "Met succes bewerkt",
            operationSucceeded: "Operatie is geslaagd",
            deletedSuccessfully: "Met succes verwijderd",
            youTubeVideoLink: "YouTube-videoverbinding",
            grabState: "Grijp staat",
            updateTime: "Update tijd",
            grab: "Grijp",
            edit: "Bewerken",
          },
        },
      },
    },
    demo: {
      title: "Intelligente kwaliteitsinspectie",
      template: "Selecteer Kwaliteitsinspectie sjabloon",
      radio: "Selecteer opnameformulier",
      uploadAudio: "Opname uploaden",
      uploadAudioTip:
        "De geüploade opname ondersteunt alleen wav-en mp3-formaten en de grootte is niet groter dan 1G",
      uploadText: "Tekst uploaden",
      uploadTextTip:
        "De geüploade tekst ondersteunt alleen srt-en txt-formaten en de grootte is niet groter dan 10M. Na het uploaden van de tekst wordt ASR niet meer gebruikt om het opnamebestand te parseren.",
      uploadSuccess: "Opnamebestand met succes geüpload",
      uploadFailed: "Opnamebestand niet uploaden",
      startQualityInspection: "Start kwaliteitsinspectie",
      qualityInspectionStarted: "Kwaliteitsinspectie in uitvoering",
      downloadText: "Tekst downloaden",
      checking: "Vooruitgang van de kwaliteitsinspectie",
      checkingTip: "Verwacht een paar minuten, wees geduldig",
      noCheckContent: "Nog geen resultaten van kwaliteitsinspectie",
      noTextDownload: "Geen kwaliteitsinspectietekst kan worden gedownload!",
      pleaseCheckParams:
        "Controleer of u de sjabloon, het spraaktype wilt selecteren en het opnamebestand wilt uploaden",
      inspectionFailed:
        "Kwaliteitsinspectie mislukt, probeer het later opnieuw!",
    },
    wechatQa: {
      baseInfo: { title: "Basisinformatie" },
      conversationSetting: {
        title: "Sessie-instellingen",
        segmentationStrategy: "Snijstrategie",
        rule: "Strategie",
        time: "Snijtijd",
        daily: "Elke dag",
        passScore: "Sessie in aanmerking komende score",
        ruleLogic: "Uitvoeringslogica",
      },
      message: {
        title: "Push-evenementen",
        table: {
          index: "Serienummer",
          name: "Naam",
          type: "Push-modus",
          desc: "Beschrijving",
          status: "Status",
        },
        subTitle: "Lijst van evenementen",
      },
      checkPoint: {
        title: "Analyze model",
        groups: "Modelgroepering",
        newClassification: "Nieuwe groep",
        editClassification: "Groepering bewerken",
        benchmarkScore: "Benchmark score",
        pointCount:
          "Gemeenschappelijk analysemodel onder de huidige classificatie",
        openPointCount: "Aantal Enables is",
        closedPointCount: "Gedeactiveerde hoeveelheid is",
        scoreLowerLimit: "Minimale score lagere limiet",
        scoreUpperLimit: "Maximale score cap",
        classificationName: "Groepsnaam",
        classificationNamePlaceHolder: "Voer een groepsnaam in",
        classificationRemark: "Groepsbeschrijving",
        classificationRemarkPlaceHolder: "Voer een groepsbeschrijving in",
      },
      chartRecord: {
        title: "Enterprise WeChat-sessie",
        table: {
          id: "Sessie-ID",
          type: "Sessietype",
          startTime: "Begin tijd van de sessie",
          endTime: "Sessie Laatste Eindtijd",
          groupId: "Groep-ID",
          client: "Klant",
          customer: "Klantenservice",
          averageScore: "Gemiddelde kwaliteitsinspectiescore",
        },
        detail: {
          title: "Details van de Enterprise WeChat-sessie",
          inspectionInfo: "Analyse-informatie",
          chartInfo: "Sessie-informatie",
          tasks: "Analyse taak",
          avgScore: "Gemiddelde score van analyze",
          qualified: "Gekwalificeerd",
          unqualified: "Ongekwalificeerd",
          taskTitle: "Toepasselijke analyze sjabloon",
          info: "Real-time analyze van informatie",
          today: "Het nieuws van vandaag",
          history: "Historische berichten",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Voer een opmerking/bijnaam in",
                pleaseEnterTheGroupChatName: "Voer een groepschatnaam in",
              },
            },
          },
          template: { whole: "Allemaal" },
        },
      },
      realTimeAnalysis: {
        title: "Real-time analyze",
        table: {
          name: "Naam van de scène",
          count: "Aantal analysemodellen",
          baseScore: "Benchmark score",
          passScore: "Gekwalificeerde score",
          maxScore: "Maximale score cap",
          minScore: "Minimale score lagere limiet",
          status: "Status",
          actions: "Operatie",
        },
        detail: {
          title: "Real-time analyze van scenario's",
          form: {
            name: "Naam van de scène",
            prevCondition: "Scenario-triggercondities",
            prevConditionToolTip:
              "Als de voorwaarde leeg is, wordt het scenario standaard geanalyseerd",
            prevConditionError:
              "Scenario-triggercondities kunnen niet samenvallen met het analysemodel",
            scoreInputPlaceholder: "Score invoeren",
            baseScore: "Benchmark score",
            status: "Status",
            passScore: "Gekwalificeerde score",
            maxScore: "Maximale score bovengrens",
            minScore: "Ondergrens van minimumscore",
            analysisModel: "Analyze model",
            message: "Aanmelding trigger",
            pushType: "Push-modus",
            createAnalysisModel: "Model toevoegen",
            scoreUpperLimitError:
              "De ondergrens van de minimumscore kan niet groter zijn dan de bovengrens van de maximale score.",
            table: { name: "Modelnaam", rule: "Regels", score: "Score" },
            pushConfigSwitchTip1: "Wanneer de scène verschijnt",
            pushConfigSwitchTip2: "Tijden en hoger, pushmeldingen",
          },
        },
      },
      smartCustomerTag: {
        title: "Slimme klantetikettering",
        table: {
          name: "Regelnaam",
          tags: "Enterprise micro-klantlabel",
          status: "Ingeschakelde status",
          createUser: "Schepper",
          createTime: "Creatietijd",
        },
        detail: {
          name: "Regelnaam",
          condition: "Regels instellen",
          logic: "Logica",
          status: "Ingeschakelde status",
          tags: "Selecteer label",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Klant Gemarkeerde Hoeveelheid" },
        },
        messageFiltering: {
          template: {
            edit: "Bewerken",
            messageContent: "Berichtinhoud",
            messageTypeID: "Type bericht ID",
            messageTypeDescription: "Boodtype Beschrijving",
            messageFiltering: "Bericht filteren",
            suitableForFilteringDataWithoutQualityInspection:
              "Geschikt voor het filteren van gegevens zonder kwaliteitsinspectie",
            create: "Creëer",
            messageDescription: "Berichtbeschrijving",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Spreektijd",
          sessionType: "Sessietype",
          whole: "Allemaal",
          staff: "Werknemer",
          customer: "Klant",
          callKeywords: "Roep sleutelwoorden",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Gegevens micro-spraakoproep Enterprise",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Sessiearchief",
        voiceCall: "Spraakoproep",
        noMoreVoiceCalls: "Geen spraakoproepen meer",
        enterpriseWechatVoiceCall: "Enterprise WeChat-spraakoproep",
        enterpriseWechatConversationDetails:
          "Details van de Enterprise WeChat-sessie",
        previous: "Vorige artikel",
        next: "Volgende artikel",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Ondernemingstaak voor microsessieanalyse",
          enterpriseAndMicroVoiceAnalysisTask:
            "Enterprise Micro Voice Analysis Taak",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Analyse van Enterprise WeChat Group Chat",
      },
      wechatQaSettingEntry: { messageFiltering: "Bericht filteren" },
    },
    businessAnalysis: {
      keyword: "Naam rapport",
      createUser: "Schepper",
      createTime: "Creatietijd",
      updateTime: "Update tijd",
    },
    semanticIntelligence: {
      applicationMessageType: "Type toepassingsbericht",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Maak de configuratie alstublieft perfect",
              generatePromptLanguage: "Prompt gegenereerd",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Naleving",
              gradedEvaluation: "Beoordeling van de kwaliteit",
            },
          },
          training: {
            index: {
              pleaseEnter: "Voer alstublieft in",
              pleaseGeneratePrompt: "Gelieve prompt te genereren",
              pleaseSelectADialogue: "Selecteer een dialoogvenster",
              training: "Opleiding",
              dataType: "Gegevenstype:",
              voiceCall: "Spraakoproep",
              textDialogue: "Tekst dialoog",
              generate: "Genereren",
              qualityInspectionResults:
                "De resultaten van de kwaliteitsinspectie:",
              hit: "Hit:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Modelnaam",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Voer bijvoorbeeld de modelnaam in of de agent geen actie heeft ondernomen.",
            modelDescription: "Beschrijving van het model",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "U kunt in detail beschrijven wat u moet testen, wat voor het model gemakkelijk te begrijpen is. Bijvoorbeeld, of de klantenservice geen actie heeft, dat wil zeggen, de klantenservice heeft gereageerd op de vraag van de klant, maar de vraag van de klant is niet afgehandeld of de inhoud van het antwoord heeft niets te maken met het antwoord van de klant.",
            validity: "Effectiviteit",
            effective: "Effectief",
            invalid: "Ongeldig",
            type: "Soort",
            hintLanguage: "Prompt",
            editIntelligentModel: "Slim model bewerken",
            createANewIntelligentModel: "Nieuw slim model",
          },
        },
        index: {
          modelName: "Modelnaam",
          modelDescription: "Beschrijving van het model",
          type: "Soort",
          whetherItMeetsTheRequirements: "Naleving",
          gradedEvaluation: "Beoordeling van de kwaliteit",
          validity: "Effectiviteit",
          intelligentModel: "Intelligent model",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Van toepassing op subjectieve kwaliteitsinspectie (zoals of de houding van de kwaliteitsinspectiedienst enthousiast is en of de dienst geduldig is)",
        },
      },
      clusterAnalysis: "Clusteranalyse",
      speechMining: "Woorden mijnbouw",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "U kunt informatie-entiteiten in batches importeren via de API-interface. Nadat de API-naam is opgeslagen, kan deze niet worden bewerkt.",
        },
        component: { nothing: "Geen" },
      },
      knowledgeBase: {
        index: {
          classification: "Indeling",
          import: "Importeren",
          importKnowledgeBase: "Importeer kennisbasis",
          moveKnowledgePointsInBatch: "Kennispunten voor batchverplaatsing",
          moveTo: "Verplaats:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Selecteer de kennispunten die u in batches wilt verplaatsen",
        },
        detail: {
          index: {
            intrasentence: "Binnen de zin",
            afterProblem: "Na het probleem",
          },
        },
      },
      semanticTags: { component: { nothing: "Geen" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Gevoelige woorden",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Gevoelige woorden: gevoelige woorden kunnen worden geconfigureerd met zakelijke zorgwoorden, gebruikt voor het ontdekken van gevoelige woordwolk door de publieke opinie",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Nieuw Gevoelig Woord",
            editSensitiveWords: "Gevoelige woorden bewerken",
            sensitiveWords: "Gevoelige woorden",
          },
          template: {
            sensitiveWords: "Gevoelige woorden",
            sensitiveWordsCannotBeEmpty: "Gevoelig woord kan niet leeg zijn",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Voer een gevoelig woord in, meerdere gevoelige woorden in het midden ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Selecteer een toepassingsscenario",
            keyWord: "Trefwoorden",
            pleaseEnterKeywords: "Voer een trefwoord in",
            keywordLengthWithinCharacters: "Trefwoordlengte binnen 40 tekens",
            dataSources: "Gegevensbron",
            pleaseSelectADataSource: "Selecteer de gegevensbron",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "De huidige clustering is voltooid. Herbewerking genereert een nieuw stuk clusteringsgegevens en overschrijft de voltooide clusteringsgegevens niet. Gelieve te bevestigen",
            savingSucceeded: "Bespaar met succes",
            clusteringStart: "Clusteren begint",
            clustering: "Clustering",
            edit: "Bewerken",
            newlyBuild: "Nieuw",
            clusterName: "Clusternaam",
            pleaseEnterAName: "Voer een naam in",
            withinCharactersOfTheName: "Naamlengte binnen 40 tekens",
            speaker: "Luidspreker",
            pleaseSelectASpeaker: "Selecteer een luidspreker",
            sessionDate: "Sessiedatum",
            pleaseEnterTheSessionDate: "Voer een sessiedatum in",
            selectUpToMonthOfHistoricalSessionData:
              "Selecteer maximaal 1 maand historische sessiegegevens",
            automaticallyClusterDailyNewData:
              "Automatische clustering van dagelijkse nieuwe gegevens",
            conversationChannel: "Gesprekskanaal",
            pleaseSelectAConversationChannel: "Selecteer een sessiekanaal",
            applicationScenario: "Toepassingsscenario's",
            clusteringRules: "Clusteringsregels",
            incompleteClusteringRuleSettings:
              "De instelling van de clusteringsregel is onvolledig",
            startClustering: "Begin met clusteren",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Selecteer een kwaliteitstaak",
              beEqualTo: "Gelijk",
            },
          },
        },
        detail: {
          index: {
            theme: "Thema",
            numberOfCorpora: "Aantal corpus",
            corpusCoverage: "Corpus dekking",
            clusterDetails: "Details voor clustering",
          },
          ranking: {
            hotWords: "Hete woorden",
            numberOfCorpora: "Aantal corpus",
            speaker: "Luidspreker",
            sit: "Ga zitten.",
            passenger: "Gast",
            hotSentence: "Hete zin",
            corpusDetails: "Corpus Details",
          },
        },
        index: {
          delete: "Verwijderen",
          deletionSucceeded: "Met succes verwijderd",
          clusterName: "Clusternaam",
          speaker: "Luidspreker",
          creationTime: "Creatietijd",
          state: "Status",
          operation: "Operatie",
          see: "Weergave",
          edit: "Bewerken",
          clusterAnalysis: "Clusteranalyse",
          newCluster: "Nieuwe Cluster",
          clusteringStatus: "Clusteringstatus",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Voer trefwoorden in om naar bezwaren te zoeken",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Spraakbibliotheek",
                recommendedScripts: "Aanbevolen woorden",
                common: "Totaal",
                strip: "Artikel",
                scriptFavorites: "Woorden Favorieten",
              },
              conditionPostfix: {
                detectRoles: "Rol detecteren",
                detectionRange: "Detectiebereik",
                sentence: "Zin",
              },
              customerFilter: {
                scriptFiltering: "Spraakfiltering",
                cancel: "Annuleer",
              },
              index: {
                viewDetails: "Details bekijken",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Weet je zeker dat je het wilt verlaten? Je kunt het niet herstellen nadat je het hebt verlaten.",
                abandonment: "Gooi weg",
                deletionSucceeded: "Met succes verwijderd",
                passenger: "Gast",
                sit: "Ga zitten.",
                adopted: "Aangenomen",
                determine: "Bepaal",
                cancel: "Annuleer",
                adopt: "Adoptie",
              },
              pageRightFrom: {
                savingSucceeded: "Bespaar met succes",
                pleaseEnterTheScriptContent: "Voer de spraakinhoud in",
                pleaseSelectAFavoriteGroup:
                  "Selecteer een favoriete groepering",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Bezwaar afhandeling van spraakbibliotheek",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript:
                    "Kies ervoor om spraak aan te nemen",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Bezwaar Omgaan met woorden Favorieten",
            },
            pageRightTable: {
              likeCancel: "Zoals geannuleerd",
              likeSuccessfully: "Ik hou van succes.",
              adoptScripts: "Adopteer spraak",
              operation: "Operatie",
              viewConversationDetails: "Gespreksdetails bekijken",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Product verkoopargument talk bibliotheek",
              },
              pageRightTable: {
                keyPointsOfScript: "Belangrijkste spraakpunten",
              },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Bezwaar Omgaan met woorden Favorieten",
              newGroup: "Nieuwe groep",
              editGroup: "Groepering bewerken",
              addGroup: "Groepering toevoegen",
              savingSucceeded: "Bespaar met succes",
              groupName: "Groepsnaam",
              pleaseEnterTheGroupName: "Voer een groepsnaam in",
              groupNameWithinCharacters: "Groepsnaam lengte binnen 20 tekens",
              deletionSucceeded: "Met succes verwijderd",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings:
                    "Aanbevolen instellingen voor spraak",
                },
                rightForm: {
                  savingSucceeded: "Bespaar met succes",
                  intelligentRecommendation: "Intelligente aanbeveling",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "De aangenomen uitstekende spraakvaardigheden worden automatisch weergegeven in de zijbalk van sessies met vergelijkbare bezwaren, en werknemers kunnen de aanbevolen spraakvaardigheden om te leren direct bekijken op de pagina met de details van de sessiecv.",
                  objectionsIncludedByDefault: "Bezwaar standaard inbegrepen",
                  addRecommendationTriggerSemantics:
                    "Voeg aanbeveling trigger semantiek toe",
                  remindEmployeesToView:
                    "Herinner werknemers eraan om te bekijken",
                  shareWithMoreEmployees: "Deel met meer werknemers.",
                  allHands: "Alle medewerkers",
                },
                smartToolbar: {
                  selectSemanticTag: "Semantisch label selecteren",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Alle gegevensrecords exporteren onder de huidige groepering?",
                newScript: "Nieuwe toespraak",
                editingScript: "Spraak bewerken",
                savingSucceeded: "Bespaar met succes",
                objection: "Bezwaar",
                pleaseEnterAScriptObjection: "Voer een spraakbezwaar in",
                speechTechnique: "Woorden",
                pleaseEnterTheScriptContent: "Voer de spraakinhoud in",
                grouping: "Groeperen",
                pleaseSelectAFavoriteGroup:
                  "Selecteer een favoriete groepering",
                adoptScripts: "Adopteer spraak",
                passenger: "Gast",
                sit: "Ga zitten.",
                numberOfViews: "Aantal weergaven",
                useful: "Nuttig",
                operation: "Operatie",
                edit: "Bewerken",
                delete: "Verwijderen",
                deletionSucceeded: "Met succes verwijderd",
                scriptRecommendationSettings:
                  "Aanbevolen instellingen voor spraak",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Spraakbibliotheek",
          scriptMining: "Woorden mijnbouw",
          objectionHandlingScripts: "Bezwaar hanteren",
          productSellingPointScripts: "Productverkooppunten",
        },
        sellingPoint: {
          index: { newCluster: "Nieuwe Cluster" },
          components: {
            callDetailModal: {
              sessionDetails: "Details van de sessie",
              cancel: "Annuleer",
              determine: "Bepaal",
              choice: "Selecteer",
            },
            pageCenterTable: { scriptOverview: "Spraakoverzicht" },
            pageLeftTable: {
              enterSearchKeywords: "Zoekwoorden invoeren",
              keyWord: "Trefwoorden",
            },
            pageRightTable: {
              index: {
                viewDetails: "Details bekijken",
                scriptLibrary: "Spraakbibliotheek",
                scriptRefinement: "Spraakraffinage",
                adoptScript: "Adopteer spraak",
              },
              tagsEdit: { keyPointsOfScript: "Belangrijkste spraakpunten" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Product verkoopargument talk bibliotheek",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Verkooppunten standaard inbegrepen",
                  },
                },
                index: {
                  keyWord: "Trefwoorden",
                  pleaseSelectAKeyword: "Selecteer een trefwoord",
                  keyPointsOfScript: "Belangrijkste spraakpunten",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Voer de belangrijkste spraakpunten in",
                  viewDetails: "Details bekijken",
                  pleaseChooseToAdoptScript:
                    "Kies ervoor om spraak aan te nemen",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Bijpassende logica",
            nLPMatching: "NLP match",
            strictlyMatchByWord: "Strikte match per woord",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Naam van berichttype",
          messageTypeID: "Type bericht ID",
          messageTypeDescription: "Boodtype Beschrijving",
          applicationMessageType: "Type toepassingsbericht",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Het is van toepassing op toegang tot gegevens van verschillende kanalen voor kwaliteitsinspectie, die moeten worden ingedeeld op basis van kanaalidentificatie.",
        },
        detail: {
          index: {
            messageTypeName: "Naam van berichttype",
            pleaseEnterAMessageTypeName: "Voer een naam van het berichttype in",
            messageTypeDescription: "Boodtype Beschrijving",
            describeYourMessageTypeInDetail:
              "Beschrijf uw berichttype in detail",
            editMessageType: "Berichttype bewerken",
            newMessageType: "Type nieuw bericht",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Selecteer een teamlid",
        existingFilter: "Er zijn al filters.",
        pleaseSelect: "Selecteer alstublieft",
        filterCondition: "Filtratievoorwaarden",
        areYouSureToDeleteTheCurrentFilter:
          "Weet u zeker dat u het huidige filter wilt verwijderen?",
        sessionDate: "Sessiedatum",
        staff: "Werknemer",
        customer: "Klant",
        pleaseEnterCustomerName: "Voer de naam van de klant in",
        keyWord: "Trefwoorden",
        whole: "Allemaal",
        pleaseEnterSessionKeywords: "Voer sessietrefwoorden in",
        sessionDuration: "Sessieduur",
        sessionLabel: "Sessiescenario's",
        pleaseSelectASessionLabel: "Selecteer een sessiescenario",
        commentTopic: "Onderwerp van commentaar",
        pleaseSelectACommentTopic: "Selecteer een commentaaronderwerp",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Weet u zeker dat u het huidige filter wilt overschrijven?",
        overlayFilter: "Afdekfilter",
        NewFilter: "Nieuw filter",
        newFilter: "Nieuw filter",
        filterName: "Filternaam",
        pleaseEnter: "Voer alstublieft in",
        allCall: "Alle oproepen",
        percentage: "Percentage",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "U kunt naar meerdere zoekwoorden zoeken. Gebruik komma's (in het Engels en Chinees) om OF te scheiden en te pauzeren om te scheiden en OK. Bijvoorbeeld:",
          callRecordID: "Oproeplogboek-ID",
          pleaseFillInTheCallRecordID: "Vul de ID van het oproeprecord in",
          minute: "Minuten",
          second: "Seconden",
          whole: "Allemaal",
          callRecording: "Oproepopname",
          enterpriseWechat: "Enterprise WeChat",
          conversationChannel: "Gesprekskanaal",
          pleaseSelectASessionChannel: "Selecteer een sessiekanaal",
          learningStatus: "Leerstaat",
          pleaseSelectLearningStatus: "Selecteer de leerstatus",
          notLearned: "Niet geleerd",
          learned: "Verleend",
          Minutes: "Minuten",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Filterconditie is leeg, nieuw filter wordt niet ondersteund!",
          filterAddedSuccessfully: "Filter met succes toegevoegd!",
          failedToAddFilter: "Filter niet toevoegen!",
          filterDeletedSuccessfully: "Filter met succes verwijderd!",
          filterDeletionFailed: "Filter verwijderen mislukt!",
          filterOverwriteSucceeded: "Filter overschrijven succesvol!",
          filterOverwriteFailed: "Filter override mislukt!",
          theme: "Thema",
          pleaseSelectATheme: "Selecteer een thema",
          keyEvents: "Belangrijkste evenementen",
          pleaseSelectAKeyEvent: "Selecteer een belangrijke gebeurtenis",
        },
        filterItem: {
          startRetesting: "Start opnieuw detectie",
          time: "Tijd",
          talkTime: "Duur van de oproep",
          keyEvents: "Belangrijkste evenementen",
          totalImplementationRateOfSupervisionItems:
            "Algemeen implementatiepercentage van toezichtsposten",
          sort: "Sorteren:",
          seeMore: "Meer bekijken",
        },
        filterStatistics: {
          conversation: "Bel",
          weChat: "WeChat",
          quantity: "Hoeveelheid",
        },
      },
      caseBase: {
        index: {
          copyTo: "Kopiëren",
          pleaseSelectTheLocationToMove:
            "Selecteer een locatie om te verplaatsen!",
          folderCopiedSuccessfully: "Map kopie succesvol!",
          cancelCollectionSuccessfully: "Annuleer de verzameling met succes!",
          collectionSucceeded: "Collectie succes!",
          starCaseBase: "Star case bibliotheek",
          move: "Verplaatsen",
          rename: "Naam wijzigen",
          delete: "Verwijderen",
          folderMovedSuccessfully: "Map succesvol verplaatst!",
          fileMovedSuccessfully: "Bestand succesvol verplaatst!",
          fileMoveFailed: "Bestandsverplaatsing mislukt!",
          folderAddedSuccessfully: "Voeg map met succes toe!",
          folderRenameSucceeded: "Map hernoemd met succes!",
          fileRenameSucceeded: "Bestand succesvol hernoemd!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Nadat u de map hebt verwijderd, kunt u deze niet meer ophalen. Weet je zeker dat je het verwijdert?",
          deleteSucceeded: "Succesvol verwijderd!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Nadat u het bestand hebt verwijderd, kunt u het niet meer ophalen. Weet je zeker dat je het verwijdert?",
          share: "Totaal",
          folders: "Map,",
          calls: "Een oproep",
          newFolder: "Nieuwe map",
          modifyFolder: "Map wijzigen",
          title: "Titel",
          pleaseEnter: "Voer alstublieft in",
          selectMoveLocation: "Selecteer Locatie verplaatsen",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Herinnering aan het leren",
          subscriptionDynamics: "Abonnement Dynamiek",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID:
              "De sessie waarop u bent geabonneerd (ID",
            thereAreDynamicUpdates: "Er is een dynamische update",
            newCommentsReplies: "Voeg opmerkingen/antwoorden toe",
            strip: "Artikel",
            newHelp: "Help toevoegen",
            goAndWatch: "Ga toeschouwers",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Antwoord op mijn opmerkingen",
            replySucceeded: "Antwoord succesvol!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Me in de comments.",
            replySucceeded: "Antwoord succesvol!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Bel voor u om te delen",
            shareCasesForYou: "Deel de zaak voor jou.",
          },
        },
        study: {
          index: {
            learned: "Verleend",
            ignored: "Genegeerd",
            study: "Leren",
            ignore: "Negeren",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Import geslaagd",
          account: "Rekeningnummer",
          password: "Wachtwoord",
          cover: "Cover",
          skip: "Sla over",
          import: "Importeren",
          importEmployees: "Importeer werknemer",
          importPrompt: "Importeer tips",
          confirm: "Bepaal",
          theDataContainsDuplicateAccountsOrNames:
            "Dubbele account of naam bestaat in deze gegevensinvoer",
          allCoverage: "Bedek alles",
          skipAll: "Alles overslaan",
          headPortrait: "Avatar",
          fullName: "Naam",
          nickname: "Bijnaam",
          excellentStaff: "Uitstekend personeel",
          yes: "Ja",
          no: "Nee",
          department: "Afdeling",
          accountNumberemail: "Rekeningnummer (e-mail)",
          telephone: "Telefoon",
          jobNumber: "Werk nr.",
          role: "Rol",
          pleaseEnterANameOrPhoneSearch:
            "Voer een e-mail, naam of telefoonzoekopdracht in",
          person: "Mensen)",
          AddEmployee: "Werknemer toevoegen",
        },
        edit: {
          jobNo: "Werk nr.",
          udeskCustomerServiceSystemIdentity:
            "Udesk klantenservice systeem identiteit",
          enterpriseWechatIdentity: "Enterprise WeChat-identiteit",
          inputID: "Input-ID",
          newIdentityBinding: "Nieuwe identiteitsbinding",
          identityBinding: "Identiteitsbinding",
          avatarGreaterThan500kb: "Avatar groter dan 500Kb",
          uploadSuccessful: "Succesvol uploaden",
          upload: "Uploaden",
          supportJPGGIFAndPNGUpTo500kb:
            "Ondersteuning jpg, gif, png, maximaal 500Kb",
          yes: "Ja",
          no: "Nee",
          accountNumber: "Rekeningnummer",
          pleaseEnterTheAccountNumber: "Voer het rekeningnummer in",
          initialPassword: "Eerste wachtwoord",
          pleaseInputAPassword: "Voer het wachtwoord in",
          confirmPassword: "Wachtwoord bevestigen",
          thePasswordsEnteredTwiceAreDifferent:
            "Het tweemaal ingevoerde wachtwoord is niet hetzelfde!",
          headPortrait: "Avatar",
          fullName: "Naam",
          pleaseEnterYourName: "Voer een naam in",
          nickname: "Bijnaam",
          department: "Afdeling",
          excellentStaff: "Uitstekend personeel",
          sessionIdentity: "Sessie Identiteit",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Het record van de tagsessie is van de werknemer",
          telephone: "Telefoon",
          jobNumber: "Werk nr.",
          role: "Rol",
          pleaseSelectARole: "Selecteer een rol",
          preservation: "Bespaar",
          cancel: "Annuleer",
          addedSuccessfully: "Succesvol toegevoegd!",
          modificationSucceeded: "Met succes gewijzigd!",
          newEmployees: "Nieuwe werknemer",
          editEmployee: "Werknemer bewerken",
        },
        detail: {
          viewDetails: "Details bekijken",
          accountNumber: "Rekeningnummer",
          headPortrait: "Avatar",
          fullName: "Naam",
          nickname: "Bijnaam",
          department: "Afdeling",
          excellentStaff: "Uitstekend personeel",
          yes: "Ja",
          no: "Nee",
          sessionIdentity: "Sessie Identiteit",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Het record van de tagsessie is van de werknemer",
          telephone: "Telefoon",
          jobNumber: "Werk nr.",
          role: "Rol",
        },
      },
      roleManage: {
        list: {
          menu: "Menu",
          functionalCategory: "Functionele categorieën",
          functionalSubclass: "Functie Subklasse",
          deletedSuccessfully: "Met succes verwijderd",
          NewRole: "Nieuwe rol",
          role: "Rol",
          dataPermissions: "Gegevensmachtigingen:",
          edit: "Bewerken",
          areYouSureYouWantToDeleteTheRole: "OK om de rol te verwijderen",
          determine: "Bepaal",
          cancel: "Annuleer",
          delete: "Verwijderen",
          applicationPermission: "Machtigingen toepassen",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "U kunt alleen uw eigen sessiegegevens zien",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "U kunt de sessiegegevens van de afdeling en ondergeschikte afdelingen bekijken",
          menu: "Menu",
          functionalCategory: "Functionele categorieën",
          functionalSubclass: "Functie Subklasse",
          successfullyAdded: "Nieuw succes",
          modifiedSuccessfully: "Wijziging geslaagd",
          edit: "Bewerken",
          cancel: "Annuleer",
          confirm: "Bevestigen",
          roleName: "Rolnaam",
          pleaseEnterTheRoleName: "Voer een rolnaam in",
          dataAuthority: "Gegevensmachtigingen",
          youCanViewCompanyWideSessionData:
            "Kan bedrijfsbrede sessiegegevens bekijken",
          applicationPermission: "Machtigingen toepassen",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Sessieproces",
          generateProcess: "Generatieproces",
          addProcess: "Proces toevoegen",
          cancel: "Annuleer",
          complete: "Compleet",
          edit: "Bewerken",
          theSettingWillBeSuccessfulLater: "Ingesteld om later succes te zien!",
          previous: "Vorige artikel",
          next: "Volgende artikel",
          debuggingMode: "Debug-modus",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Nadat het bericht is geactiveerd en overeenkomt met de regels van belangrijke gebeurtenissen, monitoringitems, klanttags of kennispunten, wordt de naam weergegeven van de regel waarmee het bericht overeenkomt en de kennispuntnaam van de kennisbank.",
          seeLater: "Kijk later",
          determine: "Bepaal",
          conversationalInsights: "Sessie Insights",
          customerInsight: "Klantinzichten",
          knowledgePointRecommendation: "Kennispuntaanbeveling",
          comment: "Opmerkingen",
          sessionQuality: "Sessiekwaliteit",
          setToListenLaterSuccessfully:
            "Ingesteld om later naar succes te luisteren!",
          listenLater: "Luister later",
          seekCounselingSuccess: "Zoek succes bij coaching!",
          altogether: "Een totaal",
          appearsAt: "Verschijnen'",
          commentsPublishedSuccessfully: "Opmerkingen met succes geplaatst",
          callRecording: "Oproepopname",
          addedSuccessfully: "Succesvol toegevoegd",
          shareWithColleagues: "Delen met collega's",
          shareWithCustomers: "Deel met klanten",
          resumptionDetails: "Details van de nieuwe aanbieding",
          addToCaseBase: "Bibliotheek Toevoegen aan Case",
          shareCases: "Gevallen delen",
          seekCounseling: "Zoek advies",
          sessionRecord: "Sessierecord",
          businessRecord: "Bedrijfsrecords",
          keywordSearch: "Zoekwoord zoeken",
          whole: "Allemaal",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Voer een opmerking in, voer @ in om anderen op de hoogte te stellen, voer # in om een thread toe te voegen",
          subscriptionSession: "Abonneren op sessie",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Na het abonnement, wanneer er nieuwe hulp, opmerkingen en antwoorden zijn, ontvangt u meldingen en herinneringen.",
        },
      },
      coachTask: {
        index: {
          employeeName: "Naam werknemer",
          customerName: "Naam van de klant",
          source: "Bron",
          viewSessionRecords: "Sessieverslagen bekijken",
          coachingStatus: "Coachingstatus",
          helpTime: "Tijd voor hulp",
          counselingTime: "Tijd voor counseling",
          helpContent: "Help-inhoud",
          coachingOperation: "Coaching operatie",
          areYouSureYouWantToIgnoreIt: "Weet je zeker dat je het wilt negeren?",
          determine: "Bepaal",
          cancel: "Annuleer",
          ignoreHelp: "Negeer hulp",
          coaching: "Coaching uitvoeren",
          viewCoaching: "Coaching bekijken",
          areYouSureToReturnToCounseling:
            "Weet je zeker dat je terugkeert naar counseling?",
          returnToPendingCoaching: "Hervat door met counseling",
          tutor: "Raadsman",
          helpSeekingStatus: "Hulpstatus",
          recentCoachingTime: "Recente counseling tijd",
          operation: "Operatie",
          ignoreSuccess: "Negeer succes!",
          recoverySucceeded: "Herstel succesvol!",
          successfulCoaching: "Succes bijsturen!",
          myMission: "Mijn missie",
          selectCoachingStatus: "Coachingstatus selecteren",
          whole: "Allemaal",
          iAskedForHelp: "Ik vroeg om hulp",
          signOut: "Uitgang",
          submit: "Indiening",
          helpPerson: "Vraag om hulp",
          customer: "Klant",
          toBeCoached: "Om begeleid te worden",
          counselingSuggestions: "Advies counseling",
          pleaseEnterCoachingSuggestions: "Voer suggesties voor coaching in",
        },
      },
      coachTaskCenter: {
        title: "Missiecentrum",
        list: {
          taskName: "Naam van de taak",
          lessonGroup: "Cursusclassificatie",
          lessonName: "Cursus",
          taskType: "Taaktype",
          taskStatus: "Taakstatus",
          taskExecutionInfo: "Implementatiestatus",
          totalCount: "Aantal te executeren personen",
          executeCount: "Aantal uitgevoerd",
          executeRate: "Implementatiegraad",
          completeCount: "Aantal voltooid",
          completeRate: "Volgingspercentage",
          commitCount: "Aantal bereiken",
          commitRate: "Nalevingspercentage",
          excellentRate: "Uitstekend tarief",
          taskTime: "Taaktijd",
          option: "Operatie",
          edit: "Bewerken",
          taskDetail: "Taakgegevens",
          delete: "Verwijderen",
          new: "Nieuwe publicatie",
          taskTypeAll: "Allemaal",
          taskTypePractice: "Oefening",
          taskTypeTest: "Onderzoek",
          taskStatusNotStarted: "Niet begonnen",
          taskStatusRunning: "In uitvoering",
          taskStatusEnd: "Eindigde",
          taskStatusInvalid: "Niet van kracht",
          unlimitedTime: "Onbeperkte tijd",
          to: "Naar",
          deleteConfirm:
            "Na verwijdering worden alle leerrecords onder deze taak gewist. Weet je zeker dat je ze verwijdert?",
          deleteSuccess: "Taak met succes verwijderd",
        },
        edit: {
          addTask: "Nieuwe publicatietaak",
          editTask: "Publicatietaak bewerken",
          publishSuccess: "Met succes gepubliceerd",
          publishFailed: "Publiceren mislukt",
          updateSuccess: "Update geslaagd",
          updateFailed: "Update mislukt",
          cannotBeNull: "Kan niet leeg zijn",
          explain: "Beschrijving",
          taskDescExtra: "(Dit wordt weergegeven bij de {0} ingang)",
          taskTargetObj: "Objecten publiceren",
          isEnable: "Effectieve status",
          dialogueMode: "Dialoogmodus",
          voice: "Stem",
          text: "Tekst",
          userPortrait: "Gebruikersportret",
          difficultyLevel: "Moeilijkheidsgraad",
          limitDuration: "Beperking van de duur",
          tipLabel: "Tips",
          tipTooltip:
            "Sta afgevaardigden toe om referentietoespraak te bekijken",
          limitTimes: "Aantal examens",
          time: "Tijd",
          gradingStandart: "Scorecriteria",
          gradingStandartDescAffix: "(Het huidige gespreksproces is verdeeld",
          gradingStandartDescSuffix:
            "Punten, spraakvaardigheid {0} punten, stemming {1} punten, snelheid {2} punten)",
          completeStandart: "Vereisten voor het voltooien van taken",
          byTimes: "Op aantal keren",
          byScore: "Door score",
          completeByTimes: "Aantal oefeningen",
          times: "Tijden",
          score: "Punten",
          anyTimes: "Elke keer",
          getScore: "Score",
          offLimitDuration: "Onbeperkte duur",
          onLimitDuration: "Beperkte tijdsduur",
          commitScore: "Standaard, score",
          excellentScore: "Uitstekende standaard, score",
          offLimitTime: "Onbeperkte tijd",
          onLimitTime: "Beperkte tijd",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Hoi," },
          callDynamic: {
            today: "Vandaag",
            yesterday: "Gisteren.",
            sessions: "Een sessie",
          },
          todoList: {
            strip: "Artikel",
            theSessionIsAddedTolistenLater:
              'Sessie wordt samengevoegd "Luister later"',
            askYouForHelp: "Vraag je om hulp",
            iHopeToContactYouLater: "Verwacht later contact op te nemen",
            lookingForwardToSendingTheInformationLater:
              "Verwacht later materiaal te verzenden",
            dialogueRisk: "Gesprek heeft risicopunten",
            tutored: "Gecoacht",
            goToCounseling: "Ga naar counseling",
            contacted: "Contacten",
            hasBeenSent: "Verzonden",
            areYouSureYouWantToIgnoreIt:
              "Weet je zeker dat je het wilt negeren?",
            determine: "Bepaal",
            cancel: "Annuleer",
            ignore: "Negeren",
            checked: "Bekeken",
          },
          callListItem: {
            strip: "Artikel",
            learned: "Verleend",
            keyEvents: "Belangrijkste evenementen",
            totalImplementationRateOfSupervisionItems:
              "Algemeen implementatiepercentage van toezichtsposten",
          },
        },
        index: {
          operationSucceeded: "Operatie succesvol!",
          sessionDynamics: "Sessie dynamiek",
          my: "Mijn",
          department: "Afdeling",
          company: "Bedrijf",
          toDoList: "Te doen",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Effectief belvolume (eenheid)",
          bestPractices: "Beste praktijken",
          averageDurationOfASingleCallminutes:
            "Gemiddelde duur van één oproep (minuten)",
          totalCallVolumePCs: "Totaal aantal oproepen (eenheid)",
          totalTalkTimehours: "Totale gespreksduur (uren)",
          speakingPercentagepercentage: "Sprekend percentage (percentage)",
          maximumContinuousSpeakingTimeminutes:
            "Maximale continue spreektijd (minuten)",
          numberOfIndepthDiscussionsPCs:
            "Aantal diepgaande discussies (eenheid)",
          maximumCustomerTalkminutes: "Maximaal spreken van klanten (minuten)",
          questionFrequencyPCs: "Vraagfrequentie (eenheid)",
          averageValue: "Gemiddeld",
          selectDate: "Datum selecteren",
          personnel: "Personeel",
          pleaseSelect: "Selecteer alstublieft",
          callRange: "Oproepbereik",
          conversationActivity: "Sessie Activiteit",
          conversationAction: "Sessie Actie",
          keyEvents: "Belangrijkste evenementen",
          callRecord: "Oproepopname",
          filter: "Filter:",
          conversation: "Bel",
          minute: "Minuten",
          sale: "Werknemer",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Januari",
            numberOfCalls: "Aantal oproepen",
            accountInformation: "Accountgegevens",
            month: "Maand",
            today: "Vandaag",
            customerDetails: "Details van de klant",
            searchKeywordsInCommunicationContent:
              "Zoekwoorden in communicatie-inhoud zoeken",
            allMembers: "Alle leden",
            reset: "Reset",
            query: "Vraag",
            allTrip: "Volledige Journey Show",
            contactOverview: "Contactoverzicht",
            lastContact: "Laatste contact",
            nextContact: "Volgende Contact",
            previous: "Vorige artikel",
            next: "Volgende artikel",
            viewDetails: "Details bekijken",
            contactDynamics: "Neem contact op met dynamisch",
            lastYear: "Vorig jaar",
            lastCall: "Laatste oproep",
            hotTopicsForDiscussion: "Hot topic van discussie",
            totalInTheLastYear: "In het afgelopen jaar helemaal.",
            callTimes: "Secundaire oproep",
          },
        },
        list: {
          index: {
            risk: "Risico",
            have: "Er zijn",
            nothing: "Geen",
            channel: "Kanaal",
            callRecording: "Oproepopname",
            enterpriseWeChatConversation: "Enterprise microsessie",
            enterpriseWeChatCall: "Enterprise Micro-Talk",
            followUpSuggestions: "Vervolgaanbevelingen",
            customer: "Klantencentrum",
            followUp: "Follow-up",
            personInCharge: "De verantwoordelijke persoon",
            recentFollowup: "Onlangs gevolgd.",
            lastContactTime: "Laatste contacttijd",
            operation: "Operatie",
            see: "Weergave",
            exceed: "Meer",
            noActivityForMoreThanDays: "Dagen zonder activiteit",
            lately: "Onlangs",
            risksOccurredInTheLastDays:
              "Risicosemantiek treedt binnen enkele dagen op",
            savedSuccessfully: "Bespaar met succes",
            today: "Vandaag",
            allDepartments: "Alle Afdelingen",
            allMembers: "Alle leden",
            customerName: "Naam van de klant",
            reset: "Reset",
            query: "Vraag",
            customers: "Klant",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Toon het contact tussen medewerkers en klanten, waarbij roze bubbels klantberichten zijn en blauwe bubbels medewerkersberichten. Hoe groter het aandeel van toespraken, hoe groter de bubbels.",
            riskAllocation: "Risicoconfiguratie",
            preservation: "Bespaar",
            pleaseEnter: "Voer alstublieft in",
            noActivityForDays: "Dagen zonder activiteit",
            riskSemanticsWithinDays:
              "Risicosemantiek treedt binnen enkele dagen op",
            addRiskSemantics: "Risico-semantiek toevoegen",
            semanticTags: "Semantische labels:",
            standardSentence: "Standaardzin:",
          },
          components: {
            advancedFilter: {
              components: {
                list: {
                  index: { addNewConditions: "Voeg nieuwe voorwaarde toe" },
                },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Voldoen aan alle volgende voorwaarden",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Filter",
                pleaseSelectFilteringCriteria: "Selecteer filtercriteria",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Wijzig de opportuniteitsfase",
              theCurrentStageIs: "De huidige fase is schik",
              ConfirmToChangeItTo: "], Bevestig om het te veranderen.",
              IsIt: "]?",
              StageCoStay: "] Fase totaal verblijf",
              day: "Dag",
              start: "Begin",
              end: "Einde",
              orderInput: "Bestelling verliezen",
              restart: "Opnieuw opstarten",
              pleaseConfigureTheSalesStageInformation:
                "Configureer de informatie over de verkoopfase",
              reasonForDocumentInput:
                "Reden voor het verliezen van de bestelling:",
              completeOrderInput: "Doc volledig verliezen",
              pleaseSelectTheReason: "Selecteer een reden",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "Het management beveelt aan:",
              },
              index: {
                sessionDetails: "Details van de sessie",
                customerDynamics: "Klantdynamiek",
                riskPoint: "Risicopunt",
                followUpSuggestions: "Vervolgaanbevelingen",
              },
              sessionDetails: {
                common: "Totaal",
                currentlyViewingThe: "Momenteel Bekijk nr.",
                sessions: "Een sessie",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "De huidige fase is gestopt",
              day: "Dag",
            },
          },
          business: { index: { reEnableOpportunity: "Opnieuw inschakelen" } },
          customerTag: { index: { label: "Label" } },
          moreCustomer: {
            index: {
              essentialInformation: "Basisinformatie",
              cancel: "Annuleer",
              confirm: "Bevestigen",
              customer: "Klant",
              currentFollowupStage: "Huidige vervolgfase",
              telephone: "Telefoon",
              followUpDays: "Vervolgdagen",
              day: "Dag",
              remarks: "Opmerkingen",
              channelPreference: "Kanaalvoorkeur",
              hello: "Hallo.",
              customFields: "Aangepaste velden",
              opportunityAmount: "Opportuniteitsbedrag",
              focus: "Bezorgdheid",
              customerInformation: "Klantinformatie",
              viewMoreCustomerInformation: "Bekijk meer klantinformatie",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Belangrijkste evenementen:" },
                row1: {
                  customerDynamics: "Klantdynamiek",
                  common: "Totaal",
                  barDynamic: "Dynamisch",
                },
                row2: {
                  riskPoint: "Risicopunt",
                  common: "Totaal",
                  risks: "Artikel Risico",
                },
                row3: {
                  followUpSuggestions: "Vervolgaanbevelingen",
                  intelligentRecommendation: "Intelligente aanbeveling",
                  strip: "Artikel",
                  managersSuggestATotalOf: "De manager beveelt een totaal aan",
                  recommendedByManagers: "Manager aanbevolen",
                  creationTime: "Creatietijd",
                  administratorSuggestions: "Advies van de beheerder",
                  suggestionsForNewManagers: "Advies van nieuwe manager",
                },
              },
            },
            index: { typeTime: "Type/Tijd" },
          },
          userInfo: {
            index: {
              personInCharge: "De verantwoordelijke persoon",
              recentFollower: "Onlangs gevolgd.",
              startTime: "Eerste tijd van bereik",
              followUpDays: "Vervolgdagen",
              day: "Dag",
              remarks: "Opmerkingen",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Teamleden",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Het totale aantal sessies dat leden van anderen hebben geleerd (de-duplicatie). Het opzoeken van de sessiedetails en het klikken op de opname om af te spelen, wordt beschouwd als een keer leren.",
          totalNumberOfStudies: "Totaal aantal leren",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Het totale aantal opmerkingen en antwoorden geïnitieerd door leden op andermans sessies (ontdubbeling)",
          totalNumberOfInteractions: "Totaal aantal interacties",
          learnTheSumOfConversationCallDuration:
            "Som van de geleerde duur van de sessieoproep",
          lengthOfStudy: "Leerduur",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Het totale aantal verzoeken om hulp van leden voor andermans sessies (de-duplicatie)",
          totalNumberOfRequests: "Totaal aantal hulpverzoeken",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Totaal aantal leden counseling voor hulp van anderen (ontdubbeling)",
          totalCounseling: "Totaal aantal tutorials",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Het totale aantal zittingen van leden aan anderen toegevoegd aan de casebibliotheek (de-duplicatie)",
          addCase: "Geval toevoegen",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Totaal aantal lidabonnementen op andermans sessies (ontdubbeling)",
          subscribe: "Abonnement",
          totalNumberOfSessionsCommunicatedByMembers:
            "Totaal aantal door leden gecommuniceerde sessies",
          totalSessions: "Totaal aantal sessies",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Het totale aantal leden dat hun sessies opnieuw afspeelt (de-duplicatie), de sessiedetails opzoekt en op de opname klikt om af te spelen, wordt beschouwd als een keer opnieuw afspelen.",
          totalNumberOfDoubleDisk: "Totaal aantal resdisks",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Totaal aantal reswks/totaal aantal sessies * 100%",
          proportionOfDoubleDisk: "Opnieuw aandeel",
          summationOfConversationCallDurationOfDuplicateDisk:
            "De som van de gespreksduur van de dubbele schijf.",
          resumptionDuration: "Duur voor heraanpassing",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Het totale aantal leden dat hulp heeft geïnitieerd voor hun eigen sessies (de-duplicatie)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Het totale aantal opmerkingen (inclusief antwoorden) ontvangen door anderen voor de ledenzitting",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Totaal aantal door anderen aan de casebibliotheek toegevoegde ledenzittingen (de-duplicatie)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Totaal aantal door anderen ingeschreven ledensessies (ontdubbeling)",
          askForHelpInTheLastMonth: "Vorige maand voor hulp.",
          lastTimeForHelp: "Laatste keer om hulp te vragen",
          operation: "Operatie",
          viewAllSessionsInTheLastMonth:
            "Bekijk alle sessies van de laatste maand",
          allDepartments: "Alle Afdelingen",
          reset: "Reset",
          query: "Vraag",
          altogether: "Een totaal",
          secondSession: "Secundaire sessie",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Huidig Testproject:",
            supervisionItem: "Toezichtartikelen",
            keyEvents: "Belangrijkste evenementen",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Controleer de monitoringitems of de belangrijkste gebeurtenissen die u wilt testen en zorg ervoor dat er gespreksinhoud is in het gesimuleerde gesprek",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Uitvoeringsanalyse" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Proces 1",
              technologicalProcess: "Proces",
              newProcess: "Nieuw proces",
            },
          },
          index: {
            upload: "Uploaden",
            preservation: "Bespaar",
            newlyBuild: "Nieuw",
            cancel: "Annuleer",
            name: "Naam",
            describe: "Beschrijving",
            type: "Soort",
            classification: "Indeling",
            content: "Inhoud",
            enclosure: "Bijlage",
            knowledgePointAssociation: "Kennispuntenvereniging",
            open: "Open",
            close: "Sluiten",
            operator: "Exploitant",
            logic: "Logica",
            see: "Weergave",
            edit: "Bewerken",
          },
        },
        index: {
          name: "Naam",
          describe: "Beschrijving",
          createdBy: "Schepper",
          type: "Soort",
          lastUpdated: "Laatste Update Tijd",
          numberOfReferences: "Aantal referenties",
          operation: "Operatie",
          see: "Weergave",
          edit: "Bewerken",
          delete: "Verwijderen",
          categoryDeletedSuccessfully: "Categorie met succes verwijderd",
          classificationOfKnowledgePoints: "Classificatie van kennispunten",
          newlyBuild: "Nieuw",
          knowledgeBase: "Kennisbasis",
          knowledgePoints: "Kennispunten",
          technologicalProcess: "Proces",
          keyword: "Trefwoord",
          batchExport: "Batch Export",
          batchDelete: "Batch verwijderen",
          importProcess: "Importproces",
          importKnowledgePoints: "Importeer kennispunten",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Als het kennispunt niet bestaat in de kennisbank, voegt u het kennispunt rechtstreeks toe; als het bestaat, sla het dan over en werk het niet bij.",
          editKnowledgePointClassification:
            "Indeling van kennispunten bewerken",
          newKnowledgePointClassification: "Nieuwe kennispuntclassificatie",
          categoryEditingSucceeded: "Succes voor het bewerken van categorieën",
          newClassificationSucceeded: "Nieuwe categorie met succes",
          classificationName: "Categorienaam",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Toezicht van de uitvoerende macht" },
      },
      topicCallout: {
        index: {
          whole: "Allemaal",
          date: "Datum",
          dimensionStatus: "Oproepstatus",
          callRecordID: "Oproeplogboek-ID",
          callRecordIDMustBeNumeric: "Oproeplog-ID moet een nummer zijn",
          strip: "Artikel",
          topicAnnotation: "Onderwerpoproep",
          annotated: "Gelabeld",
          stripNotMarked: "Bar, zonder label",
          barInDimension: "Bars, in callout",
          customer: "Klant",
          sale: "Verkoop",
          common: "Totaal",
          sessions: "Sessies",
          currentTopic: "Huidig onderwerp:",
          staff: "Werknemer",
          topicOfConversation: "Onderwerp",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Operatie geslaagd, call-out reset",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Na het resetten worden alle labelrecords van deze sessie gewist. Gelieve te bevestigen",
          resetAll: "Alles resetten",
          pleaseSelectTheTopicToBeMarked:
            "Selecteer een onderwerp om te labelen",
          pleaseTickTheSentenceToBeMarked:
            "Controleer de zin die moet worden gemarkeerd",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Operatie is geslaagd, label is van kracht geworden",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "De reikwijdte van deze annotatie omvat berichten van andere onderwerpen. Klik op OK om ze te overschrijven.",
          confirmDimensions: "Bevestig de oproep",
          talkTime: "Spreektijd",
          callID: "Bel-ID",
        },
      },
      topicList: {
        index: {
          callID: "Bel-ID",
          latestMarkedTime: "Nieuwste markeringstijd",
          topicOfConversation: "Onderwerp",
          numberOfSentences: "Aantal zinnen",
          sentence: "Zin",
          operation: "Operatie",
          see: "Weergave",
          delete: "Verwijderen",
          edit: "Bewerken",
          newSubTopic: "Nieuw subonderwerp",
          move: "Verplaatsen",
          whole: "Allemaal",
          topicDeletedSuccessfully: "Onderwerp met succes verwijderd",
          newlyBuild: "Nieuw",
          topicList: "Onderwerplijst",
          callIDMustBeNumeric: "Bel-ID moet een nummer zijn",
          leadInTopic: "Onderwerp importeren",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Als de categorienaam duplicaat is, wordt de oorspronkelijke onderwerpcategorie niet overschreven en wordt het item overgeslagen en niet geïmporteerd.",
          mobileClassification: "Mobiele classificatie",
          editTopic: "Onderwerp bewerken",
          newTopic: "Nieuw onderwerp",
          topicName: "Naam van het onderwerp",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Wijziging geslaagd",
            createdSuccessfully: "Succesvol gemaakt",
            modificationFailed: "Wijziging mislukt",
            creationFailed: "Kan niet worden gemaakt",
            monitoringName: "Monitoringnaam",
            upToCharacters: "Tot 40 tekens",
            pleaseEnterAName: "Voer een naam in",
            monitoringIndicators: "Monitoring index",
            sessionActivity: "Sessie Activiteit",
            conversationAction: "Sessie Actie",
            keyEvents: "Belangrijkste evenementen",
            supervisionItems: "Toezichtartikelen",
            monitoringMembers: "Monitoring van leden",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Groepen die begeleiding, training of managementinterventies ontvangen",
            groupA: "Groep A",
            pleaseSelectGroupAData: "Selecteer Groepsgegevens A",
            thereAreDuplicateUsersInGroupB: "Dubbele gebruikers in groep B",
            groupARemarks: "Groep A opmerkingen",
            controlGroupGroupComparedWithGroupA:
              "Controlegroep, vergeleken met groep A",
            groupB: "Groep B",
            pleaseSelectGroupBData: "Selecteer groep B-gegevens",
            thereAreDuplicateUsersInGroupA:
              "Er zijn dubbele gebruikers in groep A",
            groupBRemarks: "Opmerkingen van groep B",
            monitoringPeriod: "Bewakingscyclus",
            startNode: "Start knooppunt",
            monitoringScope: "Bewakingsbereik",
            pleaseSelectMonitoringConditions:
              "Selecteer de bewakingsvoorwaarden",
            pleaseEnterANumber: "Voer een nummer in",
            pleaseSelectATimePeriod: "Selecteer een tijdsperiode",
            preservation: "Bespaar",
            cancel: "Annuleer",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Met succes verwijderd",
            notStarted: "Niet begonnen",
            haveInHand: "In uitvoering",
            closed: "Eindigde",
            newGroupTest: "Nieuwe groepstest",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Verkoopfase",
        communicationStrategy1: "Communicatiestrategie",
        riskAllocation: "Risicoconfiguratie",
        businessConfiguration: "Zakelijke configuratie",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Bezwaar hanteren",
                  productSellingPointScript: "Productverkooppunten",
                  caseGrouping: "Geval groeperen",
                  pleaseSelectExcellentCases: "Selecteer een goede zaak.",
                  pleaseSelectKnowledgePoints: "Selecteer kennispunten",
                  associativeLinks: "Bijbehorende links",
                  jumpTo: "Spring",
                  jumpType: "Springtype",
                  knowledgePoints: "Kennispunten",
                  excellentCases: "Uitstekend Geval",
                  excellentScript: "Uitstekende toespraak",
                  pleaseSelectASingleKnowledgePoint:
                    "Selecteer een enkel kennispunt",
                },
              },
            },
            index: {
              name: "Naam",
              pushText: "Push-tekst",
              enableStatus: "Ingeschakelde status",
              triggerRules: "Trigger regel",
              communicationStrategy: "Communicatiestrategie",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "Groep voor communicatiestrategie",
              name: "Naam",
              pushText: "Push-tekst",
              effectiveness: "Effectiviteit",
              openSuccessfully: "Open met succes",
              closeSuccessfully: "Met succes gesloten",
              operationFailed: "Operatie mislukt",
              operation: "Operatie",
              edit: "Bewerken",
              delete: "Verwijderen",
              newlyBuild: "Nieuw",
              communicationStrategy: "Communicatiestrategie",
              editGroup: "Groepering bewerken",
              groupName: "Groepsnaam",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Alle sessies",
                  recentDays: "Recente dagen",
                  recentSessions: "Recente sessies",
                  scopeOfApplication: "Toepassingsgebied:",
                  day: "Dag",
                  through: "Pass",
                },
              },
            },
            index: {
              describe: "Beschrijving",
              applicableCustomers: "Van toepassing op klanten",
              enableStatus: "Ingeschakelde status",
              triggerRules: "Trigger regel",
              riskRules: "Risicoregels",
            },
          },
          list: {
            index: {
              riskAllocation: "Risicoconfiguratie",
              keyWord: "Trefwoorden",
              newRiskRule: "Nieuwe risicoregel",
              riskRuleName: "Naam risicoregel",
              describe: "Beschrijving",
              state: "Status",
              openSuccessfully: "Open met succes",
              closeSuccessfully: "Met succes gesloten",
              operationFailed: "Operatie mislukt",
              operation: "Operatie",
              edit: "Bewerken",
              delete: "Verwijderen",
              deletionSucceeded: "Met succes verwijderd",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Bestelling verliezen" },
              endReasonList: {
                addEndReason: "Einde toevoegen Reden",
                enterEndReason: "Voer de eindreden in",
              },
              stageList: {
                keyEvents: "Belangrijkste evenementen",
                waysideData: "Gegevens over de weg volgen",
                includeAny: "Bevat elke",
                includeAll: "Bevat alle",
                pleaseEnterTheStageName: "Voer de fasenaam in",
                setStageRules: "Stadiumregels instellen",
                stageName: "Fasenaam",
                operator: "Exploitant",
                logic: "Logica",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Tijdtype veldinvoerformaat: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Verzoek mislukt, probeer het later opnieuw",
              salesStage: "Verkoopfase",
              essentialInformation: "Basisinformatie",
              name: "Naam",
              applicableDepartments: "Toepasselijke afdeling",
              applicableToOthers: "Van toepassing op andere",
              manualChangePhase: "Handmatige verandering fase",
              enableStatus: "Ingeschakelde status",
              describe: "Beschrijving",
              opportunityStageSettings: "Opportunity-fase-instellingen",
              stageSettings: "Fase-instellingen",
              aliasSettings: "Alias-instellingen",
              closeOpportunity: "Opportunity beëindigen",
              newSuccessfully: "Nieuw succes",
              newFailed: "Nieuw mislukt",
              savingSucceeded: "Bespaar met succes",
              saveFailed: "Opslaan mislukt",
            },
          },
          list: {
            index: {
              salesStage: "Verkoopfase",
              keyWord: "Trefwoorden",
              newSalesStage: "Nieuwe verkoopfase",
              salesStageName: "Naam verkoopfase",
              describe: "Beschrijving",
              state: "Status",
              openSuccessfully: "Open met succes",
              closeSuccessfully: "Met succes gesloten",
              operationFailed: "Operatie mislukt",
              operation: "Operatie",
              edit: "Bewerken",
              delete: "Verwijderen",
              deletionSucceeded: "Met succes verwijderd",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Grote modelgeneratie",
            conversationInsights: "Sessie Insights",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Automatisch gegenereerd" },
              },
              hooks: {
                conversationSummary: "Samenvatting van de sessie",
                conversationProcess: "Sessieproces",
                opportunityPoints: "Kansenpunt",
              },
              prompt: {
                index: {
                  edit: "Bewerken",
                  restoreDefault: "Standaard herstellen",
                  cancel: "Annuleer",
                  confirm: "Bevestigen",
                },
              },
            },
            index: {
              saveSuccessful: "Bespaar met succes",
              hintLanguage: "Prompt",
              preserve: "Bespaar",
              cancel: "Annuleer",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Grote modelgeneratie",
        salesStage: "Verkoopfase",
        communicationStrategy: "Communicatiestrategie",
        riskAllocation: "Risicoconfiguratie",
        businessConfiguration: "Zakelijke configuratie",
      },
      salesLearningCenter: {
        knowledgeBase: "Kennisbasis",
        speechCorpus: "Spraakbibliotheek",
        learningCenter: "Leercentrum",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "Klantinformatie" } },
        },
        index: { otherInformation: "Andere Informatie" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Importeren" } } },
        hooks: {
          index: {
            all: "Allemaal",
            intelligentModel: "Intelligent model",
            smartRules: "Slimme regels",
            manualScoring: "Handmatige Scoring",
            enable: "Inschakelen",
            notEnabled: "Niet ingeschakeld",
            voiceCall: "Spraakoproep",
            textDialogue: "Tekst dialoog",
            ecommerceTextSession: "E-commerce tekstsessie",
            workOrder: "Werkorder",
          },
        },
        index: {
          ruleName: "Regelnaam",
          ruleType: "Regeltype",
          pleaseSelect: "Selecteer alstublieft",
          ruleStatus: "Regelstatus",
          dataSourceType: "Type gegevensbron",
          voiceCall: "Spraakoproep",
          textDialogue: "Tekst dialoog",
          ecommerceTextSession: "E-commerce tekstsessie",
          workOrder: "Werkorder",
          status: "Status",
          operation: "Operatie",
          edit: "Bewerken",
          ruleTemplate: "Regelsjabloon",
        },
      },
      analysisReportTitle: "Analyserapport",
      ruleTest: "Regeltest",
      intelligentTags: {
        labelDisplayQuantity: "Aantal labelweergave",
        unlimited: "Onbeperkt",
        ignoringTagLibraries: "Negeer tagbibliotheek",
        automaticallyGenerateLabels: "Labels automatisch genereren",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "Nadat de sessie is ingeschakeld, worden tags automatisch gegenereerd op basis van het grote model en worden de gegenereerde tags standaard geclassificeerd onder Niet geclassificeerd.",
        defaultValidity: "Default effectivity",
        supervisionItem: "Toezichtartikelen",
        customerLabel: "Klantenlabel",
        newSupervisionItemRule: "Regel voor nieuwe toezichtartikelen",
        editSupervisionItemRule: "Regels voor begeleide artikelen bewerken",
        newCustomerLabel: "Nieuw klantenlabel",
        editCustomerLabel: "Klantentags bewerken",
        newSessionLabel: "Nieuw sessielabel",
        editSessionLabel: "Sessielabel bewerken",
        newKeyEvent: "Nieuw sleutelevenement",
        editKeyEvents: "Belangrijkste gebeurtenis bewerken",
        detail: {
          template: { nameIsARequiredField: "Naam is een verplicht veld" },
          formItemComponent: {
            largeModelExtensionLabel: "Grote Model Uitbreidingslabels",
            sessionRecordID: "Sessie record id",
            sessionTime: "Sessie tijd",
            smartTags: "Slim label",
            viewDetails: "Details bekijken",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Na verwijdering worden de gegevens van dit sessierecord gewist. Weet je zeker dat je het verwijdert?",
            tagData: "Tag gegevens",
            batchDeletion: "Batch verwijderen",
          },
        },
        template: {
          advancedFiltering: "Geavanceerd filteren",
          dataType: "Gegevenstype",
          filterCriteria: "Filterconditie",
          edit: "Bewerken",
          createANewSubcategory: "Nieuwe subcategorie",
          delete: "Verwijderen",
          nameLengthCannotExceed: "Naamlengte mag niet groter zijn dan 40",
        },
        component: {
          modelExpansion: "Modeluitbreiding",
          enable: "Inschakelen",
          notEnabled: "Niet ingeschakeld",
          standardScreening: "Standaard Filter",
          advancedFiltering: "Geavanceerd filteren",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Trefwoorden",
              sessionLabel: "Sessielabel",
              keyEvents: "Belangrijkste evenementen",
              matchAny: "Overeenkomen met elke",
              matchAll: "Alles overeenkomen",
              beEqualTo: "Gelijk",
              includeAll: "Bevat alle",
              includeAny: "Bevat elke",
              advancedConditions: "Geavanceerde voorwaarden",
              smartTag: "Slim label",
              smartTagI: "Slim label één",
              smartTagII: "Slim label II",
            },
            template: {
              pleaseSelectSmartTag: "Selecteer een slim label",
              pleaseSelectASessionLabel: "Selecteer een sessielabel",
              pleaseSelectAKeyEvent: "Selecteer een belangrijke gebeurtenis",
              pleaseSelectAdvancedCondition: "Selecteer geavanceerde criteria",
              newAdvancedFilter: "Nieuwe geavanceerde filter",
              moveUp: "Omhoog gaan",
              moveDown: "Naar beneden gaan",
              moveLeft: "Verplaats naar links",
              shiftRight: "Beweeg naar rechts",
            },
          },
          component: {
            businessAnalysispng: "Bedrijfsanalyses.png",
            savedSuccessfully: "Bespaar met succes",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Permanent effectief",
            permanentlyInvalid: "Permanent ongeldig",
            customValidity: "Aangepaste geldigheidsperiode",
            termOfValidity: "Geldigheidsperiode",
            name: "Naam",
            explain: "Beschrijving",
            state: "Status",
            classification: "Indeling",
            smartRecommendation: "Intelligente aanbeveling",
            preconditions: "Voorwaarde",
            SetPreconditionsForThisSupervisionItem:
              "1. Stel voorwaarden voor dit toezichtitem:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Wanneer aan de randvoorwaarden is voldaan, wordt dit beschouwd als "het te implementeren toezichtpunt" en wordt het toezichtpunt getest;',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Als niet aan de randvoorwaarden is voldaan, wordt het toezichtitem niet gedetecteerd;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Stel geen randvoorwaarden in, dat wil zeggen dat alle sessies standaard worden gedetecteerd.",
            have: "Er zijn",
            nothing: "Geen",
            preconditionRule: "Voorwaarde regel",
            preconditionLogic: "Voorwaarde logica",
          },
          recommendation: {
            caseGrouping: "Geval groeperen",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Wanneer werknemers niet goed presteren, kunnen ze op intelligente wijze uitstekende spraakvaardigheden en uitstekende cases aanbevelen.",
            smartRecommendation: "Intelligente aanbeveling",
            open: "Open",
            close: "Sluiten",
            whenThisSupervisionItem: "Wanneer dit supervisieartikel",
            implement: "Uitvoering",
            unexecuted: "Niet uitgevoerd",
            when: "Wanneer:",
            recommendedKnowledgePoints: "Aanbevolen kennispunten",
            caseRecommendation: "Aanbeveling zaak",
          },
        },
        index: {
          determine: "Bepaal",
          sort: "Sorteren",
          sortSucceeded: "Sorteren met succes",
          supervisionItemClassification: "Classificatie van toezichtpunten",
          pressEnterToCreateANew: "Druk op Enter Nieuw",
          nameCannotBeEmpty: "Naam kan niet leeg zijn",
          newlyBuild: "Nieuw",
          name: "Naam",
          explain: "Beschrijving",
          validStatus: "Geldige status",
          permanentlyValid: "Permanent effectief",
          permanentlyInvalid: "Permanent ongeldig",
          operation: "Operatie",
          edit: "Bewerken",
          batchDelete: "Batch verwijderen",
          newSupervisionItem: "Nieuw toezichtartikel",
          keyWord: "Trefwoorden",
          delete: "Verwijderen",
          deleteSucceeded: "Met succes verwijderd",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Gegevens kunnen niet worden hersteld nadat ze zijn verwijderd. Weet u zeker dat u het wilt verwijderen?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Slim label",
              beEqualTo: "Gelijk",
              includeAll: "Bevat alle",
              includeAny: "Bevat elke",
              matchAny: "Overeenkomen met elke",
              matchAll: "Alles overeenkomen",
              smartTagI: "Slim label één",
              smartTagII: "Slim label II",
            },
            template: {
              pleaseSelectASmartTag: "Selecteer een slim label",
              pleaseSelectAdvancedConditions: "Selecteer geavanceerde criteria",
              newAdvancedFilter: "Nieuwe geavanceerde filter",
              rename: "Naam wijzigen",
              moveUp: "Omhoog gaan",
              moveDown: "Naar beneden gaan",
              shiftLeft: "Verplaats naar links",
              shiftRight: "Beweeg naar rechts",
            },
          },
        },
        template: {
          delete: "Verwijderen",
          labelFiltering: "Het filteren van het etiket:",
          satisfyAny: "Voldoen aan willekeurige",
          meetAll: "Ontmoet alle",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Aantal secundaire classificatielabel gesprekken ≥ primaire classificatie, een sessie kan meerdere labels bevatten",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logica voor de berekening van de ringverandering: (dialoogvolume in de geselecteerde tijdsperiode-dialoogvolume in de onmiddellijk voorafgaande tijdsperiode)/dialoogvolume in de voorgaande tijdsperiode",
          proportionOfPrimaryClassification:
            "Percentage van de classificatie op het eerste niveau",
          saveAs: "Bespaar",
          cover: "Cover",
          preservation: "Bespaar",
          qualityInspectionTask: "Analysetaken:",
          query: "Vraag",
          changeFromMonthToMonth: "Ring veranderen",
          tagRanking: "Labelrangschikking",
          proportion: "Percentabele situatie",
        },
        component: {
          numberOfConversations: "Aantal gesprekken",
          operation: "Operatie",
          businessAnalysispng: "Bedrijfsanalyses.png",
          savingSucceeded: "Bespaar met succes",
          deletionSucceeded: "Met succes verwijderd",
          sequence: "Volgorde",
          firstLevelClassification: "Niveau 1 classificatie",
          ringRatioChange: "Ring veranderen",
          classClassification: "Klassenindeling",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Bevestig om het document en de geëxtraheerde inhoud te verwijderen?",
            addKnowledgeBase: "Knowledge Base toevoegen",
            uploadDocument: "Document uploaden",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Ondersteuning woord, pdf, ppt, excel, txt formaat bestanden",
            taskName: "Naam van de taak",
            extractConversationDate: "Uittreksel Gespreksdatum",
            pleaseSelectAConversationDate: "Selecteer een gespreksdatum",
            dataSource: "Gegevensbron",
            dataType: "Gegevenstype",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Export geslaagd",
              problem: "Vraag",
              answer: "Antwoord",
              addToKnowledgeBase: "Toevoegen aan kennisbasis",
              pleaseConfirmWhetherToDeleteIt: "Bepaal of u wilt verwijderen",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Toevoegen aan kennisbasis",
              selectClassification: "Categorie selecteren",
              pleaseSelectAKnowledgeBaseClassification:
                "Selecteer de classificatie van de kennisbasis",
            },
          },
        },
        index: {
          intelligentExtraction: "Intelligente extractie",
          documentExtraction: "Documentextractie",
          dialogueExtraction: "Dialoogextractie",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operatie is geslaagd",
          labelIgnored: "Tag genegeerd",
          add: "Toevoegen",
          areYouSureToDeleteIt: "Weet u zeker dat u wilt verwijderen?",
          batchFilterLabels: "Label met batchfilter",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Intelligente kwaliteitsinspectie",
        savedSuccessfully: "Bespaar met succes",
        logoSettings: "Logo-instellingen",
        default: "Standaard",
        custom: "Aangepast",
        title: "Titel",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "De titelnaam die door het systeem wordt weergegeven, wordt aanbevolen om binnen 6 tekens te zijn.",
        logoImageUpload: "Logo afbeelding uploaden",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Selecteer een foto met een grootte van maximaal 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Om het browse-effect van het logo te garanderen, wordt voorgesteld om afbeeldingen te uploaden:",
        Size5050Pixels: "1. Grootte: 50*50 pixels;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. formaat: foto met transparante achtergrond, png of jpg,logo afbeelding met behulp van wit",
        browserLabelIcon: "Browser tabblad pictogram",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Om het effect van het browsen van tags te garanderen, wordt aanbevolen om afbeeldingen te uploaden:",
        Size3232Pixels: "1. Grootte: 32*32 pixels;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Formaat: afbeelding met transparante achtergrond, png of jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Kwaliteitsinspectie aan de gang, wacht alstublieft...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "In 2015 bereikte het onroerend goed van mijn land een kritiek moment van overleven. Een groot aantal huizen was onbeheerd en mensen waren niet bereid om ze te kopen. Hangzhou viel ooit onder de 8000 en sommige ontwikkelaars sloegen de markt kapot om te verzenden. Shenzhen consolideerde op dat moment nog steeds rond 2w-3w, en huizen in eersteklas steden liepen ook in gevaar.",
        xiaoAn: "Ann.",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload deze geheugenbarrières worden jsr-geheugenbarrières genoemd, die zich op het jvm-niveau bevinden. Er zijn twee implementaties aan de onderkant van het besturingssysteem: de ene is om de bus te vergrendelen en de andere is de primitieve geheugenbarrière, zoals sfance (schrijfbarrière) mfence (volledige barrière) ifence (leesbarrière). De onderste laag van de virtuele hotspots-machine maakt gebruik van een assemblage-instructie: addl vergrendelen, een lege operatie vergrendelen, dat is de lock-bus. Dit wordt gedaan omdat sommige besturingssystemen geen geheugenbarrièreprimitieven ondersteunen.",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Probeer het vlees meerdere keren te marineren en roerbak de groenten na het toevoegen van wijn. Het vlees is ranzig en ruikt zuur! Vanaf nu geen wijn meer koken voor spek",
        xiaodong: "Xiaodong",
        xiaoxi: "Xiao Xi",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Probeer het vlees meerdere keren te marineren, en de groenten die worden gebakken na het toevoegen van wijn zijn als verwend vlees, met een zure geur! Vanaf nu geen wijn meer koken voor spek",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "E-commerce beoordelingsbeheer",
        socialMediaReviewManagement: "Beheer van social media review",
        customDataSource: "Aangepaste gegevensbron",
        qualityInspectionResults: "Resultaten kwaliteitsinspectie",
        accountManagement: "Accountbeheer",
        employeeProfile: "Werknemersportret",
        intelligentAnalysis: "Intelligente analyze",
        customKanban: "Aangepaste Kanban",
        dataScreening: "Gegevensoverzicht",
        learningRecord: "Leerrecord",
        dataAnalysis: "Gegevensanalyse",
        scoringManagement: "Scoren management",
        businessConfiguration: "Zakelijke configuratie",
        manualReview: "Handmatige beoordeling",
        taskList: "Taaklijst",
        videoAnalysis: "Videoanalyse",
        overview: "Overzicht",
        personalSignage: "Persoonlijke Kanban",
        learningCenter: "Leercentrum",
        wrongQuestionSet: "Verkeerde probleemset",
        abilityImprovement: "Mogelijkheid om te upgraden",
        close: "Sluiten",
        switchingSystemApplications: "Toepassingen voor schakelsystemen",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Beweeg de muis hier om het wisselmenu van de applicatie op te roepen en klik op het menu-item om naar verschillende toepassingen te gaan.",
        teamAnalysis: "Teamanalyse",
        semanticIntelligence: "Semantische intelligentie",
        permissionManagement: "Toestemming beheer",
        dataConnection: "Docking van gegevens",
        courseManagement: "Cursusbeheer",
        labelManagement: "Labelbeheer",
        curriculum: "Cursuscentrum",
        courseConfiguration: "Cursusconfiguratie",
        task: "Mijn missie",
        historicalLearningData: "Geschiedenis",
        taskCenter: "Taakbeheer",
        knowledgeBase: "Kennisbasis",
        ecommerceSessionManagement: "E-commerce sessiebeheer",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Toepassingsschakeling",
          descriptionOfIntelligentQualityInspectionSystem:
            "Beschrijving van intelligent kwaliteitsinspectiesysteem",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "E-commerce sessiefilter",
        allEcommerceSessions: "Alle e-commerce sessies",
        ecommerceSessionManagement: "E-commerce sessiebeheer",
      },
    },
    analysisEnable: {
      bestPractices: "Beste praktijken",
      behaviorAnalysis: "Gedragsanalyse",
      teamAnalysis: "Teamanalyse",
      executiveSupervision: "Toezicht van de uitvoerende macht",
      executiveForceAnalysis: "Uitvoeringsanalyse",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Beoordeling:",
          startTime: "Starttijd",
          workOrderNo: "Werkordernummer",
          customerServiceHasReadNotRead: "Agent gelezen/ongelezen",
          canYouAppeal: "Of u in beroep moet gaan",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Micro-opmerkingen van Enterprise",
          addFriendTime: "Voeg buddy tijd toe",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Start opnieuw met inspectie",
          callID: "Bel-ID",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Nieuwe functies! Klik op de knop 'Quick Sampling Check' springt automatisch naar de volgende pagina na succesvolle inzending ~ ~ ~",
        },
      },
      template: {
        deleteRecord: "Record verwijderen",
        areYouSureToDeleteIt: "Weet u zeker dat u wilt verwijderen?",
        confirm: "Bepaal",
        reIdentification: "Heridentificatie",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Heridentificatie zal de overdrachtstijd in beslag nemen, wees voorzichtig.",
        determine: "Bepaal",
        executionSuccessful: "Succesvolle uitvoering",
      },
      component: {
        pleaseSelectData: "Selecteer gegevens",
        operationSuccessful: "Operatie is geslaagd",
        startPerformingReQualityInspection: "Start opnieuw met inspectie",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Bestandsnaam uploaden",
        analysisTask: "Analyse taak",
        analyzeProgress: "Analyseer de voortgang",
        analysisSucceeded: "Analyse van succes",
        analysisFailed: "Analyse mislukt",
        localDataUpload: "Lokale gegevens uploaden",
        recordingUpload: "Audio-opname uploaden",
        textUpload: "Tekst uploaden",
        uploadTime: "Uploadtijd",
        taskName: "Naam van de taak",
        operator: "Exploitant",
        qualityInspectionProgress: "Vooruitgang van de kwaliteitsinspectie",
        whole: "Allemaal",
        haveInHand: "In uitvoering",
        completed: "Voltooide",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Alle ingevulde gegevensrecords exporteren onder de huidige filtercriteria?",
        upload: "Uploaden",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Naam invullen",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Voer de naam in, zoals het type/verzameling/doel van de geïmporteerde gegevens.",
            default: "Standaard",
            jDCOM: "Jingdong",
            dataType: "Gegevenstype",
            selectAnalysisTask: "Selecteer Analyse Taak",
            uploadFiles: "Bestand uploaden",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Download de Excel-sjabloon en vul deze in volgens het formaat:",
            template: "Sjabloon",
            SupportUpTo10000PiecesOfData:
              "2. maximale ondersteuning 10000 stuks van gegevens",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Ondersteuning Jingdong geëxporteerde dialooggegevens upload,.txt bestand, bestandsgrootte maximale ondersteuning 100M",
            alongTheWayDataTemplate: "Follow-the-road data-sjabloon",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Wacht geduldig tijdens de kwaliteitsinspectie van de tekst.",
            textQualityInspectionCompleted: "Tekstkwaliteitsinspectie voltooid",
          },
        },
        template: {
          startAnalysis: "Start analyze",
          pleaseUploadTheFile: "Upload het bestand",
          localDataUpload: "Lokale gegevens uploaden",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "Nieuwe functies! Klik op de knop 'Review Submit', nadat de inzending is gelukt, springt deze automatisch naar de volgende pagina ~ ~ ~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Taaktoestemming voor kwaliteitsinspectie",
            edit: "Bewerken",
            iParticipated: "Ik ben betrokken bij",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Deze rol kan de gegevens onder de geselecteerde afdeling zien",
            dataPermissions: "Gegevensmachtigingen",
            selectDepartment: "Selecteer Afdeling",
            edit: "Bewerken",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Start opnieuw met inspectie",
          callID: "Bel-ID",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Nieuwe functies! Click 'sampling submit' en 'quick sampling submit' knoppen zullen automatisch naar de volgende pagina springen na succesvolle inzending ~ ~ ~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Regels" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Beheer van werkorders",
          workOrderDetails: "Details van werkorders",
        },
      },
      template: {
        workOrderFilter: "Werkorder Filter",
        allWorkOrders: "Alle werkbestellingen",
        workOrderManagement: "Beheer van werkorders",
      },
    },
    trainingCenter: {
      topicAnnotation: "Onderwerpoproep",
      topicList: "Onderwerplijst",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Roltype",
              keyWord: "Trefwoorden",
              pleaseEnterKeywordSearch: "Voer een zoekwoord in",
              query: "Vraag",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Deze functie is nog niet geopend. Neem indien nodig contact op met de systeembeheerder.",
          pleaseSelectAQuestion: "Selecteer een vraag!",
          recommendedSimilarSentencesLargeModel:
            "Aanbevolen vergelijkbare zinnen (groot model)",
          selectAll: "Alles selecteren",
          add: "Toevoegen",
          changeBatch: "Een batch wijzigen",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Zakelijke werktijden",
      localDataUpload: "Lokale gegevens uploaden",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Menulijst samenstellen volgens de huidige gebruikersrechten",
      sessionSettings: "Sessie-instellingen",
      logoSettings: "Logo-instellingen",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails: "Welkom terug! Voer uw gegevens in",
        mailbox: "Mailbox",
        password: "Wachtwoord",
        signIn: "Inloggen",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "Huidige IP staat login niet toe",
          welcomeToLogin: "Welkom bij Login",
          pursuingExcellenceAndLeadingChange:
            "Het streven naar uitmuntendheid, het leiden van de verandering",
          intelligentPlatform: "Intelligent platform",
        },
        virtualLogin: { index: { pleaseSelect: "Selecteer alstublieft" } },
      },
      oauth: {
        illegalCall: "Illegale oproep",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Welkom terug! Klik op de onderstaande knop om naar het authenticatieplatform te gaan",
        loggingIn: "Inloggen...",
        unifiedIdentityAuthenticationLogin:
          "Login voor eengemaakte identiteitsverificatie",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Regeltesten: Sommige regels kunnen niet worden getest met behulp van regels, zoals spraaksnelheid en volume, gegevens onderweg, enz.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Meer informatie",
          enteringTheSystem: "Voer het systeem in",
        },
      },
      index: {
        cancel: "Annuleer",
        enteringTheSystem: "Voer het systeem in",
        close: "Sluiten",
        enterSystemApplication: "Voer de systeemtoepassing in",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Klik op "Enter System" om naar een ingeschakelde systeemapp te gaan',
      },
    },
    permissionManagement: {
      employeeManagement: "Werknemersmanagement",
      roleManagement: "Rolbeheer",
      permissionManagement: "Toestemming beheer",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Gelijk",
            contain: "Bevat",
            doesNotContain: "Bevat niet",
            applicationSystem: "Toepassingssysteem",
            intelligentQualityInspection:
              "Intelligente kwaliteitsinspectie/intelligente sessieanalyse",
            salesEmpowerment: "Verkoopempowerment",
            dataSourceType: "Type gegevensbron",
            filterCriteria: "Filterconditie",
          },
        },
        index: {
          dataConnection: "Docking van gegevens",
          savingSucceeded: "Bespaar met succes",
          dataIsolation: "Gegevensisolatie",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Na het openen, kunt u het gegevensbereik van elke applicatie systeem toegang oproep en enterprise WeChat sessie instellen. Als de schakelaar niet is ingeschakeld, zijn alle systemen verbonden met de volledige gegevens.",
          accessRules: "Toegangsregels",
          preservation: "Bespaar",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Naam van de aanvraag",
            accessRights: "Toegang",
            inAppPermissionSettings: "In-app machtigingsinstellingen",
            setUp: "Opstelling",
            applicationAccess: "Toegang tot de aanvraag",
            newRole: "Nieuwe rol",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Details van de sessie" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Details van de sessie",
          caseDetails: "Geval Details",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Zichtbare werknemers",
            newCourse: "Nieuwe cursus",
            editCourse: "Cursus bewerken",
            courseName: "Cursusnaam",
            courseIntroduction: "Introductie van de cursus",
            courseType: "Cursustype",
            courseClassification: "Cursusclassificatie",
            courseCover: "Cursus Cover",
            learningContent: "Inhoud leren",
            uploadLearningMaterials: "Leermateriaal uploaden",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Test",
              preservation: "Bespaar",
              cancel: "Annuleer",
              editNodeName: "Knooppuntnaam bewerken",
              determine: "Bepaal",
              individual: "Een",
              of: ", Totaal",
              branch: "Punten",
            },
            configDrawerComponents: {
              pPTParsing: "PPT-analyze in...",
              uploadSuccessful: "Succesvol uploaden",
              uploadAttachments: "Gehechtheid uploaden",
              pPTParsingPleaseWait: "PPT parsing, wacht alstublieft",
            },
            customerPortraitConfigDrawer: {
              cancel: "Annuleer",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Het kan niet worden hersteld na verwijdering. Weet u zeker dat u dit portret wilt verwijderen?",
              delete: "Verwijderen",
              determine: "Bepaal",
              customerPortrait: "Klantenportret",
              bindSelectedCharacterPortrait:
                "Bind het geselecteerde karakterportret",
              customerProfileDetails: "Details van het klantprofiel",
              createANewCustomerProfile: "Nieuw klantprofiel",
            },
            flow: {
              sidebar: { node: "Knooppunt" },
              useControlButtons: {
                cancellingPleaseWait: "Ongedaan maken, alsjeblieft later...",
                redoInProgressPleaseWait: "Opnieuw doen, alsjeblieft later...",
                automaticOrganization: "Automatische afwerking",
                canvasAdaptation: "Canvas adaptief",
                delete: "Verwijderen",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Knooppunt kan geen verbinding maken met zichzelf",
              },
            },
            globalConfigDrawer: {
              cancel: "Annuleer",
              determine: "Bepaal",
              globalConfiguration: "Wereldwijde configuratie",
              sessionDetection: "Sessie detectie",
              reciprocalRule: "Interactieregels",
              robotVoice: "Robot geluid",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Student node time-out onbeantwoorde instellingen",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Maximaal toegestane stille tijd (en)",
              promptScriptForFailureToAnswerOverTime: "Prompt voor time-out",
              addScript: "Woorden toevoegen",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (Natuur)",
              zhishaChat: "Zhisha (chat)",
              zhiqiangentle: "Zhiqian (zacht)",
              zhimiEmotion: "Ken rijst (emotie)",
              aiShuoNatural: "Aishuo (Natuur)",
              zhishuoChat: "Zhishuo (chat)",
              aiHaoAffinity: "Ai Hao (affiniteit)",
              zhixiangEmotion: "Zhixiang (Emotie)",
              robotVoice: "Robot geluid",
              schoolboy: "Jongens",
              girlStudent: "Meisjes",
              volume: "Volume",
              tone: "Toon",
              speechSpeed: "Snelheid van spraak",
              test: "Test",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Hallo, ik ben een intelligente trainingscoach en kijk ernaar uit om met je te communiceren.",
            },
            sessionDetectionForm: {
              expressionDetection: "Expressie detectie",
              excessiveDetectionOfModalParticles:
                "Overmatige modale woorddetectie",
              theNumberOfRepetitionsIs: "Het aantal herhalingen is",
              minus: "Tijd, minus",
              appear: "Verschijnen",
              subtract: "Tijden, min",
              branch: "Punten",
              addRules: "Regel toevoegen",
              includingNegativeAngryComplaining:
                "(Inclusief negatief, boos, klagen)",
              scorePointsDisplay: "Score-punten weergeven",
              reminderOfSpeakingTooFasttooSlow:
                "Speedtoo snelle/te langzame herinnering",
              scriptViolationReminder: "Herinnering aan spraakschending",
              emotionalReminder: "Emotionele herinnering",
              speechSpeedDetection: "Spraaksnelheid detectie",
              normalSpeechSpeedScore: "SNELHEID NORMAAL SCORE",
              speakTooFast: "Te snel snel snel snel snel",
              everyMinuteGreaterThan: "Per minuut groter",
              aWordAppears: "Woord, verschijnen.",
              speakTooSlowly: "SNELHEID LANGZAAM",
              lessThanPerMinute: "Minder dan per minuut",
              ignoreLessThan: "Negeer minder",
              aSentenceOfWords: "Zin van woorden",
              emotionalDetection: "Emotie detectie",
              emotionalNormalScore: "Normale stemmingsscore",
              negativeEmotions: "Negatieve emotie",
              illegalWordDetection: "Illegale woorddetectie",
              scriptViolationConfiguration:
                "Configuratie van spraakschendingen",
              realTimePromptOnTheFrontEnd: "Front-end real-time prompt",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Kennisbasis Q & A",
                  qAMethod: "Vraag-en antwoordmethode",
                  numberOfQuestionsAndAnswers: "Aantal vragen en antwoorden",
                  scoringRules: "Scoreregels",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Elke vraag en antwoord is correct, je hebt",
                  branch: "Punten",
                  successfullySaved: "Bespaar met succes",
                },
                pptNode: {
                  verificationPassed: "Verificatie geslaagd",
                  upload: "Uploaden",
                  supportPptPdfFormatFiles:
                    "Ondersteuning ppt en pdf-formaat bestanden",
                  uploadSlide: "Dia's uploaden",
                  assessmentMethod: "Beoordelingsmethode",
                  associateSlide: "Geassocieerde dia's",
                  standardScript: "Standaard toespraak",
                  successfullySaved: "Bespaar met succes",
                },
                robotNode: {
                  robotScript: "Robot Speech",
                  randomScript: "Willekeurige toespraak",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Kies woorden met vergelijkbare semantiek, en de robot zal willekeurig een van de zinnen zeggen",
                  customerPortrait: "Klantenportret",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Wanneer het klantportret consistent is, spreekt de robot dit",
                  setAsDefaultScript: "Instellen als standaard",
                  yes: "Ja",
                  no: "Nee",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Dit wordt gezegd wanneer de robot een gebruikerstag mist.",
                  successfullySaved: "Bespaar met succes",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Dit vertakkingsknooppunt heeft al een standaardspraak. Wilt u de standaardspraak vervangen door de huidige spraak?",
                },
                startNode: {
                  paragon: "Voice-over",
                  successfullySaved: "Bespaar met succes",
                },
                userNode: {
                  verificationPassed: "Verificatie geslaagd",
                  standardScript: "Standaard toespraak",
                  successfullySaved: "Bespaar met succes",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Maak de configuratie alstublieft perfect",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Voer een voorbeeldgesprek in",
                  dialogueExample: "Voorbeeld van een gesprek",
                  generateConversationExamples: "Dialoogvoorbeeld genereren",
                  mattersNeedingAttention: "Voorzorgsmaatregelen",
                  pleaseEnterThePromptLanguage: "Voer een prompt in",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Dialoogscène",
                  simulateCharacter: "Gesimuleerd karakter",
                  accompanyingTrainees: "Begeleidend object",
                  selectFromTemplateLibrary:
                    "Selecteer uit Sjabloonbibliotheek",
                  templateLibrary: "Sjabloon bibliotheek",
                  role: "Rol",
                  scene: "Scène",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Testvenster voor slide",
              clearContent: "Duidelijke inhoud",
              close: "Sluiten",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI-generatieproces",
                scriptStyle: "Spraakstijl",
                theMostConversationRounds: "De meeste rondes van dialoog",
                copyConversation: "Dialoogvenster kopiëren",
                application: "Toepassing",
                regenerate: "Regenereren",
                startGenerating: "Start generatie",
                pleaseGenerateAProcess: "Gelieve Proces te genereren",
                copySuccessful: "Kopieer succes",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Verkoopvertegenwoordiger",
                    customerServicePersonnel: "Klantenservicepersoneel",
                    storeCounter: "Winkelteller",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Full-text spraakvaardigheden genereren",
                speaker: "Luidspreker",
                scriptStyle: "Spraakstijl",
                copyConversation: "Dialoogvenster kopiëren",
                application: "Toepassing",
                regenerate: "Regenereren",
                startGenerating: "Start generatie",
                pleaseGenerateAProcess: "Gelieve Proces te genereren",
                operationSuccessful: "Operatie is geslaagd",
                copySuccessful: "Kopieer succes",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Spraakpoets" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Woorden kunnen niet leeg zijn!",
              copySuccessful: "Kopieer succes",
              cancel: "Annuleer",
              copy: "Kopiëren",
              replaceTheOriginalText: "Vervang origineel",
              aiGeneration: "Ai generatie",
              aiPolishing: "Ai polish",
              currentStyle: "Huidige stijl",
              rigorous: "Streng",
              mild: "Zacht",
              lively: "Levendig",
              simplicity: "Eenvoudig",
              pleaseEnterTheSpeakerRole: "Voer een sprekerrol in",
              startGenerating: "Start generatie",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Woorden kunnen niet leeg zijn!",
              copySuccessful: "Kopieer succes",
              aiRewrite: "Ai herschrijf",
              cancel: "Annuleer",
              copy: "Kopiëren",
              replaceTheOriginalText: "Vervang origineel",
              currentStyle: "Huidige stijl",
              rigorous: "Streng",
              mild: "Zacht",
              lively: "Levendig",
              simplicity: "Eenvoudig",
              pleaseEnterTheSpeakerRole: "Voer een sprekerrol in",
              startGenerating: "Start generatie",
            },
          },
          index: {
            verificationPassed: "Verificatie geslaagd",
            ruleValidation: "Regelverificatie",
            aIGeneration: "AI-generatie",
            generateFullTextSpeechScript:
              "Full-text spraakvaardigheden genereren",
            editCourse: "Cursus bewerken",
            importConversation: "Gesprek importeren",
            uploadExcelToCompleteDialogueImport:
              "Excel volledig dialoogvenster importeren uploaden",
            editBasicInformation: "Basisinformatie bewerken",
            test: "Test",
            release: "Publiceren",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Nieuw label" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Kan niet herstellen na verwijdering. Weet u zeker dat u dit label wilt verwijderen?",
              delete: "Verwijderen",
              cancel: "Annuleer",
            },
          },
        },
        index: {
          labelManagement: "Labelbeheer",
          newGroup: "Nieuwe groep",
          edit: "Bewerken",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Kan niet herstellen na verwijdering. Weet u zeker dat u deze labelgroep wilt verwijderen?",
          delete: "Verwijderen",
          cancel: "Annuleer",
          editGroup: "Groepering bewerken",
          labelGroupName: "Naam van de groep van het etiket",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Camera uitgeschakeld",
              noCameraDevicesFound: "Camera-apparaat niet gevonden",
            },
          },
          cue: {
            index: {
              regenerate: "Regenereren",
              generate: "Genereren",
              referenceScript: "Referentietoespraak",
              mattersNeedingAttention: "Voorzorgsmaatregelen",
              examFrequency: "Aantal examens",
              section: "Nee.",
              timestotal: "Tijden/Totaal",
              second: "Tijden",
              requirementsForPassingTheExam:
                "Vereisten voor het slagen voor het examen",
              examScore: "Testscore ≥",
              branch: "Punten",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Geen portret",
              customerBackground: "Klant achtergrond",
              customerLabel: "Klantenlabel",
            },
          },
          flow: {
            index: {
              processReminder: "Procesprompt (",
              score: "Score",
              TotalScore: "/Totale score",
            },
          },
          index: {
            taskName: "Naam van de taak",
            taskStatus: "Taakstatus",
            whole: "Allemaal",
            completionStatus: "Voltooiingsstatus",
            courseClassification: "Cursusclassificatie",
            practiceMode: "Oefenmodus",
            examMode: "Onderzoeksmodus",
            practiceTime: "Oefentijd",
          },
          ppt: {
            index: {
              nextPage: "Volgende pagina",
              tips: "Prompt",
              continueWithCurrentPage: "Doorgaan met huidige pagina",
              goToTheNextPage: "Ga naar de volgende pagina",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Nadat u de volgende pagina hebt ingevoerd, kunt u niet terugkeren naar de vorige pagina. Bevestig dat de uitleg van de huidige pagina compleet is voordat u springt.",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Klik op de volgende pagina zal niet verschijnen deze prompt",
            },
            list: { index: { slide: "Glijbaan" } },
          },
          record: {
            index: {
              unableToRecord: "Kan niet opnemen:",
              creatingARecorderService: "Recorderservice maken",
              turnOffTheRecorderService: "Schakel de Recorder-service uit",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "De huidige client is al verbonden met de ASR Websocket-server",
              afterProcessingTheLastFrameRestoreTheState:
                "Na het verwerken van het laatste frame, herstel de staat",
              aSRConnectionClosed: "ASR-verbinding gesloten...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "De opname is gepauzeerd en is verwerkt, maar er zijn nog steeds gegevens in de cache",
              foundASRChannelClosedRecreateWebsocketLink:
                "Gevonden ASR-kanaal gesloten, opnieuw te creëren Websocket link",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Gevonden dat het ASR-kanaal niet open is, opnieuw te maken Websocket link",
            },
            ppt: { hook: { index: { serverError: "Serverfout" } } },
            text: {
              index: {
                pleaseEnter: "Voer alstublieft in",
                sendOut: "Verzenden",
                startPracticing: "Begin met oefenen",
                startTheExam: "Start het examen",
              },
            },
            util: {
              recorded: "Opgenomen",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "De 16-bit pcm SendFrameSize moet een geheel veelvoud van 2 zijn",
              pCMSamplingRateShouldNotOccur:
                "Pcm sampling rate mag niet verschijnen",
              andTheRequiredSamplingRate:
                "En de vereiste bemonsteringsfrequentie",
              atypism: "Inconsequent",
              errorsThatShouldNotOccur: "Fouten die niet mogen optreden:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> Gegevens die niet in orde zijn, kunnen worden weggegooid als de vereisten niet hoog zijn. </Span>',
              flower: "Bloemen",
              TransferStopped: ": Overdracht gestopt",
            },
            video: { index: { camera: "Camera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "De huidige client is al verbonden met de ASR Websocket-server",
                    aSRConnectionClosed: "ASR-verbinding gesloten...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Gevonden ASR-kanaal gesloten, opnieuw te creëren Websocket link",
                    unableToRecord: "Kan niet opnemen:",
                    aSRChannelClosedReopened: "ASR-kanaal gesloten, heropend",
                    theASRChannelWasNotCreatedStartingNow:
                      "ASR-kanaal niet gemaakt, begin nu te maken",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect callback om het ASR-kanaal te wissen",
                    creatingARecorderService: "Recorderservice maken",
                    turnOffTheRecorderService:
                      "Schakel de Recorder-service uit",
                  },
                  util: {
                    recorded: "Opgenomen",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "De 16-bit pcm SendFrameSize moet een geheel veelvoud van 2 zijn",
                    pCMSamplingRateShouldNotOccur:
                      "Pcm sampling rate mag niet verschijnen",
                    andTheRequiredSamplingRate:
                      "En de vereiste bemonsteringsfrequentie",
                    atypism: "Inconsequent",
                    errorsThatShouldNotOccur: "Fouten die niet mogen optreden:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> Gegevens die niet in orde zijn, kunnen worden weggegooid als de vereisten niet hoog zijn. </Span>',
                    flower: "Bloemen",
                    TransferStopped: ": Overdracht gestopt",
                  },
                },
                index: { failInSend: "Verzenden mislukt" },
              },
              index: {
                startRecording: "Begin met opnemen",
                endRecording: "Opname beëindigen",
                startPracticing: "Begin met oefenen",
                startTheExam: "Start het examen",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Het huidige aantal resterende examens is",
              totalScriptScore: "Totale spraakscore:",
              totalEmotionalScore: "Totale emotionele score:",
              totalScoreOfExpression: "Totale expressiescore:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "De huidige resultaten voor het scoren van taken moeten handmatig worden beoordeeld. Nadat de beoordeling is voltooid, wordt u op de hoogte gebracht van de definitieve resultaten. Wacht alstublieft geduldig.",
              areYouSureToSubmit: "Inzending bevestigen?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Oefening is beëindigd, weet u zeker dat u het wilt indienen?",
              theExamHasEndedAreYouSureToSubmit:
                "Het examen is afgelopen, weet u zeker dat u het wilt indienen?",
              completed: "Voltooide",
              page: "Pagina",
              pageCount: "Totaal aantal pagina's",
              topic: "Onderwerp",
              totalNumberOfQuestions: "Totaal aantal vragen",
              totalScore: "Totaal score",
              branch: "Punten",
              accumulatedCompletionProgress:
                "Cumulatieve voortgang van de voltooiing",
              accumulatedPracticeDuration: "Cumulatieve oefenduur",
              lastPauseExerciseResults: "Laatste time-out-oefenresultaten",
              exerciseInstructions: "Instructies voor oefeningen",
              examInstructions: "Examenbeschrijving",
              halfwayPausePracticeResults:
                "Halverwege pauze-Praktijkresultaten",
              tips: "Prompt",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Na het stoppen wordt deze oefening niet in de score opgenomen. Weet je zeker dat je wilt stoppen?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Stoppen is niet toegestaan in het midden van het examen. Inzending bevestigen?",
              pleaseOperateWithCaution: "Tijden, gelieve zorgvuldig te werken)",
              restart: "Opnieuw opstarten",
              returnToLearning: "Keer terug naar leren",
              continuePracticing: "Blijf oefenen",
              continuingTheExam: "Vervolg het examen",
              return: "Terugkeer",
              notSubmittedTemporarily: "Niet ingediend",
              iGotIt: "Ik weet het.",
              confirmExit: "Afsluiten bevestigen",
              confirmSubmission: "Inzending bevestigen",
              signOut: "Uitgang",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Pauzeer",
              submit: "Indiening",
              remainingTimeOfThisQuestion: "Resterende tijd",
              SecondsLeftUntilTheEndTime: "10 seconden tot eindtijd",
              remainingTime: "Resterende tijd",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Gebruikerspraakinhoud niet gedetecteerd, probeer het opnieuw",
          },
          wave: {
            index: {
              continueRecording: "Doorgaan met opnemen",
              submit: "Indiening",
              endRecording: "Opname beëindigen",
              suspendRecording: "Pauzeer opname",
              startPracticing: "Begin met oefenen",
              startTheExam: "Start het examen",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Dialoogmodus",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Te veel lawaai, schakel over naar een rustige omgeving",
              normalNoiseDetection: "Geluidsdetectie is normaal",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Het volume is te laag, verhoog het volume",
              volumeDetectionIsNormal: "Volumedetectie normaal",
              noiseDetection: "Geluidsdetectie",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Blijf stil en neem 3s omgevingsgeluid op",
              endRecording: "Opname beëindigen",
              startRecording: "Begin met opnemen",
              volumeDetection: "Volumedetectie",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Lees "volumetest nu" in normaal volume"',
              playLastDetectedAudio: "Afspelen Laatste Detectie Audio",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Lees de vereisten zorgvuldig voordat u begint.",
              return: "Terugkeer",
              iGotIt: "Ik weet het.",
              timeLimitForSingleQuestionAnswering:
                "Beantwoordtijdslimiet voor één vraag",
              totalTimeLimit: "Totale tijdslimiet",
              unlimitedTime: "Onbeperkte tijd",
              stillNeedToCompleteTheTaskInTotal:
                "Het is nog steeds nodig om de taak te voltooien.",
              practice: "Oefening",
              second: "Tijden",
              taskScore: "Eén taakscore ≥",
              branch: "Punten",
              or: "Of",
              examScore: "Testscore ≥",
              remainingExamTimes: "Aantal examen resterende",
              totalScore: "Totaal score",
              Script: "(Prater",
              scoreEmotion: "Emotie",
              fractionExpression: "Sub-expressie",
              points: "Punten)",
              standardScore: "Standaard score",
              excellentScore: "Uitstekende score",
              exerciseInstructions: "Instructies voor oefeningen",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Om de vraag soepel te beantwoorden, moet u eerst de apparatuurtest voltooien:",
              camera: "Camera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Jezelf in de video kunnen zien, betekent dat de camera normaal is.",
              microphone: "Microfoon",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Spreek in de microfoon, zie het golfvormpatroon betekent dat de microfoon normaal is",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Leeg",
                  copyTo: "Kopiëren",
                  classification: "Indeling",
                  rename: "Naam wijzigen",
                  newlyBuild: "Nieuw",
                  categoryParent: "Superieure classificatie",
                  moveTo: "Verplaatsen",
                  name: "Naam",
                },
              },
              util: {
                rename: "Naam wijzigen",
                moveTo: "Verplaatsen",
                copyTo: "Kopiëren",
                delete: "Verwijderen",
                courseClassification: "Cursusclassificatie",
                newlyBuild: "Nieuw",
              },
            },
          },
          edit: { index: { curriculum: "Curriculum" } },
          index: {
            learn: "Leren",
            practice: "Oefen",
            examination: "Test",
            curriculum: "Curriculum",
            courseIntroduction: "Inleiding van de cursus:",
            createdOn: "Creatie tijd:",
            published: "Gepubliceerd",
            tasks: "Taken",
            unpublishedTasks: "Niet-gepubliceerde taak",
            task: "Taken",
            operation: "Operatie",
            learningMaterials: "Leermaterialen",
            goPractice: "Ga naar de praktijk",
            publishTask: "Publicatietaken",
            taskData: "Taakgegevens",
            edit: "Bewerken",
            copy: "Kopiëren",
            successfullyCopied: "Kopieer succes",
            delete: "Verwijderen",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Na verwijdering worden alle taakgegevens onder deze cursus gewist. Weet u zeker dat u de cursus verwijdert?",
            courseName: "Cursusnaam",
            creationTime: "Creatietijd",
            releaseStatus: "Publicatiestatus",
            whole: "Allemaal",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Geen geluid gedetecteerd",
              voice: "Stem",
              text: "Tekst",
              referenceScript: "Referentietoespraak:",
              clickOnTheRightSideToStartRecording:
                "Klik aan de rechterkant om de opname te starten",
              sendOut: "Verzenden",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "De huidige client is al verbonden met de ASR Websocket-server",
                afterProcessingTheLastFrameRestoreTheState:
                  "Na het verwerken van het laatste frame, herstel de staat",
                aSRConnectionClosed: "ASR-verbinding gesloten...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "De opname is gepauzeerd en is verwerkt, maar er zijn nog steeds gegevens in de cache",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Gevonden ASR-kanaal gesloten, opnieuw te creëren Websocket link",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Gevonden dat het ASR-kanaal niet open is, opnieuw te maken Websocket link",
                unableToRecord: "Kan niet opnemen:",
                creatingARecorderService: "Recorderservice maken",
                turnOffTheRecorderService: "Schakel de Recorder-service uit",
              },
              util: {
                recorded: "Opgenomen",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "De 16-bit pcm SendFrameSize moet een geheel veelvoud van 2 zijn",
                pCMSamplingRateShouldNotOccur:
                  "Pcm sampling rate mag niet verschijnen",
                andTheRequiredSamplingRate:
                  "En de vereiste bemonsteringsfrequentie",
                atypism: "Inconsequent",
                errorsThatShouldNotOccur: "Fouten die niet mogen optreden:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> Gegevens die niet in orde zijn, kunnen worden weggegooid als de vereisten niet hoog zijn. </Span>',
                flower: "Bloemen",
                TransferStopped: ": Overdracht gestopt",
              },
            },
            finishTest: {
              index: {
                endTest: "Eindtest",
                testResult: "Testresultaten",
                returnToCanvas: "Keer terug naar Canvas",
                retest: "Hertest",
              },
            },
          },
          index: {
            testFromCurrentNode: "Test van het huidige knooppunt",
            listening: "Radio in...",
            canvasTesting: "Canvas Test",
            endOfProcess: "Einde van het proces",
            restart: "Opnieuw opstarten",
            start: "Begin",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Analyse van instortingen",
                  expandParsing: "Breid Parse uit",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Te veel modale woorden",
                  second: "Tijden",
                  ratingOverview: "Scoren overzicht",
                  totalScore: "Totaal score",
                  section: "Nee.",
                  name: "Naam",
                  of: "(Totaal",
                  people: "Mensen)",
                  scriptScore: "Spraakscore",
                  common: "Totaal",
                  keyPoints: "Een belangrijk punt,",
                  notReached: "Niet bereikt,",
                  have: "Er zijn",
                  violationOfRegulations: "Overtredingen",
                  emotionalScore: "Emotiescore",
                  appear: "Verschijnen",
                  secondaryNegativeEmotions: "Secundaire negatieve emoties",
                  expressionScore: "Uitdrukkingsscore",
                  speakTooFast: "Te snel snel snel snel snel",
                  times: "Tijden,",
                  speakTooSlowly: "SNELHEID LANGZAAM",
                  average: "Gemiddeld",
                  wordminute: "Woord/minuut",
                },
              },
              score: {
                index: {
                  studentNodeName: "Naam studentenknooppunt",
                  keyPointName: "Naam sleutelpunt",
                  slideNodeName: "Naam van het schuifknooppunt",
                  dialogue: "Dialoog",
                  mainPoints: "Belangrijkste punten",
                  slide: "Glijbaan",
                  detectionResult: "Testresultaten",
                  satisfy: "Ontmoet",
                  dissatisfaction: "Niet tevreden",
                  score: "Score",
                  script: "Woorden",
                  semantics: "Semantisch",
                  scriptViolation: "Overtreding van de toespraak",
                  emotion: "Emotie",
                  expression: "Uitdrukking",
                },
              },
              taskTitle: { index: { branch: "Punten" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Deze keer",
                  lastTime: "Laatste keer",
                  comprehensiveEvaluation: "Uitgebreide evaluatie",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Standaard toespraak cumulatief',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Een woord, het aantal woorden geraakt door de student",
                  aWordspan: "Woord </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Times </span><br /><span style = "margin-left: 8px">· Langzame snelheid',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Te veel modale woorden',
                  complete: "Volledige inhoud",
                  keyHit: "Belangrijkste punten geraakt",
                  languageNorm: "Taalspecificatie",
                  fluentExpression: "Vlotte uitdrukking",
                  emotionallyPositive: "Emotioneel positief",
                  radarChart: "Radarkaart",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Aantal volledige stromen',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· Raak de belangrijkste punten',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· Ja',
                  dealingWithIllegalWordsspan:
                    "In strijd met het woord </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· Spreek te snel',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '</Span><br /><span style = "margin-left: 8px"> te veel modale woorden',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Negatieve emoties',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Scoor record",
                  firstRating: "Eerste keer rating",
                  totalScore: "Totaal score",
                  changeTo: "Verandering",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Communicatiefase",
                  communicationObjectives: "Communicatiedoelstellingen",
                  scoringCriteria: "Scorecriteria",
                  suggestionsForImprovement: "Aanbevelingen voor promotie",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Resterende:",
                  second: "Tijden",
                  doItAgain: "Nog een keer.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "De browser ondersteunt het afspelen van audio niet.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions:
                    "Opmerkingen en suggesties over spraak",
                  regenerate: "Regenereren",
                  generate: "Genereren",
                  generationFailed: "Bouwen mislukt",
                },
              },
              resultModal: {
                index: { return: "Terugkeer", viewDetails: "Details bekijken" },
              },
            },
            index: {
              reRatingSuccessful: "Succes opnieuw scoren",
              reRatingFailed: "Opnieuw scoren mislukt",
              downloadRecording: "Download opname",
              downloadText: "Tekst downloaden",
              download: "Download",
              reRating: "Opnieuw scoren",
              submitARatingAppeal: "Dien Grading Grievance in",
              reviewSubmission: "Inzending beoordelen",
              saveChanges: "Wijzigingen opslaan",
              previousSentence: "Vorige zin",
              nextSentence: "Volgende zin",
              score: "Beoordeling",
              scoringSituation: "Score",
              dialogueAnalysis: "Analyse van de dialoog",
            },
          },
          index: {
            ratingAppealInProgress: "Grieven scoren",
            operation: "Operatie",
            wrongQuestionSet: "Verkeerde probleemset",
            ratingUpdate: "Beoordelingsupdate",
            viewDetails: "Details bekijken",
            curriculum: "Curriculum",
            taskName: "Naam van de taak",
            taskType: "Taaktype",
            score: "Score",
            scoringResults: "Resultaten scoren",
            time: "Tijd",
            courseClassification: "Cursusclassificatie",
            whole: "Allemaal",
            learningTime: "Leertijd",
            historicalLearningData: "Historische leergegevens",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Oefenpauze",
              lastProgress: "Laatste vooruitgang",
              continuePracticing: "Blijf oefenen",
              practice: "Oefening",
              startPracticing: "Begin met oefenen",
              historicalData: "Historische gegevens",
              courseIntroduction: "Inleiding van de cursus:",
              exerciseDifficulty: "Moeilijkheidsgraad van de praktijk:",
              practiceCompletionRequirements:
                "Vereisten voor het voltooien van de oefening:",
              practiceFrequency: "Aantal oefeningen ≥",
              second: "Tijden",
              anyScore: "Elke score ≥",
              branch: "Punten",
              or: "Of",
              taskList: "Taaklijst",
              practiceTime: "Oefentijd:",
              to: "Naar",
              unlimitedTime: "Onbeperkte tijd",
              completionStatus: "Voltooiingsstatus",
              numberOfExercises: "Aantal oefeningen",
              maximumScore: "Hoogste score",
              operation: "Operatie",
              enteringLearning: "Voer het leren in",
              historicalLearningData: "Historische leergegevens",
              wrongQuestionSet: "Verkeerde probleemset",
            },
          },
          index: {
            practiceTasks: "Oefen taak",
            examTasks: "Onderzoekstaken",
            task: "Taken",
          },
          learn: {
            index: {
              courseName: "Cursusnaam",
              courseIntroduction: "Introductie van de cursus",
              learningContent: "Inhoud leren",
              downloadLearningMaterials: "Leermateriaal downloaden",
              startPracticing: "Begin met oefenen",
            },
          },
          test: {
            index: {
              taskList: "Taaklijst",
              courseIntroduction: "Inleiding van de cursus:",
              examDifficulty: "Moeilijkheidsgraad van het examen:",
              requirementsForPassingTheExam:
                "Vereisten voor het slagen voor het examen:",
              examScore: "Testscore ≥",
              branch: "Punten",
              examTime: "Onderzoekstijd:",
              to: "Naar",
              unlimitedTime: "Onbeperkte tijd",
              completionStatus: "Voltooiingsstatus",
              completionTimes: "Aantal voltooiingen",
              maximumScore: "Hoogste score",
              operation: "Operatie",
              immediateExam: "Onmiddellijk onderzoek",
              history: "Geschiedenis",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Referentietoespraak" } },
              flow: {
                index: {
                  wrongQuestionItem: "Verkeerd artikel (",
                  score: "Score",
                  TotalScore: "/Totale score",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Serverfout" } },
                  index: {
                    startRecording: "Begin met opnemen",
                    endRecording: "Opname beëindigen",
                    startPracticing: "Begin met oefenen",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Voer alstublieft in",
                    sendOut: "Verzenden",
                    startPracticing: "Begin met oefenen",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Begin met opnemen",
                    endRecording: "Opname beëindigen",
                    startPracticing: "Begin met oefenen",
                  },
                  hook: { index: { failInSend: "Verzenden mislukt" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Scoor van deze verkeerde oefening",
                  tips: "Prompt",
                  areYouSureToEnd: "Einde bevestigen?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Oefen opnieuw en beheerst de verkeerde vragen niet.",
                  continue: "Doorgaan",
                  returnToTheSetOfIncorrectQuestions:
                    "Keer terug naar de verkeerde vragenset",
                  end: "Einde",
                  wrongQuestionItem: "Verkeerd artikel",
                  numberOfExercisesThisTime: "Het nummer van deze oefening",
                  masteringTheSituation: "Grijp de situatie",
                  mastered: "Mastering",
                  notMastered: "Niet onder de knie",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Oefen deze vraag opnieuw uit",
                  nextQuestion: "Volgende vraag",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Verkeerde oefening",
                endExercise: "Beëindig oefening",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Verkeerde oefening",
                endExercise: "Beëindig oefening",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Verkeerde oefening",
                endExercise: "Beëindig oefening",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Speech bekijken",
                  section: "Nee.",
                  secondaryDiscourseTechnique: "Secundaire spraak",
                },
              },
              tableItem: {
                count: { second: "Tijden" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Er is geen gerelateerde informatie gevonden",
                  recallWords: "Word terugroepen",
                  branch: "Punten",
                  answerIncorrectly: "Verkeerd antwoord",
                  second: "Tijden",
                  wrongAnswerRate: "Verkeerd antwoordpercentage",
                },
                standardScript: {
                  standardScript: "Standaard toespraak",
                  myScript: "Mijn woorden",
                },
                status: {
                  mastered: "Mastering",
                  notMastered: "Niet onder de knie",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "Totaal aantal antwoorden",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Aantal keren dat er verkeerde vragen zijn geoefend",
                },
              },
            },
            index: {
              wrongQuestionList: "Lijst van verkeerde vragen",
              wrongQuestionSet: "Verkeerde probleemset",
              mispracticeExercises: "Verkeerde oefening",
              taskName: "Naam van de taak",
              staff: "Werknemer",
              masteringTheSituation: "Grijp de situatie",
              whole: "Allemaal",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Aantal oefeningen ≥",
              second: "Tijden",
              or: "Of",
              excellentScoreAtAnyOneTime: "Elke score uitstekend (≥",
              points: "Punten)",
              examScore: "Testscore ≥",
              branch: "Punten",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Willekeurige gemiddelde verdeling",
              assignToDesignatedPersonnel: "Toewijzen aan aangewezen personen",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Succes bij het verhogen van het aantal examens",
              failedToIncreaseTheNumberOfExams: "Verhoog het aantal testfouten",
              increaseTheNumberOfExams: "Verhoog het aantal examens",
              selectObjects: "Object selecteren",
            },
          },
          executionFilter: {
            index: { participationRate: "Deelnamepercentage" },
          },
          intelligentReview: {
            index: {
              overallReview: "Algemene beoordeling",
              pleaseEnterTheReviewCriterianotMandatory:
                "Voer de commentaarcriteria in (niet vereist)",
              commentaryOnSingleSentenceRhetoric: "Opmerkingen over enkele zin",
              realTimeReview: "Realtime beoordelingen",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Gemiddelde testscore",
            averageExamDurationmin: "Gemiddelde examenduur (min)",
            accumulatedPracticeDurationmin: "Cumulatieve oefenduur (min)",
            accumulatedPracticeTimes: "Cumulatief aantal oefeningen",
            totalScore: "Totaal score",
            average: "Gemiddeld",
            total: "Totaal",
            keyHitCount: "Belangrijkste punten raken telling",
            practiceAverageScore: "Oefen gemiddelde score",
            averagePracticeDurationmin: "Gemiddelde duur van de oefening (min)",
            topOfTheHighestScore: "De hoogste score Top3",
            branch: "Punten",
            errorProneItemTop: "Foutgevoelige artikelen Top5",
            hitRate: "Hitsnelheid",
            hitRateOfKeyPointsInScript: "Spraak Belangrijkste punten hit rate",
            averageScriptScore: "Gemiddelde spraakscore",
            sortByProcess: "Sorteren op proces",
            sortByScore: "Sorteren op score",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Gemiddelde score van deze pagina/totale score van deze pagina",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "De gemiddelde score van dit item/de totale score van dit item",
            details: "Details",
            viewRules: "Regels bekijken",
            standardScript: "Standaard toespraak",
          },
          scoreDetailList: {
            section: "Nee.",
            second: "Tijden",
            practice: "Oefening",
            examination: "Onderzoek",
            notMeetingStandards: "Niet aan de norm",
            meetingStandards: "Standaard",
            excellent: "Uitstekend",
            frequency: "Aantal keren",
            fraction: "Fractie",
            result: "Resultaten",
            operation: "Operatie",
            viewDetails: "Details bekijken",
          },
          index: {
            common: "Totaal",
            people: "Mensen",
            staff: "Werknemer",
            notMeetingStandards: "Niet aan de norm",
            meetingStandards: "Standaard",
            excellent: "Uitstekend",
            notStarted: "Niet begonnen",
            haveInHand: "In uitvoering",
            completed: "Voltooide",
            passed: "Overgegaan",
            hangInTheAir: "Onvoltooid",
            failed: "Niet geslaagd",
            unlimitedTime: "Onbeperkte tijd",
            to: "Naar",
            maximumScoringCriteria: "Hoogste scorecriteria",
            completionStatus: "Voltooiingsstatus",
            employeeName: "Naam werknemer",
            frequency: "Aantal keren",
            practice: "Oefening",
            examination: "Onderzoek",
            average: "Gemiddeld",
            speechCraftAvgScore: "Gemiddelde spraakscore",
            emotionAvgScore: "Gemiddelde emotionele score",
            speedAvgScore: "Express gemiddelde score",
            flowAvgScore: "Gemiddelde processcore",
            practiceCostTime: "Cumulatieve duur",
            participateCommitRate: "Nalevingspercentage",
            participateExcellentRate: "Uitstekend tarief",
            duration: "Duur",
            score: "Score",
            scoringResults: "Resultaten scoren",
            flowScore: "Procescore",
            craftScore: "Spraakscore",
            emotionScore: "Emotiescore",
            speedScore: "Uitdrukkingsscore",
            contentIntegrity: "Inhoud integriteit",
            pointHitRate: "Belangrijkste punten hitpercentage",
            emotionNegativeRate: "Negatief aandeel van emotie",
            paraPhaSiaRate: "Proportie van abnormale spraaksnelheid",
            toneOvermuchRate: "Overmatige proportie van modale woorden",
            maximumScore: "Hoogste score",
            highestScoreResult: "Hoogste score resultaten",
            state: "Status",
            averageDurationmin: "Gemiddelde duur (min)",
            lately: "Onlangs",
            time: "Tijd",
            operation: "Operatie",
            viewDetails: "Details bekijken",
            wrongQuestionSet: "Verkeerde probleemset",
            taskName: "Naam van de taak",
            completeRequirements: "Volledige eisen",
            publishTo: "Objecten publiceren",
            taskTime: "Taaktijd",
            fractionalComposition: "Fractie samenstelling",
            totalScore: "Totaal score",
            branch: "Punten",
            Script: "(Prater",
            scoreEmotion: "Emotie",
            fractionExpression: "Sub-expressie",
            points: "Punten)",
            implementationRate: "Deelnamepercentage",
            numberOfExecutors: "Aantal deelnemers",
            numberOfPeopleNotExecuted: "Aantal niet-deelnemers",
            completionRate: "Volgingspercentage",
            numberOfCompletedPersonnel: "Aantal voltooid",
            numberOfUnfinishedPersonnel: "Aantal onafgemaakte",
            complianceRate: "Nalevingspercentage",
            numberOfQualifiedPersonnel: "Aantal bereiken",
            numberOfNonCompliantIndividuals:
              "Aantal mensen dat niet aan de norm voldoet",
            excellentRate: "Uitstekend tarief",
            participateCompleteRate: "Volgingspercentage deelname",
            numberOfOutstandingIndividuals: "Uitstekende nummers",
            numberOfNonOutstandingIndividuals: "Niet-uitstekend nummer",
            export: "Uitvoer",
            taskData: "Taakgegevens",
            resultAnalysis: "Analyze van resultaten",
            scoreDetails: "Score details-",
            dataOverview: "Gegevensoverzicht",
            taskDetail: "Taakdetails",
            scriptAnalysis: "Spraakanalyse",
            flowAnalysis: "Procesanalyse",
            expressionAnalysis: "Expressie analyze",
            emotionAnalysis: "Sentiment analyze",
            byTime: "Tijddimensieweergave",
            byNumber: "Graaddimensie weergave",
            personData: "Statistieken door personeel",
            numberData: "Detail per record",
            count: "Op aantal keren",
            person: "Door aantal mensen",
            reachTimes: "Aflezingen",
            outOfReachTimes: "Onbereikte tijden",
            seeOutOfReach: "Klik om onbereikt te bekijken",
            accumulative: "Cumulatief",
            script: "Woorden",
            times: "Tijden",
            sentence: "Zin",
            expressionAverage: "Gemiddelde expressiescore",
            modalAnalysis: "Analyse van modaal deeltje",
            total: "Totaal",
            muchModalAnalysis: "Te veel modale woorden",
            normal: "Normaal",
            speedAnalysis: "Spraaksnelheidsanalyse",
            speedDistribution: "Spraaksnelheid distributie",
            speakingSpeed: "Spreeksnelheid",
            normalSpeak: "Normale spraaksnelheid",
            tooFastSpeak: "Speedy Speedy",
            tooSlowlySpeak: "Langzame snelheid",
            violationWord: "Overtreding van woorden",
            violationWordAnalysis: "Analyse van overtredingswoorden",
            noData: "Nee",
            emotionalScore: "Emotiescore",
            emotionalDistribution: "Verdeling van emotie",
            negativeEmotion: "Negatieve emotie",
            normalEmotion: "Normale emotie",
            getDetail: "Details bekijken",
            barPattern: "Barweergave",
            timeDimension: "Tijdbreedte-weergave",
            timesDimension: "Graaddimensie weergave",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Gemiddeld",
                  averageScoreOfScript: "Gemiddelde spraakscore",
                  averageEmotionalScore: "Gemiddelde emotionele score",
                  expressionAverageScore: "Express gemiddelde score",
                  processAverageScore: "Gemiddelde processcore",
                  accumulatedDuration: "Cumulatieve duur",
                  accumulatedTimes: "Cumulatieve tijden",
                  participationComplianceRate: "Deelnamepercentage",
                  participationRateForAchievingExcellence: "Deelnamepercentage",
                  branch: "Punten",
                  numberOfPeople: "Aantal mensen",
                  averageScoreOfExpression: "Gemiddelde expressiescore",
                  people: "Mensen",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Negatieve emotie" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Gemiddelde spraakscore",
                  branch: "Punten",
                },
                useSummaryDetail: {
                  keyHitRate: "Belangrijkste punten hitpercentage",
                },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Slimme recensies",
            pleaseImproveTheConfiguration:
              "Maak de configuratie alstublieft perfect",
            examResults: "Examenresultaten",
            showTheResultsAfterEachExam: "Toon de resultaten na elk examen.",
            showTheResultsAfterTheLastExam:
              "Toon de resultaten na het laatste examen.",
            doNotDisplayResults: "Laat geen resultaten zien",
            resultDisplay: "Resultaten tonen",
            score: "Score",
            scoringResults: "Resultaten scoren",
            allowViewingScoreDetails: "Toestaan om score details te bekijken",
            viewCanvasConfiguration: "Canvas configuratie bekijken",
            synchronizationFailedProcessNodeHasChanged:
              "Synchronisatie is mislukt, procesknooppunt heeft wijzigingen gegenereerd",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Synchronisatie is succesvol, klik op Publiceren om de cursus bij te werken",
            retrievedTheLatestCanvasConfiguration:
              "Nieuwste canvasconfiguratie getrokken",
            synchronizeCanvasConfiguration: "Synchroniseer canvasconfiguratie",
            scoringMethod: "Scoremethode",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Punten, stroom {0} punten, spraakvaardigheid {1} punten, emotie {2} punten, snelheid {3} punten)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "De standaard van uitmuntendheid moet hoger zijn dan de nalevingsnorm.",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "De scorecriteria moeten groter zijn dan 0% en minder dan of gelijk aan 100",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "De scorecriteria moeten groter zijn dan 0 punten en kleiner dan of gelijk aan {0} punten.",
            doRatingsRequireManualReview:
              "Of scoren handmatig moet worden beoordeeld",
            allocationStrategy: "Toewijzingsbeleid",
            pleaseSelectTheAssignedPersonnel:
              "Selecteer een toegewezen persoon",
            pleaseSelectADesignatedPerson: "Selecteer een aangewezen persoon",
            timeLimitForSingleQuestionAnswering:
              "Beantwoordtijdslimiet voor één vraag",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Publiceer standaard naar nieuw toegevoegde werknemers",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Opnieuw scoren geeft aan dat de score wordt vergeleken volgens de scoreregels van het nieuwste canvas. De huidige taak heeft de scoregegevens handmatig beoordeeld. Wil je de score overschrijven?",
            notCovered: "Niet gedekt",
            cover: "Cover",
            participationSituation: "Deelname",
            numberOfParticipants: "Aantal deelnemers",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Som het aantal oefeningsobjecten dat voor deze taak is geselecteerd",
            sumOfTheNumberOfParticipantsInTheTask:
              "Som het aantal mensen dat heeft deelgenomen aan de taak",
            numberOfCompletedPersonnel: "Aantal voltooid",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Vergroot het aantal mensen dat voldoet aan de vereisten voor het voltooien van de taak",
            numberOfQualifiedPersonnel: "Aantal bereiken",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Verhoog het aantal mensen dat aan de taakscore voldoet",
            excellentPersonnel: "Uitstekende nummers",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Verhoog het aantal mensen dat de taak met uitstekende score ontmoet",
            participationRate: "Deelnamepercentage",
            numberOfParticipantsNumberOfParticipants:
              "Aantal deelnemers/aantal deelnemers",
            numberOfCompletedNumberOfParticipants:
              "Aantal Voltooide/Aantal Moeten Deelnemen",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Aantal bereiken/aantal deelnemers",
            excellentNumbernumberOfParticipants:
              "Uitstekend aantal/aantal deelnemers",
            participationCompletionRate: "Volgingspercentage deelname",
            numberOfCompletedParticipated: "Aantal Voltooide/Deelnemende",
            participationComplianceRate: "Deelnamepercentage",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Aantal Vereising/Deelname",
            participationRateForAchievingExcellence: "Deelnamepercentage",
            accumulatedDuration: "Cumulatieve duur",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Deelnemen aan de duur van de taak en de som",
            averageDuration: "Gemiddelde duur",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Totale duur van de deelnemers/totaal aantal deelnemers",
            accumulatedTimes: "Cumulatieve tijden",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Som het aantal deelnemers aan de taak",
            averageScore: "Gemiddeld",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Gemiddelde score van personeel dat bij de taak betrokken is",
            maximumScore: "Hoogste score",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "De hoogste score van het personeel dat bij de taak betrokken is",
            averageScoreOfScript: "Gemiddelde spraakscore",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Spraakscore voor studenten Sum/Totale deelnametijden",
            totalScoreOfScript: "Totale score van spraak",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Totale spraakscore ingesteld onder deze taak",
            processAverageScore: "Gemiddelde processcore",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Som van studentenprocesscore/totaal aantal opdrachten",
            totalProcessScore: "Totale processcore",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Totale score van het proces ingesteld onder deze taak",
            averageEmotionalScore: "Gemiddelde emotionele score",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Som van emotiescore in opleiding/totaal aantal opdrachten",
            totalEmotionalScore: "Totale emotionele score",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Totale emotionele score vastgesteld onder deze taak",
            averageScoreOfExpression: "Gemiddelde expressiescore",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Som van de expressiescore van de leerling/totaal aantal opdrachten",
            totalScoreOfExpression: "Uitdrukking totale score",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Totale score van expressie ingesteld onder deze taak",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Start opnieuw scoren uit te voeren, kunt u naar het taakcentrum om de voortgang te bekijken.",
            reRating: "Opnieuw scoren",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Opnieuw scoren geeft aan dat de score wordt vergeleken volgens de scoreregels van het nieuwste canvas en dat de huidige score wordt overschreven.",
            releaseTime: "Release tijd",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Beoordeeld",
              pendingReview: "Te beoordelen",
              enterKeywordSearch: "Voer trefwoorden in om te zoeken",
            },
            pageRightTable: {
              index: {
                employeeName: "Naam werknemer",
                systemRating: "Systeem score",
                scoringResults: "Resultaten scoren",
                duration: "Duur",
                time: "Tijd",
                state: "Status",
                whetherToModifyTheRating: "Correcte score",
                finalScore: "Laatste score",
                reviewedBy: "Recensent",
                operation: "Operatie",
                viewScoreDetails: "Bekijk score details",
                viewStatus: "Status bekijken",
                whole: "Allemaal",
                correctionStatus: "Correctiestatus",
                completeReview: "Volledige beoordeling",
                tips: "Prompt",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "De definitieve resultaten van de stagiairs worden onderworpen aan de huidige definitieve resultaten van het onderzoek. Gelieve te bevestigen",
              },
            },
          },
          index: { manualReview: "Handmatige beoordeling" },
        },
        review: {
          index: {
            employeeName: "Naam werknemer",
            score: "Score",
            scoringResults: "Resultaten scoren",
            duration: "Duur",
            taskName: "Naam van de taak",
            numberOfAppealScripts: "Aantal grieven",
            appealTime: "Beroepstijd",
            processStatus: "Processtatus",
            scoreAfterReview: "Scoor na beoordeling",
            reviewedBy: "Recensent",
            operation: "Operatie",
            viewScoreStatus: "Bekijk score",
            whole: "Allemaal",
            manualReview: "Handmatige beoordeling",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Oefening Taakgegevens",
            implementationRate: "Implementatiegraad",
            numberOfExecutors: "Aantal uitvoering",
            numberOfPeopleNotExecuted: "Aantal niet-uitgevoerd",
            completionRate: "Volgingspercentage",
            numberOfCompletedPersonnel: "Aantal voltooid",
            numberOfUnfinishedPersonnel: "Aantal onafgemaakte",
            complianceRate: "Nalevingspercentage",
            numberOfQualifiedPersonnel: "Aantal bereiken",
            numberOfNonCompliantIndividuals:
              "Aantal mensen dat niet aan de norm voldoet",
            excellentRate: "Uitstekend tarief",
            numberOfOutstandingIndividuals: "Uitstekende nummers",
            numberOfNonOutstandingIndividuals: "Niet-uitstekend nummer",
            examTaskData: "Taakgegevens van het examen",
            published: "Gepubliceerd",
            courses: "Een cursus",
            courseData: "Cursusgegevens",
            practiceTasks: "Oefen taak",
            examTasks: "Onderzoekstaken",
          },
          practiceSummary: {
            practiceSummary: "Oefenoverzicht",
            numberOfPracticeTasks: "Aantal praktijktaken",
            numberOfExercises: "Aantal oefeningen",
            accumulatedPracticeDuration: "Cumulatieve oefenduur",
            complianceRate: "Nalevingspercentage",
            excellentRate: "Uitstekend tarief",
            ranking: "Ranglijst",
          },
          rankingList: {
            numberOfExercises: "Aantal oefeningen",
            exerciseDuration: "Oefenduur",
            complianceRate: "Nalevingspercentage",
            excellentRate: "Uitstekend tarief",
            diligenceChart: "Lijst van diligentie",
            excellentList: "Uitstekende lijst",
            ranking: "Ranglijsten",
            fullName: "Naam",
            i: "Ik...",
          },
          taskCard: {
            end: "Deadline:",
            noDeadline: "Geen deadline",
            second: "Tijden",
            branch: "Punten",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Geen taken",
            completed: "Voltooide",
          },
          filter: { employeeDepartment: "Werknemersafdeling" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Exporteren is succesvol, ga naar het taakcentrum om te bekijken",
              export: "Uitvoer",
            },
          },
          filterConfig: {
            dropdown: { custom: "Aangepast" },
            modal: {
              updateSuccessful: "Update geslaagd",
              successfullySaved: "Bespaar met succes",
              setAsACommonReport: "Ingesteld op gemeenschappelijke rapporten",
              updateCurrentReport: "Actueel rapport bijwerken",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Gemiddeld",
                  averageScoreOfScript: "Gemiddelde spraakscore",
                  averageEmotionalScore: "Gemiddelde emotionele score",
                  expressionAverageScore: "Express gemiddelde score",
                  processAverageScore: "Gemiddelde processcore",
                  branch: "Punten",
                },
              },
              comprehensiveEvaluation: { const: { data: "Gegevens" } },
              doneStatus: {
                index: {
                  practice: "Oefening",
                  examination: "Onderzoek",
                  executed: "Uitvoeren",
                  unexecuted: "Niet uitgevoerd",
                  accumulatedExamDuration: "Cumulatieve testduur",
                  examFrequency: "Aantal examens",
                },
              },
              filter: {
                index: {
                  practice: "Oefening",
                  examination: "Onderzoek",
                  pleaseCompleteTheRequiredFields:
                    "Voltooi de vereiste artikelen",
                  staff: "Werknemer",
                  taskType: "Taaktype",
                  pleaseSelect: "Selecteer alstublieft",
                  taskName: "Naam van de taak",
                },
              },
            },
            index: {
              completionStatus: "Voltooiingsstatus",
              comprehensiveEvaluation: "Uitgebreide evaluatie",
              abilityGrowthTrend: "Tendens van capaciteitsgroei",
              errorProneAnalysis: "Foutgevoelige puntanalyse",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Actief nummer",
            accumulatedPracticeDuration: "Cumulatieve oefenduur",
            averagePracticeDuration: "Gemiddelde trainingstijd",
            people: "Mensen",
            numberOfPracticeTasks: "Aantal praktijktaken",
            numberOfExercises: "Aantal oefeningen",
            exerciseDuration: "Oefenduur",
            second: "Tijden",
            avgScore: "Gemiddeld",
            speechCraftAvgScore: "Gemiddelde spraakscore",
            emotionAvgScore: "Gemiddelde emotionele score",
            speedAvgScore: "Express gemiddelde score",
            flowAvgScore: "Gemiddelde processcore",
            practiceCostTime: "Cumulatieve duur",
            totalPracticeTimes: "Cumulatieve tijden",
            participateCommitRate: "Deelnamepercentage",
            participateExcellentRate: "Deelnamepercentage",
          },
        },
        index: {
          employeeProfile: "Werknemersportret",
          overview: "Overzicht",
          personalSignage: "Persoonlijke Kanban",
          ongoingExercises: "Oefening aan de gang",
          ongoingExams: "Onderzoeksonderzoek",
          practiceTaskProgress: "Voortgang van de trainingstaak",
          examTaskProgress: "Voortgang van testtaken",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Selecteer een conceptwoord",
              sessionDetectionSettings: "Sessiedetectie-instellingen",
              successfullySaved: "Bespaar met succes",
              abnormalSpeechSpeed: "Abnormale spraaksnelheid",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "De door de gebruiker gedefinieerde spraaksnelheidsdetectiestandaard wordt ondersteund. Het resultaat is een normale spraaksnelheid, een te hoge spraaksnelheid en een te lage spraaksnelheid. Wanneer de spraaksnelheid abnormaal is (te snel/te langzaam), wordt de score verlaagd volgens de scoringsregels.",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Speedtoo snel: meer dan een minuut",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Woord, te traag: minder dan per minuut",
              charactersIgnoringLessThan: "Woorden, negeer minder",
              aSentenceOfWords: "Zin van woorden",
              excessiveModalParticles: "Te veel modale woorden",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Ondersteuning van aangepaste toon woorden, en stel de regels van te veel toon woorden. Wanneer er te veel modale woorden zijn, wordt de score verlaagd volgens de scoringsregels.",
              theNumberOfRepetitionsIs: "Het aantal herhalingen is",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "De tweede keer wordt vastgesteld dat er te veel modale woorden zijn.",
              definitionOfSensitiveViolations:
                "Definitie van gevoelige schending",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "U kunt het lexicon van illegale woorden aanpassen om te detecteren of er illegale woorden in een zin voorkomen. Wanneer er een illegaal woord is, wordt de score verlaagd volgens de scoringsregels.",
              violationWords: "Overtredingen woorden:",
              emotionalDetection: "Emotie detectie",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Volgens de systeememotiemodeldialoog wordt de emotietest beoordeeld als normale emotie en negatieve emotie (inclusief negatief, boos en klagen). Wanneer er een negatieve emotie is, wordt de score verlaagd volgens de scoringsregels.",
              preserve: "Bespaar",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Beoordelingsdimensie",
              ratingPoints: "Punten scoren",
              explain: "Beschrijving",
              type: "Soort",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Willekeurige gegevens" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Standaard toespraak cumulatief',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Een woord, het aantal woorden geraakt door de student",
                  aWordspan: "Woord </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Times </span><br /><span style = "margin-left: 8px">· Langzame snelheid',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Te veel modale woorden',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "De score is" },
                  item: {
                    violationOnceReduced: "1 overtreding minus",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Abnormale snelheid (inclusief te hoge snelheid en te lage snelheid) 1 afname",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Te veel modale woorden 1 minus",
                    oneNegativeDecreaseInEmotions: "Negatieve emotie minus 1",
                    branch: "Punten",
                  },
                  wrap: { name: "Naam", preserve: "Bespaar" },
                },
              },
              index: {
                complete: "Volledige inhoud",
                keyHit: "Belangrijkste punten geraakt",
                accurateFollowupReading: "Nauwkeurig lezen",
                languageNorm: "Taalspecificatie",
                speechSpeed: "Snelheid van spraak",
                modalParticles: "Modaal woord",
                emotionallyPositive: "Emotioneel positief",
                scoringModel: "Scoremodel",
                successfullyModified: "Wijziging geslaagd",
                newSuccessfullyCreated: "Nieuw succes",
              },
            },
            index: {
              scoringModel: "Scoremodel",
              newModel: "Nieuw model",
              model: "Model",
              type: "Soort",
              operation: "Operatie",
              preview: "Voorbeeld",
              edit: "Bewerken",
            },
          },
          index: {
            scoringModel: "Scoremodel",
            ratingDimension: "Beoordelingsdimensie",
            sessionDetectionSettings: "Sessiedetectie-instellingen",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Selecteer alstublieft",
                hit: "Hit",
                misses: "Gemiste",
              },
            },
          },
          index: {
            duration: "Duur",
            processScore: "Procescore",
            branch: "Punten",
            contentCompleteness: "Inhoud integriteit",
            scriptScore: "Spraakscore",
            keyHitRate: "Belangrijkste punten hitpercentage",
            emotionalScore: "Emotiescore",
            negativeProportionOfEmotions: "Negatief aandeel van emotie",
            expressionScore: "Uitdrukkingsscore",
            theProportionOfAbnormalSpeechSpeed:
              "Proportie van abnormale spraaksnelheid",
            excessiveProportionOfModalParticles:
              "Overmatige proportie van modale woorden",
            learningRecordData: "Gegevens over het leren van records",
            staff: "Werknemer",
            curriculum: "Curriculum",
            mainPoints: "Belangrijkste punten",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Tafel",
                        lineChart: "Lijnkaart",
                        barChart: "Histogram",
                        pieChart: "Taart",
                      },
                      index: { switchingTypes: "Type schakelaar" },
                    },
                  },
                  index: {
                    saveSuccessful: "Bespaar met succes",
                    editChart: "Grafiek bewerken",
                    chartName: "Grafieknaam",
                    deleteSuccessful: "Met succes verwijderd",
                    refresh: "Vernieuwen",
                    export: "Uitvoer",
                    areYouSureToDeleteIt:
                      "Weet u zeker dat u wilt verwijderen?",
                    delete: "Verwijderen",
                    viewFilteringCriteria: "Filtercriteria bekijken",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Exporteren is succesvol, ga naar het taakcentrum om te bekijken",
              },
            },
            header: {
              index: {
                saveSuccessful: "Bespaar met succes",
                deleteSuccessful: "Met succes verwijderd",
                editReport: "Rapport bewerken",
                reportName: "Naam rapport",
                refresh: "Vernieuwen",
                areYouSureToDeleteIt: "Weet u zeker dat u wilt verwijderen?",
                delete: "Verwijderen",
                exportToExcel: "Excel exporteren",
                exportSnapshot: "Snapshot exporteren",
                export: "Uitvoer",
                authority: "Toestemmingen",
                permissionSettings: "Toestemming-instellingen",
                reportVisiblePermissions: "Zichtbare machtigingen melden",
                visibleToEveryone: "Zichtbaar voor iedereen",
                onlyVisibleToOneself: "Alleen zichtbaar voor zichzelf",
                designatedPersonnelVisible: "Gespecificeerde persoon zichtbaar",
                pleaseSelect: "Selecteer alstublieft",
                reportDataPermissions: "Gegevenstoestemming rapporteren",
                restrictedByUserPermissions:
                  "Beperkt door gebruikersmachtigingen",
                visiblePeopleAreTheSame: "Zichtbare mensen zijn hetzelfde.",
              },
            },
            titleFooter: {
              index: { addReport: "Nieuw rapport", reportName: "Naam rapport" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Exporteren is succesvol, ga naar het taakcentrum om te bekijken",
            dataQueryInProgress: "Gegevensquery in...",
            customKanban: "Aangepaste Kanban",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Tafel",
                lineChart: "Lijnkaart",
                barChart: "Histogram",
                pieChart: "Taart",
              },
              index: {
                successfullyAdded: "Succesvol toegevoegd",
                viewSQL: "Weergave SQL",
                export: "Uitvoer",
                addToReport: "Toevoegen aan Rapport",
              },
            },
            refer: {
              hooks: {
                data: "Gegevens",
                interrogativeSentence: "Vragen",
                historicalQuery: "Historische vraag",
              },
              index: { copySuccessful: "Kopieer succes", copy: "Kopiëren" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Niet aan de norm",
                        meetingStandards: "Standaard",
                        excellent: "Uitstekend",
                        notStartedYet: "Niet begonnen",
                        inProgress: "In uitvoering",
                        completed: "Voltooide",
                        incomplete: "Onvoltooid",
                      },
                    },
                  },
                  const: {
                    taskName: "Naam van de taak",
                    department: "Afdeling",
                    studentName: "Naam van de student",
                    scoringResults: "Resultaten scoren",
                    taskCompletionStatus: "De voltooiingsstatus van de taak",
                  },
                  index: { pleaseSelectADimension: "Selecteer een dimensie" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Voer @ in om de bullet box te openen voor dimensieselectie",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Voer de queryverklaring in om rechtstreeks te vragen",
                query: "Vraag",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Exporteren is succesvol, ga naar het taakcentrum om te bekijken",
            dataQueryInProgress: "Gegevensquery in...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Zoekwoord zoeken",
                previous: "Vorige",
                next: "Volgende",
                theObjectThatTriggersTheScrollingEventIs:
                  "Het object dat de scroll-gebeurtenis activeert is",
                userScrolling: "Gebruiker Scroll",
              },
            },
            summary: {
              index: {
                reminder: "Herinnering:",
                edit: "Bewerken",
                name: "Naam",
                hintLanguage: "Prompt",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Uitzichtpunt extractie" },
            },
            outline: {
              index: {
                numberOfKeywords: "Aantal trefwoorden",
                speaker: "Luidspreker",
                speechDuration: "Spreekduur",
                proportionOfSpeaking: "Spreekverhouding",
                maximumSpeechDuration: "Maximale lengte van de spraak",
                numberOfQuestions: "Aantal vragen",
                averageSpeechSpeed: "Gemiddelde snelheid",
                conversationalActions: "Sessie Actie",
                contentSummary: "Samenvatting van de inhoud",
                generateSummary: "Samenvatting genereren",
                keywordCloud: "Trefwoorden woord cloud",
              },
            },
          },
          index: {
            outline: "Samenvatting",
            viewpoint: "Advies",
            contentSummary: "Samenvatting van de inhoud",
            viewpointExtraction: "Uitzichtpunt extractie",
            summaryOfKeyPoints: "Samenvatting van de belangrijkste punten",
            videoAnalysis: "Videoanalyse",
          },
        },
        list: {
          index: {
            delete: "Verwijderen",
            uploadSuccessful: "Succesvol uploaden",
            videoList: "Videolijst",
            uploadVideo: "Video uploaden",
            videoName: "Videonaam",
            uploadTime: "Uploadtijd",
            endTime: "Eindtijd",
            analyzeProgress: "Analyseer de voortgang",
            haveInHand: "In uitvoering",
            completed: "Voltooide",
            fail: "Mislukt",
            toBegin: "Om te beginnen",
            operation: "Operatie",
            see: "Weergave",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Werktijd toevoegen" },
        },
      },
      index: {
        businessWorkingHours: "Zakelijke werktijden",
        newlyBuild: "Nieuw",
        name: "Naam",
        describe: "Beschrijving",
        operation: "Operatie",
        edit: "Bewerken",
        delete: "Verwijderen",
        editBusinessWorkingHours: "Zakelijke werktijden bewerken",
        newBusinessWorkingHours: "Nieuwe zakelijke werktijden",
        workingHours: "Werktijd",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Intelligente extractie",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Weet je zeker dat je wilt afzeggen? Annulering is niet mogelijk",
        },
      },
    },
    accountManagement: {
      securitySetting: "Beveiligingsinstellingen",
      accountManagement: "Accountbeheer",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Naar",
            switchToIPAddressMode: "Schakel over naar IP-adresmodus",
            switchToIPSegmentMode: "Schakel over naar IP-segmentmodus",
          },
        },
      },
      index: {
        saveSuccessful: "Bespaar met succes",
        pleaseImproveTheConfiguration:
          "Maak de configuratie alstublieft perfect",
        securitySetting: "Beveiligingsinstellingen",
        employeeLoginIPRestrictions: "IP-beperkingen voor login van werknemers",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Beperk het IP-bereik waarop werknemers kunnen inloggen. Login is niet toegestaan buiten het bereik. Alleen geldig voor websites, mobiele apps zijn niet beperkt en beheerders zijn niet beperkt.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Voer bij het toevoegen of wijzigen het IP-segment van het externe netwerk (hetzelfde C-segment) of een vast extern netwerk-IP-adres in.",
        allowLoginIPRange: "IP-bereik toegestaan om in te loggen",
        preserve: "Bespaar",
      },
    },
    coachAdminEntry: { speechRecognition: "Spraakherkenning" },
    billingCenter: {
      template: {
        details: "Details",
        languageType: "Taalsoort",
        aSREngine: "ASR-motor",
        voiceQualityQuota: "Quota van stemkwaliteit",
        realtimeData: "Real-time gegevens",
        calculatedByDay: "Berekend per dag",
      },
      component: {
        aSRName: "ASR-naam",
        language: "Taal",
        remainingQuota: "Resterende quota",
        hours: "Uren",
        usedQuota: "Gebruikt quotum",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Operatie succesvol gered!",
        deletedSuccessfully: "Succesvol verwijderd!",
        pleaseSelectLanguage: "Selecteer taal",
        pleaseConfigureRules: "Configureer de regels",
        updateSuccessful: "Update succesvol!",
        operationSuccessful: "Operatie succesvol!",
      },
      template: {
        aSRLanguage: "ASR-taal",
        pleaseSelect: "Selecteer alstublieft",
        model: "Model",
        pleaseSelectLanguage: "Selecteer taal",
        delete: "Verwijderen",
        newASRModel: "Nieuw ASR-model",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Mededingingsanalyse" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "E-commerce beoordelingsfilter",
        allComments: "Alle opmerkingen",
        ecommerceReviewManagement: "E-commerce beoordelingsbeheer",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Social Media Review Filter",
        allComments: "Alle opmerkingen",
        socialMediaReviewManagement: "Beheer van social media review",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Mededingingsanalyse",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Naam",
        nickname: "Bijnaam",
        name: "Naam",
        email: "Rekeningnummer (e-mail)",
        mobile: "Telefoon",
        agentId: "Werk nr.",
        position: "Post",
        role: "Rol",
        actions: "Operatie",
        createUser: "Nieuwe werknemer",
        editUser: "Werknemer bewerken",
        account: "Rekeningnummer",
        originPassword: "Eerste wachtwoord",
        newPassword: "Nieuw wachtwoord",
        resumePassword: "Wachtwoord bevestigen",
        userGroup: "Werknemersgroep",
        authorizationGroup: "Toestemming groep",
        resetPassword: "Wachtwoord resetten",
      },
      userGroups: {
        id: "ID",
        name: "Naam",
        userCount: "Aantal werknemers",
        actions: "Operatie",
        createUserGroup: "Werknemersgroep toevoegen",
        editUserGroup: "Werknemersgroep bewerken",
        member: "Leden",
      },
      role: {
        id: "ID",
        name: "Naam",
        actions: "Operatie",
        all: "Allemaal",
        taskName: "Taken",
        readTask: "Taakweergave",
        manageTemplate: "Sjabloonbeheer",
        manualInspection: "Handmatige beoordeling",
        readResultAll: "Resultsweergave",
        readResultPersonal: "Results View (Individueel)",
        readProcess: "Kwaliteitsinspectieproces bekijken",
        readReport: "Statistieken en rapporten",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Datum",
        taskName: "Naam",
        size: "Grootte",
        status: "Status",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Naam",
            remark: "Beschrijving",
            actions: "Operatie",
          },
          checkPoint: {
            name: "Naam",
            remark: "Beschrijving",
            type: "Soort",
            gradeType: "Evaluatiemethode",
            conditionLogic: "Regels",
            predeterminedScore: "Score",
            weight: "Gewicht",
            status: "Status",
            actions: "Operatie",
            switchOn: "Inschakelen",
            switchOff: "Deactiveren",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Tijd",
            customer: "Klant",
            customerPhone: "Klanttelefoon",
            callType: "Oproeptype",
            source: "Bron",
            voiceRecording: "Oproepopname",
            graded: "Beoordeling",
            openingGreeting: "Groeten openen",
          },
          checkPointList: {
            name: "Kwaliteitsinspectiepunt",
            type: "Soort",
            deductScore: "Fractie",
            isHit: "Hoesje",
            status: "Beoordeling",
            finalDeductScore: "Punten aftrek bevestigen",
            hit: "Hit",
            notHit: "Gemiste",
          },
        },
      },
    },
    custom: { keywords: "Dialoog Sleutelwoorden" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Systeeminstellingen" },
      systemIntegration: {
        text: "Systeemintegratie",
        subTitle: {
          udesk: "Udesk klantenservice systeem",
          udeskWhite: "Klantenservice systeem",
          wechat: "Enterprise WeChat",
          taobao: "Taobao Qianniu",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Veldbeheer voor kwaliteitsinspectie" },
      customerField: { text: "Klantenveld" },
      speechRecognition: { text: "Spraakherkenning" },
      user: { text: "Werknemersmanagement" },
      userGroups: { text: "Werknemersgroepsbeheer" },
      role: { text: "Rolbeheer" },
      dataMigration: { text: "Gegevensmigratie" },
      taskCenter: { text: "Missiecentrum" },
      recordUpload: { text: "Lokale opname uploaden" },
      anomalousData: { text: "Abnormale gegevens" },
      systemLog: { text: "Berichtenlogboek" },
      msgCenter: { text: "Berichtencentrum" },
      wechatDocking: { text: "Enterprise micro-docking" },
    },
    tasks: {
      homePage: { text: "Startpagina" },
      workbench: { text: "Werkbank" },
      semanticIntelligence: { text: "Semantische intelligentie" },
      intelligentAnalysis: { text: "Intelligente analyze" },
      intelligentLabel: { text: "Slim label" },
      admin: { text: "Beheer" },
      callManage: { text: "Oproepbeheer" },
      dialogueManage: { text: "Dialoogbeheer" },
      ticketManage: { text: "Beheer van werkorders" },
      dataAnalysis: { text: "Gegevensanalyse" },
      customerService: { text: "Klantenservicecentrum" },
      clientCenter: { text: "Klantencentrum" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Opleidingscentrum" },
      templates: { text: "Sjabloonbeheer" },
      wechatQaTasks: { text: "Enterprise WeChat werkbank" },
      wechatQaSetting: { text: "Configuratie van Enterprise WeChat Analyse" },
      wechatQaChartRecord: { text: "Enterprise WeChat-sessie" },
      wechatStaffCenter: { text: "Enterprise micro-werknemerscentrum" },
      callAnalysis: { text: "Gespreksanalyse" },
      caseBaseFolder: { text: "Case Bibliotheek" },
      coachTask: { text: "Mentortaken" },
      teamAnalysis: { text: "Teamanalyse" },
      customer: { text: "Klantencentrum" },
      analysisEnable: { text: "Analyse Empowerment" },
      analysisHelp: { text: "Help analyze" },
      analysisStudy: { text: "Leeranalyse" },
      analysisReview: { text: "Samengestelde schijfanalyse" },
      analysisTags: { text: "Slimme etiketanalyse" },
      analysisSentiment: { text: "Analyze van de publieke opinie" },
      analysisBusiness: { text: "Bedrijfsanalyse" },
      groupTest: { text: "Groepstest" },
    },
    navBar: {
      billingCenter: "Helpcentrum",
      uploadDownload: "Upload-en downloadcentrum",
      setting: "Systeeminstellingen",
    },
  },
  fix: {
    selectType: "Selecteer type:",
    all: "Allemaal",
    call: "Bel",
    text: "Dialoog",
    wechat: "Enterprise WeChat-sessie",
    businessTrendAnalysis: "Niveau 1 classificatie zakelijke trendanalyse",
    noEchart: "Geen gegevens voor de grafiek",
    checkPointisRequired: "Regelconfiguratie is vereist",
    voiceDownLoad: "Download opname",
    exportTxt: "TXT-tekst exporteren",
    exportWord: "Woordtekst exporteren",
    sureReRole: "Omkeert de bevestiging de rol van de spreker?",
    sureRecover: "Correctie bevestigen?",
    sureReAsr: "Bevestig heridentificatie?",
    reRole: "Omgekeerde luidspreker",
    recover: "Herfoutcorrectie",
    reAsr: "Heridentificatie",
    recordingOperation: "Opname operatie",
    onlySave30Day: "(Alleen 30 dagen)",
    appealError:
      "Doe een beroep op het kwaliteitsinspectiepunt voordat u het indient.",
    exportToTaskCenter:
      "Succesvol geëxporteerd naar het upload-en downloadcentrum",
    caseBaseLimit: "De casebibliotheek mag niet groter zijn dan 50",
    unnamed: "Naamloos",
    wordSpeed: "Woord/min",
    hit: "Hit",
    checkPoint: "Kwaliteitsinspectiepunt",
    realTimeAdditionTip1:
      "Real-time toevoeging: kwaliteitsinspectie begint onmiddellijk wanneer gegevens het kwaliteitscontrolesysteem binnenkomen",
    realTimeAdditionTip2:
      "Timing Toevoegen: Timing voegt automatisch gegevens toe voor kwaliteitsinspectie",
    null: "Geen",
    collcetedProps: "Verzamelvelden",
    editingNameNow: "Naam wordt bewerkt",
    upToFourLevels: "Indeling tot vier niveaus",
    default: "Standaard",
    nameCantBeNull: "Naam kan niet leeg zijn",
    editingNow: "Actie wordt bewerkt",
    nameError: "Verkeerde naam",
    nameMaxLength8: "Maximale lengte van de naam is 8",
    callTime: "Spreektijd",
    sentimentValue: "Waarde klantsentiment",
    agentSentimentValue: "Klantenservice sentiment waarde",
    sentimentAnalysis: "Sentiment analyze",
    associatedData: "Gegevens over de weg volgen",
    notifyTheOwner: "Breng de groepseigenaar op de hoogte",
    notifyTheEmployee: "Breng de werknemer zelf op de hoogte",
    notifyTheOwnerTip:
      "Wanneer het type conversatie groepschat is, wordt de groepseigenaar op de hoogte gesteld.",
    customNotifyEmployee: "Medewerker Aangepaste kennisgeving",
    customNotifyEmployeeTip:
      "Als de triggerende persoon een werknemer is, wordt de werknemer aan wie het bericht behoort op de hoogte gebracht.",
    clickToLearnMore: "Klik om meer te leren",
    repeatMarkingTip:
      "Dit record is gemarkeerd door dit kwaliteitsinspectiepunt en het is niet nodig om de markering te herhalen.",
    successfulEvaluation: "Evaluatie van succes",
    evaluationFailed: "Evaluatie mislukt",
    qualityInspectionScore: "Kwaliteitsinspectiescore",
    semanticTags: "Semantisch label",
    smartTags: "Slim label",
    collect: "Verzamel informatie",
    appealRecord: "Record van grieven",
    satisfy: "Tevreden",
    notSatisfy: "Niet tevreden",
    appealPlaceHolder: "Voer de reden van het beroep in",
    giveUp: "Verlaten",
    rejectReasonPlaceHolder: "Voer een reden in voor afwijzing",
    reject: "Afgewezen",
    changeRejectReason: "Reden van afwijzing herzien",
    pass: "Door",
    revise: "Wijziging",
    machineEvaluation: "Machine-evaluatie",
    poor: "Slecht",
    excellent: "Goed.",
    moderate: "Algemeen",
    mustMarked: "Dit kwaliteitsinspectiepunt moet worden gemarkeerd",
    pleaseChoose: "Selecteer alstublieft",
    manualEvaluation: "Handmatige evaluatie",
    qualityInspectionTemplate: "Toepasselijke kwaliteitsinspectie sjabloon",
    collectionTemplate: "Toepasselijke acquisitie sjabloon",
    keywordsWordCloud: "Trefwoorden woord cloud",
    editCategoryLabel: "Categorie-etiketten bewerken",
    justCall: "Kijk maar naar de oproep",
    justConversation: "Kijk maar naar de dialoog.",
    batchOperation: "Batch operatie",
    customerServicesGroup: "Klantenservicegroep",
    joinGroup: "Word lid van de groep",
    removeGroup: "Verplaats uit groep",
    enterInformationEntity:
      "Voer de inhoud van de entiteit van de informatie in",
    enterNotBeNull: "Invoer kan niet leeg zijn",
    operationGroup: "Operatiegroep",
    customerServiceSelected: "Geselecteerde klantenservice",
    callAndConversation: "Oproep/gesprek",
    conversation: "Dialoog",
    system: "Systeem",
    customize: "Aangepast",
    effective: "Effectief",
    invalid: "Ongeldig",
    successfulAndToTaskCenter:
      "De uitvoering is succesvol, ga naar het taakcentrum om te controleren",
    recalculationFailed: "Herberekening mislukt",
    selectRecalculatedIndicator:
      "Selecteer de indicator die u wilt herberekenen",
    source: "Selecteer een gegevensbron",
    placeholder: "Voer een trefwoord in",
    cumulative: "Cumulatief",
    average: "Gemiddeld type",
    distribution: "Wet van distributie",
    originalDataCannotEmpty: "Ruwe gegevens kunnen niet leeg zijn",
    inspection: "Kwaliteitsinspectie",
    check: "Willekeurige inspectie",
    review: "Beoordeling",
    draftSavedSuccessfully: "Ontwerp met succes opgeslagen",
    optimization: "Optimalisatie",
    agentName: "Naam klantenservice",
    allTasks: "Alle kwaliteitsinspectietaken",
    datePickerSelect: "Datum selecteren",
    callSelect: "Dialoog selecteren",
    taskSelect: "Taak selecteren",
    genReport: "Rapport genereren",
    dataSource: "Gegevensbron",
    callsTotal: "Totaal aantal oproepen",
    intelligentQualityInspection: "Intelligente kwaliteitsinspectie",
    percentage: "Proportie",
    artificialSampling: "Handmatige bemonstering",
    appealNumber: "Bedrag van de klachten",
    totalConversation: "Totale hoeveelheid dialoog",
    samplingReviewRate: "Opslagingspercentage pompen",
    data: "Gegevens",
    inspectionResults: "Resultaten kwaliteitsinspectie",
    inspected: "Kwaliteitsinspectie",
    totalScore: "Totaal score",
    learnMore: "Meer bekijken",
    inspectionEffect: "Kwaliteitsinspectie effect",
    allTotal: "Totaal",
    manualInspection: "Handmatige beoordeling",
    artificialReview: "Handmatige beoordeling",
    approvalAmountAndRate: "Goedgekeurde hoeveelheid & goedkeuringspercentage",
    customerScore: "Klantenservice score",
    inspectionRecommendations: "Suggesties voor kwaliteitsinspectie",
    saveDraft: "Ontwerp opslaan",
    historicalAdvice: "Historisch advies",
    advicePlaceHolder: "Voer alstublieft een suggestie in...",
    upload: "Bestand uploaden",
    customAdd: "Filter toevoegen",
    advancedConditions: "Geavanceerde voorwaarden",
    keyWord: "Trefwoorden",
    containsAll: "Bevat alle",
    containsAny: "Bevat elke",
    matchAny: "Overeenkomen met elke",
    matchAll: "Alles overeenkomen",
    smartTagsOne: "Slim label één",
    smartTagsTwo: "Slim label II",
    season: "Seizoen",
    month: "Maand",
    week: "Week",
    day: "Dag",
    hour: "Wanneer",
    notMoreThan366: "Niet meer dan 366 dagen.",
    propsCannotBeNull: "Sommige velden worden niet ingevuld",
    other: "Overige",
    correlationCoefficient: "Correlatiecoëfficiënt",
    repetitionRate: "Herhalingsfrequentie",
    configurationItemsMoreThan20: "Voeg maximaal 20 configuratie-items toe",
    conversionAnalysis: "Transformatie analyze",
    correlationAnalysis: "Correlatie analyze",
    successfulExportAndToTaskCenter:
      "Exporteren is succesvol, ga naar het taakcentrum om te bekijken",
    typeSelect: "Selecteer type",
    exportExcel: "Excel exporteren",
    exportPng: "Png exporteren",
    noDataChart: "Geen gegevens voor de grafiek",
    placeOperator: "Selecteer een slim label",
    placeAdvancedConditions: "Selecteer geavanceerde criteria",
    createAdvancedConditions: "Nieuwe geavanceerde filter",
    moveUp: "Omhoog gaan",
    moveDown: "Naar beneden gaan",
    moveLeft: "Verplaats naar links",
    moveRight: "Beweeg naar rechts",
    statusChangeSuccess: "Status succesvol gewijzigd",
    editSuccess: "Wijziging geslaagd",
    saveSuccess: "Bespaar met succes",
    firstPage: "Startpagina",
    lastPage: "Staartpagina",
    customerInfo: "Klantinformatie",
    type: "Soort",
    NumberTypeErrorMessage:
      'De inhoud van de veldinformatie van het nummertype kan alleen getallen of "."',
    taskStartPrompt: "Opdrachtprompt voor het openen van taken",
    startConfirm:
      "Klik op de bevestigingsknop om de kwaliteitsinspectietaak te openen",
    continueToSubmitWithoutEvaluation:
      "Doorgaan met indiening zonder evaluatie",
    rejectTip:
      "Er zijn geen wijzigingsacties gedetecteerd, weet u zeker dat u alle beroepen rechtstreeks afwijst?",
    unmarkedSelfLearningTip:
      "U hebt ongemarkeerde zelflerende kwaliteitsinspectiepunten",
    markedSuccessfully: "Etikettering succes",
    markingFailed: "Etikettering mislukt",
    exceedingLength: "Lengte overschrijden",
    uncategorized: "Niet geclassificeerd",
    addSuccess: "Nieuw succes",
    deleteSuccess: "Met succes verwijderd",
    featureName: "Naam van element",
    informationEntity: "Informatie entiteit",
    standardAnswer: "Standaard antwoord",
    keyElementAnswer: "Sleutelelementen om te beantwoorden",
    generalStatement: "Algemene verklaring (niet nodig om te vragen)",
    generalQuestionsAndConfirmation: "Algemene vragen en bevestiging",
    specialQuestionsAndAnswers: "Speciale vragen en antwoorden",
    agent: "Klantenservice",
    customer: "Klant",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Sleutelelement semantisch label en informatie-entiteit kunnen niet tegelijkertijd leeg zijn",
    standardSentence: "Standaardzin",
    acquaintance: "Kennismaking",
    smartUePlaceholder: "Voer een naam voor slimme interactie in",
    typeSelectPlaceholder: "Selecteer het type",
    keyElement: "Belangrijkste elementen",
    reQuerySementicTags: "Reselecteer semantische labels",
    querySementicTags: "Semantisch label selecteren",
    QATestForm: "Vragen-en antwoorddetectieformulier",
    selectNotBeNull: "Selectie kan niet leeg zijn",
    featureDetectionTarget: "Element detectiedoel",
    categorized: "Indeling",
    effectiveness: "Effectiviteit",
    hideAdvancedSettings: "Geavanceerde instellingen verbergen",
    showAdvancedSettings: "Geavanceerde instellingen tonen",
    generalStatementOnly: "ALGEMENE VERKLARING",
    generalQuestions: "Algemene twijfel",
    affirmativeExpression: "Bevestigende uitdrukking",
    negativeIntention: "Negatieve intentie",
    searchSementicTags: "Semantische labels opvragen",
    choiceQuestion: "Selectieve vragende zin",
    selectiveAnswer: "Keuze type antwoord",
    sementicTagsPlaceholder: "Voer de inhoud van semantische etiketten in",
    openSpecialQuestion: "Open speciale vragen",
    openAnswer: "Open antwoord.",
    customQA: "Aangepaste Q & A",
    questions: "Vragen methode",
    dataPlaceholder: "Voer content in",
    reply: "Antwoord",
    notFindSuitableLabel: "Er werd geen geschikt label gevonden.",
    addSemanticTag: "Een nieuwe semantische tag toevoegen",
    intelligentInteraction: "Intelligente interactie",
    matching: "Match",
    bout: "Tijden",
    keywordMatch: "Trefwoord matching",
    addFilterConditions: "Voeg filtervoorwaarde toe",
    buttonStartCollectionTask:
      "Klik op de OK knop om de verzameltaak te starten",
    buttonStartTask:
      "Klik op de OK knop om de kwaliteitsinspectietaak te openen",
    ruleConfigurationIsRequired: "Regelconfiguratie is vereist",
    asrRunning: "ASR-uitvoering",
    errorCorrectionExecution: "Uitvoering van foutcorrectie",
    emotionalVolumeRecognition: "Emotie Volumeherkenning",
    dataPreprocessing: "Voorverwerking van gegevens",
    inspectionExecution: "Uitvoering kwaliteitsinspectie",
    businessId: "Zakelijk ID",
    businessTime: "Zakelijke tijd",
    createTime: "Creatietijd",
    abnormalReason: "Abnormale reden",
    singleChat: "Enkele chat",
    groupChat: "Groepschat",
    taskContent: "Inhoud van de taak",
    submissionTime: "Inzendingstijd",
    startTime: "Starttijd",
    endTime: "Eindtijd",
    runningTime: "Tijdrovende operatie",
    totalAmountOfData: "Totaal gegevensvolume",
    resultsOfThe: "Uitvoeringsresultaten",
    operator: "Exploitant",
    taskDetails: "Taakdetails",
    executiveFunction: "Uitvoerende functie",
    qualityCheckTasks: "Kwaliteitsinspectietaak",
    implementationDetails: "Uitvoeringsgegevens",
    conditionalRules: "Voorwaardigingsregels",
    smartCondition: "Slimme omstandigheden",
    goThis: "Ga naar dit",
    pleaseMarkClassification: "Markeer de classificatie",
    advancedScreening: "Geavanceerd filteren",
    noDataToManipulate: "Geen gegevens om te manipuleren",
    concept: "Conceptwoorden",
    checkPoint1: "Regelconfiguratie",
    requiredFieldsCannotEmpty: "Vereist item kan niet leeg zijn",
    rule: "Regels",
    afterFixUnit: "Woord",
    unmarkedSelfLearningPointsTip:
      "U hebt ongemarkeerde zelflerende kwaliteitsinspectiepunten",
    onlyReservedFor7Days: "(Slechts 7 dagen)",
    downloadError: "Downloaden is mislukt",
    pause: "Pauzeer",
    continue: "Doorgaan",
    recalcScore: "Alleen scores opnieuw berekenen (met beoordelingen)",
    recalculatePointsAndScores:
      "Kwaliteitsinspectiepunten en scores opnieuw berekenen",
    recalculatePointsAndScoresWithEmtry:
      "Kwaliteitsinspectiepunten en-scores opnieuw berekenen (kwaliteitscontrolepunten zijn leeg)",
    inProgress: "In uitvoering",
    toBeExecuted: "Te implementeren",
    executeImmediately: "Onmiddellijke uitvoering",
    specialElements: "Speciale elementen",
    logicRule: "Logische regels",
    every: "Meerdere wedstrijden",
    hitRecall: "Hit Recall Word",
    notHitRecall: "Gemiste Recall-woord",
    hitRecallRegular: "Hit recall regelmatig",
    notHitRecallRegular: "Regular gemist",
    hitRule: "Hit Regels",
    notHitRule: "Gemiste regel",
    hitSimilarSentence: "Hit soortgelijke zinnen",
    notHitSimilarSentence: "Gelijkaardige zinnen gemist",
    hitExcludeSimilarSentences: "Hit om soortgelijke zinnen uit te sluiten",
    notHitExcludeSimilarSentences:
      "Gemiste om soortgelijke zinnen uit te sluiten",
    updateSuccess: "Update geslaagd",
    qualify: "Regelmatig",
    recallKeyWord: "Recall Trefwoorden",
    grammar: "Grammatica regels",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Geldigheidswijziging mislukt, probeer het opnieuw",
    postAutoMateTasksCaseByTaskId:
      "Kan de synchronisatieconfiguratie van de casebibliotheek niet opslaan",
    getAutoMateTasksCaseByTaskId:
      "Het verkrijgen van de synchronisatieconfiguratie van de casebibliotheek is mislukt",
    postInspectionRemarkModelConfigs: "Sjabloon voor opmerkingen mislukt",
    putInspectionRemarkModelConfigsById: "Sjabloon voor opmerkingen mislukt",
    getInspectionRemarkModelConfigsById:
      "Het ontvangen van sjabloongegevens voor opmerkingen is mislukt",
    postKnowledgeDatas:
      "Het creëren van kennisbasis is mislukt, probeer het opnieuw",
    putKnowledgeDatasById:
      "Repository modificatie mislukt, probeer het opnieuw",
    getLibraryFiles: "Het verkrijgen van de lijst met prijsmappen is mislukt.",
    getLibraryCalls: "Het bestand is mislukt",
    getLibraryFilesSearch: "Zoeken mislukt",
    putLibraryFilesMoveById: "Het verplaatsen van de prijsmap is mislukt.",
    putLibraryCallsById: "Bestand hernoemen mislukt!",
    postLibraryFiles: "Het toevoegen van een map is mislukt",
    putLibraryFilesById: "De naam van de map is mislukt",
    deleteLibraryFilesById: "Verwijderen mislukt",
    deleteLibraryCallsById: "Verwijderen mislukt",
    postLibraryCalls: "Toevoegen mislukt",
    getVoiceUploadResultById: "Export uploaden resultaat mislukt",
  },
};
