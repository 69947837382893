import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';

export default class TasksConditionDetailComponent extends React.Component {
    //#region state
    state = {
        operatorTypes: [],
    };
    //#endregion

    //#region Computed properties
    static computes = {
        isEditing: [
            'privates.model.condition',
            function({ props, state, privates, locales }) {
                return (
                    privates.model && privates.model.condition.id > 0
                );
            }
        ]
    };
    //#endregion

    //#region Life Cycle
    componentDidMount() {
        let operatorTypes = Udesk.enums.operatorTypes.filter(item => item !== null);
        let task = this.privates.model.task;

        if (
            task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
            task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id
        ) {
            operatorTypes = operatorTypes.filter(type => {
                return (
                    type.id !== Udesk.enums.operatorTypes.question.id &&
                    type.id !== Udesk.enums.operatorTypes.speed.id &&
                    type.id !== Udesk.enums.operatorTypes.volume.id &&
                    type.id !== Udesk.enums.operatorTypes.emotion.id &&
                    type.id !== Udesk.enums.operatorTypes.grabDetection.id &&
                    type.id !== Udesk.enums.operatorTypes.questionsAndAnswers.id &&
                    type.id !== Udesk.enums.operatorTypes.element.id
                );
            });
        }

        if (
            task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id ||
            task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id
        ) {
            operatorTypes = operatorTypes.filter(type => {
                return (
                    type.id !== Udesk.enums.operatorTypes.keyword.id &&
                    type.id !== Udesk.enums.operatorTypes.semantics.id &&
                    type.id !== Udesk.enums.operatorTypes.semanticTags.id &&
                    type.id !== Udesk.enums.operatorTypes.entityInfoDetection.id &&
                    type.id !== Udesk.enums.operatorTypes.grammar.id &&
                    type.id !== Udesk.enums.operatorTypes.questionsAndAnswers.id &&
                    type.id !== Udesk.enums.operatorTypes.element.id
                );
            });
        }

        this.setState({
            operatorTypes: [].concat(operatorTypes)
        });
    }
    //#endregion

    //#region actions
    actions = {
        historyBack() {
            let routeOptions = {
                history: this.props.history,
                routeName: "tasksConditionIndex",
                pathParams: { taskId: this.privates.model.taskId }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        checkRangeChanged(condition) {
            Object.assign(this.privates.model.condition, condition);
        },
        save() {
            let condition = this.privates.model.condition;
            let operatorItemError = false;
            let operatorList = condition.operatorList || [];

            if (operatorList && operatorList instanceof Array && operatorList.length > 0) {
                operatorList.forEach(operatorItem => {
                    operatorItemError = false;

                    if (operatorItem.type === Udesk.enums.operatorTypes.entityInfoDetection.id && operatorItem.param) {
                        if (operatorItem.param.entity == null || operatorItem.param.operator == null) {
                            operatorItemError = true;
                        }

                        if (
                            operatorItem.param.operator === Udesk.enums.entityOperators.equals.id ||
                            operatorItem.param.operator === Udesk.enums.entityOperators.notEquals.id ||
                            operatorItem.param.operator === Udesk.enums.entityOperators.contains.id ||
                            operatorItem.param.operator === Udesk.enums.entityOperators.notContains.id
                        ) {
                            if (operatorItem.param.expectedValueObject == null) {
                                operatorItemError = true;
                            }
                        } else {
                            if (operatorItem.param.expectedValue == null) {
                                operatorItemError = true;
                            }
                        }
                    }
                    // if (operatorItem.type === Udesk.enums.operatorTypes.sentenceLength.id && operatorItem.param) {
                    //     if (operatorItem.param.compareType == null ||
                    //         operatorItem.param.wordsNumber == null) {
                    //         operatorItemError = true;
                    //     }
                    //     if (operatorItem.param.operator === Udesk.enums.entityOperators.equals.id ||
                    //         operatorItem.param.operator === Udesk.enums.entityOperators.notEquals.id ||
                    //         operatorItem.param.operator === Udesk.enums.entityOperators.contains.id ||
                    //         operatorItem.param.operator === Udesk.enums.entityOperators.notContains.id) {
                    //         if (operatorItem.param.expectedValueObject == null) {
                    //             operatorItemError = true;
                    //         }
                    //     } else {
                    //         if (operatorItem.param.expectedValue == null) {
                    //             operatorItemError = true;
                    //         }
                    //     }
                    // }
                    // if (operatorItem.type === Udesk.enums.operatorTypes.basicInfoDetection.id && operatorItem.param) {
                    //     if (operatorItem.param.entity == null ||
                    //         operatorItem.param.operator == null ||
                    //         operatorItem.param.expectedValue == null) {
                    //         operatorItemError = true;
                    //     }
                    // }
                });
            }

            if (operatorItemError) {
                return Udesk.ui.notify.error(UdeskLocales.current.business.info.conditionError);
            }

            let taskId = this.privates.model.taskId;
            let operatorLogic = this.privates.model.condition.operatorLogic;
            let reg = /^[\d\s()&&||!]+$/;

            if (!reg.test(operatorLogic)) {
                Udesk.ui.notify.error(UdeskLocales.current.business.info.conditionError);
                return;
            }

            condition['taskId'] = taskId;

            if (this.privates.computes.isEditing) {
                this.actions.editConditon(condition);
            } else {
                this.actions.creatConditon(condition);
            }
        },

        creatConditon(params) {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionConditions', this.props.sdkOptions);
            Udesk.ajax.post(url, params).then(
                resp => {
                    Udesk.ui.notify.success(
                        `${UdeskLocales.current.pages.tasks.manage.template.condition.index.name}${UdeskLocales.current.business.info.creatSuccess}`
                    );
                    this.actions.historyBack();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            `${UdeskLocales.current.pages.tasks.manage.template.condition.index.name}${UdeskLocales.current.business.info.creatError}`
                    );
                }
            );
        },

        editConditon(params) {
            let conditionId = this.privates.model.condition.id;
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionConditions/' + conditionId + '?taskId=' + this.privates.model.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, params).then(
                resp => {
                    Udesk.ui.notify.success(
                        `${UdeskLocales.current.pages.tasks.manage.template.condition.index.name}${UdeskLocales.current.business.info.editSuccess}`
                    );
                    this.actions.historyBack();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            `${UdeskLocales.current.pages.tasks.manage.template.condition.index.name}${UdeskLocales.current.business.info.editError}`
                    );
                }
            );
        },

        operatorListChanged(operatorList) {
            this.privates.model.condition.operatorList = operatorList;
        },
    //#endregion
    };
}
