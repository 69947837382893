// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetCommunicateStrategyCategoryQuery,
    BaseResponseListCommunicateStrategyCategoryFoundResponse,
    PostCommunicateStrategyCategoryBody,
    BaseResponseCommunicateStrategyCategory,
} from '../../types';

/**
 * 查询沟通策略详情分组-分页
 *
 * @export
 * @tags 沟通策略设置
 * @link [GET] /communicateStrategy/category
 */
export function getCommunicateStrategyCategory(): Promise<BaseResponseListCommunicateStrategyCategoryFoundResponse>;
export function getCommunicateStrategyCategory(
    options: ApiOptions<never, GetCommunicateStrategyCategoryQuery>
): Promise<BaseResponseListCommunicateStrategyCategoryFoundResponse>;
export function getCommunicateStrategyCategory(
    options?: ApiOptions<never, GetCommunicateStrategyCategoryQuery>
): Promise<BaseResponseListCommunicateStrategyCategoryFoundResponse> {
    return request<never, never, GetCommunicateStrategyCategoryQuery>(
        '/communicateStrategy/category',
        { ...options, method: 'get', __$requestCalleeName: 'getCommunicateStrategyCategory' }
    );
}

/**
 * 新增沟通策略详情分组
 *
 * @export
 * @tags 沟通策略设置
 * @link [POST] /communicateStrategy/category
 */
export function postCommunicateStrategyCategory(
    data: PostCommunicateStrategyCategoryBody
): Promise<BaseResponseCommunicateStrategyCategory>;
export function postCommunicateStrategyCategory(
    data: PostCommunicateStrategyCategoryBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseCommunicateStrategyCategory>;
export function postCommunicateStrategyCategory(
    data: PostCommunicateStrategyCategoryBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseCommunicateStrategyCategory> {
    return request<PostCommunicateStrategyCategoryBody, never, never>(
        '/communicateStrategy/category',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postCommunicateStrategyCategory',
        }
    );
}

export const meta = [
    { tags: ['沟通策略设置'], path: '/communicateStrategy/category', method: 'get' },
    { tags: ['沟通策略设置'], path: '/communicateStrategy/category', method: 'post' },
];
