import React from 'react';
import Udesk from 'Udesk';
import RoleDetailRoute from './route';
import { Redirect } from 'react-router-dom';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import NavBar from 'udesk-react/src/components/nav-bar';
import './style.scss';
// import { PageHeader } from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
import { getCurrentRouteName } from 'Udesk/system/subApp';

export default class RoleDetailTemplate extends RoleDetailRoute {
    render() {
        let { actions, locales } = this;
        let { routes, route, location, sdkOptions } = this.props;
        let { id } = this.privates;
        let readOnly = route.name === 'roleView';

        let navLists = [
            {
                pathName: getCurrentRouteName(readOnly ? 'roleViewBasicInfo' : 'roleEditBasicInfo'),
                text: locales.components.pages.role.detail.navTabNames.basicInfo,
                pathParams: { id },
            },
            {
                pathName: getCurrentRouteName(readOnly ? 'roleViewDataPermissions' : 'roleEditDataPermissions'),
                text: locales.components.pages.role.detail.navTabNames.dataPermissions,
                pathParams: { id },
            },
            {
                pathName: getCurrentRouteName(readOnly ? 'roleViewTaskPermissions' : 'roleEditTaskPermissions'),
                text: locales.components.pages.role.detail.navTabNames.taskPermissions,
                pathParams: { id },
            },
        ];

        if (this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name,
                pathParams: this.props.match.params,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <Page
                    pageBodyClassName="role-detail-data-permissions-page"
                    title={
                        id
                            ? readOnly
                                ? locales.components.pages.role.detail.view
                                : locales.components.pages.role.detail.edit
                            : locales.components.pages.role.detail.new
                    }
                    padding={true}
                    onBack={actions.backToIndex}
                    extra={
                        id
                            ? [
                                <NavBar
                                    navLists={navLists}
                                    classNames="role-detail-page-header-title-right-part qa-react-page-header-title-right-part"
                                    navItemClassNames="role-detail-page-header-nav-item"
                                    activeClassNames="role-detail-page-header-nav-item-active"
                                />,
                            ]
                            : []
                    }
                >
                    <For each="route" index="i" of={routes}>
                        <SubRoutes key={i} route={route} childProps={{ hiddenPageHeader: readOnly }}/>
                    </For>
                </Page>
                // <div className="role-detail-page">
                // <div className='udesk-qa-web-page'>
                //     <PageHeader
                //         className="udesk-qa-web-page-header"
                //         title={id ? (readOnly ? locales.components.pages.role.detail.view : locales.components.pages.role.detail.edit) : locales.components.pages.role.detail.new}
                //         onBack={actions.backToIndex}
                //         extra={id ? [
                //             <NavBar navLists={navLists} classNames="role-detail-page-header-title-right-part qa-react-page-header-title-right-part" navItemClassNames="role-detail-page-header-nav-item" activeClassNames="role-detail-page-header-nav-item-active" />,
                //         ] : []}
                //     />
                //     <div className='udesk-qa-web-page-body'>
                //         <div className='udesk-qa-web-page-body-root role-detail-page' style={{ padding: 16, height: '100%' }}>
                //             <For each="route" index="i" of={routes}>
                //                 <SubRoutes key={i} route={route} />
                //             </For>
                //         </div>
                //     </div>
                // </div>
            );
        }
    }
}
