// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostKnowledgeDatasFindByKeywordBody,
    BaseResponseListKnowledgeDataFoundResponse,
} from '../../types';

/**
 * findAll
 *
 * @export
 * @tags knowledge-data-controller
 * @link [POST] /knowledgeDatas/findByKeyword
 */
export function postKnowledgeDatasFindByKeyword(
    data: PostKnowledgeDatasFindByKeywordBody
): Promise<BaseResponseListKnowledgeDataFoundResponse>;
export function postKnowledgeDatasFindByKeyword(
    data: PostKnowledgeDatasFindByKeywordBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListKnowledgeDataFoundResponse>;
export function postKnowledgeDatasFindByKeyword(
    data: PostKnowledgeDatasFindByKeywordBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListKnowledgeDataFoundResponse> {
    return request<PostKnowledgeDatasFindByKeywordBody, never, never>(
        '/knowledgeDatas/findByKeyword',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postKnowledgeDatasFindByKeyword',
        }
    );
}

export const meta = [
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas/findByKeyword', method: 'post' },
];
