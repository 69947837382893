import Udesk from 'Udesk';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLiveEffect } from "src/util/hook";
import { createDefaultMoment, getDateTimeString } from "src/util/moment";
import { fire } from 'src/util/core';
import { message } from "udesk-ui";
/**
 * 这里处理音频播放问题， 可以播放一个列表。 
 * 解决了暂停问题，如果前后俩次点击所传参数一致将会暂停
 * 如果前后点击参数不一致，将重新播放列表文件
 * @return {*} 
 */
export const useAudioPlay = () => {
    const ref = useRef<HTMLAudioElement>(null);
    const cache = useRef<any>({
        id: '',
        isPlay: false,
        isEnd: false,
        list: [],
    });
    // 自动播放
    const autoHandle = useCallback(() => {
        if (cache.current.isEnd) {
            cache.current.isEnd = false;
            cache.current.isPlay = false;

            autoHandle();
        } else {
            if (cache.current.isPlay) {
                ref.current?.pause();
            } else {
                ref.current?.play();
            }
            cache.current.isPlay = !cache.current.isPlay;
        }
    }, []);
    // 文件播放完毕后的回调，如果列表中文件未播放完毕，将继续播放
    const endedHandle = useCallback(() => {
        const list = cache.current.list;

        if (list.length > 0) {
            ref.current?.setAttribute('src', list.shift());
            ref.current?.play();

            cache.current.isEnd = false;
            cache.current.isPlay = true;
        } else {
            cache.current.isEnd = true;
            cache.current.isPlay = false;
        }
    }, []);
    // 播放事件，参数为文件url列表
    const playHandle = useCallback((...list) => {
        if (list.length > 0) {
            if (list.join() !== cache.current.id) {
                cache.current.list = list;
                cache.current.id = list.join();

                endedHandle();
            } else {
                autoHandle();
            }
        } else {
            ref.current?.pause();
        }
    }, [autoHandle, endedHandle]);

    useEffect(() => {
        ref.current?.addEventListener('ended', endedHandle);
    }, []);

    return [ref, playHandle, endedHandle] as any[];
};

export const useDisabledForStartButton = (task) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [count, setCount] = useState<number>(1);

    const getStatus = useCallback(() => {
        if (task) {
            const {taskEndTime, taskStartTime} = task;
            if (taskEndTime && taskStartTime) {
                const startTime = createDefaultMoment(taskStartTime);
                const endTime = createDefaultMoment(taskEndTime);
                const nowTime = createDefaultMoment();

                if (nowTime.isAfter(startTime)) {
                    if (nowTime.isBefore(endTime)) {
                        return 1; // 符合要求
                    } else {
                        return 2; // 时间过晚
                    }
                } else {
                    return 0; // 时间未到，需要启用定时器
                }
            }
        }

        return -1; // 永久有效
    }, [task]);

    useEffect(() => {
        const status = getStatus();
        if (status === 0) {
            setTimeout(() => {
                setCount(count + 1);
            }, 1000);
        } else {
            setIsDisabled(status === 2);
        }
    }, [
        count,
        getStatus
    ]);

    return [isDisabled];
};

/**
 * 文本消息时间
 * @returns 
 */
export const useStartSecond = () => {
    const ref = useRef<any>(null);

    const initStartSecond = useCallback((date = Date.now()) => {
        ref.current = date;
    }, []);

    const newStartSecond = useCallback(() => {
        return Date.now() - ref.current;
    }, []);

    return [initStartSecond, newStartSecond];
};

export const useTaskFlow = (request, task, record) => {
    const [flowData, setFlowData] = useState<any[]>();
    const [flowIndex, setFlowIndex] = useState(0);

    useEffect(() => {
        setFlowIndex(0);
        setFlowData(task?.nodeList);
    }, [task]);

    const getFlowData = useCallback(() => {
        if (task?.nodeList && record?.id) {
            request(`/intelligentPartner/${record?.id}/nodes`, task.nodeList, 'post').then(
                resp => {
                    setFlowData(resp.data?.nodeDetailList);
                    setFlowIndex(resp.data?.currentProcessNodeIndex);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [record?.id, request, task]);

    return {
        flowIndex,
        flowData,
        getFlowData,
    }; 
};

export const useSuspend = (request, task, record, setRecord) => {
    const [isSuspend, setIsSuspend] = useState<boolean>(false);
    const [isSuspending, setIsSuspending] = useState<boolean>(false);
    const [isLoadSuspendInfo, setIsLoadSuspendInfo] = useState<boolean>(false);
    const [loadingForSuspend, setLoadingForSuspend] = useState<boolean>(false);
    const [loadingForRestart, setLoadingForRestart] = useState<boolean>(false);
    const [suspendDetail, setSuspendDetail] = useState<any>();

    useLiveEffect((success) => {
        if (task) {
            request(`intelligentPartner/${task.id}/isSuspend`).then(
                resp => {
                    success(() => {
                        const recordId = resp.data?.recordId;
                        if (recordId) {
                            setRecord(record => {
                                if(!record) {
                                    return {
                                        id: recordId,
                                        costTime: resp.data?.suspendDetail?.costTime
                                    };
                                }
                                return record;
                            });
                            setIsSuspend(!!recordId);
                            setSuspendDetail(resp.data?.suspendDetail);
                        }
                        setIsLoadSuspendInfo(true);
                    });
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [request, task]);

    const pauseTask = useCallback((callback, params) => {
        if (record) {
            setLoadingForSuspend(true);
            request(`intelligentPartner/${record.id}/suspend`, params, 'put').then(
                resp => {
                    setIsSuspending(true);
                    setSuspendDetail(resp.data?.suspendDetail);
                    fire(callback, resp.data?.suspendDetail);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                setLoadingForSuspend(false);
            });
        }
    }, [record, request]);

    const restartTask = useCallback((callback?) => {
        if (record) {
            setLoadingForRestart(true);
            request(`intelligentPartner/${record.id}/cancel`).then(
                resp => {
                    setIsSuspend(false);
                    setIsSuspending(false);
                    setSuspendDetail(undefined);
                    fire(callback);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                setLoadingForRestart(false);
            });
        }
    }, [record, request]);

    const continueTask = useCallback((callback) => {
        if (record) {
            return request(`/intelligentPartner/${record.id}/continue`, {
                continueTime: getDateTimeString()
            }, 'put').then(
                resp => {
                    setIsSuspending(false);
                    fire(callback, resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [record, request]);

    return {
        suspendDetail,
        isSuspend, isSuspending, isLoadSuspendInfo, 
        loadingForSuspend, loadingForRestart, 
        pauseTask, continueTask, restartTask,
    };
};

const useTimeoutHint = (globalConfig, timeBias) => {
    const {maxSilencePeriod = 0, timeoutHintWords} = globalConfig || {};

    const list = useMemo(() => {
        if (timeoutHintWords?.length > 0) {
            return JSON.parse(timeoutHintWords);
        }
        return [];
    }, [timeoutHintWords]);

    return {
        maxSilencePeriod: maxSilencePeriod > 0 ? maxSilencePeriod + timeBias : 0,
        timeoutHintWords: list
    };
};

/**
 * 练习超时提醒Hook
 * @param globalConfig 课程全局配置
 * @param nextMessage 最新的一条记录
 * @param isSuspending 是否暂停
 * @param timeBias 时间补偿， 默认文本练习补偿1s，其他补偿8s
 */
export const useAnswerTimeout = (globalConfig, nextMessage, isSuspending, timeBias = 8) => {
    const [data, setData] = useState<any>();
    const [seconds, setSeconds] = useState(0);
    const {maxSilencePeriod, timeoutHintWords} = useTimeoutHint(globalConfig, timeBias);
    
    const startClock = useCallback(() => {
        setSeconds(seconds => seconds - 1);
    }, []);

    useEffect(() => {
        if (maxSilencePeriod) {
            setSeconds(maxSilencePeriod);
        }
    }, [maxSilencePeriod]);

    useEffect(() => {
        if (nextMessage) {
            if (maxSilencePeriod) {
                setData(nextMessage);
                setSeconds(maxSilencePeriod);
            }
        }
    }, [
        maxSilencePeriod, 
        nextMessage,
    ]);

    useEffect(() => {
        let timer:any;
        if (!isSuspending && data && maxSilencePeriod > 0 && timeoutHintWords.length > 0) {
            if (seconds > 0) {
                timer = setTimeout(startClock, 1000);
            }
            if (seconds <= 0) {
                if (!data.content) {
                    message.warning(
                        timeoutHintWords[Math.floor(Math.random() * timeoutHintWords.length)]
                    );
                }
            }
        }
        return () => {
            clearTimeout(timer);
        };
    }, [
        isSuspending, 
        maxSilencePeriod, 
        data, 
        seconds, 
        startClock, 
        timeoutHintWords
    ]);
};