import React from 'react';
import ReactModal from 'udesk-react/src/components/react-modal';
import TagCategoriesComponent from './component';
import { Button } from 'udesk-ui';
export default class TagCategoriesTemplate extends TagCategoriesComponent {
    render() {
        let {
            isClassificationVisible,
        } = this.props;
        let {
            smartTagCategorys,
        } = this.privates;
        let {
            actions,
            locales
        } = this;

        return (
            <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save} visible={isClassificationVisible} title={locales.components.pages.intelligentTags.index.editTagClassification} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.cancelSaveClassification} onOk={actions.saveClassification} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                <div className="udesk-qa-modal-body-content">
                    <For each="item" index="index" of={smartTagCategorys}>
                        <div className="smart-tag-category-item" key={index}>
                            <input type="text" className="form-control smart-tag-category-item-text" value={item.categoryName} onChange={actions.changeCategoryName.params(item)} />
                            <input type="color" className="form-control smart-tag-category-item-color" value={item.categoryColor} onChange={actions.changeCategoryColor.params(item)} />
                            <span className="smart-tag-category-item-delete" onClick={actions.deleteClassification.params(item, index)}>{locales.labels.delete}</span>
                        </div>
                    </For>
                    <Button onClick={actions.addClassification} type='link'>{locales.components.pages.intelligentTags.addClassification}</Button>
                    {/* <div className="smart-tag-category-item-add-action" onClick={actions.addClassification}>
                        {locales.components.pages.intelligentTags.addClassification}
                    </div> */}
                </div>
            </ReactModal>
        );
    }
}

