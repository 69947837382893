import React from 'react';
// import Locales from 'UdeskLocales';
import Udesk from 'Udesk';
import { Divider, Space } from 'udesk-ui';
import styled from 'styled-components';
import { Count } from './Count';
import { Status } from './Status';
import { ScoreRuleList } from './ScoreRuleList';
import { StandardScript } from './StandardScript';
import { useWrongQuestionSetContext } from '../../context';
import UdeskLocales from 'UdeskLocales';

const TableItemWrap = styled.div`
    .item-head {
        display: flex;
        margin-bottom: 12px;
        .item-head-title {
            flex: 1;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;

            color: rgba(0, 0, 0, 0.85);
        }
    }
    .item-body {
        display: flex;

        .item-body-content {
            display: flex;
            flex-flow: wrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .item-body-left {
            flex: 0 0 50%;
            margin-right: 16px;
        }
        .item-body-right {
            min-width: 200px;
            margin-right: 16px;
            flex: 1;
            overflow: hidden;
            > div {
                height: 100%;
            }
        }
        img {
            margin-right: 16px;
            border-radius: 2px;
        }
    }
`;

export const TableItem = (props) => {
    // const locales = Locales['current'];

    const { order, showDetail } = props;
    const context = useWrongQuestionSetContext();
    const {
        nodeName = '',
        answeredTimes = 0,
        exerciseTimes = 0,
        isAbsorb,
        nodeWords,
        dialogueMode,
        evaluationMode,
        pptUrl,
        mistakeRuleDetailList,
    } = context;

    const isPPT = Udesk.enums.learningTaskDialogueModeType.ppt.id === dialogueMode;
    const isWhole = Udesk.enums.pptEvaluationMode.whole.id === evaluationMode;
    return (
        <TableItemWrap>
            <div className="item-head">
                <Space className="item-head-title">
                    <div>{order}.</div>
                    <div>
                        {isPPT && isWhole ? mistakeRuleDetailList?.[0]?.ruleName ?? '-' : nodeName}
                    </div>
                </Space>
                <Space split={<Divider type="vertical" />}>
                    <Count
                        label={
                            /* 共计答题次数 */ UdeskLocales['current'].pages.coach.learningCenter
                                .wrongQuestionSet.list.components.tableItem.index
                                .totalNumberOfQuestionsAnswered
                        }
                        count={answeredTimes}
                        color="#1A6EFF"
                    />
                    <Count
                        label={
                            /* 错题已练习次数 */ UdeskLocales['current'].pages.coach.learningCenter
                                .wrongQuestionSet.list.components.tableItem.index
                                .numberOfTimesIncorrectQuestionsHaveBeenPracticed
                        }
                        count={exerciseTimes}
                        color="#FB5127"
                    />
                </Space>
                <Status status={isAbsorb} />
            </div>
            <div className="item-body">
                {isPPT && !isWhole && <img width={200} height={120} src={pptUrl} alt="" />}
                <div className="item-body-content">
                    {(!isPPT || !isWhole) && (
                        <div className="item-body-left">
                            <ScoreRuleList />
                        </div>
                    )}
                    <div className="item-body-right">
                        <StandardScript words={nodeWords} ellipsis={true} showDetail={showDetail} />
                    </div>
                </div>
            </div>
        </TableItemWrap>
    );
};
