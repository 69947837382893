import OptionsBuilderClass from '../options-builder';
import common from '../../../../udesk/index';

class WordCloudOptionsBuilderClass extends OptionsBuilderClass {
    _getChartType() {
        return common.enums.echartTypes.wordCloud.key;
    }

    getSeriesOptions(optionsOwner) {
        let series = super.getSeriesOptions(...arguments);
        for (let seriesItem of series) {
            // Shape can be 'circle', 'cardioid', 'diamond', 'triangle-forward', 'triangle', 'pentagon', 'star'
            // let shapes = ['circle', 'cardioid', 'diamond', 'triangle-forward', 'triangle', 'pentagon', 'star'];

            seriesItem.shape = "circle";
            seriesItem.rotationRange = [0, 0];
            seriesItem.sizeRange = [12, 50];
            //在像素网格的尺寸用于标记画布的可用性 网格大小越大，单词之间的间距也越大
            seriesItem.gridSize = 10;
            seriesItem.autoSize = {
                enable: true,
                minSize: 8
            };
            seriesItem.textStyle = {
                normal: {
                    color: function () {
                        return 'rgb(' + [
                            Math.round(Math.random() * 160),
                            Math.round(Math.random() * 160),
                            Math.round(Math.random() * 160)
                        ].join(',') + ')';
                    }
                },
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: '#2878ff',
                    //scale: 2,
                    //color: '#60ACFC',
                }
            };
        }
        return series;
    }

}

export default WordCloudOptionsBuilderClass;