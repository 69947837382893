import React from 'react';
import Udesk from 'Udesk';
import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import ReactModal from 'udesk-react/src/components/react-modal';
import CustomerServiceGrounp from '../../components/customer-service-grounp';
import './style.scss';
import InteractiveRecognitionRoute from './route';
import _isEmpty from 'lodash-es/isEmpty';
import { PageHeader, Button, Space } from 'udesk-ui';
import ExportButton from 'Component/common/export-button';

export default class InteractiveRecognitionTemplate extends InteractiveRecognitionRoute {
    render() {
        // let {
        //     sdkOptions
        // } = this.props;
        let {
            columns,

        } = this.state;
        let {
            isUploadVisible,
            file,
            isNodeModelVisible,
            isEditNodeModel,
            selectedNode,
            activeNode,
            interactiveDatas,
            categories,
            pageNum,
            pageSize,
            total
        } = this.privates;
        let {
            actions,
            privates,
            locales
        } = this;
        return (
            // <div className="interactive-recognition-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.interactiveRecognition.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root interactive-recognition-index-page'>
                        <div className="interactive-recognition-index-page-body qa-react-page-body">
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:interactive:view")}>
                                <div className="interactive-recognition-index-page-body-search-container qa-react-page-body-search-container">
                                    <div className="qa-react-page-body-search-container-left-part interactive-recognition-index-page-body-search-input-container pull-left col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                        <input type="text" className="interactive-recognition-index-page-body-search-input form-control" defaultValue={privates.keyword} placeholder={locales.labels.searchPlaceHolder} onKeyUp={actions.searchItems} onChange={actions.mutator('privates.keyword', e => e.target.value)} />
                                    </div>
                                    <div className="qa-react-page-body-search-container-middle-part pull-left">
                                        <span>
                                            {locales.components.pages.interactiveRecognition.detail.category}{locales.labels.colon}{activeNode.name}
                                        </span>
                                    </div>
                                    <div className="qa-react-page-body-search-container-right-part pull-right">
                                        <Space>
                                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:interactive:add")}>
                                                <Button onClick={actions.createItem}>
                                                    {locales.labels.create}
                                                </Button>
                                            </If>
                                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:interactive:import")}>
                                                <Button onClick={actions.changeUploadVisible}>
                                                    {locales.labels.import}
                                                </Button>
                                            </If>
                                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:interactive:export")}>
                                                <ExportButton exportDataCount={total} hasFilterCondition={true} onClick={actions.export} />
                                            </If>
                                        </Space>
                                    </div>
                                </div>
                                <div className="interactive-recognition-index-page-body-content-container qa-react-page-body-content-container row">
                                    <If condition={privates.asyncModelFulfilled}>
                                        <If condition={!privates.asyncModelResolved}>
                                            {privates.asyncModelErrorMsg}
                                        </If>
                                    </If>
                                    <If condition={!privates.asyncModelFulfilled}>
                                        Loading...
                                    </If>
                                    <If condition={privates.asyncModelFulfilled && privates.asyncModelResolved}>
                                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12 interactive-recognition-index-page-classification-container">
                                            {/* <ReactTree nodes={privates.asyncModel.categories} childNodesField="children" enableNodeActions={true} onAdding={actions.addNode} onEditing={actions.editNode} onDeleting={actions.deleteNode} className="interactive-recognition-category-container" onEdited={actions.editedNode} clickable={true} onActiveNodeChanged={actions.switchNode} getNodeBehaviors={actions.getNodeBehaviors} expandedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-down-s-xiasanjiao" collapsedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-yousanjiao"/> */}
                                            {
                                                !_isEmpty(categories) && (
                                                    <CustomerServiceGrounp data={categories} grounpSaveChange={actions.grounpSaveChange} groupNodeDelChange={actions.groupNodeDelChange} onSelectSearch={actions.onSelectSearch} />
                                                )
                                            }

                                        </div>
                                        <div className="col-lg-10 col-md-10 col-sm-8 col-xs-12">
                                            <ReactTable columns={columns} items={interactiveDatas} />
                                            {/* <ReactPager
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                                languageCode={sdkOptions.props.language}
                                                pageChanged={actions.pageChanged}
                                            /> */}
                                            <UdeskPagination
                                                current={pageNum}
                                                pageSize={pageSize}
                                                total={total}
                                                onChange={actions.pageChanged}
                                            />
                                        </div>
                                    </If>
                                </div>
                            </If>
                            <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save} visible={isUploadVisible} title={locales.components.pages.interactiveRecognition.index.import} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.changeUploadVisible} onOk={actions.importRecognitions} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                                <div className="udesk-qa-modal-body-content">
                                    <div className="form-group">
                                        <label>
                                            {locales.labels.uploadFile}{locales.labels.colon}
                                        </label>
                                        <div className="upload-file-button-container">
                                            <input type="file" className="upload-file-inpiut" onChange={actions.uploadFile} />
                                            <button type="button" className="upload-file-button btn btn-sm btn-primary">{locales.labels.upload}</button>
                                        </div>
                                        <If condition={file && file.name}>
                                            <span className="upload-file-name">
                                                {file.name}
                                            </span>
                                        </If>
                                    </div>
                                    <div className="form-group">
                                        {locales.labels.numOne}{locales.labels.colon}&nbsp;&nbsp;
                                        {locales.labels.click}&nbsp;&nbsp;
                                        <span className="upload-file-template" onClick={actions.downloadTemplate}>
                                            {locales.labels.downloadTemplate}
                                        </span>
                                        &nbsp;&nbsp;
                                        {locales.labels.fillInTheForm}
                                    </div>
                                    <div className="form-group">
                                        {locales.labels.numTwo}{locales.labels.colon}&nbsp;&nbsp;
                                        {locales.labels.UploadExcelfile}
                                    </div>
                                </div>
                            </ReactModal>
                            <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save} visible={isNodeModelVisible} title={isEditNodeModel ? locales.components.pages.interactiveRecognition.editClassify : locales.components.pages.interactiveRecognition.addClassify} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.changeNodeModelVisible} onOk={actions.saveNode} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                                <div className="udesk-qa-modal-body-content">
                                    <div className="form-group">
                                        <label className="form-control-label">
                                            {locales.labels.name}{locales.labels.colon}
                                            <span className="required"></span>
                                        </label>
                                        <input type="text" className="form-control" defaultValue={selectedNode.name} onChange={actions.changeNodeName} />
                                    </div>
                                </div>
                            </ReactModal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

