// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetInspectionDataRecheckStatusQuery,
    BaseResponseListInspectRecheckRecordFoundResponse,
} from '../../../types';

/**
 * getRecheckStatus
 *
 * @export
 * @tags 质检工作台
 * @link [GET] /inspectionData/recheck/status
 */
export function getInspectionDataRecheckStatus(): Promise<BaseResponseListInspectRecheckRecordFoundResponse>;
export function getInspectionDataRecheckStatus(
    options: ApiOptions<never, GetInspectionDataRecheckStatusQuery>
): Promise<BaseResponseListInspectRecheckRecordFoundResponse>;
export function getInspectionDataRecheckStatus(
    options?: ApiOptions<never, GetInspectionDataRecheckStatusQuery>
): Promise<BaseResponseListInspectRecheckRecordFoundResponse> {
    return request<never, never, GetInspectionDataRecheckStatusQuery>(
        '/inspectionData/recheck/status',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionDataRecheckStatus' }
    );
}

export const meta = [
    { tags: ['质检工作台'], path: '/inspectionData/recheck/status', method: 'get' },
];
