import StackColumnBuilderClass from './stack-column';
import BarBuilderClass from "./bar";

let barBuilder = new BarBuilderClass();

class StackBarOptionsBuilderClass extends StackColumnBuilderClass {
    getXAxisOptions(optionsOwner) {
        return barBuilder.getXAxisOptions(optionsOwner);
    }

    getYAxisOptions(optionsOwner) {
        return barBuilder.getYAxisOptions(optionsOwner);
    }

    _getLabelPosition() {
        return barBuilder._getLabelPosition();
    }
}

export default StackBarOptionsBuilderClass;
