// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetCallWorkTimesQuery,
    BaseResponseListCallWorkTimeFoundResponse,
    PostCallWorkTimesBody,
    BaseResponseCallWorkTimeFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 业务工作时间
 * @link [GET] /callWorkTimes
 */
export function getCallWorkTimes(): Promise<BaseResponseListCallWorkTimeFoundResponse>;
export function getCallWorkTimes(
    options: ApiOptions<never, GetCallWorkTimesQuery>
): Promise<BaseResponseListCallWorkTimeFoundResponse>;
export function getCallWorkTimes(
    options?: ApiOptions<never, GetCallWorkTimesQuery>
): Promise<BaseResponseListCallWorkTimeFoundResponse> {
    return request<never, never, GetCallWorkTimesQuery>('/callWorkTimes', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCallWorkTimes',
    });
}

/**
 * save
 *
 * @export
 * @tags 业务工作时间
 * @link [POST] /callWorkTimes
 */
export function postCallWorkTimes(
    data: PostCallWorkTimesBody
): Promise<BaseResponseCallWorkTimeFoundResponse>;
export function postCallWorkTimes(
    data: PostCallWorkTimesBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseCallWorkTimeFoundResponse>;
export function postCallWorkTimes(
    data: PostCallWorkTimesBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseCallWorkTimeFoundResponse> {
    return request<PostCallWorkTimesBody, never, never>('/callWorkTimes', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCallWorkTimes',
    });
}

export const meta = [
    { tags: ['业务工作时间'], path: '/callWorkTimes', method: 'get' },
    { tags: ['业务工作时间'], path: '/callWorkTimes', method: 'post' },
];
