import React from "react";
import moment from "moment";
import Udesk from 'Udesk';
import _extend from "lodash-es/extend";
import Locales from "UdeskLocales";
import { getSystemModule } from 'Udesk/system/subApp';

class GeneralInspectionReportComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
    };
    //#endregion

    state = {};
    privates = {
        radioValue: "-1",
        lineDataList: {},
        columns: [],
        effectTableData: [],
        statAt: [
            moment()
                .add(-7, "day")
                .format("YYYY-MM-DD 00:00:00"),
            moment().format("YYYY-MM-DD 23:59:59")
        ],
        taskDataList: [],
        taskId: -1,
        proposalData: [],
        callListData: {},
        dialogListData: {},
        echarsTypeField: 1,
        effectListData: {},
        effectDataListEcharts: {},
        agentColumns: [],
        agentDataList: [],
        agentTableRef: React.createRef(),
        reultDataList: [],
        textAreaText: "",
        reultMore: true,
        reultInfoFlag: {},
        storages: {
            textAreaText: ""
        },
        loading: false,
        callList: [
            {
                key: "-1",
                name: Locales['current'].fix.callAndConversation
            },
            {
                key: "1",
                name: Locales['current'].fix.call
            },
            {
                key: "2",
                name: Locales['current'].fix.conversation
            }
        ],
        echarsType: [
            {
                key: 1,
                name: Locales['current'].fix.inspection
            },
            {
                key: 2,
                name: Locales['current'].fix.check
            },
            {
                key: 3,
                name: Locales['current'].fix.review
            }
        ]
    };
    enableStorage = true;
    _storageKey = "";
    storageStrategies = {
        storageWay: "sessionStorage",
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: []
        }
    };

    static computes = {};
    init() {
        const { company, user } = Udesk.data.init;
        this._storageKey = `general-inspection-report-userId=${user.id}company=${company.id} `;
        if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
            this.privates.radioValue = '-1';
        } else if (company.enabledCallInspect !== 0) {
            this.privates.radioValue = '1';
        } else if (company.enabledImInspect !== 0) {
            this.privates.radioValue = '2';
        }
    }
    actions = {
        timeChange(dataString) {
            let startTime = '', endTime = '';
            if (!dataString[0] || !dataString[1]) {
                startTime = moment()
                    .add(-7, "day")
                    .format("YYYY-MM-DD 00:00:00");
                endTime = moment().format("YYYY-MM-DD 23:59:59");
            } else {
                startTime = dataString[0];
                endTime = dataString[1];
            }

            this.privates.statAt = [startTime, endTime];
            this.actions.update();
        },
        radioGroup(value) {
            this.privates.radioValue = value.target.value;
            this.actions.update();
        },
        textAreaChange(value) {
            this.privates.textAreaText = value.target.value;
            this.actions.update();
        },
        reultMoreChange() {
            this.privates.reultMore = false;
            this.actions.update();
        },
        reultInfoChange(value) {
            this.privates.reultInfoFlag[value] = false;
            this.actions.update();
        },
        textAreaSave() {
            this.privates.storages.textAreaText = this.privates.textAreaText;
            Udesk.ui.notify.success(Locales['current'].fix.draftSavedSuccessfully);
        },
        textAreaCancel() {
            this.privates.textAreaText = "";
            this.actions.update();
        },
        dataCancel() {
            this.privates.statAt = [
                moment()
                    .add(-7, "day")
                    .format("YYYY-MM-DD 00:00:00"),
                moment().format("YYYY-MM-DD 23:59:59")
            ];
            // this.privates.radioValue = "-1";
            const company = Udesk.data.init.company;
            if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
                this.privates.radioValue = '-1';
            } else if (company.enabledCallInspect !== 0) {
                this.privates.radioValue = '1';
            } else if (company.enabledImInspect !== 0) {
                this.privates.radioValue = '2';
            }
            this.privates.taskId = -1;
            this.actions.update();
        },
        echarsTypeChange(key) {
            this.privates.echarsTypeField = key;
            if (key === 1) {
                this.privates.lineDataList = this.privates.qualityTestingData;
            } else if (key === 2) {
                this.privates.lineDataList = this.privates.spotCheckData;
            } else if (key === 3) {
                this.privates.lineDataList = this.privates.representationsData;
            }
            this.actions.update();
        },
        dataSave() {
            let { sdkOptions } = this.props;
            let { statAt, radioValue, taskId } = this.privates;
            let data = {
                statAt,
                beginOfWeek: "1",
                endOfWeek: "7",
                ids: [],
                permission: "all",
                inspect_data_source: radioValue,
                task_id: taskId
            };
            let saveUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionUniversalReports/queryBusinessStatistics`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(saveUrl, data).then(
                    resp => {
                        resolve(resp);
                        if (resp.data && resp.data.rows && resp.data.rows.length > 0) {
                            if (radioValue === "-1") {
                                this.privates.callListData = resp.data.rows.find(
                                    current => current.inspect_data_source === "1"
                                );
                                this.privates.dialogListData = resp.data.rows.find(
                                    current => current.inspect_data_source === "2"
                                );
                            } else if (radioValue === "1") {
                                this.privates.callListData = resp.data.rows.find(
                                    current => current.inspect_data_source === "1"
                                );
                                this.privates.dialogListData = {};
                            }
                            if (radioValue === "2") {
                                this.privates.callListData = {};
                                this.privates.dialogListData = resp.data.rows.find(
                                    current => current.inspect_data_source === "2"
                                );
                            }
                        } else {
                            this.privates.callListData = [];
                            this.privates.dialogListData = [];
                        }
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            let dataTimeUrl = "";
            let effectTimeUrl = "";
            if (moment(statAt[1]).diff(moment(statAt[0]), "month") > 6) {
                dataTimeUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionUniversalReports/queryBusinessStatisticsTrendMonth`,
                    sdkOptions
                );
                effectTimeUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionUniversalReports/queryQualityEffectTrendMonth`,
                    sdkOptions
                );
            } else if (
                moment(statAt[1]).diff(moment(statAt[0]), "month") < 6 &&
                moment(statAt[1]).diff(moment(statAt[0]), "month") >= 2
            ) {
                dataTimeUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionUniversalReports/queryBusinessStatisticsTrendWeek`,
                    sdkOptions
                );
                effectTimeUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionUniversalReports/queryQualityEffectTrendWeek`,
                    sdkOptions
                );
            } else if (
                moment(statAt[1]).diff(moment(statAt[0]), "month") < 2 &&
                moment(statAt[1]).diff(moment(statAt[0]), "day") >= 2
            ) {
                dataTimeUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionUniversalReports/queryBusinessStatisticsTrendDay`,
                    sdkOptions
                );
                effectTimeUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionUniversalReports/queryQualityEffectTrendDay`,
                    sdkOptions
                );
            } else {
                dataTimeUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionUniversalReports/queryBusinessStatisticsTrendHour`,
                    sdkOptions
                );
                effectTimeUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionUniversalReports/queryQualityEffectTrendHour`,
                    sdkOptions
                );
            }
            new Promise((resolve, reject) => {
                Udesk.ajax.post(dataTimeUrl, data).then(
                    resp => {
                        resolve(resp);
                        this.privates.echarsTypeField = 1;
                        this.privates.qualityTestingData = {
                            ...resp.data,
                            header: {
                                ...resp.data.header,
                                indicators: resp.data.header.indicators.filter(
                                    current => current.group === "group_01"
                                )
                            }
                        };

                        this.privates.spotCheckData = {
                            ...resp.data,
                            header: {
                                ...resp.data.header,
                                indicators: resp.data.header.indicators.filter(
                                    current => current.group === "group_02"
                                )
                            }
                        };

                        this.privates.representationsData = {
                            ...resp.data,
                            header: {
                                ...resp.data.header,
                                indicators: resp.data.header.indicators.filter(
                                    current => current.group === "group_03"
                                )
                            }
                        };

                        this.privates.lineDataList = this.privates.qualityTestingData;
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            let effectUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionUniversalReports/queryQualityEffect`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(effectUrl, data).then(
                    resp => {
                        resolve(resp);
                        if (resp.data && resp.data.rows && resp.data.rows.length > 0) {
                            this.privates.effectListData = resp.data.rows[0];
                        }
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            new Promise((resolve, reject) => {
                Udesk.ajax.post(effectTimeUrl, data).then(
                    resp => {
                        resolve(resp);
                        this.privates.effectDataListEcharts = resp.data;
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            let effectTableUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionUniversalReports/queryQualityEffectPointList`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(effectTableUrl, data).then(
                    resp => {
                        resolve(resp);
                        if (resp.data) {
                            if (
                                resp.data.header &&
                                resp.data.header.indicators &&
                                resp.data.header.indicators.length > 0
                            ) {
                                this.privates.columns = resp.data.header.indicators.map(
                                    item => {
                                        return {
                                            title: item.fieldTitle,
                                            dataIndex: item.fieldKey,
                                            key: item.fieldKey,
                                            width: "23%"
                                        };
                                    }
                                );
                                this.privates.columns = [
                                    ...this.privates.columns,
                                    {
                                        title: Locales['current'].labels.action,
                                        dataIndex: "action",
                                        key: "action",
                                        render: (item, records) => {
                                            return (
                                                <a
                                                    className="general-effect-button-good"
                                                    href={`/site/tasks/manage/${records.qa_inspection_effects_generic__task_id}/template/check-point/index`}
                                                >
                                                    {Locales['current'].fix.optimization}
                                                </a>
                                            );
                                        }
                                    }
                                ];
                            }
                            if (resp.data.rows && resp.data.rows.length > 0) {
                                this.privates.effectTableData = resp.data.rows.map(
                                    (item, index) => {
                                        return {
                                            key: index + 1,
                                            width: "23%",
                                            ...item
                                        };
                                    }
                                );
                            }
                        } else {
                            this.privates.columns = [];
                            this.privates.effectTableData = [];
                        }
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            let agentUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionUniversalReports/queryQualityResultsAgentScore`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(agentUrl, data).then(
                    resp => {
                        resolve(resp);
                        if (
                            resp.data &&
                            resp.data.header &&
                            resp.data.header.dimensions &&
                            resp.data.header.dimensions.length > 0 &&
                            resp.data.rows &&
                            resp.data.rows.length > 0
                        ) {
                            let list = [
                                {
                                    title: Locales['current'].fix.agentName,
                                    dataIndex: "qa_agent_summary_generic__agent_name",
                                    key: "qa_agent_summary_generic__agent_name",
                                    width: 200
                                }
                            ];
                            resp.data.rows.forEach(item => {
                                if (
                                    !list.find(
                                        current =>
                                            current.key === item.qa_agent_summary_generic__task_id
                                    )
                                ) {
                                    list = [
                                        ...list,
                                        {
                                            title:
                                                item.qa_agent_summary_generic__task_name.length > 15 ? (<span title={item.qa_agent_summary_generic__task_name}>{item.qa_agent_summary_generic__task_name.substring(0, 10)}...</span>) : (
                                                    item.qa_agent_summary_generic__task_name
                                                ),
                                            dataIndex: item.qa_agent_summary_generic__task_id,
                                            key: item.qa_agent_summary_generic__task_id,
                                            width: 200
                                        }
                                    ];
                                }
                            });

                            this.privates.agentColumns = list.map(item => {
                                return {
                                    ...item,
                                    render: (text, record) => {
                                        if (!text) {
                                            return "--";
                                        } else {
                                            return text;
                                        }
                                    }
                                };
                            });
                            let lists = [];
                            resp.data.rows.forEach(item => {
                                if (
                                    !lists.find(
                                        current =>
                                            current.key === item.qa_agent_summary_generic__agent_id
                                    )
                                ) {
                                    if (
                                        item.qa_agent_summary_generic__task_id &&
                                        item.qa_agent_summary_generic__total_score
                                    ) {
                                        lists = [
                                            ...lists,
                                            {
                                                key: item.qa_agent_summary_generic__agent_id,
                                                qa_agent_summary_generic__agent_name:
                                                    item.qa_agent_summary_generic__agent_name,
                                                [item.qa_agent_summary_generic__task_id]:
                                                    item.qa_agent_summary_generic__total_score
                                            }
                                        ];
                                    } else {
                                        lists = [
                                            ...lists,
                                            {
                                                key: item.qa_agent_summary_generic__agent_id,
                                                qa_agent_summary_generic__agent_name:
                                                    item.qa_agent_summary_generic__agent_name
                                            }
                                        ];
                                    }
                                } else {
                                    lists.forEach(items => {
                                        if (items.key === item.qa_agent_summary_generic__agent_id) {
                                            items = _extend(items, {
                                                [item.qa_agent_summary_generic__task_id]:
                                                    item.qa_agent_summary_generic__total_score
                                            });
                                        }
                                    });
                                }
                            });
                            this.privates.agentDataList = lists;
                        } else {
                            this.privates.agentColumns = [];
                            this.privates.agentDataList = [];
                        }
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            let resultUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionUniversalReports/queryQualityResultsTaskScore`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(resultUrl, data).then(
                    resp => {
                        resolve(resp);
                        this.privates.reultDataList = resp.data;
                        this.privates.reultDataList.forEach(item => {
                            this.privates.reultInfoFlag = {
                                ...this.privates.reultInfoFlag,
                                [item.taskId]: true
                            };
                        });
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        taskFieldChange(value) {
            this.privates.taskId = value;
            this.actions.update();
        },
        dataExport() {
            let { sdkOptions } = this.props;
            let { statAt, radioValue, taskId, textAreaText } = this.privates;
            let data = {
                statAt,
                beginOfWeek: "1",
                endOfWeek: "7",
                ids: [],
                permission: "all",
                inspect_data_source: radioValue,
                task_id: taskId,
                inspectionRemark: textAreaText,
                systemModule: getSystemModule(),
            };
            let saveUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionUniversalReports/export`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(saveUrl, data).then(
                    resp => {
                        resolve(resp);
                        this.privates.textAreaText = "";
                        this.privates.storages.textAreaText = "";
                        this.actions.update();
                        Udesk.ui.notify.success(Locales['current'].labels.exportSuccess);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        }
    };
    //#region Life Cycle
    parseProps({ props, prevProps }) { }
    componentDidMount() {
        this.privates.textAreaText = this.privates.storages.textAreaText;
        this.actions.update();
    }
    componentWillUnmount() { }
    //#endregion
}

export default GeneralInspectionReportComponent;
