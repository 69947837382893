// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostKnowledgeDatasExportBody, BaseResponseVoid } from '../../types';

/**
 * 导出知识点
 *
 * @export
 * @tags knowledge-data-controller
 * @link [POST] /knowledgeDatas/export
 */
export function postKnowledgeDatasExport(
    data: PostKnowledgeDatasExportBody
): Promise<BaseResponseVoid>;
export function postKnowledgeDatasExport(
    data: PostKnowledgeDatasExportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postKnowledgeDatasExport(
    data: PostKnowledgeDatasExportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostKnowledgeDatasExportBody, never, never>('/knowledgeDatas/export', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postKnowledgeDatasExport',
    });
}

export const meta = [
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas/export', method: 'post' },
];
