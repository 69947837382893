// import { Redirect } from 'react-router-dom';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Form, Icon, Switch, Tabs, Space, Button, Select } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { getCurrentRouteName, isSalesSubApp } from 'Udesk/system/subApp';
import { inspect } from 'src/util/core';
import { hasFeature } from 'src/util/permit';
import { useRequest } from 'src/util/hook';
// import { getRouteNameWithCA } from 'src/routes/conversationAnalysis/routes-global';

const { TabPane } = Tabs;

function IntelligentTags(props) {
    const company = Udesk.data.init.company ? Udesk.data.init.company : null;
    const ADD_TYPES = [
        {
            pathName: getCurrentRouteName('smartConversationIntelligentTags'),
            key: 'intelligent-tags',
            text: UdeskLocales['current'].components.pages.intelligentTags.subTitle.common,
            id: 'smart-conversation',
        },
        {
            pathName: getCurrentRouteName('wechatIntelligentTags'),
            key: 'wechat-tags',
            text: UdeskLocales['current'].components.pages.intelligentTags.subTitle.wecom,
            id: 'wechat',
        },
        {
            pathName: getCurrentRouteName('ignoreIntelligentTags'),
            key: 'ignore-label',
            id: 'ignore',
            redirect: getCurrentRouteName('smartConversationIntelligentTags'),
        },
        {
            pathName: 'sessionLabel',
            key: 'session-label',
            text: UdeskLocales['current'].components.pages.intelligentTags.sessionLabelTitle,
            id: 'session-label',
        },
        {
            pathName: 'keyEvents',
            key: 'key-events',
            text: UdeskLocales['current'].components.pages.intelligentTags.keyEventsTitle,
            id: 'key-events',
        },
        {
            pathName: 'supervision',
            key: 'supervision',
            text: /* 监督项 */ UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags
                .supervisionItem,
            id: 'supervision',
        },
        {
            pathName: 'customerLabel',
            key: 'customer-label',
            text: /* 客户标签 */ UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags
                .customerLabel,
            id: 'customer-label',
        },
    ].filter((item) => {
        if (isSalesSubApp()) {
            return (
                item.id === 'session-label' ||
                item.id === 'key-events' ||
                item.id === 'supervision' ||
                item.id === 'customer-label'
            );
        } else {
            return (
                (item.id !== 'wechat' ||
                    (company &&
                        company.enabledWeComInspect !== 0 &&
                        Udesk.data.init.user &&
                        Udesk.data.init.user.hasFeature('smart:wecomTag:root'))) &&
                item.id !== 'session-label' &&
                item.id !== 'key-events' &&
                item.id !== 'supervision' &&
                item.id !== 'customer-label'
            );
        }
    });
    const [activeKey, setActiveKey] = useState('intelligent-tags');
    const onAddTypeChange = (activeKey) => {
        setActiveKey(activeKey);
        const tab = ADD_TYPES.find((t) => t.key === activeKey) ?? {};
        const routeOptions = {
            history: props.history,
            routeName: tab.pathName,
            pathParams: { taskId: props.match.params.taskId },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        const currentPath = ADD_TYPES.find(
            (item) => props.history.location.pathname.indexOf(item.id) >= 0
        );
        currentPath && setActiveKey(currentPath.key);
    }, [props.history.location]);
    const title = useMemo(() => {
        if (props.history.location.pathname.includes('/smart-conversation/new')) {
            return UdeskLocales['current'].components.pages.intelligentTags.detail.new;
        } else if (props.history.location.pathname.includes('/smart-conversation/edit')) {
            return UdeskLocales['current'].components.pages.intelligentTags.detail.edit;
        } else if (props.history.location.pathname.includes('/session-label/new')) {
            return UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags
                .newSessionLabel;
        } else if (props.history.location.pathname.includes('/session-label/edit')) {
            return UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags
                .editSessionLabel;
        } else if (props.history.location.pathname.includes('/key-events/new')) {
            return UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.newKeyEvent;
        } else if (props.history.location.pathname.includes('/key-events/edit')) {
            return UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.editKeyEvents;
        } else if (props.history.location.pathname.includes('/supervision/new')) {
            return /* 新建监督项规则 */ UdeskLocales['current'].pages.intelligentAnalysis
                .intelligentTags.newSupervisionItemRule;
        } else if (props.history.location.pathname.includes('/supervision/edit')) {
            return /* 编辑监督项规则 */ UdeskLocales['current'].pages.intelligentAnalysis
                .intelligentTags.editSupervisionItemRule;
        } else if (props.history.location.pathname.includes('/customer-label/new')) {
            return /* 新建客户标签 */ UdeskLocales['current'].pages.intelligentAnalysis
                .intelligentTags.newCustomerLabel;
        } else if (props.history.location.pathname.includes('/customer-label/edit')) {
            return /* 编辑客户标签 */ UdeskLocales['current'].pages.intelligentAnalysis
                .intelligentTags.editCustomerLabel;
        } else if (props.history.location.pathname.includes('/ignore-label/index')) {
            return /* 忽略标签库 */ UdeskLocales['current'].pages.intelligentAnalysis
                .intelligentTags.ignoringTagLibraries;
        } else {
            return UdeskLocales['current'].components.pages.intelligentTags.title;
        }
    }, [props.history.location.pathname]);
    const showFooter = useMemo(() => {
        const hiddenFooterUrl = [
            '/smart-conversation/new',
            '/smart-conversation/edit',
            '/session-label/new',
            '/session-label/edit',
            '/key-events/new',
            '/key-events/edit',
            '/supervision/new',
            '/supervision/edit',
            '/customer-label/new',
            '/customer-label/edit',
            '/ignore-label/index',
        ];
        return !hiddenFooterUrl.some((url) => props.history.location.pathname.includes(url));
    }, [props.history.location.pathname]);
    const showExtra = useMemo(() => {
        const hiddenFooterUrl = ['/smart-conversation/index'];
        return hiddenFooterUrl.some((url) => props.history.location.pathname.includes(url));
    }, [props.history.location.pathname]);
    const backToIndex = () => {
        // window.history.back();
        let routeOptions = {
            history: props.history,
            routeName: 'intelligentTagsIndex',
        };
        const currentRoute = ADD_TYPES.find((i) => props.history.location.pathname.includes(i.id));
        if (currentRoute) {
            routeOptions.routeName = currentRoute.pathName;
        }
        if (currentRoute.key === 'ignore-label') {
            routeOptions.routeName = currentRoute.redirect;
        }
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    return (
        <Page
            pageBodyClassName="intelligent-tags-page-index"
            bodyStyle={{ height: '100%' }}
            title={title}
            padding={!props.history.location.pathname.includes('supervision')}
            isRouteEntry={true}
            onBack={!showFooter ? backToIndex : undefined}
            extra={inspect(showExtra, () =>
                hasFeature('sys:largeAi:qa:smart', () => <SmartTagsModelSettingForm {...props} />)
            )}
            footer={
                showFooter ? (
                    <Tabs
                        className="intelligent-tags-page-index-tabs"
                        activeKey={activeKey}
                        onChange={onAddTypeChange}
                    >
                        {props.routes
                            .filter((r) => ADD_TYPES.find((t) => t.pathName === r.name))
                            .map((item, i) => {
                                const tab = ADD_TYPES.find((t) => t.pathName === item.name) ?? {};
                                return <TabPane tab={tab.text} key={tab.key}></TabPane>;
                            })}
                    </Tabs>
                ) : undefined
            }
        >
            <SubRoutes
                route={props.routes.find(
                    (r) => r.name === ADD_TYPES.find((i) => i.key === activeKey)?.pathName
                )}
            />
        </Page>
    );
}

export default React.memo(IntelligentTags);

const DisplayQuantity = {
    Unlimited: -1,
    Top5: 5,
    Top10: 10,
    Top15: 15,
};

const SmartTagsModelSettingForm = React.memo((props) => {
    const [form] = Form.useForm();
    const { run } = useRequest({
        autoUpdateData: false,
        onSuccess(resp) {
            form.setFieldsValue({
                isAutoGenerate: !!resp.data.isAutoGenerate,
                isDefaultValid: !!resp.data.isDefaultValid,
                displayQuantity: resp.data.displayQuantity ?? DisplayQuantity.Unlimited,
            });
        },
    });

    const { run: update } = useRequest({
        autoUpdateData: false,
        onSuccess() {
            Udesk.ui.notify.success(
                /* 保存成功 */ UdeskLocales['current'].business.info.saveSuccess
            );
        },
    });

    const onValuesChange = useCallback((changedValues, allValues) => {
        const params = {
            isAutoGenerate: allValues.isAutoGenerate ? 1 : 0,
            isDefaultValid: allValues.isDefaultValid ? 1 : 0,
            displayQuantity: allValues.displayQuantity,
        };
        update('/smartTags/model/setting', params, 'put');
    }, []);

    useEffect(() => {
        run('/smartTags/model/setting');
    }, []);

    const goIgnore = () => {
        Udesk.ui.routing.transitionTo({
            history: props.history,
            routeName: getCurrentRouteName('ignoreIntelligentTags'),
        });
    };

    return (
        <Space>
            <Button type="link" onClick={goIgnore}>
                {/* 忽略标签库 */}
                {
                    UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags
                        .ignoringTagLibraries
                }
            </Button>

            <Form form={form} layout="inline" onValuesChange={onValuesChange}>
                <Form.Item
                    label={
                        <span>
                            {
                                /* 自动生成标签 */ UdeskLocales['current'].pages.intelligentAnalysis
                                    .intelligentTags.automaticallyGenerateLabels
                            }
                        </span>
                    }
                    name="isAutoGenerate"
                    valuePropName="checked"
                    tooltip={{
                        title: /* 开启后，基于大模型对会话自动生成标签，生成的标签默认归类到“未分类”下 */ UdeskLocales[
                            'current'
                        ].pages.intelligentAnalysis.intelligentTags
                            .afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault,
                        icon: (
                            <Icon
                                style={{ verticalAlign: 'middle' }}
                                type="QuestionCircleOutlined"
                                antdIcon
                            />
                        ),
                        overlayInnerStyle: {
                            width: 200,
                            whiteSpace: 'pre-wrap',
                        },
                    }}
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    label={
                        /* 默认有效性 */ UdeskLocales['current'].pages.intelligentAnalysis
                            .intelligentTags.defaultValidity
                    }
                    name="isDefaultValid"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item label={/* 标签显示数量 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.labelDisplayQuantity} name="displayQuantity">
                    <Select
                        options={[
                            {
                                label: /* 不限 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentTags.unlimited,
                                value: DisplayQuantity.Unlimited,
                            },
                            {
                                label: 'top5',
                                value: DisplayQuantity.Top5,
                            },
                            {
                                label: 'top10',
                                value: DisplayQuantity.Top10,
                            },
                            {
                                label: 'top15',
                                value: DisplayQuantity.Top15,
                            },
                        ]}
                    />
                </Form.Item>
            </Form>
        </Space>
    );
});
