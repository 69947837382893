

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));

var classCallCheck = function (instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
    }
};

var createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ("value" in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }

    return function (Constructor, protoProps, staticProps) {
        if (protoProps) defineProperties(Constructor.prototype, protoProps);
        if (staticProps) defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();

var inherits = function (subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};

var possibleConstructorReturn = function (self, call) {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
};

var cache = new WeakMap();

function getCachedState(promise) {
    var isPromise = promise && typeof promise.then === "function";

    if (!isPromise) {
        return {
            status: 1,
            result: promise,
            error: undefined
        };
    }

    if (!cache.has(promise)) {
        var cached = {
            status: 0,
            result: undefined,
            error: undefined
        };

        promise.then(function (result) {
            cached.status = 1;
            cached.result = result;
        }, function (error) {
            cached.status = 2;
            cached.error = error;
        });

        cache.set(promise, cached);
    }

    return cache.get(promise);
}

var Async = function (_React$Component) {
    inherits(Async, _React$Component);

    function Async() {
        classCallCheck(this, Async);
        return possibleConstructorReturn(this, (Async.__proto__ || Object.getPrototypeOf(Async)).apply(this, arguments));
    }

    createClass(Async, [{
        key: "componentWillMount",
        value: function componentWillMount() {
            this.setState(getCachedState(this.props.await));
        }
    }, {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(nextProps) {
            this.setState(getCachedState(nextProps.await));
        }
    }, {
        key: "componentDidMount",
        value: function componentDidMount() {
            var _this2 = this;

            var subscribed = true;

            this.componentWillUnmount = function () {
                subscribed = false;
            };

            if (this.state.status === 0) {
                this.props.await.then(function () {
                    return subscribed && _this2.componentWillMount();
                }, function () {
                    return subscribed && _this2.componentWillMount();
                });
            }
        }
    }, {
        key: "componentDidUpdate",
        value: function componentDidUpdate(prevProps) {
            if (prevProps.await !== this.props.await) {
                this.componentWillUnmount();
                this.componentDidMount();
            }
        }
    }, {
        key: "render",
        value: function render() {
            var _state = this.state,
                status = _state.status,
                result = _state.result,
                error = _state.error;


            switch (status) {
                case 0:
                    return this.props.children(this.props.waiting(this.props.await));
                case 1:
                    return this.props.children(this.props.then(result));
                case 2:
                    return this.props.children(this.props.catch(error));
                default:
                    throw new Error("unknown status");
            }
        }
    }]);
    return Async;
}(React.Component);

Async.defaultProps = {
    children: function children() {
        return null;
    },
    waiting: function waiting(promise) {
    // @todo support suspense by default
    // throw promise;
    },
    then: function then(result) {
        return result;
    },
    catch: function _catch(error) {
        throw error;
    }
};

function noop() {}

function createLoader(loader) {
    var resolver = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : noop;

    return function (_React$Component) {
        inherits(Loader, _React$Component);

        function Loader() {
            var _ref;

            var _temp, _this, _ret;

            classCallCheck(this, Loader);

            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
            }

            return _ret = (_temp = (_this = possibleConstructorReturn(this, (_ref = Loader.__proto__ || Object.getPrototypeOf(Loader)).call.apply(_ref, [this].concat(args))), _this), _this.state = {}, _temp), possibleConstructorReturn(_this, _ret);
        }

        createClass(Loader, [{
            key: "componentDidMount",
            value: function componentDidMount() {
                this.setState({
                    promise: loader(this.props),
                    promiseKey: resolver(this.props)
                });
            }
        }, {
            key: "componentWillReceiveProps",
            value: function componentWillReceiveProps(nextProps) {
                var nextPromiseKey = resolver(nextProps);

                if (this.state.promiseKey !== nextPromiseKey) {
                    this.setState({
                        promise: loader(nextProps),
                        promiseKey: nextPromiseKey
                    });
                }
            }
        }, {
            key: "render",
            value: function render() {
                return React.createElement(
                    Async,
                    { "await": this.state.promise },
                    this.props.children
                );
            }
        }]);
        return Loader;
    }(React.Component);
}

/**
 * @module ReactAsyncAwait
 */

exports.Async = Async;
exports.createLoader = createLoader;
