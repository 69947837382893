// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerScoringModelsQuery,
    BaseResponseListIntelligentPartnerScoringModelFoundResponse,
    PostIntelligentPartnerScoringModelsBody,
    BaseResponseIntelligentPartnerScoringModelFoundResponse,
} from '../../../types';

/**
 * 评分模型分页
 *
 * @export
 * @tags 智能陪练-评分模型
 * @link [GET] /intelligentPartner/scoring/models
 */
export function getIntelligentPartnerScoringModels(): Promise<BaseResponseListIntelligentPartnerScoringModelFoundResponse>;
export function getIntelligentPartnerScoringModels(
    options: ApiOptions<never, GetIntelligentPartnerScoringModelsQuery>
): Promise<BaseResponseListIntelligentPartnerScoringModelFoundResponse>;
export function getIntelligentPartnerScoringModels(
    options?: ApiOptions<never, GetIntelligentPartnerScoringModelsQuery>
): Promise<BaseResponseListIntelligentPartnerScoringModelFoundResponse> {
    return request<never, never, GetIntelligentPartnerScoringModelsQuery>(
        '/intelligentPartner/scoring/models',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentPartnerScoringModels' }
    );
}

/**
 * 新增评分模型
 *
 * @export
 * @tags 智能陪练-评分模型
 * @link [POST] /intelligentPartner/scoring/models
 */
export function postIntelligentPartnerScoringModels(
    data: PostIntelligentPartnerScoringModelsBody
): Promise<BaseResponseIntelligentPartnerScoringModelFoundResponse>;
export function postIntelligentPartnerScoringModels(
    data: PostIntelligentPartnerScoringModelsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerScoringModelFoundResponse>;
export function postIntelligentPartnerScoringModels(
    data: PostIntelligentPartnerScoringModelsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerScoringModelFoundResponse> {
    return request<PostIntelligentPartnerScoringModelsBody, never, never>(
        '/intelligentPartner/scoring/models',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerScoringModels',
        }
    );
}

export const meta = [
    { tags: ['智能陪练-评分模型'], path: '/intelligentPartner/scoring/models', method: 'get' },
    { tags: ['智能陪练-评分模型'], path: '/intelligentPartner/scoring/models', method: 'post' },
];
