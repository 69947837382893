import React from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Checkbox, Input, Radio } from 'udesk-ui';
import styled from 'styled-components';
// import './style.scss';

const Wrap = styled.div`
    display: flex;
`;

type NAProps = {
    onNaCheckedChange: (naChecked: 0 | 1) => void;
    naChecked: 0 | 1;
    naPreviousFormula?: string;
    onNaPreviousFormulaChange: (naPreviousFormula: string) => void;
    naScoreType?: number;
    onNotCatchPreRuleChange: (naScoreType: number) => void;
    showNAPreviousFormula?: boolean;
    labelPaddingTop?: number;
};

export const NA = React.memo((props: NAProps) => {
    const locales = Locales['current'];

    const {
        onNaCheckedChange,
        naChecked,
        naPreviousFormula,
        onNaPreviousFormulaChange,
        onNotCatchPreRuleChange,
        naScoreType,
        showNAPreviousFormula = true,
        labelPaddingTop = 6,
    } = props;
    const onNAChange = (e) => {
        onNaCheckedChange?.(e.target.checked ? 1 : 0);
    };
    return (
        <Wrap className="tasks-manage-template-configuration-detail-logic-na">
            <div style={{ paddingTop: labelPaddingTop, paddingLeft: 4 }}>
                {locales.pages.tasks.manage.template.checkPoint.configuration.logic.na}
            </div>
            <div className="tasks-manage-template-configuration-detail-logic-na-content">
                <Checkbox onChange={onNAChange} checked={Boolean(naChecked)}>
                    {locales.pages.tasks.manage.template.checkPoint.configuration.logic.naDes}
                </Checkbox>
                {showNAPreviousFormula && (
                    <>
                        {locales.pages.tasks.manage.template.checkPoint.configuration.logic.preRule}
                        <Input
                            value={naPreviousFormula}
                            onChange={(e) => {
                                onNaPreviousFormulaChange?.(e.target.value);
                            }}
                            style={{ width: 200 }}
                        ></Input>
                    </>
                )}

                <div style={{ marginTop: 16, marginBottom: 29 }}>
                    {
                        locales.pages.tasks.manage.template.checkPoint.configuration.logic
                            .notCatchPreRule
                    }
                    <Radio.Group
                        onChange={(e) => {
                            onNotCatchPreRuleChange?.(e.target.value);
                        }}
                        value={naScoreType}
                    >
                        {Udesk.enums.naScoreType.map((item, index) => {
                            return (
                                <Radio key={item.id} value={item.id}>
                                    {item.name}
                                </Radio>
                            );
                        })}
                    </Radio.Group>
                </div>
            </div>
        </Wrap>
    );
});

// 原代码
// <div className='tasks-manage-template-configuration-detail-logic-na'>
//     <div style={{ paddingTop: 6, paddingLeft: 4 }}>
//         {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.na}
//     </div>
//     <div className='tasks-manage-template-configuration-detail-logic-na-content'>
//         <Checkbox onChange={actions.onNAChange} checked={privates.naChecked}>
//             {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.naDes}
//         </Checkbox>
//         {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.preRule}
//         <Input
//             value={privates.naPreviousFormula}
//             onChange={actions.naPreviousFormulaChange}
//             style={{ width: 200 }}
//         >
//         </Input>
//         <div style={{ marginTop: 16, marginBottom: 29 }}>
//             {UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.notCatchPreRule}
//             <Radio.Group onChange={actions.onNotCatchPreRuleChange} value={privates.naScoreType}>
//                 {Udesk.enums.naScoreType.map((item, index) => {
//                     return (
//                         <Radio key={item.id} value={item.id}>{item.name}</Radio>
//                     );
//                 })}
//             </Radio.Group>
//         </div>
//     </div>
// </div>
